import { Pipe, PipeTransform } from '@angular/core';
import { convertHtmlToPlainText } from '../lib/placeholder-values/strip-html';

/**
 * Pipe used to display HTML code as plain text, e.g. in tooltips.
 */
@Pipe({
    name: 'htmlToPlaintext',
})
export class HtmlToPlaintextPipe implements PipeTransform {
    transform(htmlText: string): string {
        if (!htmlText) {
            return '';
        }

        return convertHtmlToPlainText(htmlText);
    }
}

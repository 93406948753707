<div class="card" @fadeInAndSlide>
    <div id="round-controls">
        <!--********** Round-to-full Input **********-->
        <currency-input
            placeholder="Auf volle"
            [(value)]="teamPreferences.valuation_decreaseRoundToFull"
            (valueChange)="emitTeamPreferenceChange()"
            tooltip="Gib einen fixen Betrag ein, z. B. 25 oder 50"
            [preventNegativeNumbers]="true"></currency-input>

        <!--********** Select round type **********-->
        <div class="selection-bar">
            <div
                class="selection-bar-item"
                (click)="setRoundType('floor')"
                [ngClass]="{
                    selected: teamPreferences.valuation_decreaseRoundType === 'floor',
                }">
                <span class="selection-label">abrunden</span>
            </div>
            <div
                class="selection-bar-item"
                (click)="setRoundType('ceil')"
                [ngClass]="{
                    selected: teamPreferences.valuation_decreaseRoundType === 'ceil',
                }">
                <span class="selection-label">aufrunden</span>
            </div>
            <div
                class="selection-bar-item"
                (click)="setRoundType('round')"
                matTooltip="Ab 0,5 wird aufgerundet, darunter abgerundet."
                [ngClass]="{
                    selected: teamPreferences.valuation_decreaseRoundType === 'round',
                }">
                <span class="selection-label">kaufmännisch</span>
            </div>
        </div>
    </div>

    <div id="round-preview">
        <div class="label">Vorschau:</div>
        <div class="label">
            {{ roundedPreview | convertToEuro }}
        </div>
    </div>
</div>

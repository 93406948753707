import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignablePdfTemplateConfig } from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { OfflineSyncBlobServiceBase } from '../libraries/database/offline-sync-blob-service.base';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

/**
 * If passed a page _id, this service returns the image of that page.
 */
@Injectable()
export class SignablePdfTemplateImageService extends OfflineSyncBlobServiceBase {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
    ) {
        super({
            serviceName: 'signablePdfTemplateImage',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            formDataBlobFieldName: 'image',
        });
    }

    /**
     * Delete all pages' images within a signableDocumentTemplateConfig.
     * May be used to revert an upload.
     */
    public async deleteAllPageImages(signableDocumentTemplateConfig: SignablePdfTemplateConfig) {
        return Promise.all(
            signableDocumentTemplateConfig.pages.map(async (page) =>
                this.deletePageImage({
                    signableDocumentTemplateConfig,
                    pageNumber: page.pageNumber,
                }),
            ),
        );
    }

    /**
     * Delete specific pages' images.
     */
    public async deleteSomePageImages({
        signableDocumentTemplateConfig,
        pageNumbers,
    }: {
        signableDocumentTemplateConfig: SignablePdfTemplateConfig;
        pageNumbers: number[];
    }) {
        return Promise.all(
            pageNumbers.map(async (pageNumber) =>
                this.deletePageImage({
                    signableDocumentTemplateConfig,
                    pageNumber,
                }),
            ),
        );
    }

    /**
     * Generate the name for a given page and delete its image file.
     */
    public async deletePageImage({
        signableDocumentTemplateConfig,
        pageNumber,
    }: {
        signableDocumentTemplateConfig: SignablePdfTemplateConfig;
        pageNumber: number;
    }) {
        const fileName = `${signableDocumentTemplateConfig._id}-page-${pageNumber}`;
        return this.delete(fileName);
    }
}

<div class="dialog-overlay" @dialogEnterAndLeave>
    <div class="dialog-container display-flex">
        <!--============================================-->
        <!-- Left Column -->
        <!--============================================-->
        <div id="left-column" class="card card-with-padding">
            <h2>Gutachten importieren</h2>
            <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

            <p class="label text-align-center margin-bottom-30">
                Importiere die wichtigsten
                <a href="https://wissen.autoixpert.de/hc/de/articles/4404011313810" target="_blank" rel="noopener">
                    Kopfdaten
                </a>
                , damit autoiXpert dir melden kann, wenn du ein Fahrzeug schon einmal besichtigt hast.
            </p>

            <!--============================================-->
            <!-- Drag & Drop indicator -->
            <!--============================================-->
            <div
                id="drag-and-drop-indicator"
                class="drop-overlay"
                *ngIf="fileIsOverBody"
                [ngClass]="{ 'file-over': fileIsOverDropZone }"
                ng2FileDrop
                (fileOver)="onFileOverDropZone($event)"
                (onFileDrop)="onFileDrop()"
                [uploader]="uploader">
                <h2>Datei hierher ziehen</h2>
                <img src="/assets/images/icons/no-data-to-display.png" alt="" />
            </div>
            <!--============================================-->
            <!-- END Drag & Drop indicator -->
            <!--============================================-->

            <!--============================================-->
            <!-- Data Source -->
            <!--============================================-->
            <section id="data-source-section">
                <h3>Datenquelle</h3>
                <div id="data-source-options-row" class="options-row grid">
                    <div
                        class="option-container"
                        *ngFor="let dataSource of sourceSystems"
                        [ngClass]="{ active: selectedSourceSystem === dataSource }"
                        (click)="selectSourceSystem(dataSource)"
                        tabindex="0">
                        <div class="option-image-container">
                            <ng-container [ngSwitch]="dataSource">
                                <img
                                    class="option-image"
                                    src="/assets/images/logos/nextsoft.png"
                                    *ngSwitchCase="'nextsoft'" />
                                <img
                                    class="option-image"
                                    src="/assets/images/logos/easyexpert.png"
                                    *ngSwitchCase="'easyexpert'" />
                                <img
                                    class="option-image"
                                    src="/assets/images/logos/audatex.svg"
                                    *ngSwitchCase="'audatex'" />
                                <mat-icon class="option-image" *ngSwitchCase="'custom'">table_rows</mat-icon>
                            </ng-container>
                        </div>
                        <div class="option-title-container" [ngSwitch]="dataSource">
                            <span class="option-title" *ngSwitchCase="'nextsoft'">neXtsoft</span>
                            <span class="option-title" *ngSwitchCase="'easyexpert'">EasyExpert</span>
                            <span class="option-title" *ngSwitchCase="'audatex'">Audatex</span>
                            <span class="option-title" *ngSwitchCase="'custom'">Freier Import</span>
                        </div>
                    </div>
                </div>

                <!--********** Import file description - Audatex **********-->
                <div class="import-file-description label" *ngIf="selectedSourceSystem === 'audatex'">
                    <a
                        href="https://wissen.autoixpert.de/hc/de/articles/4404480603410-AudaFusion-Kennzeichen-Pr%C3%BCfdatei-exportieren"
                        target="_blank"
                        rel="noopener">
                        Exportiere deine Gutachten aus AudaFusion
                    </a>
                    als CSV-Datei und lade sie hier hoch.
                </div>

                <!--********** Custom Data Source **********-->
                <div class="margin-bottom-20" *ngIf="selectedSourceSystem === 'custom'">
                    <h4>
                        Fremdsystem
                        <span
                            class="help-indicator"
                            matTooltip="Gib den Namen des Fremdsystems ein, damit wir dir bei der Abfrage auf Kennzeichen & VIN anzeigen können, aus welchem System ein Treffer stammt.">
                            ?
                        </span>
                    </h4>
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            [(ngModel)]="customSourceSystemName"
                            (input)="filterSourceSystemAutocompleteOptions()"
                            (focus)="filterSourceSystemAutocompleteOptions()"
                            (ngModelChange)="updateUploaderUrl()"
                            [matAutocomplete]="customSourceSystemAutocomplete"
                            placeholder="Name des Fremdsystems" />
                    </mat-form-field>
                    <mat-autocomplete #customSourceSystemAutocomplete>
                        <mat-option
                            *ngFor="let sourceSystemAutocompleteOption of filteredSourceSystemAutocompleteOptions"
                            [value]="sourceSystemAutocompleteOption"
                            (mousedown)="$event.preventDefault()">
                            {{ sourceSystemAutocompleteOption }}
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="label margin-top-20 margin-bottom-10 text-align-center">
                    Alle Marken sind Eigentum ihrer jeweiligen Inhaber.
                </div>

                <!--********** Import file description - Custom Import **********-->
                <div class="import-file-description label" *ngIf="selectedSourceSystem === 'custom'">
                    Füge deine Gutachten in die
                    <a href="https://wissen.autoixpert.de/hc/de/articles/4404011313810" target="_blank" rel="noopener">
                        CSV-Vorlage aus unserem Wissens-Center
                    </a>
                    ein und lade sie hier hoch.
                </div>
            </section>
            <!--============================================-->
            <!-- END Data Source -->
            <!--============================================-->

            <!--============================================-->
            <!-- File Upload -->
            <!--============================================-->
            <section id="file-upload-section" *ngIf="selectedSourceSystem">
                <!--********** No File uploaded **********-->
                <ng-container *ngIf="uploader && uploader.queue.length === 0">
                    <!--********** Dropzone **********-->
                    <div id="file-dropzone" class="label">Datei hierher ziehen</div>

                    <!--********** Or **********-->
                    <div>oder</div>

                    <!--********** Upload Button **********-->
                    <div id="file-upload-button-container">
                        <button id="upload-photos" (click)="fileUpload.click()">
                            <mat-icon id="upload-icon" class="medium-icon">publish</mat-icon>
                            <span>Datei hochladen</span>
                        </button>
                        <!-- Allow uploading multiple XML files from Audatex AudaPad3 at once. They will be concatenated. -->
                        <input
                            type="file"
                            multiple
                            ng2FileSelect
                            [uploader]="uploader"
                            #fileUpload
                            accept=".csv,.xml" />
                    </div>
                </ng-container>

                <!--********** File has been uploaded **********-->
                <div id="uploaded-file-list-container" *ngIf="uploader && uploader.queue.length > 0">
                    <div class="uploaded-file-container" *ngFor="let item of uploader.queue">
                        <mat-icon class="uploaded-file-attachment-icon">attach_file</mat-icon>
                        <div class="file-name">{{ item._file.name }}</div>
                        <mat-icon class="toolbar-icon" (click)="item.remove()">delete</mat-icon>
                    </div>
                </div>
            </section>
            <!--============================================-->
            <!-- END File Upload -->
            <!--============================================-->

            <div class="dialog-buttons-container">
                <button
                    (click)="uploadImportFile()"
                    [ngClass]="{ disabled: !isUploadAllowed() || fileUploadPending }"
                    [matTooltip]="isUploadAllowed() ? '' : 'Bitte wähle zuerst eine Datenquelle aus'">
                    <img
                        src="/assets/images/icons/loading-indicator-white.svg"
                        alt=""
                        class="pending-icon"
                        *ngIf="fileUploadPending" />
                    Importieren
                </button>
                <button class="secondary" (click)="closeDialog()">Abbrechen</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Left Column -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column -->
        <!--============================================-->
        <div id="right-column" *ngIf="importHistories.length" @slideInHorizontally>
            <div class="card">
                <h2>Importe</h2>

                <table id="import-history-list">
                    <tr class="import-history-list-entry" *ngFor="let importHistory of importHistories">
                        <!--********** Date **********-->
                        <td class="import-date">
                            <div class="display-flex flex-align-center">
                                <mat-icon class="margin-right-8">today</mat-icon>
                                <div>
                                    <div matTooltip="{{ importHistory.createdAt | moment: 'ddd, DD.MM.YYYY' }}"></div>
                                    {{ importHistory.createdAt | moment }}
                                    <div class="label">{{ importHistory.createdAt | moment: 'HH:mm [Uhr]' }}</div>
                                </div>
                            </div>
                        </td>

                        <!--********** Number of imported objects **********-->
                        <td class="number-of-imported-objects-cell text-align-end">
                            <span class="number-of-imported-objects" matTooltip="Anzahl importierter Datensätze">
                                {{ importHistory.numberOfObjects | number: '1.0-0' }}
                            </span>
                        </td>

                        <td class="import-history-submenu">
                            <mat-icon
                                class="toolbar-icon"
                                [matMenuTriggerFor]="importHistorySubmenu"
                                *ngIf="importHistoryIdBeingDeleted !== importHistory._id">
                                more_vert
                            </mat-icon>
                            <mat-menu #importHistorySubmenu>
                                <a mat-menu-item class="menu-item-with-icon" (click)="rewindImport(importHistory)">
                                    <mat-icon>delete</mat-icon>
                                    Importierte Datensätze löschen
                                </a>
                            </mat-menu>

                            <!--********** Pending Indicator **********-->
                            <img
                                src="/assets/images/icons/loading-indicator.svg"
                                *ngIf="importHistoryIdBeingDeleted === importHistory._id"
                                matTooltip="Lösche Daten..." />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Right Column -->
        <!--============================================-->
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getPaymentStatus } from '@autoixpert/lib/invoices/get-payment-status';
import { getUnpaidAmount } from '@autoixpert/lib/invoices/get-unpaid-amount';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { isMiddleClick } from '../../libraries/is-middle-click';
import { openInNewTabOnMiddleClick } from '../../libraries/open-in-new-tab-on-middle-click';
import { ApiErrorService } from '../../services/api-error.service';
import { DownloadService } from '../../services/download.service';
import { InvoiceService } from '../../services/invoice.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'associated-invoice-list-item',
    templateUrl: './associated-invoice-list-item.component.html',
    styleUrls: ['./associated-invoice-list-item.component.scss'],
})
export class AssociatedInvoiceListItemComponent implements OnInit {
    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private downloadService: DownloadService,
        private toastService: ToastService,
        private invoiceService: InvoiceService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() invoiceId: Invoice['_id'];

    public invoice: Invoice;
    public invoicePdfDownloading: boolean;

    async ngOnInit() {
        await this.loadInvoice();
    }

    private async loadInvoice() {
        try {
            this.invoice = await this.invoiceService.get(this.invoiceId);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: ``,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    public showInvoice(event: MouseEvent, invoice: Invoice) {
        if (!isMiddleClick(event)) {
            this.router.navigate(['Rechnungen', invoice._id]);
        }
    }

    public getUnpaidAmount(invoice: Invoice): number {
        return getUnpaidAmount(this.invoice);
    }

    protected openInvoiceInNewTabOnMiddleClick(event: MouseEvent): void {
        openInNewTabOnMiddleClick(event, `/Rechnungen/${this.invoice._id}`);
    }

    public downloadInvoice(invoice: Invoice) {
        this.invoicePdfDownloading = true;
        this.httpClient
            .get(`/api/v0/invoices/${invoice._id}/documents/invoice?format=pdf`, {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe({
                next: (response) => {
                    this.invoicePdfDownloading = false;
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.invoicePdfDownloading = false;
                    console.error('Error downloading the PDF buffer.', { error });
                    this.toastService.error(
                        'Fehler beim Download',
                        'Die PDF-Datei konnte nicht heruntergeladen werden.',
                    );
                },
            });
    }

    protected readonly getPaymentStatus = getPaymentStatus;
}

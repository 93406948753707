import { Injectable } from '@angular/core';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from './logged-in-user.service';
import { UserService } from './user.service';

type UserPreferencesKeys = keyof typeof UserPreferences;

@Injectable()
export class UserPreferencesService extends UserPreferences {
    /**
     * return Proxy to this.user
     */
    constructor(
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
    ) {
        super();

        // getUser$ is synchronous, therefore this.user will exist on logged in user
        this.loggedInUserService.getUser$().subscribe((user) => {
            this.user = user;
        });

        // Proxy to logged in user
        return new Proxy<UserPreferences & UserPreferencesService>(this, this.handler);
    }

    private user: User = new User();

    /**
     * handle get and set operations on proxy
     */
    private handler = {
        get: (target, propertyKey: UserPreferencesKeys, receiver) => {
            // use default value as fallback if no user is logged in
            if (!this.user?.preferences) {
                return Reflect.get(this, propertyKey, receiver);
            }

            const defaultValue = Reflect.get(this, propertyKey, receiver);
            const userValue = Reflect.get(this.user.preferences, propertyKey, receiver);

            // return default value if user value is nullish
            return userValue ?? defaultValue;
        },
        set: (target, propertyKey: UserPreferencesKeys, value) => {
            const returnValue = Reflect.set(this.user.preferences, propertyKey, value);
            this.save();
            return returnValue;
        },
    };

    /**
     * Save the preferences to the server
     */
    public save() {
        // Have the user saved into local storage including his new preferences
        this.loggedInUserService.setUser(this.user);

        // Save the new preferences to the server.
        this.userService.put(this.user).catch((error) => {
            console.error('Error saving the user preferences.', { error });
        });
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';

@Component({
    selector: 'recipient-selector',
    templateUrl: 'recipient-selector.component.html',
    styleUrls: ['recipient-selector.component.scss'],
})
export class RecipientSelectorComponent {
    @Input() contactPerson: ContactPerson;
    @Input() contactPeopleForQuickSelection: ContactPerson[];
    @Input() autocompleteGroupNames: ContactPerson['organizationType'][];
    @Input() hideIban: boolean;
    @Input() disabled: boolean;

    @Output() contactPersonChange: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();

    public contactPersonSelectorVisible: boolean;

    public openContactPersonSelector(): void {
        this.contactPersonSelectorVisible = true;
    }

    public closeContactPersonSelector(): void {
        this.contactPersonSelectorVisible = false;
    }

    public emitContactPersonChange(contactPerson: ContactPerson): void {
        this.contactPersonChange.emit(contactPerson);
    }
}

<td class="checkbox-column">
    <mat-checkbox [checked]="checked" (change)="selectionChanged.emit()"></mat-checkbox>
</td>
<td class="customer-name-column">
    <div class="report-customer-name-column">
        <div class="report-customer-organization" *ngIf="report.claimant.contactPerson.organization">
            {{ report.claimant.contactPerson.organization }}
        </div>
        <div
            [ngClass]="{ label: report.claimant.contactPerson.organization }"
            *ngIf="
                report.claimant.contactPerson.salutation ||
                report.claimant.contactPerson.firstName ||
                report.claimant.contactPerson.lastName
            ">
            {{ report.claimant.contactPerson.firstName }} {{ report.claimant.contactPerson.lastName }}
        </div>
        <div
            class="missing-data-info label"
            *ngIf="
                !report.claimant.contactPerson.organization &&
                !report.claimant.contactPerson.firstName &&
                !report.claimant.contactPerson.lastName
            ">
            Kein Name
        </div>
    </div>
</td>
<td>{{ report.token }}</td>
<td>
    <license-plate [licensePlate]="report.car.licensePlate" *ngIf="report.car.licensePlate"></license-plate>
    <span class="missing-data-info label" *ngIf="!report.car.licensePlate">Kein Kennzeichen</span>
</td>
<td matTooltip="Fertigstellungsdatum">
    <ng-container *ngIf="report.completionDate">{{ report.completionDate | moment: 'DD.MM.YYYY' }}</ng-container>
    <span *ngIf="!report.completionDate" class="missing-data-info label">Nicht fertig</span>
</td>
<td>
    <report-type-chip [reportType]="report.type" [showLiability]="true"></report-type-chip>
</td>
<td class="total-gross-column">{{ calculateTotalGrossFees(report) | convertToEuro }}</td>

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { Subtype } from '@autoixpert/helper-types/subtype';
import { findRecordById } from '@autoixpert/lib/arrays/find-record-by-id';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { CounterPattern, ReportTokenOrInvoiceNumberPlaceholderValues } from '@autoixpert/lib/counters/counter-pattern';
import { getBaseForFeeCalculation } from '@autoixpert/lib/damage-calculation-values/get-base-for-fee-calculation';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { removeDocumentFromReport } from '@autoixpert/lib/documents/remove-document-from-report';
import { determineFeePreferencesKey } from '@autoixpert/lib/fee-calculation/determine-fee-preference-key';
import { getPhotosFeeTotal } from '@autoixpert/lib/fee-calculation/get-photos-fee-total';
import { getPostageAndPhoneFees } from '@autoixpert/lib/fee-calculation/get-postage-and-phone-fees-total';
import { getTravelExpensesTotal } from '@autoixpert/lib/fee-calculation/get-travel-expenses-total';
import { getWritingFeesTotal } from '@autoixpert/lib/fee-calculation/get-writing-fees-total';
import { getYearOfLatestFeeTable } from '@autoixpert/lib/fee-set/get-year-of-latest-fee-table';
import { generateId } from '@autoixpert/lib/generate-id';
import { round } from '@autoixpert/lib/numbers/round';
import { Translator } from '@autoixpert/lib/placeholder-values/translator';
import { ReportTypeGerman, translateReportType } from '@autoixpert/lib/report/translate-report-type';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { getFullUserContactPerson } from '@autoixpert/lib/users/get-full-user-contact-person';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { Place } from '@autoixpert/models/contacts/place';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { InvoiceLineItemTemplate } from '@autoixpert/models/invoices/invoice-line-item-template';
import { InvoiceParameters } from '@autoixpert/models/invoices/invoice-parameters';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import {
    CustomFeeSet,
    CustomFeeSetRow,
    reportTypesWithFeeSets,
} from '@autoixpert/models/reports/assessors-fee/custom-fee-set';
import { FeeTableName, OfficialFeeTable } from '@autoixpert/models/reports/assessors-fee/fee-calculation';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { InvoiceNumberConfig, ReportTokenConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { Team } from '@autoixpert/models/teams/team';
import { DefaultDaysUntilDueConfig } from '@autoixpert/models/user/preferences/default-days-until-due-config';
import {
    CustomFeeConfig,
    DefaultFeeConfig,
    DefaultFeeSetRow,
    FeePreferences,
    FeePreferencesKey,
} from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { bvskFeeTable2022 } from '@autoixpert/static-data/fee-tables/bvsk-fee-table-2022';
import { bvskFeeTable2024 } from '@autoixpert/static-data/fee-tables/bvsk-fee-table-2024';
import { cgfFeeTable2023 } from '@autoixpert/static-data/fee-tables/cgf-fee-table-2023';
import { hukFeeTable2021 } from '@autoixpert/static-data/fee-tables/huk-fee-table-2021';
import { hukFeeTable2023 } from '@autoixpert/static-data/fee-tables/huk-fee-table-2023';
import { hukFeeTable2025 } from '@autoixpert/static-data/fee-tables/huk-fee-table-2025';
import { vksFeeTable2021 } from '@autoixpert/static-data/fee-tables/vks-fee-table-2021';
import { defaultDamageReportDocuments } from '@autoixpert/static-data/reports/default-report-documents';
import { getDefaultDaysUntilDue } from 'src/app/shared/libraries/invoices/get-default-days-until-due';
import { selectFeeSet } from 'src/app/shared/libraries/select-fee-set';
import { InvoiceLineItemTemplateService } from 'src/app/shared/services/invoice-line-item-template.service';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { LineItemUnitAutocompleteEntry } from '../../../invoices/invoice-editor/invoice-editor.component';
import { blockChildAnimationOnLoad } from '../../../shared/animations/block-child-animation-on-load.animation';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { fadeOutAnimation } from '../../../shared/animations/fade-out.animation';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { MatQuillComponent } from '../../../shared/components/mat-quill/mat-quill.component';
import {
    PromptDialogComponent,
    PromptDialogData,
} from '../../../shared/components/prompt-dialog/prompt-dialog.component';
import { openDirectionsOnGoogleMaps } from '../../../shared/libraries/distance-calculation/open-directions-on-google-maps';
import { getInvoiceApiErrorHandlers } from '../../../shared/libraries/error-handlers/get-invoice-api-error-handlers';
import { getInvoiceNumberOrReportTokenCounterErrorHandlers } from '../../../shared/libraries/error-handlers/get-invoice-number-or-report-token-counter-error-handlers';
import { calculateOtherFeesTotal } from '../../../shared/libraries/fees/calculate-other-fees-total';
import { calculateTotalGrossFees } from '../../../shared/libraries/fees/calculate-total-gross-fees';
import { calculateTotalNetFees } from '../../../shared/libraries/fees/calculate-total-net-fees';
import { getMissingAccessRightTooltip } from '../../../shared/libraries/get-missing-access-right-tooltip';
import { convertHtmlToPlainText } from '../../../shared/libraries/strip-html';
import { getInvoiceRecipientByRole } from '../../../shared/libraries/template-engine/get-invoice-recipient-by-role';
import { trackById } from '../../../shared/libraries/track-by-id';
import { hasAccessRight } from '../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { CustomFeeSetService } from '../../../shared/services/custom-fee-set.service';
import { DistanceService } from '../../../shared/services/distance.service';
import { DownloadService } from '../../../shared/services/download.service';
import { FieldGroupConfigService } from '../../../shared/services/field-group-config.service';
import { InvoiceCancellationService } from '../../../shared/services/invoice-cancellation.service';
import { InvoiceNumberService } from '../../../shared/services/invoice-number.service';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { NewWindowService } from '../../../shared/services/new-window.service';
import { RenderedPhotoFileService } from '../../../shared/services/rendered-photo-file.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { ReportTokenAndInvoiceNumberService } from '../../../shared/services/report-token-and-invoice-number.service';
import { ReportTokenService } from '../../../shared/services/report-token.service';
import { ReportService } from '../../../shared/services/report.service';
import { TeamService } from '../../../shared/services/team.service';
import { TemplatePlaceholderValuesService } from '../../../shared/services/template-placeholder-values.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { lineItemUnitsStatic } from '../../../shared/static-data/line-item-units';

@Component({
    selector: 'fees',
    templateUrl: 'fees.component.html',
    styleUrls: ['fees.component.scss'],
    animations: [
        fadeOutAnimation(),
        fadeInAndOutAnimation(100),
        slideInAndOutVertically(),
        blockChildAnimationOnLoad(),
    ],
})
export class FeesComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private renderedPhotoFileService: RenderedPhotoFileService,
        private invoiceService: InvoiceService,
        private invoiceCancellationService: InvoiceCancellationService,
        public userPreferences: UserPreferencesService,
        private httpClient: HttpClient,
        private apiErrorService: ApiErrorService,
        private downloadService: DownloadService,
        private toastService: ToastService,
        private reportService: ReportService,
        private domSanitizer: DomSanitizer,
        private reportDetailsService: ReportDetailsService,
        private teamService: TeamService,
        private loggedInUserService: LoggedInUserService,
        private invoiceNumberService: InvoiceNumberService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
        private reportTokenService: ReportTokenService,
        private reportTokenAndInvoiceNumberService: ReportTokenAndInvoiceNumberService,
        private distanceService: DistanceService,
        private customFeeSetService: CustomFeeSetService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        private networkStatusService: NetworkStatusService,
        private dialog: MatDialog,
        private invoiceLineItemTemplateService: InvoiceLineItemTemplateService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private newWindowService: NewWindowService,
    ) {}

    reportId: string;
    report: Report;
    user: User;
    team: Team;

    /**
     * This contains the local object URLs for the two photos in the photo fees row.
     */
    private localThumbnailSafeFileUrlConfigs = new Map<Photo['_id'], LocalThumbnailSafeFileUrlConfig>();

    public lastInvoiceNumber: string;

    // Subscriptions
    private subscriptions: Subscription[] = [];
    private reportTypeChangeSubscription: Subscription;
    private websocketListenerSubscription: Subscription;
    /*=============================================================================
     /* Static UI Elements
     /*===========================================================================*/

    public bvskFeesColumnNames: this['columnNames'] = [
        {
            label: 'Schadenhöhe',
            tooltip: 'Entspricht der Honorargrundlage',
        },
        {
            label: 'HB I',
            tooltip: '95 % der BVSK-Mitglieder legen ihr Honorar oberhalb dieses Wertes fest.',
        },
        {
            label: 'HB II',
            tooltip: '90 % der BVSK-Mitglieder legen ihr Honorar oberhalb dieses Wertes fest.',
        },
        {
            label: 'HB III',
            tooltip: '95 % der BVSK-Mitglieder legen ihr Honorar unterhalb dieses Wertes fest.',
        },
        {
            label: 'HB IV',
            tooltip: '90 % der BVSK-Mitglieder legen ihr Honorar unterhalb dieses Wertes fest.',
        },
        {
            label: 'HB V',
            tooltip:
                'Honorarkorridor, in dem je nach Schadenhöhe zwischen 50 % und 60 % der BVSK-Mitglieder ihr Honorar berechnen.',
        },
    ];
    public hukFeesColumnNames: this['columnNames'] = [
        {
            label: 'Schadenhöhe',
            tooltip: null,
        },
        {
            label: 'Rechnungs-Netto',
            tooltip: null,
        },
        {
            label: 'Rechnungs-Brutto',
            tooltip: null,
        },
    ];
    public vksFeesColumnNames: this['columnNames'] = [
        {
            label: 'Schadenhöhe',
            tooltip: null,
        },
        {
            label: 'Grundhonorar von',
            tooltip: null,
        },
        {
            label: 'Grundhonorar bis',
            tooltip: null,
        },
    ];
    public cgfFeesColumnNames: this['columnNames'] = [
        {
            label: 'Schadenhöhe',
            tooltip: null,
        },
        {
            label: 'Grundhonorar von',
            tooltip: null,
        },
        {
            label: 'Grundhonorar bis',
            tooltip: null,
        },
    ];
    public customFeeSetColumnNames: this['columnNames'] = [
        {
            label: 'Schadenhöhe',
            tooltip: null,
        },
        {
            label: 'Honorar',
            tooltip: null,
        },
    ];

    /**
     * Flags for the text block selectors.
     */
    showAssessorFeeDescriptionTextTemplates: boolean = false;

    // Amendment Report
    public originalReport: Report;

    public invoiceRecipient: ContactPerson;
    public invoiceRecipientContactPersonEditorShown: boolean;

    public invoiceLineItemTemplates: InvoiceLineItemTemplate[] = [];

    // A reference to the anchor tag allows the anchor tag to be clicked.
    @ViewChild('invoiceNumberElement', { static: false }) public invoiceNumberElement: ElementRef = null;
    @ViewChildren('descriptionInput') descriptionInputs: QueryList<MatQuillComponent>;
    /*=============================================================================
     /* END Static UI Elements
     /*/ //////////////////////////////////////////////////////////////////////////*/

    /******************************************************************************
     /*  Dynamic UI Elements
     /*****************************************************************************/
    public lowerFeeSet: number[];
    public upperFeeSet: number[];
    public readonly bvskFees: OfficialFeeTable = bvskFeeTable2024;
    public readonly hukFees: OfficialFeeTable = hukFeeTable2025;
    public readonly vksFees: OfficialFeeTable = vksFeeTable2021;
    public readonly cgfFees: OfficialFeeTable = cgfFeeTable2023;
    public columnNames: { label: string; tooltip: string }[];
    public sliderMin: number;
    public sliderMax: number;
    public asessorsFeeDescriptionShown = false;
    public defaultAssessorFeeDescriptionUpdatedIconShown = false;
    public feeUpdatedIconShown = false;
    public reportPhotos: Photo[] = [];
    public defaultFeesUpdatedIconShown = false;
    public traveledDistanceCalculationPending = false;
    public numberOfPagesPending = false;

    // Custom Fee Items
    // Unit autocomplete
    public lineItemUnits: LineItemUnitAutocompleteEntry[] = lineItemUnitsStatic;
    public filteredLineItemUnits: LineItemUnitAutocompleteEntry[] = [];

    public generateInvoiceNumberRequestPending = false;
    // True if the invoice number was set but the report token was already defined.
    public wasAdjustingReportTokenToInvoiceNumberPrevented: boolean = false;
    public associatedInvoiceIds: Invoice['_id'][] = [];

    public mainInvoice: Invoice = null;
    public invoiceFromInvoiceParameters: Invoice = null;
    public invoiceCancellationPending = false;

    // Custom Fee Set
    public customFeeSetDialogShown = false;
    public customFeeSets: CustomFeeSet[];
    public customFeeSet: CustomFeeSet;
    public customFeeSetLoading = true;
    public customFeeSetForEditDialog: CustomFeeSet;

    public reportPdfDownloadPending = false;
    protected showRememberCollectiveInvoiceSettingsInfoNote = false;

    /* *****************************************************************************
     /*  END Dynamic UI Elements
     /* ///////////////////////////////////////////////////////////////////////////*/

    get baseForFeeCalculation(): number {
        return getBaseForFeeCalculation(this.report).value;
    }

    get assessorsFeeForSlider(): number {
        return this.report.feeCalculation.assessorsFee;
    }

    set assessorsFeeForSlider(value: number) {
        this.report.feeCalculation.assessorsFee = +value;
    }

    get otherFeesTotal(): number {
        return calculateOtherFeesTotal(this.report);
    }

    /**
     * Getter for the data model only. We display the filled data property instead of this getter in the view.
     */
    get feesTotalNet(): number {
        return calculateTotalNetFees(this.report);
    }

    /**
     * Calculate the VAT rate and store on the report.
     * TODO Remove after July 1, 2024 because the VAT rate is set on report creation. We cannot remove this right away
     * though because some reports have been created without a VAT rate already.
     */
    private setDefaultVatRate() {
        // Only proceed if the VAT rate is null or undefined.
        if (this.report.feeCalculation.invoiceParameters.vatRate != null) return;
        // Set default VAT rate from team config
        this.report.feeCalculation.invoiceParameters.vatRate = this.team.invoicing.vatRate;
        this.saveReport();
    }

    get feesTotalVat(): number {
        return round(this.feesTotalGross - this.feesTotalNet);
    }

    get feesTotalGross(): number {
        return calculateTotalGrossFees(this.report);
    }

    get invoiceParamsDifferFromMainInvoice(): boolean {
        if (!this.invoiceFromInvoiceParameters || !this.mainInvoice) return false;

        return !this.invoiceService.areInvoicesEqual(this.invoiceFromInvoiceParameters, this.mainInvoice).result;
    }

    get invoiceParamsDifferFromMainInvoiceTooltip(): string {
        if (!this.invoiceFromInvoiceParameters || !this.mainInvoice) return '';

        const equalityCheck = this.invoiceService.areInvoicesEqual(this.invoiceFromInvoiceParameters, this.mainInvoice);

        // Invoices are equal -> Don't return tooltip
        if (equalityCheck.result) {
            return '';
        } else {
            const tooltipParts: string[] = [];

            for (const difference of equalityCheck.differences) {
                switch (difference.key) {
                    case 'intro':
                        tooltipParts.push('Einleitungstext');
                        break;
                    case 'subject':
                        tooltipParts.push('Betreff');
                        break;
                    case 'recipientsDiffer':
                        tooltipParts.push('Empfänger');
                        break;
                    case 'date':
                        tooltipParts.push('Rechnungsdatum');
                        break;
                    case 'dateOfSupply':
                        tooltipParts.push('Leistungsdatum');
                        break;
                    case 'daysUntilDue':
                        tooltipParts.push('Zahlungsfrist');
                        break;
                    case 'dueDate':
                        tooltipParts.push('Zahlungsziel');
                        break;
                    case 'totalNet':
                        tooltipParts.push('Gesamt netto');
                        break;
                    case 'totalGross':
                        tooltipParts.push('Gesamt brutto');
                        break;
                    case 'reportId':
                        tooltipParts.push('verknüpftes Gutachten');
                        break;
                    case 'factoringEnabled':
                        tooltipParts.push('mit oder ohne Factoring');
                        break;
                    case 'numberOfLineItems':
                        tooltipParts.push('Anzahl der Positionen');
                        break;
                    case 'lineItemContent':
                        tooltipParts.push(
                            `Alte Position ${convertHtmlToPlainText(difference.valueB, {
                                replaceParagraphsWithSingleNewLine: true,
                            })}`,
                        );
                        break;
                }
            }

            return `Diese Abweichungen bestehen:\n${tooltipParts.join('\n')}`;
        }
    }

    /******************************************************************************
     /* Initialization
     /*****************************************************************************/
    ngOnInit(): void {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(
            this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)),
            this.route.parent.params.subscribe((params) => (this.reportId = params['reportId'])),
        );

        const reportSubscription = this.reportDetailsService.get(this.reportId).subscribe({
            next: (report: Report) => {
                this.report = report;
                this.reportPhotos = report.photos.filter((photo) => photo.versions.report.included);

                this.setupBasedOnReportType();

                this.determineFeeTable();

                void this.getOriginalReport();

                this.setDefaultVatRate();

                if (!this.report.feeCalculation.invoiceParameters.daysUntilDue) {
                    this.insertDefaultDaysUntilDue();
                }

                // Load photo thumbnails from server
                void this.initializePhotoFiles();
                // Insert custom fee items from user preferences
                this.fillInCustomLineItems();

                this.determineInvoiceRecipient();

                this.loadAssociatedInvoices();

                this.joinAsRealtimeEditor();

                this.registerReportTypeChangeListener();
                this.registerWebsocketListeners();
            },
        });

        this.subscriptions.push(reportSubscription);

        this.invoiceLineItemTemplateService
            .find()
            .toPromise()
            .then((templates) => (this.invoiceLineItemTemplates = templates));
    }

    /******************************************************************************
     /* END Initialization
     /*/ //////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Assessors Fee
    //****************************************************************************/
    private setupBasedOnReportType(): void {
        let reportChanged = false;

        // Get data for the head runner with the standard fee tables as soon as the report comes in.
        switch (this.report.type) {
            case 'valuation':
                // We must not check for lock state earlier because that would skip setting up the fee table.
                if (this.isReportLocked()) return;
                if (this.report.feeCalculation.assessorsFee == null && !this.report.feeCalculation.assessorsFeeFrozen) {
                    this.report.feeCalculation.assessorsFee = this.userPreferences.valuationFee;
                    reportChanged = true;
                }
                break;
            case 'leaseReturn':
                // We must not check for lock state earlier because that would skip setting up the fee table.
                if (this.isReportLocked()) return;
                if (this.report.feeCalculation.assessorsFee == null && !this.report.feeCalculation.assessorsFeeFrozen) {
                    this.report.feeCalculation.assessorsFee = this.userPreferences.leaseReturnFee;
                    reportChanged = true;
                }
                break;
            case 'usedVehicleCheck':
                // We must not check for lock state earlier because that would skip setting up the fee table.
                if (this.isReportLocked()) return;
                if (this.report.feeCalculation.assessorsFee == null && !this.report.feeCalculation.assessorsFeeFrozen) {
                    this.report.feeCalculation.assessorsFee = this.userPreferences.usedVehicleCheckFee;
                    reportChanged = true;
                }
                break;
            case 'oldtimerValuationSmall':
                if (this.isReportLocked()) return;
                if (this.report.feeCalculation.assessorsFee == null && !this.report.feeCalculation.assessorsFeeFrozen) {
                    this.report.feeCalculation.assessorsFee = this.userPreferences.oldtimerValuationSmallFee;
                    reportChanged = true;
                }
                break;
            case 'shortAssessment':
                if (this.isReportLocked()) return;
                if (this.report.feeCalculation.assessorsFee == null && !this.report.feeCalculation.assessorsFeeFrozen) {
                    this.report.feeCalculation.assessorsFee = this.userPreferences.shortAssessmentFee;
                    reportChanged = true;
                }
                break;
            case 'invoiceAudit':
                if (this.isReportLocked()) return;
                if (this.report.feeCalculation.assessorsFee == null && !this.report.feeCalculation.assessorsFeeFrozen) {
                    this.report.feeCalculation.assessorsFee = this.userPreferences.invoiceAuditFee;
                    reportChanged = true;
                }
                break;
            // All insurance-related report types require fee tables. Now we also support partial and full kasko.
            case 'liability':
            case 'fullKasko':
            case 'partialKasko':
                this.findUpperAndLowerFeeSet();
                this.getCustomFeeSets();
                this.setHeaderElements();
                break;
            default:
                console.warn(
                    `The component does not know what preferences to load for the '${this.report.type}' report type.`,
                );
        }
        if (reportChanged) {
            this.saveReport();
        }
    }

    public setAssessorsFee(fee: number): void {
        if (this.isReportLocked()) return;

        this.report.feeCalculation.assessorsFee = fee;
    }

    public toggleAssessorsFeeDescription(): void {
        if (this.isReportLocked()) return;

        // If showing the input and the description is still empty, pre-fill the default text from the user's preferences
        if (!this.asessorsFeeDescriptionShown && !this.report.feeCalculation.assessorsFeeDescription) {
            switch (this.report.type) {
                case 'liability':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionLiability || '';
                    break;
                case 'shortAssessment':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionShortAssessment || '';
                    break;
                case 'partialKasko':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionPartialKasko || '';
                    break;
                case 'fullKasko':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionFullKasko || '';
                    break;
                case 'valuation':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionValuation || '';
                    break;
                case 'oldtimerValuationSmall':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionOldtimerValuationSmall || '';
                    break;
                case 'leaseReturn':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionLeaseReturn || '';
                    break;
                case 'usedVehicleCheck':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionUsedVehicleCheck || '';
                    break;
                case 'invoiceAudit':
                    this.report.feeCalculation.assessorsFeeDescription =
                        this.team.preferences.assessorsFeeDescriptionInvoiceAudit || '';
                    break;
            }
        }

        this.asessorsFeeDescriptionShown = !this.asessorsFeeDescriptionShown;
    }

    public getReportTypeInGerman(): string {
        return translateDocumentType(this.report.type);
    }

    public rememberAssessorFeeDescription(): void {
        switch (this.report.type) {
            case 'liability':
                this.team.preferences.assessorsFeeDescriptionLiability =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            case 'shortAssessment':
                this.team.preferences.assessorsFeeDescriptionShortAssessment =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            case 'partialKasko':
                this.team.preferences.assessorsFeeDescriptionPartialKasko =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            case 'fullKasko':
                this.team.preferences.assessorsFeeDescriptionFullKasko =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            case 'valuation':
                this.team.preferences.assessorsFeeDescriptionValuation =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            case 'oldtimerValuationSmall':
                this.team.preferences.assessorsFeeDescriptionOldtimerValuationSmall =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            case 'leaseReturn':
                this.team.preferences.assessorsFeeDescriptionLeaseReturn =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            case 'usedVehicleCheck':
                this.team.preferences.assessorsFeeDescriptionUsedVehicleCheck =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            case 'invoiceAudit':
                this.team.preferences.assessorsFeeDescriptionInvoiceAudit =
                    this.report.feeCalculation.assessorsFeeDescription;
                break;
            default:
                // Default: Don't display the success icon which would suggest that a report type was actually saved.
                return;
        }

        void this.saveTeam();

        this.defaultAssessorFeeDescriptionUpdatedIconShown = true;
        window.setTimeout(() => {
            this.defaultAssessorFeeDescriptionUpdatedIconShown = false;
        }, 1000);
    }

    /**
     * By setting the assessor's fee manually, lock it.
     */
    public freezeAssessorsFee() {
        this.report.feeCalculation.assessorsFeeFrozen = true;
    }

    /**
     * Unlock the assessor's fee in order to have it calculated automatically.
     */
    public unfreezeAssessorsFee() {
        if (this.isReportLocked()) return;

        this.report.feeCalculation.assessorsFeeFrozen = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Assessors Fee
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photos
    //****************************************************************************/
    public activateManualNumberOfPhotos() {
        this.report.feeCalculation.useManualNumberOfPhotos = true;
        this.report.feeCalculation.manualNumberOfPhotos = this.reportPhotos.length;
    }

    public deactivateManualNumberOfPhotos() {
        this.report.feeCalculation.useManualNumberOfPhotos = false;
        this.report.feeCalculation.manualNumberOfPhotos = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photos
    /////////////////////////////////////////////////////////////////////////////*/

    /******************************************************************************
     /* Custom Line Items
     /*****************************************************************************/
    public addInvoiceLineItem(): void {
        if (this.isReportLocked()) {
            return;
        }

        const newCostItem = new LineItem({
            description: '',
            quantity: 1,
            unitPrice: null,
            unit: 'Stück',
            active: true,
        });

        this.report.feeCalculation.invoiceParameters.lineItems.push(newCostItem);

        // Wait for the new row (including the description input field) to be rendered, then focus it
        setTimeout(() => {
            this.descriptionInputs.last.focusInput();
        }, 0);

        void this.saveReport();
    }

    public async saveInvoiceLineItemTemplate(lineItem: LineItem): Promise<void> {
        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.error(
                'Keine Berechtigung',
                'Du hast keine Berechtigung, Vorlagen für Rechnungspositionen zu speichern. Kontaktiere deinen Administrator',
            );
            return;
        }
        const newInvoiceLineItemTemplate = new InvoiceLineItemTemplate({
            _id: generateId(),
            description: lineItem.description,
            quantity: lineItem.quantity,
            unitPrice: lineItem.unitPrice,
            unit: lineItem.unit,
            createdBy: this.user._id,
            teamId: this.team._id,
        });
        try {
            await this.invoiceLineItemTemplateService.create(newInvoiceLineItemTemplate);
            this.toastService.success(
                'Rechnungsposition gespeichert',
                'Die Rechnungsposition wurde als Vorlage gespeichert und kann für zukünftige Rechnungen wiederverwendet werden.',
            );

            // Add the reference to allow updates in the template
            lineItem.lineItemTemplateId = newInvoiceLineItemTemplate._id;
            this.invoiceLineItemTemplates.push(newInvoiceLineItemTemplate);
            this.saveReport();
        } catch (error) {
            this.apiErrorService.handleAndRethrow(error);
        }
    }

    public async updateInvoiceLineItemTemplate(lineItem: LineItem): Promise<void> {
        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.error(
                'Keine Berechtigung',
                'Du hast keine Berechtigung, Vorlagen für Rechnungspositionen zu speichern. Kontaktiere deinen Administrator',
            );
            return;
        }
        // Update an existing invoice line item template
        const newInvoiceLineItemTemplate = findRecordById(this.invoiceLineItemTemplates, lineItem.lineItemTemplateId);
        newInvoiceLineItemTemplate.description = lineItem.description;
        newInvoiceLineItemTemplate.quantity = lineItem.quantity;
        newInvoiceLineItemTemplate.unitPrice = lineItem.unitPrice;
        newInvoiceLineItemTemplate.unit = lineItem.unit;

        try {
            await this.invoiceLineItemTemplateService.put(newInvoiceLineItemTemplate);
            this.toastService.success(
                'Rechnungsposition aktualisiert',
                'Die Vorlage der Rechnungsposition wurde aktualisiert und kann für zukünftige Rechnungen wiederverwendet werden.',
            );
        } catch (error) {
            this.apiErrorService.handleAndRethrow(error);
        }
    }

    public async insertInvoiceLineItemTemplate(lineItem: LineItem, lineItemTemplate: InvoiceLineItemTemplate) {
        lineItem.description = lineItemTemplate.description;
        lineItem.unitPrice = lineItemTemplate.unitPrice;
        lineItem.quantity = lineItemTemplate.quantity;
        lineItem.unit = lineItemTemplate.unit;
        lineItem.lineItemTemplateId = lineItemTemplate._id;
        await this.saveReport();
    }

    public removeInvoiceLineItem(costsItem): void {
        if (this.isReportLocked()) {
            return;
        }

        const index = this.report.feeCalculation.invoiceParameters.lineItems.indexOf(costsItem);
        if (index > -1) {
            this.report.feeCalculation.invoiceParameters.lineItems.splice(index, 1);
        }
    }

    public fillInCustomLineItems(): void {
        if (this.isReportLocked()) return;

        const feePreferencesKey: FeePreferencesKey = determineFeePreferencesKey(
            this.report.type,
            this.report.feeCalculation.selectedFeeTable,
        );

        /**
         * Only add additional line items if no invoice was written for this report, yet. If an invoice was written,
         * the field at invoiceParameters._id is filled.
         *
         * Also, don't overwrite the line item configuration in this report.
         */
        if (
            !this.report.feeCalculation.invoiceParameters._id &&
            (!this.report.feeCalculation.invoiceParameters.lineItems ||
                this.report.feeCalculation.invoiceParameters.lineItems.length === 0)
        ) {
            this.report.feeCalculation.invoiceParameters.lineItems = JSON.parse(
                JSON.stringify(this.team.preferences[feePreferencesKey].reportInvoiceLineItems || []),
            );
            this.saveReport();
        }
    }

    //*****************************************************************************
    //  Line Item Unit Autocomplete
    //****************************************************************************/
    public filterLineItemAutocomplete(searchTerm: string): void {
        this.filteredLineItemUnits = [...this.lineItemUnits];

        if (!searchTerm) return;

        this.filteredLineItemUnits = this.lineItemUnits.filter((entry) =>
            entry.label.toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }

    public insertLineItemUnitBasedOnQuantity(lineItem: LineItem, selectedLabel: string): void {
        const autocompleteEntry: LineItemUnitAutocompleteEntry = this.lineItemUnits.find(
            (entry) =>
                entry.label === selectedLabel ||
                entry.singularValue === selectedLabel ||
                entry.pluralValue === selectedLabel,
        );

        if (!autocompleteEntry) return;

        lineItem.unit = lineItem.quantity > 1 ? autocompleteEntry.pluralValue : autocompleteEntry.singularValue;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Line Item Unit Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    /******************************************************************************
     /* END Custom Line Items
     //////////////////////////////////////////////////////////////////////////////


     /******************************************************************************
     /* Fee Sets
     /*****************************************************************************/

    /**
     * Save current fee table as default in user preferences.
     */
    public setFeeTableAsUserDefault({
        feeTableName,
        customFeeTableId,
    }: {
        feeTableName: FeeTableName;
        customFeeTableId?: string;
    }) {
        switch (this.report.type) {
            case 'liability':
                this.team.preferences.defaultFeeTableLiability = feeTableName;
                if (feeTableName === 'custom') {
                    this.team.preferences.defaultCustomFeeTableIdLiability = customFeeTableId;
                }
                break;
            case 'partialKasko':
                this.team.preferences.defaultFeeTablePartialKasko = feeTableName;
                if (feeTableName === 'custom') {
                    this.team.preferences.defaultCustomFeeTableIdPartialKasko = customFeeTableId;
                }
                break;
            case 'fullKasko':
                this.team.preferences.defaultFeeTableFullKasko = feeTableName;
                if (feeTableName === 'custom') {
                    this.team.preferences.defaultCustomFeeTableIdFullKasko = customFeeTableId;
                }
                break;
        }

        this.saveTeam();
    }

    /**
     * Determine the fee sets that are relevant for the current damage value.
     */
    private findUpperAndLowerFeeSet() {
        // The fee sets are usually only used in liability/kasko reports, so no need to produce JavaScript type errors because of missing data in other report types.
        if (!this.showFeeSet(this.report) || !this.report.feeCalculation.selectedFeeTable) {
            return;
        }

        let feeTable: number[][];
        /**
         * Depending on the report setting, choose the right fee table to get the upper and
         * lower fee set from.
         */
        switch (this.report.feeCalculation.selectedFeeTable) {
            case 'BVSK':
                switch (this.report.feeCalculation.yearOfFeeTable) {
                    case 2022:
                        feeTable = bvskFeeTable2022.table;
                        break;
                    case 2024:
                    default:
                        feeTable = bvskFeeTable2024.table;
                        break;
                }
                break;
            case 'HUK':
                switch (this.report.feeCalculation.yearOfFeeTable) {
                    case 2021:
                        feeTable = hukFeeTable2021.table;
                        break;
                    case 2023:
                        feeTable = hukFeeTable2023.table;
                        break;
                    case 2025:
                    default:
                        feeTable = hukFeeTable2025.table;
                        break;
                }
                break;
            case 'VKS':
                switch (this.report.feeCalculation.yearOfFeeTable) {
                    case 2021:
                    default:
                        feeTable = vksFeeTable2021.table;
                        break;
                }
                break;
            case 'CGF':
                switch (this.report.feeCalculation.yearOfFeeTable) {
                    case 2023:
                    default:
                        feeTable = cgfFeeTable2023.table;
                        break;
                }
                break;
            case 'custom':
                if (!this.customFeeSet) return;
                feeTable = this.customFeeSet.fees.map((row) => [row.lowerLimit, row.fee]);
                break;
        }

        /**
         * Get the upper and lower fee sets for the report's damage value.
         *
         * As soon as the damage value of one set is higher than the report's damage cost,
         * the upper set must be this one and the lower set must be the one before.
         */

        // Special case: If report's damage value is lower than the first fee table row, take first 2 rows.
        if (this.baseForFeeCalculation <= feeTable[0][0]) {
            this.lowerFeeSet = feeTable[0];
            this.upperFeeSet = feeTable[1];
        }
        // Special case 2: If report's damage value is greater than the last table row, take last two rows.
        else if (this.baseForFeeCalculation > feeTable[feeTable.length - 1][0]) {
            const lastIndex = feeTable.length - 1;
            this.lowerFeeSet = feeTable[lastIndex - 1];
            this.upperFeeSet = feeTable[lastIndex];
        } else {
            for (let i = 0; i < feeTable.length; i++) {
                // As soon as the report's damage value is greater than the damage value in the i'th fee table row,
                // take that row and the one before as upper and lower limits.
                if (feeTable[i][0] >= this.baseForFeeCalculation) {
                    this.lowerFeeSet = feeTable[i - 1];
                    this.upperFeeSet = feeTable[i];

                    // Break as soon as the feeTable reaches a higher value than the damage value. Otherwise, the loop
                    // will keep going and overwrite the found row.
                    break;
                }
            }
        }
        this.setUpSliderValue();
    }

    /**
     * Display the caravan fee table if the report is for a caravan.
     * If the caravan fee table is already selected, keep it in the dropdown.
     */
    public isCaravanTableAllowed(): boolean {
        if (
            !this.report.car.shape ||
            this.report.car.shape === 'motorHome' ||
            this.report.car.shape === 'caravanTrailer'
        ) {
            return true;
        }
        return this.report.feeCalculation.selectedFeeTable === 'CGF';
    }

    /**
     * Is a newer version of the selected fee table available?
     * Will return false for custom fee tables.
     */
    public isNewerFeeTableAvailable(): boolean {
        const selectedFeeTable = this.report.feeCalculation.selectedFeeTable;
        const year = this.report.feeCalculation.yearOfFeeTable;

        switch (selectedFeeTable) {
            case 'BVSK':
                return year !== this.bvskFees.year;
            case 'HUK':
                return year !== this.hukFees.year;
            case 'VKS':
                return year !== this.vksFees.year;
            case 'CGF':
                return year !== this.cgfFees.year;
        }

        return false;
    }

    public selectFeeSet({
        feeTableName,
        yearOfFeeTable,
        customFeeSet,
    }: {
        feeTableName: Report['feeCalculation']['selectedFeeTable'];
        yearOfFeeTable: number; // 2024
        customFeeSet?: CustomFeeSet;
    }) {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Öffne es erneut, um Änderungen vorzunehmen.');
            return;
        }

        const { customFeeSet: selectedCustomFeeSet, error } = selectFeeSet({
            feeTableName,
            yearOfFeeTable,
            report: this.report,
            teamPreferences: this.team.preferences,
            selectedCustomFeeSet: customFeeSet,
        });
        this.customFeeSet = selectedCustomFeeSet;

        if (error === 'No custom fee set selected') {
            this.toastService.error(
                'Honorartabelle nicht verfügbar',
                'Die Tabelle konnte nicht geladen werden. Bitte versuche er später erneut oder kontaktiere die Hotline.',
            );
            return;
        }

        this.updateFeeTableBox();
        this.saveReport();
    }

    public determineFeeTable() {
        /**
         * Fee tables usually qualify for liability reports only. Kasko reports, valuations and short assessments do
         * not use fee tables.
         */
        if (this.report.type !== 'liability') {
            return;
        }

        /**
         * If the user already manually set a fee table for this report, use that. Otherwise use the user's default or
         * - if the user does not have one yet - use the current BVSK table.
         */
        this.report.feeCalculation.selectedFeeTable ||= this.team.preferences.defaultFeeTableLiability || 'BVSK';

        // One of our standard tables.
        if (this.report.feeCalculation.selectedFeeTable !== 'custom') {
            this.report.feeCalculation.yearOfFeeTable ||= getYearOfLatestFeeTable(
                this.report.feeCalculation.selectedFeeTable,
            );
        }
        // Custom table
        else {
            this.report.feeCalculation.yearOfFeeTable ||= this.customFeeSet?.year;
        }
        this.updateFeeTableBox();
    }

    public updateFeeTableBox(): void {
        this.findUpperAndLowerFeeSet();
        this.setHeaderElements();
    }

    /**
     * Depending on the selected fee set, the table in the head runner has different
     * column names. BVSK has 5 columns for instance, while HUK only comes with 3.
     */
    private setHeaderElements() {
        switch (this.report.feeCalculation.selectedFeeTable) {
            case 'BVSK':
                this.columnNames = this.bvskFeesColumnNames;
                break;
            case 'HUK':
                this.columnNames = this.hukFeesColumnNames;
                break;
            case 'VKS':
                this.columnNames = this.vksFeesColumnNames;
                break;
            case 'CGF':
                this.columnNames = this.cgfFeesColumnNames;
                break;
            case 'custom':
                this.columnNames = this.customFeeSetColumnNames;
        }
    }

    public getDefaultCustomFeeSetConfig() {
        return (
            this.team.preferences.customFeeConfigs?.find(
                (feeConfig) => feeConfig.customFeeSetId === this.customFeeSet._id,
            )?.row ?? 'higher'
        );
    }

    protected getSelectDefaultFeeSetTooltip(): string {
        return (
            'Als Standard setzen' +
            (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')
                ? '.\n\n' + getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                : '')
        );
    }

    public selectDefaultFeeSetField(
        provider: Report['feeCalculation']['selectedFeeTable'],
        row: DefaultFeeSetRow,
        column?: number,
    ): void {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        switch (provider) {
            case 'BVSK':
                this.team.preferences.bvskFeeConfig = {
                    row,
                    column,
                };
                break;
            case 'HUK':
                this.team.preferences.hukFeeConfig = row;
                break;
            case 'VKS':
                this.team.preferences.vksFeeConfig = {
                    row,
                    column,
                };
                break;
            case 'CGF':
                this.team.preferences.cgfFeeConfig = {
                    row,
                    column,
                };
                break;
            case 'custom':
                if (this.team.preferences.customFeeConfigs?.length > 0) {
                    const customFeeConfigIndex = this.team.preferences.customFeeConfigs?.findIndex(
                        (feeConfig) => feeConfig.customFeeSetId === this.customFeeSet._id,
                    );
                    if (customFeeConfigIndex >= 0) {
                        this.team.preferences.customFeeConfigs[customFeeConfigIndex].row = row;
                    } else {
                        this.team.preferences.customFeeConfigs.push(
                            new CustomFeeConfig({ row, customFeeSetId: this.customFeeSet._id }),
                        );
                    }
                } else {
                    this.team.preferences.customFeeConfigs = [
                        new CustomFeeConfig({ row, customFeeSetId: this.customFeeSet._id }),
                    ];
                }

                break;
            default:
                throw Error('FEE_SET_PROVIDER_MISMATCH');
        }

        this.saveTeam();
    }

    /**
     * Take the medium value in the selected feeSet by default.
     * Always set the min and max values. Don't set the assessorsFee if it has already been set.
     */
    public setUpSliderValue(overwriteUserInput = false): void {
        if (
            this.report.type === 'valuation' ||
            this.report.type === 'shortAssessment' ||
            this.report.type === 'oldtimerValuationSmall'
        )
            return;

        switch (this.report.feeCalculation.selectedFeeTable) {
            case 'BVSK':
                this.sliderMin = this.lowerFeeSet[1];
                this.sliderMax = this.upperFeeSet[3];
                break;
            case 'HUK':
                // The HUK values contain decimals, which messes up the slider. Round them.
                this.sliderMin = Math.floor(this.lowerFeeSet[2] - this.getAllAncillaryFeesForHukTable());
                this.sliderMax = Math.ceil(this.upperFeeSet[2] - this.getAllAncillaryFeesForHukTable());
                break;
            case 'VKS':
            case 'CGF':
                this.sliderMin = Math.floor(this.lowerFeeSet[1]);
                this.sliderMax = Math.ceil(this.upperFeeSet[2]);
                break;
            case 'custom':
                this.sliderMin = Math.floor(this.lowerFeeSet[1]);
                this.sliderMax = Math.ceil(this.upperFeeSet[1]);
                break;
        }

        /**
         * Only set the assessorsFee if
         * - the damage value is > zero and
         * - it has not been frozen by setting it manually.
         */
        if (
            ((this.baseForFeeCalculation > 0 && !this.report.feeCalculation.assessorsFeeFrozen) ||
                overwriteUserInput) &&
            !this.isReportLocked()
        ) {
            const oldFee = this.report.feeCalculation.assessorsFee;
            const newFee = this.getAssessorsFeeForSelectedFeeTable();

            // The new fee may be undefined if the fee set has not been loaded yet. Don't update the report then.
            if (newFee != undefined && oldFee !== newFee) {
                this.report.feeCalculation.assessorsFee = newFee;
                this.saveReport();
            }
        }
    }

    public getAppropriateHukBaseFee(invoiceTotal?: number) {
        if (this.report.feeCalculation.selectedFeeTable !== 'HUK') return;
        if (!this.upperFeeSet || !this.lowerFeeSet) return;

        if (typeof invoiceTotal === 'undefined') {
            /**
             * Special cases: The base fee lies exactly on one row instead between two rows, e.g. 11.000 €.
             */
            if (this.upperFeeSet[0] === this.baseForFeeCalculation) {
                invoiceTotal = this.upperFeeSet[2];
            } else if (this.lowerFeeSet[0] === this.baseForFeeCalculation) {
                invoiceTotal = this.lowerFeeSet[2];
            } else {
                // Get appropriate base fee depending on user's preferences
                if (this.team.preferences.hukFeeConfig === 'higher') {
                    invoiceTotal = this.upperFeeSet[2];
                } else {
                    invoiceTotal = this.lowerFeeSet[2];
                }
            }
        }

        return (
            invoiceTotal -
            this.getAllAncillaryFeesForHukTable() -
            this.getTotalOfAuxilliaryItemsIncludedInHukAssessorsFee()
        );
    }

    /**
     * Some custom line items are included within the HUK base fee, but shall be printed on the invoice separately
     * anyways. An included item reduces the HUK base fee by its amount, so that the invoice total matches the
     * specification of HUK.
     */
    public toggleOtherCostIncludedInHukBaseFee(lineItem: LineItem) {
        lineItem.includedInHukBaseFee = !lineItem.includedInHukBaseFee;
        this.setUpSliderValue(true);
    }

    /**
     * If an item of other costs was subtracted from the HUK base fee before, don't subtract it any more (= add it back
     * on top of the assessor's fee).
     */
    public excludeOtherCostFromHukBaseFee(lineItem: LineItem) {
        if (this.report.feeCalculation.selectedFeeTable !== 'HUK') {
            return;
        }
        if (!lineItem.active) {
            lineItem.includedInHukBaseFee = false;

            // The assessors fee may have been manually set, therefore we don't recalculate the entire fee but adjust it relatively.
            this.report.feeCalculation.assessorsFee =
                this.report.feeCalculation.assessorsFee + lineItem.unitPrice * lineItem.quantity;

            this.setUpSliderValue(true);
        }
    }

    private getTotalOfAuxilliaryItemsIncludedInHukAssessorsFee(): number {
        return this.report.feeCalculation.invoiceParameters.lineItems
            .filter((lineItem) => lineItem.includedInHukBaseFee && lineItem.active)
            .reduce((total, value) => total + value.quantity * value.unitPrice, 0);
    }

    public setAssessorsFeeByHukInvoiceTotalNet(invoiceTotalNet: number): void {
        this.report.feeCalculation.assessorsFee = this.getAppropriateHukBaseFee(invoiceTotalNet);
        this.saveReport();
    }

    public reportTypeHasFeeTable(): boolean {
        return new Array<Report['type']>('liability').includes(this.report.type);
    }

    public getAssessorsFeeForSelectedFeeTable(): number {
        let assessorsFee: number;

        // Custom fee sets must be loaded from the server first.
        if (!this.upperFeeSet || !this.lowerFeeSet) {
            return undefined;
        }

        switch (this.report.feeCalculation.selectedFeeTable) {
            // The different array indexes are due to the different table structures for each fee set.
            case 'HUK':
                assessorsFee = this.getAppropriateHukBaseFee();
                break;
            case 'BVSK': {
                const bvskColumn: DefaultFeeConfig['column'] = this.team.preferences.bvskFeeConfig.column;
                /**
                 * Special cases: The base fee lies exactly on one row instead between two rows, e.g. 11.000 €.
                 */
                if (this.upperFeeSet[0] === this.baseForFeeCalculation) {
                    assessorsFee = this.upperFeeSet[bvskColumn];
                } else if (this.lowerFeeSet[0] === this.baseForFeeCalculation) {
                    assessorsFee = this.lowerFeeSet[bvskColumn];
                } else {
                    // Get appropriate fee depending on user's preferences
                    if (this.team.preferences.bvskFeeConfig.row === 'higher') {
                        assessorsFee = this.upperFeeSet[bvskColumn];
                    } else {
                        assessorsFee = this.lowerFeeSet[bvskColumn];
                    }
                }
                break;
            }
            case 'VKS': {
                const vksColumn: DefaultFeeConfig['column'] = this.team.preferences.vksFeeConfig.column;
                /**
                 * Special cases: The base fee lies exactly on one row instead between two rows, e.g. 11.000 €.
                 */
                if (this.upperFeeSet[0] === this.baseForFeeCalculation) {
                    assessorsFee = this.upperFeeSet[vksColumn];
                } else if (this.lowerFeeSet[0] === this.baseForFeeCalculation) {
                    assessorsFee = this.lowerFeeSet[vksColumn];
                } else {
                    // Get appropriate fee depending on user's preferences
                    if (this.team.preferences.vksFeeConfig.row === 'higher') {
                        assessorsFee = this.upperFeeSet[vksColumn];
                    } else {
                        assessorsFee = this.lowerFeeSet[vksColumn];
                    }
                }
                break;
            }
            case 'CGF': {
                const cgfColumn: DefaultFeeConfig['column'] = this.team.preferences.cgfFeeConfig.column;
                /**
                 * Special cases: The base fee lies exactly on one row instead between two rows, e.g. 11.000 €.
                 */
                if (this.upperFeeSet[0] === this.baseForFeeCalculation) {
                    assessorsFee = this.upperFeeSet[cgfColumn];
                } else if (this.lowerFeeSet[0] === this.baseForFeeCalculation) {
                    assessorsFee = this.lowerFeeSet[cgfColumn];
                } else {
                    // Get appropriate fee depending on user's preferences
                    if (this.team.preferences.cgfFeeConfig.row === 'higher') {
                        assessorsFee = this.upperFeeSet[cgfColumn];
                    } else {
                        assessorsFee = this.lowerFeeSet[cgfColumn];
                    }
                }
                break;
            }
            case 'custom':
                /**
                 * Special cases: The base fee lies exactly on one row instead between two rows, e.g. 11.000 €.
                 */
                if (this.upperFeeSet[0] === this.baseForFeeCalculation) {
                    assessorsFee = this.upperFeeSet[1];
                } else if (this.lowerFeeSet[0] === this.baseForFeeCalculation) {
                    assessorsFee = this.lowerFeeSet[1];
                } else {
                    // Get appropriate fee depending on user's preferences
                    if (this.getDefaultCustomFeeSetConfig() === 'higher') {
                        assessorsFee = this.upperFeeSet[1];
                    } else {
                        assessorsFee = this.lowerFeeSet[1];
                    }
                }
                break;
        }
        return assessorsFee;
    }

    /**
     * The HUK table sets values for the sum of the base fee and all other typical costs. Custom line items, such as
     * Covid protection measures, may be charged extra.
     * @private
     */
    private getAllAncillaryFeesForHukTable(): number {
        return (
            getPhotosFeeTotal(this.report) +
            getTravelExpensesTotal(this.report) +
            getWritingFeesTotal(this.report) +
            getPostageAndPhoneFees(this.report)
        );
    }

    public showFeeSet(report: Report): boolean {
        return reportTypesWithFeeSets.includes(report.type);
    }

    //*****************************************************************************
    //  Custom Fee Set
    //****************************************************************************/
    public async createCustomFeeSet(): Promise<void> {
        if (this.isReportLocked()) {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Du musst das Gutachten öffnen, bevor du diese Einstellung verändern kannst.',
            );
            return;
        }

        this.customFeeSet = new CustomFeeSet({
            fees: [
                new CustomFeeSetRow({
                    lowerLimit: 500,
                    fee: 230,
                }),
                new CustomFeeSetRow({
                    lowerLimit: 750,
                    fee: 260,
                }),
                new CustomFeeSetRow({
                    lowerLimit: 1000,
                    fee: 300,
                }),
            ],
            availableInReportTypes: [this.report.type],
        });

        this.updateFeeTableBox();

        // open dialog, server sync in background
        this.openCustomFeeSetDialog(this.customFeeSet);

        try {
            await this.customFeeSetService.create(this.customFeeSet);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Honorartabelle nicht angelegt',
                    body: 'Die individuelle Honorartabelle konnte nicht auf dem Server erstellt werden. Bitte kontaktiere die Hotline.',
                },
            });
        }

        this.customFeeSets.push(this.customFeeSet);
        this.report.feeCalculation.selectedFeeTable = 'custom';
        this.report.feeCalculation.selectedCustomFeeTableId = this.customFeeSet._id;
        this.report.feeCalculation.yearOfFeeTable = this.customFeeSet.year;
        this.saveReport();
    }

    public getCustomFeeSets(): void {
        this.customFeeSetService.find().subscribe({
            next: (customFeeSets) => {
                this.customFeeSets = customFeeSets;
                if (this.report.feeCalculation.selectedFeeTable === 'custom') {
                    const customFeeSet = this.customFeeSets.find(
                        (feeSet) => feeSet._id === this.report.feeCalculation.selectedCustomFeeTableId,
                    );
                    this.customFeeSetLoading = false;
                    if (!customFeeSet) {
                        this.toastService.error(
                            'Honorartabelle nicht geladen',
                            'Die Tabelle konnte nicht geladen werden. Bitte versuche er später erneut oder kontaktiere die Hotline.',
                        );
                        return;
                    }
                    this.customFeeSet = customFeeSet;

                    // Update year if necessary.
                    if (this.report.feeCalculation.yearOfFeeTable !== this.customFeeSet.year) {
                        this.report.feeCalculation.yearOfFeeTable = this.customFeeSet.year;
                        void this.saveReport();
                    }
                }
                this.updateFeeTableBox();
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Honorartabelle nicht geladen',
                        body: 'Die Tabelle konnte nicht geladen werden. Bitte versuche er später erneut oder kontaktiere die Hotline.',
                    },
                });
            },
        });
    }

    public async saveCustomFeeSet(feeSet: CustomFeeSet): Promise<CustomFeeSet> {
        try {
            return await this.customFeeSetService.put(feeSet);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Honorartabelle nicht gespeichert',
                    body: 'Bitte prüfe deine Eingaben. Bleibt der Fehler bestehen, kontaktiere die Hotline.',
                },
            });
        }
    }

    public async deleteCustomFeeSet(customFeeSet: CustomFeeSet): Promise<void> {
        // If the fee set has already been deleted, don't trigger a second delete.
        if (!customFeeSet) {
            return;
        }

        // Remove custom Fee Set Config from user Preferences
        const customFeeConfigIndex = this.team.preferences.customFeeConfigs?.findIndex(
            (feeConfig) => feeConfig.customFeeSetId === customFeeSet._id,
        );
        if (customFeeConfigIndex >= 0) {
            this.team.preferences.customFeeConfigs.splice(customFeeConfigIndex, 1);
        }

        this.saveTeam();

        try {
            await this.customFeeSetService.delete(customFeeSet._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Honorartabelle nicht gelöscht',
                    body: 'Bitte kontaktiere die Hotline.',
                },
            });
        }

        const indexOfCustomFeeSet = this.customFeeSets.indexOf(customFeeSet);
        this.customFeeSets.splice(indexOfCustomFeeSet, 1);

        if (customFeeSet === this.customFeeSet) {
            const customFeeSetsForReportType = this.customFeeSets.filter((feeSet) =>
                feeSet.availableInReportTypes.includes(this.report.type),
            );
            if (customFeeSetsForReportType.length > 0) {
                this.customFeeSet = customFeeSetsForReportType[0];
                this.report.feeCalculation.selectedCustomFeeTableId = this.customFeeSet._id;
                this.report.feeCalculation.yearOfFeeTable = this.customFeeSet.year;
            } else {
                this.customFeeSet = null;
                this.report.feeCalculation.selectedCustomFeeTableId = null;
                this.report.feeCalculation.selectedFeeTable = 'BVSK';
                this.report.feeCalculation.yearOfFeeTable = getYearOfLatestFeeTable('BVSK');
            }

            this.saveReport();
            this.updateFeeTableBox();
        }

        // Set a new default fee set for this report type if the deleted fee set was the default
        let wasDefaultTable = false;
        switch (this.report.type) {
            case 'liability':
                if (this.team.preferences.defaultCustomFeeTableIdLiability === customFeeSet._id) {
                    this.team.preferences.defaultFeeTableLiability = 'BVSK';
                    this.team.preferences.defaultCustomFeeTableIdLiability = null;
                    wasDefaultTable = true;
                }
                break;
            case 'partialKasko':
                if (this.team.preferences.defaultCustomFeeTableIdPartialKasko === customFeeSet._id) {
                    this.team.preferences.defaultFeeTablePartialKasko = 'BVSK';
                    this.team.preferences.defaultCustomFeeTableIdPartialKasko = null;
                    wasDefaultTable = true;
                }
                break;
            case 'fullKasko':
                if (this.team.preferences.defaultCustomFeeTableIdFullKasko === customFeeSet._id) {
                    this.team.preferences.defaultFeeTableFullKasko = 'BVSK';
                    this.team.preferences.defaultCustomFeeTableIdFullKasko = null;
                    wasDefaultTable = true;
                }
                break;
        }

        if (wasDefaultTable) {
            this.saveTeam();
            this.toastService.info(
                'Standardtabelle gelöscht',
                'Die gelöschte Honorartabelle war als Standard ausgewählt. Bitte wähle eine andere Tabelle als Standard, ansonsten wird die BVSK-Tabelle für neue Gutachten verwendet.',
                { timeOut: 10000 },
            );
        }
    }

    public openCustomFeeSetDialog(customFeeSetItem: CustomFeeSet): void {
        this.customFeeSetDialogShown = true;
        this.customFeeSetForEditDialog = customFeeSetItem;
    }

    public closeCustomFeeSetDialog(): void {
        this.customFeeSetDialogShown = false;
        this.customFeeSetForEditDialog = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Fee Set
    /////////////////////////////////////////////////////////////////////////////*/

    /******************************************************************************
     /* END Fee Sets
     /*****************************************************************************/

    //*****************************************************************************
    //  Travel Expenses
    //****************************************************************************/
    public calculateTraveledKilometers(event: MouseEvent): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Du kannst die Strecke zur Besichtigung ermitteln, sobald du wieder online bist.',
            );
            return;
        }

        if (event.ctrlKey || event.metaKey) {
            this.openGoogleMaps();
            return;
        }

        // TODO Get the distance traveled by the selected assessor, not by the location of the user clicking the icon
        const { origins, destinations } = this.getOriginsAndDestinationsForReport();

        if (!destinations.length) {
            this.toastService.info('Bitte gib zuerst die Anschrift mindestens einer Besichtigung an.');
            return;
        }

        this.traveledDistanceCalculationPending = true;

        this.distanceService.getTotalDistance(origins, destinations).subscribe({
            next: (totalDistanceInMeters) => {
                // Convert m -> km and round; Multiply by 2 because the assessor traveled the distance back and forth
                this.report.feeCalculation.numberOfKilometers = Math.round(totalDistanceInMeters / 1000);
                this.saveReport();
                this.traveledDistanceCalculationPending = false;
            },
            error: (error) => {
                this.traveledDistanceCalculationPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Entfernung konnte nicht ermittelt werden',
                        body: 'Bitte prüfe, ob Adressdaten in den Besichtigungen und in deinen Einstellungen hinterlegt sind.',
                    },
                });
            },
        });
    }

    public getOriginsAndDestinationsForReport(): { origins: Place[]; destinations: Place[] } {
        const userContactPerson = getFullUserContactPerson({
            user: this.user,
            officeLocations: this.team.officeLocations,
            officeLocationId: this.report.officeLocationId,
        });

        const originAddress = {
            streetAndHouseNumber: userContactPerson.streetAndHouseNumberOrLockbox,
            zip: userContactPerson.zip,
            city: userContactPerson.city,
        };
        const origins: Place[] = [];
        const destinations: Place[] = [];
        const visitsWithCompleteAddress = this.report.visits
            // Filter out visits with an empty location
            .filter((visit) => visit.street || visit.zip || visit.city);
        for (const visit of visitsWithCompleteAddress) {
            const visitAddress = {
                streetAndHouseNumber: visit.street,
                zip: visit.zip,
                city: visit.city,
            };
            origins.push(originAddress);
            destinations.push(visitAddress);
            /**
             * Calculate the distance there and back. If the origins address were not included for the return trip and
             * autoiXpert would simply double the outward trip, small differences in the distance would arise if
             * Autobahn entries and exits would are located in different locations, e.g. because one location only has
             * an entry but no exit.
             */
            origins.push(visitAddress);
            destinations.push(originAddress);
        }

        return {
            origins,
            destinations,
        };
    }

    /**
     * Open Google Maps with directions to all visits locations
     */
    public openGoogleMaps(): void {
        const { origins, destinations } = this.getOriginsAndDestinationsForReport();

        openDirectionsOnGoogleMaps({
            origins,
            destinations,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Travel Expenses
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Writing Fees
    //****************************************************************************/
    public async determineNumberOfReportPdfPages(event: MouseEvent) {
        if (this.hasReportDocumentCustomUpload()) {
            this.toastService.error('Seiten nicht zählbar', this.getDetermineNumberOfReportPdfPagesTooltip());
            return;
        }
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Du kannst die Anzahl der Seiten zählen lassen, sobald du wieder online bist.',
            );
            return;
        }

        if (event.ctrlKey || event.metaKey) {
            void this.downloadPdfForCountingPages();
            return;
        }

        this.numberOfPagesPending = true;

        try {
            const { numberOfPages } = await this.httpClient
                .get<{ numberOfPages: number }>(`/api/v0/reports/${this.reportId}/pageCount`)
                .toPromise();

            this.report.feeCalculation.numberOfPages = numberOfPages;
            void this.saveReport();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Anzahl Seiten nicht ermittelt',
                    body: 'Bitte versuche es erneut. Sollte das Problem bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        } finally {
            this.numberOfPagesPending = false;
        }
    }

    public async downloadPdfForCountingPages() {
        this.numberOfPagesPending = true;

        try {
            const response = await this.httpClient
                .get(
                    `/api/v0/reports/${this.reportId}/documents/report?format=pdf&renderDocumentForCountingPages=true`,
                    {
                        responseType: 'blob',
                        observe: 'response',
                    },
                )
                .toPromise();

            this.downloadService.downloadBlobResponseWithHeaders(response);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Dokument für das Zählen von Seiten nicht herunterladbar',
                    body: 'Bitte versuche es erneut. Sollte das Problem bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        } finally {
            this.numberOfPagesPending = false;
        }
    }

    public hasReportDocumentCustomUpload(): boolean {
        const reportDocumentMetadata: DocumentMetadata = this.report.documents.find(
            (document) => document.type === 'report',
        );

        return !!reportDocumentMetadata?.uploadedDocumentId;
    }

    public getDetermineNumberOfReportPdfPagesTooltip(): string {
        if (this.hasReportDocumentCustomUpload()) {
            return `Seiten können nicht gezählt werden, weil ein individuelles Gutachten-Dokument hochgeladen wurde. Für das Zählen muss auf die Textbausteine zurückgegriffen werden.

Bitte zähle die Seiten der PDF-Datei manuell oder entferne deine eigene PDF.`;
        } else {
            return `Seiten zählen.

Du kannst über die Einstellungen der Textbausteine definieren, welche vom Zählen ausgeschlossen werden sollen.
Die Urteile der Gerichte dazu gehen auseinander. Oft geht es um diese Bausteine:
- Deckblatt
- Inhaltsverzeichnis
- Kalkulation
- Fotoanlage

Standardmäßig werden die Fotoanlage und die Kalkulationsseiten ausgeklammert.

Strg + Klick lädt das Dokument herunter, das für die Zählung verwendet wird.`;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Writing Fees
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Fee Items
    //****************************************************************************/
    public handleReorderingDrop(event: CdkDragDrop<LineItem>) {
        // Extract from the array...
        const customLineItem = this.report.feeCalculation.invoiceParameters.lineItems.splice(event.previousIndex, 1)[0];
        // ... and insert at new position.
        this.report.feeCalculation.invoiceParameters.lineItems.splice(event.currentIndex, 0, customLineItem);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Fee Items
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sync with Server
    //****************************************************************************/
    /**
     * Save reports to the server.
     */
    public async saveReport(): Promise<void> {
        // Every time the report is saved, the invoice params or the feeCalculation object could have changed
        this.generateInvoiceFromInvoiceParameters();

        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
        }
    }

    public saveTeam(): void {
        this.teamService.put(this.team).catch((error) => {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Einstellungen nicht gespeichert',
                    body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sync with Server
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Preferences
    //****************************************************************************/
    public rememberDefaultFees(): void {
        const preferencesKey: FeePreferencesKey = determineFeePreferencesKey(
            this.report.type,
            this.report.feeCalculation.selectedFeeTable,
        );

        const newFeePreferences: FeePreferences = {
            usePhotosFixedPrice: this.report.feeCalculation.usePhotosFixedPrice,
            photosFixedPrice: this.report.feeCalculation.photosFixedPrice,
            pricePerPhoto: this.report.feeCalculation.pricePerPhoto,
            secondPhotoSet: this.report.feeCalculation.secondPhotoSet,
            pricePerSecondPhoto: this.report.feeCalculation.pricePerSecondPhoto,
            secondPhotoSetFixedPrice: this.report.feeCalculation.secondPhotoSetFixedPrice,
            useTravelExpensesFixedPrice: this.report.feeCalculation.useTravelExpensesFixedPrice,
            travelExpensesFixedPrice: this.report.feeCalculation.travelExpensesFixedPrice,
            pricePerKilometer: this.report.feeCalculation.pricePerKilometer,
            pricePerPage: this.report.feeCalculation.pricePerPage,
            pricePerPageCopy: this.report.feeCalculation.pricePerPageCopy,
            useWritingFeesFixedPrice: this.report.feeCalculation.useWritingFeesFixedPrice,
            useWritingCopyFees: this.report.feeCalculation.useWritingCopyFees,
            writingFeesFixedPrice: this.report.feeCalculation.writingFeesFixedPrice,
            writingCopyFeesFixedPrice: this.report.feeCalculation.writingCopyFeesFixedPrice,
            postageAndPhoneFees: this.report.feeCalculation.postageAndPhoneFees,
            // Only remember line items with a description
            reportInvoiceLineItems: JSON.parse(
                JSON.stringify(
                    this.report.feeCalculation.invoiceParameters.lineItems.filter((customFee) => customFee.description),
                ),
            ),
        };

        this.team.preferences[preferencesKey] = {
            ...this.team.preferences[preferencesKey],
            ...newFeePreferences,
        };

        this.saveTeam();

        this.defaultFeesUpdatedIconShown = true;
        window.setTimeout(() => {
            this.defaultFeesUpdatedIconShown = false;
        }, 1000);
    }

    public rememberAssessorsFee(): void {
        switch (this.report.type) {
            case 'valuation':
                this.userPreferences.valuationFee = this.report.feeCalculation.assessorsFee;
                break;
            case 'leaseReturn':
                this.userPreferences.leaseReturnFee = this.report.feeCalculation.assessorsFee;
                break;
            case 'usedVehicleCheck':
                this.userPreferences.usedVehicleCheckFee = this.report.feeCalculation.assessorsFee;
                break;
            case 'oldtimerValuationSmall':
                this.userPreferences.oldtimerValuationSmallFee = this.report.feeCalculation.assessorsFee;
                break;
            case 'shortAssessment':
                this.userPreferences.shortAssessmentFee = this.report.feeCalculation.assessorsFee;
                break;
            case 'invoiceAudit':
                this.userPreferences.invoiceAuditFee = this.report.feeCalculation.assessorsFee;
                break;
        }

        this.feeUpdatedIconShown = true;
        window.setTimeout(() => {
            this.feeUpdatedIconShown = false;
        }, 1000);
    }

    public getDefaultDaysUntilDueConfig(): DefaultDaysUntilDueConfig {
        return this.team.preferences.defaultDaysUntilDueConfigs.find((entry) => entry.reportType === this.report.type);
    }

    public async saveDaysUntilDueToTeamPreferences(): Promise<void> {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        const defaultDaysUntilDueConfig: DefaultDaysUntilDueConfig = this.getDefaultDaysUntilDueConfig();

        /**
         * Update the existing config or add a new one.
         */
        if (defaultDaysUntilDueConfig) {
            defaultDaysUntilDueConfig.numberOfDays = this.report.feeCalculation.invoiceParameters.daysUntilDue;
        } else {
            this.team.preferences.defaultDaysUntilDueConfigs.push(
                new DefaultDaysUntilDueConfig({
                    reportType: this.report.type,
                    numberOfDays: this.report.feeCalculation.invoiceParameters.daysUntilDue,
                }),
            );
        }

        await this.saveTeam();
        this.toastService.success('Zahlungsziel wurde gemerkt.');
    }

    public async removeDaysUntilDueFromTeamPreferences(): Promise<void> {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        const defaultDaysUntilDueConfig: DefaultDaysUntilDueConfig = this.getDefaultDaysUntilDueConfig();

        if (defaultDaysUntilDueConfig) {
            removeFromArray(defaultDaysUntilDueConfig, this.team.preferences.defaultDaysUntilDueConfigs);
            await this.saveTeam();
            this.toastService.success('Zahlungsziel wurde entfernt.');
        }
    }

    public insertDefaultDaysUntilDue(): void {
        this.report.feeCalculation.invoiceParameters.daysUntilDue = getDefaultDaysUntilDue({
            team: this.team,
            reportType: this.report.type,
        });
    }

    public translateReportType(): ReportTypeGerman {
        return translateReportType(this.report.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Preferences
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Photo File from Server
    //****************************************************************************/
    /**
     * Load all photos from the server (including the correct authentication header) and save them to local blobs.
     */
    private async initializePhotoFiles(): Promise<void> {
        // get the thumbnails of the first two photos
        const firstTwoPhotos = this.reportPhotos.slice(0, 2);
        for (const photo of firstTwoPhotos) {
            // If the photo already exists, don't query a second time.
            if (this.localThumbnailSafeFileUrlConfigs.has(photo._id)) {
                continue;
            }
            try {
                const renderedPhotoBlob: Blob = await this.renderedPhotoFileService.getFile({
                    reportId: this.report._id,
                    photo,
                    version: 'report',
                    format: 'thumbnail400',
                });
                const localBlobUrl: string = window.URL.createObjectURL(renderedPhotoBlob);
                this.localThumbnailSafeFileUrlConfigs.set(photo._id, {
                    url: localBlobUrl,
                    safeUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(localBlobUrl),
                });
            } catch (error) {
                console.error('Error retrieving the thumbnail file.', { error });
            }
        }
    }

    /**
     * Return either the local photo URI or null if the photo has not yet been loaded from the server.
     * @param {string} photoId
     * @return {SafeResourceUrl}
     */
    public hasLocalThumbnailUrl(photoId: string): boolean {
        return this.localThumbnailSafeFileUrlConfigs.has(photoId);
    }

    public getLocalThumbnailUrl(photoId: string): SafeResourceUrl {
        return this.localThumbnailSafeFileUrlConfigs.has(photoId)
            ? this.localThumbnailSafeFileUrlConfigs.get(photoId).safeUrl
            : null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Photo File from Server
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoicing
    //****************************************************************************/
    public async toggleSkipWritingInvoice() {
        if (this.isReportLocked()) {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Du musst das Gutachten öffnen, bevor du diese Einstellung verändern kannst.',
            );
            return;
        }

        // About to activate skipping invoice but invoice number has already been assigned -> possibility of hole in number range.
        if (!this.report.feeCalculation.skipWritingInvoice && this.report.feeCalculation.invoiceParameters.number) {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Mögliches Loch im Rechnungskreis',
                        content: `Du hast in diesem Gutachten bereits die Rechnungsnummer ${this.report.feeCalculation.invoiceParameters.number} vergeben.<br> Wenn du die Rechnung nicht erzeugst, bleibt möglicherweise ein Loch im Rechnungskreis zurück.<br><br>
Vergib die Rechnungsnummer manuell einem anderen Gutachten, wenn du kein Loch produzieren möchtest.`,
                        confirmLabel: 'Loch ist OK',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: false,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) return;
        }

        const isInvoiceNumberLeading = this.reportTokenService.isInvoiceNumberSyncedAndLeading(this.report);
        // About to activate skipping invoice (with no invoice number yet) but user has synced report + invoice numbers. In case
        // the user generates a report number or locks report, an unused invoice number would be generated.
        if (
            !this.report.feeCalculation.skipWritingInvoice &&
            isInvoiceNumberLeading &&
            !this.report.feeCalculation.invoiceParameters.number &&
            !this.report.token
        ) {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Mögliches Loch im Rechnungskreis',
                        content: `Du hast eingestellt, dass der Zähler für das Aktenzeichen und die Rechnungsnummer synchron sind. Wenn du die Rechnung für dieses Gutachten deaktivierst, wird autoiXpert für dieses Gutachten trotzdem ein Aktenzeichen generieren. Für diesen Zähler gibt es aber keine Rechnung - das heißt, du hast ein Loch in deinen Rechnungsnummern.
Das kannst du verhindern, indem du für dieses Gutachten manuell ein Aktenzeichen vergibst.`,
                        confirmLabel: 'Habe ich verstanden',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: false,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) return;
        }

        this.report.feeCalculation.skipWritingInvoice = !this.report.feeCalculation.skipWritingInvoice;

        // If the user wants to skip the invoice, remove the invoice document.
        if (this.report.feeCalculation.skipWritingInvoice) {
            const invoiceDocument = this.report.documents.find((document) => document.type === 'invoice');
            removeDocumentFromReport({
                report: this.report,
                documentGroup: 'report',
                document: invoiceDocument,
            });

            // Ask the user for a reason why the invoice is skipped
            this.showSkipWritingInvoiceReasonDialog();
        } else {
            // The invoice is currently always taken from the default liability documents.
            const invoiceDocumentTemplate = defaultDamageReportDocuments.find(
                (document) => document.type === 'invoice',
            );

            const invoiceDocument = new DocumentMetadata({
                ...invoiceDocumentTemplate,
                createdBy: this.user._id,
            });

            //  Don't add if an invoice already exists.
            const existingInvoiceDocument = this.report.documents.find((document) => document.type === 'invoice');
            if (!existingInvoiceDocument) {
                addDocumentToReport({
                    team: this.team,
                    report: this.report,
                    newDocument: invoiceDocument,
                    documentGroup: 'report',
                });
            }

            // Clear the skipWritingInvoiceReason after activating the invoice again
            this.report.feeCalculation.skipWritingInvoiceReason = null;
        }

        this.saveReport();
    }

    protected showSkipWritingInvoiceReasonDialog() {
        this.dialog
            .open(PromptDialogComponent, {
                data: {
                    heading: 'Grund erfassen',
                    content: 'Warum soll keine Rechnung geschrieben werden?',
                    helpIndicatorText:
                        'Hilft dir im Falle einer Betriebsprüfung nachzuvollziehen, warum eine Leistung ohne Rechnung erbracht wurde und deshalb möglicherweise eine Rechnungsnummer fehlt.\n\nDie Begründung erscheint nur im Gutachten-Export und wird nicht abgedruckt.',
                    placeholder: 'Grund',
                    confirmLabel: 'Übernehmen',
                    cancelLabel: 'Abbrechen',
                    initialInputValue: this.report.feeCalculation.skipWritingInvoiceReason,
                    maxWidth: 400,
                    inputFieldWidth: '100%',
                    optional: true,
                    autocompleteEntryType: 'skipWritingInvoiceReason',
                } as PromptDialogData,
            })
            .afterClosed()
            .subscribe((response) => {
                if (response?.userInput) {
                    this.report.feeCalculation.skipWritingInvoiceReason = response.userInput;
                    this.saveReport();
                }
            });
    }

    protected async toggleCollectiveInvoice() {
        if (this.isReportLocked()) {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Du musst das Gutachten öffnen, bevor du diese Einstellung verändern kannst.',
            );
            return;
        }

        // About to activate skipping invoice but invoice number has already been assigned -> possibility of hole in number range.
        if (!this.report.feeCalculation.isCollectiveInvoice && this.report.feeCalculation.invoiceParameters.number) {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Mögliches Loch im Rechnungskreis',
                        content: `Du hast in diesem Gutachten bereits die Rechnungsnummer ${this.report.feeCalculation.invoiceParameters.number} vergeben.<br> Wenn du die Rechnung nicht erzeugst, bleibt möglicherweise ein Loch im Rechnungskreis zurück.<br><br>
Vergib die Rechnungsnummer manuell einem anderen Gutachten, wenn du kein Loch produzieren möchtest.`,
                        confirmLabel: 'Loch ist OK',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: false,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) return;
        }

        this.report.feeCalculation.isCollectiveInvoice = !this.report.feeCalculation.isCollectiveInvoice;

        if (this.report.feeCalculation.isCollectiveInvoice) {
            const invoiceRecipient = getInvoiceRecipientByRole(
                this.report.feeCalculation.invoiceParameters.recipientRole ?? 'claimant',
                this.report,
            );
            if (!invoiceRecipient.receivesCollectiveInvoice) {
                this.showRememberCollectiveInvoiceSettingsInfoNote = true;
            }
        }

        // If the user wants to skip the invoice, remove the invoice document.
        if (this.report.feeCalculation.isCollectiveInvoice) {
            const invoiceDocument = this.report.documents.find((document) => document.type === 'invoice');
            removeDocumentFromReport({
                report: this.report,
                documentGroup: 'report',
                document: invoiceDocument,
            });
        } else {
            // The invoice is currently always taken from the default liability documents.
            const invoiceDocumentTemplate = defaultDamageReportDocuments.find(
                (document) => document.type === 'invoice',
            );

            const invoiceDocument = new DocumentMetadata({
                ...invoiceDocumentTemplate,
                createdBy: this.user._id,
            });

            //  Don't add if an invoice already exists.
            const existingInvoiceDocument = this.report.documents.find((document) => document.type === 'invoice');
            if (!existingInvoiceDocument) {
                addDocumentToReport({
                    team: this.team,
                    report: this.report,
                    newDocument: invoiceDocument,
                    documentGroup: 'report',
                });
            }
        }

        this.saveReport();
    }

    protected openCollectiveInvoiceHelpPage(event: Event) {
        this.newWindowService.open('https://wissen.autoixpert.de/hc/de/articles/25615213028882', '_blank', 'noopener');
        event.preventDefault();
        event.stopPropagation();
    }

    //*****************************************************************************
    //  Invoice Recipient
    //****************************************************************************/
    private determineInvoiceRecipient(): void {
        this.invoiceRecipient = getInvoiceRecipientByRole(
            this.report.feeCalculation.invoiceParameters.recipientRole,
            this.report,
        );
    }

    public setInvoiceRecipientRole(recipientRole: InvoiceParameters['recipientRole']): void {
        if (this.isReportLocked()) {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Falls du den Empfänger verändern möchtest, öffne zuerst das Gutachten.',
            );
            return;
        }

        this.report.feeCalculation.invoiceParameters.recipientRole = recipientRole;
        this.determineInvoiceRecipient();
    }

    public showInvoiceRecipientContactPersonEditor(): void {
        if (this.isReportLocked()) {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Falls du den Empfänger verändern möchtest, öffne zuerst das Gutachten.',
            );
            return;
        }

        if (!this.report.feeCalculation.invoiceParameters.recipient) {
            this.report.feeCalculation.invoiceParameters.recipient = new ContactPerson();
        }
        this.invoiceRecipientContactPersonEditorShown = true;
    }

    public hideInvoiceRecipientContactPersonEditor(): void {
        this.invoiceRecipientContactPersonEditorShown = false;
    }

    public getClaimantDenomination() {
        return Translator.claimantDenomination(this.report.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Recipient
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Number
    //****************************************************************************/

    /**
     * Generate a new invoice number and save the counter back to the server.
     */
    public async generateInvoiceNumber() {
        if (this.isReportLocked()) {
            return;
        }

        // Since we wouldn't always use the up-to-date counter, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Nur online kann sichergestellt werden, dass der Zähler über alle Geräte hinweg eindeutig vergeben wird.',
            );
            return;
        }

        // Focus the input so that the label does not "jump"
        this.invoiceNumberElement.nativeElement.focus();

        this.generateInvoiceNumberRequestPending = true;

        /**
         * Generate either:
         * - both invoice number and report token
         * - just the invoice number
         */
        const isInvoiceNumberLeading = this.reportTokenService.isInvoiceNumberSyncedAndLeading(this.report);

        // generate invoice number and report token
        if (isInvoiceNumberLeading) {
            try {
                const { reportToken, invoiceNumber } =
                    await this.reportTokenAndInvoiceNumberService.generateReportTokenAndInvoiceNumber(this.report);
                this.reportTokenAndInvoiceNumberService.writeToReport(this.report, reportToken, invoiceNumber);

                const reportTokenConfig = this.reportTokenService.getReportTokenConfig(this.report.officeLocationId);
                await this.invoiceNumberJournalEntryService.create({
                    entryType: 'invoiceNumberGeneratedManually',
                    documentType: 'report',
                    invoiceNumber,
                    reportId: this.report._id,
                    reportTokenConfigId: reportTokenConfig._id,
                });
            } catch (error) {
                this.generateInvoiceNumberRequestPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getInvoiceNumberOrReportTokenCounterErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'Rechnungsnummer & Aktenzeichen nicht generiert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            }
        }
        // Invoice number only
        else {
            try {
                const invoiceNumber = await this.invoiceNumberService.generateInvoiceNumber({
                    officeLocationId: this.report.officeLocationId,
                    responsibleAssessorId: this.report.responsibleAssessor,
                    report: this.report,
                });
                this.report.feeCalculation.invoiceParameters.number = invoiceNumber;

                const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(
                    this.report.officeLocationId,
                );
                await this.invoiceNumberJournalEntryService.create({
                    entryType: 'invoiceNumberGeneratedManually',
                    documentType: 'report',
                    invoiceNumber,
                    reportId: this.report._id,
                    invoiceNumberConfigId: invoiceNumberConfig._id,
                });
            } catch (error) {
                this.generateInvoiceNumberRequestPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getInvoiceNumberOrReportTokenCounterErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'Rechnungsnummer nicht generiert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            }
        }
        this.generateInvoiceNumberRequestPending = false;

        /** Check if invoice number already exists. */
        const invoicesWithSameNumber = await this.invoiceService.findByInvoiceNumber({
            invoiceNumber: this.report.feeCalculation.invoiceParameters.number,
            invoiceDate: this.report.feeCalculation.invoiceParameters.date || todayIso(),
        });
        if (invoicesWithSameNumber.length > 0) {
            this.toastService.error(
                'Doppelte Rechnungsnummer',
                `Es gibt bereits ${
                    invoicesWithSameNumber.length > 1
                        ? invoicesWithSameNumber.length + ' Rechnungen'
                        : '<a href="/Rechnungen/' + invoicesWithSameNumber[0]._id + '"> eine Rechnung</a>'
                } mit gleicher Rechnungsnummer. Mögliche Ursachen:
            - Zähler manuell zurückgesetzt
            - Nummer bereits manuell vergeben
            - Zählermuster ist ohne Zähler konfiguriert
            
            <a href='/Einstellungen#invoice-number-container'>Einstellungen öffnen</a>`,
                {
                    clickToClose: true,
                },
            );
        }

        await this.saveReport();
    }

    /**
     * If a user manually changes the invoice number, adjust the report token accordingly in either of two ways:
     * - extract: Extract the parts of the invoice number according to the pattern from the invoice number definition
     * and fill the parts into the token.
     * - overwrite: Simply copy the full invoice number to the report token.
     * @param force Fill the target despite a value existing there.
     * @param extractOrOverwrite - extract parts or simply copy and overwrite.
     */
    public async adjustReportTokenToInvoiceNumber({
        force,
        extractOrOverwrite,
    }: {
        force?: boolean;
        extractOrOverwrite: 'extract' | 'overwrite';
    }) {
        // Document any manual changes to the invoice number.
        if (!force && extractOrOverwrite === 'extract') {
            const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.report.officeLocationId);
            void this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceNumberChangedManually',
                documentType: 'report',
                invoiceNumber: this.report.feeCalculation.invoiceParameters.number,
                previousInvoiceNumber: this.lastInvoiceNumber,
                reportId: this.report._id,
                invoiceNumberConfigId: invoiceNumberConfig._id,
            });
        }

        /**
         * Only adjust the report token if the invoice number was not deleted.
         */
        if (!this.report.feeCalculation.invoiceParameters.number) {
            return;
        }
        /**
         * Only adjust the report token if it should be synced with the invoice number.
         */
        const isInvoiceNumberLeading = this.reportTokenService.isInvoiceNumberSyncedAndLeading(this.report);
        if (!isInvoiceNumberLeading) {
            return;
        }

        if (this.report.token && !force) {
            this.wasAdjustingReportTokenToInvoiceNumberPrevented = true;
            return;
        }

        let reportTokenConfig: ReportTokenConfig;
        let invoiceNumberConfig: InvoiceNumberConfig;

        if (extractOrOverwrite === 'extract') {
            try {
                reportTokenConfig = this.reportTokenService.getReportTokenConfig(this.report.officeLocationId);
                invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.report.officeLocationId);
            } catch (error) {
                /**
                 * If the user did not explicitly requested to update the invoice number, do not display an error
                 * message. An error may happen if a user has changed his report token config and revisits the report
                 * to test the new settings. This could happen with new customers / testers which would be confused by
                 * the error message.
                 */
                if (!force) {
                    return;
                }
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getInvoiceNumberOrReportTokenCounterErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'Konfiguration des Aktenzeichens & der Rechnungsnummer nicht geladen',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            }

            let invoiceNumberPlaceholderValues: ReportTokenOrInvoiceNumberPlaceholderValues;
            try {
                const invoiceNumberPattern = new CounterPattern(invoiceNumberConfig.pattern);
                invoiceNumberPlaceholderValues = invoiceNumberPattern.extractPlaceholderValues(
                    this.report.feeCalculation.invoiceParameters.number,
                );
            } catch (error) {
                /**
                 * If the user did not explicitly requested to update the invoice number, do not display an error
                 * message. An error may happen if a user has changed his report token config and revisits the report
                 * to test the new settings. This could happen with new customers / testers which would be confused by
                 * the error message.
                 */
                if (!force) {
                    return;
                }
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Rechnungsnummer weicht von Muster ab',
                        body: `Bitte stelle sicher, dass deine Rechnungsnummer dem Muster entspricht, das du in den <a href='/Einstellungen'>Einstellungen</a> definiert hast.<br><br>Aktuelles Muster: ${invoiceNumberConfig.pattern}`,
                    },
                });
            }

            try {
                const reportTokenPattern = new CounterPattern(reportTokenConfig.pattern);

                const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
                    reportId: this.report._id,
                });
                const fieldGroupConfigs =
                    await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
                this.report.token = reportTokenPattern.replaceAllPlaceholders({
                    shorthandPlaceholderValues: invoiceNumberPlaceholderValues,
                    regularPlaceholderValues: placeholderValues,
                    fieldGroupConfigs: fieldGroupConfigs,
                });
            } catch (error) {
                /**
                 * If the user did not explicitly requested to update the invoice number, do not display an error
                 * message. An error may happen if a user has changed his report token config and revisits the report
                 * to test the new settings. This could happen with new customers / testers which would be confused by
                 * the error message.
                 */
                if (!force) {
                    return;
                }
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        NO_VALUE_FOUND_FOR_PLACEHOLDER_IN_PATTERN: (error) => ({
                            title: 'Wert für Platzhalter in Aktenzeichen fehlt',
                            body: `Um das Aktenzeichen zu generieren, muss der Platzhalter <strong>${error.data?.placeholder}</strong> auch in der Rechnungsnummer existieren. Der konnte dort aber nicht gefunden werden.<br><br>Bitte prüfe das Format der Rechnungsnummer. Stelle sicher, dass alle Platzhalter im <a href="/Einstellungen">Muster für das Aktenzeichen</a> auch im Muster für die Rechnungsnummer existieren.`,
                        }),
                    },
                    defaultHandler: {
                        title: 'Aktenzeichen nicht generiert',
                        body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>`,
                    },
                });
            }
        } else {
            this.report.token = this.report.feeCalculation.invoiceParameters.number;
        }

        this.wasAdjustingReportTokenToInvoiceNumberPrevented = false;
        this.toastService.success('Aktenzeichen angeglichen', `Neues Aktenzeichen: ${this.report.token}`);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Number
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  VAT Rate
    //****************************************************************************/
    public setVatRate(
        vatRate: Subtype<Invoice['vatRate'], 0>,
        vatExemptionReason: InvoiceParameters['vatExemptionReason'],
    ): void;
    public setVatRate(vatRate: Exclude<Invoice['vatRate'], 0>): void;
    public setVatRate(vatRate: Invoice['vatRate'], vatExemptionReason?: InvoiceParameters['vatExemptionReason']): void {
        if (this.isReportLocked()) {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Falls du die MwSt. verändern möchtest, öffne zuerst das Gutachten.',
            );
            return;
        }

        this.report.feeCalculation.invoiceParameters.vatRate = vatRate;
        this.report.feeCalculation.invoiceParameters.vatExemptionReason = vatExemptionReason;
    }

    protected translateVatExemptionReason(vatExemptionReason: InvoiceParameters['vatExemptionReason']): string {
        switch (vatExemptionReason) {
            case 'smallBusiness':
                return 'Kleinunternehmer';
            case 'reverseCharge':
                return 'Reverse Charge';
            case 'companyInternalInvoice':
                return 'Innenumsatz Organschaft';
            default:
                return 'Neutral';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END VAT Rate
    /////////////////////////////////////////////////////////////////////////////*/

    public previewInvoice() {
        this.reportPdfDownloadPending = true;
        this.httpClient
            .get(`/api/v0/reports/${this.reportId}/documents/invoice?format=pdf`, {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe({
                next: (response) => {
                    this.reportPdfDownloadPending = false;
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.reportPdfDownloadPending = false;
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Fehler beim Download',
                            body: 'Die Rechnungs-PDF konnte nicht heruntergeladen werden.',
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoicing
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Associated Invoices
    //****************************************************************************/
    private loadAssociatedInvoices() {
        // // If the user does not have access to the invoice list, don't show associated invoices.
        // if (!this.user.accessRights.invoiceList) {
        //     return;
        // }

        let reportIds = [this.report._id];

        // In case this is an amendment report or invoice audit linked to an original report -> display invoices of the original report as well
        // In the original report we also display invoices related to the amendment report. This is currently not implemented for invoices from
        // invoice audits, because we do not store the invoice audit reportId in the original report. Also amendment reports of amendment reports
        // are not fully supported yet (otherwise we would need to follow the amendmentReportId property until we reach the last amendment report)
        const originalReportId =
            this.report.originalReportId || this.report.amendmentReportId || this.report.invoiceAudit?.reportId;
        if (originalReportId) {
            reportIds = [originalReportId, ...reportIds];
        }

        this.invoiceService.find({ reportIds: { $in: reportIds } }).subscribe({
            next: (invoices) => {
                this.mainInvoice = invoices.find(
                    (invoice) => invoice.number === this.report.feeCalculation.invoiceParameters.number,
                );

                this.associatedInvoiceIds = invoices.map((invoice) => invoice._id);

                // Generate the hash for the current invoice params. This is only necessary if there are associated invoices.
                this.generateInvoiceFromInvoiceParameters();
            },
            error: (error) => {
                console.error('Error getting the associated invoices from the server.', { error });
                this.toastService.error(
                    'Assoziierte Rechnungen nicht geladen',
                    'Bitte melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
            },
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Associated Invoices
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Generate a temporary invoice based on the current invoice parameters.
     * If there are differences to an existing main invoice (the one having the same invoice number), offer to
     * overwrite it if it has already been booked.
     */
    public generateInvoiceFromInvoiceParameters(): void {
        // If there isn't an associated invoice (_id of invoice params is missing), don't bother calculating things.
        if (!this.mainInvoice) return;

        this.invoiceFromInvoiceParameters = this.invoiceService.createInvoiceFromReport({
            report: this.report,
            user: this.user,
            team: this.team,
        });
    }

    /**
     * Cancel the existing invoice and write a new invoice based on the current invoice parameters.
     *
     * There are three invoices involved here:
     * 1) The original invoice to be cancelled
     * 2) The invoice that cancels the original invoice (cancellingInvoice)
     * 3) The new invoice that takes the original invoice's place - the report will link to this invoice from now on.
     */
    public async cancelAndRebookMainInvoice(): Promise<void> {
        // Since we wouldn't always use the up-to-date counter, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Nur online kann sichergestellt werden, dass der Zähler über alle Geräte hinweg eindeutig vergeben wird.',
            );
            return;
        }

        this.invoiceCancellationPending = true;

        // The invoice that will take the original invoices place.
        const newInvoice: Invoice = this.invoiceService.createInvoiceFromReport({
            report: this.report,
            user: this.user,
            team: this.team,
        });

        const responsibleAssessorId: string = this.report.visits[0] ? this.report.visits[0].assessor : null;

        try {
            // Cancel the original invoice (1) with a cancellation invoice (2)
            await this.invoiceCancellationService.createFullCancellationInvoice({
                rootInvoice: this.mainInvoice,
            });

            // Configure the new invoice (3)
            const invoiceNumber = await this.invoiceNumberService.generateInvoiceNumber({
                officeLocationId: this.report.officeLocationId,
                responsibleAssessorId,
                report: this.report,
            });

            // By assigning the invoice number after calling the createInvoiceFromReport method,
            // we can move the newInvoice declaration to a higher lever, making the code less verbose
            // than if we had to pass the newInvoice param to each following operator
            newInvoice.number = invoiceNumber;
            newInvoice._id = generateId();
            this.report.feeCalculation.invoiceParameters.number = invoiceNumber;
            // Save the new invoice's (3) ID to the report
            this.report.feeCalculation.invoiceParameters._id = newInvoice._id;

            await this.invoiceService.create(newInvoice, { waitForServer: true });
            await this.saveReport();
            this.loadAssociatedInvoices();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getInvoiceApiErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Fehler bei Storno',
                    body: `Bitte kontaktiere die <a href="/Hilfe">Hotline</a>.`,
                },
            });
        }
    }

    //*****************************************************************************
    //  Amendment Reports
    //****************************************************************************/
    public async getOriginalReport() {
        if (!this.report.originalReportId) return;

        this.originalReport = await this.reportService.get(this.report.originalReportId);
    }

    /**
     * Within an amendment report, the damage value often increases, thereby increasing the assessor fee.
     * It is customary to charge only the difference in assessor fee.
     */
    public getAssessorFeeDifferenceFromOriginalReport(): number {
        if (!this.originalReport) return;
        if (!this.originalReport.feeCalculation.assessorsFee) return;

        const newAppropriateAssessorFee = this.getAssessorsFeeForSelectedFeeTable();

        return newAppropriateAssessorFee - this.originalReport.feeCalculation.assessorsFee;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Amendment Reports
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab: 'fees',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Listen to Websocket Events
    //****************************************************************************/
    private registerWebsocketListeners() {
        this.websocketListenerSubscription?.unsubscribe();

        this.websocketListenerSubscription = this.reportService.patchedFromExternalServerOrLocalBroadcast$.subscribe(
            () => {
                this.updateFeeTableBox();
            },
        );

        this.subscriptions.push(this.websocketListenerSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Listen to Websocket Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Listen to Report Type Change
    //****************************************************************************/
    private registerReportTypeChangeListener() {
        this.reportTypeChangeSubscription?.unsubscribe();

        this.reportTypeChangeSubscription = this.reportDetailsService.reportTypeChange$.subscribe(() => {
            this.updateFeeTableBox();
            this.determineInvoiceRecipient();
        });

        this.subscriptions.push(this.reportTypeChangeSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Listen to Report Type Change
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    public userIsAdmin(): boolean {
        return isAdmin(this.user._id, this.team);
    }

    public getTooltipForBaseForFeeCalculation(): string {
        switch (this.report.type) {
            case 'valuation':
                return 'Marktwert';
            case 'leaseReturn':
            case 'usedVehicleCheck':
                return 'Es gibt keine marktübliche Honorargrundlage für diesen Gutachtentyp.\n\nStattdessen wird ein pauschales Honorar verlangt.';
            default:
                return getBaseForFeeCalculation(this.report).description;
        }
    }

    public translateDocumentType = translateDocumentType;

    public trackById = trackById;

    public isFirefox(): boolean {
        return navigator.userAgent.includes('Firefox/');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        // Thumbnail URLs are no longer needed. Free their memory.
        this.localThumbnailSafeFileUrlConfigs.forEach((localThumbnailSafeFileUrlConfig) => {
            window.URL.revokeObjectURL(localThumbnailSafeFileUrlConfig.url);
        });

        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    protected readonly getWritingFeesTotal = getWritingFeesTotal;
    protected readonly getTravelExpensesTotal = getTravelExpensesTotal;
    protected readonly getPhotosFeeTotal = getPhotosFeeTotal;
    protected readonly getPostageAndPhoneFees = getPostageAndPhoneFees;
    protected readonly isAdmin = isAdmin;
    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

interface LocalThumbnailSafeFileUrlConfig {
    url: string;
    safeUrl: SafeResourceUrl;
}

import { get } from 'lodash-es';
import {
    PlaceholderValueLeafDefinition,
    PlaceholderValueLeafType,
    PlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';

export function getTypeAtPropertyPath(
    propertyPath: string,
    placeholderValueTree: PlaceholderValueTree,
): PlaceholderValueLeafType | 'Array' {
    const typeDefinition = get(placeholderValueTree, propertyPath) as
        | PlaceholderValueLeafDefinition
        | PlaceholderValueLeafType
        | PlaceholderValueLeafType[];

    if (Array.isArray(typeDefinition)) {
        return 'Array';
    } else if (typeof typeDefinition === 'object') {
        if (Array.isArray(typeDefinition.type)) {
            return typeDefinition.type[0];
        }
        return typeDefinition.type;
    }

    return typeDefinition;
}

<div
    id="three-columns-layout-container"
    *ngIf="report"
    [ngClass]="{ 'justify-content-center': report.type === 'valuation' || report.type === 'oldtimerValuationSmall' }">
    <div id="left-column-wrapper" class="one-third-data-column">
        <!--============================================-->
        <!-- Amendment Report -->
        <!--============================================-->

        <!--********** Link to Original Report (within amendment report) **********-->
        <div class="card dark-card margin-bottom-15" *ngIf="originalReport">
            <a class="amendment-report-container" routerLink="/Gutachten/{{ originalReport._id }}">
                <div>
                    <div class="label">Dies ist ein</div>
                    <h2 class="no-margin">Nachtrag</h2>
                </div>

                <div class="amendment-report-details-container display-flex flex-align-center">
                    <mat-icon>description</mat-icon>
                    <div class="amendment-report-text-container">
                        <div class="label">Original</div>
                        <div matTooltip="Aktenzeichen des Originalgutachtens">
                            {{ originalReport.token || 'Kein Aktenzeichen' }}
                        </div>
                        <!--                    <div class="label" *ngIf="originalReport.completionDate" matTooltip="Fertigstellungsdatum">{{originalReport.completionDate | moment}}</div>-->
                    </div>
                </div>
            </a>

            <!-- Amendment reason -->
            <div class="amendment-reason-container">
                <mat-form-field class="mat-block light-mode">
                    <mat-quill
                        placeholder="Begründung"
                        [(ngModel)]="report.amendmentReason"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()"></mat-quill>
                    <mat-icon
                        matSuffix
                        *ngIf="!isReportLocked()"
                        class="input-toolbar-icon"
                        (click)="showAmendmentReasonTextTemplateSelector = true"
                        matTooltip="Textvorlagen">
                        playlist_add
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>

        <text-template-selector
            [(text)]="report.amendmentReason"
            (textChange)="saveReport()"
            [report]="report"
            *ngIf="showAmendmentReasonTextTemplateSelector"
            placeholderForResultingText="Begründung des Nachtrages"
            (close)="showAmendmentReasonTextTemplateSelector = false"
            [textTemplateType]="'amendmentReason'"
            @runChildAnimations></text-template-selector>

        <!--********** Link to Amendment Report (within original report) **********-->
        <a
            routerLink="/Gutachten/{{ amendmentReport._id }}"
            class="amendment-report-container card dark-card margin-bottom-15"
            *ngIf="amendmentReport">
            <div>
                <div class="label">Dies ist das</div>
                <h2 class="no-margin">Original</h2>
            </div>

            <div class="amendment-report-details-container display-flex flex-align-center">
                <mat-icon>note_add</mat-icon>
                <div class="amendment-report-text-container">
                    <div class="label">Nachtrag</div>
                    <div matTooltip="Aktenzeichen des Nachtrags">
                        {{ amendmentReport.token || 'Kein Aktenzeichen' }}
                    </div>
                    <!--                    <div class="label" *ngIf="amendmentReport.completionDate" matTooltip="Fertigstellungsdatum">{{amendmentReport.completionDate | moment}}</div>-->
                </div>
            </div>
        </a>
        <!--============================================-->
        <!-- END Amendment Report -->
        <!--============================================-->

        <!--============================================-->
        <!-- Claimant Data -->
        <!--============================================-->
        <div id="claimant-data-container" class="card card-with-padding">
            <h2 class="headline-with-image">
                <img src="/assets/images/icons/claimant-darkgrey_32.png" class="image-in-headline" />
                {{ getClaimantDenomination() }}
            </h2>

            <!--********** Toolbar **********-->
            <div class="card-toolbar">
                <!--********** Car Registration Scanner **********-->
                <div
                    class="toolbar-icon toolbar-button-with-text"
                    *ngIf="!isReportLocked()"
                    (click)="showCarRegistrationScanner(); hideCarRegistrationScanNotice()"
                    matTooltip="Fahrzeugschein auslesen">
                    <mat-icon class="medium-icon">center_focus_weak</mat-icon>
                    Scan
                </div>

                <!--********** 3-Dot Menu **********-->
                <mat-icon *ngIf="!isReportLocked()" class="toolbar-icon" [matMenuTriggerFor]="claimantFieldsSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #claimantFieldsSubmenu>
                    <a
                        mat-menu-item
                        class="menu-item-with-toggle"
                        (click)="toggleClaimantIban()"
                        matTooltip="Eingabefeld für IBAN des {{
                            getClaimantDenomination()
                        }}s ein-/ausblenden. Manche Gutachter erfassen die IBAN als Service für den beteiligten Anwalt.">
                        {{ getClaimantDenomination() }} IBAN
                        <mat-slide-toggle
                            class="slide-toggle-small margin-left-15"
                            [(ngModel)]="userPreferences.claimantIbanShown"
                            (click)="$event.stopPropagation()"></mat-slide-toggle>
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-toggle"
                        (click)="toggleClaimantCaseNumber()"
                        matTooltip="Eingabefeld für das Aktenzeichen des {{
                            getClaimantDenomination()
                        }}s ein-/ausblenden.">
                        {{ getClaimantDenomination() }} Aktenzeichen
                        <mat-slide-toggle
                            class="slide-toggle-small margin-left-15"
                            [(ngModel)]="userPreferences.claimantCaseNumberShown"
                            (change)="changeDetectorRef.detectChanges()"
                            (click)="$event.stopPropagation()"></mat-slide-toggle>
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-toggle"
                        (click)="toggleClaimantDebtorNumber()"
                        matTooltip="Eingabefeld für die Debitorennummer des {{
                            getClaimantDenomination()
                        }}s ein-/ausblenden.">
                        {{ getClaimantDenomination() }} Debitorennummer
                        <mat-slide-toggle
                            class="slide-toggle-small margin-left-15"
                            [(ngModel)]="userPreferences.claimantDebtorNumberShown"
                            (click)="$event.stopPropagation()"></mat-slide-toggle>
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-toggle"
                        (click)="toggleClaimantNotes()"
                        matTooltip="Eingabefeld für Notizen zum {{ getClaimantDenomination() }} ein-/ausblenden.">
                        {{ getClaimantDenomination() }} Notizen
                        <mat-slide-toggle
                            class="slide-toggle-small margin-left-15"
                            [(ngModel)]="userPreferences.claimantNotesShown"
                            (click)="$event.stopPropagation()"></mat-slide-toggle>
                    </a>
                </mat-menu>
            </div>

            <!--============================================-->
            <!-- Scan Notice -->
            <!--============================================-->
            <div
                id="car-registration-scan-notice"
                *ngIf="!user?.userInterfaceStates.carRegistrationScanNoticeClosed"
                @fadeOut>
                <img src="/assets/images/icons/car-registration.png" alt="" id="car-registration-scan-icon" />
                <div id="car-registration-scan-notice-text">
                    <h3>Fahrzeugschein-Scan</h3>
                    Scanne jetzt den Fahrzeugschein des {{ getClaimantDenomination() }}s über den Scan-Button oben
                    rechts.
                </div>
                <mat-icon class="info-note-close-icon" (click)="hideCarRegistrationScanNotice()">close</mat-icon>
            </div>
            <!--============================================-->
            <!-- END Scan Notice -->
            <!--============================================-->

            <contact-person-form
                [contact-person]="report.claimant.contactPerson"
                [organizationTypes]="['claimant']"
                [additionalContactPeopleForAutocomplete]="claimantsFromOpenReports"
                (nameChange)="setScreenTitle()"
                [show-notes]="userPreferences.claimantNotesShown"
                [show-birthdate]="true"
                [show-iban]="!!(userPreferences.claimantIbanShown || report.claimant.contactPerson.iban)"
                [show-liquidity-check]="!!user && !!user.adeltaFinanzUser.username"
                [show-contact-reset]="true"
                [show-debtor-number]="
                    !!(userPreferences.claimantDebtorNumberShown || report.claimant.contactPerson.debtorNumber)
                "
                (liquidityCheckError)="displayLiquidityCheckErrorMessage($event)"
                (taxationChange)="handleClaimantTaxationChange()"
                (contactPersonChange)="saveReport()"
                (contactPersonSelection)="contactPersonWasSelected($event)"
                [disabled]="isReportLocked()"></contact-person-form>

            <!--============================================-->
            <!-- Claimant Token -->
            <!--============================================-->
            <mat-form-field
                class="mat-block"
                *ngIf="user.preferences.claimantCaseNumberShown || report.claimant.caseNumber">
                <input
                    matInput
                    placeholder="Aktenzeichen ({{ getClaimantDenomination() }})"
                    [(ngModel)]="report.claimant.caseNumber"
                    (change)="saveReport()" />
            </mat-form-field>
            <!--============================================-->
            <!-- END Claimant Token -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="accident-data_claimant"
                [report]="report"
                [useNegativeBottomMarginForCards]="false"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->

            <div id="license-plate-claimant-container" *ngIf="report.type !== 'usedVehicleCheck'">
                <license-plate
                    [displayLarge]="true"
                    [(licensePlate)]="report.car.licensePlate"
                    (licensePlateChange)="setScreenTitle(); clearPreviousAndLegacyReports()"
                    (blur)="licensePlateInputWasBlurred()"
                    [disabled]="isReportLocked()"></license-plate>

                <!--============================================-->
                <!-- Previous & Legacy Reports -->
                <!--============================================-->
                <mat-icon
                    id="previous-reports-indicator"
                    class="outlined"
                    [matMenuTriggerFor]="previousReportsList"
                    *ngIf="previousReports.length || legacyReports.length"
                    [matTooltip]="getPreviousReportsTriggerTooltip()"
                    [matTooltipShowDelay]="0">
                    info
                </mat-icon>

                <mat-menu class="previous-reports-menu" #previousReportsList>
                    <!--********** Previous Reports **********-->
                    <mat-option
                        *ngFor="let previousReport of previousReports"
                        class="previous-report-option"
                        (mousedown)="openReportInNewWindowOnMiddleMouseClick(previousReport, $event)">
                        <div class="previous-report-option-inner-wrapper">
                            <div
                                class="previous-report-manufacturer-icon-container"
                                *ngIf="previousReport.car.make && iconForCarBrandExists(previousReport.car.make)"
                                (click)="navigateToReport(previousReport)">
                                <img
                                    [src]="iconFilePathForCarBrand(previousReport.car.make, 'large')"
                                    alt=""
                                    class="car-manufacturer-logo" />
                            </div>
                            <div
                                class="previous-report-name-and-date-container"
                                (click)="navigateToReport(previousReport)">
                                <div
                                    *ngIf="previousReport.claimant.contactPerson.organization"
                                    class="previous-report-name-container"
                                    [matTooltip]="previousReport.claimant.contactPerson.organization">
                                    {{ previousReport.claimant.contactPerson.organization }}
                                </div>
                                <div
                                    *ngIf="!previousReport.claimant.contactPerson.organization"
                                    class="previous-report-name-container"
                                    [matTooltip]="
                                        previousReport.claimant.contactPerson.firstName +
                                        ' ' +
                                        previousReport.claimant.contactPerson.lastName
                                    ">
                                    {{ previousReport.claimant.contactPerson.firstName }}
                                    {{ previousReport.claimant.contactPerson.lastName }}
                                </div>
                                <div class="label" matTooltip="Aktenzeichen">{{ previousReport.token }}</div>
                                <div class="label">{{ previousReport.createdAt | moment }}</div>
                            </div>
                            <div
                                class="previous-report-model-and-vin-container"
                                (click)="navigateToReport(previousReport)">
                                <div class="previous-report-model" [matTooltip]="previousReport.car.model">
                                    {{ previousReport.car.model }}
                                </div>
                                <div class="label">{{ previousReport.car.vin }}</div>
                            </div>
                            <div class="use-previous-reports-data-container">
                                <mat-icon
                                    class="copy-car-data-icon"
                                    (click)="copyCarDataFromPreviousReport(previousReport)"
                                    matTooltip="Fahrzeugdaten übernehmen">
                                    file_download
                                </mat-icon>
                            </div>
                        </div>
                    </mat-option>

                    <!--********** Legacy Reports **********-->
                    <mat-option
                        *ngFor="let legacyReport of legacyReports"
                        class="previous-report-option"
                        (click)="$event.stopPropagation(); displayInfoNoteAboutLegacyReport()">
                        <div class="previous-report-option-inner-wrapper">
                            <!--********** Brand Icon **********-->
                            <div
                                class="previous-report-manufacturer-icon-container"
                                *ngIf="legacyReport.make && iconForCarBrandExists(legacyReport.make)">
                                <img [src]="iconFilePathForCarBrand(legacyReport.make)" class="car-manufacturer-logo" />
                            </div>

                            <!--********** Name & Date **********-->
                            <div class="previous-report-name-and-date-container">
                                <!--********** Name **********-->
                                <div
                                    *ngIf="legacyReport.claimant.name"
                                    class="previous-report-name-container"
                                    [matTooltip]="legacyReport.claimant.name">
                                    {{ legacyReport.claimant.name }}
                                </div>
                                <div
                                    class="italic"
                                    *ngIf="!legacyReport.claimant.name"
                                    matTooltip="Es wurde kein Name angegeben">
                                    Kein Name
                                </div>
                                <!--********** Token **********-->
                                <div class="label" matTooltip="Aktenzeichen">{{ legacyReport.token }}</div>
                                <!--********** Date **********-->
                                <div class="label">{{ legacyReport.completionDate | moment }}</div>
                            </div>

                            <!--********** Model & VIN **********-->
                            <div class="previous-report-model-and-vin-container">
                                <div class="previous-report-model" [matTooltip]="legacyReport.model">
                                    {{ legacyReport.model }}
                                </div>
                                <div class="label">{{ legacyReport.vin }}</div>
                            </div>

                            <!--********** Legacy System Logo **********-->
                            <div class="legacy-system-logo" [ngSwitch]="legacyReport.sourceSystem?.toLowerCase()">
                                <img
                                    src="/assets/images/logos/audatex.svg"
                                    *ngSwitchCase="'audatex'"
                                    matTooltip="Gutachten importiert aus Audatex" />
                                <img
                                    src="/assets/images/logos/easyexpert.png"
                                    *ngSwitchCase="'easyexpert'"
                                    matTooltip="Gutachten importiert aus EasyExpert" />
                                <img
                                    src="/assets/images/logos/combiplus.png"
                                    *ngSwitchCase="'combiplus'"
                                    matTooltip="Gutachten importiert aus CombiPlus" />
                                <img
                                    src="/assets/images/logos/nextsoft.png"
                                    *ngSwitchCase="'nextsoft'"
                                    matTooltip="Gutachten importiert aus neXtsoft" />
                                <div
                                    class="legacy-system-label-dynarex"
                                    *ngSwitchCase="'dynarex'"
                                    matTooltip="Gutachten importiert aus Dynarex">
                                    Dynarex
                                </div>
                                <div
                                    class="semibold text-overflow-ellipsis"
                                    *ngSwitchDefault
                                    [matTooltip]="legacyReport.sourceSystem">
                                    {{ legacyReport.sourceSystem }}
                                </div>
                            </div>
                        </div>
                    </mat-option>
                </mat-menu>
                <!--============================================-->
                <!-- END Previous & Legacy Reports -->
                <!--============================================-->
            </div>

            <!--***********************************************
            ** VAT Checkboxes & Inputs
            ************************************************-->
            <!--********** These checkboxes and inputs cannot be moved to the contact person form because the license plate must be shown between the contact person form and these checkboxes for astetic reasons. **********-->
            <div class="checkbox-group" [ngClass]="{ 'parent-of-an-only-child': report.type === 'usedVehicleCheck' }">
                <tri-state-checkbox
                    id="claimant-may-deduct-taxes-checkbox"
                    [(ngModel)]="report.claimant.contactPerson.mayDeductTaxes"
                    [disabled]="isReportLocked()"
                    (change)="handleClaimantTaxationChange(); saveReport(); focusInput('vatIdInputClaimant', $event)">
                    Vorsteuerabzugsberechtigt
                </tri-state-checkbox>
            </div>
            <mat-form-field class="mat-block" *ngIf="report.claimant.contactPerson.mayDeductTaxes">
                <input
                    matInput
                    placeholder="Umsatzsteuer-ID"
                    [(ngModel)]="report.claimant.contactPerson.vatId"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    #vatIdInputClaimant
                    matTooltip="bestehend aus Länderkennzeichen und 9 Ziffern, z. B. DE123456789" />
            </mat-form-field>
            <!--***********************************************
            ** END VAT Checkboxes & Inputs
            ************************************************-->

            <div class="checkbox-group" *ngIf="report.type !== 'usedVehicleCheck'">
                <mat-checkbox
                    id="claimant-is-owner-of-verhicle-checkbox"
                    [(ngModel)]="report.claimant.isOwner"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()">
                    Ist Fahrzeughalter
                </mat-checkbox>
            </div>

            <div id="claimant-is-owner-of-vehicle-container" *ngIf="!report.claimant.isOwner">
                <div class="copy-address-button-container" *ngIf="!isReportLocked()">
                    <button (click)="copyContactDetails(report.claimant, report.ownerOfClaimantsCar); saveReport()">
                        Kontaktdaten übernehmen
                    </button>
                </div>
                <h3>Fahrzeughalter</h3>
                <contact-person-form
                    [contact-person]="report.ownerOfClaimantsCar.contactPerson"
                    [show-vat-fields]="true"
                    (contactPersonChange)="saveReport()"
                    [disabled]="isReportLocked()"></contact-person-form>
            </div>

            <!--============================================-->
            <!-- Lawyer -->
            <!--============================================-->
            <ng-container
                *ngIf="
                    !['valuation', 'oldtimerValuationSmall', 'leaseReturn', 'usedVehicleCheck'].includes(report.type)
                ">
                <div class="checkbox-group">
                    <mat-checkbox
                        id="claimant-represented-by-lawyer-checkbox"
                        [(ngModel)]="report.claimant.representedByLawyer"
                        (change)="insertOrRemovePowerOfAttorney(); saveReport()"
                        [disabled]="isReportLocked()">
                        Durch Anwalt vertreten
                    </mat-checkbox>
                </div>

                <div id="lawyer-container" *ngIf="report.claimant.representedByLawyer">
                    <h3>Rechtsanwalt</h3>
                    <contact-person-form
                        [contact-person]="report.lawyer.contactPerson"
                        [organization-placeholder]="'Kanzlei'"
                        [additionalContactPeopleForAutocomplete]="lawyersFromOpenReports"
                        [organizationTypes]="['lawyer']"
                        [show-contact-reset]="true"
                        [show-notes]="true"
                        [show-suggestions-if-empty]="true"
                        (contactPersonChange)="insertOrRemovePowerOfAttorney(); saveReport()"
                        [disabled]="isReportLocked()"></contact-person-form>
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            placeholder="Aktenzeichen (Anwalt)"
                            [(ngModel)]="report.lawyer.caseNumber"
                            (change)="saveReport()"
                            [disabled]="isReportLocked()" />
                    </mat-form-field>

                    <!--============================================-->
                    <!-- Custom Fields -->
                    <!--============================================-->
                    <custom-field-display
                        fieldLocation="accident-data_lawyer"
                        [report]="report"
                        (reportChange)="saveReport()"
                        [disabled]="isReportLocked()"></custom-field-display>
                    <!--============================================-->
                    <!-- END Custom Fields -->
                    <!--============================================-->
                </div>
            </ng-container>
            <!--============================================-->
            <!-- END Lawyer -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Claimant Data -->
        <!--============================================-->

        <div id="report-data-container" class="card card-with-padding" (change)="saveReport()">
            <h2 id="report-data-heading">Gutachten-Eigenschaften</h2>
            <div id="report-type" class="label" [ngSwitch]="report.type">
                <span matTooltip="Gutachtentyp">
                    <ng-container *ngSwitchCase="'liability'">Haftpflichtschaden</ng-container>
                    <ng-container *ngSwitchCase="'shortAssessment'">Kurzgutachten/KVA</ng-container>
                    <ng-container *ngSwitchCase="'partialKasko'">Teilkaskoschaden</ng-container>
                    <ng-container *ngSwitchCase="'fullKasko'">Vollkaskoschaden</ng-container>
                    <ng-container *ngSwitchCase="'valuation'">Bewertung</ng-container>
                    <ng-container *ngSwitchCase="'leaseReturn'">Zustandsbericht</ng-container>
                    <ng-container *ngSwitchCase="'usedVehicleCheck'">Gebrauchtwagen-Check</ng-container>
                    <ng-container *ngSwitchCase="'oldtimerValuationSmall'">Oldtimer</ng-container>
                </span>
            </div>

            <div class="multiple-inputs-grid">
                <!--============================================-->
                <!-- Responsible Assessor -->
                <!--============================================-->
                <mat-form-field id="responsible-assessor-form-field" class="mat-block">
                    <mat-select
                        placeholder="Sachverständiger"
                        [(ngModel)]="report.responsibleAssessor"
                        (selectionChange)="preselectOfficeLocationOfResponsibleAssessor(); saveReport()"
                        [disabled]="isReportLocked()"
                        matTooltip="Verfasser des Gutachtens. Stempel, Unterschrift & Name dieser Person werden im Gutachten abgedruckt."
                        #responsibleAssessorSelect="matSelect">
                        <mat-select-trigger>
                            <ng-container *ngIf="getUsersFullName(report.responsibleAssessor)">
                                {{ getUsersFullName(report.responsibleAssessor) }}
                            </ng-container>
                            <ng-container *ngIf="getDeactivatedUsersFullName(report.responsibleAssessor)">
                                {{ getDeactivatedUsersFullName(report.responsibleAssessor) }} (deaktiviert)
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    !getUsersFullName(report.responsibleAssessor) &&
                                    !getDeactivatedUsersFullName(report.responsibleAssessor)
                                ">
                                Ehemaliger Nutzer ({{ report.responsibleAssessor }})
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option *ngFor="let assessor of assessors" [value]="assessor._id">
                            <div class="autocomplete-option-wrapper autocomplete-option-wrapper-with-user-avatar">
                                <user-avatar [userId]="assessor._id"></user-avatar>
                                <span>{{ getUsersFullName(assessor._id) }}</span>
                                <mat-icon
                                    class="medium-icon"
                                    (click)="rememberDefaultResponsibleAssessor(assessor._id, $event)"
                                    [ngClass]="{ active: userPreferences.responsibleAssessor === assessor._id }"
                                    [matTooltip]="
                                        userPreferences.responsibleAssessor === assessor._id
                                            ? 'Diese Option wird automatisch vorausgewählt. Klicke noch einmal, um den Standard wieder zu entfernen.'
                                            : 'Als Standard setzen'
                                    ">
                                    star
                                </mat-icon>
                            </div>
                        </mat-option>
                        <!--********** If the assessor ID is a deactivated user **********-->
                        <mat-option
                            *ngIf="
                                report.responsibleAssessor && getDeactivatedUsersFullName(report.responsibleAssessor)
                            "
                            [value]="report.responsibleAssessor"
                            disabled>
                            {{ getDeactivatedUsersFullName(report.responsibleAssessor) }} (deaktiviert)
                        </mat-option>

                        <!--********** If the assessor ID does not match a current user (deleted user) **********-->
                        <mat-option
                            *ngIf="
                                report.responsibleAssessor &&
                                !getUsersFullName(report.responsibleAssessor) &&
                                !getDeactivatedUsersFullName(report.responsibleAssessor)
                            "
                            [value]="report.responsibleAssessor"
                            disabled>
                            Ehemaliger Nutzer ({{ report.responsibleAssessor }})
                        </mat-option>

                        <!--********** Invite New Users **********-->
                        <div class="mat-option" (click)="handleClickOnNewAssessor()" *ngIf="userIsAdmin()">
                            <div class="label text-align-center flex-grow">+ Neuer Sachverständiger</div>
                        </div>
                    </mat-select>
                </mat-form-field>
                <!--============================================-->
                <!-- END Responsible Assessor -->
                <!--============================================-->

                <!--============================================-->
                <!-- Office Location -->
                <!--============================================-->
                <mat-form-field
                    id="office-location-form-field"
                    class="mat-block"
                    *ngIf="team && (team.officeLocations?.length > 1 || !getOfficeLocation(report.officeLocationId))">
                    <mat-select
                        placeholder="Standort"
                        [(ngModel)]="report.officeLocationId"
                        (selectionChange)="saveReport()"
                        [disabled]="isReportLocked()"
                        matTooltip="Dieser Standort bestimmt, mit welchen Adressdaten die Platzhalter in deiner Kopf- & Fußzeile ersetzt werden.">
                        <mat-select-trigger>
                            <ng-container *ngIf="getOfficeLocation(report.officeLocationId)">
                                {{ getOfficeLocation(report.officeLocationId).title }}
                            </ng-container>
                            <ng-container *ngIf="!getOfficeLocation(report.officeLocationId)">
                                Ehemaliger Standort ({{ report.officeLocationId }})
                            </ng-container>
                        </mat-select-trigger>

                        <mat-option
                            *ngFor="let officeLocation of team.officeLocations"
                            [value]="officeLocation._id"
                            class="option-with-label">
                            <div class="autocomplete-option-wrapper">
                                <!--********** Internal Title & Address **********-->
                                <div>
                                    <div>{{ officeLocation.title }}</div>
                                    <div class="label">
                                        {{ officeLocation.streetAndHouseNumber }} - {{ officeLocation.zip }}
                                        {{ officeLocation.city }}
                                    </div>
                                </div>

                                <!--********** Assessor's default location **********-->
                                <mat-icon
                                    class="medium-icon"
                                    (click)="rememberDefaultOfficeLocation(officeLocation._id, $event)"
                                    [ngClass]="{
                                        active: user.defaultOfficeLocationId === officeLocation._id,
                                    }"
                                    [matTooltip]="
                                        user.defaultOfficeLocationId === officeLocation._id
                                            ? 'Dieser Standort wird automatisch für neue Gutachten ausgewählt, in denen du der zuständige Sachverständige bist.'
                                            : 'Als Standard für deine neuen Gutachten setzen.'
                                    ">
                                    star
                                </mat-icon>
                            </div>
                        </mat-option>
                        <!--********** If the selected office location ID does not match an office location **********-->
                        <mat-option
                            *ngIf="report.officeLocationId && !getOfficeLocation(report.officeLocationId)"
                            [value]="report.officeLocationId"
                            disabled>
                            Ehemaliger Standort ({{ report.officeLocationId }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--============================================-->
                <!-- END Office Location -->
                <!--============================================-->
            </div>

            <div class="multiple-inputs-grid">
                <mat-form-field id="report-token" class="mat-block">
                    <!-------------- TODO Implement a check mechanism that prevents duplicate reportTokens -->
                    <input
                        matInput
                        placeholder="Aktenzeichen"
                        [(ngModel)]="report.token"
                        (ngModelChange)="setScreenTitle()"
                        (change)="adjustInvoiceNumberToReportToken({ force: false, extractOrOverwrite: 'extract' })"
                        [disabled]="isReportLocked()"
                        #reportTokenElement />
                    <mat-icon
                        matSuffix
                        id="generate-report-token-icon"
                        class="input-toolbar-icon"
                        *ngIf="!report.token && !generateReportTokenRequestPending"
                        (click)="generateReportToken()"
                        matTooltip="Neues Aktenzeichen generieren. Das Muster ist in den Einstellungen anpassbar.">
                        get_app
                    </mat-icon>
                    <img
                        class="generate-report-token-pending-icon"
                        *ngIf="!report.token && generateReportTokenRequestPending"
                        src="/assets/images/icons/loading-indicator.svg"
                        matTooltip="Aktenzeichen wird generiert..."
                        matTooltipShowDelay="300" />
                    <mat-hint
                        class="alert alert-danger"
                        *ngIf="report.car.identificationProvider === 'dat' && report.token && report.token.length > 20"
                        matTooltip="Die DAT erlaubt für Aktenzeichen maximal 20 Zeichen. Beim Export zur DAT kürzt autoiXpert das Aktenzeichen entsprechend.">
                        DAT: max. 20 Zeichen
                    </mat-hint>
                    <mat-hint
                        class="alert alert-danger"
                        *ngIf="
                            report.car.identificationProvider === 'audatex' && report.token && report.token.length > 40
                        "
                        matTooltip="Die Audatex erlaubt für Aktenzeichen maximal 40 Zeichen. Beim Export zu Audatex kürzt autoiXpert das Aktenzeichen entsprechend.">
                        Audatex: max. 40 Zeichen
                    </mat-hint>
                </mat-form-field>
                <date-input
                    id="report-completion-date"
                    placeholder="Fertigstellung am"
                    [(date)]="report.completionDate"
                    (dateChange)="saveReport()"
                    class="mat-block"
                    [disabled]="isReportLocked()"
                    [matTooltip]="
                        'Abschlussdatum auf dem Gutachten-Dokument; wird bei Gutachtenabschluss automatisch gesetzt. Bis zum Abschluss wird bei der Druckvorschau das aktuelle Datum verwendet.\n\nKürzel: m = morgen, h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat'
                    "></date-input>
            </div>

            <!--============================================-->
            <!-- Adjust Invoice Number to Report Token Note -->
            <!--============================================-->
            <div
                id="adjust-invoice-number-to-report-token-note"
                class="info-note"
                *ngIf="wasAdjustingInvoiceNumberToReportTokenPrevented && report.token"
                @fadeOut>
                <mat-icon>sync</mat-icon>
                <div id="adjust-invoice-number-to-report-token-text">
                    <!--********** Text **********-->
                    <div>Rechnungsnummer dem Aktenzeichen angleichen?</div>

                    <!--********** Buttons **********-->
                    <div class="info-note-buttons-row">
                        <!--********** Copy smart **********-->
                        <div
                            class="info-note-button-primary"
                            (click)="adjustInvoiceNumberToReportToken({ force: true, extractOrOverwrite: 'extract' })"
                            matTooltip="Anhand des hinterlegten Musters werden Zähler, Datum usw. extrahiert und in die Vorlage für deine Rechnungsnummer eingefügt.{{
                                '\n\n'
                            }}Aktuelle Rechnungsnummer: {{ report.feeCalculation.invoiceParameters.number }}">
                            Angleichen
                        </div>

                        <!--********** Copy 1:1 **********-->
                        <div
                            class="info-note-button-secondary"
                            (click)="adjustInvoiceNumberToReportToken({ force: true, extractOrOverwrite: 'overwrite' })"
                            matTooltip="Das Aktenzeichen wird 1:1 als Rechnungsnummer verwendet.{{
                                '\n\n'
                            }}Aktuelle Rechnungsnummer: {{ report.feeCalculation.invoiceParameters.number }}">
                            Überschreiben
                        </div>
                    </div>
                </div>
                <mat-icon
                    class="info-note-close-icon"
                    (click)="wasAdjustingInvoiceNumberToReportTokenPrevented = false"
                    matTooltip="Nicht angleichen">
                    close
                </mat-icon>
            </div>
            <!--============================================-->
            <!-- END Adjust Invoice Number to Report Token Note -->
            <!--============================================-->

            <!--============================================-->
            <!-- Report Token Config Note -->
            <!--============================================-->
            <div
                id="report-token-config-note"
                class="info-note"
                *ngIf="!user?.userInterfaceStates.reportTokenConfigNoticeClosed"
                @fadeOut>
                <mat-icon class="no-color">settings</mat-icon>
                <div id="report-token-config-text">
                    <h3>Aktenzeichen</h3>
                    <!-- Don't let prettier format the following inline elements (to prevent whitespace between anchor and full stop) -->
                    <!-- prettier-ignore -->
                    <div>
                        Kannst du in den <a
                            routerLink="/Einstellungen"
                            [queryParams]="{ section: 'report-token-container' }"
                            (click)="hideReportTokenConfigNote()">Einstellungen konfigurieren</a>.
                    </div>
                </div>
                <mat-icon class="toolbar-icon no-color" (click)="hideReportTokenConfigNote()">close</mat-icon>
            </div>
            <!--============================================-->
            <!-- END Report Token Config Note -->
            <!--============================================-->

            <div class="multiple-inputs-grid">
                <!--============================================-->
                <!-- Ordering Method -->
                <!--============================================-->
                <mat-form-field id="ordering-method">
                    <mat-select
                        placeholder="Auftrag wurde ... erteilt"
                        [(ngModel)]="report.orderingMethod"
                        (selectionChange)="saveReport()"
                        [disabled]="isReportLocked()">
                        <mat-select-trigger [ngSwitch]="report.orderingMethod">
                            <span *ngSwitchCase="'personal'">persönlich</span>
                            <span *ngSwitchCase="'phone'">telefonisch</span>
                            <span *ngSwitchCase="'written'">schriftlich</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let option of orderingMethodOptions" [value]="option.value">
                            <div class="autocomplete-option-wrapper">
                                <span>{{ option.label }}</span>
                                <mat-icon
                                    *ngIf="option.value"
                                    class="remember-icon medium-icon"
                                    (click)="setDefaultOrderingMethod(option.value, $event)"
                                    [ngClass]="{ active: userPreferences.orderingMethod === option.value }"
                                    [matTooltip]="
                                        userPreferences.orderingMethod === option.value
                                            ? 'Diese Option wird automatisch vorausgewählt. Klicke noch einmal, um den Standard wieder zu entfernen.'
                                            : 'Als Standard setzen'
                                    ">
                                    star
                                </mat-icon>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--============================================-->
                <!-- END Ordering Method -->
                <!--============================================-->
                <div id="order-date-and-time-group">
                    <date-input
                        id="order-date"
                        placeholder="Erteilt am"
                        [(date)]="report.orderDate"
                        (dateChange)="saveReport()"
                        matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"
                        [disabled]="isReportLocked()"></date-input>
                    <time-input
                        *ngIf="team.preferences.reportOrderTimeShown || report.orderTime"
                        [(time)]="report.orderTime"
                        (timeChange)="saveReport()"
                        [disabled]="isReportLocked()"
                        matTooltip="Uhrzeit der Auftragserteilung. Diese Angabe ist nicht zwingend erforderlich, wird aber beispielsweise vom ZAK-Verband empfohlen."></time-input>
                </div>
                <!--********** 3-Dot Menu **********-->
                <mat-icon
                    *ngIf="!isReportLocked()"
                    class="input-external-icon show-icon-on-card-hover"
                    [matMenuTriggerFor]="orderDateSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #orderDateSubmenu>
                    <a
                        mat-menu-item
                        (click)="toggleOrderTimeInputShown()"
                        [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                        [matTooltip]="
                            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : 'Die Angabe der Uhrzeit (Auftragserteilung) ist nicht zwingend erforderlich, wird aber beispielsweise vom ZAK-Verband empfohlen.'
                        ">
                        Uhrzeit des Auftrags {{ team.preferences.reportOrderTimeShown ? 'verbergen' : 'anzeigen' }}
                    </a>
                </mat-menu>
            </div>
            <div
                class="checkbox-group-container"
                *ngIf="report.type !== 'oldtimerValuationSmall' && report.type !== 'usedVehicleCheck'">
                <div class="checkbox-group">
                    <mat-checkbox
                        id="claimant-has-placed-order-checkbox"
                        [(ngModel)]="report.orderPlacedByClaimant"
                        [disabled]="isReportLocked()"
                        (change)="saveReport()"
                        [ngSwitch]="report.type">
                        Auftrag durch {{ getClaimantDenomination() }}
                    </mat-checkbox>

                    <!--********** Printed report heading **********-->
                    <mat-checkbox
                        [(ngModel)]="report.useCostEstimateHeading"
                        (ngModelChange)="rememberUsageOfCostEstimateHeading(); changeReportTypeSpecificDocumentsTitle()"
                        *ngIf="report.type === 'shortAssessment'"
                        (change)="saveReport()"
                        matTooltip="Im Gutachten statt 'Kurzgutachten' die Überschrift 'Kostenvoranschlag' verwenden">
                        Überschrift "Kostenvoranschlag"
                    </mat-checkbox>
                </div>
            </div>

            <!--********** Order Places By 3rd Party **********-->
            <autocomplete-with-memory
                *ngIf="!report.orderPlacedByClaimant"
                [(ngModel)]="report.actualCustomer"
                autocompleteEntryType="orderingCustomer"
                (change)="saveReport()"
                placeholder="Auftrag erteilt durch"
                [disabled]="isReportLocked()"></autocomplete-with-memory>

            <!--============================================-->
            <!-- Intermediary -->
            <!--============================================-->
            <div id="intermediary-input-wrapper">
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        placeholder="Vermittler"
                        [(ngModel)]="report.intermediary.name"
                        (input)="report.intermediary.contactPersonId = null"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()"
                        [matAutocomplete]="intermediaryAutocomplete.autocomplete"
                        autocomplete="off"
                        [matTooltip]="
                            'Anzahl der vermittelten Gutachten auswerten (wird nicht im Gutachten abgedruckt)'
                        " />
                </mat-form-field>
                <!--********** Autocomplete Intermediary **********-->
                <address-autocompletion
                    #intermediaryAutocomplete
                    [disabled]="isReportLocked()"
                    [contactAutocompletionEnabled]="true"
                    [organizationTypeFilter]="['intermediary', 'lawyer', 'garage']"
                    (contactPersonSelected)="insertIntermediaryIntoReport($event)"
                    [value]="report.intermediary.name"></address-autocompletion>

                <div
                    class="info-note"
                    *ngIf="showInsertIntermediaryAsGarageInfoNote && !isReportLocked()"
                    @slideInAndOutVertically>
                    <mat-icon>build</mat-icon>
                    <div class="flex-grow">
                        <h3>Als Werkstatt übernehmen?</h3>
                        <p>Möchtest du den ausgewählten Kontakt als Werkstatt im Gutachten eintragen?</p>
                        <mat-checkbox
                            class="margin-bottom-15 mat-block"
                            *ngIf="!report.visits[0].locationName"
                            [(ngModel)]="insertIntermediaryAsGarageIncludeVisitLocation">
                            Auch als Besichtigungsort einfügen
                        </mat-checkbox>
                        <div
                            class="info-note-button-primary"
                            (click)="
                                insertContactPersonAsGarage({
                                    contactPerson: contactPersonToPossiblyInsertAsGarage,
                                    alsoSetVisitLocation: true,
                                })
                            ">
                            Ja, eintragen
                        </div>
                        <div
                            class="info-note-button-secondary"
                            (click)="showInsertIntermediaryAsGarageInfoNote = false">
                            Nein
                        </div>
                    </div>
                </div>

                <!--********** Input toolbar **********-->
                <div class="input-toolbar">
                    <mat-icon
                        class="input-toolbar-icon no-repositioning"
                        (click)="showIntermediaryManagementDialog()"
                        matTooltip="Vermittler einfügen"
                        *ngIf="!isReportLocked()">
                        record_voice_over
                    </mat-icon>
                </div>
            </div>
            <!--============================================-->
            <!-- END Intermediary -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="accident-data_report-properties"
                [report]="report"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->
        </div>
    </div>
    <!--============================================-->
    <!-- CENTER COLUMN -->
    <!--============================================-->
    <div id="center-column-wrapper" class="one-third-data-column">
        <div
            id="accident-data-container"
            class="card card-with-padding"
            (change)="saveReport()"
            *ngIf="!['valuation', 'oldtimerValuationSmall', 'leaseReturn', 'usedVehicleCheck'].includes(report.type)">
            <h2>{{ isKaskoCase() ? 'Schadendaten' : 'Unfalldaten' }}</h2>
            <div class="date-and-time-group">
                <date-input
                    [(date)]="report.accident.date"
                    (dateChange)="saveReport()"
                    placeholder="{{ isKaskoCase() ? 'Schadentag' : 'Unfalltag' }}"
                    matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"
                    [disabled]="isReportLocked()"></date-input>
                <time-input
                    [(time)]="report.accident.time"
                    (timeChange)="saveReport()"
                    [disabled]="isReportLocked()"></time-input>
            </div>
            <mat-form-field class="place mat-block">
                <input
                    matInput
                    placeholder="{{ isKaskoCase() ? 'Schadenort' : 'Unfallort' }}"
                    [(ngModel)]="report.accident.location"
                    [matAutocomplete]="accidentLocationAutocomplete.autocomplete"
                    [disabled]="isReportLocked()" />
                <span matSuffix>
                    <!--============================================-->
                    <!-- Claimant SVG -->
                    <!--============================================-->
                    <svg
                        class="input-toolbar-icon"
                        matTooltip="Adresse des {{ getClaimantDenomination() }}s einfügen"
                        (click)="copyClaimantAddressToAccidentLocation(); saveReport()"
                        *ngIf="!isReportLocked()"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="32px"
                        height="32px"
                        viewBox="0 0 32 32"
                        enable-background="new 0 0 32 32"
                        xml:space="preserve">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.109,26.636c0,0-0.106-3.104,1.031-3.883c1.139-0.777,5.557-2.646,7.732-4.159
                                c0.293-0.203,0.53-1.097,0.078-1.738c-0.527-0.746-0.85-2.034-1.024-3.447c-0.564-0.014-0.579-1.432-0.094-1.453
                                c-0.104-1.672-0.061-3.187,0.098-4.206c0.258-1.669,2.792-2.284,4.242-2.284c1.451,0,3.294,0.632,3.659,2.284
                                c0.225,1.015,0.287,2.679,0.16,4.354c0.267,0,0.338,1.259-0.137,1.268c-0.194,1.407-0.536,2.703-1.044,3.482
                                c-0.428,0.659-0.216,1.535,0.076,1.738c2.177,1.513,6.594,3.382,7.733,4.159c1.139,0.778,1.031,3.883,1.031,3.883H4.109z">
                            Anspruchsteller Icon
                        </path>
                    </svg>
                    <!--============================================-->
                    <!-- END Claimant SVG -->
                    <!--============================================-->
                </span>
            </mat-form-field>
            <address-autocompletion
                #accidentLocationAutocomplete
                [disabled]="isReportLocked()"
                [externalAddressAutocompletionEnabled]="true"
                (addressSelected)="insertAddressAutocompletionInAccidentLocation($event)"
                [value]="report.accident.location"></address-autocompletion>
            <div class="checkbox-group parent-of-an-only-child">
                <tri-state-checkbox
                    id="polizeilich-erfasst"
                    [(ngModel)]="report.accident.recordedByPolice"
                    *ngIf="report.type !== 'shortAssessment'"
                    [disabled]="isReportLocked()"
                    (change)="saveReport(); focusInput('policeCaseNumberInput', $event)">
                    Polizeilich erfasst
                </tri-state-checkbox>
            </div>
            <mat-form-field class="mat-block" *ngIf="report.accident.recordedByPolice">
                <input
                    matInput
                    placeholder="Aktenzeichen Polizei"
                    [(ngModel)]="report.accident.caseNumberPolice"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    #policeCaseNumberInput />
            </mat-form-field>
            <!--********** Police Department **********-->
            <autocomplete-with-memory
                *ngIf="report.accident.recordedByPolice"
                placeholder="Polizeibehörde"
                [(ngModel)]="report.accident.policeDepartment"
                (change)="saveReport()"
                [disabled]="isReportLocked()"
                autocompleteEntryType="policeDepartment"></autocomplete-with-memory>

            <!--============================================-->
            <!-- Kasko Damage Type -->
            <!--============================================-->
            <section id="kasko-damage-section" *ngIf="report.type === 'partialKasko'">
                <h3>Schadenart</h3>
                <div class="options-row grid">
                    <div
                        class="option-container"
                        *ngFor="let kaskoDamageType of kaskoDamageTypes"
                        [ngClass]="{
                            active: report.accident.kaskoDamageType === kaskoDamageType.value,
                            unselectable: isReportLocked(),
                        }"
                        (click)="toggleKaskoDamageType(kaskoDamageType.value)"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0">
                        <img
                            class="option-image"
                            src="/assets/images/icons/{{ kaskoDamageType.imageName }}"
                            alt="{{ kaskoDamageType.label }}" />
                        <div class="option-title-container">
                            <span class="option-title">{{ kaskoDamageType.label }}</span>
                        </div>
                    </div>
                </div>

                <!--********** Custom Label **********-->
                <autocomplete-with-memory
                    id="kasko-damage-type-custom-label-input"
                    placeholder="Schadenart"
                    *ngIf="report.accident.kaskoDamageType === 'other'"
                    [(ngModel)]="report.accident.kaskoDamageTypeCustomLabel"
                    (change)="saveReport()"
                    [disabled]="isReportLocked()"
                    autocompleteEntryType="kaskoDamageType"></autocomplete-with-memory>
            </section>
            <!--============================================-->
            <!-- END Kasko Damage Type -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="accident-data_accident"
                [report]="report"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- Visits -->
        <!--============================================-->
        <div id="visits-container" class="card card-with-padding">
            <h2>Besichtigungen</h2>
            <div
                class="visit-container"
                *ngFor="let visit of report.visits; trackBy: trackById; let visitIndex = index"
                [ngClass]="{ 'last-visit-container': visitIndex === report.visits.length - 1 }">
                <h3 *ngIf="report.visits.length > 1">{{ visitIndex + 1 }}. Besichtigung</h3>
                <mat-icon
                    class="toolbar-icon remove-visit-icon"
                    (click)="removeVisit(visit); saveReport()"
                    *ngIf="visitIndex > 0"
                    matTooltip="Besichtigung löschen">
                    delete
                </mat-icon>
                <div class="visit-address-input-container">
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            placeholder="Besichtigung bei"
                            [(ngModel)]="visit.locationName"
                            (change)="saveReport()"
                            [disabled]="isReportLocked()"
                            [matAutocomplete]="visitLocationAutocomplete.autocomplete"
                            autocomplete="off" />
                    </mat-form-field>
                    <address-autocompletion
                        #visitLocationAutocomplete
                        [disabled]="isReportLocked()"
                        [contactAutocompletionEnabled]="true"
                        [organizationTypeFilter]="['visitLocationFavorite', 'garage']"
                        [showSuggestionsIfEmpty]="true"
                        [externalOrganizationAutocompletionEnabled]="true"
                        (addressSelected)="insertAddressAutocompletionInVisitLocation(visit, $event)"
                        (contactPersonSelected)="
                            insertAddressAutocompletionInVisitLocation(visit, $event);
                            suggestToUseContactPersonAsGarage($event)
                        "
                        [value]="visit.locationName"
                        [addressContext]="{ city: visit.city }"></address-autocompletion>

                    <mat-icon
                        id="show-location-favorites-icon"
                        matTooltip="Favoriten-Adresse einfügen"
                        (click)="showVisitLocationFavoritesDialog(visit)"
                        *ngIf="!isReportLocked()">
                        star
                    </mat-icon>
                    <!--============================================-->
                    <!-- Claimant SVG -->
                    <!--============================================-->
                    <svg
                        id="insert-claimants-address-icon"
                        matTooltip="Adresse des {{ getClaimantDenomination() }}s einfügen"
                        (click)="insertClaimantsAddress(visit); saveReport()"
                        *ngIf="!isReportLocked()"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="32px"
                        height="32px"
                        viewBox="0 0 32 32">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.109,26.636c0,0-0.106-3.104,1.031-3.883c1.139-0.777,5.557-2.646,7.732-4.159
                            c0.293-0.203,0.53-1.097,0.078-1.738c-0.527-0.746-0.85-2.034-1.024-3.447c-0.564-0.014-0.579-1.432-0.094-1.453
                            c-0.104-1.672-0.061-3.187,0.098-4.206c0.258-1.669,2.792-2.284,4.242-2.284c1.451,0,3.294,0.632,3.659,2.284
                            c0.225,1.015,0.287,2.679,0.16,4.354c0.267,0,0.338,1.259-0.137,1.268c-0.194,1.407-0.536,2.703-1.044,3.482
                            c-0.428,0.659-0.216,1.535,0.076,1.738c2.177,1.513,6.594,3.382,7.733,4.159c1.139,0.778,1.031,3.883,1.031,3.883H4.109z">
                            Anspruchsteller Icon
                        </path>
                    </svg>
                    <!--============================================-->
                    <!-- END Claimant SVG -->
                    <!--============================================-->
                    <mat-icon
                        id="insert-my-address-icon"
                        matTooltip="Eigene Adresse einfügen"
                        (click)="insertUsersAddress(visit); saveReport()"
                        *ngIf="!isReportLocked()">
                        home
                    </mat-icon>
                </div>

                <div
                    class="info-note margin-bottom-20"
                    *ngIf="showInsertVisitLocationContactAsGarageInfoNote && !isReportLocked()"
                    @slideInAndOutVertically>
                    <mat-icon>build</mat-icon>
                    <div class="flex-grow">
                        <h3>Als Werkstatt übernehmen?</h3>
                        <p>Möchtest du den ausgewählten Kontakt als Werkstatt im Gutachten eintragen?</p>
                        <div
                            class="info-note-button-primary"
                            (click)="
                                insertContactPersonAsGarage({
                                    contactPerson: visitLocationContactPersonToPossiblyInsertAsGarage,
                                })
                            ">
                            Ja, eintragen
                        </div>
                        <div
                            class="info-note-button-secondary"
                            (click)="showInsertVisitLocationContactAsGarageInfoNote = false">
                            Nein
                        </div>
                    </div>
                </div>

                <div class="visit-address-input-container">
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            placeholder="Straße & Hausnr."
                            [matAutocomplete]="visitLocationStreetAutocomplete.autocomplete"
                            [(ngModel)]="visit.street"
                            (change)="saveReport()"
                            autocomplete="off"
                            [disabled]="isReportLocked()" />
                    </mat-form-field>
                    <!--********** Autocomplete Visit Locations (Street) **********-->
                    <address-autocompletion
                        #visitLocationStreetAutocomplete
                        [disabled]="isReportLocked()"
                        [externalAddressAutocompletionEnabled]="true"
                        (addressSelected)="insertAddressAutocompletionInVisitLocation(visit, $event)"
                        [value]="visit.street"
                        [addressContext]="{ city: visit.city }"></address-autocompletion>
                    <mat-icon
                        id="get-address-from-current-position-icon"
                        matTooltip="Aktuellen Standort einfügen"
                        (click)="getAddressFromCurrentPosition(visit)"
                        *ngIf="!isReportLocked() && !getCurrentLocationForVisitPending">
                        my_location
                    </mat-icon>
                    <mat-spinner
                        id="get-address-from-current-position-icon"
                        [diameter]="25"
                        matTooltip="Standort wird abgefragt"
                        *ngIf="getCurrentLocationForVisitPending"></mat-spinner>
                </div>

                <div class="place-group">
                    <mat-form-field class="zipCode">
                        <!--********** Maxlength 8 honors ZIP codes from Brazil. **********-->
                        <input
                            matInput
                            placeholder="PLZ"
                            [(ngModel)]="visit.zip"
                            (change)="saveReport()"
                            [disabled]="isReportLocked()"
                            zip-city-input
                            (cityChange)="insertCityInVisitLocation(visit, $event)"
                            [street]="visit.street"
                            [city]="visit.city"
                            maxlength="8" />
                    </mat-form-field>

                    <mat-form-field class="place">
                        <input
                            matInput
                            placeholder="Ort"
                            [(ngModel)]="visit.city"
                            (change)="saveReport()"
                            [disabled]="isReportLocked()" />
                    </mat-form-field>
                </div>

                <div class="date-and-time-group">
                    <date-input
                        [(date)]="visit.date"
                        class="mat-block"
                        (dateChange)="saveReport()"
                        matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"
                        [disabled]="isReportLocked()"></date-input>
                    <time-input
                        [(time)]="visit.time"
                        (timeChange)="saveReport()"
                        [disabled]="isReportLocked()"
                        matTooltip="Optional. Wenn du keine Uhrzeit angibst, wird nur das Datum abgedruckt."></time-input>
                </div>

                <!--********** Assessor selection **********-->
                <mat-form-field class="mat-block">
                    <mat-select
                        placeholder="Sachverständiger"
                        [(ngModel)]="visit.assessor"
                        (selectionChange)="saveReport()"
                        [disabled]="isReportLocked()">
                        <mat-select-trigger>
                            <ng-container *ngIf="getUsersFullName(visit.assessor)">
                                {{ getUsersFullName(visit.assessor) }}
                            </ng-container>
                            <ng-container *ngIf="getDeactivatedUsersFullName(report.responsibleAssessor)">
                                {{ getDeactivatedUsersFullName(report.responsibleAssessor) }} (deaktiviert)
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    !getUsersFullName(report.responsibleAssessor) &&
                                    !getDeactivatedUsersFullName(report.responsibleAssessor)
                                ">
                                Ehemaliger Nutzer ({{ visit.assessor }})
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option *ngFor="let assessor of assessors" [value]="assessor._id">
                            <div class="autocomplete-option-wrapper autocomplete-option-wrapper-with-user-avatar">
                                <user-avatar [userId]="assessor._id"></user-avatar>
                                <span>
                                    {{ getUsersFullName(assessor._id) }}
                                </span>
                            </div>
                        </mat-option>
                        <!--********** If the assessor ID is a deactivated user **********-->
                        <mat-option
                            *ngIf="
                                report.responsibleAssessor && getDeactivatedUsersFullName(report.responsibleAssessor)
                            "
                            [value]="report.responsibleAssessor"
                            disabled>
                            {{ getDeactivatedUsersFullName(report.responsibleAssessor) }} (deaktiviert)
                        </mat-option>

                        <!--********** If the assessor ID does not match a current user (deleted user) **********-->
                        <mat-option
                            *ngIf="
                                visit.assessor &&
                                !getUsersFullName(visit.assessor) &&
                                !getDeactivatedUsersFullName(report.responsibleAssessor)
                            "
                            [value]="visit.assessor"
                            disabled>
                            Ehemaliger Nutzer ({{ visit.assessor }})
                        </mat-option>

                        <!--********** Invite New Users **********-->
                        <div class="mat-option" (click)="handleClickOnNewAssessor()" *ngIf="userIsAdmin()">
                            <div class="label text-align-center flex-grow">+ Neuer Sachverständiger</div>
                        </div>
                    </mat-select>
                </mat-form-field>

                <!--********** Car Assembly State **********-->
                <autocomplete-with-memory
                    *ngIf="
                        [
                            'liability',
                            'shortAssessment',
                            'partialKasko',
                            'fullKasko',
                            'valuation',
                            'oldtimerValuationSmall',
                            'invoiceAudit',
                            'usedVehicleCheck',
                        ].includes(report.type)
                    "
                    [(ngModel)]="visit.carAssemblyState"
                    autocompleteEntryType="carAssemblyState"
                    (change)="saveReport()"
                    placeholder="Fahrzeugzustand"
                    [disabled]="isReportLocked()"></autocomplete-with-memory>

                <!--********** Same State As Right After Accident **********-->
                <div
                    class="checkbox-group parent-of-an-only-child"
                    *ngIf="
                        ['liability', 'shortAssessment', 'partialKasko', 'fullKasko', 'invoiceAudit'].includes(
                            report.type
                        )
                    ">
                    <mat-checkbox
                        [(ngModel)]="visit.sameStateAsInAccident"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()">
                        Zustand wie nach Unfall
                    </mat-checkbox>
                </div>

                <!--********** Viewing Conditions **********-->
                <autocomplete-with-memory
                    [(ngModel)]="visit.conditions"
                    autocompleteEntryType="visitViewingConditions"
                    (change)="saveReport()"
                    placeholder="Bedingungen"
                    [disabled]="isReportLocked()"></autocomplete-with-memory>

                <!--============================================-->
                <!-- Auxiliary Devices -->
                <!--============================================-->
                <div class="auxiliary-devices-container">
                    <div class="auxiliary-device-form-field-container">
                        <mat-form-field class="mat-block">
                            <mat-chip-list #auxiliaryDevicesChipList>
                                <mat-chip
                                    *ngFor="let auxiliaryDevice of visit.auxiliaryDevices"
                                    [removable]="report.state !== 'done'"
                                    (removed)="removeAuxiliaryDevice(visit, auxiliaryDevice); saveReport()"
                                    [disabled]="isReportLocked()">
                                    {{ auxiliaryDevice }}
                                    <mat-icon matChipRemove *ngIf="report.state !== 'done'">close</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Hilfsmittel"
                                    #auxiliaryDevicesInput
                                    #auxiliaryDevicesAutocompleteTrigger="matAutocompleteTrigger"
                                    [matChipInputFor]="auxiliaryDevicesChipList"
                                    (matChipInputTokenEnd)="enterAuxiliaryDevice(visit, $event); saveReport()"
                                    matChipInputAddOnBlur="true"
                                    [matAutocomplete]="auxiliaryDevicesAutocomplete"
                                    (input)="filterAuxiliaryDeviceAutocomplete(visit, auxiliaryDevicesInput.value)"
                                    (focus)="
                                        retrieveAuxiliaryDeviceEntries();
                                        filterAuxiliaryDeviceAutocomplete(visit, auxiliaryDevicesInput.value)
                                    "
                                    [disabled]="isReportLocked()" />
                            </mat-chip-list>

                            <!--********** Auxiliary Device Autocomplete **********-->
                            <mat-autocomplete
                                #auxiliaryDevicesAutocomplete
                                (optionSelected)="
                                    selectAuxiliaryDeviceFromAutocomplete(
                                        visit,
                                        $event,
                                        auxiliaryDevicesInput,
                                        auxiliaryDevicesAutocompleteTrigger
                                    );
                                    saveReport()
                                ">
                                <mat-option
                                    *ngFor="let auxiliaryDevice of filteredAuxiliaryDevicesAutocompleteEntries"
                                    (mousedown)="$event.preventDefault()"
                                    [value]="auxiliaryDevice.value">
                                    <div class="autocomplete-option-wrapper">
                                        <div
                                            class="autocomplete-option-label"
                                            [matTooltip]="auxiliaryDevice.value"
                                            matTooltipShowDelay="700">
                                            {{ auxiliaryDevice.value }}
                                        </div>

                                        <!--********** Delete Icon **********-->
                                        <mat-icon
                                            *ngIf="user.accessRights.editTextsAndDocumentBuildingBlocks"
                                            class="toolbar-icon remove-icon-autocomplete-option"
                                            [ngClass]="{ disabled: auxiliaryDevice.createdBy === 'autoixpert-default' }"
                                            (click)="
                                                removeAuxiliaryDeviceAutocompleteEntry(auxiliaryDevice);
                                                filterAuxiliaryDeviceAutocomplete(visit, auxiliaryDevicesInput.value);
                                                $event.stopPropagation();
                                                saveReport()
                                            "
                                            [matTooltip]="
                                                auxiliaryDevice.createdBy === 'autoixpert-default'
                                                    ? 'Standard-Hilfsmittel können nicht gelöscht werden'
                                                    : 'Hilfsmittel löschen'
                                            ">
                                            delete
                                        </mat-icon>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <mat-icon class="auxiliary-devices-submenu-icon" [matMenuTriggerFor]="auxiliaryDevicesSubmenu">
                        more_vert
                    </mat-icon>
                    <mat-menu #auxiliaryDevicesSubmenu>
                        <!--********** Insert Standard Devices **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="userPreferences.auxiliaryDevicesDefault.length"
                            (click)="insertStandardAuxiliaryDevices(visit)"
                            [disabled]="isReportLocked()"
                            matTooltip="Zuvor als Standard gesetzte Hilfsmittel erneut einfügen">
                            <mat-icon>get_app</mat-icon>
                            Standardhilfsmittel einfügen
                        </a>

                        <!--********** Remember Standard Devices **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                            (click)="rememberStandardAuxiliaryDevices(visit.auxiliaryDevices)"
                            matTooltip="Die aktuell ausgewählten Hilfsmittel bei neuen Gutachten vorbelegen">
                            <mat-icon>auto_fix_high</mat-icon>
                            Aktuelle Hilfsmittel immer einfügen
                        </a>

                        <!--********** Remember Devices as Autocomplete Entries **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                            (click)="rememberCurrentAuxiliaryDevices(visit.auxiliaryDevices)"
                            matTooltip="Hilfsmittel der Vorschlagsliste hinzufügen für schnellere Auswahl">
                            <mat-icon class="outlined">bookmark_border</mat-icon>
                            Hilfsmittel als Vorschläge merken
                        </a>
                    </mat-menu>
                </div>
                <!--============================================-->
                <!-- END Auxiliary Devices -->
                <!--============================================-->

                <!--********** Visit Comment **********-->
                <mat-form-field class="mat-block">
                    <mat-quill
                        placeholder="Bemerkungen"
                        [(ngModel)]="visit.comment"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()"></mat-quill>
                    <mat-icon
                        matSuffix
                        *ngIf="!isReportLocked()"
                        class="input-toolbar-icon"
                        (click)="showVisitCommentTextTemplateSelector.set(visit._id, true)"
                        matTooltip="Textvorlagen">
                        playlist_add
                    </mat-icon>
                </mat-form-field>
                <text-template-selector
                    [(text)]="visit.comment"
                    (textChange)="saveReport()"
                    [report]="report"
                    *ngIf="showVisitCommentTextTemplateSelector.get(visit._id)"
                    placeholderForResultingText="Bemerkungen zur Besichtigung"
                    (close)="showVisitCommentTextTemplateSelector.delete(visit._id)"
                    [textTemplateType]="'visitComment'"
                    @runChildAnimations></text-template-selector>

                <!--============================================-->
                <!-- Other People Present -->
                <!--============================================-->
                <h4>Anwesend</h4>
                <div class="checkbox-group">
                    <mat-checkbox
                        [(ngModel)]="visit.assessorWasPresent"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()">
                        Sachverständiger
                        {{ getUsersFullName(visit.assessor) }}
                    </mat-checkbox>
                </div>
                <div class="checkbox-group">
                    <mat-checkbox
                        [(ngModel)]="visit.claimantWasPresent"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()">
                        {{ getClaimantDenomination() }} {{ report.claimant.contactPerson.lastName }}
                    </mat-checkbox>
                </div>
                <ng-container
                    *ngIf="showOtherPeoplePresentAtTheseVisits.includes(visit); else otherPeoplePresentButtonContainer">
                    <div class="checkbox-group">
                        <mat-checkbox
                            [(ngModel)]="visit.garageTechnicianWasPresent"
                            (change)="saveReport()"
                            [disabled]="isReportLocked()">
                            Werkstattmitarbeiter
                        </mat-checkbox>
                    </div>
                    <div class="checkbox-group" *ngIf="!report.claimant.isOwner">
                        <mat-checkbox
                            [(ngModel)]="visit.ownerOfClaimantsCarWasPresent"
                            (change)="saveReport()"
                            *ngIf="!report.claimant.isOwner"
                            [disabled]="isReportLocked()">
                            Fahrzeughalter
                            {{ report.ownerOfClaimantsCar.contactPerson.lastName }}
                        </mat-checkbox>
                    </div>
                    <div
                        class="checkbox-group"
                        *ngIf="
                            !['valuation', 'oldtimerValuationSmall', 'leaseReturn', 'usedVehicleCheck'].includes(
                                report.type
                            )
                        ">
                        <mat-checkbox
                            [(ngModel)]="visit.driverOfClaimantsCarWasPresent"
                            (change)="saveReport()"
                            [disabled]="isReportLocked()">
                            Fahrzeugführer
                        </mat-checkbox>
                    </div>

                    <!--============================================-->
                    <!-- Other People Present -->
                    <!--============================================-->
                    <div id="other-people-present-container">
                        <mat-form-field class="mat-block">
                            <mat-chip-list #otherPeoplePresentChipList>
                                <mat-chip
                                    *ngFor="let person of visit.otherPeoplePresent"
                                    [removable]="report.state !== 'done'"
                                    (removed)="removeOtherPeoplePresent(visit, person); saveReport()"
                                    [disabled]="isReportLocked()">
                                    {{ person }}
                                    <mat-icon matChipRemove *ngIf="report.state !== 'done'">close</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Anwesende Personen"
                                    #otherPeoplePresentInput
                                    #otherPeoplePresentAutocompleteTrigger="matAutocompleteTrigger"
                                    [matChipInputFor]="otherPeoplePresentChipList"
                                    (matChipInputTokenEnd)="enterOtherPeoplePresent(visit, $event); saveReport()"
                                    [matChipInputAddOnBlur]="true"
                                    [matAutocomplete]="otherPeoplePresentAutocomplete"
                                    (input)="filterOtherPeoplePresentAutocomplete(visit, otherPeoplePresentInput.value)"
                                    (focus)="filterOtherPeoplePresentAutocomplete(visit, otherPeoplePresentInput.value)"
                                    [disabled]="isReportLocked()" />
                            </mat-chip-list>

                            <mat-autocomplete
                                #otherPeoplePresentAutocomplete
                                (optionSelected)="
                                    selectOtherPeoplePresentFromAutocomplete(
                                        visit,
                                        $event,
                                        otherPeoplePresentInput,
                                        otherPeoplePresentAutocompleteTrigger
                                    );
                                    filterOtherPeoplePresentAutocomplete(visit, otherPeoplePresentInput.value);
                                    saveReport()
                                ">
                                <mat-option
                                    *ngFor="let person of filteredOtherPeoplePresentAutocompleteEntries"
                                    [value]="person.value"
                                    (mousedown)="$event.preventDefault()">
                                    <div class="autocomplete-option-wrapper">
                                        <div
                                            class="autocomplete-option-label"
                                            [matTooltip]="person.value"
                                            matTooltipShowDelay="700">
                                            {{ person.value }}
                                        </div>

                                        <!--********** Delete Icon **********-->
                                        <mat-icon
                                            *ngIf="user.accessRights.editTextsAndDocumentBuildingBlocks"
                                            class="toolbar-icon remove-icon-autocomplete-option"
                                            (click)="
                                                removeOtherPeoplePresentAutocompleteEntry(person);
                                                filterOtherPeoplePresentAutocomplete(
                                                    visit,
                                                    otherPeoplePresentInput.value
                                                );
                                                $event.stopPropagation();
                                                saveReport()
                                            "
                                            matTooltip="Anwesende Person löschen">
                                            delete
                                        </mat-icon>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <!--********** Submenu **********-->
                        <mat-icon
                            class="other-people-present-submenu-icon"
                            *ngIf="
                                user?.accessRights.editTextsAndDocumentBuildingBlocks ||
                                userPreferences.otherPeoplePresentDefault.length
                            "
                            [matMenuTriggerFor]="otherPeoplePresentSubmenu">
                            more_vert
                        </mat-icon>
                        <mat-menu #otherPeoplePresentSubmenu>
                            <!--********** Insert Standard Other People Present **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="userPreferences.otherPeoplePresentDefault.length"
                                (click)="insertDefaultOtherPeoplePresent(visit)"
                                [disabled]="isReportLocked()"
                                matTooltip="Zuvor als Standard gesetzte Anwesende erneut einfügen">
                                <mat-icon>get_app</mat-icon>
                                Standardanwesende einfügen
                            </a>

                            <!--********** Remember Standard Other People Present **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                                (click)="rememberDefaultOtherPeoplePresent(visit.otherPeoplePresent)"
                                matTooltip="Die aktuellen weiteren Anwesenden bei neuen Gutachten vorbelegen">
                                <mat-icon>auto_fix_high</mat-icon>
                                Aktuelle Anwesende immer einfügen
                            </a>

                            <!--********** Remember Other People Present as Autocomplete Entries **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                                (click)="addCurrentOtherPeoplePresentToAutocomplete(visit.otherPeoplePresent)"
                                matTooltip="Anwesende der Vorschlagsliste hinzufügen für schnellere Auswahl">
                                <mat-icon class="outlined">bookmark_border</mat-icon>
                                Anwesende Personen als Vorschläge merken
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Display less people present **********-->
                    <div class="other-people-present-expand-button-container">
                        <!-- Only show reduce button if neither of the fields is filled -->
                        <button
                            class="flat"
                            *ngIf="!hasOtherPeoplePresent(visit)"
                            (click)="hideOtherPeoplePresentAtThisVisit(visit)">
                            <mat-icon class="small-icon">keyboard_arrow_up</mat-icon>
                            Weniger anzeigen
                        </button>
                    </div>
                    <!--============================================-->
                    <!-- END Other People Present -->
                    <!--============================================-->
                </ng-container>

                <!--********** Display more people present **********-->
                <ng-template #otherPeoplePresentButtonContainer>
                    <div class="other-people-present-expand-button-container">
                        <button class="flat" (click)="showOtherPeoplePresentAtThisVisit(visit)">
                            <mat-icon class="small-icon">keyboard_arrow_down</mat-icon>
                            Weitere anzeigen
                        </button>
                    </div>
                </ng-template>
                <!--============================================-->
                <!-- END Other People Present -->
                <!--============================================-->
            </div>
            <div id="add-visit-button-container" *ngIf="!isReportLocked()">
                <button id="add-visit" (click)="addVisit()">Neue Besichtigung</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Visits -->
        <!--============================================-->

        <!--***********************************************
        ** Customer Signatures Valuation
        ************************************************-->
        <customer-signatures-card
            [report]="report"
            *ngIf="['valuation', 'oldtimerValuationSmall'].includes(report.type)"></customer-signatures-card>
        <!--***********************************************
        ** END Customer Signatures Valuation
        ************************************************-->
    </div>
    <!--============================================-->
    <!-- ENDE CENTER COLUMN -->
    <!--============================================-->

    <!--============================================-->
    <!-- RIGHT COLUMN -->
    <!--============================================-->
    <div
        id="right-column-wrapper"
        class="one-third-data-column"
        *ngIf="!['valuation', 'oldtimerValuationSmall'].includes(report.type)">
        <div id="author-of-damage-data-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Author Of Damage -->
            <!--============================================-->
            <ng-container
                *ngIf="
                    !isKaskoCase() &&
                    report.type !== 'leaseReturn' &&
                    report.type !== 'usedVehicleCheck' &&
                    report.type !== 'invoiceAudit'
                ">
                <h2 id="author-of-damage-heading">
                    <img src="/assets/images/icons/author-of-damage-darkgrey_32.png" class="image-in-headline" />
                    Unfallgegner
                    <span
                        class="help-indicator"
                        matTooltip="Unbekannte Angaben dürfen weggelassen werden. autoiXpert bereinigt das Gutachten um nicht gefüllte Zeilen & Platzhalter.

                Dies gilt auch, wenn gar keine Angaben zum Unfallgegner gemacht werden.">
                        ?
                    </span>
                </h2>
                <contact-person-form
                    [contact-person]="report.authorOfDamage.contactPerson"
                    (contactPersonChange)="saveReport()"
                    [show-email]="false"
                    [disabled]="isReportLocked()"></contact-person-form>

                <!--============================================-->
                <!-- Custom Fields -->
                <!--============================================-->
                <custom-field-display
                    fieldLocation="accident-data_author-of-damage"
                    [report]="report"
                    [useNegativeBottomMarginForCards]="false"
                    (reportChange)="saveReport()"
                    [disabled]="isReportLocked()"></custom-field-display>
                <!--============================================-->
                <!-- END Custom Fields -->
                <!--============================================-->

                <license-plate
                    [displayLarge]="true"
                    [(licensePlate)]="report.authorOfDamage.licensePlate"
                    (blur)="saveReport()"
                    [disabled]="isReportLocked()"></license-plate>

                <div class="checkbox-group parent-of-an-only-child">
                    <mat-checkbox
                        id="author-of-damage-is-owner-of-vehicle-checkbox"
                        [(ngModel)]="report.authorOfDamage.isOwner"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()">
                        Ist Fahrzeughalter
                    </mat-checkbox>
                </div>

                <div id="author-of-damage-is-owner-of-vehicle-container" *ngIf="!report.authorOfDamage.isOwner">
                    <div class="copy-address-button-container" *ngIf="!isReportLocked()">
                        <button (click)="copyContactDetails(report.authorOfDamage, report.ownerOfAuthorOfDamagesCar)">
                            Kontaktdaten übernehmen
                        </button>
                    </div>
                    <h3>Fahrzeughalter</h3>
                    <contact-person-form
                        [contact-person]="report.ownerOfAuthorOfDamagesCar.contactPerson"
                        [show-email]="false"
                        (contactPersonChange)="saveReport()"
                        [disabled]="isReportLocked()"></contact-person-form>
                </div>
            </ng-container>
            <!--============================================-->
            <!-- END Author Of Damage -->
            <!--============================================-->

            <!--============================================-->
            <!-- Insurance -->
            <!--============================================-->
            <div id="insurance-container" *ngIf="report.type !== 'leaseReturn' && report.type !== 'usedVehicleCheck'">
                <div id="insurance-heading-container">
                    <h2 class="headline-with-image" *ngIf="isKaskoCase() || report.type === 'invoiceAudit'">
                        <img src="/assets/images/icons/insurance-darkgray.svg" class="image-in-headline" />
                        Versicherung
                    </h2>

                    <!-- Sometimes the main heading of the card is already set to "Versicherung" -> Skip the 2nd heading then. -->
                    <h3 *ngIf="!(isKaskoCase() || report.type === 'invoiceAudit')">Versicherung</h3>

                    <!--********** GDV Query **********-->
                    <div
                        *ngIf="!isReportLocked()"
                        id="gdv-button"
                        [ngClass]="{ 'insurance-is-main-heading': isKaskoCase() || report.type === 'invoiceAudit' }"
                        (click)="getResponsibleInsuranceFromGdv()"
                        matTooltip="Versicherungsdaten über Z@-Online des GDV abfragen"
                        @fadeInAndOut>
                        <img
                            id="gdv-logo"
                            class="cursor-pointer"
                            *ngIf="!gdvResponsibleInsuranceRequestPending"
                            src="/assets/images/logos/gdv-logo.svg" />
                        <img
                            *ngIf="gdvResponsibleInsuranceRequestPending"
                            src="/assets/images/icons/loading-indicator.svg"
                            alt=""
                            class="pending-icon" />
                    </div>

                    <mat-icon
                        id="insurance-submenu-trigger"
                        class="toolbar-icon"
                        [matMenuTriggerFor]="insuranceSubmenu">
                        more_vert
                    </mat-icon>
                    <!--********** Toolbar **********-->
                    <mat-menu #insuranceSubmenu>
                        <mat-option
                            (click)="toggleInsuranceInsuranceCompanyNumber()"
                            matTooltip="Die VU-Nummer (auch GDV-Nummer oder GDV-VU-Nummer) ist die eindeutige Registrierungsnummer einer Versicherung bei der BaFin. Diese Nummer ist notwendig, damit ein Restwertinserat einer Versicherung zugeordnet werden kann bei der Restwertbörse entsprechend der Vereinbarungen abgerechnet werden kann. Für welche Versicherungen und Restwertbörsen bei dir eine Vereinbarung besteht, kannst du im Kontaktmanagement bei der Versicherung eintragen.">
                            VU-Nummer (GDV-Nummer)
                            {{ userPreferences.insurance_displayInsuranceCompanyNumber ? 'verbergen' : 'anzeigen' }}
                        </mat-option>
                    </mat-menu>
                </div>

                <contact-person-form
                    [contact-person]="report.insurance.contactPerson"
                    [organization-placeholder]="'Versicherung'"
                    [organizationTypes]="['insurance']"
                    [filterByFields]="['organization']"
                    [additionalContactPeopleForAutocomplete]="insurancesFromOpenReports"
                    [show-person]="false"
                    [show-contact-reset]="true"
                    [show-insurance-company-number]="userPreferences.insurance_displayInsuranceCompanyNumber"
                    [show-suggestions-if-empty]="true"
                    (contactPersonChange)="saveReport()"
                    [disabled]="isReportLocked()"></contact-person-form>

                <!--********** Insurance Number **********-->
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        placeholder="Versicherungsnummer"
                        [(ngModel)]="report.authorOfDamage.insuranceNumber"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()" />
                </mat-form-field>

                <!--********** Case Number **********-->
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        placeholder="Schadennummer"
                        [(ngModel)]="report.insurance.caseNumber"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()" />
                </mat-form-field>

                <!--********** Insurance Deuctible **********-->
                <currency-input
                    placeholder="Selbstbeteiligung"
                    [(value)]="report.insurance.deductible"
                    *ngIf="isKaskoCase()"
                    (valueChange)="saveReport()"
                    [disabled]="isReportLocked()"></currency-input>

                <!--============================================-->
                <!-- Custom Fields -->
                <!--============================================-->
                <custom-field-display
                    fieldLocation="accident-data_insurance"
                    [report]="report"
                    (reportChange)="saveReport()"
                    [disabled]="isReportLocked()"></custom-field-display>
                <!--============================================-->
                <!-- END Custom Fields -->
                <!--============================================-->
            </div>
            <!--============================================-->
            <!-- END Insurance -->
            <!--============================================-->

            <!--============================================-->
            <!-- Lease Provider -->
            <!--============================================-->
            <div id="lease-provider-container" *ngIf="report.type === 'leaseReturn'">
                <h2 class="headline-with-image">
                    <img src="/assets/images/icons/lease-provider.svg" class="image-in-headline" />
                    Leasinggeber
                </h2>
                <contact-person-form
                    [contact-person]="report.leaseProvider.contactPerson"
                    [organizationTypes]="['leaseProvider']"
                    [additionalContactPeopleForAutocomplete]="leaseProvidersFromOpenReports"
                    [show-person]="false"
                    (contactPersonChange)="saveReport()"
                    [disabled]="isReportLocked()"></contact-person-form>

                <!--********** Case Number **********-->
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        placeholder="Vertragsnummer"
                        [(ngModel)]="report.leaseProvider.caseNumber"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()" />
                </mat-form-field>
            </div>
            <!--============================================-->
            <!-- END Lease Provider -->
            <!--============================================-->
            <!--============================================-->
            <!-- Seller -->
            <!--============================================-->
            <div id="seller-container" *ngIf="report.type === 'usedVehicleCheck'">
                <h2 class="headline-with-image">
                    <mat-icon class="image-in-headline">euro_symbol</mat-icon>
                    Verkäufer
                </h2>
                <contact-person-form
                    [contact-person]="report.seller.contactPerson"
                    [organizationTypes]="['seller']"
                    [additionalContactPeopleForAutocomplete]="sellersFromOpenReports"
                    (contactPersonChange)="saveReport()"
                    [disabled]="isReportLocked()"></contact-person-form>

                <!--********** License Plate **********-->
                <license-plate
                    [displayLarge]="true"
                    [(licensePlate)]="report.car.licensePlate"
                    (blur)="saveReport()"
                    [disabled]="isReportLocked()"></license-plate>
            </div>
            <!--============================================-->
            <!-- END Seller -->
            <!--============================================-->
        </div>

        <!--============================================-->
        <!-- Customer Signatures -->
        <!--============================================-->
        <customer-signatures-card [report]="report" *ngIf="report.type !== 'invoiceAudit'"></customer-signatures-card>
        <!--============================================-->
        <!-- END Customer Signatures -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- ENDE RIGHT COLUMN -->
    <!--============================================-->
</div>

<!--********** Intermediaries **********-->
<contact-people-manager
    *ngIf="intermediaryManagementDialogShown"
    [organizationType]="'intermediary'"
    (contactPersonSelection)="insertIntermediaryIntoReport($event); saveReport()"
    (close)="hideIntermediaryManagementDialog()"></contact-people-manager>

<!--********** Visit Location Favorites **********-->
<contact-people-manager
    *ngIf="visitLocationFavoritesDialogShown"
    [organizationType]="'visitLocationFavorite'"
    (contactPersonSelection)="
        insertVisitLocationFavorite({ visit: visitForFavoritesDialog, visitLocationFavorite: $event })
    "
    (close)="hideVisitLocationFavoritesDialog()"></contact-people-manager>

<car-registration-scanner-dialog
    [report]="report"
    (reportChange)="saveReport()"
    *ngIf="carRegistrationScannerDialogShown"
    (close)="hideCarRegistrationScanner(); findPreviousAndLegacyReports()"
    @runChildAnimations></car-registration-scanner-dialog>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { DatDossierType } from '@autoixpert/models/reports/damage-calculation/dat-dossier-type';
import { DatAuthenticationService } from '../dat-authentication.service';

@Injectable()
export class CopyDatDossierService {
    constructor(
        private httpClient: HttpClient,
        private datAuthenticationService: DatAuthenticationService,
    ) {}

    public async copyDossier(
        reportId: string,
        dossierType: DatDossierType = 'damageCalculation',
    ): Promise<CopyDatDossierResponse> {
        const datJwt = await this.datAuthenticationService.getJwt();

        return this.httpClient
            .post<CopyDatDossierResponse>(`${apiBasePath}/reports/${reportId}/datDossierCopy`, null, {
                headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                params: {
                    dossierType,
                },
            })
            .toPromise();
    }
}

export interface CopyDatDossierResponse {
    newDatDossierId: number;
    dossierType: DatDossierType;
}

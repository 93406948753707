// These are the document building blocks for which DOCX partials exist. This list must be equal to the list of partial files in the backend.
import { DocumentBuildingBlock } from '../../models/documents/document-building-block';

export const enhancedDocumentBuildingBlocks = [
    'AbUndAnmeldegebühren',
    'Anschreiben',
    'Anstoßskizze',
    'Ausstattung',
    'Bankverbindung',
    'Bereifung',
    'BeteiligteBewertung',
    'BeteiligteHaftpflichtschaden',
    'BeteiligteKaskoschaden',
    'Bewertung',
    'BewertungOldtimer',
    'Bewertungsergebnis',
    'Brieffenster',
    'BrieffensterRechnung',
    'DeckblattGebrauchtwagencheck',
    'DeckblattMitFoto',
    'DeckblattOhneFoto',
    'DeckblattWerkstattinformation',
    'DeckblattZustandsbericht',
    'DeckblattBewertung',
    'Entsorgungskosten',
    'ErgebnisRechnungsprüfung',
    'ErmittlungFahrzeugwert',
    'Fahrzeugdaten',
    'Fotoanlage',
    'FotoanlageZweispaltig',
    'Gutachtenfertigstellung',
    'GutachtenfertigstellungReparaturbestätigung',
    'GutachtenübersichtSammelrechnung',
    'Höchstgebot',
    'Inhaltsverzeichnis',
    'KorrekturenRechnungsprüfung',
    'Lackschichtdicke',
    'MethodenMinderwert',
    'Minderwert',
    'Notreparatur',
    'NutzungsentschädigungProAusfalltag',
    'Prüfpositionen',
    'Rechnungspositionen',
    'RechnungVortext',
    'Reparaturdauer',
    'Reparaturkostenkalkulation',
    'Restkraftstoff',
    'Restwertermittlung',
    'Umbaukosten',
    'UnterschriftKunde',
    'ZweiteUnterschriftKunde',
    'Honorarvereinbarung',
    'VorhaltekostenProTag',
    'WeitereGebote',
    'WeitereKosten',
    'Werkstattkostensätze',
    'WertbeeinflussendeFaktoren',
    'Wertschätzung',
    'Wertverbesserung',
    'Wiederbeschaffungswert',
    'ZusammenfassungAnwaltsvollmacht',
    'ZusammenfassungBeteiligte',
    'ZusammenfassungBewertung',
    'ZusammenfassungHaftpflichtschaden',
    'ZusammenfassungKaskoschaden',
    'ZusammenfassungKurzgutachten',
    'ZusammenfassungMinderwertprotokoll',
    'ZusammenfassungRechnung',
    'ZusammenfassungRechnungsprüfung',
    'ZusammenfassungReparaturbestätigung',
    'ZusammenfassungWerkstattinformation',
    'ZusammenfassungZustandsbericht',
    'ZusammenfassungGebrauchtwagencheck',
    'Zustand',
    'ZustandDesFahrzeugsBeiBesichtigung',
];

/**
 * Images and tables count as graphics.
 */
export const documentBuildingBlocksEnhancedWithGraphic: DocumentBuildingBlock['placeholder'][] = [
    'Anstoßskizze',
    'Ausstattung',
    'Bankverbindung',
    'Bereifung',
    'BeteiligteBewertung',
    'BeteiligteHaftpflichtschaden',
    'BeteiligteKaskoschaden',
    'Bewertung',
    'BewertungOldtimer',
    'Bewertungsergebnis',
    'Brieffenster',
    'BrieffensterRechnung',
    'DeckblattMitFoto',
    'DeckblattOhneFoto',
    'DeckblattWerkstattinformation',
    'DeckblattZustandsbericht',
    'DeckblattBewertung',
    'ErgebnisRechnungsprüfung',
    'Fahrzeugdaten',
    'Fotoanlage',
    'FotoanlageZweispaltig',
    'Gutachtenfertigstellung',
    'GutachtenfertigstellungReparaturbestätigung',
    'Höchstgebot',
    'Inhaltsverzeichnis',
    'KorrekturenRechnungsprüfung',
    'Lackschichtdicke',
    'Prüfpositionen',
    'Reparaturkostenkalkulation',
    'UnterschriftKunde',
    'ZweiteUnterschriftKunde',
    'VorhaltekostenProTag',
    'WeitereGebote',
    'WeitereKosten',
    'Werkstattkostensätze',
    'WertbeeinflussendeFaktoren',
    'ZusammenfassungAnwaltsvollmacht',
    'ZusammenfassungBeteiligte',
    'ZusammenfassungBewertung',
    'ZusammenfassungHaftpflichtschaden',
    'ZusammenfassungKaskoschaden',
    'ZusammenfassungKurzgutachten',
    'ZusammenfassungMinderwertprotokoll',
    'ZusammenfassungRechnung',
    'ZusammenfassungRechnungsprüfung',
    'ZusammenfassungReparaturbestätigung',
    'ZusammenfassungWerkstattinformation',
    'ZusammenfassungZustandsbericht',
    'Zustand',
    /**
     * This block does not contain any graphic but it contains a special loop which allows one document building block per visit. This format
     * is unique, so building an interface to edit it would not be worth it. Instead, it should be updated via MS Word.
     */
    'ZustandDesFahrzeugsBeiBesichtigung',
];

/**
 * Each user gets their own color, independent from the device type he's using.
 */
export const realtimeEditorColors: string[] = [
    '#15a9e8',
    '#ff3366',
    '#bc52cc',
    '#37d958',
    '#ffae00',
    '#28dac4',
    '#515c6d',
];

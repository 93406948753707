import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AxError } from '@autoixpert/models/errors/ax-error';

/**
 * Ensure the frontend code can always handle errors the same way: Through AxErrors. This ensures that code like
 *     try {
 *         ...
 *     }
 *     catch (error) {
 *         if (error.code === 'AX_ERROR_EXAMPLE_CODE) {
 *             return doSomething();
 *         }
 *         throw error;
 *     }
 * works. If the catch block would catch the response, a type error would live at response but an HTTP error would live at
 * response.error. Having equal error types simplifies code.
 */
@Injectable()
export class ConvertToAxErrorInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler) {
        // Pass on the configured observable to the caller of this interceptor
        return next.handle(request).pipe(
            // configure dealing with the specific error type
            catchError((response: HttpErrorResponse) => {
                //*****************************************************************************
                //  Handle Blob, JSON or Text Response
                //****************************************************************************/
                return (
                    new Observable<HttpErrorResponse>((observer) => {
                        // Extract data from Blob
                        if (response.error instanceof Blob) {
                            let error;

                            const fileReader = new FileReader();
                            fileReader.addEventListener('loadend', (event: ProgressEvent) => {
                                try {
                                    error = JSON.parse(fileReader.result as string);
                                } catch (jsonParseError) {
                                    // If the response isn't JSON, it's probably some HTML NGINX error.
                                    error = fileReader.result;
                                }

                                // Overwrite the (readonly) error property by cloning the object
                                response = new HttpErrorResponse({
                                    error,
                                    headers: response.headers,
                                    status: response.status,
                                    statusText: response.statusText,
                                    url: response.url,
                                });
                                observer.next(response);
                            });
                            fileReader.readAsText(response.error);
                        }
                        // If the error is not inside a blob, pass it on as-is
                        else {
                            observer.next(response);
                        }
                    })
                        /////////////////////////////////////////////////////////////////////////////*/
                        //  END Handle Blob, JSON or Text Response
                        /////////////////////////////////////////////////////////////////////////////*/
                        .pipe(
                            //*****************************************************************************
                            //  Deserialize AxError
                            //****************************************************************************/
                            switchMap((response) => {
                                let error: AxError;

                                if (response.error?.type === 'AxError') {
                                    error = AxError.from({
                                        ...response.error,
                                        data: {
                                            ...(response.error.data || {}),
                                            apiName: request.url,
                                        },
                                    });
                                } else {
                                    error = new AxError({
                                        code: 'GENERIC_API_ERROR',
                                        message: `While requesting resources from the external service "${request.url}", a non-AxError occurred. Please have a look at the error property of this error.`,
                                        data: {
                                            apiName: request.url,
                                            requestUrl: request.url,
                                            responseBody: response.error,
                                            /**
                                             * AutoiXpert-internal requests do not use headers for relevant error information.
                                             *
                                             * External APIs like the AWS S3 API might return relevant information in the headers but
                                             * since they are CORS requests, the browser by default limits the headers that can be read.
                                             * See https://stackoverflow.com/questions/48413050/missing-headers-in-fetch-response
                                             * While we could add the header to S3 to allow reading CORS response headers, we decided
                                             * against it to keep our code clean from such workarounds. Makes sense for other
                                             * external APIs we might use in the future and which we cannot configure, too.
                                             */
                                            // responseHeaders: extractRelevantHttpResponseHeaders(responseHeaders),
                                            httpStatusCode: response.status,
                                        },
                                    });
                                }

                                return observableThrowError(error);
                            }),
                            /////////////////////////////////////////////////////////////////////////////*/
                            //  END Deserialize AxError
                            /////////////////////////////////////////////////////////////////////////////*/
                        )
                );
            }),
        );
    }
}

import { AxEmailOAuthProviderName } from '../../internal-access-token';

export class EmailAccount {
    // The username is used for authentication. If "email" is not specified, take the username for sending, too.
    username: string = null;
    email: string = null;
    password: string = null;
    host: string = null;
    port: number = null;
    displayName: string = null; // The name displayed in outgoing e-mail
    /**
     * Allow the SMTP server connection to be started unencrypted, via SSL or first unencrypted and later upgraded to TLS.
     */
    connectionStartType?: 'starttls' | 'ssl' | 'unencrypted';

    /**
     * Used for authentication with Gmail or Outlook.com.
     */
    oauthProvider?: AxEmailOAuthProviderName;
}

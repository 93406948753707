import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import copyTextToClipboard from 'copy-text-to-clipboard';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { translateReportType } from '@autoixpert/lib/report/translate-report-type';
import { CustomFieldDropdownOption } from '@autoixpert/models/custom-fields/custom-field-dropdown-option';
import { CustomFieldLocation } from '@autoixpert/models/custom-fields/custom-field-location';
import { FieldConfig } from '@autoixpert/models/custom-fields/field-config';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { Report } from '@autoixpert/models/reports/report';
import { TeamWebhook } from '@autoixpert/models/teams/team-webhook';
import { TeamWebhookService } from 'src/app/shared/services/team-webhook.service';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';
import { slideInAndOutHorizontally } from '../../../../shared/animations/slide-in-and-out-horizontally.animation';
import { slideInAndOutVertically } from '../../../../shared/animations/slide-in-and-out-vertical.animation';
import { convertFieldTitleToPlaceholderName } from '../../../../shared/libraries/template-engine/convert-field-title-to-placeholder-name';
import { FieldGroupConfigService } from '../../../../shared/services/field-group-config.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
    selector: 'custom-field-editor-dialog',
    templateUrl: 'custom-field-editor-dialog.component.html',
    styleUrls: ['custom-field-editor-dialog.component.scss'],
    animations: [
        slideInAndOutHorizontally(),
        dialogEnterAndLeaveAnimation(),
        runChildAnimations(),
        slideInAndOutVertically(),
    ],
})
export class CustomFieldEditorDialogComponent implements OnInit {
    constructor(
        private toastService: ToastService,
        private dialogService: MatDialog,
        private fieldGroupConfigService: FieldGroupConfigService,
        private teamWebhookService: TeamWebhookService,
    ) {}

    @Input() fieldLocation: CustomFieldLocation;
    @Input() reportType: Report['type'];
    @Input() fieldGroupConfig: FieldGroupConfig;
    @Input() selectedFieldConfig: FieldConfig;

    @Output() fieldConfigChange: EventEmitter<FieldConfig> = new EventEmitter();
    @Output() sortOrderChange: EventEmitter<void> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;

    public reportTypesDialogShown: boolean;
    public isSuffixInputVisible: boolean;

    protected webhooks: TeamWebhook[] = [];

    /**
     * Load and store all configs, so we can detect duplicate field names.
     */
    private allFieldGroupConfigs: FieldGroupConfig[];

    async ngOnInit() {
        // Call the selection routine on the field config passed from the parent.
        this.selectFieldConfig(this.selectedFieldConfig);

        this.allFieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        this.webhooks = await this.teamWebhookService.find().toPromise();
    }

    //*****************************************************************************
    //  CRUD Custom Field Configs
    //****************************************************************************/
    public createCustomFieldConfig() {
        const newConfig: FieldConfig = new FieldConfig({
            reportTypes: [this.reportType],
        });

        this.fieldGroupConfig.fieldConfigs.push(newConfig);
        this.selectFieldConfig(newConfig);

        // Let parent know about change.
        this.emitFieldConfigChange();
    }

    /**
     * Delete a global custom field config associated with a field.
     *
     * Remove the custom field on this report.
     */
    public async deleteFieldConfig(fieldConfig: FieldConfig) {
        // Shorthand
        const fieldConfigs: FieldConfig[] = this.fieldGroupConfig.fieldConfigs;

        const index: number = fieldConfigs.indexOf(fieldConfig);
        const isLastElementInList: boolean = index === fieldConfigs.length - 1;

        const decision = await this.dialogService
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Feld global löschen?',
                    content:
                        'Das Feld wird für alle Gutachten gelöscht. Das ist nicht widerrufbar.\n\nFalls du dieses Feld in Bedingungen von Textbausteinen verwendet hast, musst du diese anschließend auch entfernen.',
                    confirmLabel: 'Löschen',
                    cancelLabel: 'Behalten',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();

        if (!decision) return;

        // Delete field config
        removeFromArray(fieldConfig, fieldConfigs);

        // Let parent know.
        this.emitFieldConfigChange();

        // If deleted element was selected, select another one automatically.
        if (this.selectedFieldConfig === fieldConfig) {
            if (isLastElementInList) {
                this.selectedFieldConfig = fieldConfigs.at(-1);
            } else {
                // The current item has been removed, so the one after it is now placed at its index.
                this.selectedFieldConfig = fieldConfigs[index];
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Custom Field Configs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Field Config List
    //****************************************************************************/
    public selectFieldConfig(fieldConfig: FieldConfig) {
        this.selectedFieldConfig = fieldConfig;

        // Focus title field if empty
        if (!fieldConfig.name) {
            // Give Angular time to update the view before focusing.
            setTimeout(() => this.titleInput.nativeElement.focus(), 0);
        }

        // Display or hide suffix input.
        this.isSuffixInputVisible = !!this.selectedFieldConfig.suffix;
    }

    public reorderCustomFieldConfigArray(event: CdkDragDrop<CustomFieldDropdownOption[]>): void {
        // Shorthand
        const customFieldConfigs: FieldConfig[] = this.fieldGroupConfig.fieldConfigs;

        const movedLineItem = customFieldConfigs.splice(event.previousIndex, 1)[0];
        // Add the item back at the new position
        customFieldConfigs.splice(event.currentIndex, 0, movedLineItem);

        // Notify parents that they must re-sort their displayed elements.
        this.sortOrderChange.emit();

        // Save the new order back to the server.
        this.emitFieldConfigChange();
    }

    public getVisibilityTooltip(fieldConfig: FieldConfig): string {
        let currentVisibility: string;
        if (fieldConfig.reportTypes.length) {
            currentVisibility = `Dieses Feld ist nur in ${
                fieldConfig.reportTypes.length === 1 ? 'dem Vorgangstyp' : 'den Vorgangstypen'
            } ${this.getSelectedReportTypesList(fieldConfig)} sichtbar.`;
        } else {
            currentVisibility = 'Dieses Feld ist in keinem Vorgangstyp sichtbar.';
        }

        return `${currentVisibility}\n\nKlicke, um es für den aktuellen Gutachtentyp ${translateReportType(
            this.reportType,
        )} einzublenden.`;
    }

    public activateFieldForCurrentReportType(fieldConfig: FieldConfig) {
        fieldConfig.reportTypes.push(this.reportType);
        this.emitFieldConfigChange(fieldConfig);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Field Config List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Field Type
    //****************************************************************************/
    public selectFieldType(fieldType: FieldConfig['type']) {
        this.selectedFieldConfig.type = fieldType;

        // Add dropdownOptions array if the field type requires it.
        if (this.isDropdownType()) {
            if (!this.selectedFieldConfig.dropdownOptions) {
                this.selectedFieldConfig.dropdownOptions = [new CustomFieldDropdownOption()];
            }
        } else {
            this.selectedFieldConfig.dropdownOptions = null;
        }

        // Remove suffix where not allowed.
        if (!this.isSuffixAllowed(fieldType)) {
            this.deleteSuffix();
            this.hideSuffixInput();
        }

        // Make sure the type of the default value matches the field type.
        this.sanitizeDefaultValue();
    }

    /**
     * Does the current type include dropdowns?
     */
    public isDropdownType(fieldType: FieldConfig['type'] = this.selectedFieldConfig.type): boolean {
        return fieldType === 'select' || fieldType === 'autocomplete';
    }

    /**
     * Does the type produce two separate placeholders? (MyCheckbox and MyCheckboxJaNeinUnbekannt)
     */
    public isCheckboxType(fieldType: FieldConfig['type'] = this.selectedFieldConfig.type): boolean {
        return fieldType === 'boolean' || fieldType === 'tristate';
    }

    /**
     * True for
     * - single-line
     * - multi-line
     * - number
     * - select (dropdown)
     * - autocomplete
     */
    public isSuffixAllowed(fieldType: FieldConfig['type'] = this.selectedFieldConfig.type): boolean {
        return new Array<FieldConfig['type']>(
            'singleLineText',
            'multiLineText',
            'number',
            'select',
            'autocomplete',
        ).includes(fieldType);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Field Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Field Placeholder
    //****************************************************************************/
    /**
     * Remove spaces and other special characters from the field name and store it as the placeholder.
     */
    public updatePlaceholder() {
        if (this.selectedFieldConfig.name) {
            this.selectedFieldConfig.placeholder = convertFieldTitleToPlaceholderName(this.selectedFieldConfig.name);
        } else {
            this.selectedFieldConfig.placeholder = null;
        }
    }

    public copyPlaceholderToClipboard(suffix?: string) {
        if (!this.selectedFieldConfig.placeholder) {
            this.toastService.warn('Titel fehlt', 'Platzhalter können nur für Felder mit Titel kopiert werden.');
            return;
        }

        const fullPathPlaceholder = `Gutachten.EigeneFelder.${this.selectedFieldConfig.placeholder}${suffix || ''}`;

        const itWorked = copyTextToClipboard(fullPathPlaceholder);
        if (itWorked) {
            this.toastService.success('Platzhalter kopiert', `Platzhalter: "${fullPathPlaceholder}"`);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Field Placeholder
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Suffix
    //****************************************************************************/
    public showSuffixInput() {
        this.isSuffixInputVisible = true;
    }

    public hideSuffixInput() {
        this.isSuffixInputVisible = false;
    }

    public deleteSuffix() {
        this.selectedFieldConfig.suffix = null;
        this.emitFieldConfigChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Suffix
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Dropdown Options
    //****************************************************************************/
    public removeSelectOption(selectOption: CustomFieldDropdownOption) {
        // In case this was the default value, clear the default.
        if (this.selectedFieldConfig.defaultValue === selectOption.value) {
            this.selectedFieldConfig.defaultValue = null;
        }

        removeFromArray(selectOption, this.selectedFieldConfig.dropdownOptions);
    }

    public reorderDropdownOptionsArray(event: CdkDragDrop<CustomFieldDropdownOption[]>): void {
        const movedLineItem = this.selectedFieldConfig.dropdownOptions.splice(event.previousIndex, 1)[0];
        // Add the item back at the new position
        this.selectedFieldConfig.dropdownOptions.splice(event.currentIndex, 0, movedLineItem);

        // Save the new order back to the server.
        this.emitFieldConfigChange();
    }

    public addDropdownOption() {
        this.selectedFieldConfig.dropdownOptions.push(new CustomFieldDropdownOption());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dropdown Options
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Default Values
    //****************************************************************************/
    /**
     * If the default value is no coherent with the value type, remove it.
     */
    public sanitizeDefaultValue() {
        switch (this.selectedFieldConfig.type) {
            case 'number':
            case 'currency':
                if (typeof this.selectedFieldConfig.defaultValue !== 'number') {
                    this.selectedFieldConfig.defaultValue = null;
                    this.emitFieldConfigChange();
                }
                break;
            case 'boolean':
            case 'tristate':
                if (typeof this.selectedFieldConfig.defaultValue !== 'boolean') {
                    this.selectedFieldConfig.defaultValue = null;
                    this.emitFieldConfigChange();
                }
                break;
            case 'date':
            case 'select':
            case 'autocomplete':
            case 'singleLineText':
            case 'multiLineText':
                if (typeof this.selectedFieldConfig.defaultValue !== 'string') {
                    this.selectedFieldConfig.defaultValue = null;
                    this.emitFieldConfigChange();
                }
                break;
            case 'button':
                if (typeof this.selectedFieldConfig.defaultValue !== 'string') {
                    this.selectedFieldConfig.defaultValue = null;
                    this.emitFieldConfigChange();
                }
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default Values
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Field Location
    //****************************************************************************/
    public translateFieldLocation(): string {
        switch (this.fieldLocation) {
            case 'accident-data_claimant':
                return 'Unfall & Beteiligte » Anspruchsteller';
            case 'accident-data_lawyer':
                return 'Unfall & Beteiligte » Anwalt';
            case 'accident-data_report-properties':
                return 'Unfall & Beteiligte » Gutachten-Eigenschaften';
            case 'accident-data_accident':
                return 'Unfall & Beteiligte » Unfalldaten';
            case 'accident-data_author-of-damage':
                return 'Unfall & Beteiligte » Unfallgegner';
            case 'accident-data_insurance':
                return 'Unfall & Beteiligte » Versicherung';
            case 'accident-data_claimant-signatures':
                return 'Unfall & Beteiligte » Unterschriften';
            case 'car-data_basic-data':
                return 'Fahrzeugdaten » Basisdaten';
            //case 'car-data_extended-data':
            //    return "Fahrzeugdaten » Erweiterte Daten";
            case 'car-data_equipment':
                return 'Fahrzeugdaten » Ausstattung';
            case 'car-condition_condition':
                return 'Zustand » Zustand';
            case 'car-condition_damage-sketch':
                return 'Zustand » Anstoßskizze';
            case 'car-condition_paint-thickness-sketch':
                return 'Zustand » Lackschichtdicke';
            case 'car-condition_tires':
                return 'Zustand » Reifen';
            //case 'car-condition_previous-and-unrepaired-damages':
            //    return "Zustand » Vor- & Altschäden";
            //case 'car-condition_damage-description':
            //    return "Zustand » Schadenbeschreibung";
            case 'damage-calculation_garage':
                return 'Kalkulation » Werkstatt';
            case 'damage-calculation_calculation':
                return 'Kalkulation » Kalkulation';
            case 'damage-calculation_market-analysis':
                return 'Kalkulation » Marktwert';
            case 'damage-calculation_downtime-compensation':
                return 'Kalkulation » Nutzungsausfall';
            case 'damage-calculation_residual-value':
                return 'Kalkulation » Restwert';
            case 'damage-calculation_old-vehicle-certificates':
                return 'Kalkulation » Altfahrzeugzertifikate';
            case 'damage-calculation_vehicle-value':
                return 'Kalkulation » Fahrzeugwert';
            case 'damage-calculation_repair':
                return 'Kalkulation » Reparatur';
            case 'fees':
                return 'Rechnung';
            case 'print-and-transmission':
                return 'Druck & Versand';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Field Location
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Types
    //****************************************************************************/
    public showReportTypesDialog() {
        this.reportTypesDialogShown = true;
    }

    public hideReportTypesDialog() {
        this.reportTypesDialogShown = false;
    }

    public getSelectedReportTypesList(fieldConfig: FieldConfig = this.selectedFieldConfig): string {
        return fieldConfig.reportTypes.map((reportType) => translateReportType(reportType)).join(', ');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Types
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitFieldConfigChange(fieldConfig: FieldConfig = this.selectedFieldConfig) {
        this.fieldConfigChange.emit(fieldConfig);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Dialog Controls
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    public closeDialog() {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    public keyboardListener(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                event.stopPropagation();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog Controls
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Validation
    //****************************************************************************/
    /**
     * Check if the given field name is already used by another custom field config. This function actually
     * checks if the resulting "placeholder" (derived from the name for custom field configs) might collide
     * with other field configs.
     */
    protected isFieldNameAlreadyTaken(fieldConfig: FieldConfig): boolean {
        return this.allFieldGroupConfigs?.some((configGroup) =>
            configGroup.fieldConfigs.some(
                (config) =>
                    config.placeholder === convertFieldTitleToPlaceholderName(fieldConfig.name) &&
                    config._id !== fieldConfig._id,
            ),
        );
    }

    /**
     * Checks if the given custom field name is valid, meaning that it is not already taken by another field config
     * and it is not empty.
     */
    protected isFieldNameValid(fieldConfig: FieldConfig): boolean {
        return !this.isFieldNameAlreadyTaken(fieldConfig) && fieldConfig.name !== '';
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Validation
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Report } from '../../models/reports/report';
import { Team } from '../../models/teams/team';

/**
 * Check if the user wants to print the custom residual value request bidders without bids.
 * Preference in report overwrites preference in team.
 */
export const getHideBiddersWithoutBidSetting = (report: Report, team: Team) => {
    const includeSettingReport = report.valuation.includeBiddersWithNoBidInCustomResidualValueSheet;
    const includeSettingTeam = team.preferences.includeBiddersWithNoBidInCustomResidualValueSheet;
    return includeSettingReport != null ? includeSettingReport : !!includeSettingTeam;
};

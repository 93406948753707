import { ContactPerson } from '../../models/contacts/contact-person';
import { InvoiceParameters } from '../../models/invoices/invoice-parameters';
import { Report } from '../../models/reports/report';

export function getInvoiceRecipientByRole(
    recipientRole: InvoiceParameters['recipientRole'],
    report: Pick<
        Report,
        'feeCalculation' | 'garage' | 'insurance' | 'lawyer' | 'leaseProvider' | 'ownerOfClaimantsCar' | 'claimant'
    >,
): ContactPerson {
    switch (recipientRole) {
        case 'custom':
            return report.feeCalculation.invoiceParameters.recipient;
        case 'garage':
            return report.garage.contactPerson;
        case 'insurance':
            return report.insurance.contactPerson;
        case 'lawyer':
            return report.lawyer.contactPerson;
        case 'leaseProvider':
            return report.leaseProvider.contactPerson;
        case 'ownerOfClaimantsCar':
            return report.ownerOfClaimantsCar.contactPerson;
        case 'claimant':
        /**
         * In case the user has not set a role at all, use the claimant by default.
         */
        default:
            return report.claimant.contactPerson;
    }
}

import { formatNumber } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { toIsoDate, todayIso } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { GtueImportHistory } from '@autoixpert/models/gtue-import-history';
import { Team } from '@autoixpert/models/teams/team';
import { slideInAndOutHorizontally } from '../../animations/slide-in-and-out-horizontally.animation';
import { slideInAndOutVertically } from '../../animations/slide-in-and-out-vertical.animation';
import { ApiErrorService } from '../../services/api-error.service';
import { GtueImportHistoryService } from '../../services/gtue-import-history.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { TeamService } from '../../services/team.service';
import { ToastService } from '../../services/toast.service';

interface SubOffice {
    label: string;
    id: string;
    location: string;
    include: boolean;
}

@Component({
    selector: 'gtue-invoice-import-dialog',
    templateUrl: './gtue-invoice-import-dialog.component.html',
    styleUrls: ['./gtue-invoice-import-dialog.component.scss'],
    animations: [slideInAndOutHorizontally(), slideInAndOutVertically(), dialogEnterAndLeaveAnimation()],
})
export class GtueInvoiceImportDialogComponent implements OnInit, OnDestroy {
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    /**
     * EventEmitter that notifies the parent, that the history rewind was completed -> parent updates invoice list.
     */
    @Output() rewindSuccessful: EventEmitter<void> = new EventEmitter<void>();

    /**
     * EventEmitter that notifies the parent, that the import was successful -> parent updates invoice list.
     */
    @Output() importSuccessful: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Invoices within this range will be imported.
     */
    protected importFromDate: IsoDate;
    protected importToDate: IsoDate;

    /**
     * Imported invoices will have a due date calculated as invoice date + daysUntilDue
     */
    protected daysUntilDue: number = 30;

    /**
     * Model value for the checkbox that determines if invoices from all sub offices should be imported
     * or if the user wants to pick which suboffice should be included.
     */
    protected includeAllSubOffices: boolean = true;

    /**
     * List of sub offices of the user. If includeAllSubOffices is false, the user can pick from this list
     * which sub offices should be included in the import and which ones should not.
     */
    protected subOffices: Array<SubOffice> = [];

    // Import Histories
    protected importHistories: GtueImportHistory[] = [];
    protected importHistoryDeletionPending: boolean;

    protected importInProgress: boolean = false;
    protected userIsLoggedIn: boolean = false;
    protected team: Team;

    /**
     * GTÜ import is limited to last 27 months.
     */
    protected minStartDateForImport: Date;

    /**
     * Subject used to subscribe to observables with takeUntil only until
     * this subject gets destroyed (in onDestroy lifecycle method).
     */
    private destroySubject$: Subject<void> = new Subject<void>();

    private subscriptions: Subscription[] = [];

    constructor(
        private httpClient: HttpClient,
        private toastService: ToastService,
        private importHistoryService: GtueImportHistoryService,
        private apiErrorService: ApiErrorService,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
    ) {}

    ngOnInit() {
        this.getImportHistories();

        // Read default values to prefill input fields (e.g. officeId, daysUntilDue)
        const userSubscription: Subscription = this.loggedInUserService
            .getTeam$()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe((team) => {
                if (team) {
                    // In case the backend has a still valid refresh token, we display the user
                    // as logged in, because the backend can use the refresh token to get a new
                    // access token. Hence, the user does not need to login again.
                    this.userIsLoggedIn = team.gtue?.refreshTokenExpirationDate
                        ? new Date(team.gtue.refreshTokenExpirationDate) > new Date()
                        : false;
                    this.team = team;

                    // No sub offices selected yet means we import for all
                    this.includeAllSubOffices = team.gtue?.selectedSubOfficeIds?.length === 0;

                    // Map the available sub offices to the frontends SubOffice datastructure, which
                    // also contains a boolean whether this sub office was selected by the user for the import.
                    this.subOffices =
                        team.gtue?.availableSubOffices?.map((subOffice): SubOffice => {
                            return {
                                label: subOffice.name,
                                id: subOffice.officeId,
                                location: [subOffice.streetAndHouseNumber, subOffice.city].filter(Boolean).join(', '),
                                include: this.includeAllSubOffices
                                    ? true
                                    : (team.gtue?.selectedSubOfficeIds?.includes(subOffice.officeId) ?? true),
                            };
                        }) ?? [];

                    // Use the last stored days until due (set after each import in the backend).
                    const daysUntilDue = team.gtue?.daysUntilDue;
                    if (daysUntilDue != null) {
                        this.daysUntilDue = team.gtue.daysUntilDue;
                    }
                }
            });

        // Listen for live sync patches on the team object, because before the user gets redirected to
        // the invoice list (with GTÜ import dialog) the backend patches the expiration date of the refresh
        // token. That date determines if the dialog shows the login or logged in state. If we are not listening
        // for patches here, we receive the old expiration date and the user is shown the login button again.
        const teamSubscription: Subscription = this.teamService.patchedFromExternalServerOrLocalBroadcast$
            .pipe(takeUntil(this.destroySubject$))
            .subscribe((team) => {
                const gtueData = team.patchedRecord.gtue;
                if (gtueData) {
                    this.userIsLoggedIn = gtueData.refreshTokenExpirationDate
                        ? new Date(gtueData.refreshTokenExpirationDate) > new Date()
                        : false;
                }
            });

        this.subscriptions.push(userSubscription, teamSubscription);

        const now = new Date();
        this.minStartDateForImport = new Date();
        // GTÜ import is limited to last 27 months (due to DSGVO restrictions).
        this.minStartDateForImport.setMonth(now.getMonth() - 27);
    }

    /**
     * Starts the import. First checks if the import date is valid.
     */
    protected importInvoices(): void {
        if (!this.importFromDate || !this.importToDate || this.importFromDate > this.importToDate) {
            this.toastService.error(
                'Zeitraum für Import ungültig',
                'Wähle ein Start- und Enddatum aus und stelle sicher, dass das Startdatum vor dem Enddatum liegt.',
            );

            return;
        }

        const rangeIsMoreThan3Years =
            DateTime.fromISO(this.importToDate).diff(DateTime.fromISO(this.importFromDate), 'years').years > 3;
        if (rangeIsMoreThan3Years) {
            this.toastService.error(
                'Zeitraum zu groß für Import',
                'Du kannst maximal 3 Jahre auf einmal importieren. Benötigst du einen größeren Zeitraum, teile diesen in mehrere Importe auf.',
            );

            return;
        }

        this.importInProgress = true;

        // In case the user disabled the toggle "all sub offices" but still selected each sub office
        // we store an empty array for the selected sub offices. Because if in the future a new sub office
        // is added, it will not be excluded.
        const allSubOfficesSelected =
            this.includeAllSubOffices || this.subOffices.every((subOffice) => subOffice.include);
        const params = new HttpParams({
            fromObject: {
                dateFrom: this.importFromDate,
                dateTo: this.importToDate,
                daysUntilDue: this.daysUntilDue,
                subOffices: allSubOfficesSelected
                    ? ''
                    : this.subOffices
                          .filter((office) => office.include)
                          .map((office) => office.id)
                          .join(','),
            },
        });

        this.httpClient
            .get<{ numberOfImportedInvoices: string }>(`/api/v0/gtue/invoiceImport`, {
                params,
            })
            .subscribe({
                next: (response) => {
                    this.importSuccessful.emit();
                    this.toastService.success(
                        'Import erfolgreich',
                        `Es wurden ${response.numberOfImportedInvoices} Rechnungen importiert.`,
                    );
                    this.closeDialog();
                },
                error: (error) => {
                    this.importInProgress = false;
                    console.error('ERROR_IMPORTING_GTUE_INVOICES', { error });

                    if (
                        error.code === 'GTUE_REFRESH_ACCESS_TOKEN_FAILED' ||
                        error.code === 'GTUE_ACCESS_TOKEN_INVALID'
                    ) {
                        // When access/refresh tokens were invalid -> ask the user to login again
                        this.toastService.error(
                            'GTÜ-Login abgelaufen',
                            'Der GTÜ-Login ist nicht mehr gültig. Bitten melde dich erneut an. Falls du Probleme dabei hast, kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                        );
                    } else if (error.code === 'GTUE_OFFICE_ID_WRONG') {
                        // Probably the office ID is wrong (not associated with the logged in user)
                        this.toastService.error(
                            'GTÜ-Büronummer falsch',
                            'Die angegebene BÜronummer scheint nicht zum eingeloggten Nutzer zu gehören. Versuche dich mit der korrekten Büronummer erneut einzuloggen. Falls du Probleme dabei hast, kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                        );
                    } else if (error.data.httpStatusCode === 404) {
                        // When running into server timeout -> tell user
                        this.toastService.info(
                            'GTÜ-Rechnungsimport dauert länger',
                            'Der Import von Rechnungen kann insbesondere bei größeren Zeiträumen etwas länger dauern. Du kannst diese Seite verlassen, der Import läuft im Hintergrund weiter. Falls die Rechnungen nach einiger Zeit immer noch nicht in der Rechnungsliste erscheinen, probiere es erneut oder kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                            { timeOut: 0 },
                        );
                    } else {
                        // Generic error
                        this.toastService.error(
                            'GTÜ-Rechnungsimport gescheitert',
                            'Bitte kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                        );
                    }
                },
            });
    }

    /**
     * Send the user to the GTÜ OAuth login page. This page opens in the current tab and after successful
     * login redirects the user to autoiXpert (same page as before).
     */
    protected async loginGtue() {
        const response = await this.httpClient.get<{ url: string }>(`/api/v0/gtue/auth/url`).toPromise();

        window.open(response.url, '_self');
    }

    /**
     * Let's the user edit the office ID, which switches the dialog to the logged-out state.
     */
    protected async logoutGtue() {
        const response = await this.httpClient.get<{ logoutUrl: string }>(`/api/v0/gtue/auth/logout`).toPromise();

        if (response.logoutUrl) {
            window.open(response.logoutUrl, '_self');
        } else {
            console.log('GTÜ: No logout URL received.');
        }

        // Clear any GTÜ user related data.
        this.subOffices = [];
        this.userIsLoggedIn = false;
    }

    protected closeDialog(): void {
        this.close.emit();
    }

    //*****************************************************************************
    //  Import Histories
    //****************************************************************************/
    private getImportHistories() {
        this.importHistoryService.find().subscribe({
            next: (importHistories) => {
                this.importHistories = importHistories;

                // Update the import date range. If there was a previous import, use that date
                // as the fromDate. Otherwise just use the first day of the current month.
                const defaultFromDate = new Date();
                defaultFromDate.setDate(1);

                this.importFromDate = toIsoDate(this.importHistories.at(-1)?.createdAt) ?? toIsoDate(defaultFromDate);
                this.importToDate = todayIso();
            },
        });
    }

    private isDeletingInvoiceHistoryAllowed() {
        // only allow admins to delete the import history
        return isAdmin(this.loggedInUserService.getUser()._id, this.loggedInUserService.getTeam());
    }

    protected async rewindImport(importHistory: GtueImportHistory) {
        // Locked invoices may only be deleted if the current user is a team admin.
        if (!this.isDeletingInvoiceHistoryAllowed()) {
            this.toastService.error(
                'Gebuchte Rechnung',
                'Eine gesamte Import-Historie kann nur vom Administrator deines Teams gelöscht werden.',
            );
            return;
        }

        const { index } = removeFromArray(importHistory, this.importHistories);

        this.importHistoryDeletionPending = true;

        try {
            await this.importHistoryService.delete(importHistory._id, { waitForServer: true });
        } catch (error) {
            this.importHistoryDeletionPending = false;

            // Add back record in UI
            this.importHistories.splice(index, 0, importHistory);

            // Display error
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Importierte Datensätze konnten nicht gelöscht werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
        this.toastService.success(
            'Importierte GTÜ-Rechnungen gelöscht',
            `Löschen abgeschlossen: Import vom ${DateTime.fromISO(importHistory.createdAt).toFormat('dd.MM.yyyy - HH:mm')} mit ${formatNumber(importHistory.numberOfObjects, 'de-de')} Datensätzen.`,
        );
        this.importHistoryDeletionPending = false;
        this.rewindSuccessful.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Import Histories
    /////////////////////////////////////////////////////////////////////////////*/

    protected isStartDateOutsideAllowedImportRange(): boolean {
        if (!this.importFromDate) {
            return false;
        }

        return new Date(this.importFromDate) < this.minStartDateForImport;
    }

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    //*****************************************************************************
    //  Keyboard Events
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.unsubscribe();

        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

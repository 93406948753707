import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { DocumentOrderItem } from '@autoixpert/models/documents/document-order-item';
import { Report } from '@autoixpert/models/reports/report';
import { getDocumentOrdersOfCommunicationRecipientsFromReport } from './get-document-orders-of-communication-recipients-from-report';
import { DocumentGroupForDocumentRemoval } from './remove-document-from-report';
import { setDocumentInclusionStatus } from './set-document-inclusion-status';

/**
 * Activate or deactivate a document for inclusion as an email attachment or as a part of the full report PDF.
 */
export function setReportDocumentInclusionStatus({
    document,
    report,
    documentGroup = 'both',
    includedInFullDocument,
}: {
    document: DocumentMetadata;
    report: Report;
    documentGroup: DocumentGroupForDocumentRemoval;
    includedInFullDocument: DocumentOrderItem['includedInFullDocument'];
}): void {
    const documentOrders: DocumentOrder[] = getDocumentOrdersOfCommunicationRecipientsFromReport({
        report,
        documentGroup,
    });

    setDocumentInclusionStatus({
        document: document,
        documentOrders,
        includedInFullDocument,
    });
}

import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { get } from 'lodash-es';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { getAvailablePlaceholders } from '@autoixpert/lib/document-building-blocks/get-available-placeholders';
import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { isDocumentBuildingBlockSavingConditionAllowed } from '../../shared/components/document-building-block-condition-group-editor/document-building-block-condition-group-editor.utils';
import { FieldGroupConfigService } from '../../shared/services/field-group-config.service';

/**
 * A dialog to edit the conditions of a document building block.
 * These top-level conditions of the document building block are evaluated before the conditions of the variants.
 */
@Component({
    selector: 'document-building-block-editor',
    templateUrl: 'document-building-block-editor.component.html',
    styleUrls: ['document-building-block-editor.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class DocumentBuildingBlockEditorComponent {
    constructor(
        private zone: NgZone,
        private dialog: MatDialog,
        private fieldGroupConfigService: FieldGroupConfigService,
    ) {}

    @Input('buildingBlock') originalBuildingBlock: DocumentBuildingBlock;

    // Placeholder values for the selected report or invoice, used to show values in property path selection overlay and for replace placeholders in the preview
    @Input() placeholderValues: PlaceholderValues | undefined;

    @Output() close = new EventEmitter<void>();
    @Output() buildingBlockChange: EventEmitter<DocumentBuildingBlock> = new EventEmitter<DocumentBuildingBlock>();

    public buildingBlock: DocumentBuildingBlock;

    // Custom Fields
    public fieldGroupConfigs: FieldGroupConfig[] = [];
    public placeholderValueTree: PlaceholderValueTree;
    public availableDocumentPlaceholders: string[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        // Let the user work on a copy until the user saves. Otherwise, the cancel button would not make sense.
        this.copyBuildingBlock();

        this.registerKeyboardShortcuts();

        await this.loadFieldGroupConfigsAndPlaceholderValueTree();
    }

    private async loadFieldGroupConfigsAndPlaceholderValueTree() {
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        this.placeholderValueTree = getPlaceholderValueTree({
            fieldGroupConfigs: this.fieldGroupConfigs,
        });

        this.availableDocumentPlaceholders = this.getAvailableDocumentPlaceholders();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    private copyBuildingBlock(): void {
        if (!this.originalBuildingBlock) {
            throw Error(
                'MISSING_BUILDILNG_BLOCK_FROM_PARENT_COMPONENT: The calling component did not supply a building block to the building block conditions editor.',
            );
        }
        // Only work on the copy until the user saves
        this.buildingBlock = JSON.parse(JSON.stringify(this.originalBuildingBlock));
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeAndDiscard();
        }
    }

    public closeAndDiscard(): void {
        this.dispatchCloseEvent({ confirm: true });
    }

    public closeAndSave(): void {
        this.dispatchChangeEvent();
        this.dispatchCloseEvent({ confirm: false });
    }

    private dispatchChangeEvent(): void {
        this.buildingBlockChange.emit(this.buildingBlock);
    }

    private dispatchCloseEvent({ confirm }: { confirm: boolean }): void {
        // Rudimentary check if the user has changed anything
        const hasUserChangedVariant = JSON.stringify(this.buildingBlock) !== JSON.stringify(this.originalBuildingBlock);

        // Do not confirm if the user has not changed anything or confirmation is not required
        if (!confirm || !hasUserChangedVariant) {
            this.close.emit();
            return;
        }

        // Open a confirm dialog
        this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                data: {
                    heading: 'Daten verwerfen?',
                    content:
                        'Du hast die Bedingungen verändert, aber noch nicht gespeichert.\nMöchtest du deine Änderungen verwerfen?',
                    confirmLabel: 'Weg damit',
                    cancelLabel: 'Behalten',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.close.emit();
                    }
                },
            });
    }
    //*****************************************************************************
    //  View Helpers
    //****************************************************************************/
    public isSavingAllowed(): boolean {
        return isDocumentBuildingBlockSavingConditionAllowed(this.buildingBlock, this.placeholderValueTree);
    }

    public propertyPathExists(propertyPath: string): boolean {
        return !!get(this.placeholderValueTree, propertyPath);
    }

    public getSavingButtonTooltip(): string {
        if (!this.isSavingAllowed()) {
            return 'Bitte alle Bedingungen vollständig ausfüllen.';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Helpers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Placeholders
    //****************************************************************************/
    public getAvailableDocumentPlaceholders(): string[] {
        return getAvailablePlaceholders({
            buildingBlockPlaceholder: this.buildingBlock.placeholder,
            fieldGroupConfigs: this.fieldGroupConfigs,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Placeholders
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    private registerKeyboardShortcuts(): void {
        /**
         * No change detection is required on every keypress, only when ctrl & enter are hit at the same time. That is returned to
         * the Angular zone further down in ctrlEnterEventListener.
         */
        this.zone.runOutsideAngular(() => {
            window.addEventListener('keydown', this.keydownEventListener);
        });
    }

    private unregisterKeyboardShortcuts(): void {
        window.removeEventListener('keydown', this.keydownEventListener);
    }

    private keydownEventListener = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            this.zone.run(() => {
                this.closeAndDiscard();
            });
        }

        if (event.ctrlKey && event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            /**
             * The keydown listener was set to run outside of Angular's zone which triggers automatic change detection. Otherwise,
             * entering text really fast in Quill was too slow. It just felt weird when typing fast.
             */
            this.zone.run(() => {
                this.closeAndSave();
            });
        }
    };

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy(): void {
        this.unregisterKeyboardShortcuts();
    }
}

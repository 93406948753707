import { DocumentMetadata, SignableDocumentType } from '../../models/documents/document-metadata';
import { DocumentOrderConfig } from '../../models/documents/document-order-config';
import { AxError } from '../../models/errors/ax-error';
import { Report, ReportType } from '../../models/reports/report';
import { SignableDocument } from '../../models/signable-documents/signable-document';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import {
    defaultDamageReportDocuments,
    defaultValuationReportDocuments,
} from '../../static-data/reports/default-report-documents';
import { addDocumentOrderToReportPerRecipient } from '../documents/add-document-order-to-report-per-recipient';
import { addDocumentToReport } from '../documents/add-document-to-report';
import { DocumentTypes, translateDocumentType } from '../translators/translate-document-type';

const reportTypeSpecificAllowedDocument: {
    assignedReportTypes: ReportType[];
    allowedDocuments: DocumentTypes[];
}[] = [
    {
        assignedReportTypes: ['liability', 'partialKasko', 'fullKasko'],
        allowedDocuments: [
            'letter',
            'report',
            'customResidualValueBidList',
            'winvalueResidualValueBidList',
            'cartvResidualValueBidList',
            'carcasionResidualValueBidList',
            'datDamageCalculation',
            'datMarketAnalysis',
            'audatexMarketAnalysis',
            'winvalueMarketAnalysis',
            'afzzertCertificate',
            'cartvMarketAnalysis',
            'invoice',
            'declarationOfAssignment',
            'revocationInstruction',
            'consentDataProtection',
            'powerOfAttorney',
            'garageInformation',
            'repairConfirmation',
            'expertStatement',
            'datValuationProtocol',
            'manuallyUploadedPdf',
        ],
    },
    {
        assignedReportTypes: ['shortAssessment'],
        allowedDocuments: [
            'letter',
            'report',
            'invoice',
            'declarationOfAssignment',
            'revocationInstruction',
            'consentDataProtection',
            'powerOfAttorney',
            'manuallyUploadedPdf',
            'garageInformation',
            'expertStatement',
            'afzzertCertificate',
        ],
    },
    {
        assignedReportTypes: ['valuation', 'oldtimerValuationSmall', 'leaseReturn', 'usedVehicleCheck'],
        allowedDocuments: [
            'letter',
            'report',
            'invoice',
            'declarationOfAssignment',
            'revocationInstruction',
            'consentDataProtection',
            'manuallyUploadedPdf',
            'expertStatement',
            'afzzertCertificate',
        ],
    },
    {
        assignedReportTypes: ['invoiceAudit'],
        allowedDocuments: ['letter', 'report', 'invoice', 'manuallyUploadedPdf'],
    },
];

/**
 * This function returns the report type specific allowed documents (as list of document types).
 */
export function getReportTypeSpecificAllowedDocuments(report: Report): DocumentTypes[] {
    return reportTypeSpecificAllowedDocument.find((configItem) => configItem.assignedReportTypes.includes(report.type))
        .allowedDocuments;
}

/**
 * This function returns the report type specific default documents
 * as list of DocumentMetadata.
 */
export function getReportTypeSpecificDefaultDocuments({
    report,
    user,
}: {
    report: Report;
    user: User;
}): DocumentMetadata[] {
    let defaultDocuments: DocumentMetadata[];
    switch (report.type) {
        case 'liability':
        case 'partialKasko':
        case 'fullKasko':
        case 'shortAssessment':
            defaultDocuments = defaultDamageReportDocuments;
            break;
        case 'valuation':
        case 'oldtimerValuationSmall':
            defaultDocuments = defaultValuationReportDocuments;
            break;
        case 'leaseReturn':
            defaultDocuments = [
                ...defaultValuationReportDocuments,
                new DocumentMetadata({
                    type: 'letter',
                    title: 'Anschreiben',
                    recipientRole: 'garage',
                    // Will be set below
                    createdBy: undefined,
                }),
                new DocumentMetadata({
                    type: 'letter',
                    title: 'Anschreiben',
                    recipientRole: 'leaseProvider',
                    // Will be set below
                    createdBy: undefined,
                }),
            ];
            break;
        case 'usedVehicleCheck':
            defaultDocuments = [
                ...defaultValuationReportDocuments,
                new DocumentMetadata({
                    type: 'letter',
                    title: 'Anschreiben',
                    recipientRole: 'garage',
                    // Will be set below
                    createdBy: undefined,
                }),
            ];
            break;
        case 'invoiceAudit':
            defaultDocuments = defaultDamageReportDocuments;
            break;
        default:
            return;
    }
    // Return a copy
    return defaultDocuments.map((documentTemplate) => {
        return new DocumentMetadata({
            // Make a copy to avoid references in object properties.
            ...JSON.parse(JSON.stringify(documentTemplate)),
            createdBy: user._id,
        });
    });
}

/**
 * This function returns the report type specific title for the report document.
 * We use the same document type (report) for all report types but assign a different (german) title.
 */
export function getReportTypeSpecificDocumentTitle(report: Report): string {
    switch (report.type) {
        case 'liability':
        case 'partialKasko':
        case 'fullKasko':
            return 'Gutachten';
        case 'shortAssessment':
            return report.useCostEstimateHeading ? 'Kostenvoranschlag' : 'Kurzgutachten';
        case 'valuation':
        case 'oldtimerValuationSmall':
            return 'Fahrzeugbewertung';
        case 'leaseReturn':
            return 'Zustandsbericht';
        case 'usedVehicleCheck':
            return 'Gebrauchtwagencheck';
        case 'invoiceAudit':
            return 'Rechnungsprüfung';
        default:
            return;
    }
}

/**
 * This function handles report type specific documents:
 * - The report document title is dependent on the report type.
 * - Each report type has specific default documents which will be added.
 * - Some documents are not allowed in all types which will be removed.
 */
export function addReportTypeSpecificDocuments({
    report,
    team,
    user,
    documentOrderConfigs,
}: {
    report: Report;
    team: Team;
    user: User;
    documentOrderConfigs: DocumentOrderConfig[];
}): void {
    /**
     * This allows documents to be sorted - even individually per recipient. The latter is an optional team setting.
     */
    addDocumentOrderToReportPerRecipient({
        report,
        documentGroup: 'report',
    });

    //*****************************************************************************
    //  Add Required Documents
    //****************************************************************************/
    /**
     * Each report type needs a set of required documents per default.
     * We add these documents for new reports.
     * If we change an existing report, we merge existing documents with the default documents.
     */
    const defaultDocuments = getReportTypeSpecificDefaultDocuments({ report, user });
    for (const requiredDocument of defaultDocuments) {
        // Some documents (e.g. letter) are different per recipient role -> match type and recipientRole.
        // Only add documents if they dont exist.
        if (
            report.documents.findIndex(
                (existingDocument) =>
                    existingDocument.type === requiredDocument.type &&
                    existingDocument.recipientRole === requiredDocument.recipientRole,
            ) < 0
        ) {
            addDocumentToReport(
                {
                    newDocument: requiredDocument,
                    report,
                    team,
                    documentGroup: 'report',
                },
                {
                    allowMultiple: requiredDocument.type === 'letter',
                },
            );
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add Required Documents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Customer Signature Documents
    //****************************************************************************/
    const existingSignableDocuments = report.signableDocuments;

    /**
     * This setting activates the documents in this report but does not yet add the documents to the documents array.
     * Activated documents are blue on the customer signature documents card in the user interface.
     */
    if (!existingSignableDocuments?.length) {
        // Initialize the documents
        report.signableDocuments = (team.preferences.documentsToBeSigned || []).map((signableDocument) => {
            const [documentType, customDocumentOrderConfigId] = signableDocument.split('-');
            if (documentType === 'customDocument') {
                return new SignableDocument({
                    documentType: documentType as SignableDocumentType,
                    customDocumentOrderConfigId,
                    signatures: [],
                });
            }
            return new SignableDocument({
                documentType: documentType as SignableDocumentType,
                signatures: [],
            });
        });
    }

    // Add documents if the user activated them in their team preferences
    for (const signableDocument of report.signableDocuments) {
        /**
         * Power of attorney: No lawyer? Don't insert the document.
         */
        if (signableDocument.documentType === 'powerOfAttorney' && !report.lawyer?.contactPerson.organization) {
            continue;
        }

        let title = translateDocumentType(signableDocument.documentType);
        /**
         * If this signable document is linked to a custom document order config, read the title from the document order config.
         */
        if (signableDocument.customDocumentOrderConfigId) {
            const documentOrderConfig: DocumentOrderConfig = documentOrderConfigs.find(
                (documentOrderConfig) => documentOrderConfig._id === signableDocument.customDocumentOrderConfigId,
            );
            if (!documentOrderConfig) {
                throw new AxError({
                    code: 'DOCUMENT_ORDER_CONFIG_FOR_CUSTOM_DOCUMENT_NOT_FOUND',
                    message: `The document order config belonging to a custom signature document could not be found but is required for naming the document correctly.`,
                    data: {
                        customDocumentOrderConfigId: signableDocument.customDocumentOrderConfigId,
                        signableDocument,
                        documentOrderConfigs,
                    },
                });
            }

            title = documentOrderConfig.titleLong;
        }

        const newSignableDocument = new DocumentMetadata({
            type: signableDocument.documentType,
            title,
            customDocumentOrderConfigId: signableDocument.customDocumentOrderConfigId,
            createdBy: user._id,
        });
        addDocumentToReport({
            newDocument: newSignableDocument,
            report,
            team,
            documentGroup: 'report',
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Customer Signature Documents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Report Attachments (non-signable)
    //****************************************************************************/
    const nonSignableCustomReportAttachments: DocumentOrderConfig[] = documentOrderConfigs.filter(
        (documentOrderConfig) => {
            return (
                documentOrderConfig.type === 'customDocument' &&
                documentOrderConfig.customDocumentConfig?.isCreatedOnReportCreation &&
                documentOrderConfig.customDocumentConfig?.documentTypeGroup === 'reportAttachment' &&
                documentOrderConfig.customDocumentConfig?.availableInReportTypes.includes(report.type)
            );
        },
    );
    for (const documentOrderConfig of nonSignableCustomReportAttachments) {
        addDocumentToReport({
            report,
            team,
            documentGroup: 'report',
            newDocument: new DocumentMetadata({
                customDocumentOrderConfigId: documentOrderConfig._id,
                type: 'customDocument',
                createdBy: user._id,
                title: documentOrderConfig.titleLong,
            }),
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Report Attachments (non-signable)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Main Report Document
    //****************************************************************************/
    /**
     * The main report document (type: 'report') receives a report-type-specific German title (e.g. Kurzbewertung...).
     */
    const reportDocument = report.documents.find((document) => document.type === 'report');
    if (reportDocument) {
        reportDocument.title = getReportTypeSpecificDocumentTitle(report);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Main Report Document
    /////////////////////////////////////////////////////////////////////////////*/
}

import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { SignableDocumentType } from '../documents/document-metadata';
import { ClaimantSignature } from './claimant-signature';
import { CheckboxElement } from './signable-pdf-template-config';

/**
 * Represents a document that can be signed by the user's customers.
 *
 * Examples:
 * - declaration of assignment (Abtretungserklärung)
 * - revocation instruction (Widerrufsbelehrung)
 * - consent of data protection (Datenschutzerklärung)
 * - power of attorney (Anwaltsvollmacht)
 *
 * The document can be either
 * - consisting of DocumentBuildingBlocks
 * - based on a PDF template uploaded by the user. <- Identified by the pdfTemplateId being present.
 */
export class SignableDocument {
    constructor(template: RequireSome<SignableDocument, 'documentType'>) {
        Object.assign(this, template);
    }

    _id: string = generateId();

    documentType: SignableDocumentType;
    customDocumentOrderConfigId?: string;

    //*****************************************************************************
    //  PDF Templates
    //****************************************************************************/
    /**
     * Which PDF template has been used to collect checkbox values and signatures?
     */
    pdfTemplateId: string;
    checkboxValueTrackers: CheckboxValueTracker[] = [];
    /////////////////////////////////////////////////////////////////////////////*/
    //  END PDF Templates
    /////////////////////////////////////////////////////////////////////////////*/

    signatures: ClaimantSignature[] = [];
}

export class CheckboxValueTracker {
    constructor(template: Partial<CheckboxValueTracker> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    name: CheckboxElement['targetProperty'] | CheckboxElement['checkboxPairId'] | CheckboxElement['_id'];
    value: boolean;
}

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';
import { getDekraErrorHandlers } from './get-dekra-error-handlers';
import { getRepairCalculationErrorHandlers } from './get-repair-calculation-error-handlers';

export function getGtmotiveErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        ...getRepairCalculationErrorHandlers(),
        ...getDekraErrorHandlers(),
        //*****************************************************************************
        //  General
        //****************************************************************************/
        GTMOTIVE_API_ERROR: (error) => ({
            title: 'Fehler in GT Motive Schnittstelle',
            body: `Ein unbekannter Fehler ist aufgetreten.<br><br><strong>GT Motive:</strong> ${error.data.gtmotiveDescription}`,
            partnerLogo: 'gtmotive',
        }),
        GTMOTIVE_ESTIMATE_NOT_FOUND: {
            title: 'Vorgang nicht gefunden',
            body: 'GT Motive konnte den mit diesem Gutachten verknüpften Vorgang nicht finden.<br><br>Kontaktiere die <a href="/Hilfe" target="_blank">autoiXpert Hotline</a>.',
            partnerLogo: 'gtmotive',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END General
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Authentication/Authorization
        //****************************************************************************/
        GTMOTIVE_CREDENTIALS_INCOMPLETE: {
            title: 'Zugangsdaten unvollständig',
            body: 'Bitte vervollständige deine GT-Motive-Zugangsdaten in den <a href="/Einstellungen#calculation-providers-section">Einstellungen</a>.',
            partnerLogo: 'gtmotive',
        },
        GTMOTIVE_CREDENTIALS_INVALID: {
            title: 'Zugangsdaten ungültig',
            body: 'Bitte überprüfe deine GT-Motive-Zugangsdaten in den <a href="/Einstellungen#calculation-providers-section">Einstellungen</a>.<br>Falls sie dort korrekt hinterlegt sind, kontaktiere am besten GT Motive, damit sie dir nicht nur die Oberfläche, sondern auch die Schnittstelle freischalten.',
            partnerLogo: 'gtmotive',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Authentication/Authorization
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  VIN
        //****************************************************************************/
        GTMOTIVE_VIN_FORMAT_INVALID: {
            title: 'GT Motive lehnt VIN ab',
            body: 'Die VIN hat ein Format, das GT Motive nicht unterstützt, z. B. weil GT Motive keine sehr alten Fahrzeuge kennt.<br><br>Prüfe die VIN in autoiXpert oder gib sie zur Prüfung über die GT Motive-Oberfläche ein.',
            partnerLogo: 'gtmotive',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END VIN
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Calculation
        //****************************************************************************/
        GTMOTIVE_ESTIMATE_NOT_UPDATABLE_IF_PAINT_SYSTEM_HAS_BEEN_CALCULATED_WITH: {
            title: 'Kalkulation bereits begonnen',
            body: 'GT-Motive erlaubt kein Update, nachdem die Kalkulation begonnen wurde. Lege die Kalkulation ggf. erneut an oder kontaktiere GT Motive direkt.',
            partnerLogo: 'gtmotive',
        },
        GTMOTIVE__REPORT_TOKEN_CONTAINS_SLASH: {
            title: 'Aktenzeichen enthält Schrägstrich',
            body: 'Bitte entferne ihn für GT Motive.',
            partnerLogo: 'gtmotive',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Calculation
        /////////////////////////////////////////////////////////////////////////////*/
    };
}

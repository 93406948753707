<internal-note
    *ngFor="let note of task.notes"
    class="note-row"
    [note]="note"
    [isNewNote]="note._id === newNote?._id"
    (noteChange)="emitTaskChange()"
    (noteDeleted)="deleteNote(note)"></internal-note>

<div id="add-note" (click)="addNote()">
    <span>+ Kommentar</span>
</div>

import { Report } from '../../models/reports/report';

/**
 * Calculate the total writing fees.
 */
export function getWritingFeesTotal(report: Pick<Report, 'feeCalculation'>): number {
    let total: number;
    if (report.feeCalculation.useWritingFeesFixedPrice) {
        total = report.feeCalculation.writingFeesFixedPrice;
        // Extra fees for page copies (fixed price)
        if (report.feeCalculation.useWritingCopyFees) {
            total += report.feeCalculation.writingCopyFeesFixedPrice;
        }
    } else {
        total = report.feeCalculation.numberOfPages * report.feeCalculation.pricePerPage;
        // Extra fees for page copies (per page)
        if (report.feeCalculation.useWritingCopyFees) {
            total += report.feeCalculation.numberOfPages * report.feeCalculation.pricePerPageCopy;
        }
    }
    return total === null || isNaN(total) ? 0 : total;
}

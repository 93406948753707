<div id="dialog-wrapper">
    <!--============================================-->
    <!-- Header -->
    <!--============================================-->
    <header>
        <div id="number-of-reports-container">
            <div id="number-of-reports">{{ dialogData.numberOfReports }}</div>
            <div class="label">
                abgeschlossene
                <br />
                Gutachten
            </div>
        </div>
        <mat-icon class="dialog-close-icon" matDialogClose matTooltip="Schließen">close</mat-icon>
    </header>
    <!--============================================-->
    <!-- END Header -->
    <!--============================================-->

    <h2 matDialogTitle>Glückwunsch!</h2>
    <mat-dialog-content>
        <div>
            <!--============================================-->
            <!-- Revenue -->
            <!--============================================-->
            <span *ngIf="dialogData.revenue">
                Du hast mit autoiXpert bereits einen Umsatz von
                <strong matTooltip="Netto">{{ dialogData.revenue | number: '1.0-0' }} €</strong>
                erzielt. 🚀
                <br />
                <br />

                <ng-container *ngIf="dialogData.numberOfReportsThreshold === 10">
                    Lade doch mal wieder jemanden zum Essen ein und stoßt zusammen darauf an!
                </ng-container>

                <ng-container *ngIf="dialogData.numberOfReportsThreshold === 50">
                    Kühle Cocktails auf einer Dachterrasse mit der Person deiner Wahl sind ein guter Weg, das zu feiern!
                </ng-container>

                <ng-container *ngIf="dialogData.numberOfReportsThreshold === 100">
                    Lass die Sektkorken knallen, das ist ein Grund zu feiern!
                </ng-container>

                <ng-container *ngIf="dialogData.numberOfReportsThreshold === 200">
                    Lade doch ein paar Freunde zu dir ein und feiere das mit einem guten Tropfen Wein!
                </ng-container>

                <ng-container *ngIf="dialogData.numberOfReportsThreshold === 500">
                    Du bist ganz weit vorne mit dabei. Lade doch ein paar Freunde ein und unternimm etwas mit ihnen - z.
                    B. bei einem spannenden Event.
                </ng-container>

                <ng-container *ngIf="dialogData.numberOfReportsThreshold === 1000">
                    Du kannst stolz auf dich und deine Leistung sein. Um noch höher hinaus zu kommen, musst du schon
                    Fallschirm springen gehen! Vielleicht dein nächstes Event?
                </ng-container>
            </span>
            <!--============================================-->
            <!-- END Revenue -->
            <!--============================================-->

            <span *ngIf="!dialogData.revenue">Starke Leistung. Weiter so! 🚀</span>
        </div>
        <p>
            Empfiehl autoiXpert auch anderen Gutachtern - aber natürlich nicht in deiner Stadt 😎 - und verdiene 50 €.
        </p>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="openRecommendationPage()" matTooltip="Weitere Infos zur Empfehlung anzeigen">
            Jetzt weiterempfehlen
        </button>
        <button class="flat" (click)="closeDialog()">Schließen</button>
    </mat-dialog-actions>
</div>

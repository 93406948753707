import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { IsoDate } from '../../lib/date/iso-date.types';
import { generateId } from '../../lib/generate-id';

export class Payment {
    constructor(paymentTemplate?: RequireSome<Payment, 'type' | 'createdBy' | 'date'>) {
        if (paymentTemplate) {
            Object.assign(this, paymentTemplate);
        }
    }

    _id: string = generateId();
    type: 'payment' | 'shortPayment' | 'partialCancellation' = 'payment';
    amount: number = null;
    date: IsoDate = null;

    // Only relevant for regular payment.
    payer: string = null;
    //*****************************************************************************
    //  Short Payments Only
    //****************************************************************************/

    reasonForShortPayment?: string;
    /**
     * Outstanding claim = the assessor is still working to get his money.
     * Written off = He knows he won't get the money.
     * Paid = He got the money in the end.
     */
    shortPaymentStatus?: 'outstandingClaim' | 'writtenOff' | 'paid';
    /**
     * What had to happen to make the debtor pay the short payment in the end?
     * Possibilities:
     * - after expert statement
     * - after threatening to lodge a complaint
     * - after the complaint was lodged
     * - after verdict was rendered
     */
    shortPaymentResolution?: string;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Short Payments Only
    /////////////////////////////////////////////////////////////////////////////*/
    title: string;
    notes: string;
    associatedBankTransactionId?: string;
    /**
     * IBAN to which the payment was made. Currently only filled through the bank transaction list / service.
     */
    iban?: string;

    /**
     * Cancellation invoices for short payments or partial cancellations.
     * As soon as the assessor has accepted a short payment, he may create a cancellation invoice.
     */
    cancellationInvoiceId?: string;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string;
}

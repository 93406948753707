import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';

export const placeholderDetectionRegexString: string = '{([A-Za-zÄÖÜäöüß.0-9]*)';

/**
 * Get all available placeholder names.
 *
 * This method is used to pertain a list of placeholders for autocompletes etc.
 */
export function getAvailablePlaceholders({
    buildingBlockPlaceholder,
    fieldGroupConfigs,
}: {
    buildingBlockPlaceholder?: DocumentBuildingBlock['placeholder'];
    fieldGroupConfigs: FieldGroupConfig[];
}): string[] {
    //*****************************************************************************
    //  Merge default placeholder values with custom fields.
    //****************************************************************************/
    const placeholderValueTreeWithCustomFields: PlaceholderValueTree = getPlaceholderValueTree({ fieldGroupConfigs });

    let placeholderList = findLeavesOfPlaceholderTree(placeholderValueTreeWithCustomFields).sort((pathA, pathB) =>
        pathA.localeCompare(pathB),
    );

    if (buildingBlockPlaceholder) {
        // Filter out placeholders that only exist in certain building blocks
        placeholderList = placeholderList.filter((leaf) => {
            if (buildingBlockPlaceholder !== 'BeschreibungProBesichtigung') {
                if (leaf.startsWith('Besichtigung.')) return false;
            }
            return true;
        });
    }
    // If there isn't a building block, the calling component is located within the message template selector.
    else {
        placeholderList.push('%Sachverständiger.Stempel');
        placeholderList.push('%Anschreiben.Absender.Stempel');
    }

    return placeholderList;
}

/**
 * Translate a tree structure to an array of deep paths.
 *
 * Terminology:
 * - Branch = Source of further leaves or branches. This is the root in the first iteration.
 * - Leave = A value without further children. Must therefore be the end of a branch.
 */
function findLeavesOfPlaceholderTree(branch: any): string[] {
    const leaves: string[] = [];

    // Go through all keys.
    for (const key of Object.keys(branch)) {
        /**
         * It's a leaf if the value...
         * - is of type string or
         * - is an array or
         * - holds a type definition object
         */
        if (
            typeof branch[key] === 'string' ||
            Array.isArray(branch[key]) ||
            (typeof branch[key] === 'object' && branch[key].hasOwnProperty('type'))
        ) {
            leaves.push(key);
            continue;
        }
        // Only parse down into objects if they are not type definition objects.
        if (typeof branch[key] === 'object') {
            // Found a branch
            const childLeaves = findLeavesOfPlaceholderTree(branch[key]);
            for (const childLeaf of childLeaves) {
                leaves.push(`${key}.${childLeaf}`);
            }
        }
    }
    return leaves;
}

export function filterDocumentPlaceholders(documentPlaceholders: string[], searchTerm: string): string[] {
    if (!searchTerm) {
        return [...documentPlaceholders];
    }

    // Split on either space or period.
    const searchTerms = searchTerm.split(/[\s.]/).map((searchTerm) => searchTerm.toLowerCase());

    return [...documentPlaceholders].filter((placeholder) => {
        const placeholderInLowerCase = placeholder.toLowerCase();
        return searchTerms.every((searchTerm) => placeholderInLowerCase.includes(searchTerm));
    });
}

<div id="layout-container">
    <!--============================================-->
    <!-- Menu -->
    <!--============================================-->
    <div id="menu-column"></div>
    <!--============================================-->
    <!-- END Menu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Reporting Column -->
    <!--============================================-->
    <div id="reporting-column">
        <!--============================================-->
        <!-- Graph -->
        <!--============================================-->
        <div id="graph-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Graph Header -->
            <!--============================================-->
            <div id="graph-header">
                <!--********** Report Name **********-->
                <div id="report-name-container">
                    <mat-icon
                        class="toolbar-icon"
                        matTooltip="Zurück zur Übersicht"
                        (click)="navigateBackToAnalyticsList()">
                        arrow_back
                    </mat-icon>
                    <h2>
                        {{ chartTitle }}
                        <span
                            class="help-indicator"
                            matTooltip="Summe geschriebener Rechnungen im Zeitverlauf. Kürzungen sind bereits abgezogen.">
                            ?
                        </span>
                    </h2>
                </div>

                <!--********** Report Dimension **********-->
                <div id="report-dimensions-container">
                    <div class="report-dimension" routerLinkActive="selected" routerLink="../Umsatz-pro-Monat">
                        Monatlich
                    </div>
                    <div
                        class="report-dimension"
                        routerLinkActive="selected"
                        routerLink="../Umsatz-pro-Sachverständigem">
                        nach Sachverständigem
                    </div>
                </div>

                <analytics-filter-button [filterRef]="filter"></analytics-filter-button>
                <div class="date-and-settings-container">
                    <!--********** Date Filter **********-->
                    <date-range-picker
                        [(dateFrom)]="filterAnalyticsFrom"
                        [(dateTo)]="filterAnalyticsTo"
                        (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
                    <analytics-settings-menu (useNetValuesChange)="refreshLocalData()"></analytics-settings-menu>
                </div>
            </div>

            <!--============================================-->
            <!-- END Graph Header -->
            <!--============================================-->

            <analytics-filter (filterChange)="updateAnalytics()" (initialized)="filtersLoaded()"></analytics-filter>

            <div class="chart-container">
                <div class="chart-placeholder" *ngIf="analyticsPending">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Lade Auswertung ...
                </div>
                <div class="chart-placeholder" *ngIf="!analyticsPending && records.length === 0">
                    <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                    <div class="no-data-found-message">Keine Daten gefunden</div>
                    Probiere doch andere Filter.
                </div>
                <div id="chart" *ngIf="!analyticsPending && records.length"></div>
            </div>

            <!--============================================-->
            <!-- Graph Summary -->
            <!--============================================-->
            <div id="graph-summary-container" class="card">
                <!--********** Total **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Gesamt
                        <span class="series-color analytics-series-1"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ totalRevenue | number: '1.0-0' }} €</div>
                </div>

                <!--********** Unpaid Absolute**********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Davon unbezahlt
                        <span class="series-color negative-color"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ outstandingRevenue | number: '1.0-0' }} €</div>
                </div>

                <!--********** Unpaid Percentage **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Davon unbezahlt
                        <span class="series-color negative-color"></span>
                    </div>

                    <!--********** Value **********-->
                    <div
                        class="graph-summary-item-value"
                        matTooltip="{{ (outstandingRevenue / totalRevenue) * 100 || 0 | number: '1.1-2' }} %">
                        {{ (outstandingRevenue / totalRevenue) * 100 || 0 | number: '1.0-1' }} %
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Graph Summary -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Graph -->
        <!--============================================-->

        <!--============================================-->
        <!-- Series List -->
        <!--============================================-->
        <div id="series-list-card" class="card card-with-padding">
            <div id="series-list-toolbar">
                <export-invoice-analytics-records-icon
                    [records]="records"
                    [summaryDownloadName]="chartTitle"></export-invoice-analytics-records-icon>
            </div>
            <div id="series-list-table-container">
                <table id="series-list">
                    <thead>
                        <tr>
                            <th class="series-entry-title-heading">Monat</th>
                            <th class="series-entry-value-heading">
                                <span class="series-color analytics-series-1"></span>
                                Umsatz ({{ settings.useNetValues ? 'netto' : 'brutto' }})
                            </th>
                            <th class="series-entry-value-heading">
                                <span class="series-color negative-color"></span>
                                Davon unbezahlt
                            </th>
                            <th class="series-entry-value-heading">
                                <span class="series-color neutral-color"></span>
                                Gutachten
                                <span
                                    class="help-indicator"
                                    matTooltip="In diesem Monat fertiggestellte Gutachten. Gefiltert nach ausgewähltem Standort, Gutachter und Gutachten-Label.">
                                    ?
                                </span>
                            </th>
                            <th class="series-entry-value-heading">Umsatz pro Rechnung</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="series-entry-row" *ngFor="let record of recordsForList">
                            <td class="series-entry-title-cell">{{ record.month | moment: 'MMMM YYYY' }}</td>

                            <!--********** Total Revenue **********-->
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross)
                                        | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfAllInvoices"
                                    [displayNetValues]="settings.useNetValues">
                                    {{ record.idsOfRegularInvoices.length }}
                                    {{ record.idsOfRegularInvoices.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                    <span class="label" *ngIf="record.idsOfCreditNotes.length">
                                        & {{ record.idsOfCreditNotes.length }}
                                        {{ record.idsOfCreditNotes.length === 1 ? 'Gutschrift' : 'Gutschriften' }}
                                    </span>
                                </div>
                            </td>

                            <!--********** Outstanding Revenue **********-->
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues
                                        ? record.outstandingRevenueNet
                                        : record.outstandingRevenueGross
                                    ) | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfOutstandingInvoices"
                                    [displayNetValues]="settings.useNetValues"
                                    valueDisplayType="unpaidAmount">
                                    {{ record.idsOfOutstandingInvoices.length }}
                                    {{ record.idsOfOutstandingInvoices.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                </div>
                            </td>

                            <!--********** Number of Reports **********-->
                            <td class="series-entry-value">{{ record.numberOfReports }} Stk.</td>

                            <!--********** Average **********-->
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross) /
                                        record.idsOfRegularInvoices.length || 0 | number: '1.0-0'
                                }}
                                €
                            </td>
                        </tr>

                        <!--============================================-->
                        <!-- Average Row -->
                        <!--============================================-->
                        <tr class="series-entry-row average-row" *ngIf="records.length">
                            <td class="series-entry-title-cell">Durchschnitt pro Monat</td>
                            <td class="series-entry-value">
                                {{ totalRevenue / records.length | number: '1.0-0' }} €
                                <div class="label">
                                    {{ totalNumberOfInvoices / records.length | number: '1.0-0' }}
                                    {{ totalNumberOfInvoices / records.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                </div>
                            </td>
                            <td class="series-entry-value">
                                {{ outstandingRevenue / records.length | number: '1.0-0' }} €
                            </td>
                            <td class="series-entry-value">
                                {{ totalNumberOfReports / records.length | number: '1.0-0' }} Stk.
                            </td>
                            <td class="series-entry-value">
                                {{ totalRevenue / totalNumberOfInvoices | number: '1.0-0' }} €
                            </td>
                        </tr>
                        <!--============================================-->
                        <!-- END Average Row -->
                        <!--============================================-->
                    </tbody>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Series List -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Reporting Column -->
    <!--============================================-->
</div>

<div class="grade-row">
    <div class="grade-title" id="total-grade-title">Gesamtzustand</div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.total"
        [category]="'total'"
        (gradeChange)="setAllGrades($event); emitGradeChange()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.body === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.body === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Blech
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.body"
        [category]="'body'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.paint === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.paint === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Lack
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.paint"
        [category]="'paint'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.chrome === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.chrome === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Chrom-/Zierteile
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.chrome"
        [category]="'chrome'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.rubber === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.rubber === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Dichtungen/Gummiteile
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.rubber"
        [category]="'rubber'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.glass === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.glass === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Glas
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.glass"
        [category]="'glass'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.tires === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.tires === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Reifen
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.tires"
        [category]="'tires'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.cabin === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.cabin === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Fahrgastraum
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.cabin"
        [category]="'cabin'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.engineCompartment === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.engineCompartment === null
                ? 'Wird im Gutachten nicht abgedruckt'
                : ''
        ">
        Motorraum
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.engineCompartment"
        [category]="'engineCompartment'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.engine === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.engine === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Motor
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.engine"
        [category]="'engine'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>
<div class="grade-row" [ngClass]="{ deactivated: report.valuation.oldtimerValuationGrades.trunk === null }">
    <div
        class="grade-title"
        [matTooltip]="
            report.valuation.oldtimerValuationGrades.trunk === null ? 'Wird im Gutachten nicht abgedruckt' : ''
        ">
        Kofferraum
    </div>
    <grade-selector
        [(grade)]="report.valuation.oldtimerValuationGrades.trunk"
        [category]="'trunk'"
        (gradeChange)="calculateAverageGrade()"></grade-selector>
</div>

<!--********** Switch: Automatically re-calculate total grade **********-->
<div id="recalculate-grade-switch-container">
    <div id="recalculate-grade-switch-label">Gesamtnote automatisch berechnen</div>
    <mat-slide-toggle
        [(ngModel)]="userPreferences.recalculateOldtimerGradesAutomatically"
        (ngModelChange)="calculateAverageGrade()"
        matTooltip="Gesamtnote automatisch berechnen"></mat-slide-toggle>
</div>

<!--============================================-->
<!-- IE / Edge warning -->
<!--============================================-->
<div id="old-browser-warning" *ngIf="browser === 'Edge' || browser === 'Internet Explorer'">
    <span>
        Dieser Browser wird nicht unterstützt. Bitte installiere einen modernen Browser wie z. B. die aktuellste Version
        von
        <a href="https://www.google.com/chrome/" target="_blank" rel="”noopener”">Google Chrome</a>
        oder
        <a href="https://www.mozilla.org/de/firefox/new/" target="_blank" rel="”noopener”">Mozilla Firefox</a>
        .
    </span>
</div>
<div id="old-browser-warning-apple" *ngIf="browser === 'Safari' && !doesBrowserSupportArrayAtMethod">
    <!-- Safari on iOS 15.3 does not yet support Array.at() which is used in our app. We currently only compile the code down to the latest versions of browsers, leaving these old ones behind. That might change in the future.  -->
    Dein Safari scheint etwas älter zu sein. Bitte aktualisiere dein Betriebssystem, damit alles sauber funktioniert.
</div>
<div id="indexeddb-warning" *ngIf="isIndexeddbUnusable()">
    <span *ngIf="browser === 'Firefox'">
        Im Incognito-Modus von Firefox fehlt eine wichtige Speicher-Funktion (IndexedDB). AutoiXpert kann nur ohne
        Incognito-Modus verwendet werden.
    </span>
    <span *ngIf="browser !== 'Firefox'">
        Dieser Browser unterstützt eine wichtige Speicher-Funktion nicht und ist deshalb nicht kompatibel. Bitte
        installiere einen modernen Browser wie z. B. die aktuellste Version von
        <a href="https://www.google.com/chrome/" target="_blank" rel="”noopener”">Google Chrome</a>
        oder
        <a href="https://www.mozilla.org/de/firefox/new/" target="_blank" rel="”noopener”">Mozilla Firefox</a>
        .
    </span>
</div>
<!--============================================-->
<!-- END IE / Edge warning -->
<!--============================================-->

<!-------------- Kopfleiste  -->
<div id="head-runner">
    <mat-icon id="mobile-menu-icon" (click)="toggleNavigationOnMobile()">menu</mat-icon>
    <div id="logo-container">
        <img
            class="head-runner-logo"
            *ngIf="!isQapterixpert()"
            src="/assets/images/logos/autoixpert-x.png"
            (click)="navigateHome()" />
        <img
            class="head-runner-logo"
            *ngIf="isQapterixpert()"
            src="/assets/images/logos/qapterixpert-q-white.svg"
            (click)="navigateHome()" />
    </div>

    <div id="license-plate-report-token-and-title-container">
        <div
            id="report-token-container"
            *ngIf="
                user &&
                (!userPreferences.reportAttributesInHeadRunner?.length ||
                    userPreferences.reportAttributesInHeadRunner?.includes('reportToken')) &&
                reportTokenInTitle
            ">
            {{ reportTokenInTitle }}
        </div>

        <license-plate
            class="license-plate-read-only"
            id="license-plate-head-runner"
            *ngIf="
                user &&
                (!userPreferences.reportAttributesInHeadRunner?.length ||
                    userPreferences.reportAttributesInHeadRunner?.includes('licensePlate')) &&
                licensePlateInTitle
            "
            [licensePlate]="licensePlateInTitle"
            [disabled]="true"></license-plate>

        <div id="head-runner-screen-title-container">
            <h1 id="head-runner-screen-title" [ngClass]="{ 'subtitle-exists': screenSubtitle }">{{ screenTitle }}</h1>
            <div class="label" *ngIf="screenSubtitle">{{ screenSubtitle }}</div>
        </div>

        <mat-icon
            id="switch-license-plate-and-report-token"
            (click)="switchLicensePlateAndReportToken()"
            *ngIf="licensePlateInTitle || reportTokenInTitle"
            [matTooltip]="getTooltipForChangingReportAttributesInHeadRunner()">
            swap_horiz
        </mat-icon>
    </div>

    <div id="center-location" [ngClass]="{ small: areManyHeaderRunnerNotificationsVisible() }">
        <!--============================================-->
        <!-- Test Period Notification -->
        <!--============================================-->
        <div
            id="test-period-notification"
            class="head-runner-notification"
            *ngIf="testPeriodNotificationVisible()"
            [ngClass]="{
                'test-period-ends-soon': getDaysLeftInTestPeriod() <= 7,
                'test-period-ended': getDaysLeftInTestPeriod() < 0,
            }"
            @fadeInAndOut>
            <span>
                <ng-container *ngIf="getDaysLeftInTestPeriod() > 1">
                    Dein Test endet in {{ getDaysLeftInTestPeriod() }} Tagen.
                </ng-container>
                <ng-container *ngIf="getDaysLeftInTestPeriod() === 1">Dein Test endet in 1 Tag.&nbsp;</ng-container>
                <ng-container *ngIf="getDaysLeftInTestPeriod() === 0">Dein Test endet heute.&nbsp;</ng-container>
                <ng-container *ngIf="getDaysLeftInTestPeriod() < 0">Der Test ist abgelaufen.&nbsp;</ng-container>
                <a routerLink="/Einstellungen">
                    Preise
                    <ng-container *ngIf="isQapterixpert()">(ab 119 €)</ng-container>
                    <ng-container *ngIf="!isQapterixpert()">(ab 89 €)</ng-container>
                    & Bestellung »
                </a>
            </span>
            <mat-icon
                class="notification-close-icon medium-icon"
                (click)="hideTestPeriodNotification()"
                matTooltip="Meldung schließen">
                close
            </mat-icon>
        </div>
        <!--============================================-->
        <!-- END Test Period Notification -->
        <!--============================================-->

        <!--============================================-->
        <!-- Downtime Notifications -->
        <!--============================================-->
        <downtime-notification
            *ngIf="user"
            (numberOfDowntimeNotificationsChange)="numberOfDowntimeNotifications = $event"></downtime-notification>
        <!--============================================-->
        <!-- END Downtime Notifications -->
        <!--============================================-->

        <!--============================================-->
        <!-- Default Of Payment Notification -->
        <!--============================================-->
        <default-of-payment-notification *ngIf="team"></default-of-payment-notification>
        <!--============================================-->
        <!-- END Default Of Payment Notification -->
        <!--============================================-->
    </div>

    <!--============================================-->
    <!-- Update Issues -->
    <!--============================================-->
    <div
        id="update-blocked-indicator"
        *ngIf="appVersionUpdateService.areUpdatesPrevented"
        (click)="openUpdateBlockedByIntrusionDialog()"
        matTooltip="Das Update wurde durch eine Antivirensoftware blockiert. Am besten deinstallierst du die Antivirensoftware wie Kaspersky & Co.{{
            '\n\n'
        }}Klicke für mehr Infos.">
        <div class="failure-icon">💥</div>
        <div class="label">Update blockiert</div>
        <div class="help-indicator">?</div>
    </div>
    <!--============================================-->
    <!-- END Update Issues -->
    <!--============================================-->

    <!--***********************************************
    ** Sync Issue Notifications Indicator
    ************************************************-->
    <!--********** Display a red warning if some local records couldn't be synced, and provide ways to fix sync issues. **********-->
    <sync-issue-indicator></sync-issue-indicator>
    <!--***********************************************
    ** END Sync Issue Notifications Indicator
    ************************************************-->

    <!--============================================-->
    <!-- Online/Offline Indicator -->
    <!--============================================-->
    <div id="network-status-indicator">
        <div
            id="network-online-indicator"
            *ngIf="networkStatus?.status === 'online'"
            [ngClass]="{ 'initial-sync-in-progress': databaseSyncInitializerService.isInitialSyncInProgress }"
            matTooltip="Du bist online.{{
                databaseSyncInitializerService.isInitialSyncInProgress
                    ? '\n\nErste Synchronisation läuft. Im Hintergrund werden deine Stammdaten und deine Kontakte synchronisiert, damit du sie auch offline nutzen kannst. Du kannst die App nutzen, aber sie ist ggf. etwas langsamer.\n\nVermeide Wartezeiten, indem du dich seltener ausloggst.'
                    : ''
            }}">
            <div
                id="network-online-indicator-icon"
                [ngClass]="{
                    'sync-in-progress':
                        pendingRequestsRegistryLength > 0 || databaseSyncInitializerService.isInitialSyncInProgress,
                }"></div>

            <div *ngIf="databaseSyncInitializerService.isInitialSyncInProgress" @slideInHorizontally>
                Initialer Sync...
            </div>
        </div>

        <!--********** Offline **********-->
        <div
            id="network-offline-indicator"
            *ngIf="networkStatus?.status !== 'online'"
            (click)="triggerNetworkDetection()"
            [matTooltip]="getOfflineIndicatorTooltip()">
            <div id="network-offline-indicator-icon"></div>
            <ng-container *ngIf="!manualReconnectionInProgress">
                Offline
                <span *ngIf="networkStatus?.offlineReason === 'internet-connection-too-slow'">
                    &nbsp;- zu langsames Internet
                </span>
                <div id="reconnect-icon-container">
                    <mat-icon class="small-icon">refresh</mat-icon>
                </div>
            </ng-container>
            <ng-container *ngIf="manualReconnectionInProgress">Verbindungsversuch...</ng-container>
        </div>
    </div>
    <!--============================================-->
    <!-- END Online/Offline Indicator -->
    <!--============================================-->

    <!--============================================-->
    <!-- Help Panel -->
    <!--============================================-->
    <div
        id="head-runner-help"
        [class.show-anydesk-logo]="isAnydeskLogoAnimationRunning"
        *ngIf="user"
        #helpIcon
        (click)="openHelpPanel()"
        (contextmenu)="$event.preventDefault(); downloadAnydesk()"
        matTooltip="Hilfe-Panel öffnen | Rechtsklick für AnyDesk">
        <mat-icon id="head-runner-help-icon" class="outlined">help</mat-icon>
        <img id="anydesk-logo-in-head-runner" src="/assets/images/logos/anydesk-logo-squares.svg" alt="AnyDesk-Logo" />

        <span>Hilfe</span>
    </div>
    <!--============================================-->
    <!-- END Help Panel -->
    <!--============================================-->

    <!--============================================-->
    <!-- User -->
    <!--============================================-->
    <div id="head-runner-user" *ngIf="user" [matMenuTriggerFor]="avatarMenu">
        <user-avatar [userId]="user._id" class="avatar-image-container"></user-avatar>
        <div class="avatar-name">{{ user.firstName }} {{ user.lastName }}</div>
        <div class="avatar-arrow">
            <mat-icon>arrow_drop_down</mat-icon>
        </div>

        <!-------------- More options menu -->
        <!-------------- TODO: Add behavior to the first two menu items -->

        <mat-menu #avatarMenu="matMenu" xPosition="before">
            <!--********** Settings **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="openPreferences()">
                <mat-icon>settings</mat-icon>
                Einstellungen
            </a>

            <!--********** Offline Storage **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="openStorageUsagePanel()">
                <mat-icon>storage</mat-icon>
                Lokaler Speicher
            </a>

            <!--********** Recommendation Bonus **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="openHelpPanel('referral')" *ngIf="!isQapterixpert()">
                <mat-icon>payments</mat-icon>
                50 € Empfehlungsbonus
            </a>

            <!--********** Invoice History **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="openInvoiceHistory()"
                *ngIf="
                    team &&
                    team.accountStatus !== 'test' &&
                    team.accountStatus !== 'partner' &&
                    !team.audatexFeatures?.qapterixpertLayout &&
                    isAdmin(user._id, team)
                ">
                <mat-icon>euro_symbol</mat-icon>
                Meine Abrechnungen
            </a>

            <!--********** Logout **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="confirmLogout()">
                <mat-icon>power_settings_new</mat-icon>
                Abmelden
            </a>
        </mat-menu>
    </div>
    <!--============================================-->
    <!-- END User -->
    <!--============================================-->
</div>
<!-------------- ENDE Kopfleiste  -->

<!-------------- Großer Bereich unterhalb Kopfleiste -->
<div id="mainframe-container" [ngClass]="{ christmas: isItChristmasTime(), blurry: isLogoutPending }">
    <!-------------- Seitennavigation -->
    <nav id="navigation" *ngIf="user" [ngClass]="{ 'visible-on-mobile': isVisibleOnMobile }">
        <!--********** Reports **********-->
        <a
            id="reports-navigation-element"
            class="navigation-element"
            [routerLinkActive]="'active'"
            matTooltip="Meine Gutachten"
            matTooltipPosition="right"
            [routerLink]="'/Gutachten'"
            (click)="hideNavigationOnMobile()">
            <img [src]="'/assets/images/navigation/meine_gutachten.png'" />
        </a>

        <!--********** Tasks **********-->
        <a
            id="tasks-navigation-element"
            class="navigation-element navigation-element-with-badge"
            [routerLinkActive]="'active'"
            matTooltip="Aufgaben"
            matTooltipPosition="right"
            [routerLink]="'/Aufgaben'"
            (click)="hideNavigationOnMobile()">
            <img [src]="'/assets/images/navigation/aufgaben.png'" />
            <span *ngIf="badgeForDueTasks" class="navigation-element-badge">{{ badgeForDueTasks }}</span>
        </a>

        <!--********** Contacts **********-->
        <a
            id="contacts-navigation-element"
            class="navigation-element"
            [routerLinkActive]="'active'"
            matTooltip="Kontakte"
            matTooltipPosition="right"
            [routerLink]="'/Kontakte'"
            (click)="hideNavigationOnMobile()"
            *ngIf="user.accessRights?.editContacts">
            <img [src]="'/assets/images/navigation/kontakte.png'" />
        </a>

        <!--********** Invoice List **********-->
        <a
            id="invoice-list-navigation-element"
            class="navigation-element"
            [routerLinkActive]="'active'"
            matTooltip="Rechnungen"
            matTooltipPosition="right"
            [routerLink]="'/Rechnungen'"
            (click)="hideNavigationOnMobile()"
            *ngIf="user.accessRights?.invoiceList">
            <img [src]="'/assets/images/navigation/rechnungen.png'" />
        </a>

        <!--********** Analytics **********-->
        <a
            id="analytics-navigation-element"
            class="navigation-element"
            [routerLinkActive]="'active'"
            matTooltip="Auswertungen"
            matTooltipPosition="right"
            [routerLink]="'/Auswertungen'"
            (click)="hideNavigationOnMobile()"
            *ngIf="user.accessRights?.reporting">
            <img src="/assets/images/navigation/auswertungen.png" />
        </a>

        <!--********** Settings **********-->
        <a
            id="settings-navigation-element"
            class="navigation-element"
            [routerLinkActive]="'active'"
            matTooltip="Einstellungen"
            matTooltipPosition="right"
            [routerLink]="'/Einstellungen'"
            (click)="hideNavigationOnMobile()"
            *ngIf="user.accessRights?.seeSettings">
            <img [src]="'/assets/images/navigation/optionen.png'" />
        </a>

        <!--********** Spacer **********-->
        <div id="navigation-vertical-spacer"></div>

        <div
            id="admin-authentication"
            *ngIf="isAdminAuthentication"
            matTooltip="Du bist über den Admin-Zugriff eingeloggt.">
            <mat-icon>fingerprint</mat-icon>
        </div>
    </nav>
    <!-------------- ENDE Seitennavigation -->

    <!-------------- Masken-Outlet -->
    <div id="router-outlet-container">
        <simple-notifications></simple-notifications>
        <router-outlet></router-outlet>
    </div>
    <!-------------- ENDE Masken-Outlet -->
</div>
<!-------------- ENDE Großer Bereich unterhalb Kopfleiste -->

<!--============================================-->
<!-- Logout Pending Overlay -->
<!--============================================-->
<div id="logout-pending-overlay" *ngIf="isLogoutPending" @fadeInAndOut>
    <div id="logout-card">
        <div id="logout-pending-text">Du wirst ausgeloggt...</div>
        <div id="logout-pending-icon">👋🏼</div>
        <div id="logout-takes-longer-text" [class.show]="doesLogoutTakeLonger">
            Bitte schließe alle anderen {{ getProductName() }}-Tabs.
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Logout Pending Overlay -->
<!--============================================-->

<!--============================================-->
<!-- Switch to Qapter-iXpert Overlay -->
<!--============================================-->
<div id="switch-to-qapterixpert-overlay" *ngIf="isSwitchToQapterixpertOverlayVisible" @fadeInAndOut>
    <div id="switch-to-qapterixpert-text-container">
        <img src="/assets/images/logos/qapterixpert-logo-white.svg" alt="Qapter-iXpert" />
        <h2>Servus! Du bist Kunde von Qapter-iXpert von Audatex.</h2>
        <div class="label margin-bottom-20">
            Tipp: Füge dir ein Lesezeichen zu
            <span class="semibold">app.qapter-ixpert.de</span>
            hinzu.
        </div>
        <div class="text-align-center margin-bottom-10">
            <button (click)="logIntoQapterixpert()">Qapter-iXpert öffnen</button>
        </div>
        <div class="label cursor-pointer" (click)="logout(true); hideSwitchToQapterixpertOverlay()">Ausloggen</div>
    </div>
</div>
<!--============================================-->
<!-- END Switch to Qapter-iXpert Overlay -->
<!--============================================-->

<div
    id="email-message-container"
    [ngClass]="{
        card: showCardFrame,
        'card-with-padding': showCardFrame,
    }">
    <h2 id="email-message-heading">
        <ng-container *ngIf="showHeadline">
            <mat-icon class="heading-icon">mail</mat-icon>
            E-Mail-Versand
        </ng-container>
    </h2>
    <div id="email-message-submenu-container" class="message-submenu-container">
        <mat-icon
            class="toolbar-icon email-message-submenu-icon"
            (click)="showMessageTemplateSelector()"
            matTooltip="Vorlage wählen">
            post_add
        </mat-icon>
        <mat-icon class="toolbar-icon email-message-submenu-icon" [matMenuTriggerFor]="documentEmailSubmenu">
            more_vert
        </mat-icon>
        <mat-menu #documentEmailSubmenu="matMenu">
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="setEmailReceived(recipient, true); emitRecipientChange()"
                *ngIf="!recipient.receivedEmail">
                <mat-icon>check</mat-icon>
                hat E-Mail empfangen
            </a>
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="setEmailReceived(recipient, false); emitRecipientChange()"
                *ngIf="recipient.receivedEmail">
                <mat-icon>clear</mat-icon>
                E-Mailversand entfernen
            </a>
            <a mat-menu-item class="menu-item-with-icon" (click)="insertEmailSignatureAndEmitChange()">
                <mat-icon>draw</mat-icon>
                E-Mail-Signatur einfügen
            </a>
        </mat-menu>
    </div>
    <div id="message-container" [ngClass]="{ hidden: !!emailJustSentInfo }">
        <!--============================================-->
        <!-- Sender -->
        <!--============================================-->
        <div id="sender-email" class="label">
            <ng-container *ngIf="user.emailAccount.username">Von: {{ getEmailSenderPreview(user) }}</ng-container>

            <!--********** No email credentials **********-->
            <ng-container *ngIf="!user.emailAccount.username">
                <!--********** During test **********-->
                <ng-container *ngIf="team.accountStatus === 'test'">
                    Von: {{ user.firstName }} {{ user.lastName }} via autoiXpert &lt;app&#64;de.autoixpert.de&gt;
                    <a
                        href="https://wissen.autoixpert.de/hc/de/articles/360006973051?utm_source=autoixpert-app&utm_medium=email-versand-im-test"
                        target="_blank"
                        rel="”noopener”">
                        <span
                            class="help-indicator"
                            matTooltip="Während des Testzeitraums kannst du Gutachten über die autoiXpert-Mailserver versenden. Du kannst jederzeit deine eigene Mailadresse verwenden, indem du deine Email-Zugangsdaten in den Einstellungen hinterlegst. Klicke für mehr Infos.">
                            ?
                        </span>
                    </a>
                    <br />
                    <a routerLink="/Einstellungen" [queryParams]="{ section: 'email-settings-container' }">
                        Eigene E-Mailadresse hinterlegen
                    </a>
                </ng-container>

                <!--********** After test **********-->
                <ng-container
                    *ngIf="team.accountStatus !== 'test'"
                    matTooltip="Außerhalb des Test-Accounts ist es nicht mehr möglich, Emails über den autoiXpert-Mailserver zu versenden. Bitte verwende deinen eigenen Mailserver-Zugang.">
                    <div id="add-own-email-credentials-note" class="info-note">
                        <mat-icon>info</mat-icon>
                        <div>
                            <h3>Eigene E-Mail als Absender</h3>
                            <div>
                                Während des Tests konntest du Gutachten über die autoiXpert-Mailserver versenden.
                                Versende jetzt unter deiner eigenen E-Mail-Adresse, indem du deine
                                <a routerLink="/Einstellungen" [queryParams]="{ section: 'email-settings-container' }">
                                    Email-Zugangsdaten in den Einstellungen hinterlegst
                                </a>
                                .
                                <a
                                    href="https://wissen.autoixpert.de/hc/de/articles/360006973051?utm_source=autoixpert-app&utm_medium=email-versand-im-test"
                                    target="_blank"
                                    rel="”noopener”">
                                    <span class="help-indicator" matTooltip="Klicke für eine detaillierte Anleitung">
                                        ?
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <!--============================================-->
        <!-- END Sender -->
        <!--============================================-->

        <!--============================================-->
        <!-- Email Recipient -->
        <!--============================================-->
        <div id="email-recipients-container">
            <div id="email-recipient-container" class="message-recipient-container" *ngIf="!toRecipientInputShown">
                <div class="message-recipient-icon-container">
                    <img
                        class="message-recipient-icon"
                        [src]="'/assets/images/icons/' + recipient.role + '-blue.svg'" />
                </div>
                <div class="message-recipient-contact-details-container">
                    <div
                        id="email-recipient"
                        (click)="openLinkExternally(getEmailRecipientLink())"
                        matTooltip="E-Mail in externem Editor öffnen">
                        <div *ngIf="recipient.contactPerson.organization">
                            {{ recipient.contactPerson.organization }}
                        </div>
                        <div *ngIf="recipient.contactPerson.firstName || recipient.contactPerson.lastName">
                            {{ recipient.contactPerson.firstName || recipient.contactPerson.salutation }}
                            {{ recipient.contactPerson.lastName }}
                        </div>
                        <div
                            *ngIf="
                                !recipient.contactPerson.organization &&
                                !recipient.contactPerson.firstName &&
                                !recipient.contactPerson.lastName
                            ">
                            Keine Kontaktdaten
                        </div>
                        <div class="label recipient-email-address" *ngIf="recipient.contactPerson.email">
                            {{ recipient.contactPerson.email }}
                        </div>
                        <div class="label warning" *ngIf="!recipient.contactPerson.email">
                            Keine E-Mailadresse hinterlegt
                        </div>
                        <div class="label" [ngSwitch]="recipient.role">
                            <span *ngSwitchCase="'claimant'">{{ getClaimantDenomination() }}</span>
                            <span *ngSwitchCase="'garage'">Werkstatt</span>
                            <span *ngSwitchCase="'lawyer'">Rechtsanwalt</span>
                            <span *ngSwitchCase="'insurance'">Versicherung</span>
                            <span *ngSwitchCase="'factoringProvider'">Factoring Provider</span>
                            <span *ngSwitchCase="'invoiceRecipient'">Rechnungsempfänger</span>
                        </div>
                    </div>
                </div>
                <mat-icon
                    class="edit-recipient-icon toolbar-icon medium-icon"
                    matTooltip="Empfänger bearbeiten"
                    (click)="enterRecipientEditMode()">
                    edit
                </mat-icon>
            </div>

            <!--============================================-->
            <!-- To Recipients -->
            <!--============================================-->
            <div class="email-recipient-input-container" *ngIf="toRecipientInputShown">
                <mat-form-field class="mat-block">
                    <mat-chip-list #toRecipientsChipList [selectable]="emailTransmissionPending">
                        <mat-chip
                            class="email-recipient-chip"
                            *ngFor="let toRecipient of email.email.toRecipients"
                            (removed)="removeRecipient(toRecipient, 'to')">
                            <!--********** Name & E-Mail **********-->
                            <div class="recipient-chip-text-container" *ngIf="toRecipient.name">
                                {{ toRecipient.name }}
                                <div
                                    class="label"
                                    [matTooltip]="
                                        getEmailAddressByRecipientPlaceholder(toRecipient.name) || toRecipient.email
                                    "
                                    [ngClass]="{
                                        warning: !(
                                            getEmailAddressByRecipientPlaceholder(toRecipient.name) || toRecipient.email
                                        ),
                                    }">
                                    {{
                                        getEmailAddressByRecipientPlaceholder(toRecipient.name) ||
                                            toRecipient.email ||
                                            'Nicht angegeben'
                                    }}
                                </div>
                            </div>
                            <!--********** E-Mail only **********-->
                            <div
                                class="recipient-chip-text-container"
                                *ngIf="!toRecipient.name"
                                [matTooltip]="toRecipient.email">
                                {{ toRecipient.email }}
                            </div>
                            <mat-icon matChipRemove>close</mat-icon>
                        </mat-chip>
                        <!-- The input should be inside the list or else the chips are rendered below the input. -->
                        <input
                            placeholder="An"
                            [(ngModel)]="toRecipientsSearchTerm"
                            #toRecipientsInputElement
                            [matChipInputFor]="toRecipientsChipList"
                            (matChipInputTokenEnd)="enterRecipientChip($event, 'to')"
                            [matChipInputSeparatorKeyCodes]="emailSeparatorKeyCodes"
                            matChipInputAddOnBlur="true"
                            [matAutocomplete]="toRecipientAutocomplete"
                            (ngModelChange)="
                                searchContactPersonOnServer(toRecipientsSearchTerm, 'to');
                                filterAutocompleteForRecipientInput(toRecipientsSearchTerm, 'to')
                            "
                            (focus)="filterAutocompleteForRecipientInput(toRecipientsSearchTerm, 'to')"
                            [disabled]="emailTransmissionPending" />
                    </mat-chip-list>

                    <!--********** Autocomplete **********-->
                    <mat-autocomplete
                        class="email-recipient-autocomplete"
                        #toRecipientAutocomplete="matAutocomplete"
                        (optionSelected)="
                            selectRecipientFromAutocomplete(
                                $event,
                                toRecipientsInputElement,
                                toRecipientsSearchTerm,
                                'to'
                            );
                            splitInvolvedPartyRecipientEmailAddresses();
                            emitEmailChange()
                        ">
                        <mat-option
                            *ngFor="let contactPerson of filteredToRecipients"
                            [value]="contactPerson.email"
                            class="contact-person-autocomplete-option"
                            (mousedown)="$event.preventDefault()">
                            <div class="contact-person-autocomplete-option-inner-container">
                                <div class="autocomplete-option-organization" *ngIf="contactPerson.organization">
                                    {{ contactPerson.organization }}
                                </div>
                                <div class="autocomplete-option-organization" *ngIf="!contactPerson.organization">
                                    {{ contactPerson.firstName }} {{ contactPerson.lastName }}
                                </div>
                                <div class="label">
                                    <span *ngIf="contactPerson.organization">
                                        {{ contactPerson.firstName }} {{ contactPerson.lastName }} -
                                    </span>
                                    {{ contactPerson.email }}
                                </div>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-icon
                    class="email-recipient-input-submenu-icon toolbar-icon"
                    [matMenuTriggerFor]="toRecipientsInputSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #toRecipientsInputSubmenu>
                    <a mat-menu-item [matMenuTriggerFor]="toRecipientsPlaceholderSubmenu">Beteiligte einfügen</a>
                    <a mat-menu-item *ngIf="isRememberingRecipientsAllowed()" (click)="rememberRecipients('to')">
                        Empfänger merken
                    </a>
                    <a
                        *ngIf="getDefaultRecipientsFromPreferences('to')?.length"
                        mat-menu-item
                        [matMenuTriggerFor]="defaultRecipientSubmenu">
                        Standardempfänger
                    </a>
                    <mat-menu #defaultRecipientSubmenu>
                        <a mat-menu-item (click)="fillInDefaultRecipientsFromPreferences('to')">Einfügen</a>
                        <a
                            mat-menu-item
                            matTooltip="Zukünftig keinen Standardempfänger einfügen."
                            (click)="rememberRecipients('to', [])">
                            Entfernen
                        </a>
                    </mat-menu>
                    <a mat-menu-item *ngIf="!ccRecipientInputShown" (click)="ccRecipientInputShown = true">
                        Cc Empfänger hinzufügen
                    </a>
                    <a mat-menu-item *ngIf="!bccRecipientInputShown" (click)="bccRecipientInputShown = true">
                        Bcc Empfänger hinzufügen
                    </a>
                </mat-menu>
                <mat-menu #toRecipientsPlaceholderSubmenu>
                    <a
                        mat-menu-item
                        *ngFor="let recipientPlaceholder of emailAddressPlaceholders"
                        (click)="
                            addPlaceholderToRecipientList(recipientPlaceholder, 'to');
                            splitInvolvedPartyRecipientEmailAddresses();
                            emitEmailChange()
                        ">
                        {{ recipientPlaceholder }}
                    </a>
                </mat-menu>
            </div>
            <!--============================================-->
            <!-- END To Recipients -->
            <!--============================================-->

            <!-- Add CC and BCC Recipient Fields -->
            <div
                class="add-cc-bcc-recipients-button"
                *ngIf="toRecipientInputShown === true && !ccRecipientInputShown && !bccRecipientInputShown"
                (click)="ccRecipientInputShown = true; bccRecipientInputShown = true">
                CC + BCC
            </div>

            <!--============================================-->
            <!-- CC Recipients -->
            <!--============================================-->
            <div class="email-recipient-input-container" *ngIf="ccRecipientInputShown">
                <mat-form-field class="mat-block">
                    <mat-chip-list #ccRecipientsChipList [selectable]="emailTransmissionPending">
                        <mat-chip
                            class="email-recipient-chip"
                            *ngFor="let ccRecipient of email.email.ccRecipients"
                            (removed)="removeRecipient(ccRecipient, 'cc')">
                            <!--********** Name & E-Mail **********-->
                            <div class="recipient-chip-text-container" *ngIf="ccRecipient.name">
                                {{ ccRecipient.name }}
                                <div
                                    class="label"
                                    [matTooltip]="
                                        getEmailAddressByRecipientPlaceholder(ccRecipient.name) || ccRecipient.email
                                    "
                                    [ngClass]="{
                                        warning: !(
                                            getEmailAddressByRecipientPlaceholder(ccRecipient.name) || ccRecipient.email
                                        ),
                                    }">
                                    {{
                                        getEmailAddressByRecipientPlaceholder(ccRecipient.name) ||
                                            ccRecipient.email ||
                                            'Nicht angegeben'
                                    }}
                                </div>
                            </div>
                            <!--********** E-Mail only **********-->
                            <ng-container *ngIf="!ccRecipient.name">{{ ccRecipient.email }}</ng-container>
                            <mat-icon matChipRemove>close</mat-icon>
                        </mat-chip>
                        <!-- The input should be inside the list or else the chips are rendered below the input. -->
                        <input
                            placeholder="Cc"
                            [(ngModel)]="ccRecipientsSearchTerm"
                            #ccRecipientsInputElement
                            [matChipInputFor]="ccRecipientsChipList"
                            (matChipInputTokenEnd)="enterRecipientChip($event, 'cc')"
                            [matChipInputSeparatorKeyCodes]="emailSeparatorKeyCodes"
                            matChipInputAddOnBlur="true"
                            [matAutocomplete]="ccRecipientAutocomplete"
                            (ngModelChange)="
                                searchContactPersonOnServer(ccRecipientsSearchTerm, 'cc');
                                filterAutocompleteForRecipientInput(ccRecipientsSearchTerm, 'cc')
                            "
                            (focus)="
                                resetRecipientAutocomplete();
                                filterAutocompleteForRecipientInput(ccRecipientsSearchTerm, 'cc')
                            "
                            [disabled]="emailTransmissionPending" />
                    </mat-chip-list>

                    <!--********** Autocomplete **********-->
                    <mat-autocomplete
                        #ccRecipientAutocomplete="matAutocomplete"
                        class="min-width-autocomplete"
                        (mousedown)="$event.preventDefault()"
                        (optionSelected)="
                            selectRecipientFromAutocomplete(
                                $event,
                                ccRecipientsInputElement,
                                ccRecipientsSearchTerm,
                                'cc'
                            );
                            splitInvolvedPartyRecipientEmailAddresses();
                            emitEmailChange()
                        ">
                        <mat-option
                            *ngFor="let contactPerson of filteredCcRecipients"
                            [value]="contactPerson.email"
                            class="contact-person-autocomplete-option">
                            <div class="contact-person-autocomplete-option-inner-container">
                                <div class="autocomplete-option-organization" *ngIf="contactPerson.organization">
                                    {{ contactPerson.organization }}
                                </div>
                                <div class="autocomplete-option-organization" *ngIf="!contactPerson.organization">
                                    {{ contactPerson.firstName }} {{ contactPerson.lastName }}
                                </div>
                                <div class="label">
                                    <span *ngIf="contactPerson.organization">
                                        {{ contactPerson.firstName }} {{ contactPerson.lastName }} -
                                    </span>
                                    {{ contactPerson.email }}
                                </div>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-icon
                    class="email-recipient-input-submenu-icon toolbar-icon"
                    [matMenuTriggerFor]="ccRecipientsInputSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #ccRecipientsInputSubmenu="matMenu">
                    <a mat-menu-item [matMenuTriggerFor]="ccRecipientsPlaceholderSubmenu">Beteiligte einfügen</a>
                    <a mat-menu-item *ngIf="isRememberingRecipientsAllowed()" (click)="rememberRecipients('cc')">
                        Cc-Empfänger merken
                    </a>
                    <a
                        *ngIf="getDefaultRecipientsFromPreferences('cc')?.length"
                        mat-menu-item
                        [matMenuTriggerFor]="defaultRecipientSubmenu">
                        Standardempfänger (Cc)
                    </a>
                    <mat-menu #defaultRecipientSubmenu>
                        <a mat-menu-item (click)="fillInDefaultRecipientsFromPreferences('cc')">Einfügen</a>
                        <a
                            mat-menu-item
                            matTooltip="Zukünftig keinen Standardempfänger im Feld Cc einfügen."
                            (click)="rememberRecipients('cc', [])">
                            Entfernen
                        </a>
                    </mat-menu>
                    <a mat-menu-item *ngIf="!bccRecipientInputShown" (click)="bccRecipientInputShown = true">
                        Bcc Empfänger hinzufügen
                    </a>
                </mat-menu>
                <mat-menu #ccRecipientsPlaceholderSubmenu="matMenu" class="visible">
                    <a
                        mat-menu-item
                        *ngFor="let recipientPlaceholder of emailAddressPlaceholders"
                        (click)="
                            addPlaceholderToRecipientList(recipientPlaceholder, 'cc');
                            splitInvolvedPartyRecipientEmailAddresses();
                            emitEmailChange()
                        ">
                        {{ recipientPlaceholder }}
                    </a>
                </mat-menu>
            </div>
            <!--============================================-->
            <!-- END CC Recipients -->
            <!--============================================-->

            <!--============================================-->
            <!-- BCC Recipients -->
            <!--============================================-->
            <div class="email-recipient-input-container" *ngIf="bccRecipientInputShown">
                <mat-form-field class="mat-block">
                    <mat-chip-list #bccRecipientsChipList [selectable]="emailTransmissionPending">
                        <mat-chip
                            class="email-recipient-chip"
                            *ngFor="let bccRecipient of email.email.bccRecipients"
                            (removed)="removeRecipient(bccRecipient, 'bcc')">
                            <!--********** Name & E-Mail **********-->
                            <div class="recipient-chip-text-container" *ngIf="bccRecipient.name">
                                {{ bccRecipient.name }}
                                <div
                                    class="label"
                                    [matTooltip]="
                                        getEmailAddressByRecipientPlaceholder(bccRecipient.name) || bccRecipient.email
                                    "
                                    [ngClass]="{
                                        warning: !(
                                            getEmailAddressByRecipientPlaceholder(bccRecipient.name) ||
                                            bccRecipient.email
                                        ),
                                    }">
                                    {{
                                        getEmailAddressByRecipientPlaceholder(bccRecipient.name) ||
                                            bccRecipient.email ||
                                            'Nicht angegeben'
                                    }}
                                </div>
                            </div>
                            <!--********** E-Mail only **********-->
                            <ng-container *ngIf="!bccRecipient.name">{{ bccRecipient.email }}</ng-container>
                            <mat-icon matChipRemove>close</mat-icon>
                        </mat-chip>
                        <!-- The input should be inside the list or else the chips are rendered below the input. -->
                        <input
                            placeholder="Bcc"
                            [(ngModel)]="bccRecipientsSearchTerm"
                            #bccRecipientsInputElement
                            [matChipInputFor]="bccRecipientsChipList"
                            (matChipInputTokenEnd)="enterRecipientChip($event, 'bcc')"
                            [matChipInputSeparatorKeyCodes]="emailSeparatorKeyCodes"
                            matChipInputAddOnBlur="true"
                            [matAutocomplete]="bccRecipientAutocomplete"
                            (ngModelChange)="
                                searchContactPersonOnServer(bccRecipientsSearchTerm, 'bcc');
                                filterAutocompleteForRecipientInput(bccRecipientsSearchTerm, 'bcc')
                            "
                            (focus)="
                                resetRecipientAutocomplete();
                                filterAutocompleteForRecipientInput(bccRecipientsSearchTerm, 'bcc')
                            "
                            [disabled]="emailTransmissionPending" />
                    </mat-chip-list>

                    <mat-autocomplete
                        #bccRecipientAutocomplete="matAutocomplete"
                        class="min-width-autocomplete"
                        (mousedown)="$event.preventDefault()"
                        (optionSelected)="
                            selectRecipientFromAutocomplete(
                                $event,
                                bccRecipientsInputElement,
                                bccRecipientsSearchTerm,
                                'bcc'
                            );
                            splitInvolvedPartyRecipientEmailAddresses();
                            emitEmailChange()
                        ">
                        <mat-option
                            *ngFor="let contactPerson of filteredBccRecipients"
                            [value]="contactPerson.email"
                            class="contact-person-autocomplete-option">
                            <div class="contact-person-autocomplete-option-inner-container">
                                <div class="autocomplete-option-organization" *ngIf="contactPerson.organization">
                                    {{ contactPerson.organization }}
                                </div>
                                <div class="autocomplete-option-organization" *ngIf="!contactPerson.organization">
                                    {{ contactPerson.firstName }} {{ contactPerson.lastName }}
                                </div>
                                <div class="label">
                                    <span *ngIf="contactPerson.organization">
                                        {{ contactPerson.firstName }} {{ contactPerson.lastName }} -
                                    </span>
                                    {{ contactPerson.email }}
                                </div>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-icon
                    class="email-recipient-input-submenu-icon toolbar-icon"
                    [matMenuTriggerFor]="bccRecipientsInputSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #bccRecipientsInputSubmenu="matMenu">
                    <a mat-menu-item [matMenuTriggerFor]="bccRecipientsPlaceholderSubmenu">Beteiligte einfügen</a>
                    <a mat-menu-item *ngIf="isRememberingRecipientsAllowed()" (click)="rememberRecipients('bcc')">
                        Bcc-Empfänger merken
                    </a>
                    <a
                        *ngIf="getDefaultRecipientsFromPreferences('bcc')?.length"
                        mat-menu-item
                        [matMenuTriggerFor]="defaultRecipientSubmenu">
                        Standardempfänger (Bcc)
                    </a>
                    <mat-menu #defaultRecipientSubmenu>
                        <a mat-menu-item (click)="fillInDefaultRecipientsFromPreferences('bcc')">Einfügen</a>
                        <a
                            mat-menu-item
                            matTooltip="Zukünftig keinen Standardempfänger im Feld Bcc einfügen."
                            (click)="rememberRecipients('bcc', [])">
                            Entfernen
                        </a>
                    </mat-menu>
                    <a mat-menu-item *ngIf="!ccRecipientInputShown" (click)="ccRecipientInputShown = true">
                        Cc Empfänger hinzufügen
                    </a>
                </mat-menu>
                <mat-menu #bccRecipientsPlaceholderSubmenu="matMenu" class="visible">
                    <a
                        mat-menu-item
                        *ngFor="let recipientPlaceholder of emailAddressPlaceholders"
                        (click)="
                            addPlaceholderToRecipientList(recipientPlaceholder, 'bcc');
                            splitInvolvedPartyRecipientEmailAddresses();
                            emitEmailChange()
                        ">
                        {{ recipientPlaceholder }}
                    </a>
                </mat-menu>
            </div>
            <!--============================================-->
            <!-- END BCC Recipients -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Email Recipient -->
        <!--============================================-->

        <!--============================================-->
        <!-- Add yourself to BCC info note -->
        <!--============================================-->
        <div
            id="add-yourself-to-bcc-note"
            class="info-note"
            *ngIf="
                user.emailAccount.username &&
                selectedDocumentGroup === 'report' &&
                !user?.userInterfaceStates.addYourselfToBccNoticeClosed &&
                userHasNoBccDefaultRecipients()
            "
            @slideInAndOutVertically>
            <mat-icon>info</mat-icon>
            <div class="info-note-text">
                <h3>Gesendete E-Mails in den Postausgang</h3>
                <div>
                    Sollen gesendete E-Mails in deinem Mailprogramm angezeigt werden? Speichere dich als BCC-Empfänger
                    und bekomme sie bei jedem Versand in deine Inbox. Von dort verschiebst du sie in den Gesendet-Ordner
                    (z. B. mit Outlook-Regeln).
                    <a href="https://wissen.autoixpert.de/hc/de/articles/7809591287570" target="_blank" rel="noopener">
                        So geht's »
                    </a>
                    <br />
                    <br />
                    <span
                        class="link"
                        (click)="
                            addMyselfToBcc(); fillInDefaultRecipientsFromPreferences('bcc'); showFilledRecipientInputs()
                        ">
                        E-Mails immer auch an mich senden
                    </span>
                </div>
            </div>
            <mat-icon
                class="toolbar-icon"
                (click)="user.userInterfaceStates.addYourselfToBccNoticeClosed = true; saveUser()">
                close
            </mat-icon>
        </div>
        <!--============================================-->
        <!-- END Add yourself to BCC info note -->
        <!--============================================-->

        <mat-form-field class="subject mat-block">
            <input
                matInput
                placeholder="Betreff"
                [(ngModel)]="email.subject"
                (input)="determineMissingPlaceholders()"
                (change)="emitEmailChange()"
                [disabled]="emailTransmissionPending"
                name="email-subject" />
        </mat-form-field>
        <mat-form-field class="message mat-block">
            <mat-quill
                placeholder="Nachricht"
                [(ngModel)]="email.body"
                (input)="determineMissingPlaceholders()"
                [minRows]="6"
                [isEmailQuill]="true"
                (change)="emitEmailChange()"
                [disabled]="emailTransmissionPending"></mat-quill>
        </mat-form-field>

        <!--********** Warning about Placeholders **********-->
        <div class="info-note warning-note missing-placeholder-warning" *ngIf="missingPlaceholders?.length">
            <mat-icon>warning</mat-icon>
            <div class="flex-grow">
                <h3>Platzhalter fehlen</h3>
                <p>
                    Für manche Platzhalter wurde kein Wert gefunden. Füge die relevanten Informationen ins Gutachten ein
                    oder entferne die Platzhalter.
                </p>
                <button
                    id="remove-missing-placeholder-button"
                    (click)="removeMissingPlaceholders()"
                    matTooltip="Lösche alle Platzhalter, für die der Wert im aktuellen Gutachten nicht verfügbar ist.">
                    <mat-icon class="margin-right-5 small-icon">auto_fix_high</mat-icon>
                    Platzhalter ohne Werte entfernen
                </button>
            </div>
        </div>

        <!--============================================-->
        <!-- Templates Button -->
        <!--============================================-->
        <div
            class="insert-text-template-button-container flex-center-content"
            *ngIf="!email.subject && !email.body"
            @slideInAndOutVertically>
            <button (click)="showMessageTemplateSelector()">
                <mat-icon id="template-button-icon" class="button-icon">post_add</mat-icon>
                Vorlage einfügen
            </button>
        </div>
        <!--============================================-->
        <!-- END Templates Button -->
        <!--============================================-->

        <!--============================================-->
        <!-- Attachments -->
        <!--============================================-->
        <div *ngIf="showAttachments" id="attachments-container">
            <!--********** Send One Compound File **********-->
            <ng-container *ngIf="!userPreferences.sendDocumentsSeparately[recipient.role] && activeDocuments.length">
                <div class="attachment-container">
                    <div class="attachment-icon-and-title-container">
                        <mat-icon class="margin-right-10">attach_file</mat-icon>
                        <span [matTooltip]="getFullDocumentName() + ' (bestehend aus den selektierten Dokumenten)'">
                            {{ getFullDocumentName() }}
                        </span>
                    </div>
                </div>

                <!--********** Invoice PDF **********-->
                <!-- "activeDocuments.length > 1" means the invoice should only be displayed as an extra document if it is not the only one selected for a full PDF. -->
                <div
                    class="attachment-container"
                    *ngIf="isInvoiceActive() && isElectronicInvoiceEnabled() && activeDocuments.length > 1">
                    <div class="attachment-icon-and-title-container">
                        <mat-icon class="margin-right-10">attach_file</mat-icon>
                        <span
                            matTooltip="E-Rechnungen sind ab dem 01.01.2025 gesetzlich neu definiert und beinhalten eine maschinenlesbare XML-Datei, damit Rechnungen automatisiert verarbeitet werden können.{{
                                '\n\n'
                            }}Rechnungen müssen separat verschickt werden, damit das Dokument in der PDF und die XML-Datei eindeutig zugeordnet werden.">
                            Rechnung.pdf
                        </span>
                    </div>
                    <mat-icon
                        class="attachment-removal-icon toolbar-icon"
                        (click)="deactivateDocument.emit(this.getInvoiceDocument())"
                        matTooltip="E-Rechnung deaktivieren">
                        close
                    </mat-icon>
                </div>
            </ng-container>

            <!--********** Send Multiple Files **********-->
            <ng-container *ngIf="userPreferences.sendDocumentsSeparately[recipient.role]">
                <ng-container *ngFor="let activeDocument of activeDocuments">
                    <!--********** If documents are sent separately, place the invoice in the desired position. If all other documents
                    are sent together, send the invoice separately. **********-->
                    <div
                        class="attachment-container"
                        *ngIf="
                            userPreferences.sendDocumentsSeparately[recipient.role] ||
                            (isElectronicInvoiceEnabled() && activeDocument.type !== 'invoice')
                        ">
                        <div class="attachment-icon-and-title-container">
                            <mat-icon class="margin-right-10">attach_file</mat-icon>
                            <span [matTooltip]="getDocumentDownloadName(activeDocument)">
                                {{ getDocumentDownloadName(activeDocument) }}
                            </span>
                        </div>
                        <mat-icon
                            class="attachment-removal-icon toolbar-icon"
                            (click)="deactivateDocument.emit(activeDocument)"
                            matTooltip="Dokument deaktiveren">
                            close
                        </mat-icon>
                    </div>
                </ng-container>
            </ng-container>

            <!--********** VXS File **********-->
            <div class="attachment-container" *ngIf="email.isDatVxsAttached">
                <div class="attachment-icon-and-title-container">
                    <mat-icon class="margin-right-10">attach_file</mat-icon>
                    <span matTooltip="DAT-Kalkulation im VXS-Format">DAT-Kalkulation (VXS)</span>
                </div>
                <mat-icon
                    class="attachment-removal-icon toolbar-icon"
                    (click)="removeVxs()"
                    matTooltip="VXS-Datei entfernen">
                    close
                </mat-icon>
            </div>

            <!--********** Split Files **********-->
            <div
                class="toggle-split-documents-container"
                *ngIf="!userPreferences.sendDocumentsSeparately[recipient.role]">
                <mat-icon
                    class="toolbar-icon"
                    (click)="
                        userPreferences.sendDocumentsSeparately[recipient.role] = true;
                        saveUserPreferenceSendingDocumentsSeparately()
                    "
                    matTooltip="In getrennten Dateien versenden">
                    call_split
                </mat-icon>
            </div>

            <!--********** Merge Files **********-->
            <div
                class="toggle-split-documents-container"
                *ngIf="userPreferences.sendDocumentsSeparately[recipient.role]">
                <mat-icon
                    class="toolbar-icon"
                    (click)="
                        userPreferences.sendDocumentsSeparately[recipient.role] = false;
                        saveUserPreferenceSendingDocumentsSeparately()
                    "
                    matTooltip="In einer Datei versenden">
                    call_merge
                </mat-icon>
            </div>

            <!--********** No Documents **********-->
            <div
                class="attachment-container"
                *ngIf="!activeDocuments.length && !this.email.isDatVxsAttached"
                matTooltip="Anhänge rechts im Abschnitt Dokumente auswählen">
                <mat-icon class="margin-right-10">attach_file</mat-icon>
                Keine Anhänge ausgewählt.
            </div>
        </div>
        <!--============================================-->
        <!-- END Attachments -->
        <!--============================================-->

        <div id="send-button-container">
            <div id="send-button" class="split-button" [ngClass]="{ disabled: !isSendingEmailAllowed() }">
                <div
                    class="split-button-left"
                    (click)="triggerEmailSendProcess($event)"
                    (meta.click)="triggerEmailSendProcess($event)"
                    [matTooltip]="getSendButtonTooltip()">
                    <mat-icon class="small-icon" *ngIf="!emailTransmissionPending">send</mat-icon>
                    <img
                        class="pending-icon"
                        src="/assets/images/icons/loading-indicator-white.svg"
                        *ngIf="emailTransmissionPending" />
                    Senden
                </div>
                <div class="split-button-divider"></div>
                <div
                    class="split-button-right"
                    [matMenuTriggerFor]="!isSendingEmailAllowed() ? null : sendButtonSubmenu">
                    <mat-icon class="small-icon">arrow_drop_down</mat-icon>
                </div>
            </div>

            <mat-menu #sendButtonSubmenu="matMenu">
                <a mat-menu-item class="menu-item-with-icon outlined" (click)="scheduleEmailSend()">
                    <mat-icon class="outlined">schedule_send</mat-icon>
                    Zeitversetzt senden
                </a>
            </mat-menu>

            <!-- The VXS-Export contains the DAT damage calculation. That's only relevant for partner garages, not for claimants, insurances etc. -->
            <ng-container *ngIf="recipient.role === 'garage'">
                <button
                    id="attach-vxs-button"
                    (click)="attachVxs()"
                    class="secondary"
                    *ngIf="report?.damageCalculation?.repair.calculationProvider === 'dat' && !email.isDatVxsAttached"
                    [matTooltip]="
                        'Kalkulation im SilverDAT-3-Format (VXS) anhängen. Diese Datei kann von der Werkstatt in SilverDAT 3 myclaim importiert werden.'
                    ">
                    VXS anhängen
                </button>
            </ng-container>
        </div>
    </div>
    <div id="email-just-sent-container" *ngIf="emailJustSentInfo" @successMessageOverlay>
        <mat-icon id="email-just-sent-icon">{{ emailJustSentInfo.icon }}</mat-icon>
        <span>{{ emailJustSentInfo.label }}</span>
    </div>
</div>

<!--********** This component may be used with either a report or an invoice to fill in the placeholders right. **********-->
<message-template-selector
    *ngIf="messageTemplateSelectorShown"
    [report]="report"
    [invoice]="invoice"
    [recipientRole]="recipient.role"
    [templateType]="templateType"
    [letterDocument]="activeCoverLetter"
    (close)="hideMessageTemplateSelector()"
    (templateSelection)="insertTemplateText($event)"
    @runChildAnimations></message-template-selector>

import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Canvas, FabricImage, Rect } from 'fabric';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { SealConfiguration, User } from '@autoixpert/models/user/user';
import { slideInAndOutHorizontally } from 'src/app/shared/animations/slide-in-and-out-horizontally.animation';
import { slideInAndOutVertically } from 'src/app/shared/animations/slide-in-and-out-vertical.animation';
import { blobToBase64 } from 'src/app/shared/libraries/blob-to-base64';
import { ApiErrorService } from 'src/app/shared/services/api-error.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { TutorialStateService } from 'src/app/shared/services/tutorial-state.service';
import { UserSealService } from 'src/app/shared/services/user-seal.service';

@Component({
    selector: 'configure-user-seal',
    templateUrl: './configure-user-seal.component.html',
    styleUrls: ['./configure-user-seal.component.scss'],
    animations: [slideInAndOutHorizontally(), slideInAndOutVertically()],
})
export class ConfigureUserSealComponent implements OnDestroy {
    constructor(
        private toastService: ToastService,
        private tutorialStateService: TutorialStateService,
        private apiErrorService: ApiErrorService,
        private userSealService: UserSealService,
    ) {}

    @ViewChild('imageCanvas') canvasElement: ElementRef<HTMLCanvasElement>;
    @ViewChild('fileInput') fileInput: ElementRef;

    @Input() selectedUser: User;
    @Output() userChange = new EventEmitter<User>();
    @Output() close = new EventEmitter<void>();
    @Output() sealDeleted = new EventEmitter<void>();

    isExistingSealLoadingPending = false;

    // Image management
    // The original uploaded image
    uploadedImageDataUrl: ImageDataUrl = null;
    originalImage: HTMLImageElement = null;
    private imageFormat: 'jpeg' | 'png' = 'jpeg';

    // The current seal (cropped or not) in the reduced size
    currentSealAsDataUrl: ImageDataUrl = null;

    // Current crop parameters
    cropX: number;
    cropY: number;
    cropWidth: number;
    cropHeight: number;

    isDragging = false;
    widthInMillimeters = 80;

    // Canvas and cropping
    private canvas: Canvas;
    private fabricImage: FabricImage;
    protected cropRect: Rect;

    private rerenderPreview$ = new BehaviorSubject<void>(null);

    private subscriptions: Subscription[] = [];

    // Signature Upload
    public signatureUploader: FileUploader;
    public signatureUploadPending: boolean;

    get isCropped(): boolean {
        return this.cropX || this.cropY || this.cropWidth || this.cropHeight ? true : false;
    }

    get aspectRatio() {
        return this.isCropped ? this.cropWidth / this.cropHeight : this.originalImage.width / this.originalImage.height;
    }

    ngOnInit(): void {
        this.initializeExistingConfiguration();
        this.subscribeToCropChange();

        this.initializeSignatureUploader();
    }

    ngOnDestroy(): void {
        this.canvas?.dispose();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    async initializeExistingConfiguration(): Promise<void> {
        if (this.selectedUser.sealConfig?.widthInMillimeters) {
            this.widthInMillimeters = this.selectedUser.sealConfig.widthInMillimeters;
        }
        try {
            if (this.selectedUser.sealConfig?.originalHash) {
                this.isExistingSealLoadingPending = true;
                const existingImageBlob = await this.userSealService.get({
                    userId: this.selectedUser._id,
                    requestOriginal: true,
                });

                this.imageFormat = existingImageBlob.headers.get('Content-Type').includes('jpeg') ? 'jpeg' : 'png';
                const existingImageFile = new File([existingImageBlob.body], `user-seal-original.${this.imageFormat}`, {
                    type: `image/${this.imageFormat}`,
                });
                await this.handleImageUpload(existingImageFile);
                this.isExistingSealLoadingPending = false;

                this.cropX = this.selectedUser.sealConfig.cropX;
                this.cropY = this.selectedUser.sealConfig.cropY;
                this.cropWidth = this.selectedUser.sealConfig.cropWidth;
                this.cropHeight = this.selectedUser.sealConfig.cropHeight;
                if (this.isCropped) {
                    this.startCropMode();
                }
            } else if (this.selectedUser.sealHash) {
                const existingImageBlob = await this.userSealService.get({
                    userId: this.selectedUser._id,
                    requestOriginal: false,
                });

                this.imageFormat = existingImageBlob.headers.get('Content-Type').includes('jpeg') ? 'jpeg' : 'png';
                const existingImageFile = new File([existingImageBlob.body], `user-seal-original.${this.imageFormat}`, {
                    type: `image/${this.imageFormat}`,
                });
                await this.handleImageUpload(existingImageFile);
                const previousWidth = Math.round(this.originalImage?.width / 15);
                if (previousWidth > 0 && previousWidth < 150) {
                    this.widthInMillimeters = previousWidth;
                }
                this.isExistingSealLoadingPending = false;
            }
        } catch (error) {
            this.toastService.error(
                'Bestehende Unterschrift nicht geladen',
                'Bitte lade eine neue Unterschrift hoch. Du kannst den Vorgang auch abbrechen und die bisherige Unterschrift behalten.',
            );
            return;
        }
    }

    resetUserSeal(): void {
        this.uploadedImageDataUrl = null;
        this.currentSealAsDataUrl = null;
        this.fabricImage?.dispose();
        this.canvas?.dispose();
        this.sealDeleted.emit();
    }

    abortEdit(): void {
        this.uploadedImageDataUrl = null;
        this.currentSealAsDataUrl = null;
        this.fabricImage?.dispose();
        this.canvas?.dispose();
        this.close.emit();
    }

    //*****************************************************************************
    //  Upload (Drag and Drop)
    //****************************************************************************/
    // File handling methods
    onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = true;
    }

    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = false;
    }

    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = false;

        const files = event.dataTransfer?.files;
        if (files && files.length) {
            this.handleImageUpload(files[0]);
        }
    }

    triggerFileInput() {
        this.fileInput.nativeElement.click();
    }

    onImageSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length) {
            this.handleImageUpload(input.files[0]);
        }
    }

    private async handleImageUpload(file: File): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (!file.type.startsWith('image/')) {
                this.toastService.error(
                    'Bilddatei hochladen.',
                    'PDFs oder andere Dateitypen werden nicht unterstützt. Du kannst aber einen Screenshot machen und den hochladen.',
                );
                reject();
                return;
            }

            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                // readAsDataURL returns a string
                this.uploadedImageDataUrl = e.target?.result as string;
                this.imageFormat = file.type.includes('jpeg') ? 'jpeg' : 'png';

                // Store the image as image (e.g. to get the original resolution)
                this.originalImage = new Image();
                this.originalImage.src = this.uploadedImageDataUrl;

                setTimeout(() => this.initializeCanvas().then(() => resolve()), 0);
            };
            reader.readAsDataURL(file);
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Upload (Drag and Drop)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Initialize Canvas
    //****************************************************************************/
    // Canvas initialization
    private async initializeCanvas(): Promise<void> {
        if (!this.canvasElement) {
            return;
        }

        if (this.canvas) {
            this.canvas.dispose();
            this.fabricImage = null;
        }

        // Create fabric canvas
        this.canvas = new Canvas(this.canvasElement.nativeElement, {
            selection: false, // Disable group selection
        });

        // Load image
        this.fabricImage = await FabricImage.fromURL(this.uploadedImageDataUrl);

        // Resize the canvas to fit the image
        const containerWidth = this.canvasElement.nativeElement.parentElement.clientWidth;
        const scale = containerWidth / this.fabricImage.width;

        this.fabricImage.scale(scale);
        this.fabricImage.selectable = false;
        this.canvas.setWidth(this.fabricImage.getScaledWidth());
        this.canvas.setHeight(this.fabricImage.getScaledHeight());

        // Center and add the image
        this.canvas.add(this.fabricImage);
        this.canvas.centerObject(this.fabricImage);
        this.canvas.renderAll();
        this.canvas.uniformScaling = false;

        this.updateSealPreview();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialize Canvas
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Crop
    //****************************************************************************/
    toggleCropMode() {
        if (this.cropRect) {
            this.abortCrop();
        } else {
            this.startCropMode();
        }
    }

    async startCropMode(): Promise<void> {
        if (!this.fabricImage || !this.canvas) return;

        let cropLeft, cropTop, cropWidth, cropHeight;

        if (this.isCropped) {
            cropLeft = this.cropX;
            cropTop = this.cropY;
            cropWidth = this.cropWidth;
            cropHeight = this.cropHeight;
        } else {
            const imgWidth = this.fabricImage.getScaledWidth();
            const imgHeight = this.fabricImage.getScaledHeight();

            // Create crop rectangle (50% of image size, centered)
            cropWidth = imgWidth * 0.5;
            // Most signatures are landscape oriented, so we use a 2:1 aspect ratio
            cropHeight = cropWidth * 0.5;

            // Get the image center (the fabric image is positioned at the upper left corner of the canvas)
            const imgCenterX = this.fabricImage.left + imgWidth / 2;
            const imgCenterY = this.fabricImage.top + imgHeight / 2;

            cropLeft = imgCenterX - cropWidth / 2;
            cropTop = imgCenterY - cropHeight / 2;
        }

        // Disable selection on the image
        this.fabricImage.selectable = false;

        // Center the crop rectangle on the image
        this.cropRect = new Rect({
            left: cropLeft,
            top: cropTop,
            width: cropWidth,
            height: cropHeight,
            fill: 'transparent',
            stroke: '#15A9E8',
            strokeWidth: 1,
            cornerColor: '#15A9E8',
            cornerSize: 10,
            transparentCorners: false,
            cornerStyle: 'circle',
            hasRotatingPoint: false,
        });

        this.cropRect.on('moving', this.triggerRerenderPreview.bind(this));
        this.cropRect.on('scaling', this.triggerRerenderPreview.bind(this));

        // Add objects to canvas
        this.canvas.add(this.cropRect);

        // Make crop rectangle active and bring it to front
        this.canvas.setActiveObject(this.cropRect);
        this.canvas.bringObjectToFront(this.cropRect);
        this.canvas.renderAll();

        this.triggerRerenderPreview();
        this.updateSealPreview();
    }

    abortCrop(): void {
        if (!this.canvas) return;

        // Remove crop objects
        this.cropRect.off('moving', this.triggerRerenderPreview.bind(this));
        this.cropRect.off('scaling', this.triggerRerenderPreview.bind(this));

        // Make the image selectable again
        if (this.fabricImage) {
            this.fabricImage.selectable = true;
        }

        // Reset references
        this.cropRect = null;
        this.canvas.remove(this.cropRect);

        this.cropX = null;
        this.cropY = null;
        this.cropWidth = null;
        this.cropHeight = null;

        this.canvas.renderAll();

        this.initializeCanvas();
        this.updateSealPreview();
    }

    /**
     * Store the crop rectangle coordinates when it is moved or resized.
     */
    protected triggerRerenderPreview() {
        this.rerenderPreview$.next();
    }

    private subscribeToCropChange(): void {
        this.subscriptions.push(
            this.rerenderPreview$.pipe(debounceTime(200)).subscribe(() => {
                // Calculate crop coordinates in the scaled space
                if (this.cropRect) {
                    this.cropX = this.cropRect.left;
                    this.cropY = this.cropRect.top;
                    this.cropWidth = this.cropRect.getScaledWidth();
                    this.cropHeight = this.cropRect.getScaledHeight();
                }
                this.updateSealPreview();
            }),
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Crop
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Render and Export
    //****************************************************************************/
    /**
     * Render the current crop area as a new image (e.g. to update the preview).
     */
    async renderSealInReducedSize() {
        // Create a canvas with the exact width in pixels as specified by widthInMillimeters
        const exportCanvas = document.createElement('canvas');
        const exportCtx = exportCanvas.getContext('2d');

        // Calculate width from the exact millimeter value (1mm = 15 Pixels) and calculate height to maintain aspect ratio
        exportCanvas.width = this.widthInMillimeters * 15;
        exportCanvas.height = Math.round((this.widthInMillimeters * 15) / this.aspectRatio);

        if (this.isCropped) {
            // Calculate the ratio between original and displayed image
            const scaleFactorX = this.originalImage.width / this.fabricImage.getScaledWidth();
            const scaleFactorY = this.originalImage.height / this.fabricImage.getScaledHeight();

            // Calculate crop coordinates in the original image space
            const originalCropLeft = Math.round(this.cropX * scaleFactorX);
            const originalCropTop = Math.round(this.cropY * scaleFactorY);
            const originalCropWidth = Math.round(this.cropWidth * scaleFactorX);
            const originalCropHeight = Math.round(this.cropHeight * scaleFactorY);

            // Set canvas size to the cropped dimensions at original resolution
            exportCanvas.width = originalCropWidth;
            exportCanvas.height = originalCropHeight;

            // Draw the cropped portion at original resolution
            exportCtx.drawImage(
                this.originalImage,
                originalCropLeft,
                originalCropTop,
                originalCropWidth,
                originalCropHeight,
                0,
                0,
                originalCropWidth,
                originalCropHeight,
            );
        } else {
            // Draw the image onto the export canvas at the target size
            exportCtx.drawImage(this.originalImage, 0, 0, exportCanvas.width, exportCanvas.height);
        }

        // Return the canvas content as a PNG data URL
        this.currentSealAsDataUrl = exportCanvas.toDataURL(`image/${this.imageFormat}`, 0.85);
        exportCanvas.remove();
    }

    protected updateSealPreview(): void {
        if (!this.uploadedImageDataUrl) {
            return;
        }
        // Render the seal as data url
        this.renderSealInReducedSize();

        const sealPreview = document.getElementById('seal-preview');
        if (sealPreview && this.currentSealAsDataUrl) {
            // Set width based on the user's selected value
            const previewWidth = this.widthInMillimeters;

            // Calculate height to maintain aspect ratio
            const previewHeight = previewWidth / this.aspectRatio;

            // Apply styles
            sealPreview.style.backgroundImage = `url(${this.currentSealAsDataUrl})`;
            sealPreview.style.backgroundSize = 'contain';
            sealPreview.style.backgroundRepeat = 'no-repeat';
            sealPreview.style.backgroundPosition = 'center';
            sealPreview.style.width = `${previewWidth}px`;
            sealPreview.style.height = `${previewHeight}px`;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Render and Export
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Upload
    //****************************************************************************/
    protected async uploadSealImage(): Promise<void> {
        if (!this.currentSealAsDataUrl) {
            this.toastService.error('Bitte wähle zuerst ein Bild aus');
            return;
        }

        this.signatureUploadPending = true;

        try {
            // First, upload the cropped image
            await this.uploadCurrentSeal();

            // Then, store the configuration and upload the original image if needed
            await this.storeConfiguration();

            this.close.emit();
        } catch (error) {
            this.signatureUploadPending = false;
            this.toastService.error('Fehler beim Exportieren des Bildes');
            console.error('Error exporting image:', error);
        }
    }

    /**
     * Uploads the cropped seal image to the server
     */
    private async uploadCurrentSeal(): Promise<void> {
        // Convert data URL to a File object
        const response = await fetch(this.currentSealAsDataUrl);
        const blob = await response.blob();
        const file = new File([blob], `user-seal.${this.imageFormat}`, { type: `image/${this.imageFormat}` });

        // Create a promise that resolves when the upload is complete
        return new Promise<void>((resolve, reject) => {
            // Set up completion handler for this specific upload
            const completeHandler = () => {
                this.signatureUploader.onCompleteAll = null;
                this.signatureUploader.clearQueue();
                resolve();
            };

            // Set options for the uploader - explicitly set isOriginal=false
            this.signatureUploader.setOptions({
                authToken: `Bearer ${store.get('autoiXpertJWT')}`,
                itemAlias: 'seal',
                url: `/api/v0/userSeals?selectedUserId=${this.selectedUser._id}&isOriginal=false`,
            });

            // Set completion handler
            this.signatureUploader.onCompleteAll = completeHandler;
            this.signatureUploader.onErrorItem = (item: FileItem, response: string, status: number) => {
                reject(response);
            };

            // Add file to the uploader queue
            this.signatureUploader.addToQueue([file]);
        });
    }

    private async storeConfiguration(): Promise<void> {
        if (!this.selectedUser.sealConfig) {
            this.selectedUser.sealConfig = new SealConfiguration();
        }

        // Update the configuration
        this.selectedUser.sealConfig.cropX = this.cropX;
        this.selectedUser.sealConfig.cropY = this.cropY;
        this.selectedUser.sealConfig.cropWidth = this.cropWidth;
        this.selectedUser.sealConfig.cropHeight = this.cropHeight;
        this.selectedUser.sealConfig.widthInMillimeters = this.widthInMillimeters;

        // Only if we have an uploaded image
        if (this.uploadedImageDataUrl) {
            /**
             * Upload the original image only, if the hash of the uploaded image is different from the current saved image.
             */
            const uploadedImageBlob = new Blob([this.uploadedImageDataUrl], { type: `image/${this.imageFormat}` });
            const uploadedImageHash = simpleHash(await blobToBase64(uploadedImageBlob));

            if (
                !this.selectedUser.sealConfig.originalHash ||
                this.selectedUser.sealConfig.originalHash !== uploadedImageHash
            ) {
                try {
                    await this.uploadOriginalImage();
                    this.selectedUser.sealConfig.originalHash = uploadedImageHash;
                } catch (error) {
                    this.toastService.error('Fehler beim Hochladen der originalen Unterschrift');
                    console.error('Error uploading original signature:', error);
                }
            }
        }

        this.userChange.emit(this.selectedUser);
    }

    /**
     * Uploads the original image to the server with isOriginal=true
     */
    private async uploadOriginalImage(): Promise<void> {
        const originalSignatureUploader = new FileUploader({
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
            itemAlias: 'seal',
            url: '/api/v0/userSeals',
        });

        originalSignatureUploader.onCompleteAll = () => {
            this.signatureUploader.clearQueue();
        };

        return new Promise<void>((resolve, reject) => {
            originalSignatureUploader.onSuccessItem = async (item: FileItem, response: string, status: number) => {
                if (status !== 201) {
                    const errorResponse: AxError = JSON.parse(response);
                    this.apiErrorService.handleAndRethrow({
                        axError: errorResponse,
                        handlers: {},
                        defaultHandler: {
                            title: 'Hochladen der originalen Unterschrift gescheitert',
                            body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                        },
                    });
                }
                resolve();
            };

            try {
                // Create a file from the original image string
                const response = fetch(this.uploadedImageDataUrl);
                response
                    .then((res) => {
                        res.blob()
                            .then((blob) => {
                                const file = new File([blob], `user-seal-original.${this.imageFormat}`, {
                                    type: `image/${this.imageFormat}`,
                                });

                                // Set options for the uploader - explicitly set isOriginal=true
                                originalSignatureUploader.setOptions({
                                    authToken: `Bearer ${store.get('autoiXpertJWT')}`,
                                    itemAlias: 'seal',
                                    url: `/api/v0/userSeals?selectedUserId=${this.selectedUser._id}&isOriginal=true`,
                                });

                                // Add file to the uploader queue
                                originalSignatureUploader.addToQueue([file]);

                                // Upload the file
                                originalSignatureUploader.uploadAll();
                            })
                            .catch((error) => reject(error));
                    })
                    .catch((error) => reject(error));
            } catch (error) {
                reject(error);
            }
        });
    }

    private initializeSignatureUploader(): void {
        this.signatureUploader = new FileUploader({
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
            itemAlias: 'seal',
            url: '/api/v0/userSeals',
        });

        this.signatureUploader.onAfterAddingFile = async (item: FileItem) => {
            // If the mime type is not a JPG or PNG, remove the file from the queue
            if (!['image/jpeg', 'image/png'].includes(item._file.type)) {
                console.error('The given file is not a JPG or PNG file.', item);
                this.toastService.error('Bitte lade eine JPG- oder PNG-Datei hoch');
                item.remove();
                return;
            }

            // Warn about file size
            if (item._file.size > 500 * 1024) {
                this.toastService.error(
                    'Finale Datei zu groß',
                    'Bitte lade nur Dateien unter 500 KB hoch. Versuche dazu, die Grafik zu verkleinern, den Slider für die Breite weiter nach links zu schieben oder als JPG abzuspeichern, das kleinere Dateigrößen als eine PNG bietet.',
                );
                this.signatureUploadPending = false;
                item.remove();
                return;
            } else if (item._file.size > 300 * 1024) {
                this.toastService.warn(
                    'Datei über 300 KB',
                    'Wir verwenden sie zwar, aber Dateien über 300 KB machen für dich alles langsamer. Z. B. den Download und den Versand per Mail.\n\nVersuche am besten, die Grafik zu verkleinern oder als JPG abzuspeichern, das kleinere Dateigrößen als eine PNG bietet.',
                );
            }

            this.signatureUploadPending = true;

            // Set options again as the selected user ID is not available when the uploader is initialized.
            this.signatureUploader.setOptions({
                authToken: `Bearer ${store.get('autoiXpertJWT')}`,
                itemAlias: 'seal',
                url: '/api/v0/userSeals?selectedUserId=' + this.selectedUser._id,
            });

            this.signatureUploader.uploadAll();
        };

        this.signatureUploader.onSuccessItem = async (item: FileItem, response: string, status: number) => {
            if (status !== 201) {
                const errorResponse: AxError = JSON.parse(response);

                this.apiErrorService.handleAndRethrow({
                    axError: errorResponse,
                    handlers: {},
                    defaultHandler: {
                        title: 'Hochladen der Unterschrift gescheitert',
                        body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                    },
                });
            }

            this.toastService.success('Hochladen von Unterschrift & Stempel erfolgreich');

            this.tutorialStateService.markUserTutorialStepComplete('userSealUploaded');
            this.tutorialStateService.markTeamSetupStepComplete('signatureAndStamp');

            // Create a hash from the file contents of the user seal.
            this.selectedUser.sealHash = simpleHash(await blobToBase64(item._file));
            this.userChange.emit(this.selectedUser);
        };

        this.signatureUploader.onCompleteAll = () => {
            this.signatureUploadPending = false;
            this.signatureUploader.clearQueue();
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Upload
    /////////////////////////////////////////////////////////////////////////////*/
}

type ImageDataUrl = string | null;

<mat-icon class="toolbar-icon" [matMenuTriggerFor]="exportMenu" matTooltip="Auswertungen als CSV herunterladen">
    file_download
</mat-icon>
<mat-menu #exportMenu>
    <!--********** Download Single Records **********-->
    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="downloadSingleRecordsAsCsv()"
        matTooltip="Alle einzelnen Datensätze, die der Auswertung zugrunde liegen.">
        <mat-icon>checklist_rtl</mat-icon>
        Alle Datensätze
    </a>

    <!--********** Download Summary **********-->
    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="downloadSummaryAsCsv()"
        matTooltip="Zusammenfassung in Gruppen, so wie in diesem Screen sichtbar.">
        <mat-icon>summarize</mat-icon>
        Zusammenfassung
    </a>
</mat-menu>

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subtask } from '@autoixpert/models/tasks/task';

@Component({
    selector: 'task-details-subtask-row',
    templateUrl: './task-details-subtask-row.component.html',
    styleUrls: ['./task-details-subtask-row.component.scss'],
    host: {
        '[class.is-completed]': 'subtask.isCompleted',
    },
})
export class TaskDetailsSubtaskRowComponent {
    constructor() {}

    @Input() isNewSubtask: boolean;
    @Input() subtask: Subtask;
    @Output() subtaskChanged: EventEmitter<Subtask> = new EventEmitter<Subtask>();
    @Output() subtaskDeleted: EventEmitter<Subtask> = new EventEmitter<Subtask>();
    @Output() subtaskAdded: EventEmitter<Subtask> = new EventEmitter<Subtask>();

    @ViewChild('titleInput') titleInput;

    isTitleInputFocused = false;

    ngAfterViewInit() {
        if (this.isNewSubtask) {
            this.titleInput?.focus();
        }
    }

    markAsNotDone(subtask: Subtask) {
        subtask.isCompleted = false;
        this.saveSubtask();
    }

    markAsDone(subtask: Subtask) {
        subtask.isCompleted = true;
        this.saveSubtask();
    }

    handleTitleInputBlur() {
        if (!this.subtask.title) {
            this.subtaskDeleted.emit(this.subtask);
        } else {
            this.saveSubtask();
        }
    }

    saveSubtask() {
        this.subtaskChanged.emit(this.subtask);
    }
}

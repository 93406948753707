//import * as isEqual 'lodash/isEqual';
import { DataTypeBase } from '../../models/indexed-db/database.types';
import { isEqualAx } from '../is-equal-ax';

/**
 * Since replacing a record in Angular in redux-style (always emit immutable object copies) often causes scrollbars to be reset,
 * we try to keep all references intact when a changed record comes in from the server.
 *
 * In contrast to lodash.merge this method removes object properties that do not exist on the changed record. Lodash is therefore
 * suitable for applying objects that contain changes only. But with lodash.merge, no properties would ever be removed from the target object.
 *
 * Process:
 * - Remove properties from the target record that do not exist in the changed record anymore.
 * - For arrays
 *   - If no first element exists or the first element is not an object, remove all array elements. Relevant for arrays of primitives.
 *   - Remove all array elements whose ID does not exist in the changed record array.
 *   - Update all array elements through a recursive mergeRecord.
 *   - Add all array elements that do not exist in the target record array but in the changed record array.
 * - Merge object recursively
 * - Assign primitive types from the changed record.
 */
export function mergeRecord<DataType extends DataTypeBase>(targetRecord: DataType, changedRecord: DataType): void {
    for (const propertyName in targetRecord) {
        /**
         * Don't handle properties from the prototype chain. Only properties from this very object are relevant for merging because the properties
         * from the prototype chain remain accessible no matter what properties exist on this object.
         */
        if (!targetRecord.hasOwnProperty(propertyName)) continue;

        /**
         * Remove properties that do not exist in the changed object (anymore).
         */
        if (!changedRecord.hasOwnProperty(propertyName)) {
            delete targetRecord[propertyName];
        }
    }

    for (const propertyName in changedRecord) {
        const targetValue = targetRecord[propertyName];
        const changedValue = changedRecord[propertyName];

        // If both properties are exactly the same (same object, same array or same primitive), no need to merge
        // because any changes in one records must be present in the second record as well then.
        if (isEqualAx(targetValue, changedValue)) continue;

        if (targetValue === undefined || targetValue === null) {
            targetRecord[propertyName] = changedValue;
        } else if (Array.isArray(targetValue)) {
            /**
             * An array needs to be merged.
             */
            if (Array.isArray(changedValue)) {
                /**
                 * Arrays of strings, numbers, ... or objects without an _id property can't be properly sorted & updated. In that case, remove all existing elements from the
                 * target array and add all elements from the changed array.
                 */
                if (!changedValue[0]?._id) {
                    // Remove all array elements. Since this method "mergeRecord()" tries to keep all references, don't simply assign an empty array.
                    while (targetValue.length > 0) {
                        targetValue.pop();
                    }

                    targetValue.push(...changedValue);
                } else {
                    /**
                     * An array of objects with an _id property. That makes the array perfectly sortable & easy to merge.
                     */
                    const targetArrayObjects = [...targetValue];

                    // Remove all array elements. Since this method "mergeRecord()" tries to keep all references, don't simply assign an empty array.
                    while (targetValue.length > 0) {
                        targetValue.pop();
                    }

                    /**
                     * Let the changed array define the new target array order.
                     */
                    for (const changedValueElement of changedValue) {
                        const targetElementWithSameId = targetArrayObjects.find(
                            (targetElement) => targetElement._id === changedValueElement._id,
                        );
                        // Since we want to keep the reference of the existing target object, merge the two objects.
                        if (targetElementWithSameId) {
                            mergeRecord<DataType>(targetElementWithSameId, changedValueElement);
                            targetValue.push(targetElementWithSameId);
                        } else {
                            /**
                             * The changed value element is a new element that is not known in the target array, so add it.
                             */
                            targetValue.push(changedValueElement);
                        }
                    }
                }
            } else {
                /**
                 * An array property is set to undefined or null.
                 * If this array property had been removed in the changed object, it would have been removed from the target object in the loop at the very top. So, there is
                 * no need to check for an existing property here.
                 */
                targetRecord[propertyName] = changedValue;
            }
        } else if (targetValue instanceof Object && changedValue instanceof Object) {
            /**
             * Plain objects can be easily merged.
             *
             * We must check both variables to be an object for one could be null, which is also an object in JavaScript.
             */
            mergeRecord<any>(targetValue, changedValue);
        } else {
            /**
             * Primitive types like strings, booleans, numbers and null cannot be merged. Assign them directly.
             */
            targetRecord[propertyName] = changedValue;
        }
    }
}

//*****************************************************************************
//  Testing
//****************************************************************************/
/**
 * TODO This should be moved to a unit test as soon as we have unit tests in the frontend.
 *
 * Paste this in the Chrome Developer Tools console to check if the changes were made correctly.
 *
 * Move this to a location where mergeRecord is defined so that the console statement below can execute this function.
 * > (window as any).mergeRecord = mergeRecord;
 */
//let target = {
//    name        : "Steffen",
//    positions   : ['CEO', 'CFO', 'HRC'],
//    possessions : [
//        {
//            _id  : '1',
//            name : 'Brieftasche'
//        },
//        {
//            _id  : '2',
//            name : 'Laptop'
//        },
//        {
//            _id  : '4',
//            name : 'Maus'
//        },
//        {
//            _id  : '3',
//            name : 'Tastatur'
//        },
//    ],
//    nickname    : 'Stevie'
//};
//mergeRecord(target, {
//    name        : "Steffen",
//    positions   : ['CFO', 'HRC'],
//    possessions : [
//        {
//            _id  : '1',
//            name : 'Geldbeutel'
//        },
//        {
//            _id  : '2',
//            name : 'Laptop'
//        },
//        {
//            _id  : '3',
//            name : 'Tastatur'
//        },
//        {
//            _id  : '5',
//            name : 'Schuhe'
//        },
//    ],
//});
// target;
/////////////////////////////////////////////////////////////////////////////*/
//  END Testing
/////////////////////////////////////////////////////////////////////////////*/

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { generateId } from '@autoixpert/lib/generate-id';
import { translateTextTemplateRole } from '@autoixpert/lib/translators/translate-recipient-role';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { TextTemplate, TextTemplateRole } from '@autoixpert/models/text-templates/text-template';
import { DefaultMessageTemplateReference } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { isSmallScreen, isTouchOnly } from '../../../../shared/libraries/is-small-screen';
import { hasAccessRight } from '../../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { FieldGroupConfigService } from '../../../../shared/services/field-group-config.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { TemplatePlaceholderValuesService } from '../../../../shared/services/template-placeholder-values.service';
import { TextTemplateService } from '../../../../shared/services/textTemplate.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';

@Component({
    selector: 'message-template-selector',
    templateUrl: 'message-template-selector.component.html',
    styleUrls: ['message-template-selector.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class MessageTemplateSelector {
    constructor(
        private templatePlaceholderValueService: TemplatePlaceholderValuesService,
        private textTemplateService: TextTemplateService,
        public userPreferences: UserPreferencesService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private loggedInUserService: LoggedInUserService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
    ) {}

    @Input() recipientRole: TextTemplateRole;
    @Input() report: Report;
    @Input() invoice: Invoice;
    @Input() templateType:
        | 'coverLetter'
        | 'email'
        | 'invoiceEmail'
        | 'invoiceLetter'
        | 'expertStatementLetter'
        | 'remoteSignatureEmail';
    // Pass a DocumentMetadata object if you want the placeholder values of the report/invoice to be enriched with
    // additional values based on this documentMetadata. This is useful if you're using this selector to generate
    // text for letters or an expert statement.
    @Input() letterDocument: DocumentMetadata;

    @Output() templateSelection: EventEmitter<TextTemplate> = new EventEmitter<TextTemplate>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    public user: User;

    messageTemplates: TextTemplate[] = [];
    filteredMessageTemplates: TextTemplate[] = [];

    selectedTemplate: TextTemplate = null;
    public subjectPreview: string = '';
    public bodyPreview: string = '';

    inEditMode: boolean = false;

    searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    searchTerm: string = '';

    placeholderValuesForCurrentReportOrInvoice: any;
    private fieldGroupConfigs: FieldGroupConfig[] = [];

    subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();

        this.subscribeToSearchTerm$();

        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        try {
            await this.getLatestPlaceholderValues();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Platzhalterwerte nicht geladen',
                    body: 'Die Werte, die zum Ersetzen der Platzhalter in den Vorlagen benötigt werden, konnten nicht geladen werden.',
                },
            });
        }

        this.getAllTemplatesFromServer();
    }

    private getAllTemplatesFromServer() {
        this.textTemplateService
            .find({
                type: this.templateType,
                role: this.recipientRole,
            })
            .subscribe({
                next: (textTemplates) => {
                    this.messageTemplates = textTemplates;
                    this.filterAndSortTemplates();

                    this.selectMessageTemplate(this.filteredMessageTemplates[0]);
                },
                error: (error) => {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Textvorlagen nicht geladen',
                            body: 'Die Textvorlagen konnten nicht geladen werden. Probiere es erneut. Sollte das Problem bestehen bleiben, wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        },
                    });
                },
            });
    }

    private async getLatestPlaceholderValues(): Promise<void> {
        // The invoice is passed if this message template selector is called from within the invoice process (invoice list > invoice editor).
        if (this.invoice) {
            this.placeholderValuesForCurrentReportOrInvoice =
                await this.templatePlaceholderValueService.getInvoiceValues({
                    invoiceId: this.invoice._id,
                    letterDocument: this.letterDocument,
                });
        } else {
            this.placeholderValuesForCurrentReportOrInvoice =
                await this.templatePlaceholderValueService.getReportValues({
                    reportId: this.report?._id,
                    letterDocument: this.letterDocument,
                });
        }
    }

    private subscribeToSearchTerm$(): void {
        // Skip the first (empty) search term returned by the BehaviorSubject "searchTerm$".
        const subscription = this.searchTerm$.pipe(skip(1)).subscribe({
            next: () => this.filterAndSortTemplates(),
        });

        this.subscriptions.push(subscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  General
    //****************************************************************************/
    public translateTextTemplateRole = translateTextTemplateRole;

    public closeTemplateSelector(): void {
        this.close.emit();
    }

    public useTemplate(textTemplate: TextTemplate): void {
        const templateWithReplacedPlaceholders = {
            ...textTemplate,
            subject: this.subjectPreview,
            body: this.bodyPreview,
        };

        this.templateSelection.emit(templateWithReplacedPlaceholders);
        this.closeTemplateSelector();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END General
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  List
    //****************************************************************************/
    public selectMessageTemplate(messageTemplate: TextTemplate): void {
        this.leaveEditMode();
        this.selectedTemplate = messageTemplate;
        this.updateSubjectPreview();
        this.updateBodyPreview();
    }

    public filterAndSortTemplates(): void {
        this.sortByDragOrder();

        this.filteredMessageTemplates = [...this.messageTemplates];

        this.applySearchFilter();
    }

    public updateSearchTerm(): void {
        this.searchTerm$.next(this.searchTerm);
    }

    private applySearchFilter(): void {
        if (this.searchTerm === '' || typeof this.searchTerm === 'undefined') {
            return;
        }

        const searchTerms = this.searchTerm.toLowerCase().split(' ');

        this.filteredMessageTemplates = this.filteredMessageTemplates.filter((messageTemplate) => {
            const propertiesToSearch = [
                messageTemplate.title || '',
                messageTemplate.idSetByUser + '' || '',
                messageTemplate.subject || '',
                messageTemplate.body || '',
            ]
                // To be better comparable, convert all properties to lowercase
                .map((property) => property && property.toLowerCase());

            return searchTerms.every((searchTerm) => {
                return propertiesToSearch.some((property) => property.includes(searchTerm));
            });
        });
    }

    private sortByDragOrder(): void {
        this.messageTemplates.sort((templateA, templateB) => {
            return +templateA.dragOrderPosition - +templateB.dragOrderPosition;
        });
    }

    public sortByTitle(): void {
        if (!this.user?.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.showMissingAccessRightNotification();
            return;
        }

        this.messageTemplates.sort((templateA, templateB) => {
            return (templateA.title ?? '').localeCompare(templateB.title ?? '');
        });
        // Save order to server
        this.updateDragOrderPositionOnAllTemplates();

        this.filterAndSortTemplates();
    }

    //*****************************************************************************
    //  Drag & Drop
    //****************************************************************************/
    public handleReorderingDrop(event: CdkDragDrop<TextTemplate>): void {
        // Extract from the array...
        const textTemplate = this.messageTemplates.splice(event.previousIndex, 1)[0];
        // ... and insert at new position.
        this.messageTemplates.splice(event.currentIndex, 0, textTemplate);

        // Save order to server
        this.updateDragOrderPositionOnAllTemplates();

        this.filterAndSortTemplates();
    }

    private updateDragOrderPositionOnAllTemplates(): void {
        for (const [key, messageTemplate] of this.messageTemplates.entries()) {
            const oldPosition: number = messageTemplate.dragOrderPosition;
            messageTemplate.dragOrderPosition = key;

            // Send updates to server
            if (messageTemplate.dragOrderPosition !== oldPosition) {
                this.saveTextTemplate(messageTemplate);
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Drag & Drop
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Details
    //****************************************************************************/
    public enterEditMode(): void {
        if (!this.user?.accessRights.editTextsAndDocumentBuildingBlocks) {
            return;
        }
        this.inEditMode = true;
    }

    public leaveEditMode(): void {
        this.inEditMode = false;
    }

    protected showMissingAccessRightNotification(): void {
        this.toastService.warn(
            'Fehlende Berechtigung',
            'Dir fehlt die Berechtigung Textvorlagen zu bearbeiten, sie anzulegen oder sie zu löschen. Dein Admin muss dich dafür freischalten.',
        );
        return;
    }

    public addTextTemplate(): void {
        if (!this.user?.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.showMissingAccessRightNotification();
            return;
        }

        const newTextTemplate = new TextTemplate({
            type: this.templateType,
            role: this.recipientRole,
            idSetByUser: this.getHighestId() + 1 + '',
        });

        // Create on server and in cache
        this.textTemplateService.create(newTextTemplate);

        // Show locally
        this.messageTemplates.unshift(newTextTemplate);
        // Remove search term so that the new entry is visible on the left-hand list
        this.searchTerm = '';
        this.updateSearchTerm();
        this.filterAndSortTemplates();
        this.selectedTemplate = newTextTemplate;
        this.inEditMode = true;
        this.updateBodyPreview();
    }

    public copyTextTemplate(textTemplate: TextTemplate): void {
        const newTextTemplate: TextTemplate = JSON.parse(JSON.stringify(textTemplate));
        newTextTemplate._id = generateId();
        newTextTemplate.title = `${textTemplate.title} (Kopie)`;

        // Create on server and in cache
        this.textTemplateService.create(newTextTemplate);

        // Show locally. Add behind original.
        const index = this.messageTemplates.indexOf(textTemplate) + 1;
        this.messageTemplates.splice(index, 0, newTextTemplate);
        this.filterAndSortTemplates();
        this.selectedTemplate = newTextTemplate;
        this.inEditMode = true;
    }

    public setStandardTemplate(
        textTemplate: TextTemplate,
        documentGroup: 'report' | 'repairConfirmation' | 'invoiceEmail' | 'remoteSignatureEmail',
    ): void {
        // Preferences for either email or cover letter
        let templateReference: DefaultMessageTemplateReference;
        switch (this.templateType) {
            case 'email':
                templateReference = this.userPreferences.defaultEmailTemplates;
                break;
            case 'coverLetter':
                templateReference = this.userPreferences.defaultCoverLetterTemplates;
                break;
            case 'invoiceEmail':
                templateReference = this.userPreferences.defaultInvoiceEmailTemplates;
                break;
            case 'remoteSignatureEmail':
                templateReference = this.userPreferences.defaultRemoteSignatureEmailTemplates;
                break;
        }

        // Remember the ID of the selected template. Assign null if the user wants to remove the default template
        templateReference[documentGroup][this.recipientRole] = textTemplate ? textTemplate._id : null;

        switch (this.templateType) {
            case 'email':
                this.userPreferences.defaultEmailTemplates = templateReference;
                break;
            case 'coverLetter':
                this.userPreferences.defaultCoverLetterTemplates = templateReference;
                break;
            case 'invoiceEmail':
                this.userPreferences.defaultInvoiceEmailTemplates = templateReference;
                break;
            case 'remoteSignatureEmail':
                if (this.recipientRole !== 'recipient') {
                    this.userPreferences.defaultRemoteSignatureEmailTemplates = templateReference;
                }
                break;
        }
    }

    public deleteTextTemplate(textTemplate: TextTemplate): void {
        this.textTemplateService.delete(textTemplate._id);

        const index = this.messageTemplates.indexOf(textTemplate);
        this.messageTemplates.splice(index, 1);
        this.filterAndSortTemplates();
        this.selectedTemplate = this.messageTemplates[index - 1] || this.messageTemplates[0];

        const restorationToast = this.toastService.info('Textvorlage gelöscht', 'Zum Wiederherstellen hier klicken.', {
            showProgressBar: true,
            timeOut: 10000,
        }); //RestorationToast
        this.subscriptions.push(
            restorationToast.click.subscribe(() => {
                this.restoreTextTemplate(textTemplate, index);
            }),
        );
    }

    private restoreTextTemplate(textTemplate: TextTemplate, index: number): void {
        // Re-create on server
        this.textTemplateService.create(textTemplate);
        this.toastService.success('Vorlage wiederhergestellt');

        // Re-create locally
        this.messageTemplates.splice(index, 0, textTemplate);
        this.filterAndSortTemplates();
    }

    /**
     * Find and return the highest user-set ID by number comparison.
     *
     * @returns {string}
     */
    private getHighestId(): number {
        if (this.messageTemplates.length === 0) {
            return 0;
        }

        // Make a copy so that we don't alter the original array with our sorting
        const messageTemplates = [...this.messageTemplates];
        messageTemplates.sort((templateA, templateB) => {
            const idA: number = +templateA.idSetByUser || 0;
            const idB: number = +templateB.idSetByUser || 0;
            return idA < idB ? -1 : 1;
        });

        // The item at the last index must have the highest ID
        return +messageTemplates[messageTemplates.length - 1].idSetByUser;
    }

    public isTemplateDefault(
        textTemplate: TextTemplate,
        documentGroup: 'report' | 'repairConfirmation' | 'invoiceEmail' | 'remoteSignatureEmail',
    ): boolean {
        if (!textTemplate) {
            return false;
        }

        switch (this.templateType) {
            case 'email':
                return (
                    this.userPreferences.defaultEmailTemplates[documentGroup]?.[this.recipientRole] === textTemplate._id
                );
            case 'coverLetter':
                return (
                    this.userPreferences.defaultCoverLetterTemplates[documentGroup]?.[this.recipientRole] ===
                    textTemplate._id
                );
            case 'invoiceEmail':
                return (
                    this.userPreferences.defaultInvoiceEmailTemplates[documentGroup]?.[this.recipientRole] ===
                    textTemplate._id
                );

            case 'remoteSignatureEmail':
                return (
                    this.userPreferences.defaultRemoteSignatureEmailTemplates[documentGroup]?.[this.recipientRole] ===
                    textTemplate._id
                );
        }
    }

    //*****************************************************************************
    //  Preview
    //****************************************************************************/
    public updateSubjectPreview() {
        this.subjectPreview = replacePlaceholders({
            textWithPlaceholders: this.selectedTemplate?.subject,
            placeholderValues: this.placeholderValuesForCurrentReportOrInvoice,
            fieldGroupConfigs: this.fieldGroupConfigs,
            isHtmlAllowed: false,
        })
            /**
             * In case there are placeholders that contain line-breaks such as {Anspruchsteller.NameKomplett}, ensure
             * those line-breaks are converted to spaces for the one-line subject. If this was not done, the browser
             * would remove the line-break and the two lines would be merged back-to-back without a space, e.g.
             * "autoiXpert GmbH & Co. KGSteffen Langer" instead of "autoiXpert GmbH & Co. KG Steffen Langer".
             */
            .replace(/\n/g, ' ');
    }

    public updateBodyPreview() {
        this.bodyPreview = replacePlaceholders({
            textWithPlaceholders: this.selectedTemplate?.body,
            placeholderValues: this.placeholderValuesForCurrentReportOrInvoice,
            fieldGroupConfigs: this.fieldGroupConfigs,
            isHtmlAllowed: true,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Preview
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Details
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Responsiveness
    //****************************************************************************/
    public isSmallScreen = isSmallScreen;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Responsiveness
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public saveTextTemplate(textTemplate: TextTemplate): void {
        this.textTemplateService.put(textTemplate);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public closeEditorOnEscKey(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            // Make sure saving is triggered if the user is still inside an input.
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                (document.activeElement as HTMLElement).blur();
            }
            event.preventDefault();
            event.stopPropagation();
            this.close.emit();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected readonly isTouchOnly = isTouchOnly;
    protected readonly hasAccessRight = hasAccessRight;
}

import { Component, Input } from '@angular/core';
import { DowntimeNotification } from '@autoixpert/models/notifications/downtime-notification';

@Component({
    selector: 'downtime-notification-overlay',
    templateUrl: './downtime-notification-overlay.component.html',
    styleUrls: ['./downtime-notification-overlay.component.scss'],
})
export class DowntimeNotificationOverlayComponent {
    @Input() applicableMessages: DowntimeNotification[];
}

import { isEqualAx } from '../is-equal-ax';

/**
 * Deep compare two objects. Returns an object map with deep paths ('path.to.property') as key and the changed value of the current state as value.
 * @param stateBefore
 * @param stateAfter
 * @param changes
 */
export function extractChanges(stateBefore: any, stateAfter: any, changes: any = {}): { [key: string]: any } {
    // If an entire property was removed, pretend the property was set to null.
    if (typeof stateBefore !== 'undefined' && typeof stateAfter === 'undefined') {
        changes = null;
    }
    // If at least one element of the array deviates, mark the entire array to be updated. This enables deleting array elements.
    else if (Array.isArray(stateAfter)) {
        if (!isEqualAx(stateBefore, stateAfter)) {
            changes = stateAfter;
        }
    } else if (stateAfter !== null && typeof stateAfter === 'object') {
        /**
         * Iterate over arrays and objects.
         * typeof null === 'object', so check for that before checking for other objects.
         */
        // If the stateAfter is an object but the stateBefore is null, updating with full paths such as "garage.contactPerson.fees.carBody" does not work if
        // "garage.contactPerson.fees" is null. Instead, create a put statement like "garage.contactPerson.fees" : {...} by adding the "flatten: false"
        // property. That will be recognized when flattening change objects within flattenChangePaths().
        if (stateBefore === null) {
            changes = {
                ...stateAfter,
                _flatten: false,
            };
        } else {
            const stateAllProperties = {
                ...(stateBefore || {}),
                ...stateAfter,
            };
            for (const property in stateAllProperties) {
                if (!stateAllProperties.hasOwnProperty(property)) continue;

                // If the stateBefore does not have this object, an error would be thrown because the property cannot be read of undefined. So, ensure we can handle this value. That
                // cannot happen with stateAfter because of the if statement "typeof stateBefore !== 'undefined' && typeof stateAfter === 'undefined'" at the top.
                const childChanges = extractChanges(
                    stateBefore ? stateBefore[property] : undefined,
                    stateAfter[property],
                );
                // Only add a change if the change is non-empty: Add primitives (non-objects), null, arrays and objects with more than zweo keys
                if (
                    typeof childChanges !== 'object' ||
                    childChanges === null ||
                    Array.isArray(childChanges) ||
                    Object.keys(childChanges).length
                ) {
                    changes[property] = childChanges;
                }
            }
        }
    }
    // String, boolean, integer, undefined, null
    else {
        if (stateBefore !== stateAfter) {
            changes = stateAfter;
        }
    }

    return changes;
}

//// For debugging
//(window as any).extractChanges = extractChanges;

<!--********** Condition group **********-->
<ng-container *ngIf="isDocumentBuildingBlockConditionGroup(condition)">
    <div class="condition-group-row">
        <!-- Condition group headline -->
        <div class="condition-group-headline-container">
            <!-- Help text: and operator -->
            <div *ngIf="condition.conditionsOperator === 'and'">Alle Bedingungen müssen erfüllt sein.</div>

            <!-- Help text: or operator -->
            <div *ngIf="condition.conditionsOperator === 'or'">Mindestens eine Bedingung muss erfüllt sein.</div>

            <!-- Add condition button -->
            <div
                *ngIf="conditionNestingDepth >= 1"
                class="condition-group-add-button"
                matTooltip="Neue Bedingung"
                (click)="addCondition()">
                <mat-icon>add</mat-icon>
            </div>

            <!-- Add condition or condition group button -->
            <div
                *ngIf="conditionNestingDepth < 1"
                class="condition-group-add-button"
                matTooltip="Neue Bedingung"
                [matMenuTriggerFor]="conditionGroupAddMenu">
                <mat-icon>add</mat-icon>
            </div>

            <mat-menu class="condition-group-add-menu" #conditionGroupAddMenu="matMenu">
                <a mat-menu-item (click)="addCondition()">
                    <mat-icon>rule</mat-icon>
                    Neue Bedingung
                </a>

                <a mat-menu-item (click)="addConditionGroup()">
                    <mat-icon>widgets</mat-icon>
                    Neue Bedingungsgruppe
                </a>
            </mat-menu>

            <!-- Settings button -->
            <div
                class="condition-group-settings-button"
                matTooltip="Einstellungen"
                [matMenuTriggerFor]="conditionGroupSettingsMenu">
                <mat-icon>more_vert</mat-icon>
            </div>

            <mat-menu class="condition-group-settings-menu" #conditionGroupSettingsMenu="matMenu">
                <!-- Duplicate condition group -->
                <a mat-menu-item class="menu-item-with-icon" (click)="duplicateCondition.emit(condition)">
                    <mat-icon>content_copy</mat-icon>
                    Kopieren
                </a>

                <!-- Delete condition group -->
                <a mat-menu-item class="menu-item-with-icon" (click)="removeCondition.emit(condition)">
                    <mat-icon>delete</mat-icon>
                    Löschen
                </a>
            </mat-menu>

            <!-- Drag handle -->
            <ng-content select="[drag-handle-condition-group]"></ng-content>
        </div>

        <!-- Condition group operator (only show for >=2 conditions) -->
        <div
            class="condition-group-operator"
            [ngSwitch]="condition.conditionsOperator"
            [style.position]="'absolute'"
            [style.left.px]="-12"
            [style.top]="'calc(50% - 12px)'"
            [matMenuTriggerFor]="conditionGroupOperatorMenu"
            [matTooltip]="
                condition.conditionsOperator === 'or'
                    ? 'Mindestens eine Bedingung muss erfüllt sein'
                    : 'Alle Bedingungen müssen erfüllt sein'
            ">
            <span *ngSwitchDefault>&</span>
            <mat-icon *ngSwitchCase="'or'">call_split</mat-icon>
        </div>

        <mat-menu class="condition-group-operator-menu" #conditionGroupOperatorMenu="matMenu">
            <a mat-menu-item class="condition-group-operator-menu-item" (click)="setGroupOperator(condition, 'and')">
                <div class="condition-group-operator">&</div>
                Und
            </a>

            <a mat-menu-item class="condition-group-operator-menu-item" (click)="setGroupOperator(condition, 'or')">
                <div class="condition-group-operator">
                    <mat-icon>call_split</mat-icon>
                </div>
                Oder
            </a>
        </mat-menu>

        <!-- Sub conditions -->
        <div
            class="condition-group-sub-conditions-container"
            cdkDropList
            (cdkDropListDropped)="hanldeConditionDrop($event)">
            <ng-container *ngFor="let subCondition of condition.conditions; trackBy: trackById">
                <div
                    cdkDrag
                    [style.margin]="isDocumentBuildingBlockConditionGroup(subCondition) ? '0px 8px' : undefined">
                    <document-building-block-condition
                        [condition]="subCondition"
                        [fieldGroupConfigs]="fieldGroupConfigs"
                        [conditionNestingDepth]="conditionNestingDepth + 1"
                        [placeholderValueTree]="placeholderValueTree"
                        [availableDocumentPlaceholders]="availableDocumentPlaceholders"
                        (removeCondition)="handleRemoveChildCondition($event)"
                        (duplicateCondition)="handleDuplicateChildCondition($event)"
                        (turnIntoConditionGroup)="handleTurnIntoConditionGroup($event)">
                        <!-- Drag handle for condition (must be in same template as cdkDrag)-->
                        <mat-icon
                            drag-handle-condition
                            class="condition-grab-handle"
                            matTooltip="Position verschieben"
                            cdkDragHandle>
                            drag_indicator
                        </mat-icon>

                        <!-- Drag handle for condition group (must be in same template as cdkDrag)-->
                        <mat-icon
                            drag-handle-condition-group
                            class="condition-group-grab-handle"
                            matTooltip="Position verschieben"
                            cdkDragHandle>
                            drag_indicator
                        </mat-icon>
                    </document-building-block-condition>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<!--********** Condition **********-->
<ng-container *ngIf="!isDocumentBuildingBlockConditionGroup(condition)">
    <div class="condition-row" [class.nested-condition]="conditionNestingDepth > 0">
        <!--********** Property Path **********-->
        <div class="condition-property-path-container">
            <mat-form-field [floatLabel]="'never'" class="condition-property-path-input light-mode">
                <input
                    #propertyPathInput
                    matInput
                    placeholder="Eigenschaft"
                    [(ngModel)]="condition.propertyPath"
                    (ngModelChange)="filterPlaceholdersForConditions($event)"
                    (focus)="filterPlaceholdersForConditions(documentPlaceholderInput.value)"
                    (change)="onPropertyPathSelect(condition)"
                    [matAutocomplete]="documentPlaceholderAutocomplete"
                    #documentPlaceholderInput />
                <mat-hint class="warning" *ngIf="condition.propertyPath && !propertyPathExists(condition.propertyPath)">
                    Eigenschaft existiert nicht.
                </mat-hint>
                <mat-autocomplete
                    #documentPlaceholderAutocomplete
                    (mousedown)="$event.preventDefault()"
                    (optionSelected)="onPropertyPathSelect(condition)"
                    (opened)="initializeAutocompleteVirtualScrollViewport()">
                    <cdk-virtual-scroll-viewport
                        #viewport
                        itemSize="30"
                        minBufferPx="1000"
                        maxBufferPx="1000"
                        class="cdk-virtual-scroll-vertical-only"
                        [style.height.px]="Math.min(filteredPlaceholdersForConditions.length * 30, 254)"
                        [style.max-height.px]="254">
                        <ng-container *cdkVirtualFor="let placeholder of filteredPlaceholdersForConditions">
                            <mat-option class="compact-option" [style.height.px]="30" [value]="placeholder">
                                <div class="placeholder-option-with-example-value-container">
                                    <!-- Placeholder label -->
                                    <div [matTooltip]="placeholder" class="placeholder-option-placeholder-label">
                                        {{ placeholder }}
                                    </div>

                                    <!-- Example value -->
                                    <div
                                        class="placeholder-option-example-value"
                                        [matTooltip]="getPlaceholderValue(placeholder)">
                                        {{ getPlaceholderValue(placeholder) }}
                                    </div>
                                </div>
                            </mat-option>
                        </ng-container>
                    </cdk-virtual-scroll-viewport>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <!--********** Operator **********-->
        <div class="condition-operator-with-label-container light-mode" *ngIf="condition.propertyPath">
            <div
                class="condition-operator-with-label"
                [ngSwitch]="condition.operator"
                [ngClass]="{ 'comparison-value-is-visible': !isComparisonValueHidden(condition) }"
                [matMenuTriggerFor]="conditionsOperatorMenu">
                <!-- equal -->
                <span *ngSwitchCase="'equal'" matTooltip="ist gleich">=&nbsp;&nbsp;ist gleich</span>

                <!-- notEqual -->
                <span *ngSwitchCase="'notEqual'" matTooltip="ist ungleich">≠&nbsp;&nbsp;ist ungleich</span>

                <!-- empty -->
                <mat-icon *ngSwitchCase="'empty'" matTooltip="ist leer">close</mat-icon>
                <span *ngSwitchCase="'empty'">ist leer</span>

                <!-- notEmpty -->
                <mat-icon *ngSwitchCase="'notEmpty'" matTooltip="ist nicht leer">check</mat-icon>
                <span *ngSwitchCase="'notEmpty'">ist nicht leer</span>

                <!-- Number -->
                <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Number'">
                    <!-- truthy-->
                    <mat-icon *ngSwitchCase="'truthy'" class="outlined" matTooltip="nicht leer und nicht 0">
                        check_circle
                    </mat-icon>
                    <span *ngSwitchCase="'truthy'">nicht leer und nicht 0</span>

                    <!-- falsy-->
                    <mat-icon *ngSwitchCase="'falsy'" matTooltip="leer oder 0">highlight_off</mat-icon>
                    <span *ngSwitchCase="'falsy'">leer oder 0</span>
                </ng-container>

                <!-- Boolean -->
                <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Boolean'">
                    <!-- truthy-->
                    <mat-icon *ngSwitchCase="'truthy'" matTooltip="trifft zu">check</mat-icon>
                    <span *ngSwitchCase="'truthy'">trifft zu</span>

                    <!-- falsy-->
                    <mat-icon *ngSwitchCase="'falsy'" matTooltip="trifft nicht zu">close</mat-icon>
                    <span *ngSwitchCase="'falsy'">trifft nicht zu</span>
                </ng-container>

                <!-- Tristate -->
                <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Tristate'">
                    <!-- truthy-->
                    <mat-icon *ngSwitchCase="'truthy'" matTooltip="trifft zu">check</mat-icon>
                    <span *ngSwitchCase="'truthy'">trifft zu</span>

                    <!-- falsy-->
                    <mat-icon *ngSwitchCase="'falsy'" matTooltip="trifft nicht zu">close</mat-icon>
                    <span *ngSwitchCase="'falsy'">trifft nicht zu</span>

                    <!-- unknown-->
                    <mat-icon
                        *ngSwitchCase="'unknown'"
                        class="condition-operator-icon-question-mark"
                        matTooltip="unbekannt">
                        question_mark
                    </mat-icon>
                    <span *ngSwitchCase="'unknown'">unbekannt</span>
                </ng-container>

                <!-- Includes -->
                <mat-icon *ngSwitchCase="'includes'" matTooltip="enthält">search</mat-icon>
                <span *ngSwitchCase="'includes'">enthält</span>

                <!-- IncludesNot -->
                <mat-icon *ngSwitchCase="'includesNot'" matTooltip="enthält nicht">not_interested</mat-icon>
                <span *ngSwitchCase="'includesNot'">enthält nicht</span>

                <!-- Greater than -->
                <span
                    *ngSwitchCase="'greaterThan'"
                    [matTooltip]="
                        getComparisonValueType(condition.propertyPath) === 'date' ? 'ist nach dem' : 'ist größer als'
                    ">
                    &gt;&nbsp;&nbsp;
                    {{ getComparisonValueType(condition.propertyPath) === 'date' ? 'ist nach dem' : 'ist größer als' }}
                </span>

                <!-- Greater than or equal -->
                <span
                    *ngSwitchCase="'greaterThanOrEqual'"
                    [matTooltip]="
                        getComparisonValueType(condition.propertyPath) === 'date'
                            ? 'ist nach dem oder am'
                            : 'ist größer/gleich'
                    ">
                    ≥&nbsp;&nbsp;{{
                        getComparisonValueType(condition.propertyPath) === 'date'
                            ? 'ist nach dem oder am'
                            : 'ist größer/gleich'
                    }}
                </span>

                <!-- Less than -->
                <span
                    *ngSwitchCase="'lessThan'"
                    [matTooltip]="
                        getComparisonValueType(condition.propertyPath) === 'date' ? 'ist vor dem' : 'ist kleiner als'
                    ">
                    &lt;&nbsp;&nbsp;{{
                        getComparisonValueType(condition.propertyPath) === 'date' ? 'ist vor dem' : 'ist kleiner als'
                    }}
                </span>

                <!-- Less than or equal -->
                <span
                    *ngSwitchCase="'lessThanOrEqual'"
                    [matTooltip]="
                        getComparisonValueType(condition.propertyPath) === 'date'
                            ? 'ist vor dem oder am'
                            : 'ist kleiner/gleich'
                    ">
                    ≤&nbsp;&nbsp;{{
                        getComparisonValueType(condition.propertyPath) === 'date'
                            ? 'ist vor dem oder am'
                            : 'ist kleiner/gleich'
                    }}
                </span>
            </div>

            <mat-menu class="condition-operator-menu" #conditionsOperatorMenu="matMenu">
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'equal')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('equal')">
                    <div class="condition-operator-icon-container">=</div>
                    ist gleich
                </a>
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'notEqual')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('notEqual')">
                    <div class="condition-operator-icon-container">≠</div>
                    ist ungleich
                </a>

                <!--********** Includes Operator **********-->
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'includes')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('includes')">
                    <div class="condition-operator-icon-container">
                        <mat-icon>search</mat-icon>
                    </div>
                    enthält
                </a>
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'includesNot')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('includesNot')">
                    <div class="condition-operator-icon-container">
                        <mat-icon>not_interested</mat-icon>
                    </div>
                    enthält nicht
                </a>

                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'empty')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('empty')">
                    <div class="condition-operator-icon-container">
                        <mat-icon>close</mat-icon>
                    </div>
                    ist leer
                </a>
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'notEmpty')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('notEmpty')">
                    <div class="condition-operator-icon-container">
                        <mat-icon>check</mat-icon>
                    </div>
                    ist nicht leer
                </a>

                <!--********** Truthy/Falsy/Unknown Operators **********-->
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'truthy')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('truthy')">
                    <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Number'">
                        <div class="condition-operator-icon-container outlined">
                            <mat-icon>check_circle</mat-icon>
                        </div>
                        nicht leer oder 0
                    </ng-container>
                    <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Boolean'">
                        <div class="condition-operator-icon-container">
                            <mat-icon>check</mat-icon>
                        </div>
                        trifft zu
                    </ng-container>
                    <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Tristate'">
                        <div class="condition-operator-icon-container">
                            <mat-icon>check</mat-icon>
                        </div>
                        trifft zu
                    </ng-container>
                </a>
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'falsy')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('falsy')">
                    <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Number'">
                        <div class="condition-operator-icon-container">
                            <mat-icon>highlight_off</mat-icon>
                        </div>
                        leer oder 0
                    </ng-container>
                    <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Boolean'">
                        <div class="condition-operator-icon-container">
                            <mat-icon>close</mat-icon>
                        </div>
                        trifft nicht zu
                    </ng-container>
                    <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Tristate'">
                        <div class="condition-operator-icon-container">
                            <mat-icon>close</mat-icon>
                        </div>
                        trifft nicht zu
                    </ng-container>
                </a>
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'unknown')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('unknown')">
                    <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Tristate'">
                        <div class="condition-operator-icon-container">
                            <mat-icon class="condition-operator-icon-question-mark">question_mark</mat-icon>
                        </div>
                        unbekannt
                    </ng-container>
                </a>

                <!--********** Greater & Less than **********-->
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'greaterThan')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('greaterThan')">
                    <div class="condition-operator-icon-container">&gt;</div>
                    {{ getComparisonValueType(condition.propertyPath) === 'date' ? 'ist nach dem' : 'ist größer als' }}
                </a>
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'greaterThanOrEqual')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('greaterThanOrEqual')">
                    <div class="condition-operator-icon-container">≥</div>
                    {{
                        getComparisonValueType(condition.propertyPath) === 'date'
                            ? 'ist nach dem oder am'
                            : 'ist größer/gleich'
                    }}
                </a>
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'lessThan')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('lessThan')">
                    <div class="condition-operator-icon-container">&lt;</div>
                    {{ getComparisonValueType(condition.propertyPath) === 'date' ? 'ist vor dem' : 'ist kleiner als' }}
                </a>
                <a
                    mat-menu-item
                    class="condition-operator-menu-item"
                    (click)="setOperator(condition, 'lessThanOrEqual')"
                    *ngIf="getOperatorsForProperty(condition.propertyPath).includes('lessThanOrEqual')">
                    <div class="condition-operator-icon-container">≤</div>
                    {{
                        getComparisonValueType(condition.propertyPath) === 'date'
                            ? 'ist vor dem oder am'
                            : 'ist kleiner/gleich'
                    }}
                </a>
            </mat-menu>
        </div>

        <!--********** Comparison Value **********-->
        <div
            class="condition-comparison-value-container"
            [ngSwitch]="getComparisonValueType(condition.propertyPath)"
            [ngClass]="{ hidden: isComparisonValueHidden(condition) }">
            <!--********** The "" is a placeholder as long as the propertyPath is not selected **********-->
            <mat-form-field [floatLabel]="'never'" class="light-mode mat-block" *ngSwitchCase="null">
                <input
                    #comparisonValueInput
                    matInput
                    placeholder="Vergleichswert"
                    type="text"
                    disabled
                    [ngModel]="condition.comparisonValue" />
            </mat-form-field>

            <!-- Text/ Boolean input -->
            <mat-form-field [floatLabel]="'never'" class="light-mode mat-block" *ngSwitchCase="'text' || 'boolean'">
                <input
                    #comparisonValueInput
                    matInput
                    placeholder="Vergleichswert"
                    type="text"
                    [disabled]="getComparisonValueType(condition.propertyPath) === 'boolean'"
                    [(ngModel)]="condition.comparisonValue"
                    [matAutocomplete]="comparisonValueAutocomplete" />
                <mat-hint class="warning" *ngIf="!isValidComparisonValue(condition)">
                    Ungültiger Vergleichswert
                </mat-hint>
                <mat-autocomplete #comparisonValueAutocomplete>
                    <mat-option
                        *ngFor="let comparisonValue of filterComparisonValueSuggestions(condition)"
                        [value]="comparisonValue"
                        [matTooltip]="comparisonValue"
                        (mousedown)="$event.preventDefault()">
                        {{ comparisonValue }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <!-- Number input -->
            <mat-form-field [floatLabel]="'never'" class="light-mode mat-block" *ngSwitchCase="'number'">
                <!--********** Use type "text" instead of "number" to allow placeholders. **********-->
                <input
                    #comparisonValueInput
                    matInput
                    placeholder="Vergleichswert"
                    type="text"
                    [disabled]="getComparisonValueType(condition.propertyPath) === 'boolean'"
                    [(ngModel)]="condition.comparisonValue" />
            </mat-form-field>

            <!-- Time input -->
            <mat-form-field [floatLabel]="'never'" class="light-mode mat-block" *ngSwitchCase="'time'">
                <input
                    #comparisonValueInput
                    matInput
                    placeholder="Vergleichswert"
                    type="time"
                    [disabled]="getComparisonValueType(condition.propertyPath) === 'boolean'"
                    [(ngModel)]="condition.comparisonValue" />
            </mat-form-field>

            <!-- Date input -->
            <date-input
                #comparisonValueDateInput
                *ngSwitchCase="'date'"
                [floatPlaceholder]="'never'"
                placeholder="Vergleichswert"
                [(date)]="condition.comparisonValue"
                [lightMode]="true"></date-input>
        </div>

        <!-- Settings button -->
        <div
            class="condition-group-settings-button"
            matTooltip="Einstellungen"
            [matMenuTriggerFor]="conditionGroupSettingsMenu">
            <mat-icon>more_vert</mat-icon>
        </div>

        <mat-menu class="condition-group-settings-menu" #conditionGroupSettingsMenu="matMenu">
            <!-- Duplicate condition -->
            <a mat-menu-item class="menu-item-with-icon" (click)="duplicateCondition.emit(condition)">
                <mat-icon>content_copy</mat-icon>
                Kopieren
            </a>

            <!-- Turn into condition group -->
            <a
                *ngIf="conditionNestingDepth < 2"
                mat-menu-item
                class="menu-item-with-icon"
                (click)="turnIntoConditionGroup.emit(condition)">
                <mat-icon>widgets</mat-icon>
                In Bedingungsgruppe umwandeln
            </a>

            <!-- Delete condition -->
            <a mat-menu-item class="menu-item-with-icon" (click)="removeCondition.emit(condition)">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>

        <ng-content select="[drag-handle-condition]"></ng-content>
    </div>
</ng-container>

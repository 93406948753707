import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    GoogleMapsPlaceAddressResponse,
    GoogleMapsPredictionResponse,
    GoogleMapsSessionToken,
} from '@autoixpert/external-apis/google-maps/places-response';
import { AddressAutocompletion } from '@autoixpert/models/address-autocompletion';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { NetworkStatusService } from '../network-status.service';

@Injectable({
    providedIn: 'root',
})
export class AddressAutocompletionService {
    constructor(
        private networkStatusService: NetworkStatusService,
        private httpClient: HttpClient,
    ) {}

    private apiPath = '/api/v0/addressAutocompletion';

    public async getAddressPredictions(userInput: string): Promise<AddressAutocompletion[]> {
        const queryParameters = new URLSearchParams();
        queryParameters.append('search', userInput);

        return (
            (await this.httpClient
                .get<AddressAutocompletion[]>(`${this.apiPath}?${queryParameters.toString()}`)
                .toPromise()) ?? []
        );
    }

    public async getOrganizationPredictions({
        userInput,
        sessionToken,
    }: {
        userInput: string;
        sessionToken: GoogleMapsSessionToken;
    }): Promise<GoogleMapsPredictionResponse> {
        const queryParameters = new URLSearchParams();
        queryParameters.append('search', userInput);
        if (sessionToken) {
            queryParameters.append('sessionToken', sessionToken);
        }
        return await this.httpClient
            .get<GoogleMapsPredictionResponse>(`${this.apiPath}/organization?${queryParameters.toString()}`)
            .toPromise();
    }

    public async getOrganizationDetails({
        placeId,
        sessionToken,
    }: {
        placeId: string;
        sessionToken: GoogleMapsSessionToken;
    }): Promise<GoogleMapsPlaceAddressResponse> {
        const queryParameters = new URLSearchParams();
        queryParameters.append('placeId', placeId);
        if (sessionToken) {
            queryParameters.append('sessionToken', sessionToken);
        }
        return await this.httpClient
            .get<GoogleMapsPlaceAddressResponse>(`${this.apiPath}/organizationDetails?${queryParameters.toString()}`)
            .toPromise();
    }

    /**
     * Get a city by its ZIP code.
     * Sometimes, multiple cities may have the same ZIP code, e.g. in 19372.
     *
     * Returns null when offline.
     */
    public async getCityFromZip(zip: string, street?: string): Promise<string> {
        // Since ZIP mapping is an online-only feature, block if offline. Don't notify the user.
        if (!this.networkStatusService.isOnline()) {
            return null;
        }

        if (!zip) {
            throw new AxError({
                code: 'NO_ZIP_CODE_PROVIDED',
                message: 'Please provide a zip code for the zip service.',
            });
        }
        if (zip.length < 5) {
            throw new AxError({
                code: 'ZIP_SHORTER_THAN_5_DIGITS',
                message: 'The ZIP must consist of exactly 5 digits.',
            });
        }

        const response = await this.httpClient
            .get<string[]>(`${this.apiPath}/zip`, {
                params: {
                    zip: zip,
                    street: street,
                },
            })
            .toPromise();

        return response?.length > 0 ? response[0] : null;
    }
}

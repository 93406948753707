import { Report } from '@autoixpert/models/reports/report';

export function getValuationPriceLabelTooltip(vehicleValueType: Report['valuation']['vehicleValueType']): string {
    switch (vehicleValueType) {
        case 'dealerPurchase':
            return 'Händlereinkaufspreis (brutto)\n\nFalls der Fahrzeughalter zum Vorsteuerabzug berechtigt ist, werden automatisch Nettopreise + MwSt. abgedruckt.';
        case 'dealerSales':
            return 'Händlerverkaufspreis (brutto)\n\nFalls der Fahrzeughalter zum Vorsteuerabzug berechtigt ist, werden automatisch Nettopreise + MwSt. abgedruckt.';
        case 'presentValue':
            return 'Aktueller Wert des Fahrzeugs (brutto)\n\nFalls der Fahrzeughalter zum Vorsteuerabzug berechtigt ist, werden automatisch Nettopreise + MwSt. abgedruckt.';
        case 'replacementValue':
            return 'Für privat gehandelte Fahrzeuge gleich dem Händler-EK. Für gewerblich gehandelte Fahrzeuge der Händler-VK. Jeweils brutto.';
    }
}

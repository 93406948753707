import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { isEqual } from 'lodash-es';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { removeFromArrayById } from '@autoixpert/lib/arrays/remove-from-array-by-id';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import { toIsoDate, todayIso } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { calculateRankForTask } from '@autoixpert/lib/tasks/calculate-rank-for-task';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { Label } from '@autoixpert/models/labels/label';
import { LabelConfig } from '@autoixpert/models/labels/label-config';
import { Task } from '@autoixpert/models/tasks/task';
import { TaskListQuickFilterType } from '@autoixpert/models/user/preferences/user-preferences';
import { WorkdaySettings } from '@autoixpert/models/user/preferences/workweek-settings';
import { User } from '@autoixpert/models/user/user';
import { taskListRankMax, taskListRankMin } from '@autoixpert/static-data/tasks/task-list-rank-limits';
import { slideInAndOutVertically } from 'src/app/shared/animations/slide-in-and-out-vertical.animation';
import { filterTasksInMemory } from 'src/app/shared/libraries/tasks/filter-tasks-in-memory';
import { getTaskFilterQuery } from 'src/app/shared/libraries/tasks/get-task-filter-query';
import { ApiErrorService } from 'src/app/shared/services/api-error.service';
import { LabelConfigService } from 'src/app/shared/services/label-config.service';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { ScreenTitleService } from 'src/app/shared/services/screen-title.service';
import { TaskService } from 'src/app/shared/services/task.service';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';
import { UserService } from 'src/app/shared/services/user.service';
import { FeathersQuery } from 'src/app/shared/types/feathers-query';
import { runChildAnimations } from '../../shared/animations/run-child-animations.animation';
import { slideOutSide } from '../../shared/animations/slide-out-side.animation';
import { TaskTitleComponent } from '../../shared/components/tasks/task-title/task-title.component';

@Component({
    selector: 'task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.scss'],
    animations: [slideInAndOutVertically(), slideOutSide(300, 600), runChildAnimations()],
})
export class TaskListComponent implements OnInit, OnDestroy {
    constructor(
        private screenTitleService: ScreenTitleService,
        private loggedInUserService: LoggedInUserService,
        private taskService: TaskService,
        private apiErrorService: ApiErrorService,
        private userService: UserService,
        private labelConfigService: LabelConfigService,
        public userPreferences: UserPreferencesService,
    ) {}

    public user: User;

    public tasks: Task[] = [];
    public filteredTasks: Task[] = [];

    // Flag that disables the slide in animation of task rows
    // This is necessary to prevent all rows from sliding in when switching
    // to the task page for the first time (or when loading more tasks).
    protected disableRowAnimation = false;

    // Pagination
    private lastTaskPaginationTokenFromServer: string;
    private numberOfLoadedTasks = 0;
    private loadMoreTasks$$ = new Subject<LoadMoreTasksPayload>();
    public isLoadMoreTasksPending = false;
    public allTasksLoadedWithCurrentFilters = false;

    // Search
    public searchTerm: string = '';
    public searchTerm$: Subject<string> = new Subject<string>();
    private atlasSearchMatches = new Set<Task['_id']>();

    // Select task
    public selectedTask: Task;

    // Detail view open
    public isDetailViewOpen = false;

    // Focus input
    @ViewChildren(TaskTitleComponent) taskTitleComponents;

    // Add a new task as a placeholder
    // As soon as the user adds data, the task will be created
    public newTask: Task = undefined;

    // Helpers for UI
    public assessors: User[] = [];
    public labelConfigs: LabelConfig[] = [];

    private subscriptions: Subscription[] = [];

    public daysForCapacity: { date: IsoDate; workdaySettings: WorkdaySettings }[] = [];
    public isSettingsDialogOpen = false;

    async ngOnInit() {
        // Update the user in case it was updated in a different tab.
        // this.subscriptions.push(this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)));
        this.user = await this.loggedInUserService.getUser();
        this.subscribeToLoadMoreTasks();
        this.setupSearch();

        this.initialLoadTasks();

        this.screenTitleService.setScreenTitle({ screenTitle: 'Meine Aufgaben' });

        // Entries for the filter
        this.loadAssessorsFromTeam();
        this.loadLabelConfigs();

        // Capacity View
        this.initializeDaysForCapacity();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    private initializeDaysForCapacity() {
        const today = moment();

        // Get the capacity for today (even if it may be 0, e.g. on weekends)
        const dayToday = today.locale('en').format('dddd').toLowerCase();
        const workdaySettingsToday: WorkdaySettings = this.userPreferences.taskList_workweekSettings[dayToday] ?? {
            active: true,
            weekday: dayToday,
            workingMinutes: 480,
        };

        this.daysForCapacity = [{ date: toIsoDate(today), workdaySettings: workdaySettingsToday }];

        // Get the next two workdays (capacity > 0)
        // Limit to the next seven days to avoid infinite loops
        let numberOfDaysAhead = 0;
        while (this.daysForCapacity.length < 3 && numberOfDaysAhead < 7) {
            numberOfDaysAhead++;
            const nextDay = today.add(1, 'day');
            const nextDayDay = nextDay.locale('en').format('dddd').toLowerCase();
            const workdaySettingsNextDay = this.userPreferences.taskList_workweekSettings[nextDayDay];
            if (workdaySettingsNextDay?.active) {
                this.daysForCapacity.push({
                    date: toIsoDate(nextDay),
                    workdaySettings: workdaySettingsNextDay,
                });
            }
        }
    }

    //*****************************************************************************
    //  Load Tasks
    //****************************************************************************/
    public async resetLoadHistory(): Promise<void> {
        this.filteredTasks = [];
        this.allTasksLoadedWithCurrentFilters = false;

        // When searching, don't skip any records.
        this.lastTaskPaginationTokenFromServer = null;
        this.numberOfLoadedTasks = 0;

        this.atlasSearchMatches.clear();
        return this.triggerLoadMoreTasks();
    }

    public initialLoadTasks() {
        this.loadMoreTasks$$.next({
            filterAndSortParams: this.getFilterAndSortQuery(),
            numberOfItemsToLoad: 30,
        });
    }

    public triggerLoadMoreTasks() {
        this.loadMoreTasks$$.next({
            searchAfterNumberOfElements: this.numberOfLoadedTasks,
            searchAfterPaginationToken: this.lastTaskPaginationTokenFromServer,
            filterAndSortParams: this.getFilterAndSortQuery(),
            searchTerm: this.searchTerm,
        });
    }

    private subscribeToLoadMoreTasks() {
        const loadMoreTasksSubscription = this.loadMoreTasks$$
            .pipe(
                // Only trigger a load if the parameters have changed.
                // This is required since the scroll observer may trigger multiple times with the same parameters.
                // It is important to have the filters and sort params in the payload to cancel the request if the user changes the filter or sort.
                // Since distinctUntilChanged may hold a reference to objects or arrays, we need to deep copy the payload in order to compare the values.
                map((payload) => JSON.parse(JSON.stringify(payload))),
                distinctUntilChanged(isEqual),
                switchMap(async (payload) => {
                    const numberOfTasksToLoad = payload.numberOfItemsToLoad || 15;
                    const isInitialLoad = !this.numberOfLoadedTasks;
                    let loadedTasks: Task[] = [];
                    this.isLoadMoreTasksPending = true;
                    this.disableRowAnimation = true;

                    /**
                     * Load tasks from server (if online) or from IndexedDB (if offline).
                     */
                    try {
                        const { records, lastPaginationToken } = await this.taskService.getTasksFromServerOrIndexedDB({
                            searchTerm: payload.searchTerm,
                            searchAfterPaginationToken: payload.searchAfterPaginationToken,
                            skip: payload.searchAfterNumberOfElements || 0,
                            limit: numberOfTasksToLoad,
                            query: payload.filterAndSortParams,
                        });
                        loadedTasks = records;

                        // Update pagination of component
                        this.lastTaskPaginationTokenFromServer = lastPaginationToken;
                        this.numberOfLoadedTasks += loadedTasks.length;
                    } catch (error) {
                        this.apiErrorService.handleAndRethrow({
                            axError: error,
                            handlers: {},
                            defaultHandler: {
                                title: 'Aufgaben nicht geladen',
                                body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                            },
                        });
                    }
                    this.isLoadMoreTasksPending = false;
                    return {
                        loadedTasks,
                        isInitialLoad,
                        numberOfTasksToLoad,
                        searchTerm: payload.searchTerm,
                    };
                }),
            )
            .subscribe(({ loadedTasks, isInitialLoad, numberOfTasksToLoad, searchTerm }) => {
                // If there are less tasks than the limit, we have reached the end of the list.
                this.allTasksLoadedWithCurrentFilters = loadedTasks.length < numberOfTasksToLoad;

                if (loadedTasks.length === 0 && !searchTerm) {
                    this.filterAndSortInMemory();
                    return;
                }

                // If the request was for a search term, add the contactPerson ids to the search matches.
                if (searchTerm) {
                    loadedTasks.forEach((contactPerson) => {
                        this.atlasSearchMatches.add(contactPerson._id);
                    });
                }

                // If this is the first load, update the in-memory cache with the loaded tasks.
                if (isInitialLoad) {
                    this.tasks = loadedTasks;
                } else {
                    // Append all new tasks to the list.
                    const contactPeopleWithoutDuplicates: Task[] = this.filterOutExistingTasks(loadedTasks);
                    if (contactPeopleWithoutDuplicates.length > 0) {
                        this.tasks.push(...contactPeopleWithoutDuplicates);
                    }
                }
                this.filterAndSortInMemory();
                setTimeout(() => {
                    // Wait for the change detection to update the template (show rows) before enabling
                    // the animation again. Otherwise the initial load will slide in all rows at once.
                    this.disableRowAnimation = false;
                }, 0);
            });

        this.subscriptions.push(loadMoreTasksSubscription);
    }

    private filterOutExistingTasks(tasks: Task[]): Task[] {
        const existingTasks = [...this.tasks];

        return tasks.filter((task) => {
            // Only include new records without a matching ID
            return !existingTasks.find((existingContactPerson) => existingContactPerson._id === task._id);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter and Sort
    //****************************************************************************/

    public selectQuickFilter(filterType: TaskListQuickFilterType) {
        this.userPreferences.taskList_quickFilter = filterType;
        this.applyFilterAndSort();
    }

    public selectSortBy(sortBy: 'rank' | 'dueDate') {
        this.userPreferences.taskList_sortBy = sortBy;
        this.applyFilterAndSort();
    }

    public toggleSortDirection() {
        this.userPreferences.taskList_sortDescending = !this.userPreferences.taskList_sortDescending;
        this.applyFilterAndSort();
    }

    public applyFilterAndSort() {
        this.resetLoadHistory();
        this.initialLoadTasks();
    }

    /**
     * This function handles the completion of a task.
     *
     * If completed tasks are displayed in the list, there is nothing special to do since a user can undo the completion.
     * If completed tasks are not displayed, the task is removed from the list after a short delay.
     * The delay and an additional toast message with undo option allow the user to undo the completion.
     */
    public async handleCompletedTask(task: Task) {
        this.saveTask(task);

        // If the completed task remains in the list, do not display a toast or remove it.
        if (this.userPreferences.taskList_showCompletedTasks) {
            return;
        }

        // // Show a confirmation toast with an undo option.
        // const confirmationToast = this.toastService.info(
        //     'Aufgabe erledigt',
        //     `Die Aufgabe "${task.title}" wurde als erledigt markiert. Klicke, um sie wieder als offen zu markieren.`,
        // );
        // confirmationToast.click.subscribe(() => {
        //     task.isCompleted = false;
        //     task.completedAt = null;
        //     this.saveTask(task);
        //     this.filterAndSortInMemory();
        // });

        // Wait for a second before removing the task from the list.
        await new Promise((resolve) => {
            setTimeout(resolve, 1000);
        });

        // If the task is still completed, remove it from the list.
        if (task.isCompleted) {
            removeFromArrayById(task._id, this.filteredTasks);
            this.filterAndSortInMemory();
        }
    }

    private getFilterAndSortQuery(): FeathersQuery {
        // Filter
        const query = getTaskFilterQuery(this.userPreferences, this.user);

        // Limit to completed tasks
        if (!this.userPreferences.taskList_showCompletedTasks) {
            query.isCompleted = false;
        }

        // Sort
        switch (this.userPreferences.taskList_sortBy) {
            case 'rank':
                query.$sort = { ...query.$sort, rank: 1 };
                break;
            case 'dueDate':
                const sortDirection = this.userPreferences.taskList_sortDescending ? -1 : 1;
                query.$sort = { ...query.$sort, dueDate: sortDirection, dueTime: sortDirection };
                break;
        }

        return query;
    }

    filterAndSortInMemory() {
        // Filter tasks
        this.filteredTasks = filterTasksInMemory(this.tasks, this.userPreferences, this.user);

        if (!this.userPreferences.taskList_showCompletedTasks) {
            this.filteredTasks = this.filteredTasks.filter((task) => !task.isCompleted);
        }

        // Sort tasks
        switch (this.userPreferences.taskList_sortBy) {
            case 'dueDate':
                const sortDirection = this.userPreferences.taskList_sortDescending ? -1 : 1;
                this.filteredTasks = this.filteredTasks.sort((a, b) => {
                    if (a.dueDate === b.dueDate) {
                        const dueTimeA = a.dueTime || '00:00';
                        const dueTimeB = b.dueTime || '00:00';
                        if (dueTimeA === dueTimeB) {
                            return moment(a.createdAt).diff(b.createdAt) * sortDirection;
                        }
                        return dueTimeA < dueTimeB ? -1 * sortDirection : 1 * sortDirection;
                    }
                    return moment(a.dueDate).diff(moment(b.dueDate)) * sortDirection;
                });
                break;

            case 'rank':
                /**
                 * Append tasks without rank to the beginning of the list.
                 */
                const tasksWithoutRank = this.filteredTasks.filter((task) => !task.rank);
                if (tasksWithoutRank.length) {
                    tasksWithoutRank.reverse().forEach((taskWithoutRank) => {
                        taskWithoutRank.rank = calculateRankForTask({
                            position: 'first',
                            sortedTasks: this.filteredTasks,
                        });
                        this.saveTask(taskWithoutRank);
                    });
                    console.log(`Calculated rank for ${tasksWithoutRank.length} tasks without rank.`);
                }

                this.filteredTasks = this.filteredTasks.sort((a, b) => a.rank - b.rank);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter and Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Search
    //****************************************************************************/

    public updateSearchTerm(): void {
        this.searchTerm$.next(this.searchTerm);
    }

    /**
     * Every time the search term changes, trigger a server search.
     *
     * Subscribe to the stream of search terms and trigger a search on the server.
     * Searches are only performed one second after the user stopped typing, and if the
     * search term is three letters or longer.
     */
    public setupSearch(): void {
        const searchServerSubscription = this.searchTerm$
            .pipe(
                tap((searchTerm) => {
                    if (!searchTerm) {
                        this.resetLoadHistory();
                    }
                }),
                // Only search for more than three characters.
                filter((searchTerm) => {
                    if (!searchTerm || typeof searchTerm !== 'string') return;

                    // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
                    const searchTermParts = searchTerm
                        .trim()
                        .split(' ')
                        .filter((searchTerm) => !!searchTerm.trim());
                    return searchTermParts.some((searchTermPart) => searchTermPart.length >= 3);
                }),
                debounceTime(250),
            )
            .subscribe({
                next: () => {
                    this.resetLoadHistory();
                },
            });
        this.subscriptions.push(searchServerSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Search
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Select Task
    //****************************************************************************/
    /**
     * Selects a task an focuses the title input.
     */
    selectTask(task: Task, event?: MouseEvent) {
        if (this.selectedTask?._id === task._id) {
            return;
        }
        this.selectedTask = task;
        event?.stopPropagation();

        const indexOfSelectedTask = this.filteredTasks.findIndex((filteredTask) => filteredTask._id === task._id);
        if (indexOfSelectedTask >= 0) {
            // Do not fail if the task title component is not yet rendered, e.g. when adding a new Task
            this.taskTitleComponents.toArray()[indexOfSelectedTask]?.focus();
        }
    }

    openDetailView(task: Task) {
        this.selectTask(task);
        this.isDetailViewOpen = true;
    }

    toggleDetailView() {
        if (this.isDetailViewOpen) {
            this.isDetailViewOpen = false;
            return;
        }

        this.openDetailView(this.selectedTask ?? this.filteredTasks[0]);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select Task
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter: Assessor
    //****************************************************************************/

    private loadAssessorsFromTeam() {
        this.assessors = this.userService
            .getAllTeamMembersFromCache()
            .filter((teamMember) => teamMember.active && teamMember.isAssessor);

        /**
         * If not a single assessor could be found, the service must still be waiting for the server response. Try again later.
         */
        if (!this.assessors.length) {
            window.setTimeout(() => this.loadAssessorsFromTeam(), 500);
            return;
        }
    }

    /**
     * Shall the task list be filtered by a certain assignee?
     * @param assessorId
     */
    public toggleAssigneeForFilter(assessorId: User['_id']) {
        toggleValueInArray(assessorId, this.userPreferences.taskList_usersForFilter);
        // Assign the array to itself to trigger the save mechanism.
        this.userPreferences.taskList_usersForFilter = [...this.userPreferences.taskList_usersForFilter];

        // Select filter type and trigger search.
        if (this.userPreferences.taskList_usersForFilter.length > 0) {
            // Loading the search results anew must be performed on every label change.
            this.selectQuickFilter('assignedUser');
        } else {
            this.selectQuickFilter('none');
        }
    }

    public filterOnlyMyTasks() {
        this.userPreferences.taskList_usersForFilter = [this.user._id];
    }

    public getAssessorFullName(userId: string): string {
        const user: User = this.assessors.find((assessor) => assessor._id === userId);

        if (!user) {
            return '';
        }

        return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    }

    public isAssessorInFilter(assessorId: User['_id']): boolean {
        return this.userPreferences.taskList_usersForFilter.includes(assessorId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter: Assessor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter: Labels
    //****************************************************************************/
    protected async loadLabelConfigs() {
        try {
            this.labelConfigs = await this.labelConfigService.find({ labelGroup: 'task' }).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Label-Konfigs konnten nicht geladen werden`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        this.labelConfigs.sort(sortByProperty(['dragOrderPosition', 'name']));
    }

    protected getLabelConfigByLabelName(labelName: Label['name']): LabelConfig {
        return this.labelConfigs.find((labelConfig) => labelConfig.name === labelName);
    }

    protected toggleSelectLabelConfigForFilter(labelName: Label['name']) {
        toggleValueInArray(labelName, this.userPreferences.taskList_labelsForFilter);

        // Assign the array to itself to trigger the save mechanism.
        this.userPreferences.taskList_labelsForFilter = [...this.userPreferences.taskList_labelsForFilter];

        // Select filter type and trigger search.
        if (this.userPreferences.taskList_labelsForFilter.length) {
            // Loading the search results anew must be performed on every label change.
            this.selectQuickFilter('labels');
        } else {
            this.selectQuickFilter('none');
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter: Labels
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Add Task
    //****************************************************************************/
    /**
     * Adds a new task to the list.
     * A task is added to top or bottom of after an existing task.
     */
    async addNewTask(position: 'first' | 'last' | 'before' | 'after', referenceTask?: Task) {
        this.newTask = new Task({
            assigneeId: this.user._id,
        });

        // ============ Sort by due date ============
        // If a reference task is given, we use the same due date.
        // Otherwise due date is today.
        if (this.userPreferences.taskList_sortBy === 'dueDate') {
            this.newTask.dueDate = referenceTask?.dueDate ?? todayIso();
        }

        // ============ Sort by rank ============
        // Insert the new task on a specific position:
        // first or last in the current list or before or after a reference task.
        else if (this.userPreferences.taskList_sortBy === 'rank') {
            this.newTask.rank = calculateRankForTask({ position, sortedTasks: this.filteredTasks, referenceTask });
        }

        this.tasks.push(this.newTask);
        this.filterAndSortInMemory();
        this.selectTask(this.newTask);

        await this.taskService.create(this.newTask);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add Task
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Update Task
    //****************************************************************************/
    async saveTask(task: Task) {
        this.taskService.put(task);
    }

    async deleteTask(taskToDelete: Task) {
        // Optimistic UI update on the list
        const indexOfTask = this.tasks.findIndex((task) => task._id === taskToDelete._id);
        if (indexOfTask !== -1) {
            this.tasks.splice(indexOfTask, 1);
        }

        // Select the next task
        const indexInFilteredArray = this.filteredTasks.indexOf(taskToDelete);
        const nextTask: Task = this.filteredTasks.at(indexInFilteredArray + 1);
        const previousTask: Task = this.filteredTasks.at(indexInFilteredArray - 1);
        if (nextTask) {
            this.selectTask(nextTask);
        }
        // If the next is not possible, select the one above.
        else if (previousTask) {
            this.selectTask(previousTask);
        }

        this.filterAndSortInMemory();

        // Optimistic UI update on the new task
        if (this.newTask?._id === taskToDelete._id) {
            this.newTask = undefined;
        }

        // Delete task on the server
        try {
            await this.taskService.delete(taskToDelete._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Aufgabe nicht gelöscht',
                    body: "Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    assignUser(task: Task, user: User) {
        task.assigneeId = user?._id;
        this.saveTask(task);
    }

    handleEstimatedDurationChange(task: Task, estimatedDuration: number) {
        task.estimatedDuration = estimatedDuration;
        this.saveTask(task);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Update Task
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sort Tasks
    //****************************************************************************/
    onTaskDropped(event: CdkDragDrop<any>) {
        if (event.previousIndex === event.currentIndex) {
            return;
        }

        const draggedTask = this.filteredTasks[event.previousIndex];
        let elementAbove: Task;
        let elementBelow: Task;

        // Drag downstairs -> index has not to be shifted since the element is removed before the new index
        if (event.currentIndex > event.previousIndex) {
            elementAbove = this.filteredTasks[event.currentIndex];
            elementBelow = this.filteredTasks[event.currentIndex + 1];
        }
        // Drag upstairs -> index has to be shifted since the element is removed after the new index
        else {
            elementAbove = this.filteredTasks[event.currentIndex - 1];
            elementBelow = this.filteredTasks[event.currentIndex];
        }

        // If an element is dragged to the top or bottom, the rank is calculated from the edge
        const rankAbove = elementAbove?.rank || taskListRankMin;
        const rankBelow = elementBelow?.rank || taskListRankMax;

        draggedTask.rank = (rankAbove + rankBelow) / 2;

        this.filterAndSortInMemory();
        this.saveTask(draggedTask);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sort Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation with keyboard
    //****************************************************************************/

    public navigateToTask(direction: 'prev' | 'next') {
        const indexOfSelectedTask = this.filteredTasks.findIndex((task) => task._id === this.selectedTask._id);
        if (indexOfSelectedTask === -1) {
            return;
        }

        if (direction === 'prev' && indexOfSelectedTask > 0) {
            this.selectTask(this.filteredTasks[indexOfSelectedTask - 1]);
        }

        if (direction === 'next' && indexOfSelectedTask < this.filteredTasks.length - 1) {
            this.selectTask(this.filteredTasks[indexOfSelectedTask + 1]);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation with keyboard
    /////////////////////////////////////////////////////////////////////////////*/

    protected trackById = trackById;
}

/**
 * Payload for the loadMoreContacts$ observable.
 */
type LoadMoreTasksPayload = {
    searchTerm?: string;
    filterAndSortParams: FeathersQuery;
    numberOfItemsToLoad?: number;
    searchAfterPaginationToken?: string;
    searchAfterNumberOfElements?: number;
};

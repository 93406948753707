import { ServerError } from '../../models/errors/ax-error';
import { OfficeLocation } from '../../models/teams/office-location';
import { User } from '../../models/user/user';

export function getOfficeLocation({
    officeLocations,
    officeLocationId,
    defaultOfficeLocationId,
}: {
    officeLocations: OfficeLocation[];
    officeLocationId: OfficeLocation['_id'];
    defaultOfficeLocationId: User['defaultOfficeLocationId'];
}): OfficeLocation {
    let officeLocation = officeLocations.find((officeLocation) => officeLocation._id === officeLocationId);
    if (!officeLocation) {
        if (!defaultOfficeLocationId) {
            throw new ServerError({
                code: 'DEFAULT_OFFICE_LOCATION_ID_MISSING',
                message: 'The user object passed does not have a defaultOfficeLocationId.',
                data: {
                    defaultOfficeLocationId,
                    officeLocations,
                    officeLocationId,
                },
            });
        }
        officeLocation = officeLocations.find((officeLocation) => officeLocation._id === defaultOfficeLocationId);
    }

    return officeLocation;
}

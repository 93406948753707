<!--============================================-->
<!-- Heading -->
<!--============================================-->
<section id="heading-section">
    <div id="heading-container">
        <h3 class="no-margin">Farbe wählen</h3>
        <mat-icon
            id="submenu-icon"
            class="toolbar-icon medium-icon"
            *ngIf="!isEditModeActive"
            [matMenuTriggerFor]="headerSubmenu">
            more_vert
        </mat-icon>
        <mat-menu #headerSubmenu>
            <a mat-menu-item class="menu-item-with-icon" (click)="enterEditMode()">
                <mat-icon>edit</mat-icon>
                Farben bearbeiten
            </a>
        </mat-menu>
    </div>
</section>
<!--============================================-->
<!-- END Heading -->
<!--============================================-->

<!--============================================-->
<!-- Colors -->
<!--============================================-->
<section id="colors-section">
    <div id="colors-grid">
        <!--suppress CssUnknownProperty -->
        <div
            class="color-patch"
            *ngFor="let colorPatch of team.preferences.colorPickerPatches"
            [style.--patchColor]="colorPatch.hexCode"
            [ngClass]="{ 'no-color-assigned': !colorPatch.hexCode }"
            (click)="handleColorPatchClick({ colorPatch, colorInputElement })"
            [clickMyselfOnInit]="!viewInitializationInProgress">
            <!--********** Color Input **********-->
            <input type="color" [(ngModel)]="colorPatch.hexCode" (change)="saveTeam()" #colorInputElement />

            <!--********** No Color Indicator **********-->
            <div class="no-color-indicator" *ngIf="!colorPatch.hexCode" matTooltip="Noch keine Farbe definiert">?</div>

            <!--********** Delete Icon **********-->
            <div class="delete-icon-container" *ngIf="isEditModeActive" (click)="deleteColor(colorPatch)">
                <mat-icon class="delete-icon">close</mat-icon>
            </div>
        </div>

        <!--********** Add new Color **********-->
        <div id="new-color-patch" (click)="addColor()" matTooltip="Neue Farbe">
            <mat-icon>add</mat-icon>
        </div>
    </div>

    <div class="label margin-top-20 text-align-center" *ngIf="isEditModeActive">
        Farben gelten für
        <br />
        das gesamte Team.
    </div>
</section>
<!--============================================-->
<!-- END Colors -->
<!--============================================-->

<div class="flex-center-content margin-bottom-20" *ngIf="isEditModeActive">
    <button (click)="leaveEditMode()">Bearbeitung beenden</button>
</div>

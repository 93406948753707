import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import Muuri, { GridEvents, GridOptions, Item } from 'muuri';
import { Subject, Subscription, merge } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { isTouchOnly } from '../../libraries/is-small-screen';
import { PhotoMuuriGridService } from '../../services/photo-muuri-grid.service';
import { MuuriMoveEvent } from './events';
import { MuuriEventListenerName } from './index';

/**
 * This directive created the Muuri grid.
 * As soon as items are added to the DOM, those child items with the directive
 * muuriGridItem add themselves to the grid by calling this directive's addItem method.
 */
@Directive({
    selector: '[muuriGrid]',
})
export class MuuriGridDirective implements OnInit, OnDestroy {
    constructor(
        private elementRef: ElementRef,
        private photoMuuriGridService: PhotoMuuriGridService,
    ) {}

    @Input() dragDisabled: boolean = false;

    @Output() layoutEnd: EventEmitter<void> = new EventEmitter<void>();
    @Output() dragStart: EventEmitter<void> = new EventEmitter<void>();
    @Output() dragEnd: EventEmitter<void> = new EventEmitter<void>();
    @Output() positionChange: EventEmitter<PositionChangeEvent> = new EventEmitter<PositionChangeEvent>();

    private layoutConfig: Partial<GridOptions> = {
        items: [],
        layoutOnInit: false, // Only begin with layout after items added themselves
        layoutEasing: 'ease',
        layoutDuration: 300,
        dragEnabled: true,
        layout: {
            fillGaps: true,
            // rounding : false
        },
        // dragStartPredicate : {
        //     distance : 5
        // },
        /**
         * May an item start dragging?
         *
         * Will be called until true/false has been returned once.
         */
        dragStartPredicate: (item, event) => {
            // Muuri Docs: "If this is final event in the drag process, let's prepare the predicate
            // for the next round (do some resetting/teardown). The default predicate
            // always needs to be called during the final event if there's a chance it
            // has been triggered during the drag process because it does some necessary
            // state resetting."
            if (event.isFinal) {
                Muuri.ItemDrag.defaultStartPredicate(item, event);
                return;
            }

            // Prevent items from being dragged while dragging is disabled.
            if (this.dragDisabled) {
                return false;
            }

            if (event.target?.nodeName === 'INPUT') {
                return false;
            }

            /**
             * Touch devices have an issue when trying to scroll: The screen is so small that one usually
             * scrolls by moving the pointer across the screen which accidentally drags photos.
             * This if-statement ensures that touch devices only drag photos when the user wants to.
             */
            if (isTouchOnly()) {
                if (event.deltaTime < 500) {
                    return;
                }
            }
            // If drag isn't disabled, let Muuri's default algorithm deal with this event.
            return Muuri.ItemDrag.defaultStartPredicate(item, event, {
                distance: this.DRAG_START_PREDICATE_DISTANCE,
            });
        },
        dragSortHeuristics: {
            sortInterval: 0,
        },
        dragSortPredicate: {
            threshold: 40,
            action: 'move',
        },
        dragPlaceholder: {
            enabled: true,
            createElement: function (item) {
                return item.getElement().cloneNode(false) as HTMLElement;
            },
            onCreate: null,
            onRemove: null,
        },
    };
    public grid: Muuri;
    private subscriptions: Subscription[] = [];

    private registeredEventListeners: Map<keyof GridEvents, GridEvents[keyof GridEvents]> = new Map();
    private addNewItem$ = new Subject<ElementRef<HTMLDivElement>>();
    private removeItem$ = new Subject<Item>();

    private previousPosition: number = undefined;
    private newPosition: number = undefined;

    private DRAG_START_PREDICATE_DISTANCE: number = 5;

    ngOnInit(): void {
        this.initializeMuuriGrid(this.elementRef);
        this.subscribeToItemChanges();
        this.registerEvents();
        this.listenToOutsideEvents();
    }

    //*****************************************************************************
    //  Grid
    //****************************************************************************/

    private initializeMuuriGrid(grid: ElementRef) {
        /**
         * As of 2024-03-05, Firefox on Android devices have issues opening the photo editor if dragging via Muuri is enabled.
         * Since we could not find a solution within Muuri and everything works fine in Chrome, Safari and Firefox on iOS, we
         * disable dragging on Firefox for Android.
         * Please check in the future whether Firefox or Muuri have fixed this issue.
         */
        const isFirefoxAndroid: boolean =
            navigator.userAgent.includes('Firefox/') && navigator.userAgent.includes('Android');
        if (isFirefoxAndroid) {
            this.layoutConfig.dragEnabled = false;
        }

        this.grid = new Muuri(grid.nativeElement, this.layoutConfig);
    }

    private subscribeToItemChanges(): void {
        const addItem$ = this.addNewItem$.pipe(tap((item) => this.grid.add([item.nativeElement], { layout: false })));
        const removeItem$ = this.removeItem$.pipe(tap((item) => this.grid.remove([item], { layout: false })));

        const subscription = merge(addItem$, removeItem$)
            .pipe(
                // The Subject is triggered on each item added / removed. Wait for all items to be added / removed before repainting the layout.
                debounceTime(25),
            )
            .subscribe(() => {
                this.grid.layout();
            });

        this.subscriptions.push(subscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Grid
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Update Items
    //****************************************************************************/
    public addItem(item: ElementRef) {
        this.addNewItem$.next(item);
    }

    public removeItem(item: ElementRef<HTMLDivElement>) {
        this.removeItem$.next(this.grid.getItem(item.nativeElement));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Update Items
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    private registerEvents(): void {
        this.on('move', (event: MuuriMoveEvent) => {
            // Only update the previous position once in order to hold on to the original position of the item
            if (this.previousPosition === undefined) {
                this.previousPosition = event.fromIndex;
            }
            // The new position shall be the target of the last move event, which is the final position
            this.newPosition = event.toIndex;
        });
        this.on('dragStart', () => {
            this.dragStart.emit();
        });
        this.on('dragEnd', () => {
            this.dragEnd.emit();

            if (this.previousPosition !== this.newPosition) {
                this.positionChange.emit({
                    fromIndex: this.previousPosition,
                    toIndex: this.newPosition,
                });
            } else {
                console.log(
                    'The photo was dropped on a drop zone next to itself. Magikarp uses splash. Nothing happened!',
                );
            }

            this.previousPosition = undefined;
            this.newPosition = undefined;
        });

        this.on('layoutEnd', () => {
            this.layoutEnd.emit();
        });
    }

    /**
     * Register event, avoiding duplicates.
     */
    private on(eventName: MuuriEventListenerName, action: any) {
        if (this.registeredEventListeners.has(eventName)) {
            return;
        }

        const eventListener = function (item, event) {
            action(item, event);
        };

        this.grid.on(eventName, eventListener);
        this.registeredEventListeners.set(eventName, eventListener);
    }

    /**
     * Listen to events triggered by the parent component and others
     */
    private listenToOutsideEvents(): void {
        const layoutSubscription = this.photoMuuriGridService.layoutTriggered$.subscribe({
            next: () => {
                this.grid.refreshSortData();
                this.grid.refreshItems();
                this.grid.layout();
            },
        });

        const moveItemsSubscription = this.photoMuuriGridService.moveItems$.subscribe({
            next: ({ fromIndexes, targetPosition }) => {
                // Sort the from indexes so that the grid items appearing first will be sorted to the front first.
                fromIndexes.sort((indexA, indexB) => {
                    if (indexA > indexB) return 1;
                    if (indexA < indexB) return -1;
                    return 0;
                });

                const movingItems: Item[] = this.grid.getItems(fromIndexes);

                // Get each half of the array (before & after target index) without the moving photos.
                const itemsBeforeTargetIndex: Item[] = this.grid
                    .getItems()
                    .slice(0, targetPosition)
                    .filter((item) => !movingItems.includes(item));
                const itemsAfterTargetIndex: Item[] = this.grid
                    .getItems()
                    .slice(targetPosition)
                    .filter((item) => !movingItems.includes(item));

                // Set new order to grid
                (this.grid as any)._items = [...itemsBeforeTargetIndex, ...movingItems, ...itemsAfterTargetIndex];

                // for (const item of movingItems) {
                //     // If there are 3 photos, insert the second behind the first and the third behind the second (targetPosition + key)
                //     this.grid.move(item, targetPosition, {layout : false});
                //     // Starting from the second photo to be moved, we want to insert the photo _after_ the first -> Increase index.
                //     targetPosition = this.grid.getItems().indexOf(item) + 1;
                // }
                this.grid.layout();
            },
        });

        const arrangementSubscription = this.photoMuuriGridService.arrangeItems$.subscribe({
            next: (newSortOrderIds) => {
                const items = this.grid.getItems();
                const sortedItems: Item[] = [];

                // Copy the items to the sortedItems array in the given order.
                for (const photoId of newSortOrderIds) {
                    const matchingItem = items.find((item) => item.getElement().id === photoId);
                    if (matchingItem) {
                        sortedItems.push(matchingItem);
                    }
                }

                this.grid.sort(sortedItems);

                //this.grid.refreshSortData();
                //this.grid.refreshItems();
                this.grid.layout();
            },
        });

        this.subscriptions.push(layoutSubscription, moveItemsSubscription, arrangementSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Cleanup
    //****************************************************************************/
    private destroyGrid() {
        this.registeredEventListeners.forEach((eventListener, eventName) => {
            this.grid.off(eventName, eventListener);
        });
        this.registeredEventListeners = new Map();

        this.grid.destroy();
        this.grid = null;
    }

    ngOnDestroy(): void {
        this.destroyGrid();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Cleanup
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface PositionChangeEvent {
    fromIndex: number;
    toIndex: number;
}

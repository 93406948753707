import { DateTime } from 'luxon';
import { generateId } from '../../lib/generate-id';
import { InvoiceParameters } from '../invoices/invoice-parameters';

/**
 * Object for "Stellungnahmen". Used if the insurance has clipped an assessor's or garange's invoice (-> short payment)
 * and the assessor gives reasons why the clipping was incorrect.
 *
 * The letter data (subject, body, date, recipientRole) sit on the DocumentMetadata object connected through the expertStatementId.
 */
export class ExpertStatement {
    _id: string = generateId();
    invoiceParameters: InvoiceParameters = new InvoiceParameters();
    createdBy: string = null;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { convertHtmlToPlainText } from '@autoixpert/lib/placeholder-values/strip-html';
import { InvoiceLineItemTemplate } from '@autoixpert/models/invoices/invoice-line-item-template';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { User } from '@autoixpert/models/user/user';
import { InvoiceLineItemTemplateService } from '../../services/invoice-line-item-template.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { MatQuillComponent } from '../mat-quill/mat-quill.component';

@Component({
    selector: 'invoice-line-item-template-selector',
    templateUrl: 'invoice-line-item-template-selector.component.html',
    styleUrls: ['invoice-line-item-template-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InvoiceLineItemTemplateSelector),
            multi: true,
        },
    ],
})
export class InvoiceLineItemTemplateSelector implements ControlValueAccessor, OnInit {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private invoiceLineItemTemplateService: InvoiceLineItemTemplateService,
    ) {}

    @Output() entryRemoved: EventEmitter<CustomAutocompleteEntry> = new EventEmitter<CustomAutocompleteEntry>();
    @Output() change: EventEmitter<string> = new EventEmitter<string>();
    @Output() blur: EventEmitter<{ isAutocompleteOpen: boolean }> = new EventEmitter();

    @Output() invoiceLinItemTemplateSelected = new EventEmitter<InvoiceLineItemTemplate>();
    @Input() invoiceLineItemTemplates: InvoiceLineItemTemplate[] = [];

    @ViewChild('autocomplete') autocomplete: MatAutocomplete;
    @ViewChild('descriptionInput') descriptionInput: MatQuillComponent;

    public user: User;
    public value: string;
    private onNgModelChange: (value: string) => void;
    private onTouched: () => void;

    // Forward the focus event to automatically focus the description input when a new position is created.
    public focusInput() {
        this.descriptionInput.focusInput();
    }

    public filteredAutocompleteEntries: InvoiceLineItemTemplate[] = [];

    public emitLineItemTemplateSelected(template: InvoiceLineItemTemplate) {
        // Delay by a tick to ensure the value is set after the material autocomplete emits the default value.
        setTimeout(() => {
            this.invoiceLinItemTemplateSelected.emit(template);
        });
    }

    async forgetLineItemTemplate(template: InvoiceLineItemTemplate) {
        removeFromArray(template, this.invoiceLineItemTemplates);
        removeFromArray(template, this.filteredAutocompleteEntries);
        await this.invoiceLineItemTemplateService.delete(template._id);
    }

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.filteredAutocompleteEntries = this.invoiceLineItemTemplates;
    }

    public filterAutocomplete(): void {
        const searchTerms = convertHtmlToPlainText(this.value || '')
            .toLowerCase()
            .split(' ');
        this.filteredAutocompleteEntries = this.invoiceLineItemTemplates.filter((entry) => {
            // Show all entries including all search terms, no matter the position, to allow the user to find an entry whose wording he does not fully know.
            const entryValue = convertHtmlToPlainText(entry.description).toLowerCase();
            return searchTerms.every((searchTerm) => entryValue.includes(searchTerm));
        });
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitNgModelChange() {
        this.onNgModelChange(this.value);
        this.onTouched();
    }

    public emitChange() {
        this.change.emit(this.value);
    }

    public emitBlur() {
        this.blur.emit({ isAutocompleteOpen: this.autocomplete.isOpen });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Control Value Accessor
    //****************************************************************************/
    // The ControlValueAccessor allows us to use [(ngModel)] on this component.

    public writeValue(value: string) {
        this.value = value;
    }

    public registerOnChange(fn: any) {
        this.onNgModelChange = fn;
    }

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Control Value Accessor
    /////////////////////////////////////////////////////////////////////////////*/
}

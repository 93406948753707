<h2>DAT-Vorgang verknüpfen</h2>
<mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>

<!--============================================-->
<!-- Dossiers Loaded -->
<!--============================================-->
<ng-container *ngIf="!dossiersPending">
    <div class="dialog-scrollable-area">
        <!--============================================-->
        <!-- Dossier List -->
        <!--============================================-->
        <div id="record-list" *ngIf="dossiers" @fadeInAndSlide>
            <table id="record-table">
                <!--********** Record **********-->
                <tr
                    class="dat-record record"
                    *ngFor="let dossier of dossiers"
                    (click)="selectDossier(dossier); hideDossierIdInput()"
                    (dblclick)="closeWithSelectedDossier()"
                    [ngClass]="{ active: selectedDossier === dossier }">
                    <!--********** Icon **********-->
                    <td class="manufacturer-icon-cell">
                        <div class="manufacturer-icon-container" [matTooltip]="dossier.make">
                            <img
                                class="car-brand-logo"
                                *ngIf="iconForCarBrandExists(dossier.make)"
                                [src]="iconFilePathForCarBrand(dossier.make, 'large')" />
                            <div
                                class="no-brand-icon-replacement text-overflow-ellipsis flex-center-content"
                                *ngIf="!iconForCarBrandExists(dossier.make)">
                                ?
                            </div>
                        </div>
                    </td>

                    <!--********** Model & Date **********-->
                    <td class="model-and-date-container">
                        <div class="model text-overflow-ellipsis" [matTooltip]="dossier.baseModel?.baseModelName">
                            {{ dossier.baseModel?.baseModelName || 'Kein Fahrzeug' }}
                        </div>
                        <div
                            class="label creation-date"
                            matTooltip="Geändert am {{ dossier.updatedAt | moment: 'DD.MM.YYYY' }}">
                            {{ capitalize(dossier.updatedAt | momentCalendar: 'day') }}
                        </div>
                    </td>

                    <!--********** License Plate **********-->
                    <td class="license-plate-cell">
                        <license-plate
                            *ngIf="dossier.licensePlate"
                            [licensePlate]="dossier.licensePlate"
                            [disabled]></license-plate>
                    </td>

                    <!--********** Calculated Amount **********-->
                    <td class="calculated-amount-container text-align-end">
                        <!--********** Calculation Result **********-->
                        <ng-container *ngIf="dossierType === 'damageCalculation'">
                            <!--********** No Total - Placeholder **********-->
                            <div
                                *ngIf="!dossier.calculationResultNet"
                                class="no-calculated-amount label italic"
                                matTooltip="Kalkulationssumme nicht vorhanden.

                                     Du kannst die Kalkulation jetzt schon verknüpfen und sie später fertigstellen.">
                                Keine Summe
                            </div>

                            <!--********** Total **********-->
                            <div
                                *ngIf="dossier.calculationResultNet"
                                class="calculated-amount semibold"
                                matTooltip="Kalkulationssumme (netto). Brutto: {{
                                    dossier.calculationResultGross | number: '1.2-2'
                                }} €">
                                {{ dossier.calculationResultNet | number: '1.2-2' }}
                                €
                            </div>
                        </ng-container>

                        <!--********** Valuation Result **********-->
                        <ng-container *ngIf="dossierType === 'valuation'">
                            <!--********** No Total - Placeholder **********-->
                            <!-- Typescript does not correct get the type DatValuationDossierListItem. We could think about adding a type guard function.-->
                            <div
                                *ngIf="!$any(dossier).salesPriceNet"
                                class="no-calculated-amount label italic"
                                matTooltip="Händler-Einkaufspreis nicht vorhanden.

                                     Du kannst die Bewertung jetzt schon verknüpfen und sie später fertigstellen.">
                                Keine Summe
                            </div>

                            <!--********** Total **********-->
                            <!-- Typescript does not correct get the type DatValuationDossierListItem. We could think about adding a type guard function.-->
                            <div
                                *ngIf="$any(dossier).salesPriceNet"
                                class="calculated-amount semibold"
                                matTooltip="Händler-Einkaufspreis (brutto). Netto: {{
                                    $any(dossier).salesPriceNet | number: '1.2-2'
                                }} €">
                                {{ $any(dossier).salesPriceGross | number: '1.2-2' }}
                                €
                            </div>
                        </ng-container>
                    </td>
                </tr>
            </table>

            <!--============================================-->
            <!-- No Records - Placeholder -->
            <!--============================================-->
            <div id="no-records-placeholder" class="text-align-center" *ngIf="!dossiers.length">
                Keine Vorgänge gefunden
                <div *ngIf="dossierType === 'valuation'" class="label">
                    Bewertungen mit Änderungen in den letzten 7 Tage werden angezeigt.
                </div>
            </div>
            <!--============================================-->
            <!-- END No Records - Placeholder -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Dossier List -->
        <!--============================================-->

        <!--============================================-->
        <!-- Connect other Calculation -->
        <!--============================================-->
        <div class="link text-align-center" (click)="showDossierIdInput()" *ngIf="!dossierIdInputShown">
            Andere DAT-Kalkulation
        </div>
        <div id="custom-dossier-id-input-container" class="text-align-center" *ngIf="dossierIdInputShown">
            <div class="margin-bottom-10">
                <a href="https://wissen.autoixpert.de/hc/de/articles/360018696079" target="_blank">
                    Wo finde ich die DAT-Ordnungsnummer?
                </a>
            </div>
            <mat-form-field>
                <input matInput [(ngModel)]="customDossierId" placeholder="DAT-Ordnungsnummer" [axAutofocus]="true" />
            </mat-form-field>
        </div>
        <!--============================================-->
        <!-- END Connect other Calculation -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Dossiers Loaded -->
    <!--============================================-->

    <!--============================================-->
    <!-- Buttons -->
    <!--============================================-->
    <div class="dialog-buttons-container">
        <button [ngClass]="{ disabled: !selectedDossier && !customDossierId }" (click)="handleConnectionButtonClick()">
            Verknüpfen
        </button>
    </div>
    <!--============================================-->
    <!-- END Buttons -->
    <!--============================================-->
</ng-container>
<!--============================================-->
<!-- Dossiers Pending -->
<!--============================================-->
<div id="dossiers-pending-placeholder" class="text-align-center" *ngIf="dossiersPending">
    <img src="/assets/images/icons/loading-indicator.svg" />
    <div><strong>Warte auf DAT...</strong></div>
    <div class="label">Das kann je nach Tagesform der DAT-Server etwas dauern.</div>
</div>
<!--============================================-->
<!-- END Dossiers Pending -->
<!--============================================-->

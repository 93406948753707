import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { determineFeePreferencesKey } from '@autoixpert/lib/fee-calculation/determine-fee-preference-key';
import { areCredentialsForCarIdentificationProviderComplete } from '@autoixpert/lib/users/are-credentials-for-car-identification-provider-complete';
import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { isGtmotiveUserComplete } from '@autoixpert/lib/users/is-gtmotive-user-complete';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { FeePreferencesKey, UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { reportTypesWithFeeSets } from '../../../models/reports/assessors-fee/custom-fee-set';
import { getYearOfLatestFeeTable } from '../../fee-set/get-year-of-latest-fee-table';

type UserPreferencesKeys = keyof typeof UserPreferences;

/**
 * This function imitates the frontend user preferences service to allow this function to run in the backend.
 */
class UserPreferencesWithDefaults extends UserPreferences {
    /**
     * return Proxy to this.user
     */
    constructor(private user: User) {
        super();

        return new Proxy<UserPreferences & UserPreferencesWithDefaults>(this, this.handler);
    }

    /**
     * handle get and operations on proxy
     */
    private handler = {
        get: (target, propertyKey: UserPreferencesKeys, receiver) => {
            // use default value as fallback if no user is logged in
            if (!this.user?.preferences) {
                return Reflect.get(this, propertyKey, receiver);
            }

            const defaultValue = Reflect.get(this, propertyKey, receiver);
            const userValue = Reflect.get(this.user.preferences, propertyKey, receiver);

            // return default value if user value is nullish
            return userValue ?? defaultValue;
        },
    };
}

/**
 * Merges the user preferences of the given user into the report.
 * The responsible assessor is provided separately, since it may differ if the report is created in the frontend.
 */
export function mergeUserPreferencesIntoReport({
    report,
    team,
    creator,
    responsibleAssessor,
}: {
    report: Report;
    team: Team;
    creator: User;
    responsibleAssessor: User;
}) {
    // The frontend uses a user preferences service to get the default preferences as fallback.
    // Imitate this behavior to allow this function to run in the backend.
    const creatorPreferences = new UserPreferencesWithDefaults(creator);

    report.orderingMethod = creatorPreferences.orderingMethod;
    report.useCostEstimateHeading = creatorPreferences.useCostEstimateHeading || false;

    // Merge default visit auxiliary devices.
    if (creatorPreferences.auxiliaryDevicesDefault.length) {
        report.visits[0].auxiliaryDevices = creatorPreferences.auxiliaryDevicesDefault;
    }

    // Merge default otherPeoplePresent.
    if (creatorPreferences.otherPeoplePresentDefault?.length) {
        report.visits[0].otherPeoplePresent = creatorPreferences.otherPeoplePresentDefault;
    }

    /**
     * Visits
     */
    if (report.visits[0]) {
        report.visits[0].assessor = report.responsibleAssessor;
    }

    /**
     * Office Location ID.
     */
    if (!report.officeLocationId) {
        if (creator._id === report.responsibleAssessor) {
            report.officeLocationId = creator.defaultOfficeLocationId;
        } else {
            report.officeLocationId = responsibleAssessor?.defaultOfficeLocationId;
        }
    }

    /**
     * Car Data
     */
    report.car.identificationProvider = creatorPreferences.vehicleIdentificationProvider;

    // Check if the credentials for the default identification provider are complete.
    if (!areCredentialsForCarIdentificationProviderComplete(report.car.identificationProvider, creator)) {
        report.car.identificationProvider = null;
    }

    // If a default isn't set, check which provider is filled out.
    if (!report.car.identificationProvider) {
        if (isDatUserComplete(creator)) {
            report.car.identificationProvider = 'dat';
        } else if (isAudatexUserComplete(creator)) {
            report.car.identificationProvider = 'audatex';
        } else if (isGtmotiveUserComplete(creator)) {
            report.car.identificationProvider = 'gtmotive';
        } else {
            // If neither account is set, use DAT.
            report.car.identificationProvider = 'dat';
        }
    }

    //*****************************************************************************
    //  Damage Calculation
    //****************************************************************************/
    /**
     * Not all reports contain a damage calculation, e.g. oldtimer valuations.
     */
    if (report.damageCalculation) {
        report.damageCalculation.repair.useSimpleEstimateCalculation =
            creatorPreferences.useSimpleEstimateCalculation ?? false;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damage Calculation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Valuation
    //****************************************************************************/
    report.valuation.decreaseFromDamagePercentage = team.preferences.valuation_decreasePercentage;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Valuation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fee Calculation
    //****************************************************************************/
    // Fee Tables are only used in liability and kasko reports. Only set a default there.
    if (reportTypesWithFeeSets.includes(report.type)) {
        // If custom, set custom fee table id
        let customFeeTableId: string;

        switch (report.type) {
            case 'liability':
                report.feeCalculation.selectedFeeTable = team.preferences.defaultFeeTableLiability;

                if (report.feeCalculation.selectedFeeTable === 'custom') {
                    customFeeTableId = team.preferences.defaultCustomFeeTableIdLiability;
                }
                break;
            case 'partialKasko':
                report.feeCalculation.selectedFeeTable = team.preferences.defaultFeeTablePartialKasko;
                if (report.feeCalculation.selectedFeeTable === 'custom') {
                    customFeeTableId = team.preferences.defaultCustomFeeTableIdPartialKasko;
                }
                break;
            case 'fullKasko':
                report.feeCalculation.selectedFeeTable = team.preferences.defaultFeeTableFullKasko;

                if (report.feeCalculation.selectedFeeTable === 'custom') {
                    customFeeTableId = team.preferences.defaultCustomFeeTableIdFullKasko;
                }
                break;
        }

        report.feeCalculation.yearOfFeeTable = getYearOfLatestFeeTable(report.feeCalculation.selectedFeeTable);
        report.feeCalculation.selectedCustomFeeTableId = customFeeTableId;
    }
    // The user may set different preferences by fee table (a set for HUK, a set for everything else). Determine which defaults are right.
    const feePreferencesKey: FeePreferencesKey = determineFeePreferencesKey(
        report.type,
        report.feeCalculation.selectedFeeTable,
    );

    // hasOwnProperty does not work when a report is created by the external API in the backend.
    // Reason: mongoose loads team behind a Proxy
    // Workaround: copy the feeSet to a new object.
    const teamPreferenceFeeSet = JSON.parse(JSON.stringify(team.preferences[feePreferencesKey]));
    for (const property in report.feeCalculation) {
        if (!report.feeCalculation.hasOwnProperty(property)) continue;
        if (teamPreferenceFeeSet?.hasOwnProperty(property)) {
            report.feeCalculation[property] = team.preferences[feePreferencesKey][property];
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fee Calculation
    /////////////////////////////////////////////////////////////////////////////*/

    // Invoice Parameters
    if (report.type === 'leaseReturn') {
        report.feeCalculation.invoiceParameters.recipientRole = 'leaseProvider';
    }
    // Set default VAT rate from team config
    report.feeCalculation.invoiceParameters.vatRate = team.invoicing.vatRate ?? 0.19;
    if (team.invoicing.vatRate === 0) {
        report.feeCalculation.invoiceParameters.vatExemptionReason = 'smallBusiness';
    }

    // Merge permanent user uploaded documents
    if (team.permanentUploadedDocuments) {
        team.permanentUploadedDocuments.forEach((customDocument) => {
            addDocumentToReport({
                report,
                team,
                documentGroup: 'report',
                newDocument: customDocument,
            });
        });
    }

    // Merge DAT valuation properties => copy the object before, Object.assign does not work with the proxy
    const creatorIncludedDocuments = JSON.parse(JSON.stringify(creator.preferences.datValuationIncludedDocuments));
    report.valuation.datValuation.includedDocuments = Object.assign({}, creatorIncludedDocuments);
    report.valuation.datValuation.requestedVatHandling = creatorPreferences.datValuationVatIncluded;
    // Set the default to dealer purchase for valuations, because most assessors want this value type for valuations (in contrast to replacement value for liability)
    report.valuation.datValuation.vehicleValueType =
        report.type === 'valuation' ? 'dealerPurchase' : creatorPreferences.datValuationVehicleValueType;
    report.valuation.datValuation.vatDisplayType = creatorPreferences.datValuationVatDisplayType;
    report.valuation.datValuation.priceOrigin = creatorPreferences.datValuationPriceOrigin;

    // Merge valuation with cartv & WinValue properties
    report.valuation.cartvValuation.searchRadiusInKm = creatorPreferences.replacementValueSearchRadiusCartv;
    report.valuation.winvalueValuation.searchRadiusInKm = creatorPreferences.replacementValueSearchRadiusWinvalue;
    report.valuation.valuepilotValuation.searchRadiusInKm = creatorPreferences.replacementValueSearchRadiusValuepilot;

    // Merge residual value properties
    report.valuation.residualValueRegionalRadius = creatorPreferences.residualValueRegionalRadius;

    /**
     * Initialize order time only if the field is present in the UI.
     */
    if (team.preferences.reportOrderTimeShown && !report.orderTime) {
        report.orderTime = new Date().toISOString();
    }
}

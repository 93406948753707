import { LineItem } from '@autoixpert/models/invoices/line-item';
import { RepairConfirmation } from '@autoixpert/models/reports/repair-confirmation';
import { Team } from '@autoixpert/models/teams/team';

export const REPAIR_CONFIRMATION_PHOTO_LINE_ITEM_DESCRIPTION = '<p>Fotos</p>';

export function generateRepairConfirmationObject({ team }: { team: Team }) {
    // Add repair confirmation object itself
    const repairConfirmation = new RepairConfirmation();

    // Remove ID from invoice. The ID determines if an invoice exists.
    repairConfirmation.invoiceParameters._id = null;

    // Add the two default line items
    repairConfirmation.invoiceParameters.lineItems = [
        new LineItem({
            description: '<p>Honorar Reparaturbestätigung</p>',
            position: 1,
            quantity: 1,
            active: true,
        }),
        new LineItem({
            description: REPAIR_CONFIRMATION_PHOTO_LINE_ITEM_DESCRIPTION,
            position: 2,
            quantity: 1,
            active: true,
        }),
    ];

    // Set the VAT rate
    repairConfirmation.invoiceParameters.vatRate = team.invoicing.vatRate;
    if (team.invoicing.vatRate === 0) {
        repairConfirmation.invoiceParameters.vatExemptionReason = 'smallBusiness';
    }

    return repairConfirmation;
}

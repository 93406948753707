import { getMapFromRecords } from '@autoixpert/lib/server-sync/get-map-from-records';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { DefaultDocumentOrder } from '../../models/teams/default-document-order/default-document-order';
import { DefaultDocumentOrderItem } from '../../models/teams/default-document-order/default-document-order-item';

/**
 * Resets the given document order to the given default document order (sorts and activates documents based on the default order).
 */
export function resetDocumentOrderToDefault({
    templateOrder,
    orderToBeSorted,
    documents,
    activateNewDocumentsByDefault,
}: {
    templateOrder: DefaultDocumentOrder;
    orderToBeSorted: DocumentOrder;
    documents: DocumentMetadata[];
    activateNewDocumentsByDefault: boolean;
}): void {
    const documentMap: Map<DocumentMetadata['_id'], DocumentMetadata> = getMapFromRecords(documents);

    // Activate documents
    orderToBeSorted.items.forEach((orderItem) => {
        const documentToBeSorted = documentMap.get(orderItem._id);
        orderItem.includedInFullDocument =
            getDefaultDocumentOrderItem(templateOrder, documentToBeSorted)?.includedInFullDocument ??
            activateNewDocumentsByDefault;
    });

    // Sort documents
    orderToBeSorted.items.sort((itemToBeSortedA, itemToBeSortedB) => {
        const templateItemA: DefaultDocumentOrderItem = getDefaultDocumentOrderItem(
            templateOrder,
            documentMap.get(itemToBeSortedA._id),
        );
        const templateItemB: DefaultDocumentOrderItem = getDefaultDocumentOrderItem(
            templateOrder,
            documentMap.get(itemToBeSortedB._id),
        );

        const indexItemA = templateOrder.items.indexOf(templateItemA);
        const indexItemB = templateOrder.items.indexOf(templateItemB);

        if (indexItemA === -1) {
            // In case we did not find a template for the document -> probably a custom uploaded document.
            // Sort them to the end of the list
            return 1;
        }

        if (indexItemB === -1) {
            // In case we did not find a template for the document -> probably a custom uploaded document.
            // Sort them to the end of the list
            return -1;
        }

        return indexItemA - indexItemB;
    });
}

/**
 * Returns the matching default document order item for the given document.
 */
function getDefaultDocumentOrderItem(templateOrder: DefaultDocumentOrder, documentToBeSorted: DocumentMetadata) {
    // Compare manually uploaded pdfs by uploadedDocumentId since there could be multiple
    if (documentToBeSorted.type === 'manuallyUploadedPdf') {
        return templateOrder.items.find(
            (templateDocumentOrderItem) =>
                templateDocumentOrderItem.uploadedDocumentId === documentToBeSorted.uploadedDocumentId,
        );
    }

    /**
     * The other document types can be compared by type
     * - type 'expertStatement' is not unique but it is fine if they all get moved to the same position
     * - type 'letter' is unique, since the template order and the document list contain only the letter from the current recipient
     */
    return templateOrder.items.find(
        (templateDocumentOrderItem) => templateDocumentOrderItem.documentType === documentToBeSorted.type,
    );
}

/**
 * This is a short form of a contact person, being able to link
 * to the original contact person through its ID while also being
 * able to display the name without retrieving the full contact person.
 */
export class ContactPersonReference {
    constructor(template?: ContactPersonReference) {
        if (template) {
            Object.assign(this, template);
        }
    }

    contactPersonId: string = null;
    name: string = null;
}

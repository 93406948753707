export class Photo {
    constructor(options: any) {
        for (const property in this) {
            // Do not allow overwriting methods
            if (<any>this[property] instanceof Function) continue;

            if (typeof options[property] !== 'undefined') {
                this[property] = options[property];
            }
        }
    }

    _id: string = null;
    orderPosition: number = null;
    title: string = null;
    description: string = null;
    originalName: string = null; // The file name when the client uploaded it
    mimetype: string = null;
    size: number = null; // Image size in bytes
    height: number = null;
    width: number = null;
    versions: PhotoConfiguration = new PhotoConfiguration();
}

export class PhotoConfiguration {
    // A photo may be included in several groups (report doc, residual value platform, repair confirmation doc, expert statement doc). For each version, the shapes may be customized.
    report: PhotoConfigurationPattern = new PhotoConfigurationPattern();
    residualValueExchange: PhotoConfigurationPattern = new PhotoConfigurationPattern();
    repairConfirmation: PhotoConfigurationPattern = new PhotoConfigurationPattern();
    expertStatement: PhotoConfigurationPattern = new PhotoConfigurationPattern();
}

export class PhotoConfigurationPattern {
    included: boolean = false;
    fabricJsInformation: Record<string, any> = null;
}

export type PhotoFormat = 'original' | 'thumbnail400' | 'rendered' | 'documentSize';
export type PhotoVersion = keyof PhotoConfiguration;

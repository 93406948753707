import { DocumentBuildingBlockCondition } from '../../models/documents/document-building-block-condition';
import { DocumentBuildingBlockConditionGroup } from '../../models/documents/document-building-block-condition-group';
import { generateId } from '../generate-id';
import { isDocumentBuildingBlockConditionGroup } from './is-document-building-block-condition-group';

export function duplicateDocumentBuildingBlockCondition<
    T extends DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup,
>(condition: T): T {
    if (isDocumentBuildingBlockConditionGroup(condition)) {
        return {
            ...condition,
            _id: generateId(),
            conditions: condition.conditions.map((condition) => duplicateDocumentBuildingBlockCondition(condition)),
        };
    }

    return { ...condition, _id: generateId() };
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Report } from '@autoixpert/models/reports/report';

@Injectable()
export class AfzzertProcessesService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Create a process and get the login URL.
     */
    public async create({ reportId }: { reportId: Report['_id'] }) {
        return await this.httpClient
            .post<AfzzertProcessServiceCreateResponse>(`/api/v0/reports/${reportId}/afzzertProcesses`, {})
            .toPromise();
    }

    /**
     * Import the certificate. This will not download the certificate but only import it into our internal object store.
     */
    public async importCertificate({ reportId }: { reportId: Report['_id'] }) {
        return await this.httpClient
            .get<{ success: true }>(`/api/v0/reports/${reportId}/afzzertProcesses`, {})
            .toPromise();
    }

    /**
     * Remove the certificate from our internal object store.
     */
    public async remove({ reportId }: { reportId: Report['_id'] }) {
        return await this.httpClient
            .delete<{
                success: true;
            }>(
                `/api/v0/reports/${reportId}/afzzertProcesses/backend-will-replace-dummy-id-with-report-id-dummy-id-required-for-feathersjs`,
            )
            .toPromise();
    }

    /**
     * Trigger downloading
     */
    public async downloadCertificatePdf({ reportId }: { reportId: Report['_id'] }) {
        return this.httpClient
            .get(`/api/v0/reports/${reportId}/documents/afzzertCertificate`, {
                responseType: 'blob',
                observe: 'response',
            })
            .toPromise();
    }
}

export interface AfzzertProcessServiceCreateResponse {
    loginUrl: string;
    loginUrlExpiresAt: string; // ISO timestamp. Valid for 30 minutes.
}

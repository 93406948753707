import { ServerError } from '../../models/errors/ax-error';

export function getDocumentFileExtension(contentTypeOrFileExtension: ContentTypeOrFileExtension): 'pdf' | 'docx' {
    switch (contentTypeOrFileExtension) {
        case 'application/pdf':
        case 'pdf':
            return 'pdf';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'docx':
            return 'docx';
        default:
            throw new ServerError({
                code: 'INVALID_FILE_FORMAT_FOR_REPORT_DOWNLOAD_NAME',
                message: `Please provide a valid file format (pdf or docx).`,
                data: {
                    fileFormat: contentTypeOrFileExtension,
                },
            });
    }
}

export type ContentTypeOrFileExtension =
    | 'pdf'
    | 'docx'
    | 'application/pdf'
    | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

<div id="help-panel-container" class="card">
    <!--============================================-->
    <!-- Header -->
    <!--============================================-->
    <header>
        <h2 id="greeting-headline">Hi {{ user.firstName }}! 👋🏼</h2>
        <div id="greeting-tagline">Wie können wir dir helfen?</div>
        <mat-icon class="dialog-close-icon" (click)="close()">close</mat-icon>
        <!--============================================-->
        <!-- Search Input -->
        <!--============================================-->
        <div id="search-input-container">
            <mat-icon id="search-input-icon">search</mat-icon>
            <input
                id="search-input"
                type="text"
                placeholder="Hilfe-Artikel suchen..."
                [(ngModel)]="searchTerm"
                (ngModelChange)="updateSearchTerm($event)"
                #searchInput />
        </div>
        <!--============================================-->
        <!-- END Search Input -->
        <!--============================================-->
    </header>
    <!--============================================-->
    <!-- END Header -->
    <!--============================================-->

    <!--============================================-->
    <!-- Main Page -->
    <!--============================================-->
    <div id="main-page-container" *ngIf="page === 'main'">
        <!--============================================-->
        <!-- Search Results -->
        <!--============================================-->
        <section id="search-results-section" *ngIf="searchResults.length || searchPending">
            <!--********** Search Results **********-->
            <ng-container *ngIf="!searchPending">
                <div class="search-result" *ngFor="let searchResult of searchResults.slice(0, 5)">
                    <a
                        [href]="searchResult.html_url"
                        target="_blank"
                        (click)="saveClickedLinkAnalytics(searchResult.html_url)"
                        rel="”noopener”">
                        {{ searchResult.title }}
                    </a>
                </div>
                <!--********** More Search Results **********-->
                <div class="search-result" *ngIf="searchResults.length > 5">
                    <a
                        id="more-search-results-link"
                        href="https://www.google.de/search?q=autoixpert+{{ searchTerm }}"
                        target="_blank"
                        rel="noopener">
                        Weitere Ergebnisse
                        <mat-icon class="medium-icon">open_in_new</mat-icon>
                    </a>
                </div>
            </ng-container>
            <!--********** Search Pending **********-->
            <div id="search-pending-container" *ngIf="searchPending">
                <img src="/assets/images/icons/loading-indicator.svg" alt="" />
            </div>
        </section>
        <!--============================================-->
        <!-- END Search Results -->
        <!--============================================-->

        <!--============================================-->
        <!-- Quick Links -->
        <!--============================================-->
        <section id="quick-links-section">
            <!--********** First Steps **********-->
            <a class="quick-link-container" href="javascript:void(0)" (click)="openIntroVideosPanel(); close()">
                <span>Intro-Videos</span>
            </a>

            <!--********** Videos / YouTube **********-->
            <a
                class="quick-link-container"
                href="https://www.youtube.com/c/autoiXpert?utm_source=autoixpert-app&utm_medium=help-panel"
                target="_blank"
                rel="”noopener”">
                <span>Alle Video-Tutorials</span>
            </a>

            <!--********** AnyDesk **********-->
            <a class="quick-link-container" [href]="getAnydeskLink()" target="_blank" rel="”noopener”">
                <img
                    class="anydesk-logo-in-overview"
                    src="/assets/images/logos/anydesk-logo-squares.svg"
                    alt="AnyDesk-Logo" />
                <span>AnyDesk</span>
            </a>

            <!--********** TeamViewer (Audatex only) **********-->
            <!--            <a-->
            <!--                class="quick-link-container"-->
            <!--                *ngIf="isQapterixpert()"-->
            <!--                [href]="getTeamviewerLink()"-->
            <!--                target="_blank"-->
            <!--                rel="”noopener”">-->
            <!--                <img-->
            <!--                    class="anydesk-logo-in-overview"-->
            <!--                    src="/assets/images/logos/teamviewer-logo.svg"-->
            <!--                    alt="TeamViewer-Logo" />-->
            <!--                <span>TeamViewer</span>-->
            <!--            </a>-->

            <!--********** Get in touch **********-->
            <a class="quick-link-container" (click)="confirmToShowGetInTouchPage()" target="_blank" rel="”noopener”">
                <span>Kontaktiere uns</span>
            </a>

            <!--********** Referral **********-->
            <a class="quick-link-container full-width" (click)="showReferralPage()" *ngIf="!isQapterixpert()">
                <span>50 € Empfehlungsbonus</span>
            </a>

            <!--********** Frontend Version **********-->
            <div *ngIf="currentVersion" class="frontend-version-container full-width">
                <span [matTooltip]="'Version vom ' + (currentVersion?.createdAt | date: 'dd.MM.yyyy HH:mm')">
                    Version&nbsp;{{ currentVersion?.commit }}
                </span>
                <mat-icon
                    *ngIf="!!latestVersion"
                    class="toolbar-icon"
                    [matTooltip]="'Neue Version ' + latestVersion?.commit + ' installieren\n(Seite wird neu geladen)'"
                    (click)="updateToLatestVersion()">
                    refresh
                </mat-icon>
            </div>
            <!--********** Development **********-->
            <div *ngIf="!currentVersion" class="frontend-version-container full-width">
                <span [matTooltip]="'Du befindest dich auf einem Development-System, ohne dedizierte Versionsnummer.'">
                    Version: Development
                </span>
                <mat-icon class="toolbar-icon">construction</mat-icon>
            </div>
        </section>
        <!--============================================-->
        <!-- END Quick Links -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Main Page -->
    <!--============================================-->

    <!--============================================-->
    <!-- Get in touch -->
    <!--============================================-->
    <section id="get-in-touch-section" *ngIf="page === 'getInTouch'">
        <div class="subpage-headline-container">
            <h3>Kontakt</h3>
            <mat-icon class="back-icon" (click)="showMainPage()">arrow_back</mat-icon>
        </div>

        <!--============================================-->
        <!-- Audatex Contact Info -->
        <!--============================================-->
        <ng-container *ngIf="user.audatexUser.username">
            <div class="contact-details-container">
                <div class="contact-details-logo-container">
                    <img src="/assets/images/logos/audatex.svg" alt="" />
                </div>
                <div class="contact-options-column">
                    <div class="semibold margin-bottom-10">Kalkulation, Daten & mehr</div>
                    <div class="contact-option-container">
                        <div class="label">Support & Vertrieb</div>
                        <a href="tel:+49 30 209691 399" class="contact-option-value" target="_blank" rel="”noopener”">
                            +49 30 209691 399
                        </a>
                    </div>
                    <div class="contact-option-container">
                        <div class="label">Schriftlicher Support</div>
                        <a
                            href="mailto:svvs-hotline@ax-ao.de"
                            class="contact-option-value"
                            target="_blank"
                            rel="”noopener”">
                            svvs-hotline&#64;ax-ao.de
                        </a>
                    </div>
                </div>
            </div>
        </ng-container>
        <!--============================================-->
        <!-- END Audatex Contact Info -->
        <!--============================================-->

        <!--============================================-->
        <!-- DAT contact details -->
        <!--============================================-->
        <div class="contact-details-container" id="dat-contact-details-container" *ngIf="user.datUser.customerNumber">
            <div class="contact-details-logo-container">
                <img id="dat-logo" src="/assets/images/logos/dat-logo-no-text.png" alt="" />
            </div>
            <div class="contact-options-column">
                <div class="semibold margin-bottom-10">Kalkulation, Daten & mehr</div>
                <div class="contact-option-container">
                    <div class="label">DAT-Support</div>
                    <a href="tel:+49 711 4503 130" class="contact-option-value" target="_blank" rel="”noopener”">
                        +49 711 4503 130
                    </a>
                    <div class="label">Kundennr.: {{ user.datUser.customerNumber }}</div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END DAT contact details -->
        <!--============================================-->

        <hr id="section-divider" *ngIf="user.audatexUser.username || user.datUser.customerNumber" />

        <!--============================================-->
        <!-- autoiXpert Contact Info -->
        <!--============================================-->
        <!--============================================-->
        <!-- Outside office hours -->
        <!--============================================-->
        <div id="outside-office-hours" *ngIf="!isInsideOfficeHours()">
            Leider befinden wir uns
            <br />
            <strong>außerhalb der Öffnungszeiten.</strong>
            <div class="label">Mo - Fr von 9 - 17 Uhr</div>
        </div>
        <!--============================================-->
        <!-- END Outside office hours -->
        <!--============================================-->

        <!--============================================-->
        <!-- AutoiXpert contact details -->
        <!--============================================-->
        <div class="contact-details-container margin-bottom-20">
            <div class="contact-details-logo-container">
                <img
                    src="/assets/images/logos/autoixpert-x.png"
                    alt=""
                    matTooltip="autoiXpert, Hersteller von {{ productName }}" />
            </div>
            <div class="contact-options-column">
                <ng-container *ngIf="isInsideOfficeHours()">
                    <div class="semibold margin-bottom-10">Bedienung {{ productName }}</div>
                    <div class="contact-option-container">
                        <div class="label">Support & Vertrieb</div>
                        <a href="tel:+4982229188990" class="contact-option-value" target="_blank" rel="”noopener”">
                            +49 8222 91 88 99 0
                        </a>
                    </div>
                </ng-container>
                <div class="contact-option-container">
                    <div class="label">Schriftlicher Support</div>
                    <a href="mailto:hilfe@autoixpert.de" class="contact-option-value" target="_blank" rel="”noopener”">
                        hilfe&#64;autoixpert.de
                    </a>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END AutoiXpert contact details -->
        <!--============================================-->

        <!--********** AnyDesk **********-->
        <div id="anydesk-container" *ngIf="isInsideOfficeHours()">
            <h3>Vorbereitung</h3>
            <p>
                Bitte starte bei Fragen zur Software
                <br />
                stets AnyDesk.
            </p>
            <a class="anydesk-download-container" [href]="getAnydeskLink()" target="_blank" rel="”noopener”">
                <img class="anydesk-logo-download" src="/assets/images/logos/anydesk-logo-invertiert.svg" alt="" />
            </a>
        </div>

        <a
            id="any-desk-link"
            *ngIf="!isInsideOfficeHours()"
            href="https://anydesk.com"
            target="_blank"
            rel="”noopener”">
            AnyDesk
        </a>
        <!--============================================-->
        <!-- END autoiXpert Contact Info -->
        <!--============================================-->
    </section>
    <!--============================================-->
    <!-- END Get in touch -->
    <!--============================================-->

    <!--============================================-->
    <!-- Referral -->
    <!--============================================-->
    <section id="referral-section" *ngIf="page === 'referral'">
        <div class="subpage-headline-container">
            <h3>Empfehlungsbonus</h3>
            <mat-icon class="back-icon" (click)="showMainPage()">arrow_back</mat-icon>
        </div>

        <ng-container *ngIf="!(referralSubject || referralMessage)">
            <img src="/assets/images/icons/50-euro-bill.png" alt="" id="referral-icon" />

            <!--********** Key phrase **********-->
            <div id="referral-key-phrase">
                50 € für den Geworbenen.
                <br />
                50 € für dich.
            </div>

            <!--********** Referral Details **********-->
            <div id="referral-details-section">
                <h4>Wie funktioniert's?</h4>
                <div id="referral-details-description">
                    Gib uns telefonisch oder per E-Mail Bescheid, wen du geworben hast. Mit Eingang der ersten Zahlung
                    überweisen wir euch beiden je 50 €.
                    <br />
                    Du kannst beliebig viele Personen empfehlen.
                </div>
                <button (click)="openRecommendationEmail()">
                    <mat-icon id="referral-send-icon" class="small-icon">send</mat-icon>
                    Empfehlen
                </button>
            </div>
        </ng-container>

        <div id="referral-message-container" *ngIf="referralSubject || referralMessage">
            <div id="referral-message-warning">
                Es sieht so aus, als ob wir die E-Mail nicht öffnen konnten.
                <br />
                Kopiere am besten diesen Text:
            </div>
            <div id="referral-subject">{{ referralSubject }}</div>
            <div id="referral-message" [innerHTML]="referralMessage"></div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Referral -->
    <!--============================================-->
</div>

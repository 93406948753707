<div class="dialog-overlay entry-dialog-container" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container">
        <h2>WinValue-Inserat verknüpfen</h2>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>

        <!--********** Offer ID **********-->
        <mat-form-field class="mat-block margin-bottom-20">
            <input
                matInput
                axTrim
                [(ngModel)]="winvalueOfferId"
                placeholder="WinValue Objekt-Nummer"
                [axAutofocus]="true" />
            <mat-hint>
                <a
                    class="secondary"
                    href="https://wissen.autoixpert.de/hc/de/articles/6879314205330-WinValue-Restwertinserat-manuell-verkn%C3%BCpfen"
                    target="_blank"
                    rel="”noopener”">
                    Wo finde ich die Objekt-Nummer?
                </a>
            </mat-hint>
        </mat-form-field>

        <!--********** Offer Ready At **********-->
        <div class="date-and-time-group">
            <date-input
                [(date)]="offerReadyAt"
                placeholder="Gebotsfrist"
                matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"></date-input>
            <time-input [(time)]="offerReadyAt"></time-input>
        </div>

        <!--********** Submit Button **********-->
        <div class="dialog-buttons-container">
            <button
                (click)="winvalueOfferId && offerReadyAt && emitObjectNumber()"
                [class.disabled]="!winvalueOfferId || !offerReadyAt"
                [matTooltip]="getSubmitButtonTooltip()">
                Verknüpfen
            </button>
        </div>
    </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatLegacyTooltipModule as MatTooltipModule,
} from '@angular/material/legacy-tooltip';
import { HelpComponent } from './help.component';
import { helpRouting } from './help.routing';
import { HelpcenterComponent } from './helpcenter/helpcenter.component';

@NgModule({
    imports: [helpRouting, CommonModule, MatTooltipModule],
    declarations: [HelpComponent, HelpcenterComponent],
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                /**
                 * Prevent the tooltip text to be selectable with the mouse pointer. This way, it does not stand in the way when the user moves the mouse to click a button currently covered by the tooltip.
                 * Details: https://github.com/angular/components/issues/25173
                 */
                disableTooltipInteractivity: true,
                showDelay: 600,
            },
        },
    ],
})
export class HelpModule {}

import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

const DEFAULT_MAX_RETRIES = 2;
const DEFAULT_BACKOFF_MS = 1000;

// Inspiration taken from https://medium.com/angular-in-depth/retry-failed-http-requests-in-angular-f5959d486294. Steffen Langer 2020-03-23

/**
 * Retry an observable (typically an HTTP request)
 */
export function httpRetry<T = Observable<any>>({
    delayMs,
    maxRetry = DEFAULT_MAX_RETRIES,
    backoffMs = DEFAULT_BACKOFF_MS,
    retryCallback,
}: {
    delayMs: number;
    maxRetry?: number;
    backoffMs?: number;
    retryCallback?: (retryNumber: number) => void;
}): (source: Observable<T>) => Observable<T> {
    let retries = 0;

    return (source: Observable<T>) => {
        return source.pipe(
            retryWhen((errors: Observable<any>) =>
                errors.pipe(
                    mergeMap((error) => {
                        if (retries++ < maxRetry) {
                            const backoffTime = delayMs + retries * backoffMs;
                            if (retryCallback) {
                                retryCallback(retries);
                            }
                            return of(error).pipe(delay(backoffTime));
                        }
                        return throwError(error);
                    }),
                ),
            ),
        );
    };
}

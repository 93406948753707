import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Service to manage the signature & seal of a user.
 */
@Injectable()
export class UserSealService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = `/api/v0/userSeals`;

    public get({
        userId,
        requestOriginal = false,
    }: {
        userId: string;
        requestOriginal?: boolean;
    }): Promise<HttpResponse<Blob>> {
        const sealId = requestOriginal ? `${userId}-original` : userId;

        return this.httpClient
            .get(`${this.apiPath}/${sealId}`, {
                responseType: 'blob',
                observe: 'response',
            })
            .toPromise();
    }

    public remove(userId: string): Promise<any> {
        return this.httpClient.delete<any>(`${this.apiPath}/${userId}`).toPromise();
    }
}

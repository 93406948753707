/**
 * Check if the cursor is positioned within an input element.
 *
 * This method is used to block certain keyboard event handlers if the user is typing.
 */
export function isCursorInInputOrTextarea(): boolean {
    return (
        (document.activeElement && document.activeElement.tagName === 'INPUT') ||
        document.activeElement.tagName === 'TEXTAREA'
    );
}

<div class="valuation-result-label">
    <div class="valuation-result-title">
        {{ valueTitle }}
        <span *ngIf="helpIndicator" class="help-indicator" [matTooltip]="helpIndicator">?</span>
    </div>
    <div class="valuation-result-subtitle label" *ngIf="subtitleText">
        <br />
        <mat-icon *ngIf="subtitleIcon" class="subtitle-icon">{{ subtitleIcon }}</mat-icon>
        <span>{{ subtitleText }}</span>
    </div>
</div>
<div class="valuation-result-amount" [ngClass]="{ 'cursor-pointer': !disabled }">
    <span [ngClass]="{ secondary: primaryValue !== 'net' }" [matTooltip]="tooltipNet">
        {{ valueNet !== 0 ? (valueNet | number: '1.0-0') : '-' }}
        €
    </span>
    <br />
    <span [ngClass]="{ secondary: primaryValue !== 'gross' }" [matTooltip]="tooltipGross">
        {{ valueGross !== 0 ? (valueGross | number: '1.0-0') : '-' }}
        €
    </span>
</div>

<mat-icon *ngIf="showCloseEditorIcon" class="dialog-close-icon" (click)="closeEditor()" matTooltip="Schließen">
    close
</mat-icon>
<!--============================================-->
<!-- PDF Pages Column -->
<!--============================================-->
<div id="pdf-pages-column" [ngClass]="{ 'document-frame': showDocumentFrame }">
    <!--============================================-->
    <!-- PDF Pages -->
    <!--============================================-->
    <!--********** Scroll container needed to position the overlay controls fixed to the pdf pages column. **********-->
    <!--suppress CssUnknownProperty -->
    <div
        id="pdf-scroll-container"
        [ngClass]="{ 'pdf-scroll-container': !parentPdfScrollContainer }"
        #pdfScrollContainer
        (click)="clearSelectionOfPdfElements(); leaveInsertionMode()"
        [style.--zoomFactorInDecimals]="zoomFactorInPercent / 100">
        <div
            id="pdf-pages-container"
            [style.width]="pageWidthInPercent + '%'"
            [ngClass]="{
                'edit-mode': mode === 'edit',
                'preview-mode': mode === 'preview',
                'fill-and-sign-mode': mode === 'fillAndSign',
                'document-frame': showDocumentFrame,
            }">
            <div
                id="page-{{ pdfPage.pageNumber }}"
                class="pdf-page"
                *ngFor="let pdfPage of signablePdfTemplateConfig.pages; let index = index"
                #pdfPageElement
                (click)="
                    placeElementAtClickPosition({
                        pdfElements: pdfElementsInInsertionMode,
                        mouseEvent: $event,
                        pdfPageElement: pdfPageElement,
                    })
                ">
                <img class="pdf-page-background-image" [src]="pageImages[index]" alt="" />

                <!--============================================-->
                <!-- Text Elements -->
                <!--============================================-->
                <div
                    class="pdf-element text-element"
                    *ngFor="let textElement of pdfPage.textElements"
                    [ngClass]="{ selected: this.selectedPdfElements.includes(textElement) }"
                    interactResizable
                    [pageWidth]="pdfPageElement.clientWidth"
                    [pageHeight]="pdfPageElement.clientHeight"
                    [(xPosition)]="textElement.leftRelativeToPageWidth"
                    [(yPosition)]="textElement.topRelativeToPageHeight"
                    [(width)]="textElement.widthRelativeToPageWidth"
                    [(height)]="textElement.heightRelativeToPageHeight"
                    [interactDisabled]="mode === 'fillAndSign'"
                    [style.font-family]="textElement.font + ', sans-serif'"
                    [style.color]="textElement.colorInHex"
                    [style.font-size.px]="
                        getLineHeightRelativeToPageHeight({ lineHeightInPt: textElement.fontSizeInPt }) *
                        pdfPageElement.clientHeight
                    "
                    pdfTextElementInputAnchor
                    #pdfTextElementInputAnchor="pdfTextElementInputAnchor"
                    [textElement]="textElement"
                    (panelOpen)="childPanelIsOpen = true"
                    (panelClose)="childPanelIsOpen = false"
                    [readonly]="mode !== 'edit'"
                    (change)="saveSignablePdfTemplateConfig()"
                    (sizeChange)="saveSignablePdfTemplateConfig()"
                    (dragEnd)="saveSignablePdfTemplateConfig()"
                    (click)="$event.stopPropagation()"
                    (mousedown)="selectPdfElement(textElement, $event)"
                    [matTooltip]="mode === 'edit' ? 'Zum Bearbeiten doppelklicken' : ''"
                    [attr.data-text-element-id]="textElement._id">
                    <div class="text-element-overflow-container">
                        {{ replacePlaceholdersDependingOnMode(textElement.content) }}
                    </div>

                    <!--********** Submenu **********-->
                    <div class="pdf-element-submenu-icon-container" *ngIf="mode === 'edit'">
                        <mat-icon class="small-icon" [matMenuTriggerFor]="textElementSubmenu">more_vert</mat-icon>
                        <mat-menu #textElementSubmenu>
                            <!--********** Edit **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="pdfTextElementInputAnchor.openTextEditPanel()">
                                <mat-icon>edit</mat-icon>
                                Bearbeiten
                            </a>

                            <!--********** Duplicate **********-->
                            <a mat-menu-item class="menu-item-with-icon" (click)="duplicateSelectedPdfElements()">
                                <mat-icon>content_copy</mat-icon>
                                Duplizieren
                            </a>

                            <!--********** Delete **********-->
                            <a mat-menu-item class="menu-item-with-icon" (click)="deleteSelectedPdfElements()">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </a>
                        </mat-menu>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Text Elements -->
                <!--============================================-->

                <!--============================================-->
                <!-- Checkbox Elements -->
                <!--============================================-->
                <div
                    [id]="checkboxElement._id"
                    [attr.data-checkbox-element-id]="checkboxElement._id"
                    class="pdf-element checkbox-element"
                    *ngFor="let checkboxElement of pdfPage.checkboxElements"
                    [ngClass]="{ selected: this.selectedPdfElements.includes(checkboxElement), readonly: isReadonly }"
                    interactResizable
                    [pageWidth]="pdfPageElement.clientWidth"
                    [pageHeight]="pdfPageElement.clientHeight"
                    [(xPosition)]="checkboxElement.leftRelativeToPageWidth"
                    [(yPosition)]="checkboxElement.topRelativeToPageHeight"
                    [(width)]="checkboxElement.widthRelativeToPageWidth"
                    [(height)]="checkboxElement.heightRelativeToPageHeight"
                    (sizeChange)="saveSignablePdfTemplateConfig()"
                    (dragEnd)="saveSignablePdfTemplateConfig()"
                    [aspectRatio]="1"
                    [interactDisabled]="mode === 'fillAndSign'"
                    [style.color]="checkboxElement.colorInHex"
                    pdfCheckboxConfigAnchor
                    #pdfCheckboxConfigAnchor="pdfCheckboxConfigAnchor"
                    [checkboxElement]="checkboxElement"
                    (panelOpen)="childPanelIsOpen = true"
                    (panelClose)="childPanelIsOpen = false"
                    [checkboxIsRequired]="
                        signablePdfTemplateConfig.requiredCheckboxes.includes(
                            checkboxElement.targetProperty || checkboxElement.checkboxPairId || checkboxElement._id
                        )
                    "
                    (checkboxIsRequiredChange)="
                        toggleRequiredCheckbox(checkboxElement); saveSignablePdfTemplateConfig()
                    "
                    (checkboxConfigChange)="saveSignablePdfTemplateConfig()"
                    [panelDisabled]="mode !== 'edit'"
                    (click)="$event.stopPropagation(); toggleCheckboxInFillAndSignMode(checkboxElement)"
                    (mousedown)="selectPdfElement(checkboxElement, $event)">
                    <!-- Tooltip must sit on SVG to prevent tooltip -->
                    <svg
                        *ngIf="mode === 'edit' || getCheckboxValueFromReport(checkboxElement)"
                        [style.fill]="checkboxElement.colorInHex"
                        class="checkbox-checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        [matTooltip]="
                            mode === 'edit'
                                ? 'Zum Bearbeiten doppelklicken'
                                : isReadonly
                                  ? 'Bearbeitung deaktiviert'
                                  : ''
                        ">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                    </svg>

                    <!--********** Requirement Marker **********-->
                    <div class="checkbox-requirement-marker" *ngIf="isCheckboxElementRequired(checkboxElement)">*</div>

                    <!--********** Answer Marker **********-->
                    <div
                        class="checkbox-answer-marker"
                        [ngClass]="{
                            yes: checkboxElement.answer === 'yes',
                            no: checkboxElement.answer === 'no',
                        }"
                        *ngIf="isCheckboxAnswerMarkerVisible(checkboxElement)"
                        matTooltip="Diese Checkbox steht für die Antwort {{
                            checkboxElement.answer === 'yes' ? 'Ja' : 'Nein'
                        }}">
                        <mat-icon *ngIf="checkboxElement.answer === 'yes'">check</mat-icon>
                        <mat-icon *ngIf="checkboxElement.answer === 'no'">close</mat-icon>
                    </div>

                    <!--********** Submenu **********-->
                    <div class="pdf-element-submenu-icon-container" *ngIf="mode === 'edit'">
                        <mat-icon class="small-icon" [matMenuTriggerFor]="checkboxElementSubmenu">more_vert</mat-icon>
                        <mat-menu #checkboxElementSubmenu>
                            <!--********** Edit **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="pdfCheckboxConfigAnchor.openCheckboxConfigPanel()">
                                <mat-icon>edit</mat-icon>
                                Bearbeiten
                            </a>

                            <!--********** Duplicate **********-->
                            <a mat-menu-item class="menu-item-with-icon" (click)="duplicateSelectedPdfElements()">
                                <mat-icon>content_copy</mat-icon>
                                Duplizieren
                            </a>

                            <!--********** Delete **********-->
                            <a mat-menu-item class="menu-item-with-icon" (click)="deleteSelectedPdfElements()">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </a>
                        </mat-menu>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Checkbox Elements -->
                <!--============================================-->

                <!--============================================-->
                <!-- Signature Elements -->
                <!--============================================-->
                <div
                    class="pdf-element signature-element"
                    *ngFor="let signatureElement of pdfPage.signatureElements"
                    [ngClass]="{ selected: this.selectedPdfElements.includes(signatureElement) }"
                    interactResizable
                    [pageWidth]="pdfPageElement.clientWidth"
                    [pageHeight]="pdfPageElement.clientHeight"
                    [(xPosition)]="signatureElement.leftRelativeToPageWidth"
                    [(yPosition)]="signatureElement.topRelativeToPageHeight"
                    [(width)]="signatureElement.widthRelativeToPageWidth"
                    [(height)]="signatureElement.heightRelativeToPageHeight"
                    [interactDisabled]="mode === 'fillAndSign'"
                    (change)="saveSignablePdfTemplateConfig()"
                    (sizeChange)="saveSignablePdfTemplateConfig()"
                    (dragEnd)="saveSignablePdfTemplateConfig()"
                    (click)="$event.stopPropagation()"
                    (mousedown)="selectPdfElement(signatureElement, $event)"
                    [attr.data-signature-element-id]="signatureElement._id">
                    <!--********** Edit & Preview but no preview image loaded yet **********-->
                    <ng-container
                        *ngIf="mode === 'edit' || (mode === 'preview' && !getSignatureObjectUrl(signatureElement._id))">
                        <div class="signature-element-label">
                            <mat-icon class="gesture-icon">gesture</mat-icon>
                            Unterschrift
                        </div>
                        <div class="signature-line-indicator"></div>
                    </ng-container>

                    <!--********** Preview & image loaded **********-->
                    <ng-container *ngIf="mode === 'preview' && getSignatureObjectUrl(signatureElement._id)">
                        <img class="signature-image" [src]="getSignatureObjectUrl(signatureElement._id)" />
                    </ng-container>

                    <!--********** Fill & Sign **********-->
                    <signature-pad
                        [id]="signatureElement._id"
                        *ngIf="mode === 'fillAndSign' && !!claimantSignaturesBySlotId.get(signatureElement._id)"
                        [reportId]="report._id"
                        [claimantSignatures]="claimantSignaturesBySlotId.get(signatureElement._id)"
                        (signatureSaved)="emitSignableDocumentChange()"
                        (signatureEndStroke)="signatureEndStroke.emit($event)"
                        (signatureFilesDeleted)="emitSignableDocumentChange()"
                        (signatureDateChange)="loadPlaceholderValues()"
                        [displayOrientationLine]="false"
                        [style.font-size.px]="
                            getLineHeightRelativeToPageHeight({ lineHeightInPt: fontSize }) *
                            pdfPageElement.clientHeight
                        "
                        [disabledReason]="signaturePadDisabledReason"
                        [canvasWidth]="pdfPageElement.clientWidth * signatureElement.widthRelativeToPageWidth"
                        [canvasHeight]="pdfPageElement.clientHeight * signatureElement.heightRelativeToPageHeight"
                        [signaturePadFullscreenMode]="signaturePadFullscreenMode"
                        [disableSignatureTransfer]="disableSignatureTransfer"
                        (signaturePadFullscreenChange)="signaturePadFullscreenChange.emit($event)"
                        (signaturePadCreated)="signaturePadCreated.emit($event)"
                        (transferSignature)="transferSignature.emit($event)"
                        [frameHighlighted]="true"></signature-pad>

                    <!--********** Submenu **********-->
                    <div class="pdf-element-submenu-icon-container" *ngIf="mode === 'edit'">
                        <mat-icon class="small-icon" [matMenuTriggerFor]="signatureElementSubmenu">more_vert</mat-icon>
                        <mat-menu #signatureElementSubmenu>
                            <!--********** Duplicate **********-->
                            <a mat-menu-item class="menu-item-with-icon" (click)="duplicateSelectedPdfElements()">
                                <mat-icon>content_copy</mat-icon>
                                Duplizieren
                            </a>

                            <!--********** Delete **********-->
                            <a mat-menu-item class="menu-item-with-icon" (click)="deleteSelectedPdfElements()">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </a>
                        </mat-menu>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Signature Elements -->
                <!--============================================-->
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END PDF Pages -->
    <!--============================================-->

    <!--============================================-->
    <!-- Page Counter & Zoom Level-->
    <!--============================================-->
    <div
        id="page-counter-and-zoom-level-container"
        [style.position]="pageCounterAndZoomLevel.position"
        [style.left]="pageCounterAndZoomLevel.left"
        [style.bottom]="pageCounterAndZoomLevel.bottom"
        [style.margin-left]="pageCounterAndZoomLevel.marginLeft"
        [style.margin-top]="pageCounterAndZoomLevel.marginTop">
        <!--********** Page Counter **********-->
        <div id="page-counter-container" class="overlay-control" *ngIf="currentPage">
            <!--********** Previous Page **********-->
            <mat-icon
                id="page-up-control"
                class="overlay-control-icon"
                (click)="scrollToPage('previous')"
                matTooltip="Vorherige Seite">
                arrow_drop_down
            </mat-icon>

            <!--********** Page Number **********-->
            <div class="overlay-control-text">
                {{ currentPage.pageNumber }} von {{ signablePdfTemplateConfig.pages.length }}
            </div>

            <!--********** Next Page **********-->
            <mat-icon class="overlay-control-icon" (click)="scrollToPage('next')" matTooltip="Nächste Seite">
                arrow_drop_down
            </mat-icon>
        </div>

        <!--********** Zoom **********-->
        <div id="zoom-control-container" class="overlay-control">
            <!--********** Zoom out **********-->
            <mat-icon class="overlay-control-icon" (click)="decreaseZoom()" matTooltip="Ansicht verkleinern">
                remove
            </mat-icon>

            <!--********** Zoom Percentage **********-->
            <div
                class="overlay-control-text cursor-pointer"
                (click)="zoomFactorInPercent = 100"
                matTooltip="Aktuelle Zoom-Stufe. Klicken, für 100% Zoom.">
                {{ zoomFactorInPercent | number: '1.0-0' }} %
            </div>

            <!--********** Zoom in **********-->
            <mat-icon class="overlay-control-icon" (click)="increaseZoom()" matTooltip="Ansicht vergrößern">
                add
            </mat-icon>
        </div>
    </div>
    <!--============================================-->
    <!-- END Page Counter & Zoom Level-->
    <!--============================================-->

    <!--============================================-->
    <!-- Mode Switcher -->
    <!--============================================-->
    <div id="mode-switcher" class="overlay-control" *ngIf="mode === 'edit' || mode === 'preview'">
        <!--********** Edit Mode **********-->
        <div class="mode-switch-option-container" (click)="selectMode('edit')" [ngClass]="{ active: mode === 'edit' }">
            <mat-icon
                class="overlay-control-icon"
                matTooltip="Text-Elemente, Checkboxen und Unterschriftsfelder bearbeiten">
                edit
            </mat-icon>
        </div>

        <!--********** Preview Mode **********-->
        <div
            class="mode-switch-option-container"
            (click)="selectMode('preview')"
            [ngClass]="{ active: mode === 'preview' }">
            <mat-icon class="overlay-control-icon" matTooltip="Vorschau mit Werten aus einem eigenen Gutachten">
                visibility
            </mat-icon>
        </div>
    </div>
    <!--============================================-->
    <!-- END Mode Switcher -->
    <!--============================================-->

    <!--============================================-->
    <!-- No PDF File Uploaded (Error Message) -->
    <!--============================================-->
    <div id="no-pdf-file-uploaded-error-message" *ngIf="!signablePdfTemplateConfig.pdfUploaded">
        <div class="info-note error-note">
            <mat-icon>error</mat-icon>
            <div>
                <div class="bold">Keine PDF-Datei hochgeladen</div>
                <div>Bitte lade für diese Vorlage eine PDF-Datei in den Einstellungen hoch.</div>
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END No PDF File Uploaded (Error Message) -->
    <!--============================================-->
</div>
<!--============================================-->
<!-- END PDF Pages Column -->
<!--============================================-->

<!--============================================-->
<!-- Tools & Preview Selector Column -->
<!--============================================-->
<div id="tools-and-preview-selector-column" *ngIf="areToolsVisible">
    <!--============================================-->
    <!-- Tools -->
    <!--============================================-->
    <div id="tools-container" *ngIf="mode === 'edit'">
        <h2>Bausteine</h2>

        <!--============================================-->
        <!-- Claimant -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="claimantToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">person</mat-icon>
            </div>

            <div class="tool-name">Anspruchsteller</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #claimantToolDropdown>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.NameKomplett}')">Vollständiger Name</a>
                <a
                    mat-menu-item
                    (click)="
                        createTextElement(
                            '{Anspruchsteller.NameKomplett}\n{Anspruchsteller.Straße}\n{Anspruchsteller.Postleitzahl} {Anspruchsteller.Ort}'
                        )
                    ">
                    Vollständige Adresse
                </a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Vorname}')">Vorname</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Nachname}')">Nachname</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Organisation}')">Organisation</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Straße}')">Straße</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Postleitzahl}')">Postleitzahl</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Ort}')">Ort</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Telefon}')">Telefon</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Telefon2}')">Telefon 2</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.EMail}')">E-Mail</a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Kennzeichen}')">Kennzeichen</a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Claimant -->
        <!--============================================-->

        <!--============================================-->
        <!-- Author of Damage -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="authorOfDamageToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">warning</mat-icon>
            </div>

            <div class="tool-name">Unfallgegner</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #authorOfDamageToolDropdown>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.NameKomplett}')">Vollständiger Name</a>
                <a
                    mat-menu-item
                    (click)="
                        createTextElement(
                            '{Unfallgegner.NameKomplett}\n{Unfallgegner.Straße}\n{Unfallgegner.Postleitzahl} {Unfallgegner.Ort}'
                        )
                    ">
                    Vollständige Adresse
                </a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Vorname}')">Vorname</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Nachname}')">Nachname</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Organisation}')">Organisation</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Straße}')">Straße</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Postleitzahl}')">Postleitzahl</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Ort}')">Ort</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Telefon}')">Telefon</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Telefon2}')">Telefon 2</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.EMail}')">E-Mail</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Kennzeichen}')">Kennzeichen</a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Author of Damage -->
        <!--============================================-->

        <!--============================================-->
        <!-- Insurance -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="insuranceToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">shield</mat-icon>
            </div>

            <div class="tool-name">Versicherung</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #insuranceToolDropdown>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.Organisation}')">
                    Organisation
                </a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.Straße}')">Straße</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.Postleitzahl}')">
                    Postleitzahl
                </a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.Ort}')">Ort</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.Telefon}')">Telefon</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.Telefon2}')">Telefon 2</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.EMail}')">E-Mail</a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.Schadennummer}')">
                    Schadennummer
                </a>
                <a mat-menu-item (click)="createTextElement('{Unfallgegner.Versicherung.Versicherungsnummer}')">
                    Versicherungsnummer
                </a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Insurance -->
        <!--============================================-->

        <!--============================================-->
        <!-- Accident -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="accidentToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">flare</mat-icon>
            </div>

            <div class="tool-name">Unfall</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #accidentToolDropdown>
                <a mat-menu-item (click)="createTextElement('{Unfall.Ort}')">Ort</a>
                <a mat-menu-item (click)="createTextElement('{Unfall.Datum}')">Datum</a>
                <a mat-menu-item (click)="createTextElement('{Unfall.Uhrzeit}')">Uhrzeit</a>
                <a mat-menu-item (click)="createTextElement('{Unfall.AktenzeichenPolizei}')">Aktenzeichen Polizei</a>
                <a mat-menu-item (click)="createTextElement('{Unfall.Polizeibehörde}')">Polizeibehörde</a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Accident -->
        <!--============================================-->

        <!--============================================-->
        <!-- Vehicle -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="vehicleToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">directions_car</mat-icon>
            </div>

            <div class="tool-name">Fahrzeug</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #vehicleToolDropdown>
                <a mat-menu-item (click)="createTextElement('{Fahrzeug.Hersteller} {Fahrzeug.Modell}')">
                    Hersteller & Modell
                </a>
                <a mat-menu-item (click)="createTextElement('{Anspruchsteller.Kennzeichen}')">Kennzeichen</a>
                <a mat-menu-item (click)="createTextElement('{Fahrzeug.VIN}')">VIN</a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Vehicle -->
        <!--============================================-->

        <!--============================================-->
        <!-- Assessor -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="assessorToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">assignment_ind</mat-icon>
            </div>

            <div class="tool-name">Sachverständiger</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #assessorToolDropdown>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.NameKomplett}')">Vollständiger Name</a>
                <a
                    mat-menu-item
                    (click)="
                        createTextElement(
                            '{Sachverständiger.NameKomplett}\n{Sachverständiger.Straße}\n{Sachverständiger.Postleitzahl} {Sachverständiger.Ort}'
                        )
                    ">
                    Vollständige Adresse
                </a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.Vorname}')">Vorname</a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.Nachname}')">Nachname</a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.Organisation}')">Organisation</a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.Straße}')">Straße</a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.Postleitzahl}')">Postleitzahl</a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.Ort}')">Ort</a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.Telefon}')">Telefon</a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.Telefon2}')">Telefon 2</a>
                <a mat-menu-item (click)="createTextElement('{Sachverständiger.EMail}')">E-Mail</a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Assessor -->
        <!--============================================-->

        <!--============================================-->
        <!-- Report -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="reportToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">assignment</mat-icon>
            </div>

            <div class="tool-name">Gutachten</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #reportToolDropdown>
                <a mat-menu-item (click)="createTextElement('{Gutachten.Aktenzeichen}')">Aktenzeichen</a>
                <a mat-menu-item (click)="createTextElement('{Gutachten.Auftragsdatum}')">Auftragsdatum</a>
                <a mat-menu-item (click)="createTextElement('{Gutachten.Auftraggeber}')">Auftraggeber</a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Report -->
        <!--============================================-->

        <!--============================================-->
        <!-- Other Placeholders -->
        <!--============================================-->
        <div class="tool" (click)="createTextElement('')" matTooltip="Beliebige Platzhalter aus dem Gutachten einfügen">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">more_horiz</mat-icon>
            </div>

            <div class="tool-name">Weitere</div>
        </div>
        <!--============================================-->
        <!-- END Other Placeholders -->
        <!--============================================-->

        <!--============================================-->
        <!-- Font Face, Color & Size -->
        <!--============================================-->
        <div id="font-face-color-and-size-row">
            <!--********** Font Face Selector **********-->
            <mat-form-field
                id="font-family-form-field"
                [appearance]="'outline'"
                matTooltip="Wähle eine der vorgegebenen Schriftarten für Text-Elemente">
                <mat-label>Schriftart</mat-label>
                <mat-select
                    [(ngModel)]="fontFamily"
                    (ngModelChange)="updateFontFamily(); saveSignablePdfTemplateConfig()"
                    [disabled]="selectedPdfElements && !filterElementsSupportingFontStyling(selectedPdfElements)">
                    <mat-option [value]="'Arial'">Arial</mat-option>
                    <mat-option [value]="'Source Sans Pro'">Source Sans Pro</mat-option>
                </mat-select>
            </mat-form-field>

            <!--********** Color **********-->
            <div
                id="color-container"
                [style.border-color]="fontColor"
                [ngClass]="{
                    disabled: selectedPdfElements && !filterElementsSupportingColorStyling(selectedPdfElements),
                }">
                <div
                    id="color-patch"
                    [style.background-color]="fontColor"
                    (click)="colorInput.click()"
                    matTooltip="Farbe für neue Text-Elemente oder Checkboxen. Falls ein vorhandenes Element selektiert ist, kannst du dessen Farbe anpassen."></div>
                <input
                    type="color"
                    #colorInput
                    [(ngModel)]="fontColor"
                    (change)="updateColor(); saveSignablePdfTemplateConfig()"
                    [disabled]="selectedPdfElements && !filterElementsSupportingColorStyling(selectedPdfElements)" />
            </div>

            <!--********** Font Size Selector **********-->
            <mat-form-field
                id="font-size-form-field"
                [appearance]="'outline'"
                matTooltip="Größe des Textes in Punkt (Pt). Entspricht der Textgröße in MS Word.">
                <mat-label>Größe</mat-label>
                <input
                    matInput
                    number-input
                    [(number)]="fontSize"
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="1"
                    [matAutocomplete]="fontSizeAutocomplete"
                    (numberChange)="updateFontSize(); saveSignablePdfTemplateConfig()"
                    [disabled]="selectedPdfElements && !filterElementsSupportingFontStyling(selectedPdfElements)" />
                <mat-autocomplete
                    (optionSelected)="setLocalFontSize($event.option.value); updateFontSize()"
                    #fontSizeAutocomplete>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="9">9</mat-option>
                    <mat-option value="10">10</mat-option>
                    <mat-option value="11">11</mat-option>
                    <mat-option value="12">12</mat-option>
                    <mat-option value="14">14</mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <!--============================================-->
        <!-- END Font Face, Color & Size -->
        <!--============================================-->

        <!--============================================-->
        <!-- Checkbox -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="checkboxToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">check_box</mat-icon>
            </div>

            <div class="tool-name">Checkbox</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>
        </div>
        <mat-menu #checkboxToolDropdown>
            <!--============================================-->
            <!-- Claimant May Deduct VAT -->
            <!--============================================-->
            <a mat-menu-item [matMenuTriggerFor]="mayDeductVatSubmenu">Vorsteuerabzugsberechtigt</a>

            <!--********** Submenu - May Deduct VAT **********-->
            <mat-menu #mayDeductVatSubmenu>
                <!--********** Yes **********-->
                <a
                    mat-menu-item
                    (click)="createCheckboxElement({ targetProperty: 'claimantMayDeductVat', answer: 'yes' })"
                    [matTooltip]="CHECKBOX_YES_OR_NO_MENU_ITEM_TOOLTIP">
                    <mat-icon>check</mat-icon>
                    Ja
                </a>

                <!--********** No **********-->
                <a
                    mat-menu-item
                    (click)="createCheckboxElement({ targetProperty: 'claimantMayDeductVat', answer: 'no' })"
                    [matTooltip]="CHECKBOX_YES_OR_NO_MENU_ITEM_TOOLTIP">
                    <mat-icon>close</mat-icon>
                    Nein
                </a>
            </mat-menu>
            <!--============================================-->
            <!-- END Claimant May Deduct VAT -->
            <!--============================================-->

            <!--============================================-->
            <!-- Previous Damages -->
            <!--============================================-->
            <a mat-menu-item [matMenuTriggerFor]="previousDamagesSubmenu">Vorschäden vorhanden</a>

            <!--********** Submenu - Previous Damages **********-->
            <mat-menu #previousDamagesSubmenu>
                <!--********** Yes **********-->
                <a
                    mat-menu-item
                    (click)="createCheckboxElement({ targetProperty: 'vehicleHasPreviousDamages', answer: 'yes' })"
                    [matTooltip]="CHECKBOX_YES_OR_NO_MENU_ITEM_TOOLTIP">
                    <mat-icon>check</mat-icon>
                    Ja
                </a>

                <!--********** No **********-->
                <a
                    mat-menu-item
                    (click)="createCheckboxElement({ targetProperty: 'vehicleHasPreviousDamages', answer: 'no' })"
                    [matTooltip]="CHECKBOX_YES_OR_NO_MENU_ITEM_TOOLTIP">
                    <mat-icon>close</mat-icon>
                    Nein
                </a>
            </mat-menu>
            <!--============================================-->
            <!-- END Previous Damages -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Checkboxes -->
            <!--============================================-->
            <a
                mat-menu-item
                (click)="createCheckboxElement({ answer: 'yes' })"
                matTooltip="Nutze freie Checkboxen, um beliebige Bestätigungen deiner Kunden einzuholen. Du kannst sie als Pflichtfelder markieren.">
                Freie Checkbox
            </a>
            <!--============================================-->
            <!-- END Custom Checkboxes -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Checkbox Pairs -->
            <!--============================================-->
            <a
                mat-menu-item
                (click)="createCheckboxPairElements()"
                matTooltip="Nutze ein Checkbox-Paar, um eine Ja/Nein-Entscheidung zu ermöglichen. Du kannst sie als Pflichtfelder markieren.">
                Checkbox-Paar Ja/Nein
            </a>
            <!--============================================-->
            <!-- END Custom Checkbox Pairs -->
            <!--============================================-->
        </mat-menu>
        <!--============================================-->
        <!-- END Checkbox -->
        <!--============================================-->

        <!--============================================-->
        <!-- Signature -->
        <!--============================================-->
        <div class="tool" [matMenuTriggerFor]="signatureToolDropdown">
            <div class="tool-icon-container">
                <mat-icon class="tool-icon">gesture</mat-icon>
            </div>

            <div class="tool-name">Unterschrift</div>

            <!--********** Dropdown **********-->
            <div class="tool-dropdown-icon-container">
                <mat-icon>arrow_drop_down</mat-icon>
            </div>

            <mat-menu #signatureToolDropdown>
                <a mat-menu-item (click)="createSignatureElement()" matTooltip="Feld für die Unterschrift">
                    Unterschrift
                </a>
                <a
                    mat-menu-item
                    (click)="createTextElement('{UnterschriftKundeDatum}')"
                    matTooltip="Datum, wann die Unterschrift geleistet wurde.">
                    Datum
                </a>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Signature -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Tools -->
    <!--============================================-->

    <!--============================================-->
    <!-- Select Preview Report -->
    <!--============================================-->
    <ng-container *ngIf="mode === 'preview'">
        <!-- Select a report to preview its data within this document. -->
        <h2>Vorschau</h2>

        <div class="text-center label margin-bottom-30">
            Prüfe dein Layout, indem du die Daten aus einem Gutachten einfügen lässt.
        </div>

        <!--********** Report Selector **********-->
        <report-selector
            [reportId]="report?._id"
            (reportLoad)="handleLoadingReportForPreview($event)"
            (reportDisconnection)="clearReportForPreview()"></report-selector>
    </ng-container>
    <!--============================================-->
    <!-- END Select Preview Report -->
    <!--============================================-->
</div>
<!--============================================-->
<!-- END Tools & Preview Selector Column -->
<!--============================================-->

<!--============================================-->
<!-- PDF Element in Insertion Mode -->
<!--============================================-->
<!--
As soon as a toolbar element is clicked, a PDF element is generated and will be
attached to the cursor until the user chooses the final position by clicking.
 -->
<ng-container
    *ngFor="let pdfElementInInsertionMode of pdfElementsInInsertionMode; let index = index; trackBy: trackById">
    <div
        id="pdf-element-in-insertion-mode"
        *ngIf="pdfElementInInsertionMode"
        [ngSwitch]="pdfElementInInsertionMode.type"
        [style.top.px]="pdfElementsInInsertionMode_yPosition"
        [style.left.px]="pdfElementsInInsertionMode_xPosition + index * 30"
        [style.font-family]="$any(pdfElementInInsertionMode).font + ', sans-serif'"
        [style.color]="$any(pdfElementInInsertionMode).colorInHex"
        @fadeIn>
        <!--********** Text **********-->
        <div
            class="pdf-element text-element"
            *ngSwitchCase="'text'"
            [style.width.px]="getDefaultTextElementWidthInPx()"
            [style.height.px]="getTextElementHeightInPx($any(pdfElementInInsertionMode))"
            [style.font-size.px]="
                getLineHeightRelativeToPageHeight({ lineHeightInPt: fontSize }) * currentPageDivElement.clientHeight
            "
            [style.font-family]="fontFamily + ', sans-serif'">
            {{ $any(pdfElementInInsertionMode).content || 'Mein Platzhalter' }}
        </div>

        <!--********** Checkbox **********-->
        <div
            [id]="pdfElementInInsertionMode._id"
            class="pdf-element checkbox-element"
            [ngClass]="{ readonly: isReadonly }"
            [matTooltip]="isReadonly ? 'Bearbeitung deaktiviert' : ''"
            *ngSwitchCase="'checkbox'"
            [style.width.px]="
                DEFAULT_CHECKBOX_ELEMENT_WIDTH_IN_PERCENT * currentPageDivElement.clientWidth + 2 * BORDER_WIDTH_IN_PX
            "
            [style.height.px]="
                DEFAULT_CHECKBOX_ELEMENT_WIDTH_IN_PERCENT * currentPageDivElement.clientWidth + 2 * BORDER_WIDTH_IN_PX
            ">
            <svg class="checkbox-checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
            </svg>
        </div>

        <!--********** Signature **********-->
        <div
            class="pdf-element signature-element preview"
            *ngSwitchCase="'signature'"
            [style.width.px]="
                DEFAULT_SIGNATURE_ELEMENT_WIDTH_IN_PERCENT * currentPageDivElement.clientWidth + 2 * BORDER_WIDTH_IN_PX
            "
            [style.height.px]="
                ((DEFAULT_SIGNATURE_ELEMENT_WIDTH_IN_PERCENT * currentPageDivElement.clientWidth) / 16) * 9 +
                2 * BORDER_WIDTH_IN_PX
            ">
            <mat-icon class="gesture-icon">gesture</mat-icon>
            Unterschrift
        </div>
    </div>
</ng-container>
<!--============================================-->
<!-- END PDF Element in Insertion Mode -->
<!--============================================-->

import { ContactPerson } from '../../models/contacts/contact-person';
import { enableLineBreaks } from './enable-line-breaks';
import { getDeclinedSalutation } from './get-declined-salutation';
import { getFullRecipientName } from './get-full-recipient-name';
import { getLetterSalutation } from './get-letter-salutation';
import { TristateGerman } from './placeholder-values.types';
import { Translator } from './translator';

export function getContactPersonPlaceholderObject(contactPerson: ContactPerson): ContactPersonGerman {
    const fullRecipientName: string[] = getFullRecipientName({ contactPerson: contactPerson });
    const fullRecipientNameDeclined: string[] = getFullRecipientName({ contactPerson, salutationDeclined: true });

    //*****************************************************************************
    //  Letter Window
    //****************************************************************************/
    const recipientAddressName = getFullRecipientName({ contactPerson, salutationDeclined: true, letterWindow: true });
    const addressParts = [...recipientAddressName, contactPerson.streetAndHouseNumberOrLockbox || ''];
    if (contactPerson.addressAddition) {
        addressParts.push(contactPerson.addressAddition);
    }
    addressParts.push((contactPerson.zip || '') + ' ' + (contactPerson.city || ''));
    if (contactPerson.country && contactPerson.country !== 'DE') {
        addressParts.push(contactPerson.country);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Letter Window
    /////////////////////////////////////////////////////////////////////////////*/

    return {
        Brieffenster: enableLineBreaks(addressParts.join('\n')),
        Anrede: contactPerson.salutation || '',
        AnredeDekliniert: getDeclinedSalutation(contactPerson.salutation) || '',
        Briefanrede: getLetterSalutation(contactPerson as ContactPerson) || '',
        Vorname: contactPerson.firstName || '',
        Nachname: contactPerson.lastName || '',
        Organisation: contactPerson.organization || '',
        NameKomplett: fullRecipientName.length ? enableLineBreaks(fullRecipientName.join('\n').trim()) : '',
        NameKomplettDekliniertUndEinzeilig: fullRecipientNameDeclined.length
            ? fullRecipientNameDeclined.join(', ').trim()
            : '',
        Straße: contactPerson.streetAndHouseNumberOrLockbox || '',
        Postleitzahl: contactPerson.zip || '',
        Ort: contactPerson.city || '',
        Telefon: contactPerson.phone || '',
        Telefon2: contactPerson.phone2 || '',
        EMail: contactPerson.email || '',
        Website: contactPerson.website || '',
        UmsatzsteuerId: contactPerson.vatId || '',
        istVorsteuerabzugsberechtigt: Translator.tristate(contactPerson.mayDeductTaxes),
        Notizen: contactPerson.notes || '',
    };
}

export interface ContactPersonGerman {
    Brieffenster: string;
    Anrede: string;
    AnredeDekliniert: string;
    Briefanrede: string;
    Vorname: string;
    Nachname: string;
    Organisation: string;
    NameKomplett: string;
    NameKomplettDekliniertUndEinzeilig: string;
    Straße: string;
    Postleitzahl: string;
    Ort: string;
    Telefon: string;
    Telefon2: string;
    EMail: string;
    Website: string;
    UmsatzsteuerId: string;
    istVorsteuerabzugsberechtigt: TristateGerman;
    Notizen: string;
}

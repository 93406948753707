// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
// Other
import { Subscription } from 'rxjs';
import { filter, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { ResponsibleInsuranceResponse } from '@autoixpert/external-apis/gdv/responsible-insurance-response';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { getContactPersonFullNameWithOrganization } from '@autoixpert/lib/contact-people/get-contact-person-full-name-with-organization';
import { CounterPattern, ReportTokenOrInvoiceNumberPlaceholderValues } from '@autoixpert/lib/counters/counter-pattern';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { generateId } from '@autoixpert/lib/generate-id';
import { ClaimantDenominationGerman, Translator } from '@autoixpert/lib/placeholder-values/translator';
import { addReportTypeSpecificDocuments } from '@autoixpert/lib/reports/add-report-type-specific-documents';
import { shouldPowerOfAttorneyDocumentBeVisible } from '@autoixpert/lib/signable-documents/should-power-of-attorney-document-be-visible';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { isGdvUserComplete } from '@autoixpert/lib/users/is-gdv-user-complete';
import { calculateValuationValues } from '@autoixpert/lib/valuation/calculate-valuation-values';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { SignableDocumentType } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { LegacyReport } from '@autoixpert/models/imports/legacy-report';
import { KaskoDamageType } from '@autoixpert/models/reports/accident';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { Visit } from '@autoixpert/models/reports/damage-description/visit';
import { Claimant } from '@autoixpert/models/reports/involved-parties/claimant';
import { InvolvedParty } from '@autoixpert/models/reports/involved-parties/involved-party';
// aX Type interfaces
import { Report } from '@autoixpert/models/reports/report';
import { InvoiceNumberConfig, ReportTokenConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { User } from '@autoixpert/models/user/user';
import { checkIfOfficeLocationComplete } from 'src/app/shared/libraries/check-if-office-location-complete';
import { CarEquipmentService } from 'src/app/shared/services/car-equipment.service';
import { GoogleMapsGeocodeService } from 'src/app/shared/services/google/google-maps-geocode.service';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { fadeOutAnimation } from '../../../shared/animations/fade-out.animation';
import { runChildAnimations } from '../../../shared/animations/run-child-animations.animation';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { getInvoiceNumberOrReportTokenCounterErrorHandlers } from '../../../shared/libraries/error-handlers/get-invoice-number-or-report-token-counter-error-handlers';
import { getGdvErrorHandlers } from '../../../shared/libraries/gdv/get-gdv-error-handlers';
import { getLicensePlateForGdvResponsibleInsuranceQuery } from '../../../shared/libraries/gdv/get-license-plate-for-gdv-responsible-insurance-query';
import { getCopyableCarProperties } from '../../../shared/libraries/get-copyable-car-properties';
import { getMissingAccessRightTooltip } from '../../../shared/libraries/get-missing-access-right-tooltip';
import { isKaskoCase } from '../../../shared/libraries/report-properties/is-kasko-case';
import { trackById } from '../../../shared/libraries/track-by-id';
import { triggerClickEventOnSpaceBarPress } from '../../../shared/libraries/trigger-click-event-on-space-bar-press';
import { hasAccessRight } from '../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { ContactPersonService } from '../../../shared/services/contact-person.service';
import { CustomAutocompleteEntriesService } from '../../../shared/services/custom-autocomplete-entries.service';
import { DocumentOrderConfigService } from '../../../shared/services/document-order-config.service';
import { FieldGroupConfigService } from '../../../shared/services/field-group-config.service';
import { GdvResponsibleInsuranceService } from '../../../shared/services/gdv/gdv-responsible-insurance.service';
import { InvoiceNumberService } from '../../../shared/services/invoice-number.service';
import { LegacyReportService } from '../../../shared/services/legacy-report.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { ReportTokenAndInvoiceNumberService } from '../../../shared/services/report-token-and-invoice-number.service';
import { ReportTokenService } from '../../../shared/services/report-token.service';
// aX Services
import { ReportService } from '../../../shared/services/report.service';
import { ScreenTitleService } from '../../../shared/services/screen-title.service';
import { TeamService } from '../../../shared/services/team.service';
import { TemplatePlaceholderValuesService } from '../../../shared/services/template-placeholder-values.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { UserService } from '../../../shared/services/user.service';
import { CustomerSignaturesCardComponent } from './customer-signatures-card/customer-signatures-card.component';

@Component({
    selector: 'accident-data',
    templateUrl: 'accident-data.component.html',
    styleUrls: ['accident-data.component.scss'],
    animations: [fadeOutAnimation(), fadeInAndOutAnimation(), slideInAndOutVertically(), runChildAnimations()],
})
export class AccidentDataComponent implements OnInit, OnDestroy {
    constructor(
        private reportService: ReportService,
        private reportDetailsService: ReportDetailsService,
        private router: Router,
        private route: ActivatedRoute,
        private screenTitleService: ScreenTitleService,
        private toastService: ToastService,
        private contactPersonService: ContactPersonService,
        private loggedInUserService: LoggedInUserService,
        private apiErrorService: ApiErrorService,
        public userPreferences: UserPreferencesService,
        private reportTokenService: ReportTokenService,
        private invoiceNumberService: InvoiceNumberService,
        private reportTokenAndInvoiceNumberService: ReportTokenAndInvoiceNumberService,
        private userService: UserService,
        private teamService: TeamService,
        private legacyReportService: LegacyReportService,
        private gdvResponsibleInsuranceService: GdvResponsibleInsuranceService,
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        private networkStatusService: NetworkStatusService,
        private carEquipmentService: CarEquipmentService,
        private googleMapsGeocodeService: GoogleMapsGeocodeService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private fieldGroupConfigService: FieldGroupConfigService,
        protected changeDetectorRef: ChangeDetectorRef,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    // General properties
    user: User;
    team: Team;
    report: Report;

    public claimantsFromOpenReports: ContactPerson[] = [];
    public lawyersFromOpenReports: ContactPerson[] = [];
    public insurancesFromOpenReports: ContactPerson[] = [];
    public garagesFromOpenReports: ContactPerson[] = [];
    public sellersFromOpenReports: ContactPerson[] = [];
    public leaseProvidersFromOpenReports: ContactPerson[] = [];
    public intermediariesFromOpenReports: ContactPerson[] = [];

    // These three viewChildern are necessary to autofocus the inputs when the checkbox is clicked
    @ViewChild('vatIdInputClaimant', { static: false }) public vatIdInputClaimant: ElementRef;
    @ViewChild('responsibleAssessorSelect', { static: false }) public responsibleAssessorSelect: MatSelect;
    @ViewChild('policeCaseNumberInput', { static: false }) public policeCaseNumberInput: ElementRef;
    @ViewChild(CustomerSignaturesCardComponent, { static: false })
    public customerSignaturesCardComponent: CustomerSignaturesCardComponent;

    @Input() autocompleteEntryType: CustomAutocompleteEntry;

    // Amendment Reports
    public originalReport: Report;
    public amendmentReport: Report;
    public showAmendmentReasonTextTemplateSelector = false;

    // Car Registration Scanner
    public carRegistrationScannerDialogShown = false;

    // Previous Report Validation
    public previousReports: Report[] = [];
    public legacyReports: LegacyReport[] = [];

    // Fields related to generating report token
    @ViewChild('reportTokenElement', { static: false }) public reportTokenElement: ElementRef = null;
    public generateReportTokenRequestPending: boolean = false;
    // True if the report token was set but the invoice number was already defined.
    public wasAdjustingInvoiceNumberToReportTokenPrevented: boolean = false;

    // Ordering Methods
    public orderingMethodOptions: { value: Report['orderingMethod']; label: string }[] = [
        {
            value: null,
            label: '',
        },
        {
            value: 'personal',
            label: 'persönlich',
        },
        {
            value: 'phone',
            label: 'telefonisch',
        },
        {
            value: 'written',
            label: 'schriftlich',
        },
    ];

    // Intermediaries
    public intermediaryManagementDialogShown: boolean;

    // Kasko Damage
    public kaskoDamageTypes: { value: KaskoDamageType; label: string; imageName: string }[] = [
        {
            value: 'animal',
            label: 'Wild',
            imageName: 'venison_48.png',
        },
        {
            value: 'fire',
            label: 'Brand',
            imageName: 'fire_48.png',
        },
        {
            value: 'theft',
            label: 'Diebstahl',
            imageName: 'theft_48.png',
        },
        {
            value: 'glass',
            label: 'Einbruch',
            imageName: 'glass_48.png',
        },
        {
            value: 'hail',
            label: 'Hagel',
            imageName: 'hail_48.png',
        },
        {
            value: 'other',
            label: 'Andere',
            imageName: 'add-grey_48.png',
        },
    ];

    // Visits
    public assessors: User[] = [];
    public deactivatedAssessors: User[] = [];
    public visitLocationFavoritesDialogShown: boolean = false;
    public visitForFavoritesDialog: Visit;

    public showOtherPeoplePresentAtTheseVisits: Visit[] = []; // This array keeps track of the visits whose other-people-present-sections are expanded

    public auxiliaryDevicesAutocompleteEntries: CustomAutocompleteEntry[] = [];
    public filteredAuxiliaryDevicesAutocompleteEntries: CustomAutocompleteEntry[] = [];
    @ViewChild('auxiliaryDevicesAutocomplete', { static: false }) auxiliaryDevicesAutocomplete: MatAutocomplete = null;

    // Visit Comment
    showVisitCommentTextTemplateSelector = new Map<Visit['_id'], boolean>();
    protected getCurrentLocationForVisitPending = false;

    // Other People Present
    @ViewChild('otherPeoplePresentAutocomplete', { static: false }) otherPeoplePresentAutocomplete: MatAutocomplete =
        null;
    public otherPeoplePresentAutocompleteEntries: CustomAutocompleteEntry[] = [];
    public filteredOtherPeoplePresentAutocompleteEntries: CustomAutocompleteEntry[] = [];

    // GDV
    public gdvResponsibleInsuranceRequestPending: boolean;

    private subscriptions: Subscription[] = [];
    private responsibleAssessorChangeSubscription: Subscription;

    private documentOrderConfigs: DocumentOrderConfig[] = [];

    /**
     * When the user enters a license plate, we check if reports with the same license plate exist.
     * This is a helper variable to keep track of the last license plate we checked, so we don't check
     * it again on the next blur event of the license plate input.
     */
    private previousLicensePlateChecked: string = null;

    /**
     * When the user selects a garage as intermediary or visit location, suggest to use that contact person as garage also.
     */
    protected showInsertIntermediaryAsGarageInfoNote: boolean = false;
    protected contactPersonToPossiblyInsertAsGarage: ContactPerson;
    protected insertIntermediaryAsGarageIncludeVisitLocation: boolean = true;
    protected showInsertVisitLocationContactAsGarageInfoNote: boolean = false;
    protected visitLocationContactPersonToPossiblyInsertAsGarage: ContactPerson;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        // Must be there before setting the default office location.
        this.loadAssessorsFromTeam();

        const reportSubscription = this.route.parent.params
            .pipe(switchMap((params) => this.reportDetailsService.get(params['reportId'])))
            .subscribe(
                (report) => {
                    this.report = report;
                    this.getOriginalReport();
                    this.getAmendmentReport();
                    this.clearPreviousAndLegacyReports();
                    this.findPreviousAndLegacyReports();
                    this.showVisitsWithOtherPeoplePresent();
                    this.changeVisitingAssessorOnResponsibleAssessorChange();

                    // Must be called after report has been loaded to exclude the current report's contacts.
                    this.loadContactPeopleFromOpenReports();

                    this.joinAsRealtimeEditor();
                },
                (error) => {
                    console.error('Gutachten konnte nicht geladen werden.', { error });
                    this.toastService.error('Gutachten nicht geladen', 'Fehler beim Laden des Gutachtens.');
                },
            );

        this.retrieveAuxiliaryDeviceEntries();
        this.retrieveOtherPeoplePresentEntries();

        this.documentOrderConfigs =
            await this.documentOrderConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        this.subscriptions.push(reportSubscription);
    }

    public setScreenTitle() {
        this.screenTitleService.setScreenTitleForReport(this.report);
    }

    private loadAssessorsFromTeam() {
        const allAssessors = this.userService.getAllTeamMembersFromCache();

        this.assessors = allAssessors.filter((teamMember) => teamMember.active && teamMember.isAssessor);
        this.deactivatedAssessors = allAssessors.filter((teamMember) => !teamMember.active && teamMember.isAssessor);

        /**
         * If not a single assessor could be found, the service must still be waiting for the server response. Try again later.
         */
        if (!this.assessors.length) {
            window.setTimeout(() => this.loadAssessorsFromTeam(), 500);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    ///////////////////////////////////////////////////////////////////////////////
    // Claimant's IBAN Value
    //////////////////////////////////////////////////////////////t/////////////////

    /**
     * Toggle hide the input for the IBAN that assessors may take down for the involved lawyers.
     */
    public toggleClaimantIban(): void {
        // When hiding, notify the user that the input stays visible if there's a value.
        if (this.userPreferences.claimantIbanShown && this.report.claimant.contactPerson.iban) {
            this.toastService.info(
                'IBAN bleibt sichtbar',
                'Das Feld wird automatisch eingeblendet, solange ein Wert im Feld steht. Bei neuen Gutachten wird das Feld ausgeblendet.',
            );
        }

        this.userPreferences.claimantIbanShown = !this.userPreferences.claimantIbanShown;
    }

    public toggleClaimantCaseNumber(): void {
        // When hiding, notify the user that the input stays visible if there's a value.
        if (this.userPreferences.claimantCaseNumberShown && this.report.claimant.caseNumber) {
            this.toastService.info(
                'Aktenzeichen bleibt sichtbar',
                'Das Feld wird automatisch eingeblendet, solange ein Wert im Feld steht. Bei neuen Gutachten wird das Feld ausgeblendet.',
            );
        }
        this.userPreferences.claimantCaseNumberShown = !this.userPreferences.claimantCaseNumberShown;
        this.changeDetectorRef.detectChanges();
    }

    public toggleClaimantDebtorNumber(): void {
        // When hiding, notify the user that the input stays visible if there's a value.
        if (this.userPreferences.claimantDebtorNumberShown && this.report.claimant.contactPerson.debtorNumber) {
            this.toastService.info(
                'Debitorennummer bleibt sichtbar',
                'Das Feld wird automatisch eingeblendet, solange ein Wert im Feld steht. Bei neuen Gutachten wird das Feld ausgeblendet.',
            );
        }
        this.userPreferences.claimantDebtorNumberShown = !this.userPreferences.claimantCaseNumberShown;
    }

    public toggleClaimantNotes(): void {
        // When hiding, notify the user that the input stays visible if there's a value.
        if (this.userPreferences.claimantNotesShown && this.report.claimant.contactPerson.notes) {
            this.toastService.info(
                'Notizen bleibt sichtbar',
                'Das Feld wird automatisch eingeblendet, solange ein Wert im Feld steht. Bei neuen Gutachten wird das Feld ausgeblendet.',
            );
        }
        this.userPreferences.claimantNotesShown = !this.userPreferences.claimantNotesShown;
    }

    ///////////////////////////////////////////////////////////////////////////////
    // END Claimant's IBAN Value
    ///////////////////////////////////////////////////////////////////////////////

    /**
     * Toggle the input for the order time (required by ZAK).
     */
    protected toggleOrderTimeInputShown(): void {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        // When hiding, notify the user that the input stays visible if there's a value.
        if (this.team.preferences.reportOrderTimeShown && this.report.orderTime) {
            this.toastService.info(
                'Uhrzeit bleibt sichtbar',
                'Das Feld wird automatisch eingeblendet, solange ein Wert im Feld steht. Bei neuen Gutachten wird das Feld ausgeblendet.',
            );
        }

        if (!this.team.preferences.reportOrderTimeShown && !this.report.orderTime) {
            // Prefill the order time
            this.report.orderTime = new Date().toISOString();
            void this.saveReport();
        }

        this.team.preferences.reportOrderTimeShown = !this.team.preferences.reportOrderTimeShown;
        void this.saveTeam();
    }

    protected getClaimantDenomination(): ClaimantDenominationGerman {
        return Translator.claimantDenomination(this.report.type);
    }

    //*****************************************************************************
    //  Amendment Reports
    //****************************************************************************/
    public async getOriginalReport() {
        if (!this.report.originalReportId) {
            this.originalReport = undefined;
            return;
        }
        this.originalReport = await this.reportService.get(this.report.originalReportId);
    }

    public async getAmendmentReport() {
        if (!this.report.amendmentReportId) {
            this.amendmentReport = undefined;
            return;
        }
        this.amendmentReport = await this.reportService.get(this.report.amendmentReportId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Amendment Reports
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Registration Scanner
    //****************************************************************************/
    public showCarRegistrationScanner(): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der Fahrzeugschein-Scanner ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.carRegistrationScannerDialogShown = true;
    }

    public hideCarRegistrationScanner(): void {
        this.carRegistrationScannerDialogShown = false;
    }

    public hideCarRegistrationScanNotice(): void {
        // Don't trigger a save every time the user clicks the scan button
        if (!this.user.userInterfaceStates.carRegistrationScanNoticeClosed) {
            this.user.userInterfaceStates.carRegistrationScanNoticeClosed = true;
            this.saveUser();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Registration Scanner
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Recalculate the valuation values when the claimant's taxation changes.
     */
    protected handleClaimantTaxationChange() {
        if (this.report.type === 'valuation') {
            calculateValuationValues(this.report);
            this.saveReport();
        }
    }

    //*****************************************************************************
    //  Adelta Liquidity Check
    //****************************************************************************/
    public displayLiquidityCheckErrorMessage(errorCode: string): void {
        switch (errorCode) {
            case 'ADELTA_FINANZ_NOT_AUTHENTICATED':
                this.toastService.error(
                    'Ungültige Zugangsdaten',
                    'Die Zugangsdaten zu ADELTA.FINANZ wurden abgelehnt. Bitte überprüfe deine ADELTA.FINANZ-Zugangsdaten in den Einstellungen.',
                );
                break;
            case 'ADELTA_FINANZ_ADDRESS_PARAMETER_ERROR':
                this.toastService.error('Adresse korrekt?', 'ADELTA.FINANZ konnte die Anschrift nicht finden.');
                break;
            case 'ADELTA_FINANZ_DEBTOR_NOT_FOUND':
                this.toastService.error(
                    'ADELTA.FINANZ kennt Debitor nicht',
                    'Der in autoiXpert gespeicherte Debitor ist ADELTA.FINANZ unbekannt. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
                break;
            case 'ADDRESS_INCOMPLETE':
                this.toastService.error(
                    'Adresse unvollständig',
                    'Die gesamte Anschrift ist für den Abgleich erforderlich.',
                );
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Adelta Liquidity Check
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report properties
    //****************************************************************************/
    public rememberUsageOfCostEstimateHeading(): void {
        this.userPreferences.useCostEstimateHeading = this.report.useCostEstimateHeading;
    }

    public changeReportTypeSpecificDocumentsTitle(): void {
        addReportTypeSpecificDocuments({
            report: this.report,
            team: this.team,
            user: this.user,
            documentOrderConfigs: this.documentOrderConfigs,
        });
    }

    public isKaskoCase(): boolean {
        return isKaskoCase(this.report.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report properties
    /////////////////////////////////////////////////////////////////////////////*/
    public getIntermediaryAutocompleteOptionValue(contactPerson: ContactPerson): string {
        return getContactPersonFullNameWithOrganization(contactPerson, ' - ');
    }

    public showIntermediaryManagementDialog(): void {
        this.intermediaryManagementDialogShown = true;
    }

    public hideIntermediaryManagementDialog(): void {
        this.intermediaryManagementDialogShown = false;
    }

    public insertIntermediaryIntoReport(contactPerson: ContactPerson) {
        this.report.intermediary.name = this.getIntermediaryAutocompleteOptionValue(contactPerson);
        this.report.intermediary.contactPersonId = contactPerson._id;

        // If contact person is a garage -> suggest to use it as garage
        if (
            contactPerson.organizationType === 'garage' &&
            !this.report.garage.contactPerson.organization &&
            !this.report.garage.contactPerson.firstName &&
            !this.report.garage.contactPerson.lastName
        ) {
            this.showInsertIntermediaryAsGarageInfoNote = true;
            this.contactPersonToPossiblyInsertAsGarage = contactPerson;
        }
    }

    /**
     * Insert the given contact person as garage into the report. Optionally insert it as the location of all visits without location yet.
     */
    protected insertContactPersonAsGarage({
        contactPerson,
        alsoSetVisitLocation,
    }: {
        contactPerson: ContactPerson;
        alsoSetVisitLocation?: boolean;
    }): void {
        if (contactPerson) {
            // Select the garage
            this.report.garage.contactPerson = contactPerson;

            // Select the garage fee set
            this.report.garage.selectedFeeSetId = contactPerson.garageFeeSets?.find((feeSet) => feeSet.isDefault)?._id;

            this.saveReport();

            if (alsoSetVisitLocation) {
                for (const visit of this.report.visits) {
                    if (!visit.locationName && this.insertIntermediaryAsGarageIncludeVisitLocation) {
                        this.insertAddressAutocompletionInVisitLocation(visit, contactPerson);
                    }
                }

                this.showInsertIntermediaryAsGarageInfoNote = false;
                this.contactPersonToPossiblyInsertAsGarage = null;
            } else {
                this.showInsertVisitLocationContactAsGarageInfoNote = false;
                this.visitLocationContactPersonToPossiblyInsertAsGarage = null;
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Intermediary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Kasko Damage Type
    //****************************************************************************/
    public toggleKaskoDamageType(damageType: KaskoDamageType): void {
        if (this.isReportLocked()) return;

        // Reset if the same icon is clicked twice
        if (this.report.accident.kaskoDamageType === damageType) {
            this.report.accident.kaskoDamageType = null;
            this.report.accident.kaskoDamageTypeCustomLabel = null;
        }
        // Another icon is clicked
        else {
            // Reset the custom label if a type other than 'other' is selected
            if (damageType !== 'other') {
                this.report.accident.kaskoDamageTypeCustomLabel = null;
            }

            this.report.accident.kaskoDamageType = damageType;
        }
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Kasko Damage Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Visits
    //****************************************************************************/
    /**
     * Adds a new visit to the visits array by copying the most recent one.
     * Copying an object instead of a >>reference<< to that object works by converting the object to JSON and parsing it afterwards.
     */
    public addVisit() {
        if (this.isReportLocked()) {
            return;
        }

        const latestVisit = this.report.visits[this.report.visits.length - 1];
        const newVisit: Visit = JSON.parse(JSON.stringify(latestVisit));

        // The ID enables the live sync algorithm to merge changes into the right visit object.
        newVisit._id = generateId();
        // Remove properties that are likely to change between visits
        newVisit.date = null;
        newVisit.time = null;
        newVisit.carAssemblyState = '';
        newVisit.conditions = '';
        newVisit.comment = null;
        newVisit.sameStateAsInAccident = null;

        // People present
        newVisit.claimantWasPresent = null;
        newVisit.driverOfClaimantsCarWasPresent = null;
        newVisit.ownerOfClaimantsCarWasPresent = null;
        newVisit.garageTechnicianWasPresent = null;
        newVisit.otherPeoplePresent = [];

        this.insertDefaultOtherPeoplePresent(newVisit);

        this.report.visits.push(newVisit);

        this.saveReport();
    }

    public removeVisit(visit: Visit) {
        if (this.isReportLocked()) {
            return;
        }

        this.report.visits.splice(this.report.visits.indexOf(visit), 1);
    }

    /**
     * Copy the claimant's address to a visit.
     */
    public insertClaimantsAddress(visit: Visit): void {
        visit.locationName = `${
            this.report.claimant.contactPerson.organization ||
            this.report.claimant.contactPerson.firstName + ' ' + this.report.claimant.contactPerson.lastName
        } (${this.getClaimantDenomination()})`;
        visit.street = this.report.claimant.contactPerson.streetAndHouseNumberOrLockbox;
        visit.zip = this.report.claimant.contactPerson.zip;
        visit.city = this.report.claimant.contactPerson.city;
    }

    /**
     * Copy the user's address from the UserPreferencesService to a visit.
     */
    public insertUsersAddress(visit: Visit): void {
        const user: User = this.loggedInUserService.getUser();
        if (!user) return;

        const defaultOfficeLocation = this.getOfficeLocation(user.defaultOfficeLocationId);

        if (!defaultOfficeLocation) return;

        visit.locationName = user.organization;
        visit.street = defaultOfficeLocation.streetAndHouseNumber;
        visit.zip = defaultOfficeLocation.zip;
        visit.city = defaultOfficeLocation.city;
    }

    public showVisitLocationFavoritesDialog(visit: Visit): void {
        this.visitForFavoritesDialog = visit;
        this.visitLocationFavoritesDialogShown = true;
    }

    public hideVisitLocationFavoritesDialog(): void {
        this.visitLocationFavoritesDialogShown = false;
    }

    public handleVisitLocationAutocompleteSelection(
        { visit, visitLocationFavorite }: { visit: Visit; visitLocationFavorite: ContactPerson },
        selectionChangeEvent: MatOptionSelectionChange,
    ): void {
        // Ignore the call to this function from the de-selected option. Angular fires the onSelectionChanged event twice; once for the old option and once for the new selected option.
        if (!selectionChangeEvent.source.selected) {
            return;
        }

        this.insertVisitLocationFavorite({
            visit,
            visitLocationFavorite,
        });
    }

    public insertVisitLocationFavorite({
        visit,
        visitLocationFavorite,
    }: {
        visit: Visit;
        visitLocationFavorite: ContactPerson;
    }): void {
        if (this.isReportLocked()) {
            return;
        }

        visit.locationName = visitLocationFavorite.organization;
        visit.street = visitLocationFavorite.streetAndHouseNumberOrLockbox;
        visit.zip = visitLocationFavorite.zip;
        visit.city = visitLocationFavorite.city;

        this.saveReport();
    }

    public addCityToVisit(visit, city): void {
        visit.city = city;
    }

    /**
     * Fetches the address of the open street map prediction and inserts it into the visit.
     */
    public async insertAddressAutocompletionInAccidentLocation(mapsPrediction: Partial<ContactPerson>) {
        const city = [mapsPrediction.zip, mapsPrediction.city].filter(Boolean).join(' ');
        this.report.accident.location = `${mapsPrediction.streetAndHouseNumberOrLockbox}, ${city}`;
        this.saveReport();
    }
    /**
     * Fetches the address of the open street map prediction and inserts it into the visit.
     */
    public async insertAddressAutocompletionInVisitLocation(visit: Visit, addressResult: Partial<ContactPerson>) {
        visit.locationName = addressResult.organization ?? visit.locationName;
        visit.street = addressResult.streetAndHouseNumberOrLockbox ?? visit.street;
        visit.city = addressResult.city ?? visit.city;
        visit.zip = addressResult.zip ?? visit.zip;
        this.saveReport();
    }

    protected suggestToUseContactPersonAsGarage(contactPerson: ContactPerson): void {
        if (
            contactPerson.organizationType === 'garage' &&
            !this.report.garage.contactPerson.organization &&
            !this.report.garage.contactPerson.firstName &&
            !this.report.garage.contactPerson.lastName
        ) {
            this.showInsertVisitLocationContactAsGarageInfoNote = true;
            this.visitLocationContactPersonToPossiblyInsertAsGarage = contactPerson;
        }
    }

    public async insertCityInVisitLocation(visit: Visit, city: string) {
        visit.city = city;
        this.saveReport();
    }

    public async getAddressFromCurrentPosition(visit: Visit) {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.error('Offline nicht verfügbar', 'Die Standortbestimmung ist offline nicht verfügbar.');
            return;
        }

        if (!navigator.geolocation) {
            this.toastService.error(
                'Standort nicht verfügbar',
                'Dein Gerät unterstützt die Erkennung des Standorts nicht.',
            );
            return;
        }

        this.getCurrentLocationForVisitPending = true;
        navigator.geolocation.getCurrentPosition(
            // Handle success
            async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                try {
                    const response = await this.googleMapsGeocodeService.convertGPSCoordinatesToAddress({
                        latitude,
                        longitude,
                    });
                    if (!visit.date) {
                        visit.date = todayIso();
                    }
                    if (!visit.time && visit.date === todayIso()) {
                        // Floor to the nearest 15 minutes
                        visit.time = moment()
                            .subtract(moment().minutes() % 15, 'minutes')
                            .toISOString();
                    }
                    this.insertAddressAutocompletionInVisitLocation(visit, {
                        streetAndHouseNumberOrLockbox: [response.route, response.streetNumber]
                            .filter(Boolean)
                            .join(' '),
                        zip: response.postalCode,
                        city: response.locality,
                    });
                } catch (error) {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        defaultHandler: {
                            title: 'Adresse nicht ermittelt',
                            body: 'Deinem Standort konnte keine Adresse zugeordnet werden. Bitte versuche es erneut oder gib die Adresse manuell ein.',
                        },
                    });
                }

                this.getCurrentLocationForVisitPending = false;
            },
            // Handle error
            (error) => {
                console.error('GETTING_GPS_POSITION_FAILED', error);
                if (error?.code === error?.PERMISSION_DENIED) {
                    this.toastService.error(
                        'Standort blockiert',
                        'Dein Gerät hat die Standortbestimmung blockiert. Bitte erlaube den Zugriff auf deinen Standort. <a href="https://wissen.autoixpert.de/hc/de/articles/24048490875922-Besichtigungsadresse-aus-GPS-Standort" target="_blank">Hier</a> findest du eine Anleitung.',
                    );
                } else {
                    this.toastService.error(
                        'Standort nicht verfügbar',
                        'Bitte lade die Seite neu und versuche es erneut. Das hilft bei manchen Browsern, deine GPS-Freigabe zu honorieren.',
                    );
                }
                this.getCurrentLocationForVisitPending = false;
            },
            {
                enableHighAccuracy: true,
                timeout: 8000,
            },
        );
    }

    //*****************************************************************************
    //  Assessor
    //****************************************************************************/
    public getResponsibleAssessor(userId: string): User {
        return this.assessors.find((assessor) => assessor._id === userId);
    }

    public getUsersFullName(userId: string): string {
        const user: User = this.getResponsibleAssessor(userId);

        if (!user) {
            return '';
        }

        return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    }

    protected getDeactivatedUsersFullName(userId: string): string {
        const deactivatedAssessor = this.deactivatedAssessors.find((assessor) => assessor._id === userId);
        if (!deactivatedAssessor) {
            return '';
        }

        return `${deactivatedAssessor.firstName || ''} ${deactivatedAssessor.lastName || ''}`.trim();
    }

    /**
     * If the responsible assessor is changed and the visiting assessor was the same as
     * the previously responsible assessor, update the visiting assessor too.
     */
    public changeVisitingAssessorOnResponsibleAssessorChange(): void {
        // Don't subscribe twice
        if (this.responsibleAssessorChangeSubscription) return;

        // Only subscribe as soon as the view child is available.
        // It won't be available until Angular rendered the component part hidden behind *ngIf="report"
        if (!this.responsibleAssessorSelect) {
            window.setTimeout(() => this.changeVisitingAssessorOnResponsibleAssessorChange(), 100);
            return;
        }

        this.responsibleAssessorChangeSubscription = this.responsibleAssessorSelect.optionSelectionChanges
            .pipe(
                map((optionSelectionChange) => optionSelectionChange.source.value),
                startWith(this.report.responsibleAssessor),
                pairwise(),
                // Filter out equal pairs (old selected option + new un-selected option)
                filter(([previousValue, currentValue]) => previousValue !== currentValue),
            )
            .subscribe(([previousValue, currentValue]) => {
                // If the responsible and last visiting assessor were the same, let them be the same after the change too.
                if (
                    this.report.visits.length &&
                    this.report.visits[this.report.visits.length - 1].assessor === previousValue
                ) {
                    this.report.visits[0].assessor = currentValue;
                }
            });

        this.subscriptions.push(this.responsibleAssessorChangeSubscription);
    }

    /**
     * When the responsible assessor is changed, select his default office location.
     */
    public preselectOfficeLocationOfResponsibleAssessor() {
        this.report.officeLocationId = this.getResponsibleAssessor(
            this.report.responsibleAssessor,
        ).defaultOfficeLocationId;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Assessor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Office Location
    //****************************************************************************/
    public getOfficeLocation(officeLocationId: string): OfficeLocation {
        if (!this.team?.officeLocations) {
            return undefined;
        }
        return this.team.officeLocations.find((officeLocation) => officeLocation._id === officeLocationId);
    }

    /**
     * rememberDefaultOfficeLocation
     * Changes the defaultOfficeLocation of the current logged in user, keeps if it is already the default.
     */
    public rememberDefaultOfficeLocation(officeLocationId: string, event: MouseEvent): void {
        // Catch the event since it is in a dropdown
        event.stopPropagation();

        // Return if it's already the users default
        if (this.user.defaultOfficeLocationId === officeLocationId) {
            return;
        }

        // Check if officeLocation is complete
        const officeLocation = this.getOfficeLocation(officeLocationId);
        if (!checkIfOfficeLocationComplete(officeLocation)) {
            this.toastService.info(
                'Standort unvollständig',
                'Nur vollständige Standorte können als Standard gesetzt werden.',
            );
            return;
        }

        this.user.defaultOfficeLocationId = officeLocationId;
        this.saveUser();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Office Location
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Auxiliary Devices Chip List
    //****************************************************************************/
    /**
     * On blur or hitting certain keys (enter, comma, space), trigger adding a device as a chip.
     *
     * @param visit
     * @param chipInputEvent
     */
    public enterAuxiliaryDevice(visit: Visit, chipInputEvent: MatChipInputEvent) {
        if (this.auxiliaryDevicesAutocomplete.isOpen) {
            return;
        }

        const inputValue = (chipInputEvent.value || '').trim();

        this.addAuxiliaryDeviceToVisit(visit, inputValue);

        // Clear input
        this.clearInputAndResetAutocomplete(chipInputEvent.chipInput.inputElement);
    }

    /**
     * Add a device as a chip unless the same device has already been added.
     *
     * @param visit
     * @param auxiliaryDevice
     */
    public addAuxiliaryDeviceToVisit(visit: Visit, auxiliaryDevice: string): void {
        // Don't add duplicates
        if (visit.auxiliaryDevices.includes(auxiliaryDevice)) {
            this.toastService.info(`Wert '${auxiliaryDevice}' bereits vorhanden`);
            return;
        }

        // Add chip if non-empty
        if (auxiliaryDevice) {
            visit.auxiliaryDevices.push(auxiliaryDevice);
        }
    }

    public removeAuxiliaryDevice(visit: Visit, auxiliaryDevice: string): void {
        visit.auxiliaryDevices.splice(visit.auxiliaryDevices.indexOf(auxiliaryDevice), 1);
    }

    public selectAuxiliaryDeviceFromAutocomplete(
        visit: Visit,
        event: MatAutocompleteSelectedEvent,
        inputElement: HTMLInputElement,
        autocompleteTrigger: MatAutocompleteTrigger,
    ): void {
        this.addAuxiliaryDeviceToVisit(visit, event.option.value);
        this.clearInputAndResetAutocomplete(inputElement);
        this.filterAuxiliaryDeviceAutocomplete(visit);
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 0);
    }

    public clearInputAndResetAutocomplete(input: HTMLInputElement): void {
        input.value = '';
    }

    public rememberStandardAuxiliaryDevices(devices: string[]): void {
        this.userPreferences.auxiliaryDevicesDefault = [...devices];
        this.toastService.success(
            'Standard-Hilfsmittel gemerkt',
            'Im nächsten Gutachten werden sie automatisch eingefügt.',
        );
    }

    public insertStandardAuxiliaryDevices(visit: Visit): void {
        visit.auxiliaryDevices = [...this.userPreferences.auxiliaryDevicesDefault];
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Auxiliary Devices Chip List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Auxiliary Devices Autocomplete
    //****************************************************************************/
    public retrieveAuxiliaryDeviceEntries() {
        // If the entries exist already, no need to query the server again --> performance.
        if (this.auxiliaryDevicesAutocompleteEntries?.length) {
            return;
        }

        this.customAutocompleteEntriesService.find({ type: 'auxiliaryDevices' }).subscribe({
            next: (entries) => {
                this.auxiliaryDevicesAutocompleteEntries = entries.sort(sortByProperty('value'));
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Eigene Autocomplete-Werte konnten nicht geholt werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    public customAuxiliaryDeviceAutocompleteEntryExists(entry: string): boolean {
        return !!this.auxiliaryDevicesAutocompleteEntries.find((existingEntry) => existingEntry.value === entry);
    }

    /**
     * Trigger bulk rememberance of all current auxiliary devices in a visit.
     * @param auxiliaryDevices
     */
    public rememberCurrentAuxiliaryDevices(auxiliaryDevices: Visit['auxiliaryDevices']) {
        const newAuxiliaryDevices = auxiliaryDevices.filter(
            (auxiliaryDevice) => !this.customAuxiliaryDeviceAutocompleteEntryExists(auxiliaryDevice),
        );

        if (!newAuxiliaryDevices.length) {
            this.toastService.success(
                'Alle Hilfsmittel in Vorschlagsliste',
                'Es wurden keine neuen Hilfsmittel hinzugefügt.',
            );
            return;
        }

        for (const auxiliaryDevice of auxiliaryDevices) {
            this.rememberAuxiliaryDeviceEntry(auxiliaryDevice);
        }
    }

    /**
     * Remember a single auxiliary device for the autocomplete.
     * @param device
     * @private
     */
    private async rememberAuxiliaryDeviceEntry(device: string) {
        if (!(device || '').trim()) return;

        // Don't remember duplicates
        if (this.customAuxiliaryDeviceAutocompleteEntryExists(device)) return;

        const newAutocompleteEntry = new CustomAutocompleteEntry({
            type: 'auxiliaryDevices',
            value: device,
        });

        this.auxiliaryDevicesAutocompleteEntries.push(newAutocompleteEntry);

        try {
            await this.customAutocompleteEntriesService.create(newAutocompleteEntry);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Autocomplete-Wert nicht gespeichert',
                    body: "Der Wert für das Autocomplete-Feld konnte nicht gespeichert werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }

        this.toastService.success(`Hilfsmittel "${device}" gemerkt`, 'Es wurde der Vorschlagsliste hinzugefügt.');
    }

    public async removeAuxiliaryDeviceAutocompleteEntry(device: CustomAutocompleteEntry) {
        if (this.isReportLocked()) return;

        const index = this.auxiliaryDevicesAutocompleteEntries.indexOf(device);
        this.auxiliaryDevicesAutocompleteEntries.splice(index, 1);

        try {
            await this.customAutocompleteEntriesService.delete(device._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Beschreibung nicht gelöscht',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * Reduce the entries of the autocomplete for auxiliary devices
     * to the ones containing the search term.
     *
     * @param visit
     * @param searchTerm
     */
    public filterAuxiliaryDeviceAutocomplete(visit: Visit, searchTerm: string = '') {
        const inputValue = (searchTerm || '').trim().toLowerCase();
        this.filteredAuxiliaryDevicesAutocompleteEntries = this.auxiliaryDevicesAutocompleteEntries
            // Only suggest values matching the search term.
            .filter((device) => device.value.toLowerCase().includes(inputValue))
            // Don't suggest already selected auxiliary devices.
            .filter((device) => !visit.auxiliaryDevices.includes(device.value))
            .sort(sortByProperty('value'));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Auxiliary Devices Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Other People Present
    //****************************************************************************/
    public showOtherPeoplePresentAtThisVisit(visit: Visit): void {
        this.showOtherPeoplePresentAtTheseVisits.push(visit);
    }

    public hideOtherPeoplePresentAtThisVisit(visit: Visit): void {
        const index = this.showOtherPeoplePresentAtTheseVisits.indexOf(visit);
        this.showOtherPeoplePresentAtTheseVisits.splice(index, 1);
    }

    /**
     * On page load, add the visits with at least one "other" person present to the visible array
     */
    public showVisitsWithOtherPeoplePresent(): void {
        this.report.visits.forEach((visit) => {
            if (this.hasOtherPeoplePresent(visit)) {
                this.showOtherPeoplePresentAtThisVisit(visit);
            }
        });
    }

    public hasOtherPeoplePresent(visit): boolean {
        return (
            visit.garageTechnicianWasPresent ||
            visit.ownerOfClaimantsCarWasPresent ||
            visit.driverOfClaimantsCarWasPresent ||
            visit.otherPeoplePresent?.length
        );
    }

    public enterOtherPeoplePresent(visit: Visit, chipInputEvent: MatChipInputEvent) {
        if (this.otherPeoplePresentAutocomplete.isOpen) {
            return;
        }

        const inputValue = (chipInputEvent.value || '').trim();

        this.addOtherPeoplePresentToVisit(visit, inputValue);

        // Clear input
        this.clearInputAndResetAutocomplete(chipInputEvent.chipInput.inputElement);
    }

    public addOtherPeoplePresentToVisit(visit: Visit, person: string): void {
        // visit.otherPeoplePresent is null by default in the backend, so convert it to an array if it is still null.
        if (!Array.isArray(visit.otherPeoplePresent)) {
            visit.otherPeoplePresent = [];
        }

        // Don't add duplicates
        if (visit.otherPeoplePresent?.includes(person)) {
            this.toastService.info(`Person '${person}' bereits vorhanden`);
            return;
        }

        // Add chip if non-empty
        if (person) {
            visit.otherPeoplePresent.push(person);
        }
    }

    public removeOtherPeoplePresent(visit: Visit, person: string): void {
        visit.otherPeoplePresent.splice(visit.otherPeoplePresent.indexOf(person), 1);
    }

    public selectOtherPeoplePresentFromAutocomplete(
        visit: Visit,
        event: MatAutocompleteSelectedEvent,
        inputElement: HTMLInputElement,
        autocompleteTrigger: MatAutocompleteTrigger,
    ): void {
        this.addOtherPeoplePresentToVisit(visit, event.option.value);
        this.clearInputAndResetAutocomplete(inputElement);
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 0);
    }

    public rememberDefaultOtherPeoplePresent(devices: string[]): void {
        this.userPreferences.otherPeoplePresentDefault = [...devices];
        this.toastService.success(
            'Standard-Anwesende gemerkt',
            'Im nächsten Gutachten werden sie automatisch eingefügt.',
        );
    }

    public insertDefaultOtherPeoplePresent(visit: Visit): void {
        visit.otherPeoplePresent = [...this.userPreferences.otherPeoplePresentDefault];
        this.saveReport();
    }

    //*****************************************************************************
    //  Auxiliary Devices Autocomplete
    //****************************************************************************/
    public retrieveOtherPeoplePresentEntries() {
        // If the entries exist already, no need to query the server again --> performance.
        if (this.otherPeoplePresentAutocompleteEntries?.length) {
            return;
        }

        this.customAutocompleteEntriesService.find({ type: 'otherPeoplePresent' }).subscribe({
            next: (entries) => {
                this.otherPeoplePresentAutocompleteEntries = entries.sort(sortByProperty('value'));
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Eigene Autocomplete-Werte konnten nicht geholt werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    public customOtherPeoplePresentAutocompleteEntryExists(entry: string): boolean {
        return !!this.otherPeoplePresentAutocompleteEntries.find((existingEntry) => existingEntry.value === entry);
    }

    /**
     * Trigger bulk rememberance of all current other people present in a visit.
     * @param otherPeoplePresent
     */
    public addCurrentOtherPeoplePresentToAutocomplete(otherPeoplePresent: Visit['otherPeoplePresent']) {
        const newOtherPeoplePresent = otherPeoplePresent.filter(
            (auxiliaryDevice) => !this.customAuxiliaryDeviceAutocompleteEntryExists(auxiliaryDevice),
        );

        if (!newOtherPeoplePresent.length) {
            this.toastService.success(
                'Alle weiteren Anwesenden bereits in Vorschlagsliste',
                'Es wurden keine neuen hinzugefügt.',
            );
            return;
        }

        for (const otherPersonPresent of otherPeoplePresent) {
            this.rememberOtherPeoplePresentEntry(otherPersonPresent);
        }
    }

    /**
     * Remember a single auxiliary device for the autocomplete.
     * @param otherPersonPresent
     * @private
     */
    private async rememberOtherPeoplePresentEntry(otherPersonPresent: string) {
        if (!(otherPersonPresent || '').trim()) return;

        // Don't remember duplicates
        if (this.customOtherPeoplePresentAutocompleteEntryExists(otherPersonPresent)) return;

        const newAutocompleteEntry = new CustomAutocompleteEntry({
            type: 'otherPeoplePresent',
            value: otherPersonPresent,
        });

        this.otherPeoplePresentAutocompleteEntries.push(newAutocompleteEntry);

        try {
            await this.customAutocompleteEntriesService.create(newAutocompleteEntry);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Autocomplete-Wert nicht gespeichert',
                    body: "Der Wert für das Autocomplete-Feld konnte nicht gespeichert werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }

        this.toastService.success(
            `Anwesender "${otherPersonPresent}" gemerkt`,
            'Er wurde der Vorschlagsliste hinzugefügt.',
        );
    }

    public async removeOtherPeoplePresentAutocompleteEntry(otherPersonPresent: CustomAutocompleteEntry) {
        if (this.isReportLocked()) return;

        removeFromArray(otherPersonPresent, this.otherPeoplePresentAutocompleteEntries);

        try {
            await this.customAutocompleteEntriesService.delete(otherPersonPresent._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Anwesender nicht gelöscht',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * Reduce the entries of the autocomplete for other people present
     * to the ones containing the search term.
     *
     * @param visit
     * @param searchTerm
     */
    public filterOtherPeoplePresentAutocomplete(visit: Visit, searchTerm: string = '') {
        const inputValue = (searchTerm || '').trim().toLowerCase();
        this.filteredOtherPeoplePresentAutocompleteEntries = this.otherPeoplePresentAutocompleteEntries
            // Only suggest values matching the search term.
            .filter((device) => device.value.toLowerCase().includes(inputValue))
            // Don't suggest already selected auxiliary devices.
            .filter((otherPersonPresent) => !visit.otherPeoplePresent.includes(otherPersonPresent.value))
            .sort(sortByProperty('value'));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Auxiliary Devices Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Other People Present
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Visits
    /////////////////////////////////////////////////////////////////////////////*/
    licensePlateInputWasBlurred(): void {
        this.saveReport();
        this.findPreviousAndLegacyReports();
    }

    //*****************************************************************************
    //  Previous & Legacy Reports
    //****************************************************************************/
    public findPreviousAndLegacyReports(): void {
        if (this.report.car.licensePlate === this.previousLicensePlateChecked) {
            // Don't check the same license plate twice
            return;
        }
        this.previousLicensePlateChecked = this.report.car.licensePlate;

        /**
         * Don't start the request without a license plate.
         *
         * Often, assessors use the placeholder "OHNE" to explicitly state that the car has no valid registration. Since these
         * values exist often across team accounts, the database query would become slow.
         */
        const wordsIndicatingEmptyLicensePlates = ['OHNE', 'OH-NE', 'ABGEMELDET'];
        if (!this.report.car.licensePlate || wordsIndicatingEmptyLicensePlates.includes(this.report.car.licensePlate)) {
            return;
        }

        // Don't search for incomplete license plates, such as "PB--" or "PB-M-"
        if (this.report.car.licensePlate.endsWith('-')) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            return;
        }

        // Previous reports (from autoiXpert)
        this.reportService.findByLicensePlate(this.report.car.licensePlate).subscribe({
            next: (previousReports) => {
                // Filter out the current report
                this.previousReports = previousReports.filter((report) => report._id !== this.report._id);
            },
            error: (err) => {
                console.error(err);
            },
        });

        // Previous reports (from legacy system)
        this.legacyReportService.find({ licensePlate: this.report.car.licensePlate }).subscribe({
            next: (legacyReports) => {
                // Filter out the current report
                this.legacyReports = legacyReports;
            },
            error: (err) => {
                console.error(err);
            },
        });
    }

    public clearPreviousAndLegacyReports(): void {
        this.previousReports = [];
        this.legacyReports = [];
        this.previousLicensePlateChecked = null;
    }

    public getPreviousReportsTriggerTooltip(): string {
        const reportsToShow = [...this.previousReports, ...this.legacyReports];
        if (reportsToShow.length === 1) {
            return `Es wurde 1 Gutachten mit gleichem Kennzeichen gefunden. Für Details klicken.`;
        } else {
            return `Es wurden ${reportsToShow.length} Gutachten mit gleichem Kennzeichen gefunden. Für Details klicken.`;
        }
    }

    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;

    public navigateToReport(report: Report): void {
        this.router.navigateByUrl(`/Gutachten/${report._id}`);
    }

    /**
     * When the user performs a middle-button click on a menu entry, open the report in a new window.
     *
     * @param {Report} report
     * @param {MouseEvent} event
     */
    public openReportInNewWindowOnMiddleMouseClick(report: Report, event: MouseEvent): void {
        // Only consider presses of the middle button (wheel click)
        if (event.button !== 1) {
            return;
        }

        window.open(`/Gutachten/${report._id}`);
    }

    public async copyCarDataFromPreviousReport(previousReport: Report) {
        const copyOfPreviousReportCar: Car = getCopyableCarProperties(previousReport.car);
        Object.assign(this.report.car, copyOfPreviousReportCar);

        try {
            await this.carEquipmentService.copyToReport(previousReport._id, this.report._id);
        } catch (error) {
            this.toastService.error(
                'Ausstattung nicht kopiert',
                "Die Ausstattung aus dem alten Gutachten konnten nicht übernommen werden.<br />Bitte starte den Kopiervorgang erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
            );
        }

        this.saveReport();
        this.toastService.success('Fahrzeugdaten übernommen');
    }

    public displayInfoNoteAboutLegacyReport() {
        this.toastService.info(
            'Gutachten aus einem Fremdsystem',
            'Wenn du Details suchst, schaue bitte in deinem bisherigen System nach.',
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Previous & Legacy Reports
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Copy Contact Details
    //****************************************************************************/
    /**
     * Copy the organization name and address from one organization to another.
     * @param source
     * @param target
     */
    public copyContactDetails(source: InvolvedParty | Claimant, target: InvolvedParty | Claimant) {
        if (this.isReportLocked()) {
            return;
        }

        target.contactPerson.organization = source.contactPerson.organization;
        target.contactPerson.streetAndHouseNumberOrLockbox = source.contactPerson.streetAndHouseNumberOrLockbox;
        target.contactPerson.zip = source.contactPerson.zip;
        target.contactPerson.city = source.contactPerson.city;

        if ('mayDeductTaxes' in source.contactPerson && 'mayDeductTaxes' in target.contactPerson) {
            target.contactPerson.mayDeductTaxes = source.contactPerson.mayDeductTaxes;
        }
        if ('vatId' in source.contactPerson && 'vatId' in target.contactPerson) {
            target.contactPerson.vatId = source.contactPerson.vatId;
        }

        this.saveReport();
    }

    public copyClaimantAddressToAccidentLocation(): void {
        const claimant = this.report.claimant.contactPerson;

        if (claimant.streetAndHouseNumberOrLockbox && claimant.zip && claimant.city) {
            this.report.accident.location = `${claimant.streetAndHouseNumberOrLockbox}, ${claimant.zip} ${claimant.city}`;
            return;
        }
        if (!claimant.streetAndHouseNumberOrLockbox && claimant.zip && claimant.city) {
            this.report.accident.location = `${claimant.zip} ${claimant.city}`;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy Contact Details
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact People Autocompletes
    //****************************************************************************/
    public async loadContactPeopleFromOpenReports() {
        const contactPeopleFromOpenReports: ContactPerson[] =
            await this.contactPersonService.getContactPeopleFromOpenReports({
                organizationTypes: [
                    'claimant',
                    'lawyer',
                    'insurance',
                    'garage',
                    'seller',
                    'leaseProvider',
                    'intermediary',
                ],
                excludedReportIds: [this.report._id],
            });

        this.claimantsFromOpenReports = [];
        this.lawyersFromOpenReports = [];
        this.insurancesFromOpenReports = [];
        this.garagesFromOpenReports = [];
        this.sellersFromOpenReports = [];
        this.leaseProvidersFromOpenReports = [];
        this.intermediariesFromOpenReports = [];

        for (const contactPersonFromOpenReport of contactPeopleFromOpenReports) {
            switch (contactPersonFromOpenReport.organizationType) {
                case 'claimant':
                    this.claimantsFromOpenReports.push(contactPersonFromOpenReport);
                    break;
                case 'lawyer':
                    this.lawyersFromOpenReports.push(contactPersonFromOpenReport);
                    break;
                case 'insurance':
                    this.insurancesFromOpenReports.push(contactPersonFromOpenReport);
                    break;
                case 'garage':
                    this.garagesFromOpenReports.push(contactPersonFromOpenReport);
                    break;
                case 'seller':
                    this.sellersFromOpenReports.push(contactPersonFromOpenReport);
                    break;
                case 'leaseProvider':
                    this.leaseProvidersFromOpenReports.push(contactPersonFromOpenReport);
                    break;
                case 'intermediary':
                    this.intermediariesFromOpenReports.push(contactPersonFromOpenReport);
                    break;
            }
        }
    }

    /**
     * When the user selects a contact person that is marked for receiving collective invoices,
     * disable the invoice in this report and mark it as being part of a collective invoice.
     */
    protected contactPersonWasSelected(contactPerson: ContactPerson): void {
        if (
            contactPerson.receivesCollectiveInvoice &&
            !this.report.feeCalculation.skipWritingInvoice &&
            this.report.feeCalculation.invoiceParameters.recipientRole === 'claimant'
        ) {
            this.report.feeCalculation.isCollectiveInvoice = true;

            this.toastService.info(
                'Gutachten für Sammelrechnung vorgemerkt',
                'Da im Kontaktmanagement die Einstellung "erhält Sammelrechnung" für diesen Kontakt aktiv ist.',
                { timeOut: 10000 },
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact People Autocompletes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Lawyers
    //****************************************************************************/
    /**
     * If the lawyer is inserted or cleared, add or remove the signable
     * document Power of Attorney.
     */
    public insertOrRemovePowerOfAttorney(): void {
        /**
         * No name supplied or checkbox removed -> remove document. Leave the documentsToBeSigned property
         * intact, that only determines if the document should be active when inserted.
         */
        if (!shouldPowerOfAttorneyDocumentBeVisible(this.report)) {
            this.removeDocumentMetadata('powerOfAttorney');
        } else {
            /**
             * Add document if lawyer exists and the lawyer's signableDocument is active but was previously not visible.
             */
            if (
                this.report.signableDocuments.find(
                    (signableDocument) => signableDocument.documentType === 'powerOfAttorney',
                )
            ) {
                /**
                 * Use the child component's method in order to trigger the setup process choosing PDF templates or
                 * document building block templates.
                 */
                void this.customerSignaturesCardComponent.addSignableDocument('powerOfAttorney');
            }
        }
    }

    /**
     * Remove a document from the array used in the Print & Send Component.
     * @param documentType
     */
    private removeDocumentMetadata(documentType: SignableDocumentType) {
        removeDocumentTypeFromReport({
            documentType,
            report: this.report,
            documentGroup: 'report',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lawyers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Locked
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report && this.report.state === 'done';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Locked
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Responsible Assessor
    //****************************************************************************/
    public rememberDefaultResponsibleAssessor(assessorId: string, event: MouseEvent): void {
        // De-select if the default is the same
        if (this.userPreferences.responsibleAssessor === assessorId) {
            this.userPreferences.responsibleAssessor = null;
        } else {
            // Set default
            this.userPreferences.responsibleAssessor = assessorId;
        }
        event.stopPropagation();
    }

    /**
     * If the user is an admin, open team member preferences.
     */
    public handleClickOnNewAssessor() {
        if (isAdmin(this.user._id, this.team)) {
            this.router.navigate(['Einstellungen'], { queryParams: { section: 'team-members' } });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Responsible Assessor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Token
    //****************************************************************************/
    // Generate a new report token and save the counter back to the server.
    public async generateReportToken(): Promise<void> {
        if (this.isReportLocked()) return;

        // Since we wouldn't always use the up-to-date counter, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Nur online kann sichergestellt werden, dass der Zähler über alle Geräte hinweg eindeutig vergeben wird.',
            );
            return;
        }

        // Focus the input so that the label does not "jump"
        this.reportTokenElement.nativeElement.focus();

        this.generateReportTokenRequestPending = true;

        /**
         * Depending on the leading counter type generate either
         * - Invoice number leading: both invoice number and report token with the same counter
         * - Report token leading: regular report token and invoice number based on the report token
         * - Not synced: report token only
         */
        const leadingCounter = this.reportTokenService.getLeadingCounter(this.report);

        // generate invoice number and report token
        if (leadingCounter === 'invoiceNumber') {
            try {
                const { reportToken, invoiceNumber } =
                    await this.reportTokenAndInvoiceNumberService.generateReportTokenAndInvoiceNumber(this.report);
                this.reportTokenAndInvoiceNumberService.writeToReport(
                    this.report,
                    reportToken,
                    this.report.feeCalculation.isCollectiveInvoice ? null : invoiceNumber,
                );

                const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(
                    this.report.officeLocationId,
                );
                await this.invoiceNumberJournalEntryService.create({
                    entryType: 'invoiceNumberGeneratedManually',
                    documentType: 'report',
                    reportId: this.report._id,
                    invoiceNumber,
                    invoiceNumberConfigId: invoiceNumberConfig._id,
                });
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getInvoiceNumberOrReportTokenCounterErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'Aktenzeichen & Rechnungsnummer nicht generiert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            } finally {
                this.generateReportTokenRequestPending = false;
            }
        }
        // Report token only or report token + report token based invoice number
        else {
            try {
                // Set the token before generating the invoice number, which might be based on the token
                this.report.token = await this.reportTokenService.generateReportToken(this.report);

                if (leadingCounter === 'reportToken' && !this.report.feeCalculation.isCollectiveInvoice) {
                    // Reset the invoice number sub counter when a report token has already been generated before
                    if (this.report.invoiceNumberConfig?.count > 0) {
                        this.report.invoiceNumberConfig.count = 0;
                    }

                    const invoiceNumber = await this.invoiceNumberService.generateInvoiceNumber({
                        officeLocationId: this.report.officeLocationId,
                        responsibleAssessorId: this.report.responsibleAssessor,
                        report: this.report,
                    });
                    this.report.feeCalculation.invoiceParameters.number = invoiceNumber;

                    const reportTokenConfig = this.reportTokenService.getReportTokenConfig(
                        this.report.officeLocationId,
                    );
                    await this.invoiceNumberJournalEntryService.create({
                        entryType: 'invoiceNumberGeneratedManually',
                        documentType: 'report',
                        reportId: this.report._id,
                        invoiceNumber,
                        reportTokenConfigId: reportTokenConfig._id,
                    });
                }
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        EMPTY_REPORT_TOKEN_COUNTER: {
                            title: 'Leerer Zähler für Aktenzeichen',
                            body: 'Bitte setze deinen aktuellen Zähler für Aktenzeichen in den <a href="/Einstellungen?section=report-token-container" target="_blank">Einstellungen</a>.',
                        },
                    },
                    defaultHandler: {
                        title: 'Aktenzeichen nicht generiert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            } finally {
                this.generateReportTokenRequestPending = false;
            }
        }

        // Update screen title in case the user displays the token there.
        this.setScreenTitle();

        // Check if report token already exists
        const reportsWithToken = await this.reportService.getByReportToken({
            reportToken: this.report.token,
        });
        const indexOfCurrent = reportsWithToken.findIndex((report) => report._id === this.report._id);
        if (indexOfCurrent >= 0) {
            reportsWithToken.splice(indexOfCurrent, 1);
        }

        // Deleted reports with the same report token are okay
        const notDeletedReportsWithToken = reportsWithToken.filter((report) => report.state !== 'deleted');

        if (notDeletedReportsWithToken.length > 0) {
            this.toastService.error(
                'Doppeltes Aktenzeichen',
                `Es gibt bereits ${
                    notDeletedReportsWithToken.length > 1
                        ? notDeletedReportsWithToken.length + ' Gutachten'
                        : '<a href="/Gutachten/' + notDeletedReportsWithToken[0]._id + '">ein Gutachten</a>'
                } mit gleichem Aktenzeichen. Mögliche Ursachen:
            - Aktenzeichenzähler manuell zurückgesetzt
            - Aktenzeichen bereits manuell vergeben
            - Zählermuster ist ohne Zähler konfiguriert
            
            <a href='/Einstellungen#report-token-container'>Einstellungen öffnen</a>`,
                {
                    clickToClose: true,
                },
            );
        }

        this.generateReportTokenRequestPending = false;
        await this.saveReport();
    }

    public hideReportTokenConfigNote(): void {
        this.user.userInterfaceStates.reportTokenConfigNoticeClosed = true;
        this.saveUser();
    }

    /**
     * If a user manually changes the token, adjust the invoice number accordingly in either of two ways:
     * - extract: Extract the parts of the token according to the pattern from the token definition and fill the parts into the invoice number.
     * - overwrite: Simply copy the full report token to the invoice number.
     * @param force Fill the target despite a value existing there.
     * @param extractOrOverwrite - extract parts or simply copy and overwrite.
     */
    public async adjustInvoiceNumberToReportToken({
        force,
        extractOrOverwrite,
    }: {
        force?: boolean;
        extractOrOverwrite: 'extract' | 'overwrite';
    }) {
        /**
         * Only adjust if the report token is still present.
         */
        if (!this.report.token) {
            return;
        }
        /**
         * Only adjust the invoice number if it should be synced with the report token.
         */
        const isInvoiceNumberLeading = this.reportTokenService.isInvoiceNumberSyncedAndLeading(this.report);
        if (!isInvoiceNumberLeading) {
            return;
        }

        if (this.report.feeCalculation.invoiceParameters.number && !force) {
            this.wasAdjustingInvoiceNumberToReportTokenPrevented = true;
            return;
        }

        let reportTokenConfig: ReportTokenConfig;
        let invoiceNumberConfig: InvoiceNumberConfig;

        if (extractOrOverwrite === 'extract') {
            try {
                reportTokenConfig = this.reportTokenService.getReportTokenConfig(this.report.officeLocationId);
                invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.report.officeLocationId);
            } catch (error) {
                /**
                 * If the user did not explicitly requested to update the invoice number, do not display an error message.
                 * An error may happen if a user has changed his report token config and revisits the report to test the new settings.
                 * This could happen with new customers / testers which would be confused by the error message.
                 */
                if (!force) {
                    return;
                }
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getInvoiceNumberOrReportTokenCounterErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'Konfiguration des Aktenzeichens & der Rechnungsnummer nicht geladen',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            }

            let reportTokenPlaceholderValues: ReportTokenOrInvoiceNumberPlaceholderValues;
            try {
                const reportTokenPattern = new CounterPattern(reportTokenConfig.pattern);
                reportTokenPlaceholderValues = reportTokenPattern.extractPlaceholderValues(this.report.token);
            } catch (error) {
                /**
                 * If the user did not explicitly requested to update the invoice number, do not display an error message.
                 * An error may happen if a user has changed his report token config and revisits the report to test the new settings.
                 * This could happen with new customers / testers which would be confused by the error message.
                 */
                if (!force) {
                    return;
                }
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Aktenzeichen weicht von Muster ab',
                        body: `Bitte stelle sicher, dass dein Aktenzeichen dem Muster entspricht, das du in den <a href='/Einstellungen'>Einstellungen</a> definiert hast.<br><br>Aktuelles Muster: ${reportTokenConfig.pattern}, Aktenzeichen: ${this.report.token}`,
                    },
                });
            }

            try {
                const invoiceNumberPattern = new CounterPattern(invoiceNumberConfig.pattern);

                const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
                    reportId: this.report._id,
                });
                const fieldGroupConfigs =
                    await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

                const previousInvoiceNumber = this.report.feeCalculation.invoiceParameters.number;
                this.report.feeCalculation.invoiceParameters.number = invoiceNumberPattern.replaceAllPlaceholders({
                    shorthandPlaceholderValues: reportTokenPlaceholderValues,
                    regularPlaceholderValues: placeholderValues,
                    fieldGroupConfigs: fieldGroupConfigs,
                });

                this.invoiceNumberJournalEntryService.create({
                    entryType: 'invoiceNumberChangedManually',
                    documentType: 'report',
                    reportId: this.report._id,
                    invoiceNumber: this.report.token,
                    invoiceNumberConfigId: invoiceNumberConfig._id,
                    previousInvoiceNumber,
                });
            } catch (error) {
                /**
                 * If the user did not explicitly requested to update the invoice number, do not display an error message.
                 * An error may happen if a user has changed his report token config and revisits the report to test the new settings.
                 * This could happen with new customers / testers which would be confused by the error message.
                 */
                if (!force) {
                    return;
                }
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        NO_VALUE_FOUND_FOR_PLACEHOLDER_IN_PATTERN: (error) => ({
                            title: 'Wert für Platzhalter in Rechnungsnummer fehlt',
                            body: `Um die Rechnungsnummer zu generieren, muss der Platzhalter <strong>${error.data?.placeholder}</strong> auch im Aktenzeichen existieren. Der konnte dort aber nicht gefunden werden.<br><br>Bitte prüfe das Format des Aktenzeichens. Stelle sicher, dass alle Platzhalter im <a href="/Einstellungen">Muster für die Rechnungsnummer</a> auch im Muster für das Aktenzeichen existieren.`,
                        }),
                    },
                    defaultHandler: {
                        title: 'Rechnungsnummer nicht generiert',
                        body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>`,
                    },
                });
            }
        } else {
            this.report.feeCalculation.invoiceParameters.number = this.report.token;
            reportTokenConfig = this.reportTokenService.getReportTokenConfig(this.report.officeLocationId);
            await this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceNumberGeneratedManually',
                documentType: 'report',
                reportId: this.report._id,
                invoiceNumber: this.report.token,
                reportTokenConfigId: reportTokenConfig._id,
            });
        }

        this.wasAdjustingInvoiceNumberToReportTokenPrevented = false;
        this.toastService.success(
            'Rechnungsnummer angeglichen',
            `Neue Rechnungsnummer: ${this.report.feeCalculation.invoiceParameters.number}`,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Token
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Ordering Method
    //****************************************************************************/
    public setDefaultOrderingMethod(method: Report['orderingMethod'], event: MouseEvent): void {
        // De-select if the default is the same
        if (this.userPreferences.orderingMethod === method) {
            this.userPreferences.orderingMethod = null;
        } else {
            // Set default
            this.userPreferences.orderingMethod = method;
        }

        event.stopPropagation();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Ordering Method
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  GDV Responsible Insurance
    //****************************************************************************/
    public async getResponsibleInsuranceFromGdv() {
        if (!this.report.accident.date) {
            this.toastService.error('Unfalltag fehlt', 'Bitte gib den Unfalltag an.');
            return;
        }

        if (moment(this.report.accident.date).isAfter(undefined, 'days')) {
            this.toastService.error(
                'Unfalldatum liegt in der Zukunft',
                'Für die GDV-Abfrage ist ein gültiges Datum notwendig.',
            );
            return;
        }

        // Don't invoke twice in a row.
        if (this.gdvResponsibleInsuranceRequestPending) {
            return;
        }

        // Check credentials
        if (!isGdvUserComplete(this.user)) {
            this.toastService.error(
                'GDV-Zugangsdaten eintragen',
                "Trage deinen Account in den <a href='/Einstellungen#gdv-container'>Einstellungen</a> ein und versuche es erneut.",
            );
            return;
        }

        // Check license plate
        const licensePlate: string = getLicensePlateForGdvResponsibleInsuranceQuery(this.report);
        if (!licensePlate) {
            this.toastService.error(
                'Kennzeichen angeben',
                'Gib zuerst das Kennzeichen des versicherten Fahrzeugs ein.',
            );
            return;
        }

        // Since GDV queries are an online-only feature, block if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der GDV-Abruf ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.gdvResponsibleInsuranceRequestPending = true;
        let gdvResponse: ResponsibleInsuranceResponse;

        try {
            gdvResponse = await this.gdvResponsibleInsuranceService.find(this.report);

            // Shorthand
            const insuranceContact = this.report.insurance.contactPerson;

            // Insurance Contact Data
            insuranceContact.insuranceCompanyNumber = gdvResponse.insurance.insuranceCompanyNumber;
            insuranceContact.organization = gdvResponse.insurance.organization;
            insuranceContact.streetAndHouseNumberOrLockbox = gdvResponse.insurance.streetAndHouseNumberOrLockbox;
            insuranceContact.zip = gdvResponse.insurance.zip;
            insuranceContact.city = gdvResponse.insurance.city;
            insuranceContact.phone = gdvResponse.insurance.phone;
            insuranceContact.email = gdvResponse.insurance.email;

            // Insurance number
            if (this.report.authorOfDamage) {
                this.report.authorOfDamage.insuranceNumber = gdvResponse.insurancePolicyNumber;
            }

            // Match to the insurance contact to enhance contact data an provide information about residual value agreements to the report
            let matchingInsurances: ContactPerson[] = [];
            if (insuranceContact.insuranceCompanyNumber) {
                // Find matching insurance by VU-Number
                matchingInsurances = await this.contactPersonService
                    .find({
                        insuranceCompanyNumber: insuranceContact.insuranceCompanyNumber,
                        organizationType: 'insurance',
                    })
                    .toPromise();
            }

            // If insurance is identified by VU-Number
            if (matchingInsurances.length) {
                if (matchingInsurances.length > 1) {
                    // If we have multiple insurances with the same VU-Number, e.g. DEVK regional offices, filter by city
                    matchingInsurances = matchingInsurances.filter(
                        (insurance) => insurance.zip === insuranceContact.zip,
                    );
                }
            } else {
                // Find the insurance by the full name search
                matchingInsurances = await this.contactPersonService
                    .find({ $search: gdvResponse.insurance.organization, organizationType: 'insurance' })
                    .toPromise();

                // Otherwise try the first word and the city. Matching the city helps differentiate between "Öffentliche Lebensvers[...] Oldenburg" and "Öffentliche Sach[...] Braunschweig"
                // Only match to the first word of a city to avoid complex Atlas Search terms (the more constraints a search term has, the slower the search and the higher the change that there is no result).
                if (!matchingInsurances.length) {
                    const firstWordOfGdvResultName = insuranceContact.organization.split(' ')[0];
                    const firstWordOfGdvResultCity = insuranceContact.city.split(' ')[0];
                    matchingInsurances = await this.contactPersonService
                        .find({
                            $search: `${firstWordOfGdvResultName} ${firstWordOfGdvResultCity}`,
                            organizationType: 'insurance',
                        })
                        .toPromise();
                }
            }

            if (matchingInsurances.length) {
                // Insert the residual value exchange agreements
                insuranceContact.availableQuotaOnResidualValueExchanges =
                    matchingInsurances[0].availableQuotaOnResidualValueExchanges;

                // If GDV data is incomplete, complete it with data from us.
                if (matchingInsurances[0].email) {
                    insuranceContact.email = matchingInsurances[0].email;
                }
            }

            this.saveReport();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGdvErrorHandlers(),
                },
                defaultHandler: {
                    title: 'GDV-Abfrage fehlerhaft',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                },
            });
        } finally {
            this.gdvResponsibleInsuranceRequestPending = false;
        }
    }

    public toggleInsuranceInsuranceCompanyNumber(): void {
        this.userPreferences.insurance_displayInsuranceCompanyNumber =
            !this.userPreferences.insurance_displayInsuranceCompanyNumber;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END GDV Responsible Insurance
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab: 'accidentData',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Focus Input
    //****************************************************************************/
    public focusInput(viewChildName: 'vatIdInputClaimant' | 'policeCaseNumberInput', event: MatCheckboxChange): void {
        setTimeout(() => {
            if (event.checked && this[viewChildName]) {
                (this[viewChildName].nativeElement as HTMLInputElement).focus();
            }
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Focus Input
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public userIsAdmin(): boolean {
        return isAdmin(this.user._id, this.team);
    }

    public trackById = trackById;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save to server
    //****************************************************************************/

    public async saveReport() {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.warn('Gutachten nicht synchronisiert', 'Ist eine Internetverbindung vorhanden?');
            console.error('An error occurred while saving the report via the ReportService.', { error });
        }
    }

    public async saveUser() {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.toastService.error('Fehler beim Speichern');
        }
    }

    public async saveTeam(): Promise<Team> {
        try {
            return await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save to server
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Handlers
    //****************************************************************************/
    /**
     * Get the function to trigger a click event on pressing the space bar from the
     * shared module.
     *
     * @type {(event:KeyboardEvent)=>void}
     */
    public triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Handler
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    protected readonly isAdmin = isAdmin;
    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

<div class="card">
    <mat-form-field
        class="mat-block"
        *ngIf="formFieldType === 'text' || formFieldType === 'number' || formFieldType === 'currency'">
        <!--============================================-->
        <!-- Text -->
        <!--============================================-->
        <input
            *ngIf="formFieldType === 'text'"
            matInput
            [placeholder]="placeholder"
            [ngModel]="value == null ? null : value + ''"
            (ngModelChange)="value = $event"
            (change)="onChange(value)" />
        <!--============================================-->
        <!-- END Text -->
        <!--============================================-->
        <!--============================================-->
        <!-- Number -->
        <!--============================================-->
        <input
            *ngIf="formFieldType === 'number'"
            matInput
            number-input
            [minimumFractionDigits]="minimumFractionDigits"
            [maximumFractionDigits]="maximumFractionDigits"
            [placeholder]="placeholder"
            [number]="value == null ? null : +value"
            (numberChange)="handleNumberChange($event)"
            (change)="onChange(value)" />
        <!--============================================-->
        <!-- END Number -->
        <!--============================================-->
        <!--============================================-->
        <!-- Number -->
        <!--============================================-->
        <currency-input
            *ngIf="formFieldType === 'currency'"
            [placeholder]="placeholder"
            [value]="value == null ? null : +value"
            (valueChange)="value = $event"
            (change)="onChange(value)" />
        <!--============================================-->
        <!-- END Number -->
        <!--============================================-->

        <!--********** Suffix **********-->
        <span matSuffix *ngIf="suffix">{{ suffix }}</span>
    </mat-form-field>

    <div *ngIf="!(formFieldType === 'text' || formFieldType === 'number' || formFieldType === 'currency')">
        Please specify formFieldType!
    </div>
</div>

export const keysOfQuickSearchReports: string[] = [
    'token',
    'claimant.contactPerson.lastName',
    'claimant.contactPerson.firstName',
    'claimant.contactPerson.organization',
    'garage.contactPerson.organization',
    'lawyer.contactPerson.organization',
    'lawyer.caseNumber',
    'car.licensePlate',
    'car.vin',
    'car.make',
    'car.model',
    'insurance.contactPerson.organization',
    'insurance.caseNumber',
    'authorOfDamage.insuranceNumber',
    'claimant.contactPerson.phone',
    'claimant.contactPerson.email',
    'ownerOfClaimantsCar.contactPerson.lastName',
    'ownerOfClaimantsCar.contactPerson.firstName',
    'ownerOfClaimantsCar.contactPerson.organization',
    'authorOfDamage.contactPerson.lastName',
    'authorOfDamage.contactPerson.firstName',
    'authorOfDamage.contactPerson.organization',
    'ownerOfAuthorOfDamagesCar.contactPerson.lastName',
    'ownerOfAuthorOfDamagesCar.contactPerson.firstName',
    'ownerOfAuthorOfDamagesCar.contactPerson.organization',
    'state',
    'deletedAt',
    'responsibleAssessor',
    'completionDate',
    // Generic Properties
    'createdAt',
    'updatedAt',
    'createdBy',
];

export function removeInvalidFilenameCharacters(input: string): string {
    return (
        input
            // Remove quotes
            .replace(/"/g, '')
            // Remove line breaks. Relevant for photo descriptions (multiline field) in a ZIP download (filename === photo description)
            .replace(/(\r\n|\n|\r)/gm, '')
            // Special characters not allowed in filenames on Windows, Mac, Linux.
            .replace(/[<>:/\\|?*]/g, '_')
    );
}

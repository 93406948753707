import { Report } from '../../models/reports/report';
import { getReportTypeSpecificAllowedDocuments } from '../reports/add-report-type-specific-documents';
import { removeDocumentFromReport } from './remove-document-from-report';

/**
 * Not all document types are available in all report types. This function removes documents
 * that are not necessary for the type of the given report.
 */
export const removeUnnecessaryDocumentsFromReport = (report: Report) => {
    const allowedDocuments = getReportTypeSpecificAllowedDocuments(report);
    const documentsToBeRemoved = report.documents.filter((document) => !allowedDocuments.includes(document.type));
    for (const document of documentsToBeRemoved) {
        removeDocumentFromReport({
            document,
            report,
            documentGroup: 'report',
        });
    }
};

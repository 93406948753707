import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    Output,
} from '@angular/core';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { LinkTaskToRecordOverlayComponent } from './link-task-to-record-overlay.component';

/**
 * Connect a record (report, invoice) to a task.
 *
 * This is the anchor opening an overlay offering to set up the connection.
 */
@Directive({
    selector: '[linkTaskToRecordAnchor]',
    exportAs: 'linkTaskToRecordAnchor',
})
export class LinkTaskToRecordAnchor {
    constructor(
        private overlayService: Overlay,
        private injector: Injector,
        private elementRef: ElementRef,
    ) {}

    @Input() reportId: Report['_id'];
    @Input() invoiceId: Invoice['_id'];

    @Output() linkChange: EventEmitter<{ report: Report; invoice: Invoice }> = new EventEmitter();

    private overlayRef: OverlayRef;
    private componentRef: ComponentRef<LinkTaskToRecordOverlayComponent>;

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openOverlay() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 5,
                    },
                ])
                .withPush(false) // The default value of "withPush" is true but causes the overlay to overflow the viewport on small screens (disables viewportMargin).
                .withViewportMargin(20),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<LinkTaskToRecordOverlayComponent>(
            LinkTaskToRecordOverlayComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.overlayRef,
                    },
                ],
            }),
        );

        // Attach Component to Portal Outlet
        this.componentRef = this.overlayRef.attach(componentPortal);

        // Update component properties.
        this.componentRef.instance.reportId = this.reportId;
        this.componentRef.instance.invoiceId = this.invoiceId;

        // Subscribe to changes in component.
        this.componentRef.instance.linkChange.subscribe((newLinks) => {
            this.linkChange.emit(newLinks);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Report } from '../../models/reports/report';

export type ReportTypeGerman =
    | 'Haftpflicht'
    | 'Teilkasko'
    | 'Vollkasko'
    | 'Kurzgutachten'
    | 'Oldtimer-Bewertung'
    | 'Bewertung'
    | 'Zustandsbericht'
    | 'Gebrauchtwagencheck'
    | 'Rechnungsprüfung';

export function translateReportType(reportType: Report['type']): ReportTypeGerman {
    switch (reportType) {
        // Damage Reports
        case 'liability':
            return 'Haftpflicht';
        case 'shortAssessment':
            return 'Kurzgutachten';
        case 'partialKasko':
            return 'Teilkasko';
        case 'fullKasko':
            return 'Vollkasko';
        // Valuation Reports
        case 'valuation':
            return 'Bewertung';
        case 'oldtimerValuationSmall':
            return 'Oldtimer-Bewertung';
        case 'leaseReturn':
            return 'Zustandsbericht';
        case 'usedVehicleCheck':
            return 'Gebrauchtwagencheck';
        // Other
        case 'invoiceAudit':
            return 'Rechnungsprüfung';
    }
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container display-flex">
        <div id="collective-invoice-content" class="card card-with-padding">
            <h2>Sammelrechnung</h2>
            <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

            <div class="recipient-and-delivery-period-container">
                <div class="recipient-selector">
                    <img src="/assets/images/icons/claimant-blue.svg" />
                    <mat-form-field *ngIf="!selectedContactPerson">
                        <input
                            matInput
                            placeholder="Rechnungsempfänger"
                            [(ngModel)]="invoiceRecipientSearchTerm"
                            [matAutocomplete]="invoiceRecipientAutocomplete.autocomplete"
                            autocomplete="off" />
                        <address-autocompletion
                            #invoiceRecipientAutocomplete
                            [contactAutocompletionEnabled]="true"
                            [organizationTypeFilter]="['claimant']"
                            [showSuggestionsIfEmpty]="true"
                            [value]="invoiceRecipientSearchTerm"
                            [allowDeletion]="false"
                            (contactPersonSelected)="invoiceRecipientSelected($event)"></address-autocompletion>
                    </mat-form-field>
                    <ng-container *ngIf="selectedContactPerson">
                        <div class="selected-invoice-recipient">
                            <ng-container *ngIf="selectedContactPerson.organization">
                                <span>
                                    {{ selectedContactPerson.organization }}
                                </span>
                                <br />
                            </ng-container>
                            <span>
                                {{ selectedContactPerson.firstName || selectedContactPerson.salutation }}
                                {{ selectedContactPerson.lastName }}
                            </span>
                            <div
                                *ngIf="
                                    !selectedContactPerson.organization &&
                                    !selectedContactPerson.lastName &&
                                    !selectedContactPerson.firstName
                                ">
                                Keine Kontaktdaten
                            </div>
                            <br />
                            <span class="label">{{ selectedContactPerson.streetAndHouseNumberOrLockbox }}</span>
                            <br />
                            <span class="label">{{ selectedContactPerson.zip }} {{ selectedContactPerson.city }}</span>
                        </div>
                        <mat-icon class="large-icon toolbar-icon" (click)="removeSelectedContactPerson()">
                            close
                        </mat-icon>
                    </ng-container>
                </div>
                <div class="date-range-container">
                    <!-- Don't let prettier format the following inline elements (to prevent whitespace between heading and help indicator) -->
                    <!-- prettier-ignore -->
                    <h4 class="display-flex flex-align-center no-margin-bottom">Leistungszeitraum<span class="help-indicator" matTooltip="Wird auf der Rechnung abgedruckt und filtert die Liste der verfügbaren Gutachten nach Fertigstellungsdatum.">?</span></h4>
                    <date-range-picker
                        [(dateFrom)]="supplyPeriodStartDate"
                        [(dateTo)]="supplyPeriodEndDate"
                        (dateRangeChange)="dateRangeChanged()"
                        [stretch]="true"></date-range-picker>
                </div>
            </div>

            <div class="available-reports-container">
                <div class="available-reports-header">
                    <mat-checkbox
                        [checked]="areAllReportsSelected()"
                        (change)="setSelectionStatusForAllReports($event.checked)"></mat-checkbox>

                    <mat-form-field
                        id="reports-search-field"
                        class="search-field mat-block without-hint-spacing without-title-spacing"
                        floatLabel="never"
                        [matTooltip]="
                            'Diese Daten werden durchsucht: \n• Aktenzeichen\n• Kennzeichen (Format GZ-AB-1234)\n• Anspruchsteller Name, Telefon, Email\n• Werkstatt\n• Anwalt Name, Aktenzeichen\n• Versicherung\n• Versicherungsschein\n• Schadennummer\n• VIN\n• Hersteller & Modell\n• Fahrzeugfarbe\n\nSuchworte müssen mindestens drei Zeichen lang sein.'
                        ">
                        <input
                            matInput
                            [(ngModel)]="reportSearchTerm"
                            placeholder="Suchen"
                            (ngModelChange)="updateSearchTerm()" />
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>

                    <div class="filter-and-sort-container">
                        <analytics-filter-button [filterRef]="filter"></analytics-filter-button>

                        <div id="sort-select-and-direction-container">
                            <mat-icon
                                id="sort-direction-indicator"
                                class="toolbar-icon small-icon"
                                [ngClass]="{ flipped: sortReportListDescending }"
                                (click)="toggleSortDirection()"
                                [matTooltip]="
                                    sortReportListDescending ? 'Sortierung absteigend' : 'Sortierung aufsteigend'
                                ">
                                arrow_upward
                            </mat-icon>
                            <div
                                id="sort-reports-by-container"
                                class="ax-header-select"
                                matTooltip="Sortierung"
                                [matMenuTriggerFor]="sortMenu"
                                [ngSwitch]="sortReportListBy">
                                <span *ngSwitchCase="'name'">Nach Name</span>
                                <span *ngSwitchCase="'token'">Nach Aktenzeichen</span>
                                <span *ngSwitchCase="'automaticDate'">Nach Datum</span>
                                <div class="ax-header-select-arrow"></div>
                            </div>
                            <mat-menu #sortMenu="matMenu">
                                <a
                                    mat-menu-item
                                    (click)="selectSortStrategy('name')"
                                    matTooltip="Nach Organisation, Vorname, Nachname">
                                    Nach Name
                                </a>
                                <a
                                    mat-menu-item
                                    (click)="selectSortStrategy('automaticDate')"
                                    matTooltip="Offene Gutachten werden nach Erstellungsdatum, abgeschlossene nach Fertigstellungsdatum sortiert.">
                                    Nach Datum
                                </a>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <analytics-filter
                    (filterChange)="availableReportsFilterChanged()"
                    (initialized)="filtersLoaded()"
                    [showInvoiceLabelFilter]="false"
                    [showOfficeLocationFilter]="false"></analytics-filter>
                <div class="available-reports-list">
                    <table>
                        <tr
                            collective-invoice-report-list-row
                            (selectionChanged)="toggleReportSelection(availableReport)"
                            *ngFor="let availableReport of availableReports; trackBy: trackById"
                            [report]="availableReport"
                            [checked]="isReportSelected(availableReport)"></tr>
                        <div class="empty-state" *ngIf="!(availableReports?.length > 0)">
                            <img
                                class="no-records-to-display-image"
                                src="/assets/images/icons/no-data-to-display.png" />
                            <div class="empty-state-labels">
                                <div class="no-data-found-message">Keine Gutachten gefunden</div>
                                Die Sammelrechnung kann in einem Gutachten über das Dreipunktmenü im Reiter "Rechnung"
                                aktiviert werden. Dann erscheint das Gutachten in dieser Übersicht.
                            </div>
                        </div>
                        <tr
                            *ngIf="
                                availableReports.length > 0 &&
                                !isLoadMoreReportsPending &&
                                !allReportsLoadedWithCurrentFilters &&
                                !reportSearchTerm
                            ">
                            <div (intersectsViewport)="triggerLoadMoreReports()">
                                <span>Mehr Gutachten laden</span>
                            </div>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="selected-reports-container">
                <div class="summary-row" (click)="selectedReportsListExpanded = !selectedReportsListExpanded">
                    <div class="icon-and-text">
                        <mat-icon class="medium-icon">
                            {{ selectedReportsListExpanded ? 'unfold_less' : 'unfold_more' }}
                        </mat-icon>
                        <h4>
                            {{ selectedReports.length }} ausgewählte{{ selectedReports.length === 1 ? 's' : '' }}
                            Gutachten
                        </h4>
                    </div>
                    <b>{{ totalGrossSelectedReports() | convertToEuro }}</b>
                </div>

                <div *ngIf="selectedReportsListExpanded" class="selected-reports-list" @slideInAndOutVertically>
                    <table>
                        <tr
                            collective-invoice-report-list-row
                            (selectionChanged)="toggleReportSelection(selectedReport)"
                            *ngFor="let selectedReport of selectedReports; trackBy: trackById"
                            [report]="selectedReport"
                            [checked]="true"></tr>
                    </table>
                </div>
            </div>

            <div id="settings-container">
                <h4 class="no-margin-bottom display-flex flex-align-center">
                    Einstellungen
                    <mat-icon
                        *ngIf="team.preferences.collectiveInvoiceSummaryAppendix && !customLineItemTitleInputShown"
                        class="toolbar-icon medium-icon"
                        [matMenuTriggerFor]="collectiveInvoiceSummaryAppendixSubmenu">
                        more_vert
                    </mat-icon>
                </h4>
                <mat-menu #collectiveInvoiceSummaryAppendixSubmenu>
                    <a
                        mat-menu-item
                        class="menu-item-with-toggle"
                        (click)="showCustomLineItemTitleInput()"
                        matTooltip="Eigenen Titel für die zusammenfassenden Rechnungsposition vergeben.">
                        Positionstitel umbenennen
                    </a>
                </mat-menu>

                <preferences-row iconName="format_list_bulleted">
                    <span>Auflistung Gutachten</span>
                    <span class="label light">
                        Wo sollen die einzelnen Gutachten (AZ, Anspruchsteller, etc.) in der Rechnung aufgeführt werden?
                    </span>

                    <mat-select
                        right
                        class="styled"
                        [disabled]="!user.accessRights.editTextsAndDocumentBuildingBlocks"
                        [ngModel]="team.preferences.collectiveInvoiceSummaryAppendix ? 'appendix' : 'lineItem'"
                        (selectionChange)="reportSummaryAsAppendixChanged($event)">
                        <mat-option value="lineItem" matTooltip="">Eigene Rechnungsposition</mat-option>
                        <mat-option value="appendix" matTooltip="">Im Anhang</mat-option>
                    </mat-select>

                    <preferences-row
                        *ngIf="team.preferences.collectiveInvoiceSummaryAppendix && customLineItemTitleInputShown"
                        @slideInAndOutVertically>
                        <span>Positionstitel</span>
                        <span class="label light">
                            Wie soll der Titel der zusammenfassenden Rechnungsposition lauten?
                        </span>

                        <mat-form-field right class="without-hint-spacing">
                            <input
                                #customLineItemTitleInputField
                                matInput
                                placeholder="Titel"
                                [(ngModel)]="customLineItemTitle" />
                        </mat-form-field>
                    </preferences-row>

                    <preferences-row>
                        <span>Alle Rechnungspositionen der Gutachten</span>
                        <span class="label light">
                            Zu jedem Gutachten auch alle darin enthaltenen Rechnungspositionen abdrucken?
                        </span>

                        <mat-slide-toggle
                            right
                            [(ngModel)]="team.preferences.collectiveInvoiceIncludeInvoiceDetails"
                            (change)="saveTeam()"
                            [disabled]="!user.accessRights.editTextsAndDocumentBuildingBlocks"></mat-slide-toggle>
                    </preferences-row>
                </preferences-row>
            </div>

            <div class="dialog-buttons-container margin-top-10">
                <!--********** Create Invoice **********-->
                <button
                    #createInvoiceButton
                    (click)="createCollectiveInvoice()"
                    [ngClass]="{ disabled: creatingInvoiceInProgress }"
                    [disabled]="selectedReports.length <= 0"
                    [matTooltip]="createInvoiceButton.disabled ? 'Keine Gutachten für Sammelrechnung ausgewählt.' : ''">
                    <img
                        src="/assets/images/icons/loading-indicator-white.svg"
                        alt=""
                        class="pending-icon"
                        matTooltip="Sammelrechnung wird erstellt"
                        *ngIf="creatingInvoiceInProgress" />
                    Rechnung erstellen
                </button>
                <!--********** Invoice Preview **********-->
                <button
                    class="secondary"
                    (click)="downloadInvoicePreview()"
                    [disabled]="selectedReports.length <= 0"
                    [matTooltip]="createInvoiceButton.disabled ? 'Keine Gutachten für Sammelrechnung ausgewählt.' : ''">
                    <img
                        src="/assets/images/icons/loading-indicator.svg"
                        class="pending-icon"
                        *ngIf="invoicePreviewDownloadPending" />
                    Vorschau
                </button>
            </div>
        </div>
    </div>
</div>

<div class="dialog-overlay" @dialogEnterAndLeave>
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
        <h2>Standort</h2>
        <!--********** Title **********-->
        <mat-form-field class="mat-block">
            <input
                matInput
                placeholder="Titel (intern)"
                [(ngModel)]="officeLocation.title"
                (change)="emitChange()"
                matTooltip="Der Titel ist für die interne Wiedererkennung vorgesehen. Er wird nicht abgedruckt." />
        </mat-form-field>

        <!--********** Street & Housenumber **********-->
        <mat-form-field class="mat-block">
            <input
                matInput
                placeholder="Straße & Hausnr."
                [(ngModel)]="officeLocation.streetAndHouseNumber"
                (change)="emitChange()"
                [matAutocomplete]="streetAutocompletion.autocomplete"
                matTooltip="Der Titel ist für die interne Wiedererkennung vorgesehen. Er wird nicht abgedruckt." />
        </mat-form-field>
        <address-autocompletion
            #streetAutocompletion
            [value]="officeLocation.streetAndHouseNumber"
            [externalAddressAutocompletionEnabled]="true"
            [addressContext]="{ city: officeLocation.city }"
            (addressSelected)="insertAddressIntoModel($event)"></address-autocompletion>

        <!--********** ZIP & City **********-->
        <div class="place-group">
            <mat-form-field class="zipCode">
                <input
                    matInput
                    placeholder="PLZ"
                    name="zipCode"
                    [(ngModel)]="officeLocation.zip"
                    (change)="emitChange()"
                    zip-city-input
                    (cityChange)="insertCityIntoModel($event); emitChange()"
                    [street]="officeLocation.streetAndHouseNumber"
                    [city]="officeLocation.city"
                    maxlength="8" />
            </mat-form-field>
            <mat-form-field class="place">
                <input
                    matInput
                    placeholder="Ort"
                    name="place"
                    [(ngModel)]="officeLocation.city"
                    (change)="emitChange()" />
            </mat-form-field>
        </div>

        <!--********** Phone **********-->
        <mat-form-field class="mat-block">
            <input
                matInput
                placeholder="Telefon"
                [(ngModel)]="officeLocation.phone"
                (change)="emitChange()"
                matTooltip="Diese Telefonnummer landet im Platzhalter {Sachverständiger.Telefon}, wenn ein Sachverständiger keine eigene Telefonnummer hinterlegt hat. Nützlich, um einheitliche Festnetznummern pro Standort abzudrucken.{{
                    '\n\n'
                }}Direkter Platzhalter: {Sachverständiger.StandortTelefon}" />
        </mat-form-field>

        <!--********** Initials **********-->
        <div class="input-row">
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Kürzel"
                    [(ngModel)]="officeLocation.initials"
                    (change)="emitChange()"
                    matTooltip="Das Kürzel kann in der Rechnungsnummer und im Aktenzeichen verwendet werden." />
            </mat-form-field>

            <!--********** Cost Center **********-->
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Kostenstelle"
                    [(ngModel)]="officeLocation.costCenter"
                    (change)="emitChange()"
                    matTooltip="Die Kostenstelle wird in DATEV exportiert und ermöglicht es dir, bzw. deinem Steuerberater, unternehmerische Auswertungen wie z. B. den Betriebsabrechnungsbogen pro Standort zu erstellen. Die Standort-Kostenstelle wird in DATEV im Feld KOST1 angezeigt (wenn KOST1 durch die Kostenstelle des Sachverständigen belegt ist, in KOST2)." />
            </mat-form-field>
        </div>

        <div class="dialog-buttons-container">
            <button (click)="closeDialog()">Bearbeitung beenden</button>
        </div>
    </div>
</div>

import { DateTime } from 'luxon';
import { RequireSome } from '@autoixpert/helper-types/require-some';
import { IsoDate } from '../../lib/date/iso-date.types';
import { generateId } from '../../lib/generate-id';
import { DocumentLayoutGroup } from '../documents/document-layout-group';
import { Team } from '../teams/team';
import { User } from '../user/user';

/**
 * We use BilledLicense to keep track of the licenses of a team.
 *
 * A license is active, if startDate is in the past and endDate is not set or in the future.
 * A license can belong to a team, a user or a documentLayoutGroup.
 */
export class AxLicense {
    constructor(template: RequireSome<AxLicense, 'type' | 'startDate' | 'endDate' | 'teamId' | 'createdBy'>) {
        Object.assign(this, template);
    }
    _id: string = generateId();
    type: AxLicenseType;

    /**
     * Start and end date determine how the license will be billed. The Account Management Scheduler also contains
     * a function for billing.
     *
     * The license always starts on the first millisecond of the start date, e.g. 2023-11-16T00:00:00.000+01:00.
     * The license always ends on the last millisecond of the end date, e.g. 2023-12-15T23:59:59.999+01:00.
     */
    startDate: IsoDate;
    endDate: IsoDate;
    /**
     * The invoice number with which this license was billed to the user. This is useful for debugging and indicates
     * whether a license needs to be converted into an invoice item.
     */
    invoiceNumber?: string;

    /**
     * The team to which this license belongs.
     */
    teamId: Team['_id'];

    /**
     * These may be reassigned if the resource (user, document layout group, ...) is deactivated and another one is activated within the same billing period.
     *
     * The assigned user ID and the assigned document layout group are assigned on both a teamAccount license and more specific licenses (additionalUser and
     * additionalDocumentLayoutGroup) since a team account includes a license for each.
     */
    assignedUserId?: User['_id'];
    assignedDocumentLayoutGroupId?: DocumentLayoutGroup['_id'];

    /**
     * This may only be true on licenses of type "audatexAddon". In case a team deactivated their Audatex addon before their billing period expired,
     * they have the chance to reactivate it free of charge until the end of the billing period. That's what this field is for.
     */
    isAudatexAddonAssigned?: boolean;

    /**
     * Logs are created every time a resource is unassigned or reassigned. No log is created for the initial assignment.
     */
    logs?: AxLicenseLog[];

    createdAt: string = DateTime.now().toISO();
    updatedAt: string = DateTime.now().toISO();
    createdBy: string;

    // Won't be required for offline-usage but for live-sync so that this data is never accidentally overwritten.
    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

export enum AxLicenseType {
    TeamAccount = 'teamAccount',
    AdditionalUser = 'additionalUser',
    AudatexAddon = 'audatexAddon',
    AdditionalDocumentLayoutGroup = 'additionalDocumentLayoutGroup',
}

// export type AxLicenseType = 'teamAccount' | 'additionalUser' | 'audatexAddon' | 'additionalDocumentLayoutGroup';

export class AxLicenseLog {
    constructor(template: Partial<AxLicenseLog> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    action: 'unassign' | 'reassign';

    /**
     * User ID and document layout group ID may be reassigned to different resources of the same type. That's different
     * from the Audatex Addon and the Team Account - they are always assigned to the team to which this license belongs,
     * so not teamId or isAudatexAddonAssigned exists in a log entry.
     */
    userId?: User['_id'];
    documentLayoutGroupId?: DocumentLayoutGroup['_id'];

    createdAt: string;
    createdBy: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AudatexCalculationText } from '@autoixpert/models/reports/damage-calculation/audatex-calculation-text';

@Injectable()
export class AudatexCalculationTextService {
    constructor(private httpClient: HttpClient) {}

    private getApiPath(reportId: string): string {
        return `/api/v0/reports/${reportId}/audatexCalculationText`;
    }

    //*****************************************************************************
    //  Public API
    //****************************************************************************/
    public get(reportId: string): Observable<AudatexCalculationText> {
        return this.httpClient.get<AudatexCalculationText>(this.getApiPath(reportId));
    }

    public upsert(calculationText: AudatexCalculationText): Observable<AudatexCalculationText> {
        return this.httpClient
            .patch<AudatexCalculationText>(
                `${this.getApiPath(calculationText._id)}/${calculationText._id}`,
                calculationText,
            )
            .pipe(
                catchError((error) => {
                    if (error.code === 'RESOURCE_NOT_FOUND') {
                        return this.httpClient.post<AudatexCalculationText>(
                            this.getApiPath(calculationText._id),
                            calculationText,
                        );
                    } else {
                        throw error;
                    }
                }),
            );
    }

    public remove(reportId: string): Observable<string> {
        return this.httpClient.delete<string>(this.getApiPath(reportId));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Public API
    /////////////////////////////////////////////////////////////////////////////*/
}

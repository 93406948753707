import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { DocumentBuildingBlock } from '../../models/documents/document-building-block';
import { DocumentBuildingBlockCondition } from '../../models/documents/document-building-block-condition';
import { DocumentBuildingBlockConditionGroup } from '../../models/documents/document-building-block-condition-group';
import { BadRequest, UnprocessableEntity } from '../../models/errors/ax-error';
import { makeLuxonDateTime } from '../ax-luxon';
import { replacePlaceholders } from '../documents/replace-document-building-block-placeholders';
import { PlaceholderValueTree } from '../placeholder-values/get-placeholder-value-tree';
import { isDocumentBuildingBlockConditionGroup } from './is-document-building-block-condition-group';

export function chooseDocumentBuildingBlocks({
    documentBuildingBlocks,
    placeholderValues,
    placeholderValueTree,
}: {
    documentBuildingBlocks: DocumentBuildingBlock[];
    placeholderValues: any;
    placeholderValueTree: PlaceholderValueTree;
}): DocumentBuildingBlock[] {
    const chosenDocumentBuildingBlocks: DocumentBuildingBlock[] = [];

    // Iterate over all text building blocks, evaluate their variants' conditions and return an object with a heading and content for each text building block.
    for (const documentBuildingBlock of documentBuildingBlocks) {
        // Filter out building blocks that have top-level conditions that are not met.
        if (
            !isBuildingBlockConditionMet({
                condition: documentBuildingBlock,
                placeholderValues,
                placeholderValueTree,
            })
        ) {
            continue;
        }

        // Check building block variants
        const documentBuildingBlockWithChosenVariantsOnly: DocumentBuildingBlock = JSON.parse(
            JSON.stringify(documentBuildingBlock),
        );
        documentBuildingBlockWithChosenVariantsOnly.variants = [];

        for (const variant of documentBuildingBlock.variants) {
            // Skip default variants in this step, they are handled later.
            if (variant.isDefaultVariant) {
                continue;
            }

            // If the variant has no conditions, it is always met.
            let allConditionsAreMet = true;

            // If the variant has conditions, evaluate them.
            if ((variant.conditions ?? []).length > 0) {
                // Evaluate the conditions of the variant using the correct conditions operator (AND or OR).
                const conditionsArrayConcatOperator = variant.conditionsOperator === 'or' ? 'some' : 'every';
                allConditionsAreMet = (variant.conditions ?? [])[conditionsArrayConcatOperator]((condition) => {
                    try {
                        return isBuildingBlockConditionMet({
                            condition,
                            placeholderValues,
                            placeholderValueTree,
                        });
                    } catch (error) {
                        // Enhance the error object with relevant data that help the user debug the invalid condition.
                        if (error.data) {
                            error.data.documentBuildingBlock = documentBuildingBlock;
                            error.data.variantIndex = documentBuildingBlock.variants.indexOf(variant);
                        }
                        throw error;
                    }
                });
            }

            // If the text building block are met, or it does not have any conditions add heading and content.
            if (allConditionsAreMet) {
                documentBuildingBlockWithChosenVariantsOnly.variants.push(variant);
            }
        }

        // If no variant is matched, check for a default variant.
        const defaultVariant = documentBuildingBlock.variants.find((variant) => variant.isDefaultVariant);
        if (!documentBuildingBlockWithChosenVariantsOnly.variants.length && defaultVariant) {
            documentBuildingBlockWithChosenVariantsOnly.variants.push(defaultVariant);
        }

        // If at least one variant is matched, add the building block to the chosen building blocks.
        if (documentBuildingBlockWithChosenVariantsOnly.variants.length) {
            chosenDocumentBuildingBlocks.push(documentBuildingBlockWithChosenVariantsOnly);
        }
    }

    return chosenDocumentBuildingBlocks;
}

/**
 * Document building blocks and document building block variants can contain conditions that depend on the values of placeholders.
 * This function evaluates a condition or condition group against the given template placeholder values.
 */
export function isBuildingBlockConditionMet({
    condition,
    placeholderValues,
    placeholderValueTree,
}: {
    condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup;
    placeholderValues: any;
    placeholderValueTree: PlaceholderValueTree;
}): boolean {
    // Check if condition is a group of conditions, if so recursively evaluate each condition.
    if (isDocumentBuildingBlockConditionGroup(condition)) {
        // If the group has no conditions, it is always met.
        if (condition.conditions.length === 0) return true;

        // Evaluate the conditions in the group using the correct conditions operator (AND or OR).
        const conditionsArrayConcatOperator = condition.conditionsOperator === 'or' ? 'some' : 'every';
        return condition.conditions[conditionsArrayConcatOperator]((condition) => {
            return isBuildingBlockConditionMet({
                condition,
                placeholderValues,
                placeholderValueTree,
            });
        });
    }

    // The object that contains the information how to compare the report value with the given condition value.
    const comparisonOperator = condition.operator;
    let comparisonValue: string | number = replacePlaceholders({
        // Typecast the comparison value to allow replacing placeholders in numbers like repair costs.
        textWithPlaceholders: `${condition.comparisonValue}`,
        placeholderValues,
        placeholderValueTree,
        isHtmlAllowed: false,
    });

    // Save the value of the property located at the given path
    let pathValue = get(placeholderValues, condition.propertyPath);
    let valueType = get(placeholderValueTree, condition.propertyPath);

    //*****************************************************************************
    //  Transform Value Depending on Type
    //****************************************************************************/
    // E.g. an enum. An enum can also have an array type, e.g. Schadenskalkulation.Minderwert.MethodenNamen
    if (valueType && typeof valueType === 'object' && 'type' in valueType) {
        valueType = valueType.type;
    }
    // E.g. "['String']"
    if (Array.isArray(valueType)) {
        valueType = valueType[0];
    }

    switch (valueType) {
        case 'String':
            // Set a default value in case the path value is undefined
            if (!pathValue) pathValue = '';

            // Remove possible DOCX XML codes
            pathValue = pathValue.replace(/(<([^>]+)>)/gi, '');
            break;
        case 'Boolean':
            pathValue = !!pathValue;
            break;
        case 'Tristate': {
            // Do nothing. The value remains the same as in the placeholder value tree: true => "Ja", false => "Nein", undefined/null => "unbekannt".
            break;
        }
        case 'Number':
            // Do not treat empty strings, undefined or null as numbers. That way, the user can differentiate between a value of 0 and ''
            // in the document building blocks. Example: residual value.
            if ([undefined, null, ''].includes(pathValue)) break;

            pathValue = parseFloat(
                pathValue
                    .toString()
                    // Remove German 1000 dividers
                    .replace(/\./g, '')
                    // Replace German decimal separator (comma) with computer-readable period.
                    .replace(/,/g, '.'),
            );
            // Through replacing placeholders, the comparison value is always a string. Typecast it to a number.
            comparisonValue = parseFloat(comparisonValue);
            break;
        case 'Date':
            pathValue = pathValue ? DateTime.fromFormat(pathValue, 'dd.MM.yyyy').startOf('day').toMillis() : undefined;
            comparisonValue = makeLuxonDateTime(comparisonValue).startOf('day').toMillis();
            break;
        case 'Date-month-only':
            pathValue = pathValue ? DateTime.fromFormat(pathValue, 'MM.yyyy').startOf('month').toMillis() : undefined;
            comparisonValue = makeLuxonDateTime(comparisonValue).startOf('month').toMillis();
            break;
        case 'Time':
            pathValue = pathValue ? DateTime.fromFormat(pathValue, 'HH:mm').toMillis() : undefined;
            comparisonValue = DateTime.fromFormat(comparisonValue, 'HH:mm').toMillis();
            break;
        default:
            // If this error is thrown, go check if the propertyPath you're trying to access is listed in the tree in placeholder-value-types.js
            throw new UnprocessableEntity({
                code: 'DOCUMENT_BUILDING_BLOCK_PLACEHOLDER_VALUE_TYPE_NOT_DEFINED',
                message: `Please make sure that the given placeholder type is defined so that it can be compared correctly. Tried valueType '${valueType}' of path '${condition.propertyPath}'`,
                data: {
                    invalidPath: condition.propertyPath,
                },
            });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Transform Value Depending on Type
    /////////////////////////////////////////////////////////////////////////////*/

    // Type casting is not required here since Mongoose outputs type cast values for us.
    switch (comparisonOperator) {
        case 'equal':
            return pathValue === comparisonValue;
        case 'notEqual':
            return pathValue !== comparisonValue;
        case 'lessThan':
            return pathValue < comparisonValue;
        case 'lessThanOrEqual':
            return pathValue <= comparisonValue;
        case 'greaterThan':
            return pathValue > comparisonValue;
        case 'greaterThanOrEqual':
            return pathValue >= comparisonValue;
        case 'empty':
            // Zero-values are not the same as empty. E.g. if the user has left the residual value empty, that's different from entering a zero.
            return (
                pathValue === null ||
                typeof pathValue === 'undefined' ||
                pathValue === '' ||
                (Array.isArray(pathValue) && !pathValue.length)
            );
        case 'notEmpty':
            return !(
                pathValue === null ||
                typeof pathValue === 'undefined' ||
                pathValue === '' ||
                (Array.isArray(pathValue) && !pathValue.length)
            );
        // Handle both tristates and booleans for truthy & falsy.
        case 'truthy':
            return valueType === 'Tristate' ? pathValue === 'Ja' : !!pathValue;
        case 'falsy':
            return valueType === 'Tristate' ? pathValue === 'Nein' : !pathValue;
        // "unknown" is only available in tristate fields.
        case 'unknown':
            return pathValue === 'unbekannt';

        case 'includes': {
            const lowerCaseComparisonValue: string = `${comparisonValue}`.toLowerCase();
            if (Array.isArray(pathValue)) {
                return pathValue.some(
                    (pathValueArrayElement) => `${pathValueArrayElement}`.toLowerCase() === lowerCaseComparisonValue,
                );
            } else if (typeof pathValue === 'string') {
                return pathValue.toLowerCase().includes(lowerCaseComparisonValue);
            }
            return false;
        }
        case 'includesNot':
            const lowerCaseComparisonValue: string = `${comparisonValue}`.toLowerCase();
            if (Array.isArray(pathValue)) {
                return !pathValue.some(
                    (pathValueArrayElement) => `${pathValueArrayElement}`.toLowerCase() === lowerCaseComparisonValue,
                );
            } else if (typeof pathValue === 'string') {
                return !pathValue.toLowerCase().includes(lowerCaseComparisonValue);
            }
            return false;
        default:
            throw new BadRequest({
                code: 'INVALID_OPERATOR',
                message:
                    'The given comparison operator of the document building block variant is invalid. Please specify one of the predefined comparison operators.',
                data: {
                    invalidOperator: comparisonOperator,
                },
            });
    }
}

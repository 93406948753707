import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { GarageFeeSet, PaintMaterialSurcharge } from '@autoixpert/models/contacts/garage-fee-set';
import { Car } from '@autoixpert/models/reports/car-identification/car';

export function getApplicablePaintMaterialSurcharge(
    paintType: Car['paintType'],
    feeSet: GarageFeeSet,
): ApplicablePaintMaterialSurcharge {
    if (!feeSet) {
        return {
            paintType: 'default',
            value: null,
        };
    }

    if (paintType) {
        const paintTypeLowerCase: string = paintType.toLowerCase();

        // By starting with the largest surcharge (sorted descending), we make sure that the higher surcharge is chosen for combined paints (e.g. "2-Schicht (uni/met.)")
        const materialSurchargesSorted: PaintMaterialSurcharge[] = [
            ...feeSet.carPaint.materialSurchargesByPaintType,
        ].sort(sortByProperty('value', 'descending'));

        // Is there a matching material surcharge for the given paint type?
        for (const materialSurcharge of materialSurchargesSorted) {
            switch (materialSurcharge.paintType) {
                case 'uni':
                    if (paintTypeLowerCase.includes('uni')) {
                        return {
                            paintType: 'uni',
                            value: materialSurcharge.value,
                        };
                    }
                    break;
                case 'metallic':
                    if (paintTypeLowerCase.includes('metallic') || paintTypeLowerCase.includes('met.')) {
                        return {
                            paintType: 'metallic',
                            value: materialSurcharge.value,
                        };
                    }
                    break;
                case 'perlcolor':
                    if (
                        paintTypeLowerCase.includes('perlcolor') ||
                        paintTypeLowerCase.includes('perleffekt') ||
                        paintTypeLowerCase.includes('perlmutt')
                    ) {
                        return {
                            paintType: 'perlcolor',
                            value: materialSurcharge.value,
                        };
                    }
                    break;
            }
        }
    }

    // No match or no paint type given -> Return default.
    return {
        paintType: 'default',
        value: feeSet.carPaint.materialSurchargeDefault,
    };
}

export interface ApplicablePaintMaterialSurcharge {
    paintType: PaintMaterialSurcharge['paintType'] | 'default';
    value: PaintMaterialSurcharge['value'];
}

import moment from 'moment';
import { RequireSome } from '../../helper-types/require-some';
import { SomeOptional } from '../../helper-types/some-optional';
import { generateId } from '../../lib/generate-id';
import { DocumentBuildingBlockCondition } from '../documents/document-building-block-condition';
import {
    DocumentBuildingBlockConditionGroup,
    DocumentBuildingBlockConditionGroupOperator,
} from '../documents/document-building-block-condition-group';
import { SignableDocumentType } from '../documents/document-metadata';
import { DataTypeBase } from '../indexed-db/database.types';

/**
 * In autoiXpert, the claimant may either sign...
 * - a document generated from document building blocks (text-only)
 * - a PDF file uploaded by the assessor. The assessor may define a signature area and checkboxes to be filled out by the claimant.
 */
export class SignablePdfTemplateConfig implements DataTypeBase {
    constructor(template: Partial<SignablePdfTemplateConfig> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string;
    documentType: SignableDocumentType;
    customDocumentOrderConfigId?: string;
    // The same condition structure as in the document building blocks is used.
    conditions: Array<DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup> = [];
    conditionsOperator: DocumentBuildingBlockConditionGroupOperator = 'and';

    pdfUploaded: boolean;

    // This will be set as soon as the user uploaded a PDF file. From the PDF file, the preview image is rendered.
    pages: SignablePdfPage[] = [];

    // use autocomplete to get contact for power of attorney, only simplified use in frontend
    useContactForPowerOfAttorney: boolean;

    defaultFont: 'Arial' | 'Source Sans Pro' = 'Arial';
    defaultFontColorInHex: string = '#3c3c3c';
    defaultFontSizeInPt: number = 11;

    /**
     * Are any checkboxes required before the document may be saved/closed?
     *
     * Yes/No checkbox pairs: either one has to be filled out.
     * Their target property will be stored in this array.
     *
     * Custom checkbox: Its _id property will be stored in this array.
     *
     * Signatures are always required, so they don't need to be listed here.
     */
    requiredCheckboxes: (CheckboxElement['targetProperty'] | CheckboxElement['_id'])[] = [];

    // Archived = deleted but still available for older reports to render their documents with this template.
    archivedAt: string;
    createdAt: string = moment().format();
    updatedAt: string = moment().format();
    createdBy: string;
    teamId: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

/**
 * A page of the PDF document, containing all elements visible on the PDF page.
 */
export class SignablePdfPage {
    constructor(template: RequireSome<SignablePdfPage, 'pageNumber'>) {
        Object.assign(this, template);
    }

    _id: string = generateId();

    /**
     * One-based index.
     */
    pageNumber: number;

    textElements: TextElement[] = [];
    signatureElements: SignatureElement[] = [];
    checkboxElements: CheckboxElement[] = [];
}

/**
 * Base class for more specific elements below.
 */
class PdfTemplateElement {
    // The ID is required for merging arrays through the offline-sync system in case of merge conflicts.
    _id: string = generateId();

    type: 'text' | 'checkbox' | 'signature';

    pageNumber: number;

    topRelativeToPageHeight: number;
    leftRelativeToPageWidth: number;

    // The image proportions stay constant, so the width will be derived from the height.
    widthRelativeToPageWidth: number;
    heightRelativeToPageHeight: number;
}

export class TextElement extends PdfTemplateElement {
    constructor(template: SomeOptional<TextElement, 'type' | '_id'>) {
        super();
        Object.assign(this, template);
    }

    type = 'text' as const;

    font: SignablePdfTemplateConfig['defaultFont'];
    fontSizeInPt: number;
    /**
     * An optional hex string. If none is provided, the color will be black.
     */
    colorInHex?: string;

    content: string;
}

export class SignatureElement extends PdfTemplateElement {
    constructor(template: SomeOptional<SignatureElement, 'type' | '_id'>) {
        super();
        Object.assign(this, template);
    }

    type = 'signature' as const;
}

export class CheckboxElement extends PdfTemplateElement {
    constructor(template: SomeOptional<CheckboxElement, 'type' | '_id'>) {
        super();
        Object.assign(this, template);
    }

    type = 'checkbox' as const;

    /**
     * If the customer checks this checkbox, what property in the report shall be set?
     */
    targetProperty: 'claimantMayDeductVat' | 'vehicleHasPreviousDamages';

    /**
     * Is this checkbox associated with another checkbox regarding a yes/ no answer?
     */
    checkboxPairId?: string;

    /**
     * If the user activates this checkbox,
     */
    answer: 'yes' | 'no';

    colorInHex: string;

    label?: string;
}

export type PdfElement = TextElement | CheckboxElement | SignatureElement;

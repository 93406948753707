import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { DraggableOptions } from '@interactjs/actions/drag/plugin';
import { ResizableOptions } from '@interactjs/actions/resize/plugin';
import { Interactable } from '@interactjs/core/Interactable';
import interact from 'interactjs';

/**
 * Make any element resizable and draggable.
 * *
 * The name part interact comes from the library interactjs.
 */
@Directive({
    selector: '[interactResizable]',
    host: {
        // Resize
        '[style.width.px]': 'this.widthInPixels',
        '[style.height.px]': 'this.heightInPixels',

        // Drag
        '[class.is-dragged]': 'this.currentlyDragged',
        '[style.position]': "'absolute'",
        '[style.left.px]': 'this.xPositionInPixels',
        '[style.top.px]': 'this.yPositionInPixels',
    },
})
export class ResizableDirective implements OnInit {
    //*****************************************************************************
    //  Resize
    //****************************************************************************/
    /**
     * Dimensions
     * - in pixels or
     * - relative the page (pageWidth and pageHeight must be set).
     */
    @Input() width: number = 0;
    @Input() height: number = 0;
    @Output() widthChange: EventEmitter<number> = new EventEmitter();
    @Output() heightChange: EventEmitter<number> = new EventEmitter();
    @Output() sizeChange: EventEmitter<number> = new EventEmitter();

    /**
     * Careful, the typing and the docs are a bit out of date.
     * E.g. the square option has been replaced by the aspectRatio modifier.
     */
    @Input() resizeOptions: Partial<ResizableOptions> | boolean;
    @Input() aspectRatio: number;

    /**
     * Translated from inputs which may be either in pixels or in percent relative
     * to the parent page dimensions.
     * Update happens in ngAfterChanges.
     */
    public widthInPixels: number = 0;
    public heightInPixels: number = 0;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Resize
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Drag
    //****************************************************************************/
    /**
     * Positions from top left of container
     * - in pixels or
     * - relative the page (pageWidth and pageHeight must be set).
     */
    @Input() xPosition: number = 0;
    @Input() yPosition: number = 0;
    @Output() xPositionChange: EventEmitter<number> = new EventEmitter();
    @Output() yPositionChange: EventEmitter<number> = new EventEmitter();

    @Input() dragOptions: Partial<DraggableOptions> | boolean;

    @Output() draggableClick = new EventEmitter();
    @Output() dragMove = new EventEmitter();
    @Output() dragEnd = new EventEmitter();

    /**
     * Translated from inputs which may be either in pixels or in percent relative
     * to the parent page dimensions.
     * Update happens in ngAfterChanges.
     */
    public xPositionInPixels: number = 0;
    public yPositionInPixels: number = 0;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Drag
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Parent Height & Width
    //****************************************************************************/
    /**
     * If a parent height and width in pixels in supplied, all dimension values are
     * returned in percent relative to that parent.
     * Otherwise, we return in pixels (= divided by 1).
     */
    @Input() pageWidth: number = 1;
    @Input() pageHeight: number = 1;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Parent Height & Width
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Not draggable and not resizable.
     */
    @Input() interactDisabled: boolean;

    /**
     * To increase performance, we use translate3d to drag an element around.
     * Only after dragging has finished, we persist the final position.
     */
    private dragDeltaX: number = 0;
    private dragDeltaY: number = 0;

    public currentlyDragged = false;

    private interactJsInstance: Interactable;

    constructor(private element: ElementRef) {}

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    ngOnInit(): void {
        this.registerInteractions();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Input Changes
    //****************************************************************************/

    /**
     * Convert from potentially relative units (percent) to pixels that are used by
     * our underlying library.
     *
     * These setters make sure the units are automatically assumed as percentages
     * as soon as pageWidth & pageHeight are set.
     */
    ngOnChanges(changes: { [prop in keyof ResizableDirective]: SimpleChange }) {
        if (changes['width'] || changes['pageWidth']) {
            this.widthInPixels = this.width * this.pageWidth;
        }
        if (changes['height'] || changes['pageHeight']) {
            this.heightInPixels = this.height * this.pageHeight;
        }
        if (changes['xPosition'] || changes['pageWidth']) {
            this.xPositionInPixels = this.xPosition * this.pageWidth;
        }
        if (changes['yPosition'] || changes['pageHeight']) {
            this.yPositionInPixels = this.yPosition * this.pageHeight;
        }
        if (changes['interactDisabled'] != null) {
            if (this.interactDisabled) {
                this.unregisterInteractions();
            } else {
                this.registerInteractions();
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Input Changes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Register & Unregister InteractJS Instance
    //****************************************************************************/
    /**
     * Every time the readonly value changes, we must (un-)register all Interactable functionality.
     */
    private registerInteractions() {
        if (this.interactJsInstance) return;

        if (this.interactDisabled) return;

        this.interactJsInstance = interact(this.element.nativeElement)
            //*****************************************************************************
            //  Resizable
            //****************************************************************************/
            .resizable(
                Object.assign(
                    {
                        // resize from all edges and corners
                        edges: {
                            left: true,
                            right: true,
                            bottom: true,
                            top: true,
                        },

                        // The default edges are about 30-50px thick. Reduce their size to allow dragging small elements too.
                        margin: 7,
                        modifiers: [
                            // Keep the edges inside the parent
                            interact.modifiers.restrictEdges({
                                outer: 'parent',
                            }),
                            // Minimum size
                            interact.modifiers.restrictSize({
                                min: {
                                    width: 15,
                                    height: 15,
                                },
                            }),
                            this.aspectRatio
                                ? interact.modifiers.aspectRatio({
                                      // ratio may be the string 'preserve' to maintain the starting aspect ratio,
                                      // or any number to force a width/height ratio.
                                      ratio: this.aspectRatio,
                                  })
                                : null,
                        ].filter(Boolean),
                        listeners: {
                            move: (event) => {
                                const target = event.target;

                                // Update the element's dimensions
                                this.widthInPixels = event.rect.width;
                                this.heightInPixels = event.rect.height;

                                this.dragDeltaX += event.deltaRect.left;
                                this.dragDeltaY += event.deltaRect.top;

                                target.style.transform = `translate3d(${this.dragDeltaX}px, ${this.dragDeltaY}px, 0)`;
                            },
                            end: (event) => {
                                event.target.style.transform = 'none';

                                // Update the final position.
                                this.xPositionInPixels += this.dragDeltaX;
                                this.yPositionInPixels += this.dragDeltaY;

                                // Clear the drag deltas.
                                this.dragDeltaX = 0;
                                this.dragDeltaY = 0;

                                // Let the parent know about the final dimensions and position.
                                this.emitWidth();
                                this.emitHeight();
                                this.emitXPositionChange();
                                this.emitYPositionChange();

                                this.dragEnd.emit();
                                this.currentlyDragged = false;
                            },
                        },
                    },
                    this.resizeOptions || {},
                ),
            )
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Resizable
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Draggable
            //****************************************************************************/
            .draggable(
                Object.assign(
                    {
                        // keep the edges inside the parent
                        modifiers: [
                            interact.modifiers.restrictRect({
                                restriction: 'parent',
                                endOnly: false,
                            }),
                        ],
                        listeners: {
                            /**
                             * When dragging is in progress...
                             * - move element according to drag deltas.
                             * - add a class.
                             */
                            move: (event) => {
                                const target = event.target;
                                this.dragDeltaX += event.dx;
                                this.dragDeltaY += event.dy;

                                target.style.transform = `translate3d(${this.dragDeltaX}px, ${this.dragDeltaY}px, 0)`;

                                this.currentlyDragged = true;

                                this.dragMove.emit({
                                    xPosition: this.xPosition + this.dragDeltaX,
                                    yPosition: this.yPosition + this.dragDeltaY,
                                });
                            },
                            /**
                             * When dragging ends...
                             * - add the drag deltas to the absolute positions.
                             * - remove the class.
                             */
                            end: (event) => {
                                event.target.style.transform = 'none';

                                // Update the final position.
                                this.xPositionInPixels += this.dragDeltaX;
                                this.yPositionInPixels += this.dragDeltaY;
                                this.emitXPositionChange();
                                this.emitYPositionChange();

                                // Clear the drag deltas.
                                this.dragDeltaX = 0;
                                this.dragDeltaY = 0;

                                this.dragEnd.emit({
                                    xPosition: this.xPosition,
                                    yPosition: this.yPosition,
                                });
                                this.currentlyDragged = false;
                            },
                        },
                    },
                    this.dragOptions || {},
                ),
            );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Draggable
        /////////////////////////////////////////////////////////////////////////////*/
    }

    private unregisterInteractions() {
        this.interactJsInstance?.unset();
        this.interactJsInstance = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Register & Unregister InteractJS Instance
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Host Listeners
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public onClick(): void {
        if (!this.currentlyDragged) {
            this.draggableClick.emit();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Host Listeners
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    private emitWidth() {
        this.widthChange.emit(this.widthInPixels / this.pageWidth);
        this.sizeChange.emit();
    }

    private emitHeight() {
        this.heightChange.emit(this.heightInPixels / this.pageHeight);
        this.sizeChange.emit();
    }

    private emitXPositionChange() {
        this.xPositionChange.emit(this.xPositionInPixels / this.pageWidth);
    }

    private emitYPositionChange() {
        this.yPositionChange.emit(this.yPositionInPixels / this.pageHeight);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.unregisterInteractions();
    }
}

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ApiErrorHandler } from '@autoixpert/abstract-services/api-error.abstract.service';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { getOutgoingMessageStatus } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-status';
import { OutgoingMessage } from '@autoixpert/models/outgoing-message';
import { Recipient } from '@autoixpert/models/recipient';
import { User } from '@autoixpert/models/user/user';
import { getEmailApiErrorHandlers } from '../../libraries/error-handlers/get-email-api-error-handlers';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'outgoing-message-dialog',
    templateUrl: 'outgoing-message-dialog.component.html',
    styleUrls: ['outgoing-message-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class OutgoingMessageDialog {
    constructor(
        private userService: UserService,
        public userPreferences: UserPreferencesService,
    ) {}

    @Input() recipient: Recipient;
    @Input() outgoingMessage: OutgoingMessage;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    public user: User;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {}

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  General
    //****************************************************************************/

    get headline() {
        const status = getOutgoingMessageStatus(this.outgoingMessage);
        switch (status) {
            case 'draft':
                return 'E-Mail Entwurf';

            case 'scheduled':
                return 'Vorgemerkte E-Mail';

            case 'sent':
                return 'Versendete E-Mail';

            case 'delivered':
                return 'Versendete E-Mail';

            case 'read':
                return 'Versendete E-Mail';

            case 'failed':
                return 'Fehlgeschlagene E-Mail';
        }
    }

    getErrorInfo(outgoingMessage: OutgoingMessage) {
        if (!outgoingMessage.error?.message) return null;

        const errorHandler = getEmailApiErrorHandlers()[outgoingMessage.error.code] as ApiErrorHandler;

        return {
            title: errorHandler?.title || 'Fehler beim Versand',
            body: errorHandler?.body || outgoingMessage.error.message,
        };
    }
    public closeDialog(): void {
        this.close.emit();
    }

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END General
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Formatting
    //****************************************************************************/
    public extractNameFromEmail(email: string): string {
        if (!email) return null;

        const nameBeforeAt: string = email.split('@')[0];
        const nameParts: string[] = nameBeforeAt.split('.');

        // Capitalize first letter of name part and join with single space.
        if (nameParts.length > 1) {
            return nameParts.map((namePart) => namePart.slice(0, 1).toUpperCase() + namePart.slice(1)).join(' ');
        }
        // Don't return anything if we couldn't recognize the name.
        return null;
    }

    public getUsersFullName(userId: string): string {
        return this.userService.getTeamMembersName(userId);
    }

    public getUsersEmailAddress(userId: string): string {
        const user = this.userService.getTeamMemberFromCache(userId);
        return user.email;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Formatting
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public closeEditorOnEscKey(event) {
        if (event.key === 'Escape') {
            this.close.emit();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {}
}

<!--============================================-->
<!-- Text Block Document -->
<!--============================================-->
<ng-container *ngIf="selectedSignableDocument && !selectedSignablePdfTemplateConfig">
    <!--============================================-->
    <!-- Document Content -->
    <!--============================================-->
    <div id="text-block-document-container">
        <!--============================================-->
        <!-- Header -->
        <!--============================================-->
        <h2 *ngIf="dipslayTitle">{{ getTabsHeading() }}</h2>
        <!--============================================-->
        <!-- END Header -->
        <!--============================================-->

        <div *ngFor="let buildingBlock of visibleDocumentBuildingBlocks; trackBy: trackById">
            <h3 class="building-block-heading" *ngIf="buildingBlock.heading">
                {{ buildingBlock.heading || buildingBlock.placeholder }}
            </h3>
            <div
                class="building-block-body"
                *ngIf="buildingBlock.content && !['Honorarvereinbarung'].includes(buildingBlock.placeholder)"
                [quillContent]="buildingBlock.content"></div>

            <ng-container [ngSwitch]="buildingBlock.placeholder">
                <!--============================================-->
                <!-- Info Table -->
                <!--============================================-->
                <ng-container *ngSwitchCase="'ZusammenfassungBeteiligte'">
                    <customer-signature-participants-summary
                        id="info-table-everything-but-power-of-attorney"
                        [config]="config"
                        [documentType]="$any(selectedTab?.documentType)"
                        *ngIf="
                            selectedTab?.documentType !== 'signAllDocumentsAtOnce'
                        "></customer-signature-participants-summary>
                </ng-container>

                <!-- ZusammenfassungAnwaltsvollmacht -->
                <ng-container *ngSwitchCase="'ZusammenfassungAnwaltsvollmacht'">
                    <customer-signature-participants-summary
                        id="info-table-power-of-attorney"
                        [config]="config"
                        [documentType]="$any(selectedTab?.documentType)"
                        *ngIf="
                            selectedTab?.documentType !== 'signAllDocumentsAtOnce'
                        "></customer-signature-participants-summary>
                </ng-container>
                <!--============================================-->
                <!-- END Info Table -->
                <!--============================================-->

                <!--============================================-->
                <!-- Signature Pad Small - Text Block Documents -->
                <!--============================================-->
                <ng-container *ngSwitchCase="'UnterschriftKunde'">
                    <signature-pad
                        #signaturePad="signaturePad"
                        [id]="getSignableDocumentId(selectedSignableDocument) + '-' + buildingBlock.placeholder"
                        [claimantSignatures]="[selectedSignableDocument?.signatures[0]]"
                        [reportId]="config.report._id"
                        [signerName]="getSignerName()"
                        [canvasWidth]="getCanvasWidth(signaturePad)"
                        [signerOrganization]="config.report.claimant.contactPerson.organization"
                        [signaturePadFullscreenMode]="signaturePadFullscreenMode"
                        [disabledReason]="getSignaturePadDisabledReason()"
                        [disableSignatureTransfer]="disableSignatureTransfer"
                        (signaturePadFullscreenChange)="signaturePadFullscreenChange.emit($event)"
                        (signatureEndStroke)="signatureEndStroke.emit($event)"
                        (signatureSaved)="saveReport.emit()"
                        (signatureFilesDeleted)="saveReport.emit()"
                        (signaturePadCreated)="signaturePadCreated.emit($event)"
                        (transferSignature)="transferSignature.emit($event)"></signature-pad>
                </ng-container>
                <!--================================================-->
                <!-- END Signature Pad Small - Text Block Documents -->
                <!--================================================-->

                <!--============================================-->
                <!-- Fee set -->
                <!--============================================-->
                <ng-container *ngSwitchCase="'Honorarvereinbarung'">
                    <customer-signatures-fee-set
                        [buildingBlock]="buildingBlock"
                        [config]="config"></customer-signatures-fee-set>
                </ng-container>
                <!--============================================-->
                <!-- END Fee set -->
                <!--============================================-->

                <!--============================================-->
                <!-- Signature Pad Small - Text Block Documents -->
                <!--============================================-->
                <ng-container *ngSwitchCase="'ZweiteUnterschriftKunde'">
                    <signature-pad
                        #signaturePad="signaturePad"
                        [id]="getSignableDocumentId(selectedSignableDocument) + '-' + buildingBlock.placeholder"
                        [claimantSignatures]="[selectedSignableDocument?.signatures[1]]"
                        [reportId]="config.report._id"
                        [signerName]="getSignerName()"
                        [canvasWidth]="getCanvasWidth(signaturePad)"
                        [signerOrganization]="config.report.claimant.contactPerson.organization"
                        [signaturePadFullscreenMode]="signaturePadFullscreenMode"
                        [disabledReason]="getSignaturePadDisabledReason()"
                        [disableSignatureTransfer]="disableSignatureTransfer"
                        (signatureEndStroke)="signatureEndStroke.emit($event)"
                        (signatureSaved)="saveReport.emit()"
                        (signatureFilesDeleted)="saveReport.emit()"
                        (signaturePadFullscreenChange)="signaturePadFullscreenChange.emit($event)"
                        (signaturePadCreated)="signaturePadCreated.emit($event)"
                        (transferSignature)="transferSignature.emit($event)"></signature-pad>
                </ng-container>
                <!--================================================-->
                <!-- END Signature Pad Small - Text Block Documents -->
                <!--================================================-->
            </ng-container>
        </div>

        <!--********** No Content Placeholder **********-->
        <div id="no-document-content-placeholder" *ngIf="showNoDocumentContentPlaceholder()">
            <h3>Kein Dokumentinhalt</h3>
            <div class="label">Trage den Dokumenttext in den Textbausteinen ein.</div>

            <!--********** Create Content for Attorney **********-->
            <div id="create-power-of-attorney-container" *ngIf="selectedTab?.documentType === 'powerOfAttorney'">
                <h3>Anwaltliche Vollmacht</h3>

                <!--********** Attorney not selected **********-->
                <div class="label" *ngIf="!config.report.lawyer?.contactPerson.organization">
                    Gib zuerst einen Anwalt an und kehre dann hierher zurück.
                </div>

                <!--********** Attorney selected **********-->
                <div
                    id="create-power-of-attorney-building-block-container"
                    *ngIf="config.report.lawyer?.contactPerson.organization">
                    <div>
                        Hinterlege jetzt eine anwaltliche Vollmacht für die Kanzlei
                        {{ config.report.lawyer.contactPerson.organization }}.
                    </div>
                    <button id="create-power-of-attorney-building-block-button" (click)="addPowerOfAttorney.emit()">
                        Neue anwaltliche Vollmacht
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Document Content -->
    <!--============================================-->
</ng-container>
<!--============================================-->
<!-- END Text Block Document -->
<!--============================================-->

<!--============================================-->
<!-- PDF Template -->
<!--============================================-->
<signable-pdf-template-editor
    *ngIf="selectedSignableDocument && selectedSignablePdfTemplateConfig"
    [signablePdfTemplateConfig]="selectedSignablePdfTemplateConfig"
    [parentPdfScrollContainer]="parentScrollContainer"
    [fieldGroupConfigs]="config.fieldGroupConfigs"
    [placeholderValuesOfPreviewReport]="config.reportPlaceholderValues"
    [report]="config.report"
    [mode]="'fillAndSign'"
    [showCloseEditorIcon]="false"
    [isReadonly]="!!documentReadonlyReason"
    [areToolsVisible]="false"
    [showDocumentFrame]="showPdfDocumentFrame"
    [pageCounterAndZoomLevel]="pageCounterAndZoomLevel"
    (signatureEndStroke)="signatureEndStroke.emit($event)"
    (signableDocumentChange)="saveReport.emit()"
    [signaturePadDisabledReason]="getSignaturePadDisabledReason()"
    [signaturePadFullscreenMode]="signaturePadFullscreenMode"
    [disableSignatureTransfer]="disableSignatureTransfer"
    (signaturePadFullscreenChange)="signaturePadFullscreenChange.emit($event)"
    (signaturePadCreated)="signaturePadCreated.emit($event)"
    (transferSignature)="transferSignature.emit($event)"
    [basePageWidthInPercent]="100"></signable-pdf-template-editor>
<!--============================================-->
<!-- END PDF Template -->
<!--============================================-->

import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { IsoDate } from './iso-date.types';

/**
 * Generate the date of today in the format YYYY-MM-DD (ISO Date format)
 */
export function todayIso(): IsoDate {
    return DateTime.now().toISODate() as IsoDate;
}

/**
 * Convert an existing string to the format YYYY-MM-DD (ISO Date format)
 */
export function toIsoDate(date: string | Date | DateTime | Moment): IsoDate {
    if (!date) return null;

    if (date instanceof Date) {
        return DateTime.fromJSDate(date, { zone: 'Europe/Berlin' }).toISODate() as IsoDate;
    }
    if (date instanceof DateTime) {
        return date.toISODate() as IsoDate;
    }

    if (moment.isMoment(date)) {
        return date.format('YYYY-MM-DD') as IsoDate;
    }

    return DateTime.fromISO(date, {
        // We always set the zone to a fixed value to make sure people working from other timezones always enter German time equivalents.
        zone: 'Europe/Berlin',
    }).toISODate() as IsoDate;
}

import { Directive, ElementRef, Input } from '@angular/core';

/**
 * Allows an element in a view to be autofocused as soon as the view is initialized.
 * Just add the attribute "clickMyselfOnInit" to the element.
 * Example: <input type="password" [clickMyselfOnInit]="true">
 */
@Directive({
    selector: '[clickMyselfOnInit]',
})
export class ClickMyselfOnInitDirective {
    constructor(private el: ElementRef) {}

    /**
     * If false, blocks the click action.
     * Useful to block the click for the initial rendering of the parent component.
     *
     * MUST be explicitly set to true if you want the click to happen.
     */
    @Input() clickMyselfOnInit: boolean;

    ngAfterViewInit() {
        if (this.clickMyselfOnInit) {
            window.setTimeout(() => this.el.nativeElement.click(), 0);
        }
    }
}

import { ReportData } from '../../models/invoices/report-data';
import { removeDuplicates } from '../arrays/remove-duplicates';
import { getReportCategory } from './get-report-category';

/**
 * Given a list of reportData objects, summaryize which types of reports it contains.
 * E. g. if there are only liability reports it returns "Gutachten", if there are liability
 * and valuations it returns "Gutachten / Bewertung".
 */
export function concatReportCategories(reportsData: ReportData[]): string {
    return (
        removeDuplicates(reportsData.map((reportData) => reportData.type))
            .map((reportType) => getReportCategory(reportType))
            .join(' / ') || 'Gutachten'
    );
}

/**
 * Grade system with 13 steps. Similar to German school grades but only including grades 1 (14) to 5 (2)
 */
export class OldtimerValuationGrades {
    // Exchangable with Classic Analytics
    total: number = 11;
    body: number = 11;
    paint: number = 11;
    chrome: number = 11;
    rubber: number = 11;
    glass: number = 11;
    tires: number = 11;
    cabin: number = 11;
    engineCompartment: number = 11;
    engine: number = 11;
    trunk: number = 11;
    // aX-only grades
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!--============================================-->
        <!-- Left Column - Original Invoice -->
        <!--============================================-->
        <div id="" class="card dark-card card-with-padding">
            <h2>Original</h2>

            <!--============================================-->
            <!-- Invoice -->
            <!--============================================-->
            <div class="invoice">
                <!--********** Open in New **********-->
                <div
                    class="open-original-invoice-icon medium-icon"
                    (click)="openInvoice(originalInvoice._id); $event.stopPropagation()"
                    matTooltip="Öffnen">
                    <mat-icon>open_in_new</mat-icon>
                </div>

                <!--********** Recipient **********-->
                <div class="invoice-recipient">
                    <div
                        class="text-overflow-ellipsis"
                        matTooltip="Empfänger: {{
                            getFullRecipientName(originalInvoice.recipient.contactPerson) || 'Keiner'
                        }}">
                        <ng-container *ngIf="getShortRecipientName(originalInvoice.recipient.contactPerson)">
                            {{ getShortRecipientName(originalInvoice.recipient.contactPerson) }}
                        </ng-container>
                        <span
                            class="label italic"
                            *ngIf="!getShortRecipientName(originalInvoice.recipient.contactPerson)">
                            Kein Empfänger
                        </span>
                    </div>
                </div>

                <!--********** Invoice Number **********-->
                <div class="invoice-number">
                    <div matTooltip="Rechnungsnummer: {{ originalInvoice.number || 'Keine' }}">
                        <ng-container *ngIf="originalInvoice.number">{{ originalInvoice.number }}</ng-container>
                        <span class="label italic" *ngIf="!originalInvoice.number">Keine Nummer</span>
                    </div>
                </div>

                <!--********** Invoice Date **********-->
                <div class="invoice-date text-align-end" matTooltip="Rechnungsdatum">
                    <ng-container *ngIf="originalInvoice.date">
                        {{ originalInvoice.date | moment: 'DD.MM.YY' }}
                    </ng-container>
                    <span class="label italic" *ngIf="!originalInvoice.date">Kein Datum</span>
                </div>

                <!--********** Net **********-->
                <div class="invoice-amount-net text-align-end">
                    <div matTooltip="Netto {{ originalInvoice.totalNet | number: '1.2-2' }} €">
                        {{ originalInvoice.totalNet || 0 | number: '1.2-2' }} €
                    </div>
                </div>

                <!--********** VAT **********-->
                <div class="invoice-vat text-align-end">
                    <div
                        matTooltip="Netto {{
                            originalInvoice.totalGross - originalInvoice.totalNet | number: '1.2-2'
                        }} €">
                        {{ originalInvoice.totalGross - originalInvoice.totalNet || 0 | number: '1.2-2' }} €
                    </div>
                </div>

                <!--********** Gross **********-->
                <div class="invoice-amount-gross text-align-end semibold margin-bottom-15">
                    <div matTooltip="Brutto {{ originalInvoice.totalGross | number: '1.2-2' }} €">
                        {{ originalInvoice.totalGross || 0 | number: '1.2-2' }} €
                    </div>
                </div>

                <div class="text-align-center">
                    <payment-status [invoice]="originalInvoice"></payment-status>
                </div>
            </div>
            <!--============================================-->
            <!-- END Invoice -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Left Column - Original Invoice -->
        <!--============================================-->

        <!--============================================-->
        <!-- Cancellation Invoice -->
        <!--============================================-->
        <div id="partial-cancellation-invoice-column" class="card card-with-padding">
            <h2 class="flex-center-content">
                Teilgutschrift
                <span
                    class="help-indicator"
                    matTooltip="Erstelle eine Teilgutschrift, auch Rechnungskorrektur oder Teilstorno genannt, um einen Beleg für deine Buchhaltung zu erzeugen, wenn du einen Teilbetrag einer Rechnung ausbuchen möchtest.{{
                        '\n\n'
                    }}Das ist z. B. relevant, wenn eine Rechnung gekürzt wird oder du eine fehlerhafte Rechnung verschickt hast.">
                    ?
                </span>
            </h2>

            <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Dialog schließen">close</mat-icon>

            <!--********** Net & Gross **********-->
            <div id="net-and-gross-amounts-container" class="multiple-inputs-row">
                <!--********** Net Amount **********-->
                <currency-input
                    [(value)]="netValue"
                    [baseValue]="originalInvoice.totalNet"
                    (valueChange)="translateNetToGross({ displayAnimation: true })"
                    placeholder="Netto-Betrag"></currency-input>

                <div
                    id="net-and-gross-transformation-indicator-container"
                    [ngClass]="{
                        'amount-conversion-from-net-to-gross-in-progress': amountConversionDirection === 'netToGross',
                        'amount-conversion-from-gross-to-net-in-progress': amountConversionDirection === 'grossToNet',
                    }"
                    matTooltip="Brutto- & Netto-Werte werden automatisch ineinander umgerechnet.">
                    <mat-icon>repeat</mat-icon>
                </div>

                <!--********** Gross Amount **********-->
                <currency-input
                    [(value)]="grossValue"
                    [baseValue]="originalInvoice.totalGross"
                    (valueChange)="translateGrossToNet({ displayAnimation: true })"
                    placeholder="Brutto-Betrag"></currency-input>
            </div>

            <!--********** Invoice Line Item Title **********-->
            <div class="input-with-external-icon-container">
                <mat-form-field class="message mat-block">
                    <mat-quill
                        placeholder="Titel der Rechnungsposition"
                        [(ngModel)]="invoiceLineItemTitle"
                        [minRows]="1"></mat-quill>
                </mat-form-field>

                <!--********** Submenu **********-->
                <div class="input-toolbar">
                    <mat-icon class="input-toolbar-icon toolbar-icon" [matMenuTriggerFor]="invoiceLineItemTitleSubmenu">
                        more_vert
                    </mat-icon>
                </div>
                <mat-menu #invoiceLineItemTitleSubmenu>
                    <a mat-menu-item class="menu-item-with-icon" (click)="rememberTitleOfInvoiceLineItem()">
                        <mat-icon class="outlined">bookmark_border</mat-icon>
                        Titel merken
                    </a>
                </mat-menu>
            </div>

            <!--********** Internal Note **********-->
            <div class="input-with-external-icon-container">
                <mat-form-field class="message mat-block">
                    <mat-quill
                        placeholder="Interne Notiz"
                        [(ngModel)]="internalNote"
                        [minRows]="1"
                        matTooltip="Wird in die Notizen der Original- und Stornorechnung übertragen."></mat-quill>
                </mat-form-field>
                <div class="input-toolbar">
                    <mat-icon
                        class="toolbar-icon input-toolbar-icon"
                        (click)="isInternalNoteTextTemplateSelectorVisible = true">
                        playlist_add
                    </mat-icon>
                </div>
            </div>

            <text-template-selector
                [(text)]="internalNote"
                *ngIf="isInternalNoteTextTemplateSelectorVisible"
                placeholderForResultingText="Interne Notiz"
                (close)="isInternalNoteTextTemplateSelectorVisible = false"
                [textTemplateType]="'partialInvoiceCancellationInternalNote'"
                @runChildAnimations></text-template-selector>

            <div class="label margin-top-20 margin-bottom-10">
                Auf der originalen Rechnung wird vermerkt, dass du einen Teil storniert hast.
                <span
                    class="help-indicator"
                    matTooltip="Du findest diese Info im Zahlungen-Dialog der Originalrechnung.">
                    ?
                </span>
            </div>

            <div class="checkbox-group-container">
                <div class="checkbox-group">
                    <mat-checkbox
                        [(ngModel)]="isShortPayment"
                        matTooltip="Lasse im Zahlungsdialog der originalen Rechnung markieren, dass die Gutschrift aufgrund einer Kürzung erfolgt ist. Das hilft dir bei der Auswertung von Kürzungen.{{
                            '\n\n'
                        }}Bleibt der Haken leer, wird auf der ursprünglichen Rechnung keine Kürzung, sondern ein einfacher Teilgutschriftsbetrag angezeigt. Das solltest du dann tun, wenn du eine Rechnung korrigieren möchtest, ohne dass dir eine Kürzung ins Haus stand.">
                        Kürzung
                    </mat-checkbox>
                </div>
            </div>

            <div class="dialog-buttons-container">
                <button (click)="createPartialCancellationInvoice()">
                    <img
                        class="pending-icon"
                        *ngIf="isPartialCancellationInvoiceBeingGenerated"
                        src="/assets/images/icons/loading-indicator-white.svg" />
                    Teilgutschrift erstellen
                </button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Cancellation Invoice -->
        <!--============================================-->
    </div>
</div>

import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Subscription } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { Team } from '@autoixpert/models/teams/team';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { GERMAN_DATE_FORMAT } from '../../libraries/ax-luxon';
import { getDefaultOfPaymentStatus } from '../../libraries/get-default-of-payment-status';
import { ApiErrorService } from '../../services/api-error.service';
import { AuthenticationService } from '../../services/authentication.service';
import { InvoiceHistoryService } from '../../services/invoice-history.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { TeamService } from '../../services/team.service';

@Component({
    selector: 'default-of-payment-notification',
    templateUrl: 'default-of-payment-notification.component.html',
    styleUrls: ['default-of-payment-notification.component.scss'],
    animations: [fadeInAndOutAnimation(), fadeInAndSlideAnimation()],
})

/*
 * Default of Payment = Zahlungsverzug
 */
export class DefaultOfPaymentNotificationComponent {
    constructor(
        private teamService: TeamService,
        private authenticationService: AuthenticationService,
        private invoiceHistoryService: InvoiceHistoryService,
        private apiErrorService: ApiErrorService,
        private loggedInUserService: LoggedInUserService,
        private dialogService: MatDialog,
    ) {}

    public team: Team;

    public defaultOfPaymentNotificationSubscriptions: Subscription[] = [];

    public showDefaultOfPaymentNotification: boolean = false;

    private subscriptions: Subscription[] = [];

    ngOnInit() {
        const userSubscripton = this.loggedInUserService.getTeam$().subscribe((team) => {
            this.team = team;

            // The team may be null on logout.
            if (!this.team) return;

            this.showDefaultOfPaymentNotification = !!this.team.billing.inDefaultOfPaymentSince;
        });
        this.subscriptions.push(userSubscripton);

        this.subscribeToAuthenticationSuccess();
        this.subscribeToSubjects();
    }

    //*****************************************************************************
    //  Subscriptions
    //****************************************************************************/

    public subscribeToAuthenticationSuccess() {
        this.authenticationService.authenticationSuccess.subscribe(async () => {
            this.subscribeToSubjects();
        });
    }

    private subscribeToTeamsDefaultOfPaymentPatchEvent() {
        const patchSubscription = this.teamService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
            next: (value) => {
                this.showDefaultOfPaymentNotification = !!value.patchedRecord.billing.inDefaultOfPaymentSince;
            },
        });

        this.defaultOfPaymentNotificationSubscriptions.push(patchSubscription);
    }

    private subscribeToSubjects() {
        if (this.defaultOfPaymentNotificationSubscriptions.length) {
            this.defaultOfPaymentNotificationSubscriptions.forEach((subscription) => subscription.unsubscribe());
            this.defaultOfPaymentNotificationSubscriptions.length = 0;
        }

        this.subscribeToTeamsDefaultOfPaymentPatchEvent();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Subscriptions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Notification Text
    //****************************************************************************/
    public getDefaultOfPaymentStatus() {
        return getDefaultOfPaymentStatus(this.team?.billing.inDefaultOfPaymentSince);
    }

    public getDefaultOfPaymentNotification(): string {
        switch (this.getDefaultOfPaymentStatus()) {
            case 'reminded':
                return `Deine autoiXpert-Zahlung ist seit dem ${DateTime.fromISO(
                    this.team.billing.inDefaultOfPaymentSince,
                ).toLocaleString(GERMAN_DATE_FORMAT)} überfällig.`;
            /**
             * This will only be shown shortly until the user uses the API which triggers a logout due to an invalid JWT.
             */
            case 'deactivated':
                return `Dein Account wurde wegen zu langem Zahlungsverzug (seit dem ${DateTime.fromISO(
                    this.team.billing.inDefaultOfPaymentSince,
                ).toLocaleString(GERMAN_DATE_FORMAT)}) gesperrt.`;
            default:
                return '';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Notification Text
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Snooze Notification
    //****************************************************************************/
    public async hideDefaultOfPaymentNotification(): Promise<void> {
        const decision = await this.dialogService
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Versprochen?',
                    content:
                        'Schwörst du hoch-feierlich, dass du die ausstehenden Zahlungen sofort ausführen wirst oder schon ausgeführt hast?',
                    // The second emoji part is for the skin color. Otherwise, the emoji would be yellow.
                    confirmLabel: 'Ja, ich schwöre! ✌🏼',
                    cancelLabel: 'Nein',
                },
            })
            .afterClosed()
            .toPromise();

        if (!decision) return;

        this.showDefaultOfPaymentNotification = false;
        this.hideNotificationForToday();
    }

    // Store a timestamp if the user wants to hide the notification
    public hideNotificationForToday() {
        const timestamp = moment();
        store.set('defaultOfPaymentNotificationClosingDate', timestamp);
    }

    // Only hide if the timestamp is of the same day
    get wasNotificationClosedToday() {
        const snoozedAt = store.get('defaultOfPaymentNotificationClosingDate');

        if (!snoozedAt) return;

        return moment(snoozedAt).isSame(moment(), 'day');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Snooze Notification
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fastbill Link
    //****************************************************************************/

    public async openInvoiceHistory() {
        try {
            const link = await this.invoiceHistoryService.find(this.team._id);
            window.open(link, '_blank', 'noopener');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    FASTBILL_CUSTOMER_NOT_FOUND: {
                        title: 'FastBill-Eintrag nicht gefunden',
                        body: 'Der Kunde konnte nicht in FastBill gefunden werden. Das Öffnen der Rechnungshistorie ist nicht möglich.',
                    },
                },
                defaultHandler: {
                    title: 'Bisherige Rechnungen konnten nicht geöffnet werden.',
                    body: "Bitte logge dich aus und wieder ein. Tritt der Fehler erneut auf, kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

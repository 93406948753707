import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ResidualValueOffer } from '@autoixpert/models/reports/residual-value/residual-value-offer';

@Component({
    selector: 'carcasion-offer-connection-dialog',
    templateUrl: './carcasion-offer-connection-dialog.component.html',
    styleUrls: ['./carcasion-offer-connection-dialog.component.scss'],
})
export class CarcasionOfferConnectionDialogComponent {
    public carcasionOfferId: number;
    public offerReadyAt: string;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() connectedOffer: EventEmitter<ResidualValueOffer> = new EventEmitter();

    public getSubmitButtonTooltip(): string {
        if (!this.carcasionOfferId) {
            return 'Bitte die car.casion Objekt-Nummer eingeben.';
        }
        if (!this.offerReadyAt) {
            return 'Bitte die Gebotsfrist eingeben.';
        }
        return '';
    }

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/

    public closeDialog(): void {
        this.close.emit();
    }

    public emitObjectNumber() {
        const carcasionOffer = new ResidualValueOffer();
        carcasionOffer.offerId = `${this.carcasionOfferId}`;
        carcasionOffer.readyAt = this.offerReadyAt;
        this.connectedOffer.emit(carcasionOffer);
        this.closeDialog();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Window Event Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Window Event Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

export function removeFromArray<T>(item: T, array: T[]): { index: number; removedItem: T } {
    const index = array.indexOf(item);

    let removedItem: T;
    if (index > -1) {
        removedItem = array.splice(index, 1)[0];
    }

    return {
        index,
        removedItem,
    };
}

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { hasWhitelistedHostname } from '@autoixpert/lib/urls/whitelisted-hostnames';

@Injectable()
export class SetAuthorizationHeaderInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const baseUrl = window.location.origin;
        const url = new URL(request.url, baseUrl);

        // Only add Authorization header to requests to our own servers. The authorization header should not be sent to AWS S3 when uploading photos, for example.
        if (!hasWhitelistedHostname(url)) {
            return next.handle(request);
        }

        const authenticationHeaderValue = `Bearer ${store.get('autoiXpertJWT')}`;

        // The request shall be immutable. Therefore clone it and assign it new headers.
        const transformedRequest = request.clone({
            setHeaders: {
                Authorization: authenticationHeaderValue,
            },
        });

        return next.handle(transformedRequest);
    }
}

<div class="dialog-container">
    <!--============================================-->
    <!-- Left column - Report Token Info -->
    <!--============================================-->
    <div id="left-column">
        <h2 class="margin-bottom-10">Aktenzeichen</h2>
        <p class="label light no-margin-top margin-bottom-20 text-align-center">Deine aktuellen Einstellungen.</p>
        <section id="report-token-section">
            <div class="report-token-section-item">
                <span class="label light">Muster</span>
                <span class="report-token-label">{{ reportTokenConfig.pattern }}</span>
            </div>

            <div class="report-token-section-item">
                <span class="label light">Nächstes Aktenzeichen</span>
                <span class="report-token-label">
                    {{ getReportTokenPreviewString(reportTokenConfig.pattern) }}
                </span>
            </div>
        </section>
    </div>
    <!--============================================-->
    <!-- END Left column - Report Token Info -->
    <!--============================================-->

    <!--============================================-->
    <!-- Right Column - Editor -->
    <!--============================================-->
    <div id="right-column">
        <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>

        <h2 class="text-align-center margin-bottom-10">Nummern für Rechnungen in Gutachten</h2>
        <p class="label light no-margin text-align-center">
            Richte die Rechnungsnummern deiner Gutachten-Rechnungen (Honorar, Reparaturbestätigung, Stornos,
            Stellungnahmen etc.) an deinen Aktenzeichen aus.
            <br />
            <a
                target="_blank"
                href="https://wissen.autoixpert.de/hc/de/articles/360003993192-Gutachtennummer-Aktenzeichen-einstellen#h_01JE6MXB2NK9CHZ7W3R114MG3B">
                Welchen Vorteil hat das?
            </a>
        </p>

        <div class="invoice-number-pattern">
            <!--********** Pattern Input **********-->
            <placeholder-autocomplete-input
                class="report-token-pattern-input"
                [(text)]="reportTokenConfig.reportInvoiceNumberPattern"
                placeholder="Muster"
                [disabled]="disabled"
                (change)="handleReportInvoiceNumberPatternChange()"
                (inputFocused)="previousReportInvoiceNumberPattern = reportTokenConfig.reportInvoiceNumberPattern"
                [tooltip]="
                    'Format: GA-{JJ}-{###}. Die Anzahl der Rautezeichen bestimmt die Zahl der Ziffern.\n\nWeitere Platzhalter:\n{JJ} oder {JJJJ} für Jahreszahl,\n{MM} für Monat und\n{TT} für den Tag.\n{TYP} für Gutachtentyp,\n{INI} für die Initialen des zuständigen Sachverständigen.'
                ">
                <!--********** Insert Placeholders **********-->
                <mat-icon
                    placeholder-autocomplete-input-suffix
                    class="report-token-input-submenu-trigger toolbar-icon"
                    [matMenuTriggerFor]="invoiceNumberPatternPlaceholderSubmenu"
                    matTooltip="Platzhalter einfügen">
                    playlist_add
                </mat-icon>
            </placeholder-autocomplete-input>
            <!--********** Submenu: Placeholders **********-->
            <mat-menu #invoiceNumberPatternPlaceholderSubmenu>
                <a
                    mat-menu-item
                    *ngFor="let tokenPlaceholder of availableReportInvoiceNumberPlaceholders"
                    (click)="insertReportInvoiceNumberPlaceholder(tokenPlaceholder.placeholder); saveTeam()">
                    {{ tokenPlaceholder.title }}
                </a>
                <a
                    mat-menu-item
                    [matMenuTriggerFor]="popularPatternsSubmenu"
                    *ngIf="popularReportInvoiceNumberPatterns.length">
                    Beliebte Muster
                </a>
            </mat-menu>

            <!--********** Submenu Popular Patterns **********-->
            <mat-menu #popularPatternsSubmenu>
                <mat-option
                    class="option-with-label"
                    *ngFor="let popularReportTokenPattern of popularReportInvoiceNumberPatterns"
                    (click)="replaceReportTokenPattern(popularReportTokenPattern.pattern)">
                    {{ popularReportTokenPattern.title }}
                    <div class="label">
                        {{ getInvoiceNumberPreviewString(popularReportTokenPattern.pattern) }}
                    </div>
                </mat-option>
            </mat-menu>
        </div>

        <!--============================================-->
        <!-- Warnings -->
        <!--============================================-->
        <div
            class="info-note warning-note flex-align-center"
            *ngIf="
                getInvoiceNumberPreviewString(reportTokenConfig.reportInvoiceNumberPattern).includes('{') ||
                getInvoiceNumberPreviewString(reportTokenConfig.reportInvoiceNumberPattern).includes('}')
            ">
            <mat-icon>warning</mat-icon>
            <div>"&#123;" und "&#125;" bitte nur als Teil des Platzhalters verwenden.</div>
        </div>

        <div
            class="info-note warning-note flex-align-center"
            *ngIf="
                !reportTokenConfig.reportInvoiceNumberPattern?.includes('{AZ}') ||
                !reportTokenConfig.reportInvoiceNumberPattern?.includes('{R}')
            ">
            <mat-icon>warning</mat-icon>
            <div>
                Das Muster für Gutachten-Rechnungen sollte mindestens das Aktenzeichen &#123;AZ&#125; und einen
                Unterzähler &#123;R&#125; beinhalten, damit deine Rechnungsnummern eindeutig sind.
            </div>
        </div>
        <!--============================================-->
        <!-- END Warnings -->
        <!--============================================-->

        <!--============================================-->
        <!-- Start sub counter from -->
        <!--============================================-->
        <preferences-row iconName="settings">
            <span>Unterzähler ab ...</span>
            <span class="label light">
                {{ 'Soll der Unterzähler {R} schon bei der ersten oder erst ab der zweiten Rechnung angefügt werden?' }}
            </span>

            <mat-select
                right
                class="styled"
                [disabled]="disabled"
                [ngModel]="reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice ? 'second' : 'first'"
                (selectionChange)="subCounterSelectionChanged($event)">
                <mat-option
                    value="first"
                    matTooltip="Der Unterzähler {R} wird in alle Rechnungsnummern im Gutachten eingefügt. Die erste Rechnung beginnt mit dem Zähler 1.

                    Beispiel:
{{
                        getInvoiceNumberExampleForSpecificIndex(0, {
                            forceStartCounterWithSecondInvoice: false,
                        })
                    }}
{{
                        getInvoiceNumberExampleForSpecificIndex(1, {
                            forceStartCounterWithSecondInvoice: false,
                        })
                    }}
{{
                        getInvoiceNumberExampleForSpecificIndex(2, {
                            forceStartCounterWithSecondInvoice: false,
                        })
                    }}">
                    1. Rechnung
                </mat-option>
                <mat-option
                    value="second"
                    matTooltip="Der Unterzähler {R} wird erst ab der zweiten Rechnung verwendet. Die erste Rechnung im Gutachten erhält keine Nummer.

                    Beispiel:
{{
                        getInvoiceNumberExampleForSpecificIndex(0, {
                            forceStartCounterWithSecondInvoice: true,
                        })
                    }}
{{
                        getInvoiceNumberExampleForSpecificIndex(1, {
                            forceStartCounterWithSecondInvoice: true,
                        })
                    }}
{{
                        getInvoiceNumberExampleForSpecificIndex(2, {
                            forceStartCounterWithSecondInvoice: true,
                        })
                    }}">
                    2. Rechnung
                </mat-option>
            </mat-select>

            <!--============================================-->
            <!-- Start cancellation invoices -->
            <!--============================================-->
            <preferences-row>
                <span>Nummer für Stornorechnungen</span>
                <span class="label light">
                    Wie sollen die Nummern für Stornorechnungen eines Gutachtens vergeben werden?
                </span>

                <mat-select
                    right
                    class="styled"
                    [disabled]="disabled"
                    [ngModel]="
                        reportTokenConfig.useCancellationInvoiceSuffixForReportInvoices ? 'suffix' : 'sub-counter'
                    "
                    (selectionChange)="cancellationInvoiceSettingsChanged($event)">
                    <mat-option
                        value="sub-counter"
                        matTooltip="Stornorechnungen reihen sich in deinen normalen Gutachtenrechnungskreis ein. Der Unterzähler wird hochgezählt.">
                        Unterzähler
                    </mat-option>
                    <mat-option
                        value="suffix"
                        matTooltip="Einige Zeichen werden der Nummer der Originalrechnung angehängt, z. B. '-S' oder '-Storno'. Der Unterzähler wird nicht hochgezählt, sodass die Nummer der Stornorechnung eindeutig auf die stornierte Rechnung schließen lässt.">
                        Suffix
                    </mat-option>
                </mat-select>
            </preferences-row>

            <preferences-row
                *ngIf="reportTokenConfig.useCancellationInvoiceSuffixForReportInvoices"
                @slideInAndOutVertically>
                <span>Suffix für Stornorechnungen</span>
                <span class="label light">
                    Dieser Text bildet zusammen mit der Nummer der Originalrechnung die Rechnungsnummer für die
                    Stornorechnung.
                </span>

                <mat-form-field right class="without-hint-spacing">
                    <input
                        id="suffix-input-field"
                        #suffixInputField
                        matInput
                        placeholder="Suffix"
                        [(ngModel)]="reportTokenConfig.reportInvoiceCancellationSuffix"
                        (focus)="
                            previousReportInvoiceCancellationSuffix = reportTokenConfig.reportInvoiceCancellationSuffix
                        "
                        (change)="handleReportInvoiceCancellationSuffixChange()"
                        [disabled]="disabled" />
                </mat-form-field>
            </preferences-row>

            <preferences-row
                *ngIf="reportTokenConfig.syncWithInvoiceCounter && reportTokenConfig.leadingCounter === 'reportToken'"
                @slideInAndOutVertically>
                <span>Nachträge und Rechnungsprüfungen</span>
                <div class="label light">
                    Sollen Nachträge und Rechnungsprüfungen einen neuen Zählerkreis basierend auf dem neuen Aktenzeichen
                    starten? Standardmäßig wird für diese Rechnungen das Aktenzeichen und der Rechnungszähler des
                    Originalgutachtens verwendet.
                </div>

                <mat-slide-toggle
                    right
                    (change)="saveTeam()"
                    [disabled]="disabled"
                    [(ngModel)]="
                        reportTokenConfig.separateCounterForAmendmentReportsAndInvoiceAudits
                    "></mat-slide-toggle>
            </preferences-row>

            <!--============================================-->
            <!-- END Start cancellation invoices -->
            <!--============================================-->
        </preferences-row>

        <!--============================================-->
        <!-- END Start sub counter from -->
        <!--============================================-->

        <div *ngIf="haveSettingsChanged()" class="info-note" @slideInAndOutVertically>
            <mat-icon class="medium-icon">info</mat-icon>
            <!-- Don't let prettier format the following inline elements (to prevent whitespace between full stop and span) -->
            <!-- prettier-ignore -->
            <div>
                <h3>Neue Einstellungen</h3>
                Du hast die Einstellungen für Rechnungsnummern im Gutachten verändert. Deine neuen Einstellungen greifen
                nur für Gutachten, in denen du noch keine Rechnungsnummer gezogen hast.<span
                    class="help-indicator"
                    matTooltip="Benötigst du in einem bestehenden Gutachten, in dem bereits eine Rechnungsnummer vergeben wurde, eine weitere Rechnungsnummer basierend auf den neuen Einstellungen, vergib diese bitte manuell.">
                    ?
                </span>
            </div>
        </div>

        <!--============================================-->
        <!-- Start next invoice number example -->
        <!--============================================-->
        <preferences-row iconName="visibility">
            <span>Beispiel</span>
            <span class="label light">
                Mögliche Rechnungsnummern zum Aktenzeichen {{ getReportTokenPreviewString(reportTokenConfig.pattern) }}.
                <br />
                {{
                    this.team.preferences.amendmentReportTokenSuffix
                        ? 'Suffix für Nachträge: ' + this.team.preferences.amendmentReportTokenSuffix
                        : 'Beispielhaftes Suffix für Nachträge: -N'
                }}.
            </span>

            <div class="next-invoice-number-examples margin-top-5">
                <div class="invoice-number-examples-column">
                    <span>Honorar</span>
                    <span>Storno-Honorar</span>
                    <span>Korrektur-Honorar</span>
                    <span>Reparaturbestätigung</span>
                    <span>Stellungnahme</span>
                    <span>1. Nachtragsrechnung</span>
                    <span>2. Nachtragsrechnung</span>
                </div>
                <div class="invoice-number-examples-column">
                    <span>{{ getInvoiceNumberExampleForSpecificIndex(0) }}</span>
                    <span>{{ getInvoiceNumberExampleForSpecificIndex(1, { isCancellationInvoice: true }) }}</span>
                    <span>{{ getInvoiceNumberExampleForSpecificIndex(2) }}</span>
                    <span>{{ getInvoiceNumberExampleForSpecificIndex(3) }}</span>
                    <span>{{ getInvoiceNumberExampleForSpecificIndex(4) }}</span>
                    <span>
                        {{
                            getInvoiceNumberExampleForSpecificIndex(5, {
                                isAmendmentInvoice: true,
                                amendmentInvoiceIndex: 0,
                            })
                        }}
                    </span>
                    <span>
                        {{
                            getInvoiceNumberExampleForSpecificIndex(6, {
                                isAmendmentInvoice: true,
                                amendmentInvoiceIndex: 1,
                            })
                        }}
                    </span>
                </div>
            </div>
        </preferences-row>

        <!--============================================-->
        <!-- END Start next invoice number example -->
        <!--============================================-->

        <div class="dialog-buttons-container">
            <button (click)="closeDialog()">Schließen</button>
        </div>
    </div>
    <!--============================================-->
    <!-- END Right Column - Editor -->
    <!--============================================-->
</div>

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

/**
 * Textarea that grows in height as the user types.
 * This is accomplished by having a shadow DIV that mirrors the textarea's content.
 * The textarea's height is 100% and therefore grows with the shadow DIV.
 */
@Component({
    selector: 'autogrow-textarea',
    templateUrl: './autogrow-textarea.component.html',
    styleUrls: ['./autogrow-textarea.component.scss'],
    host: {
        '[class.dark-mode]': 'this.darkMode',
    },
})
export class AutogrowTextareaComponent {
    constructor(private readonly elRef: ElementRef<HTMLElement>) {
        elRef.nativeElement.style.setProperty('--padding-vertical', '0px');
        elRef.nativeElement.style.setProperty('--padding-horizontal', '0px');
    }

    public isFocused = false;

    @ViewChild('valueInput') textareaRef?: ElementRef<HTMLTextAreaElement>;
    @ViewChild('shadowDiv') shadowDivRef?: ElementRef<HTMLSpanElement>;

    @Input() placeholder = '';
    /**
     * Default: false
     */
    @Input() singleLine: boolean = false;
    /**
     * Default: false
     */
    @Input() darkMode: boolean = false;

    @Input() value: string;

    @Input() set paddingVertical(value: number) {
        this.elRef.nativeElement.style.setProperty('--padding-vertical', `${value}px`);
    }

    @Input() set paddingHorizontal(value: number) {
        this.elRef.nativeElement.style.setProperty('--padding-horizontal', `${value}px`);
    }

    @Output() valueChange = new EventEmitter<{ value: string }>();
    @Output() focused = new EventEmitter<{ value: string }>();
    @Output() blurred = new EventEmitter<{ value: string }>();
    @Output() enterPressed = new EventEmitter<{ value: string; keyboardEvent: KeyboardEvent }>();
    @Output() shiftEnterPressed = new EventEmitter<{ value: string; keyboardEvent: KeyboardEvent }>();
    @Output() elementCreated = new EventEmitter<{ element: AutogrowTextareaComponent }>();

    private get textarea() {
        return this.textareaRef?.nativeElement;
    }

    private get shadowDiv() {
        return this.shadowDivRef?.nativeElement;
    }

    private setShadowDivContent(value?: string) {
        if (value !== undefined && value !== null) {
            if (this.shadowDiv) {
                this.shadowDiv.textContent = value ? value.replace(/\n+/g, ' ') : '​';
            }
        }
    }

    protected setTextareaContent(value?: string) {
        if (this.textarea && this.textarea.value !== value && value !== undefined && value !== null) {
            this.textarea.value = value;
            this.setShadowDivContent(value);
        }
    }

    protected handleInputChange(event: Event) {
        let value = (event.target as HTMLTextAreaElement)?.value ?? '';
        value = value.replace(/\n+/g, ' ');
        this.valueChange.emit({ value });
        this.setShadowDivContent(value);
    }

    protected handlePaste(event: ClipboardEvent) {
        setTimeout(() => {
            if (!this.textarea) return;
            this.textarea.value = this.textarea.value.replace(/\n+/g, ' ');
        });
    }

    protected handleKeydownEnter(event: Event | KeyboardEvent) {
        event.preventDefault();
        const value = (event.target as HTMLTextAreaElement).value ?? '';
        this.enterPressed.emit({ value, keyboardEvent: event as KeyboardEvent });
        return true;
    }

    protected handleKeydownShiftEnter(event: Event | KeyboardEvent) {
        event.preventDefault();
        const value = (event.target as HTMLTextAreaElement).value ?? '';
        this.shiftEnterPressed.emit({ value, keyboardEvent: event as KeyboardEvent });
        return true;
    }

    protected handleFocus(event: Event) {
        const value = (event.target as HTMLTextAreaElement).value ?? '';
        this.isFocused = true;
        this.focused.emit({ value });
    }

    protected handleBlur(event: Event) {
        const value = (event.target as HTMLTextAreaElement).value ?? '';
        this.isFocused = false;
        this.blurred.emit({ value });
    }

    focus() {
        this.textarea?.focus();
    }

    selectText() {
        this.textarea?.select();
    }

    blur() {
        this.textarea?.blur();
    }

    ngAfterViewInit() {
        this.elementCreated.emit({ element: this });
    }
}

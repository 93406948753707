export function round(number: number, numberOfDecimalDigits: number = 2): number {
    if (isNaN(number)) return number;

    const multiplier: number = Math.pow(10, numberOfDecimalDigits);
    return roundNegativeNumbersCorrectly(number * multiplier) / multiplier;
}

/**
 * JavaScript has issues rounding certain negative numbers:
 * - Math.round(0.5) === 1 --> true
 * - Math.round(-0.5) === -1 --> false, it's 0. That's arithmetically wrong.
 *
 *  For details, see https://stackoverflow.com/questions/41586838/rounding-of-negative-numbers-in-javascript.
 */
function roundNegativeNumbersCorrectly(number: number): number {
    return Math.sign(number) * Math.round(Math.abs(number));
}

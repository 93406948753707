import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { DocumentLayoutConfig, DocumentLayoutGroup } from '@autoixpert/models/documents/document-layout-group';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import {
    VideoPlayerDialogComponent,
    VideoPlayerDialogData,
} from '../../../shared/components/video-player-dialog/video-player-dialog.component';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { DocumentLayoutGroupService } from '../../../shared/services/document-layout-group.service';
import { DocumentOrderConfigService } from '../../../shared/services/document-order-config.service';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
    selector: 'document-layout-group-preferences',
    templateUrl: './document-layout-group-preferences.component.html',
    styleUrls: ['./document-layout-group-preferences.component.scss'],
})
export class DocumentLayoutGroupPreferencesComponent implements OnInit, OnDestroy {
    constructor(
        private documentLayoutGroupService: DocumentLayoutGroupService,
        private toastService: ToastService,
        public dialog: MatDialog,
        private documentOrderConfigService: DocumentOrderConfigService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() public user: User;
    @Input() public team: Team;
    @Input() public teamMembers: User[];

    public documentLayoutGroups: DocumentLayoutGroup[] = [];
    public documentOrderConfigs: DocumentOrderConfig[] = [];

    private subscriptions: Subscription[] = [];

    async ngOnInit(): Promise<void> {
        this.loadDocumentLayoutGroups();
        this.registerDocumentLayoutGroupListeners();
        void this.loadDocumentOrderConfigs();
    }

    private loadDocumentLayoutGroups(): void {
        // Sort, so that the original layout group is always at the top.
        this.documentLayoutGroupService.find({ $sort: 'createdAt' }).subscribe({
            next: (documentLayoutGroups) => {
                this.documentLayoutGroups = documentLayoutGroups;
            },
            error: (error) => {
                console.error('Error loading document layout groups.', { error });
                this.toastService.error(
                    'Layoutgruppen nicht geladen',
                    'Die Briefpapier-Konfigurationen konnten nicht geladen werden. Das ist ein technisches Problem. Kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
            },
        });
    }

    private async loadDocumentOrderConfigs() {
        try {
            this.documentOrderConfigs = await this.documentOrderConfigService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Dokument-Konfigurationen nicht geladen',
                    body: 'Die Konfigurationen werden gebraucht, um Briefpapieren den Dokumenten zuzuordnen. Bitte versuche es erneut. Falls das Problem weiterhin besteht, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    public registerDocumentLayoutGroupListeners() {
        /**
         * Layout groups can be deleted within the layout group component. This service listens
         * for deletions and removes the object from the local array.
         */
        this.subscriptions.push(
            this.documentLayoutGroupService.deletedInLocalDatabase$.subscribe(
                (documentLayoutGroupId: DocumentLayoutGroup['_id']) => {
                    const deletedDocumentLayoutGroup: DocumentLayoutGroup = this.documentLayoutGroups.find(
                        (documentLayoutGroup) => documentLayoutGroup._id === documentLayoutGroupId,
                    );
                    removeFromArray(deletedDocumentLayoutGroup, this.documentLayoutGroups);
                },
            ),
        );
    }

    public async addDocumentLayoutGroup(): Promise<void> {
        //*****************************************************************************
        //  Ordering Process
        //****************************************************************************/
        // TODO Implement a more professional ordering process if this feature is used a lot.
        const isOrderConfirmed: boolean = await this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    heading: 'Kostenpflichtig bestellen?',
                    content:
                        'Für 69 € zzgl. MwSt. im Monat kannst du ein weiteres Dokumentenlayout erstellen.\n\nDamit kannst du zwei Firmen mit unterschiedlichen Briefpapieren aber gemeinsamen Kontakten und Nutzern über einen Account steuern und auswerten. Das ist günstiger, als wenn du für 89 € zzgl. MwSt. einen 2. Büro-Account eröffnest.',
                    confirmLabel: 'Ja, bestellen!',
                    cancelLabel: 'Ups, doch nicht...',
                },
            })
            .afterClosed()
            .toPromise();

        if (!isOrderConfirmed) {
            return;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Ordering Process
        /////////////////////////////////////////////////////////////////////////////*/

        const defaultDocumentLayoutGroup = this.documentLayoutGroups.find(
            (documentLayoutGroup) => documentLayoutGroup.isDefault,
        );
        const defaultDocumentLayoutConfig = defaultDocumentLayoutGroup.configs.find((config) => config.isDefault);

        const newDocumentLayoutGroup = new DocumentLayoutGroup({
            title: 'Neue Layoutgruppe',
            accentColorDocx: defaultDocumentLayoutGroup.accentColorDocx,
            configs: [
                new DocumentLayoutConfig({
                    title: 'Neues Briefpapier',
                    isDefault: true,
                    documentTemplateStyle: defaultDocumentLayoutConfig.documentTemplateStyle,
                }),
            ],
        });
        this.documentLayoutGroups.push(newDocumentLayoutGroup);
        this.documentLayoutGroupService.create(newDocumentLayoutGroup);
    }

    /**
     * Only office locations which are not yet linked to any document layout group may be added to a document
     * layout group.
     */
    public getOfficeLocationsWithoutAssociation(): OfficeLocation[] {
        const associatedOfficeLocationIds: OfficeLocation['_id'][] = [];
        for (const documentLayoutGroup of this.documentLayoutGroups) {
            associatedOfficeLocationIds.push(...documentLayoutGroup.associatedOfficeLocationIds);
        }

        return this.team.officeLocations.filter(
            (officeLocation) => associatedOfficeLocationIds.includes(officeLocation._id) === false,
        );
    }

    /**
     * When a document layout group was set as default, remove the default flag from the old default.
     */
    public removeDefaultFromOtherLayoutGroups(newDefaultDocumentLayoutGroup: DocumentLayoutGroup) {
        for (const documentLayoutGroup of this.documentLayoutGroups) {
            if (documentLayoutGroup._id !== newDefaultDocumentLayoutGroup._id && documentLayoutGroup.isDefault) {
                documentLayoutGroup.isDefault = false;
                this.documentLayoutGroupService.put(documentLayoutGroup);
            }
        }
    }

    //*****************************************************************************
    //  Help Center & YouTube
    //****************************************************************************/
    public openVideo(heading: string, videoUrl: string) {
        this.dialog.open<VideoPlayerDialogComponent, VideoPlayerDialogData>(VideoPlayerDialogComponent, {
            data: {
                heading,
                videoUrl,
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Help Center & YouTube
    /////////////////////////////////////////////////////////////////////////////*/

    protected readonly isAdmin = isAdmin;

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

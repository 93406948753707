<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="closeEditor()">close</mat-icon>
        <h2>Einstellungen</h2>
        <div class="label error myclaim-label" *ngIf="datUser.isValuateProUser">
            Du nutzt einen DAT valuatePro Account. Die DAT liefert zwar Fahrzeugwerte aber keine PDF-Dokumente über die
            Schnittstelle.
            <br />
            Um alle Einstellungen nutzen zu können, brauchst du eine DAT valuateExpert Lizenz. Falls du eine Werkstatt
            bist, kannst du auch die Bewertung über myclaim bestellen, die eine Bewertungs-PDF und ein
            Bewertungsprotokoll unterstützt.
            <br />
            Kontaktiere dazu deinen
            <a href="https://www.dat.de/service/ansprechpartner/" target="_blank" rel="noopener">DAT-Ansprechpartner</a>
            .
        </div>
        <div class="label error myclaim-label" *ngIf="datUser.isMyclaimUser && !datUser.isValuateProUser">
            Du nutzt einen DAT myclaim Account. Bis auf das Bewertungsprotokoll berücksichtigt myclaim keine andere
            Einstellung.
            <br />
            Um alle Einstellungen nutzen zu können, brauchst du eine DAT valuateExpert Lizenz.
            <br />
            Kontaktiere dazu deinen
            <a href="https://www.dat.de/service/ansprechpartner/" target="_blank" rel="noopener">DAT-Ansprechpartner</a>
            .
        </div>
        <form>
            <div id="columns-container">
                <section id="valuation-settings-column">
                    <h3>Preise & Werte</h3>

                    <date-input
                        [(date)]="report.valuation.datValuation.referenceDate"
                        (dateChange)="saveReport()"
                        placeholder="Stichtag"></date-input>

                    <!--********** Value Type **********-->
                    <mat-form-field class="mat-block">
                        <mat-select
                            [(ngModel)]="report.valuation.datValuation.vehicleValueType"
                            placeholder="Wertangabe"
                            name="vehicle-value-type"
                            (selectionChange)="updateOverallValueTypeForValuationReports(); saveReport()">
                            <mat-option value="dealerPurchase">Händlereinkaufspreis</mat-option>
                            <mat-option value="dealerSales">Händlerverkaufspreis</mat-option>
                            <mat-option value="marketValue">Marktwert</mat-option>
                            <mat-option value="replacementValue">Wiederbeschaffungswert</mat-option>
                            <mat-option value="presentValue">Zeitwert</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Gross / Net **********-->
                    <div id="vat-handling-selector">
                        <div class="selection-bar-label">Vorsteuerabzug</div>
                        <div class="selection-bar">
                            <div
                                class="selection-bar-item"
                                (click)="selectVatHandling('net')"
                                [ngClass]="{
                                    selected: report.valuation.datValuation.requestedVatHandling === 'net',
                                }"
                                matTooltip="In der DAT-Bewertung wird der Fahrzeugwert netto ausgewiesen.">
                                Netto
                            </div>
                            <div
                                class="selection-bar-item"
                                (click)="selectVatHandling('detectFromCarOwnerVatStatus')"
                                [ngClass]="{
                                    selected:
                                        report.valuation.datValuation.requestedVatHandling ===
                                        'detectFromCarOwnerVatStatus',
                                }"
                                matTooltip="Wenn der Fahrzeughalter vorsteuerabzugsberechtigt ist, wird der Netto-Wert automatisch ausgewiesen. Ansonsten der Brutto-Wert.">
                                Automatisch
                            </div>
                            <div
                                class="selection-bar-item"
                                (click)="selectVatHandling('gross')"
                                [ngClass]="{ selected: report.valuation.datValuation.requestedVatHandling === 'gross' }"
                                matTooltip="In der DAT-Bewertung wird der Fahrzeugwert brutto ausgewiesen.">
                                Brutto
                            </div>
                        </div>
                    </div>

                    <!--********** VAT Display Type **********-->
                    <mat-form-field
                        class="mat-block"
                        *ngIf="
                            report.valuation.vehicleValueType === 'replacementValue' &&
                            (report.valuation.taxationType === 'margin' ||
                                report.valuation.taxationType === 'margin_reduced') &&
                            report.valuation.datValuation.requestedVatHandling === 'gross'
                        ">
                        <mat-select
                            [(ngModel)]="report.valuation.datValuation.vatDisplayType"
                            placeholder="MwSt. ermitteln"
                            (valueChange)="saveReport()"
                            name="vat-display-type">
                            <!--********** Options for common VAT **********-->
                            <ng-container *ngIf="report.valuation.taxationType === 'margin'">
                                <mat-option
                                    value="relativeToSalesPrice"
                                    matTooltip="MwSt. auf den Verkaufspreis des Fahrzeugs">
                                    2,4% auf Fahrzeugpreis
                                </mat-option>
                                <mat-option
                                    value="relativeToMargin"
                                    matTooltip="Die Handelsspanne wird von der DAT gesondert berechnet.">
                                    19% auf Handelsspanne
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="report.valuation.taxationType === 'margin_reduced'">
                                <mat-option
                                    value="relativeToSalesPrice"
                                    matTooltip="MwSt. auf den Verkaufspreis des Fahrzeugs">
                                    2,0% auf Fahrzeugpreis
                                </mat-option>
                                <mat-option
                                    value="relativeToMargin"
                                    matTooltip="Die Handelsspanne wird von der DAT gesondert berechnet.">
                                    16% auf Handelsspanne
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Price Origin **********-->
                    <div id="price-origin-container">
                        <h4>
                            Preis verwenden von
                            <span
                                class="help-indicator"
                                matTooltip="Die DAT-Bewertung erlaubt dem Sachverständigen das Überschreiben des ermittelten Verkaufswertes/Wiederbeschaffungswertes. Um die Dokumente mit dem selbst definierten Wert abzurufen, wähle die Option 'Sachverständiger'. Der manuelle Wert wird in der Oberfläche der DAT festgelegt.">
                                ?
                            </span>
                        </h4>
                        <div class="options-row">
                            <div
                                class="option-container"
                                [ngClass]="{ active: report.valuation.datValuation.priceOrigin === 'dat' }"
                                (click)="selectPriceOrigin('dat')">
                                <img class="option-image" src="/assets/images/logos/dat-logo-no-text.svg" />
                                <div class="option-title-container">
                                    <span class="option-title">DAT-Bewertung</span>
                                </div>
                            </div>
                            <div
                                class="option-container"
                                [ngClass]="{ active: report.valuation.datValuation.priceOrigin === 'assessor' }"
                                (click)="selectPriceOrigin('assessor')">
                                <img class="option-image" src="/assets/images/icons/person-blue_48.png" />
                                <div class="option-title-container">
                                    <span class="option-title">Sachverständiger</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!--============================================-->
                <!-- Dokumente -->
                <!--============================================-->
                <section id="documents">
                    <h3 id="documents-heading">Dokumente</h3>
                    <div class="toggle-container">
                        <div
                            class="toggle-label"
                            (click)="
                                toggleDocument('protocol', $event);
                                deactivateAllDocuments(report.valuation.datValuation.includedDocuments.protocol)
                            ">
                            Protokoll
                        </div>
                        <mat-slide-toggle
                            [(ngModel)]="report.valuation.datValuation.includedDocuments.protocol"
                            (ngModelChange)="deactivateAllDocuments($event); saveReport()"
                            name="protocol-document"></mat-slide-toggle>
                    </div>
                    <div class="second-level">
                        <div class="toggle-container">
                            <div class="toggle-label" (click)="toggleDocument('equipments', $event)">Ausstattung</div>
                            <mat-slide-toggle
                                [(ngModel)]="report.valuation.datValuation.includedDocuments.equipments"
                                (change)="activateProtocol(); saveReport()"
                                name="equipments-document"></mat-slide-toggle>
                        </div>
                        <div class="toggle-container">
                            <div class="toggle-label" (click)="toggleDocument('repairCosts', $event)">
                                Reparaturkosten
                            </div>
                            <mat-slide-toggle
                                [(ngModel)]="report.valuation.datValuation.includedDocuments.repairCosts"
                                (change)="activateProtocol(); saveReport()"
                                name="repair-costs-document"></mat-slide-toggle>
                        </div>
                        <div class="third-level" *ngIf="report.valuation.datValuation.includedDocuments.repairCosts">
                            <div class="toggle-container">
                                <div class="toggle-label" (click)="toggleDocument('repairCostsDetails', $event)">
                                    mit Details
                                </div>
                                <mat-slide-toggle
                                    [(ngModel)]="report.valuation.datValuation.includedDocuments.repairCostsDetails"
                                    (change)="activateProtocol(); saveReport()"
                                    name="repair-costs-details-document"></mat-slide-toggle>
                            </div>
                        </div>
                        <div class="toggle-container">
                            <div class="toggle-label" (click)="toggleDocument('diminishedValue', $event)">
                                Wertminderung
                            </div>
                            <mat-slide-toggle
                                [(ngModel)]="report.valuation.datValuation.includedDocuments.diminishedValue"
                                (change)="activateProtocol(); saveReport()"
                                name="diminished-value-document"></mat-slide-toggle>
                        </div>
                        <div class="toggle-container">
                            <div class="toggle-label" (click)="toggleDocument('increasedValue', $event)">
                                Werterhöhung
                            </div>
                            <mat-slide-toggle
                                [(ngModel)]="report.valuation.datValuation.includedDocuments.increasedValue"
                                (change)="activateProtocol(); saveReport()"
                                name="increased-value-document"></mat-slide-toggle>
                        </div>
                        <div class="third-level" *ngIf="report.valuation.datValuation.includedDocuments.increasedValue">
                            <div class="toggle-container">
                                <div class="toggle-label" (click)="toggleDocument('increasedValueDetails', $event)">
                                    mit Details
                                </div>
                                <mat-slide-toggle
                                    [(ngModel)]="report.valuation.datValuation.includedDocuments.increasedValueDetails"
                                    (change)="activateProtocol(); saveReport()"
                                    name="increased-value-details-document"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Dokumente -->
                <!--============================================-->
            </div>

            <!--***********************************************
            ** Errors
            ************************************************-->
            <div id="error-container">
                <div class="info-note warning-note label" *ngIf="!isAssessorDefinedDatValueLogical()">
                    <mat-icon class="medium-icon">warning</mat-icon>
                    <div>
                        <h3>Ungültige Kombination</h3>
                        Die DAT lässt nur eine Überschreibung des Händler-Verkaufspreises/Wiederbeschaffungswerts zu.
                        <br />
                        <br />
                        Bitte ändere die Wertangabe zu "Wiederbeschaffungswert" oder "Händler-Verkaufspreis" oder
                        verwende den Wert der DAT.
                    </div>
                </div>
            </div>
            <!--***********************************************
            ** END Errors
            ************************************************-->

            <div id="buttons-container">
                <button (click)="closeEditor()">Schließen</button>
                <button class="secondary" (click)="rememberSettings()" matTooltip="Einstellungen merken">
                    Merken
                    <span class="button-overlay" *ngIf="userPreferencesUpdatedIconShown" @fadeInAndOut>
                        <mat-icon class="button-action-done-icon">check</mat-icon>
                    </span>
                </button>
            </div>
        </form>
    </div>
</div>

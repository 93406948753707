<div id="document-layout-config-list-item">
    <!--***********************************************
    ** Title & Tags
    ************************************************-->
    <div id="title-and-document-types-container">
        <div id="title-container">
            <div id="title" *ngIf="!isTitleEditModeActive" (dblclick)="enterTitleEditMode()">
                {{ documentLayoutConfig.title }}
                <mat-icon
                    class="title-edit-icon input-toolbar-icon small-icon"
                    *ngIf="!disabled"
                    (click)="enterTitleEditMode()"
                    matTooltip="Titel bearbeiten. Der Titel dient nur der internen Verwendung und wird nicht abgedruckt.">
                    edit
                </mat-icon>
            </div>
            <div class="title-input-container" *ngIf="isTitleEditModeActive">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Titel"
                        [(ngModel)]="documentLayoutConfig.title"
                        (change)="emitChangeEvent()"
                        (keydown)="leaveTitleEditModeOnEnter($event)"
                        [axAutofocus]="true" />
                    <mat-icon
                        matSuffix
                        class="input-toolbar-icon"
                        (click)="leaveTitleEditMode()"
                        matTooltip="Bearbeitung beenden">
                        check
                    </mat-icon>
                </mat-form-field>
            </div>
        </div>

        <!--********** Default **********-->
        <div *ngIf="documentLayoutConfig.isDefault" id="default-indicator" [matTooltip]="getSetAsDefaultTooltip()">
            <mat-icon
                id="default-icon"
                class="small-icon"
                (click)="setAsDefault()"
                [ngClass]="{ active: documentLayoutConfig.isDefault }">
                star
            </mat-icon>
            <div class="label">Standard</div>
        </div>
        <!--********** Associated with Specific Documents **********-->
        <document-type-tag-list
            *ngIf="!documentLayoutConfig.isDefault"
            [disallowPowerOfAttorney]="true"
            [class.contains-open-dialog]="isDocumentTypeDialogOpen"
            [disabled]="disabled"
            [numberOfDisplayedTags]="3"
            [selectedDocumentOrderConfigIds]="documentLayoutConfig.associatedDocumentOrderConfigIds"
            [allDocumentOrderConfigs]="documentOrderConfigs"
            (showDialog)="onShowDocumentTypesDialog()"
            (closeDialog)="onCloseDocumentTypesDialog()"
            (change)="handleChangeInAssociatedDocumentTypes($event)"></document-type-tag-list>
    </div>
    <!--***********************************************
    ** END Title & Tags
    ************************************************-->

    <!--***********************************************
    ** Layout Style
    ************************************************-->
    <div id="style-selector-container" [matMenuTriggerFor]="layoutStyleMenu">
        <mat-icon class="input-toolbar-icon small-icon">type_specimen</mat-icon>
        <span id="layout-style-handle" class="label" [ngSwitch]="documentLayoutConfig.documentTemplateStyle">
            <span *ngSwitchCase="null">Stil wählen</span>
            <span *ngSwitchCase="'modern'">Modern</span>
            <span *ngSwitchCase="'classic'">Klassisch</span>
            <span *ngSwitchCase="'basedOnHeaderAndFooterTemplate'">Abweichend</span>
        </span>
    </div>
    <mat-menu #layoutStyleMenu>
        <a
            mat-menu-item
            (click)="setStyle('modern'); emitChangeEvent()"
            matTooltip="Schriftart: Source Sans Pro.{{ '\n' }}Textausrichtung: linksbündig.{{
                '\n'
            }}Zentrale Daten werden in deiner Akzentfarbe hervorgehoben.">
            Modern
        </a>
        <a
            mat-menu-item
            (click)="setStyle('classic'); emitChangeEvent()"
            matTooltip="Schriftart: Arial.{{ '\n' }}Textausrichtung: Blocksatz.{{
                '\n'
            }}Zentrale Daten werden rechtsbündig & schwarz hervorgehoben.">
            Klassisch
        </a>
        <a
            mat-menu-item
            (click)="setStyle('basedOnHeaderAndFooterTemplate'); emitChangeEvent()"
            matTooltip="Die Stilvorlagen werden aus der Docx-Datei deiner Kopf- & Fußzeile übernommen.{{
                '\n'
            }}Das solltest du nur wählen, wenn du dich gut mit den Stilvorlagen in Microsoft Word auskennst.">
            Abweichend
        </a>
    </mat-menu>
    <!--***********************************************
    ** END Layout Style
    ************************************************-->

    <!--***********************************************
    ** Toolbar
    ************************************************-->
    <div class="toolbar has-submenu">
        <!--********** Download **********-->
        <img
            class="pending-icon toolbar-icon"
            src="/assets/images/icons/loading-indicator.svg"
            *ngIf="isHeaderAndFooterDownloadPending"
            matTooltip="Lädt runter..." />
        <mat-icon
            class="toolbar-icon"
            [class.disabled]="disabled"
            (click)="downloadHeaderAndFooterTemplate()"
            *ngIf="!isHeaderAndFooterDownloadPending && documentLayoutConfig.headerAndFooterTemplateHash"
            matTooltip="Vorlage herunterladen">
            file_download
        </mat-icon>

        <!--********** Upload **********-->
        <img
            class="pending-icon toolbar-icon"
            src="/assets/images/icons/loading-indicator.svg"
            *ngIf="isHeaderAndFooterUploadPending"
            matTooltip="Lädt hoch..." />
        <mat-icon
            class="toolbar-icon"
            [class.disabled]="disabled"
            (click)="openFileSelector()"
            *ngIf="!isHeaderAndFooterUploadPending"
            matTooltip="Neue Vorlage hochladen">
            file_upload
        </mat-icon>

        <!--********** Submenu **********-->
        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="documentLayoutConfigMenu">more_vert</mat-icon>
        <mat-menu #documentLayoutConfigMenu="matMenu">
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="setAsDefault()"
                matTooltip="Dieses Briefpapier für alle Dokumente verwenden, die kein eigenes Briefpapier haben.">
                <mat-icon>star</mat-icon>
                Als Standard setzen
            </a>
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="resetHeaderAndFooterTemplate()"
                matTooltip="Das Standard-Briefpapier von autoiXpert einfügen. Das überschreibt dein bisheriges Briefpapier.">
                <mat-icon>restart_alt</mat-icon>
                Zurücksetzen
            </a>
            <a
                mat-menu-item
                class="menu-item-with-icon alert"
                (click)="deleteDocumentLayoutConfig()"
                [matTooltip]="
                    documentLayoutConfig.isDefault
                        ? 'Das Standard-Briefpapier kann nicht gelöscht werden.'
                        : 'Briefpapier entfernen.'
                "
                [class.disabled]="documentLayoutConfig.isDefault">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>
    </div>
    <!--***********************************************
    ** END Toolbar
    ************************************************-->

    <input type="file" #fileUpload accept=".docx" (change)="onChangeHeaderAndFooterUploadFile($event.target)" />
</div>

import { CdkDragDrop } from '@angular/cdk/drag-drop/index';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { CustomFieldDropdownOption } from '@autoixpert/models/custom-fields/custom-field-dropdown-option';
import { PaintThicknessScale } from '@autoixpert/models/reports/paint-thickness-scale';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../../../../shared/animations/fade-in-and-slide.animation';
import { LoggedInUserService } from '../../../../../shared/services/logged-in-user.service';
import { PaintThicknessInputComponent } from '../paint-thickness-input/paint-thickness-input.component';

@Component({
    selector: 'paint-thickness-scale-edit-dialog',
    templateUrl: 'paint-thickness-scale-edit-dialog.component.html',
    styleUrls: ['paint-thickness-scale-edit-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), fadeInAndSlideAnimation()],
})
export class PaintThicknessScaleEditDialogComponent implements OnInit {
    constructor(private loggedInUserService: LoggedInUserService) {}

    @Input() disabled: boolean = false;
    @Input() selectedPaintThicknessScaleId: PaintThicknessScale['_id'];

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() selectedPaintThicknessScaleIdChange: EventEmitter<PaintThicknessScale['_id']> = new EventEmitter();
    @Output() scaleDataChanged: EventEmitter<void> = new EventEmitter();

    @ViewChildren(PaintThicknessInputComponent)
    paintThicknessInputs: QueryList<PaintThicknessInputComponent>;

    private user: User;
    private team: Team;

    protected selectedPaintThicknessScale: PaintThicknessScale;
    protected paintThicknessScales: PaintThicknessScale[] = [];

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        this.paintThicknessScales = this.team.preferences.paintThicknessMeasurementScales;

        this.selectedPaintThicknessScale = this.paintThicknessScales.find(
            (scale) => scale._id === this.selectedPaintThicknessScaleId,
        );
    }

    protected handleScaleChange(): void {
        for (const input of this.paintThicknessInputs) {
            input.determineType();
        }
    }

    //*****************************************************************************
    //  Scale List
    //****************************************************************************/
    protected selectPaintThicknessScale(scale: PaintThicknessScale) {
        this.selectedPaintThicknessScale = scale;
        this.selectedPaintThicknessScaleIdChange.emit(scale._id);
    }

    protected markPaintThicknessScaleAsFavorite(scale: PaintThicknessScale) {
        // Unmark all others.
        this.paintThicknessScales.forEach((scale) => (scale.isDefault = false));

        scale.isDefault = true;

        this.emitDataChange();
    }

    protected emitDataChange(): void {
        this.scaleDataChanged.emit();
    }

    protected createPaintThicknessScale() {
        if (this.disabled) return;

        const newScale = new PaintThicknessScale();
        this.paintThicknessScales.push(newScale);
        this.selectPaintThicknessScale(newScale);

        this.emitDataChange();
    }

    protected copyPaintThicknessScale(scale: PaintThicknessScale) {
        if (this.disabled) return;

        const copy: PaintThicknessScale = JSON.parse(JSON.stringify(scale));

        const newScale: PaintThicknessScale = new PaintThicknessScale({
            ...copy,
            name: `${copy.name} (Kopie)`,
            isDefault: false,
        });
        this.paintThicknessScales.push(newScale);
        this.selectPaintThicknessScale(newScale);

        this.emitDataChange();
    }

    protected deletePaintThicknessScale(scale: PaintThicknessScale) {
        if (this.disabled) return;

        if (this.paintThicknessScales?.length === 1) {
            // Never let the user delete the last scale
            return;
        }

        removeFromArray(scale, this.paintThicknessScales);

        // If the deleted scale was the selected one, select another.
        if (this.selectedPaintThicknessScale === scale) {
            this.selectPaintThicknessScale(this.paintThicknessScales[0]);
        }

        // When deleting the default scale -> set default to another scale
        if (scale.isDefault) {
            this.paintThicknessScales[0].isDefault = true;
        }

        this.emitDataChange();
    }

    protected reorderPaintThicknessScales(event: CdkDragDrop<CustomFieldDropdownOption[]>): void {
        const movedLineItem = this.paintThicknessScales.splice(event.previousIndex, 1)[0];
        // Add the item back at the new position
        this.paintThicknessScales.splice(event.currentIndex, 0, movedLineItem);

        // Save the new order back to the server.
        this.emitDataChange();
    }

    protected async closePaintThicknessScaleDialog() {
        this.close.emit();
    }

    protected handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            void this.closePaintThicknessScaleDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected closeEditorOnEscKey(event) {
        if (event.key === 'Escape') {
            // Make sure saving is triggered if the user is still inside an input.
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                (document.activeElement as HTMLElement).blur();
            }
            void this.closePaintThicknessScaleDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

import { ApiErrorHandleParameters } from '../../services/api-error.service';

export function getEmailApiErrorHandlers(): ApiErrorHandleParameters['handlers'] {
    return {
        //*****************************************************************************
        //  SMTP
        //****************************************************************************/
        SMTP_HOST_NOT_FOUND: {
            title: 'SMTP-Server nicht gefunden',
            body: 'Bitte prüfe den Host-Server in den Einstellungen deines E-Mail-Kontos.',
        },
        SMTP_AUTHENTICATION_FAILED: {
            title: 'E-Mail-Zugangsdaten ungültig',
            body: 'Bitte prüfe Benutzername und Passwort deines E-Mail-Kontos.',
        },
        TOO_MANY_BAD_AUTH_ATTEMPTS: {
            title: 'Zu viele falsche Anmeldeversuche',
            body: 'Laut deinem E-Mail-Provider sind Benutzername und Passwort zu häufig falsch eingegeben worden.<br><br>Bitte warte einige Zeit (je nach Anbieter manchmal Stunden), prüfe dann deine Zugangsdaten in den <a href="/Einstellungen">Einstellungen</a> und versuche es erneut.',
        },
        GMAIL_AUTHENTICATION_BLOCKED: {
            title: 'Gmail blockiert Anmeldung',
            body: 'Prüfe Benutzername und Passwort. Aktiviere außerdem den Gmail-Versand für Dritt-Applikationen wie autoiXpert. <a href="https://wissen.autoixpert.de/hc/de/articles/360007488851" target="_blank" rel=”noopener”>Anleitung</a>.',
        },
        // In case the Google account has two-factor authentication enabled.
        GMAIL_APP_SPECIFIC_PASSWORD_REQUIRED: {
            title: 'App-spezifisches Passwort',
            body: 'Damit autoiXpert E-Mails via Gmail versenden darf, musst du ein <a href="https://support.google.com/mail/?p=InvalidSecondFactor" target="_blank" rel=”noopener”>App-spezifisches Passwort definieren</a>.',
        },
        MICROSOFT_PREVENTED_DISABLED_SMTP_CLIENT_AUTHENTICATION: {
            title: 'Microsoft-Account muss verknüpft werden',
            body: 'Seit September 2024 erfordert Microsoft eine andere (sicherere) Anmeldung für den E-Mail-Versand. Bitte verknüpfe dafür deinen Microsoft-Account in den <a href="/Einstellungen">Einstellungen</a>.',
        },
        SMTP_CONNECTION_FAILED: {
            title: 'SMTP-Server nicht erreichbar',
            body: 'Bitte prüfe Host und Port zu deinem E-Mail-Konto.',
        },
        SMTP_TIMEOUT: (error) => ({
            title: 'SMTP Timeout',
            body: `Das könnte ein temporäres Problem zwischen deinem E-Mail-Provider und autoiXpert sein. Versuche es noch einmal.<br><br>
<strong>Nachricht:</strong>${error.data.smtpErrorMessage}<br>
<strong>Error-Code:</strong>${error.data.smtpErrorCode}<br>
<strong>Command:</strong>${error.data.smtpCommand}`,
        }),
        UNKNOWN_SMTP_ERROR: (error) => ({
            title: 'Verbindung zum E-Mail-Konto nicht möglich',
            body: `<strong>Nachricht:</strong><br>${error.data.smtpErrorMessage}<br><a href="https://www.google.de/search?q=smtp+${error.data.smtpErrorCode}+${error.data.smtpErrorMessage}" target="_blank" rel=”noopener”>Google-Suche ausführen</a>`,
        }),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END SMTP
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Email Images
        //****************************************************************************/
        ADDING_EMAIL_SIGNATURE_IMAGES_FAILED: {
            title: 'E-Mail-Signatur: Problem mit Bildern',
            body: `Bilder aus der E-Mail-Signatur konnten nicht als Anhang eingefügt werden.<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Email Images
        /////////////////////////////////////////////////////////////////////////////*/
    };
}

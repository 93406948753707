import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { CarEquipmentTemplate } from '@autoixpert/models/reports/car-identification/car-equipment-template';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class CarEquipmentTemplateService extends OfflineSyncServiceBase<CarEquipmentTemplate> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'carEquipmentTemplate',
            httpClient,
            networkStatusService,
            frontendLogService,
            syncIssueNotificationService,
            serviceWorker,
        });
    }
}

import { generateId } from '../../../lib/generate-id';

export class LeaseReturn {
    constructor(template: Partial<LeaseReturn> = {}) {
        Object.assign(this, template);
    }

    title: string = null;
    instantiatedTemplateId?: string = null; // ID of the template that was used to instantiate report.leaseReturn
    relativeResidualValue: number = null; // Relativer Restwert (Zeitwert des Fahrzeugs) in Prozent des Neupreises
    sections: LeaseReturnSection[] = [];
    leaseReturnItemTaxationType: 'net' | 'gross' = 'net';
}

export class LeaseReturnSection {
    constructor(template: Partial<LeaseReturnSection> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string;
    items: LeaseReturnItem[] = [];
}

export class LeaseReturnItem {
    constructor(template: Partial<LeaseReturnItem> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string = null;
    isRequired: boolean = null;
    comment: string = null;
    repairCostsNet: number = null;
    repairCostsGross: number = null;
    aboveAverageWearCostsNet: number = null;
    aboveAverageWearCostsGross: number = null;
    isVatNeutral: boolean = false;
    photoIds: string[] = [];
}

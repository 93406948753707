import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import {
    documentBuildingBlockRecordMigrations,
    documentBuildingBlockServiceObjectStoreAndIndexMigrations,
} from './document-building-block.service-migrations';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class DocumentBuildingBlockService extends OfflineSyncServiceBase<DocumentBuildingBlock> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected frontendLogService: FrontendLogService,
    ) {
        super({
            serviceName: 'documentBuildingBlock',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            objectStoreAndIndexMigrations: documentBuildingBlockServiceObjectStoreAndIndexMigrations,
            recordMigrations: documentBuildingBlockRecordMigrations,
        });
    }

    public findDefault(): Promise<DocumentBuildingBlock[]> {
        return this.httpClient.get<DocumentBuildingBlock[]>(`/api/v0/defaultDocumentBuildingBlocks`).toPromise();
    }
}

<div [@sentEmailItem]="'shown'" class="message-list-item" (click)="displayOutgoingMessage.emit(message)">
    <div class="message-icon-container">
        <div class="message-icon-ring" [matTooltip]="getOutgoingMessageStatusTooltip(message)">
            <mat-icon class="message-icon small-icon">send</mat-icon>
            <mat-icon
                *ngIf="getOutgoingMessageStatusIcon(message) as statusIcon"
                class="message-status-icon small-icon"
                [style.color]="getOutgoingMessageStatusColor(message)">
                {{ statusIcon }}
            </mat-icon>
        </div>
    </div>
    <div class="message-subject-container">
        <div class="message-subject-wrapper" [matTooltip]="message.subject">
            {{ message.subject }}
        </div>
    </div>
    <div
        *ngIf="message.deliveredAt || message.failedAt"
        class="message-date-container"
        matTooltip="Versandt: {{ message.sentAt | moment: 'DD.MM.YYYY - HH:mm' }}">
        {{ message.deliveredAt || message.failedAt | moment: 'DD.MM.YY' }}
    </div>
    <div class="message-submenue-container">
        <mat-icon
            *ngIf="getOutgoingMessageStatus(message) === 'scheduled'"
            class="submenu-icon"
            [matTooltip]="'Versand abbrechen'"
            (click)="deleteOutgoingMessage.emit(message); $event.stopPropagation()">
            undo
        </mat-icon>

        <mat-icon class="submenu-icon" [matMenuTriggerFor]="messageSubmenu" (click)="$event.stopPropagation()">
            more_vert
        </mat-icon>
        <mat-menu #messageSubmenu="matMenu">
            <a
                *ngIf="getOutgoingMessageStatus(message) === 'scheduled'"
                mat-menu-item
                (click)="sendOutgoingMessage.emit(message)">
                Jetzt senden
            </a>

            <a mat-menu-item (click)="displayOutgoingMessage.emit(message)">Anzeigen</a>
            <a mat-menu-item (click)="editAsNew.emit(message)">Als neu bearbeiten</a>
            <a mat-menu-item (click)="deleteOutgoingMessage.emit(message)">
                {{ getOutgoingMessageStatus(message) === 'scheduled' ? 'Versand abbrechen' : 'Löschen' }}
            </a>
        </mat-menu>
    </div>
</div>

import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password-card/forgot-password-card.component';
import { LoginCardComponent } from './login-card/login-card.component';
import { LoginComponent } from './login.component';

export const loginRoutes: Routes = [
    {
        path: 'Login',
        component: LoginComponent,
        children: [
            {
                path: '',
                component: LoginCardComponent,
            },
            {
                path: 'PasswortVergessen',
                component: ForgotPasswordComponent,
            },
        ],
    },
];

export const authProviders = [];

export const loginRouting = RouterModule.forChild(loginRoutes);

import { InvoiceRecipientRole } from '../../../models/invoices/invoice';
import { PaymentReminderLevel } from '../../../models/invoices/payment-reminder';
import { Translator } from '../../placeholder-values/translator';

/**
 * Formats the title for a payment reminder document.
 */
export function paymentReminderToDocumentTitle(
    paymentReminderLevel: PaymentReminderLevel,
    recipientRole: InvoiceRecipientRole,
): string {
    switch (paymentReminderLevel) {
        case 'level0':
            return `Zahlungserinnerung ${Translator.invoiceInvolvedPartyType(recipientRole)}`;
        case 'level1':
            return `1. Mahnung ${Translator.invoiceInvolvedPartyType(recipientRole)}`;
        case 'level2':
            return `2. Mahnung ${Translator.invoiceInvolvedPartyType(recipientRole)}`;
        case 'level3':
            return `3. Mahnung ${Translator.invoiceInvolvedPartyType(recipientRole)}`;
    }
}

<div id="layout-container">
    <!--============================================-->
    <!-- Menu -->
    <!--============================================-->
    <div id="menu-column"></div>
    <!--============================================-->
    <!-- END Menu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Reporting Column -->
    <!--============================================-->
    <div id="reporting-column">
        <!--============================================-->
        <!-- Graph -->
        <!--============================================-->
        <div id="graph-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Graph Header -->
            <!--============================================-->
            <div id="graph-header">
                <!--********** Report Name **********-->
                <div id="report-name-container">
                    <mat-icon
                        class="toolbar-icon"
                        matTooltip="Zurück zur Übersicht"
                        (click)="navigateBackToAnalyticsList()">
                        arrow_back
                    </mat-icon>
                    <div id="report-title-and-description-container">
                        <h2>{{ chartTitle }}</h2>
                    </div>
                </div>

                <short-payment-analytics-navigation-bar></short-payment-analytics-navigation-bar>

                <analytics-filter-button [filterRef]="filter"></analytics-filter-button>

                <div class="date-and-settings-container">
                    <!--********** Date Filter **********-->
                    <date-range-picker
                        [(dateFrom)]="filterAnalyticsFrom"
                        [(dateTo)]="filterAnalyticsTo"
                        (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
                    <analytics-settings-menu
                        (useNetValuesChange)="refreshLocalData()"
                        localStoragePostfix="ShortPayments"
                        [useNetValuesDefaultValue]="false"></analytics-settings-menu>
                </div>
            </div>

            <!--============================================-->
            <!-- END Graph Header -->
            <!--============================================-->

            <analytics-filter (filterChange)="updateAnalytics()" (initialized)="filtersLoaded()"></analytics-filter>

            <div class="chart-container">
                <div class="chart-placeholder" *ngIf="analyticsPending">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Lade Auswertung ...
                </div>
                <div class="chart-placeholder" *ngIf="!analyticsPending && records.length === 0">
                    <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                    <div class="no-data-found-message">Keine Daten gefunden</div>
                    Probiere doch andere Filter.
                </div>
                <div id="chart" *ngIf="!analyticsPending && records.length"></div>
            </div>

            <!--============================================-->
            <!-- Graph Summary -->
            <!--============================================-->
            <div id="graph-summary-container" class="card">
                <!--********** Total **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Kürzungen
                        <span class="series-color short-payment-color"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ totalShortPayments | number: '1.0-0' }} €</div>
                </div>

                <!--********** Invoices **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Gekürzte Rechnungen</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalNumberOfShortPaidInvoices | number: '1.0-0' }}
                    </div>
                </div>

                <!--********** % of Short Payments **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Kürzungsquote
                        <span class="help-indicator" matTooltip="Anteil der Kürzungen am Gesamtumsatz">?</span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ (totalShortPayments / totalRevenueGross || 0) * 100 | number: '1.0-1' }} %
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Graph Summary -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Graph -->
        <!--============================================-->

        <!--============================================-->
        <!-- Series List -->
        <!--============================================-->
        <div id="series-list-card" class="card card-with-padding">
            <div id="series-list-toolbar">
                <export-invoice-analytics-records-icon
                    [records]="$any(records)"
                    [summaryDownloadName]="chartTitle"></export-invoice-analytics-records-icon>
            </div>
            <div id="series-list-table-container">
                <table id="series-list">
                    <thead>
                        <tr>
                            <!--********** Insurance Name **********-->
                            <th class="series-entry-title-heading">Versicherung</th>

                            <!--********** Short Payment Total **********-->
                            <th class="series-entry-value-heading">
                                <span class="series-color short-payment-color"></span>
                                Kürzungen ({{ settings.useNetValues ? 'netto' : 'brutto' }})
                            </th>

                            <!--********** Revenue with this insurance **********-->
                            <th class="series-entry-value-heading">
                                Umsatz ({{ settings.useNetValues ? 'netto' : 'brutto' }})
                                <span class="help-indicator" matTooltip="Gesamtumsatz mit dieser Versicherung">?</span>
                            </th>

                            <!--********** Percentage of Short Payments relative to the revenue with this insurance **********-->
                            <th class="series-entry-value-heading">
                                Kürzungsquote
                                <span
                                    class="help-indicator"
                                    matTooltip="Prozentualer Anteil aller Kürzungen dieser Versicherung am Umsatz mit dieser Versicherung">
                                    ?
                                </span>
                            </th>

                            <!--********** Share of all short payments (across insurances) **********-->
                            <th class="series-entry-value-heading">
                                Anteil an Kürzungen
                                <span
                                    class="help-indicator"
                                    matTooltip="Welchen Anteil hat diese Versicherung mit ihren Kürzungen an der Gesamtsumme deiner Kürzungen?">
                                    ?
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="series-entry-row"
                            *ngFor="let record of records"
                            [ngClass]="{ hidden: isRecordHidden(record) }">
                            <!--********** Insurance Name **********-->
                            <td class="series-entry-title-cell" [ngClass]="{ 'not-available': !record.insurance }">
                                <div class="series-entry-title-inner-container">
                                    <div class="flex-grow text-overflow-ellipsis">
                                        {{ record.insurance || 'Ohne Versicherung' }}
                                    </div>
                                    <mat-icon
                                        class="toolbar-icon toggle-hide-record-icon"
                                        matTooltip="Diesen Datensatz im Diagramm ausblenden"
                                        *ngIf="!isRecordHidden(record)"
                                        (click)="toggleHideRecord(record); refreshLocalData()">
                                        visibility
                                    </mat-icon>
                                    <mat-icon
                                        class="toolbar-icon toggle-hide-record-icon"
                                        matTooltip="Diesen Datensatz im Diagramm einblenden"
                                        *ngIf="isRecordHidden(record)"
                                        (click)="toggleHideRecord(record); refreshLocalData()">
                                        visibility_off
                                    </mat-icon>
                                </div>
                            </td>

                            <!--********** Short Payment Total **********-->
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues
                                        ? record.allShortPaymentsSumNet
                                        : record.allShortPaymentsSumGross
                                    ) | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfShortPaidInvoices"
                                    [displayNetValues]="settings.useNetValues">
                                    in {{ record.idsOfShortPaidInvoices.length }}
                                    {{ record.idsOfShortPaidInvoices.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                </div>
                            </td>

                            <!--********** Total Revenue With This Insurance **********-->
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross)
                                        | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfRegularInvoices"
                                    [displayNetValues]="settings.useNetValues">
                                    {{ record.idsOfRegularInvoices.length }}
                                    {{ record.idsOfRegularInvoices.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                </div>
                            </td>

                            <!--********** Short Payment Quota This Insurance **********-->
                            <td class="series-entry-value">
                                {{ (record.shortPaymentQuota || 0) * 100 | number: '1.0-1' }} %
                            </td>

                            <!--********** Short Payment Quota Overall **********-->
                            <td class="series-entry-value">
                                {{
                                    ((settings.useNetValues
                                        ? record.allShortPaymentsSumNet
                                        : record.allShortPaymentsSumGross) / totalShortPayments || 0) * 100
                                        | number: '1.0-1'
                                }}
                                %
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Series List -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Reporting Column -->
    <!--============================================-->
</div>

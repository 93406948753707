// Slide animation for the calculation provider logos
import { animate, style, transition, trigger } from '@angular/animations';

export function slideOutLeft(enterDuration = 300, leaveDuration = 300) {
    return trigger('slideOutLeft', [
        transition(':enter', [
            style({
                transform: 'translate3d(-100%, 0, 0)',
            }),
            animate(`${enterDuration}ms ease`),
        ]),
        transition(
            ':leave',
            animate(
                `${leaveDuration}ms ease`,
                style({
                    transform: 'translate3d(0, 0, 0)',
                }),
            ),
        ),
    ]);
}

<!-- Shadow DIV -->
<!-- [style.margin-left]="singleLine ? '0' : 'calc(-1 * (var(--padding-horizontal) + 2px))'" -->
<div
    #shadowDiv
    class="shadow-div"
    [style.white-space]="singleLine ? 'pre' : undefined"
    [textContent]="value || placeholder || '​'"></div>

<!-- Textarea -->
<!-- [style.margin-left]="singleLine ? '0' : 'calc(-1 * (var(--padding-horizontal) + 2px))'" -->
<textarea
    #valueInput
    spellcheck="true"
    autocomplete="off"
    autocorrect="off"
    [placeholder]="placeholder"
    [value]="value || ''"
    [rows]="singleLine ? 1 : undefined"
    [wrap]="singleLine ? 'off' : undefined"
    [style.white-space]="singleLine ? 'pre' : undefined"
    (keydown.enter)="handleKeydownEnter($event)"
    (keydown.shift.enter)="handleKeydownShiftEnter($event)"
    (input)="handleInputChange($event)"
    (paste)="handlePaste($event)"
    (focus)="handleFocus($event)"
    (blur)="handleBlur($event)"
    (elementCreated)="setTextareaContent(value)"></textarea>

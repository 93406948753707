<div class="analytics-filter-button" [ngClass]="{ 'has-active-filters': activeFiltersLabel, expanded: isExpanded }">
    <div class="inverted-border-radius-background">
        <div class="inverted-border-radius-foreground"></div>
    </div>
    <mat-icon class="icon">filter_alt</mat-icon>
    <div class="ax-header-select" (click)="toggleExpandedState()" [ngClass]="{ active: activeFiltersLabel }">
        <!-- Label -->
        <div class="ax-header-select-label">
            <!-- No filter placeholder -->
            <span *ngIf="!activeFiltersLabel">Filter</span>

            <!-- Filters -->
            <span *ngIf="activeFiltersLabel">{{ activeFiltersLabel }}</span>
        </div>

        <!-- Dropdown icon -->
        <mat-icon class="dropdown-icon">arrow_drop_down</mat-icon>
    </div>
    <div class="inverted-border-radius-background right">
        <div class="inverted-border-radius-foreground"></div>
    </div>
</div>

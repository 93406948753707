<h2 class="margin-bottom-10">Layout der Dokumente</h2>
<div class="label text-align-center margin-bottom-20">
    Bestimmte das Aussehen deines Briefpapiers. Entweder einheitlich oder pro Dokumenttyp.
</div>

<!--***********************************************
** Sub Menu
************************************************-->
<div id="document-layout-groups-toolbar-container">
    <mat-icon
        class="toolbar-icon document-layout-groups-submenu-icon"
        [matMenuTriggerFor]="documentLayoutGroupsSubmenu">
        more_vert
    </mat-icon>
</div>
<mat-menu #documentLayoutGroupsSubmenu>
    <a
        mat-menu-item
        (click)="addDocumentLayoutGroup()"
        matTooltip="Weitere Layoutgruppen ermöglichen dir, das Briefpapier eines zusätzlichen Büros einzupflegen. Relevant, wenn du ein Büro gekauft hast und dessen Briefpapier weiterverwenden möchtest.">
        <mat-icon>add</mat-icon>
        Layoutgruppe hinzufügen
    </a>
</mat-menu>
<!--***********************************************
** END Sub Menu
************************************************-->

<!--***********************************************
** Layout Groups
************************************************-->
<div id="document-layout-groups-container">
    <document-layout-group
        *ngFor="let documentLayoutGroup of documentLayoutGroups"
        [user]="user"
        [team]="team"
        [teamMembers]="teamMembers"
        [showTitle]="documentLayoutGroups.length > 1"
        [showOfficeLocations]="documentLayoutGroups.length > 1"
        [availableOfficeLocations]="getOfficeLocationsWithoutAssociation()"
        [documentLayoutGroup]="documentLayoutGroup"
        [documentOrderConfigs]="documentOrderConfigs"
        [disabled]="!isAdmin(user._id, team)"
        (default)="removeDefaultFromOtherLayoutGroups($event)"></document-layout-group>
</div>
<!--***********************************************
** END Layout Groups
************************************************-->

<!--============================================-->
<!-- Header & Footer Instructions -->
<!--============================================-->
<div id="header-and-footer-instructions-columns">
    <!--********** Left Column **********-->
    <div id="header-and-footer-instructions-left-column">
        <!--********** Video for Header & Footer **********-->
        <div
            class="video-container"
            (click)="openVideo('Kopf- & Fußzeile einstellen', 'https://www.youtube.com/embed/LhYAey2JvC4?rel=0')">
            <div class="video-thumbnail-container">
                <img
                    class="video-thumbnail"
                    src="/assets/images/video-thumbnails/tour-video-thumbnail.png"
                    alt="Tour Video" />
                <img class="video-play-icon" src="/assets/images/icons/play.svg" alt="Play" />
            </div>
            <div class="video-labels">
                <div class="video-title">Kopf- & Fußzeile</div>
                <div class="video-duration">3:52 min</div>
            </div>
        </div>
    </div>

    <!--********** Right Column **********-->
    <div id="header-and-footer-instructions-right-column">
        <!--********** Config Hints **********-->
        <div class="header-and-footer-instructions-right-column-inner-column">
            <h3>Tipps zur Einrichtung</h3>
            <div>
                <a
                    href="https://wissen.autoixpert.de/hc/de/articles/360024274991"
                    class="helpcenter-link"
                    target="_blank"
                    rel="noopener">
                    Kopf- & Fußzeile einstellen
                </a>
            </div>

            <div>
                <a
                    href="https://wissen.autoixpert.de/hc/de/articles/360016612740"
                    class="helpcenter-link"
                    target="_blank"
                    rel="noopener">
                    Typische Platzhalter
                </a>
            </div>

            <div>
                <a href="https://image-color.com/" class="helpcenter-link" target="_blank" rel="noopener">
                    Logo-Farbe finden
                </a>
            </div>
        </div>

        <!--********** Troubleshooting **********-->
        <div class="header-and-footer-instructions-right-column-inner-column">
            <h3>Probleme lösen</h3>
            <div>
                <a
                    href="https://wissen.autoixpert.de/hc/de/articles/16538289661458-Leerseiten-Brieffenster-falsch-positioniert-oder-zu-viel-Abstand-im-Gutachten"
                    class="helpcenter-link"
                    target="_blank"
                    rel="noopener">
                    Deckblatt bricht um
                </a>
            </div>

            <div>
                <a
                    href="https://wissen.autoixpert.de/hc/de/articles/16538289661458-Leerseiten-Brieffenster-falsch-positioniert-oder-zu-viel-Abstand-im-Gutachten"
                    class="helpcenter-link"
                    target="_blank"
                    rel="noopener">
                    Brieffenster verschoben
                </a>
            </div>

            <div>
                <a
                    href="https://wissen.autoixpert.de/hc/de/articles/16538289661458-Leerseiten-Brieffenster-falsch-positioniert-oder-zu-viel-Abstand-im-Gutachten"
                    class="helpcenter-link"
                    target="_blank"
                    rel="noopener">
                    Leerseiten im Gutachten
                </a>
            </div>
        </div>
    </div>

    <div id="question-mark-container">
        <mat-icon class="outlined">help</mat-icon>
    </div>
</div>
<!--============================================-->
<!-- END Header & Footer Instructions -->
<!--============================================-->

export type CustomFieldLocation =
    | 'accident-data_claimant'
    | 'accident-data_lawyer'
    | 'accident-data_report-properties'
    | 'accident-data_accident'
    | 'accident-data_author-of-damage'
    | 'accident-data_insurance'
    | 'accident-data_claimant-signatures'
    | 'car-data_basic-data'
    | 'car-data_extended-data'
    | 'car-data_equipment'
    | 'car-condition_condition'
    | 'car-condition_damage-sketch'
    | 'car-condition_paint-thickness-sketch'
    | 'car-condition_tires'
    | 'car-condition_previous-and-unrepaired-damages'
    | 'car-condition_damage-description'

    // Currently, I can't image a good location of a custom field within the photos component.
    //'photos' |
    | 'damage-calculation_garage'
    | 'damage-calculation_calculation'
    | 'damage-calculation_market-analysis'
    | 'damage-calculation_downtime-compensation'
    | 'damage-calculation_residual-value'
    | 'damage-calculation_old-vehicle-certificates'
    | 'damage-calculation_vehicle-value'
    | 'damage-calculation_repair'

    // It seems sensible to use only one area for custom fields here since the invoice is already very easy to configure.
    | 'fees'

    // It seems sensible to use only one area for custom fields here since letters and emails are not linked to document building blocks and therefore not to conditions or custom fields.
    | 'print-and-transmission';

export function getCustomFieldLocationGerman(customFieldLocation: CustomFieldLocation): string {
    switch (customFieldLocation) {
        case 'accident-data_claimant':
            return 'Unfall & Beteiligte > Anspruchsteller';
        case 'accident-data_lawyer':
            return 'Unfall & Beteiligte > Anwalt';
        case 'accident-data_report-properties':
            return 'Unfall & Beteiligte > Gutachten-Eigenschaften';
        case 'accident-data_accident':
            return 'Unfall & Beteiligte > Unfalldaten';
        case 'accident-data_author-of-damage':
            return 'Unfall & Beteiligte > Unfallgegner';
        case 'accident-data_insurance':
            return 'Unfall & Beteiligte > Versicherung';
        case 'accident-data_claimant-signatures':
            return 'Unfall & Beteiligte > Unterschriften';

        case 'car-data_basic-data':
            return 'Fahrzeugauswahl > Basisdaten';
        case 'car-data_extended-data':
            return 'Fahrzeugauswahl > Fahrzeugdaten erweitert';
        case 'car-data_equipment':
            return 'Fahrzeugauswahl > Ausstattungen';

        case 'car-condition_condition':
            return 'Zustand > Karte Zustand';
        case 'car-condition_damage-sketch':
            return 'Zustand > Anstoßskizze';
        case 'car-condition_paint-thickness-sketch':
            return 'Zustand > Lackschichtdicke';
        case 'car-condition_tires':
            return 'Zustand > Reifen';
        case 'car-condition_previous-and-unrepaired-damages':
            return 'Zustand > Vor- & Altschäden';
        case 'car-condition_damage-description':
            return 'Zustand > Schadenbeschreibung';

        case 'damage-calculation_garage':
            return 'Kalkulation > Werkstatt';
        case 'damage-calculation_calculation':
            return 'Kalkulation > Karte Kalkulation';
        case 'damage-calculation_market-analysis':
            return 'Kalkulation > Marktwert';
        case 'damage-calculation_downtime-compensation':
            return 'Kalkulation > Nutzungsausfall';
        case 'damage-calculation_residual-value':
            return 'Kalkulation > Restwert';
        case 'damage-calculation_old-vehicle-certificates':
            return 'Kalkulation > Altfahrzeugzertifikate';
        case 'damage-calculation_vehicle-value':
            return 'Kalkulation > Fahrzeugwert';
        case 'damage-calculation_repair':
            return 'Kalkulation > Reparatur';

        case 'fees':
            return 'Rechnung';

        case 'print-and-transmission':
            return 'Druck & Versand';

        default:
            return `${customFieldLocation} (keine Übersetzung verfügbar)`;
    }
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';

@Component({
    selector: 'gtue-redirect-page',
    templateUrl: './gtue-redirect-page.component.html',
    styleUrls: ['./gtue-redirect-page.component.scss'],
})
export class GtueRedirectPageComponent implements OnInit {
    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly httpClient: HttpClient,
        private readonly toastService: ToastService,
    ) {}

    ngOnInit(): void {
        // read out auth_code from query params
        const authCode = this.route.snapshot.queryParamMap.get('code');

        if (authCode) {
            // make request to backend at /auth/token
            this.httpClient
                .get<{ success: boolean }>(`/api/v0/gtue/auth/token`, { params: { authCode } })
                .toPromise()
                .then((response) => {
                    if (response.success) {
                        // After success, return to invoice list and open dialog for import
                        this.router.navigate(['Rechnungen'], { queryParams: { showGtueImportDialog: true } });
                    } else {
                        this.showErrorAndReturnToInvoiceList();
                    }
                })
                .catch(() => {
                    this.showErrorAndReturnToInvoiceList();
                });
        } else {
            // User probably got redirected after logout -> show notification
            this.toastService.info('Erfolgreich abgemeldet', 'Du wurdest erfolgreich bei GTÜ+ abgemeldet.');
            this.router.navigate(['Rechnungen'], { queryParams: { showGtueImportDialog: true } });
        }
    }

    showErrorAndReturnToInvoiceList(): void {
        this.toastService.error(
            'GTÜ-Anmeldung gescheitert.',
            'Die Anmeldung bei GTÜ konnte nicht erfolgreich durchgeführt werden. Bitte versuche es später erneut oder kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
        );
        this.router.navigate(['Rechnungen']);
    }
}

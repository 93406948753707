import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { fadeInAndSlideAnimation } from '@autoixpert/animations/fade-in-and-slide.animation';
import { PANEL_CLOSE_SUBJECT_TOKEN } from '@autoixpert/injection-tokens/common-overlay-injection-tokens';
import { CheckboxElement } from '@autoixpert/models/signable-documents/signable-pdf-template-config';

/**
 * If the user double-clicks a checkbox element, this panel will be opened.
 *
 * This panel allows negating the condition under which this checkbox will be printed.
 *
 * The PdfCheckboxElementInputAnchorDirective is responsible for opening this overlay panel.
 */
@Component({
    selector: 'pdf-checkbox-config-panel',
    templateUrl: './pdf-checkbox-config-panel.component.html',
    styleUrls: ['./pdf-checkbox-config-panel.component.scss'],
    animations: [fadeInAndSlideAnimation()],
})
export class PdfCheckboxConfigPanelComponent {
    constructor(
        @Inject(CHECKBOX_ELEMENT_TOKEN) public checkboxElement: CheckboxElement,
        @Inject(CHECKBOX_ELEMENT_CHANGE_SUBJECT_TOKEN) private checkboxConfigChangeSubject: Subject<CheckboxElement>,
        @Inject(REQUIRED_FIELD_TOKEN) public isRequiredField: boolean,
        @Inject(REQUIRED_FIELD_CHANGE_SUBJECT_TOKEN) private requiredFieldChangeSubject: Subject<boolean>,
        @Inject(PANEL_CLOSE_SUBJECT_TOKEN) private panelCloseSubject: Subject<void>,
        private overlayRef: OverlayRef,
    ) {}

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.closeOnBackdropClick();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitConfigChange() {
        this.checkboxConfigChangeSubject.next(this.checkboxElement);
    }

    public emitRequiredChange() {
        this.requiredFieldChangeSubject.next(this.isRequiredField);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls & Animation
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public closePanel(): void {
        this.overlayRef.detach();

        // Notify the anchor that the panel has been closed.
        this.panelCloseSubject.next();
        this.panelCloseSubject.complete();

        // Unsubscribe subscribers after the dialog has been closed.
        this.checkboxConfigChangeSubject.complete();
        this.requiredFieldChangeSubject.complete();
    }

    /**
     * Close dialog on backdrop click. Don't close if notes are still being edited.
     */
    public closeOnBackdropClick(): void {
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                event.stopImmediatePropagation();
                this.closePanel();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls & Animation
    /////////////////////////////////////////////////////////////////////////////*/
}

//*****************************************************************************
//  Inputs
//****************************************************************************/
export const CHECKBOX_ELEMENT_TOKEN = new InjectionToken<CheckboxElement[]>('CHECKBOX_ELEMENT_TOKEN');
// Initial state of the required checkbox.
export const REQUIRED_FIELD_TOKEN = new InjectionToken<boolean>('REQUIRED_FIELD_TOKEN');
/////////////////////////////////////////////////////////////////////////////*/
//  END Inputs
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Outputs
//****************************************************************************/
// Requirements must be kept in a separate array on the signablePdfTemplateConfig instead of the checkbox itself
// because two checkboxes can make up a pair (yes and no), both being required at the same time.
export const CHECKBOX_ELEMENT_IS_REQUIRED_CHANGE_SUBJECT_TOKEN = new InjectionToken<Subject<CheckboxElement[]>>(
    'CHECKBOX_ELEMENT_IS_REQUIRED_CHANGE_SUBJECT_TOKEN',
);
export const CHECKBOX_ELEMENT_CHANGE_SUBJECT_TOKEN = new InjectionToken<Subject<CheckboxElement[]>>(
    'CHECKBOX_ELEMENT_CHANGE_SUBJECT_TOKEN',
);
// Emits every time the required checkbox is changed.
export const REQUIRED_FIELD_CHANGE_SUBJECT_TOKEN = new InjectionToken<Subject<boolean>>(
    'REQUIRED_FIELD_CHANGE_SUBJECT_TOKEN',
);
/////////////////////////////////////////////////////////////////////////////*/
//  END Outputs
/////////////////////////////////////////////////////////////////////////////*/

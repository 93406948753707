/**
 * Whether a team is marked as Qapter-iXpert.
 * - It pays Audatex, not autoiXpert for the software. AutoiXpert charges Audatex in return.
 * - The UI of Qapter-iXpert is orange, not blue like autoiXpert.
 */
import { Team } from '@autoixpert/models/teams/team';

export function isQapterixpertTeam(team: Team): boolean {
    if (!team) return;

    return team.audatexFeatures?.qapterixpertLayout;
}

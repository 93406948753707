<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container" [ngClass]="{ 'full-width': selectedFileNamePattern }">
        <!--============================================-->
        <!-- Left Column - File Pattern List -->
        <!--============================================-->
        <div id="file-pattern-list-column">
            <!--============================================-->
            <!-- Document Selector -->
            <!--============================================-->
            <!--********** Trigger **********-->
            <div
                id="document-selector"
                [matMenuTriggerFor]="documentSelectorMenu"
                matTooltip="Für welches Dokument möchtest du den Dateinamen festlegen?">
                <mat-icon id="document-icon">description</mat-icon>
                <span id="document-title" class="text-overflow-ellipsis">
                    {{
                        translateDocumentType({
                            documentType: selectedDocumentType,
                            documentOrderConfigId: selectedDocumentOrderConfigId,
                            shortVersion: true,
                        })
                    }}
                </span>
                <mat-icon id="dropdown-arrow">arrow_drop_down</mat-icon>
            </div>

            <!--********** Menu **********-->
            <mat-menu #documentSelectorMenu>
                <div class="document-selector-menu">
                    <!--********** Combined Files **********-->
                    <mat-optgroup label="Gesamt-PDFs">
                        <!--********** With Report **********-->
                        <mat-option
                            class="compact-option"
                            (click)="selectDocumentType('fullDocumentWithReport')"
                            matTooltip="Gesamt-PDF, wenn das Gutachten-Dokument selbst auch aktiv ist.">
                            {{ translateDocumentType({ documentType: 'fullDocumentWithReport', shortVersion: false }) }}
                        </mat-option>

                        <!--********** Without Report **********-->
                        <mat-option
                            class="compact-option"
                            (click)="selectDocumentType('fullDocumentWithoutReport')"
                            matTooltip="Gesamt-PDF, wenn das Gutachten-Dokument selbst nicht aktiv ist.">
                            {{
                                translateDocumentType({
                                    documentType: 'fullDocumentWithoutReport',
                                    shortVersion: false,
                                })
                            }}
                        </mat-option>
                    </mat-optgroup>

                    <!--********** Single Files **********-->
                    <mat-optgroup label="Einzeldokumente">
                        <mat-option
                            class="compact-option"
                            *ngFor="let documentType of selectableDocumentTypes"
                            (click)="selectDocumentType(documentType)"
                            [matTooltip]="translateDocumentType({ documentType: documentType, shortVersion: false })">
                            {{ translateDocumentType({ documentType: documentType, shortVersion: false }) }}
                        </mat-option>

                        <mat-option
                            class="compact-option"
                            *ngFor="let customDocument of selectableCustomDocuments"
                            (click)="selectDocumentType($any(customDocument.type), customDocument._id)"
                            [matTooltip]="
                                translateDocumentType({
                                    documentType: $any(customDocument.type),
                                    documentOrderConfigId: customDocument._id,
                                    shortVersion: false,
                                })
                            ">
                            {{
                                translateDocumentType({
                                    documentType: $any(customDocument.type),
                                    documentOrderConfigId: customDocument._id,
                                    shortVersion: false,
                                })
                            }}
                        </mat-option>
                    </mat-optgroup>
                </div>
            </mat-menu>
            <!--============================================-->
            <!-- END Document Selector -->
            <!--============================================-->

            <!--============================================-->
            <!-- List -->
            <!--============================================-->
            <div id="file-pattern-list" class="rounded-record-list">
                <!--********** Item **********-->
                <div
                    class="file-pattern-list-item rounded-record-list-item"
                    *ngFor="let fileNamePattern of fileNamePatterns"
                    (click)="selectFileNamePattern(fileNamePattern)"
                    [ngClass]="{ selected: fileNamePattern === selectedFileNamePattern }">
                    <!--********** Title & Documents **********-->
                    <div class="file-pattern-title-and-documents-container">
                        <!--********** Title **********-->
                        <div class="file-pattern-title">{{ fileNamePattern.title }}</div>

                        <!--********** Included Documents **********-->
                        <div class="included-documents-container">
                            <selected-report-types
                                [(selectedReportTypes)]="fileNamePattern.reportTypes"
                                [disabled]="true"></selected-report-types>
                        </div>
                    </div>

                    <!--********** Submenu **********-->
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="fileNamePatternSubmenu">more_vert</mat-icon>
                    <mat-menu #fileNamePatternSubmenu>
                        <!--********** Copy **********-->
                        <a mat-menu-item class="menu-item-with-icon" (click)="copyFileNamePattern(fileNamePattern)">
                            <mat-icon>content_copy</mat-icon>
                            Kopieren
                        </a>

                        <!--********** Delete **********-->
                        <a mat-menu-item class="menu-item-with-icon" (click)="deleteFileNamePattern(fileNamePattern)">
                            <mat-icon>delete</mat-icon>
                            Löschen
                        </a>
                    </mat-menu>
                </div>
            </div>
            <!--============================================-->
            <!-- END List -->
            <!--============================================-->

            <!--********** New Item Button **********-->
            <div class="dashed-button" (click)="createFileNamePattern()">+ Neues Muster</div>
        </div>
        <!--============================================-->
        <!-- END Left Column - File Pattern List -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column - Edit Area -->
        <!--============================================-->
        <div id="editor-column" *ngIf="selectedFileNamePattern" @slideInAndOutHorizontally>
            <h2 id="headline">Muster für Dateinamen</h2>
            <div id="explainer" class="label text-align-center">
                Mit welchem Dateinamen soll das Dokument "{{
                    translateDocumentType({
                        documentType: selectedDocumentType,
                        documentOrderConfigId: selectedDocumentOrderConfigId,
                        shortVersion: true,
                    })
                }}" heruntergeladen werden?
            </div>
            <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>

            <!--********** Pattern Title **********-->
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Titel"
                    [(ngModel)]="selectedFileNamePattern.title"
                    (change)="saveFileNamePattern()"
                    matTooltip="Für dich zur internen Kennzeichnung.{{
                        '\n\n'
                    }}Gib z. B. an, für welche Vorgangstypen ein Muster gilt (z. B. Schadengutachten oder Wertgutachten)." />
            </mat-form-field>

            <!--============================================-->
            <!-- Report Types -->
            <!--============================================-->
            <h4 class="semibold">
                Für welche Vorgangstypen gilt diese Einstellung?
                <span
                    class="help-indicator"
                    matTooltip="Du kannst z. B. abweichende Bezeichnungen für die Gesamt-PDFs eines Haftpflichtschadens und einer Bewertung definieren.">
                    ?
                </span>
            </h4>

            <selected-report-types
                [(selectedReportTypes)]="selectedFileNamePattern.reportTypes"
                (selectedReportTypesChange)="saveFileNamePattern()"
                (dialogOpened)="reportTypeSelectionOpen = true"
                (dialogClosed)="reportTypeSelectionOpen = false"></selected-report-types>

            <!--============================================-->
            <!-- Duplicate Patterns -->
            <!--============================================-->
            <!-- If other patterns target the same report type, the server won't know which one to choose. Display a warning. -->
            <div
                id="duplicate-patterns-warning-note"
                class="info-note warning-note"
                *ngIf="getDuplicatePatterns().length">
                <mat-icon>warning</mat-icon>
                <div>
                    <div class="margin-bottom-10">
                        Andere Muster definieren einen Titel für die gleichen Vorgangstypen wie dieses Muster.
                    </div>
                    <div *ngFor="let duplicatePattern of getDuplicatePatterns()">
                        <span class="italic">{{ duplicatePattern.fileNamePatternTitle }}</span>
                        definiert ein Muster für:
                        <ul>
                            <li *ngFor="let reportType of duplicatePattern.reportTypes">
                                {{ translateReportType(reportType) }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Duplicate Patterns -->
            <!--============================================-->

            <!--============================================-->
            <!-- END Report Types -->
            <!--============================================-->

            <!--============================================-->
            <!-- Pattern -->
            <!--============================================-->
            <section id="pattern-section">
                <h3>Dateiname</h3>
                <!--********** Pattern Input **********-->
                <div id="pattern-input-container">
                    <placeholder-autocomplete-input
                        class="mat-block"
                        [(text)]="selectedFileNamePattern.pattern"
                        (change)="saveFileNamePattern()"
                        placeholder="Muster"
                        matTooltip='Nach diesem Muster werden Dateinamen bestimmt. Beispiel: "Gutachten - {Anspruchsteller.Nachname}"'></placeholder-autocomplete-input>

                    <!--********** Pattern Templates **********-->
                    <mat-icon
                        class="toolbar-icon medium-icon"
                        [matMenuTriggerFor]="patternTemplatesSubmenu"
                        matTooltip="Vorlagen einfügen">
                        add
                    </mat-icon>
                    <mat-menu #patternTemplatesSubmenu>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="insertPlaceholder('Gutachten.Aktenzeichen'); saveFileNamePattern()">
                            <mat-icon>add</mat-icon>
                            Aktenzeichen
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="insertPlaceholder('Anspruchsteller.Nachname'); saveFileNamePattern()">
                            <mat-icon>add</mat-icon>
                            Anspruchsteller-Nachname
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="insertPlaceholder('Anschreiben.Empfänger.Typ'); saveFileNamePattern()"
                            matTooltip="z. B. Anspruchsteller oder Versicherung">
                            <mat-icon>add</mat-icon>
                            Empfängertyp
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="insertPlaceholder('Anspruchsteller.Kennzeichen'); saveFileNamePattern()">
                            <mat-icon>add</mat-icon>
                            Kennzeichen
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="insertPlaceholder('Unfall.Datum'); saveFileNamePattern()">
                            <mat-icon>add</mat-icon>
                            Unfalldatum
                        </a>
                        <a
                            mat-menu-item
                            disabled
                            class="label"
                            matTooltip="Positioniere den Cursor in dem Feld 'Muster' links und füge eine geschweifte Klammer '{' ein. Dann werden dir weitere Platzhalter vorgeschlagen.">
                            Für weitere Platzhalter &lcub; eingeben
                        </a>
                    </mat-menu>
                </div>

                <!--********** Pattern Preview **********-->
                <div id="pattern-preview-container">
                    <div class="label">Dateiname beim Download</div>
                    <div id="pattern-preview">{{ getFilenameForPattern(selectedFileNamePattern.pattern) }}.pdf</div>
                </div>
            </section>
            <!--============================================-->
            <!-- END Pattern -->
            <!--============================================-->

            <div class="dialog-buttons-container">
                <button (click)="closeDialog()">Bearbeitung beenden</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Right Column - Edit Area -->
        <!--============================================-->

        <!--============================================-->
        <!-- Loading Overlay -->
        <!--============================================-->
        <div id="loading-overlay" *ngIf="fileNamePatternsLoading" @fadeInAndOut>
            <img class="margin-bottom-15" src="/assets/images/icons/loading-indicator.svg" alt="" />
            Lade...
        </div>
        <!--============================================-->
        <!-- END Loading Overlay -->
        <!--============================================-->
    </div>
</div>

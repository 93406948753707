import { Moment } from 'moment';
import moment from 'moment/moment';

const relativeDateShorthandRegEx: RegExp = /^([+-]?)(\d+)([twm])$/;
const absoluteDateShorthandRegEx: RegExp = /^([vghm]|vorgestern|gestern|heute|morgen)$/;

/**
 * aX allows using shorthands like:
 * Relative shorthands
 * - 2t -> two days
 * - 1w -> one week
 * - 1m -> one month
 *
 * Absolute shorthands
 * - v -> vorgestern (the day before yesterday)
 * - g -> gestern (yesterday)
 * - h -> today (heute)
 * - m -> morgen (tomorrow)
 *
 * This methods parses such date inputs and returns a moment object.
 */
export function parseDateShorthands(dateString: string, parseFormats: string[] = []): Moment {
    if (!dateString) {
        return null;
    }
    dateString = dateString.toLowerCase();

    let date: Moment;

    // Filter shortcuts for the day before yesterday, yesterday and today (vorgestern = v; gestern = g; heute = h)
    // If the rawDate is only one character long, check if it is a shortcut. If it's longer, try to parse the date for our supported formats.
    if (isAbsoluteDateShorthand(dateString)) {
        let daysBeforeToday: number;
        switch (dateString.toLowerCase()) {
            case 'v':
            case 'vorgester':
                daysBeforeToday = 2;
                break;
            case 'g':
            case 'gestern':
                daysBeforeToday = 1;
                break;
            case 'h':
            case 'heute':
                daysBeforeToday = 0;
                break;
            case 'm':
            case 'morgen':
                daysBeforeToday = -1;
                break;
        }
        date = moment().subtract(daysBeforeToday, 'days');
    }
    // Relative Dates - ex.: +2d, -3w
    else if (isRelativeDateShorthand(dateString)) {
        const match = dateString.match(relativeDateShorthandRegEx);
        const operator = match[1] === '-' ? -1 : 1;
        const amount = +match[2];

        // determine unit
        let unit;
        switch (match[3]) {
            case 't':
                unit = 'days';
                break;
            case 'w':
                unit = 'weeks';
                break;
            case 'm':
                unit = 'months';
                break;
            default:
                throw new Error(`UNKNOWN_RELATIVE_DATE_UNIT: ${match[3]}`);
        }

        date = moment().add(operator * amount, unit);
    }
    // longer date
    else {
        dateString = convertMonthNameToMomentFormat(dateString);
        // Parse the value for the given parseFormats and for the ISO format that is stored in the database.
        date = moment(dateString, [...parseFormats, moment.ISO_8601]);
    }

    if (date.isValid()) {
        // Reset the time values, so that only the date value is returned. This is to ensure data consistency; we do not want to save (random) data that the user did not enter himself.
        return date.hours(0).minutes(0).seconds(0);
    } else {
        return date;
    }
}

/**
 * aX allows using relative shorthands like:
 * - 2t -> two days
 * - 1w -> one week
 * - 1m -> one month
 *
 * This methods checks if the input is such a shorthand.
 */
export function isRelativeDateShorthand(dateString: string): boolean {
    return relativeDateShorthandRegEx.test(dateString);
}

/**
 * aX allows using absolute shorthands like:
 * - v -> vorgestern (the day before yesterday)
 * - g -> gestern (yesterday)
 * - h -> today (heute)
 * - m -> morgen (tomorrow)
 *
 * This methods checks if the input is such a shorthand.
 */
export function isAbsoluteDateShorthand(dateString: string): boolean {
    return absoluteDateShorthandRegEx.test(dateString);
}

export function isDateShorthand(dateString: string): boolean {
    return isRelativeDateShorthand(dateString) || isAbsoluteDateShorthand(dateString);
}

function convertMonthNameToMomentFormat(value: string): string {
    /**
     * If input contains three or four letter month identifiers (Jan, Feb, Sept, ...),
     * replace them with the German terms that momentJS recognizes.
     *
     * The German ä must be included for Mär (=> März)
     */
    const monthShorthandMatches = value.match(/^([\wä]{3,4})\s/);

    if (monthShorthandMatches) {
        // Take the first capture group. That is the month without trailing space.
        const monthShorthand = monthShorthandMatches[1];

        // Map the monthShorthand to the moment-conform abbreviations
        let monthShorthandForMomentjs: string;
        switch (monthShorthand) {
            case 'Jan':
            case 'jan':
                monthShorthandForMomentjs = 'Jan.';
                break;
            case 'Feb':
            case 'feb':
                monthShorthandForMomentjs = 'Feb.';
                break;
            case 'Mär':
            case 'mär':
            case 'Mrz':
            case 'mrz':
            case 'März':
            case 'märz':
                monthShorthandForMomentjs = 'März';
                break;
            case 'Apr':
            case 'apr':
                monthShorthandForMomentjs = 'Apr.';
                break;
            case 'Mai':
            case 'mai':
                monthShorthandForMomentjs = 'Mai';
                break;
            case 'Jun':
            case 'jun':
            case 'Juni':
            case 'juni':
                monthShorthandForMomentjs = 'Juni';
                break;
            case 'Jul':
            case 'jul':
            case 'Juli':
            case 'juli':
                monthShorthandForMomentjs = 'Juli';
                break;
            case 'Aug':
            case 'aug':
                monthShorthandForMomentjs = 'Aug.';
                break;
            case 'Sep':
            case 'sep':
            case 'Sept':
            case 'sept':
                monthShorthandForMomentjs = 'Sep.';
                break;
            case 'Okt':
            case 'okt':
                monthShorthandForMomentjs = 'Okt.';
                break;
            case 'Nov':
            case 'nov':
                monthShorthandForMomentjs = 'Nov.';
                break;
            case 'Dez':
            case 'dez':
                monthShorthandForMomentjs = 'Dez.';
                break;
        }
        return value.replace(monthShorthand, monthShorthandForMomentjs);
    }
    // If there is nothing to replace -> return initial value
    return value;
}

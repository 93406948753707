'use strict';

import { Component } from '@angular/core';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';

@Component({
    selector: 'ax-login',
    styleUrls: ['login.scss'],
    templateUrl: 'login.component.html',
})
export class LoginComponent {
    public isQapterixpert(): boolean {
        return isQapterixpert();
    }
}

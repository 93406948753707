export function utf16ToWindows1252Bytes(input: string): Uint8Array {
    const output: Uint8Array = new Uint8Array(input.length);

    // Performance improvement by preventing property access on every iteration.
    const inputLength = input.length;
    for (let i = 0; i < inputLength; i++) {
        const unicodeCodePoint = input[i].codePointAt(0);
        // A character that's present in windows-1252. Add its code point value.
        if (unicodeCodePoint < 256) {
            output[i] = unicodeCodePoint;
        } else if (input[i] === '€') {
            // The € symbol has the codePointAt value of 8364 which does not correspond to the 128 for Windows-1252
            output[i] = 128;
        } else {
            // This is an invalid character that is not contained in windows-1252, so insert a question mark instead.
            output[i] = 63;
        }
    }

    return output;
}

<!--============================================-->
<!-- Organization -->
<!--============================================-->
<!-- In case the organization is an insurance, we display their logo. -->
<div class="organization-and-logo-wrapper">
    <!--********** Organization Form Field **********-->
    <div class="organization-form-field-wrapper">
        <mat-form-field class="mat-block" *ngIf="showOrganization">
            <!--
            autocomplete="off" is ignored by Chrome. It still fills our form with addresses saved in the browser.
            This overlays our own autocomplete.
            Solution: Provide a random string so the browser doesn't know what to autofill.
             -->
            <input
                matInput
                placeholder="{{ organizationPlaceholder }}"
                axTrim
                [(ngModel)]="contactPerson.organization"
                (change)="dispatchNameChangeEvent(); dispatchContactPersonChangeEvent()"
                [disabled]="disabled"
                [matAutocomplete]="organizationAutocomplete.autocomplete"
                autocomplete="do-not-fill-with-native-autocomplete-random-string" />
            <div class="display-flex" matSuffix>
                <!-- Notes may be easily overlooked, therefore we display an extra icon here. -->
                <mat-icon
                    class="input-toolbar-icon notes-indicator-icon"
                    *ngIf="contactPerson.notes && contactPerson.notes.trim()"
                    matTooltip="Notiz:{{ '\n\n' }}{{ contactPerson.notes | htmlToPlaintext }}">
                    notes
                </mat-icon>

                <mat-icon
                    class="input-toolbar-icon delete-organization-icon"
                    (click)="clearContactPerson(); dispatchContactPersonChangeEvent()"
                    *ngIf="
                        !disabled &&
                        showContactReset &&
                        (contactPerson.organization || contactPerson.firstName || contactPerson.lastName)
                    "
                    matTooltip="{{
                        contactPerson.organizationType === 'garage'
                            ? 'Werkstatt und Kostensätze'
                            : translateOrganizationType(this.contactPerson)
                    }} entfernen">
                    delete
                </mat-icon>
            </div>
        </mat-form-field>
    </div>

    <!--********** Organization Logo (currently insurances only) **********-->
    <img
        class="organization-logo"
        *ngIf="organizationPlaceholder === 'Versicherung' && insuranceLogoExists(contactPerson.organization)"
        src="/assets/images/logos/insurances/{{ getIconNameForInsurance(contactPerson.organization) }}"
        @slideInAndOutHorizontally />
</div>
<address-autocompletion
    #organizationAutocomplete
    [disabled]="disabled"
    [externalOrganizationAutocompletionEnabled]="
        !(organizationTypes.includes('insurance') && organizationTypes.length === 1)
    "
    [contactAutocompletionEnabled]="true"
    [showSuggestionsIfEmpty]="showSuggestionsIfEmpty"
    [additionalContactPeopleForAutocomplete]="additionalContactPeopleForAutocomplete"
    [organizationTypeFilter]="organizationTypes"
    [showFirstAndLastName]="showPerson"
    [value]="contactPerson.organization"
    [addressContext]="contactPerson"
    [showSearchMatches]="showSearchMatches"
    (contactPersonSelected)="insertContactPersonInReport($event)"
    (addressSelected)="insertAddressAutocompletionInReport($event)"></address-autocompletion>
<!--============================================-->
<!-- END Organization -->
<!--============================================-->

<!--============================================-->
<!-- Salutation, First and Last Name -->
<!--============================================-->
<div class="name-group" *ngIf="showPerson">
    <!--********** Salutation **********-->
    <mat-form-field class="salutation">
        <input
            matInput
            placeholder="Anrede"
            axTrim
            [(ngModel)]="contactPerson.salutation"
            [matAutocomplete]="salutationOptions"
            (input)="filterSalutations(contactPerson.salutation)"
            (change)="dispatchContactPersonChangeEvent()"
            [disabled]="disabled"
            autocomplete="do-not-fill-with-native-autocomplete-random-string" />
    </mat-form-field>
    <mat-autocomplete #salutationOptions="matAutocomplete" (optionSelected)="dispatchContactPersonChangeEvent()">
        <mat-option
            *ngFor="let salutation of filteredSalutations"
            [value]="salutation"
            (mousedown)="$event.preventDefault()">
            {{ salutation }}
        </mat-option>
    </mat-autocomplete>

    <!--********** First Name **********-->
    <mat-form-field class="first-name">
        <input
            matInput
            placeholder="Vorname"
            axTrim
            [(ngModel)]="contactPerson.firstName"
            (ngModelChange)="dispatchNameChangeEvent()"
            (change)="dispatchContactPersonChangeEvent()"
            [disabled]="disabled"
            [matAutocomplete]="firstNameAutocomplete.autocomplete"
            autocomplete="do-not-fill-with-native-autocomplete-random-string" />
    </mat-form-field>
    <address-autocompletion
        #firstNameAutocomplete
        [disabled]="disabled"
        [contactAutocompletionEnabled]="true"
        [additionalContactPeopleForAutocomplete]="additionalContactPeopleForAutocomplete"
        [organizationTypeFilter]="organizationTypes"
        (contactPersonSelected)="insertContactPersonInReport($event)"
        (addressSelected)="insertAddressAutocompletionInReport($event)"
        [value]="contactPerson.firstName"
        [addressContext]="contactPerson"></address-autocompletion>

    <!--********** Last Name **********-->
    <mat-form-field class="last-name">
        <input
            matInput
            placeholder="Nachname"
            axTrim
            [(ngModel)]="contactPerson.lastName"
            (ngModelChange)="dispatchNameChangeEvent()"
            (change)="dispatchContactPersonChangeEvent()"
            [disabled]="disabled"
            [matAutocomplete]="lastNameAutocomplete.autocomplete"
            autocomplete="do-not-fill-with-native-autocomplete-random-string" />
    </mat-form-field>
    <address-autocompletion
        #lastNameAutocomplete
        [disabled]="disabled"
        [contactAutocompletionEnabled]="true"
        [additionalContactPeopleForAutocomplete]="additionalContactPeopleForAutocomplete"
        [organizationTypeFilter]="organizationTypes"
        [value]="contactPerson.lastName"
        [addressContext]="contactPerson"
        (contactPersonSelected)="insertContactPersonInReport($event)"
        (addressSelected)="insertAddressAutocompletionInReport($event)"></address-autocompletion>
</div>
<!--============================================-->
<!-- END Salutation, First and Last Name -->
<!--============================================-->

<!--============================================-->
<!-- Address -->
<!--============================================-->
<ng-container *ngIf="showAddress">
    <mat-form-field class="mat-block">
        <input
            matInput
            placeholder="Straße & Hausnr. oder Postfach"
            axTrim
            #streetInput
            [(ngModel)]="contactPerson.streetAndHouseNumberOrLockbox"
            (change)="dispatchContactPersonChangeEvent()"
            [matAutocomplete]="streetAutocomplete.autocomplete"
            autocomplete="do-not-fill-with-native-autocomplete-random-string"
            [disabled]="disabled" />
    </mat-form-field>

    <address-autocompletion
        #streetAutocomplete
        [disabled]="disabled"
        [externalAddressAutocompletionEnabled]="true"
        (addressSelected)="insertAddressAutocompletionInReport($event)"
        [value]="contactPerson.streetAndHouseNumberOrLockbox"
        [addressContext]="contactPerson"></address-autocompletion>

    <div class="place-group" (change)="dispatchContactPersonChangeEvent()">
        <mat-form-field class="zipCode">
            <!-- Country codes are used for residual value exchanges if the car had an accident abroad. -->
            <input
                matInput
                placeholder="PLZ"
                axTrim
                name="zipCode"
                zip-city-input
                [(ngModel)]="contactPerson.zip"
                [disabled]="disabled"
                [street]="contactPerson.streetAndHouseNumberOrLockbox"
                [city]="contactPerson.city"
                (cityChange)="insertCityIntoModel($event)"
                autocomplete="do-not-fill-with-native-autocomplete-random-string"
                maxlength="8"
                matTooltip="Postleitzahl mit 5 Ziffern.{{
                    '\n\n'
                }}Ausländische PLZ: Stelle den zweistelligen Ländercode voran, z. B. PL-12345" />
        </mat-form-field>
        <mat-form-field class="place">
            <input
                matInput
                placeholder="Ort"
                axTrim
                name="place"
                #cityInput
                [(ngModel)]="contactPerson.city"
                (change)="dispatchContactPersonChangeEvent()"
                [matAutocomplete]="cityAutocomplete.autocomplete"
                autocomplete="do-not-fill-with-native-autocomplete-random-string"
                [disabled]="disabled" />
        </mat-form-field>
        <address-autocompletion
            #cityAutocomplete
            [disabled]="disabled"
            [contactAutocompletionEnabled]="true"
            [additionalContactPeopleForAutocomplete]="additionalContactPeopleForAutocomplete"
            [organizationTypeFilter]="organizationTypes"
            (addressSelected)="insertAddressAutocompletionInReport($event)"
            (contactPersonSelected)="insertContactPersonInReport($event)"
            [value]="contactPerson.city"
            [addressContext]="contactPerson"></address-autocompletion>
    </div>
</ng-container>
<!--============================================-->
<!-- END Address -->
<!--============================================-->

<!--============================================-->
<!-- Birthday & ADELTA.FINANZ Liquidity Check -->
<!--============================================-->
<div
    class="birthdate-and-liquidity-check-container mat-block"
    *ngIf="showBirthdate && user.adeltaFinanzUser.username && user.adeltaFinanzUser.customerNumber">
    <date-input
        placeholder="Geburtstag"
        [(date)]="contactPerson.birthdate"
        (dateChange)="dispatchContactPersonChangeEvent()"
        startView="multi-year"
        [startAt]="birthdayYear"
        [disabled]="disabled"
        [matTooltip]="
            showLiquidityCheck ? 'Optional. Wird für den ADELTA-Liquiditätsbutton benötigt.' : 'Optional'
        "></date-input>
    <mat-icon
        class="liquidity-check-icon"
        *ngIf="showLiquidityCheck && !adeltafinanzRequestPending"
        [ngClass]="{
            success: contactPerson.financiallyStable,
            error: contactPerson.financiallyStable === false,
            warn: adeltafinanzAddressIncorrect,
        }"
        (click)="checkLiquidity()"
        [matTooltip]="getLiquidityIconTooltip()">
        equalizer
    </mat-icon>
    <img src="/assets/images/icons/loading-indicator.svg" *ngIf="adeltafinanzRequestPending" />
</div>
<!--============================================-->
<!-- END Birthday & ADELTA.FINANZ Liquidity Check -->
<!--============================================-->

<!--============================================-->
<!-- Communication Details -->
<!--============================================-->
<ng-container *ngIf="showPhoneNumber">
    <div
        class="phone-numbers-container"
        [ngClass]="{ 'second-phone-shown': secondPhoneNumberShown || contactPerson.phone2 }">
        <mat-form-field class="phone mat-block">
            <input
                matInput
                type="tel"
                placeholder="Telefon"
                axTrim
                [(ngModel)]="contactPerson.phone"
                (change)="dispatchContactPersonChangeEvent()"
                autocomplete="do-not-fill-with-native-autocomplete-random-string"
                [disabled]="disabled" />
            <mat-icon
                matSuffix
                class="input-toolbar-icon phone-number-suffix"
                *ngIf="!disabled"
                matTooltip="Telefonnummer hinzufügen"
                (click)="showSecondPhoneNumber()">
                add
            </mat-icon>
        </mat-form-field>
        <mat-form-field class="phone2 mat-block" *ngIf="secondPhoneNumberShown || contactPerson.phone2">
            <input
                matInput
                type="tel"
                placeholder="Telefon 2"
                axTrim
                [(ngModel)]="contactPerson.phone2"
                (change)="dispatchContactPersonChangeEvent()"
                autocomplete="do-not-fill-with-native-autocomplete-random-string"
                [disabled]="disabled" />
            <mat-icon
                matSuffix
                class="input-toolbar-icon phone-number-suffix"
                *ngIf="!disabled"
                matTooltip="Zweite Telefonnummer entfernen"
                (click)="removeSecondPhoneNumber()">
                close
            </mat-icon>
        </mat-form-field>
    </div>
</ng-container>
<mat-form-field class="mat-block" *ngIf="showEmail">
    <input
        #emailInput="ngModel"
        matInput
        placeholder="E-Mail"
        axTrim
        emailAutocomplete
        [(ngModel)]="contactPerson.email"
        (change)="dispatchContactPersonChangeEvent()"
        autocomplete="do-not-fill-with-native-autocomplete-random-string"
        [disabled]="disabled"
        matTooltip="Mehrere E-Mail-Adressen können mit Semikolon (;) getrennt werden, z. B. a@z.de;b@z.de"
        [pattern]="EMAIL_VALIDATION_REGEX" />
    <mat-error *ngIf="emailInput.invalid">E-Mail-Adresse ist ungültig.</mat-error>
</mat-form-field>
<div class="website-and-dat-customer-number-container" *ngIf="showWebsite || showDatCustomerNumber">
    <mat-form-field class="mat-block" *ngIf="showWebsite">
        <input
            matInput
            type="text"
            placeholder="Website"
            axTrim
            [(ngModel)]="contactPerson.website"
            (change)="dispatchContactPersonChangeEvent()"
            [disabled]="disabled" />
    </mat-form-field>
    <mat-form-field class="dat-customer-number-form-field mat-block" *ngIf="showDatCustomerNumber">
        <input
            matInput
            type="text"
            placeholder="DAT-Kundennummer"
            axTrim
            [(ngModel)]="contactPerson.datCustomerNumber"
            (change)="dispatchContactPersonChangeEvent()"
            [disabled]="disabled"
            maxlength="7"
            [matTooltip]="
                'An diese DAT-Kundennummer kannst du die Kalkulation über DAT myclaim versenden.\n\nBeispiel-DAT-Kundennummer: 1329195'
            " />
    </mat-form-field>
</div>
<mat-form-field class="mat-block" *ngIf="showNotes || contactPerson.notes">
    <mat-quill
        placeholder="Notizen"
        [(ngModel)]="contactPerson.notes"
        (change)="dispatchContactPersonChangeEvent()"
        [disabled]="disabled"
        [maxRows]="5"
        #notesInput></mat-quill>
</mat-form-field>
<!--============================================-->
<!-- END Communication Details -->
<!--============================================-->

<!--============================================-->
<!-- IBAN -->
<!--============================================-->

<mat-form-field class="mat-block" *ngIf="showIban">
    <input
        matInput
        placeholder="IBAN"
        iban-input
        [(ngModel)]="contactPerson.iban"
        (input)="isIbanInvalid = false"
        (ibanChange)="handleIbanChangeEvent($event); dispatchContactPersonChangeEvent()"
        [disabled]="disabled" />
    <mat-hint *ngIf="isIbanInvalid" class="error">IBAN ungültig</mat-hint>
</mat-form-field>
<!--============================================-->
<!-- END IBAN -->
<!--============================================-->

<!--***********************************************
** VAT
************************************************-->
<div
    class="checkbox-group"
    *ngIf="showCollectiveInvoice"
    [ngClass]="{ 'parent-of-an-only-child': centerVatDeductionCheckbox }">
    <mat-checkbox
        id="claimant-receives-collective-invoice"
        [(ngModel)]="contactPerson.receivesCollectiveInvoice"
        [disabled]="disabled"
        (change)="dispatchContactPersonChangeEvent()">
        Erhält Sammelrechnung
    </mat-checkbox>
</div>
<div class="claimant-vat-data-container" *ngIf="showVatFields">
    <div class="checkbox-group" [ngClass]="{ 'parent-of-an-only-child': centerVatDeductionCheckbox }">
        <tri-state-checkbox
            id="claimant-may-deduct-taxes-checkbox"
            [(ngModel)]="contactPerson.mayDeductTaxes"
            [disabled]="disabled"
            (change)="dispatchContactPersonChangeEvent(); focusVatIdInput($event)">
            Vorsteuerabzugsberechtigt
        </tri-state-checkbox>
    </div>
    <mat-form-field class="mat-block" *ngIf="contactPerson.mayDeductTaxes">
        <input
            matInput
            placeholder="Umsatzsteuer-ID"
            axTrim
            [(ngModel)]="contactPerson.vatId"
            (change)="dispatchContactPersonChangeEvent()"
            [disabled]="disabled"
            #vatIdInput
            matTooltip="bestehend aus Länderkennzeichen und 9 Ziffern, z. B. DE123456789" />
    </mat-form-field>
</div>
<!--***********************************************
** END VAT
************************************************-->

<!--============================================-->
<!-- Debtor Number -->
<!--============================================-->
<mat-form-field class="mat-block" *ngIf="showDebtorNumber">
    <input
        matInput
        placeholder="Debitorennummer"
        axTrim
        [(ngModel)]="contactPerson.debtorNumber"
        (change)="dispatchContactPersonChangeEvent()"
        matTooltip="Du kannst hier die Nummer des Debitorenkontos eintragen.{{
            '\n\n'
        }}Beim DATEV-Export wird diese Nummer als Kundenkontonummer verwendet, sodass dein Beleg automatisch auf das korrekte Kundenkonto verbucht wird.
        Beachte: Gib hier ein Personenkonto ein. Personenkonten haben fünf bzw. sieben Ziffern (eine Ziffer mehr als Sachkonten)."
        [disabled]="disabled" />
</mat-form-field>
<!--============================================-->
<!-- END Debtor Number -->
<!--============================================-->

<!--============================================-->
<!-- Insurance Company Number (VU-Number) -->
<!--============================================-->
<mat-form-field class="mat-block" *ngIf="showInsuranceCompanyNumber">
    <input
        matInput
        placeholder="VU-Nummer (GDV-Nummer)"
        axTrim
        [(ngModel)]="contactPerson.insuranceCompanyNumber"
        (change)="dispatchContactPersonChangeEvent()"
        matTooltip="Jede Versicherung hat eine vierstellige Registrierungsnummer bei der BaFin. Diese wird oft als GDV-Nummer bezeichnet und ist notwendig, damit Restwertbörsen ein Inserat direkt mit einer Versicherung abrechnen können."
        [disabled]="disabled" />
</mat-form-field>
<!--============================================-->
<!-- END Insurance Company Number (VU-Number) -->
<!--============================================-->

import { ClaimantSignature } from '@autoixpert/models/signable-documents/claimant-signature';
import { SignableDocument } from '@autoixpert/models/signable-documents/signable-document';

/**
 * SignableDocuments are created without signatures at first, so we must fill them
 * here as soon as we know which template will be used.
 *
 * Documents with multiple signatures (e.g. with placeholder 'ZweiteUnterschriftKunde') need two signatures.
 * Other documents need at least one signature.

 * The slotId is not relevant for the first signature.
 */
export function setupAllClaimantSignaturesOnBuildingBlockDocuments({
    signableDocument,
    buildingBlocks,
}: {
    signableDocument: SignableDocument;
    buildingBlocks: DocumentBuildingBlockWithPlaceholder[];
}): void {
    // All signable documents have at least one signature
    if (!signableDocument.signatures?.length) {
        signableDocument.signatures = [
            new ClaimantSignature({
                documentType: signableDocument.documentType,
                customDocumentOrderConfigId: signableDocument.customDocumentOrderConfigId,
            }),
        ];
    }

    // declarationOfAssignment with included fee table has two signatures
    const secondSignatureRequired = (buildingBlocks ?? []).find(
        (block) => block.placeholder === 'ZweiteUnterschriftKunde',
    );
    const secondSignatureInitialized = signableDocument.signatures.find(
        (signature) => signature.signatureSlotId === '1',
    );
    if (secondSignatureRequired && !secondSignatureInitialized) {
        signableDocument.signatures.push(
            new ClaimantSignature({
                documentType: signableDocument.documentType,
                customDocumentOrderConfigId: signableDocument.customDocumentOrderConfigId,
                signatureSlotId: '1',
            }),
        );
    }
}

interface DocumentBuildingBlockWithPlaceholder {
    placeholder: string;
}

import { Report } from '@autoixpert/models/reports/report';
import { FeePreferencesKey } from '@autoixpert/models/user/preferences/user-preferences';
import { TeamPreferences } from '../../models/teams/team-preferences';

export function determineFeePreferencesKey(
    reportType: Report['type'],
    feeTable: TeamPreferences['defaultFeeTableLiability'],
): FeePreferencesKey {
    if (reportType === 'liability' && feeTable === 'HUK') {
        return 'liabilityHukFees';
    } else {
        return 'defaultFees';
    }
}

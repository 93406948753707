import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MongoQuery } from '@autoixpert/lib/database-query/mongo-query.type';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { Task } from '@autoixpert/models/tasks/task';
import { User } from '@autoixpert/models/user/user';
import { LiveSyncWithInMemoryCacheServiceBase } from '../libraries/database/live-sync-with-in-memory-cache.service-base';
import { get$SearchMongoQueryTasks } from '../libraries/database/search-query-translators/get-$search-mongo-query-tasks';
import { FeathersQuery } from '../types/feathers-query';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FrontendLogService } from './frontend-log.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class TaskService extends LiveSyncWithInMemoryCacheServiceBase<Task> implements OnDestroy {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected feathersSocketioService: FeathersSocketioService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected loggedInUserService: LoggedInUserService,
    ) {
        super({
            serviceName: 'task',
            httpClient,
            networkStatusService,
            feathersSocketioClient: feathersSocketioService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            objectStoreAndIndexMigrations: undefined,
            recordMigrations: [],
            get$SearchMongoQuery: get$SearchMongoQueryTasks,
        });

        this.initialize();
    }

    private user: User;
    private subscriptions: Subscription[] = [];

    /**
     * Keep all ids of open and due tasks of the user.
     * This allows to handle changes in tasks without querying all tasks.
     */
    private currentOpenAndDueTasks$ = new BehaviorSubject<Task['_id'][]>([]);
    public numberOfDueTasks$$ = this.currentOpenAndDueTasks$.asObservable().pipe(
        map((tasks) => tasks.length),
        distinctUntilChanged(),
    );

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    /**
     * Initialize the service to listen to changes in the logged in user and tasks.
     */
    private async initialize() {
        // Update the counter if the user changes
        const userSubscription = this.loggedInUserService.getUser$().subscribe((user) => {
            this.unsubscribeFromChangesInTasks();
            this.user = user;
            if (user) {
                this.getInitialDueTasks();
            }
        });
        this.subscriptions.push(userSubscription);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Tasks
    //****************************************************************************/
    /**
     * Return tasks from the server or indexedDB.
     * Use a pagination token (searchAfterPaginationToken) to get the next page of results when online.
     * Use skip to get the next page of results when offline.
     */
    public async getTasksFromServerOrIndexedDB({
        searchAfterPaginationToken,
        skip,
        limit = 10,
        query,
        searchTerm,
    }: {
        searchAfterPaginationToken?: string;
        skip?: number;
        limit: number;
        query: FeathersQuery;
        searchTerm?: string;
    }): Promise<{
        records: Task[];
        lastPaginationToken?: string;
    }> {
        if (searchTerm && searchTerm.length >= 3) {
            query.$search = searchTerm;
        }

        query.$limit = limit;
        if (searchAfterPaginationToken) {
            query.$searchAfterPaginationToken = searchAfterPaginationToken;
        }
        if (skip) {
            query.$skip = skip;
        }

        return await this.findWithPaginationToken(query);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Load all due tasks of the user when the service is initialized.
     * Then subscribe to changes in tasks to update the counter.
     */
    private async getInitialDueTasks() {
        const query: MongoQuery<Task> = {
            assigneeId: this.user._id,
            dueDate: { $lte: todayIso() },
            isCompleted: false,
            $limit: 100,
        };
        const dueTasks = await this.localDb.findLocal(query);
        const taskIds = dueTasks.map((task) => task._id);
        this.currentOpenAndDueTasks$.next(taskIds);
        this.subscribeToChangesInTasks();
    }

    private externalCreateSubscription: Subscription;
    private externalPatchSubscription: Subscription;
    private createSubscription: Subscription;
    private patchSubscription: Subscription;
    private deleteSubscription: Subscription;

    /**
     * Unsubscribe from changes in tasks, e.g. after the user changes or logs out.
     */
    private unsubscribeFromChangesInTasks() {
        this.externalCreateSubscription?.unsubscribe();
        this.externalPatchSubscription?.unsubscribe();
        this.createSubscription?.unsubscribe();
        this.patchSubscription?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    /**
     * Subscribe to changes in tasks from the current tab, another tab or the server.
     */
    private subscribeToChangesInTasks() {
        // Events from external (websocket)
        // This is necessary to update the counter after login
        this.externalCreateSubscription = this.createdFromExternalServerOrLocalBroadcast$.subscribe(() =>
            this.getInitialDueTasks(),
        );
        this.externalPatchSubscription = this.patchedFromExternalServerOrLocalBroadcast$.subscribe(() =>
            this.getInitialDueTasks(),
        );

        // Events from internal
        this.createSubscription = this.createdInLocalDatabase$.subscribe(this.handleCreatedOrPatchedTask.bind(this));
        this.patchSubscription = this.patchedInLocalDatabase$.subscribe(({ patchedRecord }) => {
            this.handleCreatedOrPatchedTask(patchedRecord);
        });
        this.deleteSubscription = this.deletedInLocalDatabase$.subscribe(this.handleDeletedTask.bind(this));
    }

    /**
     * Update the counter, when a task is created or patched.
     */
    private handleCreatedOrPatchedTask(task: Task) {
        const isNotCompleted = !task.isCompleted;
        const isDue = task.dueDate && !moment(task.dueDate).isAfter(moment(), 'day');
        const isAssignedToUser = task.assigneeId === this.user._id;

        const isRelevant = isNotCompleted && isDue && isAssignedToUser;
        const isAlreadyCounted = this.currentOpenAndDueTasks$.value.includes(task._id);

        if (isRelevant && !isAlreadyCounted) {
            this.currentOpenAndDueTasks$.next([...this.currentOpenAndDueTasks$.value, task._id]);
        } else if (!isRelevant && isAlreadyCounted) {
            this.currentOpenAndDueTasks$.next(
                this.currentOpenAndDueTasks$.value.filter((taskId) => taskId !== task._id),
            );
        }
    }

    private handleDeletedTask(taskId: Task['_id']) {
        const isAlreadyCounted = this.currentOpenAndDueTasks$.value.includes(taskId);
        if (isAlreadyCounted) {
            this.currentOpenAndDueTasks$.next(
                this.currentOpenAndDueTasks$.value.filter((countedId) => countedId !== taskId),
            );
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeFromChangesInTasks();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

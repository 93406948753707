import { OverlayRef } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySelectChange } from '@angular/material/legacy-select/index';
import { InvoiceNumberOrReportTokenCounter } from '@autoixpert/models/teams/invoice-number-or-report-token-counter';
import { InvoiceNumberConfig, ReportTokenConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { getReportInvoiceNumberPatternWithoutSubCounter } from '../../../shared/libraries/preferences/get-report-invoice-number-pattern-without-sub-counter';
import { getTokenPreview } from '../../../shared/libraries/preferences/get-token-preview';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { InvoiceNumberOrReportTokenCounterService } from '../../../shared/services/invoice-number-or-report-token-counter.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { TeamService } from '../../../shared/services/team.service';

@Component({
    selector: 'report-invoice-number-config-dialog',
    templateUrl: './report-invoice-number-config-dialog.component.html',
    styleUrls: ['./report-invoice-number-config-dialog.component.scss'],
    animations: [slideInAndOutVertically()],
})
export class ReportInvoiceNumberConfigDialogComponent implements OnInit {
    @ViewChild('suffixInputField') suffixInputField: ElementRef<HTMLInputElement>;

    @Input() disabled: boolean = false;
    @Input() reportTokenConfig: ReportTokenConfig;
    @Input() invoiceNumberConfig: InvoiceNumberConfig;

    @Output() configChanged: EventEmitter<void> = new EventEmitter();

    private reportTokenCounter: InvoiceNumberOrReportTokenCounter = new InvoiceNumberOrReportTokenCounter();
    protected previousReportInvoiceNumberPattern: string;
    protected previousReportInvoiceCancellationSuffix: string;

    protected team: Team;
    protected user: User;

    // Report Token & Invoice Number
    protected availableReportInvoiceNumberPlaceholders: { title: string; placeholder: string }[] = [
        {
            title: 'Aktenzeichen',
            placeholder: '{AZ}',
        },
        {
            title: 'Unterzähler',
            placeholder: '{R}',
        },
        {
            title: 'Tag',
            placeholder: '{TT}',
        },
        {
            title: 'Monat',
            placeholder: '{MM}',
        },
        {
            title: 'Jahr (4-stellig)',
            placeholder: '{JJJJ}',
        },
        {
            title: 'Jahr (2-stellig)',
            placeholder: '{JJ}',
        },
        {
            title: 'Gutachtentyp',
            placeholder: '{TYP}',
        },
        {
            title: 'Initialen',
            placeholder: '{INI}',
        },
        {
            title: 'Standort (Kürzel)',
            placeholder: '{STO}',
        },
    ];

    protected popularReportInvoiceNumberPatterns: { title: string; pattern: string }[] = [
        {
            title: 'Aktenzeichen + Unterzähler',
            pattern: '{AZ}/{R}',
        },
        {
            title: 'Präfix, Aktenzeichen + Unterzähler',
            pattern: 'RE-{AZ}/{R}',
        },
    ];

    /**
     * Store a copy of the previous settings, so we can determine if the user changed them (to show the info note).
     */
    private previousSettings: ReportTokenConfig;

    constructor(
        private overlayRef: OverlayRef,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private apiErrorService: ApiErrorService,
        private counterService: InvoiceNumberOrReportTokenCounterService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    ngOnInit() {
        this.team = this.loggedInUserService.getTeam();
        this.user = this.loggedInUserService.getUser();
        void this.loadCounter();

        this.previousSettings = structuredClone(this.reportTokenConfig);
    }

    private async loadCounter() {
        this.reportTokenCounter = await this.counterService.get(this.reportTokenConfig._id);
    }

    /**
     * Boolean whether the current settings are different than the previous settings.
     */
    protected haveSettingsChanged(): boolean {
        return !this.deepEqual(this.previousSettings, this.reportTokenConfig);
    }

    /**
     * Compare two objects for equality, but treat undefined/false/null the same (because
     * the previous settings might not include a key, which is basically the same as false).
     */
    private deepEqual(obj1, obj2) {
        // Get all keys in both objects
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // Get unique set of all keys
        const allKeys = new Set([...keys1, ...keys2]);

        for (const key of allKeys) {
            const val1 = obj1[key] || false;
            const val2 = obj2[key] || false;

            // If the values are both objects, compare recursively
            if (typeof val1 === 'object' && val1 !== null && typeof val2 === 'object' && val2 !== null) {
                if (!this.deepEqual(val1, val2)) return false;
            }
            // If they are not equal, return false
            else if (val1 !== val2) {
                return false;
            }
        }

        return true;
    }

    /**
     * Add to existing pattern.
     */
    protected insertReportInvoiceNumberPlaceholder(placeholder: string) {
        if (!this.reportTokenConfig.reportInvoiceNumberPattern) {
            this.reportTokenConfig.reportInvoiceNumberPattern = '';
        }

        this.reportTokenConfig.reportInvoiceNumberPattern += placeholder;
        void this.saveTeam();
    }

    /**
     * Shorthand to retrieve a preview of the next report token (e.g. "GA-2024-04-009")
     */
    protected getReportTokenPreviewString(pattern?: string): string {
        return getTokenPreview(
            this.reportTokenCounter,
            this.reportTokenConfig,
            'reportToken',
            this.reportTokenConfig.syncWithInvoiceCounter && this.reportTokenConfig.leadingCounter === 'invoiceNumber',
            this.team,
            this.user,
            pattern,
        );
    }

    /**
     * Shorthand to retrieve a preview of the next invoice number based on report token (e.g. "GA-2024-04-009/1")
     */
    protected getInvoiceNumberPreviewString(pattern?: string): string {
        return getTokenPreview(
            this.reportTokenCounter,
            this.reportTokenConfig,
            'reportToken',
            this.reportTokenConfig.leadingCounter === 'invoiceNumber',
            this.team,
            this.user,
            pattern,
            null,
            this.getReportTokenPreviewString(this.reportTokenConfig.pattern),
        );
    }

    /**
     * Create an example invoice number for the n-th invoice.
     * @param index How many invoices exist already for the report?
     * @param isCancellationInvoice Is it a cancellation invoice?
     * @param forceStartCounterWithSecondInvoice Overwrite the current setting for reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice.
     * Helpful, if you want to create example invoice numbers based on theoretical settings for startReportInvoiceSubCounterWithSecondInvoice, not the actual current setting.
     * @param isAmendmentInvoice: Amendment invoices (similar to invoice audits) might start their own counter from 0
     */
    protected getInvoiceNumberExampleForSpecificIndex(
        index: number,
        {
            isCancellationInvoice,
            forceStartCounterWithSecondInvoice,
            isAmendmentInvoice,
            amendmentInvoiceIndex,
        }: {
            isCancellationInvoice?: boolean;
            forceStartCounterWithSecondInvoice?: boolean;
            isAmendmentInvoice?: boolean;
            amendmentInvoiceIndex?: number;
        } = {
            isCancellationInvoice: false,
            forceStartCounterWithSecondInvoice: null,
            amendmentInvoiceIndex: 0,
        },
    ): string {
        const newCounterForAmendmentInvoice =
            isAmendmentInvoice && this.reportTokenConfig.separateCounterForAmendmentReportsAndInvoiceAudits;
        let invoiceSubCounter = index;
        const startCounterWithSecondInvoice =
            forceStartCounterWithSecondInvoice ?? this.reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice;
        const reportTokenSuffix = newCounterForAmendmentInvoice
            ? (this.team.preferences.amendmentReportTokenSuffix ?? '-N')
            : '';

        if (isCancellationInvoice && this.reportTokenConfig.useCancellationInvoiceSuffixForReportInvoices) {
            // If we have a cancellation invoice, we might need the invoice number of the first invoice (that gets cancelled by the cancellation invoice)
            const firstInvoiceNumber = getTokenPreview(
                new InvoiceNumberOrReportTokenCounter(),
                this.invoiceNumberConfig,
                'invoiceNumber',
                false,
                this.team,
                this.user,
                this.reportTokenConfig.reportInvoiceNumberPattern,
                null,
                this.getReportTokenPreviewString(this.reportTokenConfig.pattern),
            );
            const patternWithoutSubCounter = getReportInvoiceNumberPatternWithoutSubCounter(this.reportTokenConfig);

            return getTokenPreview(
                new InvoiceNumberOrReportTokenCounter(),
                this.invoiceNumberConfig,
                'invoiceNumber',
                false,
                this.team,
                this.user,
                (startCounterWithSecondInvoice ? patternWithoutSubCounter : firstInvoiceNumber) +
                    this.reportTokenConfig.reportInvoiceCancellationSuffix,
                null,
                this.getReportTokenPreviewString(this.reportTokenConfig.pattern),
            );
        }

        if (startCounterWithSecondInvoice) {
            invoiceSubCounter--;
            amendmentInvoiceIndex--;

            const patternWithoutSubCounter = getReportInvoiceNumberPatternWithoutSubCounter(this.reportTokenConfig);

            if (index === 0 || (amendmentInvoiceIndex === -1 && newCounterForAmendmentInvoice)) {
                return getTokenPreview(
                    new InvoiceNumberOrReportTokenCounter(),
                    this.invoiceNumberConfig,
                    'invoiceNumber',
                    false,
                    this.team,
                    this.user,
                    patternWithoutSubCounter,
                    null,
                    this.getReportTokenPreviewString(this.reportTokenConfig.pattern + reportTokenSuffix),
                );
            }
        }

        if (index > 1 && this.reportTokenConfig.useCancellationInvoiceSuffixForReportInvoices) {
            invoiceSubCounter--;
        }

        return getTokenPreview(
            new InvoiceNumberOrReportTokenCounter({
                count: newCounterForAmendmentInvoice ? amendmentInvoiceIndex : invoiceSubCounter,
            }),
            this.invoiceNumberConfig,
            'invoiceNumber',
            false,
            this.team,
            this.user,
            this.reportTokenConfig.reportInvoiceNumberPattern,
            null,
            this.getReportTokenPreviewString(this.reportTokenConfig.pattern + reportTokenSuffix),
        );
    }

    /**
     * Replace the existing pattern.
     * @param pattern
     */
    protected async replaceReportTokenPattern(pattern: string) {
        this.reportTokenConfig.reportInvoiceNumberPattern = pattern;

        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            reportTokenConfigId: this.reportTokenConfig._id,
            reportInvoiceNumberPattern: this.reportTokenConfig.reportInvoiceNumberPattern,
            previousReportInvoiceNumberPattern: this.previousReportInvoiceNumberPattern,
        });
    }

    /**
     * User changed the selection whether or not the first invoice should already use the sub counter or not.
     */
    protected subCounterSelectionChanged(change: MatLegacySelectChange): void {
        this.reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice = change.value === 'second';
        void this.saveTeam();

        this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            reportTokenConfigId: this.reportTokenConfig._id,
            startReportInvoiceSubCounterWithSecondInvoice:
                this.reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice,
        });
    }

    /**
     * User changed the selection whether to use a suffix for cancellation invoices or the regular sub counter.
     */
    protected cancellationInvoiceSettingsChanged(change: MatLegacySelectChange): void {
        const useSuffix = change.value === 'suffix';
        this.reportTokenConfig.useCancellationInvoiceSuffixForReportInvoices = useSuffix;

        if (useSuffix) {
            // Focus the input field for the suffix
            setTimeout(() => {
                this.suffixInputField.nativeElement.focus();
            }, 0);

            if (!this.reportTokenConfig.reportInvoiceCancellationSuffix) {
                // Provide a default, so users don't forget to set one
                this.reportTokenConfig.reportInvoiceCancellationSuffix = '-Storno';
            }
        }

        void this.saveTeam();

        this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            reportTokenConfigId: this.reportTokenConfig._id,
            useCancellationInvoiceSuffix: this.reportTokenConfig.useCancellationInvoiceSuffixForReportInvoices,
        });
    }

    handleReportInvoiceCancellationSuffixChange() {
        this.saveTeam();

        this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            reportTokenConfigId: this.reportTokenConfig._id,
            reportInvoiceCancellationSuffix: this.reportTokenConfig.reportInvoiceCancellationSuffix,
            previousReportInvoiceCancellationSuffix: this.previousReportInvoiceCancellationSuffix,
        });
    }

    protected async closeDialog() {
        this.overlayRef.detach();
    }

    protected async handleReportInvoiceNumberPatternChange() {
        await this.saveTeam();

        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            reportTokenConfigId: this.reportTokenConfig._id,
            reportInvoiceNumberPattern: this.reportTokenConfig.reportInvoiceNumberPattern,
            previousReportInvoiceNumberPattern: this.previousReportInvoiceNumberPattern,
        });
    }

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected closeEditorOnEscKey(event) {
        if (event.key === 'Escape') {
            // Make sure saving is triggered if the user is still inside an input.
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                (document.activeElement as HTMLElement).blur();
            }
            void this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }
}

import { determineFeePreferencesKey } from '@autoixpert/lib/fee-calculation/determine-fee-preference-key';
import { CustomFeeSet } from '@autoixpert/models/reports/assessors-fee/custom-fee-set';
import { Report } from '@autoixpert/models/reports/report';
import { TeamPreferences } from '@autoixpert/models/teams/team-preferences';

export function selectFeeSet({
    report,
    teamPreferences,
    feeTableName,
    yearOfFeeTable,
    selectedCustomFeeSet,
}: {
    report: Report;
    teamPreferences: TeamPreferences;
    feeTableName: Report['feeCalculation']['selectedFeeTable'];
    yearOfFeeTable: number;
    selectedCustomFeeSet: CustomFeeSet;
}) {
    let customFeeSet: CustomFeeSet | undefined = undefined;
    const oldFeeTable = report.feeCalculation.selectedFeeTable;

    /**
     * Custom Fee Set
     *
     * If new fee set is custom, add ID.
     * If old fee set was custom, remove ID.
     */
    const switchingFromCustom = oldFeeTable === 'custom';
    const switchingToCustom = feeTableName === 'custom';

    if (switchingToCustom) {
        if (!selectedCustomFeeSet) {
            return { error: 'No custom fee set selected' };
        }
        report.feeCalculation.selectedCustomFeeTableId = selectedCustomFeeSet._id;
        customFeeSet = selectedCustomFeeSet;
    } else if (switchingFromCustom) {
        // clear custom id
        report.feeCalculation.selectedCustomFeeTableId = null;
    }

    /**
     * Replace fees when switching to the HUK table or away from it because we have different default fees for HUK on the one hand and all other fee tables on the other.
     *
     * We don't have to overwrite the fees when switching within the group of non-HUK tables because they currently share the same default fees. If we change that at some point,
     * it makes sense to replace the fees in every case.
     */
    const switchingAwayFromHuk = oldFeeTable === 'HUK';
    const switchingToHuk = feeTableName === 'HUK';

    if (switchingAwayFromHuk || switchingToHuk) {
        // Import HUK specific line items or import default line items (based on the new fee table)
        const feePreferencesKey = determineFeePreferencesKey(report.type, feeTableName);
        for (const property in report.feeCalculation) {
            if (teamPreferences[feePreferencesKey].hasOwnProperty(property)) {
                report.feeCalculation[property] = teamPreferences[feePreferencesKey][property];
            }
        }
        // Update the line items, copy object, not just reference
        report.feeCalculation.invoiceParameters.lineItems = JSON.parse(
            JSON.stringify(teamPreferences[feePreferencesKey].reportInvoiceLineItems),
        );
    }

    report.feeCalculation.selectedFeeTable = feeTableName;
    report.feeCalculation.yearOfFeeTable = yearOfFeeTable;

    return { report, customFeeSet };
}

import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { ChecklistItemName } from '@autoixpert/models/report-progress/report-progress-checklist-item-names';
import { Report } from '@autoixpert/models/reports/report';

/**
 * For each tab within the report process, return a list of items that can be used to display a status.
 *
 * This list must include all items across all report types. The unnecessary items will be filtered out
 * when cross-comparing with each report type's config.
 * @param tabName
 * @param reportType
 */
export function getChecklistItemNamesByTabName(
    tabName: ReportTabName,
    reportType: Report['type'],
): ChecklistItemName[] {
    switch (tabName) {
        case 'accidentData':
            return [
                'claimantNameAndAddress',
                'claimantEmail',
                'claimantLicensePlate',
                'lawyerNameAndAddress',
                'accidentDate',
                'partialKaskoDamageType',
                'visitLocationAndDate',
                'authorOfDamageLicensePlate',
                'leaseProviderNameAndAddress',
                'leaseContractNumber',
                'insuranceName',
                'insuranceNumber',
                'insuranceCaseNumber',
                'declarationOfAssignment',
            ];
        case 'carData':
            return [
                'vin',
                'vinWasChecked',
                'carMakeAndModel',
                'productionYear',
                'firstRegistration',
                'latestRegistration',
            ];
        case 'carCondition':
            return [
                'conditionInputs',
                'roadworthiness',
                'mileage',
                'nextGeneralInspection',
                'damageSketch',
                'damageDescription',
                'paintThickness',
                'repairedPreviousDamage',
                'unrepairedPreviousDamage',
                'tires',
            ];
        case 'carConditionOldtimer':
            return ['mileage', 'nextGeneralInspection', 'tires', 'paintThickness'];
        case 'photos':
            return ['photosUploaded', 'somePhotosNamed', 'somePhotosHidden', 'someResidualValueVersionsAdded'];
        case 'damageCalculation':
            return [
                'garage',
                'repairCosts',
                'vehicleValue',
                'replacementValue',
                'residualValue',
                'diminishedValue',
                'downtimeCompensation',
                'damageClass',
            ];
        case 'valuation':
            return ['vehicleValue'];
        case 'valuationOldtimer':
            return ['oldtimerMarketValue', 'oldtimerReplacementValue'];
        case 'leaseReturnChecklist':
            return ['leaseReturnChecklistRepairCosts', 'leaseReturnChecklistDiminishedValue'];
        case 'invoiceAudit':
            return ['invoiceAuditProjectedCosts', 'invoiceAuditActualCosts', 'invoiceAuditCorrectedCosts'];
        case 'fees':
            return ['assessorsFee', 'travelExpenses', 'writingFees'];
        case 'printAndTransmission':
            return [
                'sentToClaimant',
                'sentToInsurance',
                'sentToLawyer',
                'sentToLawyerOrInsurance',
                'sentToGarage',
                'sentToLeaseProvider',
                'sentToSeller',
            ];
    }
}

export class GtmotiveUser {
    /**
     * The company's ID.
     */
    customerId: string = null;
    /**
     * The person's ID.
     */
    userId: string = null;
    /**
     * Apparently, no password is needed. All interface partners may edit every users' data.
     */
}

/**
 * autoiXpert suggests a default title for each damage, based on its position.
 */
export const defaultDamageTitles = {
    frontLeft: 'Vorne links',
    frontCenter: 'Vorne mittig',
    frontRight: 'Vorne rechts',
    fenderFrontLeft: 'Kotflügel links',
    hood: 'Motorhaube',
    fenderFrontRight: 'Kotflügel rechts',
    doorDriver: 'Fahrertür',
    windshield: 'Windschutzscheibe',
    doorFrontPassenger: 'Beifahrertür',
    doorBackPassengerLeft: 'Fondtür links',
    roof: 'Dach',
    doorBackPassengerRight: 'Fondtür rechts',
    fenderRearLeft: 'Seitenwand links',
    fenderRearRight: 'Seitenwand rechts',
    centerLeft: 'Mitte links',
    centerRight: 'Mitte rechts',
    rearLeft: 'Hinten links',
    rearWindow: 'Rückscheibe',
    roofRear: 'Dach hinten', // e.g. Motor Home
    rearCenter: 'Hinten mittig',
    rearRight: 'Hinten rechts',
    // Truck & Semi Trailer
    leftWallFront: 'Linke Wand vorne',
    leftWallCenter: 'Linke Wand mittig',
    leftWallRear: 'Linke Wand hinten',
    ceilingFront: 'Decke vorne',
    ceilingCenter: 'Decke mittig',
    ceilingRear: 'Decke hinten',
    rightWallFront: 'Rechte Wand vorne',
    rightWallCenter: 'Rechte Wand mittig',
    rightWallRear: 'Rechte Wand hinten',
    // Semi Truck
    frameLeft: 'Rahmen links',
    frameRight: 'Rahmen rechts',
};

<!--============================================-->
<!-- Signature Pad - Small -->
<!--============================================-->
<!--suppress CssUnknownProperty -->
<div
    class="signature-container"
    [ngClass]="{ 'file-over-pad': fileIsOverSignaturePad, 'frame-highlighted': frameHighlighted && !disabledReason }"
    ng2FileDrop
    (fileOver)="onFileOverSignaturePad($any($event))"
    (onFileDrop)="onSignatureDrop($any($event))"
    [uploader]="uploader"
    [style.--signatureCanvasWidth.px]="canvasWidth"
    [style.--signatureCanvasHeight.px]="canvasHeight">
    <canvas class="signature-canvas" #signatureCanvasSmall [width]="canvasWidth" [height]="canvasHeight"></canvas>

    <!--********** Signature Readonly **********-->
    <!-- If the signature was loaded from the server, the user must delete it first to change it -->
    <div
        class="signature-readonly-container"
        *ngIf="wasSignatureLoadedFromServer()"
        (click)="warnUserAboutDisabledSignaturePad()"></div>

    <!--********** Disabled + Reason **********-->
    <div
        class="signature-disabled-container"
        *ngIf="!wasSignatureLoadedFromServer() && disabledReason"
        (click)="warnUserAboutDisabledSignaturePad()">
        <div class="signature-disabled-reason" [ngSwitch]="disabledReason">
            <div *ngSwitchCase="'reportLocked'">Gutachten abgeschlossen</div>
            <div *ngSwitchCase="'signAllAtOnceActive'">Später gesammelt zu unterschreiben</div>
            <div *ngSwitchCase="'deadlinePassed'">Frist abgelaufen</div>
            <div *ngSwitchCase="'signatureSubmitted'">Bereits unterschrieben</div>
            <div *ngSwitchDefault>Feld deaktiviert</div>
        </div>
    </div>

    <!--********** Toolbar **********-->
    <action-toolbar *ngIf="toolbarShown && !signaturePadSmall.isEmpty()">
        <action-toolbar-item
            label="Ändern"
            (wasClicked)="openSignaturePadFullscreen()"
            iconName="touch_app"></action-toolbar-item>

        <!--********** Transfer Signature **********-->
        <action-toolbar-item
            *ngIf="!disableSignatureTransfer"
            (wasClicked)="transferSignatureFile()"
            iconName="move_down"></action-toolbar-item>

        <!--********** Delete Signature **********-->
        <action-toolbar-item (wasClicked)="removeSignatureFile()" iconName="delete"></action-toolbar-item>
    </action-toolbar>

    <div class="action-toolbar-click-area-mobile" *ngIf="!disabledReason" (click)="toolbarShown = true"></div>

    <!-- Empty state shown on mobile -> click on it opens the fullscreen overlay -->
    <div
        *ngIf="!disabledReason && signaturePadSmall.isEmpty()"
        (click)="openSignaturePadFullscreen()"
        class="empty-state-mobile">
        <mat-icon>touch_app</mat-icon>
        <span>
            Tippen zum
            <br />
            Unterschreiben
        </span>
    </div>

    <div class="signature-pad-toolbar" *ngIf="!disabledReason">
        <!--********** Large Signature Pad Icon **********-->
        <mat-icon
            id="signature-pad-fullscreen-icon"
            class="toolbar-icon"
            *ngIf="signaturePadSmall.isEmpty()"
            (click)="openSignaturePadFullscreen()"
            matTooltip="Auf großer Fläche unterschreiben, z. B. mit dem Finger">
            fullscreen
        </mat-icon>

        <!--********** Transfer Signature **********-->
        <mat-icon
            class="toolbar-icon"
            *ngIf="!signaturePadSmall.isEmpty() && !disableSignatureTransfer"
            (click)="transferSignatureFile()"
            matTooltip="Unterschrift übertragen">
            move_down
        </mat-icon>

        <!--********** Delete Signature **********-->
        <mat-icon
            class="toolbar-icon"
            *ngIf="!signaturePadSmall.isEmpty()"
            (click)="removeSignatureFile()"
            matTooltip="Unterschrift löschen">
            delete
        </mat-icon>

        <!--********** Change Date **********-->
        <div class="datepicker-container">
            <mat-icon class="toolbar-icon" (click)="signatureDatepicker.open()" matTooltip="Datum ändern">
                today
            </mat-icon>
            <mat-form-field class="signature-date-input">
                <input
                    matInput
                    [ngModel]="signatureDate"
                    [matDatepicker]="signatureDatepicker"
                    (dateChange)="handleDateSelection($event); emitSignatureDateChange($event)" />
                <mat-datepicker [touchUi]="isTablet" #signatureDatepicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>

    <!--********** Signer Data **********-->
    <div id="signature-small-signer-container" *ngIf="signerName || displayOrientationLine">
        <div class="signature-author-and-date-container">
            <!-- signerName may be multiline -->
            <div class="signature-author" [innerHTML]="signerName"></div>
            <div class="signature-date">{{ signatureDate | moment: 'DD.MM.YYYY' }}</div>
        </div>
        <div class="label" *ngIf="signerOrganization">{{ signerOrganization }}</div>
    </div>

    <!--********** Loading... **********-->
    <div class="siganture-loading-indicator" *ngIf="signatureDownloadPending">
        <img src="/assets/images/icons/loading-indicator.svg" alt="" class="pending-icon" />
        Lädt...
    </div>
</div>
<!--============================================-->
<!-- END Signature Pad - Small -->
<!--============================================-->

<!--============================================-->
<!-- Fullscreen Signature Pad -->
<!--============================================-->
<div
    class="signature-pad-fullscreen-overlay"
    *ngIf="signaturePadFullscreenShown && signaturePadFullscreenMode === 'signatureOnly'">
    <div class="signature-pad-fullscreen-container card card-with-padding">
        <h2 class="signature-pad-fullscreen-title">Unterschrift {{ signerName || 'Anspruchsteller' }}</h2>
        <div class="signature-fullscreen-canvas-container">
            <canvas class="signature-fullscreen-canvas" #signatureCanvasFullscreen></canvas>
            <div class="signature-pad-toolbar" *ngIf="signaturePadFullscreen">
                <mat-icon
                    *ngIf="!signaturePadFullscreen.isEmpty()"
                    class="toolbar-icon"
                    (click)="signaturePadFullscreen.clear()">
                    delete
                </mat-icon>
                <div class="datepicker-container">
                    <mat-icon class="toolbar-icon" (click)="signatureDatepicker.open()" matTooltip="Datum ändern">
                        today
                    </mat-icon>
                    <mat-form-field class="signature-date-input">
                        <input
                            matInput
                            [ngModel]="signatureDate"
                            [matDatepicker]="signatureDatepicker"
                            (dateChange)="handleDateSelection($event); emitSignatureDateChange($event)" />
                        <mat-datepicker [touchUi]="isTablet" #signatureDatepicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <!--********** Signer Data **********-->
            <div class="signature-fullscreen-signer-container">
                <div class="signature-author-and-date-container">
                    <div class="signature-author-and-date-container">
                        <!-- signerName may be multiline -->
                        <div class="signature-author" [innerHTML]="signerName"></div>
                        <div class="signature-date">{{ signatureDate | moment: 'DD.MM.YYYY' }}</div>
                    </div>
                </div>
                <div class="label" *ngIf="signerOrganization">{{ signerOrganization }}</div>
            </div>
        </div>

        <div class="signature-fullscreen-buttons-container">
            <button (click)="saveAndCloseSignaturePadFullscreen()">Übernehmen</button>
            <button (click)="closeSignaturePadFullscreen()" class="secondary">Abbrechen</button>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Fullscreen Signature Pad -->
<!--============================================-->

<!--============================================-->
<!-- Fullscreen Signature Pad (Alternative) -->
<!--============================================-->
<div
    class="signature-pad-fullscreen-overlay-alt"
    *ngIf="signaturePadFullscreenShown && signaturePadFullscreenMode === 'allOptions'">
    <!-- Backdrop -->
    <div class="signature-pad-fullscreen-backdrop" (click)="closeSignaturePadFullscreen()"></div>

    <!-- Modal -->
    <div class="signature-pad-fullscreen-container-alt card">
        <!-- Header -->
        <div class="signature-pad-fullscreen-alt-header">
            <!-- Draw tab -->
            <div
                class="signature-pad-fullscreen-alt-header-tab-item"
                [ngClass]="{ active: signaturePadFullscreenShown === 'draw' }"
                (click)="switchFullscreenTab('draw')">
                <!-- Draw tab icon -->
                <mat-icon class="tab-icon">stylus_note</mat-icon>

                <!-- Draw tab label -->
                <div class="tab-label">Zeichnen</div>

                <!-- Draw tab active indicator -->
                <div class="active-indicator"></div>
            </div>

            <!-- Upload tab -->
            <div
                class="signature-pad-fullscreen-alt-header-tab-item"
                [ngClass]="{ active: signaturePadFullscreenShown === 'upload' }"
                (click)="switchFullscreenTab('upload')">
                <!-- Draw tab icon -->
                <mat-icon class="tab-icon">image</mat-icon>

                <!-- Draw tab label -->
                <div class="tab-label">Bilddatei</div>

                <!-- Draw tab active indicator -->
                <div class="active-indicator"></div>
            </div>

            <!-- Smartphone tab -->
            <div
                class="signature-pad-fullscreen-alt-header-tab-item"
                [ngClass]="{ active: signaturePadFullscreenShown === 'smartphone' }"
                (click)="switchFullscreenTab('smartphone')">
                <!-- Draw tab icon -->
                <mat-icon class="tab-icon">smartphone</mat-icon>

                <!-- Draw tab label -->
                <div class="tab-label">Smartphone</div>

                <!-- Draw tab active indicator -->
                <div class="active-indicator"></div>
            </div>

            <!-- Close button -->
            <mat-icon class="signature-pad-fullscreen-alt-close-icon" (click)="closeSignaturePadFullscreen()">
                close
            </mat-icon>
        </div>

        <div class="signature-pad-fullscreen-alt-content">
            <!-- Draw tab -->
            <div
                class="signature-pad-fullscreen-alt-draw-container"
                [ngClass]="{ active: signaturePadFullscreenShown === 'draw' }">
                <!-- Signature Canvas -->
                <div class="signature-fullscreen-canvas-container no-border">
                    <canvas class="signature-fullscreen-canvas" #signatureCanvasFullscreen></canvas>
                    <div class="signature-pad-toolbar" *ngIf="signaturePadFullscreen">
                        <!--********** Delete Signature **********-->
                        <mat-icon
                            *ngIf="!signaturePadFullscreen.isEmpty()"
                            class="toolbar-icon"
                            (click)="signaturePadFullscreen.clear()">
                            delete
                        </mat-icon>
                        <div class="datepicker-container">
                            <mat-icon
                                class="toolbar-icon"
                                (click)="signatureDatepicker.open()"
                                matTooltip="Datum ändern">
                                today
                            </mat-icon>
                            <mat-form-field class="signature-date-input">
                                <input
                                    matInput
                                    [ngModel]="signatureDate"
                                    [matDatepicker]="signatureDatepicker"
                                    (dateChange)="handleDateSelection($event); emitSignatureDateChange($event)" />
                                <mat-datepicker [touchUi]="isTablet" #signatureDatepicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <!--********** Signer Data **********-->
                    <div class="signature-fullscreen-signer-container">
                        <div class="signature-author-and-date-container">
                            <div class="signature-author-and-date-container">
                                <!-- signerName may be multiline -->
                                <div class="signature-author" [innerHTML]="signerName"></div>
                                <div class="signature-date">{{ signatureDate | moment: 'DD.MM.YYYY' }}</div>
                            </div>
                        </div>
                        <div class="label" *ngIf="signerOrganization">{{ signerOrganization }}</div>
                    </div>
                </div>

                <!-- Save buttons -->
                <div class="signature-fullscreen-buttons-container small-padding">
                    <button (click)="saveAndCloseSignaturePadFullscreen()">Übernehmen</button>
                    <button (click)="closeSignaturePadFullscreen()" class="secondary">Abbrechen</button>
                </div>
            </div>

            <!-- Upload tab  -->
            <div
                class="signature-pad-fullscreen-alt-upload-container"
                [ngClass]="{
                    active: signaturePadFullscreenShown === 'upload',
                    'file-over-pad': fileIsOverSignaturePad,
                    'frame-highlighted': frameHighlighted && !disabledReason,
                }"
                ng2FileDrop
                (fileOver)="onFileOverSignaturePad($any($event))"
                (onFileDrop)="onSignatureDrop($any($event))"
                [uploader]="uploader">
                <!-- Description -->
                <div class="signature-pad-fullscreen-alt-upload-description">
                    <span>
                        Lade ein Bild deiner Unterschrift hoch, indem du es hierhin ziehst oder auf den Button klickst.
                    </span>
                </div>

                <!-- Upload button -->
                <div class="signature-pad-fullscreen-alt-upload-button">
                    <input
                        type="file"
                        accept="image/*"
                        (change)="handleSignatureUpload($event)"
                        #signatureUploadInput />
                    <button (click)="signatureUploadInput.click()">Bild hochladen</button>
                </div>
            </div>

            <!-- Smartphone tab -->
            <div
                class="signature-pad-fullscreen-alt-smartphone-container"
                [ngClass]="{ active: signaturePadFullscreenShown === 'smartphone' }">
                <!-- Description -->
                <div class="signature-pad-fullscreen-alt-smartphone-description">
                    <span>Unterschreibe die Dokumente auf deinem&nbsp;</span>
                    <b>Smartphone oder Tablet</b>
                    <span>, indem du den QR-Code scannst.</span>
                </div>

                <!-- QR Code -->
                <div class="signature-pad-fullscreen-alt-smartphone-qr-code">
                    <img
                        draggable="false"
                        class="qr-code"
                        [src]="qrCodeDataUrl"
                        [matTooltip]="'Klicke, um die URL vom QR-Code in die Zwischenablage zu kopieren'"
                        alt="QR-Code zum Scannen mit dem Smartphone"
                        (click)="copyQrCodeUrlToClipboard()"
                        (elementCreated)="generateQrCodeDataUrl()" />
                </div>
            </div>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Fullscreen Signature Pad (Alternative) -->
<!--============================================-->

<!--============================================-->
<!-- Signature Canvas Offscreen -->
<!--============================================-->
<!-- Used for up-scaling the signature image before uploading it to the server -->
<canvas class="signature-canvas-offscreen" #signatureCanvasOffscreen></canvas>
<!--============================================-->
<!-- END Signature Canvas Offscreen -->
<!--============================================-->

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { HexColor } from '@autoixpert/models/hex-color';
import { ColorPickerComponent } from './color-picker.component';

@Directive({
    selector: '[colorPicker]',
})
export class ColorPickerAnchorDirective {
    constructor(
        private overlayService: Overlay,
        private elementRef: ElementRef,
    ) {}

    @Input() selectedColor: HexColor;
    @Output() selectedColorChange: EventEmitter<HexColor> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    private overlayRef: OverlayRef;
    private componentRef: ComponentRef<ColorPickerComponent>;

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openColorPickerOverlay() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 10,
                    },
                ])
                .withPush(false) // The default value of "withPush" is true but causes the overlay to overflow the viewport on small screens (disables viewportMargin).
                .withViewportMargin(20),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
            this.close.emit();
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<ColorPickerComponent>(ColorPickerComponent);

        // Attach Component to Portal Outlet
        this.componentRef = this.overlayRef.attach(componentPortal);

        // Update component properties.
        this.componentRef.instance.initiallySelectedColor = this.selectedColor;

        // Subscribe to changes in component.
        this.componentRef.instance.colorSelected.subscribe((selectedColor) => {
            this.selectedColorChange.emit(selectedColor);
            this.overlayRef.detach();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

<div class="dialog-overlay" @dialogEnterAndLeave>
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
        <h2 class="no-margin-bottom">Kürzel & Farbe</h2>
        <div class="label margin-bottom-10">{{ userFullName }}</div>

        <div id="user-avatar-container">
            <user-avatar
                [userId]="user._id"
                class="cursor-pointer"
                [size]="62"
                (click)="!user.profilePictureHash && colorInput.click()"></user-avatar>

            <input type="color" [(ngModel)]="user.avatarColor" (change)="emitChange()" #colorInput />
        </div>

        <p class="label">
            Die Initialen & die Farbe eines Nutzers werden statt dem Profilbild angezeigt, wenn kein Profilbild
            hochgeladen wurde.
        </p>
        <p class="label">Außerdem können sie im Aktenzeichen oder in der Rechnungsnummer verwendet werden.</p>

        <!--********** Initialen **********-->
        <mat-form-field class="mat-block">
            <input matInput placeholder="Initialen" [(ngModel)]="user.initials" (change)="emitChange()" matTooltip="" />
        </mat-form-field>

        <div class="dialog-buttons-container">
            <button (click)="closeDialog()">Bearbeitung beenden</button>
        </div>
    </div>
</div>

import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { numberFormatter } from '../shared/libraries/number-formatter';

export const [
    seriesDimension1,
    seriesDimension2,
    seriesDimension3,
    seriesDimension4,
    seriesDimension5,
    seriesDimension6,
    seriesDimension7,
] = [
    // Define and move colors around here.
    // (Comment necessary so that Prettier doesn't inline the array, removing color previews in the IDE's left gutter)
    '#15a9e8',
    '#1768c4',
    '#2e9482',
    '#99d4f6',
    '#7145a8',
    '#e3caa6',
    '#98335b',
];

/* Color Theme Swatches in Hex */

// Special Meaning Colors
export const shortPaymentColor = '#bc52cc';
export const undeterminedColor = '#a1a1a1';
export const neutralColor = '#515c6d';
export const successColor = '#27a345';
export const negativeColor = '#e65050';
export const alertColor = '#ffae00';

export const defaultColors: string[] = [
    seriesDimension1,
    seriesDimension2,
    seriesDimension3,
    seriesDimension4,
    seriesDimension5,
    seriesDimension6,
    seriesDimension7,
];

export function setDefaultChartOptions() {
    const apexDefaultOptions = (window as unknown as any).Apex as apexcharts.ApexOptions;

    //*****************************************************************************
    //  X-Axis
    //****************************************************************************/
    apexDefaultOptions.xaxis = {
        categories: [],
        axisTicks: {
            show: false,
        },
        labels: {
            show: true,
            formatter: dynamicFormatter,
        },
        axisBorder: {
            color: '#c1c1c1',
        },
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END X-Axis
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/
    apexDefaultOptions.chart = {
        height: 300,
        toolbar: {
            show: false,
        },
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Plot Options
    //****************************************************************************/
    apexDefaultOptions.plotOptions = {
        bar: {
            columnWidth: '20%',
            barHeight: '50%',
            dataLabels: {
                position: 'top',
            },
            endingShape: 'flat',
            colors: {
                backgroundBarOpacity: 1,
            },
        },
    } as ApexPlotOptions;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Plot Options
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Stroke
    //****************************************************************************/
    apexDefaultOptions.stroke = {
        show: false,
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Stroke
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Labels
    //****************************************************************************/
    apexDefaultOptions.dataLabels = {
        enabled: false,
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Labels
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Legend
    //****************************************************************************/
    apexDefaultOptions.legend = {
        show: false,
        position: 'bottom',
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Legend
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltip
    //****************************************************************************/
    apexDefaultOptions.tooltip = {
        theme: 'dark',
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltip
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Colors
    //****************************************************************************/
    apexDefaultOptions.colors = defaultColors;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Colors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Grid
    //****************************************************************************/
    apexDefaultOptions.grid = {
        borderColor: '#f1f1f1',
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Grid
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fill
    //****************************************************************************/
    apexDefaultOptions.fill = {
        opacity: 1,
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fill
    /////////////////////////////////////////////////////////////////////////////*/
}

function dynamicFormatter(value: string, timestamp?: number): string {
    if (/\d{4}-\d{2}/.test(value)) {
        return moment(value, ['YYYY-MM']).format('MMM YY').replace('.', '');
    } else if (!isNaN(+value)) {
        return numberFormatter(+value);
    }
    return value;
}

//*****************************************************************************
//  Height depending on Number of Records
//****************************************************************************/
/**
 * The height of the charts must be calculated based on the number of records on display and on
 * the offset to make space for the X-axis.
 */
export const heightOffsetForHorizontalBarChart = 80;
export const barHeightForHorizontalBarChart = 36;

export function getHeightOfHorizontalBarChart(numberOfRecords: number): number {
    return heightOffsetForHorizontalBarChart + numberOfRecords * barHeightForHorizontalBarChart;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Height depending on Number of Records
/////////////////////////////////////////////////////////////////////////////*/

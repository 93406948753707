<div id="copy-report-dialog-container">
    <!--********** Close Icon **********-->
    <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

    <h2 *ngIf="createAmendmentReport">Nachtrag erstellen</h2>
    <h2 *ngIf="createInvoiceAudit">Rechnungsprüfung erstellen</h2>
    <h2 *ngIf="!createAmendmentReport && !createInvoiceAudit">Vorgang kopieren</h2>

    <!--============================================-->
    <!-- Copy Intention -->
    <!--============================================-->
    <div id="copy-intentions-grid" class="options-row grid" *ngIf="!createAmendmentReport && !createInvoiceAudit">
        <!--********** Basic Data (Claimant & Car Data) **********-->
        <div
            class="option-container-with-icon-and-label option-container selection-box"
            [ngClass]="{ selected: copyIntention === 'basicData' }"
            (click)="copyIntention = 'basicData'"
            matTooltip="Geeignet für neue Aufträge zu bekannten Fahrzeugen oder Kunden, die von einem vorherigen Auftrag übernommen werden sollen.">
            <!--********** Selection Marker **********-->
            <div class="selection-box-active-marker">
                <mat-icon>check</mat-icon>
            </div>

            <!--********** Option Image **********-->
            <div class="option-image-container">
                <mat-icon class="option-image">person</mat-icon>
            </div>

            <!--********** Title & Label **********-->
            <div class="option-title">Grunddaten</div>
            <div class="label">
                Anspruchsteller + Fahrzeug.
                <br />
                Ohne km-Stand, Zustand etc.
            </div>
        </div>

        <!--********** Custom Data **********-->
        <div
            class="option-container-with-icon-and-label option-container selection-box"
            [ngClass]="{ selected: copyIntention === 'custom' }"
            (click)="copyIntention = 'custom'"
            matTooltip="Geeignet, um z. B. je einen Vorgang mit gleichen Beteiligten & Unfalldaten für Zugmaschine und Auflieger anzulegen. Ermöglicht die Erstellung einer weitgehend gleichen Kopie des Datensatzes.">
            <!--********** Selection Marker **********-->
            <div class="selection-box-active-marker">
                <mat-icon>check</mat-icon>
            </div>

            <!--********** Option Image **********-->
            <div class="option-image-container">
                <mat-icon class="option-image">dashboard_customize</mat-icon>
            </div>

            <!--********** Title & Label **********-->
            <div class="option-title">Individuell</div>
            <div class="label">
                Alle Beteiligten
                <br />
                + optionale Daten
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Copy Intention -->
    <!--============================================-->

    <!--============================================-->
    <!-- Optional Data -->
    <!--============================================-->
    <section id="optional-data-section" *ngIf="copyIntention === 'custom'" @fadeInAndSlide>
        <h3>
            Optionale Daten kopieren
            <span
                *ngIf="!createInvoiceAudit"
                class="help-indicator"
                matTooltip="Grundsätzlich werden alle Daten außer die versendeten E-Mails kopiert. Bei den folgenden Daten entscheidest du.">
                ?
            </span>
            <span
                *ngIf="createInvoiceAudit"
                class="help-indicator"
                matTooltip="Der Anspruchsteller, die Versicherung und das Fahrzeug werden kopiert. Bei den folgenden Daten entscheidest du.">
                ?
            </span>
        </h3>

        <div class="copy-option-grid">
            <!--********** Photos **********-->
            <div
                class="option-container large-selection-box"
                [ngClass]="{ selected: copyPhotos }"
                (click)="copyPhotos = !copyPhotos"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <mat-icon class="option-image">photo_library</mat-icon>
                <div class="option-title-container">
                    <span class="option-title">
                        {{ sourceReport.photos.length }} {{ sourceReport.photos.length === 1 ? 'Foto' : 'Fotos' }}
                    </span>
                </div>
            </div>

            <!--********** Calculation **********-->
            <!-- An Audatex task contains multiple calculations by default, so there is not reason to copy the task. -->
            <div
                class="option-container large-selection-box"
                *ngIf="
                    !createInvoiceAudit &&
                    sourceReport.damageCalculation?.repair.calculationProvider &&
                    sourceReport.damageCalculation.repair.calculationProvider !== 'audatex'
                "
                [ngClass]="{ selected: copyCalculation }"
                (click)="toggleCalculation()"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <mat-icon class="option-image">calculate</mat-icon>
                <div class="option-title-container">
                    <span class="option-title">Kalkulation</span>
                </div>
            </div>

            <!--********** Damage Description **********-->
            <div
                *ngIf="!createInvoiceAudit"
                class="option-container large-selection-box"
                [ngClass]="{ selected: copyDamageDescription }"
                (click)="copyDamageDescription = !copyDamageDescription"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <mat-icon class="option-image">assignment</mat-icon>
                <div class="option-title-container">
                    <span class="option-title" matTooltip="Schadenbeschreibung">Schadenbeschr.</span>
                </div>
            </div>

            <!--********** Residual Value Offers **********-->
            <div
                *ngIf="!createInvoiceAudit"
                class="option-container large-selection-box"
                [ngClass]="{ selected: copyResidualValueOffer }"
                (click)="copyResidualValueOffer = !copyResidualValueOffer"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <mat-icon class="option-image icon-emphasized">gavel</mat-icon>
                <div class="option-title-container">
                    <span class="option-title" matTooltip="Restwertinserate">Restwertinserate</span>
                </div>
            </div>

            <!--********** Market Analysis **********-->
            <div
                *ngIf="!createInvoiceAudit"
                class="option-container large-selection-box"
                [ngClass]="{ selected: copyMarketAnalysis }"
                (click)="copyMarketAnalysis = !copyMarketAnalysis"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <mat-icon class="option-image">euro</mat-icon>
                <div class="option-title-container">
                    <span class="option-title" matTooltip="Marktanalyse">Marktanalyse</span>
                </div>
            </div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Optional Data -->
    <!--============================================-->

    <!--============================================-->
    <!-- Report Token -->
    <!--============================================-->
    <section id="report-token-section" *ngIf="createAmendmentReport || createInvoiceAudit">
        <h3>
            Aktenzeichen
            <span
                class="help-indicator"
                matTooltip="Du kannst das Aktenzeichen für {{
                    createAmendmentReport ? 'den Nachtrag ' : 'die Rechnungsprüfung'
                }} aus dem bisherigen Aktenzeichen ableiten lassen.">
                ?
            </span>
        </h3>

        <!--********** Copy reportToken **********-->
        <div id="report-activation-settings">
            <div id="report-activation-checkbox-container">
                <mat-checkbox
                    [(ngModel)]="copyReportToken"
                    matTooltip="Soll das Aktenzeichen {{
                        createAmendmentReport ? 'des Nachtrag' : 'der Rechnungsprüfung'
                    }} gleich dem Original-Gutachten (plus einem optionalen Suffix) sein?{{
                        '\n\n'
                    }}Falls du eine fortlaufende Nummer verwenden möchtest, generiere sie direkt im Gutachten.">
                    Aktenzeichen von Gutachten übernehmen
                </mat-checkbox>
                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="invoiceActivationSubmenu">more_vert</mat-icon>
                <mat-menu #invoiceActivationSubmenu>
                    <a
                        mat-menu-item
                        (click)="rememberReportTokenSettings()"
                        [matTooltip]="
                            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : ''
                        "
                        [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                        Einstellung merken
                    </a>
                    <a
                        mat-menu-item
                        (click)="rememberSuffix(reportTokenSuffix, 'reportTokenSuffix')"
                        [matTooltip]="
                            hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? 'Suffix für künftige Kopiervorgänge merken'
                                : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                        "
                        [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                        Suffix Merken
                    </a>
                </mat-menu>
            </div>
        </div>

        <div *ngIf="copyReportToken" class="multiple-inputs-grid">
            <!--********** Suffix & Remember Option **********-->
            <div class="display-flex flex-align-center">
                <mat-form-field>
                    <input matInput [(ngModel)]="reportTokenSuffix" placeholder="Suffix" />
                </mat-form-field>
            </div>

            <!--********** Token Preview **********-->
            <div class="info-note">
                <div>
                    <div class="label">Vorschau</div>
                    <div
                        *ngIf="sourceReport.token"
                        matTooltip="Dieses Aktenzeichen wird der Gutachtenkopie zugewiesen.">
                        {{ getReportTokenWithSuffix() }}
                    </div>
                    <div *ngIf="!sourceReport.token" class="label italic">
                        Aktenzeichen fehlt im Quellgutachten. Das Feld bleibt leer.
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Report Token -->
    <!--============================================-->

    <!--============================================-->
    <!-- Invoice -->
    <!--============================================-->
    <section id="invoice-number-options-section" *ngIf="createAmendmentReport || createInvoiceAudit">
        <h3>Rechnung</h3>
        <div id="invoice-activation-settings">
            <!--********** Activate invoice **********-->
            <div id="invoice-activation-checkbox-container">
                <mat-checkbox
                    [(ngModel)]="activateInvoice"
                    matTooltip="Soll eine Rechnung für {{
                        createAmendmentReport ? 'den Nachtrag' : 'die Rechnungsprüfung'
                    }} geschrieben werden?{{
                        '\n\n'
                    }}Diese Einstellung kannst du jederzeit im Reiter Rechnung anpassen.">
                    Rechnung aktivieren
                </mat-checkbox>
                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="invoiceActivationSubmenu">more_vert</mat-icon>
                <mat-menu #invoiceActivationSubmenu>
                    <a
                        mat-menu-item
                        (click)="rememberInvoiceActivationSettings()"
                        [matTooltip]="
                            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : ''
                        "
                        [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                        Einstellung merken
                    </a>
                    <a
                        mat-menu-item
                        (click)="rememberSuffix(invoiceNumberSuffix, 'invoiceNumberSuffix')"
                        [matTooltip]="
                            hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? 'Suffix für künftige Kopiervorgänge merken'
                                : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                        "
                        [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                        Suffix Merken
                    </a>
                </mat-menu>
            </div>

            <!--********** Copy invoice number **********-->
            <mat-checkbox
                [(ngModel)]="copyInvoiceNumber"
                *ngIf="activateInvoice"
                matTooltip="Soll die Rechnungsnummer {{
                    createAmendmentReport ? 'des Nachtrags' : 'der Rechnungsprüfung'
                }} gleich der des Original-Gutachtens (plus einem optionalen Zusatz) sein?{{
                    '\n\n'
                }}Falls du eine fortlaufende Nummer oder eine Nummer mit Unterzähler verwenden möchtest, deaktiviere diese Option und generiere sie direkt im Gutachten.">
                Rechnungsnummer von Gutachten + Suffix
            </mat-checkbox>
        </div>

        <div class="multiple-inputs-grid" *ngIf="activateInvoice && copyInvoiceNumber">
            <!--********** Suffix & Remember Option **********-->
            <div class="display-flex flex-align-center">
                <mat-form-field>
                    <input matInput [(ngModel)]="invoiceNumberSuffix" placeholder="Suffix" />
                </mat-form-field>
            </div>

            <!--********** Invoice Number Preview **********-->
            <div class="info-note">
                <div>
                    <div class="label">Vorschau</div>
                    <div
                        *ngIf="sourceReport.feeCalculation.invoiceParameters.number"
                        matTooltip="Diese Rechnungsnummer wird der Gutachtenkopie zugewiesen.">
                        {{ getInvoiceNumberWithSuffix() }}
                    </div>
                    <div *ngIf="!sourceReport.feeCalculation.invoiceParameters.number" class="label italic">
                        Rechungsnummer fehlt im Quellgutachten. Das Feld bleibt leer.
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Invoice -->
        <!--============================================-->
    </section>
    <!--============================================-->
    <!-- Buttons -->
    <!--============================================-->
    <div class="dialog-buttons-container">
        <button (click)="startCopyProcess()">
            <img
                class="pending-icon"
                *ngIf="copyInProgress"
                src="/assets/images/icons/loading-indicator-white.svg"
                alt=""
                @slideInAndOutHorizontally />
            <ng-container [ngSwitch]="currentCopyStep">
                <ng-container *ngSwitchCase="'reportObject'">Vorgang kopieren...</ng-container>
                <ng-container *ngSwitchCase="'photos'">Fotos kopieren...</ng-container>
                <ng-container *ngSwitchCase="'calculation'">Kalkulation kopieren...</ng-container>
                <ng-container *ngSwitchCase="'carEquipment'">Ausstattungen kopieren...</ng-container>
                <ng-container *ngSwitchCase="'userUploadedDocuments'">Eigene Dokumente kopieren...</ng-container>
                <ng-container *ngSwitchCase="'savingReport'">Aufräumen...</ng-container>
                <ng-container *ngSwitchDefault>Erstellen</ng-container>
            </ng-container>
        </button>
    </div>
    <!--============================================-->
    <!-- END Buttons -->
    <!--============================================-->
</div>

import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SecurityContext,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FabricImage, FabricObject } from 'fabric';
import JSZip from 'jszip';
import { clamp } from 'lodash-es';
import { FileUploader } from 'ng2-file-upload';
import { FileItem } from 'ng2-file-upload/file-upload/file-item.class';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Photo, PhotoConfiguration } from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { ThumbnailSize } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import {
    createLicensePlateRedactionFabricPolygon,
    hasLicensePlateRedactionPolygonAlreadyBeenAdded,
} from 'src/app/shared/services/license-plate-redaction/license-plate-redaction-model-output.utils';
import { LicensePlateRedactionService } from 'src/app/shared/services/license-plate-redaction/license-plate-redaction.service';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import {
    PromptDialogComponent,
    PromptDialogData,
    PromptDialogReturnValue,
} from '../../../../shared/components/prompt-dialog/prompt-dialog.component';
import { PositionChangeEvent } from '../../../../shared/directives/muuri/muuri-grid.directive';
import { addWatermarkToPhoto } from '../../../../shared/libraries/fabric/ts/add-watermark-to-all-photos';
import { isSmallScreen, isTouchOnly } from '../../../../shared/libraries/is-small-screen';
import { getPhotoFromFile } from '../../../../shared/libraries/photos/get-photo-from-file';
import { ResizePhotoResult, resizePhoto } from '../../../../shared/libraries/photos/resize-photo';
import { preventContextMenuOnTouchDevices } from '../../../../shared/libraries/prevent-context-menu-on-touch-devices';
import { removeInvalidFilenameCharacters } from '../../../../shared/libraries/remove-invalid-filename-characters';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { AXRESTClient } from '../../../../shared/services/ax-restclient';
import { DownloadService } from '../../../../shared/services/download.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { OriginalPhotoService, PhotoUpload } from '../../../../shared/services/original-photo.service';
import { PhotoMuuriGridService } from '../../../../shared/services/photo-muuri-grid.service';
import { RenderedPhotoFileService } from '../../../../shared/services/rendered-photo-file.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { ReportService } from '../../../../shared/services/report.service';
import { RestorePhotosFromReportService } from '../../../../shared/services/restore-photos-from-report.service';
import { TeamService } from '../../../../shared/services/team.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';
import { UserService } from '../../../../shared/services/user.service';
import { WatermarkImageFileService } from '../../../../shared/services/watermark-image-file.service';
import { LicensePlateRedactionInfoDialogComponent } from '../../photos/license-plate-redaction-info-dialog/license-plate-redaction-info-dialog.component';
import { getDefaultFilters } from '../../photos/photo-editor/photo-editor.filters';

const DELETION_ANIMATION_DURATION = 200; // ms

@Component({
    selector: 'photo-grid',
    templateUrl: 'photo-grid.component.html',
    styleUrls: ['photo-grid.component.scss'],
    animations: [
        trigger('deletePhoto', [
            state('false', style({})),
            state(
                'true',
                style({
                    opacity: 0,
                    transform: 'scale(0.3)',
                }),
            ),
            transition('* => *', animate(`${DELETION_ANIMATION_DURATION}ms`)),
        ]),
        fadeInAndOutAnimation(),
    ],
})
export class PhotoGridComponent implements OnChanges, OnInit {
    constructor(
        private toastService: ToastService,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
        private apiErrorService: ApiErrorService,
        private photoMuuriGridService: PhotoMuuriGridService,
        private downloadService: DownloadService,
        private watermarkImageFileService: WatermarkImageFileService,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private originalPhotoService: OriginalPhotoService,
        private renderedPhotoFileService: RenderedPhotoFileService,
        private reportService: ReportService,
        public userPreferences: UserPreferencesService,
        private dialog: MatDialog,
        private ngZone: NgZone,
        private restorePhotosService: RestorePhotosFromReportService,
        private licensePlateRedactionService: LicensePlateRedactionService,
        private reportDetailsService: ReportDetailsService,
        private userService: UserService,
    ) {
        if (!this.thumbnailSize) {
            /**
             * The default thumbnail size can't be queried if the user loads the page anew. That happens, for example, when a photo file is missing during document
             * generation and the photo grid is loaded without cached photos.
             */
            this.thumbnailSize =
                (isSmallScreen() ? this.userPreferences.thumbnailSizeMobile : this.userPreferences.thumbnailSize) ||
                'medium';
        }
    }

    @ViewChild('licensePlatRedactionColorpicker') licensePlatRedactionColorpicker?: ElementRef<HTMLInputElement>;

    // This is a parameter of the parent component
    @Input() report: Report;

    // Reference to the photos array
    @Input() photos: Photo[] = [];

    @Input() gridToolbarShown: boolean;
    @Input() floatingActionButtonShown: boolean;
    @Input() photoVersion: PhotoGroupName = 'report';
    @Input() photoGroupReportAvailable: boolean = true;
    @Input() photoGroupResidualValueExchangeAvailable: boolean;
    @Input() photoGroupRepairConfirmationAvailable: boolean;
    @Input() photoGroupExpertStatementAvailable: boolean;
    @Input() photoGroupFilterShown: boolean = true;
    @Input() allowCarRegistrationScan: boolean = false;
    @Input() zipDownloadFilename: string;
    @Input() disabled: boolean;
    // Usually set to true while the photo editor is open so the user does not accidentally hit any keyboard shortcuts in the photos component.
    @Input() disableKeyboardShortcuts: boolean;
    @Input() thumbnailSize: ThumbnailSize;
    // Set to true if the photo grid has very little space in the user interface such as in the expert statement.
    @Input() miniMode: boolean = false;

    @Output() photoChange = new EventEmitter<{
        callback?: (photos: Photo[]) => Promise<void>;
        photos: Photo[];
    }>();
    @Output() photoDeletion: EventEmitter<Photo[]> = new EventEmitter<Photo[]>();
    @Output() clickWhileDisabled: EventEmitter<void> = new EventEmitter<void>();
    @Output() carRegistrationScannerClick: EventEmitter<Photo> = new EventEmitter<Photo>();
    @Output() openPhotoEditor: EventEmitter<{ photo: Photo; photoGroup: PhotoGroupName }> = new EventEmitter<{
        photo: Photo;
        photoGroup: PhotoGroupName;
    }>();

    protected user: User;
    protected team: Team;

    public selectedPhotos: Photo[] = [];
    public previouslySelectedPhoto: Photo;

    // Title Edit Mode
    public photoInTitleEditMode: Photo;

    public set filterBy(value: PhotoGroupName) {
        this.photoVersion = value;
        // Reload all thumbnails when switching photo groups because the thumbnails may be different.
        this.initializePhotoThumbnailFiles();
    }

    public get filterBy(): PhotoGroupName {
        return this.photoVersion;
    }

    public numberOfDownloadingPhotos: number = null;
    public downloadedPhotos: { filename: string; blob: Blob }[] = [];
    // Contains an error message per photo why it was not rendered/downloaded (if it was not rendered/downloaded).
    public photoDownloadProblems = new Map<Photo['_id'], { title: string; body: string }>();

    // Keep track of photos that are being deleted. Necessary for animation triggers.
    public photoDeletionsInProgress: Map<string, Photo> = new Map();

    // Becomes true when photos are dragged to rearrange their position. This must disable the drop-listener
    // for uploading new photos, otherwise the photos would be duplicated.
    public firstMuuriLayoutCycleFinished: boolean = false;
    public isPhotoBeingRearranged: boolean;
    public reorderingInputVisible: boolean;
    public reorderingTargetIndex: number;
    @ViewChild('reorderingInput') public reorderingInput: ElementRef<HTMLInputElement>;

    public defaultPhotoDescriptionsEditorShown: boolean = false;
    public defaultPhotoDescriptions: string[] = [];
    public photosJustNamed: Photo[] = [];

    // Contains a reference to a timeout which scrolls the page when the user moves the cursors to the very top or bottom
    // of the page while dragging a photo. Only one scroll may be active at a time to make scrolling smooth.
    public scrollTimeoutCache: number;
    // Sometimes, the detection of the onDragEnd event does not work correctly. Thus, we should remove the upload after a second
    // of no other onDragOver event on the body
    public fileOverBodyTimeoutCache: number;

    // Becomes true when the user drags files over the drop zone
    public fileIsOverDropZone: boolean = false;
    // Becomes true when the user drags files over the window. The drop zone can then be shown.
    public fileIsOverBody: boolean = false;
    // Import photos by means of drag & drop or a browser files dialog. This is not used for uploading.
    public photoFileSelector: FileUploader;
    @ViewChild('multipleFileUpload', { static: false }) fileUploadInput: ElementRef<HTMLInputElement>;

    private localThumbnailSafeFileUrlConfigs = new Map<Photo['_id'], LocalThumbnailSafeFileUrlConfig>();

    /**
     * When photos get deleted, we display a small trash icon (restores photos when clicked).
     * This flag triggers a CSS animation that lets the trash can jump a little after a photo got deleted.
     */
    protected isTrashIconAnimationRunning = false;

    /**
     * We keep a list of photos that the user just deleted. These can be restored as long as the
     * user is on the current page.
     */
    protected deletedPhotos: Photo[] = [];

    private photoRestorationInProgress: boolean = false;

    /** License plate redaction */
    protected licensePlateRedactionsQueued: Photo[] = [];
    protected licensePlateRedactionsProcessed: Photo[] = [];
    protected licensePlateRedactionsModelLoading = false;
    protected licensePlateRedactionsFilterGroupPulsing = false;

    private subscriptions: Subscription[] = [];
    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        /**
         * It's important that these load first since any async calls like clearing local thumbnails causes the template
         * to re-render and the template relies on these values to be present.
         */
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        this.initializeUploader();

        // In case the user needs to find corrupt photo uploads, force loading the photos from the server. This should be removed as soon as
        // autoiXpert is offline-ready because the photos will be stored locally and can be re-uploaded when an error on the server is detected.
        if (this.route.parent.snapshot.queryParams['forceLoadingPhotosFromServer']) {
            await this.renderedPhotoFileService.clearLocalThumbnails(this.report._id);
        }

        if (this.route.snapshot.queryParams['fotogruppe']) {
            this.filterBy = this.translatePhotoGroup(this.route.snapshot.queryParams['fotogruppe']);
        }

        // Relevant for team preferences about adding watermark to uploaded photos.
        this.subscriptions.push(
            this.loggedInUserService.getTeam$().subscribe({
                next: (team) => {
                    this.team = team;
                },
            }),
        );

        this.listenToWebsocketPatchEvents();
        this.loadThumbnailWhenInitialResizeCompletes();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (typeof changes['photos'] !== 'undefined') {
            if (this.route.parent.snapshot.queryParams['selectedPhotos']) {
                const photoIdsToBeSelected = this.route.parent.snapshot.queryParams['selectedPhotos'].split(',');
                this.selectedPhotos = this.photos.filter((photo) => photoIdsToBeSelected.includes(photo._id));
            }
        }
    }

    /**
     * Small animation of the trash icon that tells the user that a photo just got deleted. Clicking the trash icon
     * restores the deleted image. In case the user deleted multiple images, a prompt asks how many of them should
     * be restored.
     */
    protected showPhotoDeletedAnimation(): void {
        if (this.isTrashIconAnimationRunning) {
            return;
        }

        this.isTrashIconAnimationRunning = true;

        setTimeout(() => {
            this.isTrashIconAnimationRunning = false;
        }, 1600);
    }

    /**
     * Opens a dialog that asks the user how many of the previously deleted images should be restored.
     */
    protected async openNumberOfPhotosToRestoreDialog(): Promise<void> {
        if (this.disabled) {
            return;
        }

        const decision = await this.dialog
            .open<PromptDialogComponent, PromptDialogData, PromptDialogReturnValue>(PromptDialogComponent, {
                data: {
                    heading: 'Fotos wiederherstellen',
                    content: `Du hast zuletzt ${this.deletedPhotos.length} Fotos gelöscht. Wie viele davon möchtest du wiederherstellen?`,
                    placeholder: 'Die letzten…',
                    initialInputValue: this.deletedPhotos.length + '',
                    confirmLabel: 'Wiederherstellen',
                    cancelLabel: 'Abbrechen',
                },
            })
            .afterClosed()
            .toPromise();

        if (decision?.confirmed) {
            const parsedUserInput = parseInt(decision.userInput, 10) ?? this.deletedPhotos.length;
            const numberOfPhotos = clamp(parsedUserInput, 0, this.deletedPhotos.length);
            this.restorePhotos(numberOfPhotos);
        }
    }

    /**
     * Restore the last X (numberOfPhotos) deleted photos.
     */
    protected async restorePhotos(numberOfPhotosToRestore: number): Promise<void> {
        if (numberOfPhotosToRestore <= 0 || this.disabled || this.photoRestorationInProgress) {
            return;
        }

        const photosToRestore = this.deletedPhotos.slice(-numberOfPhotosToRestore);

        try {
            this.photoRestorationInProgress = true;

            const { restoredPhotos } = await this.restorePhotosService.restorePhotos({
                reportId: this.report._id,
                photosToRestore,
            });

            // Reset the deleted photos array, because these just got restored
            this.deletedPhotos = [];

            // Restore photo metadata in database
            this.report.photos.push(...restoredPhotos);

            // And finally save it
            await this.saveReport(this.report);

            this.toastService.success(
                `${restoredPhotos.length} ${numberOfPhotosToRestore === 1 ? 'Foto' : 'Fotos'} wiederhergestellt`,
            );
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    RESTORING_DELETED_PHOTOS_FAILED: (error) => ({
                        title: 'Fehler beim Wiederherstellen',
                        body: `${error.data.restoredPhotos.length} von ${numberOfPhotosToRestore} ${numberOfPhotosToRestore === 1 ? 'Foto' : 'Fotos'} wiederhergestellt.`,
                        // Log to Sentry.
                        forceConsiderErrorHandled: false,
                    }),
                },
                defaultHandler: {
                    title: 'Fehler beim Wiederherstellen der Fotos',
                    body: `Fotos konnten nicht wiederhergestellt werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        } finally {
            this.photoRestorationInProgress = false;
        }
    }

    public markFirstMuuriLayoutCycleFinished() {
        this.ngZone.run(() => {
            this.firstMuuriLayoutCycleFinished = true;
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    public togglePermanentDescription() {
        this.userPreferences.alwaysDisplayPhotoDescription = !this.userPreferences.alwaysDisplayPhotoDescription;
    }

    //*****************************************************************************
    //  Photo Upload
    //****************************************************************************/
    /**
     * Load all photo thumbnails from the local cache or the server and save them to local blobs.
     */
    private initializePhotoThumbnailFiles(): void {
        this.photos.forEach((photo) => {
            this.loadPhotoThumbnailFile(photo).catch((error) =>
                console.error('Error initializing photo thumbnails.', error),
            );
        });
    }

    private initializeUploader(): void {
        this.photoFileSelector = new FileUploader({
            url: AXRESTClient.marryToBaseUrl(`/reports/${this.report._id}/photoFiles`),
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
        });

        // After selecting photos for the upload, sync the report object first; then upload the binaries.
        this.photoFileSelector.onAfterAddingAll = async () => {
            // Don't upload if report is locked
            if (this.isGridDisabled()) {
                this.toastService.warn('Gutachten bereits abgeschlossen', 'Hochladen nicht möglich');
                this.photoFileSelector.clearQueue();
                return;
            }

            const numberOfSelectedPhotos = this.photoFileSelector.queue.length;

            // Don't let the user upload more than 200 photos at once
            if (numberOfSelectedPhotos > 200) {
                this.toastService.warn('Zu viele Fotos', 'Du kannst höchstens 200 Fotos auf einmal hochladen.');
                this.photoFileSelector.clearQueue();
                return;
            }

            // If more than 100 photos selected -> notify user and ask for confirmation
            if (numberOfSelectedPhotos > 100) {
                const confirmPhotoUpload = await this.confirmUploadOfLargeAmountOfPhotos(
                    this.photoFileSelector.queue.length,
                );

                if (!confirmPhotoUpload) {
                    // Reset the queue, because the user chose to cancel the upload
                    this.photoFileSelector.clearQueue();
                    return;
                }
            }

            //*****************************************************************************
            //  File Upload Order
            //****************************************************************************/
            //console.log("Current queue", this.photoFileSelector.queue.map(queueItem => (queueItem.file.rawFile as unknown as File).lastModified));

            /**
             * Sort photos on desktop, but not on mobile devices.
             *
             * DESKTOP
             * Sort the upload array alphabetically by filename. That's important since the browser/file explorer sends the file list with the photo on position 1 that's
             * currently being dragged by the user. The user, however, would expect the photos to be added alphabetically (sorted by the order in which the photos were made).
             *
             * MOBILE - APPLE
             * Apple devices remove timestamps and image names when uploading files, therefore we cannot know which sort order makes sense.
             * Photos are uploaded in the order they have been selected in the media library.
             *
             * MOBILE - ANDROID
             * Android photos start with "IMG_" and can be sorted just like on Windows.
             */

            const isAndroidFilenamePattern = this.photoFileSelector.queue.every((item) => {
                return item.file.name.startsWith('IMG_');
            });
            const isDesktop = !isTouchOnly(); // = no phone, no tablet.
            if (isDesktop || isAndroidFilenamePattern) {
                /**
                 * If the user names his photos "1.jpg", "2.jpg", "3.jpg", ..., "11.jpg", "12.jpg", Windows already sorts those photos as humans expect them:
                 * 1, 2, ..., 11, 12. However, the regular algorithm when using localeCompare() would result in 1, 11, 12, 2, 3, ... which is not what we want.
                 * Handle that special case.
                 * Works with filenames like "1 - vorne links.jpg", too.
                 */
                const allFilenamesStartWithNumbers = this.photoFileSelector.queue.every((item) => {
                    return item.file.name.substring(0, item.file.name.lastIndexOf('.')).match(/^(\d+).*/);
                });
                if (allFilenamesStartWithNumbers) {
                    this.photoFileSelector.queue.sort((item1, item2) => {
                        return +item1.file.name.replace(/^(\d+).*/, '$1') - +item2.file.name.replace(/^(\d+).*/, '$1');
                    });
                } else {
                    this.photoFileSelector.queue.sort((item1, item2) => item1.file.name.localeCompare(item2.file.name));
                }
            }

            //console.log("Queue after sort", this.photoFileSelector.queue.map(queueItem => (queueItem.file.rawFile as unknown as File).lastModified));
            /////////////////////////////////////////////////////////////////////////////*/
            //  END File Upload Order
            /////////////////////////////////////////////////////////////////////////////*/

            const photosWaitingForUpload: { photo: Photo; item: FileItem }[] = [];

            //*****************************************************************************
            //  Add Photo Objects to Report
            //****************************************************************************/
            /**
             * Validate queue and add empty photo placeholders to UI. This ensures that the user get immediate visual feedback
             * even though rendering the photos may take longer.
             */
            for (let index = 0; index < this.photoFileSelector.queue.length; index++) {
                const item: FileItem = this.photoFileSelector.queue[index];

                // Of course, we don't have to update the same photo multiple times.
                if (item['photo'] || item.isReady || item.isUploading || item.isUploaded) {
                    continue;
                }
                // If the mime type is neither png nor jpeg, remove the image from the queue
                if (!['image/jpeg', 'image/jpg'].includes(item._file.type)) {
                    console.error('The given file is not a jpeg image.', item);
                    this.toastService.error(
                        'Kein JPEG',
                        `Die Datei "${item._file.name}" ist keine JPEG-Datei. Es werden aber nur JPEG-Dateien unterstützt.`,
                    );
                    this.photoFileSelector.queue.splice(index, 1);
                    continue;
                }

                let photo: Photo;
                try {
                    photo = await getPhotoFromFile(item._file, this.photos.length);
                } catch (error) {
                    /**
                     * For unknown reasons, the photo file may be corrupt. This prevents the photo object to be read from the file because the photo dimensions need to be read via loading the photo
                     * into an HTML Image instance.
                     */
                    console.error(
                        `The photo "${item._file?.name}" seems to be broken since it could not be read from the uploaded file. This is usually caused by a corrupt photo file.`,
                        { error },
                    );
                    this.toastService.error(
                        `Defektes Foto`,
                        `Das Foto "${item._file?.name}" kann nicht verarbeitet werden.<br><br>Bitte lösche das Foto und lade es erneut hoch.<br>
                        Sollte das Foto wieder nicht verarbeitet werden können, öffne es in einem Bildbearbeitungsprogramm (z. B. "Paint" auf Windows), ändere eine Kleinigkeit (z. B. auf 99 % der Größe reduzieren) und speichere es erneut ab. Lade dieses bearbeitete Foto dann neu hoch.

                        Falls du ein iPhone nutzt, fotografiere nicht im High Efficiency-Format (HEIC), sondern direkt in JPEG. <a href="https://wissen.autoixpert.de/hc/de/articles/11867524437522" target="_blank" rel="noopener">So geht's</a>.`,
                    );
                    continue;
                }

                const photoConfiguration: PhotoConfiguration = new PhotoConfiguration();
                // If a specific photo group is selected, only add the photo to that group
                switch (this.filterBy) {
                    case 'report':
                        photoConfiguration.report.included = true;
                        photoConfiguration.residualValueExchange.included = true;
                        break;
                    case 'residualValueExchange':
                        photoConfiguration.residualValueExchange.included = true;
                        break;
                    case 'repairConfirmation':
                        photoConfiguration.repairConfirmation.included = true;
                        break;
                    case 'expertStatement':
                        photoConfiguration.expertStatement.included = true;
                        break;
                }

                photo.versions = photoConfiguration;
                /**
                 * Ensure the component knows when a photo was initially resized to a width of 3000 pixels and is available in the local IndexedDB. This
                 * way, the component does not even have to try (and fail) to load the image before it's available on the server or in the IndexedDB.
                 */
                this.originalPhotoService.photosWaitingForInitialResizingOrIndexeddb.add(photo._id);
                this.photos.push(photo);

                // Save a reference to the photo so that the event handler onSuccess can be set again after the user navigated away from this view and returned.
                item['photo'] = photo;

                photosWaitingForUpload.push({
                    photo,
                    item,
                });
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Add Photo Objects to Report
            /////////////////////////////////////////////////////////////////////////////*/

            // Happens if the user tried to upload .raw or .png photo files. autoiXpert only accepts .jpeg files and removes all other formats from the queue.
            if (!this.photoFileSelector.queue.length) {
                return;
            }

            //*****************************************************************************
            //  Reduce Image Size & Save Photos to IndexedDB
            //****************************************************************************/
            // This is a cache. Only create one object per upload batch.
            let watermarkImageUrl: string;

            // Reduce image size, start upload and render thumbnails
            for (let index = 0; index < photosWaitingForUpload.length; index++) {
                const photo: Photo = photosWaitingForUpload[index].photo;

                let resizedOriginalImageResult: ResizePhotoResult;
                try {
                    /**
                     * Reduce the image size so that the image upload now and rendering photos later works fast.
                     */
                    resizedOriginalImageResult = await resizePhoto({
                        photoFileOrBlob: photosWaitingForUpload[index].item._file,
                        targetWidth: 3000,
                    });
                } catch (error) {
                    /**
                     * For unknown reasons, the photo file may be corrupt. This causes the resize algorithm to fail. In that case, tell the user and skip rendering this photo
                     */
                    console.error(
                        `The photo "${photosWaitingForUpload[index]?.item?._file?.name}" seems to be broken since it could not be resized. This is usually caused by a corrupt photo file.`,
                        { error },
                    );
                    // If the error is an AxError, it may have a toJSON method with which we may investigate the issue further.
                    const errorMessage: string = `${error} - ${error.toJSON ? error.toJSON() : ''}`;
                    this.toastService.error(
                        `Defektes Foto`,
                        `Das Foto "${photosWaitingForUpload[index]?.item?._file?.name}" kann nicht verkleinert werden.<br><br>Bitte lösche das Foto und lade es erneut hoch.<br><br><strong>Technische Fehlermeldung:</strong> ${errorMessage}`,
                    );

                    // Remove invalid photo from the upload queue and the report.
                    this.photoFileSelector.queue.splice(
                        this.photoFileSelector.queue.findIndex(
                            (fileItem) => fileItem === photosWaitingForUpload[index].item,
                        ),
                        1,
                    );
                    photosWaitingForUpload.splice(index, 1);
                    this.photos.splice(
                        this.photos.findIndex((reportPhoto) => reportPhoto._id === photo._id),
                        1,
                    );

                    continue;
                }
                photo.height = resizedOriginalImageResult.dimensions.height;
                photo.width = resizedOriginalImageResult.dimensions.width;
                photo.size = resizedOriginalImageResult.photoBlob.size;

                const team: Team = this.loggedInUserService.getTeam();
                if (!team) {
                    console.warn('Team missing from Photos Component when determining whether to place a watermark.');
                }

                // Insert the watermark after resizing the upload photo to ensure that the scaling factors work correctly.
                if (team?.preferences.watermark.type && team.preferences.watermark.insertAutomatically) {
                    if (!team.preferences.watermark.type) {
                        this.toastService.error(
                            'Kein Wasserzeichen gesetzt',
                            'Bitte vervollständige die Einstellungen des Wasserzeichens über den Foto-Editor.',
                        );
                    } else {
                        let watermarkImageBlob: Blob;

                        if (team.preferences.watermark.type === 'image') {
                            if (!watermarkImageUrl) {
                                try {
                                    watermarkImageBlob = await this.watermarkImageFileService.get(
                                        team._id,
                                        team.preferences.watermark.imageHash,
                                    );
                                    watermarkImageUrl = window.URL.createObjectURL(watermarkImageBlob);
                                } catch (error) {
                                    if (error.code === 'WATERMARK_IMAGE_NOT_FOUND_LOCALLY_AND_CLIENT_OFFLINE') {
                                        this.toastService.warn(
                                            'Wasserzeichen offline nicht gefunden',
                                            'Stelle eine Internetverbindung her, um das Wasserzeichen abzurufen.<br><br>Fotos werden bis auf Weiteres ohne Wasserzeichen hochgeladen.',
                                        );
                                    } else {
                                        this.toastService.warn(
                                            'Wasserzeichen nicht gefunden',
                                            'Um das Wasserzeichen wieder automatisch einfügen zu lassen, lade bitte das Wasserzeichen-Bild erneut über den Foto-Editor hoch.<br><br>Fotos werden bis auf Weiteres ohne Wasserzeichen hochgeladen.',
                                        );
                                    }

                                    this.team.preferences.watermark.insertAutomatically = false;
                                    try {
                                        await this.teamService.put(this.team);
                                    } catch (error) {
                                        this.toastService.error(
                                            'Wasserzeichen-Einstellungen',
                                            'Dass das Wasserzeichen nicht automatisch eingefügt wird, konnte nicht gespeichert werden.',
                                        );
                                    }
                                }
                            }
                        }

                        /**
                         * The automatic insertion of the watermark image may have been set to false if the watermark image could not be found locally or on the server,
                         * so check again here before adding the watermark.
                         */
                        if (this.team.preferences.watermark.insertAutomatically) {
                            await addWatermarkToPhoto({
                                photo,
                                watermarkPreferences: team.preferences.watermark,
                                watermarkImageUrl,
                            });
                        }
                    }
                }

                // Save the size-reduced photo file to local database service.
                this.originalPhotoService
                    .create({
                        _id: `${this.report._id}-${photo._id}`,
                        blob: resizedOriginalImageResult.photoBlob,
                    })
                    .then(() => {
                        this.originalPhotoService.photosWaitingForInitialResizingOrIndexeddb.delete(photo._id);
                    });

                // Remove item from photo file selector's queue.
                const uploadItem = this.getItemFromQueue(photo._id);
                removeFromArray(uploadItem, this.photoFileSelector.queue);
            }

            // If a watermark image URL was used, clear its resources so that the browser does not need to hold them until the document is closed.
            if (watermarkImageUrl) {
                window.URL.revokeObjectURL(watermarkImageUrl);
            }

            // Let the parent component know that the photos array changed. The parent component will save the report.
            this.emitPhotoChange();
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Reduce Image Size & Save Photos to IndexedDB
            /////////////////////////////////////////////////////////////////////////////*/
        };
    }

    /**
     * This function marks all selected photos as
     */
    public async forceUploadSelectedPhotos() {
        let numberOfForceUploadedPhotos: number = 0;
        for (const photo of this.selectedPhotos) {
            const originalPhotoId: string = `${this.report._id}-${photo._id}`;
            if (!(await this.originalPhotoService.localDb.existsLocal(originalPhotoId))) {
                this.toastService.warn(
                    'Foto nicht hochgeladen',
                    `Das Original des Fotos "${
                        photo.title || photo.originalName
                    }" existiert nicht lokal und konnte nicht hochgeladen werden. Bitte lösche es und nimm es erneut auf.`,
                );
                continue;
            }

            await this.originalPhotoService.localDb.deleteDeletionChangeRecord(originalPhotoId);
            await this.originalPhotoService.localDb.setCreationChangeRecord(originalPhotoId);
            numberOfForceUploadedPhotos++;
        }

        try {
            await this.originalPhotoService.pushToServer();
            this.toastService.success(
                `${numberOfForceUploadedPhotos} Foto${numberOfForceUploadedPhotos === 1 ? '' : 's'} hochgeladen`,
            );
        } catch (error) {
            this.toastService.error(
                'Mindestens ein Foto konnte nicht zum Server gesendet werden. Bitte untersuche die Ursache in den Sync-Problemen oben rechts in autoiXpert oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>',
            );
        }
    }

    /**
     * For large amounts of photos (between 100 - 200), ask the user to confirm the photo upload.
     * This dialog should also try to convince the user to filter out unwanted images before uploading them.
     */
    private async confirmUploadOfLargeAmountOfPhotos(numberOfPhotos: number): Promise<boolean> {
        return await this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    heading: 'Sehr viele Fotos',
                    content: `Du bist dabei ${numberOfPhotos} Fotos hochzuladen. Dies kann je nach Internetgeschwindigkeit eine Weile dauern.\n\nIndem du die Fotos auf deinem Computer vorselektierst, kannst du beim Hochladen in autoiXpert Zeit und Datenvolumen sparen.`,
                    confirmLabel: 'Alle hochladen!',
                    cancelLabel: 'Abbrechen',
                },
            })
            .afterClosed()
            .toPromise();
    }

    public async retryUpload() {
        await this.originalPhotoService.pushToServer();
    }

    /**
     * This method is called from outside this component. It allows external user interface elements to open the file selector for uploading photos.
     */
    public selectFilesForUpload(): void {
        if (this.disabled) {
            this.toastService.info('Gutachten abgeschlossen', 'Bitte entsperre das Gutachten, um Fotos hochzuladen.');
            return;
        }
        this.fileUploadInput.nativeElement.click();
    }

    /**
     * Utility method.
     * @param photoId
     * @private
     */
    private getItemFromQueue(photoId: Photo['_id']): FileItem {
        return this.photoFileSelector.queue.find((item) => item['photo']?._id === photoId);
    }

    public doesThumbnail400DownloadTakeLonger(photoId): boolean {
        return this.renderedPhotoFileService.doesPhotoDownloadTakeLonger(
            this.report._id,
            photoId,
            this.filterBy,
            'thumbnail400',
        );
    }

    //*****************************************************************************
    //  Drag'n'drop photos from external sources, e.g. file system
    //****************************************************************************/
    // Event handler which listens to the mousein and mouseout event if the user drags a file
    public onFileOverDropZone(fileOver: boolean): void {
        if (fileOver === true) {
            this.fileIsOverDropZone = true;
        } else {
            this.fileIsOverDropZone = false;
            this.fileIsOverBody = false;
        }
    }

    public onFileDrop(): void {
        // Disable the drop zone as soon as content is dropped
        this.fileIsOverBody = false;
    }

    public getUploadItem(photoId: Photo['_id']): PhotoUpload {
        return this.originalPhotoService.getPhotoUpload(photoId);
    }

    /**
     * Show drop zone
     */
    @HostListener('body:dragover', ['$event'])
    onFileOverBody() {
        clearTimeout(this.fileOverBodyTimeoutCache);

        this.fileIsOverBody = true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Drag'n'drop photos from external sources, e.g. file system
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Upload
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Thumbnails
    //****************************************************************************/
    /**
     * Return either the local photo URI or null if the photo has not yet been loaded from the server.
     */
    public getLocalThumbnailUrl(photoId: string): SafeResourceUrl {
        return this.localThumbnailSafeFileUrlConfigs.get(photoId)?.safeResourceUrl;
    }

    /**
     * This function is called when the photo editor is closed. The user may have changed fabric.js information through the photo editor.
     */
    public reloadChangedPhotoThumbnails() {
        const safeFileUrlConfigKeyValuePairs: [Photo['_id'], LocalThumbnailSafeFileUrlConfig][] = Array.from(
            this.localThumbnailSafeFileUrlConfigs.entries(),
        );
        for (const [photoId, localThumbnailSafeFileUrlConfig] of safeFileUrlConfigKeyValuePairs) {
            const photo: Photo = this.photos.find((photo) => photo._id === photoId);
            // If the photo was removed in the meantime, remove the safe file URL, too. No re-rendering is necessary since the photo is gone.
            if (!photo) {
                this.localThumbnailSafeFileUrlConfigs.delete(photoId);
                continue;
            }

            const fabricJsInformation = photo.versions[this.filterBy].fabricJsInformation;
            const currentFabricJsInformationHash: string = simpleHash(JSON.stringify(fabricJsInformation));
            if (currentFabricJsInformationHash !== localThumbnailSafeFileUrlConfig.fabricJsInformationHash) {
                this.loadPhotoThumbnailFile(photo).catch((error) =>
                    console.error('Loading a photo thumbnail failed.', { error }),
                );
            }
        }
    }

    /**
     * Release the locally saved image object. This should be called when a new version of the thumbnail was downloaded.
     * @param photoId
     */
    public freeLocalThumbnailUrl(photoId) {
        if (this.localThumbnailSafeFileUrlConfigs.has(photoId)) {
            window.URL.revokeObjectURL(
                this.domSanitizer.sanitize(
                    SecurityContext.RESOURCE_URL,
                    this.localThumbnailSafeFileUrlConfigs.get(photoId).safeResourceUrl,
                ),
            );
            this.localThumbnailSafeFileUrlConfigs.delete(photoId);
        }
    }

    public async loadPhotoThumbnailFile(photo: Photo, retryCount?: number): Promise<void> {
        // If the photo has not been resized down to the autoiXpert standard, it does not exist in the local IndexedDB, so skip the call.
        // The thumbnail will be loaded after
        if (this.isPhotoWaitingForResizingOrIndexeddb(photo._id)) {
            return;
        }

        let renderedPhotoBlob: Blob;
        try {
            renderedPhotoBlob = await this.renderedPhotoFileService.getFile({
                reportId: this.report._id,
                photo,
                version: this.filterBy,
                format: 'thumbnail400',
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    GETTING_WATERMARK_IMAGE_FAILED: () => {
                        this.photoDownloadProblems.set(photo._id, {
                            title: 'Wasserzeichen fehlt',
                            body: 'Hat jemand das Wasserzeichen gelöscht? Lade es erneut hoch oder entferne es von diesem Foto.',
                        });

                        // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                        return {
                            title: '',
                            body: '',
                        };
                    },
                    ORIGINAL_PHOTO_FILE_CANNOT_BE_LOADED_FROM_THE_SERVER_WHILE_OFFLINE: () => {
                        this.photoDownloadProblems.set(photo._id, {
                            title: 'Offline nicht verfügbar',
                            body: 'Dieses Foto kann erst angezeigt werden, wenn du wieder online bist.',
                        });

                        // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                        return {
                            title: '',
                            body: '',
                        };
                    },
                    GETTING_PHOTO_BUFFER_FAILED: () => {
                        // Retry after a short while
                        if (!retryCount) {
                            window.setTimeout(() => this.loadPhotoThumbnailFile(photo, 1), 3000);
                            // Don't show a toast.
                            return {
                                title: '',
                                body: '',
                            };
                        }

                        this.photoDownloadProblems.set(photo._id, {
                            title: 'Original-Foto fehlt',
                            body: 'Bitte markiere es in autoiXpert auf dem Gerät, auf dem du es aufgenommen hast, und lade es über das Sync-Icon unten rechts in der Foto-Toolbar erneut hoch.',
                        });

                        // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                        return {
                            title: '',
                            body: '',
                        };
                    },
                    ERROR_RENDERING_FABRIC_JS_INFORMATION: () => {
                        this.photoDownloadProblems.set(photo._id, {
                            title: 'Fehler bei Formen',
                            body: 'Bitte erstelle/ändere eine Form (Pfeil, Rechteck, ...) auf dem Foto, um die Form-Daten neu zu generieren.',
                        });

                        // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                        return {
                            title: '',
                            body: '',
                        };
                    },
                    PHOTO_DOES_NOT_EXIST_ON_REPORT: () => {
                        this.photoDownloadProblems.set(photo._id, {
                            title: 'Foto in Gutachten nicht bekannt',
                            body: 'Aktualisiere die Seite und deaktiviere den privaten Browser-Modus. Besteht das Problem weiterhin, kontaktiere die Hotline.',
                        });

                        // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                        return {
                            title: '',
                            body: '',
                        };
                    },
                    RENDERING_PHOTO_BLOB_FROM_CANVAS_FAILED: () => {
                        this.photoDownloadProblems.set(photo._id, {
                            title: 'Foto-Vorschau auf Gerät nicht möglich',
                            body: 'Das ist ein technisches Problem mit diesem Gerät. Bitte informiere die Hotline.',
                        });

                        // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                        return {
                            title: '',
                            body: '',
                        };
                    },
                },
                defaultHandler: () => {
                    this.photoDownloadProblems.set(photo._id, {
                        title: 'Unerwarteter Fehler',
                        body: 'Bitte aktualisiere die Seite. Besteht das Problem weiterhin, kontaktiere die Hotline.',
                    });

                    // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                    return {
                        title: '',
                        body: '',
                    };
                },
            });
        }
        this.freeLocalThumbnailUrl(photo._id);
        const localUri = window.URL.createObjectURL(renderedPhotoBlob);
        this.localThumbnailSafeFileUrlConfigs.set(photo._id, {
            fabricJsInformationHash: simpleHash(JSON.stringify(photo.versions[this.filterBy].fabricJsInformation)),
            safeResourceUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(localUri),
        });
        this.photoDownloadProblems.delete(photo._id);
    }

    public isPhotoWaitingForResizingOrIndexeddb(photoId: Photo['_id']): boolean {
        return this.originalPhotoService.photosWaitingForInitialResizingOrIndexeddb.has(photoId);
    }

    public isPhotoInPortraitFormat(photo: Photo): boolean {
        const fabricInformation = photo.versions[this.photoVersion]?.fabricJsInformation;

        // Has the photo been rotated with fabric?
        const angle: 0 | 90 | 180 | -90 =
            fabricInformation?.objects?.find(
                (fabricObject: FabricObject) => fabricObject.type.toLowerCase() === 'image',
            )?.angle ?? 0;

        const originallyLandscape: boolean = photo.width > photo.height;

        if (originallyLandscape) {
            return angle == null || angle === 90 || angle === -90;
        }
        // Originally in portrait mode
        else {
            return angle === 0 || angle === 180;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Thumbnails
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Selection
    //****************************************************************************/

    public clickSelectionMarker(photo: Photo, event: MouseEvent): void {
        // If the user holds down the CTRL key (meta on Mac), don't do anything. The click handler on the photo card will take care of the proper action.
        if (event.ctrlKey || event.metaKey) {
            return;
        }

        // The same goes for the shift key
        if (event.shiftKey) {
            return;
        }

        this.toggleSelect(photo);
    }

    private toggleSelect(photo: Photo): void {
        // If it is not yet included
        if (!this.selectedPhotos.includes(photo)) {
            this.selectedPhotos.push(photo);
            this.previouslySelectedPhoto = photo;
            this.sortSelectedPhotos();
        } else {
            // If it is included
            this.selectedPhotos.splice(this.selectedPhotos.indexOf(photo), 1);
            this.previouslySelectedPhoto = null;
            // Clear the re-ordering input if the last photo has been de-selected
            if (!this.selectedPhotos.length) {
                this.reorderingInputVisible = false;
                this.reorderingTargetIndex = null;
            }
        }
    }

    public selectPhotos(photos: Photo[]): void {
        this.selectedPhotos = [...photos];
        this.sortSelectedPhotos();
    }

    /**
     * Make sure the selected photos are always in the order of their visible positions in the photo grid.
     *
     * Use case: moving multiple photos.
     * If we didn't sort the array by grid position, after moving multiple photos, the last-selected photo would be
     * the last photo in the grid. Goal: We want the selected photo with the previously highest grid position to be
     * the last photo after moving.
     * @private
     */
    private sortSelectedPhotos(): void {
        this.selectedPhotos.sort((photoA, photoB) => (photoA.orderPosition < photoB.orderPosition ? -1 : 1));
    }

    //public revertSortOrder(): void {
    //    this.photos.reverse();
    //    this.emitPhotoChange();
    //}

    public selectAllPhotos(): void {
        // If all photos have already been selected, clear the selection
        if (this.selectedPhotos.length === this.photos.length) {
            this.clearPhotoSelection();
            return;
        }

        // First clear the selection to avoid adding photos a second time.
        this.clearPhotoSelection();

        // Don't assign the same array. Removing an element from the selection would remove it from photos too then.
        this.selectPhotos(this.photos);
    }

    public clearPhotoSelection(): void {
        this.selectedPhotos.length = 0;
    }

    /**
     * Add or remove photo to/from selection if the user pressed the CTRL key when clicking.
     * @param event
     * @param photo
     */
    public toggleSelectionOnCtrlClick(photo: Photo, event: MouseEvent): void {
        if (event.ctrlKey || event.metaKey) {
            this.toggleSelect(photo);
        }

        // While holding down shift, select all photos from the most recently selected photo through the photo just clicked.
        if (event.shiftKey) {
            // Clear the selection first. This is the same behavior as in the Windows Explorer
            this.clearPhotoSelection();

            if (this.previouslySelectedPhoto) {
                const indexOfPreviouslySelectedPhoto = this.photos.indexOf(this.previouslySelectedPhoto);
                const indexOfPhoto = this.photos.indexOf(photo);

                // Get all photos between the previously clicked and the now-clicked photo.
                let photosToBeToggled: Photo[];

                if (indexOfPreviouslySelectedPhoto < indexOfPhoto) {
                    photosToBeToggled = this.photos.slice(indexOfPreviouslySelectedPhoto, indexOfPhoto + 1);
                } else {
                    photosToBeToggled = this.photos.slice(indexOfPhoto, indexOfPreviouslySelectedPhoto + 1);
                }

                for (const photoToBeToggled of photosToBeToggled) {
                    this.toggleSelect(photoToBeToggled);
                }
            }
            // If there isn't a previously selected photo, simply select this one.
            else {
                this.toggleSelect(photo);
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Title
    //****************************************************************************/
    public enterPhotoTitleEditMode(photo: Photo) {
        // Don't allow edits in disabled mode.
        if (this.isGridDisabled()) return;

        this.photoInTitleEditMode = photo;
    }

    public leavePhotoTitleEditMode() {
        this.photoInTitleEditMode = null;
    }

    public leaveTitleEditModeOnEnter(event: KeyboardEvent) {
        switch (event.key) {
            case 'Enter':
                // Trigger change event first
                (event.target as HTMLInputElement).blur();
                this.leavePhotoTitleEditMode();
                event.stopPropagation();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Title
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Duplicate Photos
    //****************************************************************************/
    public getDuplicatePhotos(): Photo[] {
        const duplicates: Photo[] = [];

        this.photos.forEach((photo) => {
            // If the photo is already registered as duplicate, we don't need to match again. All duplicates are already included.
            if (duplicates.includes(photo)) return;

            /**
             * Duplicate = not this photo but same file size
             * comparison of file size is necessary because photos taken on iOS all have the same file name due to privacy reasons
             * and photos from the ios photos library have random generated names
             */
            const duplicatesOfThisPhoto: Photo[] = this.photos.filter(
                (photo2) => photo2 !== photo && photo.size === photo2.size,
            );
            if (duplicatesOfThisPhoto.length) {
                duplicates.push(...duplicatesOfThisPhoto);
            }
        });

        return duplicates;
    }

    /**
     * Regular click: Select all duplicates but not the originals (first photos with the same file size).
     * Shift click: Select all duplicates and the originals.
     */
    protected handleDuplicatePhotosClick(event: MouseEvent): void {
        const duplicates: Photo[] = this.getDuplicatePhotos();
        if (event.shiftKey) {
            const duplicatesAndOriginals: Photo[] = this.photos.filter((photo) =>
                duplicates.some((duplicate) => photo.size === duplicate.size),
            );
            this.selectPhotos(duplicatesAndOriginals);
        } else {
            this.selectPhotos(duplicates);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Duplicate Photos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Groups
    //****************************************************************************/
    public getPhotosIncludedInPictureGroup(group: PhotoGroupName): Photo[] {
        return this.photos.filter((photo) => photo.versions[group].included);
    }

    /**
     * Returns true if at least one photo in the selection is included in the respective group.
     * @param {"report" | "residualValueExchange" | "repairConfirmation" | "expertStatement"} group
     * @returns {boolean}
     */
    public selectedPhotosIncludedInPictureGroup(group: PhotoGroupName): boolean {
        return this.selectedPhotos.filter((photo) => photo.versions[group].included).length > 0;
    }

    /**
     * Toggle whether all selected photos belong to the specified group
     * @param {"report" | "residualValueExchange" | "repairConfirmation" | "expertStatement"} group
     */
    public toggleSelectedPhotosPhotoGroup(group: PhotoGroupName): void {
        // If there are photos in the group, toggle them off. If none is included, add all.
        const targetToggleState = !this.selectedPhotosIncludedInPictureGroup(group);
        for (const selectedPhoto of this.selectedPhotos) {
            selectedPhoto.versions[group].included = targetToggleState;
        }
        this.emitPhotoChange();
    }

    /**
     * Toggle if a single photo belongs to a specific group
     * @param photo
     * @param {"report" | "residualValueExchange" | "repairConfirmation" | "expertStatement"} group
     */
    public togglePhotoGroup(photo: Photo, group: PhotoGroupName): void {
        photo.versions[group].included = !photo.versions[group].included;
        this.emitPhotoChange();
    }

    /**
     * Translate the German term for a photo group to the English term used in the code.
     *
     * @param {string} photoGroupGerman
     * @returns {"report" | "residualValueExchange" | "repairConfirmation" | "expertStatement"}
     */
    public translatePhotoGroup(
        photoGroupGerman: 'Gutachten' | 'Restwertbörse' | 'Reparaturbestätigung' | 'Stellungnahme',
    ): PhotoGroupName {
        switch (photoGroupGerman) {
            case 'Gutachten':
                return 'report';
            case 'Restwertbörse':
                return 'residualValueExchange';
            case 'Reparaturbestätigung':
                return 'repairConfirmation';
            case 'Stellungnahme':
                return 'expertStatement';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Groups
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Change View Type and Size
    //****************************************************************************/
    public setThumbnailSize(size: ThumbnailSize) {
        this.thumbnailSize = size;
        if (this.isSmallScreen()) {
            if (size === 'small' || size === 'medium' || size === 'large') {
                this.userPreferences.thumbnailSizeMobile = size;
            } else {
                this.toastService.error('Foto-Größe für mobile Geräte ungültig', 'Bitte kontaktiere die Hotline.');
            }
        } else {
            this.userPreferences.thumbnailSize = size;
        }

        // Ensure the changed CSS styles are applied before recalculating the muuri dimensions.
        this.changeDetectorRef.detectChanges();

        this.refreshMuuriGrid();
    }

    public setFilterGroup(filterGroup: PhotoGroupName): void {
        this.filterBy = filterGroup;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Change View Type and Size
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Editor
    //****************************************************************************/
    openEditor(photo: Photo, event: MouseEvent): void {
        if (this.isPhotoWaitingForResizingOrIndexeddb(photo._id)) {
            this.toastService.info(
                'Foto wird verarbeitet',
                'Das ausgewählte Foto wird gerade verarbeitet. Versuche es in Kürze erneut oder kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
            );
            return;
        }

        // Only trigger the routing to the editor if the CTRL key has not been pressed. Holding down the CTRL key toggles the photo's selection.
        // The Shift key is also used for selection only.
        if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
            this.emitOpenPhotoEditor(photo);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Editor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Tooltip
    //****************************************************************************/
    public getPhotoTooltip(photo: Photo): string {
        // Let the user see the original name of the photo in the tooltip. That's important in case the user wants to link the rescaled photo in autoiXpert
        // to the original photo in his file system. That might be the case if the insurance asks for the original photo version.
        if (photo.description) {
            return `${photo.description} (${photo.originalName})`;
        } else if (photo.title) {
            return photo.title;
        } else {
            return photo.originalName;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Tooltip
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Registration Scanner
    //****************************************************************************/
    /**
     * Does another photo have a description like "Fahrzeugschein"? If not, we display the scan icon on every photo.
     */
    public atLeastOnePhotoHasCarRegistrationDescription(): boolean {
        return this.photos.some((photo) => this.photoDepictsCarRegistration(photo));
    }

    public photoDepictsCarRegistration(photo: Photo): boolean {
        return /Fahrzeugschein|Zulassung|Fzg\b|-Schein/i.test(photo.description || photo.originalName);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Registration Scanner
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Rearrange photos
    //****************************************************************************/

    public refreshMuuriGrid(): void {
        this.photoMuuriGridService.triggerLayout();
    }

    /**
     * Registers the currently dragged photo
     */
    onDragStart(): void {
        this.isPhotoBeingRearranged = true;

        // Watch the ondragover event of the photos container since the ondrag event of the dragged photo
        // does not contain a clientX or clientY property which we need to scroll when the user reaches the container's end.
        document.ondragover = (event: DragEvent) => {
            if (event.clientY < 150) {
                this.scrollContainer(-10);
            } else if (event.clientY > window.outerHeight - 150) {
                this.scrollContainer(10);
            }
        };
    }

    scrollContainer(step: number): void {
        if (this.scrollTimeoutCache) {
            return;
        }

        this.scrollTimeoutCache = window.setTimeout(() => {
            // Free the timeout cache so that the next scroll may be conducted.
            clearTimeout(this.scrollTimeoutCache);
            this.scrollTimeoutCache = undefined;
            document.documentElement.scrollTop += step;
        }, 100);
    }

    /**
     * Called after onDropSuccess so this method performs some cleanup even if the user dropped the photo outside of a valid drop zone.
     */
    onDragEnd(): void {
        this.fileIsOverBody = false;
        this.isPhotoBeingRearranged = false;

        // We needed the ondragover event listener to determine when to sroll while dragging. After dropping,
        // the event listener is not necessary until the next drag.
        document.ondragover = undefined;
    }

    onPositionChange(event: PositionChangeEvent): void {
        // Remove the dragged photo from the photos array.
        const photo = this.deletePhotoFromReportPhotosArray(this.photos[event.fromIndex]);

        // Add it back at the new position.
        this.photos.splice(event.toIndex, 0, photo);

        // Save the index on the photo itself
        this.setOrderPositions();

        this.changeDetectorRef.detectChanges();

        this.emitPhotoChange();
    }

    private setOrderPositions(): void {
        // Set the new order position for each photo.
        this.photos.forEach((photo: Photo, index) => (photo.orderPosition = index));
        this.emitPhotoChange();
    }

    public moveSelectedPhotosToTargetIndex(targetOrderPosition: number): void {
        if (targetOrderPosition < 1 || targetOrderPosition !== Math.round(targetOrderPosition)) {
            this.toastService.info('Position ungültig', 'Es sind nur ganzzahlige positive Positionen möglich.');
            return;
        }

        const movingPhotos: Photo[] = [...this.selectedPhotos];
        const movingPhotosIndexes: number[] = []; // movingPhotos.map(movingPhoto => this._photos.indexOf(movingPhoto));

        if (!movingPhotos.length) {
            return;
        }

        //*****************************************************************************
        //  Move within report.photos
        //****************************************************************************/
        // Determine index positions to pass to the Muuri-internal logic. Muuri must move the items itself, this does not happen automatically when Angular detects changes.
        // We must note the images' indexes here without altering the array. If we removed photos here, the following indexes would be only right for the diminished array, not the original one.
        for (const photo of movingPhotos) {
            const index: number = this.photos.indexOf(photo);
            movingPhotosIndexes.push(index);
        }

        // The reorderingTargetIndex holds the user input. If the user enters "1", he means index "0", so subtract that offset.
        let targetIndex = targetOrderPosition - 1;

        // If there is at least one being-moved photo before the target index, we must insert the photo _after_ the target. Otherwise before the target. That way of sorting feels the most natural.
        const arePhotosBeforeTarget = Math.min(...movingPhotosIndexes) < targetIndex;
        if (arePhotosBeforeTarget) {
            targetIndex++;
        }

        // Get each half of the array (before & after target index) without the moving photos.
        const itemsBeforeTargetIndex: Photo[] = this.photos
            .slice(0, targetIndex)
            .filter((photo) => !movingPhotos.includes(photo));
        const itemsAfterTargetIndex: Photo[] = this.photos
            .slice(targetIndex)
            .filter((photo) => !movingPhotos.includes(photo));

        // Create an array of IDs by which to sort. We cannot assign a fresh array because that would break references of report.photos.
        const newPhotoOrder = [...itemsBeforeTargetIndex, ...movingPhotos, ...itemsAfterTargetIndex];

        // Set new order to original photos array
        this.photos.sort((photoA, photoB) => {
            const targetIndexA = newPhotoOrder.indexOf(photoA);
            const targetIndexB = newPhotoOrder.indexOf(photoB);

            if (targetIndexA < targetIndexB) return -1;
            if (targetIndexA > targetIndexB) return 1;
            return 0;
        });

        this.setOrderPositions();
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Move within report.photos
        /////////////////////////////////////////////////////////////////////////////*/

        this.photoMuuriGridService.moveItems({
            fromIndexes: movingPhotosIndexes,
            // Get the index for muuri before updating the report photos array. Muuri will receive the index while its still at an un-updated state compared to the report photos array.
            targetPosition: targetIndex,
        });
        this.refreshMuuriGrid();
        this.selectedPhotos = [];
        this.hideReorderingInput();
        this.emitPhotoChange();
    }

    public moveSelectedPhotosToBeginning(): void {
        this.moveSelectedPhotosToTargetIndex(1);
    }

    public moveSelectedPhotosToEnd(): void {
        this.moveSelectedPhotosToTargetIndex(this.photos.length);
    }

    public selectActivatedPhotos(): void {
        const activatedPhotos: Photo[] = [...this.photos.filter((photo) => photo.versions[this.filterBy].included)];

        // If all activated photos have already been selected, clear the selection.
        if (
            activatedPhotos.length === this.selectedPhotos.length &&
            this.selectedPhotos.every((photo) => activatedPhotos.includes(photo))
        ) {
            this.clearPhotoSelection();
            return;
        }

        this.selectedPhotos = activatedPhotos;

        this.sortSelectedPhotos();
    }

    public selectDeactivatedPhotos(): void {
        const deactivatedPhotos: Photo[] = [...this.photos.filter((photo) => !photo.versions[this.filterBy].included)];

        // If all deactivated photos have already been selected, clear the selection.
        if (
            deactivatedPhotos.length === this.selectedPhotos.length &&
            this.selectedPhotos.every((photo) => deactivatedPhotos.includes(photo))
        ) {
            this.clearPhotoSelection();
            return;
        }

        this.selectedPhotos = deactivatedPhotos;

        this.sortSelectedPhotos();
    }

    public selectPhotosIncludedInReport(): void {
        this.selectedPhotos = [];
        this.selectedPhotos = [...this.photos.filter((photo) => photo.versions[this.photoVersion].included === true)];
        this.sortSelectedPhotos();
    }

    public moveSelectedPhotosToTargetIndexOnEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.moveSelectedPhotosToTargetIndex(this.reorderingTargetIndex);
            event.preventDefault();
        }
    }

    public showReorderingInput(): void {
        this.reorderingInputVisible = true;
        window.setTimeout(
            () => {
                this.reorderingInput.nativeElement.focus();
            },
            // Wait 50 ms. Before, we waited 0 ms but then Firefox - for whatever reason - would insert the photo position twice (44 instead of 4, 11 instead of 1).
            50,
        );
    }

    public hideReorderingInput(): void {
        this.reorderingInputVisible = false;
        this.reorderingTargetIndex = null;
    }

    /**
     * Sort the grid by the current order of the photos array.
     */
    public applyOrderToGrid() {
        this.photoMuuriGridService.arrangeItems(this.photos.map((photo) => photo._id));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Rearrange photos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Download
    //****************************************************************************/
    /**
     * Download one photo as a JPEG or multiple photos as a ZIP archive.
     * @param photos
     * @param format
     */
    public async downloadPhotos({ photos, format }: { photos: Photo[]; format: 'rendered' | 'original' }) {
        this.numberOfDownloadingPhotos = photos.length;

        await Promise.all(
            photos.map(async (photo) => {
                let photoBlob: Blob;
                if (format === 'original') {
                    photoBlob = await this.originalPhotoService.get(`${this.report._id}-${photo._id}`);
                } else {
                    photoBlob = await this.renderedPhotoFileService.getFile({
                        reportId: this.report._id,
                        photo,
                        version: this.filterBy,
                        format,
                    });
                }
                this.downloadedPhotos.push({
                    filename: this.getPhotoDownloadFilename(photo),
                    blob: photoBlob,
                });
            }),
        );

        /**
         * If only a single image should be downloaded, download it as a JPEG instead of a ZIP.
         */
        if (this.downloadedPhotos.length === 1) {
            this.downloadService.downloadFile(this.downloadedPhotos[0].blob, this.downloadedPhotos[0].filename);
        } else {
            /**
             * Download multiple photos as a ZIP archive.
             */
            const zip = new JSZip();
            for (const photoTitleAndBlob of this.downloadedPhotos) {
                let fileNameWithOptionalCounter: string = photoTitleAndBlob.filename;
                let counter: number = 1;

                // While a file with the same name exists, add a counter to the filename. On first iteration, we check for the filename without a counter, therefore it may be initialized with zero.
                while (zip.file(fileNameWithOptionalCounter)) {
                    const [, fileNameWithoutExtension, extension] =
                        photoTitleAndBlob.filename.match(/(.*)(\..{1,4})/) ?? []; // Extension should have between one and four characters
                    fileNameWithOptionalCounter = `${fileNameWithoutExtension} (${counter})${extension}`;
                    counter++;
                }
                zip.file(fileNameWithOptionalCounter, photoTitleAndBlob.blob, { binary: true });
            }
            const zipFile: Blob = await zip.generateAsync({ type: 'blob' });
            this.downloadService.downloadFile(zipFile, this.zipDownloadFilename || 'Fotos autoiXpert.zip');
        }

        this.numberOfDownloadingPhotos = null;
        this.downloadedPhotos = [];
    }

    private getPhotoDownloadFilename(photo: Photo) {
        if (!photo.description) {
            /**
             * In case the filename was from a file system that allowed special characters. Usually, the original name should already
             * be a valid filename.
             */
            return removeInvalidFilenameCharacters(photo.originalName || 'Foto.jpg');
        }
        return `${removeInvalidFilenameCharacters(photo.description)}.jpg`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Download
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Deletion
    //****************************************************************************/
    /**
     * If on a tablet, confirm the deletion of a photo. On a computer, mistakenly
     * clicking the delete icon is highly unlikely.
     * @param photo
     */
    public confirmDeletion(photo: Photo): void {
        if (isTouchOnly()) {
            this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Foto löschen?',
                        content: 'Das kann nicht rückgängig gemacht werden.',
                        confirmLabel: 'Kann weg',
                        cancelLabel: 'Doch lieber behalten',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result) {
                        this.deletePhoto(photo);
                    }
                });
        } else {
            this.deletePhoto(photo);
        }
    }

    /**
     * Remove photo from server. If deletion fails, add photo back to user interface.
     * @param photo
     * @param isMultiDelete
     */
    public async deletePhoto(photo: Photo, isMultiDelete = false) {
        if (this.isGridDisabled()) {
            return;
        }

        const photoIndex = this.photos.indexOf(photo);

        // Remove photo from photos array. If deletion fails, the photo will be added back in the error handler.
        if (isMultiDelete) {
            this.deletePhotoFromReportPhotosArray(photo);
        }
        // If only a single photo is being deleted, use a fancy animation.
        else {
            // Start delete animation. As soon as the animation is done, remove the photo from this._photos.
            this.photoDeletionsInProgress.set(photo._id, photo);

            // We used to use Angular's animation.done callback but that isn't currently triggered after an element was moved and then deleted.
            window.setTimeout(() => this.deletePhotoAnimationCallback(photo), DELETION_ANIMATION_DURATION);
        }

        // If the photo is still being uploaded, cancel the upload process.
        if (this.originalPhotoService.getPhotoUpload(photo._id)) {
            this.originalPhotoService.cancelUpload(photo._id);
        }
        // If the photo was uploaded already, remove it from the server.
        else {
            try {
                await this.originalPhotoService.delete(`${this.report._id}-${photo._id}`);
            } catch (error) {
                // If the photo was not found on the server, just keep going. Deleting a non-existent photo
                // is not a real error since the user's intention was fulfilled.
                if (error.statusCode === 404) {
                    console.log('The photo has already been deleted on the server. Keep going.');
                } else {
                    // If the delete-animation ran through in the meantime, its callback removed the photo from the
                    // this.photos array. Add it back now.
                    if (this.photos.indexOf(photo) === -1) {
                        // Add photo back to the photos array so that it is not lost.
                        const rightSidePhotos = this.photos.splice(photoIndex);
                        this.photos.push(photo, ...rightSidePhotos);
                    }
                    // The photo should not be marked deleted. Any animation should stop.
                    this.photoDeletionsInProgress.delete(photo._id);

                    console.error('The photo could not be deleted.', { error });
                }
                return;
            }
        }
        // Remove deleted photo from selection
        removeFromArray(photo, this.selectedPhotos);

        // Remember which photos got deleted, so the user can restore them
        this.rememberDeletedPhoto(photo);
        this.showPhotoDeletedAnimation();
    }

    /**
     * Remember the given photo after deletion so that it can be restored on demand (while photo-grid is still open).
     */
    public rememberDeletedPhoto(photo: Photo): void {
        this.deletedPhotos.push(photo);
    }

    /**
     * After the deletion-animation is done, the photo must be removed from the DOM.
     * @param photo
     */
    public deletePhotoAnimationCallback(photo: Photo): void {
        // The animation.done callback is triggered every time the assigned state changes (in our case whether the photo is included in the photoDeletionsInProgress Map).
        // Prevent duplicate actions for the state change from true to false
        if (this.photoDeletionsInProgress.has(photo._id) === false) return;
        if (!this.photos.includes(photo)) return;

        this.deletePhotoFromReportPhotosArray(photo);
        this.photoDeletionsInProgress.delete(photo._id);
        this.setOrderPositions();

        this.emitPhotoDeletion([photo]);
        // The change event may only be emitted after the local photos array has been updated because
        // the current state of that array is passed to listeners. We want them to get the new state.
        this.emitPhotoChange();

        // Trigger change detection because there was no event that angular observes which may trigger the change detector.
        this.changeDetectorRef.detectChanges();
    }

    private deletePhotoFromReportPhotosArray(photo: Photo): Photo {
        const index = this.photos.indexOf(photo);
        if (index > -1) {
            return this.photos.splice(index, 1)[0];
        }
        console.log(`ID ${photo._id}: Failed to delete photo from photos array`);
        return null;
    }

    /**
     * Delete multiple photos at once, only updating the grid once after all items are deleted.
     * @param photos
     */
    public deletePhotos(photos: Photo[]): void {
        if (this.isGridDisabled()) {
            this.toastService.info(
                'Gutachten ist abgeschlossen',
                'Wenn du ein Foto löschen möchtest, öffne das Gutachten zuerst.',
            );
            return;
        } else if (photos.some((photo) => this.isPhotoDisabled(photo))) {
            this.toastService.info(
                'Fotogruppe wechseln',
                'Bitte wechsle zur Gutachten-Fotogruppe, um die ausgewählten Fotos zu löschen.',
            );
            return;
        }

        // If only one photo is selected, delete it with an animation.
        if (photos.length === 1) {
            this.deletePhoto(photos[0]);
            return;
        }

        /**
         * Create a fresh array with the same elements. Not doing this causes inconsistent program behavior with the foreach method
         * below.
         *
         * Problem: The forEach method will not execute for each photo if the photos parameter holds a reference to this.selectedPhotos, since
         * the splice call deletes elements while the forEach method is still running.
         *
         */
        photos = [...photos];

        photos.forEach((photo: Photo) => {
            // Delete photo from report and server
            this.deletePhoto(photo, true);
        });

        this.setOrderPositions();

        this.emitPhotoDeletion([...photos]);
        this.emitPhotoChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Deletion
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Default Photo Descriptions
    //****************************************************************************/
    public addDescriptionsToFirstPhotos(): void {
        const defaultDescriptions: string[] = this.userPreferences.defaultPhotoDescriptions?.map(
            (defaultDescription) => defaultDescription.title,
        );

        // Get as many photos as there are default descriptions
        const photos: Photo[] = this.photos.slice(0, defaultDescriptions.length);

        for (const [index, photo] of photos.entries()) {
            photo.description = defaultDescriptions[index];
            this.photosJustNamed.push(photo);
        }

        setTimeout(() => {
            this.photosJustNamed = [];
        }, 1000);

        this.emitPhotoChange();
    }

    public openDefaultPhotoDescriptionsEditor(): void {
        this.defaultPhotoDescriptionsEditorShown = true;
    }

    public closeDefaultPhotoDescriptionsEditor(): void {
        this.defaultPhotoDescriptions = [];
        this.defaultPhotoDescriptionsEditorShown = false;
    }

    public convertFilenameToDescription() {
        for (const photo of this.photos) {
            // If a description was entered in autoiXpert already, do not overwrite it.
            if (photo.description) {
                continue;
            }

            photo.description = photo.originalName.substr(0, photo.originalName.lastIndexOf('.'));
            this.photosJustNamed.push(photo);
        }

        // If no photo was named because all of them have a description already, show an info.
        if (this.photosJustNamed.length === 0) {
            this.toastService.info(
                'Keine Fotos umbenannt',
                'Alle Fotos haben bereits eine Beschreibung über autoiXpert erhalten. Damit diese Daten nicht überschrieben werden, wurde kein Foto umbenannt.',
            );
        }

        setTimeout(() => {
            this.photosJustNamed = [];
        }, 1000);

        this.emitPhotoChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default Photo Descriptions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  License Plate Redaction
    //****************************************************************************/
    protected async showLicensePlateRedactionTutorial() {
        // Show tutorial
        let confirmed = false;
        const dialogRef = this.dialog.open<LicensePlateRedactionInfoDialogComponent, any, any>(
            LicensePlateRedactionInfoDialogComponent,
            {},
        );

        dialogRef.componentInstance.closeClick.subscribe(() => {
            dialogRef.close();
        });

        dialogRef.componentInstance.confirmClick.subscribe(() => {
            this.user.userInterfaceStates.licensePlateRedactionTutorialDismissed = true;
            this.saveUser();
            confirmed = true;
            dialogRef.close();
        });

        await dialogRef.afterClosed().toPromise();

        return { confirmed };
    }

    protected async redactLicensePlates() {
        if (!this.user.userInterfaceStates.licensePlateRedactionTutorialDismissed) {
            const { confirmed } = await this.showLicensePlateRedactionTutorial();
            if (!confirmed) return;
        }

        this.licensePlateRedactionsProcessed = [];
        this.licensePlateRedactionsQueued = this.selectedPhotos.length ? [...this.selectedPhotos] : [...this.photos];
        if (!this.licensePlateRedactionsQueued.length) {
            this.toastService.info('Keine Fotos hochgeladen', 'Bitte lade zuerst Fotos hoch.');
            return;
        }

        // Switch to residual value exchange group if not already there
        if (this.filterBy !== 'residualValueExchange') {
            this.setFilterGroup('residualValueExchange');
            this.licensePlateRedactionsFilterGroupPulsing = true;

            setTimeout(() => {
                this.licensePlateRedactionsFilterGroupPulsing = false;
            }, 2_000);
        }

        // Load model if not already loaded
        if (!this.licensePlateRedactionService.hasModelBeenLoaded()) {
            this.licensePlateRedactionsModelLoading = true;
            try {
                await this.licensePlateRedactionService.loadModel();
                this.licensePlateRedactionsModelLoading = false;
            } catch (error) {
                this.cancelLicensePlateRedactions();
                this.apiErrorService.handleAndRethrow({
                    axError: new AxError({
                        error,
                        code: 'LICENSE_PLATE_REDACTION_MODEL_LOADING_FAILED',
                        message: 'Failed to load the license plate redaction model.',
                    }),
                    defaultHandler: {
                        title: 'Kennzeichen schwärzen nicht möglich',
                        body: 'Bei der Initialisierung ist ein Fehler aufgetreten. Bitte kontaktiere den aX-Support.',
                    },
                });
            }
        }

        let unsavedRedactionPhotos: Photo[] = [];
        while (this.licensePlateRedactionsQueued.length) {
            const photo = this.licensePlateRedactionsQueued[0];

            try {
                // Perform the redaction
                const imageBlob = await this.originalPhotoService.get(`${this.report._id}-${photo._id}`);
                const result = await this.licensePlateRedactionService.redactLicensePlateLocally({
                    image: { width: photo.width, height: photo.height, blob: imageBlob },
                });

                // Move the photo from the loading to the processed array
                this.licensePlateRedactionsQueued = this.licensePlateRedactionsQueued.slice(1);
                this.licensePlateRedactionsProcessed.push(photo);

                // Bootstrap the fabricJsInformation object if it doesn't exist yet
                if (!photo.versions.residualValueExchange?.fabricJsInformation?.objects) {
                    const image = new FabricImage(new Image(), {
                        data: {
                            axType: 'photo',
                        },
                        height: photo.height,
                        width: photo.width,
                    });

                    photo.versions.residualValueExchange.fabricJsInformation = {
                        version: '2.0.0-rc.1',
                        axFilters: getDefaultFilters(),
                        objects: [image.toObject(['data'])],
                    };
                }

                // Calculate scale factors
                const fabricJsInformation = photo.versions.residualValueExchange.fabricJsInformation;
                const canvasImage = fabricJsInformation.objects[0];
                if (!canvasImage) continue;

                const canvasWidth = canvasImage.width;
                const canvasHeight = canvasImage.height;
                const canvasScaleX = canvasImage.scaleX || 1;
                const canvasScaleY = canvasImage.scaleY || 1;

                const scaleX = (canvasWidth / result.image.width) * canvasScaleX;
                const scaleY = (canvasHeight / result.image.height) * canvasScaleY;

                // Create a new polygon object
                for (const redaction of result.redactions) {
                    const polygon = createLicensePlateRedactionFabricPolygon({
                        redaction,
                        scaleX,
                        scaleY,
                        color: this.team.preferences.licensePlateRedactionColor,
                    });

                    if (
                        hasLicensePlateRedactionPolygonAlreadyBeenAdded({
                            objects: fabricJsInformation.objects,
                            polygon,
                        })
                    ) {
                        continue;
                    }

                    // Insert the polygon into the photo's fabricJsInformation
                    photo.versions.residualValueExchange.fabricJsInformation.objects.push(polygon.toObject(['data']));
                    unsavedRedactionPhotos.push(photo);

                    // Save the redaction to the server when there are enough unsaved redactions
                    if (unsavedRedactionPhotos.length >= 1) {
                        this.photoChange.emit({
                            photos: unsavedRedactionPhotos,
                            callback: this.reloadPhotoThumbnails.bind(this),
                        });
                        unsavedRedactionPhotos = [];
                    }
                }
            } catch (error) {
                // Silently fail
                console.log(error);
                // If the redaction failed, remove the photo from the queue
                this.licensePlateRedactionsQueued = this.licensePlateRedactionsQueued.slice(1);
                this.licensePlateRedactionsProcessed.push(photo);
            }
        }

        // Final save of redactions
        this.photoChange.emit({
            photos: unsavedRedactionPhotos,
            callback: this.reloadPhotoThumbnails.bind(this),
        });
    }

    async cancelLicensePlateRedactions() {
        this.licensePlateRedactionsQueued = [];
        this.licensePlateRedactionsProcessed = [];
        this.licensePlateRedactionsModelLoading = false;
        this.licensePlateRedactionsFilterGroupPulsing = false;
    }

    async reloadPhotoThumbnails(photos: Photo[]) {
        if (!photos.length) return;

        for (const photo of photos) {
            try {
                await this.loadPhotoThumbnailFile(photo);
            } catch (error) {
                console.error('Failed to reload thumbnail after license plate redaction.', { error });
            }
        }
    }

    async openLicensePlateRedactionColorPicker(event: Event) {
        if (!this.isAdmin()) {
            this.toastService.error(
                'Fehlende Berechtigung',
                'Nur Administratoren können die Kennzeichen-Schwärzungsfarbe ändern.',
            );
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        this.licensePlatRedactionColorpicker?.nativeElement?.focus();
        this.licensePlatRedactionColorpicker?.nativeElement?.click();
    }

    async setLicensePlateRedactionColor(color: string) {
        if (!this.isAdmin()) {
            this.toastService.error(
                'Fehlende Berechtigung',
                'Nur Administratoren können die Kennzeichen-Schwärzungsfarbe ändern.',
            );
            return;
        }

        this.team.preferences.licensePlateRedactionColor = color;
        await this.teamService.put(this.team);
    }

    async setLicensePlateRedactionBlur() {
        if (!this.isAdmin()) {
            this.toastService.error(
                'Fehlende Berechtigung',
                'Nur Administratoren können die Kennzeichen-Schwärzungsunschärfe ändern.',
            );
            return;
        }

        this.team.preferences.licensePlateRedactionColor = null;
        await this.teamService.put(this.team);
    }

    isAdmin() {
        return isAdmin(this.user?._id, this.team);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END License Plate Redaction
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Websockets
    //****************************************************************************/
    private listenToWebsocketPatchEvents() {
        const subscription = this.reportService.patchedFromExternalServerOrLocalBroadcast$
            .pipe(
                // Only consider updates to the current report.
                filter(({ patchedRecord }) => patchedRecord._id === this.report._id),
            )
            .subscribe({
                next: () => {
                    // When sorting photos on another client, apply the new order on this client as well.
                    this.applyOrderToGrid();
                },
            });

        this.subscriptions.push(subscription);
    }

    private loadThumbnailWhenInitialResizeCompletes(): void {
        const subscription = this.originalPhotoService.createdInLocalDatabase$.subscribe({
            next: (originalPhotoRecord) => {
                const photoFromThisPhotoGrid: Photo = this.photos.find(
                    (photo) => `${this.report._id}-${photo._id}` === originalPhotoRecord._id,
                );
                /**
                 * Only care about photo creations that concern photos in this photo grid component. The photo grid component is used in multiple locations in autoiXpert:
                 * - photo list
                 * - expert statement
                 * If an async creation takes place, make sure that only photos shown in this component trigger a thumbnail reload.
                 */
                if (!photoFromThisPhotoGrid) {
                    return;
                }
                if (
                    this.originalPhotoService.photosWaitingForInitialResizingOrIndexeddb.has(photoFromThisPhotoGrid._id)
                ) {
                    this.originalPhotoService.photosWaitingForInitialResizingOrIndexeddb.delete(
                        photoFromThisPhotoGrid._id,
                    );
                    this.loadPhotoThumbnailFile(photoFromThisPhotoGrid);
                }
            },
        });

        this.subscriptions.push(subscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Websockets
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Is Disabled
    //****************************************************************************/
    /**
     * The Photo Grid is disabled if the report is locked (determined from the parent
     * component, passed through the 'disabled' input), except the repair confirmation
     * or expert statement photo groups are selected.
     *
     * We know that this currently allows all photos to be edited and re-sorted.
     */
    public isGridDisabled(): boolean {
        return this.disabled && !(this.filterBy === 'repairConfirmation' || this.filterBy === 'expertStatement');
    }

    public isPhotoDisabled(photo: Photo): boolean {
        const isFilterGroupDifferentFromReport: boolean = this.filterBy !== 'report';
        const isPhotoActiveInReport: boolean = photo.versions.report.included;

        return this.isGridDisabled() || (isFilterGroupDifferentFromReport && isPhotoActiveInReport);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Is Disabled
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Change Emitters
    //****************************************************************************/
    public emitPhotoChange(): void {
        this.photoChange.emit({ photos: this.photos });
    }

    public emitPhotoDeletion(photos: Photo[]): void {
        this.photoDeletion.emit(photos);
    }

    public emitClickWhileDisabled(): void {
        this.clickWhileDisabled.emit();
    }

    public emitCarRegistrationScannerClick(photo: Photo): void {
        if (this.disabled) {
            return;
        }

        if (this.getUploadItem(photo._id)) {
            this.toastService.info('Foto wird hochgeladen', 'Bitte warte, bis das Foto hochgeladen ist.');
            return;
        }
        /**
         * Exclude the photo from the residual value exchange since it contains personal information.
         */
        photo.versions.residualValueExchange.included = false;

        this.carRegistrationScannerClick.emit(photo);
    }

    public emitOpenPhotoEditor(photo: Photo): void {
        this.openPhotoEditor.emit({
            photo,
            photoGroup: this.filterBy,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Change Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Responsive Layout
    //****************************************************************************/
    public isSmallScreen(): boolean {
        return isSmallScreen('large');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Responsive Layout
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    // Listen to the window event since listening should be possible even if the focus is removed from the editor.
    @HostListener('window:keydown', ['$event'])
    public keyDownListener(event: KeyboardEvent) {
        // Do not listen to keyboard events when the editor is open.
        if (this.defaultPhotoDescriptionsEditorShown || this.disableKeyboardShortcuts) {
            return;
        }

        // Escape is treated differently since that should work even if the user focuses the move-photo-to-index-input.
        if (event.key === 'Escape') {
            this.clearPhotoSelection();
            return;
        }

        // Do not listen to the following keyboard events when an input is selected such as when moving photos through entering a number.
        if (
            (event.target as HTMLInputElement).tagName === 'INPUT' ||
            (event.target as HTMLInputElement).tagName === 'TEXTAREA'
        ) {
            return;
        }

        switch (event.key) {
            // If CTRL+A or Command+A (Mac) is hit, select all photos
            case 'a':
                if (event.ctrlKey || event.metaKey) {
                    this.selectAllPhotos();
                    event.preventDefault();
                }
                break;
            case 'Backspace':
            case 'Delete':
                if (this.selectedPhotos) {
                    this.deletePhotos(this.selectedPhotos);
                }
                break;
            case 'Home':
                if (event.ctrlKey || event.metaKey) {
                    this.moveSelectedPhotosToBeginning();
                    event.preventDefault();
                }
                break;
            case 'End':
                if (event.ctrlKey || event.metaKey) {
                    this.moveSelectedPhotosToEnd();
                    event.preventDefault();
                }
                break;
            case 's':
                if (event.ctrlKey || event.metaKey) {
                    this.selectActivatedPhotos();
                    event.preventDefault();
                }
                break;
            case 'd':
                if (event.ctrlKey || event.metaKey) {
                    this.selectDeactivatedPhotos();
                    event.preventDefault();
                }
                break;
            case 'g':
                if (event.ctrlKey || event.metaKey) {
                    this.selectPhotosIncludedInReport();
                    event.preventDefault();
                }
                break;
        }

        // Show reordering input when typing a number
        if (!isNaN(+event.key) && this.selectedPhotos.length) {
            this.reorderingTargetIndex = +event.key;
            this.showReorderingInput();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Angular Performance Optimizations
    //****************************************************************************/
    /**
     * For Angular to recycle rendered elements even if the underlying array changed (e.g. after getting the report from the cache first, then from the server),
     * we must give Angular another identifier. If the identifier is the same, the DOM element will not be re-rendered. We use the Photo's _id property since that doesn't
     * change through an update.
     * @param index
     * @param photo
     */
    public getPhotoId(index: number, photo: Photo): string {
        return photo._id;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Angular Performance Optimizations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Component deactivation (navigate away)
    //****************************************************************************/
    canDeactivate() {
        return this.licensePlateRedactionsQueued.length === 0;
    }

    async forceDeactivate() {
        await this.cancelLicensePlateRedactions();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Component deactivation (navigate away)
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Save reports to the server.
     */
    private async saveReport(report: Report): Promise<void> {
        if (isReportLocked(report)) {
            return;
        }
        try {
            await this.reportDetailsService.patch(report);
        } catch (error) {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', report, { error });
        }
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    protected readonly preventContextMenuOnTouchDevices = preventContextMenuOnTouchDevices;
}

export type PhotoGroupName = 'report' | 'residualValueExchange' | 'repairConfirmation' | 'expertStatement';

interface LocalThumbnailSafeFileUrlConfig {
    fabricJsInformationHash: string;
    safeResourceUrl: SafeResourceUrl;
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';

@Component({
    selector: 'analytics-list',
    templateUrl: 'analytics-list.component.html',
    styleUrls: ['analytics-list.component.scss'],
})
export class AnalyticsListComponent {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public tutorialStateService: TutorialStateService,
    ) {}

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateTo(route): void {
        this.router.navigate([route], {
            relativeTo: this.route,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/
}

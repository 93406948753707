import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { CarEquipmentPosition } from '@autoixpert/models/reports/car-identification/car-equipment';
import { openPhantomCalculationHelpInNewTab } from '../../../../shared/libraries/open-phantom-calculation-help-in-new-tab';
import { DatIdentificationResponsePerVehicle } from '../car-data.component';

@Component({
    selector: 'multiple-cars-identification-helper',
    templateUrl: 'multiple-cars-identification-helper.component.html',
    styleUrls: ['multiple-cars-identification-helper.component.scss'],
})
export class MultipleCarsIdentificationHelperComponent {
    constructor(private dialog: MatDialog) {}

    // True if this popup is shown after the VIN returned multiple results. This prevents existing data to be loaded from the car
    // and shows a message telling the user why he is on this screen.
    @Input()
    public set identificationResponsesPerVehicle(identificationResponses: DatIdentificationResponsePerVehicle[]) {
        this.identificationResponses = identificationResponses;
        this.sortBySubmodel();
        this.determineMakeAndModel();
    }

    @Output() public close = new EventEmitter<void>();
    @Output() public identificationResponsePerVehicleChosen = new EventEmitter<DatIdentificationResponsePerVehicle>();

    private classificationGroupDatIds = {
        motor: 1,
        autobody: 2,
        build: 7,
        gearbox: 11,
        wheelbase: 3,
        propulsion: 4,
        numberOfAxles: 9,
        driversCab: 5,
        tonnage: 6,
        suspension: 8,
        equipmentLine: 10,
    };

    public identificationResponses: DatIdentificationResponsePerVehicle[];
    public make: Car['make'];
    public model: Car['model'];
    public submodelGroups: SubmodelGroup[] = [];
    public selectedIdentificationResponsePerVehicle: DatIdentificationResponsePerVehicle;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    private sortBySubmodel(): void {
        this.identificationResponses.sort((responseA, responseB) =>
            (responseA.newCarInformation.datIdentification.marketIndexName || '').localeCompare(
                responseB.newCarInformation.datIdentification.marketIndexName || '',
            ),
        );

        this.identificationResponses.forEach((vehicleEdition) => {
            let matchingSubmodelGroup: SubmodelGroup = this.submodelGroups.find(
                (subtype) => subtype.name === vehicleEdition.newCarInformation.submodel,
            );

            // Create missing submodel group
            if (!matchingSubmodelGroup) {
                matchingSubmodelGroup = {
                    name: vehicleEdition.newCarInformation.submodel,
                    editions: [],
                };
                this.submodelGroups.push(matchingSubmodelGroup);
            }

            matchingSubmodelGroup.editions.push(vehicleEdition);
        });
    }

    private determineMakeAndModel(): void {
        const firstVehicleEdition: DatIdentificationResponsePerVehicle = this.identificationResponses[0];

        this.make = firstVehicleEdition.newCarInformation.make;
        this.model = firstVehicleEdition.newCarInformation.model;
    }

    //*****************************************************************************
    //  END Initialization
    //****************************************************************************/

    //*****************************************************************************
    //  Car Brand Icons
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    public getDatECodeEquipmentOptionName(carEquipmentPositions: CarEquipmentPosition[], classificationGroup: string) {
        if (!carEquipmentPositions || !carEquipmentPositions.length) {
            return '';
        }

        const carEquipmentPosition: CarEquipmentPosition = carEquipmentPositions.find(
            (carEquipmentPosition) =>
                parseInt(carEquipmentPosition.datEquipmentGroup + '') ===
                this.classificationGroupDatIds[classificationGroup],
        );

        if (!carEquipmentPosition) {
            return '';
        }

        let optionValue = '';
        switch (carEquipmentPosition.datEquipmentGroup) {
            case '1':
                optionValue = carEquipmentPosition.description.replace(/Motor /, '');
                break;
            case '2':
                optionValue = carEquipmentPosition.description.replace(/Karosserie: /, '');
                break;
            case '7':
                optionValue = carEquipmentPosition.description.replace(/Bauart /, '');
                break;
            case '11':
                optionValue = carEquipmentPosition.description.replace(/Getriebe /, '');
                break;
            case '3':
                optionValue = carEquipmentPosition.description.replace(/Radstand /, '');
                break;
            case '4':
                optionValue = carEquipmentPosition.description.replace(/Antriebsart /, '');
                break;
            case '9':
                optionValue = carEquipmentPosition.description.replace(/Anzahl Achsen /, '');
                break;
            case '5':
                optionValue = carEquipmentPosition.description.replace(/Fahrerhaus /, '');
                break;
            case '6':
                optionValue = carEquipmentPosition.description.replace(/Tonnage /, '');
                break;
            case '8':
                optionValue = carEquipmentPosition.description.replace(/Federungsart /, '');
                break;
            case '10':
                optionValue = carEquipmentPosition.description.replace(/Ausstattungslinie /, '');
                break;
        }

        return optionValue;
    }

    public chooseVinResponsePerVehicle(vinResponsePerVehicle: DatIdentificationResponsePerVehicle): void {
        if (!this.selectedIdentificationResponsePerVehicle) return;

        this.dispatchVinResponsePerVehicleChosenEvent(vinResponsePerVehicle);
    }

    public getMarketIndexWithoutProductionPeriod(marketIndex: string): string {
        const match: RegExpMatchArray = marketIndex.match(/(.*?), (\d{4} - \d{4})/);
        const allExceptProductionPeriod = match?.[1];

        return allExceptProductionPeriod || marketIndex;
    }

    public getProductionPeriod(marketIndex: string): string {
        const match: RegExpMatchArray = marketIndex.match(/(.*?), (\d{4} - \d{4})/);
        const productionPeriod = match?.[2];

        return productionPeriod || '-';
    }

    public selectIdentificationResponse(response: DatIdentificationResponsePerVehicle): void {
        this.selectedIdentificationResponsePerVehicle = response;
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeAndDiscard();
        }
    }

    public openPhantomCalculationHelp() {
        openPhantomCalculationHelpInNewTab();
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public async closeAndDiscard(): Promise<void> {
        const wasDiscardingCofirmed: boolean = await this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    heading: 'Kostenpflichtige VIN-Daten verwerfen?',
                    content:
                        'Die angezeigten Daten sind das Ergebnis einer VIN-Abfrage, die dir von der DAT in Rechnung gestellt wurde. Wirklich schließen?\n\nFalls dein Fahrzeug nicht dabei ist, kannst du das ähnlichste Fahrzeug wählen und eine Phantom-Kalkulation erstellen.',
                    confirmLabel: 'Egal, weg damit!',
                    cancelLabel: 'Ich gucke nochmal...',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();

        if (wasDiscardingCofirmed) {
            this.dispatchCloseEvent();
        }
    }

    private dispatchVinResponsePerVehicleChosenEvent(vinResponsePerVehicle: DatIdentificationResponsePerVehicle): void {
        this.identificationResponsePerVehicleChosen.emit(vinResponsePerVehicle);
    }

    private dispatchCloseEvent(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    // Listen to the window event since listening should be possible even if the focus is removed from the editor.
    @HostListener('window:keydown', ['$event'])
    public keyDownListener(event) {
        switch (event.key) {
            case 'Escape':
                this.closeAndDiscard();
                break;
        }
    }

    //*****************************************************************************
    //  End Keyboard Shortcuts
    //****************************************************************************/
}

interface SubmodelGroup {
    name: string;
    editions: DatIdentificationResponsePerVehicle[];
}

import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTypeGroupName } from '../lib/documents/get-document-type-groups-from-document-order-configs';
import { translateDocumentTypeGroupToGerman } from '../lib/translators/translate-document-type-group';

/**
 * Document type groups are "Gutachten", "Gutachten-Anhang", "Rechnung" etc. These
 * can be found in the document building block list.
 */
@Pipe({
    name: 'translateDocumentTypeGroupName',
})
export class TranslateDocumentTypeGroupNamePipe implements PipeTransform {
    transform(documentTypeGroupName: DocumentTypeGroupName): string {
        return translateDocumentTypeGroupToGerman(documentTypeGroupName);
    }
}

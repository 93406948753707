import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { Report } from '@autoixpert/models/reports/report';
import { DocumentGroupForDocumentRemoval, removeDocumentFromReport } from './remove-document-from-report';

/**
 * Remove all document metadata objects with a given type from report.documents and remove its reference from all involved parties' document orders.
 *
 * Necessary, e.g., when removing the DAT Valuation document after the DAT valuation was deleted.
 */
export function removeDocumentTypeFromReport({
    documentType,
    customDocumentOrderConfigId,
    report,
    documentGroup = 'both',
}: {
    documentType: DocumentMetadata['type'];
    customDocumentOrderConfigId?: string;
    report: Report;
    documentGroup: DocumentGroupForDocumentRemoval;
}): void {
    const documentsWithType: DocumentMetadata[] = report.documents.filter(
        (document) =>
            document.type === documentType && document.customDocumentOrderConfigId === customDocumentOrderConfigId,
    );
    for (const document of documentsWithType) {
        removeDocumentFromReport({
            document,
            report,
            documentGroup,
        });
    }
}

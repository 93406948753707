import { CommunicationRecipient } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { Report } from '@autoixpert/models/reports/report';

/**
 * Return all involved parties within a report.
 */
export function getCommunicationRecipients(report: Report): CommunicationRecipient[] {
    return [
        report.claimant,
        report.garage,
        report.lawyer,
        report.insurance,
        report.leaseProvider,
        report.seller,
    ].filter((involvedParty) => !!involvedParty);
}

import { round } from '@autoixpert/lib/numbers/round';
import { Report } from '@autoixpert/models/reports/report';
import { calculateTotalNetFees } from './calculate-total-net-fees';

/**
 * Calculate the total gross fees for the given report.
 */
export function calculateTotalGrossFees(report: Report): number {
    return round(calculateTotalNetFees(report) * (1 + report.feeCalculation.invoiceParameters.vatRate));
}

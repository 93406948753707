import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { omit } from 'lodash-es';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { translateReportType } from '@autoixpert/lib/report/translate-report-type';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import {
    DocumentTypeForFileNamePattern,
    FileNamePattern,
} from '@autoixpert/models/file-name-patterns/file-name-pattern';
import { Report, ReportType, reportTypes } from '@autoixpert/models/reports/report';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';
import { slideInAndOutHorizontally } from '../../animations/slide-in-and-out-horizontally.animation';
import { removeIllegalCharactersFromFilename } from '../../libraries/filenames/remove-illegal-characters-from-filename';
import { ApiErrorService } from '../../services/api-error.service';
import { DocumentOrderConfigService } from '../../services/document-order-config.service';
import { FieldGroupConfigService } from '../../services/field-group-config.service';
import { FileNamePatternService } from '../../services/file-name-pattern.service';
import { TemplatePlaceholderValuesService } from '../../services/template-placeholder-values.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'file-name-pattern-editor',
    templateUrl: 'file-name-pattern-editor.component.html',
    styleUrls: ['file-name-pattern-editor.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), slideInAndOutHorizontally(), fadeInAndOutAnimation()],
})
export class FileNamePatternEditorComponent {
    constructor(
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private fileNamePatternService: FileNamePatternService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
    ) {}

    // File Name Patterns
    public fileNamePatterns: FileNamePattern[] = [];
    public selectedFileNamePattern: FileNamePattern;
    public fileNamePatternsLoading: boolean;

    // Document Types
    @Input() selectedDocumentType: DocumentTypeForFileNamePattern; // For now we only support naming the documents we generated ourselves.
    @Input() selectedDocumentOrderConfigId?: DocumentOrderConfig['_id'];

    public selectableDocumentTypes: DocumentTypeForFileNamePattern[] = [
        'report',
        'letter',
        'invoice',
        'declarationOfAssignment',
        'repairConfirmation',
        'expertStatement',
        'customResidualValueBidList',
        'manualCalculation',
        'diminishedValueProtocol',
        'consentDataProtection',
        'revocationInstruction',
        'powerOfAttorney',
        'garageInformation',
        'photoPortfolio',
    ];

    public selectableCustomDocuments: DocumentOrderConfig[] = [];

    @Input() reportId: Report['_id']; // User to generate placeholders
    private fieldGroupConfigs: FieldGroupConfig[] = [];
    private placeholderValues: PlaceholderValues;
    private documentOrderConfigs: DocumentOrderConfig[] = [];

    @Output() close: EventEmitter<void> = new EventEmitter();

    public reportTypeSelectionOpen: boolean;

    ngOnInit() {
        this.loadFileNamePatterns();
        this.loadDocumentOrderConfigs();
        this.loadFieldGroupConfigsAndPlaceholderValueTree();
    }

    private async loadFieldGroupConfigsAndPlaceholderValueTree() {
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        this.placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.reportId,
        });
    }

    private async loadDocumentOrderConfigs() {
        this.documentOrderConfigs =
            await this.documentOrderConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        this.selectableCustomDocuments = this.documentOrderConfigs.filter(
            (documentOrderConfig) => documentOrderConfig.type === 'customDocument',
        );
    }

    //*****************************************************************************
    //  Selected Document
    //****************************************************************************/
    public translateDocumentType({
        documentType,
        documentOrderConfigId,
        shortVersion,
    }: {
        documentType: DocumentTypeForFileNamePattern;
        documentOrderConfigId?: string;
        shortVersion: boolean;
    }) {
        switch (documentType) {
            case 'fullDocumentWithReport':
                return 'Gesamt-PDF (mit Gutachten)';
            case 'fullDocumentWithoutReport':
                return 'Gesamt-PDF (ohne Gutachten)';
            case 'customDocument': {
                const documentOrderConfig = this.documentOrderConfigs.find(
                    (documentOrderConfig) => documentOrderConfig._id === documentOrderConfigId,
                );
                return (
                    (shortVersion ? documentOrderConfig?.titleShort : documentOrderConfig?.titleLong) ||
                    documentOrderConfig?.titleShort ||
                    documentOrderConfig?.titleLong ||
                    translateDocumentType(documentType, shortVersion)
                );
            }

            default:
                return translateDocumentType(documentType, shortVersion);
        }
    }

    public selectDocumentType(documentType: DocumentTypeForFileNamePattern, documentOrderConfig?: string) {
        this.selectedDocumentType = documentType;
        this.selectedDocumentOrderConfigId = documentOrderConfig;

        // Load the patterns for the given document type.
        this.loadFileNamePatterns();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Selected Document
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  File Name Patterns
    //****************************************************************************/
    public selectFileNamePattern(fileNamePattern: FileNamePattern) {
        this.selectedFileNamePattern = fileNamePattern;
    }

    private loadFileNamePatterns() {
        this.fileNamePatternsLoading = true;

        this.fileNamePatternService
            .find({
                documentType: this.selectedDocumentType,
                customDocumentOrderConfigId: this.selectedDocumentOrderConfigId,
            })
            .subscribe({
                next: (fileNamePatterns) => {
                    this.fileNamePatterns = fileNamePatterns;
                    this.selectFileNamePattern(fileNamePatterns[0]);
                },
                error: (error) => {
                    this.fileNamePatternsLoading = false;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Muster für Dateinamen nicht geladen',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
                complete: () => (this.fileNamePatternsLoading = false),
            });
    }

    public async createFileNamePattern(fileNamePattern?: FileNamePattern) {
        // Create a sensible default.
        if (!fileNamePattern) {
            // For new patterns, only assign the report types for which a file name pattern has not yet been defined to avoid duplicate definitions.
            const reportTypesWithAPattern: ReportType[] = [
                ...new Set(this.fileNamePatterns.map((fileNamePattern) => fileNamePattern.reportTypes).flat()),
            ];
            const remainingReportTypes: ReportType[] = reportTypes.filter(
                (reportType) => !reportTypesWithAPattern.includes(reportType),
            );

            fileNamePattern = new FileNamePattern({
                title: 'Mein Muster',
                pattern: `${this.translateDocumentType({
                    documentType: this.selectedDocumentType,
                    documentOrderConfigId: this.selectedDocumentOrderConfigId,
                    shortVersion: false,
                })} - {Anspruchsteller.Vorname} {Anspruchsteller.Nachname} {Anspruchsteller.Kennzeichen}`,
                documentType: this.selectedDocumentType,
                customDocumentOrderConfigId: this.selectedDocumentOrderConfigId,
                reportTypes: remainingReportTypes,
            });
        }
        this.fileNamePatterns.push(fileNamePattern);
        try {
            await this.fileNamePatternService.create(fileNamePattern);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Anlage eines Dateinamen-Musters gescheitert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }

        this.selectFileNamePattern(fileNamePattern);
    }

    public copyFileNamePattern(fileNamePattern: FileNamePattern) {
        // Copy everything except the ID.
        const copy: FileNamePattern = new FileNamePattern(JSON.parse(JSON.stringify(omit(fileNamePattern, '_id'))));

        this.createFileNamePattern(copy);
    }

    public async saveFileNamePattern() {
        try {
            await this.fileNamePatternService.put(this.selectedFileNamePattern);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Speichern eines Dateinamen-Musters gescheitert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public async deleteFileNamePattern(fileNamePattern: FileNamePattern) {
        removeFromArray(fileNamePattern, this.fileNamePatterns);

        try {
            await this.fileNamePatternService.delete(fileNamePattern._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Löschen eines Dateinamen-Musters gescheitert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }

        this.selectFileNamePattern(this.fileNamePatterns[0]);
    }

    public insertPlaceholder(placeholder: string) {
        this.selectedFileNamePattern.pattern += `{${placeholder}}`;
    }

    public getFilenameForPattern(pattern: FileNamePattern['pattern']) {
        const filenameDirty = this.replacePlaceholders(pattern);

        // Clean filename
        return removeIllegalCharactersFromFilename(filenameDirty);
    }

    public replacePlaceholders(textWithPlaceholders: string) {
        // These resources may not be available right away after loading.
        if (!this.placeholderValues || !this.fieldGroupConfigs) {
            return textWithPlaceholders;
        }

        return replacePlaceholders({
            textWithPlaceholders: textWithPlaceholders,
            placeholderValues: this.placeholderValues,
            fieldGroupConfigs: this.fieldGroupConfigs,
            isHtmlAllowed: false,
        });
    }

    /**
     * Check if there are multiple file name patterns for the same report type.
     */
    public getDuplicatePatterns(): { fileNamePatternTitle: FileNamePattern['title']; reportTypes: ReportType[] }[] {
        if (!this.fileNamePatterns) return [];

        const otherFileNamePatterns: FileNamePattern[] = this.fileNamePatterns.filter(
            (fileNamePattern) => fileNamePattern != this.selectedFileNamePattern,
        );

        const duplicates: { fileNamePatternTitle: FileNamePattern['title']; reportTypes: ReportType[] }[] = [];

        for (const otherFileNamePattern of otherFileNamePatterns) {
            const duplicatedReportTypes: ReportType[] = otherFileNamePattern.reportTypes.filter((othersReportType) =>
                this.selectedFileNamePattern.reportTypes.includes(othersReportType),
            );
            if (duplicatedReportTypes.length) {
                duplicates.push({
                    fileNamePatternTitle: otherFileNamePattern.title,
                    reportTypes: duplicatedReportTypes,
                });
            }
        }

        return duplicates;
    }

    translateReportType = translateReportType;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END File Name Patterns
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.close.emit();
        }
    }

    public closeDialog() {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                // Don't handle this event if a child dialog is open.
                if (this.reportTypeSelectionOpen) return;
                event.stopPropagation();
                this.closeDialog();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

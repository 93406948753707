import { getSelectedGarageFeeSet } from '@autoixpert/lib/contact-people/garages/get-selected-garage-fee-set';
import { isEqualAx } from '@autoixpert/lib/is-equal-ax';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { Report } from '@autoixpert/models/reports/report';

/**
 * Determines whether the currently selected garage fees are equal to the latest exported fees (to a calculation provider).
 * This function currently does not respect dekra fee sets, as they are not stored in the garage and exported fee set.
 * @returns True if the garage fees changed, false if not.
 */
export function didGarageFeesChange({ report, garageFeeSet }: { report: Report; garageFeeSet: GarageFeeSet }): boolean {
    // coalesce all falsy values to null, so that isEqualAx() treats undefined and null as equal
    const exportedFeeSet = report.damageCalculation.repair.exportedFeeSet ?? null;
    const selectedFeeSet = garageFeeSet || getSelectedGarageFeeSet(report.garage) || null;

    // Other than JSON.stringify(), lodash's isEqual() establishes equality even if the order of the properties is changed which happens by chance.
    return !isEqualAx(exportedFeeSet, selectedFeeSet, true);
}

<!-- Decreases -->
<div id="decreases">
    <div class="corrections-title-row">
        <div class="corrections-title">
            <mat-icon id="decrease-heading-icon" class="medium-icon margin-right-8">arrow_cool_down</mat-icon>
            <h3>Abwertungen</h3>
            <span
                class="help-indicator"
                matTooltip="Abzüge vom Fahrzeug-Grundwert durch Vor- oder Altschäden am Fahrzeug.">
                ?
            </span>
        </div>
    </div>

    <div
        class="correction-items-container"
        cdkDropList
        (cdkDropListDropped)="reorderLineItemsArray($event, 'decrease')">
        <replacement-value-correction-line
            #descriptionInputsValueDecrease
            *ngFor="let lineItem of report.valuation.replacementValueCorrectionConfig.decreases; let index = index"
            [repairExecutionTypeShown]="true"
            [correctionItem]="lineItem"
            [report]="report"
            (triggerAddItem)="addLineItem('decrease'); emitChange()"
            (triggerRemoveItem)="removeLineItem($event, 'decrease'); calculateTotal('decrease'); emitChange()"
            (valuesChanged)="calculateTotal('decrease'); emitChange()"
            [isLast]="report.valuation.replacementValueCorrectionConfig.decreases.length - 1 === index"
            type="decrease"
            cdkDrag>
            <mat-icon matTooltip="Position verschieben" *ngIf="!isReportLocked" cdkDragHandle>drag_indicator</mat-icon>
        </replacement-value-correction-line>
        <div class="new-correction-item-button-container">
            <button class="flat" (click)="addLineItem('decrease'); emitChange()" *ngIf="!isReportLocked">
                + Neue Abwertung
            </button>
        </div>
    </div>
</div>

<button
    class="flat show-value-increase-button"
    *ngIf="!isReportLocked && !report.valuation.replacementValueCorrectionConfig.increases?.length"
    (click)="addLineItem('increase'); emitChange()">
    <mat-icon class="button-icon">arrow_warm_up</mat-icon>
    Aufwertungen
</button>

<!-- Increases -->
<div id="increases" *ngIf="report.valuation.replacementValueCorrectionConfig.increases?.length">
    <div class="corrections-title-row">
        <div class="corrections-title">
            <mat-icon id="increase-heading-icon" class="medium-icon margin-right-8">arrow_warm_up</mat-icon>
            <h3>Aufwertungen</h3>
            <span class="help-indicator" matTooltip="Erhöhung des Fahrzeug-Grundwerts.">?</span>
        </div>
    </div>
    <div
        class="correction-items-container"
        cdkDropList
        (cdkDropListDropped)="reorderLineItemsArray($event, 'increase')">
        <replacement-value-correction-line
            #descriptionInputsValueIncrease
            *ngFor="let lineItem of report.valuation.replacementValueCorrectionConfig.increases; let index = index"
            [correctionItem]="lineItem"
            [report]="report"
            (triggerAddItem)="addLineItem('increase'); emitChange()"
            (triggerRemoveItem)="removeLineItem($event, 'increase'); calculateTotal('increase'); emitChange()"
            (valuesChanged)="calculateTotal('increase'); emitChange()"
            [isLast]="report.valuation.replacementValueCorrectionConfig.increases.length - 1 === index"
            type="increase"
            cdkDrag>
            <mat-icon matTooltip="Position verschieben" *ngIf="!isReportLocked" cdkDragHandle>drag_indicator</mat-icon>
        </replacement-value-correction-line>
        <div class="new-correction-item-button-container">
            <button class="flat" (click)="addLineItem('increase'); emitChange()" *ngIf="!isReportLocked">
                + Neue Aufwertung
            </button>
        </div>
    </div>
</div>

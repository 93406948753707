<div id="layout-container">
    <!--============================================-->
    <!-- Menu -->
    <!--============================================-->
    <div id="menu-column"></div>
    <!--============================================-->
    <!-- END Menu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Reporting Column -->
    <!--============================================-->
    <div id="reporting-column">
        <!--============================================-->
        <!-- Graph -->
        <!--============================================-->
        <div id="graph-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Graph Header -->
            <!--============================================-->
            <div id="graph-header">
                <!--********** Report Name **********-->
                <div id="report-name-container">
                    <mat-icon
                        class="toolbar-icon"
                        matTooltip="Zurück zur Übersicht"
                        (click)="navigateBackToAnalyticsList()">
                        arrow_back
                    </mat-icon>
                    <h2>
                        {{ chartTitle }}
                        <span
                            class="help-indicator"
                            matTooltip="Summe des Umsatzes abgeschlossener Gutachten nach Typ (Haftpflichtschaden, Bewertung etc.). Rechnungen ohne verknüpftes Gutachten werden automatisch herausgefiltert.">
                            ?
                        </span>
                    </h2>
                </div>

                <analytics-filter-button [filterRef]="filter"></analytics-filter-button>

                <div class="date-and-settings-container">
                    <!--********** Date Filter **********-->
                    <date-range-picker
                        [(dateFrom)]="filterAnalyticsFrom"
                        [(dateTo)]="filterAnalyticsTo"
                        (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
                    <analytics-settings-menu (useNetValuesChange)="refreshLocalData()"></analytics-settings-menu>
                </div>
            </div>

            <!--============================================-->
            <!-- END Graph Header -->
            <!--============================================-->

            <analytics-filter (filterChange)="updateAnalytics()" (initialized)="filtersLoaded()"></analytics-filter>

            <div class="chart-container">
                <div class="chart-placeholder" *ngIf="analyticsPending">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Lade Auswertung ...
                </div>
                <div class="chart-placeholder" *ngIf="!analyticsPending && records.length === 0">
                    <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                    <div class="no-data-found-message">Keine Daten gefunden</div>
                    Probiere doch andere Filter.
                </div>
                <div id="multiple-charts-container" *ngIf="!analyticsPending && records.length">
                    <div id="bar-chart"></div>
                    <div id="doughnut-chart"></div>
                </div>
            </div>

            <!--============================================-->
            <!-- Graph Summary -->
            <!--============================================-->
            <div id="graph-summary-container" class="card">
                <!--********** Total **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Gesamtumsatz
                        <span class="series-color analytics-series-1"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ totalRevenue | number: '1.0-0' }} €</div>
                </div>

                <!--********** Unpaid Absolute**********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Gutachten</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalNumberOfReports | number: '1.0-0' }}
                    </div>
                </div>

                <!--********** Average **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Umsatz pro Gutachten</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalRevenue / totalNumberOfReports || 0 | number: '1.0-0' }} €
                    </div>
                </div>

                <!--********** Gutachtentyp **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Gutachtentyp</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ records.length }}
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Graph Summary -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Graph -->
        <!--============================================-->

        <!--============================================-->
        <!-- Series List -->
        <!--============================================-->
        <div id="series-list-card" class="card card-with-padding">
            <div id="series-list-toolbar">
                <export-invoice-analytics-records-icon
                    [records]="records"
                    [summaryDownloadName]="chartTitle"></export-invoice-analytics-records-icon>
            </div>
            <div id="series-list-table-container">
                <table id="series-list">
                    <thead>
                        <tr>
                            <th class="series-entry-title-heading">Gutachtentypen</th>
                            <th class="series-entry-value-heading">
                                <span class="series-color analytics-series-1"></span>
                                Gesamtumsatz
                                <span *ngIf="settings.useNetValues">(netto)</span>
                                <span *ngIf="!settings.useNetValues">(brutto)</span>
                            </th>
                            <th class="series-entry-value-heading">Gutachten</th>
                            <th class="series-entry-value-heading">Umsatz pro Gutachten</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="series-entry-row" *ngFor="let record of records">
                            <!--********** Intermediary Name **********-->
                            <td class="series-entry-title-cell">
                                {{ getReportTypeGerman(record.reportType) || 'Ohne Gutachtentyp' }}
                            </td>

                            <!--********** Revenue **********-->
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross)
                                        | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfAllInvoices"
                                    [displayNetValues]="settings.useNetValues">
                                    {{ record.idsOfRegularInvoices.length }}
                                    {{ record.idsOfRegularInvoices.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                </div>
                            </td>

                            <!--********** Number of Reports **********-->
                            <td class="series-entry-value">{{ record.totalNumberOfReports }} Stk.</td>

                            <!--********** Revenue per Report **********-->
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross) /
                                        record.totalNumberOfReports || 0 | number: '1.0-0'
                                }}
                                €
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Series List -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Reporting Column -->
    <!--============================================-->
</div>

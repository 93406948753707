import { User } from '../../models/user/user';

export function getUserFullName(user: User | undefined, fallback?: string): string {
    if (!user) return fallback;

    const firstName = (user.firstName || '').trim();
    const lastName = (user.lastName || '').trim();

    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }

    return firstName || lastName || user.email || '';
}

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { CanDeactivateGuard } from '../shared/services/can-deactivate-guard.service';
import { HelpComponent } from './help.component';
import { HelpcenterComponent } from './helpcenter/helpcenter.component';

const helpRoutes: Routes = [
    {
        path: 'Hilfe',
        component: HelpComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            {
                path: '',
                component: HelpcenterComponent,
            },
        ],
    },
];

export const helpRouting = RouterModule.forChild(helpRoutes);

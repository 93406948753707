import { Report } from '../../models/reports/report';
import { getCarOwner } from './get-car-owner';

/**
 * Indicates weather the car owner may deduct taxes.
 * The car owner is either the claimant or the owner of the claimants car.
 */
export function mayCarOwnerDeductTaxes(report: Pick<Report, 'claimant' | 'ownerOfClaimantsCar'>): boolean | null {
    return (
        getCarOwner(report).contactPerson.mayDeductTaxes ??
        // Return null if the value is undefined.
        null
    );
}

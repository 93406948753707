import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { BankAccountForSync } from '@autoixpert/models/bank-account-sync/bank-account-for-sync';

@Injectable()
export class BankAccountService {
    constructor(private httpClient: HttpClient) {}

    public find(): Observable<BankAccountForSync[]> {
        return this.httpClient.get<BankAccountForSync[]>(`${apiBasePath}/bankAccountSync/accounts`);
    }

    public patch(bankAccount: BankAccountForSync): Observable<void> {
        return this.httpClient.patch<void>(`${apiBasePath}/bankAccountSync/accounts/${bankAccount._id}`, bankAccount);
    }

    public delete(bankAccountId: string): Observable<void> {
        return this.httpClient.delete<void>(`${apiBasePath}/bankAccountSync/accounts/${bankAccountId}`);
    }
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container" *ngIf="variant">
        <!-- Close button -->
        <div id="close-button" (click)="closeAndDiscard()">
            <mat-icon>close</mat-icon>
        </div>

        <!-- Headline -->
        <div class="editor-headline">
            <h2>Variante bearbeiten</h2>
            <div>{{ buildingBlock.title }}</div>
        </div>

        <!-- Building block conditions info callout -->
        <div *ngIf="buildingBlock.conditions?.length" class="building-block-conditions-info-callout">
            <div class="building-block-conditions-info-callout-icon-container">
                <mat-icon class="outlined">info</mat-icon>
            </div>
            <div class="building-block-conditions-info-callout-text">
                Der
                <span class="link" (click)="handleBuildingBlockConditionsInfoCalloutClick()">Baustein selbst</span>
                hat auch Bedingungen, die diese Variante möglicherweise ausblenden.
            </div>
        </div>

        <!-- Condition group editor -->
        <document-building-block-condition-group-editor
            *ngIf="!variant.isDefaultVariant"
            [availableDocumentPlaceholders]="availableDocumentPlaceholders"
            [placeholderValues]="placeholderValues"
            [placeholderValueTree]="placeholderValueTree"
            [fieldGroupConfigs]="fieldGroupConfigs"
            [conditionGroup]="variant"></document-building-block-condition-group-editor>

        <!-- No conditions placeholder -->
        <div
            *ngIf="!(variant.conditions ?? []).length && !variant.isDefaultVariant"
            id="no-conditions-placeholder-note">
            Ohne Bedingungen wird diese Variante immer gedruckt.
        </div>

        <!-- Default variant info callout -->
        <h3 *ngIf="variant.isDefaultVariant" [style.padding]="'0px 20px'">Bedingungen</h3>

        <div *ngIf="variant.isDefaultVariant" class="default-variant-placeholder">
            <div class="default-variant-placeholder-icon-container">
                <mat-icon>web_stories</mat-icon>
            </div>

            <div class="default-variant-placeholder-info-text">
                <div class="default-variant-placeholder-info-text-heading">Auffang-Variante für diesen Baustein</div>
                <div class="default-variant-placeholder-info-text-description">
                    Diese Variante wird gedruckt, wenn die Bedingungen für keine andere Variante erfüllt sind. Du kannst
                    deshalb keine Bedingungen angeben.
                </div>
            </div>
        </div>

        <!-- Variant editor container -->
        <div id="variant-editor-container-wrapper">
            <div id="variant-editor-container">
                <div id="template-container">
                    <h3>
                        Vorlage
                        <span
                            class="help-indicator"
                            [matTooltip]="
                                'Setze Platzhalter in die Textbausteine ein, indem Du {MeinPlatzhalter} tippst.\n\nBeispiel: {Gutachten.Aktenzeichen}'
                            ">
                            ?
                        </span>
                    </h3>

                    <!--============================================-->
                    <!-- Heading -->
                    <!--============================================-->
                    <placeholder-autocomplete-input
                        placeholder="Überschrift"
                        [(text)]="variant.heading"
                        [documentBuildingBlock]="buildingBlock"></placeholder-autocomplete-input>
                    <!--============================================-->
                    <!-- END Heading -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Content -->
                    <!--============================================-->
                    <placeholder-autocomplete-quill
                        placeholder="Text"
                        [(ngModel)]="variant.content"
                        [documentBuildingBlockPlaceholder]="buildingBlock.placeholder"></placeholder-autocomplete-quill>
                    <!--============================================-->
                    <!-- END Content -->
                    <!--============================================-->
                </div>
                <div id="preview-container" *ngIf="variant.heading || variant.content">
                    <h3>
                        Vorschau mit Beispieltexten
                        <span
                            class="help-indicator"
                            matTooltip="Alle durch geschweifte Klammern kenntlich gemachten Platzhalter , z. B. {MeinPlatzhalter}, werden durch Beispieltexte ersetzt.">
                            ?
                        </span>
                    </h3>
                    <div id="preview-text-container">
                        <div id="preview-heading">{{ replacePlaceholders(variant.heading, false) }}</div>
                        <div
                            *ngIf="variant.content"
                            id="preview-body"
                            [style.overflow]="'hidden'"
                            [quillContent]="replacePlaceholders(variant.content)"></div>
                    </div>
                </div>
                <div id="buttons-container">
                    <button
                        (click)="isSavingAllowed() && closeAndSave()"
                        [class.disabled]="!isSavingAllowed()"
                        [matTooltip]="getSavingButtonTooltip()">
                        Speichern
                    </button>
                    <button class="flat" (click)="closeAndDiscard()">Abbrechen</button>
                </div>
            </div>
        </div>
    </div>
</div>

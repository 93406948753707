<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="emitCloseEvent()">close</mat-icon>
        <h2>Büro-Account kündigen</h2>

        <p class="text-align-center">
            Wir bedauern sehr, dass du den Vertrag mit autoiXpert kündigen möchtest.
            <br />
            Dein Vertrag läuft noch bis zum {{ expirationDate | moment: 'DD.MM.YYYY' }} 📅
        </p>
        <p class="text-align-center">
            Deine Daten werden wir für 6 Monate ab Vertragsende speichern. In dieser Zeit kannst du den Account über die
            Hotline wieder reaktivieren. Danach werden deine Daten endgültig gelöscht.
        </p>
        <p class="text-align-center">
            Kontakte kannst du selbst exportieren, Infos dazu findest du in folgendem Info-Artikel:
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/12997402696978-Adressdaten-exportieren"
                target="_blank"
                rel="”noopener”">
                Adressdaten exportieren
            </a>
            . Falls du Hilfe beim Exportieren deiner Gutachten-PDFs brauchst, wende dich bitte an unsere Hotline.
        </p>

        <p class="text-align-center">
            Bitte halte uns in positiver Erinnerung, empfiehl uns weiter und komm gerne wieder zu uns zurück, wenn die
            Zeit dafür gekommen ist. Du bist jederzeit bei uns willkommen! 🎉
        </p>

        <div class="dialog-buttons-container">
            <div id="cancel-button-container">
                <button id="cancel-button" class="secondary delete" (click)="confirm()">Bestätigen</button>
            </div>
            <button class="secondary" (click)="emitCloseEvent()">Abbrechen</button>
        </div>
    </div>
</div>

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import moment from 'moment/moment';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { Report } from '@autoixpert/models/reports/report';
import { ContactPersonService } from '../../services/contact-person.service';
import { InvoiceNumberService } from '../../services/invoice-number.service';
import { ReportTokenAndInvoiceNumberService } from '../../services/report-token-and-invoice-number.service';
import { ReportTokenService } from '../../services/report-token.service';
import { ToastService } from '../../services/toast.service';
import { rememberContactPeopleInReport } from './remember-contact-people-in-report';

export async function lockReport(
    report: Report,
    userId: string,
    reportTokenService: ReportTokenService,
    reportTokenAndInvoiceNumberService: ReportTokenAndInvoiceNumberService,
    invoiceNumberService: InvoiceNumberService,
    contactPersonService: ContactPersonService,
    toastService: ToastService,
    dialog: MatDialog,
): Promise<string[]> {
    const completedTasks: string[] = [];

    report.state = 'done';
    report.lockedAt = moment().format();
    report.lockedBy = userId;

    /**
     * Ensure that the assessor's fee does not change when the user opens the report in the future to change something unrelated to the invoice fee
     * but the assessor's fee tables have changed in the meantime. Then, the invoice would change without the user expecting it.
     */
    report.feeCalculation.assessorsFeeFrozen = true;

    // Completion date
    if (!report.completionDate) {
        report.completionDate = todayIso();
        completedTasks.push(`Fertigstellungsdatum gesetzt`);
    }

    // Invoice-related data
    if (!report.feeCalculation.skipWritingInvoice && !report.feeCalculation.isCollectiveInvoice) {
        // Invoice Date
        if (!report.feeCalculation.invoiceParameters.date) {
            report.feeCalculation.invoiceParameters.date = report.completionDate;
            completedTasks.push(`Rechnungsdatum gesetzt`);
        }
    }
    await rememberContactPeopleInReport(report, contactPersonService);

    //*****************************************************************************
    //  Fill in Report Token & Invoice Number
    //****************************************************************************/

    /**
     * If the report token is not synced with the invoice number, we need to generate a new report token and invoice number at once.
     */
    const isInvoiceNumberLeading = reportTokenService.isInvoiceNumberSyncedAndLeading(report);
    generateReportTokenAndInvoiceNumber: if (isInvoiceNumberLeading) {
        /**
         * Only generate a new report token and invoice number if:
         *  - the invoice number is missing and an invoice should be written.
         *  - the report token is missing - if no invoice should be written, we calculate the number anyways since they are in sync.
         */
        if (
            !report.token ||
            (!report.feeCalculation.invoiceParameters.number &&
                !report.feeCalculation.skipWritingInvoice &&
                !report.feeCalculation.isCollectiveInvoice)
        ) {
            let reportToken: string;
            let invoiceNumber: string;

            //*****************************************************************************
            //  Confirm Creation of Token & Number
            //****************************************************************************/
            const decision = await dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Aktenzeichen & Rechnungsnummer',
                        content:
                            'Aktuell fehlt mindestens eines der beiden. Möchtest du die fehlende(n) Nummer(n) vergeben?',
                        confirmLabel: 'Erzeugen',
                        cancelLabel: 'Überspringen',
                        confirmColorRed: false,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) break generateReportTokenAndInvoiceNumber;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Confirm Creation of Token & Number
            /////////////////////////////////////////////////////////////////////////////*/

            try {
                ({ reportToken, invoiceNumber } =
                    await reportTokenAndInvoiceNumberService.generateReportTokenAndInvoiceNumber(report));
            } catch (error) {
                toastService.warn(
                    'Rechnungs-Nr./Aktenzeichen nicht ermittelt',
                    'Bitte schließe das Gutachten erneut ab. Sollte der Fehler bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
                throw error;
            }

            reportTokenAndInvoiceNumberService.writeToReport(report, reportToken, invoiceNumber);

            // Display this step only if the user expects an invoice to be written.
            if (!report.feeCalculation.skipWritingInvoice && !report.feeCalculation.isCollectiveInvoice) {
                completedTasks.push(`Rechnungs-Nr. ermittelt`);
            }
            completedTasks.push(`Aktenzeichen ermittelt`);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fill in Report Token & Invoice Number
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fill in Invoice Number
    //****************************************************************************/

    // If no invoice number was set or generated yet, generate a new one. Don't generate the invoice number if we'd like to skip the invoice or a collective invoice will be created later on.
    else {
        generateInvoiceNumber: if (
            !report.feeCalculation.invoiceParameters.number &&
            !report.feeCalculation.skipWritingInvoice &&
            !report.feeCalculation.isCollectiveInvoice
        ) {
            //*****************************************************************************
            //  Confirm Creation of Number
            //****************************************************************************/
            const decision = await dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Rechnungsnummer fehlt',
                        content: 'Möchtest du eine Rechnungsnummer vergeben?',
                        confirmLabel: 'Erzeugen',
                        cancelLabel: 'Überspringen',
                        confirmColorRed: false,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) break generateInvoiceNumber;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Confirm Creation of Number
            /////////////////////////////////////////////////////////////////////////////*/

            report.feeCalculation.invoiceParameters.number = await invoiceNumberService.generateInvoiceNumber({
                officeLocationId: report.officeLocationId,
                responsibleAssessorId: report.responsibleAssessor,
                report,
            });
            completedTasks.push(`Rechnungs-Nr. ermittelt`);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Fill in Invoice Number
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Fill in Report Token
        //****************************************************************************/

        // If no report token was set or generated yet, generate a new one.
        generateReportToken: if (!report.token) {
            //*****************************************************************************
            //  Confirm Creation of Token & Number
            //****************************************************************************/
            const decision = await dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Aktenzeichen fehlt',
                        content: 'Möchtest du ein Aktenzeichen vergeben?',
                        confirmLabel: 'Erzeugen',
                        cancelLabel: 'Überspringen',
                        confirmColorRed: false,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) break generateReportToken;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Confirm Creation of Token & Number
            /////////////////////////////////////////////////////////////////////////////*/
            report.token = await reportTokenService.generateReportToken(report);
            completedTasks.push(`Aktenzeichen ermittelt`);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fill in Report Token
    /////////////////////////////////////////////////////////////////////////////*/

    return completedTasks;
}

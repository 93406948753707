import { UnprocessableEntity } from '../../models/errors/ax-error';
import { DiminishedValueCalculation } from '../../models/reports/diminished-value/diminished-value-calculation';
import { fullTaxationRate } from '../../static-data/taxation-rates';

//*****************************************************************************
//  Sync with Backend/Frontend
//****************************************************************************/
// This class is also used in backend-autoixpert for creating the diminished value protocol.
// Please sync changes between the two files.
/////////////////////////////////////////////////////////////////////////////*/
//  END Sync with Backend/Frontend
/////////////////////////////////////////////////////////////////////////////*/

// Factor used to convert a gross value (including 19 % tax) back to net
const DEDUCT_VAT_FACTOR = 1 + fullTaxationRate;

export class DiminishedValueMethods {
    static calculateMfm(diminishedValueCalculation: DiminishedValueCalculation): number {
        const reduceGrossValuesByVat =
            diminishedValueCalculation.isBasedOnNetValues && diminishedValueCalculation.reduceInputValuesByVat;

        const originalPrice = reduceGrossValuesByVat
            ? diminishedValueCalculation.originalPrice / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.originalPrice;
        const timeValue = reduceGrossValuesByVat
            ? diminishedValueCalculation.timeValue / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.timeValue;
        const repairCosts = reduceGrossValuesByVat
            ? diminishedValueCalculation.totalRepairCosts / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.totalRepairCosts;
        const damageLevel = diminishedValueCalculation.damageLevel;
        const ageInMonths = diminishedValueCalculation.ageInMonths;
        const marketability = diminishedValueCalculation.marketabilityMfm;
        const previousDamage = diminishedValueCalculation.previousDamageMfm;

        const warningMessages: string[] = [];
        // If inputs are missing, show only that specific error message. The user should complete inputs first.
        if (
            !originalPrice ||
            !timeValue ||
            !repairCosts ||
            !damageLevel ||
            typeof ageInMonths === 'undefined' ||
            !marketability ||
            !previousDamage
        ) {
            warningMessages.push('Eingaben fehlen');
        }

        if (warningMessages.length > 0) {
            throw new UnprocessableEntity({
                code: 'ERROR_CALCULATING_RUHKOPF_METHOD',
                message: 'An error occurred calculating the Ruhkopf/Sahm method.',
                data: {
                    warningMessages,
                },
            });
        }

        return (
            (timeValue / 100 +
                (timeValue / originalPrice) *
                    repairCosts *
                    damageLevel *
                    DiminishedValueMethods.getAgeFactorMfm(ageInMonths)) *
            marketability *
            previousDamage
        );
    }

    /**
     * This method is separated from the MFM method because its value is needed separately for the diminished value protocol.
     */
    static getAgeFactorMfm(ageInMonths): number {
        const ageFactors = [
            [120, 0.0],
            [119, 0.002],
            [118, 0.0061],
            [117, 0.01],
            [116, 0.0137],
            [115, 0.0173],
            [114, 0.0206],
            [113, 0.0238],
            [112, 0.0268],
            [111, 0.0296],
            [110, 0.0322],
            [109, 0.0347],
            [108, 0.037],
            [107, 0.0391],
            [106, 0.0411],
            [105, 0.0429],
            [104, 0.0446],
            [103, 0.0461],
            [102, 0.0476],
            [101, 0.0488],
            [100, 0.05],
            [99, 0.051],
            [98, 0.052],
            [97, 0.0528],
            [96, 0.0535],
            [95, 0.0541],
            [94, 0.0547],
            [93, 0.0551],
            [92, 0.0555],
            [91, 0.0558],
            [90, 0.0561],
            [89, 0.0563],
            [88, 0.0564],
            [87, 0.0565],
            [86, 0.0566],
            [85, 0.0566],
            [84, 0.0566],
            [83, 0.0566],
            [82, 0.0565],
            [81, 0.0565],
            [80, 0.0564],
            [79, 0.0564],
            [78, 0.0564],
            [77, 0.0563],
            [76, 0.0563],
            [75, 0.0564],
            [74, 0.0564],
            [73, 0.0566],
            [72, 0.0567],
            [71, 0.0569],
            [70, 0.0572],
            [69, 0.0575],
            [68, 0.0579],
            [67, 0.0583],
            [66, 0.0588],
            [65, 0.0594],
            [64, 0.0601],
            [63, 0.0609],
            [62, 0.0618],
            [61, 0.0627],
            [60, 0.0638],
            [59, 0.0649],
            [58, 0.0662],
            [57, 0.0676],
            [56, 0.069],
            [55, 0.0706],
            [54, 0.0723],
            [53, 0.0742],
            [52, 0.0761],
            [51, 0.0781],
            [50, 0.0803],
            [49, 0.0826],
            [48, 0.085],
            [47, 0.0875],
            [46, 0.0902],
            [45, 0.093],
            [44, 0.0959],
            [43, 0.0989],
            [42, 0.102],
            [41, 0.1052],
            [40, 0.1085],
            [39, 0.112],
            [38, 0.1155],
            [37, 0.1192],
            [36, 0.1229],
            [35, 0.1268],
            [34, 0.1307],
            [33, 0.1347],
            [32, 0.1388],
            [31, 0.143],
            [30, 0.1472],
            [29, 0.1515],
            [28, 0.1558],
            [27, 0.1602],
            [26, 0.1646],
            [25, 0.169],
            [24, 0.1734],
            [23, 0.1779],
            [22, 0.1823],
            [21, 0.1867],
            [20, 0.1911],
            [19, 0.1954],
            [18, 0.1997],
            [17, 0.204],
            [16, 0.2081],
            [15, 0.2122],
            [14, 0.2161],
            [13, 0.2199],
            [12, 0.2236],
            [11, 0.2271],
            [10, 0.2305],
            [9, 0.2336],
            [8, 0.2366],
            [7, 0.2393],
            [6, 0.2417],
            [5, 0.2439],
            [4, 0.2458],
            [3, 0.2474],
            [2, 0.2486],
            [1, 0.2495],
            [0, 0.25],
        ];

        // Find the lowest number of months that the car's age exceeds.
        for (const [months, factor] of ageFactors) {
            if (ageInMonths - months >= 0) {
                return factor;
            }
        }
        return 0;
    }

    static calculateBvsk(diminishedValueCalculation: DiminishedValueCalculation): number {
        const reduceGrossValuesByVat =
            diminishedValueCalculation.isBasedOnNetValues && diminishedValueCalculation.reduceInputValuesByVat;

        const replacementValue = reduceGrossValuesByVat
            ? diminishedValueCalculation.replacementValue / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.replacementValue;
        const damageIntensity = diminishedValueCalculation.damageIntensity;
        const marketability = diminishedValueCalculation.marketabilityBvsk;
        const previousDamage = diminishedValueCalculation.previousDamageBvsk;

        const warningMessages: string[] = [];
        if (
            !replacementValue ||
            damageIntensity === null ||
            typeof marketability === 'undefined' ||
            marketability === null ||
            !previousDamage
        ) {
            warningMessages.push('Eingaben fehlen');
        }

        if (warningMessages.length > 0) {
            throw new UnprocessableEntity({
                code: 'ERROR_CALCULATING_BVSK_METHOD',
                message: 'An error occurred calculating the BVSK method.',
                data: {
                    warningMessages,
                },
            });
        }

        return (replacementValue * previousDamage * (damageIntensity + marketability)) / 100;
    }

    static calculateHalbgewachs(diminishedValueCalculation: DiminishedValueCalculation): number {
        const reduceGrossValuesByVat =
            diminishedValueCalculation.isBasedOnNetValues && diminishedValueCalculation.reduceInputValuesByVat;

        const originalPrice = reduceGrossValuesByVat
            ? diminishedValueCalculation.originalPrice / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.originalPrice;
        const timeValue = reduceGrossValuesByVat
            ? diminishedValueCalculation.timeValue / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.timeValue;
        const ageInMonths = diminishedValueCalculation.ageInMonths;
        const totalRepairCosts = reduceGrossValuesByVat
            ? diminishedValueCalculation.totalRepairCosts / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.totalRepairCosts;
        const laborCosts = reduceGrossValuesByVat
            ? diminishedValueCalculation.laborCosts / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.laborCosts;
        const materialCosts = reduceGrossValuesByVat
            ? diminishedValueCalculation.materialCosts / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.materialCosts;

        const warningMessages: string[] = [];
        // If inputs are missing, show only that specific error message. The user should complete inputs first.
        if (
            !originalPrice ||
            !timeValue ||
            typeof ageInMonths === 'undefined' ||
            ageInMonths === null ||
            !totalRepairCosts ||
            !laborCosts ||
            materialCosts == null // Loose equality check for null or undefined. Because materialCost = 0 does not mean that input values are missing
        ) {
            warningMessages.push('Eingaben fehlen');
        } else {
            if (totalRepairCosts / timeValue < 0.1)
                warningMessages.push('Bagatellschaden: Die Reparaturkosten sind geringer als 10% des Zeitwertes.');
            if (totalRepairCosts / timeValue > 1.3)
                warningMessages.push(
                    'Wirtschaftlicher Totalschaden: Die Reparaturkosten übersteigen 130 % des Zeitwertes.',
                );
            if (timeValue / originalPrice < 0.4)
                warningMessages.push('Der Zeitwert ist geringer als 40% des Neupreises.');
            if (ageInMonths > 72) warningMessages.push('Das Fahrzeug ist älter als 72 Monate.');
            if (laborCosts / materialCosts < 0.4)
                warningMessages.push('Das Verhältsnis von Lohn- zu Ersatzteilkosten liegt unter 40 %.');
            // Material costs need to be greater than 0, otherwise we divide by zero.
            if (materialCosts <= 0) warningMessages.push('Ersatzteilkosten müssen über 0 € liegen.');
            if (warningMessages.length >= 1) {
                warningMessages.unshift('Methode ausgeschlossen.');
            }
        }

        if (warningMessages.length > 0) {
            throw new UnprocessableEntity({
                code: 'ERROR_CALCULATING_HALBGEWACHS_METHOD',
                message: 'An error occurred calculating the Halbgewachs method.',
                data: {
                    warningMessages,
                },
            });
        }

        // Factor 100 of the X-factor and dividend 100 cancel each other out
        return (
            DiminishedValueMethods.getXValueHalbgewachs({
                ageInMonths,
                totalRepairCosts,
                timeValue,
                laborCosts,
                materialCosts,
            }) *
            (timeValue + totalRepairCosts)
        );
    }

    static getXValueHalbgewachs({
        ageInMonths,
        totalRepairCosts,
        timeValue,
        laborCosts,
        materialCosts,
    }: {
        ageInMonths: number;
        totalRepairCosts: number;
        timeValue: number;
        laborCosts: number;
        materialCosts: number;
    }): number {
        const Xm =
            -1.831 * 10 ** -7 * ageInMonths ** 3 +
            1.954 * 10 ** -5 * ageInMonths ** 2 -
            1.051 * 10 ** -3 * ageInMonths +
            0.03175;

        const repairCostRatio = (100 * totalRepairCosts) / timeValue;
        const Xk = -7.7828 * 10 ** -7 * repairCostRatio ** 2 + 2.081 * 10 ** -4 * repairCostRatio - 1.0722 * 10 ** -3;

        const laborMaterialRatio = (100 * laborCosts) / materialCosts;
        const Xa = 0.015354 * Math.log(laborMaterialRatio) - 0.055549;

        return Xm + Xk + Xa;
    }

    static calculateHamburgModel(diminishedValueCalculation: DiminishedValueCalculation): number {
        const reduceGrossValuesByVat =
            diminishedValueCalculation.isBasedOnNetValues && diminishedValueCalculation.reduceInputValuesByVat;

        // Although the Hamburger Model is already based on net values, we deduct the full tax rate once again from the substantial repair costs.
        // This is because the model was previously already used to calculate the diminished value for private individuals
        // and now with the latest judgement by the BGH the tax should be subtracted from the resulting diminished value.
        // This is an assumption by us. It might also be that this method is just no longer applicable with the latest judgement.
        const substantialRepairCosts = reduceGrossValuesByVat
            ? diminishedValueCalculation.substantialRepairCosts / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.substantialRepairCosts;

        // Labor and material costs do not need to be switched to net because they are used to determine a
        // ratio (hence, adding or subtracting a value to or from both values would anyway end in the same diminished value result)
        const carBodyLaborCosts = diminishedValueCalculation.laborCosts;
        const totalRepairCostsExceptPaintAndAuxiliaryCosts =
            diminishedValueCalculation.laborCosts + diminishedValueCalculation.materialCosts;

        const ageInMonths = diminishedValueCalculation.ageInMonths;
        const mileageUnit = diminishedValueCalculation.mileageUnit;
        const mileage = diminishedValueCalculation.mileage;

        const warningMessages: string[] = [];
        if (substantialRepairCosts === null || mileage === null || !mileageUnit || ageInMonths === null) {
            warningMessages.push('Eingaben fehlen');
        }

        if (warningMessages.length > 0) {
            throw new UnprocessableEntity({
                code: 'ERROR_CALCULATING_HAMBURG_MODEL',
                message: 'An error occurred calculating the Hamburg Model method.',
                data: {
                    warningMessages,
                },
            });
        }

        // Convert miles to kilometers
        let mileageKilometers: number = mileage;
        if (mileageUnit === 'mi') {
            mileageKilometers = mileage / 0.62137;
        }

        // Certain conditions prohibit the calculation
        if (ageInMonths > 5 * 12 || mileageKilometers > 100000) {
            return 0;
        }

        let mileageFactor = 0;
        const mileageLevels = [
            [20000, 0.3],
            [50000, 0.2],
            [75000, 0.15],
            [100000, 0.1],
        ];

        // Find the lowest number of kilometers that the car's mileage exceeds.
        for (const [upperKilometerLimit, factor] of mileageLevels) {
            if (mileageKilometers < upperKilometerLimit) {
                mileageFactor = factor;
                break;
            }
        }

        return (
            (substantialRepairCosts / carBodyLaborCosts) * totalRepairCostsExceptPaintAndAuxiliaryCosts * mileageFactor
        );
    }

    static calculateRuhkopf(diminishedValueCalculation: DiminishedValueCalculation): number {
        const reduceGrossValuesByVat =
            diminishedValueCalculation.isBasedOnNetValues && diminishedValueCalculation.reduceInputValuesByVat;

        const originalPrice = reduceGrossValuesByVat
            ? diminishedValueCalculation.originalPrice / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.originalPrice;
        const timeValue = reduceGrossValuesByVat
            ? diminishedValueCalculation.timeValue / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.timeValue;
        const replacementValue = reduceGrossValuesByVat
            ? diminishedValueCalculation.replacementValue / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.replacementValue;
        const repairCosts = reduceGrossValuesByVat
            ? diminishedValueCalculation.totalRepairCosts / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.totalRepairCosts;
        const ageInMonths = diminishedValueCalculation.ageInMonths;

        const warningMessages: string[] = [];
        // If inputs are missing, show only that specific error message. The user should complete inputs first.
        if (
            !originalPrice ||
            !timeValue ||
            typeof ageInMonths === 'undefined' ||
            ageInMonths === null ||
            !repairCosts
        ) {
            warningMessages.push('Eingaben fehlen');
        } else {
            if (repairCosts / originalPrice < 0.1)
                warningMessages.push('Bagatellschaden: Die Reparaturkosten sind geringer als 10% des Neupreises.');
            if (timeValue / originalPrice < 0.4)
                warningMessages.push('Der Veräußerungswert ist geringer als 40% des Neupreises.');
            if (repairCosts / replacementValue > 0.9)
                warningMessages.push('Wirtschaftlicher Totalschaden: Die Reparaturkosten übersteigen 90% des WBW.');
            if (warningMessages.length >= 1) {
                warningMessages.unshift('Methode ausgeschlossen.');
            }
        }

        if (warningMessages.length > 0) {
            throw new UnprocessableEntity({
                code: 'ERROR_CALCULATING_RUHKOPF_METHOD',
                message: 'An error occurred calculating the Ruhkopf/Sahm method.',
                data: {
                    warningMessages,
                },
            });
        }

        return (
            (timeValue + repairCosts) *
            DiminishedValueMethods.getXValueRuhkopf({
                ageInMonths,
                totalRepairCosts: repairCosts,
                timeValue,
            })
        );
    }

    /**
     * This method is separated from the Ruhkopf method because its value is needed separately for the diminished value protocol.
     */
    static getXValueRuhkopf({
        ageInMonths,
        totalRepairCosts,
        timeValue,
    }: {
        ageInMonths: number;
        totalRepairCosts: number;
        timeValue: number;
    }): number {
        const repairCostRatio = totalRepairCosts / timeValue;
        let repairCostColumn: number;

        // Determine which column of the table to get the factor from
        if (repairCostRatio > 0.6) {
            repairCostColumn = 2;
        } else if (repairCostRatio > 0.3) {
            repairCostColumn = 1;
        } else if (repairCostRatio > 0.1) {
            repairCostColumn = 0;
        } else {
            // Invalid according to table at colliseum.net
            // Minderwert.de treats values smaller than 10% the same as smaller 30%
            repairCostColumn = 0;
        }

        // Xr -> Percentage of (replacement value + repair costs) that reflects the diminished value
        const reductionFactorsYoungCars = [0.05, 0.06, 0.07];
        const reductionFactorsMediumAgedCars = [0.04, 0.05, 0.06];
        const reductionFactorsOldCars = [0.03, 0.04, 0.05];

        // Determine which row of the table to get the factor from
        if (ageInMonths > 24) {
            return reductionFactorsOldCars[repairCostColumn];
        } else if (ageInMonths > 12) {
            return reductionFactorsMediumAgedCars[repairCostColumn];
        } else {
            return reductionFactorsYoungCars[repairCostColumn];
        }
    }

    static calculateDvgt(diminishedValueCalculation: DiminishedValueCalculation): number {
        const reduceGrossValuesByVat =
            diminishedValueCalculation.isBasedOnNetValues && diminishedValueCalculation.reduceInputValuesByVat;

        // 2/3 of the total repair costs are an approximation of the relevant repair costs according to a court
        let relevantRepairCosts = (diminishedValueCalculation.totalRepairCosts * 2) / 3;
        if (reduceGrossValuesByVat) {
            relevantRepairCosts = relevantRepairCosts / DEDUCT_VAT_FACTOR;
        }
        const ageInMonths = diminishedValueCalculation.ageInMonths;
        const mileage = diminishedValueCalculation.mileage;
        const mileageUnit = diminishedValueCalculation.mileageUnit;

        const warningMessages: string[] = [];
        if (
            !relevantRepairCosts ||
            typeof ageInMonths === 'undefined' ||
            ageInMonths === null ||
            !mileage ||
            !mileageUnit
        ) {
            warningMessages.push('Eingaben fehlen');
        }
        if (warningMessages.length > 0) {
            throw new UnprocessableEntity({
                code: 'ERROR_CALCULATING_DVGT_METHOD',
                message: 'An error occurred calculating the DVGT method.',
                data: {
                    warningMessages,
                },
            });
        }

        // Convert miles to kilometers
        let mileageKilometers: number = mileage;
        if (mileageUnit === 'mi') {
            mileageKilometers = mileage / 0.62137;
        }

        let reductionFactor: number;

        if (ageInMonths > 5 * 12 || mileageKilometers > 100000) {
            reductionFactor = 0;
        } else if (ageInMonths > 4 * 12 || mileageKilometers > 80000) {
            reductionFactor = 0.1;
        } else if (ageInMonths > 3 * 12 || mileageKilometers > 60000) {
            reductionFactor = 0.15;
        } else if (ageInMonths > 2 * 12 || mileageKilometers > 40000) {
            reductionFactor = 0.2;
        } else if (ageInMonths > 12 || mileageKilometers > 20000) {
            reductionFactor = 0.25;
        } else {
            reductionFactor = 0.3;
        }

        return relevantRepairCosts * reductionFactor;
    }

    static calculateTroemner(diminishedValueCalculation: DiminishedValueCalculation): number {
        // Source: https://minderwert.de/dr-troemner/

        const warningMessages: string[] = [];
        if (
            !diminishedValueCalculation.replacementValue ||
            !diminishedValueCalculation.originalPrice ||
            !diminishedValueCalculation.marketabilityTroemner ||
            !diminishedValueCalculation.ageFactorTroemner ||
            !diminishedValueCalculation.previousDamageTroemner ||
            !diminishedValueCalculation.vehicleDamageTroemner
        ) {
            warningMessages.push('Eingaben fehlen');
        }
        if (warningMessages.length > 0) {
            throw new UnprocessableEntity({
                code: 'ERROR_CALCULATING_TROEMNER_METHOD',
                message: 'An error occurred calculating the Dr. Troemner method.',
                data: {
                    warningMessages,
                },
            });
        }

        const reduceGrossValuesByVat =
            diminishedValueCalculation.isBasedOnNetValues && diminishedValueCalculation.reduceInputValuesByVat;

        const replacementValue = reduceGrossValuesByVat
            ? diminishedValueCalculation.replacementValue / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.replacementValue;
        const originalPrice = reduceGrossValuesByVat
            ? diminishedValueCalculation.originalPrice / DEDUCT_VAT_FACTOR
            : diminishedValueCalculation.originalPrice;

        return (
            (replacementValue / 100) *
            ((replacementValue + originalPrice) / originalPrice) *
            diminishedValueCalculation.vehicleDamageTroemner *
            diminishedValueCalculation.marketabilityTroemner *
            diminishedValueCalculation.previousDamageTroemner *
            diminishedValueCalculation.ageFactorTroemner
        );
    }
}

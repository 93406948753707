import { ApiErrorHandler, ApiErrorHandlerCreator } from '../services/api-error.service';

export function getDatabaseErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        ATLAS_SEARCH_FAILED: () => ({
            title: 'Suche fehlgeschlagen',
            body: `Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        ID_COLLISION: () => ({
            title: 'Doppelte ID',
            body: `Ein Datensatz mit dieser ID existiert bereits. Lade die Seite neu.<br><br>Besteht das Problem weiterhin, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        MONGOOSE_ERROR: () => ({
            title: 'Datenbankfehler',
            body: `Lade die Seite neu.<br><br>Besteht das Problem weiterhin, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
    };
}

export class AudatexVehicleIdentification {
    audatexCode: string = null; // Concatenation of manufacturer, model and submodel codes.
    schwackeCode: string = null; // Used for valuating a vehicle through Audatex.

    // Uncomment lines that are relevant for vehicle identification.
    //EngineVariant: string; // Example: "1999ccm (2.0 Ltr) 107kW",
    //VehicleInterior: string; // Example: "Mars",
    //ManufactureDateRangeStart: string; // Example: "2001-02-01",
    //ManufactureDateRangeEnd: string; // Example: "2001-03-01",
    //VINQueryReturnCode: string; // Example: "00",
    //ImportedIntoCountry: string; // Example: "true"

    constructionTimeFrom: string = null;
    constructionTimeTo: string = null;

    isValuationPossible: boolean = null;
    isDamageCalculationPossible: boolean = null;
}

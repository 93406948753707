//*****************************************************************************
//  Description
//****************************************************************************/
// Document building blocks are usually derived from the team's settings. However, a user may overwrite those generated document building blocks
// per report. There are 3 options:
// * overwrite content
// * remove document building block (empty heading and content)
// * add document building block (either beforeDocumentBuildingBlockId or afterDocumentBuildingBlockId because the template engine needs
// to know where to insert this newly added document building block)
/////////////////////////////////////////////////////////////////////////////*/
//  END Description
/////////////////////////////////////////////////////////////////////////////*/

export class EditedDocumentBuildingBlock {
    constructor(template: Partial<EditedDocumentBuildingBlock> = {}) {
        Object.assign(this, template);
    }

    _id: string; // CHECKEN, ID anders?
    heading: string;
    content: string;
    referencePlaceholder: string;
    action: 'overwrite' | 'addBefore' | 'addAfter';
}

import { filters as FabricFilters } from 'fabric';

/**
 * Apply the given filters. They are usually passed after extraction from within photo.versions[version].fabricJsInformation.
 * @param fabricPhoto
 * @param filters
 */
export function applyFilters(fabricPhoto, filters) {
    // Initialize or reset the filters on the fabric photo
    fabricPhoto.filters = [];

    if (+filters['brightness'].value !== 0) {
        fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Brightness({
            // Change the scale from [-100, 100] to [-1, 1]
            brightness: +filters['brightness'].value / 100,
        });
    }
    if (+filters['contrast'].value !== 0) {
        fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Contrast({
            // Contrast may be a value between -1 and 1. The value returned from the range object, however, lies between -100 and 100.
            // Transform this here.
            contrast: +filters['contrast'].value / 100,
        });
    }
    if (+filters['saturation'].value !== 0) {
        fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Saturation({
            saturation: +filters['saturation'].value / 100,
        });
    }

    if (+filters['sharpness'].value > 0) {
        let kernelCenter = +filters['sharpness'].value / 10,
            kernelOuter = (kernelCenter - 1) / -4;

        if (kernelCenter === 0) {
            kernelCenter = 1;
            kernelOuter = 0;
        }
        // Sharpen.
        // A great tutorial about how convolution works can be found at https://docs.gimp.org/en/plug-in-convmatrix.html
        fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Convolute({
            matrix: [0, kernelOuter, 0, kernelOuter, kernelCenter, kernelOuter, 0, kernelOuter, 0],
        });
    }
    // If the user wants a negative sharpness, the image is blurred
    else if (+filters['sharpness'].value < 0) {
        const degreeOfBlur = (+filters['sharpness'].value / 10) * -1,
            // For three iterations, this is 9, for 4 it is 16 and so on.
            blurMatrixLength = 9, //Math.pow(blurMatrixDimension, 2),
            matrix = [],
            matrixElement = 1 / blurMatrixLength;

        for (let i = 0; i < blurMatrixLength; i++) {
            /**
             * e.g.
             * [
             *   1/9, 1/9, 1/9,
             *   1/9, 1/9, 1/9,
             *   1/9, 1/9, 1/9
             * ]
             * or
             * [
             *   1/25, 1/25, 1/25, 1/25, 1/25,
             *   1/25, 1/25, 1/25, 1/25, 1/25,
             *   1/25, 1/25, 1/25, 1/25, 1/25,
             *   1/25, 1/25, 1/25, 1/25, 1/25,
             *   1/25, 1/25, 1/25, 1/25, 1/25
             * ]
             */
            matrix.push(matrixElement);
        }
        for (let i = 0; i < degreeOfBlur; i++) {
            fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Convolute({
                matrix: matrix,
            });
        }
    }

    // This function takes a while (~100ms to 1s).
    fabricPhoto.applyFilters();
}

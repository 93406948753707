<div id="equipment-bulk-import-container">
    <h3>Ausstattungen importieren</h3>

    <!--********** Close Icon **********-->
    <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

    <!--********** Close Icon **********-->
    <p id="explanation" class="label">
        Du kannst die Ausstattungsliste aus einer E-Mail oder einer Website per Kopieren & Einfügen hier einfügen. Die
        einzelnen Ausstattungen können durch Semikola oder Zeilenumbrüche getrennt werden.
    </p>

    <!--********** Text Input **********-->
    <div>
        <mat-form-field>
            <textarea
                matInput
                placeholder="Ausstattungsliste"
                [(ngModel)]="equipmentPositionsAsString"
                (ngModelChange)="handleTextChange()"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>
    </div>

    <!--********** Trennzeichen **********-->
    <div class="select-separator">
        <div class="selection-bar-label">Trennzeichen</div>
        <div class="selection-bar">
            <div
                class="selection-bar-item"
                (click)="handleSeparatorChange(';')"
                [ngClass]="{
                    selected: separator === ';',
                }">
                Semikolon (;)
            </div>
            <div
                class="selection-bar-item"
                (click)="handleSeparatorChange('\n')"
                [ngClass]="{ selected: separator === '\n' }">
                Zeilenumbruch
            </div>
        </div>
    </div>

    <!--********** Typ **********-->
    <div id="select-type">
        <div class="selection-bar-label">Einfügen als</div>
        <div class="selection-bar">
            <div
                class="selection-bar-item"
                (click)="handleTypeChange('series')"
                matTooltip="Die neuen Ausstattungen werden als Serienausstattung importiert."
                [ngClass]="{
                    selected: type === 'series',
                }">
                <mat-icon class="small-icon">check</mat-icon>
                Serie
            </div>

            <div
                class="selection-bar-item"
                (click)="handleTypeChange('special')"
                matTooltip="Die neuen Ausstattungen werden als Sonderausstattung importiert."
                [ngClass]="{
                    selected: type === 'special',
                }">
                <mat-icon class="small-icon">star</mat-icon>
                Sonder
            </div>

            <div
                class="selection-bar-item"
                (click)="handleTypeChange('additional')"
                matTooltip="Die neuen Ausstattungen werden als Zusatzausstattung importiert."
                [ngClass]="{
                    selected: type === 'additional',
                }">
                <mat-icon class="small-icon outlined">star</mat-icon>
                Zusatz
            </div>
        </div>
    </div>

    <!--********** Preview **********-->
    <div id="equipment-positions-preview" @slideInAndOutVertically>
        <p class="label preview-placeholder" *ngIf="equipmentPositionsDescriptionsAsArray?.length < 1">
            Kopiere eine Liste von Ausstattungen in das Textfeld.
        </p>
        <span class="preview-position" *ngFor="let description of equipmentPositionsDescriptionsAsArray">
            {{ description }}
        </span>
    </div>

    <!--********** Buttons **********-->
    <div class="dialog-buttons-container">
        <button
            (click)="importPositions()"
            [ngClass]="{
                disabled: equipmentPositionsDescriptionsAsArray?.length < 1,
            }"
            [matTooltip]="
                equipmentPositionsDescriptionsAsArray?.length > 0
                    ? previewText
                    : 'Füge mindestens eine Ausstattung hinzu, um den Import zu starten'
            ">
            <span class="number-of-objects-to-import" *ngIf="equipmentPositionsDescriptionsAsArray?.length > 0">
                {{ equipmentPositionsDescriptionsAsArray.length }}
            </span>
            Importieren
        </button>
    </div>
</div>

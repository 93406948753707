import { generateId } from '../../lib/generate-id';
import { Timestamps } from '../timestamps';

export class InvoiceNumberOrReportTokenCounter implements Timestamps {
    constructor(template: Partial<InvoiceNumberOrReportTokenCounter> = {}) {
        Object.assign(this, template);
    }

    // This is the ID of an InvoiceNumberConfig or a ReportTokenConfig to which this counter belongs.
    _id: string = generateId();
    count: number = 0;
    resetCycle: CounterResetCycle = 'never';
    lastResetAt: string = null; // The user may have autoiXpert reset the counter automatically e.g. every year or once in a month. This is the timestamp for the last reset.

    createdAt: string;
    createdBy: string;
    teamId: string;
    updatedAt: string;

    /**
     * This interface does not have a _documentVersion and _schemaVersion because
     * - it resides only on the server, so no client merge is required.
     * - it should *not* accept a "last write wins". The counter may be set ("force updated") or increased by one.
     */
}

export type CounterResetCycle = 'never' | 'annually' | 'monthly' | 'daily';

import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http/index';
import { Injectable } from '@angular/core';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from './logged-in-user.service';

/**
 * Service to CRUD header and footer templates for rendering DOCX/PDF files.
 */
@Injectable()
export class HeaderAndFooterTemplateService {
    constructor(
        private httpClient: HttpClient,
        private loggedInUserService: LoggedInUserService,
    ) {
        this.loggedInUserService.getUser$().subscribe({
            next: (user) => {
                this.user = user;
                if (this.user) {
                    this.apiPath = `/api/v0/teams/${this.user.teamId}/headerAndFooterTemplates`;
                }
            },
        });
    }

    private apiPath: string;
    private user: User;

    public get(headerAndFooterTemplateId: string): Promise<HttpResponse<Blob>> {
        return this.httpClient
            .get(`${this.apiPath}/${headerAndFooterTemplateId}`, {
                responseType: 'blob',
                observe: 'response',
            })
            .toPromise();
    }

    public remove(headerAndFooterTemplateId: string): Promise<{ _id: string }> {
        return this.httpClient.delete<{ _id: string }>(`${this.apiPath}/${headerAndFooterTemplateId}`).toPromise();
    }

    public create(headerAndFooterTemplateId: string, documentFile: Blob): Promise<{ _id: string }> {
        const formData = new FormData();
        formData.append('_id', headerAndFooterTemplateId);
        formData.append('document', documentFile);

        return this.httpClient.post<{ _id: string }>(`${this.apiPath}`, formData).toPromise();
    }

    public resetDefault(headerAndFooterTemplateId: string): Promise<{ _id: string }> {
        const formData = new FormData();
        formData.append('_id', headerAndFooterTemplateId);
        /**
         * For resetting the header and footer template, the blob may contain anything since the
         * file template is read from the server's file system.
         */
        formData.append('document', new Blob(['anything']));

        return this.httpClient
            .post<{ _id: string }>(`${this.apiPath}`, formData, {
                params: {
                    resetHeaderAndFooterTemplate: 'true',
                },
            })
            .toPromise();
    }
}

import { generateId } from '../../lib/generate-id';
import { ExternalDocumentType, RenderedDocumentType } from '../documents/document-metadata';
import { DataTypeBase } from '../indexed-db/database.types';
import { ReportType } from '../reports/report';

/**
 * The user may customize how the name of a document is generated.
 *
 * He may define a pattern with static and dynamic content (placeholders).
 */
export class FileNamePattern implements DataTypeBase {
    constructor(template: Partial<FileNamePattern> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string;
    pattern: string;
    documentType: DocumentTypeForFileNamePattern;
    customDocumentOrderConfigId?: string;
    reportTypes: ReportType[] = []; // Within which report type process is this pattern valid? Use case: Different names for the main report in liability cases and valuations.
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    teamId: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

export type DocumentTypeForFileNamePattern =
    | RenderedDocumentType
    | ExternalDocumentType
    | 'fullDocumentWithReport'
    | 'fullDocumentWithoutReport';

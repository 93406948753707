import { Place } from '@autoixpert/models/contacts/place';

/**
 * Map street, zip and city to a proper query string passable to Google Maps.
 * @param location
 */
export function mapLocationToQueryString(location: Place): string {
    const street = `${location.streetAndHouseNumber || ''}`.trim();
    const zipAndCity = `${location.zip || ''} ${location.city || ''}`.trim();

    /**
     * Add a comma if either one of
     * - street & house number
     * - ZIP & city
     * exists.
     * The comma is necessary to improve Google Maps matching results.
     */
    return [street, zipAndCity].filter(Boolean).join(', ');
}

/**
 * Map multiple location objects to a simple query string passable to Google Maps.
 * @param locations
 */
export function mapLocationsToQueryString(locations: Place[]): string {
    return locations.map(mapLocationToQueryString).join('|');
}

import { DateTime } from 'luxon';
import { AccountStatus } from '@autoixpert/models/teams/account-status';
import { generateId } from '../../lib/generate-id';
import { NUMBER_OF_VINS_DURING_TEST } from '../../static-data/number-of-vins-during-test';
import { DocumentMetadata } from '../documents/document-metadata';
import { DatevConfig } from './datev-config';
import { GtueSubOffice } from './gtue-sub-office';
import { InvoiceNumberConfig, ReportTokenConfig } from './invoice-or-report-counter-config';
import { OfficeLocation } from './office-location';
import { ProfessionalAssociation } from './professional-association';
import { TeamPreferences } from './team-preferences';
import { TeamUserInterfaceStates } from './team-user-interface-states';

export class Team {
    constructor(template: Partial<Team> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    vatId: string = ''; // Steuernummer
    europeanVatId: string = ''; // Umsatzsteuer-ID
    commercialRegisterId: string = ''; // Handelsregister-Nummer: HRA 12925
    commercialRegisterName: string = ''; // Handelsregister-Name: Amtsgericht Memmingen
    billing: Billing = new Billing();
    bankAccountSync: BankAccountSync = new BankAccountSync();
    datev: DatevConfig = new DatevConfig();
    gtue?: GtueConfig = new GtueConfig();
    /**
     * Login is possible until the end of this day. On the day after this day, the account is deactivated.
     */
    expirationDate: string = null;
    accountStatus: AccountStatus = 'test';
    /**
     * At the time of registration, has the team qualified for a special offer?
     */
    specialOfferAtRegistration: 'tuevRheinland';
    /**
     * There are a few restrictions during the usual test period:
     * - the user must not change personal data.
     * - the user cannot use the DOCX download.
     * - the number of queryable DAT VINs is limited.
     *
     * If this flag is true, those restrictions are lifted and the user may use aX like a paying, trustworthy customer.
     */
    isExemptFromTestAccountRestrictions: boolean;
    isCommisionByMarkus: boolean = null;

    /**
     * If true, logs will be created for endpoints:
     * - reports
     * - invoices
     *
     * This is useful for debugging.
     * This field is configured via the account management.
     */
    logCreateUpdateDeleteRequests?: boolean = false;

    becameCustomerAt: string = null;
    datTestAccountExtendedUntil: string = null;
    qapterTestAccountExtendedUntil: string = null;
    audatexAddonTestExtendedUntil: string = null;

    // Some Audatex features are optional and may be paid for.
    audatexFeatures: {
        hasAudatexAddon: boolean;
        qapterixpertLayout: boolean;
    };
    // Contains the team's billing address to which autoiXpert bills are sent
    invoicing: TeamInvoicingParameters = new TeamInvoicingParameters();
    reportTokenConfigs: ReportTokenConfig[] = [new ReportTokenConfig({ isDefault: true })];
    // Contains an array of user IDs who are allowed to change the team properties
    administrators: string[] = [];
    members: string[] = [];
    officeLocations: OfficeLocation[] = [];

    // Persist every association the assessor belongs to in order to know our customer base better.
    professionalAssociations: ProfessionalAssociation[] = [];

    // Persist permanent uploaded documents on the team so every user can access these
    permanentUploadedDocuments: DocumentMetadata[];

    /**
     * Set to the logo file's hash value if a logo exists for this team. The logo might be used in the report template via {Sachverständiger.Logo}
     */
    logoHash: string;
    /**
     * Contains the simpleHash() of the uploaded duplicate and draft watermark templates. This is used to check if the
     * watermark templates have changed when a document is re-rendered.
     */
    docxWatermarkHashes: {
        duplicate: string;
        draft: string;
    };
    preferences: TeamPreferences = new TeamPreferences();
    userInterfaceStates: TeamUserInterfaceStates = new TeamUserInterfaceStates();

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toJSDate() as any;
    createdAt: string = DateTime.now().setZone('UTC').toJSDate() as any;
    createdBy: string;

    _documentVersion: number = 0;
    _schemaVersion = 8 as const;
}

export class TeamInvoicingParameters {
    invoiceOutroText: string;
    vatRate: 0 | 0.19 | 0.2 = 0.19;
    bankAccount: BankAccount = new BankAccount();
    secondBankAccount?: BankAccount = new BankAccount();
    invoiceNumberConfigs: InvoiceNumberConfig[] = [new InvoiceNumberConfig({ isDefault: true })];
}

export class BankAccount {
    iban: string = null;
    bic: string = null;
    owner: string = null;
    bankName: string = null;
}

export class Billing {
    address: BillingAddress = new BillingAddress();
    paymentCycle: PaymentCycle = null;
    paymentMethod: PaymentMethod = null;
    testPeriodVinCounter: number = null;
    testPeriodVinLimit: number = NUMBER_OF_VINS_DURING_TEST;
    /**
     * We used to re-sell VINs from DAT to very few users. This feature is deprecated.
     * @deprecated
     */
    datVinResellingActive?: boolean;
    bankAccount: BankAccount = new BankAccount();
    /**
     * The customer number (debtor number) in FastBill/DATEV. We add the DATEV account number to FastBill so that the DATEV export from FastBill
     * contains this number.
     */
    datevAccountNumber?: number;
    /**
     * If a team does not pay its autoiXpert bills, they will be reminded first. If they do not pay several days after the reminder, the account will be deactivated.
     */
    inDefaultOfPaymentSince?: string; // ISO Date string
    /**
     * We display a warning if office locations or bank account don't match our billing settings.
     * Often customers change this information for their reports but won't remember to change their billing settings as well.
     * Customers can close these warnings, we then keep the current billing address.
     */
    warningClosedForBillingAddress?: string;
    warningClosedForIBAN?: string;
}

export class BillingAddress {
    organization: string = null;
    firstName: string = null;
    lastName: string = null;
    streetAndHouseNumberOrLockbox: string = null;
    zip: string = null;
    city: string = null;
}

export class GtueConfig {
    /**
     * Unique identifier for the users account (aka bueroNr).
     * An office can have multiple subOffices.
     *
     * This officeId is also stored on the gtueAuthData object because it is part of the auth data.
     */
    officeId: string;
    /**
     * Used to determine the due date for imported invoices.
     * This field stores the last value the user typed into the input field
     * when importing invoices from GTÜ.
     */
    daysUntilDue: number;
    /**
     * The users office can contain more than one sub office. We allow the user to select sub offices to import invoices from.
     * This array stores the latest selection of sub offices or
     * is empty (or not set) if the user selected to import from all sub offices.
     */
    selectedSubOfficeIds: Array<string>;
    /**
     * The users office can contain more than one sub office. This array stores all
     * available sub offices of the user.
     */
    availableSubOffices: Array<GtueSubOffice>;

    /**
     * When the current refresh token expires. If set, we assume that the current access token is
     * still valid or the refresh token can be used to retrieve a new access token.
     */
    refreshTokenExpirationDate: string;
}

export class BankAccountSync {
    numberOfActiveBankAccounts: number = null;
}

export type PaymentCycle = 'monthly' | 'annually';
export type PaymentMethod = 'directDebit' | 'invoice';

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeathersSocketioService } from '../services/feathers-socketio.service';

/**
 * In order to prevent patches from client A to be broadcast back to client A, each HTTP
 * request is attached the ID of its origin window's socket. The backend filters that socket
 * out when broadcasting events.
 */
@Injectable()
export class SetSocketIdHeaderInterceptor implements HttpInterceptor {
    constructor(private feathersSocketioService: FeathersSocketioService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        // The request shall be immutable. Therefore clone it and assign it new headers.
        const transformedRequest = request.clone({
            setHeaders: {
                'autoixpert-socket-ids-on-this-device':
                    Array.from(this.feathersSocketioService.autoiXpertSocketIdsOnThisDevice.values()).join(',') || '',
            },
        });

        return next.handle(transformedRequest);
    }
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <h2>Ersatzrad</h2>

        <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>

        <!--********** Copy Tire Data **********-->
        <div
            class="text-align-center margin-bottom-20"
            *ngIf="!spareTireEquipment.dimension && !spareTireEquipment.treadInMm"
            @fadeInAndSlide>
            <span
                class="link"
                (click)="copyTireInfoFromFirstTire(); emitChange()"
                matTooltip="Reifendimension, Hersteller und Saison vom ersten Reifen des Fahrzeugs übernehmen">
                Reifendaten kopieren
            </span>
        </div>

        <!--============================================-->
        <!-- Tire Dimension -->
        <!--============================================-->
        <div class="multiple-inputs-grid">
            <mat-form-field class="mat-block">
                <input
                    matInput
                    [(ngModel)]="spareTireEquipment.dimension"
                    (change)="emitChange()"
                    [disabled]="disabled"
                    placeholder="Reifendimension"
                    matTooltip="z. B. 195/65 R 15 91 H" />
            </mat-form-field>

            <mat-form-field class="mat-block">
                <input
                    matInput
                    id="tread-input"
                    number-input
                    [(number)]="spareTireEquipment.treadInMm"
                    (change)="emitChange()"
                    [disabled]="disabled"
                    placeholder="Profil"
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="1" />
                <span matSuffix>mm</span>
            </mat-form-field>
        </div>
        <!--============================================-->
        <!-- END Tire Dimension -->
        <!--============================================-->

        <!--============================================-->
        <!-- Tire Manufacturer -->
        <!--============================================-->
        <mat-form-field class="mat-block">
            <input
                matInput
                placeholder="Hersteller"
                [(ngModel)]="spareTireEquipment.manufacturer"
                [disabled]="disabled"
                [matAutocomplete]="tireManufacturersAutocomplete"
                (ngModelChange)="filterTireManufacturerAutocomplete(spareTireEquipment.manufacturer)"
                (change)="emitChange()"
                (focus)="filterTireManufacturerAutocomplete(spareTireEquipment.manufacturer)" />
        </mat-form-field>
        <mat-autocomplete #tireManufacturersAutocomplete="matAutocomplete">
            <mat-option
                *ngFor="let tireManufacturer of filteredTireManufacturers"
                [value]="tireManufacturer"
                (click)="emitChange()"
                (mousedown)="$event.preventDefault()">
                {{ tireManufacturer }}
            </mat-option>
        </mat-autocomplete>
        <!--============================================-->
        <!-- END Tire Manufacturer -->
        <!--============================================-->

        <!--============================================-->
        <!-- Seasons -->
        <!--============================================-->
        <div id="tire-equipment-container" class="options-row grid">
            <div
                class="tire-equipment-option option-container"
                id="summer-tires"
                [ngClass]="{ active: spareTireEquipment.season === 'summer', unselectable: disabled }"
                (click)="!disabled && selectTireSeason('summer')"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <img src="/assets/images/icons/summer-tires.png" alt="Sommerreifen" class="option-image" />
                <div class="tire-equipment-option-title-container option-title-container">
                    <span class="option-title">Sommer</span>
                </div>
            </div>
            <div
                class="tire-equipment-option option-container"
                id="winter-tires"
                [ngClass]="{ active: spareTireEquipment.season === 'winter', unselectable: disabled }"
                (click)="!disabled && selectTireSeason('winter')"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <img src="/assets/images/icons/winter-tires.png" alt="Winterreifen" class="option-image" />
                <div class="tire-equipment-option-title-container option-title-container">
                    <span class="option-title">Winter</span>
                </div>
            </div>
            <div
                class="tire-equipment-option option-container"
                id="allyear-tires"
                [ngClass]="{ active: spareTireEquipment.season === 'allyear', unselectable: disabled }"
                (click)="!disabled && selectTireSeason('allyear')"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <img src="/assets/images/icons/allyear-tires.png" alt="Ganzjahresreifen" class="option-image" />
                <div class="tire-equipment-option-title-container option-title-container">
                    <span class="option-title">Ganzjahr</span>
                </div>
            </div>

            <mat-icon
                id="show-custom-season-icon"
                class="input-external-icon"
                (click)="!disabled && (customTireTypeShown = !customTireTypeShown)"
                matTooltip="Eigenen Reifentyp angeben">
                add
            </mat-icon>
            <div class="input-for-custom-value" *ngIf="customTireTypeShown">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Anderer Reifentyp"
                        [(ngModel)]="spareTireEquipment.customType"
                        (change)="selectCustomTireType()"
                        [axAutofocus]="customTireTypeShown && !spareTireEquipment.customType" />
                </mat-form-field>
            </div>
        </div>
        <!--============================================-->
        <!-- END Seasons -->
        <!--============================================-->

        <div class="dialog-buttons-container">
            <button (click)="closeDialog()">Schließen</button>
        </div>
    </div>
</div>

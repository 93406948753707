import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { Query as FeathersQuery } from '@feathersjs/feathers';
import { captureException } from '@sentry/angular';
import { get, set, unset } from 'lodash-es';
import { DateTime } from 'luxon';
import { Observable, Subject, merge, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter } from 'rxjs/operators';
import { pluralize } from '@autoixpert/lib/pluralize';
import { extractChanges } from '@autoixpert/lib/server-sync/extract-changes';
import { FlattenedChangePaths, flattenChangePaths } from '@autoixpert/lib/server-sync/flatten-change-paths';
import { getLatestTimestamp } from '@autoixpert/lib/server-sync/get-latest-timestamp';
import { ObjectDifference, getListOfDifferences } from '@autoixpert/lib/server-sync/get-list-of-differences';
import { insertChangesIntoRecord } from '@autoixpert/lib/server-sync/insert-changes-into-record';
import { threeWayMerge } from '@autoixpert/lib/server-sync/three-way-merge';
import { sleep } from '@autoixpert/lib/sleep';
import { AxError, NotFound, ServerError } from '@autoixpert/models/errors/ax-error';
import {
    DataTypeBase,
    DatabaseInfo,
    DatabaseServiceName,
    DeletedRecord,
    MergeResult,
    ObjectStoreAndIndexMigrations,
    PatchedEvent,
    RecordEdit,
    RecordMigrations,
} from '@autoixpert/models/indexed-db/database.types';
import { FrontendLogService } from '../../services/frontend-log.service';
import { NetworkStatusService } from '../../services/network-status.service';
import { SyncIssueNotificationService } from '../../services/sync-issue-notification.service';
import { ToastService } from '../../services/toast.service';
import { getProductName } from '../get-product-name';
import { StorageSpaceManager } from '../storage-space-manager/storage-space-manager.class';
import { applyMongoQuery } from './apply-mongo-query';
import { AxHttpSync } from './ax-http-sync.class';
import { AxIndexedDB } from './ax-indexed-db.class';
import { handlePushToServerFailureForSpecificRecord } from './handle-push-to-server-failure-for-specific-record';

/**
 * Manage records for various types of data in IndexedDB, e.g. reports, contact people etc.
 *
 * Services may extend this base class to inherit functionality. This class should not be instantiated directly.
 *
 * *** IMPORTANT ***
 * When extending this class, register is with the database sync initializer class, so that it will be synced to the server when the client reloads.
 */
export class OfflineSyncServiceBase<DataType extends DataTypeBase> {
    public readonly serviceName: DatabaseServiceName; // Name of the service in the backend that this database shall sync with. Singular, such as "report" for the endpoint "/reports"
    protected serviceNamePlural: string; // Plural name, such as "reports" or "contactPeople"-> Is explicitly passed or inferred automatically.
    protected networkStatusService: NetworkStatusService;
    protected frontendLogService: FrontendLogService;
    protected syncIssueNotificationService: SyncIssueNotificationService;
    protected toastNotificationService: ToastService; // Name differently to avoid having to change all extending classes from private toastService to protected toastService.
    protected serviceWorker: SwUpdate;

    /**
     * Abstraction layer over:
     *   - IndexedDB
     *   - the corresponding CRUD events
     *   - local data migrations
     *   - last access tracking and old data ejection
     *
     * This property may be overwritten if the mechanism how data is stored changes, e.g. for photo blob files.
     */
    private _localDb: AxIndexedDB<DataType>;
    private set localDb(value: AxIndexedDB<DataType>) {
        this._localDb = value;
        this.linkIndexedDbEvents();
    }

    public get localDb(): AxIndexedDB<DataType> {
        return this._localDb;
    }

    /**
     * Abstraction layer over server communication for directly reading and writing. This does
     * not include Websocket communication e.g. for realtime-editing in the report details component.
     *
     * This property may be overwritten if the mechanism to sync with the server is changed, e.g. for photo blob files.
     */
    public httpSync: AxHttpSync<DataType>;
    private fullSyncInProgress: boolean;

    //*****************************************************************************
    //  Local CRUD Events
    //****************************************************************************/
    /**
     * Changes come from
     * - another device via a socket connection or through an HTTP pull.
     * - another tab on this device (Broadcast)
     *
     * In contrast to patchedInLocalDatabase$, this is only fired if the change comes from an external source. This should
     * be reflected in the active component, which merges changes in a three-way merge based on the currently held record, the server shadow and the external record.
     */
    public createdFromExternalServerOrLocalBroadcast$: Observable<DataType>;
    public patchedFromExternalServerOrLocalBroadcast$: Observable<PatchedEvent<DataType>>;
    /**
     * Subscribe to changes that originated on this device in this tab, only. This is useful if local changes should change some sort of local cache (in-memory cache) without applying the changes
     * to IndexedDB from multiple tabs on the same device.
     */
    public createdFromLocalUserInThisTab$: Subject<DataType>;
    public patchedFromLocalUserInThisTab$: Subject<PatchedEvent<DataType>>;

    /**
     * Services may update their in-memory caches (as used in the ReportService) when a record changes.
     * Don't subscribe to this subject to update records in the component because this will create a lagged loop: The changed record will be written
     * to IndexedDb, the user may have continued to another field in the meantime and changed its content. Then this patch event fires and the now older record without
     * the second change lands in the component --> The user's change is overwritten.
     */
    public createdInLocalDatabase$: Observable<DataType>;
    public patchedInLocalDatabase$: Observable<PatchedEvent<DataType>>;

    public deletedInLocalDatabase$: Observable<DataType['_id']>;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local CRUD Events
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Map that contains the recordId and the error code why the sync failed.
     */
    public recordIdsWithSyncIssues = new Map<DataType['_id'], string>();

    //*****************************************************************************
    //  Debounce Patch Calls
    //****************************************************************************/
    /**
     * Goal: Only trigger an actual put every X ms, despite the client requiring a put more often.
     * This effectively throttles the amount of server put calls.
     * @private
     */
    private pushToServerTimeoutId: number;
    private pushToServerCompleted$: Subject<number>;
    private pushToServerPromise: Promise<number>;
    private PUSH_TO_SERVER_DEBOUNCE_TIME_IN_MS = 300;

    /**
     * While a push-to-server is in progress, no second push-to-server should be started. Instead, a promise-then-function is
     * created on this.pushToServerPromise which causes another push to start when the current push-to-server completes.
     *
     * This prevents race conditions that cause DOCUMENT_VERSION_TOO_HIGH errors by ensuring that no two pushes with the same record but with a different _documentVersion
     * are sent to the server at the same time.
     */
    private pushToServerInProgress: boolean;
    private followupPushToServerPromise: Promise<number>;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Debounce Patch Calls
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Throttle Pull From Server
    //****************************************************************************/
    /**
     * Some requests return lots of records, such as querying for all claimants.
     * Query the records in batches to save our server from too large requests.
     * Servers and clients also have limits on URL length, which is limited in this way too.
     * @private
     */
    protected batchSize: number = 100;
    /**
     * When doing an initial sync or a recurring sync, wait a certain number of milliseconds between
     * server requests to allow the client to process user events. If the client is just busy pulling
     * from the server, weaker clients fail to allocate CPU capacity for rendering the user interface fast. That
     * makes autoiXpert feel slow.
     */
    private millisecondsBetweenSyncRequests: number = 1_000;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Throttle Pull From Server
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Constructor
    //****************************************************************************/
    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        httpClient: HttpClient;
        apiPathPrefix?: string; // e. g. "/admin"
        networkStatusService: NetworkStatusService;
        syncIssueNotificationService: SyncIssueNotificationService;
        frontendLogService: FrontendLogService;
        recordMigrations?: RecordMigrations<DataType>;
        objectStoreAndIndexMigrations?: ObjectStoreAndIndexMigrations<DataType>;
        skipOutstandingSyncsCheckOnLogout?: boolean;
        keysOfQuickSearchRecords?: string[];
        get$SearchMongoQuery?: (searchTerm: string) => [string, any];
        pullFromServerBatchSize?: number;
        toastNotificationService?: ToastService;
        serviceWorker: SwUpdate;
    }) {
        this.serviceName = params.serviceName;
        this.serviceNamePlural = params.serviceNamePlural ?? pluralize(this.serviceName);
        this.networkStatusService = params.networkStatusService;
        this.frontendLogService = params.frontendLogService;
        this.syncIssueNotificationService = params.syncIssueNotificationService;
        this.toastNotificationService = params.toastNotificationService;
        this.serviceWorker = params.serviceWorker;

        this.localDb = new AxIndexedDB<DataType>({
            serviceName: params.serviceName,
            serviceNamePlural: params.serviceNamePlural,
            recordMigrations: params.recordMigrations,
            objectStoreAndIndexMigrations: params.objectStoreAndIndexMigrations,
            keysOfQuickSearchRecords: params.keysOfQuickSearchRecords,
            get$SearchMongoQuery: params.get$SearchMongoQuery,
        });

        this.httpSync = new AxHttpSync<DataType>({
            serviceName: params.serviceName,
            serviceNamePlural: params.serviceNamePlural,
            httpClient: params.httpClient,
            apiPathPrefix: params.apiPathPrefix,
        });

        this.batchSize = params.pullFromServerBatchSize ?? this.batchSize;

        /**
         * Multiple purposes:
         * - Allow old data to be removed in case of little IndexedDB storage space.
         * - Check for unsynced changes on logout. Clear data if there are no unsynced changes or the user wants to remove all unsynced changes.
         */
        StorageSpaceManager.registerService(this);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Constructor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Create
    //****************************************************************************/
    public async create(record: DataType, options: { waitForServer?: boolean } = {}): Promise<DataType> {
        const localResult: DataType = await this.localDb.createLocal(record);

        if (options.waitForServer) {
            // If creating the record on the server is required, throw an error if offline.
            if (!this.networkStatusService.isOnline()) {
                throw new AxError({
                    code: 'CANNOT_CREATE_RECORD_ON_SERVER_WHILE_OFFLINE',
                    message:
                        'The create call required the record to be created on the server but the client is offline.',
                });
            }

            // Wait for server sync before returning.
            await this.pushToServer();
            handlePushToServerFailureForSpecificRecord({
                recordId: record._id,
                syncIssueNotificationService: this.syncIssueNotificationService,
                recordIdsWithSyncIssues: this.recordIdsWithSyncIssues,
                serviceName: this.serviceName,
            });
        }
        // Check online status before even trying to send data through the socket to avoid Socket.io buffering partial calls.
        else if (this.networkStatusService.isOnline()) {
            void this.pushToServer();
        }
        return localResult;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Get & Find
    //****************************************************************************/

    /**
     * Returns a local record. If online, pulls changes from server before returning the local record.
     * @param recordId
     */
    public async get(recordId: DataType['_id']): Promise<DataType> {
        if (this.networkStatusService.isOnline()) {
            try {
                const recordShellsFromServers: RecordShell[] = await this.httpSync.findRemote({
                    _id: recordId,
                    $select: ['_id', '_documentVersion'],
                });

                /**
                 * The findRemote call above does not throw an error if the result set is empty because it's not a "get" call but a "find" call.
                 * In case of a missing record, throw the error here.
                 * RecordShellsFromServer may be undefined if the user was logged out due to invalid JWT.
                 */
                if (recordShellsFromServers?.length === 0) {
                    throw new NotFound({
                        // This code must be same as in backend-autoixpert > ensure-ax-error.ts.
                        code: 'RESOURCE_NOT_FOUND',
                        message: `The given record does not exist on the server.`,
                        data: {
                            recordId,
                            serviceName: this.serviceName,
                        },
                    });
                }

                await this.pullFromServer(recordShellsFromServers);
            } catch (error) {
                // If the client can't reach the autoiXpert backend, load the local record instead.
                if (['CLIENT_IS_OFFLINE', 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN'].includes(error.code)) {
                    return this.localDb.getLocal(recordId);
                }
                throw error;
            }
        }

        return this.localDb.getLocal(recordId);
    }

    /**
     * Return both local records and remote records if there were changes to the requested records.
     * If offline, return local records only.
     * If you want to get the best possible answer but wish to handle the result of this function as a promise, use toPromise() like so:
     * > serviceInstance.find({...}).toPromise()
     * That will resolve the promise with the last emitted value when the observable completes.
     *
     * This is relevant for displaying an initial view in a component. This returns the best effort (indexed DB records) when being offline
     * and the most up-to-date data when online.
     *
     * Add useful autocomplete capabilities through "Partial<DataType>". Most MongoDB/Feathers queries contain property names from DataType. Exceptions: $skip, $sort etc.
     */
    public find(feathersQuery?: FeathersQuery | Partial<DataType>): Observable<DataType[]> {
        // Return empty array for { _id: { $in: [] } } to be consistent with MongoDB behavior
        if (feathersQuery?._id?.$in && feathersQuery._id.$in.length === 0) {
            return of([]);
        }

        if (this.networkStatusService.isOnline()) {
            // TODO Convert this from an Observable to a Promise (Mark & Steffen 2022-07-22)
            return fromPromise(
                Promise.all([
                    // Fetch remote + get local record for each ID
                    this.findRemoteAndMergeWithLocal({
                        feathersQuery,
                    })
                        .then((result) => result.findResult)
                        .catch((error) => {
                            // If the client can't reach the autoiXpert backend, load the local records instead.
                            if (['CLIENT_IS_OFFLINE', 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN'].includes(error.code)) {
                                return this.localDb.findLocal(feathersQuery);
                            }
                            return Promise.reject(error);
                        }),

                    // Retrieve records that exist locally only
                    this.localDb.getCreationChangeRecords().then(async (changeRecords) => {
                        const localOnlyIds: DataType['_id'][] = changeRecords.map((changeRecord) => changeRecord._id);
                        const localOnlyRecords: DataType[] = await this.localDb.findLocalByIds(localOnlyIds);
                        return applyMongoQuery(feathersQuery, localOnlyRecords, (searchQuery) =>
                            this.localDb.get$SearchMongoQuery(searchQuery),
                        );
                    }),
                ]).then(([recordsFromServer, createdLocallyOnlyRecords]) => {
                    return [...recordsFromServer, ...createdLocallyOnlyRecords];
                }),
            );
        }

        // Offline
        else {
            return fromPromise(this.localDb.findLocal(feathersQuery));
        }
    }

    /**
     * Pagination with searchSequenceToken was added 08/2024.
     *
     * Pagination is available from Atlas Search which provides indexes for queries with full-text-search or sorting.
     * Currently pagination is only available for reports, invoices and contactpeople
     * To keep logic for existing services, findWithPaginationToken is a separate method and the signature of 'find' remains unchanged.
     */
    public async findWithPaginationToken(
        feathersQuery?: FeathersQuery | Partial<DataType>,
    ): Promise<{ records: DataType[]; lastPaginationToken?: string }> {
        if (this.networkStatusService.isOnline()) {
            // We may implement $skip in Atlas Search in the future, e.g. for the external API. Therefore send only the pagination token..
            delete (feathersQuery as any).$skip;
            let lastPaginationToken = undefined;

            // Convert this from an Observable to a Promise (Mark & Steffen 2022-07-22) - done, Lukas 2024-08-15
            const [recordsFromServer, createdLocallyOnlyRecords] = await Promise.all([
                // Fetch remote + get local record for each ID
                this.findRemoteAndMergeWithLocal({
                    feathersQuery,
                })
                    .then((result) => {
                        lastPaginationToken = result.lastPaginationToken;
                        return result.findResult;
                    })
                    .catch((error) => {
                        // If the client can't reach the autoiXpert backend, load the local records instead.
                        if (['CLIENT_IS_OFFLINE', 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN'].includes(error.code)) {
                            return this.localDb.findLocal(feathersQuery);
                        }
                        return Promise.reject(error);
                    }),

                // Retrieve records that exist locally only
                // TODO: Provide more information or an example for this records, I did not understand (Lukas 2024-08-15)
                this.localDb.getCreationChangeRecords().then(async (changeRecords) => {
                    const localOnlyIds: DataType['_id'][] = changeRecords.map((changeRecord) => changeRecord._id);
                    const localOnlyRecords: DataType[] = await this.localDb.findLocalByIds(localOnlyIds);
                    return applyMongoQuery(feathersQuery, localOnlyRecords, (searchQuery) =>
                        this.localDb.get$SearchMongoQuery(searchQuery),
                    );
                }),
            ]);

            return {
                records: [...recordsFromServer, ...createdLocallyOnlyRecords],
                lastPaginationToken,
            };
        }

        // Fallback for offline:
        // Execute the query on the indexedDB.
        else {
            // IndexedDB is not able to use the pagination token
            delete (feathersQuery as any).$searchAfterPaginationToken;

            const records = await this.localDb.findLocal(feathersQuery);
            return { records };
        }
    }

    /**
     * Use the server to execute the feathersQuery but load only the _id and _documentVersion in the first request.
     * - If the records exist locally and are up-to-date, use those.
     * - If the records are outdated locally, load them from the server again, merge them with local and return the local record.
     */
    private async findRemoteAndMergeWithLocal({ feathersQuery }: { feathersQuery: FeathersQuery }): Promise<{
        numberOfChangedRecords: PullFromServerResult<DataType>['numberOfChangedRecords'];
        findResult: DataType[];
        lastPaginationToken?: string;
    }> {
        const recordShellsFromServer: RecordShell[] = await this.httpSync.findRemote({
            ...feathersQuery,
            $select: ['_id', '_documentVersion'],
        });

        // The pagination token is optional
        const lastPaginationToken =
            recordShellsFromServer.length === 0
                ? undefined
                : recordShellsFromServer[recordShellsFromServer.length - 1].paginationToken;

        const pullFromServerResult: PullFromServerResult<DataType> = await this.pullFromServer(recordShellsFromServer);

        /**
         * Return the records from the server & from local in the order in which the query result returned came
         * from the server.
         */
        const findResult: DataType[] = [];

        for (const recordShell of recordShellsFromServer) {
            const updatedRecord = pullFromServerResult.updatedRecords.get(recordShell._id);
            const createdRecord = pullFromServerResult.createdRecords.get(recordShell._id);
            const unchangedRecord = pullFromServerResult.unchangedRecords.get(recordShell._id);
            /**
             * The record from the server came back either as locally updated or as locally created.
             */
            findResult.push(unchangedRecord || updatedRecord || createdRecord);
        }

        return {
            numberOfChangedRecords: pullFromServerResult.numberOfChangedRecords,
            findResult,
            lastPaginationToken,
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get & Find
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Write a full record to indexedDB first and then trigger a push to server if online.
     *
     * If `waitForServer` is set to true, it always waits for the server, even if offline (causing an error).
     *
     * @param record
     * @param options -> waitForServer = only resolve the promise after the server has been updated. Useful if API endpoints rely on an updated version of the record.
     */
    public async put(record: DataType, options: { waitForServer?: boolean } = {}): Promise<DataType> {
        const localResult: DataType = await this.localDb.putLocal(record, 'localUser');

        if (options.waitForServer) {
            // If updating the server is required, throw an error if offline.
            if (!this.networkStatusService.isOnline()) {
                throw new AxError({
                    code: 'CANNOT_PATCH_RECORD_ON_SERVER_WHILE_OFFLINE',
                    message: 'The put call required the server to be updated but the client is offline.',
                });
            }

            // Wait for server sync before returning.
            await this.pushToServer();
            handlePushToServerFailureForSpecificRecord({
                recordId: record._id,
                syncIssueNotificationService: this.syncIssueNotificationService,
                recordIdsWithSyncIssues: this.recordIdsWithSyncIssues,
                serviceName: this.serviceName,
            });
        } else if (this.networkStatusService.isOnline()) {
            void this.pushToServer();
        }

        return localResult;
    }

    public async delete(recordId: DataType['_id'], options: { waitForServer?: boolean } = {}): Promise<void> {
        await this.localDb.deleteLocal(recordId);

        if (options.waitForServer) {
            // If deleting the record on the server is required, throw an error if offline.
            if (!this.networkStatusService.isOnline()) {
                throw new AxError({
                    code: 'CANNOT_DELETE_RECORD_ON_SERVER_WHILE_OFFLINE',
                    message: 'The delete call required the server to be updated but the client is offline.',
                });
            }

            // Wait for server sync before returning.
            await this.pushToServer();
            handlePushToServerFailureForSpecificRecord({
                recordId: recordId,
                syncIssueNotificationService: this.syncIssueNotificationService,
                recordIdsWithSyncIssues: this.recordIdsWithSyncIssues,
                serviceName: this.serviceName,
            });
        } else if (this.networkStatusService.isOnline()) {
            void this.pushToServer();
        }
    }

    public async undelete(record: DataType, options: { waitForServer?: boolean } = {}): Promise<void> {
        await this.localDb.undeleteLocal(record);

        if (options.waitForServer) {
            // If deleting the record on the server is required, throw an error if offline.
            if (!this.networkStatusService.isOnline()) {
                throw new AxError({
                    code: 'CANNOT_UNDELETE_RECORD_ON_SERVER_WHILE_OFFLINE',
                    message: 'The undelete call required the server to be updated but the client is offline.',
                });
            }

            await this.httpSync.removeDeletedMarker(record._id);
            // Wait for server sync before returning.
            await this.pushToServer();
            handlePushToServerFailureForSpecificRecord({
                recordId: record._id,
                syncIssueNotificationService: this.syncIssueNotificationService,
                recordIdsWithSyncIssues: this.recordIdsWithSyncIssues,
                serviceName: this.serviceName,
            });
        } else if (this.networkStatusService.isOnline()) {
            void this.pushToServer();
            await this.httpSync.removeDeletedMarker(record._id);
        }
    }

    /**
     * Reset a record to resolve sync issues that are caused by a local misconfiguration, e.g. due to an old
     * version on the client having created a record in an old format.
     */
    public async reset(recordId: DataType['_id']): Promise<void> {
        await this.localDb.resetLocal(recordId);

        // Remove from sync issues if present.
        this.recordIdsWithSyncIssues.delete(recordId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Public CRUD-API
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Push all changes (creations, patches, deletions) to the server.
     *
     * Calls are throttled by 300 ms. All callers within a throttled group receive the same
     * shared promise which will be resolved once the push to server triggered by the latest
     * call to this method has finished.
     *
     * Example:
     * Caller A:    0 ms - Receives Promise A
     * Caller B: +200 ms - Receives Promise A
     *           +300 ms - Promise A is resolved or rejected, depending on the result of _pushToServer.
     * ---
     * Caller C: +500 ms - Receives Promise B
     * Caller D: + 50 ms - Received Promise B
     *           +300 ms - Promise B is resolved or rejected, depending on the result of _pushToServer.
     */
    public async pushToServer(): Promise<number> {
        // The first call sets up the promise that resolves when the push-to-server completes.
        if (!this.pushToServerPromise) {
            //console.log('Push-to-server promise is being created.');
            // Create subject if no current timer is running.
            this.pushToServerCompleted$ = new Subject();
            // Create & save promise that resolves when the subject completes
            this.pushToServerPromise = new Promise((resolve, reject) => {
                this.pushToServerCompleted$.subscribe({
                    next: (numberOfChangedRecords: number) => {
                        //console.log('Push-to-server next.');

                        resolve(numberOfChangedRecords);
                    },
                    error: (error) => {
                        //console.log('Push-to-server failed.');

                        // Allow a new promise/subject to be created next time this.pushToServer() is called.
                        this.pushToServerPromise = null;
                        this.pushToServerInProgress = false;
                        this.pushToServerCompleted$ = null;

                        reject(error);
                    },
                    /**
                     * Complete is called if the subject completes without error. See https://reactivex.io/documentation/contract.html.
                     */
                    complete: () => {
                        //console.log('Push-to-server complete.');
                        // Allow a new promise/subject to be created next time this.pushToServer() is called.
                        this.pushToServerPromise = null;
                        this.pushToServerInProgress = false;
                        this.pushToServerCompleted$ = null;
                    },
                });
            });
        }

        /**
         * If a push-to-server is currently active, schedule the second one for later to prevent DOCUMENT_VERSION_TOO_HIGH errors. See details at the definition of this.pushToServerInProgress.
         */
        if (this.pushToServerInProgress) {
            console.log(`⏱ [offline-sync ${this.serviceName}] Push-to-server in progress. Return follow-up promise.`);
            /**
             * Only register a then-function if none has been registered for the current push-to-server. Otherwise, multiple push-to-server would be triggered when the current push-to-server
             * completes. That would cause too many requests or (since there is a timeout catching those calls) would increase the debugging complexity due to multiple unintuitive pushToServer() calls.
             */
            if (!this.followupPushToServerPromise) {
                /**
                 * Push changes to the server again since a pushToServer() throttle timeout was reached while an earlier pushToServer() was still in progress.
                 */
                console.log(
                    `⏱ [offline-sync ${this.serviceName}] Creating a follow-up promise to push to the server after the current push-to-server completes.`,
                );
                this.followupPushToServerPromise = this.pushToServerPromise.then(() => {
                    //console.log('⏱ Previous pushToServer() completed. Start the follow-up to sync changes that were introduced in the meantime.');
                    /**
                     * Since the follow-up push to server is triggered when currentPushToServerCompletedSubject emits the next value, allow the next push-to-server registration for the next time pushToServer() is called multiple times
                     * at the same time.
                     */
                    this.followupPushToServerPromise = null;

                    return this.pushToServer().then((pushToServerResult) => {
                        console.log(`✅ [offline-sync ${this.serviceName}] Follow-up push-to-server completed.`);
                        return pushToServerResult;
                    });
                });
            }
            return this.followupPushToServerPromise;
        }

        /**
         * If a timer is already running, clear it and start a new one. This enables throttling.
         */
        if (this.pushToServerTimeoutId) {
            window.clearTimeout(this.pushToServerTimeoutId);
            //console.log('Timeout pushToServer() cleared.');
        }

        // Set timeout: complete subject
        this.pushToServerTimeoutId = window.setTimeout(async () => {
            //console.log('Timeout pushToServer() reached.');
            /**
             * As soon as this timeout is reached, let the this.pushToServerPromise be recreated next time pushToServer() is called. This is necessary
             * so that multiple pushToServer() calls can be executed one after another.
             */
            this.pushToServerTimeoutId = null;

            this.pushToServerInProgress = true;
            let numberOfPushedRecords: number;
            try {
                numberOfPushedRecords = await this._pushToServerUnthrottled();
            } catch (error) {
                let errorMessage: [string, string];
                //*****************************************************************************
                //  Handle Closed IndexedDB Connection
                //****************************************************************************/
                /**
                 * Some browsers kill the IndexedDB connection after some time. This alert tells the user that's the case.
                 * The automatic re-opening of the IndexedDB connection in the file ax-indexed-db.class.ts should avoid
                 * our code ever running into this error handler branch.
                 */
                if (error.name === 'InvalidStateError') {
                    errorMessage = [
                        'Lokaler Speicher nicht erreichbar.',
                        `[Service: ${this.serviceName}] Die Verbindung zur IndexedDB wurde geschlossen.\n\nBitte lade die Seite neu, damit der Sync wieder funktioniert.`,
                    ];

                    /**
                     * Capture in Sentry for better transparency how often this occurs.
                     */
                    const axError = new AxError({
                        code: 'PUSH_TO_SERVER_FAILED_DUE_TO_CLOSED_INDEXEDDB_CONNECTION',
                        message:
                            'The push to server failed because the connection to the IndexedDB was closed unexpectedly.',
                        data: {
                            serviceName: this.serviceName,
                        },
                    });
                    captureException(axError);

                    // The toast service is rarely passed to this base class. Notify the user through an alert.
                    window.alert(errorMessage.join('\n\n'));
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Handle Closed IndexedDB Connection
                /////////////////////////////////////////////////////////////////////////////*/

                this.pushToServerCompleted$.error(error);

                // Return to not let the sync "finish". If we didn't do this, the initial sync would throw many alerts
                // when trying to call next() on a nulled pushToServerCompleted$ Subject.
                return;
            }

            this.pushToServerCompleted$.next(numberOfPushedRecords);
            this.pushToServerCompleted$.complete();
        }, this.PUSH_TO_SERVER_DEBOUNCE_TIME_IN_MS);

        // Return a shared promise to all throttled calls.
        return this.pushToServerPromise;
    }

    /**
     * This method has been extracted to simplify the throttling code within pushToServer.
     *
     * Returns the number of pushed records.
     *
     * @private
     */
    private async _pushToServerUnthrottled(retryCount = 0): Promise<number> {
        const [creationChangeRecords, deletionChangeRecords, patchChangeRecords] = await Promise.all([
            this.localDb.getCreationChangeRecords(),
            this.localDb.getDeletionChangeRecords(),
            this.localDb.getPatchChangeRecords(),
        ]);

        //*****************************************************************************
        //  Derive Required Edits
        //****************************************************************************/
        /**
         * Contains exactly one object per record if the record was created, patched or deleted.
         * Contains no object for unmodified records since those don't need to be synced.
         *
         * The RecordEdit object contains info about how the record has been edited locally (created, patched, deleted).
         */
        const edits = new Map<DataType['_id'], RecordEdit>();

        const allChangeRecords: { _id: string }[] = [
            ...creationChangeRecords,
            ...deletionChangeRecords,
            ...patchChangeRecords,
        ];

        //if (!allChangeRecords.length) {
        //    console.log('Keine Änderungen erkannt.', {allChangeRecords});
        //}

        // Create a map entry for each record, no matter if it has been created, patched or deleted.
        for (const record of allChangeRecords) {
            edits.set(record._id, {
                // The actual flags will be set later.
                created: false,
                patched: false,
                deleted: false,
            });
        }

        for (const creationChangeRecord of creationChangeRecords) {
            const edit = edits.get(creationChangeRecord._id);
            edit.created = true;
        }

        for (const patchChangeRecord of patchChangeRecords) {
            const edit = edits.get(patchChangeRecord._id);
            edit.patched = true;
        }

        for (const deletionChangeRecord of deletionChangeRecords) {
            const edit = edits.get(deletionChangeRecord._id);
            edit.deleted = true;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Derive Required Edits
        /////////////////////////////////////////////////////////////////////////////*/

        const editEntries: [DataType['_id'], RecordEdit][] = [...edits.entries()];

        const idsOfRecordsNeedingToBeRepushedAfterLocalMigration: DataType['_id'][] = [];
        const recordIdsPushAfterRemoteChangesMerged: DataType['_id'][] = [];

        /**
         * Sync changes to server.
         * If a record has been marked with multiple conditions, e.g. as created & patched locally, the routine only triggers
         * a request for the flag with the highest precedence.
         * Highest precedence: Created or deleted.
         * Lowest precedence: Patched.
         *
         * A record cannot be created, patched or deleted at the same time. A local-only created record will be automatically removed
         * locally by the central deletion routine. A locally created record that was locally updated still counts as "created".
         *
         * Execute server requests in parallel. That increases performance in comparison to syncing each record on its own.
         */
        const promiseSettledResult = await Promise.allSettled(
            editEntries.map(async ([recordId, edit]) => {
                try {
                    //*****************************************************************************
                    //  Record Created
                    //****************************************************************************/
                    /**
                     * Scenario 1: Record was created and not edited --> create on server.
                     * Scenario 2: Record was created and edited --> create on server.
                     */
                    if (edit.created) {
                        const record = await this.localDb.getLocal(recordId);
                        if (!record) {
                            const error = new AxError({
                                code: 'LOCAL_RECORD_MISSING',
                                message: `Missing current state for record ${this.serviceName}/${recordId}. It was required during patchRemote.`,
                                data: {
                                    recordId,
                                    serviceName: this.serviceName,
                                },
                            });

                            this.recordIdsWithSyncIssues.set(recordId, error.code);
                            /**
                             * Show a notification to the user that there were sync issues so that the user may resolve them.
                             */
                            this.syncIssueNotificationService.create({
                                heading: 'Lokaler Datensatz fehlt',
                                reason: 'Das ist ein technisches Problem. Bitte wende dich an die Hotline.',
                                databaseService: this,
                                recordId: recordId,
                                error: error,
                            });

                            throw error;
                        }

                        /**
                         * Optimistic deletes. The records are added back in case of failure. This ensures that local changes that occur during a this.createRemote() call are
                         * reflected in the IndexedDB immediately to be synced next time the pushToServer() method is called.
                         */
                        await this.localDb.deleteCreationChangeRecord(recordId);
                        if (edit.patched) {
                            await this.localDb.deletePatchChangeRecord(recordId);
                        }

                        try {
                            const createdRecord: DataType = await this.httpSync.createRemote(record);
                            await this.localDb.putServerShadow(createdRecord);

                            // In case the record had sync issues before, clear that.
                            this.recordIdsWithSyncIssues.delete(record._id);

                            // In case a notification was added to the SyncIssueNotificationService, remove it since this sync issue was resolved.
                            this.syncIssueNotificationService.resolve({
                                databaseService: this,
                                recordId: record._id,
                            });
                        } catch (error) {
                            if (error?.code === 'ID_COLLISION') {
                                /**
                                 * If the record with this _id already exists on the server, put this record to the server instead.
                                 */
                                console.log(
                                    `[offline-sync ${this.serviceName}] This record should have been created on the server but it already exists. Do not try to create it on the server anymore.`,
                                    record,
                                );

                                /**
                                 * We assume that the current record is in the same state as the server shadow because we generate unique IDs, so it's most likely that this client
                                 * is only trying to POST twice shortly after another.
                                 *
                                 * If the server shadow is more up-to-date (higher _documentVersion), this client will pull it before determining the deltas anyways.
                                 */
                                await this.localDb.putServerShadow(record);

                                /**
                                 * We abandoned this approach since pulling from the server may lead to pushing to the server which led to infinity loops.
                                 */
                                //let pullingNewServerStateWorked = false;
                                //try {
                                //    await this.pullFromServer({
                                //        _id : recordId
                                //    });
                                //    pullingNewServerStateWorked = true;
                                //}
                                //catch (error) {
                                //    // If pulling did not work, restore the original state.
                                //    await indexeddbConnection.put('created', {_id : recordId});
                                //}
                                //
                                //if (pullingNewServerStateWorked) {
                                //    await indexeddbConnection.put('patched', {_id : recordId});
                                //    await indexeddbConnection.put('serverShadows', record);
                                //    edit.created = false;
                                //    edit.patched = true;
                                //}
                            } else {
                                console.log(`[offline-sync ${this.serviceName}] Create on server failed.`, { error });

                                // Add records back that were optimistically deleted.
                                await this.localDb.setCreationChangeRecord(recordId);
                                if (edit.patched) {
                                    await this.localDb.setPatchChangeRecord(recordId);
                                }

                                // If the user is not authenticated, he is redirected to the login page through an Angular HTTP interceptor. No need to add a sync issue for that.
                                if (error?.code === 'AX_NOT_AUTHENTICATED') {
                                    return;
                                }

                                /*****************************************************************************
                                 *  Handle Schema Version Mismatch
                                 *****************************************************************************/
                                if (error.code === 'NEWER_SCHEMA_VERSION_AVAILABLE') {
                                    const highestClientMigrationVersion =
                                        this.localDb.getHighestMigrationVersionNumber();
                                    const requiredSchemaVersionByServer = error.data.requiredSchemaVersion;

                                    // If the client has a local migration to move from an older to the latest schema version on the server, execute it.
                                    if (highestClientMigrationVersion >= requiredSchemaVersionByServer) {
                                        await this.localDb.updateRecordSchemas();
                                    } else {
                                        // Service Workers are only enabled on production.
                                        if (this.serviceWorker.isEnabled) {
                                            void this.serviceWorker.checkForUpdate();
                                        }
                                        throw Error(
                                            `This client does not know how to reach the server's schema version level. Please update the client.`,
                                        );
                                    }

                                    // Trigger another push to server after we've migrated the record locally.
                                    idsOfRecordsNeedingToBeRepushedAfterLocalMigration.push(recordId);
                                }
                                /*****************************************************************************
                                 * END Handle Schema Version Mismatch
                                 *****************************************************************************/

                                this.recordIdsWithSyncIssues.set(record._id, error.code);

                                switch (error.code) {
                                    case 'INVOICE_NUMBER_EXISTS':
                                        // Show a notification to the user that there were sync issues so that the user may resolve them.
                                        this.syncIssueNotificationService.create({
                                            heading: 'Rechnungsnummer existiert bereits',
                                            reason: 'Das passiert oft, wenn in den Einstellungen der Storno-Suffix leer ist oder die Rechnungsnummer manuell gesetzt wurde.',
                                            solution:
                                                'Falls die Ursache eine Stornorechnung war: Setze in den Einstellungen > Rechnung einen Suffix für die Stornorechnung.',
                                            databaseService: this,
                                            recordId: record._id,
                                            error: error,
                                        });
                                        break;
                                    default:
                                        // Show a notification to the user that there were sync issues so that the user may resolve them.
                                        this.syncIssueNotificationService.create({
                                            heading: 'Anlage fehlgeschlagen',
                                            reason: 'Das passiert oft, wenn der Server nicht erreichbar ist oder das Datenformat noch alt ist.',
                                            databaseService: this,
                                            recordId: record._id,
                                            error: error,
                                        });
                                }

                                throw error;
                            }
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Record Created
                    /////////////////////////////////////////////////////////////////////////////*/

                    //*****************************************************************************
                    //  Record Deleted
                    //****************************************************************************/
                    /**
                     * Scenario 1: Record exists on server and was locally deleted and not edited --> delete on server.
                     * Scenario 2: Record exists on server and was locally deleted and edited --> delete on server.
                     */
                    if (edit.deleted) {
                        /**
                         * Optimistic deletes. The records are added back in case of failure. This ensures that local changes that occur during a this.deleteRemote() call are
                         * reflected in the IndexedDB immediately to be synced next time the pushToServer() method is called.
                         */
                        await this.localDb.deleteDeletionChangeRecord(recordId);
                        if (edit.patched) {
                            await this.localDb.deletePatchChangeRecord(recordId);
                        }

                        let deleteRemoteSucceeded: boolean = false;
                        try {
                            await this.httpSync.deleteRemote(recordId);

                            // In case the record had sync issues before, clear that.
                            this.recordIdsWithSyncIssues.delete(recordId);

                            // In case a notification was added to the SyncIssueNotificationService, remove it since this sync issue was resolved.
                            this.syncIssueNotificationService.resolve({
                                databaseService: this,
                                recordId: recordId,
                            });

                            deleteRemoteSucceeded = true;
                        } catch (error) {
                            // If the server record could not be deleted simply because it was already deleted, consider this a success.
                            if (error?.name === 'NotFound') {
                                deleteRemoteSucceeded = true;
                            } else {
                                console.error(`[offline-sync ${this.serviceName}] Delete on server failed.`, { error });

                                // If the user is not authenticated, he is redirected to the login page through an Angular HTTP interceptor. No need to add a sync issue for that.
                                if (error?.code === 'AX_NOT_AUTHENTICATED') {
                                    return;
                                }

                                this.recordIdsWithSyncIssues.set(recordId, error.code);
                                // Show a notification to the user that there were sync issues so that the user may resolve them.
                                this.syncIssueNotificationService.create({
                                    heading: 'Löschen fehlgeschlagen',
                                    reason: 'Der Datensatz konnte nicht auf dem Server gelöscht werden.',
                                    databaseService: this,
                                    recordId: recordId,
                                    error: error,
                                });

                                // Add records back that were optimistically deleted.
                                await this.localDb.setDeletionChangeRecord(recordId);
                                if (edit.patched) {
                                    await this.localDb.setPatchChangeRecord(recordId);
                                }

                                throw error;
                            }
                        }

                        if (deleteRemoteSucceeded) {
                            await this.localDb.deleteServerShadow(recordId);
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Record Deleted
                    /////////////////////////////////////////////////////////////////////////////*/

                    //*****************************************************************************
                    //  Record Updated
                    //****************************************************************************/
                    /**
                     * Scenario 1: Record was only patched, not created or deleted --> put on server.
                     *
                     * It's required that this is an "if" not an "else if" since records that should have been created but couldn't
                     * because the record exists on the server already will instead be patched.
                     */
                    if (edit.patched) {
                        const serverShadow: DataType | undefined = await this.localDb.getServerShadow(recordId);
                        const localRecord: DataType | undefined = await this.localDb.getLocal(recordId);

                        //*****************************************************************************
                        //  Required Records Validation
                        //****************************************************************************/
                        if (!serverShadow) {
                            const error = new AxError({
                                code: 'SERVER_SHADOW_MISSING',
                                message: `Missing server shadow for record ${this.serviceName}/${recordId}. It was required during patchRemote.`,
                                data: {
                                    recordId,
                                    serviceName: this.serviceName,
                                },
                            });

                            this.recordIdsWithSyncIssues.set(recordId, error.code);
                            /**
                             * Show a notification to the user that there were sync issues so that the user may resolve them.
                             */
                            this.syncIssueNotificationService.create({
                                heading: 'Server-Zustand fehlt',
                                reason: 'Das passiert bei fehlerhaftem Sync mit dem Server.',
                                solution: 'Ist ggf. deine Festplatte voll? Falls nicht, kontaktiere bitte die Hotline.',
                                databaseService: this,
                                recordId: recordId,
                                error: error,
                            });

                            // Log to Sentry.
                            captureException(error);

                            throw error;
                        }
                        if (!localRecord) {
                            const error = new AxError({
                                code: 'LOCAL_RECORD_MISSING',
                                message: `Missing current state for record ${this.serviceName}/${recordId}. It was required during patchRemote.`,
                                data: {
                                    recordId,
                                    serviceName: this.serviceName,
                                },
                            });

                            this.recordIdsWithSyncIssues.set(recordId, error.code);
                            /**
                             * Show a notification to the user that there were sync issues so that the user may resolve them.
                             */
                            this.syncIssueNotificationService.create({
                                heading: 'Lokaler Datensatz fehlt',
                                reason: 'Das passiert bei fehlerhaftem Sync mit dem Server.',
                                databaseService: this,
                                recordId: recordId,
                                error: error,
                            });

                            throw error;
                        }
                        /////////////////////////////////////////////////////////////////////////////*/
                        //  END Required Records Validation
                        /////////////////////////////////////////////////////////////////////////////*/

                        //*****************************************************************************
                        //  Increase _documentVersion
                        //****************************************************************************/
                        /**
                         * Increase the version number so that this object can be written to the server. If the version number of this PATCH request
                         * does not equal the server's version number + 1, the server will reject the request to prevent accidental overwrites.
                         * The client would then react correctly by incorporating the state from the server and retrying the request.
                         */
                        localRecord._documentVersion = serverShadow._documentVersion + 1;
                        /////////////////////////////////////////////////////////////////////////////*/
                        //  END Increase _documentVersion
                        /////////////////////////////////////////////////////////////////////////////*/

                        //*****************************************************************************
                        //  Create Delta Patch List
                        //****************************************************************************/
                        /**
                         * Compare current local record with the latest state from the server (server shadow). Send changes to server
                         * and apply changes to local server shadow to build the current server state incrementally.
                         *
                         * Why build incrementally? Exchanging full objects on every put would cause lots of unnecessary data transfer.
                         */
                        // TODO If a large array has changed, we currently put the entire array. By means of microdiff etc. one could put only the changed objects instead.
                        const deltaPatch: FlattenedChangePaths = flattenChangePaths(
                            extractChanges(serverShadow, localRecord),
                        );
                        // Always send the schema version along to ensure that no old record formats are sent to the server. The server would reject an old format.
                        deltaPatch._schemaVersion = localRecord._schemaVersion;
                        const localChanges = getListOfDifferences(serverShadow, localRecord);
                        // Skip updatedAt, which is set by the server. Skipping this also prevents triggering update cycle if only updatedAt changed.
                        const localChangesWithoutMetadata = localChanges.filter(
                            (difference) => difference.key !== 'updatedAt' && difference.key !== '_documentVersion',
                        );
                        /////////////////////////////////////////////////////////////////////////////*/
                        //  END Create Delta Patch List
                        /////////////////////////////////////////////////////////////////////////////*/

                        //*****************************************************************************
                        //  Abort if No Changes Detected
                        //****************************************************************************/
                        // No changes -> No need to patch.
                        if (!localChangesWithoutMetadata.length) {
                            /**
                             * If there haven't been any changes compared to the server shadow, unmark the record as patched.
                             *
                             * This happens when the user is offline, changes a record value but later changes it back. When
                             * he comes online, this sync is triggered and the record is marked as patched.
                             */
                            await this.localDb.deletePatchChangeRecord(recordId);
                            // In case the record had sync issues before, clear that.
                            this.recordIdsWithSyncIssues.delete(recordId);

                            // In case a notification was added to the SyncIssueNotificationService, remove it since this sync issue was resolved.
                            this.syncIssueNotificationService.resolve({
                                databaseService: this,
                                recordId: recordId,
                            });
                            return;
                        }
                        /////////////////////////////////////////////////////////////////////////////*/
                        //  END Abort if No Changes Detected
                        /////////////////////////////////////////////////////////////////////////////*/

                        //*****************************************************************************
                        //  Block Report Resets
                        //****************************************************************************/
                        /**
                         * We've observed regularly that reports are being reset. We don't know the reason, therefore
                         * we're blocking any faulty updates and log the event to Sentry.
                         */
                        if (this.serviceNamePlural === 'reports') {
                            if (
                                isPropertyBeingCleared('photos', localChangesWithoutMetadata) &&
                                (isPropertyBeingCleared('car.mileageMeter', localChangesWithoutMetadata) ||
                                    isPropertyBeingCleared(
                                        'car.unrepairedPreviousDamage',
                                        localChangesWithoutMetadata,
                                    ) ||
                                    isPropertyBeingCleared('car.repairedPreviousDamage', localChangesWithoutMetadata) ||
                                    isPropertyBeingCleared('car.damageDescription', localChangesWithoutMetadata))
                            ) {
                                const axError = new ServerError({
                                    code: 'REPORT_RESET_BLOCKED',
                                    message: `Photos and other properties have been cleared. This is likely an involuntary reset.`,
                                    data: {
                                        userAgent: window.navigator.userAgent,
                                        reportId: localRecord._id,
                                        localChangesWithoutMetadata,
                                        localRecord,
                                        serverShadow,
                                    },
                                });

                                console.error(
                                    `It appears that a report has been reset by a client. This error has been logged to Sentry.`,
                                    axError,
                                );
                                // Sentry
                                captureException(axError);

                                this.toastNotificationService.error(
                                    `Rücksetzung des Gutachtens ${(localRecord as any).token || localRecord._id} erkannt`,
                                    "Fotos und Schadenbeschreibung/Vorschäden wurden offenbar zurückgesetzt. Bitte kontaktiere <a href='/Hilfe'>Hotline</a>, um die Fehlerursache zu finden.<br><br>Bitte notiere dir, welche Schritte du in diesem Gutachten mit welchen Geräten ausgeführt hast.<br><br>Alle anderen Gutachten werden weiterhin regulär synchronisiert.",
                                );

                                /**
                                 * We do send the patch in order to keep the user able to work.
                                 * Alternatives:
                                 * - clear changes -> We wouldn't know what we've cleared, possibly confusing the user.
                                 * - throw error -> An error would occur on every save, even in other reports. Not feasible for everyday work.
                                 */
                                // throw axError;
                            }
                        }
                        /////////////////////////////////////////////////////////////////////////////*/
                        //  END Block Report Resets
                        /////////////////////////////////////////////////////////////////////////////*/

                        /**
                         * Optimistic delete. The record is added back in case of failure. This ensures that local changes that occur during an HTTP call are
                         * reflected in the IndexedDB immediately to be synced next time the pushToServer() method is called.
                         */
                        await this.localDb.deletePatchChangeRecord(recordId);

                        try {
                            await this.httpSync.patchRemote(recordId, deltaPatch);

                            // Only after sync happened, overwrite local server shadow to always determine the differences to the real server state.
                            insertChangesIntoRecord(serverShadow, localChanges);
                            await this.localDb.putServerShadow(serverShadow);

                            // In case the record had sync issues before, clear that.
                            this.recordIdsWithSyncIssues.delete(recordId);

                            // In case a notification was added to the SyncIssueNotificationService, remove it since this sync issue was resolved.
                            this.syncIssueNotificationService.resolve({
                                databaseService: this,
                                recordId: recordId,
                            });
                        } catch (error) {
                            console.log(
                                `[offline-sync ${this.serviceName}] Saving sync issue with rejected record ${recordId}`,
                            );

                            // Add the optimistically deleted patch change record back.
                            await this.localDb.setPatchChangeRecord(recordId);

                            // If the client is offline, we display that explicitly in the app. No need to add a sync issue for that.
                            if (error?.code === 'CLIENT_IS_OFFLINE') {
                                return;
                            }
                            // If the user is not authenticated, he is redirected to the login page through an Angular HTTP interceptor. No need to add a sync issue for that.
                            if (error?.code === 'AX_NOT_AUTHENTICATED') {
                                return;
                            }

                            this.recordIdsWithSyncIssues.set(recordId, error.code);

                            switch (error?.code) {
                                //*****************************************************************************
                                //  Handle Document Version Mismatch
                                //****************************************************************************/
                                case 'NEWER_DOCUMENT_VERSION_AVAILABLE': {
                                    const remoteRecord: DataType = error.data.recordFromServer;

                                    // Merge remote changes into local record
                                    threeWayMerge({
                                        localRecord,
                                        remoteRecord,
                                        serverShadow,
                                    });

                                    /**
                                     * Ensure that putLocal is called before putServerShadow. putLocal emits an event containing both the localRecord and
                                     * the serverShadow. That event is used by components to extract changes between the new localRecord and the old serverShadow. Those
                                     * changes are then applied to the component data. If the server shadow was the new object already, no changes would be detected and
                                     * applied to the component.
                                     */
                                    await this.localDb.putLocal(localRecord, 'externalServer');
                                    await this.localDb.putServerShadow(remoteRecord);

                                    recordIdsPushAfterRemoteChangesMerged.push(recordId);

                                    return;
                                }
                                /////////////////////////////////////////////////////////////////////////////*/
                                //  END Handle Document Version Mismatch
                                /////////////////////////////////////////////////////////////////////////////*/

                                //*****************************************************************************
                                //  Handle Schema Version Mismatch
                                //****************************************************************************/
                                case 'NEWER_SCHEMA_VERSION_AVAILABLE': {
                                    /**
                                     * Show a notification to the user that there were sync issues so that the user may resolve them.
                                     *
                                     * This notification should not be shown if a newer document version is available (see above), so this .create() call
                                     * is placed after checking for the error code "NEWER_DOCUMENT_VERSION_AVAILABLE".
                                     * The _documentVersion should always be updated when we change the schema through a server-side migration.
                                     */
                                    this.syncIssueNotificationService.create({
                                        heading: 'Altes Datenformat',
                                        reason: `Das passiert oft, wenn dein Browser noch alten ${getProductName()}-Code nutzt.`,
                                        databaseService: this,
                                        recordId: recordId,
                                        error: error,
                                    });

                                    const highestMigrationVersionNumber =
                                        this.localDb.getHighestMigrationVersionNumber();
                                    const requiredSchemaVersionByServer = error.data.requiredSchemaVersion;

                                    if (requiredSchemaVersionByServer <= highestMigrationVersionNumber) {
                                        await this.localDb.updateRecordSchemas();
                                    } else {
                                        // Service Workers are only enabled on production.
                                        if (this.serviceWorker.isEnabled) {
                                            await this.serviceWorker.checkForUpdate();
                                        }
                                        throw Error(
                                            `This client does not know how to reach the server's schema version level. Please update the client.`,
                                        );
                                    }

                                    recordIdsPushAfterRemoteChangesMerged.push(recordId);

                                    return;
                                }
                                /////////////////////////////////////////////////////////////////////////////*/
                                //  END Handle Schema Version Mismatch
                                /////////////////////////////////////////////////////////////////////////////*/

                                //*****************************************************************************
                                //  Handle Schema Validation Failed
                                //****************************************************************************/
                                case 'SCHEMA_VALIDATION_FAILED': {
                                    /**
                                     * If the _schemaVersions are equal, we assume the invalid property must be a relict
                                     * from an old client version -> Remove or overwrite with server value.
                                     */
                                    const remoteRecord: DataType = error.data.recordFromServer;

                                    if (remoteRecord._schemaVersion === localRecord._schemaVersion) {
                                        /**
                                         * Technical error for logging. Not intended for the user.
                                         */
                                        const schemaViolationError = new AxError({
                                            code: 'SCHEMA_VIOLATED_DESPITE_EQUAL_SCHEMA_VERSION',
                                            message: `Patching the record caused a schema violation. This is most likely caused by an old client version. The invalid property will be deleted and another sync will be triggered.`,
                                            data: {
                                                recordId,
                                                serviceName: this.serviceName,
                                                localSchemaVersion: localRecord._schemaVersion,
                                                remoteSchemaVersion: remoteRecord._schemaVersion,
                                                invalidPath: error.data.invalidPath,
                                                invalidValue: error.data.invalidValue,
                                            },
                                        });

                                        //*****************************************************************************
                                        //  Attempt Auto-Fix
                                        //****************************************************************************/
                                        // Check if we have already tried auto-fixing a few times. Abort after too many attempts.
                                        let existingSyncIssueNotification = this.syncIssueNotificationService.get({
                                            databaseService: this,
                                            recordId,
                                        });

                                        if (!existingSyncIssueNotification) {
                                            existingSyncIssueNotification = this.syncIssueNotificationService.create({
                                                heading: 'Ungültiges Datenformat trotz gleicher Schema-Version',
                                                reason: `Das passiert oft, wenn ein alter Client-Stand alte Datenstrukturen abgespeichert hat. Auto-Reparatur wird versucht.`,
                                                databaseService: this,
                                                recordId,
                                                error: schemaViolationError,
                                                previousAutoFixAttempts: 0,
                                            });
                                        }

                                        /**
                                         * We allow trying more than once. That allows removing multiple invalid
                                         * properties. The server only returns one invalid property per request.
                                         */
                                        if (existingSyncIssueNotification?.previousAutoFixAttempts <= 3) {
                                            /**
                                             * If the property is present in the server record, use that record's value.
                                             * Otherwise, remove the value completely.
                                             */
                                            const remotePropertyValue = get(remoteRecord, error.data.invalidPath);
                                            if (remotePropertyValue) {
                                                set(localRecord, error.data.invalidPath, remotePropertyValue);
                                            } else {
                                                /**
                                                 * Since the property does not exist on the server any more, clear
                                                 * it on the server shadow.
                                                 *
                                                 * We cannot replace the full serverShadow because we still
                                                 * need to get a change list for all other changed properties from
                                                 * the original server shadow.
                                                 */
                                                unset(serverShadow, error.data.invalidPath);
                                                await this.localDb.putServerShadow(serverShadow);

                                                // Remove property from local record.
                                                unset(localRecord, error.data.invalidPath);
                                            }

                                            /**
                                             * There may be other changes apart from the conflicting one, therefore we must
                                             * still mark this record as changed by the user.
                                             */
                                            await this.localDb.putLocal(localRecord, 'merge');

                                            /**
                                             * Don't await this call. That could cause callstack exceeded errors if there were:
                                             * - many records that violate the schema
                                             * - many properties per record violating the schema.
                                             */
                                            void this.pushToServer();

                                            this.syncIssueNotificationService.increasePreviousAutoFixAttempts({
                                                databaseService: this,
                                                recordId,
                                            });
                                        }
                                        /////////////////////////////////////////////////////////////////////////////*/
                                        //  END Attempt Auto-Fix
                                        /////////////////////////////////////////////////////////////////////////////*/

                                        throw schemaViolationError;
                                    }

                                    /**
                                     * Show a notification to the user that there were sync issues so that the user may resolve them.
                                     *
                                     * This notification should not be shown if a newer document version is available (see above), so this .create() call
                                     * is placed after checking for the error code "NEWER_DOCUMENT_VERSION_AVAILABLE".
                                     */
                                    this.syncIssueNotificationService.create({
                                        heading: 'Altes oder ungültiges Datenformat',
                                        reason: `Das passiert oft, wenn dein Browser noch alten ${getProductName()}-Code nutzt oder ein struktureller Fehler vorliegt.`,
                                        databaseService: this,
                                        recordId: recordId,
                                        error: error,
                                    });
                                    break;
                                }
                                /////////////////////////////////////////////////////////////////////////////*/
                                //  END Handle Schema Validation Failed
                                /////////////////////////////////////////////////////////////////////////////*/

                                //*****************************************************************************
                                //  Access Right Missing
                                //****************************************************************************/
                                case 'ADMIN_PRIVILEGES_REQUIRED': {
                                    this.syncIssueNotificationService.create({
                                        heading: 'Änderung ist Admins vorbehalten',
                                        reason: `Diese Änderung darf nur als Admin vorgenommen werden.`,
                                        solution:
                                            'Lass dich als Admin freischalten oder kontaktiere einen, damit er die Änderung vornimmt.',
                                        databaseService: this,
                                        recordId: recordId,
                                        error: error,
                                    });

                                    // Log to Sentry.
                                    captureException(error);
                                    break;
                                }
                                /////////////////////////////////////////////////////////////////////////////*/
                                //  END Access Right Missing
                                /////////////////////////////////////////////////////////////////////////////*/
                                default: {
                                    /**
                                     * Show a notification to the user that there were sync issues so that the user may resolve them.
                                     *
                                     * This notification should not be shown if a newer document version is available (see above), so this .create() call
                                     * is placed after checking for the error code "NEWER_DOCUMENT_VERSION_AVAILABLE".
                                     */
                                    this.syncIssueNotificationService.create({
                                        heading: 'Sync fehlgeschlagen',
                                        reason: 'Der Datensatz konnte nicht zum Server gespeichert werden.',
                                        databaseService: this,
                                        recordId: recordId,
                                        error: error,
                                    });
                                }
                            }

                            throw error;
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Record Updated
                    /////////////////////////////////////////////////////////////////////////////*/
                } catch (error) {
                    /**
                     * In theory the user should not be able to change settings without the necessary access right because the
                     * UI should disable or hide respective controls. One case where it might happen is when the user just
                     * lost an access right but is still on a page that is only available for users with the lost access right.
                     * For all other cases we want to log these errors to sentry, so we can fix them in the UI.
                     */
                    if (error.code === 'ACCESS_DENIED') {
                        captureException(
                            new AxError({
                                code: 'SYNC_ISSUE_DUE_TO_MISSING_ACCESS_RIGHTS',
                                message:
                                    'A sync issue occurred because the user tried to change settings that require access rights the user does not have.',
                                data: {
                                    recordId,
                                },
                            }),
                        );
                    }

                    // Error is currently swallowed by Promise.allSettled() but in case someone uses the returned array, this promise will be properly rejected.
                    throw error;
                }
            }),
        );

        const rejectedPromiseResults: PromiseRejectedResult[] = (
            promiseSettledResult as PromiseRejectedResult[]
        ).filter((result) => result.status === 'rejected');
        if (rejectedPromiseResults.length) {
            throw new AxError({
                code: 'OFFLINE_SYNC_PUSH_TO_SERVER_FAILED',
                message: 'There were issues syncing changes to the server. See data for a list of errors.',
                data: {
                    serviceName: this.serviceName,
                    errors: rejectedPromiseResults.map((result) => result.reason),
                },
            });
        }

        if (recordIdsPushAfterRemoteChangesMerged.length || idsOfRecordsNeedingToBeRepushedAfterLocalMigration.length) {
            if (retryCount > 5) {
                throw new AxError({
                    code: 'TOO_MANY_RETRIES_PUSHING_CHANGES_TO_SERVER',
                    message:
                        'Pushing changes to the server may result in a couple of retries until all server changes are merged locally and pushed back to the server. But there seems to be an infinity loop. This is likely a technical issue.',
                    data: {
                        serviceName: this.serviceName,
                        retryCount,
                        recordIdsPushAfterRemoteChangesMerged,
                    },
                });
            }
            await this._pushToServerUnthrottled(retryCount++);
        }

        // How many records have been changed?
        return edits.size;
    }

    /**
     * Fetch all records that belong to the given record shells from the server.
     * - New records
     * - Changed records: Merge incoming changes.
     * - Deleted records: Delete them locally.
     */
    public async pullFromServer(recordShellsFromServer: RecordShell[]): Promise<PullFromServerResult<DataType>> {
        const recordIdsForPull = recordShellsFromServer.map((recordShell) => recordShell._id);

        if (!recordIdsForPull.length) {
            return {
                createdRecords: new Map(),
                updatedRecords: new Map(),
                unchangedRecords: new Map(),
                conflictingChanges: [],
                numberOfChangedRecords: 0,
            };
        }

        const localRecords: DataType[] = await this.localDb.findLocalByIds(recordIdsForPull);

        const newOnServerRecordIds = new Set<DataType['_id']>();
        const updatedOnServerRecordIds = new Set<DataType['_id']>();

        for (const recordShellFromServer of recordShellsFromServer) {
            const localRecord = localRecords.find((localRecord) => localRecord._id === recordShellFromServer._id);

            if (!localRecord) {
                // The record does not exist on the client, so get it from the server and save it locally.
                newOnServerRecordIds.add(recordShellFromServer._id);
            } else if (recordShellFromServer._documentVersion > localRecord._documentVersion) {
                // The record on the server is newer.
                updatedOnServerRecordIds.add(recordShellFromServer._id);
            } else {
                // The record is up-to-date on the client. No need for any further action.
            }
        }

        //*****************************************************************************
        //  Get From Server
        //****************************************************************************/
        let recordsFromServer: DataType[] = [];
        const idsOfRecordsToFetch: string[] = [...newOnServerRecordIds, ...updatedOnServerRecordIds];
        // Only send a request if at least one record must be fetched.
        if (newOnServerRecordIds.size || updatedOnServerRecordIds.size) {
            for (let i = 0; i < idsOfRecordsToFetch.length; i += this.batchSize) {
                const lowerLimit = i;
                const upperLimit = i + this.batchSize;

                const batchIds: string[] = idsOfRecordsToFetch.slice(lowerLimit, upperLimit);

                try {
                    const newRecordsFromServer = await this.httpSync.findRemote({
                        _id: {
                            $in: batchIds,
                        },
                    });

                    recordsFromServer = [...recordsFromServer, ...newRecordsFromServer];
                } catch (error) {
                    /**
                     * If the user is not authenticated, he is redirected to the login page through an Angular HTTP interceptor.
                     * No need to rethrow the error to the global Angular error handler.
                     */
                    if (error?.code === 'AX_NOT_AUTHENTICATED') {
                        return;
                    }
                    throw error;
                }
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Get From Server
        /////////////////////////////////////////////////////////////////////////////*/

        const localRecordMap = new Map<DataType['_id'], DataType>();
        for (const localRecord of localRecords) {
            localRecordMap.set(localRecord._id, localRecord);
        }

        const remoteRecordMap = new Map<DataType['_id'], DataType>();
        for (const recordFromServer of recordsFromServer) {
            remoteRecordMap.set(recordFromServer._id, recordFromServer);
        }

        const newOnServerRecords = new Map<DataType['_id'], DataType>();
        const updatedOnServerRecords = new Map<DataType['_id'], DataType>();
        const unchangedRecords = new Map<DataType['_id'], DataType>();

        //*****************************************************************************
        //  New Records
        //****************************************************************************/
        // New records = whose keys do not exist in the local IndexedDB.
        // Create them locally and emit events.
        const newRecordsFromServer: DataType[] = [];
        for (const newOnServerRecordId of newOnServerRecordIds) {
            const newRecordFromServer: DataType = remoteRecordMap.get(newOnServerRecordId);
            // If a requested record is not available (e.g. because user has no right to see it), skip.
            // Atlas Search returned id's of records that the user has no right to see. This caused an 'cannot read ._id of undefined' error and stopped the search from working.
            // This is fixed on the backend side too.
            if (!newRecordFromServer) {
                console.log(`Record ${newOnServerRecordId} was requested but not provided in the response. Skip.`);
                continue;
            }
            newRecordsFromServer.push(newRecordFromServer);
            newOnServerRecords.set(newRecordFromServer._id, newRecordFromServer);
        }
        // If there are only updates, no creates, newRecordsFromServer is empty.
        if (newRecordsFromServer.length) {
            // Create all newly created records in one transaction to increase write performance.
            await Promise.all([
                this.localDb.createLocal(newRecordsFromServer, 'externalServer'),
                this.localDb.putServerShadow(newRecordsFromServer),
            ]);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END New Records
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Changed Records
        //****************************************************************************/

        const listOfConflictingChanges: MergeResult<DataType>[] = [];
        /**
         * This will be true if changes from the server apply to records that were changed locally, too. In that
         * case, the local changes need to be synced back to the server as well.
         */
        let remoteChangesWereMergedWithLocalChanges = false;

        // Which records have been changed? Patch them locally and emit events.
        for (const updatedOnServerRecordId of updatedOnServerRecordIds) {
            const recordHasBeenChangedLocally: boolean =
                (await this.localDb.getLocalStatus(updatedOnServerRecordId)) === 'patched';

            const remoteRecord = remoteRecordMap.get(updatedOnServerRecordId);
            updatedOnServerRecords.set(remoteRecord._id, remoteRecord);

            if (!recordHasBeenChangedLocally) {
                // No local changes? -> Merge server version.
                /**
                 * Await the putLocal method before setting a new server shadow because the events
                 * - patchedFromExternalServer$
                 * - patchedFromLocalUserInThisTab$
                 * within putLocal require the server shadow to be in the old state so that the components listening to those events need to calculate the correct
                 * differences in changes.
                 */
                await this.localDb.putLocal(remoteRecord, 'externalServer');
                await this.localDb.putServerShadow(remoteRecord);
            } else {
                //*****************************************************************************
                //  Merge Changes
                //****************************************************************************/
                /**
                 * We do have local changes. -> Merge all non-conflicting changes, then add conflicting changes to conflict list.
                 *
                 * non-conflicting = A field's new value is equal on both records.
                 */
                const serverShadow = await this.localDb.getServerShadow(updatedOnServerRecordId);
                const localRecord = localRecordMap.get(updatedOnServerRecordId);

                if (!localRecord) {
                    throw new AxError({
                        code: 'LOCAL_RECORD_MISSING_IN_PULL_FROM_SERVER',
                        message: `Service "${this.serviceName}" is missing local record ID ${updatedOnServerRecordId} when trying to update with incoming changes.`,
                    });
                }
                if (!serverShadow) {
                    throw new AxError({
                        code: 'SERVER_SHADOW_MISSING_IN_PULL_FROM_SERVER',
                        message: `Service "${this.serviceName}" is missing server shadow record ID ${updatedOnServerRecordId} when trying to update with incoming changes.`,
                    });
                }

                /**
                 * Merge remote changes into local record.
                 */
                const mergeResult = threeWayMerge({
                    localRecord,
                    remoteRecord,
                    serverShadow,
                });

                // Write the merged local record back to the DB and mark the record as changed (source = local) so that it's synced to the server on the next cycle.
                await this.localDb.putLocal(localRecord, 'localUser');

                // Since this pull changed the record, push it back to the server immediately. That ensures data consistency across all devices.
                remoteChangesWereMergedWithLocalChanges = true;

                if (mergeResult.conflictingChanges.length) {
                    // Add all conflicting changes to the list that must be handled by the caller.
                    listOfConflictingChanges.push({
                        mergedRecord: localRecord,
                        conflictingChanges: mergeResult.conflictingChanges,
                    });
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Merge Changes
            /////////////////////////////////////////////////////////////////////////////*/
        }
        if (remoteChangesWereMergedWithLocalChanges) {
            // Since this pull changed records, push them back to the server immediately. That ensures data consistency across all devices.
            await this.pushToServer();
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Changed Records
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Unchanged Records
        //****************************************************************************/
        for (const localRecord of localRecords) {
            if (!newOnServerRecords.has(localRecord._id) && !updatedOnServerRecords.has(localRecord._id)) {
                unchangedRecords.set(localRecord._id, localRecord);
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Unchanged Records
        /////////////////////////////////////////////////////////////////////////////*/

        return {
            createdRecords: newOnServerRecords,
            updatedRecords: updatedOnServerRecords,
            unchangedRecords,
            conflictingChanges: listOfConflictingChanges,
            numberOfChangedRecords: newOnServerRecords.size + updatedOnServerRecords.size,
        };
    }

    //*****************************************************************************
    //  Fetch & Process Deleted Records
    //****************************************************************************/
    /**
     * Deleted records are not in the MongoDB collection anymore, so they won't be found when querying
     * for new records. Instead, the server creates a tombstone record for the deleted record so that
     * clients on which the record was not deleted initially can know that the record is gone.
     *
     * This function queries those tombstone records and applies changes locally.
     */
    public async findNewDeletedRecords(): Promise<number> {
        const databaseInfo: DatabaseInfo = await this.localDb.getDatabaseInfo();

        const deletedRecordsRemote: DeletedRecord[] = await this.httpSync.findDeletedRemote({
            syncRecordsDeletedAfter: databaseInfo.lastDeletedSyncAt && DateTime.fromISO(databaseInfo.lastDeletedSyncAt),
        });

        if (deletedRecordsRemote.length) {
            // Remove potentially saved records from the local database.
            await this.localDb.deleteLocal(
                deletedRecordsRemote.map((deletedRecord) => deletedRecord._id),
                'externalServer',
            );

            databaseInfo.lastDeletedSyncAt = getLatestTimestamp(deletedRecordsRemote, 'createdAt').toISO();

            // Save new timestamps.
            await this.localDb.setDatabaseInfo(databaseInfo);
        }

        return deletedRecordsRemote.length;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fetch & Process Deleted Records
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Full Sync for Master Data
    //****************************************************************************/
    /**
     * This method can be called by a component to sync master data (German "Stammdaten") that should
     * be kept offline on this device so that a user may create a report without an Internet connection.
     *
     * Master data includes:
     * - contact people
     * - text templates
     * - document building blocks (for letting the user sign assignment declarations)
     * - ...
     */
    public async fullSync(batchSize: number = this.batchSize): Promise<FullSyncResult> {
        /**
         * Avoid concurrent full syncs.
         * Reason:
         * After importing new contacts, our contact list unregisters its CRUD listeners, then waits for a full sync, then registers them again.
         * If two full syncs were triggered, the second one could now push all its records into the view through the CRUD listeners. Potentially thousands, breaking the view.
         */
        if (this.fullSyncInProgress) {
            console.log(`[${this.serviceName}] Full sync already in progress.`);
            return {
                numberOfPulledRecords: 0,
                numberOfPushedRecords: 0,
            };
        }

        const databaseInfo: DatabaseInfo = await this.localDb.getDatabaseInfo();
        let mostRecentUpdatedAt: DateTime;
        let numberOfPulledRecords: number;
        this.fullSyncInProgress = true;

        // If there has been a full sync for this service before, query only the records that have been updated since.
        if (databaseInfo.lastFullSyncAt) {
            const fullPullResult: FullPullResult = await this.recurrentFullPull(
                DateTime.fromISO(databaseInfo.lastFullSyncAt),
                batchSize,
            );
            mostRecentUpdatedAt = fullPullResult.mostRecentUpdatedAt;
            numberOfPulledRecords = fullPullResult.numberOfPulledRecords;
        } else {
            const fullPullResult: FullPullResult = await this.initialFullPull(batchSize);
            mostRecentUpdatedAt = fullPullResult.mostRecentUpdatedAt;
            /**
             * If this is the initial pull, no other records exist locally. So this device only needs to keep
             * track of tombstone records that were deleted after this moment.
             */
            if (mostRecentUpdatedAt) {
                databaseInfo.lastDeletedSyncAt = fullPullResult.mostRecentUpdatedAt.toISO();
            }

            numberOfPulledRecords = fullPullResult.numberOfPulledRecords;
        }

        this.fullSyncInProgress = false;

        const numberOfPushedRecords: number = await this.pushToServer();

        /**
         * Set lastFullSyncAt to the most up-to-date server record.
         * - Using the server time prevents errors caused by an offset of the local computer time.
         *
         * Only refresh lastFullSyncAt if there are any new records.
         * - Otherwise the lastFullSyncAt would be set to undefined if the client is up-to-date.
         */
        if (mostRecentUpdatedAt) {
            databaseInfo.lastFullSyncAt = mostRecentUpdatedAt.toISO();
            await this.localDb.setDatabaseInfo(databaseInfo);
        }

        return {
            numberOfPulledRecords,
            numberOfPushedRecords,
        };
    }

    /**
     * This method may be used to sync all records of this service's data type to this device.
     *
     * Use this method for master data that must be kept offline completely in case the user wants to create
     * a report without an internet connection.
     */
    private async initialFullPull(batchSize: number = this.batchSize): Promise<FullPullResult> {
        let numberOfPulledRecords = 0;
        let numberOfRecordsInLastFind = 0;
        let skip = 0;

        // The default is null so that records' timestamps are always larger and take precedence over this default value.
        let mostRecentUpdatedAt: DateTime = null;

        /**
         * Query all records. The last iteration occurs when the batch size is larger than the found number of records.
         */
        do {
            /**
             * Do not use pullFromService for this but instead save the records immediately. That has
             * the advantage that ony one instead of two queries need to be sent to the server:
             *   1st query: for only the _id and the _documentVersion
             *   2nd query: for the actual content once pullFromServer finds out that the record is new
             */
            const records: DataType[] = await this.httpSync.findRemote({
                $skip: skip,
                $limit: batchSize,
            });

            await Promise.all([
                this.localDb.createLocal(records, 'externalServer'),
                this.localDb.putServerShadow(records),
            ]);

            const mostRecentUpdatedAtInRecords: DateTime = getLatestTimestamp(records);
            if (mostRecentUpdatedAtInRecords > mostRecentUpdatedAt) {
                mostRecentUpdatedAt = mostRecentUpdatedAtInRecords;
            }

            numberOfRecordsInLastFind = records.length;

            numberOfPulledRecords += numberOfRecordsInLastFind;
            skip += numberOfRecordsInLastFind;

            /**
             * Wait a certain number of milliseconds between server requests to allow the client browser to process UI events. If the client is just busy pulling
             * from the server, weaker clients fail to allocate CPU capacity for rendering the user interface fast. That makes autoiXpert feel slow.
             */
            await sleep(this.millisecondsBetweenSyncRequests);
        } while (numberOfRecordsInLastFind === batchSize);

        return {
            numberOfPulledRecords,
            mostRecentUpdatedAt,
        };
    }

    /**
     * Sync only the records that were updated since the given time stamp to this device.
     *
     * Use this method for master data that must be kept offline completely in case the user wants to create
     * a report without an internet connection. This method should be executed only if the initial full sync
     * ran before or the data on this device will be incomplete.
     */
    private async recurrentFullPull(
        syncRecordsChangedAfter: DateTime,
        batchSize: number = this.batchSize,
    ): Promise<FullPullResult> {
        let numberOfPulledRecords = 0;
        let numberOfRecordsInLastFind = 0;
        let skip = 0;

        // The default is null so that records' timestamps are always larger and take precedence over this default value.
        let mostRecentUpdatedAt: DateTime = null;

        /**
         * Query all records that were updated after the given timestamp. The last iteration occurs when the batch size is larger than the found number of records.
         */
        do {
            const recordShells: RecordShell[] = await this.httpSync.findRemote({
                $skip: skip,
                $limit: batchSize,
                $select: ['_id', '_documentVersion'],
                updatedAt: {
                    $gt: syncRecordsChangedAfter.toISO(),
                },
            });

            const pullResult = await this.pullFromServer(recordShells);
            const records: DataType[] = [...pullResult.createdRecords.values(), ...pullResult.updatedRecords.values()];

            const mostRecentUpdatedAtInRecords: DateTime = getLatestTimestamp(records);
            if (mostRecentUpdatedAtInRecords > mostRecentUpdatedAt) {
                mostRecentUpdatedAt = mostRecentUpdatedAtInRecords;
            }

            numberOfRecordsInLastFind = recordShells.length;

            numberOfPulledRecords += pullResult.numberOfChangedRecords;
            skip += numberOfRecordsInLastFind;

            /**
             * Wait a certain number of milliseconds between server requests to allow the client browser to process UI events. If the client is just busy pulling
             * from the server, weaker clients fail to allocate CPU capacity for rendering the user interface fast. That makes autoiXpert feel slow.
             */
            await sleep(this.millisecondsBetweenSyncRequests);
        } while (numberOfRecordsInLastFind === batchSize);

        return {
            numberOfPulledRecords,
            mostRecentUpdatedAt,
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Full Sync for Master Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invalidate Cache / Remove Data
    //****************************************************************************/
    public async hasOutstandingSyncs(): Promise<boolean> {
        return !!(await this.localDb.getNumberOfOutstandingSyncs());
    }

    /**
     * Used for clearing all object stores when logging out.
     */
    public async clearDatabase() {
        // Clear object stores instead of dropping the entire database, because deleting a database closes all connections to it.
        // It's very complex to check before every call to the DB if the connection is still open.
        await this.localDb.clearObjectStores();

        // If there were any records with sync issues, clearing the object stores removed the underlying invalid records, so remove the sync issues flags.
        this.recordIdsWithSyncIssues.clear();
        // Clear any associated sync issues.
        this.syncIssueNotificationService.clearIssuesForService({ databaseService: this });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invalidate Cache / Remove Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Database Size
    //****************************************************************************/
    /**
     * Used in storage usage panel component.
     */
    public async getDatabaseSize() {
        return this.localDb.getDatabaseSize();
    }

    /**
     * Used through StorageSpaceManager.registerService(this).
     */
    public async ejectData(lastAccessedBefore?: DateTime): Promise<void> {
        await this.localDb.ejectData(lastAccessedBefore);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Database Size
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    protected linkIndexedDbEvents() {
        //*****************************************************************************
        //  Local Create, Update, Delete Events
        //****************************************************************************/
        this.createdFromExternalServerOrLocalBroadcast$ = merge(
            this.localDb.createdFromExternalServer$,
            this.localDb.createdFromLocalBroadcast$,
        ).pipe(
            filter((createdRecord) => {
                if (!createdRecord) {
                    /**
                     * This might be the root cause for the JIRA issue https://autoixpert.atlassian.net/browse/AX-4419.
                     */
                    captureException(
                        new AxError({
                            code: 'NO_CREATED_RECORD_EMITTED_FROM_EXTERNAL_SERVER_OR_LOCAL_BROADCAST',
                            message: 'The local database emitted a create event without a created record.',
                            data: {
                                createdRecord,
                            },
                        }),
                    );
                }

                return !!createdRecord;
            }),
        );

        this.createdFromLocalUserInThisTab$ = this.localDb.createdFromLocalUserInThisTab$;
        this.createdInLocalDatabase$ = merge(
            this.createdFromExternalServerOrLocalBroadcast$,
            this.createdFromLocalUserInThisTab$,
        );

        this.patchedFromExternalServerOrLocalBroadcast$ = merge(
            this.localDb.patchedFromExternalServer$,
            this.localDb.patchedFromLocalBroadcast$,
        ).pipe(
            filter(({ patchedRecord }) => {
                if (!patchedRecord) {
                    /** This might be the root cause for the JIRA Issue https://autoixpert.atlassian.net/browse/AX-4419 */
                    captureException(
                        new AxError({
                            code: 'NO_PATCHED_RECORD_EMITTED_FROM_EXTERNAL_SERVER_OR_LOCAL_BROADCAST',
                            message: 'The local database emitted a patched event without a patched record.',
                            data: {
                                patchedRecord,
                            },
                        }),
                    );
                }

                return !!patchedRecord;
            }),
        );

        this.patchedFromLocalUserInThisTab$ = this.localDb.patchedFromLocalUserInThisTab$;
        this.patchedInLocalDatabase$ = merge(
            this.patchedFromExternalServerOrLocalBroadcast$,
            this.patchedFromLocalUserInThisTab$,
        );

        this.deletedInLocalDatabase$ = merge(
            this.localDb.deletedFromExternalServer$,
            this.localDb.deletedFromLocalBroadcast$,
            this.localDb.deletedFromLocalUserInThisTab$,
        );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Local Create, Update, Delete Events
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Debugging
    //****************************************************************************/
    private generateTimerLabel(methodName: string): string {
        const uniqueId: string = `${Math.round(Math.random() * 1_000)
            .toString()
            .padStart(3)}`;
        return `[${uniqueId}] ${methodName} - ${this.serviceName}`;
    }

    /**
     * Log the amount of time passed and the number of records found for a record query. Used to measure performance of IndexedDB.
     * @param startTime
     * @param timerLabel
     * @param numberOfRecords
     * @private
     */
    private logTime(startTime: number, timerLabel: string, numberOfRecords: number): void {
        // Log to console if debugging is enabled.
        if (this.isDebuggingEnabled()) {
            const passedDuration: number = Math.round(performance.now() - startTime);
            console.log(`${timerLabel} ${passedDuration}ms - ${numberOfRecords} records`);
        }
    }

    private isDebuggingEnabled(): boolean {
        return (
            (window as any).offlineSyncDebugging === '*' ||
            (window as any).offlineSyncDebugging?.includes?.(this.serviceName)
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Debugging
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface RecordShell {
    _id: DataTypeBase['_id'];
    _documentVersion: DataTypeBase['_documentVersion'];
    // The pagination token (searchSequenceToken from Atlas Search is optional)
    paginationToken?: string;
}

export interface PullFromServerResult<DataType extends DataTypeBase> {
    createdRecords: Map<DataType['_id'], DataType>;
    updatedRecords: Map<DataType['_id'], DataType>;
    unchangedRecords: Map<DataType['_id'], DataType>;
    conflictingChanges: MergeResult<DataType>[];
    numberOfChangedRecords: number;
}

export interface FullPullResult {
    numberOfPulledRecords: number;
    mostRecentUpdatedAt: DateTime;
}

export interface FullSyncResult {
    numberOfPulledRecords: number;
    numberOfPushedRecords: number;
}

function isPropertyBeingCleared(propertyPath: string, objectDifferences: ObjectDifference[]): boolean {
    // Property must exist in the payload.
    const matchingObjectDifference = objectDifferences.find(
        (objectDifference) => objectDifference.key === propertyPath,
    );
    return (
        !!matchingObjectDifference &&
        // Value must be null, empty string or empty array.
        (matchingObjectDifference.newValue === null ||
            matchingObjectDifference.newValue === '' ||
            (Array.isArray(matchingObjectDifference.newValue) && matchingObjectDifference.newValue.length === 0))
    );
}

<!-- Example invoice dropdown-->
<div
    [matMenuTriggerFor]="exampleInvoiceDropdown"
    #exampleInvoiceDropdownTrigger="matMenuTrigger"
    [class]="['example-invoice-dropdown-trigger', theme]"
    (menuOpened)="initializeOverlay()">
    <!-- Icon -->
    <div class="invoice-preview-icon-container" [style.margin-right.px]="0">
        <mat-icon>euro_symbol</mat-icon>
    </div>

    <div class="example-invoice-dropdown-info-container">
        <!-- Invoice number -->
        <div
            *ngIf="getInvoiceNumberWithFallback(invoice) as invoiceNumber"
            class="example-invoice-dropdown-trigger-title"
            [matTooltip]="invoiceNumber">
            {{ invoiceNumber }}
        </div>

        <!-- Created at -->
        <div *ngIf="invoice?.createdAt" class="example-invoice-dropdown-trigger-id label">
            <span matTooltip="Erstellt am">
                {{ invoice?.createdAt | moment: 'DD.MM.YY' }}
            </span>
        </div>
    </div>

    <!-- Dropdown indicator -->
    <div class="example-invoice-dropdown-trigger-dropdown-indicator">
        <mat-icon>arrow_drop_down</mat-icon>
    </div>

    <!-- Remove button -->
    <mat-icon
        *ngIf="!!invoice"
        class="toolbar-icon"
        [style.margin-left.px]="-10"
        (click)="handleRemoveInvoiceClick($event)"
        matTooltip="Rechnung entfernen">
        close
    </mat-icon>
</div>

<!-- Example invoice dropdown overlay-->
<mat-menu
    #exampleInvoiceDropdown="matMenu"
    class="example-invoice-dropdown-overlay {{ theme === 'dark' ? 'dark' : 'light' }}">
    <div class="example-invoice-dropdown-overlay-container" (click)="$event.preventDefault(); $event.stopPropagation()">
        <!-- Search input -->
        <div class="example-invoice-dropdown-search-container">
            <!-- Search icon -->
            <mat-icon>search</mat-icon>

            <!-- Search input -->
            <input #searchInput type="text" placeholder="Suchen" (input)="handleSearchInputChange($event)" />

            <!-- Search loading indicator-->
            <div class="example-invoice-dropdown-search-loading-indicator">
                <mat-spinner *ngIf="searchPending" [diameter]="15"></mat-spinner>
            </div>
        </div>

        <!-- Invoices search results list-->
        <cdk-virtual-scroll-viewport
            #virtualScroll
            [itemSize]="50"
            minBufferPx="1000"
            maxBufferPx="1000"
            [style.height.px]="filteredInvoices.length * 50"
            *ngIf="filteredInvoices?.length"
            class="example-invoice-dropdown-search-results cdk-virtual-scroll-vertical-only">
            <ng-container *cdkVirtualFor="let invoice of filteredInvoices; trackBy: trackById">
                <!-- Invoice search result -->
                <div class="invoice-preview-option" (click)="handleInvoiceClick(invoice)">
                    <div class="invoice-preview-option-inner-wrapper">
                        <div class="invoice-preview-icon-container" (click)="navigateToInvoice(invoice._id)">
                            <mat-icon>euro_symbol</mat-icon>
                        </div>

                        <div class="invoice-preview-name-and-date-container">
                            <!-- Organization name -->
                            <div
                                *ngIf="getInvoiceNumberWithFallback(invoice) as invoiceNumber"
                                class="invoice-preview-name-container"
                                [matTooltip]="invoiceNumber">
                                {{ invoiceNumber }}
                            </div>

                            <!-- Creation date -->
                            <div class="label">
                                <span matTooltip="Erstellt am">
                                    {{ invoice?.createdAt | moment: 'DD.MM.YY' }}
                                </span>
                            </div>
                        </div>
                        <div class="invoice-preview-open-in-new-container">
                            <mat-icon
                                class="invoice-preview-open-in-new"
                                (click)="handleOpenInNewClick(invoice?._id, $event)"
                                matTooltip="In neuem Tab öffnen">
                                open_in_new
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>

        <!-- Placeholder -->
        <div *ngIf="filteredInvoices.length === 0" class="example-invoice-dropdown-search-results-placeholder">
            {{ hasValidSearchTerm(searchTerm) && !searchPending ? 'Keine Ergebnisse' : 'Nach Rechnungen suchen' }}
        </div>
    </div>
</mat-menu>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ClaimantSignaturesFormDraftService {
    constructor(private readonly httpClient: HttpClient) {}

    public async delete({ reportId }: { reportId: string }): Promise<void> {
        await this.httpClient.delete<void>(`/api/v0/claimantSignatureForm/drafts/${reportId}`).toPromise();
    }
}

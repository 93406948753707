import { removeLineBreaks } from './enable-line-breaks';
import { PlaceholderValues } from './get-placeholder-values';

/**
 * This function changes the content of its parameter templatePlaceholderValues.
 * It's required for converting DOCX placeholders to HTML so that the frontend may display them in the browser.
 * @param templatePlaceholderValues
 */
export function removeLineBreaksFromDocxPlaceholders(templatePlaceholderValues: PlaceholderValues): void {
    if (templatePlaceholderValues.Fahrzeug) {
        templatePlaceholderValues.Fahrzeug.Bemerkungen = removeLineBreaks(
            templatePlaceholderValues.Fahrzeug.Bemerkungen,
        );
        templatePlaceholderValues.Fahrzeug.Vorschäden = removeLineBreaks(templatePlaceholderValues.Fahrzeug.Vorschäden);
        templatePlaceholderValues.Fahrzeug.Altschäden = removeLineBreaks(templatePlaceholderValues.Fahrzeug.Altschäden);
    }

    if (templatePlaceholderValues.Sachverständiger) {
        templatePlaceholderValues.Sachverständiger.Brieffenster = removeLineBreaks(
            templatePlaceholderValues.Sachverständiger.Brieffenster,
        );
        templatePlaceholderValues.Sachverständiger.NameKomplett = removeLineBreaks(
            templatePlaceholderValues.Sachverständiger.NameKomplett,
        );
    }
    if (templatePlaceholderValues.Anspruchsteller) {
        templatePlaceholderValues.Anspruchsteller.Brieffenster = removeLineBreaks(
            templatePlaceholderValues.Anspruchsteller.Brieffenster,
        );
        templatePlaceholderValues.Anspruchsteller.NameKomplett = removeLineBreaks(
            templatePlaceholderValues.Anspruchsteller.NameKomplett,
        );

        if (templatePlaceholderValues.Anspruchsteller.Anwalt) {
            templatePlaceholderValues.Anspruchsteller.Anwalt.Brieffenster = removeLineBreaks(
                templatePlaceholderValues.Anspruchsteller.Anwalt.Brieffenster,
            );
            templatePlaceholderValues.Anspruchsteller.Anwalt.NameKomplett = removeLineBreaks(
                templatePlaceholderValues.Anspruchsteller.Anwalt.NameKomplett,
            );
        }
    }
    if (templatePlaceholderValues.Fahrzeughalter) {
        templatePlaceholderValues.Fahrzeughalter.Brieffenster = removeLineBreaks(
            templatePlaceholderValues.Fahrzeughalter.Brieffenster,
        );
        templatePlaceholderValues.Fahrzeughalter.NameKomplett = removeLineBreaks(
            templatePlaceholderValues.Fahrzeughalter.NameKomplett,
        );
    }
    if (templatePlaceholderValues.Unfallgegner) {
        templatePlaceholderValues.Unfallgegner.Brieffenster = removeLineBreaks(
            templatePlaceholderValues.Unfallgegner.Brieffenster,
        );
        templatePlaceholderValues.Unfallgegner.NameKomplett = removeLineBreaks(
            templatePlaceholderValues.Unfallgegner.NameKomplett,
        );

        if (templatePlaceholderValues.Unfallgegner.Versicherung) {
            templatePlaceholderValues.Unfallgegner.Versicherung.Brieffenster = removeLineBreaks(
                templatePlaceholderValues.Unfallgegner.Versicherung.Brieffenster,
            );
            templatePlaceholderValues.Unfallgegner.Versicherung.NameKomplett = removeLineBreaks(
                templatePlaceholderValues.Unfallgegner.Versicherung.NameKomplett,
            );
        }
    }
    if (templatePlaceholderValues.FahrzeughalterUnfallgegner) {
        templatePlaceholderValues.FahrzeughalterUnfallgegner.Brieffenster = removeLineBreaks(
            templatePlaceholderValues.FahrzeughalterUnfallgegner.Brieffenster,
        );
        templatePlaceholderValues.FahrzeughalterUnfallgegner.NameKomplett = removeLineBreaks(
            templatePlaceholderValues.FahrzeughalterUnfallgegner.NameKomplett,
        );
    }
    if (templatePlaceholderValues.Werkstatt) {
        templatePlaceholderValues.Werkstatt.Brieffenster = removeLineBreaks(
            templatePlaceholderValues.Werkstatt.Brieffenster,
        );
        templatePlaceholderValues.Werkstatt.NameKomplett = removeLineBreaks(
            templatePlaceholderValues.Werkstatt.NameKomplett,
        );
    }
    if (templatePlaceholderValues.FactoringProvider) {
        templatePlaceholderValues.FactoringProvider.Brieffenster = removeLineBreaks(
            templatePlaceholderValues.FactoringProvider.Brieffenster,
        );
        templatePlaceholderValues.FactoringProvider.NameKomplett = removeLineBreaks(
            templatePlaceholderValues.FactoringProvider.NameKomplett,
        );
    }

    if (templatePlaceholderValues.Rechnung?.Empfänger) {
        templatePlaceholderValues.Rechnung.Empfänger.Brieffenster = removeLineBreaks(
            templatePlaceholderValues.Rechnung.Empfänger.Brieffenster,
        );
        templatePlaceholderValues.Rechnung.Empfänger.NameKomplett = removeLineBreaks(
            templatePlaceholderValues.Rechnung.Empfänger.NameKomplett,
        );
    }
}

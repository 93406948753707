import { Component, HostListener, OnInit } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { generateId } from '@autoixpert/lib/generate-id';
import { mergeRecord } from '@autoixpert/lib/server-sync/merge-record';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { User } from '@autoixpert/models/user/user';
import { isSmallScreen } from '../../shared/libraries/is-small-screen';
import { trackById } from '../../shared/libraries/track-by-id';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { ContactExportService } from '../../shared/services/contact-export.service';
import { ContactPersonService } from '../../shared/services/contact-person.service';
import { DownloadService } from '../../shared/services/download.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { FeathersQuery } from '../../shared/types/feathers-query';

@Component({
    selector: 'contact-list',
    templateUrl: 'contact-list.component.html',
    styleUrls: ['contact-list.component.scss'],
})
export class ContactListComponent implements OnInit {
    constructor(
        public contactPersonService: ContactPersonService,
        public userPreferences: UserPreferencesService,
        public screenTitleService: ScreenTitleService,
        private loggedInUserService: LoggedInUserService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private tutorialStateService: TutorialStateService,
        private contactExportService: ContactExportService,
        private downloadService: DownloadService,
        private networkStatusService: NetworkStatusService,
    ) {}

    contactPeople: ContactPerson[] = [];
    filteredContactPeople: ContactPerson[] = [];
    selectedContactPerson: ContactPerson;
    contactPersonToBeEdited: ContactPerson;
    numberOfSyncedContactPeople: number = 0;
    atlasSearchMatches = new Set<ContactPerson['_id']>();

    // Load more contact people
    private lastContactPaginationTokenFromServer: string = null;
    private numberOfLoadedContacts: number = 0;
    loadMoreContacts$$ = new Subject<LoadMoreContactsPayload>();
    isLoadMoreContactsPending = false;

    // Will be calculated depending on screen height. Fills the entire screen with records.
    private numberOfRecordsForInitialRequest: number;
    public recordsLimitReached: boolean = false;

    contactPersonEditorShown: boolean = false;
    garageFeesEditorShown: boolean = false;
    importContactsDialogShown: boolean = false;

    user: User;

    private subscriptions: Subscription[] = [];

    public screenHeight;
    public tableRowHeight = 61;

    searchTerm: string = '';
    searchTerm$: Subject<string> = new Subject<string>();
    private searchServerSubscription: Subscription;

    contactExportPending: boolean;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        // Update the user in case it was updated in a different tab.
        this.subscriptions.push(this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)));
        this.screenHeight = window.screen.availHeight;
        this.subscribeToLoadMoreContacts();
        this.setupSearch();

        this.initialLoadContacts();

        this.screenTitleService.setScreenTitle({ screenTitle: 'Meine Kontakte' });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  List Handling
    //****************************************************************************/
    public selectContactPerson(contactPerson: ContactPerson): void {
        this.selectedContactPerson = contactPerson;
    }

    public openImportContactsDialog(): void {
        this.importContactsDialogShown = true;
    }

    public hideContactImportDialog(): void {
        this.importContactsDialogShown = false;
    }

    public async forceReloadAllContactPeople() {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.info(
                'Nur online möglich',
                'Der Abgleich der Kontakte mit dem Server ist nur möglich, wenn du online bist. Bitte stelle eine Internetverbindung her.',
            );
            return;
        }

        this.contactPeople = [];
        this.filteredContactPeople = [];
        this.numberOfSyncedContactPeople = 0;

        await this.contactPersonService.localDb.clearObjectStores();
        await this.fullSyncContactPeople();

        // We've loaded records into the DB. Now load into the list.
        await this.resetLoadHistory();
    }

    public downloadListOfContactPeople(): void {
        this.contactExportPending = true;
        this.contactExportService.downloadListOfContactPeople().subscribe({
            next: (response) => {
                this.downloadService.downloadBlobResponseWithHeaders(response);
                this.contactExportPending = false;
            },
            error: (error) => {
                this.contactExportPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Export gescheitert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            },
        });
    }

    public trackById = trackById;

    public hasSyncIssues(recordId: ContactPerson['_id']): boolean {
        return this.contactPersonService.recordIdsWithSyncIssues.has(recordId);
    }

    public getSyncIssueTooltip(): string {
        let tooltip = 'Dieser Datensatz konnte nicht zum Server synchronisiert werden.';

        if (this.networkStatusService.isOnline()) {
            tooltip += '\n\nKlicke, um alle Datensätze neu zu synchronisieren.';
        } else {
            tooltip +=
                '\n\nStelle eine Verbindung mit dem Internet her und klicke, um alle Datensätze zu synchronisieren.';
        }

        return tooltip;
    }

    public async triggerSync() {
        await this.contactPersonService.pushToServer();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END List Handling
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Search, Filter & Sort
    //****************************************************************************/

    public selectSortStrategy(strategy: 'lastName' | 'organization'): void {
        this.userPreferences.sortContactListBy = strategy;
        this.resetLoadHistory();
    }

    public toggleSortDirection(): void {
        this.userPreferences.sortContactListDescending = !this.userPreferences.sortContactListDescending;
        this.resetLoadHistory();
    }

    public selectQuickFilter(quickFilter: ContactPerson['organizationType']) {
        this.userPreferences.contactListQuickFilter = quickFilter;
        this.filterContacts();
        this.resetLoadHistory();
    }

    /**
     * Find contacts that are matched by all search words supplied.
     * If the search term is empty, reset the searchResult to all contacts.
     */
    public filterContacts() {
        this.filteredContactPeople = [...this.contactPeople];

        this.applySearchFilter();
        this.applyQuickFilter();
    }

    private applyQuickFilter(): void {
        if (!this.userPreferences.contactListQuickFilter) {
            return;
        }
        this.filteredContactPeople = this.filteredContactPeople.filter(
            (contact) => contact.organizationType === this.userPreferences.contactListQuickFilter,
        );
    }

    private applySearchFilter(): void {
        if (!this.searchTerm) {
            return;
        }

        const searchTerms = this.searchTerm.toLowerCase().split(' ');

        this.filteredContactPeople = this.filteredContactPeople.filter((contact) => {
            if (this.atlasSearchMatches.has(contact._id)) {
                return true;
            }
            const propertiesToBeSearched: string[] = [
                contact.lastName,
                contact.firstName,
                contact.organization,
                contact.city,
                contact.zip,
                contact.phone,
                contact.email,
            ];

            return searchTerms.every((searchTerm) => {
                return propertiesToBeSearched.some((propertyToBeSearched) => {
                    if (!propertyToBeSearched) {
                        return false;
                    }
                    return propertyToBeSearched.toLowerCase().includes(searchTerm);
                });
            });
        });
    }

    public updateSearchTerm$(): void {
        this.searchTerm$.next(this.searchTerm);
    }

    /**
     * Every time the search term changes, trigger a server search.
     *
     * Subscribe to the stream of search terms and trigger a search on the server.
     * Searches are only performed one second after the user stopped typing, and if the
     * search term is three letters or longer.
     */
    public setupSearch(): void {
        this.searchServerSubscription = this.searchTerm$
            .pipe(
                tap((searchTerm) => {
                    if (!searchTerm) {
                        this.resetLoadHistory();
                    }
                }),
                // Only search for more than three characters.
                filter((searchTerm) => {
                    if (!searchTerm || typeof searchTerm !== 'string') return;

                    // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
                    const searchTermParts = searchTerm
                        .trim()
                        .split(' ')
                        .filter((searchTerm) => !!searchTerm.trim());
                    return searchTermParts.some((searchTermPart) => searchTermPart.length >= 3);
                }),
                debounceTime(250),
            )
            .subscribe({
                next: () => {
                    this.resetLoadHistory();
                },
            });
    }

    private getFilterAndSortQuery(): FeathersQuery {
        const query: {
            organizationType?: ContactPerson['organizationType'];
            $sort?: Record<string, 1 | -1>;
        } = {};

        if (this.userPreferences.contactListQuickFilter) {
            query.organizationType = this.userPreferences.contactListQuickFilter;
        }

        /**
         * Sort
         */
        const sortOrder = this.userPreferences.sortContactListDescending ? -1 : 1;
        switch (this.userPreferences.sortContactListBy) {
            case 'lastName':
                query.$sort = {
                    lastName: sortOrder,
                    firstName: sortOrder,
                    organization: sortOrder,
                };
                break;
            case 'organization':
                query.$sort = {
                    organization: sortOrder,
                    lastName: sortOrder,
                    firstName: sortOrder,
                };
                break;
        }

        return query;
    }

    public async resetLoadHistory(): Promise<void> {
        this.recordsLimitReached = false;

        // When searching, don't skip any records.
        this.lastContactPaginationTokenFromServer = null;
        this.numberOfLoadedContacts = 0;

        this.atlasSearchMatches.clear();
        return this.triggerLoadMoreContacts();
    }

    /**
     * Returns all reports that don't yet exist in the collection they would go into.
     */
    private filterOutExistingContactPeople(contactPeople: ContactPerson[]): ContactPerson[] {
        const existingContactPeople = [...this.contactPeople];

        return contactPeople.filter((contactPerson) => {
            // Only include new records without a matching ID
            return !existingContactPeople.find(
                (existingContactPerson) => existingContactPerson._id === contactPerson._id,
            );
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Search, Filter & Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CRUD Contact People
    //****************************************************************************/

    public editNewContactPerson(): void {
        this.contactPersonToBeEdited = new ContactPerson({
            _id: null,
            organizationType: 'claimant',
        });
        this.showContactPersonEditor();
    }

    public copyContactPerson(contactPerson: ContactPerson): void {
        this.editNewContactPerson();

        const copyOfContactPerson: ContactPerson = JSON.parse(JSON.stringify(contactPerson));
        delete copyOfContactPerson._id;
        delete copyOfContactPerson.createdBy;
        delete copyOfContactPerson.createdAt;

        Object.assign(this.contactPersonToBeEdited, copyOfContactPerson);
    }

    public editContactPerson(contactPerson: ContactPerson): void {
        this.contactPersonToBeEdited = contactPerson;
        this.showContactPersonEditor();
    }

    public async deleteContactPerson(contactPerson: ContactPerson): Promise<void> {
        await this.contactPersonService.delete(contactPerson._id);

        const index: number = this.contactPeople.indexOf(contactPerson);
        if (index > -1) {
            this.contactPeople.splice(index, 1);
        }

        this.filterContacts();

        const contactNameParts: string[] = [];

        if (contactPerson.organization) {
            contactNameParts.push(contactPerson.organization);
        }

        if (contactPerson.firstName || contactPerson.lastName) {
            contactNameParts.push(`${contactPerson.firstName || ''} ${contactPerson.lastName || ''}`.trim());
        }

        const contactName: string = contactNameParts.join(' - ');

        const toast = this.toastService.info(`Kontakt ${contactName} gelöscht`, 'Zum Wiederherstellen hier klicken', {
            showProgressBar: true,
            timeOut: 10000,
            preventDuplicates: true,
        }); //RestorationToast
        toast.click.subscribe(async () => await this.contactPersonService.undelete(contactPerson));
    }

    public showContactPersonEditor(): void {
        this.contactPersonEditorShown = true;
    }

    public hideContactPersonEditor(): void {
        this.contactPersonEditorShown = false;
    }

    public async handleContactPersonChange(contactPerson: ContactPerson): Promise<void> {
        if (!contactPerson._id) {
            contactPerson._id = generateId();
            await this.contactPersonService.create(contactPerson);
            this.tutorialStateService.markUserTutorialStepComplete('newContactCreatedManually');
        } else {
            mergeRecord<ContactPerson>(this.contactPersonToBeEdited, contactPerson);
            await this.contactPersonService.put(contactPerson);
        }

        // In case a contact type has been changed and a filter is active.
        this.filterContacts();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Contact People
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Call while user is on a mobile device
    //****************************************************************************/
    /**
     * If the contact person only has one number, call that number. If the person has
     * two numbers, offer a selection through a mat menu.
     * @param contactPerson
     * @param numberSelectionMenuTrigger
     */
    public callOrSelectPhoneNumber(contactPerson: ContactPerson, numberSelectionMenuTrigger: MatMenuTrigger) {
        // If only one of both is defined, ...
        if ((contactPerson.phone && !contactPerson.phone2) || (!contactPerson.phone && contactPerson.phone2)) {
            // ...call the one that's defined.
            const phoneNumber: string = contactPerson.phone || contactPerson.phone2;
            window.location.href = `tel:${phoneNumber}`;

            // Close the menu right away because no selection is necessary.
            numberSelectionMenuTrigger.closeMenu();
            return;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Call while user is on a mobile device
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Garage Fees
    //****************************************************************************/
    public editGarageFees(contactPerson: ContactPerson): void {
        this.selectContactPerson(contactPerson);
        this.contactPersonToBeEdited = contactPerson;
        this.showGarageFeesEditor();
    }

    public showGarageFeesEditor(): void {
        this.garageFeesEditorShown = true;
    }

    public hideGarageFeesEditor(): void {
        this.garageFeesEditorShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage Fees
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/

    public initialLoadContacts() {
        this.numberOfRecordsForInitialRequest = Math.round(this.screenHeight / this.tableRowHeight);

        this.loadMoreContacts$$.next({
            filterAndSortParams: this.getFilterAndSortQuery(),
            numberOfItemsToLoad: this.numberOfRecordsForInitialRequest,
        });
    }

    public triggerLoadMoreContacts() {
        this.loadMoreContacts$$.next({
            searchAfterNumberOfElements: this.numberOfLoadedContacts,
            searchAfterPaginationToken: this.lastContactPaginationTokenFromServer,
            filterAndSortParams: this.getFilterAndSortQuery(),
            searchTerm: this.searchTerm,
        });
    }

    private subscribeToLoadMoreContacts() {
        const loadMoreContactsSubscription = this.loadMoreContacts$$
            .pipe(
                // Only trigger a load if the parameters have changed.
                // This is required since the scroll observer may trigger multiple times with the same parameters.
                // It is important to have the filters and sort params in the payload to cancel the request if the user changes the filter or sort.
                // Since distinctUntilChanged may hold a reference to objects or arrays, we need to deep copy the payload in order to compare the values.
                map((payload) => JSON.parse(JSON.stringify(payload))),
                distinctUntilChanged(isEqual),
                switchMap(async (payload) => {
                    const numberOfReportsToLoad = payload.numberOfItemsToLoad || 15;
                    const isInitialLoad = !this.numberOfLoadedContacts;
                    let loadedContacts: ContactPerson[] = [];
                    this.isLoadMoreContactsPending = true;

                    /**
                     * Load reports from server (if online) or from IndexedDB (if offline).
                     */
                    try {
                        const { records, lastPaginationToken } =
                            await this.contactPersonService.getContactsFromServerOrIndexedDB({
                                searchTerm: payload.searchTerm,
                                searchAfterPaginationToken: payload.searchAfterPaginationToken,
                                skip: payload.searchAfterNumberOfElements || 0,
                                limit: numberOfReportsToLoad,
                                query: payload.filterAndSortParams,
                            });
                        loadedContacts = records;

                        // Update pagination of component
                        this.lastContactPaginationTokenFromServer = lastPaginationToken;
                        this.numberOfLoadedContacts += loadedContacts.length;
                    } catch (error) {
                        this.apiErrorService.handleAndRethrow({
                            axError: error,
                            handlers: {},
                            defaultHandler: {
                                title: 'Kontakte nicht geladen',
                                body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                            },
                        });
                    } finally {
                        this.isLoadMoreContactsPending = false;
                    }
                    return { loadedContacts, isInitialLoad, numberOfReportsToLoad, searchTerm: payload.searchTerm };
                }),
            )
            .subscribe(({ loadedContacts, isInitialLoad, numberOfReportsToLoad, searchTerm }) => {
                // If there are less reports than the limit, we have reached the end of the list.
                this.recordsLimitReached = loadedContacts.length < numberOfReportsToLoad;

                if (loadedContacts.length === 0 && !searchTerm) {
                    return;
                }

                // If the request was for a search term, add the contactPerson ids to the search matches.
                if (searchTerm) {
                    loadedContacts.forEach((contactPerson) => {
                        this.atlasSearchMatches.add(contactPerson._id);
                    });
                }

                // If this is the first load, update the in-memory cache with the loaded reports.
                if (isInitialLoad) {
                    this.contactPeople = loadedContacts;
                    this.filterContacts();
                } else {
                    // Append all new reports to the list.
                    const contactPeopleWithoutDuplicates: ContactPerson[] =
                        this.filterOutExistingContactPeople(loadedContacts);
                    if (contactPeopleWithoutDuplicates.length > 0) {
                        this.contactPeople.push(...contactPeopleWithoutDuplicates);
                        this.filterContacts();
                    }

                    // The loaded reports are already present, e.g. loaded from indexed db before.
                    // Trigger the next load to get the next reports.
                    else {
                        // Do not automatically load since this could lead to an infinite loop.
                        // Atlas search does not like infinite loops.
                    }
                }
            });

        this.subscriptions.push(loadMoreContactsSubscription);
    }

    /**
     * Sync all contact people to which this user has access.
     */
    private async fullSyncContactPeople(): Promise<void> {
        /**
         * No query parameters in the find method sync all contact people to this device.
         * The contact person service is smart enough to detect which records should be read from the server
         * and which records are present on this device already.
         */
        if (this.networkStatusService.isOnline()) {
            try {
                await this.contactPersonService.fullSync();
            } catch (error) {
                this.toastService.warn(
                    'Sync fehlgeschlagen',
                    'Die neusten Kontakte konnten nicht vom Server geladen werden.<br><br>Du kannst aber mit deinen aktuellen Kontakten weiterarbeiten.',
                );
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Mobile Check
    //****************************************************************************/
    public isMobile(): boolean {
        return isSmallScreen();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Mobile Check
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public createNewContactPersonOnCtrlN(event) {
        // Make sure the user is not inside an input
        if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
            return;
        }

        // cover Windows an Mac machines
        if (event.key === 'n') {
            event.preventDefault();
            this.editNewContactPerson();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    public console = console;
    public navigator = navigator;

    ngOnDestroy() {
        this.searchServerSubscription.unsubscribe();
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}

/**
 * Payload for the loadMoreContacts$ observable.
 */
type LoadMoreContactsPayload = {
    searchTerm?: string;
    filterAndSortParams: FeathersQuery;
    numberOfItemsToLoad?: number;
    searchAfterPaginationToken?: string;
    searchAfterNumberOfElements?: number;
};

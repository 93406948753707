<div id="fees-container" *ngIf="report">
    <div id="damage-costs-and-official-fee-sets-container">
        <!--***********************************************
        ** Base for Fee Calculation
        ************************************************-->
        <div id="damage-costs-container" class="card" [matTooltip]="getTooltipForBaseForFeeCalculation()">
            <ng-container>
                <div id="damage-costs-label" class="label">Honorargrundlage</div>
                <div id="damage-costs-value" [ngSwitch]="report.type">
                    <ng-container *ngSwitchDefault>
                        <mat-icon
                            class="warning margin-top-5"
                            *ngIf="!report.damageCalculation.damageType"
                            matTooltip="Keine Schadenklasse im Reiter Kalkulation ausgewählt.{{
                                '\n\n'
                            }}Ob es sich um einen Total- oder Reparaturschaden handelt, ist erforderlich für die Berechnung der Honorargrundlage.">
                            warning
                        </mat-icon>
                        <ng-container *ngIf="report.damageCalculation.damageType">
                            {{ baseForFeeCalculation | number: '1.2-2' }} €
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'valuation'">
                        {{ report.valuation.vehicleValueNet || 0 | number: '1.2-2' }} €
                    </ng-container>
                    <ng-container *ngSwitchCase="'invoiceAudit'">Keine</ng-container>
                    <ng-container *ngSwitchCase="'leaseReturn'">keine</ng-container>
                    <ng-container *ngSwitchCase="'usedVehicleCheck'">keine</ng-container>
                    <ng-container *ngSwitchCase="'oldtimerValuationSmall'">
                        {{
                            report.valuation.vehicleValueGross || report.valuation.secondVehicleValueGross || 0
                                | number: '1.2-2'
                        }}
                        €
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <!--***********************************************
        ** END Base for Fee Calculation
        ************************************************-->
        <!--***********************************************
        ** Fee Sets
        ************************************************-->
        <div id="official-fee-sets-container" class="card" *ngIf="showFeeSet(report)">
            <div id="fee-table-provider" [matMenuTriggerFor]="selectFeeSetDropdown">
                <h2 id="fee-table-title" [ngSwitch]="report.feeCalculation.selectedFeeTable">
                    <!-- Fee Table Logo -->
                    <div class="fee-table-name-container">
                        <div class="fee-table-name">
                            <img
                                class="fee-table-logo"
                                *ngSwitchCase="'BVSK'"
                                src="/assets/images/logos/bvsk-logo.png"
                                [class]="report.feeCalculation.selectedFeeTable" />
                            <img
                                class="fee-table-logo"
                                *ngSwitchCase="'HUK'"
                                src="/assets/images/logos/huk-logo.png"
                                [class]="report.feeCalculation.selectedFeeTable" />
                            <img
                                class="fee-table-logo"
                                *ngSwitchCase="'VKS'"
                                src="/assets/images/logos/vks-logo.png"
                                [class]="report.feeCalculation.selectedFeeTable" />
                            <img
                                class="fee-table-logo"
                                *ngSwitchCase="'CGF'"
                                matTooltip="Caravaning Gutachter Fachverband e.V."
                                src="/assets/images/logos/cgf-logo.png"
                                [class]="report.feeCalculation.selectedFeeTable" />
                            <span *ngSwitchCase="'custom'" id="custom-fee-table-title">{{ customFeeSet?.title }}</span>
                            <span *ngSwitchDefault id="no-fee-table-title">Tabelle auswählen</span>
                        </div>

                        <!--********** Fee Table Year **********-->
                        <div id="fee-table-year">
                            <ng-container *ngIf="report.feeCalculation.selectedFeeTable !== 'custom'">
                                <span
                                    matTooltip="Jahr der Umfrage und Datenlage. Die Veröffentlichung erfolgt oft erst im Folgejahr nach statistischer Aufarbeitung.">
                                    {{ report.feeCalculation.yearOfFeeTable }}
                                </span>

                                <!--********** Warning: Old fee table selected **********-->
                                <mat-icon
                                    *ngIf="isNewerFeeTableAvailable()"
                                    class="small-icon warning"
                                    matTooltip="Es gibt eine neuere Version dieser Honorartabelle.">
                                    warning
                                </mat-icon>
                            </ng-container>

                            <!--********** Custom Fee Table **********-->
                            <ng-container *ngIf="report.feeCalculation.selectedFeeTable === 'custom'">
                                <span *ngIf="customFeeSet?.year" matTooltip="Jahr der Honorartabelle">
                                    {{ customFeeSet.year }}
                                </span>
                                <ng-container *ngIf="!customFeeSet?.year">Jahr unbekannt</ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <mat-icon id="swap-icon">arrow_drop_down</mat-icon>
                </h2>

                <mat-menu #selectFeeSetDropdown>
                    <!--********** BVSK **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="
                            selectFeeSet({
                                feeTableName: 'BVSK',
                                yearOfFeeTable: bvskFees.year,
                            })
                        ">
                        <img class="fee-table-logo" src="/assets/images/logos/bvsk-logo_square_360.png" />
                        <span class="fee-table-list-custom-name">BVSK</span>
                        <mat-icon
                            class="fee-table-submenu-icon toolbar-icon"
                            [matMenuTriggerFor]="bvskFeeTableSubmenu"
                            (click)="$event.stopPropagation()">
                            more_vert
                        </mat-icon>
                        <mat-menu #bvskFeeTableSubmenu>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                [matTooltip]="
                                    hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                        ? 'Honorartabelle als Standard (' +
                                          translateReportType() +
                                          ') für das gesamte Team setzen.'
                                        : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                "
                                [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                                (click)="setFeeTableAsUserDefault({ feeTableName: 'BVSK' })">
                                <mat-icon class="outlined">bookmark</mat-icon>
                                Als Standard setzen
                            </a>

                            <!--********** Open Table PDF **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                href="https://www.autoixpert.de/assets/fee-tables/bvsk.pdf"
                                target="_blank"
                                matTooltip="Öffne die Tabelle als PDF-Datei in einem neuen Tab">
                                <mat-icon>open_in_new</mat-icon>
                                Tabelle ansehen
                            </a>

                            <!--============================================-->
                            <!-- Older Tables -->
                            <!--============================================-->
                            <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="olderTablesBvsk">
                                <mat-icon>table_view</mat-icon>
                                Ältere Tabellen
                            </a>

                            <mat-menu #olderTablesBvsk>
                                <a mat-menu-item (click)="selectFeeSet({ feeTableName: 'BVSK', yearOfFeeTable: 2022 })">
                                    BVSK 2022
                                </a>
                            </mat-menu>
                            <!--============================================-->
                            <!-- END Older Tables -->
                            <!--============================================-->
                        </mat-menu>
                    </a>

                    <!--********** HUK **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="selectFeeSet({ feeTableName: 'HUK', yearOfFeeTable: hukFees.year })">
                        <img class="fee-table-logo" src="/assets/images/logos/insurances/huk-coburg.png" />
                        <span class="fee-table-list-custom-name">HUK-Coburg</span>
                        <mat-icon
                            class="fee-table-submenu-icon toolbar-icon"
                            [matMenuTriggerFor]="hukFeeTableSubmenu"
                            (click)="$event.stopPropagation()">
                            more_vert
                        </mat-icon>
                        <mat-menu #hukFeeTableSubmenu>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="setFeeTableAsUserDefault({ feeTableName: 'HUK' })"
                                [matTooltip]="
                                    hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                        ? 'Honorartabelle als Standard (' +
                                          translateReportType() +
                                          ') für das gesamte Team setzen.'
                                        : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                "
                                [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                                <mat-icon class="outlined">bookmark</mat-icon>
                                Als Standard setzen
                            </a>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                href="https://www.autoixpert.de/assets/fee-tables/huk.pdf"
                                target="_blank"
                                matTooltip="Öffne die Tabelle als PDF-Datei in einem neuen Tab">
                                <mat-icon>open_in_new</mat-icon>
                                Tabelle ansehen
                            </a>

                            <!--============================================-->
                            <!-- Older Tables -->
                            <!--============================================-->
                            <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="olderTablesHuk">
                                <mat-icon>table_view</mat-icon>
                                Ältere Tabellen
                            </a>

                            <mat-menu #olderTablesHuk>
                                <a mat-menu-item (click)="selectFeeSet({ feeTableName: 'HUK', yearOfFeeTable: 2023 })">
                                    HUK 2023
                                </a>
                            </mat-menu>
                            <!--============================================-->
                            <!-- END Older Tables -->
                            <!--============================================-->
                        </mat-menu>
                    </a>

                    <!--********** VKS **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="selectFeeSet({ feeTableName: 'VKS', yearOfFeeTable: vksFees.year })">
                        <img class="fee-table-logo" src="/assets/images/logos/vks-logo_square_360.png" />
                        <span class="fee-table-list-custom-name">VKS</span>
                        <mat-icon
                            class="fee-table-submenu-icon toolbar-icon"
                            [matMenuTriggerFor]="vksFeeTableSubmenu"
                            (click)="$event.stopPropagation()">
                            more_vert
                        </mat-icon>
                        <mat-menu #vksFeeTableSubmenu>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="setFeeTableAsUserDefault({ feeTableName: 'VKS' })"
                                [matTooltip]="
                                    hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                        ? 'Honorartabelle als Standard (' +
                                          translateReportType() +
                                          ') für das gesamte Team setzen.'
                                        : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                "
                                [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                                <mat-icon class="outlined">bookmark</mat-icon>
                                Als Standard setzen
                            </a>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                href="https://www.autoixpert.de/assets/fee-tables/vks.pdf"
                                target="_blank"
                                matTooltip="Öffne die Tabelle als PDF-Datei in einem neuen Tab">
                                <mat-icon>open_in_new</mat-icon>
                                Tabelle ansehen
                            </a>
                        </mat-menu>
                    </a>

                    <!--********** CGF (Caravan) **********-->
                    <a
                        *ngIf="isCaravanTableAllowed()"
                        mat-menu-item
                        matTooltip="Caravaning Gutachter Fachverband e.V."
                        class="menu-item-with-icon"
                        (click)="selectFeeSet({ feeTableName: 'CGF', yearOfFeeTable: cgfFees.year })">
                        <img class="fee-table-logo" src="/assets/images/logos/cgf-logo-small.png" />
                        <span class="fee-table-list-custom-name">CGF</span>
                        <mat-icon
                            class="fee-table-submenu-icon toolbar-icon"
                            [matMenuTriggerFor]="cgfFeeTableSubmenu"
                            (click)="$event.stopPropagation()">
                            more_vert
                        </mat-icon>
                        <mat-menu #cgfFeeTableSubmenu>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="setFeeTableAsUserDefault({ feeTableName: 'CGF' })"
                                [matTooltip]="
                                    hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                        ? 'Honorartabelle als Standard (' +
                                          translateReportType() +
                                          ') für das gesamte Team setzen.'
                                        : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                "
                                [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                                <mat-icon class="outlined">bookmark</mat-icon>
                                Als Standard setzen
                            </a>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                href="https://www.autoixpert.de/assets/fee-tables/cgf.pdf"
                                target="_blank"
                                matTooltip="Öffne die Tabelle als PDF-Datei in einem neuen Tab">
                                <mat-icon>open_in_new</mat-icon>
                                Tabelle ansehen
                            </a>
                        </mat-menu>
                    </a>

                    <!--********** EIGENE **********-->
                    <ng-container *ngFor="let customFeeSetItem of customFeeSets">
                        <a
                            *ngIf="customFeeSetItem.availableInReportTypes.includes(report.type)"
                            mat-menu-item
                            class="custom-fee-table-item menu-item-with-icon"
                            (click)="
                                selectFeeSet({
                                    feeTableName: 'custom',
                                    yearOfFeeTable: customFeeSetItem.year,
                                    customFeeSet: customFeeSetItem,
                                })
                            ">
                            <mat-icon class="custom-fee-table-icon">table_view</mat-icon>
                            <span class="fee-table-list-custom-name">{{ customFeeSetItem.title }}</span>
                            <mat-icon
                                *ngIf="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                                class="fee-table-submenu-icon toolbar-icon"
                                [matMenuTriggerFor]="customFeeTableSubmenu"
                                (click)="$event.stopPropagation()">
                                more_vert
                            </mat-icon>
                            <mat-menu #customFeeTableSubmenu>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon flex-center-content"
                                    (click)="
                                        setFeeTableAsUserDefault({
                                            feeTableName: 'custom',
                                            customFeeTableId: customFeeSetItem._id,
                                        })
                                    "
                                    [matTooltip]="
                                        'Honorartabelle als Standard (' +
                                        translateReportType() +
                                        ') für das gesamte Team setzen.'
                                    ">
                                    <mat-icon class="outlined">bookmark</mat-icon>
                                    Als Standard setzen
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="openCustomFeeSetDialog(customFeeSetItem)">
                                    <mat-icon>edit</mat-icon>
                                    Bearbeiten
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon alert"
                                    (click)="deleteCustomFeeSet(customFeeSetItem)">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </a>
                    </ng-container>

                    <!--********** Neue Eigene Honorartabelle **********-->
                    <a
                        *ngIf="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                        mat-menu-item
                        class="add-fee-table-item menu-item-with-icon"
                        (click)="createCustomFeeSet()">
                        <mat-icon>add</mat-icon>
                        <span class="fee-table-list-add-new">hinzufügen</span>
                    </a>
                </mat-menu>
            </div>
            <div id="official-fee-sets-flex-wrapper">
                <div
                    class="text-align-center margin-right-50"
                    *ngIf="showFeeSet(report) && !report.feeCalculation.selectedFeeTable">
                    Keine Honorartabelle auswählt.
                </div>

                <!--============================================-->
                <!-- BVSK Fees -->
                <!--============================================-->
                <table
                    class="official-fee-sets"
                    *ngIf="report.feeCalculation.selectedFeeTable === 'BVSK' && lowerFeeSet && upperFeeSet">
                    <tr>
                        <th *ngFor="let columnName of columnNames" [matTooltip]="columnName.tooltip">
                            {{ columnName.label }}
                        </th>
                    </tr>
                    <tr
                        [id]="'official-fee-set-' + (index === 0 ? 'lower' : 'higher')"
                        class="official-fee-set"
                        *ngFor="let feeSet of [lowerFeeSet, upperFeeSet]; let index = index">
                        <td class="official-fee fee-set-damage-costs">{{ feeSet[0] | number: '1.0-0' }} €</td>
                        <!-- Get values of HB 1-4 through a loop. -->
                        <td
                            class="official-fee"
                            *ngFor="let fee of [feeSet[1], feeSet[2], feeSet[3], feeSet[4]]; let columnIndex = index">
                            <span class="official-fee-content-container">
                                <span
                                    class="official-fee-amount"
                                    [ngClass]="{ disabled: isReportLocked() }"
                                    (click)="setAssessorsFee(fee); freezeAssessorsFee(); saveReport()"
                                    matTooltip="Honorar übernehmen">
                                    {{ fee | number: '1.0-0' }} €
                                </span>
                                <!-- First column is the damage total, i. e. first fee set field is at index 1. Therefore columnIndex + 1 -->
                                <mat-icon
                                    class="default-fee-marker"
                                    [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                    (click)="
                                        selectDefaultFeeSetField(
                                            'BVSK',
                                            index === 0 ? 'lower' : 'higher',
                                            columnIndex + 1
                                        )
                                    "
                                    [ngClass]="{
                                        active:
                                            team.preferences.bvskFeeConfig.row === (index === 0 ? 'lower' : 'higher') &&
                                            team.preferences.bvskFeeConfig.column === columnIndex + 1,
                                    }">
                                    check
                                </mat-icon>
                            </span>
                        </td>
                        <td class="official-fee">
                            <span class="official-fee-content-container">
                                <span
                                    class="official-fee-amount"
                                    [ngClass]="{ disabled: isReportLocked() }"
                                    (click)="setAssessorsFee(feeSet[7]); freezeAssessorsFee(); saveReport()"
                                    [matTooltip]="
                                        (feeSet[7] | convertToEuro: 2 : true) +
                                        ' (Mitte des HB5-Korridors) als Honorar übernehmen'
                                    ">
                                    {{ feeSet[5] | number: '1.0-0' }} € - {{ feeSet[6] | number: '1.0-0' }} €
                                </span>
                                <!-- First column is the damage total, i. e. first fee set field is at index 1. Therefore columnIndex + 1 -->
                                <mat-icon
                                    class="default-fee-marker"
                                    [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                    (click)="selectDefaultFeeSetField('BVSK', index === 0 ? 'lower' : 'higher', 7)"
                                    [ngClass]="{
                                        active:
                                            team.preferences.bvskFeeConfig.row === (index === 0 ? 'lower' : 'higher') &&
                                            team.preferences.bvskFeeConfig.column === 7,
                                    }">
                                    check
                                </mat-icon>
                            </span>
                        </td>
                    </tr>
                </table>
                <!--============================================-->
                <!-- END BVSK Fees -->
                <!--============================================-->

                <!--============================================-->
                <!-- Custom Fees -->
                <!--============================================-->
                <ng-container *ngIf="report.feeCalculation.selectedFeeTable === 'custom'">
                    <div id="custom-fee-set-loading" *ngIf="!customFeeSet && customFeeSetLoading">
                        Moment, die Honorartabelle wird geladen
                    </div>
                    <div id="custom-fee-set-placeholder" *ngIf="!customFeeSet && !customFeeSetLoading">
                        Jetzt eine eigene Honorartabelle anlegen.
                        <button
                            [matTooltip]="
                                !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                    ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                    : ''
                            "
                            [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                            (click)="createCustomFeeSet()">
                            Eigene Honorartabelle
                        </button>
                    </div>
                    <ng-container *ngIf="customFeeSet">
                        <table id="custom-fee-set" class="official-fee-sets" *ngIf="lowerFeeSet && upperFeeSet">
                            <tr>
                                <th *ngFor="let headerElement of columnNames" [matTooltip]="headerElement.tooltip">
                                    {{ headerElement.label }}
                                </th>
                            </tr>
                            <tr
                                [id]="'official-fee-set-' + (index === 0 ? 'lower' : 'higher')"
                                class="official-fee-set"
                                *ngFor="let feeSet of [lowerFeeSet, upperFeeSet]; let index = index">
                                <td class="official-fee fee-set-damage-costs">{{ feeSet[0] | number: '1.0-0' }} €</td>
                                <td class="official-fee">
                                    <span class="official-fee-content-container">
                                        <span
                                            class="official-fee-amount"
                                            [ngClass]="{ disabled: isReportLocked() }"
                                            (click)="setAssessorsFee(feeSet[1]); freezeAssessorsFee(); saveReport()"
                                            matTooltip="Honorar übernehmen">
                                            {{ feeSet[1] | number: '1.0-0' }} €
                                        </span>
                                        <mat-icon
                                            class="default-fee-marker"
                                            [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                            (click)="
                                                selectDefaultFeeSetField('custom', index === 0 ? 'lower' : 'higher')
                                            "
                                            [ngClass]="{
                                                active:
                                                    getDefaultCustomFeeSetConfig() ===
                                                    (index === 0 ? 'lower' : 'higher'),
                                            }">
                                            check
                                        </mat-icon>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </ng-container>
                </ng-container>
                <!--============================================-->
                <!-- END Custom Fees -->
                <!--============================================-->
                <!-------------- END Custom fees -->

                <!--============================================-->
                <!-- HUK Fees -->
                <!--============================================-->
                <table
                    class="official-fee-sets"
                    *ngIf="report.feeCalculation.selectedFeeTable === 'HUK' && lowerFeeSet && upperFeeSet">
                    <tr>
                        <th *ngFor="let headerElement of columnNames" [matTooltip]="headerElement.tooltip">
                            {{ headerElement.label }}
                        </th>
                    </tr>
                    <tr
                        [id]="'official-fee-set-' + (index === 0 ? 'lower' : 'higher')"
                        class="official-fee-set"
                        *ngFor="let feeSet of [lowerFeeSet, upperFeeSet]; let index = index">
                        <td class="official-fee fee-set-damage-costs">{{ feeSet[0] | number: '1.0-0' }} €</td>
                        <!--********** Net Value **********-->
                        <td class="official-fee">
                            <div class="official-fee-content-container">
                                <span
                                    class="official-fee-amount"
                                    [ngClass]="{ disabled: isReportLocked() }"
                                    (click)="
                                        setAssessorsFeeByHukInvoiceTotalNet(feeSet[2]);
                                        freezeAssessorsFee();
                                        saveReport()
                                    "
                                    matTooltip="Honorar übernehmen">
                                    {{ feeSet[2] | number: '1.0-0' }} €
                                </span>
                                <mat-icon
                                    class="default-fee-marker"
                                    [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                    (click)="selectDefaultFeeSetField('HUK', index === 0 ? 'lower' : 'higher')"
                                    [ngClass]="{
                                        active: team.preferences.hukFeeConfig === (index === 0 ? 'lower' : 'higher'),
                                    }">
                                    check
                                </mat-icon>
                            </div>
                        </td>

                        <!--********** Gross Value **********-->
                        <td class="official-fee">
                            <div class="official-fee-content-container">
                                <span
                                    class="official-fee-amount"
                                    [ngClass]="{ disabled: isReportLocked() }"
                                    (click)="
                                        setAssessorsFeeByHukInvoiceTotalNet(feeSet[2]);
                                        freezeAssessorsFee();
                                        saveReport()
                                    "
                                    matTooltip="Honorar übernehmen">
                                    {{ feeSet[1] | number: '1.0-0' }} €
                                </span>
                                <mat-icon
                                    class="default-fee-marker"
                                    [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                    (click)="selectDefaultFeeSetField('HUK', index === 0 ? 'lower' : 'higher')"
                                    [ngClass]="{
                                        active: team.preferences.hukFeeConfig === (index === 0 ? 'lower' : 'higher'),
                                    }">
                                    check
                                </mat-icon>
                            </div>
                        </td>
                    </tr>
                </table>
                <!--============================================-->
                <!-- END HUK Fees -->
                <!--============================================-->

                <!--============================================-->
                <!-- VKS Fees -->
                <!--============================================-->
                <table
                    class="official-fee-sets"
                    *ngIf="report.feeCalculation.selectedFeeTable === 'VKS' && lowerFeeSet && upperFeeSet">
                    <tr>
                        <th *ngFor="let headerElement of columnNames" [matTooltip]="headerElement.tooltip">
                            {{ headerElement.label }}
                        </th>
                    </tr>
                    <tr
                        [id]="'official-fee-set-' + (rowIndex === 0 ? 'lower' : 'higher')"
                        class="official-fee-set"
                        *ngFor="let feeSet of [lowerFeeSet, upperFeeSet]; let rowIndex = index">
                        <td class="official-fee fee-set-damage-costs">{{ feeSet[0] | number: '1.0-0' }} €</td>
                        <!-- Get both values -->
                        <td class="official-fee">
                            <span class="official-fee-content-container">
                                <span
                                    class="official-fee-amount"
                                    [ngClass]="{ disabled: isReportLocked() }"
                                    (click)="setAssessorsFee(feeSet[1]); freezeAssessorsFee(); saveReport()"
                                    matTooltip="Honorar übernehmen">
                                    {{ feeSet[1] | number: '1.0-0' }} €
                                </span>
                                <mat-icon
                                    class="default-fee-marker"
                                    [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                    (click)="selectDefaultFeeSetField('VKS', rowIndex === 0 ? 'lower' : 'higher', 1)"
                                    [ngClass]="{
                                        active:
                                            team.preferences.vksFeeConfig.row ===
                                                (rowIndex === 0 ? 'lower' : 'higher') &&
                                            team.preferences.vksFeeConfig.column === 1,
                                    }">
                                    check
                                </mat-icon>
                            </span>
                        </td>
                        <td class="official-fee">
                            <span class="official-fee-content-container">
                                <span
                                    class="official-fee-amount"
                                    [ngClass]="{ disabled: isReportLocked() }"
                                    (click)="setAssessorsFee(feeSet[2]); freezeAssessorsFee(); saveReport()"
                                    matTooltip="Honorar übernehmen">
                                    {{ feeSet[2] | number: '1.0-0' }} €
                                </span>
                                <mat-icon
                                    class="default-fee-marker"
                                    [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                    (click)="selectDefaultFeeSetField('VKS', rowIndex === 0 ? 'lower' : 'higher', 2)"
                                    [ngClass]="{
                                        active:
                                            team.preferences.vksFeeConfig.row ===
                                                (rowIndex === 0 ? 'lower' : 'higher') &&
                                            team.preferences.vksFeeConfig.column === 2,
                                    }">
                                    check
                                </mat-icon>
                            </span>
                        </td>
                    </tr>
                </table>
                <!--============================================-->
                <!-- END VKS Fees -->
                <!--============================================-->

                <!--============================================-->
                <!-- CGF Fees -->
                <!--============================================-->
                <table
                    class="official-fee-sets"
                    *ngIf="report.feeCalculation.selectedFeeTable === 'CGF' && lowerFeeSet && upperFeeSet">
                    <tr>
                        <th *ngFor="let headerElement of columnNames" [matTooltip]="headerElement.tooltip">
                            {{ headerElement.label }}
                        </th>
                    </tr>
                    <tr
                        [id]="'official-fee-set-' + (rowIndex === 0 ? 'lower' : 'higher')"
                        class="official-fee-set"
                        *ngFor="let feeSet of [lowerFeeSet, upperFeeSet]; let rowIndex = index">
                        <td class="official-fee fee-set-damage-costs">{{ feeSet[0] | number: '1.0-0' }} €</td>
                        <!-- Get both values -->
                        <td class="official-fee">
                            <span class="official-fee-content-container">
                                <span
                                    class="official-fee-amount"
                                    [ngClass]="{ disabled: isReportLocked() }"
                                    (click)="setAssessorsFee(feeSet[1]); freezeAssessorsFee(); saveReport()"
                                    matTooltip="Honorar übernehmen">
                                    {{ feeSet[1] | number: '1.0-0' }} €
                                </span>
                                <mat-icon
                                    class="default-fee-marker"
                                    [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                    (click)="selectDefaultFeeSetField('CGF', rowIndex === 0 ? 'lower' : 'higher', 1)"
                                    [ngClass]="{
                                        active:
                                            team.preferences.cgfFeeConfig.row ===
                                                (rowIndex === 0 ? 'lower' : 'higher') &&
                                            team.preferences.cgfFeeConfig.column === 1,
                                    }">
                                    check
                                </mat-icon>
                            </span>
                        </td>
                        <td class="official-fee">
                            <span class="official-fee-content-container">
                                <span
                                    class="official-fee-amount"
                                    [ngClass]="{ disabled: isReportLocked() }"
                                    (click)="setAssessorsFee(feeSet[2]); freezeAssessorsFee(); saveReport()"
                                    matTooltip="Honorar übernehmen">
                                    {{ feeSet[2] | number: '1.0-0' }} €
                                </span>
                                <mat-icon
                                    class="default-fee-marker"
                                    [matTooltip]="getSelectDefaultFeeSetTooltip()"
                                    (click)="selectDefaultFeeSetField('CGF', rowIndex === 0 ? 'lower' : 'higher', 2)"
                                    [ngClass]="{
                                        active:
                                            team.preferences.cgfFeeConfig.row ===
                                                (rowIndex === 0 ? 'lower' : 'higher') &&
                                            team.preferences.cgfFeeConfig.column === 2,
                                    }">
                                    check
                                </mat-icon>
                            </span>
                        </td>
                    </tr>
                </table>
                <!--============================================-->
                <!-- END CGF Fees -->
                <!--============================================-->
            </div>
        </div>
        <!--***********************************************
        ** END Fee Sets
        ************************************************-->

        <!--***********************************************
        ** Notes about Fees
        ************************************************-->
        <div class="card typical-fee-info-container text-align-center" *ngIf="report.type === 'shortAssessment'">
            Das Honorar für ein {{ translateDocumentType('shortAssessment') }} liegt meist unterhalb von 100 €.
        </div>
        <div class="card typical-fee-info-container text-align-center" *ngIf="report.type === 'leaseReturn'">
            Das Honorar für einen {{ translateDocumentType('leaseReturn') }} liegt meist zwischen 80 € (ohne
            Kalkulation) und 100 € (mit Kalkulation).
        </div>
        <div class="card typical-fee-info-container text-align-center" *ngIf="report.type === 'usedVehicleCheck'">
            Das Honorar für einen {{ translateDocumentType('usedVehicleCheck') }} liegt meist zwischen 100 € und 150 €
            (mit Kalkulation).
        </div>
        <div class="card typical-fee-info-container text-align-center" *ngIf="report.type === 'valuation'">
            Das Honorar für eine Bewertung liegt meist zwischen 100 und 300 €.
        </div>
        <div class="card typical-fee-info-container text-align-center" *ngIf="report.type === 'oldtimerValuationSmall'">
            Das Honorar für ein kleines Wertgutachten liegt meist zwischen 100 und 300 €.
        </div>
        <div class="card typical-fee-info-container text-align-center" *ngIf="report.type === 'invoiceAudit'">
            Lege dein Honorar individuell fest.
        </div>
        <!--***********************************************
        ** END Notes about Fees
        ************************************************-->
    </div>
    <div id="fee-calculation" (change)="saveReport()">
        <div id="fee-items-container" class="card" @blockChildAnimationOnLoad>
            <div class="fee-item" id="assessors-fee-container">
                <div id="assessors-fee-amount-row">
                    <h2 id="assessors-fee-heading" class="fee-item-heading">
                        Grundhonorar
                        <mat-icon
                            id="assessors-fee-description-icon"
                            class="toolbar-icon"
                            [ngClass]="{
                                'description-exists': !!report.feeCalculation.assessorsFeeDescription,
                                disabled: isReportLocked(),
                            }"
                            (click)="toggleAssessorsFeeDescription()"
                            matTooltip="Beschreibung der Rechnungsposition anpassen">
                            notes
                        </mat-icon>
                    </h2>
                    <div id="service-fee-slider-container" class="fee-item-calculation-container">
                        <div id="service-fee-slider-wrapper" *ngIf="columnNames">
                            <mat-slider
                                id="service-fee-slider"
                                thumbLabel
                                [min]="sliderMin"
                                [max]="sliderMax"
                                [(ngModel)]="assessorsFeeForSlider"
                                [disabled]="report.state === 'done'"
                                (input)="assessorsFeeForSlider = $event.value"
                                (change)="freezeAssessorsFee(); saveReport()"></mat-slider>
                        </div>

                        <!--============================================-->
                        <!-- Static Assessor's Fee -->
                        <!--============================================-->
                        <div class="remember-typical-fee-button-container" *ngIf="!reportTypeHasFeeTable()">
                            <button
                                class="flat"
                                (click)="rememberAssessorsFee()"
                                [matTooltip]="'Honorar für ' + translateDocumentType(this.report.type) + ' merken'">
                                Honorar merken
                                <span class="button-overlay" *ngIf="feeUpdatedIconShown" @fadeInAndOut>
                                    <mat-icon class="button-action-done-icon">check</mat-icon>
                                </span>
                            </button>
                        </div>
                        <!--============================================-->
                        <!-- END Static Assessor's Fee -->
                        <!--============================================-->

                        <div class="amount-and-price-per-piece-container">
                            <currency-input
                                placeholder="Honorar"
                                [(value)]="report.feeCalculation.assessorsFee"
                                (valueChange)="freezeAssessorsFee()"
                                [disabled]="report.state === 'done'"></currency-input>

                            <!--********** In liability cases, warn user about possibly wrong fees **********-->
                            <ng-container *ngIf="reportTypeHasFeeTable()">
                                <!--********** Base Fee too high for HUK **********-->
                                <mat-icon
                                    class="assessors-fee-warning-icon small-icon"
                                    *ngIf="
                                        report.feeCalculation.selectedFeeTable === 'HUK' &&
                                        !isReportLocked() &&
                                        report.feeCalculation.assessorsFee > getAppropriateHukBaseFee()
                                    "
                                    matTooltip="Grundhonorar führt zu Gesamtbetrag über oberem HUK-Wert. Klicken für Korrektur."
                                    (click)="unfreezeAssessorsFee(); setUpSliderValue(true)">
                                    warning
                                </mat-icon>

                                <!--********** Fee too low **********-->
                                <!-- Don't display in case of amendment report. -->
                                <mat-icon
                                    class="assessors-fee-warning-icon warning small-icon"
                                    *ngIf="
                                        report.feeCalculation.assessorsFee > 0 &&
                                        report.feeCalculation.assessorsFee < getAssessorsFeeForSelectedFeeTable() - 1 &&
                                        !report.originalReportId
                                    "
                                    matTooltip="
                                        Grundhonorar liegt unter dem als Standard gewählten Tabellenwert ({{
                                        getAssessorsFeeForSelectedFeeTable() | convertToEuro: 2 : true
                                    }}).{{ '\n\n' }}Klicke, um den Wert aus der Tabelle einzufügen.
                                    "
                                    (click)="setUpSliderValue(true)">
                                    warning
                                </mat-icon>

                                <!--********** Refresh slider value **********-->
                                <mat-icon
                                    id="refresh-slider-value-icon"
                                    class="small-icon"
                                    *ngIf="!isReportLocked() && !report.feeCalculation.assessorsFeeFrozen"
                                    (click)="setUpSliderValue(true)"
                                    matTooltip="Neu berechnen">
                                    refresh
                                </mat-icon>
                            </ng-container>

                            <!--********** Assessor's Fee Frozen **********-->
                            <mat-icon
                                id="assessors-fee-frozen-icon"
                                class="small-icon"
                                *ngIf="!isReportLocked() && report.feeCalculation.assessorsFeeFrozen"
                                (click)="unfreezeAssessorsFee(); setUpSliderValue(true)"
                                matTooltip="Honorar festgeschrieben, z. B. durch Gutachtenabschluss oder manuelle Änderung.{{
                                    '\n\n'
                                }}Klicken, um Honorar bei Änderung der Honorargrundlage wieder automatisch berechnen zu lassen.">
                                push_pin
                            </mat-icon>
                        </div>
                    </div>
                    <div class="fee-item-subtotal">
                        <div class="label">Honorar</div>
                        <div class="fee-item-subtotal-value">
                            {{ report.feeCalculation.assessorsFee || 0 | number: '1.2-2' }} €
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- Assessors Fee in Original Report -->
                <!--============================================-->
                <div
                    id="assessors-fee-in-original-report-row"
                    *ngIf="
                        originalReport &&
                        getAssessorFeeDifferenceFromOriginalReport() &&
                        getAssessorFeeDifferenceFromOriginalReport() !== report.feeCalculation.assessorsFee
                    "
                    @slideInAndOutVertically>
                    <!--********** Spacer Left **********-->
                    <div class="fee-item-heading"></div>

                    <div id="assessors-fee-difference-calculation-container" class="fee-item-calculation-container">
                        <div class="label">
                            Differenz zu Originalgutachten:
                            <span
                                class="link"
                                (click)="
                                    setAssessorsFee(getAssessorFeeDifferenceFromOriginalReport());
                                    freezeAssessorsFee();
                                    saveReport()
                                "
                                matTooltip="Honorardifferenz übernehmen.{{ '\n' }}Ursprünglich abgerechnetes Honorar: {{
                                    originalReport.feeCalculation.assessorsFee | number: '1.2-2'
                                }} €{{ '\n' }}Honorar laut Tabelle: {{
                                    getAssessorsFeeForSelectedFeeTable() | number: '1.2-2'
                                }} €">
                                {{ getAssessorFeeDifferenceFromOriginalReport() | number: '1.2-2' }} €
                            </span>
                        </div>
                    </div>

                    <!--********** Spacer Right **********-->
                    <div id="assessors-fee-in-original-spacer-right" class="fee-item-subtotal"></div>
                </div>
                <!--============================================-->
                <!-- END Assessors Fee in Original Report -->
                <!--============================================-->
                <!--============================================-->
                <!-- Assessors Fee Description -->
                <!--============================================-->
                <div id="assessors-fee-description-row" *ngIf="asessorsFeeDescriptionShown">
                    <!--********** Spacer Left **********-->
                    <div id="assessors-fee-description-spacer-left" class="fee-item-heading"></div>

                    <div class="fee-item-calculation-container">
                        <mat-form-field class="mat-block">
                            <mat-quill
                                [(ngModel)]="report.feeCalculation.assessorsFeeDescription"
                                (change)="saveReport()"
                                placeholder="Positionsbeschreibung"></mat-quill>
                            <mat-icon
                                matSuffix
                                *ngIf="!isReportLocked()"
                                class="input-toolbar-icon"
                                (click)="showAssessorFeeDescriptionTextTemplates = true"
                                matTooltip="Textvorlagen">
                                playlist_add
                            </mat-icon>
                        </mat-form-field>
                        <button
                            id="remember-assessors-fee-description-button"
                            class="flat"
                            [matTooltip]="
                                hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                    ? 'Beschreibung für das gesamte Team speichern.'
                                    : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                            "
                            [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                            (click)="rememberAssessorFeeDescription()">
                            Für {{ getReportTypeInGerman() }} merken
                            <span
                                class="button-overlay"
                                *ngIf="defaultAssessorFeeDescriptionUpdatedIconShown"
                                @fadeInAndOut>
                                <mat-icon class="button-action-done-icon">check</mat-icon>
                            </span>
                        </button>
                        <text-template-selector
                            [(text)]="report.feeCalculation.assessorsFeeDescription"
                            (textChange)="saveReport()"
                            [report]="report"
                            *ngIf="showAssessorFeeDescriptionTextTemplates"
                            placeholderForResultingText="Positionsbeschreibung Honorar"
                            (close)="showAssessorFeeDescriptionTextTemplates = false"
                            [textTemplateType]="'assessorsFeeDescription'"></text-template-selector>
                    </div>

                    <!--********** Spacer Right **********-->
                    <div id="assessors-fee-description-spacer-right" class="fee-item-subtotal"></div>
                </div>
                <!--============================================-->
                <!-- END Assessors Fee Description -->
                <!--============================================-->
            </div>

            <!--============================================-->
            <!-- Photo Fees -->
            <!--============================================-->
            <div class="fee-item" id="photo-fee-container">
                <h2 class="fee-item-heading">Fotogebühr</h2>
                <div id="photo-fee-calculation-container" class="fee-item-calculation-container">
                    <div id="car-photos-container">
                        <div
                            id="car-photo-in-back"
                            class="car-photo-thumbnail-container"
                            *ngIf="reportPhotos && reportPhotos[1] && hasLocalThumbnailUrl(reportPhotos[1]._id)">
                            <img
                                [src]="getLocalThumbnailUrl(reportPhotos[1]._id)"
                                alt="{{ report.car.make }} {{ report.car.model }}" />
                        </div>
                        <div
                            id="car-photo-in-front"
                            class="car-photo-thumbnail-container"
                            *ngIf="reportPhotos && reportPhotos[0] && hasLocalThumbnailUrl(reportPhotos[0]._id)">
                            <img
                                [src]="getLocalThumbnailUrl(reportPhotos[0]._id)"
                                alt="{{ report.car.make }} {{ report.car.model }}" />

                            <!--********** Number Of Photos - Readyonly **********-->
                            <div
                                id="number-of-photos"
                                class="cursor-pointer"
                                *ngIf="reportPhotos.length"
                                (click)="activateManualNumberOfPhotos(); saveReport()"
                                matTooltip="{{ reportPhotos.length }} {{
                                    reportPhotos.length === 1 ? 'Foto' : 'Fotos'
                                }}{{ '\n\n' }}Klicke, um die Anzahl zu überschreiben.">
                                {{ reportPhotos.length }}
                            </div>
                        </div>
                    </div>
                    <div class="checkbox-group fixed-width">
                        <mat-checkbox
                            id="photos-fee-flat-checkbox"
                            [(ngModel)]="report.feeCalculation.usePhotosFixedPrice"
                            [disabled]="report.state === 'done'"
                            (change)="saveReport()">
                            Pauschal
                        </mat-checkbox>
                        <mat-checkbox
                            id="photos-fee-second-photo-set-checkbox"
                            [(ngModel)]="report.feeCalculation.secondPhotoSet"
                            [disabled]="report.state === 'done'"
                            (change)="saveReport()">
                            Zweiter Fotosatz
                        </mat-checkbox>
                    </div>
                    <div
                        class="amount-and-price-per-piece-container"
                        *ngIf="!report.feeCalculation.usePhotosFixedPrice">
                        <!--********** Number Of Photos **********-->
                        <div
                            class="input-with-external-icon-container"
                            *ngIf="report.feeCalculation.useManualNumberOfPhotos">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Anzahl Fotos"
                                    number-input
                                    [minimumFractionDigits]="0"
                                    [maximumFractionDigits]="0"
                                    [(number)]="report.feeCalculation.manualNumberOfPhotos"
                                    (change)="saveReport()" />
                            </mat-form-field>
                            <mat-icon
                                id="deactivate-manual-number-of-photos"
                                class="toolbar-icon small-icon"
                                (click)="deactivateManualNumberOfPhotos(); saveReport()"
                                matTooltip="Manuelle Eingabe verstecken und Anzahl Fotos automatisch ermitteln">
                                close
                            </mat-icon>
                        </div>

                        <div class="custom-fee-row-inputs-grid">
                            <!--********** Price Per Photo **********-->
                            <currency-input
                                placeholder="Preis Foto"
                                [(value)]="report.feeCalculation.pricePerPhoto"
                                [disabled]="report.state === 'done'"></currency-input>

                            <!--********** Price per Photo Copy **********-->
                            <currency-input
                                placeholder="Preis Zweitfoto"
                                *ngIf="report.feeCalculation.secondPhotoSet"
                                [(value)]="report.feeCalculation.pricePerSecondPhoto"
                                [disabled]="report.state === 'done'"></currency-input>
                        </div>
                    </div>
                    <div class="amount-and-price-per-piece-container" *ngIf="report.feeCalculation.usePhotosFixedPrice">
                        <div class="custom-fee-row-inputs-grid">
                            <currency-input
                                placeholder="Fotosatz"
                                [(value)]="report.feeCalculation.photosFixedPrice"
                                [disabled]="report.state === 'done'"></currency-input>
                            <currency-input
                                placeholder="Zweitsatz"
                                *ngIf="report.feeCalculation.secondPhotoSet"
                                [(value)]="report.feeCalculation.secondPhotoSetFixedPrice"
                                [disabled]="report.state === 'done'"></currency-input>
                        </div>
                    </div>
                </div>
                <div class="fee-item-subtotal">
                    <div class="label">Fotogebühr</div>
                    <div class="fee-item-subtotal-value">{{ getPhotosFeeTotal(report) | number: '1.2-2' }} €</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Photo Fees -->
            <!--============================================-->

            <!--============================================-->
            <!-- Traveling Fees -->
            <!--============================================-->
            <div class="fee-item" id="traveling-fee-container">
                <h2 class="fee-item-heading">Fahrtkosten</h2>
                <div id="traveling-fee-calculation-container" class="fee-item-calculation-container">
                    <div id="travel-expenses-icon-container">
                        <img src="/assets/images/icons/distance_84x44.png" alt="Distance icon" />
                    </div>
                    <div class="checkbox-group fixed-width">
                        <mat-checkbox
                            id="traveling-fee-flat-checkbox"
                            [(ngModel)]="report.feeCalculation.useTravelExpensesFixedPrice"
                            [disabled]="report.state === 'done'"
                            (change)="saveReport()">
                            Pauschal
                        </mat-checkbox>
                    </div>
                    <div
                        class="amount-and-price-per-piece-container"
                        *ngIf="!report.feeCalculation.useTravelExpensesFixedPrice">
                        <!--********** Number of Kilometers **********-->
                        <div id="number-of-kilometers-container">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Anzahl Km"
                                    number-input
                                    [minimumFractionDigits]="0"
                                    [maximumFractionDigits]="1"
                                    [(number)]="report.feeCalculation.numberOfKilometers"
                                    [disabled]="isReportLocked()" />
                                <span matSuffix>km</span>
                            </mat-form-field>
                            <div id="number-of-kilometers-toolbar">
                                <mat-icon
                                    matSuffix
                                    class="input-toolbar-icon"
                                    (click)="calculateTraveledKilometers($event)"
                                    *ngIf="!isReportLocked() && !traveledDistanceCalculationPending"
                                    [matTooltip]="
                                        getOriginsAndDestinationsForReport().destinations.length
                                            ? 'Gefahrene Strecke zu Besichtigungen ermitteln.\n\nStrg + Klick öffnet Google Maps.\n\nGoogle Maps kann leicht abweichende Ergebnisse anzeigen, falls ein anderer Rück- als Hinweg gewählt wurde.'
                                            : 'Bitte gib zuerst die Anschrift mindestens einer Besichtigung an.'
                                    ">
                                    directions
                                </mat-icon>
                                <img
                                    matSuffix
                                    src="/assets/images/icons/loading-indicator.svg"
                                    alt=""
                                    *ngIf="traveledDistanceCalculationPending"
                                    matTooltip="Berechne Distanz..." />
                            </div>
                        </div>

                        <!--********** Price per Kilometer **********-->
                        <currency-input
                            placeholder="Preis p. Km"
                            [(value)]="report.feeCalculation.pricePerKilometer"
                            [disabled]="report.state === 'done'"></currency-input>
                    </div>

                    <!--********** Fixed Price Travel Expenses **********-->
                    <div
                        class="amount-and-price-per-piece-container"
                        *ngIf="report.feeCalculation.useTravelExpensesFixedPrice">
                        <currency-input
                            placeholder="Fahrtkosten"
                            [(value)]="report.feeCalculation.travelExpensesFixedPrice"
                            [disabled]="report.state === 'done'"></currency-input>
                    </div>
                </div>
                <div class="fee-item-subtotal">
                    <div class="label">Fahrtkosten</div>
                    <div class="fee-item-subtotal-value">{{ getTravelExpensesTotal(report) | number: '1.2-2' }} €</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Traveling Fees -->
            <!--============================================-->

            <!--============================================-->
            <!-- Writing Fees -->
            <!--============================================-->
            <div class="fee-item">
                <h2 class="fee-item-heading">Schreibkosten</h2>
                <div id="writing-fee-calculation-container" class="fee-item-calculation-container">
                    <!--********** Spacer instead of icon **********-->
                    <div id="writing-costs-spacer">&nbsp;</div>

                    <div class="checkbox-group fixed-width">
                        <mat-checkbox
                            [(ngModel)]="report.feeCalculation.useWritingFeesFixedPrice"
                            [disabled]="report.state === 'done'"
                            (change)="saveReport()">
                            Pauschal
                        </mat-checkbox>
                        <mat-checkbox
                            [(ngModel)]="report.feeCalculation.useWritingCopyFees"
                            [disabled]="report.state === 'done'"
                            (change)="saveReport()">
                            Kopie
                        </mat-checkbox>
                    </div>
                    <div
                        class="amount-and-price-per-piece-container custom-fee-row-inputs-grid"
                        *ngIf="!report.feeCalculation.useWritingFeesFixedPrice">
                        <div id="number-of-pages-container">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Anzahl Seiten"
                                    number-input
                                    [minimumFractionDigits]="0"
                                    [maximumFractionDigits]="0"
                                    [(ngModel)]="report.feeCalculation.numberOfPages"
                                    [disabled]="isReportLocked()" />
                            </mat-form-field>
                            <img
                                class="loading-indicator"
                                src="/assets/images/icons/loading-indicator.svg"
                                alt="Lade-Icon"
                                *ngIf="numberOfPagesPending"
                                matTooltip="Dokument für Zahl der Seiten wird generiert..." />
                            <mat-icon
                                id="count-report-pdf-pages-icon"
                                class="input-toolbar-icon"
                                *ngIf="!isReportLocked() && !numberOfPagesPending"
                                [class.disabled]="hasReportDocumentCustomUpload()"
                                (click)="determineNumberOfReportPdfPages($event)"
                                [matTooltip]="getDetermineNumberOfReportPdfPagesTooltip()">
                                calculate
                            </mat-icon>
                        </div>

                        <currency-input
                            placeholder="Preis Seite"
                            [(value)]="report.feeCalculation.pricePerPage"
                            [disabled]="report.state === 'done'"></currency-input>
                        <!--********** Price per Page Copy **********-->
                        <currency-input
                            placeholder="Preis Kopie"
                            *ngIf="report.feeCalculation.useWritingCopyFees"
                            [(value)]="report.feeCalculation.pricePerPageCopy"
                            [disabled]="report.state === 'done'"></currency-input>
                    </div>
                    <!--********** Fixed Price **********-->
                    <div
                        class="amount-and-price-per-piece-container custom-fee-row-inputs-grid"
                        *ngIf="report.feeCalculation.useWritingFeesFixedPrice">
                        <currency-input
                            placeholder="Schreibkosten"
                            [(value)]="report.feeCalculation.writingFeesFixedPrice"
                            [disabled]="report.state === 'done'"></currency-input>
                        <currency-input
                            placeholder="Kopiekosten"
                            *ngIf="report.feeCalculation.useWritingCopyFees"
                            [(value)]="report.feeCalculation.writingCopyFeesFixedPrice"
                            [disabled]="report.state === 'done'"></currency-input>
                    </div>
                </div>
                <div class="fee-item-subtotal">
                    <div class="label">Schreibkosten</div>
                    <div class="fee-item-subtotal-value">{{ getWritingFeesTotal(report) | number: '1.2-2' }} €</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Writing Fees -->
            <!--============================================-->

            <!--============================================-->
            <!-- Postage and Phone Fees -->
            <!--============================================-->
            <div id="postage-and-phone-fees-fee-container" class="fee-item">
                <h2 class="fee-item-heading">Porto & Telefon</h2>
                <div id="postage-and-phone-calculation-container" class="fee-item-calculation-container">
                    <div class="amount-and-price-per-piece-container custom-fee-row-inputs-grid">
                        <currency-input
                            [(value)]="report.feeCalculation.postageAndPhoneFees"
                            placeholder="Porto & Telefon"
                            [disabled]="report.state === 'done'"></currency-input>
                    </div>
                </div>
                <div class="fee-item-subtotal">
                    <div class="label">Porto & Telefon</div>
                    <div class="fee-item-subtotal-value">{{ getPostageAndPhoneFees(report) | number: '1.2-2' }} €</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Writing Fees -->
            <!--============================================-->

            <!--============================================-->
            <!-- Other Fees -->
            <!--============================================-->
            <div class="fee-item" id="other-fees-container">
                <div id="other-fees-heading-container">
                    <h2 class="fee-item-heading">Sonstiges</h2>
                </div>
                <div id="other-fees-calculation-container" class="fee-item-calculation-container">
                    <!--============================================-->
                    <!-- Custom Fees -->
                    <!--============================================-->
                    <div
                        id="custom-fee-item-list"
                        cdkDropList
                        cdkDropListLockAxis="y"
                        (cdkDropListDropped)="handleReorderingDrop($event); saveReport()">
                        <div
                            class="custom-fee-row other-fees-line-item"
                            cdkDrag
                            [cdkDragDisabled]="isReportLocked()"
                            *ngFor="
                                let customFeeItem of report.feeCalculation.invoiceParameters.lineItems;
                                trackBy: trackById;
                                let index = index
                            ">
                            <mat-icon class="custom-fee-drag-handle" cdkDragHandle>drag_indicator</mat-icon>
                            <div class="custom-fee-row-checkbox-and-description-container">
                                <mat-checkbox
                                    class="custom-fee-row-active-marker"
                                    [disabled]="report.state === 'done'"
                                    [(ngModel)]="customFeeItem.active"
                                    (change)="excludeOtherCostFromHukBaseFee(customFeeItem); saveReport()"
                                    matTooltip="Position auf Rechnung drucken"></mat-checkbox>

                                <invoice-line-item-template-selector
                                    #descriptionInput
                                    [invoiceLineItemTemplates]="invoiceLineItemTemplates"
                                    [(ngModel)]="customFeeItem.description"
                                    (change)="saveReport()"
                                    (invoiceLinItemTemplateSelected)="
                                        insertInvoiceLineItemTemplate(customFeeItem, $event)
                                    "
                                    [disabled]="report.state === 'done'"></invoice-line-item-template-selector>

                                <div>
                                    <img
                                        class="custom-huk-fee-icon"
                                        src="/assets/images/logos/insurances/huk-coburg.png"
                                        alt=""
                                        *ngIf="
                                            report.feeCalculation.selectedFeeTable === 'HUK' &&
                                            report.type === 'liability'
                                        "
                                        [ngClass]="{ active: customFeeItem.includedInHukBaseFee }"
                                        (click)="toggleOtherCostIncludedInHukBaseFee(customFeeItem)"
                                        matTooltip="{{
                                            'Markiere diese Rechnungsposition als im HUK-Zielbetrag enthalten.\nDein Grundhonorar wird automatisch um diesen Betrag reduziert. Geeignet, wenn du mit der HUK Sonderpositionen vereinbart hast. Externe Leistungen (z. B. Achsvermessungen) solltest du nicht markieren, damit sie weiterhin regulär aufgeschlagen werden.'
                                        }}" />
                                </div>
                            </div>

                            <div class="custom-fee-row-inputs-grid flex-on-xl-screen">
                                <!--********** Quantity **********-->
                                <mat-form-field class="custom-fee-quantity">
                                    <input
                                        matInput
                                        placeholder="Menge"
                                        number-input
                                        [(number)]="customFeeItem.quantity"
                                        [disabled]="isReportLocked()"
                                        name="invoice-quantity"
                                        (change)="insertLineItemUnitBasedOnQuantity(customFeeItem, customFeeItem.unit)"
                                        [minimumFractionDigits]="0" />
                                </mat-form-field>

                                <!--********** Unit **********-->
                                <mat-form-field class="custom-fee-unit">
                                    <input
                                        matInput
                                        placeholder="Einheit"
                                        [(ngModel)]="customFeeItem.unit"
                                        [disabled]="isReportLocked()"
                                        name="invoice-unit"
                                        (ngModelChange)="filterLineItemAutocomplete($event)"
                                        (focus)="filterLineItemAutocomplete('')"
                                        [matAutocomplete]="invoiceLineItemUnitAutocomplete" />
                                    <mat-autocomplete
                                        #invoiceLineItemUnitAutocomplete
                                        (optionSelected)="
                                            insertLineItemUnitBasedOnQuantity(customFeeItem, $event.option.viewValue);
                                            saveReport()
                                        ">
                                        <mat-option
                                            *ngFor="let filteredLineItemUnit of filteredLineItemUnits"
                                            (mousedown)="$event.preventDefault()">
                                            {{ filteredLineItemUnit.label }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="custom-fee-row-inputs-grid flex-on-xl-screen">
                                <!--********** Unit Price **********-->
                                <div class="custom-fee-unit-price">
                                    <currency-input
                                        placeholder="Einzelpreis"
                                        [(value)]="customFeeItem.unitPrice"
                                        (valueChange)="saveReport()"
                                        [disabled]="isReportLocked()"
                                        (keydown.tab)="
                                            index === report.feeCalculation.invoiceParameters.lineItems.length - 1
                                                ? addInvoiceLineItem()
                                                : null
                                        "></currency-input>
                                </div>

                                <!--********** Item Total **********-->
                                <div class="custom-fee-total-price">
                                    <currency-input
                                        totalPriceReadonlyHint
                                        placeholder="Gesamtpreis"
                                        [readonly]="true"
                                        [tabindex]="-1"
                                        [value]="
                                            customFeeItem.quantity * customFeeItem.unitPrice || 0
                                        "></currency-input>
                                </div>
                            </div>

                            <mat-icon class="custom-fee-row-submenu-icon" [matMenuTriggerFor]="feePositionSubMenu">
                                more_vert
                            </mat-icon>

                            <mat-menu #feePositionSubMenu="matMenu">
                                <a
                                    mat-menu-item
                                    *ngIf="
                                        !customFeeItem.lineItemTemplateId &&
                                        user.accessRights.editTextsAndDocumentBuildingBlocks
                                    "
                                    class="menu-item-with-icon"
                                    (click)="saveInvoiceLineItemTemplate(customFeeItem)"
                                    [matTooltip]="
                                        user?.accessRights.editTextsAndDocumentBuildingBlocks
                                            ? 'Rechnungsposition als Vorlage speichern'
                                            : 'Das Speichern von Rechnungspositionen als Vorlagen erfordert die Berechtigung, Texte und Textbausteine zu editieren.'
                                    ">
                                    <mat-icon class="outlined">bookmark</mat-icon>
                                    Als Vorlage speichern
                                </a>
                                <a
                                    mat-menu-item
                                    *ngIf="customFeeItem.lineItemTemplateId"
                                    [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                                    class="menu-item-with-icon"
                                    (click)="updateInvoiceLineItemTemplate(customFeeItem)"
                                    [matTooltip]="
                                        user?.accessRights.editTextsAndDocumentBuildingBlocks
                                            ? 'Rechnungsposition als Vorlage speichern'
                                            : 'Das Speichern von Rechnungspositionen als Vorlagen erfordert die Berechtigung, Texte und Textbausteine zu editieren.'
                                    ">
                                    <mat-icon class="outlined">bookmark</mat-icon>
                                    Vorlage aktualisieren
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    matTooltip="Position entfernen"
                                    (click)="removeInvoiceLineItem(customFeeItem); saveReport()"
                                    *ngIf="!isReportLocked()">
                                    <mat-icon class="outlined">delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </div>
                    </div>

                    <!--********** Buttons **********-->
                    <div id="custom-fee-buttons-container" *ngIf="!isReportLocked()">
                        <button id="add-custom-fee-button" class="flat" (click)="addInvoiceLineItem()">
                            <mat-icon class="small-icon">add</mat-icon>
                            Position hinzufügen
                        </button>
                        <button
                            class="flat"
                            (click)="rememberDefaultFees()"
                            [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                            [matTooltip]="
                                hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                    ? 'Alle Gebühren (außer Honorar) und Sonderpositionen als Standard setzen und bei neuen Gutachten einfügen'
                                    : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                            ">
                            Gebühren merken
                            <span class="button-overlay" *ngIf="defaultFeesUpdatedIconShown" @fadeInAndOut>
                                <mat-icon class="button-action-done-icon">check</mat-icon>
                            </span>
                        </button>
                    </div>
                    <!--============================================-->
                    <!-- END Custom Fees -->
                    <!--============================================-->
                </div>
                <div class="fee-item-subtotal">
                    <div class="label">Sonstiges</div>
                    <div class="fee-item-subtotal-value">{{ otherFeesTotal | number: '1.2-2' }} €</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Other Fees -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <!-- Commented out because we'll introduce a custom invoice editor later on anyways and we don't want people to build logic on custom fields now. -->
            <!--            <custom-field-display fieldLocation="fees" [report]="report" [useNegativeSideMarginForCards]="false" (reportChange)="saveReport()" [disabled]="isReportLocked()"></custom-field-display>-->
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->

            <!--============================================-->
            <!-- Skip Writing Invoice Overlay - Fee Items Container -->
            <!--============================================-->
            <div
                class="skip-writing-invoice-overlay"
                *ngIf="report.feeCalculation.skipWritingInvoice"
                [ngClass]="{ 'is-firefox': isFirefox() }"
                @fadeInAndOut>
                <img src="/assets/images/icons/no-invoice.svg" alt="" />
                <h3>Ohne Rechnung</h3>
                <p>Bei Gutachtenabschluss wird keine Rechnung geschrieben.</p>
                <p class="skip-writing-invoice-reason text-align-center no-margin-top">
                    Grund:
                    <b>{{ report.feeCalculation.skipWritingInvoiceReason ?? 'Keine Angabe' }}</b>
                    <!--********** Edit Icon **********-->
                    <mat-icon
                        *ngIf="!isReportLocked()"
                        (click)="showSkipWritingInvoiceReasonDialog()"
                        class="toolbar-icon small-icon outlined">
                        edit
                    </mat-icon>
                </p>

                <div class="link" (click)="toggleSkipWritingInvoice()" *ngIf="!isReportLocked()">
                    Rechnung aktivieren
                </div>
            </div>
            <!--============================================-->
            <!-- END Skip Writing Invoice Overlay - Fee Items Container -->
            <!--============================================-->
        </div>

        <!--============================================-->
        <!-- Right Column - Total & Associated invoices -->
        <!--============================================-->
        <div id="fee-invoice-and-associated-invoices-container">
            <div id="fee-invoice-total-container" class="card card-with-padding" @blockChildAnimationOnLoad>
                <!--********** Invoice Card Toolbar **********-->
                <div class="card-toolbar">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="invoiceCardSubmenu">more_vert</mat-icon>
                </div>
                <mat-menu #invoiceCardSubmenu>
                    <!--********** Activate Invoice **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="report.feeCalculation.skipWritingInvoice || report.feeCalculation.isCollectiveInvoice"
                        (click)="
                            report.feeCalculation.skipWritingInvoice
                                ? toggleSkipWritingInvoice()
                                : toggleCollectiveInvoice()
                        "
                        [disabled]="isReportLocked()"
                        matTooltip="Bei Gutachtenabschluss eine Rechnung generieren">
                        <mat-icon>task</mat-icon>
                        Rechnung aktivieren
                    </a>

                    <!--********** Deactivate Invoice **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="!report.feeCalculation.skipWritingInvoice && !report.feeCalculation.isCollectiveInvoice"
                        (click)="toggleSkipWritingInvoice()"
                        [disabled]="isReportLocked()"
                        matTooltip="Bei Gutachtenabschluss keine Rechnung generieren">
                        <mat-icon>block</mat-icon>
                        Keine Rechnung schreiben
                    </a>

                    <!--********** Collective Invoice **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="!report.feeCalculation.isCollectiveInvoice && !report.feeCalculation.skipWritingInvoice"
                        (click)="toggleCollectiveInvoice()"
                        [disabled]="isReportLocked()">
                        <mat-icon>library_add</mat-icon>
                        Sammelrechnung
                        <span
                            id="collective-invoice-help-indicator"
                            class="help-indicator cursor-pointer"
                            (click)="openCollectiveInvoiceHelpPage($event)"
                            [matTooltip]="
                                'In Sammelrechnung abzurechnen. Bei Gutachtenabschluss wird keine Rechnung generiert. \n\nDie Sammelrechnung kann über das Dropdown neben dem Button &quot;Neue Rechnung&quot; in der Rechnungsliste erstellt werden.\n\n Klicke auf das Fragezeichen für eine ausführliche Anleitung.'
                            ">
                            ?
                        </span>
                    </a>
                </mat-menu>

                <h2>{{ report.feeCalculation.isCollectiveInvoice ? 'Sammelrechnung' : 'Rechnung' }}</h2>

                <div id="editable-invoice-parameters-container" class="ghost-button-parent">
                    <div
                        id="fee-invoice-recipient-container"
                        [matMenuTriggerFor]="isReportLocked() ? null : invoiceRecipientSelectionMenu"
                        matTooltip="Rechnungsempfänger"
                        [class.disabled]="isReportLocked()">
                        <div
                            id="fee-invoice-recipient"
                            *ngIf="invoiceRecipient?.organization || invoiceRecipient?.lastName">
                            <div id="fee-invoice-recipient-organization" *ngIf="invoiceRecipient.organization">
                                {{ invoiceRecipient.organization }}
                            </div>
                            <div id="fee-invoice-recipient-name">
                                {{ invoiceRecipient.salutation }} {{ invoiceRecipient.firstName }}
                                {{ invoiceRecipient.lastName }}
                            </div>
                            <div id="fee-invoice-recipient-street">
                                {{ invoiceRecipient.streetAndHouseNumberOrLockbox }}
                            </div>
                            <div id="fee-invoice-recipient-city">
                                {{ invoiceRecipient.zip }} {{ invoiceRecipient.city }}
                            </div>
                        </div>
                        <div
                            id="fee-invoice-no-recipient-container"
                            *ngIf="!invoiceRecipient?.organization && !invoiceRecipient?.lastName"
                            matTooltip="Die Adresse lässt sich im Prozessschritt Unfall & Beteiligte angeben">
                            <div [ngSwitch]="invoiceRecipient?.organizationType">
                                <ng-container *ngSwitchDefault>
                                    Hier steht bald die Adresse des Rechnungsempfängers.
                                </ng-container>
                                <ng-container *ngSwitchCase="'claimant'" [ngSwitch]="report.type">
                                    Hier steht bald die Adresse des {{ getClaimantDenomination() }}s.
                                </ng-container>
                                <ng-container *ngSwitchCase="'insurance'">
                                    Hier steht bald die Adresse der Versicherung.
                                </ng-container>
                                <ng-container *ngSwitchCase="'lawyer'">
                                    Hier steht bald die Adresse des Anwalts.
                                </ng-container>
                                <ng-container *ngSwitchCase="'garage'">
                                    Hier steht bald die Adresse der Werkstatt.
                                </ng-container>
                                <ng-container *ngSwitchCase="'leaseProvider'">
                                    Hier steht bald die Adresse des Leasinggebers.
                                </ng-container>
                            </div>
                        </div>
                        <mat-icon id="invoice-recipient-selector-icon" class="toolbar-icon">arrow_drop_down</mat-icon>
                        <mat-menu id="invoice-recipient-selection-menu" #invoiceRecipientSelectionMenu>
                            <!--********** Claimant **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="setInvoiceRecipientRole('claimant'); saveReport()">
                                <mat-icon>person</mat-icon>
                                {{ getClaimantDenomination() }}
                            </a>
                            <!--********** Claimant **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="!report.claimant.isOwner"
                                (click)="setInvoiceRecipientRole('ownerOfClaimantsCar'); saveReport()">
                                <mat-icon>badge</mat-icon>
                                Fahrzeughalter
                            </a>
                            <!--********** Insurace **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="setInvoiceRecipientRole('insurance'); saveReport()"
                                *ngIf="report.insurance?.contactPerson">
                                <mat-icon>shield</mat-icon>
                                Versicherung
                            </a>

                            <!--********** Garage **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="setInvoiceRecipientRole('garage'); saveReport()"
                                *ngIf="report.garage?.contactPerson">
                                <mat-icon>build</mat-icon>
                                Werkstatt
                            </a>

                            <!--********** Leasing Provider **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="setInvoiceRecipientRole('leaseProvider'); saveReport()"
                                *ngIf="report.leaseProvider?.contactPerson">
                                <mat-icon>account_balance</mat-icon>
                                Leasinggeber
                            </a>

                            <!--********** Custom **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="showInvoiceRecipientContactPersonEditor()">
                                <mat-icon>edit</mat-icon>
                                Anderer Empfänger
                            </a>
                        </mat-menu>
                    </div>

                    <div id="invoice-number-container" *ngIf="!report.feeCalculation.isCollectiveInvoice">
                        <mat-form-field class="mat-block light-mode ghost-until-hover">
                            <input
                                id="invoice-number"
                                placeholder="Nummer"
                                matInput
                                #invoiceNumberElement
                                [(ngModel)]="report.feeCalculation.invoiceParameters.number"
                                (focus)="lastInvoiceNumber = report.feeCalculation.invoiceParameters.number"
                                (change)="
                                    adjustReportTokenToInvoiceNumber({ force: false, extractOrOverwrite: 'extract' })
                                "
                                [disabled]="report.state === 'done'" />
                        </mat-form-field>
                        <mat-icon
                            class="generate-invoice-number-icon"
                            *ngIf="
                                !report.feeCalculation.invoiceParameters.number &&
                                !generateInvoiceNumberRequestPending &&
                                !isReportLocked()
                            "
                            (click)="generateInvoiceNumber()"
                            matTooltip="Neue Rechnungsnummer generieren">
                            file_download
                        </mat-icon>

                        <mat-icon
                            [editCounterAnchor]="report"
                            class="invoice-number-settings-icon outlined"
                            *ngIf="report.invoiceNumberConfig"
                            matTooltip="Rechnungszähler für dieses Gutachten bearbeiten">
                            more_vert
                        </mat-icon>
                        <img
                            class="generate-invoice-number-pending-icon"
                            *ngIf="
                                !report.feeCalculation.invoiceParameters.number && generateInvoiceNumberRequestPending
                            "
                            src="/assets/images/icons/loading-indicator.svg"
                            matTooltip="Rechnungsnummer wird generiert..." />
                    </div>
                    <!--============================================-->
                    <!-- Adjust Report Token To Invoice Number Note -->
                    <!--============================================-->
                    <div
                        id="adjust-report-token-to-invoice-number-note"
                        class="info-note"
                        *ngIf="
                            wasAdjustingReportTokenToInvoiceNumberPrevented &&
                            report.feeCalculation.invoiceParameters.number
                        "
                        @fadeOut>
                        <div id="adjust-report-token-to-invoice-number-text">
                            <div>Aktenzeichen angleichen?</div>

                            <!--********** Buttons **********-->
                            <div class="info-note-buttons-row">
                                <!--********** Copy smart **********-->
                                <div
                                    class="info-note-button-primary"
                                    (click)="
                                        adjustReportTokenToInvoiceNumber({ force: true, extractOrOverwrite: 'extract' })
                                    "
                                    matTooltip="Anhand des hinterlegten Musters werden Zähler, Datum usw. extrahiert und in die Vorlage für deine Rechnungsnummer eingefügt.{{
                                        '\n\n'
                                    }}Aktuelles Aktenzeichen: {{ report.token }}">
                                    Angleichen
                                </div>

                                <!--********** Copy 1:1 **********-->
                                <div
                                    class="info-note-button-secondary"
                                    (click)="
                                        adjustReportTokenToInvoiceNumber({
                                            force: true,
                                            extractOrOverwrite: 'overwrite',
                                        })
                                    "
                                    matTooltip="Die Rechnungsnummer wird 1:1 als Aktenzeichen verwendet.{{
                                        '\n\n'
                                    }}Aktuelles Aktenzeichen: {{ report.token }}">
                                    Überschreiben
                                </div>
                            </div>
                        </div>
                        <mat-icon
                            class="info-note-close-icon"
                            (click)="wasAdjustingReportTokenToInvoiceNumberPrevented = false"
                            matTooltip="Nicht angleichen">
                            close
                        </mat-icon>
                    </div>
                    <!--============================================-->
                    <!-- END Adjust Report Token To Invoice Number Note -->
                    <!--============================================-->
                    <div
                        id="invoice-date-and-days-until-due-container"
                        *ngIf="!report.feeCalculation.isCollectiveInvoice">
                        <div id="invoice-date-container">
                            <date-input
                                id="invoice-date"
                                placeholder="Datum"
                                [(date)]="report.feeCalculation.invoiceParameters.date"
                                matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"
                                (dateChange)="saveReport()"
                                [disabled]="report.state === 'done'"
                                [lightMode]="true"
                                [ghost-until-hover]="true"></date-input>
                        </div>

                        <div id="days-until-due-container">
                            <mat-form-field
                                class="mat-block light-mode ghost-until-hover"
                                *ngIf="report.feeCalculation.invoiceParameters"
                                matTooltipPosition="below"
                                matTooltip="in Tagen">
                                <input
                                    matInput
                                    number-input
                                    [minimumFractionDigits]="0"
                                    [maximumFractionDigits]="0"
                                    placeholder="Zahlungsziel"
                                    [(number)]="report.feeCalculation.invoiceParameters.daysUntilDue"
                                    (change)="saveReport()"
                                    [disabled]="
                                        (team && !team.administrators.includes(user._id)) || report.state === 'done'
                                    " />
                                <!-- Cast to any: Usually number and '' should not have an overlap. Maybe there were some issues in the past? We kept the check for the empty string to do not break existing behavior. -->
                                <span
                                    matSuffix
                                    *ngIf="
                                        report.feeCalculation.invoiceParameters.daysUntilDue != null &&
                                        $any(report.feeCalculation.invoiceParameters.daysUntilDue) !== ''
                                    "
                                    @fadeInAndOut>
                                    {{ report.feeCalculation.invoiceParameters.daysUntilDue === 1 ? 'Tag' : 'Tage' }}
                                </span>
                                <mat-icon
                                    id="days-until-due-deviate-from-standard"
                                    class="small-icon"
                                    *ngIf="
                                        user &&
                                        user.adeltaFinanzUser.username &&
                                        report.feeCalculation.invoiceParameters.factoringEnabled &&
                                        report.feeCalculation.invoiceParameters.daysUntilDue &&
                                        !(
                                            report.feeCalculation.invoiceParameters.daysUntilDue == 30 ||
                                            report.feeCalculation.invoiceParameters.daysUntilDue == 60
                                        )
                                    "
                                    matTooltip="ADELTA.FINANZ erlaubt nur Zahlungsziele von 30 und 60 Tagen. Beim Export können Fehler auftreten.">
                                    warning
                                </mat-icon>
                            </mat-form-field>

                            <mat-icon
                                id="days-until-due-submenu-icon"
                                [matMenuTriggerFor]="dueDateMenu"
                                class="toolbar-icon">
                                more_vert
                            </mat-icon>
                            <mat-menu #dueDateMenu>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="team.preferences.defaultDaysUntilDueConfigs.length"
                                    (click)="insertDefaultDaysUntilDue(); saveReport()">
                                    <mat-icon>file_download</mat-icon>
                                    Standard-Zahlungsziel einfügen
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="saveDaysUntilDueToTeamPreferences()"
                                    [matTooltip]="
                                        !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                            ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                            : ''
                                    "
                                    [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                                    <mat-icon>star</mat-icon>
                                    Zahlungsziel für {{ translateReportType() }} merken
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon alert"
                                    *ngIf="!!getDefaultDaysUntilDueConfig()"
                                    (click)="removeDaysUntilDueFromTeamPreferences()"
                                    [matTooltip]="
                                        !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                            ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                            : ''
                                    "
                                    [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                                    <mat-icon>delete</mat-icon>
                                    Zahlungsziel für {{ translateReportType() }} löschen
                                </a>
                            </mat-menu>
                        </div>
                    </div>
                </div>

                <!--***********************************************
                ** Electronic Invoice
                ************************************************-->
                <electronic-invoice-toggle
                    *ngIf="invoiceRecipient && !report.feeCalculation.isCollectiveInvoice"
                    [disabled]="isReportLocked()"
                    [invoiceDate]="report.feeCalculation.invoiceParameters.date"
                    [(isElectronicInvoiceEnabled)]="report.feeCalculation.invoiceParameters.isElectronicInvoiceEnabled"
                    [(isElectronicInvoiceStatusFrozen)]="
                        report.feeCalculation.invoiceParameters.isElectronicInvoiceStatusFrozen
                    "
                    (isElectronicInvoiceEnabledChange)="saveReport()"
                    (isElectronicInvoiceStatusFrozenChange)="saveReport()"
                    [invoiceRecipientContactPerson]="invoiceRecipient"></electronic-invoice-toggle>
                <!--***********************************************
                ** END Electronic Invoice
                ************************************************-->

                <div
                    *ngIf="report.feeCalculation.isCollectiveInvoice && showRememberCollectiveInvoiceSettingsInfoNote"
                    id="remember-collective-invoice-settings-info-note"
                    class="info-note">
                    <div class="info-note-text">
                        <h3>Einstellung merken</h3>
                        <p class="no-margin-bottom">
                            Du kannst die Sammelrechnung standardmäßig für diesen Kontakt im
                            <a href="/Kontakte">Kontaktmanagement</a>
                            aktivieren.
                        </p>
                    </div>
                </div>

                <div id="fee-invoice-total-value-container">
                    <div class="label">Rechnungsbetrag</div>
                    <div id="fee-invoice-total-net">
                        <span matTooltip="Netto">{{ feesTotalNet | number: '1.2-2' }} €</span>
                        <mat-icon id="invoice-total-submenu" class="toolbar-icon" [matMenuTriggerFor]="vatRateMenu">
                            more_vert
                        </mat-icon>
                        <mat-menu #vatRateMenu>
                            <a
                                mat-menu-item
                                [mat-menu-trigger-for]="zeroPercentVatSubMenu"
                                [ngClass]="{ 'color-blue': report.feeCalculation.invoiceParameters.vatRate === 0 }">
                                0 % MwSt. -
                                {{
                                    translateVatExemptionReason(
                                        report.feeCalculation.invoiceParameters.vatExemptionReason
                                    )
                                }}
                                <mat-menu #zeroPercentVatSubMenu>
                                    <a
                                        mat-menu-item
                                        [ngClass]="{
                                            'color-blue':
                                                report.feeCalculation.invoiceParameters.vatExemptionReason ===
                                                'smallBusiness',
                                        }"
                                        (click)="setVatRate(0, 'smallBusiness'); saveReport()"
                                        matTooltip="Falls du beim Finanzamt als Kleinunternehmer geführt wirst, wähle diese Option. Kleinunternehmer haben eine vereinfachte Steuer, können aber keine Vorsteuer geltend machen.">
                                        Kleinunternehmer
                                    </a>
                                    <a
                                        mat-menu-item
                                        [ngClass]="{
                                            'color-blue':
                                                report.feeCalculation.invoiceParameters.vatExemptionReason ===
                                                'reverseCharge',
                                        }"
                                        (click)="setVatRate(0, 'reverseCharge'); saveReport()"
                                        matTooltip="Wenn du Rechnungen an Unternehmen im EU-Ausland schreibst, kann du das Reverse-Charge-Verfahren anwenden. Dadurch führt der Rechnungsempfänger die MwSt. nach seinen nationalen Vorgaben ab.">
                                        Reverse Charge
                                    </a>
                                    <a
                                        mat-menu-item
                                        [ngClass]="{
                                            'color-blue':
                                                report.feeCalculation.invoiceParameters.vatExemptionReason ===
                                                'companyInternalInvoice',
                                        }"
                                        (click)="setVatRate(0, 'companyInternalInvoice'); saveReport()"
                                        matTooltip="Wenn du Rechnungen an verbundene Unternehmen schreibst, die z. B. Teil eures Konzerns sind, muss keine MwSt. berechnet werden.">
                                        Innenumsatz Organschaft
                                    </a>
                                </mat-menu>
                            </a>
                            <a
                                mat-menu-item
                                (click)="setVatRate(0.19); saveReport()"
                                [ngClass]="{ 'color-blue': report.feeCalculation.invoiceParameters.vatRate === 0.19 }">
                                19 % MwSt. - Regelsatz Deutschland
                            </a>
                            <a
                                mat-menu-item
                                (click)="setVatRate(0.2); saveReport()"
                                [ngClass]="{ 'color-blue': report.feeCalculation.invoiceParameters.vatRate === 0.2 }">
                                20 % MwSt. - Regelsatz Österreich
                            </a>
                        </mat-menu>
                    </div>
                    <div id="fee-invoice-total-gross">
                        <span
                            matTooltip="Brutto ({{ feesTotalVat | number: '1.2-2' }} € = {{
                                report.feeCalculation.invoiceParameters.vatRate * 100
                            }}% MwSt.)">
                            {{ feesTotalGross | number: '1.2-2' }} €
                        </span>
                    </div>
                </div>
                <!--***********************************************
                ** Note About Missing 0% VAT Reason
                ************************************************-->
                <div
                    class="info-note"
                    *ngIf="
                        report.feeCalculation.invoiceParameters.vatRate === 0 &&
                        !report.feeCalculation.invoiceParameters.vatExemptionReason
                    ">
                    <div class="info-note-text">
                        Grund für 0 % MwSt. muss angegeben werden.
                        <span
                            class="help-indicator"
                            matTooltip="Derzeit ist noch kein Grund für die fehlende MwSt. angegeben. Bitte wähle den Grund aus, um eine gültige E-Rechnung schreiben zu können.">
                            ?
                        </span>
                        <div class="info-note-buttons-row">
                            <div
                                class="info-note-button-primary"
                                (click)="setVatRate(0, 'smallBusiness'); saveReport()"
                                matTooltip="Falls du beim Finanzamt als Kleinunternehmer geführt wirst, wähle diese Option. Kleinunternehmer haben eine vereinfachte Steuer, können aber keine Vorsteuer geltend machen.">
                                Kleinunternehmer
                            </div>
                            <div
                                class="info-note-button-primary"
                                (click)="setVatRate(0, 'reverseCharge'); saveReport()"
                                matTooltip="Wenn du Rechnungen an Unternehmen im EU-Ausland schreibst, kann du das Reverse-Charge-Verfahren anwenden. Dadurch führt der Rechnungsempfänger die MwSt. nach seinen nationalen Vorgaben ab.">
                                Reverse Charge
                            </div>
                            <div
                                class="info-note-button-primary"
                                (click)="setVatRate(0, 'companyInternalInvoice'); saveReport()"
                                matTooltip="Wenn du Rechnungen an verbundene Unternehmen schreibst, die z. B. Teil eures Konzerns sind, muss keine MwSt. berechnet werden.">
                                Innenumsatz Organschaft
                            </div>
                        </div>
                    </div>
                </div>
                <!--***********************************************
                ** END Note About Missing 0% VAT Reason
                ************************************************-->

                <div *ngIf="!report.feeCalculation.isCollectiveInvoice" id="fee-invoice-buttons-container">
                    <!--********** Preview Invoice **********-->
                    <button (click)="previewInvoice()">
                        <img
                            id="invoice-pdf-download-pending-icon"
                            class="pending-icon"
                            *ngIf="reportPdfDownloadPending"
                            src="/assets/images/icons/loading-indicator-white.svg"
                            matTooltip="Der PDF-Download wird vorbereitet..."
                            [matTooltipShowDelay]="300" />
                        Vorschau
                    </button>

                    <!--********** Cancel & Re-book invoice **********-->
                    <button
                        class="secondary"
                        *ngIf="invoiceParamsDifferFromMainInvoice && mainInvoice.lockedAt"
                        (click)="cancelAndRebookMainInvoice()"
                        matTooltip="Klicken, um aktuelle Gutachten-Rechnung zu stornieren & eine neue auszustellen. {{
                            invoiceParamsDifferFromMainInvoiceTooltip
                        }}">
                        <mat-icon id="invoice-params-differ-from-main-invoice-icon" class="warning">warning</mat-icon>
                        Stornieren & neu
                    </button>
                </div>

                <!--============================================-->
                <!-- Skip Writing Invoice Overlay - Invoice Card -->
                <!--============================================-->
                <div
                    class="skip-writing-invoice-overlay"
                    *ngIf="report.feeCalculation.skipWritingInvoice"
                    [ngClass]="{ 'is-firefox': isFirefox() }"
                    @fadeInAndOut>
                    <img src="/assets/images/icons/no-invoice.svg" alt="" />
                    <h3>Ohne Rechnung</h3>
                    <p class="text-align-center">Bei Gutachtenabschluss wird keine Rechnung geschrieben.</p>
                    <div class="link light" (click)="toggleSkipWritingInvoice()" *ngIf="!isReportLocked()">
                        Rechnung aktivieren
                    </div>
                </div>
                <!--============================================-->
                <!-- END Skip Writing Invoice Overlay - Invoice Card -->
                <!--============================================-->
            </div>

            <div id="associated-invoices-container" *ngIf="associatedInvoiceIds.length" class="card">
                <associated-invoice-list-item
                    *ngFor="let associatedInvoiceId of associatedInvoiceIds"
                    [invoiceId]="associatedInvoiceId"></associated-invoice-list-item>
            </div>
        </div>
        <!--============================================-->
        <!-- END Right Column - Total & Associated invoices -->
        <!--============================================-->
    </div>
</div>

<custom-fee-set-dialog
    *ngIf="customFeeSetDialogShown"
    [(customFeeSet)]="customFeeSetForEditDialog"
    [report]="report"
    [team]="team"
    (customFeeSetChange)="saveCustomFeeSet($event); updateFeeTableBox()"
    (close)="closeCustomFeeSetDialog()"></custom-fee-set-dialog>

<!--********** The email needs to be shown in order to complete the recipient address for an XRechnung (electronic invoice) **********-->
<contact-person-editor
    *ngIf="invoiceRecipientContactPersonEditorShown"
    [(contactPerson)]="report.feeCalculation.invoiceParameters.recipient"
    (close)="hideInvoiceRecipientContactPersonEditor()"
    (contactPersonChange)="setInvoiceRecipientRole('custom'); saveReport()"
    [autocompleteGroupNames]="['claimant', 'insurance', 'garage', 'intermediary']"
    [showPhone]="false"
    [hideIban]="true"
    [showEmail]="true"
    [vatFieldsAllowed]="true"
    [showWebsite]="false"></contact-person-editor>

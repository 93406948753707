import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { User } from '@autoixpert/models/user/user';
import { ProfilePictureFileService } from '../../services/profile-picture-file.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: '<user-avatar>',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent {
    constructor(
        private profilePictureFileService: ProfilePictureFileService,
        private userService: UserService,
    ) {}

    // Provide the userId, the component will load the user itself.
    @Input() set userId(_userId: User['_id']) {
        void this.initUser(_userId);
    }

    /**
     * Size of the avatar:
     * - small: 22px
     * - medium: 34px
     * - large: 62px
     * Default: 34
     */
    @Input() size: 20 | 24 | 34 | 50 | 62 = 34;

    /**
     * The tooltip contains the full name of the user. Add a prefix, e.g. 'Verantwortlich: '
     * Default: true
     */
    @Input() showTooltip: boolean = true;
    @Input() tooltipPrefix: string = '';
    /**
     * Display or hide the full component.
     * Default: false.
     */
    @Input() showEmptyState: boolean = false;

    /**
     * Local caching and change detection for the image.
     */
    profilePictureUrl: SafeUrl;
    profilePictureUrlIsPending: boolean = false;

    public tooltip: string = '';

    // Cache the user to trigger reloading the user profile picture if the user changes.
    public user: User;

    private userChangeSubscription: Subscription;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    private async initUser(userId: User['_id']) {
        if (userId) {
            this.user = this.userService.getTeamMemberFromCache(userId) || (await this.userService.get(userId));
        } else {
            this.user = undefined;
        }

        this.generateTooltip();

        // In case the profile picture of a user changes, reload it.
        this.subscribeToUserChanges();
        await this.loadProfilePictureUrl();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltip
    //****************************************************************************/
    /**
     *  Its useful for most use-cases to display the name of the user as a tooltip.
     * In some cases, a prefix is useful (e.g. 'Verantwortlich: Max Mustermann').
     * In other cases (e.g. realtime edit indicators) the parent component may set a tooltip.
     */
    private generateTooltip(): string {
        if (!this.showTooltip) {
            return '';
        }
        if (!this.user) {
            return '';
        }
        return `${this.tooltipPrefix}${this.user.firstName || ''} ${this.user.lastName || ''}`.trim();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltip
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load the profilePictureUrl
    //****************************************************************************/
    /**
     * This function loads the profile picture from the service and sets the URL for the component.
     *
     * Usually, the profile picture is already available in the profilePictureFileService cache.
     * In this case, the function is synchronous and could be run directly from the template.
     * If the profilePicture is not available in the cache, the function is asynchronous.
     * We save the URL in the component to have better control over the UI.
     */
    async loadProfilePictureUrl() {
        this.profilePictureUrlIsPending = true;

        // Retrieve the profile picture only if the user has a hash
        if (!this.user?.profilePictureHash) {
            this.profilePictureUrl = undefined;
        } else {
            try {
                this.profilePictureUrl = await this.profilePictureFileService.getProfilePictureUrl({
                    userId: this.user._id,
                    profilePictureHash: this.user.profilePictureHash,
                });
            } catch (error) {
                this.profilePictureUrl = undefined;

                //                 this.apiErrorService.handleAndRethrow({
                //                     axError: error,
                //                     handlers: {},
                //                     defaultHandler: {
                //                         title: 'Profilbild nicht geladen',
                //                         body: `Das Profilbild von ${this.user.firstName} ${this.user.lastName} (${this.user.email}) konnte nicht geladen werden.
                //
                // Lade die Seite neu. Falls das Problem erneut auftritt, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                //                     },
                //                 });
            } finally {
                this.profilePictureUrlIsPending = false;
            }
        }
    }

    private subscribeToUserChanges() {
        /**
         * If the user changes after this component has previously been initialized,
         * we need to unsubscribe from the previous subscriptions to avoid memory leaks.
         */
        if (this.userChangeSubscription) {
            this.userChangeSubscription.unsubscribe();
        }

        this.userChangeSubscription = this.userService.patchedInLocalDatabase$.subscribe(({ patchedRecord }) => {
            if (patchedRecord._id === this.user?._id) {
                this.user = patchedRecord;
                this.generateTooltip();
                void this.loadProfilePictureUrl();
            }
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load the profilePictureUrl
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.userChangeSubscription?.unsubscribe();
    }
}

export const exampleReport = {
    Sachverständiger: {
        Brieffenster: 'Funktionale Accounts\naX Demodaten\nAn der Leibi 6\n89278 Nersingen',
        Anrede: '',
        AnredeDekliniert: '',
        Briefanrede: 'Sehr geehrter Herr Demodaten',
        Vorname: 'aX',
        Nachname: 'Demodaten',
        Organisation: 'Funktionale Accounts',
        NameKomplett: '<w:p><w:r><w:t>Funktionale Accounts</w:t><w:br/><w:t>aX Demodaten</w:t></w:r></w:p>',
        Straße: 'An der Leibi 6',
        Postleitzahl: '89278',
        Ort: 'Nersingen',
        Telefon: '',
        Telefon2: '',
        EMail: 'demodaten@autoixpert.de',
        Website: '',
        Standortname: 'Nersingen',
        Standortkürzel: 'NER',
        Stempel: '',
        Logo: '',
        BeruflicheQualifikation: '',
    },
    Bankverbindung: {
        IBAN: null,
        BIC: null,
        Kontoinhaber: null,
        Bank: null,
    },
    ZweiteBankverbindung: {
        IBAN: '',
        BIC: '',
        Kontoinhaber: '',
        Bank: '',
    },
    FactoringProvider: {
        erhältRechnung: false,
        Brieffenster: '\n ',
        Anrede: '',
        AnredeDekliniert: '',
        Briefanrede: 'Sehr geehrte Damen und Herren',
        Vorname: '',
        Nachname: '',
        Organisation: '',
        NameKomplett: '',
        Straße: '',
        Postleitzahl: '',
        Ort: '',
        Telefon: '',
        Telefon2: '',
        EMail: '',
        Website: '',
    },
    Steuern: {
        Steuernummer: '',
        UmsatzsteuerId: '',
        SteuernummerOderUmsatzsteuerId: '',
    },
    Gutachten: {
        Aktenzeichen: 'Gutachten-002',
        Erstellungsdatum: '16.11.2023',
        Fertigstellungsdatum: '16.11.2023',
        Auftragsdatum: '18.11.2020',
        Auftraggeber: 'Anspruchsteller',
        AuftragDurchAnspruchsteller: true,
        ArtDerAuftragserteilung: 'persönlich',
        FahrzeugscheinLagImOriginalVor: true,
        FahrzeugscheinLagInKopieVor: false,
        VINAmFahrzeugÜberprüft: true,
        Typ: 'Haftpflichtschaden',
        AnzahlFotos: 12,
        istTotalschaden: false,
        FotoDeckblatt: 12,
        FotoWerkstattInformation: 12,
        FotoRestwertgebot: 12,
        FotoZustandsbericht: 12,
        FotoGebrauchtwagencheck: 12,
        FotoMinderwertprotokoll: 12,
        Vermittler: '',
        fiktiveAbrechnung: 'unbekannt',
        istNachtrag: false,
        BegründungNachtrag: '',
        istKostenvoranschlag: null,
        EigeneFelder: {},
    },
    Anspruchsteller: {
        Anwalt: {
            Brieffenster: '\n ',
            Anrede: '',
            AnredeDekliniert: '',
            Briefanrede: 'Sehr geehrte Damen und Herren',
            Vorname: '',
            Nachname: '',
            Organisation: '',
            NameKomplett: '',
            Straße: '',
            Postleitzahl: '',
            Ort: '',
            Telefon: '',
            Telefon2: '',
            EMail: '',
            Website: '',
            Aktenzeichen: '',
            Notizen: '',
        },
        istDurchAnwaltVertreten: null,
        Brieffenster: 'Frau\nAnna Marie Meyer\nMusterweg 13/1\n54321 Musterhausen',
        Anrede: 'Frau',
        AnredeDekliniert: 'Frau',
        Briefanrede: 'Sehr geehrte Frau Meyer',
        Vorname: 'Anna Marie',
        Nachname: 'Meyer',
        Organisation: '',
        NameKomplett: 'Anna Marie Meyer',
        Straße: 'Musterweg 13/1',
        Postleitzahl: '54321',
        Ort: 'Musterhausen',
        Telefon: '08222-9188990',
        Telefon2: '',
        EMail: 'anna-marie@mustermayer.com',
        Website: '',
        IBAN: '',
        Kennzeichen: 'MH MM 1970',
        KennzeichenDocx: 'MH:MM 1970',
        istVorsteuerabzugsberechtigt: 'unbekannt',
        UmsatzsteuerId: null,
        istFahrzeughalter: true,
        Typ: 'Anspruchsteller',
    },
    Fahrzeughalter: {
        Brieffenster: 'Frau\nAnna Marie Meyer\nMusterweg 13/1\n54321 Musterhausen',
        Anrede: 'Frau',
        AnredeDekliniert: 'Frau',
        Briefanrede: 'Sehr geehrte Frau Meyer',
        Vorname: 'Anna Marie',
        Nachname: 'Meyer',
        Organisation: '',
        NameKomplett: 'Anna Marie Meyer',
        Straße: 'Musterweg 13/1',
        Postleitzahl: '54321',
        Ort: 'Musterhausen',
        Telefon: '08222-9188990',
        Telefon2: '',
        EMail: 'anna-marie@mustermayer.com',
        Website: '',
        istVorsteuerabzugsberechtigt: 'unbekannt',
        UmsatzsteuerId: null,
    },
    Auftraggeber: {
        Brieffenster: 'Frau\nAnna Marie Meyer\nMusterweg 13/1\n54321 Musterhausen',
        Anrede: 'Frau',
        AnredeDekliniert: 'Frau',
        Briefanrede: 'Sehr geehrte Frau Meyer',
        Vorname: 'Anna Marie',
        Nachname: 'Meyer',
        Organisation: '',
        NameKomplett: 'Anna Marie Meyer',
        Straße: 'Musterweg 13/1',
        Postleitzahl: '54321',
        Ort: 'Musterhausen',
        Telefon: '08222-9188990',
        Telefon2: '',
        EMail: 'anna-marie@mustermayer.com',
        Website: '',
        istVorsteuerabzugsberechtigt: 'unbekannt',
    },
    Verkäufer: null,
    Unfallgegner: {
        Versicherung: {
            Versicherungsnummer: '31-1323123767',
            Schadennummer: 'KH-2018-GG/132456790/1',
            SchadennummerOderVersicherungsnummer: 'KH-2018-GG/132456790/1',
            Selbstbeteiligung: 0,
            Brieffenster: 'Cosmos Versicherung\nHalbergstraße 50-60\n66121 Saarbrücken',
            Anrede: '',
            AnredeDekliniert: '',
            Briefanrede: 'Sehr geehrte Damen und Herren',
            Vorname: '',
            Nachname: '',
            Organisation: 'Cosmos Versicherung',
            NameKomplett: 'Cosmos Versicherung',
            Straße: 'Halbergstraße 50-60',
            Postleitzahl: '66121',
            Ort: 'Saarbrücken',
            Telefon: '06819-666800',
            Telefon2: '',
            EMail: 'info@cosmosdirekt.de',
            Website: 'http://www.cosmosdirekt.de',
        },
        Kennzeichen: 'HDH LB 87',
        KennzeichenDocx: 'HDH:LB 87',
        istFahrzeughalter: true,
        Brieffenster: 'Herrn\nLeon Baumann\nKönigsberger Str. 21\n89564 Nattheim',
        Anrede: 'Herr',
        AnredeDekliniert: 'Herrn',
        Briefanrede: 'Sehr geehrter Herr Baumann',
        Vorname: 'Leon',
        Nachname: 'Baumann',
        Organisation: '',
        NameKomplett: 'Leon Baumann',
        Straße: 'Königsberger Str. 21',
        Postleitzahl: '89564',
        Ort: 'Nattheim',
        Telefon: '07323-12345',
        Telefon2: '',
        EMail: '',
        Website: '',
    },
    FahrzeughalterUnfallgegner: {
        Brieffenster: 'Herrn\nLeon Baumann\nKönigsberger Str. 21\n89564 Nattheim',
        Anrede: 'Herr',
        AnredeDekliniert: 'Herrn',
        Briefanrede: 'Sehr geehrter Herr Baumann',
        Vorname: 'Leon',
        Nachname: 'Baumann',
        Organisation: '',
        NameKomplett: 'Leon Baumann',
        Straße: 'Königsberger Str. 21',
        Postleitzahl: '89564',
        Ort: 'Nattheim',
        Telefon: '07323-12345',
        Telefon2: '',
        EMail: '',
        Website: '',
    },
    Leasinggeber: null,
    Werkstatt: {
        Brieffenster: 'BMW Musterwerkstatt GmbH & Co. KG\nBlautal Str. 109-111\n89077 Ulm',
        Anrede: '',
        AnredeDekliniert: '',
        Briefanrede: 'Sehr geehrte Damen und Herren',
        Vorname: '',
        Nachname: '',
        Organisation: 'BMW Musterwerkstatt GmbH & Co. KG',
        NameKomplett: 'BMW Musterwerkstatt GmbH & Co. KG',
        Straße: 'Blautal Str. 109-111',
        Postleitzahl: '89077',
        Ort: 'Ulm',
        Telefon: '0731-7654321',
        Telefon2: '',
        EMail: 'info@bmw-musterwerkstatt.de',
        Website: 'www.bmw-musterwerkstatt.de',
        istMarkenwerkstatt: null,
        Kostensätze: {
            EinheitArbeitswert: 'Stunde',
            EinheitArbeitswertPlural: 'Stunden',
            Mechanik1ProArbeitswert: '139,00 €',
            Mechanik2ProArbeitswert: 0,
            Mechanik3ProArbeitswert: 0,
            Elektrik1ProArbeitswert: '139,00 €',
            Elektrik2ProArbeitswert: 0,
            Elektrik3ProArbeitswert: 0,
            Karosserie1ProArbeitswert: '143,00 €',
            Karosserie2ProArbeitswert: 0,
            Karosserie3ProArbeitswert: 0,
            LackProArbeitswert: '145,00 €',
            DellenProArbeitswert: '145,00 €',
            Mechanik1: '139,00 €',
            Mechanik2: 0,
            Mechanik3: 0,
            Elektrik1: '139,00 €',
            Elektrik2: 0,
            Elektrik3: 0,
            Karosserie1: '143,00 €',
            Karosserie2: 0,
            Karosserie3: 0,
            Lack: '145,00 €',
            Lacksystem: 'AZT',
            Lackmaterial: '38 %',
            LackmaterialEinheit: '%',
            Dellen: '145,00 €',
            Verbringung: false,
            VerbringungJaNein: 'Nein',
            Verbringungskosten: 0,
            MaterialzuschlagUPE: '15 %',
            KleinUndVerbrauchsmaterial: '2',
            KleinUndVerbrauchsmaterialEinheit: '%',
        },
    },
    Fahrzeug: {
        VIN: 'WBAUB310X0VH51444',
        Hersteller: 'BMW',
        HerstellerLogo: 'bmw',
        HerstellerLogoExistiert: true,
        HerstellerLogoGroß: 'bmw',
        Modell: 'Baureihe 1 Lim. (E81/E87)',
        Untertyp: '118d',
        Art: 'Kompaktwagen',
        Karosserie: 'Kompaktwagen',
        KarosserieMitReifen: 'compact',
        Anstosszeichnung: 'compact',
        istMotorrad: false,
        istAnhänger: false,
        Schäden: [
            {
                Titel: 'Fahrertür',
                Beschreibung: '',
            },
            {
                Titel: 'Kotflügel links',
                Beschreibung: '',
            },
        ],
        Schadenbeschreibung: '<p>Der Kotflügel links sowie die Fahrertür sind stark eingedellt und zerkratzt.</p>',
        Schlüsselnummer: '0005/AHW,0005/ARO',
        Antriebsart: 'Diesel',
        Getriebe: '6-Gang manuell',
        Leistung: '105 KW',
        AnzahlZylinder: '',
        Motorbauart: '',
        Hubraum: '1.995 ccm',
        Leermasse: '',
        ZulässigeGesamtmasse: '',
        Länge: '',
        Breite: '',
        Höhe: '',
        Radstand: '',
        AnzahlAchsen: 2,
        AnzahlAngetriebeneAchsen: 1,
        Sitzplätze: 4,
        AnzahlTüren: 3,
        Baujahr: '2008',
        AlterInJahrenLautBaujahr: 16,
        AlterInJahrenLautErstzulassung: 15,
        Erstzulassung: '29.05.2008',
        letzteZulassung: '12.10.2016',
        nächsteHU: '07.2021',
        nächsteSP: '',
        LaufleistungAbgelesen: '91.234 km',
        LaufleistungGeschätzt: '91.234 km',
        LaufleistungAngegeben: '',
        LaufleistungKommentar: '',
        AnzahlVorbesitzer: '1',
        Bemerkungen: '',
        Besonderheiten: '',
        Lack: {
            Farbe: 'SCHWARZ 2',
            Farbcode: '',
            Art: 'Uni (2-Schicht)',
            Zustand: 'gut, gepflegt',
        },
        Allgemeinzustand: 'normal',
        Karosseriezustand: 'normal',
        Innenraumzustand: 'dem Alter entsprechend',
        Fahrwerkszustand: '',
        istProbefahrtDurchgeführt: null,
        istFehlerspeicherAusgelesen: null,
        Airbags: {
            ausgelöst: false,
            Kommentar: null,
        },
        Reifen: [
            {
                Achse: 1,
                Dimension: '195/55R16',
                Hersteller: 'Continental',
                Position: 'Links',
                Profil: '7 mm',
                Art: 'Winter',
                Bemerkung: '',
            },
            {
                Achse: 1,
                Dimension: '195/55R16',
                Hersteller: 'Continental',
                Position: 'Rechts',
                Profil: '7 mm',
                Art: 'Winter',
                Bemerkung: '',
            },
            {
                Achse: 2,
                Dimension: '195/55R16',
                Hersteller: 'Continental',
                Position: 'Links',
                Profil: '4 mm',
                Art: 'Winter',
                Bemerkung: '',
            },
            {
                Achse: 2,
                Dimension: '195/55R16',
                Hersteller: 'Continental',
                Position: 'Rechts',
                Profil: '4 mm',
                Art: 'Winter',
                Bemerkung: '',
            },
        ],
        istReifenKommentarVorhanden: false,
        istZwillingsbereifungVorhanden: false,
        Achsen: [
            {
                AchsenNummer: 1,
                VorneOderHinten: 'vorne',
                Achslast: null,
                ReifenLinks: {
                    Achse: 1,
                    Dimension: '195/55R16',
                    Hersteller: 'Continental',
                    Position: 'Links',
                    Profil: '7 mm',
                    Art: 'Winter',
                    Bemerkung: '',
                },
                ReifenRechts: {
                    Achse: 1,
                    Dimension: '195/55R16',
                    Hersteller: 'Continental',
                    Position: 'Rechts',
                    Profil: '7 mm',
                    Art: 'Winter',
                    Bemerkung: '',
                },
            },
            {
                AchsenNummer: 2,
                VorneOderHinten: 'hinten',
                Achslast: null,
                ReifenLinks: {
                    Achse: 2,
                    Dimension: '195/55R16',
                    Hersteller: 'Continental',
                    Position: 'Links',
                    Profil: '4 mm',
                    Art: 'Winter',
                    Bemerkung: '',
                },
                ReifenRechts: {
                    Achse: 2,
                    Dimension: '195/55R16',
                    Hersteller: 'Continental',
                    Position: 'Rechts',
                    Profil: '4 mm',
                    Art: 'Winter',
                    Bemerkung: '',
                },
            },
        ],
        Notbereifung: {
            istReparaturkitVorhanden: false,
            istNotradVorhanden: false,
            Ersatzrad: null,
            Kommentar: '',
        },
        istScheckheftGepflegt: 'unbekannt',
        letzterService: {
            Datum: '',
            Laufleistung: '',
        },
        Vorschäden:
            '<p>Mit einem Lackschichtdickenmessgerät wurde festgestellt, dass das Fahrzeug teilweise nachlackiert ist.</p>',
        Altschäden: '',
        Nachschäden: '',
        Fahrfähigkeit: 'verkehrssicher',
        Schadstoffgruppe: 'Gruppe 4',
        SchadstoffgruppeFarbe: 'Grün',
        SchadstoffgruppeBild: 4,
        Abgasnorm: '',
        Ausstattung: {
            Serienausstattung: [],
            SerienausstattungSpalteA: [],
            SerienausstattungSpalteB: [],
            Sonderausstattung: [],
            SonderausstattungSpalteA: [],
            SonderausstattungSpalteB: [],
            Zusatzausstattung: [],
            ZusatzausstattungSpalteA: [],
            ZusatzausstattungSpalteB: [],
        },
    },
    Unfall: {
        Ort: 'B10 Abfahrt Musterhausen',
        Datum: '17.11.2020',
        Uhrzeit: '14:30 Uhr',
        Schadenhergang:
            '<p>Zum Schadenhergang sind Einzelheiten nicht  bekannt geworden. Insoweit wird auf die Schadenunterlagen verwiesen.</p>',
        Plausibilität:
            '<p>Eine Stellungnahme dazu, ob die im Gutachten beschriebenen Schäden dem geschilderten Unfallereignis zuzuordnen und plausibel sind, kann nicht angegeben werden. Soweit erkennbar, sind die festgestellten Schäden auf ein einzelnes Schadenereignis zurückzuführen.</p>',
        Kompatibilität: '',
        polizeilichErfasst: 'Ja',
        AktenzeichenPolizei: 'BY-181120-545352',
        Polizeibehörde: '',
        KaskoSchadenart: '',
    },
    Besichtigungen: [
        {
            BesichtigungBei: 'Anna Marie Meyer (Anspruchsteller)',
            Straße: 'Pfaffenweg 3',
            Postleitzahl: '89231',
            Ort: 'Neu-Ulm',
            Datum: '18.11.2020',
            Uhrzeit: '09:30 Uhr',
            anwesenderSachverständiger: 'aX Demodaten',
            Anwesende: 'Sachverständiger, Anspruchsteller',
            Fahrzeugzustand: 'unzerlegt',
            istÄnderungSeitUnfallErsichtlich: false,
            warSachverständigerAnwesend: true,
            warAnspruchstellerAnwesend: true,
            warMechanikerAnwesend: null,
            warFahrzeughalterAnwesend: null,
            andereAnwesende: '',
            Bedingungen: 'ausreichend',
            Hilfsmittel: 'Hebebühne, Messlatte, Spaltmesskeil',
            Nummer: 1,
        },
    ],
    ErsteBesichtigung: {
        BesichtigungBei: 'Anna Marie Meyer (Anspruchsteller)',
        Straße: 'Pfaffenweg 3',
        Postleitzahl: '89231',
        Ort: 'Neu-Ulm',
        Datum: '18.11.2020',
        Uhrzeit: '09:30 Uhr',
        anwesenderSachverständiger: 'aX Demodaten',
        Anwesende: 'Sachverständiger, Anspruchsteller',
        Fahrzeugzustand: 'unzerlegt',
        istÄnderungSeitUnfallErsichtlich: false,
        warSachverständigerAnwesend: true,
        warAnspruchstellerAnwesend: true,
        warMechanikerAnwesend: null,
        warFahrzeughalterAnwesend: null,
        andereAnwesende: '',
        Bedingungen: 'ausreichend',
        Hilfsmittel: 'Hebebühne, Messlatte, Spaltmesskeil',
        Nummer: 1,
    },
    LetzteBesichtigung: {
        BesichtigungBei: 'Anna Marie Meyer (Anspruchsteller)',
        Straße: 'Pfaffenweg 3',
        Postleitzahl: '89231',
        Ort: 'Neu-Ulm',
        Datum: '18.11.2020',
        Uhrzeit: '09:30 Uhr',
        anwesenderSachverständiger: 'aX Demodaten',
        Anwesende: 'Sachverständiger, Anspruchsteller',
        Fahrzeugzustand: 'unzerlegt',
        istÄnderungSeitUnfallErsichtlich: false,
        warSachverständigerAnwesend: true,
        warAnspruchstellerAnwesend: true,
        warMechanikerAnwesend: null,
        warFahrzeughalterAnwesend: null,
        andereAnwesende: '',
        Bedingungen: 'ausreichend',
        Hilfsmittel: 'Hebebühne, Messlatte, Spaltmesskeil',
        Nummer: 1,
    },
    AnzahlBesichtigungen: 1,
    Schadenskalkulation: {
        GesamtNetto: '200,00 €',
        MwSt: '0,00 €',
        MwStProzent: '19 %',
        GesamtBrutto: '200,00 €',
        Minderwert: {
            Wert: '200,00 €',
            MethodenAnzahl: 0,
            MethodenNamen: '',
            Neupreis: '35.000,00 €',
            AlterInMonaten: 125,
            Laufleistung: '91.234 km',
            ReparaturkostenErheblich: '2.000,00 €',
            ReparaturkostenGesamt: '2.780,60 €',
            Wiederbeschaffungswert: '10.500,00 €',
            Veräußerungswert: '10.500,00 €',
            MarktgängigkeitBvsk: 0,
            MarktgängigkeitMfm: '1,0',
            VorschadenBvsk: '0,8',
            VorschadenMfm: '0,8',
            LohnOhneLack: '1.404,71 €',
            Ersatzteile: '1.375,89 €',
            SchadenumfangMfm: '0,4',
            SchadensklasseBvsk: '1,5 %',
            Kommentar: '',
            BVSK: '126,00 €',
            RuhkopfSahm: '0,00 €',
            DVGT: '0,00 €',
            Halbgewachs: '0,00 €',
            HamburgerModell: '0,00 €',
            MFM: '84,00 €',
            Durchschnitt: '0,00 €',
            AlterskorrekturMfm: '0,000',
            XWertRuhkopfSahm: '3 %',
            XWertHalbgewachs: '-13 %',
            ReparaturZuWiederbeschaffungswert: '26,5 %',
        },
        TechnischerMinderwert: {
            Wert: '',
            Kommentar: '',
        },
        Wertverbesserung: {
            Wert: '',
            Kommentar: '',
        },
        Quelle: null,
        istPhantomkalkulation: false,
        istÜberschlägigeKalkulation: false,
        istEinfacheSchätzung: false,
        Schadensklasse: 'Reparaturschaden',
        Reparatur: {
            ReparaturauftragErteilt: 'unbekannt',
            mitDekraSätzen: false,
            BeilackierungErforderlich: false,
            BeilackierungKommentar: '',
            AchsvermessungKommentar: '',
            KarosserievermessungKommentar: '',
            InstandsetzungKunststoffteileErforderlich: false,
            KostenNetto: 0,
            KostenMwSt: 0,
            KostenBrutto: 0,
            ErsatzteilkostenNetto: '- €',
            ErsatzteilkostenBrutto: '0,00 €',
            ArbeitslohnkostenNetto: '- €',
            ArbeitslohnkostenBrutto: '0,00 €',
            LackierungskostenNetto: '- €',
            LackierungskostenBrutto: '0,00 €',
            NebenkostenNetto: '- €',
            NebenkostenBrutto: '0,00 €',
            NeuFürAltNetto: 0,
            NeuFürAltMwSt: 0,
            NeuFürAltBrutto: 0,
            RabattNetto: 0,
            RabattMwSt: 0,
            RabattBrutto: 0,
            KorrigierteKostenNetto: 0,
            KorrigierteKostenMwSt: 0,
            KorrigierteKostenBrutto: 0,
            DauerInArbeitstagen: '3',
            DauerEinheit: 'Arbeitstage',
            Weg: '',
            AnzahlRisiken: 0,
            Risiken: '',
            RisikenKommentar: '',
            KalkulationKommentar: '',
        },
        ÜberschlägigeKalkulation: {
            ErsatzteileKosten: 0,
            ArbeitslohnKosten: 0,
            LackierungKosten: 0,
            Nebenkosten: 0,
            RabatteUndVergütungNetto: 0,
            RabatteUndVergütungBrutto: 0,
            RabatteUndVergütungMwSt: 0,
        },
        ManuelleKalkulation: {
            Datum: '',
            Positionen: {
                Ersatzteile: [],
                Nebenkosten: [],
                Arbeitslohn: [],
                Lackierung: [],
                Abzüge: [],
            },
            Summen: {
                Ersatzteile: {
                    Zwischensumme: '0,00 €',
                    KleinUndVerbrauchsmaterial: '0,00 €',
                    Summe: 0,
                },
                Nebenkosten: {
                    Summe: 0,
                },
                Arbeitslohn: {
                    Mechanik1StundenOderAWs: 0,
                    Mechanik2StundenOderAWs: 0,
                    Mechanik3StundenOderAWs: 0,
                    Elektrik1StundenOderAWs: 0,
                    Elektrik2StundenOderAWs: 0,
                    Elektrik3StundenOderAWs: 0,
                    Karosserie1StundenOderAWs: 0,
                    Karosserie2StundenOderAWs: 0,
                    Karosserie3StundenOderAWs: 0,
                    DellenStundenOderAWs: 0,
                    Mechanik1: 0,
                    Mechanik2: 0,
                    Mechanik3: 0,
                    Elektrik1: 0,
                    Elektrik2: 0,
                    Elektrik3: 0,
                    Karosserie1: 0,
                    Karosserie2: 0,
                    Karosserie3: 0,
                    Dellen: 0,
                    StundenOderAWs: 0,
                    Summe: 0,
                },
                Lackierung: {
                    StundenOderAWs: 0,
                    Arbeitslohn: 0,
                    Materialaufschlag: 0,
                    Summe: 0,
                },
            },
        },
        Restkraftstoff: {
            Menge: '',
            PreisProLiter: '',
            Kosten: '',
        },
        Umbaukosten: null,
        Entsorgungskosten: '',
        AbUndAnmeldegebühren: '',
        WeitereKosten: {
            Summe: '',
            Positionen: [],
        },
        '130ProzentGrenzeNetto': '8.213,40 €',
        '130ProzentGrenzeBrutto': '8.213,40 €',
        '130ProzentGrenzeNachVorsteuerabzugsfähigkeit': '8.213,40 €',
        DocumentHash: null,
    },
    Notreparatur: {
        Status: '',
        KostenBrutto: '',
        KostenBruttoCirca: null,
        Kommentar: '',
        Arbeiten: '',
    },
    Bewertung: {
        FahrzeugwertNetto: '6.318,00 €',
        FahrzeugwertBrutto: '6.318,00 €',
        FahrzeugwertMwSt: '0,00 €',
        FahrzeugwertBezeichnung: 'Wiederbeschaffungswert',
        Besteuerungsart: 'steuerneutral',
        Quelle: null,
        FahrzeugwertMinusReparaturkostenNetto: 0,
        FahrzeugwertMinusReparaturkostenBrutto: 0,
        FahrzeugwertMinusReparaturkostenMwSt: 0,
        DatValuationDocumentHash: null,
        AudatexValuationDocumentHash: null,
        Stichtag: '28.02.2019',
    },
    Zustandsbericht: null,
    Rechnungsprüfung: null,
    Restwert: {
        KostenBrutto: '4.800,00 €',
        KostenNetto: '4.033,61 €',
        Kommentar: null,
        AnzahlGebote: 2,
        AnzahlRegionaleGebote: 2,
        AnzahlÜberregionaleGebote: 0,
        AnzahlAusgewählteGebote: 2,
        wurdeInseratErstellt: true,
        sindAlleInserateAbgelaufen: true,
        KeineGeboteTrotzAnfrage: false,
        Gebote: [
            {
                Bieter: {
                    Brieffenster: 'Musterbieter A\nBernd Beispiel\nHauptstr. 17\n89278 Nersingen',
                    Anrede: '',
                    AnredeDekliniert: '',
                    Briefanrede: 'Sehr geehrter Herr Bernd Beispiel',
                    Vorname: '',
                    Nachname: 'Bernd Beispiel',
                    Organisation: 'Musterbieter A',
                    NameKomplett: 'Musterbieter A\nBernd Beispiel',
                    Straße: 'Hauptstr. 17',
                    Postleitzahl: '89278',
                    Ort: 'Nersingen',
                    Telefon: '0731 123456',
                    Telefon2: '',
                    EMail: 'info@ah-beispiel.de',
                    Website: '',
                    NameAdresse: 'Musterbieter A\nBernd Beispiel\nHauptstr. 17\n89278 Nersingen',
                    Kontaktdaten: 'info@ah-beispiel.de\n0731 123456',
                },
                Gebot: {
                    ID: 'AX-oKr3CgwYT',
                    Wert: '4.800,00 €',
                    Bindefrist: '28.11.2019',
                    Datum: '',
                    Quelle: 'eigene',
                    Nummer: 1,
                },
            },
            {
                Bieter: {
                    Brieffenster: 'Musterbieter B\nMichaela Muster\nBahnhofstr. 11\n89231 Neu-Ulm',
                    Anrede: '',
                    AnredeDekliniert: '',
                    Briefanrede: 'Sehr geehrter Herr Michaela Muster',
                    Vorname: '',
                    Nachname: 'Michaela Muster',
                    Organisation: 'Musterbieter B',
                    NameKomplett: 'Musterbieter B\nMichaela Muster',
                    Straße: 'Bahnhofstr. 11',
                    Postleitzahl: '89231',
                    Ort: 'Neu-Ulm',
                    Telefon: '0731 456789',
                    Telefon2: '',
                    EMail: 'michaela-muster@gmail.com',
                    Website: '',
                    NameAdresse: 'Musterbieter B\nMichaela Muster\nBahnhofstr. 11\n89231 Neu-Ulm',
                    Kontaktdaten: 'michaela-muster@gmail.com\n0731 456789',
                },
                Gebot: {
                    ID: 'AX-mfmDRtO9d',
                    Wert: '4.699,00 €',
                    Bindefrist: '24.11.2019',
                    Datum: '',
                    Quelle: 'eigene',
                    Nummer: 2,
                },
            },
        ],
        HöchstesAusgewähltesGebot: {
            Bieter: {
                Brieffenster: 'Musterbieter A\nBernd Beispiel\nHauptstr. 17\n89278 Nersingen',
                Anrede: '',
                AnredeDekliniert: '',
                Briefanrede: 'Sehr geehrter Herr Bernd Beispiel',
                Vorname: '',
                Nachname: 'Bernd Beispiel',
                Organisation: 'Musterbieter A',
                NameKomplett: 'Musterbieter A\nBernd Beispiel',
                Straße: 'Hauptstr. 17',
                Postleitzahl: '89278',
                Ort: 'Nersingen',
                Telefon: '0731 123456',
                Telefon2: '',
                EMail: 'info@ah-beispiel.de',
                Website: '',
                NameAdresse: 'Musterbieter A\nBernd Beispiel\nHauptstr. 17\n89278 Nersingen',
                Kontaktdaten: 'info@ah-beispiel.de\n0731 123456',
            },
            Gebot: {
                ID: 'AX-oKr3CgwYT',
                Wert: '4.800,00 €',
                Bindefrist: '28.11.2019',
                Datum: '',
                Quelle: 'eigene',
                Nummer: 1,
            },
        },
        WeitereAusgewählteGebote: [
            {
                Bieter: {
                    Brieffenster: 'Musterbieter B\nMichaela Muster\nBahnhofstr. 11\n89231 Neu-Ulm',
                    Anrede: '',
                    AnredeDekliniert: '',
                    Briefanrede: 'Sehr geehrter Herr Michaela Muster',
                    Vorname: '',
                    Nachname: 'Michaela Muster',
                    Organisation: 'Musterbieter B',
                    NameKomplett: 'Musterbieter B\nMichaela Muster',
                    Straße: 'Bahnhofstr. 11',
                    Postleitzahl: '89231',
                    Ort: 'Neu-Ulm',
                    Telefon: '0731 456789',
                    Telefon2: '',
                    EMail: 'michaela-muster@gmail.com',
                    Website: '',
                    NameAdresse: 'Musterbieter B\nMichaela Muster\nBahnhofstr. 11\n89231 Neu-Ulm',
                    Kontaktdaten: 'michaela-muster@gmail.com\n0731 456789',
                },
                Gebot: {
                    ID: 'AX-mfmDRtO9d',
                    Wert: '4.699,00 €',
                    Bindefrist: '24.11.2019',
                    Datum: '',
                    Quelle: 'eigene',
                    Nummer: 2,
                },
            },
        ],
        NurRegionaleGebote: false,
    },
    Wiederbeschaffung: {
        Besteuerungsart: 'steuerneutral',
        SteuerbetragAbsolut: 0,
        SteuerbetragProzentual: '0%',
        KostenNetto: '6.318,00 €',
        KostenBrutto: '6.318,00 €',
        DauerInKalendertagen: '',
        DauerEinheit: 'Kalendertage',
        Kommentar: '',
        CartvKorridorAbdrucken: true,
        CartvSuchradius: '',
        ValuepilotKorridorAbdrucken: true,
        ValuepilotSuchradius: '',
        WinValueKorridorAbdrucken: true,
        WinValueSuchradius: '',
        DatKorridorAbdrucken: true,
        AudatexKorridorAbdrucken: true,
        EigeneMarktwertanalysen: [],
        AnzahlMarktwertanalysen: 0,
    },
    Wiederbeschaffungsaufwand: '1.518,00 €',
    WiederherstellungsaufwandDurchWiederbeschaffungswertProzent: '3',
    Nutzungsausfall: {
        Art: 'Ausfalldauer (Reparatur)',
        Entschädigungsgruppe: 'D',
        EntschädigungProTag: '38,00 €',
        Dauer: '3',
        DauerEinheit: 'Arbeitstage',
        Mietwagenklasse: 6,
        MietwagenklasseName: 'Mittelklasse +',
        MietwagenklasseKosten: '',
        Kommentar: '',
    },
    Vorhaltekosten: {
        aktiviert: false,
        KostenNetto: 0,
    },
    Honorar: {
        BetragNetto: '294,00 €',
        BetragBrutto: '349,86 €',
        Fotokosten: 'NaN €',
        Fahrtkosten: '25,00 €',
        Schreibkosten: '10,00 €',
        SonstigeKosten: '7,50 €',
        HonorartabelleName: 'BVSK',
        Jahr: '2020',
        Grundlage: '200,00 €',
    },
    Abtretung: {
        Datum: '',
    },
    Fotos: [
        {
            Fotonummer: 1,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Ansicht vorne links</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'clE3gzG8K',
        },
        {
            Fotonummer: 2,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Ansicht hinten rechts</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'D5EQnrek9P',
        },
        {
            Fotonummer: 3,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Ansicht vorne rechts</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'ZqUöHdr56L',
        },
        {
            Fotonummer: 4,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Ansicht hinten links</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'bZcdTpsFlä',
        },
        {
            Fotonummer: 5,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Innenraum</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'UvSiHkVTT2',
        },
        {
            Fotonummer: 6,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>KM-Stand</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'IWudjvmmGC',
        },
        {
            Fotonummer: 7,
            AllgemeineFotobeschreibung: '',
            DetaillierteFotobeschreibungen: [],
            Foto: 'YnNXsjUjy7',
        },
        {
            Fotonummer: 8,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Fahrzeugschein</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'AhD7VwNPcQ',
        },
        {
            Fotonummer: 9,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Schadenübersicht</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'cG0Cs3vxRc',
        },
        {
            Fotonummer: 10,
            AllgemeineFotobeschreibung: '',
            DetaillierteFotobeschreibungen: [
                {
                    Fotobeschreibungsnummer: '1|#15a9e8',
                    Fotobeschreibung: '<p>Der Kotflügel ist stark verschrammt und deformiert.</p>',
                },
                {
                    Fotobeschreibungsnummer: '2|#15a9e8',
                    Fotobeschreibung: '<p>Die Fahrertür ist eingedrückt.</p>',
                },
            ],
            Foto: 'bUwRC8rqwd',
        },
        {
            Fotonummer: 11,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Detailaufnahme mit dem Dellenreflektor.</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'Gm8IjpkuöO',
        },
        {
            Fotonummer: 12,
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Detailaufnahme</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Foto: 'öä8döYXbn1',
        },
    ],
    FotosSpalteA: [
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Ansicht vorne links</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 1,
            FotoZweispaltig: 'clE3gzG8K',
        },
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Ansicht vorne rechts</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 3,
            FotoZweispaltig: 'ZqUöHdr56L',
        },
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Innenraum</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 5,
            FotoZweispaltig: 'UvSiHkVTT2',
        },
        {
            AllgemeineFotobeschreibung: '',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 7,
            FotoZweispaltig: 'YnNXsjUjy7',
        },
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Schadenübersicht</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 9,
            FotoZweispaltig: 'cG0Cs3vxRc',
        },
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Detailaufnahme mit dem Dellenreflektor.</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 11,
            FotoZweispaltig: 'Gm8IjpkuöO',
        },
    ],
    FotosSpalteB: [
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Ansicht hinten rechts</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 2,
            FotoZweispaltig: 'D5EQnrek9P',
        },
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Ansicht hinten links</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 4,
            FotoZweispaltig: 'bZcdTpsFlä',
        },
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>KM-Stand</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 6,
            FotoZweispaltig: 'IWudjvmmGC',
        },
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Fahrzeugschein</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 8,
            FotoZweispaltig: 'AhD7VwNPcQ',
        },
        {
            AllgemeineFotobeschreibung: '',
            DetaillierteFotobeschreibungen: [
                {
                    Fotobeschreibungsnummer: '1|#15a9e8',
                    Fotobeschreibung: '<p>Der Kotflügel ist stark verschrammt und deformiert.</p>',
                },
                {
                    Fotobeschreibungsnummer: '2|#15a9e8',
                    Fotobeschreibung: '<p>Die Fahrertür ist eingedrückt.</p>',
                },
            ],
            Fotonummer: 10,
            FotoZweispaltig: 'bUwRC8rqwd',
        },
        {
            AllgemeineFotobeschreibung:
                '<w:p><w:pPr><w:pStyle w:val="FotoFlietext"/><w:keepLines/></w:pPr><w:r><w:t>Detailaufnahme</w:t></w:r></w:p>',
            DetaillierteFotobeschreibungen: [],
            Fotonummer: 12,
            FotoZweispaltig: 'öä8döYXbn1',
        },
    ],
    sindFotosInPrüfpositionen: false,
    Anschreiben: {
        Absender: {
            Brieffenster: 'Funktionale Accounts\naX Demodaten\nAn der Leibi 6\n89278 Nersingen',
            Anrede: '',
            AnredeDekliniert: '',
            Briefanrede: 'Sehr geehrter Herr Demodaten',
            Vorname: 'aX',
            Nachname: 'Demodaten',
            Organisation: 'Funktionale Accounts',
            NameKomplett: '<w:p><w:r><w:t>Funktionale Accounts</w:t><w:br/><w:t>aX Demodaten</w:t></w:r></w:p>',
            Straße: 'An der Leibi 6',
            Postleitzahl: '89278',
            Ort: 'Nersingen',
            Telefon: '',
            Telefon2: '',
            EMail: 'demodaten@autoixpert.de',
            Website: '',
            Standortname: 'Nersingen',
            Standortkürzel: 'NER',
            Stempel: '',
            Logo: '',
            BeruflicheQualifikation: '',
        },
        Empfänger: {
            Brieffenster: 'Frau\nAnna Marie Meyer\nMusterweg 13/1\n54321 Musterhausen',
            Anrede: 'Frau',
            AnredeDekliniert: 'Frau',
            Briefanrede: 'Sehr geehrte Frau Meyer',
            Vorname: 'Anna Marie',
            Nachname: 'Meyer',
            Organisation: '',
            NameKomplett: 'Anna Marie Meyer',
            Straße: 'Musterweg 13/1',
            Postleitzahl: '54321',
            Ort: 'Musterhausen',
            Telefon: '08222-9188990',
            Telefon2: '',
            EMail: 'anna-marie@mustermayer.com',
            Website: '',
            Typ: 'Auftraggeber',
        },
        Datum: '16.11.2023',
        Betreff: '',
        Inhalt: '',
    },
    check: 1,
    arrowUpRed: 1,
    arrowDownGreen: 1,
    Rechnung: {
        Absender: {
            Brieffenster: 'Funktionale Accounts\naX Demodaten\nAn der Leibi 6\n89278 Nersingen',
            Anrede: '',
            AnredeDekliniert: '',
            Briefanrede: 'Sehr geehrter Herr Demodaten',
            Vorname: 'aX',
            Nachname: 'Demodaten',
            Organisation: 'Funktionale Accounts',
            NameKomplett: '<w:p><w:r><w:t>aX Demodaten</w:t></w:r></w:p>',
            Straße: 'An der Leibi 6',
            Postleitzahl: '89278',
            Ort: 'Nersingen',
            Telefon: '',
            Telefon2: '',
            EMail: 'demodaten@autoixpert.de',
            Website: '',
            Standortname: 'Nersingen',
            Standortkürzel: 'NER',
            Stempel: '',
            Logo: '',
            BeruflicheQualifikation: '',
        },
        Empfänger: {
            Brieffenster: 'Frau\nAnna Marie Meyer\nMusterweg 13/1\n54321 Musterhausen',
            Anrede: 'Frau',
            AnredeDekliniert: 'Frau',
            Briefanrede: 'Sehr geehrte Frau Meyer',
            Vorname: 'Anna Marie',
            Nachname: 'Meyer',
            Organisation: '',
            NameKomplett: 'Anna Marie Meyer',
            Straße: 'Musterweg 13/1',
            Postleitzahl: '54321',
            Ort: 'Musterhausen',
            Telefon: '08222-9188990',
            Telefon2: '',
            EMail: 'anna-marie@mustermayer.com',
            Website: '',
            Typ: 'Rechnungsempfänger',
        },
        Nummer: 'RE-00002',
        Einleitung: 'Wir danken für Ihren Auftrag und erlauben uns, die folgenden Positionen in Rechnung zu stellen.',
        GutachtenzusammenfassungDrucken: true,
        Datum: '18.11.2020',
        Leistungsdatum: '18.11.2020',
        istLeistungsdatumGleichRechnungsdatum: true,
        fälligAm: '18.12.2020',
        Zahlungsziel: 30,
        Einheit: 'EUR',
        Positionen: [
            {
                Nummer: 1,
                Beschreibung:
                    '<p>KFZ-Schadensgutachten</p><p>- Datenerfassung</p><p>- Fahrzeugbeschädigungen aufnehmen</p><p>- Festlegung wirtschaftlichster Reparaturweg</p><p>- Erstellung einer Schadenskalkulation</p><p>- Ermittlung des Wiederbeschaffungswerts</p>',
                Menge: '1',
                Einheit: '',
                Einzelpreis: '294,00 €',
                Gesamtpreis: '294,00 €',
            },
            {
                Nummer: 2,
                Beschreibung: '<p>Lichtbilder</p>',
                Menge: '12',
                Einheit: 'Stück',
                Einzelpreis: '2,50 €',
                Gesamtpreis: '30,00 €',
            },
            {
                Nummer: 3,
                Beschreibung: '<p>Lichtbildkopien</p>',
                Menge: '12',
                Einheit: 'Stück',
                Einzelpreis: '0,70 €',
                Gesamtpreis: '8,40 €',
            },
            {
                Nummer: 4,
                Beschreibung: '<p>Fahrtkosten (pauschal)</p>',
                Menge: '1',
                Einheit: '',
                Einzelpreis: '25,00 €',
                Gesamtpreis: '25,00 €',
            },
            {
                Nummer: 5,
                Beschreibung: '<p>Schreibkosten (pauschal)</p>',
                Menge: '1',
                Einheit: '',
                Einzelpreis: '10,00 €',
                Gesamtpreis: '10,00 €',
            },
            {
                Nummer: 6,
                Beschreibung: '<p>Porto &amp; Telefon (pauschal)</p>',
                Menge: '1',
                Einheit: '',
                Einzelpreis: '7,50 €',
                Gesamtpreis: '7,50 €',
            },
        ],
        istEuLieferung: false,
        GesamtNetto: '374,90 €',
        MwSt: '59,98 €',
        MwStProzentsatz: '16 %',
        GrundFürNullProzentMwSt: '',
        GesamtBrutto: '434,88 €',
        bezahlterBetrag: '- €',
        gekürzterBetrag: '- €',
        offenerBetrag: '434,88 €',
        ersteZahlung: {
            Betrag: '',
            Datum: '',
            Zahler: '',
            InterneBemerkungen: '',
            istKürzung: false,
        },
        letzteZahlung: {
            Betrag: '',
            Datum: '',
            Zahler: '',
            InterneBemerkungen: '',
            istKürzung: false,
        },
        istStornoRechnung: false,
        stornierteRechnung: null,
    },
    OnlineUnterschrift: {
        Frist: '12.12.2024 um 12:00 Uhr',
        Link: 'https://unterschrift.autoixpert.de/Gutachten/1RfWEUd0PZam?token=cGTdxoKhgQkp',
    },
    Dokument: {
        Typ: 'Rechnung',
        Gruppe: 'Gutachten',
    },
};

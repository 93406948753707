import { animate, style, transition, trigger } from '@angular/animations';

export function slideInAndOutHorizontally(leaveDuration = 200) {
    return trigger('slideInAndOutHorizontally', [
        transition(':enter', [
            style({
                opacity: 0,
                transform: 'translateX(-50px)',
            }),
            animate(
                `${leaveDuration}ms ease`,
                style({
                    opacity: 1,
                    transform: 'translateX(0)',
                }),
            ),
        ]),

        transition(':leave', [
            animate(
                `${leaveDuration}ms ease`,
                style({
                    opacity: 0,
                    transform: 'translateX(50px)',
                }),
            ),
        ]),
    ]);
}

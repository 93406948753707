import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { PANEL_CLOSE_SUBJECT_TOKEN } from '@autoixpert/injection-tokens/common-overlay-injection-tokens';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { Task } from '@autoixpert/models/tasks/task';
import { getOpenTasks } from '../../../libraries/tasks/get-open-tasks';
import {
    CHANGE_SUBJECT_TOKEN,
    INVOICE_TOKEN,
    REPORT_TOKEN,
    TASKS_TOKEN,
    TasksListOverlayComponent,
} from './tasks-list-overlay.component';

@Directive({
    selector: '[tasks-list-overlay-anchor]',
    exportAs: 'tasksListOverlayAnchor',
    host: {
        '[class.tasks-icon]': 'true',
    },
})
export class TasksListOverlayAnchorDirective implements OnInit, OnDestroy {
    constructor(
        private elementRef: ElementRef,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input() tasks: Task[];
    @Input() report: Report;
    @Input() invoice: Invoice;
    @Input() autoOpenWhenVisible: boolean;

    @Output() change: EventEmitter<Task[]> = new EventEmitter<Task[]>();
    @Output() panelClose: EventEmitter<void> = new EventEmitter<void>();

    private overlayRef: OverlayRef;

    //*****************************************************************************
    //  Lifecycle Hooks
    //****************************************************************************/
    ngOnInit() {
        if (this.autoOpenWhenVisible) {
            this.openTasksPanel();
        }
    }

    ngOnDestroy() {
        this.overlayRef?.detach();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lifecycle Hooks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openTasksPanel() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                ])
                .withPush(false) // withPush being true (default) causes any overlay to grow up to 100% of the viewport, overflowing to the bottom.
                .withViewportMargin(20),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        /**
         * The overlay is responsible for saving the tasks but the parent might still want to know about changes
         * so that they can display different states of the notes.
         */
        const changeListener = new Subject<Task[]>();
        changeListener.subscribe((tasks) => this.change.emit(tasks));

        /**
         * Also listen for the close event of the parent.
         */
        const panelCloseListener = new Subject<void>();
        panelCloseListener.subscribe(() => this.panelClose.emit());

        //*****************************************************************************
        //  Injector
        //****************************************************************************/
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: CHANGE_SUBJECT_TOKEN,
                    useValue: changeListener,
                },
                {
                    provide: PANEL_CLOSE_SUBJECT_TOKEN,
                    useValue: panelCloseListener,
                },
                {
                    provide: TASKS_TOKEN,
                    useValue: this.tasks,
                },
                {
                    provide: REPORT_TOKEN,
                    useValue: this.report,
                },
                {
                    provide: INVOICE_TOKEN,
                    useValue: this.invoice,
                },
                {
                    provide: OverlayRef,
                    useValue: this.overlayRef,
                },
            ],
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Injector
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Component Portal
        //****************************************************************************/
        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<TasksListOverlayComponent>(
            TasksListOverlayComponent,
            null,
            injector,
        );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Component Portal
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Attach Component to Portal Outlet
        //****************************************************************************/
        this.overlayRef.attach(componentPortal);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Attach Component to Portal Outlet
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Host Class Binding
    //****************************************************************************/
    @HostBinding('class.highlighted-icon-blue')
    public get hasOpenTasks(): boolean {
        return getOpenTasks(this.tasks).length > 0;
    }

    @HostBinding('class.all-tasks-complete')
    public get allTasksComplete(): boolean {
        return this.tasks.length > 0 && this.tasks.every((task) => task.isCompleted);
    }

    @HostBinding('class.has-overdue-tasks')
    public get hasOverdueTasks(): boolean {
        const today = todayIso();
        return getOpenTasks(this.tasks).some((task) => task.dueDate <= today);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Host Class Binding
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Directive, ElementRef, Host, OnDestroy, OnInit } from '@angular/core';
import { MuuriGridDirective } from './muuri-grid.directive';

/**
 * This directive must be assigned to each grid item.
 * As soon as items are added to the DOM through *ngFor, those child items with the directive
 * muuriGridItem add themselves to the grid by calling the grid's addItem method.
 */
@Directive({
    selector: '[muuriGridItem]',
})
export class MuuriGridItemDirective implements OnInit, OnDestroy {
    constructor(
        @Host() private muuriGrid: MuuriGridDirective,
        private elementRef: ElementRef,
    ) {}

    ngOnInit(): void {
        this.muuriGrid.addItem(this.elementRef);
    }

    ngOnDestroy(): void {
        this.muuriGrid.removeItem(this.elementRef);
    }
}

import { HttpClient } from '@angular/common/http';
import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ApiErrorService } from '../services/api-error.service';
import { AXRESTClient } from '../services/ax-restclient';
import { NetworkStatusService } from '../services/network-status.service';
import { ToastService } from '../services/toast.service';

@Directive({
    selector: '[dragDropFileUpload]',
    host: {
        '[class.is-upload-pending]': 'this.isUploadPending',
        '[class.is-dragover]': 'this.isFileOverElement',
    },
})
export class DragDropFileUploadDirective {
    constructor(
        protected httpClient: HttpClient,
        private toastService: ToastService,
        private networkStatusService: NetworkStatusService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() allowMultipleFiles: boolean = false;
    @Input() allowedFileExtensions: ('.pdf' | '.jpeg' | '.csv' | '.docx')[] = []; // use dot like in html <input type="file" accept=".pdf" >
    @Input() uploadApiPath: string; // e.g. `/requests/${requestId}/asdf`
    @Input() uploadId: string;
    @Output() onUploadSuccessful: EventEmitter<UploadResponse[]> = new EventEmitter();
    @Output() onUploadFailure: EventEmitter<any> = new EventEmitter();

    public fileOverElementTimeoutCache: number = null;
    public isFileOverElement: boolean = false;
    public isUploadPending: boolean = false;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Detect Hover State
    //****************************************************************************/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Detect Hover State
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Click
    //****************************************************************************/

    @HostListener('click', ['$event'])
    public async handleClick(event: any) {
        event.preventDefault();
        const inputElement = document.createElement('input');
        inputElement.setAttribute('style', 'display:none');
        inputElement.setAttribute('type', 'file');
        if (this.allowedFileExtensions.length > 0) {
            inputElement.setAttribute('accept', this.allowedFileExtensions.join(','));
        }
        if (this.allowMultipleFiles) {
            inputElement.setAttribute('multiple', '');
        }

        async function handleInputFileUpload(this: DragDropFileUploadDirective) {
            const fileList = inputElement.files;
            const filesToUpload: File[] = [];
            for (let i = 0; i < fileList.length; i++) {
                filesToUpload.push(fileList[i]);
            }
            await this.uploadMultipleFiles(filesToUpload);
        }

        inputElement.addEventListener('change', handleInputFileUpload.bind(this), false);
        inputElement.click();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Click
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle DragOver
    //****************************************************************************/
    @HostListener('dragover', ['$event'])
    public onDropOver(event: any) {
        event.preventDefault();
        this.isFileOverElement = true;

        clearTimeout(this.fileOverElementTimeoutCache);
        this.fileOverElementTimeoutCache = window.setTimeout(() => {
            this.isFileOverElement = false;
        }, 500);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle DragOver
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle File Drop
    //****************************************************************************/

    @HostListener('drop', ['$event'])
    public async onDrop(event: DragEvent) {
        event.preventDefault();
        this.isFileOverElement = false;

        const filesToUpload: File[] = [];

        // drop handler from mdn reference: https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            const itemList = event.dataTransfer.items;
            for (let i = 0; i < itemList.length; i++) {
                const item = itemList[i];
                if (item.kind === 'file') {
                    filesToUpload.push(item.getAsFile());
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            const fileList = event.dataTransfer.files;
            for (let i = 0; i < fileList.length; i++) {
                filesToUpload.push(fileList[i]);
            }
        }

        await this.uploadMultipleFiles(filesToUpload);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle File Drop
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Upload File
    //****************************************************************************/

    private async uploadSingleFile(file: File): Promise<UploadResponse> {
        const apiUrl = AXRESTClient.marryToBaseUrl(this.uploadApiPath);

        const formData = new FormData();
        formData.append('_id', this.uploadId);
        formData.append('document', file);

        return this.httpClient.post(apiUrl, formData).toPromise();
    }

    public async uploadMultipleFiles(filesToUpload: File[]) {
        if (!this.allowMultipleFiles && filesToUpload.length > 1) {
            this.toastService.error('Nur eine Datei erlaubt', 'Nur eine Datei kann pro Vorlage hochgeladen werden.');
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Dokumente können hochgeladen werden, sobald du wieder online bist.',
            );
            return;
        }

        // If the mime type is no allowed, remove the document from the queue
        for (const file of filesToUpload) {
            // Replace . in file extension to match application/pdf with .pdf
            if (
                !this.allowedFileExtensions.some((allowedFileExtension) =>
                    file.type.includes(allowedFileExtension.replace('.', '')),
                )
            ) {
                this.toastService.error(
                    `Nur ${this.allowedFileExtensions.join(', ').toUpperCase()} erlaubt`,
                    'Bitte lade eine passende Datei hoch.',
                );
                filesToUpload.splice(filesToUpload.indexOf(file), 1);
            }
        }

        // Show a loading icon next to the document name.
        if (filesToUpload.length == 0) {
            return;
        }

        this.isUploadPending = true;

        //*****************************************************************************
        //  Trigger Upload
        //****************************************************************************/
        try {
            const uploadResponses: UploadResponse[] = [];
            await Promise.all(
                filesToUpload.map(async (fileToUpload) => {
                    const uploadResponse = await this.uploadSingleFile(fileToUpload);

                    // Collect responses in case of multi file upload.
                    uploadResponses.push(uploadResponse);
                }),
            );

            this.onUploadSuccessful.emit(uploadResponses);
        } catch (error) {
            this.onUploadFailure.emit(error);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ERROR_PDF_IS_ENCRYPTED: {
                        title: 'PDF Datei ist verschlüsselt',
                        body: 'Bitte speichere die Datei ohne Verschlüsselung und probiere es erneut.',
                    },
                },
                defaultHandler: {
                    title: 'Upload fehlgeschlagen',
                    body: "Eine oder mehrere Dateien konnten nicht hochgeladen werden. Bitte probiere es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Trigger Upload
        /////////////////////////////////////////////////////////////////////////////*/

        this.isUploadPending = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Upload File
    /////////////////////////////////////////////////////////////////////////////*/
}

// We currently cannot pass type parameters to an Angular directive through a
// template, therefore we type the response only loosely.
type UploadResponse = Record<string, any>;

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container dialog-scrollable-area">
        <img src="/assets/images/icons/close.png" alt="Schließen" class="close-icon" (click)="closeAndDiscard()" />
        <div id="car-type-container">
            <div id="car-manufacturer-icon-container" *ngIf="make && iconForCarBrandExists(make)">
                <img [src]="iconFilePathForCarBrand(make, 'large')" alt="" id="car-manufacturer-logo" />
            </div>
            <div id="car-make-and-model">{{ make }} {{ model }}</div>
        </div>
        <div id="after-vin-selection-message">
            Die DAT hat mehrere Fahrzeuge für diese VIN gefunden, weil der Hersteller keine eindeutige Zuordnung kennt
            oder der DAT von dem Hersteller keine Original-Daten vorliegen.
            <br />
            Bitte wähle das ähnlichste Modell aus der Liste.
        </div>

        <div id="submodel-groups-container">
            <div class="submodel-group" *ngFor="let submodelGroup of submodelGroups">
                <div class="submodel-summary">
                    <div class="submodel-sticky-awesomeness">
                        <div class="label">Untertyp</div>
                        <div class="submodel-name">{{ submodelGroup.name }}</div>
                    </div>
                </div>
                <div class="vehicle-editions-container">
                    <div
                        class="vehicle-edition"
                        *ngFor="let vehicleEdition of submodelGroup.editions"
                        (click)="selectIdentificationResponse(vehicleEdition)"
                        (dblclick)="chooseVinResponsePerVehicle(selectedIdentificationResponsePerVehicle)"
                        [ngClass]="{ selected: selectedIdentificationResponsePerVehicle === vehicleEdition }">
                        <!--***********************************************
                        ** Valuation or Repair Only?
                        ************************************************-->
                        <div
                            class="valuation-or-calculation-impossible-container"
                            *ngIf="!vehicleEdition.newCarInformation.datIdentification.isDamageCalculationPossible">
                            <mat-icon
                                class="no-calculation-possible-icon"
                                (click)="openPhantomCalculationHelp()"
                                [matTooltip]="
                                    'Keine DAT-Kalkulation für dieses Fahrzeug möglich.\n\nDas passiert häufig bei älteren, sehr jungen oder Import-Fahrzeugen.\n\nDu kannst in der DAT-Oberfläche aber ein ähnliches Fahrzeug wählen (Phantomkalkulation). Klicke für eine Anleitung auf das rote Dreieck.'
                                ">
                                warning
                            </mat-icon>
                            <div class="no-valuation-or-calculation-possible-label">Keine Kalkulation möglich</div>
                        </div>
                        <div
                            class="valuation-or-calculation-impossible-container"
                            *ngIf="!vehicleEdition.newCarInformation.datIdentification.isValuationPossible">
                            <mat-icon class="no-valuation-possible-icon" [matTooltip]="'Keine DAT-Bewertung möglich.'">
                                warning
                            </mat-icon>
                            <div class="no-valuation-or-calculation-possible-label">Keine Bewertung möglich</div>
                        </div>
                        <!--***********************************************
                        ** END Valuation or Repair Only?
                        ************************************************-->

                        <div
                            class="classification-group-option motor"
                            *ngIf="getDatECodeEquipmentOptionName(vehicleEdition.datECodeEquipmentPositions, 'motor')">
                            <div class="label">Motor</div>
                            <div class="value">
                                {{ getDatECodeEquipmentOptionName(vehicleEdition.datECodeEquipmentPositions, 'motor') }}
                            </div>
                        </div>
                        <div
                            class="classification-group-option autobody"
                            *ngIf="
                                getDatECodeEquipmentOptionName(vehicleEdition.datECodeEquipmentPositions, 'autobody')
                            ">
                            <div class="label">Karosserie</div>
                            <div class="value">
                                {{
                                    getDatECodeEquipmentOptionName(
                                        vehicleEdition.datECodeEquipmentPositions,
                                        'autobody'
                                    )
                                }}
                            </div>
                        </div>
                        <div
                            class="classification-group-option gearbox"
                            *ngIf="
                                getDatECodeEquipmentOptionName(vehicleEdition.datECodeEquipmentPositions, 'gearbox')
                            ">
                            <div class="label">Getriebe</div>
                            <div class="value">
                                {{
                                    getDatECodeEquipmentOptionName(vehicleEdition.datECodeEquipmentPositions, 'gearbox')
                                }}
                            </div>
                        </div>
                        <div
                            class="classification-group-option equipmentLine"
                            *ngIf="
                                getDatECodeEquipmentOptionName(
                                    vehicleEdition.datECodeEquipmentPositions,
                                    'equipmentLine'
                                )
                            ">
                            <div class="label">Ausstattungslinie</div>
                            <div class="value">
                                {{
                                    getDatECodeEquipmentOptionName(
                                        vehicleEdition.datECodeEquipmentPositions,
                                        'equipmentLine'
                                    )
                                }}
                            </div>
                        </div>
                        <div
                            class="classification-group-option equipmentLine"
                            *ngIf="vehicleEdition.newCarInformation.datIdentification.marketIndexName">
                            <div class="label">Marktindex</div>
                            <div class="value">
                                {{
                                    getMarketIndexWithoutProductionPeriod(
                                        vehicleEdition.newCarInformation.datIdentification.marketIndexName
                                    )
                                }}
                            </div>
                        </div>
                        <div
                            class="classification-group-option equipmentLine"
                            *ngIf="vehicleEdition.newCarInformation.datIdentification.marketIndexName">
                            <div class="label">Baujahre</div>
                            <div class="value">
                                {{
                                    getProductionPeriod(
                                        vehicleEdition.newCarInformation.datIdentification.marketIndexName
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="buttons-container" [ngClass]="{ sticky: !!selectedIdentificationResponsePerVehicle }">
            <button
                (click)="chooseVinResponsePerVehicle(selectedIdentificationResponsePerVehicle)"
                [ngClass]="{ disabled: !selectedIdentificationResponsePerVehicle }"
                matTooltip="Ausstattungen und technische Daten dieses Fahrzeugs verwenden.">
                Übernehmen
            </button>
        </div>
    </div>
</div>

import moment from 'moment';
import { Report } from '../../models/reports/report';

export function isReportDeleted(report: Report): boolean {
    return report.state === 'deleted';
}

/**
 * This function sets the deletedAt field of the report to the current date and sets the state to 'deleted'.
 * Handling the report in the local cache and syncing it with the backend is the responsibility of the caller.
 */
export function moveReportToTrash(report: Report): void {
    report.state = 'deleted';
    report.deletedAt = moment().format();
}

/**
 * This function removes the deletedAt field from the report and sets the state to 'recorded'.
 * Handling the report in the local cache and syncing it with the backend is the responsibility of the caller.
 */
export function restoreReportFromTrash(report: Report): void {
    // Always set the state of the restored report to 'recorded', because they
    // are more likely to appear in the users viewport.
    report.state = 'recorded';
    report.deletedAt = null;
}

export function detectBrowser(): { browser: string; majorVersion: number } {
    const userAgent = navigator.userAgent;
    let temp;
    let matches = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    //*****************************************************************************
    //  Internet Explorer
    //****************************************************************************/
    if (/trident/i.test(matches[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return {
            browser: 'Internet Explorer',
            majorVersion: parseInt(temp[1]),
        };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Internet Explorer
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Old Edge & Opera
    //****************************************************************************/
    if (matches[1] === 'Chrome') {
        temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
        if (temp != null) {
            if (temp[1] === 'Edge') {
                return {
                    browser: 'Edge',
                    majorVersion: parseInt(temp[2]),
                };
            } else {
                return {
                    browser: 'Opera',
                    majorVersion: parseInt(temp[2]),
                };
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Old Edge & Opera
    /////////////////////////////////////////////////////////////////////////////*/
    matches = matches[2] ? [matches[1], matches[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((temp = userAgent.match(/version\/(\d+)/i)) != null) {
        matches.splice(1, 1, temp[1]);
    }
    return {
        browser: matches[0],
        majorVersion: parseInt(matches[1]),
    };
}

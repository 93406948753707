import { DateTime } from 'luxon';
import { OutgoingMessage, OutgoingMessageSchedule, OutgoingMessageStatus } from '@autoixpert/models/outgoing-message';

export function getOutgoingMessageScheduledAt(schedule: OutgoingMessageSchedule): DateTime {
    switch (schedule.type) {
        case 'offset': {
            const scheduledAt = DateTime.now()
                .plus({ seconds: schedule.config.secondsOffset || 0 })
                .plus({ minutes: schedule.config.minutesOffset || 0 })
                .plus({ hours: schedule.config.hoursOffset || 0 })
                .plus({ days: schedule.config.daysOffset || 0 });
            return scheduledAt;
        }

        case 'relative': {
            const now = DateTime.now();
            const scheduledAt = now.plus({ days: schedule.config.daysOffset }).set({
                hour: schedule.config.hour,
                minute: schedule.config.minute,
                second: schedule.config.seconds || 0,
            });
            return scheduledAt;
        }

        case 'next-weekday': {
            const now = DateTime.now();
            const targetWeekday = schedule.config.weekday;
            // Calculate days until the next occurrence of the target weekday
            const daysUntilTarget = (7 + targetWeekday - now.weekday) % 7;
            const scheduledAt = now.plus({ days: daysUntilTarget > 0 ? daysUntilTarget : 7 }).set({
                hour: schedule.config.hour,
                minute: schedule.config.minute,
                second: 0,
            });
            return scheduledAt;
        }

        case 'custom': {
            // Parse custom date and time
            if (schedule.config.date) {
                const scheduledAt = DateTime.fromISO(schedule.config.date).set({
                    hour: schedule.config.hour,
                    minute: schedule.config.minute,
                    second: 0,
                });
                return scheduledAt;
            } else {
                return null;
            }
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { generateId } from '@autoixpert/lib/generate-id';
import { residualValueBidSortFunction } from '@autoixpert/lib/residual-value-bid-sort-function';
import { getHideBiddersWithoutBidSetting } from '@autoixpert/lib/residual-value-request/get-hide-bidders-without-bid-setting';
import { getFullUserContactPerson } from '@autoixpert/lib/users/get-full-user-contact-person';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { Place } from '@autoixpert/models/contacts/place';
import { Report } from '@autoixpert/models/reports/report';
import {
    Bidder,
    ResidualValueBid,
    ResidualValueExchangeName,
} from '@autoixpert/models/reports/residual-value/residual-value-bid';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { addCustomResidualValueBidList } from '../../../../shared/libraries/custom-residual-value-bid-list';
import { openDirectionsOnGoogleMaps } from '../../../../shared/libraries/distance-calculation/open-directions-on-google-maps';
import { hasAccessRight } from '../../../../shared/libraries/user/has-access-right';
import { ContactPersonService } from '../../../../shared/services/contact-person.service';
import { DownloadService } from '../../../../shared/services/download.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { TeamService } from '../../../../shared/services/team.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserService } from '../../../../shared/services/user.service';

@Component({
    selector: 'bid-selector',
    templateUrl: 'bid-selector.component.html',
    styleUrls: ['bid-selector.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class BidSelectorComponent implements OnInit, OnDestroy {
    constructor(
        private reportDetailsService: ReportDetailsService,
        private contactPersonService: ContactPersonService,
        private httpClient: HttpClient,
        private downloadService: DownloadService,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private userService: UserService,
        private teamService: TeamService,
    ) {}

    public user: User;
    public team: Team;

    public bids: ResidualValueBid[] = [];
    private autoonlineBids: ResidualValueBid[] = [];
    private winvalueBids: ResidualValueBid[] = [];
    private cartvBids: ResidualValueBid[] = [];
    private carcasionBids: ResidualValueBid[] = [];

    private subscriptions: Subscription[] = [];

    @Input() startInCreationMode: boolean = false;
    @Input() disabled: boolean = false;
    @Input() initialFilter: ResidualValueExchangeName | 'none';

    public get selectedBids(): ResidualValueBid[] {
        return this.bids.filter((bid) => bid.selected);
    }

    @Output() highestBidSelected: EventEmitter<ResidualValueBid> = new EventEmitter();
    public filteredBids: ResidualValueBid[] = [];
    public bidsInEditMode: ResidualValueBid[] = [];
    public bidDetailsShown: WeakMap<ResidualValueBid, boolean> = new WeakMap();

    // ********** Filters **********
    @Input() useRegionalBidsOnly: boolean = false;
    @Output() useRegionalBidsOnlyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * If bidders without a bid should be hidden in the list of bids.
     */
    protected hideBiddersWithoutBid = null;

    /**
     * Number of bidders that did not place a bid (not interested or no bid placed after request period ended)
     */
    protected numberOfBiddersWithoutBid = 0;
    public showAutoonlineBids: boolean = true;
    public showCartvBids: boolean = true;
    public showWinvalueBids: boolean = true;
    public showCarcasionBids: boolean = true;
    public showCustomBids: boolean = true;

    public availableBidders: ContactPerson[] = [];
    public filteredBidders: ContactPerson[] = [];

    @Input() report: Report;

    @Output() close: EventEmitter<any> = new EventEmitter();

    @ViewChild('contentContainer', { static: true }) contentContainer;

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        // As soon as the bid selector opens, position the content so that the user can see it. It is
        // usually called when the user scrolled down completely, which would open the bids outside
        // of the visible area before.
        this.contentContainer.nativeElement.scrollIntoView(false);

        if (this.startInCreationMode) {
            this.addCustomBid();
        }

        this.initializeFilters();

        this.attachOriginInformationToBids();

        this.filterAndSortBids();

        this.registerKeyboardEvents();
    }

    //*****************************************************************************
    //  General Functions
    //****************************************************************************/
    /**
     * Trigger the output event to close the editor.
     */
    public closeBidSelector(): void {
        this.close.emit();
    }

    /**
     * In order to display a bid's origin, we must expose that information on each object.
     */
    public attachOriginInformationToBids(): void {
        if (this.report.valuation.autoonlineResidualValueOffer?.bids) {
            this.autoonlineBids = this.report.valuation.autoonlineResidualValueOffer.bids.map((bid) => {
                bid.origin = 'autoonline';
                return bid;
            });
        } else {
            this.autoonlineBids = [];
        }

        if (this.report.valuation.winvalueResidualValueOffer?.bids) {
            this.winvalueBids = this.report.valuation.winvalueResidualValueOffer.bids.map((bid) => {
                bid.origin = 'winvalue';
                return bid;
            });
        } else {
            this.winvalueBids = [];
        }

        if (this.report.valuation.cartvResidualValueOffer?.bids) {
            this.cartvBids = this.report.valuation.cartvResidualValueOffer.bids.map((bid) => {
                bid.origin = 'cartv';
                return bid;
            });
        } else {
            this.cartvBids = [];
        }

        if (this.report.valuation.carcasionResidualValueOffer?.bids) {
            this.carcasionBids = this.report.valuation.carcasionResidualValueOffer.bids.map((bid) => {
                bid.origin = 'carcasion';
                return bid;
            });
        } else {
            this.carcasionBids = [];
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END General Functions
    /////////////////////////////////////////////////////////////////////////////*/

    public toggleContactDetails(bid: ResidualValueBid) {
        this.bidDetailsShown.set(bid, !this.bidDetailsShown.get(bid));
    }

    public toggleRegional(bid: ResidualValueBid) {
        if (this.disabled) {
            return;
        }

        bid.regional = !bid.regional;
        this.deselectNonMatchingBids();
        this.filterAndSortBids();
        this.saveReport();
    }

    public openBidderAddressOnGoogleMaps(bid: ResidualValueBid): void {
        const claimantAddress: ContactPerson = this.report.claimant.contactPerson;
        const responsibleAssessor: ContactPerson = getFullUserContactPerson({
            user: this.userService.getTeamMemberFromCache(this.report.responsibleAssessor),
            officeLocations: this.team.officeLocations,
            officeLocationId: this.report.officeLocationId,
        });

        let origin: Place;
        if (claimantAddress.streetAndHouseNumberOrLockbox || claimantAddress.zip) {
            origin = {
                streetAndHouseNumber: claimantAddress.streetAndHouseNumberOrLockbox,
                zip: claimantAddress.zip,
                city: claimantAddress.city,
            };
        } else if (responsibleAssessor) {
            origin = {
                streetAndHouseNumber: responsibleAssessor.streetAndHouseNumberOrLockbox,
                zip: responsibleAssessor.zip,
                city: responsibleAssessor.city,
            };
        }

        const destinations: Place[] = [
            {
                streetAndHouseNumber: bid.bidder.address.street,
                zip: bid.bidder.address.postcode,
                city: bid.bidder.address.city,
            },
        ];

        openDirectionsOnGoogleMaps({
            origins: [origin],
            destinations,
        });
    }

    public acceptBids(): void {
        if (this.disabled) {
            return;
        }

        // Sort selected bids by highest price before emitting them
        const selectedBids = this.selectedBids.sort(residualValueBidSortFunction);

        this.highestBidSelected.emit(selectedBids[0]);
        this.closeBidSelector();
    }

    //*****************************************************************************
    //  Filtering Bids
    //****************************************************************************/
    public filterAndSortBids(): void {
        // The winvalue and cartv bids arrays contain the origin property. Don't get the bids from the report object directly.
        this.bids = [
            ...this.autoonlineBids,
            ...this.winvalueBids,
            ...this.cartvBids,
            ...this.carcasionBids,
            ...this.report.valuation.customResidualValueBids,
        ];

        this.filteredBids = this.bids.slice();

        this.filterBidsByRegional();
        this.filterBidsByOrigin();
        this.filterBidsByBiddersWithoutBid();

        this.sortBids();
    }

    private filterBidsByRegional(): void {
        if (this.useRegionalBidsOnly) {
            this.filteredBids = this.filteredBids.filter((bid) => bid.regional);
        }
    }

    /**
     * Calculate the amount of bidders without bid and depending on the users settings hide or show bidders without bids.
     */
    private filterBidsByBiddersWithoutBid(): void {
        this.initializeBiddersWithoutBidFilter();

        if (this.hideBiddersWithoutBid) {
            // First check how many bids with the current filters will be hidden because they have no bid value (not interested)
            this.numberOfBiddersWithoutBid = this.filteredBids.filter((bid) => bid.bidValue.value === null).length;

            // Then clear those from the filtered list
            this.filteredBids = this.filteredBids.filter((bid) => bid.bidValue.value !== null);
        }
    }

    /**
     * User can choose to show or hide bidders without bid in the custom residual value sheet. This function toggles the setting
     * for this report (overwrites global team setting).
     */
    protected toggleHideBiddersWithoutBid(): void {
        // Check if the user wants to print the bidders without bid. Preference in report overwrites preference in team.
        const includeBiddersWithNoBid = getHideBiddersWithoutBidSetting(this.report, this.team);

        this.report.valuation.includeBiddersWithNoBidInCustomResidualValueSheet = !includeBiddersWithNoBid;
        void this.saveReport();
    }

    /**
     * User can choose to remember the current setting (whether to show or hide bidders without a bid in the custom residual value sheet)
     * for the whole team (not only the current report).
     */
    protected async rememberHideBiddersWithNoBidSettings(): Promise<void> {
        const currentSettingForReport = this.report.valuation.includeBiddersWithNoBidInCustomResidualValueSheet;

        this.team.preferences.includeBiddersWithNoBidInCustomResidualValueSheet = currentSettingForReport;
        const message = currentSettingForReport
            ? 'Bieter ohne Gebot in eigenem Gebotsblatt immer abdrucken.'
            : 'Keine Bieter ohne Gebot in eigenem Gebotsblatt abdrucken.';
        this.toastService.success('Einstellung für alle Gutachten gespeichert', message);
        await this.teamService.put(this.team);
    }

    /**
     * Dismiss and never show the info note again that suggests to show bidders without bid in the current report.
     */
    async dismissIncludeBiddersWithoutBidInfo() {
        this.team.userInterfaceStates.isInfoDismissed_activateBiddersWithNoBidForCustomResidualValueSheet = true;
        this.toastService.success(
            'Einstellung gespeichert',
            'Wir fragen dich nicht erneut. Du kannst die Einstellung jederzeit über das Dreipunktmenü ändern.',
        );
        void this.teamService.put(this.team);
    }

    /**
     * Toggles the input mask where custom residual value bids can be created between "no bid placed"
     * and "bid placed". In the latter case the user can input a bid value, in the former the inputs are
     * disabled.
     */
    protected toggleNoBidPlaced(bid: ResidualValueBid): void {
        bid.notInterested = !bid.notInterested;

        if (bid.notInterested) {
            // In case the bidder did not place a bid, we remove the bid value and binding date
            bid.bidValue.value = null;
            bid.bindingDate = null;
        }
    }

    private filterBidsByOrigin(): void {
        const allowedSources: ResidualValueBid['origin'][] = [];

        if (this.showAutoonlineBids) allowedSources.push('autoonline');
        if (this.showCarcasionBids) allowedSources.push('carcasion');
        if (this.showCartvBids) allowedSources.push('cartv');
        if (this.showWinvalueBids) allowedSources.push('winvalue');
        if (this.showCustomBids) allowedSources.push('custom', 'axResidualValueRequest');

        this.filteredBids = this.filteredBids.filter((bid) => allowedSources.includes(bid.origin));
    }

    public toggleAutoonlineFilter(): void {
        this.showAutoonlineBids = !this.showAutoonlineBids;
        this.filterAndSortBids();
    }

    public toggleCartvFilter(): void {
        this.showCartvBids = !this.showCartvBids;
        this.filterAndSortBids();
    }

    public toggleWinvalueFilter(): void {
        this.showWinvalueBids = !this.showWinvalueBids;
        this.filterAndSortBids();
    }

    public toggleCarcasionFilter(): void {
        this.showCarcasionBids = !this.showCarcasionBids;
        this.filterAndSortBids();
    }

    public toggleCustomBidsFilter(): void {
        this.showCustomBids = !this.showCustomBids;
        this.filterAndSortBids();
    }

    /**
     * First time the user opens this bid selector, we check how many bids were placed. If there are more than 3 bids (with a value), we
     * hide all bidders that were not interested or did not place a bid after the request ended. These are only shown initially, if there are less than
     * 3 bids or the user has already selected a bidder without a bid. When hidden, we display a message at the bottom of the list that lets the user
     * fade in all bidders without bid.
     */
    private initializeBiddersWithoutBidFilter() {
        if (this.hideBiddersWithoutBid === null) {
            const numberOfBiddersWithoutBid = this.bids.filter((bid) => bid.bidValue.value === null).length;
            const numberOfBids = this.bids.length - numberOfBiddersWithoutBid;
            const hasSelectedBidderWithoutBid =
                this.bids.findIndex((bid) => bid.bidValue.value === null && bid.selected) !== -1;
            this.hideBiddersWithoutBid = numberOfBids >= 3 && !hasSelectedBidderWithoutBid;
        }
    }

    private initializeFilters(): void {
        // If nothing is set, use the default
        if (!this.initialFilter || this.initialFilter === 'none') {
            return;
        }

        // only turn on the filter given externally
        this.disableAllFilters();

        switch (this.initialFilter) {
            case 'autoonline':
                this.showAutoonlineBids = true;
                break;
            case 'winvalue':
                this.showWinvalueBids = true;
                break;
            case 'cartv':
                this.showCartvBids = true;
                break;
            case 'carcasion':
                this.showCarcasionBids = true;
                break;
            case 'own':
                this.showCustomBids = true;
                break;
            default:
                console.error(`Initial bid selector filter set to ${this.initialFilter} not allowed.`);
        }
    }

    private disableAllFilters(): void {
        this.showAutoonlineBids = false;
        this.showCarcasionBids = false;
        this.showWinvalueBids = false;
        this.showCartvBids = false;
        this.showCustomBids = false;
    }

    public deselectNonMatchingBids(): void {
        if (this.useRegionalBidsOnly) {
            // De-select all non-regional bids
            this.selectedBids.filter((bid) => !bid.regional).forEach((bid) => (bid.selected = false));
        }
    }

    public emitRegionalFilterChange(): void {
        this.useRegionalBidsOnlyChange.emit(this.useRegionalBidsOnly);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filtering Bids
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sorting Bids
    //****************************************************************************/
    // TODO implement other sort mechanisms than just sorting by price
    public sortBids() {
        this.filteredBids.sort(residualValueBidSortFunction);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sorting Bids
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handling custom bids
    //****************************************************************************/
    public addCustomBid(): ResidualValueBid {
        const newBid = new ResidualValueBid({
            origin: 'custom',
            regional: true,
        });
        this.enterEditModeOfCustomBid(newBid);
        this.filterAndSortBids();
        return newBid;
    }

    public editCustomBid(bid: ResidualValueBid): void {
        if (this.disabled) {
            return;
        }

        this.enterEditModeOfCustomBid(bid);
    }

    public enterEditModeOfCustomBid(bid: ResidualValueBid): void {
        // If the bid in edit mode is empty, clear it.
        if (
            this.bidsInEditMode.length === 1 &&
            !this.bidsInEditMode[0].bidValue.value &&
            !this.bidsInEditMode[0].bidder.address.contact
        ) {
            this.bidsInEditMode.length = 0;
        }

        // Don't add the bid a second time
        const sameBidInEditMode = this.bidsInEditMode.find((bidInEditMode) => bidInEditMode.bidID === bid.bidID);
        if (sameBidInEditMode) {
            this.toastService.info('Dieses Gebot befindet sich bereits in Bearbeitung');
            return;
        }

        const copyOfCustomBid: ResidualValueBid = JSON.parse(JSON.stringify(bid));
        this.bidsInEditMode.push(copyOfCustomBid);
    }

    public saveBid(bid: ResidualValueBid): void {
        // Replace bid in report with new bid
        const index = this.report.valuation.customResidualValueBids.findIndex(
            (customBid) => customBid.bidID === bid.bidID,
        );
        if (index > -1) {
            this.report.valuation.customResidualValueBids.splice(index, 1, bid);
        } else {
            this.report.valuation.customResidualValueBids.push(bid);
        }

        addCustomResidualValueBidList({
            report: this.report,
            team: this.loggedInUserService.getTeam(),
            user: this.user,
        });

        this.rememberBidder(bid.bidder);

        this.saveReport();
    }

    public leaveEditMode(bid: ResidualValueBid): void {
        this.bidsInEditMode.splice(this.bidsInEditMode.indexOf(bid), 1);
    }

    public downloadBidDocument(bid: ResidualValueBid, format = 'pdf'): void {
        this.toastService.info('Download gestartet');

        this.httpClient
            .get(
                `/api/v0/reports/${this.report._id}/documents/customResidualValueBidTemplates/${bid.bidID}?format=${format}`,
                {
                    observe: 'response',
                    responseType: 'blob',
                },
            )
            .subscribe({
                next: (response) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (err) => {
                    this.toastService.error('Download fehlgeschlagen', 'Bitte den aX-Support kontaktieren');
                    console.error('DOWNLOADING_CUSTOM_BID_PDF_FAILED', err);
                },
            });
    }

    public removeCustomBid(bid: ResidualValueBid): void {
        this.report.valuation.customResidualValueBids.splice(
            this.report.valuation.customResidualValueBids.indexOf(bid),
            1,
        );

        // If the last custom residual value bid was deleted, remove the report document.
        if (this.report.valuation.customResidualValueBids.length === 0) {
            removeDocumentTypeFromReport({
                report: this.report,
                documentGroup: 'report',
                documentType: 'customResidualValueBidList',
            });
        }

        this.saveReport();
    }

    private async rememberBidder(bidder: Bidder): Promise<void> {
        const contactPersonToRemember: ContactPerson = this.mapBidderToContactPerson(bidder);

        this.contactPersonService
            .find({ organizationType: contactPersonToRemember.organizationType })
            // Only use the cached answer. We want to cache all contacts locally.
            .pipe(first())
            .subscribe({
                next: async (contactPeople) => {
                    const matchingContactPerson = contactPeople.find((contactPerson) => {
                        return (
                            contactPerson.organization === contactPersonToRemember.organization &&
                            contactPerson.zip === contactPersonToRemember.zip &&
                            contactPerson.streetAndHouseNumberOrLockbox ===
                                contactPersonToRemember.streetAndHouseNumberOrLockbox &&
                            contactPerson.firstName === contactPersonToRemember.firstName &&
                            contactPerson.lastName === contactPersonToRemember.lastName
                        );
                    });

                    // If it doesn't exist yet, create it
                    if (!matchingContactPerson) {
                        if (!this.user.accessRights.editContacts) {
                            this.toastService.warn(
                                'Kontakt nicht im Kontaktmanagement gespeichert',
                                `Der neue Kontakt wurde nicht im Kontaktmanagement gespeichert, da dir das Zugriffsrecht ${translateAccessRightToGerman('editContacts')} fehlt.`,
                                { timeOut: 10000 },
                            );
                            return;
                        }

                        const copyOfReportContactPerson = JSON.parse(JSON.stringify(contactPersonToRemember));
                        copyOfReportContactPerson._id = generateId();
                        await this.contactPersonService.create(copyOfReportContactPerson);
                        this.availableBidders.push(copyOfReportContactPerson);
                        return;
                    }

                    // Updating is more complex. Update everything except the ID on the existing lawyer.
                    const copyOfReportContactPerson: ContactPerson = JSON.parse(
                        JSON.stringify(contactPersonToRemember),
                    );

                    // Remove ID so it doesn't overwrite our cache entry's ID
                    delete copyOfReportContactPerson._id;
                    const mergedContactPerson: ContactPerson = Object.assign(
                        {},
                        matchingContactPerson,
                        copyOfReportContactPerson,
                    );

                    if (!this.user.accessRights.editContacts) {
                        this.toastService.warn(
                            'Kontaktdaten nur im Gutachten aktualisiert',
                            `Deine Änderungen am Kontakt wurden nicht in das Kontaktmanagement übertragen, da dir das Zugriffsrecht ${translateAccessRightToGerman('editContacts')} fehlt.`,
                            { timeOut: 10000 },
                        );
                        return;
                    }

                    await this.contactPersonService.put(mergedContactPerson);

                    // Update the contact details in this component. Possibly changed: phone, email
                    const index = this.availableBidders.findIndex((availableContactPerson) => {
                        return (
                            availableContactPerson.organization === contactPersonToRemember.organization &&
                            availableContactPerson.zip === contactPersonToRemember.zip &&
                            availableContactPerson.streetAndHouseNumberOrLockbox ===
                                contactPersonToRemember.streetAndHouseNumberOrLockbox &&
                            availableContactPerson.firstName === contactPersonToRemember.firstName &&
                            availableContactPerson.lastName === contactPersonToRemember.lastName
                        );
                    });

                    this.availableBidders.splice(index, 1, mergedContactPerson);
                },
            });
    }

    private mapBidderToContactPerson(bidder: Bidder): ContactPerson {
        if (!bidder) {
            return;
        }

        const translatedBidderProperties = {
            lastName: bidder.address.contact,
            email: bidder.address.emailAddress,
            phone: bidder.address.telephone,
            phone2: bidder.address.telephone2,
            organization: bidder.address.companyName,
            streetAndHouseNumberOrLockbox: bidder.address.street,
            zip: bidder.address.postcode,
            city: bidder.address.city,
        };

        return Object.assign(new ContactPerson({ organizationType: 'bidder' }), translatedBidderProperties);
    }

    private mapContactPersonToBidder(contactPerson: ContactPerson): Bidder {
        const translatedContactPersonProperties = {
            address: {
                contact: contactPerson.lastName,
                emailAddress: contactPerson.email,
                telephone: contactPerson.phone,
                telephone2: contactPerson.phone2,
                companyName: contactPerson.organization,
                street: contactPerson.streetAndHouseNumberOrLockbox,
                postcode: contactPerson.zip,
                city: contactPerson.city,
            },
        };

        return Object.assign(new Bidder(), translatedContactPersonProperties);
    }

    public selectBidderFromAutocomplete(bidder: ContactPerson, bid: ResidualValueBid): void {
        // Prevent copying the ID
        const bidderCopy: Bidder = this.mapContactPersonToBidder(Object.assign({}, bidder));

        Object.assign(bid.bidder, bidderCopy);
    }

    public insertBidderAddressFromAutocomplete(bidder: Partial<ContactPerson>, bid: ResidualValueBid): void {
        bid.bidder.address.city = bidder.city;
        bid.bidder.address.postcode = bidder.zip;
        bid.bidder.address.street = bidder.streetAndHouseNumberOrLockbox;
        if (bidder.organization) {
            bid.bidder.address.companyName = bidder.organization;
        }
    }

    /**
     * Remove an entry and re-add it if the server connection failed.
     *
     * @param contactPerson
     */
    public async removeAutocompleteEntry(contactPerson: ContactPerson): Promise<void> {
        // Remove from available records and the passed collection immediately. Re-add later if an error occurs.
        const indexInAvailableCollection = this.availableBidders.indexOf(contactPerson);
        if (indexInAvailableCollection > -1) {
            this.availableBidders.splice(indexInAvailableCollection, 1);
        }

        const indexInFilteredCollection = this.filteredBidders.indexOf(contactPerson);
        if (indexInFilteredCollection > -1) {
            this.filteredBidders.splice(indexInFilteredCollection, 1);
        }

        try {
            await this.contactPersonService.delete(contactPerson._id);
        } catch (error) {
            this.toastService.error('Bieter konnte nicht gelöscht werden');
            console.error('BIDDER_AUTOCOMPLETE_OPTION_COULD_NOT_BE_DELETED', { error });

            // Re-add the contact person to the list
            this.availableBidders.splice(indexInAvailableCollection, 0, contactPerson);
            this.filteredBidders.splice(indexInFilteredCollection, 0, contactPerson);

            // Re-add to the local cache
            // this.contactPersonService.create(contactPerson)
            //     .subscribe();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handling custom bids
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  ZIP Code
    //****************************************************************************/
    /**
     * The ZipCityDirective only updates the view's input, but not the model. Do that here.
     * @param bidder
     * @param {string} city
     */
    public insertCityIntoModel(bidder: Bidder, city: string): void {
        bidder.address.city = city;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END ZIP Code
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeBidSelector();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server communication
    //****************************************************************************/
    public async saveReport() {
        await this.reportDetailsService.patch(this.report);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Events
    //****************************************************************************/
    /**
     * Wrapper function over all shortcuts.
     *
     * It's necessary to provide separate functions so that both registering and
     * unregistering have a handle on the same function object.
     */
    private registerKeyboardEvents(): void {
        window.addEventListener('keydown', this.closeEditorOnEscKey);
    }

    @HostListener('window:keydown', ['$event'])
    public keyEventListener(event: KeyboardEvent): void {
        if (event.key === 'Enter' && event.ctrlKey) {
            // Blur so that the change event is fired, updating the model, so that the latest input value is used from now on.
            if (document.activeElement && document.activeElement.tagName === 'INPUT') {
                (document.activeElement as HTMLInputElement).blur();
            }

            const targetBid = this.bidsInEditMode[0];
            this.saveBid(targetBid);
            this.leaveEditMode(targetBid);
            this.filterAndSortBids();
        }
        if (
            event.key === 'n' &&
            document.activeElement.tagName !== 'INPUT' &&
            document.activeElement.tagName !== 'TEXTAREA'
        ) {
            this.addCustomBid();
        }
    }

    private unregisterKeyboardEvents(): void {
        window.removeEventListener('keydown', this.closeEditorOnEscKey);
    }

    private closeEditorOnEscKey = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
            this.closeBidSelector();
        }
    };

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.unregisterKeyboardEvents();
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected readonly isAdmin = isAdmin;
    protected readonly hasAccessRight = hasAccessRight;
}

import { OldtimerValuationGrades } from '@autoixpert/models/reports/market-value/oldimter-valuation-grades';

export const gradeDescriptions: { [k in keyof OldtimerValuationGrades]: string[] } = {
    total: [],
    body: [
        'Spaltmaße absolut einheitlich und eng gefasst (4-8 mm), perfektes Schliessverhalten, absolut exakte Übergänge, keinerlei Rost',
        'Spaltmaße weitgehend einheitlich und eng gefasst (4-8 mm), gutes Schliessverhalten, saubere Übergänge keinerlei Rost',
        'Spaltmaße weisen leichte Unregelmäßigkeiten auf, gutes Schliessverhalten, Übergänge mit leichtem Versatz, leichter Oberflächenrost',
        'Spaltmaße unregelmäßig, Schliessverhalten verbesserungsbedürftig, Übergänge mit gut sichtbarem Versatz, starke Verrostungen',
        'Spaltmaße völlig uneinheitlich, Schliessung z.T. nur mit Kraftaufwand möglich, Übergänge mit erheblichem Versatz, Durchrostungen',
    ],
    paint: [
        'Spiegelgrad 1:1, sehr geringer Spachtel, keinerlei Staubeinschlüsse, keine Krater, keinerlei Trichterbildung bei Übergängen',
        'Sehr guter Spiegelgrad, geringer Spachtel, kaum Staubeinschlüsse, kaum Krater, wenig Trichterbildung an Übergängen',
        'Leichter Schleier, vereinzelt Staubeinschlüsse, leichte Krater und Orangenhaut, leichte Trichterbildung an Übergängen',
        'Lack stumpf und matt, geringer Spiegelgrad, Krater und Orangenhaut, leichte Risse und Abplatzungen, erhebliche Trichterbildung',
        'Lack erheblich verwittert, Abnutzung bis auf blankes Blech, Risse u. Abplatzungen, Neulackierung unumgänglich',
    ],
    chrome: [
        'Chrom ist perfekt; keinerlei Pickel, Kratzer, Schleifriefen, Beulen etc., Reflexion spiegelgleich, höchste Glanzstufe',
        'Chrom ist gut, keine Kratzer, keine Riefen und ganz leichte Ermattungen, guter Spiegelgrad, keinerlei Beschädigungen',
        'Chrom leicht wellig und matt, leichte Kratzer und Riefen, ganz leichte Pickel, etwas matt in der Öberfläche, undeutliche Spiegelung',
        'Chrom weist Abplatzungen auf, erhebliche Kratzer und Riefen, keine Spiegelung mehr, erhebliche Pickel',
        'Chrom hat erhebliche Abplatzungen, Kratzer und Riefen, unvollständig, Durchrostungen',
    ],
    rubber: [
        'Wie Neuzustand; weich und flexibel, Dichtfunktion optimal, exakte Montage, keinerlei Ausfransungen oder poröse Bereiche',
        'Gepflegter älterer Zustand; weich und flexibel, Dichtfunktion optimal, exakte Montage, keinerlei Ausfransungen oder poröse Bereiche',
        'Älterer Zustand, leichte Risse und Ausfransungen, Verhärtungen, Dichtfunktion gegeben, z.T. leichte Ablösungen und poröse Bereiche',
        'Verbrauchter Zustand, Risse, Ausfransungen, Dichtfunktion eingeschränkt',
        'Erheblich verbrauchter Zustand, Fehlteile, Dichtfunktion nicht mehr gegeben, z.T. nur Fragmente vorhanden',
    ],
    glass: [
        'Glas ist neuwertig, keinerlei Kratzer oder Risse, keine Steinschläge oder Erblindungen, exakter Einbau',
        'Glas in gutem Zustand, keine Kratzer, keine Risse, keinerlei Steinschläge oder Erblindungen, exakter Einbau',
        'Glas in gebrauchtem Zustand, keine Risse, leichte Kratzer oder Steinschläge, ggf. leichte Erblindungen an Außenkanten',
        'Glas in verbrauchtem Zustand, leichte Risse, erhebliche Kratzer und Steinschläge, Erblindungen, Delamination bei Verbundglas',
        'Glas in verbrauchtem Zustand, Fehlteile, Risse, Kratzer und Steinschläge, Erblindungen und Delamination',
    ],
    tires: [
        'Reifen neuwertig, historisch entsprechend Reifenformat, volle Profiltiefe, keinerlei Abnutzung oder Beschädigungen sichtbar, Alter max. 1 Jahr',
        'Reifen in gutem Zustand, historisch entsprechend Reifenformat, min. 6-7 mm Profiltiefe, Alter max. 3 Jahre',
        'Reifen in gebrauchtem Zustand, min. 3-5 mm Profiltiefe, keine Beschädigungen sichtbar, Alter max.5 Jahre',
        'Reifen in verbrauchtem Zustand, Profiltiefe nicht ausreichend, Reifen rissig, nicht TÜV-konform',
        'Reifen unbrauchbar, Luftverlust, Rissbildung, nicht TÜV-konform',
    ],
    cabin: [
        'Interieur in neuwertigem, perfektem Zustand, keinerlei Abnutzungsspuren oder Verunreinigungen, handwerklich perfekte Arbeit',
        'Interieur in nahezu neuwertigem, leicht gebrauchten Zustand, sehr geringe Abnutzungsspuren und Verunreinigungen',
        'Interieur in gebrauchtem Zustand, leichte Abnutzungen und Abrieb, geringe Verunreinigungen, keine defekten Teile',
        'Interieur in erheblich gebrauchten Zustand, Abnutzung und Abrieb, Risse und Verunreinigungen, defekte Teile',
        'Interieur in völlig verbrauchtem Zustand, Fehlteile, erhebliche Abnutzung und Disfunktion, starke Verunreinigungen',
    ],
    engineCompartment: [
        'Neuwertiger Eindruck, einheitliche Lackierung, neue Verzinkung und Verchromung der Anbauteile, völlig schmutz- und rostfrei, optisch absolut sauber',
        'Nahezu neuwertiger, leicht gebrauchter Zustand; einheitliche Lackierung, gute Verzinkung und Verchromung der Anbauteile, völlig rostfrei und sauber',
        'Gebrauchter Zustand, Flugrost in Falzen, leichte Verunreinigungen, ältere Verzinkung und Verchromung der Anbauteile',
        'Stark gebrauchter Zustand, Rostbildung erheblich, Anbauteile erheblich gebraucht und verunreinigt, starke Öl- und Schmierrückstände',
        'Verbrauchter Zustand, Fehlteile, sehr starke Verunreinigungen, starker Rostbefall und Durchrostungen, erhebliche Öl- und Schmierrückstände',
    ],
    engine: [
        'Motor in neuem oder vollständig überholten Zustand, Zylinder mit voller Kompression, alle Lager und Verschleißteile ersetzt, auch optisch im perfektem Zustand',
        'Motor in leicht gebrauchtem Zustand, Überholung oder Ersatz kürzlich erfolgt, Zylinder mit voller Kompression, kleine Gebrauchsspuren',
        'Motor in gebrauchtem aber voll funktionsfähigen Zustand, Überholung oder Ersatz liegt länger (4 – 7 Jahre) zurück, leichte Flüssigkeitsverluste',
        'Motor in stark gebrauchtem Zustand, Überholung liegt lange zurück, Reparaturen erforderlich, Flüssigkeitsverluste, Kompressionsverlust',
        'Motor in verbrauchtem Zustand, Fehlteile, vollständige Überholung erforderlich, starke Verunreinigungen und evtl. irreparable Schäden',
    ],
    trunk: [
        'Kofferraum in neuwertigem, sauberen Zustand, Ladekante weist keinerlei Kratzer auf, Bordwerkzeug und Reserverad vorhanden, gutes Geruchsbild',
        'Kofferraum in nahezu neuwertigem Zustand, kleinste Abnutzungen an der Ladekante, gutes Geruchsbild, Bordwerkzeug und Reserverad vorhanden',
        'Kofferraum in gebrauchtem Zustand, Abnutzungen an der Ladekante, Flugrost am Kofferraumboden, gutes Geruchsbild, Löcher an Verkleidungen',
        'Kofferraum in stark gebrauchtem Zustand, erhebliche Abnutzung von Verkleidungen und Ladekante, ggf. Feuchtigkeit, Rost am Kofferraumboden',
        'Kofferraum in verbrauchtem Zustand, Durchrostungen, defekte Verkleidungen, Fehlteile, starke Geruchsbildung, Verunreinigungen',
    ],
};

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { toIsoDate, todayIso } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { addDocumentToInvoice } from '@autoixpert/lib/documents/add-document-to-invoice';
import { removeDocumentFromInvoice } from '@autoixpert/lib/documents/remove-document-from-invoice';
import { getUnpaidAmount } from '@autoixpert/lib/invoices/get-unpaid-amount';
import { paymentReminderLevelToDocumentType } from '@autoixpert/lib/invoices/payment-reminders/payment-reminder-level-to-document-type';
import { paymentReminderToDocumentTitle } from '@autoixpert/lib/invoices/payment-reminders/payment-reminder-to-document-title';
import { getUserFullName } from '@autoixpert/lib/users/get-user-full-name';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AxError } from '@autoixpert/models/errors/ax-error';
import {
    Invoice,
    InvoiceInvolvedParty,
    InvoiceInvolvedPartyRole,
    PaymentReminders,
} from '@autoixpert/models/invoices/invoice';
import { PaymentReminder, PaymentReminderLevel } from '@autoixpert/models/invoices/payment-reminder';
import { User } from '@autoixpert/models/user/user';
import { areContactPeopleEqual } from '../../shared/libraries/are-contact-people-equal';
import { getRelativeDate } from '../../shared/libraries/get-relative-date';
import { triggerClickEventOnSpaceBarPress } from '../../shared/libraries/trigger-click-event-on-space-bar-press';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { DownloadService } from '../../shared/services/download.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'payment-reminder-dialog',
    templateUrl: 'payment-reminder-dialog.component.html',
    styleUrls: ['payment-reminder-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class PaymentReminderDialogComponent {
    constructor(
        protected userPreferences: UserPreferencesService,
        private invoiceService: InvoiceService,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private toastService: ToastService,
        private downloadService: DownloadService,
        private apiErrorService: ApiErrorService,
        private httpClient: HttpClient,
        private tutorialStateService: TutorialStateService,
        private router: Router,
    ) {}

    @Input() invoice: Invoice;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() documentCreate: EventEmitter<DocumentMetadata> = new EventEmitter<DocumentMetadata>();

    // Temporary payment reminder to avoid changing data of other payment reminders than the selected by accident.
    private currentNewPaymentReminder: PaymentReminder;
    private selectedRecipientRole: InvoiceInvolvedPartyRole;
    private selectedPaymentReminderLevel: PaymentReminderLevel;

    protected paymentPeriodSettingsShown: boolean = false;
    protected previewDownloadPending: boolean = false;

    protected teamMembers: User[] = [];
    private user: User;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.teamMembers = this.userService.getAllTeamMembersFromCache();
        this.selectDefaultRecipient();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * If the selected payment reminder already exists, it is returned. Otherwise, null is returned.
     */
    get existingPaymentReminder(): PaymentReminder | null {
        return this.selectedRecipient.paymentReminders[this.selectedPaymentReminderLevel];
    }

    /**
     * Get the current selected payment reminder.
     * This could be an existing payment reminder - or the current new payment reminder.
     */
    protected get selectedPaymentReminder(): PaymentReminder & { wasCreatedBefore?: boolean } {
        // If the payment reminder was created before, return the existing one.
        if (this.existingPaymentReminder) {
            // Add a property to the existing payment reminder to indicate that it was created before.
            // Spread ensures that the existing payment reminder is not mutated.
            return { ...this.existingPaymentReminder, wasCreatedBefore: true };
        }
        // Return the current new payment reminder.
        return this.currentNewPaymentReminder;
    }

    /**
     * The selected recipient should always exist.
     */
    private get selectedRecipient(): InvoiceInvolvedParty {
        return this.getInvolvedPartyByRole(this.selectedRecipientRole);
    }

    /**
     * Get the involved party object for a given role.
     */
    private getInvolvedPartyByRole(recipientRole: InvoiceInvolvedPartyRole): InvoiceInvolvedParty {
        if (recipientRole === 'invoiceRecipient') {
            return this.invoice.recipient;
        }
        return this.invoice[recipientRole];
    }

    private getPaymentReminder(
        level: PaymentReminderLevel,
        recipientRole: InvoiceInvolvedPartyRole = this.selectedRecipientRole,
    ): PaymentReminder {
        const recipient = this.getInvolvedPartyByRole(recipientRole);
        return recipient.paymentReminders[level];
    }

    protected paymentReminderExists(
        level: PaymentReminderLevel,
        recipientRole: InvoiceInvolvedPartyRole = this.selectedRecipientRole,
    ): boolean {
        return !!this.getPaymentReminder(level, recipientRole);
    }

    //*****************************************************************************
    //  Select Payment Reminder (manually or automatically)
    //****************************************************************************/

    /**
     * This function may be used to select the payment reminder recipient and the payment reminder level when this dialog component is opened.
     * It ensures that the user always starts within the payment reminder process of the recipient that has been reminded the most. That's typically
     * the recipient to whom the assessor wants to send the next payment reminder, too.
     * Only in vary rare occasions, this may change.
     */
    private getHighestPaymentReminderOfAllRecipients(): PaymentReminder | null {
        const lawyerHighestLevel = this.getHighestExistingPaymentReminderForRecipient(this.invoice.lawyer);
        const insuranceHighestLevel = this.getHighestExistingPaymentReminderForRecipient(this.invoice.insurance);
        const recipientHighestLevel = this.getHighestExistingPaymentReminderForRecipient(this.invoice.recipient);

        const paymentReminderLevels = ['level3', 'level2', 'level1', 'level0'];
        for (const level of paymentReminderLevels) {
            if (lawyerHighestLevel === level) {
                return this.invoice.lawyer.paymentReminders[level];
            }

            if (insuranceHighestLevel === level) {
                return this.invoice.insurance.paymentReminders[level];
            }
            if (recipientHighestLevel === level) {
                return this.invoice.recipient.paymentReminders[level];
            }
        }

        return null;
    }

    /**
     * If payment reminders already exist, the recipient of the highest payment reminder level is selected.
     * If no payment reminders exist, the recipient is selected according to the usual way of sending payment reminders:
     * 1: lawyer, 2: insurance, 3: invoice recipient.
     */
    private selectDefaultRecipient() {
        const highestPaymentReminder = this.getHighestPaymentReminderOfAllRecipients();
        /**
         * If previous payment reminders were sent, let the user see that process when he opens this dialog.
         */
        if (highestPaymentReminder) {
            this.selectRecipientRole(highestPaymentReminder.recipientRole);
        } else {
            /**
             * If this is an invoice for a liability report, an insurance and/or a lawyer are involved. If a lawyer is involved, the assessor usually does
             * not communicate with the insurance directly but through the lawyer, so the lawyer is initially selected.
             *
             * In invoices which are not related to a liability, e.g. for valuations, the payment reminder will be sent to the invoice recipient directly.
             */
            if (this.invoice.lawyer?.contactPerson.organization) {
                this.selectRecipientRole('lawyer');
            } else if (this.invoice.insurance?.contactPerson.organization) {
                this.selectRecipientRole('insurance');
            } else {
                this.selectRecipientRole('invoiceRecipient');
            }
        }
    }

    /**
     * Select a recipient role.
     * If no payment reminders exist, the structure will be created.
     * If payment reminders exist, the next highest payment reminder will be selected.
     */
    protected selectRecipientRole(recipientRole: InvoiceInvolvedPartyRole): void {
        this.selectedRecipientRole = recipientRole;

        this.ensureSelectedRecipientHasPaymentReminderStructure();
        this.selectNextHighestPaymentReminderForRecipient();
        this.initializeSelectedPaymentReminderIfNotExist();
    }

    /**
     * Select a payment reminder level.
     * If the payment reminder level does not exist yet, a new one will be created to be edited.
     */
    protected selectPaymentReminderLevel(level: PaymentReminderLevel): void {
        this.selectedPaymentReminderLevel = level;
        this.initializeSelectedPaymentReminderIfNotExist();
    }

    /**
     * Initialize the payment reminder structure for the selected recipient (if it doesn't exist yet).
     */
    private ensureSelectedRecipientHasPaymentReminderStructure() {
        if (!this.selectedRecipient.paymentReminders) {
            this.selectedRecipient.paymentReminders = new PaymentReminders();
        }
    }

    /**
     * Select the next highest payment reminder level for the selected recipient.
     */
    private selectNextHighestPaymentReminderForRecipient() {
        const currentHighestPaymentReminderLevel = this.getHighestExistingPaymentReminderForRecipient(
            this.selectedRecipient,
        );
        this.selectedPaymentReminderLevel = this.getNextHigherPaymentReminderLevel(currentHighestPaymentReminderLevel);
    }

    /**
     * If a payment reminder for the selected recipient and level does not exist yet, a new one will be created.
     * The defaults (e. g. payment reminder offset) are taken from the user preferences.
     */
    private initializeSelectedPaymentReminderIfNotExist() {
        // No need to create a new payment reminder if one already exists.
        if (this.existingPaymentReminder) {
            return;
        }

        this.currentNewPaymentReminder = this.initializeNewPaymentReminder({
            level: this.selectedPaymentReminderLevel,
            recipientRole: this.selectedRecipientRole,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select Payment Reminder (manually or automatically)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  UI Helpers
    //****************************************************************************/

    /**
     * If claimant and invoice recipient are the same contact person, the claimant is not shown as a separate recipient.
     */
    protected isClaimantEqualToInvoiceRecipient(): boolean {
        return areContactPeopleEqual(this.invoice.claimant?.contactPerson, this.invoice.recipient.contactPerson);
    }

    /**
     * Insert the invoices unpaid amount as the amount of the payment reminder.
     */
    protected insertUnpaidAmount(): void {
        this.selectedPaymentReminder.amountNet = getUnpaidAmount(this.invoice);
    }

    /**
     * Get the full name of the user who created the selected payment reminder.
     */
    protected get selectedPaymentReminderAssessorName(): string {
        if (this.selectedPaymentReminder.wasCreatedBefore) {
            const creatorUserId = this.selectedPaymentReminder.senderId;
            const creatorUser = this.teamMembers.find((user) => user._id === creatorUserId);
            return getUserFullName(creatorUser);
        }
        return getUserFullName(this.user);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END UI Helpers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    // Create / Preview / Save Payment Reminder
    //****************************************************************************/

    /**
     * Returns a new payment reminder for a given recipient and level.
     * Fills the unpaid amount and the due date according to the user's preferences.
     */
    private initializeNewPaymentReminder({
        level,
        recipientRole,
    }: {
        level: PaymentReminderLevel;
        recipientRole: InvoiceInvolvedPartyRole;
    }): PaymentReminder {
        let offsetAmount: number;
        let offsetUnit: 'businessDays' | 'calendarDays';

        switch (level) {
            case 'level0':
                offsetAmount = this.userPreferences.paymentReminderLevel0TimeOffset;
                offsetUnit = this.userPreferences.paymentReminderLevel0TimeOffsetUnit;
                break;
            case 'level1':
                offsetAmount = this.userPreferences.paymentReminderLevel1TimeOffset;
                offsetUnit = this.userPreferences.paymentReminderLevel1TimeOffsetUnit;
                break;
            case 'level2':
                offsetAmount = this.userPreferences.paymentReminderLevel2TimeOffset;
                offsetUnit = this.userPreferences.paymentReminderLevel2TimeOffsetUnit;
                break;
            case 'level3':
                offsetAmount = this.userPreferences.paymentReminderLevel3TimeOffset;
                offsetUnit = this.userPreferences.paymentReminderLevel3TimeOffsetUnit;
                break;
        }

        let dueDate: IsoDate;
        if (offsetAmount && offsetUnit) {
            dueDate = toIsoDate(getRelativeDate(offsetAmount, offsetUnit).format());
        }

        return new PaymentReminder({
            level,
            recipientRole,
            date: todayIso(),
            dueDate,
            senderId: this.loggedInUserService.getUser()._id,
            amountNet: getUnpaidAmount(this.invoice),
        });
    }

    /**
     * Save the current payment reminder as new payment reminder and create the document.
     */
    protected createAndSaveNewPaymentReminder() {
        // Save the payment reminder data
        this.selectedRecipient.paymentReminders[this.selectedPaymentReminderLevel] = this.currentNewPaymentReminder;

        // Set timestamp to facilitate filtering by overdue payment reminders in the invoice list.
        this.invoice.nextPaymentReminderAt = this.selectedPaymentReminder.dueDate;

        this.addPaymentReminderDocumentToInvoice(this.selectedPaymentReminderLevel, this.selectedRecipientRole);
        this.saveInvoice();
    }

    /**
     * Generate the metadata for the payment reminder document.
     * This may be used to create a new document or to create the document preview.
     */
    private generatePaymentReminderDocumentMetadata(
        level: PaymentReminderLevel,
        recipientRole: InvoiceInvolvedPartyRole,
    ): DocumentMetadata {
        return new DocumentMetadata({
            createdBy: this.loggedInUserService.getUser()._id,
            type: paymentReminderLevelToDocumentType(level),
            title: paymentReminderToDocumentTitle(level, recipientRole),

            // For the reminder date to be printed, we must pass it on to the DocumentMetadata.
            date: this.selectedPaymentReminder.date,
            recipientRole,
        });
    }

    /**
     * Create the payment reminder document and add it to the invoice
     */
    private addPaymentReminderDocumentToInvoice(
        level: PaymentReminderLevel,
        recipientRole: InvoiceInvolvedPartyRole,
    ): void {
        const newDocument = this.generatePaymentReminderDocumentMetadata(level, recipientRole);
        try {
            // Add the new document.
            addDocumentToInvoice(
                {
                    newDocument,
                    invoice: this.invoice,
                    team: this.loggedInUserService.getTeam(),
                },
                {
                    allowMultiple: true,
                },
            );
        } catch (error) {
            /**
             * We had multiple situations in 01/2025 and before, where the document order structure was not valid, the addDocumentToInvoice crashed and the invoice was left in an invalid state (paymentReminder added but nextPaymentReminderAt not set).
             * Unfortunately, we currently have no idea how we can reproduce this error. Therefore, we have to catch it here and inform the user about it.
             * We decided not to throw an error here, since we don't want to interrupt the user's workflow.
             *
             * TODO: Remove this as soon as we know why we sometimes have missing document order structures.
             */
            this.toastService.warn(
                'Zahlungserinnerung nicht für alle Empfänger hinzugefügt',
                'Die Zahlungserinnerung konnte nicht für alle Empfänger hinzugefügt werden, vermutlich ist die Dokument-Order-Struktur nicht gültig. Kontaktiere die Hotline.',
            );
            Sentry.captureException(
                new AxError({
                    code: 'ERROR_ADDING_PAYMENT_REMINDER_TO_INVOICE',
                    message: 'Adding payment reminder document to invoice failed',
                    error,
                }),
            );
            console.error('ERROR_ADDING_PAYMENT_REMINDER_TO_INVOICE, see causedBy:', { error });
        }

        this.documentCreate.emit(newDocument);

        const toast = this.toastService.success(
            `${newDocument.title} erstellt`,
            'Du findest sie in der Rechnung im Reiter <i>Druck & Versand</i>.<br><br>Zum Öffnen diese Nachricht klicken.',
        );
        toast.click.subscribe(() => this.router.navigate(['Rechnungen', this.invoice._id, 'Druck-und-Versand']));

        this.tutorialStateService.markUserTutorialStepComplete('paymentReminderCreated');
    }

    /**
     * Download the payment reminder document as a preview.
     */
    protected previewPaymentReminderDocument(): void {
        this.previewDownloadPending = true;

        /**
         * A few params (document title + recipient role) are not saved on the payment reminder
         * object but on the DocumentMetadata object which will only be created later.
         * Therefore, send those parameters through the query.
         */
        const { type, title, recipientRole } = this.generatePaymentReminderDocumentMetadata(
            this.selectedPaymentReminderLevel,
            this.selectedRecipientRole,
        );
        const queryParams: {
            documentTitleForPreview: string;
            format: 'pdf' | 'docx';
        } & PaymentReminder = {
            recipientRole: recipientRole as InvoiceInvolvedPartyRole,
            format: 'pdf',
            documentTitleForPreview: title,
            ...this.selectedPaymentReminder,
        };

        const urlSearchParams = new URLSearchParams();
        /**
         * Convert all query parameters to strings.
         */
        for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key)) {
                if (queryParams[key] === undefined) {
                    continue;
                }

                let value: string = queryParams[key];
                if (typeof value !== 'string') {
                    value = String(queryParams[key]);
                }
                urlSearchParams.append(key, value);
            }
        }
        this.httpClient
            .get(`/api/v0/invoices/${this.invoice._id}/documents/${type}?${urlSearchParams.toString()}`, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                    this.previewDownloadPending = false;
                },
                error: (error) => {
                    this.previewDownloadPending = false;
                    console.error('ERROR_DOWNLOADING_PAYMENT_REMINDER_PREVIEW', { error });
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Fehler beim Download',
                            body: 'Das Dokument konnte nicht generiert werden. Bitte kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                        },
                    });
                },
            });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    // END Create / Preview / Save Payment Reminder
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Delete Payment Reminder
    //****************************************************************************/

    /**
     * Deletes the selected payment reminder and the corresponding document.
     */
    protected deleteSelectedPaymentReminder() {
        this.selectedRecipient.paymentReminders[this.selectedPaymentReminderLevel] = null;
        this.deletePaymentReminderDocument(this.selectedPaymentReminderLevel, this.selectedRecipientRole);
        this.saveInvoice();
        this.toastService.success(
            `${paymentReminderToDocumentTitle(this.selectedPaymentReminderLevel, this.selectedRecipientRole)} gelöscht`,
        );
        this.initializeSelectedPaymentReminderIfNotExist();
    }

    /**
     * Deletes a payment reminder document for a given level and recipient.
     */
    private deletePaymentReminderDocument(level: PaymentReminderLevel, recipientRole: InvoiceInvolvedPartyRole) {
        const documentType = paymentReminderLevelToDocumentType(level);
        const documentToDelete = this.invoice.documents.find(
            (document) => document.type === documentType && document.recipientRole === recipientRole,
        );

        // Return if the document does not exist.
        if (!documentToDelete) {
            return;
        }
        removeDocumentFromInvoice({ document: documentToDelete, invoice: this.invoice });
    }

    /**
     * Removes all payment reminders from the invoice (including the corresponding documents).
     */
    protected resetAllPaymentReminders(): void {
        if (this.invoice.lawyer) {
            this.resetPaymentReminderDocumentsForInvolvedParty('lawyer');
            this.invoice.lawyer.paymentReminders = new PaymentReminders();
        }
        if (this.invoice.insurance) {
            this.resetPaymentReminderDocumentsForInvolvedParty('insurance');
            this.invoice.insurance.paymentReminders = new PaymentReminders();
        }
        if (this.invoice.claimant) {
            this.resetPaymentReminderDocumentsForInvolvedParty('claimant');
            this.invoice.claimant.paymentReminders = new PaymentReminders();
        }

        this.resetPaymentReminderDocumentsForInvolvedParty('invoiceRecipient');
        this.invoice.recipient.paymentReminders = new PaymentReminders();

        this.saveInvoice();
        this.closeEditor();
    }

    /**
     * Removes all payment reminders documents for a given involved party role (including the corresponding documents).
     */
    private resetPaymentReminderDocumentsForInvolvedParty(involvedPartyRole: InvoiceInvolvedPartyRole) {
        this.deletePaymentReminderDocument('level0', involvedPartyRole);
        this.deletePaymentReminderDocument('level1', involvedPartyRole);
        this.deletePaymentReminderDocument('level2', involvedPartyRole);
        this.deletePaymentReminderDocument('level3', involvedPartyRole);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Delete Payment Reminder
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Lib
    //****************************************************************************/

    /**
     * Get the next higher payment reminder level.
     */
    private getNextHigherPaymentReminderLevel(currentLevel: PaymentReminderLevel): PaymentReminderLevel {
        switch (currentLevel) {
            case 'level0':
                return 'level1';
            case 'level1':
                return 'level2';
            case 'level2':
                return 'level3';
            case 'level3':
                return 'level3';
            default:
                return 'level0';
        }
    }

    /**
     * Gets the highest payment reminder level.
     */
    private getHighestExistingPaymentReminderForRecipient(
        recipient: InvoiceInvolvedParty,
    ): PaymentReminderLevel | null {
        if (!recipient || !recipient.paymentReminders) return null;

        if (recipient.paymentReminders.level3) {
            return 'level3';
        }
        if (recipient.paymentReminders.level2) {
            return 'level2';
        }
        if (recipient.paymentReminders.level1) {
            return 'level1';
        }
        if (recipient.paymentReminders.level0) {
            return 'level0';
        }
        return null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lib
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Payment Period Settings
    //****************************************************************************/
    protected showPaymentPeriodSettings(): void {
        this.paymentPeriodSettingsShown = true;
    }

    protected hidePaymentPeriodSettings(): void {
        this.paymentPeriodSettingsShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payment Period Settings
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    protected closeEditor(): void {
        this.close.emit();
    }

    protected handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeEditor();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    /**
     * Save the invoice.
     * Ensure 'hasPaymentReminder' and 'nextPaymentReminderAt' are up to date.
     */
    private saveInvoice(): void {
        const highestPaymentReminder = this.getHighestPaymentReminderOfAllRecipients();
        this.invoice.hasPaymentReminder = !!highestPaymentReminder;
        if (highestPaymentReminder) {
            this.invoice.nextPaymentReminderAt = highestPaymentReminder.dueDate;
        }
        this.invoiceService.put(this.invoice);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Handlers
    //****************************************************************************/

    /**
     * Get the function to trigger a click event on pressing the space bar from the
     * shared module.
     *
     * @type {(event:KeyboardEvent)=>void}
     */
    protected triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;

    @HostListener('window:keydown', ['$event'])
    protected handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeEditor();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

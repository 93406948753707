import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'icon-picker',
    templateUrl: './icon-picker.component.html',
    styleUrls: ['./icon-picker.component.scss'],
})
export class IconPickerComponent {
    @ViewChild('iconPickerTrigger') iconPickerTrigger?: MatMenuTrigger;

    @Input() set icon(icon: string) {
        this.selectedIcon = icon;
    }

    @Output() iconPicked: EventEmitter<{ icon: string }> = new EventEmitter<{ icon: string }>();

    protected menuOpen = false;
    protected selectedIcon: string = 'add';

    protected handleSelectedIconChange({ icon }: { icon: string }) {
        this.iconPickerTrigger?.closeMenu();
        this.selectedIcon = icon;
        this.iconPicked.emit({ icon });
    }
}

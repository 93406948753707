import { HttpClient, HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as IBAN from 'iban';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { fadeInAndSlideAnimation } from '@autoixpert/animations/fade-in-and-slide.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { arrayIncludesAnyOfTheseValues } from '@autoixpert/lib/arrays/array-includes-any-of-these-values';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { GERMAN_DATE_FORMAT } from '@autoixpert/lib/ax-luxon';
import { Environment } from '@autoixpert/lib/environment/environment';
import { generateId } from '@autoixpert/lib/generate-id';
import { isEuropeanVatIdValid } from '@autoixpert/lib/invoices/taxes/validate-european-vat-id';
import { isGermanTaxIdValid } from '@autoixpert/lib/invoices/taxes/validate-german-tax-id';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import { isQapterixpertTeam } from '@autoixpert/lib/is-qapterixpert-team';
import { getFullName } from '@autoixpert/lib/placeholder-values/get-full-name';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { getEmailFromOptions } from '@autoixpert/lib/users/get-email-from-options';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { isAudatexTestAccount } from '@autoixpert/lib/users/is-audatex-test-account';
import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { isAutoonlineUserComplete } from '@autoixpert/lib/users/is-autoonline-user-complete';
import { isDatTestAccount } from '@autoixpert/lib/users/is-dat-test-account';
import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { isGtmotiveUserComplete } from '@autoixpert/lib/users/is-gtmotive-user-complete';
import { isTeamMatureCustomer } from '@autoixpert/lib/users/is-team-mature-customer';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { AxEmailOAuthProviderName } from '@autoixpert/models/internal-access-token';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { BankAccount, Team } from '@autoixpert/models/teams/team';
import { CredentialPropertyPath } from '@autoixpert/models/user/preferences/credentials-property-path';
import { EmailSignature } from '@autoixpert/models/user/preferences/email-signature';
import { DefaultFactoringProvider, UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { DatNetworkType } from '@autoixpert/models/user/third-party-accounts/dat-user';
import { Crashback24User, PersaldoUser, SealConfiguration, User } from '@autoixpert/models/user/user';
import { TEST_PERIOD_DURATION_IN_DAYS } from '@autoixpert/static-data/test-period-duration-in-days';
import { checkIfOfficeLocationComplete } from 'src/app/shared/libraries/check-if-office-location-complete';
import { UserCredentialsSynchronizationService } from 'src/app/shared/services/user-credentials-synchronization.service';
import { environment } from '../../../environments/environment';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../shared/animations/run-child-animations.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { IbanChangeEvent } from '../../shared/directives/iban-input.directive';
import { blobToBase64 } from '../../shared/libraries/blob-to-base64';
import { defaultSalutations } from '../../shared/libraries/default-salutations';
import { getAudatexErrorHandlers } from '../../shared/libraries/error-handlers/get-audatex-error-handlers';
import { getDatErrorHandlers } from '../../shared/libraries/error-handlers/get-dat-error-handlers';
import { getGtmotiveErrorHandlers } from '../../shared/libraries/error-handlers/get-gtmotive-error-handlers';
import { getAskYourAdminTooltip } from '../../shared/libraries/get-ask-your-admin-tooltip';
import { getMissingAccessRightTooltip } from '../../shared/libraries/get-missing-access-right-tooltip';
import { getProductName } from '../../shared/libraries/get-product-name';
import { getVatRate } from '../../shared/libraries/get-vat-rate-2020';
import { resizePhoto } from '../../shared/libraries/photos/resize-photo';
import { scrollToTheTop } from '../../shared/libraries/scroll-to-the-top';
import { createCompareableAddressString } from '../../shared/libraries/strings/compare-address';
import { hasAccessRight } from '../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { AudatexTaskService } from '../../shared/services/audatex/audatex-task.service';
import { AutoonlineCredentialsCheckService } from '../../shared/services/autoonline/autoonline-credentials-check.service';
import { BicAndBankName } from '../../shared/services/bic.service';
import { ContactPersonService } from '../../shared/services/contact-person.service';
import { Crashback24Service } from '../../shared/services/crashback24.service';
import { DatAuthenticationService } from '../../shared/services/dat-authentication.service';
import { DownloadService } from '../../shared/services/download.service';
import { EmailSignatureService } from '../../shared/services/emailSignature.service';
import { EmailSignatureImageService } from '../../shared/services/emailSignatureImage.service';
import { GtmotiveEstimateService } from '../../shared/services/gtmotive/gtmotive-estimate.service';
import { InvoiceHistoryService } from '../../shared/services/invoice-history.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { NewWindowService } from '../../shared/services/new-window.service';
import { OrderAndCancellationService } from '../../shared/services/order-and-cancellation.service';
import { PersaldoService } from '../../shared/services/persaldo.service';
import { ProfilePictureFileService } from '../../shared/services/profile-picture-file.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { TeamLogoService } from '../../shared/services/team-logo.service';
import { TeamService } from '../../shared/services/team.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserSealService } from '../../shared/services/user-seal.service';
import { UserService } from '../../shared/services/user.service';
import { AxPasswordDialogComponent } from '../ax-password-dialog/ax-password-dialog.component';
import { ChangeUsernameDialogComponent } from '../change-username-dialog/change-username-dialog.component';
import {
    SubscriptionDialogComponent,
    SubscriptionDialogComponentConfig,
} from '../subscription-dialog/subscription-dialog.component';

@Component({
    selector: 'preferences-overview',
    templateUrl: 'preferences-overview.component.html',
    styleUrls: ['preferences-overview.component.scss'],
    animations: [fadeInAndOutAnimation(), runChildAnimations(), slideInAndOutVertically(), fadeInAndSlideAnimation()],
})
export class PreferencesOverviewComponent {
    constructor(
        public userPreferences: UserPreferencesService,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private teamService: TeamService,
        private toastService: ToastService,
        private screenTitleService: ScreenTitleService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private apiErrorService: ApiErrorService,
        private httpClient: HttpClient,
        private downloadService: DownloadService,
        private userSealService: UserSealService,
        private teamLogoService: TeamLogoService,
        public tutorialStateService: TutorialStateService,
        private persaldoService: PersaldoService,
        private crashback24Service: Crashback24Service,
        private contactPersonService: ContactPersonService,
        private datAuthenticationService: DatAuthenticationService,
        private audatexTaskService: AudatexTaskService,
        private gtmotiveEstimateService: GtmotiveEstimateService,
        private invoiceHistoryService: InvoiceHistoryService,
        private autoonlineCredentialsCheckService: AutoonlineCredentialsCheckService,
        private emailSignatureService: EmailSignatureService,
        private emailSignatureImageService: EmailSignatureImageService,
        private profilePictureFileService: ProfilePictureFileService,
        private networkStatusService: NetworkStatusService,
        private orderAndCancellationService: OrderAndCancellationService,
        private userCredentialsSynchronizationService: UserCredentialsSynchronizationService,
        private changeDetectorRef: ChangeDetectorRef,
        private newWindowService: NewWindowService,
    ) {}

    public selectedUser: User;
    protected loggedInUser: User;

    public team: Team;

    public teamMembers: User[] = [];

    // Passwords
    public axPassword = '(unverändert)'; // ax password can be assumed to exist.
    public emailPassword = '';
    public emailDisplayNameVisible = false;

    // Synchronize Credentials
    protected showSynchronizationMessage: { [propertyPath in CredentialPropertyPath]?: boolean } = {};

    // Profile Picture
    public allowedImageFileTypes: string[] = ['image/jpg', 'image/jpeg', 'image/png'];
    @ViewChild('axUserPhotoFileUpload', { static: false }) axUserPhotoFileUpload: ElementRef;

    // User Contact Details
    public salutations: string[] = defaultSalutations;
    public filteredSalutations: string[];
    public secondPhoneNumberShown: boolean;

    // Office Locations
    public officeLocationForEditDialog: OfficeLocation;

    // Email Account
    public emailAddressInputVisible = false;
    public emailProviders: EmailProvider[] = emailProviders;
    public filteredEmailProviders: EmailProvider[] = [];
    public isEmailSignatureShown: boolean = false;
    public emailSignature: EmailSignature = null;

    // Tax IDs
    protected isEuropeanVatIdInvalid: boolean = false;
    protected isGermanTaxIdInEuropeanVatIdField: boolean = false;
    protected isGermanTaxIdInvalid: boolean = false;

    public factoringProvider: DefaultFactoringProvider;
    public factoringProviderAddresses: ContactPerson[] = [
        new ContactPerson({
            organization: 'ADELTA.FINANZ AG',
            organizationType: 'factoringProvider',
            streetAndHouseNumberOrLockbox: 'Marc-Chagall-Straße 2',
            zip: '40477',
            city: 'Düsseldorf',
            email: 'info@adeltafinanz.de',
            createdBy: 'autoixpert-default',
            teamId: 'autoixpert-ADMIN',
        }),
        new ContactPerson({
            organization: 'Deutsche Verrechnungsstelle AG',
            organizationType: 'factoringProvider',
            streetAndHouseNumberOrLockbox: 'Schanzenstr. 30',
            zip: '51063',
            city: 'Köln',
            email: 'abwicklung@kfzvs.de',
            createdBy: 'autoixpert-default',
            teamId: 'autoixpert-ADMIN',
        }),
        new ContactPerson({
            organization: 'Goya Finance AG',
            organizationType: 'factoringProvider',
            streetAndHouseNumberOrLockbox: 'An der Eselshaut 2',
            zip: '67435',
            city: 'Neustadt an der Weinstraße',
            email: 'support@persaldo24.de',
            createdBy: 'autoixpert-default',
            teamId: 'autoixpert-ADMIN',
        }),
    ];
    public adeltafinanzImportPending = false;
    private subscriptions: Subscription[] = [];

    // Team
    public isOrderAudatexDialogVisible: boolean;
    public isDeleteTeamDialogVisible: boolean;
    public isFirstBankAccountIbanInvalid: boolean;
    public isSecondBankAccountIbanInvalid: boolean;

    // Signature & Seal
    protected userSealEditorOpened: boolean = false;
    public signatureUploader: FileUploader;
    public userForEditInitialsDialog: User;

    // Team Logo
    public teamLogoUploader: FileUploader;
    public teamLogoUploadPending: boolean;

    protected productName: ReturnType<typeof getProductName>;

    private OAUTH_GOOGLE_CLIENT_ID = '266893963116-pt97tum84bhvi40aodmtb6pun04s9ar3.apps.googleusercontent.com';
    private OAUTH_GOOGLE_REDIRECT_URI = `https://${window.location.host}/Einstellungen`;
    private OAUTH_MICROSOFT_CLIENT_ID = '02512e3e-16b4-4055-8069-9651037d8a93';
    private OAUTH_MICROSOFT_REDIRECT_URI = `https://${window.location.host}/Einstellungen`;

    async ngOnInit(): Promise<void> {
        scrollToTheTop();

        // Get the currently logged-in user from cache.
        this.selectedUser = this.loggedInUserService.getUser();
        this.loggedInUser = this.selectedUser;
        this.subscriptions.push(
            this.loggedInUserService.getTeam$().subscribe(async (team) => {
                this.team = team;
                this.teamMembers = await this.userService.getAllTeamMembers(this.team, this.loggedInUser);
            }),
        );

        this.showOrHideEmailInput();

        /**
         * This usually depends on the current state of the user, so it should be placed after this.loadUserFromServer(). But
         * it rarely changes (update from server rarely important) and the interface "jumps" if the factoring provider is only
         * determined after the server request for the user.
         */
        this.loadFactoringProvider();

        await this.loadUserFromServer();
        await this.loadTeamFromServer();

        this.hideKnownPasswords();

        this.screenTitleService.setScreenTitle({ screenTitle: 'Einstellungen' });

        this.initializeTeamLogoUploader();

        this.route.queryParams.subscribe((queryParams) => {
            if (queryParams['section']) {
                /**
                 * Some sections are only inserted by Angular after the next change detection cycle (e.g. the Team Members section).
                 * Therefore, scrolling may only occur after Angular had the time to update the view.
                 */
                this.changeDetectorRef.detectChanges();
                this.scrollIntoView('#' + queryParams['section']);
            }
        });

        this.productName = getProductName();

        /**
         * Handle the OAuth redirect from the OAuth provider.
         */
        await this.createGmailAccessTokenWithAuthorizationCode();
        await this.createMicrosoftAccessTokenWithAuthorizationCode();

        this.validateEuropeanVatId();
        this.validateGermanTaxId();
    }

    private hideKnownPasswords(): void {
        this.emailPassword = this.selectedUser.emailAccount.password ? '(unverändert)' : '';
    }

    /**
     * Load the current settings from the server.
     * If the user changed his preferences on a different device, the preferences should not be overwritten here.
     */
    private async loadUserFromServer(): Promise<void> {
        try {
            const user = await this.userService.get(this.loggedInUserService.getUser()._id);
            this.loggedInUserService.setUser(user);
            this.showOrHideEmailInput();
            if (user.emailAccount.displayName) {
                this.showEmailDisplayName();
            }
        } catch (error) {
            console.error('Error loading the user from the server.', { error });
            this.toastService.error(
                'User nicht geladen',
                'Die aktuellen Einstellungen des Users konnten nicht vom Server geladen werden.',
            );
        }
    }

    private async loadTeamFromServer(): Promise<void> {
        // Get fresh version from server. If a fresh version exists, a patch event will be emitted locally, which the loggedInUserService merges into the above team.
        try {
            await this.teamService.get(this.selectedUser.teamId);
        } catch (error) {
            this.toastService.error('Team nicht gefunden');
        }
    }

    protected selectUser(selectedUser: User, { showToast }: { showToast?: boolean } = {}): void {
        // Leave live updates for the previous user (exclude logged-in user as we already joined the channel for him)
        if (this.loggedInUser._id !== this.selectedUser._id) {
            this.userService
                .leaveUpdateChannel(this.selectedUser._id)
                .catch(() =>
                    console.log(`Leaving the user update channel failed for user with ID: ${this.selectedUser._id}`),
                );
        }

        this.selectedUser = selectedUser;
        this.isEmailSignatureShown = false;
        this.emailSignature = null;
        this.hideKnownPasswords();
        this.showOrHideEmailInput();

        // Join live updates for the user (exclude logged-in user as we already joined the channel for him)
        if (this.loggedInUser._id !== selectedUser._id) {
            console.log(`Joining the user update channel for user with ID: ${selectedUser._id}`);
            this.userService
                .joinUpdateChannel(selectedUser._id)
                // For debugging.
                // .then(() => console.log(`Joined the user update channel for user with ID: ${user._id}`))
                .catch(() =>
                    console.log(`Joining the user update channel failed for user with ID: ${selectedUser._id}`),
                );
        }

        if (showToast) {
            this.toastService.success(
                'Benutzer ausgewählt',
                this.loggedInUser._id !== this.selectedUser._id
                    ? 'Du bearbeitest jetzt die Einstellungen eines anderen Benutzers.'
                    : 'Du bearbeitest jetzt wieder deine eigenen Einstellungen.',
            );
        }
    }

    //*****************************************************************************
    //  Place Order
    //****************************************************************************/
    public getWidthOfRemainingDaysProgressBar(): string {
        const testPeriodStart: Moment = moment(this.team.createdAt);
        const testPeriodEnd: Moment = moment(this.team.expirationDate);

        const testPeriodLength = testPeriodEnd.diff(testPeriodStart, 'days');

        // Use Math.min() to prevent a too long test period indicator after the text expired.
        return Math.min(this.getPassedTestDays() / testPeriodLength, 1) * 100 + '%';
    }

    public getPassedTestDays(): number {
        const startDate = moment(this.team.createdAt);
        return moment().diff(startDate, 'days');
    }

    public getRemainingTestDays(): number {
        const endDate = moment(this.team.expirationDate);
        const now = moment().startOf('day');
        return endDate.diff(now, 'days');
    }

    public openDirectDebitDialog(): void {
        this.openSubscriptionDialog({ displayDirectDebit: true });
    }
    public openInvoiceAddressDialog(): void {
        this.openSubscriptionDialog({ displayAddress: true });
    }
    public openSubscriptionDialog({
        displayFullBooking = false,
        displayAddress = false,
        displayDirectDebit = false,
    }: {
        displayFullBooking?: boolean;
        displayAddress?: boolean;
        displayDirectDebit?: boolean;
    }): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Deine SEPA-Daten können werden, sobald du wieder online bist.',
            );
            return;
        }

        const dialogRef = this.dialog.open<
            SubscriptionDialogComponent,
            SubscriptionDialogComponentConfig,
            { team: Team }
        >(SubscriptionDialogComponent, {
            data: {
                team: this.team,
                user: this.selectedUser,
                display: {
                    fullBooking: displayFullBooking,
                    invoiceAddress: displayAddress,
                    directDebit: displayDirectDebit,
                },
            },
            maxWidth: '800px',
        });
        dialogRef.afterClosed().subscribe({
            next: async (orderPlaced) => {
                if (orderPlaced) {
                    // Load the user to trigger reloading the team in the AppComponent to remove the yellow "test-ends-soon" badge
                    await this.loadUserFromServer();
                    // Reload the new team data from the server, with testAccount = false
                    await this.loadTeamFromServer();
                }
            },
        });
    }

    public downloadDirectDebitMandate(): void {
        this.httpClient
            .get(`/api/v0/teams/${this.team._id}/documents/directDebitMandate?format=pdf`, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe({
                next: (response) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'SEPA-Mandat nicht abrufbar',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Place Order
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Personal Data
    //****************************************************************************/
    public openAxPasswordDialog(): void {
        const dialogRef = this.dialog.open(AxPasswordDialogComponent, {
            width: '400px',
        });
        if (dialogRef?.componentInstance) {
            dialogRef.componentInstance.editedUser = this.selectedUser;
        }
    }

    public openChangeUsernameDialog(): void {
        const dialogRef = this.dialog.open(ChangeUsernameDialogComponent, {
            width: '400px',
        });
        if (dialogRef?.componentInstance) {
            dialogRef.componentInstance.editedUser = this.selectedUser;
        }
    }

    public async uploadProfilePicture(event: Event): Promise<void> {
        const uploadedProfilePicture: Blob = (event.target as HTMLInputElement).files[0];
        if (!uploadedProfilePicture || !this.isAllowedImageType(uploadedProfilePicture)) {
            return;
        }

        const reducedProfilePicturePhotoResult = await resizePhoto({
            targetWidth: 100,
            photoFileOrBlob: uploadedProfilePicture,
        });
        const reducedProfilePicture: Blob = reducedProfilePicturePhotoResult.photoBlob;
        this.selectedUser.profilePictureHash = simpleHash(await blobToBase64(reducedProfilePicture));
        await this.profilePictureFileService.create({
            _id: this.selectedUser._id,
            blob: reducedProfilePicture,
            blobContentHash: this.selectedUser.profilePictureHash,
        });
        await this.userService.put(this.selectedUser);
        this.toastService.success('Profilbild gespeichert');
    }

    public async deleteProfilePicture(): Promise<void> {
        this.selectedUser.profilePictureHash = undefined;
        await this.userService.put(this.selectedUser);
        await this.profilePictureFileService.delete(this.selectedUser._id);
        this.toastService.success('Profilbild gelöscht');
    }

    private isAllowedImageType(image: Blob): boolean {
        return this.allowedImageFileTypes.some((allowedType) => allowedType === image.type);
    }

    public filterSalutations(filterTerm: string) {
        // If the term is empty, reset the list.
        if (!filterTerm) {
            this.filteredSalutations = [...this.salutations];
            return;
        }

        filterTerm = filterTerm.toLowerCase();

        // Show only the elements that contain the filterTearm
        this.filteredSalutations = this.salutations.filter(
            (salutation: string) => salutation.toLowerCase().indexOf(filterTerm) > -1,
        );
    }

    public showSecondPhoneNumber(): void {
        this.secondPhoneNumberShown = true;
    }

    public removeSecondPhoneNumber(): void {
        this.secondPhoneNumberShown = false;
        this.selectedUser.phone2 = null;
    }

    public isChangingPersonalDataAllowed() {
        return isTeamMatureCustomer({ team: this.team });
    }

    public getTooltipIfChangingPersonalDataIsDisallowed(): string {
        if (!this.isChangingPersonalDataAllowed()) {
            const testPeriodEndDate: string = DateTime.fromISO(this.team.createdAt)
                .plus({
                    day: TEST_PERIOD_DURATION_IN_DAYS,
                })
                .setLocale('de')
                .toLocaleString(GERMAN_DATE_FORMAT);
            return `Falls du deine persönlichen Daten vor dem ${testPeriodEndDate} (Ende des Testzeitraums) anpassen möchtest, kontaktiere uns bitte persönlich. Wir nehmen die Änderungen gerne für dich vor.\n\nDieses Datum bleibt auch von einer Bestellung unberührt.`;
        }
    }

    public displayWarningIfChangingPersonalDataIsDisallowed() {
        if (!this.isChangingPersonalDataAllowed()) {
            this.toastService.warn(
                'Persönliche Daten noch nicht änderbar',
                this.getTooltipIfChangingPersonalDataIsDisallowed(),
            );
        }
    }
    //*****************************************************************************
    //  Office Locations
    //****************************************************************************/
    public selectDefaultOfficeLocation(officeLocation: OfficeLocation) {
        if (!this.isOfficeLocationComplete(officeLocation)) {
            this.toastService.info(
                'Standort unvollständig',
                'Nur vollständige Standorte können als Standard gesetzt werden.',
            );
            return;
        }
        this.selectedUser.defaultOfficeLocationId = officeLocation._id;
    }

    public isDefaultOfficeLocation(officeLocation: OfficeLocation): boolean {
        return officeLocation._id === this.selectedUser.defaultOfficeLocationId;
    }

    public createNewOfficeLocation(): void {
        const newOfficeLocation = new OfficeLocation();
        this.team.officeLocations.push(newOfficeLocation);
        this.openOfficeLocationEditDialog(newOfficeLocation);
    }

    public openOfficeLocationEditDialog(officeLocation: OfficeLocation): void {
        // Don't allow non-admins to edit.
        if (!this.userIsAdmin()) return;

        this.officeLocationForEditDialog = officeLocation;
    }

    public closeOfficeLocationEditDialog(): void {
        this.officeLocationForEditDialog = null;
    }

    public removeOfficeLocation(officeLocation: OfficeLocation) {
        if (this.team.officeLocations.length === 1) {
            this.toastService.warn('Letzter Standort', 'Der letzte Standort kann nicht gelöscht werden.');
            return;
        }

        removeFromArray(officeLocation, this.team.officeLocations);

        // Update the location of all users in this team who used this location as their default.
        if (this.areTeamMembersPopulated()) {
            for (const member of this.teamMembers) {
                if (member.defaultOfficeLocationId === officeLocation._id) {
                    // Set the first location. That choice is random but the user can later change that.
                    member.defaultOfficeLocationId = this.team.officeLocations[0]._id;

                    // Save user back to server
                    this.userService.put(member).catch((error) => {
                        this.apiErrorService.handleAndRethrow({
                            axError: error,
                            handlers: {},
                            defaultHandler: {
                                title: 'Standard-Standort nicht gespeichert',
                                body: "Bitte aktualisiere die Seite und versuche es noch einmal. Sollte der Fehler erneut auftreten, kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                            },
                        });
                    });
                }
            }
        }
    }

    public isOfficeLocationComplete(officeLocation: OfficeLocation): boolean {
        return checkIfOfficeLocationComplete(officeLocation);
    }

    public closeBillingAddressWarning(): void {
        this.team.billing.warningClosedForBillingAddress = createCompareableAddressString({
            zip: this.team.billing.address.zip,
            streetAndHouseNumber: this.team.billing.address.streetAndHouseNumberOrLockbox,
            city: this.team.billing.address.city,
        });
        this.saveTeam();
    }

    public displayWarningIfOfficeLocationsAndBillingAddressDiffer(): boolean {
        // This waring is only relevant for customers with a paying account.
        if (this.team.accountStatus !== 'paying') {
            return;
        }

        const billingAddressString = createCompareableAddressString({
            zip: this.team.billing.address.zip,
            streetAndHouseNumber: this.team.billing.address.streetAndHouseNumberOrLockbox,
            city: this.team.billing.address.city,
        });

        if (billingAddressString === this.team.billing.warningClosedForBillingAddress) {
            return false;
        }

        return !this.team.officeLocations.some(
            (officeLocation) => createCompareableAddressString(officeLocation) === billingAddressString,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Office Locations
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Personal Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Email
    //****************************************************************************/
    /**
     * If the user has a SMTP username not including the @ symbol, he may have to enter an e-mail address.
     */
    public showOrHideEmailInput(): void {
        if (!this.selectedUser) return;
        // Show if the separate email address has been specified before or if the actual username does not include an @ symbol.
        this.emailAddressInputVisible =
            !!this.selectedUser.emailAccount.email ||
            (this.selectedUser.emailAccount.username && !this.selectedUser.emailAccount.username.includes('@'));
    }

    /**
     * Derive the SMTP settings from common email providers such as Gmail or Web.de
     */
    public deriveEmailSettingsFromEmailAddress(): void {
        let email = '';
        if (this.selectedUser.emailAccount.username && this.selectedUser.emailAccount.username.includes('@')) {
            email = this.selectedUser.emailAccount.username;
        } else if (this.selectedUser.emailAccount.email && this.selectedUser.emailAccount.email.includes('@')) {
            email = this.selectedUser.emailAccount.email;
        }
        const emailDomain = email.split('@')[1];
        const associatedEmailProvider = this.emailProviders.find((emailProvider) =>
            emailProvider.domains.includes(emailDomain),
        );
        if (associatedEmailProvider) {
            this.selectedUser.emailAccount.host = associatedEmailProvider.smtpHost;
            this.selectedUser.emailAccount.port = associatedEmailProvider.smtpPort;
        }
    }

    public derivePortFromHost(): void {
        const associatedEmailProvider = this.emailProviders.find(
            (emailProvider) => emailProvider.smtpHost === this.selectedUser.emailAccount.host,
        );
        if (associatedEmailProvider) {
            this.selectedUser.emailAccount.port = associatedEmailProvider.smtpPort;
        }
    }

    public filterEmailServerHosts(): void {
        this.filteredEmailProviders = [...this.emailProviders];

        const searchTerm: string = (this.selectedUser.emailAccount.host || '').toLowerCase();
        const searchTerms: string[] = searchTerm.split(' ');

        if (searchTerm === '') {
            return;
        }

        this.filteredEmailProviders = this.filteredEmailProviders.filter((provider) => {
            const propertiesToSearch: string[] = [provider.title, provider.smtpHost];

            return searchTerms.every((searchTerm) =>
                propertiesToSearch.some((property) => property.includes(searchTerm)),
            );
        });
    }

    /**
     * Save the user object including the email credentials and the validate the email credentials. If the function "saveUser" was triggered
     * at the same as as triggering the GET request to validate the email credentials, the user update might happen after the validation process completed.
     * This would lead to wrong results.
     */
    public async saveAndValidateEmailCredentials(): Promise<void> {
        // If the email password was changed, set it to the user object.
        if (this.emailPassword !== '(unverändert)') {
            this.selectedUser.emailAccount.password = this.emailPassword;
        }

        try {
            await this.userService.put(this.selectedUser, { waitForServer: true });
        } catch (error) {
            this.toastService.error('Fehler beim Speichern');
        }

        if (this.loggedInUser._id === this.selectedUser._id) {
            this.loggedInUserService.setUser(this.selectedUser);
        }

        await this.validateEmailCredentials();
    }

    /**
     * Validate email credentials with the user's SMTP server.
     */
    private async validateEmailCredentials() {
        /**
         * After the user has been saved to the server, let the server verify the credentials.
         *
         * Credentials do not need to be verified through an SMTP check when working with the provider's OAuth-based API.
         * The API replaces SMTP and therefore does not work with the SMTP check.
         */
        if (
            !this.selectedUser.emailAccount.username ||
            !this.selectedUser.emailAccount.password ||
            !this.selectedUser.emailAccount.host ||
            !this.selectedUser.emailAccount.port ||
            this.selectedUser.emailAccount.oauthProvider
        )
            return;

        if (!this.networkStatusService.isOnline()) {
            return;
        }

        try {
            await this.httpClient.get(`/api/v0/users/${this.selectedUser._id}/validateEmailCredentials`).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    // Overwrite the default error handler for an invalid SMTP authentication to include a note about the email provider if necessary.
                    SMTP_AUTHENTICATION_FAILED: () => {
                        let body = 'Bitte prüfe Benutzername und Passwort deines E-Mail-Kontos.';

                        const emailProvidersRequiringSettings = emailProviders.filter(
                            (emailProvider) => emailProvider.requireThirdPartyAppPermission,
                        );
                        if (
                            emailProvidersRequiringSettings.some(
                                (emailProviderRequiringSettings) =>
                                    this.selectedUser.emailAccount.host === emailProviderRequiringSettings.smtpHost,
                            )
                        ) {
                            body += `<br><br>Bei deinem E-Mail-Provider könnten für den Versand durch autoiXpert zusätzliche Einstellungen notwendig sein. <a href="https://wissen.autoixpert.de/hc/de/articles/360006973051" target="_blank" rel="noopener">Hier gibt's eine Anleitung</a>.`;
                        }

                        return {
                            title: 'SMTP-Zugangsdaten ungültig',
                            body,
                        };
                    },
                },
                defaultHandler: {
                    title: 'Unbekannter Fehler',
                    body: 'Bitte melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        this.toastService.success('E-Mail-Zugangsdaten korrekt');
        this.tutorialStateService.markUserTutorialStepComplete('emailCredentialsAdded');
        this.tutorialStateService.markTeamSetupStepComplete('emailCredentials');
    }

    public getEmailSenderPreview(user: User): string {
        const fromOptions = getEmailFromOptions(user).from;
        return `${fromOptions.name} <${fromOptions.address}>`;
    }

    public showEmailDisplayName(): void {
        this.emailDisplayNameVisible = true;
    }

    public toggleEmailDisplayName(): void {
        this.emailDisplayNameVisible = !this.emailDisplayNameVisible;
    }

    public removeEmailConnectionStartType(): void {
        this.selectedUser.emailAccount.connectionStartType = null;
    }

    public showEmailConnectionStartType(): void {
        this.selectedUser.emailAccount.connectionStartType = 'starttls';
    }

    //*****************************************************************************
    //  Email Signature
    //****************************************************************************/
    public showEmailSignature(): void {
        this.isEmailSignatureShown = true;
        this.loadEmailSignature();
    }

    private async loadEmailSignature(): Promise<void> {
        let emailSignatures: EmailSignature[];

        try {
            emailSignatures = await this.emailSignatureService
                .find({
                    createdBy: this.selectedUser._id,
                })
                .toPromise();
        } catch (error) {
            this.isEmailSignatureShown = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'E-Mail-Signatur nicht abrufbar',
                    body: 'Die E-Mail-Signatur konnte nicht vom Server geladen werden. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        if (emailSignatures.length === 0) {
            this.emailSignature = new EmailSignature({
                createdBy: this.selectedUser._id,
            });
            await this.emailSignatureService.create(this.emailSignature);
        } else if (emailSignatures.length > 0) {
            this.emailSignature = emailSignatures[0];
        }
    }

    public saveEmailSignature() {
        this.emailSignatureService.put(this.emailSignature);
    }

    /**
     * This function is called from within the Quill image upload plugin. Make this an arrow
     * function to ensure "this" always points to this component not to the Quill plugin.
     */
    public uploadEmailSignatureImage = async (imageFile: File): Promise<string> => {
        return await this.emailSignatureImageService.create(this.selectedUser.teamId, imageFile);
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Email Signature
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Gmail
    //****************************************************************************/
    public openGmailOauthDialog(): void {
        window.localStorage.setItem('oauthProvider', 'google');

        const gmailOauthUrl = new URL('https://accounts.google.com/o/oauth2/v2/auth');
        gmailOauthUrl.searchParams.append('client_id', this.OAUTH_GOOGLE_CLIENT_ID);
        gmailOauthUrl.searchParams.append('redirect_uri', this.OAUTH_GOOGLE_REDIRECT_URI);
        /**
         * In order to use Google's SMTP server, we need to request the "https://mail.google.com/" scope. The scope
         * "https://www.googleapis.com/auth/gmail.send" only allows sending email through the Gmail API, not through
         * SMTP.
         * Sources:
         * - https://stackoverflow.com/questions/61860408/google-oauth2-api-works-for-all-scopes-except-the-one-i-need-gmail-send
         * - https://stackoverflow.com/a/77814564/1027464
         *
         * TODO When we integrate Google Drive, we'll probably need the Google API Node.js library in the backend anyway. Then we might think about reducing the scope to the minimum necessary.
         */
        gmailOauthUrl.searchParams.append('scope', 'https://mail.google.com/');
        // "response_type": "code" means "authorization code". For details, see https://developers.google.com/identity/protocols/oauth2/web-server#httprest.
        gmailOauthUrl.searchParams.append('response_type', 'code');
        // Generate a refresh token so that the user has to link his email account only once.
        gmailOauthUrl.searchParams.append('access_type', 'offline');

        this.newWindowService.open(gmailOauthUrl.toString(), '_self', 'noopener');
    }

    /**
     * Use the authorization code from the OAuth provider to create an access token for the Gmail account. The backend
     * can use that access token to send emails on behalf of the user.
     */
    public async createGmailAccessTokenWithAuthorizationCode(): Promise<void> {
        const queryParams = new URLSearchParams(window.location.search);

        const oauthProvider: AxEmailOAuthProviderName = window.localStorage.getItem(
            'oauthProvider',
        ) as AxEmailOAuthProviderName;

        if (oauthProvider !== 'google') return;
        const oauthErrorMessage: string = queryParams.get('error');

        if (oauthErrorMessage) {
            this.toastService.error(
                'Google-Zugriff verweigert',
                `Der Zugriff auf dein Google-Konto wurde verweigert.<br><br><b>Meldung: </b>${oauthErrorMessage}`,
            );
            return;
        }

        const authorizationCode = queryParams.get('code');
        if (!authorizationCode) {
            console.log(
                `No authorization code found in the URL. The user probably canceled the OAuth process. This may also happen if the user opened the settings while the OAuth process was open in another tab.`,
            );
            window.localStorage.removeItem('oauthProvider');
            this.toastService.info(
                'Authentifizierung abgebrochen',
                'Die Gmail-Verknüpfung wurde abgebrochen, weil du die autoiXpert-Einstellungen ohne Authentifizierungscode aufgerufen hast. Falls gewünscht, starte den Prozess erneut.',
            );
            return;
        }

        try {
            await this.httpClient
                .post(`/api/v0/users/${this.selectedUser._id}/oauth/google/accessTokens`, {
                    authorizationCode,
                    redirectUri: this.OAUTH_GOOGLE_REDIRECT_URI,
                })
                .toPromise();

            // At this point the user successfully linked the Google Account using OAuth -> Mark email setup as complete
            this.tutorialStateService.markUserTutorialStepComplete('emailCredentialsAdded');
            this.tutorialStateService.markTeamSetupStepComplete('emailCredentials');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Gmail-Verknüpfung fehlgeschlagen',
                    body: 'Fehler beim Erstellen des Google Access Tokens aus dem Autorisierungscode.<br><br>Das ist ein technisches Problem. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        } finally {
            window.localStorage.removeItem('oauthProvider');
        }

        this.selectedUser.emailAccount.oauthProvider = 'google';
        await this.saveUser();
    }

    public async removeGmailOauth() {
        this.selectedUser.emailAccount.oauthProvider = null;
        await this.saveUser();

        try {
            await this.httpClient
                .delete(
                    `/api/v0/users/${this.selectedUser._id}/oauth/google/accessTokens/dummy-id-will-be-overwritten-on-server`,
                )
                .toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Gmail-Verknüpfung nicht aufgehoben',
                    body: 'Fehler beim Löschen des Google OAuth Access Tokens.<br><br>Das ist ein technisches Problem. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Gmail
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  Microsoft
    /////////////////////////////////////////////////////////////////////////////*/
    public openMicrosoftOauthDialog(): void {
        window.localStorage.setItem('oauthProvider', 'microsoft');

        const microsoftOauthUrl = new URL('https://login.microsoftonline.com/common/oauth2/v2.0/authorize');
        microsoftOauthUrl.searchParams.append('client_id', this.OAUTH_MICROSOFT_CLIENT_ID);
        microsoftOauthUrl.searchParams.append('redirect_uri', this.OAUTH_MICROSOFT_REDIRECT_URI);
        /**
         * Scope-ID source: https://learn.microsoft.com/en-us/exchange/client-developer/legacy-protocols/how-to-authenticate-an-imap-pop-smtp-application-by-using-oauth
         *
         * Scope "offline_access": Generate a refresh token so that the user has to link his email account only once.
         */
        microsoftOauthUrl.searchParams.append('scope', 'Mail.Send offline_access');
        // "response_type": "code" means "authorization code". For details, see https://developers.google.com/identity/protocols/oauth2/web-server#httprest.
        microsoftOauthUrl.searchParams.append('response_type', 'code');
        if (this.selectedUser?.emailAccount?.email) {
            microsoftOauthUrl.searchParams.append('login_hint', this.selectedUser.emailAccount.email);
        }

        this.newWindowService.open(microsoftOauthUrl.toString(), '_self', 'noopener');
    }

    /**
     * Use the authorization code from the OAuth provider to create an access token for the Microsoft account. The backend
     * can use that access token to send emails on behalf of the user.
     */
    public async createMicrosoftAccessTokenWithAuthorizationCode(): Promise<void> {
        const queryParams = new URLSearchParams(window.location.search);

        const oauthProvider: AxEmailOAuthProviderName = window.localStorage.getItem(
            'oauthProvider',
        ) as AxEmailOAuthProviderName;

        if (oauthProvider !== 'microsoft') return;
        const oauthErrorMessage: string = queryParams.get('error');

        if (oauthErrorMessage) {
            this.toastService.error(
                'Microsoft-Zugriff verweigert',
                `Der Zugriff auf dein Microsoft-Konto wurde verweigert.<br><br><b>Meldung: </b>${oauthErrorMessage}`,
            );
            return;
        }

        const authorizationCode = queryParams.get('code');
        if (!authorizationCode) {
            console.log(
                `No authorization code found in the URL. The user probably canceled the OAuth process. This may also happen if the user opened the settings while the OAuth process was open in another tab.`,
            );
            window.localStorage.removeItem('oauthProvider');
            this.toastService.info(
                'Authentifizierung abgebrochen',
                'Die Microsoft-Verknüpfung wurde abgebrochen, weil du die autoiXpert-Einstellungen ohne Authentifizierungscode aufgerufen hast. Falls gewünscht, starte den Prozess erneut.',
            );
            return;
        }

        try {
            await this.httpClient
                .post(`/api/v0/users/${this.selectedUser._id}/oauth/microsoft/accessTokens`, {
                    authorizationCode,
                    redirectUri: this.OAUTH_MICROSOFT_REDIRECT_URI,
                })
                .toPromise();

            // At this point the user successfully linked the Microsoft Account using OAuth -> Mark email setup as complete
            this.tutorialStateService.markUserTutorialStepComplete('emailCredentialsAdded');
            this.tutorialStateService.markTeamSetupStepComplete('emailCredentials');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Microsoft-Verknüpfung fehlgeschlagen',
                    body: 'Fehler beim Erstellen des Microsoft Access Tokens aus dem Autorisierungscode.<br><br>Das ist ein technisches Problem. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        } finally {
            window.localStorage.removeItem('oauthProvider');
        }
        this.selectedUser.emailAccount.oauthProvider = 'microsoft';
        await this.saveUser();
    }

    public async removeMicrosoftOauth() {
        this.selectedUser.emailAccount.oauthProvider = null;
        await this.saveUser();

        try {
            await this.httpClient
                .delete(
                    `/api/v0/users/${this.selectedUser._id}/oauth/microsoft/accessTokens/dummy-id-will-be-overwritten-on-server`,
                )
                .toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Microsoft-Verknüpfung nicht aufgehoben',
                    body: 'Fehler beim Löschen des Microsoft OAuth Access Tokens.<br><br>Das ist ein technisches Problem. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    protected isMicrosoftEmailAccount(): boolean {
        return (
            arrayIncludesAnyOfTheseValues({
                array: this.selectedUser?.emailAccount?.email,
                searchItems: ['@outlook.com', '@live.com'],
            }) ||
            arrayIncludesAnyOfTheseValues({
                array: this.selectedUser?.emailAccount?.host,
                searchItems: ['outlook.com', 'office365.com'],
            }) ||
            this.selectedUser?.emailAccount?.oauthProvider === 'microsoft'
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Microsoft
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Email
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Residual Value Exchanges
    //****************************************************************************/
    public completeTutorialStepResidualValueExchangeConfigured(
        credentialsPropertyPath: 'winvalueUser' | 'cartvUser' | 'carcasionUser',
    ) {
        // WinValue
        const winvalueCredentialsComplete = !!(
            this.selectedUser.winvalueUser.customerNumber &&
            this.selectedUser.winvalueUser.password &&
            this.selectedUser.winvalueUser.apiKey
        );

        if (credentialsPropertyPath === 'winvalueUser') {
            this.showSynchronizationMessage.winvalueUser = winvalueCredentialsComplete;
        }

        // cartv
        const cartvCredentialsComplete = !!(
            this.selectedUser.cartvUser.customerNumber &&
            this.selectedUser.cartvUser.password &&
            this.selectedUser.cartvUser.accessKey
        );

        if (credentialsPropertyPath === 'cartvUser') {
            this.showSynchronizationMessage.cartvUser = cartvCredentialsComplete;
        }

        // carcasion
        const carcasionCredentialsComplete = !!(
            this.selectedUser.carcasionUser.email && this.selectedUser.carcasionUser.password
        );

        if (credentialsPropertyPath === 'carcasionUser') {
            this.showSynchronizationMessage.carcasionUser = carcasionCredentialsComplete;
        }

        // Check if the user has completed the tutorial step for the respective residual value exchange.
        if (winvalueCredentialsComplete || cartvCredentialsComplete) {
            this.tutorialStateService.markUserTutorialStepComplete('residualValueExchangeCredentialsAdded');
        }
    }

    protected doesCarcasionEmailContainAutoixpertDomain(carcasionEmailAddress: string): boolean {
        return carcasionEmailAddress?.includes?.('@autoixpert.de');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Residual Value Exchanges
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Old Vehicle Certificates (German "Altfahrzeugzertifikate")
    //****************************************************************************/
    protected openAfzzertSummaryPage() {
        this.newWindowService.open('https://www.afz-zert.de/', '_blank', 'noopener');
    }
    protected openAfzzertApiKeyPage() {
        this.newWindowService.open(
            'https://www.afz-zert.de/mein-konto-willkommen/schnittstellen/',
            '_blank',
            'noopener',
        );
    }

    protected saveAfzzertUser() {
        // Check if Afz-Zert. credentials are complete.
        this.showSynchronizationMessage.afzzertUser = !!this.selectedUser.afzzertUser.apiKey;

        // Save user
        this.saveUser();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Old Vehicle Certificates (German "Altfahrzeugzertifikate")
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Factoring Provider
    //****************************************************************************/

    public loadFactoringProvider(): void {
        this.factoringProvider = {
            contactPerson: Object.assign(
                new ContactPerson({ organizationType: 'factoringProvider' }),
                this.userPreferences.factoringProvider.contactPerson,
            ),
            bankAccount: this.userPreferences.factoringProvider.bankAccount,
        };
    }

    public saveFactoringProvider(): void {
        this.userPreferences.factoringProvider = this.factoringProvider;
    }

    public getCurrentFactoringProvider(): 'adelta' | 'kfzvs' | 'persaldo' | 'other' {
        if (!this.factoringProvider) return;

        if (this.factoringProvider.contactPerson.organization) {
            const factoringProvider: string = this.factoringProvider.contactPerson.organization.toLowerCase();
            if (factoringProvider.includes('adelta.finanz')) {
                return 'adelta';
            } else if (factoringProvider.includes('deutsche verrechnungsstelle')) {
                return 'kfzvs';
            } else if (
                factoringProvider.includes('invois ag') ||
                factoringProvider.includes('liquide24') ||
                factoringProvider.includes('goya') ||
                factoringProvider.includes('persaldo')
            ) {
                return 'persaldo';
            }
        }
        return 'other';
    }

    public insertBankNameForFactoringProvider(bankName: string): void {
        this.factoringProvider.bankAccount.bankName = bankName;
    }

    public insertFactoringProviderBankAccount(): void {
        // Adelta
        if (this.getCurrentFactoringProvider() === 'adelta') {
            this.factoringProvider.bankAccount = {
                owner: 'ADELTA.FINANZ AG',
                iban: 'DE65 2505 0000 0152 0065 57',
                bic: 'NOLADE2HXXX',
                bankName: 'Norddeutsche Landesbank Girozentrale',
            };
        }
        // KFZVS
        else if (this.getCurrentFactoringProvider() === 'kfzvs') {
            this.factoringProvider.bankAccount = {
                owner: 'Deutsche Verrechnungsstelle AG',
                iban: '(Persönliche DSR24-IBAN hier eintragen)',
                bic: 'COKSDE33XXX',
                bankName: 'Kreissparkasse Köln',
            };
        }
        // KFZVS
        else if (this.getCurrentFactoringProvider() === 'persaldo') {
            this.factoringProvider.bankAccount = {
                owner: 'Invois AG',
                iban: '(Persönliche Goya-Mobility-IBAN hier eintragen)',
                bic: 'COBADEFF',
                bankName: 'Commerzbank Leipzig',
            };
        }
    }

    public mayStartAdeltafinanzImport(): boolean {
        return !!(
            this.selectedUser.adeltaFinanzUser.username &&
            this.selectedUser.adeltaFinanzUser.password &&
            this.selectedUser.adeltaFinanzUser.customerNumber
        );
    }

    public importAdeltafinanzDebtors(): void {
        this.adeltafinanzImportPending = true;
        this.httpClient.get(`/api/v0/adeltafinanz/debtors`).subscribe({
            next: (data: any) => {
                this.adeltafinanzImportPending = false;
                if (data.success) {
                    this.toastService.success(
                        'Import erfolgreich',
                        `Es wurden ${data.importedCounter} neue Debitoren von ADELTA.FINANZ importiert.`,
                    );
                } else {
                    this.toastService.warn('Import nur teilweise möglich', 'Bitte wende dich an die Hotline.');
                }
            },
            error: () => {
                this.adeltafinanzImportPending = false;
                this.toastService.error('Import nicht möglich', 'Bitte wende dich an die Hotline.');
            },
        });
    }

    //*****************************************************************************
    //  Adelta.Finanz
    //****************************************************************************/
    saveAdeltaFinanzUser() {
        // Check if Adelta.Finanz credentials are complete.
        if (
            this.selectedUser.adeltaFinanzUser.username &&
            this.selectedUser.adeltaFinanzUser.password &&
            this.selectedUser.adeltaFinanzUser.customerNumber
        ) {
            this.showSynchronizationMessage.adeltaFinanzUser = true;
        } else {
            this.showSynchronizationMessage.adeltaFinanzUser = false;
        }

        // Save user
        this.saveUser();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Adelta.Finanz
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  KfzVS
    //****************************************************************************/
    protected saveKfzvsUser() {
        // Check if KfzVS credentials are complete.
        if (this.selectedUser.kfzvsUser.customerNumber) {
            this.showSynchronizationMessage.kfzvsUser = true;
        } else {
            this.showSynchronizationMessage.kfzvsUser = false;
        }

        // Save user
        this.saveUser();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END KfzVS
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Persaldo
    //****************************************************************************/
    public async savePersaldoUser(): Promise<void> {
        // Check if Persaldo credentials are complete.
        if (this.selectedUser.persaldoUser.username && this.selectedUser.persaldoUser.password) {
            this.showSynchronizationMessage.persaldoUser = true;
        } else {
            this.showSynchronizationMessage.persaldoUser = false;
        }

        // Save user
        const persaldoUserSnapshot: PersaldoUser = JSON.parse(JSON.stringify(this.selectedUser.persaldoUser));
        let success: boolean;
        try {
            await this.saveUser(this.selectedUser, { waitForServer: true });
            ({ success } = await this.persaldoService.checkCredentials().toPromise());
        } catch (error) {
            return;
        }

        // Skip the check result if the credentials were not yet complete. Otherwise, entering a username will trigger only the error toast.
        if (persaldoUserSnapshot.username && persaldoUserSnapshot.password) {
            if (success) {
                this.toastService.success('Goya-Mobility-Zugangsdaten korrekt');
            } else {
                this.toastService.error(
                    'Goya-Mobility-Zugangsdaten ungültig',
                    "Bitte prüfe deine Zugangsdaten. Logge dich z. B. testweise direkt bei <a href='https://factoringtech.io' target='_blank' rel='noopener'>Goya Mobility</a> ein.",
                    { timeOut: 5000 },
                );
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Persaldo
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  crashback24
    //****************************************************************************/
    public async saveCrashback24User(): Promise<void> {
        const crashback24UserSnapshot: Crashback24User = JSON.parse(JSON.stringify(this.selectedUser.crashback24User));
        await this.saveUser(this.selectedUser, { waitForServer: true });

        if (!crashback24UserSnapshot.username || !crashback24UserSnapshot.password) return;

        let credentialCheckResult: { success: true };
        try {
            credentialCheckResult = await this.crashback24Service.checkCredentials(this.selectedUser._id).toPromise();
        } catch (error) {
            console.error('Failed to check Crashbach24 credentials', { error });
        }
        // Skip the check result if the credentials were not yet complete. Otherwise, entering a username only will trigger the error toast.
        if (crashback24UserSnapshot.username && crashback24UserSnapshot.password) {
            if (credentialCheckResult.success) {
                this.toastService.success('crashback24-Zugangsdaten korrekt');
                // Create lawyer contact person if it does not yet exist.
                const contactPeople = await this.contactPersonService.httpSync.findRemote({
                    organizationType: 'lawyer',
                    organization: 'crashback24 GmbH',
                });
                // If crashback24 does not yet exist as a lawyer, create it.
                if (!contactPeople.length) {
                    try {
                        await this.contactPersonService.create({
                            _id: generateId(),
                            firstName: null,
                            lastName: null,
                            salutation: null,
                            organizationType: 'lawyer',
                            organization: 'crashback24 GmbH',
                            streetAndHouseNumberOrLockbox: 'Richard-Wagner-Straße 5',
                            zip: '50674',
                            city: 'Köln',
                            phone: '+49 221 986 579 74',
                            phone2: null,
                            email: 'info@crashback24.com',
                            website: 'www.crashback24.com',
                            notes: null,
                            _schemaVersion: 2,
                            _documentVersion: 1,
                            updatedAt: null,
                            createdAt: null,
                            createdBy: null,
                            teamId: null,
                            _externalId: null,
                        });
                        this.toastService.success(
                            'Anwalt angelegt',
                            'Der Anwalt "crashback24" wurde als Adresse angelegt.',
                        );
                    } catch (error) {
                        this.toastService.error(
                            'Anwalt nicht angelegt',
                            'Der Anwalt "crashback24" konnte nicht als Adresse angelegt werden. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        );
                    }
                }
            } else {
                this.toastService.error(
                    'crashback24-Zugangsdaten ungültig',
                    `Bitte prüfe deine Zugangsdaten. Logge dich z. B. testweise direkt bei <a href='${this.getCrashback24LoginUrl()}' target='_blank' rel='noopener'>crashback24</a> ein.`,
                    { timeOut: 5000 },
                );
            }
        }
    }

    private getCrashback24LoginUrl() {
        return environment.production ? 'https://crashback24.42dbs.de/' : 'https://test-crashback.42dbs.de/';
    }

    /**
     * Open the crashback24 website that contains further information on becoming a partner assessor.
     */
    public openCrashback24Website() {
        window.open('https://www.crashback24.com/partner-werden', '_blank', 'noopener');
    }

    /**
     * Open the GDV login where the user finds an email address to start a registration.
     */
    public openGdvRegistration() {
        window.open('https://www.zentralruf.de/zentralruf-fuer-profis', '_blank', 'noopener');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END crashback24
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  GDV
    //****************************************************************************/
    saveGdvUser() {
        // Check if GDV credentials are complete.
        if (this.selectedUser.gdvUser.username && this.selectedUser.gdvUser.password) {
            this.showSynchronizationMessage.gdvUser = true;
        } else {
            this.showSynchronizationMessage.gdvUser = false;
        }

        // Save user
        this.saveUser();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END GDV
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Factoring Provider
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Team
    //****************************************************************************/
    //*****************************************************************************
    //  Payments
    //****************************************************************************/
    public copyTeamNameToBankAccountOwner(bankAccount: BankAccount): void {
        bankAccount.owner = this.team.billing.address.organization;
        this.saveTeam();
    }

    public handleIbanChangeEvent(result: IbanChangeEvent, bankAccount: BankAccount): void {
        if (bankAccount === this.team.invoicing.secondBankAccount) {
            this.isSecondBankAccountIbanInvalid = !result.isValid;
        }
        if (bankAccount === this.team.invoicing.bankAccount) {
            this.isFirstBankAccountIbanInvalid = !result.isValid;
        }
        bankAccount.iban = this.formatIban(bankAccount.iban);
    }

    public closeBillingIBANWarning(): void {
        this.team.billing.warningClosedForIBAN = IBAN.electronicFormat(this.team.billing.bankAccount.iban);
        this.saveTeam();
    }

    /**
     * Determine if the bank accounts for
     * - invoicing the users' clients and
     * - direct debit mandates towards autoiXpert
     * differ.
     */
    public displayWarningIfBankAccountsDiffer() {
        // No need to check if the IBAN used for charging the autoiXpert customer is empty.
        if (!this.team.billing.bankAccount?.iban || this.team.billing.paymentMethod !== 'directDebit') {
            return false;
        }

        // The user close the warning already.
        const billingIBAN = IBAN.electronicFormat(this.team.billing.bankAccount.iban);
        if (billingIBAN === this.team.billing.warningClosedForIBAN) {
            return false;
        }

        // The user entered two bank accounts, so both should be checked.
        if (this.team.invoicing.bankAccount.iban && this.team.invoicing.secondBankAccount?.iban) {
            const firstBankAccountIsUsedForBilling =
                billingIBAN === IBAN.electronicFormat(this.team.invoicing.bankAccount.iban);
            const secondBankAccountIsUsedForBilling =
                billingIBAN === IBAN.electronicFormat(this.team.invoicing.secondBankAccount.iban);

            // If neither the first nor the second bank account matches the one the autoiXpert customer wants to use to pay for autoiXpert, show a warning.
            return !firstBankAccountIsUsedForBilling && !secondBankAccountIsUsedForBilling;
        }
        // The user entered only one bank account (this is the typical default).
        else if (this.team.invoicing.bankAccount.iban) {
            const firstBankAccountIsUsedForBilling =
                IBAN.electronicFormat(this.team.invoicing.bankAccount.iban) ===
                IBAN.electronicFormat(this.team.billing.bankAccount.iban);
            return !firstBankAccountIsUsedForBilling;
        }
    }

    public handleBicRecognition({ bic, bankName }: BicAndBankName, bankAccount: BankAccount) {
        bankAccount.bic = bic;
        bankAccount.bankName = bankName;
    }

    public formatIban(iban: string): string {
        // The printFormat method cannot handle null or undefined
        return IBAN.printFormat(iban ?? '', ' ');
    }

    public async openInvoiceHistory() {
        try {
            const link = await this.invoiceHistoryService.find(this.team._id);
            window.open(link, '_blank', 'noopener');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    FASTBILL_CUSTOMER_NOT_FOUND: {
                        title: 'FastBill-Eintrag nicht gefunden',
                        body: 'Der Kunde konnte nicht in FastBill gefunden werden. Das Öffnen der Rechnungshistorie ist nicht möglich.',
                    },
                },
                defaultHandler: {
                    title: 'Bisherige Rechnungen konnten nicht geöffnet werden.',
                    body: "Bitte logge dich aus und wieder ein. Tritt der Fehler erneut auf, kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public showSecondBankAccount() {
        if (!this.team.invoicing.secondBankAccount) {
            this.team.invoicing.secondBankAccount = new BankAccount();
        }
        this.saveTeam();
    }

    public deleteSecondBankAccount() {
        this.isSecondBankAccountIbanInvalid = false;
        this.team.invoicing.secondBankAccount = null;
        this.saveTeam();
    }

    /**
     * Delete team account
     * Team gets an expiration date, scheduler will deactivate if date is reached.
     */
    public async cancelTeamAccount(expirationDate: Team['expirationDate']) {
        this.isDeleteTeamDialogVisible = false;

        if (!expirationDate) {
            this.toastService.error(
                'Kündigung nicht möglich',
                'Keine Lizenz für das Team verfügbar. Bitte wende dich an die Hotline.',
            );
            return;
        }
        try {
            await this.orderAndCancellationService.cancelTeamAccount({ teamId: this.team._id, expirationDate });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                defaultHandler: {
                    title: 'Kündigung nicht möglich',
                    body: 'Bitte kontaktiere die Hotline.',
                },
            });
        }
    }

    /**
     * Open a dialog where the admin can confirm to delete the account.
     */
    public openDeleteTeamDialog() {
        // Not available for QapteriXpert teams
        if (this.isQapterixpertTeam()) {
            return;
        }

        // Requires Admin
        if (!this.userIsAdmin()) {
            this.toastService.error(
                'Erfordert Admin-Rechte',
                'Kontaktiere deinen Administrator um das Audatex Add-on zu buchen.',
            );
            return;
        }

        // Requires online
        if (!this.networkStatusService.isOnline()) {
            this.toastService.error(
                'Internetverbindung erforderlich',
                'Du kannst das Audatex Add-on nur buchen, wenn du online bist.',
            );
            return;
        }
        this.isDeleteTeamDialogVisible = true;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payments
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Team
    //****************************************************************************/
    /**
     * Whether the team members are not only an array of strings but full user objects.
     */
    public areTeamMembersPopulated(): boolean {
        return this.teamMembers.some((teamMember) => teamMember._id);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Settings
    //****************************************************************************/
    public selectVatRate(vatRate: 0 | 0.19 | 0.2): void {
        if (!this.userIsAdmin()) {
            this.toastService.warn(
                'Admin kontaktieren',
                'Das Ändern der Mehrwertsteuer ist nur für Administratoren erlaubt.',
            );
            return;
        }

        this.team.invoicing.vatRate = vatRate;
    }

    public isReducedVatRateForSecondHalfOf2020Active(): boolean {
        return getVatRate() === 0.16;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Settings
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tax IDs
    //****************************************************************************/
    protected validateEuropeanVatId() {
        /**
         * VAT IDs may never contain spaces, neither at the beginning or the end nor in the middle.
         */
        this.team.europeanVatId = (this.team.europeanVatId || '').replace(/\s/g, '');
        this.isEuropeanVatIdInvalid = this.team.europeanVatId && !isEuropeanVatIdValid(this.team.europeanVatId);
        this.isGermanTaxIdInEuropeanVatIdField = isGermanTaxIdValid(this.team.europeanVatId);
    }
    protected validateGermanTaxId() {
        /**
         * Some German "Steuernummern" may contain spaces between number blocks, e.g. Bremen and Hessen.
         * Source: https://de.wikipedia.org/wiki/Steuernummer
         */
        this.team.vatId = (this.team.vatId || '').trim();
        this.isGermanTaxIdInvalid = this.team.vatId && !isGermanTaxIdValid(this.team.vatId);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tax IDs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signature & Seal
    //****************************************************************************/

    /**
     * Display the user seal configuration:
     * - if the user activated the edit mode
     * - if the user has no seal yet
     */
    get isUserSealConfigurationVisible(): boolean {
        return this.userSealEditorOpened || (this.selectedUser && !this.selectedUser?.sealHash);
    }

    public editSeal(): void {
        this.userSealEditorOpened = true;
    }

    public removeSeal(): void {
        // Save the old seal hash in case the removal fails.

        this.userSealService
            .remove(this.selectedUser._id)
            .catch((error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Unterschrift konnte nicht gelöscht werden',
                        body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                    },
                });
            })
            .then(() => {
                this.selectedUser.sealHash = null;
                this.selectedUser.sealConfig = new SealConfiguration();
                this.saveUser();

                this.userSealEditorOpened = true;
            });
    }

    public hideNameBelowSeal(value: boolean) {
        this.selectedUser.hideNameBelowSeal = value;
        this.saveUser();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature & Seal
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Team Logo
    //****************************************************************************/
    private initializeTeamLogoUploader(): void {
        this.teamLogoUploader = new FileUploader({
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
            itemAlias: 'logo',
            url: `/api/v0/teamLogos`,
        });

        this.teamLogoUploader.onAfterAddingFile = async (item: FileItem) => {
            // If the mime type is not a JPG or PNG, remove the file from the queue
            if (!['image/jpeg', 'image/png'].includes(item._file.type)) {
                console.error('The given file is not a JPG or PNG file.', item);
                this.toastService.error('Bitte lade eine JPG- oder PNG-Datei hoch');
                item.remove();
                return;
            }

            // Warn about file size
            if (item._file.size > 500 * 1024) {
                this.toastService.error(
                    'Datei zu groß',
                    'Bitte lade nur Dateien unter 500 KB hoch. Versuche dazu, die Grafik zu verkleinern oder als JPG abzuspeichern, das kleinere Dateigrößen als eine PNG bietet.',
                );
                item.remove();
                return;
            } else if (item._file.size > 300 * 1024) {
                this.toastService.warn(
                    'Datei über 300 KB',
                    'Wir verwenden sie zwar, aber Dateien über 300 KB machen für dich alles langsamer. Z. B. den Download von Dokumenten.\n\nVersuche am besten, die Grafik zu verkleinern oder als JPG abzuspeichern, das oft kleinere Dateigrößen als eine PNG bietet.',
                );
            }

            this.teamLogoUploadPending = true;
            this.teamLogoUploader.uploadAll();
        };

        this.teamLogoUploader.onSuccessItem = async (item: FileItem, response: string, status: number) => {
            if (status !== 201) {
                const errorResponse: AxError = JSON.parse(response);

                this.apiErrorService.handleAndRethrow({
                    axError: errorResponse,
                    handlers: {},
                    defaultHandler: {
                        title: 'Hochladen des Logos gescheitert',
                        body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                    },
                });
            }

            this.toastService.success('Hochladen des Logos erfolgreich');

            // Create a hash from the file contents of the user seal.
            this.team.logoHash = simpleHash(await blobToBase64(item._file));
            await this.saveTeam();

            this.tutorialStateService.markTeamSetupStepComplete('headerAndFooterTemplate');
        };

        this.teamLogoUploader.onErrorItem = async (_item: FileItem, response: string) => {
            const errorResponse: AxError = JSON.parse(response);
            this.apiErrorService.handleAndRethrow({
                axError: errorResponse,
                handlers: {},
                defaultHandler: {
                    title: 'Hochladen des Logos gescheitert',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        };

        this.teamLogoUploader.onCompleteAll = () => {
            this.teamLogoUploadPending = false;
            this.teamLogoUploader.clearQueue();
        };
    }

    public async removeTeamLogo(): Promise<void> {
        this.team.logoHash = null;
        this.saveTeam();

        try {
            await this.teamLogoService.remove(this.team._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team-Logo nicht gelöscht',
                    body: 'Das Team-Logo konnte nicht gelöscht werden. Bitte probiere es erneut. Sollte das Problem weiter bestehen, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    public async downloadTeamLogo(): Promise<void> {
        if (!this.team.logoHash) {
            this.toastService.error(
                'Kein Team-Logo',
                'Weil derzeit kein Team-Logo hinterlegt ist, kann es nicht heruntergeladen werden. Bitte lösche es und lade es neu hoch.',
            );
            return;
        }

        let teamLogoBlobResponse: HttpResponse<Blob>;
        try {
            teamLogoBlobResponse = await this.teamLogoService.get(this.team._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team-Logo nicht heruntergeladen',
                    body: 'Das Team-Logo konnte nicht vom Server geladen werden. Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        this.downloadService.downloadBlobResponseWithHeaders(teamLogoBlobResponse);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team Logo
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Building Blocks
    //****************************************************************************/
    public navigateToDocumentBuildingBlockList(): void {
        this.tutorialStateService.markUserTutorialStepComplete('documentBuildingBlockListOpened');
        this.router.navigate(['Textbausteine'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Building Blocks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  External API
    //****************************************************************************/
    public navigateToExternalApiSettings(): void {
        this.router.navigate(['Externe-API'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END External API
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Calculation Providers
    //****************************************************************************/
    public doMultipleCalculationProvidersExist(): boolean {
        return this.isDatUserComplete() && this.isAudatexUserComplete();
    }

    public setDefaultVehicleIdentificationProvider(provider: UserPreferences['vehicleIdentificationProvider']) {
        this.userPreferences.vehicleIdentificationProvider = provider;
    }

    //*****************************************************************************
    //  Audatex
    //****************************************************************************/
    public trimAudatexUsername(): void {
        if (typeof this.selectedUser.audatexUser?.username === 'string') {
            this.selectedUser.audatexUser.username = this.selectedUser.audatexUser.username.trim();
        }
    }

    /**
     * Audatex usernames always end with '@audatex.de', so we need to add the domain if the user hasn't done so yet.
     */
    public addDomainToAudatexUsername() {
        // Username is filled and it doesn't contain an "@"? -> Append
        if (this.selectedUser?.audatexUser.username && !this.selectedUser.audatexUser.username.includes('@')) {
            this.selectedUser.audatexUser.username += '@audatex.de';
        }
    }

    public trimAudatexPassword(): void {
        if (this.selectedUser.audatexUser.password && typeof this.selectedUser.audatexUser.password === 'string') {
            this.selectedUser.audatexUser.password = this.selectedUser.audatexUser.password.trim();
        }
    }

    public isAudatexUserComplete(): boolean {
        return isAudatexUserComplete(this.selectedUser);
    }

    public isAudatexTestAccount() {
        return isAudatexTestAccount(this.selectedUser.audatexUser);
    }

    public saveAudatexUser(): void {
        // If data is missing, autoiXpert should not attempt to authenticate against Audatex because that request would always fail.
        if (!this.isAudatexUserComplete()) {
            this.showSynchronizationMessage.audatexUser = false;
            this.saveUser();
        } else {
            this.saveUserAndValidateAudatexCredentials();
        }
    }

    private async saveUserAndValidateAudatexCredentials() {
        await this.saveUser(this.selectedUser, { waitForServer: true });

        if (!this.networkStatusService.isOnline()) {
            return;
        }

        try {
            // Try to list the user's Audatex tasks. If the credentials are valid, this request will succeed.
            await this.audatexTaskService.findAll();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getAudatexErrorHandlers(),
                    /**
                     * Overwrite the "invalid credentials" error since it usually asks the user to check the credentials in the settings. At this point,
                     * the user is in the settings already, so remove that phrase.
                     */
                    AUDATEX_CREDENTIALS_INVALID: {
                        title: 'Zugangsdaten ungültig',
                        body: 'Ob deine Audatex-Zugangsdaten aktuell sind, kannst du über das <a href="https://www.audanet.de/breclient/ui" target="_blank" rel=”noopener”>Qapter Login</a> testen.',
                    },
                },
                defaultHandler: {
                    title: 'Fehler bei Prüfung der Audatex-Zugangsdaten',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        } finally {
            this.showSynchronizationMessage.audatexUser = false;
        }

        this.showSynchronizationMessage.audatexUser = true;
        this.toastService.success('Audatex-Zugangsdaten korrekt');
    }

    public isQapterixpert() {
        return isQapterixpert();
    }

    public isQapterixpertTeam() {
        return isQapterixpertTeam(this.team);
    }

    /**
     * OpenOrderAudatexAddonDialog
     *
     */
    public openOrderAudatexAddonDialog() {
        // Not available for QapteriXpert teams
        if (this.isQapterixpertTeam()) {
            return;
        }

        // Requires Admin
        if (!this.userIsAdmin()) {
            this.toastService.error(
                'Erfordert Admin-Rechte',
                'Kontaktiere deinen Administrator um das Audatex Add-on zu buchen.',
            );
            return;
        }

        // Requires online
        if (!this.networkStatusService.isOnline()) {
            this.toastService.error(
                'Internetverbindung erforderlich',
                'Du kannst das Audatex Add-on nur buchen, wenn du online bist.',
            );
            return;
        }
        this.isOrderAudatexDialogVisible = true;
    }
    /**
     * Order Audatex-Addon
     */
    public async orderAudatexAddon() {
        try {
            await this.teamService.orderAudatexAddon(this.team);
            this.toastService.success('Audatex Add-on gebucht');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                defaultHandler: {
                    title: 'Audatex Add-on nicht gebucht',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        }
    }
    /**
     * Cancel Audatex Add-on
     */
    public async cancelAudatexAddon() {
        // Not available for QapteriXpert teams
        if (this.isQapterixpertTeam()) {
            return;
        }

        // Requires Admin
        if (!this.userIsAdmin()) {
            this.toastService.error(
                'Erfordert Admin-Rechte',
                'Kontaktiere deinen Administrator um das Audatex Add-on zu kündigen.',
            );
            return;
        }

        // Requires online
        if (!this.networkStatusService.isOnline()) {
            this.toastService.error(
                'Internetverbindung erforderlich',
                'Du kannst das Audatex Add-on nur kündigen, wenn du online bist.',
            );
            return;
        }
        const proceedWithCancel = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Audatex Add-on kündigen',
                    content:
                        'Vorgänge aus Audatex sind dann nicht mehr importierbar oder öffenbar.\n\nDas Add-on wird künftig nicht mehr abgerechnet.\n\nDein Qapter-Vertrag mit Audatex wird dadurch nicht beeinflusst.',
                    confirmLabel: 'Kündigen',
                    cancelLabel: 'Behalten',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();
        if (!proceedWithCancel) return;

        try {
            await this.teamService.cancelAudatexAddon(this.team);
            this.toastService.success('Audatex Add-on gekündigt');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                defaultHandler: {
                    title: 'Audatex Add-on nicht gekündigt',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DAT
    //****************************************************************************/
    public saveDatUser(): void {
        store.remove('datJwt');

        // Always assume this is a calculateExpert user until it's proven that this is a myclaim user. The DAT-JWT authentication
        // endpoint otherwise fails in authenticating against calculateExpert because it assumes this user to be a myclaim user.
        this.selectedUser.datUser.isMyclaimUser = undefined;
        this.selectedUser.datUser.myclaimNetworkType = undefined;
        this.selectedUser.datUser.isValuateExpertPlusPartnerUser = undefined;
        this.selectedUser.datUser.isValuateProUser = undefined;

        // If data is missing, autoiXpert should not attempt to authenticate against the DAT.
        if (
            !this.selectedUser.datUser.password ||
            !this.selectedUser.datUser.username ||
            !this.selectedUser.datUser.customerNumber
        ) {
            this.showSynchronizationMessage.datUser = false;
            this.saveUser();
        } else {
            this.saveUserAndValidateDatCredentials();
        }
    }

    public isDatTestAccount(): boolean {
        return isDatTestAccount(this.selectedUser.datUser);
    }

    /**
     * Return true if the autoiXpert test account expired but the DAT test account was extended, e.g. because a customer ordered his DAT account but the DAT
     * still needs some more time to deliver it.
     */
    public isDatTestAccountExtendedAndValid(): boolean {
        if (!this.team) {
            return false;
        }

        return (
            this.team.datTestAccountExtendedUntil &&
            moment(this.team.datTestAccountExtendedUntil)
                .startOf('day')
                .isAfter(moment().subtract(1, 'day').startOf('day'))
        );
    }

    private async saveUserAndValidateDatCredentials() {
        await this.saveUser(this.selectedUser, { waitForServer: true });

        if (!this.networkStatusService.isOnline()) {
            return;
        }

        try {
            const datJwt = await this.datAuthenticationService.getJwt();
            const accountInfo = await this.httpClient
                .get<DatAccountTypeResponse>(`/api/v0/dat/accountType`, {
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                })
                .toPromise();

            this.selectedUser.datUser.isMyclaimUser = accountInfo.isMyclaimUser || undefined;
            this.selectedUser.datUser.myclaimNetworkType = accountInfo.myclaimNetworkType || undefined;
            this.selectedUser.datUser.isValuateExpertPlusPartnerUser =
                accountInfo.isValuateExpertPlusPartnerUser || undefined;
            this.selectedUser.datUser.isValuateProUser = accountInfo.isValuateProUser || undefined;
            this.showSynchronizationMessage.datUser = true;
            await this.saveUser();
            this.toastService.success('DAT-Zugangsdaten korrekt');
        } catch (error) {
            /**
             * In case of error, reset what we know about the user.
             * Only save the user object if any of these properties were defined before. Otherwise, saving the user would be unnecessary.
             */
            if (
                this.selectedUser.datUser.isMyclaimUser ||
                this.selectedUser.datUser.myclaimNetworkType ||
                this.selectedUser.datUser.isValuateExpertPlusPartnerUser
            ) {
                if (this.selectedUser.datUser.isMyclaimUser) {
                    this.selectedUser.datUser.isMyclaimUser = undefined;
                }
                if (this.selectedUser.datUser.myclaimNetworkType) {
                    this.selectedUser.datUser.myclaimNetworkType = undefined;
                }
                if (this.selectedUser.datUser.isValuateExpertPlusPartnerUser) {
                    this.selectedUser.datUser.isValuateExpertPlusPartnerUser = undefined;
                }
                await this.saveUser();
            }
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDatErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Fehler bei Prüfung des DAT-Account-Typs',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        } finally {
            this.showSynchronizationMessage.datUser = false;
        }
    }

    public trimDatUsername(): void {
        if (this.selectedUser.datUser.username && typeof this.selectedUser.datUser.username === 'string') {
            this.selectedUser.datUser.username = this.selectedUser.datUser.username.trim();
        }
    }

    public trimDatPassword(): void {
        if (this.selectedUser.datUser.password && typeof this.selectedUser.datUser.password === 'string') {
            this.selectedUser.datUser.password = this.selectedUser.datUser.password.trim();
        }
    }

    public trimDatCustomerNumber(): void {
        if (this.selectedUser.datUser.customerNumber && typeof this.selectedUser.datUser.customerNumber === 'string') {
            this.selectedUser.datUser.customerNumber = parseInt(this.selectedUser.datUser.customerNumber);
        }
    }

    public isDatUserComplete(): boolean {
        return isDatUserComplete(this.selectedUser);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  GT Motive
    //****************************************************************************/
    public trimGtmotiveCustomerId(): void {
        if (typeof this.selectedUser.gtmotiveUser?.customerId === 'string') {
            this.selectedUser.gtmotiveUser.customerId = this.selectedUser.gtmotiveUser.customerId.trim();
        }
    }

    public trimGtmotiveUserId(): void {
        if (typeof this.selectedUser.gtmotiveUser?.userId === 'string') {
            this.selectedUser.gtmotiveUser.userId = this.selectedUser.gtmotiveUser.userId.trim();
        }
    }

    public isGtmotiveUserComplete() {
        return isGtmotiveUserComplete(this.selectedUser);
    }

    public saveGtmotiveUser(): void {
        // If data is missing, autoiXpert should not attempt to authenticate against GT Motive because that request would always fail.
        if (!this.isGtmotiveUserComplete()) {
            this.showSynchronizationMessage.gtmotiveUser = false;
            this.saveUser();
        } else {
            this.saveUserAndValidateGtmotiveCredentials();
        }
    }

    private async saveUserAndValidateGtmotiveCredentials() {
        await this.saveUser(this.selectedUser, { waitForServer: true });

        if (!this.networkStatusService.isOnline()) {
            return;
        }

        try {
            await this.gtmotiveEstimateService.findAll();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGtmotiveErrorHandlers(),
                    /**
                     * Overwrite the "invalid credentials" error since it usually asks the user to check the credentials in the settings. At this point,
                     * the user is in the settings already, so remove that phrase.
                     */
                    GTMOTIVE_CREDENTIALS_INVALID: {
                        title: 'Zugangsdaten ungültig',
                        body: 'Ob deine GT-Motive-Zugangsdaten aktuell sind, kannst du über das <a href="https://estimate.mygtmotive.com/" target="_blank" rel=”noopener”>GT-Motive-Login</a> testen.',
                    },
                },
                defaultHandler: {
                    title: 'Fehler bei Prüfung der GT-Motive-Zugangsdaten',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        } finally {
            this.showSynchronizationMessage.gtmotiveUser = false;
        }

        this.showSynchronizationMessage.gtmotiveUser = true;
        this.toastService.success('GT-Motive-Zugangsdaten korrekt');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END GT Motive
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculation Providers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  AUTOonline
    //****************************************************************************/
    public padAutoonlineUser() {
        if (this.selectedUser.autoonlineUser.customerNumber) {
            this.selectedUser.autoonlineUser.customerNumber = this.selectedUser.autoonlineUser.customerNumber.padStart(
                8,
                '0',
            );
        }
    }

    public trimAutoonlinePassword() {
        if (
            this.selectedUser.autoonlineUser.password &&
            typeof this.selectedUser.autoonlineUser.password === 'string'
        ) {
            this.selectedUser.autoonlineUser.password = this.selectedUser.autoonlineUser.password.trim();
        }
    }

    public saveAutoonlineUser(): void {
        // Don't verify incomplete credentials
        if (!isAutoonlineUserComplete(this.selectedUser)) {
            this.saveUser();
        } else {
            this.saveUserAndValidateAutoonlineCredentials();
        }
    }

    private async saveUserAndValidateAutoonlineCredentials() {
        await this.saveUser(this.selectedUser, { waitForServer: true });

        if (!this.networkStatusService.isOnline()) {
            return;
        }

        try {
            await this.autoonlineCredentialsCheckService.checkCredentials();
            this.showSynchronizationMessage.autoonlineUser = true;
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    /**
                     * Overwrite the "invalid credentials" error since it usually asks the user to check the credentials in the settings. At this point,
                     * the user is in the settings already, so remove that phrase.
                     */
                    AUTOONLINE_CREDENTIALS_INVALID: {
                        title: 'Zugangsdaten ungültig',
                        body: 'Ob deine AUTOonline-Zugangsdaten aktuell sind, kannst du über das <a href="https://easyonline.autoonline.com/" target="_blank" rel=”noopener”>AUTOonline Login</a> testen.',
                    },
                },
                defaultHandler: {
                    title: 'Fehler bei Prüfung der AUTOonline-Zugangsdaten',
                    body: "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                },
            });
        } finally {
            this.showSynchronizationMessage.autoonlineUser = false;
        }

        this.toastService.success('AUTOonline-Zugangsdaten korrekt');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END AUTOonline
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utility Functions
    //****************************************************************************/
    public scrollIntoView(querySelector: string): void {
        document.querySelector(querySelector).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utility Functions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  User Role
    //****************************************************************************/
    public isAdmin(teamMember: User): boolean {
        if (!teamMember) return false;

        return isAdmin(teamMember._id, this.team);
    }

    public userIsAdmin(): boolean {
        return this.isAdmin(this.loggedInUser);
    }

    public getAskYourAdminTooltip(): string {
        return getAskYourAdminTooltip(this.team, this.teamMembers);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END User Role
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save
    //****************************************************************************/

    public async saveUser(
        user: User = this.selectedUser,
        { waitForServer }: { waitForServer?: boolean } = {},
    ): Promise<void> {
        // If the logged-in user has been updated, propagate those changes to all components.
        if (user._id === this.loggedInUser?._id) {
            this.loggedInUserService.setUser(user);
        }
        try {
            await this.userService.put(user, { waitForServer });
        } catch (error) {
            this.toastService.error('Fehler beim Speichern');
        }
    }

    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public moment = moment;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Synchronize Credentials
    //****************************************************************************/

    protected userCanSynchronizeCredentials() {
        return this.userIsAdmin() && this.team.members.length > 1;
    }

    protected synchronizeCredentials(propertyPath: CredentialPropertyPath) {
        try {
            // Open a confirm dialog
            this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                    maxWidth: '550px',
                    data: {
                        heading: 'Zugangsdaten synchronisieren?',
                        content:
                            'Die neuen Zugangsdaten werden für jeden Nutzer-Account deines Teams hinterlegt. Bestehende Zugangsdaten werden überschrieben und sind nicht mehr wiederherstellbar.',
                        confirmLabel: 'Synchronisieren',
                        cancelLabel: 'Abbrechen',
                    },
                })
                .afterClosed()
                .subscribe({
                    next: async (result) => {
                        if (result) {
                            // Dismiss the synchronization message if the user confirms the synchronization
                            this.dismissSynchronizationMessage(propertyPath);

                            // Synchronize API request
                            const { numSynchronizedUsers } = await this.userCredentialsSynchronizationService
                                .synchronize(this.selectedUser._id, propertyPath)
                                .toPromise();

                            this.toastService.success(
                                'Zugangsdaten synchronisiert',
                                numSynchronizedUsers === 1
                                    ? `Die Zugangsdaten wurden für ${numSynchronizedUsers} anderen Nutzer erfolgreich synchronisiert.`
                                    : `Die Zugangsdaten wurden für ${numSynchronizedUsers} andere Nutzer erfolgreich synchronisiert.`,
                            );
                        }
                    },
                });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Fehler beim Synchronisieren der Zugangsdaten',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    protected dismissSynchronizationMessage(propertyPath: string) {
        this.showSynchronizationMessage[propertyPath] = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Synchronize Credentials
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * User clicked on the signature icon to download the previously uploaded file.
     */
    protected downloadUserSealFile(): void {
        if (!this.selectedUser?.sealHash) {
            this.toastService.error(
                'Keine Datei für Unterschrift & Stempel',
                'Weil derzeit keine Datei für Unterschrift & Stempel hinterlegt ist, kann diese nicht heruntergeladen werden. Bitte lösche sie und lade sie erneut hoch.',
            );
            return;
        }

        this.userSealService
            .get({ userId: this.selectedUser._id })
            .then((response: HttpResponse<Blob>) => {
                this.downloadService.downloadFile(
                    response.body,
                    `Unterschrift-und-Stempel-${getFullName(this.selectedUser).replace(' ', '-')}`,
                );
            })
            .catch((error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Unterschrift & Stempel nicht heruntergeladen',
                        body: 'Die Datei für Unterschrift & Stempel konnte nicht vom Server geladen werden. Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            });
    }

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
    protected readonly Environment = Environment;

    public async saveSelectedUserEmailPreferences(): Promise<void> {
        if (!this.selectedUser.preferences.emailSendingDelayAmount) {
            this.selectedUser.preferences.emailSendingDelayAmount = 10;
        }

        if (!this.selectedUser.preferences.emailSendingDelayUnit) {
            this.selectedUser.preferences.emailSendingDelayUnit = 'seconds';
        }

        // Save selected user preferences
        await this.saveUser(this.selectedUser, { waitForServer: true });
    }
}

// Please sort alphabetically.
export const emailProviders: EmailProvider[] = [
    {
        title: '1&1',
        domains: [],
        smtpHost: 'smtp.1und1.de',
        smtpPort: 587,
    },
    {
        title: 'AOL',
        domains: ['aol.com', 'aol.de'],
        smtpHost: 'smtp.aol.com',
        smtpPort: 465,
        requireThirdPartyAppPermission: true,
    },
    {
        title: 'Freenet',
        domains: ['freenet.de'],
        smtpHost: 'mx.freenet.de',
        smtpPort: 587,
    },
    {
        title: 'Gmail',
        domains: ['gmail.com', 'googlemail.com', 'googlemail.de'],
        smtpHost: 'smtp.gmail.com',
        smtpPort: 587,
        // Currently documented in the help center article https://wissen.autoixpert.de/hc/de/articles/360006973051.
        // Best case: We allow a user to connect his Google account through OAuth so that Google considers autoiXpert to be secure.
        requireThirdPartyAppPermission: true,
    },
    {
        title: 'GMX',
        domains: ['gmx.net', 'gmx.de'],
        smtpHost: 'mail.gmx.net',
        smtpPort: 587,
        requireThirdPartyAppPermission: true,
    },
    {
        title: 'iCloud',
        domains: ['icloud.com'],
        // Yes, Apple owns me.com.
        smtpHost: 'smtp.mail.me.com',
        smtpPort: 587,
    },
    {
        title: 'Ionos',
        domains: [],
        smtpHost: 'smtp.ionos.de',
        smtpPort: 465,
    },
    {
        title: 'Mail.de',
        domains: ['mail.de'],
        smtpHost: 'smtp.mail.de',
        smtpPort: 587,
    },
    {
        title: 'Microsoft 365',
        domains: ['outlook.com'],
        smtpHost: 'smtp-mail.outlook.com',
        smtpPort: 587,
        requireThirdPartyAppPermission: true,
    },
    {
        title: 'STRATO',
        domains: [],
        smtpHost: 'smtp.strato.de',
        smtpPort: 465,
    },
    {
        title: 'T-Online',
        domains: ['t-online.de'],
        smtpHost: 'securesmtp.t-online.de',
        smtpPort: 465,
        requireThirdPartyAppPermission: true,
    },
    {
        title: 'Web.de',
        domains: ['web.de'],
        smtpHost: 'smtp.web.de',
        smtpPort: 587,
        requireThirdPartyAppPermission: true,
    },
    {
        title: 'Yahoo!',
        domains: ['yahoo.com', 'yahoo.de'],
        smtpHost: 'smtp.mail.yahoo.com',
        smtpPort: 587,
    },
];

export class EmailProvider {
    title: string;
    domains: string[];
    smtpHost: string;
    smtpPort: number;
    requireThirdPartyAppPermission?: boolean;
}

export interface DatAccountTypeResponse {
    success: boolean;
    isMyclaimUser?: boolean;
    myclaimNetworkType?: DatNetworkType | string;
    isValuateExpertPlusPartnerUser?: boolean;
    isValuateProUser?: boolean;
}

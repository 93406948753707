import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { ArrayElement } from '@autoixpert/helper-types/array-element';
import { reduceByProperty } from '@autoixpert/lib/arrays/reduce-by-property';
import { removeDuplicates } from '@autoixpert/lib/arrays/remove-duplicates';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import { iconForCarBrandExists, iconNameForCarBrand } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import {
    ShortPaymentReasonsByInsuranceAnalyticsRecord,
    ShortPaymentStatusByInsuranceAnalyticsRecord,
} from '@autoixpert/models/analytics/revenue-analytics.model';
import { AnalyticsFilterComponent } from '../../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../../shared/libraries/currency-formatter-euro';
import { ShortPaymentAnalyticsService } from '../../../shared/services/analytics/short-payment-analytics.service';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { AnalyticsSettingsMenuComponent } from '../../analytics-settings-menu/analytics-settings-menu.component';
import {
    defaultColors,
    setDefaultChartOptions,
    shortPaymentColor,
    undeterminedColor,
} from '../../default-chart-options';

@Component({
    selector: 'short-payment-reasons',
    templateUrl: './short-payment-reasons.component.html',
    styleUrls: ['./short-payment-reasons.component.scss'],
})
export class ShortPaymentReasonsComponent implements OnInit {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;
    @ViewChild(AnalyticsSettingsMenuComponent, { static: true }) protected settings: AnalyticsSettingsMenuComponent;

    constructor(
        private shortPaymentAnalyticsService: ShortPaymentAnalyticsService,
        private apiErrorService: ApiErrorService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    public chartTitle: string = 'Kürzungen';

    private chart: ApexCharts;
    private chartOptions: apexcharts.ApexOptions;
    public analyticsPending: boolean = false;

    public totalRevenueGross: number = 0;
    public totalShortPayments: number = 0;

    public records: ShortPaymentReasonsByInsuranceAnalyticsRecord[] = [];
    public filteredRecords: this['records'] = [];
    public recordsHiddenFromChart: this['records'] = [];
    public possibleReasons: ArrayElement<
        ShortPaymentReasonsByInsuranceAnalyticsRecord['reasonsForShortPayment']
    >['reasonName'][] = [];
    public areSomeRecordsMissingReason: boolean;

    public mostFrequentReason: { reasonName: string; occurrences: number };
    public financiallyMostImpactfulReason: { reasonName: string; shortPaymentAmountGross: number };
    private maximumGraphCategories: number = 10;

    public filterAnalyticsFrom: string;
    public filterAnalyticsTo: string;

    ngOnInit() {
        setDefaultChartOptions();

        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateBackToAnalyticsList(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    public rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/
    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.records.length) return;

        // If we have to summarize the remaining revenues, add the "others" revenue to the chart series
        const { firstFewRecords, otherRevenue } = this.clipDataPointsForChart();
        if (otherRevenue) {
            firstFewRecords.push(otherRevenue);
        }

        const colors: string[] = [
            shortPaymentColor,
            this.areSomeRecordsMissingReason ? undeterminedColor : null,
            ...defaultColors,
        ].filter(Boolean);

        this.chartOptions = {
            chart: {
                type: 'bar',
                stacked: true,
            },
            colors: colors,
            stroke: {
                colors,
                width: 3,
            },
            series: [
                {
                    name: 'Summe Kürzungen',
                    data: firstFewRecords.map((record) =>
                        this.settings.useNetValues ? record.allShortPaymentsSumNet : record.allShortPaymentsSumGross,
                    ),
                    group: 'allShortPaymentsSum',
                },
                ...this.possibleReasons.map((reasonName) => {
                    return {
                        name: reasonName || 'kein Grund angegeben',
                        data: this.filteredRecords.map((record) => {
                            const currentReason = record.reasonsForShortPayment.find(
                                (reason) => reason.reasonName === reasonName,
                            );
                            return this.settings.useNetValues
                                ? currentReason.shortPaymentsSumNet
                                : currentReason.shortPaymentsSumGross;
                        }),
                        group: 'reasons',
                    };
                }),
            ].filter(Boolean),
            xaxis: {
                // Categories go into the Y axis when the bar chart is horizontal but...
                categories: firstFewRecords.map((record) => (record.insurance || 'Ohne Versicherung').toUpperCase()),
                labels: {
                    style: {
                        fontFamily: "'Source Sans Pro', sans-serif",
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: (value) => currencyFormatterEuro(+value),
                },
            },
            tooltip: {
                y: {
                    formatter: (revenueByIntermediary) => currencyFormatterEuro(revenueByIntermediary),
                },
            },
        };

        // Clear before re-drawing
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
        this.chart.render();
    }

    /**
     * If there are more than 5 data points, sum up the remainder.
     */
    private clipDataPointsForChart(): {
        firstFewRecords: ShortPaymentReasonsByInsuranceAnalyticsRecord[];
        otherRevenue: ShortPaymentReasonsByInsuranceAnalyticsRecord;
    } {
        const firstFewRevenues: ShortPaymentReasonsByInsuranceAnalyticsRecord[] = this.filteredRecords.slice(
            0,
            this.maximumGraphCategories,
        );
        let otherRevenue: ShortPaymentReasonsByInsuranceAnalyticsRecord = null;

        // Sum up all hidden records for the right-most chart entry.
        if (this.records.length > this.maximumGraphCategories) {
            const hiddenRecords = this.filteredRecords.slice(this.maximumGraphCategories);
            const hiddenShortPaymentsSumNet: number = hiddenRecords.reduce(
                (total, record) => total + record.allShortPaymentsSumNet,
                0,
            );
            const hiddenShortPaymentsSumGross: number = hiddenRecords.reduce(
                (total, record) => total + record.allShortPaymentsSumGross,
                0,
            );

            otherRevenue = {
                insurance: 'Sonstige',
                allShortPaymentsSumNet: hiddenShortPaymentsSumNet,
                allShortPaymentsSumGross: hiddenShortPaymentsSumGross,
                reasonsForShortPayment: [
                    ...this.possibleReasons,
                    // Beside the regular possible reasons, there's also "no value provided" which is represented by the empty string.
                    '',
                ].map((reasonName) => {
                    const matchingReasons = hiddenRecords
                        .map((hiddenRecord) => hiddenRecord.reasonsForShortPayment)
                        .flat(1)
                        .filter((hiddenRecordReason) => hiddenRecordReason.reasonName === reasonName);

                    return {
                        reasonName,
                        shortPaymentsSumGross: reduceByProperty(matchingReasons, 'shortPaymentsSumGross'),
                        shortPaymentsSumNet: reduceByProperty(matchingReasons, 'shortPaymentsSumNet'),
                        // Not relevant for the chart.
                        shareOfAllShortPaymentsInPercent: null,
                        numberOfShortPaymentsWithThisType: null,
                        idsOfRegularInvoices: [],
                    };
                }),
                idsOfRegularInvoices: removeDuplicates(
                    hiddenRecords.map((hiddenRecord) => hiddenRecord.idsOfRegularInvoices).flat(),
                ),
            };
        }

        return {
            firstFewRecords: firstFewRevenues,
            otherRevenue,
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Hide Records
    //****************************************************************************/
    public filterRecords() {
        this.filteredRecords = [...this.records].filter((record) => !this.recordsHiddenFromChart.includes(record));
    }

    public toggleHideRecord(record: ArrayElement<this['records']>) {
        toggleValueInArray(record, this.recordsHiddenFromChart);
    }

    public isRecordHidden(record: ArrayElement<this['records']>): boolean {
        return this.recordsHiddenFromChart.includes(record);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Hide Records
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public refreshLocalData(): void {
        this.filterRecords();

        this.calculateTotalShortPayments();

        // Determine reasons
        this.possibleReasons = this.filteredRecords[0]?.reasonsForShortPayment
            .map((reasonsForShortPayment) => reasonsForShortPayment.reasonName)
            .sort();
        this.areSomeRecordsMissingReason = this.possibleReasons?.includes('');

        this.determineSpotlightReasonsForSummary();

        setTimeout(() => {
            this.drawChart();
        });
    }

    public calculateTotalShortPayments(): void {
        this.totalShortPayments = this.records.reduce(
            (total, currentItem) =>
                total +
                (this.settings.useNetValues
                    ? currentItem.allShortPaymentsSumNet
                    : currentItem.allShortPaymentsSumGross),
            0,
        );
    }

    public determineSpotlightReasonsForSummary() {
        //*****************************************************************************
        //  Accumulate Number of Payment Reasons Across Insurances
        //****************************************************************************/

        const frequency = new Map<string, number>();

        for (const record of this.filteredRecords) {
            for (const reasonForShortPayment of record.reasonsForShortPayment) {
                // We currently exclude "no reason provided" (= empty reason) because that won't interest the user.
                if (!reasonForShortPayment.reasonName) continue;

                frequency.set(
                    reasonForShortPayment.reasonName,
                    (frequency.get(reasonForShortPayment.reasonName) ?? 0) +
                        reasonForShortPayment.numberOfShortPaymentsWithThisType,
                );
            }
        }
        this.mostFrequentReason = [...frequency]
            .map(([reasonName, occurrences]) => ({ reasonName, occurrences }))
            .sort(sortByProperty('occurrences', 'descending'))[0];
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Accumulate Number of Payment Reasons Across Insurances
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Find Financially Most Hurting Reason
        //****************************************************************************/
        const amountByPaymentReason = new Map();

        for (const record of this.filteredRecords) {
            for (const reasonForShortPayment of record.reasonsForShortPayment) {
                // We currently exclude "no reason provided" (= empty reason) because that won't interest the user.
                if (!reasonForShortPayment.reasonName) continue;

                amountByPaymentReason.set(
                    reasonForShortPayment.reasonName,
                    (amountByPaymentReason.get(reasonForShortPayment.reasonName) ?? 0) +
                        reasonForShortPayment.shortPaymentsSumGross,
                );
            }
        }
        this.financiallyMostImpactfulReason = [...amountByPaymentReason]
            .map(([reasonName, shortPaymentAmountGross]) => ({ reasonName, shortPaymentAmountGross }))
            .sort(sortByProperty('shortPaymentAmountGross', 'descending'))[0];
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Find Financially Most Hurting Reason
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Table
    //****************************************************************************/
    iconForCarBrandExists = iconForCarBrandExists;
    iconNameForCarBrand = iconNameForCarBrand;

    public sortRecords(
        sortColumns: { column: keyof ShortPaymentStatusByInsuranceAnalyticsRecord; ascending: 1 | -1 }[],
    ): void {
        this.records.sort((recordA, recordB) => {
            let sortResult: number = 0;

            for (const sortColumnConfig of sortColumns) {
                // Only continue if we could not determine the right order (records are equal)
                if (sortResult !== 0) break;

                // Necessary for typing
                const recordAColumn = recordA[sortColumnConfig.column];
                const recordBColumn = recordB[sortColumnConfig.column];

                if (typeof recordAColumn === 'number' && typeof recordBColumn === 'number') {
                    if (recordAColumn === recordBColumn) {
                        sortResult = 0;
                    } else {
                        sortResult =
                            recordAColumn < recordBColumn
                                ? sortColumnConfig.ascending * -1
                                : sortColumnConfig.ascending;
                    }
                }
                if (typeof recordAColumn === 'string' && typeof recordBColumn === 'string') {
                    sortResult = recordAColumn.localeCompare(recordBColumn) * sortColumnConfig.ascending;
                }
            }

            return sortResult;
        });
    }

    public getShortPaymentReasonObject(record: ShortPaymentReasonsByInsuranceAnalyticsRecord, reason: string) {
        return record.reasonsForShortPayment.find((reasonObject) => reasonObject.reasonName === reason);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Table
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    public async updateAnalytics() {
        this.analyticsPending = true;

        try {
            this.records = await this.shortPaymentAnalyticsService.getShortPaymentReasonsByInsurance({
                from: this.filterAnalyticsFrom,
                to: this.filterAnalyticsTo,
                officeLocationIds: this.filter.officeLocationIds,
                assessorIds: this.filter.assessorIds,
                reportLabelIds: this.filter.reportLabelConfigIds,
                invoiceLabelIds: this.filter.invoiceLabelConfigIds,
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ACCESS_DENIED: {
                        title: 'Zugriffsrecht fehlt',
                        body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                        forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                    },
                },
                defaultHandler: {
                    title: 'Fehler bei Auswertung',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        } finally {
            this.analyticsPending = false;
        }

        this.sortRecords([
            {
                column: 'allShortPaymentsSumNet' satisfies keyof ArrayElement<this['records']>,
                ascending: -1,
            },
            {
                column: 'insurance' satisfies keyof ArrayElement<this['records']>,
                ascending: -1,
            },
        ]);
        this.analyticsPending = false;
        this.refreshLocalData();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/
}

import { CustomFeeSet } from '../../models/reports/assessors-fee/custom-fee-set';
import { FeeCalculation, FeeTable, OfficialFeeTable } from '../../models/reports/assessors-fee/fee-calculation';
import { TeamPreferences } from '../../models/teams/team-preferences';
import { bvskFeeTable2022 } from '../../static-data/fee-tables/bvsk-fee-table-2022';
import { bvskFeeTable2024 } from '../../static-data/fee-tables/bvsk-fee-table-2024';
import { cgfFeeTable2023 } from '../../static-data/fee-tables/cgf-fee-table-2023';
import { hukFeeTable2021 } from '../../static-data/fee-tables/huk-fee-table-2021';
import { hukFeeTable2023 } from '../../static-data/fee-tables/huk-fee-table-2023';
import { hukFeeTable2025 } from '../../static-data/fee-tables/huk-fee-table-2025';
import { vksFeeTable2021 } from '../../static-data/fee-tables/vks-fee-table-2021';

/**
 * Returns the fee table that is selected for the given report.
 * This information is derived by the selected fee table and the user preferences.
 *
 * @returns The resulting fee table that is selected for the given report. "undefined" if no fee table could be calculated.
 *
 * | Schadenhöhe | Honorar |
 * | ----------- | ------- |
 * | 500.00      | 334.39  |
 * | 750.00      | 373.66  |
 * | 1000.00     | 439.11  |
 */
export function getSelectedFeeTableColumn({
    feeCalculation,
    teamPreferences,
    customFeeSets,
}: {
    feeCalculation: FeeCalculation;
    teamPreferences?: Pick<
        TeamPreferences,
        'cgfFeeConfig' | 'vksFeeConfig' | 'defaultFeeTableLiability' | 'bvskFeeConfig'
    >;
    customFeeSets: CustomFeeSet[];
}): FeeTable | undefined {
    // Check if fee set is selected, check defaultFeeTable otherwise
    const selectedFeeTable = feeCalculation.selectedFeeTable || teamPreferences?.defaultFeeTableLiability || 'BVSK';
    if (!selectedFeeTable) return undefined;

    const year = feeCalculation.yearOfFeeTable;

    switch (feeCalculation.selectedFeeTable) {
        case 'BVSK': {
            let bvskFeeTable: OfficialFeeTable['table'];

            switch (year) {
                case 2022:
                    bvskFeeTable = bvskFeeTable2022.table;
                    break;
                case 2024:
                default:
                    bvskFeeTable = bvskFeeTable2024.table;
                    break;
            }

            // Check user preferences for BVSK fee table settings (set HB III as default)
            const column = teamPreferences?.bvskFeeConfig?.column ?? 3;

            // Generate the report fee table
            const reportFeeTable: FeeTable = [];
            for (const row of bvskFeeTable) {
                reportFeeTable.push([row[0], row[column]]);
            }

            return reportFeeTable;
        }

        case 'CGF': {
            const cgfFeeTable = cgfFeeTable2023.table;

            // Check user preferences for CGF fee table settings (set net fee lower as default)
            const column = teamPreferences?.cgfFeeConfig.column ?? 1;

            // Generate the report fee table
            const reportFeeTable: FeeTable = [];
            for (const row of cgfFeeTable) {
                reportFeeTable.push([row[0], row[column]]);
            }

            return reportFeeTable;
        }

        case 'HUK': {
            let hukFeeTable: OfficialFeeTable['table'];

            switch (year) {
                case 2021:
                    hukFeeTable = hukFeeTable2021.table;
                    break;
                case 2023:
                    hukFeeTable = hukFeeTable2023.table;
                    break;
                case 2025:
                default:
                    hukFeeTable = hukFeeTable2025.table;
                    break;
            }

            // Generate the report fee table
            const reportFeeTable: FeeTable = [];
            for (const row of hukFeeTable) {
                reportFeeTable.push([row[0], row[2]]);
            }

            return reportFeeTable;
        }

        case 'VKS': {
            const vksFeeTable = vksFeeTable2021.table;

            // Check user preferences for VKS fee table settings (set net fee lower as default)
            const column = teamPreferences?.vksFeeConfig.column ?? 1;

            // Generate the report fee table
            const reportFeeTable: FeeTable = [];
            for (const row of vksFeeTable) {
                reportFeeTable.push([row[0], row[column]]);
            }

            return reportFeeTable;
        }

        case 'custom': {
            // Get selected custom fee table
            const selectedCustomFeeTableId = feeCalculation.selectedCustomFeeTableId;
            const selectedCustomFeeTable = customFeeSets.find(
                (customFeeSet) => customFeeSet._id === selectedCustomFeeTableId,
            );
            if (!selectedCustomFeeTable) return undefined;

            // Generate the report fee table
            const reportFeeTable: FeeTable = [];
            for (const { lowerLimit, fee } of selectedCustomFeeTable.fees) {
                reportFeeTable.push([lowerLimit, fee]);
            }

            return reportFeeTable;
        }
    }
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostBinding, HostListener, Injector, Input } from '@angular/core';
import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { ReportProgressConfig } from '@autoixpert/models/report-progress/report-progress-config';
import { Report } from '@autoixpert/models/reports/report';
import { IS_POSITION_INDICATOR_SHOWN, REPORT_TAB } from './report-progress-panel.tokens';
import { ReportProgressPanelComponent } from './report-progress-panel/report-progress-panel.component';

@Directive({
    selector: '[reportProgressPanelAnchor]',
})
export class ReportProgressPanelDirective {
    constructor(
        private elementRef: ElementRef,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    // Which tab is the progress displayed for?
    @Input() tabName: ReportTabName;

    // For which report type do we have to load the configuration?
    @Input() report: Report;

    // What items did the team choose to display?
    @Input() reportProgressConfig: ReportProgressConfig;

    @Input() overlayPositionY: 'top' | 'bottom' = 'bottom';

    private overlayRef: OverlayRef;

    @HostListener('click', ['$event'])
    public showPortal(event: MouseEvent) {
        // Prevent bubbling to avoid navigation if someone only wants to see the status of a tab.
        event.preventDefault();
        event.stopPropagation();

        // Avoid duplicate panels.
        if (this.overlayRef) return;

        //*****************************************************************************
        //  Portal Outlet
        //****************************************************************************/
        // Create a portal outlet to attach the portal component to, later on.
        // Overlays are an implementation of portal outlets.
        this.overlayRef = this.overlayService.create({
            // Backdrop must exist to be clickable. Clicks shall close the panel.
            hasBackdrop: true,

            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        // Attach portal with its top center point...
                        overlayX: 'center',
                        overlayY: this.overlayPositionY === 'top' ? 'bottom' : 'top',
                        // ...to the anchor element's center bottom point.
                        originX: 'center',
                        originY: this.overlayPositionY === 'top' ? 'top' : 'bottom',
                    },
                ]),
            scrollStrategy: this.overlayService.scrollStrategies.close({
                threshold: 0,
            }),
        });

        this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Portal Outlet
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Injector
        //****************************************************************************/
        // Create injector to pass some data to the component.
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: this.overlayRef,
                },
                {
                    provide: Report,
                    useValue: this.report,
                },
                {
                    provide: REPORT_TAB,
                    useValue: this.tabName,
                },
                {
                    provide: ReportProgressConfig,
                    useValue: this.reportProgressConfig,
                },
                {
                    provide: IS_POSITION_INDICATOR_SHOWN,
                    useValue: this.overlayPositionY === 'bottom',
                },
            ],
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Injector
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Component Portal
        //****************************************************************************/
        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<ReportProgressPanelComponent>(
            ReportProgressPanelComponent,
            null,
            injector,
        );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Component Portal
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Attach Component to Portal Outlet
        //****************************************************************************/
        this.overlayRef.attach(componentPortal);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Attach Component to Portal Outlet
        /////////////////////////////////////////////////////////////////////////////*/
    }

    @HostBinding('class.panel-visible')
    public get isPanelVisible(): boolean {
        return !!this.overlayRef;
    }
}

import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../shared/animations/fade-in-and-slide.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { slideOutDialogVertical } from '../../shared/animations/slide-out-dialog-vertical.animation';
import { slideOutLeft } from '../../shared/animations/slide-out-left.animation';
import { slideOutRight } from '../../shared/animations/slide-out-right.animation';
import {
    VideoPlayerDialogComponent,
    VideoPlayerDialogData,
} from '../../shared/components/video-player-dialog/video-player-dialog.component';
import { getProductName } from '../../shared/libraries/get-product-name';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'intro-videos-panel',
    templateUrl: 'intro-videos-panel.component.html',
    styleUrls: ['intro-videos-panel.component.scss'],
    animations: [
        slideOutLeft(),
        slideOutRight(),
        slideOutDialogVertical(),
        slideInAndOutVertically(),
        fadeInAndSlideAnimation(),
        fadeInAndOutAnimation(),
    ],
})
export class IntroVideosPanelComponent implements OnInit, OnDestroy {
    constructor(
        private overlayRef: OverlayRef,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private dialog: MatDialog,
        public userPreferences: UserPreferencesService,
        private userService: UserService,
        private apiErrorService: ApiErrorService,
    ) {}

    public user: User;

    public page: FirstStepsPanelPage = 'intro';

    public productName = getProductName();

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.subscribeToUserChange();
    }

    public subscribeToUserChange() {
        const subscription = this.loggedInUserService.getUser$().subscribe({
            next: (user) => {
                this.user = user;
            },
        });
        this.subscriptions.push(subscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Videos
    //****************************************************************************/
    public openVideo(heading: string, videoUrl: string) {
        this.dialog.open<VideoPlayerDialogComponent, VideoPlayerDialogData>(VideoPlayerDialogComponent, {
            data: {
                heading,
                videoUrl,
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Videos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public closePanel(): void {
        this.user.userInterfaceStates.introVideosVisible = false;
        this.saveUser();

        // Remember having shown the user the tutorial flag
        if (!store.get('howToReopenIntroVideosHasBeenDisplayed')) {
            this.toastService.info(
                'Intro-Videos erneut öffnen?',
                'Klicke auf das Fragezeichen oben rechts in der Kopfleiste.',
                {
                    timeOut: 5000,
                },
            );
            store.set('howToReopenIntroVideosHasBeenDisplayed', true);
        }
    }

    /**
     * After the slide out animation finished, remove the overlay
     */
    public handleFullPanelAnimationEnd(event: AnimationEvent): void {
        // Don't dispose the dialog after the entry animation finished.
        if (event.fromState === 'void') return;

        this.overlayRef.dispose();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls
    /////////////////////////////////////////////////////////////////////////////*/

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

export type FirstStepsPanelPage = 'intro' | 'earnMoney' | 'discoverFeatures';

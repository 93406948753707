import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { map } from 'rxjs/operators';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { AxHttpSyncBlob } from '@autoixpert/lib/database/ax-http-sync-blob.class';
import { httpRetry } from '@autoixpert/lib/rxjs-http-retry';
import { DocumentType } from '@autoixpert/models/documents/document-metadata';
import { BlobDataType } from '@autoixpert/models/indexed-db/database-blob.types';
import { Report } from '@autoixpert/models/reports/report';
import { SignatureElement } from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { OfflineSyncBlobServiceBase } from '../libraries/database/offline-sync-blob-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class ClaimantSignatureFileService extends OfflineSyncBlobServiceBase {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'claimantSignatureFile',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            // The upload is overwritten in this service's custom HTTP Sync class.
            formDataBlobFieldName: null,
        });

        /**
         * Overwrite the HTTP Sync service because watermark images have a custom path: It includes the team ID.
         */
        this.httpSync = new AxHttpSyncClaimantSignatureFile({
            serviceName: this.serviceName,
            serviceNamePlural: this.serviceNamePlural,
            httpClient: this.httpClient,
            // The upload is overwritten in this service's custom HTTP Sync class.
            formDataBlobFieldName: null,
        });
    }
}

class AxHttpSyncClaimantSignatureFile extends AxHttpSyncBlob {
    //*****************************************************************************
    //  Create Remote
    //****************************************************************************/
    public async createRemote(record: { _id: BlobDataType['_id']; blob: Blob }): Promise<void> {
        const [reportId, ...fragments] = record._id.split('-');

        const fileName = [...fragments].filter(Boolean).join('-');

        const formData = new FormData();
        // The report ID will be prefixed on the server.
        formData.append('_id', fileName);
        formData.append('signature', record.blob);

        await this.httpClient
            .post(`${apiBasePath}/reports/${reportId}/claimantSignatures`, formData)
            .pipe(
                httpRetry({
                    delayMs: 2000,
                }),
            )
            .toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create Remote
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Find Remote & Get Offline-First
    //****************************************************************************/
    public async getRemote(recordId: BlobDataType['_id']): Promise<Blob> {
        // Signature slot ID = ID of the SignatureElement in the SignablePdfTemplateConfig
        const idParts = recordId.split('-');

        const reportId = idParts.splice(0, 1);
        const recordIdWithoutReportPrefix = idParts.join('-');

        // Use httpClient.request (instead of httpClient.get) to send a request without Angular's standard "Cache-Control: no-cache" headers.
        return this.httpClient
            .request('GET', `${apiBasePath}/reports/${reportId}/claimantSignatures/${recordIdWithoutReportPrefix}`, {
                headers: new HttpHeaders().set('Accept', 'image/jpeg, image/png, */*'),
                observe: 'response',
                responseType: 'blob',
            })
            .pipe(map((response) => response.body))
            .toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Find Remote & Get Offline-First
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Delete Remote
    //****************************************************************************/
    public async deleteRemote(claimantSignatureFileRecordId: BlobDataType['_id']): Promise<void> {
        const [reportId, ...claimantSignatureId] = claimantSignatureFileRecordId.split('-');
        await this.httpClient
            .delete(`/api/v0/reports/${reportId}/claimantSignatures/${claimantSignatureId.join('-')}`)
            .toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Delete Remote
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarShape } from '@autoixpert/models/reports/car-identification/car';
import { SpareTireEquipment } from '@autoixpert/models/reports/car-identification/spare-tire-equipment';
import { Report } from '@autoixpert/models/reports/report';
import { triggerClickEventOnSpaceBarPress } from 'src/app/shared/libraries/trigger-click-event-on-space-bar-press';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';

@Component({
    selector: 'spare-tire-equipment-editor',
    templateUrl: './spare-tire-equipment-editor.component.html',
    styleUrls: ['spare-tire-equipment-editor.component.scss'],
    animations: [fadeInAndOutAnimation(), runChildAnimations()],
})
export class SpareTireEquipmentEditorComponent implements OnInit {
    constructor(private toastService: ToastService) {}

    @Input() userPreferences: UserPreferencesService;
    @Input() report: Report;
    @Input() disabled: boolean;
    @Output() dataChange: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Whether an optional comment field is shown.
     */
    protected spareTireCommentShown: boolean;

    /**
     * Whether the spare tire dialog is currently shown.
     */
    protected spareTireDetailsDialogShown: boolean;

    /** For usage in template */
    protected triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;

    ngOnInit(): void {
        this.determineIfSpareTireCommentShouldBeShown();
    }

    protected emitTireChange(): void {
        this.dataChange.emit();
    }

    private determineIfSpareTireCommentShouldBeShown() {
        if (this.report.car.spareTireEquipment.comment) {
            this.showSpareTireComment();
        }
    }

    protected showSpareTireComment() {
        this.spareTireCommentShown = true;
    }

    protected hideSpareTireComment() {
        this.spareTireCommentShown = false;
        this.report.car.spareTireEquipment.comment = null;
    }

    protected selectSpareWheelType(type: SpareTireEquipment['type']) {
        if (this.disabled) return;

        // Shorthand
        const spareTire = this.report.car.spareTireEquipment;

        // If the clicked option is already selected, deselect it.
        if (spareTire.type === type) {
            spareTire.type = null;
        } else {
            spareTire.type = type;
        }
        // In case we just de-selected the spare tire, clear its details.
        if (spareTire.type !== 'spareWheel') {
            spareTire.dimension = null;
            spareTire.treadInMm = null;
            spareTire.manufacturer = null;
            spareTire.season = null;
        }
    }

    get carShapeHasSpareTires(): boolean {
        const carShapesWithoutSeasonalTires: CarShape[] = ['bicycle', 'e-bike', 'pedelec'];
        return !carShapesWithoutSeasonalTires.includes(this.report.car.shape);
    }

    /**
     * Are all details except type empty?
     *
     * Type is excluded because we need this method mainly to determine if the data entered in the dialog is empty.
     */
    public areSpareTireDetailsEmpty(): boolean {
        // Shorthand
        const spareTire = this.report.car.spareTireEquipment;

        // The season does not matter because it won't be shown in the preview.
        return !spareTire.dimension && !spareTire.manufacturer && !spareTire.treadInMm;
    }

    public hideSpareTireEquipmentSection() {
        this.userPreferences.spareTireEquipmentShown = false;
        if (this.report.car.spareTireEquipment.type || !this.areSpareTireDetailsEmpty()) {
            this.toastService.info(
                'Ausblenden ab nächstem Gutachten',
                'Wenn Daten für Notbereifung vorhanden sind, werden sie trotzdem angezeigt.',
            );
        }
    }
}

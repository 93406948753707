import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatIdentificationResponsePerVehicle } from 'src/app/reports/details/car-data/car-data.component';
import { DatAuthenticationService } from './dat-authentication.service';

@Injectable()
export class DatVehicleIdentificationService {
    constructor(
        private httpClient: HttpClient,
        private datAuthenticationService: DatAuthenticationService,
    ) {}

    /**
     * Get the vehicle information from the damage calculation of a DAT dossier.
     */
    async getVehicleFromDamageCalculation(dossierId): Promise<DatIdentificationResponsePerVehicle> {
        const datJwt = await this.datAuthenticationService.getJwt();

        return this.httpClient
            .get<DatIdentificationResponsePerVehicle>(`/api/v0/dat/dossiers/${dossierId}`, {
                params: {
                    dossierType: 'damageCalculation',
                },
                headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
            })
            .toPromise();
    }

    /**
     * Get the vehicle information from the valuation of a DAT dossier.
     */
    async getVehicleFromValuation(dossierId): Promise<DatIdentificationResponsePerVehicle> {
        const datJwt = await this.datAuthenticationService.getJwt();

        return this.httpClient
            .get<DatIdentificationResponsePerVehicle>(`/api/v0/dat/dossiers/${dossierId}`, {
                params: {
                    dossierType: 'valuation',
                },
                headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
            })
            .toPromise();
    }
}

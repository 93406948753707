import { generateId } from '../../lib/generate-id';

export class LineItem {
    constructor(options: Partial<LineItem> = {}) {
        Object.assign(this, options);
    }

    _id: string = generateId();
    position: number = null; // Rechnungsposition
    description: string = ''; // Beschreibung
    quantity: number = null; // Anzahl
    unitPrice: number = null; // Einzelpreis
    unit: string = null;
    active: boolean = true;
    /**
     * Some line items need to be subtracted from the HUK table's target invoice total to get the target assessor fee. If this property is set to true,
     * this line item will be subtracted.
     * Example for selectedForHuk === true: Charge for IT systems
     * Example for selectedForHuk === false: An invoice for a required hoist is added to the assessor's invoice.
     */
    includedInHukBaseFee?: boolean = false;

    // Keep the reference to the line item template - to be able to update the template
    lineItemTemplateId?: string = null;
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    Output,
} from '@angular/core';
import { User } from '@autoixpert/models/user/user';
import { UserSelectorListComponent } from './user-selector-list.component';

@Directive({
    selector: '[userSelectorInputAnchor]',
    exportAs: 'userSelectorInputAnchor',
})
export class UserSelectorInputAnchorDirective {
    constructor(
        private overlayService: Overlay,
        private elementRef: ElementRef,
    ) {}

    @Input() theme: 'light' | 'dark' = 'light';

    @Output() userSelected: EventEmitter<User> = new EventEmitter();

    private overlayRef: OverlayRef;
    private componentRef: ComponentRef<UserSelectorListComponent>;

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openOverlay() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 5,
                    },
                ])
                .withPush(true)
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<UserSelectorListComponent>(UserSelectorListComponent, null);

        // Attach Component to Portal Outlet
        this.componentRef = this.overlayRef.attach(componentPortal);

        // Update component properties.
        this.componentRef.instance.theme = this.theme;
        this.componentRef.instance.theme = this.theme;

        // Subscribe to changes in component.
        this.componentRef.instance.userSelected.subscribe((newDate) => {
            this.userSelected.emit(newDate);
        });

        this.componentRef.instance.closeList.subscribe(() => {
            this.overlayRef.detach();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

import { isGtmotiveUserComplete } from '@autoixpert/lib/users/is-gtmotive-user-complete';
import { AffectedUserGroup } from '@autoixpert/models/notifications/downtime-notification';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { isQapterixpertTeam } from '../teams/is-qapterixpert-team';
import { isAudatexUserComplete } from './is-audatex-user-complete';
import { isAutoonlineUserComplete } from './is-autoonline-user-complete';
import { isCarcasionUserComplete } from './is-carcasion-user-complete';
import { isCarTvUserComplete } from './is-cartv-user-complete';
import { isDatUserComplete } from './is-dat-user-complete';
import { isGdvUserComplete } from './is-gdv-user-complete';
import { isWinvalueUserComplete } from './is-winvalue-user-complete';

/**
 * Determine what user groups a user belongs to.
 *
 * User groups are used to display global notifications, mostly in case of downtimes.
 *
 * We must filter downtimes to the affected user groups though: If the CARTV interface is down, only CARTV users must see the notification.
 */
export function getUserGroups({ user, team }: { user: User; team: Team }): AffectedUserGroup[] {
    const affectedUserGroups: AffectedUserGroup[] = [];

    // Everybody is part of the 'all' group.
    affectedUserGroups.push('all');

    if (isQapterixpertTeam(team)) {
        affectedUserGroups.push('qapterixpert');
    } else {
        affectedUserGroups.push('autoixpert');
    }

    if (isAudatexUserComplete(user)) {
        affectedUserGroups.push('qapter');
    }
    if (isAutoonlineUserComplete(user)) {
        affectedUserGroups.push('autoonline');
    }
    if (isDatUserComplete(user)) {
        affectedUserGroups.push('dat');
    }
    if (isCarcasionUserComplete(user)) {
        affectedUserGroups.push('carcasion');
    }
    if (isCarTvUserComplete(user)) {
        affectedUserGroups.push('cartv');
    }
    if (isGdvUserComplete(user)) {
        affectedUserGroups.push('gdv');
    }
    if (isGtmotiveUserComplete(user)) {
        affectedUserGroups.push('gtmotive');
    }
    if (isWinvalueUserComplete(user)) {
        affectedUserGroups.push('winvalue');
    }

    return affectedUserGroups;
}

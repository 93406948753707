import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';

export class AudatexCalculationText {
    constructor(template?: Partial<AudatexCalculationText>) {
        this._id = generateId();
        if (template) {
            Object.assign(this, template);
        }
    }

    _id: string;
    content: string;
    controlSheet: string;
    createdBy: string;
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

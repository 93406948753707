import { DateTime } from 'luxon';

/**
 * Shorthand to determine if two dates are equal.
 *
 * Might be necessary for date representations including a time (datetime vs. ISO Date).
 */
export function areDatesEqual(dateA: string, dateB: string): boolean {
    if (!dateA || !dateB) return false;

    return DateTime.fromISO(dateA).toISODate() === DateTime.fromISO(dateB).toISODate();
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { toGermanDate } from '@autoixpert/lib/ax-luxon';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { isDatTestVin } from '@autoixpert/lib/car/is-dat-test-vin';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { areCredentialsForCarIdentificationProviderComplete } from '@autoixpert/lib/users/are-credentials-for-car-identification-provider-complete';
import { isAudatexTestAccount } from '@autoixpert/lib/users/is-audatex-test-account';
import { isDatTestAccount } from '@autoixpert/lib/users/is-dat-test-account';
import { LegacyReport } from '@autoixpert/models/imports/legacy-report';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { CarEquipmentService } from 'src/app/shared/services/car-equipment.service';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { slideInAndOutVertically } from '../../../../shared/animations/slide-in-and-out-vertical.animation';
import { getDatErrorHandlers } from '../../../../shared/libraries/error-handlers/get-dat-error-handlers';
import { getGtmotiveErrorHandlers } from '../../../../shared/libraries/error-handlers/get-gtmotive-error-handlers';
import { getCopyableCarProperties } from '../../../../shared/libraries/get-copyable-car-properties';
import { openPhantomCalculationHelpInNewTab } from '../../../../shared/libraries/open-phantom-calculation-help-in-new-tab';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { AudatexTaskService } from '../../../../shared/services/audatex/audatex-task.service';
import { AudatexVinResult, AudatexVinService } from '../../../../shared/services/audatex/audatex-vin.service';
import { DatAuthenticationService } from '../../../../shared/services/dat-authentication.service';
import { GtmotiveEstimateService } from '../../../../shared/services/gtmotive/gtmotive-estimate.service';
import { GtmotiveVinResult, GtmotiveVinService } from '../../../../shared/services/gtmotive/gtmotive-vin.service';
import { LegacyReportService } from '../../../../shared/services/legacy-report.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../../shared/services/network-status.service';
import { ReportService } from '../../../../shared/services/report.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';
import { UserService } from '../../../../shared/services/user.service';
import { DatIdentificationResponsePerVehicle } from '../../car-data/car-data.component';

/**
 * VIN input used for displaying and querying the VIN from either Audatex or DAT.
 */
@Component({
    selector: 'vin-input',
    templateUrl: 'vin-input.component.html',
    styleUrls: ['vin-input.component.scss'],
    animations: [fadeInAndSlideAnimation(), slideInAndOutVertically()],
})
export class VinInputComponent implements OnInit, OnDestroy, OnChanges {
    constructor(
        private httpClient: HttpClient,
        private apiErrorService: ApiErrorService,
        private reportService: ReportService,
        private loggedInUserService: LoggedInUserService,
        private router: Router,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private toastService: ToastService,
        public userPreferences: UserPreferencesService,
        private datAuthenticationService: DatAuthenticationService,
        private audatexVinService: AudatexVinService,
        private audatexTaskService: AudatexTaskService,
        private legacyReportService: LegacyReportService,
        private gtmotiveEstimateService: GtmotiveEstimateService,
        private gtmotiveVinService: GtmotiveVinService,
        private networkStatusService: NetworkStatusService,
        private carEquipmentService: CarEquipmentService,
        private userService: UserService,
    ) {}

    public user: User;
    public team: Team;

    @Input() car: Car;

    // Required when querying VINs from Audatex.
    @Input() report: Report;
    // Sufficient if only displaying VINs or only querying them from DAT.
    @Input() reportId: string;
    @Input() reportType: Report['type'];
    @Input() disabled: boolean;
    // The parent component has to handle car selection in case of multiple replies. The logic for that is centralized in the parent
    // because the KBA selector needs that logic as well. Therefore, in parent components not implementing that logic, vin queries are not possible.
    @Input() vinQueryAllowed = true;
    @Input() autofocus = false;

    // Give the parent a means to remove error messages from this input component.
    @Input() errorMessageClearer$: Subject<void>;

    @Output() carChange: EventEmitter<Car> = new EventEmitter<Car>();
    @Output() previousReportSelected: EventEmitter<Car> = new EventEmitter<Car>();
    @Output() vinResponses: EventEmitter<
        (DatIdentificationResponsePerVehicle | AudatexVinResult | GtmotiveVinResult)[]
    > = new EventEmitter();
    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();

    // Previous Reports
    public previousReports: Report[] = [];
    public legacyReports: LegacyReport[] = [];
    public previousReportsChecked: boolean;

    public vinRequestPending = false;

    public warningMessage: SafeHtml = '';
    public errorMessage: SafeHtml = '';

    private subscriptions: Subscription[] = [];

    ngOnInit() {
        this.subscriptions.push(
            // Update the team & user in case they were updated in a different tab.
            this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)),
            this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)),
        );

        // Listen to parent clearing local error messages.
        if (this.errorMessageClearer$) {
            const errorMessageClearerSubscription = this.errorMessageClearer$.subscribe(() =>
                this.setErrorMessage(null),
            );
            this.subscriptions.push(errorMessageClearerSubscription);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['car']) {
            // When coming back to this screen and the VIN has already been verified, we do not need to display that the vehicle has been visited multiple times before.
            if (!this.car.verifiedVin || this.car.verifiedVin !== this.car.vin) {
                this.findPreviousReports();
            }
        }
    }

    //*****************************************************************************
    //  Get VIN Data
    //****************************************************************************/
    public transformVinToUppercase() {
        this.car.vin = this.car.vin.toUpperCase();
    }

    /**
     * Check the format of the VIN: 17 digits, only certain characters and digits etc.
     * There is a checksum on position 9 in the North American standard. This, however, does NOT apply to European VINs. autoiXpert therefore
     * does not validate that checksum. Instead, it validates the checksum outside the VIN from the car's papers.
     */
    public validateVINFormat(): boolean {
        // If the car was produced before 1981, the VIN may be shorter than 17 digits and cannot be validated with our algorithm.
        if (this.car.productionYear && parseInt(moment(this.car.productionYear).format('YYYY')) < 1981) {
            this.setWarningMessage(null);
            return true;
        }

        // Oldtimer cars usually have a VIN different from today's standard. Skip checks.
        if (this.reportType === 'oldtimerValuationSmall') return;

        const vinValidationResponse = this.validateVin(this.car.vin);

        if (vinValidationResponse.valid) {
            this.setWarningMessage(null);
            return true;
        } else {
            switch (vinValidationResponse.errorCode) {
                // The user knows that an empty VIN will not be validated.
                case 'EMPTY_VIN':
                    this.setWarningMessage(null);
                    break;
                case 'INVALID_LENGTH':
                    this.setWarningMessage(
                        'Die VIN ist kürzer als 17 Stellen. Dies kann nur bei Fahrzeugen mit Baujahr vor 1981 der Fall sein.',
                    );
                    break;
                case 'INVALID_CHARS':
                    this.setWarningMessage(
                        'Die VIN beinhaltet ungültige Zeichen. Es dürfen nur Zahlen und Buchstaben außer O, Q und I verwendet werden.',
                    );
                    break;
                case 'INVALID_CHECKDIGIT':
                    // In case of Test VINs, skip validation
                    if (isDatTestVin(this.car.vin)) {
                        return true;
                    }
                    this.setWarningMessage('Die Prüfziffer der VIN ist nicht korrekt. Bitte überprüfe deine Eingabe.');
                    break;
            }
            return false;
        }
    }

    public replaceInvalidVinCharacters(event: KeyboardEvent): void {
        if (event.key === 'i' || event.key === 'I') {
            const caretPosition = (event.target as HTMLInputElement).selectionStart;
            this.car.vin = this.car.vin.substring(0, caretPosition) + '1' + this.car.vin.substring(caretPosition);
            event.preventDefault();
            // Reset the caret after the next Angular change detection cycle.
            window.setTimeout(() => {
                (event.target as HTMLInputElement).selectionStart = caretPosition + 1;
                (event.target as HTMLInputElement).selectionEnd = caretPosition + 1;
            }, 0);
        }
        if (['o', 'O', 'q', 'Q'].includes(event.key)) {
            const caretPosition = (event.target as HTMLInputElement).selectionStart;
            this.car.vin = this.car.vin.substring(0, caretPosition) + '0' + this.car.vin.substring(caretPosition);
            event.preventDefault();
            // Reset the caret after the next Angular change detection cycle.
            window.setTimeout(() => {
                (event.target as HTMLInputElement).selectionStart = caretPosition + 1;
                (event.target as HTMLInputElement).selectionEnd = caretPosition + 1;
            }, 0);
        }
        if (event.key === ' ') {
            event.preventDefault();
        }
    }

    public getNotCalculableTooltip(): string {
        // if (!this.car.verifiedVin) return;

        if (
            !this.car.datIdentification.isDamageCalculationPossible &&
            !this.car.datIdentification.isValuationPossible
        ) {
            return `Keine DAT-Kalkulation & -Bewertung möglich. (Daten fehlen bei der DAT)\n\nDas passiert häufig bei älteren, sehr jungen oder Import-Fahrzeugen.\n\nDu kannst in der DAT-Oberfläche aber ein ähnliches Fahrzeug wählen (Phantomkalkulation). Klicke für eine Anleitung auf das rote Dreieck.`;
        }
        if (!this.car.datIdentification.isDamageCalculationPossible) {
            return `Keine DAT-Kalkulation für dieses Fahrzeug möglich. (Daten fehlen bei der DAT)\n\nDas passiert häufig bei älteren, sehr jungen oder Import-Fahrzeugen.\n\nDu kannst in der DAT-Oberfläche aber ein ähnliches Fahrzeug wählen (Phantomkalkulation). Klicke für eine Anleitung auf das rote Dreieck.`;
        }
        if (!this.car.datIdentification.isValuationPossible) {
            return `Keine DAT-Bewertung möglich. (Daten fehlen bei der DAT)\n\nDas passiert häufig bei älteren, sehr jungen oder Import-Fahrzeugen. Oft können die Marktwertportale trotzdem einen Fahrzeugwert ermitteln.`;
        }
    }

    public openPhantomCalculationHelp(): void {
        openPhantomCalculationHelpInNewTab();
    }

    //*****************************************************************************
    //  Check VIN
    //****************************************************************************/
    /**
     * Validates the given VIN according to certain format conditions. See Wikipedia.
     * The results should be used to warn the user but should not prohibit the user from sending the VIN to the DAT.
     * @param {string} vin
     * @return {vinValidationResponse}
     */
    public validateVin(vin: string): vinValidationResponse {
        const defaults = {
            vinLength: 17,
            invalidChars: 'OQI',
            // All digits, all capital letters except OQI because they may be confused with numbers.
            // The string will be converted to upper case before validating.
            validRegEx: /[A-HJ-NPR-Z0-9]{17}/,
        };

        if (!vin) {
            return {
                valid: false,
                errorCode: 'EMPTY_VIN',
            };
        }

        // Cars produced after 1981 have a 17-digit VIN. Before, they could have VINs from 11 to 17 digits.
        // You may produce a warning the view but not an error.
        if (vin.length !== defaults.vinLength) {
            return {
                valid: false,
                errorCode: 'INVALID_LENGTH',
            };
        }

        // The vin should always be uppercase but we allow lowercase input to make things easier for the user.
        vin = vin.toUpperCase();

        if (!defaults.validRegEx.test(vin)) {
            return {
                valid: false,
                errorCode: 'INVALID_CHARS',
            };
        }

        return {
            valid: true,
        };
    }

    public getCheckDigit(vin: string): string {
        if (!this.validateVin(vin).valid) return null;

        const characterMap = {
            A: 1,
            B: 2,
            C: 3,
            D: 4,
            E: 5,
            F: 6,
            G: 7,
            H: 8,
            // I does not exist. It's always a 1 (one) instead.
            // 'I' : 9,
            J: 1,
            K: 2,
            L: 3,
            M: 4,
            N: 5,
            // O does not exist. It's always a 0 (zero) instead.
            // 'O' : 0,
            P: 7,
            // Q does not exist. It's always a 0 (zero) instead.
            // 'Q' : 8,
            R: 9,
            S: 2,
            T: 3,
            U: 4,
            V: 5,
            W: 6,
            X: 7,
            Y: 8,
            Z: 9,
        };
        const weights = [
            9, 8, 7, 6, 5, 4, 3, 2,
            // In the North American format, the check digit is on position 9. But not in the European format.
            10, 9, 8, 7, 6, 5, 4, 3, 2,
        ];

        // The user may enter lowercase characters. Uppercase is required for mapping.
        vin = vin.toUpperCase();

        // Newer VIN lengths should be 17 characters/digits but cars produced before 1981 may have VINs from
        // 11 to 17 characters/digits.
        const vinLength = vin.length;
        const vinCharList = vin.split('');
        let sum = 0;

        for (let i = 0; i < vinLength; ++i) {
            // If this is a string, convert it to the corresponding number to calculate the checksum.
            // A string will become NaN when converted to a number via the "+" operator.
            if (Number.isNaN(+vinCharList[i])) {
                sum += characterMap[vinCharList[i]] * weights[i];
            }
            // Numbers can be used for calculations immediately.
            else {
                sum += +vinCharList[i] * weights[i];
            }
        }

        // Might be a number between 0 and 9 or an X instead of a 10
        let checkDigit: number | string = sum % 11;

        // The checksum 10 has two digits, so replace it by X. This is a standard.
        if (checkDigit === 10) {
            checkDigit = 'X';
        }

        // Convert to string in case it's a number
        return checkDigit + '';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Check VIN
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Start request to get the data regarding the VIN from the DAT servers
     */
    public async getVinData(): Promise<void> {
        if (this.disabled || !this.car.vin || this.vinRequestPending) return;

        if (!this.vinQueryAllowed) {
            const toast = this.toastService.info(
                'VIN-Abfrage nur im Reiter "Fahrzeug" möglich',
                'Die VIN selbst wurde gespeichert. Klicke, um zum Reiter "Fahrzeug" zu gelangen.',
            );
            toast.click.subscribe(() => this.router.navigate(['../Fahrzeug'], { relativeTo: this.route }));
            return;
        }

        // Show warnings if there are issues with the VIN. Since this is not 100% reliable, only show warnings and do not
        // prohibit the user to send a server request.
        this.validateVINFormat();

        // Since getting VIN data is an online-only feature, block if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline('Offline nicht verfügbar', 'VINs sind abrufbar, sobald du wieder online bist.');
            return;
        }

        // Reset VIN error
        this.errorMessage = null;
        this.vinRequestPending = true;

        switch (this.car.identificationProvider) {
            case 'audatex':
                await this.getAudatexVinResult();
                break;
            case 'dat':
                await this.getDatVinResult();
                break;
            case 'gtmotive':
                await this.getGtmotiveVinResult();
                break;
            default:
                throw new Error(`Invalid identification provider: ${this.car.identificationProvider}`);
        }
    }

    public getVinQueryTooltip(): string {
        if (this.report.car.identificationProvider === 'audatex') {
            return 'Fahrzeugdaten über Audatex abfragen';
        } else if (this.report.car.identificationProvider === 'gtmotive') {
            return 'Fahrzeugdaten über GT Motive abfragen';
        } else {
            return 'Fahrzeugdaten bei der DAT abfragen';
        }
    }

    //*****************************************************************************
    //  DAT VIN Query
    //****************************************************************************/
    private async getDatVinResult() {
        //*****************************************************************************
        //  Check if number of test VINs have been used up
        //****************************************************************************/
        if (
            this.isNumberOfVinQueriesLimited() &&
            !isDatTestVin(this.car.vin) &&
            this.team.billing.testPeriodVinCounter >= this.team.billing.testPeriodVinLimit
        ) {
            this.toastService.warn(
                'Geschenkte Test-VINs aufgebraucht',
                'Wir hoffen, dir hat der Test bisher gefallen!\n\nSobald du deinen eigenen DAT-Account hinterlegst, kannst du wieder beliebige VINs über DAT abfragen.',
            );
            this.vinRequestPending = false;
            return;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Check if number of test VINs have been used up
        /////////////////////////////////////////////////////////////////////////////*/

        let vinResponsesPerVehicle: DatIdentificationResponsePerVehicle[];
        try {
            const datJwt = await this.datAuthenticationService.getJwt();

            /**
             * The VIN result may be more specific if a production year is sent along with the request. We got this hint from Mr. Engelbert Krensel (DAT).
             */
            let queryParams = new HttpParams();
            if (this.car.productionYear) {
                queryParams = queryParams.append('productionYear', this.car.productionYear);
            }

            vinResponsesPerVehicle = await this.httpClient
                .get<DatIdentificationResponsePerVehicle[]>(`/api/v0/dat/vin/${this.car.vin}`, {
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                    params: queryParams,
                })
                .toPromise();
        } catch (error) {
            this.vinRequestPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDatErrorHandlers(),
                    DAT_VIN_BLOCKED: {
                        action: () => {
                            this.setErrorMessage(
                                'Die Daten für dieses Fahrzeug können nicht angezeigt werden, weil der Hersteller die VIN gesperrt hat.<br>' +
                                    'Um die VIN-Daten für dieses gesperrte Fahrzeug zu erhalten, erteile der DAT bitte einen separaten Auftrag.<br><br>' +
                                    'Für weitere Informationen zu gesperrten Fahrzeugen von Audi, Bentley, Seat, Skoda, Volkswagen erreichst die DAT unter <a href="mailto:gesperrte.fahrzeuge@dat.de" target="_blank" rel=”noopener”>gesperrte.fahrzeuge@dat.de</a> oder Telefon: 0711/4503-140.',
                            );
                        },
                    },
                    INVALID_VIN: {
                        action: () => {
                            this.setErrorMessage(
                                `Die VIN ist nicht gültig.<br>Bitte prüfe die Länge (17 Zeichen) und ggf. ungültige Zeichen (Nur Zahlen und Buchstaben außer O, Q und I).`,
                            );
                        },
                    },
                    MANUFACTURER_INTERFACE_ERROR: {
                        action: () => {
                            this.setErrorMessage(
                                `In der Schnittstelle zwischen der DAT und dem Hersteller des Fahrzeugs ist ein Fehler aufgetreten.<br>Versuche es bitte später erneut.`,
                            );
                        },
                    },
                    MANUFACTURER_VIN_INACCESSIBLE: {
                        action: () => {
                            this.setErrorMessage(
                                `Der Hersteller liefert der DAT keine Daten für dieses Fahrzeug.<br>Du kannst alternativ eine KBA-Abfrage versuchen.`,
                            );
                        },
                    },
                    DAT_MARKET_INDEX_NOT_FOUND: {
                        action: () => {
                            this.setErrorMessage(
                                `Der DAT-Marktindex, den die DAT für diese VIN hinterlegt hat, konnte bei der DAT nicht gefunden werden. Die DAT hat dieses Fahrzeug dann noch nicht als kalkulierbares/bewertbares Fahrzeug vorliegen.<br><br>Als Workaround kannst du eine DAT-Phantomkalkulation anlegen.`,
                            );
                        },
                    },
                    VIN_NOT_FOUND: {
                        action: () => {
                            this.setErrorMessage(
                                'Leider kann die DAT dir für die abgefragte Fahrgestellnummer keine Fahrzeugdaten über die VIN-Abfrage anbieten.<br>' +
                                    'Bitte prüfe die Verfügbarkeit der Hersteller in der <a href="https://www.dat.de/fileadmin/media/vininfo/de/vininfo.pdf" target="_blank" rel=”noopener”>DAT VIN-Info</a>.<br><br>' +
                                    'Für weitere Information steht dir der DAT-Kundendienst unter der Tel. 0711/4503-130 oder <a href="mailto:kundendienst@dat.de" target="_blank" rel=”noopener”>kundendienst@dat.de</a> zur Verfügung.',
                            );
                        },
                    },
                    DAT_UNHANDLED_ERROR: {
                        action: () => {
                            this.setErrorMessage(
                                `Die DAT hat einen unbekannten Fehler zurückgeliefert.<br>Bitte wende dich an den autoiXpert-Support.`,
                            );
                        },
                    },
                    INVALID_PRODUCTION_DATE: {
                        action: () => {
                            this.setErrorMessage(`Das Produktionsjahr ist ungültig.`);
                        },
                    },
                    DAT_SOAP_INTERFACE_STOPPED: {
                        action: () => {
                            this.setErrorMessage(
                                `Die DAT-Schnittstelle ist derzeit nicht erreichbar.<br>Bitte versuche es später erneut.`,
                            );
                        },
                    },
                    DAT_INTERNAL_SERVICE_UNREACHABLE: {
                        action: () => {
                            this.setErrorMessage(
                                `Die DAT-Schnittstelle ist derzeit nicht erreichbar.<br>Bitte versuche es später erneut.`,
                            );
                        },
                    },
                    DAT_DATA_PROCESSING_ISSUE: {
                        action: () => {
                            this.setErrorMessage(
                                `Die DAT-Server haben ein Problem mit der Datenverarbeitung gemeldet.<br>Bitte versuche es später erneut.`,
                            );
                        },
                    },
                    DAT_VIN_SERVER_INTERNAL_ERROR: {
                        action: (error) => {
                            this.setErrorMessage(
                                `Die DAT konnte die VIN-Abfrage nicht verarbeiten.<br>Bitte führe die Abfrage über die <a href="https://www.dat.de" target="_blank" rel="noopener">DAT.de</a> durch. Scheitert die Abfrage auch bei der DAT.de, wende dich an die <a href="https://www.dat.de/service/support/" target="_blank" rel="noopener">DAT-Hotline</a>.` +
                                    (error.data?.datErrorMessage
                                        ? `<br><br>Fehlermeldung des VIN-Servers des Herstellers: ${error.data.datErrorMessage}`
                                        : ''),
                            );
                        },
                    },
                    DAT_UNAUTHORIZED: {
                        action: () => {
                            this.setErrorMessage(
                                `Die DAT hat die Anfrage abgelehnt. Bitte prüfe Benutzername, Passwort und Kundennummer in den <a href="Einstellungen#calculation-providers-section" target="_blank">Einstellungen</a>. <br><br>Falls du einen neuen DAT-Account benötigst, wende dich an die <a href="/Hilfe">Hotline</a>.`,
                            );
                        },
                    },
                    DAT_VIN_SERVICE_UNLICENSED: {
                        action: () => {
                            this.setErrorMessage(
                                `Die DAT hat die Anfrage abgelehnt.<br><br>Zur Nutzung der VIN-Abfrage benötigst du eine bestimmte DAT-Einstellung, die dir noch nicht zugeordnet wurde (Stichwort "VIN-Zusatzvereinbarung"). Wende dich dazu bitte an deinen DAT-Ansprechpartner oder den DAT-Vertrieb unter der Tel: 0711/4503-140 oder via E-Mail an <a href="mailto:vertrieb@dat.de" target="_blank" rel="noopener">vertrieb@dat.de</a>.`,
                            );
                        },
                    },
                    DAT_INTERFACE_PARTNER_UNAUTHORIZED: {
                        action: () => {
                            this.setErrorMessage(
                                `Die DAT hat die VIN-Abfrage über autoiXpert abgewiesen.<br>Bitte wende dich an den autoiXpert-Support und nenne den Fehler-Code "DAT_INTERFACE_PARTNER_UNAUTHORIZED".`,
                            );
                        },
                    },
                    DAT_UNLICENSED_MANUFACTURER: {
                        action: () => {
                            this.setErrorMessage(
                                `Die DAT lässt diese Anfrage nicht zu, weil dir Lizenzrechte fehlen.<br>Dies kann daran liegen, dass dein DAT-Zugang abgelaufen ist. Bitte prüfe deinen Zugang via <a href="www.dat.de" target="_blank" rel=”noopener”>www.dat.de</a>.<br><br>` +
                                    'Für weitere Information steht dir der DAT-Kundendienst unter der Tel. 0711/4503-130 oder <a href="mailto:kundendienst@dat.de" target="_blank" rel=”noopener”>kundendienst@dat.de</a> zur Verfügung.',
                            );
                        },
                    },
                    VIN_IN_REVIEW_PROCESS: {
                        action: () => {
                            this.setErrorMessage(
                                `Die VIN befindet sich gerade im Genehmigungsverfahren des Herstellers und ist noch nicht zum Abruf verfügbar.`,
                            );
                        },
                    },
                    DAT_NUMBER_OF_VIN_REQUESTS_EXHAUSTED: {
                        action: () => {
                            this.setErrorMessage(
                                `Die zulässige Höchstzahl an DAT-VIN-Abfragen für deinen Account wurde überschritten. Bitte wende dich an den <a href="mailto:vertrieb@dat.de" target="_blank" rel=”noopener”>DAT Vertrieb</a>.`,
                            );
                        },
                    },
                    DAT_MANUFACTURER_NOT_SUPPORTED: {
                        action: () => {
                            this.setErrorMessage(
                                'Leider kann die DAT dir für die abgefragte Fahrgestellnummer keine Fahrzeugdaten über die VIN-Abfrage anbieten. Bitte prüfe die Verfügbarkeit der Hersteller in der <a href="https://www.dat.de/fileadmin/media/vininfo/de/vininfo.pdf" target="_blank" rel=”noopener”>DAT VIN-Info</a>.<br><br>' +
                                    'Für weitere Information steht dir der DAT-Kundendienst unter der Tel. 0711/4503-130 oder <a href="mailto:kundendienst@dat.de" target="_blank" rel=”noopener”>kundendienst@dat.de</a> zur Verfügung.',
                            );
                        },
                    },
                },
                // Includes "GENERAL_ERROR"
                defaultHandler: {
                    action: (error) => {
                        this.setErrorMessage(
                            `Der VIN-Service der DAT ist momentan nicht erreichbar, z. B. aufgrund von Wartungsarbeiten bei der DAT oder Ausfällen beim Fahrzeughersteller.${
                                error.data && error.data.datErrorMessage
                                    ? '<br><br>Fehlermeldung der DAT: ' + error.data.datErrorMessage + '<br><br>'
                                    : ''
                            }<br>Bitte frage die VIN testhalber in der DAT-Oberfläche unter www.dat.de ab. Funktioniert es dort auch nicht, wende dich bitte an den <a href="https://www.dat.de/service/support/" target="_blank" rel="noopener">DAT-Support</a>.`,
                        );
                    },
                },
            });
        }
        this.emitVinResponses(vinResponsesPerVehicle);
        this.vinRequestPending = false;
    }

    /**
     * The number of VIN queries shall only be limited if the
     * user is using a test account for the respective data provider.
     *
     * Exemptions for an entire team can be made through the account management.
     */
    public isNumberOfVinQueriesLimited(): boolean {
        if (this.team.isExemptFromTestAccountRestrictions) return false;

        switch (this.car.identificationProvider) {
            case 'dat':
                return isDatTestAccount(this.user.datUser);
            case 'audatex':
                return isAudatexTestAccount(this.user.audatexUser);
            default:
                return false;
        }
    }

    public hasReachedLimitOfTestVins(): boolean {
        return this.team.billing.testPeriodVinCounter >= this.team.billing.testPeriodVinLimit;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT VIN Query
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Audatex VIN Query
    //****************************************************************************/
    private async getAudatexVinResult() {
        //*****************************************************************************
        //  Check if number of test VINs have been used up
        //****************************************************************************/
        if (
            this.isNumberOfVinQueriesLimited() &&
            this.team.billing.testPeriodVinCounter >= this.team.billing.testPeriodVinLimit
        ) {
            this.toastService.warn(
                'Geschenkte Test-VINs aufgebraucht',
                'Wir hoffen, dir hat der Test bisher gefallen!\n\nSobald du deinen eigenen Audatex-Account hinterlegst, kannst du wieder beliebige VINs über Audatex abfragen.',
            );
            this.vinRequestPending = false;
            return;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Check if number of test VINs have been used up
        /////////////////////////////////////////////////////////////////////////////*/

        /**
         * Create a task if it doesn't exist yet. Then query VIN results.
         */
        if (!this.report.audatexTaskId) {
            console.log(
                `No Audatex task ID associated with this report. Create an Audatex task and link it to this report.`,
            );
            try {
                await this.createAudatexTaskAndSaveReport();
            } catch (error) {
                this.vinRequestPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Audatex-Task konnte nicht angelegt werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }
        }

        let vinResponse: AudatexVinResult[];
        try {
            vinResponse = await this.audatexVinService.getVinResult(this.reportId, this.car.vin);
            this.emitVinResponses(vinResponse);

            if (vinResponse[0]?.newCarInformation?.model && !vinResponse[0]?.newCarInformation?.submodel) {
                this.setErrorMessage(
                    `Audatex kennt zu diesem Fahrzeug keinen Untertyp.<br><br>Öffne Qapter über die Lupe im Feld "Audatex-Code" und wähle ein vergleichbares Fahrzeug manuell.<br><br>Importiere die Fahrzeugdaten anschließend über den Download-Pfeil im Feld Audatex-Code.`,
                );
            }

            this.vinRequestPending = false;
        } catch (error) {
            this.vinRequestPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    AUDATEX_CREDENTIALS_INVALID: {
                        action: () => {
                            this.setErrorMessage(
                                `Audatex hat deine Zugangsdaten abgelehnt. Bitte überprüfe deine Audatex-Zugangsdaten in den <a href="/Einstellungen#calculation-providers-section">Einstellungen</a>.`,
                            );
                        },
                    },
                    AUDATEX_TASK_ID_MISSING: {
                        action: () => {
                            this.setErrorMessage(
                                `Task-ID von Audatex fehlt. Das ist ein technischer Fehler. Bitte wende dich an die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>`,
                            );
                        },
                    },
                    NO_PERMISSION_TO_GET_AUDATEX_TASK: {
                        action: () => {
                            this.setErrorMessage(
                                `Der hinterlegte Audatex-Nutzer hat keinen Zugriff auf den angefragten Task. Bitte prüfe, ob der richtige Audatex-Account in den Einstellungen angegeben wurde, mit dem der Vorgang angelegt wurde, oder starte einen neuen Vorgang.`,
                            );
                        },
                    },
                    AUDATEX_VIN_IDENTIFICATION_FAILED: {
                        action: () => {
                            this.setErrorMessage(
                                `Audatex kann die VIN nicht verarbeiten.<br><br>Öffne Qapter über die Lupe im Feld "Audatex-Code" und identifiziere das Fahrzeug manuell.<br><br>Importiere die Fahrzeugdaten anschließend über den Download-Pfeil im Feld Audatex-Code.<br><br>Bei Fragen kann dir die Datenhotline beim Audatex-Support weiterhelfen.`,
                            );
                        },
                    },
                    AUDATEX_FIELD_VALUE_TOO_LARGE: {
                        action: () => {
                            this.setErrorMessage(
                                `Der hinterlegte Audatex-Account hat nicht alle erforderlichen Rechte. Bitte kontaktiere Audatex mit dem Stichwort "FieldValueTooLarge bei VIN-Abfrage".`,
                            );
                        },
                    },
                },
                // Includes "GENERAL_ERROR"
                defaultHandler: {
                    action: (error) => {
                        this.setErrorMessage(
                            `Der VIN-Service von Audatex ist momentan nicht erreichbar, z. B. aufgrund von Wartungsarbeiten bei Audatex.${
                                error.data?.audatexErrorMessage
                                    ? '<br><br>Fehlermeldung von Audatex: ' +
                                      error.data.audatexErrorMessage +
                                      '<br><br>'
                                    : ''
                            }<br>Bitte frage die VIN testhalber in der Audatex-Oberfläche unter www.audatex.de ab. Funktioniert es dort nicht, wende dich bitte an den <strong>Audatex-Support</strong>.<br><br>Funktioniert die Abfrage dort, aber nicht in autoiXpert, wende dich bitte an die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                        );
                    },
                },
            });
        }
    }

    /**
     * We must create a task with Audatex and save the audatexTaskId on the report before we can continue with querying the VIN data.
     * @private
     */
    private async createAudatexTaskAndSaveReport() {
        try {
            await this.audatexTaskService.create({ report: this.report });
        } catch (error) {
            this.audatexTaskService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'Audatex-Task konnte nicht angelegt werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex VIN Query
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  GT Motive VIN Query
    //****************************************************************************/
    private async getGtmotiveVinResult() {
        /**
         * Create a task if it doesn't exist yet. Then query VIN results.
         */
        if (!this.report.gtmotiveEstimateId) {
            try {
                await this.createGtmotiveEstimateAndSaveReport();
            } catch (error) {
                this.vinRequestPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getGtmotiveErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'GT Motive VIN konnte nicht abgefragt werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }
        }
        let vinResponse: any;
        try {
            vinResponse = await this.gtmotiveVinService.getVinResult(this.reportId, this.car.vin);
            this.emitVinResponses(vinResponse);

            if (!vinResponse[0].newCarInformation.verifiedVin) {
                this.setWarningMessage(
                    'GT Motive kennt für dieses Fahrzeug nur Haupttyp & Untertyp. Bitte öffne die manuelle GT-Motive-Fahrzeugauswahl und wähle ein vergleichbares Fahrzeug (Phantom-Kalkulation).',
                );
            }

            this.vinRequestPending = false;
        } catch (error) {
            this.vinRequestPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    GTMOTIVE_VIN_QUERY_NO_RESULTS: {
                        action: (error) => {
                            this.setErrorMessage(
                                `GT Motive kennt für diese VIN kein Fahrzeug. Bitte identifiziere das Fahrzeug manuell über die <a href="${error.data.gtmotiveUserInterfaceUrl}" target="_blank">GT Motive Oberfläche</a>.`,
                            );
                        },
                    },
                    GTMOTIVE_ESTIMATE_ID_MISSING: {
                        action: () => {
                            this.setErrorMessage(
                                `Vorgangs-ID von GT Motive fehlt. Das ist ein technischer Fehler. Bitte wende dich an die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.`,
                            );
                        },
                    },
                    GTMOTIVE_ESTIMATE_NOT_FOUND: {
                        action: () => {
                            this.setErrorMessage(
                                `GT Motive konnte den mit diesem Gutachten verknüpften Vorgang nicht finden.<br>Kontaktiere die <a href="/Hilfe" target="_blank">autoiXpert Hotline</a>.`,
                            );
                        },
                    },
                },
                // Includes "GENERAL_ERROR"
                defaultHandler: {
                    action: (error) => {
                        this.setErrorMessage(
                            `Der VIN-Service von GT Motive ist momentan nicht erreichbar, z. B. aufgrund von Wartungsarbeiten bei GT Motive.${
                                error.data?.audatexErrorMessage
                                    ? '<br><br>Fehlermeldung von GT Motive: ' +
                                      error.data.gtmotiveErrorMessage +
                                      '<br><br>'
                                    : ''
                            }<br>Bitte frage die VIN testhalber in der GT Motive-Oberfläche unter <a href="https://estimate.mygtmotive.com/" target="_blank">estimate.mygtmotive.com</a> ab. Funktioniert es dort nicht, wende dich bitte an den <strong>GT Motive-Support</strong>.<br><br>Funktioniert die Abfrage dort, aber nicht in autoiXpert, wende dich bitte an die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                        );
                    },
                },
            });
        }
    }

    private async createGtmotiveEstimateAndSaveReport(): Promise<Report> {
        const response = await this.gtmotiveEstimateService.create(this.reportId);

        this.report.gtmotiveEstimateId = response.gtmotiveEstimateId;
        // Skip cache answer. We're only interested in when the server knows about the taskId.
        return this.saveReport({ waitForServer: true });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END GT Motive VIN Query
    /////////////////////////////////////////////////////////////////////////////*/

    public triggerVINVerificationOnEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.getVinData();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Verify VIN
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Previous Reports
    //****************************************************************************/
    public async findPreviousReports(): Promise<void> {
        // Don't start the request without a valid VIN
        if (!this.validateVin(this.car.vin).valid) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            return;
        }

        // Previous reports (from autoiXpert)
        const [previousReports, legacyReports] = await Promise.all([
            this.reportService.findByVin(this.car.vin).toPromise(),
            this.legacyReportService.find({ vin: this.car.vin }).toPromise(),
        ]);

        // Filter out the current report
        this.previousReports = previousReports.filter((report) => report._id !== this.reportId);
        this.report.vinFoundInPreviousReportIds = this.previousReports.map(({ _id }) => _id);

        // Previous reports (from legacy system)
        this.legacyReports = legacyReports;
        this.report.vinFoundInLegacyReports = this.legacyReports.map(({ _id }) => _id);

        this.report.vinPreviousReportsCheckedAt = new Date().toISOString();
        this.previousReportsChecked = true;

        await this.saveReport();
    }

    public clearPreviousAndLegacyReports(): void {
        this.previousReports = [];
        this.legacyReports = [];
        this.report.vinFoundInPreviousReportIds = [];
        this.report.vinFoundInLegacyReports = [];
    }

    public getPreviousReportsTriggerTooltip(): string {
        const reportsToShow = this.getPreviousReportsAmount();
        const date = new Date(this.report.vinPreviousReportsCheckedAt);
        const dateFormatted = toGermanDate(this.report.vinPreviousReportsCheckedAt);
        const time = `${('' + date.getHours()).padStart(2, '0')}:${('' + date.getMinutes()).padStart(2, '0')}`;

        if (!this.previousReportsChecked && this.report.vinPreviousReportsCheckedAt) {
            if (reportsToShow === 1) {
                return `Es wurde bei der letzen Abfrage am ${dateFormatted} um ${time} Uhr 1 Gutachten mit gleicher VIN gefunden. Für Details klicken.`;
            } else {
                return `Es wurden bei der letzen Abfrage am ${dateFormatted} um ${time} Uhr ${reportsToShow} Gutachten mit gleicher VIN gefunden. Für Details klicken.`;
            }
        }

        if (reportsToShow === 1) {
            return `Es wurde 1 Gutachten mit gleicher VIN gefunden. Für Details klicken.`;
        } else {
            return `Es wurden ${reportsToShow} Gutachten mit gleicher VIN gefunden. Für Details klicken.`;
        }
    }

    public getPreviousReportsAmount() {
        const reportsToShow = [
            ...this.previousReports.map(({ _id }) => _id),
            ...this.legacyReports.map(({ _id }) => _id),
            ...(this.report.vinFoundInPreviousReportIds || []),
            ...(this.report.vinFoundInLegacyReports || []),
        ]
            // Only count unique report IDs. The first occurrence is added, all other are omitted.
            .filter((value, index, self) => self.indexOf(value) === index);

        return reportsToShow.length;
    }

    public navigateToReport(report: Report): void {
        this.router.navigateByUrl(`/Gutachten/${report._id}`);
    }

    /**
     * When the user performs a middle-button click on a menu entry, open the report in a new window.
     *
     * @param {Report} report
     * @param {MouseEvent} event
     */
    public openReportInNewWindow(report: Report, event: MouseEvent): void {
        // Only consider presses of the middle button (wheel click)
        if (event.button !== 1) {
            return;
        }

        window.open(`/Gutachten/${report._id}`);
    }

    /**
     * Copy the properties of the car of a previous report, except the ones that have likely changed in the meantime.
     * @param previousReport
     */
    public async copyCarDataFromPreviousReport(previousReport: Report): Promise<void> {
        const copyOfPreviousReportCar: Car = getCopyableCarProperties(previousReport.car);
        Object.assign(this.car, copyOfPreviousReportCar);

        try {
            await this.carEquipmentService.copyToReport(previousReport._id, this.report._id);
        } catch (error) {
            this.toastService.error(
                'Ausstattung nicht kopiert',
                "Die Ausstattung aus dem alten Gutachten konnten nicht übernommen werden.<br />Bitte starte den Kopiervorgang erneut oder kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
            );
            console.error('Car equipments could not be copied from previous report.', error);
        }

        this.previousReportSelected.emit(this.car);
        this.emitReportChange();
        this.toastService.success('Fahrzeugdaten übernommen');
    }

    public displayInfoNoteAboutLegacyReport() {
        this.toastService.info(
            'Gutachten aus einem Fremdsystem',
            'Wenn du Details suchst, schaue bitte in deinem bisherigen System nach.',
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Previous Reports
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Brand Icons (Previous Reports List)
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Brand Icons (Previous Reports List)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Example VIN
    //****************************************************************************/
    public isTestUserForIdentificationProviderConfigured(): boolean {
        /**
         * Show test VINs for test accounts and the autoiXpert demo account (Stefan Brodbeck) so that it can use example VINs
         * during webinars and demonstrations on trade shows.
         */
        switch (this.car.identificationProvider) {
            case 'audatex':
                return (
                    isAudatexTestAccount(this.user.audatexUser) ||
                    this.user.audatexUser?.username === 'Ixpert.Test1@audatex.de'
                );
            case 'dat':
                return isDatTestAccount(this.user.datUser) || this.user.datUser?.username === 'schlandr';
            case 'gtmotive':
                return this.team.accountStatus === 'test';
        }
    }

    /**
     * Insert one of the exmaple VINs randomly.
     */
    public insertExampleVin(): void {
        const datTestVins: { vin: string; firstRegistration: IsoDate }[] = [
            {
                vin: 'WAUDATTESTSTUB001', // Audi A3
                firstRegistration: '2007-09-27',
            },
            {
                vin: 'WBADEXTESTSTUB001', // BMW 5er
                firstRegistration: '2014-08-27',
            },
            {
                vin: 'ZARDEXTESTSTUB001', // Alfa Romeo Giulietta
                firstRegistration: '2013-06-20',
            },
            {
                vin: 'JTHDEXTESTSTUB001', // Lexus GS
                firstRegistration: '2013-12-20',
            },
            {
                vin: 'WMWDEXTESTSTUB001', // Mini One
                firstRegistration: '2011-11-11',
            },
            {
                vin: 'YV1DEXTESTSTUB001', // Volvo XC60
                firstRegistration: '2014-05-18',
            },
        ];

        const realVins: { vin: string; firstRegistration: IsoDate }[] = [
            {
                vin: 'WBAEA31080CV65563', // BMW 6er
                firstRegistration: '2008-05-27',
            },
            {
                vin: 'WVWZZZ3CZFE486440', // VW Passat
                firstRegistration: '2015-12-14',
            },
            {
                vin: 'WP0ZZZ99ZMS237991', // Porsche 911 Cabrio
                firstRegistration: '2021-04-21',
            },
            {
                vin: 'SAJAA0122DNU06177', // Jaguar XF
                firstRegistration: '2013-09-20',
            },
        ];

        let exampleVins: { vin: string; firstRegistration: IsoDate }[] = [];
        switch (this.car.identificationProvider) {
            case 'dat':
                exampleVins = datTestVins;
                break;
            case 'audatex':
            case 'gtmotive':
                exampleVins = realVins;
                break;
        }

        const randomIndex = Math.round(Math.random() * (exampleVins.length - 1));
        const chosenVin = exampleVins[randomIndex];
        this.car.vin = chosenVin.vin;
        this.car.firstRegistration = chosenVin.firstRegistration;
        // Get a latest registration not long in the past
        this.car.latestRegistration = toIsoDate(DateTime.now().minus({ months: 2, days: 5 }));

        this.getVinData();
    }

    public hideExampleVinNote(): void {
        this.user.userInterfaceStates.exampleVinNoticeClosed = true;
        this.saveUser();
    }

    public reactivateExampleVinNote(): void {
        this.user.userInterfaceStates.exampleVinNoticeClosed = false;
        this.saveUser();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Example VIN
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Errors & Warnings
    //****************************************************************************/
    public setErrorMessage(errorMessage: string) {
        if (!errorMessage) {
            this.errorMessage = null;
            return;
        }
        this.errorMessage = this.domSanitizer.bypassSecurityTrustHtml(errorMessage);
    }
    public setWarningMessage(warningMessage: string) {
        if (!warningMessage) {
            this.warningMessage = null;
            return;
        }
        this.warningMessage = this.domSanitizer.bypassSecurityTrustHtml(warningMessage);
    }

    public areCredentialsForSelectedIdentificationProviderComplete(): boolean {
        return areCredentialsForCarIdentificationProviderComplete(this.car.identificationProvider, this.user);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Errors & Warnings
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    private emitVinResponses(
        vinResponsesPerVehicle: (DatIdentificationResponsePerVehicle | AudatexVinResult | GtmotiveVinResult)[],
    ): void {
        this.vinResponses.emit(vinResponsesPerVehicle);
    }

    public emitReportChange(): void {
        this.carChange.emit(this.car);
    }

    public emitFocusEvent(event: FocusEvent): void {
        this.focus.emit(event);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    // Only used for saving the Audatex Task Id.
    private saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        return this.reportService.put(this.report, { waitForServer });
    }

    private async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    protected readonly isDatTestVin = isDatTestVin;
}

interface vinValidationResponse {
    valid: boolean;
    checkDigit?: string;
    errorCode?: string;
}

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getPersaldoErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        MISSING_PARAMETER_INVOICE_NUMBER: {
            title: 'Rechnungsnummer fehlt',
            body: 'Es wurde noch keine Rechnungsnummer gesetzt. Bitte prüfe das im Reiter "Rechnung".',
        },
        MISSING_PERSALDO_USER: {
            title: 'Goya-Mobility-User fehlt',
            body: 'Der Goya-Mobility-Nutzername kann nicht gefunden werden. Bitte trage die Kundennummer nach.',
        },
        INVOICE_NOT_FOUND: {
            title: 'Rechnung nicht gefunden',
            body: 'Die Rechnung zu diesem Gutachten konnte nicht gefunden werden. Bitte öffne das Gutachten und schließe es erneut ab.',
        },
        MISSING_INVOICE_DATE: {
            title: 'Rechnungsdatum fehlt',
            body: 'Bitte definiere ein Rechnungsdatum.',
        },
        INVOICE_NUMBER_ALREADY_EXPORTED: {
            title: 'Rechnungs-Nr. existiert bereits',
            body: 'Verändere die Rechnungsnummer und exportiere erneut oder kontaktiere Goya Mobility direkt, um die Rechnung entfernen zu lassen.',
        },
        CREATING_PERSALDO_CASE_FAILED: {
            title: 'Vorgang nicht angelegt',
            body: 'Die Daten konnten nicht an Goya Mobility gesendet werden. Bitte prüfe deine Zugangsdaten in den Einstellungen.',
        },
        DOCUMENT_GENERATION_FAILED: {
            title: 'Dokumente nicht generiert',
            body: 'Die PDF-Dokumente konnten nicht generiert werden. Bitte versuche es noch einmal oder versuche, die Dokumente einzeln herunterzuladen, um die Ursache zu finden.',
        },
        PERSALDO_HOUSE_NUMBER_REQUIRED: {
            title: 'Hausnummer fehlt',
            body: 'Goya Mobility erfordert eine Hausnummer.',
        },
        INVOICE_IS_MISSING_FOR_PERSALDO_EXPORT: {
            title: 'Dokument "Rechnung" inaktiv',
            body: 'Bitte aktiviere das Dokument "Rechnung" für den Export zu Goya Mobility.',
        },
        REPORT_IS_MISSING_FOR_PERSALDO_EXPORT: {
            title: 'Dokument "Gutachten" inaktiv',
            body: 'Bitte aktiviere das Dokument "Gutachten" für den Export zu Goya Mobility.',
        },
        ASSIGNMENT_DECLARATION_IS_MISSING_FOR_PERSALDO_EXPORT: {
            title: 'Dokument "Abtretung" inaktiv',
            body: 'Bitte aktiviere das Dokument "Abtretung" für den Export zu Goya Mobility.',
        },
        MISSING_DAMAGER_LICENSE_PLATE: {
            title: 'Kennzeichen des Unfallgegners fehlt',
            body: 'Goya Mobility erfordert das Kennzeichen des Unfallgegners. Falls du das Kennzeichen nicht kennst, gib bitte "unbekannt" dafür ein.',
        },
        FIELDS_MISSING: (error) => ({
            title: 'Angaben fehlen',
            body:
                'Goya Mobility erfordert die Angabe folgender Felder: \n- ' +
                error.data.missingFields.map((field) => field.germanTranslation).join('\n- '),
        }),
    };
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { RevenueByCityAnalyticsRecord } from '@autoixpert/models/analytics/customer-analytics.model';
import { AnalyticsFilterComponent } from '../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { RevenueAnalyticsService } from '../../shared/services/analytics/revenue-analytics.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { AnalyticsSettingsMenuComponent } from '../analytics-settings-menu/analytics-settings-menu.component';
import { setDefaultChartOptions } from '../default-chart-options';

@Component({
    selector: 'revenue-by-city',
    templateUrl: './revenue-by-city.component.html',
    styleUrls: ['./revenue-by-city.component.scss'],
})
export class RevenueByCityComponent implements OnInit {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;
    @ViewChild(AnalyticsSettingsMenuComponent, { static: true }) protected settings: AnalyticsSettingsMenuComponent;

    constructor(
        private revenueAnalyticsService: RevenueAnalyticsService,
        private apiErrorService: ApiErrorService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    public chartTitle: string = 'Umsatz nach Ort';
    private chart: ApexCharts;
    private chartOptions: apexcharts.ApexOptions;
    public analyticsPending: boolean = false;

    public totalRevenue: number = 0;
    public totalNumberOfInvoices: number = 0;

    public records: RevenueByCityAnalyticsRecord[] = [];
    public groupedRecords: RevenueByCityAnalyticsRecord[] = [];
    private maximumGraphCategories: number = 10;

    public groupRecordsByCity: boolean = true;

    public filterAnalyticsFrom: string;
    public filterAnalyticsTo: string;

    ngOnInit() {
        setDefaultChartOptions();

        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateBackToAnalyticsList(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    public rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Group by ZIP or City
    //****************************************************************************/
    public groupRecords(): void {
        if (!this.groupRecordsByCity) {
            this.groupedRecords = [...this.records];
            return;
        }

        this.groupedRecords = [];

        for (const record of this.records) {
            const recordMatchingCity = this.groupedRecords.find((groupedRecord) => groupedRecord.city === record.city);

            // City record already exists -> Add numbers
            if (recordMatchingCity) {
                recordMatchingCity.totalRevenueNet += record.totalRevenueNet;
                recordMatchingCity.totalRevenueGross += record.totalRevenueGross;
                recordMatchingCity.idsOfAllInvoices.push(...record.idsOfAllInvoices);
                recordMatchingCity.idsOfRegularInvoices.push(...record.idsOfRegularInvoices);
            }
            // Create city record
            else {
                this.groupedRecords.push({
                    city: record.city,
                    zip: 'Verschiedene',
                    totalRevenueNet: record.totalRevenueNet,
                    totalRevenueGross: record.totalRevenueGross,
                    idsOfAllInvoices: record.idsOfAllInvoices,
                    idsOfRegularInvoices: record.idsOfRegularInvoices,
                    idsOfCreditNotes: record.idsOfCreditNotes,
                    idsOfOutstandingInvoices: record.idsOfOutstandingInvoices,
                });
            }
        }

        // Sort grouped records
        this.groupedRecords.sort((recordA, recordB) => (recordA.totalRevenueNet > recordB.totalRevenueNet ? -1 : 1));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Group by ZIP or City
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/

    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.records.length) return;

        // If we have to summarize the remaining revenues, add the "others" revenue to the chart series
        const { firstFewRevenues, otherRevenue } = this.getCityRevenuesForChart();
        if (otherRevenue) {
            firstFewRevenues.push(otherRevenue);
        }

        this.chartOptions = {
            chart: {
                type: 'bar',
            },
            plotOptions: {},
            series: [
                {
                    name: 'Umsatz nach Ort',
                    data: firstFewRevenues.map((record) => record.totalRevenueNet),
                },
            ],
            xaxis: {
                // Categories go into the Y axis when the bar chart is horizontal but...
                categories: firstFewRevenues.map((record) =>
                    (this.groupRecordsByCity
                        ? record.city || 'Nicht angegeben'
                        : `${record.city || 'Nicht angegeben'} ${record.zip || ''}`
                    ).toUpperCase(),
                ),
                labels: {
                    style: {
                        fontFamily: "'Source Sans Pro', sans-serif",
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: (value) => currencyFormatterEuro(+value),
                },
            },
            tooltip: {
                y: {
                    formatter: (revenueByIntermediary) => currencyFormatterEuro(revenueByIntermediary),
                },
            },
        };

        // Clear before re-drawing
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
        this.chart.render();
    }

    /**
     * If there are more than 5 intermediaries, sum up the remainder.
     */
    private getCityRevenuesForChart(): {
        firstFewRevenues: RevenueByCityAnalyticsRecord[];
        otherRevenue: RevenueByCityAnalyticsRecord;
    } {
        const firstFewRevenues: RevenueByCityAnalyticsRecord[] = this.groupedRecords.slice(
            0,
            this.maximumGraphCategories,
        );
        let otherRevenue: RevenueByCityAnalyticsRecord = null;

        if (this.groupedRecords.length > this.maximumGraphCategories) {
            otherRevenue = {
                city: 'Sonstige',
                zip: '',
                totalRevenueNet: this.records
                    .slice(this.maximumGraphCategories)
                    .reduce((total, record) => total + record.totalRevenueNet, 0),
                totalRevenueGross: null,
                idsOfAllInvoices: [],
                idsOfRegularInvoices: [],
                idsOfCreditNotes: [],
                idsOfOutstandingInvoices: [],
            };
        }

        return {
            firstFewRevenues,
            otherRevenue,
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public refreshLocalData(): void {
        this.groupRecords();
        this.calculateTotalRevenue();
        this.drawChart();
    }

    public calculateTotalRevenue(): void {
        this.totalRevenue = this.records.reduce((total, currentItem) => total + currentItem.totalRevenueNet, 0);
    }

    public calculateTotalNumberOfInvoices(): void {
        this.totalNumberOfInvoices = this.records.reduce(
            (total, currentItem) => total + currentItem.idsOfRegularInvoices.length,
            0,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Table
    //****************************************************************************/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Table
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    public updateAnalytics(): void {
        this.analyticsPending = true;

        this.revenueAnalyticsService
            .getRevenueByCity({
                from: this.filterAnalyticsFrom,
                to: this.filterAnalyticsTo,
                officeLocationIds: this.filter.officeLocationIds,
                assessorIds: this.filter.assessorIds,
                reportLabelIds: this.filter.reportLabelConfigIds,
                invoiceLabelIds: this.filter.invoiceLabelConfigIds,
            })
            .subscribe({
                next: (records) => {
                    this.records = records;

                    this.analyticsPending = false;
                    this.groupRecords();
                    this.calculateTotalRevenue();
                    this.calculateTotalNumberOfInvoices();
                    setTimeout(() => {
                        this.drawChart();
                    }, 0);
                },
                error: (error) => {
                    this.analyticsPending = false;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ACCESS_DENIED: {
                                title: 'Zugriffsrecht fehlt',
                                body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                                forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                            },
                        },
                        defaultHandler: {
                            title: 'Fehler bei Auswertung',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/
}

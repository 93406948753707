import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocxWatermarkType } from '@autoixpert/models/documents/document-metadata';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from './logged-in-user.service';

/**
 * Service to CRUD DOCX watermark templates for rendering DOCX/PDF files.
 */
@Injectable()
export class DocxWatermarkTemplateService {
    constructor(
        private httpClient: HttpClient,
        private loggedInUserService: LoggedInUserService,
    ) {
        this.loggedInUserService.getUser$().subscribe({
            next: (user) => {
                this.user = user;
                if (this.user) {
                    this.apiPath = `/api/v0/teams/${this.user.teamId}/docxWatermarkTemplates`;
                }
            },
        });
    }

    private apiPath: string;
    private user: User;

    public get(watermarkType: DocxWatermarkType): Promise<HttpResponse<Blob>> {
        return this.httpClient
            .get(`${this.apiPath}/${watermarkType}`, {
                responseType: 'blob',
                observe: 'response',
            })
            .toPromise();
    }

    public remove(watermarkType: DocxWatermarkType): Promise<{ _id: string }> {
        return this.httpClient.delete<{ _id: string }>(`${this.apiPath}/${watermarkType}`).toPromise();
    }

    public create(watermarkType: DocxWatermarkType, documentFile: Blob): Promise<{ _id: string }> {
        const formData = new FormData();
        formData.append('_id', watermarkType);
        formData.append('document', documentFile);

        return this.httpClient.post<{ _id: string }>(`${this.apiPath}`, formData).toPromise();
    }

    public resetDefault(watermarkType: DocxWatermarkType): Promise<{ _id: string }> {
        return this.httpClient.delete<{ _id: string }>(`${this.apiPath}/${watermarkType}`).toPromise();
    }
}

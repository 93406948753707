import { animate, style, transition, trigger } from '@angular/animations';

export function slideInHorizontally(leaveDuration = 200) {
    return trigger('slideInHorizontally', [
        transition(':enter', [
            style({
                opacity: 0,
                transform: 'translateX(-50px)',
            }),
            animate(
                `${leaveDuration}ms ease`,
                style({
                    opacity: 1,
                    transform: 'translateX(0)',
                }),
            ),
        ]),
    ]);
}

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Observable, Subject, Subscription, merge } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { getContactPersonFullNameWithOrganization } from '@autoixpert/lib/contact-people/get-contact-person-full-name-with-organization';
import { addClaimantAndLicensePlateToDocumentTitle } from '@autoixpert/lib/document-title/add-claimant-and-license-plate-to-document-title';
import { getDocumentDownloadName } from '@autoixpert/lib/documents/get-document-download-name';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { Translator } from '@autoixpert/lib/placeholder-values/translator';
import {
    removeMissingPlaceholdersFromHTML,
    removeMissingPlaceholdersFromText,
    replaceMissingPlaceholders,
} from '@autoixpert/lib/template-engine/replace-missing-placeholders';
import { getEmailFromOptions } from '@autoixpert/lib/users/get-email-from-options';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { FileNamePattern } from '@autoixpert/models/file-name-patterns/file-name-pattern';
import { Invoice, InvoiceInvolvedParty } from '@autoixpert/models/invoices/invoice';
import { OutgoingEmailMessage, OutgoingMessageSchedule } from '@autoixpert/models/outgoing-message';
import { Recipient } from '@autoixpert/models/recipient';
import { EmailRecipient } from '@autoixpert/models/reports/document-email';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { TextTemplate } from '@autoixpert/models/text-templates/text-template';
import { EmailSignature } from '@autoixpert/models/user/preferences/email-signature';
import { DefaultEmailRecipients } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { runChildAnimations } from '../../animations/run-child-animations.animation';
import { slideInAndOutVertically } from '../../animations/slide-in-and-out-vertical.animation';
import { successMessageOverlayAnimation } from '../../animations/success-message-overlay.animation';
import { isValidEmailAddress } from '../../libraries/contacts/is-valid-email-address';
import { convertHtmlToPlainText } from '../../libraries/strip-html';
import { extractMissingPlaceholdersFromText } from '../../libraries/template-engine/extract-missing-placeholders-from-text';
import { getInvoiceRecipientByRole } from '../../libraries/template-engine/get-invoice-recipient-by-role';
import { ApiErrorService } from '../../services/api-error.service';
import { ContactPersonService } from '../../services/contact-person.service';
import { EmailSignatureService } from '../../services/emailSignature.service';
import { FieldGroupConfigService } from '../../services/field-group-config.service';
import { FileNamePatternService } from '../../services/file-name-pattern.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { NetworkStatusService } from '../../services/network-status.service';
import { TemplatePlaceholderValuesService } from '../../services/template-placeholder-values.service';
import { ToastService } from '../../services/toast.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserService } from '../../services/user.service';
import {
    ScheduleEmailDialogComponent,
    ScheduleEmailResult,
} from '../schedule-email-dialog/schedule-email-dialog.component';

@Component({
    selector: 'email-editor',
    templateUrl: 'email-editor.component.html',
    styleUrls: ['email-editor.component.scss'],
    animations: [slideInAndOutVertically(), runChildAnimations(), successMessageOverlayAnimation()],
})
export class EmailEditorComponent implements OnChanges, OnInit {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        public userPreferences: UserPreferencesService,
        private contactPersonService: ContactPersonService,
        private userService: UserService,
        private emailSignatureService: EmailSignatureService,
        private networkStatusService: NetworkStatusService,
        private dialog: MatDialog,
        private fieldGroupConfigService: FieldGroupConfigService,
        private fileNamePatternService: FileNamePatternService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() recipient: Recipient;
    @Input() email: OutgoingEmailMessage; // Separate from the recipient so that the parent component can decide where the email object is coming from
    @Input() report: Report;
    @Input() invoice: Invoice;
    @Input() activeDocuments: DocumentMetadata[] = [];
    @Input() selectedDocumentGroup: 'report' | 'invoice' | 'repairConfirmation';
    @Input() templateType: 'email' | 'invoiceEmail' | 'remoteSignatureEmail' = 'email';
    @Input() emailTransmissionPending: boolean = false;
    @Input() emailJustSentInfo: false | { label: string; icon: string } = false;

    /** Whether CC and BCC need to be activated even when recipient mode is already on (Gmail style) */
    @Input() ccAndBccOptIn: boolean = false;
    @Input() showHeadline: boolean = true;
    @Input() showCardFrame: boolean = true;
    @Input() showAttachments: boolean = true;

    @Output() recipientChange: EventEmitter<Recipient> = new EventEmitter<Recipient>();
    @Output() emailChange: EventEmitter<OutgoingEmailMessage> = new EventEmitter<OutgoingEmailMessage>();
    @Output() documentChange: EventEmitter<DocumentMetadata> = new EventEmitter<DocumentMetadata>();
    @Output() deactivateDocument: EventEmitter<DocumentMetadata> = new EventEmitter<DocumentMetadata>();
    @Output() emailSend = new EventEmitter<{ email: OutgoingEmailMessage; schedule?: OutgoingMessageSchedule }>();

    public user: User;
    public team: Team;

    public toRecipientsSearchTerm: string = '';
    public ccRecipientsSearchTerm: string = '';
    public bccRecipientsSearchTerm: string = '';
    public availableRecipients: ContactPerson[] = [];
    public filteredToRecipients: ContactPerson[] = [];
    public filteredCcRecipients: ContactPerson[] = [];
    public filteredBccRecipients: ContactPerson[] = [];
    public toRecipientInputShown: boolean = false;
    public ccRecipientInputShown: boolean = false;
    public bccRecipientInputShown: boolean = false;
    public emailSeparatorKeyCodes: number[] = [ENTER, COMMA];

    // Server Search
    private searchTerm$: Subject<{ searchTerm: string; recipientGroup: RecipientGroup }> = new Subject();
    searchServer$: Observable<{ recipientGroup: RecipientGroup; contactPeople: ContactPerson[] }> =
        this.searchTerm$.pipe(
            // Block short search terms.
            filter((searchQuery) => searchQuery.searchTerm.length > 2),
            // Reduce server load by throttling.
            debounceTime(300),
            switchMap((searchQuery) =>
                this.contactPersonService
                    .find({ $search: searchQuery.searchTerm, $limit: 50 })
                    // Attach the recipient group to allow sorting the response into the right autocomplete.
                    .pipe(map((contactPeople) => ({ recipientGroup: searchQuery.recipientGroup, contactPeople }))),
            ),
        );

    public emailAddressPlaceholders: (EmailRecipientPlaceholder | string)[] = [];

    public emailAddressDelimiter: RegExp = /[;,]/g;

    // Missing placeholders
    public missingPlaceholders: string[];

    // Message Templates
    public messageTemplateSelectorShown: boolean = false;

    // Placeholders & Placeholder Value Tree
    private placeholderValues: PlaceholderValues;
    private placeholderValueTree: PlaceholderValueTree;
    private fieldGroupConfigs: FieldGroupConfig[] = [];
    private fileNamePatterns: FileNamePattern[] = [];
    // Determines which auxiliary placeholders are rendered on top of the report or invoice.
    public activeCoverLetter: DocumentMetadata;

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        this.subscribeToRecipientSearch();

        // Load everything that's necessary for document title generation.
        this.loadResourcesForDocumentFileNameGeneration();
        this.subscribeToChangesOfResourcesForFileNameGeneration();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnChanges(changes: SimpleChanges) {
        if (changes['invoiceRecipient'] || changes['email']) {
            // Only fill in if none of the recipients have been filled to prevent re-filling after the user removed recipients
            if (
                !(
                    this.email.email.toRecipients.length ||
                    this.email.email.ccRecipients.length ||
                    this.email.email.bccRecipients.length
                )
            ) {
                this.fillInDefaultRecipientsFromPreferences('to');
                this.fillInDefaultRecipientsFromPreferences('cc');
                this.fillInDefaultRecipientsFromPreferences('bcc');
            }

            // Which placeholders are available for a report, which ones for an invoice?
            this.determineAvailableEmailAddressPlaceholders();

            /**
             * If a recipient's email contains multiple addresses, split them and insert them separately.
             */
            this.splitInvolvedPartyRecipientEmailAddresses();

            this.showFilledRecipientInputs();
            this.replaceMissingPlaceholders();
        }

        if (changes['report']) {
            // Which placeholders are available for a report, which ones for an invoice?
            this.determineAvailableEmailAddressPlaceholders();
        }

        if (changes['activeDocuments']) {
            this.determineActiveCoverLetter();
        }
    }

    //*****************************************************************************
    //  Recipient Inputs
    //****************************************************************************/
    public enterRecipientEditMode(): void {
        // If the recipient matches one of the possible defaults, add it to the toRecipients group
        let defaultRecipientPlaceholder: EmailRecipientPlaceholder;
        switch (this.recipient.role) {
            case 'claimant':
                defaultRecipientPlaceholder = 'Anspruchsteller';
                break;
            case 'garage':
                defaultRecipientPlaceholder = 'Werkstatt';
                break;
            case 'lawyer':
                defaultRecipientPlaceholder = 'Anwalt';
                break;
            case 'insurance':
                defaultRecipientPlaceholder = 'Versicherung';
                break;
            case 'invoiceRecipient':
                defaultRecipientPlaceholder = 'Rechnungsempfänger';
                break;
        }
        if (defaultRecipientPlaceholder) {
            // If the user entered multiple email addresses, insert multiple records. They are fixed then; a later change to the email address in a previous tab won't be reflected.
            const emailAddress: string = this.getEmailAddressByRecipientPlaceholder(defaultRecipientPlaceholder);
            if (emailAddress?.match(this.emailAddressDelimiter)) {
                this.splitEmailAddresses(emailAddress)
                    // Add recipient
                    .forEach((emailAddress) =>
                        this.email.email.toRecipients.push(
                            new EmailRecipient({
                                email: emailAddress,
                            }),
                        ),
                    );
            }
            // Single email address
            else {
                this.email.email.toRecipients.push(
                    new EmailRecipient({
                        name: defaultRecipientPlaceholder,
                    }),
                );
            }
            // Don't emit an email change event to allow the user to go back to the default view by switching aX process tabs.
        }

        this.toRecipientInputShown = true;
        this.ccRecipientInputShown = !this.ccAndBccOptIn;
        this.bccRecipientInputShown = !this.ccAndBccOptIn;
    }

    public showFilledRecipientInputs(): void {
        this.toRecipientInputShown = !!this.email.email.toRecipients.length;
        // If the to-recipient input is shown, we must also show the other two. Otherwise, the other two could never be shown because the handle is invisible
        this.ccRecipientInputShown = !!this.email.email.ccRecipients.length || this.toRecipientInputShown;
        this.bccRecipientInputShown = !!this.email.email.bccRecipients.length || this.toRecipientInputShown;
    }

    /**
     * Loop over all recipient groups and split email addresses if there are semicolons.
     */
    public splitInvolvedPartyRecipientEmailAddresses(): void {
        const recipientGroups: EmailRecipient[][] = [
            this.email.email.toRecipients,
            this.email.email.ccRecipients,
            this.email.email.bccRecipients,
        ];

        for (const recipientGroup of recipientGroups) {
            for (const recipient of recipientGroup) {
                /**
                 * The "recipient.email" property is empty with placeholders such as "Werkstatt". Get their email address dynamically.
                 */
                // TODO Remove this un-logic of retrieving the email address on every use of a named placeholder. Instead, write the email address to the object and refresh it on ngOnInit.
                const recipientEmail = recipient.email
                    ? recipient.email
                    : this.getEmailAddressByRecipientPlaceholder(recipient.name);

                /**
                 * The recipient email may be null if the User object has not yet been loaded and the placeholder of "Ich" is being resolved.
                 */
                if (recipientEmail?.match(this.emailAddressDelimiter)) {
                    this.splitEmailAddresses(recipientEmail).forEach((emailAddress) => {
                        recipientGroup.push(
                            new EmailRecipient({
                                email: emailAddress,
                            }),
                        );
                    });

                    /**
                     * Remove the recipient that was split.
                     */
                    const index = recipientGroup.indexOf(recipient);
                    if (index > -1) {
                        recipientGroup.splice(index, 1);
                    }
                }
            }
        }
    }

    private splitEmailAddresses(email: string): string[] {
        if (!email) {
            return [];
        }

        return (
            email
                ?.split(this.emailAddressDelimiter)
                // Trim spaces
                .map((emailAddress) => emailAddress.trim())
        );
    }

    private subscribeToRecipientSearch() {
        const subscription = this.searchServer$.subscribe((result) => {
            this.availableRecipients = result.contactPeople;

            let currentSearchTerm: string;
            switch (result.recipientGroup) {
                case 'to':
                    currentSearchTerm = this.toRecipientsSearchTerm;
                    break;
                case 'cc':
                    currentSearchTerm = this.ccRecipientsSearchTerm;
                    break;
                case 'bcc':
                    currentSearchTerm = this.bccRecipientsSearchTerm;
                    break;
            }

            this.filterAutocompleteForRecipientInput(currentSearchTerm, result.recipientGroup);
        });
        this.subscriptions.push(subscription);
    }

    //*****************************************************************************
    //  Recipient Chips
    //****************************************************************************/
    /**
     * Handle when to enter the MatChip representing a recipient in the to, cc and bcc inputs.
     * @param event
     * @param group
     */
    public enterRecipientChip(event: MatChipInputEvent, group: RecipientGroup): void {
        if (!event.value) return;

        // Placeholder
        if (this.isValidPlaceholder(event.value)) {
            this.addPlaceholderToRecipientList(event.value, group);
            event.chipInput.inputElement.value = '';
            this.emitEmailChange();
        }
        // E-Mail address
        else if (isValidEmailAddress(event.value)) {
            this.addEmailAddressToRecipientList(event.value, group);
            event.chipInput.inputElement.value = '';
            this.emitEmailChange();
        }
        // Invalid input
        /**
         * If the input value does not even contain an @ character, we can assume the user was searching for the matching autocomplete entry.
         */
        else if (event.value?.includes('@') && !isValidEmailAddress(event.value)) {
            this.toastService.warn('E-Mailadresse korrekt?', 'Die Adresse scheint das falsche Format zu haben.');
            return;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Recipient Chips
    /////////////////////////////////////////////////////////////////////////////*/

    private addEmailAddressToRecipientList(emailAddress: string, group: RecipientGroup) {
        // No empty values
        if (!emailAddress) return;

        const recipientGroup = this.getRecipientGroup(group);

        // Prevent duplicates
        if (!this.isEmailAddressDuplicate(recipientGroup, emailAddress)) {
            recipientGroup.push(
                new EmailRecipient({
                    email: emailAddress,
                }),
            );
            this.emitEmailChange();
        } else {
            this.toastService.info('Empfänger bereits vorhanden');
        }
    }

    public addPlaceholderToRecipientList(placeholder: string, group: RecipientGroup) {
        // No empty values
        if (!placeholder) return;

        // Make sure the email is in the right format
        if (!this.isValidPlaceholder(placeholder)) {
            this.toastService.warn(
                'Unbekannter Platzhalter',
                'Den Platzhalter gibt es leider nicht. Versuche einen anderen.',
            );
            return;
        }

        const recipientGroup = this.getRecipientGroup(group);

        // Prevent duplicates
        if (!this.isPlaceholderDuplicate(recipientGroup, placeholder)) {
            recipientGroup.push(
                new EmailRecipient({
                    name: placeholder,
                }),
            );
            this.emitEmailChange();
        } else {
            this.toastService.info('Platzhalter bereits vorhanden');
        }
    }

    private isEmailAddressDuplicate(recipientGroup: EmailRecipient[], email: string): boolean {
        return !!recipientGroup.find((existingRecipient) => existingRecipient.email === email);
    }

    private isPlaceholderDuplicate(recipientGroup: EmailRecipient[], placeholder: string): boolean {
        return !!recipientGroup.find((existingRecipient) => existingRecipient.name === placeholder);
    }

    private isValidPlaceholder(placeholder: string): boolean {
        return this.emailAddressPlaceholders.includes(placeholder);
    }

    public getEmailAddressByRecipientPlaceholder(placeholder: EmailRecipientPlaceholder | string): string {
        switch (placeholder) {
            case 'Anspruchsteller':
                if (this.report) {
                    return this.report.claimant?.contactPerson.email;
                }
                break;
            case 'Werkstatt':
                if (this.report) {
                    return this.report.garage?.contactPerson.email;
                }
                break;
            case 'Anwalt':
                if (this.report) {
                    return this.report.lawyer?.contactPerson.email;
                }
                break;
            case 'Versicherung':
                if (this.report) {
                    return this.report.insurance?.contactPerson.email;
                }
                break;
            case 'Sachverständiger':
                if (this.report && this.report.visits[0]) {
                    const responsibleAssessor: User = this.userService.getTeamMemberFromCache(
                        this.report.visits[0].assessor,
                    );
                    return responsibleAssessor ? responsibleAssessor.email : null;
                }
                break;
            case 'Ich':
                if (this.user) {
                    return this.user.email;
                }
                break;
            case 'Rechnungsempfänger':
                if (this.recipient?.role === 'invoiceRecipient') {
                    return this.recipient.contactPerson.email;
                }
                break;
            default:
                return null;
        }
        return null;
    }

    public getClaimantDenomination() {
        return Translator.claimantDenomination(this.report?.type);
    }

    public getNameByRecipientPlaceholder(placeholder: EmailRecipientPlaceholder): string {
        switch (placeholder) {
            case 'Anspruchsteller':
                if (this.report && this.report.claimant) {
                    return this.report.claimant
                        ? getContactPersonFullNameWithOrganization(this.report.claimant.contactPerson, ' - ')
                        : null;
                }
                break;
            case 'Werkstatt':
                if (this.report && this.report.garage) {
                    return this.report.garage
                        ? getContactPersonFullNameWithOrganization(this.report.garage.contactPerson, ' - ')
                        : null;
                }
                break;
            case 'Anwalt':
                if (this.report && this.report.lawyer) {
                    return this.report.lawyer
                        ? getContactPersonFullNameWithOrganization(this.report.lawyer.contactPerson, ' - ')
                        : null;
                }
                break;
            case 'Versicherung':
                if (this.report && this.report.insurance) {
                    return this.report.insurance
                        ? getContactPersonFullNameWithOrganization(this.report.insurance.contactPerson, ' - ')
                        : null;
                }
                break;
            case 'Sachverständiger':
                if (this.report && this.report.visits[0]) {
                    const responsibleAssessor: User = this.userService.getTeamMemberFromCache(
                        this.report.visits[0]?.assessor,
                    );
                    return responsibleAssessor
                        ? getContactPersonFullNameWithOrganization(
                              {
                                  organization: responsibleAssessor.organization,
                                  lastName: responsibleAssessor.lastName,
                                  firstName: responsibleAssessor.firstName,
                              },
                              ' - ',
                          )
                        : null;
                }
                break;
            case 'Ich':
                if (this.user) {
                    return getContactPersonFullNameWithOrganization(
                        {
                            organization: this.user.organization,
                            lastName: this.user.lastName,
                            firstName: this.user.firstName,
                        },
                        ' - ',
                    );
                }
                break;
            case 'Rechnungsempfänger':
                if (this.recipient.role === 'invoiceRecipient') {
                    return this.recipient
                        ? getContactPersonFullNameWithOrganization(this.recipient.contactPerson, ' - ')
                        : null;
                }
                break;
            default:
                return null;
        }
        return null;
    }

    private fillInNamesAndEmailAddressesForPlaceholders(recipients: EmailRecipient[]): EmailRecipient[] {
        // Multiple addresses are possible for each recipient group (to, cc, bcc)
        return recipients.map((recipient) => {
            if (this.isValidPlaceholder(recipient.name)) {
                return {
                    name: this.getNameByRecipientPlaceholder(recipient.name as EmailRecipientPlaceholder),
                    // Must be a valid placeholder because of the if check above.
                    email: this.getEmailAddressByRecipientPlaceholder(recipient.name as EmailRecipientPlaceholder),
                };
            } else {
                return recipient;
            }
        });
    }

    private getRecipientsWithEmptyEmailAddresses(): EmailRecipient[] {
        const recipients = [
            ...this.email.email.toRecipients,
            ...this.email.email.ccRecipients,
            ...this.email.email.bccRecipients,
        ];

        if (!this.toRecipientInputShown) {
            const defaultRecipient = {
                email: this.recipient.contactPerson.email,
                name:
                    getContactPersonFullNameWithOrganization(this.recipient.contactPerson) ||
                    'Kein Empfänger angegeben',
            };
            recipients.push(defaultRecipient);
        }

        return this.fillInNamesAndEmailAddressesForPlaceholders(recipients).filter((recipient) => !recipient.email);
    }

    /**
     * Get invalid or empty e-mail addresses
     */
    private getRecipientsWithInvalidEmailAddresses(): EmailRecipient[] {
        const recipients = [
            ...this.email.email.toRecipients,
            ...this.email.email.ccRecipients,
            ...this.email.email.bccRecipients,
        ];

        if (!this.toRecipientInputShown) {
            const defaultRecipient = {
                email: this.recipient.contactPerson.email,
                name:
                    getContactPersonFullNameWithOrganization(this.recipient.contactPerson) ||
                    'Kein Empfänger angegeben',
            };
            recipients.push(defaultRecipient);
        }

        return (
            this.fillInNamesAndEmailAddressesForPlaceholders(recipients)
                // Invalid addresses
                .filter((recipient) => !isValidEmailAddress(recipient.email))
        );
    }

    //*****************************************************************************
    //  Recipient Autocomplete
    //****************************************************************************/
    public selectRecipientFromAutocomplete(
        event: MatAutocompleteSelectedEvent,
        inputElement: HTMLInputElement,
        inputText: string,
        group: RecipientGroup,
    ): void {
        this.addEmailAddressToRecipientList(event.option.value, group);

        // After a chip has been added, we must clear the input.
        inputElement.value = '';
    }

    private determineAvailableEmailAddressPlaceholders(): void {
        this.emailAddressPlaceholders = ['Ich'];

        // Add placeholders only available if a report is present
        if (this.report) {
            this.emailAddressPlaceholders.push(
                'Sachverständiger',
                'Anspruchsteller',
                'Werkstatt',
                'Anwalt',
                'Versicherung',
            );
        }

        if (this.invoice) {
            this.emailAddressPlaceholders.push('Rechnungsempfänger');
        }
    }

    public resetRecipientAutocomplete() {
        this.filteredToRecipients = [];
        this.filteredCcRecipients = [];
        this.filteredBccRecipients = [];
    }

    public filterAutocompleteForRecipientInput(searchTerm: string, recipientGroup: 'to' | 'cc' | 'bcc'): void {
        let filteredContactPeople: ContactPerson[] = [];

        if (searchTerm) {
            const searchTermLowerCase = searchTerm.toLowerCase();

            // A contact shall be matched if all search terms (delimited by a space) match in at least one property.
            const searchTerms = searchTermLowerCase.split(' ');

            filteredContactPeople = this.availableRecipients.filter((contact) => {
                return (
                    !!contact.email &&
                    searchTerms.every((term) =>
                        [contact.email, contact.organization, contact.firstName, contact.lastName].some(
                            (contactProperty) => contactProperty?.toLowerCase().includes(term),
                        ),
                    )
                );
            });
        }

        // Write into the right array.
        switch (recipientGroup) {
            case 'to':
                this.filteredToRecipients = filteredContactPeople;
                break;
            case 'cc':
                this.filteredCcRecipients = filteredContactPeople;
                break;
            case 'bcc':
                this.filteredBccRecipients = filteredContactPeople;
                break;
        }
    }

    public searchContactPersonOnServer(searchTerm: string, searchGroup: RecipientGroup) {
        this.searchTerm$.next({ searchTerm, recipientGroup: searchGroup });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Recipient Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Recipient Utilities
    //****************************************************************************/
    public removeRecipient(recipient: EmailRecipient, group: RecipientGroup): void {
        const recipientGroup = this.getRecipientGroup(group);
        const index = recipientGroup.indexOf(recipient);
        recipientGroup.splice(index, 1);
        this.emitEmailChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Recipient Utilities
    /////////////////////////////////////////////////////////////////////////////*/
    private getRecipientGroup(group: RecipientGroup): EmailRecipient[] {
        switch (group) {
            case 'to':
                return this.email.email.toRecipients;
            case 'cc':
                return this.email.email.ccRecipients;
            case 'bcc':
                return this.email.email.bccRecipients;
        }
    }

    //*****************************************************************************
    //  Default Recipients
    //****************************************************************************/
    /**
     * Remember the given recipients for the given recipient group. If no recipients are provided,
     * this function takes the recipients currently typed into the recipient input field for the given recipient group.
     */
    public rememberRecipients(group: RecipientGroup, recipients: EmailRecipient[] = null): void {
        let recipientsGroup = recipients;
        if (recipients === null) {
            recipientsGroup = JSON.parse(JSON.stringify(this.getRecipientGroup(group))) ?? [];
        }

        // Get recipients from preferences
        let defaultRecipients: DefaultEmailRecipients;
        switch (this.recipient.role) {
            case 'claimant':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsClaimant;
                break;
            case 'garage':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsGarage;
                break;
            case 'lawyer':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsLawyer;
                break;
            case 'insurance':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsInsurance;
                break;
            case 'invoiceRecipient':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsInvoiceRecipient;
                break;
            default:
                throw Error('Cannot remember recipients for the selected role of ' + this.recipient.role);
        }
        if (!defaultRecipients) defaultRecipients = new DefaultEmailRecipients();

        // Update recipients
        switch (group) {
            case 'to':
                defaultRecipients.toRecipients = recipientsGroup;
                break;
            case 'cc':
                defaultRecipients.ccRecipients = recipientsGroup;
                break;
            case 'bcc':
                defaultRecipients.bccRecipients = recipientsGroup;
                break;
        }

        // Save
        switch (this.recipient.role) {
            case 'claimant':
                this.userPreferences.defaultEmailRecipientsClaimant = defaultRecipients;
                break;
            case 'garage':
                this.userPreferences.defaultEmailRecipientsGarage = defaultRecipients;
                break;
            case 'lawyer':
                this.userPreferences.defaultEmailRecipientsLawyer = defaultRecipients;
                break;
            case 'insurance':
                this.userPreferences.defaultEmailRecipientsInsurance = defaultRecipients;
                break;
            case 'invoiceRecipient':
                this.userPreferences.defaultEmailRecipientsInvoiceRecipient = defaultRecipients;
                break;
            default:
                throw Error('Cannot remember recipients for the selected role of ' + this.recipient.role);
        }

        const recipientGroupName = group === 'to' ? 'Empfänger' : group === 'cc' ? 'Cc-Empfänger' : 'Bcc-Empfänger';
        if (recipientsGroup?.length) {
            this.toastService.success(
                `${recipientGroupName} gemerkt`,
                `Das ${recipientGroupName}feld wird in Zukunft mit der aktuellen Auswahl vorbelegt.`,
            );
        } else {
            this.toastService.success(
                'Standardempfänger entfernt',
                `In Zukunft wird das Feld ${recipientGroupName} nicht mehr vorbelegt.`,
            );
        }
    }

    public isRememberingRecipientsAllowed(): boolean {
        const allowedRecipientRoles: Recipient['role'][] = [
            'claimant',
            'garage',
            'lawyer',
            'insurance',
            'invoiceRecipient',
        ];

        return allowedRecipientRoles.includes(this.recipient.role);
    }

    /**
     * Fill in the defaults for a group described by two attributes:
     * - recipient's role
     * - recipient group (to, cc, bcc)
     * @param group
     */
    public fillInDefaultRecipientsFromPreferences(group: RecipientGroup): void {
        if (!this.recipient) return;
        if (!this.email) return;

        // Get recipients from preferences
        let defaultRecipients: DefaultEmailRecipients;
        switch (this.recipient.role) {
            case 'claimant':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsClaimant;
                break;
            case 'garage':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsGarage;
                break;
            case 'lawyer':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsLawyer;
                break;
            case 'insurance':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsInsurance;
                break;
            case 'leaseProvider':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsLeaseProvider;
                break;
            case 'invoiceRecipient':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsInvoiceRecipient;
                break;
            default: {
                // No matching group? Abort.
                return;
            }
        }

        // if user preference not set use empty object
        defaultRecipients ??= new DefaultEmailRecipients();

        // Fill into e-mail, if the e-mail does not have any recipients in the respective group yet.
        switch (group) {
            case 'to':
                if (defaultRecipients.toRecipients.length) {
                    this.email.email.toRecipients = JSON.parse(JSON.stringify(defaultRecipients.toRecipients)) ?? [];
                    this.emitEmailChange();
                }
                break;
            case 'cc':
                if (defaultRecipients.ccRecipients.length) {
                    this.email.email.ccRecipients = JSON.parse(JSON.stringify(defaultRecipients.ccRecipients)) ?? [];
                    this.emitEmailChange();
                }
                break;
            case 'bcc':
                if (defaultRecipients.bccRecipients.length) {
                    this.email.email.bccRecipients = JSON.parse(JSON.stringify(defaultRecipients.bccRecipients)) ?? [];
                    this.emitEmailChange();
                }
                break;
        }
    }

    protected getDefaultRecipientsFromPreferences(group: RecipientGroup): EmailRecipient[] {
        if (!this.recipient) return;
        if (!this.email) return;

        // Get recipients from preferences
        let defaultRecipients: DefaultEmailRecipients;
        switch (this.recipient.role) {
            case 'claimant':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsClaimant;
                break;
            case 'garage':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsGarage;
                break;
            case 'lawyer':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsLawyer;
                break;
            case 'insurance':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsInsurance;
                break;
            case 'leaseProvider':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsLeaseProvider;
                break;
            case 'invoiceRecipient':
                defaultRecipients = this.userPreferences.defaultEmailRecipientsInvoiceRecipient;
                break;
            default: {
                // No matching group? Abort.
                return;
            }
        }

        switch (group) {
            case 'to':
                return defaultRecipients.toRecipients;
            case 'cc':
                return defaultRecipients.ccRecipients;
            case 'bcc':
                return defaultRecipients.bccRecipients;
        }
    }

    /**
     * Add oneself to the default recipients of all report involved parties.
     */
    public addMyselfToBcc() {
        this.addRecipientToDefaultBccRecipients(this.userPreferences.defaultEmailRecipientsClaimant, 'Ich');
        this.addRecipientToDefaultBccRecipients(this.userPreferences.defaultEmailRecipientsGarage, 'Ich');
        this.addRecipientToDefaultBccRecipients(this.userPreferences.defaultEmailRecipientsLawyer, 'Ich');
        this.addRecipientToDefaultBccRecipients(this.userPreferences.defaultEmailRecipientsInsurance, 'Ich');
        this.addRecipientToDefaultBccRecipients(this.userPreferences.defaultEmailRecipientsLeaseProvider, 'Ich');

        // Since we do not re-assign the array, the user preferences save routine is not triggered automatically.
        this.userPreferences.save();
    }

    /**
     * Add a *named* recipient (placeholder) to the default BCC recipients.
     */
    private addRecipientToDefaultBccRecipients(
        involvedPartyDefaultRecipients: DefaultEmailRecipients,
        recipientName: EmailRecipient['name'],
    ) {
        if (!involvedPartyDefaultRecipients.bccRecipients) {
            involvedPartyDefaultRecipients.bccRecipients = [];
        }

        const existingEmailRecipient = involvedPartyDefaultRecipients.bccRecipients.find(
            (recipient) => recipient.name === recipientName,
        );
        if (!existingEmailRecipient) {
            involvedPartyDefaultRecipients.bccRecipients = [
                ...involvedPartyDefaultRecipients.bccRecipients,
                new EmailRecipient({ name: recipientName }),
            ];
        }
    }

    public userHasNoBccDefaultRecipients(): boolean {
        return (
            !this.userPreferences.defaultEmailRecipientsClaimant.bccRecipients.length &&
            !this.userPreferences.defaultEmailRecipientsGarage.bccRecipients.length &&
            !this.userPreferences.defaultEmailRecipientsLawyer.bccRecipients.length &&
            !this.userPreferences.defaultEmailRecipientsInsurance.bccRecipients.length &&
            !this.userPreferences.defaultEmailRecipientsLeaseProvider.bccRecipients.length
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default Recipients
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Recipient Inputs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sender
    //****************************************************************************/
    public getEmailSenderPreview(user: User): string {
        const fromOptions = getEmailFromOptions(user).from;
        return `${fromOptions.name} <${fromOptions.address}>`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sender
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Message Templates
    //****************************************************************************/
    public showMessageTemplateSelector(): void {
        this.messageTemplateSelectorShown = true;
    }

    public hideMessageTemplateSelector(): void {
        this.messageTemplateSelectorShown = false;
    }

    public async insertTemplateText(textTemplate: TextTemplate): Promise<void> {
        this.email.subject = textTemplate.subject;
        this.email.body = textTemplate.body;
        this.replaceMissingPlaceholders();
        await this.insertEmailSignature();

        this.emitEmailChange();
    }

    private async insertEmailSignature(): Promise<void> {
        let emailSignatures: EmailSignature[] = this.emailSignatureService.getAllFromCurrentInMemoryCache();
        /**
         * If there are no email signatures in the memory cache, query the server.
         * The server is not queried immediately to increase performance. Email signatures are inserted very often
         * but changed rarely, so a good in-memory cache increases speed.
         * The server is queried at all to ensure the user can still insert email signatures even if the app has not fully synced
         * (and populated the in-memory cache) after the user refreshed the page.
         */
        if (!emailSignatures.length) {
            emailSignatures = await this.emailSignatureService.find({ createdBy: this.user._id }).toPromise();
        }
        // If there are no email signatures, don't try to insert one.
        if (!emailSignatures.length) {
            return;
        }

        const emailSignatureForThisUser = emailSignatures.find(
            (emailSignature) => emailSignature.createdBy === this.user._id,
        );
        if (emailSignatureForThisUser?.content) {
            if (!this.email.body) {
                /**
                 * Gmail adds two line breaks before the signature so that the user can easily click in this space to position his cursor. Since there
                 * is another line break right before the signature, add one here if the field is empty.
                 */
                this.email.body = '<p><br class="ql-line-break"></p>';
            }
            this.email.body += `<p><br class="ql-line-break"></p>${emailSignatureForThisUser.content}`;
        }
    }

    public async insertEmailSignatureAndEmitChange() {
        await this.insertEmailSignature();
        this.emitEmailChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Message Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Missing Placeholders
    //****************************************************************************/
    public determineMissingPlaceholders(): void {
        const subjectMatches = this.email.subject ? extractMissingPlaceholdersFromText(this.email.subject) : [];
        const bodyMatches = this.email.body ? extractMissingPlaceholdersFromText(this.email.body) : [];

        this.missingPlaceholders = [
            // If a placeholder is missing multiple times, only display once.
            ...new Set([...subjectMatches, ...bodyMatches]),
        ];
    }

    /**
     * Replaces all missing placeholders from the current email.
     */
    public async replaceMissingPlaceholders() {
        // Create the placeholder values only if there are missing placeholders
        this.determineMissingPlaceholders();
        if (!this.missingPlaceholders.length) return;

        const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.report?._id,
        });
        const fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        const placeholderValueTree = getPlaceholderValueTree({ fieldGroupConfigs });

        this.email.subject = replaceMissingPlaceholders({
            placeholderValues,
            placeholderValueTree,
            text: this.email.subject,
            isHtmlAllowed: false,
        });
        this.email.body = replaceMissingPlaceholders({
            placeholderValues,
            placeholderValueTree,
            text: this.email.body,
            isHtmlAllowed: true,
        });

        // If placeholders are still missing, display the warning.
        // The user may remove the remaining placeholders or add more information to the report and replace missing placeholders again.
        this.determineMissingPlaceholders();

        this.emitEmailChange();
    }

    /**
     * Removes all missing placeholders from the current email.
     */
    public removeMissingPlaceholders(): void {
        this.email.subject = removeMissingPlaceholdersFromText(this.email.subject);
        this.email.body = removeMissingPlaceholdersFromHTML(this.email.body);

        // Since we removed all missing placeholders, we don't need the warning anymore.
        this.missingPlaceholders = [];

        this.emitEmailChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Missing Placeholders
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sending Email
    //****************************************************************************/
    public setEmailReceived(recipient: Recipient, value: boolean): void {
        recipient.receivedEmail = value;
    }

    /**
     * Notify the parent component to send the email.
     *
     * The event emitter transmits the new properly formatted email intended to be saved by the parent.
     * @param event - The mouse or event object that triggered the email send
     * @param schedule - Optional schedule object for scheduled emails
     */
    public async triggerEmailSendProcess(event: MouseEvent | Event, schedule?: OutgoingMessageSchedule): Promise<void> {
        // If no scheduled time is provided, set the default from user settings
        if (!schedule && this.userPreferences.emailSendingDelayEnabled) {
            const delay = this.userPreferences.emailSendingDelayAmount;
            const unit = this.userPreferences.emailSendingDelayUnit;
            if (delay && unit) {
                switch (unit) {
                    case 'seconds': {
                        schedule = {
                            type: 'offset',
                            config: { secondsOffset: delay },
                        };
                        break;
                    }

                    case 'minutes': {
                        schedule = {
                            type: 'offset',
                            config: { minutesOffset: delay },
                        };
                        break;
                    }

                    case 'hours': {
                        schedule = {
                            type: 'offset',
                            config: { hoursOffset: delay },
                        };
                        break;
                    }
                }
            }
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'E-Mails können gesendet werden, sobald du wieder online bist.',
            );
            return;
        }

        // Don't send an empty email
        if (!this.isSendingEmailAllowed()) {
            const recipientsWithoutEmail: EmailRecipient[] = this.getRecipientsWithEmptyEmailAddresses();
            if (recipientsWithoutEmail.length) {
                const recipientNameList: string = recipientsWithoutEmail.map((recipient) => recipient.name).join(',\n');
                this.toastService.info(
                    'E-Mail-Adressen unbekannt',
                    `Diese Empfänger haben noch keine E-Mail-Adresse: ${recipientNameList}.`,
                );
            }

            const recipientsWithInvalidEmail: EmailRecipient[] = this.getRecipientsWithInvalidEmailAddresses();
            if (recipientsWithInvalidEmail.length) {
                const recipientNameAndEmailList: string = recipientsWithInvalidEmail
                    .map((recipient) => `${recipient.name} <${recipient.email}>`)
                    .join(',\n');
                this.toastService.info(
                    'E-Mail-Adressen ungültig',
                    `Bitte prüfe die E-Mail-Adressen dieser Empfänger:\n${recipientNameAndEmailList}.`,
                );
            }
            return;
        }

        //*****************************************************************************
        //  Plausibility of Invoice Data
        //****************************************************************************/
        // If this is a standalone invoice, check the invoice number directly.
        if (this.invoice) {
            if (!this.invoice.number) {
                const decision = await this.confirmSendingWithoutInvoiceNumber();
                if (!decision) {
                    return;
                }
            }

            if (this.isInvoiceRecipientEmpty(this.invoice.recipient)) {
                const decision = await this.confirmSendingWithoutInvoiceRecipient();
                if (!decision) {
                    return;
                }
            }
        }
        // If a report is connected, check plausibility of invoice data.
        else if (this.report && this.activeDocuments.some((doc) => doc.type === 'invoice')) {
            switch (this.selectedDocumentGroup) {
                case 'report': {
                    if (!this.report.feeCalculation.invoiceParameters.number) {
                        const decision = await this.confirmSendingWithoutInvoiceNumber();
                        if (!decision) {
                            return;
                        }
                    }

                    if (!this.report.feeCalculation.assessorsFee) {
                        const decision = await this.dialog
                            .open(ConfirmDialogComponent, {
                                data: {
                                    heading: 'Honorar fehlt',
                                    content: `Möchtest du wirklich eine Rechnung ohne Honorar versenden?`,
                                    confirmLabel: 'Trotzdem versenden',
                                    cancelLabel: 'Ups, trage ich nach.',
                                },
                            })
                            .afterClosed()
                            .toPromise();
                        if (!decision) {
                            return;
                        }
                    }

                    // Get recipient from invoice component
                    const invoiceRecipient = getInvoiceRecipientByRole(
                        this.report.feeCalculation.invoiceParameters.recipientRole,
                        this.report,
                    );

                    // Prompt user, if he wants to send a invoice with an empty recipient
                    if (this.isContactPersonEmpty(invoiceRecipient)) {
                        const decision = await this.confirmSendingWithoutInvoiceRecipient();
                        if (!decision) {
                            return;
                        }
                    }

                    break;
                }
                case 'repairConfirmation':
                    // The repair confirmation invoice cannot exist without an invoice number.

                    // Probably sending without a total won't be what the user wants.
                    if (!this.report.repairConfirmation.invoiceParameters.number) {
                        const decision = await this.dialog
                            .open(ConfirmDialogComponent, {
                                data: {
                                    heading: 'Rechnungsnummer für Reparaturbestätigung fehlt',
                                    content: `Möchtest du wirklich eine Rechnung ohne Nummer versenden?`,
                                    confirmLabel: 'Trotzdem versenden',
                                    cancelLabel: 'Ups, trage ich nach.',
                                },
                            })
                            .afterClosed()
                            .toPromise();
                        if (!decision) {
                            return;
                        }
                    }
                    break;
                case 'invoice':
                    break;
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Plausibility of Invoice Data
        /////////////////////////////////////////////////////////////////////////////*/

        const defaultRecipient = {
            email: this.recipient.contactPerson.email,
            name: getContactPersonFullNameWithOrganization(this.recipient.contactPerson) || 'Kein Empfänger angegeben',
        };
        // Unless the user displayed the input field for the toRecipients, add the default recipient
        if (this.toRecipientInputShown) {
            // Attach email address to placeholder
            this.email.email.toRecipients = this.fillInNamesAndEmailAddressesForPlaceholders(
                this.email.email.toRecipients,
            );
        } else {
            this.email.email.toRecipients = [defaultRecipient];
        }

        // In case there are any email addresses with semicolons in them, split them.
        this.splitInvolvedPartyRecipientEmailAddresses();

        // E-Mail to be saved in the saved e-mails array. We want to keep the role of each recipient, therefore we only remove the names in a later step.
        const emailToSend = new OutgoingEmailMessage({
            ...this.email,
            email: {
                toRecipients: this.email.email.toRecipients,
                ccRecipients: this.fillInNamesAndEmailAddressesForPlaceholders(this.email.email.ccRecipients),
                bccRecipients: this.fillInNamesAndEmailAddressesForPlaceholders(this.email.email.bccRecipients),
            },
            sentAt: moment().format(),
            createdBy: this.user._id,
            multiplePdfAttachments: this.userPreferences.sendDocumentsSeparately[this.recipient.role],
            attachedDocuments: this.getEmailAttachments(),
            areAttachmentsSeparated: this.userPreferences.sendDocumentsSeparately[this.recipient.role],
        });

        // Sending an email also triggers saving it.
        this.emailSend.emit({ email: emailToSend, schedule });
    }

    private async confirmSendingWithoutInvoiceNumber(): Promise<boolean> {
        return this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    heading: 'Rechnungsnummer fehlt',
                    content: 'Möchtest du die Rechnung ohne Nummer senden?',
                    confirmLabel: 'Ohne Nummer senden',
                    cancelLabel: 'Besser nicht',
                },
            })
            .afterClosed()
            .toPromise();
    }

    private async confirmSendingWithoutInvoiceRecipient(): Promise<boolean> {
        return this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    heading: 'Rechnungsempfänger fehlt',
                    content: 'Möchtest du die Rechnung ohne Rechnungsempfänger senden?',
                    confirmLabel: 'Ohne Empfänger senden',
                    cancelLabel: 'Besser nicht',
                },
            })
            .afterClosed()
            .toPromise();
    }

    public isContactPersonEmpty(contactPerson: ContactPerson): boolean {
        if (!contactPerson) return false;

        return !contactPerson.organization && !contactPerson.lastName && !contactPerson.streetAndHouseNumberOrLockbox;
    }

    public isInvoiceRecipientEmpty(invoiceRecipient: InvoiceInvolvedParty): boolean {
        if (!invoiceRecipient) return false;

        return (
            !invoiceRecipient.contactPerson.organization &&
            !invoiceRecipient.contactPerson.lastName &&
            !invoiceRecipient.contactPerson.streetAndHouseNumberOrLockbox
        );
    }

    protected getInvoiceDocument(): DocumentMetadata {
        return this.activeDocuments.find((document) => document.type === 'invoice');
    }

    protected isInvoiceActive(): boolean {
        return !!this.getInvoiceDocument();
    }

    protected isElectronicInvoiceEnabled(): boolean {
        if (this.invoice) {
            return this.invoice.isElectronicInvoiceEnabled;
        } else {
            return this.report.feeCalculation.invoiceParameters.isElectronicInvoiceEnabled;
        }
    }

    public isSendingEmailAllowed(): boolean {
        const isTestAccountOrUsesOwnEmail: boolean =
            this.team.accountStatus === 'test' || !!this.user.emailAccount.username;
        const hasRecipients: boolean = !!(
            this.recipient.contactPerson.email ||
            this.email.email.toRecipients.length ||
            this.email.email.ccRecipients?.length ||
            this.email.email.bccRecipients?.length
        );
        const hasEmptyEmailAddresses: boolean = !!this.getRecipientsWithEmptyEmailAddresses().length;
        const hasInvalidEmailAddresses: boolean = !!this.getRecipientsWithInvalidEmailAddresses().length;

        return !!(
            this.email.subject &&
            this.email.body &&
            hasRecipients &&
            !hasEmptyEmailAddresses &&
            !hasInvalidEmailAddresses &&
            isTestAccountOrUsesOwnEmail
        );
    }

    public getSendButtonTooltip(): string {
        if (this.isSendingEmailAllowed()) {
            // Only if email has default scheduling: Shortcut Meta+Click to immediately send the email
            if (this.user.preferences.emailSendingDelayEnabled) {
                return 'ALT+Klick zum sofortigen Senden';
            }
            return '';
        }

        if (!this.email.subject || !this.email.body || !this.recipient.contactPerson.email) {
            return 'Bitte Betreff, Nachricht und E-Mailadresse angeben.';
        }

        if (this.team.accountStatus !== 'test' && !this.user.emailAccount.username) {
            return 'Bitte gib zuerst deine E-Mail-Zugangsdaten in den Einstellungen an.';
        }

        if (this.getRecipientsWithEmptyEmailAddresses().length) {
            return 'Manche Empfänger haben keine E-Mail-Adresse.';
        }

        if (this.getRecipientsWithInvalidEmailAddresses().length) {
            return 'Manche Empfänger haben ungültige E-Mail-Adressen.';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sending Email
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Schedule Email
    //****************************************************************************/
    protected async scheduleEmailSend() {
        // Don't schedule an empty email
        if (!this.isSendingEmailAllowed()) {
            const recipientsWithoutEmail: EmailRecipient[] = this.getRecipientsWithEmptyEmailAddresses();
            if (recipientsWithoutEmail.length) {
                const recipientNameList: string = recipientsWithoutEmail.map((recipient) => recipient.name).join(',\n');
                this.toastService.info(
                    'E-Mail-Adressen unbekannt',
                    `Diese Empfänger haben noch keine E-Mail-Adresse: ${recipientNameList}.`,
                );
            }

            const recipientsWithInvalidEmail: EmailRecipient[] = this.getRecipientsWithInvalidEmailAddresses();
            if (recipientsWithInvalidEmail.length) {
                const recipientNameAndEmailList: string = recipientsWithInvalidEmail
                    .map((recipient) => `${recipient.name} <${recipient.email}>`)
                    .join(',\n');
                this.toastService.info(
                    'E-Mail-Adressen ungültig',
                    `Bitte prüfe die E-Mail-Adressen dieser Empfänger:\n${recipientNameAndEmailList}.`,
                );
            }
            return;
        }

        // Open the schedule email dialog
        const dialogRef = this.dialog.open(ScheduleEmailDialogComponent, {
            width: '500px',
            panelClass: 'schedule-email-dialog',
        });

        // Handle the dialog result
        const result = await dialogRef.afterClosed().toPromise();
        if (result) {
            console.log({ result });
            const scheduleResult = result as ScheduleEmailResult;

            // Use the triggerEmailSendProcess function to send the email with the scheduled time
            await this.triggerEmailSendProcess(new Event('schedule'), scheduleResult.schedule);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Schedule Email
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Attachments
    //****************************************************************************/

    public getFullDocumentName(): string {
        if (this.invoice) {
            return this.getFullInvoiceFileName();
        }
        if (this.report) {
            return this.getFullReportFileName();
        }
    }

    private async loadResourcesForDocumentFileNameGeneration(): Promise<void> {
        // Currently, dynamic file name generation is only possible for report documents, not for documents in the invoice process.
        if (!this.report) return;

        this.placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.report?._id,
        });
        // Can be taken from cache because we keep these records local at all times.
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        this.placeholderValueTree = getPlaceholderValueTree({ fieldGroupConfigs: this.fieldGroupConfigs });
        this.fileNamePatterns = await this.fileNamePatternService.find().toPromise();
    }

    /**
     * If any changes to file patterns happen outside this component, reload the file patterns.
     * @private
     */
    private subscribeToChangesOfResourcesForFileNameGeneration() {
        const subscription = merge(
            // Field Group Configs
            this.fieldGroupConfigService.createdInLocalDatabase$,
            this.fieldGroupConfigService.patchedInLocalDatabase$,
            this.fieldGroupConfigService.deletedInLocalDatabase$,
            // File Name Patterns
            this.fileNamePatternService.createdInLocalDatabase$,
            this.fileNamePatternService.patchedInLocalDatabase$,
            this.fileNamePatternService.deletedInLocalDatabase$,
        ).subscribe({
            next: () => {
                this.loadResourcesForDocumentFileNameGeneration();
            },
        });
        this.subscriptions.push(subscription);
    }

    public getFullReportFileName() {
        // These resources may not be available right away after loading.
        if (!this.fileNamePatterns || !this.placeholderValues || !this.fieldGroupConfigs) {
            return 'Titel wird geladen...';
        }

        const documentContainsReport: boolean = !!this.activeDocuments.find((document) => document.type === 'report');

        const matchingFilePattern = this.fileNamePatterns.find(
            (fileNamePattern) =>
                fileNamePattern.documentType ===
                (documentContainsReport ? 'fullDocumentWithReport' : 'fullDocumentWithoutReport'),
        );
        if (matchingFilePattern) {
            const basename = replacePlaceholders({
                textWithPlaceholders: matchingFilePattern.pattern,
                placeholderValues: this.placeholderValues,
                fieldGroupConfigs: this.fieldGroupConfigs,
                isHtmlAllowed: false,
            });
            return `${basename}.pdf`;
        } else {
            const documentMetadata = this.report.documents.find((document) => document.type === 'report');
            let downloadName: string = addClaimantAndLicensePlateToDocumentTitle(
                documentMetadata?.title || '',
                this.report,
                'pdf',
            );
            // If a cover letter is included, the full report PDF is different per recipient. That will be reflected in the download file name.
            const isFullReportOutputDifferentPerRecipient = this.activeDocuments.some(
                (documentMetadata) => documentMetadata.type === 'letter',
            );
            if (isFullReportOutputDifferentPerRecipient) {
                downloadName = downloadName.replace(
                    /\.pdf$/,
                    ` - ${Translator.involvedPartyType(
                        this.recipient
                            .role as any /*"any" type because invoiceRecipient cannot be the case in report document*/,
                        this.report.type,
                    )}.pdf`,
                );
            }
            return downloadName;
        }
    }

    public getFullInvoiceFileName(): string {
        // Check if the screen is in the required state.
        if (!this.recipient) {
            return;
        }

        if (this.activeDocuments.length === 1) {
            return `${this.activeDocuments[0].title}.pdf`;
        }

        const documentTitle = this.activeDocuments.map((document) => document.title).join(' - ');

        if (this.invoice.number) {
            return `${documentTitle} zu Rechnung ${this.invoice.number}.pdf`;
        }
        return `${documentTitle}.pdf`;
    }

    /**
     * Get the download name for either the report or the invoice. Relevant if the document name is cut off because it's
     * too long in the user interface.
     *
     * This component is used both in the report process as well as the invoice process.
     */
    public getDocumentDownloadName(document: DocumentMetadata): string {
        // Only invoice
        if (this.invoice) {
            return `${document.title}.pdf`;
        }
        if (this.report) {
            // These resources may not be available right away after loading.
            if (!this.placeholderValues || !this.fileNamePatterns || !this.fieldGroupConfigs) {
                return `Titel wird generiert...`;
            }

            try {
                return getDocumentDownloadName({
                    documentMetadata: document,
                    fileExtension: 'pdf',
                    report: this.report,
                    placeholderValues: this.placeholderValues,
                    fileNamePatterns: this.fileNamePatterns,
                    fieldGroupConfigs: this.fieldGroupConfigs,
                });
            } catch (error) {
                if (error.code === 'MULTIPLE_MATCHING_FILE_NAME_PATTERNS_FOUND') {
                    return 'Dateinamenmuster nicht eindeutig. Bitte passe die Dateinamenmuster an.';
                }
                throw error;
            }
        }
    }

    /**
     * Re-assigning the value triggers the save() method in the service.
     */
    public saveUserPreferenceSendingDocumentsSeparately(): void {
        //eslint-disable-next-line no-self-assign
        this.userPreferences.sendDocumentsSeparately = this.userPreferences.sendDocumentsSeparately;
    }

    public attachVxs(): void {
        if (!this.email.isDatVxsAttached) {
            this.email.isDatVxsAttached = true;
            this.emitEmailChange();
        }
        if (!this.userPreferences.attachDatVxs) {
            this.userPreferences.attachDatVxs = true;
        }
    }

    public removeVxs(): void {
        this.email.isDatVxsAttached = false;
        this.emitEmailChange();
        this.userPreferences.attachDatVxs = false;
    }

    private determineActiveCoverLetter(): void {
        this.activeCoverLetter = this.activeDocuments.find((documentMetadata) => documentMetadata.type === 'letter');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Attachments
    /////////////////////////////////////////////////////////////////////////////*/

    public getEmailRecipientLink(): string {
        const emailAddress = this.recipient.contactPerson.email;
        const subject = encodeURIComponent(this.email.subject || '');
        const body = encodeURIComponent(
            convertHtmlToPlainText(this.email.body, { replaceParagraphsWithSingleNewLine: true }),
        );

        return `mailto:${emailAddress}?subject=${subject}&body=${body}`;
    }

    public openLinkExternally(link: string) {
        window.open(link);
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitRecipientChange(): void {
        this.recipientChange.emit();
    }

    public emitEmailChange(): void {
        this.emailChange.emit(this.email);
    }

    public emitDocumentChange(document: DocumentMetadata): void {
        this.documentChange.emit(document);
    }

    public getEmailAttachments(): DocumentMetadata[] {
        return this.activeDocuments;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

export type EmailRecipientPlaceholder =
    | 'Anspruchsteller'
    | 'Werkstatt'
    | 'Anwalt'
    | 'Versicherung'
    | 'Ich'
    | 'Sachverständiger'
    | 'Rechnungsempfänger';

type RecipientGroup = 'to' | 'cc' | 'bcc';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable, of } from 'rxjs';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { CarEquipment, CarEquipmentPosition } from '@autoixpert/models/reports/car-identification/car-equipment';
import { Report } from '@autoixpert/models/reports/report';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { DatAuthenticationService } from './dat-authentication.service';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';
import { ToastService } from './toast.service';

@Injectable()
export class CarEquipmentService extends OfflineSyncServiceBase<CarEquipment> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        private toastService: ToastService,
    ) {
        super({
            serviceName: 'carEquipment',
            httpClient,
            networkStatusService,
            frontendLogService,
            syncIssueNotificationService,
            serviceWorker,
        });
    }

    /**
     * Possible equipments are always fetched right from the DAT. Audatex doesn't provide possible equipments.
     * @param report
     * @param datJwt
     */
    public getPossible(report: Report, { datJwt }: { datJwt: string }): Observable<CarEquipmentPosition[]> {
        // If offline, return an empty array.
        if (!this.networkStatusService.isOnline()) {
            return of([]);
        }

        // If at least one of the parameters is missing, the possible equipment cannot be queried. That's the case
        // when a user opens the equipment selector before identifying a vehicle.
        if (
            !report.car.datIdentification.datEuropaCode ||
            !report.car.datIdentification.marketIndex ||
            !report.car.productionYear
        ) {
            return of([]);
        }

        return this.httpClient.get<CarEquipmentPosition[]>(`/api/v0/reports/${report._id}/possibleEquipment`, {
            headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
        });
    }

    /**
     * Copy car equipment from one Report to another
     */
    public async copyToReport(sourceReportId: string, toReportId: string) {
        const sourceCarEquipmentArray: CarEquipment[] = await this.find({ reportId: sourceReportId }).toPromise();
        const sourceCarEquipment: CarEquipment = sourceCarEquipmentArray?.[0] || new CarEquipment();

        // Get newCarEquipment for newReport, was created in reportService.creatEmptyReport()
        const fetchedNewCarEquipment = await this.find({ reportId: toReportId }).toPromise();
        const newCarEquipment = fetchedNewCarEquipment?.[0];

        // Copy values except _id, reportId, createdBy from sourceCarEquipment, use JSON to deep copy
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { _id, reportId, createdBy, ...copiedCarEquipment } = JSON.parse(
            JSON.stringify(sourceCarEquipment),
        ) as CarEquipment;
        const mergedCarEquipment = { ...newCarEquipment, ...copiedCarEquipment };

        try {
            await this.put(mergedCarEquipment);
        } catch (error) {
            throw new AxError({
                code: 'CAR_EQIPMENT_NOT_COPIED',
                message: 'Car equipment could not be uploaded to the target report',
                error,
            });
        }
    }
}

import { HttpParams } from '@angular/common/http';
import moment from 'moment';

export function getTimeRangeParams({ field, from, to }: { field: string; from: string; to: string }): HttpParams {
    let params = new HttpParams();

    if (from) {
        params = params.set(`${field}[$gte]`, moment(from).format('YYYY-MM-DD'));
    }
    if (to) {
        params = params.set(`${field}[$lte]`, moment(to).format('YYYY-MM-DD'));
    }

    return params;
}

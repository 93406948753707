/**
 * Get the preconfigured drawing context for photos (no alpha channel = transparency).
 *
 * Used for resizing photos.
 */
export function getCanvasDrawingContextForPhotos(): {
    drawingContextForResizing: CanvasRenderingContext2D;
    canvasForResizing: HTMLCanvasElement;
} {
    const canvasForResizing = document.createElement('canvas');
    // {alpha: false} allows the browser to always ignore rendering transparency. For photos, we don't need transparency.
    const drawingContextForResizing = canvasForResizing.getContext('2d', { alpha: false });

    return {
        drawingContextForResizing,
        canvasForResizing,
    };
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { LeaseReturnItem, LeaseReturnSection } from '@autoixpert/models/reports/damage-calculation/lease-return';
import {
    LeaseReturnTemplate,
    LeaseReturnTemplateItem,
    LeaseReturnTemplateSection,
} from '@autoixpert/models/reports/damage-calculation/lease-return-template';
import { Report } from '@autoixpert/models/reports/report';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { LeaseReturnTemplateService } from '../../../../shared/services/lease-return-template.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
    selector: 'lease-return-template-management',
    templateUrl: 'lease-return-template-management.component.html',
    styleUrls: ['lease-return-template-management.component.scss'],
    animations: [],
})
export class LeaseReturnTemplateManagementComponent {
    constructor(
        private leaseReturnTemplateService: LeaseReturnTemplateService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private dialog: MatDialog,
    ) {}

    // Template List
    public leaseReturnTemplates: LeaseReturnTemplate[] = [];
    public selectedTemplate: LeaseReturnTemplate;
    public leaseReturnTemplatesPending: boolean;

    // Template
    public templateTitleInEditMode: boolean;

    // Item List
    public sectionTitlesInEditMode: WeakMap<LeaseReturnTemplateSection, void> = new WeakMap();
    public sectionsWithItemsCollapsed: Map<LeaseReturnTemplateSection, void> = new Map();
    public selectedItem: LeaseReturnTemplateItem;
    public itemsInEditMode: WeakMap<LeaseReturnTemplateItem, void> = new WeakMap();
    public NEW_ITEM_TITLE: string = 'Neue Position';

    // Text Templates
    public textTemplateDialogShown: boolean;

    @Input() report: Report;

    @Output() close: EventEmitter<void> = new EventEmitter();

    ngOnInit() {
        this.loadLeaseReturnTemplates();
    }

    //*****************************************************************************
    //  Template List
    //****************************************************************************/
    public createNewLeaseReturnTemplate(newTemplate?: LeaseReturnTemplate): void {
        newTemplate =
            newTemplate ??
            new LeaseReturnTemplate({
                title: 'Neuer Leitfaden',
                sections: [
                    new LeaseReturnTemplateSection({
                        title: null,
                        items: [new LeaseReturnTemplateItem()],
                    }),
                ],
            });
        this.leaseReturnTemplates.push(newTemplate);
        this.leaseReturnTemplateService.create(newTemplate);
        this.selectTemplate(newTemplate);
    }

    public selectTemplate(leaseReturnTemplate: LeaseReturnTemplate): void {
        this.selectedTemplate = leaseReturnTemplate;
        this.expandAllSections();
    }

    public copyLeaseReturnTemplate(leaseReturnTemplate: LeaseReturnTemplate): void {
        const copy: LeaseReturnTemplate = JSON.parse(JSON.stringify(leaseReturnTemplate));
        delete copy._id;

        const newTemplate = new LeaseReturnTemplate(copy);
        newTemplate.title = `${leaseReturnTemplate.title} (Kopie)`;

        this.createNewLeaseReturnTemplate(newTemplate);
    }

    public deleteLeaseReturnTemplate(leaseReturnTemplate: LeaseReturnTemplate): void {
        // Confirm deletion
        this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Prüfleitfaden löschen?',
                    content: 'Das kann nicht rückgängig gemacht werden.',
                    confirmLabel: 'Bitte entsorgen!',
                    cancelLabel: 'Behalten',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .subscribe({
                next: (confirmation) => {
                    if (confirmation) {
                        // Delete from service
                        this.leaseReturnTemplateService.delete(leaseReturnTemplate._id);

                        // Delete from component
                        removeFromArray(leaseReturnTemplate, this.leaseReturnTemplates);
                        // If the deleted element is not yet included in the template list, select another template.
                        if (!this.leaseReturnTemplates.includes(this.selectedTemplate)) {
                            this.selectTemplate(this.leaseReturnTemplates[0]);
                        }
                    }
                },
            });
    }

    public sortTemplates(): void {
        this.leaseReturnTemplates.sort((templateA, templateB) => {
            return (templateA.title || '').localeCompare(templateB.title || '');
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Template List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Template Head
    //****************************************************************************/
    public enterTitleEditMode(): void {
        this.templateTitleInEditMode = true;
    }

    public leaveTitleEditMode(): void {
        this.templateTitleInEditMode = false;
    }

    public leaveTitleEditModeOnEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.leaveTitleEditMode();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Template Head
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sections & Items
    //****************************************************************************/
    public createSectionInTemplate(): void {
        const newSection = new LeaseReturnTemplateSection({
            items: [new LeaseReturnTemplateItem()],
        });
        this.selectedTemplate.sections.push(newSection);
        this.enterEditModeForTemplateSection(newSection);
    }

    public duplicateSection(section: LeaseReturnTemplateSection): void {
        const newSection = JSON.parse(JSON.stringify(section));
        const indexOfOldSection: number = this.selectedTemplate.sections.indexOf(section);
        this.selectedTemplate.sections.splice(indexOfOldSection + 1, 0, newSection);
    }

    public deleteSection(section: LeaseReturnTemplateSection): void {
        removeFromArray(section, this.selectedTemplate.sections);
    }

    //*****************************************************************************
    //  Collapse/Expand Sections
    //****************************************************************************/
    /**
     * Add all sections to the "collapsed" Map.
     */
    public collapseAllSections(): void {
        this.sectionsWithItemsCollapsed = new Map(this.selectedTemplate.sections.map((section) => [section, null]));
    }

    /**
     * Remove all sections from the collapsed list by creating a pristine Map.
     */
    public expandAllSections(): void {
        this.sectionsWithItemsCollapsed = new Map();
    }

    public collapseSection(section: LeaseReturnTemplateSection): void {
        this.sectionsWithItemsCollapsed.set(section);
    }

    public expandSection(section: LeaseReturnTemplateSection): void {
        this.sectionsWithItemsCollapsed.delete(section);
    }

    /**
     * If the user double-clicked on a section header's white space, expand/collapse the section items.
     * @param section
     * @param event
     */
    public toggleSectionItemsExpandedOnDoubleClick(section: LeaseReturnTemplateSection, event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.sectionsWithItemsCollapsed.has(section) ? this.expandSection(section) : this.collapseSection(section);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Collapse/Expand Sections
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Section Drag & Drop
    //****************************************************************************/
    public handleSectionDrop(event: CdkDragDrop<LeaseReturnSection>): void {
        moveItemInArray(this.selectedTemplate.sections, event.previousIndex, event.currentIndex);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Section Drag & Drop
    /////////////////////////////////////////////////////////////////////////////*/

    public createItemInTemplate(section: LeaseReturnTemplateSection): void {
        const newItem = new LeaseReturnTemplateItem();
        section.items.push(newItem);
        this.enterEditModeForTemplateItem(newItem);
    }

    public selectItem(item: LeaseReturnTemplateItem): void {
        this.selectedItem = item;
    }

    public duplicateItem(item: LeaseReturnTemplateItem, section: LeaseReturnTemplateSection): void {
        const newItem = JSON.parse(JSON.stringify(item));
        const indexOfOldItem: number = section.items.indexOf(item);
        section.items.splice(indexOfOldItem + 1, 0, newItem);
    }

    public deleteItem(item: LeaseReturnTemplateItem, section: LeaseReturnTemplateSection): void {
        removeFromArray(item, section.items);
    }

    //*****************************************************************************
    //  Item Drag & Drop
    //****************************************************************************/
    public handleItemDrop(section: LeaseReturnTemplateSection, event: CdkDragDrop<LeaseReturnItem>): void {
        const element = section.items.splice(event.previousIndex, 1)[0];
        section.items.splice(event.currentIndex, 0, element);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Item Drag & Drop
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sections & Items
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Section Title Edit Mode
    //****************************************************************************/
    public enterEditModeForTemplateSection(section: LeaseReturnTemplateSection): void {
        this.sectionTitlesInEditMode.set(section);
    }

    public leaveEditModeForTemplateSection(section: LeaseReturnTemplateSection): void {
        this.sectionTitlesInEditMode.delete(section);
    }

    public leaveTemplateSectionEditModeOnEnter(event: KeyboardEvent, section: LeaseReturnTemplateSection): void {
        if (event.key === 'Enter') {
            this.leaveEditModeForTemplateSection(section);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Section Title Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Item Title Edit Mode
    //****************************************************************************/
    public enterEditModeForTemplateItem(item: LeaseReturnTemplateItem): void {
        this.itemsInEditMode.set(item);
    }

    public leaveEditModeForTemplateItem(item: LeaseReturnTemplateItem): void {
        this.itemsInEditMode.delete(item);
    }

    public leaveTemplateItemEditModeOnEnter(event: KeyboardEvent, item: LeaseReturnTemplateItem): void {
        if (event.key === 'Enter') {
            this.leaveEditModeForTemplateItem(item);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Item Title Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Text Templates
    //****************************************************************************/
    public showTextTemplatesDialog(): void {
        this.textTemplateDialogShown = true;
    }

    public hideTextTemplatesDialog(): void {
        this.textTemplateDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Text Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public saveTemplate(): void {
        this.leaseReturnTemplateService.put(this.selectedTemplate);
    }

    public loadLeaseReturnTemplates(): void {
        this.leaseReturnTemplatesPending = true;

        this.leaseReturnTemplateService.find().subscribe({
            next: (leaseReturnTemplates) => {
                this.leaseReturnTemplates = leaseReturnTemplates;
                this.leaseReturnTemplatesPending = false;
                this.sortTemplates();
                // Select the first template
                this.selectTemplate(leaseReturnTemplates[0]);
            },
            error: () => {
                this.leaseReturnTemplatesPending = false;
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitCloseEvent(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

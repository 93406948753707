import { Pipe, PipeTransform } from '@angular/core';
import { ResidualValueBid } from '@autoixpert/models/reports/residual-value/residual-value-bid';

/**
 * Given a list of residual value bids returns a string stating how many bids with a value
 * (excluding bidders without bid / not interested) were placed.
 */
@Pipe({
    name: 'customBidsLabel',
    pure: false,
})
export class CustomBidsLabelPipe implements PipeTransform {
    transform(bids: ResidualValueBid[]): string {
        const numberOfBidsWithValue = bids.filter((bid) => bid.bidValue.value !== null).length;
        return `${numberOfBidsWithValue} ${bids.length === 1 ? 'Gebot' : 'Gebote'}`;
    }
}

<!-- User avatar -->
<div
    *ngIf="user || showEmptyState"
    class="user-avatar"
    [class]="'avatar-size-' + size"
    [ngClass]="{ 'three-letters': (user | userInitials).length > 3 }"
    [matTooltip]="tooltip">
    <!-- User avatar image -->
    <ng-container *ngIf="profilePictureUrl">
        <img [src]="profilePictureUrl" />
    </ng-container>

    <!-- User avatar fallback -->
    <ng-container *ngIf="user && !profilePictureUrl">
        <div [style.background-color]="user.avatarColor" class="user-avatar-fallback">
            <div class="initials">{{ user | userInitials }}</div>
        </div>
    </ng-container>

    <ng-container *ngIf="!user && showEmptyState">
        <div [style.background-color]="'#f4f6fa'" class="no-user-fallback">
            <mat-icon>person</mat-icon>
        </div>
    </ng-container>
</div>

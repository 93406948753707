import { Injectable } from '@angular/core';
import { Notification, NotificationType, NotificationsService } from 'angular2-notifications/dist';

@Injectable()
export class ToastService {
    /**
     * Errors like "Offline nicht verfügbar" are usually displayed very often when they occur. That pressures the user more
     * than it should. Also, this does not convey any more information to the user. This registry tries to solve that by removing redundant errors.
     *
     * The title and body are concatenated to form the key. Until a timeout is reached, no other error with the same
     * content will be displayed.
     *
     * But what if multiple records need multiple errors? Then the software engineer should add an individual element
     * to the toast such as an ID or a name of the individual record.
     */
    private preventDuplicateErrorsRegistry = new Set<string>();

    constructor(private notificationService: NotificationsService) {}

    public success(title: any, content?: any, override?: Partial<NotificationOptions>): Notification {
        return this.notificationService.success(title, content, override);
    }

    public error(title: any, content?: any, override?: Partial<NotificationOptions>): Notification {
        /**
         * Ensure that the same error is only displayed once if it occurs multiple times within a second.
         */
        const titleAndContent: string = `${title}${content}`;
        if (this.preventDuplicateErrorsRegistry.has(titleAndContent)) {
            return;
        }
        this.preventDuplicateErrorsRegistry.add(titleAndContent);
        setTimeout(() => {
            this.preventDuplicateErrorsRegistry.delete(titleAndContent);
        }, 1000);

        const options = Object.assign(
            {
                // Remove this toast when the user clicks on it so that the user can read and understand the error.
                timeOut: 0,
            },
            override,
        );

        return this.notificationService.error(title, content, options);
    }

    public warn(title: any, content?: any, override?: Partial<NotificationOptions>): Notification {
        const options = Object.assign(
            {
                // Increase the timeout so that the user may read the text better.
                timeOut: 5000,
            },
            override,
        );

        return this.notificationService.warn(title, content, options);
    }

    public info(title: any, content?: any, override?: Partial<NotificationOptions>): Notification {
        const options = Object.assign(
            {
                // Increase the timeout so that the user may read the text better.
                timeOut: 5000,
            },
            override,
        );

        return this.notificationService.info(title, content, options);
    }

    public html(html: any, type?: NotificationType, override?: Partial<NotificationOptions>): Notification {
        return this.notificationService.html(html, type as any, override);
    }

    public remove(id?: string): void {
        return this.notificationService.remove(id);
    }

    //*****************************************************************************
    //  Custom Notification Types
    //****************************************************************************/
    public offline(title: string, content?: string, override: Partial<NotificationOptions> = {}): Notification {
        return this.notificationService.html(
            `
            <div class="simple-notification-custom-content-wrapper">
                <img class="simple-notification-custom-icon" src="/assets/images/icons/cloud_off_blue.svg">
                <div class="simple-notification-text-column">
                    <div class="sn-title">${title}</div>
                    <div class="sn-content">${content}</div>
                </div>
            </div>`,
            'info' as any,
            {
                timeOut: 5000,
                ...override,
                // The class is necessary to reset the white-space on the wrapper element of class .sn-content. I have filed a pull request to have that element replaced with a .sn-html element. (Mark 2022-03-30)
                theClass: 'simple-notification-custom-content',
            },
            'info',
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Notification Types
    /////////////////////////////////////////////////////////////////////////////*/

    public partnerError(
        title: string,
        content: string,
        icon: ToastPartnerLogos,
        override: Partial<NotificationOptions> = {},
    ) {
        let partnerIcon;

        switch (icon) {
            case 'dat':
                partnerIcon = '/assets/images/logos/dat-logo-no-text.svg';
                break;
            case 'audatex':
                partnerIcon = '/assets/images/logos/qapter-logo-white-orange.svg';
                break;
            case 'gtmotive':
                partnerIcon = '/assets/images/logos/gtmotive-logo-no-text.png';
                break;
            case 'winvalue':
                partnerIcon = '/assets/images/logos/winvalue-inverted.png';
                break;
            case 'cartv':
                partnerIcon = '/assets/images/logos/cartv-inverted.png';
                break;
            case 'autoonline':
                partnerIcon = '/assets/images/logos/autoonline-logo-inverted.svg';
                break;
            case 'dekra':
                partnerIcon = '/assets/images/logos/dekra.svg';
                break;
            case 'afzzert':
                partnerIcon = '/assets/images/logos/afzzert.svg';
                break;
        }

        /**
         * This HTML block will be wrapped in a .sn-content container. Since that has `white-space: pre-line` defined for it, we must remove all line breaks from the HTML code.
         *
         * Line breaks from the content are not removed.
         */
        return this.notificationService.html(
            `
                <div class="display-flex flex-align-center sn-container">
                    <img class="sn-partner-logo" src="${partnerIcon}" alt="Schnittstellen-Icon">
                    <div class="sn-item-container">
                        <div class="sn-title-without-padding">$title</div>
                        <div>$content</div>
                    </div>
                </div>`
                .replace(/\n/gm, '')
                .replace('$title', title)
                .replace('$content', content),
            NotificationType.Error,
            {
                timeOut: 0,
                ...override,
            },
            'error',
        );
    }
}

interface NotificationOptions {
    position: 'top' | 'bottom' | 'middle' | 'right' | 'left' | 'center'; //	["bottom", "right"] 	Set the position on the screen where the notifications should display. Pass an array with two values example: ["top", "left"].
    timeOut: number; // 0 	Determine how long a notification should wait before closing. If set to 0 a notification won't close itself.
    showProgressBar: boolean; // true 	Determine if a progress bar should be shown | not.
    pauseOnHover: boolean; // true 	Determines if the timeOut should be paused when the notification is hovered.
    lastOnBottom: boolean; // true 	Determines if new notifications should appear at the bottom | top of the list.
    clickToClose: boolean; // true 	Determines if notifications should close on click.
    clickIconToClose: boolean; // false 	Determines if notifications should close when user clicks the icon.
    maxLength: number; // 0 	Set the maximum allowed length of the content string. If set to 0 | not defined there is no maximum length.
    maxStack: number; // 8 	Set the maximum number of notifications that can be on the screen at once.
    preventDuplicates: boolean; // false 	If true prevents duplicates of open notifications.
    preventLastDuplicates: boolean | string; // 	false 	If set to "all" prevents duplicates of the latest notification shown ( even if it isn't on screen any more ). If set to "visible" only prevents duplicates of the last created notification if the notification is currently visible.
    theClass: string; // null 	A class that should be attached to the notification. (It doesn't exactly get attached to the selector but to the first div of the template.)
    rtl: boolean; // false 	Adds the class .rtl-mode to the notification aligning the icon to the left and adding direction: rtl
    animate: 'fade' | 'fromTop' | 'fromRight' | 'fromBottom' | 'fromLeft' | 'scale' | 'rotate' | null; // "fromRight" 	Choose the type of animation | set the value to null not to display animations.
    icons: any;
}

export type ToastPartnerLogos =
    | 'dat'
    | 'audatex'
    | 'gtmotive'
    | 'winvalue'
    | 'cartv'
    | 'autoonline'
    | 'dekra'
    | 'afzzert';

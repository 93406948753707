import { Axle } from '../../models/reports/car-identification/axle';
import { Tire } from '../../models/reports/car-identification/tire';
import { getAllTiresOfVehicle } from '../tires/get-all-tires-of-vehicle';
import { getAllTiresOnAxle } from '../tires/get-all-tires-on-axle';
import { TireGerman } from './placeholder-values.types';
import { Translator } from './translator';
import { convertToMillimeters } from './utility-functions';

/**
 * The axle is optional in case the placeholder values are being generated for a spare wheel.
 */
export function getTirePlaceholderValues({
    tire,
    axle,
    useSecondTireSet,
}: {
    tire: Omit<Tire, '_id'>;
    axle?: Axle;
    useSecondTireSet?: boolean;
}): TireGerman {
    /**
     * Check if an outer tire on the same axle exists. If so, declare the inner tires as "inner" instead of only
     * "left" or "right".
     */
    let distinguishBetweenInnerAndOuterTires = false;
    if (tire.position === 'left' && axle.outerLeftTire) {
        distinguishBetweenInnerAndOuterTires = true;
    } else if (tire.position === 'right' && axle.outerRightTire) {
        distinguishBetweenInnerAndOuterTires = true;
    }

    const tireData = useSecondTireSet ? tire.secondTireSet : tire;
    return {
        Achse: tire.axle,
        Dimension: tireData.type || '',
        Hersteller: tireData.manufacturer || '',
        Position: Translator.tirePosition(tire.position, distinguishBetweenInnerAndOuterTires),
        Profil:
            tireData.treadInMm != null
                ? convertToMillimeters(tireData.treadInMm, { decimalPlaces: 1, omitDecimalsIfZero: true })
                : '',
        Art: Translator.tireType(tireData) || '',
        Bemerkung: tireData.comment || '',
    };
}

export function getTiresPlaceholderValues({
    axles,
    useSecondTireSet,
}: {
    axles: Axle[];
    useSecondTireSet?: boolean;
}): TireGerman[] {
    const tiresGerman: TireGerman[] = [];

    //*****************************************************************************
    //  Tires Defined at All?
    //****************************************************************************/
    const tires = getAllTiresOfVehicle(axles);
    const isAtLeastOneTireDefined = tires.some((tire) => tire.treadInMm || tire.type || tire.manufacturer);

    // If no tire is defined at all, return an empty array.
    if (!isAtLeastOneTireDefined) {
        return tiresGerman;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  Tires Defined at All?
    /////////////////////////////////////////////////////////////////////////////*/

    // TODO Add replacement wheel/tire.

    for (const axle of axles) {
        const tires: Tire[] = getAllTiresOnAxle(axle);
        tiresGerman.push(
            ...tires.map((tire) =>
                getTirePlaceholderValues({
                    tire,
                    axle: axle,
                    useSecondTireSet,
                }),
            ),
        );
    }

    return tiresGerman;
}

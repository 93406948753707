import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { sanitizeHtmlExceptStyle } from '../lib/html/sanitize-html-except-style';

/**
 * Allows the display of content generated with Quill in the host element's innerHTML.
 * This directive ensures sanitization of the content and adds the class 'ql-editor' to the host
 * to ensure all content styles are used as in Quill.
 */
@Directive({
    selector: '[quillContent]',
})
export class QuillContent implements OnInit {
    @Input() set quillContent(value: string | SafeHtml) {
        // Narrow typing so that sanitizeHtmlExceptStyle doesn't throw, which expects a string.
        // SafeHtml is also basically of type string, so we cannot perform property checks to narrow down the type.
        if (typeof value === 'string') {
            this.renderer.setProperty(
                this.hostElement.nativeElement,
                'innerHTML',
                sanitizeHtmlExceptStyle(value, this.domSanitizer),
            );
        }
    }

    @Input() isEmailQuill: boolean = false;

    constructor(
        private hostElement: ElementRef,
        private domSanitizer: DomSanitizer,
        private renderer: Renderer2,
    ) {}

    ngOnInit() {
        this.renderer.addClass(this.hostElement.nativeElement, 'ql-editor');
        /**
         * If this is an email editor or email templates display container, use styles more similar to Gmail.
         * The default Quill styles are set so that they resemble margins in DOCX files.
         */
        if (this.isEmailQuill) {
            this.renderer.addClass(this.hostElement.nativeElement, 'ql-email');
        }
    }
}

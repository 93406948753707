import { GarageFeeSet } from '../../contacts/garage-fee-set';
import { CommunicationRecipient } from './communication-recipient';

export class Garage extends CommunicationRecipient {
    constructor() {
        super('garage');
    }

    /**
     * The ID of the fee set stored in report.garage.contactPerson.garageFeeSets[]
     */
    selectedFeeSetId: GarageFeeSet['_id'];
}

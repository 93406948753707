import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { AxLicense } from '@autoixpert/models/billing/ax-license';
import { Team } from '@autoixpert/models/teams/team';
import { AxLicenseService } from 'src/app/shared/services/ax-license.service';

@Component({
    selector: 'cancel-team-account-dialog',
    templateUrl: './cancel-team-account-dialog.component.html',
    styleUrls: ['./cancel-team-account-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class CancelTeamAccountDialogComponent {
    constructor(private axLicenseService: AxLicenseService) {}

    @Input() team: Team;
    @Output() deleteTeam: EventEmitter<Team['expirationDate']> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    private currentTeamLicense: AxLicense;
    public expirationDate: Team['expirationDate'];

    ngOnInit() {
        this.fetchExpirationDate();
    }

    private async fetchExpirationDate() {
        this.currentTeamLicense = await this.axLicenseService.getCurrentTeamLicense(this.team);
        this.expirationDate = this.currentTeamLicense.endDate;
    }

    /**
     * Emit event to order Audatex Addon.
     */
    public confirm() {
        this.deleteTeam.emit(this.expirationDate);
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.emitCloseEvent();
        }
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitCloseEvent(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.emitCloseEvent();
                break;
            case 'Enter':
                this.confirm();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

import { generateId } from '@autoixpert/lib/generate-id';
import { Car, isCarSemiTruck } from '@autoixpert/models/reports/car-identification/car';
import { Report } from '@autoixpert/models/reports/report';
import { GarageFeeSet } from '../models/contacts/garage-fee-set';
import { DekraResponse } from '../models/reports/dekra-fees/dekra-fees';

/**
 * Deduces a full fee set from the DEKRA fees and stores it on the report.
 */
export function createGarageFeeSetFromDekraFees({
    report,
    dekraFees,
}: {
    report: Report;
    dekraFees: DekraResponse;
}): void {
    // Map the Dekra fee set to our garage fee set
    const garageFeeSet = mapDekraResponseToGarageFees(dekraFees, report);
    report.garage.contactPerson.garageFeeSets = [garageFeeSet];

    // Actually select the fee set.
    report.garage.selectedFeeSetId = garageFeeSet._id;
}

/**
 * Deduces a full fee set from the DEKRA fees.
 *
 * The DEKRA fees differentiate between cars and trucks and has a different
 * format which this method translates.
 */
export function mapDekraResponseToGarageFees(dekraFees: DekraResponse, report: Report): GarageFeeSet {
    const feeSetId: string = generateId();
    return {
        _id: feeSetId,
        validFrom: null,
        isDefault: true,
        title: 'DEKRA-Kostensätze',

        selectedWorkFractionUnit: 1, // For DEKRA fees, hourly rates are provided; no work fractions.
        mechanics: {
            firstLevel: isCarSemiTruckOrBus(report.car) ? dekraFees.semiTruck.mechanics : dekraFees.car.mechanics,
            secondLevel: null,
            thirdLevel: null,
        },
        electrics: {
            firstLevel: isCarSemiTruckOrBus(report.car) ? dekraFees.semiTruck.mechanics : dekraFees.car.mechanics, // DEKRA does not provide values for electrics. Use mechanics instead. Recommendation by Markus.
            secondLevel: null,
            thirdLevel: null,
        },
        carBody: {
            firstLevel: isCarSemiTruckOrBus(report.car) ? dekraFees.semiTruck.carBody : dekraFees.car.carBody,
            secondLevel: null,
            thirdLevel: null,
        },
        carPaint: {
            paintSystem: 'allianzCenterForTechnology',
            wage: isCarSemiTruckOrBus(report.car) ? dekraFees.semiTruck.carPaint : dekraFees.car.carPaint,
            materialSurchargeDefault: null,
            materialSurchargeUnit: null,
            materialSurchargesByPaintType: [],
        },
        dentsWage: isCarSemiTruckOrBus(report.car) ? dekraFees.semiTruck.carPaint : dekraFees.car.carPaint, // DEKTA does not provide values for removing dents. Use car paint values instead. Recommendation by Markus Fronius.
        transport: {
            calculationType: 'none',
            timeRequired: null,
            fixedPrice: null,
        },
        sparePartsSurcharge: null,
        smallPartsSurcharge: 2,
        smallPartsUnit: 'percent',
        customCalculationItems: [],
        audatexConfigCodes: [],
    };
}

function isCarSemiTruckOrBus(car: Car): boolean {
    return isCarSemiTruck(car.shape) || car.shape === 'bus';
}

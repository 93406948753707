<div class="dialog-overlay" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!--============================================-->
        <!-- Transaction Card -->
        <!--============================================-->
        <div id="transaction-column">
            <div id="transactions-card" class="card">
                <mat-icon
                    class="dialog-close-icon"
                    (click)="markTransactionsAsSeenAndCloseDialog()"
                    matTooltip="Dialog schließen">
                    close
                </mat-icon>

                <!--============================================-->
                <!-- Head Runner -->
                <!--============================================-->
                <div *ngIf="isSmallScreen()">
                    <h2 class="transactions-heading">Zahlungseingänge</h2>
                </div>
                <section id="head-runner">
                    <div *ngIf="!isSmallScreen()">
                        <h2 class="no-margin margin-right-30">Zahlungseingänge</h2>
                    </div>

                    <!--********** Search **********-->
                    <mat-form-field class="search-field">
                        <mat-icon matPrefix>search</mat-icon>
                        <input
                            matInput
                            placeholder="Suchen"
                            [(ngModel)]="transactionListSearchTerm"
                            (input)="filterTransactions()" />
                    </mat-form-field>

                    <!--********** Filters **********-->
                    <div id="filters-container" class="flex-space-between">
                        <!--********** Connected Invoices Filter **********-->
                        <div
                            class="filter-container flex-center-content cursor-pointer margin-right-20"
                            matTooltip="Filter für Zahlungseingänge mit verknüpften Rechnungen"
                            [matMenuTriggerFor]="linkFilterMenu"
                            [ngSwitch]="linkFilter"
                            [ngClass]="{ 'filter-active': linkFilter }">
                            <ng-container *ngSwitchCase="null">
                                <mat-icon class="selected-filter-icon margin-right-8 medium-icon">link</mat-icon>
                                Alle
                            </ng-container>

                            <ng-container *ngSwitchCase="'onlyLinked'">
                                <mat-icon class="selected-filter-icon margin-right-8 medium-icon">link</mat-icon>
                                nur Verknüpfte
                            </ng-container>
                            <ng-container *ngSwitchCase="'onlyUnlinked'">
                                <mat-icon class="selected-filter-icon margin-right-8 medium-icon">link_off</mat-icon>
                                nur Unverknüpfte
                            </ng-container>
                            <mat-icon class="medium-icon">arrow_drop_down</mat-icon>
                        </div>
                        <mat-menu #linkFilterMenu>
                            <!--********** No Filter **********-->
                            <a mat-menu-item class="menu-item-with-icon" (click)="selectLinkFilter(null)">
                                <mat-icon>filter_alt_off</mat-icon>
                                Kein Filter
                            </a>

                            <!--********** Only Linked **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="selectLinkFilter('onlyLinked')"
                                matTooltip="Nur Zahlungseingänge mit verbundener Rechnung anzeigen">
                                <mat-icon>link</mat-icon>
                                Nur Verknüpfte
                            </a>

                            <!--********** Only Unlinked **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="selectLinkFilter('onlyUnlinked')"
                                matTooltip="Nur Zahlungseingänge ohne verbundener Rechnung anzeigen">
                                <mat-icon>link_off</mat-icon>
                                Nur Unverknüpfte
                            </a>
                        </mat-menu>

                        <!--********** Seen Status Filter **********-->
                        <div
                            class="filter-container flex-center-content cursor-pointer"
                            matTooltip="Filter für gesehene Zahlungseingänge"
                            [matMenuTriggerFor]="unseenFilterMenu"
                            [ngSwitch]="seenFilter"
                            [ngClass]="{ 'filter-active': seenFilter }">
                            <ng-container *ngSwitchCase="null">
                                <mat-icon class="selected-filter-icon margin-right-8 medium-icon">visibility</mat-icon>
                                Alle
                            </ng-container>

                            <ng-container *ngSwitchCase="'onlySeen'">
                                <mat-icon class="selected-filter-icon margin-right-8 medium-icon">visibility</mat-icon>
                                nur Gesehene
                            </ng-container>

                            <ng-container *ngSwitchCase="'onlyUnseen'">
                                <mat-icon class="selected-filter-icon margin-right-8 medium-icon">
                                    visibility_off
                                </mat-icon>
                                nur Ungesehene
                            </ng-container>
                            <mat-icon class="medium-icon">arrow_drop_down</mat-icon>
                        </div>
                        <mat-menu #unseenFilterMenu>
                            <!--********** No Filter **********-->
                            <a mat-menu-item class="menu-item-with-icon" (click)="selectSeenFilter(null)">
                                <mat-icon>filter_alt_off</mat-icon>
                                Kein Filter
                            </a>

                            <!--********** Only Seen **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="selectSeenFilter('onlySeen')"
                                matTooltip="Nur bereits gesehene Zahlungseingänge anzeigen">
                                <mat-icon>visibility</mat-icon>
                                Nur Gesehene
                            </a>

                            <!--********** Only Unseen **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="selectSeenFilter('onlyUnseen')"
                                matTooltip="Nur neue, ungesehene Zahlungseingänge anzeigen">
                                <mat-icon>visibility_off</mat-icon>
                                Nur Ungesehene
                            </a>
                        </mat-menu>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Head Runner -->
                <!--============================================-->

                <!--============================================-->
                <!-- Transaction List -->
                <!--============================================-->
                <div
                    id="transaction-list-container"
                    infinite-scroll
                    [infiniteScrollDistance]="0.1"
                    [infiniteScrollThrottle]="0"
                    (scrolled)="onTransactionListScroll()"
                    [scrollWindow]="false">
                    <table id="transaction-list">
                        <tr
                            *ngFor="let transaction of filteredTransactions"
                            class="record"
                            [ngClass]="{
                                active: selectedTransaction === transaction,
                                'marked-as-done': transaction.markedAsDone,
                            }"
                            (click)="selectTransaction(transaction)">
                            <!--********** Logo **********-->
                            <td class="transaction-logo-cell">
                                <div
                                    class="transaction-logo-container flex-center-content"
                                    [ngClass]="{
                                        'no-insurance-logo':
                                            !getIconNameForInsurance(transaction.counterParty.holderName) &&
                                            !transaction.counterParty.holderName?.startsWith(
                                                'Deutsche Verrechnungsstelle'
                                            ),
                                    }"
                                    [matTooltip]="
                                        transaction.counterParty.holderName ||
                                        'Quelle nicht in Bank-Schnittstelle verfügbar'
                                    ">
                                    <!--********** Insurance Logo **********-->
                                    <img
                                        *ngIf="getIconNameForInsurance(transaction.counterParty.holderName)"
                                        src="/assets/images/logos/{{
                                            getIconNameForInsurance(transaction.counterParty.holderName)
                                        }}"
                                        alt="" />

                                    <!--********** Payer Initials, if logo unavailable **********-->
                                    <div
                                        class="payer-initials"
                                        *ngIf="!getIconNameForInsurance(transaction.counterParty.holderName)">
                                        {{ getPayerInitials(transaction) }}
                                    </div>
                                </div>
                            </td>

                            <!--********** Transaction Reference & Counterpart **********-->
                            <td class="transaction-reference-cell">
                                <div
                                    class="transaction-reference semibold"
                                    [innerHTML]="getReferenceWithHighlightedMatches(transaction)"
                                    [matTooltip]="
                                        getReferenceWithHighlightedMatches(transaction) !==
                                            transaction.bankReferences.unstructured &&
                                        getReferenceWithHighlightedMatches(transaction) !==
                                            transaction.bankReferences.structured
                                            ? 'Treffer in gelb hervorgehoben'
                                            : ''
                                    "></div>
                                <div
                                    class="transaction-counterparty label"
                                    [matTooltip]="
                                        transaction.counterParty?.holderName ||
                                        transaction.counterParty?.iban ||
                                        'Keine Quelle bekannt'
                                    ">
                                    {{
                                        transaction.counterParty?.holderName ||
                                            transaction.counterParty?.iban ||
                                            'Keine Quelle bekannt'
                                    }}
                                </div>
                            </td>

                            <!--********** Value Date **********-->
                            <td
                                class="value-date-cell text-align-center"
                                matTooltip="Buchungsdatum: {{ transaction.valueDate | moment: 'DD.MM.YYYY' }}">
                                <div class="value-date-day semibold">
                                    {{ transaction.valueDate | moment: 'DD' }}
                                </div>
                                <div class="value-date-month">
                                    {{ getMonthWithoutPeriod(transaction.valueDate) }}
                                </div>
                            </td>

                            <!--********** Connected Invoice **********-->
                            <td class="connected-invoice-cell">
                                <div class="connected-invoices">
                                    <!--********** Invoice Connected **********-->
                                    <!-- More than one invoice connected -> Show aggregated amount -->
                                    <div *ngIf="transaction.connectedInvoices.length > 1">
                                        <div class="connected-invoice">
                                            <div class="connected-invoice-icon-container margin-right-5">
                                                <mat-icon
                                                    class="connected-invoice-icon medium-icon connected-invoice-approved-icon"
                                                    @fadeInAndOut>
                                                    description
                                                </mat-icon>
                                            </div>
                                            <div class="connected-invoice-number flex-grow">
                                                {{ transaction.connectedInvoices.length }} Rechnungen
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="transaction.connectedInvoices.length === 1">
                                        <ng-container *ngFor="let connectedInvoice of transaction.connectedInvoices">
                                            <div
                                                class="connected-invoice"
                                                *ngIf="connectedInvoice?.invoiceId"
                                                (click)="
                                                    openInvoice(connectedInvoice.invoiceId); $event.stopPropagation()
                                                "
                                                [ngClass]="{ approved: connectedInvoice.approvedByUser }">
                                                <div class="connected-invoice-icon-container margin-right-5">
                                                    <mat-icon
                                                        class="connected-invoice-icon medium-icon approve-connected-invoice-icon"
                                                        *ngIf="!connectedInvoice.approvedByUser"
                                                        (click)="
                                                            approveConnectedInvoice(
                                                                transaction,
                                                                connectedInvoice.invoiceId
                                                            );
                                                            $event.stopPropagation()
                                                        "
                                                        matTooltip="Zahlung von {{
                                                            transaction.amount.amount / 100 | number: '1.2-2'
                                                        }} € auf der Rechnung {{
                                                            connectedInvoice.invoiceNumber
                                                        }} vermerken">
                                                        check
                                                    </mat-icon>

                                                    <mat-icon
                                                        class="connected-invoice-icon medium-icon connected-invoice-approved-icon"
                                                        *ngIf="connectedInvoice.approvedByUser"
                                                        (click)="
                                                            unapproveConnectedInvoice(
                                                                transaction,
                                                                connectedInvoice.invoiceId
                                                            );
                                                            $event.stopPropagation()
                                                        "
                                                        matTooltip="Zahlung wurde auf der Rechnung eingetragen.{{
                                                            '\n\n'
                                                        }}Klicken, um die Zahlung wieder zu entfernen."
                                                        @fadeInAndOut>
                                                        description
                                                    </mat-icon>
                                                </div>
                                                <div
                                                    class="connected-invoice-number flex-grow"
                                                    matTooltip="Rechnungsvorschlag{{
                                                        '\n\n'
                                                    }}Klicken, um die Rechnung zu öffnen.">
                                                    {{ connectedInvoice.invoiceNumber || 'Keine Nummer' }}
                                                </div>
                                                <mat-icon
                                                    class="disconnect-invoice-icon toolbar-icon medium-icon"
                                                    (click)="
                                                        handleInvoiceDisconnectionInTransactionList(
                                                            transaction,
                                                            connectedInvoice.invoiceId
                                                        );
                                                        $event.stopPropagation()
                                                    "
                                                    [matTooltip]="
                                                        getUnlinkInvoiceIconTooltip(
                                                            transaction,
                                                            connectedInvoice.invoiceId
                                                        )
                                                    ">
                                                    close
                                                </mat-icon>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <div
                                        class="number-of-suggested-invoices label italic"
                                        *ngIf="
                                            !this.hasTransactionConnectedInvoices(transaction) &&
                                            transaction.suggestedInvoices.length
                                        "
                                        matTooltip="Klicken, um die Vorschläge anzuzeigen.{{
                                            '\n\n'
                                        }}Vorschläge macht das System dann, wenn Dinge erkannt wurden, die nicht eindeutig sind, aber passen könnten, z. B. ein passender Betrag. Wird hingegen das Aktenzeichen oder die Rechnungsnummer im Verwendungszweck erkannt, wird die Rechnung direkt zur Verknüpfung vorbelegt und muss nur noch bestätigt werden.">
                                        {{ transaction.suggestedInvoices.length }}
                                        {{ transaction.suggestedInvoices.length === 1 ? 'Vorschlag' : 'Vorschläge' }}
                                    </div>
                                </div>
                            </td>

                            <!--********** Amount Container **********-->
                            <td class="amount-cell semibold text-align-end">
                                <div
                                    class="amount"
                                    [ngClass]="{
                                        matched: propertyHasBeenMatchedForAnySuggestedInvoice(
                                            transaction,
                                            'amountGross'
                                        ),
                                    }">
                                    <!--********** Amount **********-->
                                    <div
                                        [matTooltip]="
                                            propertyHasBeenMatchedForAnySuggestedInvoice(transaction, 'amountGross')
                                                ? 'Betrag entspricht dem Bruttobetrag der verknüpften Rechnung'
                                                : 'Betrag des Zahlungseingangs'
                                        ">
                                        {{ transaction.amount.amount / 100 | number: '1.2-2' }}
                                        {{ transaction.amount.currency === 'EUR' ? '€' : transaction.amount.currency }}
                                    </div>

                                    <!--********** Net Match **********-->
                                    <!-- If net and gross are equal and thus both matched, don't show the net label. -->
                                    <div
                                        class="label"
                                        *ngIf="
                                            !propertyHasBeenMatchedForAnySuggestedInvoice(transaction, 'amountGross') &&
                                            propertyHasBeenMatchedForAnySuggestedInvoice(transaction, 'amountNet')
                                        "
                                        matTooltip="entspricht dem Nettobetrag der verknüpften Rechnung">
                                        netto
                                    </div>

                                    <!--********** VAT Match **********-->
                                    <div
                                        class="label"
                                        *ngIf="
                                            propertyHasBeenMatchedForAnySuggestedInvoice(transaction, 'valueAddedTax')
                                        "
                                        matTooltip="entspricht der MwSt. der verknüpften Rechnung">
                                        MwSt.
                                    </div>
                                </div>
                            </td>

                            <!--********** Submenu **********-->
                            <td class="toolbar-cell">
                                <mat-icon
                                    class="toolbar-icon"
                                    [matMenuTriggerFor]="transactionRowSubmenu"
                                    (click)="$event.stopPropagation()">
                                    more_vert
                                </mat-icon>
                                <mat-menu #transactionRowSubmenu>
                                    <a
                                        mat-menu-item
                                        *ngIf="!transaction.markedAsDone"
                                        (click)="toggleMarkAsDone(transaction); saveTransaction(transaction)">
                                        Als erledigt markieren
                                    </a>
                                    <a
                                        mat-menu-item
                                        *ngIf="transaction.markedAsDone"
                                        (click)="toggleMarkAsDone(transaction); saveTransaction(transaction)">
                                        Noch zu erledigen
                                    </a>
                                </mat-menu>
                            </td>
                        </tr>
                    </table>
                    <div id="pending-icon-container" *ngIf="loadingTransactionsPending">
                        <img
                            class="pending-icon"
                            src="/assets/images/icons/loading-indicator.svg"
                            alt="Loading Indicator" />
                    </div>
                    <!--============================================-->
                    <!-- No Transactions Placeholder -->
                    <!--============================================-->
                    <div
                        id="no-transactions-placeholder"
                        class="flex-center-content text-align-center"
                        *ngIf="!filteredTransactions.length">
                        <!--********** No Transactions at all **********-->
                        <div *ngIf="!transactions.length">
                            <h3>Keine Buchungen</h3>
                            <div>Es wurden keine Buchungen gefunden.</div>
                        </div>

                        <!--********** No filtered transactions **********-->
                        <ng-container *ngIf="transactions.length">
                            <div *ngIf="seenFilter === 'onlyUnseen' && !linkFilter">
                                <h3>Keine neuen Buchungen</h3>
                                <div>Prima, alles aufgeräumt.</div>
                            </div>
                            <div *ngIf="!(seenFilter === 'onlyUnseen' && !linkFilter)">
                                <h3>Keine Buchungen</h3>
                                <div>Versuche, andere Filter zu verwenden.</div>
                            </div>
                        </ng-container>
                    </div>
                    <!--============================================-->
                    <!-- END No Transactions Placeholder -->
                    <!--============================================-->
                </div>
                <!--============================================-->
                <!-- END Transaction List -->
                <!--============================================-->

                <!--============================================-->
                <!-- Buttons -->
                <!--============================================-->
                <div class="dialog-buttons-container">
                    <!--********** Accept all Connections **********-->
                    <button
                        *ngIf="getTransactionsRequiringApproval().length"
                        (click)="approveAllUnapprovedInvoices()"
                        matTooltip="Zahlungseingänge als Zahlungen auf Rechnungen festschreiben">
                        <mat-icon class="button-icon margin-right-10">done_all</mat-icon>
                        {{ getTransactionsRequiringApproval().length }}
                        {{ getTransactionsRequiringApproval().length === 1 ? 'Vorschlag' : 'Vorschläge' }} annehmen
                    </button>
                    <button
                        [ngClass]="{ secondary: getTransactionsRequiringApproval().length }"
                        (click)="markTransactionsAsSeenAndCloseDialog()">
                        Schließen
                    </button>
                    <div id="transactions-sum-container" class="text-align-end">
                        <div
                            id="transactions-sum"
                            class="flex-center-content"
                            matTooltip="Summe aller sichtbaren Zahlungseingänge">
                            <mat-icon id="amount-sum-icon" class="medium-icon margin-right-8">functions</mat-icon>
                            <div class="semibold">{{ transactionsSum | number: '1.2-2' }} €</div>
                        </div>
                        <div class="label">
                            {{ filteredTransactions.length }}
                            {{ filteredTransactions.length === 1 ? 'Buchung' : 'Buchungen' }}
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Buttons -->
                <!--============================================-->

                <!--============================================-->
                <!-- Loading Placeholder -->
                <!--============================================-->
                <div
                    id="loading-transactions-placeholder"
                    class="flex-center-content"
                    *ngIf="fetchAndMatchPending || initialLoadPending"
                    @fadeOut>
                    <img class="margin-bottom-30" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    <h3>Zahlungseingänge</h3>
                    Mit Konto abgleichen...
                </div>
                <!--============================================-->
                <!-- END Loading Placeholder -->
                <!--============================================-->
            </div>
        </div>
        <!--============================================-->
        <!-- END Transaction Card -->
        <!--============================================-->

        <!--============================================-->
        <!-- Invoice Suggestions Column -->
        <!--============================================-->
        <div
            id="invoice-suggestions-column"
            [ngClass]="{ 'overflow-auto': !user?.userInterfaceStates.bankTransactionListTutorialVideoSeen }"
            *ngIf="!fetchAndMatchPending && !initialLoadPending"
            @slideInHorizontally>
            <!--============================================-->
            <!-- Tutorial Video -->
            <!--============================================-->
            <div
                id="tutorial-video-card"
                class="card card-with-padding margin-bottom-20"
                *ngIf="!user?.userInterfaceStates.bankTransactionListTutorialVideoSeen"
                @slideOutVertical>
                <mat-icon class="dialog-close-icon" (click)="hideTutorialVideo()" matTooltip="Tutorial ausblenden">
                    close
                </mat-icon>

                <h2>Einführung</h2>
                <!--********** Intro Video **********-->
                <div
                    class="header-card video-card card margin-bottom-15"
                    (click)="openVideo('Intro Kontoabgleich', bankAccountSyncVideoLink); hideTutorialVideo()">
                    <div class="video-thumbnail-container">
                        <img
                            class="video-thumbnail"
                            src="/assets/images/video-thumbnails/tour-video-thumbnail.png"
                            alt="Tour Video" />
                        <img class="video-play-icon" src="/assets/images/icons/play.svg" alt="Play" />
                    </div>
                    <div class="video-title">Kontoabgleich</div>
                    <div class="video-duration">1:30 min</div>
                </div>
                <div class="label text-align-center">
                    Auch verfügbar auf
                    <a href="https://www.youtube.com/watch?v=c0lKE_ggrxE" target="_blank" rel="noopener">YouTube</a>
                </div>
            </div>
            <!--============================================-->
            <!-- END Tutorial Video -->
            <!--============================================-->

            <!--============================================-->
            <!-- Collective Payment -->
            <!--============================================-->
            <div id="collective-payment-card" class="card dark-card card-with-padding" *ngIf="isCollectivePayment()">
                <h2>Sammelüberweisung</h2>
                <div class="collective-payment-list">
                    <ng-container *ngFor="let connectedInvoice of selectedTransaction.connectedInvoices">
                        <div class="collective-payment-item" *ngIf="connectedInvoice.invoiceId">
                            <mat-icon class="invoice-icon">description</mat-icon>
                            <div
                                class="invoice-number"
                                (click)="openInvoice(connectedInvoice.invoiceId)"
                                matTooltip="Rechnung öffnen">
                                {{ connectedInvoice.invoiceNumber || 'Keine Nummer' }}
                            </div>

                            <div class="invoice-payment-amount">
                                {{
                                    getInvoicePaymentsSum(selectedTransaction, connectedInvoice.invoiceId)
                                        | number: '1.2-2'
                                }}
                                €
                            </div>

                            <!--********** Disconnect Invoice **********-->
                            <mat-icon
                                class="disconnect-invoice-icon toolbar-icon medium-icon"
                                mat-icon-button
                                (click)="
                                    handleInvoiceDisconnectionInTransactionList(
                                        selectedTransaction,
                                        connectedInvoice.invoiceId
                                    )
                                "
                                [matTooltip]="
                                    getUnlinkInvoiceIconTooltip(selectedTransaction, connectedInvoice.invoiceId)
                                ">
                                close
                            </mat-icon>
                        </div>
                    </ng-container>
                </div>

                <div class="collective-payment-summary" *ngIf="selectedTransaction">
                    <div class="slim-progress-bar-background margin-left-10 margin-right-10">
                        <div class="slim-progress-bar-fill" [style.width.%]="getCollectivePaymentProgress()"></div>
                    </div>

                    <div class="total-amount">
                        <span>Noch offen</span>
                        <span class="amount">
                            {{ getCollectivePaymentRemainingAmount() | number: '1.2-2' }}
                            €
                        </span>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Collective Payment -->
            <!--============================================-->

            <!--============================================-->
            <!-- Invoice Suggestions Card -->
            <!--============================================-->
            <div id="invoice-suggestions-card-wrapper">
                <div id="invoice-suggestions-card" class="card dark-card card-with-padding">
                    <ng-container *ngIf="!invoiceSearchInputShown">
                        <div>
                            <h2 class="no-margin margin-bottom-10">Vorschläge</h2>
                            <div
                                class="label margin-bottom-20 text-align-center"
                                matTooltip="Vorschläge auf Basis des Verwendungszwecks, des Betrags oder des Zahlers">
                                Relevante Rechnungen
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="invoiceSearchInputShown">
                        <h2 class="no-margin margin-bottom-20">Suchergebnisse</h2>
                        <mat-icon
                            id="search-result-back-arrow"
                            class="toolbar-icon"
                            (click)="hideInvoiceSearchInput()"
                            matTooltip="Zurück zu Rechnungsvorschlägen">
                            arrow_back
                        </mat-icon>
                        <!--============================================-->
                        <!-- Invoice Search -->
                        <!--============================================-->
                        <div id="invoice-search-field-container">
                            <!--********** Form Field **********-->
                            <mat-form-field id="invoice-search-field" class="search-field light-mode">
                                <mat-icon matPrefix>search</mat-icon>
                                <input
                                    matInput
                                    [(ngModel)]="invoiceSearchTerm"
                                    (ngModelChange)="updateInvoiceSearchTerm()"
                                    placeholder="Rechnung suchen"
                                    [axAutofocus]="true" />
                                <span matSuffix>
                                    <img
                                        id="invoice-search-field-loading-indicator"
                                        [ngClass]="{ visible: invoicesPending }"
                                        class="medium-icon"
                                        src="/assets/images/icons/loading-indicator.svg"
                                        alt="" />
                                </span>
                            </mat-form-field>
                        </div>
                        <!--============================================-->
                        <!-- END Invoice Search -->
                        <!--============================================-->
                    </div>

                    <ng-container *ngIf="selectedTransaction">
                        <!--============================================-->
                        <!-- Suggested Invoices -->
                        <!--============================================-->
                        <section id="invoices" *ngIf="!invoicesPending && visibleInvoices.length" @fadeIn>
                            <div
                                class="invoice"
                                *ngFor="let invoice of visibleInvoices"
                                [ngClass]="{
                                    active: isInvoiceConnectedToTransaction(selectedTransaction, invoice._id),
                                    discarded: isInvoiceSuggestionDiscarded(selectedTransaction, invoice._id),
                                }"
                                (click)="toggleConnectInvoice(invoice)">
                                <!--********** Active Marker **********-->
                                <div
                                    class="large-selection-box-active-marker"
                                    [matTooltip]="
                                        isInvoiceConnectedToTransaction(selectedTransaction, invoice._id)
                                            ? 'Rechnung ist mit ausgewählter Buchung verknüpft'
                                            : 'Rechnung mit Buchung verknüpfen.\n\nDer Betrag wird als Zahlung auf der Rechnung vermerkt.'
                                    ">
                                    <mat-icon>check</mat-icon>
                                </div>

                                <!--********** Partial Payment **********-->
                                <div
                                    class="large-selection-box-partial-payment-marker"
                                    matTooltip="Zahlung auf mehrere Rechnungen aufteilen"
                                    (click)="$event.stopPropagation(); initializePartialPaymentMenu(invoice)"
                                    [matMenuTriggerFor]="partialPaymentMenu"
                                    #partialPaymentMenuTrigger="matMenuTrigger"
                                    (menuClosed)="partialPaymentMenuOpen = false"
                                    (menuOpened)="partialPaymentMenuOpen = true">
                                    <mat-icon>call_split</mat-icon>
                                </div>

                                <mat-menu #partialPaymentMenu>
                                    <div
                                        *ngIf="partialPaymentMenuOpen"
                                        class="partial-payment-menu-amount-container"
                                        (click)="$event.stopPropagation()">
                                        <currency-input
                                            #currencyInput
                                            placeholder="Zahlungsbetrag"
                                            [matTooltip]="
                                                'Betrag eingeben, welcher bei der ausgewählten Rechnung als Zahlung vermerkt werden soll.'
                                            "
                                            [(value)]="partialPaymentAmount"
                                            [autofocus]="true"></currency-input>

                                        <button
                                            (click)="
                                                partialPaymentMenuTrigger.closeMenu();
                                                splitPayment(selectedTransaction, invoice)
                                            ">
                                            Aufteilen
                                        </button>
                                    </div>
                                </mat-menu>

                                <!--********** Open Invoice **********-->
                                <div
                                    class="open-suggested-invoice-icon medium-icon"
                                    (click)="openInvoice(invoice._id); $event.stopPropagation()"
                                    matTooltip="Öffnen">
                                    <mat-icon>open_in_new</mat-icon>
                                </div>

                                <!--********** Recipient **********-->
                                <div class="invoice-recipient contains-possible-match">
                                    <div
                                        class="text-overflow-ellipsis"
                                        [ngClass]="{
                                            'match-highlight': propertyHasBeenMatched(
                                                selectedTransaction,
                                                invoice._id,
                                                'invoiceRecipientName'
                                            ),
                                        }"
                                        matTooltip="Empfänger: {{
                                            getFullRecipientName(invoice.recipient.contactPerson) || 'Keiner'
                                        }}">
                                        <ng-container *ngIf="getShortRecipientName(invoice.recipient.contactPerson)">
                                            {{ getShortRecipientName(invoice.recipient.contactPerson) }}
                                        </ng-container>
                                        <span
                                            class="label italic"
                                            *ngIf="!getShortRecipientName(invoice.recipient.contactPerson)">
                                            Kein Empfänger
                                        </span>
                                    </div>
                                </div>

                                <!--********** Invoice Number **********-->
                                <div class="invoice-number contains-possible-match">
                                    <div
                                        [ngClass]="{
                                            'match-highlight': propertyHasBeenMatched(
                                                selectedTransaction,
                                                invoice._id,
                                                'invoiceNumber'
                                            ),
                                        }"
                                        matTooltip="Rechnungsnummer: {{ invoice.number || 'Keine' }}">
                                        <ng-container *ngIf="invoice.number">{{ invoice.number }}</ng-container>
                                        <span class="label italic" *ngIf="!invoice.number">Keine Nummer</span>
                                    </div>
                                </div>

                                <!--********** Invoice Date **********-->
                                <div class="invoice-date text-align-end" matTooltip="Rechnungsdatum">
                                    <ng-container *ngIf="invoice.date">
                                        {{ invoice.date | moment: 'DD.MM.YY' }}
                                    </ng-container>
                                    <span class="label italic" *ngIf="!invoice.date">Kein Datum</span>
                                </div>

                                <!--********** Matches **********-->
                                <div class="invoice-match-label-list">
                                    <div
                                        class="invoice-match-label"
                                        *ngFor="
                                            let matchedProperty of getInvoiceMatchData(selectedTransaction, invoice._id)
                                                ?.matchedProperties
                                        "
                                        [matTooltip]="getMatchedPropertyTooltip(matchedProperty)">
                                        {{ getMatchedPropertyLabel(matchedProperty) }}
                                    </div>
                                </div>

                                <!--********** Net **********-->
                                <div class="invoice-amount-net text-align-end contains-possible-match">
                                    <div
                                        [ngClass]="{
                                            'match-highlight': propertyHasBeenMatched(
                                                selectedTransaction,
                                                invoice._id,
                                                'amountNet'
                                            ),
                                        }"
                                        matTooltip="Netto {{ invoice.totalNet | number: '1.2-2' }} €">
                                        {{ invoice.totalNet || 0 | number: '1.2-2' }} €
                                    </div>
                                </div>

                                <!--********** VAT **********-->
                                <div class="invoice-vat text-align-end contains-possible-match">
                                    <div
                                        [ngClass]="{
                                            'match-highlight': propertyHasBeenMatched(
                                                selectedTransaction,
                                                invoice._id,
                                                'valueAddedTax'
                                            ),
                                        }"
                                        matTooltip="Netto {{
                                            invoice.totalGross - invoice.totalNet | number: '1.2-2'
                                        }} €">
                                        {{ invoice.totalGross - invoice.totalNet || 0 | number: '1.2-2' }} €
                                    </div>
                                </div>

                                <!--********** Gross **********-->
                                <div
                                    class="invoice-amount-gross text-align-end semibold contains-possible-match margin-bottom-15">
                                    <div
                                        [ngClass]="{
                                            'match-highlight': propertyHasBeenMatched(
                                                selectedTransaction,
                                                invoice._id,
                                                'amountGross'
                                            ),
                                        }"
                                        matTooltip="Brutto {{ invoice.totalGross | number: '1.2-2' }} €">
                                        {{ invoice.totalGross || 0 | number: '1.2-2' }} €
                                    </div>
                                </div>

                                <!--********** Invoice Paid Marker **********-->
                                <div class="text-align-center">
                                    <payment-status
                                        [invoice]="invoice"
                                        (statusClick)="
                                            openPaymentsDialog(invoice); $event.stopPropagation()
                                        "></payment-status>
                                </div>
                            </div>

                            <!--********** Show search field **********-->
                            <ng-container *ngIf="!invoiceSearchInputShown">
                                <!--********** Simple Link **********-->
                                <div
                                    id="show-invoice-search-input"
                                    class="label link text-align-center"
                                    (click)="showInvoiceSearchInput()"
                                    *ngIf="visibleInvoices.length">
                                    Andere Rechnung
                                </div>
                            </ng-container>
                        </section>
                        <!--============================================-->
                        <!-- END Suggested Invoices -->
                        <!--============================================-->

                        <!--============================================-->
                        <!-- No Suggested Invoices -->
                        <!--============================================-->
                        <section
                            id="no-suggested-invoices"
                            class="flex-center-content"
                            *ngIf="!invoicesPending && !visibleInvoices.length">
                            <div class="text-align-center">
                                <div class="margin-bottom-20">Keine Vorschläge gefunden.</div>
                                <button (click)="showInvoiceSearchInput()" *ngIf="!invoiceSearchInputShown">
                                    Rechnung suchen
                                </button>
                            </div>
                        </section>
                        <!--============================================-->
                        <!-- END No Suggested Invoices -->
                        <!--============================================-->
                    </ng-container>

                    <!--============================================-->
                    <!-- No Transaction Selected -->
                    <!--============================================-->
                    <section
                        id="no-transaction-selected-placeholder"
                        class="flex-center-content"
                        *ngIf="!selectedTransaction">
                        <div class="text-align-center">
                            <img src="/assets/images/icons/no-data-to-display.png" class="margin-bottom-15" alt="" />
                            <div class="margin-bottom-10">Wähle eine Buchung aus</div>
                            <div class="label">Dann werden dir hier passende Rechnungen vorgeschlagen.</div>

                            <div
                                id="secondary-video-link"
                                class="display-flex flex-center-content"
                                *ngIf="user?.userInterfaceStates.bankTransactionListTutorialVideoSeen">
                                <div
                                    class="link display-flex flex-center-content"
                                    (click)="openVideo('Intro Kontoabgleich', bankAccountSyncVideoLink)">
                                    <mat-icon class="margin-right-8 outlined">play_circle</mat-icon>
                                    So funktioniert's
                                </div>
                            </div>
                        </div>
                    </section>
                    <!--============================================-->
                    <!-- END No Transaction Selected -->
                    <!--============================================-->
                </div>

                <div
                    id="target-iban-of-selected-transaction"
                    class="label"
                    *ngIf="selectedTransaction"
                    matTooltip="IBAN des Kontos, auf dem diese Zahlung einging">
                    {{ formatIban(selectedTransaction.iban) }}
                </div>
                <!--============================================-->
                <!-- END Invoice Suggestions Card -->
                <!--============================================-->
            </div>
        </div>
        <!--============================================-->
        <!-- END Invoice Suggestions Column -->
        <!--============================================-->
    </div>
</div>

<payments-dialog
    *ngIf="paymentsDialogShown"
    [invoice]="invoiceForPaymentsDialog"
    (close)="closePaymentsDialog()"
    (invoiceChange)="saveInvoice(invoiceForPaymentsDialog)"></payments-dialog>

import { Injectable } from '@angular/core';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { OfflineSyncBlobServiceBase } from '../libraries/database/offline-sync-blob-service.base';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { ApiErrorService } from './api-error.service';

@Injectable()
export class SyncIssueNotificationService {
    private syncIssueNotifications = new Map<SyncIssueNotification['_id'], SyncIssueNotification>();

    constructor(private apiErrorService: ApiErrorService) {}

    /**
     * This method is used to determine whether autoiXpert should let the user know that there are sync issues.
     */
    public hasNotifications(): boolean {
        return !!this.syncIssueNotifications.size;
    }

    public find(): SyncIssueNotification[] {
        return Array.from(this.syncIssueNotifications.values());
    }

    /**
     * Use this when a sync issue arises. That usually happens during DatabaseServiceBase.pushToServer().
     */
    public create(syncIssueNotificationData: Omit<SyncIssueNotification, '_id'>): SyncIssueNotification {
        const syncIssueNotification: SyncIssueNotification = {
            ...syncIssueNotificationData,
            _id: this.getId({
                databaseService: syncIssueNotificationData.databaseService,
                recordId: syncIssueNotificationData.recordId,
            }),
        };
        this.syncIssueNotifications.set(syncIssueNotification._id, syncIssueNotification);

        this.logSyncIssueToSentry(syncIssueNotificationData.error);

        return syncIssueNotification;
    }

    /**
     * Get the sync issue, e.g. for reading the auto-fix attempts.
     */
    public get({
        databaseService,
        recordId,
    }: Pick<SyncIssueNotification, 'databaseService' | 'recordId'>): SyncIssueNotification {
        const syncIssueNotificationId: string = this.getId({
            databaseService,
            recordId,
        });
        return this.syncIssueNotifications.get(syncIssueNotificationId);
    }

    /**
     * Use this when a sync issue is resolved. That usually happens after the user dealt with this sync issue and
     * triggers a sync through DatabaseServiceBase.pushToServer().
     */
    public resolve({ databaseService, recordId }: Pick<SyncIssueNotification, 'databaseService' | 'recordId'>): void {
        const syncIssueNotificationId: string = this.getId({
            databaseService,
            recordId,
        });
        this.syncIssueNotifications.delete(syncIssueNotificationId);
    }

    //*****************************************************************************
    //  Auto-Fix Sync Issues - Counter
    //****************************************************************************/
    /**
     * To prevent infinity loops when auto-fixing, the sync issue records the number of
     * previous attempts.
     * This method increases that count.
     */
    public increasePreviousAutoFixAttempts({
        databaseService,
        recordId,
    }: Pick<SyncIssueNotification, 'databaseService' | 'recordId'>): void {
        const syncIssueNotificationId: string = this.getId({
            databaseService,
            recordId,
        });
        const existingNotification = this.syncIssueNotifications.get(syncIssueNotificationId);

        if (existingNotification) {
            existingNotification.previousAutoFixAttempts ||= 0;
            existingNotification.previousAutoFixAttempts++;
            this.syncIssueNotifications.set(syncIssueNotificationId, existingNotification);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Auto-Fix Sync Issues - Counter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Bulk Clear Issues
    //****************************************************************************/
    /**
     * Clear the issues from a service. Useful when logging out or removing all data of a service.
     * @param databaseService
     */
    public clearIssuesForService({ databaseService }: Pick<SyncIssueNotification, 'databaseService'>) {
        const mapKeys = this.syncIssueNotifications.keys();
        for (const mapKey of mapKeys) {
            // Remove all issues associated with a service.
            if (mapKey.startsWith(databaseService.serviceName)) {
                this.syncIssueNotifications.delete(mapKey);
            }
        }
    }

    public clearAll() {
        this.syncIssueNotifications.clear();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bulk Clear Issues
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Log To Sentry
    //****************************************************************************/
    private logSyncIssueToSentry(error: AxError | undefined) {
        if (!error) {
            return;
        }

        let axError: AxError;
        if (error?.type === 'AxError') {
            axError = error;
        }
        /**
         * If a sync-issue arose due to a client-side error, we log it to Sentry. Server-side errors (status code >= 500) are already logged to Sentry
         * through the ApiErrorService.
         * This ensures that we know about ALL errors that occur in the client and cause sync issues.
         */
        if (axError.statusCode && axError.statusCode < 500) {
            this.apiErrorService.logErrorSilently(axError);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Log To Sentry
    /////////////////////////////////////////////////////////////////////////////*/

    private getId({ databaseService, recordId }: Pick<SyncIssueNotification, 'databaseService' | 'recordId'>): string {
        return `${databaseService.serviceName}-${recordId}`;
    }
}

/**
 * Sync issue notifications exist only locally. They are created when records cannot be synced to the
 * autoiXpert backend and are removed again after the issue was resolved.
 * The user has multiple ways to resolve the issue including:
 * - retrying sync
 * - navigating to the record in the respective component (ReportDetailsComponent, InvoiceEditorComponent, ...)
 * - loading the local record from the server
 */
export class SyncIssueNotification {
    // `${syncIssueNotification.databaseService.serviceName}-${syncIssueNotification.recordId}`
    _id: string;
    /**
     * The heading and content of the user display message. This is a localized message.
     */
    heading: string;

    reason: string;
    solution?: string;
    /**
     * May be the report token for reports, the invoice number for invoices, etc.
     */
    humanReadableIdentifier?: string;
    /**
     * A reference to the database service in which the faulty record is saved.
     */
    databaseService: OfflineSyncServiceBase<any> | OfflineSyncBlobServiceBase;
    /**
     * The ID of the record that caused the sync issue.
     */
    recordId: string;
    /**
     * An optional error that caused this sync issue. This is usually for technical debugging purposes only.
     */
    error?: any;
    /**
     * How often has this issue been tried to auto fix and sync?
     *
     * We must prevent infinite sync cycles if the client is still running old code
     * and writes invalid properties.
     *
     * The sync issue must hold on to the number of attempts because the sync service
     * is highly event-driven and therefore currently cannot keep counts.
     */
    previousAutoFixAttempts?: number = 0;
}

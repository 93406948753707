import { Injectable } from '@angular/core';

export interface AppVersion {
    hash: string;
    appData: {
        commit: string;
        createdAt: string;
        silentUpdate: boolean;
    };
}

@Injectable()
export class AppVersionService {
    constructor() {}

    public currentVersion?: AppVersion;
    public latestVersion?: AppVersion;
}

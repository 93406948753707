import { FabricObject, Pattern, StaticCanvas, filters } from 'fabric';
import { FabricImage } from 'fabric/dist/fabric';
import { generateId } from '@autoixpert/lib/generate-id';
import { CanvasManager } from './photo-editor.canvas-manager';

export class CanvasBlurPatternManager {
    private canvasManager: CanvasManager;

    // Hold references to blur pattern canvas, fabric photo and the pattern itself in order to update them when necessary.
    private readonly blurPatterns: {
        [key: string]: { canvas: StaticCanvas; fabricPhoto: FabricImage; pattern: Pattern };
    } = {};

    constructor({ canvasManager }: { canvasManager: CanvasManager }) {
        this.canvasManager = canvasManager;
    }

    /**
     * Calculate the left and top position of an object based on its origin property (center or left/top).
     */
    private getObjectLeftAndTop(object: FabricObject) {
        let objectLeft = object.left;
        let objectTop = object.top;

        if (object.originX === 'center') {
            objectLeft = object.left - (object.width * object.scaleX) / 2;
        }
        if (object.originY === 'center') {
            objectTop = object.top - (object.height * object.scaleY) / 2;
        }

        return { objectLeft, objectTop };
    }

    /**
     * Check if the fabric object has a blurred background.
     * As this is not supported by fabricjs, we store the blur factor in the object's data property.
     * The ID property is needed to uniquely identify the object and associate it with the blur pattern canvas.
     */
    public isBlurPatternObject(object: FabricObject) {
        return object.data?.axBlurFactor !== undefined && !!object.data?.axId;
    }

    /**
     * Find and return all objects on the main canvas that have a blurred background.
     */
    public getBlurPatternObjects() {
        return this.canvasManager.canvas.getObjects().filter((object) => this.isBlurPatternObject(object));
    }

    /**
     * Set the blur pattern data on the object with the given blur factor.
     */
    public setObjectBlurPatternData(object: FabricObject, { blurFactor }: { blurFactor: number }) {
        object.data ??= {};
        object.data.axId ??= generateId();
        object.data.axBlurFactor = blurFactor;
    }

    //*****************************************************************************
    //  Enliven blur pattern
    //****************************************************************************/
    /**
     * Enlivening a blur pattern object is necessary because fabricjs does not support blur patterns natively.
     * Therefore, we need to identify objects with a blur pattern background and create a blur pattern canvas for them.
     * The object only contains the metadata necessary to create the blur pattern.
     */
    public async enlivenObjectsBlurPattern() {
        const objects = this.getBlurPatternObjects();
        for (const object of objects) {
            await this.enlivenObjectBlurPattern(object);
        }
    }

    public async enlivenObjectBlurPattern(object: FabricObject) {
        if (!this.isBlurPatternObject(object)) {
            console.warn('[enlivenObjectBlurPattern] Object is not a blur pattern object', object);
            return;
        }

        const pattern = await this.createBlurPattern(object);
        object.set({ fill: pattern, objectCaching: false });
        this.rescaleBlurPatternPhoto(object);
        this.registerBlurPatternCallbacks(object);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Enliven blur pattern
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Register fabricjs object callbacks to update the blur pattern when the object is modified.
     * The blurred image on the blur pattern canvas needs to be rescaled and repositioned when the object is moved, resized or rotated.
     */
    private registerBlurPatternCallbacks(object: FabricObject) {
        const modifyCallback = async (source: string) => {
            this.rescaleBlurPatternPhoto(object);
        };

        object.on('mousemove', () => modifyCallback('mousemove'));
        object.on('resizing', () => modifyCallback('resizing'));
        object.on('moving', () => modifyCallback('moving'));
        object.on('rotating', () => modifyCallback('rotating'));
    }

    /**
     * Create a blur pattern for the given object.
     * The blur pattern is a canvas that contains a blurred image of the main canvas.
     * We clone the original fabric photo and apply a blur filter to it.
     * Re-use the blur pattern canvas if it already exists.
     */
    private async createBlurPattern(object: FabricObject) {
        if (!this.isBlurPatternObject(object)) {
            console.warn('[createBlurPattern] Object missing blur pattern data', object);
            return;
        }
        const id = object.data.axId;

        if (!this.blurPatterns[id]) {
            const fabricPhoto = await this.canvasManager.fabricPhoto.clone();

            const { objectLeft, objectTop } = this.getObjectLeftAndTop(object);
            const cropX = objectLeft / object.scaleX;
            const cropY = objectTop / object.scaleX;
            fabricPhoto.set({
                cropX,
                cropY,
                scaleX: fabricPhoto.scaleX * object.scaleX,
                scaleY: fabricPhoto.scaleY * object.scaleY,
            });

            // Apply blur filter
            fabricPhoto.filters.push(new filters.Blur({ blur: object.data.axBlurFactor }));
            fabricPhoto.applyFilters();

            // Clone pattern canvas
            const canvas = new StaticCanvas();
            canvas.setDimensions({ width: fabricPhoto.width, height: fabricPhoto.height });
            canvas.add(fabricPhoto);
            canvas.renderAll();

            // Create pattern
            const pattern = new Pattern({
                source: canvas.getElement(),
                repeat: 'no-repeat',
            });

            this.blurPatterns[id] = { canvas, fabricPhoto, pattern };
        } else {
            this.applyBlurPatternPhotoFilters(object);
        }

        return this.blurPatterns[id].pattern;
    }

    /**
     * Similar to the function CanvasObjectManager.rescaleObjects, it is necessary to rescale the blur pattern photo when the main canvas is resized.
     */
    private rescaleBlurPatternPhoto(object: FabricObject) {
        if (!this.isBlurPatternObject(object)) {
            console.warn('[rescaleBlurPatternPhoto] Object missing blur pattern data', object);
            return;
        }

        const id = object.data?.axId;
        if (!id) return;

        const blurPattern = this.blurPatterns[id];
        if (!blurPattern) return;
        const { canvas, fabricPhoto, pattern } = blurPattern;

        const { objectLeft, objectTop } = this.getObjectLeftAndTop(object);

        fabricPhoto.set({
            angle: this.canvasManager.fabricPhoto.angle - object.angle,
            cropX: objectLeft / this.canvasManager.fabricPhoto.scaleX,
            cropY: objectTop / this.canvasManager.fabricPhoto.scaleY,
            // I have no idea why we need to multiply by 0.5 here, but it works.
            scaleX: (this.canvasManager.fabricPhoto.scaleX / object.scaleX) * 0.5,
            scaleY: (this.canvasManager.fabricPhoto.scaleY / object.scaleY) * 0.5,
        });

        /**
         * CropX and CropY does nothing if negative, therefore we need to use the pattern offset instead to move the image.
         * This is necessary when the object is moved beyond the top or left canvas boundaries.
         */
        pattern.offsetX = objectLeft > 0 ? 0 : -objectLeft / object.scaleX;
        pattern.offsetY = objectTop > 0 ? 0 : -objectTop / object.scaleY;

        canvas.renderAll();
    }

    /**
     * Rescale all blur pattern photos on the main canvas. This is necessary when the main canvas is resized.
     */
    public rescaleBlurPatternPhotos() {
        const objects = this.getBlurPatternObjects();
        for (const object of objects) {
            this.rescaleBlurPatternPhoto(object);
        }
    }

    /**
     * Filters like sharpness or saturation can be applied to the original fabric photo on the main canvas.
     * These filters need to also be applied to all blur pattern photos.
     */
    public applyBlurPatternPhotosFilters() {
        const objects = this.getBlurPatternObjects();
        for (const object of objects) {
            this.applyBlurPatternPhotoFilters(object);
        }
    }

    /**
     * Apply the blur filter to the fabric photo on the blur pattern canvas.
     * Add all filters in addition to the blur filter to the fabric photo.
     */
    private applyBlurPatternPhotoFilters(object: FabricObject) {
        if (!this.isBlurPatternObject(object)) {
            console.warn('[applyBlurPatternPhotoFilters] Object missing blur pattern data', object);
            return;
        }

        const id = object.data?.axId;
        if (!id) return;

        const blurPattern = this.blurPatterns[id];
        if (!blurPattern) return;
        const { fabricPhoto, canvas } = blurPattern;

        fabricPhoto.filters = [
            ...this.canvasManager.fabricPhoto.filters,
            new filters.Blur({
                blur: object.data.axBlurFactor,
            }),
        ];

        fabricPhoto.applyFilters();
        canvas.renderAll();
    }
}

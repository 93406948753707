export async function blobToBase64(blob: Blob): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
        reader.onloadend = function () {
            const dataUrl = reader.result as string;
            // The data URL looks like "base64,amtzZGZoZ3Nqa2Fzc2RmZ3NkZmdzZGZnc2RmZ3NkZmdh". This removes the "base64," in front of the string.
            resolve(dataUrl.substr(dataUrl.indexOf(',') + 1));
        };
        reader.onerror = reject;
    });
}

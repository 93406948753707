import { Injectable } from '@angular/core';
import { RealtimeEditSessionServiceBase } from '../libraries/realtime-edit-sessions/realtime-edit-session.service-base';
import { FeathersSocketioService } from './feathers-socketio.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';

/**
 * Keep track of other users editing the same office space.
 */
@Injectable()
export class ReportRealtimeEditorService extends RealtimeEditSessionServiceBase {
    constructor(
        protected feathersClient: FeathersSocketioService,
        protected networkStatusService: NetworkStatusService,
        protected loggedInUserService: LoggedInUserService,
    ) {
        super({
            process: 'report',
            feathersClient,
            networkStatusService,
            loggedInUserService,
        });
    }
}

import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { CarEquipment } from '@autoixpert/models/reports/car-identification/car-equipment';
import {
    ValuepilotEquipmentOption,
    ValuepilotEquipmentOptions,
    ValuepilotMarketAnalysis,
} from '@autoixpert/models/reports/market-value/valuepilot-market-analysis';
import { translateValuepilotEquipmentOption } from '../../../../shared/libraries/translation/translate-valuepilot-equipment-option';
import { CarEquipmentService } from '../../../../shared/services/car-equipment.service';

@Component({
    selector: 'valuepilot-equipment-dialog',
    templateUrl: 'valuepilot-equipment-dialog.component.html',
    styleUrls: ['valuepilot-equipment-dialog.component.scss'],
})
export class ValuepilotEquipmentDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<ValuepilotEquipmentDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: ValuepilotEquipmentDialogData,
        private carEquipmentService: CarEquipmentService,
    ) {}

    public carEquipment: CarEquipment;
    public valuepilotEquipmentOptions: ValuepilotEquipmentOptions;

    public valuepilotEquipmentOptionNamesWithoutSpecialUiNeeds: (keyof ValuepilotEquipmentOptions)[] = [
        // 'airconditioningAutomatic',
        // 'airconditioningManual',
        'leatherSeats',
        'xenonHeadlights',
        'hitch',
        'particulateFilter',
        'headupDisplay',
        'navigationSystem',
        'sunRoof',
    ];
    private possibleEquipmentTitleMaps: { propertyName: keyof ValuepilotEquipmentOptions; titles: string[] }[] = [
        {
            propertyName: 'airconditioningAutomatic',
            titles: ['Klimaautomatik'],
        },
        {
            propertyName: 'airconditioningManual',
            titles: ['Klima (manuell)'],
        },
        {
            propertyName: 'leatherSeats',
            titles: ['Lederausstattung', 'Ledersitze'],
        },
        {
            propertyName: 'xenonHeadlights',
            titles: ['Xenon'],
        },
        {
            propertyName: 'hitch',
            titles: ['Anhängerkupplung'],
        },
        {
            propertyName: 'particulateFilter',
            titles: ['Rußpartikelfilter'],
        },
        {
            propertyName: 'headupDisplay',
            titles: ['Head-Up Display'],
        },
        {
            propertyName: 'navigationSystem',
            titles: ['Navigation'],
        },
        {
            propertyName: 'sunRoof',
            titles: ['Schiebedach'],
        },
    ];

    ngOnInit() {
        // Shorthand
        this.valuepilotEquipmentOptions = this.data.valuepilotMarketAnalysis.valuepilotEquipmentOptions;

        // Query Equipment
        this.carEquipmentService.find({ reportId: this.data.reportId }).subscribe({
            next: (carEquipment) => {
                this.carEquipment = carEquipment[0];

                // Recognize equipment titles to activate checkboxes.
                // Only run if no options are active.
                const allEquipmentOptionsAreInactive = Object.entries(this.valuepilotEquipmentOptions).every(
                    ([key, value]) => !(value as ValuepilotEquipmentOption).active,
                );
                if (allEquipmentOptionsAreInactive) {
                    this.scanCarEquipment();
                }
            },
        });
    }

    public translateValuepilotEquipmentOption = translateValuepilotEquipmentOption;

    /**
     * Scan equipment list from VIN (and possibly manually extended) and activate equipment checkboxes accordingly.
     */
    public scanCarEquipment() {
        if (!this.carEquipment) return;

        // Check for each equipment option (for VALUEpilot) if the associated titles exist within the equipment list.
        for (const equipmentTitleMap of this.possibleEquipmentTitleMaps) {
            // Shorthand
            const equipmentOption =
                this.data.valuepilotMarketAnalysis.valuepilotEquipmentOptions[equipmentTitleMap.propertyName];

            // Clear any existing associations.
            equipmentOption.recognizedEquipmentTitle = undefined;

            // Check each possible name for an equipment.
            for (const possibleTitle of equipmentTitleMap.titles) {
                const possibleTitleLowerCase = possibleTitle.toLowerCase();

                // Find a matching equipment by its title. Case-insensitive.
                const matchingEquipment = this.carEquipment.equipmentPositions.find((equipmentPosition) =>
                    equipmentPosition.description.toLowerCase().includes(possibleTitleLowerCase),
                );

                equipmentOption.active = !!matchingEquipment;
                if (matchingEquipment) {
                    equipmentOption.recognizedEquipmentTitle = matchingEquipment.description;
                    break;
                }
            }
        }
    }

    public deactivateAirconditioningManual() {
        if (this.data.valuepilotMarketAnalysis.valuepilotEquipmentOptions.airconditioningAutomatic.active) {
            this.data.valuepilotMarketAnalysis.valuepilotEquipmentOptions.airconditioningManual.active = false;
        }
    }

    public deactivateAirconditioningAutomatic() {
        if (this.data.valuepilotMarketAnalysis.valuepilotEquipmentOptions.airconditioningManual.active) {
            this.data.valuepilotMarketAnalysis.valuepilotEquipmentOptions.airconditioningAutomatic.active = false;
        }
    }

    public openValuepilotWebInterface() {
        window.open('https://valuepilot.autoonline.com/', '_blank', 'noopener');
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public startMarketAnalysis() {
        this.dialogRef.close(true);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface ValuepilotEquipmentDialogData {
    valuepilotMarketAnalysis: ValuepilotMarketAnalysis;
    reportId: string; // Used for querying equipment list.
}

<!--============================================-->
<!-- Search Field -->
<!--============================================-->
<ng-container *ngIf="!reportId && !selectedReport">
    <mat-form-field class="mat-block search-field">
        <mat-icon
            matPrefix
            id="report-search-icon"
            matTooltip="Suche in Aktenzeichen, Firmen-, Vor- & Nachname sowie Nummernschild. Suche beginnt ab drei Zeichen">
            search
        </mat-icon>
        <input
            matInput
            placeholder="Gutachten suchen"
            [(ngModel)]="searchTerm"
            (ngModelChange)="updateSearchTerm($event); filterReportsAutocomplete()"
            name="report-token"
            [matAutocomplete]="reportSearchAutocomplete"
            (focus)="filterReportsAutocomplete()" />
        <img
            matSuffix
            src="/assets/images/icons/loading-indicator.svg"
            alt=""
            id="report-search-pending-indicator"
            *ngIf="isReportSearchPending"
            matTooltip="Suche..." />
    </mat-form-field>

    <!--============================================-->
    <!-- Autocomplete -->
    <!--============================================-->
    <mat-autocomplete #reportSearchAutocomplete>
        <mat-option
            *ngFor="let report of filteredReports"
            class="report-option"
            (mousedown)="$event.preventDefault()"
            (onSelectionChange)="selectReport(report)">
            <div class="report-option-inner-wrapper">
                <div
                    class="report-manufacturer-icon-container"
                    (click)="navigateToReport(report)"
                    *ngIf="report.car.make && iconForCarBrandExists(report.car.make); else carMakeIconPlaceholder">
                    <img
                        [src]="iconFilePathForCarBrand(report.car.make, 'large')"
                        alt=""
                        class="car-manufacturer-logo"
                        [matTooltip]="report.car.make + ' ' + report.car.model" />
                </div>
                <ng-template #carMakeIconPlaceholder>
                    <div class="car-make-icon-placeholder"></div>
                </ng-template>
                <div class="report-name-and-date-container">
                    <div
                        *ngIf="report.claimant.contactPerson.organization"
                        class="report-name-container"
                        [matTooltip]="report.claimant.contactPerson.organization">
                        {{ report.claimant.contactPerson.organization }}
                    </div>
                    <div
                        *ngIf="!report.claimant.contactPerson.organization"
                        class="report-name-container"
                        [matTooltip]="
                            report.claimant.contactPerson.firstName + ' ' + report.claimant.contactPerson.lastName
                        ">
                        {{ report.claimant.contactPerson.firstName }} {{ report.claimant.contactPerson.lastName }}
                    </div>
                    <div class="label">
                        <span *ngIf="report.token" [matTooltip]="'Aktenzeichen ' + report.token">
                            {{ report.token }} -
                        </span>
                        <span matTooltip="Erstellt am">{{ report.createdAt | moment: 'DD.MM.YY' }}</span>
                    </div>
                </div>
                <div class="report-open-in-new-container">
                    <mat-icon
                        class="report-open-in-new"
                        (click)="handleOpenInNewClick(report._id, $event)"
                        matTooltip="In neuem Tab öffnen">
                        open_in_new
                    </mat-icon>
                </div>
            </div>
        </mat-option>
    </mat-autocomplete>
    <!--============================================-->
    <!-- END Autocomplete -->
    <!--============================================-->
</ng-container>
<!--============================================-->
<!-- END Search Field -->
<!--============================================-->

<!--============================================-->
<!-- Report Details -->
<!--============================================-->
<div id="details-of-selected-report-container" *ngIf="reportId">
    <!--********** Report Details **********-->
    <div id="report-details-list" *ngIf="selectedReport">
        <!--============================================-->
        <!-- Report Header Data -->
        <!--============================================-->
        <div class="report-details-item">
            <div class="report-details-icon-container">
                <mat-icon>fingerprint</mat-icon>
            </div>
            <div class="report-details-item-value-container flex-grow text-overflow-ellipsis">
                <div class="report-details-item-value">
                    <a
                        id="connected-report-token-link"
                        [routerLink]="'/Gutachten/' + reportId"
                        *ngIf="selectedReport.token">
                        {{ selectedReport.token }}
                    </a>
                    <span *ngIf="!selectedReport.token">-</span>
                </div>
                <div class="report-details-item-label">Aktenzeichen</div>
            </div>
            <!--********** Submenu **********-->
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="reportDetailsSubmenu">more_vert</mat-icon>
            <mat-menu #reportDetailsSubmenu>
                <a mat-menu-item class="menu-item-with-icon" [routerLink]="['/Gutachten', reportId]">
                    <mat-icon>visibility</mat-icon>
                    Gutachten öffnen
                </a>
                <mat-option (click)="disconnectReport()" [disabled]="disabled">
                    <mat-icon>link_off</mat-icon>
                    Verknüpfung aufheben
                </mat-option>
            </mat-menu>
        </div>
        <!--============================================-->
        <!-- END Report Header Data -->
        <!--============================================-->

        <!--============================================-->
        <!-- Claimant -->
        <!--============================================-->
        <div class="report-details-item">
            <div class="report-details-icon-container">
                <mat-icon>person</mat-icon>
            </div>
            <div class="report-details-item-value-container">
                <div class="report-details-item-value">
                    <span
                        *ngIf="selectedReport.claimant?.contactPerson.organization"
                        [matTooltip]="selectedReport.claimant.contactPerson.organization">
                        {{ selectedReport.claimant.contactPerson.organization }}
                    </span>
                    <span
                        *ngIf="!selectedReport.claimant?.contactPerson.organization"
                        [matTooltip]="
                            selectedReport.claimant.contactPerson.firstName +
                            ' ' +
                            selectedReport.claimant.contactPerson.lastName
                        ">
                        {{ selectedReport.claimant.contactPerson.firstName }}
                        {{ selectedReport.claimant.contactPerson.lastName }}
                    </span>
                </div>
                <div class="report-details-item-label">Anspruchsteller</div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Claimant -->
        <!--============================================-->

        <!--============================================-->
        <!-- License Plate -->
        <!--============================================-->
        <div id="report-details-license-plate-container">
            <a [routerLink]="'/Gutachten/' + reportId">
                <license-plate
                    *ngIf="selectedReport.car.licensePlate"
                    [licensePlate]="selectedReport.car.licensePlate"></license-plate>
            </a>
        </div>
        <!--============================================-->
        <!-- END License Plate -->
        <!--============================================-->
    </div>
</div>
<!--============================================-->
<!-- END Report Details -->
<!--============================================-->

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ContactPerson, OrganizationType } from '@autoixpert/models/contacts/contact-person';
import { Report } from '@autoixpert/models/reports/report';
import { areContactPeopleEqual } from '../libraries/are-contact-people-equal';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { get$SearchMongoQueryContactPeople } from '../libraries/database/search-query-translators/get-$search-mongo-query-contact-people';
import { FeathersQuery } from '../types/feathers-query';
import {
    contactPersonRecordMigrations,
    contactPersonServiceObjectStoreAndIndexMigrations,
} from './contact-person.service-migrations';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { ReportService } from './report.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class ContactPersonService extends OfflineSyncServiceBase<ContactPerson> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        private reportService: ReportService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'contactPerson',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            objectStoreAndIndexMigrations: contactPersonServiceObjectStoreAndIndexMigrations,
            recordMigrations: contactPersonRecordMigrations,
            get$SearchMongoQuery: get$SearchMongoQueryContactPeople,
        });
    }

    //*****************************************************************************
    //  Contact People from Open Reports
    //****************************************************************************/
    /**
     * When a user uses an autocomplete address field in the report details screens, the user expects all addresses from other open reports that have not yet been
     * saved in the contact people database collection to be available, too. This function returns all contact people from those open reports.
     * @param organizationTypes
     * @param existingContacts
     * @param excludedReportIds - Exclude reports such as the one loaded when calling this method to only return contacts from other reports.
     */
    public async getContactPeopleFromOpenReports({
        organizationTypes,
        existingContacts = [],
        excludedReportIds = [],
    }: {
        organizationTypes: OrganizationType[];
        existingContacts?: ContactPerson[];
        excludedReportIds?: Report['_id'][];
    }): Promise<ContactPerson[]> {
        const contactPeople: ContactPerson[] = [];
        const openReports: Report[] = await this.reportService.getAllOpenReports().toPromise();
        const possibleDuplicates: ContactPerson[] = [...existingContacts];

        // Add claimant, lawyer and garage contact people from open reports
        for (const organizationType of organizationTypes) {
            // Map report to contact person
            const contactsOfOpenReports: ContactPerson[] = openReports
                .filter((openReport) => !excludedReportIds.includes(openReport._id))
                .map((report) => {
                    /**
                     * Some contact people do not exist on certain report types, e.g. the "lawyer" does not exist
                     * on valuation reports.
                     */
                    return report[organizationType]?.contactPerson;
                })
                // No empty contacts
                .filter(
                    (contactPerson) =>
                        contactPerson &&
                        (contactPerson.organization || contactPerson.firstName || contactPerson.lastName),
                );

            // Only add non-duplicates
            for (const contactOfOpenReport of contactsOfOpenReports) {
                if (
                    possibleDuplicates.some((possibleDuplicate) =>
                        areContactPeopleEqual(possibleDuplicate, contactOfOpenReport),
                    )
                ) {
                    continue;
                }
                contactOfOpenReport.createdBy = 'from-open-report';
                contactPeople.push(contactOfOpenReport);

                // Avoid displaying the same contact from two or more open reports by adding a generated contact to the duplicates array.
                possibleDuplicates.push(contactOfOpenReport);
            }
        }

        return contactPeople;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact People from Open Reports
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Return contacts from the server or indexedDB.
     * Use a pagination token (searchAfterPaginationToken) to get the next page of results when online.
     * Use skip to get the next page of results when offline.
     */
    public async getContactsFromServerOrIndexedDB({
        searchAfterPaginationToken,
        skip,
        limit = 10,
        query,
        searchTerm,
    }: {
        searchAfterPaginationToken?: string;
        skip?: number;
        limit: number;
        query: FeathersQuery;
        searchTerm?: string;
    }): Promise<{
        records: ContactPerson[];
        lastPaginationToken?: string;
    }> {
        if (searchTerm && searchTerm.length >= 3) {
            query.$search = searchTerm;
        }

        query.$limit = limit;
        if (searchAfterPaginationToken) {
            query.$searchAfterPaginationToken = searchAfterPaginationToken;
        }
        if (skip) {
            query.$skip = skip;
        }

        return await this.findWithPaginationToken(query);
    }
}

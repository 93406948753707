import { GarageFeeSet } from '../../models/contacts/garage-fee-set';
import { CarShape, isCarSemiTruck } from '../../models/reports/car-identification/car';

/**
 * Get the relevant wage classes for rates 1 to 3 depending on the given manufacturer.
 * Audatex knows 3 garage wage classes (rates 1, 2 and 3). Rate 5 is for dents, so it's always the same. Rate 4 seems to be obsolete. Paint has its own rate, so that's not relevant for this mapping, either.
 *
 * Source: https://www.sv.audatex.de/lohnklassifizierungen/
 */
export function getAudatexWageRates({
    manufacturer,
    carShape,
    feeSet,
}: {
    manufacturer: string;
    carShape: CarShape;
    feeSet: GarageFeeSet;
}): {
    rate1: number;
    rate2: number;
    rate3: number;
} {
    const manufacturerLowerCase = (manufacturer || '').toLowerCase();

    const mechanicsWage: number =
        feeSet.mechanics.firstLevel ||
        // If only car body is entered, use that as some garages only have one wage which is the car body wage.
        feeSet.carBody.firstLevel;

    const electricsWage: number =
        feeSet.electrics.firstLevel ||
        // Fallback: Some customers may enter the electrics wage in the second row of the first column because officially, Volkswagen works with "difficulty levels", not mechanics, electrics and car body.
        feeSet.mechanics.secondLevel ||
        // If only car body is entered, use that as some garages only have one wage which is the car body wage.
        feeSet.carBody.firstLevel ||
        // Some users only enter one wage and expect (like it used to be in AudaFusion) that that value will be used for all three wage classes.
        feeSet.mechanics.firstLevel;

    const carBodyWage: number =
        feeSet.carBody.firstLevel ||
        // Fallback: Some customers may enter the car body wage in the third row of the first column because officially, Volkswagen works with "difficulty levels", not mechanics, electrics and car body.
        feeSet.mechanics.thirdLevel ||
        // Some users only enter one wage and expect (like it used to be in AudaFusion) that that value will be used for all three wage classes.
        feeSet.mechanics.firstLevel;

    //*****************************************************************************
    //  Transmit all three wages as entered by the user
    //****************************************************************************/
    /**
     * VW companies charge different fees for a small car (e.g. Audi A1) than for a luxury car (Audi R8).
     */
    if (doesManufacturerUseAllThreeWages({ manufacturer: manufacturerLowerCase, carShape })) {
        return {
            // Some users only enter the car body value and expect (like it used to be in AudaFusion) that the car body value will be used for all three wage classes.
            rate1: mechanicsWage,
            rate2: electricsWage,
            rate3: carBodyWage,
        };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Transmit all three wages as entered by the user
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Only 2 Wages (No Electric Wage)
    //****************************************************************************/
    if (doesManufacturerUseOnlyMechanicsAndCarBody({ manufacturer: manufacturerLowerCase, carShape })) {
        return {
            rate1: mechanicsWage,
            rate2: carBodyWage,
            rate3: null,
        };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Only 2 Wages (No Electric Wage)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Default
    //****************************************************************************/
    /**
     * Most manufacturers use only one wage class for everything.
     *
     * Transmit carBody instead of mechanics because according to Dennis Zittlau of TÜV Nord, damage repair
     * is mostly situated in the car body type of work, so the manufacturers recommend using the
     * highest wage class.
     */
    return {
        rate1: carBodyWage,
        rate2: null,
        rate3: null,
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default
    /////////////////////////////////////////////////////////////////////////////*/
}

/**
 * Our default is transmitting all three wage rates.
 *
 * Some manufacturers only use one wage rate or two wage rates.
 * This methods returns if a manufacturer requires a transmission format deviating from our default.
 */
export function isRemappingRequiredForAudatexWageRates({
    manufacturer,
    carShape,
}: {
    manufacturer: string;
    carShape: CarShape;
}): boolean {
    if (!manufacturer) return false;

    const manufacturerLowerCase = manufacturer.toLowerCase();

    return (
        doesManufacturerUseOnlyOneWage({ manufacturer: manufacturerLowerCase, carShape }) ||
        doesManufacturerUseOnlyMechanicsAndCarBody({ manufacturer: manufacturerLowerCase, carShape })
    );
}

function doesManufacturerUseAllThreeWages({
    manufacturer,
    carShape,
}: {
    manufacturer: string;
    carShape: CarShape;
}): boolean {
    if (
        manufacturer.includes('audi') ||
        manufacturer.includes('porsche') ||
        manufacturer.includes('seat') ||
        manufacturer.includes('skoda') ||
        manufacturer.includes('cupra') ||
        manufacturer.includes('vw') ||
        manufacturer.includes('volkswagen') ||
        manufacturer.includes('citroen') ||
        manufacturer.includes('dacia') ||
        manufacturer.includes('ds') ||
        manufacturer.includes('peugeot') ||
        manufacturer.includes('renault') ||
        manufacturer.includes('scania') ||
        manufacturer.includes('chrysler') ||
        manufacturer.includes('jeep') ||
        manufacturer.includes('bmw') ||
        manufacturer.includes('mini') ||
        manufacturer.includes('opel')
    ) {
        return true;
    }

    // Only the cars brand unit uses all three wages.
    if (manufacturer.includes('volvo') && !isCarSemiTruck(carShape)) {
        return true;
    }

    if (!manufacturer) return true;
}

/**
 * We use a black list instead of a white list because new manufacturers mostly come from Asia,
 * which mostly uses one wage rate. So, it's easier to maintain a black list and quicker for our
 * customers than waiting for us to add the new manufacturer to a white list.
 */
function doesManufacturerUseOnlyOneWage({
    manufacturer,
    carShape,
}: {
    manufacturer: string;
    carShape: CarShape;
}): boolean {
    return (
        !doesManufacturerUseAllThreeWages({ manufacturer, carShape }) &&
        !doesManufacturerUseOnlyMechanicsAndCarBody({ manufacturer, carShape })
    );
}

function doesManufacturerUseOnlyMechanicsAndCarBody({
    manufacturer,
    carShape,
}: {
    manufacturer: string;
    carShape: CarShape;
}): boolean {
    if (
        manufacturer.includes('mercedes-benz') ||
        manufacturer.includes('amg') ||
        manufacturer.includes('mercedes') ||
        manufacturer.includes('smart')
    ) {
        return true;
    }

    return manufacturer.includes('ford') && !isCarSemiTruck(carShape);
}

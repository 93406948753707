import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import {
    LeaseReturnCommentTemplate,
    LeaseReturnTemplate,
    LeaseReturnTemplateItem,
    LeaseReturnTemplateSection,
} from '@autoixpert/models/reports/damage-calculation/lease-return-template';
import { Report } from '@autoixpert/models/reports/report';
import { User } from '@autoixpert/models/user/user';
import { slideOutDialogVertical } from '../../../../shared/animations/slide-out-dialog-vertical.animation';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { LeaseReturnTemplateService } from '../../../../shared/services/lease-return-template.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { TextTemplateService } from '../../../../shared/services/textTemplate.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';

@Component({
    selector: 'lease-return-text-template-dialog',
    templateUrl: 'lease-return-text-template-dialog.component.html',
    styleUrls: ['lease-return-text-template-dialog.component.scss'],
    animations: [slideOutDialogVertical()],
})
export class LeaseReturnTextTemplateDialog {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private userPreferences: UserPreferencesService,
        private textTemplateService: TextTemplateService,
        private toastService: ToastService,
        private leaseReturnTemplateService: LeaseReturnTemplateService,
        private apiErrorService: ApiErrorService,
    ) {}

    private user: User;
    @Input() leaseReturnTemplateFromParent: LeaseReturnTemplate;
    @Input() textTemplates: LeaseReturnCommentTemplate[] = [];
    @Input() textTemplateTitle: string;
    public textTemplateInEditMode: LeaseReturnCommentTemplate;

    @Input() report: Report;
    @Output() close: EventEmitter<boolean> = new EventEmitter();

    // Copy Dialog
    public selectedTab:
        | 'templateList'
        | 'selectTemplatesToCopy'
        | 'selectTargetTemplates'
        | 'selectTargetItems'
        | 'setOptions' = 'templateList';
    public selectedTemplatesToCopy: LeaseReturnCommentTemplate[] = [];
    @Input() leaseReturnTemplates: LeaseReturnTemplate[];
    public selectedTargetTemplate: LeaseReturnTemplate;
    public selectedTargetTemplateItems: LeaseReturnTemplateItem[] = [];
    public removeTemplatesInTargetItemsBeforeCopying: boolean;

    private subscriptions: Subscription[] = [];

    ngOnInit() {
        this.filterAndSortTextTemplates();

        // Update the user in case it was updated in a different tab.
        this.loggedInUserService.getUser$().subscribe((user) => (this.user = user));
    }

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    /**
     * Close the entire windows when the user clicks outside of it on the black, partially transparent overlay.
     * @param event
     */
    public closeSelectorWithClickOnOverlay(event) {
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    public closeDialog() {
        this.close.emit(true);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter & Sort
    //****************************************************************************/
    public filterAndSortTextTemplates(): void {
        this.sortByDragOrder();
    }

    private sortByDragOrder(): void {
        this.textTemplates.sort((templateA, templateB) => {
            return +templateA.dragOrderPosition - +templateB.dragOrderPosition;
        });
    }

    public sortByTitle(): void {
        this.textTemplates.sort((templateA, templateB) => {
            return (templateA.body ?? '').localeCompare(templateB.body ?? '');
        });
        // Save order to server
        this.updateDragOrderPositionOnAllTemplates();

        this.filterAndSortTextTemplates();
    }

    /**
     * The component loads the templates from the server. But if the user changes text templates on
     * the LeaseReturnTemplate passed by the parent component, he doesn't see any updated because we
     * edit the version that came through the Service.find call.
     * Therefore, overwrite the reference by ID.
     */
    public mergeTemplateFromParent(): void {
        if (!this.leaseReturnTemplateFromParent) return;

        const matchingTemplate = this.leaseReturnTemplates.find(
            (template) => template._id === this.leaseReturnTemplateFromParent._id,
        );
        removeFromArray(matchingTemplate, this.leaseReturnTemplates);
        this.leaseReturnTemplates.unshift(this.leaseReturnTemplateFromParent);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter & Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Manage text templates
    //****************************************************************************/

    public addTextTemplate(): void {
        const newTextTemplate = new LeaseReturnCommentTemplate();

        this.enterEditMode(newTextTemplate);

        this.textTemplates.unshift(newTextTemplate);
        this.filterAndSortTextTemplates();

        this.saveLeaseReturnTemplate(this.leaseReturnTemplateFromParent);
    }

    public enterEditMode(textTemplate: LeaseReturnCommentTemplate): void {
        // Abort if already in edit mode.
        if (this.textTemplateInEditMode === textTemplate) return;

        // Leave edit mode of all other templates
        if (this.textTemplateInEditMode) {
            this.leaveEditMode(this.textTemplateInEditMode);
            this.saveTextTemplate(this.textTemplateInEditMode);
        }
        // Enter edit mode
        this.textTemplateInEditMode = textTemplate;
    }

    public saveTextTemplate(textTemplate: LeaseReturnCommentTemplate): void {
        // Don't save empty templates. This component only uses the body, not the title.
        if (!textTemplate.body) return;

        this.saveLeaseReturnTemplate(this.leaseReturnTemplateFromParent);
    }

    public leaveEditMode(textTemplate: LeaseReturnCommentTemplate): void {
        this.textTemplateInEditMode = null;
        // Remove empty templates
        if (!textTemplate.body) {
            this.deleteTextTemplate(textTemplate);
        }
    }

    public leaveEditModeOnShortcut(
        currentTextTemplate: LeaseReturnCommentTemplate,
        keyboardEvent: KeyboardEvent,
    ): void {
        if (keyboardEvent.key === 'Escape') {
            this.leaveEditMode(currentTextTemplate);
        }
        if (keyboardEvent.key === 'Enter' && (keyboardEvent.ctrlKey || keyboardEvent.metaKey)) {
            this.saveTextTemplate(currentTextTemplate);
            this.leaveEditMode(currentTextTemplate);
        }
    }

    public deleteTextTemplate(textTemplate: LeaseReturnCommentTemplate): void {
        const index: number = this.textTemplates.indexOf(textTemplate);
        this.textTemplates.splice(index, 1);
        this.filterAndSortTextTemplates();

        this.saveLeaseReturnTemplate(this.leaseReturnTemplateFromParent);

        // Don't warn the user about having deleted an empty text block
        if (!textTemplate.body) return;

        const restorationToast = this.toastService.info('Textvorlage gelöscht', 'Zum Wiederherstellen hier klicken.', {
            showProgressBar: true,
            timeOut: 10000,
        }); //RestorationToast
        this.subscriptions.push(
            restorationToast.click.subscribe(() => {
                this.restoreTextTemplate(textTemplate, index);
            }),
        );
    }

    private restoreTextTemplate(textTemplate: LeaseReturnCommentTemplate, index: number): void {
        // Re-create locally
        this.textTemplates.splice(index, 0, textTemplate);
        this.filterAndSortTextTemplates();
        this.saveLeaseReturnTemplate(this.leaseReturnTemplateFromParent);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Manage text templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Drag & Drop
    //****************************************************************************/
    public handleReorderingDrop(event: CdkDragDrop<LeaseReturnCommentTemplate>): void {
        // Extract from the array...
        const textTemplate = this.textTemplates.splice(event.previousIndex, 1)[0];
        // ... and insert at new position.
        this.textTemplates.splice(event.currentIndex, 0, textTemplate);

        // Save order to server
        this.updateDragOrderPositionOnAllTemplates();

        this.filterAndSortTextTemplates();
    }

    private updateDragOrderPositionOnAllTemplates(): void {
        for (const [key, messageTemplate] of this.textTemplates.entries()) {
            const oldPosition: number = messageTemplate.dragOrderPosition;
            messageTemplate.dragOrderPosition = key;

            // Send updates to server
            if (messageTemplate.dragOrderPosition !== oldPosition) {
                this.saveTextTemplate(messageTemplate);
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Drag & Drop
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Copy Dialog
    //****************************************************************************/
    public selectTab(tab: this['selectedTab']): void {
        this.selectedTab = tab;
    }

    public toggleSelectTextTemplateToCopy(textTemplate: LeaseReturnCommentTemplate): void {
        if (this.selectedTemplatesToCopy.includes(textTemplate)) {
            const index = this.selectedTemplatesToCopy.indexOf(textTemplate);
            this.selectedTemplatesToCopy.splice(index, 1);
        } else {
            this.selectedTemplatesToCopy.push(textTemplate);
        }
    }

    public selectAllTextTemplatesToCopy(): void {
        this.selectedTemplatesToCopy = [...this.textTemplates];
    }

    public selectLeaseReturnTemplate(leaseReturnTemplate: LeaseReturnTemplate): void {
        this.selectedTargetTemplate = leaseReturnTemplate;
    }

    public areAllItemsInTemplateSelected(templateSection: LeaseReturnTemplateSection): boolean {
        return templateSection.items.every((item) => this.selectedTargetTemplateItems.includes(item));
    }

    public areSomeItemsInTemplateSelected(templateSection: LeaseReturnTemplateSection): boolean {
        return templateSection.items.some((item) => this.selectedTargetTemplateItems.includes(item));
    }

    public toggleSelectItemsOfSection(templateSection: LeaseReturnTemplateSection): void {
        // Either remove all...
        if (this.areAllItemsInTemplateSelected(templateSection)) {
            templateSection.items.forEach((item) => {
                const index = this.selectedTargetTemplateItems.indexOf(item);
                if (index > -1) {
                    this.selectedTargetTemplateItems.splice(index, 1);
                }
            });
        }
        // ... or add all
        else {
            templateSection.items.forEach((item) => {
                if (!this.selectedTargetTemplateItems.includes(item)) {
                    this.selectedTargetTemplateItems.push(item);
                }
            });
        }
    }

    public toggleSelectTemplateItem(templateItem: LeaseReturnTemplateItem): void {
        if (this.selectedTargetTemplateItems.includes(templateItem)) {
            const index = this.selectedTargetTemplateItems.indexOf(templateItem);
            this.selectedTargetTemplateItems.splice(index, 1);
        } else {
            this.selectedTargetTemplateItems.push(templateItem);
        }
    }

    public copyTextTemplatesToTarget(): void {
        for (const targetItem of this.selectedTargetTemplateItems) {
            // Remove old templates before adding new ones...
            if (this.removeTemplatesInTargetItemsBeforeCopying) {
                targetItem.commentTemplates = [...this.selectedTemplatesToCopy];
            }
            //... or simply append.
            else {
                targetItem.commentTemplates.push(...this.selectedTemplatesToCopy);
            }
        }

        this.saveLeaseReturnTemplate(this.selectedTargetTemplate);
        this.toastService.success(
            'Vorlagen kopiert',
            'Die Textvorlagen sind nun auf jeder ausgewählten Position verfügbar.',
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public loadLeaseReturnTemplates(forceLoad?: boolean): void {
        // Don't reload if the parent passed the templates.
        if (!forceLoad && this.leaseReturnTemplates) {
            return;
        }

        this.leaseReturnTemplateService.find().subscribe({
            next: (templates) => {
                this.leaseReturnTemplates = templates;
                this.mergeTemplateFromParent();
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Leasing-Rückläufer-Vorlagen nicht geladen',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    public saveLeaseReturnTemplate(leaseReturnTemplate: LeaseReturnTemplate): void {
        this.leaseReturnTemplateService.put(leaseReturnTemplate);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event) {
        switch (event.key) {
            case 'Escape':
                // Make sure saving is triggered if the user is still inside an input.
                if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                    (document.activeElement as HTMLElement).blur();
                }
                this.close.emit(true);
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    @HostBinding('@slideOutDialogVertical') get slideOutDialogVertical() {
        return true;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'action-toolbar-item',
    templateUrl: 'action-toolbar-item.component.html',
    styleUrls: ['action-toolbar-item.scss'],
})
export class ActionToolbarItemComponent {
    @Input() label: string = '';
    @Input() iconName: string = '';
    @Output() wasClicked: EventEmitter<void> = new EventEmitter();
}

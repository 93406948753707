import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LegacyFloatLabelType as FloatLabelType } from '@angular/material/legacy-form-field';
import { generateId } from '@autoixpert/lib/generate-id';
import { round } from '@autoixpert/lib/numbers/round';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';

@Component({
    selector: 'currency-input',
    templateUrl: 'currency-input.component.html',
    styleUrls: ['currency-input.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class CurrencyInputComponent {
    @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;

    @Input() placeholder: string;
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() name: string = generateId();
    @Input() warning: string = null;
    @Input() hint: string = null;
    @Input() lightMode: boolean;
    @Input() ghostUntilHover: boolean;
    @Input() disabled: boolean = false;
    @Input() readonly: boolean = false;
    @Input() baseValue: number; // If a base value is set, the user may enter "50%" and the field displays 50% of the base value.
    @Input() autofocus: boolean; // This must be named differently from the axAutofocus directive to avoid an injector error when Angular tries to inject the HTMLInput element that currency-input is not. It only has an Input element as descendant.
    @Input() tabindex: number;
    @Input() tooltip: string;
    @Input() preventNegativeNumbers: boolean = false;

    /**
     * Translate the value of type number to a string in German locale format. This adds a comma instead of a dot
     * as the decimal delimiter and adds a dot as a thousands separator.
     * @param givenValue
     */
    @Input('value') set valueFromParent(givenValue: number) {
        if (givenValue !== null && givenValue !== undefined) {
            this.valueForChildInput = givenValue.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        } else {
            this.valueForChildInput = null;
        }
    }

    /**
     * Set a default value that's displayed if the user has not entered his own value.
     * @param givenValue
     */
    @Input('defaultValue') set defaultValueFromParent(givenValue: number) {
        if (givenValue !== null && givenValue !== undefined) {
            this.defaultValue = givenValue.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        } else {
            this.defaultValue = null;
        }
    }

    valueForChildInput: string;
    defaultValue: string;
    isInputFocused: boolean;

    @Output() valueChange: any = new EventEmitter();

    /**
     * ElementRef is exposed so that data-attributes on this
     * component can be read from outside.
     */
    constructor(public elementRef: ElementRef) {}

    /**
     * Parses and formats the string from the input field, and emits the number as type number.
     * @param event
     */
    public formatNumber(event) {
        let numberString: string = event.target.value;

        // Empty string is treated as null to stay within the number realm.
        if (numberString === '') {
            this.valueChange.emit(null);
            return;
        }

        // Replace all thousand delimiters (dots) in German numbers.
        numberString = numberString.replace(/\./g, '');

        // Replace the decimal delimiter (comma) in German numbers with a dot.
        numberString = numberString.replace(',', '.');

        if (this.baseValue && numberString.includes('%')) {
            const relativeValue: number = round(this.baseValue * (parseFloat(numberString) / 100));
            this.valueChange.emit(relativeValue);
            return;
        }

        // Convert type string to type number.
        let parsedNumber: number = parseFloat(numberString);
        if (isNaN(parsedNumber)) {
            parsedNumber = 0;
        }
        let number: number = round(parsedNumber);

        if (this.preventNegativeNumbers && number < 0) {
            number = Math.abs(number);
        }

        this.valueChange.emit(number);
    }

    public markAsFocused(): void {
        this.isInputFocused = true;
    }

    public unmarkAsFocused(): void {
        this.isInputFocused = false;
    }

    public focus() {
        this.inputElement.nativeElement.focus();
    }
}

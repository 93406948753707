<div class="report-type-container" *ngIf="showLiability || reportType !== 'liability'" [ngSwitch]="reportType">
    <span class="report-type-abbreviation" *ngSwitchCase="'liability'" matTooltip="Haftpflichtschaden">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
    <span class="report-type-abbreviation" *ngSwitchCase="'shortAssessment'" matTooltip="Kurzgutachten">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
    <span class="report-type-abbreviation" *ngSwitchCase="'partialKasko'" matTooltip="Teilkaskoschaden">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
    <span class="report-type-abbreviation" *ngSwitchCase="'fullKasko'" matTooltip="Vollkaskoschaden">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
    <span class="report-type-abbreviation" *ngSwitchCase="'valuation'" matTooltip="Bewertung">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
    <span class="report-type-abbreviation" *ngSwitchCase="'leaseReturn'" matTooltip="Zustandsbericht">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
    <span class="report-type-abbreviation" *ngSwitchCase="'usedVehicleCheck'" matTooltip="Gebrauchtwagen-Check">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
    <span class="report-type-abbreviation" *ngSwitchCase="'oldtimerValuationSmall'" matTooltip="Oldtimergutachten">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
    <span class="report-type-abbreviation" *ngSwitchCase="'invoiceAudit'" matTooltip="Rechnungsprüfung">
        {{ getReportTypeAbbreviation(reportType) }}
    </span>
</div>

import moment from 'moment';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { RecordMigrations } from '@autoixpert/models/indexed-db/database.types';
import { FeeTableName } from '@autoixpert/models/reports/assessors-fee/fee-calculation';
import { AccountStatus } from '@autoixpert/models/teams/account-status';
import { Team } from '@autoixpert/models/teams/team';

export const teamRecordMigrations: RecordMigrations<Team> = {
    /**
     * Remove field "preferences.hasCustomFeeSet"
     * teams can have multiple custom fee sets which are stored in the user objects (AX-2862)
     *
     * Allow multiple invoice number and report token configs
     */
    '3': (team: Team): Team => {
        // Multiple fee sets
        delete (team.preferences as any).hasCustomFeeSet;

        // Multiple invoice number counters & multiple report token counters
        delete (team.invoicing as any).pattern;
        delete (team.invoicing as any).pattern;
        delete (team.invoicing as any).useCancellationInvoiceSuffix;
        delete (team.invoicing as any).cancellationSuffix;
        delete (team.invoicing as any).useCancellationInvoiceSuffixWithNewCounter;
        delete (team as any).reportTokenSettings;
        /**
         * Create empty arrays to prevent type errors when accessing these arrays. Since the objects are created with a unique ID on the server-side,
         * do not try to recreate those objects client-side. That would result in a different ID and, thus, two objects where only one is needed.
         * These arrays will be filled during the next sync between client and server.
         */
        team.invoicing.invoiceNumberConfigs = [];
        team.reportTokenConfigs = [];
        return team;
    },
    '4': (team: Team): Team => {
        team.accountStatus = getAccountStatus(team);
        delete (team as any).isTestAccount;

        team.audatexFeatures.hasAudatexAddon = (team.audatexFeatures as any).interfaces;

        delete (team.preferences as any).documentOrders;
        delete (team.permanentUploadedDocuments as any).included;

        return team;
    },
    '5': (team: Team): Team => {
        delete (team.preferences as any).useNetValuesForDiminishedValueForBusinesses;
        delete (team.preferences as any).isInfoDismissed_useNetValuesForDiminishedValueForBusinesses;

        return team;
    },
    '6': (
        team: Team & {
            preferences: {
                isInfoDismissed_activateBiddersWithNoBidForCustomResidualValueSheet: any;
                setupSteps: any;
                isInfoDismissed_activateFeeSetOnDeclarationOfAssignment: any;
                submittedProfessionalAssociations: any;
                welcomeSplashScreenClosed: any;
                isInfoNoteVisible_setStatusOnShortPayments: any;
            };
        },
    ): Team => {
        //*****************************************************************************
        // Invoice Numbers per Report
        //****************************************************************************/

        // Delete unused property useCancellationInvoiceSuffixWithNewCounter
        for (const config of team.invoicing.invoiceNumberConfigs) {
            delete (config as any).useCancellationInvoiceSuffixWithNewCounter;
        }

        let generateTokenOnCreate = false;
        for (const reportTokenConfig of team.reportTokenConfigs) {
            // The leading counter of report token configs that are currently "synced with invoice counter"
            // is always the invoice number (report token was not possible yet). So we save that explicitly.
            if (reportTokenConfig.syncWithInvoiceCounter) {
                reportTokenConfig.leadingCounter = 'invoiceNumber';
            }

            if ((reportTokenConfig as any).generateTokenOnCreate) {
                // If at least one report token counter had the setting createTokenOnCreate -> enable new global setting
                generateTokenOnCreate = true;
            }

            delete (reportTokenConfig as any).generateTokenOnCreate;
        }

        if (generateTokenOnCreate) {
            team.preferences.generateReportTokenOnCreate = true;
        }

        //*****************************************************************************
        // End Invoice Numbers per Report
        //****************************************************************************/

        //*****************************************************************************
        // Extract UserInterfaceStates
        //****************************************************************************/

        // These were moved to the team.userInterfaceStatus object. Only delete the removed path and fetch the new one from the server on the next sync.
        // Complementing backend migration 20241106205300.extract-info-note-and-tutorial-fields-from-team-preferences.ts
        delete team.preferences.isInfoDismissed_activateBiddersWithNoBidForCustomResidualValueSheet;
        delete team.preferences.setupSteps;
        delete team.preferences.isInfoDismissed_activateFeeSetOnDeclarationOfAssignment;
        delete team.preferences.submittedProfessionalAssociations;
        delete team.preferences.welcomeSplashScreenClosed;

        // This property was removed completely (not used anymore)
        delete team.preferences.isInfoNoteVisible_setStatusOnShortPayments;

        //*****************************************************************************
        // End Extract UserInterfaceStates
        //****************************************************************************/

        return team;
    },
    '7': (
        team: Team & {
            preferences: {
                defaultFeeTable: FeeTableName;
                defaultCustomFeeTableId: string;
            };
        },
    ): Team => {
        // Complementing backend migration migration_20250320_0900_renameDefaultFeeTableProperties

        team.preferences.defaultFeeTableLiability = team.preferences.defaultFeeTable;
        delete team.preferences.defaultFeeTable;

        team.preferences.defaultCustomFeeTableIdLiability = team.preferences.defaultCustomFeeTableId;
        delete team.preferences.defaultCustomFeeTableId;

        return team;
    },
    '8': (team): Team => {
        team.permanentUploadedDocuments?.forEach((document: DocumentMetadata & { markAsDuplicate: any }) => {
            delete document.markAsDuplicate;
        });

        return team;
    },
};

//*****************************************************************************
//  Legacy Get Account Status
//****************************************************************************/
function getAccountStatus(team: Team): AccountStatus {
    const now = moment();
    const isExpirationDateInPast: boolean = !!(team.expirationDate && moment(team.expirationDate).isBefore(now)); // Don't use day granularity to allow account management to deactivate a user today (subtracting only one minute from now)
    const hasBecomeCustomer: boolean =
        team.becameCustomerAt && moment(team.becameCustomerAt).isSameOrBefore(now, 'day'); // Has he become customer today or any day before?
    const isCustomerAfterExpiration: boolean =
        team.becameCustomerAt &&
        team.expirationDate &&
        moment(team.becameCustomerAt).isSameOrAfter(team.expirationDate, 'day'); // Has the switch to customer happened on the same day or after the account expired? (Possible if people suspend their account and start at a later date)
    const willBecomeCustomer: boolean = team.becameCustomerAt && moment(team.becameCustomerAt).isAfter(now, 'day'); // Will the user become customer tomorrow or any day later?

    /**
     * Deactivated
     * Options:
     * A) Expiration date has passed. He has never and will never become customer (no date present).
     * B) Expiration date has passed, but he was a customer before that.
     */
    if (
        (isExpirationDateInPast && !hasBecomeCustomer && !willBecomeCustomer) ||
        (isExpirationDateInPast && !isCustomerAfterExpiration)
    ) {
        return 'deactivated';
    }

    /**
     * Paying in the future
     * Options:
     * A) He has expired, but will become customer (expired Nov 15 but wants to start Dec 1)
     */

    if (isExpirationDateInPast && willBecomeCustomer) {
        return 'deactivatedButPayingInFuture';
    }

    /**
     * Paying
     * Options:
     * A) Became customer and expiration date does not exist.
     * B) Became customer and expiration date is in the future (phasing out).
     * C) Became customer and there still is an expiration date, but that happened before he became customer, e.g. after the test account ended.
     */
    if (hasBecomeCustomer && (!team.expirationDate || !isExpirationDateInPast || isCustomerAfterExpiration)) {
        return 'paying';
    }

    // Partner
    if (!hasBecomeCustomer && !willBecomeCustomer && !team.expirationDate) {
        return 'partner';
    }

    /**
     * If none of the other account statuses match, this must be a tester.
     */
    return 'test';
}
/////////////////////////////////////////////////////////////////////////////*/
//  END Legacy Get Account Status
/////////////////////////////////////////////////////////////////////////////*/

import { Observable } from 'rxjs';
import { Report } from '@autoixpert/models/reports/report';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';

export abstract class AbstractReportService {
    public abstract get(recordId: Report['_id']): Promise<Report>;

    public abstract searchReportsWithoutPagination({
        $search,
        from,
        to,
        sortBy,
        sortDescending,
        $limit,
    }: {
        $search?: string;
        from?: string;
        to?: string;
        sortBy?: UserPreferences['sortReportListBy'];
        sortDescending?: UserPreferences['sortReportListDescending'];
        $limit?: number;
    }): Observable<Report[]>;
}

import { Pipe, PipeTransform } from '@angular/core';
import { PaintThicknessScaleRangeDefinition } from '@autoixpert/models/reports/paint-thickness-scale';

/**
 * Returns the label for the paint thickness scale. E.g. '< 70', '>= 70', '> 150' etc.
 */
@Pipe({
    name: 'paintThicknessRangeLabel',
    pure: false,
})
export class PaintThicknessRangeLabelPipe implements PipeTransform {
    transform(rangeDef: PaintThicknessScaleRangeDefinition, index: number, omitValue: boolean = false): unknown {
        switch (index) {
            case 0:
                return omitValue ? '<' : `< ${rangeDef.maxValue + 1}`;
            case 1:
                return omitValue ? '>=' : `>= ${rangeDef.minValue}`;
            default:
                return omitValue ? '>' : `> ${rangeDef.minValue - 1}`;
        }
    }
}

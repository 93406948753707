import { Invoice, InvoiceInvolvedParty, invoiceInvolvedPartyRoles } from '@autoixpert/models/invoices/invoice';

export function getInvolvedPartiesFromInvoice({ invoice }: { invoice: Invoice }): InvoiceInvolvedParty[] {
    const involvedParties: InvoiceInvolvedParty[] = [];

    for (const involvedPartyRole of invoiceInvolvedPartyRoles) {
        const involvedParty: InvoiceInvolvedParty =
            invoice[involvedPartyRole === 'invoiceRecipient' ? 'recipient' : involvedPartyRole];
        if (involvedParty) {
            involvedParties.push(involvedParty);
        }
    }

    return involvedParties;
}

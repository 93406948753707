import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { LegacyReport } from '@autoixpert/models/imports/legacy-report';

@Injectable()
export class LegacyReportService {
    constructor(private httpClient: HttpClient) {}

    public find({ licensePlate, vin }: { licensePlate?: string; vin?: string }): Observable<LegacyReport[]> {
        let queryParams = new HttpParams();

        if (licensePlate) {
            queryParams = queryParams.append('licensePlate', licensePlate);
        }

        if (vin) {
            queryParams = queryParams.append('vin', vin);
        }

        return this.httpClient.get<LegacyReport[]>(`${apiBasePath}/legacyReports`, {
            params: queryParams,
        });
    }
}

/**
 * We use this function to compare different addresses (e.g. billing and office locations)
 * This could be enhanced using google maps places api.
 * @param zip
 * @param streetAndHouseNumber
 * @param city
 */
export function createCompareableAddressString({
    zip = '',
    streetAndHouseNumber = '',
    city = '',
}: {
    zip?: string;
    streetAndHouseNumber?: string;
    city?: string;
}) {
    return `${streetAndHouseNumber}${zip}${city}`.toLowerCase().replaceAll(/\s/g, ''); // remove white spaces
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { isEqualAx } from '@autoixpert/lib/is-equal-ax';
import { CustomField } from '@autoixpert/models/custom-fields/custom-field';
import { CustomFieldGroup } from '@autoixpert/models/custom-fields/custom-field-group';
import { CustomFieldLocation } from '@autoixpert/models/custom-fields/custom-field-location';
import { FieldConfig } from '@autoixpert/models/custom-fields/field-config';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { Report } from '@autoixpert/models/reports/report';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';
import { trackById } from '../../../../shared/libraries/track-by-id';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { CustomFieldButtonTriggerService } from '../../../../shared/services/custom-field-button-trigger.service';
import { FieldGroupConfigService } from '../../../../shared/services/field-group-config.service';
import { NetworkStatusService } from '../../../../shared/services/network-status.service';
import { ToastService } from '../../../../shared/services/toast.service';

/**
 * Component to display the configured custom fields within other components.
 *
 * To edit a custom field config, use the CustomFieldEditorDialogComponent.
 */
@Component({
    selector: 'custom-field-display',
    templateUrl: 'custom-field-display.component.html',
    styleUrls: ['custom-field-display.component.scss'],
    host: {
        '[class.display-background-color]': 'this.displayBackgroundColor',
        '[class.use-negative-side-margins]': 'this.useNegativeSideMarginForCards',
        '[class.use-negative-margin-bottom]': 'this.useNegativeBottomMarginForCards',
    },
    animations: [runChildAnimations()],
})
export class CustomFieldDisplayComponent implements OnInit {
    constructor(
        public fieldGroupConfigService: FieldGroupConfigService,
        private apiErrorService: ApiErrorService,
        private networkStatusService: NetworkStatusService,
        private toastService: ToastService,
        private dialogService: MatDialog,
        private customFieldButtonTriggerService: CustomFieldButtonTriggerService,
    ) {}

    @Input() report: Report;
    @Input() fieldLocation: CustomFieldLocation;
    @Input() displayBackgroundColor: boolean = true;
    /**
     * If the parent has the CSS class .card, apply a negative margin left and right to make the background-color stretch to the edge of the parent.
     */
    @Input() useNegativeSideMarginForCards: boolean = true;
    /**
     * If the parent has the CSS class .card, apply a negative margin bottom to make the background-color stretch to the edge of the parent.
     * Used only if this component is the last element on the card. Otherwise, the negative margin would make it stretch into the following element.
     */
    @Input() useNegativeBottomMarginForCards: boolean = true;
    @Input() disabled: boolean;

    @Output() reportChange: EventEmitter<Report> = new EventEmitter();

    /**
     * Custom fields on this report at the given fieldLocation.
     */
    public get customFields(): CustomField[] {
        return this.getCustomFieldGroupFromReport()?.customFields ?? [];
    }

    /**
     * Team-wide configuration of custom fields in this location within the report.
     *
     * May be null if the team has not yet configured a field group.
     */
    public fieldGroupConfig: FieldGroupConfig;

    // Which custom field config shall be pre-selected in the edit dialog?
    public fieldConfigForEditDialog: FieldConfig;

    public isFieldConfigDialogShown: boolean;

    // Text Templates for multi-line fields
    // For which field is the textTemplateSelector visible?
    public textTemplateSelectorShown: FieldConfig['_id'];

    public readonly DEFAULT_FIELD_NAME: string = 'Eigenes Feld ohne Titel';

    protected buttonIsLoading = false;
    protected showButtonSuccess = false;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        await this.loadFieldGroupConfig();
        this.syncGlobalFieldsToReport();
    }

    private async loadFieldGroupConfig() {
        const allFieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        this.fieldGroupConfig = allFieldGroupConfigs.find(
            (fieldGroupConfig) => fieldGroupConfig.fieldLocation === this.fieldLocation,
        );
    }

    /**
     * If a group is missing for the currently displayed field group, insert it. Unless the report is locked.
     *
     * This can only happen if a field *config* group has been added after this report was initialized.
     * All existing custom field config groups are translated to custom field groups on creation of a report.
     *
     * @returns The new custom field group or null if none has been created.
     */
    private initializeCustomFieldGroup(): CustomFieldGroup {
        if (this.disabled) return;

        const matchingFieldGroup = this.getCustomFieldGroupFromReport();
        if (!matchingFieldGroup) {
            const newCustomFieldGroup = new CustomFieldGroup({
                fieldLocation: this.fieldLocation,
            });
            this.report.customFieldGroups.push(newCustomFieldGroup);
            this.emitReportChange();
            return newCustomFieldGroup;
        }
    }

    /**
     * Add field group config
     * - to memory cache
     * - on the server/indexedDB
     *
     */
    private async initializeFieldGroupConfig() {
        const newFieldGroupConfig = new FieldGroupConfig({
            fieldLocation: this.fieldLocation,
        });

        await this.fieldGroupConfigService.create(newFieldGroupConfig);
        this.fieldGroupConfig = newFieldGroupConfig;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CRUD Field Configs
    //****************************************************************************/
    /**
     * Custom fields are never directly created. Workflow:
     * 1) Create field group config (this method).
     * 2) Sync custom fields on report with global field group configs.
     */
    public async createFieldConfig() {
        // Create fieldGroupConfig if non-existant yet.
        if (!this.fieldGroupConfig) {
            await this.initializeFieldGroupConfig();
        }

        // Create custom field group on report if it doesn't exist.
        const customFieldGroup = this.getCustomFieldGroupFromReport();
        if (!customFieldGroup) {
            this.initializeCustomFieldGroup();
        }

        const newConfig: FieldConfig = new FieldConfig({
            reportTypes: [this.report.type],
        });

        this.fieldGroupConfig.fieldConfigs.push(newConfig);
        this.saveFieldGroupConfig();

        // Add to local report as well
        this.syncGlobalFieldsToReport();

        this.fieldConfigForEditDialog = newConfig;
        this.isFieldConfigDialogShown = true;
    }

    /**
     * For a given custom field, get its associated config.
     * @param customField
     */
    public getFieldConfig(customField: CustomField): FieldConfig {
        return this.fieldGroupConfig?.fieldConfigs.find(
            (customFieldConfig) => customFieldConfig._id === customField.fieldConfigId,
        );
    }

    public editCustomFieldConfig(customField: CustomField) {
        const matchingCustomFieldConfig = this.getFieldConfig(customField);
        if (!matchingCustomFieldConfig) {
            this.toastService.error(
                'Globales Feld bereits gelöscht',
                'Du kannst die Einstellungen für dieses Feld nicht ändern, weil es global bereits gelöscht wurde. Es existiert nur noch auf alten Gutachten, wo du es manuell löschen kannst.',
            );
            return;
        }

        this.fieldConfigForEditDialog = this.getFieldConfig(customField);
        this.isFieldConfigDialogShown = true;
    }

    /**
     * Delete a global custom field config associated with a field.
     *
     * Remove the custom field on this report.
     */
    public async deleteFieldConfig(customField: CustomField) {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Felder löschen nur online möglich',
                'Eine Online-Verbindung wird benötigt, damit die Felder von allen Gutachten in deinem Team gelöscht werden können.',
            );
            return;
        }

        // Shorthand
        const fieldConfigs: FieldConfig[] = this.fieldGroupConfig.fieldConfigs;

        const decision = await this.dialogService
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Feld global löschen?',
                    content:
                        'Das Feld wird für alle Gutachten gelöscht. Das ist nicht widerrufbar.\n\nFalls du dieses Feld in Bedingungen von Textbausteinen verwendet hast, musst du diese anschließend auch entfernen.',
                    confirmLabel: 'Löschen',
                    cancelLabel: 'Behalten',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();

        if (!decision) return;

        const fieldConfig = this.getFieldConfig(customField);

        // Delete field config, so that it won't be inserted again on new reports.
        removeFromArray(fieldConfig, fieldConfigs);
        // Save the updated field group config that does not contain the removed field anymore.
        this.saveFieldGroupConfig();

        // Delete field from this report
        this.deleteCustomField(customField);

        //*****************************************************************************
        //  Remove from All Reports
        //****************************************************************************/
        // Remove the custom field from all other reports owned by the logged-in user's team.
        try {
            const result = await this.fieldGroupConfigService.removeCustomFieldFromAllReports(fieldConfig._id);
            this.toastService.success(
                `Feld global entfernt`,
                `${fieldConfig.name ? `Das Feld "${fieldConfig.name}"` : `Das Feld`} wurde von ${
                    result.numberOfReportsFromWhichCustomFieldWasRemoved
                } weiteren Gutachten entfernt.`,
            );
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    MISSING_CUSTOM_FIELD_CONFIG_ID: {
                        title: 'ID des eigenen Feldes fehlt',
                        body: 'Das ist ein technisches Problem. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                },
                defaultHandler: {
                    title: 'Eigenes Feld nicht global entfernt',
                    body: 'Das eigene Feld konnte nicht von allen Gutachten entfernt werden. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Remove from All Reports
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Field Configs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CRUD Custom Fields
    //****************************************************************************/
    /**
     * This method is a getter instead of a static property to immediately reflect
     * updates to the report through websockets.
     */
    private getCustomFieldGroupFromReport(): CustomFieldGroup {
        return this.report.customFieldGroups.find(
            (customFieldGroups) => customFieldGroups.fieldLocation === this.fieldLocation,
        );
    }

    /**
     * Delete a custom field (on this report) for which a config does not exist any more.
     * @param customField
     */
    public deleteCustomField(customField: CustomField) {
        // Delete custom field in this report.
        removeFromArray(customField, this.getCustomFieldGroupFromReport().customFields);
        this.emitReportChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Custom Fields
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Text Templates for Multi-line Fields
    //****************************************************************************/
    public showTextTemplates(fieldConfigId: FieldConfig['_id']) {
        this.textTemplateSelectorShown = fieldConfigId;
    }

    public hideTextTemplates() {
        this.textTemplateSelectorShown = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Text Templates for Multi-line Fields
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Field Config Dialog
    //****************************************************************************/
    public closeCustomFieldConfigDialog() {
        this.isFieldConfigDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Field Config Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sync Custom Field Configs With Report
    //****************************************************************************/
    /**
     * Add all custom fields configured team-wide to the current report.
     * - Don't remove any existing fields.
     * @private
     */
    public syncGlobalFieldsToReport() {
        if (this.disabled) return;

        // If no field group config exists, there's no need to sync.
        if (!this.fieldGroupConfig) return;

        // If no fields must be inserted for the current report type, abort.
        if (
            !this.fieldGroupConfig.fieldConfigs.filter((fieldConfig) =>
                fieldConfig.reportTypes.includes(this.report.type),
            ).length
        )
            return;

        // Attached to the current report.
        let customFieldGroup: CustomFieldGroup = this.getCustomFieldGroupFromReport();
        // Fail gracefully. Just because a custom field group is missing does not mean the user must be prevented from editing the report anymore due to JavaScript errors.
        if (!customFieldGroup) {
            customFieldGroup = this.initializeCustomFieldGroup();
        }
        const localFields = customFieldGroup.customFields;

        // Update existing fields and update missing ones.
        for (const customFieldConfig of this.fieldGroupConfig.fieldConfigs) {
            // Fields may be configured to be used in certain report types only.
            if (!customFieldConfig.reportTypes.includes(this.report.type)) {
                continue;
            }

            let matchingLocalField: CustomField = localFields.find(
                (localField) => localField.fieldConfigId === customFieldConfig._id,
            );

            // Add missing fields.
            if (!matchingLocalField) {
                matchingLocalField = new CustomField({
                    fieldConfigId: customFieldConfig._id,
                });
                localFields.push(matchingLocalField);
            }

            // Update existing fields. We update all properties that might have changed in the global config.
            matchingLocalField.name = customFieldConfig.name;
            // Keep existing value.
            matchingLocalField.value = matchingLocalField.value ?? customFieldConfig.defaultValue;
        }

        // Sort fields according to sorting of custom field configs
        const customFieldIdsBeforeSort: CustomField['_id'][] = this.getCustomFieldGroupFromReport().customFields.map(
            (customField) => customField._id,
        );
        this.getCustomFieldGroupFromReport().customFields.sort((customFieldA, customFieldB) => {
            const customFieldConfigA = this.getFieldConfig(customFieldA);
            const customFieldConfigB = this.getFieldConfig(customFieldB);

            // If a field config has been deleted, leave the field in-place.
            if (!customFieldConfigA || !customFieldConfigB) {
                return 0;
            }

            const indexA = this.fieldGroupConfig.fieldConfigs.indexOf(customFieldConfigA);
            const indexB = this.fieldGroupConfig.fieldConfigs.indexOf(customFieldConfigB);

            return indexA < indexB ? -1 : 1;
        });
        const customFieldIdsAfterSort: CustomField['_id'][] = this.getCustomFieldGroupFromReport().customFields.map(
            (customField) => customField._id,
        );

        // Save in case of changes in sort order.
        if (!isEqualAx(customFieldIdsBeforeSort, customFieldIdsAfterSort)) {
            this.emitReportChange();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sync Custom Field Configs With Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Button Click
    //****************************************************************************/
    public async handleButtonClick(customField: CustomField) {
        if (this.buttonIsLoading || this.showButtonSuccess) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                `${customField.name} nur online möglich`,
                `Es wird eine Online-Verbindung benötigt, um diese Aktion auszuführen.`,
            );
            return;
        }

        const fieldConfig = this.getFieldConfig(customField);
        if (!fieldConfig.webhookUrl || !fieldConfig.webhookEventType) {
            this.toastService.error(
                'Button nicht konfiguriert',
                'Bitte konfiguriere diesen Button in der Feld-Konfiguration.',
            );
            return;
        }

        this.buttonIsLoading = true;

        try {
            const startTime = Date.now();
            const response = await this.customFieldButtonTriggerService
                .triggerButton(this.report._id, fieldConfig.webhookUrl, fieldConfig.webhookEventType)
                .toPromise();

            if (!response.success) {
                throw new Error(response.error || 'Unbekannter Fehler');
            }

            // Show loading spinner for at least 1 second.
            const duration = Math.max(0, 1000 - (Date.now() - startTime));
            await new Promise((resolve) => setTimeout(resolve, duration));

            this.buttonIsLoading = false;
            this.showButtonSuccess = true;
            setTimeout(() => {
                this.showButtonSuccess = false;
            }, 1000);
        } catch (error) {
            this.buttonIsLoading = false;
            this.toastService.error(
                `Fehler bei ${fieldConfig.name}`,
                `Es ist ein Fehler aufgetreten: ${error.message}`,
            );
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Button Click
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Change Events
    //****************************************************************************/
    public emitReportChange() {
        this.reportChange.emit(this.report);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Change Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public saveFieldGroupConfig() {
        this.fieldGroupConfigService.put(this.fieldGroupConfig);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public trackById = trackById;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/
}

<div class="card dark-card on-dark-background">
    <div class="padding-wrapper">
        <h3 class="no-padding-bottom">Händler-EK</h3>
        <div class="taxation-note label">
            <ng-container *ngIf="mayCarOwnerDeductTaxes">
                Mehrwertsteuer ausweisbar
                <span
                    class="help-indicator"
                    matTooltip="Da der Anspruchsteller vorsteuerabzugsberechtigt ist, wird die Mehrwertsteuer beim Verkauf des Fahrzeugs ausgewiesen.">
                    ?
                </span>
            </ng-container>
            <ng-container *ngIf="!mayCarOwnerDeductTaxes">
                Mehrwertsteuer nicht ausweisbar
                <span
                    class="help-indicator"
                    matTooltip="Da der Anspruchsteller nicht vorsteuerabzugsberechtigt ist, kann beim Verkauf keine Mehrwertsteuer ausgewiesen werden.">
                    ?
                </span>
            </ng-container>
        </div>
        <net-and-gross-currency-input
            [lightMode]="true"
            label="Grundwert (EK)"
            [(netValue)]="report.valuation.baseValueDealerPurchaseNet"
            (netValueChange)="valueChange.emit()"
            [(grossValue)]="report.valuation.baseValueDealerPurchaseGross"
            (grossValueChange)="valueChange.emit()"
            [taxationType]="mayCarOwnerDeductTaxes ? 'full' : 'neutral'"></net-and-gross-currency-input>

        <h3 class="">Händler-VK</h3>
        <taxation-type-selector
            [(taxationType)]="report.valuation.taxationType"
            (taxationTypeChange)="recalculateNetValueAfterTaxationChange(); valueChange.emit()"
            [dark]="true"></taxation-type-selector>

        <net-and-gross-currency-input
            matTooltip="Bei neutraler Besteuerung wird die Mehrwertsteuer nicht ausgewiesen."
            [lightMode]="true"
            label="Grundwert (VK)"
            [(netValue)]="report.valuation.baseValueDealerSalesNet"
            (netValueChange)="valueChange.emit()"
            [(grossValue)]="report.valuation.baseValueDealerSalesGross"
            (grossValueChange)="valueChange.emit()"
            [taxationType]="report.valuation.taxationType"></net-and-gross-currency-input>
    </div>
</div>

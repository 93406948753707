import { DocumentMetadata } from '../../models/documents/document-metadata';
import { Photo } from '../../models/reports/damage-description/photo';

/**
 * The photo version depends on the document that is generated via this service instance. If this service instance creates any kind of report, take the
 * report photos. If a repair confirmation is being generated, take the respective photos instead.
 */
export function getPhotoVersionFromDocumentMetadata(documentMetadata: DocumentMetadata): keyof Photo['versions'] {
    switch (documentMetadata.type) {
        case 'repairConfirmation':
            return 'repairConfirmation';
        case 'expertStatement':
            return 'expertStatement';
        case 'customResidualValueBidList':
            return 'residualValueExchange';
        case 'garageInformation':
        case 'report':
        default:
            return 'report';
    }
}

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'license-plate',
    templateUrl: 'license-plate.component.html',
    styleUrls: ['license-plate.scss'],
})
export class LicensePlateComponent {
    /**
     * If a German license plate is provided, split it into the right three parts.
     *
     * @param {string} value
     */
    @Input() set licensePlate(value: string) {
        this._licensePlate = value || '';

        this.checkLicensePlateFormat();

        // Reset the parts so that they're empty if a non-German license plate is provided
        this.splitGermanLicensePlate();
    }

    public _licensePlate: string = '';

    @Input() displayLarge: boolean = false;
    @Input() showTypeSwitcher: boolean = true;
    @Input() disabled: boolean = false;

    @Input() set autofocus(value: boolean) {
        if (value) {
            // Don't focus the city input if one of the other inputs is already selected. The car registration scanner would otherwise always focus the first input even when clicking the second or third.
            if (
                document.activeElement !== this.initialsInput.nativeElement &&
                document.activeElement !== this.numbersInput.nativeElement
            ) {
                this.cityInput.nativeElement.focus();
            }
        }
    }

    @Output() licensePlateChange: EventEmitter<string> = new EventEmitter();
    @Output() blur: EventEmitter<void> = new EventEmitter();
    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();

    public useGermanFormat: boolean = true;
    public licensePlateHasGermanFormat: boolean;
    public licensePlateCity: string = '';
    public licensePlateInitials: string = '';
    public licensePlateNumbers: string = '';

    /**
     * When the license plate is empty, we assume a German format. If the user selected the foreign license plate
     * and removed all characters, this property is set to true, so that the foreign license plate input is still shown.
     */
    protected showForeignLicensePlateWhenEmpty: boolean = false;

    @ViewChild('CityInput', { static: false }) cityInput: ElementRef;
    @ViewChild('InitialsInput', { static: false }) initialsInput: ElementRef;
    @ViewChild('NumberInput', { static: false }) numbersInput: ElementRef;

    @ViewChild('foreignLicensePlateInput', { static: false }) set foreignLicensePlateInput(
        foreignLicensePlateInput: ElementRef,
    ) {
        this._foreignLicensePlateInput = foreignLicensePlateInput;
    }

    private _foreignLicensePlateInput: ElementRef;

    constructor(private changeDetector: ChangeDetectorRef) {}

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    private matchesGermanLicensePlateFormat(licensePlate: string): boolean {
        // If empty, treat as German format
        if (!licensePlate) {
            return true;
        }

        // Shape: HSK-- || HSK-AB- || HSK-AB-1234
        return /[A-Za-zÄÖÜäöü]{1,3}-[A-Za-zÄÖÜäöü]{0,2}-\d{0,4}[EH]?/.test(licensePlate);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/
    public dispatchLicensePlateChange() {
        this.licensePlateChange.emit(this._licensePlate);
    }

    /**
     * Split the license plate string into the three parts needed for the view:
     *
     * - City
     * - Initials
     * - Numbers
     */
    private splitGermanLicensePlate(): void {
        if (!this._licensePlate) {
            return;
        }

        // Foreign license plates don't need the three separate inputs.
        if (!this.licensePlateHasGermanFormat) {
            this.licensePlateCity = '';
            this.licensePlateInitials = '';
            this.licensePlateNumbers = '';
            return;
        }

        const licensePlateParts = this._licensePlate.split('-');
        this.licensePlateCity = licensePlateParts[0] || '';
        this.licensePlateInitials = licensePlateParts[1] || '';
        this.licensePlateNumbers = licensePlateParts[2] || '';
    }

    public joinLicensePlateParts(): void {
        if (this.licensePlateCity || this.licensePlateInitials || this.licensePlateNumbers) {
            this._licensePlate =
                this.licensePlateCity + '-' + this.licensePlateInitials + '-' + this.licensePlateNumbers;
        } else {
            this._licensePlate = '';
        }
    }

    public advanceCursorOnSpecialChars(event: KeyboardEvent): void {
        // If space key is pressed
        if (event.key === ' ' || event.key === '-') {
            // Prevent the key from being printed.
            event.preventDefault();

            if (event.target === this.cityInput.nativeElement) {
                this.initialsInput.nativeElement.focus();
                return;
            }

            if (event.target === this.initialsInput.nativeElement) {
                this.numbersInput.nativeElement.focus();
                return;
            }
        }

        // Advance the cursor if the user types a digit into the second field
        if (isFinite(+event.key) && event.target === this.initialsInput.nativeElement) {
            this.numbersInput.nativeElement.focus();
            return;
        }
    }

    public transformCityToUpperCase(): void {
        this.licensePlateCity = this.licensePlateCity.toUpperCase();
    }

    public transformInitialsToUpperCase(): void {
        this.licensePlateInitials = this.licensePlateInitials.toUpperCase();
    }

    /**
     * In case of an H or E letter for historic or electric cars
     */
    public transformNumberToUpperCase(): void {
        this.licensePlateNumbers = this.licensePlateNumbers.toUpperCase();
    }

    public transformLicensePlateToUpperCase(): void {
        // When the user deleted the whole foreign license plate, the matchesGermanLicensePlateFormat()
        // function will set the useGermanFormat to true (default for empty string). To prevent this, we
        // set the following helper variable to true, which keeps the foreign license plate input visible.
        this.showForeignLicensePlateWhenEmpty = this._licensePlate === '';

        const currentSelectionIndex = (this._foreignLicensePlateInput.nativeElement as HTMLInputElement).selectionStart;
        this._licensePlate = this._licensePlate.toUpperCase();

        /**
         * Set the cursor position back to where it was before the content was edited through toUpperCase().
         * Without this, the cursor would land at the end of the input when someone edits the middle of the value manually.
         * Use setTimeout to execute this after change detection was run.
         */
        setTimeout(() => {
            (this._foreignLicensePlateInput.nativeElement as HTMLInputElement).setSelectionRange(
                currentSelectionIndex,
                currentSelectionIndex,
            );
        }, 1);
    }

    public toggleLicensePlateType(): void {
        const isGermanFormatDisplayed =
            this.useGermanFormat && this.licensePlateHasGermanFormat && !this.showForeignLicensePlateWhenEmpty;
        this.useGermanFormat = !isGermanFormatDisplayed;

        // When switching back to German format but the license plate does not conform, remove it.
        if (this.useGermanFormat && !this.licensePlateHasGermanFormat) {
            this._licensePlate = '';
            this.dispatchLicensePlateChange();
        }

        if (this.useGermanFormat) {
            this.showForeignLicensePlateWhenEmpty = false;
        }
    }

    /**
     * When in foreign format, remove double dashes from the German license plate (separators)
     */
    public replaceDoubleDashesInForeignFormat(): void {
        if (!this.matchesGermanLicensePlateFormat(this._licensePlate)) {
            this._licensePlate = this._licensePlate.replace('--', '');
            this.focusForeignLicensePlateInput();
        }
    }

    private focusForeignLicensePlateInput(): void {
        this.changeDetector.detectChanges();
        if (this._foreignLicensePlateInput) {
            setTimeout(() => this._foreignLicensePlateInput.nativeElement.focus(), 0);
        } else {
            console.log('this._foreignLicensePlateInput was undefined');
        }
    }

    public checkLicensePlateFormat(): void {
        this.licensePlateHasGermanFormat = this.matchesGermanLicensePlateFormat(this._licensePlate);
    }

    public filterOutCharsOutsidePattern(event: KeyboardEvent): void {
        // only consider characters, no special key values ('backspace' etc.)
        if (event.key.length !== 1) {
            return;
        }

        // If neither a number, nor e nor h -> prevent key print
        if (!/[0-9ehEH]/.test(event.key)) {
            event.preventDefault();
            return;
        }

        // Check if inserting the char would create a string matching the pattern
        const target = event.target as HTMLInputElement;
        const currentValue = target.value;
        const futureValue =
            currentValue.slice(0, target.selectionStart) + event.key + currentValue.slice(target.selectionEnd);

        // If pattern were unmatched -> prevent key press
        if (!/^[0-9]{1,4}[ehEH]?$/.test(futureValue)) {
            event.preventDefault();
            return;
        }
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitFocusEvent(event: FocusEvent): void {
        this.focus.emit(event);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

import { UnprocessableEntity } from '@autoixpert/models/errors/ax-error';

/**
 * Returns the estimated time in minutes.
 *
 * Time may be estimated in minutes and hours.
 *
 * Throws an error if input is invalid.
 */
export function parseEstimatedDuration(estimatedDuration: string): number {
    if (!estimatedDuration) {
        return 0;
    }

    // const parsedDuration = /(?<predecimal>\d)[.,]?(?<decimal>\d)? ?(?<unit>h|m)/.exec(estimatedDuration);
    const parsedDuration = /(\d*)[.,]?(\d*)? ?([hm])?/.exec(estimatedDuration);
    const [_, predecimal, decimal, unit = 'm'] = parsedDuration;

    if (!predecimal) {
        throw new UnprocessableEntity({
            code: 'INVALID_TIME_ESTIMATE_INPUT',
            message: `The user inserted an invalid time estimate.`,
            data: { userInput: estimatedDuration },
        });
    }
    let value = parseInt(predecimal);
    if (unit === 'h') {
        value *= 60;
    }
    if (unit === 'h' && decimal) {
        value += Math.floor(parseFloat(`0.${decimal}`) * 60);
    }

    return value;
}

import { RouterModule, Routes } from '@angular/router';
import { AccessRights } from '@autoixpert/models/user/user';
import { AccessRightGuard } from '../shared/services/access-right-guard.service';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { CanDeactivateGuard } from '../shared/services/can-deactivate-guard.service';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactsComponent } from './contacts.component';

//import {contactDetailsRoutes} from './details/contact-details.routes';

export const contactsRoutes: Routes = [
    {
        path: 'Kontakte',
        component: ContactsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            {
                path: '',
                component: ContactListComponent,
                pathMatch: 'full',
                data: {
                    requiredAccessRight: 'editContacts',
                } satisfies { requiredAccessRight: keyof AccessRights },
                canActivate: [AccessRightGuard],
            },
            //{
            //    path     : ':contactId',
            //    component: ContactDetailsComponent,
            //    children : contactDetailsRoutes
            //}
        ],
    },
];

export const contactsRouting = RouterModule.forChild(contactsRoutes);

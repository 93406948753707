import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import { Report } from '@autoixpert/models/reports/report';
import { UserPreferencesService } from './user-preferences.service';

@Injectable()
export class ScreenTitleService {
    constructor(
        private titleService: Title,
        private userPreferences: UserPreferencesService,
    ) {}

    private screenTitleStream: Subject<string> = new Subject<string>();
    private screenSubtitleStream: Subject<string> = new Subject<string>();
    private licensePlateStream: Subject<string> = new Subject<string>();
    private reportTokenStream: Subject<string> = new Subject<string>();

    public setScreenTitle({ screenTitle, screenSubtitle, licensePlate, reportToken }: ScreenTitleOptions) {
        if (screenTitle.trim()) {
            this.screenTitleStream.next(screenTitle);
            // Set the title of the HTML page, i. e. the title displayed on the browser tab.
            const suffix = isQapterixpert() ? 'QX' : 'aX';

            // If the report token is displayed in the screen title, also display it in the tab title of the browser.
            const token =
                this.userPreferences.reportAttributesInHeadRunner?.includes('reportToken') && reportToken
                    ? reportToken + ' | '
                    : '';
            this.titleService.setTitle(`${token}` + screenTitle + ` | ${suffix}`);
        } else {
            const placeholder = isQapterixpert() ? 'Qapter-iXpert' : 'autoiXpert';
            this.screenTitleStream.next(placeholder);
            // Set the title of the HTML page, i. e. the title displayed on the browser tab.
            this.titleService.setTitle(placeholder);
        }
        this.setSubtitle(screenSubtitle);
        this.setLicensePlate(licensePlate);
        this.setReportToken(reportToken);
    }

    public setSubtitle(screenSubtitle) {
        this.screenSubtitleStream.next(screenSubtitle);
    }

    public setLicensePlate(licensePlate) {
        this.licensePlateStream.next(licensePlate);
    }

    public setReportToken(reportToken) {
        this.reportTokenStream.next(reportToken);
    }

    public getScreenTitleObservable(): Subject<string> {
        return this.screenTitleStream;
    }

    public getScreenSubtitleObservable(): Subject<string> {
        return this.screenSubtitleStream;
    }

    public getLicensePlateObservable() {
        return this.licensePlateStream;
    }

    public getReportTokenObservable() {
        return this.reportTokenStream;
    }

    public setScreenTitleForReport(report: Report): void {
        const organization = report.claimant.contactPerson.organization || '';
        const firstName = report.claimant.contactPerson.firstName || '';
        const lastName = report.claimant.contactPerson.lastName || '';

        const screenTitleOptions = {
            screenTitle: null,
            screenSubtitle: null,
            licensePlate: report.car.licensePlate || undefined,
            reportToken: report.token || undefined,
        };

        if (report.claimant.contactPerson.organization) {
            screenTitleOptions.screenTitle = organization;
            screenTitleOptions.screenSubtitle = `${firstName} ${lastName}`.trim();
        } else {
            screenTitleOptions.screenTitle = `${firstName} ${lastName}`.trim();
        }
        this.setScreenTitle(screenTitleOptions);
    }
}

export interface ScreenTitleOptions {
    screenTitle: string;
    screenSubtitle?: string;
    licensePlate?: string;
    reportToken?: string;
}

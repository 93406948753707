/**
 * Allow a JWT to be passed from one autoiXpert domain like app.autoixpert.de to beta.autoixpert.de.
 *
 * JWTs should not be transferred through the URL because that's insecure. URLs are recorded in the browser
 * history and in the server logs. Those are no places for authentication information to be stored.
 */
export async function sendJwtToOtherAutoixpertDomain({
    domain,
    jwt,
}: {
    domain:
        | 'app.autoixpert.de'
        | 'beta.autoixpert.de'
        | 'offline.autoixpert.de'
        | 'autoixpert.lokal'
        | 'app.qapter-ixpert.de'
        | 'qx.autoixpert.lokal';
    jwt: string;
}) {
    const iframe = document.createElement('iframe');
    const origin = `https://${domain}`;
    iframe.src = `${origin}/assets/cross-domain-authentication.html`;
    // Ensure this iframe is not displayed to the user because it looks ugly.
    iframe.style.display = 'none';

    return new Promise<void>((resolve) => {
        /**
         * The entire communication between this window and the iframe should only start after the iframe was loaded.
         */
        iframe.addEventListener('load', () => {
            // This does not work due to same-origin restrictions. The iframe is not allowed to send messages to the parent if the origins deviate.
            ///**
            // * Handle the response from the iframe.
            // */
            //window.addEventListener('message', (event) => {
            //    const payload = event.data;
            //    if (event.origin !== origin) {
            //        console.log('Rejected message because it did not originate from the correct domain.', event.origin, origin);
            //        return;
            //    }
            //    if (payload.messageType !== 'add-jwt-response') {
            //        console.log('Do not handle message in sendJwtToOtherAutoixpertDomain() because it does not have a type of "add-jwt-response".', event.origin, origin);
            //        return;
            //    }
            //
            //    if (payload.success === true) {
            //        resolve();
            //    }
            //    else {
            //        reject(payload.error);
            //    }
            //});

            /**
             * Send the request to the iframe. The iframe should use this JWT to add it to its local storage.
             */
            iframe.contentWindow.postMessage(
                {
                    messageType: 'add-jwt-request',
                    jwtLocalStorageKey: 'jwt-for-cross-domain-login',
                    jwt,
                },
                origin,
            );

            resolve();
        });

        // This causes the iframe to actually load.
        document.body.appendChild(iframe);
    });
}

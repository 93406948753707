<div id="notes-overlay-container" class="card" @fadeInAndSlide>
    <div class="small-overlay-content">
        <div class="small-overlay-headline-container">
            <mat-icon class="small-overlay-headline-icon">notes</mat-icon>
            <h2 class="small-overlay-heading">Notizen</h2>
        </div>

        <div id="notes-list">
            <internal-note
                *ngFor="let note of notes"
                [note]="note"
                (editModeActivated)="enterEditMode($event)"
                (editModeQuit)="leaveEditMode($event)"
                (noteDeleted)="deleteNote($event)"
                (noteChange)="emitChange()"></internal-note>

            <!--============================================-->
            <!-- New Note -->
            <!--============================================-->
            <div id="new-note-container" *ngIf="newNote">
                <div class="display-flex">
                    <!--********** User Picture **********-->
                    <user-avatar [userId]="newNote.createdBy" tooltipPrefix="Erstellt von "></user-avatar>

                    <!--********** Text Input **********-->
                    <div class="edit-mode-container flex-grow" *ngIf="newNote">
                        <!--********** Textarea **********-->
                        <mat-form-field class="mat-block">
                            <mat-quill
                                placeholder="Notizen"
                                [(ngModel)]="newNote.text"
                                (keydown)="saveNoteOnCtrlEnter($event)"
                                [autofocus]="true"></mat-quill>
                        </mat-form-field>
                    </div>
                </div>

                <!--********** Buttons **********-->
                <div class="text-align-center margin-bottom-10">
                    <button (click)="saveNewNote()">Speichern</button>
                    <button class="secondary" (click)="clearNewNote()">Verwerfen</button>
                </div>
            </div>
            <!--============================================-->
            <!-- END New Note -->
            <!--============================================-->
        </div>
    </div>

    <!--============================================-->
    <!-- Add Comment Button -->
    <!--============================================-->
    <div class="small-overlay-main-button" *ngIf="notesInEditMode.size === 0 && !newNote" (click)="createNewNote()">
        <span>+ Neue Notiz</span>
    </div>
    <!--============================================-->
    <!-- END Add Comment Button -->
    <!--============================================-->
</div>

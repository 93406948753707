import { RecordMigrations } from '@autoixpert/models/indexed-db/database.types';
import { FeeTableName } from '@autoixpert/models/reports/assessors-fee/fee-calculation';
import {
    CustomFeeConfig,
    DefaultFeeConfig,
    DefaultFeeSetRow,
    FeePreferences,
} from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { UserUserInterfaceStates } from '@autoixpert/models/user/user-user-interface-states';

export const userRecordMigrations: RecordMigrations<User> = {
    '4': (user: User & { team: string }): User => {
        console.log('Converting users to _schemaVersion 4');
        user.teamId = user.team;
        delete user.team;
        return user;
    },
    '5': (user: User & { preferences: { contactListShowInsurances: any } }): User => {
        console.log('Converting users to _schemaVersion 5');
        delete user.preferences.contactListShowInsurances;
        return user;
    },
    '6': (
        user: User & { preferences: { selectedTutorial: any; tutorialVisible: boolean; introVideosVisible: boolean } },
    ): User => {
        console.log('Converting users to _schemaVersion 6');

        // Delete
        delete user.preferences.selectedTutorial;

        // Rename
        user.preferences.introVideosVisible = user.preferences.tutorialVisible;
        delete user.preferences.tutorialVisible;

        return user;
    },
    '7': (
        user: User & {
            preferences: {
                assessorsFeeDescriptionLiability: string;
                assessorsFeeDescriptionValuation: string;
                assessorsFeeDescriptionOldtimerValuationSmall: string;
                assessorsFeeDescriptionShortAssessment: string;
                assessorsFeeDescriptionPartialKasko: string;
                assessorsFeeDescriptionFullKasko: string;
                assessorsFeeDescriptionLeaseReturn: string;
                assessorsFeeDescriptionUsedVehicleCheck: string;
                assessorsFeeDescriptionInvoiceAudit: string;
                defaultFeeTable: FeeTableName;
                defaultCustomFeeTableId: string;
                bvskFeeConfig: DefaultFeeConfig;
                hukFeeConfig: DefaultFeeSetRow;
                vksFeeConfig: DefaultFeeConfig;
                cgfFeeConfig: DefaultFeeConfig;
                customFeeConfigs: CustomFeeConfig[];
                defaultFees: FeePreferences;
                liabilityHukFees: FeePreferences;
                previousDamageStandardText: string;
                unrepairedDamageStandardText: string;
                meantimeDamageStandardText: string;
                damageDescriptionStandardText: string;
                plausibilityStandardText: string;
                circumstancesStandardText: string;
                compatibilityStandardText: string;
            };
        },
    ): User => {
        //*****************************************************************************
        // Move Report Fee Settings and Standard Texts from User to Team
        //****************************************************************************/

        // Complementing backend migration 20241105135000.move-report-fee-settings-and-standard-texts-from-user-to-team.ts
        console.log('Converting users to _schemaVersion 7');
        // Delete unused properties (moved to team)
        delete user.preferences.assessorsFeeDescriptionLiability;
        delete user.preferences.assessorsFeeDescriptionValuation;
        delete user.preferences.assessorsFeeDescriptionOldtimerValuationSmall;
        delete user.preferences.assessorsFeeDescriptionShortAssessment;
        delete user.preferences.assessorsFeeDescriptionPartialKasko;
        delete user.preferences.assessorsFeeDescriptionFullKasko;
        delete user.preferences.assessorsFeeDescriptionLeaseReturn;
        delete user.preferences.assessorsFeeDescriptionUsedVehicleCheck;
        delete user.preferences.assessorsFeeDescriptionInvoiceAudit;

        delete user.preferences.defaultFeeTable;
        delete user.preferences.defaultCustomFeeTableId;
        delete user.preferences.bvskFeeConfig;
        delete user.preferences.hukFeeConfig;
        delete user.preferences.vksFeeConfig;
        delete user.preferences.cgfFeeConfig;
        delete user.preferences.customFeeConfigs;
        delete user.preferences.defaultFees;
        delete user.preferences.liabilityHukFees;

        delete user.preferences.previousDamageStandardText;
        delete user.preferences.unrepairedDamageStandardText;
        delete user.preferences.meantimeDamageStandardText;
        delete user.preferences.damageDescriptionStandardText;
        delete user.preferences.plausibilityStandardText;
        delete user.preferences.circumstancesStandardText;
        delete user.preferences.compatibilityStandardText;

        //*****************************************************************************
        // END Move Report Fee Settings and Standard Texts from User to Team
        //****************************************************************************/

        return user;
    },
    '8': (
        user: User & {
            preferences: {
                // Moved to user.userInterfaceStates
                bankAccountSyncAvailabilityNoteClosed: boolean;
                firstBankAccountSyncNoteClosed: boolean;
                bankTransactionListTutorialVideoSeen: boolean;
                buildingBlockTutorialSeen: boolean;
                reportTokenConfigNoticeClosed: boolean;
                exampleVinNoticeClosed: boolean;
                carRegistrationScanNoticeClosed: boolean;
                transferMissingSignaturesNoticeClosed: boolean;
                photoEditorContextMenuNoticeClosed: boolean;
                cartvDowntimeCompensationImportNoticeClosed: boolean;
                fullDocumentConfigNoteShown: boolean;
                addYourselfToBccNoticeClosed: boolean;
                printAndTransmission_automaticBuildingBlockSelection_infoPanelClosed: boolean;
                audatexWageRateTranslationNoteClosed: boolean;
                hideResidualValueBidderGroupsExplanation: boolean;
                hideSpecialCostsNotification: boolean;
                valuationTutorialSeen: boolean;
                introVideosVisible: boolean;
                licensePlateRedactionTutorialDismissed: boolean;

                // Deleted
                bankAccountSyncIntroNoteClosed: boolean;
                bankAccountConnectionTutorialVideoSeen: boolean;
            };
        },
    ): User => {
        console.log('Converting users to _schemaVersion 8');

        // Complementing backend migration migration_20250318_155000_extractInfoNoteAndTutorialFieldsFromUserPreferences

        // Delete
        delete user.preferences.bankAccountSyncIntroNoteClosed;
        delete user.preferences.bankAccountConnectionTutorialVideoSeen;

        // Rename
        if (!user.userInterfaceStates) {
            // Make sure the new object exists
            user.userInterfaceStates = new UserUserInterfaceStates();
        }

        user.userInterfaceStates.bankAccountSyncAvailabilityNoteClosed =
            user.preferences.bankAccountSyncAvailabilityNoteClosed;
        delete user.preferences.bankAccountSyncAvailabilityNoteClosed;
        user.userInterfaceStates.firstBankAccountSyncNoteClosed = user.preferences.firstBankAccountSyncNoteClosed;
        delete user.preferences.firstBankAccountSyncNoteClosed;
        user.userInterfaceStates.bankTransactionListTutorialVideoSeen =
            user.preferences.bankTransactionListTutorialVideoSeen;
        delete user.preferences.bankTransactionListTutorialVideoSeen;
        user.userInterfaceStates.buildingBlockTutorialSeen = user.preferences.buildingBlockTutorialSeen;
        delete user.preferences.buildingBlockTutorialSeen;
        user.userInterfaceStates.reportTokenConfigNoticeClosed = user.preferences.reportTokenConfigNoticeClosed;
        delete user.preferences.reportTokenConfigNoticeClosed;
        user.userInterfaceStates.exampleVinNoticeClosed = user.preferences.exampleVinNoticeClosed;
        delete user.preferences.exampleVinNoticeClosed;
        user.userInterfaceStates.carRegistrationScanNoticeClosed = user.preferences.carRegistrationScanNoticeClosed;
        delete user.preferences.carRegistrationScanNoticeClosed;
        user.userInterfaceStates.transferMissingSignaturesNoticeClosed =
            user.preferences.transferMissingSignaturesNoticeClosed;
        delete user.preferences.transferMissingSignaturesNoticeClosed;
        user.userInterfaceStates.photoEditorContextMenuNoticeClosed =
            user.preferences.photoEditorContextMenuNoticeClosed;
        delete user.preferences.photoEditorContextMenuNoticeClosed;
        user.userInterfaceStates.cartvDowntimeCompensationImportNoticeClosed =
            user.preferences.cartvDowntimeCompensationImportNoticeClosed;
        delete user.preferences.cartvDowntimeCompensationImportNoticeClosed;
        user.userInterfaceStates.fullDocumentConfigNoteShown = user.preferences.fullDocumentConfigNoteShown;
        delete user.preferences.fullDocumentConfigNoteShown;
        user.userInterfaceStates.addYourselfToBccNoticeClosed = user.preferences.addYourselfToBccNoticeClosed;
        delete user.preferences.addYourselfToBccNoticeClosed;
        user.userInterfaceStates.printAndTransmission_automaticBuildingBlockSelection_infoPanelClosed =
            user.preferences.printAndTransmission_automaticBuildingBlockSelection_infoPanelClosed;
        delete user.preferences.printAndTransmission_automaticBuildingBlockSelection_infoPanelClosed;
        user.userInterfaceStates.audatexWageRateTranslationNoteClosed =
            user.preferences.audatexWageRateTranslationNoteClosed;
        delete user.preferences.audatexWageRateTranslationNoteClosed;
        user.userInterfaceStates.hideResidualValueBidderGroupsExplanation =
            user.preferences.hideResidualValueBidderGroupsExplanation;
        delete user.preferences.hideResidualValueBidderGroupsExplanation;
        user.userInterfaceStates.hideSpecialCostsNotification = user.preferences.hideSpecialCostsNotification;
        delete user.preferences.hideSpecialCostsNotification;
        user.userInterfaceStates.valuationTutorialSeen = user.preferences.valuationTutorialSeen;
        delete user.preferences.valuationTutorialSeen;
        user.userInterfaceStates.introVideosVisible = user.preferences.introVideosVisible;
        delete user.preferences.introVideosVisible;
        user.userInterfaceStates.licensePlateRedactionTutorialDismissed =
            user.preferences.licensePlateRedactionTutorialDismissed;
        delete user.preferences.licensePlateRedactionTutorialDismissed;

        return user;
    },
};

import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import * as IBAN from 'iban';
import moment from 'moment';
import { isAudatexTestAccount } from '@autoixpert/lib/users/is-audatex-test-account';
import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { DocumentLayoutGroup } from '@autoixpert/models/documents/document-layout-group';
import { BankAccount, BillingAddress, Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { DocumentLayoutGroupService } from 'src/app/shared/services/document-layout-group.service';
import { UserService } from 'src/app/shared/services/user.service';
import { IbanChangeEvent } from '../../shared/directives/iban-input.directive';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { BicAndBankName } from '../../shared/services/bic.service';
import { DirectDebitMandateService } from '../../shared/services/direct-debit-mandate.service';
import { OrderAndCancellationService } from '../../shared/services/order-and-cancellation.service';
import { TeamService } from '../../shared/services/team.service';
import { ToastService } from '../../shared/services/toast.service';

/**
 * Component for booking your autoiXpert account.
 *
 * Not used for Qapter-iXpert customers. They must book by calling Audatex.
 */
@Component({
    selector: 'subscription-dialog',
    templateUrl: 'subscription-dialog.component.html',
    styleUrls: ['subscription-dialog.component.scss'],
})
export class SubscriptionDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<SubscriptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: SubscriptionDialogComponentConfig,
        private toastService: ToastService,
        private directDebitMandateService: DirectDebitMandateService,
        private orderAndCancellationService: OrderAndCancellationService,
        private apiErrorService: ApiErrorService,
        private documentLayoutGroupService: DocumentLayoutGroupService,
        private teamService: TeamService,
        private userService: UserService,
    ) {}

    public team: Team;
    public user: User;
    public displayOptions: DisplayOptions;
    public dialogMayBeClosed: boolean = true;

    public selectedPaymentCycle: 'monthly' | 'annually' = 'monthly';
    public selectedPaymentMethod: 'directDebit' | 'invoice' = 'directDebit';
    public audatexAddon: boolean;

    // Account Costs
    public activeTeamMembers: User[] = [];
    public documentLayoutGroups: DocumentLayoutGroup[] = [];
    public additionalUsers: number = 0;
    public additionalDocumentLayouts: number = 0;
    public OFFICE_ACCOUNT_FEE_MONTHLY: number = 89;
    public OFFICE_ACCOUNT_FEE_ANNUALLY: number = 85;
    public OFFICE_ACCOUNT_AUDATEX_ADDON_FEE_MONTHLY: number = 30;
    public USER_ACCOUNT_FEE: number = 20;
    public USER_ACCOUNT_AUDATEX_ADDON_FEE: number = 10;
    public USER_ACCOUNTS_INCLUDED: number = 1;
    public DOCUMENT_LAYOUT_GROUP_FEE: number = 69;
    public DOCUMENT_LAYOUT_GROUPS_INCLUDED: number = 1;

    // Start Date
    public startDate: string;
    public startDateInEditMode: boolean;

    // Direct Debit Mandate
    public bankAccount: BankAccount = new BankAccount();
    public isIbanInvalid: boolean;
    public directDebitConditionsAcknowledged: boolean;

    // Invoice
    public billingAddress: BillingAddress = new BillingAddress();

    // Order Placement
    public orderPlacementPending: boolean;
    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.user = this.dialogData.user;
        this.team = this.dialogData.team;

        /**
         * A full booking occurs if the team account is changing from a test account to a full account.
         *
         * This dialog can also be used to only add a payment method.
         */
        this.displayOptions = this.dialogData.display ?? { fullBooking: this.team.accountStatus === 'test' };

        // Closing dialog allowed?
        this.dialogMayBeClosed = !this.dialogRef.disableClose;

        // Set the Audatex Addon active if the user already has their own Qapter account.
        this.audatexAddon = isAudatexUserComplete(this.user) && !isAudatexTestAccount(this.user.audatexUser);

        // Copy Bank Account - Take billing data (previously entered for paying with autoiXpert) or fall back to the invoicing data (printed on the customer's invoices for his clients to pay him).
        this.bankAccount = this.team.billing.bankAccount.iban
            ? JSON.parse(JSON.stringify(this.team.billing.bankAccount))
            : JSON.parse(JSON.stringify(this.team.invoicing.bankAccount));
        this.checkIban();
        this.bankAccount.iban = IBAN.printFormat(this.bankAccount.iban || '');

        // Copy invoice address
        this.billingAddress = JSON.parse(JSON.stringify(this.team.billing.address));
        this.billingAddress.firstName = this.billingAddress.firstName ?? this.user.firstName;
        this.billingAddress.lastName = this.billingAddress.lastName ?? this.user.lastName;

        // If payment method or payment cycle are already set, use them in place of the defaults.
        this.setPaymentCycle(this.team.billing.paymentCycle || this.selectedPaymentCycle);
        this.setPaymentMethod(this.team.billing.paymentMethod || this.selectedPaymentMethod);

        this.determineStartDate();

        // We charge fees for additional team members and document layout groups.
        this.loadTeamMembers();
        this.loadDocumentLayoutGroups();
    }

    /**
     * Load all team members.
     */
    private async loadTeamMembers() {
        try {
            this.activeTeamMembers = await this.userService.getActiveTeamMembers(this.team, this.user);
            this.additionalUsers = this.getNumberAdditionalUsers();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team-Mitglieder nicht geladen',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                },
            });
        }
    }

    /**
     * Load all document layout groups.
     */
    private loadDocumentLayoutGroups(): void {
        // Sort, so that the original layout group is always at the top.
        this.documentLayoutGroupService.find({ $sort: 'createdAt' }).subscribe({
            next: (documentLayoutGroups) => {
                this.documentLayoutGroups = documentLayoutGroups;
                this.additionalDocumentLayouts = this.getNumberAdditionalDocumentLayouts();
            },
            error: (error) => {
                console.error('Error loading document layout groups.', { error });
                this.toastService.error(
                    'Layoutgruppen nicht geladen',
                    'Die Briefpapier-Konfigurationen konnten nicht geladen werden. Das ist ein technisches Problem. Kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
            },
        });
    }

    public getActiveTeamMembers() {
        return this.activeTeamMembers.filter((user) => user.active);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Payment
    //****************************************************************************/
    public setPaymentCycle(paymentCycle: this['selectedPaymentCycle']): void {
        this.selectedPaymentCycle = paymentCycle;
        if (paymentCycle === 'monthly') {
            this.selectedPaymentMethod = 'directDebit';
        }
    }

    public setPaymentMethod(paymentMethod: this['selectedPaymentMethod']): void {
        if (this.selectedPaymentCycle === 'monthly' && paymentMethod === 'invoice') {
            this.toastService.info(
                'Überweisung nur bei Jahreszahlung',
                'Überweisung ist nur bei jährlicher Zahlweise möglich. Bei monatlicher Zahlweise wird der Betrag von deinem Konto abgebucht.',
            );
            return;
        }
        this.selectedPaymentMethod = paymentMethod;
    }

    public getNumberAdditionalUsers(): number {
        return this.activeTeamMembers.length > this.USER_ACCOUNTS_INCLUDED
            ? this.activeTeamMembers.length - this.USER_ACCOUNTS_INCLUDED
            : 0;
    }
    public getNumberAdditionalDocumentLayouts(): number {
        return this.documentLayoutGroups.length > this.DOCUMENT_LAYOUT_GROUPS_INCLUDED
            ? this.documentLayoutGroups.length - this.DOCUMENT_LAYOUT_GROUPS_INCLUDED
            : 0;
    }

    public getBillingPositionsPerMonth(): BillingPosition[] {
        const positions = [];

        let price: number;
        if (this.audatexAddon) {
            // There is no discount for annual payments with the Audatex Addon.
            price = this.OFFICE_ACCOUNT_FEE_MONTHLY + this.OFFICE_ACCOUNT_AUDATEX_ADDON_FEE_MONTHLY;
        } else {
            // AutoiXpert customers can get a discount when they pay annually.
            price =
                this.selectedPaymentCycle === 'monthly'
                    ? this.OFFICE_ACCOUNT_FEE_MONTHLY
                    : this.OFFICE_ACCOUNT_FEE_ANNUALLY;
        }
        // Base Fee
        positions.push({
            title: 'Grundgebühr',
            count: 1,
            price,
        });

        // Additional Users
        if (this.additionalUsers) {
            positions.push({
                title: 'Zusatznutzer',
                count: this.additionalUsers,
                price: this.USER_ACCOUNT_FEE + (this.audatexAddon ? this.USER_ACCOUNT_AUDATEX_ADDON_FEE : 0),
            });
        }

        // Additional Document Layout Groups
        if (this.additionalDocumentLayouts) {
            positions.push({
                title:
                    this.additionalDocumentLayouts > 1
                        ? 'zusätzliche Dokumentenlayouts'
                        : 'zusätzliches Dokumentenlayout',
                count: this.additionalDocumentLayouts,
                price: this.DOCUMENT_LAYOUT_GROUP_FEE,
            });
        }

        return positions;
    }

    /**
     * Calculate the account costs either for a year or for a single month.
     * @param period
     */
    public getAccountCosts(period: 'perMonth' | 'perYear'): number {
        const numberOfMonths = period === 'perMonth' ? 1 : 12;
        const billingPositions = this.getBillingPositionsPerMonth();
        const amountPerMonth = billingPositions.reduce((sum, position) => position.count * position.price + sum, 0);
        return amountPerMonth * numberOfMonths;
    }

    /**
     * Describe how the monthly fee is calculated.
     */
    public getAccountCostsTooltip(): string {
        const billingPositions = this.getBillingPositionsPerMonth();

        const tooltipParts: string[] = billingPositions.map((billingPosition) => {
            if (billingPosition.count !== 1) {
                return `${billingPosition.count} ${billingPosition.title} à ${billingPosition.price} €`;
            } else {
                return `${billingPosition.title} ${billingPosition.price} €`;
            }
        });

        return tooltipParts.join('\n + ');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payment
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Audatex Add-on
    //****************************************************************************/
    public toggleAudatexAddon() {
        this.audatexAddon = !this.audatexAddon;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex Add-on
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Start Date
    //****************************************************************************/
    public determineStartDate(): void {
        /**
         *  Either the date set by the aX team in the account management or the
         *  expiration date; That's usually when the test account ends.
         */
        this.startDate = this.team.becameCustomerAt || this.team.expirationDate;
    }

    public enterStartDateEditMode(): void {
        this.startDateInEditMode = true;
    }

    public leaveStartDateEditMode(): void {
        this.startDateInEditMode = false;
    }

    public isStartDateInThePast(): boolean {
        return moment(this.startDate).isBefore(moment().startOf('day'));
    }

    public lateStartDateCausesInterruption(): boolean {
        const startDate = moment(this.startDate);
        const startDateSomeDaysAfterExpiration: boolean = startDate.isAfter(
            moment(this.team.expirationDate).endOf('day').add(1, 'day'),
        );
        /**
         * If the start date is today, the customer does not care if there are days between the (past) expiration
         * date and his start date.
         */
        const startDateIsToday: boolean = startDate.isSame(undefined, 'days');

        return startDateSomeDaysAfterExpiration && !startDateIsToday;
    }

    /**
     * Is the start date the same *day* as the account (test) expiration date?
     * We cannot just compare the date strings because they may contain different times.
     */
    public isStartDateEndOfTestAccount(): boolean {
        return moment(this.startDate).isSame(moment(this.team.expirationDate), 'day');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Start Date
    /////////////////////////////////////////////////////////////////////////////*/
    public async placeOrder(): Promise<void> {
        const errorMessage: string = this.getOrderButtonTooltip();
        if (errorMessage) {
            this.toastService.info('Daten prüfen', errorMessage);
            return;
        }

        if (this.orderPlacementPending) return;

        this.orderPlacementPending = true;

        // Format IBAN properly (upper case letters, no spaces). Fastbill requires a properly formatted in electronic format IBAN.
        // IBAN exists in case of direct debit mandate, but not in case of bank transfer.
        // Formatting must be done before all server calls, such as creating the debit mandate.
        if (this.bankAccount.iban) {
            this.bankAccount.iban = IBAN.electronicFormat(this.bankAccount.iban);
        }

        //*****************************************************************************
        //  Save Billing Details on Team
        //****************************************************************************/
        // Team billing data must be saved before the placeOrder() call
        this.team.billing.paymentMethod = this.selectedPaymentMethod;
        this.team.audatexFeatures.hasAudatexAddon = this.audatexAddon;
        Object.assign<BankAccount, BankAccount>(this.team.billing.bankAccount, this.bankAccount);
        Object.assign<BillingAddress, BillingAddress>(this.team.billing.address, this.billingAddress);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Save Billing Details on Team
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Order the product
        //****************************************************************************/
        /**
         * If the account is still in the test, trigger an order. For paying accounts, just add a payment method.
         * If the user is changing from test to paying customer (full order), trigger the transaction on the server that sets the right properties on the team object.
         */
        if (this.team.accountStatus === 'test' && !this.team.becameCustomerAt) {
            // Save the team first so that the placeOrder() call has the latest data, e.g. selected payment method.
            try {
                await this.teamService.put(this.team, { waitForServer: true });
            } catch (error) {
                this.orderPlacementPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Team nicht gespeichert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }

            try {
                await this.orderAndCancellationService.orderTeamAccount({
                    teamId: this.team._id,
                    paymentCycle: this.selectedPaymentCycle,
                    paymentMethod: this.selectedPaymentMethod,
                    becameCustomerAt: this.startDate,
                    audatexAddon: this.audatexAddon,
                });
                this.toastService.success('Bestellung erfolgreich');
            } catch (error) {
                this.orderPlacementPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ALREADY_PAYING_CUSTOMER: {
                            title: 'Bereits zahlender Kunde',
                            body: "Du bist bereits zahlender Kunde. Bei Fragen kontaktiere bitte die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                        },
                    },
                    defaultHandler: {
                        title: 'Bestellung gescheitert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Order the product
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Change Means of Payment
        //****************************************************************************/
        // The team billing properties have been updated further up.
        else {
            try {
                await this.teamService.put(this.team, { waitForServer: true });
                if (this.displayOptions.directDebit) {
                    this.toastService.success('Zahlungsmittel hinzugefügt');
                }
                if (this.displayOptions.invoiceAddress) {
                    this.toastService.success('Rechnungsadresse hinzugefügt');
                }
            } catch (error) {
                this.orderPlacementPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Zahlungsdaten nicht geändert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Change Means of Payment
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Add Direct Debit Mandate
        //****************************************************************************/
        if (this.selectedPaymentMethod === 'directDebit') {
            try {
                await this.directDebitMandateService.create(this.team._id, this.bankAccount);
            } catch (error) {
                // Remove mandate and trigger the process again.
                if (error.code === 'DIRECT_DEBIT_MANDATE_EXISTS_ALREADY') {
                    await this.directDebitMandateService.remove(this.team._id);

                    // Make way for placeOrder method to run again.
                    this.orderPlacementPending = false;
                    await this.placeOrder();
                    return;
                }
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Add Direct Debit Mandate
        /////////////////////////////////////////////////////////////////////////////*/

        this.orderPlacementPending = false;
        this.dialogRef.close(true);
    }

    public orderPlacementAllowed(): boolean {
        return !this.getOrderButtonTooltip();
    }

    public getOrderButtonTooltip(): string {
        if (!this.displayOptions.invoiceAddress) {
            if (!this.selectedPaymentCycle) {
                return 'Bitte gib eine Zahlweise an.';
            }

            if (!this.selectedPaymentMethod) {
                return 'Bitte gib eine Zahlmethode an (Lastschrift oder Rechnung).';
            }

            if (!this.selectedPaymentMethod) {
                return 'Bitte gib eine Zahlmethode an (Lastschrift oder Rechnung).';
            }

            if (this.selectedPaymentMethod === 'directDebit') {
                if (!this.bankAccount.owner) {
                    return 'Bitte gib einen Kontoinhaber an.';
                }
                if (!this.bankAccount.iban) {
                    return 'Bitte gib eine IBAN an.';
                }
                if (!this.isIbanValid(this.bankAccount.iban)) {
                    return 'Die IBAN scheint üngültig zu sein. Bitte gib eine gültige IBAN an.';
                }

                if (!this.directDebitConditionsAcknowledged) {
                    return 'Bitte akzeptiere die SEPA-Bedingungen.';
                }
            }
        }

        if (this.selectedPaymentMethod === 'invoice' || this.displayOptions.invoiceAddress) {
            if (!this.billingAddress.organization) {
                return 'Bitte gib eine Organisation an.';
            }
            if (!this.billingAddress.streetAndHouseNumberOrLockbox) {
                return 'Bitte gib eine Straße & Hausnummer an.';
            }
            if (!this.billingAddress.zip) {
                return 'Bitte gib eine gültige PLZ an.';
            }

            if (!this.billingAddress.city) {
                return 'Bitte gib einen Ort an.';
            }
        }

        if (this.team.accountStatus === 'test') {
            if (moment(this.startDate).isBefore(undefined, 'days')) {
                return `Startdatum darf nicht in der Vergangenheit liegen.`;
            }
        }
    }

    public handleIbanChangeEvent(result: IbanChangeEvent): void {
        this.isIbanInvalid = !result.isValid;
    }

    public handleBicRecognition({ bic, bankName }: BicAndBankName) {
        this.bankAccount.bic = bic;
        this.bankAccount.bankName = bankName;
    }

    public capitalizeBic() {
        this.bankAccount.bic = (this.bankAccount.bic || '').toUpperCase();
    }

    private checkIban(): void {
        // Empty IBAN should be valid
        if (!this.bankAccount.iban) {
            this.isIbanInvalid = false;
            return;
        }
        this.isIbanInvalid = !this.isIbanValid(this.bankAccount.iban);
    }

    private isIbanValid(iban: string): boolean {
        return IBAN.isValid(iban);
    }

    //*****************************************************************************
    //  Invoice
    //****************************************************************************/
    public insertCityIntoModel(city: string) {
        this.billingAddress.city = city;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Place Order
    //****************************************************************************/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Place Order
    /////////////////////////////////////////////////////////////////////////////*/

    public insertAddressIntoModel(address: Partial<ContactPerson>) {
        this.billingAddress.streetAndHouseNumberOrLockbox = address.streetAndHouseNumberOrLockbox;
        this.billingAddress.zip = address.zip;
        this.billingAddress.city = address.city;
    }

    //*****************************************************************************
    //  Dialog Events
    //****************************************************************************/
    public close(): void {
        this.dialogRef.close();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog Events
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface SubscriptionDialogComponentConfig {
    team: Team;
    user: User;
    display?: DisplayOptions;
}

interface DisplayOptions {
    fullBooking?: boolean;
    directDebit?: boolean;
    invoiceAddress?: boolean;
}

type BillingPosition = {
    title: string;
    count: number;
    price: number;
};

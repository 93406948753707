import { RequireSome } from '@autoixpert/helper-types/require-some';
import { generateId } from '@autoixpert/lib/generate-id';
import { DocumentOrderItem } from '@autoixpert/models/documents/document-order-item';
import { InvoiceInvolvedPartyRole } from '@autoixpert/models/invoices/invoice';
import { InvolvedPartyRole } from '@autoixpert/models/reports/involved-parties/involved-party';

/**
 * A list connected to an involved party that sorts and activates the documents in the report.documents array for
 * sending emails or downloading the full PDF.
 */
export class DocumentOrder {
    constructor(template?: RequireSome<DocumentOrder, 'recipientRole'>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    _id: string = generateId();

    recipientRole: InvolvedPartyRole | InvoiceInvolvedPartyRole;
    items: DocumentOrderItem[] = [];
}

import { generateId } from '../../../lib/generate-id';

export class ReplacementValueCorrectionItem {
    constructor(options: Partial<ReplacementValueCorrectionItem> = {}) {
        Object.assign(this, options);
    }

    _id: string = generateId();
    type: CorrectionItemType = 'decrease';
    position: number = null; // Position in order
    description: string = ''; // Field "Beschreibung"
    value: number = null; // Correction value -> always positive. Can be interpreted by using the type property (decrease or increase)
    repairExecutionType: CorrectionItemRepairExecutionType;
}
export type CorrectionItemType = 'decrease' | 'increase';
export type CorrectionItemRepairExecutionType = 'repaired' | 'unprofessional' | 'partial' | 'none' | null;

import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss'],
    host: {
        '[class.has-header-image]': '!!this.data.headerImagePath',
    },
})
export class ConfirmDialogComponent {
    constructor(
        private dialog: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    ) {}

    public confirm(): void {
        this.dialog.close(true);
    }

    public cancel(): void {
        this.dialog.close(false);
    }
}

export interface ConfirmDialogData {
    heading: string;
    content: string;
    textAlign?: 'left';
    confirmLabel: string;
    cancelLabel: string;
    /**
     * Path to a header image. Will be displayed at the top of the dialog.
     */
    headerImagePath?: string;
    headerImageMaxHeight?: string;
    /**
     * Makes the confirm button red. Use for destructive actions
     * that should immediately catch the eye.
     */
    confirmColorRed?: boolean;
}

import type { FabricObject } from 'fabric';

/**
 * Determine the blur factor for a fabric object based on its area.
 * Common use-cases for blurring are license plates and faces.
 * Therefore, the larger the object to blur, the more blur is needed in order to make the object unrecognizable.
 */
export function determineFabricObjectBlurFactor({
    object,
    min,
    max,
}: {
    object: FabricObject;
    min?: number;
    max?: number;
}): number {
    const area = object.width * object.scaleX * object.height * object.scaleY;
    /**
     * Calculate the blur factor such that an object contained by a rectangle of 100px x 100px has a blur factor of 0.1 so the object is not recognizable.
     * However, this number was primarily derived out of trial and error and might need to be adjusted based on the use-case.
     */
    let blurFactor = area / 100_000;

    // Ensure the blur factor is within the given bounds of (0.2, 0.5) as 0.1 might not be sufficient in some edge cases and a factor larger than 0.5 is not necessary and looks unnatural.
    blurFactor = Math.min(max ?? 0.5, blurFactor);
    blurFactor = Math.max(min ?? 0.2, blurFactor);
    return blurFactor;
}

<div class="dialog-overlay entry-dialog-container" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container">
        <h2>AUTOonline-Inserat verknüpfen</h2>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>
        <mat-form-field class="mat-block">
            <input
                matInput
                axTrim
                [(ngModel)]="autoonlineOfferId"
                (change)="validateAutoonlineOfferIdFormat()"
                placeholder="AUTOonline Objekt-ID"
                [axAutofocus]="true" />
            <mat-hint>
                <span class="error" *ngIf="isOfferIdFormatInvalid">Falsches Format. &nbsp;</span>
                <a href="https://wissen.autoixpert.de/hc/de/articles/10423855898130" target="_blank" rel="noopener">
                    So findest du die ID.
                </a>
            </mat-hint>
        </mat-form-field>

        <div class="date-and-time-group">
            <date-input
                [(date)]="offerReadyAt"
                placeholder="Gebotsfrist"
                matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"></date-input>
            <time-input [(time)]="offerReadyAt"></time-input>
        </div>

        <div class="dialog-buttons-container">
            <button
                (click)="autoonlineOfferId && offerReadyAt && emitObjectNumber()"
                [class.disabled]="!autoonlineOfferId || !offerReadyAt"
                [matTooltip]="getSubmitButtonTooltip()">
                Verknüpfen
            </button>
        </div>
    </div>
</div>

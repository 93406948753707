import { User } from '../../models/user/user';

export function getEmailFromOptions(user: User): {
    from: { name: string; address: string };
    replyTo?: { name: string; address: string };
} {
    // Use the user's separate email if he has entered one.
    if (isUserEmailAccountComplete(user)) {
        const displayName: string = user.emailAccount?.displayName || `${user.firstName} ${user.lastName}`;

        return {
            from: {
                name: displayName,
                address: getSenderEmail(user),
            },
        };
    } else {
        return {
            from: {
                name: `${user.firstName} ${user.lastName}`,
                address: 'app@de.autoixpert.de',
            },
            replyTo: {
                name: `${user.firstName} ${user.lastName}`,
                address: getSenderEmail(user),
            },
        };
    }
}

/**
 * Get the email address from which the user will send emails. This is also used for the reply-to address in case the user has incomplete email credentials and mails are sent through autoiXpert.
 * If the user entered a username only, use that as the sender email. Some browser autocompletes (wrongly) fill the username with the autoiXpert user name without providing email credentials.
 * If no credentials were entered, use the user's autoiXpert account email.
 */
export function getSenderEmail(user: User) {
    return user.emailAccount?.email || user.emailAccount?.username || user.email;
}

export function isUserEmailAccountComplete(user: User): boolean {
    /**
     * user.emailAccount.email is optional since if the username is an email, we'll use that as the sender address.
     */
    return !!(
        user?.emailAccount.username &&
        (user?.emailAccount.password || user?.emailAccount.oauthProvider) &&
        user?.emailAccount.host &&
        user?.emailAccount.port
    );
}

import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { WhiteButtonWithShadowComponent } from './white-button-with-shadow.component';

@NgModule({
    imports: [MatIconModule],
    declarations: [WhiteButtonWithShadowComponent],
    exports: [WhiteButtonWithShadowComponent],
})
export class WhiteButtonWithShadowModule {}

import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { ResidualValueExchangeId } from '../reports/residual-value/residual-value-offer';
import { GarageFeeSet } from './garage-fee-set';

export class ContactPerson implements DataTypeBase {
    constructor(contactPersonTemplate?: RequireSome<ContactPerson, 'organizationType'>) {
        Object.assign(this, contactPersonTemplate);
    }

    _id: string = generateId();
    _externalId: string = null; // Used for external API integrations

    salutation: string = null; // Anrede/Geschlecht
    firstName: string = null; // Vorname
    lastName: string = null; // Nachname
    email: string = null; // eMail
    phone: string = null; // Telefonnummer
    phone2: string = null;
    organization: string = null; // Organisation/Firma
    organizationType: OrganizationType = null; // Typ, z. B. garage, insurance, lawyer, or private for a private person
    streetAndHouseNumberOrLockbox: string = null; // Straße & Hausnummer
    birthdate?: string = null; // Only used with claimant
    addressAddition?: string = null;
    zip: string = null; // Postleitzahl
    city: string = null; // Stadt/Ort
    country?: string = null; // Currently not filled, but read in various places in the backend.
    website?: string = null; // Webseite

    /**
     * VAT = Value Added Tax = German "Umsatzsteuer"
     * Optional because only to be used with claimant.
     */
    mayDeductTaxes?: boolean;
    vatId?: string;

    // Garage Properties
    garageFeeSets?: GarageFeeSet[]; // Garage Fee Set. Only initialized when the contactPerson is loaded into the GarageFeesComponent.
    garageBrands?: string[]; // Free garages may also work on specific brands only.
    isBrandCertified?: boolean; // ist Markenwerkstatt -> TODO Can be removed after 2023-07-01 because we assume that people will have entered garage brands since 2022-12-01.
    garageCharacteristics?: string[]; // A garage can be tagged with things like "works with DAT/Audatex, supports electric vehicles etc.

    /**
     * The insurance is usually identified by the 'VU-Nummer', which is the registration number in the BaFin.
     * This number is often also referred to as 'GDV-Nummer'.
     */
    insuranceCompanyNumber?: string;
    /**
     * Some insurances have a special agreement with residual value exchanges that assessors may be allowed to use.
     * This is usually relevant for full / partial kasko reports which are ordered by the insurance.
     */
    availableQuotaOnResidualValueExchanges?: ResidualValueExchangeId[] = [];

    assignedBidderGroupIds?: string[]; // If the contact person is a residual value bidder, he shall carry an array with all ids of his assigned bidder groups
    datCustomerNumber?: number;
    iban?: string; // Optional and currently only used by claimant. Can be triggered through Three-Point-Menu.
    financiallyStable?: boolean; // Optional because only to be used with claimant

    debtorNumber?: string; // business partner account number for DATEV export
    /**
     * If true, report invoices for this claimant will be marked as receiving a collective invoice by default (no individual invoice written)
     */
    receivesCollectiveInvoice?: boolean;

    notes: string = null;

    _schemaVersion = 2 as const;
    _documentVersion: number = 0;
    createdBy: string = null;
    teamId: string = null;
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

export type OrganizationType =
    | 'authorOfDamage'
    | 'bidder'
    | 'claimant'
    | 'factoringProvider'
    | 'garage'
    | 'insurance'
    | 'intermediary'
    | 'lawyer'
    | 'leaseProvider'
    | 'ownerOfAuthorOfDamagesCar'
    | 'ownerOfClaimantsCar'
    | 'residualValueRequestRecipient'
    | 'seller'
    | 'visitLocationFavorite';

export const organizationTypes = [
    'authorOfDamage',
    'bidder',
    'claimant',
    'factoringProvider',
    'garage',
    'insurance',
    'intermediary',
    'lawyer',
    'leaseProvider',
    'ownerOfAuthorOfDamagesCar',
    'ownerOfClaimantsCar',
    'residualValueRequestRecipient',
    'seller',
    'visitLocationFavorite',
];

<!--============================================-->
<!-- Info on Selected Invoice -->
<!--============================================-->
<div id="invoice-info-panel">
    <div class="card-toolbar">
        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="settingsSubmenu">more_vert</mat-icon>
        <!--============================================-->
        <!-- Settings: Row Visibility -->
        <!--============================================-->
        <mat-menu #settingsSubmenu>
            <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="rowVisibilitySettingsMenu">
                <mat-icon class="outlined">table_rows</mat-icon>
                Zeilen konfigurieren
            </a>

            <mat-menu #rowVisibilitySettingsMenu>
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    (click)="
                        userPreferences.invoiceList_infoPanelInvoiceNumberShown =
                            !userPreferences.invoiceList_infoPanelInvoiceNumberShown
                    ">
                    Rechnungsnummer
                    <mat-slide-toggle
                        class="slide-toggle-small margin-left-15"
                        [(ngModel)]="userPreferences.invoiceList_infoPanelInvoiceNumberShown"
                        (click)="$event.stopPropagation()"></mat-slide-toggle>
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    (click)="
                        userPreferences.invoiceList_infoPanelLabelsShown =
                            !userPreferences.invoiceList_infoPanelLabelsShown
                    ">
                    Labels
                    <mat-slide-toggle
                        class="slide-toggle-small margin-left-15"
                        [(ngModel)]="userPreferences.invoiceList_infoPanelLabelsShown"
                        (click)="$event.stopPropagation()"></mat-slide-toggle>
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    (click)="
                        userPreferences.invoiceList_infoPanelNotesShown =
                            !userPreferences.invoiceList_infoPanelNotesShown
                    ">
                    Notizen
                    <mat-slide-toggle
                        class="slide-toggle-small margin-left-15"
                        [(ngModel)]="userPreferences.invoiceList_infoPanelNotesShown"
                        (click)="$event.stopPropagation()"></mat-slide-toggle>
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    (click)="
                        userPreferences.invoiceList_infoPanelAssociatedReportShown =
                            !userPreferences.invoiceList_infoPanelAssociatedReportShown
                    ">
                    Verknüpftes Gutachten
                    <mat-slide-toggle
                        class="slide-toggle-small margin-left-15"
                        [(ngModel)]="userPreferences.invoiceList_infoPanelAssociatedReportShown"
                        (click)="$event.stopPropagation()"></mat-slide-toggle>
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    (click)="
                        userPreferences.invoiceList_infoPanelTasksShown =
                            !userPreferences.invoiceList_infoPanelTasksShown
                    ">
                    Aufgaben
                    <mat-slide-toggle
                        class="slide-toggle-small margin-left-15"
                        [(ngModel)]="userPreferences.invoiceList_infoPanelTasksShown"
                        (click)="$event.stopPropagation()"></mat-slide-toggle>
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    (click)="
                        userPreferences.invoiceList_infoPanelInvolvedPartiesShown =
                            !userPreferences.invoiceList_infoPanelInvolvedPartiesShown
                    ">
                    Beteiligte
                    <mat-slide-toggle
                        class="slide-toggle-small margin-left-15"
                        [(ngModel)]="userPreferences.invoiceList_infoPanelInvolvedPartiesShown"
                        (click)="$event.stopPropagation()"></mat-slide-toggle>
                </a>
            </mat-menu>
        </mat-menu>
        <!--============================================-->
        <!-- END Settings: Row Visibility -->
        <!--============================================-->
    </div>
    <mat-icon class="dialog-close-icon" (click)="closePanel()" matTooltip="Ausblenden">close</mat-icon>

    <ng-container *ngIf="invoice">
        <display-internal-id [internalId]="invoice._id" entityName="Rechnung"></display-internal-id>
        <!--********** Heading **********-->
        <div id="heading-container" class="text-align-center">
            <h2>{{ getInvoiceTypeGerman() }}</h2>
            <div *ngIf="userPreferences.invoiceList_infoPanelInvoiceNumberShown" class="label">
                {{ invoice.number || 'keine Nummer' }}
            </div>
        </div>

        <section id="invoice-state-section">
            <!--============================================-->
            <!-- Next Reminder -->
            <!--============================================-->
            <div *ngIf="invoice.type === 'invoice'" id="next-reminder-row" class="info-panel-row">
                <!--********** Icon **********-->
                <div
                    class="info-panel-row_icon-container cursor-pointer"
                    (click)="paymentReminderDatepickerAnchor.openOverlay()">
                    <mat-icon>today</mat-icon>
                </div>

                <!--********** Text **********-->
                <div
                    class="info-panel-row_text-container cursor-pointer"
                    datePickerWithInputAnchor
                    [(date)]="invoice.nextPaymentReminderAt"
                    (dateChange)="saveInvoice()"
                    #paymentReminderDatepickerAnchor="datePickerWithInputAnchor">
                    <div *ngIf="invoice.nextPaymentReminderAt" class="info-panel-row_value">
                        {{ invoice.nextPaymentReminderAt | moment }}
                    </div>
                    <div class="info-panel-row_no-value" *ngIf="!invoice.nextPaymentReminderAt">Kein Datum</div>
                    <div
                        class="label"
                        matTooltip="Wann soll diese Rechnung wieder als zu mahnen markiert werden? Nützlich für die Filterung in der Rechnungsliste.{{
                            '\n\n'
                        }}Du kannst die Mahnfrist manuell überschreiben, falls z. B. ein Anwalt erst später als normalerweise gemahnt werden soll.">
                        Zu mahnen
                    </div>
                </div>

                <!--********** Action Button **********-->
                <div
                    class="info-panel-row_action-button dark-card-action-button"
                    (click)="emitPaymentReminderClickedEvent()">
                    Mahnen
                </div>
            </div>
            <!--============================================-->
            <!-- END Next Reminder -->
            <!--============================================-->

            <!--============================================-->
            <!-- Associated Assessor -->
            <!--============================================-->
            <div *ngIf="!invoice.isCollectiveInvoice" class="info-panel-row">
                <div class="info-panel-row_icon-container">
                    <user-avatar
                        [showEmptyState]="true"
                        [userId]="invoice.associatedAssessorId"
                        [size]="24"></user-avatar>
                </div>
                <div class="info-panel-row_text-container" *ngIf="invoice.associatedAssessorId">
                    <div class="info-panel-row_value">{{ getUserFullName(invoice.associatedAssessorId) }}</div>
                    <div class="label">Sachverständiger</div>
                </div>
                <div class="info-panel-row_no-value" *ngIf="!invoice.associatedAssessorId">Kein Sachverständiger</div>
            </div>
            <!--============================================-->
            <!-- END Associated Assessor -->
            <!--============================================-->

            <!--============================================-->
            <!-- Labels -->
            <!--============================================-->
            <div *ngIf="userPreferences.invoiceList_infoPanelLabelsShown" id="labels-row" class="info-panel-row">
                <!--********** Icon **********-->
                <div
                    id="labels-icon-container"
                    class="info-panel-row_icon-container cursor-pointer"
                    (click)="labelDropdownAnchor.openOverlay($event)"
                    matTooltip="Nutze Labels, um den Status festzuhalten oder um danach zu filtern.">
                    <mat-icon>sell</mat-icon>
                </div>

                <!--********** Text **********-->
                <div
                    class="info-panel-row_text-container"
                    labelDropdown
                    [(selectedLabels)]="invoice.labels"
                    [labelGroup]="'invoice'"
                    (selectedLabelsChange)="saveInvoice(); labelsChanged.emit()"
                    #labelDropdownAnchor="labelDropdownAnchor">
                    <label-display [labels]="invoice.labels" [darkMode]="true"></label-display>
                </div>
            </div>
            <!--============================================-->
            <!-- END Labels -->
            <!--============================================-->

            <!--============================================-->
            <!-- Notes -->
            <!--============================================-->
            <div *ngIf="userPreferences.invoiceList_infoPanelNotesShown" id="notes-row" class="info-panel-row">
                <!--********** Icon **********-->
                <div
                    class="info-panel-row_icon-container cursor-pointer"
                    (click)="internalNotesPanelAnchor.openNotesPanel()">
                    <mat-icon>notes</mat-icon>
                </div>

                <!--********** Text **********-->
                <div
                    class="info-panel-row_text-container cursor-pointer"
                    internal-notes-panel-anchor
                    [notes]="invoice.notes"
                    (change)="saveInvoice()"
                    #internalNotesPanelAnchor="internalNotesPanelAnchor">
                    <div *ngIf="invoice.notes.length" class="info-panel-row_label cursor-pointer">
                        {{ invoice.notes.length }} {{ invoice.notes.length === 1 ? 'Notiz' : 'Notizen' }}
                    </div>
                    <div class="info-panel-row_no-value" *ngIf="!invoice.notes.length">Keine Notizen</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Notes -->
            <!--============================================-->
        </section>

        <!--============================================-->
        <!-- Report Data -->
        <!--============================================-->
        <section *ngIf="invoice.reportIds?.length === 1 && userPreferences.invoiceList_infoPanelAssociatedReportShown">
            <div
                id="report-token-row"
                class="info-panel-row has-hover"
                (click)="openReportInNewTab()"
                matTooltip="Gutachten in neuem Tab öffnen">
                <!--********** Icon **********-->
                <div class="info-panel-row_icon-container">
                    <mat-icon>fingerprint</mat-icon>
                </div>

                <!--********** Text **********-->
                <div class="info-panel-row_text-container">
                    <div class="info-panel-row_value">
                        {{ invoice.reportsData?.[0]?.token }}
                    </div>
                    <div class="label">Aktenzeichen</div>
                </div>

                <!--********** Action Button **********-->
                <mat-icon id="open-report-in-new-tab-icon" class="small-icon">open_in_new</mat-icon>
            </div>
        </section>

        <!--============================================-->
        <!-- Task List -->
        <!--============================================-->
        <task-list-inline
            *ngIf="userPreferences.invoiceList_infoPanelTasksShown"
            [invoice]="invoice"></task-list-inline>
        <!--============================================-->
        <!-- END Task List -->
        <!--============================================-->

        <section *ngIf="invoice.reportIds?.length === 1 && userPreferences.invoiceList_infoPanelInvolvedPartiesShown">
            <!--********** Claimant **********-->
            <involved-party-contact-details
                *ngIf="isNameOrOrganizationFilled(invoice.claimant?.contactPerson)"
                [contactPerson]="invoice.claimant.contactPerson"
                [involvedPartyRole]="'claimant'"
                [reportType]="invoice.reportsData?.[0]?.type"
                [reportId]="invoice.reportIds?.[0]"
                [hasReceivedEmail]="invoice.claimant.receivedEmail"
                [hasReceivedLetter]="invoice.claimant.receivedLetter"></involved-party-contact-details>

            <!--********** Insurance **********-->
            <involved-party-contact-details
                *ngIf="isNameOrOrganizationFilled(invoice.insurance?.contactPerson)"
                [contactPerson]="invoice.insurance.contactPerson"
                [involvedPartyRole]="'insurance'"
                [reportType]="invoice.reportsData?.[0]?.type"
                [reportId]="invoice.reportIds?.[0]"
                [hasReceivedEmail]="invoice.insurance.receivedEmail"
                [hasReceivedLetter]="invoice.insurance.receivedLetter">
                <div *ngIf="invoice.reportsData?.[0]?.insuranceCaseNumber" matTooltip="Schadennummer">
                    <span class="label">SN:</span>
                    {{ invoice.reportsData?.[0]?.insuranceCaseNumber }}
                </div>
                <div *ngIf="invoice.reportsData?.[0]?.insuranceContractNumber" matTooltip="Versicherungsnummer">
                    <span class="label">VN:</span>
                    {{ invoice.reportsData?.[0]?.insuranceContractNumber }}
                </div>
            </involved-party-contact-details>

            <!--********** Lawyer **********-->
            <involved-party-contact-details
                *ngIf="isNameOrOrganizationFilled(invoice.lawyer?.contactPerson)"
                [contactPerson]="invoice.lawyer.contactPerson"
                [involvedPartyRole]="'lawyer'"
                [reportType]="invoice.reportsData?.[0]?.type"
                [reportId]="invoice.reportIds?.[0]"
                [hasReceivedEmail]="invoice.lawyer.receivedEmail"
                [hasReceivedLetter]="invoice.lawyer.receivedLetter">
                <div *ngIf="invoice.reportsData?.[0]?.lawyerCaseNumber" matTooltip="Aktenzeichen des Anwalts">
                    <span class="label">AZ:</span>
                    {{ invoice.reportsData?.[0]?.lawyerCaseNumber }}
                </div>
            </involved-party-contact-details>
        </section>
        <!--============================================-->
        <!-- END Report Data -->
        <!--============================================-->
    </ng-container>

    <!--============================================-->
    <!-- "No Invoice Selected" Placeholder -->
    <!--============================================-->
    <div id="no-invoice-selected_placeholder" *ngIf="!invoice">
        <div class="margin-bottom-30">
            <img src="/assets/images/icons/no-data-to-display.png" alt="" />
        </div>
        <span class="semibold">
            Bitte wähle eine
            <br />
            Rechnung aus.
        </span>
    </div>
    <!--============================================-->
    <!-- END "No Invoice Selected" Placeholder -->
    <!--============================================-->
</div>
<!--============================================-->
<!-- END Info on Selected Invoice -->
<!--============================================-->

<!--============================================-->
<!-- Credit Notes -->
<!--============================================-->
<!-- <credit-notes-list *ngIf="invoice"></credit-notes-list>-->
<!--============================================-->
<!-- END Credit Notes -->
<!--============================================-->

<h2 class="margin-bottom-10">Marktwertanalyse verknüpfen</h2>
<div class="label margin-bottom-30">Falls du die Analyse extern gestartet hast, verknüpfe sie hier nachträglich.</div>

<!--********** Close **********-->
<mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>

<!--********** Market Analysis ID **********-->
<div id="provider-and-id-input-container">
    <!--********** Provider Logo **********-->
    <div id="provider-logo-container">
        <img
            src="/assets/images/logos/cartv-logo.svg"
            *ngIf="marketAnalysisProviderName === 'CARTV'"
            alt="CARTV Logo" />

        <!-- VALUEpilot is currently (2024-05-30) not supported. -->
        <img
            src="/assets/images/logos/valuepilot.png"
            *ngIf="marketAnalysisProviderName === 'VALUEpilot'"
            alt="VALUEpilot Logo" />
        <img
            src="/assets/images/logos/winvalue.png"
            *ngIf="marketAnalysisProviderName === 'WinValue'"
            alt="Winvalue Logo" />
    </div>

    <!--********** Input **********-->
    <mat-form-field class="mat-block margin-bottom-20">
        <input
            matInput
            [(ngModel)]="marketAnalysisId"
            placeholder="{{ marketAnalysisProviderName }} Marktanalyse-ID"
            [axAutofocus]="true" />
        <mat-hint>
            <a
                class="secondary"
                *ngIf="marketAnalysisProviderName === 'CARTV'"
                href="https://wissen.autoixpert.de/hc/de/articles/19247403965586-CARTV-Marktwertanalyse-manuell-verkn%C3%BCpfen"
                target="_blank"
                rel="”noopener”">
                Wo finde ich die CARTV-ID?
            </a>
            <a
                class="secondary"
                *ngIf="marketAnalysisProviderName === 'WinValue'"
                href="https://wissen.autoixpert.de/hc/de/articles/19247196599058-WinValue-Marktwertanalyse-manuell-verkn%C3%BCpfen"
                target="_blank"
                rel="”noopener”">
                Wo finde ich die WinValue-ID?
            </a>
        </mat-hint>
    </mat-form-field>
</div>
<!--********** Submit Button **********-->
<div class="dialog-buttons-container">
    <button (click)="emitAnalysisId()" [class.disabled]="!marketAnalysisId" [matTooltip]="getSubmitButtonTooltip()">
        Verknüpfen
    </button>
</div>

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { generateId } from '@autoixpert/lib/generate-id';
import { getOutgoingMessageScheduledAt } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-scheduled-at';
import { OutgoingMessageSchedule } from '@autoixpert/models/outgoing-message';
import { EmailSchedulingOption } from '@autoixpert/models/teams/email-scheduling-option';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { TeamService } from '../../services/team.service';
import { AxDateAdapter } from '../date-input/ax-date-adapter';
import { AX_MOMENT_DATE_FORMATS } from '../date-input/ax-moment-date-formats';

export interface ScheduleEmailResult {
    schedule: OutgoingMessageSchedule;
}

@Component({
    selector: 'app-schedule-email-dialog',
    templateUrl: './schedule-email-dialog.component.html',
    styleUrls: ['./schedule-email-dialog.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: AxDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: AX_MOMENT_DATE_FORMATS,
        },
    ],
})
export class ScheduleEmailDialogComponent implements OnInit {
    team: Team;
    user: User;

    selectedOption: string = '';
    customDate: Date = new Date();
    customTime: string = '09:00';
    delayAmount: number = 10; // Default delay amount
    delayUnit: 'seconds' | 'minutes' | 'hours' = 'seconds'; // Default delay unit
    availableDelayUnits: { value: string; label: string }[] = [
        { value: 'seconds', label: 'Sekunden' },
        { value: 'minutes', label: 'Minuten' },
        { value: 'hours', label: 'Stunden' },
    ];

    // New properties for edit mode
    editMode: boolean = false;
    showAddOptionForm: boolean = false;
    newOptionForm: FormGroup;
    editingOption: EmailSchedulingOption | null = null;

    weekdays = [
        { value: 1, label: 'Montag' },
        { value: 2, label: 'Dienstag' },
        { value: 3, label: 'Mittwoch' },
        { value: 4, label: 'Donnerstag' },
        { value: 5, label: 'Freitag' },
        { value: 6, label: 'Samstag' },
        { value: 0, label: 'Sonntag' },
    ];

    subscriptions: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<ScheduleEmailDialogComponent>,
        private teamService: TeamService,
        private fb: FormBuilder,
        private loggedInUserService: LoggedInUserService,
    ) {
        this.newOptionForm = this.fb.group({
            name: ['', Validators.required],
            type: ['relative', Validators.required],
            daysOffset: [1, [Validators.required, Validators.min(0)]],
            weekday: [1, Validators.required],
            timeOfDay: ['09:00', Validators.required],
        });
    }

    ngOnInit(): void {
        this.initializeForm();

        this.subscriptions.push(
            this.loggedInUserService.getTeam$().subscribe(async (team) => {
                this.team = team;
                this.loadSchedulingOptions();
            }),
        );
    }

    initializeForm(): void {
        this.newOptionForm = this.fb.group({
            name: ['', Validators.required],
            type: ['relative', Validators.required],
            daysOffset: [1, [Validators.required, Validators.min(0)]],
            weekday: [1, Validators.required],
            timeOfDay: ['09:00', Validators.required],
        });
    }

    loadSchedulingOptions(): void {
        // Set the first option as selected by default
        if (this.team.preferences.emailSchedulingOptions.length > 0) {
            this.selectedOption = this.team.preferences.emailSchedulingOptions[0].id;
        } else {
            this.selectedOption = 'custom';
        }
    }

    async saveSchedulingOptions() {
        await this.teamService.put(this.team);
    }

    toggleEditMode(): void {
        this.editMode = !this.editMode;
        this.editingOption = null;
        this.resetNewOptionForm();
    }

    resetNewOptionForm(): void {
        this.newOptionForm.reset({
            name: '',
            type: 'relative',
            daysOffset: 1,
            weekday: 1,
            timeOfDay: '09:00',
        });
    }

    addNewOption(): void {
        if (this.newOptionForm.valid) {
            const formValue = this.newOptionForm.value;

            // Parse time string (HH:MM) to get hour and minute
            const [hours, minutes] = formValue.timeOfDay.split(':').map(Number);

            const newOption: EmailSchedulingOption = {
                id: this.editingOption ? this.editingOption.id : generateId(),
                name: formValue.name,
                type: formValue.type,
                config: {
                    hour: hours,
                    minute: minutes,
                },
            };

            if (formValue.type === 'relative') {
                newOption.config.daysOffset = formValue.daysOffset;
            } else if (formValue.type === 'next-weekday') {
                newOption.config.weekday = formValue.weekday;
            }

            if (this.editingOption) {
                // Update existing option
                const index = this.team.preferences.emailSchedulingOptions.findIndex(
                    (o) => o.id === this.editingOption.id,
                );
                if (index !== -1) {
                    this.team.preferences.emailSchedulingOptions[index] = newOption;
                }
            } else {
                // Add new option
                this.team.preferences.emailSchedulingOptions.push(newOption);
            }

            this.resetNewOptionForm();
            this.editingOption = null;
            this.showAddOptionForm = false;
            this.saveSchedulingOptions();
        }
    }

    editOption(option: EmailSchedulingOption): void {
        this.editingOption = option;

        // Format hour and minute to HH:MM string
        const timeString = `${String(option.config.hour).padStart(2, '0')}:${String(option.config.minute).padStart(2, '0')}`;

        this.newOptionForm.patchValue({
            name: option.name,
            type: option.type,
            daysOffset: option.config.daysOffset || 1,
            weekday: option.config.weekday || 1,
            timeOfDay: timeString,
        });
    }

    deleteOption(option: EmailSchedulingOption): void {
        const index = this.team.preferences.emailSchedulingOptions.findIndex((o) => o.id === option.id);
        if (index !== -1) {
            this.team.preferences.emailSchedulingOptions.splice(index, 1);
            this.saveSchedulingOptions();

            // If the deleted option was selected, reset selection
            if (this.selectedOption === option.id) {
                this.selectedOption =
                    this.team.preferences.emailSchedulingOptions.length > 0
                        ? this.team.preferences.emailSchedulingOptions[0].id
                        : 'custom';
            }
        }
    }

    cancelEdit(): void {
        this.editingOption = null;
        this.showAddOptionForm = false;
        this.resetNewOptionForm();
    }

    selectOption(optionId: string): void {
        this.selectedOption = optionId;

        if (optionId === 'custom') {
            // Set default custom values to tomorrow morning
            this.customDate = DateTime.now().plus({ days: 1 }).toJSDate();
            this.customTime = '09:00';
        }
    }

    getOptionDisplayTime(option: EmailSchedulingOption): string {
        let dateTime: DateTime;

        if (option.type === 'relative') {
            dateTime = DateTime.now().plus({ days: option.config.daysOffset });
            dateTime = dateTime.set({ hour: option.config.hour, minute: option.config.minute, second: 0 });
        } else if (option.type === 'next-weekday') {
            const now = DateTime.now();
            const targetWeekday = option.config.weekday;
            // Calculate days until the next occurrence of the target weekday
            const daysUntilTarget = (7 + targetWeekday - now.weekday) % 7;
            dateTime = now.plus({ days: daysUntilTarget > 0 ? daysUntilTarget : 7 });
            // Set the time
            dateTime = dateTime.set({ hour: option.config.hour, minute: option.config.minute, second: 0 });
        } else {
            return 'Custom';
        }

        // Format day abbreviation and time
        return this.formatDateTime(dateTime);
    }

    formatDateTime(dateTime: DateTime): string {
        // Format as "8. März, 08:00"
        return `${dateTime.toFormat('d')}. ${dateTime.toFormat('LLLL')}, ${dateTime.toFormat('HH:mm')}`;
    }

    getScheduleData(): ScheduleEmailResult | null {
        let schedule: OutgoingMessageSchedule;

        if (this.selectedOption === 'custom') {
            // Parse custom date and time
            if (this.customDate && this.customTime) {
                const [hours, minutes] = this.customTime.split(':').map(Number);
                // day offset based on custom date
                schedule = {
                    type: 'custom',
                    config: {
                        date: this.customDate.toISOString(),
                        hour: hours,
                        minute: minutes,
                    },
                };
            } else {
                return null;
            }
        } else {
            // Find the selected option
            const selectedOption = this.team.preferences.emailSchedulingOptions.find(
                (o) => o.id === this.selectedOption,
            );
            if (!selectedOption) {
                return null;
            }

            if (selectedOption.type === 'relative') {
                schedule = {
                    type: 'relative',
                    config: {
                        daysOffset: selectedOption.config.daysOffset,
                        hour: selectedOption.config.hour,
                        minute: selectedOption.config.minute,
                    },
                };
            } else if (selectedOption.type === 'next-weekday') {
                schedule = {
                    type: 'next-weekday',
                    config: {
                        weekday: selectedOption.config.weekday,
                        hour: selectedOption.config.hour,
                        minute: selectedOption.config.minute,
                    },
                };
            } else {
                return null;
            }
        }

        return {
            schedule,
        };
    }

    isValidSelection(): boolean {
        if (!this.selectedOption) {
            return false;
        }

        const scheduleData = this.getScheduleData();
        if (!scheduleData) {
            return false;
        }

        // Check if the scheduled time is in the past
        const scheduledAt = getOutgoingMessageScheduledAt(scheduleData.schedule);
        return !scheduledAt || scheduledAt > DateTime.now();
    }

    isPastDateTime(): boolean {
        const scheduleData = this.getScheduleData();
        if (!scheduleData) {
            return false;
        }

        const scheduledAt = getOutgoingMessageScheduledAt(scheduleData.schedule);
        return scheduledAt && scheduledAt < DateTime.now();
    }

    scheduleEmailSend(): void {
        const scheduleData = this.getScheduleData();
        if (scheduleData) {
            this.dialogRef.close(scheduleData);
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    toggleAddOptionForm(): void {
        this.showAddOptionForm = !this.showAddOptionForm;
        if (this.showAddOptionForm) {
            this.editingOption = null;
            this.resetNewOptionForm();
        }
    }

    // Method to handle drag and drop reordering
    dropOption(event: CdkDragDrop<EmailSchedulingOption[]>): void {
        moveItemInArray(this.team.preferences.emailSchedulingOptions, event.previousIndex, event.currentIndex);
        // Save the new order
        this.saveSchedulingOptions();
    }
}

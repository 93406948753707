import { MatDateFormats } from '@angular/material/core';

export const AX_MOMENT_MONTH_FORMATS: MatDateFormats = {
    display: {
        dateInput: 'MM.YYYY',
        dateA11yLabel: 'MM.YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
        monthYearLabel: 'MMM YYYY',
    },
    parse: {
        dateInput: [
            'MM.YYYY',
            'MM.YY',
            'MMYY',
            'MMYYYY',
            'MM/YYYY',
            'MM/YY',
            'DDMMYY',
            'DDMMYYYY',
            'DD.MM.YY',
            'MMM YY',
            'MMM YYYY',
            'MMMM YYYY',
        ],
    },
};

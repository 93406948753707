import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { GtmotiveEstimateListItem } from '@autoixpert/models/reports/damage-calculation/gtmotive-estimate-list-item';
import { Report } from '@autoixpert/models/reports/report';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { capitalize } from '../../../../shared/libraries/text-transformation/capitalize';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { GtmotiveEstimateService } from '../../../../shared/services/gtmotive/gtmotive-estimate.service';

@Component({
    selector: 'connect-gtmotive-estimate-dialog',
    templateUrl: 'connect-gtmotive-estimate-dialog.component.html',
    styleUrls: ['connect-gtmotive-estimate-dialog.component.scss'],
    animations: [fadeInAndSlideAnimation(), dialogEnterAndLeaveAnimation()],
})
export class ConnectGtmotiveEstimateDialogComponent implements OnInit {
    constructor(
        private gtmotiveEstimateService: GtmotiveEstimateService,
        private apiErrorService: ApiErrorService,
    ) {}

    public gtmotiveEstimates: GtmotiveEstimateListItem[] = [];
    public gtmotiveEstimatesPending: boolean;
    public selectedGtmotiveEstimate: GtmotiveEstimateListItem;

    public customGtmotiveEstimateId: string;
    public gtmotiveEstimateIdInputShown: boolean;

    @Input() licensePlate: string;
    @Input() vin: string;

    @Output() gtmotiveEstimateSelection: EventEmitter<Report['gtmotiveEstimateId']> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.loadGtmotiveEstimates();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Select GT Motive Estimate
    //****************************************************************************/
    public selectGtmotiveEstimate(gtmotiveEstimate: GtmotiveEstimateListItem): void {
        this.selectedGtmotiveEstimate = gtmotiveEstimate;
    }

    private preselectGtmotiveEstimateWithMatchingVehicle(): void {
        // Match by license plate
        if (this.licensePlate) {
            const matchingGtmotiveEstimate = this.gtmotiveEstimates.find(
                (gtmotiveEstimate) => gtmotiveEstimate.licensePlate === this.licensePlate,
            );
            if (matchingGtmotiveEstimate) {
                this.selectGtmotiveEstimate(matchingGtmotiveEstimate);
                return;
            }
        }

        // Match by VIN
        if (this.vin) {
            const matchingGtmotiveEstimate = this.gtmotiveEstimates.find(
                (gtmotiveEstimate) => gtmotiveEstimate.vin === this.vin,
            );
            if (matchingGtmotiveEstimate) {
                this.selectGtmotiveEstimate(matchingGtmotiveEstimate);
                return;
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select GT Motive Estimate
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load GT Motive Estimates
    //****************************************************************************/
    private async loadGtmotiveEstimates(): Promise<void> {
        this.gtmotiveEstimatesPending = true;

        try {
            this.gtmotiveEstimates = await this.gtmotiveEstimateService.findAll();
            this.gtmotiveEstimatesPending = false;
            this.preselectGtmotiveEstimateWithMatchingVehicle();
        } catch (error) {
            this.gtmotiveEstimatesPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                //handlers       : {
                //    ...getAudatexErrorHandlers()
                //},
                defaultHandler: {
                    title: 'GT-Motive-Vorgänge konnten nicht geladen werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load GT Motive Estimates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Brand Icons
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date & Time
    //****************************************************************************/
    public capitalize(text: string): string {
        return capitalize(text);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date & Time
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Dossier ID
    //****************************************************************************/
    public showGtmotiveEstimateIdInput(): void {
        // Clear the selection to indicate to the user which dossier will be used.
        this.selectedGtmotiveEstimate = null;
        this.gtmotiveEstimateIdInputShown = true;
    }

    public hideGtmotiveEstimateIdInput(): void {
        this.gtmotiveEstimateIdInputShown = false;
        this.customGtmotiveEstimateId = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Dossier ID
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Connection
    //****************************************************************************/
    public handleConnectionButtonClick(): void {
        if (this.selectedGtmotiveEstimate || this.customGtmotiveEstimateId) {
            this.emitGtmotiveEstimateSelection();
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Connection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/
    public emitGtmotiveEstimateSelection(): void {
        let gtmotiveEstimateId: Report['gtmotiveEstimateId'];
        if (this.gtmotiveEstimateIdInputShown) {
            gtmotiveEstimateId = this.customGtmotiveEstimateId;
        } else {
            gtmotiveEstimateId = this.selectedGtmotiveEstimate.gtmotiveEstimateId;
        }
        this.gtmotiveEstimateSelection.emit(gtmotiveEstimateId);
    }

    public closeDialog(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

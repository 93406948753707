import { Component, Input } from '@angular/core';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { downloadAnalyticsRecordsAsCsv } from '../../../shared/libraries/download-analytics-records-as-csv';
import { InvoicesWithReportsService } from '../../../shared/services/analytics/invoices-with-reports.service';
import { ApiErrorService } from '../../../shared/services/api-error.service';

@Component({
    selector: 'export-invoice-analytics-records-icon',
    templateUrl: 'export-invoice-analytics-records-icon.component.html',
})
export class ExportInvoiceAnalyticsRecordsIconComponent {
    constructor(
        private invoicesWithReportsService: InvoicesWithReportsService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() records: { idsOfAllInvoices: string[] }[];
    @Input() summaryDownloadName: string;

    public downloadSummaryAsCsv(): void {
        downloadAnalyticsRecordsAsCsv(this.records, this.summaryDownloadName);
    }

    public async downloadSingleRecordsAsCsv() {
        try {
            await this.invoicesWithReportsService.downloadRecordsAsCsv(
                this.records.map((record) => record.idsOfAllInvoices).flat(),
            );
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ACCESS_DENIED: (error: AxError) => ({
                        title: 'Keine Berechtigung',
                        body: `Für den Download aller Datensätze benötigst du das Zugriffsrecht "${translateAccessRightToGerman(error.data.accessRight)}".`,
                    }),
                },
                defaultHandler: {
                    title: 'Download nicht möglich',
                    body: 'Ein Fehler ist aufgetreten',
                },
            });
        }
    }
}

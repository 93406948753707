<div class="docx-watermark-card">
    <div class="card-toolbar">
        <!-- Download button with loading indicator -->
        <img
            class="pending-icon medium-icon toolbar-icon"
            src="/assets/images/icons/loading-indicator.svg"
            *ngIf="isDownloadPending"
            matTooltip="Lädt runter..." />
        <mat-icon
            class="upload-button medium-icon toolbar-icon"
            *ngIf="!isDownloadPending"
            (click)="downloadWatermark()"
            matTooltip="Vorlage herunterladen">
            file_download
        </mat-icon>

        <!-- Upload button with loading indicator -->
        <img
            class="pending-icon medium-icon toolbar-icon"
            src="/assets/images/icons/loading-indicator.svg"
            *ngIf="isUploadPending"
            matTooltip="Lädt hoch..." />
        <mat-icon
            class="upload-button medium-icon toolbar-icon"
            *ngIf="!isUploadPending"
            (click)="openFileSelector()"
            matTooltip="Neue Vorlage hochladen">
            file_upload
        </mat-icon>

        <!-- Menu with option to reset -->
        <mat-icon
            *ngIf="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks') && doesCustomTemplateExist()"
            class="medium-icon toolbar-icon"
            [matMenuTriggerFor]="docxWatermarkMenu">
            more_vert
        </mat-icon>
        <mat-menu #docxWatermarkMenu>
            <a mat-menu-item class="menu-item-with-icon alert" (click)="resetDocxWatermark()">
                <mat-icon>restart_alt</mat-icon>
                Zurücksetzen
            </a>
        </mat-menu>
    </div>

    <div class="label-and-blue-icon">
        <div class="docx-watermark-template-name">
            {{ watermarkType === 'duplicate' ? 'Duplikat' : 'Entwurf' }}
        </div>
        <div class="docx-watermark-template-icon-container">
            <mat-icon class="outlined">water_drop</mat-icon>
            <div class="watermark-type-abbreviation label">
                {{ watermarkType === 'duplicate' ? 'D' : 'E' }}
            </div>
        </div>
    </div>

    <!-- Hidden file input -->
    <input
        type="file"
        #fileUpload
        accept=".docx"
        (change)="onChangeWatermarkUploadFile($event.target)"
        style="display: none" />
</div>

import { getRepairEffortToReplacementValueRatio } from '@autoixpert/lib/damage-calculation-values/get-repair-effort-to-replacement-value-ratio';
import { ReportProgressConfig } from '@autoixpert/models/report-progress/report-progress-config';
import { ChecklistItem } from '@autoixpert/models/report-progress/report-progress-setting';
import { Report } from '@autoixpert/models/reports/report';

export function isChecklistItemRequired({
    item,
    reportProgressConfig,
    report,
}: {
    item: ChecklistItem;
    report: Report;
    reportProgressConfig: ReportProgressConfig;
}): boolean {
    if (item.name === 'residualValue') {
        // If the entire item is not required, don't bother about the ratio.
        if (!item.isRequired) return false;

        // If the threshold is empty, the field is always required.
        if (!reportProgressConfig.residualValueThresholdInPercent) return true;

        const ratioOfRepairCostsToReplacementEffort: number = getRepairEffortToReplacementValueRatio(report);

        // Threshold is saved as 70, if the user entered 70%.
        return ratioOfRepairCostsToReplacementEffort >= reportProgressConfig.residualValueThresholdInPercent / 100;
    }
    return item.isRequired;
}

<div id="dialog-wrapper">
    <form id="password-fields-container">
        <div class="hidden-username-container">
            <!-- Hidden input to prevent the browser from filling in the old email into the "new email" field -->
            <input type="email" name="username" placeholder="Benutzername" tabindex="-1" />
        </div>

        <!-- Headline container -->
        <div class="dialog-headline-container">
            <!-- Headline -->
            <h2>Benutzernamen ändern</h2>

            <!-- Subline -->
            <div *ngIf="isUpdateForOtherUser()" [matTooltip]="editedUser.email">für {{ getFullName(editedUser) }}</div>
        </div>

        <!-- New username -->
        <mat-form-field id="new-username-container" class="input-field-container">
            <input
                matInput
                type="email"
                placeholder="Neue E-Mail-Adresse"
                [(ngModel)]="newUsername"
                (keydown)="triggerFormOnEnterKey($event)"
                name="new-username" />
        </mat-form-field>

        <!-- Confirmation password form -->
        <mat-form-field id="password-container" class="input-field-container">
            <input
                matInput
                [type]="passwordShown ? 'text' : 'password'"
                [placeholder]="isUpdateForOtherUser() ? 'Dein Passwort (Admin)' : 'Passwort zur Bestätigung'"
                [(ngModel)]="password"
                (keydown)="triggerFormOnEnterKey($event)"
                name="password" />
            <mat-icon class="password-show-toggle small-icon" (click)="passwordShown = !passwordShown">
                remove_red_eye
            </mat-icon>
            <!-- Warning messages -->
            <mat-hint class="alert alert-danger">{{ warning }}</mat-hint>
        </mat-form-field>
    </form>

    <!-- Save button -->
    <div id="buttons-container">
        <button
            id="save-button"
            (click)="saveNewUsername()"
            [ngClass]="{ disabled: getSaveButtonTooltip() }"
            [matTooltip]="getSaveButtonTooltip()">
            <img
                id="save-button-loader"
                src="/assets/images/icons/loading-indicator-white.svg"
                alt=""
                *ngIf="loading" />
            Speichern
        </button>
    </div>

    <!-- Close button -->
    <mat-icon id="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
</div>

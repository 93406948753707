// Slide animation for the calculation provider logos
import { animate, sequence, style, transition, trigger } from '@angular/animations';

export function slideOutSide(enterDuration = 200, leaveDuration = 400) {
    return trigger('slideOutSide', [
        //*****************************************************************************
        //  Enter
        //****************************************************************************/
        transition(':enter', [
            style({
                maxWidth: 0,
                opacity: 0,
                marginLeft: 0,
            }),
            sequence([animate(`${enterDuration}ms ease`)]),
        ]),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Enter
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Leave
        //****************************************************************************/
        transition(':leave', [
            style({
                overflowX: 'hidden',
            }),
            sequence([
                animate(
                    `${leaveDuration}ms ease`,
                    style({
                        maxWidth: 0,
                        opacity: 0,
                        marginLeft: 0,
                    }),
                ),
                style({
                    overflowX: '*',
                }),
            ]),
        ]),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Leave
        /////////////////////////////////////////////////////////////////////////////*/
    ]);
}

<div
    class="preferences-row-container"
    [class.small-padding]="!iconName"
    [class.no-horizontal-padding]="noHorizontalPadding">
    <div class="preferences-row">
        <div class="preferences-row-icon">
            <mat-icon *ngIf="iconName" class="moderate-icon">{{ iconName }}</mat-icon>
        </div>

        <div class="preferences-row-labels">
            <!-- Title and description -->
            <ng-content></ng-content>
        </div>
        <div class="preferences-action">
            <!-- Control to the right -->
            <ng-content select="[right]"></ng-content>
        </div>
    </div>
    <!-- Nested rows (without border and without icon). These settings belong to this components general setting (often they are faded out, if this components toggle is disabled for example) -->
    <ng-content select="preferences-row"></ng-content>
    <ng-content select="[helpSection]"></ng-content>
</div>

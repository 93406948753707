// noinspection RegExpRedundantEscape
/**
 * Using the Angular email directive for validation is not enough, as it allows addresses without
 * top level domain (e.g. "some@example"). Because our users often forget to write the TLD, we
 * add a separate validation here that checks if the email contains at least a dot and two further characters
 * somewhere after the @ sign.
 *
 * Source: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
 *
 * Adjusted so that one-letter TLDs are not allowed.
 *
 * Use the v flag to match the default behavior of HTMLInput elements' pattern attribute.
 * v flag special behavior: More characters must be escaped before use in character classes (e.g. /, {, | and } must be escaped).
 */
const emailRegex =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    /[a-zA-Z0-9ÄÖÜäöüß.!#$&%'*+\/=?^_`\{\|\}~\-]+@[a-zA-Z0-9ÄÖÜäöüß](?:[a-zA-Z0-9ÄÖÜäöüß\-]{0,61}[a-zA-Z0-9ÄÖÜäöüß])?(?:\.[a-zA-Z0-9ÄÖÜäöüß](?:[a-zA-Z0-9ÄÖÜäöüß\-]{0,61}[a-zA-Z0-9ÄÖÜäöüß])+)+/v;

/**
 * Use `new RegExp()` to prevent an unsolvable TypeScript error when using the `v` flag. TypeScript wants us to escape
 * the ampersand but escaping it makes the regular expression invalid when having Angular compile it.
 *
 * Old macOS users (macs from 2011 to 2013 which can only be updated to macOS 10 [current version is 15]) have old Safari browsers, too. They cannot handle the V flag and throw an error
 * when using it as a parameter to the RegExp constructor. Therefore, we have to use the old way of creating a RegExp object.
 *
 * Solution: We removed the ampersand (&) above.
 */
// const emailRegex = new RegExp(
//     /[a-zA-Z0-9.!#$%&'*+\/=?^_`\{\|\}~\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])+)+/,
//     'v',
// );

/**
 * Regex that checks a single email address.
 */
export const EMAIL_VALIDATION_REGEX = new RegExp(`^${emailRegex.source}$`);

/**
 * Regex checks a list of email addresses separated by colons. Also allows whitespaces around the semicolon.
 * E.g. valid: 'some@example.com;other@domain.com' or 'some@example.com ; other@domain.com'
 */
export const EMAIL_LIST_WITH_SEMICOLON_VALIDATION_REGEX = new RegExp(
    `^(${emailRegex.source})(\\s*;\\s*${emailRegex.source})*$`,
);

// Array shape: [damageValue, gross fee, net fee]
export const hukFeeTable2021 = {
    year: 2021,
    table: [
        [500, 315.35, 265],
        [750, 354.62, 298],
        [1000, 443.87, 373],
        [1250, 490.28, 412],
        [1500, 527.17, 443],
        [1750, 561.68, 472],
        [2000, 589.05, 495],
        [2250, 616.42, 518],
        [2500, 643.79, 541],
        [2750, 668.78, 562],
        [3000, 692.58, 582],
        [3250, 716.38, 602],
        [3500, 738.99, 621],
        [3750, 761.6, 640],
        [4000, 785.4, 660],
        [4250, 804.44, 676],
        [4500, 828.24, 696],
        [4750, 846.09, 711],
        [5000, 865.13, 727],
        [5250, 885.36, 744],
        [5500, 904.4, 760],
        [5750, 923.44, 776],
        [6000, 943.67, 793],
        [6500, 972.23, 817],
        [7000, 999.6, 840],
        [7500, 1025.78, 862],
        [8000, 1060.29, 891],
        [8500, 1091.23, 917],
        [9000, 1123.36, 944],
        [9500, 1156.68, 972],
        [10000, 1190, 1000],
        [10500, 1225.7, 1030],
        [11000, 1255.45, 1055],
        [11500, 1286.39, 1081],
        [12000, 1317.33, 1107],
        [12500, 1349.46, 1134],
        [13000, 1387.54, 1166],
        [13500, 1420.86, 1194],
        [14000, 1448.23, 1217],
        [14500, 1483.93, 1247],
        [15000, 1514.87, 1273],
        [16000, 1570.8, 1320],
        [17000, 1623.16, 1364],
        [18000, 1676.71, 1409],
        [19000, 1726.69, 1451],
        [20000, 1782.62, 1498],
        [21000, 1843.31, 1549],
        [22000, 1899.24, 1596],
        [23000, 1955.17, 1643],
        [24000, 2009.91, 1689],
        [25000, 2070.6, 1740],
        [26000, 2143.19, 1801],
        [27000, 2203.88, 1852],
        [28000, 2255.05, 1895],
        [29000, 2308.6, 1940],
        [30000, 2387.14, 2006],
        [32500, 2497.81, 2099],
        [35000, 2608.48, 2192],
        [37500, 2710.82, 2278],
        [40000, 2845.29, 2391],
        [42500, 2938.11, 2469],
        [45000, 3077.34, 2586],
        [47500, 3172.54, 2666],
        [50000, 3282.02, 2758],
    ],
};

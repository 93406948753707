<section id="custom-car-properties-table">
    <div id="custom-car-properties-header">
        <div
            id="custom-car-properties-header-title"
            matTooltip="Die Bezeichnung wird in der linken Spalte der Fahrzeugdatentabelle angezeigt.">
            Bezeichnung
        </div>
        <div
            id="custom-car-properties-header-value"
            matTooltip="Der Inhalt wird in der rechten Spalte der Fahrzeugdatentabelle angezeigt.">
            Inhalt
        </div>
    </div>

    <div id="custom-car-property-rows" cdkDropList (cdkDropListDropped)="reorderCalculationItemsArray($event)">
        <div
            class="custom-car-property-row"
            *ngFor="let customProperty of report.car.customProperties; trackBy: trackById"
            cdkDrag
            [cdkDragDisabled]="isReportLocked">
            <!--********** Grab Handle **********-->
            <mat-icon
                class="data-field-grab-handle"
                matTooltip="Position verschieben"
                *ngIf="!isReportLocked"
                cdkDragHandle>
                drag_indicator
            </mat-icon>

            <custom-car-property-input-title
                class="custom-car-properties-input-title"
                [property]="customProperty"
                (propertyChanged)="emitChange()"
                [isReportLocked]="isReportLocked"
                [availableProperties]="availablePropertyTitlesForAutocomplete"></custom-car-property-input-title>

            <custom-car-property-input-value
                class="custom-car-properties-input-value"
                [property]="customProperty"
                (propertyChanged)="emitChange()"
                [isReportLocked]="isReportLocked"
                [currentPropertyAutocompleteValues]="
                    getAutocompleteValuesForTitle(customProperty.title)
                "></custom-car-property-input-value>

            <!-- ********** Delete ********** -->
            <mat-icon
                class="delete-icon toolbar-icon"
                matTooltip="Position entfernen"
                (click)="removeDataField(customProperty)"
                *ngIf="!isReportLocked">
                delete
            </mat-icon>
        </div>
    </div>
    <div *ngIf="!isReportLocked" class="add-new-data-field">
        <button class="flat" (click)="addDataField()" [disabled]="isReportLocked">+ Weitere Eigenschaft</button>
    </div>
</section>

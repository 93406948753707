import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';
import { Timestamps } from '../../timestamps';
import { ResidualValueContactPerson } from './residual-value-contact-person';

export class ResidualValueRequest {
    bindingPeriodInDays: number = null;
    // Array of objects containing data on residual value invitations.
    invitationReceipts: IResidualValueInvitationReceipt[] = [];
    // Contact Person IDs of people who should receive an invitation.
    selectedRecipientIds: string[] = [];
    // The contactPerson ID of the bidder who received the car in the end. Used for reporting.
    winningBidderId: string = null;
    closingDate: string = null;
    retrievedAt: string = null;

    // We need the information to decide whether an offer belongs to the current report or an original report (e.g. in amendment report or copied report)
    createdInReportId: string;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

export class IResidualValueInvitationReceipt {
    _id: string;
    invitationId: string;
    contactPersonId: string;
    openedResidualValueRequestAt: string | Date;
    emailSentAt: string | Date;
}

export class ResidualValueInvitation implements Timestamps {
    constructor(template: Partial<ResidualValueInvitation>) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    recipient: ResidualValueContactPerson;
    residualValueRequestId: string;
    openedByRecipientAt: string;
    bidValue: number;
    bidValueSetAt: string;
    notInterested: boolean;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

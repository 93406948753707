import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { addDocumentOrderToReportPerRecipient } from '@autoixpert/lib/documents/add-document-order-to-report-per-recipient';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { getDocumentOrderForRecipient } from '@autoixpert/lib/documents/get-document-order-for-recipient';
import { getOrderedDocuments } from '@autoixpert/lib/documents/get-ordered-documents';
import { removeDocumentFromReport } from '@autoixpert/lib/documents/remove-document-from-report';
import { determineFeePreferencesKey } from '@autoixpert/lib/fee-calculation/determine-fee-preference-key';
import { createInvoiceFromInvoiceParameters } from '@autoixpert/lib/invoices/create-invoice-from-invoice-parameters';
import { round } from '@autoixpert/lib/numbers/round';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { OutgoingEmailMessage, OutgoingMessage } from '@autoixpert/models/outgoing-message';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { CommunicationRecipient } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { FeePreferencesKey } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { getInvoiceApiErrorHandlers } from '../../../shared/libraries/error-handlers/get-invoice-api-error-handlers';
import { getVatRateForTeam } from '../../../shared/libraries/get-vat-rate-2020';
import { getCommunicationRecipients } from '../../../shared/libraries/report-properties/get-communication-recipients';
import {
    REPAIR_CONFIRMATION_PHOTO_LINE_ITEM_DESCRIPTION,
    generateRepairConfirmationObject,
} from '../../../shared/libraries/report/generate-repair-confirmation-object';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { DownloadService } from '../../../shared/services/download.service';
import { InvoiceNumberService } from '../../../shared/services/invoice-number.service';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { RenderedPhotoFileService } from '../../../shared/services/rendered-photo-file.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { UserService } from '../../../shared/services/user.service';

@Component({
    selector: 'repair-confirmation',
    templateUrl: 'repair-confirmation.component.html',
    styleUrls: ['repair-confirmation.component.scss'],
})
export class RepairConfirmationComponent implements OnInit, OnDestroy {
    constructor(
        private reportDetailsService: ReportDetailsService,
        private route: ActivatedRoute,
        private router: Router,
        private httpClient: HttpClient,
        private renderedPhotoFileService: RenderedPhotoFileService,
        private domSanitizer: DomSanitizer,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        public userPreferences: UserPreferencesService,
        private invoiceNumberService: InvoiceNumberService,
        private invoiceService: InvoiceService,
        private downloadService: DownloadService,
        private apiErrorService: ApiErrorService,
        private userService: UserService,
        private networkStatusService: NetworkStatusService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    public report: Report;
    public reportId: string;
    public user: User;
    private team: Team;
    private subscriptions: Subscription[] = [];

    private localThumbnailFileUrls = new Map<Photo['_id'], string>();

    confirmationPhotos: Photo[] = [];

    public assessors: User[] = [];

    public repairConfirmationPreviewDownloadPending = false;
    public showRepairConfirmationCommentTextTemplateSelector = false;
    public invoicePreviewDownloadPending = false;
    public invoiceCreationPending = false;
    public lastInvoiceNumber: string;

    public feeLineItem: LineItem = null;
    public photoLineItem: LineItem = null;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit(): void {
        this.extractRouteParameter();
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();
        const reportSubscription = this.reportDetailsService.get(this.reportId).subscribe({
            next: (report) => {
                this.report = report;

                // create repair confirmation objects
                this.createRepairConfirmationObject();

                // photos
                this.getConfirmationPhotos();
                this.refreshNumberOfPhotosOnInvoice();

                // data from report
                this.fillInInvoiceDate();
                this.fillInDaysUntilDue();
                this.determineVat();
                this.loadAssessorsFromTeam();
                this.fillInAssessor();

                // set up handles
                this.setupLineItemShorthands();

                // get user preferences
                this.getFeeFromPreferences();
                this.getPhotoPriceFromPreferences();

                // save the automatically inserted data
                this.saveReport();
            },
        });

        this.subscriptions.push(reportSubscription);
    }

    /**
     * Get the current report ID. This is needed to load the report from the server or from local storage.
     */
    private extractRouteParameter() {
        this.subscriptions.push(
            this.route.parent.params.subscribe((params) => {
                this.reportId = params['reportId'];
            }),
        );
    }

    /**
     * Create a repairConfirmation object on the report if there is none.
     */
    private createRepairConfirmationObject(): void {
        if (!this.report.repairConfirmation) {
            // Add repair confirmation object itself
            this.report.repairConfirmation = generateRepairConfirmationObject({ team: this.team });
        }

        if (!this.report.repairConfirmation.documentOrders?.length) {
            // Add document state objects for the print and transmission screen
            this.report.repairConfirmation.documentOrders = [];

            /**
             * This allows documents to be sorted - even individually per recipient. The latter is an optional team setting.
             */
            addDocumentOrderToReportPerRecipient({
                report: this.report,
                documentGroup: 'repairConfirmation',
            });

            addDocumentToReport({
                report: this.report,
                team: this.team,
                documentGroup: 'repairConfirmation',
                newDocument: new DocumentMetadata({
                    type: 'repairConfirmation',
                    title: 'Reparaturbestätigung',
                    createdBy: this.user._id,
                }),
            });
            addDocumentToReport(
                {
                    report: this.report,
                    team: this.team,
                    documentGroup: 'repairConfirmation',
                    newDocument: new DocumentMetadata({
                        type: 'invoice',
                        title: 'Rechnung',
                        createdBy: this.user._id,
                    }),
                },
                {
                    /**
                     * Since the report invoice usually already exists, allow this repair confirmation invoice to be added explicitly.
                     */
                    allowMultiple: true,
                },
            );

            /**
             * Add cover letters and document emails per recipient.
             */
            const communicationRecipients: CommunicationRecipient[] = getCommunicationRecipients(this.report);

            for (const communicationRecipient of communicationRecipients) {
                addDocumentToReport(
                    {
                        report: this.report,
                        team: this.team,
                        documentGroup: 'repairConfirmation',
                        newDocument: new DocumentMetadata({
                            type: 'letter',
                            recipientRole: communicationRecipient.role,
                            title: 'Anschreiben',
                            createdBy: this.user._id,
                        }),
                    },
                    {
                        allowMultiple: true,
                    },
                );
                this.report[communicationRecipient.role].outgoingMessageDraft.repairConfirmation =
                    new OutgoingEmailMessage({
                        isDatVxsAttached: this.userPreferences.attachDatVxs,
                    });
            }
        }
    }

    private async getConfirmationPhotos(): Promise<void> {
        this.confirmationPhotos = this.report.photos.filter((photo) => photo.versions.repairConfirmation.included);

        // Get the thumbnails of the first two photos
        const firstTwoPhotos = this.confirmationPhotos.slice(0, 2);
        for (const photo of firstTwoPhotos) {
            // If the photo already exists, don't query a second time.
            if (this.localThumbnailFileUrls.has(photo._id)) {
                continue;
            }
            try {
                const renderedPhotoBlob: Blob = await this.renderedPhotoFileService.getFile({
                    reportId: this.report._id,
                    photo,
                    version: 'repairConfirmation',
                    format: 'thumbnail400',
                });
                this.localThumbnailFileUrls.set(photo._id, window.URL.createObjectURL(renderedPhotoBlob));
            } catch (error) {
                console.error('Error retrieving the thumbnail file.', { error });
            }
        }
    }

    private loadAssessorsFromTeam() {
        this.assessors = this.userService
            .getAllTeamMembersFromCache()
            .filter((teamMember) => teamMember.active && teamMember.isAssessor);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Data
    //****************************************************************************/
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    protected iconForCarBrandExists = iconForCarBrandExists;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Confirmation Photos
    //****************************************************************************/
    /**
     * Return either the local photo URI or null if the photo has not yet been loaded from the server.
     */
    public getLocalThumbnailUrl(photoId: string): SafeResourceUrl {
        return this.localThumbnailFileUrls.has(photoId)
            ? this.domSanitizer.bypassSecurityTrustResourceUrl(this.localThumbnailFileUrls.get(photoId))
            : null;
    }

    public navigateToPhotos(): void {
        this.router.navigate(['Fotos'], {
            relativeTo: this.route.parent,
            queryParams: {
                fotogruppe: 'Reparaturbestätigung',
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Confirmation Photos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Reinspection
    //****************************************************************************/
    public getUsersFullName(userId: string): string {
        const user: User = this.assessors.find((assessor) => assessor._id === userId);

        if (!user) {
            return '';
        }

        return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    }

    public selectExecutionType(repairExecutionType: RepairExecutionTypes): void {
        this.report.repairConfirmation.repairExecutionType = repairExecutionType;
        this.saveReport();
    }

    /**
     * If the user has not yet entered an actual downtime, fill in the value from the damage calculation.
     */
    public fillInEstimatedDowntime(): void {
        this.report.repairConfirmation.actualReparationDowntime =
            this.report.damageCalculation.downtimeInWorkdaysDueToReparation;
        this.saveReport();
    }

    public downloadRepairConfirmationPreview(): void {
        // prevent a second download request
        if (this.repairConfirmationPreviewDownloadPending) {
            return;
        }

        this.repairConfirmationPreviewDownloadPending = true;

        this.httpClient
            .get(`/api/v0/reports/${this.report._id}/documents/repairConfirmation?format=pdf`, {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe({
                next: (response) => {
                    this.repairConfirmationPreviewDownloadPending = false;
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.repairConfirmationPreviewDownloadPending = false;
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Fehler beim Download',
                            body: 'Die PDF-Datei konnte nicht heruntergeladen werden.',
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reinspection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice
    //****************************************************************************/

    public addInvoiceLineItem(): void {
        if (this.isInvoiceLocked()) {
            return;
        }

        this.report.repairConfirmation.invoiceParameters.lineItems.push(
            new LineItem({
                position: this.report.repairConfirmation.invoiceParameters.lineItems.length + 1,
                quantity: 1,
                active: true,
            }),
        );
    }

    public removeInvoiceLineItem(invoiceLineItem: LineItem): void {
        const index = this.report.repairConfirmation.invoiceParameters.lineItems.indexOf(invoiceLineItem);
        this.report.repairConfirmation.invoiceParameters.lineItems.splice(index, 1);
    }

    public getInvoiceTotalNet(): number {
        return this.report.repairConfirmation.invoiceParameters.lineItems
            .filter((lineItem) => lineItem.active)
            .reduce((total, currentValue) => total + (currentValue.unitPrice || 0) * (currentValue.quantity || 0), 0);
    }

    public getInvoiceTotalGross(): number {
        return round(this.getInvoiceTotalNet() * (1 + this.report.repairConfirmation.invoiceParameters.vatRate));
    }

    public navigateToPrintAndTransmission(): void {
        this.router.navigate(['Druck-und-Versand'], {
            relativeTo: this.route.parent,
            queryParams: {
                dokumentgruppe: 'Reparaturbestätigung',
            },
        });
    }

    public async generateInvoiceNumber(): Promise<void> {
        // Since we wouldn't always use the up-to-date counter, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Nur online kann sichergestellt werden, dass der Zähler über alle Geräte hinweg eindeutig vergeben wird.',
            );
            return;
        }

        const responsibleAssessorId: string = this.report.repairConfirmation
            ? this.report.repairConfirmation.assessor
            : null;

        try {
            const invoiceNumber = await this.invoiceNumberService.generateInvoiceNumber({
                officeLocationId: this.report.officeLocationId,
                responsibleAssessorId,
                report: this.report,
            });

            const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.report.officeLocationId);
            this.report.repairConfirmation.invoiceParameters.number = invoiceNumber;
            await this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceNumberGeneratedManually',
                documentType: 'repairConfirmation',
                reportId: this.report._id,
                invoiceNumber,
                invoiceNumberConfigId: invoiceNumberConfig._id,
            });
        } catch (error) {
            console.error('Error when generating an invoice number: ', { error });
            this.toastService.error(
                'Rechnungsnummer konnte nicht generiert werden.',
                "Bitte prüfe deine Internetverbindung oder wende dich an die <a href='/Hilfe'>Hotline</a>.",
            );
            return;
        }
        await this.saveReport();
    }

    /**
     * Create handles for the fee and photo line items
     */
    public setupLineItemShorthands(): void {
        this.feeLineItem = this.report.repairConfirmation.invoiceParameters.lineItems[0];
        this.photoLineItem = this.report.repairConfirmation.invoiceParameters.lineItems[1];
    }

    public refreshNumberOfPhotosOnInvoice(): void {
        if (this.isInvoiceLocked()) return;

        const photosLineItem = this.report.repairConfirmation.invoiceParameters.lineItems.find(
            (lineItem) => lineItem.description === REPAIR_CONFIRMATION_PHOTO_LINE_ITEM_DESCRIPTION,
        );
        if (!photosLineItem) {
            console.error('Photos line item not found. It is required for setting the number of photos.');
            return;
        }

        // Get the number of photos relevant for the repair confirmation
        photosLineItem.quantity = this.confirmationPhotos.length;
    }

    /**
     * Retrieve the standard fee for a repair confirmation from the user's preferences
     */
    public getFeeFromPreferences(): void {
        if (this.isInvoiceLocked()) return;

        if (!this.feeLineItem.unitPrice) {
            this.feeLineItem.unitPrice = this.userPreferences.repairConfirmationFee || null;
        }
    }

    /**
     * Set the fee for a repair confirmation on the user's preferences
     */
    public rememberRepairConfirmationFee(value: number): void {
        this.userPreferences.repairConfirmationFee = value;
    }

    /**
     * Get the default photo price from the user's preferences
     */
    public getPhotoPriceFromPreferences(): void {
        if (this.isInvoiceLocked()) return;

        const feePreferencesKey: FeePreferencesKey = determineFeePreferencesKey(
            this.report.type,
            this.team.preferences.defaultFeeTableLiability,
        );

        if (!this.photoLineItem.unitPrice) {
            this.photoLineItem.unitPrice =
                this.team.preferences[feePreferencesKey].pricePerPhoto ||
                this.team.preferences.defaultFees?.pricePerPhoto;
        }
    }

    public fillInInvoiceDate(): void {
        if (!this.report.repairConfirmation.invoiceParameters.date) {
            this.report.repairConfirmation.invoiceParameters.date = todayIso();
        }
    }

    public fillInDaysUntilDue(): void {
        if (!this.report.repairConfirmation.invoiceParameters.daysUntilDue) {
            this.report.repairConfirmation.invoiceParameters.daysUntilDue =
                this.report.feeCalculation.invoiceParameters.daysUntilDue;
        }
    }

    public determineVat(): void {
        const invoiceDate = this.report.repairConfirmation.invoiceParameters.date;
        this.report.repairConfirmation.invoiceParameters.vatRate = getVatRateForTeam(
            this.team.invoicing.vatRate,
            invoiceDate || undefined,
        );
    }

    private fillInAssessor(): void {
        if (!this.report.repairConfirmation.assessor) {
            this.report.repairConfirmation.assessor = this.report.visits[0].assessor;
        }
        if (!this.report.repairConfirmation.visitingAssessor) {
            this.report.repairConfirmation.visitingAssessor = this.report.visits[0].assessor;
        }
    }

    /**
     * Add the fixed invoice positions to a fresh invoice object,
     * and create the invoice on the server.
     */
    public async createInvoice(): Promise<void> {
        // Since we wouldn't always use the up-to-date counter, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Nur online kann sichergestellt werden, dass der Zähler über alle Geräte hinweg eindeutig vergeben wird.',
            );
            return;
        }

        const invoice = createInvoiceFromInvoiceParameters({
            invoiceParameters: this.report.repairConfirmation.invoiceParameters,
            report: this.report,
            user: this.user,
            team: this.team,
        });

        // Set the responsible assessor of the repair confirmation as associated assessor for the invoice
        if (this.report.repairConfirmation.assessor) {
            invoice.associatedAssessorId = this.report.repairConfirmation.assessor;
        }

        // If the total net is negative, it's a credit note. This is probably no relevant use case but keeps existing behavior.
        if (invoice.totalNet < 0) {
            invoice.type = 'creditNote';
        }

        const responsibleAssessorId: string = this.report.repairConfirmation.assessor;

        //*****************************************************************************
        //  Set invoice number
        //****************************************************************************/
        // Create an invoice number if missing.
        if (!invoice.number) {
            try {
                const invoiceNumber: string = await this.invoiceNumberService.generateInvoiceNumber({
                    officeLocationId: this.report.officeLocationId,
                    responsibleAssessorId,
                    report: this.report,
                });
                this.report.repairConfirmation.invoiceParameters.number = invoiceNumber;
                invoice.number = invoiceNumber;

                const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(
                    this.report.officeLocationId,
                );

                await this.invoiceNumberJournalEntryService.create({
                    entryType: 'invoiceNumberGeneratedOnCreation',
                    documentType: 'repairConfirmation',
                    reportId: this.report._id,
                    invoiceId: invoice._id,
                    invoiceNumber,
                    invoiceNumberConfigId: invoiceNumberConfig._id,
                });
            } catch (error) {
                console.error('ERROR_GENERATING_INVOICE_NUMBER', { error });
                this.toastService.error('Keine Rechnungsnummer', 'Rechnungsnummer konnte nicht generiert werden');
                return;
            }
        }
        //*****************************************************************************
        //  END Set invoice number
        //****************************************************************************/

        //*****************************************************************************
        //  Save to Server
        //****************************************************************************/
        try {
            // Wait for the server to confirm that the invoice number doesn't yet exist.
            await this.invoiceService.create(invoice, { waitForServer: true });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getInvoiceApiErrorHandlers(),
                },
                defaultHandler: { title: 'Rechnung konnte nicht generiert werden' },
            });
        } finally {
            this.invoiceCreationPending = false;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Save to Server
        /////////////////////////////////////////////////////////////////////////////*/

        this.invoiceCreationPending = false;
        this.toastService.success(`Rechnung ${invoice.number} erstellt`, 'Du findest sie in der Rechnungsliste');
        this.report.repairConfirmation.invoiceParameters._id = invoice._id;
        await this.saveReport();
    }

    public async downloadInvoicePreview(): Promise<void> {
        // Prevent a second download request
        if (this.invoicePreviewDownloadPending) {
            return;
        }

        this.invoicePreviewDownloadPending = true;

        const invoice: Invoice = createInvoiceFromInvoiceParameters({
            invoiceParameters: this.report.repairConfirmation.invoiceParameters,
            report: this.report,
            user: this.user,
            team: this.team,
        });

        try {
            const response = await this.invoiceService.getInvoicePreview(invoice);
            this.downloadService.downloadBlobResponseWithHeaders(response);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Fehler beim Download`,
                    body: `Die Rechnungsvorschau konnte nicht erstellt werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        } finally {
            this.invoicePreviewDownloadPending = false;
        }
    }

    /**
     * After an invoice has been locked, it can be re-opened and edited. This deletes the invoice record.
     * The revised version must be re-submitted.
     */
    public async makeInvoiceEditable(): Promise<void> {
        // We want instant feedback, so we delete the ID outside the callback (see below). For the DELETE call, we need to hold on to this ID here though.
        const oldInvoiceId: string = this.report.repairConfirmation.invoiceParameters._id;

        //*****************************************************************************
        //  Remove existing invoice
        //****************************************************************************/
        let existingInvoice: Invoice;

        existingInvoiceTryCatch: try {
            existingInvoice = await this.invoiceService.get(this.report.repairConfirmation.invoiceParameters._id);
        } catch (error) {
            // Swallow NOT_FOUND errors because when making the invoice params editable here, the invoice not being present in the invoice list is our target state.
            if ('code' in error && error.code !== 'NOT_FOUND') {
                break existingInvoiceTryCatch;
            }
            throw error;
        }

        if (existingInvoice) {
            if (existingInvoice.lockedAt) {
                this.toastService.error(
                    `Rechnung bereits gebucht`,
                    `Sie wurde nicht gelöscht. Bitte prüfe, ob du die <a href="/Rechnungen/${existingInvoice._id}" target="_blank">alte Rechnung ${existingInvoice.number}</a> stornieren solltest, falls du nun eine neue anlegen möchtest.`,
                );
                return;
            } else {
                // Opening a locked repair confirmation invoice removes this invoice so that the edited invoice can be saved anew.
                await this.invoiceService.delete(oldInvoiceId);
            }
        }

        // Remove the flag than an invoice exists.
        this.report.repairConfirmation.invoiceParameters._id = null;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Remove existing invoice
        /////////////////////////////////////////////////////////////////////////////*/

        // They couldn't be updated while this invoice was locked. Now being editable, the invoice may be updated.
        this.refreshNumberOfPhotosOnInvoice();

        await this.saveReport();
    }

    public isInvoiceLocked(): boolean {
        return !!this.report.repairConfirmation.invoiceParameters._id;
    }

    async handleInvoiceNumberChange() {
        await this.saveReport();

        const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.report.officeLocationId);
        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceNumberChangedManually',
            documentType: 'repairConfirmation',
            invoiceNumber: this.report.repairConfirmation.invoiceParameters.number,
            previousInvoiceNumber: this.lastInvoiceNumber,
            reportId: this.report._id,
            invoiceNumberConfigId: invoiceNumberConfig._id,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Repair Confirmation Removal
    //****************************************************************************/
    public deleteRepairConfirmation(): void {
        const repairConfirmation = this.report.repairConfirmation;

        const repairConfirmationDocuments: DocumentMetadata[] = [];
        const communicationRecipients: CommunicationRecipient[] = getCommunicationRecipients(this.report);
        for (const communicationRecipient of communicationRecipients) {
            const documentOrder: DocumentOrder = getDocumentOrderForRecipient(
                this.report.repairConfirmation.documentOrders,
                communicationRecipient.role,
            );

            /**
             * In case the user wants to restore the deleted repair confirmation, all documents which are deleted must be tracked.
             */
            repairConfirmationDocuments.push(
                ...getOrderedDocuments({
                    documents: this.report.documents,
                    documentOrder,
                }),
            );

            for (const repairConfirmationDocument of repairConfirmationDocuments) {
                removeDocumentFromReport({
                    document: repairConfirmationDocument,
                    report: this.report,
                    documentGroup: 'repairConfirmation',
                });
            }
        }

        this.report.repairConfirmation = null;

        this.saveReport();

        const toast = this.toastService.info('Reparaturbestätigung gelöscht', 'Klicken um sie wiederherzustellen', {
            showProgressBar: true,
            timeOut: 10000,
        }); //RestorationToast
        toast.click.subscribe(() => {
            this.report.repairConfirmation = repairConfirmation;

            /**
             * Add blank document orders for each recipient. Then add all documents anew. That might change the order but
             * guarantees a clean reinsertion.
             */
            addDocumentOrderToReportPerRecipient({
                report: this.report,
                documentGroup: 'repairConfirmation',
            });
            for (const repairConfirmationDocument of repairConfirmationDocuments) {
                addDocumentToReport(
                    {
                        report: this.report,
                        team: this.team,
                        documentGroup: 'repairConfirmation',
                        newDocument: repairConfirmationDocument,
                    },
                    {
                        /**
                         * Since the deleted documents usually contain a letter per recipient, allow adding back the
                         * same type multiple times.
                         */
                        allowMultiple: true,
                    },
                );
            }

            this.router.navigate(['./Reparaturbestätigung'], { relativeTo: this.route.parent });
        });
        this.router.navigate(['Druck-und-Versand'], { relativeTo: this.route.parent });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Repair Confirmation Removal
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public async saveReport(): Promise<void> {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.error(
                'Gutachten konnte nicht gespeichert werden',
                'Bitte versuche es später noch einmal oder kontaktiere die aX Hotline',
            );
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Destruction
    //****************************************************************************/
    ngOnDestroy(): void {
        // Thumbnail URLs are no longer needed. Free their memory.
        this.localThumbnailFileUrls.forEach((objectUrl) => {
            window.URL.revokeObjectURL(objectUrl);
        });

        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Destruction
    /////////////////////////////////////////////////////////////////////////////*/

    public repairExecutionTypes: {
        name: RepairExecutionTypes;
        title: string;
        iconName: string;
    }[] = [
        {
            name: 'repaired',
            title: 'repariert',
            iconName: 'check',
        },
        {
            name: 'professional',
            title: 'fach- & sachgerecht',
            iconName: 'verified',
        },
        {
            name: 'partial',
            title: 'teilweise',
            iconName: 'star_half',
        },
        {
            name: 'withUsedParts',
            title: 'mit Gebrauchtteilen',
            iconName: 'cached',
        },
        {
            name: 'roadworthy',
            title: 'verkehrssicher',
            iconName: 'verified_user',
        },
    ];
}

export type RepairExecutionTypes = 'repaired' | 'professional' | 'withUsedParts' | 'partial' | 'roadworthy';

import { OfficialFeeTable } from '@autoixpert/models/reports/assessors-fee/fee-calculation';
import { round } from '../../lib/numbers/round';

// Array shape: [damageValue, ...[Fee groups 1-5, arithmetic mean]]
export const bvskFeeTable2024: OfficialFeeTable = {
    year: 2024,
    table: [
        [500, 185, 211, 306, 299, 265, 306],
        [750, 231, 252, 340, 333, 300, 340],
        [1_000, 275, 300, 399, 391, 354, 399],
        [1_250, 342, 364, 446, 436, 396, 446],
        [1_500, 392, 405, 483, 472, 432, 483],
        [1_750, 421, 436, 515, 504, 463, 515],
        [2_000, 453, 466, 545, 534, 491, 545],
        [2_250, 479, 491, 573, 561, 514, 573],
        [2_500, 502, 515, 600, 588, 541, 600],
        [2_750, 526, 539, 626, 614, 565, 626],
        [3_000, 548, 561, 651, 638, 588, 651],
        [3_250, 568, 582, 674, 661, 610, 674],
        [3_500, 588, 603, 699, 685, 632, 699],
        [3_750, 609, 625, 723, 709, 655, 723],
        [4_000, 630, 646, 748, 733, 678, 748],
        [4_250, 651, 666, 769, 754, 699, 769],
        [4_500, 671, 686, 792, 777, 719, 792],
        [4_750, 688, 703, 810, 795, 737, 810],
        [5_000, 705, 721, 830, 815, 756, 830],
        [5_250, 724, 740, 851, 835, 774, 851],
        [5_500, 744, 759, 873, 856, 793, 873],
        [5_750, 759, 776, 890, 874, 811, 890],
        [6_000, 777, 794, 912, 895, 830, 912],
        [6_500, 803, 821, 941, 924, 857, 941],
        [7_000, 828, 846, 971, 953, 884, 971],
        [7_500, 853, 870, 1_000, 982, 911, 1_000],
        [8_000, 882, 901, 1_035, 1_016, 943, 1_035],
        [8_500, 912, 931, 1_069, 1_049, 975, 1_069],
        [9_000, 942, 961, 1_103, 1_083, 1_004, 1_103],
        [9_500, 970, 990, 1_134, 1_113, 1_032, 1_134],
        [10_000, 1_000, 1_021, 1_166, 1_145, 1_063, 1_166],
        [10_500, 1_030, 1_052, 1_204, 1_183, 1_096, 1_204],
        [11_000, 1_058, 1_080, 1_232, 1_210, 1_123, 1_232],
        [11_500, 1_089, 1_112, 1_271, 1_248, 1_159, 1_271],
        [12_000, 1_117, 1_140, 1_302, 1_279, 1_187, 1_302],
        [12_500, 1_146, 1_170, 1_335, 1_312, 1_217, 1_335],
        [13_000, 1_181, 1_204, 1_372, 1_348, 1_251, 1_372],
        [13_500, 1_209, 1_234, 1_407, 1_383, 1_283, 1_407],
        [14_000, 1_236, 1_262, 1_438, 1_413, 1_311, 1_438],
        [14_500, 1_267, 1_290, 1_473, 1_448, 1_343, 1_473],
        [15_000, 1_299, 1_323, 1_510, 1_484, 1_376, 1_510],
        [16_000, 1_342, 1_367, 1_567, 1_540, 1_429, 1_567],
        [17_000, 1_389, 1_418, 1_626, 1_598, 1_482, 1_626],
        [18_000, 1_436, 1_466, 1_680, 1_651, 1_534, 1_680],
        [19_000, 1_492, 1_525, 1_741, 1_712, 1_595, 1_741],
        [20_000, 1_543, 1_577, 1_806, 1_775, 1_653, 1_806],
        [21_000, 1_595, 1_634, 1_875, 1_844, 1_714, 1_875],
        [22_000, 1_650, 1_689, 1_933, 1_901, 1_770, 1_933],
        [23_000, 1_700, 1_740, 1_992, 1_958, 1_826, 1_992],
        [24_000, 1_750, 1_791, 2_057, 2_021, 1_881, 2_057],
        [25_000, 1_801, 1_839, 2_094, 2_060, 1_921, 2_094],
        [26_000, 1_853, 1_891, 2_158, 2_123, 1_975, 2_158],
        [27_000, 1_903, 1_943, 2_221, 2_185, 2_030, 2_221],
        [28_000, 1_956, 1_999, 2_292, 2_253, 2_093, 2_292],
        [29_000, 2_009, 2_058, 2_352, 2_312, 2_149, 2_352],
        [30_000, 2_066, 2_116, 2_434, 2_392, 2_218, 2_434],
        [32_500, 2_157, 2_217, 2_573, 2_529, 2_340, 2_573],
        [35_000, 2_281, 2_345, 2_727, 2_683, 2_486, 2_727],
        [37_500, 2_389, 2_465, 2_885, 2_839, 2_628, 2_885],
        [40_000, 2_513, 2_597, 3_053, 3_006, 2_771, 3_053],
        [42_500, 2_651, 2_746, 3_222, 3_180, 2_982, 3_222],
        [45_000, 2_798, 2_898, 3_410, 3_365, 3_164, 3_410],
        [47_500, 2_927, 3_036, 3_575, 3_528, 3_315, 3_575],
        [50_000, 3_047, 3_172, 3_738, 3_690, 3_464, 3_738],
    ],
};

/**
 * Programmatically calculate the arithmetic mean of the HB5 corridor and append it to the fee table.
 * Array shape: [damageValue, ...[Fee groups 1-5], Mean of HB5 corridor]
 */
export const bvskFeeTable2024IncludingHB5Mean = bvskFeeTable2024.table.map((row) => {
    const hb5From = row.at(-2);
    const hb5To = row.at(-1);
    // Calculate the arithmetic mean within HB5 corridor. I guess this is not mathematically correct, but
    // should be sufficient for choosing a fee that lies in the middle of the HB5 corridor.
    row[row.length] = round(hb5From + (hb5To - hb5From) / 2);

    return row;
});

export const bvskAdditionalCosts = {
    axleMeasurement: 107,
    carBodyMeasurement: 219,
    errorLogReading: 54,
};

/**
 * The user may define a list of photo descriptions that can be assigned to his photos at the click of a button.
 *
 * This is useful if the assessor usually takes the same sort of photos at positions 1 through 10 for instance.
 *
 * This must be a separate class despite only having one property because we bind to an input in the view.
 * Binding to a string[] doesn't work well in Angular.
 */
export class DefaultPhotoDescription {
    constructor(template: Partial<DefaultPhotoDescription> = {}) {
        Object.assign(this, template);
    }

    title: string;
}

/**
 * Creates an HTML Image element, assigns the file parameter as src attribute value and
 * returns the image dimensions as soon as the browser has loaded the image.
 */
export function getImageDimensions(file: HTMLImageElement['src']): Promise<{
    width: number;
    height: number;
}> {
    return new Promise(function (resolve) {
        // Create DOM element
        const imageElement = new Image();

        // Register callback
        imageElement.onload = function () {
            resolve({
                width: imageElement.naturalWidth,
                height: imageElement.naturalHeight,
            });
        };

        // Trigger loading image
        imageElement.src = file;
    });
}

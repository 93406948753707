import { omit } from 'lodash-es';
import moment from 'moment';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { generateId } from '@autoixpert/lib/generate-id';
import { createInvoiceFromReport } from '@autoixpert/lib/invoices/create-invoice-from-report';
import { setPaymentStatus } from '@autoixpert/lib/invoices/set-payment-status';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { InvoiceService } from '../../services/invoice.service';
import { ReportDetailsService } from '../../services/report-details.service';
import { ToastService } from '../../services/toast.service';
import { areContactPeopleEqual } from '../are-contact-people-equal';

/**
 * Create or update the report invoice if the report is locked.
 */
export async function createOrUpdateInvoice(
    report: Report,
    user: User,
    team: Team,
    invoiceService: InvoiceService,
    toastService: ToastService,
    reportDetailService: ReportDetailsService,
): Promise<{ invoiceCreated?: boolean; invoiceUpdated?: boolean }> {
    // Don't create an invoice if the user wants to skip writing it or it belongs to a collective invoice.
    if (report.feeCalculation.skipWritingInvoice || report.feeCalculation.isCollectiveInvoice) {
        return {
            invoiceCreated: false,
            invoiceUpdated: false,
        };
    }

    // Only create or update an invoice if an invoice number has been set.
    const invoiceNumber = report.feeCalculation.invoiceParameters.number;
    if (!invoiceNumber) {
        return {
            invoiceCreated: false,
            invoiceUpdated: false,
        };
    }

    // Prepare the invoice object
    const generatedInvoice = createInvoiceFromReport({
        report: report,
        user,
        team,
    });

    /**
     * The following input parameters are relevant:
     *   1. Does an invoice already exist with this number?
     *   2. Does the user have access to the invoice with this number?
     *
     * The following user stories can occur:
     *   1. User has access to all invoice. No invoice exists for this report. User creates a new invoice and the number is not taken -> Create invoice & show success toast
     *   2. User has access to all invoices. No invoice exists for this report. User tries to create a new invoice but the number is already taken -> Do not create invoice & show error toast
     *   3. User has access to all invoices. An invoice exists for this report. User updates the existing invoice if it has not been locked yet (do nothing otherwise).
     *   4. User has limited invoices access. No invoice exists for this report. User creates a new invoice and the number is not taken -> Create invoice & show success toast
     *   5. User has limited invoices access. No invoice exists for this report. User tries to create a new invoice but the number is already taken -> Do not create invoice & show error toast
     *   6. User has limited invoices access. An invoice exists for this report & user has access. User updates the existing invoice if it has not been locked yet (do nothing otherwise).
     *   7. User has limited invoices access. An invoice exists for this report & user has NO access. Nothing should happen?
     *
     */
    const {
        invoiceExists: invoiceWithNumberExists,
        userMayAccessInvoice,
        invoiceReportIds,
    } = await invoiceService.checkInvoiceNumber({
        invoiceNumber: generatedInvoice.number,
        invoiceDate: generatedInvoice.date,
    });

    const invoiceForThisReportExists = invoiceReportIds?.includes(report._id) ?? false;

    // Does the report invoice exist?
    let accessibleInvoiceForReport: Invoice;
    if (invoiceWithNumberExists && userMayAccessInvoice) {
        accessibleInvoiceForReport = (
            await invoiceService
                .find({
                    number: invoiceNumber,
                    // Invoice must belong to this report. If another report has created an invoice with this number,
                    // an error will be thrown on creation of this report's invoice.
                    reportIds: report._id,
                    // Check within the fiscal year of the invoice params.
                    date: {
                        $gt: toIsoDate(moment(generatedInvoice.date).startOf('year').format()),
                        $lt: toIsoDate(moment(generatedInvoice.date).endOf('year').format()),
                    },
                })
                .toPromise()
        )?.[0];
    }

    //*****************************************************************************
    //  Create Invoice
    //****************************************************************************/

    /**
     * If there is no invoice on the report yet, create one.
     * Make sure that there is really no invoice for the report as the user could just not have the permission
     * to access the existing invoice
     */
    if (!accessibleInvoiceForReport && !invoiceForThisReportExists) {
        generatedInvoice.reportIds = [report._id];

        //*****************************************************************************
        //  Check for Duplicate Invoice Number
        //****************************************************************************/
        // Check if the invoice number within the same annual invoice cycle already exists.
        if (invoiceWithNumberExists) {
            toastService.error(
                `Die Rechnungsnummer ${generatedInvoice.number} existiert bereits`,
                'Bitte wähle eine andere Rechnungsnummer und versuche es erneut.',
            );
            return {
                invoiceCreated: false,
            };
        }

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Check for Duplicate Invoice Number
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Check E-Rechnung / XRechnung Parameters
        //****************************************************************************/
        if (generatedInvoice.isElectronicInvoiceEnabled) {
            try {
                await invoiceService.getInvoicePreview(generatedInvoice);
            } catch (error) {
                throw new AxError({
                    code: 'INVALID_ELECTRONIC_INVOICE_PARAMETERS',
                    message: 'The XRechnung validation failed. Please check the parameters and try again.',
                    error,
                });
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Check E-Rechnung / XRechnung Parameters
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Generate Invoice ID
        //****************************************************************************/
        generatedInvoice._id = generateId();
        await reportDetailService.saveReport(report);

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Generate Invoice ID
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  POST Invoice
        //****************************************************************************/
        try {
            // Create the independent invoice object
            await invoiceService.create(generatedInvoice, { waitForServer: true });
        } catch (error) {
            throw new AxError({
                code: 'CREATING_REPORT_INVOICE_AS_INDEPENDENT_INVOICE_FAILED',
                message: `The given invoice could not be created in the invoice collection.`,
                data: {
                    reportId: report._id,
                    invoiceNumber: generatedInvoice.number,
                },
                error,
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END POST Invoice
        /////////////////////////////////////////////////////////////////////////////*/

        return {
            invoiceCreated: true,
        };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Update Existing Invoice If Invoice is not Booked Yet
    //****************************************************************************/
    else if (accessibleInvoiceForReport && !accessibleInvoiceForReport.lockedAt) {
        const mergedInvoice: Invoice = JSON.parse(JSON.stringify(accessibleInvoiceForReport));
        Object.assign<Invoice, Partial<Invoice>>(
            mergedInvoice,
            omit(
                generatedInvoice,
                new Array<keyof Invoice>(
                    /**
                     * Remove properties that should not change if the invoice is recreated from the report. Properties
                     * such as payments must stay intact because they are independent from the invoice generated
                     * from the report.
                     */
                    '_id',
                    'hasOutstandingPayments',
                    'payments',
                    'nextPaymentReminderAt',
                    'idsOfCancellationInvoices',
                    'isFullyCanceled',
                    'rootInvoiceId',
                    'isFullCancellationInvoice',
                    'documents',
                    /**
                     * Update the document orders because new involved parties might have been added: lawyer & insurance. It's better
                     * to reset the document order than to have none at all which causes the invoice's print and send screen to break.
                     */
                    // 'documentOrders',
                    'importedFromThirdPartySystem',
                    'idInThirdPartySystem',
                    'notes',
                    'createdAt',
                    'createdBy',
                    '_documentVersion',
                    '_schemaVersion',
                    /**
                     * Skip all involved parties. Only their contact person may be overwritten, but not their payment reminders.
                     */
                    'recipient',
                    'claimant',
                    'insurance',
                    'lawyer',
                ),
            ),
        );

        // Update the contact person on each involved party.
        mergedInvoice.recipient.contactPerson = generatedInvoice.recipient.contactPerson;

        //*****************************************************************************
        //  Merge Involved Parties
        //****************************************************************************/
        const involvedPartiesToCopy = ['claimant', 'insurance', 'lawyer'];

        /**
         * Copy as little as necessary:
         * - if the existing invoice already has an involved party (including information on the payment reminders), only copy the contact person from the report.
         * - if the existing invoice does not yet know an involved party (e. g. lawyer has been activated retroactively), copy the full involved party.
         */
        for (const involvedPartyKey of involvedPartiesToCopy) {
            // If the new invoice has an involved party, start the copy process.
            if (generatedInvoice[involvedPartyKey]) {
                // involved party exists? -> Copy only the contact person to keep the payment reminders.
                if (mergedInvoice[involvedPartyKey]) {
                    mergedInvoice[involvedPartyKey].contactPerson = generatedInvoice[involvedPartyKey].contactPerson;
                }
                // No involved party yet -> Copy the full involved party.
                else {
                    mergedInvoice[involvedPartyKey] = generatedInvoice[involvedPartyKey];
                }
            }
            // If the new invoice doesn't include the involved party, remove it from the merge result.
            else {
                mergedInvoice[involvedPartyKey] = undefined;
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Merge Involved Parties
        /////////////////////////////////////////////////////////////////////////////*/

        setPaymentStatus(mergedInvoice);

        // Customers are surprised, if we display that the invoice was updated but nothing changed.
        // Therefore we only display the message if one of the relevant fields actually changed.
        // Okay, I know this is not the fanciest code. But probably the most easy.
        const invoiceChanged = !(
            accessibleInvoiceForReport.totalNet === mergedInvoice.totalNet &&
            accessibleInvoiceForReport.totalGross === mergedInvoice.totalGross &&
            accessibleInvoiceForReport.date === mergedInvoice.date &&
            accessibleInvoiceForReport.daysUntilDue === mergedInvoice.daysUntilDue &&
            accessibleInvoiceForReport.number === mergedInvoice.number &&
            /**
             * Contact People
             */
            areContactPeopleEqual(
                accessibleInvoiceForReport.recipient.contactPerson,
                mergedInvoice.recipient.contactPerson,
            ) &&
            areContactPeopleEqual(
                accessibleInvoiceForReport.claimant?.contactPerson,
                mergedInvoice.claimant?.contactPerson,
            ) &&
            areContactPeopleEqual(
                accessibleInvoiceForReport.insurance?.contactPerson,
                mergedInvoice.insurance?.contactPerson,
            ) &&
            areContactPeopleEqual(
                accessibleInvoiceForReport.lawyer?.contactPerson,
                mergedInvoice.lawyer?.contactPerson,
            ) &&
            /**
             * Line Items
             */
            accessibleInvoiceForReport.lineItems.every((lineItem: LineItem, index) => {
                return (
                    lineItem.description === mergedInvoice.lineItems[index].description &&
                    lineItem.unit === mergedInvoice.lineItems[index].unit &&
                    lineItem.active === mergedInvoice.lineItems[index].active &&
                    lineItem.quantity === mergedInvoice.lineItems[index].quantity &&
                    lineItem.unitPrice === mergedInvoice.lineItems[index].unitPrice &&
                    lineItem.position === mergedInvoice.lineItems[index].position
                );
            })
        );

        // Only send a PATCH request if we display an info toast to the user. It's confusing to update invisibly.
        if (invoiceChanged) {
            //*****************************************************************************
            //  Check E-Rechnung / XRechnung Parameters
            //****************************************************************************/
            if (generatedInvoice.isElectronicInvoiceEnabled) {
                try {
                    await invoiceService.getInvoicePreview(mergedInvoice);
                } catch (error) {
                    throw new AxError({
                        code: 'INVALID_ELECTRONIC_INVOICE_PARAMETERS',
                        message: 'The XRechnung validation failed. Please check the parameters and try again.',
                        error,
                    });
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Check E-Rechnung / XRechnung Parameters
            /////////////////////////////////////////////////////////////////////////////*/

            await invoiceService.put(mergedInvoice);
        }

        return {
            invoiceUpdated: invoiceChanged,
        };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Update Existing Invoice If Invoice is not Booked Yet
    /////////////////////////////////////////////////////////////////////////////*/
    // Invoice exists but is booked. Don't update automatically.
    return {
        invoiceUpdated: false,
    };
}

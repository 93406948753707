import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SafeHtml } from '@angular/platform-browser';
import moment from 'moment';
import { Observable, Subscription, of as observableOf } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { fadeInAnimation } from '@autoixpert/animations/fade-in.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { chooseAndRenderDocumentBuildingBlocks } from '@autoixpert/lib/document-building-blocks/choose-and-render-document-building-blocks';
import { matchConditions } from '@autoixpert/lib/document-building-blocks/match-conditions';
import { addMissingClaimantSignaturesOnPdfTemplateDocument } from '@autoixpert/lib/documents/add-missing-claimant-signatures-on-pdf-template-document';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { setupAllClaimantSignaturesOnBuildingBlockDocuments } from '@autoixpert/lib/documents/setup-all-claimant-signatures-on-building-block-documents';
import { isBeta } from '@autoixpert/lib/environment/is-beta';
import { isProduction } from '@autoixpert/lib/environment/is-production';
import { generateId } from '@autoixpert/lib/generate-id';
import { getCommunicationRecipientsFromReport } from '@autoixpert/lib/involved-parties/get-communication-recipients-from-report';
import { getOutgoingMessageScheduledAt } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-scheduled-at';
import { getOutgoingMessageStatus } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-status';
import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { OutgoingEmailMessage, OutgoingMessage, OutgoingMessageSchedule } from '@autoixpert/models/outgoing-message';
import { CommunicationRecipient } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { Report } from '@autoixpert/models/reports/report';
import { SignableDocument } from '@autoixpert/models/signable-documents/signable-document';
import { SignablePdfTemplateConfig } from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { Team } from '@autoixpert/models/teams/team';
import { TextTemplate } from '@autoixpert/models/text-templates/text-template';
import { EmailSignature } from '@autoixpert/models/user/preferences/email-signature';
import { User } from '@autoixpert/models/user/user';
import { getRelativeDate } from 'src/app/shared/libraries/get-relative-date';
import { ApiErrorService } from 'src/app/shared/services/api-error.service';
import { ClaimantSignaturesFormDraftService } from 'src/app/shared/services/claimant-signature-form-draft.service';
import { DocumentBuildingBlockService } from 'src/app/shared/services/document-building-block.service';
import { DocumentOrderConfigService } from 'src/app/shared/services/document-order-config.service';
import { EmailService } from 'src/app/shared/services/email.service';
import { EmailSignatureService } from 'src/app/shared/services/emailSignature.service';
import { FieldGroupConfigService } from 'src/app/shared/services/field-group-config.service';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { OutgoingMessageService } from 'src/app/shared/services/outgoing-message.service';
import { ReportDetailsService } from 'src/app/shared/services/report-details.service';
import { SignablePdfTemplateConfigService } from 'src/app/shared/services/signable-pdf-template-config.service';
import { TemplatePlaceholderValuesService } from 'src/app/shared/services/template-placeholder-values.service';
import { TextTemplateService } from 'src/app/shared/services/textTemplate.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';
import { UserService } from 'src/app/shared/services/user.service';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';

type LoadedDocumentBuildingBlocks = {
    [key: string]: RenderedDocumentBuildingBlock[];
};

export interface RenderedDocumentBuildingBlock {
    _id: string;
    placeholder: string;
    heading: string;
    content: SafeHtml;
}

@Component({
    selector: 'remote-signature-dialog',
    templateUrl: './remote-signature-dialog.component.html',
    styleUrls: ['./remote-signature-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), runChildAnimations(), fadeInAnimation()],
})
export class RemoteSignatureDialogComponent implements OnInit, OnDestroy {
    constructor(
        private dialog: MatDialog,
        private userService: UserService,
        private toastService: ToastService,
        private emailService: EmailService,
        private apiErrorService: ApiErrorService,
        public userPreferences: UserPreferencesService,
        private loggedInUserService: LoggedInUserService,
        private textTemplateService: TextTemplateService,
        private reportDetailsService: ReportDetailsService,
        private emailSignatureService: EmailSignatureService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private documentBuildingBlockService: DocumentBuildingBlockService,
        private signablePdfTemplateConfigService: SignablePdfTemplateConfigService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private claimantSignaturesFormDraftService: ClaimantSignaturesFormDraftService,
        private outgoingMessageService: OutgoingMessageService,
    ) {}

    @Input() public report: Report;
    @Output() public close: EventEmitter<void> = new EventEmitter<void>();

    public emailJustSentInfo: false | { label: string; icon: string } = false;
    @Input() emailTransmissionPending: boolean = false;

    public user: User;
    public team: Team;
    protected allUsers: User[] = [];
    protected isComposingNewMessage: boolean = false;

    // Text templates
    public templateSelectorShown: boolean = false;
    public placeholderValuesForCurrentInvoice: any;
    private fieldGroupConfigs: FieldGroupConfig[] = [];

    // Outgoing messages
    protected outgoingMessages: OutgoingMessage[] = [];

    //*****************************************************************************
    //  Signable PDF Template Configs
    //****************************************************************************/
    public signablePdfTemplateConfigs: SignablePdfTemplateConfig[] = [];
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signable PDF Template Configs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Building Blocks
    //****************************************************************************/
    public renderedDocumentBuildingBlocks: LoadedDocumentBuildingBlocks = {};
    public buildingBlocksPending: boolean = true;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Building Blocks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Placeholder Value Tree
    //****************************************************************************/
    // Used to determine the right signable PDF template config.
    public placeholderValues: PlaceholderValues;
    public placeholderValueTree: PlaceholderValueTree;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Placeholder Value Tree
    /////////////////////////////////////////////////////////////////////////////*/

    private subscriptions: Subscription[] = [];

    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();
        this.allUsers = this.userService.getAllTeamMembersFromCache();
        await this.loadOutgoingMessages();

        this.subscriptions.push(this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)));
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        // Initialize the remote signature config. Wait until server has saved the report so that the placeholders can be replaced when inserting the default email template.
        await this.initializeRemoteSignatureConfig();

        // Required additional data
        await this.loadFieldGroupConfigs();

        // Default email template
        await this.insertDefaultEmailTemplate();

        // Content
        await this.loadBuildingBlocksForAllDocuments();
        await this.loadSignablePdfTemplateConfigs();

        // Setup document structure
        await this.setupDocuments();
        this.registerOutgoingMessagesWebsocketEvents();
    }

    //*****************************************************************************
    //  Dialog
    //****************************************************************************/
    protected closeDialog(): void {
        this.close.emit();
    }

    protected handleOverlayClick(event: MouseEvent) {
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Remote Signature Config
    //****************************************************************************/
    private getSignableDocumentId(signableDocument: {
        documentType: string;
        customDocumentOrderConfigId?: string;
    }): string {
        if (!signableDocument.customDocumentOrderConfigId) {
            return signableDocument.documentType;
        }
        return `${signableDocument.documentType}-${signableDocument.customDocumentOrderConfigId}`;
    }

    async initializeRemoteSignatureConfig() {
        if (
            !this.report.remoteSignatureConfig?.authenticationToken ||
            !this.report.remoteSignatureConfig?.createdByUserId ||
            !this.report.remoteSignatureConfig?.signatureDeadlineAt
        ) {
            this.report.remoteSignatureConfig ??= { authenticationToken: generateId(), createdByUserId: this.user._id };
            this.report.remoteSignatureConfig.authenticationToken ??= generateId();
            this.report.remoteSignatureConfig.createdByUserId ??= this.user._id;
            this.report.remoteSignatureConfig.signatureDeadlineAt ??= this.getDeadlineSettingsDate().toISOString();
            await this.saveReport({ waitForServer: true });
        }
    }

    async promptResetAuthenticationToken() {
        this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    heading: 'Neuen Link generieren',
                    content:
                        'Generiere einen neuen Link, falls der Anspruchsteller nochmal neu unterschreiben möchte. Der alte Link wird ungültig und kann nicht mehr verwendet werden.',
                    confirmLabel: 'Neuen Link generieren',
                    cancelLabel: 'Abbrechen',
                },
                maxWidth: '450px',
            })
            .afterClosed()
            .subscribe((response) => {
                if (response) {
                    this.resetAuthenticationToken();
                }
            });
    }
    async resetAuthenticationToken() {
        this.report.remoteSignatureConfig = {
            authenticationToken: generateId(),
            createdByUserId: this.user._id,
            signatureDeadlineAt: this.report.remoteSignatureConfig.signatureDeadlineAt,
            // Reset the claimant's submission status
            claimantSubmittedAt: null,
            // Reset the claimant's signature check status
            claimantSignaturesChecked: false,
        };

        // Replace link in email as well
        this.email.body = this.email.body.replace(
            /https:\/\/unterschrift\.autoixpert\.de\/Gutachten\/[a-zA-Z0-9]+\?token=[a-zA-Z0-9]+/g,
            this.remoteSignatureLink,
        );

        await this.saveReport();
        try {
            await this.claimantSignaturesFormDraftService.delete({ reportId: this.report._id });
        } catch (error) {
            // If the draft does not exist, that's fine.
        }

        this.toastService.info(
            'Link zurückgesetzt',
            'Der Link wurde zurückgesetzt. Bitte kopiere ihn erneut. Der alte Link ist nicht mehr gültig.',
        );
    }

    get remoteSignatureLink() {
        const baseUrl = isBeta()
            ? 'https://beta.unterschrift.autoixpert.de'
            : isProduction()
              ? 'https://unterschrift.autoixpert.de'
              : 'https://unterschrift.autoixpert.lokal';

        return `${baseUrl}/Gutachten/${this.report._id}?token=${this.report.remoteSignatureConfig.authenticationToken}`;
    }

    get signatureDeadlineTime() {
        return moment(this.report.remoteSignatureConfig.signatureDeadlineAt).format('HH:mm');
    }

    handleSignatureDeadlineChange(newDate: IsoDate) {
        const date = moment(this.report.remoteSignatureConfig.signatureDeadlineAt);
        const hours = date.hours();
        const minutes = date.minutes();

        this.report.remoteSignatureConfig.signatureDeadlineAt = moment(newDate)
            .set('hour', hours)
            .set('minute', minutes)
            .toISOString();

        this.saveReport();
    }

    hasSignatureDeadlinePassed() {
        return moment().isAfter(this.report.remoteSignatureConfig.signatureDeadlineAt);
    }

    // Time is in ISO format (we only need the time part)
    setSignatureDeadlineTime(time: string) {
        const date = this.report.remoteSignatureConfig.signatureDeadlineAt;
        this.report.remoteSignatureConfig.signatureDeadlineAt = moment(date)
            .set('hour', moment(time).hours())
            .set('minute', moment(time).minutes())
            .toISOString();
    }

    copyLinkToClipboard() {
        const link = this.remoteSignatureLink;
        try {
            navigator.clipboard.writeText(link);
            this.toastService.info('Link kopiert', 'Der Link wurde in die Zwischenablage kopiert.');
        } catch (error) {
            this.toastService.error(
                'Fehler beim Kopieren',
                'Der Link konnte nicht in die Zwischenablage kopiert werden.',
            );
        }
    }

    getDeadlineSettingsDate() {
        const offsetAmount = this.userPreferences.remoteSignatureDeadlineOffsetAmount;
        const offsetUnit = this.userPreferences.remoteSignatureDeadlineOffsetUnit;
        return getRelativeDate(offsetAmount, offsetUnit).toDate();
    }

    setDeadlineOffsetAmount(event: Event) {
        if (event.target instanceof HTMLInputElement) {
            const value = event.target.value;
            this.userPreferences.remoteSignatureDeadlineOffsetAmount = parseInt(value);
        }
    }

    setDeadlineToSettingsDate() {
        // Update the deadline of the current report based on the new settings
        const date = this.getDeadlineSettingsDate();
        const hours = moment(this.report.remoteSignatureConfig.signatureDeadlineAt).hours();
        const minutes = moment(this.report.remoteSignatureConfig.signatureDeadlineAt).minutes();
        moment(date).hours(hours).minutes(minutes);

        this.report.remoteSignatureConfig.signatureDeadlineAt = this.getDeadlineSettingsDate().toISOString();
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remote Signature Config
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Send E-Mail
    //****************************************************************************/
    get email() {
        this.selectedRecipient.outgoingMessageDraft ??= {
            report: new OutgoingEmailMessage(),
            repairConfirmation: new OutgoingEmailMessage(),
            expertStatement: new OutgoingEmailMessage(),
            remoteSignature: new OutgoingEmailMessage(),
        };
        this.selectedRecipient.outgoingMessageDraft.remoteSignature ??= new OutgoingEmailMessage();
        return this.selectedRecipient.outgoingMessageDraft.remoteSignature;
    }

    get recipients() {
        return getCommunicationRecipientsFromReport({ report: this.report });
    }

    get selectedRecipient(): CommunicationRecipient {
        return this.recipients.find((recipient) => recipient.role === 'claimant');
    }

    public async sendEmail({
        email: sentEmailTemplate,
        schedule,
    }: {
        email: OutgoingMessage;
        schedule?: OutgoingMessageSchedule;
    }) {
        this.emailTransmissionPending = true;

        /** Hold on the current values of recipient and document group at the
         * time the user started sending the email.
         * Otherwise, switching the value while transmission is in progress, would
         * assign the email to the wrong recipient/group.
         */
        const selectedRecipient = this.selectedRecipient;
        /**
         * Hold on to this object if we must restore it later in case of a transmission error. This object contains the email object
         * with placeholders for recipients, no sentAt date and so on. The object "sentEmailTemplate" already contains all "rendered"
         * email recipients, a sentAt date etc., so that should not be persisted on the report in case of error.
         */
        const originalEmailWithRecipientPlaceholders: OutgoingEmailMessage =
            selectedRecipient.outgoingMessageDraft.remoteSignature;

        // The email must be synced to the server before triggering the "send" API endpoint because the "send" API endpoint reads its contents from the report object.
        selectedRecipient.outgoingMessageDraft.remoteSignature = new OutgoingEmailMessage({
            _id: sentEmailTemplate._id,
            subject: sentEmailTemplate.subject,
            body: sentEmailTemplate.body,
            email: {
                toRecipients: sentEmailTemplate.email.toRecipients,
                ccRecipients: sentEmailTemplate.email.ccRecipients,
                bccRecipients: sentEmailTemplate.email.bccRecipients,
            },
            sentAt: sentEmailTemplate.sentAt,
            createdBy: sentEmailTemplate.createdBy,
            attachedDocuments: sentEmailTemplate.attachedDocuments,
        });
        await this.saveReport({ waitForServer: true });

        // Always create an outgoing message with information about the email that was scheduled, sent or failed.
        const outgoingMessage = new OutgoingEmailMessage({
            type: 'standard-email',
            source: 'remoteSignature',
            reportId: this.report?._id,
            recipientType: selectedRecipient.role,
            subject: sentEmailTemplate.subject,
            body: sentEmailTemplate.body,
            attachedDocuments: sentEmailTemplate.attachedDocuments,
            scheduledAt: schedule ? getOutgoingMessageScheduledAt(schedule).toISO() : undefined,
            email: {
                toRecipients: sentEmailTemplate.email.toRecipients,
                ccRecipients: sentEmailTemplate.email.ccRecipients,
                bccRecipients: sentEmailTemplate.email.bccRecipients,
            },
        });

        try {
            if (!schedule) {
                outgoingMessage.sentAt = moment().format();
                await this.outgoingMessageService.create(outgoingMessage, { waitForServer: true });

                await this.emailService.sendStandardEmail({
                    subject: outgoingMessage.subject,
                    body: outgoingMessage.body,
                    toRecipients: outgoingMessage.email.toRecipients || [],
                    ccRecipients: outgoingMessage.email.ccRecipients || [],
                    bccRecipients: outgoingMessage.email.bccRecipients || [],
                });

                outgoingMessage.deliveredAt = moment().format();
                this.outgoingMessages.push(outgoingMessage);
                await this.outgoingMessageService.put(outgoingMessage);
            } else {
                outgoingMessage.scheduledAt = getOutgoingMessageScheduledAt(schedule).toISO();
                this.outgoingMessages.push(outgoingMessage);
                await this.outgoingMessageService.create(outgoingMessage);
            }

            // Only set the email as received if the email was sent successfully.
            selectedRecipient.receivedEmail = true;
        } catch (error) {
            selectedRecipient.outgoingMessageDraft.remoteSignature = originalEmailWithRecipientPlaceholders;
            this.saveReport();

            outgoingMessage.failedAt = moment().format();
            outgoingMessage.error = {
                code: error?.code,
                message: error?.message,
                data: error?.data,
            };
            this.outgoingMessages.push(outgoingMessage);
            await this.outgoingMessageService.put(outgoingMessage);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    MISSING_EMAIL_BODY: {
                        title: 'E-Mail-Text fehlt',
                        body: 'Bitte ändere den E-Mail-Text noch einmal, damit er gespeichert wird. Dann sollte der Versand funktionieren.',
                    },
                },
                defaultHandler: {
                    title: 'E-Mail nicht gesendet',
                    body: 'Ein Fehler ist aufgetreten',
                },
            });
        }

        selectedRecipient.outgoingMessageDraft.remoteSignature = new OutgoingEmailMessage();

        // Success info
        const status = getOutgoingMessageStatus(outgoingMessage);
        switch (status) {
            case 'scheduled': {
                this.emailJustSentInfo = { label: 'E-Mail wurde vorgemerkt', icon: 'schedule' };
                break;
            }
            case 'failed': {
                this.emailJustSentInfo = { label: 'E-Mail konnte nicht gesendet werden', icon: 'error' };
                break;
            }
            default: {
                this.emailJustSentInfo = { label: 'E-Mail wurde gesendet', icon: 'check' };
                break;
            }
        }
        this.isComposingNewMessage = false;

        window.setTimeout(() => {
            this.emailJustSentInfo = false;
        }, 2000);

        // Save the report again to save the sentEmail in the sentOutgoingMessages array
        this.saveReport();
        this.emailTransmissionPending = false;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Send E-Mail
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report
    //****************************************************************************/
    public async saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        try {
            return await this.reportDetailsService.patch(this.report, { waitForServer });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    FEATHERS_ERROR: () => {
                        return {
                            title: 'Ein Fehler ist aufgetreten',
                            body: 'Es wurde keine Rechnung geschrieben. Bitte wende Dich an den autoiXpert-Support',
                        };
                    },
                },
                defaultHandler: {
                    title: 'Email nicht gesendet',
                    body: 'Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    private async loadFieldGroupConfigs() {
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
    }

    //*****************************************************************************
    //  Text Document Content
    //****************************************************************************/
    public async loadBuildingBlocksForAllDocuments(): Promise<void> {
        this.buildingBlocksPending = true;

        // Get all building blocks.
        let documentBuildingBlocks: DocumentBuildingBlock[];
        let documentOrderConfigs: DocumentOrderConfig[];
        let placeholderValues: PlaceholderValues;

        //*****************************************************************************
        //  Building Blocks
        //****************************************************************************/
        try {
            documentBuildingBlocks = await this.documentBuildingBlockService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Textbausteine nicht geladen`,
                    body: `Bitte warte ein paar Minuten, bis sich alle Daten synchronisiert haben. Falls es nach 5 min bei guter Internetverbindung immer noch nicht funktioniert, kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Building Blocks
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Document Order Configs
        //****************************************************************************/
        try {
            documentOrderConfigs = await this.documentOrderConfigService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Dokument-Reihenfolgen nicht geladen`,
                    body: `Bitte warte ein paar Minuten, bis sich alle Daten synchronisiert haben. Falls es nach 5 min bei guter Internetverbindung immer noch nicht funktioniert, kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Document Order Configs
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Placeholder Values
        //****************************************************************************/
        try {
            placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
                reportId: this.report?._id,
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Platzhalter-Werte nicht geladen`,
                    body: `Bitte warte ein paar Minuten, bis sich alle Daten synchronisiert haben. Falls es nach 5 min bei guter Internetverbindung immer noch nicht funktioniert, kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Placeholder Values
        /////////////////////////////////////////////////////////////////////////////*/

        /**
         * - Download the document building block for all documents,
         * - replace its placeholders and
         * - save them to the respective variables for the view.
         */
        for (const signableDocument of this.report.signableDocuments) {
            const documentOrderConfig = documentOrderConfigs.find(
                (documentOrderConfig) =>
                    documentOrderConfig.type === signableDocument.documentType &&
                    (!signableDocument.customDocumentOrderConfigId ||
                        documentOrderConfig._id === signableDocument.customDocumentOrderConfigId),
            );

            // Sort blocks by the order structure of the document
            const sortedBlocks = this.sortBuildingBlocksAccordingToTemplateConfig(
                documentBuildingBlocks,
                documentOrderConfig.documentBuildingBlockIds,
            );

            // Choose and render variants
            this.renderedDocumentBuildingBlocks[this.getSignableDocumentId(signableDocument)] =
                chooseAndRenderDocumentBuildingBlocks({
                    documentBuildingBlocks: sortedBlocks,
                    placeholderValues,
                    fieldGroupConfigs: this.fieldGroupConfigs,
                });
        }
        this.buildingBlocksPending = false;
    }

    /**
     * Sort document building blocks by the provided document order structure.
     *
     * If a block either doesn't exist in the structure or in the provided blocks array, it will be skipped.
     */
    private sortBuildingBlocksAccordingToTemplateConfig(
        buildingBlocks: DocumentBuildingBlock[],
        documentBuildingBlockIds: DocumentBuildingBlock['_id'][],
    ): DocumentBuildingBlock[] {
        const sortedBlocks: DocumentBuildingBlock[] = [];

        for (const documentBuildingBlockId of documentBuildingBlockIds) {
            const matchingBlock = buildingBlocks.find((block) => block._id === documentBuildingBlockId);
            if (matchingBlock) {
                sortedBlocks.push(matchingBlock);
            }
        }

        return sortedBlocks;
    }

    private async loadSignablePdfTemplateConfigs() {
        try {
            this.signablePdfTemplateConfigs = await this.signablePdfTemplateConfigService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `PDF-Vorlagen nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    private async setupDocuments() {
        for (const signableDocument of this.report.signableDocuments) {
            let signablePdfTemplateConfig: SignablePdfTemplateConfig;

            //*****************************************************************************
            //  Existing PDF Template
            //****************************************************************************/
            if (signableDocument.pdfTemplateId) {
                // Load referenced template.
                signablePdfTemplateConfig = this.signablePdfTemplateConfigs.find(
                    (signablePdfTemplateConfig) => signablePdfTemplateConfig._id === signableDocument.pdfTemplateId,
                );

                //*****************************************************************************
                //  Referenced Template Not Found
                //****************************************************************************/
                if (!signablePdfTemplateConfig) {
                    const decision = await this.dialog
                        .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                            data: {
                                heading: 'PDF-Vorlage nicht gefunden',
                                content: `Die PDF-Vorlage, die du für das Dokument ${translateDocumentType(
                                    signableDocument.documentType,
                                )} ausgewählt hast, wurde nicht gefunden.\n\nDas Dokument ist damit nicht mehr druckbar.`,
                                confirmLabel: 'Verknüpfung entfernen',
                                cancelLabel: 'Beibehalten',
                            },
                        })
                        .afterClosed()
                        .toPromise();
                    if (decision) {
                        signableDocument.pdfTemplateId = null;
                    }
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Referenced Template Not Found
                /////////////////////////////////////////////////////////////////////////////*/

                //*****************************************************************************
                //  Check Other Templates
                //****************************************************************************/
                // If there's another matching document, offer that.
                const possiblyOtherConfig: SignablePdfTemplateConfig =
                    await this.matchSignablePdfTemplateConfig(signableDocument);
                if (possiblyOtherConfig && possiblyOtherConfig._id !== signableDocument.pdfTemplateId) {
                    // If an ID is set but deviates from the determined one, offer updating it.
                    const decision = await this.dialog
                        .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                            data: {
                                heading: 'PDF-Vorlage aktualisiert',
                                content: `Gemäß deiner eingestellten Bedingungen steht eine neue PDF-Vorlage für das Dokument ${translateDocumentType(
                                    signableDocument.documentType,
                                )} zur Verfügung (Titel: ${
                                    possiblyOtherConfig.title || translateDocumentType(possiblyOtherConfig.documentType)
                                }).\n\nBestehende Unterschriften werden entfernt.`,
                                confirmLabel: 'Neue Vorlage verwenden',
                                cancelLabel: 'Bisherige behalten',
                                confirmColorRed: true,
                            },
                        })
                        .afterClosed()
                        .toPromise();
                    if (decision) {
                        signableDocument.pdfTemplateId = possiblyOtherConfig._id;
                        signablePdfTemplateConfig = possiblyOtherConfig;
                        // Remove old signatures. The new ones will be set up further down.
                        signableDocument.signatures = [];
                    }
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Check Other Templates
                /////////////////////////////////////////////////////////////////////////////*/

                addMissingClaimantSignaturesOnPdfTemplateDocument({
                    signablePdfTemplateConfig,
                    signableDocument,
                });
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Existing PDF Template
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  No PDF Template
            //****************************************************************************/
            // Either a fresh document or one with document building blocks.
            if (!signableDocument.pdfTemplateId) {
                // Only check for a matching PDF template if there aren't any signatures yet.
                const doSignaturesExist: boolean = !!signableDocument.signatures[0]?.hash;
                if (doSignaturesExist) {
                    // If there are any signatures, the document is already signed, and we don't need to check for a matching PDF template.
                    continue;
                }

                // Try to find PDF template.
                signablePdfTemplateConfig = await this.matchSignablePdfTemplateConfig(signableDocument);

                // Match found.
                if (signablePdfTemplateConfig) {
                    addMissingClaimantSignaturesOnPdfTemplateDocument({
                        signablePdfTemplateConfig,
                        signableDocument,
                    });
                    signableDocument.pdfTemplateId = signablePdfTemplateConfig._id;
                    continue;
                }

                // A signable document with building blocks has one or more signatures.
                setupAllClaimantSignaturesOnBuildingBlockDocuments({
                    signableDocument,
                    buildingBlocks: this.renderedDocumentBuildingBlocks[this.getSignableDocumentId(signableDocument)],
                });
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END No PDF Template
            /////////////////////////////////////////////////////////////////////////////*/
        }

        this.saveReport();
    }

    /**
     * Signable PDF template configs are matched through
     * - document type
     * - conditions
     */
    private async matchSignablePdfTemplateConfig(
        signableDocument: SignableDocument,
    ): Promise<SignablePdfTemplateConfig> {
        this.placeholderValues ??= await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.report?._id,
            letterDocument: undefined,
        });
        this.placeholderValueTree ??= getPlaceholderValueTree({ fieldGroupConfigs: this.fieldGroupConfigs });

        const matchingConfigs: SignablePdfTemplateConfig[] = matchConditions({
            // Filter: must match tab + must not be archived.
            elementsWithConditions: this.signablePdfTemplateConfigs.filter(
                (signablePdfTemplateConfig) =>
                    // Match the type
                    signablePdfTemplateConfig.documentType === signableDocument.documentType &&
                    // A PDF template must be uploaded for the template to be considered.
                    signablePdfTemplateConfig.pdfUploaded &&
                    // Match the custom document ID if provided.
                    signablePdfTemplateConfig.customDocumentOrderConfigId ===
                        signableDocument.customDocumentOrderConfigId &&
                    // The template must not have been archived.
                    !signablePdfTemplateConfig.archivedAt,
            ),
            placeholderValues: this.placeholderValues,
            placeholderValueTree: this.placeholderValueTree,
        });

        // No matching config. The user must use the text block documents instead of PDFs.
        if (!matchingConfigs.length) return;

        // More than one config? Inform user and link to settings.
        if (matchingConfigs.length > 1) {
            this.toastService.warn(
                'Mehrere PDF-Dateien gefunden',
                `Es gibt mehrere PDF-Dateien, deren Bedingungen für das Dokument ${translateDocumentType(
                    signableDocument.documentType,
                )} zutreffen. Bitte gib in den <a href='/Einstellungen/Unterschreibbare-PDF-Dokumente'>Einstellungen</a> eindeutige Bedingungen an.\n\nEs wird die erste gefundene Datei verwendet.`,
            );
        }

        return matchingConfigs[0];
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Message Templates
    //****************************************************************************/
    public hideMessageTemplateSelector(): void {
        this.templateSelectorShown = false;
    }

    public insertTemplateText(textTemplate: TextTemplate): void {
        this.email.subject = this.replacePlaceholders(textTemplate.subject);
        this.email.body = this.replacePlaceholders(textTemplate.body);
        // this.email.title = textTemplate.title;

        this.hideMessageTemplateSelector();
        this.insertEmailSignatureAndEmitChange();
    }

    private replacePlaceholders(templateWithPlaceholders: string): string {
        return replacePlaceholders({
            textWithPlaceholders: templateWithPlaceholders,
            placeholderValues: this.placeholderValuesForCurrentInvoice,
            fieldGroupConfigs: this.fieldGroupConfigs,
        });
    }

    public async insertEmailSignatureAndEmitChange() {
        await this.insertEmailSignature();
        this.saveReport();
    }

    private async insertEmailSignature(): Promise<void> {
        let emailSignatures: EmailSignature[] = this.emailSignatureService.getAllFromCurrentInMemoryCache();
        /**
         * If there are no email signatures in the memory cache, query the server.
         * The server is not queried immediately to increase performance. Email signatures are inserted very often
         * but changed rarely, so a good in-memory cache increases speed.
         * The server is queried at all to ensure the user can still insert email signatures even if the app has not fully synced
         * (and populated the in-memory cache) after the user refreshed the page.
         */
        if (!emailSignatures.length) {
            emailSignatures = await this.emailSignatureService.find({ createdBy: this.user._id }).toPromise();
        }
        // If there are no email signatures, don't try to insert one.
        if (!emailSignatures.length) {
            return;
        }

        const emailSignatureForThisUser = emailSignatures.find(
            (emailSignature) => emailSignature.createdBy === this.user._id,
        );
        if (emailSignatureForThisUser?.content) {
            if (!this.email.body) {
                /**
                 * Gmail adds two line breaks before the signature so that the user can easily click in this space to position his cursor. Since there
                 * is another line break right before the signature, add one here if the field is empty.
                 */
                this.email.body = '<p><br class="ql-line-break"></p>';
            }
            this.email.body += `<p><br class="ql-line-break"></p>${emailSignatureForThisUser.content}`;
        }
    }

    private insertDefaultEmailTemplate(): Promise<boolean> {
        const emailDefaultTemplateId =
            this.userPreferences.defaultRemoteSignatureEmailTemplates['remoteSignatureEmail'] &&
            this.userPreferences.defaultRemoteSignatureEmailTemplates['remoteSignatureEmail'][
                this.selectedRecipient.role
            ];
        // Default template must exist
        if (!emailDefaultTemplateId) {
            return Promise.resolve(false);
        }

        // Only insert default if target message is empty
        if (!this.email.subject && !this.email.body) {
            return new Promise<boolean>((resolve, reject) => {
                this.findMessageTemplateById(emailDefaultTemplateId).subscribe({
                    next: async (textTemplate) => {
                        const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
                            reportId: this.report?._id,
                            letterDocument: undefined,
                        });

                        this.email.subject = replacePlaceholders({
                            textWithPlaceholders: textTemplate.subject,
                            placeholderValues,
                            fieldGroupConfigs: this.fieldGroupConfigs,
                            isHtmlAllowed: false,
                        });
                        this.email.body = replacePlaceholders({
                            textWithPlaceholders: textTemplate.body,
                            placeholderValues,
                            fieldGroupConfigs: this.fieldGroupConfigs,
                        });

                        /**
                         * If the email signature was added even though no default text template exists, a user would
                         * insert his own signature when visiting the print and transmission screen. Often, however,
                         * another colleague would actually send the email - now with the wrong signature.
                         */
                        await this.insertEmailSignature();

                        this.saveReport();
                        resolve(true);
                    },
                    error: reject,
                });
            });
        }
    }

    private findMessageTemplateById(templateId: string): Observable<TextTemplate> {
        return this.textTemplateService
            .find({
                type: 'remoteSignatureEmail',
                role: this.selectedRecipient.role,
            })
            .pipe(
                switchMap((textTemplates) => observableOf(...textTemplates)),
                filter((textTemplate) => textTemplate._id === templateId),
            );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Message Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Outgoing Messages
    //****************************************************************************/
    public editAsNew(outgoingMessage: OutgoingMessage) {
        const outgoingMessageCopy = new OutgoingEmailMessage(outgoingMessage);
        outgoingMessageCopy._id = generateId();

        this.selectedRecipient.outgoingMessageDraft.remoteSignature = outgoingMessageCopy;
        this.isComposingNewMessage = true;
        this.saveReport();
    }

    private async loadOutgoingMessages(): Promise<void> {
        this.outgoingMessages = await this.outgoingMessageService
            .find({ reportId: this.report._id, source: 'remoteSignature' })
            .toPromise();
    }
    private registerOutgoingMessagesWebsocketEvents() {
        const createUpdatesSubscription: Subscription =
            this.outgoingMessageService.createdFromExternalServerOrLocalBroadcast$.subscribe({
                next: (createdOutgoingMessage: OutgoingMessage) => {
                    if (
                        this.outgoingMessages.some(
                            (outgoingMessage) => outgoingMessage._id === createdOutgoingMessage._id,
                        )
                    ) {
                        return;
                    }
                    this.outgoingMessages.push(createdOutgoingMessage);
                },
            });
        this.subscriptions.push(createUpdatesSubscription);

        const patchUpdatesSubscription: Subscription =
            this.outgoingMessageService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
                next: (patchedEvent: PatchedEvent<OutgoingMessage>) => {
                    if (!this.outgoingMessages) return;

                    // If this view holds the record being updated, update it.
                    const outgoingMessage = this.outgoingMessages.find(
                        (outgoingMessage) => outgoingMessage._id === patchedEvent.patchedRecord._id,
                    );
                    if (outgoingMessage) {
                        applyOfflineSyncPatchEventToLocalRecord({
                            localRecord: outgoingMessage,
                            patchedEvent,
                        });
                    }
                },
            });

        this.subscriptions.push(patchUpdatesSubscription);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Outgoing Messages
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public closeEditorOnEscKey(event: KeyboardEvent) {
        if (event.key === 'Escape' && !this.templateSelectorShown) {
            // Make sure saving is triggered if the user is still inside an input.
            if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                (document.activeElement as HTMLElement).blur();
            }
            event.preventDefault();
            event.stopPropagation();
            this.close.emit();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    protected readonly isReportLocked = isReportLocked;
}

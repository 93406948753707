import { generateId } from '@autoixpert/lib/generate-id';
import { InvoiceInvolvedPartyRole } from '@autoixpert/models/invoices/invoice';
import { CommunicationRecipientRole } from '@autoixpert/models/reports/involved-parties/involved-party';
import { DefaultDocumentOrderItem } from '@autoixpert/models/teams/default-document-order/default-document-order-item';

/**
 * A list connected to an involved party that sorts and activates the documents in the report.documents array for
 * sending emails or downloading the full PDF.
 */
export class DefaultDocumentOrder {
    constructor(template?: Partial<DefaultDocumentOrder>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    _id: string = generateId();

    /**
     * The 'default' is used when no other more specific default document order is found.
     */
    recipientRole: CommunicationRecipientRole | InvoiceInvolvedPartyRole | 'default';
    items: DefaultDocumentOrderItem[] = [];
}

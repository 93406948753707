import { OfficialFeeTable } from '@autoixpert/models/reports/assessors-fee/fee-calculation';

// Array shape: [damageValue, gross fee, net fee]
export const hukFeeTable2023: OfficialFeeTable = {
    year: 2023,
    table: [
        [500, 328.44, 276],
        [750, 366.52, 308],
        [1_000, 455.77, 383],
        [1_250, 527.17, 443],
        [1_500, 567.63, 477],
        [1_750, 600.36, 504.5],
        [2_000, 633.08, 532],
        [2_250, 662.83, 557],
        [2_500, 691.39, 581],
        [2_750, 718.17, 603.5],
        [3_000, 743.16, 624.5],
        [3_250, 767.55, 645],
        [3_500, 794.92, 668],
        [3_750, 821.1, 690],
        [4_000, 846.09, 711],
        [4_250, 867.51, 729],
        [4_500, 890.72, 748.5],
        [4_750, 910.95, 765.5],
        [5_000, 930.58, 782],
        [5_250, 952, 800],
        [5_500, 975.8, 820],
        [5_750, 996.03, 837],
        [6_000, 1_017.45, 855],
        [6_500, 1_047.8, 880.5],
        [7_000, 1_078.74, 906.5],
        [7_500, 1_110.87, 933.5],
        [8_000, 1_147.76, 964.5],
        [8_500, 1_183.46, 994.5],
        [9_000, 1_217.97, 1_023.5],
        [9_500, 1_251.88, 1_052],
        [10_000, 1_286.39, 1_081],
        [10_500, 1_325.66, 1_114],
        [11_000, 1_354.82, 1_138.5],
        [11_500, 1_394.68, 1_172],
        [12_000, 1_427.41, 1_199.5],
        [12_500, 1_463.11, 1_229.5],
        [13_000, 1_500.59, 1_261],
        [13_500, 1_536.29, 1_291],
        [14_000, 1_569.02, 1_318.5],
        [14_500, 1_605.91, 1_349.5],
        [15_000, 1_643.39, 1_381],
        [16_000, 1_703.49, 1_431.5],
        [17_000, 1_762.39, 1_481],
        [18_000, 1_818.92, 1_528.5],
        [19_000, 1_883.77, 1_583],
        [20_000, 1_946.25, 1_635.5],
        [21_000, 2_015.86, 1_694],
        [22_000, 2_080.12, 1_748],
        [23_000, 2_142.6, 1_800.5],
        [24_000, 2_203.88, 1_852],
        [25_000, 2_259.22, 1_898.5],
        [26_000, 2_319.31, 1_949],
        [27_000, 2_384.76, 2_004],
        [28_000, 2_452.59, 2_061],
        [29_000, 2_509.71, 2_109],
        [30_000, 2_588.25, 2_175],
        [32_500, 2_731.65, 2_295.5],
        [35_000, 2_879.21, 2_419.5],
        [37_500, 3_024.98, 2_542],
        [40_000, 3_174.92, 2_668],
        [42_500, 3_383.17, 2_843],
        [45_000, 3_563.46, 2_994.5],
        [47_500, 3_725.89, 3_131],
        [50_000, 3_869.29, 3_251.5],
    ],
};

import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { DatevConfig } from '@autoixpert/models/teams/datev-config';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { slideInAndOutVertically } from 'src/app/shared/animations/slide-in-and-out-vertical.animation';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
    selector: 'datev-invoices-export-dialog',
    templateUrl: 'datev-invoices-export-dialog.component.html',
    styleUrls: ['datev-invoices-export-dialog.component.scss'],
    animations: [slideInAndOutVertically()],
})
export class DatevInvoicesExportDialogComponent implements OnInit {
    constructor(
        private dialog: MatDialogRef<DatevInvoicesExportDialogComponent, DatevInvoicesExportDialogReturnValue>,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public data: DatevInvoicesExportDialogData,
    ) {}

    public datevConfig: DatevConfig;
    public areAdvancedOptionsVisible = false;
    public loggedInUser: User;
    public team: Team;

    ngOnInit() {
        this.loggedInUser = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        this.datevConfig = this.data.datevConfig;
        this.openAdvancedSettingsIfAlreadyFilled();
    }

    get userHasAdminRights(): boolean {
        return isAdmin(this.loggedInUser._id, this.team);
    }

    private openAdvancedSettingsIfAlreadyFilled() {
        // The accountNo is filled for all customers
        if (
            this.datevConfig.defaultBusinessPartnerAccountNo ||
            this.datevConfig.defaultCostCenter ||
            this.datevConfig.bookingTextPattern
        ) {
            this.areAdvancedOptionsVisible = true;
        }
    }

    public displayMissingAccessRightsError(): void {
        this.toastService.error(
            'Admin-Rechte erforderlich',
            'Wende dich an deinen Administrator, um die Exporteinstellungen zu verändern.',
        );
    }

    //*****************************************************************************
    //  Export Type
    //****************************************************************************/
    public selectExportType(exportType: 'invoiceDate' | 'paymentDate'): void {
        if (!this.userHasAdminRights) {
            this.displayMissingAccessRightsError();
            return;
        }
        this.datevConfig.exportByInvoiceOrPaymentDate = exportType;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Export Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Result
    //****************************************************************************/
    public triggerExport(): void {
        this.dialog.close({
            datevConfig: this.datevConfig,
            startExport: true,
        });
    }

    public closeDialog(): void {
        this.dialog.close({
            datevConfig: this.datevConfig,
            startExport: false,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Result
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface DatevInvoicesExportDialogData {
    datevConfig: DatevConfig;
}

export interface DatevInvoicesExportDialogReturnValue {
    datevConfig: DatevConfig;
    startExport: boolean;
}

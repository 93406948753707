import { IsoDate } from '../../../lib/date/iso-date.types';
import { AfzzertProcess } from '../afzzert-process';
import { Damage } from '../damage-description/damage';
import { PaintThicknessMeasurement } from '../paint-thickness-measurement';
import { PaintThicknessScale } from '../paint-thickness-scale';
import { AudatexVehicleIdentification } from './audatex-vehicle-identification';
import { Axle } from './axle';
import { CustomCarProperty } from './custom-car-property';
import { DatVehicleIdentification } from './dat-vehicle-identification';
import { GtmotiveVehicleIdentification } from './gtmotive-vehicle-identification';
import { SpareTireEquipment } from './spare-tire-equipment';
import { Tire } from './tire';

export class Car {
    constructor(template: Partial<Car> = {}) {
        Object.assign(this, template);
    }
    vin: string;
    verifiedVin: string;
    identificationProvider: 'audatex' | 'dat' | 'gtmotive';
    kbaCode: string;

    datIdentification: DatVehicleIdentification = new DatVehicleIdentification();
    audatexIdentification: AudatexVehicleIdentification = new AudatexVehicleIdentification();
    gtmotiveIdentification: GtmotiveVehicleIdentification = new GtmotiveVehicleIdentification();

    make: string;
    model: string;
    submodel: string;

    shape: CarShape;
    customShapeLabel: string;

    performanceKW: number;
    performanceHP: number;
    engineDisplacement: number;
    numberOfGears: number;
    gearboxType: 'manual' | 'automatic' | 'sequential' | 'other' | null; // "sequential" is often used in motorcycles. It requires shifting from one gear to the next, shifting from 1 to 3 is impossible.
    gearboxModelName: string;
    numberOfDoors: number;
    numberOfSeats: number;
    numberOfAxles: number;
    numberOfPoweredAxles: number;
    numberOfCylinders: number;
    engineConfiguration: string;
    axles: Axle[] = [
        new Axle({
            axleNumber: 1,
            axlePosition: 'front',
            axleLoad: null,
            leftTire: new Tire({ axle: 1, position: 'left' }),
            rightTire: new Tire({ axle: 1, position: 'right' }),
            isSteerable: true,
        }),
        new Axle({
            axleNumber: 2,
            axlePosition: 'rear',
            axleLoad: null,
            leftTire: new Tire({ axle: 2, position: 'left' }),
            rightTire: new Tire({ axle: 2, position: 'right' }),
            isSteerable: false,
        }),
    ];
    //spareWheel?: Tire;
    hasTireRepairKit: boolean;
    hasSecondTireSet: boolean;

    runsOnGasoline: boolean;
    runsOnDiesel: boolean;
    runsOnLPG: boolean;
    runsOnNaturalGasoline: boolean;
    /**
     * Electric bikes have an electric motor but no high voltage battery.
     * A high voltage battery is the most critical part of electric cars and therefore, if a car runs on electricity,
     * we display inputs like highVoltageCheck or battery status. These are not relevant for bikes with a small and cheap battery.
     *
     * Bikes with electric motor do not have the property `runsOnElectricity` set to true since they do not need all the special functions for electric cars.
     * We only display some specific inputs (like e.g. capacity) for bikes with electric motor.
     */
    runsOnElectricity: boolean;
    runsOnBiodiesel: boolean;
    runsOnHydrogen: boolean;
    runsOnSomethingElse: string;
    emissionGroup: number; // Which color does the emission sticker have?
    emissionStandard: string; // Which official norm is the vehicle associated with?

    /**
     * May contain not only the year but also the month and day from which date on the car mode has been in production.
     * The DAT returns a specific date for instance.
     * If the user selects another year, only the year will be saved with Jan, 1 (e. g. '2025-01-01')
     */
    productionYear: IsoDate;
    firstRegistration: IsoDate;
    latestRegistration: IsoDate;
    numberOfPreviousOwners: NumberOfPreviousOwnersType;
    sourceOfNumberOfPreviousOwners?: string;
    nextGeneralInspection: IsoDate;
    nextSafetyTest: IsoDate;
    mileageMeter: number;
    mileage: number;
    mileageAsStated: number;
    mileageUnit: MileageUnit = 'km';
    mileageComment: string;
    unloadedWeight: number;
    maximumTotalWeight: number;
    length: number;
    width: number;
    height: number;
    wheelBase: number;
    // axleLoadOne: number;
    // axleLoadTwo: number;
    // axleLoadThree?: number;
    // axleLoadFour?: number;
    roadworthiness: string;

    /**
     * Electric vehicles
     */
    batteryCapacity: number;

    // If the battery is rented, the owner of the car does not own the battery.
    // In this case, the replacement value and residual value does not include the battery value.
    carOwnerIsBatteryOwner?: boolean;
    batteryOwner: string;

    // Batterieerhaltungszustand
    // The battery's state of health is a measurement of the battery's remaining capacity.
    batteryStateOfHealth: number;
    batteryStateOfHealthComment: string;

    // Batterieschaden
    // Indicates whether the battery is damaged.
    batteryDamage: 'visualInspectionNotPossible' | 'noVisualDamage' | 'visualDamage';
    batteryDamageComment: string;

    licensePlate: string;
    spareTireEquipment: SpareTireEquipment = new SpareTireEquipment();
    damages: Damage[] = [];
    damageDescription: string;
    repairedPreviousDamage: string;
    unrepairedPreviousDamage: string;
    meantimeDamage: string;
    autobodyCondition: string;
    generalCondition: string;
    interiorCondition: string;
    undercarriageCondition: string;
    serviceBookComplete: boolean;
    lastServiceDate: IsoDate;
    lastServiceMileage: number; // The mileage the vehicle had when the last service was done.
    paintColor: string;
    paintColorCode: string;
    paintCondition: string;
    paintType: string;
    paintThicknessMeasurementComment?: string;
    paintThicknessMeasurements?: PaintThicknessMeasurement[];
    paintThicknessSelectedScaleId?: PaintThicknessScale['_id'];

    /**
     * Afz-Zert. is a provider of old vehicle certificates (German: "Altfahrzeugzertifikate").
     */
    afzzertProcess?: AfzzertProcess;

    /**
     * German "Besonderheiten"
     * The assessor may add special features, such as tuning elements.
     */
    specialFeature: string;
    // Special equipment of oldtimers. Maily used in the oldtimer valuation process.
    // Other equipment is stored in its own collection "carequipments". They are not part of the car object
    // to prevent the client's storage from overflowing.
    specialEquipment: string[] = [];
    airbagsReleased: boolean;
    airbagsComment: string;

    /**
     * German "Bemerkungen zum Fahrzeug"
     * The assessor may enter any information that other fields don't cover.
     */
    comment: string;

    /**
     * Custom car properties allow the assessor to add additional, value relevant information into the car data.
     * E.g. individual components of a bicycle.
     */
    customProperties: CustomCarProperty[] = [];
}

export type MileageUnit = 'km' | 'mi' | 'h'; // Kilometer, Meilen, Betriebsstunden

export type CarShape =
    | 'bicycle'
    | 'e-bike'
    | 'pedelec'
    | 'sedan'
    | 'compact'
    | 'coupe'
    | 'stationWagon'
    | 'suv'
    | 'convertible'
    | 'van'
    | 'transporter'
    | 'motorcycle'
    | 'pickup'
    | 'motorHome'
    | 'caravanTrailer'
    | 'trailer'
    | 'truck'
    | 'semiTruck'
    | 'semiTrailer'
    | 'bus';

export type NumberOfPreviousOwnersType = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | 'multiple' | 'unknown';

export function getFuelTypes(car: Car) {
    const fuelTypes = [];

    if (car.runsOnGasoline) fuelTypes.push('Benzin');
    if (car.runsOnDiesel) fuelTypes.push('Diesel');
    if (car.runsOnLPG) fuelTypes.push('Autogas');
    if (car.runsOnNaturalGasoline) fuelTypes.push('Erdgas');
    if (car.runsOnElectricity) fuelTypes.push('Elektro');
    if (car.runsOnBiodiesel) fuelTypes.push('Biodiesel');
    if (car.runsOnHydrogen) fuelTypes.push('Wasserstoff');
    if (car.runsOnSomethingElse) fuelTypes.push('Sonstiger Antrieb');

    return fuelTypes;
}

export function getMileageInKm(car: Car) {
    const mileageNumber: number = car.mileage || car.mileageMeter || car.mileageAsStated;
    if (car.mileageUnit === 'km' || !car.mileageUnit) {
        return mileageNumber;
    } else if (car.mileageUnit === 'mi') {
        const kilometersPerMile = 1.609344;
        return mileageNumber * kilometersPerMile;
    }
}

export function isCarSemiTruck(carShape: Car['shape']): boolean {
    return new Array<CarShape>('semiTrailer', 'semiTruck', 'truck').includes(carShape);
}

import { formatNumber } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { AdditionalSpecialCosts } from '@autoixpert/models/reports/market-value/additional-special-costs';
import { RemainingFuel } from '@autoixpert/models/reports/market-value/remaining-fuel';
import { Retrofit, RetrofitItem } from '@autoixpert/models/reports/market-value/retrofit-item';
import { Report } from '@autoixpert/models/reports/report';
import { blockChildAnimationOnLoad } from '../../../../shared/animations/block-child-animation-on-load.animation';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';
import { slideInAndOutVertically } from '../../../../shared/animations/slide-in-and-out-vertical.animation';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';

/**
 * Sonderkosten-Dialog
 * - Umbaukosten (retrofit costs)
 * - Restkraftstoff (remaining fuel)
 * - ...
 */
@Component({
    selector: 'special-costs-dialog',
    templateUrl: 'special-costs-dialog.component.html',
    styleUrls: ['special-costs-dialog.component.scss'],
    animations: [slideInAndOutVertically(), blockChildAnimationOnLoad(), runChildAnimations()],
})
export class SpecialCostsDialogComponent implements OnChanges {
    constructor(
        public userPreferences: UserPreferencesService,
        private toastService: ToastService,
    ) {}

    @Input() report: Report;
    @Output() reportChange: EventEmitter<Report> = new EventEmitter<Report>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    @ViewChildren('additionalCostsTitleInput') additionalCostsTitleInputs: QueryList<ElementRef>;

    protected roundingEditModeActive: boolean = false;
    protected retrofitCommentShown: boolean = false;
    protected retrofitCommentTextTemplatesShown = false;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnChanges(changes: SimpleChanges) {
        if (changes['report']) {
            this.checkForRequiredProperties();
            this.displayRetrofitCommentIfPresent();
        }
    }

    private loadDefaultValues(): void {
        const damageCalculation = this.report.damageCalculation;

        // Price per Liter
        if (!damageCalculation.remainingFuel.pricePerLiter) {
            if (this.getMainFuelType() === 'gasoline' && this.userPreferences.pricePerLiterGasoline) {
                damageCalculation.remainingFuel.pricePerLiter = this.userPreferences.pricePerLiterGasoline || null;
            }
            if (this.getMainFuelType() === 'diesel' && this.userPreferences.pricePerLiterDiesel) {
                damageCalculation.remainingFuel.pricePerLiter = this.userPreferences.pricePerLiterDiesel || null;
            }
        }

        // Disposal
        if (!damageCalculation.disposalCosts && this.userPreferences.disposalCosts) {
            damageCalculation.disposalCosts = this.userPreferences.disposalCosts || null;
        }

        // Deregistration & Registration
        if (
            !damageCalculation.deregistrationAndRegistrationCosts &&
            this.userPreferences.deregistrationAndRegistrationCosts
        ) {
            damageCalculation.deregistrationAndRegistrationCosts =
                this.userPreferences.deregistrationAndRegistrationCosts || null;
        }
    }

    private checkForRequiredProperties(): void {
        let saveNecessary: boolean = false;
        if (!this.report.damageCalculation.remainingFuel) {
            this.report.damageCalculation.remainingFuel = new RemainingFuel();
            // If the remaining fuel object had to be patched, this looks like the first time the user opens the dialog.
            this.loadDefaultValues();
            saveNecessary = true;
        }
        if (!this.report.damageCalculation.retrofit) {
            this.report.damageCalculation.retrofit = new Retrofit();
            saveNecessary = true;
        }
        if (!this.report.damageCalculation.retrofit.items) {
            this.report.damageCalculation.retrofit.items = [];
            saveNecessary = true;
        }
        if (!this.report.damageCalculation.additionalCosts) {
            this.report.damageCalculation.additionalCosts = [];
            saveNecessary = true;
        }

        if (saveNecessary) {
            this.emitReportChange();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Remaining Fuel
    //****************************************************************************/
    public getMainFuelType():
        | 'gasoline'
        | 'diesel'
        | 'autogas'
        | 'methane'
        | 'electric'
        | 'biodiesel'
        | 'hydrogen'
        | string {
        if (this.report.car.runsOnGasoline) {
            return 'gasoline';
        }
        if (this.report.car.runsOnDiesel) {
            return 'diesel';
        }
        if (this.report.car.runsOnLPG) {
            return 'autogas';
        }
        if (this.report.car.runsOnNaturalGasoline) {
            return 'methane';
        }
        if (this.report.car.runsOnElectricity) {
            return 'electric';
        }
        if (this.report.car.runsOnBiodiesel) {
            return 'biodiesel';
        }
        if (this.report.car.runsOnHydrogen) {
            return 'hydrogen';
        }
        // In case of null, the entire function returns null
        return this.report.car.runsOnSomethingElse;
    }

    public calculateTotalFuelCosts(overwrite?: boolean): void {
        if (
            this.report.damageCalculation.remainingFuel.amount === null ||
            this.report.damageCalculation.remainingFuel.pricePerLiter === null
        )
            return;

        // Don't overwrite unless explicitly stated
        if (!overwrite && this.report.damageCalculation.remainingFuel.costs) return;

        this.report.damageCalculation.remainingFuel.costs = this.roundRemainingFuelCosts();
        this.emitReportChange();
    }

    private roundRemainingFuelCosts(): number {
        const calculatedCosts: number =
            this.report.damageCalculation.remainingFuel.amount *
            this.report.damageCalculation.remainingFuel.pricePerLiter;

        // Zero or null -> Don't round
        if (!this.userPreferences.remainingFuelRoundingAmount) return calculatedCosts;

        const differenceToLeastNaturalDivider: number =
            calculatedCosts % this.userPreferences.remainingFuelRoundingAmount;

        // No difference? -> Value already matches rounding target.
        if (!differenceToLeastNaturalDivider) {
            return calculatedCosts;
        }
        // Round
        let roundedValue = calculatedCosts - differenceToLeastNaturalDivider;
        if (this.userPreferences.remainingFuelRoundingDirection === 'up') {
            roundedValue += this.userPreferences.remainingFuelRoundingAmount;
        }
        return roundedValue;
    }

    public getFuelCostsHint(): string {
        if (
            !this.report.damageCalculation.remainingFuel.amount ||
            !this.report.damageCalculation.remainingFuel.pricePerLiter
        )
            return '';

        const calculatedCosts: number =
            this.report.damageCalculation.remainingFuel.amount *
            this.report.damageCalculation.remainingFuel.pricePerLiter;

        // Don't show a hint if the calculated and entered values are equal
        if (calculatedCosts === this.report.damageCalculation.remainingFuel.costs) return;

        return `Berechnet: ${formatNumber(calculatedCosts, 'de', '1.2-2')} €`;
    }

    public enterRoundingEditMode(): void {
        this.roundingEditModeActive = true;
    }

    public leaveRoundingEditMode(): void {
        this.roundingEditModeActive = false;
    }

    public removeRoundingAmount(): void {
        this.userPreferences.remainingFuelRoundingAmount = null;
    }

    public rememberPricePerLiter(): void {
        if (this.getMainFuelType() === 'gasoline') {
            this.userPreferences.pricePerLiterGasoline = this.report.damageCalculation.remainingFuel.pricePerLiter;
            this.toastService.success('Benzin-Preis gemerkt');
        }
        if (this.getMainFuelType() === 'diesel') {
            this.userPreferences.pricePerLiterDiesel = this.report.damageCalculation.remainingFuel.pricePerLiter;
            this.toastService.success('Diesel-Preis gemerkt');
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remaining Fuel
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrofit Parts
    //****************************************************************************/
    public addRetrofitItem() {
        const item = new RetrofitItem();
        this.report.damageCalculation.retrofit.items.push(item);
        this.emitReportChange();
    }

    public removeRetrofitItem(retrofit: RetrofitItem) {
        removeFromArray(retrofit, this.report.damageCalculation.retrofit.items);
        this.emitReportChange();
    }

    private displayRetrofitCommentIfPresent() {
        if (this.report.damageCalculation.retrofit.comment) {
            this.retrofitCommentShown = true;
        }
    }

    public toggleRetrofitComment(): void {
        this.retrofitCommentShown = !this.retrofitCommentShown;

        if (!this.retrofitCommentShown) {
            this.report.damageCalculation.retrofit.comment = null;
        }
    }

    public getTotalRetrofitCosts(): number {
        if (!this.report.damageCalculation?.retrofit?.items) return;

        return this.report.damageCalculation.retrofit.items.reduce(
            (total, value) => total + (value.costsGross || 0),
            0,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrofit Parts
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Disposal
    //****************************************************************************/
    public rememberDisposalCosts(): void {
        this.userPreferences.disposalCosts = this.report.damageCalculation.disposalCosts;
        this.toastService.success('Entsorgungskosten gemerkt');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Disposal
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Deregistration & Registration
    //****************************************************************************/
    public rememberDeregistrationAndRegistrationCosts(): void {
        this.userPreferences.deregistrationAndRegistrationCosts =
            this.report.damageCalculation.deregistrationAndRegistrationCosts;
        this.toastService.success('Ab- & Anmeldegebühr gemerkt');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Deregistration & Registration
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Additional Costs
    //****************************************************************************/
    public addAdditionalCostItem(): void {
        this.report.damageCalculation.additionalCosts.push(new AdditionalSpecialCosts());

        this.emitReportChange();

        // Wait for the new row (including the title input field) to be rendered, then focus it
        setTimeout(() => {
            this.additionalCostsTitleInputs.last.nativeElement.focus();
        }, 0);
    }

    public removeAdditionalCostItem(costItem: AdditionalSpecialCosts): void {
        if (this.report.state === 'done') {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Wenn du Änderungen machen möchtest, öffne das Gutachten zuerst wieder.',
            );
            return;
        }

        const index = this.report.damageCalculation.additionalCosts.indexOf(costItem);
        this.report.damageCalculation.additionalCosts.splice(index, 1);
        this.emitReportChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Additional Costs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sum
    //****************************************************************************/
    public getTotalSpecialCosts(): number {
        const damageCalculation = this.report.damageCalculation;

        return (
            damageCalculation.remainingFuel.costs +
            damageCalculation.retrofit?.items?.reduce((total, value) => total + (value.costsGross || 0), 0) +
            (damageCalculation.disposalCosts || 0) + // May be undefined
            (damageCalculation.deregistrationAndRegistrationCosts || 0) + // May be undefined
            damageCalculation.additionalCosts.reduce((total, costItem) => total + (costItem.costs || 0), 0)
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sum
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public emitReportChange(): void {
        this.reportChange.emit(this.report);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog(): void {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    private handleKeydown(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.closeDialog();
                }
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly isReportLocked = isReportLocked;
}

import { generateId } from '../../../lib/generate-id';

export class Retrofit {
    items: RetrofitItem[] = [];
    comment: string;
}

export class RetrofitItem {
    _id: string = generateId();
    description: string = null;
    costsGross: number = null;
}

import { B } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import JSZip from 'jszip';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { utf16ToWindows1252Bytes } from '@autoixpert/lib/datev/csv-export/utf16-to-windows1252';
import { executeInvoiceSanityChecksForDatevExport } from '@autoixpert/lib/datev/execute-invoice-sanity-checks-for-datev-export';
import { getDatevExportDocumentXml } from '@autoixpert/lib/datev/get-datev-export-document-xml';
import { getInvoicePdfFilename, getInvoiceXmlFilename } from '@autoixpert/lib/datev/get-datev-export-filenames';
import { PaymentWithInvoiceReference, getPaymentsFromInvoice } from '@autoixpert/lib/datev/get-payments-from-invoice';
import { getQueryForInvoicesByPaymentDate } from '@autoixpert/lib/invoices/get-query-for-invoices-by-payment-date';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { DatevConfig } from '@autoixpert/models/teams/datev-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { convertPaymentsToAxCsv } from '../../libraries/payments-export/convert-payments-to-ax-csv';
import { InvoiceService } from '../invoice.service';
import { ToastService } from '../toast.service';

/**
 * When using this guard, the route must be passed a 'data' object with a 'requiredAccessRight' property.
 */
@Injectable()
export class DatevInvoiceExportService {
    constructor(
        private invoiceService: InvoiceService,
        private httpClient: HttpClient,
        private toastService: ToastService,
    ) {}
    public isInProgress = false;

    // Display the progress of the export in the UI
    private totalInvoicesCount = new BehaviorSubject(0);
    public totalInvoicesCount$ = this.totalInvoicesCount.asObservable();
    private processedInvoicesCount = new BehaviorSubject(0);
    public processedInvoicesCount$ = this.processedInvoicesCount.asObservable();

    public async getInvoicesByInvoiceDateZip({
        fromDate,
        toDate,
        filterOfficeLocations,
        dateFilterType,
    }: {
        fromDate: IsoDate;
        toDate: IsoDate;
        filterOfficeLocations: OfficeLocation['_id'][];
        dateFilterType: DatevConfig['exportByInvoiceOrPaymentDate'];
    }): Promise<{ file: Blob; filename: string }> {
        this.isInProgress = true;

        this.totalInvoicesCount.next(0);
        this.processedInvoicesCount.next(0);
        const datevZip = new JSZip();

        let exportStartDate = fromDate;
        let exportEndDate = toDate;

        /**
         * Build Query string to query invoices
         */
        let query: any = {};
        if (dateFilterType === 'invoiceDate') {
            if (exportStartDate || exportEndDate) {
                query.date = {};
            }
            if (exportStartDate) {
                query.date.$gte = exportStartDate;
            }
            if (exportEndDate) {
                query.date.$lte = exportEndDate;
            }
        } else if (dateFilterType === 'paymentDate') {
            query = getQueryForInvoicesByPaymentDate({ fromDate, toDate });
        }

        // Office locations filter
        if (filterOfficeLocations.length > 0) {
            query.officeLocationId = { $in: filterOfficeLocations };
        }

        // For now, we exclude imported GTÜ invoices. We might optionally include them in the future.
        query.importedFromThirdPartySystem = { $ne: 'gtue' };

        const invoices: Invoice[] = await this.invoiceService.find(query).toPromise();

        try {
            this.totalInvoicesCount.next(invoices.length);

            const [incompleteInvoices, invoicesToExport]: [Invoice[], Invoice[]] =
                executeInvoiceSanityChecksForDatevExport(invoices);
            if (incompleteInvoices.length > 0) {
                this.toastService.warn(
                    'Unvollständige Rechnungen ignoriert',
                    'Einige Rechnungen waren unvollständig (keine Rechnungsnummer, kein Rechnungsdatum oder kein Betrag) nicht exportiert werden: ' +
                        incompleteInvoices.map((invoice) => invoice.number).join(', '),
                );
            }

            //*****************************************************************************
            //  Dates for ZIP Filename
            //****************************************************************************/
            if (!exportStartDate) {
                exportStartDate = invoicesToExport[0].date;
            }
            if (!exportEndDate) {
                exportEndDate = invoicesToExport[invoicesToExport.length - 1].date;
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Dates for ZIP Filename
            /////////////////////////////////////////////////////////////////////////////*/

            if (dateFilterType === 'paymentDate') {
                // Add the payments CSV to the ZIP file.
                // This is currently omitted since it might cause problems with DATEV importing programs.
                // this.addPaymentsCSVToZip(datevZip, { invoices: invoicesToExport, fromDate, toDate });
            }

            datevZip.file('document.xml', getDatevExportDocumentXml({ invoices: invoicesToExport }));

            //*****************************************************************************
            //  Add Individual Invoice to DATEV ZIP
            //****************************************************************************/
            // Download every invoice one after another to reduce load on the autoiXpert backend (heavy load if queried in parallel).
            for (const invoice of invoicesToExport) {
                await Promise.all([
                    this.addInvoicePdfToZip(datevZip, invoice),
                    this.addInvoiceXmlToZip(datevZip, invoice),
                ]);
                this.processedInvoicesCount.next(this.processedInvoicesCount.value + 1);
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Add Individual Invoice to DATEV ZIP
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Build the ZIP File and filename
            //****************************************************************************/
            const datevZipFile = await datevZip.generateAsync({ type: 'blob' });
            let filename: string;
            if (dateFilterType === 'invoiceDate') {
                filename = `DATEV Rechnungsbelege - ${moment(exportStartDate).format('DD.MM.YYYY')} bis ${moment(
                    exportEndDate,
                ).format('DD.MM.YYYY')}.zip`;
            } else if (dateFilterType === 'paymentDate') {
                filename = `Zahlungen mit Rechnungen - ${moment(fromDate).format('DD.MM.YYYY')} bis ${moment(
                    toDate,
                ).format('DD.MM.YYYY')}.zip`;
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Build the ZIP File and filename
            /////////////////////////////////////////////////////////////////////////////*/

            this.isInProgress = false;
            return { file: datevZipFile, filename };
        } catch (error) {
            this.isInProgress = false;
            console.error('ERROR_CREATING_DATEV_ARCHIVE', { error });
            throw error;
        }
    }

    /**
     * Loads the invoice PDF for the given invoice and adds it to the ZIP file.
     */
    private async addInvoicePdfToZip(zipFile: any, invoice: Invoice): Promise<void> {
        let invoicePdf: Blob;

        try {
            const invoiceDocument: DocumentMetadata = invoice.documents.find(
                (documentMetadata) => documentMetadata.type === 'invoice',
            );
            let downloadPath: string = `/api/v0/invoices/${invoice._id}/documents/invoice?format=pdf`;
            if (invoiceDocument.uploadedDocumentId) {
                downloadPath = `/api/v0/invoices/${invoice._id}/documents/userUploads/${invoiceDocument.uploadedDocumentId}`;
            }

            invoicePdf = await this.httpClient
                .get(downloadPath, {
                    observe: 'body',
                    responseType: 'blob',
                })
                .toPromise();
        } catch (error) {
            throw new AxError({
                code: 'GETTING_INVOICE_PDF_FAILED',
                message: `The invoice PDF could not be loaded from the server.`,
                data: {
                    invoice,
                },
                error,
            });
        }

        zipFile.file(getInvoicePdfFilename(invoice), invoicePdf);
    }

    /**
     * Loads the DATEV XML for the given invoice and adds it to the ZIP file.
     */
    private async addInvoiceXmlToZip(zipFile: any, invoice: Invoice): Promise<void> {
        let invoiceXml: Blob;

        try {
            invoiceXml = await this.httpClient
                .get(`/api/v0/invoices/${invoice._id}/datev`, {
                    observe: 'body',
                    responseType: 'blob',
                })
                .toPromise();
        } catch (error) {
            throw new AxError({
                code: 'GETTING_INVOICE_DATEV_XML_FAILED',
                message: `The datev invoice xml could not be loaded from the server.`,
                data: {
                    invoice,
                },
                error,
            });
        }

        zipFile.file(getInvoiceXmlFilename(invoice), invoiceXml);
    }

    /**
     * Generate a human readable CSV file with all payments for the given invoices.
     * Currently omitted since it might cause problems with DATEV importing programs.
     */
    private addPaymentsCSVToZip(
        zipFile: any,
        { invoices, fromDate, toDate }: { invoices: Invoice[]; fromDate: IsoDate; toDate: IsoDate },
    ): void {
        //*****************************************************************************
        //  Extract Payments from Invoices
        //****************************************************************************/
        let payments: PaymentWithInvoiceReference[] = [];
        for (const invoice of invoices) {
            payments.push(...getPaymentsFromInvoice(invoice));
        }

        // Keep only the payments lying within the selected date range.
        payments = payments.filter((payment) => {
            const paymentDate = moment(payment.date);
            return paymentDate.isSameOrAfter(fromDate, 'day') && paymentDate.isSameOrBefore(toDate, 'day');
        });

        if (!payments.length) {
            throw new AxError({
                code: 'NO_PAYMENTS_FOR_THE_GIVEN_TIMEFRAME',
                message: 'Please specify a different timeframe for which payments shall be exported.',
                data: {
                    from: fromDate,
                    to: toDate,
                },
            });
        }

        // Move the earlier payments to the front of the array.
        payments.sort((paymentA, paymentB) => moment(paymentA.date).diff(paymentB.date, 'seconds'));
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Extract Payments from Invoices
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Create ZIP File
        //****************************************************************************/
        // Human readable CSV file --- not included because that might cause problems with DATEV importing programs.
        const csvContents = convertPaymentsToAxCsv({ payments });
        const csvBlob = new Blob([utf16ToWindows1252Bytes(csvContents)], {
            type: 'text/csv;charset=windows-1252',
        });
        zipFile.file(
            `Zahlungen mit Rechnungen vom ${moment(fromDate).format('DD-MM-YY')} bis ${moment(toDate).format('DD-MM-YY')}.csv`,
            csvBlob,
        );
    }
}

/**
 * lodash string functions remove all umlauts. Since we want our customers to use Umlauts in titles of documentBuildingBlocks,
 *  we implemented our own 'toCamelCase' which handles german umlauts.
 *
 * This function handles both, small letters at the beginning of a word, umlauts at the beginning of a word and abbreviations,
 * where only the first letter will be uppercase. All special characters except umlauts will be removed.
 *
 */
export function toCamelCase(input: string): string {
    // Convert all special characters to whitespace.
    const findAllSpecialCharacters = /[^a-zA-Z0-9ÄÖÜäöüß]/g;
    let output = input.replace(findAllSpecialCharacters, ' ');

    // Convert all letters to lowercase.
    output = output.replace(/[A-ZÄÖÜ]/g, (match: string) => {
        return match.toLowerCase();
    });

    // Convert all letters after white-space to uppercase.
    const findLettersAfterWhitespace = /\s(\S)/g;
    output = output.replace(findLettersAfterWhitespace, (match: string) => {
        return match.toUpperCase();
    });

    // Remove all white spaces.
    const findAllWhitespaces = /\s/g;
    output = output.replace(findAllWhitespaces, '');

    // Convert first letter is lowercase.
    return output.charAt(0).toLowerCase() + output.slice(1);
}

export function toCamelCaseWithUpperCaseFirstLetter(input: string): string {
    if (!input) {
        // Nothing to do, if string is empty
        return '';
    }

    const camelCaseWithLowerCaseFirstLetter = toCamelCase(input);
    return `${camelCaseWithLowerCaseFirstLetter[0].toUpperCase()}${camelCaseWithLowerCaseFirstLetter.substr(1)}`;
}

/**
 * Create camel case word but keep the case of the first letter.
 * Examples:
 *  - "Hat Rechtschutzversicherung" --> "HatRechtschutzversicherung"
 *  - "hat Rechtschutzversicherung" --> "hatRechtschutzversicherung"
 */
export function toCamelCaseKeepCaseFirstLetter(input: string): string {
    if (!input) {
        // Nothing to do, if string is empty
        return '';
    }

    input = input.trim();

    const camelCaseWithLowerCaseFirstLetter = toCamelCase(input);
    return `${input[0]}${camelCaseWithLowerCaseFirstLetter.substr(1)}`;
}

<!--********** The host element acts as the <tr> and is styled as such. **********-->
<!--<tr>-->

<!-- Desktop view (labels are placed inline) -->
<ng-container *ngIf="!isSmallScreen('small')">
    <!-- The thumbnail shall be loaded from the database. It's name will include the report id.-->
    <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
    <ng-container *ngTemplateOutlet="customerNameAndLabels"></ng-container>

    <td
        class="report-token-column"
        (click)="navigateToDetailsViewOnMobile()"
        *ngIf="userPreferences.reportListReportTokenShown">
        <span *ngIf="report.token" matTooltip="Aktenzeichen">{{ report.token }}</span>
        <span class="missing-data-info label" *ngIf="!report.token">Kein Aktenzeichen</span>
    </td>
    <td class="report-licensePlate-column" (click)="navigateToDetailsViewOnMobile()">
        <license-plate [licensePlate]="report.car.licensePlate" *ngIf="report.car.licensePlate"></license-plate>
        <span class="missing-data-info label" *ngIf="!report.car.licensePlate">Kein Kennzeichen</span>
    </td>

    <!--============================================-->
    <!-- Report Date -->
    <!--============================================-->
    <td class="report-createdAt" (click)="navigateToDetailsViewOnMobile()">
        <!--============================================-->
        <!-- Locked Report -->
        <!--============================================-->
        <!--********** Completion Date **********-->
        <span
            *ngIf="isReportLocked()"
            matTooltip="Fertigstellungsdatum: {{ report.completionDate | moment: 'DD.MM.YYYY' }}{{
                '\n'
            }}Abgeschlossen: {{ report.lockedAt | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
            {{ report.completionDate | moment: 'dd, DD.MM.YYYY' }}
        </span>
        <!--============================================-->
        <!-- END Locked Report -->
        <!--============================================-->

        <!--============================================-->
        <!-- Open Reports -->
        <!--============================================-->
        <ng-container *ngIf="!isReportLocked()" [ngSwitch]="selectedDateRow">
            <!--********** CreatedAt **********-->
            <span
                *ngSwitchCase="'created'"
                matTooltip="Erstellt: {{ report.createdAt | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                {{ report.createdAt | moment: 'dd, DD.MM.YYYY' }}
            </span>

            <!--********** Order **********-->
            <ng-container *ngSwitchCase="'order'">
                <span
                    *ngIf="report.orderDate"
                    matTooltip="Auftrag: {{ report.orderDate | moment: 'DD.MM.YYYY' }} {{
                        report.orderTime ? (report.orderTime | moment: 'HH:mm') + ' Uhr' : ''
                    }}">
                    {{ report.orderDate | moment: 'dd, DD.MM.YYYY' }}
                </span>
                <span class="missing-data-info label" *ngIf="!report.orderDate">Auftrag noch nicht erteilt</span>
            </ng-container>

            <!--********** Accident **********-->
            <ng-container *ngSwitchCase="'accident'">
                <span
                    *ngIf="report.accident?.date"
                    matTooltip="Unfall: {{ report.accident.date | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                    {{ report.accident.date | moment: 'dd, DD.MM.YYYY' }}
                </span>
                <span class="missing-data-info label" *ngIf="!report.accident?.date">Kein Unfalldatum</span>
            </ng-container>

            <!--********** First Visit **********-->
            <ng-container *ngSwitchCase="'firstVisit'">
                <span class="missing-data-info label" *ngIf="!report.visits[0]?.date">noch keine Besichtigung</span>

                <!--********** First Visit Date **********-->
                <div *ngIf="report.visits[0]?.date" class="first-visit-date">
                    <span
                        matTooltip="Erste Besichtigung: {{ report.visits[0].date | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                        {{ report.visits[0].date | moment: 'dd, DD.MM.YYYY' }}
                    </span>
                    <mat-icon
                        *ngIf="firstVisitIsToday"
                        class="medium-icon"
                        matTooltip="Besichtigung ist heute {{
                            report.visits[0].time ? '(' + (report.visits[0].time | moment: 'HH:mm') + ' Uhr)' : ''
                        }}">
                        today
                    </mat-icon>
                </div>

                <!--********** Last Visit Date **********-->
                <div class="last-visit-date" *ngIf="lastVisitDate">
                    <span
                        *ngIf="!lastVisitDate.isToday"
                        class="label"
                        matTooltip="Nachbesichtigung: {{ report.visits[0].date | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                        Nachbesichtigung am {{ lastVisitDate.date | moment: 'DD.MM.YYYY' }}
                    </span>
                    <span
                        *ngIf="lastVisitDate.isToday"
                        class="label"
                        matTooltip="Nachbesichtigung: {{ report.visits[0].date | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                        Nachbesichtigung heute
                    </span>
                    <mat-icon *ngIf="lastVisitDate.isToday" class="small-icon" matTooltip="Besichtigung ist heute">
                        today
                    </mat-icon>
                </div>
            </ng-container>
        </ng-container>
        <!--============================================-->
        <!-- END Open Reports -->
        <!--============================================-->
    </td>
    <!--============================================-->
    <!-- END Report Date -->
    <!--============================================-->
    <td class="report-car-model-column" (click)="navigateToDetailsViewOnMobile()">
        <div class="report-car-model-container">
            <img
                class="car-make-icon"
                *ngIf="report.car.make && iconForCarBrandExists(report.car.make)"
                [src]="iconFilePathForCarBrand(report.car.make)" />
            <div class="report-car-model-label">
                <!--********** Make **********-->
                <span class="car-make" *ngIf="report.car.make">{{ report.car.make }}&nbsp;</span>
                <span class="missing-data-info label" *ngIf="!report.car.make">Kein Hersteller &nbsp;</span>

                <!--********** Model **********-->
                <span *ngIf="report.car.model" class="car-model" [matTooltip]="report.car.model">
                    {{ report.car.model | cleanBaseModel }}
                </span>
                <span class="missing-data-info label" *ngIf="!report.car.model">Kein Modell</span>
            </div>
        </div>
    </td>

    <!--============================================-->
    <!-- Search Matches -->
    <!--============================================-->
    <td class="report-search-match-column" *ngIf="searchTerm">
        <div class="report-search-matches-container">
            <mat-icon class="report-search-match-icon small-icon">search</mat-icon>
            <div class="report-search-matches-column">
                <div
                    class="report-search-match"
                    *ngFor="let searchMatch of getFirstThreeSearchMatches()"
                    [matTooltip]="getSearchMatchName(searchMatch.name) + searchMatch.value">
                    <div *ngIf="!['RB Rechnungs-Nr.', 'SN Rechnungs-Nr.'].includes(searchMatch.name)">
                        {{ searchMatch.name }}
                    </div>
                    <div
                        class="display-flex flex-align-center"
                        *ngIf="['RB Rechnungs-Nr.', 'SN Rechnungs-Nr.'].includes(searchMatch.name)">
                        <img
                            class="report-search-match-alternative-icon"
                            src="/assets/images/icons/repair-confirmation-white.svg"
                            *ngIf="searchMatch.name === 'RB Rechnungs-Nr.'" />
                        <mat-icon
                            class="report-search-match-alternative-icon"
                            *ngIf="searchMatch.name === 'SN Rechnungs-Nr.'">
                            comment
                        </mat-icon>
                        Rechnungs-Nr.
                    </div>
                </div>

                <!--********** Additional matches **********-->
                <div
                    class="report-search-match"
                    *ngIf="searchMatches?.length > 3"
                    [matTooltip]="getAdditionalMatchesTooltip()">
                    + {{ searchMatches.length - 3 }}
                </div>
            </div>
        </div>
    </td>
    <!--============================================-->
    <!-- END Search Matches -->
    <!--============================================-->
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    <ng-container *ngTemplateOutlet="submenu"></ng-container>
</ng-container>

<!-- Mobile view (labels are placed below the default row) -->
<ng-container *ngIf="isSmallScreen('small')">
    <div class="mobile-default-row">
        <!-- The thumbnail shall be loaded from the database. It's name will include the report id.-->
        <ng-container *ngTemplateOutlet="thumbnail"></ng-container>
        <ng-container *ngTemplateOutlet="customerNameAndLabels"></ng-container>
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        <ng-container *ngTemplateOutlet="submenu"></ng-container>
    </div>
    <!--********** Labels **********-->
    <div *ngIf="userPreferences.reportListLabelsShown && report.labels.length > 0" class="report-labels-column">
        <div
            class="report-labels"
            labelDropdown
            [ngClass]="{ 'no-labels': !report.labels.length }"
            [(selectedLabels)]="report.labels"
            [labelGroup]="'report'"
            (selectedLabelsChange)="emitChange()">
            <label-display *ngIf="report.labels.length > 0" [labels]="report.labels" [small]="true"></label-display>
            <span *ngIf="report.labels.length < 1" class="no-labels-button">+ Label</span>
        </div>
    </div>
</ng-container>

<!--============================================-->
<!-- Templates used in different places         -->
<!--============================================-->
<ng-template #thumbnail>
    <td class="report-thumbnail-cell" (click)="navigateToDetailsViewOnMobile()">
        <div class="report-thumbnail-container thumbnail">
            <!--********** Photo Loaded **********-->
            <img *ngIf="getFirstReportPhoto() && getLocalThumbnailUrl()" [src]="getLocalThumbnailUrl()" />
            <!--********** Photo Loading **********-->
            <div
                *ngIf="getFirstReportPhoto() && !getLocalThumbnailUrl() && !loadingFirstReportPhotoFailed"
                class="first-photo-loading">
                <mat-spinner diameter="40" strokeWidth="2"></mat-spinner>
            </div>
            <!--********** First Photo Non-Existent **********-->
            <img
                src="/assets/images/icons/car-white_50.png"
                class="no-first-photo-exists"
                *ngIf="!getFirstReportPhoto()" />
            <!--********** Loading Photo Failed. **********-->
            <div
                *ngIf="loadingFirstReportPhotoFailed"
                class="first-photo-failed-to-load"
                matTooltip="Das erste Foto dieses Gutachtens konnte nicht geladen werden. Lösche es im Reiter 'Fotos' und lade es neu hoch.">
                <mat-icon>broken_image</mat-icon>
            </div>

            <!--********** Sync Issues? **********-->
            <div
                class="sync-issue-container"
                *ngIf="hasSyncIssues()"
                matTooltip="Dieser Datensatz konnte nicht zum Server synchronisiert werden. Für Details klicke auf den roten Button 'Sync-Probleme' oben rechts.">
                <mat-icon>sync_problem</mat-icon>
            </div>

            <!--********** Responsible Assessor Avatar **********-->
            <user-avatar
                *ngIf="
                    assessors.length > 1 &&
                    userPreferences.reportListResponsibleAssessorShown &&
                    report.responsibleAssessor
                "
                [size]="20"
                tooltipPrefix="Verantwortlicher: "
                [userId]="report.responsibleAssessor"
                class="responsible-assessor-avatar"></user-avatar>
        </div>
    </td>
</ng-template>

<ng-template #customerNameAndLabels>
    <td class="report-customer-name-and-labels-column" (click)="navigateToDetailsViewOnMobile()">
        <!-- Wrapper report-customer-name-and-labels-column is used to vertically align the content in this table cell -->
        <div class="report-customer-name-and-labels-container">
            <div class="report-customer-name-column">
                <div class="report-customer-organization" *ngIf="report.claimant.contactPerson.organization">
                    {{ report.claimant.contactPerson.organization }}
                </div>
                <div
                    [ngClass]="{ label: report.claimant.contactPerson.organization }"
                    *ngIf="
                        report.claimant.contactPerson.salutation ||
                        report.claimant.contactPerson.firstName ||
                        report.claimant.contactPerson.lastName
                    ">
                    {{ report.claimant.contactPerson.firstName }} {{ report.claimant.contactPerson.lastName }}
                </div>
                <div
                    class="missing-data-info label"
                    *ngIf="
                        !report.claimant.contactPerson.organization &&
                        !report.claimant.contactPerson.firstName &&
                        !report.claimant.contactPerson.lastName
                    ">
                    Kein Name
                </div>

                <!--********** Only used in mobile view **********-->
                <div class="report-token-in-name-container label" *ngIf="report.token">{{ report.token }}</div>
            </div>
            <!--********** Labels **********-->
            <div *ngIf="userPreferences.reportListLabelsShown && !isSmallScreen('small')" class="report-labels-column">
                <div
                    class="report-labels"
                    labelDropdown
                    [ngClass]="{ 'no-labels': !report.labels.length }"
                    [(selectedLabels)]="report.labels"
                    [labelGroup]="'report'"
                    (selectedLabelsChange)="emitChange()">
                    <label-display *ngIf="report.labels.length > 0" [labels]="report.labels"></label-display>
                    <span *ngIf="report.labels.length < 1" class="no-labels-button">+ Label</span>
                </div>
            </div>
        </div>
    </td>
</ng-template>

<ng-template #toolbar>
    <td class="report-record-toolbar">
        <div class="report-record-toolbar-container">
            <!--********** Is GDV Order **********-->
            <ng-container *ngIf="isGdvOrder()">
                <img
                    id="gdv-logo"
                    src="/assets/images/logos/gdv-logo.svg"
                    matTooltip="Dieses Gutachten wurde aus dem GDV-Netz erstellt." />
            </ng-container>

            <!--********** Remote signature status **********-->
            <ng-container *ngIf="getRemoteSignatureStatus() as signatureStatus">
                <mat-icon
                    *ngIf="['link-generated', 'deadline-passed', 'signatures-submitted'].includes(signatureStatus)"
                    class="report-record-toolbar-icon medium-icon remote-signature-status"
                    [matTooltip]="getRemoteSignatureStatusTooltip(signatureStatus)"
                    [ngClass]="{
                        'link-generated': signatureStatus === 'link-generated',
                        'deadline-passed': signatureStatus === 'deadline-passed',
                        'signatures-submitted': signatureStatus === 'signatures-submitted',
                    }"
                    (click)="navigateToRemoteSignatureDialog()">
                    signature
                </mat-icon>
            </ng-container>

            <!--********** Residual Value Offer Due **********-->
            <mat-icon
                class="report-record-toolbar-icon medium-icon icon-emphasized"
                *ngIf="areAnyResidualValueRequestsRunningOrReadyToBeImported()"
                [ngClass]="{ 'ready-to-be-imported': residualValueMayBeImported() }"
                (click)="navigateToDamageCalculation()"
                [matTooltip]="getResidualValueOffersTooltip()">
                gavel
            </mat-icon>

            <!--********** Repair Confirmation **********-->
            <img
                src="/assets/images/icons/repair-confirmation.svg"
                class="report-record-toolbar-icon medium-icon"
                *ngIf="report.repairConfirmation"
                (click)="navigateToRepairConfirmation()"
                alt=""
                matTooltip="Klicke, um die Reparaturbestätigung zu öffnen" />

            <!--********** Expert Statement **********-->
            <mat-icon
                class="report-record-toolbar-icon medium-icon"
                *ngIf="report.expertStatements?.length"
                (click)="navigateToExpertStatement()"
                matTooltip="Klicke, um die Stellungnahme zu öffnen">
                comment
            </mat-icon>

            <!--********** Is Amendment Report **********-->
            <mat-icon
                class="report-record-toolbar-icon medium-icon"
                *ngIf="report.originalReportId"
                (click)="navigateToOriginalReport()"
                matTooltip="Dies ist ein Nachtrag. Klicke, um das Originalgutachten zu öffnen.">
                note_add
            </mat-icon>

            <!--********** Is Original Report **********-->
            <mat-icon
                class="report-record-toolbar-icon medium-icon"
                *ngIf="report.amendmentReportId"
                (click)="navigateToAmendmentReport()"
                matTooltip="Dies ist das Originalgutachten. Klicke, um den Nachtrag zu öffnen.">
                file_present
            </mat-icon>

            <!--********** Notes **********-->
            <mat-icon
                class="report-record-toolbar-icon"
                *ngIf="report.notes?.length || notesIconManuallyShown"
                internal-notes-panel-anchor
                [notes]="report.notes"
                [ngClass]="{ important: hasImportantNote(report) }"
                matTooltip="Notizen"
                (change)="emitChange()"
                (click)="showNotesManually()"
                (panelClose)="hideIconForEmptyNotes()"
                [autoOpenWhenVisible]="notesIconManuallyShown">
                notes
            </mat-icon>

            <!--********** Report Type **********-->
            <report-type-chip [reportType]="report.type"></report-type-chip>

            <!--********** Restore From Trash **********-->
            <mat-icon
                class="restore-report-from-trash report-record-toolbar-icon"
                *ngIf="isReportDeleted()"
                matTooltip="Gutachten aus Papierkorb wiederherstellen"
                (click)="emitRestoreFromTrash()">
                restore_from_trash
            </mat-icon>
        </div>
    </td>
</ng-template>

<ng-template #submenu>
    <td class="report-submenu">
        <mat-icon
            class="report-submenu-option"
            [matMenuTriggerFor]="reportOptionsMenu"
            #reportOptionsMenuTrigger="matMenuTrigger">
            more_vert
        </mat-icon>

        <mat-menu #reportOptionsMenu="matMenu" xPosition="before">
            <!--********** Restore From Trash **********-->
            <a mat-menu-item class="menu-item-with-icon" *ngIf="isReportDeleted()" (click)="emitRestoreFromTrash()">
                <mat-icon>restore</mat-icon>
                Wiederherstellen
            </a>

            <!--********** Edit **********-->
            <a mat-menu-item *ngIf="!isReportLocked()" class="menu-item-with-icon" (click)="navigateToReport()">
                <mat-icon>edit</mat-icon>
                Bearbeiten

                <!--********** Open in New Tab **********-->
                <mat-icon
                    class="toolbar-icon"
                    (click)="openReportInNewTab($event, reportOptionsMenuTrigger)"
                    matTooltip="In neuem Tab öffnen.{{
                        '\n\n'
                    }}Auch möglich, indem du mit dem Mausrad auf eine Gutachten-Zeile klickst.">
                    open_in_new
                </mat-icon>
            </a>

            <!--********** Open **********-->
            <a mat-menu-item *ngIf="isReportLocked()" class="menu-item-with-icon" [routerLink]="[report._id]">
                <mat-icon>visibility</mat-icon>
                Öffnen
            </a>

            <!--********** Notes **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="!notesIconManuallyShown && !report.notes?.length"
                (click)="showNotesManually()">
                <mat-icon>notes</mat-icon>
                Notizen
            </a>

            <!--********** Copy Report **********-->
            <a mat-menu-item class="menu-item-with-icon" *ngIf="!isReportDeleted()" (click)="openCopyReportDialog()">
                <mat-icon>flip_to_front</mat-icon>
                Kopieren
            </a>

            <!--********** Amendment **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="!isReportDeleted()"
                (click)="openAmendmentReportDialog()"
                matTooltip="Das Gutachten und die Kalkulation werden kopiert.{{
                    '\n\n'
                }}Ein Nachtrag ist dann erforderlich, wenn sich der Umfang der Kalkulation nach Einreichung des Gutachtens bei der Versicherung verändert hat.">
                <mat-icon>note_add</mat-icon>
                Nachtrag
            </a>

            <!--********** Delete **********-->
            <a mat-menu-item class="menu-item-with-icon" *ngIf="!isReportDeleted()" (click)="emitMoveToTrash()">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>

            <!--********** Permanently Delete **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="isReportDeleted()"
                (click)="emitDeleteReportPermanently()">
                <mat-icon>delete</mat-icon>
                Endgültig löschen
            </a>
        </mat-menu>
    </td>
</ng-template>
<!--============================================-->
<!-- END Templates used in different places     -->
<!--============================================-->

import { animate, style, transition, trigger } from '@angular/animations';

export function shrinkOut(leaveDuration = 200) {
    return trigger('shrinkOut', [
        transition(':leave', [
            animate(
                `${leaveDuration}ms ease`,
                style({
                    opacity: 0,
                    transform: 'scale(.4)',
                }),
            ),
        ]),
    ]);
}

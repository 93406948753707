// aX imports
import { DragDropModule } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {
    MAT_LEGACY_CHIPS_DEFAULT_OPTIONS as MAT_CHIPS_DEFAULT_OPTIONS,
    MatLegacyChipsModule as MatChipsModule,
} from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import {
    MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatLegacyTooltipModule as MatTooltipModule,
} from '@angular/material/legacy-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { AbstractApiErrorService } from '@autoixpert/abstract-services/api-error.abstract.service';
import { AbstractClaimantSignatureFileService } from '@autoixpert/abstract-services/claimant-signature-file.abstract.service';
import { AbstractFieldGroupConfigService } from '@autoixpert/abstract-services/field-group-config.abstract.service';
import { AbstractReportService } from '@autoixpert/abstract-services/report.abstract.service';
import { AbstractSignablePdfTemplateConfigService } from '@autoixpert/abstract-services/signable-pdf-template-config.abstract.service';
import { AbstractSignablePdfTemplateImageService } from '@autoixpert/abstract-services/signable-pdf-template-image.abstract.service';
import { AbstractTemplatePlaceholderValuesService } from '@autoixpert/abstract-services/template-placeholder-values.abstract.service';
import { AbstractToastService } from '@autoixpert/abstract-services/toast.abstract.service';
import { AutogrowTextareaComponent } from '@autoixpert/components/autogrow-textarea/autogrow-textarea.component';
import { AutogrowTextareaModule } from '@autoixpert/components/autogrow-textarea/autogrow-textarea.module';
import { ClaimantSignatureModule } from '@autoixpert/components/claimant-signature/claimant-signature.module';
import { LicensePlateModule } from '@autoixpert/components/license-plate/license-plate.module';
import { PlaceholderAutocompleteInputModule } from '@autoixpert/components/placeholder-autocomplete-input/placeholder-autocomplete-input.module';
import { SignablePdfTemplateEditorModule } from '@autoixpert/components/signable-pdf-template-editor/signable-pdf-template-editor.module';
import { SharedDirectivesModule } from '@autoixpert/directives/shared-directives.module';
import { SharedPipesModule } from '@autoixpert/pipes/shared-pipes.module';
import { InvoiceTemplateTitleDialogComponent } from '../invoices/invoice-editor/invoice-template-title-dialog/invoice-template-title-dialog.component';
import { PaymentStatusComponent } from '../invoices/payment-status/payment-status.component';
import { DocumentTypesDialogComponent } from '../preferences/document-types-dialog/document-types-dialog.component';
import { MessageTemplateSelector } from '../reports/details/print-and-transmission/message-template-selector/message-template-selector.component';
import { PreferencesRowComponent } from '../reports/details/shared/preferences-row/preferences-row.component';
import { TaskDetailsCommentsComponent } from '../tasks/task-details-comments/task-details-comments.component';
import { TaskDetailsSubtaskRowComponent } from '../tasks/task-details-subtasks/task-details-subtask-row/task-details-subtask-row.component';
import { TaskDetailsSubtasksComponent } from '../tasks/task-details-subtasks/task-details-subtasks.component';
import { TaskDetailsComponent } from '../tasks/task-details/task-details.component';
import { AddressAutocompletionComponent } from './components/address-autocompletion/address-autocompletion.component';
import { AssessorPickerComponent } from './components/assessor-picker/assessor-picker.component';
import { AssociatedInvoiceListItemComponent } from './components/associated-invoices-list-item/associated-invoice-list-item.component';
import { AudatexWageRateTranslationNoteComponent } from './components/audatex-wage-rate-translation-note/audatex-wage-rate-translation-note.component';
import { AugmentedDataIndicatorComponent } from './components/augmented-data-indicator/augmented-data-indicator.component';
import { AutocompleteBubbleListComponent } from './components/autocomplete-bubble-list/autocomplete-bubble-list.component';
import { AutocompleteWithMemory } from './components/autocomplete-with-memory/autocomplete-with-memory.component';
import { ColorPickerAnchorDirective } from './components/color-picker/color-picker-anchor.directive';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ContactPeopleManager } from './components/contact-people-manager/contact-people-manager.component';
import { ContactPersonEditorComponent } from './components/contact-person-editor/contact-person-editor.component';
import { ContactPersonFormComponent } from './components/contact-person-form/contact-person-form.component';
import { ContextSensitiveDowntimeNotificationComponent } from './components/context-sensitive-downtime-notification/context-sensitive-downtime-notification.component';
import { DowntimeNotificationOverlayComponent } from './components/context-sensitive-downtime-notification/downtime-notification-overlay/downtime-notification-overlay.component';
import { CollectiveInvoiceReportListRowComponent } from './components/create-collective-invoice-dialog/collective-invoice-report-list-row/collective-invoice-report-list-row.component';
import { CreateCollectiveInvoiceDialogComponent } from './components/create-collective-invoice-dialog/create-collective-invoice-dialog.component';
import { CurrencyInputDisplayValueComponent } from './components/currency-input/currency-input-display-value/currency-input-display-value.component';
import { CurrencyInputPrefixComponent } from './components/currency-input/currency-input-prefix/currency-input-prefix.component';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DatePickerWithInputAnchorDirective } from './components/date-picker-with-input-overlay/date-picker-with-input-anchor.directive';
import { DatePickerWithInputOverlayComponent } from './components/date-picker-with-input-overlay/date-picker-with-input-overlay.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DescriptionFromRepairCalculationDialogComponent } from './components/description-from-repair-calculation-dialog/description-from-repair-calculation-dialog.component';
import { DisplayInternalIdComponent } from './components/display-internal-id/display-internal-id.component';
import { DocumentBuildingBlockConditionGroupEditorComponent } from './components/document-building-block-condition-group-editor/document-building-block-condition-group-editor.component';
import { DocumentBuildingBlockConditionComponent } from './components/document-building-block-condition/document-building-block-condition.component';
import { DocumentTypeTagListComponent } from './components/document-type-tag-list/document-type-tag-list.component';
import { EditReportInvoiceNumberCounterDialogComponent } from './components/edit-report-invoice-number-counter-dialog/edit-report-invoice-number-counter-dialog.component';
import { EditReportInvoiceNumberCounterOverlayAnchorDirective } from './components/edit-report-invoice-number-counter-dialog/edit-report-invoice-number-counter-overlay-anchor.directive';
import { ElectronicInvoiceToggleComponent } from './components/electronic-invoice-toggle/electronic-invoice-toggle.component';
import { EmailEditorComponent } from './components/email-editor/email-editor.component';
import { FileNamePatternEditorComponent } from './components/file-name-pattern-editor/file-name-pattern-editor.component';
import { AnalyticsFilterButtonComponent } from './components/filter/analytics-filter-button/analytics-filter-button.component';
import { AnalyticsFilterComponent } from './components/filter/analytics-filter/analytics-filter.component';
import { FormFieldOverlayAnchorDirective } from './components/form-field-overlay/form-field-overlay-anchor.directive';
import { FormFieldOverlayComponent } from './components/form-field-overlay/form-field-overlay.component';
import { GarageBrandsComponent } from './components/garage-brands/garage-brands.component';
import { GarageFeesComponent } from './components/garage-fees/garage-fees.component';
import { GoogleMapsPredictionComponent } from './components/google-maps-prediction/google-maps-prediction.component';
import { GtueInvoiceImportDialogComponent } from './components/gtue-invoice-import-dialog/gtue-invoice-import-dialog.component';
import { HtmlIntrusionDialogComponent } from './components/html-intrusion-dialog/html-intrusion-dialog.component';
import { InternalNoteComponent } from './components/internal-note/internal-note.component';
import { InternalNotesPanelAnchorDirective } from './components/internal-notes-panel/internal-notes-panel-anchor.directive';
import { InternalNotesPanelComponent } from './components/internal-notes-panel/internal-notes-panel.component';
import { InvoiceLineItemTemplateSelector } from './components/invoice-line-item-template-selector/invoice-line-item-template-selector.component';
import { InvoiceListOverlayAnchorDirective } from './components/invoice-list-overlay/invoice-list-overlay-anchor.directive';
import { InvoiceListOverlayComponent } from './components/invoice-list-overlay/invoice-list-overlay.component';
import { InvolvedPartyContactDetailsComponent } from './components/involved-party-contact-details/involved-party-contact-details.component';
import { LabelDisplayComponent } from './components/label-display/label-display.component';
import { LabelDropdownAnchorDirective } from './components/label-dropdown/label-dropdown-anchor.directive';
import { LabelDropdownOverlayComponent } from './components/label-dropdown/label-dropdown-overlay.component';
import { LabelManagerAnchorDirective } from './components/label-manager/label-manager-anchor.directive';
import { LabelManagerComponent } from './components/label-manager/label-manager.component';
import { MatQuillComponent } from './components/mat-quill/mat-quill.component';
import { MonthInputComponent } from './components/month-input/month-input.component';
import { NetAndGrossCurrencyInputComponent } from './components/net-and-gross-currency-input/net-and-gross-currency-input.component';
import { OfficeLocationListComponent } from './components/office-location-list/office-location-list.component';
import { OfficeLocationsPickerComponent } from './components/office-locations-picker/office-locations-picker.component';
import { OutgoingMessageDialog } from './components/outgoing-message-dialog/outgoing-message-dialog.component';
import { OutgoingMessageComponent } from './components/outgoing-message/outgoing-message.component';
import { OutgoingMessagesListComponent } from './components/outgoing-messages-list/outgoing-messages-list.component';
import { PlaceholderAutocompleteQuill } from './components/placeholder-autocomplete-quill/placeholder-autocomplete-quill.component';
import { RecipientSelectorComponent } from './components/recipient-selector/recipient-selector.component';
import { RecipientsColumnComponent } from './components/recipients-column/recipients-column.component';
import { RecordTemplateSelectorComponent } from './components/record-template-selector/record-template-selector.component';
import { LabelPickerComponent } from './components/report-label-picker/label-picker.component';
import { ReportListOverlayAnchorDirective } from './components/report-list-overlay/report-list-overlay-anchor.directive';
import { ReportListOverlayComponent } from './components/report-list-overlay/report-list-overlay.component';
import { ReportTypeChipComponent } from './components/report-type-chip/report-type-chip.component';
import { ReportTypeDialogAnchor } from './components/report-type-dialog/report-type-dialog-anchor';
import { ReportTypeDialogComponent } from './components/report-type-dialog/report-type-dialog.component';
import { ReportTypesDialogComponent } from './components/report-types-dialog/report-types-dialog.component';
import { ScheduleEmailDialogComponent } from './components/schedule-email-dialog/schedule-email-dialog.component';
import { SelectInvoiceDropdownComponent } from './components/select-invoice-dropdown/select-invoice-dropdown.component';
import { SelectReportDropdownComponent } from './components/select-report-dropdown/select-report-dropdown.component';
import { SelectedReportTypesComponent } from './components/selected-report-types/selected-report-types.component';
import { LinkTaskToRecordAnchor } from './components/tasks/link-task-to-record/link-task-to-record-input-anchor.directive';
import { LinkTaskToRecordOverlayComponent } from './components/tasks/link-task-to-record/link-task-to-record-overlay.component';
import { TaskAssociatedInvoiceOrReportComponent } from './components/tasks/task-associated-invoice-or-report/task-associated-invoice-or-report.component';
import { TaskDetailsDialogComponent } from './components/tasks/task-details-dialog/task-details-dialog.component';
import { TaskDoneCheckboxComponent } from './components/tasks/task-done-checkbox/task-done-checkbox.component';
import { TaskEstimatedDurationComponent } from './components/tasks/task-estimated-duration/task-estimated-duration.component';
import { TaskListInlineComponent } from './components/tasks/task-list-inline/task-list-inline.component';
import { TaskTitleComponent } from './components/tasks/task-title/task-title.component';
import { TasksListOverlayAnchorDirective } from './components/tasks/tasks-panel/tasks-list-overlay-anchor.directive';
import { TasksListOverlayComponent } from './components/tasks/tasks-panel/tasks-list-overlay.component';
import { TaxationTypeSelectorComponent } from './components/taxation-type-selector/taxation-type-selector.component';
import { TextTemplateSelectorComponent } from './components/text-template-selector/text-template-selector.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { TriStateCheckboxComponent } from './components/tri-state-checkbox/tri-state-checkbox.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { UserSelectionDropdownComponent } from './components/user-selection-dropdown/user-selection-dropdown.component';
import { UserSelectorInputAnchorDirective } from './components/user-selector-list/user-selector-input-anchor.directive';
import { UserSelectorListComponent } from './components/user-selector-list/user-selector-list.component';
import { VehicleValueSettingsCardComponent } from './components/vehicle-value-settings-card/vehicle-value-settings-card.component';
import { VideoPlayerDialogComponent } from './components/video-player-dialog/video-player-dialog.component';
import { YearInputComponent } from './components/year-input/year-input.component';
import { AugmentedDataIndicatorDirective } from './directives/augmented-data-indicator.directive';
import { BicInputDirective } from './directives/bic-input.directive';
import { ClickMyselfOnInitDirective } from './directives/click-myself-on-init.directive';
import { DragDropFileUploadDirective } from './directives/drag-drop-file-upload.directive';
import { EmailAutocompleteDirective } from './directives/email-autocomplete.directive';
import { IbanInputDirective } from './directives/iban-input.directive';
import { InitNotifierDirective } from './directives/init-notifier.directive';
import { MatQuillAutocompleteTrigger } from './directives/mat-quill-autocomplete';
import { MatSelectRemoveAriaOwnsDirective } from './directives/mat-select-remove-aria-owns.directive';
import { MuuriGridItemDirective } from './directives/muuri/muuri-grid-item.directive';
import { MuuriGridDirective } from './directives/muuri/muuri-grid.directive';
import { TotalPriceReadonlyHintDirective } from './directives/total-price-readonly-hint.directive';
import { ZipCityInputDirective } from './directives/zip-city-input.directive';
import { ApiErrorService } from './services/api-error.service';
import { BicService } from './services/bic.service';
import { ClaimantSignatureFileService } from './services/claimant-signature-file.service';
import { DistanceService } from './services/distance.service';
import { EmailSignatureService } from './services/emailSignature.service';
import { EmailSignatureImageService } from './services/emailSignatureImage.service';
import { FieldGroupConfigService } from './services/field-group-config.service';
import { FileNamePatternService } from './services/file-name-pattern.service';
import { GtueImportHistoryService } from './services/gtue-import-history.service';
import { ImportHistoryService } from './services/import-history.service';
import { LabelConfigService } from './services/label-config.service';
import { OutgoingMessageService } from './services/outgoing-message.service';
import { PhotoMuuriGridService } from './services/photo-muuri-grid.service';
import { ReportService } from './services/report.service';
import { SignablePdfTemplateConfigService } from './services/signable-pdf-template-config.service';
import { SignablePdfTemplateImageService } from './services/signable-pdf-template-image.service';
import { TaskService } from './services/task.service';
import { TemplatePlaceholderValuesService } from './services/template-placeholder-values.service';
import { ToastService } from './services/toast.service';

@NgModule({
    imports: [
        AutogrowTextareaModule,
        BrowserAnimationsModule,
        CdkTableModule,
        CdkTableModule,
        CommonModule,
        CommonModule,
        DragDropModule,
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatLegacyProgressSpinnerModule,
        MatMenuModule,
        MatMomentDateModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatTooltipModule,
        RouterModule,
        ScrollingModule,
        MatLegacyFormFieldModule,
        MatChipsModule,
        SharedDirectivesModule,
        SharedPipesModule,
        ClaimantSignatureModule,
        SignablePdfTemplateEditorModule,
        LicensePlateModule,
        PlaceholderAutocompleteInputModule,
        FormsModule,
    ],
    declarations: [
        AnalyticsFilterComponent,
        AnalyticsFilterButtonComponent,
        AssessorPickerComponent,
        AssociatedInvoiceListItemComponent,
        AudatexWageRateTranslationNoteComponent,
        AugmentedDataIndicatorComponent,
        AugmentedDataIndicatorComponent,
        AugmentedDataIndicatorDirective,
        AugmentedDataIndicatorDirective,
        AutocompleteBubbleListComponent,
        AutocompleteBubbleListComponent,
        AutocompleteWithMemory,
        BicInputDirective,
        ClickMyselfOnInitDirective,
        ColorPickerAnchorDirective,
        ColorPickerComponent,
        DocumentBuildingBlockConditionComponent,
        DocumentBuildingBlockConditionGroupEditorComponent,
        ContactPeopleManager,
        ContactPersonEditorComponent,
        ContactPersonFormComponent,
        CreateCollectiveInvoiceDialogComponent,
        CurrencyInputComponent,
        CurrencyInputDisplayValueComponent,
        CurrencyInputPrefixComponent,
        DateInputComponent,
        DatePickerWithInputAnchorDirective,
        DatePickerWithInputOverlayComponent,
        DateRangePickerComponent,
        DescriptionFromRepairCalculationDialogComponent,
        DocumentTypeTagListComponent,
        DocumentTypesDialogComponent,
        DragDropFileUploadDirective,
        ElectronicInvoiceToggleComponent,
        EmailAutocompleteDirective,
        EmailAutocompleteDirective,
        EmailEditorComponent,
        ScheduleEmailDialogComponent,
        FileNamePatternEditorComponent,
        FormFieldOverlayAnchorDirective,
        FormFieldOverlayComponent,
        GarageBrandsComponent,
        GarageFeesComponent,
        GtueInvoiceImportDialogComponent,
        HtmlIntrusionDialogComponent,
        IbanInputDirective,
        InitNotifierDirective,
        InternalNotesPanelAnchorDirective,
        InternalNotesPanelComponent,
        InvoiceLineItemTemplateSelector,
        InvoiceListOverlayAnchorDirective,
        InvoiceListOverlayComponent,
        InvoiceTemplateTitleDialogComponent,
        InvolvedPartyContactDetailsComponent,
        LabelDisplayComponent,
        LabelDropdownAnchorDirective,
        LabelDropdownOverlayComponent,
        LabelManagerAnchorDirective,
        LabelManagerComponent,
        MatQuillAutocompleteTrigger,
        MatQuillComponent,
        MatSelectRemoveAriaOwnsDirective,
        MessageTemplateSelector,
        MonthInputComponent,
        MuuriGridDirective,
        MuuriGridItemDirective,
        OfficeLocationListComponent,
        OfficeLocationsPickerComponent,
        PaymentStatusComponent,
        PlaceholderAutocompleteQuill,
        PreferencesRowComponent,
        RecipientSelectorComponent,
        RecipientsColumnComponent,
        RecordTemplateSelectorComponent,
        LabelPickerComponent,
        ReportListOverlayAnchorDirective,
        ReportListOverlayComponent,
        ReportListOverlayComponent,
        ReportTypeDialogAnchor,
        ReportTypeDialogAnchor,
        ReportTypeDialogComponent,
        ReportTypesDialogComponent,
        SelectInvoiceDropdownComponent,
        SelectReportDropdownComponent,
        SelectedReportTypesComponent,
        SelectedReportTypesComponent,
        OutgoingMessageDialog,
        TaskDetailsComponent,
        TaskDetailsCommentsComponent,
        TaskDetailsSubtasksComponent,
        TaskDetailsSubtaskRowComponent,
        LinkTaskToRecordAnchor,
        LinkTaskToRecordOverlayComponent,
        TaskAssociatedInvoiceOrReportComponent,
        TaskDoneCheckboxComponent,
        TaskEstimatedDurationComponent,
        TaskTitleComponent,
        TasksListOverlayAnchorDirective,
        TasksListOverlayComponent,
        TextTemplateSelectorComponent,
        TimeInputComponent,
        TotalPriceReadonlyHintDirective,
        TriStateCheckboxComponent,
        UserAvatarComponent,
        UserSelectionDropdownComponent,
        VideoPlayerDialogComponent,
        YearInputComponent,
        ZipCityInputDirective,
        EmailAutocompleteDirective,
        AutocompleteBubbleListComponent,
        InvolvedPartyContactDetailsComponent,
        LabelDisplayComponent,
        LabelManagerComponent,
        LabelManagerAnchorDirective,
        DatePickerWithInputOverlayComponent,
        DatePickerWithInputAnchorDirective,
        AugmentedDataIndicatorComponent,
        AugmentedDataIndicatorDirective,
        UserSelectionDropdownComponent,
        AudatexWageRateTranslationNoteComponent,
        MatSelectRemoveAriaOwnsDirective,
        UserAvatarComponent,
        UserSelectorListComponent,
        UserSelectorInputAnchorDirective,
        InternalNoteComponent,
        TaskListInlineComponent,
        TaskDetailsDialogComponent,
        DisplayInternalIdComponent,
        EditReportInvoiceNumberCounterDialogComponent,
        EditReportInvoiceNumberCounterOverlayAnchorDirective,
        GoogleMapsPredictionComponent,
        AddressAutocompletionComponent,
        VehicleValueSettingsCardComponent,
        NetAndGrossCurrencyInputComponent,
        TaxationTypeSelectorComponent,
        ContextSensitiveDowntimeNotificationComponent,
        DowntimeNotificationOverlayComponent,
        OutgoingMessageComponent,
        OutgoingMessagesListComponent,
        CollectiveInvoiceReportListRowComponent,
        ReportTypeChipComponent,
    ],
    exports: [
        AnalyticsFilterComponent,
        AnalyticsFilterButtonComponent,
        AssessorPickerComponent,
        AssociatedInvoiceListItemComponent,
        AudatexWageRateTranslationNoteComponent,
        AugmentedDataIndicatorDirective,
        AugmentedDataIndicatorDirective,
        AutocompleteWithMemory,
        AutogrowTextareaComponent,
        BicInputDirective,
        BrowserAnimationsModule,
        CdkTableModule,
        ClickMyselfOnInitDirective,
        ColorPickerAnchorDirective,
        CommonModule,
        ContextSensitiveDowntimeNotificationComponent,
        DocumentBuildingBlockConditionComponent,
        DocumentBuildingBlockConditionGroupEditorComponent,
        ContactPeopleManager,
        ContactPersonEditorComponent,
        ContactPersonFormComponent,
        CreateCollectiveInvoiceDialogComponent,
        CurrencyInputComponent,
        CurrencyInputDisplayValueComponent,
        CurrencyInputPrefixComponent,
        DateInputComponent,
        DatePickerWithInputAnchorDirective,
        DateRangePickerComponent,
        DisplayInternalIdComponent,
        DocumentTypeTagListComponent,
        DocumentTypesDialogComponent,
        DragDropFileUploadDirective,
        DragDropModule,
        ElectronicInvoiceToggleComponent,
        EmailAutocompleteDirective,
        EmailEditorComponent,
        FileNamePatternEditorComponent,
        FileUploadModule,
        FormFieldOverlayAnchorDirective,
        FormFieldOverlayComponent,
        FormsModule,
        GarageBrandsComponent,
        GarageFeesComponent,
        GtueInvoiceImportDialogComponent,
        IbanInputDirective,
        InitNotifierDirective,
        InternalNotesPanelAnchorDirective,
        InternalNotesPanelComponent,
        InvoiceLineItemTemplateSelector,
        InvoiceListOverlayAnchorDirective,
        InvoiceListOverlayComponent,
        InvoiceTemplateTitleDialogComponent,
        InvolvedPartyContactDetailsComponent,
        LabelDisplayComponent,
        LabelDropdownAnchorDirective,
        LabelDropdownOverlayComponent,
        LicensePlateModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatMomentDateModule,
        MatQuillAutocompleteTrigger,
        MatQuillComponent,
        MatRadioModule,
        MatSelectModule,
        MatSelectRemoveAriaOwnsDirective,
        MatSlideToggleModule,
        MatSliderModule,
        MatTooltipModule,
        MessageTemplateSelector,
        MonthInputComponent,
        MuuriGridDirective,
        MuuriGridItemDirective,
        OfficeLocationListComponent,
        OfficeLocationsPickerComponent,
        PaymentStatusComponent,
        PlaceholderAutocompleteInputModule,
        PlaceholderAutocompleteQuill,
        PreferencesRowComponent,
        RecipientSelectorComponent,
        RecipientsColumnComponent,
        RecordTemplateSelectorComponent,
        LabelPickerComponent,
        ReportListOverlayAnchorDirective,
        ReportListOverlayComponent,
        ReportTypeDialogAnchor,
        ReportTypeDialogComponent,
        ReportTypesDialogComponent,
        ScrollingModule,
        SelectInvoiceDropdownComponent,
        SelectReportDropdownComponent,
        SelectedReportTypesComponent,
        OutgoingMessageDialog,
        SharedDirectivesModule,
        SharedPipesModule,
        SignablePdfTemplateEditorModule,
        TaskDetailsComponent,
        TaskDetailsCommentsComponent,
        TaskDetailsSubtasksComponent,
        TaskDetailsSubtaskRowComponent,
        LinkTaskToRecordAnchor,
        LinkTaskToRecordOverlayComponent,
        TaskAssociatedInvoiceOrReportComponent,
        TaskDoneCheckboxComponent,
        TaskEstimatedDurationComponent,
        TaskTitleComponent,
        TasksListOverlayAnchorDirective,
        TasksListOverlayComponent,
        TextTemplateSelectorComponent,
        TimeInputComponent,
        TotalPriceReadonlyHintDirective,
        TriStateCheckboxComponent,
        UserAvatarComponent,
        UserSelectionDropdownComponent,
        VideoPlayerDialogComponent,
        YearInputComponent,
        ZipCityInputDirective,
        InvolvedPartyContactDetailsComponent,
        LabelDisplayComponent,
        DatePickerWithInputAnchorDirective,
        EmailAutocompleteDirective,
        AugmentedDataIndicatorDirective,
        UserSelectionDropdownComponent,
        AudatexWageRateTranslationNoteComponent,
        SignablePdfTemplateEditorModule,
        LicensePlateModule,
        PlaceholderAutocompleteInputModule,
        UserAvatarComponent,
        UserSelectorInputAnchorDirective,
        InternalNoteComponent,
        TaskListInlineComponent,
        EditReportInvoiceNumberCounterOverlayAnchorDirective,
        GoogleMapsPredictionComponent,
        AddressAutocompletionComponent,
        VehicleValueSettingsCardComponent,
        NetAndGrossCurrencyInputComponent,
        TaxationTypeSelectorComponent,
        OutgoingMessagesListComponent,
        ReportTypeChipComponent,
    ],
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                /**
                 * Prevent the tooltip text to be selectable with the mouse pointer. This way, it does not stand in the way when the user moves the mouse to click a button currently covered by the tooltip.
                 * Details: https://github.com/angular/components/issues/25173
                 */
                disableTooltipInteractivity: true,
                showDelay: 600,
            },
        },
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA],
            },
        },
        ApiErrorService,
        BicService,
        DecimalPipe,
        DistanceService,
        EmailSignatureImageService,
        EmailSignatureService,
        FileNamePatternService,
        ImportHistoryService,
        LabelConfigService,
        GtueImportHistoryService,
        PhotoMuuriGridService,
        TaskService,
        ToastService,
        OutgoingMessageService,
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-de',
        },
        {
            provide: AbstractClaimantSignatureFileService,
            useClass: ClaimantSignatureFileService,
        },
        {
            provide: AbstractToastService,
            useClass: ToastService,
        },
        {
            provide: AbstractApiErrorService,
            useClass: ApiErrorService,
        },
        {
            provide: AbstractSignablePdfTemplateConfigService,
            useClass: SignablePdfTemplateConfigService,
        },
        {
            provide: AbstractSignablePdfTemplateImageService,
            useClass: SignablePdfTemplateImageService,
        },
        {
            provide: AbstractReportService,
            useClass: ReportService,
        },
        {
            provide: AbstractFieldGroupConfigService,
            useClass: FieldGroupConfigService,
        },

        {
            provide: AbstractTemplatePlaceholderValuesService,
            useClass: TemplatePlaceholderValuesService,
        },
    ],
})
export class SharedModule {}

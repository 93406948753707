import { animate, query, style, transition, trigger } from '@angular/animations';

export function slideOutDialogVertical(leaveDuration = 200) {
    return trigger('slideOutDialogVertical', [
        transition(':leave', [
            query(
                '.dialog-container',
                animate(
                    `${leaveDuration}ms ease`,
                    style({
                        opacity: 0,
                        transform: 'translateY(50px)',
                    }),
                ),
                // Handle components that comprise both the anchor and the dialog. The dialog is often hidden with *ngIf, which would throw an error if the animation didn't have the optional flag.
                { optional: true },
            ),
            query(
                '.dialog-overlay',
                animate(
                    `${leaveDuration}ms ease`,
                    style({
                        opacity: 0,
                    }),
                ),
                // Handle components that comprise both the anchor and the dialog. The dialog is often hidden with *ngIf, which would throw an error if the animation didn't have the optional flag.
                { optional: true },
            ),
        ]),
    ]);
}

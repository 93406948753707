<!-- Labels picker -->
<div
    class="label-picker"
    *ngIf="labelConfigs.length > 1 || labelConfigs.length || showWithSingleLabel"
    [ngClass]="{ active: labelConfigIds.length > 0 }">
    <mat-icon class="label-picker-icon">sell</mat-icon>
    <div class="ax-header-select" [ngClass]="{ active: labelConfigIds.length > 0 }" [matMenuTriggerFor]="LabelsMenu">
        <!-- Label -->
        <div class="ax-header-select-label">
            <!-- No label picked placeholder -->
            <span *ngIf="labelConfigIds.length === 0">{{ labelGroup === 'report' ? 'GA' : 'RE' }}-Label</span>

            <!-- Single label picked -->
            <span *ngIf="labelConfigIds.length === 1">
                {{ getLabelTitleById(labelConfigIds[0]) }}
            </span>

            <!-- Multiple labels picked -->
            <span *ngIf="labelConfigIds.length > 1">{{ labelConfigIds.length }} Labels</span>
        </div>

        <!-- Dropdown icon -->
        <mat-icon>arrow_drop_down</mat-icon>
    </div>
</div>

<!-- Label picker overlay -->
<mat-menu #LabelsMenu class="label-menu">
    <!--********** Clear **********-->
    <a mat-menu-item (click)="clearLabelConfigs()">
        <div class="clear-menu-item">Kein Labelfilter</div>
    </a>

    <!--********** Labels **********-->
    <ng-container *ngFor="let labelConfig of labelConfigs; trackBy: trackById">
        <a mat-menu-item class="menu-item-with-icon" (click)="toggleLabelConfigId(labelConfig._id)">
            <!--********** Label **********-->
            <div class="label-container flex-grow">
                <div class="label-item">
                    <!--********** Label Color **********-->
                    <!--suppress CssUnknownProperty -->
                    <div class="label-color" [style.--labelColor]="labelConfig.color"></div>

                    <!--********** Label Name **********-->
                    <div class="label-title" [matTooltip]="labelConfig.name">
                        {{ labelConfig.name }}
                    </div>
                </div>
            </div>

            <!--********** Multiselect **********-->
            <mat-checkbox
                [checked]="labelConfigIds.includes(labelConfig._id)"
                (change)="toggleLabelConfigId(labelConfig._id)"
                (click)="$event.stopPropagation()"
                matTooltip="Zeige die Gutachten mit diesem Label an."></mat-checkbox>
        </a>

        <!-- No label placeholder -->
        <div *ngIf="labelConfigs.length === 0" class="no-label-placeholder">Keine Labels vorhanden.</div>
    </ng-container>

    <div *ngIf="showCollectiveInvoiceRestrictionsNote && labelConfigs?.length" class="mat-menu-item-note">
        Sammelrechnungen
        <br />
        nicht unterstützt
        <span
            class="help-indicator"
            matTooltip="Es kann nicht nach Labeln von Gutachten gefiltert werden, die in einer Sammelrechnung abgerechnet wurden.">
            ?
        </span>
    </div>
</mat-menu>

<!--============================================-->
<!-- Setup Steps -->
<!--============================================-->
<setup-steps
    *ngIf="team?.userInterfaceStates?.setupSteps && !team.userInterfaceStates.setupSteps.closed"
    @slideOutVertical></setup-steps>
<!--============================================-->
<!-- END Setup Steps -->
<!--============================================-->

<!--============================================-->
<!-- Reports -->
<!--============================================-->
<section id="report-section">
    <div id="reports-container">
        <!--********** Message About Trash View **********-->
        <div id="trash-view-message-container" class="card" *ngIf="showTrash">
            <div id="trash-view-message">
                Gutachten werden 30 Tage ab Löschdatum im Papierkorb aufbewahrt, bis sie endgültig gelöscht werden.
                <button (click)="toggleTrashView()">Papierkorb schließen</button>
            </div>
        </div>
        <!--********** END Message About Trash View **********-->

        <!-------------- Offene Aufträge ------------------------>
        <div id="reports-unfinished-container" class="reports-list-container card">
            <!-------------- Offene Aufträge - Kopfleiste ------------------------>
            <div id="reports-head-runner">
                <div id="sort-and-filter-container">
                    <!--============================================-->
                    <!-- Sorting -->
                    <!--============================================-->
                    <div id="sort-select-and-direction-container">
                        <div
                            id="sort-reports-by-container"
                            class="ax-header-select"
                            matTooltip="Sortierung"
                            [matMenuTriggerFor]="sortMenu"
                            [ngSwitch]="userPreferences.sortReportListBy">
                            <span *ngSwitchCase="'name'">Nach Name</span>
                            <span *ngSwitchCase="'licensePlate'">Nach Kennzeichen</span>
                            <span *ngSwitchCase="'token'">Nach Aktenzeichen</span>
                            <span *ngSwitchCase="'carBrand'">Nach Marke & Modell</span>
                            <span *ngSwitchCase="'automaticDate'">Nach Datum</span>
                            <span *ngSwitchCase="'createdAt'">Nach Erstellungsdatum</span>
                            <span *ngSwitchCase="'updatedAt'">Nach Änderungsdatum</span>
                            <span *ngSwitchCase="'orderDate'">Nach Auftragsdatum</span>
                            <span *ngSwitchCase="'accidentDate'">Nach Unfalldatum</span>
                            <span *ngSwitchCase="'firstVisitDate'">Nach Besichtigungsdatum</span>
                            <div class="ax-header-select-arrow"></div>
                        </div>
                        <mat-menu #sortMenu="matMenu">
                            <a
                                mat-menu-item
                                (click)="selectSortStrategy('name')"
                                matTooltip="Nach Organisation, Vorname, Nachname">
                                Nach Name
                            </a>
                            <a mat-menu-item (click)="selectSortStrategy('licensePlate')">Nach Kennzeichen</a>
                            <a mat-menu-item (click)="selectSortStrategy('token')">Nach Aktenzeichen</a>
                            <a mat-menu-item (click)="selectSortStrategy('carBrand')">Nach Marke & Modell</a>
                            <a mat-menu-item [matMenuTriggerFor]="dateMenu">Nach Datum</a>
                        </mat-menu>
                        <mat-menu #dateMenu="matMenu">
                            <a mat-menu-item (click)="selectSortStrategy('automaticDate')">
                                Automatisch
                                <span
                                    class="help-indicator"
                                    matTooltip="Offene Gutachten werden nach Erstellungsdatum, abgeschlossene nach Fertigstellungsdatum sortiert.">
                                    ?
                                </span>
                            </a>
                            <a mat-menu-item (click)="selectSortStrategy('createdAt')">Erstellung</a>
                            <a mat-menu-item (click)="selectSortStrategy('updatedAt')">Letzte Änderung</a>
                            <a mat-menu-item (click)="selectSortStrategy('orderDate')">Auftrag</a>
                            <a mat-menu-item (click)="selectSortStrategy('accidentDate')">Unfall</a>
                            <a mat-menu-item (click)="selectSortStrategy('firstVisitDate')">
                                Besichtigung
                                <span
                                    class="help-indicator"
                                    matTooltip="Falls mehrere Besichtigungen vorliegen, wird das Datum der ersten Besichtigung verwendet.">
                                    ?
                                </span>
                            </a>
                        </mat-menu>
                        <mat-icon
                            id="sort-direction-indicator"
                            class="toolbar-icon"
                            [ngClass]="{ flipped: userPreferences.sortReportListDescending }"
                            (click)="toggleSortDirection()"
                            [matTooltip]="
                                userPreferences.sortReportListDescending
                                    ? 'Sortierung absteigend'
                                    : 'Sortierung aufsteigend'
                            ">
                            arrow_upward
                        </mat-icon>
                    </div>
                    <!--============================================-->
                    <!-- END Sorting -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Filters -->
                    <!--============================================-->
                    <mat-icon
                        class="color-blue medium-icon margin-right-8 margin-left-25"
                        *ngIf="
                            userPreferences.reportListQuickFilter && userPreferences.reportListQuickFilter !== 'none'
                        ">
                        filter_alt
                    </mat-icon>
                    <div
                        #quickFilterMenuTrigger="matMenuTrigger"
                        class="quick-filter-select-container ax-header-select"
                        matTooltip="Schnellfilter"
                        [matMenuTriggerFor]="quickFilterMenu"
                        [ngSwitch]="userPreferences.reportListQuickFilter"
                        [ngClass]="{
                            active:
                                userPreferences.reportListQuickFilter &&
                                userPreferences.reportListQuickFilter !== 'none',
                        }"
                        (click)="loadLabelConfigs()">
                        <span class="ax-header-select-label" *ngSwitchCase="'none'">Kein Filter</span>
                        <span class="ax-header-select-label" *ngSwitchCase="'onlyOwn'">Meine Gutachten</span>
                        <span class="ax-header-select-label" *ngSwitchCase="'involvedUser'">
                            <ng-container *ngIf="userPreferences.reportListQuickFilterAssessorIds.length === 1">
                                {{ getAssessorFullName(userPreferences.reportListQuickFilterAssessorIds[0]) }}
                            </ng-container>
                            <ng-container *ngIf="userPreferences.reportListQuickFilterAssessorIds.length > 1">
                                {{ userPreferences.reportListQuickFilterAssessorIds.length }} Nutzer
                            </ng-container>
                        </span>
                        <span *ngSwitchCase="'reportType'" [ngSwitch]="userPreferences.reportListQuickFilterReportType">
                            <span class="ax-header-select-label" *ngSwitchCase="'liability'">Haftpflichtschaden</span>
                            <span class="ax-header-select-label" *ngSwitchCase="'shortAssessment'">Kurzgutachten</span>
                            <span class="ax-header-select-label" *ngSwitchCase="'partialKasko'">Teilkasko</span>
                            <span class="ax-header-select-label" *ngSwitchCase="'fullKasko'">Vollkasko</span>
                            <span class="ax-header-select-label" *ngSwitchCase="'valuation'">Bewertung</span>
                            <span class="ax-header-select-label" *ngSwitchCase="'oldtimerValuationSmall'">
                                Oldtimerbewertung
                            </span>
                            <span class="ax-header-select-label" *ngSwitchCase="'leaseReturn'">Zustandsbericht</span>
                            <span class="ax-header-select-label" *ngSwitchCase="'usedVehicleCheck'">
                                Gebrauchtwagencheck
                            </span>
                            <span class="ax-header-select-label" *ngSwitchCase="'invoiceAudit'">Rechnungsprüfung</span>
                        </span>
                        <span class="ax-header-select-label" *ngSwitchCase="'reportWithExpertStatement'">
                            Stellungnahme
                        </span>
                        <span class="ax-header-select-label" *ngSwitchCase="'reportWithRepairConfirmation'">
                            Reparaturbestätigung
                        </span>
                        <!--********** Label Filter **********-->
                        <div *ngSwitchCase="'labels'" class="ax-header-select-label">
                            <div class="label-item" *ngIf="userPreferences.reportList_labelsForFilter.length === 1">
                                <!--********** Label Color **********-->
                                <!--suppress CssUnknownProperty -->
                                <div
                                    class="label-color"
                                    [style.--labelColor]="
                                        getLabelConfigByLabelName(userPreferences.reportList_labelsForFilter[0])?.color
                                    "></div>

                                <!--********** Label Name **********-->
                                <div class="label-name" [matTooltip]="userPreferences.reportList_labelsForFilter[0]">
                                    {{ userPreferences.reportList_labelsForFilter[0] }}
                                </div>
                            </div>
                            <ng-container *ngIf="userPreferences.reportList_labelsForFilter.length > 1">
                                {{ userPreferences.reportList_labelsForFilter.length }} Labels
                            </ng-container>
                        </div>
                        <div class="ax-header-select-arrow"></div>
                    </div>
                    <mat-menu #quickFilterMenu="matMenu">
                        <a mat-menu-item class="no-filter-menu-item" (click)="selectQuickFilter('none')">Kein Filter</a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilter('onlyOwn')"
                            matTooltip="Nur Gutachten, die du selbst angelegt hast oder deren zuständiger Sachverständiger du bist.">
                            Meine Gutachten
                        </a>
                        <a
                            mat-menu-item
                            [matMenuTriggerFor]="assessorFilter"
                            matTooltip="Zeige nur die Vorgänge bestimmter Nutzer (Ersteller oder zuständiger Sachverständiger)">
                            Nutzer
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilter('reportWithExpertStatement')"
                            matTooltip="Nur Gutachten, die eine Stellungnahme beinhalten.">
                            Gutachten mit Stellungnahme
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilter('reportWithRepairConfirmation')"
                            matTooltip="Nur Gutachten, die eine Reparaturbestätigung beinhalten">
                            Gutachten mit Reparaturbestätigung
                        </a>
                        <a
                            mat-menu-item
                            [matMenuTriggerFor]="reportTypeFilter"
                            matTooltip="Nur bestimmte Gutachtentypen, z. B. Haftpflicht oder Bewertung">
                            Gutachtentyp
                        </a>
                        <a mat-menu-item [matMenuTriggerFor]="labelFilterSubmenu">Labels</a>
                    </mat-menu>

                    <mat-menu #reportTypeFilter>
                        <!-- It's important to call selectQuickFilter last, because that triggers the filterAndSort() method. -->
                        <a
                            mat-menu-item
                            (click)="selectQuickFilterReportType('liability'); selectQuickFilter('reportType')">
                            Haftpflichtschaden
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilterReportType('shortAssessment'); selectQuickFilter('reportType')">
                            Kurzgutachten
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilterReportType('partialKasko'); selectQuickFilter('reportType')">
                            Teilkasko
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilterReportType('fullKasko'); selectQuickFilter('reportType')">
                            Vollkasko
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilterReportType('valuation'); selectQuickFilter('reportType')">
                            Bewertung
                        </a>
                        <a
                            mat-menu-item
                            (click)="
                                selectQuickFilterReportType('oldtimerValuationSmall'); selectQuickFilter('reportType')
                            ">
                            Oldtimerbewertung
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilterReportType('leaseReturn'); selectQuickFilter('reportType')">
                            Zustandsbericht
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilterReportType('usedVehicleCheck'); selectQuickFilter('reportType')">
                            Gebrauchtwagencheck
                        </a>
                        <a
                            mat-menu-item
                            (click)="selectQuickFilterReportType('invoiceAudit'); selectQuickFilter('reportType')">
                            Rechnungsprüfung
                        </a>
                    </mat-menu>

                    <!--********** Assessors **********-->
                    <mat-menu #assessorFilter>
                        <a
                            mat-menu-item
                            class="assessor-filter__assessor-menu-item"
                            *ngFor="let assessor of assessors"
                            (click)="toggleSelectAssessorForFilter(assessor._id)">
                            <!--********** Profile Picture **********-->
                            <user-avatar class="assessor-filter-profile-picture" [userId]="assessor._id"></user-avatar>

                            <!--********** Name **********-->
                            <div class="assessor-filter__assessor-name">
                                {{ getAssessorFullName(assessor._id) }}
                            </div>

                            <!--********** Multiselect **********-->
                            <!-- Stop Mouseup event to prevent closing the menu after selecting an assessor. -->
                            <mat-checkbox
                                [checked]="userPreferences.reportListQuickFilterAssessorIds.includes(assessor._id)"
                                (change)="toggleSelectAssessorForFilter(assessor._id)"
                                (click)="$event.stopPropagation()"
                                matTooltip="Zeige die Gutachten dieses Nutzers an"></mat-checkbox>
                        </a>
                    </mat-menu>

                    <!--********** Labels **********-->
                    <mat-menu #labelFilterSubmenu class="label-filter-submenu">
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngFor="let labelConfig of labelConfigs"
                            (click)="toggleSelectLabelConfigForFilter(labelConfig.name)">
                            <!--********** Label **********-->
                            <div class="label-item-container flex-grow">
                                <div class="label-item">
                                    <!--********** Label Color **********-->
                                    <!--suppress CssUnknownProperty -->
                                    <div
                                        class="label-color"
                                        [style.--labelColor]="getLabelConfigByLabelName(labelConfig.name)?.color"></div>

                                    <!--********** Label Name **********-->
                                    <div class="label-name" [matTooltip]="labelConfig.name">
                                        {{ labelConfig.name }}
                                    </div>
                                </div>
                            </div>

                            <!--********** Multiselect **********-->
                            <mat-checkbox
                                [checked]="userPreferences.reportList_labelsForFilter.includes(labelConfig.name)"
                                (change)="toggleSelectLabelConfigForFilter(labelConfig.name)"
                                (click)="$event.stopPropagation()"
                                matTooltip="Zeige die Gutachten mit diesem Label an.{{
                                    '\n\n'
                                }}Labels können im grauen Info-Panel rechts der Gutachtenliste vergeben werden."></mat-checkbox>
                        </a>

                        <!-- No labels placeholder -->
                        <div *ngIf="labelConfigs.length === 0" class="no-labels-placeholder">
                            Keine Labels vorhanden.
                        </div>
                    </mat-menu>
                    <!--============================================-->
                    <!-- END Filters -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Number of Open Reports -->
                    <!--============================================-->
                    <div id="number-of-open-reports-container" *ngIf="openReports.length">
                        <div id="number-of-open-reports" matTooltip="Offene, sichtbare Gutachten">
                            {{ filteredOpenReports.length }}
                        </div>
                        <div
                            id="warning-too-many-open-reports"
                            class="info-note warning-note one-liner-note"
                            *ngIf="isWarningAboutTooManyOpenReportsVisible()"
                            matTooltip="Du hast sehr viele offene Gutachten.{{
                                '\n\n'
                            }}Schließe sie im Reiter 'Druck & Versand' ab, um Rechnungen schreiben zu lassen und Platz in deiner Übersicht zu schaffen.">
                            <mat-icon class="medium-icon">warning</mat-icon>
                            <span>Viele offene Gutachten</span>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Number of Open Reports -->
                    <!--============================================-->
                </div>

                <!--============================================-->
                <!-- Search Field -->
                <!--============================================-->
                <div id="report-search-field-container">
                    <mat-form-field
                        id="reports-search-field"
                        class="search-field mat-block"
                        floatLabel="never"
                        [matTooltip]="
                            'Diese Daten werden durchsucht: \n• Aktenzeichen\n• Kennzeichen (Format GZ-AB-1234)\n• Anspruchsteller Name, Telefon, Email\n• Werkstatt\n• Anwalt Name, Aktenzeichen\n• Versicherung\n• Versicherungsschein\n• Schadennummer\n• VIN\n• Hersteller & Modell\n• Fahrzeugfarbe\n\nSuchworte müssen mindestens drei Zeichen lang sein.'
                        ">
                        <input
                            matInput
                            [(ngModel)]="searchTerm"
                            (input)="filterAndSortReports()"
                            placeholder="Suchen"
                            (ngModelChange)="updateSearchTerm()" />
                        <mat-icon matPrefix>search</mat-icon>
                        <mat-icon
                            matSuffix
                            id="date-limit-toggle-icon"
                            class="input-toolbar-icon"
                            *ngIf="!dateLimitInputsShown"
                            (click)="toggleDateLimitInputs()"
                            matTooltip="Erstellungsdatum eingrenzen">
                            today
                        </mat-icon>
                    </mat-form-field>
                    <div id="date-limit-inputs-container" *ngIf="dateLimitInputsShown">
                        <date-range-picker
                            [(dateFrom)]="dateLimitFrom"
                            [(dateTo)]="dateLimitTo"
                            (dateRangeChange)="
                                updateDateLimitFrom($event.dateFrom);
                                updateDateLimitTo($event.dateTo);
                                resetLoadHistoryAndReloadReports()
                            "
                            [floatPlaceholder]="'never'"></date-range-picker>

                        <!--
                    <date-input
                        [(date)]="dateLimitFrom"
                        placeholder="Von"
                        floatPlaceholder="never"
                        (dateChange)="updateDateLimitFrom($event); filterAndSortReports()"></date-input>
                    <date-input
                        [(date)]="dateLimitTo"
                        placeholder="Bis"
                        floatPlaceholder="never"
                        (dateChange)="updateDateLimitTo($event); filterAndSortReports()"></date-input>
                    -->

                        <mat-icon
                            class="toolbar-icon"
                            (click)="toggleDateLimitInputs()"
                            matTooltip="Datumsgrenzen entfernen">
                            close
                        </mat-icon>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Search Field -->
                <!--============================================-->

                <!--============================================-->
                <!-- Head Runner Toolbar -->
                <!--============================================-->
                <div id="reports-head-runner-icons-container">
                    <!--********** New Report **********-->
                    <!-- The container is necessary for the pulse animation. -->
                    <div
                        id="split-button-container"
                        [ngClass]="{ 'button-pulse': user && !user.tutorialState.liabilityReportCreated }">
                        <div class="split-button attract-focus">
                            <!--********** Default Report Type **********-->
                            <div
                                class="split-button-left"
                                (click)="createReport()"
                                matTooltip="Neuer Haftpflichtschaden">
                                <mat-icon id="new-report-button-icon" class="small-icon">add</mat-icon>
                                <div id="new-report-button-label">Haftpflicht</div>
                            </div>

                            <!--********** Other Report Types **********-->
                            <div
                                class="split-button-right"
                                report-type-dialog-anchor
                                (changedReportType)="createReport($event)"
                                matTooltip="Andere Gutachtentypen">
                                <mat-icon class="small-icon">arrow_drop_down</mat-icon>
                            </div>
                        </div>
                    </div>

                    <!--********** Refresh **********-->
                    <mat-icon
                        id="refresh-report-list-toolbar-icon"
                        class="head-runner-icon toolbar-icon"
                        *ngIf="!reportRefreshInProgress"
                        (click)="refreshReports()"
                        matTooltip="Gutachten neu laden">
                        refresh
                    </mat-icon>
                    <img
                        id="refreshing-reports-indicator"
                        src="/assets/images/icons/loading-indicator.svg"
                        *ngIf="reportRefreshInProgress"
                        matTooltip="Aktualisiere Gutachten..." />

                    <!--********** Toggle Details Pane **********-->
                    <mat-icon
                        id="toggle-details-pane-toolbar-icon"
                        class="head-runner-icon toolbar-icon outlined"
                        (click)="toggleReportDetailsPane()"
                        matTooltip="Details">
                        info
                    </mat-icon>

                    <!--********** Export Pending Indicator **********-->
                    <img
                        src="/assets/images/icons/loading-indicator.svg"
                        alt=""
                        *ngIf="reportExportPending"
                        class="margin-right-8"
                        matTooltip="Exportiere..." />

                    <!--********** Submenu **********-->
                    <mat-icon class="head-runner-icon toolbar-icon" [matMenuTriggerFor]="reportsHeadRunnerMenu">
                        more_vert
                    </mat-icon>
                    <mat-menu #reportsHeadRunnerMenu="matMenu">
                        <!--********** Import Legacy Reports **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="showLegacyReportsImportDialog()"
                            *ngIf="isUserAdmin()">
                            <mat-icon>upload</mat-icon>
                            Gutachten importieren
                        </a>

                        <!--********** Export Reports **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            matTooltip="Alle Gutachten werden als CSV-Datei heruntergeladen. {{
                                !user.accessRights.seeAllReports
                                    ? 'Der Export erfordert die Berechtigung &quot;Alle Gutachten&quot;.'
                                    : ''
                            }}"
                            [ngClass]="{ disabled: !user.accessRights.seeAllReports }"
                            (click)="exportReportsAsCSV()"
                            *ngIf="isUserAdmin()">
                            <mat-icon>download</mat-icon>
                            Gutachten exportieren
                        </a>

                        <!--********** Spalten editieren **********-->
                        <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="editColumnsSubmenu">
                            <mat-icon class="outlined">view_column</mat-icon>
                            Spalten konfigurieren
                        </a>
                        <mat-menu #editColumnsSubmenu>
                            <!--********** Report Token **********-->
                            <a
                                mat-menu-item
                                (click)="
                                    userPreferences.reportListReportTokenShown =
                                        !userPreferences.reportListReportTokenShown
                                "
                                class="menu-item-with-toggle">
                                <mat-icon>fingerprint</mat-icon>
                                <span class="menu-item-label">Aktenzeichen</span>
                                <mat-slide-toggle
                                    class="slide-toggle-small margin-left-15"
                                    [(ngModel)]="userPreferences.reportListReportTokenShown"
                                    (click)="$event.stopPropagation()"></mat-slide-toggle>
                            </a>

                            <!--********** Responsible Assessor **********-->
                            <a
                                mat-menu-item
                                (click)="
                                    userPreferences.reportListResponsibleAssessorShown =
                                        !userPreferences.reportListResponsibleAssessorShown
                                "
                                class="menu-item-with-toggle">
                                <mat-icon>person</mat-icon>
                                <span class="menu-item-label">Sachverständigen</span>
                                <mat-slide-toggle
                                    class="slide-toggle-small margin-left-15"
                                    [(ngModel)]="userPreferences.reportListResponsibleAssessorShown"
                                    (click)="$event.stopPropagation()"></mat-slide-toggle>
                            </a>

                            <!--********** Labels **********-->
                            <a
                                mat-menu-item
                                (click)="userPreferences.reportListLabelsShown = !userPreferences.reportListLabelsShown"
                                class="menu-item-with-toggle">
                                <mat-icon>sell</mat-icon>
                                <span class="menu-item-label">Labels</span>
                                <mat-slide-toggle
                                    class="slide-toggle-small margin-left-15"
                                    [(ngModel)]="userPreferences.reportListLabelsShown"
                                    (click)="$event.stopPropagation()"></mat-slide-toggle>
                            </a>

                            <!--********** Date for open Reports **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                [matMenuTriggerFor]="selectDateRowSubmenu"
                                matTooltip="Du kannst auswählen, welches Datum du bei offenen Gutachten angezeigt haben möchtest. Bei abgeschlossenen Gutachten wird immer das Fertigstellungsdatum angezeigt.">
                                <mat-icon>today</mat-icon>
                                Datum auswählen
                            </a>

                            <mat-menu #selectDateRowSubmenu>
                                <a
                                    class="menu-item-date-row-submenu"
                                    mat-menu-item
                                    (click)="userPreferences.reportListSelectedDateRow = 'created'">
                                    Erstellungsdatum
                                    <mat-icon *ngIf="userPreferences.reportListSelectedDateRow === 'created'">
                                        check
                                    </mat-icon>
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-date-row-submenu"
                                    (click)="userPreferences.reportListSelectedDateRow = 'order'">
                                    Auftragsdatum
                                    <mat-icon *ngIf="userPreferences.reportListSelectedDateRow === 'order'">
                                        check
                                    </mat-icon>
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-date-row-submenu"
                                    (click)="userPreferences.reportListSelectedDateRow = 'firstVisit'">
                                    Erste Besichtigung
                                    <mat-icon *ngIf="userPreferences.reportListSelectedDateRow === 'firstVisit'">
                                        check
                                    </mat-icon>
                                </a>
                                <a
                                    class="menu-item-date-row-submenu"
                                    mat-menu-item
                                    (click)="userPreferences.reportListSelectedDateRow = 'accident'">
                                    Unfalldatum
                                    <mat-icon *ngIf="userPreferences.reportListSelectedDateRow === 'accident'">
                                        check
                                    </mat-icon>
                                </a>
                            </mat-menu>
                        </mat-menu>

                        <!--********** Trash **********-->
                        <a mat-menu-item class="menu-item-with-icon" *ngIf="!showTrash" (click)="toggleTrashView()">
                            <mat-icon class="outlined">delete_sweep</mat-icon>
                            Papierkorb öffnen
                        </a>
                        <a mat-menu-item class="menu-item-with-icon" *ngIf="showTrash" (click)="toggleTrashView()">
                            <mat-icon class="outlined">delete_forever</mat-icon>
                            Papierkorb schließen
                        </a>
                    </mat-menu>
                </div>
                <!--============================================-->
                <!-- END Head Runner Toolbar -->
                <!--============================================-->
            </div>
            <!-------------- ENDE Offene Aufträge - Kopfleiste ------------------->

            <!-------------- No data to display -->
            <div
                *ngIf="reportsLoaded && !showTrash && openReports.length === 0 && doneReports.length === 0"
                id="no-reports-to-display-container">
                <h3>
                    Hallo
                    <span *ngIf="user?.firstName">{{ user.firstName }}</span>
                </h3>
                <img src="/assets/images/icons/no-data-to-display.png" />
                <p>Ziemlich leer hier. Lass uns ein neues Gutachten anlegen.</p>
                <button (click)="createReport()">Los geht's!</button>
            </div>
            <!-------------- ENDE No data to display -->

            <!-------------- No Trash Entries -->
            <div *ngIf="showTrash && filteredDeletedReports.length === 0" id="no-trashed-reports-to-display-container">
                <p>Aktuell keine Gutachten im Papierkorb.</p>
            </div>
            <!-------------- ENDE No Trash Entries -->

            <!--============================================-->
            <!-- Data hidden by filters -->
            <!--============================================-->
            <div
                id="no-reports-with-filter"
                *ngIf="
                    (openReports.length > 0 || doneReports.length > 0) &&
                    filteredOpenReports.length === 0 &&
                    filteredDoneReports.length === 0
                ">
                <h3>Keine Gutachten sichtbar</h3>
                <p>{{ openReports.length + doneReports.length }} Gutachten durch Filter ausgeblendet</p>
                <div id="no-report-visible-server-search-pending" *ngIf="isLoadMoreDoneReportsPending">
                    <img src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Durchsuche Server...
                </div>
            </div>
            <!--============================================-->
            <!-- END Data hidden by filters -->
            <!--============================================-->

            <!-------------- Unfinished reports - Records ------------------------>
            <table *ngIf="!showTrash" class="report-list">
                <tr
                    report-list-row
                    *ngFor="let report of filteredOpenReports; trackBy: getReportId"
                    [report]="report"
                    [assessors]="assessors"
                    [isSelected]="selectedReport === report"
                    [searchTerm]="searchTerm"
                    [searchMatches]="searchMatchesOpenReports.get(report)"
                    (click)="selectReport(report)"
                    (dblclick)="navigate([report._id])"
                    (reportChange)="saveReport($event)"
                    (moveToTrash)="moveReportToTrash($event)"
                    (deleteReportPermanently)="deleteReportPermanently($event)"></tr>
            </table>
            <!-------------- END Unfinished reports - Records ------------------>
            <!-------------- Deleted reports - Records ------------------>
            <table *ngIf="showTrash" class="report-list">
                <tr
                    report-list-row
                    *ngFor="let report of filteredDeletedReports; trackBy: getReportId"
                    [report]="report"
                    [assessors]="assessors"
                    [isSelected]="selectedReport === report"
                    [searchTerm]="searchTerm"
                    [searchMatches]="searchMatchesOpenReports.get(report)"
                    (click)="selectReport(report)"
                    (dblclick)="navigate([report._id])"
                    (reportChange)="saveReport($event)"
                    (restoreFromTrash)="restoreReportFromTrash($event)"
                    (deleteReportPermanently)="deleteReportPermanently($event)"></tr>
            </table>
            <!-------------- END Deleted reports - Records ------------------>
        </div>
        <!-------------- END Unfinished reports ------------------------>

        <!-------------- Finished reports ---------------------->
        <div
            id="reports-done-container"
            *ngIf="!showTrash && filteredDoneReports.length > 0"
            class="reports-list-container card">
            <!-------------- Finished reports - Records ------------------------>
            <table class="report-list">
                <tr
                    report-list-row
                    *ngFor="let report of filteredDoneReports; trackBy: getReportId"
                    [report]="report"
                    [assessors]="assessors"
                    [isSelected]="selectedReport === report"
                    [searchTerm]="searchTerm"
                    [searchMatches]="searchMatchesDoneReports.get(report)"
                    (click)="selectReport(report)"
                    (dblclick)="navigate([report._id])"
                    (reportChange)="saveReport($event)"
                    (moveToTrash)="moveReportToTrash($event)"
                    (restoreFromTrash)="restoreReportFromTrash($event)"
                    (deleteReportPermanently)="deleteReportPermanently($event)"></tr>
            </table>
            <div
                (intersectsViewport)="triggerLoadMoreDoneReports()"
                *ngIf="
                    filteredDoneReports.length > 0 &&
                    !isLoadMoreDoneReportsPending &&
                    !allReportsLoadedWithCurrentFilters &&
                    !searchTerm
                ">
                <span>Mehr Gutachten laden</span>
            </div>
            <!------- END Finished reports - Records -->
        </div>

        <!--============================================-->
        <!-- Controls Below the Table -->
        <!--============================================-->
        <div id="load-more-done-reports-button-and-number-of-reports-container">
            <!--********** Load more reports **********-->
            <button
                id="load-more-done-reports-button"
                class="show-more-button"
                (click)="triggerLoadMoreDoneReports()"
                [ngClass]="{ disabled: allReportsLoadedWithCurrentFilters }">
                Mehr anzeigen
            </button>

            <!--********** Number of visible reports **********-->
            <div id="number-of-visible-reports-container" matTooltip="Sichtbare Gutachten">
                <h4 *ngIf="!reportLimitReached">
                    {{
                        showTrash
                            ? filteredDeletedReports.length
                            : filteredOpenReports.length + filteredDoneReports.length
                    }}
                    Gutachten
                    <img *ngIf="isLoadMoreDoneReportsPending" src="/assets/images/icons/loading-indicator.svg" />
                </h4>
                <h4 *ngIf="reportLimitReached">
                    Alle
                    {{
                        showTrash
                            ? filteredDeletedReports.length
                            : filteredOpenReports.length + filteredDoneReports.length
                    }}
                    Gutachten geladen
                </h4>
            </div>
        </div>
        <!--============================================-->
        <!-- END Controls Below the Table -->
        <!--============================================-->
        <!-------------- ENDE Finished reports ------------------------>
    </div>
    <!--============================================-->
    <!-- Report Details Pane -->
    <!--============================================-->
    <div
        id="report-details-pane-placeholder"
        *ngIf="userPreferences.showReportDetailsPane"
        @slideOutSide
        [@.disabled]="animationsDisabled">
        <div id="report-details-pane-container" class="card card-with-padding">
            <div class="card-toolbar">
                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="settingsSubmenu">more_vert</mat-icon>
                <!--============================================-->
                <!-- Settings: Row Visibility -->
                <!--============================================-->
                <mat-menu #settingsSubmenu>
                    <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="rowVisibilitySettingsMenu">
                        <mat-icon class="outlined">table_rows</mat-icon>
                        Zeilen konfigurieren
                    </a>

                    <mat-menu #rowVisibilitySettingsMenu>
                        <a
                            mat-menu-item
                            class="menu-item-with-toggle"
                            (click)="
                                userPreferences.reportDetailsPane_reportTokenShown =
                                    !userPreferences.reportDetailsPane_reportTokenShown
                            ">
                            Aktenzeichen
                            <mat-slide-toggle
                                class="slide-toggle-small margin-left-15"
                                [(ngModel)]="userPreferences.reportDetailsPane_reportTokenShown"
                                (click)="$event.stopPropagation()"></mat-slide-toggle>
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-toggle"
                            (click)="
                                userPreferences.reportDetailsPane_lastEditedShown =
                                    !userPreferences.reportDetailsPane_lastEditedShown
                            ">
                            Zuletzt bearbeitet
                            <mat-slide-toggle
                                class="slide-toggle-small margin-left-15"
                                [(ngModel)]="userPreferences.reportDetailsPane_lastEditedShown"
                                (click)="$event.stopPropagation()"></mat-slide-toggle>
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-toggle"
                            (click)="
                                userPreferences.reportDetailsPane_labelsShown =
                                    !userPreferences.reportDetailsPane_labelsShown
                            ">
                            Labels
                            <mat-slide-toggle
                                class="slide-toggle-small margin-left-15"
                                [(ngModel)]="userPreferences.reportDetailsPane_labelsShown"
                                (click)="$event.stopPropagation()"></mat-slide-toggle>
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-toggle"
                            (click)="
                                userPreferences.reportDetailsPane_tasksShown =
                                    !userPreferences.reportDetailsPane_tasksShown
                            ">
                            Aufgaben
                            <mat-slide-toggle
                                class="slide-toggle-small margin-left-15"
                                [(ngModel)]="userPreferences.reportDetailsPane_tasksShown"
                                (click)="$event.stopPropagation()"></mat-slide-toggle>
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-toggle"
                            (click)="
                                userPreferences.reportDetailsPane_involvedPartiesShown =
                                    !userPreferences.reportDetailsPane_involvedPartiesShown
                            ">
                            Beteiligte
                            <mat-slide-toggle
                                class="slide-toggle-small margin-left-15"
                                [(ngModel)]="userPreferences.reportDetailsPane_involvedPartiesShown"
                                (click)="$event.stopPropagation()"></mat-slide-toggle>
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-toggle"
                            (click)="
                                userPreferences.reportDetailsPane_progressShown =
                                    !userPreferences.reportDetailsPane_progressShown
                            ">
                            Fortschritt
                            <mat-slide-toggle
                                class="slide-toggle-small margin-left-15"
                                [(ngModel)]="userPreferences.reportDetailsPane_progressShown"
                                (click)="$event.stopPropagation()"></mat-slide-toggle>
                        </a>
                    </mat-menu>
                </mat-menu>
                <!--============================================-->
                <!-- END Settings: Row Visibility -->
                <!--============================================-->
            </div>
            <mat-icon
                class="dialog-close-icon"
                matTooltip="Schließen"
                (click)="userPreferences.showReportDetailsPane = false">
                close
            </mat-icon>

            <div id="report-details-pane-report-selected-container" *ngIf="selectedReport">
                <display-internal-id [internalId]="selectedReport._id" entityName="Gutachten"></display-internal-id>
                <!--============================================-->
                <!-- Report Header Data -->
                <!--============================================-->
                <h2>Details</h2>
                <div class="report-details-section">
                    <div *ngIf="userPreferences.reportDetailsPane_reportTokenShown" class="report-details-item">
                        <div class="info-panel-row_icon-container">
                            <mat-icon>fingerprint</mat-icon>
                        </div>
                        <div class="report-details-item-value-container">
                            <div class="report-details-item-value">{{ selectedReport.token || '-' }}</div>
                            <div class="report-details-item-label">Aktenzeichen</div>
                        </div>
                    </div>
                    <div class="report-details-item">
                        <div class="info-panel-row_icon-container">
                            <user-avatar
                                [showEmptyState]="true"
                                [userId]="selectedReport.createdBy"
                                [size]="24"></user-avatar>
                        </div>
                        <div class="report-details-item-value-container">
                            <div class="report-details-item-value">{{ getUserFullName(selectedReport.createdBy) }}</div>
                            <div class="report-details-item-label">
                                Ersteller
                                <ng-container *ngIf="selectedReport.createdBy === selectedReport.responsibleAssessor">
                                    &nbsp;& Sachverständiger
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div
                        class="report-details-item"
                        *ngIf="selectedReport.createdBy !== selectedReport.responsibleAssessor">
                        <div class="info-panel-row_icon-container">
                            <user-avatar
                                [showEmptyState]="true"
                                [userId]="selectedReport.responsibleAssessor"
                                [size]="24"></user-avatar>
                        </div>
                        <!--********** Responsible Assessor **********-->
                        <div class="report-details-item-value-container">
                            <div class="report-details-item-value">
                                {{ getAssessorFullName(selectedReport.responsibleAssessor) || 'Niemand angegeben' }}
                            </div>
                            <div class="report-details-item-label">Sachverständiger</div>
                        </div>
                    </div>

                    <!--********** Order Date **********-->
                    <div class="report-details-item">
                        <div class="info-panel-row_icon-container">
                            <mat-icon>event_available</mat-icon>
                        </div>
                        <div class="report-details-item-value-container">
                            <div *ngIf="selectedReport.orderDate" class="report-details-item-value">
                                {{ selectedReport.orderDate | moment }}
                                {{
                                    selectedReport.orderTime
                                        ? '- ' + (selectedReport.orderTime | moment: 'HH:mm') + ' Uhr'
                                        : ''
                                }}
                            </div>
                            <div *ngIf="selectedReport.orderDate" class="report-details-item-label">Auftragsdatum</div>
                            <div *ngIf="!selectedReport.orderDate" class="report-details-item-label">
                                Auftrag noch nicht erteilt
                            </div>
                        </div>
                    </div>

                    <!--********** Updated At **********-->
                    <div *ngIf="userPreferences.reportDetailsPane_lastEditedShown" class="report-details-item">
                        <div class="info-panel-row_icon-container">
                            <mat-icon>today</mat-icon>
                        </div>
                        <div class="report-details-item-value-container">
                            <div
                                class="report-details-item-value"
                                matTooltip="{{ selectedReport.updatedAt | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                                {{ selectedReport.updatedAt | moment }}
                            </div>
                            <div class="report-details-item-label">Zuletzt bearbeitet</div>
                        </div>
                    </div>

                    <!--============================================-->
                    <!-- Labels -->
                    <!--============================================-->
                    <div
                        *ngIf="userPreferences.reportDetailsPane_labelsShown"
                        id="labels-row"
                        class="report-details-item">
                        <div
                            id="labels-icon-container"
                            class="info-panel-row_icon-container cursor-pointer"
                            (click)="labelDropdownAnchor.openOverlay($event)"
                            matTooltip="Nutze Labels, um den Status festzuhalten oder um danach zu filtern.">
                            <mat-icon>sell</mat-icon>
                        </div>

                        <!--********** Text **********-->
                        <div
                            class="info-panel-row_text-container"
                            labelDropdown
                            [(selectedLabels)]="selectedReport.labels"
                            [labelGroup]="'report'"
                            (selectedLabelsChange)="selectedLabelsForReportChanged()"
                            #labelDropdownAnchor="labelDropdownAnchor">
                            <label-display [labels]="selectedReport.labels" [darkMode]="true"></label-display>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Labels -->
                    <!--============================================-->
                </div>
                <!--============================================-->
                <!-- END Report Header Data -->
                <!--============================================-->

                <!--============================================-->
                <!-- Task List -->
                <!--============================================-->
                <task-list-inline
                    *ngIf="userPreferences.reportDetailsPane_tasksShown"
                    [report]="selectedReport"></task-list-inline>
                <!--============================================-->
                <!-- END Task List -->
                <!--============================================-->

                <div *ngIf="userPreferences.reportDetailsPane_involvedPartiesShown" class="report-details-section">
                    <!--********** Claimant **********-->
                    <involved-party-contact-details
                        *ngIf="isNameOrOrganizationFilled(selectedReport.claimant?.contactPerson)"
                        [contactPerson]="selectedReport.claimant.contactPerson"
                        [involvedPartyRole]="'claimant'"
                        [reportType]="selectedReport.type"
                        [reportId]="selectedReport._id"
                        [hasReceivedEmail]="selectedReport.claimant.receivedEmail"
                        [hasReceivedLetter]="selectedReport.claimant.receivedLetter"></involved-party-contact-details>

                    <!--********** Insurance **********-->
                    <involved-party-contact-details
                        *ngIf="isNameOrOrganizationFilled(selectedReport.insurance?.contactPerson)"
                        [contactPerson]="selectedReport.insurance.contactPerson"
                        [involvedPartyRole]="'insurance'"
                        [reportType]="selectedReport?.type"
                        [reportId]="selectedReport._id"
                        [hasReceivedEmail]="selectedReport.insurance.receivedEmail"
                        [hasReceivedLetter]="selectedReport.insurance.receivedLetter">
                        <div *ngIf="selectedReport.insurance.caseNumber" matTooltip="Schadennummer">
                            <span class="label">SN:</span>
                            {{ selectedReport.insurance.caseNumber }}
                        </div>
                        <div *ngIf="selectedReport.authorOfDamage.insuranceNumber" matTooltip="Versicherungsnummer">
                            <span class="label">VN:</span>
                            {{ selectedReport.authorOfDamage.insuranceNumber }}
                        </div>
                    </involved-party-contact-details>

                    <!--********** Lawyer **********-->
                    <involved-party-contact-details
                        *ngIf="isNameOrOrganizationFilled(selectedReport.lawyer?.contactPerson)"
                        [contactPerson]="selectedReport.lawyer.contactPerson"
                        [involvedPartyRole]="'lawyer'"
                        [reportType]="selectedReport.type"
                        [reportId]="selectedReport._id"
                        [hasReceivedEmail]="selectedReport.lawyer.receivedEmail"
                        [hasReceivedLetter]="selectedReport.lawyer.receivedLetter">
                        <div *ngIf="selectedReport.lawyer.caseNumber" matTooltip="Aktenzeichen des Anwalts">
                            <span class="label">AZ:</span>
                            {{ selectedReport.lawyer.caseNumber }}
                        </div>
                    </involved-party-contact-details>

                    <!--********** Garage **********-->
                    <involved-party-contact-details
                        *ngIf="isNameOrOrganizationFilled(selectedReport.garage?.contactPerson)"
                        [contactPerson]="selectedReport.garage.contactPerson"
                        [involvedPartyRole]="'garage'"
                        [reportType]="selectedReport.type"
                        [reportId]="selectedReport._id"
                        [hasReceivedEmail]="selectedReport.garage.receivedEmail"
                        [hasReceivedLetter]="selectedReport.garage.receivedLetter"></involved-party-contact-details>
                </div>

                <!--============================================-->
                <!-- Report Progress -->
                <!--============================================-->
                <div *ngIf="userPreferences.reportDetailsPane_progressShown" id="report-progress-container">
                    <h3>Fortschritt</h3>
                    <table id="report-progress-table">
                        <ng-container *ngFor="let reportTabName of reportTabNames">
                            <tr class="report-progress-row" *ngIf="isReportTabVisible(reportTabName)">
                                <td
                                    class="report-progress-label-cell cursor-pointer"
                                    [routerLink]="[selectedReport._id, getLinkSectionByReportTabName(reportTabName)]">
                                    {{ translateReportTabName(reportTabName) }}
                                </td>
                                <td class="report-progress-icon-cell">
                                    <report-progress-indicator-icon
                                        *ngIf="reportProgressConfig"
                                        [report]="selectedReport"
                                        [reportProgressConfig]="reportProgressConfig"
                                        [reportTabName]="reportTabName"
                                        overlayPositionY="top"></report-progress-indicator-icon>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <!--============================================-->
                <!-- END Report Progress -->
                <!--============================================-->
            </div>

            <!-------------- No report selected -->
            <div id="report-details-pane-no-report-container" *ngIf="!selectedReport">
                <div class="margin-bottom-30">
                    <img src="/assets/images/icons/no-data-to-display.png" alt="" />
                </div>
                <span class="semibold">
                    Bitte wähle ein
                    <br />
                    Gutachten aus.
                </span>
            </div>
        </div>
        <div class="card" id="associated-invoices-pane-container" *ngIf="associatedInvoiceIdsOfSelectedReport.length">
            <associated-invoice-list-item
                *ngFor="let associatedInvoiceId of associatedInvoiceIdsOfSelectedReport"
                [invoiceId]="associatedInvoiceId"></associated-invoice-list-item>
        </div>
    </div>
    <!--============================================-->
    <!-- END Report Details Pane -->
    <!--============================================-->
</section>
<!--============================================-->
<!-- END Reports -->
<!--============================================-->

<!--============================================-->
<!-- Import Legacy Reports Dialog -->
<!--============================================-->
<legacy-reports-import-dialog
    *ngIf="importLegacyReportsDialogShown"
    (close)="hideLegacyReportsImportDialog()"
    @runChildAnimations></legacy-reports-import-dialog>
<!--============================================-->
<!-- END Import Legacy Reports Dialog -->
<!--============================================-->

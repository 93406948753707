<mat-form-field class="mat-block">
    <mat-quill
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [disabled]="disabled"
        [matAutocomplete]="placeholderAutocomplete"
        [isEmailQuill]="isEmailQuill"
        (ngModelChange)="handleQuillNgModelChange($event)"
        (change)="handleQuillChange($event)"
        (blur)="handleQuillBlur()"
        (focus)="handleQuillFocus()"
        #matQuillComponent></mat-quill>
    <!--********** AutoiXpert removes margins for paragraphs in emails (like Gmail), so not difference between <br> and <p> there. **********-->
    <mat-hint *ngIf="!isEmailQuill">Umschalt ⇧ + Enter für Umbruch mit weniger Abstand</mat-hint>

    <!--============================================-->
    <!-- Autocomplete -->
    <!--============================================-->
    <mat-autocomplete #placeholderAutocomplete (opened)="initializeVirtualScrollViewport()">
        <cdk-virtual-scroll-viewport
            #virtualScrollViewport
            class="autocomplete-test-viewport"
            [itemSize]="autocompleteItemHeight"
            [ngStyle]="{ height: autocompleteHeight }"
            minBufferPx="200"
            maxBufferPx="400">
            <mat-option
                *cdkVirtualFor="let placeholder of filteredPlaceholders"
                class="compact-option"
                [value]="placeholder"
                (mousedown)="$event.preventDefault()"
                (onSelectionChange)="handlePlaceholderAutocompleteSelection($event)">
                {{ placeholder }}
            </mat-option>
        </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
    <!--============================================-->
    <!-- END Autocomplete -->
    <!--============================================-->
</mat-form-field>

import { FabricImage, FabricObject, FabricText, Shadow } from 'fabric';
import { TeamPreferences } from '@autoixpert/models/teams/team-preferences';
import { DEFAULT_FABRIC_CONTROL_OPTIONS } from '../../../../reports/details/photos/photo-editor/fabric-js-custom-types';
import { OriginX, OriginY } from '../../../types/fabric-type-extensions';

export async function getWatermarkTextOrImage({
    watermarkImageUrl,
    watermarkPreferences,
    canvasHeight,
    canvasWidth,
}: {
    watermarkImageUrl: string;
    watermarkPreferences: TeamPreferences['watermark'];
    canvasHeight: number;
    canvasWidth: number;
}): Promise<FabricObject> {
    /**
     * Determine positions
     */
    let originX: OriginX; // Which point of the object is relevant for positioning? -> left right means one of the right corners or side is used for positioning the object.
    let originY: OriginY;
    let top: number; // Distance from canvas top edge in px
    let left: number; // Distance from canvas left edge in px
    switch (watermarkPreferences.anchor) {
        case 'topLeft':
            originX = 'left';
            originY = 'top';
            top = canvasHeight * watermarkPreferences.offsetY;
            left = canvasWidth * watermarkPreferences.offsetX;
            break;
        case 'topRight':
            originX = 'right';
            originY = 'top';
            top = canvasHeight * watermarkPreferences.offsetY;
            left = canvasWidth * (1 + watermarkPreferences.offsetX);
            break;
        case 'center':
            originX = 'center';
            originY = 'center';
            top = canvasHeight * (0.5 + watermarkPreferences.offsetY);
            left = canvasWidth * (0.5 + watermarkPreferences.offsetX);
            break;
        case 'bottomLeft':
            originX = 'left';
            originY = 'bottom';
            top = canvasHeight * (1 + watermarkPreferences.offsetY);
            left = canvasWidth * watermarkPreferences.offsetX;
            break;
        case 'bottomRight':
            originX = 'right';
            originY = 'bottom';
            top = canvasHeight * (1 + watermarkPreferences.offsetY);
            left = canvasWidth * (1 + watermarkPreferences.offsetX);
            break;
    }

    const sharedProperties = {
        originX,
        originY,
        top,
        left,
        opacity: watermarkPreferences.opacity,
        ...DEFAULT_FABRIC_CONTROL_OPTIONS,
    };

    if (watermarkPreferences.type === 'text') {
        const text = new FabricText(watermarkPreferences.text, {
            data: {
                axType: 'watermarkText',
            },
            ...sharedProperties,
            // Text-related properties
            fontSize: 19,
            fontFamily: 'Source Sans Pro',
            fontWeight: 'bold',
            fill: watermarkPreferences.textColor,
            shadow: new Shadow('rgba(0,0,0,.2) 0 2px 2px'),
        });

        // These properties are only set when the user edits the text. Only then do we know the text's dimensions.
        if (watermarkPreferences.textScaleXRelativeToPhoto && watermarkPreferences.textScaleYRelativeToPhoto) {
            text.set({
                scaleX: watermarkPreferences.textScaleXRelativeToPhoto * canvasWidth,
                scaleY: watermarkPreferences.textScaleYRelativeToPhoto * canvasHeight,
            });
        }

        return text;
    } else if (watermarkPreferences.type === 'image') {
        /**
         * Load image, then add it to canvas.
         */
        return new Promise((resolve) => {
            FabricImage.fromURL(watermarkImageUrl).then((image: FabricImage) => {
                // The image should be scaled so that the target width (canvasWidth * watermarkPreferences.scaleX) is reached. That's accomplished
                // by dividing the target width by the image width.
                // These properties are not set until the
                if (!watermarkPreferences.widthRelativeToPhoto) {
                    watermarkPreferences.widthRelativeToPhoto = image.width / canvasWidth;
                }
                if (!watermarkPreferences.aspectRatio) {
                    watermarkPreferences.aspectRatio = image.width / image.height;
                }
                const imageScaleX = (canvasWidth * watermarkPreferences.widthRelativeToPhoto) / image.width;
                const imageScaleY =
                    (canvasWidth * watermarkPreferences.widthRelativeToPhoto) /
                    watermarkPreferences.aspectRatio /
                    image.height;

                image.set({
                    data: {
                        axType: 'watermarkImage',
                    },
                    ...sharedProperties,
                    scaleX: imageScaleX,
                    scaleY: imageScaleY,
                });
                resolve(image);
            });
        });
    } else {
        console.error(
            'Unable to draw watermark if no watermark type is chosen. Please select either "image" or "text" as watermark type.',
        );
    }
}

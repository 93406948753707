import { RouterModule, Routes } from '@angular/router';
import { AccessRights } from '@autoixpert/models/user/user';
import { AccessRightGuard } from '../shared/services/access-right-guard.service';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { DocumentBuildingBlockListComponent } from './document-building-block-list/document-building-block-list.component';
import { ExternalApiPreferencesComponent } from './external-api/external-api-preferences.component';
import { InvoiceNumberJournalComponent } from './preferences-overview/invoice-number-journal/invoice-number-journal.component';
import { PreferencesOverviewComponent } from './preferences-overview/preferences-overview.component';
import { PreferencesComponent } from './preferences.component';
import { SignablePdfTemplatesListComponent } from './signable-pdf-templates/signable-pdf-templates-list/signable-pdf-templates-list.component';

const preferencesRoutes: Routes = [
    {
        path: 'Einstellungen',
        component: PreferencesComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: PreferencesOverviewComponent,
                pathMatch: 'full',
                data: {
                    requiredAccessRight: 'seeSettings',
                } satisfies { requiredAccessRight: keyof AccessRights },
                canActivate: [AccessRightGuard],
            },
            {
                path: 'Textbausteine',
                component: DocumentBuildingBlockListComponent,
            },
            {
                path: 'Unterschreibbare-PDF-Dokumente',
                component: SignablePdfTemplatesListComponent,
            },
            {
                path: 'Externe-API',
                component: ExternalApiPreferencesComponent,
            },

            {
                path: 'Rechnungsnummern-Journal',
                component: InvoiceNumberJournalComponent,
            },
        ],
    },
];

export const preferencesRouting = RouterModule.forChild(preferencesRoutes);

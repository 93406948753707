import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';
import { DataTypeBase } from '../../indexed-db/database.types';
import { ReportType } from '../report';

export class CustomFeeSet implements DataTypeBase {
    constructor(template?: Partial<CustomFeeSet>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    _id: string = generateId();
    title: string = 'Neue Honorartabelle';
    fees: CustomFeeSetRow[] = [];
    year: number = DateTime.now().year;

    /**
     * The fee set is only available in the selected report types. Useful for fee sets
     * that are specific to partial or full kasko reports.
     */
    availableInReportTypes: ReportType[] = ['liability'];

    createdBy: string;
    teamId: string;

    _schemaVersion = 2 as const;
    _documentVersion: number = 0;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

export class CustomFeeSetRow {
    constructor(template?: Partial<CustomFeeSetRow>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    lowerLimit: number;
    fee: number;
}

/**
 * List of report types that currently support a fee table.
 */
export const reportTypesWithFeeSets: ReportType[] = ['liability', 'partialKasko', 'fullKasko'];

import { Injectable } from '@angular/core';
import { Notification } from '@autoixpert/models/notifications/notification';

@Injectable()
export class axNotificationService {
    private apiPath: string = '/notifications';

    /*
    mockNotifications: Notification[] = [
        {
            _id: 'askdjhf872435',
            userId: 'mark.langer@autoixpert.de',
            imageId: '/api/v0/users/2/profilePicture',
            description: 'Mark Langer hat dich zu autoixpert eingeladen.',
            createdAt: '2016-10-06T10:15:00',
            unread: true,
        },
        {
            _id: 'askdjhf872435',
            userId: 'mark.langer@autoixpert.de',
            imageId: '/images/mock-data/profilbild-andi.png',
            description: 'Andreas Schliefer hat das Gutachten XYZ abgeschlossen.',
            createdAt: '2016-10-05T11:55:00',
            unread: false,
        },
    ];
    notifications: Notification[] = [...this.mockNotifications];
    */
    notifications: Notification[] = [];

    public getNotifications(): Notification[] {
        return this.notifications;
    }

    public addNotification(notification: Notification) {
        this.notifications.unshift(notification);
    }
}

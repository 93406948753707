<div class="print-settings-value-card">
    <!--********** Corrections target **********-->
    <div
        class="corrections-target-selector"
        *ngIf="correctionsSum"
        [ngClass]="{
            selected: isCorrectionsTarget,
            'corrections-increase-value': correctionsSum > 0,
            'corrections-decrease-value': correctionsSum < 0,
        }"
        (click)="selectThisValueAsCorrectionsTarget(); anyValueChange.emit()"
        matTooltip="Die Korrekturen werden auf diesen Wert angewendet.{{
            '\n\n'
        }}Falls der andere Wert aktiv ist, wird er automatisch über die Marge berechnet.">
        <mat-icon>arrow_cool_down</mat-icon>
        Korrekturen
    </div>

    <!--********** Heading **********-->
    <h3>
        <mat-icon
            *ngIf="isOverwritten"
            (click)="isOverwrittenChange.emit(false); anyValueChange.emit()"
            matTooltip="Dieser Preis wurde manuell geändert und wird nicht automatisch aktualisiert.{{
                '\n\n'
            }}Du musst selbst sicherstellen, dass Brutto- und Nettowerte zueinander passen.{{
                '\n\n'
            }}Klicke, um wieder automatisch berechnen zu lassen."
            class="value-overwritten-icon small-icon cursor-pointer warning">
            keep
        </mat-icon>
        <span>{{ heading }}</span>
    </h3>

    <!--********** Activator **********-->
    <mat-slide-toggle
        class="is-active-toggle"
        [(ngModel)]="isActive"
        [disabled]="disabled"
        (ngModelChange)="isActiveChange.emit(isActive); anyValueChange.emit()"></mat-slide-toggle>

    <!--********** Settings & Values **********-->
    <div class="settings">
        <!--********** Value name **********-->
        <div class="settings-row">
            <span class="settings-row-label label">Bezeichnung</span>
            <mat-select
                [ngModel]="vehicleValueType"
                (selectionChange)="vehicleValueTypeChange.emit($event.value); anyValueChange.emit()"
                [disabled]="disabled">
                <mat-option value="dealerPurchase" matTooltip="Händler-Einkaufspreis">
                    {{ translateVehicleValueType('dealerPurchase') }}
                </mat-option>
                <mat-option value="dealerSales" matTooltip="Händler-Verkaufspreis">
                    {{ translateVehicleValueType('dealerSales') }}
                </mat-option>
                <mat-option value="marketValue">
                    {{ translateVehicleValueType('marketValue') }}
                </mat-option>
                <mat-option value="residualValue">
                    {{ translateVehicleValueType('residualValue') }}
                </mat-option>
                <mat-option matTooltip="auch: Wiederaufbauwert" value="restorationValue">
                    {{ translateVehicleValueType('restorationValue') }}
                </mat-option>
                <mat-option value="replacementValue" matTooltip="Wiederbeschaffungswert">
                    {{ translateVehicleValueType('replacementValue') }}
                </mat-option>
                <mat-option value="presentValue">
                    {{ translateVehicleValueType('presentValue') }}
                </mat-option>
            </mat-select>
        </div>

        <!--********** VAT deductible (purchase price only) **********-->
        <div *ngIf="showVatDeductible" class="settings-row">
            <div class="settings-row-label label">
                <span *ngIf="isVatDeductible">MwSt. ausweisbar</span>
                <span *ngIf="!isVatDeductible">MwSt. nicht ausweisbar</span>
                <span
                    class="help-indicator"
                    [matTooltip]="
                        isVatDeductible
                            ? 'Bei einem vorsteuerabzugsberechtigten Auftraggeber wird die Mehrwertsteuer ausgewiesen. Du kannst im Reiter &quot;Kunde&quot; einstellen, ob der Auftraggeber vorsteuerabzugsberechtigt ist.'
                            : 'Die Mehrwertsteuer kann nur ausgewiesen werden, wenn der Auftraggeber vorsteuerabzugsberechtigt ist. Das kannst du im Reiter &quot;Kunde&quot; einstellen.'
                    ">
                    ?
                </span>
            </div>
            <mat-icon *ngIf="isVatDeductible" class="small-icon">check</mat-icon>
            <mat-icon *ngIf="!isVatDeductible" class="small-icon">close</mat-icon>
        </div>

        <!--********** Taxation type (sales price only) **********-->
        <div *ngIf="showTaxationType" class="settings-row">
            <span class="settings-row-label label">Besteuerung</span>
            <mat-select
                [ngModel]="taxationType"
                (selectionChange)="taxationTypeChange.emit($event.value); anyValueChange.emit()"
                [disabled]="disabled"
                placeholder="Steuersatz"
                matTooltip="Im Fahrzeugwert enthaltene Mehrwertsteuer">
                <mat-select-trigger [ngSwitch]="taxationType">
                    <span *ngSwitchCase="'full'">Regelsatz</span>
                    <span *ngSwitchCase="'full_16'">16%</span>
                    <span *ngSwitchCase="'margin'">Differenz - 2,4%</span>
                    <span *ngSwitchCase="'margin_reduced'">Differenz - 2%</span>
                    <span *ngSwitchCase="'neutral'">Neutral</span>
                </mat-select-trigger>
                <mat-option
                    value="full"
                    matTooltip="Neue oder junge Fahrzeuge, für die überwiegend MwSt. ausgewiesen werden kann (Verkäufer sind Hersteller, Händler, Gewerbetreibende).">
                    Regelsatz - 19 %
                </mat-option>
                <mat-option
                    value="margin"
                    matTooltip="Mittelalte Fahrzeuge, die überwiegend im Gebrauchtwagenhandel verfügbar sind. Typisches Fahrzeugalter: 3 bis 10 Jahre.">
                    Differenz - 2,4 %
                </mat-option>
                <mat-option
                    value="neutral"
                    matTooltip="Ältere Fahrzeuge, die überwiegend auf dem Privatmarkt gehandelt werden. MwSt. kann nicht ausgewiesen werden.">
                    Neutral - 0 %
                </mat-option>
            </mat-select>
        </div>

        <!--********** Net Value **********-->
        <div class="settings-row">
            <span class="settings-row-label label">Netto</span>
            <mat-form-field
                class="without-hint-spacing without-title-spacing"
                [matTooltip]="
                    netValue
                        ? ''
                        : 'Nettowert nicht ermittelt. Einige Anbieter (z. B. Marktwertanalyseportale oder Audatexbewertung) stellen nicht immer einen Nettowert bereit. Du kannst den Wert manuell einfügen.'
                "
                [class.no-underline]="!isEditingNetValue">
                <input
                    #netValueInput
                    matInput
                    number-input
                    [(number)]="netValue"
                    (numberChange)="netValueChange.emit(netValue); anyValueChange.emit()"
                    [disabled]="disabled || !isEditingNetValue"
                    [minimumFractionDigits]="showDecimalPlaces ? 2 : 0"
                    [maximumFractionDigits]="showDecimalPlaces ? 2 : 0" />
                <span matSuffix>€</span>
            </mat-form-field>
            <mat-icon *ngIf="!disabled" class="toolbar-icon small-icon" (click)="toggleEditingNetValue()">
                {{ isEditingNetValue ? 'check' : 'edit' }}
            </mat-icon>
        </div>

        <!--********** Gross Value **********-->
        <div class="settings-row">
            <span class="settings-row-label label">Brutto</span>
            <mat-form-field
                class="without-hint-spacing without-title-spacing"
                [class.no-underline]="!isEditingGrossValue">
                <input
                    #grossValueInput
                    matInput
                    number-input
                    [(number)]="grossValue"
                    (numberChange)="grossValueChange.emit(grossValue); anyValueChange.emit()"
                    [disabled]="disabled || !isEditingGrossValue"
                    [minimumFractionDigits]="showDecimalPlaces ? 2 : 0"
                    [maximumFractionDigits]="showDecimalPlaces ? 2 : 0" />
                <span matSuffix>€</span>
            </mat-form-field>
            <mat-icon *ngIf="!disabled" class="toolbar-icon small-icon" (click)="toggleEditingGrossValue()">
                {{ isEditingGrossValue ? 'check' : 'edit' }}
            </mat-icon>
        </div>
    </div>
</div>

import { getDefaultDocumentOrderByTypeAndCommunicationRecipientRole } from '@autoixpert/lib/teams/preferences/get-default-document-order-by-type-and-communication-recipient-role';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { CommunicationRecipient } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { Report } from '@autoixpert/models/reports/report';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';
import { Team } from '@autoixpert/models/teams/team';
import { CurrentAndDefaultDocumentOrder } from './insert-document';

/**
 * Create pairs of the current document order and default document order (team preferences) for each involved party.
 *
 * The default order defines after which document a new document should be inserted, even if the user manually rearranged
 * the document order in the report.
 */
export function getCurrentAndDefaultDocumentOrderForCommunicationRecipient({
    report,
    team,
    documentGroup = 'report',
    communicationRecipient,
}: {
    report: Report;
    team: Team;
    documentGroup: 'report' | 'repairConfirmation';
    communicationRecipient: CommunicationRecipient;
}): CurrentAndDefaultDocumentOrder {
    let defaultDocumentOrder: DefaultDocumentOrder;
    try {
        defaultDocumentOrder = getDefaultDocumentOrderByTypeAndCommunicationRecipientRole({
            team,
            defaultDocumentOrderGroupType: documentGroup === 'repairConfirmation' ? 'repairConfirmation' : report.type,
            communicationRecipientRole: communicationRecipient.role,
        });
    } catch (error) {
        throw new AxError({
            code: 'GETTING_DEFAULT_DOCUMENT_ORDER_FAILED',
            message: 'The default document order could not be retrieved when adding a document to a report.',
            data: {
                reportType: report.type,
                communicationRecipientRole: communicationRecipient.role,
            },
            error,
        });
    }

    const documentOrders: DocumentOrder[] =
        documentGroup === 'repairConfirmation' ? report.repairConfirmation.documentOrders : report.documentOrders;

    let documentOrder: DocumentOrder;
    try {
        documentOrder = documentOrders.find(
            (documentOrder) => documentOrder.recipientRole === communicationRecipient.role,
        );
    } catch (error) {
        throw new AxError({
            code: 'FINDING_REPORT_DOCUMENT_ORDER_FAILED',
            message: 'The document order for the given involved party role could not be found in the report.',
            data: {
                communicationRecipientRole: communicationRecipient.role,
            },
            error,
        });
    }
    return {
        documentOrder,
        defaultDocumentOrder,
    };
}

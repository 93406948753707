import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getAfzzertErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        NO_AFZZERT_CERTIFICATE_FOUND: {
            title: 'Zertifikat nicht gefunden',
            body: 'Bitte stelle das Zertifikat über die <a href="https://www.afz-zert.de" target="_blank" rel="noopener">Afz-Zert.-Website</a> fertig.<br><br>Sollte das nicht funktionieren, lösche das Zertifikat hier und erstelle es neu.',
            partnerLogo: 'afzzert',
        },
        INVALID_AFZZERT_CREDENTIALS: {
            title: 'Zugangsdaten ungültig',
            body: 'Bitte prüfe deine Zugangsdaten in den <a href="/Einstellungen#afzzert-container">Einstellungen</a>.',
            partnerLogo: 'afzzert',
        },
        INVALID_AFZZERT_INPUT_PARAMETERS: (axError) => {
            const errorData: AfzzertAxErrorData = axError.data;

            const errorMessages = Object.entries(errorData.afzzertErrorData.params).map(
                ([parameterName, errorMessage]) => {
                    return `${parameterName}: ${errorMessage}`;
                },
            );

            return {
                title: 'Afz-Zert. hat Eingabe abgelehnt',
                body: `Bitte überprüfe die Eingaben und versuche es erneut.<br><br><b>Meldungen von Afz-Zert.:</b><br>- ${errorMessages.join(
                    '<br> - ',
                )}`,
                partnerLogo: 'afzzert',
            };
        },
    };
}

interface AfzzertAxErrorData {
    afzzertErrorCode: string;
    afzzertErrorMessage: string;
    afzzertErrorData: {
        status: number; // 400,
        /**
         * Example:
         * {
         *     "vehicle_registration_date": "The first registration date cannot be before 1886. Or do you have the worlds first car? Then contact us immediately!",
         *     "certificate_date": "The first registration date cannot be before 1886. Or do you have the worlds first car? Then contact us immediately!"
         * }
         */
        params: Record<string, string>;
        details: any;
    };
}

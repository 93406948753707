import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import moment from 'moment';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { toIsoDate, todayIso } from '@autoixpert/lib/date/iso-date';
import { createInvoice } from '@autoixpert/lib/invoices/create-invoice';
import { createInvoiceFromReport } from '@autoixpert/lib/invoices/create-invoice-from-report';
import { setPaymentStatus } from '@autoixpert/lib/invoices/set-payment-status';
import { round } from '@autoixpert/lib/numbers/round';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { InvoiceCoreData } from '@autoixpert/models/invoices/invoice-core-data';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Payment } from '@autoixpert/models/invoices/payment';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { currencyFormatterEuro } from '../libraries/currency-formatter-euro';
import { LiveSyncWithInMemoryCacheServiceBase } from '../libraries/database/live-sync-with-in-memory-cache.service-base';
import { get$SearchMongoQueryInvoices } from '../libraries/database/search-query-translators/get-$search-mongo-query-invoices';
import { FeathersQuery } from '../types/feathers-query';
import { ApiErrorService } from './api-error.service';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FrontendLogService } from './frontend-log.service';
import { invoiceRecordMigrations, invoiceServiceObjectStoreAndIndexMigrations } from './invoice.service-migrations';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { ReportService } from './report.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class InvoiceService extends LiveSyncWithInMemoryCacheServiceBase<Invoice> {
    constructor(
        private loggedInUserService: LoggedInUserService,
        protected httpClient: HttpClient,
        private apiErrorService: ApiErrorService,
        protected networkStatusService: NetworkStatusService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected frontendLogService: FrontendLogService,
        protected feathersSocketioService: FeathersSocketioService,
        protected reportService: ReportService,
    ) {
        super({
            serviceName: 'invoice',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            feathersSocketioClient: feathersSocketioService,
            recordMigrations: invoiceRecordMigrations,
            objectStoreAndIndexMigrations: invoiceServiceObjectStoreAndIndexMigrations,
            get$SearchMongoQuery: get$SearchMongoQueryInvoices,
        });
    }

    /**
     * Return invoices from the server or indexedDB.
     * Use a pagination token (searchAfterPaginationToken) to get the next page of results when online.
     * Use skip to get the next page of results when offline.
     */
    public async getInvoicesFromServerOrIndexedDB({
        searchAfterPaginationToken,
        skip,
        limit = 10,
        query,
        searchTerm,
    }: {
        searchAfterPaginationToken?: string;
        skip?: number;
        limit: number;
        query: FeathersQuery;
        searchTerm?: string;
    }): Promise<{
        records: Invoice[];
        lastPaginationToken?: string;
    }> {
        if (searchTerm && searchTerm.length >= 3) {
            query.$search = searchTerm;
        }

        query.$limit = limit;
        if (searchAfterPaginationToken) {
            query.$searchAfterPaginationToken = searchAfterPaginationToken;
        }
        if (skip) {
            query.$skip = skip;
        }

        return await this.findWithPaginationToken(query);
    }

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public generateNewInvoice(): Invoice {
        const user: User = this.loggedInUserService.getUser();
        const team: Team = this.loggedInUserService.getTeam();

        const newInvoice = createInvoice({
            user: this.loggedInUserService.getUser(),
            team: this.loggedInUserService.getTeam(),
            invoiceData: {
                date: todayIso(),
                vatRate: team.invoicing.vatRate,
                vatExemptionReason: team.invoicing.vatRate === 0 ? 'smallBusiness' : undefined,
                officeLocationId: user.defaultOfficeLocationId,
            },
        });

        // Create at least one empty line item so that the user may start typing right away.
        const newLineItem = new LineItem();
        newLineItem.position = 1;
        newInvoice.lineItems.push(newLineItem);

        // Bank accounts
        newInvoice.bankAccount = team.invoicing.bankAccount;
        if (team.invoicing.secondBankAccount?.iban) {
            newInvoice.secondBankAccount = team.invoicing.secondBankAccount;
        }

        return newInvoice;
    }

    /**
     * Create an invoice based on a Report. Data is mostly taken from the feeCalculation and claimant objects.
     */
    public createInvoiceFromReport = createInvoiceFromReport;

    /**
     * After generating a cancellation invoice, you should create a payment on the original invoice
     * marking the canceled amount as canceled.
     * If omitted, the original invoice doesn't know it can be treated as fully paid at a lesser payment sum.
     */
    public async addPaymentToInvoice({
        targetInvoice,
        newPayment,
    }: {
        targetInvoice: Invoice;
        newPayment: Payment;
    }): Promise<{ updatedOriginalInvoice: Invoice }> {
        //*****************************************************************************
        //  Add Cancellation Payment to Original Invoice
        //****************************************************************************/
        let updatedOriginalInvoice: Invoice;

        newPayment.date ||= todayIso();
        newPayment.createdBy ||= this.loggedInUserService.getUser()._id;

        targetInvoice.payments.push(newPayment);

        setPaymentStatus(targetInvoice);

        try {
            updatedOriginalInvoice = await this.put(targetInvoice, { waitForServer: true });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Eintragung der Teilgutschrift auf Original gescheitert`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Add Cancellation Payment to Original Invoice
        /////////////////////////////////////////////////////////////////////////////*/

        return {
            updatedOriginalInvoice,
        };
    }

    /**
     * Delete an invoice from the server, checks for canceledInvoice and updates references.
     * Updates localInvoices if provided.
     */
    public async safeDelete(invoice: Invoice, localInvoices?: Invoice[]): Promise<void> {
        // Clear cancelled status in case of cancellation invoices
        if (invoice.rootInvoiceId) {
            // Clear the status on the other invoice before deleting this invoice
            let rootInvoice: Invoice;

            try {
                rootInvoice = await this.get(invoice.rootInvoiceId);
            } catch (error) {
                if (error.code === 'RESOURCE_NOT_FOUND') {
                    console.log(
                        `The invoice that was cancelled through invoice ID "${invoice._id}" is already gone on the server. Since the target state is already reached, do not do anything else.`,
                    );
                } else {
                    throw error;
                }
            }

            if (rootInvoice) {
                //*****************************************************************************
                //  Update Latest Record from IndexedDB
                //****************************************************************************/
                // Remove pointer to the deleted invoice from the root invoice.
                removeFromArray(invoice._id, rootInvoice.idsOfCancellationInvoices);

                // Remove cancellation payment.
                const partialCancellationPayment: Payment = rootInvoice.payments.find(
                    (payment) =>
                        payment.type === 'partialCancellation' && payment.cancellationInvoiceId === invoice._id,
                );
                removeFromArray(partialCancellationPayment, rootInvoice.payments);

                rootInvoice.isFullyCanceled = false;

                try {
                    await this.put(rootInvoice, { waitForServer: true });
                } catch (error) {
                    // If the canceled invoice does not exist anymore, that's fine
                    if (error.statusCode === 404) {
                        // Do nothing.
                    } else {
                        throw error;
                    }
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Update Latest Record from IndexedDB
                /////////////////////////////////////////////////////////////////////////////*/

                //*****************************************************************************
                //  Update Local Record
                //****************************************************************************/
                // Update the cancelled invoice in the local component.
                const rootInvoice_local: Invoice = localInvoices?.find((invoice) => invoice._id === rootInvoice._id);
                if (rootInvoice_local) {
                    removeFromArray(invoice._id, rootInvoice_local.idsOfCancellationInvoices);

                    // Remove cancellation payment.
                    const partialCancellationPayment_local: Payment = rootInvoice_local.payments.find(
                        (payment) =>
                            payment.type === 'partialCancellation' && payment.cancellationInvoiceId === invoice._id,
                    );
                    removeFromArray(partialCancellationPayment_local, rootInvoice_local.payments);
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Update Local Record
                /////////////////////////////////////////////////////////////////////////////*/
            }
        }

        // Delete current invoice
        try {
            await this.delete(invoice._id, {
                /**
                 * If online, wait for the server so that when deleting an invoice from the invoice editor and then returning back to the invoice list,
                 * which queries the server for all existing invoices, the invoice has already been deleted on the server.
                 *
                 * When offline, there's no querying the server in the invoice list. Hence, we must not wait for the server.
                 */
                waitForServer: this.networkStatusService.isOnline(),
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    INVOICE_LOCKED: {
                        title: 'Gebuchte Rechnung',
                        body: 'Gebuchte Rechnungen können nur von einem Administrator deines Teams gelöscht werden.',
                    },
                },
                defaultHandler: {
                    title: 'Rechnung nicht gelöscht',
                    body: 'Ein Fehler ist aufgetreten',
                },
            });
        }
    }

    public getInvoiceCoreData(invoice: Invoice): InvoiceCoreData {
        // Make a copy to not create any unintended connections between objects and arrays of the source and the clone
        const invoiceCopy: Invoice = JSON.parse(JSON.stringify(invoice));

        return {
            intro: invoiceCopy.intro,
            type: invoiceCopy.type,
            subject: invoiceCopy.subject,
            recipient: invoiceCopy.recipient,
            currencyCode: invoiceCopy.currencyCode,
            euDelivery: invoiceCopy.euDelivery,
            officeLocationId: invoiceCopy.officeLocationId,
            date: toIsoDate(invoiceCopy.date),
            dateOfSupply: toIsoDate(invoiceCopy.dateOfSupply),
            daysUntilDue: invoiceCopy.daysUntilDue,
            dueDate: invoiceCopy.dueDate ? toIsoDate(invoiceCopy.dueDate) : null,
            lineItems: invoiceCopy.lineItems,
            totalNet: invoiceCopy.totalNet,
            totalGross: invoiceCopy.totalGross,
            vatRate: invoiceCopy.vatRate,
            vatExemptionReason: invoiceCopy.vatExemptionReason,
            reportIds: invoiceCopy.reportIds,
            reportsData: invoiceCopy.reportsData,
            factoringEnabled: invoiceCopy.factoringEnabled,
        };
    }

    /**
     * Determine if the invoice core data (line items, total, etc.) are equal. Return the overall comparison result and a list of differences.
     * @param invoiceA
     * @param invoiceB
     */
    public areInvoicesEqual(
        invoiceA: Invoice,
        invoiceB: Invoice,
    ): { result: boolean; differences: InvoiceDifference[] } {
        const invoiceCoreA: InvoiceCoreData = this.getInvoiceCoreData(invoiceA);
        const invoiceCoreB: InvoiceCoreData = this.getInvoiceCoreData(invoiceB);

        //*****************************************************************************
        //  Compare all non-object properties
        //****************************************************************************/
        const primitiveDifferences: InvoiceDifference[] = [];
        const primitiveKeysToCompare: (keyof InvoiceCoreData)[] = ['daysUntilDue', 'factoringEnabled'];
        primitiveKeysToCompare.forEach((key) => {
            // The values are equal if they are both undefined or null or if they are strictly equal. If these conditions are unmet, they must be unequal.
            if (
                !((invoiceCoreA[key] == null && invoiceCoreB[key] == null) || invoiceCoreA[key] === invoiceCoreB[key])
            ) {
                primitiveDifferences.push({
                    key,
                    valueA: invoiceCoreA[key],
                    valueB: invoiceCoreB[key],
                });
            }
        });

        /**
         * Compare dates not by the ISO string but by their human-readable date, e.g. '01.12.2021'.
         */
        const datesToCompare: (keyof InvoiceCoreData)[] = ['date'];
        datesToCompare.forEach((key) => {
            const dateA: string = invoiceCoreA[key] as string;
            const dateB: string = invoiceCoreB[key] as string;

            // The values are equal if they are both undefined or null or if they are strictly equal. If these conditions are unmet, they must be unequal.
            if (!((invoiceCoreA[key] == null && invoiceCoreB[key] == null) || moment(dateA).isSame(dateB, 'date'))) {
                primitiveDifferences.push({
                    key,
                    valueA: invoiceCoreA[key],
                    valueB: invoiceCoreB[key],
                });
            }
        });

        const totalsToCompare: (keyof InvoiceCoreData)[] = ['totalGross', 'totalNet'];
        totalsToCompare.forEach((key) => {
            const totalA: number = invoiceCoreA[key] as number;
            const totalB: number = invoiceCoreB[key] as number;

            // They're unequal unless they're both nullish or equal.
            if (!((totalA == null && totalB == null) || round(totalA) === round(totalB))) {
                primitiveDifferences.push({
                    key,
                    valueA: invoiceCoreA[key],
                    valueB: invoiceCoreB[key],
                });
            }
        });

        const primitivesAreEqual: boolean = primitiveDifferences.length === 0;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Compare all non-object properties
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Compare line items
        //****************************************************************************/
        const lineItemDifferences: InvoiceDifference[] = [];
        // Number of line items is different
        if (invoiceCoreA.lineItems.length !== invoiceCoreB.lineItems.length) {
            lineItemDifferences.push({
                key: 'numberOfLineItems',
                valueA: invoiceCoreA.lineItems,
                valueB: invoiceCoreB.lineItems,
            });
        }
        // Number of line items is the same
        else {
            invoiceCoreA.lineItems.forEach((lineItemA, index: number) => {
                const lineItemB: LineItem = invoiceCoreB.lineItems[index];

                if (
                    round(lineItemA.unitPrice) !== round(lineItemB.unitPrice) ||
                    lineItemA.active !== lineItemB.active ||
                    // || lineItemA.description !== lineItemB.description // Don't compare the description because that rarely happens in production (Who would overbook an invoice with a new invoice number to fix a typo?) but this triggers after the refactoring of create-invoice-from-report.ts.
                    // || lineItemA.position !== lineItemB.position // The position is not relevant for re-booking an invoice
                    lineItemA.quantity !== lineItemB.quantity
                ) {
                    lineItemDifferences.push({
                        key: 'lineItemContent',
                        valueA: `${lineItemA.description}: ${lineItemA.quantity} * ${currencyFormatterEuro(
                            lineItemA.unitPrice,
                        )}`,
                        valueB: `${lineItemB.description}: ${lineItemB.quantity} * ${currencyFormatterEuro(
                            lineItemB.unitPrice,
                        )}`,
                    });
                }
            });
        }
        const lineItemsAreEqual: boolean = lineItemDifferences.length === 0;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Compare line items
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Compare recipients
        //****************************************************************************/
        const contactPersonA = invoiceCoreA.recipient.contactPerson;
        const contactPersonB = invoiceCoreB.recipient.contactPerson;
        const recipientDifferences: InvoiceDifference[] = [];
        const recipientsAreEqual: boolean =
            invoiceCoreA.recipient &&
            invoiceCoreB.recipient &&
            invoiceCoreA.recipient.role === invoiceCoreB.recipient.role &&
            contactPersonA.organization === contactPersonB.organization &&
            contactPersonA.salutation === contactPersonB.salutation &&
            contactPersonA.firstName === contactPersonB.firstName &&
            contactPersonA.lastName === contactPersonB.lastName &&
            contactPersonA.streetAndHouseNumberOrLockbox === contactPersonB.streetAndHouseNumberOrLockbox &&
            contactPersonA.zip === contactPersonB.zip &&
            contactPersonA.city === contactPersonB.city;
        if (!recipientsAreEqual) {
            recipientDifferences.push({
                key: 'recipientsDiffer',
                valueA: `${contactPersonA.organization} ${contactPersonA.firstName} ${contactPersonA.lastName}`.replace(
                    '  ',
                    ' ',
                ),
                valueB: `${contactPersonB.organization} ${contactPersonB.firstName} ${contactPersonB.lastName}`.replace(
                    '  ',
                    ' ',
                ),
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Compare recipients
        /////////////////////////////////////////////////////////////////////////////*/

        return {
            result: primitivesAreEqual && lineItemsAreEqual && recipientsAreEqual,
            differences: [...primitiveDifferences, ...lineItemDifferences, ...recipientDifferences],
        };
    }

    public isDeletingInvoiceAllowed(invoice: Invoice, user: User, team: Team): boolean {
        // If this invoice is not yet booked/locked, every team member may delete it.
        if (!invoice.lockedAt) {
            return true;
        }

        // Is the current user an admin? If so, allow deleting a booked invoice.
        return isAdmin(user._id, team);
    }

    /**
     * Returns an array of all invoices with the given number.
     * This is useful to check, if an invoice number was already assigned.
     */
    public async findByInvoiceNumber({
        invoiceNumber,
        invoiceDate,
    }: {
        invoiceNumber: Invoice['number'];
        invoiceDate: Invoice['date'];
    }): Promise<Invoice[]> {
        return await this.find({
            number: invoiceNumber,
            // Limit equality check to the fiscal year of the invoice's date.
            date: {
                $gt: moment(invoiceDate).startOf('year').format(),
                $lt: moment(invoiceDate).endOf('year').format(),
            },
        }).toPromise();
    }

    public async checkInvoiceNumber({
        invoiceNumber,
        invoiceDate,
    }: {
        invoiceNumber: Invoice['number'];
        invoiceDate: Invoice['date'];
    }): Promise<CheckInvoiceNumberResponse> {
        return this.httpClient
            .post<CheckInvoiceNumberResponse>(
                `/api/v0/invoiceNumberAndAccessCheck`,
                { invoiceNumber },
                {
                    params: { invoiceDate },
                },
            )
            .toPromise();
    }

    public async getReportInvoice(report: Report): Promise<Invoice> {
        if (!report.feeCalculation.invoiceParameters._id) return;

        return (await this.find({ _id: report.feeCalculation.invoiceParameters._id }).toPromise())[0];
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Preview
    //****************************************************************************/
    public async getInvoicePreview(invoice: Invoice) {
        return await this.httpClient
            .post(`/api/v0/invoicePreview?format=pdf`, invoice, {
                responseType: 'blob',
                observe: 'response',
            })
            .toPromise();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Preview
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Electronic Invoice
    //****************************************************************************/
    /**
     * Retrieves an XRechnung document for the specified invoice ID.
     */
    public async getXrechnung(invoiceId: Invoice['_id']): Promise<Blob> {
        return await this.httpClient
            .get(`/api/v0/invoices/${invoiceId}/xrechnung`, {
                responseType: 'blob',
            })
            .toPromise();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Electronic Invoice
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface InvoiceDifference {
    key: string;
    valueA: any;
    valueB: any;
}

export class CheckInvoiceNumberResponse {
    invoiceExists: boolean;
    userMayAccessInvoice: boolean;
    invoiceReportIds?: Array<Report['_id']>;
}

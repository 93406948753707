import { PlaceholderValues } from '../lib/placeholder-values/get-placeholder-values';
import { DocumentMetadata } from '../models/documents/document-metadata';
import { Report } from '../models/reports/report';

export abstract class AbstractTemplatePlaceholderValuesService {
    abstract getReportValues({
        reportId,
        letterDocument,
    }: {
        reportId: Report['_id'];
        letterDocument?: DocumentMetadata;
    }): Promise<PlaceholderValues>;
}

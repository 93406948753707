<div class="dialog-overlay" @dialogEnterAndLeave (click)="handleOverlayClick($event)">
    <div class="dialog-container" [ngClass]="{ 'dialog-financial-institution': !!selectingFinancialInstitution }">
        <!-- Back button -->
        <mat-icon
            *ngIf="selectingFinancialInstitution === 'CATEGORY' || selectingFinancialInstitution === 'COUNTRY'"
            class="dialog-back-icon"
            (click)="navigateBack()"
            (mousedown)="$event.preventDefault()">
            arrow_back
        </mat-icon>

        <!-- Close button -->
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <!-- Headline -->
        <h2>
            <ng-container [ngSwitch]="selectingFinancialInstitution">
                <ng-container *ngSwitchDefault>Bankkonten</ng-container>
                <ng-container *ngSwitchCase="'OVERVIEW'">Bank auswählen</ng-container>
                <ng-container *ngSwitchCase="'CATEGORY'">Bank auswählen</ng-container>
                <ng-container *ngSwitchCase="'BANK_ACCOUNTS'">Bankkonten auswählen</ng-container>
                <ng-container *ngSwitchCase="'COUNTRY'">Wähle das Land deiner Bank</ng-container>
            </ng-container>
        </h2>

        <!--============================================-->
        <!-- Bank Accounts -->
        <!--============================================-->
        <section
            id="bank-accounts-section"
            *ngIf="!bankAccountsPending && !selectingFinancialInstitution && bankAccounts.length">
            <!--********** Bank Account Record **********-->
            <div id="bank-account-list">
                <div class="bank-account-record record" *ngFor="let bankAccount of bankAccounts">
                    <div
                        class="bank-account-icon-container flex-center-content margin-right-20"
                        matTooltip="Bankkonto verbunden">
                        <mat-icon>account_balance</mat-icon>
                    </div>

                    <div class="bank-account-name-and-details">
                        <!--********** Title **********-->
                        <div
                            class="semibold text-overflow-ellipsis"
                            *ngIf="!bankAccountTitlesInEditMode.has(bankAccount)"
                            [matTooltip]="bankAccount.alias || bankAccount.iban">
                            {{ bankAccount.alias || bankAccount.iban }}
                        </div>

                        <mat-form-field *ngIf="bankAccountTitlesInEditMode.has(bankAccount)">
                            <input
                                matInput
                                placeholder="Kontoname"
                                [(ngModel)]="bankAccount.alias"
                                (change)="saveBankAccount(bankAccount)" />
                            <span matSuffix>
                                <mat-icon
                                    class="input-toolbar-icon"
                                    (click)="leaveEditMode(bankAccount)"
                                    matTooltip="Bearbeitung beenden">
                                    check
                                </mat-icon>
                            </span>
                        </mat-form-field>

                        <!--********** Account Details **********-->
                        <div class="label bank-account-details" [matTooltip]="getBankAccountDetails(bankAccount)">
                            <!--********** Bank Name **********-->
                            {{ getBankAccountDetails(bankAccount) }}
                        </div>

                        <!--********** Connection Date **********-->
                        <div class="label" matTooltip="Verbunden seit">
                            seit {{ bankAccount.createdAt | moment: 'dd, DD.MM.YYYY - HH:mm [Uhr]' }}
                        </div>
                    </div>
                    <div>
                        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="bankAccountSubmenu">more_vert</mat-icon>
                        <mat-menu #bankAccountSubmenu>
                            <a mat-menu-item class="menu-item-with-icon" (click)="renameBankAccount(bankAccount)">
                                <mat-icon>edit</mat-icon>
                                Umbenennen
                            </a>
                            <a mat-menu-item class="menu-item-with-icon" (click)="disconnectBankAccount(bankAccount)">
                                <mat-icon>link_off</mat-icon>
                                Trennen
                            </a>
                        </mat-menu>
                    </div>
                </div>
            </div>

            <!--********** Add Bank Account **********-->
            <div id="new-bank-account-button-container" class="text-align-center margin-bottom-10">
                <button class="flat" (click)="startFinancialInstitutionSelection()">+ Neues Bankkonto</button>
            </div>

            <div class="text-align-center">
                <button (click)="closeDialog()">Schließen</button>
            </div>
        </section>
        <!--============================================-->
        <!-- END Bank Accounts -->
        <!--============================================-->

        <!--============================================-->
        <!-- Setup Info -->
        <!--============================================-->
        <section
            id="setup-info-section"
            *ngIf="!bankAccountsPending && !selectingFinancialInstitution && !bankAccounts.length">
            <!--********** Intro **********-->
            <div id="setup-intro" class="text-align-center">
                Erlaube autoiXpert, Zahlungseingänge auf deinem Bankkonto
                <br />
                mit deinen Rechnungen abzugleichen.
            </div>

            <!--============================================-->
            <!-- Benefits -->
            <!--============================================-->
            <div id="benefits-row" class="text-align-center">
                <!--********** Bank Account Sync **********-->
                <div class="benefit-container">
                    <div class="benefit-icon-container">
                        <mat-icon class="benefit-icon">swap_horiz</mat-icon>
                    </div>
                    <div class="benefit-heading">Zahlungseingänge auslesen</div>
                    <div class="benefit-text label">Kann autoiXpert mithilfe unseres Partners GoCardless.</div>
                </div>

                <!--********** Readonly **********-->
                <div class="benefit-container">
                    <div class="benefit-icon-container">
                        <mat-icon class="benefit-icon">auto_fix_high</mat-icon>
                    </div>
                    <div class="benefit-heading">Rechnungen vorschlagen</div>
                    <div class="benefit-text label">Auf Basis des Verwendungszwecks. Darin steckt die Magie.</div>
                </div>

                <!--********** European Provider **********-->
                <div class="benefit-container">
                    <div class="benefit-icon-container">
                        <mat-icon class="benefit-icon">payments</mat-icon>
                    </div>
                    <div class="benefit-heading">Zahlungen erfassen</div>
                    <div class="benefit-text label">
                        Vorschlag annehmen, Zahlung automatisch eintragen lassen, fertig.
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Benefits -->
            <!--============================================-->

            <!--            &lt;!&ndash;============================================&ndash;&gt;-->
            <!--            &lt;!&ndash; Motivation &ndash;&gt;-->
            <!--            &lt;!&ndash;============================================&ndash;&gt;-->
            <!--            <div class="text-align-center margin-bottom-30">-->
            <!--                Worauf wartest du? Auf geht's!-->
            <!--            </div>-->
            <!--            &lt;!&ndash;============================================&ndash;&gt;-->
            <!--            &lt;!&ndash; END Motivation &ndash;&gt;-->
            <!--            &lt;!&ndash;============================================&ndash;&gt;-->

            <div class="text-align-center margin-bottom-10">
                <button (click)="startFinancialInstitutionSelection()">
                    <mat-icon class="button-icon">link</mat-icon>
                    Konto verbinden
                </button>
                <button
                    class="secondary"
                    (click)="
                        openVideo(
                            'Bankkonto verbinden',
                            'https://www.youtube.com/embed/RS_M8FuirqE?si=B_V9-nk9YKg8aFkb'
                        )
                    "
                    matTooltip="Video abspielen">
                    <mat-icon class="button-icon outlined">play_circle</mat-icon>
                    So funktioniert's
                </button>
            </div>

            <!--            <div class="label text-align-center">Du kannst Bankkonten jederzeit wieder trennen.</div>-->
        </section>
        <!--============================================-->
        <!-- END Setup Info -->
        <!--============================================-->

        <!--============================================-->
        <!-- Pending Section -->
        <!--============================================-->
        <section
            id="pending-section"
            class="flex-center-content"
            *ngIf="!selectingFinancialInstitution && bankAccountsPending">
            <div class="text-align-center">
                <img class="margin-bottom-20" src="/assets/images/icons/loading-indicator.svg" alt="" />
                <div>Lade Konten...</div>
            </div>
        </section>
        <!--============================================-->
        <!-- END Pending Section -->
        <!--============================================-->

        <!--============================================-->
        <!-- Financial Institutions Section -->
        <!--============================================-->
        <section id="financial-institutions-section" *ngIf="selectingFinancialInstitution">
            <!--********** Search row **********-->
            <div class="financial-institutions-search-row">
                <!-- Search input -->
                <mat-form-field
                    *ngIf="!['COUNTRY', 'BANK_ACCOUNTS'].includes(selectingFinancialInstitution)"
                    class="mat-block financial-institutions-search">
                    <input
                        matInput
                        [axAutofocus]="true"
                        placeholder="Suche nach deiner Bank"
                        [(ngModel)]="financialInstitutionsSearch"
                        (ngModelChange)="filterFinancialInstitutions()" />

                    <!-- Clear button -->
                    <mat-icon
                        matSuffix
                        *ngIf="financialInstitutionsSearch"
                        class="toolbar-icon financial-institutions-search-clear-icon"
                        (click)="clearFinancialInstitutionsSearch()"
                        (mousedown)="$event.preventDefault()">
                        close
                    </mat-icon>
                </mat-form-field>

                <!-- Country selector (only shown in overview) -->
                <div
                    *ngIf="selectingFinancialInstitution === 'OVERVIEW'"
                    class="financial-institution-country-selector">
                    <!-- Germany icon -->
                    <svg
                        *ngIf="selectingFinancialInstitutionCountry === 'DE'"
                        [matTooltip]="'Land auswählen'"
                        (click)="navigateToFinancialInstitutionCountrySelection()"
                        aria-hidden="true"
                        role="img"
                        width="20"
                        height="20"
                        viewBox="0 0 50 50">
                        <path
                            fill="#FFDA44"
                            d="M1.555 33.695C5.088 43.215 14.251 50 25 50c10.75 0 19.913-6.784 23.445-16.304L25 31.521 1.555 33.695Z"></path>
                        <path
                            fill="#000"
                            d="M25 0C14.251 0 5.088 6.784 1.555 16.304L25 18.478l23.445-2.174C44.913 6.784 35.75 0 25 0Z"></path>
                        <path
                            fill="#D80027"
                            d="M1.555 16.304A24.946 24.946 0 0 0 0 25c0 3.058.55 5.987 1.555 8.696h46.89A24.946 24.946 0 0 0 50 25c0-3.058-.55-5.987-1.555-8.696H1.555Z"></path>
                    </svg>

                    <!-- Austria icon -->
                    <svg
                        *ngIf="selectingFinancialInstitutionCountry === 'AT'"
                        [matTooltip]="'Land auswählen'"
                        (click)="navigateToFinancialInstitutionCountrySelection()"
                        aria-hidden="true"
                        role="img"
                        width="20"
                        height="20"
                        viewBox="0 0 50 50">
                        <path
                            fill="#F0F0F0"
                            d="M48.445 33.695A24.945 24.945 0 0 0 50 24.999c0-3.058-.55-5.987-1.555-8.695L25 14.13 1.555 16.304A24.947 24.947 0 0 0 0 24.999c0 3.058.55 5.988 1.555 8.696L25 35.869l23.445-2.174Z"></path>
                        <path
                            fill="#D80027"
                            d="M25 50c10.749 0 19.913-6.784 23.445-16.304H1.555C5.087 43.216 14.25 50 25 50ZM25 0C14.25 0 5.087 6.784 1.555 16.304h46.89C44.913 6.784 35.749 0 25 0Z"></path>
                    </svg>
                </div>
            </div>

            <!--********** Search **********-->
            <div
                *ngIf="!!financialInstitutionsSearch; else noSearch"
                class="financial-institutions-search"
                (mousedown)="$event.preventDefault()">
                <gocardless-financial-institutions-list
                    [financialInstitutions]="financialInstitutionsFiltered"
                    [financialInstitutionsLoading]="financialInstitutionsLoading"
                    (financialInstitutionSelected)="
                        handleFinancialInstitutionSelected($event.id)
                    "></gocardless-financial-institutions-list>
            </div>

            <ng-template #noSearch>
                <!--********** Overview **********-->
                <div
                    *ngIf="selectingFinancialInstitution === 'OVERVIEW'"
                    class="financial-institutions-overview"
                    (mousedown)="$event.preventDefault()">
                    <!-- Loading indicator -->
                    <div *ngIf="financialInstitutionsLoading" class="financial-institutions-loading" @fadeInAndOut>
                        <img src="/assets/images/icons/loading-indicator.svg" alt="" />
                    </div>

                    <!-- Financial institution groups -->
                    <div
                        *ngFor="
                            let financialInstitutionGroup of financialInstitutionsGroups;
                            trackBy: trackByFinancialInstitutionGroupId
                        "
                        class="financial-institutions-group"
                        (click)="handleFinancialInstitutionGroupClick(financialInstitutionGroup)">
                        <!-- Logo -->
                        <img
                            width="40"
                            height="40"
                            class="financial-institution-group-logo"
                            [src]="financialInstitutionGroup.logo"
                            alt="" />

                        <!-- Name -->
                        <div class="financial-institution-group-name">{{ financialInstitutionGroup.name }}</div>

                        <!-- Chevron right -->
                        <mat-icon class="financial-institution-group-chevron-right">chevron_right</mat-icon>
                    </div>
                </div>

                <!--********** Category **********-->
                <div
                    *ngIf="selectingFinancialInstitution === 'CATEGORY'"
                    class="financial-institution-categories"
                    (mousedown)="$event.preventDefault()">
                    <gocardless-financial-institutions-list
                        [financialInstitutions]="financialInstitutionsFiltered"
                        [financialInstitutionsLoading]="financialInstitutionsLoading"
                        (financialInstitutionSelected)="
                            handleFinancialInstitutionSelected($event.id)
                        "></gocardless-financial-institutions-list>
                </div>

                <!--********** Bank accounts selection **********-->
                <div
                    *ngIf="selectingFinancialInstitution === 'BANK_ACCOUNTS'"
                    class="financial-institution-bank-accounts">
                    <div
                        *ngFor="let bankAccount of bankAccountsToConnect; let last = last"
                        class="bank-account-record record"
                        [ngClass]="{ last: last }"
                        (click)="toggleBankAccountSelection(bankAccount)">
                        <div
                            class="bank-account-icon-container flex-center-content margin-right-20"
                            matTooltip="Bankkonto verbunden">
                            <mat-icon>account_balance</mat-icon>
                        </div>

                        <div class="bank-account-name-and-details">
                            <!--********** Title **********-->
                            <div
                                class="semibold text-overflow-ellipsis"
                                [matTooltip]="bankAccount.name || bankAccount.iban">
                                {{ bankAccount.name || bankAccount.iban }}
                            </div>

                            <!--********** Account Details **********-->
                            <div
                                class="label bank-account-details"
                                [matTooltip]="getGocardlessBankAccountDetails(bankAccount)">
                                <!--********** Bank Name **********-->
                                {{ getGocardlessBankAccountDetails(bankAccount) }}
                            </div>
                        </div>

                        <mat-checkbox
                            class="bank-account-selected"
                            [checked]="selectedBankAccountIds.includes(bankAccount.id)"
                            (click)="$event.stopPropagation()"
                            (change)="toggleBankAccountSelection(bankAccount)"></mat-checkbox>
                    </div>

                    <!-- Error messages-->
                    <div *ngIf="selectedBankAccountIds.length === 0" class="bank-accounts-error-message-container">
                        <span>Bitte wähle mindestens ein Bankkonto aus.</span>
                    </div>

                    <!-- Confirm selection -->
                    <div class="bank-accounts-button-row">
                        <button (click)="confirmBankAccountsSelection()">
                            <mat-icon class="button-icon">link</mat-icon>
                            Ausgewählte Bankkonten verbinden
                        </button>
                    </div>
                </div>

                <!--********** Country **********-->
                <mat-radio-group
                    *ngIf="selectingFinancialInstitution === 'COUNTRY'"
                    class="financial-institution-countries"
                    [(ngModel)]="selectingFinancialInstitutionCountry">
                    <!-- Germany -->
                    <div class="financial-institution-country" (click)="selectFinancialInstitutionCountry('DE')">
                        <svg aria-hidden="true" role="img" width="20" height="20" viewBox="0 0 50 50">
                            <path
                                fill="#FFDA44"
                                d="M1.555 33.695C5.088 43.215 14.251 50 25 50c10.75 0 19.913-6.784 23.445-16.304L25 31.521 1.555 33.695Z"></path>
                            <path
                                fill="#000"
                                d="M25 0C14.251 0 5.088 6.784 1.555 16.304L25 18.478l23.445-2.174C44.913 6.784 35.75 0 25 0Z"></path>
                            <path
                                fill="#D80027"
                                d="M1.555 16.304A24.946 24.946 0 0 0 0 25c0 3.058.55 5.987 1.555 8.696h46.89A24.946 24.946 0 0 0 50 25c0-3.058-.55-5.987-1.555-8.696H1.555Z"></path>
                        </svg>
                        <div class="country-label">Deutschland</div>
                        <mat-radio-button [value]="'DE'"></mat-radio-button>
                    </div>

                    <!-- Austria -->
                    <div class="financial-institution-country" (click)="selectFinancialInstitutionCountry('AT')">
                        <svg aria-hidden="true" role="img" width="20" height="20" viewBox="0 0 50 50">
                            <path
                                fill="#F0F0F0"
                                d="M48.445 33.695A24.945 24.945 0 0 0 50 24.999c0-3.058-.55-5.987-1.555-8.695L25 14.13 1.555 16.304A24.947 24.947 0 0 0 0 24.999c0 3.058.55 5.988 1.555 8.696L25 35.869l23.445-2.174Z"></path>
                            <path
                                fill="#D80027"
                                d="M25 50c10.749 0 19.913-6.784 23.445-16.304H1.555C5.087 43.216 14.25 50 25 50ZM25 0C14.25 0 5.087 6.784 1.555 16.304h46.89C44.913 6.784 35.749 0 25 0Z"></path>
                        </svg>

                        <div class="country-label">Österreich</div>
                        <mat-radio-button [value]="'AT'"></mat-radio-button>
                    </div>
                </mat-radio-group>
            </ng-template>

            <!--********** Bank account connection creation pending loading indicator **********-->
            <div class="bank-account-connection-creation-pending" *ngIf="bankAccountConnectionCreationPending" @fadeIn>
                <img src="/assets/images/icons/loading-indicator.svg" alt="" />
            </div>
        </section>
        <!--============================================-->
        <!-- END Financial Institutions Section -->
        <!--============================================-->
    </div>
</div>

import { AxError } from '@autoixpert/models/errors/ax-error';
import { DataTypeBase, DatabaseServiceName } from '@autoixpert/models/indexed-db/database.types';
import { SyncIssueNotification, SyncIssueNotificationService } from '../../services/sync-issue-notification.service';

/**
 * Since pushing to the server may include multiple records, multiple errors may be thrown. This function is used in single-record
 * functions such as create, patch or delete to extract the relevant error and - if there was an error with that record - throw.
 */
export function handlePushToServerFailureForSpecificRecord({
    recordId,
    syncIssueNotificationService,
    recordIdsWithSyncIssues,
    serviceName,
}: {
    recordId: DataTypeBase['_id'];
    syncIssueNotificationService: SyncIssueNotificationService;
    recordIdsWithSyncIssues: Map<string, string>;
    serviceName: DatabaseServiceName;
}) {
    if (recordIdsWithSyncIssues.has(recordId)) {
        const syncIssueNotification: SyncIssueNotification[] = syncIssueNotificationService.find();
        // Get the error for the correct service and the correct record.
        const errorBelongingToThisRecord = syncIssueNotification.find(
            (syncIssueNotification) =>
                syncIssueNotification.databaseService.serviceName === serviceName &&
                syncIssueNotification.recordId === recordId,
        )?.error;
        // If there is an error for the given record ID, throw it so that it can be handled.
        if (errorBelongingToThisRecord) {
            throw errorBelongingToThisRecord;
        } else {
            throw new AxError({
                code: 'SYNC_ISSUE_WITHOUT_ERROR_FOUND',
                message: `A record was marked as having sync issues but no error was logged for it.`,
                data: {
                    recordId,
                    serviceName,
                    errorBelongingToThisRecord,
                },
            });
        }
    }
}

<div id="page-layout" *ngIf="report">
    <!--============================================-->
    <!-- Records -->
    <!--============================================-->
    <div id="expert-statement-records-column">
        <div id="expert-statement-records-container" class="card">
            <div
                class="expert-statement-record record"
                *ngFor="let expertStatement of report.expertStatements"
                [ngClass]="{ active: selectedExpertStatement === expertStatement }"
                (click)="selectExpertStatement(expertStatement)">
                <!--********** Title & Date **********-->
                <div class="expert-statement-record-title-and-date">
                    <div class="expert-statement-title" [matTooltip]="getMatchingDocumentData(expertStatement).subject">
                        {{ getMatchingDocumentData(expertStatement).subject || 'Stellungnahme' }}
                    </div>
                    <div class="expert-statement-date label">
                        {{ getMatchingDocumentData(expertStatement).date || expertStatement.createdAt | moment }}
                    </div>
                </div>

                <!--********** Submenu **********-->
                <div class="expert-statement-record-toolbar">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="recordSubmeunu">more_vert</mat-icon>
                    <mat-menu #recordSubmeunu>
                        <a mat-menu-item (click)="deleteExpertStatement(expertStatement)">Löschen</a>
                    </mat-menu>
                </div>
            </div>

            <!--********** New Record **********-->
            <div id="new-record">
                <button
                    class="flat"
                    (click)="
                        addExpertStatement();
                        selectExpertStatement(report.expertStatements[report.expertStatements.length - 1])
                    ">
                    + Neue Stellungnahme
                </button>
            </div>
        </div>

        <!--********** Switch between tabs **********-->
        <div id="statement-invoice-switcher" class="card dark-card">
            <div
                class="statement-invoice-switcher-item"
                (click)="selectTab('statement')"
                [ngClass]="{ selected: selectedTab === 'statement' }">
                <div>Stellungnahme</div>
                <div class="statement-invoice-status-indicator">
                    <mat-icon
                        class="small-icon"
                        *ngIf="statementStatus(selectedExpertStatementDocument) === 'partiallyComplete'"
                        matTooltip="in Bearbeitung"
                        @fadeInAndOut>
                        edit
                    </mat-icon>
                    <mat-icon
                        class="small-icon"
                        *ngIf="statementStatus(selectedExpertStatementDocument) === 'complete'"
                        matTooltip="Vollständig"
                        @fadeInAndOut>
                        check_circle
                    </mat-icon>
                </div>
            </div>
            <div
                class="statement-invoice-switcher-item"
                (click)="selectTab('invoice')"
                [ngClass]="{ selected: selectedTab === 'invoice' }">
                <div>Rechnung</div>
                <div class="statement-invoice-status-indicator">
                    <mat-icon
                        class="small-icon"
                        *ngIf="invoiceStatus() === 'partiallyComplete'"
                        matTooltip="in Bearbeitung"
                        @fadeInAndOut>
                        edit
                    </mat-icon>
                    <mat-icon
                        class="small-icon"
                        *ngIf="invoiceStatus() === 'complete'"
                        matTooltip="Vollständig"
                        @fadeInAndOut>
                        check_circle
                    </mat-icon>
                </div>
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Records -->
    <!--============================================-->

    <!--============================================-->
    <!-- Statement & Invoice -->
    <!--============================================-->
    <div id="statement-and-invoice-container" class="card card-with-padding">
        <h2>
            <ng-container *ngIf="selectedTab === 'statement'">Stellungnahme</ng-container>
            <ng-container *ngIf="selectedTab === 'invoice'">Rechnung</ng-container>
        </h2>

        <!--============================================-->
        <!-- Expert Statement -->
        <!--============================================-->
        <ng-container *ngIf="selectedTab === 'statement'">
            <!--********** Card toolbar **********-->
            <div class="card-toolbar">
                <mat-icon class="toolbar-icon" matTooltip="Vorlage wählen" (click)="showMessageTemplateSelector()">
                    post_add
                </mat-icon>
                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="statementSubmenu">more_vert</mat-icon>
                <mat-menu #statementSubmenu>
                    <a mat-menu-item (click)="deleteExpertStatement(selectedExpertStatement)">Stellungnahme löschen</a>
                </mat-menu>
            </div>

            <!--********** Recipient **********-->
            <!--********** TODO As soon as the backend supports a full recipient when generating a letter, use the recipient selector **********-->
            <!--            <recipient-selector [(contactPerson)]="selectedExpertStatement.recipient" [contactPeopleForQuickSelection]="relevantRecipients"-->
            <!--                                (contactPersonChange)="saveReport()"></recipient-selector>-->
            <div
                class="recipient-container"
                [matMenuTriggerFor]="recipientSubmenu"
                [ngClass]="{ locked: isInvoiceLocked() }">
                <ng-container
                    *ngIf="
                        getRecipient() &&
                            (getRecipient().organization || getRecipient().firstName || getRecipient().lastName);
                        then withRecipient;
                        else noRecipient
                    "></ng-container>
                <!--********** Recipient Selected **********-->
                <ng-template #withRecipient>
                    <div class="recipient">
                        <div>
                            <div *ngIf="getRecipient().organization">{{ getRecipient().organization }}</div>
                            <div *ngIf="getRecipient().firstName || getRecipient().lastName">
                                {{ getRecipient().salutation }} {{ getRecipient().firstName }}
                                {{ getRecipient().lastName }}
                            </div>
                            <div>{{ getRecipient().streetAndHouseNumberOrLockbox }}</div>
                            <div>{{ getRecipient().zip }} {{ getRecipient().city }}</div>
                        </div>
                        <mat-icon class="recipient-edit-icon" matTooltip="Empfänger bearbeiten">
                            arrow_drop_down
                        </mat-icon>
                    </div>
                </ng-template>

                <!--********** Recipient not yet selected **********-->
                <ng-template #noRecipient>
                    <div class="no-recipient">
                        <div class="no-recipient-icon-ring" [ngSwitch]="selectedExpertStatementDocument.recipientRole">
                            <img *ngSwitchCase="'claimant'" src="/assets/images/icons/claimant-blue.svg" />
                            <img *ngSwitchCase="'insurance'" src="/assets/images/icons/insurance-blue.svg" />
                            <img *ngSwitchCase="'lawyer'" src="/assets/images/icons/lawyer-blue.svg" />
                            <img *ngSwitchCase="'garage'" src="/assets/images/icons/garage-blue.svg" />
                        </div>
                        <div class="label" [ngSwitch]="selectedExpertStatementDocument.recipientRole">
                            <ng-container *ngSwitchCase="'claimant'">Anspruchsteller</ng-container>
                            <ng-container *ngSwitchCase="'insurance'">Versicherung</ng-container>
                            <ng-container *ngSwitchCase="'lawyer'">Anwalt</ng-container>
                            <ng-container *ngSwitchCase="'garage'">Werkstatt</ng-container>
                        </div>
                        <mat-icon class="recipient-edit-icon" matTooltip="Empfänger bearbeiten">
                            arrow_drop_down
                        </mat-icon>
                    </div>
                </ng-template>

                <mat-menu #recipientSubmenu="matMenu">
                    <!--********** Insurance **********-->
                    <a
                        *ngIf="report.insurance"
                        mat-menu-item
                        class="menu-item-with-icon recipient-selection"
                        (click)="setRecipientRole('insurance'); saveReport()">
                        <img src="/assets/images/icons/insurance-gray.svg" alt="" />
                        Versicherung
                        <mat-icon
                            class="save-default-recipient-icon medium-icon"
                            [ngClass]="{
                                selected: this.userPreferences.defaultRecipientExpertStatement === 'insurance',
                            }"
                            (click)="setDefaultRecipient('insurance', $event)"
                            matTooltip="Versicherung als Standard-Empfänger setzen">
                            star
                        </mat-icon>
                    </a>

                    <!--********** Lawyer **********-->
                    <a
                        mat-menu-item
                        *ngIf="report.lawyer"
                        class="menu-item-with-icon recipient-selection"
                        (click)="setRecipientRole('lawyer'); saveReport()">
                        <img src="/assets/images/icons/lawyer.svg" alt="" />
                        Anwalt
                        <mat-icon
                            class="save-default-recipient-icon medium-icon"
                            [ngClass]="{ selected: this.userPreferences.defaultRecipientExpertStatement === 'lawyer' }"
                            (click)="setDefaultRecipient('lawyer', $event)"
                            matTooltip="Anwalt als Standard-Empfänger setzen">
                            star
                        </mat-icon>
                    </a>

                    <!--********** Claimant **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon recipient-selection"
                        (click)="setRecipientRole('claimant'); saveReport()">
                        <img src="/assets/images/icons/claimant-gray.svg" alt="" />
                        Anspruchsteller
                        <mat-icon
                            class="save-default-recipient-icon medium-icon"
                            [ngClass]="{
                                selected: this.userPreferences.defaultRecipientExpertStatement === 'claimant',
                            }"
                            (click)="setDefaultRecipient('claimant', $event)"
                            matTooltip="Anspruchsteller als Standard-Empfänger setzen">
                            star
                        </mat-icon>
                    </a>

                    <!--********** Garage **********-->
                    <a
                        mat-menu-item
                        *ngIf="report.garage"
                        class="menu-item-with-icon recipient-selection"
                        (click)="setRecipientRole('garage'); saveReport()">
                        <img src="/assets/images/icons/garage-gray.svg" alt="" />
                        Werkstatt
                        <mat-icon
                            class="save-default-recipient-icon medium-icon"
                            [ngClass]="{
                                selected: this.userPreferences.defaultRecipientExpertStatement === 'garage',
                            }"
                            (click)="setDefaultRecipient('garage', $event)"
                            matTooltip="Werkstatt als Standard-Empfänger setzen">
                            star
                        </mat-icon>
                    </a>
                </mat-menu>
            </div>

            <!--********** Subject & Date **********-->
            <div id="subject-and-date-container">
                <mat-form-field class="subject mat-block">
                    <input
                        matInput
                        placeholder="Betreff"
                        [(ngModel)]="selectedExpertStatementDocument.subject"
                        (change)="saveReport()" />
                </mat-form-field>
                <date-input [(date)]="selectedExpertStatementDocument.date" (dateChange)="saveReport()"></date-input>
            </div>
            <mat-form-field id="message-form-field" class="mat-block autogrow-vertically">
                <mat-quill
                    placeholder="Nachricht"
                    [(ngModel)]="selectedExpertStatementDocument.body"
                    (change)="saveReport()"></mat-quill>
                <mat-icon
                    matSuffix
                    class="input-toolbar-icon"
                    (click)="textTemplateSelectorVisible = true"
                    matTooltip="Textvorlagen">
                    playlist_add
                </mat-icon>
                <mat-hint>Platzhalter für Stempel & Unterschrift: &#123;%Sachverständiger.Stempel&#125;</mat-hint>
            </mat-form-field>

            <!--********** Buttons **********-->
            <div id="letter-buttons">
                <button (click)="navigateToPrintAndTransmission()">Druck & Versand</button>
                <button class="secondary" (click)="downloadExpertStatementPreview(selectedExpertStatement)">
                    <img
                        class="pending-icon"
                        *ngIf="expertStatementPreviewDownloadPending"
                        src="/assets/images/icons/loading-indicator.svg"
                        alt="" />
                    Vorschau
                </button>
                <button
                    class="secondary"
                    (click)="selectTab('invoice')"
                    matTooltip="Stelle eine optionale Rechnung für deine Stellungnahme.">
                    Rechnung
                </button>
            </div>

            <!--********** Templates **********-->
            <text-template-selector
                [(text)]="selectedExpertStatementDocument.body"
                (textChange)="saveReport()"
                [report]="report"
                *ngIf="textTemplateSelectorVisible"
                placeholderForResultingText="Stellungnahme"
                (close)="textTemplateSelectorVisible = false"
                [textTemplateType]="'expertStatement'"
                [letterDocument]="selectedExpertStatementDocument"
                @runChildAnimations></text-template-selector>
        </ng-container>
        <!--============================================-->
        <!-- END Expert Statement -->
        <!--============================================-->

        <!--============================================-->
        <!-- Invoice -->
        <!--============================================-->
        <div
            class="recipient-container"
            *ngIf="selectedTab === 'invoice'"
            [matMenuTriggerFor]="invoiceRecipientSubmenu"
            [ngClass]="{ locked: isInvoiceLocked() }">
            <ng-container
                *ngIf="
                    getInvoiceRecipient() &&
                        (getInvoiceRecipient().organization ||
                            getInvoiceRecipient().firstName ||
                            getInvoiceRecipient().lastName);
                    then withRecipient;
                    else noRecipient
                "></ng-container>
            <!--********** Recipient Selected **********-->
            <ng-template #withRecipient>
                <div class="recipient">
                    <div>
                        <div *ngIf="getInvoiceRecipient().organization">{{ getInvoiceRecipient().organization }}</div>
                        <div *ngIf="getInvoiceRecipient().firstName || getInvoiceRecipient().lastName">
                            {{ getInvoiceRecipient().salutation }} {{ getInvoiceRecipient().firstName }}
                            {{ getInvoiceRecipient().lastName }}
                        </div>
                        <div>{{ getInvoiceRecipient().streetAndHouseNumberOrLockbox }}</div>
                        <div>{{ getInvoiceRecipient().zip }} {{ getInvoiceRecipient().city }}</div>
                    </div>
                    <mat-icon class="recipient-edit-icon toolbar-icon" matTooltip="Empfänger bearbeiten">
                        arrow_drop_down
                    </mat-icon>
                </div>
            </ng-template>

            <!--********** Recipient not yet selected **********-->
            <ng-template #noRecipient>
                <div class="no-recipient">
                    <div
                        class="no-recipient-icon-ring"
                        [ngSwitch]="selectedExpertStatement.invoiceParameters.recipientRole">
                        <img *ngSwitchCase="'claimant'" src="/assets/images/icons/claimant-blue.svg" />
                        <img *ngSwitchCase="'insurance'" src="/assets/images/icons/insurance-blue.svg" />
                        <img *ngSwitchCase="'lawyer'" src="/assets/images/icons/lawyer-blue.svg" />
                    </div>
                    <div class="label" [ngSwitch]="selectedExpertStatement.invoiceParameters.recipientRole">
                        <ng-container *ngSwitchCase="'claimant'">Anspruchsteller</ng-container>
                        <ng-container *ngSwitchCase="'insurance'">Versicherung</ng-container>
                        <ng-container *ngSwitchCase="'lawyer'">Anwalt</ng-container>
                    </div>
                    <mat-icon class="recipient-edit-icon toolbar-icon" matTooltip="Empfänger bearbeiten">
                        arrow_drop_down
                    </mat-icon>
                </div>
            </ng-template>

            <mat-menu #invoiceRecipientSubmenu="matMenu">
                <a
                    *ngIf="report.insurance"
                    mat-menu-item
                    class="menu-item-with-icon recipient-selection"
                    (click)="setInvoiceRecipientRole('insurance'); saveReport()">
                    <img src="/assets/images/icons/insurance-gray.svg" alt="" />
                    Versicherung
                    <mat-icon
                        class="save-default-recipient-icon medium-icon"
                        [ngClass]="{ selected: this.userPreferences.defaultRecipientExpertStatement === 'insurance' }"
                        (click)="setDefaultRecipient('insurance', $event)"
                        matTooltip="Setze die Versicherung als Standard-Empfänger.">
                        star
                    </mat-icon>
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon recipient-selection"
                    (click)="setInvoiceRecipientRole('claimant'); saveReport()">
                    <img src="/assets/images/icons/claimant-gray.svg" alt="" />
                    Anspruchsteller
                    <mat-icon
                        class="save-default-recipient-icon medium-icon"
                        [ngClass]="{ selected: this.userPreferences.defaultRecipientExpertStatement === 'claimant' }"
                        (click)="setDefaultRecipient('claimant', $event)"
                        matTooltip="Setze die Versicherung als Standard-Empfänger.">
                        star
                    </mat-icon>
                </a>
                <a
                    *ngIf="report.lawyer"
                    mat-menu-item
                    class="menu-item-with-icon recipient-selection"
                    (click)="setInvoiceRecipientRole('lawyer'); saveReport()">
                    <img src="/assets/images/icons/lawyer.svg" alt="" />
                    Anwalt
                    <mat-icon
                        class="save-default-recipient-icon medium-icon"
                        [ngClass]="{ selected: this.userPreferences.defaultRecipientExpertStatement === 'lawyer' }"
                        (click)="setDefaultRecipient('lawyer', $event)"
                        matTooltip="Setze die Versicherung als Standard-Empfänger.">
                        star
                    </mat-icon>
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon recipient-selection"
                    (click)="showInvoiceRecipientContactPersonEditor()">
                    <mat-icon style="color: #c1c1c1">edit</mat-icon>
                    Anderer Empfänger
                </a>
            </mat-menu>
        </div>
        <ng-container *ngIf="selectedTab === 'invoice'">
            <div class="card-submenu">
                <mat-icon
                    class="toolbar-icon"
                    (click)="showInvoiceTemplateTitleDialog()"
                    *ngIf="!invoiceTemplatesShown"
                    matTooltip="Rechnung als Vorlage speichern">
                    playlist_add_check
                </mat-icon>
                <mat-icon [matMenuTriggerFor]="invoiceCardSubmenu" class="toolbar-icon">more_vert</mat-icon>
                <mat-menu #invoiceCardSubmenu="matMenu">
                    <mat-option
                        class="menu-item-with-icon"
                        *ngIf="!isInvoiceLocked() && invoiceTemplates?.length"
                        (click)="showInvoiceTemplates()">
                        <mat-icon>list_alt</mat-icon>
                        Rechnungsvorlagen anzeigen
                    </mat-option>
                    <mat-option
                        *ngIf="!isInvoiceLocked()"
                        (click)="createInvoice()"
                        matTooltip="Rechnung generieren und in Rechnungsliste und Druck & Versand einfügen lassen.">
                        Rechnung erstellen
                    </mat-option>
                    <mat-option *ngIf="isInvoiceLocked()" (click)="deleteInvoice(selectedExpertStatement)">
                        Rechnung bearbeiten
                    </mat-option>
                    <mat-option (click)="deleteExpertStatement(selectedExpertStatement)">
                        Stellungnahme löschen
                    </mat-option>
                </mat-menu>
            </div>
            <div id="invoice-number-and-date-container">
                <div id="invoice-number-container">
                    <mat-form-field>
                        <input
                            matInput
                            placeholder="Nummer"
                            [(ngModel)]="selectedExpertStatement.invoiceParameters.number"
                            (focus)="lastInvoiceNumber = selectedExpertStatement.invoiceParameters.number"
                            [disabled]="isInvoiceLocked()"
                            (change)="handleInvoiceNumberChange()" />
                    </mat-form-field>
                    <mat-icon
                        id="invoice-number-generator-icon"
                        *ngIf="!isInvoiceLocked()"
                        (click)="generateInvoiceNumber()"
                        matTooltip="Rechnungsnummer generieren">
                        file_download
                    </mat-icon>
                    <mat-icon
                        [editCounterAnchor]="report"
                        class="invoice-number-settings-icon outlined"
                        *ngIf="report.invoiceNumberConfig"
                        matTooltip="Rechnungszähler für dieses Gutachten bearbeiten">
                        more_vert
                    </mat-icon>
                </div>
                <date-input
                    id="invoice-date-container"
                    [(date)]="selectedExpertStatement.invoiceParameters.date"
                    (dateChange)="updateVatRate(selectedExpertStatement); saveReport()"
                    matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"
                    placeholder="Rechnungsdatum"
                    [disabled]="isInvoiceLocked()"></date-input>
                <mat-form-field id="days-until-due-container">
                    <input
                        matInput
                        placeholder="Zahlungsziel (Tage)"
                        number-input
                        [(number)]="selectedExpertStatement.invoiceParameters.daysUntilDue"
                        [maximumFractionDigits]="0"
                        [minimumFractionDigits]="0"
                        (change)="saveReport()"
                        [disabled]="isInvoiceLocked()" />
                </mat-form-field>
                <!--***********************************************
                ** Electronic Invoice
                ************************************************-->
                <electronic-invoice-toggle
                    [disabled]="isInvoiceLocked()"
                    [invoiceDate]="selectedExpertStatement.invoiceParameters.date"
                    [(isElectronicInvoiceEnabled)]="
                        selectedExpertStatement.invoiceParameters.isElectronicInvoiceEnabled
                    "
                    [(isElectronicInvoiceStatusFrozen)]="
                        selectedExpertStatement.invoiceParameters.isElectronicInvoiceStatusFrozen
                    "
                    (isElectronicInvoiceEnabledChange)="saveReport()"
                    (isElectronicInvoiceStatusFrozenChange)="saveReport()"
                    [invoiceRecipientContactPerson]="getInvoiceRecipient()"></electronic-invoice-toggle>
                <!--***********************************************
                ** END Electronic Invoice
                ************************************************-->
            </div>

            <!--============================================-->
            <!-- Invoice Templates -->
            <!--============================================-->
            <ng-container *ngIf="invoiceTemplatesShown">
                <section id="invoice-templates-section">
                    <h3>Vorlagen</h3>
                    <div id="invoice-templates-list">
                        <div
                            class="invoice-template"
                            *ngFor="let invoiceTemplate of invoiceTemplates"
                            (click)="selectInvoiceTemplate(invoiceTemplate)"
                            (dblclick)="useInvoiceTemplate(invoiceTemplate)"
                            [ngClass]="{ selected: selectedInvoiceTemplate === invoiceTemplate }">
                            <div class="invoice-template-title">
                                {{ invoiceTemplate.title }}
                            </div>
                            <div class="invoice-template-total">{{ invoiceTemplate.totalNet | number: '1.0-0' }} €</div>
                            <mat-icon
                                class="invoice-template-delete-icon"
                                *ngIf="invoiceTemplate.createdBy !== 'autoixpert-default'"
                                (click)="deleteInvoiceTemplate(invoiceTemplate)"
                                matTooltip="Vorlage löschen">
                                delete
                            </mat-icon>
                        </div>
                    </div>
                    <div id="invoice-templates-buttons-container">
                        <button
                            (click)="useInvoiceTemplate(selectedInvoiceTemplate)"
                            [ngClass]="{ disabled: !selectedInvoiceTemplate }"
                            [matTooltip]="selectedInvoiceTemplate ? '' : 'Bitte eine Vorlage auswählen'">
                            Übernehmen
                        </button>
                        <button class="secondary" (click)="hideInvoiceTemplates()">Vorlagen schließen</button>
                    </div>
                </section>
            </ng-container>
            <!--============================================-->
            <!-- END Invoice Templates -->
            <!--============================================-->
            <ng-container *ngIf="!invoiceTemplatesShown">
                <!--============================================-->
                <!-- Custom Line Items -->
                <!--============================================-->
                <h3 id="line-items-heading">Positionen</h3>
                <div class="line-item" *ngFor="let lineItem of selectedExpertStatement.invoiceParameters.lineItems">
                    <mat-checkbox
                        [(ngModel)]="lineItem.active"
                        class="line-item-active-marker"
                        (change)="saveReport()"
                        [disabled]="isInvoiceLocked()"></mat-checkbox>

                    <!--********** Description **********-->
                    <mat-form-field class="line-item-description">
                        <mat-quill
                            placeholder="Position"
                            [(ngModel)]="lineItem.description"
                            (change)="saveReport()"
                            [disabled]="isInvoiceLocked()"
                            name="invoice-item-description"></mat-quill>
                    </mat-form-field>

                    <!--********** Quantity **********-->
                    <mat-form-field class="line-item-quantity">
                        <input
                            matInput
                            number-input
                            placeholder="Menge"
                            [(number)]="lineItem.quantity"
                            [disabled]="isInvoiceLocked()"
                            (change)="saveReport()"
                            [minimumFractionDigits]="0"
                            [maximumFractionDigits]="2" />
                    </mat-form-field>

                    <!--********** Unit **********-->
                    <mat-form-field class="line-item-unit">
                        <input
                            matInput
                            placeholder="Einheit"
                            [(ngModel)]="lineItem.unit"
                            [disabled]="isInvoiceLocked()"
                            name="invoice-unit"
                            (change)="saveReport()"
                            (ngModelChange)="filterLineItemAutocomplete($event)"
                            (focus)="filterLineItemAutocomplete('')"
                            [matAutocomplete]="invoiceLineItemUnitAutocomplete" />
                        <mat-autocomplete
                            #invoiceLineItemUnitAutocomplete
                            (optionSelected)="
                                insertLineItemUnitBasedOnQuantity(lineItem, $event.option.viewValue); saveReport()
                            ">
                            <mat-option
                                value=""
                                *ngFor="let filteredLineItemUnit of filteredLineItemUnits"
                                (mousedown)="$event.preventDefault()">
                                {{ filteredLineItemUnit.label }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!--********** Unit Price **********-->
                    <currency-input
                        class="line-item-unit-price"
                        [(value)]="lineItem.unitPrice"
                        placeholder="Einzelpreis"
                        (valueChange)="saveReport()"
                        [disabled]="isInvoiceLocked()"></currency-input>

                    <!--********** Total Price **********-->
                    <currency-input
                        totalPriceReadonlyHint
                        class="line-item-total-price"
                        [value]="(lineItem.unitPrice || 0) * (lineItem.quantity || 0)"
                        placeholder="Gesamt"
                        [readonly]="true"></currency-input>

                    <mat-icon
                        class="line-item-delete-icon"
                        *ngIf="!isInvoiceLocked()"
                        (click)="removeInvoiceLineItem(lineItem); saveReport()"
                        matTooltip="Position löschen">
                        delete
                    </mat-icon>
                </div>
                <!--============================================-->
                <!-- END Custom Line Items -->
                <!--============================================-->

                <div id="add-line-item-container">
                    <button
                        class="flat"
                        (click)="addInvoiceLineItem(); saveReport()"
                        [ngClass]="{ disabled: isInvoiceLocked() }">
                        + Position hinzufügen
                    </button>
                </div>
                <div id="invoice-total-container" *ngIf="getInvoiceTotalNet()">
                    <div class="label">Summe</div>
                    <div id="invoice-total">
                        <span matTooltip="Netto">{{ getInvoiceTotalNet() | number: '1.2-2' }} €</span>
                    </div>
                    <div class="label">
                        <span matTooltip="Brutto">{{ getInvoiceTotalGross() | number: '1.2-2' }} €</span>
                    </div>
                </div>
                <div
                    id="factoring-provider-container"
                    *ngIf="user && (user.adeltaFinanzUser.username || user.kfzvsUser.customerNumber)">
                    <mat-checkbox
                        [(ngModel)]="selectedExpertStatement.invoiceParameters.factoringEnabled"
                        matTooltip="Rechnung an {{
                            userPreferences.factoringProvider.contactPerson.organization
                        }} abtreten"
                        [disabled]="isInvoiceLocked()">
                        Factoring aktivieren
                    </mat-checkbox>
                </div>
                <div id="print-and-export-buttons-container">
                    <button id="create-invoice-button" (click)="createInvoice()" *ngIf="!isInvoiceLocked()">
                        <img
                            class="pending-icon"
                            src="/assets/images/icons/loading-indicator-white.svg"
                            *ngIf="invoiceCreationPending" />
                        Rechnung erstellen
                    </button>
                    <button (click)="navigateToPrintAndTransmission()" *ngIf="isInvoiceLocked()">
                        Druck & Versand
                    </button>
                    <button
                        class="secondary"
                        *ngIf="isInvoiceLocked()"
                        (click)="deleteInvoice(selectedExpertStatement)">
                        Rechnung bearbeiten
                    </button>
                    <button class="secondary" (click)="downloadInvoicePreview()">
                        <img
                            src="/assets/images/icons/loading-indicator.svg"
                            class="pending-icon"
                            *ngIf="invoicePreviewDownloadPending" />
                        Vorschau
                    </button>
                </div>
            </ng-container>
        </ng-container>
        <!--============================================-->
        <!-- END Invoice -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Statement & Invoice -->
    <!--============================================-->

    <!--============================================-->
    <!-- Photos -->
    <!--============================================-->
    <div id="photo-column">
        <!--********** Photos Header **********-->
        <div id="photos-header" class="card dark-card" *ngIf="photos.length">
            <div id="number-of-photos">{{ photos.length }} {{ photos.length === 1 ? 'Foto' : 'Fotos' }}</div>
            <div id="toolbar-container">
                <mat-icon
                    id="upload-photos-icon"
                    class="toolbar-icon"
                    (click)="openPhotoFileSelector()"
                    matTooltip="Fotos hochladen">
                    add
                </mat-icon>
                <mat-icon
                    id="photo-overview-opener-icon"
                    class="toolbar-icon"
                    (click)="navigateToPhotoList()"
                    matTooltip="Fotoübersicht öffnen">
                    collections
                </mat-icon>
            </div>
        </div>

        <photo-grid
            *ngIf="report"
            [report]="report"
            [photos]="photos"
            [floatingActionButtonShown]="false"
            [disableKeyboardShortcuts]="photoEditorVisible"
            photoVersion="expertStatement"
            [photoGroupReportAvailable]="false"
            [photoGroupExpertStatementAvailable]="true"
            [photoGroupFilterShown]="false"
            thumbnailSize="veryLarge"
            [miniMode]="true"
            zipDownloadFilename="Fotos Stellungnahme autoiXpert.zip"
            (photoChange)="savePhotos()"
            (photoDeletion)="deletePhotos($event)"
            (openPhotoEditor)="setupPhotoEditor($event); openPhotoEditor()"
            #photoGridComponent></photo-grid>

        <photo-editor
            *ngIf="photoEditorVisible"
            [report]="report"
            [initialPhoto]="initialPhotoForEditor"
            [photos]="photos"
            [limitToPhotoGroup]="true"
            photoVersion="expertStatement"
            (change)="savePhotos()"
            (close)="closeEditor()"
            [disabled]="isReportLocked()"
            disabledMessage="Entsperre das Gutachten, bevor du Fotos bearbeiten kannst."></photo-editor>
    </div>
    <!--============================================-->
    <!-- END Photos -->
    <!--============================================-->
</div>

<message-template-selector
    *ngIf="messageTemplateSelectorShown"
    [report]="report"
    [templateType]="'expertStatementLetter'"
    [letterDocument]="selectedExpertStatementDocument"
    (close)="hideMessageTemplateSelector()"
    (templateSelection)="insertTemplateText($event); saveReport()"></message-template-selector>

<!--============================================-->
<!-- Template Title Dialog -->
<!--============================================-->
<invoice-template-title-dialog
    *ngIf="invoiceTemplateTitleDialogShown"
    [title]="getInvoiceTemplateTitle()"
    (useTitle)="addInvoiceToTemplates($event)"
    (close)="invoiceTemplateTitleDialogShown = false"></invoice-template-title-dialog>
<!--============================================-->
<!-- END Template Title Dialog -->
<!--============================================-->

<!--***********************************************
** Custom Invoice Recipient
************************************************-->

<contact-person-editor
    *ngIf="invoiceRecipientContactPersonEditorShown"
    [(contactPerson)]="selectedExpertStatement.invoiceParameters.recipient"
    (close)="hideInvoiceRecipientContactPersonEditor()"
    (contactPersonChange)="setInvoiceRecipientRole('custom'); saveReport()"
    [autocompleteGroupNames]="['claimant', 'insurance', 'garage', 'lawyer']"
    [showPhone]="false"
    [showEmail]="false"
    [vatFieldsAllowed]="true"
    [showWebsite]="false"></contact-person-editor>

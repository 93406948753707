import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import {
    MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatLegacyTooltipModule as MatTooltipModule,
} from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { FileUploadModule } from 'ng2-file-upload';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { ActionToolbarModule } from '../action-toolbar/action-toolbar.module';
import { SignaturePadComponent } from './signature-pad.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        FileUploadModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatMomentDateModule,
        SharedDirectivesModule,
        SharedPipesModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        ActionToolbarModule,
    ],
    declarations: [SignaturePadComponent],
    exports: [SignaturePadComponent],
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                /**
                 * Prevent the tooltip text to be selectable with the mouse pointer. This way, it does not stand in the way when the user moves the mouse to click a button currently covered by the tooltip.
                 * Details: https://github.com/angular/components/issues/25173
                 */
                disableTooltipInteractivity: true,
                showDelay: 600,
            },
        },
    ],
})
export class SignaturePadModule {}

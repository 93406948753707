<!-- List of financial institutions -->
<cdk-virtual-scroll-viewport
    itemSize="65"
    minBufferPx="1000"
    maxBufferPx="1000"
    class="financial-institutions-list cdk-virtual-scroll-vertical-only">
    <!-- Loading indicator -->
    <div *ngIf="financialInstitutionsLoading; else notLoading" class="financial-institutions-loading" @fadeInAndOut>
        <img src="/assets/images/icons/loading-indicator.svg" alt="" />
    </div>

    <ng-template #notLoading>
        <ng-container
            *cdkVirtualFor="let financialInstitution of financialInstitutions; trackBy: trackByFinancialInstitutionId">
            <!-- Financial institution -->
            <div class="financial-institution" (click)="financialInstitutionSelected.emit(financialInstitution)">
                <!-- Logo -->
                <img
                    width="40"
                    height="40"
                    class="financial-institution-logo"
                    [src]="financialInstitution.logo"
                    [alt]="financialInstitution.name + ' Logo'" />

                <!-- Name -->
                <div class="financial-institution-name">{{ financialInstitution.name }}</div>

                <!-- Chevron right -->
                <mat-icon class="financial-institution-chevron-right">chevron_right</mat-icon>
            </div>
        </ng-container>

        <!-- No results placeholders -->
        <div *ngIf="!financialInstitutions.length" class="financial-institutions-no-results-placeholder">
            <mat-icon class="financial-institutions-no-results-placeholder-icon outlined">error</mat-icon>
            <div class="financial-institutions-no-results-placeholder-text">Keine Ergebnisse</div>
        </div>
    </ng-template>
</cdk-virtual-scroll-viewport>

/**
 * Each team is offered some setup steps. This object tracks their completion.
 */
export class SetupSteps {
    constructor(template: Partial<SetupSteps> = {}) {
        Object.assign(this, template);
    }

    /**
     * Has the entire setup steps section been closed.
     */
    closed: boolean;
    /**
     * Has the letterhead been customized (file upload or accent color change)?
     */
    headerAndFooterTemplate: boolean;
    /**
     * Has either team member added their own stamp?
     */
    signatureAndStamp: boolean;
    /**
     * Has at least one team member added their own email address to send from?
     */
    emailCredentials: boolean;
    /**
     * Customized the settings at least once.
     */
    reportTokenAndInvoiceNumberPattern: boolean;
    /**
     * Signable PDF config has been uploaded.
     */
    ownDeclarationOfAssignment: boolean;
    /**
     * Either contacts or the report header data.
     */
    contactsOrLegacyReportsImported: boolean;
}

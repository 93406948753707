import { Task } from '../../models/tasks/task';
import { taskListRankMax, taskListRankMin } from '../../static-data/tasks/task-list-rank-limits';

/**
 * When inserting or re-positioning a task, its rank must be re-calculated.
 *
 * The rank will be a large number.
 *
 * Algorithm:
 * Get the rank of the tasks before and after the target position. The average between the two is the new rank.
 */
export function calculateRankForTask({
    position,
    sortedTasks,
    referenceTask,
}: {
    position: 'first' | 'last' | 'before' | 'after';
    sortedTasks: Task[];
    referenceTask?: Task;
}): number {
    const rankAtTop = sortedTasks[0]?.rank ?? taskListRankMax;
    const rankAtBottom = sortedTasks.at(-1)?.rank ?? taskListRankMin;

    if (sortedTasks.length === 0) {
        return (taskListRankMin + taskListRankMax) / 2;
    }

    if (position === 'first') {
        return (rankAtTop + taskListRankMin) / 2;
    } else if (position === 'last') {
        return (rankAtBottom + taskListRankMax) / 2;
    } else if (position === 'before' || position === 'after') {
        const indexOfTargetTask = sortedTasks.findIndex((task) => task._id === referenceTask._id);

        const rankOfReferenceTask = referenceTask?.rank || taskListRankMin;

        // Other neighbor = task that will be next to the inserted task on the other side than the reference task.
        const otherNeighborTask: Task = sortedTasks[indexOfTargetTask + (position === 'before' ? -1 : 1)] ?? null;
        const rankOfOtherNeighbor =
            otherNeighborTask?.rank ?? (position === 'before' ? taskListRankMin : taskListRankMax);

        return (rankOfReferenceTask + rankOfOtherNeighbor) / 2;
    }
}

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import {
    DatCalculationDossierListItem,
    DatValuationDossierListItem,
} from '@autoixpert/models/reports/damage-calculation/dat-dossier-list-item';
import { DatDossierType } from '@autoixpert/models/reports/damage-calculation/dat-dossier-type';
import { Repair } from '@autoixpert/models/reports/damage-description/repair';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { getDatErrorHandlers } from '../../../../shared/libraries/error-handlers/get-dat-error-handlers';
import { capitalize } from '../../../../shared/libraries/text-transformation/capitalize';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { DatDossierListService } from '../../../../shared/services/dat-dossier-list.service';

@Component({
    selector: 'connect-dat-dossier-dialog',
    templateUrl: 'connect-dat-dossier-dialog.component.html',
    styleUrls: ['connect-dat-dossier-dialog.component.scss'],
    animations: [fadeInAndSlideAnimation(), dialogEnterAndLeaveAnimation()],
})
export class ConnectDatDossierDialogComponent implements OnInit {
    constructor(
        private dialog: MatDialogRef<ConnectDatDossierDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConnectDatDossierDialogData,
        private datDossierListService: DatDossierListService,
        private apiErrorService: ApiErrorService,
    ) {}

    public dossiers: DatCalculationDossierListItem[] | DatValuationDossierListItem[] = [];
    public dossiersPending: boolean;
    private errorLoadingDossiers: boolean = false;
    public selectedDossier: DatCalculationDossierListItem | DatValuationDossierListItem;

    public customDossierId: string;
    public dossierIdInputShown: boolean;

    protected licensePlate: string;
    protected vin: string;
    protected dossierType: DatDossierType;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.licensePlate = this.data.licensePlate;
        this.vin = this.data.vin;
        this.dossierType = this.data.dossierType;

        this.loadDossiers();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Select Dossier
    //****************************************************************************/
    public selectDossier(dossier: DatCalculationDossierListItem | DatValuationDossierListItem): void {
        this.selectedDossier = dossier;
    }

    private preselectDossierWithMatchingVehicle(): void {
        // Match by license plate
        if (this.licensePlate) {
            const matchingDossier = this.dossiers.find((dossier) => dossier.licensePlate === this.licensePlate);
            if (matchingDossier) {
                this.selectDossier(matchingDossier);
                return;
            }
        }

        // Match by VIN
        if (this.vin) {
            const matchingDossier = this.dossiers.find((dossier) => dossier.vin === this.vin);
            if (matchingDossier) {
                this.selectDossier(matchingDossier);
                return;
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select Dossier
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load DAT Dossiers
    //****************************************************************************/
    private async loadDossiers(): Promise<void> {
        this.dossiersPending = true;

        try {
            this.dossiers = await this.datDossierListService.find(this.dossierType);
            this.dossiersPending = false;
            this.preselectDossierWithMatchingVehicle();
        } catch (error) {
            this.dossiersPending = false;
            this.errorLoadingDossiers = true;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDatErrorHandlers(),
                },
                defaultHandler: {
                    title: 'DAT-Vorgänge konnten nicht geladen werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load DAT Dossiers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Brand Icons
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date & Time
    //****************************************************************************/
    public capitalize(text: string): string {
        return capitalize(text);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date & Time
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Dossier ID
    //****************************************************************************/
    public showDossierIdInput(): void {
        // Clear the selection to indicate to the user which dossier will be used.
        this.selectedDossier = null;
        this.dossierIdInputShown = true;
    }

    public hideDossierIdInput(): void {
        this.dossierIdInputShown = false;
        this.customDossierId = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Dossier ID
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Connection
    //****************************************************************************/
    public handleConnectionButtonClick(): void {
        if (this.selectedDossier || this.customDossierId) {
            this.closeWithSelectedDossier();
        } else {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Connection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/
    public closeWithSelectedDossier(): void {
        let dossierId: Repair['datCalculation']['dossierId'];
        if (this.dossierIdInputShown) {
            dossierId = +this.customDossierId;
        } else {
            dossierId = this.selectedDossier.datDossierId;
        }
        console.log('close with', dossierId);
        this.dialog.close(dossierId);
    }

    public closeDialog(): void {
        this.dialog.close();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Window Event Listeners
    //****************************************************************************/
    /**
     * This is needed for SilverDAT 2 imports. As soon as the user comes back from the import page (iframe pointing to dat.de), the list should
     * be refreshed so the newly imported calculation is displayed.
     */
    @HostListener('window:visibilitychange', ['$event'])
    private reloadDatDossiersOnVisibilityChange(): void {
        // If the user's credentials are wrong or the DAT servers are down, prevent querying the DAT again when focussing the screen.
        if (!this.errorLoadingDossiers) {
            this.loadDossiers();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Window Event Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface ConnectDatDossierDialogData {
    licensePlate: string;
    vin: string;
    dossierType: DatDossierType;
}

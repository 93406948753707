import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInAndSlideAnimation } from '@autoixpert/animations/fade-in-and-slide.animation';
import { roundToNearest } from '@autoixpert/lib/numbers/round-to-nearest';
import { TeamPreferences } from '@autoixpert/models/teams/team-preferences';

@Component({
    selector: 'valuation-round-decrease-configuration',
    templateUrl: './valuation-round-decrease-configuration.component.html',
    styleUrls: ['./valuation-round-decrease-configuration.component.scss'],
    animations: [fadeInAndSlideAnimation()],
})
export class ValuationRoundDecreaseConfigurationComponent {
    @Input() previewAmount: number;
    @Input() teamPreferences: TeamPreferences;
    @Output() teamPreferencesChange: EventEmitter<void> = new EventEmitter();

    get roundedPreview() {
        return roundToNearest({
            toBeRounded: this.previewAmount,
            type: this.teamPreferences.valuation_decreaseRoundType,
            stepSize: this.teamPreferences.valuation_decreaseRoundToFull,
        });
    }

    public emitTeamPreferenceChange() {
        this.teamPreferencesChange.emit();
    }

    protected setRoundType(roundType: TeamPreferences['valuation_decreaseRoundType']) {
        this.teamPreferences.valuation_decreaseRoundType = roundType;
        this.emitTeamPreferenceChange();
    }
}

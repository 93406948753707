import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaterialIconsMetadata } from './material-icons-metadata';

/**
 * Lazy-load icon metadata once and provide it to the icon picker component.
 * Do not include the metadata in build as they are over 1MB in size.
 */
@Injectable()
export class IconPickerService {
    constructor(private readonly httpService: HttpClient) {}

    private iconMetadata: MaterialIconsMetadata;

    async loadIconsMetadata(): Promise<MaterialIconsMetadata> {
        if (!this.iconMetadata) {
            this.iconMetadata = await this.httpService
                .get<MaterialIconsMetadata>('/assets/metadata/material-icons-de.json')
                .toPromise();
        }

        return this.iconMetadata;
    }
}

import { AfterViewInit, Component, Input, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { getSelectedFeeTableColumn } from '@autoixpert/lib/fee-set/get-selected-fee-table-column';
import { getActiveFeeSetLineItemDescriptions } from '@autoixpert/lib/fee-set/report-fee-set-line-item';
import { convertToEuro } from '@autoixpert/lib/placeholder-values/convert-to-euro';
import { SignableDocumentsRenderingConfig } from '../../../models/signable-documents/signable-document-rendering-config';

@Component({
    selector: 'customer-signatures-fee-set',
    templateUrl: './customer-signatures-fee-set.component.html',
    styleUrls: ['./customer-signatures-fee-set.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomerSignaturesFeeSetComponent implements AfterViewInit {
    @Input() config: SignableDocumentsRenderingConfig;
    @Input() buildingBlock: { content: SafeHtml };
    protected feeTableColumnA: FeeTableRow[] = [];
    protected feeTableColumnB: FeeTableRow[] = [];
    protected customFeeLineItems: string[] = [];

    convertToEuro = convertToEuro;

    private generateReportFeeTable(): void {
        const feeTable = getSelectedFeeTableColumn({
            feeCalculation: this.config.report.feeCalculation,
            teamPreferences: this.config.feeTable,
            customFeeSets: this.config.feeTable.customFeeSets,
        });

        if (!feeTable) return;

        const feeTableVatRate = this.config.report.feeCalculation?.invoiceParameters.vatRate ?? 0.19;

        // If a full column doesn't have any decimal digits, don't print double zeros for simplicity.
        const damageValuesContainDecimals: boolean = feeTable.some(([lowerLimit, fee]) => lowerLimit % 1 !== 0);
        const netFeesContainDecimals: boolean = feeTable.some(([lowerLimit, fee]) => fee % 1 !== 0);
        const grossFeesContainDecimals: boolean = feeTable.some(
            ([lowerLimit, fee]) => (fee * (1 + feeTableVatRate)) % 1 !== 0,
        );

        const feeTableFormatted = feeTable.map<FeeTableRow>(([lowerLimit, fee]) => ({
            damageValue: convertToEuro(lowerLimit, { decimalPlaces: damageValuesContainDecimals ? 2 : 0 }),
            feeNet: convertToEuro(fee, { decimalPlaces: netFeesContainDecimals ? 2 : 0 }),
            feeGross: convertToEuro(fee * (1 + feeTableVatRate), { decimalPlaces: grossFeesContainDecimals ? 2 : 0 }),
        }));

        const indexHalf = feeTable.length <= 20 ? feeTable.length : Math.ceil(feeTableFormatted.length / 2);
        this.feeTableColumnA = feeTableFormatted.slice(0, indexHalf);
        this.feeTableColumnB = feeTableFormatted.slice(indexHalf);
    }

    private generateCustomFeeLineItems(): void {
        this.customFeeLineItems = getActiveFeeSetLineItemDescriptions(
            this.config.report.feeCalculation.invoiceParameters.lineItems,
        );
    }

    ngAfterViewInit() {
        this.generateReportFeeTable();
        this.generateCustomFeeLineItems();
    }
}

interface FeeTableRow {
    // Formatted numbers containing a € symbol.
    damageValue: string;
    feeNet: string;
    feeGross: string;
}

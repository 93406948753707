<mat-form-field class="password-container mat-block" [ngClass]="{ 'password-visible': passwordVisible }">
    <input
        [type]="type === 'password' && !passwordVisible ? 'password' : 'text'"
        [ngClass]="{ 'characters-hidden': !passwordVisible }"
        matInput
        placeholder="{{ placeholder }}"
        [ngModel]="passwordFromParent"
        (input)="determineChangeStatus($event); removePlaceholder($event)"
        [autocomplete]="autocomplete"
        [name]="name"
        [disabled]="disabled"
        (change)="setNewPassword($event)" />
    <mat-icon
        class="password-show-toggle small-icon"
        (click)="togglePasswordVisibility()"
        [matTooltip]="passwordVisible ? 'Passwort verstecken' : 'Passwort anzeigen'">
        remove_red_eye
    </mat-icon>
</mat-form-field>

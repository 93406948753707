import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { CanDeactivateGuard } from '../shared/services/can-deactivate-guard.service';
import { TaskListComponent } from './task-list/task-list.component';
import { TasksModuleComponent } from './tasks-module.component';

export const tasksRoutes: Routes = [
    {
        path: 'Aufgaben',
        component: TasksModuleComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            {
                path: '',
                component: TaskListComponent,
                pathMatch: 'full',
            },
        ],
    },
];

export const tasksRouting = RouterModule.forChild(tasksRoutes);

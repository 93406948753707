/**
 * Rescale and reposition objects on the canvas.
 * @param objects The objects to be repositioned. Their top, left, width and height properties will be adjusted.
 * @param oldFabricPhoto An object describing the old photo's scale and position properties. May be either a
 * fabric.Image or a plain object.
 * @param newFabricPhoto The new fabric.Image onto which the objects will be rendered.
 * @returns {*}
 */
export function rescaleObjects(objects, oldFabricPhoto, newFabricPhoto) {
    // If there are no objects to be rescaled, abort.
    if (!objects || !objects.length) {
        return objects;
    }

    // Calculate the scale factor to bring the objects from the oldFabricPhoto to the scale of the new one
    const serializedToCurrentScale = {
        x: newFabricPhoto.scaleX / oldFabricPhoto.scaleX,
        y: newFabricPhoto.scaleY / oldFabricPhoto.scaleY,
    };

    const newCanvasLeftPadding = newFabricPhoto.getBoundingRect().left;
    const newCanvasTopPadding = newFabricPhoto.getBoundingRect().top;

    objects.forEach((object) => {
        /**
         * image.left and fabricPhoto.left
         *   Depending on the photo's and the canvas' sizes, the photo has margins to the left or top so that the
         *   canvas space may be used as much as possible while keeping the photo's aspect ratio.
         *   Since this may change on different screen sizes, remove the old margin before rescaling the position.left
         *   and then add the current margin back to its position
         */
        object.left = object.left * serializedToCurrentScale.x + newCanvasLeftPadding;
        object.top = object.top * serializedToCurrentScale.y + newCanvasTopPadding;
        object.scaleX = object.scaleX * serializedToCurrentScale.x;
        object.scaleY = object.scaleY * serializedToCurrentScale.y;
    });

    return objects;
}

<mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Dialog schließen">close</mat-icon>
<h2>Wasserzeichen</h2>
<div id="introductory-text" class="label">
    <p>
        Lass auf deinen über Textbausteine generierten Dokumenten (also keine externen wie die DAT-Bewertung) ein
        Wasserzeichen abdrucken.
    </p>

    <p>
        Du hast zwei Optionen, die im Standard die Worte "Duplikat" oder "Entwurf" drucken. Du kannst den Inhalt und die
        Position des Drucks aber selbständig anpassen, indem du die DOCX-Dateien anpasst.
    </p>
</div>

<div id="docx-watermarks-flex-container">
    <docx-watermark-settings-card watermarkType="duplicate"></docx-watermark-settings-card>
    <docx-watermark-settings-card watermarkType="draft"></docx-watermark-settings-card>
</div>

<div class="additional-templates-container helpcenter-link-container">
    <a
        href="https://wissen.autoixpert.de/hc/de/articles/25628666939666-Vorlagen-f%C3%BCr-DOCX-Wasserzeichen"
        (click)="$event.preventDefault(); openHelpCenterArticle()"
        class="help-link">
        Weitere Vorlagen
        <mat-icon class="small-icon">open_in_new</mat-icon>
    </a>
</div>

<div class="close-button-container">
    <button (click)="closeDialog()">Schließen</button>
</div>

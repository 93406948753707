import { IsoDate } from '../../lib/date/iso-date.types';

export class Accident {
    constructor(template: Partial<Accident> = {}) {
        Object.assign(this, template);
    }

    location: string = null; // Unfallort
    date: IsoDate = null; // Unfalltag
    time: string = null; // Unfallzeitpunkt
    kaskoDamageType: KaskoDamageType = null;
    kaskoDamageTypeCustomLabel: string = null;
    recordedByPolice: boolean = null; // Polizeilich erfasst
    caseNumberPolice: string = null; // Schadensnummer bei der Polizei
    policeDepartment: string = null; // Polizeibehörde
    circumstances: string = null; // Schadenhergang
    plausibility: string = null; // Plausibilität. Whether the claimant statements are plausible.
    compatibility?: string; // Kompatibilität. Whether the damages on the involved cars fit the accident data. Optional since only used in GTÜ reports.
}

export type KaskoDamageType = 'animal' | 'fire' | 'theft' | 'glass' | 'hail' | 'other';

import { Component, EventEmitter, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';

@Component({
    selector: 'license-plate-redaction-info-dialog',
    templateUrl: './license-plate-redaction-info-dialog.component.html',
    styleUrls: ['./license-plate-redaction-info-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class LicensePlateRedactionInfoDialogComponent {
    constructor() {}

    @Output() closeClick = new EventEmitter<void>();
    @Output() confirmClick = new EventEmitter<void>();

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.rejectDialog();
        }
    }

    protected rejectDialog() {
        this.closeClick.emit();
    }

    protected confirmDialog() {
        this.confirmClick.emit();
    }
}

import { get } from 'lodash-es';
import { DataTypeBase } from '../../models/indexed-db/database.types';
import { QuickSearchRecord } from '../../models/indexed-db/quick-search-record';

export function boilDownToQuickRecord<DataType extends DataTypeBase>(
    fullRecord: DataType,
    keysOfQuickSearchRecords: string[],
): QuickSearchRecord {
    const quickSearchRecord: QuickSearchRecord = {
        _id: fullRecord._id,
    };

    for (const keyPath of keysOfQuickSearchRecords) {
        quickSearchRecord[keyPath] = get(fullRecord, keyPath);
    }

    return quickSearchRecord;
}

import { AxError } from '../../models/errors/ax-error';

/**
 * Errors may come nested in another errors causedBy property.
 *
 * This method returns an array of all errors present in that chain, starting with the topmost error, proceeding to the most nested error.
 */
export function flattenErrors(axError: AxError | Error): AxError[] {
    const axErrors = [];
    axErrors.push(axError);
    if (typeof axError === 'object' && 'causedBy' in axError && axError.causedBy) {
        axErrors.push(...flattenErrors(axError.causedBy));
    }

    return axErrors;
}

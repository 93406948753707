import { DefaultDocumentOrderGroup } from '@autoixpert/models/teams/default-document-order/default-document-order-group';
import { generateId } from '../../lib/generate-id';
import { FeeTableName } from '../reports/assessors-fee/fee-calculation';
import { PaintThicknessScale } from '../reports/paint-thickness-scale';
import { DefaultDaysUntilDueConfig } from '../user/preferences/default-days-until-due-config';
import {
    CustomFeeConfig,
    DefaultFeeConfig,
    DefaultFeeSetRow,
    FeePreferences,
} from '../user/preferences/user-preferences';
import { ColorPickerPatch } from './color-picker-patch';
import { EmailSchedulingOption } from './email-scheduling-option';

export class TeamPreferences {
    // Car Data
    alwaysDisplayVehicleDimensionsAndWeight: boolean;
    // Photos
    watermark: {
        type: 'image' | 'text';
        imageHash: string;
        text: string;
        textColor: string;
        anchor: 'topLeft' | 'topRight' | 'center' | 'bottomLeft' | 'bottomRight';
        offsetX: number; // Distance from anchor on X axis in decimals, e.g. 0.1 (= 10%)
        offsetY: number;
        aspectRatio: number; // Watermark width over watermark height, e.g. 0.1. For images, the watermark height will be derived from this width and the aspect ratio.
        widthRelativeToPhoto: number; // Width relative to the photo width, e.g. 0.25. For images, the watermark height will be derived from this width and the aspect ratio.
        textScaleXRelativeToPhoto: number;
        textScaleYRelativeToPhoto: number;
        opacity: number; // From 0 to 1, e.g. 0.5 for 50% opacity
        insertAutomatically: boolean;
    };
    preferredPhotoAspectRatio: number;
    // Email Scheduling Options
    emailSchedulingOptions: EmailSchedulingOption[] = [];
    // Invoicing
    factoringEnabled: boolean = false;
    bookInvoiceWhenLockingReport: boolean = false;
    defaultDaysUntilDueConfigs: DefaultDaysUntilDueConfig[] = [
        {
            _id: generateId(),
            numberOfDays: 30,
            reportType: 'default',
        },
    ];
    // Collective Invoice:
    // Print report summary in appendix instead of as line items in invoice
    collectiveInvoiceSummaryAppendix?: boolean;
    // For each report, print all invoice positions
    collectiveInvoiceIncludeInvoiceDetails?: boolean;

    // Invoice List

    /**
     * Sorting and activating documents in a report or invoice process. This structure allows documents to be sorted independently per recipient.
     */
    defaultDocumentOrderGroups: DefaultDocumentOrderGroup[] = [];
    allowCustomDocumentOrderPerRecipient: boolean = false;

    documentsToBeSigned: string[] = [];
    signAllDocumentsAtOnce: boolean = false;
    documentBuildingBlocks: {
        keepNextParagraphConnected: boolean;
    };

    // Amendment (Nachtrag)
    amendmentReportTokenSuffix: string;
    amendmentReportWithReportToken: boolean; // Whether the report shall receive a token or not. Choose no token if you want to generate a number later, within your regular invoice cycle.
    amendmentReportInvoiceNumberSuffix: string;
    amendmentInvoiceShallBeActivatedOnCopy: boolean; // Whether the invoice shall be activated or not by default.
    amendmentInvoiceWithInvoiceNumber: boolean; // Whether the invoice shall receive a number or not. Choose no number if you want to generate a number later, within your regular invoice cycle.

    // InvoiceAudit (Rechnungsprüfung)
    invoiceAuditReportTokenSuffix: string;
    invoiceAuditWithReportToken: boolean; // Whether the report shall receive a token or not. Choose no token if you want to generate a number later, within your regular invoice cycle.
    invoiceAuditInvoiceNumberSuffix: string;
    invoiceAuditInvoiceShallBeActivatedOnCopy: boolean; // Whether the invoice shall be activated or not by default.
    invoiceAuditInvoiceWithInvoiceNumber: boolean; // Whether the invoice shall receive a number or not. Choose no number if you want to generate a number later, within your regular invoice cycle.

    // Compensation
    hoursInRepairWorkday: number = 6; // How many hours are there in a garage's workday? Used to calculate the number of days required for the repair.
    // Color picker
    colorPickerPatches: ColorPickerPatch[] = [];
    // Replacement value correction
    replacementValueCorrectionsPrintSumOnly: boolean = false;
    includeBiddersWithNoBidInCustomResidualValueSheet: boolean = false; // If the custom residual value sheet should include bidders that did not place a bid

    alwaysReduceInputsToDiminishedValueCalculatorByVat: boolean = false;

    /**
     * External API: The user can decide whether the external API should be blocked after exceeding
     * the paid limit or if the additional usage should be billed on top.
     */
    failOnExternalApiCreditsOverdraft: boolean = false;

    // Paint thickness
    /**
     * Shall the legend (which color for which scale section) be printed?
     */
    paintThicknessMeasurementExcludeClassification: boolean = false;
    /**
     * Determines at which steps the different paint measurement categories (e.g. paint ok, paint to thick, etc.) are applied.
     */
    paintThicknessMeasurementScales: PaintThicknessScale[];

    reportOrderTimeShown?: boolean;
    generateReportTokenOnCreate: boolean;

    sourceOfNumberOfPreviousOwnersShown?: boolean;

    // Assessors Fee Descriptions
    assessorsFeeDescriptionLiability: string = null;
    assessorsFeeDescriptionValuation: string = null;
    assessorsFeeDescriptionOldtimerValuationSmall: string = null;
    assessorsFeeDescriptionShortAssessment: string = null;
    assessorsFeeDescriptionPartialKasko: string = null;
    assessorsFeeDescriptionFullKasko: string = null;
    assessorsFeeDescriptionLeaseReturn: string = null;
    assessorsFeeDescriptionUsedVehicleCheck: string = null;
    assessorsFeeDescriptionInvoiceAudit: string = null;

    // Fees
    defaultFeeTableLiability: FeeTableName = 'BVSK';
    defaultFeeTableFullKasko: FeeTableName = 'BVSK';
    defaultFeeTablePartialKasko: FeeTableName = 'BVSK';
    defaultCustomFeeTableIdLiability: string = null;
    defaultCustomFeeTableIdFullKasko: string = null;
    defaultCustomFeeTableIdPartialKasko: string = null;
    bvskFeeConfig: DefaultFeeConfig = new DefaultFeeConfig({
        row: 'higher',
        column: 5,
    });
    hukFeeConfig: DefaultFeeSetRow = 'higher';
    vksFeeConfig: DefaultFeeConfig = new DefaultFeeConfig({
        row: 'higher',
        column: 2,
    });
    cgfFeeConfig: DefaultFeeConfig = new DefaultFeeConfig({
        row: 'higher',
        column: 2,
    });
    customFeeConfigs: CustomFeeConfig[] = [];

    // Default Fees
    defaultFees: FeePreferences = new FeePreferences(); // The user may specify default fees and fees for the HUK table which often differs from the rest.
    liabilityHukFees: FeePreferences = new FeePreferences();

    // Damage details (standard texts)
    previousDamageStandardText = '';
    unrepairedDamageStandardText = '';
    meantimeDamageStandardText = '';
    damageDescriptionStandardText = '';
    plausibilityStandardText = '';
    circumstancesStandardText = '';
    // Optional because only required by GTÜ partner accounts.
    compatibilityStandardText: string;

    // Valuation: damage calculation decrease rounding
    valuation_decreaseRoundToFull: number = 25;
    valuation_decreaseRoundType: 'round' | 'floor' | 'ceil' = 'round';
    valuation_decreasePercentage: number = 50;
    valuation_showDecimalPlaces = false;

    // License plate redaction color (null = use blur effect)
    licensePlateRedactionColor: string = null;
}

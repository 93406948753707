<!-------------- Large license plate -->

<div class="license-plate-container" *ngIf="displayLarge">
    <div
        class="license-plate license-plate-large"
        *ngIf="useGermanFormat && licensePlateHasGermanFormat && !showForeignLicensePlateWhenEmpty">
        <!-- If there are only a few characters in the cityCode, shrink the input field ==> -->
        <!-- by assigning one of three classes that define a fixed with. -->
        <input
            type="text"
            class="cityCode"
            placeholder="XX"
            pattern="[A-Za-z]{1,3}"
            maxlength="3"
            #CityInput
            [disabled]="disabled"
            [(ngModel)]="licensePlateCity"
            (ngModelChange)="
                transformCityToUpperCase();
                joinLicensePlateParts();
                checkLicensePlateFormat();
                replaceDoubleDashesInForeignFormat();
                dispatchLicensePlateChange()
            "
            (blur)="blur.emit()"
            (keydown)="advanceCursorOnSpecialChars($event)"
            (focus)="emitFocusEvent($event)"
            [ngClass]="{
                'short-city-code': licensePlateCity.length === 1,
                'medium-city-code': licensePlateCity.length === 2 || licensePlateCity.length === 0,
                'long-city-code': licensePlateCity.length > 2,
            }" />
        <img src="/assets/images/icons/license-plate-divider-large.png" />
        <input
            type="text"
            class="initials-code"
            placeholder="XX"
            maxlength="2"
            [(ngModel)]="licensePlateInitials"
            [disabled]="disabled"
            pattern="[A-Za-z]{1,2}"
            (ngModelChange)="
                transformInitialsToUpperCase();
                joinLicensePlateParts();
                checkLicensePlateFormat();
                replaceDoubleDashesInForeignFormat();
                dispatchLicensePlateChange()
            "
            (blur)="blur.emit()"
            (keydown)="advanceCursorOnSpecialChars($event)"
            (focus)="emitFocusEvent($event)"
            #InitialsInput
            [ngClass]="{
                'initials-code-of-length-2': licensePlateInitials.length === 2 || licensePlateInitials.length === 0,
            }" />
        <!-- The names of the classes account for the space inside the individualCode string. -->
        <input
            type="text"
            class="number-code"
            placeholder="0000"
            maxlength="5"
            pattern="[0-9]{1,4}[EH]?"
            [disabled]="disabled"
            #NumberInput
            [(ngModel)]="licensePlateNumbers"
            (keypress)="filterOutCharsOutsidePattern($event)"
            (focus)="emitFocusEvent($event)"
            (ngModelChange)="
                transformNumberToUpperCase();
                joinLicensePlateParts();
                checkLicensePlateFormat();
                replaceDoubleDashesInForeignFormat();
                dispatchLicensePlateChange()
            "
            (blur)="blur.emit()"
            [ngClass]="{
                'number-code-of-length-1': licensePlateNumbers.length === 1,
                'number-code-of-length-2': licensePlateNumbers.length === 2,
                'number-code-of-length-3': licensePlateNumbers.length === 3,
                'number-code-of-length-4': licensePlateNumbers.length === 4 || licensePlateNumbers.length === 0,
                'number-code-of-length-5': licensePlateNumbers.length === 5,
            }" />
        <ng-container *ngTemplateOutlet="typeToggle"></ng-container>
    </div>
    <div
        class="license-plate license-plate-large"
        *ngIf="!useGermanFormat || !licensePlateHasGermanFormat || showForeignLicensePlateWhenEmpty">
        <input
            class="foreign-license-plate-input"
            type="text"
            placeholder="Frei"
            [disabled]="disabled"
            [(ngModel)]="_licensePlate"
            (ngModelChange)="transformLicensePlateToUpperCase(); dispatchLicensePlateChange()"
            (blur)="blur.emit()"
            #foreignLicensePlateInput
            [style.width]="autoSizeHelper.scrollWidth > 85 ? autoSizeHelper.scrollWidth + 'px' : '85px'"
            matTooltip="Gib ein beliebiges Kennzeichen ein" />
        <span class="license-plate-auto-size-helper" #autoSizeHelper>
            <!-- Helper that makes the above input automatically adjust to the required width of the text inside -->
            <!-- We put the text inside a span to determine the required width and then bind that width to the input field -->
            <!-- Using native size attribute of the input or css unit "ch" does not work, because the Euro Plate font is not monospaced -->
            {{ _licensePlate }}
        </span>
        <ng-container *ngTemplateOutlet="typeToggle"></ng-container>
    </div>
    <ng-template #typeToggle>
        <!-- Type toggle that can be inserted in two different places -->
        <mat-icon
            class="license-plate-type-toggle"
            (click)="toggleLicensePlateType()"
            *ngIf="showTypeSwitcher && !disabled"
            [matTooltip]="
                useGermanFormat && licensePlateHasGermanFormat
                    ? 'Anderen Kennzeichentyp eingeben'
                    : 'Deutsches Kennzeichen eingeben'
            ">
            swap_horiz
        </mat-icon>
    </ng-template>
</div>

<!-------------- Small license plate -->

<!--********** German format **********-->
<span class="license-plate-read-only" *ngIf="!displayLarge && licensePlateHasGermanFormat">
    {{ licensePlateCity }}
    <img src="/assets/images/icons/license-plate-divider.png" />
    {{ licensePlateInitials }} {{ licensePlateNumbers }}
</span>

<!--********** Foreign format **********-->
<span class="license-plate-read-only" *ngIf="!displayLarge && !licensePlateHasGermanFormat">
    {{ _licensePlate }}
</span>

export const GLOBAL_ADMIN_USERS = [
    // autoiXpert team members
    'sl@autoixpert.de',
    'ml@autoixpert.de',
    'as@autoixpert.de',

    'benjamin.assadsolimani@autoixpert.de',
    'jonathan.wienes@autoixpert.de',
    'lukas.grossmann@autoixpert.de',
    'olga.hirth@autoixpert.de',
    'sandra.bader@autoixpert.de',
    'sven.fackert@autoixpert.de',

    // User for creating and maintaining shared public data, such as insurance addresses.
    'common-data-creator@autoixpert.de',
    // Used for password resets
    'app@autoixpert.de',
];

export const GLOBAL_ACCOUNTING_ADMIN_USERS = [
    // autoiXpert founders
    'sl@autoixpert.de',
    'ml@autoixpert.de',
    'as@autoixpert.de',

    // Accounting team
    'sandra.bader@autoixpert.de',
];

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { translateReportType } from '@autoixpert/lib/report/translate-report-type';
import { ReportType } from '@autoixpert/models/reports/report';
import { damageReportTypes } from '@autoixpert/static-data/reports/report-type-groups';

/**
 * Select multiple report types (e.g. liability, kasko, valuation).
 *
 * Displays all available report types. Highlights all selected ones.
 */
@Component({
    selector: 'report-types-dialog',
    templateUrl: 'report-types-dialog.component.html',
    styleUrls: ['report-types-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class ReportTypesDialogComponent {
    @Input() selectedReportTypes: ReportType[];
    @Input() showOnlyDamageReportTypes: boolean = false;
    @Input() hideShortAssessment: boolean = false;
    @Output() selectedReportTypesChange: EventEmitter<ReportType[]> = new EventEmitter();

    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    public damageReportTypes: ReportType[] = damageReportTypes;
    public valuationReportTypes: ReportType[] = [
        'valuation',
        'oldtimerValuationSmall',
        'leaseReturn',
        'usedVehicleCheck',
    ];
    public otherReportTypes: ReportType[] = ['invoiceAudit'];

    public toggleReportType(reportType: ReportType) {
        if (this.selectedReportTypes.includes(reportType)) {
            removeFromArray(reportType, this.selectedReportTypes);
        } else {
            this.selectedReportTypes.push(reportType);
        }
        this.emitReportTypesChange();
    }

    //*****************************************************************************
    //  Translation
    //****************************************************************************/
    public translateReportType = translateReportType;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Translation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog(): void {
        this.close.emit();
    }

    public emitReportTypesChange(): void {
        this.selectedReportTypesChange.emit(this.selectedReportTypes);
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.closeDialog();
            event.stopPropagation();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

<div class="synchronize-credentials-container" @fadeInAndOut>
    <div class="synchronize-credentials-first-column">
        <!-- Icon -->
        <mat-icon>vpn_key</mat-icon>
    </div>

    <div class="synchronize-credentials-second-column">
        <!-- Headline -->
        <div class="synchronize-credentials-headline">Neue Zugangsdaten</div>

        <!-- Message -->
        <div class="synchronize-credentials-message">
            Für alle Nutzer-Accounts
            <a class="synchronize-credentials-accept" (click)="confirmClick.emit()">übernehmen.</a>
        </div>

        <!-- Close button -->
        <div class="synchronize-credentials-close" (click)="cancelClick.emit()">
            <mat-icon class="toolbar-icon">close</mat-icon>
        </div>
    </div>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { User } from '@sentry/angular';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { CarEquipment } from '@autoixpert/models/reports/car-identification/car-equipment';
import { AudatexTaskListItem } from '@autoixpert/models/reports/damage-calculation/audatex-task-list-item';
import { DamageCalculation } from '@autoixpert/models/reports/damage-calculation/damage-calculation';
import { AudatexDowntimeCompensation } from '@autoixpert/models/reports/damage-calculation/downtime-compensation/audatex-downtime-compensation';
import { Repair } from '@autoixpert/models/reports/damage-description/repair';
import { AudatexValuation } from '@autoixpert/models/reports/market-value/audatex-valuation';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import {
    ConnectAudatexTaskDialogComponent,
    ConnectAudatexTaskDossierDialogData,
} from 'src/app/reports/details/shared/connect-audatex-task-dialog/connect-audatex-task-dialog.component';
import { getSelectedAudatexValuationResult } from '../../libraries/audatex-helpers/get-selected-audatex-valuation-result';
import { clearRepairCalculationData } from '../../libraries/damage-calculation/clear-repair-calculation-data';
import { determineDamageType } from '../../libraries/damage-calculation/determine-damage-type';
import { persistExportedGarageFeeSet } from '../../libraries/damage-calculation/persist-exported-garage-fee-set';
import { clearDatValuationData } from '../../libraries/dat-helpers/clear-dat-valuation-data';
import { getAudatexErrorHandlers } from '../../libraries/error-handlers/get-audatex-error-handlers';
import { confirmToRemoveValueIncreaseFromReport } from '../../libraries/report/confirm-to-remove-value-increase-from-report';
import { setVehicleValue } from '../../libraries/report/set-vehicle-value';
import { ApiErrorHandleParameters, ApiErrorService } from '../api-error.service';
import { LoggedInUserService } from '../logged-in-user.service';
import { NewWindowService } from '../new-window.service';
import { ReportService } from '../report.service';
import { UserPreferencesService } from '../user-preferences.service';

@Injectable()
export class AudatexTaskService {
    constructor(
        private httpClient: HttpClient,
        private loggedInUserService: LoggedInUserService,
        public userPreferences: UserPreferencesService,
        private newWindowService: NewWindowService,
        private reportService: ReportService,
        private apiErrorService: ApiErrorService,
        private dialog: MatDialog,
    ) {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();
    }

    private user: User;
    private team: Team;
    private apiPath = '/api/v0';

    private _create(reportId: Report['_id']): Promise<AudatexTaskCreationResponse> {
        return this.httpClient
            .post<AudatexTaskCreationResponse>(`${this.apiPath}/reports/${reportId}/audatex/task`, {})
            .toPromise();
    }

    private _patch(reportId: Report['_id'], audatexTaskId: Report['audatexTaskId']): Promise<{ success: boolean }> {
        return this.httpClient
            .patch<{ success: boolean }>(`${this.apiPath}/reports/${reportId}/audatex/task/${audatexTaskId}`, {})
            .toPromise();
    }

    private _find(
        reportId: Report['_id'],
        {
            requireValuation = true,
            requireCalculation = true,
        }: {
            requireValuation?: boolean;
            requireCalculation?: boolean;
        } = {},
    ): Promise<AudatexTaskFindResponse> {
        let httpParams = new HttpParams();
        if (requireValuation) {
            httpParams = httpParams.append('requireValuation', 'true');
        }
        if (requireCalculation) {
            httpParams = httpParams.append('requireCalculation', 'true');
        }

        return this.httpClient
            .get<AudatexTaskFindResponse>(`${this.apiPath}/reports/${reportId}/audatex/task`, {
                params: httpParams,
            })
            .toPromise();
    }

    public findAll(): Promise<AudatexTaskListItem[]> {
        return this.httpClient.get<AudatexTaskListItem[]>(`${this.apiPath}/audatex/tasks`).toPromise();
    }

    public getDowntimeCompensation(reportId: Report['_id']): Promise<AudatexDowntimeCompensation> {
        return this.httpClient
            .get<AudatexDowntimeCompensation>(`${this.apiPath}/reports/${reportId}/audatexDowntimeCompensation`)
            .toPromise();
    }

    private _delete(reportId: Report['_id']): Promise<any> {
        return this.httpClient.delete<any>(`${this.apiPath}/reports/${reportId}/audatex/task`).toPromise();
    }

    /**
     * Allow the components to display a loading spinner while the request is pending.
     */
    private isRequestPending$ = new BehaviorSubject<boolean>(false);
    public readonly isRequestPending$$ = this.isRequestPending$.asObservable();
    public async abortPendingIndicator() {
        this.isRequestPending$.next(false);
    }

    public async create({ report }: { report: Report }) {
        this.isRequestPending$.next(true);
        const creationResponse = await this._create(report._id);
        report.audatexTaskId = creationResponse.audatexTaskId;
        report.valuation.valuationProvider = 'audatex';

        // Save taskId to the server. The server must know the process ID to export the repair garage's wages.
        await this.reportService.put(report, { waitForServer: true });
        this.isRequestPending$.next(false);
    }

    public async update({ report, options = {} }: { report: Report; options?: { garageFeeSet?: GarageFeeSet } }) {
        if (report.state === 'done') return;

        this.isRequestPending$.next(true);

        await this._patch(report._id, report.audatexTaskId);
        if (report.damageCalculation) {
            persistExportedGarageFeeSet(report, options.garageFeeSet);
        }
        await this.reportService.put(report, { waitForServer: true });
        this.isRequestPending$.next(false);
    }

    /**
     * Returns the Audatex Task without merging into the report.
     * This is relevant, e.g. for fetching the identification in the CarDataComponent
     */
    public async loadTask({ report }: { report: Report }): Promise<AudatexTaskFindResponse> {
        if (report.state === 'done') return;

        this.isRequestPending$.next(true);

        try {
            return await this._find(report._id, {
                requireValuation: false,
                requireCalculation: false,
            });
        } finally {
            this.isRequestPending$.next(false);
        }
    }

    /**
     * DAT separates Valuation and Calculation. Audatex has both in one task.
     * For best user experience, we always try to import both, since then use may calculate and valuate in the same UI workflow.
     * If an error occurs, we need to know weather the user wants to import the calculation or the valuation.
     * If the error occurs in a part the user does not want to import (e.g. valuation is missing), the backend may ignore it.
     */
    public async retrieveResults({
        report,
        requireValuation,
        requireCalculation,
    }: {
        report: Report;
        requireValuation?: boolean;
        requireCalculation?: boolean;
    }): Promise<{ audatexGrossValueWasCalculatedWithAutoixpertTaxRate?: boolean }> {
        if (report.state === 'done') return;

        this.isRequestPending$.next(true);
        try {
            const audatexFindResponse = await this._find(report._id, { requireValuation, requireCalculation });

            let audatexGrossValueWasCalculatedWithAutoixpertTaxRate;

            // If the audatex valuation was included in the task and imported, merge it into the report.
            if (audatexFindResponse.valuation) {
                audatexGrossValueWasCalculatedWithAutoixpertTaxRate = this.retrieveValuationResults({
                    report,
                    audatexFindResponse,
                });
            }
            if (audatexFindResponse.damageCalculation) {
                await this.retrieveCalculationResults({ report, audatexFindResponse });
            }

            determineDamageType(report, this.userPreferences);

            await this.reportService.put(report, { waitForServer: true });

            return { audatexGrossValueWasCalculatedWithAutoixpertTaxRate };
        } finally {
            this.isRequestPending$.next(false);
        }
    }

    /**
     * Import the valuation results, merge them into the report, add necessary documents and save the report back to the server.
     */
    private retrieveValuationResults({
        report,
        audatexFindResponse,
    }: {
        report: Report;
        audatexFindResponse: AudatexTaskFindResponse;
    }): boolean {
        report.valuation.audatexValuation = audatexFindResponse.valuation;
        if (report.type === 'valuation') {
            report.valuation.referenceDate = audatexFindResponse.valuation.referenceDate;
            report.valuation.vehicleValueType = report.valuation.vehicleValueType || 'replacementValue';
        }

        if (!report.valuation.audatexValuation.valuesRetrieved) {
            report.valuation.audatexValuation.valuesRetrieved = true;
        }

        /**
         * If the user identified the vehicle within the Schwacke database, save that to the report. That way, the next time the user
         * opens the Audatex valuation, autoiXpert will send him to the valuation screen instead of the identification screen.
         */
        report.car.audatexIdentification.schwackeCode = audatexFindResponse.car.audatexIdentification.schwackeCode;

        const { valueGross, valueNet, type, audatexGrossValueWasCalculatedWithAutoixpertTaxRate } =
            getSelectedAudatexValuationResult({ report });

        if (!valueGross) {
            throw new AxError({
                code: 'AUDATEX_VALUATION_MISSING',
                message: 'Audatex valuation is missing',
            });
        }

        report.valuation.originalPriceWithoutEquipmentNet =
            audatexFindResponse.valuation.originalPriceWithoutEquipmentNet;
        report.valuation.originalPriceWithoutEquipmentGross =
            audatexFindResponse.valuation.originalPriceWithoutEquipmentGross;
        report.valuation.originalPriceWithEquipmentNet = audatexFindResponse.valuation.originalPriceWithEquipmentNet;
        report.valuation.originalPriceWithEquipmentGross =
            audatexFindResponse.valuation.originalPriceWithEquipmentGross;

        // Valuation reports handle the value themselves
        if (report.type !== 'valuation') {
            report.valuation.valuationProvider = 'audatex';

            setVehicleValue({
                valueNet: null,
                valueGross,
                report,
                userPreferences: this.userPreferences,
            });

            // The valuation has this document in its DOCX file anyway, so don't add it as an attachment.
            this.addAudatexValuationDocument({ report });
        }

        return audatexGrossValueWasCalculatedWithAutoixpertTaxRate;
    }

    private async retrieveCalculationResults({
        report,
        audatexFindResponse,
    }: {
        report: Report;
        audatexFindResponse: AudatexTaskFindResponse;
    }) {
        const increasedValueExistedBefore: boolean = !!report.damageCalculation.repair.increasedValue;

        Object.assign<Repair, AudatexTaskFindResponse['damageCalculation']['repair']>(
            report.damageCalculation.repair,
            audatexFindResponse.damageCalculation.repair,
        );

        // If there's a value increase in the calculation, copy that to the report so that it's printed.
        if (report.damageCalculation.repair.increasedValue) {
            report.valuation.valueIncrease = report.damageCalculation.repair.increasedValue;
        }

        // If the value increase has been removed from the calculation, offer the user to remove it from the report as well.
        else if (increasedValueExistedBefore && report.valuation.valueIncrease) {
            await confirmToRemoveValueIncreaseFromReport({ report, dialogService: this.dialog });
        }

        // If the user wants the damage calculation to be available as an individual document in the print and send screen, create it here.
        if (report.type !== 'liability' || this.userPreferences.createDocumentMetadataForDamageCalculation) {
            addDocumentToReport({
                report,
                team: this.team,
                newDocument: new DocumentMetadata({
                    type: 'manualCalculation', //TODO: Add document type for audatex calculation
                    title: 'Audatex Kalkulation',
                    uploadedDocumentId: null,
                    permanentUserUploadedDocument: false,
                    createdAt: moment().format(),
                    createdBy: this.user._id,
                }),
                documentGroup: 'report',
            });
        }
    }

    public async unlink({ report, options = {} }: { report: Report; options?: { showConfirmDialog?: boolean } }) {
        if (report.state === 'done') return;

        // Clear damage calculation
        clearRepairCalculationData(report);

        // Includes save to server
        await this.removeValuationData({ report });
    }

    public async removeCalculationData({ report }: { report: Report }) {
        if (report.state === 'done') return;

        clearRepairCalculationData(report);
        await this.reportService.put(report, { waitForServer: true });
    }

    public async removeValuationData({ report }: { report: Report }) {
        if (report.state === 'done') return;

        report.valuation.audatexValuation = new AudatexValuation();
        report.valuation.valuationProvider = null;
        await this.reportService.put(report, { waitForServer: true });
    }

    public async openTaskConnectionDialog({ report }: { report: Report }) {
        if (report.state === 'done') return;

        const audatexTaskId = await this.dialog
            .open<ConnectAudatexTaskDialogComponent, ConnectAudatexTaskDossierDialogData, Report['audatexTaskId']>(
                ConnectAudatexTaskDialogComponent,
                {
                    data: {
                        licensePlate: report.car.licensePlate,
                        vin: report.car.vin,
                    },
                    maxWidth: '700px',
                },
            )
            .afterClosed()
            .toPromise();

        if (!audatexTaskId) return;

        report.damageCalculation.repair.calculationProvider = 'audatex';
        report.audatexTaskId = audatexTaskId;

        // Save the report so that the server knows about the new task ID, then retrieve the results.
        await this.reportService.put(report, { waitForServer: true });
        await this.retrieveResults({ report });
    }

    public addAudatexValuationDocument({ report }: { report: Report }) {
        addDocumentToReport({
            team: this.team,
            report,
            newDocument: new DocumentMetadata({
                type: 'audatexMarketAnalysis',
                title: 'Audatex Bewertung',
                uploadedDocumentId: null,
                permanentUserUploadedDocument: false,
                createdAt: moment().format(),
                createdBy: this.user._id,
            }),
            documentGroup: 'report',
        });
    }

    //*****************************************************************************
    //  Audatex Valuation UI
    //****************************************************************************/
    public async openUI({
        report,
        step,
    }: {
        report: Report;
        step?: 'VehicleIdentification' | 'VehicleValues' | 'CalculationResults' | 'CalculationParameters';
    }) {
        if (!step) {
            step = report.state === 'done' ? 'CalculationResults' : 'CalculationParameters';
        }

        this.newWindowService.open(
            `https://www.audanet.de/axnlogin/opentask.do` +
                `?task=${report.audatexTaskId}` +
                `&username=${this.user.audatexUser?.username}` +
                `&password=${encodeURIComponent(this.user.audatexUser?.password || '')}` +
                `&work=CompleteAssessment` +
                `&usenewui=true` +
                `&step=${step}`,
            '_blank',
            'noopener',
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex Valuation UI
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Implement a specific error handler to handle possible DAT valuation errors.
     * This handler provides consistent error messages, e.g. between create() and createAndOpen()
     */
    public handleAndRethrow({
        axError,
        report,
        defaultHandler,
    }: {
        axError: AxError;
        report: Report;
        defaultHandler: ApiErrorHandleParameters['defaultHandler'];
    }) {
        this.isRequestPending$.next(false);
        this.apiErrorService.handleAndRethrow({
            axError,
            handlers: {
                ...getAudatexErrorHandlers(),
                AUDATEX_VALUATION_NOT_POSSIBLE: (error) => ({
                    title: 'Audatex-Bewertung nicht möglich',
                    body: error.message,
                }),
                AUDATEX_VALUATION_MISSING: {
                    title: 'Bewertung fehlt',
                    body: 'Bitte führe erst die <a href="https://wissen.autoixpert.de/hc/de/articles/6505826848018" target="_blank" rel="noopener">Bewertung in Audatex</a> aus und ermittle einen der folgenden Werte: Händereinkaufspreis, Händlerverkaufspreis, Marktwert oder Wiederbeschaffungswert.<br><br><strong>Meldung "Fehler im Schwacke-Service"?</strong> <a href="https://www.audatex.de/service-support" target="_blank" rel="noopener">Kontaktiere Audatex</a> für weitere Infos.',
                    timeout: 10000,
                    partnerLogo: 'audatex',
                },
                REPORT_NOT_FOUND: {
                    title: 'Gutachten nicht gefunden',
                    body: 'Das Gutachten konnte nicht auf dem Server gefunden werden. Falls es noch nicht synchronisiert wurde, versuche es gleich erneut. Lade ggf. die Seite neu, weil das den Sync anstößt.',
                },
            },
            defaultHandler: defaultHandler ?? {
                title: 'Fehler in Audatex-Schnittstelle',
                body: 'Probiere es erneut oder kontaktiere die Hotline.',
                partnerLogo: 'audatex',
            },
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Error Handling
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface AudatexTaskCreationResponse {
    audatexTaskId: string;
}

export interface AudatexTaskFindResponse {
    audatexTaskId: string;
    car: Car;
    damageCalculation: DamageCalculation;
    valuation: AudatexValuation;
    carEquipment: CarEquipment;
}

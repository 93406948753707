import { DateTime } from 'luxon';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { RequireSome } from '../../helper-types/require-some';
import { IsoDate } from '../../lib/date/iso-date.types';
import { generateId } from '../../lib/generate-id';
import { calculateDueDate } from '../../lib/invoices/calculate-due-date';
import { BillingAccount } from '../billing/billing-account';
import { ContactPerson } from '../contacts/contact-person';
import { DocumentMetadata } from '../documents/document-metadata';
import { DataTypeBase } from '../indexed-db/database.types';
import { InternalNote } from '../internal-note';
import { Label } from '../labels/label';
import { OutgoingEmailMessage, OutgoingMessage } from '../outgoing-message';
import { LineItem } from './line-item';
import { Payment } from './payment';
import { PaymentReminder } from './payment-reminder';
import { ReportData } from './report-data';

export class Invoice implements DataTypeBase {
    constructor(template?: Partial<Invoice>) {
        this._id = generateId();
        if (template) {
            Object.assign(this, template);
        }

        if (!this.dueDate) {
            // Set calculated dueDate if not set by template
            this.dueDate = calculateDueDate(this.date, this.daysUntilDue);
        }

        // The first reminder would be due on the due date.
        if (!this.nextPaymentReminderAt) {
            this.nextPaymentReminderAt = this.dueDate;
        }
    }

    _id: string;
    number: string = null; // Rechnungsnummer
    currencyCode: string = 'EUR'; // 3-stelliges Währungskürzel
    intro: string = null; // Einleitungstext
    subject: string = null; // Rechnungstitel
    /**
     * We have different invoices in autoixpert - most are actual invoices where the assessor expects a payment from his client.
     * Others are credit notes, invoices without amount, ... which will never be paid. Therefore it was hard to decide, weather such an invoice was paid or not.
     *
     * We decided to mark all invoices where the assessor is still waiting for a payment (which is currently outstanding) with a flag "hasOutstandingPayments".
     * Credit notes, invoices with amount 0, cancellation invoices and fully cancelled invoices will not have outstanding payments.
     */
    hasOutstandingPayments: boolean = false;
    /**
     * German "Innergemeinschaftliche Leistung"
     * Set to true if this invoice should not contain value added tax (VAT) because the recipient needs to pay the VAT in his country.
     * This works when sending an invoice from an assessor office in one EU country to a customer in another EU country.
     */
    euDelivery: boolean = false;
    // Involved Parties
    recipient: InvoiceInvolvedParty = new InvoiceInvolvedParty({ role: 'invoiceRecipient' });
    claimant: InvoiceInvolvedParty = null;
    lawyer: InvoiceInvolvedParty = null;
    insurance: InvoiceInvolvedParty = null;
    date: IsoDate = null; // Rechnungsdatum
    supplyPeriodStart: IsoDate = null; // For supply periods, this is the start of the period. End date is stored in dateOfSupply.
    dateOfSupply: IsoDate = null; // If supplyPeriodStart is set, this is the end date of the period.
    daysUntilDue: number = 30;
    dueDate: IsoDate = null;
    lineItems: LineItem[] = [];
    totalNet: number = null;
    totalGross: number = null;
    vatRate: 0 | 0.19 | 0.16 | 0.2 = null;
    /**
     * If the VAT rate is 0, this reason should be set.
     *
     * _smallBusiness_
     * "Kleinunternehmerregelung" (§ 19 UStG). Small companies in Germany do not have to deal
     * with VAT if their revenue is below a certain threshold.
     *
     * _reverseCharge_
     * "Reverse Charge" (§ 13b UStG) allows the recipient of the goods or services to pay the VAT instead of the supplier.
     * This simplifies creating invoices for cross-border goods and services since the recipient applies his national and
     * probably well-known VAT tax law.
     *
     * _companyInternalInvoice_
     * Invoices between companies of the same group are not subject to VAT. The final recipient company will pay the VAT in the end.
     * Relevant for Schumann group companies (Schaden Tax GmbH, Sachverständigenbüro Schumann GmbH, Ingenieurbüro Lehnert GmbH, Schumann Prüf GmbH, Lehnert Automotive Consult GmbH).
     * Sources:
     * - https://debitoor.de/lexikon/innenumsatz
     * - https://www.haufe.de/umsatzsteuer/organschaft-22-innenumsaetze-innerhalb-des-unternehmens_idesk_PI1996_HI844939.html
     */
    vatExemptionReason: 'smallBusiness' | 'reverseCharge' | 'companyInternalInvoice';

    bankAccount: BillingAccount = new BillingAccount();
    secondBankAccount = new BillingAccount();
    reportIds: Array<string> = null;
    printReportSummary: boolean = null;
    factoringEnabled: boolean = null;

    /**
     * True if an XRechnung XML file should be embedded into the invoice PDF.
     */
    isElectronicInvoiceEnabled: boolean;
    /**
     * This is true if the user explicitly set the electronic invoice status in the report invoice parameters. This flag
     * prevents the status from being changed when the invoice recipient's data changes or when the user enters the
     * report invoice screen.
     *
     * This flag does not exist on invoices since invoices have no automatic electronic invoice status changes.
     */
    isElectronicInvoiceStatusFrozen: boolean;

    officeLocationId: string = null;
    /**
     * The assessor who is responsible for the associated report. This is relevant to get the assessors cost center for the DATEV export.
     * Another (future) use case: Improve the revenue per assessor analytics for individual invoices (see JIRA: AX-3755).
     */
    associatedAssessorId: string = null;
    adeltafinanzProcessId: string = null;
    /**
     * Payments could include actual payments or cancellations (short payments, partial cancellations).
     */
    payments: Payment[] = [];
    /**
     * When is the next payment reminder due for this invoice?
     *
     * This date must be updated when changing payment reminder dates.
     * The invoice list displays may filter by this date.
     */
    nextPaymentReminderAt: IsoDate = null;
    /**
     * Since all invoices have nextPaymentReminderAt, we need to know if there is a payment reminder at all (e.g. for filtering)
     * Before 2024-08-16, we used the documents array to check if there is a payment reminder. This would be too expensive using Atlas Search.
     * Therefore we introduced this flag.
     */
    hasPaymentReminder: boolean = false;
    factoringProvider?: InvoiceInvolvedParty;
    documents: DocumentMetadata[] = [];
    documentOrders: DocumentOrder[] = [];

    //*****************************************************************************
    //  Cancellation (Storno)
    //****************************************************************************/
    //*****************************************************************************
    //  Root Invoice
    //****************************************************************************/
    /**
     * The invoices that this invoice is (partially) canceled by.
     * - This property only exists on root invoices.
     * - Must be explicitly extended on the root invoice when creating cancellation invoices.
     */
    idsOfCancellationInvoices: Invoice['_id'][] = [];

    /**
     * Has this invoice been fully canceled by another cancellation invoice?
     * (= vollständig storniert)
     * - This property only exists on root invoices.
     * - Fully canceled invoices (and their cancellation invoice counterparts) are excluded from all analytics for simplicity because the user is only
     *   interested in income-effective invoices.
     *   Partially canceled & partial cancellation invoices are still included in the analytics module.
     */
    isFullyCanceled = false;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Root Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Cancellation Invoice
    //****************************************************************************/
    /**
     * The invoice that is (partially) canceled by this invoice.
     * - This property only exists on cancellation invoices (both full and partial).
     */
    rootInvoiceId: Invoice['_id'] = null;
    /**
     * Has this invoice canceled out another invoice fully?
     * (= Vollstornorechnung)
     * - This property only exists on cancellation invoices (only full cancellation).
     * - Full cancellation invoices (and their canceled counterparts) are excluded from all analytics for simplicity.
     *   The user is only interested in income-effective invoices. Partially canceled & partial cancellation invoices are still included in the analytics module.
     */
    isFullCancellationInvoice = false;

    // Store the invoice type for fast sorting (invoice, creditNote or cancellationInvoice)
    type: InvoiceType = 'invoice';
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Cancellation Invoice
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Cancellation (Storno)
    /////////////////////////////////////////////////////////////////////////////*/

    notes: InternalNote[] = [];
    lockedAt: string = null;
    lockedBy: string = null;

    // Report Data
    reportsData: Array<ReportData> = [];

    // Label
    labels: Label[] = [];

    // Collective Invoice
    isCollectiveInvoice?: boolean;
    collectiveInvoiceCustomInvoiceLineItemTitle?: string;

    // Imported from 3rd-party system.
    importedFromThirdPartySystem?: 'dynarex' | 'gtue';
    // ID from the 3rd party system. Used in case we need to attach more data to it later.
    idInThirdPartySystem?: string;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string = null;
    teamId: string = null;

    _documentVersion: number = 0;
    _schemaVersion = 10 as const;
}

export class PaymentReminders {
    // Payment Reminders are only created when payment reminder documents are created
    level0: PaymentReminder = null;
    level1: PaymentReminder = null;
    level2: PaymentReminder = null;
    level3: PaymentReminder = null;
}

/**
 * In contrast to the involved party on the report object, this object does not contain a contact person. The contact person will
 * always be loaded from the report object. This enables the user to only change the details once: on the report.
 */
export class InvoiceInvolvedParty {
    constructor(template: RequireSome<InvoiceInvolvedParty, 'role'>) {
        if (!template.role) throw Error('Missing role when creating an instance of InvoiceInvolvedParty.');

        Object.assign(this, template);
    }

    role: InvoiceInvolvedPartyRole = null;
    contactPerson: ContactPerson = new ContactPerson(); // The contactPerson is only available after we attached it to the involved parties claimant, lawyer, insurance in the frontend. The recipient has it in all cases.
    receivedEmail: boolean = null;
    receivedLetter: boolean = null;
    outgoingMessageDraft: OutgoingEmailMessage = new OutgoingEmailMessage();
    paymentReminders: PaymentReminders = new PaymentReminders();
}

//*****************************************************************************
//  Description
//****************************************************************************/
/**
 * This script prevents cyclic imports between payment-reminder.ts and invoice-involved-party.ts.
 *
 * The error "Cannot access 'invoiceInvolvedPartyRoles' before initialization" is rather misleading. We found a
 * cyclic import to be the root cause through this Stack Overflow post: Cannot access 'invoiceInvolvedPartyRoles' before initialization
 */
/////////////////////////////////////////////////////////////////////////////*/
//  END Description
/////////////////////////////////////////////////////////////////////////////*/

export const invoiceInvolvedPartyRoles = [
    'invoiceRecipient',
    'claimant',
    'lawyer',
    'insurance',
    'leaseProvider',
] as const;
export type InvoiceInvolvedPartyRole = (typeof invoiceInvolvedPartyRoles)[number];

// Have a type invoice recipients like report recipients (involved parties and recipients are not the same in reports)
export const invoiceRecipientRoles = ['invoiceRecipient', 'claimant', 'lawyer', 'insurance', 'leaseProvider'] as const;
export type InvoiceRecipientRole = (typeof invoiceInvolvedPartyRoles)[number];

/**
 * Denormalize the invoice type for faster indexing and sorting.
 *
 * - invoice: Regular invoices with a positive total amount.
 * - creditNote: Partial cancellation invoices (short payments) or credit notes (Gutschriften) with a negative total amount.
 * - cancellationInvoice: Full cancellation invoices (Stornorechnungen) with a negative total amount.
 */
export type InvoiceType = 'invoice' | 'creditNote';

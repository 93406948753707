import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { LoggedInUserService } from '../services/logged-in-user.service';
import { ToastService } from '../services/toast.service';

@Injectable()
export class RefreshFrontendInterceptor implements HttpInterceptor {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private router: Router,
        private toastService: ToastService,
        private serviceWorker: SwUpdate,
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler) {
        // Pass on the configured observable to the caller of this interceptor
        return next.handle(request).pipe(
            // configure dealing with the specific error type
            catchError((error: AxError) => {
                // if schema validation failed, the user is likely to use an old version of the frontend
                if (['SCHEMA_VALIDATION_FAILED', 'MONGOOSE_CAST_TO_EMBEDDED_FAILED'].includes(error.code)) {
                    console.log('Attempt to save data in old schema detected. Checking for new frontend version...');

                    // With service workers (production)
                    if (this.serviceWorker.isEnabled) {
                        this.serviceWorker
                            .checkForUpdate()
                            .then((isUpdateAvailable: boolean) => {
                                if (!isUpdateAvailable) {
                                    this.toastService.error(
                                        'Datenfehler',
                                        `Es scheint Probleme mit der Struktur der Daten zu geben. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                                    );
                                } else {
                                    // The update will be downloaded and a notification will be shown to the user through the checkForUpdate() call above.
                                }
                            })
                            .catch((error) => {
                                console.error(
                                    'Error while checking for updates after an error with code "SCHEMA_VALIDATION_FAILED" or "MONGOOSE_CAST_TO_EMBEDDED_FAILED".',
                                    error,
                                );
                            });
                    }
                    // ... and without service workers (development)
                    else {
                        // Since we now use the sync issue indicator to report issues, there's no need to reload the app on development.
                        //this.appVersionUpdateService.warnUserAndRemoveLocalData();
                        //window.setTimeout(() => {
                        //    document.location.reload();
                        //}, 10000);

                        this.toastService.error('Sync-Issue auf Development', 'Weitere Infos in der Konsole.');
                    }
                }
                return observableThrowError(error);
            }),
        );
    }
}

import { Component } from '@angular/core';
import { ExternalApiTeamCredits } from '@autoixpert/models/teams/external-api-team-credits';
import { ExternalApiTeamCreditsService } from 'src/app/shared/services/external-api-team-credits.service';

@Component({
    selector: 'external-api-usage',
    templateUrl: './external-api-usage.component.html',
    styleUrls: ['./external-api-usage.component.scss'],
})
export class ExternalApiUsageComponent {
    constructor(private readonly externalApiTeamCreditsService: ExternalApiTeamCreditsService) {}

    protected credits: ExternalApiTeamCredits;

    async ngOnInit() {
        this.credits = await this.externalApiTeamCreditsService.find().toPromise();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentLayoutGroup, getDocumentTemplateId } from '@autoixpert/models/documents/document-layout-group';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from './logged-in-user.service';

/**
 * Service to remove the DOCX templates on AWS S3, generated by the backend when uploading a header and footer template.
 */
@Injectable()
export class DocumentTemplateService {
    constructor(
        private httpClient: HttpClient,
        private loggedInUserService: LoggedInUserService,
    ) {
        this.loggedInUserService.getUser$().subscribe({
            next: (user) => {
                this.user = user;
            },
        });
    }

    private user: User;

    public async remove({
        documentLayoutGroupId,
        documentOrderConfigId,
    }: {
        documentLayoutGroupId: DocumentLayoutGroup['_id'];
        documentOrderConfigId: DocumentOrderConfig['_id'];
    }): Promise<any> {
        const templateFileId: string = getDocumentTemplateId({
            documentLayoutGroupId,
            documentOrderConfigId,
        });

        await Promise.all([
            // Delete the template file itself.
            this.httpClient
                .delete<any>(`/api/v0/teams/${this.user.teamId}/documentTemplates/${templateFileId}`)
                .toPromise(),
            /**
             * Invalidate the template hash so that all document based on previous templates must be re-rendered. That works because
             * the cached documents' hash values depend on the template hash.
             */
            this.httpClient
                .patch<any>(
                    `/api/v0/teams/${this.user.teamId}/renderedDocumentTemplateHashes/${documentOrderConfigId}`,
                    {
                        hash: null,
                    },
                )
                .toPromise(),
        ]);
    }
}

import { ServerError } from '@autoixpert/models/errors/ax-error';

export async function getHtmlImageFromFileOrBlob(photoFileOrBlob: File | Blob): Promise<HTMLImageElement> {
    const localPhotoFileUrl = window.URL.createObjectURL(photoFileOrBlob);

    /**
     * The HTML Image object is an easy way to determine a photo's height and width. Our research from
     * 2022-01-13 led to the conclusion that this is the fastest way.
     */
    const image = new Image();
    image.src = localPhotoFileUrl;

    // Wait for the image to load.
    try {
        await new Promise<void>((resolve, reject) => {
            image.onload = () => {
                window.URL.revokeObjectURL(localPhotoFileUrl);
                resolve();
            };
            image.onerror = reject;
        });
    } catch (error) {
        // Error is not serializable, so we need to manually extract the message and stack trace.
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        const errorStack = error instanceof Error ? error.stack : undefined;
        throw new ServerError({
            code: 'LOADING_LOCAL_PHOTO_BLOB_INTO_IMAGE_OBJECT_FAILED',
            message: `Due to a technical issue, the local photo blob could not be loaded into an Image instance to determine the photo dimensions.`,
            error,
            data: {
                localPhotoFileUrl,
                errorMessage,
                errorStack,
            },
        });
    }
    return image;
}

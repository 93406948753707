<h2>
    Reparaturbestätigung
    <span
        class="help-indicator"
        matTooltip="Bestätigung, dass ein Fahrzeug in Eigenleistung (nicht in einer Werkstatt gegen Rechnung) instandgesetzt wurde.">
        ?
    </span>
</h2>

<div class="label margin-bottom-15">
    In {{ getProductName() }} sind Reparaturbestätigungen
    <strong>Untervorgänge</strong>
    eines bestehenden Gutachtens.
</div>

<h3 class="margin-top-30">Wer hat das Gutachten geschrieben?</h3>
<div id="option-selector-row" class="options-row grid">
    <!--********** Own Report **********-->
    <div
        class="large-selection-box"
        (click)="selectedSource = 'ownOffice'"
        [ngClass]="{ selected: selectedSource === 'ownOffice' }">
        <div class="large-selection-box-active-marker"><mat-icon>check</mat-icon></div>
        <mat-icon class="large-selection-box-option-icon large-icon margin-bottom-15">home</mat-icon>
        Mein Büro
        <div class="label">
            Dein Büro hat das vorausgegangene Schadengutachten mit {{ getProductName() }} geschrieben.
        </div>
    </div>

    <!--********** Foreign Report **********-->
    <div
        class="large-selection-box"
        (click)="selectedSource = 'thirdPartyOffice'"
        [ngClass]="{ selected: selectedSource === 'thirdPartyOffice' }">
        <div class="large-selection-box-active-marker"><mat-icon>check</mat-icon></div>
        <mat-icon class="large-selection-box-option-icon large-icon margin-bottom-15">sensor_occupied</mat-icon>
        Fremdes Büro
        <div class="label">
            Dein Büro hat das Gutachten nicht selbst mit {{ getProductName() }} geschrieben, bestätigt aber die
            Reparatur.
        </div>
    </div>
</div>

<!--============================================-->
<!-- Solution -->
<!--============================================-->
<h3>Vorgehen</h3>
<ng-container *ngIf="selectedSource === 'ownOffice'">
    <div>
        Öffne das vorhandene Gutachten und füge eine Reparaturbestätigung über die Prozessleiste hinzu.
        <a href="https://wissen.autoixpert.de/hc/de/articles/360005078851" target="_blank" rel="noopener">
            So gehts &raquo;
        </a>
    </div>
    <div class="dialog-buttons-container">
        <button (click)="closeDialog({ action: 'none' })">Schließen</button>
    </div>
</ng-container>

<ng-container *ngIf="selectedSource === 'thirdPartyOffice'">
    <div>
        Lege zuerst einen neuen Haftpflichtschaden an, fülle Halter- und Fahrzeugdaten aus und füge dann eine
        Reparaturbestätigung ein.
    </div>
    <div class="dialog-buttons-container">
        <button (click)="closeDialog({ action: 'createReportWithoutTokenOrInvoice' })">
            Gutachten ohne Aktenzeichen & Rechnung anlegen
            <span
                class="help-indicator"
                matTooltip="Da ein Gutachten-Datensatz notwendig ist, du aber kein vollwertiges Gutachten schreibst, wird auch kein Aktenzeichen und keine Rechnungsnummer gezogen. Die Rechnung für die Reparaturbestätigung selbst kannst du trotzdem schreiben.">
                ?
            </span>
        </button>
    </div>
</ng-container>
<!--============================================-->
<!-- END Solution -->
<!--============================================-->

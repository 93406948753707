<!-- Search -->
<div class="icon-picker-search-container">
    <!-- Search icon -->
    <mat-icon class="icon-picker-search-icon">search</mat-icon>

    <!-- Search input -->
    <input
        #searchInput
        class="icon-picker-search-input"
        type="text"
        placeholder="Suchen"
        (keydown.arrowdown)="moveFocusedIndex(1)"
        (keydown.arrowup)="moveFocusedIndex(-1)"
        (input)="handleSearchInputChange($event)"
        (elementCreated)="$event.element.focus()" />

    <!-- Clear button -->
    <mat-icon
        *ngIf="!!searchTerm"
        class="toolbar-icon icon-picker-search-clear-button"
        [matTooltip]="'Suche zurücksetzen'"
        (click)="clearAndFocusSearchInput()">
        close
    </mat-icon>
</div>

<!-- No results placeholder -->
<div *ngIf="!rows.length" class="icon-picker-no-results-placeholder">Keine Ergebnisse</div>

<!-- Icon results virtual scroll container -->
<cdk-virtual-scroll-viewport
    *ngIf="rows.length"
    itemSize="36"
    class="icon-picker-scroll-container"
    (click)="handleIconClick($event)">
    <div class="icon-picker-scroll-container-padding"></div>

    <ng-container *cdkVirtualFor="let row of rows; trackBy: trackById">
        <ng-container [ngSwitch]="row.type">
            <ng-container *ngSwitchCase="IconPickerRowType.CATEGORY">
                <div class="icon-picker-category-row">{{ row.label }}</div>
            </ng-container>
            <ng-container *ngSwitchCase="IconPickerRowType.ICONS">
                <div class="icon-picker-icons-row">
                    <ng-container *ngFor="let icon of row.icons; trackBy: trackById">
                        <div
                            [id]="icon._id"
                            *ngIf="icon.tooltip; else emptyCell"
                            class="icon-picker-item"
                            [matTooltip]="icon.tooltip">
                            <mat-icon>{{ icon._id }}</mat-icon>
                        </div>

                        <ng-template #emptyCell>
                            <div class="icon-picker-item-empty"></div>
                        </ng-template>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <div class="icon-picker-scroll-container-padding"></div>
</cdk-virtual-scroll-viewport>

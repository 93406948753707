import { Invoice } from '../../models/invoices/invoice';
import { LineItem } from '../../models/invoices/line-item';
import { round } from '../numbers/round';

export function computeInvoiceTotalNet(invoiceParts: { lineItems: LineItem[] }): number {
    return round(invoiceParts.lineItems.reduce((sum, lineItem) => sum + lineItem.quantity * lineItem.unitPrice, 0));
}

export function computeInvoiceTotalGross(invoiceParts: { lineItems: LineItem[]; vatRate: Invoice['vatRate'] }): number {
    return round(computeInvoiceTotalNet(invoiceParts) * (1 + invoiceParts.vatRate));
}

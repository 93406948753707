import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';
import { DataTypeBase } from '../../indexed-db/database.types';

export class LeaseReturnTemplate implements DataTypeBase {
    constructor(template: Partial<LeaseReturnTemplate> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string = null;
    sections: LeaseReturnTemplateSection[] = [];

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string = null;
    teamId: string = null;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

export class LeaseReturnTemplateSection {
    constructor(template: Partial<LeaseReturnTemplateSection> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string;
    items: LeaseReturnTemplateItem[] = [];
}

export class LeaseReturnTemplateItem {
    constructor(template: Partial<LeaseReturnTemplateItem> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string = null;
    isRequired: boolean = null;
    commentTemplates: LeaseReturnCommentTemplate[] = [];
}

export class LeaseReturnCommentTemplate {
    constructor(template: Partial<LeaseReturnCommentTemplate> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    body: string = null;
    dragOrderPosition: number = null;
}

import { OfficialFeeTable } from '@autoixpert/models/reports/assessors-fee/fee-calculation';

// Array shape: [damageValue, gross fee, net fee]
export const hukFeeTable2025: OfficialFeeTable = {
    year: 2025,
    table: [
        [500, 368.9, 310],
        [750, 413.53, 347.5],
        [1_000, 506.35, 425.5],
        [1_250, 571.2, 480],
        [1_500, 617.02, 518.5],
        [1_750, 654.5, 550],
        [2_000, 690.2, 580],
        [2_250, 721.14, 606],
        [2_500, 751.49, 631.5],
        [2_750, 781.24, 656.5],
        [3_000, 808.61, 679.5],
        [3_250, 834.79, 701.5],
        [3_500, 861.56, 724],
        [3_750, 888.93, 747],
        [4_000, 915.71, 769.5],
        [4_250, 940.1, 790],
        [4_500, 965.69, 811.5],
        [4_750, 986.51, 829],
        [5_000, 1_009.12, 848],
        [5_250, 1_032.33, 867.5],
        [5_500, 1_056.13, 887.5],
        [5_750, 1_076.95, 905],
        [6_000, 1_100.16, 924.5],
        [6_500, 1_133.48, 952.5],
        [7_000, 1_165.61, 979.5],
        [7_500, 1_197.14, 1_006],
        [8_000, 1_235.82, 1_038.5],
        [8_500, 1_273.3, 1_070],
        [9_000, 1_311.38, 1_102],
        [9_500, 1_346.49, 1_131.5],
        [10_000, 1_383.97, 1_163],
        [10_500, 1_425.03, 1_197.5],
        [11_000, 1_457.75, 1_225],
        [11_500, 1_499.4, 1_260],
        [12_000, 1_534.51, 1_289.5],
        [12_500, 1_571.99, 1_321],
        [13_000, 1_613.64, 1_356],
        [13_500, 1_652.32, 1_388.5],
        [14_000, 1_686.83, 1_417.5],
        [14_500, 1_724.31, 1_449],
        [15_000, 1_765.37, 1_483.5],
        [16_000, 1_824.87, 1_533.5],
        [17_000, 1_889.72, 1_588],
        [18_000, 1_949.82, 1_638.5],
        [19_000, 2_021.22, 1_698.5],
        [20_000, 2_089.64, 1_756],
        [21_000, 2_164.61, 1_819],
        [22_000, 2_231.25, 1_875],
        [23_000, 2_295.51, 1_929],
        [24_000, 2_363.34, 1_986],
        [25_000, 2_415.11, 2_029.5],
        [26_000, 2_483.53, 2_087],
        [27_000, 2_551.36, 2_144],
        [28_000, 2_625.14, 2_206],
        [29_000, 2_695.35, 2_265],
        [30_000, 2_777.46, 2_334],
        [32_500, 2_919.07, 2_453],
        [35_000, 3_086.86, 2_594],
        [37_500, 3_251.08, 2_732],
        [40_000, 3_428.99, 2_881.5],
        [42_500, 3_621.17, 3_043],
        [45_000, 3_821.69, 3_211.5],
        [47_500, 4_000.78, 3_362],
        [50_000, 4_178.09, 3_511],
    ],
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Team } from '@autoixpert/models/teams/team';
import { NetworkStatusService } from './network-status.service';
import { TeamService } from './team.service';

@Injectable()
export class OrderAndCancellationService {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected teamService: TeamService,
    ) {}

    //*****************************************************************************
    //  Team Account Order
    //****************************************************************************/
    public async orderTeamAccount({
        teamId,
        becameCustomerAt,
        paymentCycle,
        paymentMethod,
        audatexAddon,
    }: {
        teamId: string;
        becameCustomerAt: string;
        paymentCycle: Team['billing']['paymentCycle'];
        paymentMethod: 'directDebit' | 'invoice';
        audatexAddon: boolean;
    }): Promise<{ success: boolean; datevAccountNumber: number }> {
        const orderPostBody: OrderTeamAccountParams = {
            becameCustomerAt,
            paymentCycle,
            paymentMethod,
            audatexAddon,
        };
        const response = await this.httpClient
            .post<{
                success: boolean;
                datevAccountNumber: number;
            }>(`/api/v0/teams/${teamId}/teamAccountOrders`, orderPostBody)
            .toPromise();
        // Refresh the team object across all components. The teamService will propagate the changes from the server.
        this.teamService.get(teamId);
        return response;
    }

    /**
     * Relevant if the customer decided not to become a customer after all although he already placed an order.
     */
    public async cancelTeamAccountOrder({ teamId }: { teamId: string }): Promise<void> {
        await this.httpClient.delete(`/api/v0/teams/${teamId}/teamAccountOrders/${teamId}`).toPromise();
        // Refresh the team object across all components. The teamService will propagate the changes from the server.
        this.teamService.get(teamId);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team Account Order
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Team Account Cancellation
    //****************************************************************************/
    public async cancelTeamAccount({
        teamId,
        expirationDate,
    }: {
        teamId: string;
        expirationDate: Team['expirationDate'];
    }) {
        const orderPostBody: CancelTeamAccountParams = {
            expirationDate,
        };
        await this.httpClient.post(`/api/v0/teams/${teamId}/teamAccountCancellations`, orderPostBody).toPromise();
        // Refresh the team object across all components. The teamService will propagate the changes from the server.
        this.teamService.get(teamId);
    }

    public async cancelTeamAccountCancellation({ teamId }: { teamId: string }) {
        await this.httpClient.delete(`/api/v0/teams/${teamId}/teamAccountCancellations/${teamId}`).toPromise();
        // Refresh the team object across all components. The teamService will propagate the changes from the server.
        this.teamService.get(teamId);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team Account Cancellation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Audatex Addon
    //****************************************************************************/
    /**
     * Orders an AudatexAddon (which requires a extra license).
     */
    public async orderAudatexAddon(team: Team) {
        await this.httpClient.post(`/api/v0/teams/${team._id}/orderAudatexAddon`, {}).toPromise();
        // Refresh the team object across all components. The teamService will propagate the changes from the server.
        this.teamService.get(team._id);
    }

    /**
     * Cancels an AudatexAddon (which requires a extra license).
     */
    public async cancelAudatexAddon(team: Team) {
        await this.httpClient.delete(`/api/v0/teams/${team._id}/orderAudatexAddon`, {}).toPromise();
        // Refresh the team object across all components. The teamService will propagate the changes from the server.
        this.teamService.get(team._id);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex Addon
    /////////////////////////////////////////////////////////////////////////////*/
}

interface OrderTeamAccountParams {
    becameCustomerAt: string;
    paymentCycle: Team['billing']['paymentCycle'];
    paymentMethod: Team['billing']['paymentMethod'];
    audatexAddon: boolean;
}

interface CancelTeamAccountParams {
    expirationDate: Team['expirationDate'];
}

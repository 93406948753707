import { generateId } from '../../lib/generate-id';

export class DocumentBuildingBlockCondition {
    constructor(options?: Partial<DocumentBuildingBlockCondition>) {
        Object.assign(this, options);
    }

    _id: string = generateId();
    propertyPath: string = null; // e.g. Gutachten.Schadenkalkulation.Reparatur.KorrigierteReparaturkostenNetto
    operator: DocumentBuildingBlockConditionOperator = null;
    comparisonValue: any = null;
}

type DocumentBuildingBlockConditionOperator =
    | 'equal'
    | 'notEqual'
    | 'greaterThan'
    | 'greaterThanOrEqual'
    | 'lessThan'
    | 'lessThanOrEqual'
    // "truthy" and "falsy" should be used for booleans.
    | 'truthy'
    | 'falsy'
    | 'unknown'
    // "empty" and "notEmpty" should be used for strings and numbers.
    | 'empty'
    | 'notEmpty'
    | 'includes'
    | 'includesNot';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { isBeta } from '@autoixpert/lib/environment/is-beta';
import { isProduction } from '@autoixpert/lib/environment/is-production';
import { getAxErrorEventProcessorForSentry } from '@autoixpert/lib/errors/get-ax-error-event-processor-for-sentry';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

//*****************************************************************************
//  Sentry
//****************************************************************************/
/**
 * Error logging is only required for production versions of our Angular application, i.e. production or beta. Prevent
 * locally running development versions from sending error reports to Sentry.
 */
Sentry.init({
    dsn: 'https://6831898c49e62bc0f8b7c1b6fc6cea35@o4507106464825344.ingest.de.sentry.io/4507107350544464',
    integrations: [
        Sentry.browserTracingIntegration(),
        /**
         * Sentry's Session Replay causes performance issues in Angular apps with too many DOM changes (currently caused by MatQuill).
         *
         * Sentry GitHub issue: https://github.com/getsentry/sentry-javascript/issues/12443
         * AutoiXpert Slack documentation: https://autoixpert.slack.com/archives/CB1U7VBBP/p1723220416982309
         */
        // Sentry.replayIntegration({
        //     maskAllText: false,
        //     blockAllMedia: false,
        // }),
    ],
    release: environment.appVersion || 'development',
    sendClientReports: false,
    enabled: isProduction() || isBeta(),
    // Performance Monitoring
    tracesSampleRate: isProduction() ? 0.005 : 0,

    /**
     * Only attach Sentry headers to API requests. No need to append headers to resource requests such as new
     * frontend source files, images etc.
     */
    tracePropagationTargets: [/^\/api/],

    // Session Replay
    // Deactivated due to performance issues. See above.
    // replaysSessionSampleRate: isProduction() ? 0 : 1.0, // We're only interested in session replay for errors.
    // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    environment: isBeta()
        ? 'beta.autoixpert.de'
        : isQapterixpert()
          ? 'app.qapter-ixpert.de'
          : isProduction()
            ? 'app.autoixpert.de'
            : 'autoixpert.lokal',
});
/////////////////////////////////////////////////////////////////////////////*/
//  END Sentry
/////////////////////////////////////////////////////////////////////////////*/

/**
 * Ensure AxError.code is used as the error name in Sentry instead of "ServerError".
 */
Sentry.addEventProcessor(getAxErrorEventProcessorForSentry());

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // The service worker must be registered here. Otherwise, the angular zones (or at least the one belonging to the main AppComponent) won't stabilize and prevent it from registering.
    .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
            // Return so that the catch block catches the error.
            return navigator.serviceWorker.register('/ngsw-worker.js');
        }
    })
    .catch((error) => {
        throw new AxError({
            code: 'REGISTERING_SERVICE_WORKER_FAILED',
            message: `The service worker could not be registered. Please have a look at the causedBy property.`,
            error,
        });
    });

import { UserPreferences } from '../../user/preferences/user-preferences';

export class MarketAnalysis {
    analysisId: string;
    retrievedAt: string;
    averagePrice: number;
    maxCorridor: number;
    minCorridor: number;
    searchRadiusInKm: SearchRadius;
    autoLoginUrl: string;
    corridorHiddenOnPrintout: boolean;

    /**
     * Cartv's market analysis allows for getting the downtime compensation from the DAT Mietwagenspiegel, so include
     * those optional properties in the market analysis object.
     */
    downtimeCompensationPerWorkday?: number;
    downtimeCompensationGroup?: string;
    rentalCarClass?: number;
    rentalCarClassCostsPerDay?: number;
    // Original price (gross) including equipment prices.
    originalPrice?: number;
}

export type SearchRadius =
    | UserPreferences['replacementValueSearchRadiusCartv']
    | UserPreferences['replacementValueSearchRadiusValuepilot']
    | UserPreferences['replacementValueSearchRadiusWinvalue']
    | null;

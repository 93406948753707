import { AxError } from '../../models/errors/ax-error';
import { TireDimensionData, extractDataFromTireDimension } from './extract-data-from-tire-dimension';

/**
 * Format valid tire dimensions as well as possible.
 *
 * For format details, see
 * - https://de.wikipedia.org/wiki/Autoreifen#Reifengr%C3%B6%C3%9Fe,_-Bezeichnung_und_-Markierung
 * - https://www.reifendirekt.de/Reifenbezeichnung.html
 */
export function formatTireDimension(input: string): string {
    if (!input) {
        return input;
    }

    let tireDimensionData: TireDimensionData;
    try {
        tireDimensionData = extractDataFromTireDimension(input);
        return `${tireDimensionData.widthInMm}/${tireDimensionData.heightInPercentOfWidth} ${
            tireDimensionData.constructionType
        } ${tireDimensionData.rimDiameterInInches} ${tireDimensionData.loadIndex || ''} ${
            tireDimensionData.speedIndex || ''
        }`
            .trim()
            .toLocaleUpperCase();
    } catch (error) {
        if (error.code === 'INVALID_TIRE_DIMENSION_FORMAT' || error.code === 'EMPTY_TIRE_DIMENSION_FORMAT') {
            /**
             * If the input cannot be easily structured, e.g. because the user forgot some characters, at least convert it to upper case.
             */
            return input?.toLocaleUpperCase();
        } else {
            throw new AxError({
                code: 'FORMATTING_TIRE_DIMENSION_FAILED',
                message: `The tire dimension could not be formatted. Have a look at the error property.`,
                data: {
                    input,
                },
                error,
            });
        }
    }
}

<section
    id="spare-tire-equipment-section"
    *ngIf="
        (userPreferences.spareTireEquipmentShown && carShapeHasSpareTires) ||
        (report.car.spareTireEquipment && (report.car.spareTireEquipment.type || !areSpareTireDetailsEmpty()))
    ">
    <!--********** Heading and Submenu Icon **********-->
    <div *ngIf="!disabled" id="spare-tire-heading-and-toolbar-container">
        <h3>Notbereifung</h3>
        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="spareTiresSectionSubmenu">more_vert</mat-icon>
    </div>

    <!--********** Submenu **********-->
    <mat-menu #spareTiresSectionSubmenu>
        <a mat-menu-item class="menu-item-with-icon" *ngIf="!spareTireCommentShown" (click)="showSpareTireComment()">
            <mat-icon class="outlined">chat_bubble</mat-icon>
            Kommentar einblenden
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="spareTireCommentShown"
            (click)="hideSpareTireComment(); emitTireChange()">
            <mat-icon class="outlined">chat_bubble</mat-icon>
            Kommentar ausblenden
        </a>
        <a mat-menu-item class="menu-item-with-icon" (click)="hideSpareTireEquipmentSection()">
            <mat-icon>visibility_off</mat-icon>
            Notbereifung ausblenden
        </a>
    </mat-menu>
    <div class="options-row grid margin-bottom-15">
        <!--********** Repair Kit **********-->
        <div
            class="option-container"
            [ngClass]="{
                active: report.car.spareTireEquipment.type === 'tireRepairKit',
                unselectable: disabled,
            }"
            (click)="selectSpareWheelType('tireRepairKit'); emitTireChange()"
            (keydown)="triggerClickEventOnSpaceBarPress($event)"
            tabindex="0">
            <img src="/assets/images/icons/tire-repair-kit.png" alt="Reparaturkit" class="option-image" />
            <div class="option-title-container">
                <span class="option-title">Reparaturkit</span>
            </div>
        </div>

        <!--********** Compact Spare Wheel **********-->
        <div
            class="option-container"
            [ngClass]="{
                active: report.car.spareTireEquipment.type === 'compactSpareWheel',
                unselectable: disabled,
            }"
            (click)="selectSpareWheelType('compactSpareWheel'); emitTireChange()"
            (keydown)="triggerClickEventOnSpaceBarPress($event)"
            tabindex="0">
            <img src="/assets/images/icons/compact-spare-tire.png" alt="Notrad" class="option-image" />
            <div class="option-title-container">
                <span class="option-title">Notrad</span>
            </div>
        </div>

        <!--********** Spare Wheel **********-->
        <div
            class="option-container"
            [ngClass]="{
                active: report.car.spareTireEquipment.type === 'spareWheel',
                unselectable: disabled,
            }"
            (click)="selectSpareWheelType('spareWheel'); emitTireChange()"
            (keydown)="triggerClickEventOnSpaceBarPress($event)"
            tabindex="0">
            <img src="/assets/images/icons/spare-tire.png" alt="Ersatzrad" class="option-image" />
            <div class="option-title-container">
                <span class="option-title">Ersatzrad</span>
            </div>
        </div>
    </div>

    <!--********** Regular Spare Tire Details **********-->
    <div
        id="spare-tire-details-container"
        class="cursor-pointer"
        [ngClass]="{ 'no-data': areSpareTireDetailsEmpty() }"
        (click)="spareTireDetailsDialogShown = true"
        *ngIf="report.car.spareTireEquipment.type === 'spareWheel'"
        @fadeInAndOut>
        <div class="link" *ngIf="areSpareTireDetailsEmpty()">Ersatzrad spezifizieren</div>
        <div
            id="spare-tire-details-text"
            *ngIf="!areSpareTireDetailsEmpty()"
            matTooltip="Diese Daten wurden für das Ersatzrad hinterlegt. Klicke, um sie zu bearbeiten.">
            <div class="semibold">
                <span *ngIf="report.car.spareTireEquipment.dimension">
                    {{ report.car.spareTireEquipment.dimension }}
                </span>
                <span *ngIf="report.car.spareTireEquipment.dimension && report.car.spareTireEquipment.treadInMm">
                    -
                </span>
                <span *ngIf="report.car.spareTireEquipment.treadInMm">
                    {{ report.car.spareTireEquipment.treadInMm }} mm
                </span>
            </div>
            <div class="label">{{ report.car.spareTireEquipment.manufacturer }}</div>
        </div>
        <mat-icon class="toolbar-icon" matTooltip="Bearbeiten">edit</mat-icon>
    </div>

    <!--********** Comment **********-->
    <autocomplete-with-memory
        *ngIf="spareTireCommentShown"
        [(ngModel)]="report.car.spareTireEquipment.comment"
        (change)="emitTireChange()"
        autocompleteEntryType="spareTireComment"
        [useTextarea]="true"
        [disabled]="disabled"
        placeholder="Kommentar Notbereifung"></autocomplete-with-memory>
</section>

<!-- Dialog to specify the spare tire details -->
<spare-tire-details-dialog
    *ngIf="spareTireDetailsDialogShown"
    [(spareTireEquipment)]="report.car.spareTireEquipment"
    [axles]="report.car.axles"
    (spareTireEquipmentChange)="emitTireChange()"
    [disabled]="disabled"
    (close)="spareTireDetailsDialogShown = false"
    @runChildAnimations></spare-tire-details-dialog>

<task-done-checkbox
    class="subtask-complete"
    [task]="subtask"
    (taskCompleted)="markAsDone(subtask)"
    (taskReopened)="markAsNotDone(subtask)"></task-done-checkbox>
<div class="subtask-title-container">
    <autogrow-textarea
        #titleInput
        class="subtask-title-input"
        [style.min-width.px]="isTitleInputFocused ? (subtask.title ? 20 : 154) : subtask.title ? 20 : 50"
        [placeholder]="isTitleInputFocused ? 'Benenne diese Aufgabe' : ''"
        [value]="subtask.title"
        [singleLine]="true"
        [paddingHorizontal]="4"
        (valueChange)="subtask.title = $event.value"
        (focused)="isTitleInputFocused = true"
        (enterPressed)="subtaskAdded.emit(subtask)"
        (blurred)="handleTitleInputBlur()"></autogrow-textarea>
</div>

<div class="subtask-delete" (click)="subtaskDeleted.emit(subtask)">
    <mat-icon>delete</mat-icon>
</div>

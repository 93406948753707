import { AxError } from '@autoixpert/models/errors/ax-error';
import { InvoiceAuditCategory } from '@autoixpert/models/reports/invoice-audit';
import { Report } from '@autoixpert/models/reports/report';

/**
 * This function extracts the calculation results from a report.
 */
export function getCalculationResultsForInvoiceAudit(sourceReport: Report) {
    if (
        !sourceReport.damageCalculation.repair.garageLaborCostsNet &&
        sourceReport.damageCalculation.repair.sparePartsCostsNet &&
        sourceReport.damageCalculation.repair.lacquerCostsNet &&
        sourceReport.damageCalculation.repair.auxiliaryCostsNet
    ) {
        throw new AxError({
            code: 'CALCULATION_RESULTS_MISSING',
            message: 'At least one calculation result is missing.',
            data: {
                garageLabourCostsNet: sourceReport.damageCalculation.repair.garageLaborCostsNet,
                sparePartsCostsNet: sourceReport.damageCalculation.repair.sparePartsCostsNet,
                lacquerCostsNet: sourceReport.damageCalculation.repair.lacquerCostsNet,
                auxilaryCostsNet: sourceReport.damageCalculation.repair.auxiliaryCostsNet,
            },
        });
    }

    const projectedRepairCosts: { [key in InvoiceAuditCategory]: number } = {
        wages: sourceReport.damageCalculation.repair.garageLaborCostsNet,
        spareParts: sourceReport.damageCalculation.repair.sparePartsCostsNet,
        paint: sourceReport.damageCalculation.repair.lacquerCostsNet,
        auxiliaryCosts: sourceReport.damageCalculation.repair.auxiliaryCostsNet,
        otherCosts: 0,
    };
    return projectedRepairCosts;
}

import { get } from 'lodash-es';
import { DataTypeBase } from '../../models/indexed-db/database.types';
import { filterConflictingChanges } from './filter-conflicting-changes';
import { ObjectDifference, getListOfDifferences } from './get-list-of-differences';
import { insertChangesIntoRecord } from './insert-changes-into-record';
import { mergeArrayChanges } from './merge-array-changes';

/**
 * Merge all changes from the remote record into the local record.
 *
 * If changes conflict, the local version wins.
 */
export function threeWayMerge<DataType extends DataTypeBase>({
    localRecord,
    remoteRecord,
    serverShadow,
    forceMergeConflictingChanges,
}: {
    localRecord: DataType;
    remoteRecord: DataType;
    serverShadow: DataType;

    /**
     * Used for changes that originated in another browser tab. They are sent through a BroadcastChannel and
     * must be force merged. If we did not force-merge, a race condition between the BroadcastChannel and the IndexedDB
     * may cause conflicting changes to be detected where there are none. Especially with arrays such as report.photos,
     * that leads to unpredictable merge results.
     */
    forceMergeConflictingChanges?: boolean;
}): {
    nonConflictingChanges: ObjectDifference[];
    conflictingChanges: ObjectDifference[];
    localRecord: DataType;
} {
    //*****************************************************************************
    //  Determine Changes Locally And Remote
    //****************************************************************************/
    // The changes made on the local version of the record.
    const localChanges = getListOfDifferences(serverShadow, localRecord);
    // The changes made on the remote version of the record since the last sync (= compared to the server shadow)
    const remoteChanges = getListOfDifferences(serverShadow, remoteRecord);

    // No remote changes? -> Keep local record as-is.
    if (!remoteChanges.length) {
        return {
            conflictingChanges: [],
            nonConflictingChanges: localChanges,
            localRecord,
        };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Determine Changes Locally And Remote
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Which remote changes are conflicting with local changes?
     */
    const { conflictingChanges, nonConflictingChanges } = filterConflictingChanges(localChanges, remoteChanges);

    //*****************************************************************************
    //  Merge Non-Conflicting
    //****************************************************************************/
    // Simple deep set
    const nonConflictingNonArrayChanges: ObjectDifference[] = [];
    for (const nonConflictingChange of nonConflictingChanges) {
        if (
            Array.isArray(get(localRecord, nonConflictingChange.key)) &&
            Array.isArray(get(remoteRecord, nonConflictingChange.key))
        ) {
            /**
             * Simply setting Array changes would cause the array to be replaced with the server array. References break.
             */
            mergeArrayChanges({
                path: nonConflictingChange.key,
                serverShadow,
                localRecord,
                remoteRecord,
            });
        } else {
            nonConflictingNonArrayChanges.push(nonConflictingChange);
        }
    }

    // This includes primitives and objects (everything but arrays).
    insertChangesIntoRecord<DataType>(localRecord, nonConflictingNonArrayChanges);
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Merge Non-Conflicting
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Merge Conflicting
    //****************************************************************************/
    /**
     * Merge conflicting arrays (3-way-merge)
     */
    const conflictingNonArrayChanges = [];
    for (const conflictingChange of conflictingChanges) {
        if (
            Array.isArray(get(localRecord, conflictingChange.key)) &&
            Array.isArray(get(remoteRecord, conflictingChange.key))
        ) {
            mergeArrayChanges({
                path: conflictingChange.key,
                serverShadow,
                localRecord,
                remoteRecord,
                forceMergeConflictingChanges,
            });
        } else {
            conflictingNonArrayChanges.push(conflictingChange);
        }
    }

    /**
     * Conflicting changes must be merged when they originated in another browser tab on this device (transmitted via local BroadcastChannels).
     *
     * If conflicting changes were not merged and the user is offline or changes occur very fast [e. g. in an autocomplete field: input blur and click on autocomplete],
     * only the first change in tab 1 would be merged in tab 2 since all following changes were considered conflicting.
     * That's because all following changes would cause tab 2 to think it had already changed the field (component field is
     * compared to the local server shadow) which the change from the other tab marks as changed as well but with a different value.
     *
     * EXAMPLE why conflicting changes are detected:
     *
     * _Change 1_
     * Tab 1:
     * - Component:       report.car.generalCondition === 'neu'
     * - Server Shadow:   report.car.generalCondition === ''
     * Tab 2:
     * - Component:       report.car.generalCondition === ''
     * - Server Shadow:   report.car.generalCondition === ''
     * - Received Change: report.car.generalCondition === 'neu' <-- Accepted since the diff between component and server shadow shows not change.
     *
     * _Change 2_
     * Tab 1:
     * - Component:       report.car.generalCondition === 'neuwertig'
     * - Server Shadow:   report.car.generalCondition === ''
     * Tab 2:
     * - Component:       report.car.generalCondition === 'neu'
     * - Server Shadow:   report.car.generalCondition === ''
     * - Received Change: report.car.generalCondition === 'neuwertig' <-- Conflicting change since the diff between component and server shadow shows a change: "" vs. "neu"
     **/
    if (forceMergeConflictingChanges && conflictingNonArrayChanges.length) {
        console.log(`[offline-sync] Merged conflicting non-array changes.`, conflictingNonArrayChanges);
        // This includes primitives and objects (everything but arrays).
        insertChangesIntoRecord<DataType>(localRecord, conflictingNonArrayChanges);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Merge Conflicting
    /////////////////////////////////////////////////////////////////////////////*/

    return {
        nonConflictingChanges,
        conflictingChanges,
        localRecord,
    };
}

//*****************************************************************************
//  For Testing/Debugging
//****************************************************************************/
// // For debugging
// (window as any).threeWayMerge = threeWayMerge;

//// Objects with IDs. Example: report.photos.
//threeWayMerge({
//    localRecord  : {
//        photos           : [
//            // Reordered
//            {
//                _id : '3',
//            },
//            {
//                _id : '2',
//            },
//            {
//                _id : '1',
//            },
//        ],
//        _documentVersion : 2
//    },
//    remoteRecord : {
//        photos           : [
//            // Deleted
//            // {
//            //     _id : '1',
//            // },
//            {
//                _id : '2',
//            },
//            {
//                _id : '3',
//            },
//            // Added
//            {
//                _id : '4',
//            },
//        ],
//        _documentVersion : 2
//    },
//    serverShadow : {
//        photos           : [
//            {
//                _id : '1',
//            },
//            {
//                _id : '2',
//            },
//            {
//                _id : '3',
//            },
//        ],
//        _documentVersion : 1
//    }
//});

// Plain values such as strings (example: team.members).
//threeWayMerge({
//    localRecord  : {
//        members           : ['a', 'b', 'c'],
//        _documentVersion : 2
//    },
//    remoteRecord : {
//        members           : ['a', 'b', 'd'],
//        _documentVersion : 2
//    },
//    serverShadow : {
//        members           : ['a', 'b'],
//        _documentVersion : 1
//    }
//});
/////////////////////////////////////////////////////////////////////////////*/
//  END For Testing/Debugging
/////////////////////////////////////////////////////////////////////////////*/

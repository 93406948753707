import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OldtimerValuationGrades } from '@autoixpert/models/reports/market-value/oldimter-valuation-grades';
import { Report } from '@autoixpert/models/reports/report';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';

@Component({
    selector: 'oldtimer-grades',
    templateUrl: './oldtimer-grades.component.html',
    styleUrls: ['./oldtimer-grades.component.scss'],
})
export class OldtimerGradesComponent {
    constructor(protected userPreferences: UserPreferencesService) {}

    @Input() report: Report;
    @Output() gradeChange = new EventEmitter();

    ngOnInit() {
        if (!this.report?.valuation.oldtimerValuationGrades) {
            this.report.valuation.oldtimerValuationGrades = new OldtimerValuationGrades();
        }
    }

    public setAllGrades(pointGrade: number) {
        if (!this.userPreferences.recalculateOldtimerGradesAutomatically) return;

        const newGrades = new OldtimerValuationGrades();

        for (const key in this.report.valuation.oldtimerValuationGrades) {
            // Don't reset excluded grades
            if (this.report.valuation.oldtimerValuationGrades[key] === null) {
                newGrades[key] = null;
                continue;
            }
            // Set all other grades
            newGrades[key] = pointGrade;
        }

        this.report.valuation.oldtimerValuationGrades = newGrades;
    }

    public calculateAverageGrade(): void {
        // Only calculate if the setting is true
        if (!this.userPreferences.recalculateOldtimerGradesAutomatically) return;

        // Exclude null-grades, i. e. grade excluded from printing
        const eligibleGrades = this.getAllGradesButTotal().filter(([key, grade]) => !!grade);
        const scoreSum = eligibleGrades
            // Add up all values
            .reduce((sum, [key, value]) => sum + value, 0);

        this.report.valuation.oldtimerValuationGrades.total = Math.round(scoreSum / eligibleGrades.length);

        this.emitGradeChange();
    }

    private getAllGradesButTotal(): [string, number][] {
        return (
            Object.entries(this.report.valuation.oldtimerValuationGrades)
                // Count all values but the total grade
                .filter(([key, value]) => key !== 'total')
        );
    }

    public toggleOldtimerGradeCalculation(): void {
        this.userPreferences.recalculateOldtimerGradesAutomatically =
            !this.userPreferences.recalculateOldtimerGradesAutomatically;
    }

    protected emitGradeChange() {
        this.gradeChange.emit();
    }
}

import { OverlayRef } from '@angular/cdk/overlay';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { fadeInAndSlideAnimation } from '@autoixpert/animations/fade-in-and-slide.animation';
import { deviceHasSmallScreen } from '@autoixpert/lib/device-detection/device-has-small-screen';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { InvoiceService } from '../../../services/invoice.service';
import { ReportService } from '../../../services/report.service';

@Component({
    selector: 'link-task-to-record-overlay-component',
    templateUrl: './link-task-to-record-overlay.component.html',
    styleUrls: ['./link-task-to-record-overlay.component.scss'],
    host: {
        '[class.card]': 'true',
    },
    providers: [],
    animations: [
        fadeInAndSlideAnimation({
            duration: 150,
            slideDistance: 10,
        }),
    ],
})
export class LinkTaskToRecordOverlayComponent implements OnInit {
    constructor(
        private overlayRef: OverlayRef,
        private reportService: ReportService,
        private invoiceService: InvoiceService,
    ) {}

    //*****************************************************************************
    //  Inputs & Outputs
    //****************************************************************************/
    @Input() set reportId(value: Report['_id']) {
        this._reportId = value;
    }
    @Input() set invoiceId(value: Invoice['_id']) {
        this._invoiceId = value;
    }
    @Output() linkChange: EventEmitter<{ report: Report; invoice: Invoice }> = new EventEmitter();
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Inputs & Outputs
    /////////////////////////////////////////////////////////////////////////////*/

    protected isTablet: boolean;
    protected selectType: 'report' | 'invoice' = 'report';

    public selectedReport: Report;
    public selectedInvoice: Invoice;

    protected _reportId: Report['_id'];
    protected _invoiceId: Invoice['_id'];

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    async ngOnInit() {
        this.isTablet = deviceHasSmallScreen();
        if (this._reportId) {
            this.selectedReport = await this.reportService.get(this._reportId);
        }
        if (this._invoiceId) {
            this.selectedInvoice = await this.invoiceService.get(this._invoiceId);
            this.selectType = 'invoice';
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    protected clearLinks() {
        this._reportId = null;
        this._invoiceId = null;
        this.emitLinkChange();
    }

    protected handleReportSelected(report: Report) {
        if (!report) {
            this.selectedReport = null;
            this._reportId = null;
        } else {
            this.selectedReport = report;
            this._reportId = report._id;
        }

        this._invoiceId = null;
        this.selectedInvoice = null;

        this.emitLinkChange();
    }

    protected handleInvoiceSelected(invoice: Invoice) {
        if (!invoice) {
            this.selectedInvoice = null;
            this._invoiceId = null;
        } else {
            this._invoiceId = invoice._id;
            this.selectedInvoice = invoice;
        }

        this.selectedReport = null;
        this._reportId = null;

        this.emitLinkChange();
    }

    protected emitLinkChange() {
        this.linkChange.emit({ report: this.selectedReport, invoice: this.selectedInvoice });
        this.closeOverlay();
    }

    protected closeOverlay() {
        this.overlayRef.detach();
    }

    //*****************************************************************************
    //  Host Bindings & Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeOverlay();
                break;
        }
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Host Bindings & Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

import { ApiErrorHandler, ErrorHandlerAction } from '../../abstract-services/api-error.abstract.service';
import { AxError } from '../../models/errors/ax-error';

export function registerActionAndClickHandlers({
    handler,
    error,
    immediateActionsAndErrors,
    toastClicksAndErrors,
}: {
    handler: ApiErrorHandler;
    error: AxError;
    immediateActionsAndErrors: ImmediateActionAndError[];
    toastClicksAndErrors: ToastClickAndError[];
}) {
    /**
     * If the handler should execute more JavaScript than just the toast, add it to the
     * callback arrays.
     */
    if (handler.action) {
        immediateActionsAndErrors.push({
            action: handler.action,
            error,
        });
    }
    if (handler.toastClick) {
        toastClicksAndErrors.push({
            toastClick: handler.toastClick,
            error,
        });
    }
}

export interface ImmediateActionAndError {
    action: ErrorHandlerAction;
    error: AxError;
}

export interface ToastClickAndError {
    toastClick: ApiErrorHandler['toastClick'];
    error: AxError;
}

import { Pipe, PipeTransform } from '@angular/core';
import { Translator } from '../lib/placeholder-values/translator';

/**
 * Pipe used to translate a boolean tristate value from a tristate checkbox to
 * "Ja", "Nein" or "unbekannt".
 */
@Pipe({
    name: 'translateTristate',
})
export class TranslateTristatePipe implements PipeTransform {
    transform(tristateValue: boolean): string {
        return Translator.tristate(tristateValue);
    }
}

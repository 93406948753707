import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { AssessorsFeeAnalyticsRecord } from '@autoixpert/models/analytics/assessors-fee-analytics.model';
import {
    RevenueByCityAnalyticsRecord,
    RevenueByCustomerAnalyticsRecord,
} from '@autoixpert/models/analytics/customer-analytics.model';
import { IntermediaryAnalyticsRecord } from '@autoixpert/models/analytics/intermediary-analytics.model';
import { RevenueByReportTypeAnalyticsRecord } from '@autoixpert/models/analytics/report-type-analytics.model';
import {
    ReportsByAssessorAnalyticsRecord,
    ReportsByMonthAnalyticsRecord,
} from '@autoixpert/models/analytics/reports-analytics.model';
import {
    RevenueByAssessorAnalyticsRecord,
    RevenueByCarManufacturerAnalyticsRecord,
    RevenueByInsuranceAnalyticsRecord,
    RevenueByMonthAnalyticsRecord,
    ShortPaymentStatusByInsuranceAnalyticsRecord,
} from '@autoixpert/models/analytics/revenue-analytics.model';
import { RevenueByInvoiceItemAnalyticsRecord } from '@autoixpert/models/analytics/revenue-by-invoice-item-analytics.model';
import { DamageTotalAnalyticsExportRecord } from '../../analytics/damage-total/damage-total.component';
import { currencyFormatterEuro } from './currency-formatter-euro';

const currencyFieldsInAnalyticsRecords: EnglishKeyNames[] = [
    'totalRevenueNet',
    'totalRevenueGross',
    'outstandingRevenueNet',
    'outstandingRevenueGross',
    'allShortPaymentsSumNet',
    'allShortPaymentsSumGross',
    'outstandingShortPaymentsSumNet',
    'outstandingShortPaymentsSumGross',
    'paidShortPaymentsSumNet',
    'paidShortPaymentsSumGross',
    'assessorsFeeTotal',
    'totalGrossOfAssociatedInvoices',
    'paymentsOfAssociatedInvoices',
    'damageTotalValueNet',
    'damageTotalValueGross',
    'averageRevenuePerReport',
];

export function downloadAnalyticsRecordsAsCsv(records: Partial<Record<EnglishKeyNames, any>>[], title: string): void {
    const recordsWithGermanHeaders = records.map((record) => {
        const recordWithGermanHeader = {};

        // Set all values on a new object having German headers.
        for (const [key, value] of Object.entries(record)) {
            // Skip the _id key
            if (key === '_id') continue;
            if (key === 'assessorId') continue;
            if (key.startsWith('idsOf')) continue;

            const isCurrencyField: boolean = currencyFieldsInAnalyticsRecords.includes(key as EnglishKeyNames);

            // Save value under German header. If the value is a number, save it in German number format.
            recordWithGermanHeader[translateKeyNameToGerman(key as EnglishKeyNames)] = isCurrencyField
                ? currencyFormatterEuro(value as any)
                : formatValue(value);
        }

        return recordWithGermanHeader;
    });

    const csvString: string = unparse(recordsWithGermanHeaders, {
        delimiter: ';',
    });
    // The \ufeff makes Excel understand UTF-8
    const blob: Blob = new Blob(['\ufeff', csvString], { type: 'text/csv' });

    saveAs(blob, `Auswertung ${title}.csv`);
}

// These keys are generated on the fly, i.e. they don't exist in the analytics records.
type LocallyGeneratedKeyNames =
    | 'totalNumberOfInvoices'
    | 'numberOfOutstandingInvoices'
    | 'numberOfShortPaidInvoices'
    | 'shortPaymentQuota'
    | 'garage'
    | 'lawyer'
    | 'damageValueTier';

// Very few analytics
type ReportExportFieldNames = 'idsOfReports';

type EnglishKeyNames =
    | keyof RevenueByMonthAnalyticsRecord
    | keyof RevenueByAssessorAnalyticsRecord
    | keyof RevenueByCarManufacturerAnalyticsRecord
    | keyof RevenueByInsuranceAnalyticsRecord
    | keyof RevenueByInvoiceItemAnalyticsRecord
    | keyof ReportsByMonthAnalyticsRecord
    | keyof ReportsByAssessorAnalyticsRecord
    | keyof IntermediaryAnalyticsRecord
    | keyof RevenueByCityAnalyticsRecord
    | keyof RevenueByCustomerAnalyticsRecord
    | keyof AssessorsFeeAnalyticsRecord
    | keyof RevenueByReportTypeAnalyticsRecord
    | keyof DamageTotalAnalyticsExportRecord
    | keyof ShortPaymentStatusByInsuranceAnalyticsRecord
    | LocallyGeneratedKeyNames;

function translateKeyNameToGerman(key: EnglishKeyNames): string {
    switch (key) {
        case 'assessorId':
            return 'Gutachter-ID';
        case 'assessorName':
            return 'Sachverständiger';
        case 'city':
            return 'Stadt';
        case 'zip':
            return 'PLZ';
        case 'customerName':
            return 'Kundenname';
        case 'month':
            return 'Monat';
        case 'numberOfReports':
            return 'Anzahl Gutachten';
        case 'totalRevenueNet':
            return 'Gesamtumsatz netto';
        case 'totalRevenueGross':
            return 'Gesamtumsatz brutto';
        case 'outstandingRevenueNet':
            return 'Davon unbezahlt (netto)';
        case 'outstandingRevenueGross':
            return 'Davon unbezahlt (brutto)';
        case 'totalNumberOfInvoices':
            return 'Gesamtzahl Rechnungen';
        case 'numberOfOutstandingInvoices':
            return 'Anzahl offener Rechnungen';
        case 'manufacturer':
            return 'Hersteller';
        case 'insurance':
            return 'Versicherung';
        //*****************************************************************************
        //  Short Payments
        //****************************************************************************/
        case 'allShortPaymentsSumNet':
            return 'Kürzungen netto';
        case 'allShortPaymentsSumGross':
            return 'Kürzungen brutto';
        case 'outstandingShortPaymentsSumNet':
            return 'Unbezahlte Kürzungen netto';
        case 'outstandingShortPaymentsSumGross':
            return 'Unbezahlte Kürzungen brutto';
        case 'paidShortPaymentsSumNet':
            return 'Bezahlte Kürzungen netto';
        case 'paidShortPaymentsSumGross':
            return 'Bezahlte Kürzungen brutto';

        case 'numberOfShortPaidInvoices':
            return 'Anzahl gekürzter Rechnungen';
        case 'shortPaymentQuota':
            return 'Kürzungsquote';
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Short Payments
        /////////////////////////////////////////////////////////////////////////////*/
        case 'intermediary':
            return 'Vermittler';
        case 'garage':
            return 'Werkstatt';
        case 'lawyer':
            return 'Anwalt';
        case 'totalNumberOfReports':
            return 'Gesamtzahl Gutachten';
        case 'assessorsFeeTotal':
            return 'Gesamthonorar';
        case 'totalGrossOfAssociatedInvoices':
            return 'Gesamtumsatz verknüpfter Rechnungen (brutto)';
        case 'paymentsOfAssociatedInvoices':
            return 'Zahlungen verknüpfter Rechnungen';
        case 'invoicePaymentQuota':
            return 'Rechnungszahlungsquote';
        case 'reportType':
            return 'Gutachtentyp';
        case 'damageValueTier':
            return 'Schadenhöhe';
        case 'damageTotalValueNet':
            return 'Gesamtsumme Schäden (netto)';
        case 'damageTotalValueGross':
            return 'Gesamtsumme Schäden (brutto)';
        case 'lowerLimit':
            return 'Unterer Grenzwert';
        case 'upperLimit':
            return 'Oberer Grenzwert';
        //*****************************************************************************
        //  Revenue by invoice item
        //****************************************************************************/
        case 'invoiceItemDescription':
            return 'Rechnungsposition';
        case 'averageRevenuePerReport':
            return 'ø Umsatz pro Gutachten';
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Short Payments
        /////////////////////////////////////////////////////////////////////////////*/

        // All ID fields are filtered out by the download method above.
        case 'idsOfAllInvoices':
        case 'idsOfRegularInvoices':
        case 'idsOfCreditNotes':
        case 'idsOfOutstandingInvoices':
        case 'idsOfShortPaidInvoices':
            break;

        default:
            console.error(`Missing translation for key "${key}"`);
            console.trace();
    }
}

function formatValue(value: any): any {
    if (value === null) {
        return 'Nicht angegeben';
    }

    if (isNaN(value)) return value;

    return Number(value).toLocaleString('de-de', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
}

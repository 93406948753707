import { OverlayRef } from '@angular/cdk/overlay';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Moment } from 'moment';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { deviceHasSmallScreen } from '@autoixpert/lib/device-detection/device-has-small-screen';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { AxDateAdapter } from '../date-input/ax-date-adapter';
import { AX_MOMENT_DATE_FORMATS } from '../date-input/ax-moment-date-formats';

@Component({
    selector: 'date-picker-with-input-overlay',
    templateUrl: './date-picker-with-input-overlay.component.html',
    styleUrls: ['./date-picker-with-input-overlay.component.scss'],
    host: {
        '[class.card]': 'true',
    },
    providers: [
        /**
         * These providers implement the parsing of shortcuts like "g" for "gestern" (= yesterday).
         */
        {
            provide: DateAdapter,
            useClass: AxDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: AX_MOMENT_DATE_FORMATS,
        },
    ],
    animations: [
        fadeInAndSlideAnimation({
            duration: 150,
            slideDistance: 10,
        }),
    ],
})
export class DatePickerWithInputOverlayComponent implements OnInit, AfterViewInit {
    constructor(private overlayRef: OverlayRef) {}

    //*****************************************************************************
    //  Inputs & Outputs
    //****************************************************************************/
    @Input() set date(value: IsoDate) {
        this._date = value;
    }

    @Input() set time(value: string) {
        this._time = value;
    }

    @Input() showTimeInput: boolean = false;

    @Output() dateChange: EventEmitter<IsoDate> = new EventEmitter();
    @Output() timeChange: EventEmitter<string> = new EventEmitter();

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Inputs & Outputs
    /////////////////////////////////////////////////////////////////////////////*/

    @ViewChild('mainInput') mainInput: ElementRef<HTMLInputElement>;

    protected isTablet: boolean;
    /**
     * A property of type string is required for the HTML template to work.
     * If you bind the date of type IsoDate directly to the mat-calendar's selected property,
     * Angular infers a union type of string & IsoDate and a string somewhere, causing an error.
     */
    protected _date: string | Moment;

    protected _time: string;

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    ngOnInit() {
        this.isTablet = deviceHasSmallScreen();
    }

    ngAfterViewInit() {
        this.mainInput.nativeElement.focus();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    protected clearDate() {
        this._date = null;
        this.emitDateChange();

        this._time = null;
        this.emitTimeChange();
    }

    handleTimeChange(time: string) {
        this._time = getTimeFromInputStr(time);
        this.emitTimeChange();
    }

    protected emitDateChange() {
        this.dateChange.emit(toIsoDate(this._date));
    }

    protected emitTimeChange() {
        this.timeChange.emit(this._time);
    }

    protected handleDatePicked() {
        this.emitDateChange();

        // Only close if time is not shown
        if (!this.showTimeInput) {
            this.closeOverlay();
        }
    }

    protected closeOverlay() {
        this.overlayRef.detach();
    }

    //*****************************************************************************
    //  Host Bindings & Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeOverlay();
                break;
        }
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Host Bindings & Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

function getTimeFromInputStr(input: string | undefined): string | null {
    let str = input || '';

    // Match hours
    const hoursMatch = matchHours(str);
    if (!hoursMatch) {
        return null;
    }
    str = str.substr(hoursMatch.hoursIndex);
    let { hours } = hoursMatch;

    // Match minutes
    let minutes = matchMinutes(str);

    if (minutes === 60) {
        minutes = 0;
        hours += 1;
    }

    hours = hours % 24;

    // Format
    const hoursStr = `${hours}`.padStart(2, '0');
    const minutesStr = `${minutes}`.padStart(2, '0');
    const result = `${hoursStr}:${minutesStr}`;
    return result;
}

function matchHours(input: string | undefined): null | { hours: number; hoursIndex: number } {
    if (!input) {
        return null;
    }

    const exactThreeConsecutiveNums =
        (input.match(/\d/g)?.length || 0) === 3 && (input.match(/\d{3}/g)?.length || 0) === 1;

    // In case 3 digits exist in input and are consecutively, prefer single digit match for hours instead of multi-digit
    const match = exactThreeConsecutiveNums ? input.match(/\d|0\d|1\d|(2[0-4])/) : input.match(/(2[0-4]|1\d|0\d|\d)/);

    if (!match) {
        return null;
    }

    const index = match.index;
    if (index === undefined) {
        return null;
    }
    const hoursStr = match[0];

    const hours = parseInt(hoursStr, 10);

    if (isNaN(hours) || hours < 0 || hours > 24) {
        return null;
    }

    return { hours, hoursIndex: index + hoursStr.length };
}

function matchMinutes(input: string | undefined): number {
    if (!input) {
        return 0;
    }

    const match = input.match(/([0-6]\d|\d)/);
    if (!match) {
        return 0;
    }

    const index = match.index;
    if (index === undefined) {
        return 0;
    }

    const minutesStr = match[0];

    const minutes = parseInt(minutesStr, 10);

    if (isNaN(minutes) || minutes < 0 || minutes > 60) {
        return 0;
    }

    return minutes;
}

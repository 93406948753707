<div class="selected-report-types-container" (click)="openDialog()" [ngClass]="{ disabled: disabled }">
    <mat-icon class="tag-icon small-icon">local_offer</mat-icon>
    <div class="list-of-report-types" [matTooltip]="getListOfSelectedReportTypesGerman().join('\n')">
        <ng-container *ngIf="selectedReportTypes.length">{{ getDisplayStringOfSelectedReportTypes() }}</ng-container>
        <span class="italic" *ngIf="!selectedReportTypes.length">keine Vorgangstypen ausgewählt</span>
    </div>
</div>

<report-types-dialog
    *ngIf="isReportTypeDialogVisible"
    [(selectedReportTypes)]="selectedReportTypes"
    [showOnlyDamageReportTypes]="showOnlyDamageReportTypes"
    [hideShortAssessment]="hideShortAssessment"
    (selectedReportTypesChange)="emitSelectedReportTypesChange()"
    (close)="closeDialog()"
    @runChildAnimations></report-types-dialog>

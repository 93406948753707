import { getDeclinedSalutation } from './get-declined-salutation';

/**
 * Get the recipient's name for the address field and other uses, e.g.
 * - non-declined (default): ['Firma 123 GmbH', 'Herr Max Mustermann']
 * - declined: ['der Firma 123 GmbH', 'Herrn Max Mustermann'] - use case: "Anbei sende ich das von der Supermaler GmbH, Herrn May Meier in Auftrag gegebene Gutachten."
 *
 * declined: Herr vs. Herrn (Dativ, dekliniert)
 */
export function getFullRecipientName({
    contactPerson,
    letterWindow = false,
    salutationDeclined = false,
}: {
    contactPerson: Partial<{ organization: string; salutation: string; firstName: string; lastName: string }>;
    /**
     * In a letter window, if no organization is provided, the salutation will occupy its own line.
     *
     * Example:
     * Herrn
     * Mark Langer
     * Am Ehrenmal 2
     * 33178 Borchen
     */
    letterWindow?: boolean;
    /**
     * Whether the salutation in German shall be declined (= dekliniert - Herr -> Herrn).
     */
    salutationDeclined?: boolean;
}): string[] {
    const recipientNameParts = [];

    if (contactPerson.organization) {
        let companyName = contactPerson.organization;

        /**
         * Use case:
         * "Anbei sende ich das von der Supermaler GmbH, Herrn May Meier in Auftrag gegebene Gutachten."
         *
         * Since the letter window usually contains a declined salutation "Herrn Stefan Müller", the company name
         * must explicitly not be declined in this case: "BBL-Software GmbH" without "der".
         */
        if (salutationDeclined && !letterWindow) {
            companyName = `der ${companyName}`;
        }

        recipientNameParts.push(companyName);
    }
    // A contactPerson is available. Address him/her directly.
    if (contactPerson.lastName) {
        let recipientName = '';
        if (contactPerson.salutation) {
            // Is this full name intended for the letter window of a print document?
            if (salutationDeclined) {
                recipientName = getDeclinedSalutation(contactPerson.salutation);
            } else {
                recipientName = contactPerson.salutation;
            }

            /**
             * If this is the letter recipient for the letter window, add a line break after the salutation if there is no company. Example:
             * Herrn
             * Steffen Langer
             * [An der Leibi 6] <-- Not part of this function
             * [89278 Nersingen] <-- Not part of this function
             */
            if (letterWindow && !contactPerson.organization) {
                recipientName += '\n';
            } else if (recipientName) {
                // If this is not supposed to be a letter recipient but a regular full name, write "Herrn Steffen Langer"
                recipientName += ' ';
            }
        }

        if (contactPerson.firstName) {
            recipientName += `${contactPerson.firstName} `;
        }

        recipientName += contactPerson.lastName;

        recipientNameParts.push(recipientName);
    }
    // No contactPerson available (no last name set)
    //else {
    //}

    return recipientNameParts;
}

<ng-content select="currency-input-prefix"></ng-content>
<ng-content select="currency-input-display-value"></ng-content>
<mat-form-field
    class="mat-block"
    [floatLabel]="defaultValue ? 'always' : floatLabel"
    [ngClass]="{ 'light-mode': lightMode, 'ghost-until-hover': ghostUntilHover, readonly: readonly }"
    [matTooltip]="
        tooltip || (baseValue ? 'Gib einen fixen Wert (z. B. 500 €) oder einen relativen Wert ein (z. B. 50%)' : '')
    ">
    <input
        #input
        matInput
        [placeholder]="placeholder"
        [ngModel]="valueForChildInput"
        (change)="formatNumber($event)"
        (focus)="markAsFocused()"
        (blur)="unmarkAsFocused()"
        [disabled]="disabled"
        [readonly]="readonly"
        [attr.name]="name"
        [axAutofocus]="autofocus"
        [tabindex]="tabindex" />
    <span matSuffix>
        <span class="default-value" *ngIf="defaultValue && valueForChildInput == null && !isInputFocused">
            {{ defaultValue }}
        </span>
        €
    </span>
    <mat-hint class="warning" *ngIf="warning" @fadeInAndOut>{{ warning }}</mat-hint>
    <mat-hint *ngIf="hint" @fadeInAndOut>{{ hint }}</mat-hint>
</mat-form-field>

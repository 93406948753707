<div class="dialog-overlay" (click)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container contains-scrollable-area">
        <h2>Audatex-Vorgang verknüpfen</h2>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>

        <!--============================================-->
        <!-- Audatex Tasks Loaded -->
        <!--============================================-->
        <ng-container *ngIf="!audatexTasksPending">
            <div class="dialog-scrollable-area">
                <!--============================================-->
                <!-- Audatex Task List -->
                <!--============================================-->
                <div id="record-list" *ngIf="audatexTasks" @fadeInAndSlide>
                    <table id="record-table">
                        <!--********** Record **********-->
                        <tr
                            class="audatex-record record"
                            *ngFor="let audatexTask of audatexTasks"
                            (click)="selectAudatexTask(audatexTask); hideAudatexTaskIdInput()"
                            (dblclick)="emitAudatexTaskSelection(); closeDialog()"
                            [ngClass]="{ active: selectedAudatexTask === audatexTask }">
                            <!--********** Icon **********-->
                            <td class="manufacturer-icon-cell">
                                <div class="manufacturer-icon-container" [matTooltip]="audatexTask.make">
                                    <img
                                        class="car-brand-logo"
                                        *ngIf="iconForCarBrandExists(audatexTask.make)"
                                        [src]="iconFilePathForCarBrand(audatexTask.make, 'large')" />
                                    <div
                                        class="no-brand-icon-replacement text-overflow-ellipsis flex-center-content"
                                        *ngIf="!iconForCarBrandExists(audatexTask.make)">
                                        ?
                                    </div>
                                </div>
                            </td>

                            <!--********** Model & Date **********-->
                            <td class="model-and-date-container">
                                <div class="model text-overflow-ellipsis" [matTooltip]="audatexTask.baseModel">
                                    {{ audatexTask.baseModel || 'Kein Fahrzeug' }}
                                </div>
                                <div
                                    class="label creation-date"
                                    matTooltip="Zuletzt bearbeitet: {{ audatexTask.updatedAt | moment: 'DD.MM.YYYY' }}">
                                    {{ capitalize(audatexTask.updatedAt | momentCalendar: 'day') }}
                                </div>
                            </td>

                            <!--********** License Plate **********-->
                            <td class="license-plate-cell">
                                <license-plate
                                    *ngIf="audatexTask.licensePlate"
                                    [licensePlate]="getLicensePlateInAxFormat(audatexTask.licensePlate)"
                                    [disabled]></license-plate>
                            </td>

                            <!--********** Calculated Amount **********-->
                            <td class="vin-container text-align-end">
                                <!--********** No VIN - Placeholder **********-->
                                <div
                                    *ngIf="!audatexTask.vin"
                                    class="no-vin label italic"
                                    matTooltip="In diesem Vorgang ist noch keine VIN eingetragen.">
                                    Keine VIN
                                </div>
                                <!--********** VIN **********-->
                                <div *ngIf="audatexTask.vin" class="vin semibold">{{ audatexTask.vin }}</div>
                            </td>
                        </tr>
                    </table>

                    <!--============================================-->
                    <!-- No Records - Placeholder -->
                    <!--============================================-->
                    <div id="no-records-placeholder" class="text-align-center" *ngIf="!audatexTasks.length">
                        Keine Vorgänge gefunden
                    </div>
                    <!--============================================-->
                    <!-- END No Records - Placeholder -->
                    <!--============================================-->
                </div>
                <!--============================================-->
                <!-- END Audatex Task List -->
                <!--============================================-->

                <!--============================================-->
                <!-- Connect other Calculation -->
                <!--============================================-->
                <div class="link text-align-center" (click)="showAudatexTaskIdInput()" *ngIf="!audatexTaskIdInputShown">
                    Anderer Audatex-Vorgang
                </div>
                <div
                    id="custom-audatex-task-id-input-container"
                    class="text-align-center"
                    *ngIf="audatexTaskIdInputShown">
                    <div class="margin-bottom-10">
                        <a href="https://wissen.autoixpert.de/hc/de/articles/4404167436434" target="_blank">
                            Wo finde ich die Audatex-Task-ID?
                        </a>
                    </div>
                    <mat-form-field>
                        <input
                            matInput
                            [(ngModel)]="customAudatexTaskId"
                            placeholder="Audatex-Task-ID"
                            [axAutofocus]="true" />
                    </mat-form-field>
                </div>
                <!--============================================-->
                <!-- END Connect other Calculation -->
                <!--============================================-->
            </div>
            <!--============================================-->
            <!-- END Audatex Tasks Loaded -->
            <!--============================================-->

            <!--============================================-->
            <!-- Buttons -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button
                    [ngClass]="{ disabled: !selectedAudatexTask && !customAudatexTaskId }"
                    (click)="handleConnectionButtonClick()">
                    Verknüpfen
                </button>
            </div>
            <!--============================================-->
            <!-- END Buttons -->
            <!--============================================-->
        </ng-container>
        <!--============================================-->
        <!-- Dossiers Pending -->
        <!--============================================-->
        <div id="tasks-pending-placeholder" class="text-align-center" *ngIf="audatexTasksPending">
            <img src="/assets/images/icons/loading-indicator.svg" />
            <div><strong>Warte auf Audatex...</strong></div>
            <div class="label">Das kann je nach Tagesform der Audatex-Server etwas dauern.</div>
        </div>
        <!--============================================-->
        <!-- END Dossiers Pending -->
        <!--============================================-->
    </div>
</div>

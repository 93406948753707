import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Service to manage the logo of a team account.
 *
 * The team logo is used in the default header and footer template, in the residual value frontend and in the
 * claimant signatures forms frontend.
 */
@Injectable()
export class TeamLogoService {
    constructor(private httpClient: HttpClient) {}

    public async get(teamId: string) {
        return this.httpClient
            .get(`/api/v0/teamLogos/${teamId}`, {
                responseType: 'blob',
                observe: 'response',
            })
            .toPromise();
    }

    public async remove(teamId: string): Promise<any> {
        return this.httpClient.delete<any>(`/api/v0/teamLogos/${teamId}`).toPromise();
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';

@Component({
    selector: 'autocomplete-bubble-list',
    templateUrl: './autocomplete-bubble-list.component.html',
    styleUrls: ['./autocomplete-bubble-list.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class AutocompleteBubbleListComponent {
    /**
     * List of values to display as bubbles (chips).
     */
    @Input() values: Array<string>;

    /**
     * Event emitter that fires when a bubble was selected. Passes the selected string.
     */
    @Output() valueSelected: EventEmitter<string> = new EventEmitter<string>();

    trackByFn(_: number, value: string): string {
        return value;
    }
}

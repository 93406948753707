import { removeFromArray } from '../../arrays/remove-from-array';

export function mergeChangesBetweenArraysOfPlainValues<PlainValue = string | number | null>({
    serverShadowArray,
    localArray,
    remoteArray,
    forceMergeConflictingChanges,
}: {
    serverShadowArray: PlainValue[];
    localArray: PlainValue[];
    remoteArray: PlainValue[];
    forceMergeConflictingChanges?: boolean;
}) {
    /**
     * The server shadow array may be null or undefined if the property was not yet initialized when the last
     * server sync happened. This function may have been reached if both the remote and the local properties
     * have been initialized in the meantime.
     */
    if (!Array.isArray(serverShadowArray)) {
        serverShadowArray = [];
    }

    // The order changes, too, if the content changes (elements added or removed).
    const orderHasChangedLocally = serverShadowArray.join(',') !== localArray.join(',');
    const orderHasChangedRemotely = serverShadowArray.join(',') !== remoteArray.join(',');
    const isOrderConflict = orderHasChangedLocally && orderHasChangedRemotely;

    // Elements in final order. Always modify the local array to keep a reference to it.
    const sortedMergeResult: PlainValue[] = localArray;

    //*****************************************************************************
    //  Order Conflict
    //****************************************************************************/
    if (isOrderConflict) {
        console.log(
            '[Merge arrays] Order of plain array values has changed locally & remotely. (serverShadowArray, localArray, remoteArray):',
            serverShadowArray,
            localArray,
            remoteArray,
        );

        /**
         * Additions = Present now, but not present before.
         *
         * Comparison with localIds is relevant with Broadcast Channels (while offline) which don't update the server shadow.
         */
        const remoteAdditions: PlainValue[] = remoteArray.filter(
            (remoteValue) => !serverShadowArray.includes(remoteValue) && !localArray.includes(remoteValue),
        );
        const remoteDeletions: PlainValue[] = serverShadowArray.filter(
            (serverShadowId) => !remoteArray.includes(serverShadowId),
        ); // Deletions = Present before, but not present now.

        //*****************************************************************************
        //  Add New Elements
        //****************************************************************************/
        // Add all remote additions to local IDs. Try to add them as close to their predecessor as possible.
        for (const remoteAddition of remoteAdditions) {
            const remoteIndex = remoteArray.indexOf(remoteAddition);

            // To insert the remote addition at the right position, we must identify the element it was inserted after.
            let predecessorValue: PlainValue | undefined = undefined;
            // Check if any of the predecessors still exists.
            for (let i = remoteIndex - 1; i >= 0; i--) {
                const predecessor: PlainValue = remoteArray[i];
                const predecessorExistsInLocalIds: boolean = localArray.includes(predecessor);
                if (predecessorExistsInLocalIds) {
                    predecessorValue = predecessor;
                    break;
                }
            }

            // If the remote predecessor's value exists locally as well, insert the new element after that.
            // We use the finalSortOrder instead of the localArray to consider already inserted elements as reference points for later ones.
            if (predecessorValue !== undefined) {
                const index = sortedMergeResult.indexOf(predecessorValue);
                sortedMergeResult.splice(index + 1, 0, remoteAddition);
            }
            // No predecessor -> Simply insert at the end. This is the case for the first item of the additions if it's at index 0 within the remote IDs.
            else {
                sortedMergeResult.push(remoteAddition);
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Add New Elements
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Remove Deleted Elements
        //****************************************************************************/
        for (const remoteDeletion of remoteDeletions) {
            removeFromArray(remoteDeletion, sortedMergeResult);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Remove Deleted Elements
        /////////////////////////////////////////////////////////////////////////////*/
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Order Conflict
    /////////////////////////////////////////////////////////////////////////////*/
    /**
     * Order has changed remotely only -> Use that order.
     * Forcing to merge conflicting changes (occurs when syncing local Broadcast Channel changes) -> Use remote order.
     */
    else if (orderHasChangedRemotely || (isOrderConflict && forceMergeConflictingChanges)) {
        // Remove all array elements
        sortedMergeResult.splice(0, sortedMergeResult.length);
        // Add all remote elements
        sortedMergeResult.push(...remoteArray);
    }

    if (!sortedMergeResult.length) {
        console.warn('[Merge arrays] After merging, the sortedMergeResult is empty.');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Merge Sort Order
    /////////////////////////////////////////////////////////////////////////////*/

    return sortedMergeResult;
}

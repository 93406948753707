import { Router } from '@angular/router';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getResidualValueAndMarketAnalysisErrorHandlers(router: Router): {
    [key: string]: ApiErrorHandler | ApiErrorHandlerCreator;
} {
    return {
        //*****************************************************************************
        //  AUTOonline
        //****************************************************************************/
        WRONG_AUTOONLINE_CREDENTIALS: {
            title: 'Zugangsdaten ungültig',
            body: 'Bitte überprüfe deine Zugangsdaten auf der <a href="https://easyonline.autoonline.com" target="_blank">Website von AUTOonline</a> und trage die aktuellen Daten in den <a href="/Einstellungen" target="_blank">aX-Einstellungen</a> ein.',
        },
        AUTOONLINE_OFFER_STILL_RUNNING: {
            toastType: 'info',
            title: 'Inserat läuft noch',
            body: 'AUTOonline lässt dich die Gebote erst abrufen, wenn die Gebotsfrist abgelaufen ist.',
        },
        AUTOONLINE_AUCTION_NOT_FINISHED: {
            // This is different from the error 'AUTOONLINE_OFFER_STILL_RUNNING'. An auction can usually not be done through autoiXpert.
            title: 'Auktion noch nicht beendet',
            body: 'AUTOonline lässt dich die Gebote erst abrufen, wenn die Auktion beendet ist.',
        },
        AUTOONLINE_OFFER_NOT_FOUND: (error) => ({
            title: 'Inserat nicht gefunden',
            body: `Wurde es möglicherweise über die Oberfläche von AUTOonline gelöscht?
Falls das Inserat manuell verknüpft wurde, prüfe zur Sicherheit noch einmal die Inserats-ID ${error.data.autoonlineOfferId}`,
        }),
        AUTOONLINE_ACCESS_TO_OFFER_DENIED: {
            title: 'Zugriff auf Inserat verweigert',
            body: 'Du hast keinen Zugriff auf das Inserat bei AUTOonline.',
        },
        AUTOONLINE_OFFER_CANCELED: {
            title: 'Inserat storniert',
            body: 'Das Inserat bei AUTOonline wurde storniert. Gebote können nicht abgefragt werden.',
        },
        AUTOONLINE_ZIP_CODE_MISSING: {
            title: 'Postleitzahl fehlt',
            body: 'Bitte trage eine Postleitzahl beim Anspruchsteller oder im Fahrzeugstandort auf der Karte "Restwert" ein.',
        },
        AUTOONLINE_DAMAGE_DESCRIPTION_MISSING: {
            title: 'Schadenbeschreibung fehlt',
            body: 'AUTOonline erfordert eine Schadenbeschreibung.',
        },
        AUTOONLINE_REGIONAL_RADIUS_CANNOT_BE_USED_FOR_FOREIGN_VEHICLES: {
            title: 'Regionaler Umkreis nur für Fahrzeuge in Deutschland ',
            body: 'Bitte entferne den regionalen Radius aus der Restwert-Konfiguration und frage die Gebote erneut ab.',
        },
        AUTOONLINE_UNKNOWN_ERROR: (error) => {
            return {
                title: 'Unbekannter AUTOonline-Fehler',
                body: `<strong>Fehlermeldung von AUTOonline</strong>: ${error.data?.audatexErrorMessage}`,
            };
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END AUTOonline
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  VALUEpilot
        //****************************************************************************/
        WRONG_VALUEPILOT_CREDENTIALS: {
            title: 'VALUEpilot-Zugangsdaten ungültig',
            body: 'Bitte überprüfe deine Zugangsdaten auf der <a href="https://valuepilot.autoonline.com" target="_blank">Website von VALUEpilot</a> und trage die aktuellen Daten in den <a href="/Einstellungen" target="_blank" rel="noopoener">aX-Einstellungen</a> ein.',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END VALUEpilot
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  car.casion
        //****************************************************************************/
        CARCASION_WRONG_CREDENTIALS: {
            title: 'Zugangsdaten ungültig',
            body: 'Bitte überprüfe deine car.casion-Zugangsdaten in den <a href="/Einstellungen" target="_blank">Einstellungen</a>.',
        },
        CARCASION_MISSING_ACCESS_RIGHTS: {
            title: 'Fehlende Zugriffsrechte',
            body: 'Bitte prüfe, ob die über die car.casion-Oberfläche Zugriff auf car.casion hast.<br><br>Falls nicht, kontaktiere deinen car.casion Ansprechpartner.',
        },
        CARCASION_VEHICLE_NOT_PLAUSIBLE: (error: AxError) => ({
            title: 'car.casion-Gebote nicht abrufbar',
            body: `Der Vorgang wird zur Qualitätssicherung von car.casion manuell geprüft. Versuche es später erneut.<br><br><strong>car.casion-Nachricht:</strong> ${error.data.carcasionErrorMessage}<br><br>Bitte melde dich bei Fragen bei <a href="https://net.casion.de/" target="_blank" rel="noopener">car.casion</a> (+49 571 911908-14).`,
        }),
        CARCASION_ERROR: (error) => {
            let carcasionErrorHtml = '';
            if (error.data?.carcasionErrorMessage) {
                carcasionErrorHtml = `<br><br>Fehlermeldung von car.casion: ${error.data.carcasionErrorMessage}`;
            }

            return {
                title: 'car.casion Error',
                body: `Ein Fehler beim Export an car.casion ist aufgetreten.${carcasionErrorHtml}`,
            };
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END car.casion
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  CarTV
        //****************************************************************************/
        CARTV_AUTHENTICATION_FAILED: {
            title: 'Zugangsdaten ungültig',
            body: 'Bitte prüfe deine Zugangsdaten über die offizielle CARTV-Restwertbörse und passe sie ggf. in den autoiXpert-Einstellungen an.',
            partnerLogo: 'cartv',
        },
        CARTV_CUSTOMER_NUMBER_INVALID: {
            title: 'Kundennummer ungültig',
            body: 'Bitte prüfe deine CARTV-Kundennummer in den autoiXpert-Einstellungen. Sie sollte 7 Stellen haben.',
            partnerLogo: 'cartv',
        },
        CARTV_ORDERID_MISSING: {
            title: 'cartv Order ID nicht vorhanden',
            body: 'An cartv wird keine Order ID übergeben. Bitte bei der <a href="/Hilfe" target="_blank">Hotline melden</a>.',
            partnerLogo: 'cartv',
        },
        WRONG_CARTV_CREDENTIALS: (error) => ({
            title: 'Zugangsdaten abgelehnt',
            body: `Die Zugangsdaten zu CARTV wurden abgelehnt. Bitte prüfe sie in den <a href="/Einstellungen#residual-and-market-value-exchanges-section" target="_blank">Einstellungen</a>.<br><br><span class="reason-label">cartv-Error:</span> ${error.data?.cartvErrorMessage}`,
            partnerLogo: 'cartv',
        }),
        CARTV_TEST_ACCOUNT_EXPIRED: (error) => ({
            title: 'cartv-Test-Account abgelaufen',
            body: `Bitte kontaktiere deinen cartv-Vertriebler oder schicke eine E-Mail an <a href="mailto:vertrieb-sv@cartv.eu" target="_blank" rel="noopener">vertrieb-sv@cartv.eu</a>.<br><br><span class="reason-label">cartv-Error:</span> ${error.data?.cartvErrorMessage}`,
            partnerLogo: 'cartv',
        }),
        CARTV_UNABLE_TO_MATCH_VEHICLE_DETAILS: (error) => ({
            title: 'cartv kann Fahrzeug nicht finden',
            body: `Oft liegt das daran, dass cartv's Datenlieferant DAT das Fahrzeug nicht kennt. Bitte kontaktiere bei Fragen den <a href="mailto:technik@cartv.eu" target="_blank" rel="noopener">cartv-Support</a> oder den <a href="https://www.dat.de/service/support/" target="_blank" rel="noopener">DAT-Support</a>.<br><br><span class="reason-label">cartv-Error:</span> ${error.data?.cartvErrorMessage}`,
            partnerLogo: 'cartv',
        }),
        WRONG_CARTV_API_KEY: {
            title: 'Zugangsschlüssel ungültig',
            body: `CARTV hat den API-Key abgelehnt. Bitte prüfe deinen Zugangsschlüssel in den <a href="/Einstellungen#residual-and-market-value-exchanges-section" target="_blank">Einstellungen</a>.`,
            partnerLogo: 'cartv',
        },
        CARTV_RESIDUAL_VALUE_OFFER_NOT_FOUND: (error) => ({
            title: 'Inserat nicht gefunden',
            body: `CARTV konnte das Inserat nicht finden. Entkopple das Inserat und verknüpfe es erneut über das Dreipunktmenü.
Falls das Inserat manuell verknüpft wurde, prüfe zur Sicherheit noch einmal die Inserats-ID ${error.data.offerId}`,
            partnerLogo: 'cartv',
        }),
        WRONG_CARTV_USER: {
            title: 'Nutzer ungültig',
            body: `Der Nutzer, mit dem das Inserat angelegt wurde, ist nicht der Nutzer in den autoiXpert-Einstellungen. Bitte prüfe deinen Nutzernamen in den <a href="/Einstellungen#residual-and-market-value-exchanges-section" target="_blank">Einstellungen</a>.`,
            partnerLogo: 'cartv',
        },
        CARTV_ACCOUNT_LOCKED: {
            title: 'cartv-Account gesperrt',
            body: `CARTV hat gemeldet, dass ein Problem mit dem Kundenstatus des Accounts vorliegt. Bitte kontaktiere den <a href="mailto:technik@cartv.eu" target="_blank" rel="noopener">cartv-Support</a>.`,
            partnerLogo: 'cartv',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END CarTV
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  WinValue
        //****************************************************************************/
        WINVALUE_AUTHENTICATION_FAILED: (error) => ({
            title: 'Zugangsdaten ungültig',
            body:
                'Bitte prüfe deine Zugangsdaten über die offizielle <a href="https://rb.winvalue.de/" target="_blank" rel=”noopener”>WinValue-Restwertbörse</a> und passe sie ggf. in den autoiXpert-Einstellungen an.' +
                (error.data?.winvalueErrorMessage
                    ? `<br><br><strong>WinValue-Meldung</strong>: ${error.data.winvalueErrorMessage}`
                    : ''),
        }),
        WINVALUE_MARKET_ANALYSIS_AUTHENTICATION_FAILED: (error) => ({
            title: 'Zugangsdaten ungültig',
            body:
                'Winvalue hat deinen API-Schlüssel abgelehnt. <a href="https://wissen.autoixpert.de/hc/de/articles/360004013091" target="_blank" rel=”noopener”>So findest du deinen API-Schlüssel</a>.' +
                (error.data?.winvalueErrorMessage
                    ? `<br><br><strong>WinValue-Meldung</strong>: ${error.data.winvalueErrorMessage}`
                    : ''),
        }),
        UNKNOWN_WINVALUE_ERROR: (error) => ({
            title: 'Unbekannter WinValue-Fehler',
            body:
                'WinValue hat einen unbekannten Fehler zurückgegeben: .' +
                (error.data?.winvalueErrorMessage
                    ? `<br><br><strong>WinValue-Meldung</strong>: ${error.data.winvalueErrorMessage}`
                    : ''),
        }),
        ERROR_SAVING_WINVALUE_RESIDUAL_VALUE_EXCHANGE_PDF: {
            title: 'Gebotsblatt konnte nicht geladen werden',
            body: 'Bitte prüfe, ob WinValue online ist, und versuche es erneut.',
        },
        WINVALUE_REGIONAL_BID_LIST_MISSING: {
            title: 'Regionales Gebotsblatt fehlt',
            body: 'Bitte aktiviere in der Oberfläche von Winvalue die Einstellung "Endkundendokument senden".',
        },
        WINVALUE_ID_MISSING: {
            title: 'WinValue-ID fehlt',
            body: 'Bitte prüfe, das Gutachten vollständig zum Server synchronisiert ist und importiere die Ergebnisse der Marktwertanalyse erneut.<br><br>Falls du die Meldung wieder bekommst, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        AUTOIXPERT_VEHICLE_TYPE_CANNOT_BE_TRANSLATED_TO_WINVALUE_VEHICLE_TYPE: {
            title: 'Fahrzeugart fehlt',
            body: 'Die Fahrzeugart (Reiter "Fahrzeugauswahl") ist in autoiXpert noch nicht definiert. Bitte wähle sie aus, damit Restwerthändler dein Fahrzeug optimal finden können.',
        },
        MICROSERVICE_NOT_RUNNING: {
            title: 'WinValue Timeout',
            body: 'WinValue konnte die Server-Anfrage nicht schnell genug bearbeiten. Probiere es erneut.<br>Falls du die Meldung wieder bekommst, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        EXTERNAL_SERVICE_UNAVAILABLE_FOR_MULTIPLE_REQUESTS_IN_A_SHORT_PERIOD: {
            title: 'WinValue hat Server-Probleme',
            body: 'WinValue konnte in den letzten Minuten mehrere Server-Anfragen nicht beantworten und hat wahrscheinlich Server-Probleme. Kontaktiere die WinValue-Hotline.',
        },
        WINVALUE_RESIDUAL_VALUE_OFFER_NOT_FOUND: (error) => ({
            title: 'Inserat nicht gefunden',
            body: `WinValue konnte das Inserat nicht finden. Entkopple das Inserat und verknüpfe es erneut über das Dreipunktmenü.
Falls das Inserat manuell verknüpft wurde, prüfe zur Sicherheit noch einmal die Inserats-ID ${error.data.winvalueOfferId}`,
            partnerLogo: 'winvalue',
        }),
        WRONG_SEARCH_STATE: {
            title: 'Suche für Marktanalyse ausführen',
            body: 'Bitte wechsele zu Winvalue und führe erst die Suche für die Marktanalyse aus.',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END WinValue
        /////////////////////////////////////////////////////////////////////////////*/

        NO_OFFER_ID: {
            title: 'Kein Inserat gefunden',
            body: 'Bitte entferne das Inserat und lege es erneut an.',
        },
        MISSING_DAMAGE_CALCULATION: {
            title: 'Kalkulation fehlt',
            body: 'Schadenskalkulation für CARTV-Export erforderlich.',
        },
        DAT_DAMAGE_CALCULATION_DOCUMENT_MISSING: {
            title: 'DAT-Kalkulation fehlt',
            body: 'Bitte importiere die Schadenskalkulation erneut. Falls das nicht funktioniert, melde dich bitte bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        AUDATEX_DAMAGE_CALCULATION_DOCUMENT_MISSING: {
            title: 'Audatex-Kalkulation fehlt',
            body: 'Bitte importiere die Schadenskalkulation erneut. Falls das nicht funktioniert, melde dich bitte bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        RENDERING_DAMAGE_CALCULATION_FAILED: {
            title: 'Anonymisierung fehlgeschlagen',
            body: 'Die Schadenskalkulation konnte nicht anonymisiert werden. Bitte bei der <a href="/Hilfe" target="_blank">Hotline melden</a>.',
        },
        FILE_NOT_FOUND: (error) => {
            if (error.data.bucket === 'photos-autoixpert') {
                // replace both -report-rendered and -residualValueExchange-rendered
                const photoId = error.data.id.replace(/-.+-rendered/, '');
                return {
                    title: 'Foto-Datei nicht gefunden',
                    body: 'Die Ursache ist vermutlich ein fehlerhafter Upload. Klicke hier, um zu dem Foto ohne Bild-Datei zu springen.',
                    toastClick: () => {
                        this.router.navigate(['Gutachten', this.reportId, 'Fotos'], {
                            queryParams: {
                                selectedPhotos: photoId,
                                forceLoadingPhotosFromServer: true,
                            },
                        });
                    },
                };
            } else {
                return {
                    title: 'Dokument nicht gefunden',
                    body: 'Ein zu übertragenes Dokument konnte nicht gefunden werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                };
            }
        },
        TOO_MANY_PHOTOS_FOR_RESIDUAL_VALUE_EXCHANGE: {
            title: 'Zu viele Fotos für Restwertbörse',
            body: 'Die Restwertbörsen-Schnittstellen funktionieren nur bis zu 50 Fotos pro Export zuverlässig. Bitte reduziere die Anzahl der ausgewählten Fotos.',
        },
        ERROR_RENDERING_PHOTO: (error) => {
            const photoId = error.data.photoId;
            return {
                title: 'Foto kann nicht gerendert werden',
                body: 'Die Ursache ist vermutlich etwas Technisches. Versuche, eine Form auf dem Bild anzupassen, damit das Foto neu gerendert wird. Klicke hier, um zu dem Foto zu springen.',
                toastClick: () => {
                    this.router.navigate(['Gutachten', this.reportId, 'Fotos'], {
                        queryParams: {
                            selectedPhotos: photoId,
                        },
                    });
                },
            };
        },
    };
}

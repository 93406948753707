import { AxError } from '../../models/errors/ax-error';
import { DatabaseServiceName } from '../../models/indexed-db/database.types';

/**
 * Get the German name for the record type associated with a local database service in singular and plural form.
 * @param databaseServiceName
 */
export function translateDatabaseServiceName(databaseServiceName: DatabaseServiceName): {
    singular: string;
    plural: string;
} {
    switch (databaseServiceName) {
        case 'autoixpertAccountingData':
            return {
                singular: 'AutoiXpert Buchhaltungsdaten',
                plural: 'AutoiXpert Buchhaltungsdaten',
            };
        case 'axLicenses':
            return {
                singular: 'AutoiXpert Lizenz',
                plural: 'AutoiXpert Lizenzen',
            };
        case 'documentLayoutGroup':
            return {
                singular: 'Dokumenten-Layout',
                plural: 'Dokumenten-Layouts',
            };
        case 'fileNamePattern':
            return {
                singular: 'Dateinamen-Muster',
                plural: 'Dateinamen-Muster',
            };
        case 'emailSignature':
            return {
                singular: 'E-Mail-Signatur',
                plural: 'E-Mail-Signaturen',
            };
        case 'referralFee':
            return {
                singular: 'Empfehlungsbonus',
                plural: 'Empfehlungsboni',
            };
        case 'carEquipment':
            return {
                singular: 'Ausstattungen',
                plural: 'Ausstattungen',
            };
        case 'carEquipmentTemplate':
            return {
                singular: 'Ausstattungen-Vorlage',
                plural: 'Ausstattungen-Vorlagen',
            };
        case 'contactPerson':
            return {
                singular: 'Kontakt',
                plural: 'Kontakte',
            };
        case 'counter':
            return {
                singular: 'Zähler',
                plural: 'Zähler',
            };
        case 'claimantSignatureFile':
            return {
                singular: 'Kundenunterschrift',
                plural: 'Kundenunterschriften',
            };
        case 'customAutocompleteEntry':
            return {
                singular: 'Autocomplete-Eintrag',
                plural: 'Autocomplete-Einträge',
            };
        case 'customFeeSet':
            return {
                singular: 'Eigene Honorartabelle',
                plural: 'Eigene Honorartabellen',
            };
        case 'fieldGroupConfig':
            return {
                singular: 'Eigene-Felder-Config',
                plural: 'Eigene-Felder-Configs',
            };
        case 'documentBuildingBlock':
            return {
                singular: 'Textbaustein',
                plural: 'Textbausteine',
            };
        case 'downtimeNotification':
            return {
                singular: 'Wartungsankündigung',
                plural: 'Wartungsankündigungen',
            };
        case 'documentOrderConfig':
            return {
                singular: 'Dokument-Struktur',
                plural: 'Dokument-Strukturen',
            };
        case 'gtueImportHistory':
            return {
                singular: 'GTÜ-Import-Historie',
                plural: 'GTÜ-Import-Historien',
            };
        case 'importHistory':
            return {
                singular: 'Import-Historie',
                plural: 'Import-Historien',
            };
        case 'invoiceTemplate':
            return {
                singular: 'Rechnungsvorlage',
                plural: 'Rechnungsvorlagen',
            };
        case 'invoiceLineItemTemplate':
            return {
                singular: 'Rechnungsposition-Vorlage',
                plural: 'Rechnungsposition-Vorlagen',
            };
        case 'invoice':
            return {
                singular: 'Rechnung',
                plural: 'Rechnungen',
            };
        case 'labelConfig':
            return {
                singular: 'Listen-Label',
                plural: 'Listen-Labels',
            };
        case 'leaseReturnTemplate':
            return {
                singular: 'Prüfleitfaden',
                plural: 'Prüfleitfaden',
            };
        case 'manufacturers':
            return {
                singular: 'Fahrzeughersteller',
                plural: 'Fahrzeughersteller',
            };
        case 'originalPhotoFile':
            return {
                singular: 'Foto',
                plural: 'Fotos',
            };
        case 'renderedPhotoFile':
            return {
                singular: 'Gerendertes Foto',
                plural: 'Gerenderte Fotos',
            };
        case 'reportProgressConfig':
            return {
                singular: 'Gutachten-Fortschritt-Config',
                plural: 'Gutachten-Fortschritt-Configs',
            };
        case 'report':
            return {
                singular: 'Gutachten',
                plural: 'Gutachten',
            };
        case 'residualValueBidderGroup':
            return {
                singular: 'Bietergruppe',
                plural: 'Bietergruppen',
            };
        case 'signablePdfTemplate':
            return {
                singular: 'Kundenunterschrifts-Dokument',
                plural: 'Kundenunterschrifts-Dokumente',
            };
        case 'signablePdfTemplateConfig':
            return {
                singular: 'Kundenunterschrifts-Dokument-Konfiguration',
                plural: 'Kundenunterschrifts-Dokument-Konfigurationen',
            };
        case 'signablePdfTemplateImage':
            return {
                singular: 'Kundenunterschrifts-Dokument-Bild',
                plural: 'Kundenunterschrifts-Dokument-Bild',
            };
        case 'signablePdfTemplateThumbnail':
            return {
                singular: 'Kundenunterschrifts-Dokument-Vorschau-Bild',
                plural: 'Kundenunterschrifts-Dokument-Vorschau-Bild',
            };
        case 'task':
            return {
                singular: 'Aufgabe',
                plural: 'Aufgaben',
            };
        case 'team':
            return {
                singular: 'Team',
                plural: 'Teams',
            };
        case 'textTemplate':
            return {
                singular: 'Textvorlage',
                plural: 'Textvorlagen',
            };
        case 'user':
            return {
                singular: 'Nutzer',
                plural: 'Nutzer',
            };
        case 'userProfilePicture':
            return {
                singular: 'Profilbild',
                plural: 'Profilbilder',
            };
        case 'watermarkImageFile':
            return {
                singular: 'Wasserzeichen-Grafik',
                plural: 'Wasserzeichen-Grafiken',
            };
        case 'outgoingMessage':
            return {
                singular: 'Nachricht',
                plural: 'Nachrichten',
            };
        default:
            console.error(
                new AxError({
                    code: 'SERVICE_NAME_CANNOT_BE_TRANSLATED',
                    message: `Please add the service name "${databaseServiceName}" to this translation registry. This is usually the case after a new service was created but not manually added here.`,
                    data: {
                        databaseServiceName,
                    },
                }),
            );
            return {
                singular: databaseServiceName,
                plural: databaseServiceName,
            };
    }
}

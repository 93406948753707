import {
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import { round } from '@autoixpert/lib/numbers/round';

@Directive({
    selector: 'input[number-input]',
})
export class NumberInputDirective {
    constructor(private hostElement: ElementRef) {}

    @Input() minimumFractionDigits: number;
    @Input() maximumFractionDigits: number;

    private DEFAULT_MIN_FRACTION_DIGITS: number = 2;
    private DEFAULT_MAX_FRACTION_DIGITS: number = 2;

    /**
     * Translate the value of type number to a string in German locale format. This adds a comma instead of a dot
     * as the decimal delimiter and adds a dot as a thousands separator.
     * @param givenValue
     */
    @Input() number: number;

    @Output() numberChange: EventEmitter<number> = new EventEmitter<number>();

    @HostBinding('class') class = 'number-input';

    ngOnInit() {
        (this.hostElement.nativeElement as HTMLInputElement).classList.add('number-input');

        // Simple comparison captures both null and undefined
        if (this.minimumFractionDigits == null) {
            this.minimumFractionDigits = this.DEFAULT_MIN_FRACTION_DIGITS;
        }
        if (this.maximumFractionDigits == null) {
            this.maximumFractionDigits = this.DEFAULT_MAX_FRACTION_DIGITS;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        /**
         * The user may change how many fraction digits are shown in valuation reports. This should be reflected in the input field.
         */
        if ((changes['maximumFractionDigits'] || changes['minimumFractionDigits']) && !changes['number']) {
            this.displayFormattedValue(this.number);
        }

        if (changes['number']) {
            const givenValue = changes['number']?.currentValue;

            /**
             * Given value should
             * - not be null (could be set by parent component of pristine report)
             * - not be empty string (user removed number that existed before)
             * - be a number ("15a" is not a number and should be ignored. null and empty string (wtf?) are numbers)
             */
            if (givenValue !== null && givenValue !== '' && !isNaN(givenValue)) {
                /**
                 * Only call the display value method as soon as at least one input value has been set.
                 * Angular may only set them after setting the numericalValue setter.
                 */
                if (this.minimumFractionDigits != null || this.maximumFractionDigits != null) {
                    this.displayFormattedValue(givenValue);
                } else {
                    // Next cycle
                    window.setTimeout(() => this.displayFormattedValue(givenValue));
                }
            } else {
                this.hostElement.nativeElement.value = null;
            }
        }
    }

    @HostListener('change') onChange() {
        const inputValue = this.hostElement.nativeElement.value;

        if (inputValue === '') {
            this.numberChange.emit(inputValue);
            return;
        }

        const parsedInputValue: number = this.parseInputValue(inputValue);
        this.displayFormattedValue(parsedInputValue);
    }

    /**
     * Parses and formats the string from the input field, and emits the number as type number.
     */
    public parseInputValue(inputValue): number {
        const numberString: string = inputValue
            // Replace all thousand delimiters (dots) in German numbers.
            .replace(/\./g, '')
            // Replace the decimal delimiter (comma) in German numbers with a dot.
            .replace(',', '.');

        // Convert type string to type number.
        const number: number = round(parseFloat(numberString), this.maximumFractionDigits);

        this.numberChange.emit(number);

        return number;
    }

    private displayFormattedValue(value: number) {
        this.hostElement.nativeElement.value = Number(value).toLocaleString('de-DE', {
            minimumFractionDigits: this.minimumFractionDigits,
            maximumFractionDigits: this.maximumFractionDigits,
        });
    }
}

import { generateId } from '../../lib/generate-id';
import { PaintThicknessColorMap, PaintThicknessType } from './paint-thickness-measurement';

export interface PaintThicknessScaleRangeDefinition {
    type: PaintThicknessType;
    minValue: number;
    maxValue: number;
    color: string;
}

export class PaintThicknessScale {
    constructor(template: Partial<PaintThicknessScale> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    isDefault: boolean = false;
    name: string = '';
    rangeDefinitions: PaintThicknessScaleRangeDefinition[] = structuredClone(
        defaultPaintThicknessScaleRangeDefinitions,
    );
}

export const defaultPaintThicknessScaleRangeDefinitions: PaintThicknessScaleRangeDefinition[] = [
    {
        type: 'thin',
        minValue: -Infinity,
        maxValue: 69,
        color: PaintThicknessColorMap['thin'],
    },
    {
        type: 'original',
        minValue: 70,
        maxValue: 165,
        color: PaintThicknessColorMap['original'],
    },
    {
        type: 'secondCoatOfPaint',
        minValue: 166,
        maxValue: 300,
        color: PaintThicknessColorMap['secondCoatOfPaint'],
    },
    {
        type: 'thinFillerLayer',
        minValue: 301,
        maxValue: 700,
        color: PaintThicknessColorMap['thinFillerLayer'],
    },
    {
        type: 'thickFillerLayer',
        minValue: 701,
        maxValue: Infinity,
        color: PaintThicknessColorMap['thickFillerLayer'],
    },
];

export const defaultPaintThicknessScale: PaintThicknessScale = {
    _id: '1',
    isDefault: true,
    name: 'Standard',
    rangeDefinitions: defaultPaintThicknessScaleRangeDefinitions,
};

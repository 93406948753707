import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomCarProperty } from '@autoixpert/models/reports/car-identification/custom-car-property';

@Component({
    selector: 'custom-car-property-input-title',
    templateUrl: './custom-car-property-input-title.component.html',
    styleUrls: ['./custom-car-property-input-title.component.scss'],
})
export class CustomCarPropertyInputTitleComponent {
    @Input() property: CustomCarProperty;
    @Input() isReportLocked: boolean;

    // List of all known properties which are not used yet.
    @Input() availableProperties: CustomCarProperty['title'][];

    @Output() propertyChanged = new EventEmitter<CustomCarProperty>();

    filteredPropertyTitlesAutocomplete: CustomCarProperty['title'][] = [];

    ngOnInit() {
        this.filterPropertyTitlesForAutocomplete();
    }

    /**
     * Filter the available property titles for the autocomplete.
     */
    filterPropertyTitlesForAutocomplete() {
        if (!this.property.title) {
            this.filteredPropertyTitlesAutocomplete = this.availableProperties;
            return;
        }

        // Return only properties, that contain the user's search terms.
        const searchTerms = this.property.title.toLowerCase().split(' ') ?? [];
        this.filteredPropertyTitlesAutocomplete = this.availableProperties.filter((propertyTitle) => {
            return searchTerms.every((searchTerm) => {
                return propertyTitle.toLowerCase().includes(searchTerm);
            });
        });
    }

    /**
     * Helpers
     */
    emitChange() {
        this.propertyChanged.emit(this.property);
    }
}

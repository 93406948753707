import { Pipe, PipeTransform } from '@angular/core';
import { IsoDate } from '../lib/date/iso-date.types';
import { getRelativeDate } from '../lib/date/relative-date';

/**
 * Format the date as a relative date string.
 */
@Pipe({
    name: 'relativeDate',
    pure: true,
})
export class RelativeDatePipe implements PipeTransform {
    transform(date: IsoDate | Date): string {
        return getRelativeDate(date);
    }
}

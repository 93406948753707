import { TeamPreferences } from '../../models/teams/team-preferences';

/**
 * Round to the nearest integer or to the nearest step size.
 *
 * Usage examples:
 * - Round to the nearest .5 value (1, 1.5, 2, 2.5 etc.)
 * - Round to the nearest 10, (0, 10, 20, 30)
 */
export function roundToNearest({
    toBeRounded,
    stepSize,
    type = 'round',
}: {
    toBeRounded: number;
    stepSize: number;
    type?: TeamPreferences['valuation_decreaseRoundType'];
}): number {
    // Do not round
    if (!stepSize) {
        return toBeRounded;
    }

    /**
     * Logic:
     * Given 0.3 rounded to the nearest 0.5: Divide the toBeRounded value by the stepSize to
     * know how often the stepSize fits into it.
     * Then round to see if the stepSize fits fully or partially.
     * Multiply out to get the actual nearest stepped size.
     */
    const ratio = toBeRounded / stepSize;
    let roundedRatio = 0;
    switch (type) {
        case 'ceil':
            roundedRatio = Math.ceil(ratio);
            break;
        case 'floor':
            roundedRatio = Math.floor(ratio);
            break;
        case 'round':
            roundedRatio = Math.round(ratio);
            break;
    }
    return roundedRatio * stepSize;
}

import { OfficeLocation } from '../../models/teams/office-location';

interface ConfigWithAssociatedOfficeLocation {
    isDefault: boolean;
    associatedOfficeLocationIds?: OfficeLocation['_id'][];
}

export function matchAssociatedOfficeLocation<T extends ConfigWithAssociatedOfficeLocation>(
    configs: T[],
    officeLocationId: OfficeLocation['_id'],
) {
    // TODO: Merge with the respective backend function used for selecting header and footer templates for rendering document templates.

    // Check if there is a config for the office location
    if (officeLocationId) {
        const matchingReportTokenConfig = configs.find((config) =>
            config.associatedOfficeLocationIds?.includes(officeLocationId),
        );
        if (matchingReportTokenConfig) {
            return matchingReportTokenConfig;
        }
    }

    // return default config if no specified or if no office location set
    return configs.find((config) => config.isDefault);
}

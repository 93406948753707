import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subscription, merge } from 'rxjs';
import { first } from 'rxjs/operators';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { Subtype } from '@autoixpert/helper-types/subtype';
import { findRecordById } from '@autoixpert/lib/arrays/find-record-by-id';
import { joinList } from '@autoixpert/lib/arrays/join-list';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { isBuildingBlockConditionMet } from '@autoixpert/lib/document-building-blocks/choose-document-building-blocks';
import { duplicateDocumentBuildingBlockCondition } from '@autoixpert/lib/document-building-blocks/duplicate-document-building-block-condition';
import { enhancedDocumentBuildingBlocks } from '@autoixpert/lib/document-building-blocks/enhanced-document-building-blocks';
import { getDocumentBuildingBlockPlaceholder } from '@autoixpert/lib/document-building-blocks/get-document-building-block-placeholder';
import {
    DocumentTypeGroup,
    DocumentTypeGroupName,
    getDocumentTypeGroupsFromDocumentOrderConfigs,
} from '@autoixpert/lib/documents/get-document-type-groups-from-document-order-configs';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { generateId } from '@autoixpert/lib/generate-id';
import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { translateDocumentOrderConfigName } from '@autoixpert/lib/translators/translate-document-order-config-name';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { translateDocumentTypeGroupToGerman } from '@autoixpert/lib/translators/translate-document-type-group';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { DocumentBuildingBlockVariant } from '@autoixpert/models/documents/document-building-block-variant';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrderConfig, DocumentTemplateType } from '@autoixpert/models/documents/document-order-config';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { DefaultDocumentOrderItem } from '@autoixpert/models/teams/default-document-order/default-document-order-item';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { openEnhancedDocumentBuildingBlockHelp } from 'src/app/shared/libraries/document-building-blocks/open-enhanced-document-building-block-help';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { TemplatePlaceholderValuesService } from 'src/app/shared/services/template-placeholder-values.service';
import { runChildAnimations } from '../../shared/animations/run-child-animations.animation';
import { slideOutVertical } from '../../shared/animations/slide-out-vertical.animation';
import { isSmallScreen, isTouchOnly } from '../../shared/libraries/is-small-screen';
import { scrollToTheTop } from '../../shared/libraries/scroll-to-the-top';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { DocumentBuildingBlockService } from '../../shared/services/document-building-block.service';
import { DocumentOrderConfigService } from '../../shared/services/document-order-config.service';
import { FieldGroupConfigService } from '../../shared/services/field-group-config.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { TeamService } from '../../shared/services/team.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserService } from '../../shared/services/user.service';
import { DocumentOrderConfigAssociationChange } from '../document-types-dialog/document-types-dialog.component';

@Component({
    selector: 'document-building-block-list',
    templateUrl: 'document-building-block-list.component.html',
    styleUrls: ['document-building-block-list.component.scss'],
    animations: [slideOutVertical(), runChildAnimations()],
})
export class DocumentBuildingBlockListComponent implements OnInit, OnDestroy {
    constructor(
        public userPreferences: UserPreferencesService,
        private route: ActivatedRoute,
        private documentBuildingBlockService: DocumentBuildingBlockService,
        private screenTitleService: ScreenTitleService,
        private dialogService: MatDialog,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private toastService: ToastService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private apiErrorService: ApiErrorService,
        private router: Router,
        private invoiceService: InvoiceService,
        private reportService: ReportService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private networkStatusService: NetworkStatusService,
        private httpClient: HttpClient,
        private userService: UserService,
    ) {}

    public buildingBlocks: DocumentBuildingBlock[] = [];
    public filteredBuildingBlocks: DocumentBuildingBlock[] = [];

    public user: User;
    private team: Team;

    public searchTerm: string = '';
    private subscriptions: Subscription[] = [];

    public blockTitlesInEditMode: Map<DocumentBuildingBlock, boolean> = new Map();
    // While the user is editing a block title, store the new title here (temporarily; gets saved on confirm)
    public newBlockTitlesInEditMode: Map<DocumentBuildingBlock['_id'], string> = new Map();
    public documentBuildingBlockVariantEditorShown: boolean = false;
    public documentBuildingBlockEditorShown: boolean = false;
    public documentOrderConfigCreationDialogShownWithType: null | Subtype<
        DocumentTypeGroupName,
        'reportAttachment' | 'signature'
    > = null;
    protected documentOrderConfigCreationDialogConfigToEdit: DocumentOrderConfig = null;
    public currentlyEditedBuildingBlock: DocumentBuildingBlock;
    public currentlyEditedVariant: DocumentBuildingBlockVariant;
    public editorInitialTopPosition: string = '90px';
    public compactViewActive: boolean = false; // Display variants in shortened view. Good for reordering building blocks through drag & drop.
    public viewOnlyCountedBlocksForCountingReportPdfPages: boolean = false; // Display only those building blocks which are counted when counting report PDF pages.

    public selectedExampleReport: Report | null;
    public selectedReportPlaceholderValues: PlaceholderValues | null; // Cache placeholder values of the selected report as computationally intensive operation.

    public selectedExampleInvoice: Invoice | null;
    public selectedInvoicePlaceholderValues: PlaceholderValues | null; // Cache placeholder values of the selected report as computationally intensive operation.

    public selectedExampleDataVariantConditionsEvaluationResults: { [variantId: string]: boolean } = {}; // Cache the results of the conditions evaluation for the selected example data.

    public exampleDataActive: boolean = false; // Display example data selection in the building block editor.
    public exampleDataPinned: boolean = false; // Whether the example data selection is pinned to the top.

    public selectedDocumentOrderConfig: DocumentOrderConfig;
    public documentOrderConfigs: DocumentOrderConfig[] = [];
    // Load the default document type groups very fast to ensure the view is created quickly.
    public documentTypeGroups: DocumentTypeGroup[] = [];
    // Contains an array of document types associated with the given document building block ID. Required for the tag list.
    public connectedDocumentOrderConfigIds = new Map<DocumentBuildingBlock['_id'], DocumentOrderConfig['_id'][]>();

    private defaultDocumentOrderConfigsCache: DocumentOrderConfig[];
    private defaultDocumentBuildingBlocksCache: DocumentBuildingBlock[];

    // These are the document building blocks for which DOCX partials exist. This list must be equal to the list of partial files in the backend.
    public enhancedDocumentBuildingBlocks = enhancedDocumentBuildingBlocks;

    // Building Block Config
    public buildingBlockForConfig: DocumentBuildingBlock;

    // Custom Fields
    private fieldGroupConfigs: FieldGroupConfig[] = [];

    protected placeholderValueTree: PlaceholderValueTree;

    // If there is an invalid condition, only tell the user once on this page (because evaluateConditionGroupWithPlaceholderValues is called multiple times from the template).
    private invalidPlaceholderNameInConditionWarningWasShown = false;

    /*****************************************************************************
     /  Initialization
     /****************************************************************************/
    async ngOnInit() {
        scrollToTheTop();

        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        this.loadFieldGroupConfigsAndPlaceholderValueTree();
        this.registerCustomFieldChangeListener();

        await this.loadDocumentOrders();

        /**
         * When there is a problem with a document building block while rendering a document, the user is sent here to edit the document building block. To not make him search manually,
         * the placeholder name is passed via query parameter. As soon as the document building blocks are loaded, filterAndSortBuildingBlocks() will be executed.
         */
        const params = this.route.snapshot.queryParams;
        this.searchTerm = params['search'] || '';
        // In this case, the user wants to focus on the building blocks of the given document.
        if (params['documentType']) {
            this.userPreferences.showOnlyDocumentBuildingBlocksOfSelectedDocument = true;
        }
        const documentType: DocumentTemplateType = params['documentType'] || 'liability';
        const documentConfigOrderId: string = params['documentConfigOrderId'];
        const documentOrderConfig = this.documentOrderConfigs.find((documentOrderConfig) => {
            if (documentConfigOrderId) {
                return documentOrderConfig._id === documentConfigOrderId;
            } else {
                return documentOrderConfig.type === documentType;
            }
        });
        this.initializeConnectedDocumentOrderConfigIds();
        this.selectDocumentOrderConfig(documentOrderConfig);

        this.screenTitleService.setScreenTitle({ screenTitle: 'Textbausteine' });

        void this.initializePreviewSettings();
    }

    private async loadDocumentOrders(): Promise<void> {
        let buildingBlocks: DocumentBuildingBlock[];
        let documentOrderConfigs: DocumentOrderConfig[];
        try {
            [buildingBlocks, documentOrderConfigs] = await Promise.all([
                this.documentBuildingBlockService.find().toPromise(),
                this.documentOrderConfigService.find().toPromise(),
            ]);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Textbausteine nicht geladen',
                    body: 'Bist du online? Wenn ja, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        this.buildingBlocks = buildingBlocks;
        this.documentOrderConfigs = documentOrderConfigs;

        this.documentTypeGroups = getDocumentTypeGroupsFromDocumentOrderConfigs({ documentOrderConfigs });
    }
    private async loadFieldGroupConfigsAndPlaceholderValueTree() {
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        this.placeholderValueTree = getPlaceholderValueTree({
            fieldGroupConfigs: this.fieldGroupConfigs,
        });
    }

    /*****************************************************************************
     //  END Initialization
     /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Restore Defaults
    //****************************************************************************/
    /**
     * Required to reset the document order structure of an entire document.
     */
    private async getDefaultDocumentOrderConfigs(): Promise<DocumentOrderConfig[]> {
        if (this.defaultDocumentOrderConfigsCache) {
            // Always return a copy to avoid overwriting the default document order structure because a reference was passed accidentally.
            return JSON.parse(JSON.stringify(this.defaultDocumentOrderConfigsCache));
        }
        this.defaultDocumentOrderConfigsCache = await this.documentOrderConfigService.findDefault();

        // Always return a copy to avoid overwriting the default document template configs because a reference was passed accidentally.
        return JSON.parse(JSON.stringify(this.defaultDocumentOrderConfigsCache));
    }

    /**
     * Required to reset document building blocks or individual variants.
     */
    private async getDefaultDocumentBuildingBlocks(): Promise<DocumentBuildingBlock[]> {
        if (!this.defaultDocumentBuildingBlocksCache) {
            this.defaultDocumentBuildingBlocksCache = await this.documentBuildingBlockService.findDefault();
        }
        // Always return a copy to avoid overwriting the default document building blocks because a reference was passed accidentally.
        return JSON.parse(JSON.stringify(this.defaultDocumentBuildingBlocksCache));
    }

    public async resetDocumentBuildingBlockOrder(): Promise<void> {
        const documentNameGerman: string = translateDocumentOrderConfigName({
            documentOrderConfig: this.selectedDocumentOrderConfig,
        });

        const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
            data: {
                heading: `Standard-Ordnung des Dokuments "${documentNameGerman}" wiederherstellen?`,
                content: 'Sicher? Dieser Schritt kann nicht rückgängig gemacht werden.',
                confirmLabel: 'Ja, sicher!',
                confirmColorRed: true,
            },
        });

        const decision: boolean = await dialogRef.afterClosed().toPromise();
        if (!decision) {
            return;
        }

        let defaultDocumentOrderConfigs: DocumentOrderConfig[];
        try {
            defaultDocumentOrderConfigs = await this.getDefaultDocumentOrderConfigs();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    DOCUMENT_BUILDING_BLOCK_NOT_FOUND: (error) => ({
                        title: `Textbaustein "${error.data?.placeholder}" nicht gefunden`,
                        body: `Der Textbaustein "${error.data?.placeholder}" konnte nicht in die Standard-Reihenfolge eingefügt werden.<br><br>Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                    }),
                },
                defaultHandler: {
                    title: 'Standard-Textbaustein-Reihenfolge nicht abrufbar',
                    body: 'Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        const defaultDocumentOrderConfig = defaultDocumentOrderConfigs.find(
            (documentOrderConfig) => documentOrderConfig.type === this.selectedDocumentOrderConfig.type,
        );
        if (!defaultDocumentOrderConfig) {
            this.toastService.error(
                `Nicht zurücksetzbar`,
                `Das Dokument "${documentNameGerman}" kann nicht zurückgesetzt werden.<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
            );
            return;
        }

        // Replace everything except the ID, so that patching works.
        defaultDocumentOrderConfig._id = this.selectedDocumentOrderConfig._id;
        this.selectedDocumentOrderConfig = defaultDocumentOrderConfig;

        this.saveSelectedDocumentOrderConfig();
        this.toastService.success(`Standard-Ordnung der Textbausteine wiederhergestellt`);
    }

    public buildingBlockDiffersFromDefault(
        currentBlock: DocumentBuildingBlock,
        defaultBlock: DocumentBuildingBlock,
    ): boolean {
        if (!defaultBlock) {
            console.warn(
                `Block with placeholder "${currentBlock.placeholder}" does not have a default block. currentBlock: `,
                currentBlock,
            );
            return false;
        }

        return (
            this.hasChangedVariant(currentBlock, defaultBlock) || // Changed variants
            currentBlock.title !== defaultBlock.title ||
            currentBlock.isSectionHeading !== defaultBlock.isSectionHeading ||
            !!currentBlock.customPartialHash ||
            currentBlock.variants.length !== defaultBlock.variants.length ||
            currentBlock.conditionsOperator !== defaultBlock.conditionsOperator || // Changed conditions operator
            JSON.stringify(currentBlock.conditions) !== JSON.stringify(defaultBlock.conditions) // Changed conditions
        );
    }

    public async resetDocumentBuildingBlock(documentBuildingBlock): Promise<void> {
        const defaultDocumentBuildingBlocks = await this.getDefaultDocumentBuildingBlocks();

        const defaultDocumentBuildingBlock = defaultDocumentBuildingBlocks.find(
            (defaultDocumentBuildingBlock) =>
                defaultDocumentBuildingBlock.placeholder === documentBuildingBlock.placeholder,
        );
        Object.assign(documentBuildingBlock, {
            title: defaultDocumentBuildingBlock.title,
            variants: defaultDocumentBuildingBlock.variants,
            conditions: defaultDocumentBuildingBlock.conditions,
            conditionsOperator: defaultDocumentBuildingBlock.conditionsOperator,
            customPartialHash: null,
        } as DocumentBuildingBlock);
        await this.documentBuildingBlockService.put(documentBuildingBlock);

        this.toastService.success('Zurückgesetzt', 'Der Textbaustein wurde auf den autoiXpert-Standard zurückgesetzt.');
    }

    public async resetVariant(
        currentBlock: DocumentBuildingBlock,
        variant: DocumentBuildingBlockVariant,
    ): Promise<void> {
        const defaultDocumentBuildingBlocks = await this.getDefaultDocumentBuildingBlocks();
        const defaultDocumentBuildingBlock = defaultDocumentBuildingBlocks.find(
            (defaultBlock) => defaultBlock.placeholder === currentBlock.placeholder,
        );

        // See if the variant exists within the default document building block. If the variant does not exist, it's a user-added variant. That can't be automatically reset, of course.
        const defaultVariant = defaultDocumentBuildingBlock?.variants.find(
            (defaultVariant) => defaultVariant._id === variant._id,
        );
        if (!defaultVariant) {
            this.toastService.error(
                'Keine Standard-Variante',
                'Die Variante konnte nicht auf den autoiXpert-Standard zurückgesetzt werden, weil dies eine von dir oder deinem Team angelegte Variante ist.<br><br>Um den autoiXpert-Standard zurück zu erhalten, setze den kompletten Textbaustein zurück.',
            );
            return;
        }

        // Reset the variant. Use assign to keep the reference between the document building block and the variant intact.
        Object.assign(variant, defaultVariant);

        await this.documentBuildingBlockService.put(currentBlock);
        this.toastService.success('Zurückgesetzt', 'Die Variante wurde auf den autoiXpert-Standard zurückgesetzt.');
    }

    /**
     * Check if the contents of at least one variant or the order of variants differs from the default.
     *
     * Possible changes:
     * - changedByUser flag is true
     * - headings differ
     * - contents differ
     *
     * @param currentBlock
     * @param defaultBlock
     */
    public hasChangedVariant(currentBlock: DocumentBuildingBlock, defaultBlock: DocumentBuildingBlock): boolean {
        return currentBlock.variants.some((variant, index) => {
            const defaultVariant: DocumentBuildingBlockVariant = defaultBlock.variants[index];
            return (
                variant.changedByUser ||
                variant.conditionsOperator !== defaultVariant.conditionsOperator ||
                variant.heading !== defaultVariant.heading ||
                variant.content !== variant.content ||
                variant.isDefaultVariant !== defaultVariant.isDefaultVariant
            );
        });
    }

    private getChangedVariantIndexes(documentBuildingBlock: DocumentBuildingBlock): number[] {
        return documentBuildingBlock.variants
            .filter((variant) => variant.changedByUser)
            .map((variant) => documentBuildingBlock.variants.indexOf(variant));
    }

    public getResetBuildingBlockTooltip(documentBuildingBlock: DocumentBuildingBlock): string {
        const humanReadableVariantIndexes = this.getChangedVariantIndexes(documentBuildingBlock).map(
            (index) => `${index + 1}`,
        );

        if (humanReadableVariantIndexes.length === 1) {
            return `Die geänderte Variante ${joinList(humanReadableVariantIndexes)} wird zurückgesetzt.`;
        }
        return `Die geänderten Varianten ${joinList(humanReadableVariantIndexes)} werden zurückgesetzt.`;
    }

    public resetAllDocumentBuildingBlocks(): void {
        const documentNameGerman: string = translateDocumentOrderConfigName({
            documentOrderConfig: this.selectedDocumentOrderConfig,
        });

        const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
            data: {
                heading: 'Textbausteine zurücksetzen?',
                content: `Wirklich alle Bausteine des Dokuments "${documentNameGerman}" zurücksetzen?\n\nDieser Schritt kann nicht rückgängig gemacht werden.`,
                confirmLabel: 'Ja, sicher!',
                confirmColorRed: true,
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                const defaultDocumentBuildingBlocks = await this.getDefaultDocumentBuildingBlocks();

                // Ignore custom document building blocks and standard ones that have not been changed.
                const documentBuildingBlocksToBeReset: DocumentBuildingBlock[] =
                    this.getBuildingBlocksBelongingToDocument().filter((currentBuildingBlock) => {
                        const defaultBuildingBlock: DocumentBuildingBlock = defaultDocumentBuildingBlocks.find(
                            (defaultBlock) => defaultBlock.placeholder === currentBuildingBlock.placeholder,
                        );
                        return (
                            !currentBuildingBlock.custom &&
                            this.buildingBlockDiffersFromDefault(currentBuildingBlock, defaultBuildingBlock)
                        );
                    });

                try {
                    await Promise.all(
                        documentBuildingBlocksToBeReset.map((documentBuildingBlockToBeReset) => {
                            const defaultDocumentBuildingBlock = defaultDocumentBuildingBlocks.find(
                                (defaultDocumentBuildingBlock) =>
                                    defaultDocumentBuildingBlock.placeholder ===
                                    documentBuildingBlockToBeReset.placeholder,
                            );
                            Object.assign(documentBuildingBlockToBeReset, {
                                title: defaultDocumentBuildingBlock.title,
                                variants: defaultDocumentBuildingBlock.variants,
                                conditions: defaultDocumentBuildingBlock.conditions,
                                conditionsOperator: defaultDocumentBuildingBlock.conditionsOperator,
                                customPartialHash: null,
                            } as DocumentBuildingBlock);
                            return this.documentBuildingBlockService.put(documentBuildingBlockToBeReset);
                        }),
                    );
                    this.toastService.success(
                        `${documentBuildingBlocksToBeReset.length} Textbausteine zurückgesetzt`,
                        'Die Textbaustein entsprechen nun wieder dem autoiXpert-Standard.',
                    );
                } catch (error) {
                    this.toastService.error(
                        'Nicht zurückgesetzt',
                        'Die Textbausteine konnten nicht auf den autoiXpert-Standard zurückgesetzt werden.',
                    );
                }
            }
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Restore Defaults
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter + Sort
    //****************************************************************************/
    public activeCoverPage(): 'DeckblattOhneFoto' | 'DeckblattMitFoto' | null {
        // Get both document building blocks: with and without title page
        const withoutPhoto = this.buildingBlocks.find(
            (buildingBlock) => buildingBlock.placeholder === 'DeckblattOhneFoto',
        );
        const withPhoto = this.buildingBlocks.find((buildingBlock) => buildingBlock.placeholder === 'DeckblattMitFoto');

        if (this.selectedDocumentOrderConfig.documentBuildingBlockIds.includes(withPhoto._id)) {
            return 'DeckblattMitFoto';
        } else if (this.selectedDocumentOrderConfig.documentBuildingBlockIds.includes(withoutPhoto._id)) {
            return 'DeckblattOhneFoto';
        } else {
            return null;
        }
    }

    public toggleCoverPage(option: 'DeckblattOhneFoto' | 'DeckblattMitFoto'): void {
        const withoutPhoto = this.buildingBlocks.find(
            (buildingBlock) => buildingBlock.placeholder === 'DeckblattOhneFoto',
        );
        const withPhoto = this.buildingBlocks.find((buildingBlock) => buildingBlock.placeholder === 'DeckblattMitFoto');

        let replacement;
        let toBeReplaced;

        if (option === withPhoto.placeholder) {
            replacement = withPhoto;
            toBeReplaced = withoutPhoto;
        }
        if (option === withoutPhoto.placeholder) {
            replacement = withoutPhoto;
            toBeReplaced = withPhoto;
        }

        if (
            this.selectedDocumentOrderConfig.documentBuildingBlockIds.includes(replacement._id) &&
            this.selectedDocumentOrderConfig.documentBuildingBlockIds.includes(toBeReplaced._id)
        ) {
            const index = this.selectedDocumentOrderConfig.documentBuildingBlockIds.findIndex(
                (id) => id === toBeReplaced._id,
            );
            this.selectedDocumentOrderConfig.documentBuildingBlockIds.splice(index, 1);
            this.filterAndSortBuildingBlocks();
            this.saveSelectedDocumentOrderConfig();
            return;
        }
        if (this.selectedDocumentOrderConfig.documentBuildingBlockIds.includes(replacement._id)) {
            this.filterAndSortBuildingBlocks();
            this.saveSelectedDocumentOrderConfig();
            return;
        }
        if (this.selectedDocumentOrderConfig.documentBuildingBlockIds.includes(toBeReplaced._id)) {
            const index = this.selectedDocumentOrderConfig.documentBuildingBlockIds.findIndex(
                (id) => id === toBeReplaced._id,
            );
            this.selectedDocumentOrderConfig.documentBuildingBlockIds.splice(index, 1, replacement._id);
            this.filterAndSortBuildingBlocks();
            this.saveSelectedDocumentOrderConfig();
            return;
        } else {
            this.selectedDocumentOrderConfig.documentBuildingBlockIds.splice(0, 0, replacement._id);
            this.filterAndSortBuildingBlocks();
            this.saveSelectedDocumentOrderConfig();
        }
    }

    public filterAndSortBuildingBlocks(): void {
        this.filterBuildingBlocks();

        this.sortBuildingBlocks();
    }

    private sortBuildingBlocks(): void {
        if (this.userPreferences.showOnlyDocumentBuildingBlocksOfSelectedDocument) {
            if (!this.selectedDocumentOrderConfig) return;

            this.filteredBuildingBlocks.sort((buildingBlockA, buildingBlockB) => {
                const indexA: number = this.selectedDocumentOrderConfig.documentBuildingBlockIds.findIndex(
                    (documentBuildingBlockId) => documentBuildingBlockId === buildingBlockA._id,
                );
                const indexB: number = this.selectedDocumentOrderConfig.documentBuildingBlockIds.findIndex(
                    (documentBuildingBlockId) => documentBuildingBlockId === buildingBlockB._id,
                );

                if (indexA > indexB) return 1;
                if (indexA < indexB) return -1;
                return 0;
            });
        }
        // If no document is selected, sort alphabetically
        else {
            this.filteredBuildingBlocks.sort((buildingBlockA, buildingBlockB) => {
                const placeholderA: string = buildingBlockA.title;
                const placeholderB: string = buildingBlockB.title;

                return placeholderA.localeCompare(placeholderB);
            });
        }
    }

    private filterBuildingBlocks(): void {
        this.filteredBuildingBlocks = [...this.buildingBlocks];
        // Only include blocks referenced by the currently selected document order structure
        if (this.selectedDocumentOrderConfig && this.userPreferences.showOnlyDocumentBuildingBlocksOfSelectedDocument) {
            this.filteredBuildingBlocks = this.getBuildingBlocksBelongingToDocument(this.filteredBuildingBlocks);
        }

        if (this.viewOnlyCountedBlocksForCountingReportPdfPages) {
            this.filteredBuildingBlocks = this.filteredBuildingBlocks.filter(
                (buildingBlock) => !buildingBlock.config?.excludeFromReportPageCount,
            );
        }

        this.applySearchFilter();
    }

    private getBuildingBlocksBelongingToDocument(
        buildingBlocks: DocumentBuildingBlock[] = this.buildingBlocks,
    ): DocumentBuildingBlock[] {
        return buildingBlocks.filter(
            (documentBuildingBlock) =>
                this.selectedDocumentOrderConfig.documentBuildingBlockIds.findIndex(
                    (sortedDocumentBuildingBlockId) => sortedDocumentBuildingBlockId === documentBuildingBlock._id,
                ) > -1,
        );
    }

    private applySearchFilter(): void {
        if (!this.searchTerm) {
            return;
        }

        const searchTerms = this.searchTerm.toLowerCase().split(' ');

        this.filteredBuildingBlocks = this.filteredBuildingBlocks.filter((buildingBlock) => {
            const propertiesToBeSearched: string[] = [
                buildingBlock.title,
                buildingBlock.variants.reduce((acc, variant) => {
                    // */* shall prevent that the end of the previous content and the current heading form a search match.
                    return acc + `*/* ${variant.heading} ${variant.content}`;
                }, ''),
                buildingBlock.placeholder,
            ];
            return searchTerms.every((searchTerm) => {
                return propertiesToBeSearched.some(
                    (propertyToBeSearched) =>
                        propertyToBeSearched && propertyToBeSearched.toLowerCase().includes(searchTerm),
                );
            });
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter + Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Block Creation & Deletion
    //****************************************************************************/
    public createDocumentBuildingBlock(): void {
        const newBlock = new DocumentBuildingBlock({
            title: 'Neuer Textbaustein',
            custom: true,
            createdBy: this.user._id,
            teamId: this.user.teamId,
            variants: [new DocumentBuildingBlockVariant()],
        });

        this.buildingBlocks.push(newBlock);

        // Activate edit mode so that the user may set a title right away
        this.enterBlockTitleEditMode(newBlock);

        // Persist building block on the server
        this.createDocumentBuildingBlockOnServer(newBlock);

        // Add reference of the block to the currently selected document.
        this.selectedDocumentOrderConfig.documentBuildingBlockIds.unshift(newBlock._id);

        this.saveSelectedDocumentOrderConfig();

        // Update the map that knows which building block belongs to which documents.
        this.initializeConnectedDocumentOrderConfigIds();
    }

    public removeDocumentBuildingBlock(buildingBlock: DocumentBuildingBlock): void {
        // Remove building block from the server
        this.removeDocumentBuildingBlockFromServer(buildingBlock);

        // Remove building block locally
        const buildingBlockIndex: number = this.buildingBlocks.indexOf(buildingBlock);
        this.buildingBlocks.splice(buildingBlockIndex, 1);

        const removedDocumentOrderMetaElements: {
            documentType: string;
            documentBuildingBlockId: DocumentBuildingBlock['_id'];
            documentBuildingBlockIdIndex: number;
        }[] = [];

        // Remove reference of the block from all documents.
        for (const documentOrderConfig of this.documentOrderConfigs) {
            const existingBuildingBlockId: DocumentBuildingBlock['_id'] =
                documentOrderConfig.documentBuildingBlockIds.find(
                    (documentBuildingBlockId) => documentBuildingBlockId === buildingBlock._id,
                );

            if (existingBuildingBlockId) {
                const existingBuildingBlockIdIndex =
                    documentOrderConfig.documentBuildingBlockIds.indexOf(existingBuildingBlockId);
                documentOrderConfig.documentBuildingBlockIds.splice(existingBuildingBlockIdIndex, 1);

                removedDocumentOrderMetaElements.push({
                    documentType: documentOrderConfig.type,
                    documentBuildingBlockId: existingBuildingBlockId,
                    documentBuildingBlockIdIndex: existingBuildingBlockIdIndex,
                });
                this.documentOrderConfigService.put(documentOrderConfig);
            }
        }

        this.filterAndSortBuildingBlocks();

        // Give the user the chance to restore the block
        const toast = this.toastService.info('Textbaustein gelöscht', 'Klicken zum Wiederherstellen', {
            showProgressBar: true,
            timeOut: 10000,
        }); //RestorationToast
        toast.click.subscribe(() => {
            // Re-create building block (server + local)
            this.createDocumentBuildingBlockOnServer(buildingBlock);
            this.buildingBlocks.splice(buildingBlockIndex, 0, buildingBlock);

            // Re-create references in the document template config.
            for (const removedDocumentOrderMetaElement of removedDocumentOrderMetaElements) {
                const documentOrderConfig = this.documentOrderConfigs.find(
                    (documentOrderConfig) => documentOrderConfig.type === removedDocumentOrderMetaElement.documentType,
                );
                documentOrderConfig.documentBuildingBlockIds.splice(
                    removedDocumentOrderMetaElement.documentBuildingBlockIdIndex,
                    0,
                    removedDocumentOrderMetaElement.documentBuildingBlockId,
                );
                this.documentOrderConfigService.put(documentOrderConfig);
            }

            // Update the filtered building blocks
            this.filterAndSortBuildingBlocks();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Block Creation & Deletion
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Block Title
    //****************************************************************************/
    public enterBlockTitleEditMode(buildingBlock: DocumentBuildingBlock): void {
        this.blockTitlesInEditMode.set(buildingBlock, true);
        this.newBlockTitlesInEditMode.set(buildingBlock._id, buildingBlock.title);
    }

    public leaveBlockTitleEditMode(buildingBlock: DocumentBuildingBlock): void {
        this.blockTitlesInEditMode.delete(buildingBlock);
        this.newBlockTitlesInEditMode.delete(buildingBlock._id);
    }

    public confirmBlockTitleChangeModeOnEnter(event: KeyboardEvent, buildingBlock: DocumentBuildingBlock): void {
        if (event.key === 'Enter') {
            this.confirmBuildingBlockTitleChange(buildingBlock);
        }
    }

    /**
     * User confirmed the new building block title and wants to save it.
     */
    protected confirmBuildingBlockTitleChange(buildingBlock: DocumentBuildingBlock): void {
        const newTitle = this.newBlockTitlesInEditMode.get(buildingBlock._id);
        if (!this.isBuildingBlockTitleValid(newTitle, buildingBlock._id)) {
            return;
        }

        buildingBlock.title = newTitle;
        this.saveDocumentBuildingBlock(buildingBlock);
        this.leaveBlockTitleEditMode(buildingBlock);
    }

    /**
     * Check if the given building block title is already used by another building block. This function actually
     * checks if the resulting "placeholder" (derived from the title for custom building blocks) might collide
     * with other building blocks.
     */
    protected isBlockTitleAlreadyTaken(newTitle: string, buildingBlockId: DocumentBuildingBlock['_id']): boolean {
        return !!this.buildingBlocks.find(
            (buildingBlock) =>
                getDocumentBuildingBlockPlaceholder(buildingBlock) ===
                    getDocumentBuildingBlockPlaceholder({
                        title: newTitle,
                        placeholder: null,
                    }) && buildingBlock._id !== buildingBlockId,
        );
    }

    /**
     * Checks if the given building block title is valid, meaning that it is not already taken by another building block
     * and it is not empty.
     */
    protected isBuildingBlockTitleValid(newTitle: string, buildingBlockId: DocumentBuildingBlock['_id']): boolean {
        return !this.isBlockTitleAlreadyTaken(newTitle, buildingBlockId) && newTitle !== '';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Block Title
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tags
    //****************************************************************************/
    public getConnectedDocumentOrderConfigIds(blockId: DocumentBuildingBlock['_id']): DocumentOrderConfig['_id'][] {
        return this.connectedDocumentOrderConfigIds.get(blockId) || [];
    }

    public initializeConnectedDocumentOrderConfigIds(): void {
        for (const buildingBlock of this.buildingBlocks) {
            const connectedDocumentOrderConfigIds: DocumentOrderConfig['_id'][] = [];

            for (const documentOrderConfig of this.documentOrderConfigs) {
                if (documentOrderConfig.documentBuildingBlockIds.includes(buildingBlock._id)) {
                    connectedDocumentOrderConfigIds.push(documentOrderConfig._id);
                }
            }
            this.connectedDocumentOrderConfigIds.set(buildingBlock._id, connectedDocumentOrderConfigIds);
        }
    }

    public handleDocumentTypeChanges(
        buildingBlockId: DocumentBuildingBlock['_id'],
        documentTypeChanges: DocumentOrderConfigAssociationChange[],
    ) {
        for (const documentTypeChange of documentTypeChanges) {
            const changedDocumentOrderConfig: DocumentOrderConfig = findRecordById(
                this.documentOrderConfigs,
                documentTypeChange.documentOrderConfig._id,
            );
            if (documentTypeChange.change === 'added') {
                changedDocumentOrderConfig.documentBuildingBlockIds.unshift(buildingBlockId);
            } else {
                removeFromArray(buildingBlockId, changedDocumentOrderConfig.documentBuildingBlockIds);
            }

            this.documentOrderConfigService.put(changedDocumentOrderConfig);
        }

        this.filterAndSortBuildingBlocks();
        // Update the cached associated document types because they likely changed.
        this.initializeConnectedDocumentOrderConfigIds();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tags
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Enhanced Document Building Blocks
    //****************************************************************************/
    public openEnhancedDocumentBuildingBlockHelp(documentBuildingBlock: DocumentBuildingBlock): void {
        openEnhancedDocumentBuildingBlockHelp(documentBuildingBlock.placeholder);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Enhanced Document Building Blocks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Building Block Configuration
    //****************************************************************************/
    public openBuildingBlockConfiguration(block: DocumentBuildingBlock): void {
        this.buildingBlockForConfig = block;
    }

    public hideBuildingBlockConfiguration(): void {
        this.buildingBlockForConfig = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Building Block Configuration
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Building Block Editor
    //****************************************************************************/
    public openBuildingBlockEditor(buildingBlock: DocumentBuildingBlock) {
        this.currentlyEditedBuildingBlock = buildingBlock;
        this.documentBuildingBlockEditorShown = true;
    }

    public closeBuildingBlockEditor() {
        this.documentBuildingBlockEditorShown = false;
    }

    public closeVariantEditorAndOpenBuildingBlockEditor() {
        this.closeDocumentBlockVariantEditor();
        setTimeout(() => this.openBuildingBlockEditor(this.currentlyEditedBuildingBlock), 200);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Building Block Editor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Order Config Creation Dialog
    //****************************************************************************/
    public openDocumentOrderConfigCreationDialog(
        documentTypeGroupName: DocumentTypeGroupName,
        configToEdit: DocumentOrderConfig = null,
    ) {
        if (configToEdit) {
            this.documentOrderConfigCreationDialogConfigToEdit = configToEdit;
        }

        this.documentOrderConfigCreationDialogShownWithType = documentTypeGroupName as Subtype<
            DocumentTypeGroupName,
            'reportAttachment' | 'signature'
        >;
    }

    /**
     * Close the dialog for creating and editing custom document order configs.
     */
    protected closeDocumentOrderConfigCreationDialog() {
        this.documentOrderConfigCreationDialogShownWithType = null;
        this.documentOrderConfigCreationDialogConfigToEdit = null;
    }

    /**
     * User created a new custom document config -> save it and reload data on page.
     */
    protected async handleDocumentOrderConfigCreated(newDocumentOrderConfig: DocumentOrderConfig) {
        if (this.documentOrderConfigCreationDialogConfigToEdit) {
            // Patch an existing config
            await this.documentOrderConfigService.put(newDocumentOrderConfig, { waitForServer: true });

            // Reload the order configs, so that the name of the order config is updated if changed
            await this.loadDocumentOrders();

            // Reload selected order config (because name might have changed)
            this.selectedDocumentOrderConfig = this.documentOrderConfigs.find(
                (config) => config._id === this.selectedDocumentOrderConfig._id,
            );
        } else {
            // Create a new config
            await this.documentOrderConfigService.create(newDocumentOrderConfig, { waitForServer: true });

            // Reload the order configs, so that the new config is shown in the list
            await this.loadDocumentOrders();

            // Jump to the newly created document order config
            this.selectDocumentOrderConfig(
                this.documentOrderConfigs.find((config) => config._id === newDocumentOrderConfig._id),
            );
        }

        // Ensure that the document is added to the default document orders
        this.addDocumentToTeamDefaultFullDocumentConfig(newDocumentOrderConfig);
    }

    //*****************************************************************************
    //  Team Default Document Orders
    //****************************************************************************/

    /**
     * Adds a given document to all full document configs of a team.
     */
    public addDocumentToTeamDefaultFullDocumentConfig(documentOrderConfig: DocumentOrderConfig) {
        for (const defaultDocumentOrderGroup of this.team.preferences.defaultDocumentOrderGroups) {
            for (const defaultDocumentOrder of defaultDocumentOrderGroup.documentOrders) {
                const existingDefaultDocumentOrderItem = defaultDocumentOrder.items.find(
                    (defaultDocumentOrderItem) =>
                        defaultDocumentOrderItem.documentType === documentOrderConfig.type &&
                        // Custom documents are not unique by their type ("customDocument") but by their customDocumentOrderConfigId.
                        defaultDocumentOrderItem.customDocumentOrderConfigId === documentOrderConfig._id,
                );
                if (!existingDefaultDocumentOrderItem) {
                    defaultDocumentOrder.items.push(
                        new DefaultDocumentOrderItem({
                            documentType: 'customDocument',
                            customDocumentOrderConfigId: documentOrderConfig._id,
                        }),
                    );
                }
            }
        }

        console.log(`Added "${documentOrderConfig.type}" to team's default document orders.`);
        void this.saveTeam();
    }

    /**
     * Removes a given document from all full document configs of a team.
     */
    public removeDocumentFromTeamDefaultFullDocumentConfig(documentOrderConfig: DocumentOrderConfig) {
        for (const defaultDocumentOrderGroup of this.team.preferences.defaultDocumentOrderGroups) {
            for (const defaultDocumentOrder of defaultDocumentOrderGroup.documentOrders) {
                const existingDefaultDocumentOrderItem = defaultDocumentOrder.items.find(
                    (defaultDocumentOrderItem) =>
                        defaultDocumentOrderItem.documentType === documentOrderConfig.type &&
                        // Custom documents are not unique by their type ("customDocument") but by their customDocumentOrderConfigId.
                        defaultDocumentOrderItem.customDocumentOrderConfigId === documentOrderConfig._id,
                );
                if (existingDefaultDocumentOrderItem) {
                    removeFromArray(existingDefaultDocumentOrderItem, defaultDocumentOrder.items);
                }
            }
        }

        console.log(`Removed "${documentOrderConfig.type}" from team's default document orders.`);
        void this.saveTeam();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team Default Document Orders
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Delete the given custom document order config.
     * - Removes the config from the collection documentorderconfigs
     * - Checks in which reports the document has been used and displays them to the user
     * - If deletion was confirmed -> delete the document from all reports (report.documents)
     * - Currently the removed document will not be removed from the report.documentOrders
     * - Remove it from the team.defaultDocumentOrderGroups
     */
    protected async deleteDocumentOrderConfig(documentOrderConfig: DocumentOrderConfig): Promise<void> {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Es kann nicht festgestellt werden, ob Dokumentvorlagen bereits verwendet werden. Bitte versuche es mit einer aktiven Internetverbindung noch einmal.',
            );
            return;
        }

        // Check if used in reports and if so, delete them from the report.documents
        const reportIdsAndTokensUsingDocument = await this.getReportIdsAndTokensUsingTemplate(documentOrderConfig);
        if (reportIdsAndTokensUsingDocument.length > 0) {
            // Only show the first ten to prevent overloading the screen.
            const listElements: string[] = reportIdsAndTokensUsingDocument.slice(0, 10).map((reportIdAndToken) => {
                return `<li><a href="/Gutachten/${reportIdAndToken._id}/Druck-und-Versand" target="_blank">${
                    reportIdAndToken.token || '[Kein Aktenzeichen]'
                }</a></li>`;
            });
            if (reportIdsAndTokensUsingDocument.length > 10) {
                listElements.push('<li>...</li>');
            }
            const reportTokenListHumanReadable: string = `<ul>${listElements.join('')}</ul>`;
            const deleteUsedDocumentAnyway = await this.dialogService
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Dokumentvorlage wird verwendet',
                        content: `Das Dokument sollte nicht gelöscht werden, weil es in ${reportIdsAndTokensUsingDocument.length} Gutachten noch verwendet wird. Löscht du das Dokument trotzdem, wird es aus den genannten Gutachten entfernt.<br><br>Die Aktenzeichen lauten:${reportTokenListHumanReadable}`,
                        textAlign: 'left',
                        confirmLabel: 'Trotzdem löschen',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();
            if (deleteUsedDocumentAnyway) {
                // Remove the custom document from all reports
                const result = await this.documentOrderConfigService.removeCustomDocumentOrderConfigFromAllReports(
                    documentOrderConfig._id,
                );

                this.toastService.success(
                    `Eigenes Dokument aus allen Gutachten entfernt`,
                    `Das Dokument "${documentOrderConfig.titleLong}" wurde aus ${
                        result.numberOfReportsFromWhichDocumentOrderConfigWasRemoved
                    } Gutachten entfernt.`,
                );
            } else {
                return;
            }
        }

        // Delete the document order config itself
        await this.documentOrderConfigService.delete(documentOrderConfig._id, { waitForServer: true });

        if (documentOrderConfig.customDocumentConfig.documentTypeGroup === 'signature') {
            // And also remove it from the "remembered" list of documents to be signed in a new report (if it's a signable document)
            removeFromArray(`customDocument-${documentOrderConfig._id}`, this.team.preferences.documentsToBeSigned);
            await this.saveTeam();
        }

        // Remove from all default document orders
        this.removeDocumentFromTeamDefaultFullDocumentConfig(documentOrderConfig);
        await this.saveTeam();

        if (documentOrderConfig._id === this.selectedDocumentOrderConfig._id) {
            // If the currently selected config got deleted -> select another config
            this.selectDocumentOrderConfig(this.documentOrderConfigs[0]);
        }

        // Reload page data so that deleted custom order config is no longer shown
        void this.loadDocumentOrders();
    }

    /**
     * Get all IDs and tokens from reports that currently contain a document based on the given
     * DocumentOrderConfig.
     */
    private async getReportIdsAndTokensUsingTemplate(
        documentOrderConfig: DocumentOrderConfig,
    ): Promise<Pick<Report, '_id' | 'token'>[]> {
        /**
         * Use the HTTP client instead of the ReportService since the report service always syncs the entire report object. This can be way more lightweight, though.
         * Also, the probability is low that reports are cached locally for this query.
         */
        return await this.httpClient
            .get<Pick<Report, '_id' | 'token'>[]>('/api/v0/reports', {
                params: {
                    'documents.customDocumentOrderConfigId': documentOrderConfig._id,
                    $select: ['_id', 'token'],
                },
            })
            .toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Order Config Creation Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Variants
    //****************************************************************************/
    public openDocumentBlockVariantEditor(
        buildingBlock: DocumentBuildingBlock,
        variant: DocumentBuildingBlockVariant,
    ): void {
        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.warn(
                'Fehlende Zugriffsrechte',
                'Dir fehlt das Zugriffsrecht zur Bearbeitung der Textbausteine. Dein Admin muss dich dafür für das Recht "Text bearbeiten" freischalten.',
            );
            return;
        }

        // Remember what building block and variant were passed to the editor
        this.currentlyEditedBuildingBlock = buildingBlock;
        this.currentlyEditedVariant = variant;
        this.documentBuildingBlockVariantEditorShown = true;

        const scrollableElement = document.querySelector('#router-outlet-container');
        this.editorInitialTopPosition = scrollableElement.scrollTop + 70 + 'px';
    }

    public processVariantEditorChangeEvent(newVariant: DocumentBuildingBlockVariant): void {
        Object.assign(this.currentlyEditedVariant, newVariant);
        this.saveDocumentBuildingBlock(this.currentlyEditedBuildingBlock);
    }

    public processConditionsEditorChangeEvent(newBuildingBlock: DocumentBuildingBlock): void {
        Object.assign(this.currentlyEditedBuildingBlock, newBuildingBlock);
        this.saveDocumentBuildingBlock(this.currentlyEditedBuildingBlock);
    }

    public closeDocumentBlockVariantEditor(): void {
        this.documentBuildingBlockVariantEditorShown = false;
    }

    public addVariant(buildingBlock: DocumentBuildingBlock): void {
        const newVariant = new DocumentBuildingBlockVariant({
            changedByUser: true,
        });

        buildingBlock.variants.unshift(newVariant);
        this.saveDocumentBuildingBlock(buildingBlock);
    }

    public copyVariant(buildingBlock: DocumentBuildingBlock, variant: DocumentBuildingBlockVariant): void {
        const newVariant: DocumentBuildingBlockVariant = JSON.parse(JSON.stringify(variant));
        newVariant._id = generateId();
        newVariant.isDefaultVariant = false;
        newVariant.conditions.map((condition) => duplicateDocumentBuildingBlockCondition(condition));
        const indexOfExistingVariant = buildingBlock.variants.indexOf(variant);

        buildingBlock.variants.splice(indexOfExistingVariant, 0, newVariant);
        this.saveDocumentBuildingBlock(buildingBlock);
    }

    public removeVariant(buildingBlock: DocumentBuildingBlock, variant: DocumentBuildingBlockVariant): void {
        const index = buildingBlock.variants.indexOf(variant);
        buildingBlock.variants.splice(index, 1);

        const toast = this.toastService.info('Variante gelöscht', 'Klicken, um sie wiederherzustellen.', {
            showProgressBar: true,
            timeOut: 10000,
        }); //RestorationToast
        toast.click.subscribe(() => {
            buildingBlock.variants.splice(index, 0, variant);
            this.saveDocumentBuildingBlock(buildingBlock);
        });
    }

    public async toggleIsDefaultVariant(
        buildingBlock: DocumentBuildingBlock,
        variant: DocumentBuildingBlockVariant,
    ): Promise<void> {
        buildingBlock.variants.forEach((v) => {
            if (v._id === variant._id) {
                v.isDefaultVariant = !v.isDefaultVariant;
            } else {
                v.isDefaultVariant = false;
            }
        });
        this.saveDocumentBuildingBlock(buildingBlock);
    }

    public reorderVariantsArray(buildingBlock: DocumentBuildingBlock, event: CdkDragDrop<unknown>): void {
        const variant: DocumentBuildingBlockVariant = buildingBlock.variants.splice(event.previousIndex, 1)[0];
        buildingBlock.variants.splice(event.currentIndex, 0, variant);
        this.saveDocumentBuildingBlock(buildingBlock);
    }

    public toggleCompactView(): void {
        this.compactViewActive = !this.compactViewActive;
    }

    public toggleViewOnlyBlocksForCountingReportPdfPages(): void {
        this.viewOnlyCountedBlocksForCountingReportPdfPages = !this.viewOnlyCountedBlocksForCountingReportPdfPages;
    }

    public toggleExampleDataActive(): void {
        this.exampleDataActive = !this.exampleDataActive;
        this.userPreferences.documentBuildingBlockPreviewActive = this.exampleDataActive;
        this.evaluateAllBuildingBlocksVariantConditionsWithPlaceholderValues();
    }

    public toggleExampleDataPinned(): void {
        this.exampleDataPinned = !this.exampleDataPinned;
        this.userPreferences.documentBuildingBlockPreviewPinned = this.exampleDataPinned;
    }

    private async initializePreviewSettings(): Promise<void> {
        const previewActive = this.userPreferences.documentBuildingBlockPreviewActive;
        if (previewActive !== undefined && previewActive !== null) {
            this.exampleDataActive = previewActive;
        }

        const previewPinned = this.userPreferences.documentBuildingBlockPreviewPinned;
        if (previewPinned !== undefined && previewPinned !== null) {
            this.exampleDataPinned = previewPinned;
        }

        const previewReportId = this.userPreferences.documentBuildingBlockPreviewReportId;
        if (previewReportId) {
            const report = await this.reportService.get(previewReportId);
            if (report) {
                this.handleExampleReportSelected(report);
            }
        }

        const previewInvoiceId = this.userPreferences.documentBuildingBlockPreviewInvoiceId;
        if (previewInvoiceId) {
            const invoice = await this.invoiceService.get(previewInvoiceId);
            if (invoice) {
                this.handleExampleInvoiceSelected(invoice);
            }
        }
    }

    public handleExampleReportSelected(report: Report | null) {
        if (!report) {
            this.selectedExampleReport = null;
            this.userPreferences.documentBuildingBlockPreviewReportId = null;

            if (!this.selectedExampleInvoice) {
                this.selectedReportPlaceholderValues = null;
                this.selectedExampleDataVariantConditionsEvaluationResults = {};
            }

            this.evaluateAllBuildingBlocksVariantConditionsWithPlaceholderValues();
            return;
        }

        this.selectedExampleReport = report;
        this.userPreferences.documentBuildingBlockPreviewReportId = report._id;

        // Select invoice
        this.invoiceService
            .find({ reportIds: report._id })
            .pipe(first())
            .subscribe((invoices) => {
                // Sort invoices by date DESC
                invoices.sort(
                    (invoice1, invoice2) => new Date(invoice2.date).getTime() - new Date(invoice1.date).getTime(),
                );

                if (invoices.length > 0) {
                    this.handleExampleInvoiceSelected(invoices[0]);
                }
            });

        void this.templatePlaceholderValuesService
            .getReportValues({
                reportId: report._id,
                letterDocument: new DocumentMetadata({
                    type: 'letter',
                    invoiceId: this.selectedExampleInvoice?._id,
                    recipientRole: 'invoiceRecipient',
                    createdBy: this.user._id,
                }),
            })
            .then((placeholderValues) => {
                this.selectedReportPlaceholderValues = placeholderValues;
                this.evaluateAllBuildingBlocksVariantConditionsWithPlaceholderValues();
            })
            .catch((error) => {
                console.error(`Error while fetching report values for report ${report._id}`, error);
            });
    }

    private getInvoiceLetterDocument(invoice: Invoice): DocumentMetadata | undefined {
        // The following selected document types require the invoice to be attached to a report.
        if (
            [
                'declarationOfAssignment',
                'consentDataProtection',
                'revocationInstruction',
                'powerOfAttorney',
                'customResidualValueBidList',
            ].includes(this.selectedDocumentOrderConfig.type) &&
            !(invoice?.reportIds?.length > 0)
        ) {
            return undefined;
        }

        return new DocumentMetadata({
            type: 'letter',
            invoiceId: this.selectedExampleInvoice?._id,
            recipientRole: 'invoiceRecipient',
            createdBy: this.user._id,
        });
    }

    public handleExampleInvoiceSelected(invoice: Invoice | null) {
        if (!invoice) {
            this.selectedExampleInvoice = null;
            this.userPreferences.documentBuildingBlockPreviewInvoiceId = null;

            if (!this.selectedExampleReport) {
                this.selectedReportPlaceholderValues = null;
                this.selectedInvoicePlaceholderValues = null;
            }

            this.evaluateAllBuildingBlocksVariantConditionsWithPlaceholderValues();
            return;
        }

        this.selectedExampleInvoice = invoice;
        this.userPreferences.documentBuildingBlockPreviewInvoiceId = invoice._id;

        void this.templatePlaceholderValuesService
            .getInvoiceValues({
                invoiceId: invoice._id,
                letterDocument: this.getInvoiceLetterDocument(invoice),
                report: this.selectedExampleReport,
            })
            .then((placeholderValues) => {
                this.selectedReportPlaceholderValues = {
                    ...this.selectedReportPlaceholderValues,
                    ...placeholderValues,
                };
                this.selectedInvoicePlaceholderValues = placeholderValues;
                this.evaluateAllBuildingBlocksVariantConditionsWithPlaceholderValues();
            })
            .catch((error) => {
                console.error(`Error while fetching invoice values for invoice ${invoice._id}`, error);
            });
    }

    public getVariantHeading(variant: DocumentBuildingBlockVariant) {
        if (this.exampleDataActive && this.selectedReportPlaceholderValues) {
            return replacePlaceholders({
                placeholderValues: this.selectedReportPlaceholderValues,
                placeholderValueTree: this.placeholderValueTree,
                textWithPlaceholders: variant.heading,
                isHtmlAllowed: false,
            });
        }

        return variant.heading;
    }

    public getVariantContent(variant: DocumentBuildingBlockVariant) {
        if (this.exampleDataActive && this.selectedReportPlaceholderValues) {
            return replacePlaceholders({
                placeholderValues: this.selectedReportPlaceholderValues,
                placeholderValueTree: this.placeholderValueTree,
                textWithPlaceholders: variant.content,
                isHtmlAllowed: true,
            });
        }

        return variant.content;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Variants
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Conditions
    //****************************************************************************/
    /**
     * We need to evaluate the variant conditions in this component (instead of the conditions preview) due to the default variant mechanism.
     * The preview whether a default variant is truthy (green) or falsy (red) is dependent on the evaluation results of the other variants.
     * Therefore, we need the results of the other variants to evaluate the default variant.
     */
    private evaluateAllBuildingBlocksVariantConditionsWithPlaceholderValues(): void {
        if (!this.exampleDataActive || (!this.selectedExampleReport && !this.selectedExampleInvoice)) {
            this.selectedExampleDataVariantConditionsEvaluationResults = {};
            return;
        }

        this.buildingBlocks.forEach((buildingBlock) =>
            this.evaluateBuildingBlocksVariantConditionsWithPlaceholderValues(buildingBlock),
        );
    }

    private evaluateBuildingBlocksVariantConditionsWithPlaceholderValues(buildingBlock: DocumentBuildingBlock): void {
        if (!this.exampleDataActive || (!this.selectedExampleReport && !this.selectedExampleInvoice)) return;

        buildingBlock.variants
            .filter((variant) => !variant.isDefaultVariant)
            .forEach((variant) => {
                let result = false;
                try {
                    result = isBuildingBlockConditionMet({
                        condition: variant,
                        placeholderValues: this.selectedReportPlaceholderValues,
                        placeholderValueTree: this.placeholderValueTree,
                    });
                } catch (error) {
                    if (error.code === 'DOCUMENT_BUILDING_BLOCK_PLACEHOLDER_VALUE_TYPE_NOT_DEFINED') {
                        // There is an invalid placeholder value type, which often happens, if the user deleted a custom field config but that field is still being used in a condition
                        if (!this.invalidPlaceholderNameInConditionWarningWasShown) {
                            this.toastService.warn(
                                'Ungültiger Platzhalter',
                                `Eine Bedingung des Textbausteins "${buildingBlock.title}" verwendet einen ungültigen Platzhalter. Das passiert zum Beispiel, wenn ein eigenes Feld gelöscht wurde, aber noch in einer Bedingung verwendet wird.`,
                                { timeOut: 0 },
                            );

                            this.invalidPlaceholderNameInConditionWarningWasShown = true;
                        }
                    } else {
                        // Only handle the invalid placeholder value type error, because that one can be solved by the user. All other errors should be rethrown and reported to sentry
                        throw error;
                    }
                }

                this.selectedExampleDataVariantConditionsEvaluationResults[variant._id] = result;
            });

        // Determine the default variant evaluation result
        const defaultVariant = buildingBlock.variants.find((variant) => variant.isDefaultVariant);
        if (defaultVariant) {
            const allOtherVariantsAreFalse: boolean = buildingBlock.variants
                .filter((variant) => !variant.isDefaultVariant)
                .every((variant) => !this.selectedExampleDataVariantConditionsEvaluationResults[variant._id]);
            this.selectedExampleDataVariantConditionsEvaluationResults[defaultVariant._id] = allOtherVariantsAreFalse;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Conditions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Order Structure
    //****************************************************************************/
    public scrollIntoView(querySelector: string, documentBuildingBlockId: DocumentBuildingBlock['_id']): void {
        const selectedElement = document.querySelector(querySelector);

        if (!selectedElement) {
            // Maybe just hidden by search query? -> Check if the missing element exists in the building block collection
            if (this.buildingBlocks.find((buildingBlock) => buildingBlock._id === documentBuildingBlockId)) {
                this.toastService.info(
                    'Durch Filter ausgeblendet',
                    'Das Element scheint durch den Suchfilter ausgeblendet zu sein.',
                );
            }
            // If the element does not have a corresponding building block, abort.
            return;
        } else {
            selectedElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }

    public translateDocumentTypeGroup = translateDocumentTypeGroupToGerman;

    public selectDocumentOrderConfig(documentOrderConfig: DocumentOrderConfig): void {
        this.selectedDocumentOrderConfig = documentOrderConfig;

        // Update the documentType in the URL without reloading the page. This allows refreshing the page and staying on the same document.
        const queryParams = {
            documentType: documentOrderConfig.type,
            documentConfigOrderId: undefined,
        };
        if (documentOrderConfig.type === 'customDocument') {
            queryParams.documentConfigOrderId = documentOrderConfig._id;
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
        });

        this.filterAndSortBuildingBlocks();

        // Update placeholder values upon selection of a new document type ("1. Mahnung", "2. Mahnung", ...)
        this.handleExampleInvoiceSelected(this.selectedExampleInvoice);
    }

    public getBuildingBlock(documentBuildingBlockId: DocumentBuildingBlock['_id']): DocumentBuildingBlock {
        const matchingBlock = this.buildingBlocks.find((block) => block._id === documentBuildingBlockId);
        if (!matchingBlock) {
            console.log('No matching document building block found for the given document order element.', {
                documentBuildingBlockId,
            });
        }
        return matchingBlock;
    }

    public showPageBreakBefore(documentBuildingBlockId: DocumentBuildingBlock['_id']): boolean {
        if (this.getBuildingBlock(documentBuildingBlockId)?.config.pageBreakBefore) {
            const preceedingIndex =
                this.selectedDocumentOrderConfig.documentBuildingBlockIds.indexOf(documentBuildingBlockId) - 1;
            // If this is the first document building block, e.g. the letter window in an invoice, do not show a page break before.
            if (preceedingIndex < 0) {
                return false;
            }
            // If the document building block before this one has a page break after, show only one break. That looks better.
            return !this.getBuildingBlock(this.selectedDocumentOrderConfig.documentBuildingBlockIds[preceedingIndex])
                ?.config.pageBreakAfter;
        }
        return false;
    }

    public showPageBreakAfter(documentBuildingBlockId: DocumentBuildingBlock['_id']): boolean {
        return this.getBuildingBlock(documentBuildingBlockId)?.config.pageBreakAfter;
    }

    public getBuildingBlockTooltip(documentBuildingBlock: DocumentBuildingBlock): string {
        if (!documentBuildingBlock) return '';

        let tooltip = documentBuildingBlock.title;
        if (documentBuildingBlock.config.pageBreakBefore && documentBuildingBlock.config.pageBreakAfter) {
            tooltip += '\n\nSeitenumbruch vor & nach Textbaustein (gestrichelte Linie).';
        } else if (documentBuildingBlock.config.pageBreakBefore) {
            tooltip += '\n\nSeitenumbruch vor Textbaustein (gestrichelte Linie).';
        } else if (documentBuildingBlock.config.pageBreakAfter) {
            tooltip += '\n\nSeitenumbruch nach Textbaustein (gestrichelte Linie).';
        }
        return tooltip;
    }

    public reorderDocumentOrderStructure(event: CdkDragDrop<string[]>) {
        // Non-UI structure
        const movedBlock = this.selectedDocumentOrderConfig.documentBuildingBlockIds.splice(event.previousIndex, 1)[0];
        // Add the item back at the new position
        this.selectedDocumentOrderConfig.documentBuildingBlockIds.splice(event.currentIndex, 0, movedBlock);

        // Save the new order back to the server.
        this.saveSelectedDocumentOrderConfig();
    }

    public stopPropagation(event: MouseEvent) {
        event.stopPropagation();
    }

    public saveSelectedDocumentOrderConfig(): void {
        this.documentOrderConfigService.put(this.selectedDocumentOrderConfig);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Order Structure
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Listen For Changes in Custom Field Configs
    //****************************************************************************/
    private registerCustomFieldChangeListener() {
        merge(
            this.fieldGroupConfigService.patchedInLocalDatabase$,
            this.fieldGroupConfigService.createdInLocalDatabase$,
            this.fieldGroupConfigService.deletedInLocalDatabase$,
        ).subscribe(() => {
            this.loadFieldGroupConfigsAndPlaceholderValueTree();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Listen For Changes in Custom Field Configs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public translateDocumentType = translateDocumentType;

    public isTablet(): boolean {
        return isSmallScreen();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/

    public async saveTeam(): Promise<Team> {
        try {
            return await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public createDocumentBuildingBlockOnServer(newBlock: DocumentBuildingBlock): void {
        this.documentBuildingBlockService.create(newBlock);
    }

    public removeDocumentBuildingBlockFromServer(buildingBlock: DocumentBuildingBlock): void {
        this.documentBuildingBlockService.delete(buildingBlock._id);
    }

    public saveDocumentBuildingBlock(buildingBlock: DocumentBuildingBlock): void {
        if (buildingBlock.title.length === 0) {
            buildingBlock.title = 'Kein Titel';
        }

        buildingBlock.updatedAt = moment().format();

        this.documentBuildingBlockService.put(buildingBlock);
        this.evaluateBuildingBlocksVariantConditionsWithPlaceholderValues(buildingBlock);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    /*****************************************************************************
     /  Clean up
     /****************************************************************************/
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Clean up
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly isTouchOnly = isTouchOnly;
}

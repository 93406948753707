<mat-icon class="toolbar-icon" [matMenuTriggerFor]="analyticsSettingsMenu">settings</mat-icon>
<mat-menu #analyticsSettingsMenu="matMenu">
    <!--********** Show Templates **********-->
    <a mat-menu-item class="menu-item-with-icon" (click)="toggleUseNetValues()">
        <mat-icon>euro</mat-icon>
        {{ useNetValues ? 'Bruttowerte anzeigen' : 'Nettowerte anzeigen' }}
        <span
            *ngIf="useNetValuesHelpIndicatorTooltip"
            class="help-indicator"
            [matTooltip]="useNetValuesHelpIndicatorTooltip">
            ?
        </span>
    </a>
</mat-menu>

/**
 * Converts "Herr" to "Herrn" including "Herr Prof. Dr." to "Herrn Prof. Dr."
 * Declension (English) = Deklination (German). That means changing nouns, adjectives, ... depending on the grammatical case etc.
 */
export function getDeclinedSalutation(salutation: string): string {
    if (!salutation) return salutation;

    if (salutation.includes('Herr')) {
        return salutation.replace(/Herr/, 'Herrn');
    }
    // Frau, Prof. Dr., ... For these salutations, the German grammatical case is identical.
    else {
        return salutation;
    }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    DatCalculationDossierListItem,
    DatValuationDossierListItem,
} from '@autoixpert/models/reports/damage-calculation/dat-dossier-list-item';
import { DatDossierType } from '@autoixpert/models/reports/damage-calculation/dat-dossier-type';
import { DatAuthenticationService } from './dat-authentication.service';

@Injectable()
export class DatDossierListService {
    constructor(
        private httpClient: HttpClient,
        private datAuthenticationService: DatAuthenticationService,
    ) {}

    private apiPath = '/api/v0';

    public async find(
        dossierType: DatDossierType,
    ): Promise<(DatCalculationDossierListItem | DatValuationDossierListItem)[]> {
        const datJwt = await this.datAuthenticationService.getJwt();

        const httpParams = new HttpParams({
            fromObject: {
                dossierType,
            },
        });

        return this.httpClient
            .get<(DatCalculationDossierListItem | DatValuationDossierListItem)[]>(`${this.apiPath}/dat/dossiers`, {
                headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                params: httpParams,
            })
            .toPromise();
    }
}

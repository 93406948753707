import { generateId } from '../../../lib/generate-id';

export class BasicTireData {
    type: string = ''; // Dimension
    treadInMm: number = null; // Reifenprofil in Millimeter
    season: TireSeason = null;
    customType?: string; // If season === 'custom', this is the custom tire type the user can enter
    manufacturer: string = ''; // Fabrikat
    comment: string = null;
}

export class Tire extends BasicTireData {
    constructor({
        axle,
        position,
        secondTireSet,
    }: {
        axle: number;
        position: TirePosition;
        secondTireSet?: SecondTire;
    }) {
        super();
        this.axle = axle;
        this.position = position;
        this.secondTireSet = secondTireSet;
    }

    _id: string = generateId(); // Necessary to allow three-way merge.
    axle: number = null; // Achsennummer 1 bis 3, möglicherweise irgendwann höher
    position: TirePosition = null; // Position auf der Achse

    // If the car has a second set of tires (e.g. additional winter tires), we store the information here
    secondTireSet?: SecondTire = null;
}

export class SecondTire extends BasicTireData {}

export type TirePosition =
    | 'left'
    | 'right' // Car
    | 'outerLeft'
    | 'outerRight' // Truck / LKW
    | 'center'; // Motorcycle

export type TireSeason = 'summer' | 'winter' | 'allyear' | 'custom';

<div class="garage-icon-container">
    <mat-icon>build</mat-icon>
</div>
<div class="garage-data-column">
    <div class="garage-details-row">
        <div class="garage-name-container">
            <span class="garage-title">{{ report.garage.contactPerson.organization }}</span>
            <span class="garage-address label">
                {{ report.garage.contactPerson.zip }} {{ report.garage.contactPerson.city }}
            </span>
        </div>
        <mat-icon (click)="removeGarageFromReport()" class="toolbar-icon">delete</mat-icon>
    </div>
    <div class="garage-fees">
        <mat-select
            id="fee-set-title-container"
            [(ngModel)]="report.garage.selectedFeeSetId"
            (selectionChange)="saveReport()"
            placeholder="Kostensätze"
            [disabled]="isReportLocked">
            <mat-select-trigger>
                <!--********** Fee Set Selected **********-->
                <div class="fee-set-preview" *ngIf="getSelectedGarageFeeSet()">
                    <div class="fee-set-labels">
                        <div class="semibold">
                            {{ getSelectedGarageFeeSet().title || 'Kein Titel' }}
                        </div>
                        <div class="label" *ngIf="getSelectedGarageFeeSet().validFrom">
                            gültig seit {{ getSelectedGarageFeeSet().validFrom | moment }}
                        </div>
                    </div>
                    <mat-icon
                        (click)="handleEditGarageFeeSetClick($event)"
                        class="default-garage-fee-set-marker medium-icon"
                        matTooltip="Standard-Kostensätze">
                        edit
                    </mat-icon>
                </div>
            </mat-select-trigger>

            <!--********** Options **********-->
            <mat-option
                class="option-with-label"
                *ngFor="let availableFeeSet of this.report.garage.contactPerson.garageFeeSets"
                [value]="availableFeeSet._id">
                <div class="fee-set-preview">
                    <div class="fee-set-labels">
                        <div class="semibold" [matTooltip]="availableFeeSet.title">
                            {{ availableFeeSet.title || 'Kein Titel' }}
                        </div>
                        <div class="label">gültig seit {{ availableFeeSet.validFrom | moment }}</div>
                    </div>
                    <mat-icon
                        *ngIf="availableFeeSet.isDefault"
                        class="default-garage-fee-set-marker medium-icon"
                        matTooltip="Standard-Kostensätze">
                        star
                    </mat-icon>
                </div>
            </mat-option>
        </mat-select>
    </div>
</div>

import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';

export function toggleValueInArray<T>(value: T, array: T[]): { performedAction: 'add' | 'remove' } {
    if (array.includes(value)) {
        removeFromArray(value, array);
        return { performedAction: 'remove' };
    } else {
        array.push(value);
        return { performedAction: 'add' };
    }
}

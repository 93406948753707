import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { getVehicleValueGross } from '@autoixpert/lib/car-valuation/get-vehicle-value';
import { calculateReplacementValueWithCorrections } from '@autoixpert/lib/damage-calculation-values/replacement-value-correction/calculate-replacement-value-with-corrections';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { ReplacementValueCorrectionConfig } from '@autoixpert/models/reports/replacement-value-corrections/replacement-value-correction-config';
import { ReplacementValueCorrectionItem } from '@autoixpert/models/reports/replacement-value-corrections/replacement-value-correction-item';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { setReplacementValueWithCorrections } from '../../../../../shared/libraries/damage-calculation/set-replacement-value-with-corrections';
import { hasAccessRight } from '../../../../../shared/libraries/user/has-access-right';
import { LoggedInUserService } from '../../../../../shared/services/logged-in-user.service';
import { ReportDetailsService } from '../../../../../shared/services/report-details.service';
import { TeamService } from '../../../../../shared/services/team.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../../shared/services/user-preferences.service';

@Component({
    selector: 'replacement-value-corrections-dialog',
    templateUrl: './replacement-value-corrections-dialog.component.html',
    styleUrls: ['./replacement-value-corrections-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class ReplacementValueCorrectionsDialogComponent implements OnInit, OnDestroy {
    @ViewChild('printSumOnlyToggle') printSumOnlyToggle: MatSlideToggle;

    /**
     * If the vehicle base value input should be focused immediately when the dialog is shown.
     */
    @Input() focusBaseValueInput: boolean = false;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Input() report: Report;

    protected team: Team;
    protected user: User;

    private subscriptions: Subscription[] = [];

    constructor(
        private readonly reportDetailsService: ReportDetailsService,
        private readonly router: Router,
        private readonly teamService: TeamService,
        private readonly loggedInUserService: LoggedInUserService,
        private readonly toastService: ToastService,
        private readonly userPreferences: UserPreferencesService,
    ) {}

    ngOnInit() {
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));
        this.user = this.loggedInUserService.getUser();

        // Create the object for all correction-related things on the report if it does not exist yet
        if (!this.report.valuation.replacementValueCorrectionConfig) {
            this.report.valuation.replacementValueCorrectionConfig = new ReplacementValueCorrectionConfig();
        }

        // Initialize the decreases list with one empty line
        if (!this.report.valuation.replacementValueCorrectionConfig.decreases.length) {
            this.report.valuation.replacementValueCorrectionConfig.decreases.push(new ReplacementValueCorrectionItem());
        }

        // First time the dialog is opened we need to copy the initial replacement value to the vehicle base value.
        // From now on the vehicle value will be calculated based on the base value and corrections
        if (this.report.valuation.vehicleBaseValue == null) {
            this.report.valuation.vehicleBaseValue = getVehicleValueGross(this.report.valuation, 'replacementValue');
        }
    }

    protected closeDialog(): void {
        this.close.emit();
    }

    /**
     * The user changed the 'print sum only' settings -> save it to the report.
     */
    protected updatePrintSumOnlySettings(printSumOnly: boolean): void {
        // Update the setting for this report (this will override the team settings in the future for this report)
        this.report.valuation.replacementValueCorrectionConfig.printSumOnly = printSumOnly;

        this.saveReport();
    }

    /**
     * User can choose to remember the current setting (whether to print or omit the individual correction item values)
     * for the whole team (not only the current report).
     */
    protected async rememberPrintSumOnlySettings(): Promise<void> {
        const currentSetting = this.printSumOnlyToggle.checked;

        this.team.preferences.replacementValueCorrectionsPrintSumOnly = currentSetting;
        const message = currentSetting
            ? 'Nur Summe der Korrekturen abdrucken.'
            : 'Betrag für jede Korrektur einzeln ausweisen.';
        this.toastService.success('Einstellung für alle Gutachten gespeichert', message);
        await this.teamService.put(this.team);
    }

    /**
     * Saves and syncs the report.
     */
    protected async saveReport({ waitForServer }: { waitForServer?: true } = {}): Promise<Report> {
        if (isReportLocked(this.report)) return;

        try {
            await this.reportDetailsService.patch(this.report, { waitForServer });
        } catch (error) {
            console.error('An error occurred while saving the report. ', { error });
        }
    }

    /**
     * User wants to edit the previous damages. Directly jump to the "Fahrzeugzustand" page and scroll down to the respective input field.
     */
    protected navigateToPreviousDamages(): void {
        this.router.navigate(['/Gutachten', this.report._id, 'Fahrzeugzustand'], {
            queryParams: { scrollToBottom: true },
        });
    }

    /**
     * Save the calculated replacement value to the report.
     */
    public calculateFinalValue(): void {
        if (isReportLocked(this.report)) {
            return;
        }

        const grossValue = calculateReplacementValueWithCorrections(this.report);
        setReplacementValueWithCorrections(this.report, grossValue, this.userPreferences);

        this.saveReport();
    }

    protected readonly calculateReplacementValueWithCorrections = calculateReplacementValueWithCorrections;
    protected readonly isAdmin = isAdmin;
    protected readonly isReportLocked = isReportLocked;

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    //*****************************************************************************
    //  Keyboard Events
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected readonly hasAccessRight = hasAccessRight;
}

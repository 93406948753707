import { Injectable } from '@angular/core';
import feathersAuthentication, { AuthenticationClient } from '@feathersjs/authentication-client';
import { feathers } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import { Socket, io } from 'socket.io-client';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { logoutAfterNotAuthenticatedError } from '../libraries/logout-after-not-authenticated-error';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';

@Injectable()
export class FeathersSocketioService {
    public socket: Socket;
    public app = feathers();

    /**
     * Contains a list of the socket IDs of all tabs on this device between the respective tab and the autoiXpert backend.
     * Since all tabs are synced locally via Broadcast Channels, there is no need to sync them again via WebSockets. So the backend
     * prevents WebSocket events to be sent to any socket that exists on this device by using these socket IDs.
     */
    public autoiXpertSocketIdsOnThisDevice = new Set<string>();
    private socketIdsOnThisDeviceBroadcastChannel = new BroadcastChannel('socket-ids-on-this-device');

    constructor(
        private networkStatusService: NetworkStatusService,
        private loggedInUserService: LoggedInUserService,
    ) {
        this.socket = io('/', {
            path: `/api/v0/socket.io`,
            /**
             * Don't use polling as a fallback. Websockets are widely supported and polling will cause socket.io to do polling
             * before establishing a websocket connection. That polling start is unnecessary.
             */
            transports: ['websocket'],
            /**
             * Since focussing the windows triggers a network detection which in turn will trigger a reconnection attempt,
             * this max delay between connection attempts may be reasonably high.
             * The first reconnection attempt will be triggered after 1 second and then double until this maximum is reached.
             *
             * Auto-reconnection is important because the user may lose connection when switching from Wi-Fi to mobile data or some
             * network device between the user and the autoiXpert server is restarted or the autoiXpert backend servers are restarted.
             */
            reconnectionDelayMax: 120_000,
        });
        // Set up Socket.io client with the socketIo
        this.app.configure(socketio(this.socket));
        this.app.set('loggedInUserService', this.loggedInUserService);

        this.app.configure(
            feathersAuthentication({
                // Save the jwt in the local storage entry "autoiXpertJWT".
                storageKey: 'autoiXpertJWT',

                Authentication: AxAuthenticationClient,
            }),
        );

        //*****************************************************************************
        //  Re-Authenticate on Error
        //****************************************************************************/
        this.app.service('realtimeEditSessions').hooks({
            error: {
                all: [
                    async (context) => {
                        /**
                         * If this socket connection is not authenticated, try to authenticate with the locally saved JWT through app.reAuthenticate (see https://docs.feathersjs.com/api/authentication/client.html#app-reauthenticate).
                         * Then retry the request. But only retry once to prevent infinity loops.
                         */
                        if (context.error?.code === 'AX_NOT_AUTHENTICATED') {
                            if (!(context.params as any).reauthenticationWasTried) {
                                (context.params as any).reauthenticationWasTried = true;

                                await this.app.reAuthenticate(true);

                                context.error = undefined;

                                switch (context.method) {
                                    case 'create':
                                        context.result = await context.service.create(context.data, context.params);
                                        break;
                                    case 'find':
                                        context.result = await context.service.find(context.params);
                                        break;
                                    case 'patch':
                                        context.result = await context.service.patch(
                                            context.id,
                                            context.data,
                                            context.params,
                                        );
                                        break;
                                    case 'get':
                                        context.result = await context.service.get(context.id, context.params);
                                        break;
                                    case 'remove':
                                        context.result = await context.service.remove(context.id, context.params);
                                        break;
                                }
                            } else {
                                console.log(`The feathers socket could not be reAuthenticated.`, {
                                    error: context.error,
                                });
                            }
                        }
                    },
                ],
            },
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Re-Authenticate on Error
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Detect Network Status when Socket Fails
        //****************************************************************************/
        /**
         * Websocket disconnects are a very reliable way to detect issues in the connectivity to the autoiXpert backend.
         */
        this.socket.on('disconnect', () => {
            console.log('🌍🚫 Network socket disconnect. Detect network status.');

            /**
             * iOS and iPadOS may put all apps in the background to a suspended state, running no code.
             * As soon as the app is in the foreground again, the network status is checked. Usually, the device is online
             * immediately.
             * If we weren't to set the status to offline in this event handler, the status being immediately online
             * would follow the online status from before the browser app being put to sleep. This would not trigger
             * a back-online event which is needed to initiate syncs, upload remaining images, or rejoin live update channels.
             */
            this.networkStatusService.networkStatusChange$.next({
                status: 'offline',
                /**
                 * We do not yet know if the user is not connected to the internet or if only our servers are down.
                 */
                offlineReason: 'unknown',
            });

            void this.networkStatusService.detectNetworkStatus();
        });
        /**
         * When the user initially loads the page, autoiXpert considers itself online. If the websocket connection fails on page load, e.g. because the user
         * (re)loaded autoiXpert while offline, the network detection should run to find out if the HTTP servers are reachable.
         */
        this.socket.once('connect_error', () => {
            console.log('🌍🚫 Network socket connect_error. Detect network status.');
            this.networkStatusService.detectNetworkStatus();
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Detect Network Status when Socket Fails
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Reconnect when Back Online
        //****************************************************************************/
        this.networkStatusService.networkBackOnline$.subscribe(async () => {
            console.log(`The network is back online, so try to reconnect the websocket.`);

            try {
                await this.ensureAuthenticatedConnection();
            } catch (error) {
                console.warn(
                    `This device could not establish an active socket connection after the network came back online.`,
                );
            }
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Reconnect when Back Online
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Authenticate When User is Ready
        //****************************************************************************/
        /**
         * After logging in successfully or restoring the existing user from a local JWT, authenticate the socket so
         * that it's being notified of new records.
         */
        this.loggedInUserService.getUser$().subscribe(async (user) => {
            if (user) {
                try {
                    /**
                     * Mark the app as authenticated because the JWT was saved after the HTTP authentication. When the socket
                     * connects, the feathers app will authenticate the socket if the app is marked as authenticated.
                     * See /node_modules/@feathersjs/authentication-client/lib/core.js:handleSocket()
                     */
                    this.app.authentication.authenticated = true;
                    await this.ensureAuthenticatedConnection();
                } catch (error) {
                    throw new AxError({
                        code: 'AUTHENTICATING_SOCKET_AFTER_USER_AUTHENTICATION_FAILED',
                        message:
                            '🔑⛔ Authenticating the socket after login failed. Since this is optional, fail silently.',
                        error,
                    });
                }
            } else {
                // user === null means the user logged out or was logged out due to an invalid JWT.
                await this.app.authentication.reset();
            }
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Authenticate When User is Ready
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Remember Socket-ID
        //****************************************************************************/
        /**
         * The socket ID is sent to the autoiXpert backend via an HTTP header for every HTTP request to the backend. The backend ensures that
         * feathers service events (create, patch, delete) are not propagated to this socket again because that would apply the same change twice on this device.
         *
         * The HTTP header is added in an Angular interceptor.
         */
        this.socket.on('connect', () => {
            // Let all other tabs know about this socket ID.
            this.socketIdsOnThisDeviceBroadcastChannel.postMessage({
                eventName: 'socket-connect',
                socketId: this.socket.id,
            } as SocketIdsOnThisDeviceEventPayload);

            // Save this tab's socket ID to the list of socket IDs on this device.
            this.autoiXpertSocketIdsOnThisDevice.add(this.socket.id);

            console.log('🌍 Network socket connected.');
        });

        this.socket.on('disconnect', () => {
            // Remove this socket ID from other tab's lists.
            this.socketIdsOnThisDeviceBroadcastChannel.postMessage({
                eventName: 'socket-disconnect',
                socketId: this.socket.id,
            });

            // Remove this socket ID from this tab's list.
            this.autoiXpertSocketIdsOnThisDevice.delete(this.socket.id);
        });

        /**
         * Ensure that the list of socket IDs across all tabs on this device is complete in every single tab. Have a look at the definition
         * of "this.autoiXpertSocketIdsOnThisDevice" to see how socket IDs are further processed.
         */
        this.socketIdsOnThisDeviceBroadcastChannel.addEventListener(
            'message',
            (event: MessageEvent<SocketIdsOnThisDeviceEventPayload>) => {
                if (event.data.eventName === 'socket-connect') {
                    this.autoiXpertSocketIdsOnThisDevice.add(event.data.socketId);

                    /**
                     * If this tab's socket is connected, let the tab with the new socket connection know about it so that all tabs
                     * know about all other tabs' socket connection IDs.
                     */
                    if (this.socket.connected) {
                        this.socketIdsOnThisDeviceBroadcastChannel.postMessage({
                            eventName: 'connected-socket-pong',
                            socketId: this.socket.id,
                        } as SocketIdsOnThisDeviceEventPayload);
                    }
                }
                if (event.data.eventName === 'connected-socket-pong') {
                    this.autoiXpertSocketIdsOnThisDevice.add(event.data.socketId);
                } else if (event.data.eventName === 'socket-disconnect') {
                    this.autoiXpertSocketIdsOnThisDevice.delete(event.data.socketId);
                }
            },
        );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Remember Socket-ID
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /**
     * This method returns a promise that resolves when the socket is connected. If the socket is not connected, yet, this method will trigger a connection.
     *
     * Usually, socket.io reconnects automatically. We observed, though, that feathers' socket.io version (v2) does not attempt to reconnect if the initial connection
     * failed, e.g. because the user loaded autoiXpert while he was offline. To reconnect the socket when it's needed, e.g. when joining a realtime edit session, this method
     * exists.
     */
    public async ensureAuthenticatedConnection() {
        await this.ensureConnection();
        await this.app.reAuthenticate(false);
    }

    private async ensureConnection() {
        return new Promise<void>((resolve, reject) => {
            if (this.socket.connected) {
                resolve();
            } else {
                this.socket.connect();
            }
            this.socket.once('connect', resolve);
            this.socket.once('connect_error', (connectError) => {
                reject(
                    new AxError({
                        code: 'SOCKET_IO_CONNECT_ERROR',
                        message: `The socket.io connection to the server failed. Have a look at the error/causedBy property for details.`,
                        error: connectError,
                    }),
                );
            });
        });
    }
}

/**
 * Overwrite getAccessToken method so that it uses store2.
 *
 * If we let feathers read the JWT directly, there would be redundant quotation marks ("") because store2 stores strings within quotation marks that are removed when reading with store2.
 * They're not removed when reading localStorage directly, as feathers would do by default.
 */
class AxAuthenticationClient extends AuthenticationClient {
    async getAccessToken(): Promise<string | null> {
        const accessToken = store.get(this.options.storageKey);

        if (!accessToken && typeof window !== 'undefined' && window.location) {
            return this.getFromLocation(window.location);
        }
        return accessToken || null;
    }

    async handleError(error: AxError | Error, type: 'authenticate' | 'logout') {
        await this.reset();

        const isAxAuthenticationError = (error as AxError)?.code === 'AX_NOT_AUTHENTICATED';
        const isFeathersClientAuthenticationError = (error as Error)?.name === 'NotAuthenticated';
        if (isAxAuthenticationError || isFeathersClientAuthenticationError) {
            const loggedInUserService = this.app.get('loggedInUserService');
            logoutAfterNotAuthenticatedError({ loggedInUserService });
            return;
        }

        throw error;
    }
}

interface SocketIdsOnThisDeviceEventPayload {
    /**
     * A tab's socket is disconnected an all other tabs should take note: socket-disconnect
     * A tab's socket is connected an all other tabs should take note: socket-connect
     * --> When a tab receives info about a newly connected socket in a second tab, it sends a pong with its own socket ID back so that the second tab with the newly connected socket knows about all other existing tabs' sockets: connected-socket-pong
     */
    eventName: 'socket-disconnect' | 'socket-connect' | 'connected-socket-pong';
    socketId: string;
}

import { CalendarMonth } from '../calendar';

export class DatevConfig {
    // necessary for payment export, currently not in use
    clientNumber: number;
    // necessary for payment export, currently not in use
    advisorNumber: number;
    // necessary for payment export, currently not in use
    firstMonthInEconomicYear: CalendarMonth;

    /**
     * Companies with regular taxation (Sollbesteuerung) export by invoice date.
     * "Istversteuerung" (= pay taxes when income actually arrives on your bank account)
     * means they need to export invoices by payment date.
     */
    exportByInvoiceOrPaymentDate: 'invoiceDate' | 'paymentDate';

    /**
     * DATEV 'Konto / Kundenkonto':
     * This is the account number of the business partner in DATEV (usually 'Soll').
     * Most companies use a default account number for all business partners ('Sammelkonto').
     * Label in DATEV Rechnungswesen: Konto
     * Property Name in DATEV-Export: bpAccountNo
     *
     * This may be overwritten by the businessPartnerAccountNo of the invoice recipient.
     */
    defaultBusinessPartnerAccountNo: number;

    /**
     * DATEV 'Gegenkonto':
     * This is the is the second account number for booking the invoice (usually 'Haben')
     * Label in DATEV Rechnungswesen: Gegenkonto
     * Property Name in DATEV-Export: accountNo
     *
     * Most companies would use 8400 since this account is configured to handle 19% VAT automatically.
     */
    accountNo: number; // Default: 8400 in SKR03

    /**
     * DATEV 'Kostenstelle':
     * The costCategoryId is required to analyze the business performance of a company, e.g. using a 'Betriebsabrechnungsbogen'
     * Label in DATEV Rechnungswesen: KOST1
     * Property Name in DATEV-Export: costCategoryId
     *
     * This may be overwritten by the costCategoryId of the assessor or office location
     */
    defaultCostCenter: string;

    /**
     * DATEV 'Belegtext'
     * This text is the label for the booking (currently cropped after 30 digits, but DATEV allows officially 60 digits)
     * Customers may use it to identify the booking, e.g. adding the license plate number.
     * Customers may add a placeholder to the label, e.g. {Fahrzeug.Kennzeichen} - {Anspruchsteller.NameKomplett}
     * Label in DATEV Rechnungswesen: Buchungstext
     * Property Name in DATEV-Export: bookingText
     */
    bookingTextPattern: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssessorsFeeAnalyticsRecord } from '@autoixpert/models/analytics/assessors-fee-analytics.model';
import { AnalyticsFilterOptions, prepareDefaultParametersForAnalyticsQuery } from './default-analytics-query-parameter';

@Injectable()
export class AssessorsFeeAnalyticsService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getAssessorsFees(filterOptions: AnalyticsFilterOptions): Observable<AssessorsFeeAnalyticsRecord[]> {
        return this.httpClient.get<AssessorsFeeAnalyticsRecord[]>(`${this.apiPath}/analytics/assessorsFeesByAssessor`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'completionDate',
                ...filterOptions,
            }),
        });
    }
}

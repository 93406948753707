import { Query as FeathersQuery } from '@feathersjs/feathers';
import { IDBPDatabase, IDBPTransaction, StoreKey, StoreNames, openDB } from 'idb';
import { IndexNames } from 'idb/build/entry';
import { DateTime } from 'luxon';
import { Subject, merge } from 'rxjs';
import { generateId } from '@autoixpert/lib/generate-id';
import { pluralize } from '@autoixpert/lib/pluralize';
import { boilDownToQuickRecord } from '@autoixpert/lib/server-sync/boil-down-to-quick-record';
import { flattenChangePaths } from '@autoixpert/lib/server-sync/flatten-change-paths';
import {
    ObjectDifference,
    getListOfDifferences,
    getListOfDifferencesFromFlattenedChangePaths,
} from '@autoixpert/lib/server-sync/get-list-of-differences';
import { insertChangesIntoRecord } from '@autoixpert/lib/server-sync/insert-changes-into-record';
import { AxError } from '@autoixpert/models/errors/ax-error';
import {
    AxIndexeddbDump,
    AxIndexeddbDumpRecords,
    ChangeRecord,
    DataTypeBase,
    DatabaseIndexeddbStructure,
    DatabaseInfo,
    DatabaseServiceName,
    IndexeddbEventSource,
    ObjectStoreAndIndexMigration,
    ObjectStoreAndIndexMigrations,
    PartialWithId,
    PatchedEvent,
    RecordMigrations,
} from '@autoixpert/models/indexed-db/database.types';
import { QuickSearchRecord } from '@autoixpert/models/indexed-db/quick-search-record';
import { StorageSpaceManager } from '../storage-space-manager/storage-space-manager.class';
import { applyMongoQuery } from './apply-mongo-query';

export class AxIndexedDB<DataType extends DataTypeBase> {
    public readonly serviceName: DatabaseServiceName; // Name of the service in the backend that this database shall sync with. Singular, such as "report" for the endpoint "/reports"
    protected serviceNamePlural: string; // Plural name, such as "reports" or "contactPeople"-> Is explicitly passed or inferred automatically.

    /**
     * The promise-less connection object should be used if available because awaiting the promise may result in ~100ms due to the event loop.
     *
     * The promise can be used if the connection has not yet been established so that calls to IndexedDB do not cause an error.
     * @private
     */
    private indexeddbConnectionPromise: Promise<IDBPDatabase<DatabaseIndexeddbStructure<DataType>>>;
    private indexeddbConnection: IDBPDatabase<DatabaseIndexeddbStructure<DataType>>;

    /**
     * Set to true if the user's browser does not grant access to IndexedDB. Possible reasons:
     * - Incognito mode in Firefox. Incognito mode in Chrome allows using IndexedDB.
     * - IndexedDB not implemented <-- This should rarely be the case.
     */
    public isIndexeddbUnusable: boolean = false;

    //*****************************************************************************
    //  Migrations
    //****************************************************************************/
    /**
     * Additional recordMigrations may be added in the constructor of a class extending this base class. These recordMigrations do not migrate the database
     * but the records within the database.
     */
    protected recordMigrations: RecordMigrations<DataType> = {};
    /**
     * These functions will be executed in the order of the key (which must be a timestamp). This ensures that both centrally defined schema changes (object stores, indexes, ...)
     * (in this class) and indexes per service (in the inheriting class) can be created. Indexes per service are relevant for sorting by a specific field, e.g. sorting
     * a contact person by organization.
     *
     * To add a schema migration in the inheriting class, pass another object like this at objectStoreAndIndexMigrations to this class' constructor.
     * The timestamp should be set to the time when the schema migration was created by the developer.
     */
    protected objectStoreAndIndexMigrations: ObjectStoreAndIndexMigrations<DataType> = {
        '2022-01-01T00:00:00.000+01:00': async (database) => {
            const recordsObjectStore = database.createObjectStore('records', { keyPath: '_id' });
            // Add an index for the schema version to increase performance when querying for records that need to be updated on page load.
            recordsObjectStore.createIndex('schema-version', '_schemaVersion');

            database.createObjectStore('serverShadows', { keyPath: '_id' });
            database.createObjectStore('created', { keyPath: '_id' });
            database.createObjectStore('deleted', { keyPath: '_id' });
            database.createObjectStore('patched', { keyPath: '_id' });
            // The database info store contains only one object holding all metadata about the database.
            database.createObjectStore('databaseInfo', { keyPath: '_id' });
            database.createObjectStore('lastAccessed', { keyPath: '_id' });
        },
        /**
         * Initial migration to create an objectStore "quickSearchRecords" in IndexedDB.
         *
         * The objects in this store are small, partial copies of the records within a database
         * to increase performance of sorting and searching records in IndexedDB.
         *
         * Smaller objects = faster reads.
         */
        '2022-07-06T00:00:00.000+01:00': async (database, transaction) => {
            database.createObjectStore('quickSearchRecords', { keyPath: '_id' });

            if (this.supportsQuickSearch) {
                // Create quickSearchRecords from existing records
                const allRecords: DataType[] = await transaction.objectStore('records').getAll();
                const quickSearchRecords: QuickSearchRecord[] = allRecords.map((fullRecord) =>
                    boilDownToQuickRecord(fullRecord, this.keysOfQuickSearchRecords),
                );

                if (!quickSearchRecords.length) {
                    return;
                }

                try {
                    await Promise.all(
                        quickSearchRecords.map((quickSearchRecord) =>
                            transaction.objectStore('quickSearchRecords').put(quickSearchRecord),
                        ),
                    );
                } catch (error) {
                    console.error('GENERATING_QUICK_SEARCH_RECORDS_FAILED within an IndexedDB migration.', error);
                }
            }
        },
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Migrations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Local CRUD Events
    //****************************************************************************/
    /**
     * Changes coming from:
     *
     * Local User in This Tab
     * The user initiated this change on this device in this tab. It needs to be
     * - persisted in the IndexedDB
     * - propagated to other tabs so that other tabs receive this change immediately and their components show the most up-to-date data (faster than via a Websocket)
     * - propagated to the server so that other devices know about this change (events are propagated to other tabs on this device again via Websockets as of now)
     *
     * External Server
     * The change was created on a different device and came to this device via the server. It needs to be
     * - persisted in the IndexedDB
     * - propagated to other tabs because this current tab may have received a changed record during a pull while the record is open in another tab's component
     * - propagated to components in this tab
     *
     * Local Broadcast
     * The change was created on this device but in a different tab. Only one tab from this device will sync this change to the server and write it to IndexedDB. The change needs to be
     * - propagated to components in this tab
     *   If the change originates from the server, e.g. during a pull in the report list component, the new data will be saved as an up-to-date
     *   server shadow.
     *   If this new server shadow was compared to the old data in the component when syncing, the sync would remove the new data on the server. That's
     *   bad because valid data is deleted.
     *
     *   If the change originates from another tab on this device, the change will be reflected immediately through the broadcast channel. Also, it will be propagated a 2nd time, this time through the websocket connection.
     *   We currently do not prevent this because it does not do any harm. It could be prevented if every socket had an IndexedDB-ID associated with it and events were not
     *   propagated to sockets that use the same IndexedDB (are located on the same device).
     */
    /**
     * Subscribe to changes that originated on this device in this tab, only. This is useful if local changes should change some sort of local cache without applying the changes
     * that should be applied when an external change comes in.
     */
    public createdFromLocalUserInThisTab$ = new Subject<DataType>();
    public patchedFromLocalUserInThisTab$ = new Subject<PatchedEvent<DataType>>();
    public deletedFromLocalUserInThisTab$ = new Subject<DataType['_id']>();

    /**
     * Changes come from another device via a socket connection or through an HTTP pull. They are written to IndexedDB and
     * are propagated to this tab's components via these subjects.
     */
    public createdFromExternalServer$ = new Subject<DataType>();
    public patchedFromExternalServer$ = new Subject<PatchedEvent<DataType>>();
    public deletedFromExternalServer$ = new Subject<DataType['_id']>();

    /**
     * Changes come from another tab on this device (Broadcast). These changes are not written to IndexedDB again but they are propagated
     * to the current tab's components via these subjects so that the components may update their data.
     */
    public createdFromLocalBroadcast$ = new Subject<DataType>();
    public patchedFromLocalBroadcast$ = new Subject<PatchedEvent<DataType>>();
    public deletedFromLocalBroadcast$ = new Subject<DataType['_id']>();
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local CRUD Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Local Broadcast Channels
    //****************************************************************************/
    /**
     * These channels are used when this device is offline. They mimic a web socket connection and broadcast all
     * create/put/delete events to all other open tabs on this device.
     * @private
     */
    private offlineTabSyncCreateChannel: BroadcastChannel;
    private offlineTabSyncPatchChannel: BroadcastChannel;
    private offlineTabSyncDeleteChannel: BroadcastChannel;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local Broadcast Channels
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Quick Search
    //****************************************************************************/
    /**
     * IndexedDB becomes very slow when querying many large objects. Therefore, when searching and filtering,
     * we maintain an auxiliary collection with only the searchable data.
     */
    protected keysOfQuickSearchRecords: string[] = [];
    private readonly supportsQuickSearch: boolean;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Quick Search
    /////////////////////////////////////////////////////////////////////////////*/

    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        recordMigrations?: RecordMigrations<DataType>;
        objectStoreAndIndexMigrations?: ObjectStoreAndIndexMigrations<DataType>;
        keysOfQuickSearchRecords?: string[];
        get$SearchMongoQuery?: (string) => [string, any];
    }) {
        this.serviceName = params.serviceName;
        this.serviceNamePlural = params.serviceNamePlural ?? pluralize(this.serviceName);
        this.recordMigrations = params.recordMigrations || {};
        this.objectStoreAndIndexMigrations = {
            ...this.objectStoreAndIndexMigrations,
            ...(params.objectStoreAndIndexMigrations || {}),
        };
        this.keysOfQuickSearchRecords = params.keysOfQuickSearchRecords || [];
        this.supportsQuickSearch = !!this.keysOfQuickSearchRecords.length;
        this.get$SearchMongoQuery = params.get$SearchMongoQuery ?? this.get$SearchMongoQuery;

        this.openDatabase();

        this.offlineTabSyncCreateChannel = new BroadcastChannel(`${this.serviceName}-offline-tab-sync-create`);
        this.offlineTabSyncPatchChannel = new BroadcastChannel(`${this.serviceName}-offline-tab-sync-patch`);
        this.offlineTabSyncDeleteChannel = new BroadcastChannel(`${this.serviceName}-offline-tab-sync-delete`);

        // Listen to live updates from other tabs and send live updates to other tabs.
        this.registerTabSyncEventListenersAndPublishers();

        //*****************************************************************************
        //  Record Migrations
        //****************************************************************************/
        this.updateRecordSchemas().catch((error) => {
            console.error(`[offline-sync ${this.serviceName}] Error updating local record schemas.`, { error });
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Record Migrations
        /////////////////////////////////////////////////////////////////////////////*/
    }

    //*****************************************************************************
    //  Open Database
    //****************************************************************************/
    public openDatabase() {
        //*****************************************************************************
        //  IndexedDB Object Store & Index Migrations
        //****************************************************************************/
        const sortedIndexeddbMigrations: ObjectStoreAndIndexMigration<DataType>[] = [];
        const indexeddbMigrationsKeys = Object.keys(this.objectStoreAndIndexMigrations).sort(
            (timestampA, timestampB) => {
                return DateTime.fromISO(timestampA) < DateTime.fromISO(timestampB) ? -1 : 1;
            },
        );
        for (const indexeddbMigrationsKey of indexeddbMigrationsKeys) {
            sortedIndexeddbMigrations.push(this.objectStoreAndIndexMigrations[indexeddbMigrationsKey]);
        }

        /**
         * This custom promise only resolves if the openDB request *including the upgrade migrations* succeeded.
         */
        this.indexeddbConnectionPromise = new Promise<IDBPDatabase<DatabaseIndexeddbStructure<DataType>>>(
            (resolve, reject) => {
                openDB(this.getDatabaseName(), sortedIndexeddbMigrations.length, {
                    //*****************************************************************************
                    //  Execute Version Upgrades
                    //****************************************************************************/
                    upgrade: async (
                        database: IDBPDatabase<DatabaseIndexeddbStructure<DataType>>,
                        oldDatabaseVersion: number,
                        newDatabaseVersion: number | null,
                        transaction: IDBPTransaction<
                            DatabaseIndexeddbStructure<DataType>,
                            StoreNames<DatabaseIndexeddbStructure<DataType>>[],
                            'versionchange'
                        >,
                    ) => {
                        for (const index in sortedIndexeddbMigrations) {
                            // The first migration at index 0 migrates to version 1, index 1 migrates to version 2 etc.
                            const migrationVersion = parseInt(index) + 1;
                            /**
                             * Only execute the database migration that's relevant for this version change. Otherwise, changes on the database would be executed multiple times.
                             */
                            if (migrationVersion > oldDatabaseVersion) {
                                try {
                                    await sortedIndexeddbMigrations[index](database, transaction);
                                } catch (error) {
                                    // Error Message: "Failed to execute 'transaction' on 'IDBDatabase': A version change transaction is running."
                                    if (error.code === 11) {
                                        const migrationError = new AxError({
                                            code: 'TRANSACTION_WAS_RUNNING_DURING_INDEXEDDB_MIGRATION',
                                            message:
                                                'Please check if all calls within your migration use the transaction passed as a parameter to the upgrade method. Details: https://stackoverflow.com/questions/33709976/uncaught-invalidstateerror-failed-to-execute-transaction-on-idbdatabase-a',
                                            error,
                                            data: {
                                                serviceName: this.serviceName,
                                                migrationVersion,
                                                oldDatabaseVersion,
                                            },
                                        });
                                        reject(migrationError);
                                    }

                                    const migrationError = new AxError({
                                        code: 'EXECUTING_INDEXEDDB_MIGRATION_FAILED',
                                        message: `The IndexedDB migration in the service "${this.serviceName}" failed to execute.`,
                                        error,
                                        data: {
                                            serviceName: this.serviceName,
                                            migrationVersion,
                                            oldDatabaseVersion,
                                        },
                                    });
                                    reject(migrationError);
                                }
                            }
                        }
                    },
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Execute Version Upgrades
                    /////////////////////////////////////////////////////////////////////////////*/
                    //*****************************************************************************
                    //  Register Close Handler
                    //****************************************************************************/
                    // If the connection is closed by the browser (possibly connection timeouts after having been open
                    // for a long while), re-establish the connection.
                    terminated: () => {
                        console.warn(`[${this.serviceName}] Terminated IndexedDB connection. Re-opening.`);
                        this.openDatabase();
                    },
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Register Close Handler
                    /////////////////////////////////////////////////////////////////////////////*/
                    // After all upgrades ran through, resolve.
                }).then(resolve, reject);
            },
        )
            .then((indexeddbConnection) => {
                this.indexeddbConnection = indexeddbConnection;
                return indexeddbConnection;
            })
            .catch((error) => {
                if (error.name === 'InvalidStateError') {
                    this.isIndexeddbUnusable = true;
                }
                throw error;
            });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END IndexedDB Object Store & Index Migrations
        /////////////////////////////////////////////////////////////////////////////*/
    }

    private getDatabaseName(): string {
        return this.serviceNamePlural;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Open Database
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Local Database Metadata
    //****************************************************************************/
    /**
     * We store info about the indexedDB instance in a separate object store.
     * The metadata is only valid for the current database (e.g. reports). It does not apply to
     * sister DBs like invoices.
     *
     * Data:
     * - lastFullSyncAt = Full syncs load all data from the server endpoint. Next time a full sync is triggered, new records can be queried via the timestamp in updatedAt.
     * - lastDeletedSyncAt = Timestamp when the last deletedAt records were loaded. New deleted records are loaded on every pullFromServer().
     * - databaseId = To avoid writing a put into a DB twice when using websockets across multiple browser tabs, we assign an ID to each DB instance.
     */
    public async getDatabaseInfo(): Promise<DatabaseInfo> {
        const databaseConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        let databaseInfo = await databaseConnection.get('databaseInfo', 'databaseInfoKey');
        if (!databaseInfo) {
            databaseInfo = {
                _id: 'databaseInfoKey',
                databaseId: generateId(),
                lastFullSyncAt: undefined,
                lastDeletedSyncAt: undefined,
            };
        }
        return databaseInfo;
    }

    public async setDatabaseInfo(databaseInfo: DatabaseInfo): Promise<void> {
        const databaseConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        await databaseConnection.put('databaseInfo', databaseInfo).catch((error) => {
            console.error(`[offline-sync ${this.serviceName}] Error writing database info.`, { error });
            throw error;
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local Database Metadata
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Local CRUD Operations (IndexedDB)
    //****************************************************************************/
    /**
     * Create a record in the local database and propagate this change to all components subscribing to this event.
     * This method should be called both when a user creates a record locally and when an event is received from the server
     * about another device having created a record.
     *
     * @param record
     * @param source - If the record has been created in this browser context (local) or in another place (external) such as the server or another tab.
     */
    public async createLocal(record: DataType, source?: IndexeddbEventSource): Promise<DataType>;
    public async createLocal(records: DataType[], source?: IndexeddbEventSource): Promise<DataType[]>;
    public async createLocal(
        records: DataType | DataType[],
        source: IndexeddbEventSource = 'localUser',
    ): Promise<DataType | DataType[]> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        let inputWasArray = true;
        if (!Array.isArray(records)) {
            records = [records];
            inputWasArray = false;
        }

        /**
         * Use a single transaction to speed up the full sync mechanism.
         */
        const transaction = indexeddbConnection.transaction(
            ['records', 'created', 'deleted', 'lastAccessed', 'quickSearchRecords'],
            'readwrite',
        );

        for (const record of records) {
            /**
             * In order to increase the perceived performance for the user, we do an optimistic write and rather handle the failure exception
             * instead of waiting for the transaction to be done.
             *
             * Notify our local listeners of external updates, e.g. through sockets from another device/the autoiXpert servers.
             */
            if (source === 'externalServer') {
                this.createdFromExternalServer$.next(record);
            } else {
                this.createdFromLocalUserInThisTab$.next(record);
            }

            //*****************************************************************************
            //  Write Record
            //****************************************************************************/

            /////////////////////////////////////////////////////////////////////////////*/
            //  END Write Record
            /////////////////////////////////////////////////////////////////////////////*/
            await transaction
                .objectStore('records')
                .put(record)
                .catch(async (error) => {
                    await StorageSpaceManager.checkNeedForDataEjection({ error });
                    console.error(
                        `[offline-sync ${this.serviceName}] Error creating record in "createLocal".`,
                        error,
                        record,
                    );
                    // A deletion from an external server simulates removing this record locally without propagating that deletion to the server.
                    // TODO Remove after 2025-02-01 if it didn't break our sync algorithm completely. Deleting a local record seems weird, that's why we removed this line. We suppose failing to write a record should not trigger deletion of the same.
                    // this.deletedFromExternalServer$.next(record._id);
                    throw error;
                });

            // Only write quickSearchRecord if this services supports it.
            if (this.supportsQuickSearch) {
                await transaction
                    .objectStore('quickSearchRecords')
                    .put(boilDownToQuickRecord(record, this.keysOfQuickSearchRecords))
                    .catch(async (error) => {
                        await StorageSpaceManager.checkNeedForDataEjection({ error });
                        console.error(
                            `[offline-sync ${this.serviceName}] Error creating quick search record in "createLocal".`,
                            error,
                            record,
                        );
                        throw error;
                    });
            }
            // If the record has been created on this device/tab, make a 'created' metadata record. This will create this record
            // on the server on next sync.
            if (source === 'localUser') {
                await transaction
                    .objectStore('created')
                    .put({ _id: record._id })
                    .catch(async (error) => {
                        await StorageSpaceManager.checkNeedForDataEjection({ error });
                        console.error(
                            `[offline-sync ${this.serviceName}] Error creating "created" entry in "createLocal".`,
                            error,
                            record,
                        );
                        throw error;
                    });
                /**
                 * If this record was previously deleted locally and is now restored or created again with the same ID, remove the
                 * deleted record.
                 * A record may be created with the same ID in the case a record is restored after it was deleted.
                 */
                await transaction.objectStore('deleted').delete(record._id);
            }
            this.setLastAccessed(record._id, transaction).catch((error) => {
                console.error(
                    `[offline-sync ${this.serviceName}] Error setting lastAccessed entry in "createLocal".`,
                    error,
                    record,
                );
                throw error;
            });
        }

        // This is the signal that everything was successfully committed to the database.
        await transaction.done;

        if (inputWasArray) {
            return records;
        } else {
            return records[0];
        }
    }

    /**
     * Used internally to get the current state of a record. That's relevant e.g. when patching records to the server.
     * @param recordId
     * @private
     */
    public async getLocal(recordId: DataType['_id']): Promise<DataType | undefined> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const [record] = await Promise.all([
            /**
             * Do not use a single transaction for getting the record and writing the last accessed timestamp to increase read speed. If the transaction
             * is started in "readwrite" mode, both the "records" store and the "lastAccessed" store are write-locked. FOr many parallel reads as with the photos
             * in the report list, this decreases performance drastically.
             */
            db.get('records', recordId),
            this.setLastAccessed(recordId),
        ]);

        return record;
    }

    public async findLocalByIds(recordIds: DataType['_id'][]): Promise<DataType[]> {
        const timerLabel = this.generateTimerLabel('findLocalByIds');
        const startTime = performance.now();
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const transaction = await db.transaction(['records'], 'readonly');
        const foundRecords: DataType[] = (
            await Promise.all(recordIds.map((recordId) => transaction.objectStore('records').get(recordId)))
        )
            // Remove entries for records not found.
            .filter(Boolean);

        /**
         * The last accessed date is used when deciding which data to eject when storage space becomes scarce.
         */
        this.setBatchLastAccessed(foundRecords.map((record) => record._id)).catch((error) =>
            console.error(
                `[offline-sync ${this.serviceName}] Error setting the lastAccessed timestamp of found records.`,
                foundRecords,
                error,
            ),
        );

        this.logTime(startTime, timerLabel, foundRecords.length);
        return foundRecords;
    }

    public async findLocal(feathersQuery: FeathersQuery = {}): Promise<DataType[]> {
        const timerLabel = this.generateTimerLabel('findLocal');
        const startTime = performance.now();

        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        let records: DataType[];

        // Execute search on quick search records for better performance.
        if (this.supportsQuickSearch && this.canQueryUseQuickSearch(feathersQuery)) {
            this.isDebuggingEnabled() && console.log(`[offline-sync ${this.serviceName}] Using quick search.`);
            const quickSearchRecords: QuickSearchRecord[] = await db.getAll('quickSearchRecords');
            const filteredQuickSearchRecords: QuickSearchRecord[] = applyMongoQuery(
                feathersQuery,
                quickSearchRecords,
                this.get$SearchMongoQuery,
            );

            // Based on the IDs of the quick search records, fetch the real records.
            const transaction = await db.transaction(['records'], 'readonly');
            records = await Promise.all([
                ...filteredQuickSearchRecords.map((quickSearchRecord) =>
                    transaction.objectStore('records').get(quickSearchRecord._id),
                ),
            ]);
        }
        // No quick search available -> Get all records which may be slow.
        else {
            this.isDebuggingEnabled() &&
                console.log(
                    `🐌 [offline-sync ${this.serviceName}] Doing full objectStore scan. Query: `,
                    feathersQuery,
                );
            records = await db.getAll('records');
            records = applyMongoQuery(feathersQuery, records, this.get$SearchMongoQuery);
        }

        this.logTime(startTime, timerLabel, records.length);

        /**
         * The last accessed date is used when deciding which data to eject when storage space becomes scarce.
         */
        this.setBatchLastAccessed(records.map((record) => record._id)).catch((error) =>
            console.error(
                `[offline-sync ${this.serviceName}] Error setting the lastAccessed timestamp of found records.`,
                records,
                error,
            ),
        );

        return records;
    }

    public async findLocalByKeyRange(keyRange: IDBKeyRange): Promise<DataType[]> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        return db.getAll('records', keyRange);
    }

    /**
     * Find all records the ID of which starts with a given prefix.
     * Useful for finding all records whose ID is composed of the report ID and another record ID, e.g. photo files.
     */
    public async findLocalByPrefix(prefix: string): Promise<DataType[]> {
        /**
         * "\uffff" is the last character in the UTF-8 charset, so it defines the last possible character that may follow the prefix.
         * Source: https://stackoverflow.com/a/20917631/1027464
         */
        return this.findLocalByKeyRange(IDBKeyRange.bound(prefix, prefix + '\uffff'));
    }

    public async findKeysFromIndexByKeyRange(
        keyRange: IDBKeyRange,
        indexName: IndexNames<DatabaseIndexeddbStructure<DataType>, 'records'>,
    ): Promise<DataType['_id'][]> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        return db.getAllKeysFromIndex('records', indexName, keyRange);
    }

    /**
     * Find all records the ID of which starts with a given prefix.
     * Useful for finding all records whose ID is composed of the report ID and another record ID, e.g. photo files.
     */
    public async findKeysFromIndexByPrefix(
        prefix: string,
        indexName: IndexNames<DatabaseIndexeddbStructure<DataType>, 'records'>,
    ): Promise<DataType['_id'][]> {
        /**
         * "\uffff" is the last character in the UTF-8 charset, so it defines the last possible character that may follow the prefix.
         * Source: https://stackoverflow.com/a/20917631/1027464
         */
        return this.findKeysFromIndexByKeyRange(IDBKeyRange.bound(prefix, prefix + '\uffff'), indexName);
    }

    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    public get$SearchMongoQuery(searchTerm: string): [string, any] {
        throw new Error(
            `Implementation of get$SearchMongoQuery() is missing for service "${this.serviceName}". Each service needs to overwrite this method if the service should support $search. Used search query: ` +
                searchTerm,
        );
    }

    //*****************************************************************************
    //  Validate FeathersQuery Against QuickSearchRecords
    //****************************************************************************/
    /**
     * Returns false and warns the developer if there are query constellations that the quickSearchRecord of this service cannot fulfill.
     * @param feathersQuery
     * @private
     */
    private canQueryUseQuickSearch(feathersQuery: FeathersQuery): boolean {
        const query = JSON.parse(JSON.stringify(feathersQuery));

        //*****************************************************************************
        //  Determine What's Queried
        //****************************************************************************/
        const queriedProperties: string[] = [];

        if (query.$sort) {
            for (const key of Object.keys(query.$sort)) {
                queriedProperties.push(key);
            }
        }

        if (query.$search) {
            for (const key of Object.keys(query.$search)) {
                queriedProperties.push(key);
            }
        }

        delete query.$sort;
        delete query.$limit;
        delete query.$skip;
        delete query.$search;

        // The remaining properties are regular query parameters.
        for (const key of Object.keys(query)) {
            queriedProperties.push(key);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Determine What's Queried
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Compare With Observed Keys
        //****************************************************************************/
        const unobservedKeys: string[] = queriedProperties.filter(
            (queriedProperty) => !this.keysOfQuickSearchRecords.includes(queriedProperty),
        );
        if (unobservedKeys.length) {
            console.warn(
                `[offline-sync ${this.serviceName}] Some keys are queried but not included in quickSearchRecords. Consider adding them to 'keysOfQuickSearchRecords'`,
                { unobservedKeys, feathersQuery },
            );
            return false;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Compare With Observed Keys
        /////////////////////////////////////////////////////////////////////////////*/
        return true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Validate FeathersQuery Against QuickSearchRecords
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Inserts a changelist with deep paths (MongoDB style) into a record and returns the full record.
     * Used for patching local records after receiving external put events. To reduce the payload sent
     * over the wire, those events contain deep paths instead of the full object.
     * @param record
     * @param source
     */
    public async patchLocal(record: PartialWithId<DataType>, source: IndexeddbEventSource): Promise<DataType> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        // Getting the local records also sets its last accessed time. No need to set it again here.
        // await this.setLastAccessed(recordId);
        const transaction = await indexeddbConnection.transaction(['records', 'serverShadows'], 'readonly');
        const [localRecord, serverShadow] = await Promise.all([
            transaction.objectStore('records').get(record._id),
            transaction.objectStore('serverShadows').get(record._id),
        ]);

        if (!localRecord) {
            throw new Error('Local record not found. patchLocal cannot be executed.');
        }

        const listOfDifferences: ObjectDifference[] = getListOfDifferencesFromFlattenedChangePaths(
            flattenChangePaths(record),
        );
        const patchedRecord: DataType = insertChangesIntoRecord(localRecord, listOfDifferences);

        // Write record back to DB.
        try {
            const transaction = await indexeddbConnection.transaction(['records', 'quickSearchRecords'], 'readwrite');
            await Promise.all([
                transaction.objectStore('records').put(patchedRecord),
                // Only write quickSearchRecord if this services supports it.
                this.supportsQuickSearch
                    ? transaction
                          .objectStore('quickSearchRecords')
                          .put(boilDownToQuickRecord(patchedRecord, this.keysOfQuickSearchRecords))
                    : null,
                /**
                 * Wait for the transaction to be done before emitting events like this.patchedFromExternalServer$ below. This prevents this issue:
                 * - Update 1 in tab 1 is triggered because the user leaves an input autocomplete because he clicks on an autocomplete entry.
                 * - Update 2 in tab 1 is triggered because the user clicks the autocomplete entry.
                 * - Update 1 in tab 2 is received via Broadcast Channel and compared to the old IndexedDB record --> Update delta is calculated correctly.
                 * - Update 2 in tab 2 is received via Broadcast Channel and compared to the old IndexedDB record but the newly updated record in the component (three-way merge) --> Update delta sees a conflicting change which is not applied.
                 * - Only now the transaction in the IndexedDB completes.
                 *
                 * Awaiting the transaction.done promise emits a Broadcast Event only after the IndexedDB change was committed. Therefore, no conflicting changes arise and all changes are applied correctly.
                 */
                transaction.done,
            ]);
        } catch (error) {
            await StorageSpaceManager.checkNeedForDataEjection({ error });
            throw error;
        }

        /**
         * If the record has been patched on this device/tab, make a 'deleted' metadata record. This will update this record
         * on the server on next sync. If the put came from another user/device, the source will be "external", so no need
         * to put this record to the server again.
         */
        if (source === 'localUser') {
            try {
                // Create metadata object that marks the record as patched.
                await indexeddbConnection.put('patched', { _id: patchedRecord._id });
            } catch (error) {
                await StorageSpaceManager.checkNeedForDataEjection({ error });
                throw error;
            }
        }

        // Notify our local listeners of external updates, e.g. through sockets from another device/the autoiXpert servers.
        if (source === 'externalServer') {
            this.patchedFromExternalServer$.next({ serverShadow, patchedRecord, eventSource: source });
        } else {
            this.patchedFromLocalUserInThisTab$.next({ serverShadow, patchedRecord, eventSource: source });
        }

        return localRecord;
    }

    /**
     * Replaces the full record locally. Used for setting records from a component where a delta patch object is usually not
     * available.
     *
     * Caution: The record parameter may be both a local object (source === local) or an external object. When merging, one
     * must pay attention to the fact that the local record emitted here may the strictly same records as held in the component.
     * @param record
     * @param source
     */
    public async putLocal(record: DataType, source: IndexeddbEventSource): Promise<DataType> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const timerLabel = this.generateTimerLabel('putLocal transaction');
        const startTime = performance.now();

        // Write record back to DB.
        try {
            const transaction = await indexeddbConnection.transaction(['records', 'quickSearchRecords'], 'readwrite');
            await Promise.all([
                transaction.objectStore('records').put(record),
                // Only write quickSearchRecord if this services supports it.
                this.supportsQuickSearch
                    ? transaction
                          .objectStore('quickSearchRecords')
                          .put(boilDownToQuickRecord(record, this.keysOfQuickSearchRecords))
                    : null,
                /**
                 * Wait for the transaction to be done before emitting events like this.patchedFromExternalServer$ below. This prevents this issue:
                 * - Update 1 in tab 1 is triggered because the user leaves an input autocomplete because he clicks on an autocomplete entry.
                 * - Update 2 in tab 1 is triggered because the user clicks the autocomplete entry.
                 * - Update 1 in tab 2 is received via Broadcast Channel and compared to the old IndexedDB record --> Update delta is calculated correctly.
                 * - Update 2 in tab 2 is received via Broadcast Channel and compared to the old IndexedDB record but the newly updated record in the component (three-way merge) --> Update delta sees a conflicting change which is not applied.
                 * - Only now the transaction in the IndexedDB completes.
                 *
                 * Awaiting the transaction.done promise emits a Broadcast Event only after the IndexedDB change was committed. Therefore, no conflicting changes arise and all changes are applied correctly.
                 */
                transaction.done,
            ]);
            this.logTime(startTime, timerLabel, 0);
        } catch (error) {
            await StorageSpaceManager.checkNeedForDataEjection({ error });
            throw error;
        }

        const serverShadow: DataType = await indexeddbConnection.get('serverShadows', record._id);

        /**
         * If the record has been patched on this device/tab, make a 'patched' metadata record. This will update this record
         * on the server on next sync. If the put came from another user/device, the source will be "external", so no need
         * to put this record to the server again.
         */
        switch (source) {
            case 'localUser': {
                try {
                    // Create metadata object that marks the record as patched.
                    await indexeddbConnection.put('patched', { _id: record._id });
                } catch (error) {
                    await StorageSpaceManager.checkNeedForDataEjection({ error });
                    throw error;
                }
                this.logTime(startTime, timerLabel, 0);
                this.patchedFromLocalUserInThisTab$.next({ serverShadow, patchedRecord: record, eventSource: source });
                break;
            }
            // Notify our local listeners of external updates, e.g. through sockets from another device/the autoiXpert servers.
            case 'externalServer': {
                this.patchedFromExternalServer$.next({ serverShadow, patchedRecord: record, eventSource: source });
                break;
            }
            case 'localBroadcastChannel': {
                this.logTime(startTime, timerLabel, 0);
                this.patchedFromLocalUserInThisTab$.next({ serverShadow, patchedRecord: record, eventSource: source });
                break;
            }
            // Merge: Mark as patched locally and emit a server change event to update components with the merge result.
            case 'merge': {
                try {
                    // Create metadata object that marks the record as patched.
                    await indexeddbConnection.put('patched', { _id: record._id });
                } catch (error) {
                    await StorageSpaceManager.checkNeedForDataEjection({ error });
                    throw error;
                }
                this.patchedFromExternalServer$.next({ serverShadow, patchedRecord: record, eventSource: source });
                break;
            }
        }

        return record;
    }

    public async deleteLocal(recordId: DataType['_id'], source?: IndexeddbEventSource): Promise<void>;
    public async deleteLocal(recordIds: DataType['_id'][], source?: IndexeddbEventSource): Promise<void>;
    public async deleteLocal(
        recordIds: DataType['_id'] | DataType['_id'][],
        source: IndexeddbEventSource = 'localUser',
    ): Promise<void> {
        if (!Array.isArray(recordIds)) {
            recordIds = [recordIds];
        }

        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const transaction = indexeddbConnection.transaction(
            ['created', 'patched', 'deleted', 'records', 'lastAccessed', 'quickSearchRecords'],
            'readwrite',
        );

        for (const recordId of recordIds) {
            const recordExistsLocallyOnly: boolean = !!(await transaction.objectStore('created').get(recordId));
            if (recordExistsLocallyOnly) {
                // Local-only records are simply purged. No record change will be saved in order to update the server because the record does not exist there.
                await transaction.objectStore('created').delete(recordId);
                await transaction.objectStore('patched').delete(recordId);
            } else {
                // If the record has been deleted on this device/tab, make a 'deleted' metadata record. This will delete this record
                // on the server on next sync.
                if (source === 'localUser') {
                    await transaction.objectStore('deleted').put({ _id: recordId });
                }
            }
            await transaction
                .objectStore('records')
                .delete(recordId)
                .catch((error) => {
                    console.error(
                        `[offline-sync ${this.serviceName}] Error deleting record in "deleteLocal".`,
                        error,
                        recordId,
                    );
                    throw error;
                });
            await transaction.objectStore('patched').delete(recordId);
            // Only delete quickSearchRecord if this services supports it.
            if (this.supportsQuickSearch) {
                await transaction.objectStore('quickSearchRecords').delete(recordId);
            }
            await this.deleteLastAccessed(recordId, transaction);

            /**
             * Use optimistic deletion instead of waiting for the transaction to complete for maximum performance.
             *
             * Notify our local listeners of external updates, e.g. through sockets from another device/the autoiXpert servers.
             */
            if (source === 'externalServer') {
                this.deletedFromExternalServer$.next(recordId);
            } else {
                this.deletedFromLocalUserInThisTab$.next(recordId);
            }
        }

        await transaction.done;
    }

    /**
     * If a user restores an accidentally deleted records, this function allows restoring it.
     * Simply recreating it would cause a server error (Conflict - record exists) on the next sync.
     *
     * Example: TODO write example
     *
     */
    public async undeleteLocal(record: DataType): Promise<DataType> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        /**
         * Scenario 1:
         * Record existed on server during last sync. Now deleted and undeleted offline. The change record in "deleted" exists and needs to be removed.
         *
         * Scenario 2:
         * Record created offline and deleted offline. Then, neither the record nor any change records exist - neither on this device nor on the server. Add record anew
         * so that it will be synced to the server.
         */
        const recordExistsOnServer: boolean = !!(await indexeddbConnection.get('deleted', record._id));

        if (recordExistsOnServer) {
            await indexeddbConnection.delete('deleted', record._id);
            /**
             * The record needs to be recreated locally but not on the server because it already exists on the server. Consider this an external change so that the
             * record is not created on the server again (which would cause a conflict error).
             */
            await this.createLocal(record, 'externalServer');
        } else {
            /**
             * Create the record locally and have it synced to the server when possible.
             */
            await this.createLocal(record, 'localUser');
        }

        return record;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local CRUD Operations (IndexedDB)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Local Changes
    //****************************************************************************/
    /**
     * Write
     */
    public async setCreationChangeRecord(recordId: DataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.put('created', { _id: recordId });
    }

    public async setPatchChangeRecord(recordId: DataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.put('patched', { _id: recordId });
    }

    public async setDeletionChangeRecord(recordId: DataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.put('deleted', { _id: recordId });
    }

    /**
     * Read
     */
    public async getCreationChangeRecords(): Promise<ChangeRecord[]> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        return await indexeddbConnection.getAll('created');
    }

    public async getPatchChangeRecords(): Promise<ChangeRecord[]> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        return await indexeddbConnection.getAll('patched');
    }

    public async getDeletionChangeRecords(): Promise<ChangeRecord[]> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        return await indexeddbConnection.getAll('deleted');
    }

    /**
     * Delete
     */
    public async deleteCreationChangeRecord(recordId: DataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.delete('created', recordId);
    }

    public async deletePatchChangeRecord(recordId: DataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.delete('patched', recordId);
    }

    public async deleteDeletionChangeRecord(recordId: DataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.delete('deleted', recordId);
    }

    /**
     * Use this in case the status of this local record matters for dealing with the server.
     * Example: Photos. Only when the original photo was uploaded, will its status change from "created" to "undefined". Then, the client
     *          may access the backend-autoixpert to have thumbnails generated (which is faster than for the client to download all original
     *          photos and render them itself).
     */
    public async getLocalStatus(recordId: DataType['_id']): Promise<LocalStatus> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const transaction = indexeddbConnection.transaction(['created', 'patched', 'deleted'], 'readonly');

        const [recordWasCreatedLocally, recordWasPatchedLocally, recordWasDeletedLocally]: boolean[] = (
            await Promise.all([
                transaction.objectStore('created').get(recordId),
                transaction.objectStore('patched').get(recordId),
                transaction.objectStore('deleted').get(recordId),
            ])
        ).map(Boolean);

        if (recordWasCreatedLocally) {
            return 'created';
        } else if (recordWasPatchedLocally) {
            return 'patched';
        } else if (recordWasDeletedLocally) {
            return 'deleted';
        }
        return undefined;
    }

    /**
     * Has a record been created, changed or deleted locally?
     *
     * Returns a map with the recordID as key and the status as value. If the record hasn't been modified, the map won't contain an entry.
     * @param recordIds
     */
    public async getLocalStatusOfManyRecords(recordIds: DataType['_id'][]): Promise<Map<DataType['_id'], LocalStatus>> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const transaction = indexeddbConnection.transaction(['created', 'patched', 'deleted'], 'readonly');

        const localStatusMap: Map<DataType['_id'], LocalStatus> = new Map();

        for (const recordId of recordIds) {
            const [recordWasCreatedLocally, recordWasPatchedLocally, recordWasDeletedLocally]: boolean[] = (
                await Promise.all([
                    transaction.objectStore('created').get(recordId),
                    transaction.objectStore('patched').get(recordId),
                    transaction.objectStore('deleted').get(recordId),
                ])
            ).map(Boolean);

            if (recordWasCreatedLocally) {
                localStatusMap.set(recordId, 'created');
            } else if (recordWasPatchedLocally) {
                localStatusMap.set(recordId, 'patched');
            } else if (recordWasDeletedLocally) {
                localStatusMap.set(recordId, 'deleted');
            }
        }

        return localStatusMap;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Local Changes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Reset Local
    //****************************************************************************/
    /**
     * If the user wants to discard his local changes, replace the local record with the server shadow (= version on the server).
     */
    public async resetLocal(recordId: DataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = indexeddbConnection.transaction(
            ['created', 'patched', 'deleted', 'records', 'serverShadows', 'lastAccessed'],
            'readwrite',
        );

        // Depending on what update state the record was in, we emit different events.
        const recordWasCreatedLocally: boolean = !!(await transaction.objectStore('created').get(recordId));
        const recordWasPatchedLocally: boolean = !!(await transaction.objectStore('patched').get(recordId));
        const recordWasDeletedLocally: boolean = !!(await transaction.objectStore('deleted').get(recordId));
        const record: DataType = await transaction.objectStore('records').get(recordId);
        const serverShadow: DataType = await transaction.objectStore('serverShadows').get(recordId);

        // Emit events based on the update state of the record.
        // Local only -> Remove the record altogether. Let the components know the record has been deleted.
        if (recordWasCreatedLocally) {
            await transaction.objectStore('records').delete(recordId);
            // This is *like* a change from an external server because changes need to be propagated locally (this tab and other tabs) but need not be synced to the server.
            this.deletedFromExternalServer$.next(recordId);
        } else {
            if (recordWasDeletedLocally) {
                this.createdFromExternalServer$.next(record);
            }
            // Only patched -> A server shadow must be present. Send the server state to the listening components, so they know about the reset.
            else if (recordWasPatchedLocally) {
                // This should not be possible
                if (!serverShadow) {
                    throw new AxError({
                        code: 'RESETTING_LOCAL_RECORD_FAILED_DUE_TO_MISSING_SERVER_SHADOW',
                        message:
                            'When the user reset a local record after it created sync issues, resetting failed due to a missing server shadow.',
                        data: {
                            record,
                            recordId,
                            serverShadow,
                        },
                    });
                }

                /**
                 * This is *like* an external change since this record is reset to the state of the server. User changes are intended to be overwritten.
                 * Pretend that the serverShadow is the target state and the current state is the record to base changes off.
                 * This forces the listening component to reset all changed properties.
                 */
                this.patchedFromExternalServer$.next({
                    serverShadow: record,
                    patchedRecord: serverShadow,
                    eventSource: 'externalServer',
                });
            }
            // Record exists on the server. Set the server version as the current version.
            if (serverShadow) {
                await transaction.objectStore('records').put(serverShadow);
            }
        }

        // Delete any meta records that a change has happened.
        await transaction.objectStore('created').delete(recordId);
        await transaction.objectStore('patched').delete(recordId);
        await transaction.objectStore('deleted').delete(recordId);

        //noinspection ES6MissingAwait -> We don't need to wait for the lastAccessed to be written.
        await this.setLastAccessed(recordId, transaction);

        await transaction.done;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reset Local
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Last Access
    //****************************************************************************/
    /**
     * To know which records have been used the least (it's been the longest time ago),
     * we must record the timestamp when they were accessed.
     *
     * @param recordId
     * @param transaction
     * @private
     */
    private async setLastAccessed(
        recordId: DataType['_id'],
        transaction?: IDBPTransaction<DatabaseIndexeddbStructure<DataType>, any, 'readwrite'>,
    ): Promise<StoreKey<IDBPTransaction<DatabaseIndexeddbStructure<DataType>>, 'lastAccessed'>> {
        try {
            const putValue = {
                _id: recordId,
                lastAccessedAt: DateTime.now().toISO(),
            };
            if (transaction) {
                return transaction.objectStore('lastAccessed').put(putValue);
            } else {
                const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
                return indexeddbConnection.put('lastAccessed', putValue);
            }
        } catch (error) {
            await StorageSpaceManager.checkNeedForDataEjection({ error });
            throw error;
        }
    }

    private async setBatchLastAccessed(recordIds: DataType['_id'][]) {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = indexeddbConnection.transaction('lastAccessed', 'readwrite');
        for (const recordId of recordIds) {
            try {
                await transaction.objectStore('lastAccessed').put({
                    _id: recordId,
                    lastAccessedAt: DateTime.now().toISO(),
                });
            } catch (error) {
                await StorageSpaceManager.checkNeedForDataEjection({ error });
                throw error;
            }
        }
    }

    private async deleteLastAccessed(
        recordId: DataType['_id'],
        transaction: IDBPTransaction<DatabaseIndexeddbStructure<DataType>, any, 'readwrite'>,
    ) {
        await transaction.objectStore('lastAccessed').delete(recordId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Last Access
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Shadow
    //****************************************************************************/
    /**
     * Set the serverShadow in the local database.
     * @param serverShadow
     */
    public async putServerShadow(serverShadow: DataType): Promise<DataType[]>;
    public async putServerShadow(serverShadows: DataType[]): Promise<DataType[]>;
    public async putServerShadow(serverShadows: DataType | DataType[]): Promise<DataType | DataType[]> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        let inputWasArray = true;
        if (!Array.isArray(serverShadows)) {
            serverShadows = [serverShadows];
            inputWasArray = false;
        }

        /**
         * Use a single transaction to speed up the full sync mechanism.
         */
        const transaction = indexeddbConnection.transaction(['serverShadows'], 'readwrite');

        for (const serverShadow of serverShadows) {
            transaction
                .objectStore('serverShadows')
                .put(serverShadow)
                .catch(async (error) => {
                    await StorageSpaceManager.checkNeedForDataEjection({ error });
                    console.error(
                        `[offline-sync ${this.serviceName}] Error creating serverShadow in "putServerShadow".`,
                        error,
                        serverShadow,
                    );
                    throw error;
                });
        }

        // This is the signal that everything was successfully committed to the database.
        await transaction.done;

        if (inputWasArray) {
            return serverShadows;
        } else {
            return serverShadows[0];
        }
    }

    public async getServerShadow(serverShadowId: DataType['_id']): Promise<DataType | undefined> {
        return await (this.indexeddbConnection || (await this.indexeddbConnectionPromise)).get(
            'serverShadows',
            serverShadowId,
        );
    }

    public async deleteServerShadow(serverShadowId: DataType['_id']): Promise<void> {
        return await (this.indexeddbConnection || (await this.indexeddbConnectionPromise)).delete(
            'serverShadows',
            serverShadowId,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Shadow
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tab Sync via Local Broadcast
    //****************************************************************************/
    private registerTabSyncEventListenersAndPublishers() {
        /**
         * Listen for changes coming from the other tabs on this machine and propagate events to other tabs.
         */
        this.registerTabSyncCreateListenerAndPublisher();
        this.registerTabSyncPatchListenerAndPublisher();
        this.registerTabSyncDeleteListenerAndPublisher();
    }

    private registerTabSyncCreateListenerAndPublisher() {
        this.offlineTabSyncCreateChannel.addEventListener('message', (messageEvent: MessageEvent<DataType>) => {
            this.isDebuggingEnabled() &&
                console.log(
                    `[offline-sync ${this.serviceName}] Created event received via BroadcastChannel.`,
                    messageEvent.data,
                );
            // Emit subject value to local components so that they can update their data accordingly.
            this.createdFromLocalBroadcast$.next(messageEvent.data);
        });

        /**
         * Propagate new records from the server (e.g. user pulled changes actively or received via Websockets) or that the user
         * created in this tab to all other tabs on this device.
         */
        merge<DataType>(this.createdFromExternalServer$, this.createdFromLocalUserInThisTab$).subscribe({
            next: (record) => {
                this.offlineTabSyncCreateChannel.postMessage(record);
            },
        });
    }

    private registerTabSyncPatchListenerAndPublisher() {
        this.offlineTabSyncPatchChannel.addEventListener(
            'message',
            async (messageEvent: MessageEvent<PatchedEvent<DataType>>) => {
                const listOfDifferences: ObjectDifference[] = getListOfDifferences(
                    messageEvent.data.serverShadow,
                    messageEvent.data.patchedRecord,
                );
                const documentVersionDifference = listOfDifferences.find(
                    (difference) => difference.key === '_documentVersion',
                );
                if (documentVersionDifference) {
                    this.isDebuggingEnabled() &&
                        console.log(
                            `[offline-sync ${this.serviceName}] "patched" event received via BroadcastChannel to update the _documentVersion during pushToServer from ${documentVersionDifference.oldValue} to ${documentVersionDifference.newValue}`,
                            listOfDifferences,
                            messageEvent.data,
                        );
                } else {
                    this.isDebuggingEnabled() &&
                        console.log(
                            `[offline-sync ${this.serviceName}] "patched" event received via BroadcastChannel`,
                            listOfDifferences,
                            messageEvent.data,
                        );
                }
                this.patchedFromLocalBroadcast$.next({
                    ...messageEvent.data,
                    eventSource: 'localBroadcastChannel',
                } as PatchedEvent<DataType>);
            },
        );

        /**
         * Propagate changes from the server (e.g. user pulled changes actively or received via Websockets) or that the user
         * created in this tab to all other tabs on this device.
         */
        merge<PatchedEvent<DataType>>(this.patchedFromExternalServer$, this.patchedFromLocalUserInThisTab$).subscribe({
            next: (patchedEvent: PatchedEvent<DataType>) => {
                this.offlineTabSyncPatchChannel.postMessage(patchedEvent);
            },
        });
    }

    private registerTabSyncDeleteListenerAndPublisher() {
        this.offlineTabSyncDeleteChannel.addEventListener('message', (messageEvent: MessageEvent<DataType['_id']>) => {
            this.isDebuggingEnabled() &&
                console.log(
                    `[offline-sync ${this.serviceName}] Deleted event received via BroadcastChannel.`,
                    messageEvent.data,
                );
            this.deletedFromLocalBroadcast$.next(messageEvent.data);
        });

        /**
         * Propagate deletions from the server (e.g. user pulled changes actively or received via Websockets) or that the user
         * caused in this tab to all other tabs on this device.
         */
        merge<DataType>(this.deletedFromExternalServer$, this.deletedFromLocalUserInThisTab$).subscribe({
            next: (record) => {
                this.offlineTabSyncDeleteChannel.postMessage(record);
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tab Sync via Local Broadcast
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Database Size
    //****************************************************************************/
    public async getDatabaseSize(): Promise<number> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        let databaseSizeInBytes: number = 0;

        /**
         * Count the size of the content of all object stores in this database.
         * There is one database per DatabaseService (e.g. "reports", "contactPeople", ...).
         */
        for (const objectStoreName of db.objectStoreNames) {
            try {
                let cursor = await db.transaction(objectStoreName, 'readonly').store.openCursor();

                while (cursor) {
                    databaseSizeInBytes += JSON.stringify(cursor.value).length;
                    cursor = await cursor.continue();
                }
            } catch (error) {
                console.error(
                    `[offline-sync ${this.serviceName}] Getting the size of the object store "${objectStoreName}" failed.`,
                    { error },
                );
            }
        }

        return databaseSizeInBytes;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Database Size
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Eject Old Data
    //****************************************************************************/
    async ejectData(lastAccessedBefore?: DateTime) {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(
            ['created', 'patched', 'deleted', 'records', 'serverShadows', 'lastAccessed'],
            'readwrite',
        );
        let cursor = await transaction.objectStore('records').openCursor();

        while (cursor) {
            const record = cursor.value;
            /**
             * Check if the record was last accessed before the time threshold. No threshold means that all records should be deleted
             * if they don't have outstanding syncs to the server.
             */
            const lastAccessedRecord = await transaction.objectStore('lastAccessed').get(record._id);
            const lastAccessedAt = lastAccessedRecord
                ? DateTime.fromISO(lastAccessedRecord?.lastAccessedAt)
                : undefined;
            const lastAccessedBeforeThreshold =
                !lastAccessedAt || !lastAccessedBefore || lastAccessedAt < lastAccessedBefore;

            /**
             * Check if all changes were synced to the server. No local changes should be deleted because of storage space issues
             * if they have not yet been synced to the server. Otherwise, useful data that the user entered while being offline
             * might get lost.
             */
            const hasCreatedEntry = await transaction.objectStore('created').get(record._id);
            const hasPatchedEntry = await transaction.objectStore('patched').get(record._id);
            const hasDeletedEntry = await transaction.objectStore('deleted').get(record._id);
            const hasOutstandingSyncs = hasCreatedEntry || hasPatchedEntry || hasDeletedEntry;

            if (lastAccessedBeforeThreshold && !hasOutstandingSyncs) {
                await transaction.objectStore('created').delete(record._id);
                await transaction.objectStore('patched').delete(record._id);
                await transaction.objectStore('deleted').delete(record._id);
                await transaction.objectStore('records').delete(record._id);
                await transaction.objectStore('serverShadows').delete(record._id);
                await transaction.objectStore('lastAccessed').delete(record._id);
            }

            cursor = await cursor.continue();
        }

        /**
         * Since records were removed, this client is not fully synced. Next time a full sync is triggered, all data needs to be read from
         * the server.
         */
        const databaseInfo = await this.getDatabaseInfo();
        databaseInfo.lastFullSyncAt = undefined;
        await this.setDatabaseInfo(databaseInfo);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Eject Old Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invalidate Cache
    //****************************************************************************/
    public async clearObjectStores() {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(db.objectStoreNames, 'readwrite');
        // Remove content from all object stores
        await Promise.allSettled(
            [...db.objectStoreNames].map((objectStoreName) =>
                transaction
                    .objectStore(objectStoreName)
                    .clear()
                    .catch((error) =>
                        console.error(
                            `[offline-sync ${this.serviceName}] Clearing the object store ${objectStoreName} while invalidating the cache failed.`,
                            { error },
                        ),
                    ),
            ),
        );
    }

    public async getNumberOfOutstandingSyncs(): Promise<number> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(['created', 'patched', 'deleted'], 'readonly');
        /**
         * Check if there are any changes that have not yet been synced to the server.
         * This check is relevant for when the user logs out. He may choose between discarding these unsynced items, too, or staying logged in.
         */
        const [numberOfCreatedEntries, numberOfPatchedEntries, numberOfDeletedEntries]: number[] = await Promise.all([
            transaction.objectStore('created').count(),
            transaction.objectStore('patched').count(),
            transaction.objectStore('deleted').count(),
        ]);
        return numberOfCreatedEntries + numberOfPatchedEntries + numberOfDeletedEntries;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invalidate Cache
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Migration
    //****************************************************************************/
    public getHighestMigrationVersionNumber(): number {
        const migrationVersions: number[] = Object.keys(this.recordMigrations).map((keyString) => +keyString);
        return migrationVersions.sort((a, b) => a - b).pop() || 0;
    }

    public async updateRecordSchemas() {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(['records', 'serverShadows'], 'readwrite');

        // Get all records with an outdated schema version. These will be updated.
        let cursor = await transaction
            .objectStore('records')
            .index('schema-version')
            .openCursor(IDBKeyRange.upperBound(this.getHighestMigrationVersionNumber(), true));

        while (cursor) {
            let record = cursor.value;
            let serverShadow = await transaction.objectStore('serverShadows').get(record._id);

            // Execute all recordMigrations for this record until the newest schema version is reached.
            while (this.recordMigrations[record._schemaVersion + 1]) {
                const targetVersionMigration = record._schemaVersion + 1;

                try {
                    const migrationReturnValue = await this.recordMigrations[targetVersionMigration](record);
                    // The migration may return the new record, or it may return nothing and modify the existing record.
                    if (migrationReturnValue) {
                        record = migrationReturnValue;
                    }
                    record._schemaVersion = targetVersionMigration;
                } catch (error) {
                    console.error(
                        `[offline-sync ${this.serviceName}] Migrating a record in the local database failed.`,
                        {
                            oldVersion: record._schemaVersion,
                            newVersion: targetVersionMigration,
                            error,
                            recordMigrations: this.recordMigrations,
                        },
                    );
                    // TODO Maybe relax this in production.
                    throw new Error('Migrating a record in the local database failed.');
                }

                /**
                 * The server shadow may not be present if this is a record that still must be created on the server. No need to migrate
                 * a non-existing server shadow then.
                 */
                if (serverShadow) {
                    try {
                        const migrationReturnValue = await this.recordMigrations[targetVersionMigration](serverShadow);
                        // The migration may return the new record, or it may return nothing and modify the existing record.
                        if (migrationReturnValue) {
                            serverShadow = migrationReturnValue;
                        }
                        serverShadow._schemaVersion = targetVersionMigration;
                    } catch (error) {
                        throw new AxError({
                            code: 'SERVER_SHADOW_RECORD_MIGRATION_FAILED',
                            message: 'Migrating a server shadow in the local database failed.',
                            data: {
                                oldVersion: serverShadow._schemaVersion,
                                newVersion: targetVersionMigration,
                                error,
                                recordMigrations: this.recordMigrations,
                            },
                        });
                    }
                }
            }

            await transaction.objectStore('records').put(record);
            if (serverShadow) {
                await transaction.objectStore('serverShadows').put(serverShadow);
            }

            cursor = await cursor.continue();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Migration
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Debugging
    //****************************************************************************/
    private generateTimerLabel(methodName: string): string {
        const uniqueId: string = `${Math.round(Math.random() * 1_000)
            .toString()
            .padStart(3)}`;
        return `[offline-sync ${this.serviceName}] [${uniqueId}] ${methodName}`;
    }

    /**
     * Log the amount of time passed and the number of records found for a record query. Used to measure performance of IndexedDB.
     * @param startTime
     * @param timerLabel
     * @param numberOfRecords
     * @private
     */
    private logTime(startTime: number, timerLabel: string, numberOfRecords: number): void {
        // Log to console if debugging is enabled.
        if (this.isDebuggingEnabled()) {
            const passedDuration: number = Math.round(performance.now() - startTime);
            console.log(`${timerLabel} ${passedDuration}ms - ${numberOfRecords} records`);
        }
    }

    private isDebuggingEnabled(): boolean {
        return (
            (window as any).offlineSyncDebugging === '*' ||
            (window as any).offlineSyncDebugging?.includes?.(this.serviceName)
        );
    }

    public async dumpData(): Promise<AxIndexeddbDump> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(db.objectStoreNames, 'readonly');

        const axIndexeddbDump: AxIndexeddbDump = {};

        // Remove content from all object stores
        for (const objectStoreName of db.objectStoreNames) {
            try {
                const objectStoreKeys = await transaction.objectStore(objectStoreName).getAllKeys();
                const objectStoreContents = await transaction.objectStore(objectStoreName).getAll();

                const dumpRecords: AxIndexeddbDumpRecords = {};
                for (let i = 0; i < objectStoreKeys.length; i++) {
                    const key = objectStoreKeys[i];
                    dumpRecords[key] = objectStoreContents[i];
                }
                axIndexeddbDump[objectStoreName] = dumpRecords;
            } catch (error) {
                console.error(
                    `[offline-sync ${this.serviceName}] Dumping the object store ${objectStoreName} for debugging failed.`,
                    { error },
                );
            }
        }
        return axIndexeddbDump;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Debugging
    /////////////////////////////////////////////////////////////////////////////*/
}

export type LocalStatus = 'created' | 'patched' | 'deleted' | undefined;

<div id="customer-signatures-container" class="card card-with-padding" *ngIf="report">
    <h2>Unterschriften</h2>

    <!--********** Submenu **********-->
    <div id="customer-signatures-submenu" class="card-submenu">
        <!--********** Download Indicator **********-->
        <img
            id="declaration-of-assignment-loading-indicator"
            src="/assets/images/icons/loading-indicator.svg"
            *ngIf="signableDocumentDownloadPending"
            matTooltip="Abtretungserklärung wird geladen..." />

        <!-- Fee-set icon -->
        <div
            class="fee-set-icon-container"
            [matMenuTriggerFor]="feeSetMenu"
            (click)="getCustomFeeSets()"
            [ngSwitch]="report.feeCalculation.selectedFeeTable">
            <img *ngSwitchCase="'BVSK'" class="fee-table-logo" src="/assets/images/logos/bvsk-logo_square_360.png" />
            <img *ngSwitchCase="'HUK'" class="fee-table-logo" src="/assets/images/logos/insurances/huk-coburg.png" />
            <img *ngSwitchCase="'VKS'" class="fee-table-logo" src="/assets/images/logos/vks-logo_square_360.png" />
            <img *ngSwitchCase="'CGF'" class="fee-table-logo" src="/assets/images/logos/cgf-logo-small.png" />
            <mat-icon *ngSwitchCase="'custom'" class="fee-table-logo">table_view</mat-icon>
        </div>
        <!-- Fee-set sub-menu-->
        <mat-menu #feeSetMenu="matMenu">
            <!--********** BVSK **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="
                    selectFeeSet({
                        feeTableName: 'BVSK',
                        yearOfFeeTable: latestBvskTable.year,
                    })
                ">
                <img class="fee-table-logo-menu-item" src="/assets/images/logos/bvsk-logo_square_360.png" />
                <span class="fee-table-list-custom-name">BVSK</span>
                <mat-icon
                    [ngClass]="{
                        'fee-table-selected-icon': true,
                        active: report.feeCalculation.selectedFeeTable === 'BVSK',
                    }">
                    check
                </mat-icon>
            </a>

            <!--********** HUK **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="
                    selectFeeSet({
                        feeTableName: 'HUK',
                        yearOfFeeTable: latestHukTable.year,
                    })
                ">
                <img class="fee-table-logo-menu-item" src="/assets/images/logos/insurances/huk-coburg.png" />
                <span class="fee-table-list-custom-name">HUK-Coburg</span>
                <mat-icon
                    [ngClass]="{
                        'fee-table-selected-icon': true,
                        active: report.feeCalculation.selectedFeeTable === 'HUK',
                    }">
                    check
                </mat-icon>
            </a>

            <!--********** VKS **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="
                    selectFeeSet({
                        feeTableName: 'VKS',
                        yearOfFeeTable: latestVksTable.year,
                    })
                ">
                <img class="fee-table-logo-menu-item" src="/assets/images/logos/vks-logo_square_360.png" />
                <span class="fee-table-list-custom-name">VKS</span>
                <mat-icon
                    [ngClass]="{
                        'fee-table-selected-icon': true,
                        active: report.feeCalculation.selectedFeeTable === 'VKS',
                    }">
                    check
                </mat-icon>
            </a>

            <!--********** CGF (Caravan) **********-->
            <a
                mat-menu-item
                matTooltip="Caravaning Gutachter Fachverband e.V."
                class="menu-item-with-icon"
                (click)="
                    selectFeeSet({
                        feeTableName: 'CGF',
                        yearOfFeeTable: latestCgfTable.year,
                    })
                ">
                <img class="fee-table-logo-menu-item" src="/assets/images/logos/cgf-logo-small.png" />
                <span class="fee-table-list-custom-name">CGF</span>
                <mat-icon
                    [ngClass]="{
                        'fee-table-selected-icon': true,
                        active: report.feeCalculation.selectedFeeTable === 'CGF',
                    }">
                    check
                </mat-icon>
            </a>

            <!--********** EIGENE **********-->
            <ng-container *ngFor="let customFeeSet of customFeeSets">
                <a
                    *ngIf="customFeeSet.availableInReportTypes.includes(report.type)"
                    mat-menu-item
                    class="custom-fee-table-item menu-item-with-icon"
                    (click)="
                        selectFeeSet({
                            feeTableName: 'custom',
                            yearOfFeeTable: customFeeSet.year,
                            customFeeSet: customFeeSet,
                        })
                    ">
                    <mat-icon>table_view</mat-icon>
                    <span class="fee-table-list-custom-name">{{ customFeeSet.title }}</span>
                    <mat-icon
                        [ngClass]="{
                            'fee-table-selected-icon': true,
                            active:
                                report.feeCalculation.selectedFeeTable === 'custom' &&
                                report.feeCalculation.selectedCustomFeeTableId === customFeeSet._id,
                        }">
                        check
                    </mat-icon>
                </a>
            </ng-container>
        </mat-menu>

        <!-- Settings icon-->
        <mat-icon
            id="declaration-of-assignment-submenu-icon"
            class="toolbar-icon"
            [matMenuTriggerFor]="declarationOfAssignmentMenu">
            more_vert
        </mat-icon>
        <mat-menu #declarationOfAssignmentMenu="matMenu">
            <!--============================================-->
            <!-- Download Documents -->
            <!--============================================-->
            <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="downloadDocumentsSubmenu">
                <mat-icon>file_download</mat-icon>
                Dokument herunterladen
            </a>

            <mat-menu #downloadDocumentsSubmenu>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadSignableDocument('declarationOfAssignment')">
                    <mat-icon>file_download</mat-icon>
                    {{ translateDocumentTitle({ documentType: 'declarationOfAssignment' }) }}
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadSignableDocument('revocationInstruction')">
                    <mat-icon>file_download</mat-icon>
                    {{ translateDocumentTitle({ documentType: 'revocationInstruction' }) }}
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadSignableDocument('consentDataProtection')">
                    <mat-icon>file_download</mat-icon>
                    {{ translateDocumentTitle({ documentType: 'consentDataProtection' }) }}
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadSignableDocument('powerOfAttorney')"
                    *ngIf="shouldPowerOfAttorneyDocumentBeVisible()">
                    <mat-icon>file_download</mat-icon>
                    {{ translateDocumentTitle({ documentType: 'powerOfAttorney' }) }}
                </a>

                <!-- Custom documents -->
                <ng-container *ngFor="let customDocumentOrderConfig of customDocumentOrderConfigs; trackBy: trackById">
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="downloadSignableDocument('customDocument', customDocumentOrderConfig._id)">
                        <mat-icon>file_download</mat-icon>
                        {{ customDocumentOrderConfig.titleLong }}
                    </a>
                </ng-container>
            </mat-menu>
            <!--============================================-->
            <!-- END Download Documents -->
            <!--============================================-->

            <!--
            <a mat-menu-item class="menu-item-with-icon" (click)="openRemoteSignatureDialog()">
                <mat-icon>signature</mat-icon>
                Online unterschreiben lassen
            </a>
            -->

            <a mat-menu-item class="menu-item-with-icon" (click)="displayInfoToastForFileUpload()">
                <mat-icon>file_upload</mat-icon>
                Scan hochladen
            </a>

            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="rememberDocumentSettings()"
                [disabled]="!userIsAdmin()"
                matTooltip="Ausgewählte Dokumente & Factoring-Einstellung als Standard setzen">
                <mat-icon>auto_fix_high</mat-icon>
                Auswahl merken
            </a>

            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="areSomeDocumentsMissingSignatures()"
                (click)="transferCustomerSignatures()"
                [disabled]="isReportLocked()"
                matTooltip="Unterschrift des ersten Dokuments auf aktive Dokumente ohne Unterschrift übertragen.">
                <mat-icon>content_copy</mat-icon>
                Unterschrift übertragen
            </a>
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="user?.userInterfaceStates.transferMissingSignaturesNoticeClosed"
                (click)="
                    user.userInterfaceStates.transferMissingSignaturesNoticeClosed = false; saveUser(); saveReport()
                "
                [disabled]="isReportLocked()"
                matTooltip="Lass dir künftig wieder anbieten die Unterschrift zu kopieren, falls manche Dokumente eine Unterschrift haben, andere aber nicht.">
                <mat-icon>assistant</mat-icon>
                Auf fehlende Unterschrift hinweisen
            </a>
        </mat-menu>
    </div>

    <!--********** Documents to sign **********-->
    <div id="signable-documents" class="options-row">
        <!--********** Declaration of Assignment **********-->
        <div
            class="signable-document-option-container"
            [ngClass]="{ active: getSignableDocumentByType('declarationOfAssignment'), unselectable: isReportLocked() }"
            (click)="toggleSignableDocument('declarationOfAssignment')">
            <mat-icon class="option-image customer-signature-document-icon">how_to_vote</mat-icon>
            <div
                class="option-title"
                [matTooltip]="
                    standardSignablePDFDocumentTitles.declarationOfAssignment
                        ? 'Diesen Titel hast du in den Einstellungen zu unterschreibbaren PDF-Dokumenten vergeben.'
                        : ''
                ">
                {{
                    standardSignableDocumentOrderConfigs.declarationOfAssignment?.titleShort ||
                        standardSignablePDFDocumentTitles.declarationOfAssignment ||
                        'Auftrag'
                }}
                <div class="icon-ring" *ngIf="isDocumentSigned('declarationOfAssignment')">
                    <mat-icon class="small-icon is-signed-icon" matTooltip="Unterschrift vorhanden">check</mat-icon>
                </div>
            </div>
        </div>

        <!--********** Revocation Instruction **********-->
        <div
            class="signable-document-option-container"
            [ngClass]="{ active: getSignableDocumentByType('revocationInstruction'), unselectable: isReportLocked() }"
            (click)="toggleSignableDocument('revocationInstruction')">
            <mat-icon class="option-image customer-signature-document-icon">undo</mat-icon>
            <div
                class="option-title"
                [matTooltip]="
                    standardSignablePDFDocumentTitles.revocationInstruction
                        ? 'Diesen Titel hast du in den Einstellungen zu unterschreibbaren PDF-Dokumenten vergeben.'
                        : ''
                ">
                {{
                    standardSignableDocumentOrderConfigs.revocationInstruction?.titleShort ||
                        standardSignablePDFDocumentTitles.revocationInstruction ||
                        'Widerruf'
                }}
                <div class="icon-ring" *ngIf="isDocumentSigned('revocationInstruction')">
                    <mat-icon class="small-icon is-signed-icon" matTooltip="Unterschrift vorhanden">check</mat-icon>
                </div>
            </div>
        </div>

        <!--********** Privacy Statement **********-->
        <div
            class="signable-document-option-container"
            [ngClass]="{ active: getSignableDocumentByType('consentDataProtection'), unselectable: isReportLocked() }"
            (click)="toggleSignableDocument('consentDataProtection')">
            <mat-icon class="option-image customer-signature-document-icon">fingerprint</mat-icon>
            <div
                class="option-title"
                [matTooltip]="
                    standardSignablePDFDocumentTitles.consentDataProtection
                        ? 'Diesen Titel hast du in den Einstellungen zu unterschreibbaren PDF-Dokumenten vergeben.'
                        : ''
                ">
                {{
                    standardSignableDocumentOrderConfigs.consentDataProtection?.titleShort ||
                        standardSignablePDFDocumentTitles.consentDataProtection ||
                        'Datenschutz'
                }}
                <div class="icon-ring" *ngIf="isDocumentSigned('consentDataProtection')">
                    <mat-icon class="small-icon is-signed-icon" matTooltip="Unterschrift vorhanden">check</mat-icon>
                </div>
            </div>
        </div>

        <!--********** Power of Attorney **********-->
        <div
            class="signable-document-option-container"
            [ngClass]="{ active: getSignableDocumentByType('powerOfAttorney'), unselectable: isReportLocked() }"
            (click)="toggleSignableDocument('powerOfAttorney')"
            *ngIf="shouldPowerOfAttorneyDocumentBeVisible()">
            <img
                *ngIf="!isQapterixpert()"
                src="/assets/images/icons/lawyer-blue.svg"
                class="option-image customer-signature-document-icon" />
            <img
                *ngIf="isQapterixpert()"
                src="/assets/images/icons/lawyer-orange.svg"
                class="option-image customer-signature-document-icon" />
            <div
                class="option-title"
                [matTooltip]="
                    standardSignablePDFDocumentTitles.powerOfAttorney
                        ? 'Diesen Titel hast du in den Einstellungen zu unterschreibbaren PDF-Dokumenten vergeben.'
                        : ''
                ">
                {{
                    standardSignableDocumentOrderConfigs.powerOfAttorney?.titleShort ||
                        standardSignablePDFDocumentTitles.powerOfAttorney ||
                        'Vollmacht'
                }}
                <div class="icon-ring" *ngIf="isDocumentSigned('powerOfAttorney')">
                    <mat-icon class="small-icon is-signed-icon" matTooltip="Unterschrift vorhanden">check</mat-icon>
                </div>
            </div>
        </div>

        <!--********** Custom signature documents **********-->
        <ng-container *ngFor="let customDocumentOrderConfig of customDocumentOrderConfigs; trackBy: trackById">
            <div
                class="signable-document-option-container"
                [ngClass]="{
                    active: getSignableDocumentByType('customDocument', customDocumentOrderConfig._id),
                    unselectable: isReportLocked(),
                }"
                (click)="toggleSignableDocument('customDocument', customDocumentOrderConfig._id)">
                <mat-icon class="option-image customer-signature-document-icon">
                    {{ customDocumentOrderConfig.customDocumentConfig.googleMaterialIconName || 'description' }}
                </mat-icon>
                <div class="option-title" [matTooltip]="customDocumentOrderConfig.titleLong">
                    {{
                        customSignablePdfDocumentTitles.get(customDocumentOrderConfig._id) ??
                            customDocumentOrderConfig.titleShort
                    }}
                    <div class="icon-ring" *ngIf="isDocumentSigned('customDocument', customDocumentOrderConfig._id)">
                        <mat-icon class="small-icon is-signed-icon" matTooltip="Unterschrift vorhanden">check</mat-icon>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <!--============================================-->
    <!-- Transfer Signature -->
    <!--============================================-->
    <div
        id="transfer-signature-info-note"
        class="info-note"
        *ngIf="
            !user?.userInterfaceStates.transferMissingSignaturesNoticeClosed &&
            !report.transferMissingSignaturesNoticeClosed &&
            areSomeDocumentsMissingSignatures() &&
            !customerSignaturesDialogShown
        "
        @fadeInAndSlide>
        <img class="info-note-image" src="/assets/images/icons/signatures-missing.png" alt="Fehlende Unterschrift" />
        <div class="info-note-text">
            <div class="info-note-preserve-close-icon-space">
                Manchen Dokumenten fehlt die Unterschrift.
                <span
                    class="help-indicator"
                    [matTooltip]="
                        'Das kann passieren, wenn dein Kunde bereits unterschrieben hat, bevor du alle Dokumente aktiviert hast.' +
                        '\n\nDiesen Dokumenten fehlen eine oder mehrere Unterschriften: ' +
                        getDocumentsMissingSignaturesTooltip()
                    ">
                    ?
                </span>
            </div>
            <div class="info-note-buttons-row">
                <div
                    class="info-note-button-primary"
                    (click)="transferCustomerSignatures()"
                    matTooltip="Die Unterschrift des ersten Dokuments auf Dokumente ohne Unterschrift übertragen.">
                    Übertragen
                </div>
                <div
                    class="info-note-button-secondary"
                    (click)="user.userInterfaceStates.transferMissingSignaturesNoticeClosed = true; saveUser()"
                    matTooltip="Meldung dauerhaft ausblenden">
                    Nicht mehr fragen
                </div>
            </div>

            <!--********** Close Icon **********-->
            <mat-icon
                class="info-note-close-icon"
                (click)="closeTransferSignatureNoticeForThisReport()"
                matTooltip="Für dieses Gutachten nicht mehr nachfragen">
                close
            </mat-icon>
        </div>
    </div>
    <!--============================================-->
    <!-- END Transfer Signature -->
    <!--============================================-->

    <!--============================================-->
    <!-- Factoring -->
    <!--============================================-->
    <div
        class="checkbox-group parent-of-an-only-child"
        *ngIf="factoringProviderExists || report.feeCalculation.invoiceParameters.factoringEnabled">
        <mat-checkbox
            id="use-factoring-service-provider"
            [(ngModel)]="report.feeCalculation.invoiceParameters.factoringEnabled"
            (change)="saveReport()"
            matTooltip="Factoring mit {{
                userPreferences.factoringProvider.contactPerson.organization || '[kein Factoring-Anbieter hinterlegt]'
            }}{{
                '\n\n'
            }}Factoring-Anbieter zahlen dir gegen eine Gebühr von ca. 3-8 % des Rechnungsbetrags sofort deine Rechnungen aus und erleichtern dir damit deine Buchhaltung. Versicherer überweisen dann nach mehreren Wochen direkt an den Factoring-Anbieter.{{
                '\n\n'
            }}Typische Anbieter für Sachverständige: KfzVS/DSR24, Goya Mobility, ADELTA.FINANZ"
            [disabled]="isReportLocked()">
            Factoring aktivieren
        </mat-checkbox>
    </div>
    <!--============================================-->
    <!-- END Factoring -->
    <!--============================================-->

    <!--============================================-->
    <!-- Custom Fields -->
    <!--============================================-->
    <custom-field-display
        fieldLocation="accident-data_claimant-signatures"
        [report]="report"
        [useNegativeBottomMarginForCards]="false"
        (reportChange)="saveReport()"
        [disabled]="isReportLocked()"></custom-field-display>
    <!--============================================-->
    <!-- END Custom Fields -->
    <!--============================================-->

    <!--============================================-->
    <!-- Remote Signature Status -->
    <!--============================================-->
    <ng-container [ngSwitch]="getRemoteSignatureStatus()">
        <!-- Signature link generated -->
        <div *ngSwitchCase="'link-generated'" class="info-note remote-signature-status-note">
            <div class="status-icon-container">
                <mat-icon class="status-icon">hourglass_top</mat-icon>
            </div>

            <div class="info-note-text">
                <div class="info-note-title">Warte auf Unterschrift</div>
                <div>Der Kunden-Link wurde generiert.</div>
            </div>

            <mat-icon
                class="status-delete toolbar-icon"
                [matTooltip]="'Kunden-Link zum online unterschreiben löschen'"
                (click)="deleteRemoteSignature()">
                delete
            </mat-icon>
        </div>

        <!-- Signature deadline passed -->
        <div *ngSwitchCase="'deadline-passed'" class="info-note remote-signature-status-note error">
            <div class="status-icon-container">
                <mat-icon class="status-icon">signature</mat-icon>
            </div>

            <div class="info-note-text">
                <div class="info-note-title">Frist abgelaufen</div>
                <div>Nicht alle Unterschriften sind eingegangen.</div>

                <div class="info-note-button-primary status-action-button" (click)="resetRemoteSignatureDeadline()">
                    Frist verlängern
                </div>
            </div>

            <mat-icon
                class="status-delete toolbar-icon"
                [matTooltip]="'Kunden-Link zum online unterschreiben löschen'"
                (click)="deleteRemoteSignature()">
                delete
            </mat-icon>
        </div>

        <!-- Signature submitted -->
        <div *ngSwitchCase="'signatures-submitted'" class="info-note remote-signature-status-note">
            <div class="status-icon-container">
                <mat-icon class="status-icon">signature</mat-icon>
            </div>

            <div class="info-note-text">
                <div class="info-note-title">Unterschriften geleistet</div>
                <!-- Prevent prettier from adding a space between "Prüfung" and the period. -->
                <!-- prettier-ignore -->
                <div>
                    Bereit zur
                    <a class="link" (click)="openCustomerSignaturesDialog()">Prüfung</a>.
                </div>

                <div class="info-note-button-primary status-action-button" (click)="markRemoteSignatureAsChecked()">
                    Als geprüft markieren
                </div>
            </div>
        </div>
    </ng-container>
    <!--============================================-->
    <!-- END Remote Signature Status -->
    <!--============================================-->

    <div id="signature-button-container" *ngIf="!isReportLocked()">
        <button class="signature-button" [ngClass]="{ disabled: !report.signableDocuments.length }">
            <div class="split-button-left" (click)="openCustomerSignaturesDialog()">Unterschreiben</div>
            <div class="split-button-divider"></div>
            <div class="split-button-right" [matMenuTriggerFor]="remoteSignatureSubmenu">
                <mat-icon class="small-icon">arrow_drop_down</mat-icon>
            </div>
        </button>
        <button
            class="flat"
            *ngIf="userIsAdmin() && !team.preferences.documentsToBeSigned?.length"
            (click)="rememberDocumentSettings()"
            matTooltip="Ausgewählte Dokumente & Factoring-Einstellung als Standard setzen">
            Merken
        </button>
    </div>

    <mat-menu #remoteSignatureSubmenu="matMenu">
        <a mat-menu-item class="menu-item-with-icon" (click)="openRemoteSignatureDialog()">
            <mat-icon>signature</mat-icon>
            Online unterschreiben lassen
        </a>
    </mat-menu>
</div>

<customer-signatures-dialog
    *ngIf="customerSignaturesDialogShown"
    [report]="report"
    (close)="closeCustomerSignaturesDialog()"
    @runChildAnimations></customer-signatures-dialog>

<remote-signature-dialog
    *ngIf="remoteSignatureDialogShown"
    [report]="report"
    (close)="closeRemoteSignatureDialog()"
    @runChildAnimations></remote-signature-dialog>

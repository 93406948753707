<h2 matDialogTitle>Werkstatt-Kostensätze</h2>
<mat-icon class="dialog-close-icon" (click)="cancel()">close</mat-icon>
<mat-dialog-content>
    Die Kostensätze wurden seit dem letzten Export verändert.
    <br />
    Neu übertragen?
</mat-dialog-content>
<mat-dialog-actions>
    <button id="stay-button" (click)="confirmRetransmission()">Übertragen & öffnen</button>
    <button id="leave-button" class="secondary" (click)="openWithoutRetransmission()">
        Nur die Kalkulation öffnen
    </button>
</mat-dialog-actions>

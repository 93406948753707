import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import {
    filterDocumentPlaceholders,
    getAvailablePlaceholders,
    placeholderDetectionRegexString,
} from '@autoixpert/lib/document-building-blocks/get-available-placeholders';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { AbstractFieldGroupConfigService } from '../../abstract-services/field-group-config.abstract.service';

@Component({
    selector: 'placeholder-autocomplete-input',
    templateUrl: 'placeholder-autocomplete-input.component.html',
    styleUrls: ['placeholder-autocomplete-input.component.scss'],
})
export class PlaceholderAutocompleteInput implements OnInit {
    constructor(private fieldGroupConfigService: AbstractFieldGroupConfigService) {}

    // Configure the RegExp to match only the placeholder at the end of the string (close to the caret)
    private placeholderDetectionPattern: RegExp = new RegExp(`${placeholderDetectionRegexString}$`);

    private availablePlaceholders: string[] = [];

    public filteredPlaceholders: string[] = [];

    @Input() useTextarea: boolean = false;
    @Input('placeholder') inputPlaceholder: string = 'Text';
    @Input() text: string = '';
    @Input() documentBuildingBlock: DocumentBuildingBlock;
    @Input() axAutofocus: boolean;
    @Input() disabled: boolean = false;
    @Input() tooltip: string = '';

    @Output() textChange: EventEmitter<string> = new EventEmitter<string>(); // equivalent to input event
    @Output() change: EventEmitter<string> = new EventEmitter<string>(); // equivalent to change event
    @Output() inputFocused: EventEmitter<void> = new EventEmitter<void>();

    // Get a handle to the input / textarea. Required to determine caret position.
    @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
    @ViewChild('cdkTextareaAutosizeDirective', { static: false }) cdkTextareaAutosizeDirective: CdkTextareaAutosize;

    // Custom Fields
    private fieldGroupConfigs: FieldGroupConfig[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        if (!this.fieldGroupConfigs?.length) {
            this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        }

        this.availablePlaceholders = getAvailablePlaceholders({
            // this.documentBuildingBlock may be undefined when this component is used for editing text templates instead of document building blocks.
            buildingBlockPlaceholder: this.documentBuildingBlock?.placeholder,
            fieldGroupConfigs: this.fieldGroupConfigs,
        });

        if (this.useTextarea) {
            this.resizeTextarea();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    private filterDocumentPlaceholderList(searchTerm: string): string[] {
        return filterDocumentPlaceholders(this.availablePlaceholders, searchTerm);
    }

    public filterPlaceholders(searchTerm: string): void {
        this.filteredPlaceholders = this.filterDocumentPlaceholderList(searchTerm);
    }

    /**
     * If there's a placeholder right before the caret, open the autocomplete.
     *
     * @param {HTMLInputElement | HTMLTextAreaElement} inputElement
     */
    public openAutocompleteOnMatchingPlaceholder(inputElement: HTMLInputElement | HTMLTextAreaElement): void {
        const initialCaretPosition = inputElement.selectionStart;
        const matches: RegExpMatchArray = this.matchPlaceholder(inputElement);

        // No matches -> abort
        if (!matches) {
            // Clear the autocomplete
            this.filteredPlaceholders = [];
            return;
        }

        // The first capture group sits at index one.
        const searchTerm = matches[1] || '';
        this.filterPlaceholders(searchTerm);

        // In case there is a selection, collapse it so that the cursor is located at the selection's starting point.
        inputElement.setSelectionRange(initialCaretPosition, initialCaretPosition);
    }

    /**
     * Replace the placeholder the user started to type with the full placeholder selected from the autocomplete.
     *
     * @param {HTMLInputElement | HTMLTextAreaElement} inputElement
     * @param {string} placeholder
     * @returns {string}
     */
    public getTextWithFullPlaceholder(
        inputElement: HTMLInputElement | HTMLTextAreaElement,
        placeholder: string,
    ): string {
        const matches = this.matchPlaceholder(inputElement);

        if (!matches) {
            return;
        }

        const replacedTextUpToCaret = matches.input.replace(this.placeholderDetectionPattern, `{${placeholder}}`);

        // Set the caret position so that the user may continue typing.
        window.setTimeout(() =>
            inputElement.setSelectionRange(replacedTextUpToCaret.length, replacedTextUpToCaret.length),
        );

        // Full text, with the placeholder before the caret completed
        return (
            inputElement.value
                .replace(matches.input, replacedTextUpToCaret)
                // In case the user already opened a double curly brace, remove one.
                .replace('}}', '}')
        );
    }

    /**
     * Try to match a placeholder right before the caret.
     *
     * @param {HTMLInputElement | HTMLTextAreaElement} inputElement
     * @returns {RegExpMatchArray}
     */
    private matchPlaceholder(inputElement: HTMLInputElement | HTMLTextAreaElement): RegExpMatchArray {
        const caretIndex = inputElement.selectionStart;

        // Only try to find placeholders in the text before the cursor
        const textToSearch = inputElement.value.substring(0, caretIndex);

        return textToSearch.match(this.placeholderDetectionPattern);
    }

    //*****************************************************************************
    //  Placeholder Autocompletion
    //****************************************************************************/
    public handleAutocompleteSelection(event: MatOptionSelectionChange): void {
        event.source.value = this.getTextWithFullPlaceholder(this.inputElement.nativeElement, event.source.value);
        // The autocomplete selection does not trigger the native change event. Also, this handler is called before the model is updated, so throw the event emission on the call stack.
        window.setTimeout(() => this.change.emit());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Placeholder Autocompletion
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Textarea Resize
    //****************************************************************************/
    /**
     * If this textarea is used within an overlay, its width is initially very narrow.
     * Therefore, even a small amount of text causes the CdkAutosizeDirective to assume
     * a very high scroll height, which in turn leads to a large number of rows.
     *
     * The force parameter coerces a resizing despite the text not having changed after Angular
     * has moved the textarea to the final position and has assigned the final dimensions.
     */
    public resizeTextarea(): void {
        /**
         * If called immediately, the textarea will not be inserted by its *ngIf condition yet.
         * The timeout solves this.
         */
        window.setTimeout(() => {
            this.cdkTextareaAutosizeDirective?.resizeToFitContent(true);
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Textarea Resize
    /////////////////////////////////////////////////////////////////////////////*/

    public emitNgModelChange(value: string): void {
        this.textChange.emit(value);
    }
}

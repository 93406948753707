import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { defaultCustomCarPropertiesForBicycles } from '@autoixpert/lib/reports/default-custom-car-properties-for-bicycles';
import {
    CustomCarProperty,
    CustomCarPropertyAutocomplete,
} from '@autoixpert/models/reports/car-identification/custom-car-property';
import { Report } from '@autoixpert/models/reports/report';
import { trackById } from 'src/app/shared/libraries/track-by-id';

@Component({
    selector: 'custom-car-properties',
    templateUrl: './custom-car-properties.component.html',
    styleUrls: ['./custom-car-properties.component.scss'],
})
export class CustomCarPropertiesComponent {
    @Input() isReportLocked: boolean = false;
    @Input() report: Report;
    @Output() dataFieldsChanged = new EventEmitter();

    @ViewChildren('.custom-car-properties-input-title') titleInputs: QueryList<HTMLInputElement>;

    defaultCustomProperties = defaultCustomCarPropertiesForBicycles;

    availablePropertyTitlesForAutocomplete: CustomCarProperty['title'][] = [];

    ngOnInit() {
        this.filterAvailablePropertyTitlesForAutocomplete();
    }

    /**
     * Suggest available properties depending on the vehicle type:
     *  - Suggest default properties of autoiXpert
     *  - Suggest saved properties of the customer
     *  - Do not suggest properties that are already used
     *
     * Currently, saving properties is only enabled for bicycle and pedelec.
     */
    filterAvailablePropertyTitlesForAutocomplete() {
        const allPropertyTitles = this.defaultCustomProperties.map((property) => property.title);
        const unusedPropertyTitles = allPropertyTitles.filter((propertyTitle) => {
            return !this.report.car.customProperties.some((customProperty) => customProperty.title === propertyTitle);
        });
        this.availablePropertyTitlesForAutocomplete = unusedPropertyTitles.sort((a, b) => a.localeCompare(b));
    }

    /**
     * Returns the autocomplete property for a given property title.
     */
    getAutocompleteValuesForTitle(title: CustomCarProperty['title']): CustomCarPropertyAutocomplete['values'] {
        if (!title) return [];

        return this.defaultCustomProperties.find((property) => property.title === title)?.values ?? [];
    }

    /**
     * Add / remove data fields
     */
    addDataField() {
        this.report.car.customProperties.push(new CustomCarProperty());
        this.emitChange();
        this.focusLastTitleInput();
    }

    removeDataField(dataField: CustomCarProperty) {
        removeFromArray(dataField, this.report.car.customProperties);
        this.emitChange();
    }

    /**
     * Reorder custom car properties
     */
    reorderCalculationItemsArray(event: CdkDragDrop<string[]>): void {
        const movedProperty = this.report.car.customProperties.splice(event.previousIndex, 1)[0];
        // Add the item back at the new position
        this.report.car.customProperties.splice(event.currentIndex, 0, movedProperty);

        // Save the new order back to the server.
        this.emitChange();
    }

    /**
     * After adding an property, focus the last title input.
     * Wait until the input is rendered, then focus it.
     */
    focusLastTitleInput() {
        setTimeout(() => {
            const inputs = document.querySelectorAll<HTMLInputElement>('.custom-car-properties-input-title input');
            inputs.item(inputs.length - 1)?.focus();
        }, 100);
    }

    /**
     * Utility functions
     */
    emitChange() {
        this.filterAvailablePropertyTitlesForAutocomplete();

        this.dataFieldsChanged.emit();
    }

    trackById = trackById;
}

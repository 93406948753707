import { DateTime } from 'luxon';
import { AxError } from '@autoixpert/models/errors/ax-error';

//*****************************************************************************
//  Attention: Frontend & Backend
//****************************************************************************/
// Keep this file up-to-date between the frontend and backend.
/////////////////////////////////////////////////////////////////////////////*/
//  END Attention: Frontend & Backend
/////////////////////////////////////////////////////////////////////////////*/

export function getDefaultOfPaymentStatus(
    defaultOfPaymentSince: null | undefined | string | Date | DateTime,
): DefaultOfPaymentStatus {
    if (defaultOfPaymentSince == null) {
        return null;
    }

    let dateTime: DateTime;
    if (typeof defaultOfPaymentSince === 'string') {
        dateTime = DateTime.fromISO(defaultOfPaymentSince);
    } else if (defaultOfPaymentSince instanceof Date) {
        dateTime = DateTime.fromJSDate(defaultOfPaymentSince);
    } else if (defaultOfPaymentSince instanceof DateTime) {
        dateTime = defaultOfPaymentSince;
    }

    if (!dateTime?.isValid) {
        throw new AxError({
            code: 'INVALID_DATE_STRING_FOR_DETERMINING_DEFAULT_OF_PAYMENT_STATUS',
            message: `The given input string is not a valid ISO datetime string.`,
            data: {
                defaultOfPaymentSince,
            },
        });
    }

    if (dateTime.diffNow(['days', 'hours']).days <= -14) {
        return 'deactivated';
    } else {
        return 'reminded';
    }
}

/**
 * null = No overdue payments
 * reminded = The user should be reminded about his overdue payments
 * deactivated = Although the user was reminded about his overdue payments, he did not react. The account was deactivated.
 */
export type DefaultOfPaymentStatus = null | 'reminded' | 'deactivated';

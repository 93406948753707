import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutocompleteBubbleListComponent } from '../components/autocomplete-bubble-list/autocomplete-bubble-list.component';

/**
 * Directive for input fields that displays a list of suggested email domains for auto completion. The directive can be applied
 * to a native input field.
 */
@Directive({
    selector: 'input[emailAutocomplete][ngModel]',
})
export class EmailAutocompleteDirective implements OnDestroy {
    /**
     * List of email provider domains sorted by their popularity in Germany.
     */
    private readonly emailDomains = [
        'web.de',
        'gmx.de',
        'gmail.com',
        'gmx.net',
        'outlook.com',
        't-online.de',
        'freenet.com',
        'yahoo.com',
        'aol.com',
        '1und1.de',
        'hotmail.com',
    ];

    /**
     * Only show this amount of suggestions to the user.
     */
    private readonly maxNumberSuggestions = 3;

    /**
     * References to the currently shown overlay, component and input subscription.
     */
    overlayRef?: OverlayRef;
    componentRef?: ComponentRef<AutocompleteBubbleListComponent>;
    inputSubscription?: Subscription;
    outsideClickSubscription?: Subscription;

    constructor(
        private readonly overlayService: Overlay,
        private readonly elementRef: ElementRef<HTMLInputElement>,
        private ngControl: NgControl,
    ) {}

    @HostListener('focus', ['$event.target'])
    inputFocused(inputElem: HTMLInputElement) {
        // When the user selects the input again (after focus was somewhere else),
        // check if we need to show the overlay again
        this.inputChanged(inputElem);
    }

    @HostListener('input', ['$event.target'])
    inputChanged(inputElem: HTMLInputElement) {
        const inputValue = inputElem.value;

        // array of emails (separated by ";") typed by the user
        const emailsFromInputField = inputValue.split(';');

        const lastEmail = emailsFromInputField.at(-1);
        if (lastEmail.includes('@')) {
            // only show the overlay after the user typed the "@" sign
            if (!this.overlayRef) {
                this.initializeOverlay();
            }

            if (!this.overlayRef.hasAttached()) {
                // if no content is attached (e.g. because of scroll strategy 'close'
                // or there has not been any yet) create the content and attach it to the overlay
                this.attachOverlayContent();
            }

            const emailParts = lastEmail.split('@');

            // check what the user already typed, so we can filter the suggested list
            const searchPhrase = emailParts.at(-1);

            // filter the domains using what the user already typed and do not include a domain if the user finished typing it
            const filteredEmails = this.emailDomains
                .filter((email) => email.startsWith(searchPhrase) && email !== searchPhrase)
                .slice(0, this.maxNumberSuggestions);

            this.componentRef.instance.values = filteredEmails;
        } else {
            // if the input does not contain the '@' sign, we remove the overlay
            this.destroyOverlay();
        }
    }

    /**
     * Creates only the overlay (without content) that later displays the list of suggested emails.
     */
    initializeOverlay(): void {
        // create the overlay
        this.overlayRef = this.overlayService.create({
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                        offsetY: 12,
                    },
                ])
                .withPush(false),
            scrollStrategy: this.overlayService.scrollStrategies.close(),
        });

        // when clicked outside the overlay or input, we close (remove) the overlay
        this.outsideClickSubscription = this.overlayRef.outsidePointerEvents().subscribe((elem) => {
            const inputWasClicked = elem.target === this.elementRef.nativeElement;

            if (!inputWasClicked) {
                this.destroyOverlay();
            }
        });
    }

    /**
     * Creates and attaches the content (bubble list) for the overlay. Also listen for selection of a bubble.
     */
    attachOverlayContent(): void {
        // create and attach the bubble component
        this.componentRef = this.overlayRef.attach(new ComponentPortal(AutocompleteBubbleListComponent));

        // listen for selection of a bubble by the user, then append the email to the current input value
        this.inputSubscription = this.componentRef.instance.valueSelected.subscribe((selectedValue) => {
            const inputValue = this.elementRef.nativeElement.value;

            // on insertion make sure that we overwrite the part of the email that the user already typed
            const emailParts = inputValue.split('@');
            const searchPhrase = emailParts.at(-1);

            this.elementRef.nativeElement.value =
                inputValue.slice(0, inputValue.length - searchPhrase.length) + selectedValue;

            // manually trigger the 'input' event which hides the overlay
            this.inputChanged(this.elementRef.nativeElement);

            /**
             * Let Angular's ngModel directive know about the value change.
             * Simply changing the input's value does not update the model.
             * The host MUST have an ngModel binding.
             */
            this.ngControl.control.setValue(this.elementRef.nativeElement.value);

            // Emit the host input's change event which usually triggers saving the bound record.
            this.elementRef.nativeElement.dispatchEvent(new Event('change'));
        });
    }

    /**
     * Destroy the current overlay and all references.
     * Unsubscribe from all subscriptions.
     */
    private destroyOverlay() {
        if (this.overlayRef) {
            // remove the overlay
            this.overlayRef.dispose();
        }
        this.inputSubscription?.unsubscribe();
        this.outsideClickSubscription?.unsubscribe();
        this.overlayRef = null;
        this.componentRef = null;
    }

    ngOnDestroy() {
        this.destroyOverlay();
    }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LabelConfig } from '@autoixpert/models/labels/label-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { User } from '@autoixpert/models/user/user';
import { slideInAndOutVertically } from '../../../animations/slide-in-and-out-vertical.animation';
import { LabelPickerComponent } from '../../report-label-picker/label-picker.component';

/**
 * These are the keys for storing the filter settings in local storage.
 */
const OFFICE_LOCATION_LOCAL_STORAGE_KEY = 'analyticsOfficeLocations';
const ASSESSOR_LOCAL_STORAGE_KEY = 'analyticsAssessors';
const REPORT_LABEL_LOCAL_STORAGE_KEY = 'analyticsReportLabels';
const INVOICE_LABEL_LOCAL_STORAGE_KEY = 'analyticsInvoiceLabels';

@Component({
    selector: 'analytics-filter',
    templateUrl: './analytics-filter.component.html',
    styleUrls: ['./analytics-filter.component.scss'],
    animations: [slideInAndOutVertically()],
})
export class AnalyticsFilterComponent implements OnInit {
    @ViewChild('reportLabelPicker') reportLabelPickerComponent: LabelPickerComponent;
    /**
     * Expanded state of the filter. This is controlled by the analytics-filter-button.
     */
    @Input() isExpanded: boolean = false;

    @Input() localStorageKeyPrefix: string = '';

    @Input() assessorIds: User['_id'][] = [];
    @Input() showAssessorFilter: boolean = true;
    @Output() assessorIdsChange: EventEmitter<User['_id'][]> = new EventEmitter();

    @Input() officeLocationIds: OfficeLocation['_id'][] = [];
    @Input() showOfficeLocationFilter: boolean = true;
    @Output() officeLocationIdsChange: EventEmitter<OfficeLocation['_id'][]> = new EventEmitter();

    @Input() reportLabelConfigIds: LabelConfig['_id'][] = [];
    @Input() showReportLabelFilter: boolean = true;
    @Output() reportLabelConfigIdsChange: EventEmitter<LabelConfig['_id'][]> = new EventEmitter();

    @Input() invoiceLabelConfigIds: LabelConfig['_id'][] = [];
    @Input() showInvoiceLabelFilter: boolean = true;
    @Output() invoiceLabelConfigIdsChange: EventEmitter<LabelConfig['_id'][]> = new EventEmitter();

    @Output() filterChange: EventEmitter<void> = new EventEmitter();

    /**
     * Fired after this filter retrieved all stored settings from local storage. The analytics pages
     * that use this filter can listen for this event and then fetch the data
     * (where they need to have the filters ready).
     */
    @Output() initialized: EventEmitter<void> = new EventEmitter();

    ngOnInit() {
        this.officeLocationIds = this.readAnalyticsFilterIdsLocally(
            this.localStorageKeyPrefix + OFFICE_LOCATION_LOCAL_STORAGE_KEY,
        );
        this.assessorIds = this.readAnalyticsFilterIdsLocally(this.localStorageKeyPrefix + ASSESSOR_LOCAL_STORAGE_KEY);
        this.reportLabelConfigIds = this.readAnalyticsFilterIdsLocally(
            this.localStorageKeyPrefix + REPORT_LABEL_LOCAL_STORAGE_KEY,
        );
        this.invoiceLabelConfigIds = this.readAnalyticsFilterIdsLocally(
            this.localStorageKeyPrefix + INVOICE_LABEL_LOCAL_STORAGE_KEY,
        );

        this.initialized.emit();
    }

    protected resetAllFilters(): void {
        this.assessorIds = [];
        this.officeLocationIds = [];
        this.reportLabelConfigIds = [];
        this.invoiceLabelConfigIds = [];

        this.rememberAnalyticsFilterIdsLocally(
            this.assessorIds,
            this.localStorageKeyPrefix + ASSESSOR_LOCAL_STORAGE_KEY,
        );
        this.rememberAnalyticsFilterIdsLocally(
            this.officeLocationIds,
            this.localStorageKeyPrefix + OFFICE_LOCATION_LOCAL_STORAGE_KEY,
        );
        this.rememberAnalyticsFilterIdsLocally(
            this.reportLabelConfigIds,
            this.localStorageKeyPrefix + REPORT_LABEL_LOCAL_STORAGE_KEY,
        );
        this.rememberAnalyticsFilterIdsLocally(
            this.reportLabelConfigIds,
            this.localStorageKeyPrefix + INVOICE_LABEL_LOCAL_STORAGE_KEY,
        );

        this.filterChange.emit();
    }

    protected officeLocationChanged(): void {
        this.officeLocationIdsChange.emit(this.officeLocationIds);
        this.filterChange.emit();
        this.rememberAnalyticsFilterIdsLocally(
            this.officeLocationIds,
            this.localStorageKeyPrefix + OFFICE_LOCATION_LOCAL_STORAGE_KEY,
        );
    }

    protected assessorChanged(): void {
        this.assessorIdsChange.emit(this.assessorIds);
        this.filterChange.emit();
        this.rememberAnalyticsFilterIdsLocally(
            this.assessorIds,
            this.localStorageKeyPrefix + ASSESSOR_LOCAL_STORAGE_KEY,
        );
    }

    protected reportLabelsChanged(): void {
        this.reportLabelConfigIdsChange.emit(this.reportLabelConfigIds);
        this.filterChange.emit();
        this.rememberAnalyticsFilterIdsLocally(
            this.reportLabelConfigIds,
            this.localStorageKeyPrefix + REPORT_LABEL_LOCAL_STORAGE_KEY,
        );
    }

    protected invoiceLabelsChanged(): void {
        this.invoiceLabelConfigIdsChange.emit(this.invoiceLabelConfigIds);
        this.filterChange.emit();
        this.rememberAnalyticsFilterIdsLocally(
            this.invoiceLabelConfigIds,
            this.localStorageKeyPrefix + INVOICE_LABEL_LOCAL_STORAGE_KEY,
        );
    }

    readAnalyticsFilterIdsLocally(key: string): string[] {
        try {
            return JSON.parse(store.get(key) || '[]');
        } catch (err: unknown) {
            // Catch errors in case the local storage is corrupted e.g., does not hold a valid array for JSON.parse
            // In this case, return an empty array as this feature is not critical
            return [];
        }
    }

    rememberAnalyticsFilterIdsLocally(filterIds: string[], key: string): void {
        try {
            store.set(key, JSON.stringify(filterIds));
        } catch (err: unknown) {
            // Catch errors in case the browser does not support local storage or the storage is full
            // Do nothing as this feature is not critical
        }
    }
}

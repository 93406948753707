<div id="invoice-list-overlay-container" class="card" *ngIf="invoices?.length">
    <!--============================================-->
    <!-- Invoice Table -->
    <!--============================================-->
    <table id="invoice-list">
        <tr class="invoice-list-item" *ngFor="let invoice of invoices" (click)="openInvoice(invoice)">
            <td class="payment-status-column">
                <payment-status [invoice]="invoice"></payment-status>
            </td>

            <!--********** Total **********-->
            <td
                class="invoice-total"
                *ngIf="valueDisplayType === 'totalAmount'"
                [ngClass]="{ canceled: invoice.isFullyCanceled }">
                <span
                    *ngIf="displayNetValues"
                    matTooltip="Netto. Brutto: {{ invoice.totalGross || 0 | number: '1.2-2' }} €">
                    {{ invoice.totalNet || 0 | number: '1.2-2' }} €
                </span>
                <span
                    *ngIf="!displayNetValues"
                    matTooltip="Brutto. Netto: {{ invoice.totalNet || 0 | number: '1.2-2' }} €">
                    {{ invoice.totalGross || 0 | number: '1.2-2' }} €
                </span>

                <!--********** Shortpaid amount **********-->
                <div
                    class="invoice-shortpay-amount"
                    *ngIf="doesInvoiceHaveShortPay(invoice)"
                    matTooltip="Umsatz (brutto) nach Kürzung: {{
                        invoice.totalGross - getInvoiceUnpaidShortPaymentSumGross(invoice) | number: '1.2-2'
                    }} €">
                    <!--********** Short payment icon **********-->
                    <svg
                        class="shortpay-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1264.278 0 2267.717 2267.719"
                        xml:space="preserve">
                        <path
                            d="M2875.046,1480.612c-0.013,0-0.02,0-0.025,0c-16.579,0-32.953,1.214-49.077,3.487l-141.15-178.406
                                c-82.692,104.55-160.819,203.32-223.939,283.123l88.555,111.908c-11.038,27.721-18.608,56.994-22.281,87.309
                                c-11.153,92.17,14.524,183.133,72.302,256.143c66.922,84.586,167.549,133.066,276.08,133.066
                                c78.986,0,153.672-25.653,215.964-74.056c73.901-57.381,120.776-140.021,131.981-232.435
                                c11.173-92.17-14.499-183.44-72.297-256.508C3084.211,1529.676,2983.577,1480.612,2875.046,1480.612z M3090.086,1855.321
                                c-6.923,57.072-35.85,107.921-81.466,143.334c-38.418,29.853-84.484,45.597-133.201,45.597
                                c-66.922,0-128.982-29.948-170.266-82.133c-35.682-45.09-51.528-101.282-44.64-158.219c6.903-57.084,35.831-107.702,81.44-143.135
                                c38.418-29.852,84.477-45.314,133.182-45.314c0,0,0.007,0,0.02,0c66.915,0,128.982,29.596,170.284,81.78
                                C3081.135,1742.353,3096.989,1798.384,3090.086,1855.321z"></path>
                        <path
                            d="M1963.815,1485.024c-15.898-2.164-32.036-3.326-48.371-3.326c-108.518,0-209.139,48.512-276.061,133.099
                                c-57.777,73.047-83.456,164.035-72.296,256.212c11.192,92.413,58.041,174.919,131.924,232.318
                                c62.336,48.403,137.041,73.985,216.021,73.985c108.531,0,209.158-48.519,276.086-133.111c57.777-73.016,83.45-163.99,72.29-256.161
                                c-3.673-30.347-11.27-59.582-22.333-87.251c110.611-139.803,773.652-977.839,916.6-1158.455
                                c72.933-92.202,87.849-198.448,43.127-307.248c-32.557-79.2-85.802-131.558-88.056-133.748
                                c-7.217-7.025-16.868-10.916-26.873-10.916c-0.828,0-1.662,0.025-2.496,0.083c-10.878,0.707-20.954,5.992-27.714,14.545
                                l-661.172,833.741c-81.017,102.424-223.215,284.01-223.215,284.01L1963.815,1485.024z M2130.033,1855.315
                                c-6.916,57.078-35.85,107.927-81.466,143.34c-38.418,29.853-84.483,45.597-133.201,45.597c-66.91,0-128.97-29.948-170.258-82.133
                                c-35.689-45.09-51.536-101.288-44.647-158.225c6.917-57.085,35.837-107.696,81.454-143.129
                                c38.412-29.852,84.471-45.314,133.175-45.314c0,0,0.007,0,0.02,0c66.922,0,128.989,29.596,170.29,81.78
                                C2121.082,1742.353,2136.936,1798.377,2130.033,1855.315z M2395.268,1172.478c27.784,0,50.323,22.558,50.323,50.329
                                c0,27.785-22.539,50.323-50.323,50.323c-27.778,0-50.323-22.538-50.323-50.323
                                C2344.951,1195.03,2367.49,1172.478,2395.268,1172.478z"></path>
                        <path
                            d="M1632.879,542.327c71.608,90.571,274.127,346.506,472.177,596.786c66.022-83.482,143.718-181.707,223.932-283.124
                                l-594.114-750.947c-6.768-8.553-16.837-13.832-27.714-14.544c-0.835-0.058-1.669-0.084-2.497-0.084
                                c-10.005,0-19.663,3.892-26.873,10.917c-2.254,2.19-55.492,54.554-88.048,133.754
                                C1545.024,343.893,1559.947,450.132,1632.879,542.327z"></path>
                    </svg>
                </div>
            </td>

            <!--********** Unpaid Amount **********-->
            <td
                class="invoice-total"
                *ngIf="valueDisplayType === 'unpaidAmount'"
                [ngClass]="{ canceled: invoice.isFullyCanceled }">
                <span *ngIf="displayNetValues" matTooltip="Offener Betrag (netto)">
                    {{ getUnpaidAmount(invoice, 'net') || 0 | number: '1.2-2' }} €
                </span>
                <span *ngIf="!displayNetValues" matTooltip="Offener Betrag (brutto)">
                    {{ getUnpaidAmount(invoice, 'gross') || 0 | number: '1.2-2' }} €
                </span>
            </td>

            <td class="recipient-name semibold">
                <div *ngIf="invoice.recipient.contactPerson.organization">
                    <div>{{ invoice.recipient.contactPerson.organization }}</div>
                    <div class="label">
                        {{ invoice.recipient.contactPerson.firstName }} {{ invoice.recipient.contactPerson.lastName }}
                    </div>
                </div>
                <div
                    *ngIf="
                        !invoice.recipient.contactPerson.organization &&
                        (invoice.recipient.contactPerson.firstName || invoice.recipient.contactPerson.lastName)
                    ">
                    {{ invoice.recipient.contactPerson.firstName }} {{ invoice.recipient.contactPerson.lastName }}
                </div>
                <div
                    *ngIf="
                        !invoice.recipient.contactPerson.organization &&
                        !invoice.recipient.contactPerson.firstName &&
                        !invoice.recipient.contactPerson.lastName
                    ">
                    Kein Empfänger
                </div>
            </td>
            <td class="due-date">
                <span
                    matTooltip="Rechnungsdatum: {{ invoice.date | moment }} | Fällig am: {{ invoice.dueDate | moment }}"
                    *ngIf="invoice.date">
                    {{ invoice.date | moment }}
                </span>
                <span class="label" *ngIf="!invoice.date">Noch kein Datum</span>
            </td>
            <td class="invoice-number">
                <span matTooltip="Rechnungsnummer">{{ invoice.number }}</span>
            </td>
        </tr>
    </table>
    <!--============================================-->
    <!-- END Invoice Table -->
    <!--============================================-->
</div>

import { generateId } from '../../lib/generate-id';
import { OfficeLocation } from './office-location';

class InvoiceOrReportCounterConfigBase {
    // This ID is the same ID as the associated invoice number counter or report token counter.
    _id: string = generateId();
    title?: string;
    pattern: string = null;
    isDefault: boolean = false; // True if this report token setting shall be used if no other office location's counter matches.
    associatedOfficeLocationIds: OfficeLocation['_id'][] = []; // This is empty for the standard assessor since he has only one report token setting.
}

export class ReportTokenConfig extends InvoiceOrReportCounterConfigBase {
    constructor(template: Partial<ReportTokenConfig> = {}) {
        super();
        Object.assign(this, template);
    }

    pattern: string = 'GA-{JJJJ}{MM}-{###}';

    /**
     * Sync with invoice counter.
     */
    syncWithInvoiceCounter: boolean = false;
    leadingCounter: LeadingCounterType = 'reportToken';

    /**
     * When the leadingCounter is the report counter, this pattern is used for invoice numbers instead of the default pattern.
     * In that case the default pattern is still used for individual invoices not related to any report.
     */
    reportInvoiceNumberPattern: string = '{AZ}/{R}';

    /**
     * If true, the first report invoice number does not contain the sub counter (that counts up from 1 for every report token).
     */
    startReportInvoiceSubCounterWithSecondInvoice: boolean = true;

    /**
     * If true, cancellation invoices related to a report use a suffix instead of the report invoice sub counter.
     */
    useCancellationInvoiceSuffixForReportInvoices: boolean = false;
    reportInvoiceCancellationSuffix?: string = null;

    /**
     * Whether invoice numbers of amendment reports and invoice audits get their own counter and are based on the new report token.
     * Default (when false) is that they are based on the report token of the original report and share the counter.
     */
    separateCounterForAmendmentReportsAndInvoiceAudits: boolean = false;
}

export class InvoiceNumberConfig extends InvoiceOrReportCounterConfigBase {
    constructor(template: Partial<InvoiceNumberConfig> = {}) {
        super();
        Object.assign(this, template);
    }

    pattern: string = 'RE-{JJJJ}{MM}-{###}';

    /**
     * 1. neuen Zähler vergeben (default)
     * 2. Zähler anhängen: (-storno#)
     *  - bei erster:       -storno
     *  - bei mehreren:     -storno/2
     */
    useCancellationInvoiceSuffix: boolean = false;
    cancellationSuffix?: string = null;
}

export type InvoiceOrReportCounterConfig = InvoiceNumberConfig | ReportTokenConfig;

export type LeadingCounterType = 'invoiceNumber' | 'reportToken';

import { DateTime } from 'luxon';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { OfficeLocation } from '../teams/office-location';
import { DocumentOrderConfig } from './document-order-config';

/**
 * A document layout group may contain one or more document layout configs.
 * There may be multiple document layout groups per team to allow for different header and footer templates for different office locations, usually
 * because one assessor office bought another one and kept the corporate identify for the acquired location.
 */
export class DocumentLayoutGroup implements DataTypeBase {
    constructor(options: Partial<DocumentLayoutGroup>) {
        Object.assign(this, options);

        if (!this._id) {
            this._id = generateId();
        }
    }

    _id: string = null;
    // A field which the user may edit to identify this document layout group.
    title: string = null;

    /**
     * True if this document layout group shall be used if no other location matches.
     */
    isDefault: boolean = false;
    /**
     * A document layout group is often used when an office acquired one or multiple offices and kept their headers and footers for the
     * respective office locations.
     * Multiple locations are required if the acquired office had more than one location.
     *
     * This is empty for the standard assessor since he has only one document layout group.
     */
    associatedOfficeLocationIds: OfficeLocation['_id'][] = [];

    // This color is used for key information in DOCX files such as the replacement value above the document building block "Wiederbeschaffungswert".
    accentColorDocx: string = '#0070c0';
    // Light variant of the primary accent color. E.g. used in cover page valuation.
    secondaryAccentColorDocx?: string;
    // If the accent color is too bright, the arrow color in the damage sketch may be set to a darker tone.
    damageSketchArrowAndCrossColor: string;

    /**
     * Each config represents one header and footer template (DOCX file) which is associated with one or more
     * document order configs (liability, fullKasko, invoice, ...).
     */
    configs: DocumentLayoutConfig[] = [];

    createdBy: string = null;
    teamId: string = null;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    updatedAt: string = DateTime.now().setZone('UTC').toISO();

    _documentVersion: number = 0;
    _schemaVersion = 2 as const;
}

/**
 * One configuration always has one associated header and footer file which may be associated with different
 * document order configs (list of document building blocks).
 */
export class DocumentLayoutConfig {
    constructor(options: Partial<DocumentLayoutConfig>) {
        Object.assign(this, options);

        if (!this._id) {
            this._id = generateId();
        }
    }

    _id: string = null;
    // A field which the user may edit to identify this document layout (header and footer template & document template style [classic, modern, basedOnHeaderAndFooterTemplate]).
    title: string = null;

    // True if this header and footer template should be used for all document types for which no specific template exists.
    isDefault: boolean = false;
    associatedDocumentOrderConfigIds: DocumentOrderConfig['_id'][] = [];
    /**
     * This sets the general style of rendered PDFs. Styles include fonts and text alignment (left vs. justified).
     */
    documentTemplateStyle: 'modern' | 'classic' | 'basedOnHeaderAndFooterTemplate' = 'modern';
    /**
     * The hash of the uploaded header and footer template will be used for determining whether a document template needs to be re-rendered
     * when rendering a document.
     * It is set by the client when a new header and footer template is uploaded.
     */
    headerAndFooterTemplateHash: string;
}

/**
 * Get the ID of the rendered document template.
 * In contrast, the ID of the header and footer template looks like this: `${documentLayoutGroup._id}-${documentLayoutConfig._id}`
 */
export function getDocumentTemplateId({
    documentLayoutGroupId,
    documentOrderConfigId,
}: {
    documentLayoutGroupId: DocumentLayoutGroup['_id'];
    documentOrderConfigId: DocumentOrderConfig['_id'];
}): `${DocumentLayoutGroup['_id']}-${DocumentOrderConfig['_id']}` {
    return `${documentLayoutGroupId}-${documentOrderConfigId}`;
}

export function splitDocumentTemplateId(documentTemplateId): {
    documentLayoutGroupId: DocumentLayoutGroup['_id'];
    documentOrderConfigId: DocumentOrderConfig['_id'];
} {
    let documentLayoutGroupId: DocumentLayoutGroup['_id'] = undefined;
    let documentOrderConfigId: DocumentOrderConfig['_id'] = undefined;

    if (typeof documentTemplateId === 'string') {
        [documentLayoutGroupId, documentOrderConfigId] = documentTemplateId.split('-') as [
            DocumentLayoutGroup['_id'],
            DocumentOrderConfig['_id'],
        ];
    }

    return {
        documentLayoutGroupId,
        documentOrderConfigId,
    };
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { Report } from '@autoixpert/models/reports/report';
import { getVatRateForTaxationType } from '@autoixpert/static-data/taxation-rates';

@Component({
    selector: 'valuation-result-edit-value-popover',
    templateUrl: './valuation-result-edit-base-value-popover.component.html',
    styleUrls: ['./valuation-result-edit-base-value-popover.component.scss'],
})
export class ValuationResultEditBaseValuePopoverComponent {
    @Input() report: Report;
    @Output() valueChange: EventEmitter<void> = new EventEmitter<void>();

    get mayCarOwnerDeductTaxes(): boolean {
        return mayCarOwnerDeductTaxes(this.report);
    }
    protected recalculateNetValueAfterTaxationChange(): void {
        const vatRate = getVatRateForTaxationType(this.report.valuation.taxationType);
        if (this.report.valuation.baseValueDealerSalesGross) {
            this.report.valuation.baseValueDealerSalesNet =
                this.report.valuation.baseValueDealerSalesGross / (1 + vatRate);
        }
    }
}

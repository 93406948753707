<!--============================================-->
<!-- Labels -->
<!--============================================-->
<div class="label-item" *ngFor="let label of labels">
    <!--********** Color **********-->
    <!--suppress CssUnknownProperty -->
    <div class="label-color" [style.--labelColor]="label.color"></div>

    <!--********** Label Name **********-->
    <div class="label-name" [matTooltip]="label.name">{{ label.name }}</div>
</div>

<div id="add-button" class="label-item" *ngIf="labels?.length">+</div>
<!--============================================-->
<!-- END Labels -->
<!--============================================-->

<!--============================================-->
<!-- No Labels -->
<!--============================================-->
<div
    class="no-labels_placeholder"
    *ngIf="!labels?.length"
    matTooltip="Nutze Labels, um den Status festzuhalten oder um danach zu filtern.">
    Kein Label
</div>
<!--============================================-->
<!-- END No Labels -->
<!--============================================-->

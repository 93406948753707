import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { IsoDate } from '../../lib/date/iso-date.types';
import { generateId } from '../../lib/generate-id';
import { InvoiceInvolvedPartyRole } from '../invoices/invoice';
import { InvolvedPartyRole } from '../reports/involved-parties/involved-party';
import { Timestamps } from '../timestamps';

export class DocumentMetadata implements Timestamps {
    constructor(options: RequireSome<DocumentMetadata, 'type' | 'createdBy'>) {
        Object.assign(this, options);
    }

    _id: string = generateId();
    type: RenderedDocumentType | ExternalDocumentType = null;
    title: string = null;
    recipientRole?: InvoiceInvolvedPartyRole | InvolvedPartyRole = null;
    subject?: string = null; // In case this document comprises text entered into a free text field
    body?: string = null; // In case this document comprises text entered into a free text field
    date?: IsoDate = null; // The date printed on the document

    /**
     * This optional property allows watermarks to be printed on all DOCX-based documents. PDF-based documents
     * cannot be watermarked since we use a DOCX-based technology. That should be alright since PDF documents
     * are typically external documents which we do not need to alter. If users wish to alter them, they can
     * download them and edit them in their PDF editor.
     *
     * The watermarks are applied on a per-recipient-and-per-document basis. This means that a report can be sent
     * to the lawyer as an original but to all others as a duplicate.
     */
    docxWatermarkType?: DocxWatermarkType;

    /**
     * If the document.type is "manuallyUploadedPdf", this field is used to identify the document among all
     * manually uploaded PDFs.
     * If the user uploaded a PDF file onto a standard document such as "report", this ID is just used to retrieve
     * the PDF file from our object storage.
     */
    uploadedDocumentId?: string = null;
    permanentUserUploadedDocument?: boolean = null; // Whether this works as a permanent document upload, so it will be added to every new report.

    /**
     * In case the user created a custom document based on document building blocks (or a claimant signature document),
     * the custom document order config ID is required to identify the correct document order config since the type
     * "customDocument" may not be unique if there are multiple custom documents.
     */
    customDocumentOrderConfigId?: string;

    expertStatementId?: string = null; // Reference to the report.expertStatement object. Only set on documents with type 'expertStatement', not their respective invoices. They are identified by their invoiceId.
    invoiceId?: string = null; // Reference to the invoice object if there are connected invoices

    // Must be set by the program; won't be set automatically by the server on creation.
    createdBy: string = null;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

export type DocumentType = RenderedDocumentType | ExternalDocumentType;

export const signableDocumentTypes = [
    'declarationOfAssignment',
    'revocationInstruction',
    'consentDataProtection',
    'powerOfAttorney',
    /**
     * A custom document can include a customer signature document building block. If so, it can be signed. If not,
     * it's just a regular report attachment.
     */
    'customDocument',
] as const;
export type SignableDocumentType = (typeof signableDocumentTypes)[number];

// This allows the print and send screens to decide whether a document may be downloaded as a DOCX document.
export const renderedDocumentTypes = [
    'report',
    'repairConfirmation',
    'expertStatement',
    'customResidualValueBidList',
    'manualCalculation',
    'invoice',
    'diminishedValueProtocol',
    'garageInformation',
    'photoPortfolio',
    'paymentReminderLevel0',
    'paymentReminderLevel1',
    'paymentReminderLevel2',
    'paymentReminderLevel3',
    'letter',
    ...signableDocumentTypes,
] as const;
/**
 * This contains all document metadata types that are rendered by the backend. This excludes external documents such as
 * the CARTV residual value bid list or manually uploaded PDFs.
 *
 * This is different from DocumentTemplateType since the RenderedDocumentType knows "report" which the DocumentTemplateType
 * does not know. Instead of "report", the DocumentTemplateType knows the report type such as "liability" or "fullKasko" because
 * document templates (document building block order, headers and footers, DOCX styles, ...) may differ between report types.
 *
 * TODO Facilitate this by using the report type as a property of the document metadata instead of the generic "report".
 */
export type RenderedDocumentType = (typeof renderedDocumentTypes)[number];

export const externalDocumentTypes = [
    'afzzertCertificate',
    'audatexMarketAnalysis',
    'autoonlineResidualValueBidList',
    'carcasionResidualValueBidList',
    'cartvMarketAnalysis',
    'cartvResidualValueBidList',
    'classicanalyticsValuation',
    'manuallyUploadedPdf',
    'customDocument',
    'datDamageCalculation',
    'datMarketAnalysis',
    'datValuationProtocol',
    'valuepilotMarketAnalysis',
    'winvalueMarketAnalysis',
    'winvalueResidualValueBidList',
] as const;
export type ExternalDocumentType = (typeof externalDocumentTypes)[number];

export const documentTypes: DocumentType[] = [...externalDocumentTypes, ...renderedDocumentTypes];

/**
 * Throw a build error if a documentType is missing in the array:
 * props: https://stackoverflow.com/a/75418615
 *  - U & ([DocumentType] extends [U[number]] ? unknown : 'Invalid') is a conditional type that checks whether the type DocumentType
 *    is a subtype of U[number] (the element type of the array U). If it is, the type is unknown; otherwise, it is 'Invalid'.
 *    This condition ensures that the provided array's element type is exactly DocumentType.
 *  - { 0: DocumentType } is another type constraint that enforces that the
 *    array must have an element at index 0 with the type DocumentType.
 */
const arrayOfAllDocumentTypes = <U extends DocumentType[]>(
    array: U & ([DocumentType] extends [U[number]] ? unknown : 'Invalid') & { 0: DocumentType },
) => array;

/**
 * This is the default list of document types that should be included in all default document orders.
 * The order is only applicable for reports and used to create new teams or add new documents to existing teams.
 * Throws an error if a documentType is missing in the defaultOrder.
 */
export const standardDefaultDocumentOrderTypes: DocumentType[] = arrayOfAllDocumentTypes([
    'letter',
    'report',
    'declarationOfAssignment',
    'revocationInstruction',
    'consentDataProtection',
    'powerOfAttorney',
    'diminishedValueProtocol',
    'manualCalculation',
    'datDamageCalculation',
    'datMarketAnalysis',
    'audatexMarketAnalysis',
    'winvalueMarketAnalysis',
    'cartvMarketAnalysis',
    'valuepilotMarketAnalysis',
    'datValuationProtocol',
    'classicanalyticsValuation',
    'customResidualValueBidList',
    'autoonlineResidualValueBidList',
    'winvalueResidualValueBidList',
    'cartvResidualValueBidList',
    'carcasionResidualValueBidList',
    'afzzertCertificate',
    'photoPortfolio',
    'invoice',
    'garageInformation',

    // A PDF which the user uploaded through the print and transmission screen such as an invoice for an axle measurement.
    'manuallyUploadedPdf',
    /**
     * A custom document-building-block-based (or PDF-based) document that can be created by the user.
     * It can be a report attachment or a signable document.
     */
    'customDocument',

    'repairConfirmation',
    'expertStatement',

    // these are hidden in fullDocumentConfigurationDialog since they are only relevant for invoice.
    'paymentReminderLevel0',
    'paymentReminderLevel1',
    'paymentReminderLevel2',
    'paymentReminderLevel3',
]);

/**
 * There are currently two types of document watermarks:
 * - duplicate: A watermark that indicates that the document is a copy. This is often an indicator for claimants that
 *              the document should not be forwarded to the insurance since the assessor has already sent it.
 * - draft:     A watermark that indicates that the document is only a draft. Assessors use this to send the report to lawyers
 *              or the garage for them to work with before the report is completed. That's required if e.g. the damage
 *              calculation needs some minor details to be complete but the rest of the report is already done.
 */
export type DocxWatermarkType = 'duplicate' | 'draft';

export const docxWatermarkTypes: DocxWatermarkType[] = ['duplicate', 'draft'];

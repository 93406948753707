import { LineItemUnitAutocompleteEntry } from '../../invoices/invoice-editor/invoice-editor.component';

export const lineItemUnitsStatic: LineItemUnitAutocompleteEntry[] = [
    {
        label: 'km',
        singularValue: 'km',
        pluralValue: 'km',
    },
    {
        label: 'Pauschal',
        singularValue: 'Pauschal',
        pluralValue: 'Pauschal',
    },
    {
        label: 'Stunden',
        singularValue: 'Stunde',
        pluralValue: 'Stunden',
    },
    {
        label: 'Minuten',
        singularValue: 'Minute',
        pluralValue: 'Minuten',
    },
    {
        label: 'Stück',
        singularValue: 'Stück',
        pluralValue: 'Stück',
    },
];

<div class="dialog-overlay" (click)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <h2>{{ headline }}</h2>

        <!--============================================-->
        <!-- Error Message -->
        <!--============================================-->
        <div *ngIf="getErrorInfo(outgoingMessage) as error" class="info-note error-note">
            <mat-icon class="error medium-icon">error</mat-icon>
            <div>
                <h3>{{ error.title }}</h3>
                <div [innerHTML]="error.body"></div>
            </div>
        </div>

        <!--============================================-->
        <!-- Message Head -->
        <!--============================================-->
        <table id="message-head">
            <tr>
                <td class="label">Empfänger</td>
                <td class="label">Datum</td>
            </tr>
            <tr>
                <td id="recipient-name" class="cell-grow">
                    <!--********** To Recipients **********-->
                    <div class="recipient-container" *ngFor="let recipient of outgoingMessage.email?.toRecipients">
                        <ng-container *ngIf="recipient.name || extractNameFromEmail(recipient.email)">
                            <span [matTooltip]="recipient.email">
                                {{ recipient.name || extractNameFromEmail(recipient.email) }}
                            </span>
                            <div class="label">{{ recipient.email }}</div>
                        </ng-container>

                        <ng-container *ngIf="!(recipient.name || extractNameFromEmail(recipient.email))">
                            <span [matTooltip]="recipient.email">{{ recipient.email }}</span>
                        </ng-container>
                    </div>
                </td>
                <td id="date-sent" class="cell-shrink">
                    {{ outgoingMessage.sentAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}
                </td>
            </tr>
            <tr *ngIf="outgoingMessage.email?.ccRecipients.length">
                <td class="label" colspan="2">Cc</td>
            </tr>
            <tr *ngIf="outgoingMessage.email?.ccRecipients.length">
                <td colspan="2">
                    <!--********** email?.Cc Recipients **********-->
                    <div class="recipient-container" *ngFor="let recipient of outgoingMessage.email?.ccRecipients">
                        <ng-container *ngIf="recipient.name || extractNameFromEmail(recipient.email)">
                            <span [matTooltip]="recipient.email">
                                {{ recipient.name || extractNameFromEmail(recipient.email) }}
                            </span>
                            <div class="label">{{ recipient.email }}</div>
                        </ng-container>

                        <ng-container *ngIf="!(recipient.name || extractNameFromEmail(recipient.email))">
                            <span [matTooltip]="recipient.email">{{ recipient.email }}</span>
                        </ng-container>
                    </div>
                </td>
            </tr>
            <tr *ngIf="outgoingMessage.email?.bccRecipients.length">
                <td class="label" colspan="2">Bcc</td>
            </tr>
            <tr *ngIf="outgoingMessage.email?.bccRecipients.length">
                <td colspan="2">
                    <!--********** Bcc Recipients **********-->
                    <div class="recipient-container" *ngFor="let recipient of outgoingMessage.email?.bccRecipients">
                        <ng-container *ngIf="recipient.name || extractNameFromEmail(recipient.email)">
                            <span [matTooltip]="recipient.email">
                                {{ recipient.name || extractNameFromEmail(recipient.email) }}
                            </span>
                            <div class="label">{{ recipient.email }}</div>
                        </ng-container>

                        <ng-container *ngIf="!(recipient.name || extractNameFromEmail(recipient.email))">
                            <span [matTooltip]="recipient.email">{{ recipient.email }}</span>
                        </ng-container>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="label" colspan="2">Absender</td>
            </tr>
            <tr>
                <td id="sender-name" class="cell-grow" colspan="2">
                    {{ getUsersFullName(outgoingMessage.createdBy) }}
                    <!--********** The user's email address is not always the address used to send. Example: User sends email and then changes his address **********-->
                    <!--<div class="label">{{getUsersEmailAddress(outgoingMessage.createdBy)}}</div>-->
                </td>
            </tr>
        </table>
        <!--============================================-->
        <!-- END Message Haed -->
        <!--============================================-->

        <!--============================================-->
        <!-- Message Body -->
        <!--============================================-->
        <div id="email-subject" class="bold">{{ outgoingMessage.subject }}</div>
        <div id="email-body" [quillContent]="outgoingMessage.body"></div>
        <!--============================================-->
        <!-- END Message Body -->
        <!--============================================-->

        <!--============================================-->
        <!-- Attachments -->
        <!--============================================-->
        <div class="label margin-top-30">Titel der Anhänge</div>
        <span class="attachment-description" *ngIf="outgoingMessage.areAttachmentsSeparated">
            Dokumente wurden getrennt versandt
        </span>
        <span class="attachment-description" *ngIf="!outgoingMessage.areAttachmentsSeparated">
            Dokumente wurden als Gesamt-PDF versandt
        </span>
        <div id="sent-attachments-container">
            <!--********** Send One Compound File **********-->
            <ng-container *ngFor="let document of outgoingMessage.attachedDocuments">
                <div class="attachment-container">
                    <div class="attachment-icon-and-title-container">
                        <mat-icon class="margin-right-10">attach_file</mat-icon>
                        <span [matTooltip]="document.title">{{ document.title }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
        <!--============================================-->
        <!-- END Attachments -->
        <!--============================================-->

        <div class="buttons-container">
            <button (click)="closeDialog()">Schließen</button>
        </div>
    </div>
</div>

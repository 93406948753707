import { Report } from '../../models/reports/report';
import { getApplicableReplacementValue } from '../car-valuation/get-applicable-replacement-value';
import { getRestorationValue } from './get-restoration-effort';

export function getRepairEffortToReplacementValueRatio(report: Report): number {
    const restorationEffort: number = getRestorationValue(report);

    if (!getApplicableReplacementValue(report)) return null;

    return restorationEffort / getApplicableReplacementValue(report);
}

import { formatNumberToGermanLocale } from './format-number-to-german-locale';

/**
 * Converts a number such as 0.2417 to a string such as "24,17 %"
 */
export function convertToPercent(number: number, decimalPlaces = 0) {
    if (number === null) {
        return '- %';
    }
    if (typeof number !== 'number') {
        //axLogger.debug(`Tried to convert a non-number to a percentage string. However, we expect a number as input. Input was "${util.inspect(number)}".`);
        // Useful for debugging. Shows the backtrace.
        // throw new Error('Debugging: Invalid input.');
        return `${number}`;
    }

    return (
        `${formatNumberToGermanLocale(number * 100, { decimalPlaces })} %`
            // When returning percentages, we don't need trailing zeros.
            .replace(',00', '')
            .replace(/(,\d)0/, '$1')
    );
}

import { FabricObject, FabricObjectProps, Group, GroupProps } from 'fabric';

// Default appearance of the resize and rotate controls of FabricObjects
// Previously we put these on the prototype of the FabricObject. Now with classes there is
// a static property ownDefaults on the FabricObject class. We might be using that in future for defaults.
// For now we are passing these default options to the constructor of each object we are creating.
export const DEFAULT_FABRIC_CONTROL_OPTIONS: Partial<FabricObjectProps> = {
    transparentCorners: false,
    borderColor: '#15A9E8',
    cornerColor: '#15A9E8',
    cornerStyle: 'circle',
    cornerSize: 10,
};

interface CustomFabricData {
    // Type of the fabric object e.g., "photo" in order to give it a special role inside autoiXpert.
    axType?: string;

    // Unique ID of the fabric object in order to identify and reference it. Fabric objects do not have an ID property.
    axId?: string;

    // Custom property to store the blur factor of a fabric object. This is used to create a blur pattern on the object.
    axBlurFactor?: number;
}

interface CustomFabricPhotoData extends CustomFabricData {
    axPhotoDescription?: string;
    // Add a custom property so angular knows where to position the card with the inputs for damage descriptions
    /**
     * TODO: This would be preferable outside the data property since this information does not need to be saved to the server.
     *       But currently, I see no way to extend typescript's fabric.IObjectOptions from within this script.
     */
    axPhotoDescriptionUICard?: {
        x: number;
        y: number;
        flippedVertically: boolean;
        offsetX: number;
    };

    // Position and size of the cropped area (if present). These values are relative to the original
    // image size. These must be adjusted to the actual image.scaleX and scaleY when applied to the canvas again.
    axCropAreaX?: number;
    axCropAreaY?: number;
    axCropAreaWidth?: number;
    axCropAreaHeight?: number;
}

interface AxFabricGroupProps extends GroupProps {
    data: CustomFabricPhotoData;
}

/**
 * Make sure we can create Groups with our custom properties.
 * https://github.com/fabricjs/fabric.js/issues/9845
 */
export class AxFabricGroup extends Group {
    constructor(objects: FabricObject[] = [], options: Partial<AxFabricGroupProps> = {}) {
        super(objects, options);
    }
}

/**
 * Make sure TypeScript knows about our custom properties when we access them.
 */
declare module 'fabric' {
    interface FabricObjectProps {
        data?: CustomFabricData;
    }
    interface FabricObject {
        data?: CustomFabricData;
        id?: string;
    }

    interface FabricImage {
        data?: CustomFabricPhotoData;
    }
}

/**
 * We put a custom attribute 'id' on fabric objects to identify objects used for the crop area. These objects are
 * only temporarily added to the canvas and need to be removed after closing the crop tool (or latest before saving
 * the photo information). The following code makes sure, that this custom ID is also serialized when the serializeCanvas()
 * function calls the toObject function on the canvas.
 */
const lastToObject = FabricObject.prototype.toObject;
FabricObject.prototype.toObject = function (arr: string[] = []) {
    return lastToObject.call(this, arr.concat(['id']));
};

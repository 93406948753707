import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import moment from 'moment';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import {
    CarRegistrationScannerResponse,
    CarRegistrationScannerValue,
} from '@autoixpert/external-apis/car-registration-scanner-response';
import { joinList } from '@autoixpert/lib/arrays/join-list';
import { getContactPersonFullNameWithOrganization } from '@autoixpert/lib/contact-people/get-contact-person-full-name-with-organization';
import { isNameOrOrganizationFilled } from '@autoixpert/lib/contact-people/is-name-or-organization-filled';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { fadeOutAnimation } from '../../../../shared/animations/fade-out.animation';
import { slideInAndOutVertically } from '../../../../shared/animations/slide-in-and-out-vertical.animation';
import { slideInHorizontally } from '../../../../shared/animations/slide-in-horizontally.animation';
import { getSalutations } from '../../../../shared/libraries/contacts/get-salutations';
import { getPhotoFromFile } from '../../../../shared/libraries/photos/get-photo-from-file';
import { ResizePhotoResult, resizePhoto } from '../../../../shared/libraries/photos/resize-photo';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { CarRegistrationScannerService } from '../../../../shared/services/car-registration-recognition.service';
import { ContactPersonService } from '../../../../shared/services/contact-person.service';
import { OriginalPhotoService, PhotoUpload } from '../../../../shared/services/original-photo.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { ScreenTitleService } from '../../../../shared/services/screen-title.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { CAR_REGISTRATION_SCAN_PHOTO_TITLE } from '../../../../shared/static-data/default-photo-titles';
import { FeathersQuery } from '../../../../shared/types/feathers-query';

@Component({
    selector: 'car-registration-scanner-dialog',
    templateUrl: 'car-registration-scanner-dialog.component.html',
    styleUrls: ['car-registration-scanner-dialog.component.scss'],
    animations: [
        fadeOutAnimation(),
        slideInHorizontally(400),
        fadeOutAnimation(400),
        slideInAndOutVertically(400),
        dialogEnterAndLeaveAnimation(),
    ],
})
export class CarRegistrationScannerDialogComponent implements OnInit, OnChanges {
    constructor(
        private toastService: ToastService,
        private domSanitizer: DomSanitizer,
        private carRegistrationScannerService: CarRegistrationScannerService,
        private apiErrorService: ApiErrorService,
        private reportDetailsService: ReportDetailsService,
        private screenTitleService: ScreenTitleService,
        private originalPhotoService: OriginalPhotoService,
        private dialog: MatDialog,
        private contactPersonService: ContactPersonService,
    ) {}

    @Input() report: Report;
    @Input() photoId: string;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() reportChange: EventEmitter<Report> = new EventEmitter<Report>();

    // Photo Upload
    public uploader: FileUploader;
    public fileOverBodyTimeoutCache: number;
    public fileIsOverBody: boolean = false;
    public fileIsOverDropZone: boolean = false;
    public uploadedPhotoId: string;

    // Photo
    public photoSource: SafeResourceUrl;
    private photoNaturalWidth: number;
    private photoNaturalHeight: number;
    public photoWidth: number;
    public photoHeight: number;

    // Results
    public recognitionResponse: CarRegistrationScannerResponse;
    public carOwner: ContactPerson = new ContactPerson();
    public car: Car = new Car();
    public ownerIsClaimant: boolean = null;
    public outdatedGeneralInspectionWarning: string;
    // public tireDimensionFirstAxis: string;
    // public tireDimensionSecondAxis: string;
    public misrecognizedFieldNames: HighlightAreaName[] = [];
    public betterScanResultsTipVisible: boolean;
    public scanWarnings: string[] = [];

    public filteredSalutations: string[];

    public selectedHighlightArea: HighlightArea;
    public highlightAreaOfHoveredInput: HighlightArea;
    public availableHighlightAreas: HighlightArea[] = [];

    public scanPending: boolean = false;

    // contact person matching
    // this is the contact info we suggest to the user (e.g. in info note)
    protected suggestedMatchingContactPerson?: ContactPerson = null;

    // this is a copy of the suggested contact info put into the form
    protected selectedMatchingContactPerson?: ContactPerson = null;

    // flag to hide the info note when we only partially matched a contact person
    protected hideContactPersonInfoNote: boolean = false;
    // flag to hide the second phone number input
    protected secondPhoneNumberShown: boolean = false;

    // flags that indicate if the user has already entered phone/email info
    // in the report before. In this case we don't want to suggest/overwrite.
    protected phoneAlreadySet: boolean = false;
    protected emailAlreadySet: boolean = false;

    ngOnInit() {
        this.getDataFromReport();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['photoId']) {
            /**
             * Downloading the photo file from the server or from the local IndexedDB and getting the vehicle registration scan results
             * may happen in parallel.
             */
            this.loadPhoto();
            this.readDataFromImage();
        } else {
            this.initializeCarRegistrationUploader();
        }
    }

    //*****************************************************************************
    //  Get Data From Report
    //****************************************************************************/
    private getDataFromReport(): void {
        this.car = JSON.parse(JSON.stringify(this.report.car));
        // Ownership must be determined before copying to get the data from the right target
        this.ownerIsClaimant = this.report.claimant.isOwner;
        this.carOwner = JSON.parse(JSON.stringify(this.getTargetContactPerson()));
    }

    private getTargetContactPerson(): ContactPerson {
        return this.ownerIsClaimant
            ? this.report.claimant.contactPerson
            : this.report.ownerOfClaimantsCar.contactPerson;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get Data From Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Upload
    //****************************************************************************/
    private initializeCarRegistrationUploader(): void {
        this.uploader = new FileUploader({
            url: `/api/v0/reports/${this.report._id}/photoFiles`,
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
        });

        this.uploader.onAfterAddingFile = async (item: FileItem) => {
            //*****************************************************************************
            //  Check File Format
            //****************************************************************************/
            // If the mime type is neither png nor jpeg, remove the image from the queue
            if (!['image/jpeg', 'image/jpg'].includes(item._file.type)) {
                this.toastService.error(
                    'Kein JPEG',
                    `Bitte lade Fotos nur im JPEG-Format hoch.<br>Das von dir hochgeladene Bild "${item._file.name}" hat den Dateityp "${item._file.type}".`,
                );
                return;
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Check File Format
            /////////////////////////////////////////////////////////////////////////////*/

            const photo: Photo = await getPhotoFromFile(item._file, this.report.photos.length);
            photo.description = CAR_REGISTRATION_SCAN_PHOTO_TITLE;
            /**
             * Include the car registration in the report but not in the residual value exchange since it contains
             * personal information.
             */
            photo.versions.report.included = true;
            this.uploadedPhotoId = photo._id;
            this.report.photos.push(photo);

            /////////////////////////////////////////////////////////////////////////////*/
            //  Reduce Image Size
            /////////////////////////////////////////////////////////////////////////////*/
            /**
             * Reduce the image size so that the image upload now and rendering photos later works fast.
             */
            const resizedPhotoResult: ResizePhotoResult = await resizePhoto({
                photoFileOrBlob: item._file,
                targetWidth: 3000,
            });
            photo.height = resizedPhotoResult.dimensions.height;
            photo.width = resizedPhotoResult.dimensions.width;
            photo.size = resizedPhotoResult.photoBlob.size;

            this.photoSource = this.domSanitizer.bypassSecurityTrustUrl(
                window.URL.createObjectURL(resizedPhotoResult.photoBlob),
            );
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Reduce Image Size
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Upload
            //****************************************************************************/
            try {
                await this.reportDetailsService.patch(this.report, {
                    // The photo must exist on the server for the server-side recognition routine to run.
                    waitForServer: true,
                });
            } catch (error) {
                this.toastService.error(
                    'Gutachten nicht gespeichert',
                    'Vor dem Upload des Fahrzeugscheins muss das Gutachten-Objekt auf dem Server gespeichert werden, was aber nicht funktionierte.<br><br>Bitte versuche es erneut. Sollte das Problem weiterhin auftreten, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
                throw new AxError({
                    code: 'SAVING_REPORT_BEFORE_UPLOADING_CAR_REGISTRATION_PHOTO_FAILED',
                    message: `The report object could not be saved before uploading the car registration photo.`,
                    error,
                });
            }

            try {
                await this.originalPhotoService.create(
                    {
                        _id: `${this.report._id}-${photo._id}`,
                        blob: resizedPhotoResult.photoBlob,
                    },
                    {
                        // The photo must exist on the server for the server-side recognition routine to run.
                        waitForServer: true,
                    },
                );
            } catch (error) {
                this.toastService.error(
                    'Foto nicht gespeichert',
                    'Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    error,
                );
                throw new AxError({
                    code: 'UPLOADING_CAR_REGISTRATION_PHOTO_FAILED',
                    message: `The photo binary could not be saved to the server. Please have a look at the error details.`,
                    error,
                });
            }

            this.photoId = photo._id;
            this.readDataFromImage();
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Upload
            /////////////////////////////////////////////////////////////////////////////*/
        };
    }

    /**
     * Show drop zone
     */
    @HostListener('body:dragover', ['$event'])
    onFileOverBody() {
        clearTimeout(this.fileOverBodyTimeoutCache);

        this.fileIsOverBody = true;
        // Clear state if we didn't get another body drag event within 500ms
        this.fileOverBodyTimeoutCache = window.setTimeout(() => (this.fileIsOverBody = false), 500);
    }

    /**
     * Event handler which listens to the mousein and mouseout event if the user drags a file
     */
    public onFileOverDropZone(fileOver: boolean): void {
        if (fileOver === true) {
            this.fileIsOverDropZone = true;
        } else {
            this.fileIsOverDropZone = false;
            this.fileIsOverBody = false;
        }
    }

    public onFileDrop(): void {
        // Disable the drop zone as soon as content is dropped
        this.fileIsOverBody = false;
    }

    public getUploadItem(): PhotoUpload {
        return this.originalPhotoService.getPhotoUpload(this.uploadedPhotoId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Upload
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Photo
    //****************************************************************************/

    private async loadPhoto(): Promise<void> {
        this.scanPending = true;

        // Download the photo file, save it to a local blob and then start fabric.js.
        try {
            const originalPhotoBlob: Blob = await this.originalPhotoService.get(`${this.report._id}-${this.photoId}`);
            this.photoSource = this.domSanitizer.bypassSecurityTrustResourceUrl(
                window.URL.createObjectURL(originalPhotoBlob),
            );
        } catch (error) {
            this.toastService.error(
                'Foto konnte nicht geladen werden',
                'Bitte aktualisiere die Seite und versuche es erneut.',
            );
            console.error('Error loading the photo from the server.', { error });
        }
    }

    public determineNaturalDimensions(event: Event): void {
        this.photoNaturalWidth = (event.target as HTMLImageElement).naturalWidth;
        this.photoNaturalHeight = (event.target as HTMLImageElement).naturalHeight;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Photo
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Resize Photo
    //****************************************************************************/
    @HostListener('window:resize', ['$event'])
    public resizePhoto(): void {
        // If you change these, change the SCSS as well at the rule for .dialog-container
        const maxWidthOfDataInputsCard = 500;
        const photoMaxWidth = window.innerWidth * 0.9 - maxWidthOfDataInputsCard;
        const photoMaxHeight = window.innerHeight * 0.95 - 60 - 2 * 20;

        const photoSizeFactor = Math.min(
            photoMaxWidth / this.photoNaturalWidth,
            photoMaxHeight / this.photoNaturalHeight,
        );

        // Use the scaled version or the original dimensions, whichever is smaller. This way, if the photo is smaller than the maximal dimensions, we don't stretch it.
        this.photoWidth = Math.min(this.photoNaturalWidth * photoSizeFactor, this.photoNaturalWidth);
        this.photoHeight = Math.min(this.photoNaturalHeight * photoSizeFactor, this.photoNaturalHeight);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Resize Photo
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Recognition
    //****************************************************************************/
    private readDataFromImage(): void {
        this.scanPending = true;
        this.carRegistrationScannerService.get(this.report._id, this.photoId).subscribe({
            next: (recognitionResponse) => {
                this.fillRecognizedData(recognitionResponse);
                this.recognitionResponse = recognitionResponse;
                this.scanPending = false;
                this.displayTipForBetterScanResults(recognitionResponse.warnings);
            },
            error: (error) => {
                this.recognitionResponse = new CarRegistrationScannerResponse();
                this.scanPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        OLD_GERMAN_CAR_REGISTRATION: {
                            title: 'Alter Fahrzeugschein',
                            body: 'Der alte Fahrzeugschein kann nicht verarbeitet werden. Es wird nur die Zulassungsbescheinigung Teil I unterstützt, die im Volksmund immer noch Fahrzeugschein heißt.',
                        },
                        NON_GERMAN_CAR_REGISTRATION: {
                            title: 'Zulassungsbescheinigung Teil I nicht erkannt',
                            body: 'Das Foto wurde nicht als Zulassungsbescheinigung Teil I erkannt. Nur deutsche Fahrzeugscheine werden unterstützt.<br><br>Falls dies eine deutsche Zulassungsbescheinigung Teil I ist, probiere es mit einem besseren Foto davon erneut.<br><br>Als letzte Lösung bleibt das manuelle Abtippen.',
                        },
                    },
                    defaultHandler: {
                        title: 'Fahrzeugschein nicht erkennbar',
                        body: 'Hast du den Fahrzeugschein gerade und gut belichtet abfotografiert? Falls es trotzdem nicht funktioniert, kontaktiere die <a href="/Hilfe">Hotline</a>.',
                    },
                });
            },
        });
    }

    private fillRecognizedData(recognitionResponse: CarRegistrationScannerResponse): void {
        // Car Owner
        this.carOwner.organization = recognitionResponse.carOwner.organization?.value;
        this.carOwner.firstName = recognitionResponse.carOwner.firstName?.value;
        this.carOwner.lastName = recognitionResponse.carOwner.lastName?.value;
        this.carOwner.streetAndHouseNumberOrLockbox = recognitionResponse.carOwner.streetAndHouseNumber?.value;
        this.carOwner.zip = recognitionResponse.carOwner.zip?.value;
        this.carOwner.city = recognitionResponse.carOwner.city?.value;
        // Car
        this.car.vin = recognitionResponse.car.vin?.value;
        this.car.firstRegistration = toIsoDate(recognitionResponse.car.firstRegistration?.value);
        this.car.latestRegistration = toIsoDate(recognitionResponse.car.latestRegistration?.value);
        this.car.licensePlate = recognitionResponse.car.licensePlate?.value;
        const nextGeneralInspection: string = recognitionResponse.car.nextGeneralInspection?.value;

        // check if we can find any contact info about the car holder in the users contacts
        void this.lookupContactDataForCarOwner();

        if (nextGeneralInspection) {
            // Only use GI date if it's either up to 3 months old or in the future. Otherwise, only display an outdated warning.
            if (moment(nextGeneralInspection).isAfter(moment().subtract(3, 'months'))) {
                this.car.nextGeneralInspection = toIsoDate(nextGeneralInspection);
            }
            // Next GI was longer than 3 months ago -> It's probably out of date. Instead of having to delete the date manually, only set it to the hint.
            else {
                this.outdatedGeneralInspectionWarning = `${moment(nextGeneralInspection).format(
                    'MM.YYYY',
                )} (> 3 Monate abgelaufen)`;
            }
        }

        this.availableHighlightAreas = [];
        const highlightAreasFromServer: { name: HighlightAreaName; recognizedValue: CarRegistrationScannerValue }[] = [
            {
                name: 'organization',
                recognizedValue: recognitionResponse.carOwner.organization,
            },
            {
                name: 'firstName',
                recognizedValue: recognitionResponse.carOwner.firstName,
            },
            {
                name: 'lastName',
                recognizedValue: recognitionResponse.carOwner.lastName,
            },
            {
                name: 'street',
                recognizedValue: recognitionResponse.carOwner.streetAndHouseNumber,
            },
            {
                name: 'zip',
                recognizedValue: recognitionResponse.carOwner.zip,
            },
            {
                name: 'city',
                recognizedValue: recognitionResponse.carOwner.city,
            },
            {
                name: 'vin',
                recognizedValue: recognitionResponse.car.vin,
            },
            {
                name: 'firstRegistration',
                recognizedValue: recognitionResponse.car.firstRegistration,
            },
            {
                name: 'latestRegistration',
                recognizedValue: recognitionResponse.car.latestRegistration,
            },
            {
                name: 'licensePlate',
                recognizedValue: recognitionResponse.car.licensePlate,
            },
            {
                name: 'nextGeneralInspection',
                recognizedValue: recognitionResponse.car.nextGeneralInspection,
            },
        ];

        for (const highlightAreaFromServer of highlightAreasFromServer) {
            if (highlightAreaFromServer.recognizedValue?.value) {
                this.availableHighlightAreas.push({
                    name: highlightAreaFromServer.name,
                    topLeft: {
                        x:
                            (highlightAreaFromServer.recognizedValue.boundingBox.topLeft.x / this.photoNaturalWidth) *
                            100,
                        y:
                            (highlightAreaFromServer.recognizedValue.boundingBox.topLeft.y / this.photoNaturalHeight) *
                            100,
                    },
                    // Width & height in %
                    width:
                        ((highlightAreaFromServer.recognizedValue.boundingBox.topRight.x -
                            highlightAreaFromServer.recognizedValue.boundingBox.topLeft.x) /
                            this.photoNaturalWidth) *
                        100,
                    height:
                        ((highlightAreaFromServer.recognizedValue.boundingBox.bottomLeft.y -
                            highlightAreaFromServer.recognizedValue.boundingBox.topLeft.y) /
                            this.photoNaturalHeight) *
                        100,
                    recognizedValue: this.formatRecognizedValue(
                        highlightAreaFromServer.name,
                        highlightAreaFromServer.recognizedValue.value,
                    ),
                });
            }
        }
    }

    private formatRecognizedValue(name: HighlightAreaName, value: string): string {
        switch (name) {
            case 'organization':
            case 'firstName':
            case 'lastName':
            case 'street':
            case 'zip':
            case 'city':
            case 'vin':
                return value;
            case 'licensePlate':
                return value
                    .replace('-', ' ') // First dash -> space
                    .replace('-', ''); // Second dash -> remove
            case 'nextGeneralInspection':
                return value ? moment(value, ['MM.YYYY', moment.ISO_8601]).format('MM.YYYY') : '';
            case 'firstRegistration':
            case 'latestRegistration':
                return value ? moment(value, ['DD.MM.YYYY', moment.ISO_8601]).format('DD.MM.YYYY') : '';
        }
    }

    public needsExtraLetterSpacing(highlightArea: HighlightArea): boolean {
        if (!highlightArea) return false;

        const areaNamesWithExtraLetterSpacing: HighlightAreaName[] = [
            'organization',
            'firstName',
            'lastName',
            'street',
            'zip',
            'city',
            'vin',
            'licensePlate',
        ];
        return areaNamesWithExtraLetterSpacing.includes(highlightArea.name);
    }

    public selectHighlightArea(areaName: HighlightAreaName) {
        // The if statement prevents a cyclic condition with the license plate input component and autofocus.
        // When we set autofocus, the license plate emits a focus event which calls this method which re-sets autofocus, etc.
        if (!this.selectedHighlightArea || this.selectedHighlightArea.name !== areaName) {
            this.selectedHighlightArea = this.availableHighlightAreas.find(
                (highlightArea) => highlightArea.name === areaName,
            );
            this.highlightAreaOfHoveredInput = null;
        }
    }

    public showHighlightAreaOfHoveredInput(areaName: HighlightAreaName) {
        this.selectedHighlightArea = null;
        this.highlightAreaOfHoveredInput = this.availableHighlightAreas.find(
            (highlightArea) => highlightArea.name === areaName,
        );
    }

    public hideHighlightAreaOfHoveredInput() {
        this.highlightAreaOfHoveredInput = undefined;
    }

    public getCurrentValue(areaName: HighlightAreaName): string {
        let value: string;
        switch (areaName) {
            case 'organization':
                value = this.carOwner.organization;
                break;
            case 'firstName':
                value = this.carOwner.firstName;
                break;
            case 'lastName':
                value = this.carOwner.lastName;
                break;
            case 'street':
                value = this.carOwner.streetAndHouseNumberOrLockbox;
                break;
            case 'zip':
                value = this.carOwner.zip;
                break;
            case 'city':
                value = this.carOwner.city;
                break;
            case 'vin':
                value = (this.car.vin || '').toUpperCase();
                break;
            case 'licensePlate':
                value = this.car.licensePlate;
                break;
            case 'nextGeneralInspection':
                value = this.car.nextGeneralInspection;
                break;
            case 'firstRegistration':
                value = this.car.firstRegistration;
                break;
            case 'latestRegistration':
                value = this.car.latestRegistration;
                break;
        }

        // If the value is currently empty, use the original value. The next general inspection may be correctly recognized but not inserted into the input because the date lies too far in the past.
        if (!value) {
            value = this.getAvailableHightlightArea(areaName)?.recognizedValue;
        }

        return this.formatRecognizedValue(areaName, value);
    }

    private getAvailableHightlightArea(areaName: HighlightAreaName): HighlightArea {
        return this.availableHighlightAreas.find((area) => area.name === areaName);
    }

    public getArrayFrom(value: string): string[] {
        if (!value) {
            return [];
        }

        return Array.from(value);
    }

    //*****************************************************************************
    //  Result Check
    //****************************************************************************/
    /**
     * If the backend service didn't recognize some fields, tell the user about it.
     */
    private displayTipForBetterScanResults(warnings: CarRegistrationScannerResponse['warnings']): void {
        this.misrecognizedFieldNames = [];
        this.betterScanResultsTipVisible = false;

        const nameFields: HighlightAreaName[] = ['organization', 'firstName', 'lastName'];
        const otherFields: HighlightAreaName[] = [
            'street',
            'zip',
            'city',
            'vin',
            'firstRegistration',
            'latestRegistration',
            'licensePlate',
            'nextGeneralInspection',
        ];
        const organizationRecognized: boolean = !!this.getAvailableHightlightArea('organization');
        const firstNameRecognized: boolean = !!this.getAvailableHightlightArea('firstName');
        const lastNameRecognized: boolean = !!this.getAvailableHightlightArea('lastName');
        if (!organizationRecognized && !firstNameRecognized && !lastNameRecognized) {
            this.misrecognizedFieldNames.push(...nameFields);
        }
        // Company is recognized
        else if (!firstNameRecognized && !lastNameRecognized) {
            // everything ok. Company may stand alone.
        } else if (!firstNameRecognized) {
            this.misrecognizedFieldNames.push('firstName');
        } else if (!lastNameRecognized) {
            this.misrecognizedFieldNames.push('lastName');
        } else {
            // Everything recognized. Fine.
        }

        for (const otherField of otherFields) {
            if (!this.getAvailableHightlightArea(otherField)) {
                this.misrecognizedFieldNames.push(otherField);
            }
        }

        if (this.getFirstRegistrationWarning()) {
            this.misrecognizedFieldNames.push('firstRegistration');
        }

        if (this.getLatestRegistrationWarning()) {
            this.misrecognizedFieldNames.push('latestRegistration');
        }

        if (this.outdatedGeneralInspectionWarning) {
            this.misrecognizedFieldNames.push('nextGeneralInspection');
        }

        if (this.misrecognizedFieldNames.length) {
            this.betterScanResultsTipVisible = true;
        }

        //*****************************************************************************
        //  Warnings
        //****************************************************************************/
        // Photo Dimensions
        if (this.photoHeight > this.photoWidth) {
            this.scanWarnings.push(
                'Fotografiere den Fahrzeugschein am besten im Querformat und lass ihn das Foto voll ausfüllen.',
            );
        }

        const textInBackgroundError = warnings.find((warning) => warning.code === 'TEXT_IN_BACKGROUND');
        if (textInBackgroundError) {
            const wordsInBackground: string = textInBackgroundError.data.annotations
                .slice(0, 3)
                .map((annotation) => `"${annotation.description}"`)
                .join(', ');
            let warning = `Text im Hintergrund kann zu unerwarteten Ergebnissen führen. Hier: ${wordsInBackground}`;
            if (textInBackgroundError.data.annotations.length > 3) {
                warning += ' & mehr';
            }
            this.scanWarnings.push(warning);
        }

        if (this.misrecognizedFieldNames.includes('vin')) {
            this.scanWarnings.push(
                `VIN nicht erkannt. Wurden die Werte auf dem Schein akkurat in die Felder gedruckt?`,
            );
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Warnings
        /////////////////////////////////////////////////////////////////////////////*/
    }

    public getMisrecognizedFieldNamesString(): string {
        const translatedNames: string[] = this.misrecognizedFieldNames.map((name) =>
            this.translateHighlightAreaName(name),
        );

        const germanListString = joinList(translatedNames);

        if (!germanListString) {
            return '';
        }

        return germanListString[0].toUpperCase() + germanListString.slice(1);
    }

    public getFirstRegistrationWarning(): string {
        if (!this.car.firstRegistration) return null;

        const firstRegistration = moment(this.car.firstRegistration);

        if (firstRegistration.isAfter()) {
            return 'liegt in Zukunft';
        }

        if (firstRegistration.isBefore('1900-01-01T00:00:00.000')) {
            return 'liegt weit zurück';
        }
    }

    public getLatestRegistrationWarning(): string {
        if (!this.car.latestRegistration) return null;

        const latestRegistration = moment(this.car.latestRegistration);

        if (latestRegistration.isAfter()) {
            return 'liegt in Zukunft';
        }

        if (this.car.firstRegistration && latestRegistration.isBefore(this.car.firstRegistration)) {
            return 'liegt vor Erstzulassung';
        }

        if (latestRegistration.isBefore('1900-01-01T00:00:00.000')) {
            return 'liegt weit zurück';
        }
    }

    public closeScanWarning(warning: string): void {
        const index = this.scanWarnings.indexOf(warning);
        if (index > -1) {
            this.scanWarnings.splice(index, 1);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Result Check
    /////////////////////////////////////////////////////////////////////////////*/

    private translateHighlightAreaName(areaName: HighlightAreaName): string {
        switch (areaName) {
            case 'organization':
                return 'Firma';
            case 'firstName':
                return 'Vorname';
            case 'lastName':
                return 'Nachname';
            case 'street':
                return 'Straße';
            case 'zip':
                return 'PLZ';
            case 'city':
                return 'Ort';
            case 'vin':
                return 'VIN';
            case 'licensePlate':
                return 'Kennzeichen';
            case 'nextGeneralInspection':
                return 'nächste HU';
            case 'firstRegistration':
                return 'erste Zulassung';
            case 'latestRegistration':
                return 'letzte Zulassung';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Recognition
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact Person
    //****************************************************************************/
    public insertCityIntoModel(city: string): void {
        this.carOwner.city = city;
    }

    /**
     * Called when the user clicks on an address autocomplete option.
     */
    public insertAddressAutocompletion(addressResult: Partial<ContactPerson>) {
        Object.keys(addressResult).forEach((key) => {
            this.carOwner[key] = addressResult[key];
        });
    }

    public filterSalutations(filterTerm: string) {
        this.filteredSalutations = getSalutations(filterTerm);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Person
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact Data Matching
    //****************************************************************************/

    /**
     * Create a search query that retrieves all contact persons from the users
     * contacts that have the same name + zip code as the car holder. Sort them
     * based on the modification date (most recent first).
     */
    private getContactPersonSearchQuery(): FeathersQuery {
        // The query includes the organization, although that might be undefined (if not set).
        // That's ok, because it will be ignored in the query.
        return {
            firstName: this.carOwner.firstName,
            lastName: this.carOwner.lastName,
            organization: this.carOwner.organization,
            zip: this.carOwner.zip,
            $sort: {
                updatedAt: -1,
            },
        };
    }

    /**
     * Check if we can find any contact info about the car holder in the users contacts.
     */
    private async lookupContactDataForCarOwner(): Promise<void> {
        this.checkIfContactDataIsAlreadySet();

        try {
            // Only check if a name or organization is set. Otherwise we don't have enough data to perform the lookup.
            if (isNameOrOrganizationFilled(this.carOwner)) {
                /**
                 * Try to find an exact match (name, plz + street match), otherwise use the first match.
                 */
                const searchQuery = this.getContactPersonSearchQuery();
                const contactPeople = await this.contactPersonService.find(searchQuery).toPromise();
                const exactMatch = contactPeople.find((contact) => {
                    return (
                        !!contact.streetAndHouseNumberOrLockbox &&
                        !!this.carOwner.streetAndHouseNumberOrLockbox &&
                        contact.streetAndHouseNumberOrLockbox.toLowerCase() ===
                            this.carOwner.streetAndHouseNumberOrLockbox.toLowerCase()
                    );
                });
                const contactPerson = exactMatch ?? contactPeople[0];

                if (contactPerson) {
                    this.suggestedMatchingContactPerson = contactPerson;

                    if (exactMatch) {
                        this.selectMatchingContactPerson();
                    }
                }
            }
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Abgleich mit Kontakten konnte nicht durchgeführt werden.',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * When the user confirms to use the suggested contact information, we store
     * a copy of the data in a separate variable.
     */
    protected selectMatchingContactPerson(): void {
        this.selectedMatchingContactPerson = structuredClone(this.suggestedMatchingContactPerson);
    }

    /**
     * Determine if the user has already entered a phone or email for the car holder.
     * In that case we don't display the suggestion to insert that data (which would
     * overwrite the current one). Because the claimant does not need to be the
     * car holder, we need to check the respective one and update this check each
     * time the user toggles the "claimant is holder" checkbox
     */
    protected checkIfContactDataIsAlreadySet(): void {
        this.phoneAlreadySet = this.ownerIsClaimant
            ? !!this.report.claimant.contactPerson.phone
            : !!this.report.ownerOfClaimantsCar.contactPerson.phone;
        this.emailAlreadySet = this.ownerIsClaimant
            ? !!this.report.claimant.contactPerson.email
            : !!this.report.ownerOfClaimantsCar.contactPerson.email;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Data Matching
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Merge Into Report
    //****************************************************************************/
    /**
     * Assign the values unless they're empty.
     */
    public mergeValuesIntoReport() {
        // Owner
        const targetContactPerson: ContactPerson = this.getTargetContactPerson();
        targetContactPerson.organization = this.carOwner.organization;
        targetContactPerson.salutation = this.carOwner.salutation;
        targetContactPerson.firstName = this.carOwner.firstName;
        targetContactPerson.lastName = this.carOwner.lastName;
        targetContactPerson.streetAndHouseNumberOrLockbox = this.carOwner.streetAndHouseNumberOrLockbox;
        targetContactPerson.zip = this.carOwner.zip;
        targetContactPerson.city = this.carOwner.city;
        if (targetContactPerson.organizationType === 'ownerOfClaimantsCar') {
            this.report.claimant.isOwner = false;
        }

        // Matched contact data (email and phone)
        if (this.selectedMatchingContactPerson) {
            if (!this.emailAlreadySet) {
                targetContactPerson.email = this.selectedMatchingContactPerson.email;
            }

            if (!this.phoneAlreadySet) {
                targetContactPerson.phone = this.selectedMatchingContactPerson.phone;
                targetContactPerson.phone2 = this.selectedMatchingContactPerson.phone2;
            }
        }

        // Car
        this.report.car.licensePlate = this.car.licensePlate;
        this.report.car.vin = this.car.vin;
        this.report.car.firstRegistration = this.car.firstRegistration;
        this.report.car.latestRegistration = this.car.latestRegistration;
        this.report.car.nextGeneralInspection = this.car.nextGeneralInspection;

        // First and latest registration being equal means the car has never been transferred.
        if (
            this.report.car.firstRegistration &&
            this.report.car.firstRegistration === this.report.car.latestRegistration
        ) {
            this.report.car.numberOfPreviousOwners = '0';
        }

        // this.report.car.tires.filter(tire => tire.axle === 1).forEach(tire => tire.type = this.tireDimensionFirstAxis);
        // this.report.car.tires.filter(tire => tire.axle === 2).forEach(tire => tire.type = this.tireDimensionSecondAxis);

        this.screenTitleService.setScreenTitleForReport(this.report);

        this.emitReportChange();
    }

    /**
     * If the user chose to copy the car data from a previous report, insert it directly into the report.
     * Don't wait for the dialog to be closed.
     */
    protected insertCarFromPreviousReport(car: Car) {
        Object.assign(this.report.car, car);
        this.emitReportChange();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Merge Into Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog(confirmClosing: boolean = false): void {
        if (
            confirmClosing &&
            (this.carOwner.organization ||
                this.carOwner.salutation ||
                this.carOwner.firstName ||
                this.carOwner.lastName ||
                this.carOwner.streetAndHouseNumberOrLockbox ||
                this.carOwner.zip ||
                this.carOwner.city ||
                this.car.vin ||
                this.car.licensePlate ||
                this.car.nextGeneralInspection ||
                this.car.firstRegistration ||
                this.car.latestRegistration)
        ) {
            this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Daten verwerfen?',
                        content: 'Möchtest du die Daten wirklich verwerfen?',
                        confirmLabel: 'Weg damit',
                        cancelLabel: 'Behalten',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .subscribe({
                    next: (result) => {
                        if (result) {
                            this.close.emit();
                        }
                    },
                });
        } else {
            this.close.emit();
        }
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeDialog(!!this.recognitionResponse);
        }
    }

    public stopPropagation(event: Event): void {
        event.stopPropagation();
    }

    public emitReportChange(): void {
        this.reportChange.emit(this.report);
    }

    @HostListener('window:keydown', ['$event'])
    private handleKeyboardEvent(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.mergeValuesIntoReport();
                }
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly getContactPersonFullNameWithOrganization = getContactPersonFullNameWithOrganization;
}

type HighlightAreaName =
    | 'organization'
    | 'firstName'
    | 'lastName'
    | 'street'
    | 'zip'
    | 'city'
    | 'vin'
    | 'licensePlate'
    | 'modelCode'
    | 'nextGeneralInspection'
    | 'firstRegistration'
    | 'latestRegistration'
    | 'tireDimensionFirstAxis'
    | 'tireDimensionSecondAxis';

interface HighlightArea {
    name: HighlightAreaName;
    topLeft: HighlightAreaPoint;
    width: number;
    height: number;
    recognizedValue: string;
}

interface HighlightAreaPoint {
    x: number;
    y: number;
}

<!--********** Selector for type **********-->
<div id="select-type">
    <div
        role="button"
        class="select-type-report"
        [ngClass]="{ 'is-type-active': selectType === 'report' }"
        (click)="selectType = 'report'">
        <mat-icon>assignment</mat-icon>
        <span>Gutachten</span>
    </div>
    <div
        role="button"
        class="select-type-invoice"
        [ngClass]="{ 'is-type-active': selectType === 'invoice' }"
        (click)="selectType = 'invoice'">
        <mat-icon>euro</mat-icon>
        <span>Rechnung</span>
    </div>
</div>

<!--********** Selectors for Report or Invoice **********-->
<div id="report-or-invoice-selector">
    <!-- Report selection-->
    <select-report-dropdown
        *ngIf="selectType === 'report'"
        [theme]="'light'"
        [report]="selectedReport"
        (reportSelected)="handleReportSelected($event)"></select-report-dropdown>

    <!-- Invoice selection-->
    <select-invoice-dropdown
        *ngIf="selectType === 'invoice'"
        [invoice]="selectedInvoice"
        [theme]="'light'"
        [reportId]="selectedReport?._id"
        (invoiceSelected)="handleInvoiceSelected($event)"></select-invoice-dropdown>
</div>

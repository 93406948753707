import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core/index';
import { MatLegacySelectChange } from '@angular/material/legacy-select/index';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { toGermanDate } from '@autoixpert/lib/ax-luxon';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { createInvoice } from '@autoixpert/lib/invoices/create-invoice';
import { createInvoiceFromReport } from '@autoixpert/lib/invoices/create-invoice-from-report';
import { getInvoiceReportData } from '@autoixpert/lib/invoices/get-invoice-report-data-from-report';
import { convertToCurrencyString } from '@autoixpert/lib/placeholder-values/convert-to-currency-string';
import { concatReportCategories } from '@autoixpert/lib/report/concat-report-categories';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { ReportData } from '@autoixpert/models/invoices/report-data';
import { LabelConfig } from '@autoixpert/models/labels/label-config';
import { Report } from '@autoixpert/models/reports/report';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { ReportListSortTypes } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { slideInAndOutHorizontally } from '../../animations/slide-in-and-out-horizontally.animation';
import { slideInAndOutVertically } from '../../animations/slide-in-and-out-vertical.animation';
import { calculateTotalGrossFees } from '../../libraries/fees/calculate-total-gross-fees';
import { calculateTotalNetFees } from '../../libraries/fees/calculate-total-net-fees';
import { ApiErrorService } from '../../services/api-error.service';
import { DownloadService } from '../../services/download.service';
import { InvoiceService } from '../../services/invoice.service';
import { LabelConfigService } from '../../services/label-config.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ReportDetailsService } from '../../services/report-details.service';
import { ReportFilterAndSortParams, ReportService } from '../../services/report.service';
import { TeamService } from '../../services/team.service';
import { ToastService } from '../../services/toast.service';
import { TutorialStateService } from '../../services/tutorial-state.service';
import { UserService } from '../../services/user.service';
import { AnalyticsFilterComponent } from '../filter/analytics-filter/analytics-filter.component';

@Component({
    selector: 'create-collective-invoice-dialog',
    templateUrl: './create-collective-invoice-dialog.component.html',
    styleUrls: ['./create-collective-invoice-dialog.component.scss'],
    animations: [slideInAndOutHorizontally(), slideInAndOutVertically(), dialogEnterAndLeaveAnimation()],
})
export class CreateCollectiveInvoiceDialogComponent implements OnInit, OnDestroy {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;
    @ViewChild('customLineItemTitleInputField') customLineItemTitleInput: ElementRef<HTMLInputElement>;

    @Input() user: User;
    @Input() team: Team;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    /**
     * EventEmitter that notifies the parent, that the invoice was created -> parent opens invoice editor.
     */
    @Output() invoiceWasCreated: EventEmitter<Invoice['_id']> = new EventEmitter<Invoice['_id']>();

    protected supplyPeriodStartDate: IsoDate;
    protected supplyPeriodEndDate: IsoDate;

    protected invoiceRecipientSearchTerm: string;

    /**
     * Subject used to subscribe to observables with takeUntil only until
     * this subject gets destroyed (in onDestroy lifecycle method).
     */
    private destroySubject$: Subject<void> = new Subject<void>();

    private subscriptions: Subscription[] = [];

    protected creatingInvoiceInProgress: boolean = false;
    protected invoicePreviewDownloadPending: boolean = false;
    protected selectedReportsListExpanded = false;

    protected selectedContactPerson: ContactPerson = null;
    protected availableReports: Report[] = [];
    protected selectedReports: Report[] = [];
    protected reportSearchTerm: string = '';
    private reportSearchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    protected sortReportListBy: ReportListSortTypes = 'automaticDate';
    protected sortReportListDescending: boolean = false;

    /**
     * Pagination
     */
    private loadMoreReports$ = new Subject<LoadMoreReportsPayload>();
    // Keep track of the last page (for server and indexedDB)
    private lastReportPaginationTokenFromServer: string;
    private numberOfLoadedReports: number = 0;

    protected isLoadMoreReportsPending: boolean = false;
    protected allReportsLoadedWithCurrentFilters = false;
    protected reportLabelConfigs: LabelConfig[] = [];
    protected customLineItemTitleInputShown = false;
    protected customLineItemTitle = '';

    protected selectedResponsibleAssessorId: User['_id'];
    protected selectedOfficeLocation: OfficeLocation;
    protected assessors: User[] = [];

    constructor(
        private toastService: ToastService,
        private apiErrorService: ApiErrorService,
        private teamService: TeamService,
        private downloadService: DownloadService,
        private reportService: ReportService,
        private reportDetailsService: ReportDetailsService,
        private invoiceService: InvoiceService,
        private router: Router,
        private route: ActivatedRoute,
        private tutorialStateService: TutorialStateService,
        private loggedInUserService: LoggedInUserService,
        private labelConfigService: LabelConfigService,
        private userService: UserService,
    ) {}

    async ngOnInit() {
        this.subscribeToSearchTermChanges();
        this.subscribeToLoadMoreReports();
        this.loadLabelConfigs();
        this.loadAssessorsFromTeam();
    }

    private loadAssessorsFromTeam() {
        this.assessors = this.userService.getAllTeamMembersFromCache();
    }

    private getResponsibleAssessor(userId: string): User {
        return this.assessors.find((assessor) => assessor._id === userId);
    }

    protected getOfficeLocation(officeLocationId: string): OfficeLocation {
        return this.team.officeLocations.find((officeLocation) => officeLocation._id === officeLocationId);
    }

    protected getUsersFullName(userId: string): string {
        const user: User = this.getResponsibleAssessor(userId);

        if (!user) {
            return '';
        }

        return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    }

    /**
     * Allows users to filter reports by label.
     */
    protected async loadLabelConfigs() {
        try {
            this.reportLabelConfigs = await this.labelConfigService.find({ labelGroup: 'report' }).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Label-Konfigs konnten nicht geladen werden`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        this.reportLabelConfigs.sort(sortByProperty(['dragOrderPosition', 'name']));
    }

    /**
     * Subscribe to the stream of search terms.
     *
     * The subscription handles filters about the search term, debouncing and triggers the reload of reports.
     */
    private subscribeToSearchTermChanges() {
        const searchTerm$Subscription = this.reportSearchTerm$
            .pipe(
                /**
                 * Ensure that a search term has 3 or more characters. Otherwise, searches like "a" are so unspecific
                 * that our backend server collapses under the load because for large customer accounts, thousand of reports
                 * are loaded into memory.
                 *
                 * Three characters allow for searches like "BMW" oder "Audi".
                 *
                 * Also allow searches if there are two or more search words separated by space. That allows to search for license plates
                 * like "PB SL".
                 */
                filter((searchTerm) => {
                    if (!searchTerm) {
                        this.resetLoadHistoryAndReloadReports();
                        return;
                    }
                    if (typeof searchTerm !== 'string') {
                        return;
                    }

                    // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
                    const searchTermParts = searchTerm
                        .trim()
                        .split(/(?:-| )+/)
                        .filter((searchTerm) => !!searchTerm.trim());
                    return (
                        // Search if there are at least two search terms or one search term with at least 3 characters.
                        // This allows searching for license plates with two short tokens *e.g. "PB SL".
                        searchTermParts.some((searchTermPart) => searchTermPart.length >= 3) ||
                        searchTermParts.length > 1
                    );
                }),
                map((searchTerm) => searchTerm.trim()),
            )
            .pipe(debounceTime(500))
            .subscribe(() => {
                // If the search term changed, reset load history, because we fetch all new results from the server
                this.resetLoadHistory();

                this.loadReports(true);
            });

        this.subscriptions.push(searchTerm$Subscription);
    }

    private subscribeToLoadMoreReports() {
        const loadMoreReportsSubscription = this.loadMoreReports$
            .pipe(
                // Only trigger a load if the parameters have changed.
                // This is required since the scroll observer may trigger multiple times with the same parameters.
                // It is important to have the filters and sort params in the payload to cancel the request if the user changes the filter or sort.
                // Since distinctUntilChanged may hold a reference to objects or arrays, we need to deep copy the payload in order to compare the values.
                map((payload) => JSON.parse(JSON.stringify(payload))),
                distinctUntilChanged(isEqual),
                switchMap(async (payload: LoadMoreReportsPayload) => {
                    const numberOfReportsToLoad = payload.numberOfItemsToLoad || 20;
                    const isInitialLoad = !this.numberOfLoadedReports;
                    let loadedReports: Report[] = [];
                    this.isLoadMoreReportsPending = true;

                    try {
                        const { records, lastPaginationToken } =
                            await this.reportService.getReportsForCollectiveInvoice({
                                searchTerm: payload.searchTerm,
                                searchAfterPaginationToken: payload.searchAfterPaginationToken,
                                skip: payload.searchAfterNumberOfElements || 0,
                                limit: numberOfReportsToLoad,
                                filterAndSortParams: payload.filterAndSortParams,
                            });
                        loadedReports = records;

                        // Update pagination of component
                        this.lastReportPaginationTokenFromServer = lastPaginationToken;
                        this.numberOfLoadedReports += loadedReports.length;
                    } catch (error) {
                        this.apiErrorService.handleAndRethrow({
                            axError: error,
                            handlers: {
                                INVALID_DATE_RANGE: {
                                    title: 'Ungültiger Datumsfilter',
                                    body: 'Stelle sicher, dass der Datumsfilter so eingestellt ist, dass das Enddatum nach dem Startdatum liegt.',
                                },
                            },
                            defaultHandler: {
                                title: 'Gutachten nicht geladen',
                                body: 'Die Gutachten konnten nicht vom Server oder der lokalen Datenbank geladen werden.',
                            },
                        });
                    }
                    this.isLoadMoreReportsPending = false;
                    return {
                        loadedReports,
                        isInitialLoad,
                        numberOfReportsToLoad,
                        searchTerm: payload.searchTerm,
                        resetLoadHistory: payload.resetLoadHistory,
                    };
                }),
            )
            .subscribe(({ loadedReports, isInitialLoad, numberOfReportsToLoad, searchTerm, resetLoadHistory }) => {
                // If there are less reports than the limit, we have reached the end of the list.
                this.allReportsLoadedWithCurrentFilters = loadedReports.length < numberOfReportsToLoad;

                if (loadedReports.length === 0) {
                    if (resetLoadHistory) {
                        this.availableReports = [];
                    }
                    return;
                }

                // If this is the first load or we are replacing exiting reports with a fresh search, clear the current reports
                if ((isInitialLoad && !searchTerm) || resetLoadHistory) {
                    this.availableReports = loadedReports;
                } else {
                    // Append all new reports to the list.
                    const reportsWithoutDuplicates: Report[] = this.filterOutExistingReports(loadedReports);
                    this.availableReports.push(...reportsWithoutDuplicates);
                }
            });

        this.subscriptions.push(loadMoreReportsSubscription);
    }

    /**
     * Trigger to load more reports, e.g. on scroll
     */
    protected async triggerLoadMoreReports() {
        if (this.allReportsLoadedWithCurrentFilters) {
            this.toastService.info('Keine weiteren Gutachten', 'Alle verfügbaren Gutachten wurden geladen.');
            return;
        }
        this.loadMoreReports$.next({
            filterAndSortParams: this.getFilterForLoadMoreReports(),
            searchTerm: this.reportSearchTerm,
            searchAfterNumberOfElements: this.numberOfLoadedReports,
            searchAfterPaginationToken: this.lastReportPaginationTokenFromServer,
        });
    }

    /**
     * Returns all reports that don't yet exist in the collection they would go into.
     * @returns {Report[]}
     */
    private filterOutExistingReports(reports: Report[]): Report[] {
        return reports.filter((newReport) => {
            // Only include new reports without a matching ID
            return !this.availableReports.find((existingReport) => existingReport._id === newReport._id);
        });
    }

    private getFilterForLoadMoreReports(): ReportFilterAndSortParams {
        let involvedUsers: User['_id'][] = [];

        if (this.filter.assessorIds?.length > 0) {
            involvedUsers = this.filter.assessorIds;
        }

        return {
            involvedUsers,
            labels: this.filter.reportLabelConfigIds
                ? this.reportLabelConfigs
                      .filter((config) => this.filter.reportLabelConfigIds.includes(config._id))
                      .map((config) => config.name)
                : [],
            completedAfter: this.supplyPeriodStartDate,
            completedBefore: this.supplyPeriodEndDate,
            sortBy: this.sortReportListBy ?? 'automaticDate',
            sortDescending: this.sortReportListDescending,
            mustHaveExpertStatement: false,
            mustHaveRepairConfirmation: false,
            reportType: null,
        };
    }

    protected resetLoadHistory(): void {
        // Reset pagination to start with a fresh load.
        this.lastReportPaginationTokenFromServer = null;
        this.numberOfLoadedReports = 0;
    }

    /**
     * When a filter or sort is changed, reset the reports.
     */
    private async resetLoadHistoryAndReloadReports() {
        this.resetLoadHistory();
        this.loadReports(true);
    }

    private async loadReports(resetLoadHistory: boolean = false): Promise<void> {
        this.loadMoreReports$.next({
            filterAndSortParams: this.getFilterForLoadMoreReports(),
            searchTerm: this.reportSearchTerm,
            resetLoadHistory: resetLoadHistory,
        });
    }

    protected dateRangeChanged(): void {
        this.resetLoadHistoryAndReloadReports();
    }

    protected showCustomLineItemTitleInput(): void {
        this.customLineItemTitleInputShown = true;
        this.customLineItemTitle = concatReportCategories(this.createCollectiveInvoiceObject().reportsData);
        // Focus the input field for the custom title
        setTimeout(() => {
            this.customLineItemTitleInput.nativeElement.focus();
        }, 0);
    }

    /**
     * User confirmed to create the collective invoice -> create the invoice object and save it.
     */
    protected async createCollectiveInvoice(): Promise<void> {
        this.creatingInvoiceInProgress = true;

        try {
            const newInvoice = this.createCollectiveInvoiceObject();

            await this.invoiceService.create(newInvoice);

            this.selectedReports.forEach((report) => {
                report.feeCalculation.collectiveInvoiceId = newInvoice._id;
                this.saveReport(report);
            });

            this.router.navigate([newInvoice._id], {
                relativeTo: this.route,
            });

            this.tutorialStateService.markUserTutorialStepComplete('customInvoiceCreated');
        } catch (error) {
            console.error('COULD_NOT_CREATE_COLLECTIVE_INVOICE', { error });
            this.toastService.error(
                'Sammelrechnung nicht erstellt',
                'Bitte versuche es erneut oder kontaktiere die aX Hotline.',
            );
            this.creatingInvoiceInProgress = false;
            return;
        }
    }

    /**
     * Helper function to create the invoice object. Used to create the actual invoice and the preview.
     */
    private createCollectiveInvoiceObject(): Invoice {
        const user = this.loggedInUserService.getUser();
        const team = this.loggedInUserService.getTeam();

        const lineItems: LineItem[] = [];
        const reportsData: ReportData[] = [];
        for (let i = 0; i < this.selectedReports.length; i++) {
            // Line Items
            const selectedReport = this.selectedReports[i];
            const completionDateFormatted = toGermanDate(selectedReport.completionDate || DateTime.now());

            const descriptionParts = [];
            if (selectedReport.token) {
                descriptionParts.push(`Az. ${selectedReport.token}`);
            }

            if (selectedReport.completionDate) {
                descriptionParts.push(completionDateFormatted);
            }
            if (selectedReport.car.licensePlate || selectedReport.car.vin) {
                descriptionParts.push(selectedReport.car.licensePlate ?? selectedReport.car.vin);
            }

            let description = `<p>${descriptionParts.join(', ')}</p>`;

            if (this.team.preferences.collectiveInvoiceIncludeInvoiceDetails) {
                const helperInvoice = createInvoiceFromReport({ report: selectedReport, user, team });
                description += helperInvoice.lineItems
                    .map(
                        (lineItem) =>
                            `${this.replaceLastOccurrence(lineItem.description.replace('<p>', '<p>' + ' - '), '</p>', `: ${convertToCurrencyString(lineItem.unitPrice * lineItem.quantity, 'EUR')}</p>`)}`,
                    )
                    .join('\n');
            }

            const lineItem = new LineItem();
            lineItem.description = description;
            lineItem.unit = 'Stück';
            lineItem.quantity = 1;
            lineItem.unitPrice = calculateTotalNetFees(selectedReport);
            lineItem.position = i;

            lineItems.push(lineItem);

            // Report Data
            reportsData.push(getInvoiceReportData({ report: selectedReport }));
        }

        const responsibleAssessors = this.getResponsibleAssessorsFromSelection();
        const associatedOfficeLocations = this.getAssociatedOfficeLocationsFromSelection();
        const newInvoice = createInvoice({
            user: user,
            team: team,
            invoiceData: {
                isCollectiveInvoice: true,
                date: todayIso(),
                vatRate: team.invoicing.vatRate,
                vatExemptionReason: team.invoicing.vatRate === 0 ? 'smallBusiness' : undefined,
                officeLocationId:
                    associatedOfficeLocations?.length === 1
                        ? associatedOfficeLocations[0]._id
                        : (this.selectedOfficeLocation._id ?? this.user.defaultOfficeLocationId),
                dateOfSupply: this.supplyPeriodEndDate,
                supplyPeriodStart: this.supplyPeriodStartDate,
                reportIds: this.selectedReports.map((report) => report._id),
                lineItems,
                reportsData: reportsData,
                associatedAssessorId:
                    responsibleAssessors?.length === 1
                        ? responsibleAssessors[0]._id
                        : (this.selectedResponsibleAssessorId ?? this.user._id),
            },
        });

        if (this.customLineItemTitle) {
            newInvoice.collectiveInvoiceCustomInvoiceLineItemTitle = this.customLineItemTitle;
        }

        if (this.selectedContactPerson) {
            newInvoice.recipient.contactPerson = this.selectedContactPerson;
            newInvoice.recipient.role = 'invoiceRecipient';
        }

        // Bank accounts
        newInvoice.bankAccount = team.invoicing.bankAccount;
        if (team.invoicing.secondBankAccount?.iban) {
            newInvoice.secondBankAccount = team.invoicing.secondBankAccount;
        }

        return newInvoice;
    }

    /**
     * Helper function to replace the last occurrence of the given search term within the given string with the given replacement.
     */
    private replaceLastOccurrence(inputString: string, searchString: string, replacementString: string) {
        const index = inputString.lastIndexOf(searchString);
        if (index === -1) return inputString;
        return inputString.substring(0, index) + replacementString + inputString.substring(index + searchString.length);
    }

    protected closeDialog(): void {
        this.close.emit();
    }

    protected handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    protected invoiceRecipientSelected(contactPerson: ContactPerson): void {
        this.selectedContactPerson = contactPerson;
    }

    protected removeSelectedContactPerson(): void {
        this.selectedContactPerson = null;
        this.invoiceRecipientSearchTerm = '';
    }

    protected totalGrossSelectedReports(): number {
        return this.selectedReports.reduce((acc, report) => acc + calculateTotalGrossFees(report), 0);
    }

    protected isReportSelected(report: Report): boolean {
        return this.selectedReports.findIndex((selectedReport) => selectedReport._id === report._id) !== -1;
    }

    /**
     * Check if all reports in the availableReports list are currently selected. This determines the state of the "select all" checkbox.
     */
    protected areAllReportsSelected(): boolean {
        return (
            this.availableReports.length > 0 &&
            this.availableReports.every(
                (availableReport) =>
                    this.selectedReports.findIndex((selectedReport) => selectedReport._id === availableReport._id) !==
                    -1,
            )
        );
    }

    protected setSelectionStatusForAllReports(checked: boolean): void {
        this.availableReports.forEach((availableReport) => {
            const selectedReportIndex = this.selectedReports.findIndex(
                (selectedReport) => selectedReport._id === availableReport._id,
            );
            const isSelected = selectedReportIndex !== -1;

            if (checked && !isSelected) {
                this.selectedReports.push(availableReport);
            } else if (!checked && isSelected) {
                this.selectedReports.splice(selectedReportIndex, 1);
            }
        });
    }

    protected toggleReportSelection(report: Report): void {
        const selectedReportIndex = this.selectedReports.findIndex(
            (selectedReport) => selectedReport._id === report._id,
        );
        if (selectedReportIndex !== -1) {
            this.selectedReports.splice(selectedReportIndex, 1);
        } else {
            this.selectedReports.push(report);
        }

        if (this.getResponsibleAssessorsFromSelection()?.length > 1 && !this.selectedResponsibleAssessorId) {
            this.selectedResponsibleAssessorId = this.getResponsibleAssessorsFromSelection()[0]._id;
        }

        if (this.getAssociatedOfficeLocationsFromSelection()?.length > 1 && !this.selectedOfficeLocation) {
            this.selectedOfficeLocation = this.getAssociatedOfficeLocationsFromSelection()[0];
        }
    }

    protected updateSearchTerm(): void {
        this.reportSearchTerm$.next(this.reportSearchTerm);
    }

    protected availableReportsFilterChanged(): void {
        this.resetLoadHistory();
        this.loadReports(true);
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    protected filtersLoaded(): void {
        this.availableReportsFilterChanged();
    }

    protected selectSortStrategy(strategy: ReportListSortTypes): void {
        this.sortReportListBy = strategy;
        this.resetLoadHistoryAndReloadReports();
    }

    protected toggleSortDirection(): void {
        this.sortReportListDescending = !this.sortReportListDescending;
        this.resetLoadHistoryAndReloadReports();
    }

    protected reportSummaryAsAppendixChanged(change: MatLegacySelectChange): void {
        this.team.preferences.collectiveInvoiceSummaryAppendix = change.value === 'appendix';
        this.saveTeam();
    }

    protected getResponsibleAssessorsFromSelection(): Array<User> {
        return [
            ...new Set(
                this.selectedReports.map((report) =>
                    this.assessors.find((assessor) => report.responsibleAssessor === assessor._id),
                ),
            ),
        ].filter(Boolean);
    }

    protected getAssociatedOfficeLocationsFromSelection(): Array<OfficeLocation> {
        return [
            ...new Set(
                this.selectedReports.map((report) =>
                    this.team.officeLocations.find((officeLocation) => report.officeLocationId === officeLocation._id),
                ),
            ),
        ].filter(Boolean);
    }

    protected async downloadInvoicePreview(): Promise<void> {
        // Prevent a second download request
        if (this.invoicePreviewDownloadPending) {
            return;
        }

        this.invoicePreviewDownloadPending = true;

        const invoice: Invoice = this.createCollectiveInvoiceObject();

        try {
            const response = await this.invoiceService.getInvoicePreview(invoice);
            this.downloadService.downloadBlobResponseWithHeaders(response);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Fehler beim Download`,
                    body: `Die Rechnungsvorschau konnte nicht erstellt werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        } finally {
            this.invoicePreviewDownloadPending = false;
        }
    }

    protected async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    private async saveReport(report: Report) {
        try {
            await this.reportDetailsService.patch(report);
        } catch (error) {
            this.toastService.warn('Gutachten nicht synchronisiert', 'Ist eine Internetverbindung vorhanden?');
            console.error('An error occurred while saving the report via the ReportService.', { error });
        }
    }

    //*****************************************************************************
    //  Keyboard Events
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.unsubscribe();

        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected readonly trackById = trackById;
}

/**
 * Payload for the loadMoreDoneReports$ observable.
 */
type LoadMoreReportsPayload = {
    searchTerm?: string;
    filterAndSortParams: ReportFilterAndSortParams;
    numberOfItemsToLoad?: number;
    searchAfterPaginationToken?: string;
    searchAfterNumberOfElements?: number;
    resetLoadHistory?: boolean;
};

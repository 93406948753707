import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import {
    AnalyticsDefaultInvoiceItemNames,
    getAnalyticsDefaultInvoiceItemNames,
} from '@autoixpert/lib/fee-calculation/analytics-default-invoice-item-names';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import {
    RevenueByInvoiceItemAnalyticsRecord,
    RevenueByInvoiceItemAnalyticsSummary,
} from '@autoixpert/models/analytics/revenue-by-invoice-item-analytics.model';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Team } from '@autoixpert/models/teams/team';
import { InvoiceLineItemTemplateService } from 'src/app/shared/services/invoice-line-item-template.service';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { AnalyticsFilterComponent } from '../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { downloadAnalyticsRecordsAsCsv } from '../../shared/libraries/download-analytics-records-as-csv';
import { stripHtml } from '../../shared/libraries/strip-html';
import { RevenueByInvoiceItemAnalyticsService } from '../../shared/services/analytics/revenue-by-invoice-item-analytics.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { getHeightOfHorizontalBarChart, setDefaultChartOptions } from '../default-chart-options';

/**
 * List of invoice items that are selected (and displayed) for the graph by default.
 */
export const DEFAULT_SELECTED_INVOICE_ITEM_DESCRIPTIONS: string[] = getAnalyticsDefaultInvoiceItemNames();

/**
 * Key used to identify the "Sonstige" entry in the graph. When the user
 * hides this entry from the UI, this key is stored.
 */
const OTHER_INVOICE_ITEMS_KEY: string = 'OTHER_INVOICE_ITEMS_KEY';

@Component({
    selector: 'assessors-fee',
    templateUrl: './revenue-by-invoice-item.component.html',
    styleUrls: ['./revenue-by-invoice-item.component.scss'],
    animations: [slideInAndOutVertically()],
})
export class RevenueByInvoiceItemComponent implements OnInit {
    @ViewChild('chart', { read: ElementRef }) private chartAnchorElem: ElementRef<HTMLDivElement>;
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;

    constructor(
        private readonly revenueByInvoiceItemAnalyticsService: RevenueByInvoiceItemAnalyticsService,
        private readonly apiErrorService: ApiErrorService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly loggedInUserService: LoggedInUserService,
        private readonly invoiceLineItemTemplateService: InvoiceLineItemTemplateService,
    ) {}

    protected chartTitle: string = 'Umsatz pro Rechnungsposition';
    private chart: ApexCharts;
    private chartOptions: apexcharts.ApexOptions;
    protected analyticsPending: boolean = false;
    protected totalNet: number = 0;

    protected summary: RevenueByInvoiceItemAnalyticsSummary;
    /**
     * All records that are displayed in the table.
     */
    protected records: RevenueByInvoiceItemAnalyticsRecord[] = [];
    /**
     * All records displayed in the graph. These are equal to or a subset of the records above.
     * In case the user hides entries from the table, this filtered list gets smaller.
     */
    protected filteredRecordsForChart: RevenueByInvoiceItemAnalyticsRecord[] = [];

    protected filterAnalyticsFrom: string;
    protected filterAnalyticsTo: string;

    /**
     * Boolean flag whether or not to display all other fees item by item. If false,
     * all other fees are summed up and displayed under the "Sonstige" entry.
     */
    protected includeOtherFees: boolean;
    /**
     * Locally hidden (and currently not stored anywhere) records that should not be shown in the graph.
     * These are the IDs of the records. By default we hide the assessor fee and other fees, because they might be quite big
     * in contrast to the other bars.
     */
    protected recordsHiddenFromChart: string[] = [AnalyticsDefaultInvoiceItemNames.ASSESSOR_FEE];

    /**
     * Reference to the team for retrieving saved invoice items.
     */
    private team: Team;

    ngOnInit() {
        this.team = this.loggedInUserService.getTeam();

        setDefaultChartOptions();
        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    protected navigateBackToAnalyticsList(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    protected rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/

    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.filteredRecordsForChart.length) return;

        // Bar Values
        const invoiceItemDescriptions: string[] = this.filteredRecordsForChart.map((record) =>
            stripHtml(record.invoiceItemDescription ?? '').toUpperCase(),
        );
        const invoiceItemTotalSums: number[] = this.filteredRecordsForChart.map(
            (record) => record.totalRevenueNet ?? 0,
        );

        this.chartOptions = {
            chart: {
                type: 'bar',
                height: getHeightOfHorizontalBarChart(this.filteredRecordsForChart.length),
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            series: [
                {
                    name: 'Umsatz (netto)',
                    data: invoiceItemTotalSums,
                },
            ],
            xaxis: {
                // Categories go into the Y axis when the bar chart is horizontal but...
                categories: invoiceItemDescriptions,
                // ... all styling for the final xaxis is determined here.
                labels: {
                    formatter: (value) => currencyFormatterEuro(+value),
                    style: {
                        fontFamily: "'Source Sans Pro', sans-serif",
                    },
                },
            },
            yaxis: {
                labels: {
                    show: true,
                },
            },
            tooltip: {
                y: {
                    formatter: (revenueByAssessor) => currencyFormatterEuro(revenueByAssessor),
                },
            },
        };

        // Clear before re-drawing
        if (this.chart) {
            this.chart.destroy();
        }

        setTimeout(() => {
            // Anchor element is bound to ngIf, wait until it was added to the DOM. Otherwise this.chartAnchorElem is undefined.
            this.chart = new ApexCharts(this.chartAnchorElem.nativeElement, this.chartOptions);
            this.chart.render();
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Table
    //****************************************************************************/

    /**
     * Downloads the displayed summary (total amount per invoice item) as CSV file.
     */
    protected downloadSummaryAsCsv(): void {
        downloadAnalyticsRecordsAsCsv(this.records, this.chartTitle);
    }

    /**
     * Downloads a CSV file listing each and every report that was used to calculate
     * the displayed summary. This file lists the fees for every single report matching
     * the selected filter.
     */
    protected async downloadSingleRecordsAsCsv(): Promise<void> {
        try {
            await this.revenueByInvoiceItemAnalyticsService.downloadRecordsAsCsv({
                from: this.filterAnalyticsFrom,
                to: this.filterAnalyticsTo,
                officeLocationIds: this.filter.officeLocationIds,
                assessorIds: this.filter.assessorIds,
                reportLabelIds: this.filter.reportLabelConfigIds,
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ACCESS_DENIED: (error: AxError) => ({
                        title: 'Keine Berechtigung',
                        body: `Für den Download aller Datensätze benötigst du das Zugriffsrecht "${translateAccessRightToGerman(error.data.accessRight)}".`,
                    }),
                },
                defaultHandler: {
                    title: 'Download nicht möglich',
                    body: 'Ein Fehler ist aufgetreten',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Table
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    protected async updateAnalytics() {
        this.analyticsPending = true;

        try {
            this.summary = await this.revenueByInvoiceItemAnalyticsService.getRevenueByInvoiceItemSummary({
                from: this.filterAnalyticsFrom,
                to: this.filterAnalyticsTo,
                officeLocationIds: this.filter.officeLocationIds,
                assessorIds: this.filter.assessorIds,
                reportLabelIds: this.filter.reportLabelConfigIds,
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ACCESS_DENIED: {
                        title: 'Zugriffsrecht fehlt',
                        body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                        forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                    },
                },
                defaultHandler: {
                    title: 'Fehler bei Auswertung',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        } finally {
            this.analyticsPending = false;
        }

        await this.refreshLocalData();
    }

    /**
     * Apply all local filters (sum up or display all other fees) and hiding entries from the chart.
     */
    protected async applyFilters() {
        await this.applyIncludeOtherFeesFilter();
        this.applyHideRecordsFilter();
    }

    protected async refreshLocalData() {
        await this.applyFilters();
        this.calculateTotalNet();
        this.drawChart();
    }

    /**
     * Other fees = non-standard line items.
     * - Standard fees include: assessors fee, photo fees, writing fees, etc.
     * - Other fees may include: Usage of an external garage's car lift.
     */
    protected async applyIncludeOtherFeesFilter(): Promise<void> {
        const rememberedItems = [
            ...this.team.preferences.defaultFees.reportInvoiceLineItems.map((item) => item.description),
            ...this.team.preferences.liabilityHukFees.reportInvoiceLineItems.map((item) => item.description),
            ...(await this.invoiceLineItemTemplateService.find().toPromise()).map((item) => item.description),
        ].map((item) => stripHtml(item));
        const otherItems = this.summary.allRecords.filter(
            (item) =>
                !getAnalyticsDefaultInvoiceItemNames().includes(item.invoiceItemDescription as any) &&
                !rememberedItems.includes(item.invoiceItemDescription),
        );

        if (this.includeOtherFees) {
            this.records = this.summary.allRecords;
        } else {
            this.records = this.summary.allRecords.filter((item) =>
                [...DEFAULT_SELECTED_INVOICE_ITEM_DESCRIPTIONS, ...rememberedItems].includes(
                    item.invoiceItemDescription,
                ),
            );

            const sumTotalRevenueNet = otherItems.reduce((sum, item) => sum + item.totalRevenueNet, 0);
            const sumNumberOfReports = otherItems.reduce((count, item) => count + item.numberOfReports, 0);
            const sumOfOtherFeesRecord: RevenueByInvoiceItemAnalyticsRecord = {
                _id: OTHER_INVOICE_ITEMS_KEY,
                invoiceItemDescription: 'Sonstige',
                totalRevenueNet: sumTotalRevenueNet,
                numberOfReports: sumNumberOfReports,
                averageRevenuePerReport: sumNumberOfReports > 0 ? sumTotalRevenueNet / sumNumberOfReports : 0,
            };
            this.records.push(sumOfOtherFeesRecord);
        }
    }

    public applyHideRecordsFilter() {
        this.filteredRecordsForChart = this.records.filter(
            (record) => !this.recordsHiddenFromChart.includes(record._id),
        );
    }

    protected calculateTotalNet(): void {
        this.totalNet = this.filteredRecordsForChart.reduce((sum, newItem) => sum + (newItem.totalRevenueNet ?? 0), 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Hide Records
    //****************************************************************************/
    public toggleHideRecord(record: string) {
        toggleValueInArray(record, this.recordsHiddenFromChart);
    }

    public isRecordHidden(record: string): boolean {
        return this.recordsHiddenFromChart.includes(record);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Hide Records
    /////////////////////////////////////////////////////////////////////////////*/

    protected readonly stripHtml = stripHtml;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { InvoiceInvolvedPartyRole } from '@autoixpert/models/invoices/invoice';
import { OutgoingMessage } from '@autoixpert/models/outgoing-message';
import { CommunicationRecipientRole } from '@autoixpert/models/reports/involved-parties/involved-party';
import { runChildAnimations } from '../../animations/run-child-animations.animation';
import { OutgoingMessageService } from '../../services/outgoing-message.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'outgoing-messages-list',
    templateUrl: './outgoing-messages-list.component.html',
    styleUrls: ['./outgoing-messages-list.component.scss'],
    animations: [runChildAnimations()],
})
export class OutgoingMessagesListComponent {
    protected outgoingMessagesDialogShown = false;
    protected outgoingMessagesDialogMessage: OutgoingMessage;
    private subscriptions: Subscription[] = [];

    constructor(
        private outgoingMessageService: OutgoingMessageService,
        private toastService: ToastService,
    ) {}

    @Input() outgoingMessages: OutgoingMessage[];
    @Input() selectedRecipientRole: CommunicationRecipientRole | InvoiceInvolvedPartyRole;
    @Output() editAsNew = new EventEmitter<OutgoingMessage>();

    // Sort outgoing messages by createdAt date ASC and filter by recipient role
    get outgoingMessagesSorted() {
        return this.outgoingMessages
            .filter((outgoingMessage) =>
                !this.selectedRecipientRole ? true : outgoingMessage.recipientType === this.selectedRecipientRole,
            )
            .sort((a, b) => {
                return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
            });
    }

    sendOutgoingMessage(outgoingMessage: OutgoingMessage) {
        this.outgoingMessageService.put({
            ...outgoingMessage,
            scheduledAt: null,
        });
    }

    async deleteOutgoingMessage(outgoingMessage: OutgoingMessage) {
        this.outgoingMessages.splice(this.outgoingMessages.indexOf(outgoingMessage), 1);
        await this.outgoingMessageService.delete(outgoingMessage._id);

        const infoToast = this.toastService.info(
            ` Nachricht wurde entfernt`,
            `Klicke, um die  Nachricht wiederherzustellen.`,
            {
                showProgressBar: true,
                timeOut: 5_000,
            },
        );

        // Restoration toast
        this.subscriptions.push(
            infoToast.click.subscribe(async () => {
                this.outgoingMessages.push(outgoingMessage);
                await this.outgoingMessageService.create(outgoingMessage);
            }),
        );
    }

    displayOutgoingMessage(outgoingMessage: OutgoingMessage) {
        this.outgoingMessagesDialogShown = true;
        this.outgoingMessagesDialogMessage = outgoingMessage;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Car } from '@autoixpert/models/reports/car-identification/car';

/**
 * Returns true if the car shape requires two or more axles.
 *
 * Used to display a waning message if the user only selected one axle.
 */
@Pipe({
    name: 'vehicleRequiresTwoOrMoreAxles',
    standalone: true,
})
export class VehicleRequiresTwoOrMoreAxlesPipe implements PipeTransform {
    transform(carShape: Car['shape']): boolean {
        return new Array<Car['shape']>(
            'bicycle',
            'e-bike',
            'pedelec',
            'sedan',
            'compact',
            'coupe',
            'stationWagon',
            'suv',
            'convertible',
            'van',
            'transporter',
            'motorcycle',
            'pickup',
            'motorHome',
            'truck',
            'semiTruck',
            'semiTrailer',
            'bus',
        ).includes(carShape);
    }
}

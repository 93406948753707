import { Component, Input } from '@angular/core';

/**
 * A prefix (label or icon or both) that is displayed inside the currency-input component (left).
 * It can be placed within the tags of the currency-input and will be projected using ng-content.
 * Using this prefix instead of the mat-prefix is necessary, because the latter will move the
 * title/placeholder of the input field to the right (see example here: https://material.angular.io/components/form-field/overview#form-field-prefix-suffix)
 */

@Component({
    selector: 'currency-input-prefix',
    templateUrl: './currency-input-prefix.component.html',
    styleUrls: ['./currency-input-prefix.component.scss'],
})
export class CurrencyInputPrefixComponent {
    @Input() iconName: string = 'south';
    @Input() label: string = '';
    @Input() color: 'default' | 'red' | 'green' = 'default';
}

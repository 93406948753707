<h2 matDialogTitle>Ausstattungen</h2>
<mat-dialog-content>
    <!--********** VALUEpilot logo **********-->
    <div class="text-align-center flex-center-content">
        <img id="valuepilot-logo" src="/assets/images/logos/valuepilot.png" />
        <span
            class="help-indicator"
            (click)="openValuepilotWebInterface()"
            matTooltip="Schnittstelle zu VALUEpilot 1. Keine Integration mit VALUEpilot 2 aus Qapter.{{
                '\n\n'
            }}Klicke, um das Web-Interface zu öffnen.">
            ?
        </span>
    </div>

    <mat-icon class="dialog-close-icon" mat-dialog-close>close</mat-icon>

    <!--********** Intro Text **********-->
    <p class="label text-align-center" *ngIf="carEquipment">
        Anhand der Ausstattungsliste wurde vorselektiert.
        <span class="link" (click)="scanCarEquipment()">Nochmal scannen</span>
    </p>

    <!--============================================-->
    <!-- Checkboxes -->
    <!--============================================-->

    <div id="equipment-options-container">
        <!-- We don't use *ngFor for the air conditioning checkboxes because some of them have logic in them -->
        <!--********** Automatic Air Conditioning **********-->
        <div class="checkbox-container">
            <mat-checkbox
                [(ngModel)]="valuepilotEquipmentOptions.airconditioningAutomatic.active"
                (change)="deactivateAirconditioningManual()">
                {{ translateValuepilotEquipmentOption('airconditioningAutomatic') }}
            </mat-checkbox>
            <div
                class="label"
                *ngIf="valuepilotEquipmentOptions.airconditioningAutomatic.recognizedEquipmentTitle"
                matTooltip="Automatisch erkannte Ausstattung">
                {{ valuepilotEquipmentOptions.airconditioningAutomatic.recognizedEquipmentTitle }}
            </div>
            <div class="label" *ngIf="!valuepilotEquipmentOptions.airconditioningAutomatic.recognizedEquipmentTitle">
                &nbsp;
            </div>
        </div>

        <!--********** Manual Air Conditioning **********-->
        <div class="checkbox-container">
            <mat-checkbox
                [(ngModel)]="valuepilotEquipmentOptions.airconditioningManual.active"
                (change)="deactivateAirconditioningAutomatic()">
                {{ translateValuepilotEquipmentOption('airconditioningManual') }}
            </mat-checkbox>
            <div
                class="label"
                *ngIf="valuepilotEquipmentOptions.airconditioningManual.recognizedEquipmentTitle"
                matTooltip="Automatisch erkannte Ausstattung">
                {{ valuepilotEquipmentOptions.airconditioningManual.recognizedEquipmentTitle }}
            </div>
            <div class="label" *ngIf="!valuepilotEquipmentOptions.airconditioningManual.recognizedEquipmentTitle">
                &nbsp;
            </div>
        </div>

        <!--********** Loop over all other properties without special UI needs such as deactivating a contradictory option. **********-->
        <div
            class="checkbox-container"
            *ngFor="let valuepilotEquipmentOptionName of valuepilotEquipmentOptionNamesWithoutSpecialUiNeeds">
            <mat-checkbox [(ngModel)]="valuepilotEquipmentOptions[valuepilotEquipmentOptionName].active">
                {{ translateValuepilotEquipmentOption(valuepilotEquipmentOptionName) }}
            </mat-checkbox>
            <div
                class="label"
                *ngIf="valuepilotEquipmentOptions[valuepilotEquipmentOptionName].recognizedEquipmentTitle"
                matTooltip="Automatisch erkannte Ausstattung">
                {{ valuepilotEquipmentOptions[valuepilotEquipmentOptionName].recognizedEquipmentTitle }}
            </div>
            <div
                class="label"
                *ngIf="!valuepilotEquipmentOptions[valuepilotEquipmentOptionName].recognizedEquipmentTitle">
                &nbsp;
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Checkboxes -->
    <!--============================================-->

    <div class="dialog-buttons-container">
        <button (click)="startMarketAnalysis()">Analyse starten</button>
    </div>
</mat-dialog-content>

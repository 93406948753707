<div>
    <outgoing-message
        *ngFor="let message of outgoingMessagesSorted"
        [message]="message"
        (deleteOutgoingMessage)="deleteOutgoingMessage($event)"
        (displayOutgoingMessage)="displayOutgoingMessage($event)"
        (sendOutgoingMessage)="sendOutgoingMessage($event)"
        (editAsNew)="editAsNew.emit($event)"></outgoing-message>
</div>

<outgoing-message-dialog
    *ngIf="outgoingMessagesDialogShown"
    @runChildAnimations
    [outgoingMessage]="outgoingMessagesDialogMessage"
    (close)="outgoingMessagesDialogShown = false"></outgoing-message-dialog>

import { CdkDragSortEvent } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { DefaultPhotoDescription } from '@autoixpert/models/user/preferences/default-photo-description';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';

@Component({
    selector: 'default-photo-descriptions-editor',
    templateUrl: 'default-photo-descriptions-editor.component.html',
    styleUrls: ['default-photo-descriptions-editor.component.scss'],
})
export class DefaultPhotoDescriptionsEditorComponent {
    constructor(private userPreferences: UserPreferencesService) {}

    ngOnInit() {
        // Make a copy
        this.defaultPhotoDescriptions = JSON.parse(JSON.stringify(this.userPreferences.defaultPhotoDescriptions));
        if (!this.defaultPhotoDescriptions) {
            this.defaultPhotoDescriptions = [
                'Ansicht vorne links',
                'Ansicht vorne rechts',
                'Ansicht hinten links',
                'Ansicht hinten rechts',
            ].map((title) => new DefaultPhotoDescription({ title }));
        }
    }

    @Output() close: EventEmitter<void> = new EventEmitter();

    public defaultPhotoDescriptions: DefaultPhotoDescription[] = [];

    public handleSortingDefaultPhotoDescriptions(event: CdkDragSortEvent): void {
        const sortedItem = this.defaultPhotoDescriptions.splice(event.previousIndex, 1)[0];
        this.defaultPhotoDescriptions.splice(event.currentIndex, 0, sortedItem);
    }

    public addDefaultPhotoDescription(): void {
        this.defaultPhotoDescriptions.push({ title: 'Neue Beschreibung' });
    }

    public removeDefaultPhotoDescription(photoDescription: DefaultPhotoDescription): void {
        removeFromArray(photoDescription, this.defaultPhotoDescriptions);
    }

    public saveDefaultPhotoDescriptions(): void {
        this.userPreferences.defaultPhotoDescriptions = this.defaultPhotoDescriptions;
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitCloseEvent(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public keydownListener(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.emitCloseEvent();
        }
        if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
            this.saveDefaultPhotoDescriptions();
            this.emitCloseEvent();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

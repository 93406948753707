import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
// import services
import { AuthenticationService } from '../shared/services/authentication.service';
import { PasswordResetService } from '../shared/services/password-reset.service';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password-card/forgot-password-card.component';
import { LoginCardComponent } from './login-card/login-card.component';
// import components
import { LoginComponent } from './login.component';
// import routing
import { loginRouting } from './login.routing';

@NgModule({
    imports: [SharedModule, loginRouting, MatLegacyInputModule, ReactiveFormsModule],
    declarations: [LoginComponent, LoginCardComponent, ForgotPasswordComponent],
    exports: [],
    providers: [AuthenticationService, PasswordResetService],
})
export class LoginModule {}

import { Invoice } from '../../models/invoices/invoice';

/**
 * Depending on the invoice type, return the proper title in German.
 * - invoice (positive total)
 * - credit note (negative total)
 * - full cancellation (Stornorechnung; negative total and cancelling another invoice fully)
 */
export function getInvoiceTypeGerman(invoice: Invoice): InvoiceTypeGerman {
    if (invoice.isFullCancellationInvoice) {
        return 'Stornorechnung';
    } else if (invoice.type === 'creditNote') {
        return 'Gutschrift';
    } else if (invoice.isCollectiveInvoice) {
        return 'Sammelrechnung';
    }
    return 'Rechnung';
}

export type InvoiceTypeGerman = 'Rechnung' | 'Gutschrift' | 'Stornorechnung' | 'Sammelrechnung';

import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { ReportType } from '@autoixpert/models/reports/report';
import { isReportTabVisible } from '../is-report-tab-visible';
import { getLinkFragmentForReportTabName } from './get-link-fragment-for-report-tab-name';

export type ReportTabWithFragment = {
    reportTabName: ReportTabName;
    reportLinkFragment: string;
};
export function getReportTabOrder(reportType: ReportType): ReportTabWithFragment[] {
    const tabOrder: ReportTabName[] = [
        'accidentData',
        'carData',
        'carCondition',
        'carConditionOldtimer',
        'photos',
        'damageCalculation',
        'valuation',
        'valuationOldtimer',
        'leaseReturnChecklist',
        'invoiceAudit',
        'fees',
        'printAndTransmission',
    ];

    const visibleTabs = tabOrder.filter((tabName) => isReportTabVisible({ reportTabName: tabName, reportType }));

    const tabsWithDetails = visibleTabs.map((reportTabName) => ({
        reportTabName,
        reportLinkFragment: getLinkFragmentForReportTabName(reportTabName),
    }));

    return tabsWithDetails;
}

<!-- Document build block condition group-->
<ng-container *ngIf="isDocumentBuildingBlockConditionGroup(condition)">
    <div class="condition-group-container">
        <div
            [ngClass]="{
                conditionGroup: true,
                conditionGroupLight: buildingBlockConditionsPreview,
                conditionGroupDark: !buildingBlockConditionsPreview,
            }">
            <!-- Condition group operator -->
            <div
                class="condition-group-operator"
                [ngSwitch]="condition.conditionsOperator"
                [matTooltip]="
                    condition.conditionsOperator === 'or'
                        ? 'Mindestens eine Bedingung muss erfüllt sein'
                        : 'Alle Bedingungen müssen erfüllt sein'
                ">
                <span *ngSwitchDefault>&</span>
                <mat-icon *ngSwitchCase="'or'">call_split</mat-icon>
            </div>

            <!-- Condition group sub-conditions -->
            <div class="condition-group-sub-conditions">
                <ng-container *ngFor="let subCondition of condition.conditions; trackBy: trackById">
                    <!-- Only  show the condition group operator if the condition depth is less than 2, otherwise a summary is shown at the end -->
                    <document-building-block-condition-preview
                        *ngIf="!isDocumentBuildingBlockConditionGroup(subCondition)"
                        [buildingBlockConditionsPreview]="buildingBlockConditionsPreview"
                        [condition]="subCondition"
                        [placeholderValueTree]="placeholderValueTree"></document-building-block-condition-preview>
                </ng-container>

                <!-- If condition depth is 1, show a summary of all sub-condition groups -->
                <div
                    *ngIf="getNumSubConditionGroups(condition) as numSubConditionGroups"
                    [ngClass]="{
                        conditionGroupsSummary: true,
                        conditionGroupsSummaryLight: buildingBlockConditionsPreview,
                        conditionGroupsSummaryDark: !buildingBlockConditionsPreview,
                    }">
                    {{
                        numSubConditionGroups === 1 ? '+ 1 Untergruppe' : '+ ' + numSubConditionGroups + ' Untergruppen'
                    }}
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- Document build block condition -->
<ng-container *ngIf="!isDocumentBuildingBlockConditionGroup(condition)">
    <div
        [ngClass]="{
            condition: true,
            conditionLight: buildingBlockConditionsPreview,
            conditionDark: !buildingBlockConditionsPreview,
        }">
        <!-- class.hidden-comparison-value: Extend the label to the largest possible width if there is only the path and the operator but not the comparison value. -->
        <div
            class="condition-property-path"
            [class.hidden-comparison-value]="
                condition.operator === 'truthy' ||
                condition.operator === 'falsy' ||
                condition.operator === 'empty' ||
                condition.operator === 'notEmpty' ||
                condition.operator === 'unknown'
            "
            [matTooltip]="condition.propertyPath"
            [matTooltipShowDelay]="600">
            <span>{{ condition.propertyPath }}</span>
        </div>
        <div class="condition-operator-container">
            <div class="condition-operator" [ngSwitch]="condition.operator">
                <span *ngSwitchCase="'equal'" matTooltip="ist gleich">=</span>
                <span *ngSwitchCase="'notEqual'" matTooltip="ist ungleich">≠</span>
                <mat-icon *ngSwitchCase="'empty'" matTooltip="ist leer">close</mat-icon>
                <mat-icon *ngSwitchCase="'notEmpty'" matTooltip="ist nicht leer">check</mat-icon>
                <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Number'">
                    <mat-icon *ngSwitchCase="'truthy'" class="outlined" matTooltip="nicht leer und nicht 0">
                        check_circle
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'falsy'" matTooltip="leer oder 0">highlight_off</mat-icon>
                </ng-container>
                <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Boolean'">
                    <mat-icon *ngSwitchCase="'truthy'" matTooltip="trifft zu">check</mat-icon>
                    <mat-icon *ngSwitchCase="'falsy'" matTooltip="trifft nicht zu">close</mat-icon>
                </ng-container>
                <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Tristate'">
                    <mat-icon *ngSwitchCase="'truthy'" matTooltip="trifft zu">check</mat-icon>
                    <mat-icon *ngSwitchCase="'falsy'" matTooltip="trifft nicht zu">close</mat-icon>
                    <mat-icon
                        *ngSwitchCase="'unknown'"
                        class="condition-operator-icon-question-mark"
                        matTooltip="unbekannt">
                        question_mark
                    </mat-icon>
                </ng-container>
                <mat-icon *ngSwitchCase="'includes'" matTooltip="enthält">search</mat-icon>
                <mat-icon *ngSwitchCase="'includesNot'" matTooltip="enthält nicht">not_interested</mat-icon>
                <span *ngSwitchCase="'greaterThan'" matTooltip="ist größer als">&gt;</span>
                <span *ngSwitchCase="'greaterThanOrEqual'" matTooltip="ist größer/gleich">≥</span>
                <span *ngSwitchCase="'lessThan'" matTooltip="ist kleiner als">&lt;</span>
                <span *ngSwitchCase="'lessThanOrEqual'" matTooltip="ist kleiner/gleich">≤</span>
            </div>
        </div>
        <div
            class="condition-comparison-value"
            *ngIf="
                !(
                    condition.operator === 'truthy' ||
                    condition.operator === 'falsy' ||
                    condition.operator === 'empty' ||
                    condition.operator === 'notEmpty' ||
                    condition.operator === 'unknown'
                )
            "
            [matTooltip]="condition.comparisonValue">
            <!--********** If the comparison operator is of type date, print the date in human-readable format. **********-->
            <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Date'">
                {{ condition.comparisonValue | moment }}
            </ng-container>
            <ng-container *ngIf="getTypeAtPropertyPath(condition.propertyPath) === 'Date-month-only'">
                {{ condition.comparisonValue | moment: 'MM.YYYY' }}
            </ng-container>
            <ng-container
                *ngIf="
                    getTypeAtPropertyPath(condition.propertyPath) !== 'Date' &&
                    getTypeAtPropertyPath(condition.propertyPath) !== 'Date-month-only'
                ">
                <span>{{ condition.comparisonValue }}</span>
            </ng-container>
        </div>
    </div>
</ng-container>

<div class="dialog-overlay" (click)="handleOverlayClick($event)">
    <div class="dialog-overlay" (click)="handleOverlayClick($event)">
        <div class="dialog-container">
            <!--============================================-->
            <!-- Recipient List -->
            <!--============================================-->
            <div id="recipient-list-column">
                <div id="recipient-list-card" class="card">
                    <!--============================================-->
                    <!-- Header -->
                    <!--============================================-->
                    <div id="header">
                        <h2 *ngIf="isReportLocked()" id="dialog-heading">Angefragte Bieter</h2>
                        <h2 *ngIf="!isReportLocked()" id="dialog-heading">Gebote anfragen</h2>

                        <!--********** Target Date **********-->
                        <div
                            id="residual-value-target-date-container"
                            [matTooltip]="
                                'Bis wann dürfen die Bieter ihr Gebot abgeben?\n\nDie Frist kannst du ändern, indem du den Dialog schließt und den Zeitraum auf der Restwert-Karte für alle Restwertbörsen festlegst.'
                            ">
                            <div class="label">Gebotsfrist</div>
                            <ng-container *ngIf="report.valuation.autoixpertResidualValueOffer.closingDate">
                                {{
                                    report.valuation.autoixpertResidualValueOffer.closingDate
                                        | moment: 'DD.MM.YYYY [-] HH:mm [Uhr]'
                                }}
                            </ng-container>
                            <ng-container *ngIf="!report.valuation.autoixpertResidualValueOffer.closingDate">
                                {{ residualValueTargetDate | moment: 'DD.MM.YYYY [-] HH:mm [Uhr]' }}
                            </ng-container>
                        </div>

                        <!--********** Binding Period **********-->
                        <mat-form-field
                            id="binding-period"
                            matTooltip="Wie lange muss sich der Bieter dazu verpflichten, das Fahrzeug nach Ende der Gebotsfrist kostenfrei abzuholen?">
                            <input
                                matInput
                                placeholder="Bindefrist"
                                [disabled]="isReportLocked()"
                                [(ngModel)]="report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays"
                                (change)="emitReportChange(); rememberBindingPeriod()" />
                            <span matSuffix>Tage</span>
                        </mat-form-field>

                        <!--********** Toolbar **********-->
                        <div>
                            <mat-icon
                                *ngIf="!isReportLocked()"
                                class="toolbar-icon"
                                (click)="editNewRecipient()"
                                matTooltip="Neuer Empfänger">
                                add
                            </mat-icon>
                            <mat-icon
                                class="toolbar-icon"
                                [matMenuTriggerFor]="headerToolbarSubmenu"
                                *ngIf="!isReportLocked()">
                                more_vert
                            </mat-icon>
                            <mat-icon class="toolbar-icon" (click)="emitCloseEvent()" matTooltip="Dialog schließen">
                                close
                            </mat-icon>
                            <mat-menu #headerToolbarSubmenu>
                                <mat-option
                                    (click)="rememberRecipients()"
                                    matTooltip="Die ausgewählten Empfänger als Standard setzen und bei neuen Restwertanfragen automatisch aktivieren.">
                                    Empfänger merken
                                </mat-option>
                                <mat-option (click)="revokeResidualValueRequest()">Gebotsanfrage löschen</mat-option>
                            </mat-menu>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Header -->
                    <!--============================================-->
                    <!--***********************************************
                    ** Bidder Groups
                    ************************************************-->
                    <div
                        class="bidder-group-explanation-container"
                        *ngIf="
                            !isReportLocked() && !user?.userInterfaceStates.hideResidualValueBidderGroupsExplanation
                        ">
                        <div class="bidder-group-explanation">
                            <mat-icon class="medium-icon">description</mat-icon>
                            <mat-icon
                                class="bidder-group-explanation-close-icon toolbar-icon"
                                (click)="toggleHideResidualValueBidderGroupsExplanation()"
                                matTooltip="Restwertgruppen-Erklärung nicht mehr anzeigen">
                                close
                            </mat-icon>
                            <div>
                                <h3>Restwertgruppen</h3>
                                <p>
                                    Erstelle Restwertgruppen, um deine Restwertbieter in verschiedene Interessengruppen
                                    zu unterteilen. So kannst du zum Beispiel alle BMW Händler in eine Gruppe
                                    zusammenfassen und beim Verteilen des Restwertinserates mittels eines Klicks gleich
                                    die ganze Gruppe auswählen.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isReportLocked()" class="bidder-groups-container">
                        <!--********** Group List **********-->
                        <div
                            class="bidder-group-with-image"
                            *ngFor="let bidderGroup of availableBidderGroups"
                            [ngClass]="{ selected: selectedBidderGroups.includes(bidderGroup) }"
                            (click)="toggleSelectBidderGroup(bidderGroup)">
                            <!--********** Group Title **********-->
                            <div class="display-flex flex-align-center">
                                <mat-icon class="no-brand-icon" *ngIf="!iconForCarBrandExists(bidderGroup.carBrand)">
                                    group
                                </mat-icon>
                                <img
                                    class="bidder-group-icon"
                                    *ngIf="iconForCarBrandExists(bidderGroup.carBrand)"
                                    [src]="iconFilePathForCarBrand(bidderGroup.carBrand)" />
                                <div class="bidder-group-title-container" matTooltip="{{ bidderGroup.title }}">
                                    {{ bidderGroup.title }}
                                </div>
                            </div>

                            <!--********** Number of Bidders & Submenu Icon **********-->
                            <div class="number-of-bidders-in-group-and-submenu-icon-container">
                                <p class="number-of-bidders-in-group" matTooltip="Anzahl Bieter in dieser Gruppe">
                                    {{ getAllAssignedBidders([bidderGroup]).length }}
                                </p>
                                <mat-icon
                                    class="group-submenu-icon"
                                    [matMenuTriggerFor]="bidderGroupSubmenu"
                                    (click)="$event.stopPropagation()">
                                    more_vert
                                </mat-icon>
                            </div>

                            <!--********** Submenu **********-->
                            <mat-menu #bidderGroupSubmenu>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="openResidualValueBidderGroupUpdateDialog(bidderGroup)">
                                    <mat-icon>edit</mat-icon>
                                    Titel bearbeiten
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="getSelectedRecipients().length"
                                    (click)="assignBidderGroupToSelectedBidders(bidderGroup._id)"
                                    matTooltip="Selektierte Bieter in dieser Gruppe speichern">
                                    <mat-icon>person_add</mat-icon>
                                    Bieter speichern
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="deleteResidualValueBidderGroup(bidderGroup)">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </div>

                        <!--********** Select/deselect all **********-->
                        <div
                            class="bidder-group-with-image"
                            (click)="toggleSelectionOnAllRecipients(); emitReportChange()">
                            <mat-icon class="bidder-group-icon">group</mat-icon>
                            <div class="bidder-group-title-container">
                                {{
                                    this.getSelectedRecipients().length &&
                                    this.availableRecipients.length === this.getSelectedRecipients().length
                                        ? 'Alle abwählen'
                                        : 'Alle auswählen'
                                }}
                            </div>
                        </div>

                        <!--********** New Group Button **********-->
                        <div class="new-bidder-group" (click)="openResidualValueBidderGroupTitleDialog()">
                            + Neue Gruppe
                        </div>
                    </div>

                    <!--***********************************************
                    ** END Bidder Groups
                    ************************************************-->

                    <ng-container *ngIf="availableRecipients.length">
                        <!--============================================-->
                        <!-- Contacts -->
                        <!--============================================-->
                        <table id="contact-list">
                            <tr class="contact-row" *ngFor="let contactPerson of availableRecipients">
                                <td class="contact-active-cell">
                                    <mat-checkbox
                                        [ngModel]="
                                            report.valuation.autoixpertResidualValueOffer.selectedRecipientIds.includes(
                                                contactPerson._id
                                            )
                                        "
                                        (change)="toggleRecipient(contactPerson)"
                                        [disabled]="
                                            isReportLocked() || invitationsPending.has(contactPerson)
                                        "></mat-checkbox>
                                </td>
                                <td class="contact-name-cell">
                                    <div class="contact-name-primary">
                                        {{ contactPerson.organization || getFullName(contactPerson) }}
                                    </div>
                                    <div class="contact-name-secondary" *ngIf="contactPerson.organization">
                                        {{ getFullName(contactPerson) }}
                                    </div>
                                </td>
                                <td class="contact-address-cell">{{ contactPerson.zip }} {{ contactPerson.city }}</td>
                                <td class="contact-phone-cell">
                                    {{ contactPerson.phone || contactPerson.phone2 || '-' }}
                                </td>
                                <td class="contact-email-cell">
                                    {{ contactPerson.email }}
                                </td>
                                <td class="invitation-status-cell">
                                    <ng-container
                                        *ngIf="getAssociatedInvitationReceipt(contactPerson)"
                                        [ngSwitch]="determineInvitationState(contactPerson)">
                                        <img
                                            src="/assets/images/icons/loading-indicator.svg"
                                            alt=""
                                            *ngSwitchCase="'pending'" />
                                        <mat-icon
                                            class="bid-request-received"
                                            *ngSwitchCase="'received'"
                                            matTooltip="Versendet am {{
                                                getAssociatedInvitationReceipt(contactPerson).emailSentAt
                                                    | moment: 'DD.MM.YYYY - HH:mm [Uhr]'
                                            }}">
                                            email
                                        </mat-icon>
                                        <mat-icon
                                            class="bid-request-opened"
                                            *ngSwitchCase="'opened'"
                                            matTooltip="Inserat geöffnet am {{
                                                getAssociatedInvitationReceipt(contactPerson)
                                                    .openedResidualValueRequestAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]'
                                            }}">
                                            visibility
                                        </mat-icon>
                                        <div class="bid-amount" *ngSwitchCase="'bidEntered'">
                                            {{ getAssociatedBid(contactPerson).bidValue.value | number: '1.0-0' }} €
                                        </div>
                                        <mat-icon
                                            class="bid-request-received"
                                            *ngSwitchCase="'notInterested'"
                                            [matTooltip]="
                                                getAssociatedBid(contactPerson).notInterested
                                                    ? 'Kein Interesse'
                                                    : 'Kein Gebot abgegeben'
                                            ">
                                            block
                                        </mat-icon>
                                    </ng-container>
                                </td>
                                <td *ngIf="!isReportLocked()" class="contact-row-toolbar-cell">
                                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="contactRowSubmenu">
                                        more_vert
                                    </mat-icon>
                                    <mat-menu #contactRowSubmenu>
                                        <mat-option (click)="editContact(contactPerson)">Bearbeiten</mat-option>
                                        <mat-option (click)="deleteContact(contactPerson)">Löschen</mat-option>
                                    </mat-menu>
                                </td>
                            </tr>
                        </table>
                        <!--============================================-->
                        <!-- END Contacts -->
                        <!--============================================-->

                        <!--============================================-->
                        <!-- Buttons -->
                        <!--============================================-->
                        <div class="dialog-buttons-container">
                            <button
                                *ngIf="!isReportLocked()"
                                (click)="sendInvitations()"
                                [ngClass]="{ disabled: !isSendingRequestAllowed() || invitationsPending.size > 0 }"
                                [matTooltip]="getSendButtonTooltip()">
                                <mat-icon
                                    id="send-button-icon"
                                    class="small-icon"
                                    *ngIf="invitationsPending.size === 0">
                                    send
                                </mat-icon>
                                <img
                                    src="/assets/images/icons/loading-indicator-white.svg"
                                    class="pending-icon"
                                    *ngIf="invitationsPending.size > 0" />
                                Senden
                                <span class="button-overlay" *ngIf="emailJustSent" @fadeInAndOut>
                                    <mat-icon class="button-action-done-icon">check</mat-icon>
                                </span>
                            </button>

                            <button class="secondary" (click)="openResidualValueOfferPreview()">
                                <img
                                    src="/assets/images/icons/loading-indicator.svg"
                                    *ngIf="previewPending"
                                    class="pending-icon"
                                    alt="" />
                                Vorschau
                            </button>
                        </div>
                        <!--============================================-->
                        <!-- END Buttons -->
                        <!--============================================-->
                    </ng-container>
                    <div id="no-contacts-placeholder" *ngIf="!availableRecipients.length">
                        <!--********** TODO Link knowledge base article -->
                        <div id="no-contacts-placeholder-text">
                            Hole jetzt lokale Restwertgebote von deinen Partnern ein.
                        </div>
                        <button (click)="editNewRecipient()">Neuer Empfänger</button>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Recipient List -->
            <!--============================================-->

            <!--============================================-->
            <!-- Contact Editor -->
            <!--============================================-->
            <div
                id="recipient-editor-card"
                class="card card-with-padding"
                *ngIf="!isReportLocked() && recipientInEditMode">
                <h2>Kontakt bearbeiten</h2>
                <contact-person-form
                    [contact-person]="recipientInEditMode"
                    [organizationTypes]="['bidder', 'garage']"
                    [additionalContactPeopleForAutocomplete]="previousBidders"></contact-person-form>

                <!--***********************************************
                ** Bidder Group Chip List
                ************************************************-->
                <div *ngIf="availableBidderGroups">
                    <mat-form-field class="mat-block">
                        <mat-chip-list #bidderGroupChipList>
                            <mat-chip
                                *ngFor="let groupId of recipientInEditMode.assignedBidderGroupIds"
                                (removed)="removeBidderGroupIdFromBidder(groupId, recipientInEditMode)">
                                {{ getBidderGroupById(groupId)?.title }}
                                <mat-icon matChipRemove>close</mat-icon>
                            </mat-chip>
                            <input
                                placeholder="Bietergruppen"
                                #bidderGroupInput
                                #bidderGroupAutocompleteTrigger="matAutocompleteTrigger"
                                [matChipInputFor]="bidderGroupChipList"
                                (matChipInputTokenEnd)="handleBidderGroupInputTokenEnd($event)"
                                [matChipInputAddOnBlur]="true"
                                [matAutocomplete]="bidderGroupAutocomplete"
                                (input)="selectBidderInGroup(bidderGroupInput.value)"
                                (focus)="selectBidderInGroup(bidderGroupInput.value)" />
                            <!--                        <mat-icon class="input-toolbar-icon" *ngIf="bidderGroupInput.value && !bidderGroupAlreadyExists(bidderGroupInput.value)" (click)="createResidualValueBidderGroup(bidderGroupInput.value)">add</mat-icon>-->
                        </mat-chip-list>

                        <mat-autocomplete
                            #bidderGroupAutocomplete
                            (mousedown)="$event.preventDefault()"
                            (optionSelected)="
                                selectBidderGroupFromAutocomplete(
                                    $event,
                                    bidderGroupInput,
                                    bidderGroupAutocompleteTrigger
                                );
                                selectBidderInGroup(bidderGroupInput.value)
                            ">
                            <mat-option *ngFor="let group of filteredBidderGroupsIds" [value]="group._id">
                                <div class="autocomplete-option-wrapper">
                                    <div
                                        class="autocomplete-option-label"
                                        [matTooltip]="group.title"
                                        matTooltipShowDelay="700">
                                        {{ group.title }}
                                    </div>
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <!--***********************************************
                ** END Bidder Group Chip List
                ************************************************-->

                <button
                    (click)="saveRecipientInEditMode()"
                    [ngClass]="{ disabled: !recipientInEditMode.email }"
                    [matTooltip]="recipientInEditMode.email ? '' : 'Bitte E-Mail angeben'">
                    Speichern
                </button>
                <button *ngIf="!isReportLocked()" class="flat" (click)="leaveRecipientEditMode()">Verwerfen</button>
            </div>
            <!--============================================-->
            <!-- END Contact Editor -->
            <!--============================================-->
        </div>
    </div>
</div>

import { animate, style, transition, trigger } from '@angular/animations';

export function slideOutVertical(leaveDuration = 500) {
    return trigger('slideOutVertical', [
        transition(
            ':leave',
            animate(
                `${leaveDuration}ms ease`,
                style({
                    height: 0,
                    opacity: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    overflow: 'hidden',
                }),
            ),
        ),
    ]);
}

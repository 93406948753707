<div class="valuation-calculation-container" [ngClass]="{ 'is-shifted': selectedTab === 'adjustments' }">
    <div id="garage-column" [ngClass]="{ collapsed: selectedTab !== 'garage' }" (click)="selectTab('garage')">
        <h3>Kostensätze</h3>

        <!--********** Use DEKRA Fees **********-->
        <div id="switch-garage-dekra-container">
            <div class="switch-garage-label" [ngClass]="{ active: !useDekraFees }" (click)="setUseDekraFees(false)">
                Werkstatt
            </div>

            <mat-slide-toggle
                [ngModel]="report.damageCalculation.repair.useDekraFees"
                [disabled]="isReportLocked()"
                (change)="toggleUseDekraFees()"></mat-slide-toggle>

            <div
                class="switch-garage-label"
                [ngClass]="{ active: useDekraFees }"
                (click)="setUseDekraFees(false)"
                matTooltip="{{ getDekraFeesTooltip() }}">
                DEKRA
            </div>
        </div>

        <!--********** Display location for DEKRA Fee **********-->
        <div *ngIf="useDekraFees" class="dekra-fee-settings">
            <mat-icon class="dekra-fee-location-icon">location_on</mat-icon>
            <div class="dekra-fee-city-preview">
                <span class="dekra-fee-city">{{ getCityForDekraFees() }}</span>

                <!--********** Edit DEKRA Zip **********-->
                <autogrow-textarea
                    *ngIf="report.damageCalculation.repair.customDekraFeeZip"
                    class="dekra-fee-city-input label"
                    [value]="report.damageCalculation.repair.customDekraFeeZip"
                    [placeholder]="'PLZ'"
                    [singleLine]="true"
                    [paddingHorizontal]="4"
                    (valueChange)="report.damageCalculation.repair.customDekraFeeZip = $event.value"
                    (enterPressed)="saveReport()"
                    (blurred)="saveReport()"></autogrow-textarea>

                <!--********** Display DEKRA Zip **********-->
                <div
                    *ngIf="!report.damageCalculation.repair.customDekraFeeZip"
                    (click)="addCustomDekraFeeZip()"
                    class="dekra-fee-zip label">
                    <span>{{ getZipForDekraFees() }}</span>
                    <mat-icon class="dekra-fee-edit toolbar-icon small-icon">edit</mat-icon>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!useDekraFees && !report.garage.contactPerson.organization">
            <mat-form-field
                id="garage-search-field"
                class="search-field mat-block"
                floatLabel="never"
                [matTooltip]="
                    'Diese Daten werden durchsucht: \n• Aktenzeichen\n• Kennzeichen (Format GZ-AB-1234)\n• Anspruchsteller Name, Telefon, Email\n• Werkstatt\n• Anwalt Name, Aktenzeichen\n• Versicherung\n• Versicherungsschein\n• Schadennummer\n• VIN\n• Hersteller & Modell\n• Fahrzeugfarbe\n\nSuchworte müssen mindestens drei Zeichen lang sein.'
                ">
                <input
                    matInput
                    [(ngModel)]="garageSearchTerm"
                    [matAutocomplete]="garageAutocompletion.autocomplete"
                    placeholder="Werkstatt suchen" />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
            <address-autocompletion
                #garageAutocompletion
                [disabled]="isReportLocked()"
                [contactAutocompletionEnabled]="true"
                [organizationTypeFilter]="['garage']"
                [value]="garageSearchTerm"
                [addressContext]="{}"
                [showSuggestionsIfEmpty]="true"
                (contactPersonSelected)="insertGarageInReport($event)"></address-autocompletion>
            <button (click)="createNewGarage()" class="flat">+ Werkstatt</button>
        </ng-container>
        <garage-preview
            *ngIf="!useDekraFees && report.garage.contactPerson.organization"
            [report]="report"
            (editGarageFeeSet)="garageFeesDialogShown = true"
            (reportChanged)="saveReport()"></garage-preview>
    </div>

    <div id="calculation-provider-column">
        <h2>Kalkulation</h2>
        <damage-calculation-providers
            [report]="report"
            (valuesDidChange)="emitDecreaseFromDamageChanged()"></damage-calculation-providers>
    </div>

    <div id="adjustments-column" [ngClass]="{ collapsed: selectedTab !== 'adjustments' }">
        <!--********** Header **********-->
        <div class="adjustments-header">
            <div class="adjustments-icon-container">
                <mat-icon>tune</mat-icon>
            </div>
            <div class="adjustments-header-labels">
                <h3>Anrechnung</h3>
                <p class="label">In welcher Höhe sollen die Reparaturkosten vom Fahrzeugwert abgezogen werden?</p>
            </div>
        </div>

        <!--********** Slider: select decrease percentage **********-->
        <div id="adjustments-slider-container">
            <mat-slider
                thumbLabel
                [(ngModel)]="report.valuation.decreaseFromDamagePercentage"
                (change)="emitDecreaseFromDamageChanged()"
                [displayWith]="formatPercentage"
                [min]="0"
                [max]="100"></mat-slider>
            <div
                #editDecreasePercentageButton
                class="decrease-percentage-value label cursor-pointer"
                form-field-overlay-anchor
                formFieldType="number"
                form-field-overlay-placeholder="Anrechnung"
                formFieldSuffix="%"
                (change)="emitDecreaseFromDamageChanged()"
                [(ngModel)]="report.valuation.decreaseFromDamagePercentage">
                <span>{{ report.valuation.decreaseFromDamagePercentage }}%</span>
                <mat-icon class="toolbar-icon small-icon">edit</mat-icon>
            </div>

            <mat-icon
                *ngIf="user.accessRights.editTextsAndDocumentBuildingBlocks"
                [matMenuTriggerFor]="decreaseFromDamageSubmenu"
                class="toolbar-icon save-decrease-percentage-icon">
                more_vert
            </mat-icon>
            <mat-menu #decreaseFromDamageSubmenu>
                <mat-option (click)="saveDecreaseFromDamagePercentage()">Prozentwert als Standard speichern</mat-option>
            </mat-menu>
        </div>

        <!--********** Button: Round the decrease amount  **********-->
        <div
            #roundDecreaseButton
            id="round-decrease-from-damage-button"
            class="cursor-pointer"
            (click)="openEditRoundConfiguration()">
            <mat-icon *ngIf="team.preferences.valuation_decreaseRoundType === 'floor'" class="round-icon">
                arrow_cool_down
            </mat-icon>
            <mat-icon *ngIf="team.preferences.valuation_decreaseRoundType === 'ceil'" class="round-icon">
                arrow_warm_up
            </mat-icon>
            <mat-icon *ngIf="team.preferences.valuation_decreaseRoundType === 'round'" class="round-icon">
                replay
            </mat-icon>
            <div *ngIf="team.preferences.valuation_decreaseRoundToFull" class="round-to-full-label">
                auf {{ team.preferences.valuation_decreaseRoundToFull | currency: 'EUR' : 'symbol' : '1.0-2' }}
                {{ translateRoundType(team.preferences.valuation_decreaseRoundType) }}
            </div>
            <div *ngIf="!team.preferences.valuation_decreaseRoundToFull">nicht runden</div>
            <mat-icon class="toolbar-icon small-icon">edit</mat-icon>
        </div>

        <!--********** Display Result **********-->
        <div id="decrease-result">
            <div class="decrease-result-label">Abzüge für Schäden</div>
            <div
                class="decrease-result-net label"
                matTooltip="Netto. {{ mayCarOwnerDeductTaxes() ? '' : 'Berechnet aus dem gerundeten Brutto-Betrag.' }}"
                [ngClass]="{ primary: mayCarOwnerDeductTaxes() }">
                <span>{{ report.valuation.decreaseFromDamageNet | number: '1.0-0' }} €</span>
            </div>
            <div
                class="decrease-result-gross label"
                matTooltip="Brutto. {{ mayCarOwnerDeductTaxes() ? 'Berechnet aus dem gerundeten Netto-Betrag.' : '' }}"
                [ngClass]="{ primary: !mayCarOwnerDeductTaxes() }">
                <span>{{ report.valuation.decreaseFromDamageGross | number: '1.0-0' }} €</span>
            </div>
        </div>
    </div>
</div>
<div
    id="garage-column-trigger"
    class="collapsible-column-trigger"
    (click)="selectTab('garage')"
    [ngClass]="{ collapsed: selectedTab !== 'garage' }">
    <div class="collapsible-column-handle" [matTooltip]="'Werkstatt oder Kostensätze anpassen'">
        <mat-icon>build</mat-icon>
    </div>
</div>
<div
    id="adjustments-column-trigger"
    class="collapsible-column-trigger"
    (click)="selectTab('adjustments')"
    [ngClass]="{ collapsed: selectedTab !== 'adjustments' }">
    <div class="collapsible-column-handle" [matTooltip]="'Anrechnung der Reparaturkosten anpassen'">
        <mat-icon>tune</mat-icon>
    </div>
</div>

<contact-person-editor
    *ngIf="garageForEditor"
    [contactPerson]="garageForEditor"
    (contactPersonChange)="handleGarageChange($event)"
    (close)="handleGarageEditorClose($event)"></contact-person-editor>

<garage-fees
    *ngIf="garageFeesDialogShown"
    [garageContact]="report.garage.contactPerson"
    [car]="report.car"
    [(selectedFeeSetId)]="report.garage.selectedFeeSetId"
    (contactPersonChange)="saveReport()"
    (selectedFeeSetIdChange)="saveReport()"
    (close)="garageFeesDialogShown = false; selectDefaultGarageFeeSetIfEmpty()"
    [askToUpdateContact]="true"
    [disabled]="isReportLocked()"
    @runChildAnimations></garage-fees>

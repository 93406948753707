import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';

@Injectable()
export class AutoonlineCredentialsCheckService {
    constructor(private httpClient: HttpClient) {}

    public checkCredentials(): Promise<AutoonlineCredentialsCheckResponse> {
        return this.httpClient
            .get<AutoonlineCredentialsCheckResponse>(`${apiBasePath}/autoonlineCredentialsCheck`)
            .toPromise();
    }
}

export interface AutoonlineCredentialsCheckResponse {
    credentialsValid: boolean;
}

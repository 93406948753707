import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { TutorialState } from './onboarding/tutorial-state';
import { UserGamification } from './onboarding/user-gamification';
import { UserPreferences } from './preferences/user-preferences';
import { AudatexUser } from './third-party-accounts/audatex-user';
import { AutoonlineUser } from './third-party-accounts/autoonline-user';
import { DatUser } from './third-party-accounts/dat-user';
import { EmailAccount } from './third-party-accounts/email-account';
import { GtmotiveUser } from './third-party-accounts/gtmotive-user';
import { UserUserInterfaceStates } from './user-user-interface-states';

export class User implements DataTypeBase {
    constructor(
        options?: RequireSome<
            User,
            | 'email'
            | 'password'
            | 'active'
            | 'organization'
            | 'salutation'
            | 'firstName'
            | 'lastName'
            | 'phone'
            | 'initials'
            | 'teamId'
        >,
    ) {
        Object.assign(this, options);
    }

    _id: string = generateId();
    email: string = '';
    password: string = null; // CHECK Must not be kept on the frontend. This property only exists so that TypeScript allows deletion.
    passwordUpdatedAt: Date;

    active: boolean = true;
    deactivateAt: string = null;
    //*****************************************************************************
    //  Contact Details
    //****************************************************************************/
    organization: string = null;
    salutation: string = null;
    firstName: string = null;
    lastName: string = null;
    phone: string = null;
    phone2: string = null;
    defaultOfficeLocationId: string = null;
    /**
     * Cost centers are an important grouping mechanism for accounting analytics in larger companies.
     * A assessor in autoixpert may be assigned to a cost center.
     * Multiple assessors may be in the same cost center.
     */
    costCenter: string = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Details
    /////////////////////////////////////////////////////////////////////////////*/
    initials: string = null;
    sealHash: string = null;
    // When the seal width is changed, the seal is rescaled to the new width and saved in the corresponding resolution.
    // This information is necessary to edit the seal after it was saved.
    sealConfig: SealConfiguration = new SealConfiguration();
    profilePictureHash: string = null;
    avatarColor: string = null;
    hideNameBelowSeal: boolean = false;
    occupationalQualification: string = null;
    teamId: string = null;
    preferences: UserPreferences = new UserPreferences();
    userInterfaceStates: UserUserInterfaceStates = new UserUserInterfaceStates();
    tutorialState: TutorialState = new TutorialState();
    accessRights: AccessRights = new AccessRights();
    // Required to start the damage calculation with the user's credentials
    datUser?: DatUser = new DatUser();
    audatexUser?: AudatexUser = new AudatexUser();
    gtmotiveUser?: GtmotiveUser = new GtmotiveUser();
    autoonlineUser: AutoonlineUser = new AutoonlineUser();
    cartvUser: CartvUser = new CartvUser();
    carcasionUser: CarcasionUser = new CarcasionUser();
    adeltaFinanzUser: AdeltaFinanzUser = new AdeltaFinanzUser();
    kfzvsUser: KfzvsUser = new KfzvsUser();
    persaldoUser: PersaldoUser = new PersaldoUser();
    crashback24User: Crashback24User = new Crashback24User();
    gdvUser: GdvUser = new GdvUser();
    winvalueUser: WinvalueUser = new WinvalueUser();
    afzzertUser: AfzzertUser = new AfzzertUser();
    emailAccount: EmailAccount = new EmailAccount();
    isBetaTester: boolean = false;
    isAssessor: boolean = true;
    gamification: UserGamification = new UserGamification();
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string;

    _documentVersion: number = 0;
    _schemaVersion = 8 as const;
}

export class WinvalueUser {
    customerNumber: string;
    password: string;
    apiKey: string;
}

export class CartvUser {
    customerNumber: string;
    password: string;
    accessKey: string;
}

export class CarcasionUser {
    email: string;
    password: string;
}

export class AdeltaFinanzUser {
    username: string;
    password: string;
    customerNumber: string;
}

export class KfzvsUser {
    customerNumber: number;
}

export class AfzzertUser {
    apiKey: string;
}

export class PersaldoUser {
    username: string;
    password: string;
}

export class Crashback24User {
    username: string;
    password: string;
}

export class GdvUser {
    username: string;
    password: string;
}

export class AccessRights {
    seeAllReports: boolean = true;
    lockReports: boolean = true;
    invoiceList: boolean = true;
    seeAllInvoices: boolean = true; // ...or just your own
    seeAllPayments: boolean = true; // CHECK ist his okay, from load-default-team-data.ts
    bankAccountSync: boolean = true; // ...or just your own
    seeReportFees: boolean = true; // See the fees tab within a report. Without this right, a user cannot download the invoice PDF.
    reporting: boolean = true;
    editContacts: boolean = true;
    editTextsAndDocumentBuildingBlocks: boolean = true; // Permit if the user is able to create, patch or delete texts, text templates, document building blocks and autocomplete entries.
    seeSettings: boolean = true;
}

export class SealConfiguration {
    widthInMillimeters: number;
    cropX: number = 0;
    cropY: number = 0;
    cropWidth: number = 0;
    cropHeight: number = 0;
    originalHash: string;
}

import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Report } from '@autoixpert/models/reports/report';

/**
 * Determine the sum of all custom invoice line items (not including the default ones like photo or writing fees)
 * of the given report.
 */
export function calculateOtherFeesTotal(report: Report): number {
    const totalOfCustomFeeItems = report.feeCalculation.invoiceParameters.lineItems
        // only consider active fee items
        .filter((customFee) => customFee.active)
        .reduce((previousValue: number, currentValue: LineItem) => {
            // If a value is not valid, just skip it.
            if (isNaN(currentValue.unitPrice)) {
                return previousValue;
            }
            return previousValue + currentValue.unitPrice * currentValue.quantity;
        }, 0);

    return isNaN(totalOfCustomFeeItems) ? 0 : totalOfCustomFeeItems;
}

import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { get } from 'lodash-es';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { getAvailablePlaceholders } from '@autoixpert/lib/document-building-blocks/get-available-placeholders';
import { isDocumentBuildingBlockConditionGroup } from '@autoixpert/lib/document-building-blocks/is-document-building-block-condition-group';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import {
    PlaceholderValueLeafDefinition,
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { DocumentBuildingBlockCondition } from '@autoixpert/models/documents/document-building-block-condition';
import { DocumentBuildingBlockVariant } from '@autoixpert/models/documents/document-building-block-variant';
import { isDocumentBuildingBlockSavingConditionAllowed } from '../../shared/components/document-building-block-condition-group-editor/document-building-block-condition-group-editor.utils';
import { getTypeAtPropertyPath } from '../../shared/libraries/document-building-blocks/get-type-at-property-path';
import { exampleReport } from '../../shared/mock-data/example-report';
import { FieldGroupConfigService } from '../../shared/services/field-group-config.service';

/**
 * A dialog to edit the conditions of a document building block variant.
 * These conditions are evaluated after the top-level conditions of the document building block.
 * Explicit saving is required to apply changes.
 */
@Component({
    selector: 'document-building-block-variant-editor',
    templateUrl: 'document-building-block-variant-editor.component.html',
    styleUrls: ['document-building-block-variant-editor.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class DocumentBuildingBlockVariantEditorComponent {
    readonly isDocumentBuildingBlockConditionGroup = isDocumentBuildingBlockConditionGroup;

    constructor(
        private zone: NgZone,
        private dialog: MatDialog,
        private fieldGroupConfigService: FieldGroupConfigService,
    ) {}

    @Input() initialTopPosition: string;
    @Input() buildingBlock: DocumentBuildingBlock;
    @Input('variant') originalVariant: DocumentBuildingBlockVariant;

    // Placeholder values for the selected report or invoice, used to show values in property path selection overlay and for replace placeholders in the preview
    @Input() placeholderValues: PlaceholderValues | undefined;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() openBuildingBlockEditor = new EventEmitter<void>();
    @Output() variantChange: EventEmitter<DocumentBuildingBlockVariant> =
        new EventEmitter<DocumentBuildingBlockVariant>();

    public variant: DocumentBuildingBlockVariant;
    public availableDocumentPlaceholders: string[] = [];

    // Custom Fields
    protected fieldGroupConfigs: FieldGroupConfig[] = [];
    protected placeholderValueTree: PlaceholderValueTree;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        // Let the user work on a copy until the user saves. Otherwise, the cancel button would not make sense.
        this.copyVariant();

        this.registerKeyboardShortcuts();

        await this.loadFieldGroupConfigsAndPlaceholderValueTree();
    }

    private async loadFieldGroupConfigsAndPlaceholderValueTree() {
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        this.placeholderValueTree = getPlaceholderValueTree({
            fieldGroupConfigs: this.fieldGroupConfigs,
        });

        this.availableDocumentPlaceholders = this.getAvailableDocumentPlaceholders();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Handlers
    //****************************************************************************/

    private copyVariant(): void {
        if (!this.originalVariant) {
            throw Error(
                'MISSING_VARIANT_FROM_PARENT_COMPONENT: The calling component did not supply a variant to the building block editor.',
            );
        }
        // Only work on the copy until the user saves
        this.variant = JSON.parse(JSON.stringify(this.originalVariant));
    }

    public closeAndSave(): void {
        this.markAsManuallyChanged();
        this.dispatchChangeEvent();
        this.dispatchCloseEvent({ confirm: false });
    }

    public closeAndDiscard(): void {
        this.dispatchCloseEvent({ confirm: true });
    }

    public onPropertyPathSelect(condition): void {
        this.setDefaultOperator(condition);

        // Some operators must be compared with certain value. E.g. truthy with true.
        this.setDefaultComparisonValue(condition);
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeAndDiscard();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Helpers
    //****************************************************************************/
    public isSavingAllowed(): boolean {
        return isDocumentBuildingBlockSavingConditionAllowed(this.variant, this.placeholderValueTree);
    }

    public getSavingButtonTooltip(): string {
        if (!this.isSavingAllowed()) {
            return 'Bitte alle Bedingungen vollständig ausfüllen.';
        }
    }

    public replacePlaceholders(templateWithPlaceholders: string, isHtmlAllowed: boolean = true): string {
        if (!templateWithPlaceholders) {
            return;
        }
        if (typeof templateWithPlaceholders !== 'string') {
            throw new Error(
                `Invalid data type for replacing placeholder values. This needs to be a string but is of type "${typeof templateWithPlaceholders}".`,
            );
        }

        // Make sure the "Besichtigung" placeholder is filled. That's a placeholder that only exists within a loop. We just assume the first visit as the target for this preview.
        templateWithPlaceholders = templateWithPlaceholders.replace(/{Besichtigung\./g, '{ErsteBesichtigung.');

        return replacePlaceholders({
            textWithPlaceholders: templateWithPlaceholders,
            placeholderValues: this.placeholderValues || (exampleReport as unknown as PlaceholderValues),
            fieldGroupConfigs: this.fieldGroupConfigs,
            isHtmlAllowed,
        });
    }

    public handleBuildingBlockConditionsInfoCalloutClick() {
        this.openBuildingBlockEditor.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Helpers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Conditions
    //****************************************************************************/

    public propertyPathExists(propertyPath: string): boolean {
        return !!get(this.placeholderValueTree, propertyPath);
    }

    public getTypeAtPropertyPath(propertyPath: string) {
        return getTypeAtPropertyPath(propertyPath, this.placeholderValueTree);
    }

    // Operators
    /**
     * @param {string} propertyPath
     * @returns {string[]}
     */
    public getOperatorsForProperty(propertyPath: string): DocumentBuildingBlockCondition['operator'][] {
        const type = this.getTypeAtPropertyPath(propertyPath);

        // Possible operators depend on the property's type
        switch (type) {
            case 'String':
                return ['equal', 'notEqual', 'includes', 'includesNot', 'empty', 'notEmpty'];
            case 'Boolean':
                return ['truthy', 'falsy'];
            case 'Tristate':
                return ['truthy', 'falsy', 'unknown'];
            case 'Number':
                return [
                    'equal',
                    'notEqual',
                    'greaterThan',
                    'greaterThanOrEqual',
                    'lessThan',
                    'lessThanOrEqual',
                    'empty',
                    'notEmpty',
                    'truthy',
                    'falsy',
                ];
            case 'Date':
            case 'Date-month-only':
            case 'Time':
                return [
                    'equal',
                    'notEqual',
                    'greaterThan',
                    'greaterThanOrEqual',
                    'lessThan',
                    'lessThanOrEqual',
                    'empty',
                    'notEmpty',
                ];
            case 'Array':
                return ['includes', 'includesNot', 'empty', 'notEmpty'];
            default:
                return [];
        }
    }

    private setDefaultOperator(condition: DocumentBuildingBlockCondition): string {
        if (!condition.operator) {
            condition.operator = this.getOperatorsForProperty(condition.propertyPath)[0];
        }

        return condition.operator;
    }

    public getComparisonValueType(propertyPath: string): string {
        const type = this.getTypeAtPropertyPath(propertyPath);

        switch (type) {
            case 'String':
            case 'Array':
                return 'text';
            case 'Number':
                return 'number';
            case 'Date':
            case 'Date-month-only':
                return 'date';
            case 'Boolean':
                return 'boolean';
            case 'Tristate':
                return 'tristate';
            case 'Time':
                return 'time';
            case undefined:
                return null;
            default:
                throw Error('UNKNOWN_COMPARISON_VALUE_TYPE. Please use types like String oder Number.');
        }
    }

    /**
     * Depending on the placeholder's type, replace forbidden comparison values with an allowed one.
     *
     * @param {DocumentBuildingBlockCondition} condition
     */
    public setDefaultComparisonValue(condition: DocumentBuildingBlockCondition): void {
        const propertyType = get(this.placeholderValueTree, condition.propertyPath);

        // Booleans always compare to 'true'
        if (propertyType === 'Boolean') {
            condition.comparisonValue = true;
            return;
        }

        // in all other cases, replace true or null values
        if (condition.comparisonValue === true || condition.comparisonValue === null) {
            condition.comparisonValue = '';
            return;
        }
    }

    public getComparisonValueSuggestions(condition: DocumentBuildingBlockCondition): {
        availableSuggestions: string[];
        suggestionType: 'enum' | 'autocomplete';
    } {
        const propertyType: PlaceholderValueLeafDefinition = get(
            this.placeholderValueTree,
            condition.propertyPath,
        ) as PlaceholderValueLeafDefinition;
        let availableSuggestions: string[] = [];
        let suggestionType: 'enum' | 'autocomplete' = null;

        if (propertyType) {
            if (typeof propertyType === 'object' && ('enum' in propertyType || 'autocomplete' in propertyType)) {
                availableSuggestions = propertyType.enum || propertyType.autocomplete || [];
                if (typeof propertyType.enum !== 'undefined') {
                    suggestionType = 'enum';
                }
                if (typeof propertyType.autocomplete !== 'undefined') {
                    suggestionType = 'autocomplete';
                }
            }
        }

        return {
            availableSuggestions,
            suggestionType,
        };
    }

    public filterComparisonValueSuggestions(condition: DocumentBuildingBlockCondition): string[] {
        const { availableSuggestions } = this.getComparisonValueSuggestions(condition);

        const searchTerms: string[] = (condition.comparisonValue || '').toLowerCase().split(' ');

        return availableSuggestions.filter((suggestion) => {
            const suggestionInLowerCase: string = suggestion.toLowerCase();
            return searchTerms.every((searchTerm) => suggestionInLowerCase.includes(searchTerm));
        });
    }

    public isValidComparisonValue(condition: DocumentBuildingBlockCondition): boolean {
        const { availableSuggestions, suggestionType } = this.getComparisonValueSuggestions(condition);

        if (!availableSuggestions.length) return true;

        // In case of enum, only allow the specified values.
        if (suggestionType === 'enum') {
            return availableSuggestions.includes(condition.comparisonValue);
        }
        // In case of autocomplete, allow any value.
        if (suggestionType === 'autocomplete') {
            return true;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Conditions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Placeholders
    //****************************************************************************/
    public getAvailableDocumentPlaceholders(): string[] {
        return getAvailablePlaceholders({
            buildingBlockPlaceholder: this.buildingBlock.placeholder,
            fieldGroupConfigs: this.fieldGroupConfigs,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Placeholders
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    private registerKeyboardShortcuts(): void {
        /**
         * No change detection is required on every keypress, only when ctrl & enter are hit at the same time. That is returned to
         * the Angular zone further down in ctrlEnterEventListener.
         */
        this.zone.runOutsideAngular(() => {
            window.addEventListener('keydown', this.keydownEventListener);
        });
    }

    private unregisterKeyboardShortcuts(): void {
        window.removeEventListener('keydown', this.keydownEventListener);
    }

    private keydownEventListener = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            this.zone.run(() => {
                this.closeAndDiscard();
            });
        }

        if (event.ctrlKey && event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            /**
             * The keydown listener was set to run outside of Angular's zone which triggers automatic change detection. Otherwise,
             * entering text really fast in Quill was too slow. It just felt weird when typing fast.
             */
            this.zone.run(() => {
                this.closeAndSave();
            });
        }
    };

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * If the user has changed heading, content or conditions mark this variant as changed.
     */
    private markAsManuallyChanged(): void {
        if (
            this.variant.heading !== this.originalVariant.heading ||
            this.variant.content !== this.originalVariant.content ||
            JSON.stringify(this.variant.conditions) !== JSON.stringify(this.originalVariant.conditions)
        ) {
            this.variant.changedByUser = true;
        }
    }

    private dispatchChangeEvent(): void {
        this.variantChange.emit(this.variant);
    }

    private dispatchCloseEvent({ confirm }: { confirm: boolean }): void {
        // Rudimentary check if the user has changed anything
        const hasUserChangedVariant = JSON.stringify(this.variant) !== JSON.stringify(this.originalVariant);

        // Do not confirm if the user has not changed anything or confirmation is not required
        if (!confirm || !hasUserChangedVariant) {
            this.close.emit();
            return;
        }

        // Open a confirm dialog
        this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                data: {
                    heading: 'Daten verwerfen?',
                    content:
                        'Du hast die Bedingungen verändert, aber noch nicht gespeichert.\nMöchtest du deine Änderungen verwerfen?',
                    confirmLabel: 'Weg damit',
                    cancelLabel: 'Behalten',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.close.emit();
                    }
                },
            });
    }

    ngOnDestroy(): void {
        this.unregisterKeyboardShortcuts();
    }
}

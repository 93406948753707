import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { HelpPanelService } from '../../../../shared/services/help-panel.service';

@Component({
    selector: 'number-of-reports-congrats-dialog',
    templateUrl: 'number-of-reports-congrats-dialog.component.html',
    styleUrls: ['number-of-reports-congrats-dialog.component.scss'],
})
export class NumberOfReportsCongratsDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<NumberOfReportsCongratsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: NumberOfReportsCongratsDialogData,
        private helpPanelService: HelpPanelService,
    ) {}

    public openRecommendationPage(): void {
        this.dialogRef.close();
        this.helpPanelService.openHelpPanel('referral');
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}

export interface NumberOfReportsCongratsDialogData {
    numberOfReports: number;
    numberOfReportsThreshold: number;
    revenue: number;
}

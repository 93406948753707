import { Pipe, PipeTransform } from '@angular/core';
import { extractConstructionTimesFromDatBaseModelName } from '../lib/car/extract-construction-times-from-dat-base-model-name';

/**
 * Make base model more readable by removing technical details.
 *
 * DAT base models come with additional info, such as construction times as in
 * "Audi A3 Sportback (8PA)(09.2004->)".
 *
 * This pipe removes:
 * - construction time
 * - model type code in brackets
 */
@Pipe({
    name: 'cleanBaseModel',
    standalone: true,
})
export class CleanBaseModelPipe implements PipeTransform {
    transform(baseModel: string = ''): string {
        const { baseModelName: baseModelWithoutConstructionTime } =
            extractConstructionTimesFromDatBaseModelName(baseModel);

        return baseModelWithoutConstructionTime.replace(/\(.*/, '');
    }
}

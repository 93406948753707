import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import {
    getCounterResetCycleLabel,
    getInvoiceNumberJournalEntryDescription,
} from '@autoixpert/lib/invoice-number-journal-entry/get-invoice-number-journal-entry-description';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { InvoiceNumberJournalEntry } from '@autoixpert/models/teams/invoice-number-journal-entry';
import { CounterResetCycle } from '@autoixpert/models/teams/invoice-number-or-report-token-counter';
import { InvoiceOrReportCounterConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { Team } from '@autoixpert/models/teams/team';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { InternalNotesPanelAnchorDirective } from 'src/app/shared/components/internal-notes-panel/internal-notes-panel-anchor.directive';
import { DownloadService } from 'src/app/shared/services/download.service';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { ScreenTitleService } from 'src/app/shared/services/screen-title.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
    selector: 'invoice-number-journal',
    templateUrl: './invoice-number-journal.component.html',
    styleUrls: ['./invoice-number-journal.component.scss'],
})
export class InvoiceNumberJournalComponent implements OnInit, AfterViewInit, OnDestroy {
    protected readonly trackById = trackById;

    private team: Team;
    private user: User;
    protected users: User[];
    protected initialized = false;
    private reports: Report[] = [];
    private invoices: Invoice[] = [];
    protected invoiceNumberJournalEntries: InvoiceNumberJournalEntry[] = [];

    protected searchTerm: string;
    protected dateLimitFrom: string = '';
    protected dateLimitTo: string = '';
    private searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private dateLimitFrom$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private dateLimitTo$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    private searchServerSubscription: Subscription;
    private searchAfterPaginationToken = null;
    protected allJournalEntriesLoadedWithCurrentFilters = false;

    protected notesIconManuallyShown: { [key: string]: boolean } = {};
    protected dateLimitInputsShown: boolean = false;

    constructor(
        private httpClient: HttpClient,
        private readonly userService: UserService,
        protected readonly userPreferences: UserPreferencesService,
        private readonly screenTitleService: ScreenTitleService,
        private readonly reportService: ReportService,
        private readonly invoiceService: InvoiceService,
        private readonly loggedInUserService: LoggedInUserService,
        private readonly invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
        private toastService: ToastService,
        private downloadService: DownloadService,
    ) {}

    @ViewChildren('notesPanelAnchor') notesPanelAnchors?: InternalNotesPanelAnchorDirective[];

    ngOnInit() {
        this.screenTitleService.setScreenTitle({ screenTitle: 'Rechnungsnummern-Journal' });
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();
        this.setupSearch();
    }

    protected getJournalEntryInvoiceNumberOrReportCounter(journalEntry: InvoiceNumberJournalEntry): {
        label: string;
        type?: 'invoice' | 'report';
    } {
        if (journalEntry.entryType === 'reportInvoiceSubCounterChanged') {
            return {
                label: 'Unterzähler',
                type: 'report',
            };
        }

        if (journalEntry.invoiceNumberConfigId) {
            const invoiceNumberConfig = this.team.invoicing.invoiceNumberConfigs.find(
                (config) => config._id === journalEntry.invoiceNumberConfigId,
            );
            return {
                label:
                    invoiceNumberConfig?.title ||
                    this.getOfficeLocationTitle(invoiceNumberConfig) ||
                    'Gelöschter Zähler',
                type: 'invoice',
            };
        }

        if (journalEntry.reportTokenConfigId) {
            const reportTokenConfig = this.team.reportTokenConfigs.find(
                (config) => config._id === journalEntry.reportTokenConfigId,
            );
            return {
                label:
                    reportTokenConfig?.title ||
                    this.getOfficeLocationTitle(reportTokenConfig) ||
                    'Gelöschter Aktenzeichenkreis',
                type: 'report',
            };
        }

        return { label: 'Kein Zähler' };
    }

    private getOfficeLocationTitle(counterConfig?: InvoiceOrReportCounterConfig): string {
        if (!counterConfig) return '';
        return (
            this.team.officeLocations
                .filter((location) => counterConfig.associatedOfficeLocationIds.includes(location._id))
                .map((location) => location.title)
                .join(', ') || (counterConfig.isDefault ? 'Standard' : 'Alle anderen Standorte')
        );
    }

    protected getInvoiceNumberCounterEventInfo(journalEntry: InvoiceNumberJournalEntry): {
        description: string;
        details?: string;
    } {
        if (journalEntry.invoiceNumber || journalEntry.previousInvoiceNumber) {
            return undefined;
        }

        return getInvoiceNumberJournalEntryDescription(journalEntry);
    }

    private getResetCycleLabel(resetCycle: CounterResetCycle) {
        return getCounterResetCycleLabel(resetCycle);
    }

    protected getJournalEntryReference(journalEntry: InvoiceNumberJournalEntry): {
        label: string;
        url: string;
        deletedAt?: string;
        repairConfirmationDeleted?: boolean;
    } {
        // Check if the journal entry is related to a report (takes precedence)
        const report = this.reports.find((report) => report._id === journalEntry.reportId);
        if (report) {
            let documentType, url;
            let repairConfirmationDeleted: boolean | undefined = undefined;
            switch (journalEntry.documentType) {
                case 'expertStatement': {
                    documentType = 'Stellungnahme';
                    url = `/Gutachten/${report._id}/Stellungnahme?tab=Rechnung&Stellungnahme=${journalEntry.expertStatementId}`;
                    break;
                }

                case 'repairConfirmation': {
                    documentType = 'Rep-Best.';
                    url = `/Gutachten/${report._id}/Reparaturbestätigung`;
                    repairConfirmationDeleted = !report.repairConfirmation;
                    break;
                }

                default: {
                    documentType = 'Gutachten';
                    url = `/Gutachten/${report._id}/Rechnung`;
                    break;
                }
            }

            return {
                url,
                label: `${documentType} | ${report.token || 'Kein Aktenzeichen'}`,
                deletedAt: report.deletedAt,
                repairConfirmationDeleted,
            };
        }

        // Check if the journal entry is related to an invoice
        const invoice = this.invoices.find((invoice) => invoice._id === journalEntry.invoiceId);
        if (invoice) {
            if (invoice.reportIds?.length > 0) {
                return {
                    label: `Rechnung | ${invoice.number || 'Keine Rechnungsnummer'}`,
                    url: `/Rechnungen/${invoice._id}`,
                };
            } else {
                return {
                    label: `freie Rechnung`,
                    url: `/Rechnungen/${invoice._id}`,
                };
            }
        }

        return undefined;
    }

    //*****************************************************************************
    //  Filter & Sort
    //****************************************************************************/

    protected resetQuickFilter() {
        this.userPreferences.invoiceNumberJournalQuickFilter = null;
        this.userPreferences.invoiceNumberJournal_usersForFilter = [];
        this.loadInvoiceNumberJournalEntries();
    }

    protected selectQuickFilterDocumentType(
        documentType: 'report' | 'expertStatement' | 'repairConfirmation' | 'invoice',
    ) {
        this.userPreferences.invoiceNumberJournal_documentTypeForFilter = documentType;
    }

    protected selectQuickFilter(quickFilter: 'documentType' | 'createdBy') {
        this.userPreferences.invoiceNumberJournalQuickFilter = quickFilter;
        this.loadInvoiceNumberJournalEntries();
    }

    protected async selectSortStrategy(strategy: UserPreferences['invoiceNumberJournalSortBy']) {
        this.userPreferences.invoiceNumberJournalSortBy = strategy;
        await this.loadInvoiceNumberJournalEntries();
    }

    protected async toggleSortDirection(): Promise<void> {
        this.userPreferences.sortInvoiceNumberJournalDescending =
            !this.userPreferences.sortInvoiceNumberJournalDescending;
        await this.loadInvoiceNumberJournalEntries();
    }

    protected toggleSelectUserForFilter(userId: string) {
        toggleValueInArray(userId, this.userPreferences.invoiceNumberJournal_usersForFilter);

        // Trigger the services setter to save the preferences in the user object.
        this.userPreferences.invoiceNumberJournal_usersForFilter =
            // eslint-disable-next-line no-self-assign
            this.userPreferences.invoiceNumberJournal_usersForFilter;

        // If no user is selected, the quick filter shall be cleared.
        this.userPreferences.invoiceNumberJournalQuickFilter = this.userPreferences.invoiceNumberJournal_usersForFilter
            .length
            ? 'createdBy'
            : null;

        this.loadInvoiceNumberJournalEntries();
    }

    protected getUserFullName(userId: string) {
        return this.userService.getTeamMembersName(userId);
    }

    //*****************************************************************************
    //  END Filter & Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Notes
    //****************************************************************************/
    /**
     * If notes exist already, the notes icon will be displayed automatically.
     * This method allows displaying the notes icon manually, e.g. through the submenu of a report record.
     */
    protected showNotesManually(journalEntry: InvoiceNumberJournalEntry) {
        this.notesIconManuallyShown[journalEntry._id] = true;
        setTimeout(() => {
            this.notesPanelAnchors.forEach((notesPanelAnchor) => {
                if (notesPanelAnchor.hostElement.id === `notes-icon-${journalEntry._id}`) {
                    notesPanelAnchor.openNotesPanel();
                }
            });
        }, 100);
    }

    protected hideIconForEmptyNotes(journalEntry: InvoiceNumberJournalEntry) {
        if (!journalEntry.notes.length) {
            this.notesIconManuallyShown[journalEntry._id] = false;
        }
    }
    //*****************************************************************************
    //  END Notes
    /////////////////////////////////////////////////////////////////////////////*/

    protected async saveInvoiceNumberJournalEntry(journalEntry: InvoiceNumberJournalEntry) {
        await this.invoiceNumberJournalEntryService.put(journalEntry);
    }

    protected async loadInvoiceNumberJournalEntries({ loadMore }: { loadMore?: boolean } = {}) {
        if (!loadMore) {
            this.searchAfterPaginationToken = null;
        }

        let $sort;
        const sortDirection = this.userPreferences.sortInvoiceNumberJournalDescending ? -1 : 1;
        switch (this.userPreferences.invoiceNumberJournalSortBy) {
            case 'createdAt': {
                $sort = { createdAt: sortDirection };
                break;
            }
            case 'invoiceNumber': {
                $sort = { invoiceNumber: sortDirection };
                break;
            }
        }

        const filters = {};

        switch (this.userPreferences.invoiceNumberJournalQuickFilter) {
            case 'createdBy': {
                filters['createdBy'] = { $in: this.userPreferences.invoiceNumberJournal_usersForFilter };
                break;
            }

            case 'documentType': {
                filters['documentType'] = this.userPreferences.invoiceNumberJournal_documentTypeForFilter;
                break;
            }
        }

        if (this.dateLimitFrom) {
            filters['createdAt'] = {
                ...filters['createdAt'],
                // Use $gt instead of $gte to fix a bug where same from and to date would not return any results
                $gt: this.dateLimitFrom,
            };
        }

        if (this.dateLimitTo) {
            filters['createdAt'] = {
                ...filters['createdAt'],
                $lte: DateTime.fromISO(this.dateLimitTo).plus({ days: 1 }).toISODate(),
            };
        }

        const limit = 100;
        const findResult = await this.invoiceNumberJournalEntryService.getJournalEntriesFromServerOrIndexedDB({
            searchTerm: this.searchTerm,
            limit,
            searchAfterPaginationToken: this.searchAfterPaginationToken,
            query: {
                $sort,
                ...filters,
            },
        });

        const { records: invoiceNumberJournalEntries, lastPaginationToken } = findResult;

        if (!loadMore || lastPaginationToken) {
            this.searchAfterPaginationToken = lastPaginationToken;
        }

        if (invoiceNumberJournalEntries.length < limit) {
            this.allJournalEntriesLoadedWithCurrentFilters = true;
        }

        // Load reports referenced by journal entries
        const reportIds = invoiceNumberJournalEntries.map((entry) => entry.reportId);
        const reports = await this.reportService
            .find({
                _id: { $in: reportIds },
            })
            .toPromise();

        // Load invoices referenced by journal entries
        const invoiceIds = invoiceNumberJournalEntries.map((entry) => entry.invoiceId);
        const invoices = await this.invoiceService
            .find({
                _id: { $in: invoiceIds },
            })
            .toPromise();

        if (loadMore) {
            this.invoiceNumberJournalEntries = [...this.invoiceNumberJournalEntries, ...invoiceNumberJournalEntries];
            this.reports = [...this.reports, ...reports];
            this.invoices = [...this.invoices, ...invoices];
        } else {
            this.invoiceNumberJournalEntries = invoiceNumberJournalEntries;
            this.reports = reports;
            this.invoices = invoices;
        }
    }

    protected updateSearchTerm$(): void {
        this.searchTerm$.next(this.searchTerm);
    } /**
     * Every time the search term changes, trigger a server search.
     *
     * Subscribe to the stream of search terms and trigger a search on the server.
     * Searches are only performed with a delay after the user stopped typing, and if the
     * search term is three letters or longer.
     */
    private setupSearch(): void {
        this.searchServerSubscription = this.searchTerm$
            .pipe(
                debounceTime(500),
                // Ensure that moving bettween 0-2 letter search letters does not trigger a search
                map((searchTerm) => ((searchTerm || '').length <= 2 ? '' : searchTerm)),
                distinctUntilChanged(),
            )
            .subscribe({
                next: () => {
                    this.loadInvoiceNumberJournalEntries();
                },
            });
    }

    protected async exportInvoiceNumberJournal() {
        let params = new HttpParams();
        // Sort
        params = params.set('sort', this.userPreferences.invoiceNumberJournalSortBy);
        params = params.set('sortDirection', this.userPreferences.sortInvoiceNumberJournalDescending ? 'desc' : 'asc');

        // Filters
        if (this.userPreferences.invoiceNumberJournalQuickFilter === 'documentType') {
            params = params.set('documentType', this.userPreferences.invoiceNumberJournal_documentTypeForFilter);
        }
        // Only add createdBy filter if at least one user is selected (no results otherwise)
        if (
            this.userPreferences.invoiceNumberJournalQuickFilter === 'createdBy' &&
            this.userPreferences.invoiceNumberJournal_usersForFilter &&
            this.userPreferences.invoiceNumberJournal_usersForFilter.length > 0
        ) {
            this.userPreferences.invoiceNumberJournal_usersForFilter.forEach((userId) => {
                params = params.append('createdByUserIds[]', userId);
            });
        }

        if (this.dateLimitFrom) {
            params = params.set('createdAt[$gte]', this.dateLimitFrom);
        }

        if (this.dateLimitTo) {
            params = params.set('createdAt[$lte]', DateTime.fromISO(this.dateLimitTo).plus({ days: 1 }).toISODate());
        }

        this.httpClient
            .get(`/api/v0/exports/invoiceNumberJournal`, {
                observe: 'response',
                responseType: 'blob',
                params,
            })
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    console.error('ERROR_EXPORTING_INVOICE_NUMBER_JOURNAL', { error });
                    this.toastService.error(
                        'Export Rechnungsnummern-Journal gescheitert',
                        'Bitte kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                    );
                },
            });
    }

    protected toggleDateLimitInputs(): void {
        if (this.dateLimitInputsShown) {
            this.dateLimitFrom = null;
            this.dateLimitTo = null;
            this.updateDateLimitFrom(null);
            this.updateDateLimitTo(null);
        }

        this.dateLimitInputsShown = !this.dateLimitInputsShown;
        this.loadInvoiceNumberJournalEntries();
    }

    protected updateDateLimitFrom(date: string): void {
        this.dateLimitFrom$.next(date);
    }

    protected updateDateLimitTo(date: string): void {
        this.dateLimitTo$.next(date);
    }

    async ngAfterViewInit() {
        this.team = this.loggedInUserService.getTeam();
        this.users = await this.userService.getActiveTeamMembers(this.team, this.user);
        await this.loadInvoiceNumberJournalEntries();

        this.initialized = true;
    }

    ngOnDestroy() {
        this.searchServerSubscription.unsubscribe();
    }
}

import { ElementRef } from '@angular/core';
import { Canvas, FabricImage, FabricObject, Group, Rect, TPointerEventInfo, util } from 'fabric';
import { PhotoConfigurationPattern } from '@autoixpert/models/reports/damage-description/photo';
import { DEFAULT_FABRIC_CONTROL_OPTIONS } from './fabric-js-custom-types';
import { CanvasBlurPatternManager } from './photo-editor.canvas-manager.blur-pattern';
import { CanvasObjectManager } from './photo-editor.canvas-manager.objects';
import { PhotoEditorComponent } from './photo-editor.component';
import { CanvasManagerOptions, FabricImageScaleOptions } from './photo-editor.interfaces';

export class CanvasManager {
    editor: PhotoEditorComponent;

    objectManager: CanvasObjectManager;
    blurPatternManager: CanvasBlurPatternManager;

    // This is the object where the fabric.js canvas lives (not the DOM element)
    canvas: Canvas;
    // Object where fabric.js instance of the photo lives
    fabricPhoto: FabricImage;
    private fabricPhotoOriginalDimensions;

    private canvasHTMLElement: ElementRef<HTMLCanvasElement>;
    private imageContainerHTMLElement: ElementRef<HTMLDivElement>;

    // Becomes true as soon as the image is loaded into the canvas the first time.
    // This only concerns the photo itself, not any shapes rendered onto it.
    imageLoaded: boolean = false;

    /**
     * Whether the zoom tool is currently active. Storing this state separately
     * from other tools is necessary, because the zoom tool stays active
     * even if another tool is selected.
     */
    public isZoomActive: boolean = false;

    // A list of canvas groups with numbered tokens (blue chips) in them
    numberTokens: any[] = [];
    activeNumberToken: any = null;

    // History (undo / redo)
    /**
     * Current state of the canvas. This is the serialized object of the canvas (created using serializeCanvas()).
     */
    private currentHistoryState: any;

    /**
     * Only store this amount of history entries. If reached -> drop the oldest entry.
     */
    private readonly MAX_NUMBER_HISTORY_ENTRIES = 100;

    /**
     * List of states that the user can go back to (undo).
     */
    public undoStates = [];

    /**
     * List of states that the user just undid and that can be redone.
     */
    public redoStates = [];

    /**
     * Flag to remember, if the history has been initialized yet.
     */
    private historyInitialized = false;

    /**
     * Helper to disable saving to the history stack. Helpful if an action triggers multiple object:modified events
     * that should not end up in the history stack.
     */
    private historyDisabled = false;

    /**
     * Storing the blobUrl of the current image so we can initialize the image again after navigating the history (redo/undo).
     */
    private blobUrl: string;

    constructor(parameters: CanvasManagerOptions) {
        this.editor = parameters.editor;

        this.blurPatternManager = new CanvasBlurPatternManager({
            canvasManager: this,
        });

        this.objectManager = new CanvasObjectManager({
            canvasManager: this,
            blurPatternManager: this.blurPatternManager,
        });

        this.canvasHTMLElement = parameters.canvasHTMLElement;
        this.imageContainerHTMLElement = parameters.imageContainerHTMLElement;
    }

    /**
     * Create a new canvas, load image into canvas, add shapes, and apply filters.
     */
    async init({ photoBlobUrl, fabricJsInfo }: { photoBlobUrl: string; fabricJsInfo?: any }): Promise<void> {
        this.blobUrl = photoBlobUrl;
        this._createCanvas();

        // Save a reference to the photo for which the image file is loaded. If the user jumps to the next photo before this image file downloads, do not insert it
        // into the canvas.
        const photoBeforeImageLoaded = this.editor.photo;

        // Add photo to canvas
        const fabricPhoto = await FabricImage.fromURL(photoBlobUrl);

        /**
         * If the photo ID in the address bar does not match the ID of this photo any more at the time
         * of arrival of the photo, return from the function.
         */
        const photoIdInAddressBar: string = this.editor.photo._id;

        // If the user jumped to the next image while the image loaded, do not insert the image into the canvas.
        if (photoBeforeImageLoaded && photoIdInAddressBar !== photoBeforeImageLoaded._id) {
            return;
        }

        // In case the user reloads the editor, the init function is correctly called twice (localStorage & server). Still, in that case we must not duplicate all canvas objects.
        if (this.imageLoaded) {
            this.clear();
        }

        // Increase performance on adding any shapes
        this.canvas.renderOnAddRemove = false;

        this.fabricPhoto = fabricPhoto;
        // Set this image apart from all other images that may be added to the photo (e.g. watermarks)
        this.fabricPhoto.data = {
            axType: 'photo',
        };

        // Save original dimensions for rescaling the image later.
        this.fabricPhotoOriginalDimensions = {
            height: this.fabricPhoto.height,
            width: this.fabricPhoto.width,
        };
        this.fabricPhoto.set({
            selectable: false,
            hoverCursor: 'default',
        });

        this.canvas.add(this.fabricPhoto);

        /**
         * Ensure the fabric.js filters have enough space to render the photo. This needs to be the original photo
         * with/height, not the photo width/height after scaling it down to fit the monitor (canvas width/height).
         */
        this.editor.filterManager.adjustFilterBackend(Math.max(this.fabricPhoto.width, this.fabricPhoto.height));

        // Add filters and shapes to canvas before it is displayed to the user. Otherwise, there will be
        // flickering on the user's canvas
        if (fabricJsInfo || this.editor.photo.versions[this.editor.photoVersion].fabricJsInformation) {
            const savedCanvas: PhotoConfigurationPattern['fabricJsInformation'] = JSON.parse(
                JSON.stringify(
                    fabricJsInfo || this.editor.photo.versions[this.editor.photoVersion].fabricJsInformation,
                ),
            );

            // Ensure that the fabricJsInfo objects contain at least the photo object.
            if (
                !savedCanvas.objects.find(
                    (object) => object.type.toLowerCase() === 'image' && object.data && object.data.axType === 'photo',
                )
            ) {
                console.warn(`The savedCanvas object does not contain the photo object. Adding it now.`);
                const image = new FabricImage(new Image(), {
                    data: {
                        axType: 'photo',
                    },
                    height: this.fabricPhoto.height,
                    width: this.fabricPhoto.width,
                });
                savedCanvas.objects.push(image.toObject(['data']));
            }

            const savedFilters = savedCanvas.axFilters || {};
            let photo: FabricImage = null;

            // Iterate over all objects and save the photo.
            savedCanvas.objects.forEach((object: FabricObject, index: number) => {
                // Pick the photo from the serialized objects. The property axType sets the photo apart from other
                // images such as watermarks.
                if (object.type.toLowerCase() === 'image' && object.data && object.data.axType === 'photo') {
                    photo = object as FabricImage;

                    // Restore cropped image
                    this.fabricPhoto.cropX = photo.cropX;
                    this.fabricPhoto.cropY = photo.cropY;
                    this.fabricPhoto.width = photo.width;
                    this.fabricPhoto.height = photo.height;
                    this.fabricPhoto.data = photo.data;

                    // The photo is added differently from the other shapes (load via URL), so remove it form the objects array.
                    savedCanvas.objects.splice(index, 1);

                    // Recover the angle with which the photo was saved.
                    this.fabricPhoto.rotate(photo.angle);
                }
            });

            // Save original dimensions for rescaling the image later.
            this.fabricPhotoOriginalDimensions = {
                height: this.fabricPhoto.height,
                width: this.fabricPhoto.width,
            };

            // Must be called before rescaling objects so we know the current image's scale and position.
            this.resize();
            this.canvas.requestRenderAll();

            // Rescale and reposition
            this.objectManager.rescaleObjects(savedCanvas.objects, photo);

            //*****************************************************************************
            //  Watermark
            //****************************************************************************/
            const watermarkImageObject = savedCanvas.objects.find(
                (fabricObject) => fabricObject.data?.axType === 'watermarkImage',
            );
            if (watermarkImageObject) {
                if (
                    this.editor.team.preferences.watermark.type === 'image' &&
                    this.editor.team.preferences.watermark.imageHash
                ) {
                    let watermarkImageBlob: Blob;
                    try {
                        watermarkImageBlob = await this.editor.watermarkImageFileService.get(
                            this.editor.team._id,
                            this.editor.team.preferences.watermark.imageHash,
                        );
                    } catch (error) {
                        if (error.code === 'WATERMARK_IMAGE_NOT_FOUND_LOCALLY_AND_CLIENT_OFFLINE') {
                            this.editor.toastService.warn(
                                'Wasserzeichen offline nicht gefunden',
                                'Stelle eine Internetverbindung her, um das Wasserzeichen abzurufen.',
                            );
                        } else {
                            this.editor.toastService.warn(
                                'Wasserzeichen nicht gefunden',
                                'Lade bitte das Wasserzeichen-Bild erneut über die Wasserzeichen-Einstellungen hoch.',
                            );
                        }
                    }

                    let watermarkImageUrl: string;
                    if (watermarkImageBlob) {
                        watermarkImageUrl = window.URL.createObjectURL(watermarkImageBlob);
                    }
                    watermarkImageObject.src = watermarkImageUrl;
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Watermark
            /////////////////////////////////////////////////////////////////////////////*/

            const enlivenedObjects = await util.enlivenObjects<FabricObject>(savedCanvas.objects);
            enlivenedObjects.forEach((enlivenedObject) => {
                enlivenedObject.set(DEFAULT_FABRIC_CONTROL_OPTIONS);
                /**
                 * Prevent moving objects if a report is locked.
                 *
                 * Alternative against which we decided: Prevent all pointer events through CSS on the canvas.
                 * Unfortunately, that prevents going to the next or previous photo through clicking on the arrows on the right
                 * or left of the editor. Also, it prevents opening text boxes on number tokens through double clicks.
                 */
                if (this.editor.isReportLocked()) {
                    enlivenedObject.set({
                        selectable: false,
                        hoverCursor: 'default',
                    });
                }
                this.canvas.add(enlivenedObject);
            });
            // Count the numberTokens so that new tokens bear the correct number on them
            this.initializeNumberTokens();

            // Restore the filters' values to the sliders. axFilters is a property we add when saving the image.
            for (const filterName in savedFilters) {
                if (!savedFilters.hasOwnProperty(filterName)) continue;
                const filter = savedFilters[filterName];
                // Only overwrite the value, not the label or the onchange function
                this.editor.filterManager.filters[filter.id].value = filter.value;
            }
            // Apply filters and render canvas
            this.editor.filterManager.apply();

            await this.blurPatternManager.enlivenObjectsBlurPattern();
            this.blurPatternManager.rescaleBlurPatternPhotos();
            this.canvas.renderAll();
        } else {
            /**
             * In case the new picture does not have any fabricJsInformation associated with it, still refresh the current number of numberAndTextTokens. This is
             * important for switching between two versions of a photo, since the canvas is not cleared completely when switching between the versions.
             */
            this.initializeNumberTokens();

            this.resize();
            this.canvas.requestRenderAll();
        }
        this.canvas.renderOnAddRemove = true;
        this.imageLoaded = true;

        /**
         * Event listeners must not be active before the photo is completely initialized. PhotoEditorComponent.showPhoto() removes the listeners
         * before switching between photos, so this call is triggered only when opening the initial photo or when switching between photos.
         */
        this.addSaveOnObjectChangeListener();

        // Disabled this feature because it was hard for users to cope with.
        // // Bring an object to the front when it is moved or rescaled by the user.
        // this.canvas.on('object:modified', (options) => {
        //     options.target.bringToFront();
        // });

        // Allow user to undo/redo actions
        this.setupHistory();
    }

    clear(): void {
        /**
         * Don't save the photo back to the server because shapes are cleared. The photo should only be saved back to the server when the user changes shapes.
         * The event listeners will be added back after the canvas is populated with an image.
         */
        this.removeSaveOnObjectChangeListener();

        // If the photo has not (yet) loaded, the canvas is not yet initialized. No clearing required, then.
        this.canvas?.clear();
    }

    /**
     * Count the numberedTokens we already have and save them to an array the length of which will be the foundation for further
     * numbered tokens.
     */
    private initializeNumberTokens() {
        this.numberTokens = [];
        this.objectManager.forEach.numberToken((numberToken) => {
            this.objectManager.initializeNumberToken(numberToken);
        });
    }

    resize(): void {
        /**
         * Since resize() may be called in response to resizing the window, the fabric photo may not yet be initialized,
         * usually due to a slow internet connection.
         * Since resize() will be called again when the photo is loaded (see this.init()), we can safely return here.
         */
        if (!this.fabricPhoto) {
            console.log(
                'Fabric photo was not loaded yet, so skip resizing it. As soon as the fabric photo is loaded, this function will be called again.',
            );
            return;
        }

        const fabricPhotoBeforeRescale: FabricImageScaleOptions = {
            left: this.fabricPhoto.left,
            top: this.fabricPhoto.top,
            scaleX: this.fabricPhoto.scaleX,
            scaleY: this.fabricPhoto.scaleY,
            width: this.fabricPhoto.width,
            height: this.fabricPhoto.height,
        };

        // The photo can at maximum be as large as the canvas container
        const canvasDimensionsMax = {
            width: this.imageContainerHTMLElement.nativeElement.offsetWidth,
            height: this.imageContainerHTMLElement.nativeElement.offsetHeight,
        };

        // TODO Check if the long block can be replaced by some sort of boundingRect detection.
        /**
         * If the photo is either not rotated or rotated by 0, 180, 360, ... degrees, compare the photo's height
         * with the canvas' height
         */
        let photoX: number = this.fabricPhotoOriginalDimensions.width;
        let photoY: number = this.fabricPhotoOriginalDimensions.height;
        // If the photo is rotated so that its height axis lies on the canvas' width axis, compare photo height with canvas width
        if (this.photoLiesSideways()) {
            //noinspection JSSuspiciousNameCombination
            photoX = this.fabricPhotoOriginalDimensions.height;
            //noinspection JSSuspiciousNameCombination
            photoY = this.fabricPhotoOriginalDimensions.width;
        }

        let scaleFactor: number = 1;
        // If the image is too large, scale it down to fit
        if (photoY > canvasDimensionsMax.height || photoX > canvasDimensionsMax.width) {
            scaleFactor = canvasDimensionsMax.height / photoY;
            // Check if the width would still be too large if we scale the image down to the canvas height.
            if (photoX * scaleFactor > canvasDimensionsMax.width) {
                // Scale the image to the canvas width because the scale factor (if scaling to height) was too small.
                // Since this makes the image smaller than scaling to height, the height fits as well.
                scaleFactor = canvasDimensionsMax.width / photoX;
            }
        }
        this.fabricPhoto.scale(scaleFactor);

        /**
         * Recalculate coordinates to ensure the bounding rect is returned correctly in the next step. Otherwise, the photo is cut off
         * when it was rotated 90 degrees before and then opened in the photo editor.
         */
        this.fabricPhoto.setCoords();
        // Set the canvas to the ideal photo size so that there are no offsets onto which a user may place shapes. If the user places shapes on those offsets, the shapes are removed
        // in the DOCX template engine which contradicts the What You See Is What You Get paradigm.
        const fabricPhotoBoundingRect = this.fabricPhoto.getBoundingRect();
        const canvasDimensions = {
            width: fabricPhotoBoundingRect.width,
            height: fabricPhotoBoundingRect.height,
        };
        this.canvas.setDimensions(canvasDimensions);
        // Set the canvas' position so that the black offsets are visible after all.
        (this.canvasHTMLElement.nativeElement.parentNode.parentNode as HTMLDivElement).style.transform = `translateY(${
            (canvasDimensionsMax.height - canvasDimensions.height) / 2
        }px) translateX(${(canvasDimensionsMax.width - canvasDimensions.width) / 2}px)`;

        this.canvas.centerObject(this.fabricPhoto);

        // Rescale all shapes
        const shapesAndWatermark = [];
        this.objectManager.forEach.shape((shape) => {
            shapesAndWatermark.push(shape);
        });
        const watermark = this.objectManager.findWatermark(this.canvas);
        if (watermark) {
            shapesAndWatermark.push(watermark);
        }

        this.objectManager.rescaleObjects(shapesAndWatermark, fabricPhotoBeforeRescale);
        shapesAndWatermark.forEach((shapeOrWatermark: FabricObject) => {
            shapeOrWatermark.setCoords();
        });
    }

    //*****************************************************************************
    //  History (undo / redo)
    //****************************************************************************/

    /**
     * Setup the objects needed for keeping a history of changes (allowing the user to undo/redo actions).
     * This means saving the initial state and listening for object:modified events on the canvas.
     */
    private setupHistory(): void {
        if (this.historyInitialized) {
            return;
        }
        // Save initial state
        this.saveHistory();

        // Register event listener for user's actions
        this.canvas.on('object:modified', this.saveHistoryOnObjectModified);
        this.historyInitialized = true;
    }

    /**
     * Undo a previous user action in the photo editor.
     */
    public undoAction(): void {
        if (this.undoStates.length > 0) {
            this.replayHistoryState(this.undoStates, this.redoStates);
        }
    }

    /**
     * Redo an action that was previously undone.
     */
    public redoAction(): void {
        if (this.redoStates.length > 0) {
            this.replayHistoryState(this.redoStates, this.undoStates);
        }
    }

    /**
     * Completely reset the history (undo/redo) to the initial state. This is used for example
     * when staying inside the editor but switching to the next photo (history must be reset).
     */
    public resetHistory(): void {
        this.undoStates = [];
        this.redoStates = [];
        this.currentHistoryState = null;
        this.historyInitialized = false;
        this.historyDisabled = false;
        this.canvas?.off('object:modified', this.saveHistoryOnObjectModified);
    }

    /**
     * Push the current state into the undo stack and then capture the current state.
     * This function gets called every time an object gets modified on the canvas.
     * If you are modifying properties that do not trigger the object:modified event,
     * call this function manually. If you are performing operations that trigger
     * object:modified too often (and you don't want each modification to be in the history)
     * you can disable the history before your modification, enable the history again afterwords
     * and then call saveHistory yourself.
     * @param force Save the history even if the property historyDisabled is currently true.
     */
    public saveHistory(force: boolean = false): void {
        if (this.historyDisabled && !force) {
            // While history is disabled, don't save the state
            return;
        }

        const newState = this.editor.serializeCanvas();
        const noChanges = JSON.stringify(this.currentHistoryState) === JSON.stringify(newState);
        if (noChanges) {
            // Make sure, that we do not create a history entry if there were no changes
            return;
        }

        // Clear the redo stack
        this.redoStates = [];

        if (this.currentHistoryState) {
            // Don't add the initial state to the redo list
            this.undoStates.push(this.currentHistoryState);

            if (this.undoStates.length > this.MAX_NUMBER_HISTORY_ENTRIES) {
                // Make sure we do not store too many operations. Also helpful if there would be
                // a bug that floods the undoStates -> prevent memory issues
                this.undoStates.shift();
            }
        }

        // Update the current state
        this.currentHistoryState = newState;
    }

    /**
     * Save the current state in the redo stack and reset to a state in the undo stack
     * Or, do the opposite (redo vs. undo)
     * @param playStack which stack to get the last state from and to then render the canvas as
     * @param saveStack which stack to push current state into
     */
    private async replayHistoryState(playStack: any, saveStack: any): Promise<void> {
        saveStack.push(this.currentHistoryState);
        this.currentHistoryState = playStack.pop();

        // We are going to restore the whole fabric.js canvas, which flickers a little (short black frame).
        // To prevent this, we put a copy of the native HTML canvas on top until the new image got rendered.
        const imageCanvas = document.getElementById('image-canvas') as HTMLCanvasElement;
        const canvasContainer = imageCanvas.parentElement;
        const clonedCanvas = this.cloneCanvas(imageCanvas);
        canvasContainer.appendChild(clonedCanvas);

        // Clear the current canvas, so we can initialize it again with the state we are restoring now
        this.clear();

        // Do the whole initialization process, because there are just too many things that we are doing aside from the plain canvas objects stored in the state
        await this.init({ photoBlobUrl: this.blobUrl, fabricJsInfo: this.currentHistoryState });

        // Finally, remove the cloned canvas again.
        clonedCanvas.remove();

        // Save the photo in case the user leaves the editor right after pressing undo/redo
        this.editor.savePhoto();
    }

    /**
     * Clones the given canvas HTML element.
     * To clone a <canvas> element in JavaScript, we can't just use .cloneNode(true) because it only duplicates the element
     * without copying the drawn content. Instead, we need to manually copy both the element and its drawing context.
     */
    cloneCanvas(originalCanvas: HTMLCanvasElement): HTMLCanvasElement {
        const clonedCanvas = document.createElement('canvas');
        const ctx = clonedCanvas.getContext('2d');

        // Match dimensions
        clonedCanvas.width = originalCanvas.width;
        clonedCanvas.height = originalCanvas.height;

        // Copy content
        ctx.drawImage(originalCanvas, 0, 0);

        return clonedCanvas;
    }

    /**
     * Temporarily disable the history. The saveHistory function is called after each object:modified event.
     * If not all of these events should create a new entry in the history -> use this function to disable it.
     * Make sure to enable it afterwords and probably call saveHistory once after the job is done.
     */
    public disableHistory(): void {
        this.historyDisabled = true;
    }

    /**
     * Enable the history again.
     */
    public enableHistory(): void {
        this.historyDisabled = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END History (undo / redo)
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Well, duh.
     * @param angle
     */
    async rotateImage(angle = 90): Promise<void> {
        // Disable history, because rotating the image and all shapes triggers MANY object:modified events, which
        // in turn create a history entry and we don't want the user to step through each of them when undoing the rotation
        this.disableHistory();

        const canvasObjects = this.canvas.getObjects();

        // Let's create a group and rotate that one, instead of rotating each object on the canvas
        const group = new Group(canvasObjects);

        // We remove all objects from the canvas and put them into the group. At the end we remove the group and add
        // the objects back to the canvas. Previously we did not clear the canvas, but with v6 of fabric.js that led
        // to a bug where control handles were in a wrong position and could not be used anymore
        this.canvas.clear();
        this.canvas.renderAll();

        this.canvas.add(group);
        group.rotate(angle);
        this.canvas.centerObject(group);

        if (this.fabricPhoto.cropX || this.fabricPhoto.cropY) {
            // When rotating the image, we also need to update the stored position of the previous
            // crop area (if present)
            const previousCropAreaX = this.fabricPhoto.data.axCropAreaX;
            const previousCropAreaY = this.fabricPhoto.data.axCropAreaY;
            const previousCropAreaWidth = this.fabricPhoto.data.axCropAreaWidth;
            const previousCropAreaHeight = this.fabricPhoto.data.axCropAreaHeight;

            const currentAngle = this.fabricPhoto.angle;
            const angleAfterRotation = currentAngle + angle;
            const imageWidthAfterRotation =
                angleAfterRotation === 0 || angleAfterRotation === 180
                    ? this.fabricPhoto.getOriginalSize().width
                    : this.fabricPhoto.getOriginalSize().height;

            this.fabricPhoto.data.axCropAreaX = imageWidthAfterRotation - previousCropAreaHeight - previousCropAreaY;
            this.fabricPhoto.data.axCropAreaY = previousCropAreaX;
            this.fabricPhoto.data.axCropAreaWidth = previousCropAreaHeight;
            this.fabricPhoto.data.axCropAreaHeight = previousCropAreaWidth;
        }

        // After rotating the group, we remove the objects from the group and add them
        // back to the canvas. The removeAll function also assures, that the object.group property is reset
        // and the groups transformations get applied to each individual object.
        const removedObjects = group.removeAll();
        this.canvas.add(...removedObjects);
        this.canvas.remove(group);

        this.resize();

        // If there was a watermark, redraw it in the right position.
        if (this.objectManager.findWatermark(this.canvas)) {
            await this.objectManager.drawWatermark();
        } else {
            this.canvas.requestRenderAll();
        }

        // Enable history again and save the photo after rotation
        this.enableHistory();
        this.saveHistory();
    }

    /**
     * Removes any currently active crop area and resets zoom factor.
     */
    public resetCropAndZoomTools(): void {
        // Stop crop mode, if currently active
        const toolbarManager = this.editor.toolbarManager;
        if (toolbarManager.activeTool === toolbarManager.tools.crop) {
            toolbarManager.activeTool.deactivate();
        }

        // Reset zoom, if active
        if (this.isZoomActive) {
            this.stopZoomMode();
        }
    }

    /**
     * Depending on the current state, enable or disable the zoom mode.
     */
    toggleZoomMode(): void {
        if (this.isZoomActive) {
            this.stopZoomMode();
        } else {
            this.startZoomMode();
        }
    }

    /**
     * Let the user zoom into the image (this zoom is not persisted).
     */
    startZoomMode({ setInitialZoomOnFirstMouseMove = true }: { setInitialZoomOnFirstMouseMove?: boolean } = {}): void {
        this.isZoomActive = true;
        this.canvas.on('mouse:move', this.zoomToolMouseMoveOnCanvasHandler);

        /**
         * The initial zoom may be set explicitly before the first mouse move if the user starts the zoom mode through a double click.
         */
        if (setInitialZoomOnFirstMouseMove) {
            // Set a default zoom factor of 2 when the user enters the photo with the mouse (or moves it)
            this.canvas.once('mouse:move', this.zoomToolMouseMoveSetInitialZoomCanvasHandler);
        }

        // Let users scroll using the mouse wheel above the canvas
        this.canvas.on('mouse:wheel', this.zoomToolMouseWheelOnCanvasHandler);
    }
    private handleDoubleclickForZoom(fabricPointerEvent: TPointerEventInfo) {
        // Number tokens have their own double click action: open their text box.
        if (fabricPointerEvent.target.data?.axType === 'numberToken') return;

        if (!this.isZoomActive) {
            this.startZoomMode({
                /**
                 * The initial zoom is set explicitly in this function because we do not have to wait for the first mouse move
                 * since we already handle a mouse event from which we can read coordinates.
                 */
                setInitialZoomOnFirstMouseMove: false,
            });

            this.zoomToolMouseMoveOnCanvasHandler(fabricPointerEvent);
            this.zoomToolMouseMoveSetInitialZoomCanvasHandler(fabricPointerEvent);
        } else {
            this.stopZoomMode();
        }
    }
    public zoomModeDoubleclickEventHandler = this.handleDoubleclickForZoom.bind(this);

    /**
     * While zoom tool is active:
     * Update the zoom area to always be centered around the current mouse position of the user.
     */
    private zoomToolMouseMoveOnCanvasHandler = (event: TPointerEventInfo) => {
        /**
         * In case this event handler is executed after the zoom mode was deactivated (usually due
         * to event congestion of scroll events), do not execute it because it may set the zoom mode
         * although the user already exited the zoom mode.
         */
        if (!this.isZoomActive) return;

        // Get current zoom factor
        const currentZoomFactor = this.canvas.getZoom();

        // When the user moves the mouse, we update the viewport so that the zoom area follows the cursor
        const viewportTransform = this.canvas.viewportTransform;
        viewportTransform[4] = -((currentZoomFactor - 1) * event.viewportPoint.x);
        viewportTransform[5] = -((currentZoomFactor - 1) * event.viewportPoint.y);

        this.canvas.setViewportTransform(viewportTransform);
    };

    /**
     * Set a default zoom factor of 2 when the user enters the photo with the mouse.
     * This additional handler is necessary, because the mouse:over handler is not
     * triggered when the user activates the zoom tool using the keyboard shortcut
     * while the mouse is already above the canvas.
     */
    private zoomToolMouseMoveSetInitialZoomCanvasHandler = (fabricPointerEvent: TPointerEventInfo) => {
        /**
         * In case this event handler is executed after the zoom mode was deactivated (usually due
         * to event congestion of scroll events), do not execute it because it may set the zoom mode
         * although the user already exited the zoom mode.
         */
        if (!this.isZoomActive) return;

        this.canvas.zoomToPoint(fabricPointerEvent.viewportPoint, 2);
    };

    private zoomToolMouseWheelOnCanvasHandler = (event: TPointerEventInfo) => {
        /**
         * In case this event handler is executed after the zoom mode was deactivated (usually due
         * to event congestion of scroll events), do not execute it because it may set the zoom mode
         * although the user already exited the zoom mode.
         */
        if (!this.isZoomActive) return;

        const delta = (event.e as WheelEvent).deltaY;
        let zoom = this.canvas.getZoom();
        zoom *= 0.999 ** delta;

        // Don't allow zooming out more than factor 1, and zooming in more than factor 10
        if (zoom > 10) zoom = 10;
        if (zoom < 1) zoom = 1;

        // ZoomToPoint makes sure, the zoom also moves the image position while zooming
        this.canvas.zoomToPoint(event.viewportPoint, zoom);

        event.e.preventDefault();
        event.e.stopPropagation();
    };

    /**
     * Ends the zoom mode, which resets the zoom and transform on the canvas and stops listening for mouse events.
     */
    stopZoomMode(): void {
        this.isZoomActive = false;
        this.canvas.off('mouse:move', this.zoomToolMouseMoveOnCanvasHandler);
        this.canvas.off('mouse:wheel', this.zoomToolMouseWheelOnCanvasHandler);

        this.resetZoomAndTransform();
    }

    /**
     * Remove any previously applied zoom or transform from the canvas.
     */
    resetZoomAndTransform(): void {
        this.canvas.setZoom(1);
        this.canvas.viewportTransform[4] = 0;
        this.canvas.viewportTransform[5] = 0;
    }

    /**
     * Crop the image to the given rectangle.
     */
    async cropImage(cropArea: Rect): Promise<void> {
        const fabricPhoto = this.fabricPhoto;

        // Convert dimensions of crop area so that they are relative to the original image dimensions
        const cropAreaLeft = cropArea.left / this.fabricPhoto.scaleX,
            cropAreaTop = cropArea.top / this.fabricPhoto.scaleY,
            cropAreaWidth = (cropArea.width * cropArea.scaleX) / this.fabricPhoto.scaleX,
            cropAreaHeight = (cropArea.height * cropArea.scaleY) / this.fabricPhoto.scaleY;

        // Now be careful, e. g. if the image is rotated by 90 deg, the cropX is no longer cropping the left side but rather the top
        // So we would need to adjust the values depending on the rotation of the image.
        // Also keep in mind, that Image.left and Image.top get adjusted because the image origin changes with rotation.

        const cropValues = this.getCropValuesForRotatedImage(
            {
                cropAreaLeft,
                cropAreaTop,
                cropAreaWidth,
                cropAreaHeight,
                imageHeight: fabricPhoto.height,
                imageWidth: fabricPhoto.width,
            },
            fabricPhoto.angle,
        );

        fabricPhoto.set({
            cropX: cropValues.cropX,
            cropY: cropValues.cropY,
            width: cropValues.width,
            height: cropValues.height,

            /**
             * This photo will be repositioned when the image is rescaled in the resize() function. But this is necessary
             * so the shapes can be repositioned correctly since their position is rescaled according to the image
             * top and left positions.
             */
            left: cropValues.left * this.fabricPhoto.scaleX,
            top: cropValues.top * this.fabricPhoto.scaleY,
        });
        // Store the dimensions of the crop area, so we can later restore it (when user selects the crop tool again)
        fabricPhoto.data.axCropAreaX = cropArea.left / fabricPhoto.scaleX;
        fabricPhoto.data.axCropAreaY = cropArea.top / fabricPhoto.scaleY;
        fabricPhoto.data.axCropAreaWidth = (cropArea.width * cropArea.scaleX) / fabricPhoto.scaleX;
        fabricPhoto.data.axCropAreaHeight = (cropArea.height * cropArea.scaleY) / fabricPhoto.scaleY;

        // Update the original size of the image, because that is needed for resizing the image correctly later
        this.fabricPhotoOriginalDimensions.width = this.photoLiesSideways() ? cropAreaHeight : cropAreaWidth;
        this.fabricPhotoOriginalDimensions.height = this.photoLiesSideways() ? cropAreaWidth : cropAreaHeight;

        // Adjust the position of objects on the canvas so that they respect the new crop offset
        this.objectManager.forEach.shape((shape: FabricObject) => {
            shape.left = shape.left - cropArea.left;
            shape.top = shape.top - cropArea.top;
        });

        // Finally resize the image so that it fits in the canvas/viewport again
        this.resize();

        // If there was a watermark, redraw it in the right position.
        if (this.objectManager.findWatermark(this.canvas)) {
            await this.objectManager.drawWatermark();
        } else {
            this.canvas.requestRenderAll();
        }

        // Save the final cropped image to the history, so the user can undo the crop
        this.saveHistory(true);
    }

    getCropValuesForRotatedImage(
        cropAreaValues: CropAreaValues,
        fabricPhotoAngle: number,
    ): {
        cropY: number;
        cropX: number;
        width: number;
        height: number;
        top: number;
        left: number;
    } {
        /**
         * Since the fabric photo has an originX of "left" and originY of "top", the positioning changes when an angle is applied
         * to the fabric photo. If the photo is rotated 90 degrees, the origin is still top-left in the data model but on the
         * canvas, it's top right. Calculate top and left accordingly.
         */
        if (fabricPhotoAngle === 0) {
            return {
                cropX: cropAreaValues.cropAreaLeft,
                cropY: cropAreaValues.cropAreaTop,
                width: cropAreaValues.cropAreaWidth,
                height: cropAreaValues.cropAreaHeight,
                top: 0,
                left: 0,
            };
        } else if (fabricPhotoAngle === 90) {
            return {
                cropX: cropAreaValues.cropAreaTop,
                cropY: cropAreaValues.imageHeight - (cropAreaValues.cropAreaLeft + cropAreaValues.cropAreaWidth),
                width: cropAreaValues.cropAreaHeight,
                height: cropAreaValues.cropAreaWidth,
                top: 0,
                left: cropAreaValues.cropAreaWidth,
            };
        } else if (fabricPhotoAngle === 180) {
            return {
                cropX: cropAreaValues.imageWidth - cropAreaValues.cropAreaLeft - cropAreaValues.cropAreaWidth,
                cropY: cropAreaValues.imageHeight - cropAreaValues.cropAreaTop - cropAreaValues.cropAreaHeight,
                width: cropAreaValues.cropAreaWidth,
                height: cropAreaValues.cropAreaHeight,
                top: cropAreaValues.cropAreaHeight,
                left: cropAreaValues.cropAreaWidth,
            };
        } else if (fabricPhotoAngle === -90) {
            return {
                cropX: cropAreaValues.imageWidth - cropAreaValues.cropAreaTop - cropAreaValues.cropAreaHeight,
                cropY: cropAreaValues.cropAreaLeft,
                width: cropAreaValues.cropAreaHeight,
                height: cropAreaValues.cropAreaWidth,
                top: cropAreaValues.cropAreaHeight,
                left: 0,
            };
        }
    }

    /**
     * When the image was cropped before and the user opens the crop tool, we need to restore the original
     * image (before cropping). This function resets cropX, cropY, height and width values of the image
     * and resizes objects and the image to fit the viewport/canvas again.
     */
    async restoreUncroppedImage(): Promise<void> {
        const fabricPhotoBeforeRescale: FabricImageScaleOptions = {
            left: this.fabricPhoto.left,
            top: this.fabricPhoto.top,
            scaleX: this.fabricPhoto.scaleX,
            scaleY: this.fabricPhoto.scaleY,
            width: this.fabricPhoto.width,
            height: this.fabricPhoto.height,
        };

        let fabricPhotoTop: number = 0;
        let fabricPhotoLeft: number = 0;
        switch (this.fabricPhoto.angle) {
            case 90:
                fabricPhotoTop = 0;
                fabricPhotoLeft = this.fabricPhoto.getOriginalSize().height * this.fabricPhoto.scaleY;
                break;
            case 180:
                fabricPhotoTop = this.fabricPhoto.getOriginalSize().height * this.fabricPhoto.scaleY;
                fabricPhotoLeft = this.fabricPhoto.getOriginalSize().width * this.fabricPhoto.scaleX;
                break;
            case -90:
                fabricPhotoTop = this.fabricPhoto.getOriginalSize().width * this.fabricPhoto.scaleX;
                fabricPhotoLeft = 0;
                break;
        }

        // Image has already been cropped before -> show the whole image before displaying the crop tools again
        this.fabricPhoto.set({
            cropX: 0,
            cropY: 0,

            /**
             * The scale factor remains as before, so no need to change it. The image is rendered in a size suitable
             * for the current screen size.
             */
            width: this.fabricPhoto.getOriginalSize().width,
            height: this.fabricPhoto.getOriginalSize().height,

            /**
             * This photo will be repositioned when the image is rescaled in the resize() function. But this is necessary
             * so the shapes can be repositioned correctly since their position is rescaled according to the image
             * top and left positions.
             */
            left: fabricPhotoLeft,
            top: fabricPhotoTop,
        });

        const cropX = this.fabricPhoto.data.axCropAreaX;
        const cropY = this.fabricPhoto.data.axCropAreaY;

        // Restore original position of shapes
        this.objectManager.forEach.shape((shape: FabricObject) => {
            const cropLeft = cropX * fabricPhotoBeforeRescale.scaleX;
            const cropTop = cropY * fabricPhotoBeforeRescale.scaleY;

            shape.left = shape.left + cropLeft;
            shape.top = shape.top + cropTop;
        });

        // Restore original image size
        this.fabricPhotoOriginalDimensions = {
            height: this.fabricPhoto.getOriginalSize().height,
            width: this.fabricPhoto.getOriginalSize().width,
        };

        this.resize();

        // Remove the crop information because we restored the original image
        delete this.fabricPhoto.data.axCropAreaX;
        delete this.fabricPhoto.data.axCropAreaY;
        delete this.fabricPhoto.data.axCropAreaWidth;
        delete this.fabricPhoto.data.axCropAreaHeight;

        // If there was a watermark, redraw it in the right position.
        if (this.objectManager.findWatermark(this.canvas)) {
            await this.objectManager.drawWatermark();
        } else {
            this.canvas.requestRenderAll();
        }

        // After uncropping, save the uncropped image to the history, so the user can undo this step
        this.saveHistory(true);
    }

    /**
     * Remove shapes and reset filters.
     */
    reset(): void {
        // Remove all objects
        // If we would delete objects in the "forEach.object" callback, we would change the underlying object-array
        // while iterating. The last objects would then not be found.
        const deleteObjects = [];
        this.objectManager.forEach.object((object) => {
            deleteObjects.push(object);
        });
        this.objectManager.deleteCanvasElements(deleteObjects);

        // Reset rotation of all remaining objects - this is probably only the photo
        this.canvas.getObjects().forEach((object) => {
            object.rotate(0);
        });
        this.resize();

        // Reset filters
        this.editor.filterManager.reset();

        this.editor.filterManager.apply();
    }

    /**
     * Returns true of the photo has been rotated to 90, 270, 450, ... degrees and false otherwise.
     * When the photo lies sidewards, its height must be compared to the canvas' width and its width must be compared
     * to the canvas' height when fitting the photo into the canvas.
     * @return {boolean}
     */
    private photoLiesSideways() {
        // If the photo is shifted by 90, 270, 450, ... degrees, it is actually shifted to lie sidewards. We then have
        // to compare its height with the canvas' width, its width with the canvas' height to make it fit into the canvas.
        return !!((Math.round(this.fabricPhoto.angle) / 90) % 2);
    }

    private _createCanvas() {
        // If the canvas has already been initialized, skip the rest.
        if (this.canvas) {
            return;
        }

        // Initialize canvas
        this.canvas = new Canvas(this.canvasHTMLElement.nativeElement.id, {
            // Do not move objects to front when selected. Before this setting was enacted, clicking elements would shuffle the layer positioning.
            preserveObjectStacking: true,
        });

        this.canvas.on('mouse:dblclick', this.zoomModeDoubleclickEventHandler);

        if (this.editor.isReportLocked()) {
            /**
             * Prevent drawing a selection rectangle if the report is locked. Shapes are not selectable anyway, so this
             * is intuitive behavior.
             */
            this.canvas.selection = false;
        }
    }

    /**
     * These need to be arrow functions so that "this" still means this CanvasManager, not the modified fabric.js object. Fabric.js set
     * "this" to the modified object in 'object:modified' and 'object:removed' listeners.
     */
    private saveOnObjectModified = () => {
        this.editor.savePhoto();
    };

    private saveOnObjectRemoved = () => {
        this.editor.savePhoto();
    };

    private saveHistoryOnObjectModified = () => {
        this.saveHistory();
    };

    /**
     * Event listeners are added both at canvas initialization and after the user switched to a new photo.
     */
    public addSaveOnObjectChangeListener() {
        this.canvas.on('object:modified', this.saveOnObjectModified);
        this.canvas.on('object:removed', this.saveOnObjectRemoved);
    }

    /**
     * Event listeners are removed before the user switches to a new photo. That ensures that the photo is not saved back to
     * the server when individual canvas objects are removed. The canvas shapes/objects from the previous photo need be removed so
     * the new shapes can be added.
     *
     * Before this, the client would save an empty photo.versions['report'].fabricJsInformation.objects array back to the server after
     * switching photos.
     */
    public removeSaveOnObjectChangeListener() {
        // If the photo has not (yet) loaded, the canvas is not yet initialized. No clearing required, then.
        this.canvas?.off('object:modified', this.saveOnObjectModified);
        this.canvas?.off('object:removed', this.saveOnObjectRemoved);
    }
}

interface CropAreaValues {
    cropAreaLeft: number;
    cropAreaTop: number;
    imageWidth: number;
    imageHeight: number;
    cropAreaWidth: number;
    cropAreaHeight: number;
}

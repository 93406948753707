import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard {
    canDeactivate(component: CanComponentDeactivate) {
        // Always navigate away if the component does not have a method to check if the user can navigate away.
        // If the component has a method "canDeactivate", check only navigating away if the return value is truthy.
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}

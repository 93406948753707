<!--============================================-->
<!-- Heading -->
<!--============================================-->
<div class="heading-container">
    <h2 class="no-margin">Labels</h2>
    <div class="label" [ngSwitch]="labelGroup">
        <ng-container *ngSwitchCase="'invoice'">
            Du kannst deine Rechnungen mit folgenden Labels versehen und danach filtern.
        </ng-container>
        <ng-container *ngSwitchCase="'report'">
            Du kannst deine Gutachten mit folgenden Labels versehen und danach filtern.
        </ng-container>
        <ng-container *ngSwitchCase="'task'">
            Du kannst deine Aufgaben mit folgenden Labels versehen und danach filtern.
        </ng-container>
        <span *ngSwitchDefault class="error">Keine Gruppe definiert</span>
    </div>
</div>
<!--============================================-->
<!-- END Heading -->
<!--============================================-->

<!--********** Close Icon **********-->
<mat-icon class="dialog-close-icon" (click)="closeOverlay()">close</mat-icon>

<!--============================================-->
<!-- Label List -->
<!--============================================-->
<section id="label-list" cdkDropList cdkDropListLockAxis="y" (cdkDropListDropped)="handleReorderingDrop($event)">
    <div class="label-list-item" cdkDrag *ngFor="let labelConfig of labelConfigs">
        <!--********** Drag Handle **********-->
        <mat-icon
            class="drag-handle-icon"
            cdkDragHandle
            matTooltip="Bestimme per Drag & Drop die Reihenfolge im Auswahl-Dropdown.">
            drag_indicator
        </mat-icon>

        <!--********** Color **********-->
        <!--suppress CssUnknownProperty -->
        <div
            class="label-color"
            [style.--labelColor]="labelConfig.color"
            colorPicker
            [(selectedColor)]="labelConfig.color"
            (selectedColorChange)="saveLabelConfig(labelConfig)"></div>

        <!--********** Label Name **********-->
        <div class="label-name" [matTooltip]="labelConfig.name">
            <span
                class="cursor-pointer"
                *ngIf="labelConfigInEditMode !== labelConfig"
                (click)="enterEditMode(labelConfig)">
                {{ labelConfig.name }}
            </span>
            <ng-container *ngIf="labelConfigInEditMode === labelConfig">
                <mat-form-field class="mat-block label-name_form-field">
                    <input
                        matInput
                        placeholder="Titel des Labels"
                        [(ngModel)]="labelConfig.name"
                        [axAutofocus]="true"
                        (change)="saveLabelConfig(labelConfig)"
                        (keydown)="handleKeydownInInput($event)"
                        (blur)="leaveEditMode()" />

                    <!--********** Input Toolbar **********-->
                    <div matSuffix>
                        <mat-icon class="input-toolbar-icon" (click)="leaveEditMode()" matTooltip="Bearbeitung beenden">
                            check
                        </mat-icon>
                    </div>
                </mat-form-field>
            </ng-container>
        </div>

        <!--********** Record Toolbar **********-->
        <div class="record-toolbar" *ngIf="labelConfigInEditMode !== labelConfig">
            <mat-icon class="toolbar-icon" (click)="enterEditMode(labelConfig)" matTooltip="Bearbeiten">edit</mat-icon>
            <mat-icon class="toolbar-icon" (click)="deleteLabelConfig(labelConfig)" matTooltip="Löschen">
                delete
            </mat-icon>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Label List -->
<!--============================================-->

<div class="flex-center-content margin-top-10">
    <button class="flat" (click)="addLabelConfig()">+ Neues Label</button>
</div>

<div class="dialog-buttons-container">
    <button (click)="closeOverlay()">Schließen</button>
</div>

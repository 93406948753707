import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { base64toBlob } from '@autoixpert/lib/base64-to-blob';
import { getDatVehicleValueByPriceType } from '@autoixpert/lib/car-valuation/get-dat-vehicle-value-by-price-type';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { setReportDocumentInclusionStatusByType } from '@autoixpert/lib/documents/set-report-document-inclusion-status-by-type';
import { stripHtml } from '@autoixpert/lib/placeholder-values/strip-html';
import { Translator, VehicleValueLabelGerman } from '@autoixpert/lib/placeholder-values/translator';
import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { isAutoonlineUserComplete } from '@autoixpert/lib/users/is-autoonline-user-complete';
import { isCarTvUserComplete } from '@autoixpert/lib/users/is-cartv-user-complete';
import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { areWinvalueMarketAnalysisCredentialsComplete } from '@autoixpert/lib/users/is-winvalue-user-complete';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { CustomMarketAnalysis } from '@autoixpert/models/reports/market-value/custom-market-analysis';
import { DatValuation } from '@autoixpert/models/reports/market-value/dat-valuation';
import { MarketAnalysis } from '@autoixpert/models/reports/market-value/market-analysis';
import {
    MarketAnalysisProvider,
    Valuation,
    ValuationVehicleValueType,
} from '@autoixpert/models/reports/market-value/valuation';
import { ValuepilotMarketAnalysis } from '@autoixpert/models/reports/market-value/valuepilot-market-analysis';
import { Report } from '@autoixpert/models/reports/report';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { runChildAnimations } from 'src/app/shared/animations/run-child-animations.animation';
import { getAudatexValuationExportTooltip } from 'src/app/shared/libraries/audatex-helpers/get-audatex-valuation-export-tooltip';
import { getSelectedAudatexValuationResult } from 'src/app/shared/libraries/audatex-helpers/get-selected-audatex-valuation-result';
import { isAudatexValuationInputDataComplete } from 'src/app/shared/libraries/audatex-helpers/is-audatex-valuation-input-data-complete';
import { isAudatexValuationPossible } from 'src/app/shared/libraries/audatex-helpers/is-audatex-valuation-possible';
import { determineDamageType } from 'src/app/shared/libraries/damage-calculation/determine-damage-type';
import { getDatValuationExportTooltip } from 'src/app/shared/libraries/dat-helpers/get-dat-valuation-export-tooltip';
import { getDatValuationOpenerTooltip } from 'src/app/shared/libraries/dat-helpers/get-dat-valuation-opener-tooltip';
import { getValuationPriceLabelTooltip } from 'src/app/shared/libraries/dat-helpers/get-valuation-price-label-tooltip';
import { isDatValuationInputDataComplete } from 'src/app/shared/libraries/dat-helpers/is-dat-valuation-input-data-complete';
import { isDatValuationPossible } from 'src/app/shared/libraries/dat-helpers/is-dat-valuation-possible';
import { getResidualValueAndMarketAnalysisErrorHandlers } from 'src/app/shared/libraries/error-handlers/get-residual-value-and-market-analysis-error-handlers';
import { determineTaxationTypes } from 'src/app/shared/libraries/report/determine-taxation-type';
import { setVehicleValue } from 'src/app/shared/libraries/report/set-vehicle-value';
import { ApiErrorService } from 'src/app/shared/services/api-error.service';
import { AudatexTaskService } from 'src/app/shared/services/audatex/audatex-task.service';
import { DatValuationService } from 'src/app/shared/services/dat-valuation.service';
import { DownloadService } from 'src/app/shared/services/download.service';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { NetworkStatusService } from 'src/app/shared/services/network-status.service';
import { NewWindowService } from 'src/app/shared/services/new-window.service';
import { ReportDetailsService } from 'src/app/shared/services/report-details.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';
import { UserService } from '../../../../shared/services/user.service';
import {
    CustomMarketAnalysisDialogComponent,
    CustomMarketAnalysisDialogData,
} from '../custom-market-analysis-dialog/custom-market-analysis-dialog.component';
import {
    ValuepilotEquipmentDialogComponent,
    ValuepilotEquipmentDialogData,
} from '../valuepilot-equipment-dialog/valuepilot-equipment-dialog.component';
import {
    MarketAnalysisConnectionDialogComponent,
    MarketAnalysisConnectionDialogData,
} from '../winvalue-market-analysis-connection-dialog/market-analysis-connection-dialog.component';

@Component({
    selector: 'market-value-overview',
    templateUrl: './market-value-overview.component.html',
    styleUrls: ['./market-value-overview.component.scss'],
    animations: [runChildAnimations()],
})
export class MarketValueOverviewComponent implements OnInit, OnDestroy {
    constructor(
        private reportDetailsService: ReportDetailsService,
        private router: Router,
        public changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private domSanitizer: DomSanitizer,
        private loggedInUserService: LoggedInUserService,
        private newWindowService: NewWindowService,
        private httpClient: HttpClient,
        private downloadService: DownloadService,
        public userPreferences: UserPreferencesService,
        private apiErrorService: ApiErrorService,
        // NgZone must stay here although the IDE may mark it as unused, because it's required on the component passed to openValuationUI().
        public ngZone: NgZone,
        private dialog: MatDialog,
        private networkStatusService: NetworkStatusService,
        private datValuationService: DatValuationService,
        private audatexTaskService: AudatexTaskService,
        private userService: UserService,
    ) {}

    private subscriptions: Subscription[] = [];
    @Input() report: Report;
    @Input() user: User;
    @ViewChild('pdfDownloadAnchor', { static: false }) pdfDownloadAnchor: ElementRef;
    @Output() vehicleValueGrossSelected = new EventEmitter<{
        vehicleValueGross: number;
        provider: MarketAnalysisProvider;
    }>();
    public pdfDownloadUrl: SafeUrl;
    public showCartvDowntimeCompensationNotification: boolean;
    public datValuationSettingsDialogShown = false;
    public datValuationRequestPending = false;
    public audatexValuationRequestPending = false;

    /**
     * This will be set to true if the user created a valuation in Audatex Qapter without tax. In that case,
     * autoiXpert will calculate the gross value according to the selected tax in autoiXpert.
     */
    public audatexGrossValueWasCalculatedWithAutoixpertTaxRate = false;

    ngOnInit() {
        this.filterMarketAnalysisProviders();
        this.prepareMarketAnalysesForUi();

        this.initializeTaxationType();
        this.subscribeDatValuationPendingState();
        this.subscribeAudatexValuationPendingState();
    }

    /**
     * Initialize the taxation type depending on the car age.
     * Valuation reports: the taxation type is initialized depending on the car owners VAT status (in the valuation component)
     */
    private initializeTaxationType() {
        if (this.report.type !== 'valuation' && !this.report.valuation.taxationType) {
            this.report.valuation.taxationType = determineTaxationTypes(this.report);
            this.saveReport();
        }
    }

    //*****************************************************************************
    //  DAT Valuation
    //****************************************************************************/

    private subscribeDatValuationPendingState(): void {
        this.subscriptions.push(
            this.datValuationService.isRequestPending$$.subscribe(
                (pending) => (this.datValuationRequestPending = pending),
            ),
        );
    }
    protected isDatUserComplete = isDatUserComplete;
    protected isDatValuationInputDataComplete = isDatValuationInputDataComplete;
    protected isDatValuationPossible = isDatValuationPossible;
    protected getDatValuationExportTooltip = getDatValuationExportTooltip;
    protected getDatValuationOpenerTooltip = getDatValuationOpenerTooltip;
    protected getDatVehicleValueByPriceType = getDatVehicleValueByPriceType;
    protected getValuationPriceLabelTooltip = getValuationPriceLabelTooltip;

    /**
     * Taxation type "neutral" is only available for replacement value.
     */
    public setDATTaxationTypeForValueType(): void {
        if (
            this.report.valuation.datValuation.requestedTaxationType === 'neutral' ||
            this.report.valuation.datValuation.vehicleValueType !== 'replacementValue'
        ) {
            this.report.valuation.datValuation.requestedTaxationType = 'margin';
            this.toastService.info(
                'Besteuerung auf "Differenz" gesetzt',
                'Die DAT bietet die neutrale Besteuerung (Privatmarkt) nur für den Wert "Wiederbeschaffungswert".',
            );
        }
    }

    public translateVehicleValueType(valueType: ValuationVehicleValueType): VehicleValueLabelGerman {
        return Translator.vehicleValueLabel(valueType);
    }

    public neutralTaxationTypeAllowed(): boolean {
        return this.report.valuation.vehicleValueType === 'replacementValue' || !this.report.valuation.vehicleValueType;
    }

    private async createDatValuation() {
        try {
            await this.datValuationService.create({ report: this.report });
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                report: this.report,
                axError: error,
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body:
                        `Die Bewertung konnte nicht erstellt werden.` +
                        (error.data?.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                    partnerLogo: 'dat',
                }),
            });
        }
    }

    private async updateDatValuation() {
        try {
            await this.datValuationService.updateDossier({ report: this.report });
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body:
                        `Die Bewertung konnte nicht erstellt werden.` +
                        (error.data && error.data.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                    partnerLogo: 'dat',
                }),
            });
        }
    }

    async retrieveDatValuationResults() {
        try {
            await this.datValuationService.retrieveResults({ report: this.report });

            // Set the original price
            this.report.valuation.originalPriceWithoutEquipmentNet =
                this.report.valuation.datValuation.originalPriceWithoutEquipmentNet;
            this.report.valuation.originalPriceWithoutEquipmentGross =
                this.report.valuation.datValuation.originalPriceWithoutEquipmentGross;
            this.report.valuation.originalPriceWithEquipmentNet =
                this.report.valuation.datValuation.originalPriceWithEquipmentNet;
            this.report.valuation.originalPriceWithEquipmentGross =
                this.report.valuation.datValuation.originalPriceWithEquipmentGross;

            /**
             * Customers set a taxation type in autoixpert. The DAT dossier provide information about taxation types 'difference' and 'regular',
             * but not about 'neutral'. Therefore we expect the user to set the taxation type in autoixpert and not to change it in the DAT dossier.
             */
            const vehicleValueNet = getDatVehicleValueByPriceType({
                report: this.report,
                vehicleValueType: this.report.valuation.datValuation.vehicleValueType,
                netOrGross: this.report.valuation.datValuation.requestedTaxationType === 'neutral' ? 'gross' : 'net',
            });
            const vehicleValueGross = getDatVehicleValueByPriceType({
                report: this.report,
                vehicleValueType: this.report.valuation.datValuation.vehicleValueType,
                netOrGross: 'gross',
            });
            // Do not set the DAT valueType for damage reports.
            // The user may use a different value type in the DAT.
            // But the value is still referred as 'Wiederbeschaffungswert' in the placeholder etc.
            setVehicleValue({
                report: this.report,
                userPreferences: this.userPreferences,
                valueNet: vehicleValueNet,
                valueGross: vehicleValueGross,
                taxationType: this.report.valuation.datValuation.requestedTaxationType,
                valuationProvider: 'dat',
            });

            this.saveReport();
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: (error) => ({
                    title: 'Import nicht möglich',
                    body:
                        `Die Bewertung konnten nicht importiert werden.` +
                        (error.data && error.data.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                }),
            });
        }
    }

    public async openDatValuation() {
        // Create a DAT valuation if it does not exist yet.
        if (!this.report.valuation.datValuation.dossierId) {
            await this.createDatValuation();
        }

        // Open the DAT valuation UI.
        this.datValuationService.openValuationUI({
            report: this.report,
            triggeringComponent: this,
            onFinish: () => this.retrieveDatValuationResults(),
        });
    }

    public async saveReportAndRetrieveValuationResults() {
        await this.updateDatValuation();
        await this.retrieveDatValuationResults();
    }

    /**
     * DAT accepts an approval code if the valuation would result in additional costs.
     * In this case we call the function recursively.
     * @param datApprovalCode
     */
    public async executeDatQuickValuation(): Promise<void> {
        await this.createDatValuation();
        await this.retrieveDatValuationResults();
    }

    /**
     * Update the requested taxation type and retrieve the valuation.
     */
    public setDatValuationTaxationType(taxationType: DatValuation['requestedTaxationType']) {
        this.report.valuation.datValuation.requestedTaxationType = taxationType;
        this.saveReportAndRetrieveValuationResults();
    }

    public setReplacementValueTaxationType(taxationType: Valuation['taxationType']): void {
        this.report.valuation.taxationType = taxationType;
    }

    public async resetDatValuation(): Promise<void> {
        this.datValuationService.reset({ report: this.report, options: { showConfirmDialog: true } });
    }

    public showDatValuationSettingsDialog(): void {
        this.datValuationService.openSettingsDialog({ report: this.report });
    }

    public transmitDatValuation() {
        this.datValuationService.updateDossier({ report: this.report });
    }

    public async openDatValuationConnectionDialog() {
        try {
            await this.datValuationService.openDossierConnectionDialog({ report: this.report });
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'DAT-Vorgang nicht verbunden',
                    body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        }
    }

    public unlinkDatValuation() {
        this.datValuationService.unlink({ report: this.report });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT Valuation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Audatex Valuation
    //****************************************************************************/
    protected isAudatexValuationPossible = isAudatexValuationPossible;
    protected isAudatexValuationInputDataComplete = isAudatexValuationInputDataComplete;
    protected isAudatexUserComplete = isAudatexUserComplete;
    public getAudatexValuationExportTooltip = getAudatexValuationExportTooltip;

    private subscribeAudatexValuationPendingState(): void {
        this.subscriptions.push(
            this.audatexTaskService.isRequestPending$$.subscribe(
                (pending) => (this.audatexValuationRequestPending = pending),
            ),
        );
    }

    public getSelectedAudatexValuationResult() {
        return getSelectedAudatexValuationResult({ report: this.report });
    }

    /**
     * Open Audatex valuation.
     * If a task has not been created yet, create it here.
     */
    public async openAudatexValuation() {
        if (
            !isAudatexUserComplete(this.user) ||
            !isAudatexValuationInputDataComplete(this.report) ||
            !isAudatexValuationPossible(this.report) ||
            (this.isReportLocked() && !this.report.audatexTaskId)
        ) {
            this.toastService.partnerError(
                'Audatex-Bewertung nicht möglich',
                getAudatexValuationExportTooltip({ report: this.report, user: this.user }),
                'audatex',
            );
        }

        if (!this.isReportLocked()) {
            /**
             * Create Audatex task if necessary. Then open valuation.
             */
            // Does a task exist?
            if (!this.report.audatexTaskId) {
                // No -> Create task, then open valuation
                try {
                    await this.audatexTaskService.create({ report: this.report });
                } catch (error) {
                    this.audatexValuationRequestPending = false;
                    this.audatexTaskService.handleAndRethrow({
                        axError: error,
                        report: this.report,
                        defaultHandler: {
                            title: 'Bewertungsdaten nicht übertragen',
                            body: 'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        },
                    });
                }
            }
            // Update task, then open valuation
            try {
                await this.audatexTaskService.update({ report: this.report });
            } catch (error) {
                this.audatexValuationRequestPending = false;
                this.audatexTaskService.handleAndRethrow({
                    axError: error,
                    report: this.report,
                    defaultHandler: {
                        title: 'Bewertungsdaten nicht übertragen',
                        body: 'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }
        }

        /**
         * If Schwacke code is already present, skip vehicleIdentification and start with vehicleValues right away.
         *
         * Audatex valuations require the Schwacke code since Audatex does not offer their own valuation. The Schwacke code cannot be derived via the SOAP service, so
         * the user has to select it via the Audatex interface manually.
         */
        const audatexUiStep: 'VehicleIdentification' | 'VehicleValues' = this.report.car.audatexIdentification
            .schwackeCode
            ? 'VehicleValues'
            : 'VehicleIdentification';
        await this.audatexTaskService.openUI({ report: this.report, step: audatexUiStep });
    }

    public async importAudatexValuation({
        automaticImport = false,
    }: {
        // Set to true if not the user triggered the import but a window focus event.
        automaticImport?: boolean;
    } = {}): Promise<void> {
        try {
            const { audatexGrossValueWasCalculatedWithAutoixpertTaxRate } =
                await this.audatexTaskService.retrieveResults({
                    report: this.report,
                    // Do not require valuation on automatic imports
                    requireValuation: !automaticImport,
                    requireCalculation: false,
                });

            this.audatexGrossValueWasCalculatedWithAutoixpertTaxRate =
                audatexGrossValueWasCalculatedWithAutoixpertTaxRate;
        } catch (error) {
            /**
             * Errors in an automatic import should not be shown to the user. When the user switches between his photos and the calculation,
             * he would otherwise be shown errors within autoiXpert all the time.
             */
            if (!automaticImport) {
                this.audatexTaskService.handleAndRethrow({
                    axError: error,
                    report: this.report,
                    defaultHandler: {
                        title: 'Audatex-Bewertung nicht importiert',
                        body: 'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        partnerLogo: 'audatex',
                    },
                });
            } else {
                // Stop the waiting state
                this.audatexTaskService.abortPendingIndicator();
            }
        }
    }

    public async removeAudatexValuation() {
        await this.audatexTaskService.removeValuationData({ report: this.report });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex Valuation
    /////////////////////////////////////////////////////////////////////////////*/

    // ********** Market Analysis **********
    public marketAnalysesForUi: MarketAnalysisForUi[] = [
        {
            name: 'cartv',
            logoFileName: 'cartv-logo.svg',
            marketAnalysis: new MarketAnalysis(),
            exportPending: false,
            importPending: false,
            areCredentialsComplete: this.areCartvCredentialsComplete.bind(this),
            openMarketAnalysis: this.openCartvMarketAnalysis.bind(this),
            openManualConnectionDialog: this.openManualCartvMarketAnalysisConnectionDialog.bind(this),
            isReadyForFirstImport: this.isCartvMarketAnalysisReadyForFirstImport.bind(this),
            importMarketAnalysis: this.importCartvMarketAnalysis.bind(this),
            haveResultsBeenImported: this.haveCartvMarketAnalysisResultsBeenImported.bind(this),
            resetMarketAnalysis: this.resetCartvMarketAnalysis.bind(this),
            /**
             * Returns true if all required fields are filled.
             * @return {boolean}
             */
            isMarketAnalysisAllowed: this.isCartvMarketAnalysisAllowed.bind(this),
            getTooltipForExportIcon: () => stripHtml(this.getTooltipForCartvMarketAnalysisExportIcon()),
            setSearchRadius: (searchRadius: UserPreferences['replacementValueSearchRadiusCartv']) => {
                this.report.valuation.cartvValuation.searchRadiusInKm = searchRadius;
                this.userPreferences.replacementValueSearchRadiusCartv = searchRadius;
                this.saveReport();
            },
            allowedSearchRadii: [200, 150, 100],
        },
        {
            name: 'valuepilot',
            logoFileName: 'valuepilot.png',
            marketAnalysis: new ValuepilotMarketAnalysis(),
            exportPending: false,
            importPending: false,
            // AUTOonline credentials are used for VALUEpilot as well.
            areCredentialsComplete: this.areAutoonlineCredentialsComplete.bind(this),
            openMarketAnalysis: this.openValuepilotMarketAnalysis.bind(this),
            openManualConnectionDialog: this.openManualValuepilotMarketAnalysisConnectionDialog.bind(this),
            isReadyForFirstImport: this.isValuepilotMarketAnalysisReadyForFirstImport.bind(this),
            importMarketAnalysis: this.downloadValuepilotMarketAnalysisDocument.bind(this),
            haveResultsBeenImported: this.haveValuepilotMarketAnalysisResultsBeenImported.bind(this),
            resetMarketAnalysis: this.resetValuepilotMarketAnalysis.bind(this),
            /**
             * Returns true if all required fields are filled.
             * @return {boolean}
             */
            isMarketAnalysisAllowed: this.isValuepilotMarketAnalysisAllowed.bind(this),
            getTooltipForExportIcon: () => stripHtml(this.getTooltipForValuepilotMarketAnalysisExportIcon()),
            setSearchRadius: (searchRadius: UserPreferences['replacementValueSearchRadiusValuepilot']) => {
                this.report.valuation.valuepilotValuation.searchRadiusInKm = searchRadius;
                this.userPreferences.replacementValueSearchRadiusValuepilot = searchRadius;
                this.saveReport();
            },
            allowedSearchRadii: [500, 200, 150, 100, 50, 20, 10],
        },
        {
            name: 'winvalue',
            logoFileName: 'winvalue.png',
            marketAnalysis: new MarketAnalysis(),
            exportPending: false,
            importPending: false,
            areCredentialsComplete: this.areWinvalueMarketAnalysisCredentialsComplete.bind(this),
            openMarketAnalysis: this.openWinvalueMarketAnalysis.bind(this),
            openManualConnectionDialog: this.openManualWinvalueMarketAnalysisConnectionDialog.bind(this),
            isReadyForFirstImport: this.isWinvalueMarketAnalysisReadyForFirstImport.bind(this),
            importMarketAnalysis: this.importWinvalueMarketAnalysis.bind(this),
            haveResultsBeenImported: this.haveWinvalueMarketAnalysisResultsBeenImported.bind(this),
            resetMarketAnalysis: this.resetWinvalueMarketAnalysis.bind(this),
            /**
             * Returns true if all required fields are filled.
             * @return {boolean}
             */
            isMarketAnalysisAllowed: this.isWinvalueMarketAnalysisAllowed.bind(this),
            getTooltipForExportIcon: () => stripHtml(this.getTooltipForWinvalueMarketAnalysisExportIcon()),
            setSearchRadius: (searchRadius: UserPreferences['replacementValueSearchRadiusWinvalue']) => {
                this.report.valuation.winvalueValuation.searchRadiusInKm = searchRadius;
                this.userPreferences.replacementValueSearchRadiusWinvalue = searchRadius;
                this.saveReport();
            },
            allowedSearchRadii: [500, 200, 100, 50, 20, 10],
        },
    ];
    public filteredMarketAnalysesForUi: MarketAnalysisForUi[] = [];

    private filterMarketAnalysisProviders() {
        // If no market value platform has complete credentials, show all of them. That way the user can see what's possible in autoiXpert.
        if (!this.marketAnalysesForUi.some((marketAnalysesForUi) => marketAnalysesForUi.areCredentialsComplete())) {
            this.filteredMarketAnalysesForUi = [...this.marketAnalysesForUi];
        } else {
            this.filteredMarketAnalysesForUi = this.marketAnalysesForUi.filter((marketAnalysesForUi) =>
                marketAnalysesForUi.areCredentialsComplete(),
            );
        }
    }

    public toggleHideCorridorOnPrintout(
        valuationSource: { corridorHiddenOnPrintout: boolean },
        valuationName: MarketAnalysisForUi['name'] | 'audatex' | 'dat' | 'custom',
    ) {
        valuationSource.corridorHiddenOnPrintout = !valuationSource.corridorHiddenOnPrintout;

        /**
         * If the corridor is hidden, the protocol should not be included in the full document.
         */
        let valuationDocumentName: DocumentMetadata['type'];
        switch (valuationName) {
            case 'dat': {
                valuationDocumentName = 'datMarketAnalysis';
                setReportDocumentInclusionStatusByType({
                    report: this.report,
                    documentGroup: 'report',
                    includedInFullDocument: !valuationSource.corridorHiddenOnPrintout,
                    documentType: 'datValuationProtocol',
                });
                break;
            }
            case 'audatex': {
                valuationDocumentName = 'audatexMarketAnalysis';
                break;
            }
            case 'cartv': {
                valuationDocumentName = 'cartvMarketAnalysis';
                break;
            }
            case 'winvalue': {
                valuationDocumentName = 'winvalueMarketAnalysis';
                break;
            }
            case 'valuepilot': {
                valuationDocumentName = 'valuepilotMarketAnalysis';
                break;
            }
        }
        if (valuationDocumentName) {
            setReportDocumentInclusionStatusByType({
                report: this.report,
                documentGroup: 'report',
                includedInFullDocument: !valuationSource.corridorHiddenOnPrintout,
                documentType: valuationDocumentName,
            });
        }
    }

    /**
     * Attach the report's carValueExchange inquiries to the marketAnalysesForUi array's objects.
     */
    private prepareMarketAnalysesForUi(): void {
        const cartvMarketAnalysisForUi = this.marketAnalysesForUi.find((provider) => provider.name === 'cartv');
        const valuepilotMarketAnalysisForUi = this.marketAnalysesForUi.find(
            (provider) => provider.name === 'valuepilot',
        );
        const winvalueMarketAnalysisForUi = this.marketAnalysesForUi.find((provider) => provider.name === 'winvalue');

        // Type strongly.
        const getterKey: keyof MarketAnalysisForUi = 'marketAnalysis';

        // Define getters so that we always use the latest object, even if the websocket patches delivered a new object after this method has been called.
        Object.defineProperty(cartvMarketAnalysisForUi, getterKey, {
            get: () => this.report.valuation.cartvValuation,
        });
        Object.defineProperty(valuepilotMarketAnalysisForUi, getterKey, {
            get: () => this.report.valuation.valuepilotValuation,
        });
        Object.defineProperty(winvalueMarketAnalysisForUi, getterKey, {
            get: () => this.report.valuation.winvalueValuation,
        });
    }

    //*****************************************************************************
    //  CARTV Market Analysis
    //****************************************************************************/
    public async createCartvMarketAnalysis() {
        // Since a market analysis is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Marktwertanalyse von CARTV ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        const cartvMarketAnalysisForUi = this.marketAnalysesForUi.find((analysis) => analysis.name === 'cartv');
        cartvMarketAnalysisForUi.exportPending = true;

        let cartvResponse;
        try {
            cartvResponse = await this.httpClient
                .post<{
                    message: string;
                    cartvValuation: MarketAnalysis;
                }>(`/api/v0/reports/${this.report._id}/marketAnalyses/cartv`, {})
                .toPromise();
        } catch (error) {
            cartvMarketAnalysisForUi.exportPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: 'Export zu CarTV gescheitert',
                    body: 'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>',
                },
            });
        }

        Object.assign<MarketAnalysis, MarketAnalysis>(
            this.report.valuation.cartvValuation,
            cartvResponse.cartvValuation,
        );

        this.newWindowService.open(this.report.valuation.cartvValuation.autoLoginUrl);
        await this.saveReport();
        cartvMarketAnalysisForUi.exportPending = false;
    }

    private areCartvCredentialsComplete(): boolean {
        return isCarTvUserComplete(this.user);
    }

    public openCartvMarketAnalysis() {
        if (!this.areCartvCredentialsComplete()) {
            this.toastService.error(
                'Zugangsdaten fehlen',
                this.getTooltipForCartvMarketAnalysisExportIcon() +
                    '<br><br>Noch keinen Account? <a href="https://www.cartv.eu/neuanmeldung-sv/" target="_blank" rel="noopener">Registrieren</a>',
            );
            return;
        }
        if (!this.isCartvMarketAnalysisAllowed() || this.isReportLocked()) {
            this.toastService.error('Marktanalyse nicht möglich', this.getTooltipForCartvMarketAnalysisExportIcon());
            return;
        }

        if (this.report.valuation.cartvValuation.analysisId) {
            this.newWindowService.open(this.report.valuation.cartvValuation.autoLoginUrl);
        } else {
            this.createCartvMarketAnalysis();
        }
    }

    public isCartvMarketAnalysisReadyForFirstImport(): boolean {
        return !!this.report.valuation.cartvValuation.analysisId && !this.report.valuation.cartvValuation.retrievedAt;
    }

    public importCartvMarketAnalysis(downloadMarketAnalysisDocument = false) {
        const cartvMarketAnalysisForUi = this.marketAnalysesForUi.find((analysis) => analysis.name === 'cartv');
        cartvMarketAnalysisForUi.importPending = true;

        this.httpClient
            .get<{
                message: string;
                cartvValuation: MarketAnalysis;
                documentBase64: string;
            }>(`/api/v0/reports/${this.report._id}/marketAnalyses/cartv`)
            .subscribe({
                next: (data) => {
                    Object.assign<MarketAnalysis, MarketAnalysis>(
                        this.report.valuation.cartvValuation,
                        data.cartvValuation,
                    );

                    this.setVehicleValueGross(+this.report.valuation.cartvValuation.averagePrice, 'cartv');
                    determineDamageType(this.report, this.userPreferences);

                    if (this.report.valuation.cartvValuation.downtimeCompensationGroup) {
                        this.report.damageCalculation.downtimeCompensationPerWorkday =
                            this.report.valuation.cartvValuation.downtimeCompensationPerWorkday;
                        this.report.damageCalculation.downtimeCompensationGroup =
                            this.report.valuation.cartvValuation.downtimeCompensationGroup;
                    }
                    if (this.report.valuation.cartvValuation.rentalCarClass) {
                        this.report.damageCalculation.rentalCarClass =
                            this.report.valuation.cartvValuation.rentalCarClass;
                        this.report.damageCalculation.rentalCarClassCostsPerDay =
                            this.report.valuation.cartvValuation.rentalCarClassCostsPerDay;
                    }
                    if (
                        !this.report.valuation.cartvValuation.downtimeCompensationGroup &&
                        !this.report.valuation.cartvValuation.rentalCarClass
                    ) {
                        this.showCartvDowntimeCompensationNotification = true;
                    }
                    if (this.report.valuation.cartvValuation.originalPrice) {
                        // Currently there's no reason to fill the properties originalPrice{WithoutEquipment...|WithEquipmentNet}.
                        this.report.valuation.originalPriceWithEquipmentGross =
                            this.report.valuation.cartvValuation.originalPrice;
                    }

                    if (downloadMarketAnalysisDocument) {
                        // Download PDF
                        const blob = base64toBlob(data.documentBase64, 'application/pdf');
                        const localUrl = window.URL.createObjectURL(blob);
                        // Create a local blob with the given market analysis PDF and get its URL
                        this.pdfDownloadUrl = this.domSanitizer.bypassSecurityTrustUrl(localUrl);
                        // Propagate the new URL to the anchor which will be clicked automatically immediately afterwards.
                        this.changeDetectorRef.detectChanges();

                        console.log('Downloading market analysis from local blob at %s', localUrl);

                        // Click on the download anchor tag to download the PDF
                        const event = new MouseEvent('click');
                        this.pdfDownloadAnchor.nativeElement.dispatchEvent(event);
                    }

                    const team = this.loggedInUserService.getTeam();

                    addDocumentToReport(
                        {
                            team,
                            report: this.report,
                            newDocument: new DocumentMetadata({
                                type: 'cartvMarketAnalysis',
                                title: 'CarTV Marktwertanalyse',
                                uploadedDocumentId: null,
                                permanentUserUploadedDocument: false,
                                createdAt: moment().format(),
                                createdBy: this.user._id,
                            }),
                            documentGroup: 'report',
                        },
                        { insertAfterFallback: 'report' },
                    );

                    this.saveReport();
                    cartvMarketAnalysisForUi.importPending = false;
                },
                error: (error) => {
                    cartvMarketAnalysisForUi.importPending = false;
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            MARKET_ANALYSIS_IN_PROGRESS: {
                                title: 'CarTV braucht noch etwas Zeit...',
                                body: 'Bitte rufe die Ergebnisse in ein paar Minuten erneut ab.',
                                toastType: 'info',
                            },
                            MARKET_ANALYSIS_ABORTED: () => {
                                // Reset the analysis so that the user can start a new one.
                                cartvMarketAnalysisForUi.resetMarketAnalysis();
                                return {
                                    title: 'CarTV Marktwertanalyse abgebrochen',
                                    body: 'Die Marktwertanalyse wurde außerhalb von autoiXpert abgebrochen. Erstelle eine neue Marktwertanalyse.\n\nManchmal meldet CARTV auch einen Abbruch, wenn kein Wertkorridor ermittelt werden kann. Wende dich dann am besten direkt an CARTV.',
                                };
                            },
                            ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                        },
                        defaultHandler: {
                            title: 'Import von CarTV gescheitert',
                            body: null,
                        },
                    });
                },
            });
    }

    public closeCartvCompensationNotification() {
        this.user.userInterfaceStates.cartvDowntimeCompensationImportNoticeClosed = true;
        this.saveUser();
    }

    public haveCartvMarketAnalysisResultsBeenImported(): boolean {
        return !!this.report.valuation.cartvValuation.retrievedAt;
    }

    public async resetCartvMarketAnalysis() {
        if (this.isReportLocked()) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Marktwertanalysen können gelöscht werden, sobald du wieder online bist.',
            );
            return;
        }

        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Marktanalyse löschen?',
                    content: 'Das kann nicht rückgängig gemacht werden.',
                    confirmLabel: 'Löschen',
                    cancelLabel: 'Doch nicht',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();
        if (!decision) return;

        this.report.valuation.cartvValuation = new MarketAnalysis();
        this.report.valuation.cartvValuation.searchRadiusInKm = this.userPreferences.replacementValueSearchRadiusCartv;
        this.prepareMarketAnalysesForUi();

        removeDocumentTypeFromReport({
            report: this.report,
            documentType: 'cartvMarketAnalysis',
            documentGroup: 'report',
        });

        // Delete CARTV record unless we're within an amendment. In that case, we only disconnect the records.
        if (!this.isAmendmentReport()) {
            this.httpClient.delete(`/api/v0/reports/${this.report._id}/marketAnalyses/cartv`).subscribe({
                error: (error) => {
                    console.error('Error deleting the CARTV market analysis.', { error });
                },
            });
        }

        this.saveReport();
    }

    public async openManualCartvMarketAnalysisConnectionDialog() {
        const result = await this.dialog
            .open<MarketAnalysisConnectionDialogComponent, MarketAnalysisConnectionDialogData, MarketAnalysis>(
                MarketAnalysisConnectionDialogComponent,
                {
                    panelClass: 'dialog-with-extra-padding',
                    data: {
                        provider: 'cartv',
                    },
                },
            )
            .afterClosed()
            .toPromise();

        if (result) {
            this.report.valuation.cartvValuation = result;
            await this.saveReport({ waitForServer: true });

            this.importCartvMarketAnalysis();
        }
    }

    public isCartvMarketAnalysisAllowed(): boolean {
        // Either the DAT€Code or the VIN must be provided so the market analysis can be conducted.
        if (!this.report.car.datIdentification.datEuropaCode && !this.report.car.vin) {
            return false;
        }
        if (!this.report.car.gearboxModelName) {
            return false;
        }
        if (!this.report.car.firstRegistration) {
            return false;
        }
        // This statement is easier to read than a simpler one that WebStorm suggests.
        //noinspection RedundantIfStatementJS
        if (!this.report.car.mileage && !this.report.car.mileageMeter && !this.report.car.mileageAsStated) {
            return false;
        }

        return true;
    }

    public getTooltipForCartvMarketAnalysisExportIcon(): string {
        if (!this.areCartvCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den <a href="/Einstellungen#residual-and-market-value-exchanges-section">Einstellungen</a> eingegeben werden.';
        }

        if (this.isReportLocked()) {
            return 'Das Gutachten ist abgeschlossen';
        }

        if (!this.isCartvMarketAnalysisAllowed()) {
            return 'Getriebeart, Erstzulassung & Laufleistung müssen definiert sein.';
        }

        return 'Marktanalyse starten';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CARTV Market Analysis
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  VALUEpilot Market Analysis
    //****************************************************************************/
    public async createValuepilotMarketAnalysis() {
        // Offline check happens before the call because the report must be saved before exporting. That report save call must be blocked already if offline.

        const valuepilotMarketAnalysisForUi = this.marketAnalysesForUi.find(
            (analysis) => analysis.name === 'valuepilot',
        );
        valuepilotMarketAnalysisForUi.exportPending = true;

        const waitTimeToast = this.toastService.info('VALUEpilot angefragt', 'Das kann ein paar Sekunden dauern...');

        let valuepilotResponse: { message: string; marketAnalysis: MarketAnalysis; documentBase64: string };
        try {
            valuepilotResponse = await this.httpClient
                .post<{
                    message: string;
                    marketAnalysis: MarketAnalysis;
                    documentBase64: string;
                }>(`/api/v0/reports/${this.report._id}/marketAnalyses/valuepilot`, {})
                .toPromise();
        } catch (error) {
            valuepilotMarketAnalysisForUi.exportPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: 'Export zu VALUEpilot gescheitert',
                    body: 'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>',
                },
            });
        }

        // Remove toast in case the request goes through faster than expected.
        this.toastService.remove(waitTimeToast.id);

        if (!valuepilotResponse.marketAnalysis.minCorridor && !valuepilotResponse.marketAnalysis.maxCorridor) {
            this.toastService.info(
                'Keine Fahrzeuge gefunden',
                'VALUEpilot konnte keine Fahrzeuge finden. Versuche ggf. den Suchradius zu verändern.',
            );
            valuepilotMarketAnalysisForUi.exportPending = false;
            return;
        }

        Object.assign<MarketAnalysis, MarketAnalysis>(
            this.report.valuation.valuepilotValuation,
            valuepilotResponse.marketAnalysis,
        );

        this.setVehicleValueGross(+this.report.valuation.valuepilotValuation.maxCorridor, 'valuepilot');
        determineDamageType(this.report, this.userPreferences);

        // VALUEpilot does not offer an auto login URL, so we don't open the interface.

        // Insert document into document structure.
        const team = this.loggedInUserService.getTeam();
        addDocumentToReport(
            {
                team,
                report: this.report,
                newDocument: new DocumentMetadata({
                    type: 'valuepilotMarketAnalysis',
                    title: 'VALUEpilot Marktwerte',
                    uploadedDocumentId: null,
                    permanentUserUploadedDocument: false,
                    createdAt: moment().format(),
                    createdBy: this.user._id,
                }),
                documentGroup: 'report',
            },
            { insertAfterFallback: 'report' },
        );

        await this.saveReport();
        valuepilotMarketAnalysisForUi.exportPending = false;
    }

    public async openManualValuepilotMarketAnalysisConnectionDialog() {
        this.toastService.info(
            'Verknüpfung nicht verfügbar',
            'Eine nachträgliche Verknüpfung ist für VALUEpilot nicht möglich. Starte eine neue Marktwertanalyse.',
        );
    }

    private areAutoonlineCredentialsComplete(): boolean {
        return isAutoonlineUserComplete(this.user);
    }

    public openValuepilotMarketAnalysis() {
        if (!this.areAutoonlineCredentialsComplete()) {
            this.toastService.error(
                'Zugangsdaten fehlen',
                this.getTooltipForValuepilotMarketAnalysisExportIcon() +
                    '<br><br>Noch keinen Account? <a href="https://www.audatex.de/sachverstaendige/valuepilot/" target="_blank" rel="noopener">Registrieren</a>',
            );
            return;
        }

        if (!this.isValuepilotMarketAnalysisAllowed() || this.isReportLocked()) {
            this.toastService.info(
                'Marktanalyse nicht möglich',
                this.getTooltipForValuepilotMarketAnalysisExportIcon(),
            );
            return;
        }

        if (this.report.valuation.valuepilotValuation.retrievedAt) {
            this.newWindowService.open(
                `https://valuepilot.autoonline.com?username=${this.user.autoonlineUser.customerNumber}&password=${this.user.autoonlineUser.password}`,
                '_blank',
                'rel=noopener',
            );
        } else {
            this.dialog
                .open<ValuepilotEquipmentDialogComponent, ValuepilotEquipmentDialogData, boolean>(
                    ValuepilotEquipmentDialogComponent,
                    {
                        width: '550px',
                        hasBackdrop: true,
                        panelClass: 'dialog-with-extra-padding',
                        data: {
                            reportId: this.report._id,
                            valuepilotMarketAnalysis: this.report.valuation.valuepilotValuation,
                        },
                    },
                )
                .afterClosed()
                .subscribe(async (decisionToStartAnalysis) => {
                    if (decisionToStartAnalysis) {
                        // Since a market analysis is an online-only feature, block this feature if offline.
                        if (!this.networkStatusService.isOnline()) {
                            this.toastService.offline(
                                'Offline nicht verfügbar',
                                'Die Marktwertanalyse von VALUEpilot ist verfügbar, sobald du wieder online bist.\n\nDeine Eingaben im Dialog wurden gespeichert.',
                            );
                            // Save locally to keep the user input from the dialog if offline.
                            await this.saveReport();
                            return;
                        }

                        // The server needs the data on the report to create the VALUEpilot record.
                        await this.saveReport({ waitForServer: true });
                        await this.createValuepilotMarketAnalysis();
                    } else {
                        // If the user canceled the dialog, simply save his selection locally.
                        await this.saveReport();
                    }
                });
        }
    }

    public isValuepilotMarketAnalysisReadyForFirstImport(): boolean {
        return false;
    }

    public downloadValuepilotMarketAnalysisDocument() {
        this.httpClient
            .get(`/api/v0/reports/${this.report._id}/documents/valuepilotMarketAnalysis`, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe({
                next: (response) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'VALUEpilot-Dokument konnte nicht geladen werden',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    public haveValuepilotMarketAnalysisResultsBeenImported(): boolean {
        return !!this.report.valuation.valuepilotValuation.retrievedAt;
    }

    public async resetValuepilotMarketAnalysis() {
        if (this.isReportLocked()) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Marktwertanalysen können gelöscht werden, sobald du wieder online bist.',
            );
            return;
        }

        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Marktanalyse löschen?',
                    content: 'Das kann nicht rückgängig gemacht werden.',
                    confirmLabel: 'Löschen',
                    cancelLabel: 'Doch nicht',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();
        if (!decision) return;

        // Keep equipment options despite deletion
        const equipmentOptions = this.report.valuation.valuepilotValuation.valuepilotEquipmentOptions;

        this.report.valuation.valuepilotValuation = new ValuepilotMarketAnalysis();
        this.report.valuation.valuepilotValuation.valuepilotEquipmentOptions = equipmentOptions;
        this.report.valuation.valuepilotValuation.searchRadiusInKm =
            this.userPreferences.replacementValueSearchRadiusValuepilot;
        this.prepareMarketAnalysesForUi();

        removeDocumentTypeFromReport({
            report: this.report,
            documentType: 'valuepilotMarketAnalysis',
            documentGroup: 'report',
        });

        // VALUEpilot does not allow deleting past records, so we don't need to communicate with the server.

        this.saveReport();
    }

    public isValuepilotMarketAnalysisAllowed(): boolean {
        if (!this.report.car.make || !this.report.car.model) {
            return false;
        }
        // noinspection RedundantIfStatementJS
        if (!this.report.car.firstRegistration) {
            return false;
        }

        return true;
    }

    public getTooltipForValuepilotMarketAnalysisExportIcon(): string {
        if (!this.areAutoonlineCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den <a href="/Einstellungen#residual-and-market-value-exchanges-section">Einstellungen</a> eingegeben werden.';
        }

        if (this.isReportLocked()) {
            return 'Das Gutachten ist abgeschlossen';
        }

        if (!this.isValuepilotMarketAnalysisAllowed()) {
            const missingData: ('Hersteller' | 'Modell' | 'Erstzulassung')[] = [];
            if (!this.report.car.make) {
                missingData.push('Hersteller');
            }
            if (!this.report.car.model) {
                missingData.push('Modell');
            }
            if (!this.report.car.firstRegistration) {
                missingData.push('Erstzulassung');
            }
            return `Bitte gib noch ein: ${missingData.join(', ')}`;
        }

        return 'Marktanalyse starten';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END VALUEpilot Market Analysis
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  WinValue Market Analysis
    //****************************************************************************/
    private areWinvalueMarketAnalysisCredentialsComplete(): boolean {
        return areWinvalueMarketAnalysisCredentialsComplete(this.user);
    }

    /**
     * Create a market analysis on the WinValue platform
     */
    public async createWinvalueMarketAnalysis(): Promise<any> {
        // Since a market analysis is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Marktwertanalyse von Winvalue ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        const winvalueMarketAnalysisForUi = this.marketAnalysesForUi.find((analysis) => analysis.name === 'winvalue');
        winvalueMarketAnalysisForUi.exportPending = true;

        let winvalueResponse: { message: string; winvalueValuation: MarketAnalysis };
        try {
            winvalueResponse = await this.httpClient
                .post<{
                    message: string;
                    winvalueValuation: MarketAnalysis;
                }>(`/api/v0/reports/${this.report._id}/marketAnalyses/winvalue/`, {})
                .toPromise();
        } catch (error) {
            winvalueMarketAnalysisForUi.exportPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: 'Export fehlgeschlagen',
                    body: 'Die Marktanalyse konnte nicht erstellt werden.',
                },
            });
        }
        this.report.valuation.winvalueValuation.analysisId = winvalueResponse.winvalueValuation.analysisId;
        this.report.valuation.winvalueValuation.autoLoginUrl = winvalueResponse.winvalueValuation.autoLoginUrl;
        await this.saveReport();
        winvalueMarketAnalysisForUi.exportPending = false;
    }

    /**
     * Open the market analysis of WinValue in an external window. Export data from aX to WinValue if no market anaylsis exists yet.
     * The market analysis is used to determine how much it costs to get another undamaged car of the same type as the damaged car.
     */
    public async openWinvalueMarketAnalysis() {
        if (
            !this.areWinvalueMarketAnalysisCredentialsComplete() ||
            !this.isWinvalueMarketAnalysisAllowed() ||
            this.isReportLocked()
        ) {
            this.toastService.info('Marktanalyse nicht möglich', this.getTooltipForWinvalueMarketAnalysisExportIcon());
            return;
        }

        // If a market analysis already exists, open it.
        if (this.report.valuation.winvalueValuation.analysisId) {
            this.newWindowService.open(this.report.valuation.winvalueValuation.autoLoginUrl);
        }
        // If no market analysis exists, create one and open it then.
        else {
            await this.createWinvalueMarketAnalysis();
            const winvalueMarketAnalysisForUi = this.marketAnalysesForUi.find(
                (analysis) => analysis.name === 'winvalue',
            );
            winvalueMarketAnalysisForUi.exportPending = true;

            this.newWindowService.open(this.report.valuation.winvalueValuation.autoLoginUrl);

            winvalueMarketAnalysisForUi.exportPending = false;
        }
    }

    public isWinvalueMarketAnalysisReadyForFirstImport(): boolean {
        return (
            !!this.report.valuation.winvalueValuation.analysisId && !this.report.valuation.winvalueValuation.retrievedAt
        );
    }

    public async importWinvalueMarketAnalysis(downloadMarketAnalysisDocumentToLocalComputer = false): Promise<void> {
        const winvalueMarketAnalysisForUi = this.marketAnalysesForUi.find((analysis) => analysis.name === 'winvalue');
        winvalueMarketAnalysisForUi.importPending = true;

        let winvalueMarketAnalysisResponse: WinvalueMarketAnalysisResponse;

        try {
            winvalueMarketAnalysisResponse = await this.httpClient
                .get<WinvalueMarketAnalysisResponse>(`/api/v0/reports/${this.report._id}/marketAnalyses/winvalue`)
                .toPromise();
        } catch (error) {
            winvalueMarketAnalysisForUi.importPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: 'Import fehlgeschlagen',
                    body: 'Die Marktanalyse konnte nicht importiert werden.',
                },
            });
        }

        Object.assign<MarketAnalysis, MarketAnalysis>(
            this.report.valuation.winvalueValuation,
            winvalueMarketAnalysisResponse.winvalueValuation,
        );

        this.setVehicleValueGross(this.report.valuation.winvalueValuation.averagePrice, 'winvalue');
        determineDamageType(this.report, this.userPreferences);

        if (downloadMarketAnalysisDocumentToLocalComputer) {
            // Download PDF
            const blob = base64toBlob(winvalueMarketAnalysisResponse.documentBase64, 'application/pdf');
            const localUrl = window.URL.createObjectURL(blob);
            // Create a local blob with the given market analysis PDF and get its URL
            this.pdfDownloadUrl = this.domSanitizer.bypassSecurityTrustUrl(localUrl);
            // Propagate the new URL to the anchor which will be clicked automatically immediately afterwards.
            this.changeDetectorRef.detectChanges();

            console.log('Downloading market analysis from local blob at %s', localUrl);

            // Click on the download anchor tag to download the PDF
            const event = new MouseEvent('click');
            this.pdfDownloadAnchor.nativeElement.dispatchEvent(event);
        }

        const team = this.loggedInUserService.getTeam();

        addDocumentToReport(
            {
                team,
                report: this.report,
                newDocument: new DocumentMetadata({
                    type: 'winvalueMarketAnalysis',
                    title: 'WinValue Marktwertanalyse',
                    uploadedDocumentId: null,
                    permanentUserUploadedDocument: false,
                    createdAt: moment().format(),
                    createdBy: this.user._id,
                }),
                documentGroup: 'report',
            },
            { insertAfterFallback: 'report' },
        );

        this.saveReport();
        winvalueMarketAnalysisForUi.importPending = false;
    }

    public haveWinvalueMarketAnalysisResultsBeenImported(): boolean {
        return !!this.report.valuation.winvalueValuation.retrievedAt;
    }

    public async openManualWinvalueMarketAnalysisConnectionDialog() {
        const result = await this.dialog
            .open<MarketAnalysisConnectionDialogComponent, MarketAnalysisConnectionDialogData, MarketAnalysis>(
                MarketAnalysisConnectionDialogComponent,
                {
                    panelClass: 'dialog-with-extra-padding',
                    data: {
                        provider: 'winvalue',
                    },
                },
            )
            .afterClosed()
            .toPromise();

        if (result) {
            this.report.valuation.winvalueValuation = result;
            await this.saveReport({ waitForServer: true });

            await this.importWinvalueMarketAnalysis();
        }
    }

    public async resetWinvalueMarketAnalysis() {
        if (this.isReportLocked()) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Marktwertanalysen können gelöscht werden, sobald du wieder online bist.',
            );
            return;
        }

        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Marktanalyse löschen?',
                    content: 'Das kann nicht rückgängig gemacht werden.',
                    confirmLabel: 'Löschen',
                    cancelLabel: 'Doch nicht',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();
        if (!decision) return;

        this.report.valuation.winvalueValuation = new MarketAnalysis();
        this.report.valuation.winvalueValuation.searchRadiusInKm =
            this.userPreferences.replacementValueSearchRadiusWinvalue;
        this.prepareMarketAnalysesForUi();

        removeDocumentTypeFromReport({
            report: this.report,
            documentType: 'winvalueMarketAnalysis',
            documentGroup: 'report',
        });

        // Delete Winvalue record unless we're within an amendment. In that case, we only disconnect the records.
        if (!this.isAmendmentReport()) {
            this.httpClient.delete(`/api/v0/reports/${this.report._id}/marketAnalyses/winvalue`).subscribe({
                error: (error) => {
                    console.error('Error deleting the WinValue market analysis.', { error });
                },
            });
        }

        this.saveReport();
    }

    public isWinvalueMarketAnalysisAllowed(): boolean {
        // Either the DAT€Code or the VIN must be provided so the market analysis can be conducted.
        if (!this.report.car.datIdentification.datEuropaCode && !this.report.car.vin) {
            return false;
        }
        if (!this.report.car.firstRegistration) {
            return false;
        }
        if (!this.report.car.shape) {
            return false;
        }
        // This statement is easier to read than a simpler one that WebStorm suggests.
        //noinspection RedundantIfStatementJS
        if (!this.report.car.mileage && !this.report.car.mileageMeter && !this.report.car.mileageAsStated) {
            return false;
        }

        return true;
    }

    public getTooltipForWinvalueMarketAnalysisExportIcon(): string {
        if (!this.areWinvalueMarketAnalysisCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den <a href="/Einstellungen#residual-and-market-value-exchanges-section">Einstellungen</a> eingegeben werden.';
        }

        if (this.isReportLocked()) {
            return 'Das Gutachten ist abgeschlossen';
        }

        if (!this.isWinvalueMarketAnalysisAllowed()) {
            return 'VIN, Fahrzeugart, Erstzulassung & Laufleistung müssen definiert sein.';
        }

        return 'Marktanalyse starten';
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END WinValue Market Analysis
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Market Analysis
    //****************************************************************************/
    public createCustomMarketAnalysis() {
        if (!this.report.valuation.customMarketAnalyses) {
            this.report.valuation.customMarketAnalyses = [];
        }

        const newAnalysis: CustomMarketAnalysis = new CustomMarketAnalysis();
        newAnalysis.retrievedAt = moment().format();
        newAnalysis.createdAt = newAnalysis.retrievedAt;
        newAnalysis.createdBy = this.user._id;

        this.report.valuation.customMarketAnalyses.push(newAnalysis);
        this.openEditCustomMarketAnalysisDialog(newAnalysis);
    }

    public deleteCustomMarketAnalysis(customMarketAnalysis: CustomMarketAnalysis) {
        removeFromArray(customMarketAnalysis, this.report.valuation.customMarketAnalyses);
    }

    public removeCustomMarketAnalysisIfEmpty(customMarketAnalysis: CustomMarketAnalysis) {
        if (
            !customMarketAnalysis.title &&
            !customMarketAnalysis.maxCorridor &&
            !customMarketAnalysis.minCorridor &&
            !customMarketAnalysis.averagePrice
        ) {
            removeFromArray(customMarketAnalysis, this.report.valuation.customMarketAnalyses);
            this.saveReport();
        }
    }

    protected async openEditCustomMarketAnalysisDialog(customMarketAnalysis: CustomMarketAnalysis) {
        await this.dialog
            .open<CustomMarketAnalysisDialogComponent, CustomMarketAnalysisDialogData, DatValuation['dossierId']>(
                CustomMarketAnalysisDialogComponent,
                {
                    data: {
                        customMarketAnalysis: customMarketAnalysis,
                        disabled: this.isReportLocked(),
                    },
                    maxWidth: '700px',
                },
            )
            .afterClosed()
            .toPromise();

        if (customMarketAnalysis?.averagePrice) {
            this.setVehicleValueGross(customMarketAnalysis.averagePrice, 'custom');
        }
        this.removeCustomMarketAnalysisIfEmpty(customMarketAnalysis);
        this.saveReport();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Market Analysis
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Outputs
    //****************************************************************************/

    public setVehicleValueGross(
        value: number,
        provider: 'custom' | 'audatex' | 'dat' | 'valuepilot' | 'winvalue' | 'cartv' = 'custom',
    ) {
        this.vehicleValueGrossSelected.emit({ vehicleValueGross: value, provider });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Outputs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    @HostListener('window:visibilitychange', ['$event'])
    protected importPendingMarketAnalyses() {
        if (document.visibilityState === 'visible') {
            if (!this.report) return;

            // Import Audatex valuation
            if (
                this.report.audatexTaskId &&
                this.report.valuation?.valuationProvider === 'audatex' &&
                !this.report.valuation.audatexValuation.documentHash
            ) {
                this.importAudatexValuation({ automaticImport: true });
            }

            // Import WinValue valuation
            if (
                this.report.valuation.winvalueValuation.analysisId &&
                !this.report.valuation.winvalueValuation.retrievedAt
            ) {
                this.importWinvalueMarketAnalysis();
            }

            // Import Cartv valuation
            if (this.report.valuation.cartvValuation.analysisId && !this.report.valuation.cartvValuation.retrievedAt) {
                this.importCartvMarketAnalysis();
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Helpers
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    public isAmendmentReport(): boolean {
        return !!this.report.originalReportId;
    }

    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        if (this.isReportLocked()) {
            return;
        }

        return this.reportDetailsService.patch(this.report, { waitForServer }).catch((error) => {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
            throw error;
        });
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Helpers
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

export class MarketAnalysisForUi {
    name: 'cartv' | 'valuepilot' | 'winvalue';
    logoFileName: string;
    marketAnalysis: MarketAnalysis;
    exportPending: boolean;
    importPending: boolean;
    areCredentialsComplete: () => boolean;
    isMarketAnalysisAllowed: () => boolean;
    openMarketAnalysis: () => void;
    importMarketAnalysis: (downloadMarketAnalysisDocument?: boolean) => void;
    isReadyForFirstImport: () => boolean;
    haveResultsBeenImported: () => boolean;
    resetMarketAnalysis: () => void;
    getTooltipForExportIcon: () => string;
    /**
     * Allows the user to enter the ID of an external market analysis by hand.
     *
     * Needed if the user broke the connection from autoiXpert to the external provider, e.g. by switching the report type.
     */
    openManualConnectionDialog: () => void;
    setSearchRadius: (searchRadius: MarketAnalysis['searchRadiusInKm']) => void;
    allowedSearchRadii:
        | UserPreferences['replacementValueSearchRadiusCartv'][]
        | UserPreferences['replacementValueSearchRadiusValuepilot'][]
        | UserPreferences['replacementValueSearchRadiusWinvalue'][];
}

interface WinvalueMarketAnalysisResponse {
    message: string;
    documentBase64: string;
    winvalueValuation: MarketAnalysis;
}

import { BillingAccount } from '../../models/billing/billing-account';
import { ContactPerson } from '../../models/contacts/contact-person';
import { BadRequest, ServerError } from '../../models/errors/ax-error';
import { Invoice } from '../../models/invoices/invoice';
import { InvoiceParameters } from '../../models/invoices/invoice-parameters';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { getContactPersonPlaceholderObject } from './get-contact-person-placeholder-object';
import { getUserPlaceholderObject } from './get-user-placeholder-object';
import { isValidBankAccount } from './is-valid-bank-account';
import { PlaceholderValuesBilling } from './placeholder-values.types';

export function getTemplatePlaceholderValuesBilling({
    team,
    user,
    invoice,
}: {
    team: Team;
    user: User;
    invoice?: Invoice | InvoiceParameters;
}): PlaceholderValuesBilling {
    //*****************************************************************************
    //  Bank Account
    //****************************************************************************/
    // The team's bank account is the default.
    let bankAccount: BillingAccount = team.invoicing.bankAccount;
    let secondBankAccount: BillingAccount = team.invoicing.secondBankAccount;
    let factoringEnabled = false;

    // If factoring is enabled either in the report's invoice parameters or the invoice itself, use the factoring provider's bank account. If it is undefined,
    // throw an error.
    if (invoice.factoringEnabled) {
        // The invoice's bank account has precedence. If this is an invoice parameters object (i.e. the bank account is missing), use the factoring provider's.
        if (!(invoice as Invoice).bankAccount || !isValidBankAccount((invoice as Invoice).bankAccount)) {
            if (!isValidBankAccount(user.preferences.factoringProvider.bankAccount)) {
                throw new BadRequest({
                    code: 'INVALID_BANK_ACCOUNT_FACTORING_PROVIDER',
                    message: `The factoring provider's bank account is invalid. Please specify an IBAN number.`,
                    data: {
                        bankAccountFactoringProvider: user.preferences.factoringProvider.bankAccount,
                    },
                });
            }
            bankAccount = user.preferences.factoringProvider.bankAccount;
        }
        factoringEnabled = true;
    }

    // Either a report's invoice parameters or an invoice itself might have a bank account written into it. That makes sense since autoiXpert
    // supports "booking" invoices which makes them unchangeable.
    if (invoice && 'bankAccount' in invoice && isValidBankAccount(invoice.bankAccount)) {
        bankAccount = invoice.bankAccount;
    }
    if (invoice && 'secondBankAccount' in invoice && isValidBankAccount(invoice.secondBankAccount)) {
        secondBankAccount = invoice.secondBankAccount;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bank Account
    /////////////////////////////////////////////////////////////////////////////*/

    try {
        // The IInvoiceParameters object does not contain an office location id -> Type check first.
        const officeLocationId: string = 'officeLocationId' in invoice ? invoice.officeLocationId : undefined;
        return {
            // Derived from the user in case no report is linked, e.g. for invoice documents, letters etc.
            Sachverständiger: getUserPlaceholderObject({
                user: user,
                team,
                officeLocationId,
            }),
            Bankverbindung: {
                IBAN: bankAccount.iban,
                BIC: bankAccount.bic,
                Kontoinhaber: bankAccount.owner,
                Bank: bankAccount.bankName,
            },
            ZweiteBankverbindung: {
                IBAN: secondBankAccount?.iban ?? '',
                BIC: secondBankAccount?.bic ?? '',
                Kontoinhaber: secondBankAccount?.owner ?? '',
                Bank: secondBankAccount?.bankName ?? '',
            },
            FactoringProvider: {
                erhältRechnung: factoringEnabled,
                ...getContactPersonPlaceholderObject(
                    ((user.preferences?.factoringProvider && user.preferences.factoringProvider?.contactPerson) ||
                        {}) as ContactPerson,
                ),
            },
            Steuern: {
                Steuernummer: team.vatId || '',
                UmsatzsteuerId: team.europeanVatId || '',
                SteuernummerOderUmsatzsteuerId: team.europeanVatId
                    ? `USt-IdNr.: ${team.europeanVatId}`
                    : team.vatId
                      ? `Steuernummer: ${team.vatId}`
                      : '',
            },
        };
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders failed. See data for details.`,
            data: {
                placeholderCategory: 'bankAccount',
            },
            error,
        });
    }
}

import { Report } from '../../../models/reports/report';
import { getVehicleValueGross } from '../../car-valuation/get-vehicle-value';

/**
 * In case there are corrections to the replacement value, this function calculates the actual replacement value
 * by taking the vehicle base value (initial replacement value before corrections) and adding/subtracting the corrections.
 */
export const calculateReplacementValueWithCorrections = (report: Report): number => {
    if (!report.valuation.replacementValueCorrectionConfig) {
        // If no corrections have been made -> return regular replacement value
        return getVehicleValueGross(report.valuation, 'replacementValue');
    }

    return (
        (report.valuation.vehicleBaseValue ?? 0) -
        (report.valuation.replacementValueCorrectionConfig.totalDecrease ?? 0) +
        (report.valuation.replacementValueCorrectionConfig.totalIncrease ?? 0)
    );
};

import { RequireSome } from '@autoixpert/helper-types/require-some';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';

/**
 * This is a reference object that links the document order with an actual document (DocumentMetadata).
 */
export class DocumentOrderItem {
    constructor(template?: RequireSome<DocumentOrderItem, '_id'>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    /**
     * Instead of using a custom ID, this is the Document Metadata ID to ensure that the offline-sync algorithm does not
     * create two entries when merging this array due to conflicts on different devices.
     */
    _id: DocumentMetadata['_id'];
    includedInFullDocument: boolean;
}

import { unescapeXml } from './escape-xml';

export function stripHtml(input: string): string {
    if (!input) {
        return '';
    }
    return unescapeXml(input.replace(/<[^>]+>/gi, '')).trim();
}

export function convertHtmlToPlainText(input: string): string {
    if (!input) {
        return '';
    }
    // Convert line breaks from Quill to plain text.
    const lineBreaksReplaced = input
        /**
         * Convert empty paragraphs (Quill: <p><br></p> or <p class="ql-justified"><br></p>)
         * or paragraph endings (regular </p> after a paragraph with text)
         * to two line breaks. to resemble a paragraph.
         */
        .replace(/(<p[^>]*><br>)?<\/p>/g, '\n\n')
        /**
         * All other breaks should be converted to regular line breaks. We added Shift+Enter as a regular break with a class to quill.
         * Those <br> tags will be caught here, too.
         */
        .replace(/<br[^>]*>/g, '\n');

    // Remove everything but line breaks, e.g. bold or italic type.
    return stripHtml(lineBreaksReplaced).trim();
}

export function convertPlainTextToHtml(plainText: string): string {
    if (!plainText) {
        return '';
    }

    /**
     * Convert 2 line breaks to an HTML paragraph.
     * Convert remaining line breaks to an HTML line break.
     */
    return `<p>${plainText.replace(/\n\n/g, '</p><p>').replace(/\n/g, '<br>')}</p>`;
}

import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    getDatVehicleValueByPriceType,
    getDatVehicleValueForPriceType,
} from '@autoixpert/lib/car-valuation/get-dat-vehicle-value-by-price-type';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { setReportDocumentInclusionStatusByType } from '@autoixpert/lib/documents/set-report-document-inclusion-status-by-type';
import {
    Translator,
    VehicleValueLabelGerman,
    VehicleValueLabelGermanShort,
} from '@autoixpert/lib/placeholder-values/translator';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { DatValuation } from '@autoixpert/models/reports/market-value/dat-valuation';
import { ValuationVehicleValueType } from '@autoixpert/models/reports/market-value/valuation';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { getAudatexValuationExportTooltip } from 'src/app/shared/libraries/audatex-helpers/get-audatex-valuation-export-tooltip';
import { getSelectedAudatexValuationResult } from 'src/app/shared/libraries/audatex-helpers/get-selected-audatex-valuation-result';
import { isAudatexValuationInputDataComplete } from 'src/app/shared/libraries/audatex-helpers/is-audatex-valuation-input-data-complete';
import { isAudatexValuationPossible } from 'src/app/shared/libraries/audatex-helpers/is-audatex-valuation-possible';
import { getDatValuationExportTooltip } from 'src/app/shared/libraries/dat-helpers/get-dat-valuation-export-tooltip';
import { getDatValuationOpenerTooltip } from 'src/app/shared/libraries/dat-helpers/get-dat-valuation-opener-tooltip';
import { getValuationPriceLabelTooltip } from 'src/app/shared/libraries/dat-helpers/get-valuation-price-label-tooltip';
import { isDatValuationInputDataComplete } from 'src/app/shared/libraries/dat-helpers/is-dat-valuation-input-data-complete';
import { isDatValuationPossible } from 'src/app/shared/libraries/dat-helpers/is-dat-valuation-possible';
import { AudatexTaskService } from 'src/app/shared/services/audatex/audatex-task.service';
import { DatValuationService } from 'src/app/shared/services/dat-valuation.service';
import { ReportDetailsService } from 'src/app/shared/services/report-details.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import { SetBaseValuePayload } from '../valuation.component';

@Component({
    selector: 'dat-or-audatex-valuation',
    templateUrl: './dat-or-audatex-valuation.component.html',
    styleUrls: ['./dat-or-audatex-valuation.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class DatOrAudatexValuationComponent {
    constructor(
        private router: Router,
        public changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private reportDetailsService: ReportDetailsService,
        public userPreferences: UserPreferencesService,
        public datValuationService: DatValuationService,
        public audatexTaskService: AudatexTaskService,
        // NgZone must stay here although the IDE may mark it as unused, because it's required on the component passed to openValuationUI().
        public ngZone: NgZone,
    ) {}
    @Input() report: Report;
    @Input() user: User;
    @Input() team: Team;

    @Output() baseValueSelected = new EventEmitter<SetBaseValuePayload>();

    // Vehicle valuation via a calculation provider.
    public datValuationRequestPending = false;
    public audatexValuationRequestPending = false;

    // Reference date label
    public referenceDateLabel = '';

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit(): void {
        this.subscribeDatCalculationPendingState();
        this.subscribeAudatexPendingState();
        this.initializeReferenceDate();
    }
    private subscribeDatCalculationPendingState(): void {
        this.subscriptions.push(
            this.datValuationService.isRequestPending$$.subscribe(
                (pending) => (this.datValuationRequestPending = pending),
            ),
        );
    }

    private subscribeAudatexPendingState(): void {
        this.subscriptions.push(
            this.audatexTaskService.isRequestPending$$.subscribe(
                (pending) => (this.audatexValuationRequestPending = pending),
            ),
        );
    }

    private initializeReferenceDate(): void {
        if (
            !this.report.valuation.referenceDate ||
            (this.selectedValuationProvider === 'dat' && !this.report.valuation.datValuation.referenceDate) ||
            (this.selectedValuationProvider === 'audatex' && !this.report.valuation.audatexValuation.referenceDate)
        ) {
            if (this.report.visits[0].date) {
                this.report.valuation.referenceDate = this.report.visits[0].date;
                this.referenceDateLabel = 'Besichtigungsdatum: ';
            } else {
                this.report.valuation.referenceDate = todayIso();
            }
            if (this.selectedValuationProvider === 'dat') {
                this.report.valuation.datValuation.referenceDate = this.report.valuation.referenceDate;
            }
            if (this.selectedValuationProvider === 'audatex') {
                this.report.valuation.audatexValuation.referenceDate = this.report.valuation.referenceDate;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Getter for UI
    //****************************************************************************/
    get selectedValuationProvider() {
        if (this.identificationProvider === 'audatex' && this.valuationProvider !== 'dat') {
            return 'audatex';
        }
        if (this.identificationProvider === 'dat' && this.valuationProvider !== 'audatex') {
            return 'dat';
        }
    }
    get identificationProvider() {
        return this.report.car.identificationProvider;
    }
    get valuationProvider() {
        return this.report.valuation.valuationProvider;
    }
    get datValuationConnected(): boolean {
        return !!this.report.valuation.datValuation?.dossierId;
    }
    get datValuationRetrieved(): boolean {
        return this.report.valuation.datValuation?.valuesRetrieved;
    }
    get audatexValuationConnected(): boolean {
        return !!this.report.valuation.audatexValuation?.documentHash;
    }
    get audatexTaskConnected(): boolean {
        return !!this.report.audatexTaskId;
    }
    get audatexValuationRetrieved(): boolean {
        return this.report.valuation.audatexValuation?.valuesRetrieved;
    }
    get valuationExcludedFromReport(): boolean {
        return (
            (this.datValuationConnected && this.valuationProvider !== 'dat') ||
            (this.audatexValuationRetrieved && this.valuationProvider !== 'audatex')
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Getter for UI
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Reference Date
    //****************************************************************************/
    get referenceDate(): IsoDate {
        if (this.selectedValuationProvider === 'dat' && this.report.valuation.datValuation.referenceDate) {
            return this.report.valuation.datValuation.referenceDate;
        }

        if (this.selectedValuationProvider === 'audatex' && this.report.valuation.audatexValuation.referenceDate) {
            return this.report.valuation.audatexValuation.referenceDate;
        }

        return this.report.valuation.referenceDate;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reference Date
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DAT Valuation
    //****************************************************************************/
    get salesPriceLabel() {
        if (
            getDatVehicleValueForPriceType(this.report.valuation.datValuation?.vehicleValueType) === 'dealerSalesPrice'
        ) {
            return this.translateVehicleValueType(this.report.valuation.datValuation?.vehicleValueType);
        }
    }
    get purchasePriceLabel() {
        if (
            getDatVehicleValueForPriceType(this.report.valuation.datValuation?.vehicleValueType) ===
            'dealerPurchasePrice'
        ) {
            return this.translateVehicleValueType(this.report.valuation.datValuation?.vehicleValueType);
        }
    }

    protected isDatUserComplete = isDatUserComplete;
    protected isDatValuationInputDataComplete = isDatValuationInputDataComplete;
    protected isDatValuationPossible = isDatValuationPossible;
    protected getDatValuationExportTooltip = getDatValuationExportTooltip;
    protected getDatValuationOpenerTooltip = getDatValuationOpenerTooltip;
    protected getDatVehicleValueByPriceType = getDatVehicleValueByPriceType;
    protected getValuationPriceLabelTooltip = getValuationPriceLabelTooltip;

    public async openDatValuation() {
        // Create a DAT valuation if it does not exist yet.
        if (!this.report.valuation.datValuation.dossierId) {
            await this.createDatValuation();
        }

        // Open the DAT valuation UI.
        this.datValuationService.openValuationUI({
            report: this.report,
            triggeringComponent: this,
            onFinish: () => this.retrieveDatValuationResults(),
        });
    }

    private async createDatValuation() {
        try {
            await this.datValuationService.create({ report: this.report });
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                report: this.report,
                axError: error,
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body:
                        `Die Bewertung konnte nicht erstellt werden.` +
                        (error.data?.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                    partnerLogo: 'dat',
                }),
            });
        }
    }

    private async updateDatValuation() {
        try {
            await this.datValuationService.updateDossier({ report: this.report });
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body:
                        `Die Bewertung konnte nicht erstellt werden.` +
                        (error.data && error.data.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                    partnerLogo: 'dat',
                }),
            });
        }
    }

    async retrieveDatValuationResults() {
        try {
            await this.datValuationService.retrieveResults({ report: this.report });

            // Shorthands
            const valuation = this.report.valuation;
            const datValuation = this.report.valuation.datValuation;

            // Copy original prices (list prices) from DAT to the main valuation (results).
            valuation.originalPriceWithoutEquipmentNet = datValuation.originalPriceWithoutEquipmentNet;
            valuation.originalPriceWithoutEquipmentGross = datValuation.originalPriceWithoutEquipmentGross;
            valuation.originalPriceWithEquipmentNet = datValuation.originalPriceWithEquipmentNet;
            valuation.originalPriceWithEquipmentGross = datValuation.originalPriceWithEquipmentGross;
            this.setDatVehicleValue();
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: (error) => ({
                    title: 'Import nicht möglich',
                    body:
                        `Die Bewertung konnten nicht importiert werden.` +
                        (error.data && error.data.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                }),
            });
        }
    }

    public async executeDatQuickValuation(): Promise<void> {
        await this.createDatValuation();
        await this.retrieveDatValuationResults();
    }

    /**
     * Update the requested taxation type and retrieve the valuation.
     */
    public async setDatValuationTaxationType(taxationType: DatValuation['requestedTaxationType']) {
        if (taxationType === 'neutral' && !this.neutralTaxationTypeAllowedWithDat()) {
            this.toastService.error(
                'Neutrale Besteuerung nicht möglich',
                'Die neutrale Besteuerung ist nur für den Wert "Wiederbeschaffungswert" verfügbar.',
            );
            return;
        }
        this.report.valuation.datValuation.requestedTaxationType = taxationType;
        await this.saveReportAndRetrieveDATValuationResults();
    }

    /**
     * Taxation type "neutral" is only available for replacement value.
     */
    public async setDATTaxationTypeForValueType() {
        if (
            this.report.valuation.datValuation.requestedTaxationType === 'neutral' &&
            this.report.valuation.datValuation.vehicleValueType !== 'replacementValue'
        ) {
            this.report.valuation.datValuation.requestedTaxationType = 'margin';
            this.toastService.info(
                'Besteuerung auf "Differenz" gesetzt',
                'Die DAT bietet die neutrale Besteuerung (Privatmarkt) nur für den Wert "Wiederbeschaffungswert".',
            );
        }
        await this.saveReportAndRetrieveDATValuationResults();
    }

    public async saveReportAndRetrieveDATValuationResults() {
        if (this.datValuationConnected) {
            await this.updateDatValuation();
            await this.retrieveDatValuationResults();
        } else {
            await this.saveReport();
        }
    }

    public showDatValuationSettingsDialog(): void {
        this.datValuationService.openSettingsDialog({ report: this.report });
    }

    /**
     * Valuation reports include the DAT valuation in the report itself.
     * If a user decides to use manual values, the DAT valuation document may be added to the reports documents manually.
     */
    public addDatValuationToDocuments({ displayToast = false } = {}) {
        this.datValuationService.addDatMarketAnalysisDocument({ report: this.report });
        this.saveReport();
        if (displayToast) {
            const toast = this.toastService.success(
                `Dokument erstellt`,
                'Du findest die DAT-Bewertung als separates Dokument im Reiter <i>Druck & Versand</i>.<br><br>Zum Öffnen diese Nachricht klicken.',
            );
            const subscription = toast.click.subscribe(() => {
                subscription.unsubscribe();
                this.router.navigate(['Gutachten', this.report._id, 'Druck-und-Versand']);
            });
        }
    }

    /**
     * Send valuation data to the DAT. Only execute if contract ID is present. Otherwise, the request would return a "not found" error.
     */
    public async transmitDatValuation(forceUpdate?: boolean): Promise<void> {
        try {
            await this.datValuationService.updateDossier({ report: this.report, forceUpdate });
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'Bewertungsdaten nicht aktualisiert',
                    body: 'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    partnerLogo: 'dat',
                },
            });
        }
    }

    /**
     * Clear all imported data without deleting the DAT valuation from DAT.de
     */
    public unlinkDatValuation(): void {
        this.datValuationService.unlink({ report: this.report });
    }

    public async openDatValuationConnectionDialog() {
        try {
            await this.datValuationService.openDossierConnectionDialog({ report: this.report });
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'DAT-Vorgang nicht verbunden',
                    body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        }
    }

    /**
     * Reset the damage calculation. Only execute if there are values present.
     */
    public async resetDatValuation(): Promise<void> {
        await this.datValuationService.reset({ report: this.report, options: { showConfirmDialog: true } });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT Valuation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  AUDATEX Valuation
    //****************************************************************************/
    protected isAudatexUserComplete = isAudatexUserComplete;
    protected isAudatexValuationPossible = isAudatexValuationPossible;
    protected isAudatexValuationInputDataComplete = isAudatexValuationInputDataComplete;
    protected getAudatexValuationExportTooltip = getAudatexValuationExportTooltip;
    protected getSelectedAudatexValuationResult = getSelectedAudatexValuationResult;

    /**
     * Open Audatex valuation.
     * If a task has not been created yet, create it here.
     */
    public async openAudatexValuation() {
        if (
            !isAudatexUserComplete(this.user) ||
            !isAudatexValuationInputDataComplete(this.report) ||
            !isAudatexValuationPossible(this.report) ||
            (this.isReportLocked() && !this.report.audatexTaskId)
        ) {
            this.toastService.partnerError(
                'Audatex-Bewertung nicht möglich',
                getAudatexValuationExportTooltip({ report: this.report, user: this.user }),
                'audatex',
            );
            return;
        }

        if (!this.isReportLocked()) {
            /**
             * Create Audatex task if necessary. Then open valuation.
             */
            // Does a task exist?
            if (!this.report.audatexTaskId) {
                // No -> Create task, then open valuation
                try {
                    await this.audatexTaskService.create({ report: this.report });
                } catch (error) {
                    this.audatexValuationRequestPending = false;
                    this.audatexTaskService.handleAndRethrow({
                        axError: error,
                        report: this.report,
                        defaultHandler: {
                            title: 'Bewertungsdaten nicht übertragen',
                            body: 'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        },
                    });
                }
            }
            // Update task, then open valuation
            try {
                await this.audatexTaskService.update({
                    report: this.report,
                });
            } catch (error) {
                this.audatexValuationRequestPending = false;
                this.audatexTaskService.handleAndRethrow({
                    axError: error,
                    report: this.report,
                    defaultHandler: {
                        title: 'Bewertungsdaten nicht übertragen',
                        body: 'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }
        }

        await this.audatexTaskService.openUI({ report: this.report, step: 'VehicleIdentification' });
    }

    public async importAudatexValuation({
        automaticImport = false,
    }: {
        // Set to true if not the user triggered the import but a window focus event.
        automaticImport?: boolean;
    } = {}): Promise<void> {
        try {
            await this.audatexTaskService.retrieveResults({
                report: this.report,
                requireValuation: true,
                requireCalculation: false,
            });

            // Get the Audatex valuation results and map to dealer purchase or dealer sales price.
            const { valueNet, valueGross, taxationType, type, isDealerPurchase } = getSelectedAudatexValuationResult({
                report: this.report,
            });

            if (isDealerPurchase) {
                this.setAudatexVehicleValue({
                    purchaseNet: valueNet,
                    purchaseGross: valueGross,
                    purchaseValueProvider: 'audatex',
                    salesNet: null,
                    salesGross: null,
                    dealerSalesTaxationType: taxationType,
                    valueType: type,
                });
            } else {
                this.setAudatexVehicleValue({
                    purchaseNet: null,
                    purchaseGross: null,
                    salesNet: valueNet,
                    salesGross: valueGross,
                    salesValueProvider: 'audatex',
                    dealerSalesTaxationType: taxationType,
                    valueType: type,
                });
            }
        } catch (error) {
            /**
             * Errors in an automatic import should not be shown to the user. When the user switches between his photos and the calculation,
             * he would otherwise be shown errors within autoiXpert all the time.
             */
            if (!automaticImport) {
                this.audatexTaskService.handleAndRethrow({
                    axError: error,
                    report: this.report,
                    defaultHandler: {
                        title: 'Audatex-Bewertung nicht importiert',
                        body: 'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        partnerLogo: 'audatex',
                    },
                });
            } else {
                this.audatexTaskService.abortPendingIndicator();
            }
        }
    }

    public async removeAudatexValuation() {
        if (this.isReportLocked()) return;
        // Clear valuation
        this.audatexTaskService.unlink({ report: this.report });
    }

    /**
     * Valuation reports include the Audatex valuation document in the report itself.
     * If a user decides to use manual values, the Audatex valuation document is excluded from the final document and may be added to the reports documents manually.
     */
    public addAudatexValuationToDocuments({ displayToast = false } = {}) {
        this.audatexTaskService.addAudatexValuationDocument({ report: this.report });
        this.saveReport();
        if (displayToast) {
            const toast = this.toastService.success(
                `Dokument erstellt`,
                'Du findest die Audatex-Bewertung als separates Dokument im Reiter <i>Druck & Versand</i>.<br><br>Zum Öffnen diese Nachricht klicken.',
            );
            const subscription = toast.click.subscribe(() => {
                subscription.unsubscribe();
                this.router.navigate(['Gutachten', this.report._id, 'Druck-und-Versand']);
            });
        }
    }

    public async openAudatexValuationConnectionDialog() {
        try {
            await this.audatexTaskService.openTaskConnectionDialog({ report: this.report });
            await this.importAudatexValuation();
        } catch (error) {
            this.audatexTaskService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'Audatex-Vorgang nicht verbunden',
                    body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END AUDATEX Valuation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Both providers
    //****************************************************************************/
    public translateVehicleValueType(
        valueType: ValuationVehicleValueType,
    ): VehicleValueLabelGerman | VehicleValueLabelGermanShort {
        return Translator.vehicleValueLabel(valueType);
    }

    public neutralTaxationTypeAllowedWithDat(): boolean {
        return (
            this.report.valuation.datValuation.vehicleValueType === 'replacementValue' ||
            !this.report.valuation.datValuation.vehicleValueType
        );
    }

    public setDatVehicleValue() {
        this.baseValueSelected.emit({
            purchaseNet: this.report.valuation.datValuation.dealerPurchasePrice,
            purchaseGross: this.report.valuation.datValuation.dealerPurchasePriceGross,
            salesNet: this.report.valuation.datValuation.dealerSalesPrice,
            salesGross: this.report.valuation.datValuation.dealerSalesPriceGross,
            purchaseValueProvider: 'dat',
            salesValueProvider: 'dat',
            valueType: this.report.valuation.datValuation.vehicleValueType,
            referenceDate: this.report.valuation.datValuation.referenceDate,
            dealerSalesTaxationType: this.report.valuation.datValuation.requestedTaxationType,
        });
    }

    public setDatWebScanValue() {
        this.baseValueSelected.emit({
            salesGross: this.report.valuation.datValuation.webscanGross,
            salesNet: this.report.valuation.datValuation.webscanNet,
            valueType: 'marketValue',
            dealerSalesTaxationType: 'margin',
            salesValueProvider: 'datWebscan',
        });
    }

    public setAudatexVehicleValue(audatexPayload: Omit<SetBaseValuePayload, 'referenceDate'>) {
        this.baseValueSelected.emit({
            ...audatexPayload,
            referenceDate: this.report.valuation.datValuation.referenceDate,
        });
    }

    /**
     * Valuation reports contain the valuation providers document in the report itself.
     * If a user changes the vehicle value in autoiXpert, he may want to exclude the DAT valuation.
     *
     * Do not exclude the valuation automatically since a user may want to round the values manually and keep the original document in the report.
     * Do not delete the original valuation since a user may want to switch back to it.
     */
    public excludeDatOrAudatexValuationFromReport() {
        if (this.isReportLocked()) {
            return;
        }
        if (this.report.type === 'valuation') {
            // Deactivate the valuation documents in print and transmission (if they exist)
            if (this.report.valuation.valuationProvider === 'dat') {
                setReportDocumentInclusionStatusByType({
                    report: this.report,
                    documentGroup: 'report',
                    includedInFullDocument: false,
                    documentType: 'datMarketAnalysis',
                });
                setReportDocumentInclusionStatusByType({
                    report: this.report,
                    documentGroup: 'report',
                    includedInFullDocument: false,
                    documentType: 'datValuationProtocol',
                });
            } else if (this.report.valuation.valuationProvider === 'audatex') {
                setReportDocumentInclusionStatusByType({
                    report: this.report,
                    documentGroup: 'report',
                    includedInFullDocument: false,
                    documentType: 'audatexMarketAnalysis',
                });
            }

            this.report.valuation.valuationProvider = 'custom';
            this.saveReport();
        }
    }
    public includeDatValuationIntoReport() {
        if (this.isReportLocked()) {
            return;
        }
        if (this.report.type === 'valuation') {
            setReportDocumentInclusionStatusByType({
                report: this.report,
                documentGroup: 'report',
                includedInFullDocument: true,
                documentType: 'datMarketAnalysis',
            });
            setReportDocumentInclusionStatusByType({
                report: this.report,
                documentGroup: 'report',
                includedInFullDocument: true,
                documentType: 'datValuationProtocol',
            });
            this.report.valuation.valuationProvider = 'dat';
            this.saveReport();
        }
    }
    public includeAudatexValuationIntoReport() {
        if (this.isReportLocked()) {
            return;
        }
        if (this.report.type === 'valuation') {
            setReportDocumentInclusionStatusByType({
                report: this.report,
                documentGroup: 'report',
                includedInFullDocument: true,
                documentType: 'audatexMarketAnalysis',
            });
            this.report.valuation.valuationProvider = 'audatex';
            this.saveReport();
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Both providers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Relative vehicle value graphics
    //****************************************************************************/

    get relativeVehicleValueGraphics() {
        const gfxData = {
            // Listenpreis
            newPrice: null,
            newPriceLabel: '',
            // Einkaufspreis
            purchasePrice: null,
            purchaseLabel: '',
            relativePurchase: null,
            // Verkaufspreis
            salesPrice: null,
            salesLabel: '',
            relativeSales: null,
            // Handelsspanne
            tradeMarginGrossPercent: null,
        };

        // Audatex valuation results are a mess. Therefore we only show the relative values for DAT valuations.
        if (this.selectedValuationProvider !== 'dat') {
            return gfxData;
        }

        // Shorthand
        const datValuation = this.report.valuation.datValuation;

        const showNet = this.mayCarOwnerDeductTaxes(this.report);

        gfxData.newPrice = showNet
            ? datValuation.originalPriceWithEquipmentNet
            : datValuation.originalPriceWithEquipmentGross;

        gfxData.salesPrice = showNet ? datValuation.dealerSalesPrice : datValuation.dealerSalesPriceGross;

        gfxData.purchasePrice = showNet ? datValuation.dealerPurchasePrice : datValuation.dealerPurchasePriceGross;

        gfxData.newPriceLabel = 'Neupreis' + (showNet ? ' (netto)' : ' (brutto)');

        gfxData.purchaseLabel = 'Händlereinkaufspreis' + (showNet ? ' (netto)' : ' (brutto)');
        gfxData.relativePurchase = `${Math.round((gfxData.purchasePrice / gfxData.newPrice) * 100)}%`;

        gfxData.salesLabel = 'Händlerverkaufspreis' + (showNet ? ' (netto)' : ' (brutto)');
        gfxData.relativeSales = `${Math.round((gfxData.salesPrice / gfxData.newPrice) * 100)}%`;

        gfxData.tradeMarginGrossPercent =
            ((datValuation.dealerSalesPriceGross - datValuation.dealerPurchasePriceGross) /
                datValuation.dealerSalesPriceGross) *
            100;

        return gfxData;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Relative vehicle value graphics
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Util
    //****************************************************************************/
    protected mayCarOwnerDeductTaxes = mayCarOwnerDeductTaxes;
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }
    /**
     * Save reports to the server.
     */
    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        if (this.isReportLocked()) {
            return;
        }

        return this.reportDetailsService.patch(this.report, { waitForServer }).catch((error) => {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
            throw error;
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Util
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    @HostListener('window:visibilitychange', ['$event'])
    protected importPendingMarketAnalyses() {
        if (document.visibilityState === 'visible') {
            // Import Audatex valuation
            if (
                this.report.audatexTaskId &&
                this.report.valuation?.valuationProvider === 'audatex' &&
                !this.report.valuation.audatexValuation.documentHash
            ) {
                this.importAudatexValuation({ automaticImport: true });
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

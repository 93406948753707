import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { Report } from '@autoixpert/models/reports/report';

export function translateReportTabName(tabName: ReportTabName, reportType: Report['type']): string {
    switch (tabName) {
        case 'accidentData':
            switch (reportType) {
                case 'valuation':
                    return 'Kunde';
                case 'leaseReturn':
                    return 'Beteiligte';
                case 'usedVehicleCheck':
                    return 'Beteiligte';
                case 'oldtimerValuationSmall':
                    return 'Kunde';
                default:
                    return 'Unfall & Beteiligte';
            }
        case 'carData':
            return 'Fahrzeugauswahl';
        case 'carCondition':
        case 'carConditionOldtimer':
            return 'Zustand';
        case 'photos':
            return 'Fotos';
        case 'damageCalculation':
            // Invoice Audit only requires the garage, not the rest of the screen. Therefore, rename it.
            if (reportType === 'invoiceAudit') {
                return 'Werkstatt';
            }

            return 'Kalkulation';
        case 'valuation':
            return 'Bewertung';
        case 'valuationOldtimer':
            return 'Bewertung';
        case 'leaseReturnChecklist':
            return 'Prüfung';
        case 'invoiceAudit':
            return 'Rechnungsprüfung';
        case 'fees':
            return 'Rechnung';
        case 'printAndTransmission':
            return 'Druck & Versand';
        default:
            break;
    }
}

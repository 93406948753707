import { insertAfterArrayElement } from '@autoixpert/lib/arrays/insert-after-array-element';
import { DefaultDocumentOrderItem } from '@autoixpert/models/teams/default-document-order/default-document-order-item';
import {
    DocumentMetadata,
    DocumentType,
    documentTypes,
    standardDefaultDocumentOrderTypes,
} from '../models/documents/document-metadata';

const hiddenInvoiceDocuments: DocumentType[] = [
    'paymentReminderLevel0',
    'paymentReminderLevel1',
    'paymentReminderLevel2',
    'paymentReminderLevel3',
];

/**
 * This function ensures that all known document types are included in the teams full document config.
 */
export function addMissingReportDocumentTypesToDefaultDocumentOrderItems(
    defaultDocumentOrderItems: DefaultDocumentOrderItem[],
): number {
    let addedDocumentOrderItems = 0;

    for (const documentType of documentTypes) {
        // Skip manually uploaded document -> we handle these separately on update
        if (documentType === 'manuallyUploadedPdf') {
            continue;
        }

        // Skip invoice documents
        if (hiddenInvoiceDocuments.includes(documentType)) {
            continue;
        }

        const isIncluded = defaultDocumentOrderItems.some(
            (defaultDocumentOrderItem) => defaultDocumentOrderItem.documentType === documentType,
        );
        if (!isIncluded) {
            insertDocumentTypeIntoDefaultDocumentOrderItems(defaultDocumentOrderItems, documentType);
            addedDocumentOrderItems++;
        }
    }

    return addedDocumentOrderItems;
}

/**
 * InsertDocumentTypeIntoFullDocumentConfig using the systems defaultFullDocumentOrder
 * inserts in place (push, unshift or splice)
 */
function insertDocumentTypeIntoDefaultDocumentOrderItems(
    defaultDocumentOrderItems: DefaultDocumentOrderItem[],
    documentType: DocumentMetadata['type'],
): void {
    const indexInDefault = standardDefaultDocumentOrderTypes.indexOf(documentType);
    const defaultDocumentOrderItem: DefaultDocumentOrderItem = new DefaultDocumentOrderItem({
        documentType: documentType,
        includedInFullDocument: true,
    });

    if (indexInDefault < 0) {
        defaultDocumentOrderItems.push(defaultDocumentOrderItem);
        return;
    }

    /**
     * DocumentType is first
     * Prepend to the start of the fullDocumentConfig.
     */
    if (indexInDefault === 0) {
        defaultDocumentOrderItems.unshift(defaultDocumentOrderItem);
        return;
    }

    /**
     * DocumentType is somewhere in the array
     * Find the previous documentType and insert after. If previous is missing, try the next before.
     */
    let prevIndexInDefault = indexInDefault - 1;
    while (prevIndexInDefault >= 0) {
        const documentTypeBefore = standardDefaultDocumentOrderTypes[prevIndexInDefault];
        const elementBefore: DefaultDocumentOrderItem = defaultDocumentOrderItems.find(
            (defaultDocumentOrderItem) => defaultDocumentOrderItem.documentType === documentTypeBefore,
        );

        if (elementBefore) {
            insertAfterArrayElement({
                array: defaultDocumentOrderItems,
                referenceElement: elementBefore,
                newElement: defaultDocumentOrderItem,
            });
            return;
        }
        prevIndexInDefault--;
    }

    /**
     * No previous document found. Prepend to the start of the default document order items so that the user sees the item immediately when
     * opening the list config.
     */
    defaultDocumentOrderItems.unshift(defaultDocumentOrderItem);
}

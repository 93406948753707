import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { Subtype } from '@autoixpert/helper-types/subtype';
import { findRecordById } from '@autoixpert/lib/arrays/find-record-by-id';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { addMissingDocumentOrdersToInvoice } from '@autoixpert/lib/documents/add-missing-document-orders-to-invoice';
import { getDocumentOrderForRecipient } from '@autoixpert/lib/documents/get-document-order-for-recipient';
import { generateId } from '@autoixpert/lib/generate-id';
import { computeInvoiceTotalGross, computeInvoiceTotalNet } from '@autoixpert/lib/invoices/compute-invoice-total';
import { determineDocumentMetadataTitle } from '@autoixpert/lib/invoices/determine-document-metadata-title';
import { getInvoiceReportData } from '@autoixpert/lib/invoices/get-invoice-report-data-from-report';
import { InvoiceTypeGerman, getInvoiceTypeGerman } from '@autoixpert/lib/invoices/get-invoice-type-german';
import { getPaymentStatus } from '@autoixpert/lib/invoices/get-payment-status';
import { getUnpaidAmount } from '@autoixpert/lib/invoices/get-unpaid-amount';
import { removeDocumentOrderFromInvoice } from '@autoixpert/lib/invoices/remove-document-order-from-invoice';
import { setPaymentStatus } from '@autoixpert/lib/invoices/set-payment-status';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { RenderedDocumentType } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { Invoice, InvoiceInvolvedParty, InvoiceInvolvedPartyRole } from '@autoixpert/models/invoices/invoice';
import { InvoiceLineItemTemplate } from '@autoixpert/models/invoices/invoice-line-item-template';
import { InvoiceParameters } from '@autoixpert/models/invoices/invoice-parameters';
import { InvoiceTemplate } from '@autoixpert/models/invoices/invoice-template';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Report } from '@autoixpert/models/reports/report';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { InvoiceLineItemTemplateService } from 'src/app/shared/services/invoice-line-item-template.service';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { runChildAnimations } from '../../shared/animations/run-child-animations.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { MatQuillComponent } from '../../shared/components/mat-quill/mat-quill.component';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { getVatRate } from '../../shared/libraries/get-vat-rate-2020';
import { calculateDueDate } from '../../shared/libraries/invoices/calculate-due-date';
import { updateScreenTitleInvoice } from '../../shared/libraries/invoices/update-screen-title-invoice';
import { convertHtmlToPlainText } from '../../shared/libraries/strip-html';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { DownloadService } from '../../shared/services/download.service';
import { InvoiceDetailsService } from '../../shared/services/invoice-details.service';
import { InvoiceNumberService } from '../../shared/services/invoice-number.service';
import { InvoiceTemplateService } from '../../shared/services/invoice-template.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { ReportService } from '../../shared/services/report.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserService } from '../../shared/services/user.service';
import { lineItemUnitsStatic } from '../../shared/static-data/line-item-units';

@Component({
    selector: 'invoice-editor',
    templateUrl: 'invoice-editor.component.html',
    styleUrls: ['invoice-editor.component.scss'],
    animations: [slideInAndOutVertically(), runChildAnimations()],
})
export class InvoiceEditorComponent implements OnInit, OnDestroy {
    constructor(
        private invoiceService: InvoiceService,
        private invoiceDetailsService: InvoiceDetailsService,
        private router: Router,
        private route: ActivatedRoute,
        private toastService: ToastService,
        private invoiceNumberService: InvoiceNumberService,
        private screenTitleService: ScreenTitleService,
        private apiErrorService: ApiErrorService,
        private downloadService: DownloadService,
        private invoiceTemplateService: InvoiceTemplateService,
        private reportService: ReportService,
        private userService: UserService,
        private loggedInUserService: LoggedInUserService,
        private networkStatusService: NetworkStatusService,
        private dialog: MatDialog,
        private invoiceLineItemTemplateService: InvoiceLineItemTemplateService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    @ViewChildren('descriptionInput') descriptionInputs: QueryList<MatQuillComponent>;

    public invoice: Invoice;
    public invoiceTemplates: InvoiceTemplate[] = [];
    public user: User;
    public team: Team;
    private subscriptions: Subscription[] = [];

    public selectedInvoiceTemplate: InvoiceTemplate;

    // View
    public invoicePreviewPending: boolean = false;
    public invoiceDownloadPending: boolean = false;
    public invoiceTemplatesShown: boolean = false;
    public invoiceTemplateTitleDialogShown: boolean = false;
    public paymentsDialogShown: boolean = false;
    public paymentReminderDialogShown: boolean = false;
    public invoiceNumberGenerationPending: boolean = false;
    public showInvoiceIntroTextTemplates: boolean;

    // Unit autocomplete
    public lineItemUnits: LineItemUnitAutocompleteEntry[] = lineItemUnitsStatic;
    public filteredLineItemUnits: LineItemUnitAutocompleteEntry[] = [];
    public invoiceLineItemTemplates: InvoiceLineItemTemplate[] = [];

    // Invoice number journal entry
    public lastInvoiceNumber: string;

    // Invoice Cancellation
    // The invoice canceled by this invoice.
    public canceledInvoice: Invoice = null;
    public canceledInvoiceNotFound: boolean;

    // Report connection
    public reportConnectionInputShown: boolean = false;
    public reportSearchPending: boolean = false;
    public reportSearchTerm: string = null;
    public reportSearchTerm$: Subject<string> = new Subject<string>();
    public reports: Report[] = [];
    public filteredReports: Report[] = [];
    public reportSearchTermSubscription: Subscription;
    public connectedReports: Report[];
    public extendLawyer: boolean = false;
    public extendInsurance: boolean = false;

    // Assessor (for collective invoice)
    public assessors: User[] = [];
    public deactivatedAssessors: User[] = [];

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        /**
         * The route parameter changes when a user clicks on a related invoice, e.g. a cancellation invoice in the status box.
         */
        const routeSubscription = this.route.parent.params.subscribe(async (params) => {
            try {
                this.invoice = await this.invoiceDetailsService.get(params['invoiceId']);

                if (this.invoice.isCollectiveInvoice) {
                    this.loadAssessorsFromTeam();
                }
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Rechnung nicht geladen',
                        body: 'Bitte kontaktiere die aX Hotline',
                    },
                });
            }

            if (this.invoice) {
                this.showTemplatesIfInvoiceEmpty();
                await this.getConnectedReport();

                updateScreenTitleInvoice({
                    invoice: this.invoice,
                    screenTitleService: this.screenTitleService,
                });

                // No connected report? Show input to search one.
                if (!(this.invoice.reportIds?.length > 0)) {
                    this.showReportConnectionInput();
                }

                this.determineVatRate();
                await this.getCancelledInvoice();
            }
            this.getInvoiceTemplates();
        });

        this.subscriptions.push(routeSubscription);

        this.registerWebsocketEventHandlers();

        this.invoiceLineItemTemplateService
            .find()
            .toPromise()
            .then((templates) => (this.invoiceLineItemTemplates = templates));
    }

    protected getInvoiceTypeGerman(invoice: Invoice = this.invoice): InvoiceTypeGerman {
        if (!invoice) return undefined;

        return getInvoiceTypeGerman(invoice);
    }

    private loadAssessorsFromTeam() {
        const allAssessors = this.userService.getAllTeamMembersFromCache();

        this.assessors = allAssessors.filter((teamMember) => teamMember.active && teamMember.isAssessor);
        this.deactivatedAssessors = allAssessors.filter((teamMember) => !teamMember.active && teamMember.isAssessor);

        /**
         * If not a single assessor could be found, the service must still be waiting for the server response. Try again later.
         */
        if (!this.assessors.length) {
            window.setTimeout(() => this.loadAssessorsFromTeam(), 500);
        }
    }

    //*****************************************************************************
    //  Invoice Head
    //****************************************************************************/

    public insertContactPersonIntoInvoice(contactPerson: ContactPerson): void {
        this.invoice.recipient.contactPerson = contactPerson;
        void this.saveInvoice();
        updateScreenTitleInvoice({
            invoice: this.invoice,
            screenTitleService: this.screenTitleService,
        });
    }

    public determineVatRate(): void {
        if (this.isInvoiceLocked()) return;

        // If the invoice already has a VAT rate, including zero, don't reset it.
        if (this.invoice.vatRate != null) return;

        // Cannot determine with empty dates
        if (!this.invoice.dateOfSupply && !this.invoice.date) return;

        if (this.team.invoicing.vatRate === 0.19) {
            this.setVatRate(getVatRate(this.invoice.dateOfSupply || this.invoice.date));
        } else if (this.team.invoicing.vatRate === 0) {
            this.setVatRate(this.team.invoicing.vatRate, 'smallBusiness');
        } else {
            this.setVatRate(this.team.invoicing.vatRate);
        }
    }

    public isCancellationInvoiceOrCancelled(invoice: Invoice): boolean {
        return getPaymentStatus(invoice) === 'fullCancellation' || getPaymentStatus(invoice) === 'fullyCanceled';
    }

    private async getCancelledInvoice() {
        if (this.invoice.rootInvoiceId) {
            try {
                this.canceledInvoice = await this.invoiceService.get(this.invoice.rootInvoiceId);
            } catch (error) {
                if (error.code === 'RESOURCE_NOT_FOUND') {
                    this.canceledInvoiceNotFound = true;
                    return;
                }
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: ``,
                        body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                    },
                });
            }
        } else {
            this.canceledInvoice = null;
        }
    }

    public async getInvoiceNumber(): Promise<void> {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Nur online kann sichergestellt werden, dass der Zähler über alle Geräte hinweg eindeutig vergeben wird.',
            );
            return;
        }

        this.invoiceNumberGenerationPending = true;

        let invoiceNumber: Invoice['number'];
        try {
            invoiceNumber = await this.invoiceNumberService.generateInvoiceNumber({
                officeLocationId: this.invoice.officeLocationId,
                responsibleAssessorId: this.connectedReports?.[0]?.responsibleAssessor,
                report: this.connectedReports?.[0],
            });
            void this.saveInvoice();
            const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.invoice.officeLocationId);
            await this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceNumberGeneratedManually',
                documentType: 'invoice',
                invoiceNumber: invoiceNumber,
                invoiceId: this.invoice._id,
                invoiceNumberConfigId: invoiceNumberConfig._id,
            });

            this.invoiceNumberGenerationPending = false;
        } catch (error) {
            this.toastService.error(
                'Rechnungsnummer konnte nicht generiert werden',
                'Bitte kontaktiere die aX Hotline',
            );
            console.error('INVOICE_NUMBER_NOT_GENERATED', { error });
            this.invoiceNumberGenerationPending = false;
        }

        // Check if invoice number already exists.
        const invoicesWithSameNumber = await this.invoiceService.findByInvoiceNumber({
            invoiceNumber: invoiceNumber,
            invoiceDate: this.invoice.date || todayIso(),
        });
        if (invoicesWithSameNumber.length > 0) {
            this.toastService.info(
                'Rechnungsnummer bereits vergeben.',
                `In deinem Account gibt es bereits ${
                    invoicesWithSameNumber.length > 1
                        ? invoicesWithSameNumber.length + ' Rechnungen'
                        : '<a href="/Rechnungen/' + invoicesWithSameNumber[0]._id + '"> eine Rechnung</a>'
                } mit gleicher Rechnungsnummer. Mögliche Ursachen:
            - Du hast deinen Rechnungszähler zurückgesetzt.
            - Du hast diese Rechnungsnummer bereits manuell vergeben.
            - Dein Rechnungsnummernzähler ist falsch konfiguriert.`,
                {
                    timeOut: 0,
                    clickToClose: true,
                },
            );
        }

        // Set invoice number anyway - some customers use the same invoice numbers each year
        this.invoice.number = invoiceNumber;

        void this.saveInvoice();
    }

    public updateDueDate() {
        if (this.invoice.type !== 'invoice') return;

        this.invoice.dueDate = calculateDueDate(this.invoice.date, this.invoice.daysUntilDue);
        this.invoice.nextPaymentReminderAt = this.invoice.dueDate;
    }

    public convertToCreditNote() {
        this.invoice.type = 'creditNote';
        this.invoice.dueDate = null;
        this.invoice.nextPaymentReminderAt = null;
    }

    public convertToInvoice() {
        this.invoice.type = 'invoice';
        this.updateDueDate();
    }

    //*****************************************************************************
    //  Office Location
    //****************************************************************************/
    public getOfficeLocation(officeLocationId: string): OfficeLocation {
        if (!this.team?.officeLocations) {
            return undefined;
        }
        return this.team.officeLocations.find((officeLocation) => officeLocation._id === officeLocationId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Office Location
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Assessor
    //****************************************************************************/
    public getResponsibleAssessor(userId: string): User {
        return this.assessors.find((assessor) => assessor._id === userId);
    }

    public getUsersFullName(userId: string): string {
        const user: User = this.getResponsibleAssessor(userId);

        if (!user) {
            return '';
        }

        return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    }

    protected getDeactivatedUsersFullName(userId: string): string {
        const deactivatedAssessor = this.deactivatedAssessors.find((assessor) => assessor._id === userId);
        if (!deactivatedAssessor) {
            return '';
        }

        return `${deactivatedAssessor.firstName || ''} ${deactivatedAssessor.lastName || ''}`.trim();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Assessor
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Head
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Line Items
    //****************************************************************************/
    public addLineItem(): void {
        const newLineItem = new LineItem();
        newLineItem.position = this.invoice.lineItems.length + 1;
        this.invoice.lineItems.push(newLineItem);

        void this.saveInvoice();

        // Wait for the new row (including the description input field) to be rendered, then focus it
        setTimeout(() => {
            this.descriptionInputs.last.focusInput();
        }, 0);
    }

    public reorderInvoiceLineItemsArray(event: CdkDragDrop<LineItem[]>): void {
        const movedLineItem = this.invoice.lineItems.splice(event.previousIndex, 1)[0];
        // Add the item back at the new position
        this.invoice.lineItems.splice(event.currentIndex, 0, movedLineItem);

        // Save the new order back to the server.
        void this.saveInvoice();
    }

    //*****************************************************************************
    //  Line Item Autocomplete
    //****************************************************************************/

    public async saveInvoiceLineItemTemplate(lineItem: LineItem): Promise<void> {
        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.error(
                'Keine Berechtigung',
                'Du hast keine Berechtigung, Vorlagen für Rechnungspositionen zu speichern. Kontaktiere deinen Administrator',
            );
            return;
        }
        const newInvoiceLineItemTemplate = new InvoiceLineItemTemplate({
            _id: generateId(),
            description: lineItem.description,
            quantity: lineItem.quantity,
            unitPrice: lineItem.unitPrice,
            unit: lineItem.unit,
            createdBy: this.user._id,
            teamId: this.team._id,
        });
        try {
            await this.invoiceLineItemTemplateService.create(newInvoiceLineItemTemplate);
            this.toastService.success(
                'Rechnungsposition gespeichert',
                'Die Rechnungsposition wurde als Vorlage gespeichert und kann für zukünftige Rechnungen wiederverwendet werden.',
            );

            // Add the reference to allow updates in the template
            lineItem.lineItemTemplateId = newInvoiceLineItemTemplate._id;
            this.invoiceLineItemTemplates.push(newInvoiceLineItemTemplate);
            void this.saveInvoice();
        } catch (error) {
            this.apiErrorService.handleAndRethrow(error);
        }
    }

    public async updateInvoiceLineItemTemplate(lineItem: LineItem): Promise<void> {
        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.error(
                'Keine Berechtigung',
                'Du hast keine Berechtigung, Vorlagen für Rechnungspositionen zu speichern. Kontaktiere deinen Administrator',
            );
            return;
        }

        // Update an existing invoice line item template
        const newInvoiceLineItemTemplate =
            findRecordById(this.invoiceLineItemTemplates, lineItem.lineItemTemplateId) ?? new InvoiceLineItemTemplate();
        newInvoiceLineItemTemplate.description = lineItem.description;
        newInvoiceLineItemTemplate.quantity = lineItem.quantity;
        newInvoiceLineItemTemplate.unitPrice = lineItem.unitPrice;
        newInvoiceLineItemTemplate.unit = lineItem.unit;

        try {
            await this.invoiceLineItemTemplateService.put(newInvoiceLineItemTemplate);
            this.toastService.success(
                'Rechnungsposition aktualisiert',
                'Die Vorlage der Rechnungsposition wurde aktualisiert und kann für zukünftige Rechnungen wiederverwendet werden.',
            );
        } catch (error) {
            this.apiErrorService.handleAndRethrow(error);
        }
    }

    public insertInvoiceLineItemTemplate(lineItem: LineItem, lineItemTemplate: InvoiceLineItemTemplate) {
        lineItem.description = lineItemTemplate.description;
        lineItem.unitPrice = lineItemTemplate.unitPrice;
        lineItem.quantity = lineItemTemplate.quantity;
        lineItem.unit = lineItemTemplate.unit;
        lineItem.lineItemTemplateId = lineItemTemplate._id;
        void this.saveInvoice();
    }

    public removeInvoiceLineItem(costsItem: LineItem): void {
        if (this.isInvoiceLocked()) {
            return;
        }

        if (this.invoice.lineItems.length === 1) {
            this.toastService.error(
                'Letzte Rechnungsposition kann nicht gelöscht werden',
                'Mindestens eine Position ist erforderlich.',
            );
            return;
        }

        const index = this.invoice.lineItems.indexOf(costsItem);
        if (index > -1) {
            this.invoice.lineItems.splice(index, 1);
        }
        this.calculateTotal();
        void this.saveInvoice();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Line Item Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Line Item Unit Autocomplete
    //****************************************************************************/
    public filterLineItemAutocomplete(searchTerm: string): void {
        this.filteredLineItemUnits = [...this.lineItemUnits];

        if (!searchTerm) return;

        this.filteredLineItemUnits = this.lineItemUnits.filter((entry) =>
            entry.label.toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }

    public insertLineItemUnitBasedOnQuantity(lineItem: LineItem, selectedLabel: string): void {
        const autocompleteEntry: LineItemUnitAutocompleteEntry = this.lineItemUnits.find(
            (entry) =>
                entry.label === selectedLabel ||
                entry.singularValue === selectedLabel ||
                entry.pluralValue === selectedLabel,
        );

        if (!autocompleteEntry) return;

        lineItem.unit = lineItem.quantity > 1 ? autocompleteEntry.pluralValue : autocompleteEntry.singularValue;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Line Item Unit Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    public setVatRate(
        vatRate: Subtype<Invoice['vatRate'], 0>,
        vatExemptionReason: InvoiceParameters['vatExemptionReason'],
    ): void;
    public setVatRate(vatRate: Exclude<Invoice['vatRate'], 0>): void;
    public setVatRate(vatRate: Invoice['vatRate'], vatExemptionReason?: InvoiceParameters['vatExemptionReason']): void {
        this.invoice.vatRate = vatRate;
        this.invoice.vatExemptionReason = vatExemptionReason;

        this.calculateTotal();
    }

    protected translateVatExemptionReason(vatExemptionReason: InvoiceParameters['vatExemptionReason']): string {
        switch (vatExemptionReason) {
            case 'smallBusiness':
                return 'Kleinunternehmer';
            case 'reverseCharge':
                return 'Reverse Charge';
            case 'companyInternalInvoice':
                return 'Innenumsatz Organschaft';
            default:
                return 'Neutral';
        }
    }

    public calculateTotal(): void {
        this.invoice.totalNet = computeInvoiceTotalNet(this.invoice);
        this.invoice.totalGross = computeInvoiceTotalGross(this.invoice);

        //*****************************************************************************
        //  Determine Payment Status
        //****************************************************************************/
        // Cancellation Invoices and credit notes are always displayed as fully paid.
        if (this.invoice.type !== 'invoice') {
            this.invoice.hasOutstandingPayments = false;
        } else {
            setPaymentStatus(this.invoice);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Determine Payment Status
        /////////////////////////////////////////////////////////////////////////////*/

        determineDocumentMetadataTitle(this.invoice);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Line Items
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Status
    //****************************************************************************/
    /**
     * Invoice must...
     * - not be booked
     * - have a recipient (lastName + organization)
     * - have an invoice number
     */
    public isLockingAllowed(): boolean {
        if (!this.invoice) return;

        return !!(
            !this.invoice.lockedAt &&
            this.invoice.recipient &&
            (this.invoice.recipient.contactPerson.lastName || this.invoice.recipient.contactPerson.organization) &&
            this.invoice.number
        );
    }

    public getBookingTooltip(): string {
        if (this.isLockingAllowed()) return 'Festgeschriebene Rechnungen können nicht mehr bearbeitet werden.';

        if (
            !this.invoice.recipient ||
            !(this.invoice.recipient.contactPerson.lastName || this.invoice.recipient.contactPerson.organization)
        )
            return 'Empfänger fehlt';

        if (!this.invoice.number) return 'Rechnungsnummer fehlt';
    }

    public async lockInvoice(): Promise<void> {
        if (!this.isLockingAllowed()) return;

        /**
         * Validate if the XRechnung parameters are correct before locking an invoice. Otherwise, the user may be unable
         * to export invoices to DATEV in the future. The DATEV export exports the invoice PDFs and during invoice PDF
         * generation, the XRechnung is validated.
         */
        if (this.invoice.isElectronicInvoiceEnabled) {
            try {
                await this.invoiceService.getXrechnung(this.invoice._id);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'XRechnung nicht geprüft',
                        body: 'Weil diese Rechnung eine E-Rechnung ist, wird das technische Format "XRechnung" beim Festschreiben geprüft. Diese Prüfung schlug aber fehl.<br><br>Bitte versuche es erneut. Falls der Fehler weiterhin besteht, wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }
        }

        this.invoice.lockedAt = moment().format();
        this.invoice.lockedBy = this.user._id;
        void this.saveInvoice();
    }

    /**
     * Unbooking an invoice is allowed if the invoice was locked by the current user and the deadline has not yet passed.
     */
    public isUnbookingAllowed(): boolean {
        if (!this.invoice) return;

        return (
            !!this.invoice.lockedAt &&
            this.invoice.lockedBy === this.user._id &&
            this.getUnbookingDeadline().isAfter(moment())
        );
    }

    public getUnbookingDeadline(): Moment {
        return moment(this.invoice?.lockedAt).add(24, 'hours');
    }

    public unbookInvoice(): void {
        if (!this.isUnbookingAllowed()) return;

        this.invoice.lockedAt = null;
        this.invoice.lockedBy = null;
        void this.saveInvoice();
    }

    /**
     * Tell the user who locked the invoice when.
     */
    public getInvoiceLockedMessage(): string {
        if (!this.invoice?.lockedAt || !this.invoice.lockedBy) {
            return '';
        }
        const dateFromNow = moment(this.invoice.lockedAt).fromNow();
        const dateFromNowCapitalized = dateFromNow[0].toUpperCase() + dateFromNow.slice(1);

        const lockedByUser = this.userService.getTeamMemberFromCache(this.invoice.lockedBy);

        // User noch yet loaded
        if (!lockedByUser) {
            return `${dateFromNowCapitalized} abgeschlossen.`;
        }

        return `${dateFromNowCapitalized} von ${lockedByUser.firstName} ${lockedByUser.lastName} abgeschlossen.`;
    }

    public isInvoiceLocked(): boolean {
        return !!this.invoice?.lockedAt;
    }

    // Returns the unpaid amount or zero, whichever is higher.
    public get unpaidAmount(): number {
        return getUnpaidAmount(this.invoice);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Status
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Payments
    //****************************************************************************/
    public openPaymentsDialog(): void {
        this.paymentsDialogShown = true;
    }

    public hidePaymentsDialog(): void {
        this.paymentsDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payments
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Payment Reminder
    //****************************************************************************/
    public openPaymentReminderDialog(): void {
        this.paymentReminderDialogShown = true;
    }

    public hidePaymentReminderDialog(): void {
        this.paymentReminderDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payment Reminder
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Connection
    //****************************************************************************/
    public showReportConnectionInput(): void {
        this.reportConnectionInputShown = true;
        this.reportSearchTermSubscription = this.reportSearchTerm$
            .pipe(
                /**
                 * Ensure that a search term has 3 or more characters. Otherwise, searches like "a" are so unspecific
                 * that our backend server collapses under the load because for large customer accounts, thousand of reports
                 * are loaded into memory.
                 *
                 * Three characters allow for searches like "BMW" oder "Audi".
                 *
                 * Also allow searches if there are two or more search words separated by space. That allows to search for license plates
                 * like "PB SL".
                 */
                filter((searchTerm) => {
                    if (!searchTerm || typeof searchTerm !== 'string') return;

                    // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
                    const searchTermParts = searchTerm
                        .trim()
                        .split(' ')
                        .filter((searchTerm) => !!searchTerm.trim());
                    return (
                        searchTermParts.length >= 2 ||
                        searchTermParts.some((searchTermPart) => searchTermPart.length >= 3)
                    );
                }),
                tap(() => {
                    this.reportSearchPending = true;
                }),
                debounceTime(300),
                switchMap((searchTerm) => this.reportService.searchReportsWithoutPagination({ $search: searchTerm })),
            )
            .subscribe({
                next: (records) => {
                    this.reports = records;
                    this.sortReports();
                    this.filterReportsAutocomplete();
                    this.reportSearchPending = false;
                },
                error: () => {
                    this.toastService.error(
                        'Fehler bei Suche',
                        "Bitte kontaktiere die <a href='/Hilfe' target='_blank'>Hotline</a>.",
                    );
                    this.reportSearchPending = false;
                },
            });
    }

    public hideReportConnectionInput(): void {
        this.reportConnectionInputShown = false;
        this.reportSearchTermSubscription.unsubscribe();
    }

    /**
     * Fetch connected report from server
     */
    public async getConnectedReport(): Promise<void> {
        if (!this.invoice || !(this.invoice.reportIds?.length > 0)) return;

        try {
            this.connectedReports = await this.reportService.findReportsById(this.invoice.reportIds).toPromise();
        } catch (error) {
            this.toastService.error(
                'Verknüpftes Gutachten fehlt',
                'Bitte stelle sicher, dass es noch existiert oder verknüpfe es erneut.',
            );
            return;
        }

        if (this.connectedReports.length !== this.invoice.reportIds.length) {
            const missingReportIds = this.invoice.reportIds.filter(
                (reportId) => this.connectedReports.findIndex((report) => report._id === reportId) === -1,
            );
            this.toastService.error(
                'Verknüpftes Gutachten fehlt',
                'Bitte stelle sicher, dass es noch existiert oder verknüpfe es erneut.',
            );
            console.error('Missing connected report IDs: ' + missingReportIds.join(', '));
        }
    }

    public updateReportSearchTerm(searchTerm: string): void {
        this.reportSearchTerm$.next(searchTerm);
    }

    public sortReports(): void {
        this.reports.sort((reportA, reportB) => {
            const tokenA = (reportA.token || '').toLowerCase().trim();
            const tokenB = (reportB.token || '').toLowerCase().trim();

            return tokenA.localeCompare(tokenB);
        });
    }

    public filterReportsAutocomplete(): void {
        const searchTerms = (this.reportSearchTerm || '').split(' ');

        this.filteredReports = this.reports.filter((report) => {
            const propertiesToBeSearched = [
                (report.token || '').toLowerCase(),
                (report.car.licensePlate || '').toLowerCase(),
                (report.claimant.contactPerson.organization || '').toLowerCase(),
                (report.claimant.contactPerson.firstName || '').toLowerCase(),
                (report.claimant.contactPerson.lastName || '').toLowerCase(),
            ];

            return searchTerms.every((searchTerm) => {
                searchTerm = (searchTerm || '').toLowerCase();
                return propertiesToBeSearched.some((property) => property.includes(searchTerm));
            });
        });
    }

    public handleOpenInNewClick(reportId: string, event: MouseEvent): void {
        window.open(`/Gutachten/${reportId}`);
        // Prevent the click from selecting the autocomplete entry
        event.stopPropagation();
    }

    public connectReport(report: Report): void {
        if (!this.invoice.reportIds) {
            this.invoice.reportIds = [];
        }

        if (!this.connectedReports) {
            this.connectedReports = [];
        }

        this.invoice.reportsData = [getInvoiceReportData({ report })];

        this.invoice.reportIds.push(report._id);
        this.connectedReports.push(report);

        // Set the responsible assessor
        this.invoice.associatedAssessorId = report.responsibleAssessor;

        //*****************************************************************************
        //  Create Involved Parties
        //****************************************************************************/
        /**
         * Not every report contains all involved parties.
         * E.g. valuation reports do not have lawyer or insurance but are often connected to a combined invoice.
         */
        const addedInvolvedPartyRoles: InvoiceInvolvedPartyRole[] = [];

        // Claimant
        if (report.claimant?.contactPerson) {
            addedInvolvedPartyRoles.push('claimant');
            this.invoice.claimant = new InvoiceInvolvedParty({
                role: 'claimant',
                contactPerson: report.claimant?.contactPerson,
            });
        }

        // Lawyer
        if (report.lawyer?.contactPerson) {
            addedInvolvedPartyRoles.push('lawyer');
            this.invoice.lawyer = new InvoiceInvolvedParty({
                role: 'lawyer',
                contactPerson: report.lawyer.contactPerson,
            });
        }

        // Insurance
        if (report.insurance?.contactPerson) {
            addedInvolvedPartyRoles.push('insurance');
            this.invoice.insurance = new InvoiceInvolvedParty({
                role: 'insurance',
                contactPerson: report.insurance.contactPerson,
            });
        }

        /**
         * Since new involved parties were added, ensure each one has their own document order. Otherwise, the documents
         * could not be shown if one of these new recipients is selected.
         *
         * The document orders are empty by default. Document Order Items will be added below.
         */
        addMissingDocumentOrdersToInvoice({ invoice: this.invoice });

        const invoiceRecipientDocumentOrder: DocumentOrder = getDocumentOrderForRecipient(
            this.invoice.documentOrders,
            'invoiceRecipient',
        );
        for (const addedInvolvedPartyRole of addedInvolvedPartyRoles) {
            const addedDocumentOrder: DocumentOrder = getDocumentOrderForRecipient(
                this.invoice.documentOrders,
                addedInvolvedPartyRole,
            );
            /**
             * Add the same document order items which were already present in the invoice recipient document order. These items
             * have a reference to all existing documents.
             */
            addedDocumentOrder.items = JSON.parse(JSON.stringify(invoiceRecipientDocumentOrder.items));
        }

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Create Involved Parties
        /////////////////////////////////////////////////////////////////////////////*/

        // If recipient is empty, set it to claimant
        if (
            !this.invoice.recipient.contactPerson.organization &&
            !this.invoice.recipient.contactPerson.firstName &&
            !this.invoice.recipient.contactPerson.lastName
        ) {
            const recipientContactPerson: ContactPerson = this.invoice.recipient.contactPerson;
            const claimantContactPerson: ContactPerson = report.claimant.contactPerson;

            recipientContactPerson.organization = claimantContactPerson.organization;
            recipientContactPerson.firstName = claimantContactPerson.firstName;
            recipientContactPerson.lastName = claimantContactPerson.lastName;
            recipientContactPerson.streetAndHouseNumberOrLockbox = claimantContactPerson.streetAndHouseNumberOrLockbox;
            recipientContactPerson.zip = claimantContactPerson.zip;
            recipientContactPerson.city = claimantContactPerson.city;
            recipientContactPerson.email = claimantContactPerson.email;
        }

        this.hideReportConnectionInput();
    }

    public async cutReportConnection(): Promise<void> {
        if (this.isInvoiceLocked()) {
            this.toastService.info(
                'Rechnung abgeschlossen',
                'Das Gutachten kann nicht getrennt werden, solange die Rechnung abgeschlossen ist.',
            );
            return;
        }

        // Would the removal of the report and the invoice involved parties break payment reminder documents that use their data?
        const cuttingWouldBreakPaymentReminderDocuments = this.invoice.documents.find((doc) => {
            const isPaymentReminderDocument = new Array<RenderedDocumentType>(
                'paymentReminderLevel0',
                'paymentReminderLevel1',
                'paymentReminderLevel2',
                'paymentReminderLevel3',
            ).includes(doc.type as RenderedDocumentType);
            const isAddressedToReportInvolvedParty = doc.recipientRole !== 'invoiceRecipient';
            return isPaymentReminderDocument && isAddressedToReportInvolvedParty;
        });
        if (cuttingWouldBreakPaymentReminderDocuments) {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Mahnstufen gehen verloren',
                        content:
                            'Wenn du das Gutachten entknüpfst, gehen die Mahnstufen verloren.\nVorhandene Zahlungserinnerungen & Mahnungen müssen erneut konfiguriert werden.',
                        confirmLabel: 'Entknüpfen',
                        cancelLabel: 'Behalten',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) return;
        }

        this.invoice.reportIds = null;
        this.connectedReports = null;

        //*****************************************************************************
        //  Remove involved parties
        //****************************************************************************/
        this.invoice.claimant = null;
        this.invoice.lawyer = null;
        this.invoice.insurance = null;

        const removedInvolvedPartyRoles: InvoiceInvolvedPartyRole[] = ['claimant', 'lawyer', 'insurance'];
        for (const removedInvolvedPartyRole of removedInvolvedPartyRoles) {
            removeDocumentOrderFromInvoice({
                invoice: this.invoice,
                recipientRole: removedInvolvedPartyRole,
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Remove involved parties
        /////////////////////////////////////////////////////////////////////////////*/

        this.invoice.reportsData = [];
        this.showReportConnectionInput();
    }

    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;

    public navigateToReport(report: Report): void {
        this.router.navigateByUrl(`/Gutachten/${report._id}`);
    }

    public unfoldLawyer(): void {
        this.extendLawyer = !this.extendLawyer;
    }

    public unfoldInsurance(): void {
        this.extendInsurance = !this.extendInsurance;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Connection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Templates
    //****************************************************************************/

    private showTemplatesIfInvoiceEmpty(): void {
        const invoiceEmpty =
            !this.invoice.subject &&
            !this.invoice.intro &&
            this.invoice.lineItems.every(
                (lineItem) => !lineItem.description && !lineItem.unit && !lineItem.unitPrice && !lineItem.quantity,
            );
        if (invoiceEmpty && this.invoiceTemplates && this.invoiceTemplates.length) {
            this.invoiceTemplatesShown = true;
        }
    }

    private getInvoiceTemplates(): void {
        this.invoiceTemplateService.find({ type: 'customInvoice' }).subscribe({
            next: (invoiceTemplates) => {
                this.invoiceTemplates = invoiceTemplates;
            },
            error: (err) => {
                this.toastService.error('Vorlagen konnten nicht vom Server geholt werden');
                console.error('COULD_NOT_RETRIEVE_INVOICE_TEMPLATES', err);
            },
        });
    }

    /**
     * Get the default title value passed to the save-as-template dialog.
     *
     * The default is either the subject or the first line item. HTML code is stripped.
     */
    public getInvoiceTemplateTitle(): string {
        return convertHtmlToPlainText(this.invoice.subject || this.invoice.lineItems[0].description || '');
    }

    public async addInvoiceToTemplates(templateTitle: string): Promise<void> {
        // Template = invoice without _id to avoid ID conflicts
        const invoiceCopy = JSON.parse(JSON.stringify(this.invoice));
        delete invoiceCopy._id;
        const invoiceTemplate: InvoiceTemplate = new InvoiceTemplate();
        invoiceTemplate.type = 'customInvoice';
        invoiceTemplate.title = templateTitle;
        invoiceTemplate.intro = invoiceCopy.intro;
        invoiceTemplate.daysUntilDue = invoiceCopy.daysUntilDue;
        invoiceTemplate.lineItems = invoiceCopy.lineItems;
        invoiceTemplate.totalNet = computeInvoiceTotalNet(invoiceCopy);
        invoiceTemplate.totalGross = computeInvoiceTotalGross(invoiceCopy);

        this.invoiceTemplates.push(invoiceTemplate);

        try {
            await this.invoiceTemplateService.create(invoiceTemplate);
            this.toastService.success('Vorlage hinzugefügt', `Titel: ${templateTitle}`);
        } catch (error) {
            // Remove template in case of error
            this.invoiceTemplates.splice(this.invoiceTemplates.indexOf(invoiceTemplate), 1);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    REQUIRED_FIELD_NOT_PROVIDED: (error) => ({
                        title: `Feld "${error.data.field}" fehlt in Anfrage`,
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    }),
                },
                defaultHandler: {
                    title: 'Vorlage konnte nicht hinzugefügt werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public showInvoiceTemplates(): void {
        this.invoiceTemplatesShown = true;
    }

    public hideInvoiceTemplates(): void {
        this.invoiceTemplatesShown = false;
        this.selectedInvoiceTemplate = null;
    }

    public getInvoiceTemplateTotal(invoiceTemplate: InvoiceTemplate): string {
        return currencyFormatterEuro(invoiceTemplate.totalNet);
    }

    public showInvoiceTemplateTitleDialog(): void {
        this.invoiceTemplateTitleDialogShown = true;
    }

    public async deleteInvoiceTemplate(invoiceTemplate: InvoiceTemplate): Promise<void> {
        this.invoiceTemplates.splice(this.invoiceTemplates.indexOf(invoiceTemplate), 1);

        try {
            await this.invoiceTemplateService.delete(invoiceTemplate._id);
        } catch (error) {
            this.toastService.error('Vorlage konnte nicht gelöscht werden');
            console.error('COULD_NOT_DELETE_INVOICE_TEMPLATE', { error });
            this.invoiceTemplates.push(invoiceTemplate);
        }
    }

    public useInvoiceTemplate(invoiceTemplate: InvoiceTemplate) {
        if (this.invoice.lockedAt) {
            this.toastService.info(
                'Rechnung bereits gebucht',
                'Das Einfügen von Vorlagen in gebuchte Rechnungen ist nicht möglich.',
            );
            return;
        }

        // Copy so that if the user changes the invoice's line items, the template doesn't change as well.
        const copyOfTemplate: Invoice = JSON.parse(JSON.stringify(invoiceTemplate));

        Object.assign(this.invoice, {
            lineItems: copyOfTemplate.lineItems,
            intro: copyOfTemplate.intro,

            // Keep the current bank account configuration. It's likely more up-to-date on this invoice.
            bankAccount: this.invoice.bankAccount,
            secondBankAccount: this.invoice.secondBankAccount,
        });

        this.calculateTotal();

        void this.saveInvoice();

        this.hideInvoiceTemplates();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Print + Send
    //****************************************************************************/

    public async downloadInvoicePreview(): Promise<void> {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Dokumente können heruntergeladen werden, sobald du wieder online bist.',
            );
            return;
        }

        this.invoicePreviewPending = true;

        try {
            const response = await this.invoiceService.getInvoicePreview(this.invoice);
            this.downloadService.downloadBlobResponseWithHeaders(response);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Rechnungsvorschau konnte nicht heruntergeladen werden',
                    body: 'Bitte generiere sie noch einmal oder kontaktiere den aX-Support.',
                },
            });
        } finally {
            this.invoicePreviewPending = false;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Print & Send
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice number journal entries
    //****************************************************************************/

    async handleInvoiceNumberChange() {
        await this.saveInvoice();

        const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.invoice.officeLocationId);
        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceNumberChangedManually',
            documentType: 'invoice',
            invoiceNumber: this.invoice.number,
            previousInvoiceNumber: this.lastInvoiceNumber,
            invoiceId: this.invoice._id,
            invoiceNumberConfigId: invoiceNumberConfig._id,
        });
    }

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/

    public async saveInvoice(): Promise<void> {
        /**
         * If a manual invoice was edited, the type must be set to 'invoice' or 'creditNote' based on the total net.
         */
        if (this.invoice.type === 'invoice' && this.invoice.totalNet < 0) {
            this.convertToCreditNote();
        } else if (this.invoice.type === 'creditNote' && this.invoice.totalNet > 0) {
            this.convertToInvoice();
        }

        try {
            await this.invoiceService.put(this.invoice);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                defaultHandler: {
                    title: 'Rechnung nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Websocket Events
    //****************************************************************************/
    private registerWebsocketEventHandlers() {
        this.registerPatchWebsocketEvent();
    }

    /**
     * On each websocket put event, update local records.
     * @private
     */
    private registerPatchWebsocketEvent() {
        const patchUpdatesSubscription: Subscription =
            this.invoiceService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
                next: (patchedEvent: PatchedEvent<Invoice>) => {
                    if (!this.invoice) return;

                    // If this view holds the record being updated, update it.
                    if (this.invoice._id === patchedEvent.patchedRecord._id) {
                        applyOfflineSyncPatchEventToLocalRecord({
                            localRecord: this.invoice,
                            patchedEvent,
                        });
                    }
                },
            });

        this.subscriptions.push(patchUpdatesSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Websocket Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected readonly getPaymentStatus = getPaymentStatus;
    protected readonly trackById = trackById;
}

export interface LineItemUnitAutocompleteEntry {
    label: 'Stunden' | 'km' | 'Pauschal' | 'Stück' | 'Minuten';
    singularValue: 'Stunde' | 'km' | 'Pauschal' | 'Stück' | 'Minute';
    pluralValue: 'Stunden' | 'km' | 'Pauschal' | 'Stück' | 'Minuten';
}

import { DateTime } from 'luxon';
import { ResidualValueInvitationReceipt } from './residual-value-invitation-receipt';

export class AutoixpertResidualValueOffer {
    bindingPeriodInDays: number;
    closingDate: string;
    selectedRecipientIds: string[] = [];
    invitationReceipts: ResidualValueInvitationReceipt[] = [];
    winningBidderId: string;
    retrievedAt: string;

    // We need the information to decide whether an offer belongs to the current report or an original report (e.g. in amendment report or copied report)
    createdInReportId: string;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
}

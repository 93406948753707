<div id="layout-container">
    <!--============================================-->
    <!-- Menu -->
    <!--============================================-->
    <div id="menu-column"></div>
    <!--============================================-->
    <!-- END Menu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Reporting Column -->
    <!--============================================-->
    <div id="reporting-column">
        <!--============================================-->
        <!-- Graph -->
        <!--============================================-->
        <div id="graph-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Graph Header -->
            <!--============================================-->
            <div id="graph-header">
                <!--********** Report Name **********-->
                <div id="report-name-container">
                    <mat-icon
                        class="toolbar-icon"
                        matTooltip="Zurück zur Übersicht"
                        (click)="navigateBackToAnalyticsList()">
                        arrow_back
                    </mat-icon>
                    <h2>{{ chartTitle }}</h2>
                </div>

                <analytics-filter-button [filterRef]="filter"></analytics-filter-button>
                <div class="date-and-settings-container">
                    <!--********** Date Filter **********-->
                    <date-range-picker
                        [(dateFrom)]="filterAnalyticsFrom"
                        [(dateTo)]="filterAnalyticsTo"
                        (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
                    <analytics-settings-menu (useNetValuesChange)="updateAnalytics()"></analytics-settings-menu>
                </div>
            </div>

            <!--============================================-->
            <!-- END Graph Header -->
            <!--============================================-->

            <analytics-filter
                (filterChange)="updateAnalytics()"
                (initialized)="filtersLoaded()"
                [showInvoiceLabelFilter]="false"></analytics-filter>

            <div class="chart-container">
                <div class="chart-placeholder" *ngIf="analyticsPending">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Lade Auswertung ...
                </div>
                <div class="chart-placeholder" *ngIf="!analyticsPending && records.length === 0">
                    <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                    <div class="no-data-found-message">Keine Daten gefunden</div>
                    Probiere doch andere Filter.
                </div>
                <div id="chart" class="margin-bottom-30" *ngIf="!analyticsPending && records.length"></div>
            </div>

            <!--============================================-->
            <!-- Graph Summary -->
            <!--============================================-->
            <div id="graph-summary-container" class="card">
                <!--********** Total **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Gesamte Schadenhöhe</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ damageTotal | number: '1.0-0' }} €</div>
                </div>

                <!--********** Reports **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Gutachten
                        <span class="series-color analytics-series-1"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalNumberOfReports | number: '1.0-0' }}
                    </div>
                </div>

                <!--********** Average **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Ø-Schadenhöhe</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ getAverageDamageTotal() || 0 | number: '1.0-0' }} €</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Graph Summary -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Graph -->
        <!--============================================-->

        <!--============================================-->
        <!-- Series List -->
        <!--============================================-->
        <div id="series-list-card" class="card card-with-padding">
            <div id="series-list-toolbar">
                <export-associated-reports-icon
                    [records]="prepareDataForExport()"
                    [summaryDownloadName]="chartTitle"></export-associated-reports-icon>
            </div>
            <div class="chart-placeholder" *ngIf="analyticsPending">
                <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                Lade Auswertung ...
            </div>
            <div id="series-list-table-container">
                <table id="series-list" *ngIf="!analyticsPending && records.length > 0">
                    <thead>
                        <tr>
                            <th class="series-entry-title-heading">Kategorie</th>
                            <th class="series-entry-value-heading">Anzahl Gutachten</th>
                            <th class="series-entry-value-heading">
                                Gesamte Schadenhöhe ({{ this.settings.useNetValues ? 'netto' : 'brutto' }})
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="series-entry-row" *ngFor="let record of records">
                            <!--********** Damage Tier **********-->
                            <td class="series-entry-title-cell">
                                <div class="garage-container">
                                    {{ getDamageTierCategoryLabel(record) }}
                                </div>
                            </td>
                            <!--********** Number of Reports **********-->
                            <td class="series-entry-value">
                                <span
                                    report-list-overlay-anchor
                                    [reportIds]="getReportsForOverlay(record)"
                                    [reportsWithIdAndDamagedValue]="getReportsWithDamageValueForOverlay(record)">
                                    {{ record.numberOfReports }} Stk.
                                </span>
                            </td>
                            <!--********** Total Damage Value **********-->
                            <td class="series-entry-value">{{ record.damageTotalValue | number: '1.0-0' }} €</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Series List -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Reporting Column -->
    <!--============================================-->
</div>

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container">
        <div id="heading-and-toolbar-container">
            <h2 [ngSwitch]="organizationType">
                <ng-container *ngSwitchCase="'visitLocationFavorite'">Favoriten</ng-container>
                <ng-container *ngSwitchCase="'intermediary'">Vermittler</ng-container>
                <ng-container *ngSwitchDefault>Kontakte</ng-container>
            </h2>
            <div id="toolbar-container">
                <mat-icon class="toolbar-icon" (click)="addContactPerson()" [matTooltip]="getTooltipForAddIcon()">
                    add
                </mat-icon>
            </div>
        </div>
        <!--============================================-->
        <!-- Contacts List Section -->
        <!--============================================-->
        <section id="contact-list-section" *ngIf="availableContactPeople.length > 0 && !contactPersonInEditMode">
            <div id="search-field-and-contact-list-container">
                <div id="search-field-container">
                    <mat-form-field class="search-field">
                        <input matInput placeholder="Suchen" [(ngModel)]="searchTerm" (input)="filterContactPeople()" />
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
                <!--============================================-->
                <!-- Contact List -->
                <!--============================================-->
                <div id="contact-list">
                    <div
                        class="contact-list-item"
                        *ngFor="let contactPerson of filteredContactPeople"
                        [ngClass]="{ selected: contactPerson === selectedContactPerson }"
                        (click)="selectContactPerson(contactPerson)"
                        (dblclick)="selectContactPerson(contactPerson); acceptContactPerson()">
                        <div class="list-item-icon-container" [ngSwitch]="organizationType">
                            <mat-icon class="list-item-icon" *ngSwitchCase="'visitLocationFavorite'">star</mat-icon>
                            <mat-icon class="list-item-icon" *ngSwitchCase="'intermediary'">record_voice_over</mat-icon>
                        </div>
                        <div class="contact-details-container">
                            <div class="organization" *ngIf="contactPerson.organization">
                                {{ contactPerson.organization }}
                            </div>
                            <div
                                class="name"
                                *ngIf="contactPerson.lastName || contactPerson.firstName"
                                [ngClass]="{ bold: !contactPerson.organization, label: contactPerson.organization }">
                                {{ contactPerson.firstName }}
                                {{ contactPerson.lastName }}
                            </div>
                            <div class="address label">
                                {{ contactPerson.streetAndHouseNumberOrLockbox }}
                                <span *ngIf="contactPerson.streetAndHouseNumberOrLockbox && contactPerson.city">-</span>
                                {{ contactPerson.zip }} {{ contactPerson.city }}
                            </div>
                        </div>
                        <div class="submenu-container">
                            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="contactPersonSubmenu">
                                more_vert
                            </mat-icon>
                            <mat-menu #contactPersonSubmenu>
                                <mat-option (click)="editContactPerson(contactPerson)">Bearbeiten</mat-option>
                                <mat-option (click)="removeContactPerson(contactPerson)">Löschen</mat-option>
                            </mat-menu>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Contact List -->
                <!--============================================-->
            </div>

            <!--============================================-->
            <!-- Buttons -->
            <!--============================================-->
            <div id="buttons-container">
                <button
                    (click)="acceptContactPerson()"
                    [ngClass]="{ disabled: !selectedContactPerson }"
                    [matTooltip]="selectedContactPerson ? '' : 'Zuerst Favorit auswählen'">
                    Übernehmen
                </button>
            </div>
            <!--============================================-->
            <!-- END Buttons -->
            <!--============================================-->
        </section>
        <!--============================================-->
        <!-- END Contact List Section -->
        <!--============================================-->

        <!--============================================-->
        <!-- Edit Mode Section -->
        <!--============================================-->
        <form id="edit-mode-section" *ngIf="contactPersonInEditMode">
            <contact-person-form
                [contact-person]="contactPersonInEditMode"
                (contactPersonChange)="saveContactPerson(); emitContactPersonReloadRequired()"></contact-person-form>
            <div id="edit-mode-buttons-container">
                <button (click)="leaveEditMode()" [ngClass]="{ secondary: contactPersonInEditModeIsEmpty() }">
                    {{ contactPersonInEditModeIsEmpty() ? 'Verwerfen' : 'Schließen' }}
                </button>
            </div>
        </form>
        <!--============================================-->
        <!-- END Edit Mode Section -->
        <!--============================================-->

        <!--============================================-->
        <!-- No Contacts Placeholder -->
        <!--============================================-->
        <section id="no-contacts-placeholder" *ngIf="availableContactPeople.length === 0" [ngSwitch]="organizationType">
            <!--********** Visit Location Favorites **********-->
            <ng-container *ngSwitchCase="'visitLocationFavorite'">
                <!-- Stars is the name of the star icon on a filled circle background -->
                <mat-icon class="no-contacts-placeholder-icon">stars</mat-icon>
                <div>Noch keine Favoriten gespeichert.</div>
                <button class="no-contacts-placeholder-button" (click)="addContactPerson()">Neuer Favorit</button>
            </ng-container>

            <!--********** Intermediaries **********-->
            <ng-container *ngSwitchCase="'intermediary'">
                <mat-icon class="no-contacts-placeholder-icon">record_voice_over</mat-icon>
                <div>Noch keine Vermittler gespeichert.</div>
                <button class="no-contacts-placeholder-button" (click)="addContactPerson()">Neuer Vermittler</button>
            </ng-container>

            <!--********** Default **********-->
            <ng-container *ngSwitchDefault>
                <img class="no-contacts-placeholder-icon" src="/assets/images/icons/claimant-gray.svg" />
                <div>Noch keine Kontakte gespeichert.</div>
                <button class="no-contacts-placeholder-button" (click)="addContactPerson()">Neuer Kontakt</button>
            </ng-container>
        </section>
        <!--============================================-->
        <!-- END No Contacts Placeholder -->
        <!--============================================-->
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CarRegistrationScannerResponse } from '@autoixpert/external-apis/car-registration-scanner-response';

@Injectable()
export class CarRegistrationScannerService {
    constructor(private httpClient: HttpClient) {}

    private apiPath: string = '/api/v0';

    public get(reportId: string, photoId: string): Observable<CarRegistrationScannerResponse> {
        return this.httpClient.get<CarRegistrationScannerResponse>(
            `${this.apiPath}/reports/${reportId}/carRegistrationScans/${photoId}`,
        );
    }
}

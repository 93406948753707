import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { roundToNearest } from '@autoixpert/lib/numbers/round-to-nearest';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { mergeRecord } from '@autoixpert/lib/server-sync/merge-record';
import { getFullUserContactPerson } from '@autoixpert/lib/users/get-full-user-contact-person';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { replaceObjectProperties } from 'src/app/shared/libraries/objects/replace-object-properties';
import { ReportDetailsService } from 'src/app/shared/services/report-details.service';
import { TeamService } from 'src/app/shared/services/team.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ValuationRoundDecreaseConfigurationComponent } from './valuation-round-decrease-configuration/valuation-round-decrease-configuration.component';

@Component({
    selector: 'valuation-calculation',
    templateUrl: './valuation-calculation.component.html',
    styleUrls: ['./valuation-calculation.component.scss'],
})
export class ValuationCalculationComponent implements OnInit {
    constructor(
        private reportDetailsService: ReportDetailsService,
        private teamService: TeamService,
        private toastService: ToastService,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input() report: Report;
    @Input() user: User;
    @Input() team: Team;

    @Output() decreaseFromDamageChanged = new EventEmitter();

    @ViewChild('roundDecreaseButton') roundDecreaseButton;
    @ViewChild('editDecreasePercentageButton') editDecreasePercentageButton;

    ngOnInit() {
        // Auto-select the adjustments tab
        if (
            this.report.damageCalculation.repair.repairCostsGross ||
            this.report.damageCalculation.repair.documentHash
        ) {
            this.selectedTab = 'adjustments';
        }
    }

    public garageSearchTerm: string;

    protected selectedTab: 'garage' | 'adjustments' = 'garage';
    protected selectTab(tab: 'garage' | 'adjustments') {
        this.selectedTab = tab;
    }

    protected garageForEditor: ContactPerson;
    protected garageFeesDialogShown = false;

    //*****************************************************************************
    //  Dekra Fees
    //****************************************************************************/
    get useDekraFees(): boolean {
        return !!this.report.damageCalculation.repair.useDekraFees;
    }

    public async setUseDekraFees(useDekraFees: boolean) {
        this.report.damageCalculation.repair.useDekraFees = useDekraFees;

        if (!useDekraFees) {
            this.clearGarage();
        }

        // Deactivate Repair Order Issued if DEKRA fees are used
        if (useDekraFees) {
            this.report.damageCalculation.repair.repairOrderIssued = null;
        }

        await this.saveReport({ waitForServer: true });
    }

    public async toggleUseDekraFees() {
        await this.setUseDekraFees(!this.useDekraFees);
    }

    public getDekraFeesTooltip(): string {
        let tooltip = `Regionaler Durchschnitt von Reparaturkostensätzen auf Basis von Erhebungen der DEKRA.\n\n`;
        if (this.report.damageCalculation.repair.customDekraFeeZip) {
            tooltip += `Die manuell gesetzte Postleitzahl "${this.report.damageCalculation.repair.customDekraFeeZip}" wird verwendet.`;
        } else if (this.report.claimant.contactPerson.zip) {
            tooltip += `Die Postleitzahl des Anspruchstellers (${this.getZipForDekraFees()}) wird verwendet.`;
        } else {
            tooltip += `Deine Postleitzahl (${this.getZipForDekraFees()}) wird verwendet, weil beim Anspruchsteller keine hinterlegt ist.`;
        }
        tooltip +=
            '\n\nDie DEKRA liefert keine Kostensätze für Elektrik und Dellendrücken. Für Elektrik wird der Kostensatz für Mechanik angenommen. Als Grundlage für das Dellendrücken dient der Kostensatz von Lackarbeiten.';
        return tooltip;
    }

    public getZipForDekraFees(): string {
        return (
            this.report.damageCalculation.repair.customDekraFeeZip ||
            this.report.claimant.contactPerson.zip ||
            getFullUserContactPerson({
                user: this.user,
                officeLocations: this.team.officeLocations,
                officeLocationId: this.report.officeLocationId,
            }).zip
        );
    }

    /**
     * Only used to indicate to the user for which place the ZIP belongs that he's using to fetch DEKRA fees.
     */
    public getCityForDekraFees(): string {
        return (
            this.report.damageCalculation.repair.customDekraFeeCity ||
            this.report.claimant.contactPerson.city ||
            getFullUserContactPerson({
                user: this.user,
                officeLocations: this.team.officeLocations,
                officeLocationId: this.report.officeLocationId,
            }).zip
        );
    }

    public async addCustomDekraFeeZip() {
        this.report.damageCalculation.repair.customDekraFeeZip = this.getZipForDekraFees();
        await this.saveReport({ waitForServer: true });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dekra Fees
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Garage
    //****************************************************************************/
    public async insertGarageInReport(garage: ContactPerson) {
        this.garageSearchTerm = '';
        this.report.garage.contactPerson = garage;

        // Select the default garage fee set
        this.report.garage.selectedFeeSetId = this.report.garage.contactPerson.garageFeeSets?.find(
            (feeSet) => feeSet.isDefault,
        )?._id;

        this.saveReport({ waitForServer: true });
    }

    public clearGarage() {
        replaceObjectProperties({
            targetObject: this.report.garage.contactPerson,
            sourceObject: new ContactPerson({
                organizationType: this.report.garage.contactPerson.organizationType,
            }),
            propertiesToKeep: ['_id'],
        });
    }

    public createNewGarage() {
        this.garageForEditor = new ContactPerson({
            organizationType: 'garage',
        });
    }

    public handleGarageChange(editedGarage: ContactPerson) {
        mergeRecord<ContactPerson>(this.garageForEditor, editedGarage);

        this.saveReport();
    }

    public handleGarageEditorClose(editedGarage: ContactPerson) {
        if (!editedGarage) {
            this.garageForEditor = null;
            return;
        }
        this.handleGarageChange(editedGarage);
        this.report.garage.contactPerson = this.garageForEditor;
        if (!this.report.garage.selectedFeeSetId) {
            this.garageFeesDialogShown = true;
        }
        this.garageForEditor = null;

        this.saveReport();
    }

    public selectDefaultGarageFeeSetIfEmpty() {
        if (!this.report.garage.selectedFeeSetId) {
            this.selectDefaultGarageFeeSet(this.report.garage.contactPerson);
            this.saveReport();
        }
    }

    public selectDefaultGarageFeeSet(garageContactPerson: ContactPerson) {
        this.report.garage.selectedFeeSetId = garageContactPerson.garageFeeSets?.find(
            (feeSet) => feeSet.isDefault,
        )?._id;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Adjustments
    //****************************************************************************/
    public formatPercentage(value: number): string {
        return `${value}%`;
    }

    protected mayCarOwnerDeductTaxes(): boolean {
        return mayCarOwnerDeductTaxes(this.report);
    }

    protected saveDecreaseFromDamagePercentage() {
        this.team.preferences.valuation_decreasePercentage = this.report.valuation.decreaseFromDamagePercentage;
        this.saveTeam();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Adjustments
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Rounding
    //****************************************************************************/
    protected openEditRoundConfiguration() {
        const overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.roundDecreaseButton)
                .withPositions([
                    // Below the anchor
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                ])
                .withDefaultOffsetY(15),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        overlayRef.backdropClick().subscribe(() => overlayRef.detach());

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
            ],
        });

        const componentRef = overlayRef.attach(
            new ComponentPortal(ValuationRoundDecreaseConfigurationComponent, null, overlayRefInjector),
        );

        componentRef.instance.previewAmount = this.report.damageCalculation.repair.repairCostsGross;

        componentRef.instance.teamPreferences = this.team.preferences;

        componentRef.instance.teamPreferencesChange.subscribe(() => {
            this.saveTeam();
            this.emitDecreaseFromDamageChanged();
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Rounding
    /////////////////////////////////////////////////////////////////////////////*/

    public emitDecreaseFromDamageChanged() {
        const roundPercentage = this.report.valuation.decreaseFromDamagePercentage / 100;

        if (
            this.report.damageCalculation.repair.repairCostsGross ||
            this.report.damageCalculation.repair.repairCostsNet
        ) {
            if (mayCarOwnerDeductTaxes(this.report)) {
                this.report.valuation.decreaseFromDamageNet = roundToNearest({
                    toBeRounded: this.report.damageCalculation.repair.repairCostsNet * roundPercentage,
                    stepSize: this.team.preferences.valuation_decreaseRoundToFull,
                    type: this.team.preferences.valuation_decreaseRoundType,
                });

                /**
                 * Keep net and gross values in sync
                 * - calculate the gross from the rounded net value
                 * - round to full euros
                 */
                this.report.valuation.decreaseFromDamageGross = roundToNearest({
                    toBeRounded: this.report.valuation.decreaseFromDamageNet * 1.19,
                    stepSize: 1,
                    type: this.team.preferences.valuation_decreaseRoundType,
                });
            } else {
                this.report.valuation.decreaseFromDamageGross = roundToNearest({
                    toBeRounded: this.report.damageCalculation.repair.repairCostsGross * roundPercentage,
                    stepSize: this.team.preferences.valuation_decreaseRoundToFull,
                    type: this.team.preferences.valuation_decreaseRoundType,
                });

                /**
                 * Keep net and gross values in sync
                 * - calculate the net from the rounded gross value
                 * - round to full euros
                 */
                this.report.valuation.decreaseFromDamageNet = roundToNearest({
                    toBeRounded: this.report.valuation.decreaseFromDamageGross / 1.19,
                    stepSize: 1,
                    type: this.team.preferences.valuation_decreaseRoundType,
                });
            }
            this.selectTab('adjustments');
        } else {
            this.report.valuation.decreaseFromDamageGross = null;
            this.report.valuation.decreaseFromDamageNet = null;
            this.selectTab('garage');
        }
        this.decreaseFromDamageChanged.emit();
    }

    //*****************************************************************************
    //  Util
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }
    /**
     * Save reports to the server.
     */
    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        if (this.isReportLocked()) {
            return;
        }

        return this.reportDetailsService.patch(this.report, { waitForServer }).catch((error) => {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
            throw error;
        });
    }

    public saveTeam(): Promise<Team> {
        return this.teamService.put(this.team);
    }

    translateRoundType(roundType: 'round' | 'floor' | 'ceil'): string {
        switch (roundType) {
            case 'round':
                return 'runden';
            case 'floor':
                return 'abrunden';
            case 'ceil':
                return 'aufrunden';
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Util
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Subtype } from '@autoixpert/helper-types/subtype';
import { DocumentMetadata, DocumentType } from '@autoixpert/models/documents/document-metadata';
import { BadRequest } from '../../models/errors/ax-error';
import { Invoice, InvoiceInvolvedParty } from '../../models/invoices/invoice';
import { PaymentReminder, PaymentReminderLevel } from '../../models/invoices/payment-reminder';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { makeLuxonDateTime, toGermanDate } from '../ax-luxon';
import { convertToEuro } from './convert-to-euro';
import { getContactPersonPlaceholderObject } from './get-contact-person-placeholder-object';
import { getUserPlaceholderObject } from './get-user-placeholder-object';
import {
    PlaceholderValuesPaymentReminder,
    PlaceholderValuesPaymentRemindersForInvoice,
    PlaceholderValuesPaymentRemindersForPaymentReminderDocument,
} from './placeholder-values.types';
import { Translator } from './translator';

/**
 * These values are generated for every report that has a connected invoice and every invoice.
 *
 * Since multiple payment reminders of the same level may exist, the *latest* payment reminder is used for the placeholder generation.
 * Example: The assessor first reminds the insurance. Then the insurance tells the assessor that the money was already sent to the lawyer.
 * The assessor then reminds the lawyer to transfer the money.
 *
 * Update 2025/02:
 * Before the update, payment reminder objects on invoice involved parties were created when creating an invoice.
 * After the update those payment reminder objects are only created, if a payment reminder document is created.
 *
 * This change allows the UI to rely on the involved parties instead of the documents array, which provides a much cleaner state handling in the UI.
 * The following code does rely on the documents array instead of the involved parties.
 * Since the following function does not separate between multiple payment reminders of the same level,
 * relying on the documents array is fine and using the involved parties would be more complex in this scenario.
 * The UI ensures, that document array and involved parties are always in sync. Therefore the following code does still work and remains unchanged.
 */
export function getTemplatePlaceholderValuesPaymentRemindersForInvoice({
    invoice,
    user,
    team,
    teamMembers,
}: {
    invoice: Invoice;
    user: User;
    team: Team;
    teamMembers: User[];
}): PlaceholderValuesPaymentRemindersForInvoice {
    const paymentReminderLevel0Document: DocumentMetadata = getNewestPaymentReminderDocument(
        invoice.documents,
        'paymentReminderLevel0',
    );
    const paymentReminderLevel1Document: DocumentMetadata = getNewestPaymentReminderDocument(
        invoice.documents,
        'paymentReminderLevel1',
    );
    const paymentReminderLevel2Document: DocumentMetadata = getNewestPaymentReminderDocument(
        invoice.documents,
        'paymentReminderLevel2',
    );
    const paymentReminderLevel3Document: DocumentMetadata = getNewestPaymentReminderDocument(
        invoice.documents,
        'paymentReminderLevel3',
    );

    const paymentReminderDocuments: DocumentMetadata[] = [
        paymentReminderLevel0Document,
        paymentReminderLevel1Document,
        paymentReminderLevel2Document,
        paymentReminderLevel3Document,
    ];
    let highestPaymentReminderDocumentIndex: number;
    // Reverse in order to go from back to front, allowing the first payment reminder documents to be missing. This covers the case that the user started with the second or third (etc.) payment reminder.
    let firstUndefinedPaymentReminderIndex: number;
    for (let i = paymentReminderDocuments.length - 1; i >= 0; i--) {
        if (!paymentReminderDocuments[i]) {
            firstUndefinedPaymentReminderIndex = i;
            break;
        }
    }
    // If all documents are defined, the last document is the highest payment reminder.
    if (firstUndefinedPaymentReminderIndex === -1) {
        highestPaymentReminderDocumentIndex = paymentReminderDocuments.length - 1;
    } else {
        highestPaymentReminderDocumentIndex = firstUndefinedPaymentReminderIndex - 1;
    }
    const highestPaymentReminderDocument: DocumentMetadata =
        paymentReminderDocuments[highestPaymentReminderDocumentIndex];

    const paymentReminderLevel0: PaymentReminder =
        paymentReminderLevel0Document && getPaymentReminderByDocument(invoice, paymentReminderLevel0Document);
    const paymentReminderLevel1: PaymentReminder =
        paymentReminderLevel1Document && getPaymentReminderByDocument(invoice, paymentReminderLevel1Document);
    const paymentReminderLevel2: PaymentReminder =
        paymentReminderLevel2Document && getPaymentReminderByDocument(invoice, paymentReminderLevel2Document);
    const paymentReminderLevel3: PaymentReminder =
        paymentReminderLevel3Document && getPaymentReminderByDocument(invoice, paymentReminderLevel3Document);
    const highestPaymentReminder: PaymentReminder =
        highestPaymentReminderDocument && getPaymentReminderByDocument(invoice, highestPaymentReminderDocument);

    return {
        Zahlungserinnerung:
            paymentReminderLevel0 &&
            getTemplatePlaceholderValuesPaymentReminder({
                paymentReminder: paymentReminderLevel0,
                paymentReminderRecipientRole: paymentReminderLevel0Document.recipientRole,
                invoice,
                teamMembers,
                team,
                user,
            }),
        ErsteMahnung:
            paymentReminderLevel1 &&
            getTemplatePlaceholderValuesPaymentReminder({
                paymentReminder: paymentReminderLevel1,
                paymentReminderRecipientRole: paymentReminderLevel1Document.recipientRole,
                invoice,
                teamMembers: teamMembers,
                team,
                user,
            }),
        ZweiteMahnung:
            paymentReminderLevel2 &&
            getTemplatePlaceholderValuesPaymentReminder({
                paymentReminder: paymentReminderLevel2,
                paymentReminderRecipientRole: paymentReminderLevel2Document.recipientRole,
                invoice,
                teamMembers: teamMembers,
                team,
                user,
            }),
        DritteMahnung:
            paymentReminderLevel3 &&
            getTemplatePlaceholderValuesPaymentReminder({
                paymentReminder: paymentReminderLevel3,
                paymentReminderRecipientRole: paymentReminderLevel3Document.recipientRole,
                invoice,
                teamMembers: teamMembers,
                team,
                user,
            }),
        HöchsteMahnung:
            highestPaymentReminder &&
            getTemplatePlaceholderValuesPaymentReminder({
                paymentReminder: highestPaymentReminder,
                paymentReminderRecipientRole: highestPaymentReminderDocument.recipientRole,
                invoice,
                teamMembers: teamMembers,
                team,
                user,
            }),
    };
}

/**
 * When a payment reminder document is being rendered, the placeholder nodes "Mahnung" and "VorherigeMahnung"
 * are different from the other fixed placeholder nodes like "ErsteMahnung", "Zahlungserinnerung", ...
 *
 * This allows one document, e.g., paymentReminderLevel0 to have one document building block which varies depending
 * on the recipient. One invoice may have multiple paymentReminderLevel0 documents - one for each involved party.
 * That's relevant for when a payment reminder process is started with one involved party but is started all
 * over with a second involved party.
 * Example: The assessor reminds the insurance. The insurance lets the assessor know that the money was already paid
 * to the lawyer. The assessor then sends a new payment reminder to the lawyer.
 */
export function getPlaceholderValuesPaymentReminderDocument({
    invoice,
    user,
    team,
    teamMembers,
    paymentReminderDocumentMetadata,
}: {
    invoice: Invoice;
    user: User;
    team: Team;
    teamMembers: User[];
    paymentReminderDocumentMetadata: DocumentMetadata;
}): PlaceholderValuesPaymentRemindersForPaymentReminderDocument {
    const paymentReminder: PaymentReminder = getPaymentReminderByDocument(invoice, paymentReminderDocumentMetadata);

    let previousPaymentReminder: PaymentReminder;
    let previousPaymentReminderDocumentMetadata: DocumentMetadata;
    const paymentReminderLevel: number = getIntegerFromPaymentReminderLevel(paymentReminder.level);
    if (paymentReminderLevel > 0) {
        const previousPaymentReminderLevel: number = paymentReminderLevel - 1;
        previousPaymentReminderDocumentMetadata = invoice.documents.find(
            (document) =>
                document.recipientRole === paymentReminderDocumentMetadata.recipientRole &&
                document.type === `paymentReminderLevel${previousPaymentReminderLevel}`,
        );
        // Make this optional in case the assessor deleted the previous payment reminder document.
        previousPaymentReminder =
            previousPaymentReminderDocumentMetadata &&
            getPaymentReminderByDocument(invoice, previousPaymentReminderDocumentMetadata);
    }

    const templatePlaceholderValuesPaymentReminder = getTemplatePlaceholderValuesPaymentReminder({
        paymentReminder: paymentReminder,
        paymentReminderRecipientRole: paymentReminderDocumentMetadata.recipientRole,
        invoice,
        teamMembers: teamMembers,
        team,
        user,
    });

    return {
        Mahnung: templatePlaceholderValuesPaymentReminder,
        /**
         * Given two payment reminders at level 0, users expect that the template placeholder values for "Zahlungserinnerung"
         * are not based on the reminder at level 0 generated last but on the currently generated payment reminder level 0 document.
         *
         * Usually, the placeholder Zahlungserinnerung fetches data about the last payment reminder at level 0.
         */
        ...(paymentReminderLevel === 0 ? { Zahlungserinnerung: templatePlaceholderValuesPaymentReminder } : {}),

        /**
         * The previous payment reminder may be referenced in the current payment reminder to let the recipient know
         * when the previous payment reminder was written.
         * It's optional since it only exists after payment reminder level 1 was written (level 0 would be the previous one then).
         */
        VorherigeMahnung:
            previousPaymentReminderDocumentMetadata &&
            getTemplatePlaceholderValuesPaymentReminder({
                paymentReminder: previousPaymentReminder,
                paymentReminderRecipientRole: previousPaymentReminderDocumentMetadata.recipientRole,
                invoice,
                teamMembers: teamMembers,
                team,
                user,
            }),
    };
}

function getTemplatePlaceholderValuesPaymentReminder({
    paymentReminder,
    paymentReminderRecipientRole,
    invoice,
    teamMembers,
    team,
    user,
}: {
    paymentReminder: PaymentReminder;
    paymentReminderRecipientRole: DocumentMetadata['recipientRole'];
    invoice: Invoice;
    teamMembers: User[];
    team: Team;
    user: User;
}): PlaceholderValuesPaymentReminder {
    const paymentReminderRecipient: InvoiceInvolvedParty =
        invoice[convertDocumentRecipientRoleToInvoiceInvolvedPartyRole(paymentReminderRecipientRole)];
    if (!paymentReminderRecipient) {
        throw new BadRequest({
            code: 'INVALID_RECIPIENT_ROLE_ON_PAYMENT_REMINDER',
            message: 'Please specify a valid recipient role for this payment reminder.',
            data: {
                invoiceId: invoice._id,
                recipientRole: paymentReminderRecipientRole,
                paymentReminderLevel: paymentReminder.level,
            },
        });
    }

    //*****************************************************************************
    //  Sender
    //****************************************************************************/
    let sender: User;
    if (!paymentReminder.senderId || paymentReminder.senderId === user._id) {
        sender = user;
    } else {
        sender = teamMembers.find((teamMember) => teamMember._id === paymentReminder.senderId);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sender
    /////////////////////////////////////////////////////////////////////////////*/

    let numberOfDaysBetweenDateAndDueDate: number = null;
    if (paymentReminder.date && paymentReminder.dueDate) {
        numberOfDaysBetweenDateAndDueDate = makeLuxonDateTime(paymentReminder.dueDate).diff(
            makeLuxonDateTime(paymentReminder.date),
            'days',
        ).days;
    }

    return {
        Stufe: getIntegerFromPaymentReminderLevel(paymentReminder.level), // 0, 1, 2 or 3
        Datum: paymentReminder.date ? toGermanDate(paymentReminder.date) : '',
        Zahlungsziel: paymentReminder.dueDate ? toGermanDate(paymentReminder.dueDate) : '',
        ZahlungszielTage: numberOfDaysBetweenDateAndDueDate,
        Mahnbetrag: paymentReminder.amountNet ? convertToEuro(paymentReminder.amountNet) : 0,
        Absender: getUserPlaceholderObject({
            user: sender,
            team,
            officeLocationId: invoice.officeLocationId,
        }),
        Empfänger: {
            ...getContactPersonPlaceholderObject(paymentReminderRecipient.contactPerson),
            Typ: Translator.invoiceInvolvedPartyType(paymentReminder.recipientRole),
        },
        Ersteller: getUserPlaceholderObject({
            user: sender,
            team,
            officeLocationId: invoice.officeLocationId,
        }),
    };
}

function getNewestPaymentReminderDocument(
    documents: DocumentMetadata[],
    paymentReminderDocumentType: PaymentReminderDocumentType,
) {
    const relevantDocuments = documents.filter(
        (documentMetadata) => paymentReminderDocumentType === documentMetadata.type,
    );

    // Sort the newest document to the front of the array.
    relevantDocuments.sort((documentA, documentB) => {
        const dateA = makeLuxonDateTime(documentA.date);
        const dateB = makeLuxonDateTime(documentB.date);

        return dateA < dateB ? 1 : -1;
    });

    return relevantDocuments[0];
}

function getPaymentReminderByDocument(invoice: Invoice, documentMetadata: DocumentMetadata): PaymentReminder {
    const paymentReminderLevel: number = getPaymentReminderLevelFromDocumentType(documentMetadata.type);
    return invoice[convertDocumentRecipientRoleToInvoiceInvolvedPartyRole(documentMetadata.recipientRole)]
        .paymentReminders[`level${paymentReminderLevel}`];
}

function convertDocumentRecipientRoleToInvoiceInvolvedPartyRole(
    documentRecipientRole: DocumentMetadata['recipientRole'],
): 'recipient' | 'lawyer' | 'insurance' | 'claimant' {
    return documentRecipientRole === 'invoiceRecipient'
        ? 'recipient'
        : (documentRecipientRole as 'lawyer' | 'insurance' | 'claimant');
}

function getPaymentReminderLevelFromDocumentType(documentType: DocumentMetadata['type']): number {
    if (!documentType?.includes('paymentReminderLevel')) {
        return null;
    }

    const level: string = documentType.replace(/paymentReminderLevel/, '');
    return +level;
}

function getIntegerFromPaymentReminderLevel(paymentReminderLevel: PaymentReminderLevel): number {
    return +paymentReminderLevel.replace(/level/, '');
}

export type PaymentReminderDocumentType = Subtype<
    DocumentType,
    'paymentReminderLevel0' | 'paymentReminderLevel1' | 'paymentReminderLevel2' | 'paymentReminderLevel3'
>;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    RevenueByCityAnalyticsRecord,
    RevenueByCustomerAnalyticsRecord,
} from '@autoixpert/models/analytics/customer-analytics.model';
import { RevenueByReportTypeAnalyticsRecord } from '@autoixpert/models/analytics/report-type-analytics.model';
import {
    RevenueByAssessorAnalyticsRecord,
    RevenueByCarManufacturerAnalyticsRecord,
    RevenueByGarageAnalyticsRecord,
    RevenueByInsuranceAnalyticsRecord,
    RevenueByLawyerAnalyticsRecord,
    RevenueByMonthAnalyticsRecord,
} from '@autoixpert/models/analytics/revenue-analytics.model';
import { AnalyticsFilterOptions, prepareDefaultParametersForAnalyticsQuery } from './default-analytics-query-parameter';

@Injectable()
export class RevenueAnalyticsService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getRevenueByMonth(filterOptions: AnalyticsFilterOptions): Observable<RevenueByMonthAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByMonthAnalyticsRecord[]>(`${this.apiPath}/analytics/revenueByMonth`, {
            params: prepareDefaultParametersForAnalyticsQuery({ dateField: 'date', ...filterOptions }),
        });
    }

    public getRevenueByReportType(
        filterOptions: AnalyticsFilterOptions,
    ): Observable<RevenueByReportTypeAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByReportTypeAnalyticsRecord[]>(
            `${this.apiPath}/analytics/revenueByReportType`,
            {
                params: prepareDefaultParametersForAnalyticsQuery({
                    dateField: 'date',
                    ...filterOptions,
                }),
            },
        );
    }

    public getRevenueByAssessor(filterOptions: AnalyticsFilterOptions): Observable<RevenueByAssessorAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByAssessorAnalyticsRecord[]>(`${this.apiPath}/analytics/revenueByAssessor`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'date',
                ...filterOptions,
            }),
        });
    }

    public getRevenueByCarManufacturer(
        filterOptions: AnalyticsFilterOptions,
    ): Observable<RevenueByCarManufacturerAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByCarManufacturerAnalyticsRecord[]>(
            `${this.apiPath}/analytics/revenueByCarManufacturer`,
            {
                params: prepareDefaultParametersForAnalyticsQuery({ dateField: 'date', ...filterOptions }),
            },
        );
    }

    public getRevenueByCity(filterOptions: AnalyticsFilterOptions): Observable<RevenueByCityAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByCityAnalyticsRecord[]>(`${this.apiPath}/analytics/revenueByCity`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'date',
                ...filterOptions,
            }),
        });
    }

    public getRevenueByCustomer(filterOptions: AnalyticsFilterOptions): Observable<RevenueByCustomerAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByCustomerAnalyticsRecord[]>(`${this.apiPath}/analytics/revenueByCustomer`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'date',
                ...filterOptions,
            }),
        });
    }

    public getRevenueByInsurance(
        filterOptions: AnalyticsFilterOptions,
    ): Observable<RevenueByInsuranceAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByInsuranceAnalyticsRecord[]>(
            `${this.apiPath}/analytics/revenueByInsurance`,
            {
                params: prepareDefaultParametersForAnalyticsQuery({
                    dateField: 'date',
                    ...filterOptions,
                }),
            },
        );
    }

    public getRevenueByGarage(filterOptions: AnalyticsFilterOptions): Observable<RevenueByGarageAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByGarageAnalyticsRecord[]>(`${this.apiPath}/analytics/revenueByGarage`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'date',
                ...filterOptions,
            }),
        });
    }

    public getRevenueByLawyer(filterOptions: AnalyticsFilterOptions): Observable<RevenueByLawyerAnalyticsRecord[]> {
        return this.httpClient.get<RevenueByLawyerAnalyticsRecord[]>(`${this.apiPath}/analytics/revenueByLawyer`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'date',
                ...filterOptions,
            }),
        });
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CustomFieldButtonTriggerService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public triggerButton(reportId: string, webhookUrl: string, webhookEventType: string) {
        return this.httpClient.post<{ success: boolean; error?: string; response?: string }>(
            `${this.apiPath}/reports/${reportId}/triggerCustomFieldButton`,
            {
                webhookUrl,
                webhookEventType,
            },
        );
    }
}

/**
 * Number of VINs a test user may query during the test period.
 *
 * We limit both DAT and Audatex.
 *
 * Reasoning:
 * - DAT: Few users cause most of our VIN costs with DAT. By limiting the usage of DAT VINs, we try to
 * limit our costs.
 * - AUDATEX: Some moochers have used our test accounts repeatedly to get free Audatex calculations. By
 * limiting the usage of Audatex VINs, we try to keep out moochers.
 */
export const NUMBER_OF_VINS_DURING_TEST = 5 as const;

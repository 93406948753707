import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';
import { Timestamps } from '../../timestamps';

export class CustomMarketAnalysis implements Timestamps {
    constructor(template: Partial<CustomMarketAnalysis> = {}) {
        Object.assign(this, template);
    }

    _id = generateId();
    title: string; // Only used if the user selected source: custom.
    retrievedAt: string;
    averagePrice: number;
    maxCorridor: number;
    minCorridor: number;
    corridorHiddenOnPrintout: boolean;
    createdBy: string;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

<div id="recipient-container" *ngIf="contactPerson">
    <ng-container
        *ngIf="
            contactPerson.organization || contactPerson.firstName || contactPerson.lastName;
            then withRecipient;
            else noRecipient
        "></ng-container>
    <!--********** Recipient Selected **********-->
    <ng-template #withRecipient>
        <div id="recipient" [class.disabled]="disabled" (click)="!disabled && openContactPersonSelector()">
            <div *ngIf="contactPerson.organization">{{ contactPerson.organization }}</div>
            <div *ngIf="contactPerson.firstName || contactPerson.lastName">
                {{ contactPerson.salutation }} {{ contactPerson.firstName }} {{ contactPerson.lastName }}
            </div>
            <div>{{ contactPerson.streetAndHouseNumberOrLockbox }}</div>
            <div>{{ contactPerson.zip }} {{ contactPerson.city }}</div>
        </div>
    </ng-template>

    <!--********** Recipient not yet selected **********-->
    <ng-template #noRecipient>
        <div id="no-recipient" (click)="!disabled && openContactPersonSelector()">
            <div id="no-recipient-icon-ring">
                <img src="/assets/images/icons/claimant-blue.svg" />
            </div>
            <div class="label">Empfänger auswählen</div>
        </div>
    </ng-template>
    <mat-icon
        id="recipient-edit-icon"
        *ngIf="!disabled"
        (click)="!disabled && openContactPersonSelector()"
        matTooltip="Empfänger auswählen">
        edit
    </mat-icon>
</div>

<!--============================================-->
<!-- Contact Person Selector -->
<!--============================================-->
<contact-person-editor
    *ngIf="contactPersonSelectorVisible"
    [contactPerson]="contactPerson"
    [autocompleteGroupNames]="autocompleteGroupNames"
    [contactPeopleForQuickSelection]="contactPeopleForQuickSelection"
    (contactPersonChange)="emitContactPersonChange($event)"
    [hideIban]="hideIban"
    [vatFieldsAllowed]="true"
    [showWebsite]="false"
    (close)="closeContactPersonSelector()"></contact-person-editor>
<!--============================================-->
<!-- END Contact Person Selector -->
<!--============================================-->

<div class="display-flex flex-align-center" *ngIf="filteredDowntimeNotifications.length">
    <!--********** To Previous Notification **********-->
    <mat-icon
        id="arrow-left"
        class="arrow-icon"
        [ngClass]="{ invisible: notificationNavigationCounter <= 0 }"
        (click)="navigateToPreviousNotification()"
        matTooltip="Vorherige Meldung">
        chevron_left
    </mat-icon>

    <!--============================================-->
    <!-- Notification Content -->
    <!--============================================-->
    <div
        class="downtime-notification-content"
        [ngClass]="{
            'downtime-is-today': downtimeIsToday(filteredDowntimeNotifications[notificationNavigationCounter]),
        }">
        <div class="downtime-notification-image-container">
            <ng-container [ngSwitch]="filteredDowntimeNotifications[notificationNavigationCounter].affectedUserGroup">
                <!--********** Qapter-iXpert **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/qapterixpert-q-white.svg"
                    *ngIf="
                        filteredDowntimeNotifications[notificationNavigationCounter].affectedUserGroup ===
                            'qapterixpert' ||
                        (filteredDowntimeNotifications[notificationNavigationCounter].affectedUserGroup === 'all' &&
                            isQapterixpert())
                    "
                    matTooltip="Qapter-iXpert" />

                <!--********** autoiXpert **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/autoixpert-x.png"
                    *ngIf="
                        filteredDowntimeNotifications[notificationNavigationCounter].affectedUserGroup ===
                            'autoixpert' ||
                        (filteredDowntimeNotifications[notificationNavigationCounter].affectedUserGroup === 'all' &&
                            !isQapterixpert())
                    " />

                <!--********** Audatex Qapter **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/qapter-logo-white-orange.png"
                    *ngSwitchCase="'qapter'" />

                <!--********** AUTOonline **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/autoonline-logo-inverted.svg"
                    *ngSwitchCase="'autoonline'" />

                <!--********** DAT **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/dat-logo-no-text.png"
                    *ngSwitchCase="'dat'" />

                <!--********** car.casion **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/car-casion.svg"
                    *ngSwitchCase="'carcasion'" />

                <!--********** CARTV **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/cartv-inverted.png"
                    *ngSwitchCase="'cartv'" />

                <!--********** Winvalue **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/winvalue.png"
                    *ngSwitchCase="'winvalue'" />

                <!--********** GDV **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/gdv-logo.svg"
                    *ngSwitchCase="'gdv'" />

                <!--********** GT Motive **********-->
                <img
                    class="downtime-notification-partner-logo"
                    src="/assets/images/logos/gtmotive-logo-no-text.png"
                    *ngSwitchCase="'gtmotive'" />
            </ng-container>
        </div>

        <!--********** Message **********-->
        <div
            class="downtime-notification-message"
            [innerHTML]="filteredDowntimeNotifications[notificationNavigationCounter].message"></div>

        <!--********** Close Icon **********-->
        <mat-icon
            class="close-icon medium-icon"
            (click)="
                removeDowntimeNotificationFromSession(filteredDowntimeNotifications[notificationNavigationCounter])
            "
            matTooltip="Meldung schließen">
            close
        </mat-icon>
    </div>
    <!--============================================-->
    <!-- END Notification Content -->
    <!--============================================-->

    <!--********** To Next Notification **********-->
    <mat-icon
        id="arrow-right"
        class="arrow-icon"
        [ngClass]="{ invisible: notificationNavigationCounter === filteredDowntimeNotifications.length - 1 }"
        (click)="navigateToNextNotification()"
        matTooltip="Nächste Meldung">
        chevron_right
    </mat-icon>
</div>

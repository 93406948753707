export function insertAfterArrayElement<ElementType>({
    array,
    newElement,
    referenceElement,
}: {
    array: ElementType[];
    newElement: ElementType;
    referenceElement: ElementType;
}): void {
    /**
     * Instead of using array.indexOf(), this allows inserting elements relative to primitive types like string.
     */
    const referenceIndex: number = array.findIndex((element) => element === referenceElement);
    array.splice(referenceIndex + 1, 0, newElement);
}

import { CounterPattern } from '@autoixpert/lib/counters/counter-pattern';
import { InvoiceNumberOrReportTokenCounter } from '@autoixpert/models/teams/invoice-number-or-report-token-counter';
import { InvoiceOrReportCounterConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';

/**
 * The TokenPreview may be used to preview the filled pattern for the next counter value.
 * ReportTokenPatterns may be synced with the invoice counter.
 */
export function getTokenPreview(
    counter: InvoiceNumberOrReportTokenCounter,
    counterConfig: InvoiceOrReportCounterConfig,
    counterType: 'reportToken' | 'invoiceNumber',
    syncWithInvoiceCounter: boolean,
    team: Team,
    user: User,
    pattern?: string,
    counterToSyncWith?: InvoiceNumberOrReportTokenCounter,
    reportToken?: string,
): string {
    /**
     * ReportTokens may be synced with the invoice counter. If this is the case, the next counter is the invoice counter + 1.
     */
    let nextCounter = (counter?.count ?? 0) + 1;
    if (counterType === 'reportToken' && syncWithInvoiceCounter) {
        nextCounter = (counterToSyncWith?.count ?? 0) + 1;
    }

    return fillTokenPreview(pattern || counterConfig.pattern, nextCounter, counterConfig, team, user, reportToken);
}

/**
 * Fill the token pattern with the information about the current user and office location.
 */
function fillTokenPreview(
    pattern: string,
    counter: number,
    counterConfig: InvoiceOrReportCounterConfig,
    team: Team,
    user: User,
    reportToken?: string,
) {
    const counterPattern = new CounterPattern(pattern);

    /**
     * Load associated office location
     */
    let associatedOfficeLocation: OfficeLocation;
    if (counterPattern.includesOfficeLocationInitials()) {
        // If this counter is assigned to an office location, preview the first assigned office location.
        if (counterConfig.associatedOfficeLocationIds.length > 0) {
            associatedOfficeLocation = team.officeLocations.find(
                (officeLocation) => officeLocation._id === counterConfig.associatedOfficeLocationIds[0],
            );
        }
        // Use the user's default office location.
        else {
            associatedOfficeLocation = team.officeLocations.find(
                (officeLocation) => officeLocation._id === user.defaultOfficeLocationId,
            );
        }
    }

    /**
     * Fill pattern with all data
     */
    return counterPattern.fill(counter, {
        reportType: 'liability',
        associatedUser: user,
        associatedOfficeLocation,
        reportToken,
    });
}

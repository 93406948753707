<div
    id="office-location-container"
    [class.horizontalDisplay]="horizontalDisplay"
    [class.no-office-location-selected]="selectedOfficeLocationIds.length === 0">
    <div class="associated-office-location" *ngFor="let officeLocation of getSelectedOfficeLocations()">
        <mat-icon class="home-icon">home</mat-icon>
        <div class="office-location-name label" [matTooltip]="officeLocation.title">{{ officeLocation.title }}</div>
        <mat-icon
            class="remove-office-location toolbar-icon medium-icon"
            (click)="unselectOfficeLocation(officeLocation._id)">
            close
        </mat-icon>
    </div>

    <section
        id="select-office-location-container"
        class="text-align-center"
        [matTooltip]="availableOfficeLocations.length < 1 ? 'Es sind bereits alle Standorte zugeordnet.' : ''">
        <button
            class="flat"
            [class.with-shadow]="horizontalDisplay && selectedOfficeLocationIds.length < 1"
            [matMenuTriggerFor]="officeLocationMenu"
            [ngClass]="{ disabled: availableOfficeLocations.length < 1 }"
            [disabled]="availableOfficeLocations.length < 1">
            + Standort
        </button>
        <mat-menu #officeLocationMenu="matMenu">
            <a
                mat-menu-item
                *ngFor="let officeLocation of availableOfficeLocations"
                class="menu-item-with-icon"
                (click)="selectOfficeLocation(officeLocation._id)">
                <mat-icon>home</mat-icon>
                {{ officeLocation.title }}
            </a>
        </mat-menu>
    </section>
</div>

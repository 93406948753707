import { Claimant } from '../../models/reports/involved-parties/claimant';
import { InvolvedParty } from '../../models/reports/involved-parties/involved-party';
import { Report } from '../../models/reports/report';

/**
 * Returns either the claimant or the owner of the claimants car.
 */
export function getCarOwner(report: Pick<Report, 'claimant' | 'ownerOfClaimantsCar'>): Claimant | InvolvedParty {
    return report.claimant.isOwner ? report.claimant : report.ownerOfClaimantsCar;
}

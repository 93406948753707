<div id="layout-container">
    <!--============================================-->
    <!-- Menu -->
    <!--============================================-->
    <div id="menu-column"></div>
    <!--============================================-->
    <!-- END Menu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Reporting Column -->
    <!--============================================-->
    <div id="reporting-column">
        <!--============================================-->
        <!-- Graph -->
        <!--============================================-->
        <div id="graph-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Graph Header -->
            <!--============================================-->
            <div id="graph-header">
                <!--********** Report Name **********-->
                <div id="report-name-container">
                    <mat-icon
                        class="toolbar-icon"
                        matTooltip="Zurück zur Übersicht"
                        (click)="navigateBackToAnalyticsList()">
                        arrow_back
                    </mat-icon>
                    <h2>{{ chartTitle }}</h2>
                </div>

                <analytics-filter-button [filterRef]="filter"></analytics-filter-button>

                <!--********** Date Filter **********-->
                <div class="date-and-settings-container">
                    <date-range-picker
                        [(dateFrom)]="filterAnalyticsFrom"
                        [(dateTo)]="filterAnalyticsTo"
                        (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
                    <analytics-settings-menu
                        (useNetValuesChange)="refreshLocalData()"
                        useNetValuesHelpIndicatorTooltip='Nettowerte für Zahlungseingänge (z. B. für Berechnung von "davon unbezahlt") werden vom System nicht explizit abgefragt, wurden aber unter der Annahme von 19 % MwSt. aus Bruttowerten berechnet.'></analytics-settings-menu>
                </div>
            </div>

            <!--============================================-->
            <!-- END Graph Header -->
            <!--============================================-->

            <analytics-filter (filterChange)="updateAnalytics()" (initialized)="filtersLoaded()"></analytics-filter>

            <div class="chart-container">
                <div class="chart-placeholder" *ngIf="analyticsPending">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Lade Auswertung ...
                </div>
                <div class="chart-placeholder" *ngIf="!analyticsPending && records.length === 0">
                    <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                    <div class="no-data-found-message">Keine Daten gefunden</div>
                    Probiere doch andere Filter.
                </div>
                <div id="chart" *ngIf="!analyticsPending && records.length"></div>
            </div>

            <!--============================================-->
            <!-- Graph Summary -->
            <!--============================================-->
            <div id="graph-summary-container" class="card">
                <!--********** Total **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Gesamtumsatz
                        <span class="series-color analytics-series-1"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ totalRevenue | number: '1.0-0' }} €</div>
                </div>

                <!--********** Outstanding **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Davon offen
                        <span class="series-color negative-color"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ outstandingRevenue | number: '1.0-0' }}
                    </div>
                </div>

                <!--********** Invoices **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Rechnungen</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalNumberOfInvoices | number: '1.0-0' }}
                    </div>
                </div>

                <!--********** Lawyers **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Anwälte</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ records.length }}
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Graph Summary -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Graph -->
        <!--============================================-->

        <!--============================================-->
        <!-- Series List -->
        <!--============================================-->
        <div id="series-list-card" class="card card-with-padding">
            <div id="series-list-toolbar">
                <export-invoice-analytics-records-icon
                    [records]="records"
                    [summaryDownloadName]="chartTitle"></export-invoice-analytics-records-icon>
            </div>
            <div id="series-list-table-container">
                <table id="series-list">
                    <thead>
                        <tr>
                            <th class="series-entry-title-heading">Kanzlei</th>
                            <th class="series-entry-value-heading">
                                <span class="series-color analytics-series-1"></span>
                                &nbsp; Gesamtumsatz
                                <span *ngIf="settings.useNetValues">(netto)</span>
                                <span *ngIf="!settings.useNetValues">(brutto)</span>
                            </th>
                            <th class="series-entry-value-heading">
                                <span class="series-color negative-color"></span>
                                &nbsp; Davon offen
                            </th>
                            <th class="series-entry-value-heading">
                                <span class="series-color negative-color"></span>
                                &nbsp; Davon offen (%)
                            </th>
                            <th class="series-entry-value-heading">Umsatz pro Rechnung</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="series-entry-row" *ngFor="let record of records">
                            <td class="series-entry-title-cell" [ngClass]="{ 'not-available': !record.lawyer }">
                                <div class="lawyer-container">
                                    {{ record.lawyer || 'Nicht angegeben' }}
                                </div>
                            </td>
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross)
                                        | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfRegularInvoices"
                                    [displayNetValues]="settings.useNetValues"
                                    valueDisplayType="totalAmount">
                                    {{ record.idsOfRegularInvoices.length }}
                                    {{ record.idsOfRegularInvoices.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                </div>
                            </td>
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues
                                        ? record.outstandingRevenueNet
                                        : record.outstandingRevenueGross
                                    ) | number: '1.0-0'
                                }}
                                €
                                <div
                                    class="label"
                                    invoice-list-overlay-anchor
                                    [invoiceIds]="record.idsOfOutstandingInvoices"
                                    [displayNetValues]="settings.useNetValues"
                                    valueDisplayType="unpaidAmount">
                                    {{ record.idsOfOutstandingInvoices.length }}
                                    {{ record.idsOfOutstandingInvoices.length === 1 ? 'Rechnung' : 'Rechnungen' }}
                                </div>
                            </td>
                            <td class="series-entry-value">
                                <div>
                                    {{
                                        (settings.useNetValues
                                            ? record.outstandingRevenueNet / record.totalRevenueNet
                                            : record.outstandingRevenueGross / record.totalRevenueGross) * 100
                                            | number: '1.0-0'
                                    }}
                                    %
                                </div>
                                <div class="outstanding-revenue-progress-bar slim-progress-bar-row">
                                    <!--********** Progress Bar **********-->
                                    <div class="slim-progress-bar-background">
                                        <div
                                            class="slim-progress-bar-fill"
                                            [style.width]="
                                                (settings.useNetValues
                                                    ? record.outstandingRevenueNet / record.totalRevenueNet
                                                    : record.outstandingRevenueGross / record.totalRevenueGross) *
                                                    100 +
                                                '%'
                                            "></div>
                                    </div>
                                </div>
                            </td>
                            <td class="series-entry-value">
                                {{
                                    (settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross) /
                                        record.idsOfRegularInvoices.length || 0 | number: '1.0-0'
                                }}
                                €
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Series List -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Reporting Column -->
    <!--============================================-->
</div>

<span
    [ngSwitch]="getCumulatedChecklistStatus()"
    reportProgressPanelAnchor
    [report]="report"
    [tabName]="reportTabName"
    [reportProgressConfig]="reportProgressConfig"
    [overlayPositionY]="overlayPositionY">
    <!--********** Complete **********-->
    <!-- Use SVG instead of font because it's rendered clearer. -->
    <mat-icon
        class="progress-indicator progress-complete-indicator"
        *ngSwitchCase="'complete'"
        matTooltip="Alle {{ getRequiredItems().length ? 'Pflichtpositionen' : 'Positionen' }} bearbeitet.{{
            '\n\n'
        }}Klicken für Details.">
        check
    </mat-icon>

    <!--********** Partially Complete **********-->
    <mat-icon
        class="progress-indicator progress-partially-complete-indicator"
        *ngSwitchCase="'partiallyComplete'"
        matTooltip="Einige Positionen bearbeitet.{{ '\n\n' }}Klicken für Details.">
        rule
    </mat-icon>

    <!--********** Empty **********-->
    <mat-icon
        class="progress-indicator progress-empty-indicator"
        *ngSwitchCase="'empty'"
        matTooltip="Noch keine Positionen bearbeitet.{{ '\n\n' }}Klicken für Details.">
        more_horiz
    </mat-icon>
</span>

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { CanDeactivateGuard } from '../shared/services/can-deactivate-guard.service';
import { ReportDetailsComponent } from './details/report-details.component';
import { reportDetailsRoutes } from './details/report-details.routes';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportsComponent } from './reports.component';

export const reportsRoutes: Routes = [
    {
        path: '',
        redirectTo: '/Gutachten',
        pathMatch: 'full',
    },
    {
        path: 'Gutachten',
        component: ReportsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            {
                path: '',
                component: ReportListComponent,
            },
            {
                path: ':reportId',
                component: ReportDetailsComponent,
                children: reportDetailsRoutes,
            },
        ],
    },
];

export const reportsRouting = RouterModule.forChild(reportsRoutes);

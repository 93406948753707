import moment from 'moment';
import { Moment } from 'moment';

export function getRelativeDate(offsetAmount: number, offsetUnit: 'businessDays' | 'calendarDays' | 'hours'): Moment {
    const targetDate: Moment = moment();

    switch (offsetUnit) {
        case 'businessDays':
            // Increment one day at a time
            for (let i = 0; i < offsetAmount; i++) {
                targetDate.add(1, 'days');
                // If the target date would be on a weekend, skip forward to Monday
                if (targetDate.isoWeekday() >= 6) {
                    targetDate.isoWeekday(8);
                }
            }
            break;
        case 'calendarDays':
            targetDate.add(offsetAmount, 'days');
            break;
        case 'hours':
            targetDate.add(offsetAmount, 'hours');
            break;
        default:
            throw Error('INVALID_TIME_OFFSET_UNIT');
    }

    return targetDate;
}

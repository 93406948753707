import { Component } from '@angular/core';
import { ScreenTitleService } from '../shared/services/screen-title.service';

@Component({
    selector: 'analytics-module',
    template: '<router-outlet></router-outlet>',
})
export class AnalyticsComponent {
    constructor(private screenTitleService: ScreenTitleService) {}

    ngOnInit() {
        this.screenTitleService.setScreenTitle({ screenTitle: 'Auswertungen' });
    }
}

<div class="dialog-overlay">
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="emitCloseEvent()" matTooltip="Dialog schließen">close</mat-icon>
        <h2 class="margin-bottom-10">Fotobeschreibungen</h2>
        <p class="label text-align-center">
            Lass die Software den ersten Fotos anhand ihrer Position automatisch eine Beschreibung geben.
        </p>
        <div
            id="photo-descriptions-list"
            cdkDropList
            (cdkDropListDropped)="handleSortingDefaultPhotoDescriptions($event)">
            <div
                class="photo-description-drop-item"
                cdkDrag
                *ngFor="let photoDescription of defaultPhotoDescriptions; let index = index">
                <mat-icon cdkDragHandle class="toolbar-icon" matTooltip="Zum Sortieren ziehen">drag_indicator</mat-icon>
                <mat-form-field class="photo-description-input">
                    <input matInput placeholder="{{ index + 1 }}. Foto" [(ngModel)]="photoDescription.title" />
                </mat-form-field>
                <mat-icon
                    class="toolbar-icon"
                    (click)="removeDefaultPhotoDescription(photoDescription)"
                    matTooltip="Löschen">
                    delete
                </mat-icon>
            </div>
        </div>
        <div id="add-photo-description-button-container">
            <button class="flat" (click)="addDefaultPhotoDescription()">+ Neue Beschreibung</button>
        </div>

        <div class="label text-align-center margin-top-20">
            <strong>Gut zu wissen:</strong>
            Die hier hinterlegten Fotobeschreibungen werden bei der Generierung der Schadenbeschreibung aus den Fotos
            ausgeschlossen.
        </div>

        <div class="dialog-buttons-container">
            <button (click)="saveDefaultPhotoDescriptions(); emitCloseEvent()">Speichern</button>
        </div>
    </div>
</div>

import { CarShape } from '@autoixpert/models/reports/car-identification/car';

export function getCarTireImageName(carShape: CarShape): string {
    switch (carShape) {
        case 'bicycle':
        case 'e-bike':
        case 'pedelec':
            return 'tire-bicycle.png';
        case 'motorcycle':
            return 'tire-motorcycle.png';
        default:
            return 'tire.png';
    }
}

<div id="editor-overlay"></div>
<div id="editor-container-positioner">
    <div class="buffer upper" (click)="closeEditor()"></div>
    <div id="editor-container" [ngClass]="{ 'has-info-note': showContextMenuInfoNote }">
        <div class="card-toolbar">
            <mat-menu #toolsSubmenu="matMenu">
                <div
                    mat-menu-item
                    *ngIf="!userPreferences.photoEditorKeepToolActive"
                    (click)="enableKeepToolActive()"
                    matTooltip="Werkzeuge aktiv lassen, bis sie abgewählt werden (Audatex-Stil).">
                    Werkzeuge dauerhaft aktivieren
                </div>
                <div
                    mat-menu-item
                    *ngIf="userPreferences.photoEditorKeepToolActive"
                    (click)="disableKeepToolActive()"
                    matTooltip="Werkzeuge automatisch abwählen, nachdem sie verwendet wurden.">
                    Werkzeuge automatisch deaktivieren
                </div>
                <div
                    mat-menu-item
                    *ngIf="!disabled"
                    (click)="copyFabricBetweenVersions('report', photoVersion)"
                    matTooltip="Formen & Filter von der Gutachten-Version zur ausgewählten Foto-Version kopieren">
                    Formen & Filter übertragen
                </div>
            </mat-menu>
            <mat-icon class="toolbar-icon" (click)="closeEditor()">close</mat-icon>
        </div>
        <!--============================================-->
        <!-- Image Container -->
        <!--============================================-->
        <div id="image-container-flex">
            <div id="image-container" [ngClass]="{ visible: canvasManager.imageLoaded }" #imageContainer>
                <!--                <div id="app-loader-screen" *ngIf="!canvasManager.imageLoaded">-->
                <!--                    <img id="app-loader-image" src="/assets/images/logos/autoixpert-inverted.png">-->
                <!--                </div>-->

                <div class="canvas-elements-positioner" (contextmenu)="openCanvasContextMenu($event)">
                    <canvas
                        id="image-canvas"
                        #canvas
                        [ngClass]="{ 'not-included': !photo?.versions[photoVersion].included }"></canvas>
                    <!--********** Canvas Context Menu **********-->
                    <div
                        id="context-menu-trigger"
                        #canvasContextMenuTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="canvasContextMenu"
                        [style.top]="canvasContextMenuPositionTop"
                        [style.left]="canvasContextMenuPositionLeft"></div>
                    <mat-menu #canvasContextMenu="matMenu">
                        <a
                            mat-menu-item
                            (click)="showVinInput()"
                            matTooltip="Dialog zur Eingabe der VIN direkt im Foto anzeigen">
                            VIN
                        </a>
                        <a
                            mat-menu-item
                            (click)="showMileageInput()"
                            matTooltip="Dialog zur Eingabe der Laufleistung direkt im Foto anzeigen">
                            Laufleistung
                        </a>
                        <a
                            mat-menu-item
                            (click)="showFirstRegistrationInput()"
                            matTooltip="Dialog zur Eingabe der Erstzulassung direkt im Foto anzeigen">
                            Erstzulassung
                        </a>
                        <a
                            mat-menu-item
                            (click)="showLatestRegistrationInput()"
                            matTooltip="Dialog zur Eingabe der letzten Zulassung direkt im Foto anzeigen">
                            Letzte Zulassung
                        </a>
                        <a
                            mat-menu-item
                            (click)="showGeneralInspectionInput()"
                            matTooltip="Dialog zur Eingabe der HU direkt im Foto anzeigen">
                            Hauptuntersuchung
                        </a>
                        <a
                            id="context-menu-tires-item"
                            mat-menu-item
                            (click)="showTiresInput()"
                            matTooltip="Dialog zur Eingabe der Reifendaten anzeigen">
                            Reifen
                        </a>
                        <a
                            id="context-menu-tires-item"
                            mat-menu-item
                            (click)="showPaintThicknessInput()"
                            matTooltip="Dialog zur Eingabe der Lackschichtdicke anzeigen">
                            Lackschichtdicke
                        </a>
                    </mat-menu>

                    <!--********** VIN Input **********-->
                    <div
                        id="vin-input-container"
                        class="context-input-container card"
                        [style.top]="canvasContextMenuPositionTop"
                        [style.left]="canvasContextMenuPositionLeft"
                        *ngIf="vinInputShown"
                        (click)="$event.stopPropagation()">
                        <vin-input
                            [(car)]="report.car"
                            [reportId]="report._id"
                            [report]="report"
                            [reportType]="report.type"
                            (carChange)="saveReport()"
                            [vinQueryAllowed]="false"
                            [autofocus]="true"
                            [disabled]="disabled"></vin-input>
                    </div>

                    <!--********** Mileage Input **********-->
                    <div
                        id="mileage-input-container"
                        class="context-input-container card display-flex"
                        [style.top]="canvasContextMenuPositionTop"
                        [style.left]="canvasContextMenuPositionLeft"
                        *ngIf="mileageInputShown"
                        (click)="$event.stopPropagation()">
                        <mat-form-field class="mat-block">
                            <input
                                matInput
                                number-input
                                [(number)]="report.car.mileageMeter"
                                [minimumFractionDigits]="0"
                                [maximumFractionDigits]="0"
                                [axAutofocus]="true"
                                placeholder="Laufleistung (abgelesen)"
                                (change)="saveReport()"
                                [disabled]="disabled" />
                        </mat-form-field>
                        <mat-form-field id="mileage-unit-select">
                            <mat-select
                                [(ngModel)]="report.car.mileageUnit"
                                placeholder="Einheit"
                                (selectionChange)="saveReport()"
                                [disabled]="disabled">
                                <mat-option value="km" matTooltip="Kilometer">km</mat-option>
                                <mat-option value="mi" matTooltip="Meilen">mi</mat-option>
                                <mat-option value="h" matTooltip="Betriebsstunden">h</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!--********** First Registration Input **********-->
                    <div
                        id="first-registration-input-container"
                        class="context-input-container card display-flex"
                        [style.top]="canvasContextMenuPositionTop"
                        [style.left]="canvasContextMenuPositionLeft"
                        *ngIf="firstRegistrationInputShown"
                        (click)="$event.stopPropagation()">
                        <date-input
                            #firstRegistrationInput
                            placeholder="Erstzulassung"
                            [(date)]="report.car.firstRegistration"
                            (dateChange)="setLatestRegistration(report.car.firstRegistration); saveReport()"
                            [disabled]="report.state === 'done'"
                            [error]="firstRegistrationIsBeforeProductionYear() ? 'Liegt vor Baujahr' : ''"
                            matTooltip="Im Fahrzeugschein: Feld 'B'"
                            (elementCreated)="firstRegistrationInput.focusInput()"></date-input>
                    </div>

                    <!--********** Last Registration Input **********-->
                    <div
                        id="latest-registration-input-container"
                        class="context-input-container card display-flex"
                        [style.top]="canvasContextMenuPositionTop"
                        [style.left]="canvasContextMenuPositionLeft"
                        *ngIf="latestRegistrationInputShown"
                        (click)="$event.stopPropagation()">
                        <date-input
                            #latestRegistrationInput
                            placeholder="Letzte Zulassung"
                            [(date)]="report.car.latestRegistration"
                            (dateChange)="saveReport()"
                            [disabled]="report.state === 'done'"
                            [error]="latestRegistrationIsBeforeFirstRegistration() ? 'Liegt vor Erstzulassung' : ''"
                            matTooltip="Im Fahrzeugschein: Feld 'I'"
                            (elementCreated)="latestRegistrationInput.focusInput()"></date-input>
                    </div>

                    <!--********** General Inspection Input **********-->
                    <div
                        id="general-inspection-input-container"
                        class="context-input-container card display-flex"
                        [style.top]="canvasContextMenuPositionTop"
                        [style.left]="canvasContextMenuPositionLeft"
                        *ngIf="generalInspectionInputShown"
                        (click)="$event.stopPropagation()">
                        <general-inspection-input
                            [autofocus]="true"
                            [(nextGeneralInspection)]="report.car.nextGeneralInspection"
                            (nextGeneralInspectionChange)="saveReport()"
                            [disabled]="isReportLocked()"></general-inspection-input>
                    </div>

                    <!--============================================-->
                    <!-- Photo Description - On-Image tokens -->
                    <!--============================================-->
                    <div
                        class="photo-description-container card card-with-padding"
                        *ngIf="canvasManager.activeNumberToken"
                        #photoDescriptionContainer
                        [style.display]="canvasManager.activeNumberToken ? 'block' : 'none'"
                        [class.flippedVertically]="
                            canvasManager.activeNumberToken.data.axPhotoDescriptionUICard.flippedVertically
                        "
                        [style.left]="canvasManager.activeNumberToken.data.axPhotoDescriptionUICard.x + 'px'"
                        [style.top]="canvasManager.activeNumberToken.data.axPhotoDescriptionUICard.y + 'px'"
                        [style.transform]="
                            canvasManager.objectManager.getPhotoDescriptionUICardTransform(
                                canvasManager.activeNumberToken
                            )
                        ">
                        <h2>Fotobeschreibung</h2>
                        <mat-form-field class="photo-description">
                            <mat-quill
                                placeholder="Fotobeschreibung"
                                [(ngModel)]="canvasManager.activeNumberToken.data.axPhotoDescription"
                                [disabled]="isEditingDisabled()"
                                (input)="onChangePhotoDescription(canvasManager.activeNumberToken)"
                                (change)="savePhoto()"
                                [autofocus]="!!canvasManager.activeNumberToken"></mat-quill>
                        </mat-form-field>
                        <mat-icon class="dialog-close-icon" (click)="canvasManager.activeNumberToken?.close($event)">
                            close
                        </mat-icon>
                        <img
                            class="pointing-triangle"
                            src="/assets/images/pointing-triangle.png"
                            [style]="
                                canvasManager.objectManager.getPhotoDescriptionPointingTriangleStyle(
                                    canvasManager.activeNumberToken
                                )
                            " />
                    </div>
                </div>
                <!--============================================-->
                <!-- END Photo Description - On-Image tokens -->
                <!--============================================-->

                <!--********** Download/Render Error **********-->
                <div id="download-error-message-positioner" *ngIf="photoDownloadProblem">
                    <div class="info-note error-note margin-bottom-15">
                        <mat-icon>error</mat-icon>
                        <div>
                            <strong>{{ photoDownloadProblem.title }}</strong>
                            {{ photoDownloadProblem.body }}
                        </div>
                    </div>
                </div>

                <!-- Only show arrows when no photo description text box is open, otherwise they would olverlap. -->
                <div
                    id="previous-photo-button"
                    *ngIf="photo && isShowingPhotoAllowed('previous') && !canvasManager.activeNumberToken"
                    (click)="showPhoto('previous')">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </div>
                <div
                    id="next-photo-button"
                    *ngIf="photo && isShowingPhotoAllowed('next') && !canvasManager.activeNumberToken"
                    (click)="showPhoto('next')">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Image Container -->
        <!--============================================-->

        <!--============================================-->
        <!-- Editor -->
        <!--============================================-->
        <div id="editor-toolbar" *ngIf="editorInputTab === 'editor'">
            <div class="navigate-history-buttons">
                <mat-icon
                    class="toolbar-icon"
                    [class.disabled]="canvasManager.undoStates.length === 0"
                    (click)="canvasManager.undoAction()"
                    matTooltip="Letzte Aktion rückgängig machen (Strg + Z)">
                    undo
                </mat-icon>
                <mat-icon
                    class="toolbar-icon"
                    [class.disabled]="canvasManager.redoStates.length === 0"
                    (click)="canvasManager.redoAction()"
                    matTooltip="Letzte Aktion wiederholen (Strg + Y)">
                    redo
                </mat-icon>
            </div>

            <h2>Foto-Editor</h2>

            <!--============================================-->
            <!-- Photo Version Selector Bar -->
            <!--============================================-->
            <div id="photo-versions-container">
                <div
                    class="photo-version-icon-and-toggle-container"
                    [ngClass]="{ selected: photoVersion === 'report', active: photo.versions.report.included }">
                    <div class="photo-version-icon-container">
                        <mat-icon
                            class="photo-version-icon"
                            matTooltip="Fotoversion für das Gutachten"
                            (click)="selectPhotoVersion('report')">
                            description
                        </mat-icon>
                    </div>
                    <mat-slide-toggle
                        class="photo-version-toggle"
                        disableRipple
                        matTooltip="Mit 'Alt+g' kann das Foto zur Gruppe Gutachten hinzugefügt/entfernt werden.{{
                            '\n\n'
                        }} Mit 'a' wird das Foto zur aktuell ausgewählten Gruppe hinzugefügt, mit 'd' von der aktuell ausgewählten Gruppe entfernt."
                        [(ngModel)]="photo.versions.report.included"
                        (change)="savePhoto()"
                        [disabled]="disabled"></mat-slide-toggle>
                </div>

                <ng-container *ngIf="report.type !== 'shortAssessment' && report.type !== 'oldtimerValuationSmall'">
                    <div
                        class="photo-version-icon-and-toggle-container"
                        [ngClass]="{
                            selected: photoVersion === 'residualValueExchange',
                            active: photo.versions.residualValueExchange.included,
                        }">
                        <div class="photo-version-icon-container">
                            <mat-icon
                                class="photo-version-icon icon-emphasized"
                                matTooltip="Fotoversion für die Restwertbörsen"
                                (click)="selectPhotoVersion('residualValueExchange')">
                                gavel
                            </mat-icon>
                        </div>
                        <mat-slide-toggle
                            class="photo-version-toggle"
                            disableRipple
                            matTooltip="Mit 'Alt+r' kann das Foto zur Gruppe Restwertbörse hinzugefügt/entfernt werden.{{
                                '\n\n'
                            }} Mit 'a' wird das Foto zur aktuell ausgewählten Gruppe hinzugefügt, mit 'd' von der aktuell ausgewählten Gruppe entfernt."
                            [(ngModel)]="photo.versions.residualValueExchange.included"
                            (change)="savePhoto()"
                            [disabled]="disabled"></mat-slide-toggle>
                    </div>

                    <div
                        class="photo-version-icon-and-toggle-container"
                        *ngIf="report.repairConfirmation"
                        [ngClass]="{
                            selected: photoVersion === 'repairConfirmation',
                            active: photo.versions.repairConfirmation.included,
                        }">
                        <div class="photo-version-icon-container">
                            <div
                                class="photo-version-icon"
                                matTooltip="Fotoversion für die Reparaturbestätigung"
                                (click)="selectPhotoVersion('repairConfirmation')">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    xml:space="preserve">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        fill="#A1A1A1"
                                        d="M19.692,4.524l-4.166,4.161L12.939,6.1l4.093-4.09 c-2.175-1.017-4.835-0.648-6.631,1.145C8.719,4.835,8.283,7.278,9.07,9.366L1.867,16.56c-0.878,0.877-0.878,2.301,0,3.179
                                c0.878,0.876,2.303,0.876,3.182,0l7.109-7.101c2.158,0.963,4.774,0.578,6.544-1.191C20.582,9.57,20.893,6.747,19.692,4.524z"></path>
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        fill="#A1A1A1"
                                        d="M13.652,18.171l2.183,2.149l5.041-5.508l1.449,1.474l-6.307,7.076l-3.763-3.72L13.652,18.171z"></path>
                                </svg>
                            </div>
                        </div>
                        <mat-slide-toggle
                            class="photo-version-toggle"
                            disableRipple
                            matTooltip="Mit 'Alt+b' kann das Foto zur Gruppe Reparaturbestätigung hinzugefügt/entfernt werden.{{
                                '\n\n'
                            }} Mit 'a' wird das Foto zur aktuell ausgewählten Gruppe hinzugefügt, mit 'd' von der aktuell ausgewählten Gruppe entfernt."
                            [(ngModel)]="photo.versions.repairConfirmation.included"
                            (change)="savePhoto()"
                            [disabled]="isEditingDisabled()"></mat-slide-toggle>
                    </div>

                    <div
                        class="photo-version-icon-and-toggle-container"
                        *ngIf="report.expertStatements?.length"
                        [ngClass]="{
                            selected: photoVersion === 'expertStatement',
                            active: photo.versions.expertStatement.included,
                        }">
                        <div class="photo-version-icon-container">
                            <mat-icon
                                class="photo-version-icon"
                                matTooltip="Fotoversion für die Stellungnahme"
                                (click)="selectPhotoVersion('expertStatement')">
                                comment
                            </mat-icon>
                        </div>
                        <mat-slide-toggle
                            class="photo-version-toggle"
                            disableRipple
                            matTooltip="Mit 'Alt+s' kann das Foto zur Gruppe Stellungnahme hinzugefügt/entfernt werden.{{
                                '\n\n'
                            }} Mit 'a' wird das Foto zur aktuell ausgewählten Gruppe hinzugefügt, mit 'd' von der aktuell ausgewählten Gruppe entfernt."
                            [(ngModel)]="photo.versions.expertStatement.included"
                            (change)="savePhoto()"
                            [disabled]="isEditingDisabled()"></mat-slide-toggle>
                    </div>
                </ng-container>
            </div>
            <!--============================================-->
            <!-- END Photo Version Selector Bar -->
            <!--============================================-->

            <div id="tools-and-description-container" *ngIf="!filtersShown">
                <div id="tools-heading-container">
                    <h3>Werkzeuge</h3>
                    <!--                    <div-->
                    <!--                        id="tool-blurpicker-container"-->
                    <!--                        class="tool-container"-->
                    <!--                        [matTooltip]="-->
                    <!--                            toolbarManager.activeBackground === 'color'-->
                    <!--                                ? 'Unschärfe aktivieren'-->
                    <!--                                : 'Unschärfe deaktivieren'-->
                    <!--                        "-->
                    <!--                        (click)="toolbarManager.handleBlurToolClick()"-->
                    <!--                        [ngClass]="{-->
                    <!--                            unselectable: isEditingDisabled(),-->
                    <!--                        }">-->
                    <!--                        <div class="tool-hover-container">-->
                    <!--                            <mat-icon-->
                    <!--                                [ngClass]="{-->
                    <!--                                    active: toolbarManager.activeBackground === 'blur',-->
                    <!--                                }"-->
                    <!--                                class="toolbar-icon">-->
                    <!--                                blur_on-->
                    <!--                            </mat-icon>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div
                        id="tool-colorpicker-container"
                        class="tool-container"
                        matTooltip="Tastaturkürzel: f"
                        (click)="toolbarManager.activeBackground = 'color'"
                        [ngClass]="{
                            active: toolbarManager.activeTool === toolbarManager.tools.colorpicker,
                            unselectable: isEditingDisabled(),
                        }">
                        <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.colorpicker)">
                            <svg
                                class="icon"
                                [attr.fill]="toolbarManager.color"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg>
                        </div>
                        <input
                            type="color"
                            title="Farbe auswählen"
                            [ngModel]="toolbarManager.color"
                            (ngModelChange)="toolbarManager.setEditorShapeColor($event)"
                            #colorpicker
                            [disabled]="isEditingDisabled()" />
                    </div>
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="toolsSubmenu">more_vert</mat-icon>
                </div>
                <div id="tools-container">
                    <div id="leftColumn" class="toolbar-column">
                        <div
                            id="tool-arrow-container"
                            class="tool-container"
                            matTooltip="Tastaturkürzel: p"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.arrow,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.arrow)">
                                <svg
                                    class="icon"
                                    [attr.fill]="toolbarManager.toolDisplayColor"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M5.307,21.692l8.438-8.396l3.025,3.025l4.04-12.896L7.844,7.47l3.178,3.178l-8.338,8.38L5.307,21.692z"></path>
                                </svg>
                                <div class="label">Pfeil</div>
                            </div>
                        </div>
                        <div
                            id="tool-circle-container"
                            class="tool-container"
                            matTooltip="Tastaturkürzel: k"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.circle,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.circle)">
                                <svg
                                    class="icon"
                                    [attr.fill]="toolbarManager.toolDisplayColor"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.963,2.446c-5.223,0-9.457,4.234-9.457,9.457
                                        c0,5.222,4.234,9.457,9.457,9.457c5.222,0,9.457-4.234,9.457-9.457C21.42,6.68,17.186,2.446,11.963,2.446z M11.963,20.498
                                        c-4.747,0-8.595-3.849-8.595-8.595c0-4.747,3.848-8.595,8.595-8.595c4.747,0,8.595,3.848,8.595,8.595
                                        C20.559,16.649,16.71,20.498,11.963,20.498z"></path>
                                </svg>
                                <div class="label">Kreis</div>
                            </div>
                        </div>
                        <div
                            id="tool-filled-circle-container"
                            class="tool-container"
                            matTooltip="Tastaturkürzel: K"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.filledCircle,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.filledCircle)">
                                <svg
                                    class="icon"
                                    [attr.fill]="toolbarManager.toolDisplayColor"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.963,2.446c-5.223,0-9.457,4.234-9.457,9.457c0,5.222,4.234,9.457,9.457,9.457s9.457-4.233,9.457-9.457
                                        C21.42,6.68,17.186,2.446,11.963,2.446z"></path>
                                </svg>
                                <div class="label">Kreis</div>
                            </div>
                        </div>
                        <div
                            id="tool-rotate-container"
                            class="tool-container margin-top-10"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.rotate,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.rotate)">
                                <img class="icon" src="/assets/images/icons/rotate-90deg-blue_24.png" />
                                <div class="label">Rotieren</div>
                            </div>
                        </div>
                    </div>

                    <div id="centerColumn" class="toolbar-column">
                        <div
                            id="tool-number-and-text-container"
                            class="tool-container"
                            matTooltip="Tastaturkürzel: F"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.numberAndText,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.numberAndText)">
                                <img class="icon" src="/assets/images/icons/text_24.png" />
                                <div class="label">Text & Nummer</div>
                            </div>
                        </div>
                        <div
                            id="tool-rectangle-container"
                            class="tool-container"
                            matTooltip="Tastaturkürzel: r"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.rectangle,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.rectangle)">
                                <svg
                                    class="icon"
                                    [attr.fill]="toolbarManager.toolDisplayColor"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        display="none"
                                        d="M11.963,2.446c-5.223,0-9.457,4.234-9.457,9.457
                                        c0,5.222,4.234,9.457,9.457,9.457c5.222,0,9.457-4.234,9.457-9.457C21.42,6.68,17.186,2.446,11.963,2.446z M11.963,20.498
                                        c-4.747,0-8.595-3.849-8.595-8.595c0-4.747,3.848-8.595,8.595-8.595c4.747,0,8.595,3.848,8.595,8.595
                                        C20.559,16.649,16.71,20.498,11.963,20.498z"></path>
                                    <path
                                        d="M2.395,4.169v15.092h19.122V4.169H2.395z M20.488,18.232H3.368V5.226h17.12V18.232z"></path>
                                </svg>
                                <div class="label">Rechteck</div>
                            </div>
                        </div>
                        <div
                            id="tool-filled-rectangle-container"
                            class="tool-container"
                            matTooltip="Tastaturkürzel: R"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.filledRectangle,
                                unselectable: isEditingDisabled(),
                            }">
                            <div
                                class="tool-hover-container"
                                (click)="toggleTool(toolbarManager.tools.filledRectangle)">
                                <svg
                                    class="icon"
                                    [attr.fill]="toolbarManager.toolDisplayColor"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        display="none"
                                        d="M11.963,2.446c-5.223,0-9.457,4.234-9.457,9.457c0,5.222,4.234,9.457,9.457,9.457
                                        s9.457-4.233,9.457-9.457C21.42,6.68,17.186,2.446,11.963,2.446z M11.963,20.498c-4.747,0-8.595-3.85-8.595-8.595
                                        c0-4.747,3.848-8.595,8.595-8.595s8.596,3.848,8.596,8.595C20.559,16.648,16.71,20.498,11.963,20.498z"></path>
                                    <path d="M2.395,4.169v15.092h19.123V4.169H2.395z"></path>
                                </svg>
                                <div class="label">Rechteck</div>
                            </div>
                        </div>
                        <div
                            id="tool-crop-container"
                            class="tool-container margin-top-10"
                            matTooltip="Tastaturkürzel: z"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.crop,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.crop)">
                                <mat-icon class="moderate-icon">crop</mat-icon>
                                <div class="label">Zuschneiden</div>
                            </div>
                            <mat-icon class="toolbar-icon medium-icon" [matMenuTriggerFor]="cropToolSubmenu">
                                more_vert
                            </mat-icon>
                            <mat-menu #cropToolSubmenu>
                                <a
                                    mat-menu-item
                                    [matMenuTriggerFor]="cropToolAspectRatioSubmenu"
                                    [disabled]="!user.accessRights.editTextsAndDocumentBuildingBlocks"
                                    [matTooltip]="
                                        !user.accessRights.editTextsAndDocumentBuildingBlocks
                                            ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                            : ''
                                    ">
                                    Seitenverhältnis
                                </a>

                                <mat-menu #cropToolAspectRatioSubmenu>
                                    <a
                                        mat-menu-item
                                        (click)="saveAspectRatioForCropTool()"
                                        [matTooltip]="
                                            'Speichere das Seitenverhältnis dieses Fotos für alle zukünftigen Zuschnitte.\n\n Diese Einstellung gilt für dein gesamtes Team. Hast du dir einmal das gewünschte Format gemerkt, öffnet sich der Zuschnittsbereich immer mit diesem Seitenverhältnis und kann über die Ecken gleichmäßig skaliert werden.'
                                        ">
                                        Von diesem Foto verwenden
                                    </a>
                                    <a
                                        mat-menu-item
                                        [matTooltip]="'Immer das Seitenverhältnis des aktuellen Fotos verwenden.'"
                                        (click)="resetAspectRatioForCropTool()">
                                        Automatisch
                                    </a>
                                </mat-menu>
                            </mat-menu>
                        </div>
                    </div>

                    <div id="rightColumn" class="toolbar-column">
                        <div
                            id="tool-watermark-container"
                            class="tool-container"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.watermark,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.watermark)">
                                <img class="icon" src="/assets/images/icons/watermark-blue_24.png" />
                                <div class="label">Wasserzeichen</div>
                            </div>
                            <mat-icon class="toolbar-icon medium-icon" [matMenuTriggerFor]="watermarkToolSubmenu">
                                more_vert
                            </mat-icon>
                            <mat-menu #watermarkToolSubmenu>
                                <mat-option
                                    (click)="addWatermarkToAllPhotos()"
                                    *ngIf="!isEditingDisabled()"
                                    matTooltip="Wasserzeichen zu allen Fotos hinzufügen">
                                    Alle Fotos
                                </mat-option>
                                <mat-option
                                    (click)="showWatermarkSettings()"
                                    [matTooltip]="
                                        !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                            ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                            : ''
                                    "
                                    [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                                    Einstellungen
                                </mat-option>
                            </mat-menu>
                        </div>
                        <div
                            id="tool-polygon-container"
                            class="tool-container"
                            matTooltip="Tastaturkürzel: y"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.polygon,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.polygon)">
                                <svg
                                    class="icon"
                                    [attr.fill]="toolbarManager.toolDisplayColor"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.034,9.997l-1.948-8.105L1.602,9.926l4.565,11.537
                                        l14.405-0.919l1.345-11.751L13.034,9.997z M19.833,19.792L6.75,20.708l-4.25-10.5l8.25-6.917l1.792,7.625l8.541-1.125
                                        L19.833,19.792z"></path>
                                </svg>
                                <div class="label">Polygon</div>
                            </div>
                        </div>
                        <div
                            id="tool-filled-polygon-container"
                            class="tool-container"
                            matTooltip="Tastaturkürzel: Y"
                            [ngClass]="{
                                active: toolbarManager.activeTool === toolbarManager.tools.filledPolygon,
                                unselectable: isEditingDisabled(),
                            }">
                            <div class="tool-hover-container" (click)="toggleTool(toolbarManager.tools.filledPolygon)">
                                <svg
                                    class="icon"
                                    [attr.fill]="toolbarManager.toolDisplayColor"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <polygon
                                        points="13.034,9.997 11.086,1.892 1.602,9.926 6.167,21.463 20.572,20.544 21.917,8.793"></polygon>
                                </svg>
                                <div class="label">Polygon</div>
                            </div>
                        </div>
                        <!--<div id="tool-reset-container"-->
                        <!--class="tool-container"-->
                        <!--[ngClass]="{active: toolbarManager.activeTool === toolbarManager.tools.reset, unselectable: disabled}">-->
                        <!--<div class="tool-hover-container"-->
                        <!--(click)="toggleTool(toolbarManager.tools.reset)">-->
                        <!--<img class="icon" src="/assets/images/icons/refresh-grey_24.png">-->
                        <!--<div class="label">Zurücksetzen</div>-->
                        <!--</div>-->
                        <!--</div>-->
                        <div
                            id="tool-zoom-container"
                            class="tool-container margin-top-10"
                            matTooltip="Schnellaktion: Doppelklick auf Foto{{
                                '\n\n'
                            }}Für Zoom: Mausrad drehen, wenn Mauszeiger über dem Foto."
                            [ngClass]="{
                                active: canvasManager.isZoomActive,
                            }">
                            <div class="tool-hover-container" (click)="canvasManager.toggleZoomMode()">
                                <mat-icon class="icon icon-light">zoom_in</mat-icon>
                                <div class="label">Vergrößern</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="filters-and-delete-photo-buttons-container">
                    <div
                        id="tool-delete-button-container"
                        class="photo-editor-button-container"
                        matTooltip="Tastaturkürzel: Entf oder ⌫"
                        (click)="!isEditingDisabled() && toolbarManager.tools.delete.onclick()"
                        [ngClass]="{
                            unselectable:
                                !canvasManager?.canvas?.getActiveObject() ||
                                canvasManager?.canvas?.getActiveObjects().length === 0,
                        }">
                        <mat-icon class="icon photo-editor-button-icon">clear</mat-icon>
                        <span class="label photo-editor-button-label">Form entfernen</span>
                    </div>

                    <div
                        class="photo-editor-button-container"
                        (click)="showFilters()"
                        [ngClass]="{ unselectable: isEditingDisabled() }">
                        <mat-icon class="icon photo-editor-button-icon">photo_filter</mat-icon>
                        <span class="label photo-editor-button-label">Filter</span>
                    </div>

                    <div
                        class="photo-editor-button-container"
                        (click)="deletePhoto(photo)"
                        [ngClass]="{ unselectable: isEditingDisabled() }">
                        <mat-icon id="delete-photo-button-icon" class="icon photo-editor-button-icon">delete</mat-icon>
                        <span class="label photo-editor-button-label">Foto löschen</span>
                    </div>

                    <div
                        class="photo-editor-button-container"
                        (click)="redactLicensePlate()"
                        [ngClass]="{ unselectable: isEditingDisabled() }">
                        <mat-icon class="icon photo-editor-button-icon">graphic_eq</mat-icon>
                        <span class="label photo-editor-button-label">Kennzeichen schwärzen</span>
                        <!-- Loading spinner -->
                        <mat-spinner
                            *ngIf="redactLicensePlateLoading"
                            diameter="14"
                            strokeWidth="2"
                            class="photo-editor-button-spinner"></mat-spinner>
                    </div>
                </div>

                <!--============================================-->
                <!-- Photo Description in Right Column -->
                <!--============================================-->
                <div id="photo-description-container" *ngIf="photo">
                    <autocomplete-with-memory
                        [(ngModel)]="photo.description"
                        [autocompleteEntryType]="'photoDescription'"
                        [useTextarea]="true"
                        (change)="checkContextMenuInfoNote(); savePhoto()"
                        placeholder="Beschreibung"
                        floatLabel="never"
                        [disabled]="isEditingDisabled()"></autocomplete-with-memory>
                </div>
                <!--============================================-->
                <!-- END Photo Description in Right Column -->
                <!--============================================-->

                <!--============================================-->
                <!-- Context Menu Notice -->
                <!--============================================-->
                <div class="info-note" *ngIf="showContextMenuInfoNote" @fadeOut>
                    <mat-icon class="medium-icon">ads_click</mat-icon>
                    <div>
                        <h3>Daten abtippen</h3>
                        Du kannst auf dem Foto rechtsklicken, um einige Daten direkt davon abzutippen.
                    </div>
                    <mat-icon
                        class="info-note-close-icon toolbar-icon"
                        (click)="closeContextMenuInfoNote()"
                        matTooltip="Info dauerhaft ausblenden">
                        close
                    </mat-icon>
                </div>
                <!--============================================-->
                <!-- END Context Menu Notice -->
                <!--============================================-->
            </div>
            <div id="filters-tab" *ngIf="filtersShown">
                <h3>Filter</h3>
                <div class="info-note warning-note label" *ngIf="!isDeviceStrongEnoughForFilterBackend">
                    <mat-icon class="warning medium-icon">warning</mat-icon>
                    <div>
                        <h3>Gerät unterstützt Filter nicht vollständig</h3>
                        Möglicherweise wird das Foto abgeschnitten angezeigt, weil das Gerät dem Browser nicht genug
                        Speicher zur Verfügung stellt, um das komplette Foto mit Filtern zu rendern. Das ist nur ein
                        Darstellungsproblem auf diesem Gerät. In den Dokumenten und auf stärkeren Geräten wird das Foto
                        richtig gerendert.
                    </div>
                </div>
                <div id="filters-container">
                    <div
                        *ngFor="let filter of filterManager.getFiltersArray(); let i = index"
                        id="filter-{{ filter.name }}-container"
                        class="filter-container">
                        <div class="label">{{ filter.label }}</div>
                        <mat-slider
                            id="filter-{{ filter.id }}"
                            class="photo-filter-slider"
                            min="-100"
                            max="100"
                            [step]="filter.step"
                            [(ngModel)]="filter.value"
                            (input)="
                                filterManager.filterChangeHandler(filter.id, $event.value);
                                filterManager.apply({ saveToHistory: true })
                            "
                            (change)="savePhoto()"
                            [disabled]="isEditingDisabled()"
                            thumbLabel></mat-slider>
                        <div class="value">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    [(ngModel)]="filter.value"
                                    (input)="filterManager.apply({ saveToHistory: true })"
                                    (change)="savePhoto()"
                                    [disabled]="isEditingDisabled()" />
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div id="close-filters-button-container">
                    <button id="close-filters-button" (click)="hideFilters()">Filter schließen</button>
                </div>
            </div>

            <!--============================================-->
            <!-- Watermark Settings -->
            <!--============================================-->
            <section id="watermark-settings-section" *ngIf="watermarkSettingsShown" @fadeInAndSlide>
                <h2>Wasserzeichen</h2>

                <!--============================================-->
                <!-- No Water Mark -->
                <!--============================================-->
                <div id="no-watermark-placeholder" *ngIf="!team.preferences.watermark.type">
                    <!--********** Watermark Icon **********-->
                    <img
                        id="watermark-image-icon"
                        (click)="watermarkImageUpload.click()"
                        src="/assets/images/icons/watermark-image.png" />

                    <!--********** Image Upload Button **********-->
                    <!-- TODO Move click handler to the callback after uploading an image -->
                    <button id="upload-image-button" (click)="watermarkImageUpload.click()">Grafik hochladen</button>
                    <input
                        type="file"
                        #watermarkImageUpload
                        ng2FileSelect
                        [uploader]="watermarkImageUploader"
                        accept="image/jpeg,image/png,image/svg+xml" />

                    <div id="use-text-container">
                        oder
                        <span
                            id="use-text-link"
                            (click)="setWatermarkType('text'); saveTeamPreferences(); drawWatermark()">
                            Text verwenden
                        </span>
                    </div>
                </div>
                <!--============================================-->
                <!-- END No Water Mark -->
                <!--============================================-->

                <!--============================================-->
                <!-- Watermark Exists -->
                <!--============================================-->
                <div id="watermark-settings-container" *ngIf="!!team.preferences.watermark.type">
                    <!--********** Image **********-->
                    <div class="watermark-setting-row" *ngIf="team.preferences.watermark.type === 'image'">
                        <h3 class="watermark-setting-title">Grafik</h3>
                        <div id="watermark-image-container">
                            <img
                                id="watermark-image"
                                *ngIf="this.watermarkImageFileBlobUrl"
                                [src]="this.watermarkImageFileBlobUrl"
                                alt="" />
                            <div *ngIf="!this.watermarkImageFileBlobUrl" class="info-note warning-note">
                                <mat-icon>image_not_supported</mat-icon>
                                Wasserzeichen-Bild nicht gefunden
                            </div>
                            <mat-icon
                                class="toolbar-icon"
                                (click)="setWatermarkType(null); saveTeamPreferences()"
                                matTooltip="Grafik löschen">
                                delete
                            </mat-icon>
                        </div>
                    </div>

                    <!--********** Text **********-->
                    <div class="watermark-setting-row" *ngIf="team.preferences.watermark.type === 'text'">
                        <h3 class="watermark-setting-title">Text</h3>
                        <div id="watermark-text-and-color-container">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    [(ngModel)]="team.preferences.watermark.text"
                                    (change)="saveTeamPreferences(); drawWatermark()"
                                    placeholder="Wasserzeichen-Text" />
                            </mat-form-field>
                            <div id="watermark-text-color-button" (click)="colorpickerInput.click()">
                                <svg
                                    class="icon"
                                    [attr.fill]="team.preferences.watermark.textColor"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                                <input
                                    type="color"
                                    title="Farbe auswählen"
                                    [ngModel]="team.preferences.watermark.textColor"
                                    (ngModelChange)="setWatermarkTextColor($event); saveTeamPreferences()"
                                    #colorpickerInput />
                            </div>
                            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="watermarkTextSubmenu">
                                more_vert
                            </mat-icon>
                            <mat-menu #watermarkTextSubmenu>
                                <mat-option (click)="setWatermarkType(null); saveTeamPreferences()">
                                    Grafik statt Text verwenden
                                </mat-option>
                            </mat-menu>
                        </div>
                    </div>

                    <!--********** Position **********-->
                    <div class="watermark-setting-row">
                        <h3 class="watermark-setting-title">Position</h3>
                        <div id="watermark-positioning-wrapper">
                            <div id="watermark-positioning-container">
                                <mat-radio-group
                                    [(ngModel)]="team.preferences.watermark.anchor"
                                    (ngModelChange)="setDefaultOffsets(); saveTeamPreferences(); drawWatermark()">
                                    <mat-radio-button
                                        class="watermark-position-option"
                                        value="topLeft"></mat-radio-button>
                                    <mat-radio-button
                                        class="watermark-position-option"
                                        value="topRight"></mat-radio-button>
                                    <mat-radio-button
                                        class="watermark-position-option"
                                        value="center"></mat-radio-button>
                                    <mat-radio-button
                                        class="watermark-position-option"
                                        value="bottomLeft"></mat-radio-button>
                                    <mat-radio-button
                                        class="watermark-position-option"
                                        value="bottomRight"></mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div
                                class="label"
                                matTooltip="Per Drag & Drop kann das Wasserzeichen auf dem Foto verschoben werden. AutoiXpert merkt sich die Position automatisch.">
                                Zur Feinjustierung
                                {{ team.preferences.watermark.type === 'image' ? 'Grafik' : 'Text' }} verschieben
                            </div>
                        </div>
                    </div>

                    <!--********** Opacity **********-->
                    <div class="watermark-setting-row">
                        <h3 class="watermark-setting-title">Deckkraft</h3>
                        <mat-slider
                            class="mat-block"
                            [(ngModel)]="team.preferences.watermark.opacity"
                            (ngModelChange)="delayTriggerSavingTeamPreferences(); drawWatermark()"
                            [max]="1"
                            step="0.01"
                            [displayWith]="formatWatermarkOpacitySliderThumbValue"
                            thumbLabel></mat-slider>
                    </div>

                    <!--********** Insert automatically **********-->
                    <div id="insert-watermark-automatically-row" class="checkbox-group parent-of-an-only-child">
                        <mat-checkbox
                            [(ngModel)]="team.preferences.watermark.insertAutomatically"
                            (ngModelChange)="saveTeamPreferences()"
                            matTooltip="Wasserzeichen automatisch beim Hochladen eines Fotos einfügen">
                            automatisch beim Hochladen einfügen
                        </mat-checkbox>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Watermark Exists -->
                <!--============================================-->

                <!--********** Buttons **********-->
                <div class="dialog-buttons-container">
                    <button (click)="hideWatermarkSettings()">Schließen</button>
                </div>
            </section>
            <!--============================================-->
            <!-- END Watermark Settings -->
            <!--============================================-->

            <!--============================================-->
            <!-- Number of Photos -->
            <!--============================================-->
            <div id="number-of-photos-container" class="text-align-end label">
                Foto {{ findPositionInPhotosArray(photo) + 1 }} von {{ photos.length }}
            </div>
            <!--============================================-->
            <!-- END Number of Photos -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Editor -->
        <!--============================================-->

        <!--============================================-->
        <!-- Tires Editor -->
        <!--============================================-->
        <div id="tires-input-container" class="display-flex" *ngIf="editorInputTab === 'tires'">
            <!-------------- Tire Details -->
            <div class="card-with-padding">
                <!--********** Card Toolbar **********-->
                <div class="card-toolbar">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="tireCardSubmenu">more_vert</mat-icon>
                    <mat-icon class="toolbar-icon" (click)="closeEditor()">close</mat-icon>
                    <mat-menu #tireCardSubmenu>
                        <!--********** Show Spare Tire **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="!userPreferences.spareTireEquipmentShown"
                            (click)="userPreferences.spareTireEquipmentShown = true">
                            <mat-icon>visibility</mat-icon>
                            Notbereifung einblenden
                        </a>

                        <!--********** Hide Spare Tire **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="userPreferences.spareTireEquipmentShown"
                            (click)="hideSpareTireEquipmentSection()">
                            <mat-icon>visibility_off</mat-icon>
                            Notbereifung ausblenden
                        </a>
                    </mat-menu>
                </div>

                <tires-editor
                    [car]="report.car"
                    (tireChange)="saveReport()"
                    [disabled]="isReportLocked()"></tires-editor>

                <spare-tire-equipment-editor
                    [userPreferences]="userPreferences"
                    [report]="report"
                    (dataChange)="saveReport()"
                    [disabled]="isReportLocked()"></spare-tire-equipment-editor>

                <!--============================================-->
                <!-- Custom Fields -->
                <!--============================================-->
                <custom-field-display
                    fieldLocation="car-condition_tires"
                    [report]="report"
                    (reportChange)="saveReport()"
                    [disabled]="isReportLocked()"></custom-field-display>
                <!--============================================-->
                <!-- END Custom Fields -->
                <!--============================================-->

                <div class="dialog-buttons-container">
                    <button (click)="hideTiresOrPaintThicknessInput($event, true)">Schließen</button>
                </div>
            </div>
        </div>

        <!--============================================-->
        <!-- Paint Thickness -->
        <!--============================================-->
        <div id="paint-thickness-input-container" class="display-flex" *ngIf="editorInputTab === 'paintThickness'">
            <!-------------- Paint Thickness -->
            <div class="card-with-padding">
                <!--********** Card Toolbar **********-->
                <div class="card-toolbar">
                    <mat-icon
                        *ngIf="!paintThicknessCommentShown"
                        class="toolbar-icon"
                        [matMenuTriggerFor]="paintThicknessCardSubmenu">
                        more_vert
                    </mat-icon>
                    <mat-icon class="toolbar-icon" (click)="closeEditor()">close</mat-icon>
                    <mat-menu #paintThicknessCardSubmenu>
                        <!--********** Show Spare Tire **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="!paintThicknessCommentShown"
                            (click)="paintThicknessCommentShown = true">
                            <mat-icon>visibility</mat-icon>
                            Kommentar anzeigen
                        </a>
                    </mat-menu>
                </div>
                <h2>Lackschichtdicke</h2>
                <paint-thickness
                    [report]="report"
                    [tires]="tires"
                    [paintThicknessCommentShown]="paintThicknessCommentShown"></paint-thickness>

                <div class="dialog-buttons-container">
                    <button (click)="hideTiresOrPaintThicknessInput($event, true)">Schließen</button>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Paint Thickness -->
        <!--============================================-->
    </div>
    <div class="buffer lower" (click)="closeEditor()"></div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { CarEquipment } from '@autoixpert/models/reports/car-identification/car-equipment';
import { DamageCalculation } from '@autoixpert/models/reports/damage-calculation/damage-calculation';
import { AudatexValuation } from '@autoixpert/models/reports/market-value/audatex-valuation';

@Injectable()
export class AudatexVinService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getVinResult(reportId: string, vin: string): Promise<AudatexVinResult[]> {
        return this.httpClient
            .get<AudatexVinResult[]>(`${this.apiPath}/reports/${reportId}/audatex/vin/${vin}`)
            .toPromise();
    }
}

export interface AudatexVinResult {
    // Contains only the data present in the VIN. The car has data like the current amount of kilometers on the tachometer. That could not be saved in the VIN.
    newCarInformation: Car;
    carEquipment: CarEquipment;
    damageCalculation: {
        downtimeCompensationPerWorkday: DamageCalculation['downtimeCompensationPerWorkday'];
        downtimeCompensationGroup: DamageCalculation['downtimeCompensationGroup'];
        rentalCarClass: DamageCalculation['rentalCarClass'];
    };
    valuation: AudatexValuation;
}

import { DateTime } from 'luxon';
import { ContactPerson } from '../../models/contacts/contact-person';
import { DocumentMetadata } from '../../models/documents/document-metadata';
import { BadRequest } from '../../models/errors/ax-error';
import { Invoice } from '../../models/invoices/invoice';
import { Report } from '../../models/reports/report';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { toGermanDate } from '../ax-luxon';
import { getContactPersonPlaceholderObject } from './get-contact-person-placeholder-object';
import { getUserPlaceholderObject } from './get-user-placeholder-object';
import { PlaceholderValuesLetter } from './placeholder-values.types';
import { Translator } from './translator';

export async function getTemplatePlaceholderValuesLetterOrExpertStatement({
    letterDocument,
    user,
    team,
    report,
    invoice,
    teamMembers,
}: {
    letterDocument: DocumentMetadata;
    user: User;
    team: Team;
    report?: Report;
    invoice?: Invoice;
    teamMembers: User[];
}): Promise<PlaceholderValuesLetter> {
    let letterRecipient: ContactPerson;

    // If a recipient role is specified for this letter, use it as the document recipient.
    if (letterDocument.recipientRole) {
        const reportInvolvedParties = ['claimant', 'lawyer', 'garage', 'insurance', 'factoringProvider'];
        if (reportInvolvedParties.includes(letterDocument.recipientRole)) {
            if (!report) {
                throw new BadRequest({
                    code: 'REPORT_RECIPIENT_INVALID',
                    message:
                        'The recipient role of this document is a report recipient but no report is linked to this document. Please link a report to this document, possibly through the invoice.',
                });
            }
            letterRecipient = report[letterDocument.recipientRole].contactPerson;
        }
        // Recipient must be derived from the invoice.
        else if (letterDocument.recipientRole === 'invoiceRecipient') {
            if (!invoice) {
                throw new BadRequest({
                    code: 'INVOICE_RECIPIENT_INVALID',
                    message:
                        'The recipient role of this document is an invoice recipient but no invoice is linked to this document. Please link an invoice to this document.',
                });
            }
            letterRecipient = invoice.recipient.contactPerson;
        } else {
            throw new BadRequest({
                code: 'INVALID_RECIPIENT_ROLE_ON_LETTER',
                message: 'Please specify a valid recipient role for this letter.',
                data: {
                    recipientRole: letterDocument.recipientRole,
                },
            });
        }
    }

    //*****************************************************************************
    //  Creator
    //****************************************************************************/
    const creator: User = teamMembers.find((teamMember) => teamMember._id === letterDocument.createdBy);
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Creator
    /////////////////////////////////////////////////////////////////////////////*/

    return {
        Anschreiben: {
            Absender: getUserPlaceholderObject({
                user: creator || user,
                team,
                // The invoice has precedence because it's the item configured last.
                officeLocationId: (invoice || report)?.officeLocationId,
            }),
            Empfänger: {
                ...getContactPersonPlaceholderObject(letterRecipient),
                Typ: Translator.letterRecipient(letterDocument.recipientRole, report?.type),
            },
            // null causes some strange moment objects, so use undefined if the date is null or otherwise falsy.
            Datum: toGermanDate(letterDocument.date || DateTime.now()),
            Betreff: letterDocument.subject || '[Kein Betreff]',
            // For the template style "classic", do not use justified text (Fließtext) but left align (Standard).
            Inhalt: letterDocument.body || '<p>[Kein Inhalt]</p>',
        },
    };
}

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { omit } from 'lodash-es';
import moment from 'moment';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { fadeInAnimation } from '@autoixpert/animations/fade-in.animation';
import { SignaturePadComponent } from '@autoixpert/components/signature-pad/signature-pad.component';
import { ensureValueIsInArray } from '@autoixpert/lib/arrays/ensure-value-is-in-array';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import { getLineHeightRelativeToPageHeight } from '@autoixpert/lib/documents/get-line-height-relative-to-page-height';
import { DINA4_ASPECT_RATIO } from '@autoixpert/lib/documents/page-aspect-ratios';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { isCursorInInputOrTextarea } from '@autoixpert/lib/keyboard-events/isCursorInInputOrTextarea';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { getClaimantSignatureFileName } from '@autoixpert/lib/signature-pad/get-claimant-signature-file-name';
import { removeMissingPlaceholdersFromText } from '@autoixpert/lib/template-engine/replace-missing-placeholders';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { Report } from '@autoixpert/models/reports/report';
import { ClaimantSignature } from '@autoixpert/models/signable-documents/claimant-signature';
import { CheckboxValueTracker, SignableDocument } from '@autoixpert/models/signable-documents/signable-document';
import {
    CheckboxElement,
    PdfElement,
    SignablePdfPage,
    SignablePdfTemplateConfig,
    SignatureElement,
    TextElement,
} from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { AbstractApiErrorService } from '../../abstract-services/api-error.abstract.service';
import { AbstractClaimantSignatureFileService } from '../../abstract-services/claimant-signature-file.abstract.service';
import { AbstractFieldGroupConfigService } from '../../abstract-services/field-group-config.abstract.service';
import { AbstractSignablePdfTemplateConfigService } from '../../abstract-services/signable-pdf-template-config.abstract.service';
import { AbstractSignablePdfTemplateImageService } from '../../abstract-services/signable-pdf-template-image.abstract.service';
import { AbstractTemplatePlaceholderValuesService } from '../../abstract-services/template-placeholder-values.abstract.service';
import { generateId } from '../../lib/generate-id';
import { trackById } from '../../lib/track-by/track-by-id';

@Component({
    selector: 'signable-pdf-template-editor',
    templateUrl: './signable-pdf-template-editor.component.html',
    styleUrls: ['./signable-pdf-template-editor.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), fadeInAnimation()],
})
export class SignablePdfTemplateEditorComponent implements AfterViewInit, OnInit {
    constructor(
        private signablePdfTemplateConfigService: AbstractSignablePdfTemplateConfigService,
        private signablePdfTemplateImageService: AbstractSignablePdfTemplateImageService,
        private domSanitizer: DomSanitizer,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef,
        private claimantSignatureFileService: AbstractClaimantSignatureFileService,
        private apiErrorService: AbstractApiErrorService,
        private fieldGroupConfigService: AbstractFieldGroupConfigService,
        private templatePlaceholderValuesService: AbstractTemplatePlaceholderValuesService,
    ) {}

    @Input() mode: 'edit' | 'preview' | 'fillAndSign' = 'edit';
    @Input() isReadonly = false;
    // Preview or fillAndSign - Insert values from a report into PDF elements to check your design or to record user answers.
    @Input() report: Pick<
        Report,
        '_id' | 'car' | 'claimant' | 'ownerOfClaimantsCar' | 'documents' | 'signableDocuments'
    >;
    @Input() basePageWidthInPercent: number = 60; // How much of the parent container's width shall each page fill?
    @Input() areToolsVisible: boolean = true;
    @Input() signaturePadDisabledReason: SignaturePadComponent['disabledReason'];
    @Input() signaturePadFullscreenMode: 'allOptions' | 'signatureOnly' = 'signatureOnly';
    @Input() disableSignatureTransfer = false;
    @Input() showDocumentFrame = true;
    @Input() showCloseEditorIcon = true;
    @Input() pageCounterAndZoomLevel: {
        position: string;
        left: string;
        bottom: string;
        marginLeft: string;
        marginTop: string;
    } = {
        position: 'absolute',
        bottom: '20px',
        left: '30px',
        marginLeft: '20px',
        marginTop: '0px',
    };

    // Signable PDF Template Config
    private signablePdfTemplateConfigIdInitialized?: string;
    private _signablePdfTemplateConfig: SignablePdfTemplateConfig;

    @Input() set signablePdfTemplateConfig(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        this._signablePdfTemplateConfig = signablePdfTemplateConfig;

        // If the signablePdfTemplateConfig changes, we must re-initialize the component. This does not necessarily happen because the component instance is recycled by Angular.
        if (
            !!this.signablePdfTemplateConfigIdInitialized &&
            this.signablePdfTemplateConfigIdInitialized !== signablePdfTemplateConfig._id
        ) {
            this.signablePdfTemplateConfigIdInitialized = signablePdfTemplateConfig._id;
            setTimeout(() => this.ngOnInit());
        }
    }

    get signablePdfTemplateConfig(): SignablePdfTemplateConfig {
        return this._signablePdfTemplateConfig;
    }

    // Option to provide a PDF scroll container. This is necessary when the PDF editor is used in a scrollable container.
    @Input() parentPdfScrollContainer?: string | HTMLElement;

    // Placeholder values
    @Input() placeholderValuesOfPreviewReport: PlaceholderValues;
    @Input() fieldGroupConfigs: FieldGroupConfig[] = [];

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Inputs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Outputs
    //****************************************************************************/
    @Output() signatureEndStroke: EventEmitter<ClaimantSignature[]> = new EventEmitter();
    @Output() signableDocumentChange: EventEmitter<SignableDocument> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() signaturePadFullscreenChange: EventEmitter<'draw' | 'upload' | 'smartphone' | false> = new EventEmitter();
    @Output() signaturePadCreated: EventEmitter<SignaturePadComponent> = new EventEmitter();
    @Output() transferSignature = new EventEmitter<{ signaturePad: SignaturePadComponent }>();
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Outputs
    /////////////////////////////////////////////////////////////////////////////*/

    @ViewChild('pdfScrollContainer') pdfScrollContainer: ElementRef<HTMLDivElement>;
    @ViewChildren(SignaturePadComponent) public signaturePadComponents: SignaturePadComponent[];

    readonly trackById = trackById;
    public currentPage: SignablePdfPage;
    public currentPageDivElement: HTMLDivElement;

    public selectedPdfElements: (TextElement | CheckboxElement | SignatureElement)[] = [];
    /**
     * The images generated from each PDF page.
     */
    public pageImages: SafeResourceUrl[] = [];

    /**
     * Zoom
     */
    public zoomFactorInPercent: number = 100;
    private ZOOM_STEP_SIZE_IN_PERCENT: number = 10;

    // Page width relative to its container.
    public get pageWidthInPercent(): number {
        return (this.basePageWidthInPercent * this.zoomFactorInPercent) / 100;
    }

    /**
     * Clicking a toolbar element will start insertion mode.
     * The element will be attached to the cursor until clicked.
     */
    public pdfElementsInInsertionMode: PdfElement[] | null;
    public pdfElementsInInsertionMode_xPosition: number;
    public pdfElementsInInsertionMode_yPosition: number;

    // Default Sizes
    public readonly DEFAULT_TEXT_ELEMENT_WIDTH_IN_PERCENT = 0.4;
    public readonly DEFAULT_CHECKBOX_ELEMENT_WIDTH_IN_PERCENT: number = 0.025;
    public readonly DEFAULT_SIGNATURE_ELEMENT_WIDTH_IN_PERCENT: number = 0.2;
    // Since all PDF elements have box-sizing: border-box, we must add the border width twice to the height and width.
    public readonly BORDER_WIDTH_IN_PX: number = 2;
    /**
     * When insertion mode is started, start listening to mouse move events.
     * Calling this function unregisters the listener.
     */
    private mouseMoveUnlistener: () => void;
    public OFFSET_BETWEEN_NEW_ELEMENTS_IN_PERCENT_OF_PAGE_HEIGHT: number = 0.01;
    public OFFSET_LEFT_FOR_NEW_ELEMENTS_IN_PERCENT_OF_PAGE_WIDTH: number = 0.01;
    public DISTANCE_TO_MOVE_ELEMENTS_WITH_ARROW_KEYS_IN_PERCENT_OF_PAGE_HEIGHT: number = 0.001;
    public DISTANCE_TO_MOVE_ELEMENTS_WITH_ARROW_KEYS_IN_PERCENT_OF_PAGE_WIDTH: number =
        this.DISTANCE_TO_MOVE_ELEMENTS_WITH_ARROW_KEYS_IN_PERCENT_OF_PAGE_HEIGHT / DINA4_ASPECT_RATIO;

    // Duplicating elements
    public pdfElementsToBeDuplicatedOnPaste: PdfElement[];

    // Font family, color and size
    public fontFamily: SignablePdfTemplateConfig['defaultFont'] = 'Arial';
    public fontColor: SignablePdfTemplateConfig['defaultFontColorInHex'] = '#3c3c3c';
    public fontSize: SignablePdfTemplateConfig['defaultFontSizeInPt'] = 10;

    // Needed to check if hitting the Escape key should close this dialog.
    public childPanelIsOpen: boolean;

    // Submenu of Checkboxes
    public readonly CHECKBOX_YES_OR_NO_MENU_ITEM_TOOLTIP =
        "Falls auf deinem Dokument zwei separate Checkboxen für 'Ja' und 'Nein' enthalten sind, solltest du auf jedem Ankreuzbereich eine Checkbox positionieren - eine mit Ja und eine mit Nein. Sonst reicht eine Ja-Checkbox.";

    // Signature
    // After loading signature binary files, we cache them in this map.
    public signatureFiles: Map<ClaimantSignature['_id'], SafeResourceUrl> = new Map();
    // Instead of filtering an array with every access, we set up this map for quicker access.
    public claimantSignaturesBySlotId: Map<SignatureElement['_id'], ClaimantSignature[]> = new Map();

    // Which document shall be signed? Only used for fillAndSign mode
    public signableDocument: SignableDocument;

    private claimantSignatureDateOptimistic?: string;

    //*****************************************************************************
    //  Lifecycle Hooks
    //****************************************************************************/
    ngOnInit() {
        if (this.mode === 'fillAndSign') {
            // Must come before setting up claimant signatures
            this.selectSignableDocument();

            // Must come after selecting a signable document because that SignableDocument is required to determine
            // the right DocumentMetadata for the document to be signed.
            this.loadFieldGroupConfigsAndPlaceholderValueTree();

            this.fillInitialCheckboxValues();
            this.setupClaimantSignatureBySlotId();
            this.signablePdfTemplateConfigIdInitialized = this.signablePdfTemplateConfig._id;
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.determineCurrentPage();
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnChanges(simpleChanges: SimpleChanges & { signablePdfTemplateConfig: SignablePdfTemplateConfig }) {
        if (simpleChanges.signablePdfTemplateConfig) {
            this.loadPageImages();
            this.insertGlobalFormats();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lifecycle Hooks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Page Thumbnails
    //****************************************************************************/
    /**
     * For each page, load the image rendered by the backend.
     */
    private async loadPageImages() {
        try {
            this.pageImages = await Promise.all(
                this.signablePdfTemplateConfig.pages.map(async (page) => {
                    // Thumbnails of uploaded PDFs are immutable, therefore we don't need to pass a hash.
                    const pageImageBlob = await this.signablePdfTemplateImageService.get(
                        `${this.signablePdfTemplateConfig._id}-page-${page.pageNumber}`,
                        undefined,
                    );
                    return this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(pageImageBlob));
                }),
            );
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    BLOB_CANNOT_BE_FETCHED_FROM_SERVER_WHILE_OFFLINE: {
                        title: 'PDF-Seiten nicht offline verfügbar',
                        body: `Öffne diese Datei einmalig, während du online bist. Dann steht sie dir auch offline zur Verfügung.`,
                    },
                },
                defaultHandler: {
                    title: `PDF-Seiten nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Page Thumbnails
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  PDF Elements
    //****************************************************************************/
    public selectPdfElement(pdfElement: PdfElement, mouseEvent: MouseEvent) {
        if (this.mode === 'fillAndSign') return;

        // Extend selection if any of the usual keys is pressed.
        if (mouseEvent.ctrlKey || mouseEvent.metaKey || mouseEvent.shiftKey) {
            toggleValueInArray(pdfElement, this.selectedPdfElements);
        }
        // Replace selection when simply clicking element.
        else {
            this.selectedPdfElements = [pdfElement];
        }

        // Display the color and font values of the first selected element.
        const elementsWithFontStyling = this.filterElementsSupportingFontStyling(this.selectedPdfElements);
        if (elementsWithFontStyling.length) {
            this.fontFamily = elementsWithFontStyling[0].font;
            this.fontSize = elementsWithFontStyling[0].fontSizeInPt;
        }
        const elementsWithColorStyling = this.filterElementsSupportingColorStyling(this.selectedPdfElements);
        if (elementsWithColorStyling.length) {
            this.fontColor = elementsWithColorStyling[0].colorInHex;
        }
    }

    public clearSelectionOfPdfElements() {
        this.selectedPdfElements = [];

        // Reset the inputs to the global values of the config.
        this.insertGlobalFormats();
    }

    public rememberPdfElementsToDuplicate(pdfElements: PdfElement[]) {
        if (!pdfElements.length) return;
        this.pdfElementsToBeDuplicatedOnPaste = pdfElements;
    }

    public duplicateSelectedPdfElements(pdfElements: PdfElement[] = this.selectedPdfElements) {
        for (const pdfElementToDuplicate of pdfElements) {
            switch (pdfElementToDuplicate.type) {
                case 'text': {
                    const newTextElement = new TextElement(omit(pdfElementToDuplicate, '_id'));
                    newTextElement.topRelativeToPageHeight +=
                        pdfElementToDuplicate.heightRelativeToPageHeight +
                        this.OFFSET_BETWEEN_NEW_ELEMENTS_IN_PERCENT_OF_PAGE_HEIGHT;
                    newTextElement.leftRelativeToPageWidth +=
                        this.OFFSET_LEFT_FOR_NEW_ELEMENTS_IN_PERCENT_OF_PAGE_WIDTH;
                    this.addElementToPage(newTextElement);
                    break;
                }
                case 'checkbox': {
                    const newCheckboxElement = new CheckboxElement(omit(pdfElementToDuplicate, '_id'));
                    newCheckboxElement.topRelativeToPageHeight +=
                        pdfElementToDuplicate.heightRelativeToPageHeight +
                        this.OFFSET_BETWEEN_NEW_ELEMENTS_IN_PERCENT_OF_PAGE_HEIGHT;
                    newCheckboxElement.leftRelativeToPageWidth +=
                        this.OFFSET_LEFT_FOR_NEW_ELEMENTS_IN_PERCENT_OF_PAGE_WIDTH;
                    this.addElementToPage(newCheckboxElement);
                    break;
                }
                case 'signature': {
                    const newSignatureElement = new SignatureElement(omit(pdfElementToDuplicate, '_id'));
                    newSignatureElement.topRelativeToPageHeight +=
                        pdfElementToDuplicate.heightRelativeToPageHeight +
                        this.OFFSET_BETWEEN_NEW_ELEMENTS_IN_PERCENT_OF_PAGE_HEIGHT;
                    newSignatureElement.leftRelativeToPageWidth +=
                        this.OFFSET_LEFT_FOR_NEW_ELEMENTS_IN_PERCENT_OF_PAGE_WIDTH;
                    this.addElementToPage(newSignatureElement);
                    break;
                }
            }
        }

        this.saveSignablePdfTemplateConfig();
    }

    public deleteSelectedPdfElements() {
        for (const pdfElementToDelete of this.selectedPdfElements) {
            switch (pdfElementToDelete.type) {
                case 'text':
                    removeFromArray(pdfElementToDelete, this.currentPage.textElements);
                    break;
                case 'checkbox':
                    removeFromArray(pdfElementToDelete, this.currentPage.checkboxElements);
                    if (this.isCheckboxElementRequired(pdfElementToDelete)) {
                        this.toggleRequiredCheckbox(pdfElementToDelete);
                    }
                    break;
                case 'signature':
                    removeFromArray(pdfElementToDelete, this.currentPage.signatureElements);
                    break;
            }
        }
        this.saveSignablePdfTemplateConfig();
    }

    public filterElementsSupportingColorStyling(pdfElements: PdfElement[]): (TextElement | CheckboxElement)[] {
        return pdfElements.filter((pdfElement) => pdfElement.type === 'text' || pdfElement.type === 'checkbox') as (
            | TextElement
            | CheckboxElement
        )[];
    }

    /**
     * Update the text element if selected. Otherwise, update the default.
     */
    public updateColor() {
        if (this.selectedPdfElements.length) {
            for (const selectedPdfElement of this.selectedPdfElements) {
                // Only text and checkbox elements can be given a color. Signature elements can't.
                if ('colorInHex' in selectedPdfElement) {
                    selectedPdfElement.colorInHex = this.fontColor;
                }
            }
        } else {
            this.signablePdfTemplateConfig.defaultFontColorInHex = this.fontColor;
        }
    }

    public movePdfElement({
        pdfElement,
        direction,
        percentageOfPageDimension,
    }: {
        pdfElement?: PdfElement;
        direction: 'vertical' | 'horizontal';
        percentageOfPageDimension: number;
    }) {
        const pdfElementsToMove = pdfElement ? [pdfElement] : this.selectedPdfElements;

        if (!pdfElementsToMove.length) return;

        for (const pdfElementToMove of pdfElementsToMove) {
            if (direction === 'vertical') {
                pdfElementToMove.topRelativeToPageHeight += percentageOfPageDimension;
            } else {
                pdfElementToMove.leftRelativeToPageWidth += percentageOfPageDimension;
            }
        }
    }

    private insertGlobalFormats() {
        this.fontFamily = this.signablePdfTemplateConfig.defaultFont;
        this.fontColor = this.signablePdfTemplateConfig.defaultFontColorInHex;
        this.fontSize = this.signablePdfTemplateConfig.defaultFontSizeInPt;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END PDF Elements
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  PDF Element Sizing
    //****************************************************************************/
    /**
     * InteractJS requires us to use box-sizing: border-box. Therefore, the border width must be added when calculating dimensions.
     */
    public get borderWidthRelativeToPageHeight(): number {
        return this.BORDER_WIDTH_IN_PX / this.currentPageDivElement.clientHeight;
    }

    /**
     * InteractJS requires us to use box-sizing: border-box. Therefore, the border width must be added when calculating dimensions.
     */
    public get borderWidthRelativeToPageWidth(): number {
        return this.BORDER_WIDTH_IN_PX / this.currentPageDivElement.clientWidth;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END PDF Element Sizing
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Text Elements
    //****************************************************************************/
    public createTextElement(content: string) {
        const newTextElement = new TextElement({
            content,
            pageNumber: this.currentPage.pageNumber,
            widthRelativeToPageWidth: null, // Will be calculated below.
            heightRelativeToPageHeight: null, // Will be calculated below.
            fontSizeInPt: this.signablePdfTemplateConfig.defaultFontSizeInPt,
            colorInHex: this.signablePdfTemplateConfig.defaultFontColorInHex,
            font: this.signablePdfTemplateConfig.defaultFont,
            // Will be set by the positioning method.
            topRelativeToPageHeight: null,
            leftRelativeToPageWidth: null,
        });

        newTextElement.widthRelativeToPageWidth =
            this.getDefaultTextElementWidthInPx() / this.currentPageDivElement.clientWidth;
        newTextElement.heightRelativeToPageHeight =
            this.getTextElementHeightInPx(newTextElement) / this.currentPageDivElement.clientHeight;

        this.enterInsertionMode([newTextElement]);
    }

    public getNumberOfLinesInContent(content: string): number {
        return content.split('\n').length;
    }

    /**
     * The default width only depends on constants. The height depends on the content.
     */
    public getDefaultTextElementWidthInPx(): number {
        return (
            this.DEFAULT_TEXT_ELEMENT_WIDTH_IN_PERCENT * this.currentPageDivElement.clientWidth +
            2 * this.BORDER_WIDTH_IN_PX
        );
    }

    /**
     * The height of a text element depends on the number of lines in the content, therefore we must pass a text element.
     */
    public getTextElementHeightInPx(textElement: TextElement): number {
        const fontSize = this.signablePdfTemplateConfig.defaultFontSizeInPt;
        const numberOfLines = this.getNumberOfLinesInContent(textElement.content);
        const lineHeight = 1.2;

        const contentHeightInPercent = this.getLineHeightRelativeToPageHeight({
            lineHeightInPt: fontSize * numberOfLines * lineHeight,
        });

        // Add the border because interactJS requires us to use box-sizing: border-box. Therefore, the border must be included in the calculations to not reduce the space for the content.
        return contentHeightInPercent * this.currentPageDivElement.clientHeight + 2 * this.BORDER_WIDTH_IN_PX;
    }

    public filterElementsSupportingFontStyling(pdfElements: PdfElement[]): TextElement[] {
        return pdfElements.filter((pdfElement) => pdfElement.type === 'text') as TextElement[];
    }

    /**
     * Update the text element if selected. Otherwise, update the default.
     */
    public updateFontFamily() {
        if (this.selectedPdfElements.length) {
            for (const selectedPdfElement of this.selectedPdfElements) {
                // Only text elements can be given a font.
                if ('font' in selectedPdfElement) {
                    selectedPdfElement.font = this.fontFamily;
                }
            }
        } else {
            this.signablePdfTemplateConfig.defaultFont = this.fontFamily;
        }
        this.saveSignablePdfTemplateConfig();
    }

    /**
     * Update the text element if selected. Otherwise, update the default.
     */
    public updateFontSize() {
        if (this.selectedPdfElements.length) {
            for (const selectedPdfElement of this.selectedPdfElements) {
                // Only text elements can be given a font size.
                if ('fontSizeInPt' in selectedPdfElement) {
                    selectedPdfElement.fontSizeInPt = this.fontSize;
                }
            }
        } else {
            this.signablePdfTemplateConfig.defaultFontSizeInPt = this.fontSize;
        }
        this.saveSignablePdfTemplateConfig();
    }

    /**
     * When selecting a font size from the autocomplete, the value will currently not be transmitted to
     * the NumberInputDirective. Therefore, we must set it manually.
     */
    public setLocalFontSize(fontSize: number) {
        this.fontSize = fontSize;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Text Elements
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Checkbox Elements
    //****************************************************************************/
    private getCheckboxElement({
        targetProperty,
        checkboxPairId,
        answer,
    }: {
        targetProperty?: CheckboxElement['targetProperty'];
        checkboxPairId?: CheckboxElement['checkboxPairId'];
        answer: CheckboxElement['answer'];
    }) {
        const newCheckboxElement = new CheckboxElement({
            targetProperty,
            checkboxPairId,
            answer,
            pageNumber: this.currentPage.pageNumber,
            widthRelativeToPageWidth:
                this.DEFAULT_CHECKBOX_ELEMENT_WIDTH_IN_PERCENT + 2 * this.borderWidthRelativeToPageWidth,
            // A checkbox must be squared. Therefore, the percentage must be adjusted to reflect the same number of pixels.
            heightRelativeToPageHeight:
                this.DEFAULT_CHECKBOX_ELEMENT_WIDTH_IN_PERCENT * DINA4_ASPECT_RATIO +
                2 * this.borderWidthRelativeToPageHeight,
            colorInHex: this.signablePdfTemplateConfig.defaultFontColorInHex,
            // Will be set by the positioning method.
            topRelativeToPageHeight: null,
            leftRelativeToPageWidth: null,
        });

        return newCheckboxElement;
    }

    public createCheckboxElement({
        targetProperty,
        answer,
    }: {
        targetProperty?: CheckboxElement['targetProperty'];
        answer: CheckboxElement['answer'];
    }) {
        this.enterInsertionMode([this.getCheckboxElement({ targetProperty, answer })]);
    }

    public createCheckboxPairElements() {
        const checkboxPairId = generateId();
        const yesCheckbox = this.getCheckboxElement({ checkboxPairId, answer: 'yes' });
        const noCheckbox = this.getCheckboxElement({ checkboxPairId, answer: 'no' });
        this.enterInsertionMode([yesCheckbox, noCheckbox]);
    }

    public toggleRequiredCheckbox(checkboxElement: CheckboxElement) {
        const keyForRequirement =
            checkboxElement.targetProperty ?? checkboxElement.checkboxPairId ?? checkboxElement._id;
        toggleValueInArray(keyForRequirement, this.signablePdfTemplateConfig.requiredCheckboxes);

        // Filter out null values.
        this.signablePdfTemplateConfig.requiredCheckboxes =
            this.signablePdfTemplateConfig.requiredCheckboxes.filter(Boolean);
    }

    /**
     * While in fill and sign mode, toggle a checkbox. This captures user input.
     */
    public toggleCheckboxInFillAndSignMode(checkboxElement: CheckboxElement) {
        if (this.mode !== 'fillAndSign' || this.isReadonly) return;

        // Yes-checkbox means true, no-checkbox means false.
        const checkboxMeaning: boolean = checkboxElement.answer === 'yes';

        let checkboxValueName: CheckboxValueTracker['name'];
        switch (checkboxElement.targetProperty) {
            case 'claimantMayDeductVat':
            case 'vehicleHasPreviousDamages':
                checkboxValueName = checkboxElement.targetProperty;
                break;
            default:
                checkboxValueName = checkboxElement.checkboxPairId || checkboxElement._id;
        }

        // Find or create the right checkbox value tracker.
        let checkboxValueObject: CheckboxValueTracker = this.getCheckboxValueTracker(checkboxValueName);
        if (!checkboxValueObject) {
            checkboxValueObject = new CheckboxValueTracker({
                name: checkboxValueName,
            });
            this.signableDocument.checkboxValueTrackers.push(checkboxValueObject);
        }

        // If the user clicks the active checkbox again, treat it as unchecked.
        if (checkboxValueObject.value === checkboxMeaning) {
            checkboxValueObject.value = null;
        } else {
            checkboxValueObject.value = checkboxMeaning;
        }

        this.emitSignableDocumentChange();
    }

    public isCheckboxElementRequired(checkboxElement: CheckboxElement): boolean {
        return this.signablePdfTemplateConfig.requiredCheckboxes.includes(
            checkboxElement.targetProperty || checkboxElement.checkboxPairId || checkboxElement._id,
        );
    }

    protected isCheckboxAnswerMarkerVisible(checkboxElement: CheckboxElement): boolean {
        if (!checkboxElement.targetProperty && !checkboxElement.checkboxPairId) return false;

        // Checkbox answer marker is visible, if the checkbox element or its counterpart is selected.
        const isVisible = this.selectedPdfElements.some((element) => {
            if (element.type !== 'checkbox') {
                return false;
            }

            if (element.targetProperty && checkboxElement.targetProperty) {
                return element.targetProperty === checkboxElement.targetProperty;
            }

            if (element.checkboxPairId && checkboxElement.checkboxPairId) {
                return element.checkboxPairId === checkboxElement.checkboxPairId;
            }

            return false;
        });

        return isVisible;
    }

    /**
     * In fill and sign mode, create the checkbox value tracker (if they do not exist yet) and if data in the report is available.
     *
     * This function is called each time, a user opens the customer signature dialog.
     * But the checkbox value trackers are only created once.
     * We do not override the value if it is already set - either by the user or by the function fillInitialCheckboxValues before.
     */
    private fillInitialCheckboxValues() {
        // Get all checkbox elements from all pages.
        for (const checkboxElement of this.signablePdfTemplateConfig.pages
            .map((page) => page.checkboxElements)
            .flat()) {
            switch (checkboxElement.targetProperty) {
                /**
                 * Sync claimantMayDeductVat checkbox value with report value.
                 */
                case 'claimantMayDeductVat': {
                    const checkboxValueTracker = this.getCheckboxValueTracker('claimantMayDeductVat');
                    if (checkboxValueTracker) {
                        checkboxValueTracker.value = mayCarOwnerDeductTaxes(this.report);
                    } else {
                        this.signableDocument.checkboxValueTrackers.push(
                            new CheckboxValueTracker({
                                name: 'claimantMayDeductVat',
                                value: mayCarOwnerDeductTaxes(this.report),
                            }),
                        );
                    }
                    break;
                }

                /**
                 * If the user has already entered a previous damage, we can activate the checkbox.
                 */
                case 'vehicleHasPreviousDamages': {
                    if (
                        !this.getCheckboxValueTracker('vehicleHasPreviousDamages') &&
                        !!this.report.car.repairedPreviousDamage
                    ) {
                        this.signableDocument.checkboxValueTrackers.push(
                            new CheckboxValueTracker({
                                name: 'vehicleHasPreviousDamages',
                                value: true,
                            }),
                        );
                    }
                    break;
                }
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Checkbox Elements
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signature Elements
    //****************************************************************************/
    public createSignatureElement() {
        const newSignatureElement = new SignatureElement({
            pageNumber: this.currentPage.pageNumber,
            widthRelativeToPageWidth:
                this.DEFAULT_SIGNATURE_ELEMENT_WIDTH_IN_PERCENT + 2 * this.borderWidthRelativeToPageWidth,
            // The width percentage must be adjusted to reflect the same number of pixels relative to the page height.
            heightRelativeToPageHeight:
                ((this.DEFAULT_SIGNATURE_ELEMENT_WIDTH_IN_PERCENT * DINA4_ASPECT_RATIO) / 16) * 9 +
                2 * this.borderWidthRelativeToPageHeight,
            // Will be set by the positioning method.
            topRelativeToPageHeight: null,
            leftRelativeToPageWidth: null,
        });

        this.enterInsertionMode([newSignatureElement]);
    }

    /**
     * Create a Map with the claimant signatures organized by the slot ID of the signature element they belong to.
     */
    public setupClaimantSignatureBySlotId() {
        const signatureElements: SignatureElement[] = this.signablePdfTemplateConfig.pages
            .map((page) => page.signatureElements)
            .flat();
        for (const signatureElement of signatureElements) {
            let matchingClaimantSignature: ClaimantSignature = this.signableDocument.signatures.find(
                (claimantSignature) => claimantSignature.signatureSlotId === signatureElement._id,
            );

            // If the matching signature doesn't exist, create it. This will be the normal case after the user selects a PDF template.
            if (!matchingClaimantSignature) {
                matchingClaimantSignature = new ClaimantSignature({
                    signatureSlotId: signatureElement._id,
                    documentType: this.signablePdfTemplateConfig.documentType,
                    customDocumentOrderConfigId: this.signablePdfTemplateConfig.customDocumentOrderConfigId,
                });
                this.signableDocument.signatures.push(matchingClaimantSignature);
            }

            // Assign an array because the child component requires an array. Don't generate the array on the fly because that changes the array reference with every iteration.
            this.claimantSignaturesBySlotId.set(signatureElement._id, [matchingClaimantSignature]);
        }
    }

    /**
     * When in fillAndSign mode, trigger saving all SignaturePads.
     *
     * This method should only be called from the outside when the user confirms signing has finished (e.g. by clicking a "next" button).
     */
    public async saveSignatures() {
        if (this.mode !== 'fillAndSign') return;

        try {
            const promises = this.signaturePadComponents.map((signaturePadComponent) =>
                signaturePadComponent.saveSignature(),
            );
            await Promise.all(promises);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Unterschriften nicht gespeichert`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signature Elements
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Insertion Mode
    //****************************************************************************/
    /**
     * When clicking a tool, a PdfElement is created and insertion mode is started.
     * Clicking the final position of the element will leave insertion mode.
     */
    public enterInsertionMode(pdfElements: PdfElement[]) {
        this.pdfElementsInInsertionMode = pdfElements;

        this.pdfElementsInInsertionMode_xPosition = null;
        this.pdfElementsInInsertionMode_yPosition = null;

        this.mouseMoveUnlistener = this.renderer.listen('document', 'mousemove', (event: MouseEvent) => {
            this.pdfElementsInInsertionMode_xPosition = event.pageX;
            this.pdfElementsInInsertionMode_yPosition = event.pageY;
        });
    }

    public leaveInsertionMode() {
        if (!this.pdfElementsInInsertionMode) return;

        this.mouseMoveUnlistener?.();
        this.pdfElementsInInsertionMode = null;
    }

    public placeElementAtClickPosition({
        pdfElements,
        mouseEvent,
        pdfPageElement,
    }: {
        pdfElements: PdfElement[];
        mouseEvent: MouseEvent;
        pdfPageElement: HTMLDivElement;
    }) {
        if (!pdfElements?.length) return;

        pdfElements.forEach((pdfElement, index) => {
            // Derive position from the position the user clicked. Add 5 pixels to display the preview below the cursor.
            pdfElement.leftRelativeToPageWidth = (mouseEvent.offsetX + index * 30) / pdfPageElement.clientWidth;
            pdfElement.topRelativeToPageHeight = mouseEvent.offsetY / pdfPageElement.clientHeight;

            this.addElementToPage(pdfElement, index);
        });

        this.leaveInsertionMode();
    }

    /**
     * After generating an element, this method positions it on the PDF page.
     */
    public addElementToPage(pdfElement: PdfElement, index = 0) {
        // Add to page.
        switch (pdfElement.type) {
            case 'text':
                this.currentPage.textElements.push(pdfElement);
                break;
            case 'checkbox':
                this.currentPage.checkboxElements.push(pdfElement);
                // Mark targetProperty / element ID as required by default.
                ensureValueIsInArray(
                    pdfElement.targetProperty || pdfElement.checkboxPairId || pdfElement._id,
                    this.signablePdfTemplateConfig.requiredCheckboxes,
                );
                break;
            case 'signature':
                this.currentPage.signatureElements.push(pdfElement);
                break;
        }

        pdfElement.pageNumber = this.currentPage.pageNumber;

        this.saveSignablePdfTemplateConfig();

        // Only open panel for the first inserted element.
        if (index === 0) {
            /**
             * Custom placeholders are inserted without content. Double-click them to open their edit panel right away.
             * Also open checkbox element edit panel right away so the user can set a name.
             */
            if ('content' in pdfElement && !pdfElement.content) {
                window.setTimeout(
                    () =>
                        document
                            .querySelector(`[data-text-element-id="${pdfElement._id}"]`)
                            ?.dispatchEvent(new MouseEvent('dblclick')),
                    0,
                );
            }

            /**
             * Open the checkbox element edit panel right away so the user can set a name.
             * This is not necessary for checkboxes with a target property because they have a static name.
             */
            if (pdfElement.type === 'checkbox' && !pdfElement.targetProperty) {
                window.setTimeout(
                    () =>
                        document
                            .querySelector(`[data-checkbox-element-id="${pdfElement._id}"]`)
                            ?.dispatchEvent(new MouseEvent('dblclick')),
                    0,
                );
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Insertion Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Page Selection
    //****************************************************************************/
    /**
     * Store the currently selected page in a class property.
     *
     * This function should be called on scroll events and when changing the page through the regular click controls.
     */
    public determineCurrentPage(): void {
        let pdfScrollContainer: HTMLElement = this.pdfScrollContainer.nativeElement;

        // Get parent PDF scroll container by selector if it's a string.
        if (typeof this.parentPdfScrollContainer === 'string') {
            pdfScrollContainer = document.querySelector(this.parentPdfScrollContainer);
        }

        // Get parent PDF scroll container by ElementRef if it's an HTMLElement.
        if (this.parentPdfScrollContainer instanceof HTMLElement) {
            pdfScrollContainer = this.parentPdfScrollContainer;
        }

        // May not be available yet.
        if (!pdfScrollContainer) return;

        // Shorthand
        const pages = this.signablePdfTemplateConfig.pages;

        let scrollContainerRect: Pick<DOMRect, 'top' | 'height'> = pdfScrollContainer.getBoundingClientRect();

        // Special case for root level scroll container
        if (pdfScrollContainer?.nodeName === 'HTML') {
            scrollContainerRect = {
                top: 0,
                height: window.innerHeight,
            };
        }

        const halfwayOfScrollContainer: number = scrollContainerRect.top + scrollContainerRect.height / 2;

        // Go through pages in reverse order to see which page has been scrolled up enough to cross
        // the 50% line of the scroll container.
        const reversedPages = [...pages].reverse();
        for (const page of reversedPages) {
            const currentPageDivElement: HTMLDivElement = document.getElementById(
                `page-${page.pageNumber}`,
            ) as HTMLDivElement;
            const pageRect: DOMRect = currentPageDivElement.getBoundingClientRect();

            // This page's top edge is above half of the parent container.
            if (pageRect.top < halfwayOfScrollContainer) {
                this.currentPageDivElement = currentPageDivElement;
                this.currentPage = page;
                break;
            }
        }
    }

    public scrollToPage(direction: 'next' | 'previous') {
        // Shorthand
        const pages = this.signablePdfTemplateConfig.pages;

        const numericalDirection = direction === 'next' ? 1 : -1;

        const indexOfCurrentPage = pages.indexOf(this.currentPage);
        const newPage = pages[indexOfCurrentPage + numericalDirection];

        if (!newPage) return;

        this.currentPage = newPage;
        this.currentPageDivElement = document.getElementById(`page-${newPage.pageNumber}`) as HTMLDivElement;

        this.currentPageDivElement.scrollIntoView({
            behavior: 'smooth',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Page Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Zoom
    //****************************************************************************/
    /**
     * Increase zoom level by one step.
     */
    public increaseZoom() {
        this.zoomFactorInPercent += this.ZOOM_STEP_SIZE_IN_PERCENT;
    }

    /**
     * Decrease zoom level by one step.
     */
    public decreaseZoom() {
        if (this.zoomFactorInPercent <= this.ZOOM_STEP_SIZE_IN_PERCENT) return;

        this.zoomFactorInPercent -= this.ZOOM_STEP_SIZE_IN_PERCENT;
    }

    @HostListener('wheel', ['$event'])
    public zoomOnCtrlScroll(wheelEvent: WheelEvent) {
        // Update which page is the current one.
        this.determineCurrentPage();

        if (!(wheelEvent.ctrlKey || wheelEvent.metaKey)) return;

        if (wheelEvent.deltaY < 0) {
            this.increaseZoom();
        } else {
            this.decreaseZoom();
        }
        wheelEvent.preventDefault();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Zoom
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Window Resize
    //****************************************************************************/
    @HostListener('window:resize', ['$event'])
    public triggerChangeDetectionOnResize() {
        // It is necessary to manually trigger change detection in order to maintain the correct position of the PDF elements.
        this.changeDetectorRef.detectChanges();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Window Resize
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Mode Selection
    //****************************************************************************/
    public selectMode(mode: this['mode']) {
        this.mode = mode;

        if (mode === 'preview') {
            // Clear selection to prevent blue selection backgrounds in preview mode.
            this.clearSelectionOfPdfElements();

            if (this.report) {
                this.selectSignableDocument();
                this.loadFieldGroupConfigsAndPlaceholderValueTree();
                this.loadClaimantSignatures();
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Mode Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Preview
    //****************************************************************************/
    public handleLoadingReportForPreview(report: Report) {
        this.report = report;
        this.selectSignableDocument();
        this.loadFieldGroupConfigsAndPlaceholderValueTree();
        this.loadClaimantSignatures();
    }

    private selectSignableDocument() {
        this.signableDocument = this.report.signableDocuments.find(
            (signableDocument) =>
                signableDocument.documentType === this.signablePdfTemplateConfig.documentType &&
                signableDocument.customDocumentOrderConfigId ==
                    this.signablePdfTemplateConfig.customDocumentOrderConfigId,
        );
    }

    public clearReportForPreview() {
        this.report = null;
        this.placeholderValuesOfPreviewReport = null;
    }

    public replacePlaceholdersDependingOnMode(textWithPlaceholders: string): string {
        if (this.mode === 'edit' || !this.placeholderValuesOfPreviewReport || !this.report) {
            return textWithPlaceholders || 'Mein Platzhalter';
        }

        // Include optimistic value for claimant signature date.
        const placeholderValuesOfPreviewReport = { ...this.placeholderValuesOfPreviewReport };
        if (!placeholderValuesOfPreviewReport.UnterschriftKundeDatum) {
            placeholderValuesOfPreviewReport.UnterschriftKundeDatum = this.claimantSignatureDateOptimistic;
        }

        let replacedText = replacePlaceholders({
            textWithPlaceholders,
            placeholderValues: placeholderValuesOfPreviewReport,
            fieldGroupConfigs: this.fieldGroupConfigs,
            isHtmlAllowed: false,
        });

        // For the customer, it's better to see empty space instead of the placeholder text "WERT_XXX_FEHLT".
        if (this.mode === 'preview' || this.mode === 'fillAndSign') {
            replacedText = removeMissingPlaceholdersFromText(replacedText);
        }
        return replacedText;
    }

    private async loadFieldGroupConfigsAndPlaceholderValueTree() {
        if (!this.report?._id) {
            console.warn(
                'The application is trying to load placeholders and field configs before the report has been loaded.',
            );
            return;
        }

        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        await this.loadPlaceholderValues();
    }

    /**
     * Generates the placeholder values for a report and signatures (such as its date).
     *
     * Must be called every time an input to those placeholder values changes.
     */
    public async loadPlaceholderValues() {
        try {
            this.placeholderValuesOfPreviewReport = await this.templatePlaceholderValuesService.getReportValues({
                reportId: this.report?._id,
                letterDocument: this.report.documents.find(
                    (document) => document.type === this.signableDocument?.documentType,
                ),
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Platzhalterwerte nicht geladen',
                    body: 'Die Werte der Platzhalter konnten nicht geladen werden. Bitte versuche es erneut.',
                },
            });
        }

        // In order for the customer to see a date when he signs, even if the assessor has not yet selected a date
        // explicitly through the datepicker, we render the date of today on the fly.
        // We don't render it in the placeholder generation method because that would change the placeholder value
        // structure every day (with a date change), rendering the cache nearly useless.
        if (this.placeholderValuesOfPreviewReport) {
            const signatureDate = this.signableDocument?.signatures?.find((signature) => !!signature?.date)?.date;
            this.claimantSignatureDateOptimistic = moment(signatureDate).format('DD.MM.YYYY');
        }
    }

    /**
     * This function is used to display the checkbox value in preview mode or fill and sign mode.
     * It does not create a checkboxValueTracker nor save the checkbox value itself but only controls the display.
     * The checkboxValueTracker is created and set in the function initializeCheckboxValues.or if a checkbox is toggled.
     */
    public getCheckboxValueFromReport(checkboxElement: CheckboxElement): boolean {
        if (!this.report) return;

        let valueFromReport: boolean;

        switch (checkboxElement.targetProperty) {
            case 'claimantMayDeductVat':
                // If this report has filled PDF values, i.e. a PDF document has been signed which allows checking a checkbox,
                // take that answer. If the report only includes signable documents from text building blocks, derive the value from the report.
                valueFromReport = this.signableDocument
                    ? this.getCheckboxValueTracker('claimantMayDeductVat')?.value
                    : mayCarOwnerDeductTaxes(this.report);
                break;
            case 'vehicleHasPreviousDamages':
                valueFromReport = this.signableDocument
                    ? this.getCheckboxValueTracker('vehicleHasPreviousDamages')?.value
                    : !!this.report.car.repairedPreviousDamage;
                break;
            default:
                valueFromReport = this.getCheckboxValueTracker(
                    checkboxElement.checkboxPairId || checkboxElement._id,
                )?.value;
        }

        // If this is an inverted checkbox, check it if the user answered no.
        if (checkboxElement.answer === 'no') {
            return valueFromReport === false;
        }

        return valueFromReport;
    }

    public getCheckboxValueTracker(name: CheckboxValueTracker['name']): CheckboxValueTracker {
        return this.signableDocument?.checkboxValueTrackers.find(
            (checkboxValueTracker) => checkboxValueTracker.name === name,
        );
    }

    public async loadClaimantSignatures() {
        const signaturesToLoad = this.signableDocument?.signatures || [];
        for (const claimantSignature of signaturesToLoad) {
            let claimantSignatureFile: Blob;
            const signatureFileName: string = getClaimantSignatureFileName({
                reportId: this.report._id,
                documentType: this.signablePdfTemplateConfig.documentType,
                customDocumentOrderConfigId: this.signablePdfTemplateConfig.customDocumentOrderConfigId,
                signatureElementId: claimantSignature.signatureSlotId,
            });
            try {
                claimantSignatureFile = await this.claimantSignatureFileService.get(signatureFileName, undefined);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: `Unterschrift konnte nicht geladen werden`,
                        body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                    },
                });
            }
            const objectUrl = URL.createObjectURL(claimantSignatureFile);
            const safeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(objectUrl);
            this.signatureFiles.set(claimantSignature.signatureSlotId, safeResourceUrl);
        }
    }

    public getSignatureObjectUrl(signatureSlotId: SignatureElement['_id']): SafeResourceUrl {
        return this.signatureFiles.get(signatureSlotId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Preview
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public saveSignablePdfTemplateConfig() {
        this.signablePdfTemplateConfigService.put(this.signablePdfTemplateConfig);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeEditor(): void {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        // Don't trigger any shortcuts if the child panel is open.
        if (this.childPanelIsOpen) return;

        switch (event.key) {
            case 'Delete':
                if (!isCursorInInputOrTextarea()) {
                    this.deleteSelectedPdfElements();
                }
                break;
            case 'Escape':
                if (this.pdfElementsInInsertionMode) {
                    this.leaveInsertionMode();
                    return;
                }
                this.closeEditor();
                break;
            case 'c':
                if (event.ctrlKey || event.metaKey) {
                    this.rememberPdfElementsToDuplicate(this.selectedPdfElements);
                }
                break;
            case 'v':
                if (event.ctrlKey || event.metaKey) {
                    if (!this.pdfElementsToBeDuplicatedOnPaste) return;
                    this.duplicateSelectedPdfElements(this.pdfElementsToBeDuplicatedOnPaste);
                }
                break;
            //*****************************************************************************
            //  Zoom
            //****************************************************************************/
            case '0':
                if (event.ctrlKey || event.metaKey) {
                    this.zoomFactorInPercent = 100;
                    event.preventDefault();
                }
                break;
            case '+':
                if (event.ctrlKey || event.metaKey) {
                    this.increaseZoom();
                    event.preventDefault();
                }
                break;
            case '-':
                if (event.ctrlKey || event.metaKey) {
                    this.decreaseZoom();
                    event.preventDefault();
                }
                break;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Zoom
            /////////////////////////////////////////////////////////////////////////////*/
            /**
             * Arrow Keys
             */
            case 'ArrowDown':
                event.preventDefault();
                this.movePdfElement({
                    direction: 'vertical',
                    percentageOfPageDimension: this.DISTANCE_TO_MOVE_ELEMENTS_WITH_ARROW_KEYS_IN_PERCENT_OF_PAGE_HEIGHT,
                });
                this.saveSignablePdfTemplateConfig();
                break;
            case 'ArrowUp':
                event.preventDefault();
                this.movePdfElement({
                    direction: 'vertical',
                    percentageOfPageDimension:
                        -1 * this.DISTANCE_TO_MOVE_ELEMENTS_WITH_ARROW_KEYS_IN_PERCENT_OF_PAGE_HEIGHT,
                });
                this.saveSignablePdfTemplateConfig();
                break;
            case 'ArrowRight':
                event.preventDefault();
                this.movePdfElement({
                    direction: 'horizontal',
                    percentageOfPageDimension: this.DISTANCE_TO_MOVE_ELEMENTS_WITH_ARROW_KEYS_IN_PERCENT_OF_PAGE_WIDTH,
                });
                this.saveSignablePdfTemplateConfig();
                break;
            case 'ArrowLeft':
                event.preventDefault();
                this.movePdfElement({
                    direction: 'horizontal',
                    percentageOfPageDimension:
                        -1 * this.DISTANCE_TO_MOVE_ELEMENTS_WITH_ARROW_KEYS_IN_PERCENT_OF_PAGE_WIDTH,
                });
                this.saveSignablePdfTemplateConfig();
                break;
        }
    }

    public emitSignableDocumentChange() {
        this.signableDocumentChange.emit(this.signableDocument);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
    public getLineHeightRelativeToPageHeight = getLineHeightRelativeToPageHeight;
}

<div id="storage-usage-panel-container" class="card">
    <!--============================================-->
    <!-- Header -->
    <!--============================================-->
    <header>
        <!--********** Icon & Headline **********-->
        <div id="header-icon-and-headlines-container">
            <!--********** Panel Icon **********-->
            <div id="panel-header-icon-container" class="emoji">
                🗄
                <!-- File Cabinet Icon -->
            </div>

            <!--********** Headline **********-->
            <div id="headline-container">
                <h2 id="panel-headline">Offline-Speicher</h2>
                <div id="panel-tagline">Auf diesem Gerät und Browser.</div>
            </div>
        </div>

        <!--********** Close Icon **********-->
        <mat-icon class="dialog-close-icon light" (click)="close()">close</mat-icon>

        <!--********** Storage Quota **********-->
        <div id="total-storage-quota-container">
            <ng-container *ngIf="deviceStorageStatistics && deviceStorageStatistics.storageUsageBytes != null">
                <div
                    id="total-storage-quota-text"
                    class="label"
                    matTooltip="{{ convertToSensibleByteUnit(deviceStorageStatistics.storageUsageBytes) }} von {{
                        convertToSensibleByteUnit(deviceStorageStatistics.deviceLimitBytes)
                    }}">
                    {{
                        (deviceStorageStatistics.storageUsageBytes / deviceStorageStatistics.deviceLimitBytes) * 100
                            | number: '1.0-0'
                    }}
                    % von {{ convertToSensibleByteUnit(deviceStorageStatistics.deviceLimitBytes) }}
                </div>

                <!--********** Graph **********-->
                <div id="total-storage-quota-graph">
                    <div
                        id="total-storage-quota-graph-fill"
                        [style.width]="
                            (deviceStorageStatistics.storageUsageBytes / deviceStorageStatistics.deviceLimitBytes) *
                                100 +
                            '%'
                        "></div>
                </div>
            </ng-container>

            <ng-container *ngIf="deviceStorageStatistics && deviceStorageStatistics.storageUsageBytes == null">
                <div class="label">Speichergrafik im Safari nicht ermittelbar</div>
            </ng-container>

            <!--********** Pending Placeholder **********-->
            <div *ngIf="!deviceStorageStatistics">Ermittle Speicher...</div>
        </div>
    </header>
    <!--============================================-->
    <!-- END Header -->
    <!--============================================-->

    <!--============================================-->
    <!-- Storage Units -->
    <!--============================================-->
    <section id="storage-unit-list" *ngIf="deviceStorageStatistics">
        <div class="storage-unit-item" *ngFor="let storageUnit of storageUnits">
            <!--********** Record Type Icon **********-->
            <div class="record-type-icon-container" [ngSwitch]="storageUnit.serviceName">
                <mat-icon
                    *ngSwitchCase="'carEquipment'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    directions_car
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'carEquipmentTemplate'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    directions_car
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'contactPerson'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    contacts
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'customAutocompleteEntry'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    input
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'customFeeSet'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    table_view
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'customFieldConfig'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    input
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'documentBuildingBlock'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    grid_view
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'documentOrderConfig'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    description
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'importHistory'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    history
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'invoiceTemplate'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    euro_symbol
                </mat-icon>
                <mat-icon *ngSwitchCase="'invoice'" [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    euro_symbol
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'leaseReturnTemplate'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    task_alt
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'manufacturers'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    business
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'originalPhotoFile'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    image
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'renderedPhotoFile'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    image
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'reportProgressConfig'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    done_all
                </mat-icon>
                <mat-icon *ngSwitchCase="'report'" [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    assignment
                </mat-icon>
                <mat-icon *ngSwitchCase="'team'" [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    groups
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'textTemplate'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    post_add
                </mat-icon>
                <mat-icon *ngSwitchCase="'user'" [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    person
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'watermarkImageFile'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    image
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'fieldGroupConfig'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    input
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'signablePdfTemplateConfig'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    signature
                </mat-icon>
                <mat-icon
                    *ngSwitchCase="'labelConfig'"
                    [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    sell
                </mat-icon>
                <mat-icon *ngSwitchCase="'task'" [matTooltip]="getRecordTypeIconTooltip(storageUnit.serviceName)">
                    task_alt
                </mat-icon>
                <mat-icon *ngSwitchDefault [matTooltip]="storageUnit.serviceName">question_mark</mat-icon>
            </div>

            <!--********** Sync Issue Text **********-->
            <div class="storage-unit-text-and-graph-column">
                <!--********** Record Type / Service Name **********-->
                <div class="semibold">{{ getRecordTypeGerman(storageUnit.serviceName) }}</div>

                <!--********** Storage Size in MB **********-->
                <div class="store-unit-size">
                    <ng-container
                        *ngIf="!storageUnitClearanceInProgress.has(storageUnit) && !dumpsInProgress.has(storageUnit)">
                        {{ convertToSensibleByteUnit(storageUnitSizes.get(storageUnit)) }}
                    </ng-container>
                    <img
                        src="/assets/images/icons/loading-indicator.svg"
                        *ngIf="storageUnitClearanceInProgress.has(storageUnit) || dumpsInProgress.has(storageUnit)"
                        alt="" />
                </div>
            </div>

            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="storageUnitSubmenu">more_vert</mat-icon>
            <mat-menu #storageUnitSubmenu>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="triggerServiceSync(storageUnit)"
                    matTooltip="Daten zum Server synchronisieren.">
                    <mat-icon>sync</mat-icon>
                    Sync anstoßen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="logIndexeddbObjectStoreToServer(storageUnit)"
                    matTooltip="Daten aus den IndexedDB Object Stores dieses Services zum Server schreiben. Blobs werden abstrahiert.">
                    <mat-icon>description</mat-icon>
                    Daten loggen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="clearStorageUnit(storageUnit)"
                    matTooltip="Lokale Daten dieser Datenart entfernen. Das kann helfen Platz zu schaffen, falls dein Speicher sich dem Limit nähert.">
                    <mat-icon class="alert">delete</mat-icon>
                    Lokale Daten entfernen
                </a>
            </mat-menu>
        </div>

        <!--********** Loading Placeholder **********-->
        <div id="loading-storage-quota-placeholder" *ngIf="storageUnitSizes.size === 0" @fadeInAndOut>
            <img src="/assets/images/icons/loading-indicator.svg" alt="" />
            Datensätze zählen...
            <div class="label">Das kann bis zu einer Minute dauern.</div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Storage Units -->
    <!--============================================-->

    <!--============================================-->
    <!-- How offline works -->
    <!--============================================-->
    <section id="how-offline-works-hint">
        Einmal lokal geöffnete Daten werden bis zum nächsten Logout lokal gespeichert.
        <br />
        <br />
        <!--********** Tablets do not have their own integrated JavaScript console. This allows us to view JavaScript issues anyway. **********-->
        <div id="virtual-console-trigger" (click)="openVirtualConsole()">Virtuelle Konsole starten</div>
    </section>
    <!--============================================-->
    <!-- END How offline works -->
    <!--============================================-->
</div>

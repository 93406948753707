/**
 * Loads the German Briefanrede such as "Sehr geehrte Frau Müller".
 * @param contactPerson
 * @return {string}
 */
import { ContactPerson } from '../../models/contacts/contact-person';

export function getLetterSalutation(contactPerson: ContactPerson) {
    let letterSalutation = '';

    // A contactPerson is available. Address him/her directly.
    if (contactPerson.lastName) {
        if (!contactPerson.salutation) {
            //if (contactPerson.firstName) {
            //    letterSalutation = `Guten Tag ${contactPerson.firstName} ${contactPerson.lastName}`;
            //}
            //else {
            letterSalutation = `Sehr geehrter Herr ${contactPerson.lastName}`;
            //}
        } else if (contactPerson.salutation.includes('Frau'))
            letterSalutation = `Sehr geehrte ${contactPerson.salutation} ${contactPerson.lastName}`;
        else letterSalutation = `Sehr geehrter ${contactPerson.salutation} ${contactPerson.lastName}`;
    }
    // No contactPerson available (no last name set)
    else letterSalutation = 'Sehr geehrte Damen und Herren';

    return letterSalutation;
}

<div>
    <div class="schedule-options" *ngIf="!editMode">
        <div class="dialog-header">
            <h2>Zeitversetzter Versand</h2>
            <div class="subtitle">Wann soll diese Nachricht versendet werden?</div>
            <div class="header-actions">
                <mat-icon class="toolbar-icon edit-button" (click)="toggleEditMode()">
                    <mat-icon [matTooltip]="'Standard-Optionen bearbeiten'">edit</mat-icon>
                </mat-icon>
                <mat-icon class="toolbar-icon close-button" [mat-dialog-close]="null">
                    <mat-icon>close</mat-icon>
                </mat-icon>
            </div>
        </div>

        <!-- Dynamic options from team preferences -->
        <div
            *ngFor="let option of team.preferences.emailSchedulingOptions"
            class="option"
            (click)="selectOption(option.id)"
            [ngClass]="{ selected: selectedOption === option.id }">
            <span class="option-text">{{ option.name }}</span>
            <span class="option-time">{{ getOptionDisplayTime(option) }}</span>
        </div>

        <div
            class="option custom-date-option"
            (click)="selectOption('custom')"
            [ngClass]="{ selected: selectedOption === 'custom' }">
            <div class="option-icon">
                <mat-icon>date_range</mat-icon>
            </div>
            <span class="option-text">Datum und Uhrzeit wählen</span>
        </div>

        <!-- Custom date and time picker -->
        <div *ngIf="selectedOption === 'custom'" class="custom-datetime-picker">
            <mat-form-field appearance="legacy">
                <input matInput [matDatepicker]="picker" placeholder="Datum wählen" [(ngModel)]="customDate" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <input matInput type="time" placeholder="Uhrzeit wählen" [(ngModel)]="customTime" />
            </mat-form-field>
        </div>

        <div class="error-message" *ngIf="isPastDateTime()">
            Die ausgewählte Zeit liegt in der Vergangenheit. Bitte wähle einen zukünftigen Zeitpunkt.
        </div>
    </div>

    <!-- Edit Mode View -->
    <div class="edit-mode" *ngIf="editMode">
        <div class="dialog-header">
            <h2>Standard-Optionen bearbeiten</h2>
            <div class="subtitle">
                Füge neue Standard-Optionen hinzu, um deine oft genutzten Zeiten direkt auswählen zu können.
            </div>
            <div class="header-actions">
                <mat-icon class="toolbar-icon back-button" (click)="toggleEditMode()">
                    <mat-icon>arrow_back</mat-icon>
                </mat-icon>
                <mat-icon class="toolbar-icon close-button" [mat-dialog-close]="null">
                    <mat-icon>close</mat-icon>
                </mat-icon>
            </div>
        </div>

        <!-- List of existing options with edit/delete buttons -->
        <div class="edit-options-list" cdkDropList (cdkDropListDropped)="dropOption($event)">
            <div
                class="edit-option-item"
                *ngFor="let option of team.preferences.emailSchedulingOptions"
                [ngClass]="{ selected: editingOption?.id === option.id }"
                cdkDrag>
                <div class="drag-handle" cdkDragHandle>
                    <mat-icon>drag_indicator</mat-icon>
                </div>
                <span class="option-name">{{ option.name }}</span>
                <span class="option-time">{{ getOptionDisplayTime(option) }}</span>
                <div class="option-actions">
                    <mat-icon class="toolbar-icon" (click)="editOption(option)">
                        <mat-icon>edit</mat-icon>
                    </mat-icon>
                    <mat-icon class="toolbar-icon" (click)="deleteOption(option)">
                        <mat-icon>delete</mat-icon>
                    </mat-icon>
                </div>
                <!-- Placeholder when dragging -->
                <div class="edit-option-item drag-placeholder" *cdkDragPlaceholder></div>
            </div>
        </div>

        <!-- Add new option button -->
        <div class="add-option-button-container" *ngIf="!showAddOptionForm && !editingOption">
            <button mat-raised-button color="primary" (click)="toggleAddOptionForm()">Option hinzufügen</button>
        </div>

        <!-- Form for adding/editing options -->
        <div class="edit-option-form" *ngIf="showAddOptionForm || editingOption">
            <h4>{{ editingOption ? 'Option bearbeiten' : 'Neue Option hinzufügen' }}</h4>
            <form [formGroup]="newOptionForm">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" placeholder="z.B. Morgen Vormittag" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Typ</mat-label>
                    <mat-select formControlName="type">
                        <mat-option value="relative">Relativ zum heutigen Tag</mat-option>
                        <mat-option value="next-weekday">Nächster Wochentag</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="time-fields" *ngIf="newOptionForm.get('type').value === 'relative'">
                    <mat-form-field>
                        <mat-label>Tage nach heute</mat-label>
                        <input matInput type="number" formControlName="daysOffset" min="0" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Uhrzeit</mat-label>
                        <input matInput type="time" formControlName="timeOfDay" />
                    </mat-form-field>
                </div>

                <div class="time-fields" *ngIf="newOptionForm.get('type').value === 'next-weekday'">
                    <mat-form-field>
                        <mat-label>Wochentag</mat-label>
                        <mat-select formControlName="weekday">
                            <mat-option *ngFor="let day of weekdays" [value]="day.value">{{ day.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Uhrzeit</mat-label>
                        <input matInput type="time" formControlName="timeOfDay" />
                    </mat-form-field>
                </div>

                <div class="form-buttons">
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="addNewOption()"
                        [disabled]="!newOptionForm.valid">
                        {{ editingOption ? 'Speichern' : 'Hinzufügen' }}
                    </button>
                    <button class="secondary" mat-stroked-button (click)="cancelEdit()">Abbrechen</button>
                </div>
            </form>
        </div>
    </div>

    <div class="dialog-buttons-container" *ngIf="!editMode">
        <button [disabled]="!isValidSelection()" (click)="scheduleEmailSend()">Zeitversetzt senden</button>
        <button class="secondary" (click)="close()">Abbrechen</button>
    </div>
</div>

import { NgModule } from '@angular/core';
import { FileUploadModule } from 'ng2-file-upload';
import { ContactExportService } from '../shared/services/contact-export.service';

/**
 * Import aX components
 */
// Directives

/**
 * Import aX services
 */
import { ContactPersonService } from '../shared/services/contact-person.service';
import { SharedModule } from '../shared/shared.module';
import { ContactImportDialogComponent } from './contact-import-dialog/contact-import-dialog.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactsComponent } from './contacts.component';
import { contactsRouting } from './contacts.routing';

@NgModule({
    imports: [SharedModule, contactsRouting, FileUploadModule],
    declarations: [ContactsComponent, ContactListComponent, ContactImportDialogComponent],
    providers: [ContactPersonService, ContactExportService],
})
export class ContactsModule {}

import { OfficeLocation } from '@autoixpert/models/teams/office-location';

/**
 * CheckIfOfficeLocationComplete
 * A officeLocation is complete if it has a title and an address:
 *  - title
 *  - streetAndHouseNumber
 *  - zip
 *  - city
 */
export function checkIfOfficeLocationComplete(officeLocation: OfficeLocation): boolean {
    return !!(officeLocation.title && officeLocation.streetAndHouseNumber && officeLocation.zip && officeLocation.city);
}

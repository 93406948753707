/**
 * A DIN A4 page is 297mm tall.
 * A Pt (Point) is defined as 0.3528 mm.
 * Hence, there are ~842 Pt in a page.
 *
 * This method returns the height of a line relative to a page. Useful to find the height of multiple lines too.
 */
export function getLineHeightRelativeToPageHeight({ lineHeightInPt }: { lineHeightInPt: number }): number {
    // const defaultLineHeight = 1.15;
    const pointsInAPage = 297 / 0.3528;
    return lineHeightInPt / pointsInAPage;
}

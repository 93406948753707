import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Payment } from '@autoixpert/models/invoices/payment';

export function getPaymentsFromInvoice(invoice: Invoice): PaymentWithInvoiceReference[] {
    const payments: PaymentWithInvoiceReference[] = [];

    for (const payment of invoice.payments) {
        payments.push({
            ...payment,
            invoice,
        });
    }

    return payments;
}

export interface PaymentWithInvoiceReference extends Payment {
    invoice: Invoice;
}

import { Component, HostListener } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CarEquipmentPosition } from '@autoixpert/models/reports/car-identification/car-equipment';
import { slideInAndOutVertically } from 'src/app/shared/animations/slide-in-and-out-vertical.animation';

/**
 * This dialog allows the user to import car equipment positions from an unstructured source, e.g. manufacturer's website or a mail from the garage.
 * The equipment positions may be separated by newlines or semicolons.
 */
@Component({
    selector: 'equipment-bulk-import-dialog',
    templateUrl: './equipment-bulk-import-dialog.component.html',
    styleUrls: ['./equipment-bulk-import-dialog.component.scss'],
    animations: [slideInAndOutVertically()],
})
export class EquipmentBulkImportDialogComponent {
    constructor(private dialogRef: MatDialogRef<EquipmentBulkImportDialogComponent, EquipmentBulkImportDialogResult>) {}

    // Keep track of the users input to allow the user to edit it as text.
    equipmentPositionsAsString: string = '';

    // Store a list of equipment positions from the user's input.
    equipmentPositionsDescriptionsAsArray: string[] = [];

    // Allow the user to switch between newlines and semicolons as separators.
    separator: '\n' | ';' = '\n';

    // Allow the user to select the type of equipment positions to import.
    type: CarEquipmentPosition['type'] = 'series';

    /**
     * Get a helpful message as tooltip for the import button.
     */
    get previewText(): string {
        const newPositionsCount = this.equipmentPositionsDescriptionsAsArray.length;
        let typeText;
        if (newPositionsCount === 1) {
            switch (this.type) {
                case 'series':
                    typeText = 'Serienausstattung';
                    break;
                case 'special':
                    typeText = 'Sonderausstattung';
                    break;
                case 'additional':
                    typeText = 'Zusatzausstattung';
                    break;
            }
        } else {
            switch (this.type) {
                case 'series':
                    typeText = 'Serienausstattungen';
                    break;
                case 'special':
                    typeText = 'Sonderausstattungen';
                    break;
                case 'additional':
                    typeText = 'Zusatzausstattungen';
                    break;
            }
        }

        if (newPositionsCount === 1) return `Es wird eine neuen Ausstattung als ${typeText} importiert.`;
        return `Es werden ${newPositionsCount} neue Ausstattungen als ${typeText} importiert.`;
    }

    /**
     * Update the preview after each change of the user's input.
     */
    handleTextChange() {
        const separatedEquipmentPositions = this.equipmentPositionsAsString
            .split(this.separator)
            .map((s) => s.trim())
            .filter((s) => s.length > 0);

        this.equipmentPositionsDescriptionsAsArray = separatedEquipmentPositions;
    }

    /**
     * Update the preview after the user changes the separator.
     */
    handleSeparatorChange(newSeparator: '\n' | ';') {
        this.separator = newSeparator;

        // Update the preview
        this.handleTextChange();
    }

    handleTypeChange(newType: CarEquipmentPosition['type']) {
        this.type = newType;
    }

    /**
     * Return the result to the parent component to avoid code duplicates.
     */
    importPositions() {
        const result = {
            type: this.type,
            descriptions: this.equipmentPositionsDescriptionsAsArray,
        };
        this.dialogRef.close(result);
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog() {
        this.dialogRef.close();
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                event.stopPropagation();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.importPositions();
                    event.stopPropagation();
                }
                break;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

export type EquipmentBulkImportDialogResult = {
    type: CarEquipmentPosition['type'];
    descriptions: string[];
};

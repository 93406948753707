import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { AutogrowTextareaComponent } from './autogrow-textarea.component';

@NgModule({
    declarations: [AutogrowTextareaComponent],
    exports: [AutogrowTextareaComponent],
    imports: [SharedDirectivesModule, NgClass],
    providers: [],
})
export class AutogrowTextareaModule {}

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FabricImage, FabricObject, ModifiedEvent, Point, config } from 'fabric';
import moment from 'moment/moment';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { getAllTiresOfVehicle } from '@autoixpert/lib/tires/get-all-tires-of-vehicle';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Tire } from '@autoixpert/models/reports/car-identification/tire';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { TeamPreferences } from '@autoixpert/models/teams/team-preferences';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { User } from '@autoixpert/models/user/user';
import {
    createLicensePlateRedactionFabricPolygon,
    hasLicensePlateRedactionPolygonAlreadyBeenAdded,
} from 'src/app/shared/services/license-plate-redaction/license-plate-redaction-model-output.utils';
import { LicensePlateRedactionService } from 'src/app/shared/services/license-plate-redaction/license-plate-redaction.service';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { fadeOutAnimation } from '../../../../shared/animations/fade-out.animation';
import { addWatermarkToAllPhotos } from '../../../../shared/libraries/fabric/ts/add-watermark-to-all-photos';
import { translateAnchorToOrigin } from '../../../../shared/libraries/fabric/ts/translate-anchor-to-origin';
import { getMissingAccessRightTooltip } from '../../../../shared/libraries/get-missing-access-right-tooltip';
import { hasAccessRight } from '../../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { CustomAutocompleteEntriesService } from '../../../../shared/services/custom-autocomplete-entries.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { OriginalPhotoService } from '../../../../shared/services/original-photo.service';
import { PhotoBlobUrlCacheService } from '../../../../shared/services/photo-blob-url-cache.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { TeamService } from '../../../../shared/services/team.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';
import { UserService } from '../../../../shared/services/user.service';
import { WatermarkImageFileService } from '../../../../shared/services/watermark-image-file.service';
import { PhotoGroupName } from '../../shared/photos-grid/photo-grid.component';
import { LicensePlateRedactionInfoDialogComponent } from '../license-plate-redaction-info-dialog/license-plate-redaction-info-dialog.component';
import { CanvasManager } from './photo-editor.canvas-manager';
import { CanvasBlurPatternManager } from './photo-editor.canvas-manager.blur-pattern';
import { CROP_AREA_BACKGROUND_OBJECT_ID, CROP_AREA_OBJECT_ID } from './photo-editor.canvas-manager.objects';
import { FilterManager } from './photo-editor.filters';
import { Tool } from './photo-editor.interfaces';
import { ToolbarManager } from './photo-editor.toolbar';

/**
 * The EditorComponent is responsible for everything you see except the canvas. The CanvasManager class is responsible for that.
 */
@Component({
    selector: 'photo-editor',
    templateUrl: 'photo-editor.component.html',
    styleUrls: ['photo-editor.component.scss'],
    animations: [fadeOutAnimation(), fadeInAndSlideAnimation()],
})
export class PhotoEditorComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    constructor(
        private dialog: MatDialog,
        private originalPhotoService: OriginalPhotoService,
        public toastService: ToastService,
        public changeDetectorRef: ChangeDetectorRef,
        public domSanitizer: DomSanitizer,
        public userPreferences: UserPreferencesService,
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private loggedInUserService: LoggedInUserService,
        private apiErrorService: ApiErrorService,
        private teamService: TeamService,
        public watermarkImageFileService: WatermarkImageFileService,
        private photoBlobUrlCacheService: PhotoBlobUrlCacheService,
        private reportDetailsService: ReportDetailsService,
        private licensePlateRedactionService: LicensePlateRedactionService,
        private userService: UserService,
    ) {}

    public team: Team;
    public user: User;

    @Input() report: Report;

    @Input('initialPhoto') photo: Photo;
    @Input() photos: Photo[] = [];
    @Input() photoVersion: PhotoGroupName = 'report';
    @Input() limitToPhotoGroup: boolean;

    @Input() reportSaver$: Observable<any>;

    @Input() disabled: boolean;
    // Message to display after the user clicked certain elements despite the component being disabled.
    @Input() disabledMessage: string;

    @Output() change = new EventEmitter<Photo>();
    @Output() close = new EventEmitter<void>();
    @Output() photoWasDeleted = new EventEmitter<Photo>();

    private photoBlobUrl: string;

    public canvasManager: CanvasManager;
    public blurPatternManager: CanvasBlurPatternManager;
    public toolbarManager: ToolbarManager;
    public filterManager: FilterManager;

    // The photos matching the photoVersion if limitToPhotoGroup is set. Otherwise, all photos.
    public photosInPhotoGroup: Photo[] = [];

    public photoDescriptionAutocompleteEntries: CustomAutocompleteEntry[] = [];
    public filteredPhotoDescriptionAutocompleteEntries: CustomAutocompleteEntry[] = [];

    public canvasContextMenuPositionTop: string;
    public canvasContextMenuPositionLeft: string;
    public showContextMenuInfoNote = false;

    public vinInputShown = false;
    public mileageInputShown = false;
    public firstRegistrationInputShown = false;
    public latestRegistrationInputShown = false;
    public generalInspectionInputShown = false;
    public filtersShown = false;
    public editorInputTab: 'editor' | 'tires' | 'paintThickness' = 'editor';
    public paintThicknessCommentShown = false;

    protected redactLicensePlateLoading = false;

    // Watermark
    public watermarkSettingsShown: boolean;
    private teamPreferencesSaver$: Subject<void>;
    private teamPreferencesSaverSubscription: Subscription;
    public watermarkImageUploader: FileUploader;
    public watermarkImageUploadPending: boolean;
    public watermarkImageFileBlobUrl: SafeResourceUrl;
    @ViewChild('watermarkImageUpload') watermarkImageUploadInput: ElementRef<HTMLInputElement>;

    @ViewChild('imageContainer', { static: true }) imageContainerHTMLElement: ElementRef;
    @ViewChild('canvas', { static: true }) canvasHTMLElement: ElementRef<HTMLCanvasElement>;
    @ViewChild('canvasContextMenuTrigger', { static: true }) canvasContextMenuTrigger: MatMenuTrigger;
    // The photo description container is only available after change detection, so set static: false.
    @ViewChild('photoDescriptionContainer', { static: false }) photoDescriptionContainer: ElementRef<HTMLDivElement>;
    @ViewChild('colorpicker', { static: false }) colorpicker: ElementRef<HTMLInputElement>;

    /**
     * This is filled if the photo could not be downloaded for display and lets the user know how to handle the problem.
     */
    public photoDownloadProblem: { title: string; body: string };

    private subscriptions: Subscription[] = [];
    protected tires: Tire[] = [];

    /**
     * Display a hint if the device does not support WebGL, which might result in broken images when using filters.
     */
    protected isDeviceStrongEnoughForFilterBackend: boolean = false;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/

    ngOnInit() {
        this.subscriptions.push(
            // Update the team if there is a change in team objects such as after the team was loaded from the server
            // when refreshing the page. Before, the team was loaded from localStorage locally.
            this.loggedInUserService.getTeam$().subscribe({
                next: (team) => {
                    this.team = team;
                },
            }),

            this.loggedInUserService.getUser$().subscribe({
                next: (user) => {
                    this.user = user;
                },
            }),
        );

        // Instantiate canvas, toolbar and filter managers.
        this.instantiateComponentManagers();

        this.filterPhotos();

        this.initializePhoto();

        this.checkContextMenuInfoNote();

        this.initializeWatermarkUploader();

        // Set the default color
        this.toolbarManager.color = this.userPreferences.editorShapeColor ?? '#15a9e8';

        // Get autocomplete entries for the photo description
        this.findPhotoDescriptionAutocompleteEntries();
        this.setUpTires();
    }

    ngAfterViewInit() {
        this.isDeviceStrongEnoughForFilterBackend = this.filterManager.isDeviceStrongEnoughForFilterBackend();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['photos']) {
            this.filterPhotos();
        }
    }
    /**
     * Attach UI properties (such as selection) to the tires from the report
     */
    private setUpTires() {
        this.tires = getAllTiresOfVehicle(this.report.car.axles);
    }

    /**
     * Create new instances of several "managers", subclasses that take care of the canvas, toolbar and the filters.
     */
    private instantiateComponentManagers() {
        this.canvasManager = new CanvasManager({
            editor: this,
            canvasHTMLElement: this.canvasHTMLElement,
            imageContainerHTMLElement: this.imageContainerHTMLElement,
        });
        this.blurPatternManager = this.canvasManager.blurPatternManager;
        this.toolbarManager = new ToolbarManager({
            editor: this,
        });
        this.filterManager = new FilterManager({
            editor: this,
        });
    }

    private filterPhotos() {
        this.photosInPhotoGroup = [...(this.photos ?? [])];

        if (this.limitToPhotoGroup) {
            this.photosInPhotoGroup = this.photosInPhotoGroup.filter(
                (photo) => photo.versions[this.photoVersion].included,
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Canvas
    //****************************************************************************/
    public serializeCanvas(): any {
        const canvasManager = this.canvasManager;
        if (!this.canvasManager.canvas) {
            throw new AxError({
                code: 'FABRICJS_CANVAS_NOT_INITIALIZED',
                message: `The canvas could not be serialized since it has not yet been initialized.`,
            });
        }
        /**
         * Add our filters to it since we cannot easily revert convolute back to sharpness. This will be restored
         * in the editor when the image is loaded the next time.
         * Copy the filters instead of creating a reference. The reference caused an issue when changing photo versions. As soon as the new version was
         * selected
         */
        (this.canvasManager.canvas as any).axFilters = JSON.parse(JSON.stringify(this.filterManager.filters));
        // Increase the precision of serialized values so that shapes do not move when they are deserialized later.
        config.NUM_FRACTION_DIGITS = 18;
        const canvasAsSerializedObject = canvasManager.canvas.toObject(['axFilters', 'data']);

        // Remove any helper objects created for crop tool
        canvasAsSerializedObject.objects = canvasAsSerializedObject.objects.filter((object: FabricObject) => {
            return object.id !== CROP_AREA_OBJECT_ID && object.id !== CROP_AREA_BACKGROUND_OBJECT_ID;
        });

        /**
         * Fabric.js patterns allow the user to create a separate canvas that is then used to fill the background of an object.
         * We use this patterns feature to create a blurred background for objects by creating a pattern canvas containing a blurred version of the photo.
         *
         * Remove the "src" attribute of patterns, as it contains all pixel information base64 encoded.
         * This makes the resulting JSON way too large. Instead, store the necessary metadata to re-create the pattern in the data attribute of the object.
         * As the pattern itself is empty now, we need to enliven it later on when deserializing the object.
         */
        canvasAsSerializedObject.objects.forEach((object: FabricObject) => {
            if (object.fill && typeof object.fill === 'object' && object.fill.type === 'pattern') {
                object.fill.source = null;
            }
        });

        //*****************************************************************************
        //  REMOVE PADDING
        //****************************************************************************/
        const padding = {
            left: canvasManager.fabricPhoto.getBoundingRect().left,
            top: canvasManager.fabricPhoto.getBoundingRect().top,
        };

        for (let i = 0; i < canvasAsSerializedObject.objects.length; i++) {
            canvasAsSerializedObject.objects[i].top -= padding.top;
            canvasAsSerializedObject.objects[i].left -= padding.left;
            // Do not show the damage description next time the photo opens if the photo was saved while the description was open.
            if (
                canvasAsSerializedObject.objects[i].data &&
                canvasAsSerializedObject.objects[i].data.axPhotoDescriptionUICard
            ) {
                canvasAsSerializedObject.objects[i].data.axPhotoDescriptionUICard.show = false;
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END REMOVE PADDING
        /////////////////////////////////////////////////////////////////////////////*/

        return canvasAsSerializedObject;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Canvas
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Photo
    //****************************************************************************/

    private async initializePhoto() {
        // When switching away from the report version...
        if (this.photoVersion !== 'report') {
            // ... and the new photo version is empty...
            if (this.isPhotoEmpty(this.photoVersion)) {
                // ... and the report version is different (not empty).
                if (
                    JSON.stringify(this.photo.versions.report.fabricJsInformation) !==
                    JSON.stringify(this.photo.versions[this.photoVersion].fabricJsInformation)
                ) {
                    this.copyFabricBetweenVersions('report', this.photoVersion);
                    // Copying the fabric.js information already initializes the photo, no need to do it twice.
                    return;
                }
            }
        }

        // Clear potential problems that were shown before for another photo.
        this.photoDownloadProblem = undefined;

        // Set the filter inputs to the values set on the new photo
        this.filterManager.loadFilterValuesFromPhoto();

        /**
         * Download the photo file, save it to a local blob and then start fabric.js. Download the watermark file and replace the fabricjsInformation watermark object with the
         * actual file. This way, we don't have to copy the image's dataUrl to every photo. Instead, we just keep a reference with much smaller file size.
         */

        let originalPhotoBlob: Blob;
        try {
            originalPhotoBlob = await this.originalPhotoService.get(`${this.report._id}-${this.photo._id}`);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    BLOB_CANNOT_BE_FETCHED_FROM_SERVER_WHILE_OFFLINE: () => {
                        this.photoDownloadProblem = {
                            title: 'Offline nicht verfügbar',
                            body: 'Dieses Foto kann erst angezeigt werden, wenn du wieder online bist.',
                        };

                        // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                        return {
                            title: '',
                            body: '',
                        };
                    },
                    GETTING_BLOB_FROM_SERVER_FAILED: () => {
                        this.photoDownloadProblem = {
                            title: 'Original-Foto fehlt',
                            body: 'Das Original-Foto konnte nicht gefunden werden. Bitte lösche das Foto und lade es erneut hoch.',
                        };

                        // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                        return {
                            title: '',
                            body: '',
                        };
                    },
                },
                defaultHandler: () => {
                    this.photoDownloadProblem = {
                        title: 'Unerwarteter Fehler',
                        body: 'Bitte aktualisiere die Seite. Besteht das Problem weiterhin, kontaktiere die Hotline.',
                    };

                    // Don't show a toast. Errors are displayed on the X icon of the photo instead.
                    return {
                        title: '',
                        body: '',
                    };
                },
            });
        }

        /**
         * The fabric.js information for the original photo are always null. It can be cached no matter what shapes are rendered into it
         * because shapes will only be (re-)created through the photo editor. So, the photo editor needs the plain original photo without shapes.
         */
        this.photoBlobUrl = this.photoBlobUrlCacheService.get(this.photo._id, this.photoVersion, 'original', null);
        if (!this.photoBlobUrl) {
            this.photoBlobUrl = window.URL.createObjectURL(originalPhotoBlob);
            this.photoBlobUrlCacheService.set(this.photo._id, this.photoVersion, 'original', null, this.photoBlobUrl);
        }

        this.canvasManager.init({ photoBlobUrl: this.photoBlobUrl });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Photo
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save Methods
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    public async saveReport({ waitForServer }: { waitForServer?: true } = {}): Promise<Report> {
        if (this.isReportLocked()) return;

        try {
            await this.reportDetailsService.patch(this.report, { waitForServer });
        } catch (error) {
            console.error('An error occurred while saving the report. ', { error });
        }
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Methods
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    /**
     * Save JSON string to server. The editor does not need to save any binary data --> speed B-)
     */
    // private savePhotoTimeout: NodeJS.Timeout;
    public async savePhoto() {
        if (!this.canvasManager.canvas) {
            console.log(
                `The canvas is not yet initialized. Cannot save the photo since serialization might potentially overwrite existing forms due to an empty forms object.`,
            );
            return;
        }

        // Update the photo's shapes, filters etc. locally.
        this.photo.versions[this.photoVersion].fabricJsInformation = this.serializeCanvas();
        this.change.emit(this.photo);

        /**
        clearTimeout(this.savePhotoTimeout);
        this.savePhotoTimeout = setTimeout(() => {
            this.photo.versions[this.photoVersion].fabricJsInformation = this.serializeCanvas();
            this.change.emit(this.photo);
        }, 500);
        */
    }

    public async deletePhoto(photo: Photo) {
        if (this.isEditingDisabled()) {
            this.displayDisabledMessage();
            return;
        }

        // Before deleting the photo, try to either navigate to the next or to the previous photo.
        (await this.showPhoto('next')) ||
            (await this.showPhoto('previous')) ||
            (() => {
                this.toastService.info('Alle Fotos gelöscht', 'Editor wurde geschlossen');

                // Delay closing the editor. Without delay, it looks like a bug to the user.
                window.setTimeout(() => {
                    this.closeEditor();
                }, 1000);
            })();

        const photoIndex = this.photos.indexOf(photo);
        this.photos.splice(photoIndex, 1);
        this.change.emit(photo);
        this.filterPhotos();

        try {
            await this.originalPhotoService.delete(`${this.report._id}-${photo._id}`);
            console.log('Successfully deleted a photo.', this.photo);

            this.photoWasDeleted.emit(photo);
        } catch (error) {
            console.error('Error while deleting photo', { error });
            this.toastService.error(
                'Foto löschen gescheitert',
                'Das ist ein technisches Problem. Bitte versuche es erneut. Sollte das Problem erneut auftreten, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
            );

            // Re-add the photo
            this.photos.splice(photoIndex, 0, photo);
            this.change.emit(photo);
            this.filterPhotos();
        }
    }

    public saveTeamPreferences() {
        this.teamService.put(this.team);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Versions
    //****************************************************************************/
    public selectPhotoVersion(version: keyof Photo['versions']) {
        this.photoVersion = version;

        // Make sure the crop area and zoom are not applied to the next image
        this.canvasManager.resetCropAndZoomTools();

        this.initializePhoto();
    }

    public copyFabricBetweenVersions(sourceVersion: keyof Photo['versions'], targetVersion: keyof Photo['versions']) {
        // Copy all filters and values from the report to the residual value exchange version.
        this.photo.versions[targetVersion].fabricJsInformation = JSON.parse(
            JSON.stringify(this.photo.versions[sourceVersion].fabricJsInformation),
        );
        // Since this change needs to be persisted, save the report.
        this.change.emit(this.photo);

        // If the current photo version gets new data, refresh it.
        if (targetVersion === this.photoVersion) {
            this.initializePhoto();
        }
    }

    /**
     * Determine whether the photo was edited through filters or shapes.
     */
    private isPhotoEmpty(version: keyof Photo['versions']) {
        if (
            !this.photo.versions[version].fabricJsInformation ||
            JSON.parse(JSON.stringify(this.photo.versions[version].fabricJsInformation)) === '{}'
        ) {
            return true;
        }

        let areFiltersSet = false;
        for (const axFilterName in this.photo.versions[version].fabricJsInformation.axFilters) {
            if (!this.photo.versions[version].fabricJsInformation.axFilters.hasOwnProperty(axFilterName)) continue;
            if (this.photo.versions[version].fabricJsInformation.axFilters[axFilterName].value !== 0) {
                areFiltersSet = true;
                break;
            }
        }

        let areShapesPresent = false;
        for (const fabricObject of this.photo.versions[version].fabricJsInformation.objects) {
            // If this is the photo, don't count this. The photo is always present and does not count as a shape.
            // Exclude the watermark, too. That does not count since that might always be present. Shapes should be copied anyway.
            if (
                fabricObject.data &&
                (fabricObject.data.axType === 'photo' ||
                    ['watermarkImage', 'watermarkText'].includes(fabricObject.data.axType))
            ) {
                continue;
            }

            // If this is an object that is not a photo, it must be a shape.
            areShapesPresent = true;
            break;
        }

        const fabricPhoto: FabricImage = this.photo.versions[version].fabricJsInformation.objects.find(
            (object) => object.data.axType === 'photo',
        );
        const wasCropped = fabricPhoto?.data.axCropAreaHeight || fabricPhoto?.data.axCropAreaWidth;

        // Return true if neither filters nor shapes are set and the image was not cropped.
        return !areFiltersSet && !areShapesPresent && !wasCropped;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Versions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Editor methods
    //****************************************************************************/

    /**
     * Close the editor.
     */
    public closeEditor() {
        // Go back to photo overview directly, no dialog required.
        this.close.emit();
    }

    /**
     * Triggered when the user enters or removes text from the photo descriptions of the numberTokens.
     * @param numberToken
     */
    onChangePhotoDescription(numberToken) {
        this.canvasManager.objectManager.updatePhotoDescriptionIndication(numberToken);
    }

    /**
     * Find out at which index of the photos array the photo is.
     */
    public findPositionInPhotosArray(photo: Photo) {
        return this.photosInPhotoGroup.indexOf(photo);
    }

    /**
     * Activate a tool or deactivate it if it's already in use.
     */
    public toggleTool(tool: Tool) {
        // If the report is locked, disallow using the tools.
        if (this.isEditingDisabled()) {
            this.displayDisabledMessage();
            return;
        }

        this.toolbarManager.activeTool === tool ? tool.deactivate() : tool.onclick();
    }

    public enableKeepToolActive() {
        this.userPreferences.photoEditorKeepToolActive = true;
    }

    public disableKeepToolActive() {
        this.userPreferences.photoEditorKeepToolActive = false;
    }

    public isShowingPhotoAllowed(direction: 'next' | 'previous') {
        // Do not allow navigation if photo is the first item in the array
        const positionInPhotosArray = this.findPositionInPhotosArray(this.photo);

        // If you're already at the last photo, loading the next one won't be possible
        if (direction === 'next' && positionInPhotosArray === this.photosInPhotoGroup.length - 1) {
            return false;
        }

        // If you're at the first photo, loading the previous one won't be possible either.
        if (direction === 'previous' && positionInPhotosArray === 0) {
            return false;
        }

        // Do not allow navigation if the previous photo has not received an ID yet, i. e. it hasn't finished uploading.
        // The previous photo is undefined if there was only one photo and the user deleted it through the delete button in the photo editor.
        const previousPhoto = this.photosInPhotoGroup[positionInPhotosArray + (direction === 'next' ? 1 : -1)];
        if (!previousPhoto || !previousPhoto._id) {
            return false;
        }

        // If none of the forbidden conditions are met, allow navigation.
        return true;
    }

    /**
     * The photo editor may only be used if either
     * - the report is *un*locked or
     * - the user is viewing the photo versions repair confirmation or expert statement, even while the report is locked.
     */
    public isEditingDisabled(): boolean {
        return (
            this.disabled && !(this.photoVersion === 'repairConfirmation' || this.photoVersion === 'expertStatement')
        );
    }

    private displayDisabledMessage() {
        this.toastService.info(
            'Bearbeitung nicht möglich',
            this.disabledMessage ?? 'Bitte deaktiviere erst den Schreibschutz.',
        );
    }

    protected async showLicensePlateRedactionTutorial() {
        // Show tutorial
        let confirmed = false;
        const dialogRef = this.dialog.open<LicensePlateRedactionInfoDialogComponent, any, any>(
            LicensePlateRedactionInfoDialogComponent,
            {},
        );

        dialogRef.componentInstance.closeClick.subscribe(() => {
            dialogRef.close();
        });

        dialogRef.componentInstance.confirmClick.subscribe(() => {
            this.user.userInterfaceStates.licensePlateRedactionTutorialDismissed = true;
            this.saveUser();
            confirmed = true;
            dialogRef.close();
        });

        await dialogRef.afterClosed().toPromise();

        return { confirmed };
    }

    public async redactLicensePlate() {
        if (this.redactLicensePlateLoading) {
            this.toastService.info(
                'In Bearbeitung',
                'Das Kennzeichen wird bereits unkenntlich gemacht. Bitte warte einen Augenblick.',
            );
            return;
        }

        if (!this.user?.userInterfaceStates.licensePlateRedactionTutorialDismissed) {
            const { confirmed } = await this.showLicensePlateRedactionTutorial();
            if (!confirmed) return;
        }

        try {
            this.redactLicensePlateLoading = true;
            const imageBlob = await this.originalPhotoService.get(`${this.report._id}-${this.photo._id}`);
            const { redactions } = await this.licensePlateRedactionService.redactLicensePlateLocally({
                image: { width: this.photo.width, height: this.photo.height, blob: imageBlob },
                /**
                 * If no license plate redaction passes the boxScoreTreshold, include at least one redaction that passes this one.
                 * Reason is that the user purposefully clicked the button for this image to redact license plates and expects at least one redaction.
                 */
                findAtLeastOneLicensePlateAboveScoreThreshold: 0.5,
            });

            if (redactions.length === 0) {
                this.toastService.info(
                    'Keine Kennzeichen gefunden',
                    'Falls eines enthalten ist, überdecke es von Hand, z. B. mit dem Polygon oder dem Rechteck.',
                );
            }

            const canvasWidth = this.canvasHTMLElement.nativeElement.clientWidth;
            const canvasHeight = this.canvasHTMLElement.nativeElement.clientHeight;
            const scaleX = canvasWidth / this.photo.width;
            const scaleY = canvasHeight / this.photo.height;

            // Create a new polygon object
            for (const redaction of redactions) {
                const polygon = createLicensePlateRedactionFabricPolygon({
                    redaction,
                    scaleX,
                    scaleY,
                    color: this.team.preferences.licensePlateRedactionColor,
                });

                if (
                    hasLicensePlateRedactionPolygonAlreadyBeenAdded({
                        objects: this.canvasManager.canvas.getObjects(),
                        polygon,
                    })
                ) {
                    continue;
                }

                if (this.blurPatternManager.isBlurPatternObject(polygon)) {
                    await this.blurPatternManager.enlivenObjectBlurPattern(polygon);
                }

                this.canvasManager.canvas.add(polygon);
            }

            this.savePhoto();
            // Allow undoing the redaction of a license plate
            this.canvasManager.saveHistory();
            this.redactLicensePlateLoading = false;
        } catch (error) {
            this.redactLicensePlateLoading = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                defaultHandler: {
                    title: 'Kennzeichen-unkenntlich-machen fehlgeschlagen',
                    body: 'Das ist ein technisches Problem. Bitte versuche es erneut. Sollte das Problem erneut auftreten, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Editor methods
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Description
    //****************************************************************************/

    public findPhotoDescriptionAutocompleteEntries() {
        this.customAutocompleteEntriesService.find({ type: 'photoDescription' }).subscribe({
            next: (entries) => {
                this.photoDescriptionAutocompleteEntries = entries;
                this.sortPhotoDescriptionAutocomplete();
                this.filterPhotoDescriptionAutocomplete(this.photo.description);
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Fotobeschreibungen nicht abgerufen',
                        body: "Die Vorlagen für die Fotobeschreibung konnten nicht vom Server geholt werden. Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                    },
                });
            },
        });
    }

    public filterPhotoDescriptionAutocomplete(searchTerm = '') {
        this.filteredPhotoDescriptionAutocompleteEntries = [...this.photoDescriptionAutocompleteEntries];

        if (!searchTerm) return;

        const searchTerms: string[] = searchTerm.toLowerCase().split(' ');

        this.filteredPhotoDescriptionAutocompleteEntries = this.filteredPhotoDescriptionAutocompleteEntries.filter(
            (entry) => {
                const entryLabelLowerCase = entry.value.toLowerCase();
                return searchTerms.every((searchTerm) => entryLabelLowerCase.includes(searchTerm));
            },
        );
    }

    /**
     * Sort the original photo description array.
     *
     * This way, the filtered values will be automatically sorted too. Sorting on every filter cycle
     * would put unnecessary load on the client.
     */
    public sortPhotoDescriptionAutocomplete() {
        // Sort
        this.photoDescriptionAutocompleteEntries.sort((entryA, entryB) => entryA.value.localeCompare(entryB.value));
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Description
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigating to the next and previous photo, respectively.
    //****************************************************************************/
    /**
     * Clear the canvas and navigate to the photo that comes next in the photos array. If it is already the last photo, it returns a rejected Promise.
     */
    public async showPhoto(direction: 'next' | 'previous'): Promise<boolean> {
        // Determine if navigation is even allowed
        const isNavigationAllowed =
            direction === 'next' ? this.isShowingPhotoAllowed('next') : this.isShowingPhotoAllowed('previous');

        // If the photo is  the last element, prohibit navigation.
        if (!isNavigationAllowed) {
            return false;
        }

        // Only start the navigation if the ID is set. As long as the photo is uploading, the ID is not set yet.
        const indexOfCurrentPhoto = this.photosInPhotoGroup.indexOf(this.photo);
        const newPhoto: Photo = this.photosInPhotoGroup[indexOfCurrentPhoto + (direction === 'next' ? 1 : -1)];

        if (!newPhoto._id) {
            this.toastService.info('Nächstes Foto lädt hoch', 'Bitte warten');
            return;
        }

        // Make sure the crop area and zoom are not applied to the next image
        this.canvasManager.resetCropAndZoomTools();

        // When navigating away from an image, delete the currently shown photo to avoid memory leaks and to clear its event listeners.
        this.canvasManager.clear();
        this.canvasManager.resetHistory();

        // Navigate to the next/previous photo
        this.photo = newPhoto;
        await this.initializePhoto();

        return true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigating to the next and previous photo, respectively.
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filters
    //****************************************************************************/
    public showFilters() {
        this.filtersShown = true;
    }

    public hideFilters() {
        this.filtersShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Watermark
    //****************************************************************************/
    public showWatermarkSettings({ setupNecessary }: { setupNecessary?: boolean } = {}) {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            this.toastService.info(
                'Keine Berechtigung',
                setupNecessary
                    ? `Das Wasserzeichen muss erst von einem Nutzer mit Zugriffsrecht ${translateAccessRightToGerman('editTextsAndDocumentBuildingBlocks')} konfiguriert werden.`
                    : this.getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks'),
            );

            return;
        }

        this.setWatermarkDefaults();
        // Ensure that event handlers are attached before adding the watermark so that changes in team preferences through watermark initialization are
        // being saved to the server (Creating a watermark causes the event "object:modified" to be fired).
        this.attachWatermarkEventHandlers();

        this.getWatermarkImageBlobUrlAndDrawWatermark();
        this.watermarkSettingsShown = true;
    }

    public hideWatermarkSettings() {
        this.detachWatermarkEventHandlers();
        this.watermarkSettingsShown = false;
    }

    public setWatermarkDefaults() {
        const preferences = this.team.preferences.watermark;
        let syncRequired = false;

        if (!preferences.anchor) {
            preferences.anchor = 'bottomLeft';
            this.setDefaultOffsets();
            syncRequired = true;
        }
        if (preferences.opacity == null) {
            preferences.opacity = 1;
            syncRequired = true;
        }
        if (!preferences.textColor) {
            preferences.textColor = '#ffae00';
            syncRequired = true;
        }

        /**
         * Save Preferences if needed
         */
        if (syncRequired) {
            this.saveTeamPreferences();
        }
    }

    public setWatermarkType(watermarkType: TeamPreferences['watermark']['type']) {
        this.team.preferences.watermark.type = watermarkType;

        if (watermarkType === 'text') {
            const teamName = this.team.billing.address.organization
                ? this.team.billing.address.organization
                : `${this.team.billing.address.firstName} ${this.team.billing.address.lastName}`;
            this.team.preferences.watermark.text = `© ${teamName}`;
        } else if (watermarkType == null) {
            this.team.preferences.watermark.aspectRatio = null;
            this.team.preferences.watermark.widthRelativeToPhoto = null;
            this.team.preferences.watermark.textScaleXRelativeToPhoto = null;
            this.team.preferences.watermark.textScaleYRelativeToPhoto = null;
            this.team.preferences.watermark.offsetX = null;
            this.team.preferences.watermark.offsetY = null;
            this.team.preferences.watermark.imageHash = null;
            this.team.preferences.watermark.text = null;
            this.team.preferences.watermark.textColor = null;

            /**
             * Restore defaults after removing all user settings
             */
            this.setDefaultOffsets();
        }

        this.saveTeamPreferences();
    }

    public setWatermarkTextColor(color: string) {
        this.team.preferences.watermark.textColor = color;

        /**
         * Update watermark.
         */
        this.drawWatermark();

        this.saveTeamPreferences();
    }

    /**
     * When changing the anchor, set a default offset based on the anchor position.
     */
    public setDefaultOffsets() {
        const watermarkPreferences = this.team.preferences.watermark;
        const defaultOffsetX = 0.05;
        const defaultOffsetY = 0.05;

        /**
         * A positive offset means shift to the right / down. A negative offset means a shift to the left / top.
         */
        switch (watermarkPreferences.anchor) {
            case 'topLeft':
                watermarkPreferences.offsetX = defaultOffsetX;
                watermarkPreferences.offsetY = defaultOffsetY;
                break;
            case 'topRight':
                watermarkPreferences.offsetX = defaultOffsetX * -1;
                watermarkPreferences.offsetY = defaultOffsetY;
                break;
            case 'center':
                watermarkPreferences.offsetX = 0;
                watermarkPreferences.offsetY = 0;
                break;
            case 'bottomLeft':
                watermarkPreferences.offsetX = defaultOffsetX;
                watermarkPreferences.offsetY = defaultOffsetY * -1;
                break;
            case 'bottomRight':
                watermarkPreferences.offsetX = defaultOffsetX * -1;
                watermarkPreferences.offsetY = defaultOffsetY * -1;
                break;
        }
    }

    public formatWatermarkOpacitySliderThumbValue(value: number) {
        return Math.round(value * 100) + '%';
    }

    public async drawWatermark() {
        try {
            await this.canvasManager.objectManager.drawWatermark();
        } catch (error) {
            this.toastService.error(
                'Wasserzeichen nicht gerendert',
                'Das ist ein technisches Problem. Bitte melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
            );
        }
    }

    public delayTriggerSavingTeamPreferences() {
        // Create Subject
        if (!this.teamPreferencesSaver$) {
            this.teamPreferencesSaver$ = new Subject();
        }

        // Subscribe to Subject
        if (!this.teamPreferencesSaverSubscription) {
            this.teamPreferencesSaverSubscription = this.teamPreferencesSaver$.pipe(debounceTime(1000)).subscribe({
                next: () => {
                    this.saveTeamPreferences();
                },
            });
        }

        // Trigger Subject
        this.teamPreferencesSaver$.next();
    }

    /**
     * The user may change the position and size ( = scaling) of the watermark on the canvas. Trigger saving that to the team's preferences.
     * @private
     */
    private attachWatermarkEventHandlers() {
        this.canvasManager.canvas?.on('object:modified', this.handleOnCanvasWatermarkModification);
    }

    private detachWatermarkEventHandlers() {
        this.canvasManager.canvas?.off('object:modified', this.handleOnCanvasWatermarkModification);
    }

    public handleOnCanvasWatermarkModification = (event: ModifiedEvent) => {
        if (event.target?.data?.axType === 'watermarkImage' || event.target?.data?.axType === 'watermarkText') {
            const watermarkPreferences = this.team.preferences.watermark;

            /**
             * Determine relative position.
             * - Get relevant corner of the canvas (or center) from which to measure distance
             * - Get relevant corner of the object (or center)
             * - Get distance between the two for both axis
             * - Set measured distance in relation to total canvas width to get percentage (to account for differing canvas sizes)
             */
            const { originX, originY } = translateAnchorToOrigin(watermarkPreferences.anchor);
            let anchorPoint: Point;
            const objectCorner = event.target.getPointByOrigin(originX, originY);
            const canvasWidth = this.canvasManager.canvas.width;
            const canvasHeight = this.canvasManager.canvas.height;

            switch (watermarkPreferences.anchor) {
                case 'topLeft':
                    anchorPoint = new Point(0, 0);
                    break;
                case 'topRight':
                    anchorPoint = new Point(canvasWidth, 0);
                    break;
                case 'center':
                    anchorPoint = new Point(canvasWidth / 2, canvasHeight / 2);
                    break;
                case 'bottomLeft':
                    anchorPoint = new Point(0, canvasHeight);
                    break;
                case 'bottomRight':
                    anchorPoint = new Point(canvasWidth, canvasHeight);
                    break;
            }

            const offsetXAbsolute = objectCorner.x - anchorPoint.x;
            const offsetYAbsolute = objectCorner.y - anchorPoint.y;

            watermarkPreferences.offsetX = offsetXAbsolute / canvasWidth;
            watermarkPreferences.offsetY = offsetYAbsolute / canvasHeight;

            const renderedWidth = event.target.scaleX * event.target.width;
            const renderedHeight = event.target.scaleY * event.target.height;
            if (watermarkPreferences.type === 'image') {
                watermarkPreferences.widthRelativeToPhoto = renderedWidth / canvasWidth;
                watermarkPreferences.aspectRatio = renderedWidth / renderedHeight;
            } else if (watermarkPreferences.type === 'text') {
                watermarkPreferences.textScaleXRelativeToPhoto = event.target.scaleX / canvasWidth;
                watermarkPreferences.textScaleYRelativeToPhoto = event.target.scaleY / canvasHeight;
            }

            this.saveTeamPreferences();
        }
    };

    public async addWatermarkToAllPhotos() {
        if (!this.team.preferences.watermark.type) {
            this.toastService.error(
                'Kein Wasserzeichen gesetzt',
                'Bitte vervollständige die Einstellungen des Wasserzeichens über den Foto-Editor.',
            );
            return;
        }

        let watermarkImageBlob: Blob;
        if (this.team.preferences.watermark.type === 'image') {
            try {
                watermarkImageBlob = await this.watermarkImageFileService.get(
                    this.team._id,
                    this.team.preferences.watermark.imageHash,
                );
            } catch (error) {
                if (error.code === 'WATERMARK_IMAGE_NOT_FOUND_LOCALLY_AND_CLIENT_OFFLINE') {
                    this.toastService.warn(
                        'Wasserzeichen offline nicht gefunden',
                        'Stelle eine Internetverbindung her, um das Wasserzeichen abzurufen.<br><br>Fotos werden bis auf Weiteres ohne Wasserzeichen hochgeladen.',
                    );
                } else {
                    this.toastService.warn(
                        'Wasserzeichen nicht gefunden',
                        'Um das Wasserzeichen wieder automatisch einfügen zu lassen, lade bitte das Wasserzeichen-Bild erneut über den Foto-Editor hoch.<br><br>Fotos werden bis auf Weiteres ohne Wasserzeichen hochgeladen.',
                    );
                }

                // Abort since adding a watermark without the required image is impossible.
                return;
            }
        }

        await addWatermarkToAllPhotos({
            photos: this.photos,
            watermarkPreferences: this.team.preferences.watermark,
            watermarkImageUrl: watermarkImageBlob ? window.URL.createObjectURL(watermarkImageBlob) : undefined,
        });

        // Reload the current photo since its watermark probably changed, too.
        this.initializePhoto();

        this.change.emit(this.photo);
        this.toastService.success('Wasserzeichen eingefügt', 'Alle Fotos haben nun ein Wasserzeichen.');
    }

    //*****************************************************************************
    //  Watermark File Upload
    //****************************************************************************/
    private initializeWatermarkUploader() {
        this.watermarkImageUploader = new FileUploader({
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
            itemAlias: 'watermarkImage',
            url: `/api/v0/teams/${this.team._id}/watermarkImage`,
        });

        this.watermarkImageUploader.onAfterAddingFile = async (item: FileItem) => {
            // If the mime type is not a JPG or PNG, remove the file from the queue
            if (!['image/jpeg', 'image/png', 'image/svg+xml'].includes(item._file.type)) {
                console.error('The given file is not a JPG, PNG or SVG file.', item);
                this.toastService.error('Bitte lade eine JPG-, PNG- oder SVG-Datei hoch');
                item.remove();
                return;
            }

            // Warn about file size
            if (item._file.size > 300 * 1024) {
                this.toastService.error(
                    'Datei zu groß',
                    'Bitte lade nur Dateien unter 300 KB hoch. Versuche dazu, die Grafik zu verkleinern oder als JPG abzuspeichern, das kleinere Dateigrößen als eine PNG bietet.',
                );
                item.remove();
                return;
            }

            this.watermarkImageUploadPending = true;

            const watermarkImageHash: string = simpleHash(await item._file.text());
            await this.watermarkImageFileService.create({
                _id: this.team._id,
                blob: item._file,
                blobContentHash: watermarkImageHash,
            });

            /**
             * Clear any old images
             */
            this.watermarkImageFileBlobUrl = null;

            this.setWatermarkType('image');
            this.team.preferences.watermark.imageHash = watermarkImageHash;

            this.getWatermarkImageBlobUrlAndDrawWatermark();
            this.toastService.success('Hochladen des Wasserzeichens erfolgreich');

            this.saveTeamPreferences();

            this.watermarkImageUploadPending = false;
            this.watermarkImageUploader.clearQueue();
        };
    }

    public async getWatermarkImageBlobUrlAndDrawWatermark() {
        if (!this.team.preferences.watermark.imageHash) return;

        const watermarkImageBlob: Blob = await this.watermarkImageFileService.get(
            this.team._id,
            this.team.preferences.watermark.imageHash,
        );
        this.watermarkImageFileBlobUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(watermarkImageBlob),
        );
        /**
         * Draw watermark as soon as it's there.
         */
        this.drawWatermark();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Watermark File Upload
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Watermark
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Context Menu
    //****************************************************************************/
    public openCanvasContextMenu(event: MouseEvent) {
        /**
         * Don't re-open the context menu if the user right clicks into an input field that he has previously opened through
         * a right click > context menu. Example: VIN input.
         */
        if ((event.target as HTMLElement).nodeName !== 'CANVAS') {
            return;
        }

        event.preventDefault();
        this.canvasContextMenuPositionTop = event.offsetY + 'px';
        this.canvasContextMenuPositionLeft = event.offsetX + 'px';
        this.canvasContextMenuTrigger.openMenu();

        // Mark ContextMenuInfoNote as closed, but don't close (layout shift)
        this.markContextMenuInfoNoteAsClosed();
    }

    public showVinInput() {
        this.vinInputShown = true;

        // Close the input on clicking on the canvas.
        this.imageContainerHTMLElement.nativeElement.addEventListener('click', this.hideVinInputOnCanvasClick);
        this.imageContainerHTMLElement.nativeElement.addEventListener('contextmenu', this.hideVinInputOnCanvasClick);
    }

    public hideVinInput(event: MouseEvent) {
        /**
         * Don't re-open the context menu if the user right clicks into an input field that he has previously opened through
         * a right click > context menu. Example: VIN input.
         */
        if ((event.target as HTMLElement).nodeName !== 'CANVAS') {
            return;
        }
        this.vinInputShown = false;
        this.imageContainerHTMLElement.nativeElement.removeEventListener('click', this.hideVinInputOnCanvasClick);
        this.imageContainerHTMLElement.nativeElement.removeEventListener('contextmenu', this.hideVinInputOnCanvasClick);
    }

    /**
     * This function needs to be stored in a variable to unbind the listener properly.
     */
    public hideVinInputOnCanvasClick = (event: MouseEvent) => {
        this.hideVinInput(event);
    };

    public showMileageInput() {
        this.mileageInputShown = true;

        // Close the input on clicking on the canvas.
        this.imageContainerHTMLElement.nativeElement.addEventListener('click', this.hideMileageInputOnCanvasClick);
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'contextmenu',
            this.hideMileageInputOnCanvasClick,
        );
    }

    public hideMileageInput(event: MouseEvent) {
        /**
         * Don't re-open the context menu if the user right clicks into an input field that he has previously opened through
         * a right click > context menu. Example: VIN input.
         */
        if ((event.target as HTMLElement).nodeName !== 'CANVAS') {
            return;
        }
        this.mileageInputShown = false;
        this.imageContainerHTMLElement.nativeElement.removeEventListener('click', this.hideMileageInputOnCanvasClick);
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'contextmenu',
            this.hideMileageInputOnCanvasClick,
        );
    }

    /**
     * This function needs to be stored in a variable to unbind the listener properly.
     */
    public hideMileageInputOnCanvasClick = (event: MouseEvent) => {
        this.hideMileageInput(event);
    };

    public showFirstRegistrationInput() {
        this.firstRegistrationInputShown = true;

        // Close the input on clicking on the canvas.
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'click',
            this.hideFirstRegistrationInputOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'contextmenu',
            this.hideFirstRegistrationInputOnCanvasClick,
        );
    }

    public hideFirstRegistrationInput(event: MouseEvent) {
        /**
         * Don't re-open the context menu if the user right clicks into an input field that he has previously opened through
         * a right click > context menu. Example: VIN input.
         */
        if ((event.target as HTMLElement).nodeName !== 'CANVAS') {
            return;
        }
        this.firstRegistrationInputShown = false;
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'click',
            this.hideFirstRegistrationInputOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'contextmenu',
            this.hideFirstRegistrationInputOnCanvasClick,
        );
    }

    /**
     * This function needs to be stored in a variable to unbind the listener properly.
     */
    public hideFirstRegistrationInputOnCanvasClick = (event: MouseEvent) => {
        this.hideFirstRegistrationInput(event);
    };

    public showLatestRegistrationInput() {
        this.latestRegistrationInputShown = true;

        // Close the input on clicking on the canvas.
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'click',
            this.hideLatestRegistrationInputOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'contextmenu',
            this.hideLatestRegistrationInputOnCanvasClick,
        );
    }

    public hideLatestRegistrationInput(event: MouseEvent) {
        /**
         * Don't re-open the context menu if the user right clicks into an input field that he has previously opened through
         * a right click > context menu. Example: VIN input.
         */
        if ((event.target as HTMLElement).nodeName !== 'CANVAS') {
            return;
        }
        this.latestRegistrationInputShown = false;
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'click',
            this.hideLatestRegistrationInputOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'contextmenu',
            this.hideLatestRegistrationInputOnCanvasClick,
        );
    }

    /**
     * This function needs to be stored in a variable to unbind the listener properly.
     */
    public hideLatestRegistrationInputOnCanvasClick = (event: MouseEvent) => {
        this.hideLatestRegistrationInput(event);
    };

    public setLatestRegistration(latestRegistrationDate: IsoDate): void {
        if (!this.report.car.latestRegistration && this.userPreferences.automaticallyInsertLatestRegistration) {
            this.report.car.latestRegistration = latestRegistrationDate;
        }
    }

    public firstRegistrationIsBeforeProductionYear(): boolean {
        if (!this.report.car.firstRegistration || !this.report.car.productionYear) return false;

        return moment(this.report.car.firstRegistration)
            .startOf('year')
            .isBefore(moment(this.report.car.productionYear).startOf('year'));
    }

    public latestRegistrationIsBeforeFirstRegistration(): boolean {
        if (!this.report.car.latestRegistration || !this.report.car.firstRegistration) return false;

        return moment(this.report.car.latestRegistration)
            .startOf('day')
            .isBefore(moment(this.report.car.firstRegistration).startOf('day'));
    }

    public showGeneralInspectionInput() {
        this.generalInspectionInputShown = true;

        // Close the input on clicking on the canvas.
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'click',
            this.hideGeneralInspectionInputOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'contextmenu',
            this.hideGeneralInspectionInputOnCanvasClick,
        );
    }

    public hideGeneralInspectionInput(event: MouseEvent) {
        /**
         * Don't re-open the context menu if the user right clicks into an input field that he has previously opened through
         * a right click > context menu. Example: VIN input.
         */
        if ((event.target as HTMLElement).nodeName !== 'CANVAS') {
            return;
        }
        this.generalInspectionInputShown = false;
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'click',
            this.hideGeneralInspectionInputOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'contextmenu',
            this.hideGeneralInspectionInputOnCanvasClick,
        );
    }

    /**
     * This function needs to be stored in a variable to unbind the listener properly.
     */
    public hideGeneralInspectionInputOnCanvasClick = (event: MouseEvent) => {
        this.hideGeneralInspectionInput(event);
    };

    public showPaintThicknessInput() {
        this.editorInputTab = 'paintThickness';
        this.paintThicknessCommentShown = !!this.report.car.paintThicknessMeasurementComment;

        // Close the input on clicking on the canvas.
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'click',
            this.handleHideSpecialInputsOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'contextmenu',
            this.handleHideSpecialInputsOnCanvasClick,
        );
    }

    public showTiresInput() {
        this.editorInputTab = 'tires';

        // Close the input on clicking on the canvas.
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'click',
            this.handleHideSpecialInputsOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.addEventListener(
            'contextmenu',
            this.handleHideSpecialInputsOnCanvasClick,
        );
    }

    public hideTiresOrPaintThicknessInput(event: MouseEvent, force = false) {
        /**
         * Don't re-open the context menu if the user right clicks into an input field that he has previously opened through
         * a right click > context menu. Example: VIN input.
         */
        if ((event.target as HTMLElement).nodeName !== 'CANVAS' && !force) {
            return;
        }
        this.editorInputTab = 'editor';
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'click',
            this.handleHideSpecialInputsOnCanvasClick,
        );
        this.imageContainerHTMLElement.nativeElement.removeEventListener(
            'contextmenu',
            this.handleHideSpecialInputsOnCanvasClick,
        );
    }

    /**
     * This function needs to be stored in a variable to unbind the listener properly.
     */
    public handleHideSpecialInputsOnCanvasClick = (event: MouseEvent) => {
        this.hideTiresOrPaintThicknessInput(event);
    };

    public checkContextMenuInfoNote() {
        this.showContextMenuInfoNote = this.shouldShowContextMenuInfoNote();
    }

    private shouldShowContextMenuInfoNote() {
        // User closed already
        if (this.user?.userInterfaceStates.photoEditorContextMenuNoticeClosed) {
            return false;
        }

        // User has less than ten locked reports
        if (this.user.gamification.congratsDialogDiplayedAtNumberOfReports < 10) {
            return false;
        }

        // No notice if no photo description is set
        if (!this.photo.description) {
            return;
        }

        // Return true, if description contains a possible keyword
        const possibleKeywords = [
            'kilometerstand',
            'laufleistung',
            'tachostand',
            'vin',
            'fin',
            'identifikation',
            'fahrgestellnummer',
            'fahrzeugschein',
            'zulassung',
            'typ',
        ];
        const searchString = this.photo.description.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
        return possibleKeywords.some((keyword) => searchString.includes(keyword));
    }

    /**
     * hide ContextMenuInfoNote and mark as closed in user preferences
     */
    public closeContextMenuInfoNote() {
        this.showContextMenuInfoNote = false;
        this.markContextMenuInfoNoteAsClosed();
    }

    /**
     * Set ContextMenuInfoNote as closed in user preferences, but don't close info note since that would result in a layout shift
     */
    public markContextMenuInfoNoteAsClosed() {
        if (!this.user?.userInterfaceStates.photoEditorContextMenuNoticeClosed) {
            this.user.userInterfaceStates.photoEditorContextMenuNoticeClosed = true;
            this.saveUser();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Context Menu
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Include Photo Version in Photo Group
    //****************************************************************************/
    public setIncludedInPhotoVersion(active: boolean) {
        switch (this.photoVersion) {
            case 'report':
            case 'residualValueExchange':
                this.photo.versions.report.included = active;
                this.photo.versions.residualValueExchange.included = active;
                break;
            case 'repairConfirmation':
                this.photo.versions.repairConfirmation.included = active;
                break;
            case 'expertStatement':
                this.photo.versions.expertStatement.included = active;
                break;
        }
        this.savePhoto();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Include Photo in Photo Group
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Emergency Tire Equipment
    //****************************************************************************/
    public hideSpareTireEquipmentSection() {
        this.userPreferences.spareTireEquipmentShown = false;
        if (this.report.car.spareTireEquipment.type || !this.areSpareTireDetailsEmpty()) {
            this.toastService.info(
                'Ausblenden ab nächstem Gutachten',
                'Wenn Daten für Notbereifung vorhanden sind, werden sie trotzdem angezeigt.',
            );
        }
    }

    /**
     * Are all details except type empty?
     *
     * Type is excluded because we need this method mainly to determine if the data entered in the dialog is empty.
     */
    public areSpareTireDetailsEmpty(): boolean {
        // Shorthand
        const spareTire = this.report.car.spareTireEquipment;

        // The season does not matter because it won't be shown in the preview.
        return !spareTire.dimension && !spareTire.manufacturer && !spareTire.treadInMm;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Emergency Tire Equipment
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Register Host Listeners
    //****************************************************************************/
    // Listen to the window event since listening should be possible even if the focus is removed from the editor.
    @HostListener('window:keydown', ['$event'])
    public keyDownListener(event) {
        // If ESC is hit, close the editor
        if (event.key === 'Escape') {
            // Only deactivate the tool when pressing ESC
            if (this.toolbarManager.activeTool) {
                this.toolbarManager.activeTool.deactivate();
                return;
            } else if (this.canvasManager.activeNumberToken) {
                this.canvasManager.activeNumberToken.hidePhotoDescriptionHandlers();
                return;
            } else if (this.watermarkSettingsShown) {
                this.hideWatermarkSettings();
                return;
            } else if (this.canvasManager.isZoomActive) {
                this.canvasManager.toggleZoomMode();
                return;
            }
            this.closeEditor();
            return;
        }

        /**
         * All following keyboard shortcuts cannot be used if the user currently types in a textarea, e.g. when editing a numberAndText or the image description.
         * Exclude checkboxes so the user may continue to navigate between photos with arrow keys after activating or deactivating a photo.
         */
        if (
            (document.activeElement.tagName === 'INPUT' &&
                (document.activeElement as HTMLInputElement).type !== 'checkbox') ||
            document.activeElement.tagName === 'TEXTAREA'
        ) {
            return;
        }

        // handle alt key separate (since option+key will return special character on macos)
        if (!event.altKey) {
            switch (event.key) {
                case ' ':
                    this.closeEditor();
                    break;
                // If del/entf or backspace is hit, remove the currently selected element on the canvas
                case 'Backspace':
                case 'Delete':
                    this.canvasManager.objectManager.deleteFocusedObjects();
                    break;
                // A capital K (Shift + k) becomes a filled circle (K = Kreis)
                case 'K':
                    this.toolbarManager.add.FilledCircle();
                    break;
                case 'k':
                    this.toolbarManager.add.Circle();
                    break;
                // R = Rechteck
                case 'R':
                    this.toolbarManager.add.FilledRectangle();
                    break;
                case 'r':
                    this.toolbarManager.add.Rectangle();
                    break;
                // PolYgon
                case 'Y':
                    this.toolbarManager.add.FilledPolygon();
                    break;
                case 'y':
                    // Ctrl+Y on Windows & Linux, Cmd+Y on Mac
                    if (event.ctrlKey || event.metaKey) {
                        this.canvasManager.redoAction();

                        // Prevent that Chrome opens history page on Mac (Cmd + Y)
                        event.preventDefault();
                    } else {
                        // y without Ctrl or Cmd -> add polygon
                        this.toolbarManager.add.Polygon();
                    }
                    break;
                // P = Pfeil
                case 'p':
                    this.toolbarManager.add.Arrow();
                    break;
                // f = Farbe
                case 'f':
                    this.toolbarManager.add.Colorpicker();
                    break;
                // F = Freitext
                case 'F':
                    this.toolbarManager.add.NumberAndText();
                    break;
                // z = Zuschneiden
                case 'z':
                    if (event.ctrlKey || event.metaKey) {
                        console.log(event);
                        if (event.shiftKey) {
                            // Alternativ for redo (Ctrl/Cmd + Shift + Z)
                            this.canvasManager.redoAction();
                        } else {
                            // Ctrl+Z on Windows & Linux, Cmd+Z on Mac -> Undo
                            this.canvasManager.undoAction();
                        }
                    } else {
                        // Z without Ctrl or Cmd -> activate crop tool
                        this.toolbarManager.cropImage();
                    }
                    break;
                // + = Vergrößern
                case '+':
                    this.toolbarManager.zoomIn();
                    break;
                case 'Enter':
                    if (this.toolbarManager.activeTool === this.toolbarManager.tools.crop) {
                        this.toolbarManager.confirmCrop();
                    }
                    break;
                case 'c':
                    // Ctrl+C on Windows & Linux, Cmd+C on Mac
                    if (event.ctrlKey || event.metaKey) {
                        this.canvasManager.objectManager.copyToClipboard();
                    }
                    break;
                case 'v':
                    // Ctrl+V on Windows & Linux, Cmd+V on Mac
                    if (event.ctrlKey || event.metaKey) {
                        this.canvasManager.objectManager.insertFromClipboard();
                    }
                    break;
                // a/d: activate/deactivate photo in selected group
                case 'a':
                    this.setIncludedInPhotoVersion(true);
                    break;
                case 'd':
                    this.setIncludedInPhotoVersion(false);
                    break;

                case 'ArrowLeft':
                    this.showPhoto('previous');
                    break;
                case 'ArrowRight':
                    this.showPhoto('next');
                    break;
            }
        }
        // handle alt key since alt + key returns different key value
        else {
            switch (event.code) {
                // g - toggle photo version for report
                case 'KeyG':
                    this.photo.versions.report.included = !this.photo.versions.report.included;
                    this.savePhoto();
                    break;

                // r - toggle photo version for residual value exchange
                case 'KeyR':
                    this.photo.versions.residualValueExchange.included =
                        !this.photo.versions.residualValueExchange.included;
                    this.savePhoto();
                    break;

                // s - toggle photo version for expert Statement
                case 'KeyS':
                    if (!this.report.expertStatements.length) break;
                    this.photo.versions.expertStatement.included = !this.photo.versions.expertStatement.included;
                    this.savePhoto();
                    break;

                // b - toggle photo version for repair confirmation
                case 'KeyB':
                    if (!this.report.repairConfirmation) break;
                    this.photo.versions.repairConfirmation.included = !this.photo.versions.repairConfirmation.included;
                    this.savePhoto();
                    break;
            }
        }
    }

    /**
     * Remember the aspect ratio of the current photo, so that the crop tool can be initialized with that aspect ratio.
     * This is helpful to ensure that all photos in the report have the same width/size.
     */
    protected saveAspectRatioForCropTool(): void {
        this.team.preferences.preferredPhotoAspectRatio =
            this.canvasManager.fabricPhoto.getBoundingRect().width /
            this.canvasManager.fabricPhoto.getBoundingRect().height;
        this.saveTeamPreferences();
    }

    /**
     * Remove the saved aspect ratio for the crop tool.
     */
    protected resetAspectRatioForCropTool(): void {
        delete this.team.preferences.preferredPhotoAspectRatio;
        this.saveTeamPreferences();
    }

    /**
     * Resize the canvas and time the browser window's size changes.
     * The canvas' and the photo's sizes are recalculated with help of the #image-container which is styled with CSS.
     */
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.canvasManager.resize();
        this.canvasManager.canvas.requestRenderAll();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Register Host Listeners
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

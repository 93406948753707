<!-- Use DIVs instead of h1 or h2 elements because they have too many default styles. -->
<div id="subheading">Herzlich willkommen!</div>
<div id="main-heading">Infos zu deinem Test-Account</div>

<mat-icon class="dialog-close-icon" (click)="closeSplashScreen()" matTooltip="Schließen">close</mat-icon>

<div id="content-grid">
    <!--********** Write & Bill Reports **********-->
    <div class="content-item">
        <!--********** Icon **********-->
        <div class="content-icon-container">
            <mat-icon class="content-icon">euro_symbol</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="content-text-container">
            <h3 class="content-heading">Gutachten schreiben & abrechnen</h3>
            <div class="content-body">
                Du kannst vollständige Gutachten schreiben & abrechnen. Im Test hast du Zugriff auf alle Funktionen.
            </div>
        </div>
    </div>

    <!--********** Keep Test Data **********-->
    <div class="content-item">
        <!--********** Icon **********-->
        <div class="content-icon-container">
            <mat-icon class="content-icon outlined">description</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="content-text-container">
            <h3 class="content-heading">Daten aus Test bleiben bestehen</h3>
            <div class="content-body">
                Falls du bestellst, behältst du alle Daten & Einstellungen aus deinem Test-Account.
            </div>
        </div>
    </div>

    <!--********** Free Help & Hotline **********-->
    <div class="content-item">
        <!--********** Icon **********-->
        <div class="content-icon-container">
            <mat-icon class="content-icon outlined">call</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="content-text-container">
            <h3 class="content-heading">Kostenfreie Hilfe & Hotline</h3>
            <div class="content-body">
                Bei Fragen zur Bedienung kannst du im
                <a href="https://wissen.autoixpert.de" target="_blank" rel="noopener">Helpcenter</a>
                und auf
                <a href="https://www.youtube.com/c/autoixpert" target="_blank" rel="noopener">Youtube</a>
                vorbeischauen oder kostenfrei bei uns anrufen.
            </div>
        </div>
    </div>

    <!--********** Free Product Webinars **********-->
    <div class="content-item">
        <!--********** Icon **********-->
        <div class="content-icon-container">
            <mat-icon class="content-icon outlined">smart_display</mat-icon>
        </div>

        <!--********** Text **********-->
        <div class="content-text-container">
            <h3 class="content-heading">Kostenfreie Produkt-Webinare</h3>
            <div class="content-body">
                Alle 4 Wochen demonstrieren wir online einen vollständigen Haftpflichtschaden.
                <br />
                <a href="https://www.autoixpert.de/Webinar" target="_blank" rel="noopener">
                    Anmeldung zum Live-Event »
                </a>
                oder
                <a href="https://www.youtube.com/watch?v=Ftp4H_3Ij88" target="_blank" rel="noopener">Video »</a>
            </div>
        </div>
    </div>
</div>

<button (click)="closeSplashScreen()">Weiter zur App</button>

<div class="label margin-top-20" *ngIf="isAuthenticatedAsAdminToken">
    Als globaler Admin eingeloggt: Welcome Screen wird dem Nutzer beim nächsten Mal wieder angezeigt.
</div>

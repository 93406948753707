<div class="dialog-overlay" @dialogEnterAndLeave>
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <h2>Zeilen importieren</h2>
        <p class="text-align-center">
            Importiere Positionen aus einer CSV-Datei, z. B. wenn du eine Ersatzteilliste von einem Hersteller bekommen
            hast.
            <br />
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/4402234671122-Zeilen-in-manuelle-Kalkulation-importieren-CSV-Import-"
                target="_blank"
                rel="noopener">
                Anleitung und Beispiel-Datei
            </a>
        </p>

        <div class="dialog-buttons-container">
            <button
                (click)="!csvUploadAndInterpretationPending && fileInput.click()"
                [ngClass]="{ disabled: csvUploadAndInterpretationPending }">
                <mat-icon class="button-icon" *ngIf="!csvUploadAndInterpretationPending">file_upload</mat-icon>
                <img
                    src="/assets/images/icons/loading-indicator-white.svg"
                    class="pending-icon"
                    *ngIf="csvUploadAndInterpretationPending" />
                Datei hochladen
            </button>
        </div>

        <input type="file" #fileInput (change)="handleCsvFileUpload($event)" />
    </div>
</div>

import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';

/**
 * Sorts the orderToBeSorted the same way as the templateOrder, effectively making the two orders equal.
 *
 * That's required when no custom default document order is allowed per recipient.
 */
export function adaptDefaultDocumentOrder({
    templateOrder,
    orderToBeSorted,
}: {
    templateOrder: DefaultDocumentOrder;
    orderToBeSorted: DefaultDocumentOrder;
}): void {
    if (templateOrder === orderToBeSorted) {
        return;
    }

    orderToBeSorted.items.sort((itemA, itemB) => {
        const templateIndexItemA: number = templateOrder.items.findIndex((defaultDocumentOrderItem) => {
            if (defaultDocumentOrderItem.documentType === 'customDocument') {
                return defaultDocumentOrderItem.customDocumentOrderConfigId === itemA.customDocumentOrderConfigId;
            } else {
                return defaultDocumentOrderItem.documentType === itemA.documentType;
            }
        });
        const templateIndexItemB: number = templateOrder.items.findIndex((defaultDocumentOrderItem) => {
            if (defaultDocumentOrderItem.documentType === 'customDocument') {
                return defaultDocumentOrderItem.customDocumentOrderConfigId === itemB.customDocumentOrderConfigId;
            } else {
                return defaultDocumentOrderItem.documentType === itemB.documentType;
            }
        });

        return templateIndexItemA - templateIndexItemB;
    });
}

export class ReportInvoiceNumberConfig {
    constructor(template: Partial<ReportInvoiceNumberConfig> = {}) {
        Object.assign(this, template);
    }

    count: number = 0;
    reportInvoiceNumberPattern: string;
    startReportInvoiceSubCounterWithSecondInvoice: boolean;
    useCancellationInvoiceSuffixForReportInvoices: boolean;
    reportInvoiceCancellationSuffix?: string;
    separateCounterForAmendmentReportsAndInvoiceAudits: boolean;
}

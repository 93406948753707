export class UserUserInterfaceStates {
    // Bank Account Sync
    bankAccountSyncAvailabilityNoteClosed: boolean = false; // Note in the invoice list that bank account sync is available
    firstBankAccountSyncNoteClosed: boolean = false; // Note on first bank account sync in the invoice list
    bankTransactionListTutorialVideoSeen: boolean;
    buildingBlockTutorialSeen: boolean = false;
    reportTokenConfigNoticeClosed: boolean = false;
    exampleVinNoticeClosed: boolean = false;
    // Accident & Involved Parties
    carRegistrationScanNoticeClosed: boolean = false;
    transferMissingSignaturesNoticeClosed: boolean = false;
    photoEditorContextMenuNoticeClosed: boolean = false;
    cartvDowntimeCompensationImportNoticeClosed: boolean;
    fullDocumentConfigNoteShown: boolean = false;
    addYourselfToBccNoticeClosed: boolean; // Suggest that the user add himself to the list of BCC recipients of every outgoing email.
    printAndTransmission_automaticBuildingBlockSelection_infoPanelClosed: boolean = false;
    audatexWageRateTranslationNoteClosed: boolean = false;
    // Residual Value Bidder Groups
    hideResidualValueBidderGroupsExplanation: boolean = false;
    hideSpecialCostsNotification: boolean = false;
    valuationTutorialSeen: boolean = false;
    // Tutorial preferences
    introVideosVisible: boolean = true;
    // License plate redaction
    licensePlateRedactionTutorialDismissed = false;
}

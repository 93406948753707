import {
    DiminishedValueCalculation,
    DiminishedValueCalculationMethod,
} from '../../models/reports/diminished-value/diminished-value-calculation';
import { DiminishedValueMethods } from '../damage-calculation-values/diminished-value-methods';

export const diminishedValueMethodNameToFunction = new Map<
    DiminishedValueCalculationMethod,
    (IDiminishedValueCalculation) => number
>([
    ['mfm', DiminishedValueMethods.calculateMfm],
    ['bvsk', DiminishedValueMethods.calculateBvsk],
    ['dvgt', DiminishedValueMethods.calculateDvgt],
    ['halbgewachs', DiminishedValueMethods.calculateHalbgewachs],
    ['hamburgModel', DiminishedValueMethods.calculateHamburgModel],
    ['ruhkopf', DiminishedValueMethods.calculateRuhkopf],
    ['troemner', DiminishedValueMethods.calculateTroemner],
]);

export function calculateAverageDiminishedValueOfActiveMethods(
    diminishedValueCalculation: DiminishedValueCalculation,
): number {
    const activeMethods = diminishedValueCalculation.methods;
    const validResults: number[] = activeMethods
        .map((methodName) => {
            try {
                return diminishedValueMethodNameToFunction.get(methodName)(diminishedValueCalculation);
            } catch (error) {
                // If there is an error executing the method, parameters are wrong or missing. Ignore the method then.
                return null;
            }
        })
        // Only consider methods that did not fail (e.g. because of incomplete parameters) and whose result is not zero.
        .filter((result) => result !== null && result > 0);

    if (!validResults.length) {
        return 0;
    }

    return validResults.reduce((sum, result) => sum + result, 0) / validResults.length;
}

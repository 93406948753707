import { DeviceType } from '@autoixpert/models/device-type';

export function getDeviceType(): DeviceType {
    const userAgent = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
        return 'tablet';
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            userAgent,
        )
    ) {
        return 'phone';
    }
    return 'desktop';
}

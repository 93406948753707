import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Injector, Input, OnDestroy } from '@angular/core';
import { Report } from '@autoixpert/models/reports/report';
import { EditReportInvoiceNumberCounterDialogComponent } from './edit-report-invoice-number-counter-dialog.component';

/**
 * Directive that can be used to open a small popup where the user can edit the report invoice number counter.
 */

@Directive({
    selector: '[editCounterAnchor]',
})
export class EditReportInvoiceNumberCounterOverlayAnchorDirective implements OnDestroy {
    constructor(
        private elementRef: ElementRef,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input('editCounterAnchor') report: Report;
    private overlayRef: OverlayRef;

    //*****************************************************************************
    //  Lifecycle Hooks
    //****************************************************************************/

    ngOnDestroy() {
        this.overlayRef?.detach();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lifecycle Hooks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openEditDialog() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                ])
                .withPush(false) // withPush being true (default) causes any overlay to grow up to 100% of the viewport, overflowing to the bottom.
                .withViewportMargin(20),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });

        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<EditReportInvoiceNumberCounterDialogComponent>(
            EditReportInvoiceNumberCounterDialogComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.overlayRef,
                    },
                ],
            }),
        );

        const componentRef = this.overlayRef.attach(componentPortal);
        componentRef.instance.report = this.report;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

import { getDocumentOrderForRecipient } from '@autoixpert/lib/documents/get-document-order-for-recipient';
import { getInvolvedPartiesFromInvoice } from '@autoixpert/lib/involved-parties/get-involved-parties-from-invoice';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { Invoice, InvoiceInvolvedParty } from '@autoixpert/models/invoices/invoice';

export function addMissingDocumentOrdersToInvoice({ invoice }: { invoice: Invoice }): void {
    //*****************************************************************************
    //  Add Document Orders per Recipient
    //****************************************************************************/
    /**
     * Documents may be sorted individually per recipient. Create a document order object for each recipient.
     */
    const invoiceInvolvedParties: InvoiceInvolvedParty[] = getInvolvedPartiesFromInvoice({ invoice });
    for (const invoiceInvolvedParty of invoiceInvolvedParties) {
        try {
            getDocumentOrderForRecipient(invoice.documentOrders, invoiceInvolvedParty.role);
        } catch (error) {
            if (error.code === 'DOCUMENT_ORDER_NOT_FOUND_FOR_GIVEN_RECIPIENT') {
                invoice.documentOrders.push(
                    new DocumentOrder({
                        recipientRole: invoiceInvolvedParty.role,
                    }),
                );
            } else {
                throw error;
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add Document Orders per Recipient
    /////////////////////////////////////////////////////////////////////////////*/
}

import { InvoiceParameters } from '../../invoices/invoice-parameters';

export class FeeCalculation {
    selectedFeeTable: FeeTableName = null;
    selectedCustomFeeTableId = null;
    yearOfFeeTable: number = null; // 2024
    assessorsFee: number = null;
    assessorsFeeFrozen: boolean = null;
    assessorsFeeDescription: string = null;
    // Photos
    pricePerPhoto: number = null;
    usePhotosFixedPrice: boolean = null;
    photosFixedPrice: number = null;
    secondPhotoSet: boolean = null;
    pricePerSecondPhoto: number = null;
    secondPhotoSetFixedPrice: number = null;
    photoFeeDescription: string = null;
    // Travel Expenses
    numberOfKilometers: number = null;
    pricePerKilometer: number = null;
    useTravelExpensesFixedPrice: boolean = null;
    travelExpensesFixedPrice: number = null;
    travelExpensesFeeDescription: string;
    // Print & Copy Fees
    useManualNumberOfPhotos: boolean = null;
    manualNumberOfPhotos: number = null;
    numberOfPages: number = null;
    pricePerPage: number = null;
    pricePerPageCopy: number = null;
    useWritingFeesFixedPrice: boolean = null;
    useWritingCopyFees: boolean = null;
    writingFeesFixedPrice: number = null;
    writingCopyFeesFixedPrice: number = null;
    postageAndPhoneFees: number = null;
    invoiceParameters: InvoiceParameters = new InvoiceParameters();
    skipWritingInvoice: boolean = null; // Don't generate an invoice when locking a report.
    skipWritingInvoiceReason: string = null; // Reason for not writing an invoice. Only for documentation (e.g. often required by tax office)
    isCollectiveInvoice?: boolean; // Will be part of a collective invoice
    collectiveInvoiceId?: string; // Collective invoice has been written -> this is the ID of the collective invoice
    printAndCopyFeeDescription: string = null;
}

export type OfficialFeeTableName = 'HUK' | 'BVSK' | 'VKS' | 'CGF';
export type FeeTableName = OfficialFeeTableName | 'custom';

export type OfficialFeeTable = {
    year: number;
    table: FeeTable;
};
export type FeeTable = number[][];

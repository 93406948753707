import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { CorrectionItem } from '@autoixpert/models/reports/invoice-audit';
import { Report } from '@autoixpert/models/reports/report';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { AutocompleteWithMemory } from '../../../shared/components/autocomplete-with-memory/autocomplete-with-memory.component';
import { getVatRate } from '../../../shared/libraries/get-vat-rate-2020';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { CustomAutocompleteEntriesService } from '../../../shared/services/custom-autocomplete-entries.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportService } from '../../../shared/services/report.service';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
    selector: 'invoice-audit',
    templateUrl: 'invoice-audit.component.html',
    styleUrls: ['invoice-audit.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class InvoiceAuditComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private reportDetailsService: ReportDetailsService,
        private toastService: ToastService,
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private apiErrorService: ApiErrorService,
        private reportService: ReportService,
        private router: Router,
    ) {}

    @ViewChildren('correctionItemTitleInput') correctionItemTitleInputs: QueryList<AutocompleteWithMemory>;

    public report: Report;
    private subscriptions: Subscription[] = [];
    public invoice: Invoice;

    public reportConnectionInputShown: boolean = true;
    public reportSearchPending: boolean = false;
    public reportSearchTerm: string = null;
    public reportSearchTerm$: Subject<string> = new Subject<string>();
    public reports: Report[] = [];
    public filteredReports: Report[] = [];
    public reportSearchTermSubscription: Subscription;
    public connectedReport: Report;

    // Correction Items
    public correctionItemTitleAutocompleteEntries: CustomAutocompleteEntry[] = [];
    public correctionItemCommentAutocompleteEntries: CustomAutocompleteEntry[] = [];

    ngOnInit() {
        const reportSubscription = this.route.parent.params
            .pipe(switchMap((params) => this.reportDetailsService.get(params['reportId'])))
            .subscribe({
                next: (report) => {
                    this.report = report;
                    this.getConnectedReport();
                },
            });
        this.subscriptions.push(reportSubscription);

        this.loadAutocompleteEntriesForCorrectionItems();

        this.showReportConnectionInput();
    }

    //*****************************************************************************
    //  Calculate Totals
    //****************************************************************************/
    public calculateValues() {
        const invoiceAudit = this.report.invoiceAudit;

        // Regular rows
        const regularRows = [
            invoiceAudit.wages,
            invoiceAudit.spareParts,
            invoiceAudit.paint,
            invoiceAudit.auxiliaryCosts,
            invoiceAudit.otherCosts,
        ];
        regularRows.forEach((row) => {
            row.difference = (row.actualRepairCosts || 0) - (row.projectedRepairCosts || 0);
            row.correctedAmount = invoiceAudit.correctionItems
                .filter((item) => item.category === row.category)
                .reduce((total, item) => (item.correctionAmount || 0) + total, 0);
        });

        // Total row
        invoiceAudit.columnTotals.projectedRepairCosts = regularRows.reduce(
            (total, row) => total + (row.projectedRepairCosts || 0),
            0,
        );
        invoiceAudit.columnTotals.actualRepairCosts = regularRows.reduce(
            (total, row) => total + (row.actualRepairCosts || 0),
            0,
        );
        invoiceAudit.columnTotals.difference = regularRows.reduce((total, row) => total + (row.difference || 0), 0);
        invoiceAudit.columnTotals.correctedAmount = regularRows.reduce(
            (total, row) => total + (row.correctedAmount || 0),
            0,
        );

        // Overall result
        invoiceAudit.correctedRepairCostsNet =
            invoiceAudit.columnTotals.actualRepairCosts + invoiceAudit.columnTotals.correctedAmount;
        invoiceAudit.correctedRepairCostsGross =
            invoiceAudit.correctedRepairCostsNet * (1 + getVatRate(moment().format()));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculate Totals
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Correction Items
    //****************************************************************************/
    public addCorrectionItem() {
        const newCorrectionItem = new CorrectionItem();
        newCorrectionItem.category = 'wages';
        const lastItem = this.report.invoiceAudit.correctionItems[this.report.invoiceAudit.correctionItems.length - 1];
        if (lastItem) {
            newCorrectionItem.category = lastItem.category;
        }
        this.report.invoiceAudit.correctionItems.push(newCorrectionItem);

        this.saveReport();

        // Wait for the new row (including the title input field) to be rendered, then focus it
        setTimeout(() => {
            this.correctionItemTitleInputs.last.axAutofocus = true;
        }, 0);
    }

    public deleteCorrectionItem(correctionItem: CorrectionItem) {
        removeFromArray(correctionItem, this.report.invoiceAudit.correctionItems);
    }

    public doCorrectionItemsWithPositiveAmountExist(): boolean {
        return this.report.invoiceAudit.correctionItems.some((item) => item.correctionAmount > 0);
    }

    /**
     * Load autocomplete entries for the potentially long list of correction items once, then pass them to the components.
     */
    public loadAutocompleteEntriesForCorrectionItems() {
        this.customAutocompleteEntriesService.find({ type: 'invoiceAuditCorrectionItemTitle' }).subscribe({
            next: (autocompleteEntries) => {
                this.correctionItemTitleAutocompleteEntries = autocompleteEntries;
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Autocomplete-Einträge nicht geladen',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
        this.customAutocompleteEntriesService.find({ type: 'invoiceAuditCorrectionItemComment' }).subscribe({
            next: (autocompleteEntries) => {
                this.correctionItemCommentAutocompleteEntries = autocompleteEntries;
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Autocomplete-Einträge nicht geladen',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    /**
     * Reassigning triggers filtering the autocomplete entries in all autocompletes with memory.
     */
    public reassignLoadedTitleAutocompleteEntries() {
        this.correctionItemTitleAutocompleteEntries = [...this.correctionItemTitleAutocompleteEntries];
    }

    /**
     * Reassigning triggers filtering the autocomplete entries in all autocompletes with memory.
     */
    public reassignLoadedCommentAutocompleteEntries() {
        this.correctionItemCommentAutocompleteEntries = [...this.correctionItemCommentAutocompleteEntries];
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Correction Items
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report State
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report State
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public async saveReport(): Promise<void> {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.warn('Gutachten nicht synchronisiert', 'Ist eine Internetverbindung vorhanden?');
            console.error('An error occurred while saving the report via the ReportService.', { error });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Connection
    //****************************************************************************/

    public showReportConnectionInput(): void {
        this.reportConnectionInputShown = true;
        this.reportSearchTermSubscription = this.reportSearchTerm$
            .pipe(
                /**
                 * Ensure that a search term has 3 or more characters. Otherwise, searches like "a" are so unspecific
                 * that our backend server collapses under the load because for large customer accounts, thousand of reports
                 * are loaded into memory.
                 *
                 * Three characters allow for searches like "BMW" oder "Audi".
                 *
                 * Also allow searches if there are two or more search words separated by space. That allows to search for license plates
                 * like "PB SL".
                 */
                filter((searchTerm) => {
                    if (!searchTerm || typeof searchTerm !== 'string') return;

                    // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
                    const searchTermParts = searchTerm
                        .trim()
                        .split(' ')
                        .filter((searchTerm) => !!searchTerm.trim());
                    return searchTermParts.some((searchTermPart) => searchTermPart.length >= 3);
                }),
                tap(() => {
                    this.reportSearchPending = true;
                }),
                debounceTime(300),
                switchMap((searchTerm) => this.reportService.searchReportsWithoutPagination({ $search: searchTerm })),
            )
            .subscribe({
                next: (reports) => {
                    this.reports = reports;
                    this.sortReports();
                    this.filterReportsAutocomplete();
                    this.reportSearchPending = false;
                },
                error: () => {
                    this.toastService.error(
                        'Fehler bei Suche',
                        "Bitte kontaktiere die <a href='/Hilfe' target='_blank'>Hotline</a>.",
                    );
                    this.reportSearchPending = false;
                },
            });
    }

    public hideReportConnectionInput(): void {
        this.reportConnectionInputShown = false;
        this.reportSearchTermSubscription.unsubscribe();
    }

    /**
     * Fetch connected report from server
     */
    public async getConnectedReport(): Promise<void> {
        if (!this.report?.invoiceAudit?.reportId) return;

        try {
            this.connectedReport = await this.reportService.get(this.report.invoiceAudit.reportId);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Verknüpftes Gutachten fehlt',
                    body: 'Bitte stelle sicher, dass es noch existiert oder verknüpfe es erneut.',
                },
            });
        }
    }

    public updateReportSearchTerm(searchTerm: string): void {
        this.reportSearchTerm$.next(searchTerm);
    }

    public sortReports(): void {
        this.reports.sort((reportA, reportB) => {
            const tokenA = (reportA.token || '').toLowerCase().trim();
            const tokenB = (reportB.token || '').toLowerCase().trim();

            return tokenA.localeCompare(tokenB);
        });
    }

    public filterReportsAutocomplete(): void {
        const searchTerms = (this.reportSearchTerm || '').split(' ');

        this.filteredReports = this.reports.filter((report) => {
            const propertiesToBeSearched = [
                (report.token || '').toLowerCase(),
                (report.car.licensePlate || '').toLowerCase(),
                (report.claimant.contactPerson.organization || '').toLowerCase(),
                (report.claimant.contactPerson.firstName || '').toLowerCase(),
                (report.claimant.contactPerson.lastName || '').toLowerCase(),
            ];

            return searchTerms.every((searchTerm) => {
                searchTerm = (searchTerm || '').toLowerCase();
                return propertiesToBeSearched.some((property) => property.includes(searchTerm));
            });
        });
    }

    public handleOpenInNewClick(reportId: string, event: MouseEvent): void {
        window.open(`/Gutachten/${reportId}`);
        // Prevent the click from selecting the autocomplete entry
        event.stopPropagation();
    }

    public async connectReport(report: Report): Promise<void> {
        this.report.invoiceAudit.reportId = report._id;

        if (
            !report.damageCalculation.repair.garageLaborCostsNet &&
            report.damageCalculation.repair.sparePartsCostsNet &&
            report.damageCalculation.repair.lacquerCostsNet &&
            report.damageCalculation.repair.auxiliaryCostsNet
        ) {
            this.toastService.warn('Wähle ein Gutachten, mit einer vollständigen Rechnung aus.');
            return;
        }

        this.report.invoiceAudit.wages.projectedRepairCosts = report.damageCalculation.repair.garageLaborCostsNet;
        this.report.invoiceAudit.spareParts.projectedRepairCosts = report.damageCalculation.repair.sparePartsCostsNet;
        this.report.invoiceAudit.paint.projectedRepairCosts = report.damageCalculation.repair.lacquerCostsNet;
        this.report.invoiceAudit.auxiliaryCosts.projectedRepairCosts =
            report.damageCalculation.repair.auxiliaryCostsNet;

        await this.getConnectedReport();
        this.hideReportConnectionInput();
    }

    public cutReportConnection(): void {
        this.connectedReport = null;
        this.report.invoiceAudit.reportId = null;

        this.showReportConnectionInput();
    }

    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;

    public navigateToReport(report: Report): void {
        this.router.navigateByUrl(`/Gutachten/${report._id}`);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Connection
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

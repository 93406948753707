import { Invoice } from '../../models/invoices/invoice';
import { Payment } from '../../models/invoices/payment';

export function getQueryForInvoicesByPaymentDate({ fromDate, toDate }: { fromDate: string; toDate: string }) {
    const query: any = {
        isFullCancellationInvoice: { $ne: true },
        isFullyCanceled: { $ne: true },
    } satisfies Partial<Record<keyof Invoice, any>>;
    let dateLimits: any;

    if (fromDate || toDate) {
        dateLimits = {};
    }
    if (fromDate) {
        dateLimits.$gte = fromDate;
    }
    if (toDate) {
        dateLimits.$lte = toDate;
    }

    query.payments = {
        $elemMatch: {
            date: dateLimits,
            // Include both actual payments and short payments that have turned out to be paid in the end.
            $or: [
                {
                    type: 'payment' satisfies Payment['type'],
                },
                {
                    type: 'shortPayment' satisfies Payment['type'],
                    shortPaymentStatus: 'paid',
                } satisfies Partial<Record<keyof Payment, any>>,
            ],
        },
    };

    return query;
}

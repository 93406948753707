import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { MatLegacyFormField } from '@angular/material/legacy-form-field/index';
import { MatSelect } from '@angular/material/select/index';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { getAbsolutePaintMaterialSurcharge } from '@autoixpert/lib/damage-calculation-values/get-absolute-paint-material-surcharge';
import { generateId } from '@autoixpert/lib/generate-id';
import { round } from '@autoixpert/lib/numbers/round';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { DamageCalculation } from '@autoixpert/models/reports/damage-calculation/damage-calculation';
import {
    ManualCalculation,
    ManualCalculationItem,
    RepairCategory,
    RepairCode,
    RepairDifficultyLevel,
} from '@autoixpert/models/reports/damage-calculation/manual-calculation';
import { Report } from '@autoixpert/models/reports/report';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';
import { slideOutDialogVertical } from '../../../../shared/animations/slide-out-dialog-vertical.animation';
import { CurrencyInputComponent } from '../../../../shared/components/currency-input/currency-input.component';
import { convertLineFeedsToSpaces } from '../../../../shared/libraries/convert-line-feeds-to-spaces';
import { getApplicablePaintMaterialSurcharge } from '../../../../shared/libraries/garages/get-applicable-paint-material-surcharge';
import { getSelectedGarageFeeSet } from '../../../../shared/libraries/garages/get-selected-garage-fee-set';
import { getVatRate } from '../../../../shared/libraries/get-vat-rate-2020';
import { trackById } from '../../../../shared/libraries/track-by-id';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { CustomAutocompleteEntriesService } from '../../../../shared/services/custom-autocomplete-entries.service';
import { ToastService } from '../../../../shared/services/toast.service';
import {
    ManualCalculationInputOverlayComponent,
    TabDirection,
} from './manual-calculation-input-overlay/manual-calculation-input-overlay.component';

@Component({
    selector: 'manual-calculation-dialog',
    templateUrl: 'manual-calculation-dialog.component.html',
    styleUrls: ['manual-calculation-dialog.component.scss'],
    animations: [fadeInAndSlideAnimation(), slideOutDialogVertical(), runChildAnimations()],
})
export class ManualCalculationDialogComponent implements OnChanges, OnInit {
    /**
     * References to the components before and after the description input. These are necessary to imitate the
     * focus order (because description input field is displayed in an overlay to enable multi-line support
     * while keeping a fixed row height for the virtual scroll). CAUTION: These query lists are dynamic and
     * only contain the items that are currently rendered by the virtual scroll area.
     */
    @ViewChildren('repairCodeSelect') repairCodeSelects: QueryList<MatSelect>;
    @ViewChildren('totalPriceInput') totalPriceInputs: QueryList<CurrencyInputComponent>;

    /**
     * When resizing the window -> Close the description overlay.
     */
    @HostListener('window:resize')
    onResize() {
        this.closeDescriptionOverlay();
    }

    constructor(
        private toastService: ToastService,
        private overlayService: Overlay,
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() report: Report;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() reportChange: EventEmitter<Report> = new EventEmitter<Report>();

    @ViewChildren('calculationItemDescriptionFormField', { read: ElementRef }) descriptionInputs: QueryList<ElementRef>;

    // Calculation Item List
    public filter: RepairCategory | 'valueIncrease' | null = null;
    public filteredCalculationItems: ManualCalculationItem[] = [];

    public itemDescriptionAutocompleteEntries: CustomAutocompleteEntry[] = [];

    // Fee Set
    public feeSet: GarageFeeSet;
    public paintMaterialSurcharge: ReturnType<typeof getApplicablePaintMaterialSurcharge>;
    /**
     * The selection of a repair code automatically groups the calculation item into one of the calculation categories.
     */
    public repairCodeToCategoryMaps: RepairCodeCategoryMap[] = [
        // The repair code "replace" ("Ersetzen") may appear in all three categories spareParts, laborCosts and auxiliaryCosts.
        {
            code: 'E-spareParts',
            category: 'spareParts',
            label: 'Ersetzen',
        },
        {
            code: 'E-laborCosts',
            category: 'laborCosts',
            label: 'Ersetzen',
        },
        {
            code: 'E-auxiliaryCosts',
            category: 'auxiliaryCosts',
            label: 'Ersetzen',
        },
        {
            code: 'I',
            category: 'laborCosts',
            label: 'Instandsetzen',
        },
        {
            code: 'R',
            category: 'laborCosts',
            label: 'Reparieren',
        },
        {
            code: 'L',
            category: 'paint',
            label: 'Lackieren',
        },
        {
            code: 'N',
            category: 'auxiliaryCosts',
            label: 'Nebenkosten',
        },

        // Less common options
        {
            code: 'A',
            category: 'laborCosts',
            label: 'Aus- & Einbau / Ab- & Anbau',
        },
        {
            code: 'C',
            category: 'laborCosts',
            label: 'Spotrepair',
        },
        {
            code: 'D',
            category: 'laborCosts',
            label: 'Demontieren / Montieren',
        },
        {
            code: 'M',
            category: 'paint',
            label: 'Montageteil demontiert lackieren',
        },
        {
            code: 'P',
            category: 'laborCosts',
            label: 'Prüfung / Sichtprüfung',
        },
        {
            code: 'RIS',
            category: 'laborCosts',
            label: 'Risiko',
        },
        {
            code: 'S',
            category: 'laborCosts',
            label: 'Einstellen',
        },
        {
            code: 'T',
            category: 'laborCosts',
            label: 'Technische Prüfung',
        },
        {
            code: 'Z',
            category: 'laborCosts',
            label: 'Zerlegen / Zusammenbauen',
        },
    ];

    /**
     * The mappings between the laborType (e.g. mechanics_1) and the actual number taken from the garage fee set.
     */
    public garageWageMaps: GarageWageMap[] = [];

    // CSV Import
    public manualCaluclationImportDialogShown: boolean;
    /**
     * Current search term used to filter the calculation items typed by the user.
     */
    protected searchTerm: string = '';

    /**
     * A reference to the overlay containing the multi-line input that we display above
     * the regular description input. This is necessary because the regular input is limited
     * to a single row so that we have equal row heights for the virtual-scrolling (needed
     * for performance).
     */
    private descriptionInputOverlayRef: OverlayRef;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.selectGarageFeeSet();
        this.findItemDescriptionAutocompleteEntries();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['report']) {
            if (!this.report.damageCalculation.repair.manualCalculation) {
                this.report.damageCalculation.repair.manualCalculation = new ManualCalculation();
                this.saveChanges();
            }
            this.filterCalculationItems();
            this.selectGarageFeeSet();
            this.setUpGarageWageMaps();
        }
    }

    private setUpGarageWageMaps(): void {
        if (!this.feeSet.mechanics || !this.feeSet.electrics || !this.feeSet.carBody) return;

        this.garageWageMaps = [
            // Mechanics
            {
                label: 'Mechanik',
                laborType: 'mechanics_1',
                level: 1,
                hourlyWage: this.feeSet.mechanics.firstLevel,
            },
            {
                label: 'Mechanik 2',
                laborType: 'mechanics_2',
                level: 2,
                hourlyWage: this.feeSet.mechanics.secondLevel,
            },
            {
                label: 'Mechanik 3',
                laborType: 'mechanics_3',
                level: 3,
                hourlyWage: this.feeSet.mechanics.thirdLevel,
            },

            // Electrics
            {
                label: 'Elektrik',
                laborType: 'electrics_1',
                level: 1,
                hourlyWage: this.feeSet.electrics.firstLevel,
            },
            {
                label: 'Elektrik 2',
                laborType: 'electrics_2',
                level: 2,
                hourlyWage: this.feeSet.electrics.secondLevel,
            },
            {
                label: 'Elektrik 3',
                laborType: 'electrics_3',
                level: 3,
                hourlyWage: this.feeSet.electrics.thirdLevel,
            },

            // Car body
            {
                label: 'Karosserie',
                laborType: 'carBody_1',
                level: 1,
                hourlyWage: this.feeSet.carBody.firstLevel,
            },
            {
                label: 'Karosserie 2',
                laborType: 'carBody_2',
                level: 2,
                hourlyWage: this.feeSet.carBody.secondLevel,
            },
            {
                label: 'Karosserie 3',
                laborType: 'carBody_3',
                level: 3,
                hourlyWage: this.feeSet.carBody.thirdLevel,
            },

            // Paint
            {
                label: 'Lack',
                laborType: 'carPaint',
                level: null,
                hourlyWage: this.feeSet.carPaint.wage,
            },
            // Dents
            {
                label: 'Dellen',
                laborType: 'dents',
                level: null,
                hourlyWage: this.feeSet.dentsWage,
            },
        ];
        // Remove records without hourly wages to keep the interface clean
        this.garageWageMaps = this.garageWageMaps.filter((wageMap) => !!wageMap.hourlyWage);
    }

    private selectGarageFeeSet() {
        this.feeSet = getSelectedGarageFeeSet(this.report.garage);
        this.paintMaterialSurcharge = getApplicablePaintMaterialSurcharge(this.report.car.paintType, this.feeSet);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Dialog Management
    //****************************************************************************/
    public closeManualCalculation(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog Management
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary Totals
    //****************************************************************************/
    /**
     * Sum up spare parts and apply surcharges.
     *
     * 1) Pristine unit price
     * 2) Apply spare part surcharge (UPE)
     * 3) Apply small parts surcharge
     */
    get sparePartsTotal(): number {
        // In case the right fee set has not yet been selected.
        if (!this.feeSet) return null;

        const sparePartsCalculationItems =
            this.report.damageCalculation.repair.manualCalculation.calculationItems.filter(
                (calculationItem) => calculationItem.category === 'spareParts',
            );
        const totalWithoutSurcharges: number = sparePartsCalculationItems.reduce(
            (total, calculationItem) => total + (calculationItem.unitPrice ?? 0) * (calculationItem.quantity ?? 0),
            0,
        );

        // Add spare parts surcharge
        const totalWithSparePartsSurcharge =
            totalWithoutSurcharges * (1 + (this.feeSet.sparePartsSurcharge ?? 0) / 100);

        let totalWithAllSurcharges: number;
        // Add small parts surcharge
        if (this.feeSet.smallPartsUnit === 'percent') {
            totalWithAllSurcharges = totalWithSparePartsSurcharge * (1 + (this.feeSet.smallPartsSurcharge ?? 0) / 100);
        } else {
            // Sum + flatFeeSurcharge - the flat fee is not applied to each part to once in total.
            totalWithAllSurcharges = totalWithSparePartsSurcharge + this.feeSet.smallPartsSurcharge;
        }

        return round(totalWithAllSurcharges, 2);
    }

    get auxiliaryCostsTotal(): number {
        const total = this.report.damageCalculation.repair.manualCalculation.calculationItems
            .filter((calculationItem) => calculationItem.category === 'auxiliaryCosts')
            .reduce((total, calculationItem) => total + calculationItem.unitPrice * calculationItem.quantity, 0);
        return round(total, 2);
    }

    get laborCostsTotal(): number {
        const total = this.report.damageCalculation.repair.manualCalculation.calculationItems
            .filter((calculationItem) => calculationItem.category === 'laborCosts')
            .reduce((total, calculationItem) => total + calculationItem.unitPrice * calculationItem.quantity, 0);
        return round(total, 2);
    }

    get paintLaborTotal(): number {
        const total = this.report.damageCalculation.repair.manualCalculation.calculationItems
            .filter((calculationItem) => calculationItem.category === 'paint')
            .reduce((total, calculationItem) => total + calculationItem.unitPrice * calculationItem.quantity, 0);
        return round(total, 2);
    }

    get paintMaterialTotal(): number {
        // Shortcut
        const feeSet: GarageFeeSet = getSelectedGarageFeeSet(this.report.garage);
        const applicablePaintMaterialSurcharge = getApplicablePaintMaterialSurcharge(this.report.car.paintType, feeSet);

        const total: number = getAbsolutePaintMaterialSurcharge({
            materialSurchargeUnit: feeSet?.carPaint.materialSurchargeUnit,
            paintCostsWithoutSurcharge: this.paintLaborTotal,
            applicablePaintMaterialSurchargeValue: applicablePaintMaterialSurcharge.value,
        });

        return round(total, 2);
    }

    get garageLaborHours(): number {
        const total = this.report.damageCalculation.repair.manualCalculation.calculationItems
            .filter((calculationItem) => calculationItem.category === 'laborCosts')
            .reduce((total, calculationItem) => total + calculationItem.quantity, 0);
        return round(total, 2);
    }

    get lacquerLaborHours(): number {
        const total = this.report.damageCalculation.repair.manualCalculation.calculationItems
            .filter((calculationItem) => calculationItem.category === 'paint')
            .reduce((total, calculationItem) => total + calculationItem.quantity, 0);
        return round(total, 2);
    }

    get manualCalculationTotalNet(): number {
        const total =
            this.sparePartsTotal +
            this.auxiliaryCostsTotal +
            this.laborCostsTotal +
            this.paintLaborTotal +
            this.paintMaterialTotal;
        return round(total, 2);
    }

    get manualCalculationTotalGross(): number {
        const total = this.manualCalculationTotalNet * (1 + getVatRate());
        return round(total, 2);
    }

    get valueIncreaseOnSpareParts(): number {
        const total = this.report.damageCalculation.repair.manualCalculation.calculationItems
            .filter((calculationItem) => calculationItem.category === 'spareParts')
            .reduce(
                (total, calculationItem) =>
                    total +
                    (calculationItem.unitPrice *
                        calculationItem.quantity *
                        (calculationItem.valueIncreasePercentage || 0)) /
                        100,
                0,
            );
        return round(total, 2);
    }

    get valueIncreaseOnPaint(): number {
        const applicablePaintMaterialSurcharge = getApplicablePaintMaterialSurcharge(
            this.report.car.paintType,
            getSelectedGarageFeeSet(this.report.garage),
        );
        const carPaintMaterialSurcharge = (applicablePaintMaterialSurcharge.value || 0) / 100;
        return round(
            this.report.damageCalculation.repair.manualCalculation.calculationItems
                .filter((calculationItem) => calculationItem.category === 'paint')
                // With paint, the base for value increases is the hourly wage, not the paint material.
                // TODO Is it right that we assume the paint material surcharge to be a percentage (and no absolute value) here? (Mark 2022-10-10)
                .reduce(
                    (total, calculationItem) =>
                        total +
                        (calculationItem.unitPrice *
                            calculationItem.quantity *
                            (1 + carPaintMaterialSurcharge) *
                            (calculationItem.valueIncreasePercentage || 0)) /
                            100,
                    0,
                ),
            2,
        );
    }

    get valueIncreaseTotal(): number {
        const total = this.valueIncreaseOnSpareParts + this.valueIncreaseOnPaint;
        return round(total, 2);
    }

    public getValueIncreaseTooltip(): string {
        const parts: string[] = ['Neu-für-Alt-Abzüge'];

        if (this.valueIncreaseOnSpareParts) {
            parts.push(
                `Ersatzteile: ${Number(this.valueIncreaseOnSpareParts).toLocaleString('de', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })} €`,
            );
        }

        if (this.valueIncreaseOnPaint) {
            parts.push(
                `Lack: ${Number(this.valueIncreaseOnPaint).toLocaleString('de', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })} €`,
            );
        }

        return parts.join('\n');
    }

    get correctedManualCalculationTotalNet(): number {
        const total = this.manualCalculationTotalNet - this.valueIncreaseTotal;
        return round(total, 2);
    }

    get correctedManualCalculationTotalGross(): number {
        const total = this.correctedManualCalculationTotalNet * (1 + getVatRate());
        return round(total, 2);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary Totals
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter
    //****************************************************************************/
    public setFilter(filter: this['filter']): void {
        if (this.filter === filter) {
            this.filter = null;
        } else {
            this.filter = filter;
        }
        this.filterCalculationItems();
    }

    public filterCalculationItems(): void {
        // #NoFilter
        if (!this.filter) {
            this.filteredCalculationItems = [
                ...this.report.damageCalculation.repair.manualCalculation.calculationItems,
            ];
        }
        // #Filter
        else {
            if (this.filter === 'valueIncrease') {
                this.filteredCalculationItems =
                    this.report.damageCalculation.repair.manualCalculation.calculationItems.filter(
                        (item) => item.valueIncreasePercentage,
                    );
            } else {
                // Find all items without a category or with the category set by the filter.
                this.filteredCalculationItems =
                    this.report.damageCalculation.repair.manualCalculation.calculationItems.filter(
                        (item) => !item.category || item.category === this.filter,
                    );
            }
        }

        if (this.searchTerm) {
            this.applySearchFilter();
        }
    }

    /**
     * Filter the calculation items by the search phrase typed by the user.
     */
    private applySearchFilter(): void {
        if (!this.searchTerm) {
            return;
        }

        const searchTerms = this.searchTerm.toLowerCase().split(' ');

        this.filteredCalculationItems = this.filteredCalculationItems.filter((calculationItem) => {
            const propertiesToBeSearched: string[] = [
                calculationItem.description,
                calculationItem.partOrWorkItemNumber,
            ];

            return searchTerms.every((searchTerm) => {
                return propertiesToBeSearched.some((propertyToBeSearched) => {
                    if (!propertyToBeSearched) {
                        return false;
                    }
                    return propertyToBeSearched.toLowerCase().includes(searchTerm);
                });
            });
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Work Fraction Units
    //****************************************************************************/
    public selectWorkFractionUnit(unit: GarageFeeSet['selectedWorkFractionUnit']): void {
        if (this.feeSet.selectedWorkFractionUnit === unit) return;

        // Ex: Switching from 1 to 12, this factor is 1/12
        const recalculationFactor: number = this.feeSet.selectedWorkFractionUnit / unit;

        this.feeSet.selectedWorkFractionUnit = unit;

        // Re-calculate the amount and the unit price if the unit changes
        this.report.damageCalculation.repair.manualCalculation.calculationItems
            // Only labor items must be re-calculated because only they can be listed with a unit of work fractions
            .filter((item) => new Array<RepairCategory>('laborCosts', 'paint').includes(item.category))
            .forEach((item) => {
                // Ex: Switching from 1 to 12, the recalculation factor is 1/12.
                // amount / 1/(1/12) = amount * 12
                item.quantity = item.quantity / recalculationFactor;

                item.unitPrice = item.unitPrice * recalculationFactor;
            });

        this.setUpGarageWageMaps();

        this.saveChanges();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Work Fraction Units
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Calculation Items
    //****************************************************************************/
    public reorderCalculationItemsArray(event: CdkDragDrop<string[]>): void {
        const movedCalculationItem = this.report.damageCalculation.repair.manualCalculation.calculationItems.splice(
            event.previousIndex,
            1,
        )[0];
        // Add the item back at the new position
        this.report.damageCalculation.repair.manualCalculation.calculationItems.splice(
            event.currentIndex,
            0,
            movedCalculationItem,
        );

        // Save the new order back to the server.
        this.saveChanges();
    }

    protected addCalculationItemAndSave(): void {
        if (this.isReportLocked()) return;

        this.addCalculationItem();
        this.filterCalculationItems();
        this.saveChanges();
    }

    public addCalculationItem(): void {
        const newLineItem = new ManualCalculationItem();
        this.report.damageCalculation.repair.manualCalculation.calculationItems.push(newLineItem);

        // Wait for the new row (including the description input field) to be rendered, then focus it
        setTimeout(() => {
            this.descriptionInputs.last.nativeElement.querySelector('input').focus();
        }, 0);
    }

    public duplicateCalculationItem(insertAfterElement: ManualCalculationItem): void {
        const newLineItem: ManualCalculationItem = JSON.parse(JSON.stringify(insertAfterElement));
        newLineItem._id = generateId();

        const index =
            this.report.damageCalculation.repair.manualCalculation.calculationItems.indexOf(insertAfterElement);
        this.report.damageCalculation.repair.manualCalculation.calculationItems.splice(index, 0, newLineItem);
    }

    public removeCalculationItem(calculationItem: ManualCalculationItem): void {
        const index = this.report.damageCalculation.repair.manualCalculation.calculationItems.indexOf(calculationItem);
        this.report.damageCalculation.repair.manualCalculation.calculationItems.splice(index, 1);
        if (this.report.damageCalculation.repair.manualCalculation.calculationItems.length === 0) {
            this.addCalculationItem();
        }
    }

    public getCalculationItemTotal(calculationItem: ManualCalculationItem): number {
        return (
            (calculationItem.quantity *
                calculationItem.unitPrice *
                (100 - (calculationItem.valueIncreasePercentage || 0))) /
                100 || 0
        );
    }

    // Warn the user that he may only rearrange the calculation items if no filter is acitve.
    public warnAboutDisabledDrag(): void {
        if (this.filter) {
            this.toastService.info(
                'Filter aktiv',
                'Bitte deaktiviere den Filter, um Elemente neu sortieren zu können.',
            );
        }
    }

    /**
     * Depending on the code, ...
     * - if there is a switch between spare part and labor costs, reset most values
     * - set the repair category (spare parts, labor costs, paint,...; this is comparable to selecting the section on the PDF document)
     *
     * @param event
     * @param calculationItem
     * @param repairCodeCategoryMap
     */
    public handleCodeSelection(
        event: MatOptionSelectionChange,
        calculationItem: ManualCalculationItem,
        repairCodeCategoryMap: RepairCodeCategoryMap,
    ): void {
        // Ignore the event for the de-selected option
        if (event && (!event.source.selected || !event.isUserInput)) return;

        // Reset unit price if we switch the category from a wage-based category to a price-based category.
        const oldCategoryIsParts =
            calculationItem.category === 'spareParts' || calculationItem.category === 'auxiliaryCosts';
        const newCategoryIsParts =
            repairCodeCategoryMap.category === 'spareParts' || repairCodeCategoryMap.category === 'auxiliaryCosts';

        if ((oldCategoryIsParts && !newCategoryIsParts) || (!oldCategoryIsParts && newCategoryIsParts)) {
            calculationItem.laborType = null;
        }

        // Labor Costs & paint: set wages automatically.
        // Car body is the most likely labor type, and most of the times, wages for mechanics, electrics and the car body barely differ within one garage.
        if (repairCodeCategoryMap.category === 'laborCosts' || repairCodeCategoryMap.category === 'paint') {
            const laborType: ManualCalculationItem['laborType'] =
                repairCodeCategoryMap.category === 'laborCosts' ? 'carBody_1' : 'carPaint';
            const garageWageMap = this.garageWageMaps.find((map) => map.laborType === laborType);

            if (garageWageMap) {
                calculationItem.laborType = garageWageMap.laborType;
                calculationItem.unitPrice = garageWageMap.hourlyWage;
            }
        }

        // Unset value increase for all categories that don't allow it
        if (
            !(
                repairCodeCategoryMap.category === 'paint' ||
                (repairCodeCategoryMap.category === 'spareParts' && repairCodeCategoryMap.code === 'E-spareParts')
            )
        ) {
            calculationItem.valueIncreasePercentage = 0;
        }

        calculationItem.category = repairCodeCategoryMap.category;
    }

    public getCalculationCategoryTranslation(category: RepairCategory): string {
        switch (category) {
            case 'spareParts':
                return 'Ersatzteile';
            case 'laborCosts':
                return 'Lohn';
            case 'auxiliaryCosts':
                return 'Nebenkosten';
            case 'paint':
                return 'Lackierung';
            default:
                return '';
        }
    }

    /**
     * Only return the letter of each repair Code.
     * The replacement repair codes ("E-spareParts", "E-laborCosts", "E-auxiliaryCosts") are shortened to "E".
     * @param repairCode
     */
    public getLetterOfRepairCode(repairCode: RepairCode): string {
        if (!repairCode) return '';

        return repairCode.split('-')[0];
    }

    public getRepairCodeSelectTriggerTooltip(calculationItem: ManualCalculationItem): string {
        if (calculationItem.repairCode && calculationItem.category) {
            return `${this.getRepairCodeLabel(
                calculationItem.repairCode,
            )} - Kategorie: ${this.getCalculationCategoryTranslation(calculationItem.category)}`;
        }
    }

    public getRepairCodeLabel(repairCode: RepairCode): string {
        const repairCodeMap: RepairCodeCategoryMap = this.repairCodeToCategoryMaps.find(
            (repairCodeMap) => repairCodeMap.code === repairCode,
        );

        if (repairCodeMap) {
            return repairCodeMap.label;
        }
        return '';
    }

    public setUnitPrice(
        event: MatOptionSelectionChange,
        calculationItem: ManualCalculationItem,
        unitPrice: number,
    ): void {
        // Ignore the event for the de-selected option
        if (!event.source.selected || !event.isUserInput) return;

        calculationItem.unitPrice = unitPrice;
    }

    public getUnitPriceTooltip(calculationItem: ManualCalculationItem): string {
        if (!calculationItem.laborType) return '';

        switch (calculationItem.laborType) {
            case 'mechanics_1':
                return 'Mechanik';
            case 'mechanics_2':
                return 'Mechanik 2';
            case 'mechanics_3':
                return 'Mechanik 3';
            case 'electrics_1':
                return 'Elektrik';
            case 'electrics_2':
                return 'Elektrik 2';
            case 'electrics_3':
                return 'Elektrik 3';
            case 'carBody_1':
                return 'Karosserie';
            case 'carBody_2':
                return 'Karosserie 2';
            case 'carBody_3':
                return 'Karosserie 3';
            case 'carPaint':
                return 'Lack';
            case 'dents':
                return 'Dellen';
        }
    }

    /**
     * Prevent that a click on the input field immediately triggers the outside click
     * handler of the description overlay (which closes the overlay again)
     */
    protected handleMouseDown(event: Event): void {
        event.preventDefault();
    }

    protected openDescriptionOverlay({
        calculationItem,
        matFormField,
        index,
    }: {
        calculationItem: ManualCalculationItem;
        matFormField: MatLegacyFormField;
        index: number;
    }): void {
        // First, close all open overlays
        this.closeDescriptionOverlay();

        const nativeFormFieldDomElement = matFormField._elementRef.nativeElement;

        this.descriptionInputOverlayRef = this.overlayService.create({
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(nativeFormFieldDomElement)
                .withPositions([{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }]),
            scrollStrategy: this.overlayService.scrollStrategies.close(),
            width: nativeFormFieldDomElement.offsetWidth,
        });

        const descriptionInputPortal = new ComponentPortal(ManualCalculationInputOverlayComponent);
        const componentRef = this.descriptionInputOverlayRef.attach(descriptionInputPortal);

        setTimeout(() => {
            // Wait for the overlay to render the multi-line textarea.
            // Otherwise the height of textarea is computed wrong (too high)
            componentRef.instance.calculationItem = calculationItem;
        }, 0);
        componentRef.instance.itemDescriptionAutocompleteEntries = this.itemDescriptionAutocompleteEntries;
        componentRef.instance.disabled = this.isReportLocked();
        componentRef.instance.onChange.subscribe(() => {
            this.saveChanges();
        });

        // Tab inside the description overlay does not work, because it is positioned at the very end of the DOM.
        // Hence, we imitate the behavior by listening for tab/shift+tab key presses and focus the input element before or
        // after the current description input field.
        componentRef.instance.onTabPressed.subscribe(({ direction }: { direction: TabDirection }) => {
            this.closeDescriptionOverlay();

            // Let's find the next/previous input field from the query list by using a custom data-attribute. This is
            // necessary because we can't use the index (because the query list only contains the rendered items of the virtual scroll area).
            if (direction === 'previous') {
                const previousFocusElement = this.totalPriceInputs.find(
                    (input) => input.elementRef.nativeElement.dataset.id === `${index - 1}`,
                );
                previousFocusElement?.focus();
            } else {
                this.repairCodeSelects
                    .find((select) => select._elementRef.nativeElement.dataset.id === `${index}`)
                    .focus();
            }
        });

        // Close panel when clicking outside.
        this.descriptionInputOverlayRef.outsidePointerEvents().subscribe(() => {
            this.closeDescriptionOverlay();
        });
    }

    public findItemDescriptionAutocompleteEntries(): void {
        this.customAutocompleteEntriesService.find({ type: 'manualCalculationItemDescription' }).subscribe({
            next: (entries) => {
                this.itemDescriptionAutocompleteEntries = entries;
                this.sortItemDescriptionAutocomplete();
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Beschreibungen nicht abgerufen',
                        body: "Die Vorlagen für die Beschreibungen konnten nicht vom Server geholt werden. Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                    },
                });
            },
        });
    }

    protected closeDescriptionOverlay(): void {
        if (this.descriptionInputOverlayRef) {
            this.descriptionInputOverlayRef.detach();
            this.descriptionInputOverlayRef.dispose();
        }
    }

    /**
     * Sort the original description array.
     *
     * This way, the filtered values will be automatically sorted too. Sorting on every filter cycle
     * would put unnecessary load on the client.
     */
    public sortItemDescriptionAutocomplete(): void {
        // Sort
        this.itemDescriptionAutocompleteEntries.sort((entryA, entryB) => entryA.value.localeCompare(entryB.value));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculation Items
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Results
    //****************************************************************************/
    public writeResultsToReport(): void {
        if (this.isReportLocked()) return;

        // Shortcut
        const damageCalculation: DamageCalculation = this.report.damageCalculation;

        // TODO Add bodyworkLaborHours, electricLaborHours, mechanicLaborHours.
        damageCalculation.repair.sparePartsCostsNet = this.sparePartsTotal;
        damageCalculation.repair.auxiliaryCostsNet = this.auxiliaryCostsTotal;
        damageCalculation.repair.garageLaborCostsNet = this.laborCostsTotal;
        damageCalculation.repair.garageLaborHours = this.garageLaborHours;
        damageCalculation.repair.lacquerMaterialCostsNet = this.paintMaterialTotal;
        damageCalculation.repair.lacquerCostsNet = this.paintLaborTotal + this.paintMaterialTotal;
        damageCalculation.repair.lacquerLaborHours = this.lacquerLaborHours;

        damageCalculation.repair.repairCostsNet = this.manualCalculationTotalNet;
        damageCalculation.repair.repairCostsGross = this.manualCalculationTotalGross;
        damageCalculation.repair.newForOldNet = this.valueIncreaseTotal;
        damageCalculation.repair.newForOldGross = this.valueIncreaseTotal * (1 + getVatRate());
        damageCalculation.repair.correctedRepairCostsNet = this.correctedManualCalculationTotalNet;
        damageCalculation.repair.correctedRepairCostsGross = this.correctedManualCalculationTotalGross;

        this.report.damageCalculation.repair.calculationProvider = 'manual';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Results
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CSV Import
    //****************************************************************************/
    public showManualCalculationImportDialog() {
        this.manualCaluclationImportDialogShown = true;
    }

    /**
     * Insert imported items into the list of items.
     */
    public insertImportedItems(items: ManualCalculationItem[]) {
        const calculationItems = this.report.damageCalculation.repair.manualCalculation.calculationItems;
        if (calculationItems?.length === 1 && !calculationItems[0].description) {
            // Remove the first empty line before importing
            removeFromArray(calculationItems[0], calculationItems);
        }

        // After import, the category is empty. Set it based on the maps defined in this component.
        for (const item of items) {
            this.setCategoryBasedOnCode(item);

            // Labor costs are currently calculated by reference of the fee set's wages. A unit price would be in conflict with that.
            if (item.category === 'laborCosts' || item.category === 'paint') {
                this.handleCodeSelection(
                    null,
                    item,
                    this.repairCodeToCategoryMaps.find((map) => map.code === item.repairCode),
                );
            }
        }
        this.report.damageCalculation.repair.manualCalculation.calculationItems.push(...items);
        this.toastService.success(`${items.length} Zeilen importiert`);
        this.filterCalculationItems();
        this.saveChanges();
    }

    private setCategoryBasedOnCode(item: ManualCalculationItem) {
        item.category = this.repairCodeToCategoryMaps.find((map) => map.code === item.repairCode)?.category;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CSV Import
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public saveChanges(): void {
        this.writeResultsToReport();
        this.reportChange.emit(this.report);
    }

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeManualCalculation();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcut(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeManualCalculation();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.closeManualCalculation();
                }
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Animations
    //****************************************************************************/
    // This binds the animation to the host element. The animation looks for the
    // .dialog-overlay and.dialog-container classes, animating both smoothly.
    @HostBinding('@slideOutDialogVertical') get slideOut() {
        return true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Animations
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly trackById = trackById;
    protected readonly convertLineFeedsToSpaces = convertLineFeedsToSpaces;
}

// Map of repair code to category
interface RepairCodeCategoryMap {
    code: RepairCode;
    category: RepairCategory;
    label: string;
}

interface GarageWageMap {
    laborType: ManualCalculationItem['laborType'];
    hourlyWage: number;
    level: RepairDifficultyLevel;
    label: string;
}

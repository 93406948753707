import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from 'src/app/shared/animations/fade-in-and-slide.animation';
import { slideInAndOutHorizontally } from 'src/app/shared/animations/slide-in-and-out-horizontally.animation';
import { CarEquipmentService } from 'src/app/shared/services/car-equipment.service';
import { CopyDatDossierService } from 'src/app/shared/services/copy/copy-dat-dossier.service';
import { CopyMarketAnalysisDocumentsToReportService } from 'src/app/shared/services/copy/copy-market-analysis-documents-to-report.service';
import { CopyPhotosToReportService } from 'src/app/shared/services/copy/copy-photos-to-report.service';
import { CopyResidualValueDocumentsToReportService } from 'src/app/shared/services/copy/copy-residual-value-documents-to-report.service';
import { CopyUserUploadedDocumentsToReportService } from 'src/app/shared/services/copy/copy-user-uploaded-documents-to-report.service';
import {
    CopyOptions,
    CurrentStep,
    ReportCopy,
    getReportCopyErrorHandlers,
} from 'src/app/shared/services/copy/report-copy';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { getMissingAccessRightTooltip } from '../../shared/libraries/get-missing-access-right-tooltip';
import { reportHasCalculation } from '../../shared/libraries/report-has-calculation';
import { triggerClickEventOnSpaceBarPress } from '../../shared/libraries/trigger-click-event-on-space-bar-press';
import { hasAccessRight } from '../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { CopyClaimantSignaturesToReportService } from '../../shared/services/copy/copy-claimant-signatures-to-report.service';
import { DocumentOrderConfigService } from '../../shared/services/document-order-config.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { TeamService } from '../../shared/services/team.service';
import { ToastService } from '../../shared/services/toast.service';

@Component({
    selector: 'copy-report-dialog',
    templateUrl: 'copy-report-dialog.component.html',
    styleUrls: ['copy-report-dialog.component.scss'],
    animations: [slideInAndOutHorizontally(), fadeInAndSlideAnimation()],
})
export class CopyReportDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<CopyReportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: CopyReportDialogComponentConfig,
        private reportService: ReportService,
        private teamService: TeamService,
        private loggedInUserService: LoggedInUserService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private router: Router,
        private networkStatusService: NetworkStatusService,
        private copyPhotosToReportService: CopyPhotosToReportService,
        private copyClaimantSignaturesToReportService: CopyClaimantSignaturesToReportService,
        private carEquipmentService: CarEquipmentService,
        private copyDatDossierService: CopyDatDossierService,
        private copyResidualValueDocumentsService: CopyResidualValueDocumentsToReportService,
        private copyMarketAnalysisDocumentsService: CopyMarketAnalysisDocumentsToReportService,
        private copyUserUploadedDocumentsService: CopyUserUploadedDocumentsToReportService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    public user: User;
    public team: Team;

    public sourceReport: Report;

    private subscriptions: Subscription[] = [];

    /**
     * Whether to create an amendment report instead of a simple copy.
     */
    public createAmendmentReport: boolean;

    /**
     * Whether to create an invoiceAudit  instead of a simple copy.
     */
    public createInvoiceAudit: boolean;

    // Used to focus the invoice number element after retrieving an invoice number.
    @ViewChild('invoiceNumberElement') invoiceNumberElement: ElementRef;

    // Copy intention
    public copyIntention: 'basicData' | 'custom';

    // Copy options
    public copyPhotos: boolean;
    public copyClaimantSignatures: boolean;
    public copyCalculation: boolean;
    public copyDamageDescription: boolean;
    public copyResidualValueOffer: boolean;
    public copyMarketAnalysis: boolean;

    // Report Token Suffix
    public copyReportToken: boolean;
    public reportTokenSuffix: string;

    // Invoice
    public activateInvoice: boolean;
    public copyInvoiceNumber: boolean;
    public invoiceNumberSuffix: string;

    // Copy Process
    public copyInProgress: boolean;
    public currentCopyStep: CurrentStep;

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        this.sourceReport = this.dialogData.sourceReport;
        this.createAmendmentReport = this.dialogData.createAmendmentReport;
        this.createInvoiceAudit = this.dialogData.createInvoiceAudit;

        if (this.createAmendmentReport || this.createInvoiceAudit) {
            this.copyIntention = 'custom';
        } else {
            this.copyIntention = 'basicData';
        }

        // Enable copysteps based on report content
        this.copyPhotos = this.reportHasPhotos();
        this.copyClaimantSignatures = this.reportHasClaimantSignatures();
        this.copyCalculation = this.reportHasCalculation();
        this.copyDamageDescription = this.reportHasDamageDescription();

        // Load team defaults for invoice number and report token suffix
        this.loadTeamDefaults();
    }

    /**
     * This function loads the team defaults for invoice number and report token suffix and activation.
     */
    public loadTeamDefaults() {
        if (this.createAmendmentReport) {
            this.invoiceNumberSuffix = this.team.preferences.amendmentReportInvoiceNumberSuffix ?? '';
            this.reportTokenSuffix = this.team.preferences.amendmentReportTokenSuffix ?? '';
            this.activateInvoice = this.team.preferences.amendmentInvoiceShallBeActivatedOnCopy ?? true;
            this.copyInvoiceNumber = this.team.preferences.amendmentInvoiceWithInvoiceNumber ?? true;
            this.copyReportToken = this.team.preferences.amendmentReportWithReportToken ?? true;
        } else if (this.createInvoiceAudit) {
            this.invoiceNumberSuffix = this.team.preferences.invoiceAuditInvoiceNumberSuffix ?? '';
            this.reportTokenSuffix = this.team.preferences.invoiceAuditReportTokenSuffix ?? '';
            this.activateInvoice = this.team.preferences.invoiceAuditInvoiceShallBeActivatedOnCopy ?? true;
            this.copyInvoiceNumber = this.team.preferences.invoiceAuditInvoiceWithInvoiceNumber ?? true;
            this.copyReportToken = this.team.preferences.invoiceAuditWithReportToken ?? true;
        }
    }

    //*****************************************************************************
    //  Access Rights
    //****************************************************************************/
    public userIsAdmin(): boolean {
        return isAdmin(this.user._id, this.team);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Access Rights
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Content Options for Copy
    //****************************************************************************/
    public reportHasPhotos(): boolean {
        return !!this.sourceReport.photos.length;
    }

    public reportHasClaimantSignatures(): boolean {
        return !!(
            this.sourceReport.signableDocuments.length &&
            this.sourceReport.signableDocuments.some(
                (signableDocument) =>
                    signableDocument.signatures?.length &&
                    signableDocument.signatures.some((signature) => signature.hash),
            )
        );
    }

    public toggleCalculation() {
        // If the report doesn't have a calculation, don't allow activating it.
        if (!this.copyCalculation && !this.reportHasCalculation()) {
            this.toastService.info(
                'Kalkulation fehlt',
                'Der Vorgang hat keine Kalkulation, deshalb kannst du die Option nicht aktivieren.',
            );
            return;
        }
        this.copyCalculation = !this.copyCalculation;
    }

    public reportHasCalculation(): boolean {
        return reportHasCalculation(this.sourceReport);
    }

    public reportHasDamageDescription(): boolean {
        return !!this.sourceReport.car.damageDescription;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Content Options for Copy
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Token
    //****************************************************************************/

    public async rememberReportTokenSettings() {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        if (this.createAmendmentReport) {
            this.team.preferences.amendmentReportWithReportToken = this.copyReportToken;
        } else if (this.createInvoiceAudit) {
            this.team.preferences.invoiceAuditWithReportToken = this.copyReportToken;
        }
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Aktivierung der Rechnung nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public async rememberSuffix(suffix: string, suffixType: 'reportTokenSuffix' | 'invoiceNumberSuffix') {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        switch (suffixType) {
            case 'reportTokenSuffix':
                if (this.createAmendmentReport) {
                    this.team.preferences.amendmentReportTokenSuffix = suffix;
                } else if (this.createInvoiceAudit) {
                    this.team.preferences.invoiceAuditReportTokenSuffix = suffix;
                }
                break;
            case 'invoiceNumberSuffix':
                if (this.createAmendmentReport) {
                    this.team.preferences.amendmentReportInvoiceNumberSuffix = suffix;
                } else if (this.createInvoiceAudit) {
                    this.team.preferences.invoiceAuditInvoiceNumberSuffix = suffix;
                }
                break;
        }

        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Suffix nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public getReportTokenWithSuffix(): string {
        return `${this.sourceReport.token || ''}${this.reportTokenSuffix || ''}`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Token
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Number
    //****************************************************************************/

    public getInvoiceNumberWithSuffix(): string {
        return `${this.sourceReport.feeCalculation.invoiceParameters.number || ''}${this.invoiceNumberSuffix || ''}`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Number
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Activation
    //****************************************************************************/

    public async rememberInvoiceActivationSettings() {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        if (this.createAmendmentReport) {
            this.team.preferences.amendmentInvoiceShallBeActivatedOnCopy = this.activateInvoice;
            this.team.preferences.amendmentInvoiceWithInvoiceNumber = this.copyInvoiceNumber;
        } else if (this.createInvoiceAudit) {
            this.team.preferences.invoiceAuditInvoiceShallBeActivatedOnCopy = this.activateInvoice;
            this.team.preferences.invoiceAuditInvoiceWithInvoiceNumber = this.copyInvoiceNumber;
        }
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Aktivierung der Rechnung nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Activation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Copy Process
    //****************************************************************************/
    public async startCopyProcess() {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                `${
                    this.createAmendmentReport ? 'Nachträge' : this.createInvoiceAudit ? 'Rechnungsprüfungen' : 'Kopien'
                } können angelegt werden, sobald du wieder online bist, weil auch Fotos & die Schadenkalkulation mitkopiert werden können. Diese brauchen eine Server-Verbindung.`,
            );
            return;
        }

        this.copyInProgress = true;

        /**
         * Refactored the ReportCopy code in a class.
         * This function keeps the state of the copy process, therefore it is no injectable service.
         * The needed services have to be injected here.
         */
        const reportCopy = new ReportCopy(
            this.reportService,
            this.copyPhotosToReportService,
            this.copyClaimantSignaturesToReportService,
            this.copyDatDossierService,
            this.copyResidualValueDocumentsService,
            this.copyMarketAnalysisDocumentsService,
            this.copyUserUploadedDocumentsService,
            this.carEquipmentService,
            this.loggedInUserService,
            this.documentOrderConfigService,
        );

        const currentCopyStepSubscription = reportCopy.listenOnChangedCounter().subscribe({
            next: (currentCopyStep) => {
                this.currentCopyStep = currentCopyStep;
            },
        });

        /**
         * Define which information is copied.
         */
        const copyOptions = new CopyOptions({
            copySteps: {
                PHOTOS: this.copyPhotos,
                CLAIMANT_SIGNATURES: this.copyClaimantSignatures,
                CALCULATION: this.copyCalculation,
                RESIDUAL_VALUE_OFFERS: this.copyResidualValueOffer,
                MARKET_ANALYSIS: this.copyMarketAnalysis,
                DAMAGE_DESCRIPTION: this.copyDamageDescription,
            },
            intention: this.copyIntention,
        });

        /**
         * Allow the user to copy / extend the invoice number and report token from the source report.
         */
        if (this.createAmendmentReport || this.createInvoiceAudit) {
            copyOptions.invoiceNumberSuffix = this.invoiceNumberSuffix;
            copyOptions.reportTokenSuffix = this.reportTokenSuffix;
            copyOptions.activateInvoice = this.activateInvoice;
            copyOptions.copyInvoiceNumber = this.copyInvoiceNumber;
            copyOptions.copyReportToken = this.copyReportToken;
        }

        /**
         * Create the new report
         */
        let newReport: Report;
        try {
            if (this.createAmendmentReport) {
                newReport = await reportCopy.createAmendmentReport(this.sourceReport, copyOptions);
            } else if (this.createInvoiceAudit) {
                newReport = await reportCopy.createInvoiceAudit(this.sourceReport, copyOptions);
            } else {
                newReport = await reportCopy.copy(this.sourceReport, copyOptions);
            }
        } catch (error) {
            this.copyInProgress = false;
            this.currentCopyStep = undefined;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: getReportCopyErrorHandlers(),
                defaultHandler: {
                    title: 'Fehler beim Kopieren',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }

        if (newReport.feeCalculation.invoiceParameters.number) {
            this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceNumberGeneratedOnAmendmentReportCreation',
                documentType: 'report',
                reportId: newReport._id,
                invoiceNumber: newReport.feeCalculation.invoiceParameters.number,
            });
        }

        this.copyInProgress = false;
        currentCopyStepSubscription.unsubscribe();
        this.currentCopyStep = undefined;

        this.router.navigate(['/Gutachten', newReport._id]);
        return this.dialogRef.close();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy Process
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog() {
        this.dialogRef.close();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Events
    //****************************************************************************/
    public triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                event.stopPropagation();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.startCopyProcess();
                    event.stopPropagation();
                }
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

export interface CopyReportDialogComponentConfig {
    sourceReport: Report;
    createAmendmentReport?: boolean;
    createInvoiceAudit?: boolean;
}

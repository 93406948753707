import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { DocumentBuildingBlock } from '../../models/documents/document-building-block';
import { replacePlaceholders } from '../documents/replace-document-building-block-placeholders';

export function renderDocumentBuildingBlock({
    documentBuildingBlock,
    placeholderValues,
    fieldGroupConfigs,
}: {
    documentBuildingBlock: DocumentBuildingBlock;
    placeholderValues: any;
    fieldGroupConfigs: FieldGroupConfig[];
}): RenderedDocumentBuildingBlockDocxTemplater {
    const headings = [];
    const contentSnippets = [];
    for (const variant of documentBuildingBlock.variants) {
        // There are variants without a heading and without content. Do not add them to the array or they will be joined with spaces further down. If they are queried
        // for empty or not empty in the document, a string like " " (two empty headings joined by a space), will still count as true. That's not the intention.
        if (variant.heading) {
            headings.push(variant.heading);
        }
        if (variant.content) {
            contentSnippets.push(variant.content);
        }
    }

    let headline = headings.length ? headings.join(' ') : '';
    let content = contentSnippets.length ? contentSnippets.join(' ') : '';

    headline = replacePlaceholders({
        textWithPlaceholders: headline,
        placeholderValues,
        fieldGroupConfigs,
        isHtmlAllowed: false,
    });
    content = replacePlaceholders({
        textWithPlaceholders: content,
        placeholderValues,
        fieldGroupConfigs,
    });

    return {
        Überschrift: headline,
        Inhalt: content,
    };
}

/**
 * These objects are passed to the DOCX Templater. The user can not influence their internal structure but he can sort
 * them in the document order structure.
 */
export interface RenderedDocumentBuildingBlockDocxTemplater {
    Überschrift: string;
    Inhalt: string;
}

import { Invoice } from '../../models/invoices/invoice';
import { InvoiceTypeGerman, getInvoiceTypeGerman } from './get-invoice-type-german';

export function determineDocumentMetadataTitle(invoice: Invoice): void {
    const invoiceDocumentTargetTitle: InvoiceTypeGerman = getInvoiceTypeGerman(invoice);

    const invoiceDocument = invoice.documents.find((document) => document.type === 'invoice');
    if (invoiceDocument.title !== invoiceDocumentTargetTitle) {
        invoiceDocument.title = invoiceDocumentTargetTitle;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    SourceAndTargetUploadedDocumentIds,
    UserUploadedDocumentCopyResponse,
} from '@autoixpert/lib/documents/document-copy.types';

@Injectable()
export class CopyUserUploadedDocumentsToReportService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public copyUserUploadedDocuments({
        sourceReportId,
        targetReportId,
        sourceAndTargetUploadedDocumentIds,
    }: {
        sourceReportId: string;
        targetReportId: string;
        sourceAndTargetUploadedDocumentIds: SourceAndTargetUploadedDocumentIds[];
    }): Promise<UserUploadedDocumentCopyResponse> {
        return this.httpClient
            .post<UserUploadedDocumentCopyResponse>(
                `${this.apiPath}/reports/${sourceReportId}/userUploadedDocumentsCopy`,
                {
                    sourceReportId,
                    targetReportId,
                    sourceAndTargetUploadedDocumentIds,
                },
            )
            .toPromise();
    }
}

import { generateId } from '../../lib/generate-id';

/**
 * Used to capture if a user is a member of an association.
 * That's important information for our sales and marketing pipelines.
 */
export class ProfessionalAssociation {
    constructor(template: Partial<ProfessionalAssociation> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    organization: ProfessionalAssociationName | string;
    type: 'networks' | 'certificates' | 'association' | 'customAssociation' | 'customNetwork';
}

export type ProfessionalAssociationName =
    | 'bvsk'
    | 'vks'
    | 'gtü'
    | 'datExpertPartner'
    | 'classicData'
    | 'tüvNord'
    | 'tüvRheinland'
    | 'ifs'
    | 'zak';

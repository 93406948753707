import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySelectChange } from '@angular/material/legacy-select/index';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { generateId } from '@autoixpert/lib/generate-id';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { InvoiceNumberOrReportTokenCounter } from '@autoixpert/models/teams/invoice-number-or-report-token-counter';
import { InvoiceNumberConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { InvoiceNumberOrReportTokenCounterService } from '../../../shared/services/invoice-number-or-report-token-counter.service';
import { TeamService } from '../../../shared/services/team.service';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
    selector: 'invoice-number-preferences',
    templateUrl: './invoice-number-preferences.component.html',
    styleUrls: ['./invoice-number-preferences.component.scss'],
    animations: [slideInAndOutVertically()],
})
export class InvoiceNumberPreferencesComponent implements OnInit {
    constructor(
        private toastService: ToastService,
        private teamService: TeamService,
        private counterService: InvoiceNumberOrReportTokenCounterService,
        private apiErrorService: ApiErrorService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    @Input() public user: User;
    @Input() public team: Team;
    @Input() public teamMembers: User[];

    @Output() scrollToReportTokenSettings: EventEmitter<void> = new EventEmitter<void>();

    protected invoiceNumberConfigs: InvoiceNumberConfig[] = [];
    /**
     * Expanded state of invoice number config sections per office location.
     * This is only relevant if more than two invoice number configs exist.
     * Each record stores the expanded state (boolean) under the configs key (string).
     */
    protected invoiceNumberConfigExpandedStates: Record<string, boolean> = {};

    public invoiceNumberConfigTitlesInEditMode: Map<InvoiceNumberConfig, void> = new Map();

    /**
     * If there are more than this amount of counter configs, we collapse them by default to save space.
     * They can then be expanded and collapsed by the user.
     */
    protected collapseThreshold = 3;

    protected previousCancellationSuffix: string;

    //*****************************************************************************
    //  Initialize
    //****************************************************************************/
    async ngOnInit(): Promise<void> {
        this.invoiceNumberConfigs = this.team.invoicing.invoiceNumberConfigs;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialize
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * User changed the selection for how cancellation invoice numbers should be generated (suffix or regular number)
     */
    protected async cancellationInvoiceSettingsChanged(
        change: MatLegacySelectChange,
        config: InvoiceNumberConfig,
    ): Promise<void> {
        const useSuffix = change.value === 'suffix';
        config.useCancellationInvoiceSuffix = useSuffix;

        if (useSuffix) {
            // Focus the input field for the suffix
            setTimeout(() => {
                const preferencesRow = change.source._elementRef.nativeElement.closest('preferences-row');
                preferencesRow.querySelector('#suffix-input-field').focus();
            }, 0);

            if (!config.cancellationSuffix) {
                // Provide a default, so users don't forget to set one
                config.cancellationSuffix = '-Storno';
            }
        }

        await this.saveTeam();

        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            useCancellationInvoiceSuffix: useSuffix,
            invoiceNumberConfigId: config._id,
        });
    }

    protected async handleCancellationSuffixChange(invoiceNumberConfig: InvoiceNumberConfig): Promise<void> {
        await this.saveTeam();
        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            cancellationSuffix: invoiceNumberConfig.cancellationSuffix,
            previousCancellationSuffix: this.previousCancellationSuffix,
            invoiceNumberConfigId: invoiceNumberConfig._id,
        });
    }

    //*****************************************************************************
    //  CRUD InvoiceNumberConfig
    //****************************************************************************/
    public async addInvoiceNumberConfig() {
        const newCounter = new InvoiceNumberOrReportTokenCounter({ _id: generateId() });
        try {
            await this.counterService.create(newCounter);
            await this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceOrReportCounterCreated',
                invoiceNumberConfigId: newCounter._id,
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Zähler nicht angelegt',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }

        const newInvoiceNumberConfig = new InvoiceNumberConfig({ _id: newCounter._id });
        this.invoiceNumberConfigs.push(newInvoiceNumberConfig);
        void this.saveTeam();
    }

    public async deleteInvoiceNumberConfig(invoiceNumberConfig: InvoiceNumberConfig) {
        if (invoiceNumberConfig.isDefault) {
            this.toastService.error(
                'Rechnungskreis ist Standard',
                'Der Standard-Rechnungskreis kann nicht gelöscht werden. Bitte ändere den Standard und probiere das Löschen erneut.',
            );
            return;
        }

        // Delete counter
        await this.counterService.delete(invoiceNumberConfig._id);

        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterDeleted',
            invoiceNumberConfigId: invoiceNumberConfig._id,
        });

        // Delete config from team
        removeFromArray(invoiceNumberConfig, this.invoiceNumberConfigs);
        this.saveTeam();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD InvoiceNumberConfig
    /////////////////////////////////////////////////////////////////////////////*/

    protected isConfigOverwrittenByReportTokenSettings(): boolean {
        return this.team.reportTokenConfigs.some(
            (reportTokenConfig) =>
                reportTokenConfig.syncWithInvoiceCounter && reportTokenConfig.leadingCounter === 'reportToken',
        );
    }

    /**
     * Get a string that lists all office locations (separated with a comma) for the given invoice
     * counter.
     */
    protected getOfficeLocationTitle(counterConfig: InvoiceNumberConfig): string {
        return (
            this.team.officeLocations
                .filter((location) => counterConfig.associatedOfficeLocationIds.includes(location._id))
                .map((location) => location.title)
                .join(', ') || (counterConfig.isDefault ? 'Standard' : 'Alle anderen Standorte')
        );
    }

    //*****************************************************************************
    //  Title Edit Mode
    //****************************************************************************/
    startTitleEditMode(invoiceNumberConfig: InvoiceNumberConfig) {
        this.invoiceNumberConfigTitlesInEditMode.set(invoiceNumberConfig, undefined);
    }

    leaveTitleEditMode(invoiceNumberConfig: InvoiceNumberConfig) {
        this.invoiceNumberConfigTitlesInEditMode.delete(invoiceNumberConfig);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Title Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handlers - could we optimize this?
    //****************************************************************************/
    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public userIsAdmin(): boolean {
        return isAdmin(this.user._id, this.team);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handlers - can we optimize this?
    /////////////////////////////////////////////////////////////////////////////*/
}

import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, ElementRef, HostBinding, HostListener, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import { isInsideOfficeHours } from '@autoixpert/lib/support/is-inside-office-hours';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../shared/animations/fade-in-and-slide.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { slideOutDialogVertical } from '../../shared/animations/slide-out-dialog-vertical.animation';
import { slideOutLeft } from '../../shared/animations/slide-out-left.animation';
import { slideOutRight } from '../../shared/animations/slide-out-right.animation';
import { getAnydeskLink } from '../../shared/libraries/device-detection/get-anydesk-link';
import { getProductName } from '../../shared/libraries/get-product-name';
import { AppVersionUpdateService } from '../../shared/services/app-version-update.service';
import { AppVersionService } from '../../shared/services/app-version.service';
import { HelpcenterSearchService, ZendeskHelpcenterArticle } from '../../shared/services/helpcenter-search.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';
import { HELP_PANEL_START_PAGE_TOKEN } from './help-panel.tokens';

@Component({
    selector: 'help-panel',
    templateUrl: 'help-panel.component.html',
    styleUrls: ['help-panel.component.scss'],
    animations: [
        slideOutLeft(),
        slideOutRight(),
        slideOutDialogVertical(),
        slideInAndOutVertically(),
        fadeInAndSlideAnimation(),
    ],
})
export class HelpPanelComponent {
    constructor(
        private overlayRef: OverlayRef,
        private loggedInUserService: LoggedInUserService,
        private helpcenterSearchService: HelpcenterSearchService,
        private toastService: ToastService,
        @Inject(HELP_PANEL_START_PAGE_TOKEN) public startPage: HelpPanelPage,
        private tutorialStateService: TutorialStateService,
        private appVersionService: AppVersionService,
        private appVersionUpdateService: AppVersionUpdateService,
        private dialog: MatDialog,
    ) {}

    public user: User;

    // Search
    public searchTerm: string;
    public searchPending: boolean = false;
    public searchResults: ZendeskHelpcenterArticle[] = [];
    public searchTerm$: Subject<string> = new Subject();

    // Get in touch
    public page: HelpPanelPage = 'main';
    public referralSubject: string = '';
    public referralMessage: string = '';
    public productName = getProductName();

    /**
     * Is a child dialog like a prompt open?
     * If so, this blocks closing this panel by hitting escape because the user would
     * expect closing the dialog only.
     */
    private childDialogOpen: boolean = false;

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

    private subscriptions: Subscription[] = [];

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscribeToSearchTermSubject();
        this.page = this.startPage;
    }

    ngAfterViewInit() {
        this.searchInput.nativeElement.focus();
    }

    //*****************************************************************************
    //  Search
    //****************************************************************************/
    public subscribeToSearchTermSubject(): void {
        const subscription: Subscription = this.searchTerm$
            .pipe(
                filter((searchTerm) => {
                    if (searchTerm && searchTerm.length > 2) {
                        return true;
                    } else {
                        this.searchResults = [];
                        return false;
                    }
                }),
                tap(() => (this.searchPending = true)),
                debounceTime(300),
            )
            .subscribe((searchTerm) => this.searchHelpcenter(searchTerm));

        this.subscriptions.push(subscription);
    }

    public updateSearchTerm(searchTerm: string): void {
        this.searchTerm$.next(searchTerm);
    }

    public searchHelpcenter(searchTerm: string): void {
        this.searchPending = true;

        this.helpcenterSearchService.find(searchTerm).subscribe({
            next: (articles) => {
                this.page = 'main';
                this.searchResults = articles;
                this.searchPending = false;

                this.tutorialStateService.markUserTutorialStepComplete('helpcenterSearched');
            },
            error: (error) => {
                this.toastService.error('Suche fehlgeschlagen');
                this.searchPending = false;
            },
        });
    }

    public saveClickedLinkAnalytics(clickedLink: string) {
        this.helpcenterSearchService.setClickedLinkOnHelpCenterQuery(clickedLink).subscribe();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Search
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tutorial
    //****************************************************************************/
    public openIntroVideosPanel() {
        this.tutorialStateService.openIntroVideosPanel();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tutorial
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Get in touch
    //****************************************************************************/
    public showMainPage(): void {
        this.page = 'main';
    }

    public async confirmToShowGetInTouchPage() {
        this.childDialogOpen = true;

        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Wissens-Datenbank',
                    content:
                        'Kennst du schon unsere Wissens-Datenbank?\n\nDarin sind die meisten Funktionen mit Bildern und Kurzvideos dokumentiert.\n\n💡 Auch mit Google durchsuchbar!',
                    confirmLabel: 'Probiere ich aus!',
                    cancelLabel: 'Kontakt',
                    confirmColorRed: false,
                    headerImagePath: '/assets/images/illustrations/3d-books-and-bulbs_600-blue.jpg',
                    headerImageMaxHeight: '190px',
                },
                panelClass: 'dialog-without-padding',
            })
            .afterClosed()
            .toPromise();
        if (decision === false) {
            this.showGetInTouchPage();
        } else if (decision === true) {
            window.open('https://wissen.autoixpert.de');
        }

        this.childDialogOpen = false;
    }

    public showGetInTouchPage(): void {
        this.page = 'getInTouch';
    }

    public showReferralPage(): void {
        this.page = 'referral';
        this.referralSubject = '';
        this.referralMessage = '';
    }

    public isQapterixpert() {
        return isQapterixpert();
    }

    public getAnydeskLink(): string {
        return getAnydeskLink();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get in touch
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Referral
    //****************************************************************************/
    public openRecommendationEmail(): void {
        const subject = 'Probier mal autoiXpert aus!';
        const message: string = `Hi!
Ich nutze autoiXpert für meine Arbeit als Sachverständiger und kann es wärmstens weiter empfehlen. Das Programm ist echt schnell, lässt sich gut bedienen und spart mir eine Menge Arbeit.

Jeder kann autoiXpert für 30 Tage kostenlos testen. Wenn du die Software danach buchen möchtest und mich gegenüber den Jungs von autoiXpert als Empfehler nennst, bekommst Du 50 € auf dein Konto überwiesen.

Hier kannst du dir autoiXpert ansehen: https://www.autoixpert.de?utm_source=Empfehlung&utm_medium=email

Viele Grüße
${this.user.firstName} ${this.user.lastName}`;

        const link = `mailto:dein@bekannter.de?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
            message,
        )}`;
        const mailtoWindow = window.open(link);

        if (!mailtoWindow) {
            this.referralSubject = subject;
            this.referralMessage = message;
        }
        // If the window opened successfully, check if a forward has taken place to the user's e-mail program.
        else {
            setTimeout(() => {
                try {
                    if (
                        mailtoWindow &&
                        (mailtoWindow.location.href.startsWith('mailto') ||
                            mailtoWindow.location.href === 'about:blank')
                    ) {
                        this.referralSubject = subject;
                        this.referralMessage = message;
                    }
                } catch (e) {
                    console.log(
                        "Couldn't access the child window of the recommendation email (mailto link). Caught & swallowed the browser error about cross origin frames.",
                    );
                }
            }, 1000);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Referral
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public close(): void {
        this.overlayRef.detach();
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Frontend Version
    //****************************************************************************/
    get currentVersion() {
        return this.appVersionService.currentVersion?.appData;
    }

    get latestVersion() {
        return this.appVersionService.latestVersion?.appData;
    }

    updateToLatestVersion() {
        this.appVersionUpdateService.warnUserAndReload();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Frontend Version
    /////////////////////////////////////////////////////////////////////////////*/

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                if (this.childDialogOpen) return;
                this.close();
                break;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected readonly isInsideOfficeHours = isInsideOfficeHours;
}

export type HelpPanelPage = 'main' | 'getInTouch' | 'referral';

import { getFullUserContactPerson } from '@autoixpert/lib/users/get-full-user-contact-person';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';

/**
 * Get the zip code for the dekra fees:
 * 1. explicit set customDekraFeeZip
 * 2. claimant zip
 * 3. zip from the office location of the report (fallback of the user)
 */
export function getZipForDekraFees({ user, team, report }: { user: User; team: Team; report: Report }): string {
    return (
        report.damageCalculation.repair.customDekraFeeZip ||
        report.claimant.contactPerson.zip ||
        getFullUserContactPerson({
            user,
            officeLocations: team.officeLocations,
            officeLocationId: report.officeLocationId,
        }).zip
    );
}

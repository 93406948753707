import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PasswordResetService {
    constructor(private httpClient: HttpClient) {}

    private apiPath: string = '/api/v0/passwordResetRequests';

    public requestPasswordReset(email: string): Observable<RequestPasswordResponse> {
        if (!email) {
            throw Error('NO_EMAIL. Please provide an email address in order to reset a password.');
        }

        return this.httpClient.post<RequestPasswordResponse>(this.apiPath, {
            email,
        });
    }

    public setNewPassword(resetRequestId: string, password: string): Observable<SetNewPasswordResponse> {
        if (!password) {
            throw Error('NO_PASSWORD. Please provide a password to be set.');
        }

        return this.httpClient.patch<SetNewPasswordResponse>(`${this.apiPath}/${resetRequestId}`, {
            password,
        });
    }
}

interface RequestPasswordResponse {
    success: boolean;
}

interface SetNewPasswordResponse {
    _id: string;
    used: boolean;
    email: string;
    createdAt: string;
    updatedAt: string;
}

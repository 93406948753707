import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { CarEquipment } from '@autoixpert/models/reports/car-identification/car-equipment';
import { GtmotiveEstimateListItem } from '@autoixpert/models/reports/damage-calculation/gtmotive-estimate-list-item';
import { Repair } from '@autoixpert/models/reports/damage-description/repair';
import { Report } from '@autoixpert/models/reports/report';

/**
 * An "estimate" in GT Motive's language is a process. It contains both the VIN data and the repair calculation data.
 */
@Injectable()
export class GtmotiveEstimateService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public create(reportId: Report['_id']): Promise<GtmotiveEstimateCreationResponse> {
        return this.httpClient
            .post<GtmotiveEstimateCreationResponse>(`${this.apiPath}/reports/${reportId}/gtmotive/estimate`, {})
            .toPromise();
    }

    public patch(
        reportId: Report['_id'],
        gtmotiveEstimateId: Report['gtmotiveEstimateId'],
        updateToGetUserInterfaceUrl?: boolean,
    ): Promise<GtmotivePatchResponse> {
        return this.httpClient
            .patch<GtmotivePatchResponse>(
                `${this.apiPath}/reports/${reportId}/gtmotive/estimate/${gtmotiveEstimateId}`,
                {},
                {
                    params: updateToGetUserInterfaceUrl ? { updateToGetUserInterfaceUrl } : undefined,
                },
            )
            .toPromise();
    }

    public find(
        reportId: Report['_id'],
        {
            importCalculationDocument,
        }: {
            /**
             * This flag is relevant if a repair calculation is imported so the document is imported, too.
             * This flag should not be set if car data is imported to improve performance.
             */
            importCalculationDocument?: boolean;
        } = {},
    ): Promise<GtmotiveEstimateFindResponse> {
        let httpParams = new HttpParams();
        if (importCalculationDocument) {
            httpParams = httpParams.append('importCalculationDocument', 'true');
        }

        return this.httpClient
            .get<GtmotiveEstimateFindResponse>(`${this.apiPath}/reports/${reportId}/gtmotive/estimate`, {
                params: httpParams,
            })
            .toPromise();
    }

    public findAll(): Promise<GtmotiveEstimateListItem[]> {
        return this.httpClient.get<GtmotiveEstimateListItem[]>(`${this.apiPath}/gtmotive/estimates`).toPromise();
    }

    public delete(reportId: Report['_id']): Promise<any> {
        return this.httpClient.delete<any>(`${this.apiPath}/reports/${reportId}/gtmotive/estimate`).toPromise();
    }
}

export interface GtmotiveEstimateCreationResponse {
    gtmotiveEstimateId: string;
}

export interface GtmotiveEstimateFindResponse {
    gtmotiveEstimateId: string;
    car: Car;
    repair: Repair;
    carEquipment: CarEquipment;
}

export interface GtmotivePatchResponse {
    gtmotiveUserInterfaceUrl: string;
}

export function getContactPersonFullNameWithOrganization(
    names: { organization: string; firstName: string; lastName: string },
    delimiterAfterOrganization: string = ' ',
): string {
    const nameParts: string[] = [];

    if (names.organization) {
        nameParts.push(names.organization);
    }

    if (names.firstName || names.lastName) {
        nameParts.push(`${names.firstName || ''} ${names.lastName || ''}`.trim());
    }
    return nameParts.join(delimiterAfterOrganization);
}

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { getFullName } from '@autoixpert/lib/placeholder-values/get-full-name';
import { User } from '@autoixpert/models/user/user';

@Component({
    selector: 'user-initials-edit-dialog',
    templateUrl: './user-initials-edit-dialog.component.html',
    styleUrls: ['./user-initials-edit-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class UserInitialsEditDialogComponent {
    constructor() {}

    @Input() user: User;

    @Output() userChange: EventEmitter<User> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    get userFullName(): string {
        return getFullName(this.user);
    }

    public emitChange() {
        this.userChange.emit(this.user);
    }

    public closeDialog(): void {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }
}

<div id="VIN-container">
    <mat-form-field class="mat-block">
        <input
            matInput
            id="vin-input"
            placeholder="Fahrgestellnummer (VIN)"
            [(ngModel)]="car.vin"
            (keydown)="replaceInvalidVinCharacters($event)"
            [disabled]="disabled"
            (input)="clearPreviousAndLegacyReports(); transformVinToUppercase(); findPreviousReports()"
            (focus)="emitFocusEvent($event)"
            (change)="errorMessage = ''; warningMessage = ''; validateVINFormat()"
            maxlength="17"
            (keyup)="triggerVINVerificationOnEnter($event)"
            matTooltip="Im Fahrzeugschein: Feld 'E'"
            [axAutofocus]="autofocus" />
    </mat-form-field>
    <div class="input-toolbar">
        <div
            id="vin-check-digit"
            *ngIf="getCheckDigit(car.vin)"
            matTooltip="VIN-Prüfziffer, Im Fahrzeugschein: Feld '3'">
            {{ getCheckDigit(car.vin) }}
        </div>
        <mat-icon
            id="no-valuation-possible-icon"
            *ngIf="
                car.identificationProvider === 'dat' &&
                (car.datIdentification.isDamageCalculationPossible === false ||
                    car.datIdentification.isValuationPossible === false)
            "
            [ngClass]="{ 'no-calculation-possible': !car.datIdentification.isDamageCalculationPossible }"
            (click)="!car.datIdentification.isDamageCalculationPossible && openPhantomCalculationHelp()"
            [matTooltip]="getNotCalculableTooltip()">
            warning
        </mat-icon>

        <!--============================================-->
        <!-- Previous & Legacy Reports -->
        <!--============================================-->
        <mat-icon
            class="previous-reports-indicator outlined"
            [ngClass]="{ 'previous-reports-indicator-stale': !previousReportsChecked }"
            [matMenuTriggerFor]="previousReportsList"
            *ngIf="getPreviousReportsAmount()"
            [matTooltip]="getPreviousReportsTriggerTooltip()"
            [matTooltipShowDelay]="0"
            (menuOpened)="findPreviousReports()">
            info
        </mat-icon>

        <mat-menu class="previous-reports-menu" #previousReportsList>
            <!--********** Previous Reports **********-->
            <mat-option
                *ngFor="let previousReport of previousReports"
                class="previous-report-option"
                (mousedown)="openReportInNewWindow(previousReport, $event)">
                <div class="previous-report-option-inner-wrapper">
                    <div
                        class="previous-report-manufacturer-icon-container"
                        *ngIf="previousReport.car.make && iconForCarBrandExists(previousReport.car.make)"
                        (click)="navigateToReport(previousReport)">
                        <img
                            [src]="iconFilePathForCarBrand(previousReport.car.make, 'large')"
                            alt=""
                            class="car-manufacturer-logo" />
                    </div>
                    <div class="previous-report-name-and-date-container" (click)="navigateToReport(previousReport)">
                        <div
                            *ngIf="previousReport.claimant.contactPerson.organization"
                            class="previous-report-name-container"
                            [matTooltip]="previousReport.claimant.contactPerson.organization">
                            {{ previousReport.claimant.contactPerson.organization }}
                        </div>
                        <div
                            *ngIf="!previousReport.claimant.contactPerson.organization"
                            [matTooltip]="
                                previousReport.claimant.contactPerson.firstName +
                                ' ' +
                                previousReport.claimant.contactPerson.lastName
                            ">
                            {{ previousReport.claimant.contactPerson.firstName }}
                            {{ previousReport.claimant.contactPerson.lastName }}
                        </div>
                        <div class="label" matTooltip="Aktenzeichen">{{ previousReport.token }}</div>
                        <div class="label">{{ previousReport.createdAt | moment }}</div>
                    </div>
                    <div class="previous-report-license-plate-container" (click)="navigateToReport(previousReport)">
                        <license-plate
                            *ngIf="previousReport.car.licensePlate"
                            [licensePlate]="previousReport.car.licensePlate"></license-plate>
                        <span *ngIf="!previousReport.car.licensePlate" class="italic label">Kein Kennzeichen</span>
                    </div>
                    <div class="use-previous-reports-data-container">
                        <mat-icon
                            *ngIf="!disabled"
                            class="copy-car-data-icon"
                            (click)="copyCarDataFromPreviousReport(previousReport)"
                            matTooltip="Fahrzeugdaten übernehmen">
                            file_download
                        </mat-icon>
                    </div>
                </div>
            </mat-option>

            <!--********** Legacy Reports **********-->
            <mat-option
                *ngFor="let legacyReport of legacyReports"
                class="previous-report-option"
                (click)="$event.stopPropagation(); displayInfoNoteAboutLegacyReport()">
                <div class="previous-report-option-inner-wrapper">
                    <!--********** Brand Icon **********-->
                    <div
                        class="previous-report-manufacturer-icon-container"
                        *ngIf="legacyReport.make && iconForCarBrandExists(legacyReport.make)">
                        <img [src]="iconFilePathForCarBrand(legacyReport.make)" class="car-manufacturer-logo" />
                    </div>

                    <!--********** Name & Date **********-->
                    <div class="previous-report-name-and-date-container">
                        <!--********** Name **********-->
                        <div
                            *ngIf="legacyReport.claimant.name"
                            class="previous-report-name-container"
                            [matTooltip]="legacyReport.claimant.name">
                            {{ legacyReport.claimant.name }}
                        </div>
                        <div
                            class="italic"
                            *ngIf="!legacyReport.claimant.name"
                            matTooltip="Es wurde kein Name angegeben">
                            Kein Name
                        </div>
                        <!--********** Token **********-->
                        <div class="label" matTooltip="Aktenzeichen">{{ legacyReport.token }}</div>
                        <!--********** Date **********-->
                        <div class="label">{{ legacyReport.completionDate | moment }}</div>
                    </div>

                    <!--********** Model & VIN **********-->
                    <div class="previous-report-license-plate-container">
                        <license-plate
                            *ngIf="legacyReport.licensePlate"
                            [licensePlate]="legacyReport.licensePlate"></license-plate>
                        <span *ngIf="!legacyReport.licensePlate" class="italic label">Kein Kennzeichen</span>
                    </div>

                    <!--********** Legacy System Logo **********-->
                    <div class="legacy-system-logo" [ngSwitch]="legacyReport.sourceSystem?.toLowerCase()">
                        <img
                            src="/assets/images/logos/audatex.svg"
                            *ngSwitchCase="'audatex'"
                            matTooltip="Gutachten importiert aus Audatex" />
                        <img
                            src="/assets/images/logos/easyexpert.png"
                            *ngSwitchCase="'easyexpert'"
                            matTooltip="Gutachten importiert aus EasyExpert" />
                        <img
                            src="/assets/images/logos/combiplus.png"
                            *ngSwitchCase="'combiplus'"
                            matTooltip="Gutachten importiert aus CombiPlus" />
                        <img
                            src="/assets/images/logos/nextsoft.png"
                            *ngSwitchCase="'nextsoft'"
                            matTooltip="Gutachten importiert aus neXtsoft" />
                        <div
                            class="legacy-system-label-dynarex"
                            *ngSwitchCase="'dynarex'"
                            matTooltip="Gutachten importiert aus Dynarex">
                            Dynarex
                        </div>
                        <div
                            class="semibold text-overflow-ellipsis"
                            *ngSwitchDefault
                            [matTooltip]="legacyReport.sourceSystem">
                            {{ legacyReport.sourceSystem }}
                        </div>
                    </div>
                </div>
            </mat-option>
        </mat-menu>
        <!--============================================-->
        <!-- END Previous & Legacy Reports -->
        <!--============================================-->

        <img
            id="vin-verified-icon"
            *ngIf="car.vin && car.vin === car.verifiedVin && !vinRequestPending"
            src="/assets/images/icons/done_24.svg"
            matTooltip="Diese VIN konnte erfolgreich verifiziert werden." />
        <img
            id="vin-request-pending-icon"
            *ngIf="vinRequestPending"
            src="/assets/images/icons/loading-indicator.svg"
            matTooltip="Die VIN-Abfrage läuft..." />
        <mat-icon
            id="verify-vin-icon"
            *ngIf="!disabled && car.vin && !vinRequestPending && vinQueryAllowed"
            (click)="getVinData()"
            [matTooltip]="
                disabled
                    ? 'Um die VIN-Abfrage auszuführen, muss das Gutachten offen sein. Aktuell ist es abgeschlossen.'
                    : getVinQueryTooltip()
            ">
            file_download
        </mat-icon>
    </div>
</div>

<!--============================================-->
<!-- DAT VIN counter in Test Period -->
<!--============================================-->
<div
    id="dat-vin-counter-in-test-period"
    class="slim-progress-bar-row"
    (click)="reactivateExampleVinNote()"
    *ngIf="isNumberOfVinQueriesLimited()"
    matTooltip="Wir schenken dir für deinen Test {{ team.billing.testPeriodVinLimit }} VINs! Davon hast du bisher {{
        team.billing.testPeriodVinCounter || 'noch keine'
    }} abgefragt.{{
        '\n\n'
    }}Sobald du deinen eigenen Audatex/DAT-Account hinterlegst, wird die Grenze automatisch aufgehoben.{{
        '\n\n'
    }}Kostenfreie Beispiel-VINs zum Kennenlernen der DAT kannst du auch außerhalb des Limits abfragen."
    @fadeInAndSlide>
    <div class="label">VINs</div>
    <div class="slim-progress-bar-background margin-left-10 margin-right-10">
        <div
            class="slim-progress-bar-fill"
            [style.width]="(team.billing.testPeriodVinCounter / team.billing.testPeriodVinLimit) * 100 + '%'"
            [ngClass]="{ alert: hasReachedLimitOfTestVins() }"></div>
    </div>
    <div class="label">{{ team.billing.testPeriodVinCounter }} von {{ team.billing.testPeriodVinLimit }}</div>
</div>
<!--============================================-->
<!-- END DAT VIN counter in Test Period -->
<!--============================================-->

<div class="alert alert-danger" *ngIf="errorMessage" [innerHTML]="errorMessage"></div>
<div class="alert alert-danger" *ngIf="warningMessage" [innerHTML]="warningMessage"></div>

<!--============================================-->
<!-- Previous Reports Info Note -->
<!--============================================-->
<!-- Only display the large info note if the manufacturer is not yet filled in, so that the screen remains simple after selecting a car from previous reports. -->
<div
    id="info-note-vin-duplicate"
    class="info-note label"
    *ngIf="getPreviousReportsAmount() && !car.make"
    [matMenuTriggerFor]="previousReportsList"
    @slideInAndOutVertically>
    <mat-icon class="medium-icon">info</mat-icon>
    <div>
        <h3>Fahrzeug bekannt</h3>
        Es {{ getPreviousReportsAmount() === 1 ? 'wurde' : 'wurden' }}
        <a>{{ getPreviousReportsAmount() }} Gutachten</a>
        mit gleicher VIN gefunden.
    </div>
</div>
<!--============================================-->
<!-- END Previous Reports Info Note -->
<!--============================================-->

<!--============================================-->
<!-- Example VIN -->
<!--============================================-->
<div
    id="example-vin-info-note"
    class="info-note"
    *ngIf="
        isTestUserForIdentificationProviderConfigured() && !user?.userInterfaceStates.exampleVinNoticeClosed && !car.vin
    "
    @fadeInAndSlide>
    <mat-icon class="no-color">directions_car</mat-icon>
    <div id="example-vin-info-note-text">
        <h3>Keine VIN zur Hand?</h3>
        <div>
            Du kannst eine
            <span class="link" (click)="insertExampleVin(); emitReportChange()">Beispiel-VIN einfügen</span>
            <!--********** Wrap in span to avoid extra whitespace. **********-->
            <span>.</span>
        </div>
    </div>
    <mat-icon class="toolbar-icon" (click)="hideExampleVinNote()" matTooltip="Dauerhaft ausblenden">close</mat-icon>
</div>
<!--============================================-->
<!-- END Example VIN -->
<!--============================================-->

<!--============================================-->
<!-- DAT Example VIN despite Qapter -->
<!--============================================-->
<div
    class="info-note warning-note margin-bottom-15"
    *ngIf="car.identificationProvider !== 'dat' && isDatTestVin(car.vin)"
    @fadeInAndSlide>
    <mat-icon class="warning">warning</mat-icon>
    <div>
        <h3>Ungültige Test-VIN</h3>
        <div>
            Diese Test-VIN ist nur für DAT gültig.
            <span class="link" (click)="insertExampleVin(); emitReportChange()">Andere Beispiel-VIN einfügen</span>
            <!--********** Wrap in span to avoid extra whitespace. **********-->
            <span>.</span>
        </div>
    </div>
    <mat-icon class="toolbar-icon" (click)="hideExampleVinNote()" matTooltip="Dauerhaft ausblenden">close</mat-icon>
</div>
<!--============================================-->
<!-- END DAT Example VIN despite Qapter -->
<!--============================================-->

<!--============================================-->
<!-- Incomplete Credentials -->
<!--============================================-->
<div
    class="info-note warning-note margin-bottom-15"
    *ngIf="!areCredentialsForSelectedIdentificationProviderComplete()"
    @fadeInAndSlide>
    <mat-icon class="warning">warning</mat-icon>
    <div>
        <h3>Zugangsdaten fehlen</h3>
        <div>
            Bitte trage deine Zugangsdaten für
            <span [ngSwitch]="car.identificationProvider">
                <ng-container *ngSwitchCase="'audatex'">Audatex Qapter</ng-container>
                <ng-container *ngSwitchCase="'dat'">DAT</ng-container>
                <ng-container *ngSwitchCase="'gtmotive'">GT Motive</ng-container>
            </span>
            in den
            <a routerLink="/Einstellungen" [queryParams]="{ section: 'calculation-providers-section' }">
                Einstellungen
            </a>
            ein.
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Incomplete Credentials -->
<!--============================================-->

import { OverlayRef } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { SomeOptional } from '@autoixpert/helper-types/some-optional';
import {
    ProfessionalAssociation,
    ProfessionalAssociationName,
} from '@autoixpert/models/teams/professional-association';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../shared/animations/fade-in-and-slide.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { slideOutDialogVertical } from '../../shared/animations/slide-out-dialog-vertical.animation';
import { slideOutLeft } from '../../shared/animations/slide-out-left.animation';
import { slideOutRight } from '../../shared/animations/slide-out-right.animation';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { TeamService } from '../../shared/services/team.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';

@Component({
    selector: 'professional-associations',
    templateUrl: 'professional-associations.component.html',
    styleUrls: ['professional-associations.component.scss'],
    animations: [
        slideOutLeft(),
        slideOutRight(),
        slideOutDialogVertical(),
        slideInAndOutVertically(),
        fadeInAndSlideAnimation(),
        fadeInAndOutAnimation(),
    ],
})
export class ProfessionalAssociationsComponent {
    constructor(
        private overlayRef: OverlayRef,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private dialog: MatDialog,
        public userPreferences: UserPreferencesService,
        private teamService: TeamService,
        private apiErrorService: ApiErrorService,
    ) {}

    public user: User;
    public team: Team;

    public showAssociationChipList: boolean;
    public otherAssociations: ProfessionalAssociation[] = [];
    public showNetworkChipList: boolean;
    public otherNetworks: ProfessionalAssociation[] = [];

    private subscriptions: Subscription[] = [];

    ngOnInit() {
        this.subscribeToUserChange();
    }

    //*****************************************************************************
    //  User Change
    //****************************************************************************/
    public subscribeToUserChange() {
        this.subscriptions.push(
            this.loggedInUserService.getUser$().subscribe({
                next: (user) => {
                    this.user = user;
                },
            }),
            this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)),
        );

        this.team.professionalAssociations = [];

        this.getOtherAssociations();
        this.getOtherNetworks();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END User Change
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Association Selection
    //****************************************************************************/
    public associationIsAssigned(
        organization: ProfessionalAssociationName | string,
        type: 'networks' | 'certificates' | 'association' | 'customAssociation' | 'customNetwork',
    ): boolean {
        return this.team.professionalAssociations?.some(
            (entry) => entry.organization === organization && entry.type === type,
        );
    }

    public toggleAssociation(association: SomeOptional<ProfessionalAssociation, '_id'>) {
        if (!association.organization || !association.type) {
            return;
        }
        if (
            (association.type === 'customAssociation' || association.type === 'customNetwork') &&
            this.associationIsAssigned(association.organization, association.type)
        ) {
            return;
        }
        if (this.associationIsAssigned(association.organization, association.type)) {
            this.removeAssociation(association);
        } else {
            const addNewAssociation = new ProfessionalAssociation(association);
            this.addNewAssociation(addNewAssociation);
        }
        this.getOtherAssociations();
        this.getOtherNetworks();
    }

    public addNewAssociation(association: ProfessionalAssociation) {
        this.team.professionalAssociations.push(association);
    }

    public removeAssociation(association: Pick<ProfessionalAssociation, 'type' | 'organization'>) {
        const index = this.team.professionalAssociations.findIndex(
            (entry) => entry.type === association.type && entry.organization === association.organization,
        );
        this.team.professionalAssociations.splice(index, 1);
        this.getOtherAssociations();
        this.getOtherNetworks();
    }

    public clearInput(input: HTMLInputElement): void {
        input.value = '';
    }

    public showAssociationChipListInput() {
        this.showAssociationChipList = !this.showAssociationChipList;
    }

    public getOtherAssociations() {
        this.otherAssociations = this.team.professionalAssociations.filter(
            (entry) => entry.type === 'customAssociation',
        );
    }

    public resetDialog() {
        this.team.professionalAssociations = [];
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Association Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Other Networks
    //****************************************************************************/
    public getOtherNetworks() {
        this.otherNetworks = this.team.professionalAssociations.filter((entry) => entry.type === 'customNetwork');
    }

    public showNetworkChipListInput() {
        this.showNetworkChipList = !this.showNetworkChipList;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Other Networks
    /////////////////////////////////////////////////////////////////////////////*/
    //*****************************************************************************
    //  Mark Dialog As Submitted
    //****************************************************************************/
    public markDialogAsSubmitted() {
        this.team.userInterfaceStates.submittedProfessionalAssociations = true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Mark Dialog As Submitted
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Thank You Note
    //****************************************************************************/
    public displayThankYouToast() {
        this.toastService.success('Vielen Dank!', 'Danke für deine Antwort.');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Thank You Note
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Dialog Controls
    //****************************************************************************/
    public closeDialog() {
        this.overlayRef.detach();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog Controls
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

<div class="options-row" [ngClass]="{ grid: isSmallScreen }">
    <!-------------- Guard against a NULL value since 0 <= null returns true -->
    <div
        class="option-container"
        *ngFor="let previousOwnerId of previousOwnersForUI"
        [ngClass]="{
            active:
                report.car.numberOfPreviousOwners !== null &&
                (+previousOwnerId <= +report.car.numberOfPreviousOwners ||
                    report.car.numberOfPreviousOwners === 'multiple'),
            unselectable: isReportLocked,
        }"
        (click)="!isReportLocked && selectNumberOfPreviousOwners(previousOwnerId)"
        (keydown)="triggerClickEventOnSpaceBarPress($event)"
        tabindex="0">
        <!--********** No previous owner **********-->
        <img
            *ngIf="previousOwnerId === '0'"
            class="option-image"
            [ngClass]="{ 'more-than-one-active': report.car.numberOfPreviousOwners !== '0' }"
            src="/assets/images/icons/person-crossed-out_48.png"
            alt="Vorbesitzer" />

        <!--********** Number of previous owners 1-7 **********-->
        <img
            *ngIf="Number(previousOwnerId) > 0"
            class="option-image"
            src="/assets/images/icons/person-blue_48.png"
            alt="Vorbesitzer" />

        <!--********** Option Title **********-->
        <div class="option-title-container">
            <span
                class="option-title"
                [ngClass]="{
                    'below-selected-number': previousOwnerId < report.car.numberOfPreviousOwners,
                }">
                {{ previousOwnerId }}
            </span>
        </div>
    </div>

    <!--********** Multiple previous owners **********-->
    <div
        class="option-container"
        [ngClass]="{
            active: report.car.numberOfPreviousOwners === 'multiple',
            unselectable: isReportLocked,
        }"
        (click)="!isReportLocked && selectNumberOfPreviousOwners('multiple')"
        (keydown)="triggerClickEventOnSpaceBarPress($event)"
        tabindex="0">
        <img class="option-image" src="/assets/images/icons/people-blue_48.png" />
        <div class="option-title-container">
            <span class="option-title">Mehrere</span>
        </div>
    </div>

    <!--********** Unknown number of previous owners **********-->
    <div
        class="option-container"
        [ngClass]="{
            active: report.car.numberOfPreviousOwners === 'unknown',
            unselectable: isReportLocked,
        }"
        (click)="!isReportLocked && selectNumberOfPreviousOwners('unknown')"
        (keydown)="triggerClickEventOnSpaceBarPress($event)"
        tabindex="0">
        <img class="option-image" src="/assets/images/icons/people-unknown.png" />
        <div class="option-title-container">
            <span class="option-title">Unbekannt</span>
        </div>
    </div>

    <!--============================================-->
    <!-- Source of number of previous owners -->
    <!--============================================-->
    <div
        id="input-for-custom-car-shape"
        *ngIf="!!(team.preferences.sourceOfNumberOfPreviousOwnersShown || report.car.sourceOfNumberOfPreviousOwners)">
        <autocomplete-with-memory
            autocompleteEntryType="sourceOfNumberOfPreviousOwners"
            [(ngModel)]="report.car.sourceOfNumberOfPreviousOwners"
            (ngModelChange)="emitChange()"
            matTooltip="Quelle der Anzahl Vorbesitzer. In der Regel findet sich diese im Fahrzeugbrief, kann aber auch vom Anspruchsteller oder der Werkstatt übermittelt werden. Die Angabe ist optional, wird jedoch beispielsweise vom ZAK-Verband empfohlen."
            [disabled]="isReportLocked"
            placeholder="Quelle"></autocomplete-with-memory>
    </div>
    <!--============================================-->
    <!-- END Source of number of previous owners -->
    <!--============================================-->

    <!--============================================-->
    <!-- Submenu Icon -->
    <!--============================================-->
    <mat-icon
        id="previous-owners-submenu-icon"
        *ngIf="!isReportLocked"
        class="toolbar-icon"
        [matMenuTriggerFor]="previousOwnersSubmenu">
        more_vert
    </mat-icon>
    <!--============================================-->
    <!-- END Submenu Icon -->
    <!--============================================-->
</div>

<!--============================================-->
<!-- Submenu -->
<!--============================================-->
<mat-menu #previousOwnersSubmenu>
    <!--********** Show Source of Previous Owners Input **********-->
    <div
        mat-menu-item
        *ngIf="!team.preferences.sourceOfNumberOfPreviousOwnersShown"
        (click)="showInputForSourceOfPreviousOwners()"
        [matTooltip]="
            hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                ? 'Ermöglicht es, die Quelle der Anzahl Vorbesitzer angeben zu können. In der Regel findet sich diese im Fahrzeugbrief, kann aber auch vom Anspruchsteller oder der Werkstatt übermittelt werden. Die Angabe ist optional, wird jedoch beispielsweise vom ZAK-Verband empfohlen.'
                : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
        "
        [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
        Quelle angeben
    </div>

    <!--********** Hide Source of Previous Owners Input **********-->
    <div
        mat-menu-item
        *ngIf="team.preferences.sourceOfNumberOfPreviousOwnersShown"
        (click)="hideInputForSourceOfPreviousOwners()"
        [matTooltip]="
            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                : ''
        "
        [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
        Quelle ausblenden
    </div>
</mat-menu>
<!--============================================-->
<!-- END Submenu -->
<!--============================================-->

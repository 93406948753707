import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { CarEquipment, CarEquipmentPosition } from '@autoixpert/models/reports/car-identification/car-equipment';
import { CarEquipmentTemplate } from '@autoixpert/models/reports/car-identification/car-equipment-template';
import { Report } from '@autoixpert/models/reports/report';
import { User } from '@autoixpert/models/user/user';
import { PromptDialogComponent } from '../../../../shared/components/prompt-dialog/prompt-dialog.component';
import { deviceHasTouch } from '../../../../shared/libraries/device-detection/device-has-touch';
import { getDatErrorHandlers } from '../../../../shared/libraries/error-handlers/get-dat-error-handlers';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { CarEquipmentTemplateService } from '../../../../shared/services/car-equipment-template.service';
import { CarEquipmentService } from '../../../../shared/services/car-equipment.service';
import { DatAuthenticationService } from '../../../../shared/services/dat-authentication.service';
import { NetworkStatusService } from '../../../../shared/services/network-status.service';
import { ToastService } from '../../../../shared/services/toast.service';
import {
    EquipmentBulkImportDialogComponent,
    EquipmentBulkImportDialogResult,
} from '../equipment-bulk-import-dialog/equipment-bulk-import-dialog.component';

@Component({
    selector: 'equipment-selector',
    templateUrl: 'equipment-selector.component.html',
    styleUrls: ['equipment-selector.component.scss'],
})
export class EquipmentSelectorComponent {
    constructor(
        private toastService: ToastService,
        private dialog: MatDialog,
        private carEquipmentService: CarEquipmentService,
        private carEquipmentTemplateService: CarEquipmentTemplateService,
        private datAuthenticationService: DatAuthenticationService,
        private apiErrorService: ApiErrorService,
        private networkStatusService: NetworkStatusService,
    ) {}

    @Input() report: Report;
    @Input() user: User;

    @Input() set builtinEquipmentFromParent(value: CarEquipmentPosition[]) {
        this.getEquipmentsFromServer();
    }

    @ViewChild('builtinEquipmentList') builtinEquipmentList;
    @ViewChild('availableEquipmentList') availableEquipmentList;

    equipmentsSearchTerm = '';
    visibleEquipmentCategories: CarEquipmentPosition['type'][] = ['series', 'special', 'additional'];

    carEquipment: CarEquipment;
    possibleEquipmentPositions: CarEquipmentPosition[] = []; // Possible equipment without duplicates from builtin equipment
    possibleEquipmentPositionsFromVehicleIdentification: CarEquipmentPosition[] = []; // Possible equipment with duplicates from builtin equipment
    filteredPossibleEquipmentPositions: CarEquipmentPosition[] = [];
    builtinEquipmentPositions: CarEquipmentPosition[] = [];
    filteredBuiltinEquipmentPositions: CarEquipmentPosition[] = [];
    newEquipmentItem: CarEquipmentPosition;
    newEquipmentItemInputShown = false;

    // Equipment Group Templates
    equipmentGroupTemplatesShown = false;
    equipmentGroupTemplates: CarEquipmentTemplate[] = [];
    selectedEquipmentGroupTemplate: CarEquipmentTemplate;

    getEquipmentRequestPending = true;
    newEquipmentJustAdded = false;

    subscriptions: Subscription[] = [];

    ngOnInit() {
        // this.getEquipmentsFromServer(); // This is already triggered by the @Input being populated by Angular
        this.getEquipmentGroupTemplatesFromServer();
    }

    //*****************************************************************************
    //  Filter
    //****************************************************************************/
    public filterEquipmentPositions() {
        this.sortEquipmentLists();

        this.filteredPossibleEquipmentPositions = [...this.possibleEquipmentPositions];
        this.filteredBuiltinEquipmentPositions = [...this.builtinEquipmentPositions];

        this.filteredPossibleEquipmentPositions = this.applyCategoryFilter(this.filteredPossibleEquipmentPositions);
        this.filteredBuiltinEquipmentPositions = this.applyCategoryFilter(this.filteredBuiltinEquipmentPositions);

        this.filteredPossibleEquipmentPositions = this.applySearchFilter(
            this.filteredPossibleEquipmentPositions,
            this.equipmentsSearchTerm,
        );
        this.filteredBuiltinEquipmentPositions = this.applySearchFilter(
            this.filteredBuiltinEquipmentPositions,
            this.equipmentsSearchTerm,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter
    /////////////////////////////////////////////////////////////////////////////*/

    public toggleEquipmentCategory(category: CarEquipmentPosition['type']) {
        const index = this.visibleEquipmentCategories.indexOf(category);

        // Not present yet
        if (index < 0) {
            this.visibleEquipmentCategories.push(category);
        }
        // Present
        else {
            this.visibleEquipmentCategories.splice(index, 1);
        }
    }

    private applyCategoryFilter(items: CarEquipmentPosition[]) {
        return items.filter((item) => this.visibleEquipmentCategories.includes(item.type));
    }

    private sortEquipment(equipmentPositions: CarEquipmentPosition[]) {
        return equipmentPositions.sort((equipmentPositionA, equipmentPositionB) =>
            equipmentPositionA.description.localeCompare(equipmentPositionB.description),
        );
    }

    private sortEquipmentLists() {
        this.sortEquipment(this.possibleEquipmentPositions);
        this.sortEquipment(this.builtinEquipmentPositions);
    }

    private applySearchFilter(items: CarEquipmentPosition[], searchTerm: string) {
        if (!searchTerm) return items;

        const searchTerms: string[] = searchTerm.toLowerCase().split(' ');

        return items.filter((item) => {
            return searchTerms.every((searchTerm) => (item.description || '').toLowerCase().includes(searchTerm));
        });
    }

    //*****************************************************************************
    //  New Equipment
    //****************************************************************************/
    public showNewEquipmentInput() {
        this.newEquipmentItem = new CarEquipmentPosition();
        this.newEquipmentItemInputShown = true;
    }

    public hideNewEquipmentInput() {
        this.newEquipmentItem = null;
        this.newEquipmentItemInputShown = false;
    }

    public handleNewEquipmentInputKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.addNewEquipment();
            this.filterEquipmentPositions();
        } else if (event.key === 'Escape') {
            this.hideNewEquipmentInput();
        }
    }

    public addNewEquipment() {
        if (!this.newEquipmentItem.description) {
            this.toastService.info('Bitte gib einen Titel ein');
            return;
        }

        const newEquipmentItem = this.newEquipmentItem;
        this.builtinEquipmentPositions.unshift(newEquipmentItem);
        this.filterEquipmentPositions();
        this.saveEquipment();

        // Keep the type so that the user does not have to re-select it for evey type
        this.newEquipmentItem = new CarEquipmentPosition({ type: newEquipmentItem.type });
        this.newEquipmentItem.description = '';

        this.newEquipmentJustAdded = true;
        window.setTimeout(() => (this.newEquipmentJustAdded = false), 1000);
    }

    public deleteEquipment(equipmentPositions: CarEquipmentPosition[], equipmentItem: CarEquipmentPosition) {
        const index = equipmentPositions.indexOf(equipmentItem);
        equipmentPositions.splice(index, 1);
        this.filterEquipmentPositions();
    }

    public async openEquipmentImportDialog() {
        const bulkImportDialog = await this.dialog.open<
            EquipmentBulkImportDialogComponent,
            undefined,
            EquipmentBulkImportDialogResult
        >(EquipmentBulkImportDialogComponent, {});

        /**
         * Add the new equipment items to the list of builtin equipment positions.
         */
        bulkImportDialog.afterClosed().subscribe((result) => {
            // If the user closed the dialog without data, there is nothing to do.
            if (!result) return;

            let modified = 0;
            let created = 0;
            result.descriptions.forEach((description) => {
                const newEquipmentItem = new CarEquipmentPosition({ type: result.type, description });

                // If an equipment item with the same description already exists, update its type.
                // This ensures, that all imported equipments have the expected type.
                const existingPosition = this.builtinEquipmentPositions.find(
                    (position) => position.description === newEquipmentItem.description,
                );
                if (existingPosition) {
                    existingPosition.type = result.type;
                    modified++;
                } else {
                    this.builtinEquipmentPositions.push(newEquipmentItem);
                    created++;
                }
            });

            this.filterEquipmentPositions();
            this.saveEquipment();
            this.toastService.success(
                'Ausstattung importiert',
                `Es wurden ${created} neue Ausstattungen hinzugefügt und ${modified} bestehende aktualisiert.`,
            );
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END New Equipment
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Equipment Group Templates
    //****************************************************************************/
    public showEquipmentGroupTemplates() {
        this.equipmentGroupTemplatesShown = true;
    }

    public hideEquipmentGroupTemplates() {
        this.equipmentGroupTemplatesShown = false;
    }

    public showEquipmentGroupTemplateTitleDialog() {
        this.dialog
            .open(PromptDialogComponent, {
                data: {
                    heading: 'Vorlagentitel',
                    content: 'Welchen Titel möchtest du für die Vorlage vergeben?',
                    placeholder: 'Titel',
                    confirmLabel: 'Übernehmen',
                    cancelLabel: 'Abbrechen',
                },
            })
            .afterClosed()
            .subscribe((response) => {
                if (response?.userInput) {
                    this.createEquipmentGroupTemplate(response.userInput);
                }
            });
    }

    public async createEquipmentGroupTemplate(title: string) {
        const newTemplate = new CarEquipmentTemplate({
            title,
            possibleEquipments: JSON.parse(JSON.stringify(this.possibleEquipmentPositions)),
            builtinEquipments: JSON.parse(JSON.stringify(this.builtinEquipmentPositions)),
            createdAt: moment().format(),
        });
        this.equipmentGroupTemplates.push(newTemplate);

        try {
            await this.carEquipmentTemplateService.create(newTemplate);
        } catch (error) {
            this.toastService.error(
                'Ausstattungen nicht gespeichert',
                'Bitte lege die Vorlage erneut an oder kontaktiere die Hotline.',
            );
            return;
        }
        this.toastService.success('Ausstattungen gespeichert', 'Eine Vorlage wurde angelegt.');
    }

    public getEquipmentGroupTemplatesFromServer() {
        this.carEquipmentTemplateService.find().subscribe({
            next: (templates) => {
                this.equipmentGroupTemplates = templates;
            },
            error: (error) => {
                this.toastService.error(
                    'Ausstattungsvorlagen nicht abgerufen',
                    'Bitte versuche es erneut oder kontaktiere die Hotline.',
                );
                console.error(error);
            },
        });
    }

    public selectEquipmentGroupTemplate(template: CarEquipmentTemplate) {
        this.selectedEquipmentGroupTemplate = template;
    }

    public useEquipmentGroupTemplate(template: CarEquipmentTemplate) {
        this.possibleEquipmentPositions = template.possibleEquipments;
        this.builtinEquipmentPositions = template.builtinEquipments;

        this.filterEquipmentPositions();
        this.saveEquipment();

        this.hideEquipmentGroupTemplates();
    }

    public async deleteEquipmentGroupTemplate(template: CarEquipmentTemplate) {
        this.equipmentGroupTemplates.splice(this.equipmentGroupTemplates.indexOf(template), 1);

        try {
            await this.carEquipmentTemplateService.delete(template._id);
        } catch (error) {
            this.toastService.error('Vorlage nicht gelöscht', 'Bitte kontaktiere die Hotline.');
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Equipment Group Templates
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Used to prevent vertical dragging on drag devices which interferes with scrolling the equipment lists.
     */
    public isTouchDevice() {
        return deviceHasTouch();
    }

    public onDropEquipment(event: CdkDragDrop<CarEquipmentPosition[]>) {
        // No need to do anything if the equipment was moved within the same container since the equipment positions are always sorted alphabetically.
        if (event.previousContainer !== event.container) {
            // We cannot use the event's `previousIndex` and `currentIndex` properties because those are the indexes within the filtered colleciton. We need the indexed within the original collection, though.

            // Get the filtered collection belonging to the connected collection ("data" property of the event). From the filtered collection, we'll get the item that is being dragged.
            const previousContainerFilteredCollection =
                event.previousContainer.data === this.possibleEquipmentPositions
                    ? this.filteredPossibleEquipmentPositions
                    : this.filteredBuiltinEquipmentPositions;
            const draggedEquipment = previousContainerFilteredCollection[event.previousIndex];
            // Get index within original, unfiltered collection
            const previousIndex = event.previousContainer.data.indexOf(draggedEquipment);
            transferArrayItem(event.previousContainer.data, event.container.data, previousIndex, event.currentIndex);
            this.filterEquipmentPositions();
            this.saveEquipment();
        }
    }

    public moveItemToBuiltinEquipments(item: CarEquipmentPosition) {
        if (isReportLocked(this.report)) return;

        const oldIndex = this.possibleEquipmentPositions.indexOf(item);
        this.possibleEquipmentPositions.splice(oldIndex, 1);

        this.builtinEquipmentPositions.unshift(item);
        this.filterEquipmentPositions();
        this.saveEquipment();
    }

    public moveItemToPossibleEquipments(item: CarEquipmentPosition) {
        if (isReportLocked(this.report)) return;

        const oldIndex = this.builtinEquipmentPositions.indexOf(item);
        this.builtinEquipmentPositions.splice(oldIndex, 1);

        this.possibleEquipmentPositions.unshift(item);
        this.filterEquipmentPositions();
        this.saveEquipment();
    }

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    private async getEquipmentsFromServer() {
        this.getEquipmentRequestPending = true;
        let possibleCarEquipmentPositions: CarEquipmentPosition[] = [];

        try {
            this.carEquipment = (await this.carEquipmentService.find({ reportId: this.report._id }).toPromise())?.[0];
        } catch (error) {
            // Implement if we run into errors.
        }

        // If no equipment exists yet, create a dummy so the following assignments work.
        if (!this.carEquipment) {
            this.carEquipment = new CarEquipment({
                reportId: this.report._id,
                createdBy: this.user._id,
                teamId: this.user.teamId,
            });
            try {
                await this.carEquipmentService.create(this.carEquipment);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: ' nicht erstellt werden.',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            }
        }

        // Only try to load possible equipment from the DAT servers if the vehicle was identified with the DAT. If the user does not have a DAT user, the request would fail.
        if (this.report.car.identificationProvider === 'dat') {
            try {
                const datJwt = await this.datAuthenticationService.getJwt();
                possibleCarEquipmentPositions = await this.carEquipmentService
                    .getPossible(this.report, { datJwt })
                    .toPromise();
            } catch (error) {
                this.getEquipmentRequestPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getDatErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'DAT-Ausstattungen',
                        body: 'Die verfügbaren Ausstattungen konnten für das gewählte Fahrzeug nicht von der DAT geladen werden.',
                    },
                });
            }
        }

        // Built-in equipment
        this.builtinEquipmentPositions = this.carEquipment.equipmentPositions || [];
        const builtinEquipmentIds = this.builtinEquipmentPositions
            .map((equipmentPosition) => equipmentPosition.datEquipmentId)
            // Only include existing IDs
            .filter((id) => !!id);

        // Possible equipment
        this.possibleEquipmentPositionsFromVehicleIdentification = possibleCarEquipmentPositions;
        // Only show the possible equipment positions that are not chosen to be built in.
        this.possibleEquipmentPositions = [
            ...this.carEquipment.customPossibleEquipmentPositions,
            ...possibleCarEquipmentPositions,
        ].filter(
            (possibleCarEquipmentPosition) =>
                !builtinEquipmentIds.includes(possibleCarEquipmentPosition.datEquipmentId),
        );

        this.filterEquipmentPositions();

        this.getEquipmentRequestPending = false;
    }

    public isClientOffline(): boolean {
        return !this.networkStatusService.isOnline();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Copy Equipment List
    //****************************************************************************/
    /**
     * This function allows the user to copy the list of equipment positions to the clipboard.
     * This may be useful if a user wants to edit the equipment positions in a text editor and adds them later or share them with someone else.
     * We use a comma instead of the semicolon because CARTV requires a comma-separated list of equipments in their CARTV fleet UI.
     */
    public async copyFilteredEquipmentPositionsToClipboard() {
        const equipmentPositionsList: string = `${this.filteredBuiltinEquipmentPositions
            .map((position) => position.description)
            .join(', ')}`;
        try {
            await navigator.clipboard.writeText(equipmentPositionsList);
        } catch (error) {
            this.toastService.error(
                'Ausstattungsliste nicht kopiert',
                'Diese Funktion wird von deinem Browser nicht unterstützt oder es liegt ein Fehler vor.',
            );
            console.error('EQUIPMENT_POSITIONS_NOT_COPIED_TO_CLIPBOARD', error);
            return;
        }

        this.toastService.success(`Ausstattungsliste in Zwischenablage kopiert`);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy Equipment List
    /////////////////////////////////////////////////////////////////////////////*/

    public saveEquipment() {
        const customEquipmentPositions = this.possibleEquipmentPositions.filter(
            (equipment) => !equipment.datEquipmentId,
        );
        // Items not included in vehicle identification response need to be saved to the server. Otherwise, the user won't see them again when re-opening this list component because they won't be included in the possible equipment request.
        const removedEquipmentPositions = this.possibleEquipmentPositions.filter((equipment) => {
            if (!equipment.datEquipmentId) return false;

            return !this.possibleEquipmentPositionsFromVehicleIdentification.find(
                (equipmentFromResponse) => equipment.datEquipmentId === equipmentFromResponse.datEquipmentId,
            );
        });

        // Only custom equipments = don't have a DAT Equipment ID
        this.carEquipment.customPossibleEquipmentPositions = [
            ...customEquipmentPositions,
            ...removedEquipmentPositions,
        ];
        this.carEquipment.equipmentPositions = this.builtinEquipmentPositions;

        return this.carEquipmentService.put(this.carEquipment);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected readonly isReportLocked = isReportLocked;
}

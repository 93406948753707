import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { removeDocument } from './remove-document';

/**
 * Removes all documents of the specified type from the documents list and all document orders of a report.
 */
export function removeDocumentByType({
    documentType,
    documentOrders,
    documents,
}: {
    documentType: DocumentMetadata['type'];
    documentOrders: DocumentOrder[];
    documents: DocumentMetadata[];
}): void {
    const documentsToRemove = documents.filter((document) => document.type === documentType);

    for (const documentToRemove of documentsToRemove) {
        removeDocument({
            document: documentToRemove,
            documentOrders,
            documents,
        });
    }
}

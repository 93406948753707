import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Report } from '@autoixpert/models/reports/report';
import { DocumentGroupName } from '../../reports/details/print-and-transmission/print-and-transmission.component';

@Injectable()
export class PersaldoService {
    constructor(private httpClient: HttpClient) {}

    public checkCredentials(): Observable<{ success: true }> {
        return this.httpClient.get<{ success: true }>(`/api/v0/reports/id-not-required/persaldo/invoices`);
    }

    public exportInvoice(report: Report, documentGroup: DocumentGroupName): Observable<PersaldoPostResponse> {
        return this.httpClient.post<PersaldoPostResponse>(
            `/api/v0/reports/${report._id}/persaldo/invoices?documentGroup=${documentGroup}`,
            null,
        );
    }
}

export interface PersaldoPostResponse {
    persaldoCaseNumber: number;
}

import { escapeRegExp } from 'lodash-es';

const availableInsuranceLogos: { possibleNames: string[]; fileName: string }[] = [
    {
        possibleNames: ['aachenmünchener'],
        fileName: 'aachenmuenchener.png',
    },
    {
        possibleNames: ['adac'],
        fileName: 'adac.png',
    },
    {
        possibleNames: ['admiraldirekt'],
        fileName: 'admiraldirekt.png',
    },
    {
        possibleNames: ['aig'],
        fileName: 'aig.png',
    },
    {
        possibleNames: ['allianz'],
        fileName: 'allianz.png',
    },
    {
        possibleNames: ['alte leipziger'],
        fileName: 'alte-leipziger.png',
    },
    {
        possibleNames: ['arag'],
        fileName: 'arag.png',
    },
    {
        possibleNames: ['axa'],
        fileName: 'axa.png',
    },
    {
        possibleNames: ['barmenia'],
        fileName: 'barmenia.png',
    },
    {
        possibleNames: ['basler'],
        fileName: 'basler.png',
    },
    {
        possibleNames: ['bavariadirekt'],
        fileName: 'bavariadirekt.png',
    },
    {
        possibleNames: ['bayerische beamten', 'BA die Bayerische'],
        fileName: 'bayerische-beamten.png',
    },
    {
        possibleNames: ['BGV'],
        fileName: 'bgv.png',
    },
    {
        possibleNames: ['concordia'],
        fileName: 'concordia.png',
    },
    {
        possibleNames: ['condor'],
        fileName: 'condor.png',
    },
    {
        possibleNames: ['continentale'],
        fileName: 'continentale.png',
    },
    {
        possibleNames: ['cosmos'],
        fileName: 'cosmos.png',
    },
    {
        possibleNames: ['da direkt', 'DA Deutsche Allgemeine'],
        fileName: 'da-direkt.png',
    },
    {
        possibleNames: ['debeka'],
        fileName: 'debeka.png',
    },
    {
        possibleNames: ['devk'],
        fileName: 'devk.png',
    },
    {
        possibleNames: ['dialog versicherung'],
        fileName: 'dialog.png',
    },
    {
        possibleNames: ['ergo'],
        fileName: 'ergo.png',
    },
    {
        possibleNames: ['europa'],
        fileName: 'europa.png',
    },
    {
        possibleNames: ['fahrlehrerversicherung'],
        fileName: 'fahrlehrerversicherung.png',
    },
    {
        possibleNames: ['feuersozietät berlin', 'Feuersozietät Berlin'],
        fileName: 'feuersozietaet-berlin.png',
    },
    {
        possibleNames: ['deutsche niederlassung der friday'],
        fileName: 'friday.png',
    },
    {
        possibleNames: ['generali'],
        fileName: 'generali.png',
    },
    {
        possibleNames: ['gothaer'],
        fileName: 'gothaer.png',
    },
    {
        possibleNames: ['gvv'],
        fileName: 'gvv.png',
    },
    {
        possibleNames: ['gemeinnützige haftpflicht'],
        fileName: 'gvv.png',
    },
    {
        possibleNames: ['haftpflichtgemeinschaft deutscher nahverkehrs'],
        fileName: 'hdna.png',
    },
    {
        possibleNames: ['hanse merkur', 'hanse-merkur', 'hansemerkur'],
        fileName: 'hanse-merkur.png',
    },
    {
        possibleNames: ['hdi'],
        fileName: 'hdi.png',
    },
    {
        possibleNames: ['helvetia'],
        fileName: 'helvetia.png',
    },
    {
        possibleNames: ['huk-coburg'],
        fileName: 'huk-coburg.png',
    },
    {
        possibleNames: ['huk24'],
        fileName: 'huk24.png',
    },
    {
        possibleNames: ['itzehoer'],
        fileName: 'itzehoer.png',
    },
    {
        possibleNames: ['janitos'],
        fileName: 'janitos.png',
    },
    {
        possibleNames: ['kravag'],
        fileName: 'kravag.png',
    },
    {
        possibleNames: ['lippische'],
        fileName: 'lippische.png',
    },
    {
        possibleNames: ['lvm'],
        fileName: 'lvm.png',
    },
    {
        possibleNames: ['mannheimer'],
        fileName: 'mannheimer.png',
    },
    {
        possibleNames: ['mecklenburgische'],
        fileName: 'mecklenburgische.png',
    },
    {
        possibleNames: ['münchener verein'],
        fileName: 'muenchener-verein.png',
    },
    {
        possibleNames: ['nexible'],
        fileName: 'nexible.png',
    },
    {
        possibleNames: ['nürnberger', 'garanta' /* Merged into Nürnberger */],
        fileName: 'nuernberger.png',
    },
    {
        possibleNames: ['provinzial nord'],
        fileName: 'provinzial-nord.png',
    },
    {
        possibleNames: ['provinzial rheinland'],
        fileName: 'provinzial-rheinland.png',
    },
    {
        possibleNames: ['r+v', 'ruv'],
        fileName: 'ruv.png',
    },
    {
        possibleNames: ['rhion'],
        fileName: 'rhion-digital.png',
    },
    {
        possibleNames: ['saarland'],
        fileName: 'saarland.png',
    },
    {
        possibleNames: ['signal iduna'],
        fileName: 'signal-iduna.png',
    },
    {
        possibleNames: ['sv sparkassenVersicherung', 'Sparkassen'],
        fileName: 'sparkassen-versicherung.png',
    },
    {
        possibleNames: ['universa'],
        fileName: 'universa.png',
    },
    {
        possibleNames: ['versicherer im raum der kirchen sachversicherung ag', 'vrk'],
        fileName: 'vrk.png',
    },
    {
        possibleNames: ['versicherungskammer bayern', 'bayerischer versicherungsverband'],
        fileName: 'versicherungskammer-bayern.png',
    },
    {
        possibleNames: ['verti'],
        fileName: 'verti.png',
    },
    {
        possibleNames: ['vgh'],
        fileName: 'vgh.png',
    },
    {
        possibleNames: ['vhv'],
        fileName: 'vhv.png',
    },
    {
        possibleNames: ['volkswohl'],
        fileName: 'volkswohlbund.png',
    },
    {
        possibleNames: ['westfälische provinzial', 'westfaelische provinzial'],
        fileName: 'westfaelische-provinzial.png',
    },
    {
        possibleNames: ['wgv', 'württembergische gemeindeversicherung'],
        fileName: 'wgv.png',
    },
    {
        possibleNames: ['wwk'],
        fileName: 'wwk.png',
    },
    {
        // Explicitly check for "Versicherung" since "Württembergische" may also be present in "Württembergische Gemeindeversicherung" (WGV)
        possibleNames: ['württembergische versicherung'],
        fileName: 'wuerttembergische.png',
    },
    {
        possibleNames: ['zurich'],
        fileName: 'zurich.png',
    },
    {
        possibleNames: ['öffentliche sachversicherung braunschweig', 'öffentliche versicherung braunschweig'],
        fileName: 'oeffentliche-versicherung-braunschweig.png',
    },
    {
        possibleNames: ['öffentliche versicherungen oldenburg', 'öffentliche lebensversicherungsanstalt oldenburg'],
        fileName: 'oeffentliche-versicherungen-oldenburg.png',
    },
    {
        possibleNames: ['ösa', 'öffentliche versicherung sachsen', 'öffentliche feuerversicherung sachsen-anhalt'],
        fileName: 'oesa.png',
    },
    {
        possibleNames: ['övb', 'öffentliche versicherung bremen'],
        fileName: 'oevb.png',
    },
];

export function insuranceLogoExists(insuranceName: string): boolean {
    if (!insuranceName) {
        return false;
    }
    return !!getFileNameForInsuranceLogo(insuranceName);
}

export function getFileNameForInsuranceLogo(insuranceName: string): string {
    if (!insuranceName) {
        return '';
    }

    // Find the available insurance icon entry that has at least one possible name that's at the start of the insurance name.
    const matchingEntry = availableInsuranceLogos.find((entry) =>
        entry.possibleNames.some((possibleName) =>
            new RegExp(`^${escapeRegExp(possibleName)}\\b`, 'i').test(insuranceName),
        ),
    );

    if (!matchingEntry) return '';

    return matchingEntry.fileName;
}

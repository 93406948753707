<!--********** User Picture **********-->
<user-avatar [userId]="note.createdBy" tooltipPrefix="Erstellt von "></user-avatar>

<!--============================================-->
<!-- Read Mode -->
<!--============================================-->
<div class="note-content-container flex-grow">
    <!--********** Head Data **********-->
    <div class="note-head-data display-flex">
        <!--********** Author & Date **********-->
        <div class="note-author-and-date flex-grow">
            <div class="note-author-and-editor" *ngIf="note.createdBy">
                <!--********** Author **********-->
                <div class="note-author semibold">{{ getFullNameOfTeamMember(note.createdBy) }}</div>
                <!--********** Edited Icon **********-->
                <mat-icon
                    class="small-icon"
                    *ngIf="note.updatedAt !== note.createdAt"
                    matTooltip="Zuletzt bearbeitet von {{ getFullNameOfTeamMember(note.updatedBy) }} am {{
                        note.updatedAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]'
                    }}">
                    edit
                </mat-icon>
            </div>
            <div class="note-date-and-important-marker">
                <!--********** Note Date **********-->
                <div
                    class="note-date label"
                    *ngIf="note.createdAt"
                    matTooltip="{{ note.createdAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                    {{ note.createdAt | momentCalendar }}
                </div>
                <!--********** Important Marker **********-->
                <mat-icon
                    class="important-note-icon"
                    *ngIf="note.isImportant"
                    matTooltip="Diese Notiz wurde als wichtig markiert.">
                    priority_high
                </mat-icon>
            </div>
        </div>

        <!--********** Submenu **********-->
        <mat-icon class="toolbar-icon medium-icon" [matMenuTriggerFor]="noteSubmenu">more_vert</mat-icon>
        <mat-menu #noteSubmenu>
            <!--********** Mark as Important **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="!note.isImportant"
                (click)="toggleNoteAsImportant(note); emitNoteChange()">
                <mat-icon>priority_high</mat-icon>
                Als wichtig markieren
            </a>

            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="note.isImportant"
                (click)="toggleNoteAsImportant(note); emitNoteChange()">
                <mat-icon style="color: red">priority_high</mat-icon>
                Markierung aufheben
            </a>

            <!--********** Edit **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="enterEditMode()">
                <mat-icon>edit</mat-icon>
                Bearbeiten
            </a>

            <!--********** Delete **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="emitNoteDeletion()">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>
    </div>

    <!--********** Readonly: Note Text **********-->
    <div
        *ngIf="!isEditModeActive"
        class="note-text"
        [quillContent]="note.text"
        (dblclick)="enterEditModeOnDoubleClick()"></div>

    <!--********** Edit: Note Text **********-->
    <div *ngIf="isEditModeActive" class="note-editable-text">
        <!--********** Textarea **********-->
        <div class="note-textarea" [ngClass]="{ empty: !note.text }">
            <mat-form-field class="mat-block">
                <mat-quill
                    [(ngModel)]="note.text"
                    (change)="emitNoteChange()"
                    (blur)="leaveEditMode()"
                    (keydown)="leaveEditModeOnCtrlEnter($event)"
                    [autofocus]="true"></mat-quill>
            </mat-form-field>
        </div>

        <!--********** Buttons **********-->
        <div class="note-save-button">
            <mat-icon class="small-icon" (click)="leaveEditMode()">check</mat-icon>
        </div>
    </div>
</div>

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, EventEmitter, HostListener, Injector, Input, Output } from '@angular/core';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { LabelConfig, LabelGroup } from '@autoixpert/models/labels/label-config';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ToastService } from '../../services/toast.service';
import { LabelManagerComponent } from './label-manager.component';

@Directive({
    selector: '[labelManagerAnchor]',
})
export class LabelManagerAnchorDirective {
    constructor(
        private overlayService: Overlay,
        private injector: Injector,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
    ) {}

    @Input() labelGroup: LabelGroup;
    @Input() labelConfigs: LabelConfig[];
    @Output() labelConfigsChange: EventEmitter<LabelConfig[]> = new EventEmitter();

    private user: User;
    private overlayRef: OverlayRef;
    private componentRef: ComponentRef<LabelManagerComponent>;

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
    }

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openOverlay() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.error(
                'Zugriffsrecht fehlt',
                `Nur Nutzer mit dem Zugriffsrecht "${translateAccessRightToGerman(
                    'editTextsAndDocumentBuildingBlocks',
                )}" können die Label-Vorlagen bearbeiten.`,
            );
            return;
        }

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            // backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService.position().global().centerHorizontally().centerVertically(),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<LabelManagerComponent>(
            LabelManagerComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.overlayRef,
                    },
                ],
            }),
        );

        // Attach Component to Portal Outlet
        this.componentRef = this.overlayRef.attach(componentPortal);

        // Update component properties.
        this.componentRef.instance.labelGroup = this.labelGroup;

        // Subscribe to changes in component.
        this.componentRef.instance.labelConfigsChange.subscribe((labelConfigs) => {
            // Every time the manager causes changes, use them right away, don't wait for the roundtrip to the server.
            this.labelConfigs = labelConfigs;

            this.labelConfigsChange.emit(labelConfigs);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

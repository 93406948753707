import { calculateReplacementValueWithCorrections } from '@autoixpert/lib/damage-calculation-values/replacement-value-correction/calculate-replacement-value-with-corrections';
import { replacementValueCorrectionExists } from '@autoixpert/lib/damage-calculation-values/replacement-value-correction/replacement-value-correction-exists';
import { Report } from '@autoixpert/models/reports/report';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { getVatRateForTaxationType } from '@autoixpert/static-data/taxation-rates';
import { VehicleValueSelected } from 'src/app/reports/details/damage-calculation/damage-calculation.component';
import { determineDamageType } from '../damage-calculation/determine-damage-type';
import { setReplacementValueWithCorrections } from '../damage-calculation/set-replacement-value-with-corrections';
import { determineTaxationTypes } from './determine-taxation-type';

export function setVehicleValue({
    report,
    userPreferences,
    valueType,
    taxationType,
    valueNet,
    valueGross,
    valuationProvider,
}: VehicleValueSelected & { report: Report; userPreferences: UserPreferences }): void {
    if (report.state === 'done') {
        return;
    }

    // Set VehicleValueType
    if (valueType) {
        report.valuation.vehicleValueType = valueType;
    }

    // Set taxation type
    if (taxationType) {
        report.valuation.taxationType = taxationType;
    }

    // Set valuation provider
    if (valuationProvider) {
        report.valuation.valuationProvider = valuationProvider;
    }

    // If only a gross value is set, calculate the net value.
    if (valueNet == null && valueGross != null) {
        valueNet = valueGross / (1 + getVatRateForTaxationType(report.valuation.taxationType));
    }
    // If only a net value is set, calculate the gross value.
    if (valueGross == null && valueNet != null) {
        valueGross = valueNet * (1 + getVatRateForTaxationType(report.valuation.taxationType));
    }

    if (replacementValueCorrectionExists(report)) {
        // In case the user added corrections to the replacement value, we need to store the new vehicle
        // value as the "vehicle base value" and update the calculated replacement value (base value with corrections)
        report.valuation.vehicleBaseValue = valueGross;
        const correctedValue = calculateReplacementValueWithCorrections(report);
        setReplacementValueWithCorrections(report, correctedValue, userPreferences);
    } else {
        report.valuation.vehicleValueNet = valueNet;
        report.valuation.vehicleValueGross = valueGross;
    }

    // Set the taxation type, if it is not set yet.
    if (!report.valuation.taxationType) {
        report.valuation.taxationType = determineTaxationTypes(report);
    }

    // Since the replacement value was changed, update the damage class which depends on the vehicle value.
    determineDamageType(report, userPreferences);
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { AssessorsFeeAnalyticsRecord } from '@autoixpert/models/analytics/assessors-fee-analytics.model';
import { AnalyticsFilterComponent } from '../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { downloadAnalyticsRecordsAsCsv } from '../../shared/libraries/download-analytics-records-as-csv';
import { AssessorsFeeAnalyticsService } from '../../shared/services/analytics/assessors-fee-analytics.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { getHeightOfHorizontalBarChart, setDefaultChartOptions } from '../default-chart-options';

@Component({
    selector: 'assessors-fee',
    templateUrl: './assessors-fee.component.html',
    styleUrls: ['./assessors-fee.component.scss'],
})
export class AssessorsFeeComponent implements OnInit {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;

    constructor(
        private assessorsFeeAnalyticsService: AssessorsFeeAnalyticsService,
        private apiErrorService: ApiErrorService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    public chartTitle: string = 'Honorar pro Sachverständigem';
    private chart: ApexCharts;
    private chartOptions: apexcharts.ApexOptions;
    public analyticsPending: boolean = false;

    public totalRevenue: number = 0;
    public totalNumberOfReports: number = 0;

    public records: AssessorsFeeAnalyticsRecord[] = [];

    public useNetValues: boolean = true;

    public filterAnalyticsFrom: string;
    public filterAnalyticsTo: string;

    ngOnInit() {
        setDefaultChartOptions();

        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateBackToAnalyticsList(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    public rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/

    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.records.length) return;

        // Bar Values
        const assessorsFeeGroups: number[] = this.records.map((record) => record.assessorsFeeTotal);
        const paidAmountGroups: number[] = this.records.map(
            (record) => record.assessorsFeeTotal * record.invoicePaymentQuota,
        );

        this.chartOptions = {
            chart: {
                type: 'bar',
                height: getHeightOfHorizontalBarChart(this.records.length),
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            series: [
                {
                    name: 'Honorar (netto)',
                    data: assessorsFeeGroups,
                },
                {
                    name: 'Davon bezahlt (genähert)',
                    data: paidAmountGroups,
                },
            ],
            xaxis: {
                // Categories go into the Y axis when the bar chart is horizontal but...
                categories: this.records.map((record) => (record.assessorName || '').toUpperCase()),
                // ... all styling for the final xaxis is determined here.
                labels: {
                    formatter: (value) => currencyFormatterEuro(+value),
                    style: {
                        fontFamily: "'Source Sans Pro', sans-serif",
                    },
                },
            },
            yaxis: {
                labels: {
                    show: true,
                },
            },
            tooltip: {
                y: {
                    formatter: (revenueByAssessor) => currencyFormatterEuro(revenueByAssessor),
                },
            },
        };

        // Clear before re-drawing
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
        this.chart.render();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public calculateTotalRevenue(): void {
        this.totalRevenue = this.records.reduce((total, currentItem) => total + currentItem.assessorsFeeTotal, 0);
    }

    public calculateTotalNumberOfReports(): void {
        this.totalNumberOfReports = this.records.reduce((total, currentItem) => total + currentItem.numberOfReports, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Table
    //****************************************************************************/

    public downloadRecordsAsCsv(): void {
        downloadAnalyticsRecordsAsCsv(this.records, this.chartTitle);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Table
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    public updateAnalytics(): void {
        this.analyticsPending = true;

        this.assessorsFeeAnalyticsService
            .getAssessorsFees({
                from: this.filterAnalyticsFrom,
                to: this.filterAnalyticsTo,
                officeLocationIds: this.filter.officeLocationIds,
                reportLabelIds: this.filter.reportLabelConfigIds,
            })
            .subscribe({
                next: (records) => {
                    this.records = records;

                    this.analyticsPending = false;
                    this.calculateTotalRevenue();
                    this.calculateTotalNumberOfReports();
                    setTimeout(() => {
                        this.drawChart();
                    }, 0);
                },
                error: (error) => {
                    this.analyticsPending = false;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ACCESS_DENIED: {
                                title: 'Zugriffsrecht fehlt',
                                body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                                forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                            },
                        },
                        defaultHandler: {
                            title: 'Fehler bei Auswertung',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/
}

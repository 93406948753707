import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { Report } from '@autoixpert/models/reports/report';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { getVatRateForTaxationType } from '@autoixpert/static-data/taxation-rates';
import { determineDamageType } from './determine-damage-type';

/**
 * Updates the replacement value after it was corrected using increases or decreases.
 */
export const setReplacementValueWithCorrections = (
    report: Report,
    valueGross: number,
    userPreferences: UserPreferences,
): void => {
    if (isReportLocked(report)) {
        return;
    }

    // Calculate the net value
    report.valuation.vehicleValueNet = valueGross / (1 + getVatRateForTaxationType(report.valuation.taxationType));
    report.valuation.vehicleValueGross = valueGross;

    // Since the replacement value was changed, update the damage class which depends on the vehicle value.
    determineDamageType(report, userPreferences);
};

<mat-icon class="close-icon toolbar-icon white-icon" (click)="rejectDialog()">close</mat-icon>

<h2>Kennzeichen abdecken</h2>

<div class="dialog-container-description">
    Fotos für Restwertbörsen:
    <br />
    Lass Kennzeichen von einer KI automatisch schwärzen
    <mat-icon
        class="help-icon small-icon outlined"
        [matTooltip]="
            'Kennzeichen stellen personenbezogene Daten dar, deshalb sollten sie nicht in der Börse einer Drittplattform auftauchen.'
        ">
        help
    </mat-icon>
</div>

<div class="features-container">
    <div class="info-container">
        <div class="info-container-icon-wrapper">
            <svg
                class="info-container-icon"
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368">
                <path
                    d="M323-160q-11 0-20.5-5.5T288-181l-78-139h58l40 80h92v-40h-68l-40-80H188l-57-100q-2-5-3.5-10t-1.5-10q0-4 5-20l57-100h104l40-80h68v-40h-92l-40 80h-58l78-139q5-10 14.5-15.5T323-800h97q17 0 28.5 11.5T460-760v160h-60l-40 40h100v120h-88l-40-80h-92l-40 40h108l40 80h112v200q0 17-11.5 28.5T420-160h-97Zm217 0q-17 0-28.5-11.5T500-200v-200h112l40-80h108l-40-40h-92l-40 80h-88v-120h100l-40-40h-60v-160q0-17 11.5-28.5T540-800h97q11 0 20.5 5.5T672-779l78 139h-58l-40-80h-92v40h68l40 80h104l57 100q2 5 3.5 10t1.5 10q0 4-5 20l-57 100H668l-40 80h-68v40h92l40-80h58l-78 139q-5 10-14.5 15.5T637-160h-97Z" />
            </svg>
        </div>

        <div class="info-container-content">
            <div class="info-container-content-headline">Dein Co-Pilot</div>
            <div class="info-container-content-description">
                Die KI deckt Kennzeichen in vielen Fällen automatisch mit Polygonen ab. In allen anderen Fällen kannst
                du Kennzeichen manuell abdecken.
            </div>
        </div>
    </div>

    <div class="info-container">
        <div class="info-container-icon-wrapper">
            <mat-icon class="info-container-icon">stylus_note</mat-icon>
        </div>

        <div class="info-container-content">
            <div class="info-container-content-headline">Abdeckung anpassbar</div>
            <div class="info-container-content-description">
                Du entscheidest selbst, ob du unsere Vorschläge akzeptierst oder noch einmal eingreifst.
            </div>
        </div>
    </div>

    <div class="info-container">
        <div class="info-container-icon-wrapper">
            <mat-icon class="info-container-icon">mobile_friendly</mat-icon>
        </div>

        <div class="info-container-content">
            <div class="info-container-content-headline">Offline verfügbar</div>
            <div class="info-container-content-description">
                ...sobald du die KI einmal online ausgeführt hast, damit sie auf deinem PC, Tablet oder Smartphone
                initialisiert wurde.
            </div>
        </div>
    </div>
</div>

<div class="dialog-buttons-container">
    <button (click)="confirmDialog()">Ok, Magie an!</button>
    <button class="secondary" (click)="rejectDialog()">Abbrechen</button>
</div>

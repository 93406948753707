import { Injectable } from '@angular/core';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { DatabaseServiceName } from '@autoixpert/models/indexed-db/database.types';
import { User } from '@autoixpert/models/user/user';
import { AxLicenseService } from 'src/app/shared/services/ax-license.service';
import { InvoiceLineItemTemplateService } from 'src/app/shared/services/invoice-line-item-template.service';
import { TaskService } from 'src/app/shared/services/task.service';
import { OfflineSyncBlobServiceBase } from '../../shared/libraries/database/offline-sync-blob-service.base';
import { FullSyncResult, OfflineSyncServiceBase } from '../../shared/libraries/database/offline-sync-service.base';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CarEquipmentTemplateService } from '../../shared/services/car-equipment-template.service';
import { CarEquipmentService } from '../../shared/services/car-equipment.service';
import { ClaimantSignatureFileService } from '../../shared/services/claimant-signature-file.service';
import { ContactPersonService } from '../../shared/services/contact-person.service';
import { CustomAutocompleteEntriesService } from '../../shared/services/custom-autocomplete-entries.service';
import { CustomFeeSetService } from '../../shared/services/custom-fee-set.service';
import { DocumentBuildingBlockService } from '../../shared/services/document-building-block.service';
import { DocumentLayoutGroupService } from '../../shared/services/document-layout-group.service';
import { DocumentOrderConfigService } from '../../shared/services/document-order-config.service';
import { DowntimeNotificationService } from '../../shared/services/downtime-notification.service';
import { EmailSignatureService } from '../../shared/services/emailSignature.service';
import { FieldGroupConfigService } from '../../shared/services/field-group-config.service';
import { FileNamePatternService } from '../../shared/services/file-name-pattern.service';
import { InvoiceTemplateService } from '../../shared/services/invoice-template.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LabelConfigService } from '../../shared/services/label-config.service';
import { LeaseReturnTemplateService } from '../../shared/services/lease-return-template.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ManufacturerService } from '../../shared/services/manufacturer.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { OriginalPhotoService } from '../../shared/services/original-photo.service';
import { ProfilePictureFileService } from '../../shared/services/profile-picture-file.service';
import { RenderedPhotoFileService } from '../../shared/services/rendered-photo-file.service';
import { ReportProgressConfigService } from '../../shared/services/report-progress-config.service';
import { ReportService } from '../../shared/services/report.service';
import { ResidualValueBidderGroupService } from '../../shared/services/residual-value-bidder-group.service';
import { SignablePdfTemplateConfigService } from '../../shared/services/signable-pdf-template-config.service';
import { TeamService } from '../../shared/services/team.service';
import { TextTemplateService } from '../../shared/services/textTemplate.service';
import { UserService } from '../../shared/services/user.service';
import { WatermarkImageFileService } from '../../shared/services/watermark-image-file.service';

/**
 * This service makes sure that IndexedDB sync services are initialized on app start. That ensures that they trigger
 * syncs after the user reloaded the page offline.
 *
 * Angular services are only initialized once they're injected.
 */
@Injectable()
export class DatabaseSyncInitializerService {
    private readonly fullSyncServices: OfflineSyncServiceBase<any>[] = [];
    private readonly cacheWhenUsedServices: OfflineSyncServiceBase<any>[] = [];
    private readonly blobCacheServices: OfflineSyncBlobServiceBase[] = [];

    public currentlySyncedServiceName: DatabaseServiceName;

    private user: User;

    constructor(
        private userService: UserService,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private profilePictureFileService: ProfilePictureFileService,
        // Report Module
        private reportService: ReportService,
        private reportProgressConfigService: ReportProgressConfigService,
        private leaseReturnTemplateService: LeaseReturnTemplateService,
        private carEquipmentService: CarEquipmentService,
        private carEquipmentTemplateService: CarEquipmentTemplateService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private customFeeSetService: CustomFeeSetService,
        private watermarkImageFileService: WatermarkImageFileService,
        private originalPhotoService: OriginalPhotoService,
        private claimantSignatureFileService: ClaimantSignatureFileService,
        private fileNamePatternService: FileNamePatternService,
        private renderedPhotoFileService: RenderedPhotoFileService,
        private residualValueBidderGroupService: ResidualValueBidderGroupService,
        // Contact Module
        private contactPersonService: ContactPersonService,
        // Invoice Module
        private invoiceService: InvoiceService,
        private invoiceTemplateService: InvoiceTemplateService,
        private invoiceLineItemTemplateService: InvoiceLineItemTemplateService,
        // Preferences Module
        private documentLayoutGroupService: DocumentLayoutGroupService,
        // Shared
        private textTemplateService: TextTemplateService,
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private documentBuildingBlockService: DocumentBuildingBlockService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private signablePdfTemplateConfigService: SignablePdfTemplateConfigService,
        private emailSignatureService: EmailSignatureService,
        private manufacturerService: ManufacturerService,
        private axLicenseService: AxLicenseService,
        private labelConfigService: LabelConfigService,
        private taskService: TaskService,
        private downtimeNotificationService: DowntimeNotificationService,
        // Not a data sync service.
        private networkStatusService: NetworkStatusService,
        private authenticationService: AuthenticationService,
    ) {
        /**
         * Data from these services will both be pushed and pulled, so these services are completely in sync
         * with the server.
         *
         * These services are master data services which are required for a user to create a report offline.
         */
        this.fullSyncServices = [
            // Very important
            this.userService,
            this.teamService,
            this.fieldGroupConfigService,
            this.customAutocompleteEntriesService,
            this.textTemplateService,
            this.documentBuildingBlockService,
            this.documentOrderConfigService,
            this.signablePdfTemplateConfigService,

            // Medium important
            this.reportProgressConfigService,
            this.manufacturerService,
            this.customFeeSetService,
            this.emailSignatureService,
            this.labelConfigService,

            // Less important
            this.leaseReturnTemplateService,
            this.carEquipmentTemplateService,
            this.documentLayoutGroupService,
            this.residualValueBidderGroupService,

            // Sync contact people last since they are often huge (one customer had 19_000 contacts due to an import from his legacy AudaFusion).
            this.contactPersonService,
        ];

        /**
         * Data from these services will be pushed to the server if the user edited something while being offline.
         *
         * Typically, this data is too large to keep it all synced on a client's device.
         */
        this.cacheWhenUsedServices = [
            this.reportService,
            this.carEquipmentService,
            this.invoiceService,
            this.taskService,
            // Not relevant for creating a report offline, so don't keep this 100 % synced.
            this.invoiceTemplateService,
            this.invoiceLineItemTemplateService,

            this.axLicenseService,
            this.downtimeNotificationService,
            this.fileNamePatternService,
        ];

        /**
         * These services are used to get blobs from the server and cache them locally.
         *
         * Also, when local files are created, they will push to the server.
         */
        this.blobCacheServices = [
            this.originalPhotoService,
            this.renderedPhotoFileService,
            this.profilePictureFileService,
            this.watermarkImageFileService,
            this.claimantSignatureFileService,
        ];

        this.loggedInUserService.getUser$().subscribe((user) => (this.user = user));

        this.registerAuthenticationHandler();
        this.registerBackOnlineHandlers();

        /**
         * Sync everything again in max. 24 hours.
         */
        setTimeout(() => {
            this.sync();
        }, 86_400_000 /* = 1 day = 24 * 60 * 60 * 1000 */);
    }

    public isInitialSyncInProgress: boolean = false;

    /**
     * Syncs data with the server.
     *
     * Should be called
     * - after all means of authentication.
     * - when the user comes back online.
     * - when the user reloads the app.
     */
    public async sync(): Promise<void> {
        if (!this.authenticationService.getLocalJwt()) {
            console.log(`[offline-sync] No local JWT found, so skip syncing data with server.`);
            return;
        }

        if (!this.user) {
            console.log(`[offline-sync] Not triggering sync -> User not logged in`);
            return;
        }

        // It's only an initial sync if the local databases have never been synced before.
        this.isInitialSyncInProgress = !(await this.fullSyncServices[0]?.localDb.getDatabaseInfo())?.lastFullSyncAt;

        try {
            await this.syncMasterData();
            await this.pushNonMasterData();
            await this.syncTombstoneRecords();
        } catch (error) {
            this.isInitialSyncInProgress = false;

            if (error.code === 'CLIENT_IS_OFFLINE') {
                // Swallow errors if the client is offline. Sync will resume when the client goes back online through this.registerBackOnlineHandlers().
                console.log(
                    `[offline-sync] Stop syncing data with the autoiXpert backend since this device went offline.`,
                );
            } else if (error.code === 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN') {
                // Swallow errors if the backend servers cannot be reached. Sync will resume when the client goes back online through this.registerBackOnlineHandlers().
                console.log(
                    `[offline-sync] Stop syncing data with the autoiXpert backend since the backend servers cannot be reached.`,
                );
            } else {
                throw error;
            }
        }
        this.isInitialSyncInProgress = false;
    }

    private async syncMasterData(): Promise<void> {
        for (const fullSyncService of this.fullSyncServices) {
            // Has the user logged out while syncing?
            if (!this.user) return;

            this.currentlySyncedServiceName = fullSyncService.serviceName;

            try {
                const fullSyncResult: FullSyncResult = await fullSyncService.fullSync();
                if (fullSyncResult.numberOfPushedRecords) {
                    // Only log if there are any changes pushed to the server. Pad the string so that multiple log entries have the same length.
                    console.log(
                        `🔺 [offline-sync] Auto Push: ${(fullSyncResult.numberOfPushedRecords + '').padStart(
                            3,
                            ' ',
                        )} record(s) ✅ [${fullSyncService.serviceName}]`,
                    );
                }
                if (fullSyncResult.numberOfPulledRecords) {
                    // Only log if there are any changes fetched from the server. Pad the string so that multiple log entries have the same length.
                    console.log(
                        `🔽 [offline-sync] Auto Pull: ${(fullSyncResult.numberOfPulledRecords + '').padStart(
                            3,
                            ' ',
                        )} record(s) ✅ [${fullSyncService.serviceName}]`,
                    );
                }
            } catch (error) {
                // If this device goes offline during sync, do not continue the sync to prevent spamming the console.
                if (['CLIENT_IS_OFFLINE', 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN'].includes(error.code)) {
                    throw error;
                }

                if (!this.user) {
                    console.log('Ignore an error during sync since the user has logged out.', { ...error });
                    return;
                }

                throw new AxError({
                    code: 'SYNCING_MASTER_DATA_FAILED',
                    message: `The master service "${fullSyncService.serviceName}" could not sync its data with the autoiXpert backend servers.`,
                    data: {
                        serviceName: fullSyncService.serviceName,
                    },
                    error,
                });
            }
        }
        this.currentlySyncedServiceName = undefined;
    }

    private async pushNonMasterData(): Promise<void> {
        const cacheServices = [...this.cacheWhenUsedServices, ...this.blobCacheServices];

        for (const fullSyncService of cacheServices) {
            // Has the user logged out while syncing?
            if (!this.user) return;

            this.currentlySyncedServiceName = fullSyncService.serviceName;

            try {
                const numberOfPushedRecords: number = await fullSyncService.pushToServer();
                if (numberOfPushedRecords) {
                    // Only log if there are any changes pushed to the server. Pad the string so that multiple log entries have the same length.
                    console.log(
                        `🔺 [offline-sync] Auto Push: ${(numberOfPushedRecords + '').padStart(3, ' ')} record(s) ✅ [${
                            fullSyncService.serviceName
                        }]`,
                    );
                }
            } catch (error) {
                // If this device goes offline during sync, do not continue the sync to prevent spamming the console.
                if (['CLIENT_IS_OFFLINE', 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN'].includes(error.code)) {
                    throw error;
                }

                console.error('PUSHING_DATA_FAILED', {
                    error: new AxError({
                        code: 'PUSHING_DATA_FAILED',
                        message: `The service "${fullSyncService.serviceName}" could not push its data to the autoiXpert backend servers.`,
                        data: {
                            serviceName: fullSyncService.serviceName,
                        },
                        error,
                    }),
                });
            }
        }
        this.currentlySyncedServiceName = undefined;
    }

    private async syncTombstoneRecords(): Promise<void> {
        const allServices = [...this.fullSyncServices, ...this.cacheWhenUsedServices];

        for (const service of allServices) {
            // Has the user logged out while syncing?
            if (!this.user) return;

            try {
                const numberOfTombstoneRecords: number = await service.findNewDeletedRecords();
                if (numberOfTombstoneRecords) {
                    // Only log if there are any changes pushed to the server. Pad the string so that multiple log entries have the same length.
                    console.log(
                        `💀 [offline-sync] Tombstone records: ${(numberOfTombstoneRecords + '').padStart(
                            3,
                            ' ',
                        )} record(s) [${service.serviceName}]`,
                    );
                }
            } catch (error) {
                // If this device goes offline during sync, do not continue the sync to prevent spamming the console.
                if (['CLIENT_IS_OFFLINE', 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN'].includes(error.code)) {
                    throw error;
                }
                console.error('GETTING_TOMBSTONE_RECORDS_FAILED', {
                    error: new AxError({
                        code: 'GETTING_TOMBSTONE_RECORDS_FAILED',
                        message: `Tombstone records for the service "${service.serviceName}" could not be fetched from the autoiXpert backend servers.`,
                        data: {
                            serviceName: service.serviceName,
                        },
                        error,
                    }),
                });
            }
        }
    }

    //*****************************************************************************
    //  Sync on Authentication
    //****************************************************************************/
    /**
     * Trigger a sync as soon as the user has been successfully authenticated.
     * @private
     */
    private registerAuthenticationHandler(): void {
        this.authenticationService.authenticationSuccess.subscribe(() => this.sync());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sync on Authentication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Online after Offline Handlers
    //****************************************************************************/
    /**
     * Register actions to take when the user comes back online after going offline.
     */
    protected registerBackOnlineHandlers(): void {
        const syncAfterOfflineBroadcast = new BroadcastChannel(`sync-after-offline-random-number`);
        let otherTabsRandomNumber: number[] = [];
        let thisTabsRandomNumber: number = 0;
        syncAfterOfflineBroadcast.addEventListener('message', (messageEvent: MessageEvent<number>) => {
            otherTabsRandomNumber.push(messageEvent.data);
        });

        this.networkStatusService.networkBackOnline$.subscribe(async () => {
            console.log(
                `[offline-sync] This device is back online. Elect a browser tab on this device for syncing changes to the server.`,
            );
            thisTabsRandomNumber = Math.random();
            syncAfterOfflineBroadcast.postMessage(thisTabsRandomNumber);

            setTimeout(async () => {
                /**
                 * This will be set to true if this tab was randomly chosen to sync local changes back to the server.
                 *
                 * This prevents a tricky error: Two tabs are open on an offline device. The user changes an attribute (e.g. version number 14) and goes back online. Before this
                 * flag, both tabs would start syncing the same changes (version 14) to the server since changes are tracked via the common Indexed DB.
                 * The first tab would succeed since the current server version was 13 while the second tab would receive an error message from the server that the tab's version (14) was out of date
                 * since the server already has the version 14 and that the tab should merge the most recent changes from the server. The second tab would merge the changes (version 14) and send
                 * another put request to the server (version 15). That put would be regularly propagated to tab 1.
                 */
                const thisTabShouldSync =
                    Math.max(...otherTabsRandomNumber, thisTabsRandomNumber) === thisTabsRandomNumber;
                // For documentation, see the declaration of this.syncAfterOfflineInProgress.
                if (thisTabShouldSync) {
                    console.log(
                        `[offline-sync] This browser tab was elected to sync changes from this device to the server.`,
                    );
                    // Pull all changes of already local records and new records that have been created after the oldest local record.
                    try {
                        await this.sync();
                    } catch (error) {
                        console.error(`[offline-sync] Error syncing changes to server after getting back online.`, {
                            error,
                        });
                        return;
                    }
                } else {
                    console.log(
                        `😴 [offline-sync] Syncing the changes from the local database to the server is in progress in another tab. This tab does not need to trigger a second sync.`,
                    );
                }

                // Reset the random numbers so the next leader election starts fresh.
                otherTabsRandomNumber = [];
                thisTabsRandomNumber = 0;
            }, 100);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Online after Offline Handlers
    /////////////////////////////////////////////////////////////////////////////*/
}

import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { Report } from '@autoixpert/models/reports/report';
import { currencyFormatterEuro } from '../currency-formatter-euro';

export async function confirmToRemoveValueIncreaseFromReport({
    report,
    dialogService,
}: {
    report: Report;
    dialogService: MatLegacyDialog;
}) {
    const decision = await dialogService
        .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
            data: {
                heading: 'Wertverbesserung entfernen?',
                content: `Sie wurde aus der Kalkulation entfernt.

Möchtest du auch die ${currencyFormatterEuro(report.valuation.valueIncrease)} Wertverbesserung im Gutachten entfernen?`,
                confirmLabel: 'Entfernen',
                cancelLabel: 'Behalten',
                confirmColorRed: false,
            },
        })
        .afterClosed()
        .toPromise();
    if (decision) {
        report.valuation.valueIncrease = null;
    }
}

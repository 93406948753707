import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { interval, merge } from 'rxjs';
import { generateId } from '@autoixpert/lib/generate-id';
import { isCursorInInputOrTextarea } from '@autoixpert/lib/keyboard-events/isCursorInInputOrTextarea';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { DowntimeNotification } from '@autoixpert/models/notifications/downtime-notification';
import { environment } from '../../../environments/environment';
import {
    HtmlIntrusionDialogComponent,
    HtmlIntrusionDialogData,
} from '../components/html-intrusion-dialog/html-intrusion-dialog.component';
import { AppVersion, AppVersionService } from './app-version.service';
import { DowntimeNotificationService } from './downtime-notification.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { ToastService } from './toast.service';

@Injectable()
export class AppVersionUpdateService {
    constructor(
        private appVersionService: AppVersionService,
        private httpClient: HttpClient,
        private serviceWorker: SwUpdate,
        private loggedInUserService: LoggedInUserService,
        private router: Router,
        private toastService: ToastService,
        private dialogService: MatDialog,
        private downtimeNotificationService: DowntimeNotificationService,
        private networkStatusService: NetworkStatusService,
    ) {
        this.checkForOldAppVersionAfterBreakingChangesUpdate();

        this.networkStatusService.networkBackOnline$.subscribe(() => {
            /**
             * If the user has put his device to sleep over night, the interval in app.component.ts will possibly not
             * trigger again for up to ten minutes.
             *
             * Coming back online will trigger a check for a new client version too, improving the time until the
             * new version is recognized.
             */
            void this.checkForNewAppVersion();
        });
    }

    /**
     * In case there is other antivirus software that also injects code into the index.html, add them here and
     * add the new keyword in the HtmlIntrusionDialog.
     *
     * If our index.html contains any of these strings, a dialog is displayed telling the user what to do
     * so that the update process works again.
     */
    private indexHtmlIntrusions = [
        'kaspersky',
        // Avast's side programs injects plugins, see https://autoixpert.slack.com/archives/CGJ9ECESJ/p1730279862856659
        'VT AudioPlayback',
    ] as const;
    /**
     * This will be set to true if the user decides to keep intruding software like Kaspersky until the next reload.
     */
    public areUpdatesPrevented = false;
    public detectedIntrusion: 'kaspersky' | 'avast';

    public startAppVersionUpdateChecker(): void {
        this.subscribeToAppVersionUpdates();
        this.startVersionWatcher();
        this.loadCurrentVersionInfo();
    }

    public subscribeToAppVersionUpdates(): void {
        /**
         * Documentations for this code:
         * - Official Angular documentation: https://angular.io/guide/service-worker-communications
         * - A good StackOverflow article about how activateUpdate() is unnecessary: https://stackoverflow.com/a/59175788/1027464
         */
        this.serviceWorker.versionUpdates.subscribe({
            next: async (versionEvent: VersionEvent) => {
                /**
                 * If the user decided he does not want to update autoiXpert, we will prevent any further updates until
                 * the user refreshes the page. That prevents the same error to be sent to Sentry every 10 minutes (interval after which
                 * the client looks for a new frontend version).
                 */
                if (this.areUpdatesPrevented) {
                    console.log(
                        'Updates are prevented because the user rejected the note about his Kaspersky installation.',
                    );
                    return;
                }

                switch (versionEvent.type) {
                    case 'NO_NEW_VERSION_DETECTED':
                        this.appVersionService.currentVersion = versionEvent.version as AppVersion;
                        break;

                    case 'VERSION_DETECTED': {
                        console.log(`Downloading new app version: ${versionEvent.version.hash}`);
                        break;
                    }

                    case 'VERSION_READY':
                        console.log(`Current app version: ${versionEvent.currentVersion.hash}`);
                        console.log(`New app version ready for use: ${versionEvent.latestVersion.hash}`);
                        this.appVersionService.currentVersion = versionEvent.currentVersion as AppVersion;
                        this.appVersionService.latestVersion = versionEvent.latestVersion as AppVersion;

                        if (!this.appVersionService.latestVersion?.appData?.silentUpdate) {
                            this.warnUserAndReload();
                        } else {
                            console.log('New version available (silent update)');
                        }
                        break;

                    case 'VERSION_INSTALLATION_FAILED': {
                        console.log(
                            `Failed to install app version '${versionEvent.version.hash}': ${versionEvent.error}`,
                        );
                        /**
                         * Since the update failed, we want to prevent any further issues being sent to Sentry until the user reloads the page
                         * because otherwise, every 10 minutes, an issue is sent. That's just spam for us.
                         */
                        this.areUpdatesPrevented = true;

                        const detectedIntrusion = await this.getIndexHtmlIntrusion();
                        if (detectedIntrusion) {
                            console.log('Detected intrusion:', detectedIntrusion);

                            switch (detectedIntrusion) {
                                case 'kaspersky': {
                                    this.detectedIntrusion = 'kaspersky';

                                    const dialogRef = this.dialogService.open<
                                        HtmlIntrusionDialogComponent,
                                        HtmlIntrusionDialogData
                                    >(HtmlIntrusionDialogComponent, {
                                        data: {
                                            intrusionProvider: 'kaspersky',
                                        },
                                    });

                                    const decision: boolean = await dialogRef.afterClosed().toPromise();
                                    if (decision) {
                                        document.location.reload();
                                    } else {
                                        console.warn(
                                            'The user decided to keep Kaspersky. Updates are prevented until the next reload to keep lots of errors from being thrown.',
                                        );
                                    }
                                    break;
                                }
                                case 'VT AudioPlayback': {
                                    this.detectedIntrusion = 'avast';

                                    const dialogRef = this.dialogService.open<
                                        HtmlIntrusionDialogComponent,
                                        HtmlIntrusionDialogData
                                    >(HtmlIntrusionDialogComponent, {
                                        data: {
                                            intrusionProvider: 'avast',
                                        },
                                    });

                                    const decision: boolean = await dialogRef.afterClosed().toPromise();
                                    if (decision) {
                                        document.location.reload();
                                    } else {
                                        console.warn(
                                            'The user decided to keep Avast. Updates are prevented until the next reload to keep lots of errors from being thrown.',
                                        );
                                    }
                                    break;
                                }
                                default:
                                    this.toastService.error(
                                        'Update-Installation nicht möglich',
                                        'Es wurde eine geänderte index.html festgestellt. Bitte lade die Seite neu.<br><br>Sollte das Problem weiterhin bestehen bleiben, könnten Sync-Probleme entstehen. Eine typische Ursache ist, dass Anti-Viren-Programm (Avast, Kaspersky, Avira, ...) den Software-Quellcode beim Download verändert. Aus Sicherheitsgründen lassen wir keine Veränderten Dateien beim Update zu.<br>Bitte tausche dein Anti-Viren-Programm gegen den Microsoft Defender aus. Für Details kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>',
                                    );
                            }
                        } else {
                            console.log(
                                'No known HTML-intrusion detected (e.g. through Kaspersky). The update failed due to a different reason.',
                            );

                            this.toastService.error(
                                'Update-Installation nicht möglich',
                                'Bitte lade die Seite neu.<br><br>Sollte das Problem bestehen bleiben, könnten Sync-Probleme entstehen.<br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>',
                            );

                            throw new AxError({
                                code: 'INSTALLING_NEW_FRONTEND_VERSION_FAILED',
                                message: `The new Angular service worker could not be installed: ${versionEvent.error}.`,
                                data: {
                                    version: versionEvent.version.hash,
                                    errorMessage: versionEvent.error,
                                },
                            });
                        }
                    }
                }
            },
            error: (err) => console.error('SERVICE_WORKER_AVAILABILITY_ERROR', err),
        });

        // If the application is in a broken state, reload the page.
        this.serviceWorker.unrecoverable.subscribe(() => this.warnUserAndReload());
    }

    /**
     * Download the index.html and check if it contains any unwanted intrusions, such as from Kaspersky.
     *
     * Kaspersky started to add custom HTML code to the index.html file. This code is not visible in the browser, but it is present in the HTML file
     * and invalidates Angular's Service Worker's integrity checks. That again causes the update to fail.
     */
    public async getIndexHtmlIntrusion(): Promise<AppVersionUpdateService['indexHtmlIntrusions'][number] | undefined> {
        let lowerCaseIndexHtml: string;

        try {
            const response = await fetch(`/index.html?ax-cache-bust=${Math.random()}`);
            lowerCaseIndexHtml = (await response.text()).toLowerCase();
            console.log('Fetched index.html for intrusion detection.', lowerCaseIndexHtml);
        } catch (error) {
            console.error(`Detecting index.html intrusions failed. Fail silently.`, error);
            return undefined;
        }

        return this.indexHtmlIntrusions.find((intrusion) => lowerCaseIndexHtml?.includes(intrusion));
    }

    /**
     * Check if the last update happened yesterday. Purpose: New app versions usually come overnight. We want the check as early as possible in the morning.
     */
    public startVersionWatcher(): void {
        if (this.serviceWorker.isEnabled) {
            console.log(
                'Service Worker enabled. Checking for new app version every 10 minutes. The service worker also automatically checks a couple seconds after initialization.',
            );
            this.checkForNewAppVersion();
            const everyTenMinutes$ = interval(60 * 1000 * 10);
            everyTenMinutes$.subscribe(() => {
                this.checkForNewAppVersion();
            });
        } else {
            console.log('Service Worker not enabled 🚫⚙️');
        }
    }

    /**
     * If there are no app version updates, the frontend does not get the current version info.
     * Therefore, we need to load the current version info manually from the ngsw.json file once.
     */
    private async loadCurrentVersionInfo(): Promise<void> {
        // ngsw.json is not available in development mode.
        if (!environment.production) return;

        const ngswJson = await this.httpClient.get<AppVersion>('/ngsw.json').toPromise();
        this.appVersionService.currentVersion = ngswJson;
    }

    /**
     * Warn the user about a new version and reload the page after 10 seconds.
     */
    public warnUserAndReload(): void {
        this.rememberCurrentRoute();

        if (isCursorInInputOrTextarea()) {
            // This must be an INPUT or TEXTAREA element.
            (document.activeElement as HTMLElement).blur();
        }

        console.log('New aX version available. Reload in 10s...');

        // TODO Distinguish between required reloads and optional reloads. Required reloads should force a reload while
        //   optional reloads should only show a toast or a more subtle notification.
        const infoToast = this.toastService.info(
            'Neue Version verfügbar 🚀',
            'Neustart in 10s oder durch Klick auf diese Meldung.',
            {
                showProgressBar: true,
                timeOut: 10_000,
            },
        );
        /**
         * Whatever happens first, click on the toast or the timeout, will trigger reloading the page.
         */
        merge(infoToast.timeoutEnd, infoToast.click).subscribe(() => {
            document.location.reload();
        });
    }

    /**
     * Make the service worker check the server for a new frontend version.
     */
    public async checkForNewAppVersion() {
        try {
            await this.serviceWorker.checkForUpdate();
        } catch (error) {
            throw new AxError({
                code: 'CHECKING_FOR_NEW_ANGULAR_APP_VERSION_FAILED',
                message:
                    'The Angular service worker could not check for a new version. Have a look at the causedBy property for details.',
                error,
            });
        }
    }

    /**
     * Before logging out, remember the route. After refreshing, the user can then continue on the same page.
     */
    private rememberCurrentRoute(): void {
        // Remember current page for after refresh
        this.loggedInUserService.forwardUrl = this.router.url;
    }

    /**
     * Hard-coded for now - check for old app version after breaking changes update and notify user.
     * Wait for 1 minute after application start so the frontend has time to update itself.
     */
    private async checkForOldAppVersionAfterBreakingChangesUpdate() {
        const breakingChangesUpdateDate = new Date('2025-03-31T19:00:00Z');

        setTimeout(async () => {
            // Only show warning after the update date
            if (new Date() >= breakingChangesUpdateDate) {
                const createdAt = this.appVersionService.currentVersion?.appData?.createdAt;
                if (createdAt) {
                    const createdAtDate = new Date(createdAt);
                    if (createdAtDate < breakingChangesUpdateDate) {
                        console.log('Creating local only warning about old app version...');
                        this.downtimeNotificationService.createLocalOnly(
                            new DowntimeNotification({
                                _id: generateId(),
                                createdBy: '',
                                affectedUserGroup: 'all',
                                numberOfDaysVisibleInAdvance: 0,
                                title: 'Veraltete autoiXpert-Version',
                                message:
                                    'Veraltete autoiXpert-Version. Aktualisiere die Seite und kontaktiere die Hotline, falls diese Meldung nicht verschwindet.',
                                validFrom: new Date().toISOString(),
                                validUntil: new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString(),
                                createdAt: new Date().toISOString(),
                                updatedAt: new Date().toISOString(),
                                _schemaVersion: 1,
                                _documentVersion: 0,
                            }),
                        );
                        return;
                    }
                }
            }

            // Check again in 1 minute in case the user never reloads the page.
            this.checkForOldAppVersionAfterBreakingChangesUpdate();
        }, 60_000);
    }
}

import { GarageFeeSet } from '../../models/contacts/garage-fee-set';
import { Car } from '../../models/reports/car-identification/car';
import {
    ManualCalculationItem,
    RepairCategory,
    RepairCode,
    RepairCodeLetter,
} from '../../models/reports/damage-calculation/manual-calculation';
import { getApplicablePaintMaterialSurcharge } from '../contact-people/garages/get-applicable-paint-material-surcharge';
import { getAbsolutePaintMaterialSurcharge } from '../damage-calculation-values/get-absolute-paint-material-surcharge';
import { convertToEuro } from './convert-to-euro';
import { formatNumberToGermanLocale } from './format-number-to-german-locale';
import {
    DeductionItemGerman,
    LaborItemGerman,
    LacquerItemGerman,
    PlaceholderValuesReport,
    ReplacementPartItemGerman,
} from './placeholder-values.types';

export function filterManualCalculationItems(
    calculationItems: ManualCalculationItem[] = [],
    filter: RepairCategory | 'valueIncrease',
): ManualCalculationItem[] {
    switch (filter) {
        case 'spareParts':
            return calculationItems.filter((item) => item.category === 'spareParts');
        case 'laborCosts':
            return calculationItems.filter((item) => item.category === 'laborCosts');
        case 'auxiliaryCosts':
            return calculationItems.filter((item) => item.category === 'auxiliaryCosts');
        case 'paint':
            return calculationItems.filter((item) => item.category === 'paint');
        case 'valueIncrease':
            return calculationItems.filter((item) => item.valueIncreasePercentage);
    }
}

//*****************************************************************************
//  Spare Parts
//****************************************************************************/

export function getSparePartsCalculationItemsPlaceholders(
    calculationItems: ManualCalculationItem[] = [],
    garageFeeSet: GarageFeeSet,
): ReplacementPartItemGerman[] {
    return filterManualCalculationItems(calculationItems, 'spareParts').map((item) => {
        // Different from the *small parts* surcharge, the *spare parts* surcharge is not explicitly printed but included within each part's unit price.
        const unitPrice: number = getSparePartUnitPrice(item.unitPrice, garageFeeSet);

        return {
            Code: getLetterOfRepairCode(item.repairCode) || '',
            ETN: item.partOrWorkItemNumber || '',
            Beschreibung: item.description || '',
            Menge: item.quantity || 0,
            Einzelpreis: convertToEuro(unitPrice),
            Gesamt: convertToEuro(item.quantity * unitPrice),
        };
    });
}

/**
 * Add the UPE surcharge to a unit price.
 * @param originalPrice
 * @param garageFeeSet
 */
function getSparePartUnitPrice(originalPrice: number, garageFeeSet: GarageFeeSet): number {
    const sparePartsSurcharge: number = garageFeeSet?.sparePartsSurcharge || 0;
    return originalPrice * (1 + sparePartsSurcharge / 100);
}

export function getSparePartsTotalPlaceholderObject(
    calculationItems: ManualCalculationItem[] = [],
    garageFeeSet: GarageFeeSet,
): PlaceholderValuesReport['Schadenskalkulation']['ManuelleKalkulation']['Summen']['Ersatzteile'] {
    const smallPartsSurcharge = garageFeeSet?.smallPartsSurcharge || 0;
    const smallPartsSurchargeUnit = garageFeeSet?.smallPartsUnit;

    // Spare parts must be applied the spare part surcharge before applying the small parts surcharge.
    const sparePartsTotal: number = filterManualCalculationItems(calculationItems, 'spareParts').reduce(
        (total, item) => total + item.quantity * getSparePartUnitPrice(item.unitPrice, garageFeeSet),
        0,
    );
    const smallPartsSurchargeAbsolute =
        smallPartsSurchargeUnit === 'percent' ? (sparePartsTotal * smallPartsSurcharge) / 100 : smallPartsSurcharge;

    return {
        Zwischensumme: convertToEuro(sparePartsTotal),
        KleinUndVerbrauchsmaterial: convertToEuro(smallPartsSurchargeAbsolute),
        Summe:
            sparePartsTotal + smallPartsSurchargeAbsolute
                ? convertToEuro(sparePartsTotal + smallPartsSurchargeAbsolute)
                : 0,
    };
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Spare Parts
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Auxiliary Costs
//****************************************************************************/
export function getAuxiliaryCostsCalculationItemsPlaceholders(
    calculationItems: ManualCalculationItem[] = [],
): ReplacementPartItemGerman[] {
    return filterManualCalculationItems(calculationItems, 'auxiliaryCosts').map((item) => {
        return {
            Code: getLetterOfRepairCode(item.repairCode) || '',
            ETN: item.partOrWorkItemNumber || '',
            Beschreibung: item.description || '',
            Menge: item.quantity || 0,
            Einzelpreis: convertToEuro(item.unitPrice),
            Gesamt: convertToEuro(item.quantity * item.unitPrice),
        };
    });
}

export function getAuxiliaryCostsTotalPlaceholderObject(
    calculationItems: ManualCalculationItem[] = [],
): PlaceholderValuesReport['Schadenskalkulation']['ManuelleKalkulation']['Summen']['Nebenkosten'] {
    const auxiliaryCostsTotal: number = filterManualCalculationItems(calculationItems, 'auxiliaryCosts').reduce(
        (total, item) => total + item.quantity * item.unitPrice,
        0,
    );

    return {
        Summe: auxiliaryCostsTotal ? convertToEuro(auxiliaryCostsTotal) : 0,
    };
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Auxiliary Costs
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Labor Costs
//****************************************************************************/
export function getLaborCostsCalculationItemsPlaceholders(
    calculationItems: ManualCalculationItem[] = [],
    garageFeeSet: GarageFeeSet,
): LaborItemGerman[] {
    return filterManualCalculationItems(calculationItems, 'laborCosts').map((item) => {
        return {
            Code: getLetterOfRepairCode(item.repairCode) || '',
            APN: item.partOrWorkItemNumber || '',
            Beschreibung: item.description || '',
            StundenOderAWs: item.quantity ? formatNumberToGermanLocale(item.quantity) : 0,
            Einzelpreis: convertToEuro(item.unitPrice),
            Gesamt: convertToEuro(item.quantity * item.unitPrice),
        };
    });
}

export function getLaborCostsDetailsPlaceholderObject(
    calculationItems: ManualCalculationItem[] = [],
): PlaceholderValuesReport['Schadenskalkulation']['ManuelleKalkulation']['Summen']['Arbeitslohn'] {
    // Sort into categories
    const mechanicItems = calculationItems.filter(
        (item) =>
            item.category === 'laborCosts' &&
            (item.laborType === 'mechanics_1' || item.laborType === 'mechanics_2' || item.laborType === 'mechanics_3'),
    );
    const electricItems = calculationItems.filter(
        (item) =>
            item.category === 'laborCosts' &&
            (item.laborType === 'electrics_1' || item.laborType === 'electrics_2' || item.laborType === 'electrics_3'),
    );
    const carBodyItems = calculationItems.filter(
        (item) =>
            item.category === 'laborCosts' &&
            (item.laborType === 'carBody_1' || item.laborType === 'carBody_2' || item.laborType === 'carBody_3'),
    );
    const dentItems = calculationItems.filter((item) => item.category === 'laborCosts' && item.laborType === 'dents');
    const allLaborItems = [...mechanicItems, ...electricItems, ...carBodyItems, ...dentItems];

    // Sort into difficulties
    const mechanicsLevelOne = mechanicItems.filter((item) => item.laborType === 'mechanics_1');
    const mechanicsLevelTwo = mechanicItems.filter((item) => item.laborType === 'mechanics_2');
    const mechanicsLevelThree = mechanicItems.filter((item) => item.laborType === 'mechanics_3');

    const electricsLevelOne = electricItems.filter((item) => item.laborType === 'electrics_1');
    const electricsLevelTwo = electricItems.filter((item) => item.laborType === 'electrics_2');
    const electricsLevelThree = electricItems.filter((item) => item.laborType === 'electrics_3');

    const carBodyLevelOne = carBodyItems.filter((item) => item.laborType === 'carBody_1');
    const carBodyLevelTwo = carBodyItems.filter((item) => item.laborType === 'carBody_2');
    const carBodyLevelThree = carBodyItems.filter((item) => item.laborType === 'carBody_3');

    return {
        // Hours
        Mechanik1StundenOderAWs: reduceLaborHoursOrWorkUnits(mechanicsLevelOne)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(mechanicsLevelOne))
            : 0,
        Mechanik2StundenOderAWs: reduceLaborHoursOrWorkUnits(mechanicsLevelTwo)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(mechanicsLevelTwo))
            : 0,
        Mechanik3StundenOderAWs: reduceLaborHoursOrWorkUnits(mechanicsLevelThree)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(mechanicsLevelThree))
            : 0,
        Elektrik1StundenOderAWs: reduceLaborHoursOrWorkUnits(electricsLevelOne)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(electricsLevelOne))
            : 0,
        Elektrik2StundenOderAWs: reduceLaborHoursOrWorkUnits(electricsLevelTwo)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(electricsLevelTwo))
            : 0,
        Elektrik3StundenOderAWs: reduceLaborHoursOrWorkUnits(electricsLevelThree)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(electricsLevelThree))
            : 0,
        Karosserie1StundenOderAWs: reduceLaborHoursOrWorkUnits(carBodyLevelOne)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(carBodyLevelOne))
            : 0,
        Karosserie2StundenOderAWs: reduceLaborHoursOrWorkUnits(carBodyLevelTwo)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(carBodyLevelTwo))
            : 0,
        Karosserie3StundenOderAWs: reduceLaborHoursOrWorkUnits(carBodyLevelThree)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(carBodyLevelThree))
            : 0,
        DellenStundenOderAWs: reduceLaborHoursOrWorkUnits(dentItems)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(dentItems))
            : 0,
        // Costs
        Mechanik1: reduceLaborCosts(mechanicsLevelOne) ? convertToEuro(reduceLaborCosts(mechanicsLevelOne)) : 0,
        Mechanik2: reduceLaborCosts(mechanicsLevelTwo) ? convertToEuro(reduceLaborCosts(mechanicsLevelTwo)) : 0,
        Mechanik3: reduceLaborCosts(mechanicsLevelThree) ? convertToEuro(reduceLaborCosts(mechanicsLevelThree)) : 0,
        Elektrik1: reduceLaborCosts(electricsLevelOne) ? convertToEuro(reduceLaborCosts(electricsLevelOne)) : 0,
        Elektrik2: reduceLaborCosts(electricsLevelTwo) ? convertToEuro(reduceLaborCosts(electricsLevelTwo)) : 0,
        Elektrik3: reduceLaborCosts(electricsLevelThree) ? convertToEuro(reduceLaborCosts(electricsLevelThree)) : 0,
        Karosserie1: reduceLaborCosts(carBodyLevelOne) ? convertToEuro(reduceLaborCosts(carBodyLevelOne)) : 0,
        Karosserie2: reduceLaborCosts(carBodyLevelTwo) ? convertToEuro(reduceLaborCosts(carBodyLevelTwo)) : 0,
        Karosserie3: reduceLaborCosts(carBodyLevelThree) ? convertToEuro(reduceLaborCosts(carBodyLevelThree)) : 0,
        Dellen: reduceLaborCosts(dentItems) ? convertToEuro(reduceLaborCosts(dentItems)) : 0,
        // Sums
        StundenOderAWs: reduceLaborHoursOrWorkUnits(allLaborItems)
            ? formatNumberToGermanLocale(reduceLaborHoursOrWorkUnits(allLaborItems))
            : 0,
        Summe: reduceLaborCosts(allLaborItems) ? convertToEuro(reduceLaborCosts(allLaborItems)) : 0,
    };
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Labor Costs
/////////////////////////////////////////////////////////////////////////////*/
//*****************************************************************************
//  Car Paint
//****************************************************************************/
export function getCarPaintCalculationItemsPlaceholders(
    calculationItems: ManualCalculationItem[] = [],
    garageFeeSet: GarageFeeSet,
    paintType: Car['paintType'],
): LacquerItemGerman[] {
    const applicablePaintMaterialSurcharge = getApplicablePaintMaterialSurcharge(paintType, garageFeeSet);
    const carPaintMaterial = applicablePaintMaterialSurcharge.value;
    const carPaintMaterialUnit = garageFeeSet?.carPaint.materialSurchargeUnit;

    return filterManualCalculationItems(calculationItems, 'paint').map((item) => {
        let materialCosts: number;
        if (carPaintMaterialUnit === 'percent') {
            materialCosts = item.quantity * item.unitPrice * (carPaintMaterial / 100);
        }
        // If the assessor decided to use a flat fee, the material for this individual labor position is 0. Instead, the flat material
        // costs will be added in the lacquer summary.
        else if (carPaintMaterialUnit === 'flatFee') {
            materialCosts = 0;
        }

        return {
            Code: getLetterOfRepairCode(item.repairCode) || '',
            Stufe: '',
            Beschreibung: item.description || '',
            StundenOderAWs: item.quantity ? formatNumberToGermanLocale(item.quantity) : 0,
            // This is not the hourly wage but the total wage. Since the material is added to the total price, there is not enough space for
            // another column "hourly wage" in the DOCX document. That's equal to how the DAT deals with this.
            Lohn: item.quantity * item.unitPrice ? convertToEuro(item.quantity * item.unitPrice) : 0,
            Material: materialCosts ? convertToEuro(materialCosts) : 0,
            Gesamt:
                item.quantity * item.unitPrice + materialCosts
                    ? convertToEuro(item.quantity * item.unitPrice + materialCosts)
                    : 0,
        };
    });
}

export function getPaintCostsDetailsPlaceholderObject(
    calculationItems: ManualCalculationItem[] = [],
    garageFeeSet: GarageFeeSet,
    paintType: Car['paintType'],
): PlaceholderValuesReport['Schadenskalkulation']['ManuelleKalkulation']['Summen']['Lackierung'] {
    // Only paint items
    const carPaintItems = filterManualCalculationItems(calculationItems, 'paint');

    const applicablePaintMaterialSurcharge = getApplicablePaintMaterialSurcharge(paintType, garageFeeSet);

    const hours = reduceLaborHoursOrWorkUnits(carPaintItems);
    const costs = reduceLaborCosts(carPaintItems);
    const material: number = getAbsolutePaintMaterialSurcharge({
        materialSurchargeUnit: garageFeeSet?.carPaint.materialSurchargeUnit,
        paintCostsWithoutSurcharge: costs,
        applicablePaintMaterialSurchargeValue: applicablePaintMaterialSurcharge.value,
    });

    return {
        StundenOderAWs: hours ? formatNumberToGermanLocale(hours) : 0,
        Arbeitslohn: costs ? convertToEuro(costs) : 0,
        Materialaufschlag: material ? convertToEuro(material) : 0,
        Summe: costs + material ? convertToEuro(costs + material) : 0,
    };
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Car Paint
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Deductions
//****************************************************************************/
export function getDeductionsCalculationItemsPlaceholders(
    calculationItems: ManualCalculationItem[] = [],
    garageFeeSet: GarageFeeSet,
    paintType: Car['paintType'],
): DeductionItemGerman[] {
    return filterManualCalculationItems(calculationItems, 'valueIncrease').map((item) => {
        let costs = item.quantity * item.unitPrice;
        let deduction: number = (item.quantity * item.unitPrice * item.valueIncreasePercentage) / 100;
        // Paint's surcharges for material is deduced, too. We observed that in the DAT damage calculation.
        if (item.category === 'paint') {
            const applicablePaintMaterialSurcharge = getApplicablePaintMaterialSurcharge(paintType, garageFeeSet);

            const material: number = getAbsolutePaintMaterialSurcharge({
                materialSurchargeUnit: garageFeeSet?.carPaint.materialSurchargeUnit,
                paintCostsWithoutSurcharge: costs,
                applicablePaintMaterialSurchargeValue: applicablePaintMaterialSurcharge.value,
            });
            costs = costs + material;
            deduction = (costs * item.valueIncreasePercentage) / 100;
        }

        return {
            Code: getLetterOfRepairCode(item.repairCode),
            ETN: item.partOrWorkItemNumber || '',
            Beschreibung: item.description || '',
            Art: 'NfA',
            Abzug: deduction ? convertToEuro(deduction) : 0,
            AbzugProzent: item.valueIncreasePercentage,
            Preis: costs ? convertToEuro(costs) : 0,
        };
    });
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Deductions
/////////////////////////////////////////////////////////////////////////////*/
/**
 * Work Units = WU = AW = Arbeitswerte
 * @param items
 */
function reduceLaborHoursOrWorkUnits(items: ManualCalculationItem[]): number {
    return items.reduce((total, item) => total + item.quantity, 0);
}

function reduceLaborCosts(items: ManualCalculationItem[]): number {
    return items.reduce((total, item) => {
        return total + item.quantity * item.unitPrice;
    }, 0);
}

/**
 * Only return the letter of each repair Code.
 * The replacement repair codes ("E-spareParts", "E-laborCosts", "E-auxiliaryCosts") are shortened to "E".
 * @param repairCode
 */
function getLetterOfRepairCode(repairCode: RepairCode): RepairCodeLetter {
    if (!repairCode) return '';

    return repairCode.split('-')[0] as RepairCodeLetter;
}

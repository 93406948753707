import { IsoDate } from '../../../lib/date/iso-date.types';
import { LineItem } from '../../invoices/line-item';
import { DiminishedValueCalculation } from '../diminished-value/diminished-value-calculation';
import { ReplacementValueCorrectionConfig } from '../replacement-value-corrections/replacement-value-correction-config';
import { AutoixpertResidualValueOffer } from '../residual-value/autoixpert-residual-value-offer';
import { AutoonlineResidualValueOffer } from '../residual-value/autoonline-residual-value-offer';
import { ResidualValueBid } from '../residual-value/residual-value-bid';
import { ResidualValueOffer } from '../residual-value/residual-value-offer';
import { AudatexValuation } from './audatex-valuation';
import { ClassicanalyticsValuation } from './classicanalytics-valuation';
import { CustomMarketAnalysis } from './custom-market-analysis';
import { DatValuation } from './dat-valuation';
import { MarketAnalysis } from './market-analysis';
import { OldtimerValuationGrades } from './oldimter-valuation-grades';
import { RestorationDeduction } from './restoration-deduction';
import { ValuepilotMarketAnalysis } from './valuepilot-market-analysis';

export class Valuation {
    //*****************************************************************************
    //  Original Price
    //****************************************************************************/
    /**
     * The amount of money the car cost when purchased from the manufacturer (list price).
     */
    originalPriceWithoutEquipmentNet: number;
    originalPriceWithoutEquipmentGross: number;
    originalPriceWithEquipmentNet: number;
    originalPriceWithEquipmentGross: number;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Original Price
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Residual Value/Scrap Value
    //****************************************************************************/
    residualValue: number;
    /**
     * Not to be confused with "residualValueOfferRemark" which is used for sending notes to potential residual value dealers/buyers.
     * This field "residualValueRemark" will be printed in the report.
     */
    residualValueRemark: string;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Residual Value/Scrap Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Used Vehicle Value
    //****************************************************************************/
    taxationType: TaxationType;
    referenceDate: IsoDate;

    /**
     * The vehicle value is usually equal to one of the other more specific value types, e.g. market value or present value.
     *
     * German "Marktwert"
     * The current market value. Usually used as the replacement value in liability reports. Analyzed
     * by providers who web scan used vehicle exchanges like cartv, WinValue or DAT WebScan.
     *
     * German "Zeitwert"
     * Usually calculated statistically by the DAT. Equals the dealer purchase price.
     *
     * German "Händlereinkaufspreis"/"Händler-EK" and "Händlerverkaufspreis"/"Händler-VK"
     * Dealer prices for procurement and sales. Usually recorded by the DAT. The dealer sales price is assumed to be the market value. In practice,
     * it's usually higher than the market value since dealers provide guarantee promises, check the vehicle etc.
     * Assessors might want to use the dealer purchase price when they create a valuation for an entrepreneur buying a vehicle from his company. The
     * value is lower than the market value and no dealer is involved, so no dealer margin should be added.
     
    * In valuation reports, this will be the dealer purchase price (and may be omitted)
     */
    vehicleValueNet: number;
    vehicleValueGross: number;
    vehicleValueType: ValuationVehicleValueType;
    vehicleValueRemark: string;
    vehicleValueOverwritten: boolean = false;

    /**
     * A second vehicle value for when a report needs two values. Currently, that's the oldtimer report:
     * 1. market value
     * 2. replacement value
     *
     * In valuation reports, this will be the dealer sales price (and may be omitted)
     */
    secondVehicleValueNet: number;
    secondVehicleValueGross: number;
    secondVehicleValueType: ValuationVehicleValueType;
    secondVehicleValueRemark: string;
    secondVehicleValueOverwritten: boolean;

    /**
     * The dealer purchase and sales price are related through their trade margin by definition.
     */
    tradeMarginGrossPercent: number;
    hasTradeMarginBeenChangedManually: boolean;

    /**
     * If there are corrections to the replacement value, this field stores the initial replacement
     * value (without corrections). This is only applicable for liability reports (valuation reports differentiate between dealer sales and purchase price and net / gross).
     */
    vehicleBaseValue?: number;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Used Vehicle Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Valuation Report specific
    //****************************************************************************/
    // German: "Anrechnung" - part of the damage calculation that decreases the vehicle value
    decreaseFromDamagePercentage: number;
    decreaseFromDamageGross: number;
    decreaseFromDamageNet: number;

    // Base Values () for the calculation of the corrected values
    baseValueDealerSalesNet: number;
    baseValueDealerSalesGross: number;
    baseValueDealerPurchaseNet: number;
    baseValueDealerPurchaseGross: number;
    /**
     * The purchase price may be derived from:
     *  - a DAT or Audatex valuation
     *  - a Residual Value Analysis (cartv, WinValue, car.casion, AUTOonline)
     *  - a custom edited value
     */
    baseValueDealerSalesProvider: 'dat' | 'audatex' | 'datWebscan' | MarketAnalysisProvider | 'marketValue'; // marketValue: for legacy (valuations created in the two weeks after the 2024-12 update)
    /**
     * The purchase price may be derived from:
     *  - a DAT or Audatex valuation
     *  - a Residual Value Analysis (cartv, WinValue, car.casion, AUTOonline)
     *  - a custom edited value
     */
    baseValueDealerPurchaseProvider:
        | 'dat'
        | 'audatex'
        | 'cartv'
        | 'autoonline'
        | 'carcasion'
        | 'winvalue'
        | 'axResidualValueRequest'
        | 'custom'
        | 'residualValue'; // residualValue: for legacy (valuations created in the two weeks after the 2024-12 update)

    // Which values are included in the valuation report?
    vehicleValueSalesPriceActive: boolean;
    vehicleValuePurchasePriceActive: boolean;
    /**
     * Which of the two possible value paths shall factor in the corrections made on the valuation screen?
     * The other value (purchase or sales price) will be derived from this value by applying a profit margin.
     */
    correctionsTarget: 'dealerPurchase' | 'dealerSales' = 'dealerPurchase';
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Valuation Report specific
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Diminished Value & Value Increase
    //****************************************************************************/
    /**
     * German "Minderwert" or "Merkantiler Minderwert".
     * The diminished value is the amount of value decrease of the vehicle due to the fact that this vehicle has now had an accident ("Unfallwagen"). People
     * are not willing to pay the same for a used car that had an accident vs. an accident-free vehicle since some damages might have been undiscovered. The
     * insurance is required to pay for the diminished value, too.
     */
    diminishedValueCalculation: DiminishedValueCalculation = new DiminishedValueCalculation();
    archivedDiminishedValueCalculations: DiminishedValueCalculation[] = [];
    diminishedValue: number;

    /**
     * German "Wertverbesserung"
     * The contrast of "Minderwert". Happens very rarely, e.g. if a previous damage is fixed with this repair. In contrast to new-for-old ("Neu-für-alt"), this
     * value increase does not have to be a timely replacement.
     */
    valueIncrease: number;
    valueIncreaseRemark: string;

    /**
     * Replacement value corrections (decreases + increases, German "Auf- und Abwertungen")
     */
    replacementValueCorrectionConfig: ReplacementValueCorrectionConfig;

    /**
     * German "Technischer Minderwert"
     *
     */
    technicalDiminishedValue: number;
    technicalDiminishedValueComment: string;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Diminished Value & Value Increase
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Restoration Costs
    //****************************************************************************/
    /**
     * German "Wiederherstellungskosten"
     * Describes the costs required to bring a comparable vehicle into the state of this vehicle. Usually required only for historic vehicles/oldtimers.
     */
    restorationCostItems: LineItem[] = [];
    /**
     * How much money did the owner spend on restoration (parts, labour, etc.)?
     */
    restorationCosts: number;
    /**
     * In case you can't buy an oldtimer in the same condition: You would need to buy a base vehicle and restore it.
     * This is how much the base vehicle costs. The restorationCosts are how much you need to spend to then restore it.
     */
    restorationBaseVehicleValue: number;
    /**
     * Restoration value = Base vehicle value + restoration costs
     */
    restorationValue: number;
    restorationDeductionItems: RestorationDeduction[] = [];
    restorationDeductions: number;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Restoration Costs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Value Sources & Partner Interfaces
    //****************************************************************************/

    //*****************************************************************************
    //  Residual Value
    //****************************************************************************/
    // TODO Make these true optional properties that will only be initialized if the user uses cartv/WinValue/car.casion
    //  and only when the user creates an offer.
    customResidualValueBids?: ResidualValueBid[] = [];
    includeBiddersWithNoBidInCustomResidualValueSheet: boolean = false;
    // Residual Value Exchanges
    // TODO Make these true optional properties that will only be initialized if the user uses cartv/WinValue/car.casion/AUTOonline
    //  and only when the user creates an offer.
    autoonlineResidualValueOffer?: AutoonlineResidualValueOffer = new AutoonlineResidualValueOffer();
    cartvResidualValueOffer?: ResidualValueOffer = new ResidualValueOffer();
    winvalueResidualValueOffer?: ResidualValueOffer = new ResidualValueOffer();
    carcasionResidualValueOffer?: ResidualValueOffer = new ResidualValueOffer();
    autoixpertResidualValueOffer?: AutoixpertResidualValueOffer = new AutoixpertResidualValueOffer();

    // Configuration options for residual value offers.
    residualValueInquiryTargetDate: string;
    residualValueRegionalRadius: ResidualValueRegionalRadius;
    vehicleLocationZip: string;
    vehicleLocationCity: string;
    useRegionalBidsOnly: boolean;
    /**
     * A remark that's sent to all residual value exchanges. Contains info for potential bidders like "Water damage (Wasserschaden)".
     * Not to be confused with residualValueRemark. "residualValueRemark" is a field the assessor may fill for the report.
     */
    residualValueOfferRemark: string;
    /**
     * Use the insurances quota of the residual value exchange (if available).
     * This is necessary for reports, that are ordered by an insurance.
     * The assessor may decide whether to use the insurance's quota or not on a per report basis.
     */
    useInsuranceDiscountForResidualValue: boolean;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Residual Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Vehicle Valuation
    //****************************************************************************/
    /**
     * German "Marktwertanalyse" and "Gebrauchtwagenanalyse" and "Fahrzeugbewertung"
     * Get the vehicle value of an equivalent vehicle before the damage occurred.
     */
    // TODO Make this a true optional property that will only be initialized if the user uses cartv
    //  and only when the user opens a cartv valuation.
    cartvValuation?: MarketAnalysis = new MarketAnalysis();
    valuepilotValuation?: ValuepilotMarketAnalysis = new ValuepilotMarketAnalysis();
    // TODO Make this a true optional property that will only be initialized if the user uses WinValue
    //  and only when the user opens a WinValue valuation.
    winvalueValuation?: MarketAnalysis = new MarketAnalysis();
    // TODO Make this a true optional property that will only be initialized if the user uses DAT (not Audatex-only)
    //  and only when the user opens a DAT valuation.
    datValuation?: DatValuation = new DatValuation();
    audatexValuation?: AudatexValuation = new AudatexValuation();
    valuationProvider: 'dat' | 'audatex' | 'classicAnalytics' | 'custom';

    customMarketAnalyses: CustomMarketAnalysis[] = [];
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Vehicle Valuation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Oldtimer Valuation
    //****************************************************************************/
    classicanalyticsValuation?: ClassicanalyticsValuation;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Oldtimer Valuation
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Value Sources & Partner Interfaces
    /////////////////////////////////////////////////////////////////////////////*/

    oldtimerValuationGrades?: OldtimerValuationGrades;
}

export type TaxationType = null | 'full' | 'full_16' | 'margin' | 'margin_reduced' | 'neutral';
/**
 * German:
 * dealerPurchase = Händlereinkaufswert
 * dealerSales = Händlerverkaufswert
 * replacementValue = Wiederbeschaffungswert
 * presentValue = Zeitwert
 * marketValue = Marktwert
 * restorationValue = Wiederherstellungswert
 * residualValue = Restwert
 */
export type ValuationVehicleValueType =
    | null
    | 'dealerPurchase'
    | 'dealerSales'
    | 'replacementValue'
    | 'presentValue'
    | 'marketValue'
    | 'restorationValue'
    | 'residualValue';

export type ResidualValueRegionalRadius = null | 30 | 50 | 100 | 200 | 'national' | 'international';

export type MarketAnalysisProvider = 'valuepilot' | 'cartv' | 'carcasion' | 'winvalue' | 'custom' | 'dat' | 'audatex';

<div
    id="default-of-payment-notification-container"
    [ngClass]="{
        warning: getDefaultOfPaymentStatus() === 'reminded',
        'account-deactivated': getDefaultOfPaymentStatus() === 'deactivated',
    }"
    *ngIf="showDefaultOfPaymentNotification && !wasNotificationClosedToday"
    @fadeInAndOut>
    <mat-icon class="margin-right-10 medium-icon">mail</mat-icon>
    <div id="default-of-payment-notification-message">
        {{ getDefaultOfPaymentNotification() }}
        <span class="link" (click)="openInvoiceHistory()">Offene Rechnungen</span>
    </div>
    <mat-icon
        class="material-symbols-outlined small-icon margin-right-8 outlined"
        matTooltip="Wenn Rechnungen 14 Tage überfällig sind, verlierst du Zugriff auf deinen Account.{{
            '\n\n'
        }}Ein Grund für Zahlungsrückstände können auch Rücklastschriften nach einem SEPA-Einzug sein, z. B. aufgrund mangelnder Kontodeckung.">
        help
    </mat-icon>
    <!--********** The close button is only available during the warning. If the account was already deactivated ,the notification cannot be removed. **********-->
    <mat-icon
        id="snooze-icon"
        class="small-icon toolbar-icon"
        *ngIf="getDefaultOfPaymentStatus() === 'reminded'"
        (click)="hideDefaultOfPaymentNotification()"
        matTooltip="Benachrichtigung für heute ausblenden.">
        timer
    </mat-icon>
</div>

<div id="layout-container" *ngIf="report && user">
    <div id="recipients-column">
        <div
            id="report-state-container"
            class="card"
            [ngClass]="{ 'report-done': report.state === 'done', disabled: !userMayLockReport() }"
            (click)="userMayLockReport() && toggleLockReport()"
            [matTooltip]="getLockReportButtonTooltip()">
            <svg
                version="1.1"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                id="report-state-ring-container">
                <circle id="report-state-ring" cx="50" cy="50" r="35"></circle>
            </svg>
            <mat-icon class="outlined" id="lock-report-icon">lock</mat-icon>
        </div>

        <!--============================================-->
        <!-- Recipients -->
        <!--============================================-->
        <recipients-column
            [recipients]="recipients"
            [selectedRecipient]="selectedRecipient"
            (selectedRecipientChange)="selectRecipient($event)"></recipients-column>
        <!--============================================-->
        <!-- END Recipients -->
        <!--============================================-->
    </div>

    <div id="cover-letter-and-email-column">
        <!--============================================-->
        <!-- Cover Letter -->
        <!--============================================-->
        <div id="invoice-letter-container" class="card card-with-padding" *ngIf="activeCoverLetter">
            <h2 id="cover-letter-heading">
                <mat-icon *ngIf="selectedDocumentGroup === 'report'" class="heading-icon">description</mat-icon>
                <div class="heading-icon" *ngIf="selectedDocumentGroup === 'repairConfirmation'">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        enable-background="new 0 0 24 24"
                        xml:space="preserve">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="#A1A1A1"
                            d="M19.692,4.524l-4.166,4.161L12.939,6.1l4.093-4.09 c-2.175-1.017-4.835-0.648-6.631,1.145C8.719,4.835,8.283,7.278,9.07,9.366L1.867,16.56c-0.878,0.877-0.878,2.301,0,3.179
                                c0.878,0.876,2.303,0.876,3.182,0l7.109-7.101c2.158,0.963,4.774,0.578,6.544-1.191C20.582,9.57,20.893,6.747,19.692,4.524z"></path>
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="#A1A1A1"
                            d="M13.652,18.171l2.183,2.149l5.041-5.508l1.449,1.474l-6.307,7.076l-3.763-3.72L13.652,18.171z"></path>
                    </svg>
                </div>
                Anschreiben
            </h2>
            <div id="cover-letter-submenu-container" class="message-submenu-container">
                <mat-icon
                    class="toolbar-icon cover-letter-submenu"
                    matTooltip="Vorlage wählen"
                    (click)="showMessageTemplateSelector()">
                    post_add
                </mat-icon>
                <mat-icon class="toolbar-icon cover-letter-submenu" [matMenuTriggerFor]="coverLetterSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #coverLetterSubmenu="matMenu">
                    <a
                        mat-menu-item
                        (click)="setLetterReceived(selectedRecipient, true)"
                        *ngIf="!selectedRecipient.receivedLetter">
                        hat Brief empfangen
                    </a>
                    <a
                        mat-menu-item
                        (click)="setLetterReceived(selectedRecipient, false)"
                        *ngIf="selectedRecipient.receivedLetter">
                        hat keinen Brief empfangen
                    </a>
                </mat-menu>
            </div>
            <div class="message-container">
                <!--============================================-->
                <!-- Cover Letter Recipient -->
                <!--============================================-->
                <div id="cover-letter-recipient-container" class="message-recipient-container">
                    <div class="message-recipient-icon-container">
                        <img
                            class="message-recipient-icon"
                            [src]="'/assets/images/icons/' + selectedRecipientRole + '-blue.svg'" />
                    </div>
                    <div class="message-recipient-contact-details-container">
                        <div id="cover-letter-recipient">
                            <div *ngIf="selectedRecipient.contactPerson.organization">
                                {{ selectedRecipient.contactPerson.organization }}
                            </div>
                            <div
                                *ngIf="
                                    selectedRecipient.contactPerson.firstName ||
                                    selectedRecipient.contactPerson.firstName
                                ">
                                {{
                                    selectedRecipient.contactPerson.firstName ||
                                        selectedRecipient.contactPerson.salutation
                                }}
                                {{ selectedRecipient.contactPerson.lastName }}
                            </div>
                            <div
                                *ngIf="
                                    !selectedRecipient.contactPerson.organization &&
                                    !selectedRecipient.contactPerson.lastName &&
                                    !selectedRecipient.contactPerson.firstName
                                ">
                                Keine Kontaktdaten
                            </div>
                        </div>
                        <div class="label" [ngSwitch]="selectedRecipientRole">
                            <span *ngSwitchCase="'claimant'">{{ getClaimantDenomination() }}</span>
                            <span *ngSwitchCase="'garage'">Werkstatt</span>
                            <span *ngSwitchCase="'lawyer'">Rechtsanwalt</span>
                            <span *ngSwitchCase="'insurance'">Versicherung</span>
                            <span *ngSwitchCase="'factoringProvider'">Factoring Provider</span>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Cover Letter Recipient -->
                <!--============================================-->

                <!--============================================-->
                <!-- Subject & Message -->
                <!--============================================-->
                <div id="subject-and-date-container">
                    <mat-form-field class="subject mat-block">
                        <input
                            matInput
                            placeholder="Betreff"
                            [(ngModel)]="activeCoverLetter.subject"
                            (input)="determineMissingPlaceholders()"
                            (change)="saveReport()" />
                    </mat-form-field>
                    <date-input [(date)]="activeCoverLetter.date" (dateChange)="saveReport()"></date-input>
                </div>

                <!--********** Message **********-->
                <mat-form-field class="message mat-block">
                    <mat-quill
                        placeholder="Nachricht"
                        [(ngModel)]="activeCoverLetter.body"
                        [minRows]="6"
                        (input)="determineMissingPlaceholders()"
                        (change)="saveReport()"></mat-quill>
                    <mat-hint>Umschalt ⇧ + Enter für Umbruch mit weniger Abstand</mat-hint>
                </mat-form-field>

                <!--********** Warning about Placeholders **********-->
                <div
                    class="info-note warning-note missing-placeholder-warning"
                    *ngIf="missingPlaceholdersInCoverLetters?.length">
                    <mat-icon>warning</mat-icon>
                    <div class="flex-grow">
                        <h3>Platzhalter fehlen</h3>
                        <p>
                            Für manche Platzhalter wurde kein Wert gefunden. Füge die relevanten Informationen ins
                            Gutachten ein oder entferne die Platzhalter.
                        </p>
                        <button
                            id="remove-missing-placeholder-button"
                            (click)="removeMissingPlaceholders()"
                            matTooltip="Lösche alle Platzhalter, für die der Wert im aktuellen Gutachten nicht verfügbar ist.">
                            <mat-icon class="margin-right-5 small-icon">auto_fix_high</mat-icon>
                            Platzhalter ohne Werte entfernen
                        </button>
                    </div>
                </div>

                <!--********** Templates Button **********-->
                <div
                    class="insert-text-template-button-container flex-center-content"
                    *ngIf="!activeCoverLetter.subject && !activeCoverLetter.body"
                    @slideInAndOutVertically>
                    <button (click)="showMessageTemplateSelector()">
                        <mat-icon id="template-button-icon" class="button-icon">post_add</mat-icon>
                        Vorlage einfügen
                    </button>
                </div>
                <!--============================================-->
                <!-- END Subject & Message -->
                <!--============================================-->
            </div>
        </div>
        <!--============================================-->
        <!-- END Cover Letter -->
        <!--============================================-->

        <!--============================================-->
        <!-- E-Mail -->
        <!--============================================-->
        <div id="email-message-height-container">
            <email-editor
                [recipient]="selectedRecipient"
                (recipientChange)="saveReport()"
                [email]="selectedRecipient.outgoingMessageDraft[selectedDocumentGroup]"
                (emailChange)="saveReport()"
                (documentChange)="saveReport()"
                (deactivateDocument)="deactivateFromCurrentFullDocument($event)"
                [report]="report"
                [activeDocuments]="getActiveDocuments()"
                (emailSend)="sendEmail($event)"
                [emailTransmissionPending]="emailTransmissionPending"
                [emailJustSentInfo]="emailJustSentInfo"
                [selectedDocumentGroup]="selectedDocumentGroup"></email-editor>
        </div>
        <!--============================================-->
        <!-- END E-Mail -->
        <!--============================================-->
    </div>

    <div id="documents-and-sent-mail-column">
        <!--============================================-->
        <!-- Info box: Automatic Building Block Selection -->
        <!--============================================-->
        <div
            id="info-box-automatic-building-block-selection"
            class="card with-primary-gradient"
            *ngIf="!user?.userInterfaceStates.printAndTransmission_automaticBuildingBlockSelection_infoPanelClosed"
            @slideOutVertical>
            <img src="/assets/images/icons/building-block-icon-light.svg" />

            <section id="info-box-automatic-building-block-selection-text-column">
                <!--********** Heading **********-->
                <h3 class="no-margin">Automatische Textbausteine</h3>

                <!--********** Body Text **********-->
                <div id="info-box-automatic-building-block-selection-text-body">
                    {{ productName }} wählt anhand deiner Eingaben viele Textbausteine automatisch aus.
                </div>
                <!--********** Buttons **********-->
                <div id="info-box-automatic-building-block-selection-buttons">
                    <div
                        class="info-note-button-primary"
                        (click)="openNewWindow('/Einstellungen/Textbausteine')"
                        matTooltip="Textbausteine in den Einstellungen anpassen">
                        <mat-icon class="small-icon outlined">edit</mat-icon>
                        Texte anpassen
                    </div>
                    <!--                    <div-->
                    <!--                        class="info-note-button-primary"-->
                    <!--                        (click)="-->
                    <!--                            openVideo(-->
                    <!--                                'Textbausteine anpassen',-->
                    <!--                                'https://www.youtube.com/embed/56Y6tv4qDfk?si=6mmH7rOq4WNyUVQ4&autoplay=1&rel=0'-->
                    <!--                            )-->
                    <!--                        "-->
                    <!--                        matTooltip="Video-Überblick, wie die Textbausteine funktionieren">-->
                    <!--                        <mat-icon class="small-icon outlined">smart_display</mat-icon>-->
                    <!--                        Überblick-->
                    <!--                    </div>-->
                </div>
            </section>

            <!--********** Close Icon **********-->
            <mat-icon
                class="dialog-close-icon light"
                (click)="
                    user.userInterfaceStates.printAndTransmission_automaticBuildingBlockSelection_infoPanelClosed = true;
                    saveUser()
                "
                matTooltip="Dauerhaft ausblenden">
                close
            </mat-icon>

            <!--********** Background Icon **********-->
            <mat-icon class="background-icon outlined">info</mat-icon>
        </div>
        <!--============================================-->
        <!-- END Info box: Automatic Building Block Selection -->
        <!--============================================-->

        <!--============================================-->
        <!-- Documents -->
        <!--============================================-->
        <div id="documents-container" class="card card-with-padding">
            <div id="documents-toolbar">
                <mat-icon
                    class="toolbar-icon"
                    (click)="nextSingleUploadDocument = null; fileInput.click()"
                    matTooltip="Neues Dokument hochladen">
                    file_upload
                </mat-icon>
                <mat-icon class="toolbar-icon" [mat-menu-trigger-for]="documentsSubMenu">more_vert</mat-icon>
                <mat-menu #documentsSubMenu>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="addPhotoPortfolioDocument()"
                        *ngIf="!doesPhotoPortfolioExist()">
                        <mat-icon class="outlined">collections</mat-icon>
                        Fotomappe hinzufügen
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-toggle"
                        matTooltip="Ohne Kopf- & Fußzeile kannst du auf vorgedrucktes Briefpapier drucken.{{
                            '\n\n'
                        }}Falls die Dokumente per E-Mail versendet werden, erhalten sie natürlich immer die Kopf- & Fußzeile."
                        (click)="toggleHeaderAndFooterOnDocuments()">
                        <mat-icon class="outlined">description</mat-icon>
                        <span class="menu-item-label">Kopf- & Fußzeile drucken</span>
                        <mat-slide-toggle
                            class="slide-toggle-small margin-left-15"
                            [ngModel]="!userPreferences.printDocumentsWithoutHeaderAndFooter"
                            (ngModelChange)="
                                userPreferences.printDocumentsWithoutHeaderAndFooter =
                                    !userPreferences.printDocumentsWithoutHeaderAndFooter
                            "
                            (click)="$event.stopPropagation()"></mat-slide-toggle>
                    </a>

                    <!--********** Activate Documents After Upload **********-->
                    <a mat-menu-item class="menu-item-with-icon" [mat-menu-trigger-for]="activationSubMenu">
                        <mat-icon class="outlined">indeterminate_check_box</mat-icon>
                        Aktivierung
                        <mat-menu #activationSubMenu>
                            <a mat-menu-item class="menu-item-with-icon" (click)="activateVisibleDocuments()">
                                <mat-icon>check_box</mat-icon>
                                Alle Dokumente aktivieren
                            </a>
                            <a mat-menu-item class="menu-item-with-icon" (click)="deactivateVisibleDocuments()">
                                <mat-icon class="outlined">check_box_outline_blank</mat-icon>
                                Alle Dokumente deaktivieren
                            </a>
                            <a
                                mat-menu-item
                                class="menu-item-with-toggle"
                                matTooltip="Sollen neu hochgeladene Dokumente automatisch für den ausgewählten Beteiligten aktiviert werden?"
                                (click)="
                                    this.userPreferences.activateDocumentsAfterUpload =
                                        !this.userPreferences.activateDocumentsAfterUpload
                                ">
                                <mat-icon class="outlined">task</mat-icon>
                                <span class="menu-item-label">Dokumente nach Upload aktivieren</span>
                                <mat-slide-toggle
                                    class="slide-toggle-small margin-left-15"
                                    [(ngModel)]="userPreferences.activateDocumentsAfterUpload"
                                    (click)="$event.stopPropagation()"></mat-slide-toggle>
                            </a>
                        </mat-menu>
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        [mat-menu-trigger-for]="documentOrderSubMenu"
                        *ngIf="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                        <mat-icon class="outlined">sort</mat-icon>
                        Standard-Reihenfolge
                        <mat-menu #documentOrderSubMenu>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="applyDefaultDocumentOrder()"
                                matTooltip="Die Standard-Reihenfolge für die Dokumente des ausgewählten Beteiligten wiederherstellen.">
                                <mat-icon class="outlined">sort</mat-icon>
                                Auf Dokumente anwenden
                            </a>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="showEditDefaultDocumentOrdersDialog()"
                                matTooltip="Reihenfolge und Zusammensetzung der Dokumente im Gesamt-PDF als Standard konfigurieren.">
                                <mat-icon class="outlined">edit</mat-icon>
                                Bearbeiten
                            </a>
                        </mat-menu>
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        [mat-menu-trigger-for]="addCustomDocumentSubMenu"
                        *ngIf="customDocumentOrderConfigs?.length">
                        <mat-icon class="outlined">attach_file_add</mat-icon>
                        Dokument anhängen
                        <mat-menu #addCustomDocumentSubMenu>
                            <a
                                mat-menu-item
                                *ngFor="let customDocumentConfig of customDocumentOrderConfigs"
                                class="menu-item-with-icon"
                                (click)="addCustomDocument(customDocumentConfig)">
                                {{ customDocumentConfig.titleLong }}
                            </a>
                        </mat-menu>
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="openDocumentBuildingBlocksInNewTab()"
                        *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks">
                        <mat-icon>settings</mat-icon>
                        Textbausteine konfigurieren
                    </a>
                </mat-menu>
            </div>

            <h2>Dokumente</h2>

            <!--============================================-->
            <!-- Document Group Icons -->
            <!--============================================-->
            <div id="document-group-icons-container" *ngIf="report.repairConfirmation">
                <!--********** Report Docs **********-->
                <div class="document-group-icon-container" [ngClass]="{ active: selectedDocumentGroup === 'report' }">
                    <mat-icon
                        class="document-group-icon"
                        (click)="selectDocumentGroup('report'); filterDocuments()"
                        matTooltip="Dokumente zum Gutachten anzeigen">
                        description
                    </mat-icon>
                </div>

                <!--********** Repair Confirmation Docs **********-->
                <div
                    class="document-group-icon-container"
                    [ngClass]="{ active: selectedDocumentGroup === 'repairConfirmation' }"
                    *ngIf="isDocumentGroupAvailable('repairConfirmation')">
                    <div
                        class="document-group-icon"
                        (click)="selectDocumentGroup('repairConfirmation'); filterDocuments()"
                        matTooltip="Dokumente zur Reparaturbestätigung anzeigen">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                fill="#A1A1A1"
                                d="M19.692,4.524l-4.166,4.161L12.939,6.1l4.093-4.09 c-2.175-1.017-4.835-0.648-6.631,1.145C8.719,4.835,8.283,7.278,9.07,9.366L1.867,16.56c-0.878,0.877-0.878,2.301,0,3.179
                                c0.878,0.876,2.303,0.876,3.182,0l7.109-7.101c2.158,0.963,4.774,0.578,6.544-1.191C20.582,9.57,20.893,6.747,19.692,4.524z"></path>
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                fill="#A1A1A1"
                                d="M13.652,18.171l2.183,2.149l5.041-5.508l1.449,1.474l-6.307,7.076l-3.763-3.72L13.652,18.171z"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Document Group Icons -->
            <!--============================================-->

            <!--====================================================-->
            <!-- Info Note If Header and Footer Are Deactivated     -->
            <!--====================================================-->
            <div
                id="header-and-footer-deactivated"
                class="info-note"
                *ngIf="userPreferences.printDocumentsWithoutHeaderAndFooter"
                @fadeInAndOut>
                <mat-icon>info</mat-icon>
                <div class="info-note-text">
                    <div>
                        Kopf- & Fußzeile für Druck deaktiviert.
                        <a class="cursor-pointer" (click)="toggleHeaderAndFooterOnDocuments()">Aktivieren.</a>
                    </div>
                </div>
            </div>
            <!--====================================================-->
            <!-- END Info Note If Header and Footer Are Deactivated -->
            <!--====================================================-->

            <div id="document-list-container" cdkDropList (cdkDropListDropped)="onDocumentReordered($event)">
                <div
                    class="document-container"
                    *ngFor="let document of filteredDocuments; trackBy: trackById"
                    [ngClass]="{
                        excluded: !getDocumentOrderItemByDocument(document)?.includedInFullDocument,
                        'not-available': !isDocumentAvailable(document),
                    }"
                    cdkDrag>
                    <div
                        class="drop-zone"
                        ng2FileDrop
                        [class.file-over]="document === documentHoveredByFile"
                        (fileOver)="onFileOverDropZone($event, document)"
                        (onFileDrop)="onFileDrop($event, document)"
                        [uploader]="uploader">
                        <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>

                        <div class="document-title-container">
                            <div class="document-title-edit" *ngIf="renameModeActiveForDocuments.get(document)">
                                <mat-form-field class="subject mat-block">
                                    <input
                                        matInput
                                        placeholder="Titel"
                                        [(ngModel)]="document.title"
                                        (change)="saveReport()"
                                        (keydown)="renameKeydownListener($event, document)"
                                        [axAutofocus]="true" />
                                </mat-form-field>
                            </div>
                            <!-- Use mousedown to ensure that accidental selection on shift + click is prevented. -->
                            <div
                                class="document-title"
                                *ngIf="!renameModeActiveForDocuments.get(document)"
                                [matTooltip]="getDocumentTooltip(document)"
                                (mousedown)="handleDocumentClick(document, $event)">
                                {{ document.title }}

                                <!--********** For expert statements, also display the first few words of the subject to distinguish each statement from another. **********-->
                                <ng-container *ngIf="document.type === 'expertStatement'">
                                    {{ clipString(document.subject, 30) }}
                                </ng-container>
                            </div>
                        </div>

                        <div class="document-submenu-and-indicators-container">
                            <!--********** Loading indicator **********-->
                            <img
                                class="document-loading-indicator"
                                *ngIf="pendingDocumentDownloads.get(document) || pendingDocumentUploads.get(document)"
                                src="/assets/images/icons/loading-indicator.svg" />

                            <!--********** Warning: Invoice params differ **********-->
                            <mat-icon
                                class="invoice-params-differ-from-invoice-icon warning"
                                *ngIf="
                                    selectedDocumentGroup === 'report' &&
                                    document.type === 'invoice' &&
                                    reportInvoice &&
                                    invoiceParamsDifferFromInvoice &&
                                    reportInvoice?.lockedAt
                                "
                                matTooltip="Die Rechnungsparameter weichen von der festgeschriebenen Rechnung ab. Klicke, um zum Reiter 'Rechnung' zu gelangen, wo du Details einsehen kannst."
                                (click)="navigateToFeesScreen()">
                                warning
                            </mat-icon>

                            <!--********** Invoice locked **********-->
                            <mat-icon
                                class="invoice-locked-icon info-icon"
                                *ngIf="document.type === 'invoice' && isInvoiceLocked()"
                                matTooltip="Gebucht am {{ invoiceLockedAt() | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                                lock
                            </mat-icon>

                            <!--********** Document Signed **********-->
                            <mat-icon
                                class="document-requires-signature-icon warning"
                                matTooltip="Unterschrift fehlt"
                                *ngIf="isDocumentMissingSignature(document)">
                                gesture
                            </mat-icon>

                            <!--********** Edited Document Building Blocks exist **********-->
                            <mat-icon
                                class="edited-document-building-blocks-icon medium-icon cursor-pointer"
                                matTooltip="Textbausteine wurden manuell überschrieben"
                                *ngIf="document.type === 'report' && report.editedDocumentBuildingBlocks?.length"
                                (click)="showBuildingBlockOverwriter()">
                                edit
                            </mat-icon>

                            <!--********** Permanent user upload **********-->
                            <mat-icon
                                class="permanent-user-uploaded-document-icon info-icon"
                                [matTooltip]="
                                    isPermanentDocumentActiveForFutureReports(document)
                                        ? 'Dokument wird jedem neuen Gutachten angehängt'
                                        : 'Dokument wurde automatisch eingefügt, wird aber für künftige Gutachten nicht mehr angehängt.'
                                "
                                *ngIf="
                                    document.type === 'manuallyUploadedPdf' && document.permanentUserUploadedDocument
                                ">
                                storage
                            </mat-icon>

                            <!--********** DOCX Watermark Active **********-->
                            <mat-icon
                                class="warning medium-icon docx-watermark-only-usable-with-active-header-and-footer"
                                *ngIf="
                                    isWatermarkActive(document) && userPreferences.printDocumentsWithoutHeaderAndFooter
                                "
                                matTooltip="Wasserzeichen nur mit aktiver Kopf- & Fußzeile nutzbar.{{
                                    '\n\n'
                                }}Grund: Das Wasserzeichen wird im DOCX-Standard, mit dem die Dokumente erzeugt werden, über die Kopf- & Fußzeile eingebunden.">
                                warning
                            </mat-icon>
                            <mat-icon
                                class="docx-watermark-active-icon medium-icon info-icon"
                                *ngIf="isWatermarkActive(document)"
                                [matTooltip]="getWatermarkActiveIconTooltip(document)">
                                water_drop
                                <div class="watermark-indicator">
                                    {{ getFirstLetterOfWatermarkTypeGerman(document) }}
                                </div>
                            </mat-icon>

                            <!--********** User-uploaded document **********-->
                            <svg
                                class="user-uploaded-document-icon info-icon"
                                *ngIf="document.uploadedDocumentId"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="32px"
                                height="32px"
                                viewBox="0 0 32 32"
                                matTooltip="Eigene PDF wurde hochgeladen">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.109,26.636c0,0-0.106-3.104,1.031-3.883c1.139-0.777,5.557-2.646,7.732-4.159
                                    c0.293-0.203,0.53-1.097,0.078-1.738c-0.527-0.746-0.85-2.034-1.024-3.447c-0.564-0.014-0.579-1.432-0.094-1.453
                                    c-0.104-1.672-0.061-3.187,0.098-4.206c0.258-1.669,2.792-2.284,4.242-2.284c1.451,0,3.294,0.632,3.659,2.284
                                    c0.225,1.015,0.287,2.679,0.16,4.354c0.267,0,0.338,1.259-0.137,1.268c-0.194,1.407-0.536,2.703-1.044,3.482
                                    c-0.428,0.659-0.216,1.535,0.076,1.738c2.177,1.513,6.594,3.382,7.733,4.159c1.139,0.778,1.031,3.883,1.031,3.883H4.109z"></path>
                            </svg>

                            <!--********** Slide Toggle **********-->
                            <mat-slide-toggle
                                class="document-slide-toggle"
                                *ngIf="getDocumentOrderItemByDocument(document)"
                                [(ngModel)]="getDocumentOrderItemByDocument(document).includedInFullDocument"
                                [disabled]="!isDocumentAvailable(document)"
                                (click)="handleToggleClick($event)"
                                (change)="handleToggleChange($event)"
                                [matTooltip]="getTooltipForDocument(document)"></mat-slide-toggle>

                            <!--********** Submenu **********-->
                            <mat-icon
                                class="document-submenu-icon"
                                #documentSubmenuTrigger="matMenuTrigger"
                                [matMenuTriggerFor]="documentSubmenu">
                                more_vert
                            </mat-icon>
                            <mat-menu #documentSubmenu="matMenu">
                                <!--********** Open **********-->
                                <a mat-menu-item class="menu-item-with-icon" (click)="downloadDocument(document)">
                                    <mat-icon>visibility</mat-icon>
                                    {{ report.state === 'done' ? 'Öffnen' : 'Vorschau anzeigen' }}
                                </a>

                                <!--********** Book Invoice **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="lockInvoice(); saveInvoice()"
                                    *ngIf="
                                        document.type === 'invoice' &&
                                        !isInvoiceLocked() &&
                                        getInvoiceForSelectedDocumentGroup()
                                    "
                                    matTooltip="Festgeschriebene Rechnungen können nicht mehr bearbeitet werden.">
                                    <mat-icon>lock</mat-icon>
                                    Festschreiben
                                </a>

                                <!--********** Open Invoice **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    [routerLink]="'/Rechnungen/' + getInvoiceForSelectedDocumentGroup()._id"
                                    *ngIf="document.type === 'invoice' && getInvoiceForSelectedDocumentGroup()">
                                    <mat-icon>edit</mat-icon>
                                    Rechnungseditor
                                </a>

                                <!--********** Edit Building Blocks **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="document.type === 'report'"
                                    [disabled]="isReportLocked()"
                                    (click)="showBuildingBlockOverwriter()"
                                    [matTooltip]="getBuildingBlockOverwriterTooltip()"
                                    [matTooltipPosition]="'right'">
                                    <mat-icon>edit</mat-icon>
                                    Textbausteine überschreiben
                                </a>

                                <!--********** Edit in word **********-->
                                <a
                                    mat-menu-item
                                    *ngIf="isDocxFileAvailable(document)"
                                    (click)="downloadDocument(document, 'docx')"
                                    [matTooltip]="
                                        'DOCX bearbeiten, PDF erstellen und hier wieder hochladen' +
                                        isTeamMatureCustomer()
                                            ? ''
                                            : '.\n\n' + getMessageAboutBlockedDocxDownload()
                                    "
                                    class="menu-item-with-icon"
                                    [matTooltipPosition]="'right'"
                                    [disabled]="!isTeamMatureCustomer()">
                                    <img id="ms-word-icon" src="/assets/images/icons/ms-word-icon.svg" />
                                    In Word bearbeiten
                                </a>

                                <!--********** Upload Custom PDF **********-->
                                <a
                                    mat-menu-item
                                    *ngIf="!document.uploadedDocumentId"
                                    (click)="nextSingleUploadDocument = document; fileInput.click()"
                                    class="menu-item-with-icon">
                                    <mat-icon>upload_file</mat-icon>
                                    Eigene PDF hochladen
                                </a>
                                <!-- Only allow deleting PDFs (instead of the entire document) for non-custom documents. Custom documents must always
                                 be removed completely. -->
                                <a
                                    mat-menu-item
                                    *ngIf="document.uploadedDocumentId && document.type !== 'manuallyUploadedPdf'"
                                    (click)="removeUploadedDocument(document)"
                                    class="menu-item-with-icon">
                                    <mat-icon>clear</mat-icon>
                                    Eigene PDF entfernen
                                </a>

                                <!--********** Rename document **********-->
                                <a
                                    mat-menu-item
                                    *ngIf="mayDocumentBeRenamed(document) && report.state !== 'done'"
                                    (click)="toggleRenamingUploadedDocument(document)"
                                    class="menu-item-with-icon">
                                    <mat-icon>edit</mat-icon>
                                    Umbenennen
                                </a>
                                <!--********** Finish renaming document **********-->
                                <a
                                    mat-menu-item
                                    *ngIf="renameModeActiveForDocuments.get(document)"
                                    (click)="toggleRenamingUploadedDocument(document)"
                                    class="menu-item-with-icon">
                                    <mat-icon>done</mat-icon>
                                    Umbenennen beenden
                                </a>

                                <!--********** Make Custom PDF Permanent **********-->
                                <!-- Currently only supported for the report itself. Expansion to repair confirmation etc. is possible. -->
                                <a
                                    mat-menu-item
                                    *ngIf="
                                        selectedDocumentGroup === 'report' &&
                                        document.type === 'manuallyUploadedPdf' &&
                                        !isPermanentDocumentActiveForFutureReports(document)
                                    "
                                    [matTooltip]="
                                        !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                            ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                            : ''
                                    "
                                    [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                                    (click)="persistCustomDocument(document)"
                                    class="menu-item-with-icon">
                                    <mat-icon>attach_file</mat-icon>
                                    PDF immer anhängen
                                </a>

                                <!--********** Deactivate for Future Reports **********-->
                                <a
                                    mat-menu-item
                                    *ngIf="
                                        selectedDocumentGroup === 'report' &&
                                        document.type === 'manuallyUploadedPdf' &&
                                        isPermanentDocumentActiveForFutureReports(document)
                                    "
                                    [matTooltip]="
                                        !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                            ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                            : ''
                                    "
                                    [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                                    (click)="removePermanentDocumentForFutureReports(document)"
                                    class="menu-item-with-icon"
                                    matTooltip="Dieser Anhang bleibt in alten Gutachten verfügbar, wird aber in neuen nicht mehr angehängt.">
                                    <mat-icon>block</mat-icon>
                                    Künftig nicht anhängen
                                </a>

                                <!--***********************************************
                                ** DOCX Watermark
                                ************************************************-->
                                <ng-container *ngIf="isWatermarkPossible(document)">
                                    <!--********** Add Watermark to DOCX File **********-->
                                    <a
                                        mat-menu-item
                                        [matMenuTriggerFor]="docxWatermarkSubmenu"
                                        class="menu-item-with-icon">
                                        <mat-icon>water_drop</mat-icon>
                                        <div class="option-text">Wasserzeichen</div>
                                        <!--**********
                                        $event.stopPropagation(): Prevent the next level submenu from opening when clicking
                                                                  the gear icon.
                                        **********-->
                                        <mat-icon
                                            (click)="
                                                $event.stopPropagation();
                                                documentSubmenuTrigger.closeMenu();
                                                openDocxWatermarkSettings()
                                            "
                                            [class.disabled]="!mayUserOpenWatermarkSettings()"
                                            [matTooltip]="getDocxWatermarkSettingsTooltip()"
                                            class="toolbar-icon">
                                            settings
                                        </mat-icon>
                                    </a>

                                    <mat-menu #docxWatermarkSubmenu>
                                        <!--********** Duplicate **********-->
                                        <a
                                            mat-menu-item
                                            (click)="
                                                activateWatermarkConfig({
                                                    documentMetadata: document,
                                                    docxWatermarkType: 'duplicate',
                                                })
                                            "
                                            matTooltip="Dokument wird als Duplikat gekennzeichnet.{{
                                                '\n\n'
                                            }}Shortcut: d + Klick auf das Dokument."
                                            class="menu-item-with-icon menu-item-with-check-mark">
                                            <mat-icon class="outlined">file_copy</mat-icon>
                                            <div class="menu-item-label">Duplikat</div>
                                            <mat-icon
                                                class="selection-icon toolbar-icon"
                                                [class.hidden]="document.docxWatermarkType !== 'duplicate'">
                                                check
                                            </mat-icon>
                                        </a>

                                        <!--********** Draft **********-->
                                        <a
                                            mat-menu-item
                                            (click)="
                                                activateWatermarkConfig({
                                                    documentMetadata: document,
                                                    docxWatermarkType: 'draft',
                                                })
                                            "
                                            matTooltip="Dokument wird als Entwurf gekennzeichnet.{{
                                                '\n\n'
                                            }}Shortcut: e + Klick auf das Dokument."
                                            class="menu-item-with-icon menu-item-with-check-mark">
                                            <mat-icon class="outlined">draw</mat-icon>
                                            <div class="menu-item-label">Entwurf</div>
                                            <mat-icon
                                                class="selection-icon toolbar-icon"
                                                [class.hidden]="document.docxWatermarkType !== 'draft'">
                                                check
                                            </mat-icon>
                                        </a>

                                        <!--********** None **********-->
                                        <a
                                            mat-menu-item
                                            (click)="deactivateWatermark(document)"
                                            class="menu-item-with-icon menu-item-with-check-mark">
                                            <mat-icon class="outlined">close</mat-icon>
                                            <div class="menu-item-label">Keins</div>
                                            <mat-icon
                                                class="selection-icon toolbar-icon"
                                                [class.hidden]="isWatermarkActive(document)">
                                                check
                                            </mat-icon>
                                        </a>
                                    </mat-menu>
                                </ng-container>
                                <!--***********************************************
                                ** END DOCX Watermark
                                ************************************************-->

                                <!--***********************************************
                                ** Delete
                                ************************************************-->
                                <!--********** Delete (default) **********-->
                                <a
                                    mat-menu-item
                                    *ngIf="
                                        mayDocumentBeDeleted(document) &&
                                        report.state !== 'done' &&
                                        !document.permanentUserUploadedDocument
                                    "
                                    (click)="deleteCustomDocument(document); filterDocuments()"
                                    class="menu-item-with-icon">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>

                                <!--********** Delete (permanent user-uploads) **********-->
                                <a
                                    mat-menu-item
                                    *ngIf="
                                        mayDocumentBeDeleted(document) &&
                                        report.state !== 'done' &&
                                        document.permanentUserUploadedDocument
                                    "
                                    [matMenuTriggerFor]="documentDeleteSubmenu"
                                    class="menu-item-with-icon">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>
                                <mat-menu #documentDeleteSubmenu="matMenu">
                                    <!--********** For this report only **********-->
                                    <a
                                        mat-menu-item
                                        (click)="deletePermanentDocumentFromThisReport(document)"
                                        class="menu-item-with-icon"
                                        matTooltip="Dieser Anhang wird aus diesem Vorgang entfernt, wird aber weiterhin bei neuen Gutachten angehängt.">
                                        <mat-icon>description</mat-icon>
                                        Nur für dieses Gutachten
                                    </a>

                                    <!--********** For all reports **********-->
                                    <a
                                        mat-menu-item
                                        (click)="deleteCustomDocument(document); filterDocuments()"
                                        class="menu-item-with-icon"
                                        matTooltip="Dieser Anhang wird zentral entfernt. Er ist also auch in alten Gutachten nicht mehr abrufbar.">
                                        <mat-icon>public</mat-icon>
                                        Für alle Gutachten
                                    </a>
                                </mat-menu>
                                <!--***********************************************
                                ** END Delete
                                ************************************************-->

                                <!--********** Edit File Name Pattern **********-->
                                <a
                                    mat-menu-item
                                    [matMenuTriggerFor]="documentConfigSubmenu"
                                    class="menu-item-with-icon"
                                    *ngIf="isFileNameCustomizable(document)">
                                    <mat-icon>settings</mat-icon>
                                    Einstellungen
                                </a>

                                <mat-menu #documentConfigSubmenu>
                                    <!--********** Edit File Name Pattern **********-->
                                    <a
                                        mat-menu-item
                                        (click)="
                                            openFileNamePatternEditor(
                                                document.type,
                                                document.customDocumentOrderConfigId
                                            )
                                        "
                                        class="menu-item-with-icon">
                                        <mat-icon class="outlined">edit</mat-icon>
                                        Muster für Dateinamen ändern
                                    </a>
                                </mat-menu>
                            </mat-menu>
                        </div>
                    </div>
                </div>

                <!--============================================-->
                <!-- New Document Drop Zone -->
                <!--============================================-->
                <div class="document-container">
                    <div
                        class="drop-zone new-document-zone"
                        ng2FileDrop
                        *ngIf="fileIsOverBody"
                        [class.file-over]="fileOverNewDocumentDropZone"
                        (fileOver)="onFileOverNewDocumentDropZone($event)"
                        (onFileDrop)="onFileDrop($event, null)"
                        [uploader]="uploader">
                        + Neues Dokument hochladen
                    </div>
                </div>
                <!--============================================-->
                <!-- END New Document Drop Zone -->
                <!--============================================-->

                <input
                    style="display: none"
                    type="file"
                    ng2FileSelect
                    [uploader]="uploader"
                    (change)="handleFileUploadEvent($event, nextSingleUploadDocument)"
                    #fileInput
                    accept="application/pdf" />
            </div>

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="print-and-transmission"
                [report]="report"
                [useNegativeBottomMarginForCards]="false"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->

            <!--============================================-->
            <!-- Note on Locking Reports -->
            <!--============================================-->
            <div
                class="info-note"
                @slideInAndOutVertically
                *ngIf="user?.accessRights.lockReports && !user.tutorialState.reportLocked">
                <mat-icon class="outlined">info</mat-icon>
                <div>
                    <h3>Gutachten abschließen</h3>
                    <div>
                        Damit wird es als erledigt markiert und die Rechnung wird in die Rechnungsliste übertragen.
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Note on Locking Reports -->
            <!--============================================-->

            <!--********** Full Document Configuration Info Note **********-->
            <div class="info-note full-document-config-info-note" *ngIf="fullDocumentConfigNoteVisible">
                <mat-icon class="medium-icon">info</mat-icon>
                <div class="info-note-text">
                    <h3>Reihenfolge geändert</h3>
                    <div>
                        Du kannst einen Standard für die Reihenfolge und Zusammensetzung des Gesamt-PDFs pro Gutachten-
                        und Empfängertyp definieren.
                    </div>
                    <span
                        class="link"
                        (click)="showEditDefaultDocumentOrdersDialog()"
                        [matTooltip]="
                            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : ''
                        "
                        [class.disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                        Standard-Reihenfolge bearbeiten
                    </span>
                </div>
                <mat-icon class="toolbar-icon" (click)="closeFullDocumentConfigNote()">close</mat-icon>
            </div>

            <div
                *ngIf="
                    isIosDevice &&
                    isInvoiceActive() &&
                    report.feeCalculation?.invoiceParameters?.isElectronicInvoiceEnabled
                "
                class="info-note warning-note">
                <mat-icon class="medium-icon outlined">warning</mat-icon>
                <div>
                    Die E-Rechnung wird nicht in die Gesamt-PDF integriert. Du musst sie separat herunterladen und
                    speichern.
                </div>
            </div>

            <div id="document-buttons-container">
                <button
                    id="download-full-document-button"
                    (click)="getActiveDocuments().length > 0 && downloadFullDocument()"
                    [matTooltip]="
                        getActiveDocuments().length
                            ? 'Alle aktivierten Dokumente als eine große PDF herunterladen'
                            : 'Mindestens ein Dokument muss aktiv sein.'
                    "
                    [ngClass]="{ disabled: getActiveDocuments().length === 0 }">
                    <img
                        id="report-pdf-download-pending-icon"
                        class="pending-icon"
                        *ngIf="fullDocumentLoading"
                        src="/assets/images/icons/loading-indicator-white.svg"
                        matTooltip="Der PDF-Download wird vorbereitet..."
                        matTooltipShowDelay="300" />
                    Gesamt-PDF
                </button>

                <!--********** Lock Report **********-->
                <button
                    id="lock-button"
                    class="secondary"
                    *ngIf="report.state !== 'done' && userMayLockReport()"
                    (click)="userMayLockReport() && toggleLockReport()"
                    [matTooltip]="getLockReportButtonTooltip()">
                    <mat-icon class="button-icon outlined">lock</mat-icon>
                    Abschließen
                </button>
                <button
                    id="unlock-button"
                    class="secondary"
                    *ngIf="report.state === 'done' && userMayLockReport()"
                    (click)="userMayLockReport() && toggleLockReport()"
                    [matTooltip]="getLockReportButtonTooltip()">
                    Entsperren
                </button>

                <!--********** ADELTA.FINANZ **********-->
                <button
                    *ngIf="
                        selectedRecipientRole === 'claimant' &&
                        user.adeltaFinanzUser.customerNumber &&
                        !adeltaFinanzExportPending &&
                        selectedDocumentGroup === 'report'
                    "
                    class="secondary"
                    [class.disabled]="!isAdeltafinanzExportAllowed()"
                    [matTooltip]="getTooltipForAdeltafinanzButton()"
                    (click)="isAdeltafinanzExportAllowed() && exportInvoiceToAdeltaFinanz()">
                    ADELTA.FINANZ
                </button>
                <button
                    *ngIf="
                        selectedRecipientRole === 'claimant' &&
                        user.adeltaFinanzUser.customerNumber &&
                        adeltaFinanzExportPending &&
                        selectedDocumentGroup === 'report'
                    "
                    class="secondary">
                    <img
                        class="pending-icon"
                        src="/assets/images/icons/loading-indicator.svg"
                        matTooltip="Der Rechnungsexport wird vorbereitet..."
                        [matTooltipShowDelay]="300" />
                    Exportiere...
                </button>

                <!--********** KfzVS **********-->
                <button
                    *ngIf="
                        selectedRecipientRole === 'claimant' &&
                        user.kfzvsUser &&
                        user.kfzvsUser.customerNumber &&
                        selectedDocumentGroup === 'report'
                    "
                    class="secondary"
                    [ngClass]="{ disabled: !isKfzvsExportAllowed() }"
                    [matTooltip]="getTooltipForKfzvsButton()"
                    (click)="exportInvoiceToKfzvs()">
                    <img
                        src="/assets/images/icons/loading-indicator-white.svg"
                        alt=""
                        class="pending-icon"
                        *ngIf="kfzvsExportPending" />
                    KfzVS
                    <mat-icon
                        id="invoice-exported-to-kfzvs-icon"
                        class="small-icon"
                        *ngIf="report.invoiceExportedToKfzvs">
                        check
                    </mat-icon>
                </button>

                <!--********** Persaldo **********-->
                <button
                    *ngIf="
                        selectedRecipientRole === 'claimant' &&
                        arePersaldoCredentialsComplete() &&
                        selectedDocumentGroup === 'report'
                    "
                    class="secondary"
                    [ngClass]="{ disabled: !isPersaldoExportAllowed() }"
                    [matTooltip]="getTooltipForPersaldoButton()"
                    (click)="exportInvoiceToPersaldo()">
                    <img
                        src="/assets/images/icons/loading-indicator-white.svg"
                        alt=""
                        class="pending-icon"
                        *ngIf="persaldoExportPending" />
                    Goya Mobility
                    <mat-icon
                        id="invoice-exported-to-persaldo-icon"
                        class="small-icon"
                        *ngIf="report.persaldoCaseNumber">
                        check
                    </mat-icon>
                </button>

                <!--********** crashback24 **********-->
                <!-- Show the button even if the credentials are now incomplete but a crashback24 process was created before the credentials were deleted. -->
                <button
                    *ngIf="
                        selectedRecipientRole === 'lawyer' &&
                        selectedDocumentGroup === 'report' &&
                        (areCrashback24CredentialsComplete() || report.crashback24ProcessId)
                    "
                    class="secondary"
                    [ngClass]="{ disabled: !isCrashback24ExportAllowed() }"
                    [matTooltip]="getTooltipForCrashback24Button()"
                    (click)="handleCrashback24Click($event)">
                    <img
                        src="/assets/images/icons/loading-indicator-white.svg"
                        alt=""
                        class="pending-icon"
                        *ngIf="crashback24ExportPending" />
                    crashback24
                    <mat-icon
                        id="crashback24-process-created-icon"
                        class="small-icon"
                        *ngIf="report.crashback24ProcessId">
                        check
                    </mat-icon>
                </button>
            </div>

            <div id="report-done-label-container" *ngIf="report.state === 'done'">
                <span
                    id="report-done-label"
                    class="label"
                    matTooltip="{{ report.lockedAt | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                    {{ getReportLockedMessage() }}
                </span>
            </div>
        </div>
        <!--============================================-->
        <!-- END Documents -->
        <!--============================================-->

        <!--============================================-->
        <!-- Sent emails -->
        <!--============================================-->
        <div id="message-list-container" class="card" *ngIf="outgoingMessages && outgoingMessages.length > 0">
            <div id="message-list">
                <outgoing-messages-list
                    [selectedRecipientRole]="selectedRecipientRole"
                    [outgoingMessages]="outgoingMessages"
                    (editAsNew)="editAsNew($event)"></outgoing-messages-list>
            </div>
        </div>
        <!--============================================-->
        <!-- END Sent emails -->
        <!--============================================-->
    </div>
</div>
<!--********** Message templates for cover letter. Email templates are handled by the email editor component. **********-->
<message-template-selector
    *ngIf="messageTemplateSelectorShown"
    [report]="report"
    [recipientRole]="selectedRecipientRole"
    [letterDocument]="activeCoverLetter"
    templateType="coverLetter"
    (close)="hideMessageTemplateSelector()"
    (templateSelection)="insertTemplateText($event)"
    @runChildAnimations></message-template-selector>

<overwrite-document-building-blocks
    *ngIf="buildingBlockOverwriterShown"
    [reportId]="report._id"
    [(editedBuildingBlocks)]="report.editedDocumentBuildingBlocks"
    (editedBuildingBlocksChange)="saveReport()"
    (close)="buildingBlockOverwriterShown = false"
    @runChildAnimations></overwrite-document-building-blocks>

<!--********** File Name Pattern **********-->
<file-name-pattern-editor
    *ngIf="documentForFileNameEditor"
    [selectedDocumentType]="documentForFileNameEditor.documentType"
    [selectedDocumentOrderConfigId]="documentForFileNameEditor.documentOrderConfigId"
    [reportId]="report._id"
    (close)="documentForFileNameEditor = null"
    @runChildAnimations></file-name-pattern-editor>

<!--********** Full Document Configuration **********-->
<default-document-order-dialog
    *ngIf="editDefaultDocumentsDialogVisible"
    (close)="editDefaultDocumentsDialogVisible = false"
    @runChildAnimations></default-document-order-dialog>

import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';
import { DefaultDocumentOrderItem } from '@autoixpert/models/teams/default-document-order/default-document-order-item';

/**
 * Returns the Default Document Order Item that matches the given document. That is relevant when inserting a
 * document into an existing document order in a report or invoice because the document's position is derived from the
 * default document order.
 */
export function getDefaultDocumentOrderItemForDocument({
    document,
    defaultDocumentOrder,
}: {
    document: DocumentMetadata;
    defaultDocumentOrder: DefaultDocumentOrder;
}): DefaultDocumentOrderItem {
    /**
     * Permanent uploaded documents such as "Allgemeine Geschäftsbedingungen" (AGBs) are of the type "manuallyUploadedPdf" and need to be identified by
     * their uploadedDocumentId to be unique.
     */
    if (document.type === 'manuallyUploadedPdf') {
        return defaultDocumentOrder.items.find(
            (defaultDocumentOrderItem) =>
                defaultDocumentOrderItem.documentType === document.type &&
                defaultDocumentOrderItem.uploadedDocumentId === document.uploadedDocumentId,
        );
    } else if (document.type === 'customDocument') {
        return defaultDocumentOrder.items.find(
            (defaultDocumentOrderItem) =>
                defaultDocumentOrderItem.documentType === document.type &&
                defaultDocumentOrderItem.customDocumentOrderConfigId === document.customDocumentOrderConfigId,
        );
    }

    /**
     * Other document types exist only once in the default document order.
     */
    return defaultDocumentOrder.items.find(
        (defaultDocumentOrderItem) => defaultDocumentOrderItem.documentType === document.type,
    );
}

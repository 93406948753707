import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as IBAN from 'iban';
import { Observable } from 'rxjs';
import { map as observableMap } from 'rxjs/operators';

@Injectable()
export class BicService {
    constructor(private httpClient: HttpClient) {}

    public getBicAndBankNameByIban(iban: string): Observable<BicAndBankName> {
        if (!iban) throw Error('NO_IBAN_PROVIDED. Please provide an IBAN for the BIC service.');

        if (!IBAN.isValid(iban)) {
            throw Error('IBAN_INVALID. Please provide a valid IBAN for the BIC service.');
        }

        return this.httpClient
            .get<OpenIbanResponse>(
                `https://openiban.com/validate/${IBAN.electronicFormat(iban)}?getBIC=true&validateBankCode=true`,
            )
            .pipe(
                observableMap((response) => {
                    return {
                        bankName: response.bankData.name,
                        bic: response.bankData.bic,
                    };
                }),
            );
    }

    // public getBankName(bic: string): Observable<string> {
    //
    //     if (!bic) throw Error("NO_BIC_PROVIDED. Please provide a Business Identifier Code for the BIC service.");
    //     if (bic.length !== 8 && bic.length !== 11) throw Error("ZIP_SHORTER_THAN_8_DIGITS");
    //
    //     return this.httpClient.get<BicBankMap[]>(`${this.apiPath}/${bic}`)
    //         .pipe(
    //             observableMap(response => {
    //                 if (response.length === 0) {
    //                     return "";
    //                 }
    //                 return response[0].bank;
    //             }));
    // }
}

class BicBankMap {
    bank: string;
    bic: string;
}

interface OpenIbanResponse {
    valid: true; // either true or false
    messages: string[]; // states the reason if valid is false. E.g. "Bank code valid: 37040044"
    iban: string; // the IBAN which was to be validated
    bankData: {
        bankCode: string;
        name: string;
        zip: string;
        city: string;
        bic: string;
    };
    checkResults: {
        bankCode: true; // info about your additional check requests
    };
}

export interface BicAndBankName {
    bankName: string;
    bic: string;
}

import { Report } from '../../../models/reports/report';

/**
 * Checks if replacement value corrections exist.
 */
export const replacementValueCorrectionExists = (report: Report): boolean => {
    /**
     * Valuation reports do not set the generic vehicle base value but have more specific values (e.g. net, gross, sales/purchase)
     * Use a different check to avoid breaking existing partials in liability reports
     */
    if (report.type === 'valuation') {
        return (
            report.valuation.replacementValueCorrectionConfig &&
            /**
             * Don't check the totals since assessors may want to document corrections without assigning a value (especially in valuation reports).
             * Don't check for the array length, because we always store empty lines that are displayed before the user starts editing.
             */
            (report.valuation.replacementValueCorrectionConfig.increases?.some((increase) => !!increase.description) ||
                report.valuation.replacementValueCorrectionConfig.decreases?.some((increase) => !!increase.description))
        );
    }
    return (
        report.valuation.vehicleBaseValue &&
        report.valuation.replacementValueCorrectionConfig &&
        /**
         * Don't check the totals since assessors may want to document corrections without assigning a value (especially in valuation reports).
         * Don't check for the array length, because we always store empty lines that are displayed before the user starts editing.
         */
        (report.valuation.replacementValueCorrectionConfig.increases?.some((increase) => !!increase.description) ||
            report.valuation.replacementValueCorrectionConfig.decreases?.some((increase) => !!increase.description))
    );
};

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'analytics-settings-menu',
    templateUrl: './analytics-settings-menu.component.html',
    styleUrls: ['./analytics-settings-menu.component.scss'],
})
export class AnalyticsSettingsMenuComponent implements OnInit {
    /**
     * If set, displays a help indicator icon next to the use net values
     * setting with the given tooltip.
     */
    @Input() useNetValuesHelpIndicatorTooltip: string = '';

    @Input() useNetValues: boolean = true;
    @Output() useNetValuesChange: EventEmitter<boolean> = new EventEmitter();
    /**
     * If set, the selected settings are stored specifically under the given key and do
     * not influence the settings of other analytics pages.
     */
    @Input() localStoragePostfix: string = '';

    /**
     * Default value for the useNetValues setting (used until the user changed it)
     */
    @Input() useNetValuesDefaultValue: boolean = true;

    ngOnInit() {
        this.getUseNetValuesFromLocalStorage();
    }

    protected toggleUseNetValues(): void {
        this.useNetValues = !this.useNetValues;
        this.useNetValuesChange.emit(this.useNetValues);
        this.rememberUseNetValuesLocally();
    }

    private getUseNetValuesFromLocalStorage(): void {
        try {
            this.useNetValues = store.get(
                'analyticsUseNetValues' + this.localStoragePostfix,
                this.useNetValuesDefaultValue,
            );
        } catch (err: unknown) {
            // Catch errors in case the browser does not support local storage or the storage is full
            // Do nothing as this feature is not critical
        }
    }

    rememberUseNetValuesLocally(): void {
        try {
            store.set('analyticsUseNetValues' + this.localStoragePostfix, this.useNetValues);
        } catch (err: unknown) {
            // Catch errors in case the browser does not support local storage or the storage is full
            // Do nothing as this feature is not critical
        }
    }
}

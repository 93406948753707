import { Component, EventEmitter, Input, Output } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';

/**
 * A list of templates of varying types.
 */
@Component({
    selector: 'record-template-selector',
    templateUrl: 'record-template-selector.component.html',
    styleUrls: ['record-template-selector.component.scss'],
})
export class RecordTemplateSelectorComponent<Record> {
    constructor() {}

    // The templates have to fulfil a certain interface. The union with Record allows stronger typing for the emission methods.
    @Input() set templates(templates: ({ title: string; dragOrderPosition?: number; createdBy: string } & Record)[]) {
        this.sortedTemplates = [...templates].sort((templateA, templateB) => {
            // If the drag order is the same or not present, compare the title.
            if (templateA.dragOrderPosition == templateB.dragOrderPosition) {
                // Treat null and undefined as equal
                return (templateA.title || '').localeCompare(templateB.title);
            }

            return templateA.dragOrderPosition < templateB.dragOrderPosition ? -1 : 1;
        });
    }

    // Instead of the default icon, a function may be provided that returns a string.
    @Input() detailTextGetter: (Record) => string;
    @Input() deleteAllowed: boolean;

    /**
     * Parent is responsible for deletion on server.
     */
    @Output() templateDeletion: EventEmitter<Record> = new EventEmitter<Record>();

    @Output() templateUsage: EventEmitter<Record> = new EventEmitter<Record>();
    @Output() noTemplateUsage: EventEmitter<void> = new EventEmitter<void>();

    public sortedTemplates: this['templates'] = [];
    public selectedTemplate: Record;

    ngOnInit() {}

    public selectTemplate(template: Record): void {
        // Allow de-selection
        if (this.selectedTemplate === template) {
            this.selectedTemplate = null;
        } else {
            this.selectedTemplate = template;
        }
    }

    public emitTemplateDeletion(template: Record): void {
        // Deleted locally
        removeFromArray(template, this.sortedTemplates);
        // Parent is responsible for deletion on server.
        this.templateDeletion.emit(template);
    }

    public useTemplate(template: Record) {
        this.templateUsage.emit(template);
    }

    public proceedWithoutTemplate(): void {
        this.noTemplateUsage.emit();
    }
}

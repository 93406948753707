import { Component, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { Subtask, Task } from '@autoixpert/models/tasks/task';

@Component({
    selector: 'task-details-subtasks',
    templateUrl: './task-details-subtasks.component.html',
    styleUrls: ['./task-details-subtasks.component.scss'],
})
export class TaskDetailsSubtasksComponent {
    constructor() {}

    @Input() task: Task;
    @Output('taskChanged') taskChanged: EventEmitter<Task> = new EventEmitter<Task>();

    newSubtask: Subtask;

    addTask(insertAfter?: Subtask) {
        this.newSubtask = new Subtask();
        const indexOfInsertAfter = this.task.subtasks.indexOf(insertAfter);
        if (indexOfInsertAfter > -1) {
            this.task.subtasks.splice(indexOfInsertAfter + 1, 0, this.newSubtask);
        } else {
            this.task.subtasks.push(this.newSubtask);
        }
    }

    deleteTask(subtask: Subtask) {
        const indexToDelete = this.task.subtasks.indexOf(subtask);
        this.task.subtasks.splice(indexToDelete, 1);
        this.saveTask();
    }

    saveTask() {
        this.taskChanged.emit(this.task);
    }
}

import { formatNumber } from '@angular/common';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { ImportHistory } from '@autoixpert/models/import-history';
import { Team } from '@autoixpert/models/teams/team';
import { slideInHorizontally } from '../../shared/animations/slide-in-horizontally.animation';
import { isCsvFile } from '../../shared/libraries/file-types/is-csv-file';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { ImportHistoryService } from '../../shared/services/import-history.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';

@Component({
    selector: 'legacy-reports-import-dialog',
    templateUrl: 'legacy-reports-import-dialog.component.html',
    styleUrls: ['legacy-reports-import-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), slideInHorizontally()],
})
export class LegacyReportsImportDialogComponent implements OnInit {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private apiErrorService: ApiErrorService,
        private importHistoryService: ImportHistoryService,
        private networkStatusService: NetworkStatusService,
        private tutorialStateService: TutorialStateService,
    ) {}

    team: Team;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() uploadSuccessful: EventEmitter<void> = new EventEmitter<void>();

    // Uploader instance for uploading csv file
    uploader: FileUploader;
    numberOfImportedLegacyReports: number = 0;
    fileUploadPending: boolean = false;
    fileIsOverBody: boolean = false;
    fileIsOverDropZone: boolean = false;
    fileOverBodyTimeoutCache: number;
    fileOverDropzoneTimeoutCache: number;

    selectedSourceSystem: LegacyReportImportSourceSystem = null;
    customSourceSystemName: string;
    sourceSystemAutocompleteOptions: string[] = ['CombiPlus', 'Dynarex', 'neXtsoft'];
    filteredSourceSystemAutocompleteOptions: string[] = [];

    private subscriptions: Subscription[] = [];

    // Import History
    public importHistories: ImportHistory[] = [];
    public importHistoryIdBeingDeleted: ImportHistory['_id'];

    // Available source systems for import
    public readonly sourceSystems: LegacyReportImportSourceSystem[] = ['audatex', 'easyexpert', 'custom'];

    ngOnInit() {
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));
        this.initializeUploader();
        this.getImportHistories();
    }

    //*****************************************************************************
    //  File Upload
    //****************************************************************************/
    private initializeUploader(): void {
        this.uploader = new FileUploader({
            url: undefined,
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
            itemAlias: 'file-with-legacy-reports',
        });

        this.updateUploaderUrl();

        // After selecting files for the upload, validate all files, then start the upload.
        this.uploader.onAfterAddingAll = async () => {
            for (let index = 0; index < this.uploader.queue.length; index++) {
                const item: FileItem = this.uploader.queue[index];

                // Of course, we don't have to update the same file multiple times.
                if (item.isReady || item.isUploading || item.isUploaded) {
                    continue;
                }
                // If the mime type is neither png nor jpeg, remove the image from the queue
                // CSV files are used for most imports. XML files are used for SAXIF imports from Audatex AudaPad 3.
                if (!isCsvFile(item._file) && item._file.type !== 'text/xml') {
                    console.error('The given file is not a CSV or XML file.', item);
                    this.toastService.error(
                        'Ungültiges Dateiformat',
                        `Die Datei "${item._file.name}" ist ungültig. Bitte lade eine CSV- oder XML-Datei hoch.`,
                    );
                    this.uploader.queue[index] = null;
                }
            }

            // Remove all empty entries which were null'ed due to an invalid file extension.
            this.uploader.queue = this.uploader.queue.filter(Boolean);

            if (!this.uploader.queue.length) {
                return;
            }

            /**
             * Concat all SAXIF XML files. This improves performance and creates only one import history entry. If every file were uploaded
             * on its own, there would be one import history entry per file.
             */
            if (this.uploader.queue[0]._file.name.endsWith('.xml')) {
                const numberOfAudatexTasks: number = this.uploader.queue.length;

                const xmlFirstLine = '<?xml version="1.0" encoding="UTF-8"?>';
                const xmlFileContents: string[] = [];
                for (const fileItem of this.uploader.queue) {
                    const fileContent = await fileItem._file.text();
                    // Remove the first line of the XML file so all SAXIF Tasks may be added to one single file.
                    xmlFileContents.push(fileContent.substr(xmlFirstLine.length));
                }

                const xmlFileContent: string = `${xmlFirstLine}<TaskList>${xmlFileContents.join('')}</TaskList>`;

                this.uploader.queue = [
                    new FileItem(
                        this.uploader,
                        new File([xmlFileContent], `Audatex AudaPad3 Import - ${numberOfAudatexTasks} Vorgänge.xml`, {
                            type: 'text/xml',
                        }),
                        {
                            url: undefined,
                        },
                    ),
                ];
            }

            /**
             * Determine which export the user wants. We currently assume that the user always enters one file at a time. If that proves to be a wrong assumption,
             * please handle different file types, e.g. through an error toast that asks the user to upload one file at a time.
             */
            this.processFileName(this.uploader.queue[0]._file);
        };

        this.uploader.onSuccessItem = (item: FileItem, response: string, status: number) => {
            const parsedResponse: { message: string; numberOfImportedItems: number; success: boolean } =
                JSON.parse(response);
            this.numberOfImportedLegacyReports += parsedResponse.numberOfImportedItems;
        };

        this.uploader.onErrorItem = (item, response) => {
            const error = JSON.parse(response);
            switch (error.code) {
                case 'INVALID_QUOTES': {
                    // Display a maximum of 5 error messages so that the user is not overwhelmed if there are too many errors.
                    const csvParsingErrorMessages: string[] = error.data.csvParsingErrors
                        .map(
                            (csvParsingError) =>
                                `<strong>Zeile ${csvParsingError.row} oder benachbarte:</strong> ${
                                    csvParsingError.message
                                }${
                                    csvParsingError.rowContent ? `<br>Inhalt: ${csvParsingError.rowContent.trim()}` : ''
                                }`,
                        )
                        .slice(0, 5);
                    this.toastService.error(
                        'CSV-Trennzeichen ungültig',
                        `Oft hilft es, die CSV-Datei <strong>in Microsoft Excel zu öffnen und wieder abzuspeichern</strong>, weil Excel Fehlformate automatisch korrigieren kann.</b><br><br>${
                            csvParsingErrorMessages?.length ? `${csvParsingErrorMessages.join('<br><br>')}<br><br>` : ''
                        }Falls der Import immer noch nicht funktioniert, kontaktiere gerne die <a href="/Hilfe">Hotline</a>.`,
                    );
                    break;
                }
                case 'INVALID_CSV': {
                    // Display a maximum of 5 error messages so that the user is not overwhelmed if there are too many errors.
                    const csvParsingErrorMessages: string[] = error.data.csvParsingErrors
                        .map(
                            (csvParsingError) =>
                                `<strong>Zeile ${csvParsingError.row} oder benachbarte:</strong> ${
                                    csvParsingError.message
                                }${
                                    csvParsingError.rowContent ? `<br>Inhalt: ${csvParsingError.rowContent.trim()}` : ''
                                }`,
                        )
                        .slice(0, 5);
                    this.toastService.error(
                        'CSV-Trennzeichen ungültig',
                        `Oft hilft es, die CSV-Datei <strong>in Microsoft Excel zu öffnen und wieder abzuspeichern</strong>, weil Excel Fehlformate automatisch korrigieren kann.</b><br><br>${
                            csvParsingErrorMessages?.length ? `${csvParsingErrorMessages.join('<br><br>')}<br><br>` : ''
                        }Falls der Import immer noch nicht funktioniert, kontaktiere gerne die <a href="/Hilfe">Hotline</a>.`,
                    );
                    break;
                }
                default:
                    this.toastService.error(
                        'Upload gescheitert',
                        `Bitte kontaktiere die Hotline.\n\nAntwort des Servers:\n${error.code} ${error.message || ''}`,
                    );
            }

            // Remove the item from the upload queue so that the user may upload the next file.
            this.uploader.queue.splice(this.uploader.queue.indexOf(item), 1);
        };

        this.uploader.onCompleteAll = () => {
            if (this.numberOfImportedLegacyReports === 0) {
                this.toastService.info(
                    'Nichts importiert',
                    `Es wurden ${this.numberOfImportedLegacyReports} Gutachten importiert.\nDuplikate wurden übersprungen.`,
                );
            } else {
                this.toastService.success(
                    'Import erfolgreich',
                    `Es wurden ${this.numberOfImportedLegacyReports} Gutachten importiert.\nDuplikate wurden übersprungen.`,
                );

                this.emitUploadSuccessful();
                this.tutorialStateService.markTeamSetupStepComplete('contactsOrLegacyReportsImported');
                this.closeDialog();
            }

            this.fileUploadPending = false;
        };
    }

    /**
     * Re-initialize the uploader so that the API url contains the right source system.
     */
    public updateUploaderUrl(): void {
        this.uploader.setOptions({
            url: `api/v0/teams/${this.team._id}/imports/legacyReports?sourceSystem=${
                this.selectedSourceSystem === 'custom' ? this.customSourceSystemName : this.selectedSourceSystem
            }`,
        });
    }

    public isUploadAllowed(): boolean {
        // We only need an organization type for nextsoft.
        const isSourceSystemComplete: boolean =
            this.selectedSourceSystem === 'custom' ? !!this.customSourceSystemName : true;

        return !!(this.selectedSourceSystem && isSourceSystemComplete && this.uploader.queue.length);
    }

    public uploadImportFile(): void {
        if (!this.isUploadAllowed()) {
            if (!this.uploader.queue.length) {
                this.toastService.info('Bitte lade eine Datei hoch');
            } else if (!this.selectedSourceSystem) {
                this.toastService.info('Bitte wähle zuerst eine Datenquelle aus');
            } else if (this.selectedSourceSystem === 'custom' && !this.customSourceSystemName) {
                this.toastService.info('Bitte gib den Namen des Software-Systems ein, aus dem du importierst.');
            }
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Gutachten können importiert werden, sobald du wieder online bist.',
            );
            return;
        }

        this.numberOfImportedLegacyReports = 0;
        this.fileUploadPending = true;
        this.uploader.uploadAll();
    }

    // Event handler which listens to the mousein and mouseout event if the user drags a file
    public onFileOverDropZone(fileOver: boolean): void {
        // If the user's mouse and the dragged file left the dropzone, hide the dropzone.
        if (!fileOver) {
            this.fileOverDropzoneTimeoutCache = window.setTimeout(() => {
                this.fileIsOverDropZone = false;
            }, 500);
        } else {
            clearTimeout(this.fileOverDropzoneTimeoutCache);
            this.fileIsOverDropZone = true;
            this.onFileOverBody();
        }
    }

    public onFileDrop(): void {
        // Disable the drop zone as soon as content is dropped
        this.fileIsOverBody = false;
    }

    /**
     * Show drop zone
     */
    @HostListener('body:dragover', ['$event'])
    public onFileOverBody() {
        clearTimeout(this.fileOverBodyTimeoutCache);

        this.fileIsOverBody = true;

        this.fileOverBodyTimeoutCache = window.setTimeout(() => {
            this.fileIsOverBody = false;
        }, 500);
    }

    public processFileName(file: File): void {
        // Ignore capitalization
        const filename: string = file.name;

        // Recognize data source
        if (/PCG_Export_/i.test(filename)) {
            this.selectSourceSystem('custom');
            this.customSourceSystemName = 'nextSoft';
        } else if (/easyexpert/i.test(filename)) {
            this.selectSourceSystem('easyexpert');
        }
        // Files from AudaPad3 (not AudaFusion - AudaFusion exports CSV files) are exported in the SAXIF XML format.
        else if (/\.xml$/i.test(filename)) {
            this.selectSourceSystem('audatex');
        }
        // Audatex AudaFusion files don't have a default title, so no recognition is possible.
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END File Upload
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Sources
    //****************************************************************************/
    public selectSourceSystem(sourceSystem: LegacyReportImportSourceSystem): void {
        this.selectedSourceSystem = sourceSystem;

        this.updateUploaderUrl();
    }

    public filterSourceSystemAutocompleteOptions() {
        const searchTerm = (this.customSourceSystemName || '').toLowerCase();
        this.filteredSourceSystemAutocompleteOptions = [...this.sourceSystemAutocompleteOptions].filter((option) =>
            option.toLowerCase().includes(searchTerm),
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Sources
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Import Histories
    //****************************************************************************/
    public getImportHistories() {
        this.importHistoryService.findByImportType('legacyReports').subscribe({
            next: (importHistories) => {
                this.importHistories = importHistories;
            },
        });
    }

    public async rewindImport(importHistory: ImportHistory) {
        const { index } = removeFromArray(importHistory, this.importHistories);

        this.importHistoryIdBeingDeleted = importHistory._id;

        try {
            await this.importHistoryService.delete(importHistory._id, { waitForServer: true });
        } catch (error) {
            this.importHistoryIdBeingDeleted = null;

            // Add back record in UI
            this.importHistories.splice(index, 0, importHistory);

            // Display error
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Importierte Datensätze konnten nicht gelöscht werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
        this.toastService.success(
            'Importierte Altgutachten gelöscht',
            `Löschen abgeschlossen: Import vom ${DateTime.fromISO(importHistory.createdAt).toFormat('dd.MM.yyyy - HH:mm')} mit ${formatNumber(importHistory.numberOfObjects, 'de-de')} Datensätzen.`,
        );
        this.importHistoryIdBeingDeleted = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Import Histories
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog(): void {
        this.close.emit();
    }

    public emitUploadSuccessful(): void {
        this.uploadSuccessful.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleCtrlEnter(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.uploadImportFile();
                }
                break;
            case 'Escape':
                this.closeDialog();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

type LegacyReportImportSourceSystem = 'audatex' | 'easyexpert' | 'custom';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FileUploadModule } from 'ng2-file-upload';
import { IconPickerModule } from '@autoixpert/components/icon-picker/icon-picker.module';
import { WhiteButtonWithShadowModule } from '@autoixpert/components/white-button-with-shadow/white-button-with-shadow.module';
import { PasswordInputComponent } from '../shared/components/password-input/password-input.component';
import { ApiUserService } from '../shared/services/api-user.service';
import { AutoonlineCredentialsCheckService } from '../shared/services/autoonline/autoonline-credentials-check.service';
import { AxLicenseService } from '../shared/services/ax-license.service';
import { Crashback24Service } from '../shared/services/crashback24.service';
import { DatAuthenticationService } from '../shared/services/dat-authentication.service';
import { DocumentBuildingBlockService } from '../shared/services/document-building-block.service';
import { DocumentLayoutGroupService } from '../shared/services/document-layout-group.service';
import { DocumentOrderConfigService } from '../shared/services/document-order-config.service';
import { DocumentPartialService } from '../shared/services/document-partial.service';
import { DocumentTemplateService } from '../shared/services/document-template.service';
import { ExternalApiTeamCreditsService } from '../shared/services/external-api-team-credits.service';
import { HeaderAndFooterTemplateService } from '../shared/services/header-and-footer-template.service';
import { InvoiceHistoryService } from '../shared/services/invoice-history.service';
import { PersaldoService } from '../shared/services/persaldo.service';
import { SignablePdfTemplateConfigService } from '../shared/services/signable-pdf-template-config.service';
import { SignablePdfTemplateImageService } from '../shared/services/signable-pdf-template-image.service';
import { SignablePdfTemplateThumbnailService } from '../shared/services/signable-pdf-template-thumbnail.service';
import { SignablePdfTemplateService } from '../shared/services/signable-pdf-template.service';
import { TeamLogoService } from '../shared/services/team-logo.service';
import { TeamWebhookService } from '../shared/services/team-webhook.service';
import { UserCredentialsSynchronizationService } from '../shared/services/user-credentials-synchronization.service';
import { UserSealService } from '../shared/services/user-seal.service';
import { SharedModule } from '../shared/shared.module';
import { AccessRightsComponent } from './access-rights/access-rights.component';
import { AxPasswordDialogComponent } from './ax-password-dialog/ax-password-dialog.component';
import { ChangeUsernameDialogComponent } from './change-username-dialog/change-username-dialog.component';
import { DocumentBuildingBlockConditionPreviewComponent } from './document-building-block-condition-preview/document-building-block-condition-preview.component';
import { DocumentBuildingBlockConditionsPreviewComponent } from './document-building-block-conditions-preview/document-building-block-conditions-preview.component';
import { DocumentBuildingBlockConfigDialogComponent } from './document-building-block-config-dialog/document-building-block-config-dialog.component';
import { DocumentBuildingBlockEditorComponent } from './document-building-block-editor/document-building-block-editor.component';
import { DocumentBuildingBlockListComponent } from './document-building-block-list/document-building-block-list.component';
import { DocumentBuildingBlockVariantEditorComponent } from './document-building-block-variant-editor/document-building-block-variant-editor.component';
import { DocumentOrderConfigCreationDialogComponent } from './document-order-config-creation-dialog/document-order-config-creation-dialog';
import { ExternalApiUsageComponent } from './external-api/api-usage/external-api-usage.component';
import { ExternalApiUsersComponent } from './external-api/api-users/external-api-users.component';
import { ExternalApiPreferencesComponent } from './external-api/external-api-preferences.component';
import { TeamWebhooksComponent } from './external-api/team-webhooks/team-webhooks.component';
import { OfficeLocationEditDialogComponent } from './office-location-edit-dialog/office-location-edit-dialog.component';
import { CancelTeamAccountDialogComponent } from './preferences-overview/cancel-team-account-dialog/cancel-team-account-dialog.component';
import { ConfigureUserSealComponent } from './preferences-overview/configure-user-seal/configure-user-seal.component';
import { DocumentLayoutConfigComponent } from './preferences-overview/document-layout-group-preferences/document-layout-config/document-layout-config.component';
import { DocumentLayoutGroupPreferencesComponent } from './preferences-overview/document-layout-group-preferences/document-layout-group-preferences.component';
import { DocumentLayoutGroupComponent } from './preferences-overview/document-layout-group-preferences/document-layout-group/document-layout-group.component';
import { InvoiceNumberJournalComponent } from './preferences-overview/invoice-number-journal/invoice-number-journal.component';
import { InvoiceNumberPreferencesComponent } from './preferences-overview/invoice-token-preferences/invoice-number-preferences.component';
import { OrderAudatexAddonDialogComponent } from './preferences-overview/order-audatex-addon-dialog/order-audatex-addon-dialog.component';
import { PreferenceSectionDeactivatedComponent } from './preferences-overview/preference-section-deactivated/preference-section-deactivated.component';
import { PreferencesOverviewComponent } from './preferences-overview/preferences-overview.component';
import { ReportInvoiceNumberConfigDialogComponent } from './preferences-overview/report-invoice-number-config-dialog/report-invoice-number-config-dialog.component';
import { ReportTokenPreferencesComponent } from './preferences-overview/report-token-preferences/report-token-preferences.component';
import { SelectLeadingCounterDialogComponent } from './preferences-overview/select-leading-counter-dialog/select-leading-counter-dialog.component';
import { SynchronizeCredentialsComponent } from './preferences-overview/synchronize-credentials/synchronize-credentials.component';
import { CostCenterPopoverComponent } from './preferences-overview/team-members/cost-center-popover/cost-center-popover.component';
import { TeamMembersComponent } from './preferences-overview/team-members/team-members.component';
import { PreferencesComponent } from './preferences.component';
import { preferencesRouting } from './preferences.routing';
import { SignablePdfTemplatesListComponent } from './signable-pdf-templates/signable-pdf-templates-list/signable-pdf-templates-list.component';
import { TeamMemberInvitationDialog } from './team-member-invitation-dialog/team-member-invitation-dialog.component';
import { TokenPatternEditorComponent } from './token-pattern-editor/token-pattern-editor.component';
import { UserInitialsEditDialogComponent } from './user-initials-edit-dialog/user-initials-edit-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        preferencesRouting,
        FileUploadModule,
        FormsModule,
        DragDropModule,
        ScrollingModule,
        MatProgressSpinnerModule,
        IconPickerModule,
        WhiteButtonWithShadowModule,
        MatLegacyFormFieldModule,
        NgIf,
    ],
    declarations: [
        AxPasswordDialogComponent,
        ChangeUsernameDialogComponent,
        DocumentOrderConfigCreationDialogComponent,

        // Document building block
        DocumentBuildingBlockConfigDialogComponent,
        DocumentBuildingBlockVariantEditorComponent,
        DocumentBuildingBlockListComponent,
        DocumentBuildingBlockConditionsPreviewComponent,
        DocumentBuildingBlockConditionPreviewComponent,
        DocumentBuildingBlockEditorComponent,

        TeamMemberInvitationDialog,
        AccessRightsComponent,
        DocumentBuildingBlockConfigDialogComponent,
        OfficeLocationEditDialogComponent,
        PasswordInputComponent,
        PreferencesComponent,
        PreferencesOverviewComponent,
        SignablePdfTemplatesListComponent,
        TeamMemberInvitationDialog,
        TokenPatternEditorComponent,
        ReportTokenPreferencesComponent,
        InvoiceNumberPreferencesComponent,
        InvoiceNumberJournalComponent,
        DocumentLayoutGroupPreferencesComponent,
        DocumentLayoutGroupComponent,
        DocumentLayoutConfigComponent,
        TeamMembersComponent,
        OrderAudatexAddonDialogComponent,
        CancelTeamAccountDialogComponent,
        SynchronizeCredentialsComponent,
        PreferenceSectionDeactivatedComponent,
        UserInitialsEditDialogComponent,
        ExternalApiUsageComponent,
        ExternalApiPreferencesComponent,
        ExternalApiUsersComponent,
        TeamWebhooksComponent,
        SelectLeadingCounterDialogComponent,
        ReportInvoiceNumberConfigDialogComponent,
        CostCenterPopoverComponent,
        ConfigureUserSealComponent,
    ],
    providers: [
        AutoonlineCredentialsCheckService,
        AxLicenseService,
        Crashback24Service,
        DatAuthenticationService,
        DocumentBuildingBlockService,
        DocumentLayoutGroupService,
        DocumentOrderConfigService,
        DocumentPartialService,
        DocumentTemplateService,
        HeaderAndFooterTemplateService,
        InvoiceHistoryService,
        PersaldoService,
        SignablePdfTemplateConfigService,
        SignablePdfTemplateImageService,
        SignablePdfTemplateService,
        SignablePdfTemplateThumbnailService,
        TeamLogoService,
        UserCredentialsSynchronizationService,
        UserSealService,
        ApiUserService,
        TeamWebhookService,
        ExternalApiTeamCreditsService,
    ],
})
export class PreferencesModule {}

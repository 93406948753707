import { Injectable } from '@angular/core';
import { CounterPattern } from '@autoixpert/lib/counters/counter-pattern';
import { matchAssociatedOfficeLocation } from '@autoixpert/lib/counters/match-associated-office-location';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Report } from '@autoixpert/models/reports/report';
import { LeadingCounterType } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { FieldGroupConfigService } from './field-group-config.service';
import { InvoiceNumberOrReportTokenCounterService } from './invoice-number-or-report-token-counter.service';
import { LoggedInUserService } from './logged-in-user.service';
import { TemplatePlaceholderValuesService } from './template-placeholder-values.service';
import { TutorialStateService } from './tutorial-state.service';
import { UserService } from './user.service';

@Injectable()
export class ReportTokenService {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private counterService: InvoiceNumberOrReportTokenCounterService,
        private tutorialStateService: TutorialStateService,
        private invoiceNumberOrReportTokenCounterService: InvoiceNumberOrReportTokenCounterService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private fieldGroupConfigService: FieldGroupConfigService,
    ) {}

    //*****************************************************************************
    //  Get configs
    //****************************************************************************/
    public getReportTokenConfig(officeLocationId: OfficeLocation['_id']) {
        const team: Team = this.loggedInUserService.getTeam();

        const reportTokenConfig = matchAssociatedOfficeLocation(team.reportTokenConfigs, officeLocationId);
        if (!reportTokenConfig) {
            throw new AxError({
                code: 'NO_MATCHING_REPORT_TOKEN_CONFIG',
                message: `The report token config for the report and location could not be found. Check if a report token config exists for the office location.`,
            });
        }

        return reportTokenConfig;
    }

    public isInvoiceNumberSyncedAndLeading(report: Report): boolean {
        const user: User = this.loggedInUserService.getUser();
        if (!user) {
            return false;
        }

        const reportTokenConfig = this.getReportTokenConfig(report.officeLocationId);
        return reportTokenConfig.syncWithInvoiceCounter && reportTokenConfig.leadingCounter === 'invoiceNumber';
    }

    /**
     * Get the currently leading counter if report token and invoice number are synced for the given report. Returns
     * null, if they are not synced.
     */
    public getLeadingCounter(report: Report): LeadingCounterType | null {
        const user: User = this.loggedInUserService.getUser();
        if (!user) {
            return null;
        }

        const reportTokenConfig = this.getReportTokenConfig(report.officeLocationId);
        return reportTokenConfig.syncWithInvoiceCounter ? reportTokenConfig.leadingCounter : null;
    }

    public shouldTokenBeGeneratedOnCreate() {
        const team: Team = this.loggedInUserService.getTeam();
        if (!team) {
            return;
        }

        return team.preferences.generateReportTokenOnCreate;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get configs
    /////////////////////////////////////////////////////////////////////////////*/

    public async generateReportToken(report: Report): Promise<string> {
        const user: User = this.loggedInUserService.getUser();
        if (!user) {
            return;
        }

        const reportTokenConfig = this.getReportTokenConfig(report.officeLocationId);
        const reportTokenCounter = await this.counterService.getAndIncreaseCount(reportTokenConfig._id);

        const tokenPattern = new CounterPattern(reportTokenConfig.pattern);

        /**
         * Get responsible assessor in case the token contains initials.
         */
        let associatedUser: User;
        if (tokenPattern.includesUserInitials()) {
            associatedUser = this.userService.getTeamMemberFromCache(report.responsibleAssessor);
        }

        /**
         * Get the officeLocation in case the token contains office location initials.
         */
        let associatedOfficeLocation: OfficeLocation;
        if (tokenPattern.includesOfficeLocationInitials()) {
            const team = this.loggedInUserService.getTeam();
            associatedOfficeLocation = team.officeLocations.find(
                (officeLocation) => officeLocation._id === report.officeLocationId,
            );
        }

        this.tutorialStateService.markUserTutorialStepComplete('reportTokenRetrieved');
        const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
            reportId: report._id,
        });
        const fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        return tokenPattern.fill(reportTokenCounter, {
            reportType: report.type,
            associatedUser,
            associatedOfficeLocation,
            placeholderValues,
            reportId: report._id,
            fieldGroupConfigs,
        });
    }

    /**
     * Check if the report token of the given report is the latest created with the respective report token counter.
     */
    async isReportTokenLatest(report: Report): Promise<boolean> {
        const counterConfig = this.getReportTokenConfig(report.officeLocationId);
        const currentCounter = await this.invoiceNumberOrReportTokenCounterService.get(counterConfig._id);

        // Find the index of the counter within the report token by searching for the placeholder {###} (can contain one or more hash characters)
        // Only escaping the first curly bracelet with backslash is necessary
        const counterPlaceholderRegex = /\{#+}/;

        const counterInReportExecResult: RegExpExecArray = counterPlaceholderRegex.exec(counterConfig.pattern);

        // There may be no counter in the report token pattern (assessors increase their report tokens manually)
        const match = counterInReportExecResult?.[0];

        if (match) {
            // Count the number of curly bracelets in the pattern. We need them to find the index of
            // the counter in the actual report token (which does not include the curly bracelets anymore)
            const numberOfCurlyBraceletsBeforeCounter = (
                counterConfig.pattern.substring(0, counterInReportExecResult.index).match(/[{}]/g) || []
            ).length;

            // Find the position of the token counter within the report token
            const counterStartIndex = counterInReportExecResult.index - numberOfCurlyBraceletsBeforeCounter;
            const counterEndIndex = counterStartIndex + (match.length - 1); // -1 to exclude the closing curly bracelet

            // Get the counter
            const extractedCounter = report.token.substring(counterStartIndex, counterEndIndex);

            // And parse the report token number
            const parsedCounter = parseInt(extractedCounter, 10);

            // So we can finally determine, if it is the latest number generated
            return parsedCounter === currentCounter.count;
        }

        // If the report token pattern does not contain the counter placeholder -> we can't determine if it is the last number or not
        return false;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ManualCalculationItem } from '@autoixpert/models/reports/damage-calculation/manual-calculation';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { CustomAutocompleteEntriesService } from '../../../../../shared/services/custom-autocomplete-entries.service';
import { LoggedInUserService } from '../../../../../shared/services/logged-in-user.service';

export type TabDirection = 'next' | 'previous';

@Component({
    selector: 'manual-calculation-input-overlay',
    templateUrl: './manual-calculation-input-overlay.component.html',
    styleUrls: ['./manual-calculation-input-overlay.component.scss'],
    host: {
        '[class.transparent]': '!calculationItem',
    },
})
export class ManualCalculationInputOverlayComponent {
    @Input() itemDescriptionAutocompleteEntries: CustomAutocompleteEntry[] = [];
    protected filteredItemDescriptionAutocompleteEntries: CustomAutocompleteEntry[] = [];

    @Input() disabled: boolean = false;
    @Input() calculationItem: ManualCalculationItem;

    @Output() onChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() onTabPressed: EventEmitter<{ direction: TabDirection }> = new EventEmitter<{ direction: TabDirection }>();

    constructor(
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private loggedInUserService: LoggedInUserService,
    ) {}

    //*****************************************************************************
    //  Remember Item Description
    //****************************************************************************/
    public rememberItemDescriptionAutocompleteEntry(description: string): void {
        if (!description) return;

        // If the entry already exists, don't add a new one
        if (this.itemDescriptionAutocompleteEntryExists(description)) return;

        const newEntry: CustomAutocompleteEntry = new CustomAutocompleteEntry({
            type: 'manualCalculationItemDescription',
            value: description,
            createdBy: this.loggedInUserService.getUser()._id,
            teamId: this.loggedInUserService.getUser().teamId,
        });

        this.itemDescriptionAutocompleteEntries.push(newEntry);
        this.sortItemDescriptionAutocomplete();
        this.filterItemDescriptionAutocomplete(description);

        this.customAutocompleteEntriesService.create(newEntry);
    }

    public itemDescriptionAutocompleteEntryExists(description: string): boolean {
        return !!this.itemDescriptionAutocompleteEntries.find((entry) => entry.value === description);
    }

    public deleteItemDescriptionAutocompleteEntry(entry: CustomAutocompleteEntry): void {
        // Remove locally
        const entryIndex: number = this.itemDescriptionAutocompleteEntries.indexOf(entry);
        if (entryIndex > -1) {
            this.itemDescriptionAutocompleteEntries.splice(entryIndex, 1);
        }
        // Update view
        this.filterItemDescriptionAutocomplete(entry.value);

        this.customAutocompleteEntriesService.delete(entry._id);
    }

    public filterItemDescriptionAutocomplete(searchTerm: string = ''): void {
        this.filteredItemDescriptionAutocompleteEntries = [...this.itemDescriptionAutocompleteEntries];

        if (!searchTerm) return;

        const searchTerms: string[] = searchTerm.toLowerCase().split(' ');

        this.filteredItemDescriptionAutocompleteEntries = this.filteredItemDescriptionAutocompleteEntries.filter(
            (entry) => {
                const entryLabelLowerCase: string = entry.value.toLowerCase();
                return searchTerms.every((searchTerm) => entryLabelLowerCase.includes(searchTerm));
            },
        );
    }

    /**
     * Sort the original description array.
     *
     * This way, the filtered values will be automatically sorted too. Sorting on every filter cycle
     * would put unnecessary load on the client.
     */
    public sortItemDescriptionAutocomplete(): void {
        // Sort
        this.itemDescriptionAutocompleteEntries.sort((entryA, entryB) => entryA.value.localeCompare(entryB.value));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remember Item Description
    /////////////////////////////////////////////////////////////////////////////*/

    protected onKeyDown(event: KeyboardEvent): void {
        if (event.code === 'Tab') {
            // Trigger onChange, because blur event won't be fired anymore after the
            // overlay gets closed in event handler of onTabPressed (parent component)
            this.onChange.emit();
            this.onTabPressed.emit({ direction: event.shiftKey ? 'previous' : 'next' });
            event.stopImmediatePropagation();
            event.preventDefault();
        }
    }
}

import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';

export enum TeamWebhookEventType {
    REPORT_CREATED = 'report.created',
    REPORT_LOCKED = 'report.locked',
    REPORT_UNLOCKED = 'report.unlocked',
    REPORT_MOVED_TO_TRASH = 'report.moved_to_trash',
    REPORT_RESTORED = 'report.restored',
    REPORT_DELETED = 'report.deleted',

    CONTACT_CREATED = 'contact.created',
    CONTACT_UPDATED = 'contact.updated',
    CONTACT_DELETED = 'contact.deleted',

    INVOICE_CREATED = 'invoice.created',
    INVOICE_LOCKED = 'invoice.locked',
    INVOICE_PAID = 'invoice.paid',
    INVOICE_DELETED = 'invoice.deleted',
}

export type ContactsWebhookType = 'contact.created' | 'contact.updated' | 'contact.deleted';
export type InvoicesWebhookType = 'invoice.created' | 'invoice.locked' | 'invoice.paid' | 'invoice.deleted';
export type ReportsWebhookType =
    | 'report.created'
    | 'report.locked'
    | 'report.unlocked'
    | 'report.moved_to_trash'
    | 'report.restored'
    | 'report.deleted';

export class TeamWebhook implements DataTypeBase {
    constructor(options?: RequireSome<TeamWebhook, 'eventType'>) {
        Object.assign(this, options);
    }

    _id: string = generateId();

    url: string;
    eventType: TeamWebhookEventType;
    active: boolean = null;
    description: string = null;

    teamId: string = null;
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

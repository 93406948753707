import moment, { Moment } from 'moment';

export function getVatRate(date?: string): 0.19 | 0.16 {
    // Earliest and latest date for 16% VAT rate
    const earliestDate: Moment = moment('2020-07-01').startOf('day');
    const latestDate: Moment = moment('2020-12-31').endOf('day');

    const dateOfSupply: Moment = moment(date || undefined);
    if (dateOfSupply.isAfter(earliestDate) && dateOfSupply.isBefore(latestDate)) {
        return 0.16;
    } else {
        return 0.19;
    }
}

export class InvoiceAudit {
    constructor(templ: Partial<InvoiceAudit> = {}) {
        if (templ) {
            Object.assign(this, templ);
        }
    }
    // Comparison between report and third-party invoice (usually invoice written by the garage).
    wages: InvoiceAuditComparisonRow = new InvoiceAuditComparisonRow('wages');
    spareParts: InvoiceAuditComparisonRow = new InvoiceAuditComparisonRow('spareParts');
    paint: InvoiceAuditComparisonRow = new InvoiceAuditComparisonRow('paint');
    auxiliaryCosts: InvoiceAuditComparisonRow = new InvoiceAuditComparisonRow('auxiliaryCosts');
    otherCosts: InvoiceAuditComparisonRow = new InvoiceAuditComparisonRow('otherCosts');
    columnTotals: InvoiceAuditComparisonRow = new InvoiceAuditComparisonRow();
    // Correction Items
    correctionItems: CorrectionItem[] = [];
    // Corrected overall costs
    correctedRepairCostsNet: number; // Foreign invoice subtracted by the sum of all correction items.
    correctedRepairCostsGross: number;
    // Header Data of Repair Invoice
    repairInvoice: { date: string; number: string } = {
        date: null,
        number: null,
    };
    // Connected Report
    reportId: string;
}

export class InvoiceAuditComparisonRow {
    constructor(category?: InvoiceAuditCategory) {
        this.category = category;
    }

    category: InvoiceAuditCategory;
    projectedRepairCosts: number;
    actualRepairCosts: number;
    difference: number;
    correctedAmount: number;
}

export class CorrectionItem {
    title: string;
    category: InvoiceAuditCategory;
    comment: string;
    correctionAmount: number;
}

export type InvoiceAuditCategory = 'wages' | 'spareParts' | 'paint' | 'auxiliaryCosts' | 'otherCosts';

import { Routes } from '@angular/router';
import { AccessRights } from '@autoixpert/models/user/user';
import { AccessRightGuard } from '../../shared/services/access-right-guard.service';
import { AccidentDataComponent } from './accident-data/accident-data.component';
import { CarConditionComponent } from './car-condition/car-condition.component';
import { CarDataComponent } from './car-data/car-data.component';
import { DamageCalculationComponent } from './damage-calculation/damage-calculation.component';
import { ExpertStatementComponent } from './expert-statement/expert-statement.component';
import { FeesComponent } from './fees/fees.component';
import { InvoiceAuditComponent } from './invoice-audit/invoice-audit.component';
import { LeaseReturnComponent } from './lease-return/lease-return.component';
import { OldtimerValuationLargeConditionComponent } from './oldtimer-valuation-large-condition/oldtimer-valuation-large-condition.component';
import { OldtimerValuationComponent } from './oldtimer-valuation/oldtimer-valuation.component';
import { PhotosComponent } from './photos/photos.component';
import { PrintAndTransmissionComponent } from './print-and-transmission/print-and-transmission.component';
import { RepairConfirmationComponent } from './repair-confirmation/repair-confirmation.component';
import { ReportDetailsGuard } from './report-details-guard.service';
import { ValuationComponent } from './valuation/valuation.component';

export const reportDetailsRoutes: Routes = [
    {
        path: '',
        redirectTo: 'Beteiligte',
        pathMatch: 'full',
    },
    {
        path: 'Beteiligte',
        component: AccidentDataComponent,
    },
    {
        path: 'Fahrzeug',
        component: CarDataComponent,
    },
    {
        path: 'Fahrzeugzustand',
        component: CarConditionComponent,
    },
    {
        path: 'Wertgutachten-gross-Zustand',
        component: OldtimerValuationLargeConditionComponent,
    },
    {
        path: 'Wertgutachten-Zustand',
        component: CarConditionComponent,
    },
    {
        path: 'Fotos',
        component: PhotosComponent,
        canDeactivate: [ReportDetailsGuard],
    },
    {
        path: 'Bewertung',
        component: ValuationComponent,
    },
    {
        path: 'Schadenskalkulation',
        component: DamageCalculationComponent,
    },
    {
        path: 'Wertgutachten-Bewertung',
        component: OldtimerValuationComponent,
    },
    {
        path: 'Prüfung',
        component: LeaseReturnComponent,
    },
    {
        path: 'Rechnungsprüfung',
        component: InvoiceAuditComponent,
    },
    {
        path: 'Rechnung',
        component: FeesComponent,
        data: {
            requiredAccessRight: 'seeReportFees',
        } as { requiredAccessRight: keyof AccessRights },
        canActivate: [AccessRightGuard],
    },
    {
        path: 'Druck-und-Versand',
        component: PrintAndTransmissionComponent,
    },
    {
        path: 'Reparaturbestätigung',
        component: RepairConfirmationComponent,
    },
    {
        path: 'Stellungnahme',
        component: ExpertStatementComponent,
    },
];

import { formatNumberToGermanLocale } from './format-number-to-german-locale';

/**
 * Converts a number such as 1234567.24 to a currency string such as "1.234.567,24 €"
 */
export function convertToEuro(
    number: number,
    {
        decimalPlaces = 2,
        omitDecimalsIfZero = false,
    }: { decimalPlaces?: number; omitDecimalsIfZero?: boolean; minDecimalPlaces?: number } = {},
): string {
    if (number === null || number === undefined) {
        return '- €';
    }
    if (typeof number !== 'number') {
        //axLogger.debug(`Tried to convert a non-number to a currency string. However, we expect a number as input. Input was "${util.inspect(number)}".`);
        // Useful for debugging. Shows the backtrace.
        // throw new Error('Debugging: Invalid input.');
        return `${number}`;
    }

    return `${formatNumberToGermanLocale(number, { decimalPlaces, omitDecimalsIfZero })} €`;
}

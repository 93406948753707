import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { InvoiceAnalytics } from '@autoixpert/models/analytics/invoice-analytics';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';

@Injectable()
export class InvoiceAnalyticsService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0/analytics/invoices';

    public find(params?: {
        from: string;
        to: string;
        dateFilterType: 'invoiceDate' | 'paymentDate';
        officeLocationIds?: OfficeLocation['_id'][];
    }): Observable<InvoiceAnalytics> {
        return this.server.find(params);
    }

    private server = {
        find: (params?: {
            from: string;
            to: string;
            dateFilterType: 'invoiceDate' | 'paymentDate';
            officeLocationIds?: OfficeLocation['_id'][];
        }): Observable<InvoiceAnalytics> => {
            let queryParams = new HttpParams().set('dateFilterType', params.dateFilterType);

            if (params.from) {
                // drop the time; the timezone contains a '+', leading to wrong interpretations in the backend
                queryParams = queryParams.set('date[$gte]', moment(params.from).format('YYYY-MM-DD'));
            }

            if (params.to) {
                // drop the time; the timezone contains a '+', leading to wrong interpretations in the backend
                queryParams = queryParams.set('date[$lte]', moment(params.to).format('YYYY-MM-DD'));
            }

            // Only add office locations filter if at least one office location is selected (no results otherwise)
            if (params.officeLocationIds && params.officeLocationIds.length > 0) {
                params.officeLocationIds.forEach((officeLocationId) => {
                    queryParams = queryParams.append('officeLocationIds[]', officeLocationId);
                });
            }

            return this.httpClient.get<InvoiceAnalytics>(`${this.apiPath}`, {
                params: queryParams,
            });
        },
    };
}

import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import 'moment-duration-format';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { residualValueBidSortFunction } from '@autoixpert/lib/residual-value-bid-sort-function';
import { isAutoonlineUserComplete } from '@autoixpert/lib/users/is-autoonline-user-complete';
import { isCarcasionUserComplete } from '@autoixpert/lib/users/is-carcasion-user-complete';
import { isCarTvUserComplete } from '@autoixpert/lib/users/is-cartv-user-complete';
import { areWinvalueResidualValueCredentialsComplete } from '@autoixpert/lib/users/is-winvalue-user-complete';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { CarShape } from '@autoixpert/models/reports/car-identification/car';
import { Valuation } from '@autoixpert/models/reports/market-value/valuation';
import { Report } from '@autoixpert/models/reports/report';
import { AutoonlineResidualValueOffer } from '@autoixpert/models/reports/residual-value/autoonline-residual-value-offer';
import {
    ResidualValueBid,
    ResidualValueExchangeName,
} from '@autoixpert/models/reports/residual-value/residual-value-bid';
import {
    ResidualValueExchangeId,
    ResidualValueOffer,
    supportedResidualValueExchanges,
} from '@autoixpert/models/reports/residual-value/residual-value-offer';
import { ResidualValueOfferResponse } from '@autoixpert/models/reports/residual-value/residual-value-offer-response';
import {
    ResidualValueInvitation,
    ResidualValueRequest,
} from '@autoixpert/models/reports/residual-value/residual-value-request';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';
import { slideInAndOutVertically } from '../../../../shared/animations/slide-in-and-out-vertical.animation';
import { slideOutSide } from '../../../../shared/animations/slide-out-side.animation';
import { MatQuillComponent } from '../../../../shared/components/mat-quill/mat-quill.component';
import { addCustomResidualValueBidList } from '../../../../shared/libraries/custom-residual-value-bid-list';
import { getResidualValueAndMarketAnalysisErrorHandlers } from '../../../../shared/libraries/error-handlers/get-residual-value-and-market-analysis-error-handlers';
import { getRelativeDate } from '../../../../shared/libraries/get-relative-date';
import { stripHtml } from '../../../../shared/libraries/strip-html';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { DownloadService } from '../../../../shared/services/download.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../../shared/services/network-status.service';
import { NewWindowService } from '../../../../shared/services/new-window.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { ReportService } from '../../../../shared/services/report.service';
import { ResidualValueRequestService } from '../../../../shared/services/residual-value-request.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { TutorialStateService } from '../../../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';

@Component({
    selector: 'residual-value-overview',
    templateUrl: 'residual-value-overview.component.html',
    styleUrls: ['residual-value-overview.component.scss'],
    animations: [
        slideOutSide(),
        // Slide animation for the label "Reparaturkosten kalkulieren mit"
        slideInAndOutVertically(),
        fadeInAndOutAnimation(),
        runChildAnimations(),
    ],
})
export class ResidualValueOverviewComponent implements OnInit {
    @ViewChild('residualValueRequestComment') residualValueRequestComment: MatQuillComponent;

    constructor(
        private reportService: ReportService,
        private router: Router,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private newWindowService: NewWindowService,
        private httpClient: HttpClient,
        private reportDetailsService: ReportDetailsService,
        private downloadService: DownloadService,
        public userPreferences: UserPreferencesService,
        private apiErrorService: ApiErrorService,
        private residualValueRequestService: ResidualValueRequestService,
        private dialog: MatDialog,
        private tutorialStateService: TutorialStateService,
        private networkStatusService: NetworkStatusService,
    ) {}

    @Input() report: Report;
    @Input() user: User;
    @Input() team: Team;
    @Input() showValuationResult = false;
    @Output() residualValueChange = new EventEmitter();
    @Output() setVehicleValue = new EventEmitter<{
        valueType: Valuation['vehicleValueType'];
        taxationType: Valuation['taxationType'];
        valueNet: number;
        valueGross: number;
        exchangeName: ResidualValueBid['origin'];
    }>();

    // Residual Value Offer Connection Dialogs
    public autoonlineOfferConnectionDialogShown = false;
    public winvalueOfferConnectionDialogShown = false;
    public carcasionOfferConnectionDialogShown = false;
    public cartvOfferConnectionDialogShown = false;

    public bidSelectorShown = false;
    public bidSelectorStartInCreationMode = false;
    public bidSelectorFilter: ResidualValueExchangeName | 'none' = null;
    public residualValueRequestExportPending = false;
    public residualValueRequestImportPending = false;

    public residualValueRequestCommentShown: boolean = false;
    public residualValueRequestCommentTextTemplateSelectorShown = false;
    public residualValueTargetDate: string = null;
    public residualValueDataInEditMode = false;
    public residualValueRequestDialogShown = false;

    ngOnInit(): void {
        this.filterResidualValueExchanges();

        // TODO: on Report Change
        this.prepareResidualValueExchangesForUi();
        this.showResidualValueRequestComment();
        this.calculateResidualValueTargetDate();
    }

    get residualValueExchangesImportPending(): boolean {
        return this.residualValueExchangesForUi.some((exchange) => exchange.importPending);
    }

    public get allBids(): ResidualValueBid[] {
        return [
            ...(this.report.valuation.autoonlineResidualValueOffer?.bids || []),
            ...(this.report.valuation.winvalueResidualValueOffer?.bids || []),
            ...(this.report.valuation.cartvResidualValueOffer?.bids || []),
            ...(this.report.valuation.carcasionResidualValueOffer?.bids || []),
            ...(this.report.valuation.customResidualValueBids || []),
        ];
    }

    public get selectedBids(): ResidualValueBid[] {
        const bids = this.allBids;
        return bids.filter((bid) => bid.selected);
    }

    public residualValueExchangesForUi: ResidualValueExchangeForUi[] = [
        new ResidualValueExchangeForUi({
            component: this,
            name: 'autoonline',
            logoFileName: 'autoonline-logo.svg',
            residualValueOffer: null,
            exportPending: false,
            importPending: false,
            areCredentialsComplete: this.areAutoonlineCredentialsComplete.bind(this),
            isResidualValueOfferAllowed: this.isAutoonlineResidualValueOfferAllowed.bind(this),
            createResidualValueOffer: this.createAutoonlineOffer.bind(this),
            connectResidualValueOffer: this.connectAutoonlineOffer.bind(this),
            openResidualValueOffer: this.openAutoonlineResidualValueExchange.bind(this),
            importBids: this.importAutoonlineResidualValueBids.bind(this),
            deleteResidualValueOffer: this.deleteAutoonlineResidualValueOffer.bind(this),
            resetResidualValueOffer: this.resetAutoonlineResidualValueOffer.bind(this),
            downloadBidList: ({ regional }: { regional?: boolean }) =>
                this.downloadResidualValueBidList({ regional, exchangeName: 'autoonline' }),
            getTooltipForExportIcon: () => stripHtml(this.getAutoonlineTooltipForExportIcon()),
            openConnectResidualValueOfferDialog: this.openAutoonlineOfferConnectionDialog.bind(this),
        }),
        new ResidualValueExchangeForUi({
            component: this,
            name: 'cartv',
            logoFileName: 'cartv-logo.svg',
            residualValueOffer: null,
            exportPending: false,
            importPending: false,
            areCredentialsComplete: this.areCartvCredentialsComplete.bind(this),
            isResidualValueOfferAllowed: this.isResidualValueOfferAllowed.bind(this),
            createResidualValueOffer: this.createCartvOffer.bind(this),
            connectResidualValueOffer: this.connectCartvOffer.bind(this),
            openResidualValueOffer: this.openCartvResidualValueExchange.bind(this),
            importBids: this.importCartvResidualValueBids.bind(this),
            deleteResidualValueOffer: this.deleteCartvResidualValueOffer.bind(this),
            resetResidualValueOffer: this.resetCartvResidualValueOffer.bind(this),
            downloadBidList: ({ regional }: { regional?: boolean }) =>
                this.downloadResidualValueBidList({ regional, exchangeName: 'cartv' }),
            getTooltipForExportIcon: () => stripHtml(this.getCartvTooltipForExportIcon()),
            openConnectResidualValueOfferDialog: this.openCartvOfferConnectionDialog.bind(this),
        }),
        new ResidualValueExchangeForUi({
            component: this,
            name: 'carcasion',
            logoFileName: 'car-casion.png',
            residualValueOffer: null,
            exportPending: false,
            importPending: false,
            areCredentialsComplete: this.areCarcasionCredentialsComplete.bind(this),
            isResidualValueOfferAllowed: this.isResidualValueOfferAllowed.bind(this),
            createResidualValueOffer: this.createCarcasionOffer.bind(this),
            connectResidualValueOffer: this.connectCarcasionOffer.bind(this),
            openResidualValueOffer: this.openCarcasionResidualValueExchange.bind(this),
            importBids: this.importCarcasionResidualValueBids.bind(this),
            deleteResidualValueOffer: this.deleteCarcasionResidualValueOffer.bind(this),
            resetResidualValueOffer: this.resetCarcasionResidualValueOffer.bind(this),
            downloadBidList: ({ regional }: { regional?: boolean }) =>
                this.downloadResidualValueBidList({ regional, exchangeName: 'carcasion' }),
            getTooltipForExportIcon: () => stripHtml(this.getCarcasionTooltipForExportIcon()),
            openConnectResidualValueOfferDialog: this.openCarcasionOfferConnectionDialog.bind(this),
        }),
        new ResidualValueExchangeForUi({
            component: this,
            name: 'winvalue',
            logoFileName: 'winvalue.png',
            residualValueOffer: null,
            exportPending: false,
            importPending: false,
            areCredentialsComplete: this.areWinvalueResidualValueCredentialsComplete.bind(this),
            isResidualValueOfferAllowed: this.isResidualValueOfferAllowed.bind(this),
            createResidualValueOffer: this.createWinvalueOffer.bind(this),
            connectResidualValueOffer: this.connectWinvalueOffer.bind(this),
            openResidualValueOffer: this.openWinvalueResidualValueExchange.bind(this),
            importBids: this.importWinvalueResidualValueBids.bind(this),
            deleteResidualValueOffer: this.deleteWinvalueResidualValueOffer.bind(this),
            resetResidualValueOffer: this.resetWinvalueResidualValueOffer.bind(this),
            downloadBidList: ({ regional }: { regional?: boolean }) =>
                this.downloadResidualValueBidList({ regional, exchangeName: 'winvalue' }),
            getTooltipForExportIcon: () => stripHtml(this.getWinvalueTooltipForResidualValueExportIcon()),
            openConnectResidualValueOfferDialog: this.openWinvalueOfferConnectionDialog.bind(this),
        }),
    ];
    public filteredResidualValueExchangesForUi: ResidualValueExchangeForUi[] = [];
    /**
     * An interval (technically multiple timeouts) is started when AUTOonline takes longer than usual to accept the export from autoiXpert. To prevent
     * the autoiXpert nginx server from sending a generic timeout, the autoiXpert backend returns an error before the
     * nginx timeout kicks in. The frontend client then updates the report object (increases a simple counter) until
     * the AUTOonline offer ID is part of it.
     */
    private autoonlineExportResultCheckTimeoutId: number = null;
    /**
     * Only show the residual value exchanges for which the user has credentials. That way, the interface stays clean.
     */

    private filterResidualValueExchanges() {
        // If no residual value exchange has complete credentials, show all of them. That way the user can see what's possible in autoiXpert.
        if (
            !this.residualValueExchangesForUi.some((residualValueExchangeForUi) =>
                residualValueExchangeForUi.areCredentialsComplete(),
            )
        ) {
            this.filteredResidualValueExchangesForUi = [...this.residualValueExchangesForUi];
        } else {
            this.filteredResidualValueExchangesForUi = this.residualValueExchangesForUi.filter(
                (residualValueExchangeForUi) => residualValueExchangeForUi.areCredentialsComplete(),
            );
        }
    }

    /**
     * Returns a list with the formatted Names of all residual value exchanges that are available on the report.
     */
    get residualValueExchangesWithInsuranceDiscountNames(): string[] {
        return (
            this.report.insurance?.contactPerson.availableQuotaOnResidualValueExchanges?.map(
                (exchangeId: ResidualValueExchangeId) => supportedResidualValueExchanges[exchangeId].name,
            ) ?? []
        );
    }

    public deleteResidualValueOfferWithOptionalConfirmDialog(provider: ResidualValueExchangeForUi['name']) {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }
        if (provider === 'autoonline') {
            this.dialog
                .open(ConfirmDialogComponent, {
                    data: {
                        heading: 'AUTOonline-Inserat löschen',
                        content:
                            'Möglicherweise stellt dir AUTOonline trotz Löschung Kosten für das Inserat in Rechnung. Für Details kontaktiere bitte AUTOonline direkt.',
                        confirmLabel: 'Trotzdem löschen',
                        cancelLabel: 'Inserat behalten',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .subscribe((response) => {
                    if (response) {
                        this.deleteAutoonlineResidualValueOffer();
                    }
                });
        } else if (provider === 'winvalue') {
            this.dialog
                .open(ConfirmDialogComponent, {
                    data: {
                        heading: 'WinValue-Inserat löschen',
                        content:
                            'Möglicherweise stellt dir WinValue trotz Löschung Kosten für das Inserat in Rechnung. Für Details kontaktiere bitte WinValue direkt.',
                        confirmLabel: 'Trotzdem löschen',
                        cancelLabel: 'Inserat behalten',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .subscribe((response) => {
                    if (response) {
                        this.deleteWinvalueResidualValueOffer();
                    }
                });
        } else if (provider === 'cartv') {
            this.deleteCartvResidualValueOffer();
        } else if (provider === 'carcasion') {
            this.deleteCarcasionResidualValueOffer();
        }
    }

    public toggleResidualValueRequestComment() {
        this.residualValueRequestCommentShown = !this.residualValueRequestCommentShown;
        if (this.residualValueRequestCommentShown) {
            this.focusResidualValueRequestComment();
        }
    }

    public importAllBids(): void {
        this.importAutoonlineResidualValueBids();
        this.importWinvalueResidualValueBids();
        this.importCartvResidualValueBids();
        this.importCarcasionResidualValueBids();
        this.importResidualValueRequestBids();
    }

    public isResidualValueOfferAllowed(): boolean {
        if (this.isReportLocked()) {
            return false;
        }

        // Don't allow export to the past.
        if (this.isResidualValueTargetDateInThePast()) {
            return false;
        }

        if (this.areTooManyPhotosSelectedForResidualValueExchange()) {
            return false;
        }

        const missingRequirements = this.getMissingDetailsForResidualValueOffers();
        return missingRequirements.length === 0;
    }

    private areTooManyPhotosSelectedForResidualValueExchange(): boolean {
        const PHOTO_LIMIT = 50 as const;

        return this.report.photos.filter((photo) => photo.versions.residualValueExchange.included).length > PHOTO_LIMIT;
    }

    public isResidualValueTargetDateInThePast(): boolean {
        return moment(this.residualValueTargetDate).isBefore();
    }

    private isFuelTypeSet(): boolean {
        return (
            this.report.car.runsOnDiesel ||
            this.report.car.runsOnElectricity ||
            this.report.car.runsOnGasoline ||
            this.report.car.runsOnLPG ||
            this.report.car.runsOnNaturalGasoline ||
            this.report.car.runsOnBiodiesel ||
            this.report.car.runsOnHydrogen ||
            !!this.report.car.runsOnSomethingElse
        );
    }

    /**
     * Attach the report's ResidualValueOffer objects to the residualValueExchangesForUi array's objects
     */
    private prepareResidualValueExchangesForUi(): void {
        const autoonlineResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'autoonline',
        );
        const winvalueResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'winvalue',
        );
        const cartvResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'cartv',
        );
        const carcasionResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'carcasion',
        );

        autoonlineResidualValueExchangeForUi.residualValueOffer = this.report.valuation.autoonlineResidualValueOffer;
        winvalueResidualValueExchangeForUi.residualValueOffer = this.report.valuation.winvalueResidualValueOffer;
        cartvResidualValueExchangeForUi.residualValueOffer = this.report.valuation.cartvResidualValueOffer;
        carcasionResidualValueExchangeForUi.residualValueOffer = this.report.valuation.carcasionResidualValueOffer;
    }

    /**
     * Only CARTV implements a search radius 'national' or 'international'
     * therefore display these settings only if credentials for CARTV are complete
     */
    public hasCartvAsResidualValueExchange() {
        return this.residualValueExchangesForUi.some(
            (exchange) => exchange.name === 'cartv' && exchange.areCredentialsComplete(),
        );
    }

    public handleResidualValueRegionalRadiusChange(event: MatSelectChange) {
        /**
         * If the user limits the scope of the residual value exchanges, he most certainly wants only those bids to be printed, too.
         */
        this.report.valuation.useRegionalBidsOnly = !!event.value;

        this.saveReport();
    }

    public translateResidualValueProvider(provider: ResidualValueExchangeForUi['name']) {
        switch (provider) {
            case 'winvalue':
                return 'WinValue';
            case 'carcasion':
                return 'car.casion';
            case 'cartv':
                return 'CarTV';
            case 'autoonline':
                return 'AUTOonline';
            case 'own':
                return 'Eigene';
        }
    }

    public getResidualValueRadiusLabel() {
        switch (this.report.valuation.residualValueRegionalRadius) {
            case null:
            case undefined:
                return;
            case 'international':
                return this.report.valuation.residualValueRegionalRadius;
            case 'national':
                return 'bundesweit';
            default:
                return this.report.valuation.residualValueRegionalRadius + ' km';
        }
    }

    public getResidualValueRadiusTooltip() {
        const radius = this.report.valuation.residualValueRegionalRadius;
        switch (radius) {
            case null:
            case undefined:
                return '';
            case 'international':
                return `Restwerthändler im In- & Ausland können auf dieses Fahrzeug bieten.`;
            case 'national':
                return `Restwerthändler aus Deutschland können auf dieses Fahrzeug bieten.`;
            default:
                return `Restwertgebote innerhalb von ${radius} km um das Fahrzeug werden von den Börsen als regional markiert.`;
        }
    }

    public isResidualValueRequestOpen(residualValueExchange: ResidualValueExchangeForUi): boolean {
        return (
            residualValueExchange.residualValueOffer?.readyAt &&
            !this.liesInThePast(residualValueExchange.residualValueOffer?.readyAt)
        );
    }

    public getResidualValueExchangeTooltip(residualValueExchange: ResidualValueExchangeForUi): string {
        let tooltip: string;

        if (this.isResidualValueRequestOpen(residualValueExchange)) {
            tooltip = `Gebotsfrist endet am ${moment(residualValueExchange.residualValueOffer?.readyAt).format('DD.MM.YYYY - HH:mm')} Uhr.`;
        } else {
            tooltip = `Gebotsfrist am ${moment(residualValueExchange.residualValueOffer?.readyAt).format(
                'DD.MM.YYYY - HH:mm',
            )} Uhr abgelaufen`;
        }

        if (residualValueExchange.name === 'cartv') {
            tooltip += `\n\nCARTV-Nr.: ${residualValueExchange.residualValueOffer.offerId}`;
        }

        return tooltip;
    }

    /**
     * This function downloads the residual value bid list for the selected residual value exchange.
     */
    private downloadResidualValueBidList({
        exchangeName,
        regional = false,
    }: {
        exchangeName: ResidualValueExchangeForUi['name'];
        regional?: boolean;
    }): void {
        const queryParams: any = {};

        let serviceName;
        switch (exchangeName) {
            case 'autoonline':
                serviceName = 'autoonlineResidualValueBidList';
                break;
            case 'winvalue':
                serviceName = 'winvalueResidualValueBidList';
                break;
            case 'cartv':
                serviceName = 'cartvResidualValueBidList';
                break;
            case 'carcasion':
                serviceName = 'carcasionResidualValueBidList';
                break;
        }

        if (regional) {
            queryParams.regional = true;
        }

        this.httpClient
            .get(`/api/v0/reports/${this.report._id}/documents/${serviceName}`, {
                responseType: 'blob',
                observe: 'response',
                params: queryParams,
            })
            .subscribe({
                next: (response) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            // Show a more specific error message if the bid list was not found.
                            BID_LIST_NOT_FOUND: {
                                title: 'Gebotsblatt nicht gefunden',
                                body: 'Frage die Gebote ab, um das Gebotsblatt zu generieren. Sollte das nicht helfen, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                                stopReasonChain: true, // It's not important for the user to know the file does not exist on S3.
                            },
                        },
                        defaultHandler: {
                            title: 'Fehler beim Download',
                            body: 'Das Gebotsblatt konnte nicht heruntergeladen werden.',
                        },
                    });
                },
            });
    }

    //*****************************************************************************
    //  AUTOonline
    //****************************************************************************/
    public async createAutoonlineOffer() {
        if (!this.areAutoonlineCredentialsComplete()) {
            this.toastService.error(
                'Zugangsdaten fehlen',
                this.getAutoonlineTooltipForExportIcon() +
                    '<br><br>Noch keinen Account? <a href="https://www.autoonline.de/restwertboerse/restweertboerse-einsteller" target="_blank" rel="noopener">Registrieren</a>',
            );
            return;
        }
        if (!this.isAutoonlineResidualValueOfferAllowed() || this.isReportLocked()) {
            this.toastService.info(this.getAutoonlineTooltipForExportIcon());
            return;
        }

        // Since a residual value offer is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Restwertbörse von AUTOonline ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        const autoonlineResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'autoonline',
        );

        // Don't allow creating duplicate offers, e.g. if the user double-clicked.
        if (autoonlineResidualValueExchangeForUi.exportPending) return;

        autoonlineResidualValueExchangeForUi.exportPending = true;

        // TODO Currently, we're using polling. In the future, we could subscribe to patch events on this report, until an offerID is present.
        //  We then know that the backend has received a response from AUTOonline and patched the report. Unsubscribe from patch events afterwards.
        let autoonlineResponse: { residualValueOffer: ResidualValueOffer };
        try {
            autoonlineResponse = await this.httpClient
                .post<{ residualValueOffer: ResidualValueOffer }>(
                    `/api/v0/reports/${this.report._id}/residualValueExchanges/autoonline`,
                    {
                        offerExpiration:
                            this.report.valuation.residualValueInquiryTargetDate || this.residualValueTargetDate,
                    },
                )
                .toPromise();
        } catch (error) {
            autoonlineResidualValueExchangeForUi.exportPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    AUTOONLINE_OFFER_CREATION_TIMEOUT_REACHED: {
                        title: 'AUTOonline-Export dauert',
                        body: 'Bitte habe noch etwas Geduld. AUTOonline braucht bei einer großen Anzahl Bilder etwas länger. Bitte bleibe vorerst in diesem Reiter.',
                        toastType: 'info',
                        action: () => {
                            this.report.valuation.autoonlineResidualValueOffer.autoixpertApiTimeoutReceivedAt =
                                DateTime.now().toISO();
                            this.report.valuation.autoonlineResidualValueOffer.numberOfResultChecksAfterApiTimeout = 0;
                            this.saveReport();

                            this.startAutoonlineExportResultCheckInterval();
                        },
                    },
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: 'Übertragung fehlgeschlagen',
                    body: 'Erstellung eines Inserats nicht möglich. Bitte prüfe, ob AUTOonline erreichbar ist oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
        Object.assign<ResidualValueOffer, ResidualValueOffer>(
            this.report.valuation.autoonlineResidualValueOffer,
            autoonlineResponse.residualValueOffer,
        );

        this.tutorialStateService.markUserTutorialStepComplete('residualValueRequestCreated');

        await this.saveReport({ waitForServer: true });
        autoonlineResidualValueExchangeForUi.exportPending = false;
        this.toastService.success('Inserat erstellt');
    }

    public async connectAutoonlineOffer(autoonlineOffer: ResidualValueOffer) {
        if (this.isReportLocked()) {
            return;
        }

        // Keep the object reference the same.
        Object.assign(this.report.valuation.autoonlineResidualValueOffer, autoonlineOffer);

        await this.saveReport({ waitForServer: true });

        this.importAutoonlineResidualValueBids();
    }

    private startAutoonlineExportResultCheckInterval() {
        console.log(`Setting timer to check for AUTOonline export results...`);
        this.residualValueExchangesForUi.find((exchange) => exchange.name === 'autoonline').exportPending = true;

        this.autoonlineExportResultCheckTimeoutId = window.setTimeout(async () => {
            console.log(`Executing timer callback to check for AUTOonline export results...`);
            /**
             * Pull changes in this report from the server. The server may have received a response from AUTOonline in the meantime. The backend
             * will write the result to the report object directly in that case.
             */
            try {
                this.report.valuation.autoonlineResidualValueOffer.numberOfResultChecksAfterApiTimeout++;
                /**
                 * Wait until all changes are merged before checking for an offerId below. If we would not wait for the server, the report in the component would not
                 * have been updated through changes merged during pullFromServer() and the offerId below would always be empty.
                 */
                await this.reportService.put(this.report, { waitForServer: true });
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'AUTOonline-Ergebnisse nicht abfragbar',
                        body: 'Das Gutachten konnte nicht vom Server geladen werden, um die aktuellen AUTOonline-Ergebnisse abzufragen.',
                    },
                });
            }

            if (this.report.valuation.autoonlineResidualValueOffer.offerId) {
                this.stopAutoonlineExportResultCheckInterval();
                this.toastService.success('AUTOonline-Export', 'Restwertinserat erfolgreich exportiert.');
            } else {
                if (this.shouldAutoonlineExportResultCheckIntervalBeStarted()) {
                    this.startAutoonlineExportResultCheckInterval();
                } else {
                    console.log(
                        `The AUTOonline timeout occurred more than 5 minutes ago or the results were already incorporated into this report. No need to query the server again.`,
                    );
                }
            }
        }, 5_000);
    }

    private stopAutoonlineExportResultCheckInterval() {
        window.clearTimeout(this.autoonlineExportResultCheckTimeoutId);
        this.autoonlineExportResultCheckTimeoutId = null;

        this.residualValueExchangesForUi.find((exchange) => exchange.name === 'autoonline').exportPending = false;
        console.log(`AUTOonline export timeout stopped.`);
    }

    private shouldAutoonlineExportResultCheckIntervalBeStarted(): boolean {
        if (!this.report.valuation.autoonlineResidualValueOffer.autoixpertApiTimeoutReceivedAt) {
            console.log(
                'No autoixpertApiTimeoutReceivedAt present on the report. this.report.valuation.autoonlineResidualValueOffer:',
                this.report.valuation.autoonlineResidualValueOffer,
            );
            return false;
        }

        const autoixpertApiTimeoutReceivedAt: DateTime = DateTime.fromISO(
            this.report.valuation.autoonlineResidualValueOffer.autoixpertApiTimeoutReceivedAt,
        );

        // Only start the timer if the API timeout was less than 5 minutes ago.
        const timeoutLiesBackMoreThan5Minutes = autoixpertApiTimeoutReceivedAt < DateTime.now().minus({ minutes: 5 });

        return !timeoutLiesBackMoreThan5Minutes;
    }

    public isAutoonlineResidualValueOfferAllowed(): boolean {
        return this.isResidualValueOfferAllowed() && !!this.report.car.damageDescription;
    }

    public openAutoonlineResidualValueExchange(): void {
        if (!this.report.valuation.autoonlineResidualValueOffer?.link) return;

        this.newWindowService.open(
            `https://easyonline.autoonline.com/Login.aspx?username=${this.user.autoonlineUser?.customerNumber}&password=${this.user.autoonlineUser?.password}`,
        );
    }

    public async importAutoonlineResidualValueBids(): Promise<void> {
        // Don't start importing a non-existent offer
        if (!this.report.valuation.autoonlineResidualValueOffer?.offerId) {
            return;
        }

        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        const autoonlineResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'autoonline',
        );
        autoonlineResidualValueExchangeForUi.importPending = true;

        let bidsResponse: ResidualValueOfferResponse;
        try {
            bidsResponse = await this.httpClient
                .get<ResidualValueOfferResponse>(`/api/v0/reports/${this.report._id}/residualValueExchanges/autoonline`)
                .toPromise();
        } catch (error) {
            autoonlineResidualValueExchangeForUi.importPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: { title: 'Gebote konnten nicht importiert werden' },
            });
        }
        const newBids = bidsResponse.residualValueOffer.bids;

        this.copySelectionToNewBids({
            existingBids: this.report.valuation.autoonlineResidualValueOffer.bids,
            newBids: newBids,
        });
        this.report.valuation.autoonlineResidualValueOffer.bids = newBids;
        this.report.valuation.autoonlineResidualValueOffer.retrievedAt = moment().format();

        this.setResidualValueToHighestBidValue();

        if (bidsResponse.hasAllBidsPdf || bidsResponse.hasRegionalBidsPdf) {
            addDocumentToReport(
                {
                    team: this.team,
                    report: this.report,
                    newDocument: new DocumentMetadata({
                        type: 'autoonlineResidualValueBidList',
                        title: 'AUTOonline Restwertgebote',
                        uploadedDocumentId: null,
                        permanentUserUploadedDocument: false,
                        createdAt: moment().format(),
                        createdBy: this.user._id,
                    }),
                    documentGroup: 'report',
                },
                { insertAfterFallback: 'report' },
            );
        } else {
            this.toastService.warn(
                'Kein Gebotsblatt',
                'AUTOonline hat kein Gebotsblatt für dieses Fahrzeug zur Verfügung gestellt. Bitte prüfe in der Oberfläche von AUTOonline, ob du das Gebotsblatt dort herunterladen kannst.',
            );
        }

        this.saveReport();
        autoonlineResidualValueExchangeForUi.importPending = false;
    }

    private areAutoonlineCredentialsComplete(): boolean {
        return isAutoonlineUserComplete(this.user);
    }

    public async deleteAutoonlineResidualValueOffer(): Promise<void> {
        if (this.isReportLocked()) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Restwertinserate können gelöscht werden, sobald du wieder online bist.',
            );
            return;
        }

        // Delete AUTOonline record unless we're within an amendment. In that case, we only disconnect the records.
        if (!this.isAmendmentReport()) {
            this.httpClient
                .delete<any>(`/api/v0/reports/${this.report._id}/residualValueExchanges/autoonline`)
                .subscribe();
        }

        this.resetAutoonlineResidualValueOffer();

        this.saveReport();
    }

    private resetAutoonlineResidualValueOffer(): void {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        Object.assign(this.report.valuation.autoonlineResidualValueOffer, new AutoonlineResidualValueOffer());

        removeDocumentTypeFromReport({
            report: this.report,
            documentGroup: 'report',
            documentType: 'autoonlineResidualValueBidList',
        });
    }

    public getAutoonlineTooltipForExportIcon(): string {
        if (!this.areAutoonlineCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den <a href="/Einstellungen#residual-and-market-value-exchanges-section">Einstellungen</a> eingegeben werden.';
        }

        if (!this.isAutoonlineResidualValueOfferAllowed()) {
            const missingDetails: string[] = this.getMissingDetailsForResidualValueOffers();
            if (!this.report.car.damageDescription) {
                missingDetails.push('Schadenbeschreibung');
            }
            return this.stringifyMissingDetailsForResidualValueOffers(missingDetails);
        }

        return 'Restwertinserat einstellen';
    }

    public openAutoonlineOfferConnectionDialog() {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        this.autoonlineOfferConnectionDialogShown = true;
    }

    public closeAutoonlineOfferConnectionDialog() {
        this.autoonlineOfferConnectionDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END AUTOonline
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  WinValue
    //****************************************************************************/
    public areWinvalueResidualValueCredentialsComplete(): boolean {
        return areWinvalueResidualValueCredentialsComplete(this.user);
    }

    public async createWinvalueOffer() {
        if (
            !this.areWinvalueResidualValueCredentialsComplete() ||
            !this.isResidualValueOfferAllowed() ||
            this.isReportLocked()
        ) {
            this.toastService.info(this.getWinvalueTooltipForResidualValueExportIcon());
            return;
        }

        // Since a residual value offer is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Restwertbörse von Winvalue ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        const winvalueResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'winvalue',
        );

        // Don't allow creating dulicate offers, e.g. if the user doubleclicked.
        if (winvalueResidualValueExchangeForUi.exportPending) return;

        winvalueResidualValueExchangeForUi.exportPending = true;

        this.toastService.info('Exportiere zu WinValue...', 'Dies kann einige Sekunden dauern.');

        let winvalueResponse: ResidualValueOfferResponse;
        try {
            winvalueResponse = await this.httpClient
                .post<ResidualValueOfferResponse>(
                    `/api/v0/reports/${this.report._id}/residualValueExchanges/winvalue`,
                    {
                        offerExpiration:
                            this.report.valuation.residualValueInquiryTargetDate || this.residualValueTargetDate,
                    },
                )
                .toPromise();
        } catch (error) {
            // The request is done.
            winvalueResidualValueExchangeForUi.exportPending = false;
            console.error('Error creating the WinValue offer.', { error });

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: 'Übertragung fehlgeschlagen',
                    body: 'Erstellung eines Inserats nicht möglich. Bitte prüfe, ob WinValue online ist oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
        Object.assign<ResidualValueOffer, ResidualValueOffer>(
            this.report.valuation.winvalueResidualValueOffer,
            winvalueResponse.residualValueOffer,
        );

        this.tutorialStateService.markUserTutorialStepComplete('residualValueRequestCreated');

        // Open the WinValue offer in edit mode so the user can hide license plates or change other data.
        this.openWinvalueResidualValueExchange();

        await this.saveReport({ waitForServer: true });
        winvalueResidualValueExchangeForUi.exportPending = false;
        this.importWinvalueResidualValueBids();
    }

    /**
     * Open the "Restwertbörse" and export necessary data.
     * The residual value finder is used to ask for bids on the damaged vehicle to estimate what the car is still worth.
     * If a WinValue offer is already linked to the report, ask the user if he wants to cancel the existing offer and add a new one.
     */
    public openWinvalueResidualValueExchange(): void {
        if (!this.report.valuation.winvalueResidualValueOffer?.link) return;
        this.newWindowService.open(this.report.valuation.winvalueResidualValueOffer.link);
    }

    public async connectWinvalueOffer(winvalueOffer: ResidualValueOffer) {
        if (this.isReportLocked()) {
            return;
        }

        // Keep the object reference the same.
        Object.assign(this.report.valuation.winvalueResidualValueOffer, winvalueOffer);

        await this.saveReport({ waitForServer: true });

        this.importWinvalueResidualValueBids();
    }

    public openHelpcenterArticleOnRegionalRadiusWinvalue() {
        this.newWindowService.open(
            'https://wissen.autoixpert.de/hc/de/articles/360019753080-Regionalen-Radius-f%C3%BCr-Restwertgebote-in-WinValue-einstellen',
            '_blank',
            'noopener',
        );
    }

    public async importWinvalueResidualValueBids(): Promise<void> {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        // Don't start importing a non-existent offer
        if (!this.report.valuation.winvalueResidualValueOffer?.offerId) {
            return;
        }

        const winvalueResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'winvalue',
        );
        winvalueResidualValueExchangeForUi.importPending = true;

        let winvalueResponse: ResidualValueOfferResponse;
        try {
            winvalueResponse = await this.httpClient
                .get<ResidualValueOfferResponse>(`/api/v0/reports/${this.report._id}/residualValueExchanges/winvalue`)
                .toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: '',
                    body: 'Gebote konnten nicht importiert werden',
                },
            });
        } finally {
            winvalueResidualValueExchangeForUi.importPending = false;
        }

        const newBids = winvalueResponse?.residualValueOffer.bids;
        this.copySelectionToNewBids({
            existingBids: this.report.valuation.winvalueResidualValueOffer.bids,
            newBids,
        });
        this.report.valuation.winvalueResidualValueOffer.bids = newBids;
        this.report.valuation.winvalueResidualValueOffer.retrievedAt = moment().format();

        this.setResidualValueToHighestBidValue();

        if (winvalueResponse.hasAllBidsPdf || winvalueResponse.hasRegionalBidsPdf) {
            addDocumentToReport(
                {
                    team: this.team,
                    report: this.report,
                    newDocument: new DocumentMetadata({
                        type: 'winvalueResidualValueBidList',
                        title: 'WinValue Restwertgebote',
                        uploadedDocumentId: null,
                        permanentUserUploadedDocument: false,
                        createdAt: moment().format(),
                        createdBy: this.user._id,
                    }),
                    documentGroup: 'report',
                },
                { insertAfterFallback: 'report' },
            );
        } else {
            this.toastService.warn(
                'Kein Gebotsblatt',
                'WinValue hat kein Gebotsblatt für dieses Fahrzeug zur Verfügung gestellt. Bitte prüfe in der Oberfläche von WinValue, ob du das Gebotsblatt dort herunterladen kannst.',
            );
        }

        this.saveReport();
        winvalueResidualValueExchangeForUi.importPending = false;
    }

    /**
     * Remove the residual value offer from WinValue's servers and display a success message.
     */
    public async deleteWinvalueResidualValueOffer(): Promise<void> {
        if (this.isReportLocked()) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Restwertinserate können gelöscht werden, sobald du wieder online bist.',
            );
            return;
        }

        // Delete WinValue record unless we're within an amendment. In that case, we only disconnect the records.
        if (!this.isAmendmentReport()) {
            this.httpClient
                .delete<any>(`/api/v0/reports/${this.report._id}/residualValueExchanges/winvalue`)
                .subscribe();
        }

        this.resetWinvalueResidualValueOffer();

        this.saveReport();
    }

    private resetWinvalueResidualValueOffer(): void {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        // Reset all properties instead of replacing the object to keep the references intact.
        Object.assign<ResidualValueOffer, ResidualValueOffer>(
            this.report.valuation.winvalueResidualValueOffer,
            new ResidualValueOffer(),
        );

        removeDocumentTypeFromReport({
            report: this.report,
            documentGroup: 'report',
            documentType: 'winvalueResidualValueBidList',
        });
    }

    public getWinvalueTooltipForResidualValueExportIcon(): string {
        if (!this.areWinvalueResidualValueCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den <a href="/Einstellungen#residual-and-market-value-exchanges-section">Einstellungen</a> eingegeben werden.';
        }

        if (!this.isResidualValueOfferAllowed()) {
            const missingDetails: string[] = this.getMissingDetailsForResidualValueOffers();
            return this.stringifyMissingDetailsForResidualValueOffers(missingDetails);
        }

        return 'Restwertinserat einstellen';
    }

    private getMissingDetailsForResidualValueOffers(): string[] {
        const isTrailer = new Array<CarShape>('trailer', 'caravanTrailer', 'semiTrailer').includes(
            this.report.car.shape,
        );
        const isBicycle = new Array<CarShape>('bicycle', 'pedelec', 'e-bike').includes(this.report.car.shape);

        const missingDetails: string[] = [];

        if (!this.report.car.make) {
            missingDetails.push('Hersteller');
        }
        if (!this.report.car.model) {
            missingDetails.push('Typ/Modell');
        }
        if (!this.report.car.productionYear) {
            missingDetails.push('Baujahr');
        }
        if (!this.report.car.firstRegistration && !isBicycle) {
            missingDetails.push('Erstzulassung');
        }
        if (!this.getCarLocationZip()) {
            missingDetails.push('PLZ des Fahrzeugstandorts');
        }
        if (!isTrailer && !isBicycle) {
            if (!this.report.car.mileage && !this.report.car.mileageMeter && !this.report.car.mileageAsStated) {
                missingDetails.push('km-Stand (abgelesen, geschätzt oder angegeben)');
            }
            if (!this.isFuelTypeSet()) {
                missingDetails.push('Motorart/Treibstoff');
            }
        }
        if (!this.report.car.shape) {
            missingDetails.push('Fahrzeugart');
        }

        return missingDetails;
    }

    private stringifyMissingDetailsForResidualValueOffers(missingDetails: string[]): string {
        if (this.isReportLocked()) {
            return 'Das Gutachten ist abgeschlossen.';
        }

        // Don't allow export to the past.
        if (this.isResidualValueTargetDateInThePast()) {
            return 'Zieldatum liegt in der Vergangenheit. Lege es in die Zukunft.';
        }

        // Don't allow exporting more than 50 photos to prevent our servers from crashing.
        if (this.areTooManyPhotosSelectedForResidualValueExchange()) {
            return 'Mehr als 50 Fotos ausgewählt.\n\nDie Restwertbörsen-Schnittstellen funktionieren nur bis zu 50 Fotos pro Export zuverlässig. Bitte reduziere die Anzahl der ausgewählten Fotos.';
        }

        if (missingDetails.length === 1) {
            return `${missingDetails[0]} ist erforderlich.`;
        } else {
            return `${missingDetails.slice(0, -1).join(', ')} und ${
                missingDetails[missingDetails.length - 1]
            } sind erforderlich.`;
        }
    }

    public openWinvalueOfferConnectionDialog() {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }
        this.winvalueOfferConnectionDialogShown = true;
    }

    public closeWinvalueOfferConnectionDialog() {
        this.winvalueOfferConnectionDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END WinValue
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CarTV
    //****************************************************************************/
    public async createCartvOffer() {
        if (!this.areCartvCredentialsComplete()) {
            this.toastService.error(
                'Zugangsdaten fehlen',
                this.getCartvTooltipForExportIcon() +
                    '<br><br>Noch keinen Account? <a href="https://www.cartv.eu/neuanmeldung-sv/" target="_blank" rel="noopener">Registrieren</a>',
            );
            return;
        }
        if (!this.isResidualValueOfferAllowed() || this.isReportLocked()) {
            this.toastService.info(this.getCartvTooltipForExportIcon());
            return;
        }

        // Since a residual value offer is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Restwertbörse von CARTV ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        const cartvResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'cartv',
        );

        // Don't allow creating duplicate offers, e.g. if the user double-clicked.
        if (cartvResidualValueExchangeForUi.exportPending) return;

        cartvResidualValueExchangeForUi.exportPending = true;

        this.toastService.info('Exportiere zu CarTV...', 'Dies kann einige Sekunden dauern.');

        let cartvResponse: ResidualValueOfferResponse;
        try {
            cartvResponse = await this.httpClient
                .post<ResidualValueOfferResponse>(`/api/v0/reports/${this.report._id}/residualValueExchanges/cartv`, {
                    offerExpiration:
                        this.report.valuation.residualValueInquiryTargetDate || this.residualValueTargetDate,
                })
                .toPromise();
        } catch (error) {
            // The request is done.
            cartvResidualValueExchangeForUi.exportPending = false;
            console.error('Error creating cartv offer', { error });

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: 'Übertragung fehlgeschlagen',
                    body: 'Erstellung eines Inserats nicht möglich. Bitte prüfe, ob CARTV online ist oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        Object.assign<ResidualValueOffer, ResidualValueOffer>(
            this.report.valuation.cartvResidualValueOffer,
            cartvResponse.residualValueOffer,
        );

        this.tutorialStateService.markUserTutorialStepComplete('residualValueRequestCreated');

        await this.saveReport({ waitForServer: true });
        cartvResidualValueExchangeForUi.exportPending = false;
        this.importCartvResidualValueBids();
    }

    public async connectCartvOffer(cartvOffer: ResidualValueOffer) {
        if (this.isReportLocked()) {
            return;
        }

        // Keep the object reference the same.
        Object.assign(this.report.valuation.cartvResidualValueOffer, cartvOffer);

        await this.saveReport({ waitForServer: true });

        this.importCartvResidualValueBids();
    }

    public openCartvResidualValueExchange(): void {
        if (!this.report.valuation.cartvResidualValueOffer?.link) return;

        this.newWindowService.open(this.report.valuation.cartvResidualValueOffer.link);
    }

    public async importCartvResidualValueBids(): Promise<void> {
        // Don't start importing a non-existent offer
        if (!this.report.valuation.cartvResidualValueOffer?.offerId) {
            return;
        }

        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        const cartvResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'cartv',
        );
        cartvResidualValueExchangeForUi.importPending = true;

        let cartvResponse: ResidualValueOfferResponse;
        try {
            cartvResponse = await this.httpClient
                .get<ResidualValueOfferResponse>(`/api/v0/reports/${this.report._id}/residualValueExchanges/cartv`)
                .toPromise();
        } catch (error) {
            cartvResidualValueExchangeForUi.importPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: { title: 'Gebote konnten nicht importiert werden' },
            });
        }

        const newBids = cartvResponse?.residualValueOffer.bids;
        this.copySelectionToNewBids({
            existingBids: this.report.valuation.cartvResidualValueOffer.bids,
            newBids,
        });
        this.report.valuation.cartvResidualValueOffer.bids = newBids;
        this.report.valuation.cartvResidualValueOffer.retrievedAt = moment().format();

        this.setResidualValueToHighestBidValue();

        if (cartvResponse.hasAllBidsPdf || cartvResponse.hasRegionalBidsPdf) {
            addDocumentToReport(
                {
                    team: this.team,
                    report: this.report,
                    newDocument: new DocumentMetadata({
                        type: 'cartvResidualValueBidList',
                        title: 'CarTV Restwertgebote',
                        uploadedDocumentId: null,
                        permanentUserUploadedDocument: false,
                        createdAt: moment().format(),
                        createdBy: this.user._id,
                    }),
                    documentGroup: 'report',
                },
                { insertAfterFallback: 'report' },
            );
        } else {
            this.toastService.warn(
                'Kein Gebotsblatt',
                'CARTV hat kein Gebotsblatt für dieses Fahrzeug zur Verfügung gestellt. Bitte prüfe in der Oberfläche von CARTV, ob du das Gebotsblatt dort herunterladen kannst.',
            );
        }

        this.saveReport();
        cartvResidualValueExchangeForUi.importPending = false;
    }

    private areCartvCredentialsComplete(): boolean {
        return isCarTvUserComplete(this.user);
    }

    public async deleteCartvResidualValueOffer(): Promise<void> {
        if (this.isReportLocked()) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Restwertinserate können gelöscht werden, sobald du wieder online bist.',
            );
            return;
        }

        /**
         * Delete CARTV record unless we're within an amendment report. In that case, we only disconnect the records because
         * that's usually what the user wants: Remove the old residual value request (without deleting it for the original report) and create a new one.
         */
        if (!this.isAmendmentReport()) {
            try {
                await this.httpClient
                    .delete<any>(`/api/v0/reports/${this.report._id}/residualValueExchanges/cartv`)
                    .toPromise();
            } catch (error) {
                if (
                    error.code === 'ERROR_DELETING_RESIDUAL_VALUE_EXCHANGE_OFFER' &&
                    error.causedBy?.code === 'CARTV_RESIDUAL_VALUE_OFFER_NOT_DELETED_DUE_TO_EXISTING_BIDS'
                ) {
                    /**
                     * CARTV may block deletion if offers have already arrived. Ask the user if he wants to disconnect the offer instead.
                     */
                    const shallOfferBeDisconnected: boolean = await this.dialog
                        .open(ConfirmDialogComponent, {
                            data: {
                                heading: 'CarTV-Inserat löschen?',
                                content:
                                    'Weil bereits Gebote eingegangen sind, lässt CarTV das Inserat nicht mehr löschen. Möchtest du das Inserat von deinem Vorgang trennen?',
                                confirmLabel: 'Inserat trennen',
                                cancelLabel: 'Inserat behalten',
                                confirmColorRed: true,
                            },
                        })
                        .afterClosed()
                        .toPromise();

                    // If the user wants to disconnect the offer, delete the documents on our servers. They're useless now.
                    if (shallOfferBeDisconnected) {
                        await this.httpClient
                            .delete<any>(`/api/v0/reports/${this.report._id}/residualValueExchanges/cartv`, {
                                params: {
                                    onlyDeleteImportedDocuments: true,
                                },
                            })
                            .toPromise();
                    } else {
                        // If the user does not want to disconnect the offer, return to prevent the disconnect steps below.
                        return;
                    }
                } else {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                        },
                        defaultHandler: {
                            title: 'CARTV-Restwertinserat nicht gelöscht',
                            body: 'Es ist ein unbekanntes Problem aufgetreten. Kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>, um eine Lösung zu finden.',
                        },
                    });
                }
            }
        }

        this.resetCartvResidualValueOffer();

        this.saveReport();
    }

    private resetCartvResidualValueOffer(): void {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        // Reset all properties instead of replacing the object to keep the references intact.
        Object.assign<ResidualValueOffer, ResidualValueOffer>(
            this.report.valuation.cartvResidualValueOffer,
            new ResidualValueOffer(),
        );

        removeDocumentTypeFromReport({
            report: this.report,
            documentGroup: 'report',
            documentType: 'cartvResidualValueBidList',
        });
    }

    public getCartvTooltipForExportIcon(): string {
        if (!this.areCartvCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den <a href="/Einstellungen#residual-and-market-value-exchanges-section">Einstellungen</a> eingegeben werden.';
        }

        if (!this.isResidualValueOfferAllowed()) {
            const missingDetails: string[] = this.getMissingDetailsForResidualValueOffers();
            return this.stringifyMissingDetailsForResidualValueOffers(missingDetails);
        }

        return 'Restwertinserat einstellen';
    }

    public openCartvOfferConnectionDialog() {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        this.cartvOfferConnectionDialogShown = true;
    }

    public closeCartvOfferConnectionDialog() {
        this.cartvOfferConnectionDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CarTV
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  car.casion
    //****************************************************************************/
    public async createCarcasionOffer() {
        if (!this.areCarcasionCredentialsComplete() || !this.isResidualValueOfferAllowed() || this.isReportLocked()) {
            this.toastService.info(this.getCarcasionTooltipForExportIcon());
            return;
        }

        // Since a residual value offer is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Restwertbörse von car.casion ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        const carcasionResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'carcasion',
        );

        // Don't allow creating dulicate offers, e.g. if the user doubleclicked.
        if (carcasionResidualValueExchangeForUi.exportPending) return;

        carcasionResidualValueExchangeForUi.exportPending = true;

        this.toastService.info('Exportiere zu car.casion...', 'Dies kann einige Sekunden dauern.');

        let carcasionResponse: ResidualValueOfferResponse;
        try {
            carcasionResponse = await this.httpClient
                .post<ResidualValueOfferResponse>(
                    `/api/v0/reports/${this.report._id}/residualValueExchanges/carcasion`,
                    {
                        offerExpiration:
                            this.report.valuation.residualValueInquiryTargetDate || this.residualValueTargetDate,
                    },
                )
                .toPromise();
        } catch (error) {
            // The request is done.
            carcasionResidualValueExchangeForUi.exportPending = false;
            console.error('Error creating carcasion offer', { error });

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: {
                    title: 'Übertragung fehlgeschlagen',
                    body: 'Erstellung eines Inserats nicht möglich. Bitte prüfe, ob deine Zugangsdaten korrekt sind und car.casion online ist. Ist beides der Fall, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        Object.assign<ResidualValueOffer, ResidualValueOffer>(
            this.report.valuation.carcasionResidualValueOffer,
            carcasionResponse.residualValueOffer,
        );

        this.tutorialStateService.markUserTutorialStepComplete('residualValueRequestCreated');

        await this.saveReport({ waitForServer: true });
        carcasionResidualValueExchangeForUi.exportPending = false;
        this.importCarcasionResidualValueBids();
    }

    public async connectCarcasionOffer(carcasionOffer: ResidualValueOffer) {
        if (this.isReportLocked()) {
            return;
        }

        // Keep the object reference the same.
        Object.assign(this.report.valuation.carcasionResidualValueOffer, carcasionOffer);

        await this.saveReport({ waitForServer: true });

        this.importCarcasionResidualValueBids();
    }

    public openCarcasionResidualValueExchange(): void {
        if (!this.report.valuation.carcasionResidualValueOffer?.link) return;
        this.newWindowService.open(this.report.valuation.carcasionResidualValueOffer.link);
    }

    public async importCarcasionResidualValueBids(): Promise<void> {
        // Don't start importing a non-existent offer
        if (!this.report.valuation.carcasionResidualValueOffer?.offerId) {
            return;
        }

        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        const carcasionResidualValueExchangeForUi = this.residualValueExchangesForUi.find(
            (exchange) => exchange.name === 'carcasion',
        );
        carcasionResidualValueExchangeForUi.importPending = true;

        let carcasionResponse: ResidualValueOfferResponse;
        try {
            carcasionResponse = await this.httpClient
                .get<ResidualValueOfferResponse>(`/api/v0/reports/${this.report._id}/residualValueExchanges/carcasion`)
                .toPromise();
        } catch (error) {
            carcasionResidualValueExchangeForUi.importPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getResidualValueAndMarketAnalysisErrorHandlers(this.router),
                },
                defaultHandler: { title: 'Gebote konnten nicht importiert werden' },
            });
        }

        const newBids = carcasionResponse.residualValueOffer.bids;
        this.copySelectionToNewBids({
            existingBids: this.report.valuation.carcasionResidualValueOffer.bids,
            newBids,
        });
        this.report.valuation.carcasionResidualValueOffer.bids = newBids;
        this.report.valuation.carcasionResidualValueOffer.retrievedAt = moment().format();

        this.setResidualValueToHighestBidValue();

        if (carcasionResponse.hasAllBidsPdf || carcasionResponse.hasRegionalBidsPdf) {
            addDocumentToReport(
                {
                    team: this.team,
                    report: this.report,
                    newDocument: new DocumentMetadata({
                        type: 'carcasionResidualValueBidList',
                        title: 'car.casion Restwertgebote',
                        uploadedDocumentId: null,
                        permanentUserUploadedDocument: false,
                        createdAt: moment().format(),
                        createdBy: this.user._id,
                    }),
                    documentGroup: 'report',
                },
                { insertAfterFallback: 'report' },
            );
        } else {
            this.toastService.warn(
                'Kein Gebotsblatt',
                'car.casion hat kein Gebotsblatt für dieses Fahrzeug zur Verfügung gestellt. Bitte prüfe in der Oberfläche von car.casion, ob du das Gebotsblatt dort herunterladen kannst.',
            );
        }

        this.saveReport();
        carcasionResidualValueExchangeForUi.importPending = false;
    }

    private areCarcasionCredentialsComplete(): boolean {
        return isCarcasionUserComplete(this.user);
    }

    public deleteCarcasionResidualValueOffer(): void {
        if (this.isReportLocked()) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Restwertinserate können gelöscht werden, sobald du wieder online bist.',
            );
            return;
        }

        // Delete car.casion record unless we're within an amendment. In that case, we only disconnect the records.
        if (!this.isAmendmentReport()) {
            this.httpClient
                .delete<any>(`/api/v0/reports/${this.report._id}/residualValueExchanges/carcasion`)
                .subscribe();
        }

        this.resetCarcasionResidualValueOffer();

        this.saveReport();
    }

    private resetCarcasionResidualValueOffer(): void {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        // Reset all properties instead of replacing the object to keep the references intact.
        Object.assign<ResidualValueOffer, ResidualValueOffer>(
            this.report.valuation.carcasionResidualValueOffer,
            new ResidualValueOffer(),
        );

        removeDocumentTypeFromReport({
            report: this.report,
            documentGroup: 'report',
            documentType: 'carcasionResidualValueBidList',
        });
    }

    public getCarcasionTooltipForExportIcon(): string {
        if (!this.areCarcasionCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den <a href="/Einstellungen#residual-and-market-value-exchanges-section">Einstellungen</a> eingegeben werden.';
        }

        if (!this.isResidualValueOfferAllowed()) {
            const missingDetails: string[] = this.getMissingDetailsForResidualValueOffers();
            return this.stringifyMissingDetailsForResidualValueOffers(missingDetails);
        }

        return 'Restwertinserat einstellen';
    }

    public openCarcasionOfferConnectionDialog() {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }

        this.carcasionOfferConnectionDialogShown = true;
    }

    public closeCarcasionOfferConnectionDialog() {
        this.carcasionOfferConnectionDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END car.casion
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Residual Value Request Through E-Mail
    //****************************************************************************/
    public openResidualValueRequestDialog(): void {
        // Open dialog in read only view
        if (this.isReportLocked()) {
            this.residualValueRequestDialogShown = true;
            return;
        }

        if (!this.isResidualValueOfferAllowed()) {
            this.toastService.info(this.getResidualValueRequestTooltipForExportIcon());
            return;
        }

        if (this.report.valuation.autoixpertResidualValueOffer.closingDate) {
            // Only query for bids if online.
            if (this.networkStatusService.isOnline()) {
                this.importResidualValueRequestBids();
            }
        }

        this.residualValueRequestDialogShown = true;
    }

    public async importResidualValueRequestBids(): Promise<void> {
        if (this.doesAutoixpertResidualValueOfferBelongToOtherReport()) {
            this.toastService.error(
                'Nur im originalen Gutachten verfügbar',
                'Du hast die Restwertgebote aus einem anderen Gutachten kopiert. Um neue Restwertgebote einzuholen, lösche die Anfrage und stelle eine neue Anfrage für dieses Gutachten.',
            );
            return;
        }

        if (!this.report.valuation.autoixpertResidualValueOffer.closingDate) return;

        // Since a residual value offer is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Restwertgebote können abgerufen werden, sobald du wieder online bist.',
            );
            return;
        }

        this.residualValueRequestImportPending = true;

        let residualValueInvitations: ResidualValueInvitation[];

        try {
            residualValueInvitations = await this.residualValueRequestService.getBids(this.report);
        } catch (error) {
            this.residualValueRequestImportPending = false;
            return;
        }

        // Check if the closing date has passed. If so, we import all invitations with no response as "no bid placed".
        const now = moment();
        const isClosingDateInPast = moment(this.report.valuation.autoixpertResidualValueOffer.closingDate).isBefore(
            now,
        );

        if (residualValueInvitations.length) {
            //*****************************************************************************
            //  Process Invitations
            //****************************************************************************/
            // Convert residual value invitations to bids
            for (const residualValueInvitation of residualValueInvitations) {
                const existingBid = this.report.valuation.customResidualValueBids.find(
                    (customBid) => customBid.bidID === residualValueInvitation._id,
                );

                //*****************************************************************************
                //  Process ResidualValueBid
                //****************************************************************************/
                // An existing bid may be updated up to two minutes after it was last updated. This allows the invited bidder to fix typos in his bid value.
                if (existingBid) {
                    if (residualValueInvitation.bidValue === null && residualValueInvitation.notInterested === null) {
                        // In case the bidder removed his bid (possible up to 2 minutes), we need to remove the bid from our customResidualValueBids too
                        this.report.valuation.customResidualValueBids =
                            this.report.valuation.customResidualValueBids.filter(
                                (bid) => bid.bidID !== residualValueInvitation._id,
                            );
                    } else {
                        existingBid.bidValue.value = residualValueInvitation.bidValue;
                        existingBid.notInterested = residualValueInvitation.notInterested;
                    }
                } else if (
                    residualValueInvitation.bidValue !== null ||
                    residualValueInvitation.notInterested ||
                    isClosingDateInPast
                ) {
                    this.report.valuation.customResidualValueBids.push({
                        bidID: residualValueInvitation._id,
                        bidRank: null,
                        bidValue: {
                            currency: 'EUR',
                            value: residualValueInvitation.bidValue,
                        },
                        notInterested: residualValueInvitation.notInterested,
                        bidder: {
                            contactPersonId: residualValueInvitation.recipient._id,
                            address: {
                                city: residualValueInvitation.recipient.city,
                                companyName: residualValueInvitation.recipient.organization,
                                contact: `${residualValueInvitation.recipient.firstName || ''} ${
                                    residualValueInvitation.recipient.lastName || ''
                                }`.trim(),
                                country: null,
                                emailAddress: residualValueInvitation.recipient.email,
                                fax: null,
                                postcode: residualValueInvitation.recipient.zip,
                                street: residualValueInvitation.recipient.streetAndHouseNumberOrLockbox,
                                telephone: residualValueInvitation.recipient.phone,
                                telephone2: residualValueInvitation.recipient.phone2,
                            },
                        },
                        // Set the bindingDate to null in case the user is not interested. Otherwise calculate the exact binding date
                        bindingDate: residualValueInvitation.notInterested
                            ? null
                            : moment(this.report.valuation.autoixpertResidualValueOffer.closingDate)
                                  .add(this.report.valuation.autoixpertResidualValueOffer.bindingPeriodInDays, 'days')
                                  .format(),
                        dateOfBid: moment(residualValueInvitation.updatedAt).format(),
                        origin: 'axResidualValueRequest',
                        regional: true,
                        selected: null,
                    });
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Process ResidualValueBid
                /////////////////////////////////////////////////////////////////////////////*/

                // Note down if an invitee already looked at the residual value request
                const residualValueReceipt = this.report.valuation.autoixpertResidualValueOffer.invitationReceipts.find(
                    (receipt) => receipt.invitationId === residualValueInvitation._id,
                );
                if (residualValueReceipt) {
                    residualValueReceipt.openedResidualValueRequestAt = residualValueInvitation.openedByRecipientAt;
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Process Invitations
            /////////////////////////////////////////////////////////////////////////////*/

            addCustomResidualValueBidList({
                report: this.report,
                user: this.user,
                team: this.loggedInUserService.getTeam(),
            });
        }

        this.setResidualValueToHighestBidValue();

        this.report.valuation.autoixpertResidualValueOffer.retrievedAt = moment().format();
        this.residualValueRequestImportPending = false;
        this.saveReport();
    }

    /**
     * We have to check if a Autoixpert Residual Value Offer belongs to the current report or e.g. the original of the amendment report.
     */
    public doesAutoixpertResidualValueOfferBelongToOtherReport() {
        return (
            this.report.valuation.autoixpertResidualValueOffer.createdInReportId &&
            this.report.valuation.autoixpertResidualValueOffer.createdInReportId !== this.report._id
        );
    }

    /**
     * Opens the residual value request preview.
     * Assessors need to see their request if they have discussions with the dealer.
     */
    public openResidualValueRequestPreview(): void {
        const window: Window = this.residualValueRequestService.openResidualValueOfferPreview(this.report._id);

        if (!window) {
            // Don't hide the message until the user clicks it. Give the user time to read and act.
            this.toastService.warn(
                'Bitte Popups zulassen',
                'Das Öffnen der Vorschau wurde blockiert. Bitte erlaube autoiXpert, in deinem Browser Popups zu öffnen.',
                { timeOut: 0 },
            );
        }
    }

    public async deleteResidualValueRequest() {
        if (this.isReportLocked()) {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Ein Inserat aus einem abgeschlossenen Gutachten kann nicht gelöscht werden.',
            );
            return;
        }

        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Restwertinserat löschen?',
                    content: 'Das kann nicht rückgängig gemacht werden.',
                    confirmLabel: 'Löschen',
                    cancelLabel: 'Doch nicht',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();
        if (!decision) return;

        // Hold on to the current object for restoring it in case of error
        const currentResidualValueRequest = this.report.valuation.autoixpertResidualValueOffer;

        // Remove data locally
        this.report.valuation.autoixpertResidualValueOffer = new ResidualValueRequest();

        /**
         * We have to check, if the request belongs to the current report or another report (e.g. original in case of an amendment report):
         * - if the request belongs to this report, we delete it
         * - if the request belongs to another report, e.g. the original, we won't delete the request, we just unlink it
         */
        if (this.doesAutoixpertResidualValueOfferBelongToOtherReport()) {
            this.report.valuation.autoixpertResidualValueOffer = new ResidualValueRequest();
            this.saveReport();
        } else {
            this.residualValueRequestService.delete(this.report).subscribe({
                next: () => {
                    // Remove all custom bids that were created with this residual value request.
                    const residualValueRequestBids = this.report.valuation.customResidualValueBids.filter(
                        (bid) => bid.origin === 'axResidualValueRequest',
                    );
                    for (const residualValueRequestBid of residualValueRequestBids) {
                        this.report.valuation.customResidualValueBids.splice(
                            this.report.valuation.customResidualValueBids.findIndex(
                                (bid) => bid.bidID === residualValueRequestBid.bidID,
                            ),
                            1,
                        );
                    }

                    if (this.report.valuation.customResidualValueBids.length === 0) {
                        removeDocumentTypeFromReport({
                            report: this.report,
                            documentGroup: 'report',
                            documentType: 'customResidualValueBidList',
                        });
                    }

                    this.saveReport();
                },
                error: (error) => {
                    // Restore previous state
                    this.report.valuation.autoixpertResidualValueOffer = currentResidualValueRequest;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Inserat konnte nicht gelöscht werden',
                            body: 'Bitte kontaktiere die Hotline.',
                        },
                    });
                },
            });
        }
    }

    public hideResidualValueRequestDialog(): void {
        this.residualValueRequestDialogShown = false;
    }

    public getResidualValueRequestTooltipForExportIcon(): string {
        // TODO Warn user if he hasn't entered his email address after the test ended. Implement a central method in the user service to determine when email is required.

        if (!this.isResidualValueOfferAllowed()) {
            const missingDetails: string[] = this.getMissingDetailsForResidualValueOffers();
            return this.stringifyMissingDetailsForResidualValueOffers(missingDetails);
        }

        return 'Lokale Restwertanfrage stellen';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Residual Value Request Through E-Mail
    /////////////////////////////////////////////////////////////////////////////*/

    public enterResidualValueDataEditMode(): void {
        if (this.isReportLocked()) {
            return;
        }

        this.residualValueDataInEditMode = true;
    }

    public leaveResidualValueDataEditMode(): void {
        this.residualValueDataInEditMode = false;
    }

    public calculateResidualValueTargetDate(): void {
        if (
            !this.userPreferences.residualValueTimeOffsetAmount ||
            this.userPreferences.residualValueTimeOffsetAmount < 0
        ) {
            this.userPreferences.residualValueTimeOffsetAmount = 0;
        }

        // If a date has been set before, use that.
        if (this.report.valuation.residualValueInquiryTargetDate) {
            this.residualValueTargetDate = this.report.valuation.residualValueInquiryTargetDate;
            return;
        }

        // Calculate a new target date based on the user's preferences.
        const targetDate: Moment = getRelativeDate(
            this.userPreferences.residualValueTimeOffsetAmount,
            this.userPreferences.residualValueTimeOffsetUnit,
        );
        this.residualValueTargetDate = targetDate.format();

        if (this.userPreferences.residualValueTargetTime) {
            const targetTime: Moment = moment(this.userPreferences.residualValueTargetTime);

            targetDate.hour(targetTime.hours());
            targetDate.minute(targetTime.minutes());

            this.residualValueTargetDate = targetDate.format();
        }
    }

    public freezeResidualValueTargetDateOnReport(): void {
        this.report.valuation.residualValueInquiryTargetDate = this.residualValueTargetDate;
    }

    public rememberResidualValueTargetTime(): void {
        this.userPreferences.residualValueTargetTime = this.residualValueTargetDate;
        this.toastService.success('Standard-Ziel für Restwert-Inserate gemerkt.');
    }

    public unfreezeResidualValueTargetDateOnReport(): void {
        this.report.valuation.residualValueInquiryTargetDate = null;
    }

    //*****************************************************************************
    //  Car Location
    //****************************************************************************/
    public insertClaimantsZip(): void {
        this.report.valuation.vehicleLocationZip = this.report.claimant.contactPerson.zip;
    }

    public insertOwnerOfClaimantsCarZip(): void {
        this.report.valuation.vehicleLocationZip = this.report.ownerOfClaimantsCar.contactPerson.zip;
    }

    public getLastVisitZip(): string {
        return this.report.visits[this.report.visits.length - 1]?.zip;
    }

    public insertLastVisitZip(): void {
        this.report.valuation.vehicleLocationZip = this.getLastVisitZip();
    }

    public insertGarageZip(): void {
        this.report.valuation.vehicleLocationZip = this.report.garage.contactPerson.zip;
    }

    public getCarLocationZip(): string {
        return this.report.valuation.vehicleLocationZip || this.report.claimant.contactPerson.zip;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Location
    /////////////////////////////////////////////////////////////////////////////*/

    private setResidualValueToHighestBidValue(): void {
        let relevantBids = this.allBids;

        // If the user has already selected bids manually, only consider those for setting the highest value.
        if (this.selectedBids.length) {
            relevantBids = this.selectedBids;
        }

        if (relevantBids.length === 0) {
            return;
        }

        // Sort bids in descending order, i. e. the largest value will be at index zero.
        relevantBids.sort(residualValueBidSortFunction);

        const highestBidValue = relevantBids[0].bidValue.value;
        const previousResidualValue = this.report.valuation.residualValue || 0;
        if (highestBidValue > previousResidualValue) {
            const previousValueFormatted = previousResidualValue
                ? new CurrencyPipe('de').transform(this.report.valuation.residualValue, '€')
                : null;
            const newValueFormatted = new CurrencyPipe('de').transform(highestBidValue, '€');

            this.report.valuation.residualValue = highestBidValue;

            // Trigger re-calculation of the damage class
            this.emitResidualValueChange();

            if (previousValueFormatted) {
                this.toastService.info(
                    'Neues Höchstgebot',
                    `Neu: ${newValueFormatted}\nAlt: ${previousValueFormatted}`,
                );
            } else {
                this.toastService.info('Neues Höchstgebot', `${newValueFormatted}`);
            }
        }
    }

    /**
     * When importing new bids, we would overwrite the user's selection of them. Therefore, copy that information.
     */
    private copySelectionToNewBids({
        existingBids = [],
        newBids,
    }: {
        existingBids: ResidualValueBid[];
        newBids: ResidualValueBid[];
    }): void {
        for (const newBid of newBids) {
            const matchingExistingBid = existingBids.find((existingBid) => existingBid.bidID === newBid.bidID);
            if (matchingExistingBid) {
                newBid.selected = matchingExistingBid.selected;
            }
        }
    }

    public openBidSelector(startInCreationMode = false, filter: ResidualValueExchangeName | 'none' = 'none'): void {
        this.bidSelectorStartInCreationMode = startInCreationMode;
        this.bidSelectorFilter = filter;

        this.bidSelectorShown = true;
    }

    public hideBidSelector(): void {
        this.bidSelectorShown = false;
    }

    public processHighestSelectedBid(highestBid: ResidualValueBid): void {
        if (!highestBid) {
            return;
        }

        // In case the bid value is null -> there is no bidder with a bid (only not interested bidders) -> set residual value to null
        // The reason for this is: if you only receive responses from bidders that are not interested, they wouldn't even pick up the car for 0 €
        // which means the car probably needs to be disposed/scrapped (which then costs money).
        this.report.valuation.residualValue = highestBid.bidValue.value || null;

        this.setVehicleValueToHighestBid(highestBid.origin);

        // Save the chosen residual value to the report
        this.saveReport();
    }

    public hasRegionalBids(residualValueExchange: ResidualValueExchangeForUi): boolean {
        return residualValueExchange.residualValueOffer?.bids?.some((bid) => bid.regional);
    }

    private showResidualValueRequestComment(): void {
        if (this.report.valuation.residualValueOfferRemark) {
            this.residualValueRequestCommentShown = true;
        }
    }

    private focusResidualValueRequestComment(): void {
        setTimeout(() => {
            if (this.residualValueRequestComment) {
                this.residualValueRequestComment.quillInstance.focus();
            }
        }, 0);
    }

    /**
     * On valuation reports the residual value may be a tool to get the vehicle value.
     * This function sets the vehicle value to the value of the highest bid.
     */
    public setVehicleValueToHighestBid(origin: ResidualValueBid['origin']): void {
        if (this.report.type === 'valuation') {
            /**
             * If the owner of the vehicle does not deduct taxes, the vehicle value net is the gross value.
             */
            const mayDeductTaxes = mayCarOwnerDeductTaxes(this.report);
            const valueNet = mayDeductTaxes
                ? this.report.valuation.residualValue / 1.19
                : this.report.valuation.residualValue;

            this.setVehicleValue.emit({
                valueGross: this.report.valuation.residualValue,
                valueNet,
                valueType: 'residualValue',
                taxationType: mayDeductTaxes ? 'full' : 'neutral',
                exchangeName: origin,
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Residual Value Exchange
    /////////////////////////////////////////////////////////////////////////////*/

    public liesInThePast(date: string): boolean {
        return moment(date).isBefore(moment());
    }

    public getDateRelativeFromNow(date: string): string {
        return moment(date).fromNow();
    }

    public emitResidualValueChange(): void {
        this.residualValueChange.emit();
    }

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    public isAmendmentReport(): boolean {
        return !!this.report.originalReportId;
    }

    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        if (this.isReportLocked()) {
            return;
        }

        return this.reportDetailsService.patch(this.report, { waitForServer }).catch((error) => {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
            throw error;
        });
    }
}

export class ResidualValueExchangeForUi {
    constructor(template: Partial<ResidualValueExchangeForUi>) {
        Object.assign(this, template);
    }

    component: ResidualValueOverviewComponent;
    name: ResidualValueExchangeId | 'own';
    logoFileName: string;
    residualValueOffer: ResidualValueOffer;
    exportPending: boolean;
    importPending: boolean;
    areCredentialsComplete: () => boolean;
    isResidualValueOfferAllowed: () => boolean;
    createResidualValueOffer: () => void;
    connectResidualValueOffer: () => void;
    openResidualValueOffer: () => void;
    importBids: () => void;
    resetResidualValueOffer: () => void;
    deleteResidualValueOffer: () => void;
    downloadBidList: (options: { regional?: boolean }) => void;
    getTooltipForExportIcon: () => string;
    openConnectResidualValueOfferDialog: () => void;

    /**
     * Insurances have special quotas for residual value exchanges.
     * An assessor may use this quota if he has a contract with the insurance.
     * Usually for kasko reports.
     */
    get isDiscountForInsuranceAvailable(): boolean {
        if (this.name !== 'own') {
            return this.component.report.insurance.contactPerson.availableQuotaOnResidualValueExchanges?.includes(
                this.name,
            );
        }
    }
}

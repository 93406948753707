import { Report } from '../../models/reports/report';
import { fullTaxationRate, fullTaxationRateSecondHalfOf2020 } from '../../static-data/taxation-rates';

/**
 * The replacement value to compare repair costs with differs for private persons (gross value)
 * and companies (net value; amount depends on taxation type)
 */
export function getApplicableResidualValue(report: Report): number {
    // Claimant = private person
    if (!report.claimant.contactPerson.mayDeductTaxes) {
        return report.valuation.residualValue || 0;
    }
    // Claimant = company able to deduct taxes
    else {
        let taxRate: number;
        // Taxation for companies able to deduct taxes is always full taxation. But in Q3 & Q4 of 2020 we must apply 16% VAT rate.
        if (report.valuation.taxationType === 'full_16') {
            taxRate = fullTaxationRateSecondHalfOf2020;
        } else {
            taxRate = fullTaxationRate;
        }
        // Calculate the net value
        return (report.valuation.residualValue || 0) / (1 + taxRate);
    }
}

/**
 * Turns line breaks into spaces.
 * Helpful for displaying multi-line text (that contains line breaks "\n") inside
 * regular input fields that only display a single line of text.
 */
export function convertLineFeedsToSpaces(text: string): string {
    if (!text) {
        return text;
    }

    return text.replace(/\n/g, ' ');
}

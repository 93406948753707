import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subtask, Task } from '@autoixpert/models/tasks/task';

@Component({
    selector: 'task-done-checkbox',
    templateUrl: './task-done-checkbox.component.html',
    styleUrls: ['./task-done-checkbox.component.scss'],
})
export class TaskDoneCheckboxComponent<T extends Task | Subtask> {
    @Input() task: T;

    @Output() taskCompleted: EventEmitter<T> = new EventEmitter();
    @Output() taskReopened: EventEmitter<T> = new EventEmitter();

    protected toggleStatus() {
        if (this.task.isCompleted) {
            this.markAsNotDone();
        } else {
            this.markAsDone();
        }
    }

    protected markAsDone() {
        this.task.isCompleted = true;
        this.task.completedAt = new Date().toISOString();
        this.taskCompleted.emit(this.task);
    }

    protected markAsNotDone() {
        this.task.isCompleted = false;
        this.task.completedAt = undefined;
        this.taskReopened.emit(this.task);
    }
}

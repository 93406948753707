import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';
import { getDocumentsFromDocumentOrderByType } from './get-documents-from-document-order-by-type';
import { insertDocumentOrderItem } from './insert-document-order-item';

/**
 * Insert the document metadata object into report.documents and add its reference to the correct position in the document order.
 */
export function insertDocument(
    {
        newDocument,
        currentAndDefaultDocumentOrders,
        documents,
    }: {
        newDocument: DocumentMetadata;
        currentAndDefaultDocumentOrders: CurrentAndDefaultDocumentOrder[];
        /**
         * The unordered list of documents that exist in this report/invoice.
         */
        documents: DocumentMetadata[];
    },
    options: InsertDocumentOptions = {},
): void {
    //*****************************************************************************
    //  Document Metadata
    //****************************************************************************/
    const existingDocument = documents.find((existingDocument) => {
        /**
         * There may always be multiple manually uploaded PDFs like "terms & conditions" (German "AGBs" or "Allgemeine Geschäftsbedingungen")
         * or custom honorary agreements.
         *
         * Instead of by type alone, manually uploaded PDFs are identified by type and their uploaded document ID. Only the
         * combination makes them unique.
         */
        if (existingDocument.type === 'manuallyUploadedPdf') {
            return existingDocument.uploadedDocumentId === newDocument.uploadedDocumentId;
        }

        /**
         * Custom documents are identified by their custom document ID. Only the combination makes them unique.
         */
        if (existingDocument.type === 'customDocument') {
            return (
                existingDocument.customDocumentOrderConfigId &&
                existingDocument.customDocumentOrderConfigId === newDocument.customDocumentOrderConfigId
            );
        }

        return existingDocument.type === newDocument.type;
    });
    if (!existingDocument || options.allowMultiple) {
        /**
         * The documents array contains a list of all documents belonging to this report or invoice. These documents are referenced in the
         * document orders of the respective recipients to be sent via email or downloaded as PDF.
         */
        documents.push(newDocument);
    } else {
        console.log(
            `The document "${newDocument._id}" (type "${newDocument.type}") could not be added since it already exists and may not exist multiple times.`,
        );
        return;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Metadata
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Order Item
    //****************************************************************************/
    for (const { documentOrder, defaultDocumentOrder } of currentAndDefaultDocumentOrders) {
        const existingDocument = getDocumentsFromDocumentOrderByType({
            documents,
            documentOrder,
            documentType: newDocument.type,
            uploadedDocumentId: newDocument.uploadedDocumentId,
        })[0];

        if (!existingDocument || options.allowMultiple) {
            insertDocumentOrderItem({
                newDocument,
                documents,
                documentOrder,
                defaultDocumentOrder,
                insertAfterFallback: options.insertAfterFallback,
                includedInFullDocument: options.includedInFullDocument,
            });
        } else {
            console.log(`The given document (${newDocument.type}) exists already and will not be inserted again.`);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Order Item
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface InsertDocumentOptions {
    /**
     * Document type after which the document shall be inserted if position couldn't be retrieved from default
     */
    insertAfterFallback?: DocumentMetadata['type'];
    /**
     * Insert document even if a document with the same type already exists, e.g. 'expertStatement'.
     */
    allowMultiple?: boolean;
    /**
     * In case the team has not configured whether this document should be active or not in their default document order, this will be the fallback.
     */
    includedInFullDocument?: boolean;
}

export interface CurrentAndDefaultDocumentOrder {
    /**
     * The ordered list of references to each document. This exists in the report/invoice and once per recipient so that the lawyer may receive a different
     * order of documents than the claimant.
     */
    documentOrder: DocumentOrder;
    /**
     * This is a team configuration that defines how documents are arranged by default, so when they are inserted.
     */
    defaultDocumentOrder: DefaultDocumentOrder;
}

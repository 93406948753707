import { AxError } from '@autoixpert/models/errors/ax-error';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';
import {
    DefaultDocumentOrderGroup,
    DefaultDocumentOrderGroupType,
} from '@autoixpert/models/teams/default-document-order/default-document-order-group';
import { Team } from '@autoixpert/models/teams/team';

/**
 * This function extracts the correct default document order from the team preferences.
 *
 * The user may define default document orders for different report types and communication recipients.
 */
export function getDefaultDocumentOrderByTypeAndCommunicationRecipientRole({
    defaultDocumentOrderGroupType,
    communicationRecipientRole,
    team,
}: {
    defaultDocumentOrderGroupType: DefaultDocumentOrderGroupType;
    communicationRecipientRole: DefaultDocumentOrder['recipientRole'];
    team: Team;
}): DefaultDocumentOrder {
    //*****************************************************************************
    //  Document Order Group
    //****************************************************************************/
    let defaultDocumentOrderGroup: DefaultDocumentOrderGroup = team.preferences.defaultDocumentOrderGroups.find(
        (defaultDocumentOrderGroup) => defaultDocumentOrderGroup.types.includes(defaultDocumentOrderGroupType),
    );

    /**
     * If no report type matches explicitly, there's still the default configuration.
     */
    if (!defaultDocumentOrderGroup) {
        defaultDocumentOrderGroup = team.preferences.defaultDocumentOrderGroups.find((defaultDocumentOrderGroup) =>
            defaultDocumentOrderGroup.types.includes('reportDefault'),
        );
    }

    if (!defaultDocumentOrderGroup) {
        throw new AxError({
            code: 'DEFAULT_DOCUMENT_ORDER_GROUP_NOT_FOUND',
            message: `No document order group was found that matches the given report type.`,
            data: {
                type: defaultDocumentOrderGroupType,
                defaultDocumentOrderGroups: team.preferences.defaultDocumentOrderGroups,
            },
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Order Group
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Order
    //****************************************************************************/
    let defaultDocumentOrder: DefaultDocumentOrder = defaultDocumentOrderGroup.documentOrders.find(
        (defaultDocumentOrder) => defaultDocumentOrder.recipientRole === communicationRecipientRole,
    );

    /**
     * If no involved party role matches explicitly, there's still the default configuration.
     */
    if (!defaultDocumentOrder) {
        defaultDocumentOrder = defaultDocumentOrderGroup.documentOrders.find(
            (defaultDocumentOrderGroup) => defaultDocumentOrderGroup.recipientRole === 'default',
        );
    }

    if (!defaultDocumentOrder) {
        throw new AxError({
            code: 'DEFAULT_DOCUMENT_ORDER_NOT_FOUND',
            message: `No document order was found that matches the given involved party role.`,
            data: {
                type: defaultDocumentOrderGroupType,
                communicationRecipientRole: communicationRecipientRole,
                defaultDocumentOrderGroup,
            },
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Order
    /////////////////////////////////////////////////////////////////////////////*/

    return defaultDocumentOrder;
}

import { AudatexCalculationConfigCode } from '../../models/contacts/audatex-calculation-config-code';

export function isAudatexCalculationConfigCodeTransmittedAutomatically(
    numericalCode: AudatexCalculationConfigCode['numericalCode'],
) {
    // Check if this code is already transmitted by autoiXpert.
    const codesTransmittedByAutoixpert = [
        23, // Aufschlag Einzelpreis (Ersatzteilpreise/UPE-Aufschlag)
        27, // Kleinersatzteile pauschal
        28, // Kleinersatzteile von Summe Ersatzteile
        40, // Lackiermaterial von Lack-Lohn
        42, // Lackiermaterial Pauschalbetr. Teile
        68, // Fahrzeug-Verbringung
        81, // Lackmaterial pro Materialeinheit
    ];

    return codesTransmittedByAutoixpert.includes(numericalCode);
}

import { MatDialog } from '@angular/material/dialog';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';

export async function confirmInvoiceDeletion({ dialogService }: { dialogService: MatDialog }): Promise<boolean> {
    return dialogService
        .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
                heading: 'Rechnung wirklich löschen?',
                content:
                    'Das Löschen der Rechnung kann zu steuerlichen Nachteilen führen, weil das Ändern bereits versendeter Rechnungen die Ermittlung deines Gewinns und damit deine Steuerlast beeinflusst. Außerdem können Lücken in deinem Rechnungskreis entstehen.\n\nIn beiden Fällen behält sich das Finanzamt vor, Maßnahmen zur Ermittlung deiner tatsächlichen Steuerlast zu ergreifen, die eventuell nachteilhaft für dich sind.',
                confirmLabel: 'Weg damit!',
                cancelLabel: 'Doch lieber behalten.',
                confirmColorRed: true,
            },
            maxWidth: '700px',
        })
        .afterClosed()
        .toPromise();
}

import { ResidualValueOffer } from './residual-value-offer';

export class AutoonlineResidualValueOffer extends ResidualValueOffer {
    /**
     * This is set if exporting to AUTOonline took so much time that the autoiXpert API had to return an error to the
     * autoiXpert client before the autoiXpert nginx returned a Gateway Timeout error.
     * The client will then poll the server for the offer ID.
     */
    autoixpertApiTimeoutReceivedAt?: string = null;
    /**
     * The backend keeps polling the AUTOonline servers. This counter will be increased every time the client
     * is polling our own backend if the results have arrived from AUTOonline.
     * The counter increase will cause the report to be pushed to the server. If the report has been updated on the server
     *  (AUTOonline results are in) our sync pulls in those changes.
     */
    numberOfResultChecksAfterApiTimeout?: number = 0;
}

import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { Report } from '@autoixpert/models/reports/report';
import { User } from '@autoixpert/models/user/user';
import { isDatValuationInputDataComplete } from './is-dat-valuation-input-data-complete';
import { isDatValuationPossible } from './is-dat-valuation-possible';

export function getDatValuationExportTooltip({ report, user }: { report: Report; user: User }): string {
    if (!isDatUserComplete(user)) {
        return 'Die Zugangsdaten müssen erst in den Einstellungen eingegeben werden.';
    }

    if (report.state === 'done') {
        return 'Das Gutachten ist abgeschlossen';
    }

    if (!isDatValuationPossible(report)) {
        return 'Die DAT bietet für dieses Fahrzeug keine Bewertung an.';
    }

    if (!isDatValuationInputDataComplete(report)) {
        const missingInformation: string[] = [];

        if (!report.car.datIdentification.datEuropaCode) {
            missingInformation.push('DAT-€-Code');
        }
        if (!report.car.datIdentification.marketIndex) {
            missingInformation.push('DAT-Marktindex');
        }
        if (!report.car.firstRegistration) {
            missingInformation.push('Erstzulassung');
        }
        if (!(report.car.mileage || report.car.mileageMeter || report.car.mileageAsStated)) {
            missingInformation.push('Laufleistung');
        }

        let missingInformationText: string;
        if (missingInformation.length === 1) {
            missingInformationText = missingInformation[0];
        } else {
            missingInformationText = `${missingInformation.slice(0, -1).join(', ')} & ${
                missingInformation[missingInformation.length - 1]
            }`;
        }

        if (missingInformation.includes('DAT-€-Code') || missingInformation.includes('DAT-Marktindex')) {
            return `Bitte ${missingInformationText} angeben. DAT-Codes bekommst du durch eine VIN-Abfrage bei der DAT.`;
        }
        return `Bitte ${missingInformationText} angeben.`;
    }

    return 'Fahrzeugbewertung starten';
}

<div id="helpcenter-container" class="card card-with-padding">
    <div id="help-icon-container"><img src="/assets/images/icons/help_64.png" /></div>

    <h2>Wir sind hier, um zu helfen.</h2>

    <p class="label" *ngIf="isQapterixpert()">
        Support für Qapter-iXpert wird von unserem Partner
        <a href="https://www.autoixpert.de" target="_blank" rel="noopener">autoiXpert</a>
        erbracht.
    </p>

    <div id="contact-details-grid">
        <!--============================================-->
        <!-- Audatex Contact Info -->
        <!--============================================-->
        <div class="contact-details-container" *ngIf="user.audatexUser.username">
            <div class="contact-details-container">
                <div class="contact-details-logo-container">
                    <img src="/assets/images/logos/audatex.svg" alt="" />
                </div>
                <div class="contact-options-column">
                    <div class="semibold">Audatex/Qapter</div>
                    <div class="label margin-bottom-10">Kalkulation, Daten, Bewertung</div>
                    <div class="contact-option-container">
                        <a href="tel:+49 30 209691 399" class="contact-option-value" target="_blank" rel="”noopener”">
                            +49 30 209691 399
                        </a>
                    </div>
                    <div class="contact-option-container">
                        <a
                            href="mailto:svvs-hotline@ax-ao.de"
                            class="contact-option-value"
                            target="_blank"
                            rel="”noopener”">
                            svvs-hotline&#64;ax-ao.de
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Audatex Contact Info -->
        <!--============================================-->

        <!--============================================-->
        <!-- DAT contact details -->
        <!--============================================-->
        <div id="dat-contact-details-container" class="contact-details-container" *ngIf="user.datUser.customerNumber">
            <div class="contact-details-logo-container">
                <img id="dat-logo" src="/assets/images/logos/dat-logo-no-text.png" alt="" />
            </div>
            <div class="contact-options-column">
                <div class="semibold">DAT</div>
                <div class="label margin-bottom-10">Kalkulation, Daten, Bewertung</div>

                <!--********** Phone **********-->
                <div class="contact-option-container">
                    <a href="tel:+49 711 4503 130" class="contact-option-value" target="_blank" rel="”noopener”">
                        +49 711 4503 130
                    </a>
                </div>

                <!--********** Email **********-->
                <div class="contact-option-container">
                    <a href="mailto:kundendienst@dat.de" class="contact-option-value" target="_blank" rel="”noopener”">
                        kundendienst&#64;dat.de
                    </a>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END DAT contact details -->
        <!--============================================-->

        <!--============================================-->
        <!-- autoiXpert Contact Info -->
        <!--============================================-->
        <!--============================================-->
        <!-- AutoiXpert contact details -->
        <!--============================================-->
        <div class="contact-details-container">
            <div class="contact-details-logo-container">
                <img
                    src="/assets/images/logos/autoixpert-x.png"
                    alt=""
                    matTooltip="autoiXpert, Hersteller von {{ productName }}" />
            </div>
            <div class="contact-options-column">
                <!--============================================-->
                <!-- Outside office hours -->
                <!--============================================-->
                <div id="outside-office-hours" *ngIf="!isInsideOfficeHours()">
                    Leider befinden wir uns
                    <br />
                    <strong>außerhalb der Öffnungszeiten.</strong>
                    <div class="label">Mo - Fr von 9 - 17 Uhr</div>
                </div>
                <!--============================================-->
                <!-- END Outside office hours -->
                <!--============================================-->

                <!--============================================-->
                <!-- Inside Office Hours -->
                <!--============================================-->
                <div class="semibold">{{ productName }}</div>
                <div class="label margin-bottom-10">Bürosoftware</div>

                <!--********** Online Documentation **********-->
                <div class="contact-option-container">
                    <div class="label">Online-Doku & Videos</div>
                    <a href="https://wissen.autoixpert.de" target="_blank" rel="noopener">wissen.autoixpert.de</a>
                    <br />
                    <a href="https://www.youtube.com/c/autoiXpert" target="_blank" rel="noopener">YouTube-Kanal</a>
                </div>

                <!--********** Phone **********-->
                <ng-container *ngIf="isInsideOfficeHours()">
                    <div class="contact-option-container">
                        <a href="tel:+4982229188990" class="contact-option-value" target="_blank" rel="”noopener”">
                            +49 8222 91 88 99 0
                        </a>
                    </div>
                </ng-container>

                <!--********** Email **********-->
                <div class="contact-option-container">
                    <a href="mailto:hilfe@autoixpert.de" class="contact-option-value" target="_blank" rel="”noopener”">
                        hilfe&#64;autoixpert.de
                    </a>
                </div>
                <!--============================================-->
                <!-- END Inside Office Hours -->
                <!--============================================-->
            </div>

            <!--********** AnyDesk **********-->
            <div id="anydesk-container">
                <h3>Vorbereitung</h3>
                <p>
                    Bitte starte bei Fragen zur Software
                    <br />
                    stets AnyDesk.
                </p>
                <a id="anydesk-download-container" [href]="getAnydeskLink()" target="_blank" rel="”noopener”">
                    <img id="anydesk-logo" src="/assets/images/logos/anydesk-logo-invertiert.svg" alt="" />
                </a>
            </div>
        </div>
        <!--============================================-->
        <!-- END AutoiXpert contact details -->
        <!--============================================-->
    </div>

    <!--============================================-->
    <!-- END autoiXpert Contact Info -->
    <!--============================================-->
</div>

import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Notification } from 'angular2-notifications';
import { merge, omit } from 'lodash-es';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import {
    doesVehicleHaveBatteryElectricEngine,
    doesVehicleHaveInternalCombustionEngine,
    doesVehicleShapeHaveEngine,
} from '@autoixpert/lib/car/get-engine-type';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { areDatesEqual } from '@autoixpert/lib/dates/are-dates-equal';
import { round } from '@autoixpert/lib/numbers/round';
import { Translator } from '@autoixpert/lib/placeholder-values/translator';
import { getPowerSources } from '@autoixpert/lib/placeholder-values/utility-functions';
import { defaultCustomCarPropertiesForBicycles } from '@autoixpert/lib/reports/default-custom-car-properties-for-bicycles';
import { getAllTiresOfVehicle } from '@autoixpert/lib/tires/get-all-tires-of-vehicle';
import { getAllTiresOnAxle } from '@autoixpert/lib/tires/get-all-tires-on-axle';
import { setTireAxle } from '@autoixpert/lib/tires/set-tire-axle';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { isGtmotiveUserComplete } from '@autoixpert/lib/users/is-gtmotive-user-complete';
import { Axle } from '@autoixpert/models/reports/car-identification/axle';
import { Car, CarShape } from '@autoixpert/models/reports/car-identification/car';
import { CarEquipment, CarEquipmentPosition } from '@autoixpert/models/reports/car-identification/car-equipment';
import { CustomCarProperty } from '@autoixpert/models/reports/car-identification/custom-car-property';
import { BaseModel, Manufacturer, SubModel } from '@autoixpert/models/reports/car-identification/dat-models';
import { EngineType } from '@autoixpert/models/reports/car-identification/engine-type';
import { SecondTire, Tire } from '@autoixpert/models/reports/car-identification/tire';
import { AudatexValuation } from '@autoixpert/models/reports/market-value/audatex-valuation';
import { OldtimerValuationGrades } from '@autoixpert/models/reports/market-value/oldimter-valuation-grades';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { DatDamageCalculationService } from 'src/app/shared/services/dat-damage-calculation.service';
import { DatValuationService } from 'src/app/shared/services/dat-valuation.service';
import { DatVehicleIdentificationService } from 'src/app/shared/services/dat-vehicle-identification.service';
import { fadeInAndSlideAnimation } from '../../../shared/animations/fade-in-and-slide.animation';
import { slideInAndOutHorizontally } from '../../../shared/animations/slide-in-and-out-horizontally.animation';
import { isBicycleOrPedelec } from '../../../shared/libraries/car/is-bicycle-or-pedelec';
import { isUtilityVehicle } from '../../../shared/libraries/car/is-utility-vehicle';
import { getAudatexErrorHandlers } from '../../../shared/libraries/error-handlers/get-audatex-error-handlers';
import { getDatErrorHandlers } from '../../../shared/libraries/error-handlers/get-dat-error-handlers';
import { getGtmotiveErrorHandlers } from '../../../shared/libraries/error-handlers/get-gtmotive-error-handlers';
import { getIdentificationProviderName } from '../../../shared/libraries/get-calculation-provider-name';
import { isSmallScreen } from '../../../shared/libraries/is-small-screen';
import { triggerClickEventOnSpaceBarPress } from '../../../shared/libraries/trigger-click-event-on-space-bar-press';
import { hasAccessRight } from '../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { AudatexKbaCodeResult, AudatexKbaCodeService } from '../../../shared/services/audatex/audatex-kba-code.service';
import { AudatexTaskService } from '../../../shared/services/audatex/audatex-task.service';
import { AudatexVinResult } from '../../../shared/services/audatex/audatex-vin.service';
import { CarEquipmentService } from '../../../shared/services/car-equipment.service';
import { DatAuthenticationService } from '../../../shared/services/dat-authentication.service';
import { GtmotiveEstimateService } from '../../../shared/services/gtmotive/gtmotive-estimate.service';
import { GtmotiveVinResult } from '../../../shared/services/gtmotive/gtmotive-vin.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { ManufacturerService } from '../../../shared/services/manufacturer.service';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { NewWindowService } from '../../../shared/services/new-window.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { TeamService } from '../../../shared/services/team.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { date2ConstructionTime } from './car-selector/car-selector.component';

@Component({
    selector: 'car-data',
    templateUrl: 'car-data.component.html',
    styleUrls: ['car-data.component.scss'],
    animations: [slideInAndOutHorizontally(), fadeInAndSlideAnimation()],
})
export class CarDataComponent implements OnInit, OnDestroy {
    protected Number = Number;

    constructor(
        private route: ActivatedRoute,
        private manufacturerService: ManufacturerService,
        private httpClient: HttpClient,
        private reportDetailsService: ReportDetailsService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private carEquipmentService: CarEquipmentService,
        private loggedInUserService: LoggedInUserService,
        private datAuthenticationService: DatAuthenticationService,
        private datVehicleIdentificationService: DatVehicleIdentificationService,
        private datDamageCalculationService: DatDamageCalculationService,
        private datValuationService: DatValuationService,
        private teamService: TeamService,
        private newWindowService: NewWindowService,
        private audatexKbaCodeService: AudatexKbaCodeService,
        private gtmotiveEstimateService: GtmotiveEstimateService,
        public userPreferences: UserPreferencesService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        private networkStatusService: NetworkStatusService,
        public ngZone: NgZone,
        public changeDetectorRef: ChangeDetectorRef,
        public audatexTaskService: AudatexTaskService,
    ) {}

    //*****************************************************************************
    //  Properties
    //****************************************************************************/
    // Internal properties
    public reportId: string;
    public report: Report;
    public user: User;
    public team: Team;
    private subscriptions: Subscription[] = [];
    private closeToastsOnDestroy: Notification[] = [];

    // Basic Data
    datECodeRequestPending = false;
    datDossierRequestPending = false;
    kbaCodeRequestPending = false;
    kbaErrorMessage: string;
    audatexCodePending: boolean = false;
    gtmotiveUmcImportPending: boolean = false;
    // A means to clear the VIN input's error messages from this component.
    public vinErrorMessageClearer$ = new Subject<void>();

    manufacturers: Manufacturer[] = [];
    filteredManufacturers: string[] = [];
    identificationResponsesPerVehicle: DatIdentificationResponsePerVehicle[] = [];
    baseModels: BaseModel[] = [];
    filteredBaseModels: BaseModel[] = [];
    subModels: SubModel[] = [];
    filteredSubModels: SubModel[] = [];
    performanceHP: number;
    transmissionTypes: string[] = [
        // These two are relevant for electric vehicles. Source: https://www.carwow.de/ratgeber/elektroauto/elektroauto-getriebe-ein-gang-reicht-meistens#gref
        '1-Gang',
        '2-Gang automatisch',

        '3-Gang automatisch',
        '4-Gang automatisch',
        '5-Gang automatisch',
        '6-Gang automatisch',
        '7-Gang automatisch',
        '8-Gang automatisch',
        '9-Gang automatisch',
        '10-Gang automatisch',
        'stufenlos automatisch', // This converts to 0 gears automatic.
        '3-Gang manuell',
        '4-Gang manuell',
        '5-Gang manuell',
        '6-Gang manuell',
        '7-Gang manuell',
        // We need the number of gears, so omit these rare transmission types
        // "Doppelkupplungsgetriebe",
        // "Planetengetriebe"
    ];
    filteredTransmissionTypes: string[] = this.transmissionTypes.slice(); // Field for autocomplete

    public selectedAdvancedCarDataTab: 'advancedCarData' | 'equipments' = 'advancedCarData';

    // Advanced Car Data (right column)
    showInputForCustomEngineType = false;
    // IMPORTANT: Keep the ids capitalized. They are used in the toggleEngineType() method to toggle a property.
    engineTypes: EngineType[] = [
        {
            id: 'Gasoline',
            title: 'Benzin',
            image: 'gasoline_48.png',
        },
        {
            id: 'Diesel',
            title: 'Diesel',
            image: 'diesel_48.png',
        },
        {
            id: 'Electricity',
            title: 'Elektro',
            image: 'electric_48.png',
        },
        {
            id: 'LPG',
            title: 'Autogas',
            image: 'autogas_48.png',
        },
        {
            id: 'NaturalGasoline',
            title: 'Erdgas',
            image: 'methane_48.png',
        },
        {
            id: 'Biodiesel',
            title: 'Bio-Diesel',
            image: 'biodiesel_48.png',
        },
        {
            id: 'Hydrogen',
            title: 'Wasserstoff',
            image: 'hydrogen_48.png',
        },
    ];

    inputForCustomCarShapeShown = false;
    carShapesForUI: CarShapeForUI[] = [
        {
            id: 'sedan',
            title: 'Limousine',
            image: 'sedan_64x32.png',
        },
        {
            id: 'compact',
            title: 'Kompaktwagen',
            image: 'compact_64x32.png',
        },
        {
            id: 'coupe',
            title: 'Coupé',
            image: 'coupe_64x32.png',
        },
        {
            id: 'stationWagon',
            title: 'Kombi',
            image: 'station-wagon_64x32.png',
        },
        {
            id: 'suv',
            title: 'SUV',
            image: 'suv_64x32.png',
        },
    ];
    carShapesForSubmenuToplevel: CarShapeForUI[] = [
        {
            id: 'convertible',
            title: 'Cabriolet',
            image: 'convertible_64x32.png',
        },
        {
            id: 'van',
            title: 'Van',
            image: 'van_64x32.png',
        },
        {
            id: 'transporter',
            title: 'Transporter',
            image: 'transporter_64x32.png',
        },
        {
            id: 'pickup',
            title: 'Pick-Up',
            image: 'pickup_64x32.png',
        },
        {
            id: 'trailer',
            title: 'Anhänger',
            image: 'trailer_64x32.png',
        },
    ];

    public carShapesForSubmenuTrucks: CarShapeForUI[] = [
        {
            id: 'truck',
            title: 'LKW mit Aufbau',
            image: 'truck_64x32.png',
        },
        {
            id: 'semiTruck',
            title: 'Sattelzugmaschine',
            image: 'semi-truck_64x32.png',
        },
        {
            id: 'semiTrailer',
            title: 'Sattelauflieger',
            image: 'semi-trailer_64x32.png',
        },
        {
            id: 'bus',
            title: 'Bus',
            image: 'bus_64x32.png',
        },
    ];

    public carShapesForSubmenuBikes: CarShapeForUI[] = [
        // Motorcycles are the most important bike type for our assessors.
        {
            id: 'motorcycle',
            title: 'Motorrad',
            image: 'motorcycle_64x32.png',
        },
        {
            id: 'bicycle',
            title: 'Fahrrad',
            image: 'bicycle.png',
        },
        {
            id: 'pedelec',
            title: 'Pedelec',
            image: 'pedelec.png',

            // Source: https://www.kba.de/DE/Service/FAQs/Typgenehmigung/Allgemein/Functions/faq_table.html, 28.03.2024
            tooltip: `Ein Pedelec hat eine elektrische Tretunterstützung mit einer größten Nenndauerleistung von 250 Watt. Die Tretunterstützung endet, wenn eine Geschwindigkeit von mehr als 25 km/h erreicht wird. (Die elektrische Unterstützung kann auch als Anfahrhilfe funktionieren – bis zu einer Geschwindigkeit von 6 km/h.)
            Quelle: Kraftfahrtbundesamt, 2024`,
        },
        {
            id: 'e-bike',
            title: 'E-Bike',
            image: 'e-bike.png',

            // Source: https://www.kba.de/DE/Service/FAQs/Typgenehmigung/Allgemein/Functions/faq_table.html, 28.03.2024
            tooltip: `Ein E-Bike hat einen elektrischen Antrieb mit einer Leistung von über 250 bis maximal 1000 Watt. Um den elektrischen Antrieb zu nutzen, ist eine Tretunterstützung nicht zwingend erforderlich – ein E-Bike wird auch ganz ohne Pedalkraft angetrieben.
            Quelle: Kraftfahrtbundesamt, 2024`,
        },
    ];

    public carShapesForSubmenuCamping: CarShapeForUI[] = [
        {
            id: 'motorHome',
            title: 'Wohnmobil',
            image: 'motor-home_64x32.png',
        },
        {
            id: 'caravanTrailer',
            title: 'Wohnanhänger',
            image: 'caravan-trailer_64x32.png',
        },
    ];

    // Why set a number for each array element? They serve as each seat's ID. You could take the index
    // plus one too, but that would be extra calculations to do in the template.
    doors: number[] = [0, 1, 2, 3, 4, 5, 6];
    seats: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    showInputForCustomNumberOfAxles = false;
    showInputForCustomNumberOfPoweredAxles = false;
    showInputForCustomNumberOfDoors = false;
    showInputForCustomNumberOfSeats = false;
    // Keep the 1 in the axles array to select "Only one axle driven"
    axles: number[] = [1, 2, 3, 4, 5];
    poweredAxles: number[] = [0, 1, 2, 3, 4, 5];

    // We copy the nex axle configuration during VIN Import to allow the customer to manually import these
    newImportedAxles: Car['axles'] = null;

    // Equipment
    carEquipment: CarEquipment;

    get isBicycleOrPedelec() {
        return isBicycleOrPedelec(this.report.car);
    }

    /**
     * Electric bikes have an electric motor but no high voltage battery.
     * A high voltage battery is the most critical part of electric cars and therefore, if a car runs on electricity,
     * we display inputs like highVoltageCheck or battery status. These are not relevant for bikes with a small and cheap battery.
     *
     * Bikes with electric motor do not have the property `runsOnElectricity` set to true since they do not need all the special functions for electric cars.
     * We only display some specific inputs (like e.g. capacity) for bikes with electric motor.
     */
    get isBikeWithElectricMotor() {
        return this.report.car.shape === 'e-bike' || this.report.car.shape === 'pedelec';
    }

    get displayAxles(): boolean {
        const excludedCarShapes: CarShape[] = ['bicycle', 'e-bike', 'pedelec', 'motorcycle'];
        return !excludedCarShapes.includes(this.report.car.shape);
    }
    get displayDoors(): boolean {
        const excludedCarShapes: CarShape[] = ['bicycle', 'e-bike', 'pedelec', 'motorcycle'];
        return !excludedCarShapes.includes(this.report.car.shape);
    }

    public parseGearboxModelName() {
        const car = this.report.car;

        const gearboxTypeMapping = {
            manuell: 'manual',
            manuel: 'manual',
            automatisch: 'automatic',
            automatik: 'automatic',
            // We make the key lowercase before mapping it to this object, so "Getriebeart" is lowercase here.
            'andere getriebeart (nicht manuell, nicht automatisch)': 'other',
        };
        // value is e.g. "5-Gang automatisch". Match "5" and "automatisch"
        const matches = /(\d)-\w*\s(\w+)/.exec(this.report.car.gearboxModelName);
        if (matches) {
            // + casts the string to a number
            car.numberOfGears = +matches[1];
            car.gearboxType = gearboxTypeMapping[matches[2].toLocaleLowerCase()];
        }
        // Stepless transmissions do not have any gears. They are automatic.
        else if (this.report.car.gearboxModelName.includes('stufenlos')) {
            car.numberOfGears = 0;
            car.gearboxType = 'automatic';
        }
        // If the vehicle has only one gear (so neither automatic, nor manual) use type "other"
        else if (this.report.car.gearboxModelName.startsWith('1')) {
            car.numberOfGears = 1;
            car.gearboxType = 'other';
        }
        // If the format is weird try to derive the number of gears, if empty return null
        else {
            car.numberOfGears = parseInt(this.report.car.gearboxModelName) || null;
            car.gearboxType = this.report.car.gearboxModelName ? 'other' : null;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Properties
    /////////////////////////////////////////////////////////////////////////////*/

    // Error messages to display in the view
    error = {
        vin: '',
        datECode: '',
    };
    warning = {
        vin: '',
        datECode: '',
    };

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        this.extractRouteParameter();
        const reportObservable = this.reportDetailsService.get(this.reportId);

        const reportSubscription = reportObservable.subscribe(async (report) => {
            this.report = report;
            this.populateShorthandProperties(report);
            this.calculateHP();
            this.showCustomTypeInputs(report);
            this.loadCarEquipment();
            this.joinAsRealtimeEditor();

            this.manufacturers = await this.manufacturerService.find().toPromise();

            // Filter autocomplete suggestions on initialization
            this.filterManufacturers(this.report.car.make);

            // If the manufacturer has been properly set, set the autocomplete entries for the baseModels and subModels
            if (this.manufacturers) {
                this.selectBaseModels(this.report.car.make);
                this.filterCarModels(this.report.car.model);
                this.selectSubModels(this.report.car.make, this.report.car.model);
                this.filterSubModels(this.report.car.submodel);
            }

            this.validateKbaFormat();
        });

        this.subscriptions.push(
            reportSubscription,
            // Update the team & user in case they were updated in a different tab.
            this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)),
            this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)),
            this.audatexTaskService.isRequestPending$$.subscribe((pending) => (this.audatexCodePending = pending)),
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    private extractRouteParameter() {
        const routeSubscription = this.route.parent.params.subscribe((params) => {
            this.reportId = params['reportId'];
        });

        this.subscriptions.push(routeSubscription);
    }

    private populateShorthandProperties(report: Report) {
        this.report.car = report.car;
    }

    /**
     * Based on the report's data, check if there is info entered into the custom fields.
     * If so, show them.
     *
     * @param report
     */
    public showCustomTypeInputs(report: Report): void {
        // Engine Type
        if (report.car.runsOnSomethingElse) {
            this.showInputForCustomEngineType = true;
        }

        // Car Shape - Unhide car shape from submenu if the report's carShape would be hidden there by default.
        this.addSelectedCarShapeToUI(report);

        if (report.car.customShapeLabel) {
            this.showInputForCustomCarShapeLabel();
        }

        // Number of axles
        if (report.car.numberOfAxles > this.axles[this.axles.length - 1]) {
            this.showInputForCustomNumberOfAxles = true;
        }

        // Number of powered axles
        if (report.car.numberOfPoweredAxles > this.axles[this.axles.length - 1]) {
            this.showInputForCustomNumberOfPoweredAxles = true;
        }

        // Number of doors
        if (report.car.numberOfDoors > this.doors[this.doors.length - 1]) {
            this.showInputForCustomNumberOfDoors = true;
        }

        // Number of seats
        if (report.car.numberOfSeats > this.seats[this.seats.length - 1]) {
            this.showInputForCustomNumberOfSeats = true;
        }
    }

    private async loadCarEquipment() {
        try {
            this.carEquipment = (await this.carEquipmentService.find({ reportId: this.report?._id }).toPromise())?.[0];
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Ausstattungen konnten nicht geladen werden.',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                },
            });
        }
    }

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    //*****************************************************************************
    //  Basic Data
    //****************************************************************************/

    //*****************************************************************************
    //  Vehicle Identification Providers
    //****************************************************************************/
    public isIdentificationProviderSelectionVisible(): boolean {
        const availableProviders: boolean[] = [
            this.isDatVinProviderAvailable(),
            this.isAudatexVinProviderAvailable(),
            this.isGtmotiveVinProviderAvailable(),
        ];
        // Multiple providers available
        if (availableProviders.filter(Boolean).length >= 2) {
            return true;
        }

        // Flag on report is set to a provider that has previously been available.
        if (this.report.car.identificationProvider === 'dat' && !this.isDatVinProviderAvailable()) {
            return true;
        }
        if (this.report.car.identificationProvider === 'audatex' && !this.isAudatexVinProviderAvailable()) {
            return true;
        }
        if (this.report.car.identificationProvider === 'gtmotive' && !this.isGtmotiveVinProviderAvailable()) {
            return true;
        }
    }

    public isDatVinProviderAvailable(): boolean {
        return !!isDatUserComplete(this.user);
    }

    public isAudatexVinProviderAvailable(): boolean {
        return !!isAudatexUserComplete(this.user);
    }

    public isGtmotiveVinProviderAvailable(): boolean {
        return !!isGtmotiveUserComplete(this.user);
    }

    public selectIdentificationProvider(provider: Car['identificationProvider']) {
        if (this.isReportLocked()) return;

        this.report.car.identificationProvider = provider;
    }

    public rememberDefaultVehicleIdentificationProvider(provider: Car['identificationProvider']) {
        this.userPreferences.vehicleIdentificationProvider = provider;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Vehicle Identification Providers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle VIN responses
    //****************************************************************************/
    public handleVinResponses(
        vinResponsesPerVehicle: (DatIdentificationResponsePerVehicle | AudatexVinResult | GtmotiveVinResult)[],
    ): void {
        const vinErrors: { title: string; body: string }[] = [];

        // If the result is an array of car objects, the VIN response contained multiple cars (non-unique VIN response). The user
        // must choose which car the report describes. DAT only.
        if (vinResponsesPerVehicle.length > 1) {
            this.identificationResponsesPerVehicle = vinResponsesPerVehicle as DatIdentificationResponsePerVehicle[];
        } else {
            vinErrors.push(
                ...(this.mergeCar(vinResponsesPerVehicle[0].newCarInformation, true, true)?.vinErrors ?? []),
            );
            this.saveEquipment(vinResponsesPerVehicle[0].carEquipment);

            // Only Audatex transmits original vehicle price data in their VIN response.
            if ('valuation' in vinResponsesPerVehicle[0]) {
                this.mergeAudatexValuation(vinResponsesPerVehicle[0].valuation);
            }

            const identificationProvider: string = getIdentificationProviderName(
                this.report.car.identificationProvider,
            );
            /**
             * Let the user know about incomplete data.
             */
            if (!vinResponsesPerVehicle[0].carEquipment?.equipmentPositions?.length) {
                vinErrors.push({
                    title: 'Keine Ausstattung',
                    body: `${
                        identificationProvider === 'DAT' ? 'Die DAT' : identificationProvider
                    } kennt für dieses Fahrzeug keine Ausstattungen. Ergänze die Ausstattungen manuell.`,
                });
            }

            const powerSources: string = getPowerSources(vinResponsesPerVehicle[0].newCarInformation);
            if (
                !vinResponsesPerVehicle[0].newCarInformation ||
                (!powerSources &&
                    !vinResponsesPerVehicle[0].newCarInformation.performanceKW &&
                    !vinResponsesPerVehicle[0].newCarInformation.engineDisplacement &&
                    !vinResponsesPerVehicle[0].newCarInformation.numberOfAxles)
            ) {
                vinErrors.push({
                    title: 'Fehlende technische Daten',
                    body: `${
                        identificationProvider === 'DAT' ? 'Die DAT' : identificationProvider
                    } kennt für dieses Fahrzeug keine oder nur unvollständige technische Daten. Ergänze die Daten manuell.`,
                });
            }
        }

        if (vinErrors.length === 1) {
            this.toastService.info(
                vinErrors[0].title,
                vinErrors[0].body,
                // Show this important info for an unlimited time to the user so that they can read it and manually dismiss the toast.
                { clickToClose: true, timeOut: undefined },
            );
        } else if (vinErrors.length > 1) {
            // Merge the error messages into one string. Save toast so we can close them onDestroy.
            this.closeToastsOnDestroy.push(
                this.toastService.info(
                    'Fehler bei der VIN-Abfrage',
                    vinErrors
                        .map(
                            (error, index) =>
                                `<p style="margin-top: -${index === 0 ? 0 : 20}px"><strong>${error.title}</strong>: ${
                                    error.body
                                }</p>`,
                        )
                        .join('\n'),
                    // Show this important info for an unlimited time to the user so that they can read it and manually dismiss the toast.
                    { clickToClose: true, timeOut: undefined },
                ),
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle VIN responses
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Audatex Code
    //****************************************************************************/
    public getAudatexCodeOnEnter(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.openAudatexCarSelector();
        }
    }

    public async openAudatexCarSelector() {
        /**
         * Create Audatex task if necessary. Then open calculation.
         */
        if (!this.report.audatexTaskId) {
            try {
                await this.audatexTaskService.create({ report: this.report });
            } catch (error) {
                this.audatexTaskService.handleAndRethrow({
                    axError: error,
                    report: this.report,
                    defaultHandler: {
                        title: 'Audatex-Vorgang nicht angelegt',
                        body: 'Der Audatex-Vorgang konnte aus einem unbekannten Grund nicht angelegt werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }
        }
        this.audatexTaskService.openUI({ report: this.report, step: 'VehicleIdentification' });
    }

    public async importAudatexIdentification() {
        try {
            const response = await this.audatexTaskService.loadTask({ report: this.report });
            this.mergeCar(response.car);
            this.mergeAudatexValuation(response.valuation);
            this.saveEquipment(response.carEquipment);

            // Clear error message within VIN input.
            this.vinErrorMessageClearer$.next();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getAudatexErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Audatex-Import nicht möglich',
                    body: 'Bitte versuche, das Fahrzeug erneut in der Audatex-Oberfläche auszuwählen. Tritt der Fehler erneut auf, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex Code
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  GT Motive Unique Model Code (UMC)
    //****************************************************************************/
    public async openGtmotiveCarSelector() {
        if (!this.report.gtmotiveEstimateId) {
            this.toastService.warn('GT-Motive-Vorgang fehlt', 'Bitte führe erst eine VIN-Abfrage durch.');
            return;
        }

        this.gtmotiveUmcImportPending = true;
        try {
            // In order to get a URL to open the GT Motive estimate, we must execute an update. That's how GT Motive works.
            const response = await this.gtmotiveEstimateService.patch(
                this.report._id,
                this.report.gtmotiveEstimateId,
                true,
            );
            this.gtmotiveUmcImportPending = false;
            this.newWindowService.open(response.gtmotiveUserInterfaceUrl);
        } catch (error) {
            this.gtmotiveUmcImportPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGtmotiveErrorHandlers(),
                },
                defaultHandler: {
                    title: 'GT Motive Vorgang nicht zu öffnen',
                    body: 'Der GT Motive Vorgang konnte nicht geöffnet werden. Bitte versuche es erneut. Sollte der Fehler bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a><div class=""></div>',
                },
            });
        }
    }

    public async importGtmotiveIdentification() {
        this.gtmotiveUmcImportPending = true;

        try {
            const response = await this.gtmotiveEstimateService.find(this.report._id);
            this.gtmotiveUmcImportPending = false;
            this.mergeCar(response.car);
            this.saveEquipment(response.carEquipment);
        } catch (error) {
            this.gtmotiveUmcImportPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGtmotiveErrorHandlers(),
                },
                defaultHandler: {
                    title: 'GT-Motive-Import nicht möglich',
                    body: 'Bitte versuche, das Fahrzeug erneut in der GT-Motive-Oberfläche auszuwählen. Tritt der Fehler erneut auf, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END GT Motive Unique Model Code (UMC)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Open DAT UI for vehicle identification
    //****************************************************************************/
    /**
     * Allow the identification of a vehicle via the DAT Damage Calculation UI.
     * Create a new Damage Calculation dossier and open the UI.
     */
    public async createAndOpenDatDamageCalculation() {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die DAT-Kalkulation ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }
        this.datDossierRequestPending = true;
        const infoToast = this.toastService.info('DAT-Vorgang erstellen', 'Dies kann einige Sekunden dauern.', {
            timeOut: 10_000,
        });

        try {
            await this.datDamageCalculationService.create({ report: this.report });
        } catch (error) {
            // Close the info toast from above
            this.toastService.remove(infoToast.id);
            this.datDossierRequestPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDatErrorHandlers(),
                },
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body:
                        `Die Schadenskalkulation konnte nicht erstellt werden.` +
                        (error.data && error.data.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                    partnerLogo: 'dat',
                }),
            });
        }

        // Update UI
        this.datDossierRequestPending = false;
        this.toastService.remove(infoToast.id); // Close the info toast from above

        this.datDamageCalculationService.openDamageCalculationUI({
            report: this.report,
            triggeringComponent: this,
            onFinish: () => this.getVehicleFromDatDamageCalculation(),
            options: {
                onlyIdentification: true,
            },
        });
    }

    public async createAndOpenDatValuation() {
        this.datDossierRequestPending = true;
        const datValuationResponse = await this.datValuationService.create({ report: this.report });

        this.datValuationService.openValuationUI({
            report: this.report,
            triggeringComponent: this,
            onFinish: () => this.getVehicleFromDatValuation(),
            options: {
                onlyIdentification: true,
            },
        });

        return datValuationResponse;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Open DAT UI for vehicle identification
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Get Vehicle from DAT Dossier
    //****************************************************************************/
    /**
     * Import vehicle from DAT Damage Calculation.
     */
    public async getVehicleFromDatDamageCalculation() {
        if (!this.report.damageCalculation?.repair?.datCalculation) {
            this.toastService.info(
                'Noch keine DAT-Kalkulation',
                'Erstelle oder verknüpfe eine DAT-Kalkulation, um die Fahrzeugdaten zu importieren.',
            );
            return;
        }

        // Reset VIN error
        this.error.datECode = '';
        this.warning.datECode = '';

        // Since DAT Calculation can only be fetched when online, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'DAT Kalkulation ist abrufbar, sobald du wieder online bist.',
            );
            return;
        }

        const datDossierId = this.report.damageCalculation?.repair?.datCalculation.dossierId;

        /**
         * Get the vehicle from the linked DAT Dossier.
         */
        if (datDossierId) {
            this.datDossierRequestPending = true;
            try {
                const data = await this.datVehicleIdentificationService.getVehicleFromDamageCalculation(datDossierId);
                this.mergeCar(data.newCarInformation);
                this.saveEquipment(data.carEquipment);
            } catch (error) {
                this.toastService.error(
                    'Import fehlgeschlagen',
                    'Die Fahrzeugdaten konnten nicht aus der DAT-Kalkulation gelesen werden.<br><br>Bitte versuche es erneut. Sollte der Fehler bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
            } finally {
                this.datDossierRequestPending = false;
            }
        }
    }

    /**
     * Import vehicle from DAT Valuation.
     */
    public async getVehicleFromDatValuation() {
        if (!this.report.valuation?.datValuation?.dossierId) {
            this.toastService.info(
                'Noch keine DAT-Bewertung',
                'Erstelle oder verknüpfe eine DAT-Bewertung, um die Fahrzeugdaten zu importieren.',
            );
            return;
        }
        // Reset VIN error
        this.error.datECode = '';
        this.warning.datECode = '';

        // Since DAT Calculation can only be fetched when online, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'DAT Kalkulation ist abrufbar, sobald du wieder online bist.',
            );
            return;
        }

        const datDossierId = this.report.valuation?.datValuation?.dossierId;

        /**
         * Get the vehicle from the linked DAT Dossier.
         */
        if (datDossierId) {
            this.datDossierRequestPending = true;
            try {
                const data = await this.datVehicleIdentificationService.getVehicleFromValuation(datDossierId);

                /**
                 * The DAT valuation dossier always sets the field "VxsVehicle.ReleaseIndicator" to "APPRAISAL", so it always considers
                 * the DAT calculation to be impossible although a calculation may be possible, indeed. Reset this field to null to prevent
                 * the calculation-impossible indicator.
                 */
                data.newCarInformation.datIdentification.isDamageCalculationPossible = null;

                this.mergeCar(data.newCarInformation);
                this.saveEquipment(data.carEquipment);
            } catch (error) {
                this.toastService.error(
                    'Import fehlgeschlagen',
                    'Die Fahrzeugdaten konnten nicht aus der DAT-Bewertung gelesen werden.<br><br>Bitte versuche es erneut. Sollte der Fehler bestehen bleiben, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
            } finally {
                this.datDossierRequestPending = false;
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get Vehicle from DAT Dossier
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Get DAT€Code Data
    //****************************************************************************/
    /**
     * Start request to get the data regarding the VIN from the DAT servers
     */
    public async getDatECodeData(): Promise<void> {
        // Reset VIN error
        this.error.datECode = '';
        this.warning.datECode = '';

        // Show errors if the DAT€Code has a wrong format and abort.
        if (!this.validateDatECodeFormat()) {
            return;
        }

        // Since DAT€Codes can only be fetched when online, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'DAT€Codes sind abrufbar, sobald du wieder online bist.',
            );
            return;
        }

        this.datECodeRequestPending = true;
        const data = await this.fetchDatECodeDataFromDAT({
            datECode: this.report.car.datIdentification.datEuropaCode,
            datConstructionTime: this.report.car.productionYear
                ? '' + date2ConstructionTime(this.report.car.productionYear)
                : '',
            datMarketIndex: this.report.car.datIdentification.marketIndex || '',
        });
        this.datECodeRequestPending = false;
        this.mergeCar(data.newCarInformation);
    }

    private async fetchDatECodeDataFromDAT({
        datECode,
        datConstructionTime,
        datMarketIndex,
        specialEquipmentManufacturerCodes,
    }: {
        datECode: Car['datIdentification']['datEuropaCode'];
        datConstructionTime: string;
        datMarketIndex: string;
        specialEquipmentManufacturerCodes?: string[];
    }): Promise<DatIdentificationResponsePerVehicle> {
        try {
            const datJwt = await this.datAuthenticationService.getJwt();
            return this.httpClient
                .get<DatIdentificationResponsePerVehicle>('/api/v0/dat/datECode', {
                    params: {
                        datECode,
                        datConstructionTime,
                        datMarketIndex,
                        specialEquipmentManufacturerCodes,
                    },
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt as string),
                })
                .toPromise();
        } catch (error) {
            this.datECodeRequestPending = false;
            switch (error.code) {
                case 'DAT_UNAUTHORIZED':
                    this.error.datECode = `Die DAT-Zugangsdaten wurden abgelehnt. Bitte prüfe sie in den <a href="Einstellungen#calculation-providers-section">Einstellungen</a>.`;
                    break;
                case 'DAT_E_CODE_NOT_FOUND':
                    this.error.datECode = `Für diesen DAT€Code konnte die DAT keine Daten finden.`;
                    break;
                case 'DAT_E_CODE_NOT_FOUND_FOR_THIS_CONSTRUCTION_TIME':
                    this.error.datECode = `Für diesen Bauzeitraum konnte kein DAT€Code gefunden werden. Bitte prüfe die Bauzeit.`;
                    break;
                case 'DAT_MARKET_INDEX_NOT_FOUND':
                    this.error.datECode = `Der Marktindex konnte zu diesem DAT€Code nicht gefunden werden. Versuche die DAT€Code-Abfrage ohne Marktindex.`;
                    break;
                default:
                    this.error.datECode = `Ein unbekannter Fehler ist aufgetreten.<br>Bitte führe die DAT€Code-Abfrage erneut aus. Sollte der Fehler immer noch auftreten, wende dich bitte an den autoiXpert-Support.`;
            }
        }
    }

    public getDatECodeOnEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.getDatECodeData();
        }
    }

    public validateDatECodeFormat(): boolean {
        // Length
        if (this.report.car.datIdentification.datEuropaCode.length !== 15) {
            this.error.datECode = 'Der DAT€Code muss 15 Ziffern lang sein.';
            return false;
        }

        // Only numbers
        if (!/^\d{15}$/.test(this.report.car.datIdentification.datEuropaCode)) {
            this.error.datECode = 'Der DAT€Code darf nur aus Zahlen bestehen.';
            return false;
        }

        return true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get DAT€Code Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  KBA Code
    //****************************************************************************/
    public async getKbaCodeData() {
        // Since KBA data may only be fetched when online, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'KBA-Schlüssel sind abrufbar, sobald du wieder online bist.',
            );
            return;
        }

        if (!this.validateKbaFormat()) {
            this.toastService.error('KBA-Code ungültig', 'Bitte bringe den Schlüssel in das Format "1234/ABC".');
            return;
        }

        this.kbaCodeRequestPending = true;

        let kbaCode = this.report.car.kbaCode.replace(/\//g, '-');
        // If there are multiple KBA codes, e.g. after a VIN request, use only the first.
        if (kbaCode.includes(',')) {
            this.toastService.info(
                'Erster KBA-Code',
                'Es wurden mehrere KBA-Codes angegeben. Der erste wurde für die Abfrage verwendet.',
            );
            kbaCode = kbaCode.split(',')[0];
        }

        if (this.report.car.identificationProvider === 'audatex') {
            /**
             * Create a task if it doesn't exist yet. Then query VIN results.
             */
            if (!this.report.audatexTaskId) {
                try {
                    await this.audatexTaskService.create({ report: this.report });
                } catch (error) {
                    this.audatexTaskService.handleAndRethrow({
                        axError: error,
                        report: this.report,
                        defaultHandler: {
                            title: 'Audatex-Vorgang nicht angelegt',
                            body: 'Der Audatex-Vorgang konnte aus einem unbekannten Grund nicht angelegt werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                        },
                    });
                }
            }
            let audatexKbaCodeResponse: AudatexKbaCodeResult[];
            try {
                audatexKbaCodeResponse = await this.audatexKbaCodeService
                    .getKbaCodeResult(this.reportId, kbaCode)
                    .toPromise();
            } catch (error) {
                this.kbaCodeRequestPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        AUDATEX_KBA_IDENTIFICATION_FAILED: {
                            title: 'KBA-Code-Abfrage fehlgeschlagen',
                            body: `Öffne Qapter über die Lupe im Feld "Audatex-Code" und identifiziere das Fahrzeug manuell.<br><br>Importiere die Fahrzeugdaten anschließend über den Download-Pfeil im Feld Audatex-Code.`,
                        },
                    },
                    defaultHandler: {
                        title: 'KBA-Code-Abfrage fehlgeschlagen',
                        body: 'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            } finally {
                this.kbaCodeRequestPending = false;
            }

            if (!audatexKbaCodeResponse.length) {
                this.toastService.info('Keine Fahrzeuge gefunden', 'Ist der KBA-Schlüssel korrekt?');
            } else if (audatexKbaCodeResponse.length > 1) {
                /**
                 * If the result is an array of car objects, the VIN response contained multiple cars (non-unique VIN response). The user
                 * must choose which car the report describes.
                 */
                this.toastService.info(
                    'Mehrere Fahrzeuge gefunden',
                    'Bitte öffne die Audatex-Oberfläche über die Lupe im Audatex-Code, um das Fahrzeug eindeutig zu identifizieren.',
                );
            } else {
                this.mergeCar(audatexKbaCodeResponse[0].newCarInformation);
                await this.saveEquipment(audatexKbaCodeResponse[0].carEquipment);
            }
            this.kbaCodeRequestPending = false;
        } else {
            //*****************************************************************************
            //  DAT
            //****************************************************************************/
            const datJwt = await this.datAuthenticationService.getJwt();

            let datIdentificationResponsePerVehicle: DatIdentificationResponsePerVehicle[];
            try {
                datIdentificationResponsePerVehicle = await this.httpClient
                    .get<DatIdentificationResponsePerVehicle[]>(`/api/v0/dat/kbaCode/${kbaCode}`, {
                        params: {
                            productionYear: this.report.car.productionYear
                                ? moment(this.report.car.productionYear).format('YYYY')
                                : '',
                        },
                        headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                    })
                    .toPromise();
            } catch (error) {
                this.kbaCodeRequestPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getDatErrorHandlers(),
                        INVALID_KBA_CODE: (error) => ({
                            title: 'Ungültiger KBA-Code',
                            body:
                                'Bitte prüfe das Format.<br><br><strong>Rückmeldung der DAT:</strong><br>' +
                                error.data.datErrorMessage,
                        }),
                        PRODUCTION_YEAR_INVALID: (error) => ({
                            title: `Ungültiges Baujahr ${error.data.productionYear}`,
                            body: `Es wurden keine Fahrzeuge gefunden.`,
                        }),
                    },
                    defaultHandler: (error) => ({
                        title: 'KBA-Code nicht abfragbar',
                        body:
                            (error.data && error.data.datErrorMessage
                                ? `Fehlermeldung der DAT: ${error.data.datErrorMessage}<br><br>`
                                : '') +
                            'Wende dich bei weiteren Fragen gerne an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    }),
                });
            }

            if (!datIdentificationResponsePerVehicle.length) {
                this.toastService.info('Keine Fahrzeuge gefunden', 'Ist der KBA-Schlüssel korrekt?');
            }
            // If the result is an array of car objects, the VIN response contained multiple cars (non-unique VIN response). The user
            // must choose which car the report describes.
            else if (datIdentificationResponsePerVehicle.length > 1) {
                this.identificationResponsesPerVehicle = datIdentificationResponsePerVehicle;
            } else {
                this.mergeCar(datIdentificationResponsePerVehicle[0].newCarInformation);
                await this.saveEquipment(datIdentificationResponsePerVehicle[0].carEquipment);
            }
            this.kbaCodeRequestPending = false;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END DAT
            /////////////////////////////////////////////////////////////////////////////*/
        }
    }

    public triggerKbaVerificationOnEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.getKbaCodeData();
        }
    }

    public transformKbaToUpperCase(): void {
        this.report.car.kbaCode = (this.report.car.kbaCode || '').toUpperCase();
    }

    public getKbaTooltip(): string {
        if (this.isReportLocked()) {
            return 'Um die KBA-Code-Abfrage auszuführen, muss das Gutachten offen sein. Aktuell ist es abgeschlossen.';
        }
        if (this.report.car.identificationProvider === 'audatex') {
            return 'Fahrzeugdaten bei Audatex abfragen.';
        } else {
            return 'Fahrzeugdaten bei der DAT abfragen.\n\nMit der Angabe des Baujahrs erhältst du präzisere Informationen.';
        }
    }

    /**
     * Display an error message if the KBA code is invalid.
     *
     * KBA code: 4 digits + slash + 3 alphanumeric characters.
     *
     * Returns true in case someone needs to have the KBA code checked before triggering
     */
    public validateKbaFormat(): boolean {
        this.kbaErrorMessage = null;

        const kbaCode = this.report.car.kbaCode;

        if (!kbaCode) return;

        if (/^(\d{4}\/?[A-Za-z0-9]{3},?){1,}$/.test(kbaCode)) {
            return true;
        }

        this.kbaErrorMessage = 'Format: 1234/ABC';
        return false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END KBA Code
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Filter the manufacturers by their name property. If no search term is provided, return all manufacturers.
     * @param searchTerm
     */
    public filterManufacturers(searchTerm: string): void {
        let filteredManufacturers: Manufacturer[] = [...this.manufacturers];

        if (searchTerm) {
            if (searchTerm.toLowerCase() === 'vw') {
                searchTerm = 'volkswagen';
            }

            filteredManufacturers = this.manufacturers.filter((manufacturer: Manufacturer) => {
                return manufacturer && manufacturer.name?.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }

        this.filteredManufacturers = filteredManufacturers.map((manufacturer) => manufacturer.name);
    }

    protected iconForCarBrandExists = iconForCarBrandExists;

    /**
     * Transform the car brand to a string without spaces in lowercase to conform with the brand icon image files.
     * @param {string} carBrand
     * @returns {string}
     */
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;

    /**
     * Set the carModels array depending on the current car.make selection.
     *
     * If car.make has not been selected yet, just set carModels to an empty array, so that ngFor loops won't fail.
     */
    public selectBaseModels(manufacturerName: string): void {
        if (!manufacturerName) {
            this.baseModels = [];
            return;
        }
        const selectedManufacturer = this.manufacturers.find((manufacturer) => {
            return manufacturer.name === manufacturerName;
        });

        // No matching manufacturer
        if (!selectedManufacturer) {
            return;
        }

        this.baseModels = selectedManufacturer.baseModels;
        // Also set the suggestions for the autocomplete.
        this.filteredBaseModels = this.baseModels.slice();
    }

    /**
     * After the user manually selected (without VIN request) a manufacturer, base model or sub model from the car data form,
     * this function updates the DAT codes for the selected car data. This way the DAT calculation will receive the selected data.
     *
     * This function always checks manufacturer + model + sub model because the user for example might edit the base model
     * although there has been a valid sub model entered already. When switching back to a valid base model the sub model code is then
     * updated as well.
     */
    public updateDatCarInfo() {
        if (!this.report.car.datIdentification) {
            // Identification codes are only available for DAT calculations
            return;
        }

        // Retrieve DAT codes for manufacturer, base model and sub model
        const selectedManufacturer = this.getDatManufacturer();
        const manufacturerDatKey = selectedManufacturer?.datKey;

        const selectedBaseModel = this.getDatBaseModel(selectedManufacturer);
        const baseModelDatKey = selectedBaseModel?.datKey;

        const selectedSubModel = this.getDatSubModel(selectedBaseModel);
        const subModelDatKey = selectedSubModel?.datKey;

        /**
         * If all fields match a DAT vehicle, select its according DAT keys. That's usually only
         * the case if the user selects a vehicle through the dropdown fields.
         *
         * If the user changes the field values manually, e.g. to change the DAT base model name of a BMW X5 from
         * "Baureihe X5 (01.2018->)" to "X5", do not delete the DAT keys.
         */
        if (manufacturerDatKey != null && baseModelDatKey != null && subModelDatKey != null) {
            // In case all three (manufacturer+model+sub model) have a valid DAT code, we set the isDamageCalculationPossible
            // to true. This property is usually sent by DAT when a calculation for a VIN request is possible (in contrast to a shadow calculation)
            // By setting this property to true, DAT will try to disable the shadow calculation and start a real calculation if possible
            this.report.car.datIdentification.isDamageCalculationPossible = true;
        }

        // Now store the DAT keys if they are valid, otherwise remove them from the datIdentification object
        if (manufacturerDatKey) {
            this.report.car.datIdentification.manufacturerKey = manufacturerDatKey;
        }

        if (baseModelDatKey) {
            this.report.car.datIdentification.baseModelKey = baseModelDatKey;
        }

        if (subModelDatKey) {
            this.report.car.datIdentification.subModelKey = subModelDatKey;
        }
    }

    /**
     * From the list of DAT manufacturer infos return the object for the currently selected car manufacturer.
     */
    public getDatManufacturer(): Manufacturer | null {
        // Lookup DAT manufacturer
        const manufacturerName = this.report.car.make;

        if (manufacturerName) {
            return this.manufacturers.find((manufacturer) => {
                return manufacturer.name === manufacturerName;
            });
        }

        return null;
    }

    /**
     * From the list of DAT manufacturer infos return the object for the currently selected base model.
     */
    public getDatBaseModel(manufacturer: Manufacturer | null): BaseModel | null {
        // Lookup DAT base model
        const baseModelName = this.report.car.model;

        if (baseModelName) {
            const selectedBaseModel = manufacturer?.baseModels.find((baseModel) => {
                return baseModel.name === baseModelName;
            });

            if (selectedBaseModel) {
                return selectedBaseModel;
            }
        }

        return null;
    }

    /**
     * From the list of DAT manufacturer infos return the object for the currently selected sub model.
     */
    public getDatSubModel(baseModel: BaseModel | null): SubModel | null {
        const subModelName = this.report.car.submodel;

        // Lookup DAT sub model
        if (subModelName) {
            const selectedSubModel = baseModel?.subModels.find((subModel) => {
                return subModel.name === subModelName;
            });

            if (selectedSubModel) {
                return selectedSubModel;
            }
        }

        return null;
    }

    /**
     * Clear the car model and reset the autocomplete options.
     * This is used when changing the selected car manufacturer.
     */
    public clearBaseModelAutocompleteOptions(): void {
        this.baseModels = [];
        // Reset the autocomplete options to nothing
        this.filterCarModels(null);
        // When clearing the base model, always clear the sub model, too.
        this.clearSubModelAutocompleteOptions();
    }

    public filterCarModels(searchTerm: string): void {
        if (!searchTerm) {
            this.filteredBaseModels = this.baseModels.slice();
            return;
        }
        this.filteredBaseModels = this.baseModels.filter((carModel) => {
            return carModel && carModel.name && carModel.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }

    /**
     * Set the subModels array depending on the current car.model selection.
     */
    public selectSubModels(manufacturerName: string, baseModelName: string): void {
        if (manufacturerName) {
            const selectedManufacturer = this.manufacturers.find((manufacturer) => {
                return manufacturer.name === manufacturerName;
            });

            // Guard against the case that the manufacturers array is still empty
            if (!selectedManufacturer) {
                return;
            }

            // If the BaseModels have not yet been loaded, do that now
            const baseModels = selectedManufacturer.baseModels;

            const selectedBaseModel = baseModels.find((baseModel) => {
                return baseModel.name === baseModelName;
            });

            // Guard against the case that the base model could not be found by what the user entered.
            if (!selectedBaseModel) {
                return;
            }

            this.subModels = selectedBaseModel.subModels;

            // Also set the suggestions for the autocomplete.
            this.filteredSubModels = [...selectedBaseModel.subModels];
        } else {
            this.subModels = [];
        }
    }

    /**
     * Clear the car model and reset the autocomplete options.
     * This is used when changing the selected car manufacturer.
     */
    public clearSubModelAutocompleteOptions(): void {
        this.subModels = [];
        this.filterSubModels(null);
    }

    public filterSubModels(searchTerm: string): void {
        if (!searchTerm) {
            this.filteredSubModels = this.subModels.slice();
            return;
        }
        this.filteredSubModels = this.subModels.filter((subModel) => {
            return subModel && subModel.name && subModel.name.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }

    /**
     * Insert axles and tire data after a VIN query manually.
     * Our customers enter tire information (e.g. dimension) at the first visit before they query the VIN.
     */
    public insertNewAxleConfiguration() {
        Object.assign(this.report.car.axles, JSON.parse(JSON.stringify(this.newImportedAxles)));
        this.newImportedAxles = null;
        this.saveReport();
    }

    /**
     * We want a tooltip which displays the incoming data for all tires and the existing values.
     */
    public getNewAxleConfigurationTooltip() {
        const tooltipText = [];
        this.newImportedAxles?.forEach((axle) => {
            getAllTiresOnAxle(axle)?.forEach((tire) => {
                const existingTire = getAllTiresOfVehicle(this.report.car.axles).find(
                    (existingTire) => tire.axle === existingTire.axle && tire.position === existingTire.position,
                );
                tooltipText.push(
                    `Achse ${axle.axleNumber} ${Translator.tirePosition(tire.position, false)}:
                   - Hersteller: ${tire.manufacturer ?? 'keine Angabe'} ${
                       existingTire?.manufacturer ? '- bisher: ' + existingTire.manufacturer : ''
                   }
                   - Dimension: ${tire.type ?? 'keine Angabe'} ${
                       existingTire?.type ? '- bisher: ' + existingTire.type : ''
                   }
                   - Profil: ${tire.treadInMm ? tire.treadInMm + 'mm' : 'keine Angabe'} ${
                       existingTire?.treadInMm ? '- bisher: ' + existingTire?.treadInMm + ' mm' : ''
                   }`,
                );
            });
        });
        return tooltipText.join('\n\n');
    }

    /**
     * We need to merge each tire into the new axle object using Object.assign() to keep the reference.
     * Angular change detection won't work if we change the reference.
     * @param newAxles
     * @param sourceAxles
     * @private
     */
    private mergeTires(newAxles: Axle[], sourceAxles: Axle[]) {
        for (const newAxle of newAxles) {
            const axleIndex = newAxles.indexOf(newAxle);
            if (sourceAxles[axleIndex]) {
                const newTires = getAllTiresOnAxle(newAxle);
                const sourceTires = getAllTiresOnAxle(sourceAxles[axleIndex]);
                for (const newTire of newTires) {
                    const sourceTireOnSamePosition = sourceTires.find((tire) => tire.position === newTire.position);
                    if (!sourceTireOnSamePosition) continue;

                    // Only copy filled properties off the new tire.
                    for (const [key, value] of Object.entries(sourceTireOnSamePosition)) {
                        // We want to keep all properties but don't want to overwrite new values with nullish values.
                        if (value || !newTire[key]) {
                            newTire[key] = value;
                        }
                    }
                }
            }
        }
    }

    /**
     * Merge the result of a vehicle identification query (e.g. VIN, DAT€Code, ...) into the report.car property.
     *
     * @param newCar
     * @param keepOldValuesIfNewOnesAreNullish - If a second identification is carried out, and it bears nullish values, this parameter determines if the nullish values are inserted or if the existing values are kept.
     * @private
     */
    private mergeCar(
        newCar: Car,
        keepOldValuesIfNewOnesAreNullish: boolean = true,
        returnVinErrorsInsteadOfToast: boolean = false,
    ): { vinErrors?: { title: string; body: string }[] } {
        const vinErrors = [];

        // Select car shape, setting up correct tires array
        this.selectCarShape(newCar.shape);

        const newCarHasEngineType = Object.keys(newCar).some((key) => key.startsWith('runsOn'));
        if (newCarHasEngineType) {
            // If new new car has an engine type -> reset the previously selected engine type
            this.unselectAllEngineTypes();
            this.report.car.runsOnSomethingElse = '';
        }

        // The axles array may be nullish if no car shape has been selected yet.
        if (newCar.axles && newCar.axles.length > 0) {
            /**
             * Check if the customer added already some information about the tires.
             * If so, don't overwrite but cache the new axles and display an info note.
             */
            const isTireConfigurationDifferent = this.report.car.axles.some((existingAxle) => {
                const existingTires = getAllTiresOnAxle(existingAxle);
                return existingTires.some((existingTire) => {
                    const newTire = getAllTiresOfVehicle(newCar.axles).find(
                        (tire) => tire.axle === existingTire.axle && tire.position === existingTire.position,
                    );
                    // First check: Did the customer add some information to the tire?
                    const isTireConfigured = existingTire.type || existingTire.manufacturer || existingTire.treadInMm;
                    // If a new tire is available and is not the same as the existing tire, there is a difference.
                    // Without this check we would add the info-note even if nothing changes.
                    if (isTireConfigured && newTire) {
                        return !(
                            newTire.type == existingTire.type &&
                            newTire.manufacturer == existingTire.manufacturer &&
                            newTire.treadInMm == existingTire.treadInMm
                        );
                    }
                    // If no new tire is available but there are changes in the existing tire, there is a difference.
                    return isTireConfigured;
                });
            });
            if (isTireConfigurationDifferent) {
                // We save the incoming axle configuration as copy
                this.newImportedAxles = JSON.parse(JSON.stringify(newCar.axles));
                // We merge the current axle configuration in the new car
                // In the following steps of 'mergeCar' we will merge these values back into report.car
                this.mergeTires(newCar.axles, this.report.car.axles);
                if (newCar.axles.length > this.report.car.axles.length) {
                    this.report.car.axles.push(...newCar.axles.slice(this.report.car.axles.length));
                }
            } else {
                // We overwrite the current axle configuration with the new incoming data.
                // In the following steps of 'mergeCar' we will merge these values in the newCar object and then back into report.car
                this.mergeTires(this.report.car.axles, newCar.axles);
            }
        }

        /**
         * Use the dimension and weight properties
         * - for utility vehicles or
         * - for all vehicles if the user configured autoiXpert that way.
         */
        if (!this.isDimensionAndWeightSectionVisible()) {
            delete newCar.unloadedWeight;
            delete newCar.maximumTotalWeight;
            delete newCar.length;
            delete newCar.width;
            delete newCar.height;
            delete newCar.wheelBase;
        }

        //*****************************************************************************
        //  Merge existing data with re-identification data
        //****************************************************************************/
        /**
         * If the user re-identifies a vehicle, prevent clearing his potentially manually entered values with nullish values from the identification response.
         */
        if (keepOldValuesIfNewOnesAreNullish) {
            // Which properties have been skipped on merge because they were empty in the new car object?
            const skippedProperties: string[] = [];
            const carBeforeMerge = JSON.parse(JSON.stringify(this.report.car));

            for (const key of Object.keys(newCar)) {
                if (newCar[key]) {
                    //*****************************************************************************
                    //  Merge Arrays
                    //****************************************************************************/
                    // Merge array elements with their existing counterparts.
                    if (Array.isArray(newCar[key])) {
                        // Loop over array
                        for (let i = 0; i < newCar[key].length; i++) {
                            const newArrayElement = newCar[key][i];
                            const oldArrayElement = this.report.car[key][i];

                            // Merge each array element
                            for (const arrayKey of Object.keys(newArrayElement)) {
                                if (newArrayElement[arrayKey]) {
                                    oldArrayElement[arrayKey] = newArrayElement[arrayKey];
                                } else if (oldArrayElement[arrayKey]) {
                                    skippedProperties.push(`${key}.${i}.${arrayKey}`);
                                }
                            }
                        }
                    } else {
                        this.report.car[key] = newCar[key];
                    }
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Merge Arrays
                    /////////////////////////////////////////////////////////////////////////////*/
                }
                // New property is nullish, but the report property already holds a value, e.g. because the user entered one by hand. -> Don't overwrite but record the key.
                else if (!newCar[key] && this.report.car[key]) {
                    skippedProperties.push(key);
                }
            }

            const ignoredProperties: (keyof Car)[] = [
                /**
                 * Ignore the number of powered axles since they are set when the car shape is selected at the very top of this function. So, if the value is empty (as is often the case
                 * with GT Motive), this message would always pop up.
                 */
                'numberOfPoweredAxles',
                /**
                 * The license plate and first/last registration may be imported from the manual DAT vehicle identification. Since that attribute does not belong to the vehicle's technical
                 * data, ignore it.
                 */
                'licensePlate',
                'firstRegistration',
                'latestRegistration',
                'nextGeneralInspection',
                'nextSafetyTest',
            ];
            for (const ignoredProperty of ignoredProperties) {
                removeFromArray(ignoredProperty, skippedProperties);
            }

            if (skippedProperties.length) {
                console.log(
                    'Skipped these properties when merging VIN result (skipped properties, car before merge, new car): ',
                    skippedProperties,
                    carBeforeMerge,
                    newCar,
                );
                /**
                 * The empty values are in English, but they may help the user nevertheless.
                 * Since a second VIN request in the same report is very rare, there is no need to optimize this.
                 */
                if (returnVinErrorsInsteadOfToast) {
                    vinErrors.push({
                        title: 'Leere VIN-Werte nicht übernommen',
                        body: `Damit deine Eingaben nicht überschrieben werden, wurden leere Felder aus der VIN-Antwort nicht in autoiXpert geleert: <ol style="margin: 0">${skippedProperties
                            .map((propertyName) => `<li>${propertyName}</li>`)
                            .join('')}</ol>`,
                    });
                } else {
                    this.closeToastsOnDestroy.push(
                        this.toastService.info(
                            'Leere VIN-Werte nicht übernommen',
                            `Damit deine Eingaben nicht überschrieben werden, wurden leere Felder aus der VIN-Antwort nicht in autoiXpert geleert: <ol style="margin: 0">${skippedProperties
                                .map((propertyName) => `<li>${propertyName}</li>`)
                                .join('')}</ol>`,
                            // Show this important info for an unlimited time to the user so that they can read it and manually dismiss the toast.
                            { clickToClose: true, timeOut: undefined },
                        ),
                    );
                }
            }
        } else {
            // Add everything we know after the VIN request to the car object.
            merge(this.report.car, newCar);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Merge existing data with re-identification data
        /////////////////////////////////////////////////////////////////////////////*/

        /**
         * Only DAT provides manufacturer and model data that autoiXpert can store in its database. Audatex
         * requires the user to open the Audatex interface.
         */
        if (this.report.car.identificationProvider === 'dat') {
            this.selectBaseModels(this.report.car.make);
            this.selectSubModels(this.report.car.make, this.report.car.model);
        }

        this.calculateHP();
        this.showCustomTypeInputs(this.report);
        this.saveReport();

        if (returnVinErrorsInsteadOfToast) {
            return { vinErrors };
        }
        return {};
    }

    /**
     * Audatex returns a value from the valuation in the VIN response: the original vehicle value.
     */
    private mergeAudatexValuation(audatexValuation: AudatexValuation) {
        // Some reports do not need a vehicle valuation, e.g. an invoice audit ("Rechnungsprüfung"). No need to merge then.
        if (!this.report.valuation) return;

        if (!this.report.valuation.audatexValuation) {
            this.report.valuation.audatexValuation = new AudatexValuation();
        }
        Object.assign(this.report.valuation.audatexValuation, audatexValuation);
    }

    private async saveEquipment(carEquipment: CarEquipment): Promise<void> {
        Object.assign(this.carEquipment, omit(carEquipment, '_id', 'reportId', 'createdBy'));

        try {
            const [carEquipment]: CarEquipment[] = await this.carEquipmentService
                .find({ reportId: this.report._id })
                .toPromise();

            // Patch or create if it doesn't exist yet.
            if (carEquipment) {
                await this.carEquipmentService.put(this.carEquipment);
            } else {
                await this.carEquipmentService.create(this.carEquipment);
            }
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Ausstattung nicht gespeichert',
                    body: 'Die Ausstattung konnte nicht auf dem Server gespeichert werden.',
                },
            });
        }
    }

    // // This allows debugging the function. Unless it is attached to this class, you cannot call it with the debugger.
    // private convertClassificationGroupNumberToString = convertClassificationGroupNumberToString;

    //*****************************************************************************
    //  Horse Power & KW
    //****************************************************************************/
    /**
     * Methods for converting KW to HP and resetting value if one field is empty.
     */
    public calculateKW() {
        if (!this.performanceHP) {
            this.report.car.performanceKW = null;
        } else {
            const kw = this.performanceHP * 0.735499;
            if (kw > 10) {
                this.report.car.performanceKW = Math.round(+kw); // + converts a string to a number
            } else {
                this.report.car.performanceKW = Math.round(+kw * 100) / 100; // Round to two decimal digits
            }
        }
    }

    public calculateHP() {
        if (!this.report.car.performanceKW) {
            this.performanceHP = null;
        } else {
            this.performanceHP = Math.round(+this.report.car.performanceKW / 0.735499); // + converts a string to a number
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Horse Power & KW
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Engine
    //****************************************************************************/
    // Display internal combustion engine specific fields only if the vehicle has an internal combustion engine.
    public doesVehicleHaveInternalCombustionEngine() {
        return !this.isBicycleOrPedelec && doesVehicleHaveInternalCombustionEngine(this.report);
    }

    // Display battery specific fields only if the vehicle has a battery electric engine.
    public doesVehicleHaveBatteryElectricEngine() {
        return doesVehicleHaveBatteryElectricEngine(this.report);
    }

    public engineDisplacementHasDecimals(): boolean {
        return (
            this.report.car.engineDisplacement && round(this.report.car.engineDisplacement, 1).toString().includes('.')
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Engine
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  First & Latest Registration
    //****************************************************************************/
    /**
     * In case the first and latest registrations happened on the same day,
     * set the number of previous owners to zero.
     *
     * Only do so if the number of previous owners has not been set before.
     *
     * If the number of previous owners is zero but the dates are different, automatically remove the value zero.
     */
    public setNumberOfPreviousOwners(): void {
        // Don't do anything if either date is still blank.
        if (!this.report.car.firstRegistration || !this.report.car.latestRegistration) return;

        if (
            areDatesEqual(this.report.car.firstRegistration, this.report.car.latestRegistration) &&
            this.report.car.numberOfPreviousOwners == null
        ) {
            this.report.car.numberOfPreviousOwners = '0';
        } else if (this.report.car.numberOfPreviousOwners === '0') {
            this.report.car.numberOfPreviousOwners = null;
        }
    }

    public isNumberOfPreviousOwnersImplausible(): boolean {
        return (
            this.report.car.firstRegistration &&
            this.report.car.latestRegistration &&
            !areDatesEqual(this.report.car.firstRegistration, this.report.car.latestRegistration) &&
            this.report.car.numberOfPreviousOwners === '0'
        );
    }

    /**
     * On iPads, the auto-filled value will not be selected automatically. This provides a method to delete with one click.
     */
    public removeLatestRegistration(): void {
        this.report.car.latestRegistration = null;
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END First & Latest Registration
    /////////////////////////////////////////////////////////////////////////////*/
    public filterTransmissionTypes(userInput: string): void {
        userInput = (userInput || '').toLowerCase();
        // If the user input is non-empty, filter the transmission types. If it is empty, reset it to all options.
        if (userInput) {
            this.filteredTransmissionTypes = this.transmissionTypes.filter((transmissionType) => {
                return transmissionType.toLowerCase().includes(userInput);
            });
        } else {
            this.filteredTransmissionTypes = this.transmissionTypes.slice();
        }
    }

    public setLatestRegistration(latestRegistrationDate: IsoDate): void {
        if (!this.report.car.latestRegistration && this.userPreferences.automaticallyInsertLatestRegistration) {
            this.report.car.latestRegistration = latestRegistrationDate;
        }
    }

    //*****************************************************************************
    //  Dimensions & Weight
    //****************************************************************************/
    /**
     * Dimension and weight is required for utility vehicles and if the user has already entered values or if the vehicle is a utility vehicle.
     * If the user set the preference to always display the section, it will be displayed for all vehicles except bikes.
     */
    public isDimensionAndWeightSectionVisible(): boolean {
        return (
            this.doesCarShapeRequireDimensionsAndWeight(this.report.car.shape) ||
            this.doDimensionAndWeightValuesExist() ||
            (this.team?.preferences.alwaysDisplayVehicleDimensionsAndWeight && !this.isBicycleOrPedelec)
        );
    }

    public doesCarShapeRequireDimensionsAndWeight(carShape: Car['shape']): boolean {
        return isUtilityVehicle(carShape) || carShape === 'trailer';
    }

    /**
     * If dimension and weight values exist, we must always display the section with the respective inputs.
     */
    public doDimensionAndWeightValuesExist(): boolean {
        // Shorthand
        const car = this.report.car;
        return !!(
            car.unloadedWeight ||
            car.maximumTotalWeight ||
            car.length ||
            car.width ||
            car.height ||
            car.wheelBase
        );
    }

    protected alwaysDisplayVehicleDimensionsAndWeightChanged() {
        if (this.team.preferences.alwaysDisplayVehicleDimensionsAndWeight) {
            this.toastService.info(
                'Abmessungen & Gewicht: immer angezeigt',
                'Der Abschnitt ist nun immer sichtbar und wird automatisch bei der Abfrage der Fahrzeugdaten ausgefüllt.',
            );
        } else {
            this.toastService.info(
                'Abmessungen & Gewicht: nur für Nutzfahrzeuge',
                'Der Abschnitt ist nur für Nutzfahrzeuge sichtbar oder falls bereits Daten vorhanden sind.',
            );
        }

        void this.saveTeam();
    }

    public getAxlesLabel(axles: Axle[], axleNumber: number): string {
        if (axles?.length === 2) {
            if (axleNumber === 1) {
                return 'Achslast vorne (kg)';
            }
            if (axleNumber === 2) {
                return 'Achslast hinten (kg)';
            }
        } else {
            return `Achslast ${axleNumber}. Achse (kg)`;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dimensions & Weight
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Basic Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Selector
    //****************************************************************************/
    public async processDatCarSelectorChangeEventAfterIdentificationRequest(
        identificationResponsePerVehicle: DatIdentificationResponsePerVehicle,
    ) {
        this.mergeCar(identificationResponsePerVehicle.newCarInformation);
        const identificationResponsePerVehicleFromDatECode = await this.fetchDatECodeDataFromDAT({
            datECode: this.report.car.datIdentification.datEuropaCode,
            datConstructionTime: this.report.car.productionYear
                ? '' + date2ConstructionTime(this.report.car.productionYear)
                : '',
            datMarketIndex: this.report.car.datIdentification.marketIndex || '',
        });

        await this.saveEquipment(identificationResponsePerVehicleFromDatECode.carEquipment);
        this.identificationResponsesPerVehicle = [];
    }

    public closeMultipleCarsIdentificationHelper(): void {
        this.identificationResponsesPerVehicle = [];
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Selector
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Registrations & Production Year
    //****************************************************************************/
    public firstRegistrationIsBeforeProductionYear(): boolean {
        if (!this.report.car.firstRegistration || !this.report.car.productionYear) return false;

        return moment(this.report.car.firstRegistration)
            .startOf('year')
            .isBefore(moment(this.report.car.productionYear).startOf('year'));
    }

    public latestRegistrationIsBeforefirstRegistration(): boolean {
        if (!this.report.car.latestRegistration || !this.report.car.firstRegistration) return false;

        return moment(this.report.car.latestRegistration)
            .startOf('day')
            .isBefore(moment(this.report.car.firstRegistration).startOf('day'));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Registrations & Production Year
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Advanced Car Data Tabs
    //****************************************************************************/
    public selectAdvancedCarDataTab(tab: this['selectedAdvancedCarDataTab']): void {
        this.selectedAdvancedCarDataTab = tab;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Advanced Car Data Tabs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Engine Types
    //****************************************************************************/

    public toggleEngineType(engineTypeId: string, mouseEvent: MouseEvent = new MouseEvent('click')): void {
        // Deactivate all engineTypes first, unless the CTRL key was pressed. Then the click only modifies the current selection.
        if (!(mouseEvent.ctrlKey || mouseEvent.metaKey)) {
            this.unselectAllEngineTypes();
        }

        // ...then toggle the status of the clicked engine type
        this.report.car['runsOn' + engineTypeId] = !this.report.car['runsOn' + engineTypeId];
        this.saveReport();
    }

    public toggleInputForCustomEngineType(): void {
        this.showInputForCustomEngineType = !this.showInputForCustomEngineType;
    }

    public unselectAllEngineTypes(): void {
        if (this.isReportLocked()) return;

        this.engineTypes.forEach((engineType) => {
            this.report.car['runsOn' + engineType.id] = false;
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Engine Types
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Shapes
    //****************************************************************************/
    public showInputForCustomCarShapeLabel(): void {
        this.inputForCustomCarShapeShown = true;
        if (!this.report.car.shape) {
            // this.toastService.info("Fahrzeugart wählen", "Wähle über die Fahrzeugart die richtige Skizze. Den Namen kannst du nun unabhängig von der Skizze setzen.");
            // We set a default if the user has not yet selected his own to force him to select the right sketch.
            this.report.car.shape = this.carShapesForUI[0].id;
        }
    }

    public hideInputForCustomCarShapeLabel(): void {
        if (this.report.car.customShapeLabel) {
            this.report.car.customShapeLabel = null;
            this.saveReport();
        }
        this.inputForCustomCarShapeShown = false;
    }

    /**
     * The UI element associated to the report's carShape is not visible by default if it is one of the UI elements from
     * the submenu. Check if it one of the submenu items and add it to the list of shown UI elements then.
     */
    private addSelectedCarShapeToUI(report: Report): void {
        // If the current car shape is empty, don't show the custom input.
        if (!report.car.shape) {
            return;
        }

        // If the report's car shape is part of the standard options, abort.
        for (const carShapeForUI of this.carShapesForUI) {
            if (report.car.shape === carShapeForUI.id) {
                return;
            }
        }

        // Check if it is set to a submenu item
        for (const carShapeForSubmenu of [
            ...this.carShapesForSubmenuToplevel,
            ...this.carShapesForSubmenuCamping,
            ...this.carShapesForSubmenuTrucks,
            ...this.carShapesForSubmenuBikes,
        ]) {
            if (report.car.shape === carShapeForSubmenu.id) {
                this.carShapesForUI[5] = carShapeForSubmenu;
                return;
            }
        }

        // If the for loop has not yet found something, the term might be a custom one
        this.inputForCustomCarShapeShown = true;
    }

    public selectCarShape(carShape: CarShape) {
        // If the car shape is already correct, don't reset it
        if (this.report.car.shape === carShape) {
            return;
        }

        this.report.car.shape = carShape;

        // Create the right combination of tires and damage positions for the selected car shape
        switch (carShape) {
            case 'bicycle':
            case 'pedelec':
            case 'e-bike':

            case 'motorcycle':
                // Display the custom car properties for bicycles and pedelec
                if (carShape !== 'motorcycle') {
                    // Bikes (even with motor) do not have an engine - see comment in model car.runsOnElectricity
                    this.unselectAllEngineTypes();
                    this.addBicycleAndPedelecDefaultCustomCarProperties();
                }
                // only reset the tires if it's necessary. From motorcycle to scooter, the user will most likely keep the tire info
                if (this.report.car.axles.length === 2 && this.report.car.axles[0].centerTire) {
                    break;
                }
                this.report.car.axles = [
                    new Axle({
                        axleNumber: 1,
                        axlePosition: 'front',
                        isSteerable: true,
                        centerTire: new Tire({ axle: 1, position: 'center' }),
                    }),
                    new Axle({
                        axleNumber: 2,
                        axlePosition: 'rear',
                        isSteerable: false,
                        centerTire: new Tire({ axle: 2, position: 'center' }),
                    }),
                ];
                this.selectNumberOfAxles(this.report.car.axles.length);
                this.selectNumberOfPoweredAxles(1);
                // In case of oldtimer valuation, remove the grades for car-specific elements
                if (this.report.valuation.oldtimerValuationGrades) {
                    this.report.valuation.oldtimerValuationGrades.cabin = null;
                    this.report.valuation.oldtimerValuationGrades.engineCompartment = null;
                    this.report.valuation.oldtimerValuationGrades.trunk = null;
                }
                break;
            case 'trailer':
            case 'caravanTrailer':
                this.report.car.axles = [
                    new Axle({
                        axleNumber: 1,
                        axlePosition: 'rear',
                        isSteerable: false,
                        leftTire: new Tire({ axle: 1, position: 'left' }),
                        rightTire: new Tire({ axle: 1, position: 'right' }),
                    }),
                ];
                this.selectNumberOfAxles(this.report.car.axles.length);
                this.selectNumberOfPoweredAxles(0);
                break;
            case 'semiTrailer':
                this.report.car.axles = [
                    new Axle({
                        axleNumber: 1,
                        axlePosition: 'rear',
                        isSteerable: false,
                        leftTire: new Tire({ axle: 1, position: 'left' }),
                        rightTire: new Tire({ axle: 1, position: 'right' }),
                    }),
                    new Axle({
                        axleNumber: 2,
                        axlePosition: 'rear',
                        isSteerable: false,
                        leftTire: new Tire({ axle: 2, position: 'left' }),
                        rightTire: new Tire({ axle: 2, position: 'right' }),
                    }),
                    new Axle({
                        axleNumber: 3,
                        axlePosition: 'rear',
                        isSteerable: false,
                        leftTire: new Tire({ axle: 3, position: 'left' }),
                        rightTire: new Tire({ axle: 3, position: 'right' }),
                    }),
                ];
                this.selectNumberOfAxles(this.report.car.axles.length);
                this.selectNumberOfPoweredAxles(0);
                break;
            case 'bus':
                this.report.car.axles = [
                    new Axle({
                        axleNumber: 1,
                        axlePosition: 'front',
                        isSteerable: true,
                        leftTire: new Tire({ axle: 1, position: 'left' }),
                        rightTire: new Tire({ axle: 1, position: 'right' }),
                    }),
                    new Axle({
                        axleNumber: 2,
                        axlePosition: 'rear',
                        isSteerable: false,
                        leftTire: new Tire({ axle: 2, position: 'left' }),
                        rightTire: new Tire({ axle: 2, position: 'right' }),
                    }),
                ];
                this.selectNumberOfAxles(this.report.car.axles.length);
                this.selectNumberOfPoweredAxles(1);
                break;

            default: {
                // only reset the axles if necessary. From convertible to sedan, the user will most likely keep the tire info.
                if (this.report.car.axles.length !== 2 || this.report.car.axles[0]?.centerTire) {
                    this.report.car.axles = [
                        new Axle({
                            axleNumber: 1,
                            axlePosition: 'front',
                            isSteerable: true,
                            leftTire: new Tire({ axle: 1, position: 'left' }),
                            rightTire: new Tire({ axle: 1, position: 'right' }),
                        }),
                        new Axle({
                            axleNumber: 2,
                            axlePosition: 'rear',
                            isSteerable: false,
                            leftTire: new Tire({ axle: 2, position: 'left' }),
                            rightTire: new Tire({ axle: 2, position: 'right' }),
                        }),
                    ];
                }
                if (this.report.car.numberOfAxles !== 2) {
                    this.selectNumberOfAxles(2);
                    this.selectNumberOfPoweredAxles(1);
                }
                if (this.report.valuation.oldtimerValuationGrades) {
                    const defaultGrades = new OldtimerValuationGrades();
                    this.report.valuation.oldtimerValuationGrades.cabin =
                        this.report.valuation.oldtimerValuationGrades.cabin || defaultGrades.cabin;
                    this.report.valuation.oldtimerValuationGrades.engineCompartment =
                        this.report.valuation.oldtimerValuationGrades.engineCompartment ||
                        defaultGrades.engineCompartment;
                    this.report.valuation.oldtimerValuationGrades.trunk =
                        this.report.valuation.oldtimerValuationGrades.trunk || defaultGrades.trunk;
                }
            }
        }

        /**
         * In case of trailer, remove unnecessary properties.
         */
        if (!this.doesVehicleHaveMotor()) {
            // Motor
            this.report.car.runsOnGasoline = false;
            this.report.car.runsOnDiesel = false;
            this.report.car.runsOnLPG = false;
            this.report.car.runsOnNaturalGasoline = false;
            this.report.car.runsOnElectricity = false;
            this.report.car.runsOnBiodiesel = false;
            this.report.car.runsOnHydrogen = false;
            this.report.car.runsOnSomethingElse = null;
            this.report.car.engineDisplacement = null;
            this.report.car.performanceKW = null;
            this.performanceHP = null; // Local to the component
            // Gearbox
            this.report.car.numberOfGears = null;
            this.report.car.gearboxModelName = null;
            this.report.car.gearboxType = null;
            // Driven Axles
            this.report.car.numberOfPoweredAxles = null;
        }

        if (!this.doesVehicleHaveSeats(carShape)) {
            // Seats
            this.report.car.numberOfSeats = null;
        }

        /**
         * If it's a utility vehicle, automatically activate the provisioning costs.
         * Only relevant in case of a damage report such as liability. Not relevant for valuations.
         */
        if (this.report.damageCalculation) {
            this.report.damageCalculation.useProvisioningCosts = isUtilityVehicle(carShape);
        }

        this.saveReport();
    }

    public selectCarShapeFromList(carShape: CarShapeForUI): void {
        // Assign the car shape to the only available spot. Pushing it into the array would grow the array indefinitely if the
        // user clicks on the list more than once.
        this.carShapesForUI[5] = carShape;
        this.selectCarShape(carShape.id);
    }

    public doesVehicleHaveMotor() {
        return doesVehicleShapeHaveEngine(this.report.car.shape);
    }

    public doesVehicleHaveSeats(carShape: Car['shape']): boolean {
        return !new Array<CarShape>(
            'bicycle',
            'e-bike',
            'pedelec',
            'trailer',
            'semiTrailer',
            'caravanTrailer',
        ).includes(carShape);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Shapes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Axles
    //****************************************************************************/
    public selectNumberOfAxles(targetNumberOfAxles: number) {
        if (this.isReportLocked()) return;

        // If the user tries to set the number of axles below the ones powered, reduce no. of powered axles.
        if (targetNumberOfAxles < this.report.car.numberOfPoweredAxles) {
            this.report.car.numberOfPoweredAxles = targetNumberOfAxles;
        }
        this.report.car.numberOfAxles = targetNumberOfAxles;

        // Remove axles if needed
        while (targetNumberOfAxles < this.report.car.axles.length) {
            this.report.car.axles.pop();
        }

        let numberOfAxlesToAdd = targetNumberOfAxles - this.report.car.axles.length;

        const includeSecondTireSet = this.report.car.hasSecondTireSet;
        // Add axles if needed
        while (numberOfAxlesToAdd > 0) {
            let axleToInsert: Axle;

            if (['motorcycle', 'pedelec', 'bicycle', 'e-bike'].includes(this.report.car.shape)) {
                axleToInsert = new Axle({
                    axleNumber: this.report.car.axles.length + 1, // One-indexed.
                    axlePosition: this.report.car.axles.length === 0 ? 'front' : 'rear',
                    axleLoad: null,
                    isSteerable: this.report.car.axles.length === 0, // Make only the first axle steerable.
                    centerTire: new Tire({
                        axle: 1,
                        position: 'center',
                        secondTireSet: includeSecondTireSet ? new SecondTire() : null,
                    }),
                });
            }
            if (
                this.report.car.shape === 'truck' ||
                this.report.car.shape === 'semiTruck' ||
                this.report.car.shape === 'semiTrailer' ||
                this.report.car.shape === 'bus'
            ) {
                axleToInsert = new Axle({
                    axleNumber: this.report.car.axles.length + 1, // One-indexed.
                    axlePosition: this.report.car.axles.length === 0 ? 'front' : 'rear',
                    axleLoad: null,
                    isSteerable: this.report.car.axles.length === 0, // Make only the first axle steerable.
                    outerLeftTire: new Tire({
                        axle: 1,
                        position: 'outerLeft',
                        secondTireSet: includeSecondTireSet ? new SecondTire() : null,
                    }),
                    leftTire: new Tire({
                        axle: 1,
                        position: 'left',
                        secondTireSet: includeSecondTireSet ? new SecondTire() : null,
                    }),
                    outerRightTire: new Tire({
                        axle: 2,
                        position: 'outerRight',
                        secondTireSet: includeSecondTireSet ? new SecondTire() : null,
                    }),
                    rightTire: new Tire({
                        axle: 2,
                        position: 'right',
                        secondTireSet: includeSecondTireSet ? new SecondTire() : null,
                    }),
                });
            } else {
                axleToInsert = new Axle({
                    axleNumber: this.report.car.axles.length + 1, // One-indexed.
                    axlePosition: this.report.car.axles.length === 0 ? 'front' : 'rear',
                    axleLoad: null,
                    isSteerable: this.report.car.axles.length === 0, // Make only the first axle steerable.
                    leftTire: new Tire({
                        axle: 1,
                        position: 'left',
                        secondTireSet: includeSecondTireSet ? new SecondTire() : null,
                    }),
                    rightTire: new Tire({
                        axle: 1,
                        position: 'right',
                        secondTireSet: includeSecondTireSet ? new SecondTire() : null,
                    }),
                });
            }

            this.report.car.axles.push(axleToInsert);
            // tire.axleNumber is not zero-based but one-based
            setTireAxle(axleToInsert, this.report.car.axles.indexOf(axleToInsert) + 1);

            /**
             * For buses, the third axle is mostly steerable.
             */
            if (this.report.car.shape === 'bus' && this.report.car.axles.indexOf(axleToInsert) === 2) {
                axleToInsert.isSteerable = true;
            }

            --numberOfAxlesToAdd;
        }
    }

    /**
     * Select number of powered axles programmatically.
     * @param numberOfPoweredAxles
     */
    public selectNumberOfPoweredAxles(numberOfPoweredAxles: number): void {
        if (this.isReportLocked()) return;

        if (numberOfPoweredAxles <= this.report.car.numberOfAxles) {
            this.report.car.numberOfPoweredAxles = numberOfPoweredAxles;
        }
        return;
    }

    public toggleInputForCustomNumberOfAxles(): void {
        this.showInputForCustomNumberOfAxles = !this.showInputForCustomNumberOfAxles;
    }

    public toggleInputForCustomNumberOfPoweredAxles(): void {
        this.showInputForCustomNumberOfPoweredAxles = !this.showInputForCustomNumberOfPoweredAxles;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Axles
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Doors
    //****************************************************************************/
    public selectNumberOfDoors(numberOfDoors: number): void {
        this.report.car.numberOfDoors = numberOfDoors;
        this.saveReport();
    }

    public toggleInputForCustomNumberOfDoors(): void {
        this.showInputForCustomNumberOfDoors = !this.showInputForCustomNumberOfDoors;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Doors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Seats
    //****************************************************************************/
    public selectNumberOfSeats(numberOfSeats: number) {
        this.report.car.numberOfSeats = numberOfSeats;
        this.saveReport();
    }

    /**
     * Show or hide the input field for the number of previous owners.
     */
    public toggleInputForCustomNumberOfSeats(): void {
        this.showInputForCustomNumberOfSeats = !this.showInputForCustomNumberOfSeats;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Seats
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Car Properties
    //****************************************************************************/

    /**
     * Custom car properties are only shown if the user has already entered at least one custom car property.
     * On bicycle and pedelec, they are shown per default and stayvisible even if a user removes all custom car properties..
     */
    showCustomCarPropertyInputs() {
        const shapesWithCustomProperties = ['bicycle', 'pedelec'];
        return (
            this.report.car.customProperties?.length > 0 || shapesWithCustomProperties.includes(this.report.car.shape)
        );
    }

    /**
     * Add a new custom car property.
     */
    addCustomCarProperty() {
        this.report.car.customProperties.push(new CustomCarProperty());
    }

    addBicycleAndPedelecDefaultCustomCarProperties() {
        // If customProperties is not an array, create it.
        if (!this.report.car.customProperties) {
            this.report.car.customProperties = [];
        }

        // Do not add defaults if the user has already entered custom car properties.
        if (this.report.car.customProperties.length > 0) return;

        defaultCustomCarPropertiesForBicycles.forEach((defaultCustomCarProperty) => {
            if (!defaultCustomCarProperty.addAsDefault) return;

            this.report.car.customProperties.push(new CustomCarProperty({ title: defaultCustomCarProperty.title }));
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Car Properties
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Access Rights
    //****************************************************************************/
    public userIsAdmin(): boolean {
        return isAdmin(this.user?._id, this.team);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Access Rights
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab: 'carData',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save Methods
    //****************************************************************************/

    public async saveReport({ waitForServer }: { waitForServer?: true } = {}): Promise<Report> {
        if (this.isReportLocked()) return;

        try {
            await this.reportDetailsService.patch(this.report, { waitForServer });
        } catch (error) {
            console.error('An error occurred while saving the report. ', { error });
        }
    }

    protected async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Methods
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Responsive Design
    //****************************************************************************/
    public isSmallScreen(): boolean {
        return isSmallScreen();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Responsive Design
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Handlers
    //****************************************************************************/

    /**
     * Get the function to trigger a click event on pressing the space bar from the
     * shared module.
     *
     * @type {(event:KeyboardEvent)=>void}
     */
    public triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Handler
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });

        this.closeToastsOnDestroy.forEach((toast) => {
            this.toastService.remove(toast.id);
        });
    }

    protected readonly hasAccessRight = hasAccessRight;
}

interface CarShapeForUI {
    id: CarShape;
    image: string;
    title: string;
    tooltip?: string;
}

export interface DatIdentificationResponsePerVehicle {
    // Contains only the data present in the VIN. The car has data like the current amount of kilometers on the tachometer. That could not be saved in the VIN.
    newCarInformation: Car;
    // Contains every property but the _id property.
    carEquipment: CarEquipment;
    datECodeEquipmentPositions?: CarEquipmentPosition[];
    specialEquipmentManufacturerCodes: number[];
    paintTypes: any;
}

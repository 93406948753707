import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { OldtimerValuationGrades } from '@autoixpert/models/reports/market-value/oldimter-valuation-grades';
import { slideOutDialogVertical } from '../../../../shared/animations/slide-out-dialog-vertical.animation';
import {
    SchoolGrade,
    translatePointGradeToSchoolGrade,
    translateSchoolGradeToPointGrade,
} from '../../../../shared/libraries/translate-school-grade-to-point-grade';
import { gradeDescriptions } from './grade-descriptions';

@Component({
    selector: 'grade-selector',
    templateUrl: 'grade-selector.component.html',
    styleUrls: ['grade-selector.component.scss'],
    animations: [
        trigger('staggeredFadeIn', [
            transition('void => *', [
                // Query all elements within the trigger-bearer
                query(':enter', [
                    style({
                        transform: 'translateX(-20px)',
                        opacity: 0,
                    }),
                    // For all children, delay animation
                    stagger(30, [
                        animate(
                            '200ms ease',
                            style({
                                transform: 'translateX(0)',
                                opacity: 1,
                            }),
                        ),
                    ]),
                ]),
            ]),
        ]),
        slideOutDialogVertical(),
    ],
})
export class GradeSelectorComponent {
    @Input() grade: number; // Number between 1 and 14 (inclusive)
    @Input() category: keyof OldtimerValuationGrades;
    @Input() disabled: boolean = false;

    @Output() gradeChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    public gradeSelectorDialogShown: boolean = false;
    public gradeInfoShown: boolean = false;
    public gradeDescriptions: string[] = [];

    public heading: string;

    ngOnInit() {
        this.setupHeading();
        this.setupGradeDescriptions();
    }

    //*****************************************************************************
    //  Grade Translation
    //****************************************************************************/

    public getSchoolgrade(): SchoolGrade {
        return translatePointGradeToSchoolGrade(this.grade);
    }

    public setSchoolGrade(schoolGrade: string): void {
        if (schoolGrade === '1+' || schoolGrade === '5-') return;
        this.grade = translateSchoolGradeToPointGrade(schoolGrade as SchoolGrade);
        this.emitGradeChange();
        // Allow Angular to update the view before closing the selector
        setTimeout(() => {
            this.closeGradeSelector();
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Grade Translation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Selector Dialog
    //****************************************************************************/
    public openGradeSelectorDialog(event: MouseEvent): void {
        this.gradeSelectorDialogShown = true;
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeGradeSelector();
        }
    }

    public closeGradeSelector(): void {
        this.gradeSelectorDialogShown = false;
    }

    public setupHeading(): void {
        switch (this.category) {
            case 'total':
                this.heading = 'Gesamtzustand';
                break;
            case 'body':
                this.heading = 'Blech';
                break;
            case 'paint':
                this.heading = 'Lack';
                break;
            case 'chrome':
                this.heading = 'Chrom-/Zierteile';
                break;
            case 'rubber':
                this.heading = 'Dichtungen/Gummiteile';
                break;
            case 'glass':
                this.heading = 'Glas';
                break;
            case 'tires':
                this.heading = 'Reifen';
                break;
            case 'cabin':
                this.heading = 'Fahrgastraum';
                break;
            case 'engineCompartment':
                this.heading = 'Motorraum';
                break;
            case 'engine':
                this.heading = 'Motor';
                break;
            case 'trunk':
                this.heading = 'Kofferraum';
                break;
        }
    }

    //*****************************************************************************
    //  Grade Info
    //****************************************************************************/
    public openGradeInfo(): void {
        this.gradeInfoShown = true;
    }

    public hideGradeInfo(): void {
        this.gradeInfoShown = false;
    }

    public setupGradeDescriptions(): void {
        switch (this.category) {
            case 'total':
            case 'body':
            case 'paint':
            case 'chrome':
            case 'rubber':
            case 'glass':
            case 'tires':
            case 'cabin':
            case 'engineCompartment':
            case 'engine':
            case 'trunk':
                this.gradeDescriptions = gradeDescriptions[this.category];
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Grade Info
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Selector Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitGradeChange(): void {
        this.gradeChange.emit(this.grade);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public closeDialogOnEnter(event: KeyboardEvent) {
        if (!this.gradeSelectorDialogShown) return;

        if (event.key === 'Escape') {
            this.closeGradeSelector();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Dialog Animation
    //****************************************************************************/
    @HostBinding('@slideOutDialogVertical') get slideOut() {
        return true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog Animation
    /////////////////////////////////////////////////////////////////////////////*/
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { ResidualValueInvitationReceipt } from '@autoixpert/models/reports/residual-value/residual-value-invitation-receipt';
import { ResidualValueInvitation } from '@autoixpert/models/reports/residual-value/residual-value-request';

@Injectable({
    providedIn: 'root',
})
export class ResidualValueInvitationService {
    constructor(private httpClient: HttpClient) {}

    public find(reportId: string): Promise<ResidualValueInvitation[]> {
        return this.httpClient
            .get<ResidualValueInvitation[]>(`/api/v0/reports/${reportId}/residualValueInvitations`)
            .toPromise();
    }

    public remove(reportId: string, invitationId: string): Promise<{ success: boolean }> {
        return this.httpClient
            .delete<{ success: boolean }>(`/api/v0/reports/${reportId}/residualValueInvitations/${invitationId}`)
            .toPromise();
    }

    public sendInvitations(reportId: string): Promise<ResidualValueInvitationsCreateResponse> {
        return this.httpClient
            .post<ResidualValueInvitationsCreateResponse>(`/api/v0/reports/${reportId}/residualValueInvitations`, {})
            .toPromise();
    }
}

export interface ResidualValueInvitationsCreateResponse {
    newInvitationReceipts: ResidualValueInvitationReceipt[];
    smtpErrors: AxError[];
}

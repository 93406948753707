import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * A small bubble with a label + icon that is displayed inside the currency-input component (right).
 * It can be used to display a readonly value inside the input field (e.g. for the replacement value correction).
 * It can be placed within the tags of the currency-input and will be projected using ng-content.
 */
@Component({
    selector: 'currency-input-display-value',
    templateUrl: './currency-input-display-value.component.html',
    styleUrls: ['./currency-input-display-value.component.scss'],
})
export class CurrencyInputDisplayValueComponent {
    @Input() label: string = '';
    @Input() iconName: string = 'edit';
    @Output() iconWasClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() wasDoubleClicked: EventEmitter<void> = new EventEmitter<void>();

    @HostListener('dblclick') public doubleClickListener(): void {
        this.wasDoubleClicked.emit();
    }
}

<div class="correction-item-grab-handle">
    <ng-content />
</div>

<autocomplete-with-memory
    #descriptionInput
    class="correction-item-description"
    placeholder="Beschreibung"
    [(ngModel)]="correctionItem.description"
    (change)="emitChanges()"
    [disabled]="isReportLocked"
    floatLabel="never"
    [useTextarea]="true"
    autocompleteEntryType="replacementValueDecreaseDescription"></autocomplete-with-memory>

<div *ngIf="repairExecutionTypeShown" class="repair-execution-type">
    <div class="selection-bar-label">Repariert?</div>
    <div class="selection-bar">
        <button
            class="selection-bar-item"
            (click)="handleRepairExecutionTypeChange('repaired')"
            [class.selected]="correctionItem.repairExecutionType === 'repaired'"
            matTooltip="Repariert"
            tabindex="0"
            type="button">
            <mat-icon class="small-icon">check</mat-icon>
        </button>
        <button
            class="selection-bar-item selection-color-grey"
            (click)="handleRepairExecutionTypeChange('partial')"
            [class.selected]="correctionItem.repairExecutionType === 'partial'"
            matTooltip="Teilweise repariert"
            tabindex="0"
            type="button">
            <mat-icon class="small-icon">contrast</mat-icon>
        </button>
        <button
            class="selection-bar-item selection-color-orange"
            (click)="handleRepairExecutionTypeChange('unprofessional')"
            [class.selected]="correctionItem.repairExecutionType === 'unprofessional'"
            matTooltip="Nicht sach- & fachgerecht repariert"
            tabindex="0"
            type="button">
            <mat-icon class="small-icon">emergency_home</mat-icon>
        </button>
        <button
            class="selection-bar-item selection-color-red"
            (click)="handleRepairExecutionTypeChange('none')"
            [class.selected]="correctionItem.repairExecutionType === 'none'"
            matTooltip="Unrepariert"
            tabindex="0"
            type="button">
            <mat-icon class="small-icon">block</mat-icon>
        </button>
    </div>
</div>

<div class="correction-item-value">
    <currency-input
        placeholder="Betrag"
        floatLabel="never"
        [(value)]="correctionItem.value"
        [baseValue]="report.valuation.vehicleBaseValue"
        [tooltip]="
            report.type === 'valuation'
                ? 'Gib einen positiven Betrag ein.'
                : 'Gib einen fixen positiven Wert (z. B. 500 €) oder einen relativen Wert (z. B. 50%) ein.'
        "
        [preventNegativeNumbers]="true"
        (valueChange)="emitChanges()"
        [disabled]="isReportLocked"
        (keydown.tab)="handleTab()"></currency-input>
</div>
<mat-icon
    class="correction-item-delete-icon"
    matTooltip="Position löschen"
    (click)="removeCorrectionItem()"
    *ngIf="!isReportLocked">
    delete
</mat-icon>

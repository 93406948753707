<mat-form-field class="mat-block" floatLabel="never">
    <mat-quill
        #descriptionInput
        placeholder="Positionstitel"
        [matAutocomplete]="autocomplete"
        [(ngModel)]="value"
        (ngModelChange)="filterAutocomplete(); emitNgModelChange()"
        (change)="emitChange()"
        (focus)="filterAutocomplete()"
        (blur)="emitBlur()"></mat-quill>
</mat-form-field>
<mat-autocomplete #autocomplete>
    <mat-option
        *ngFor="let invoiceLineItemTemplate of filteredAutocompleteEntries"
        class="line-item-option"
        [value]="invoiceLineItemTemplate.description"
        (onSelectionChange)="emitLineItemTemplateSelected(invoiceLineItemTemplate)">
        <div class="line-item-title">
            {{ invoiceLineItemTemplate.description | htmlToPlaintext }}
            <br />
            <span class="label">
                {{ invoiceLineItemTemplate.quantity }} {{ invoiceLineItemTemplate.unit }} à
                {{ invoiceLineItemTemplate.unitPrice | currency: 'EUR' }}
            </span>
        </div>
        <mat-icon
            class="line-item-delete"
            *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
            (click)="forgetLineItemTemplate(invoiceLineItemTemplate); $event.stopPropagation()">
            delete
        </mat-icon>
    </mat-option>
</mat-autocomplete>

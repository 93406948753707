import { FabricImage, FabricObject } from 'fabric';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { TeamPreferences } from '@autoixpert/models/teams/team-preferences';
import { getDefaultFilters } from '../../../../reports/details/photos/photo-editor/photo-editor.filters';
import { getWatermarkTextOrImage } from './get-watermark-text-or-image';

export async function addWatermarkToAllPhotos({
    watermarkImageUrl,
    watermarkPreferences,
    photos,
}: {
    watermarkImageUrl: string;
    watermarkPreferences: TeamPreferences['watermark'];
    photos: Photo[];
}) {
    // Add the watermark on all photos.
    for (const photo of photos) {
        await addWatermarkToPhoto({
            watermarkImageUrl,
            watermarkPreferences,
            photo,
        });
    }
}

export async function addWatermarkToPhoto({
    watermarkImageUrl,
    watermarkPreferences,
    photo,
}: {
    watermarkImageUrl: string;
    watermarkPreferences: TeamPreferences['watermark'];
    photo: Photo;
}) {
    // Add the watermark on all included versions.
    for (const version in photo.versions) {
        if (!photo.versions.hasOwnProperty(version) || !photo.versions[version].included) {
            continue;
        }

        //*****************************************************************************
        //  Add fabric.js Info Object if it does not Exist
        //****************************************************************************/
        if (!photo.versions[version].fabricJsInformation) {
            const image = new FabricImage(new Image(), {
                data: {
                    axType: 'photo',
                },
                height: photo.height,
                width: photo.width,
            });

            photo.versions[version].fabricJsInformation = {
                version: '2.0.0-rc.1',
                axFilters: getDefaultFilters(),
                objects: [image.toObject(['data'])],
            };
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Add fabric.js Info Object if it does not Exist
        /////////////////////////////////////////////////////////////////////////////*/

        // Remove existing watermark, if there is one.
        const existingWatermark: FabricObject = photo.versions[version].fabricJsInformation.objects.find((object) =>
            ['watermarkImage', 'watermarkText'].includes(object.data?.axType),
        );
        if (existingWatermark) {
            photo.versions[version].fabricJsInformation.objects.splice(
                photo.versions[version].fabricJsInformation.objects.indexOf(existingWatermark),
                1,
            );
        }

        const fabricPhoto = photo.versions[version].fabricJsInformation.objects.find(
            (object) => object.data?.axType === 'photo',
        );
        // Add new watermark
        const watermark = await getWatermarkTextOrImage({
            watermarkImageUrl,
            watermarkPreferences,
            canvasHeight: photo.height * fabricPhoto.scaleY,
            canvasWidth: photo.width * fabricPhoto.scaleX,
        });
        photo.versions[version].fabricJsInformation.objects.push(watermark.toObject(['data']));
    }
}

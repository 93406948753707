import { Component, Input } from '@angular/core';
import { isDocumentBuildingBlockConditionGroup } from '@autoixpert/lib/document-building-blocks/is-document-building-block-condition-group';
import { PlaceholderValueTree } from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { DocumentBuildingBlockCondition } from '@autoixpert/models/documents/document-building-block-condition';
import { DocumentBuildingBlockConditionGroup } from '@autoixpert/models/documents/document-building-block-condition-group';
import { getTypeAtPropertyPath } from 'src/app/shared/libraries/document-building-blocks/get-type-at-property-path';
import { trackById } from 'src/app/shared/libraries/track-by-id';

/**
 * Component to preview a document building block condition or condition group.
 * This component is necessary due to the recursiveness of conditions (a condition can contain conditions if it is a condition group)
 * The recursion depth is 1, so this component is only used to preview the first level of conditions.
 * The second level of conditions is summarized with a count of sub-conditions.
 */
@Component({
    selector: 'document-building-block-condition-preview',
    templateUrl: './document-building-block-condition-preview.component.html',
    styleUrls: ['./document-building-block-condition-preview.component.scss'],
})
export class DocumentBuildingBlockConditionPreviewComponent {
    public readonly isDocumentBuildingBlockConditionGroup = isDocumentBuildingBlockConditionGroup;
    public readonly trackById = trackById;

    @Input() placeholderValueTree: PlaceholderValueTree;
    @Input() buildingBlockConditionsPreview: boolean = false;
    @Input() condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup;

    public getTypeAtPropertyPath(propertyPath: string) {
        return getTypeAtPropertyPath(propertyPath, this.placeholderValueTree);
    }

    public getNumSubConditionGroups(condition: DocumentBuildingBlockConditionGroup): number {
        return condition.conditions.filter((condition) => isDocumentBuildingBlockConditionGroup(condition)).length;
    }
}

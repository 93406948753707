import { unescapeXml } from '@autoixpert/lib/placeholder-values/escape-xml';

/**
 * Removes HTML element tags, so that only their inner text remains. Converts special HTML characters to their unicode representation (e.g. &amp; to &).
 * @param input
 */
export function stripHtml(input: string): string {
    if (!input) {
        return input;
    }
    // unescapeXml = Convert "&amp;" back to "&". Relevant for email body etc.
    return unescapeXml(input.replace(/<[^>]+>/gi, '')).trim();
}

/**
 * Removes HTML elements from a string and replaces line break and paragraph elements (<br> and <p>) with \n.
 * @param input
 * @param isEmailHtml
 */
export function convertHtmlToPlainText(
    input: string,
    {
        replaceParagraphsWithSingleNewLine,
    }: {
        replaceParagraphsWithSingleNewLine?: boolean;
    } = {},
): string {
    if (!input) {
        return input;
    }
    // Convert line breaks from Quill to plain text.
    const lineBreaksReplaced = input
        /**
         * Convert empty paragraphs (Quill: <p><br></p> or <p class="ql-justified"><br></p>) or paragraph endings (regular </p> after a paragraph with text)
         * to two line breaks to resemble a paragraph.
         * In case of an email, autoiXpert converts a regular Enter and a Shift+Enter to a regular line break. This resembles the way Gmail or GMX treat line breaks
         * and that feels pretty natural to the user.
         */
        .replace(/(<p[^>]*><br>)?<\/p>/g, replaceParagraphsWithSingleNewLine ? '\n' : '\n\n')
        /**
         * All other breaks should be converted to regular line breaks. We added Shift+Enter as a regular break with a class to quill.
         * Those <br> tags will be caught here, too.
         */
        .replace(/<br[^>]*>/g, '\n');

    // Remove everything but line breaks, e.g. bold or italic type.
    return stripHtml(lineBreaksReplaced).trim();
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { ReportsByMonthAnalyticsRecord } from '@autoixpert/models/analytics/reports-analytics.model';
import { RevenueByMonthAnalyticsRecord } from '@autoixpert/models/analytics/revenue-analytics.model';
import { AnalyticsFilterComponent } from '../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { numberFormatter } from '../../shared/libraries/number-formatter';
import { ReportsAnalyticsService } from '../../shared/services/analytics/reports-analytics.service';
import { RevenueAnalyticsService } from '../../shared/services/analytics/revenue-analytics.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { AnalyticsSettingsMenuComponent } from '../analytics-settings-menu/analytics-settings-menu.component';
import { negativeColor, neutralColor, seriesDimension1, setDefaultChartOptions } from '../default-chart-options';

@Component({
    selector: 'revenue-by-month',
    templateUrl: './revenue-by-month.component.html',
    styleUrls: ['./revenue-by-month.component.scss'],
})
export class RevenueByMonthComponent implements OnInit {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;
    @ViewChild(AnalyticsSettingsMenuComponent, { static: true }) protected settings: AnalyticsSettingsMenuComponent;

    constructor(
        private revenueByMonthService: RevenueAnalyticsService,
        private reportsByMonthService: ReportsAnalyticsService,
        private apiErrorService: ApiErrorService,
        private router: Router,
    ) {}

    public chartTitle: string = 'Umsatz pro Monat';
    private chart: ApexCharts;
    private chartOptions: apexcharts.ApexOptions;
    public analyticsPending: boolean = false;

    public totalRevenue: number = 0;
    public outstandingRevenue: number = 0;
    public totalNumberOfReports: number = 0;
    public totalNumberOfInvoices: number = 0;

    public records: RevenueByMonthAndReportsAnalyticsRecord[] = [];
    public recordsForList: RevenueByMonthAndReportsAnalyticsRecord[] = [];

    public filterAnalyticsFrom: string;
    public filterAnalyticsTo: string;

    ngOnInit() {
        setDefaultChartOptions();

        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateBackToAnalyticsList(): void {
        this.router.navigate(['/Auswertungen']);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    public rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/
    public refreshLocalData(): void {
        this.calculateTotalRevenue();
        this.calculateOutstandingRevenue();
        this.calculateTotalNumberOfReports();
        this.calculateTotalNumberOfInvoices();
        this.drawChart();
    }

    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.records.length) return;
        const revenue: number[] = this.records.map((record) =>
            this.settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross,
        );
        const outstandingRevenue: number[] = this.records.map((record) =>
            this.settings.useNetValues ? record.outstandingRevenueNet : record.outstandingRevenueGross,
        );

        this.chartOptions = {
            chart: {
                type: 'line',
            },
            colors: [seriesDimension1, negativeColor, neutralColor],
            series: [
                {
                    name: 'Umsatz',
                    data: revenue,
                    type: 'column',
                },
                {
                    name: 'Davon noch offen',
                    data: outstandingRevenue,
                    type: 'column',
                },
                {
                    name: 'Gutachten',
                    data: this.records.map((record) => record.numberOfReports),
                    type: 'line',
                },
            ],
            xaxis: {
                categories: this.records.map((record) => record.month),
            },
            yaxis: [
                {
                    seriesName: 'Umsatz',
                    labels: {
                        show: false,
                        formatter: currencyFormatterEuro,
                    },
                    show: false,
                    max: Math.max(...revenue, ...outstandingRevenue),
                },
                {
                    seriesName: 'Davon noch offen',
                    labels: {
                        show: false,
                        formatter: currencyFormatterEuro,
                    },
                    show: false,
                    max: Math.max(...revenue, ...outstandingRevenue),
                },
                {
                    seriesName: 'Gutachten',
                    labels: {
                        formatter: numberFormatter,
                    },
                    show: false,
                },
            ],
            stroke: {
                show: true,
                width: [0, 0, 2],
                colors: [seriesDimension1, negativeColor, neutralColor],
                curve: 'straight',
            },
            fill: {
                opacity: 1,
            },
        };

        // Clear before re-drawing
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
        this.chart.render();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public calculateTotalRevenue(): void {
        this.totalRevenue = this.records.reduce(
            (total, currentItem) =>
                total + (this.settings.useNetValues ? currentItem.totalRevenueNet : currentItem.totalRevenueGross),
            0,
        );
    }

    public calculateOutstandingRevenue(): void {
        this.outstandingRevenue = this.records.reduce(
            (total, currentItem) =>
                total +
                (this.settings.useNetValues ? currentItem.outstandingRevenueNet : currentItem.outstandingRevenueGross),
            0,
        );
    }

    public calculateTotalNumberOfReports(): void {
        this.totalNumberOfReports = this.records.reduce((total, currentItem) => total + currentItem.numberOfReports, 0);
    }

    public calculateTotalNumberOfInvoices(): void {
        this.totalNumberOfInvoices = this.records.reduce(
            (total, currentItem) => total + currentItem.idsOfRegularInvoices.length,
            0,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    public updateAnalytics(): void {
        this.analyticsPending = true;

        const revenueByMonth$ = this.revenueByMonthService.getRevenueByMonth({
            from: this.filterAnalyticsFrom,
            to: this.filterAnalyticsTo,
            officeLocationIds: this.filter.officeLocationIds,
            assessorIds: this.filter.assessorIds,
            reportLabelIds: this.filter.reportLabelConfigIds,
            invoiceLabelIds: this.filter.invoiceLabelConfigIds,
        });

        const numberOfReports$ = this.reportsByMonthService.getReportsByMonth({
            from: this.filterAnalyticsFrom,
            to: this.filterAnalyticsTo,
            officeLocationIds: this.filter.officeLocationIds,
            assessorIds: this.filter.assessorIds,
            reportLabelIds: this.filter.reportLabelConfigIds,
            // Don't filter the number of reports by invoice label
        });

        forkJoin([revenueByMonth$, numberOfReports$]).subscribe({
            next: ([revenueByMonth, reportsByMonth]) => {
                this.records = [];
                for (const revenueForOneMonth of revenueByMonth) {
                    // Get the matching number of reports for an assessor. The order may vary between the two.
                    const matchingRevenueByMonthRecord: ReportsByMonthAnalyticsRecord = reportsByMonth.find(
                        (record) => record.month === revenueForOneMonth.month,
                    );
                    const numberOfReports: number = matchingRevenueByMonthRecord
                        ? matchingRevenueByMonthRecord.numberOfReports
                        : 0;

                    // Merge number of reports with revenue data.
                    this.records.push({
                        month: revenueForOneMonth.month,
                        numberOfReports,
                        totalRevenueNet: revenueForOneMonth.totalRevenueNet,
                        totalRevenueGross: revenueForOneMonth.totalRevenueGross,
                        idsOfAllInvoices: revenueForOneMonth.idsOfAllInvoices,
                        idsOfRegularInvoices: revenueForOneMonth.idsOfRegularInvoices,
                        idsOfCreditNotes: revenueForOneMonth.idsOfCreditNotes,
                        outstandingRevenueNet: revenueForOneMonth.outstandingRevenueNet,
                        outstandingRevenueGross: revenueForOneMonth.outstandingRevenueGross,
                        idsOfOutstandingInvoices: revenueForOneMonth.idsOfOutstandingInvoices,
                    });
                }

                // Latest month goes to the top
                this.recordsForList = [...this.records];
                this.recordsForList.sort((recordA, recordB) => recordA.month.localeCompare(recordB.month) * -1);

                this.analyticsPending = false;
                this.calculateTotalRevenue();
                this.calculateOutstandingRevenue();
                this.calculateTotalNumberOfReports();
                this.calculateTotalNumberOfInvoices();
                setTimeout(() => {
                    this.drawChart();
                }, 0);
            },
            error: (error) => {
                this.analyticsPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ACCESS_DENIED: {
                            title: 'Zugriffsrecht fehlt',
                            body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                            forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                        },
                    },
                    defaultHandler: {
                        title: 'Fehler bei Auswertung',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface RevenueByMonthAndReportsAnalyticsRecord extends RevenueByMonthAnalyticsRecord {
    numberOfReports: number;
}

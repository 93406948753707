import { DateTime } from 'luxon';
import { Observable, Subject } from 'rxjs';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { determineNumberOfDowntimeCompensationClassReductionsAccordingToAge } from '@autoixpert/lib/damage-calculation-values/downtime-compensation/determine-number-of-downtime-compensation-class-reductions-according-to-age';
import { SourceAndTargetUploadedDocumentIds } from '@autoixpert/lib/documents/document-copy.types';
import { generateId } from '@autoixpert/lib/generate-id';
import { addReportTypeSpecificDocuments } from '@autoixpert/lib/reports/add-report-type-specific-documents';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AxError, UnprocessableEntity } from '@autoixpert/models/errors/ax-error';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { RepairCalculation } from '@autoixpert/models/reports/damage-description/repair';
import { InvoiceAudit } from '@autoixpert/models/reports/invoice-audit';
import { Report } from '@autoixpert/models/reports/report';
import { AutoixpertResidualValueOffer } from '@autoixpert/models/reports/residual-value/autoixpert-residual-value-offer';
import { AutoonlineResidualValueOffer } from '@autoixpert/models/reports/residual-value/autoonline-residual-value-offer';
import { ResidualValueOffer } from '@autoixpert/models/reports/residual-value/residual-value-offer';
import { ClaimantSignature } from '@autoixpert/models/signable-documents/claimant-signature';
import { SignableDocument } from '@autoixpert/models/signable-documents/signable-document';
import { damageReportTypes } from '@autoixpert/static-data/reports/report-type-groups';
import { getDatErrorHandlers } from '../../libraries/error-handlers/get-dat-error-handlers';
import { getCopyableCarProperties } from '../../libraries/get-copyable-car-properties';
import { getCommunicationRecipients } from '../../libraries/report-properties/get-communication-recipients';
import { getCalculationResultsForInvoiceAudit } from '../../libraries/report-properties/invoice-audit/get-calculation-results-for-invoice-audit';
import { isKaskoCase } from '../../libraries/report-properties/is-kasko-case';
import { ApiErrorHandler, ApiErrorHandlerCreator } from '../api-error.service';
import { CarEquipmentService } from '../car-equipment.service';
import { DocumentOrderConfigService } from '../document-order-config.service';
import { LoggedInUserService } from '../logged-in-user.service';
import { ReportService } from '../report.service';
import { CopyClaimantSignaturesToReportService } from './copy-claimant-signatures-to-report.service';
import { CopyDatDossierService } from './copy-dat-dossier.service';
import { CopyMarketAnalysisDocumentsToReportService } from './copy-market-analysis-documents-to-report.service';
import { CopyPhotosToReportService } from './copy-photos-to-report.service';
import { CopyResidualValueDocumentsToReportService } from './copy-residual-value-documents-to-report.service';
import { CopyUserUploadedDocumentsToReportService } from './copy-user-uploaded-documents-to-report.service';

export type CopyIntention = 'basicData' | 'custom' | 'amendment' | 'invoiceAudit';

export type CurrentStep =
    | 'reportObject'
    | 'photos'
    | 'calculation'
    | 'carEquipment'
    | 'savingReport'
    | 'residualValue'
    | 'marketAnalysis'
    | 'userUploadedDocuments';

export class CopySteps {
    constructor(template: Partial<CopySteps> = {}) {
        Object.assign(this, template);
    }
    PHOTOS = false;
    CLAIMANT_SIGNATURES = false;
    CALCULATION = false;
    RESIDUAL_VALUE_OFFERS = false;
    MARKET_ANALYSIS = false;
    DAMAGE_DESCRIPTION = false;
}

export class CopyOptions {
    constructor(template: Partial<CopyOptions> = {}) {
        Object.assign(this, template);
    }
    copySteps = new CopySteps();
    intention?: CopyIntention = 'basicData';

    activateInvoice: boolean = false;
    copyInvoiceNumber: boolean = false;
    invoiceNumberSuffix?: string = '';

    copyReportToken: boolean = false;
    reportTokenSuffix?: string = '';
}

/**
 * This class creates a report copy.
 *
 * Object oriented programming is used to keep the code structured.
 */
export class ReportCopy {
    constructor(
        private reportService: ReportService,
        private copyPhotosToReportService: CopyPhotosToReportService,
        private copyClaimantSignaturesToReportService: CopyClaimantSignaturesToReportService,
        private copyDatDossierService: CopyDatDossierService,
        private copyResidualValueDocumentsService: CopyResidualValueDocumentsToReportService,
        private copyMarketAnalysisDocumentsService: CopyMarketAnalysisDocumentsToReportService,
        private copyUserUploadedDocumentsService: CopyUserUploadedDocumentsToReportService,
        private carEquipmentService: CarEquipmentService,
        private loggedInUserService: LoggedInUserService,
        private documentOrderConfigService: DocumentOrderConfigService,
    ) {}

    // This is the report that is being copied.
    sourceReport: Report;
    // This is the result of the copy process.
    newReport: Report;
    // This is the empty report shell.
    // The shell is filled with data (copy basic data) or only the _id is used (copy custom data)
    emptyReport: Report;

    /**
     * Notify the UI about the current copy step to keep the app responding.
     */
    private currentStepSubject$ = new Subject<CurrentStep>();
    readonly currentStep$ = this.currentStepSubject$.asObservable();
    public listenOnChangedCounter(): Observable<CurrentStep> {
        return this.currentStep$;
    }

    options: CopyOptions = new CopyOptions();

    /**
     * This function is used to copy a report.
     * If copyOptions.intention is 'basicData', only the most basic data is copied (claimant, car).
     * Otherwise, the copySteps can be used to define which data should be copied.
     */
    public async copy(sourceReport: Report, copyOptions: CopyOptions) {
        this.sourceReport = sourceReport;
        this.options = copyOptions;

        await this.createEmptyReport();
        if (this.options.intention === 'basicData') {
            await this.copyBasicData();
        } else {
            this.copyAllData();
            await this.handlePhotos();
            await this.handleClaimantSignatures();
            await this.handleCalculation();
            await this.handleResidualValueOffers();
            await this.handleMarketAnalysis();
            await this.handleDamageDescription();
            await this.copyAllUserUploadedDocuments();
        }
        await this.copyCarEquipment();
        this.resetInvoice();
        this.resetEMails();

        if (this.options.intention === 'amendment') {
            this.newReport.originalReportId = this.sourceReport._id;
            this.configureInvoiceReportToken();
        }

        await this.saveReport();
        if (this.options.intention === 'amendment') {
            await this.linkAmendmentToOriginalReport();
        }

        this.currentStepSubject$.next(undefined);
        return this.newReport;
    }

    /**
     * Creates an amendment report.
     * The amendment report is linked to the source report.
     */
    public async createAmendmentReport(sourceReport: Report, copyOptions: CopyOptions) {
        copyOptions.intention = 'amendment';

        return await this.copy(sourceReport, copyOptions);
    }

    /**
     * Creates an invoice audit report.
     * The invoice audit report contains relevant data (claimant, insurance, car, accident, garage, photos) from the source report.
     * The calculation results are copied, not the calculation itself.
     */
    public async createInvoiceAudit(sourceReport: Report, copyOptions: CopyOptions) {
        copyOptions.intention = 'invoiceAudit';
        this.options = copyOptions;
        this.sourceReport = sourceReport;

        // Create an report as empty shell
        await this.createEmptyReport();

        // Copy the relevant data from the source report to the new report.
        await this.copyDataForInvoiceAudit();
        await this.copyCalculationResultsForInvoiceAudit();
        await this.handlePhotos();

        // Since reportType changes, other documents may apply.
        addReportTypeSpecificDocuments({
            report: this.newReport,
            user: this.loggedInUserService.getUser(),
            team: this.loggedInUserService.getTeam(),
            documentOrderConfigs: await this.documentOrderConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary(),
        });
        this.configureInvoiceReportToken();

        await this.saveReport();
        return this.newReport;
    }

    /**
     * copy:
     *  - claimant
     *  - insurance
     *  - accident data
     *  - visits
     *  - car, car equipment
     *  - garage
     */
    private async copyDataForInvoiceAudit() {
        this.newReport = this.emptyReport;
        this.newReport.type = 'invoiceAudit';
        this.newReport.invoiceAudit = new InvoiceAudit({ reportId: this.sourceReport._id });

        // Copy involved parties
        this.newReport.claimant = this.sourceReport.claimant;
        this.newReport.insurance = this.sourceReport.insurance;
        this.newReport.garage = this.sourceReport.garage;

        // Copy car & accident
        this.newReport.car = this.sourceReport.car;
        this.newReport.accident = this.sourceReport.accident;
        this.newReport.sourceOfTechnicalData = this.sourceReport.sourceOfTechnicalData;
        this.newReport.vinWasChecked = this.sourceReport.vinWasChecked;

        // Photos - these may be deleted later on
        this.newReport.photos = this.sourceReport.photos;

        // Copy report-based invoice number config and reset counter (if available)
        if (this.sourceReport.invoiceNumberConfig) {
            this.newReport.invoiceNumberConfig = this.sourceReport.invoiceNumberConfig;
            this.newReport.invoiceNumberConfig.count = 0;
        }
    }

    /**
     * The InvoiceAudit needs only the calculation results, not the calculation itself.
     */
    private async copyCalculationResultsForInvoiceAudit() {
        // Copy the sum of the damage calculation as projected repair costs
        const calculationResults = getCalculationResultsForInvoiceAudit(this.sourceReport);
        this.newReport.invoiceAudit.wages.projectedRepairCosts = calculationResults.wages;
        this.newReport.invoiceAudit.spareParts.projectedRepairCosts = calculationResults.spareParts;
        this.newReport.invoiceAudit.paint.projectedRepairCosts = calculationResults.paint;
        this.newReport.invoiceAudit.auxiliaryCosts.projectedRepairCosts = calculationResults.auxiliaryCosts;
        this.newReport.invoiceAudit.otherCosts.projectedRepairCosts = calculationResults.otherCosts;
    }

    /**
     * This method creates the new report shell on the server.
     * Creating the report on the server is necessary, since some services used later on require access to a report to save data.
     * Access would be denied if the report does not exist.
     */
    private async createEmptyReport() {
        this.currentStepSubject$.next('reportObject');

        try {
            this.emptyReport = await this.reportService.createEmptyReport({
                reportType: this.sourceReport.type,
                waitForServer: true,
            });
        } catch (error) {
            throw new UnprocessableEntity({
                code: 'REPORT_NOT_CREATED',
                message: 'Report could not be created on the server.',
                error,
            });
        }
    }

    /**
     * This method copies the basic data from the source report to the empty report.
     * This is a whitelist of the most basic properties (only claimant & car)
     * The downtime compensation group is updated if the car's age requires a different group.
     */
    private async copyBasicData() {
        const sourceReportCopy: Report = JSON.parse(JSON.stringify(this.sourceReport));

        // Different from the custom data routine, the basic data routine only adds
        // a whitelist of properties to the empty report.
        this.newReport = this.emptyReport;

        this.newReport.claimant = sourceReportCopy.claimant;
        if (isKaskoCase(sourceReportCopy.type)) {
            this.newReport.insurance = sourceReportCopy.insurance;
            this.newReport.insurance.caseNumber = null;
        }
        this.newReport.car = new Car({
            ...getCopyableCarProperties(sourceReportCopy.car),
        });

        // If the car is still within the same downtime compensation reduction group (0, 1 or 2 classes reduced),
        // copy the downtime compensation info. If the group has changed, we urge the user to determine the group again.
        if (damageReportTypes.includes(sourceReportCopy.type)) {
            const downtimeCompensationGroupSourceReport =
                determineNumberOfDowntimeCompensationClassReductionsAccordingToAge({
                    firstRegistration: sourceReportCopy.car.firstRegistration,
                    comparisonDate: sourceReportCopy.accident.date,
                });
            const downtimeCompensationGroupNow = determineNumberOfDowntimeCompensationClassReductionsAccordingToAge({
                firstRegistration: sourceReportCopy.car.firstRegistration,
                comparisonDate: DateTime.now().toISODate(),
            });
            if (downtimeCompensationGroupSourceReport === downtimeCompensationGroupNow) {
                this.newReport.damageCalculation.downtimeCompensationGroup =
                    sourceReportCopy.damageCalculation.downtimeCompensationGroup;
                this.newReport.damageCalculation.downtimeCompensationPerWorkday =
                    sourceReportCopy.damageCalculation.downtimeCompensationPerWorkday;
            }
        }
    }

    /**
     * This function copies all data from the old report to the new report.
     * Metadata (_id, createdBy, ...) is overwritten with the empty report's metadata.
     */
    private copyAllData() {
        // Copy source report into independent object.
        this.newReport = JSON.parse(JSON.stringify(this.sourceReport));

        // Overwrite unique or unfitting properties
        this.newReport._id = this.emptyReport._id;
        this.newReport.createdAt = this.emptyReport.createdAt;
        this.newReport.createdBy = this.emptyReport.createdBy;
        this.newReport.teamId = this.emptyReport.teamId;
        this.newReport._documentVersion = this.emptyReport._documentVersion;
        this.newReport._schemaVersion = this.emptyReport._schemaVersion;
        this.newReport.amendmentReportId = null; // In case the user writes a second amendment, clear the first reference.
        this.newReport.originalReportId = null; // Will be set later on.
        this.newReport.state = 'recorded';
        this.newReport.lockedAt = null;
        this.newReport.lockedBy = null;
        this.newReport.state = 'recorded';
        this.newReport.completionDate = null;

        // Reset invoice number counter per report (if available)
        if (this.newReport.invoiceNumberConfig) {
            this.newReport.invoiceNumberConfig.count = 0;
        }
    }

    /**
     * This method handles the photos.
     * If the photos should be copied, each photo gets a new ID and the asset on S3 is copied.
     */
    private async handlePhotos() {
        // Reset photo information if they should not be copied.
        if (!this.options.copySteps.PHOTOS) {
            this.newReport.photos = this.emptyReport.photos;
            return;
        }

        // Only copy if there are photos to copy
        if (this.newReport.photos.length < 1) {
            return;
        }

        try {
            this.newReport.photos = [];
            const sourceAndTargetPhotoIds: { sourcePhotoId: Photo['_id']; targetPhotoId: Photo['_id'] }[] = [];

            // Loop over either photo array and attach the photoId at the same index position.
            // Create new ID for each photo.
            this.sourceReport.photos.forEach((photo) => {
                const { _id: sourcePhotoId, ...photoWithoutId } = photo;
                const targetPhotoId = generateId();

                this.newReport.photos.push({ _id: targetPhotoId, ...photoWithoutId });
                sourceAndTargetPhotoIds.push({ sourcePhotoId, targetPhotoId });
            });

            await this.copyPhotosToReportService.copyPhotos({
                sourceReportId: this.sourceReport._id,
                targetReportId: this.newReport._id,
                sourceAndTargetPhotoIds,
            });
        } catch (error) {
            throw new AxError({
                code: 'PHOTOS_NOT_COPIED',
                message: 'Photo files could not be copied.',
                error,
            });
        }
    }

    private async handleClaimantSignatures() {
        // Reset claimant signature information if it should not be copied.
        if (!this.options.copySteps.CLAIMANT_SIGNATURES) {
            for (const signableDocument of this.newReport.signableDocuments) {
                signableDocument.signatures = [];
            }
            console.log(`Skip copying claimant signatures since the copy step "CLAIMANT_SIGNATURES" is deactivated.`);
            return;
        }

        /**
         * Copy the data structure of the source report's claimant signatures to the target report
         * and define new IDs.
         */
        const sourceAndTargetClaimantSignatureIds: {
            sourceClaimantSignatureId: ClaimantSignature['_id'];
            targetClaimantSignatureId: ClaimantSignature['_id'];
        }[] = [];
        this.newReport.signableDocuments = [];
        for (const signableDocument of this.sourceReport.signableDocuments) {
            const signableDocumentCopy: SignableDocument = JSON.parse(JSON.stringify(signableDocument));
            signableDocumentCopy._id = generateId();
            signableDocumentCopy.signatures = [];
            this.newReport.signableDocuments.push(signableDocumentCopy);

            for (const signature of signableDocument.signatures) {
                const signatureCopy: ClaimantSignature = JSON.parse(JSON.stringify(signature));
                signatureCopy._id = generateId();
                signableDocumentCopy.signatures.push(signatureCopy);

                /**
                 * Only copy the binary file if the source binary exists.
                 */
                if (signature.hash) {
                    let sourceClaimantSignatureId: string = signableDocument.documentType;
                    let targetClaimantSignatureId: string = signableDocument.documentType;

                    if (signature.customDocumentOrderConfigId) {
                        sourceClaimantSignatureId += `-${signature.customDocumentOrderConfigId}`;
                        targetClaimantSignatureId += `-${signatureCopy.customDocumentOrderConfigId}`;
                    }
                    /**
                     * In PDF-based signable documents or declarationOfAssignment with fee table, multiple signatures are possible. Therefore, the signature ID is added to
                     * ID of the binary file.
                     */
                    if (signature.signatureSlotId) {
                        sourceClaimantSignatureId += `-${signature.signatureSlotId}`;
                        targetClaimantSignatureId += `-${signatureCopy.signatureSlotId}`;
                    }

                    sourceAndTargetClaimantSignatureIds.push({
                        sourceClaimantSignatureId,
                        targetClaimantSignatureId,
                    });
                }
            }
        }

        /**
         * If no source binary signature exists, no need to ask the server to copy them.
         */
        if (sourceAndTargetClaimantSignatureIds.length < 1) {
            return;
        }

        /**
         * Copy the binary signature files.
         */
        try {
            await this.copyClaimantSignaturesToReportService.copyClaimantSignatures({
                sourceReportId: this.sourceReport._id,
                targetReportId: this.newReport._id,
                sourceAndTargetClaimantSignatureIds,
            });
        } catch (error) {
            throw new AxError({
                code: 'COPYING_CLAIMANT_SIGNATURES_FAILED',
                message: 'Claimant signature binary files could not be copied.',
                error,
            });
        }
    }

    /**
     * This method handles the calculation.
     * If the calculation should be copied, a new DAT dossier is created. All calculation-related data is cleared, so that the user knows he must import the calculation again.
     * Audatex handles multiple calculations by default, so no need to copy the entire task. That's cheaper for an assessor.
     * GT Motive is not implemented yet.
     * Manual calculation is copied with the report data.
     */
    private async handleCalculation() {
        // Reset complete repair data if calculation is not copied
        if (!this.options.copySteps.CALCULATION) {
            this.newReport.damageCalculation = this.emptyReport.damageCalculation;
            return;
        }

        if (this.newReport.damageCalculation?.repair.calculationProvider === 'dat') {
            this.currentStepSubject$.next('calculation');
            try {
                const response = await this.copyDatDossierService.copyDossier(this.sourceReport._id);
                this.newReport.damageCalculation.repair.datCalculation = {
                    dossierId: response.newDatDossierId,
                };
            } catch (error) {
                throw new UnprocessableEntity({
                    code: 'DAT_DOSSIER_NOT_COPIED',
                    message: 'DAT-Dossier could not be copied.',
                    error,
                });
            }

            // Clear all calculation-related data so that the user knows he must import the calculation again.
            const emptyRepairCalculation = new RepairCalculation();
            Object.assign(this.newReport.damageCalculation.repair, emptyRepairCalculation);
        }
    }

    /**
     * This method handles the residual value offers.
     * If the residual value offers should be copied, the according files are copied on the backend.
     */
    private async handleResidualValueOffers() {
        // If the residual value offers should not be copied, reset them.
        if (!this.options.copySteps.RESIDUAL_VALUE_OFFERS) {
            this.newReport.valuation.autoonlineResidualValueOffer = new AutoonlineResidualValueOffer();
            this.newReport.valuation.autoixpertResidualValueOffer = new AutoixpertResidualValueOffer();
            this.newReport.valuation.cartvResidualValueOffer = new ResidualValueOffer();
            this.newReport.valuation.winvalueResidualValueOffer = new ResidualValueOffer();
            this.newReport.valuation.carcasionResidualValueOffer = new ResidualValueOffer();
            this.newReport.valuation.customResidualValueBids = [];
            this.removeDocumentsByType([
                'winvalueResidualValueBidList',
                'cartvResidualValueBidList',
                'carcasionResidualValueBidList',
                'autoonlineResidualValueBidList',
                'customResidualValueBidList',
            ]);
            return;
        }

        this.currentStepSubject$.next('residualValue');
        const residualValueTypes: ('winvalue' | 'cartv' | 'carcasion' | 'autoonline')[] = [];

        this.sourceReport.documents.forEach((document) => {
            if (document.type === 'winvalueResidualValueBidList') {
                residualValueTypes.push('winvalue');
            } else if (document.type === 'cartvResidualValueBidList') {
                residualValueTypes.push('cartv');
            } else if (document.type === 'carcasionResidualValueBidList') {
                residualValueTypes.push('carcasion');
            } else if (document.type === 'autoonlineResidualValueBidList') {
                residualValueTypes.push('autoonline');
            }
        });

        try {
            await this.copyResidualValueDocumentsService.copyResidualValueDocuments({
                sourceReportId: this.sourceReport._id,
                targetReportId: this.newReport._id,
                residualValueDocumentTypes: residualValueTypes,
            });
        } catch (error) {
            throw new UnprocessableEntity({
                code: 'RESIDUAL_VALUE_OFFERS_NOT_COPIED',
                message: 'Restwertinserate konnten nicht kopiert werden.',
                error,
            });
        }
    }

    /**
     * This method handles the market analysis.
     * If the market analysis should be copied, the according files are copied on the backend.
     */
    private async handleMarketAnalysis() {
        if (!this.options.copySteps.MARKET_ANALYSIS) {
            this.newReport.valuation.cartvValuation = this.emptyReport.valuation.cartvValuation;
            this.newReport.valuation.valuepilotValuation = this.emptyReport.valuation.valuepilotValuation;
            this.newReport.valuation.winvalueValuation = this.emptyReport.valuation.winvalueValuation;
            this.newReport.valuation.datValuation = this.emptyReport.valuation.datValuation;
            this.newReport.valuation.audatexValuation = this.emptyReport.valuation.audatexValuation;
            this.newReport.valuation.customMarketAnalyses = this.emptyReport.valuation.customMarketAnalyses;
            this.removeDocumentsByType([
                'datMarketAnalysis',
                'cartvMarketAnalysis',
                'winvalueMarketAnalysis',
                'valuepilotMarketAnalysis',
                'audatexMarketAnalysis',
            ]);
            return;
        }
        this.currentStepSubject$.next('marketAnalysis');

        const marketAnalysisTypes: ('dat' | 'audatex' | 'cartv' | 'winvalue' | 'valuepilot')[] = [];
        this.sourceReport.documents.forEach((document) => {
            if (document.type === 'datMarketAnalysis') {
                marketAnalysisTypes.push('dat');
            } else if (document.type === 'cartvMarketAnalysis') {
                marketAnalysisTypes.push('cartv');
            } else if (document.type === 'winvalueMarketAnalysis') {
                marketAnalysisTypes.push('winvalue');
            } else if (document.type === 'valuepilotMarketAnalysis') {
                marketAnalysisTypes.push('valuepilot');
            } else if (document.type === 'audatexMarketAnalysis') {
                marketAnalysisTypes.push('audatex');
            }
        });

        try {
            await this.copyMarketAnalysisDocumentsService.copyMarketAnalysisDocuments({
                sourceReportId: this.sourceReport._id,
                targetReportId: this.newReport._id,
                marketAnalysisDocumentTypes: marketAnalysisTypes,
            });
        } catch (error) {
            throw new UnprocessableEntity({
                code: 'MARKET_ANALYSIS_NOT_COPIED',
                message: 'Marktanalyse konnte nicht kopiert werden.',
                error,
            });
        }
    }

    /**
     * This function resets the damage description to the empty report's damage description.
     */
    private async handleDamageDescription() {
        if (!this.options.copySteps.DAMAGE_DESCRIPTION) {
            this.newReport.car.damageDescription = null;
        }
    }

    /**
     * This function copies the car equipment which is in a separate collection.
     */
    private async copyCarEquipment() {
        this.currentStepSubject$.next('carEquipment');
        try {
            await this.carEquipmentService.copyToReport(this.sourceReport._id, this.newReport._id);
        } catch (error) {
            throw new UnprocessableEntity({
                code: 'CAR_EQUIPMENT_NOT_COPIED',
                message: 'Ausstattung konnte nicht kopiert werden.',
                error,
            });
        }
    }

    /**
     * This function resets the fee calculation and configures the invoice number.
     */
    private resetInvoice() {
        this.newReport.feeCalculation = this.emptyReport.feeCalculation;
        this.newReport.feeCalculation.skipWritingInvoice = this.emptyReport.feeCalculation.skipWritingInvoice;
        this.newReport.invoiceExportedToAdeltafinanz = this.emptyReport.invoiceExportedToAdeltafinanz;
        this.newReport.invoiceExportedToKfzvs = this.emptyReport.invoiceExportedToKfzvs;
        this.newReport.persaldoCaseNumber = this.emptyReport.persaldoCaseNumber;
        this.newReport.crashback24ProcessId = this.emptyReport.crashback24ProcessId;
    }

    /**
     * This function configures the Invoice number and the report token for amendment reports and invoice audits.
     */
    private configureInvoiceReportToken() {
        // Skip writing invoice if the user does not want to write an invoice.
        this.newReport.feeCalculation.skipWritingInvoice = !this.options.activateInvoice;

        // Add suffix to invoice number if the user wants to.
        if (this.options.copyInvoiceNumber) {
            this.newReport.feeCalculation.invoiceParameters.number = `${
                this.sourceReport.feeCalculation.invoiceParameters.number || ''
            }${this.options.invoiceNumberSuffix || ''}`;
        }

        // Add suffix to report token if the user wants to.
        if (this.options.copyReportToken) {
            this.newReport.token = `${this.sourceReport.token || ''}${this.options.reportTokenSuffix || ''}`;
        }
    }

    /**
     * Clear sent emails in the copy because the user will be confused to see that an
     * amendment report has already been sent, which is wrong.
     * */
    private resetEMails() {
        const involvedParties = getCommunicationRecipients(this.newReport);
        for (const involvedParty of involvedParties) {
            involvedParty.receivedEmail = false;
            involvedParty.receivedLetter = false;
        }
    }

    private async saveReport() {
        this.currentStepSubject$.next('savingReport');
        try {
            await this.reportService.put(this.newReport, { waitForServer: true });
        } catch (err) {
            throw new UnprocessableEntity({
                code: 'REPORT_NOT_SAVED',
                message: 'Report could not be saved on the server.',
            });
        }
    }

    private async linkAmendmentToOriginalReport() {
        // Save reference to the new report on the original report
        this.sourceReport.amendmentReportId = this.newReport._id;

        try {
            await this.reportService.put(this.sourceReport, { waitForServer: true });
        } catch (err) {
            throw new UnprocessableEntity({
                code: 'SOURCE_REPORT_NOT_SAVED',
                message: 'Source report could not be saved on the server to link the amendment report.',
            });
        }
    }

    /**
     * Copy the user uploaded documents for all documents which contain a user uploaded document except permanent uploaded documents.
     * This should be the last step to ensure only these files are copied, which belong to documents which are in the new report.
     * The report needs to be accessible in the backend since userUploadedDocuments must belong to an existing report.
     */
    private async copyAllUserUploadedDocuments() {
        this.currentStepSubject$.next('userUploadedDocuments');

        const sourceAndTargetUploadedDocumentIds: SourceAndTargetUploadedDocumentIds[] = [];
        this.sourceReport.documents.forEach((existingDocument) => {
            /**
             * Copy the user uploaded documents for all documents which contain a user uploaded document except permanent uploaded documents.
             */
            if (existingDocument.uploadedDocumentId && !existingDocument.permanentUserUploadedDocument) {
                /**
                 * Find the matching document in the new report.
                 * Not all documents are copied (e.g. invoice is omitted depending on user settings).
                 * If the document is not found, continue with the next document.
                 */
                const documentInNewReport = this.newReport.documents.find(
                    (newDocument) => newDocument._id === existingDocument._id,
                );
                if (!documentInNewReport) {
                    return;
                }

                /**
                 * Since the custom uploaded documents are not prefixed, we need to create a new id for the document.
                 * Assign the id to the document in the new report.
                 * There is an Issue (AX-3536) to prefix user uploaded documents with the report.
                 */
                const targetUploadedDocumentId = generateId();
                documentInNewReport.uploadedDocumentId = targetUploadedDocumentId;
                sourceAndTargetUploadedDocumentIds.push({
                    sourceId: existingDocument.uploadedDocumentId,
                    targetId: targetUploadedDocumentId,
                });
            }
        });

        if (sourceAndTargetUploadedDocumentIds.length > 0) {
            try {
                await this.copyUserUploadedDocumentsService.copyUserUploadedDocuments({
                    sourceReportId: this.sourceReport._id,
                    targetReportId: this.newReport._id,
                    sourceAndTargetUploadedDocumentIds,
                });
            } catch (error) {
                throw new UnprocessableEntity({
                    code: 'USER_UPLOADED_DOCUMENTS_NOT_COPIED',
                    message: 'Dokumente konnten nicht kopiert werden.',
                    error,
                });
            }
        }
    }

    /**
     * Helper function to remove documents by type.
     * Useful to remove residual value offers, market analysis documents, etc.
     */
    private removeDocumentsByType(documentTypes: DocumentMetadata['type'][]) {
        const affectedDocuments = this.newReport.documents.filter((document) => documentTypes.includes(document.type));
        affectedDocuments.forEach((documentToRemove) => {
            removeFromArray(documentToRemove, this.newReport.documents);
        });
    }
}

export function getReportCopyErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        ...getDatErrorHandlers(),
        REPORT_NOT_CREATED: {
            title: 'Kopie nicht erstellt',
            body: "Der Vorgang konnte nicht kopiert werden. Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
        },
        PHOTOS_NOT_COPIED: {
            title: 'Kopieren der Fotos nicht möglich',
            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
        },
        DAT_DOSSIER_NOT_COPIED: {
            title: 'Kopieren des DAT-Vorgangs gescheitert',
            body: "Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
        },
        RESIDUAL_VALUE_OFFERS_NOT_COPIED: {
            title: 'Kopieren der Restwertinserate gescheitert',
            body: "Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
        },
        MARKET_ANALYSIS_NOT_COPIED: {
            title: 'Kopieren der Restwertinserate gescheitert',
            body: "Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
        },
        CAR_EQUIPMENT_NOT_COPIED: {
            title: 'Ausstattung nicht kopiert',
            body: "Die Ausstattung des Fahrzeugs konnte nicht in das neue Gutachten kopiert werden. Bitte starte den Kopiervorgang erneut oder kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
        },
        USER_UPLOADED_DOCUMENTS_NOT_COPIED: {
            title: 'Kopieren der eigenen PDF-Dateien gescheitert',
            body: "Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
        },
        REPORT_NOT_SAVED: {
            title: 'Gutachtenkopie nicht gespeichert',
            body: 'Die Gutachtenkopie konnte nicht gespeichert werden.',
        },
        SOURCE_REPORT_NOT_SAVED: {
            title: 'Referenz auf Originalgutachten nicht gespeichert',
            body: 'Das Originalgutachten konnte nicht gespeichert werden.',
        },
    };
}

<div class="webhooks-container">
    <ng-container *ngFor="let webhook of webhooks; trackBy: trackById">
        <div class="webhook">
            <!-- Event type -->
            <div class="webhook-event-type-container">
                <mat-form-field>
                    <mat-select
                        [value]="webhook.eventType"
                        (selectionChange)="updateTeamWebhook(webhook, 'eventType', $event.value)"
                        placeholder="Event-Typ"
                        [(ngModel)]="webhook.eventType">
                        <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
                            {{ translateWebhookEventType(eventType) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="webhook-info-container">
                <!-- URL -->
                <mat-form-field>
                    <input
                        matInput
                        [value]="webhook.url"
                        (input)="updateTeamWebhook(webhook, 'url', $any($event.target).value)"
                        placeholder="URL"
                        [(ngModel)]="webhook.url" />
                </mat-form-field>

                <!-- Description -->
                <mat-form-field class="mat-block">
                    <mat-quill
                        placeholder="Beschreibung"
                        [(ngModel)]="webhook.description"
                        (change)="updateTeamWebhook(webhook)"></mat-quill>
                </mat-form-field>
            </div>

            <!-- Active -->
            <mat-slide-toggle
                [checked]="webhook.active"
                (change)="updateTeamWebhook(webhook, 'active', $event.checked)">
                Aktiv
            </mat-slide-toggle>

            <!-- Settings -->
            <div class="webhook-toolbar-container">
                <mat-icon class="toolbar-icon webhook-submenu-icon" [matMenuTriggerFor]="webhookSubmenu">
                    more_vert
                </mat-icon>
            </div>

            <mat-menu #webhookSubmenu>
                <!-- Duplicate -->
                <a mat-menu-item matTooltip="Löschen" (click)="duplicateTeamWebhook(webhook)">Kopieren</a>

                <!-- Delete -->
                <a mat-menu-item matTooltip="Löschen" (click)="deleteTeamWebhook(webhook)">Löschen</a>
            </mat-menu>
        </div>
    </ng-container>

    <!-- Add new webhook -->
    <div id="add-webhook-container" (click)="createTeamWebhook()" matTooltip="Webhook hinzufügen">
        <div id="add-webhook-inner-container">
            <div id="add-webhook-icon-container">
                <mat-icon *ngIf="!addTeamWebhookPending" id="add-webhook-icon">add</mat-icon>
                <img
                    *ngIf="addTeamWebhookPending"
                    src="/assets/images/icons/loading-indicator.svg"
                    class="pending-icon" />
            </div>
            <div class="webhook-name">Webhook hinzufügen</div>
        </div>
    </div>
</div>

import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject, InjectionToken, OnInit } from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { PANEL_CLOSE_SUBJECT_TOKEN } from '@autoixpert/injection-tokens/common-overlay-injection-tokens';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { InternalNote } from '@autoixpert/models/internal-note';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'internal-notes-panel',
    templateUrl: 'internal-notes-panel.component.html',
    styleUrls: ['internal-notes-panel.component.scss'],
    animations: [fadeInAndSlideAnimation()],
})
export class InternalNotesPanelComponent implements OnInit {
    constructor(
        @Inject(INTERNAL_NOTES_TOKEN) public notes: InternalNote[],
        @Inject(CHANGE_SUBJECT_TOKEN) private changeSubject: Subject<InternalNote[]>,
        @Inject(PANEL_CLOSE_SUBJECT_TOKEN) private panelCloseSubject: Subject<void>,
        private overlayRef: OverlayRef,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
    ) {}

    public user: User;
    public team: Team;

    // Edit Mode
    public notesInEditMode: Map<InternalNote, boolean> = new Map();

    // New Comment
    public newNote: InternalNote;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.user = this.loggedInUserService.getUser();

        // Have at least an empty note showing, ready to be edited.
        if (!this.notes.length) {
            this.createNewNote();
        }

        this.closeOnBackdropClick();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CRUD Notes
    //****************************************************************************/
    public createNewNote() {
        this.newNote = new InternalNote({
            createdBy: this.user._id,
            updatedBy: this.user._id,
        });
    }

    public enterEditMode(note: InternalNote) {
        this.notesInEditMode.set(note, true);
    }

    public leaveEditMode(note: InternalNote) {
        this.notesInEditMode.delete(note);
    }

    public deleteNote(note: InternalNote) {
        removeFromArray(note, this.notes);
        this.emitChange();

        this.notesInEditMode.delete(note);
    }

    /**
     * Move new note to notes array.
     */
    public saveNewNote() {
        this.newNote.createdAt = this.newNote.updatedAt = moment().format();

        // Only save the note if it has text.
        if (this.newNote.text) {
            this.notes.push(this.newNote);
            this.emitChange();
        }
        this.clearNewNote();
    }

    public saveNoteOnCtrlEnter(event: KeyboardEvent) {
        switch (event.key) {
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.saveNewNote();
                    break;
                }
        }
    }

    public clearNewNote() {
        this.newNote = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Notes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitChange() {
        this.changeSubject.next(this.notes);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls & Animation
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public closePanel(): void {
        this.overlayRef.detach();

        // Notify the anchor that the panel has been closed.
        this.panelCloseSubject.next();
        this.panelCloseSubject.complete();

        // Unsubscribe subscribers after the dialog has been closed.
        this.changeSubject.complete();
    }

    /**
     * Close dialog on backdrop click. Don't close if notes are still being edited.
     */
    public closeOnBackdropClick(): void {
        this.overlayRef.backdropClick().subscribe(() => {
            // If at least one not is in edit mode, prevent backdrop clicks from closing our dialog.
            if (this.notesInEditMode.size > 0) {
                this.toastService.warn(
                    'Dialog nicht geschlossen',
                    'Bitte beende zuerst die Bearbeitung aller Notizen.',
                );
                return;
            } else if (this.newNote?.text) {
                this.toastService.warn(
                    'Dialog nicht geschlossen',
                    'Bitte beende zuerst die Bearbeitung aller Notizen.',
                );
                return;
            }
            // No note in edit mode? -> Close dialog.
            this.overlayRef.detach();
        });
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                event.stopPropagation();
                this.closePanel();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls & Animation
    /////////////////////////////////////////////////////////////////////////////*/
}

export const INTERNAL_NOTES_TOKEN = new InjectionToken<InternalNote[]>('INTERNAL_NOTES_TOKEN');
export const CHANGE_SUBJECT_TOKEN = new InjectionToken<Subject<InternalNote[]>>('CHANGE_SUBJECT_TOKEN');

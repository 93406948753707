export class DatUser {
    constructor(template: Partial<DatUser> = {}) {
        Object.assign(this, template);
    }

    username: string;
    password: string;
    // A 7-digit number
    customerNumber: number;
    /**
     * This will be set to true if the DAT user belongs to a garage or car dealer. Instead of DAT SilverDAT3 calculateExpert they use
     * DAT SilverDAT3 myclaim.
     */
    isMyclaimUser?: boolean;
    /**
     * There may be various network types within DAT myclaim. The DAT may customize the repair calculation process for each network type, e.g. the
     * Austrian myclaim network uses a different DAT calculation DOCX template.
     */
    myclaimNetworkType?: DatNetworkType | string;
    isValuateExpertPlusPartnerUser?: boolean;
    isValuateProUser?: boolean;
}

export type DatNetworkType = 'DAT' | 'DATAU' | 'TUV_SUD';

import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { OldtimerValuationGrades } from '@autoixpert/models/reports/market-value/oldimter-valuation-grades';
import { Valuation } from '@autoixpert/models/reports/market-value/valuation';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { getProductName } from '../../../shared/libraries/get-product-name';
import { getVatRate } from '../../../shared/libraries/get-vat-rate-2020';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { AXRESTClient } from '../../../shared/services/ax-restclient';
import { DownloadService } from '../../../shared/services/download.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';

@Component({
    selector: 'oldtimer-valuation',
    templateUrl: 'oldtimer-valuation.component.html',
    styleUrls: ['oldtimer-valuation.component.scss'],
    animations: [fadeInAndOutAnimation(), slideInAndOutVertically()],
})
export class OldtimerValuationComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private reportDetailsService: ReportDetailsService,
        private loggedInUserService: LoggedInUserService,
        public userPreferences: UserPreferencesService,
        private changeDetectorRef: ChangeDetectorRef,
        private apiErrorService: ApiErrorService,
        private httpClient: HttpClient,
        private downloadService: DownloadService,
        private toastService: ToastService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
    ) {}

    public report: Report;
    public team: Team;
    public user: User;
    private subscriptions: Subscription[] = [];

    public restorationValueControlsShown: boolean = false;
    public costItemsDialogShown: boolean = false;
    public deductionItemsDialogShown: boolean = false;
    public classicanalyticsPdfUploadPending: boolean = false;

    public isFileOverAudatexDropZone: boolean = false;
    // If autoiXpert reacts to every mouseout event, the indicator starts blinking even when hovering child elements
    // such as the document title or the on-off-switch. Avoid that by using a timer.
    private mouseoutAudatexDropZoneHover = null;
    // Sometimes, the detection of the onDragEnd event does not work correctly. Thus, we should remove the upload after a second
    // of no other onDragOver event on the body
    public fileOverBodyTimeoutCache = null;
    // Becomes true when the user drags files over the window. The drop zone can then be shown.
    public isFileOverBody: boolean = false;
    // Uploader instance for uploading photos to the server
    public uploader: FileUploader;
    //*****************************************************************************
    //  Initialization
    //****************************************************************************/

    ngOnInit() {
        const reportSubscription = this.route.parent.params
            .pipe(switchMap((params) => this.reportDetailsService.get(params['reportId'])))
            .subscribe((report) => {
                this.report = report;
                this.setupOldtimerValuationObject();
                this.showRestorationData();

                // Realtime Editor
                this.joinAsRealtimeEditor();
                this.initializeUploader();
            });

        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        this.subscriptions.push(reportSubscription);
    }

    public setupOldtimerValuationObject(): void {
        if (!this.report?.valuation.oldtimerValuationGrades) {
            this.report.valuation.oldtimerValuationGrades = new OldtimerValuationGrades();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Restoration
    //****************************************************************************/
    /**
     * Fade in the input fields for the restoration value. This can be triggered by the user (force = true)
     * when clicked on the "add restoration value" button or automatically, if the report already contains
     * data related to the restoration value.
     */
    public showRestorationData(force: boolean = false): void {
        if (force || this.report.valuation.restorationBaseVehicleValue || this.report.valuation.restorationCosts) {
            this.restorationValueControlsShown = true;
        }
    }

    /**
     * User changed either the restoration costs or vehicle base value. Recalculate the sum, which is the restoration cost.
     */
    updateRestorationValue(): void {
        this.report.valuation.restorationValue =
            (this.report.valuation.restorationBaseVehicleValue ?? 0) + (this.report.valuation.restorationCosts ?? 0);
        this.saveReport();
    }

    public openCostItemsDialog(): void {
        this.costItemsDialogShown = true;
    }

    public closeCostItemsDialog(): void {
        this.costItemsDialogShown = false;
    }

    public openDeductionItemsDialog(): void {
        this.deductionItemsDialogShown = true;
    }

    public closeDeductionItemsDialog(): void {
        this.deductionItemsDialogShown = false;
    }

    public getCorrectedRestorationCostsNet(): number {
        return this.report.valuation.restorationCosts - this.report.valuation.restorationDeductions || 0;
    }

    public getRestorationValue(): number {
        return (
            this.report.valuation.datValuation.dealerPurchasePrice +
                this.getCorrectedRestorationCostsNet() * (1 + getVatRate()) || 0
        );
    }

    public acceptRestorationCosts(restorationCosts: number): void {
        this.report.valuation.restorationCosts = restorationCosts;
    }

    public acceptRestorationDeductions(restorationDeductions: number): void {
        this.report.valuation.restorationDeductions = restorationDeductions;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Restoration
    /////////////////////////////////////////////////////////////////////////////*/

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    //*****************************************************************************
    //  Save Methods
    //****************************************************************************/
    /**
     * Save reports to the server.
     */
    public async saveReport(): Promise<void> {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Methods
    /////////////////////////////////////////////////////////////////////////////*/

    public selectOldtimerValuationProvider(valuationProvider: Valuation['valuationProvider']) {
        this.report.valuation.valuationProvider = valuationProvider;
    }

    //*****************************************************************************
    //  Classic Analytics Document File Upload
    //****************************************************************************/
    public onFileOverClassicAnalyticsDropZone(fileOver: boolean): void {
        // If the user's mouse and the dragged file left the dropzone, hide the dropzone.
        if (!fileOver) {
            this.mouseoutAudatexDropZoneHover = setTimeout(() => {
                this.isFileOverAudatexDropZone = false;
            }, 500);
        } else {
            clearTimeout(this.mouseoutAudatexDropZoneHover);
            this.isFileOverAudatexDropZone = true;
            this.onFileOverBody();
        }
    }

    public async onFileDrop(fileList): Promise<void> {
        // Hide the drop zone as soon as content is dropped
        this.isFileOverAudatexDropZone = false;
        this.isFileOverBody = false;

        if (fileList.length > 1) {
            this.toastService.error(
                'Nur eine Datei erlaubt',
                'Nur eine Datei kann für die Classic Analytics Bewertung hochgeladen werden.',
            );
            return;
        }

        // Validate queue
        for (const item of this.uploader.queue) {
            // Of course, we don't have to update the same document multiple times.
            if (item.isReady || item.isUploading || item.isUploaded) {
                continue;
            }
            // If the mime type is no PDF, remove the document from the queue
            if (!item._file.type.includes('pdf')) {
                console.error('The given file is not a PDF.', item);
                this.toastService.error('Nur PDF erlaubt', 'Bitte lade eine PDF-Datei hoch.');
                this.uploader.queue.splice(this.uploader.queue.indexOf(item), 1);
                return;
            }

            // Insert the Classic Analytics valuation into the documents array.
            addDocumentToReport({
                report: this.report,
                documentGroup: 'report',
                team: this.team,
                newDocument: new DocumentMetadata({
                    type: 'classicanalyticsValuation',
                    title: 'Classic Analytics Bewertung',
                    uploadedDocumentId: null,
                    permanentUserUploadedDocument: false,
                    createdAt: moment().format(),
                    createdBy: this.user._id,
                }),
            });

            this.selectOldtimerValuationProvider('classicAnalytics');

            /**
             * Save the report to the server before uploading the binary files. This prevents content errors where a PDF was uploaded but
             * the report is not configured to have a ClassicAnalytics valuation.
             */
            await this.reportDetailsService.patch(this.report, { waitForServer: true });

            this.upload(item);
        }
    }

    /**
     * Triggered when a PDF file is dropped on the Audatex button or the file upload button is clicked.
     * Parameter is a native fileList of the dropped files.
     */
    public upload(item: FileItem): void {
        this.classicanalyticsPdfUploadPending = true;
        // The name of the file in the form-data/multipart. This is what the server looks for when extracting the file from the post request.
        item.alias = 'document';
        // Updates the progress bar on the item
        item.onProgress = this.onProgress.bind(this);
        item.onSuccess = (/*response, status*/) => {
            this.classicanalyticsPdfUploadPending = false;
            // TODO Enable uploading a document in the background even when switching views.
            // TODO Enable remembering uploads in case no internet connection is available.
            // // Remove the uploadItem entry for this photo since the upload completed.
            // this.photoService.photoUploads.delete(photo._id);
        };
        item.onError = (response, status, headers) => {
            // The response is parsed as a string, so convert it to an object.
            const error = JSON.parse(response);
            this.classicanalyticsPdfUploadPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    CONVERTING_PDF_TO_PNG_FAILED: {
                        title: 'Konvertierung fehlgeschlagen',
                        body: 'Die PDF-Datei konnte nicht zu Bildern konvertiert werden. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                },
                defaultHandler: {
                    title: 'PDF nicht hochgeladen',
                    body: 'Die Audatex-Kalkulation konnte nicht hochgeladen werden.',
                },
            });
        };

        // Send file to the server.
        item.upload();
    }

    private onProgress(): void {
        // Update view manually. Apparently, angular cannot detect the change on its own.
        try {
            if (this.changeDetectorRef['destroyed'] === false) {
                this.changeDetectorRef.detectChanges();
            }
        } catch (error) {
            console.warn(
                'Change detection failed. This might be due to the user navigating to another view. The change detector cannot find the original view then, since it is destroyed on leaving. If that is the case, this behavior is accepted.',
                error,
            );
        }
    }

    private initializeUploader(): void {
        // Enable file upload when dragging & dropping files on the individual documents
        this.uploader = new FileUploader({
            url: AXRESTClient.marryToBaseUrl(`/reports/${this.report._id}/documents/classicanalyticsValuation`),
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
        });
    }

    /**
     * Show drop zone
     */
    @HostListener('body:dragover', ['$event'])
    public onFileOverBody() {
        clearTimeout(this.fileOverBodyTimeoutCache);

        this.isFileOverBody = true;

        this.fileOverBodyTimeoutCache = setTimeout(() => {
            this.isFileOverBody = false;
        }, 500);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Classic Analytics Document File Upload
    /////////////////////////////////////////////////////////////////////////////*/

    removeExternalValuation() {
        this.httpClient.delete(`/api/v0/reports/${this.report._id}/documents/classicanalyticsValuation`).subscribe({
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    // This is an error that is only important for the autoiXpert team. Do not show an error to the user.
                    defaultHandler: { action: () => console.log(`The Classic Anayltics PDF could not be deleted.`) },
                });
            },
        });
        removeDocumentTypeFromReport({
            report: this.report,
            documentType: 'classicanalyticsValuation',
            documentGroup: 'report',
        });
        this.report.valuation.valuationProvider = null;
        this.saveReport();
    }

    downloadExternalValuationPdf() {
        this.httpClient
            .get(`/api/v0/reports/${this.report._id}/documents/classicanalyticsValuation`, {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe({
                next: (response) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    console.error('Error downloading the PDF buffer.', { error });
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Classic Analytics PDF',
                            body: 'Die PDF-Datei konnte nicht heruntergeladen werden.',
                        },
                    });
                },
            });
    }

    //*****************************************************************************
    //  Product Name
    //****************************************************************************/
    public getProductName = getProductName;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Product Name
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab: 'valuationOldtimer',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Component Clean-up
    //****************************************************************************/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Component Clean-up
    /////////////////////////////////////////////////////////////////////////////*/
}

<div id="conditions-container">
    <!-- Conditions headline -->
    <div id="conditions-headline-container">
        <h3 id="conditions-heading">Bedingungen</h3>
        <!-- Condition group operator -->
        <!-- (only show for >=2 conditions) *ngIf="conditionGroup.conditions.length > 1" -->
        <div
            class="condition-group-operator"
            [ngSwitch]="conditionGroup.conditionsOperator"
            [style.margin-left.px]="15"
            [matMenuTriggerFor]="conditionGroupOperatorMenu"
            [matTooltip]="
                conditionGroup.conditionsOperator === 'or'
                    ? 'Mindestens eine Bedingung muss erfüllt sein'
                    : 'Alle Bedingungen müssen erfüllt sein'
            ">
            <span *ngSwitchDefault>&</span>
            <mat-icon *ngSwitchCase="'or'">call_split</mat-icon>
        </div>

        <mat-menu class="condition-group-operator-menu" #conditionGroupOperatorMenu="matMenu">
            <a
                mat-menu-item
                class="condition-group-operator-menu-item"
                (click)="setConditionGroupOperator(conditionGroup, 'and')"
                matTooltip="Alle Bedingungen müssen zutreffen">
                <div class="condition-group-operator">&</div>
                Und
            </a>

            <a
                mat-menu-item
                class="condition-group-operator-menu-item"
                (click)="setConditionGroupOperator(conditionGroup, 'or')"
                matTooltip="Mindestens eine der Bedingungen muss zutreffen">
                <div class="condition-group-operator">
                    <mat-icon>call_split</mat-icon>
                </div>
                Oder
            </a>
        </mat-menu>
    </div>

    <div id="conditions-toolbar">
        <!--********** New condition **********-->
        <div id="new-condition-button">
            <!--********** Add Condition **********-->
            <div
                id="new-condition-button-section"
                class="new-condition-button-section"
                (click)="addCondition()"
                matTooltip="Neue Bedingung">
                <div id="new-condition-label-large-displays">+ Bedingung</div>
            </div>

            <!--********** Divider **********-->
            <div id="new-condition-button-divider"></div>

            <!--********** Add Condition Group **********-->
            <div
                id="new-condition-group-button-section"
                class="new-condition-button-section"
                matTooltip="Neue Bedingungsgruppe"
                [matMenuTriggerFor]="conditionTypeMenu">
                <mat-icon class="small-icon">arrow_drop_down</mat-icon>
            </div>

            <mat-menu #conditionTypeMenu="matMenu">
                <div
                    mat-menu-item
                    (click)="addConditionGroup()"
                    matTooltip="Eine Bedingungsgruppe erlaubt es, mehrere Bedingungen mit UND oder ODER zu verknüpfen.">
                    <mat-icon>widgets</mat-icon>
                    Neue Bedingungsgruppe
                </div>
            </mat-menu>
        </div>
    </div>

    <!-- Building block conditionGroup conditions -->
    <div class="conditions-list-container" cdkDropList (cdkDropListDropped)="hanldeConditionDrop($event)">
        <ng-container *ngFor="let condition of conditionGroup.conditions; trackBy: trackById">
            <div cdkDrag [style.margin]="isDocumentBuildingBlockConditionGroup(condition) ? '0px 20px' : undefined">
                <document-building-block-condition
                    [condition]="condition"
                    [placeholderValues]="placeholderValues"
                    [fieldGroupConfigs]="fieldGroupConfigs"
                    [placeholderValueTree]="placeholderValueTree"
                    [availableDocumentPlaceholders]="availableDocumentPlaceholders"
                    (removeCondition)="handleRemoveChildCondition($event)"
                    (duplicateCondition)="handleDuplicateChildCondition($event)"
                    (turnIntoConditionGroup)="handleTurnIntoConditionGroup($event)">
                    <!-- Drag handle for condition (must be in same template as cdkDrag)-->
                    <mat-icon
                        drag-handle-condition
                        class="condition-grab-handle"
                        matTooltip="Position verschieben"
                        cdkDragHandle>
                        drag_indicator
                    </mat-icon>

                    <!-- Drag handle for condition group (must be in same template as cdkDrag)-->
                    <mat-icon
                        drag-handle-condition-group
                        class="condition-group-grab-handle"
                        matTooltip="Position verschieben"
                        cdkDragHandle>
                        drag_indicator
                    </mat-icon>
                </document-building-block-condition>
            </div>
        </ng-container>
    </div>
</div>

<div class="dialog-overlay" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!--============================================-->
        <!-- Message Template List -->
        <!--============================================-->
        <div id="list-column" class="card">
            <ng-container *ngIf="recipientRole">
                <div
                    id="recipient-icon-container"
                    [matTooltip]="'Vorlagen für Empfängertyp ' + translateTextTemplateRole(recipientRole)">
                    <img id="recipient-icon" src="/assets/images/icons/{{ recipientRole }}-blue.svg" />
                </div>
                <h3 id="recipient-role-heading">{{ translateTextTemplateRole(recipientRole) }}</h3>
            </ng-container>
            <!--============================================-->
            <!-- Search & Sort -->
            <!--============================================-->
            <div id="search-and-sort-container">
                <!--********** Search **********-->
                <mat-form-field id="search-field-container" class="search-field" floatLabel="never">
                    <input
                        matInput
                        placeholder="Suchen"
                        [(ngModel)]="searchTerm"
                        (ngModelChange)="updateSearchTerm()"
                        [axAutofocus]="true" />
                    <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>
                <!--********** Sort Templates **********-->
                <div class="toolbar">
                    <mat-icon
                        class="toolbar-icon add-template-on-click"
                        [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                        (click)="addTextTemplate()"
                        matTooltip="Neue Vorlage">
                        add
                    </mat-icon>
                    <mat-icon
                        class="toolbar-icon"
                        [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                        (click)="sortByTitle()"
                        matTooltip="Nach Titel sortieren">
                        text_rotate_vertical
                    </mat-icon>
                </div>
            </div>
            <div id="searchterm-prevents-drag-and-drop-warning" class="label" *ngIf="!!searchTerm">
                Drag & Drop nur ohne Suchfilter möglich
            </div>
            <!--============================================-->
            <!-- END Search & Sort -->
            <!--============================================-->
            <div id="list" cdkDropList cdkDropListLockAxis="y" (cdkDropListDropped)="handleReorderingDrop($event)">
                <div
                    class="list-item record"
                    *ngFor="let messageTemplate of filteredMessageTemplates"
                    [ngClass]="{ active: selectedTemplate === messageTemplate }"
                    (click)="selectMessageTemplate(messageTemplate)"
                    (dblclick)="useTemplate(messageTemplate)"
                    cdkDrag
                    cdkDragBoundary="#list"
                    [cdkDragDisabled]="!!searchTerm || !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')">
                    <mat-icon class="drag-handle medium-icon" *ngIf="isTouchOnly()" cdkDragHandle>
                        drag_indicator
                    </mat-icon>

                    <div class="list-item-title-and-text-container">
                        <div class="list-item-title" *ngIf="messageTemplate.title">{{ messageTemplate.title }}</div>
                        <div class="list-item-title label" *ngIf="!messageTemplate.title">Noch kein Titel</div>
                    </div>

                    <!--********** Icons for default templates **********-->
                    <div class="list-item-icons-container">
                        <mat-icon
                            class="list-item-document-group-icon"
                            *ngIf="isTemplateDefault(messageTemplate, 'report')"
                            matTooltip="Standardvorlage für Gutachten. Sie wird automatisch eingefügt.">
                            assignment
                        </mat-icon>
                        <mat-icon
                            class="list-item-document-group-icon"
                            *ngIf="isTemplateDefault(messageTemplate, 'remoteSignatureEmail')"
                            matTooltip="Standardvorlage für Unterschrift online einholen">
                            signature
                        </mat-icon>
                        <div
                            class="list-item-document-group-icon"
                            *ngIf="isTemplateDefault(messageTemplate, 'repairConfirmation')"
                            matTooltip="Standardvorlage für Reparaturbestätigungen. Sie wird automatisch eingefügt.">
                            <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                enable-background="new 0 0 24 24"
                                xml:space="preserve">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    fill="#A1A1A1"
                                    d="M19.692,4.524l-4.166,4.161L12.939,6.1l4.093-4.09 c-2.175-1.017-4.835-0.648-6.631,1.145C8.719,4.835,8.283,7.278,9.07,9.366L1.867,16.56c-0.878,0.877-0.878,2.301,0,3.179
                                c0.878,0.876,2.303,0.876,3.182,0l7.109-7.101c2.158,0.963,4.774,0.578,6.544-1.191C20.582,9.57,20.893,6.747,19.692,4.524z"></path>
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    fill="#A1A1A1"
                                    d="M13.652,18.171l2.183,2.149l5.041-5.508l1.449,1.474l-6.307,7.076l-3.763-3.72L13.652,18.171z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div
                    id="empty-list-placeholder"
                    *ngIf="messageTemplates.length > 0 && filteredMessageTemplates.length === 0">
                    <h4>Keine Einträge sichtbar</h4>
                    {{ messageTemplates.length }} Einträge durch Suche & Filter ausgeblendet
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Message Template List -->
        <!--============================================-->

        <!--============================================-->
        <!-- Message Template Details -->
        <!--============================================-->
        <div id="details-column" class="card">
            <mat-icon class="close-icon" (click)="closeTemplateSelector()">close</mat-icon>
            <div id="heading-and-toolbar-container">
                <h2 id="details-heading" [ngSwitch]="templateType">
                    <span *ngSwitchCase="'coverLetter'">Anschreibenvorlagen</span>
                    <span *ngSwitchCase="'email'">E-Mailvorlagen</span>
                    <span *ngSwitchCase="'invoiceEmail'">E-Mailvorlagen Rechnung</span>
                    <span *ngSwitchCase="'invoiceLetter'">Rechnungsdokumente Vorlagen</span>
                    <span *ngSwitchCase="'expertStatementLetter'">Stellungnahme Vorlagen</span>
                    <span *ngSwitchCase="'remoteSignatureEmail'">Online unterschreiben</span>
                    <span
                        class="help-indicator"
                        matTooltip="Setze Platzhalter in Betreff und Nachricht ein, indem Du {MeinPlatzhalter} tippst. Zum Beispiel: {Gutachten.Aktenzeichen}">
                        ?
                    </span>
                </h2>
                <div id="toolbar">
                    <mat-icon
                        class="toolbar-icon"
                        (click)="enterEditMode()"
                        matTooltip="Bearbeiten"
                        *ngIf="!inEditMode && !isSmallScreen() && user.accessRights.editTextsAndDocumentBuildingBlocks">
                        edit
                    </mat-icon>
                    <mat-icon
                        class="toolbar-icon"
                        *ngIf="user.accessRights.editTextsAndDocumentBuildingBlocks"
                        [matMenuTriggerFor]="templateDetailsMenu">
                        more_vert
                    </mat-icon>
                    <mat-menu #templateDetailsMenu="matMenu">
                        <!--********** Report cover letter and email **********-->
                        <ng-container
                            *ngIf="
                                templateType !== 'invoiceEmail' &&
                                    templateType !== 'invoiceLetter' &&
                                    templateType !== 'expertStatementLetter' &&
                                    templateType !== 'remoteSignatureEmail';
                                selectedTemplate
                            ">
                            <a
                                mat-menu-item
                                *ngIf="!isTemplateDefault(selectedTemplate, 'report')"
                                (click)="setStandardTemplate(selectedTemplate, 'report')"
                                matTooltip="Diese Vorlage automatisch diesen Empfängertyp einfügen">
                                Standard für Gutachten
                            </a>
                            <a
                                mat-menu-item
                                *ngIf="isTemplateDefault(selectedTemplate, 'report')"
                                (click)="setStandardTemplate(null, 'report')">
                                Standard für Gutachten aufheben
                            </a>
                            <a
                                mat-menu-item
                                *ngIf="!isTemplateDefault(selectedTemplate, 'repairConfirmation')"
                                (click)="setStandardTemplate(selectedTemplate, 'repairConfirmation')"
                                matTooltip="Diese Vorlage automatisch für Reparaturbestätigungen an diesen Empfängertyp einfügen">
                                Standard für Reparaturbestätigung
                            </a>
                            <a
                                mat-menu-item
                                *ngIf="isTemplateDefault(selectedTemplate, 'repairConfirmation')"
                                (click)="setStandardTemplate(null, 'repairConfirmation')">
                                Standard für Reparaturbestätigung aufheben
                            </a>
                        </ng-container>
                        <!--********** Custom invoice email **********-->
                        <ng-container *ngIf="templateType === 'invoiceEmail'">
                            <a
                                mat-menu-item
                                *ngIf="!isTemplateDefault(selectedTemplate, 'invoiceEmail')"
                                (click)="setStandardTemplate(selectedTemplate, 'invoiceEmail')">
                                Als Standard setzen
                            </a>
                            <a
                                mat-menu-item
                                *ngIf="isTemplateDefault(selectedTemplate, 'invoiceEmail')"
                                (click)="setStandardTemplate(null, 'invoiceEmail')">
                                Standard aufheben
                            </a>
                        </ng-container>

                        <!--********** Remote signature email **********-->
                        <ng-container *ngIf="templateType === 'remoteSignatureEmail'">
                            <a
                                mat-menu-item
                                *ngIf="!isTemplateDefault(selectedTemplate, 'remoteSignatureEmail')"
                                (click)="setStandardTemplate(selectedTemplate, 'remoteSignatureEmail')">
                                Als Standard setzen
                            </a>
                            <a
                                mat-menu-item
                                *ngIf="isTemplateDefault(selectedTemplate, 'remoteSignatureEmail')"
                                (click)="setStandardTemplate(null, 'remoteSignatureEmail')">
                                Standard aufheben
                            </a>
                        </ng-container>

                        <a mat-menu-item (click)="copyTextTemplate(selectedTemplate)">Kopieren</a>
                        <a mat-menu-item (click)="deleteTextTemplate(selectedTemplate)">Löschen</a>
                    </mat-menu>
                </div>
            </div>

            <!--********** Display container **********-->
            <div
                id="details-display-container"
                [class.text-templates-access-right-missing]="!user?.accessRights.editTextsAndDocumentBuildingBlocks"
                *ngIf="selectedTemplate && !inEditMode">
                <div class="template-title-container" (click)="enterEditMode()">
                    <div class="label">Titel</div>
                    <div *ngIf="selectedTemplate.title">{{ selectedTemplate.title }}</div>
                    <div *ngIf="!selectedTemplate.title" class="italic">Noch kein Titel</div>
                </div>
                <div class="subject-container" (click)="enterEditMode()">
                    <div class="label">Betreff</div>
                    <div *ngIf="selectedTemplate.subject">{{ subjectPreview }}</div>
                    <div *ngIf="!selectedTemplate.subject" class="italic">Noch kein Betreff</div>
                </div>
                <div class="message-body-container" (click)="enterEditMode()">
                    <div class="label">Nachricht</div>
                    <div
                        *ngIf="selectedTemplate.body"
                        [quillContent]="bodyPreview"
                        [isEmailQuill]="['email', 'invoiceEmail', 'remoteSignatureEmail'].includes(templateType)"></div>
                    <div *ngIf="!selectedTemplate.body" class="italic">Noch kein Textkörper</div>
                </div>
            </div>

            <!--********** Edit container **********-->
            <div id="details-edit-container" *ngIf="inEditMode">
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        placeholder="Titel"
                        [(ngModel)]="selectedTemplate.title"
                        (change)="saveTextTemplate(selectedTemplate); filterAndSortTemplates()" />
                </mat-form-field>

                <placeholder-autocomplete-input
                    class="subject-container mat-block"
                    placeholder="Betreff"
                    [(text)]="selectedTemplate.subject"
                    (change)="saveTextTemplate(selectedTemplate)"
                    (textChange)="updateSubjectPreview()"></placeholder-autocomplete-input>

                <placeholder-autocomplete-quill
                    placeholder="Nachricht"
                    [(ngModel)]="selectedTemplate.body"
                    (change)="saveTextTemplate(selectedTemplate)"
                    (input)="updateBodyPreview()"
                    [isEmailQuill]="
                        ['email', 'invoiceEmail', 'remoteSignatureEmail'].includes(templateType)
                    "></placeholder-autocomplete-quill>

                <div id="instant-preview-container" *ngIf="selectedTemplate.subject || selectedTemplate.body">
                    <div id="subject-instant-preview">{{ subjectPreview }}</div>
                    <div
                        id="body-instant-preview"
                        [quillContent]="bodyPreview"
                        [isEmailQuill]="['email', 'invoiceEmail', 'remoteSignatureEmail'].includes(templateType)"></div>
                </div>
            </div>

            <!--********** Buttons **********-->
            <div id="details-buttons-container" *ngIf="selectedTemplate">
                <button
                    (click)="(selectedTemplate.subject || selectedTemplate.body) && useTemplate(selectedTemplate)"
                    *ngIf="!inEditMode"
                    [ngClass]="{ disabled: !selectedTemplate.subject && !selectedTemplate.body }"
                    [matTooltip]="
                        !selectedTemplate.subject && !selectedTemplate.body
                            ? 'Es sind weder Betreff noch Nachricht vorhanden'
                            : ''
                    ">
                    Vorlage übernehmen
                </button>
                <button (click)="leaveEditMode()" *ngIf="inEditMode">Bearbeitung beenden</button>
            </div>

            <!--********** No Templates Container **********-->
            <div id="empty-template-list-container" *ngIf="messageTemplates.length === 0">
                <img id="no-data-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                <div id="no-data-to-display-heading" class="bold">
                    Keine Vorlagen
                    <span *ngIf="translateTextTemplateRole(recipientRole)">
                        vom Typ {{ translateTextTemplateRole(recipientRole) }}
                    </span>
                    vorhanden
                    <span
                        class="help-indicator"
                        matTooltip="autoiXpert zeigt dir nur die Vorlagen an, die für den ausgewählten Empfängertyp relevant sind.">
                        ?
                    </span>
                </div>
                <div id="no-data-to-display-text">
                    Vorlagen sparen eine Menge Zeit und Arbeit. Lass uns eine anlegen!
                </div>
                <button
                    class="add-template-on-click"
                    [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                    (click)="addTextTemplate()">
                    Neue Vorlage
                </button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Message Template Details -->
        <!--============================================-->
    </div>
</div>

import { LineItem } from '../../models/invoices/line-item';
import { convertToEuro } from '../placeholder-values/convert-to-euro';
import { convertHtmlToPlainText } from '../placeholder-values/strip-html';

export function getFeeSetLineItemDescription(lineItem: LineItem, options: { includeVatTax?: boolean } = {}): string {
    const description = convertHtmlToPlainText(lineItem.description);
    const taxMultiplier = options.includeVatTax ? 1.19 : 1;
    const taxIdentifier = options.includeVatTax ? 'brutto' : 'netto';
    const lineItemUnit = lineItem.unit?.trim() ?? '';

    switch (lineItemUnit) {
        case '':
            return `${description}: ${convertToEuro(lineItem.unitPrice * taxMultiplier)} ${taxIdentifier}.`;

        case 'Stunde':
        case 'Stunden':
            return `${description}: ${convertToEuro(lineItem.unitPrice * taxMultiplier)} ${taxIdentifier} / Stunde.`;

        case 'km':
        case 'Stück':
            return `${description}: ${convertToEuro(
                lineItem.unitPrice * taxMultiplier,
            )} ${taxIdentifier} / ${lineItemUnit}.`;

        case 'Pauschal':
            return `${description}: ${convertToEuro(lineItem.unitPrice * taxMultiplier)} ${taxIdentifier} pauschal.`;

        default:
            return `${description}: ${convertToEuro(
                lineItem.unitPrice * taxMultiplier,
            )} ${taxIdentifier} ${lineItemUnit}.`;
    }
}

export function getActiveFeeSetLineItemDescriptions(
    lineItems: LineItem[],
    options: { includeVatTax?: boolean } = {},
): string[] {
    return lineItems
        .filter((lineItem) => lineItem.active && lineItem.unitPrice)
        .sort((lineItem1, lineItem2) => lineItem1.position - lineItem2.position)
        .map((lineItem) => getFeeSetLineItemDescription(lineItem, options));
}

import { InvoiceNumberJournalEntry } from '@autoixpert/models/teams/invoice-number-journal-entry';
import { CounterResetCycle } from '@autoixpert/models/teams/invoice-number-or-report-token-counter';

export function getInvoiceNumberJournalEntryDescription(journalEntry: InvoiceNumberJournalEntry): {
    description: string;
    details?: string;
} {
    switch (journalEntry.entryType) {
        case 'invoiceNumberChangedManually':
        case 'invoiceNumberGeneratedManually':
        case 'invoiceNumberGeneratedOnCreation':
        case 'invoiceNumberGeneratedOnAmendmentReportCreation':
        case 'invoiceNumberGeneratedOnInvoiceCancellation': {
            if (journalEntry.previousInvoiceNumber) {
                let invoiceNumberOrCounterEvent = journalEntry.invoiceNumber || '';
                invoiceNumberOrCounterEvent += ` (vorher: ${journalEntry.previousInvoiceNumber})`;
                return { description: invoiceNumberOrCounterEvent };
            }
            return { description: journalEntry.invoiceNumber || '' };
        }

        case 'invoiceOrReportCounterCreated': {
            return { description: 'Zählerkreis erstellt' };
        }

        case 'invoiceOrReportCounterChangedByResetCycle': {
            return {
                description: 'Zähler gesetzt auf ' + journalEntry.counterCount,
                details: `Der Zähler wurde von ${journalEntry.previousCounterCount} auf ${journalEntry.counterCount} gesetzt.`,
            };
        }

        case 'invoiceOrReportCounterChanged': {
            if (journalEntry.counterCount !== undefined) {
                if (journalEntry.previousCounterCount !== undefined) {
                    return {
                        description: 'Zähler gesetzt auf ' + journalEntry.counterCount,
                        details: `Der Zähler wurde von ${journalEntry.previousCounterCount} auf ${journalEntry.counterCount} gesetzt.`,
                    };
                }
                return { description: 'Zähler gesetzt auf ' + journalEntry.counterCount };
            }

            if (journalEntry.counterPattern) {
                if (journalEntry.previousCounterPattern) {
                    return {
                        description: 'Muster gesetzt: ' + journalEntry.counterPattern,
                        details: `Das Muster wurde von ${journalEntry.previousCounterPattern} auf ${journalEntry.counterPattern} gesetzt.`,
                    };
                }
                return {
                    description: 'Muster gesetzt: ' + journalEntry.counterPattern,
                };
            }

            if (journalEntry.reportInvoiceNumberPattern) {
                if (journalEntry.previousReportInvoiceNumberPattern) {
                    return {
                        description: 'Unterzähler-Muster gesetzt: ' + journalEntry.reportInvoiceNumberPattern,
                        details: `Das Unterzähler-Muster wurde von ${journalEntry.previousReportInvoiceNumberPattern} auf ${journalEntry.reportInvoiceNumberPattern} gesetzt.`,
                    };
                }
                return {
                    description: 'Unterzähler-Muster gesetzt: ' + journalEntry.reportInvoiceNumberPattern,
                };
            }

            if (journalEntry.useCancellationInvoiceSuffix === true) {
                return { description: 'Einstellung: Storno Suffix' };
            }

            if (journalEntry.useCancellationInvoiceSuffix === false) {
                if (journalEntry.invoiceNumberConfigId) {
                    return { description: 'Einstellung: Storno fortlaufend' };
                }
                return { description: 'Einstellung: Storno Unterzähler' };
            }

            if (journalEntry.cancellationSuffix) {
                if (journalEntry.previousCancellationSuffix) {
                    return {
                        description: 'Storno Suffix gesetzt: ' + journalEntry.cancellationSuffix,
                        details: `Das Storno Suffix wurde von ${journalEntry.previousCancellationSuffix} auf ${journalEntry.cancellationSuffix} gesetzt.`,
                    };
                }
                return {
                    description: 'Storno Suffix gesetzt: ' + journalEntry.cancellationSuffix,
                };
            }

            if (journalEntry.reportInvoiceCancellationSuffix) {
                if (journalEntry.previousReportInvoiceCancellationSuffix) {
                    return {
                        description:
                            'Unterzähler-Storno-Suffix gesetzt: ' + journalEntry.reportInvoiceCancellationSuffix,
                        details: `Das Unterzähler-Storno-Suffix wurde von ${journalEntry.previousReportInvoiceCancellationSuffix} auf ${journalEntry.reportInvoiceCancellationSuffix} gesetzt.`,
                    };
                }
                return {
                    description: 'Unterzähler-Storno-Suffix gesetzt: ' + journalEntry.reportInvoiceCancellationSuffix,
                };
            }

            if (journalEntry.resetCycle) {
                if (journalEntry.previousResetCycle) {
                    return {
                        description: `Einstellung: Zähler ${getCounterResetCycleLabel(journalEntry.resetCycle)} zurücksetzen`,
                        details: `Die Einstellung wurde von ${getCounterResetCycleLabel(journalEntry.previousResetCycle)} zurücksetzen auf ${getCounterResetCycleLabel(journalEntry.resetCycle)} zurücksetzen geändert.`,
                    };
                }
                return {
                    description: `Einstellung: Zähler ${getCounterResetCycleLabel(journalEntry.resetCycle)} zurücksetzen`,
                };
            }

            if (journalEntry.leadingCounter) {
                return {
                    description: `Einstellung: ${journalEntry.leadingCounter === 'reportToken' ? 'Aktenzeichen' : 'Rechnungszähler'} führt`,
                };
            }

            if (journalEntry.syncWithInvoiceCounter === true) {
                return { description: 'Einstellung: Rechnungsnummern koppeln' };
            }

            if (journalEntry.syncWithInvoiceCounter === false) {
                return { description: 'Einstellung: Rechnungsnummern nicht koppeln' };
            }

            if (journalEntry.startReportInvoiceSubCounterWithSecondInvoice === true) {
                return { description: 'Einstellung: Unterzähler ab 2. Rechnung starten' };
            }

            if (journalEntry.startReportInvoiceSubCounterWithSecondInvoice === false) {
                return { description: 'Einstellung: Unterzähler ab 1. Rechnung starten' };
            }

            break;
        }

        case 'invoiceOrReportCounterDeleted': {
            return { description: 'Zählerkreis gelöscht' };
        }

        case 'reportInvoiceSubCounterChanged': {
            if (journalEntry.previousCounterCount) {
                return {
                    description: `Zähler gesezt auf ${journalEntry.counterCount}`,
                    details: `Der Zähler wurde von ${journalEntry.previousCounterCount} auf ${journalEntry.counterCount} gesetzt.`,
                };
            }
            return {
                description: `Zähler gesezt auf ${journalEntry.counterCount}`,
            };
        }
    }

    return undefined;
}

export function getCounterResetCycleLabel(resetCycle: CounterResetCycle): string {
    switch (resetCycle) {
        case 'never': {
            return 'nie';
        }

        case 'annually': {
            return 'jährlich';
        }

        case 'monthly': {
            return 'monatlich';
        }

        case 'daily': {
            return 'täglich';
        }
    }
}

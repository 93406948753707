import { Injectable } from '@angular/core';
import { NotificationType } from 'angular2-notifications/dist';
import { detectBrowser } from '@autoixpert/lib/browser/detect-browser';
import { ToastService } from './toast.service';

@Injectable()
export class NewWindowService {
    constructor(private toastService: ToastService) {}

    public open(url: string, target: string = '_blank', features: string = ''): Window {
        // Call the
        const newWindow = window.open(url, target, features);
        // Detect if the popup is blocked. If so, tell the user.
        if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
            let allowPopupsText: string;
            switch (detectBrowser().browser) {
                case 'Chrome':
                    allowPopupsText =
                        "Über dieses Icon im oberen Bereich des Fensters kannst du neue Fenster zulassen:<br><img src='/assets/images/icons/popup-blocked-chrome.png'>";
                    break;
                case 'Firefox':
                    allowPopupsText =
                        "<a href='https://support.mozilla.org/de/kb/pop-blocker-einstellungen-ausnahmen-problemloesung#w_was-sind-pop-ups' target='_blank'>So geht's!</a>";
                    break;
                case 'Safari':
                case 'Internet Explorer':
                case 'Edge':
                case 'Opera':
                default:
                    allowPopupsText =
                        'Bitte lasse das Öffnen von Popup-Fenstern zu.<br><br><strong>Für Apple-Nutzer:</strong><br>Einstellungs-App deines Geräts > Safari > Deaktiviere "Pop-Ups blockieren"';
            }
            this.toastService.html(
                `<div class="sn-title">Bitte erlaube autoiXpert, neue Fenster zu öffnen.</div>
                       <div class="sn-content">${allowPopupsText}</div>`,
                NotificationType.Warn,
                { timeOut: 10_000 },
            );
        }
        return newWindow;
    }
}

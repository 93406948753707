<div id="two-column-layout-container" *ngIf="report">
    <!--============================================-->
    <!-- Basic Car Data -->
    <!--============================================-->
    <div id="basic-car-data-container" class="card card-with-padding" (change)="saveReport()">
        <h2>Basisdaten</h2>

        <!--********** Card Toolbar **********-->
        <div
            class="card-toolbar"
            *ngIf="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks') && !isReportLocked()">
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="basicCarDataSubmenu">more_vert</mat-icon>
            <mat-menu #basicCarDataSubmenu>
                <!--********** Always Display Vehicle Dimensions And Weight **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    (click)="
                        team.preferences.alwaysDisplayVehicleDimensionsAndWeight =
                            !team.preferences.alwaysDisplayVehicleDimensionsAndWeight;
                        alwaysDisplayVehicleDimensionsAndWeightChanged()
                    "
                    matTooltip="Abmessungen & Gewicht für alle Fahrzeugtypen einblenden, nicht nur für Nutzfahrzeuge">
                    <mat-icon>visibility</mat-icon>
                    Abmessungen & Gewicht immer einblenden
                    <mat-slide-toggle
                        class="slide-toggle-small margin-left-15"
                        [(ngModel)]="team.preferences.alwaysDisplayVehicleDimensionsAndWeight"
                        (ngModelChange)="alwaysDisplayVehicleDimensionsAndWeightChanged()"
                        (click)="$event.stopPropagation()"></mat-slide-toggle>
                </a>
            </mat-menu>
        </div>

        <!--============================================-->
        <!-- VIN Input -->
        <!--============================================-->

        <!--============================================-->
        <!-- VIN Provider Selection -->
        <!--============================================-->
        <section id="vin-provider-selection-section" *ngIf="isIdentificationProviderSelectionVisible()">
            <!--********** Audatex **********-->
            <div
                class="vin-provider-container"
                [ngClass]="{
                    selected: report.car.identificationProvider === 'audatex',
                    unselectable: isReportLocked(),
                }"
                *ngIf="isAudatexVinProviderAvailable() || report.car.identificationProvider === 'audatex'"
                (click)="selectIdentificationProvider('audatex'); saveReport()">
                <img
                    id="qapter-logo"
                    src="/assets/images/logos/qapter-logo-black-orange.svg"
                    alt=""
                    matTooltip="Fahrzeug mit Audatex/Qapter identifizieren" />

                <!--********** Favorite Markter **********-->
                <div
                    class="vin-provider-favorite-marker"
                    (click)="rememberDefaultVehicleIdentificationProvider('audatex')"
                    [ngClass]="{ active: userPreferences.vehicleIdentificationProvider === 'audatex' }"
                    [matTooltip]="
                        userPreferences.vehicleIdentificationProvider === 'audatex'
                            ? 'Qapter ist Standard für neue Gutachten'
                            : 'Qapter als Standard für neue Gutachten setzen'
                    ">
                    <mat-icon class="small-icon">star</mat-icon>
                </div>
                <mat-icon
                    class="small-icon vin-requested-icon"
                    *ngIf="report.car.audatexIdentification.audatexCode"
                    matTooltip="VIN über Audatex abgefragt">
                    check
                </mat-icon>
            </div>

            <!--********** DAT **********-->
            <div
                class="vin-provider-container"
                [ngClass]="{ selected: report.car.identificationProvider === 'dat', unselectable: isReportLocked() }"
                *ngIf="isDatVinProviderAvailable() || report.car.identificationProvider === 'dat'"
                (click)="selectIdentificationProvider('dat'); saveReport()">
                <img
                    src="/assets/images/logos/dat-logo-no-text.png"
                    alt=""
                    matTooltip="Fahrzeug mit DAT identifizieren" />

                <!--********** Favorite Markter **********-->
                <div
                    class="vin-provider-favorite-marker"
                    (click)="rememberDefaultVehicleIdentificationProvider('dat')"
                    [ngClass]="{ active: userPreferences.vehicleIdentificationProvider === 'dat' }"
                    [matTooltip]="
                        userPreferences.vehicleIdentificationProvider === 'dat'
                            ? 'DAT ist Standard für neue Gutachten'
                            : 'DAT als Standard für neue Gutachten setzen'
                    ">
                    <mat-icon class="small-icon">star</mat-icon>
                </div>
                <mat-icon
                    class="small-icon vin-requested-icon"
                    *ngIf="report.car.datIdentification.datEuropaCode"
                    matTooltip="VIN über DAT abgefragt">
                    check
                </mat-icon>
            </div>

            <!--********** GT Motive **********-->
            <div
                class="vin-provider-container"
                [ngClass]="{
                    selected: report.car.identificationProvider === 'gtmotive',
                    unselectable: isReportLocked(),
                }"
                *ngIf="isGtmotiveVinProviderAvailable() || report.car.identificationProvider === 'gtmotive'"
                (click)="selectIdentificationProvider('gtmotive'); saveReport()">
                <img
                    src="/assets/images/logos/gtmotive-logo.png"
                    alt=""
                    matTooltip="Fahrzeug mit GT Motive identifizieren" />

                <!--********** Favorite Markter **********-->
                <div
                    class="vin-provider-favorite-marker"
                    (click)="rememberDefaultVehicleIdentificationProvider('gtmotive')"
                    [ngClass]="{ active: userPreferences.vehicleIdentificationProvider === 'gtmotive' }"
                    [matTooltip]="
                        userPreferences.vehicleIdentificationProvider === 'gtmotive'
                            ? 'GT Motive ist Standard für neue Gutachten'
                            : 'GT Motive als Standard für neue Gutachten setzen'
                    ">
                    <mat-icon class="small-icon">star</mat-icon>
                </div>
                <mat-icon
                    class="small-icon vin-requested-icon"
                    *ngIf="report.car.gtmotiveIdentification.uniqueModelIdentifier"
                    matTooltip="VIN über GT Motive abgefragt">
                    check
                </mat-icon>
            </div>
        </section>
        <!--============================================-->
        <!-- END VIN Provider Selection -->
        <!--============================================-->

        <vin-input
            [(car)]="report.car"
            [reportId]="report._id"
            [report]="report"
            [reportType]="report.type"
            (carChange)="saveReport()"
            (vinResponses)="handleVinResponses($event); saveReport()"
            [errorMessageClearer$]="vinErrorMessageClearer$"
            [disabled]="isReportLocked()"></vin-input>

        <!--********** DAT Downtime warning **********-->
        <context-sensitive-downtime-notification
            *ngIf="report.car.identificationProvider === 'dat'"
            childClass="margin-bottom-15"
            [processContext]="'vinQuery'"></context-sensitive-downtime-notification>

        <!--============================================-->
        <!-- END VIN Input -->
        <!--============================================-->

        <div class="info-note margin-bottom-15" *ngIf="newImportedAxles" @fadeInAndSlide>
            <mat-icon class="warning">info</mat-icon>
            <div>
                <h3>Reifendaten verfügbar</h3>
                <div [matTooltip]="getNewAxleConfigurationTooltip()">
                    Durch den Import sind neue Reifendaten verfügbar.
                    <a style="cursor: pointer" (click)="insertNewAxleConfiguration()">
                        Bereits eingegebene überschreiben?
                    </a>
                </div>
            </div>
            <mat-icon
                id="remove-date-filter-icon"
                class="toolbar-icon medium-icon no-color"
                (click)="newImportedAxles = null"
                matTooltip="Datumsfilter entfernen">
                close
            </mat-icon>
        </div>

        <!--============================================-->
        <!-- Audatex Code -->
        <!--============================================-->
        <div id="audatex-code-container" *ngIf="report.car.identificationProvider === 'audatex'">
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Audatex-Code"
                    [(ngModel)]="report.car.audatexIdentification.audatexCode"
                    [disabled]="report.state === 'done'"
                    maxlength="10"
                    (keyup)="getAudatexCodeOnEnter($event)" />
            </mat-form-field>
            <img
                id="audatex-code-request-pending-icon"
                *ngIf="audatexCodePending"
                src="/assets/images/icons/loading-indicator.svg"
                matTooltip="Die Audatex-Code-Abfrage läuft..." />
            <div class="input-toolbar" *ngIf="!audatexCodePending">
                <mat-icon
                    id="open-audatex-car-selector-icon"
                    class="input-toolbar-icon no-repositioning"
                    *ngIf="!isReportLocked()"
                    (click)="openAudatexCarSelector()"
                    matTooltip="Audatex-Fahrzeugauswahl öffnen">
                    search
                </mat-icon>

                <!--********** Import Audatex results **********-->
                <mat-icon
                    id="import-audatex-identification-icon"
                    class="input-toolbar-icon no-repositioning"
                    *ngIf="!isReportLocked() && report.audatexTaskId && !audatexCodePending"
                    (click)="importAudatexIdentification()"
                    matTooltip="Fahrzeug-Daten von Audatex importieren">
                    file_download
                </mat-icon>
                <img
                    class="input-toolbar-icon"
                    *ngIf="audatexCodePending"
                    src="/assets/images/icons/loading-indicator.svg"
                    matTooltip="Die Audatex-Code-Abfrage läuft..." />
            </div>
        </div>
        <!--============================================-->
        <!-- END Audatex Code -->
        <!--============================================-->

        <!--============================================-->
        <!-- DAT€Code -->
        <!--============================================-->
        <div
            id="dat-e-code-and-market-index-container"
            *ngIf="report.car.identificationProvider === 'dat' && report.type !== 'oldtimerValuationSmall'">
            <div id="dat-e-code-container">
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        id="dat-e-code-input"
                        placeholder="DAT€Code"
                        [(ngModel)]="report.car.datIdentification.datEuropaCode"
                        [disabled]="report.state === 'done'"
                        maxlength="15"
                        (keyup)="getDatECodeOnEnter($event)" />
                </mat-form-field>
                <img
                    id="dat-e-code-request-pending-icon"
                    *ngIf="datDossierRequestPending"
                    src="/assets/images/icons/loading-indicator.svg"
                    matTooltip="Kommuniziere mit DAT..." />

                <div class="input-toolbar" *ngIf="!isReportLocked() && !datDossierRequestPending">
                    <mat-icon
                        class="input-toolbar-icon no-repositioning"
                        *ngIf="
                            !report.car.datIdentification.datEuropaCode &&
                            !report.valuation?.datValuation?.dossierId &&
                            !report.damageCalculation?.repair?.datCalculation?.dossierId
                        "
                        [matMenuTriggerFor]="datDossierCreationSubmenu"
                        matTooltip='Eine neue DAT-Kalkulation oder Bewertung erstellen und das Fahrzeug über die Oberfläche der DAT verbinden. Wenn du bereits eine DAT-Kalkulation oder DAT-Bewertung hast, kannst du diese im Tab "Kalkulation" verknüpfen und danach hier die Fahrzeugdaten importieren.'>
                        search
                    </mat-icon>
                    <mat-menu #datDossierCreationSubmenu>
                        <a
                            mat-menu-item
                            matTooltip="Erstellt eine neue, leere DAT-Kalkulation. In der Kalkulation kann das Fahrzeug über die Oberfläche der DAT identifiziert werden. Die Fahrzeugdaten werden dann in das Gutachten übernommen. Eine bestehende Kalkulation kann im Tab 'Kalkulation' verknüpft werden."
                            (click)="createAndOpenDatDamageCalculation()">
                            Neue DAT-Kalkulation erstellen
                        </a>

                        <a
                            mat-menu-item
                            matTooltip="Erstellt eine neue, leere DAT-Bewertung. In der Bewertung kann das Fahrzeug über die Oberfläche der DAT identifiziert werden. Die Fahrzeugdaten werden dann in das Gutachten übernommen. Eine bestehende Bewertung kann im Tab 'Kalkulation' verknüpft werden."
                            (click)="createAndOpenDatValuation()">
                            Neue DAT-Bewertung erstellen
                        </a>
                    </mat-menu>
                    <mat-icon
                        class="input-toolbar-icon no-repositioning"
                        *ngIf="
                            report.car.datIdentification?.datEuropaCode ||
                            report.valuation?.datValuation?.dossierId ||
                            report.damageCalculation?.repair?.datCalculation?.dossierId
                        "
                        [matMenuTriggerFor]="datDossierImportSubmenu"
                        matTooltip="Fahrzeugdaten aus DAT-Kalkulation oder DAT-Bewertung importieren.">
                        file_download
                    </mat-icon>
                    <mat-menu #datDossierImportSubmenu>
                        <a
                            *ngIf="report.car.datIdentification?.datEuropaCode"
                            mat-menu-item
                            matTooltip="Frage die Fahrzeugdaten über den DAT€Code ab."
                            (click)="getDatECodeData()">
                            DAT€Code abfragen
                        </a>
                        <a
                            mat-menu-item
                            [ngClass]="{ disabled: !report.damageCalculation?.repair?.datCalculation?.dossierId }"
                            [matTooltip]="
                                report.damageCalculation?.repair?.datCalculation?.dossierId
                                    ? 'Die Fahrzeugdaten werden aus der verknüpften DAT-Kalkulation importiert.'
                                    : 'Verknüpfe eine DAT-Kalkulation um die Fahrzeugdaten aus der DAT-Kalkulation zu importieren.'
                            "
                            (click)="getVehicleFromDatDamageCalculation()">
                            Fahrzeug aus DAT-Kalkulation laden
                        </a>
                        <a
                            mat-menu-item
                            [ngClass]="{ disabled: !report.valuation?.datValuation?.dossierId }"
                            [matTooltip]="
                                report.valuation?.datValuation?.dossierId
                                    ? 'Die Fahrzeugdaten werden aus der verknüpften DAT-Bewertung importiert.'
                                    : 'Verknüpfe eine DAT-Bewertung um die Fahrzeugdaten aus der DAT-Bewertung zu importieren.'
                            "
                            (click)="getVehicleFromDatValuation()">
                            Fahrzeug aus DAT-Bewertung laden
                        </a>
                    </mat-menu>
                </div>
            </div>
            <div id="dat-market-index-container">
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        id="dat-markt-index-input"
                        placeholder="Marktindex"
                        [(ngModel)]="report.car.datIdentification.marketIndex"
                        [disabled]="report.state === 'done'"
                        maxlength="5"
                        matTooltip="Der Marktindex ist relevant für die DAT-Bewertung und beinhaltet die Sammlung kaufmännischer Betrachtungen eines anhand des DAT €uropa-Code® technisch eindeutig identifizierten Fahrzeuges, z. B. eine Fan-Edition zur Fußball-WM, die anders bepreist wurde als ein vergleichbar ausgestattetes Fahrzeug."
                        (keyup)="getDatECodeOnEnter($event)" />
                </mat-form-field>
            </div>
        </div>
        <div class="alert alert-danger" *ngIf="error.datECode" [innerHTML]="error.datECode"></div>
        <div class="alert alert-danger" *ngIf="warning.datECode" [innerHTML]="warning.datECode"></div>
        <!--============================================-->
        <!-- END DAT€Code -->
        <!--============================================-->

        <!--============================================-->
        <!-- GT Motive Unique Model Code (UMC) -->
        <!--============================================-->
        <div id="gtmotive-umc-container" *ngIf="report.car.identificationProvider === 'gtmotive'">
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="GT-Motive-Code"
                    [(ngModel)]="report.car.gtmotiveIdentification.uniqueModelIdentifier"
                    [disabled]="report.state === 'done'"
                    maxlength="10" />
            </mat-form-field>
            <img
                id="gtmotive-umc-request-pending-icon"
                *ngIf="gtmotiveUmcImportPending"
                src="/assets/images/icons/loading-indicator.svg"
                matTooltip="Die GT-Motive-Abfrage läuft..." />
            <div class="input-toolbar" *ngIf="!gtmotiveUmcImportPending">
                <mat-icon
                    id="open-gtmotive-car-selector-icon"
                    class="input-toolbar-icon no-repositioning"
                    *ngIf="!isReportLocked()"
                    (click)="openGtmotiveCarSelector()"
                    matTooltip="GT-Motive-Fahrzeugauswahl öffnen">
                    search
                </mat-icon>

                <!--********** Import GT Motive results **********-->
                <mat-icon
                    id="import-gtmotive-identification-icon"
                    class="input-toolbar-icon no-repositioning"
                    *ngIf="!isReportLocked() && report.gtmotiveEstimateId && !gtmotiveUmcImportPending"
                    (click)="importGtmotiveIdentification()"
                    matTooltip="Fahrzeug-Daten von GT Motive importieren">
                    file_download
                </mat-icon>
                <img
                    class="input-toolbar-icon"
                    *ngIf="gtmotiveUmcImportPending"
                    src="/assets/images/icons/loading-indicator.svg"
                    matTooltip="Die GT-Motive-Abfrage läuft..." />
            </div>
        </div>
        <!--============================================-->
        <!-- END GT Motive Unique Model Code (UMC) -->
        <!--============================================-->

        <!--********** Manufacturer & Model **********-->
        <div id="manufacturer-and-model-container">
            <div id="manufacturer-and-model-inputs-container">
                <!--********** Manufacturer **********-->
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        placeholder="Hersteller"
                        [(ngModel)]="report.car.make"
                        [matAutocomplete]="carMakeAutocomplete"
                        [disabled]="report.state === 'done'"
                        (ngModelChange)="filterManufacturers(report.car.make)"
                        (focus)="filterManufacturers(report.car.make)"
                        (change)="
                            clearBaseModelAutocompleteOptions();
                            updateDatCarInfo();
                            selectBaseModels(report.car.make);
                            saveReport()
                        "
                        matTooltip="Im Fahrzeugschein: Feld 'D.1'" />
                </mat-form-field>
                <mat-autocomplete #carMakeAutocomplete>
                    <mat-option
                        *ngFor="let manufacturerName of filteredManufacturers"
                        [value]="manufacturerName"
                        (click)="updateDatCarInfo(); selectBaseModels(report.car.make); saveReport()"
                        (mousedown)="$event.preventDefault()">
                        <div
                            class="car-make-option"
                            [ngClass]="{ 'no-icon': !iconForCarBrandExists(manufacturerName) }">
                            <img
                                [src]="iconFilePathForCarBrand(manufacturerName, 'large')"
                                alt="{{ manufacturerName }}"
                                class="car-make-option-image"
                                *ngIf="iconForCarBrandExists(manufacturerName)" />
                            <span class="car-make-option-name">{{ manufacturerName }}</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>

                <!--********** Model **********-->
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        placeholder="Haupttyp"
                        [(ngModel)]="report.car.model"
                        [disabled]="!report.car.make || report.state === 'done'"
                        [matAutocomplete]="carModelAutocomplete"
                        (ngModelChange)="filterCarModels(report.car.model)"
                        (focus)="filterCarModels(report.car.model)"
                        (change)="
                            clearSubModelAutocompleteOptions();
                            updateDatCarInfo();
                            selectSubModels(report.car.make, report.car.model);
                            saveReport()
                        "
                        matTooltip="Im Fahrzeugschein: Feld 'D.2'" />
                </mat-form-field>
                <mat-autocomplete #carModelAutocomplete>
                    <mat-option
                        *ngFor="let carModel of filteredBaseModels"
                        [value]="carModel.name"
                        (click)="updateDatCarInfo(); selectSubModels(report.car.make, report.car.model); saveReport()"
                        (mousedown)="$event.preventDefault()">
                        <div class="base-model-option">
                            <span class="base-model-name" [matTooltip]="carModel.name">{{ carModel.name }}</span>
                            <span class="base-model-manufacturing-time">
                                <!-- ab & bis are never displayed together to save space. -->
                                <span *ngIf="carModel.manufacturingStart">
                                    <ng-container *ngIf="!carModel.manufacturingEnd">ab</ng-container>
                                    {{ carModel.manufacturingStart }}
                                </span>
                                <span *ngIf="carModel.manufacturingEnd">bis {{ carModel.manufacturingEnd }}</span>
                            </span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
            <!--********** Manufacturer Icon **********-->
            <div id="manufacturer-icon-container" *ngIf="report.car.make && iconForCarBrandExists(report.car.make)">
                <img
                    [src]="iconFilePathForCarBrand(report.car.make, 'large')"
                    alt="Hersteller-Logo"
                    class="car-manufacturer-logo" />
            </div>
        </div>

        <!--********** Submodel **********-->
        <mat-form-field class="mat-block">
            <input
                matInput
                placeholder="Untertyp"
                [(ngModel)]="report.car.submodel"
                [disabled]="!report.car.make || !report.car.model || report.state === 'done'"
                [matAutocomplete]="carSubmodelAutocomplete"
                (ngModelChange)="filterSubModels(report.car.submodel)"
                (focus)="filterSubModels(report.car.submodel)"
                (change)="updateDatCarInfo(); saveReport()"
                matTooltip="Im Fahrzeugschein: Feld 'D.3'" />
        </mat-form-field>
        <mat-autocomplete #carSubmodelAutocomplete>
            <mat-option
                *ngFor="let subModel of filteredSubModels"
                [value]="subModel.name"
                (click)="updateDatCarInfo(); saveReport()"
                (mousedown)="$event.preventDefault()"
                class="base-model-option">
                <span class="base-model-name">{{ subModel.name }}</span>
            </mat-option>
        </mat-autocomplete>

        <!--***********************************************
        ** KBA Code
        ************************************************-->
        <div id="kba-code-container">
            <mat-form-field class="mat-block">
                <input
                    id="kba-input"
                    matInput
                    placeholder="Schlüsselnummer (KBA)"
                    [(ngModel)]="report.car.kbaCode"
                    [disabled]="report.state === 'done'"
                    (change)="transformKbaToUpperCase(); validateKbaFormat()"
                    (keyup)="triggerKbaVerificationOnEnter($event)"
                    matTooltip="Im Fahrzeugschein: Feld '2.1' und '2.2'" />
                <mat-hint class="error" *ngIf="kbaErrorMessage">{{ kbaErrorMessage }}</mat-hint>
            </mat-form-field>

            <img
                id="kba-code-request-pending-icon"
                *ngIf="kbaCodeRequestPending"
                src="/assets/images/icons/loading-indicator.svg"
                matTooltip="Die KBA-Code-Abfrage läuft..." />
            <div class="input-toolbar" *ngIf="report.car.kbaCode && !kbaCodeRequestPending">
                <mat-icon
                    id="get-kba-code-data-icon"
                    class="input-toolbar-icon no-repositioning"
                    (click)="!isReportLocked() && getKbaCodeData()"
                    [matTooltip]="getKbaTooltip()">
                    search
                </mat-icon>
            </div>
        </div>
        <!--***********************************************
        ** END KBA Code
        ************************************************-->

        <div id="performance-container" *ngIf="doesVehicleHaveMotor() || isBikeWithElectricMotor">
            <mat-form-field>
                <input
                    matInput
                    number-input
                    placeholder="Leistung (KW)"
                    [(number)]="report.car.performanceKW"
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="2"
                    [disabled]="report.state === 'done'"
                    (numberChange)="calculateHP(); saveReport()"
                    matTooltip="Im Fahrzeugschein: Feld 'P2'" />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    number-input
                    placeholder="Leistung (PS)"
                    [(number)]="performanceHP"
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="2"
                    [disabled]="report.state === 'done'"
                    (numberChange)="calculateKW(); saveReport()"
                    matTooltip="Im Fahrzeugschein: Feld 'P2' * 1,36" />
            </mat-form-field>
        </div>

        <div class="multiple-inputs-row">
            <!--********** Engine Configuration **********-->
            <autocomplete-with-memory
                *ngIf="
                    doesVehicleHaveMotor() &&
                    (doesVehicleHaveInternalCombustionEngine() || !doesVehicleHaveBatteryElectricEngine())
                "
                placeholder="Motorbauart"
                [(ngModel)]="report.car.engineConfiguration"
                (change)="saveReport()"
                autocompleteEntryType="engineConfiguration"
                [disabled]="isReportLocked()"
                matTooltip="z. B. Reihen-,
                Boxer- oder V-Motor"></autocomplete-with-memory>

            <!--********** Cylinders **********-->
            <mat-form-field
                class="mat-block"
                *ngIf="
                    doesVehicleHaveMotor() &&
                    (doesVehicleHaveInternalCombustionEngine() || !doesVehicleHaveBatteryElectricEngine())
                ">
                <input
                    matInput
                    number-input
                    placeholder="Zylinder"
                    [(number)]="report.car.numberOfCylinders"
                    [disabled]="isReportLocked()"
                    [maximumFractionDigits]="0"
                    [minimumFractionDigits]="0"
                    matTooltip="Anzahl Zylinder" />
            </mat-form-field>
        </div>

        <div class="multiple-inputs-row" *ngIf="doesVehicleHaveMotor() || isBikeWithElectricMotor">
            <!--============================================-->
            <!-- Transmission -->
            <!--============================================-->
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Getriebe"
                    [(ngModel)]="report.car.gearboxModelName"
                    [matAutocomplete]="transmissionAutocomplete"
                    [disabled]="report.state === 'done'"
                    (input)="filterTransmissionTypes(report.car.gearboxModelName)"
                    (change)="parseGearboxModelName(); saveReport()" />
                <mat-autocomplete #transmissionAutocomplete="matAutocomplete">
                    <mat-option
                        *ngFor="let transmissionType of filteredTransmissionTypes"
                        [value]="transmissionType"
                        (click)="parseGearboxModelName(); saveReport()"
                        (mousedown)="$event.preventDefault()">
                        {{ transmissionType }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <!--============================================-->
            <!-- END Transmission -->
            <!--============================================-->

            <!--********** Displacement **********-->
            <mat-form-field
                class="mat-block"
                *ngIf="
                    doesVehicleHaveMotor() &&
                    (doesVehicleHaveInternalCombustionEngine() || !doesVehicleHaveBatteryElectricEngine())
                ">
                <input
                    matInput
                    number-input
                    placeholder="Hubraum (ccm)"
                    [(number)]="report.car.engineDisplacement"
                    [disabled]="report.state === 'done'"
                    [maximumFractionDigits]="2"
                    [minimumFractionDigits]="0"
                    matTooltip="Im Fahrzeugschein: Feld 'P.1'" />
                <mat-hint
                    class="warning"
                    *ngIf="report.car.identificationProvider === 'dat' && engineDisplacementHasDecimals()">
                    DAT lehnt Nachkommastellen ab
                </mat-hint>
            </mat-form-field>

            <!--********** Battery Capacity **********-->
            <mat-form-field class="mat-block" *ngIf="doesVehicleHaveBatteryElectricEngine() || isBikeWithElectricMotor">
                <input
                    matInput
                    number-input
                    placeholder="Kapazität (kWh)"
                    [(number)]="report.car.batteryCapacity"
                    [disabled]="report.state === 'done'"
                    [maximumFractionDigits]="2"
                    [minimumFractionDigits]="0"
                    matTooltip="Batteriekapazität in kWh" />
            </mat-form-field>
        </div>

        <!--********** Production Year **********-->
        <year-input
            placeholder="Baujahr"
            [(date)]="report.car.productionYear"
            matTooltip="Datum im Format YYYY, z. B. 2007"
            [disabled]="report.state === 'done'"
            (dateChange)="saveReport()"></year-input>

        <div id="registration-container" class="multiple-inputs-grid">
            <date-input
                placeholder="Erstzulassung"
                [(date)]="report.car.firstRegistration"
                (dateChange)="
                    setLatestRegistration(report.car.firstRegistration); setNumberOfPreviousOwners(); saveReport()
                "
                [disabled]="report.state === 'done'"
                [error]="firstRegistrationIsBeforeProductionYear() ? 'Liegt vor Baujahr' : ''"
                matTooltip="Im Fahrzeugschein: Feld 'B'"></date-input>
            <date-input
                placeholder="Letzte Zulassung"
                [(date)]="report.car.latestRegistration"
                (dateChange)="setNumberOfPreviousOwners(); saveReport()"
                (blur)="saveReport()"
                [disabled]="report.state === 'done'"
                [error]="latestRegistrationIsBeforefirstRegistration() ? 'Liegt vor Erstzulassung' : ''"
                matTooltip="Im Fahrzeugschein: Feld 'I'"></date-input>

            <!--********** Only visible on tablets **********-->
            <mat-icon
                id="remove-latest-registration"
                *ngIf="report.car.latestRegistration"
                (click)="removeLatestRegistration()">
                delete
            </mat-icon>

            <!--********** Submenu **********-->
            <mat-icon
                id="registration-dates-submenu"
                class="input-external-icon"
                [matMenuTriggerFor]="registrationDatesSubmenu">
                more_vert
            </mat-icon>
            <mat-menu #registrationDatesSubmenu>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="
                        userPreferences.automaticallyInsertLatestRegistration =
                            !userPreferences.automaticallyInsertLatestRegistration
                    "
                    matTooltip="{{
                        userPreferences.automaticallyInsertLatestRegistration
                            ? 'Bei einer Änderung der Erstzulassung nicht automatisch mit dem gleichen Datum vorbelegen.'
                            : 'Bei einer Änderung der Erstzulassung automatisch mit dem gleichen Datum vorbelegen.'
                    }}">
                    <mat-icon>today</mat-icon>
                    {{
                        userPreferences.automaticallyInsertLatestRegistration
                            ? 'Letzte Zulassung nicht automatisch gleichsetzen'
                            : 'Letzte Zulassung automatisch gleichsetzen'
                    }}
                </a>
            </mat-menu>
        </div>

        <div
            class="info-note warning-note one-liner-note"
            *ngIf="isNumberOfPreviousOwnersImplausible()"
            @fadeInAndSlide>
            <mat-icon>warning</mat-icon>
            Wirklich null Vorbesitzer?
            <span
                class="help-indicator"
                matTooltip="Die erste und letzte Zulassung weichen voneinander ab. Sind null Vorbesitzer wirklich plausibel?">
                ?
            </span>
        </div>

        <!--============================================-->
        <!-- Dimensions & Weight -->
        <!--============================================-->
        <ng-container *ngIf="isDimensionAndWeightSectionVisible()">
            <!--********** Heading & Toolbar **********-->
            <div class="heading-and-toolbar-container">
                <h3>Abmessungen & Gewicht</h3>

                <!--********** Toolbar **********-->
                <div class="heading-toolbar" *ngIf="userIsAdmin() && !isReportLocked()">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="dimensionAndWeightSubmenu">more_vert</mat-icon>
                    <mat-menu #dimensionAndWeightSubmenu>
                        <!--********** Always Display Vehicle Dimensions And Weight **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-toggle"
                            (click)="
                                team.preferences.alwaysDisplayVehicleDimensionsAndWeight =
                                    !team.preferences.alwaysDisplayVehicleDimensionsAndWeight;
                                alwaysDisplayVehicleDimensionsAndWeightChanged()
                            "
                            matTooltip="Abmessungen & Gewicht für alle Fahrzeugtypen einblenden, nicht nur für Nutzfahrzeuge">
                            <mat-icon>visibility</mat-icon>
                            Abmessungen & Gewicht immer einblenden
                            <mat-slide-toggle
                                class="slide-toggle-small margin-left-15"
                                [(ngModel)]="team.preferences.alwaysDisplayVehicleDimensionsAndWeight"
                                (ngModelChange)="alwaysDisplayVehicleDimensionsAndWeightChanged()"
                                (click)="$event.stopPropagation()"></mat-slide-toggle>
                        </a>
                    </mat-menu>
                </div>
            </div>
            <!--============================================-->
            <!-- Weight -->
            <!--============================================-->
            <div class="multiple-inputs-row">
                <mat-form-field>
                    <input
                        matInput
                        number-input
                        [(number)]="report.car.unloadedWeight"
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0"
                        [disabled]="isReportLocked()"
                        placeholder="Leergewicht (kg)"
                        matTooltip="Im Fahrzeugschein: Feld 'G'" />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        number-input
                        [(number)]="report.car.maximumTotalWeight"
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0"
                        [disabled]="isReportLocked()"
                        placeholder="Zul. Gesamtgewicht (kg)"
                        matTooltip="Im Fahrzeugschein: Feld 'F.1/F.2'" />
                </mat-form-field>
            </div>
            <!--============================================-->
            <!-- END Weight -->
            <!--============================================-->

            <!--============================================-->
            <!-- Dimensions -->
            <!--============================================-->
            <div class="multiple-inputs-row">
                <mat-form-field>
                    <input
                        matInput
                        number-input
                        [(number)]="report.car.length"
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0"
                        [disabled]="isReportLocked()"
                        placeholder="Länge (mm)"
                        matTooltip="Im Fahrzeugschein: Feld '18'" />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        number-input
                        [(number)]="report.car.width"
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0"
                        [disabled]="isReportLocked()"
                        placeholder="Breite (mm)"
                        matTooltip="Im Fahrzeugschein: Feld '19'" />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        number-input
                        [(number)]="report.car.height"
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0"
                        [disabled]="isReportLocked()"
                        placeholder="Höhe (mm)"
                        matTooltip="Im Fahrzeugschein: Feld '20'" />
                </mat-form-field>
            </div>
            <!--============================================-->
            <!-- END Dimensions -->
            <!--============================================-->

            <!--============================================-->
            <!-- Wheelbase -->
            <!--============================================-->
            <mat-form-field class="mat-block">
                <input
                    matInput
                    number-input
                    [(number)]="report.car.wheelBase"
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="0"
                    [disabled]="isReportLocked()"
                    placeholder="Radstand (mm)" />
            </mat-form-field>
            <!--============================================-->
            <!-- END Wheelbase -->
            <!--============================================-->

            <!--============================================-->
            <!-- Weight -->
            <!--============================================-->
            <div class="multiple-inputs-grid">
                <div *ngFor="let axle of report.car.axles">
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            number-input
                            [(number)]="axle.axleLoad"
                            [minimumFractionDigits]="0"
                            [maximumFractionDigits]="0"
                            [disabled]="isReportLocked()"
                            placeholder="{{ getAxlesLabel(report.car.axles, axle.axleNumber) }}"
                            matTooltip="Im Fahrzeugschein: Felder '7.x'" />
                    </mat-form-field>
                </div>
            </div>
            <!--============================================-->
            <!-- END Weight -->
            <!--============================================-->
        </ng-container>
        <!--============================================-->
        <!-- END Dimensions & Weight -->
        <!--============================================-->

        <!--********** Source of Technical Data **********-->
        <autocomplete-with-memory
            placeholder="Quelle der technischen Daten"
            autocompleteEntryType="sourceOfTechnicalData"
            [(ngModel)]="report.sourceOfTechnicalData"
            (change)="saveReport()"
            [disabled]="isReportLocked()"></autocomplete-with-memory>

        <div class="checkbox-group">
            <mat-checkbox
                [(ngModel)]="report.vinWasChecked"
                (change)="saveReport()"
                [disabled]="report.state === 'done'">
                VIN am Fahrzeug überprüft
            </mat-checkbox>
        </div>

        <!--********** Battery Owner **********-->
        <ng-container *ngIf="doesVehicleHaveBatteryElectricEngine()">
            <div class="checkbox-group">
                <tri-state-checkbox
                    [(ngModel)]="report.car.carOwnerIsBatteryOwner"
                    (change)="saveReport()"
                    matTooltip="Wenn die Batterie nicht dem Fahrzeughalter gehört, müssen Wiederbeschaffungswert und Restwert ohne Batterie bestimmt werden. Das wird in der Zusammenfassung im Gutachten ausgewiesen."
                    [disabled]="report.state === 'done'">
                    Batterie gehört dem Fahrzeugeigentümer
                </tri-state-checkbox>
            </div>
            <autocomplete-with-memory
                *ngIf="report.car.carOwnerIsBatteryOwner === false"
                placeholder="Eigentümer der Batterie"
                [(ngModel)]="report.car.batteryOwner"
                (change)="saveReport()"
                autocompleteEntryType="batteryOwner"
                [disabled]="isReportLocked()"></autocomplete-with-memory>
        </ng-container>

        <!--============================================-->
        <!-- Custom Fields -->
        <!--============================================-->
        <custom-field-display
            fieldLocation="car-data_basic-data"
            [report]="report"
            (reportChange)="saveReport()"
            [disabled]="isReportLocked()"></custom-field-display>
        <!--============================================-->
        <!-- END Custom Fields -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- ENDE Basic Car Data -->
    <!--============================================-->

    <!--============================================-->
    <!-- Advanced Car Data -->
    <!--============================================-->
    <div id="advanced-car-data-container">
        <div class="card card-with-padding">
            <div class="tabs-container">
                <h2
                    class="tab-heading"
                    [ngClass]="{ selected: selectedAdvancedCarDataTab === 'advancedCarData' }"
                    (click)="selectAdvancedCarDataTab('advancedCarData')">
                    Fahrzeugdaten erweitert
                </h2>
                <h2
                    class="tab-heading"
                    [ngClass]="{ selected: selectedAdvancedCarDataTab === 'equipments' }"
                    (click)="selectAdvancedCarDataTab('equipments')">
                    Ausstattungen
                </h2>
            </div>

            <div id="advanced-car-data-tab" *ngIf="selectedAdvancedCarDataTab === 'advancedCarData'">
                <!--============================================-->
                <!-- Car Shape -->
                <!--============================================-->
                <div id="car-shape-container" class="advanced-car-data-row">
                    <h3>Fahrzeugart</h3>
                    <div class="options-row" [ngClass]="{ grid: isSmallScreen() }">
                        <div
                            class="option-container"
                            *ngFor="let carShape of carShapesForUI"
                            [ngClass]="{ active: carShape.id === report.car.shape, unselectable: isReportLocked() }"
                            (click)="!isReportLocked() && selectCarShape(carShape.id)"
                            (keydown)="triggerClickEventOnSpaceBarPress($event)"
                            [matTooltip]="carShape.tooltip"
                            tabindex="0">
                            <img
                                class="option-image"
                                src="/assets/images/icons/{{ carShape.image }}"
                                alt="{{ carShape.title }}" />
                            <div class="option-title-container">
                                <span class="option-title">
                                    <ng-container
                                        *ngIf="
                                            report.car.shape === carShape.id && report.car.customShapeLabel;
                                            then overwrittenShapeTitle;
                                            else regularShapeTitle
                                        "></ng-container>
                                    <ng-template #overwrittenShapeTitle>{{ report.car.customShapeLabel }}</ng-template>
                                    <ng-template #regularShapeTitle>{{ carShape.title }}</ng-template>
                                </span>
                            </div>
                        </div>
                        <!--============================================-->
                        <!-- Other Name -->
                        <!--============================================-->
                        <div id="input-for-custom-car-shape" *ngIf="inputForCustomCarShapeShown">
                            <mat-form-field
                                matTooltip="Den Namen der Fahrzeugart kannst du unabhängig von der Skizze setzen.">
                                <input
                                    matInput
                                    placeholder="Andere Bezeichnung"
                                    [(ngModel)]="report.car.customShapeLabel"
                                    (change)="saveReport()"
                                    [axAutofocus]="true"
                                    [disabled]="report.state === 'done'" />
                            </mat-form-field>
                        </div>
                        <!--============================================-->
                        <!-- END Other Name -->
                        <!--============================================-->

                        <div
                            id="car-shape-more-options-container"
                            class="more-options option-container"
                            [matMenuTriggerFor]="moreCarShapesSubmenu"
                            *ngIf="!isReportLocked()">
                            <img class="option-image" src="/assets/images/icons/add-grey.png" alt="Mehr Optionen" />
                            <div class="option-title-container">
                                <span class="option-title">Andere</span>
                            </div>

                            <!--============================================-->
                            <!-- Car Shapes Submenu -->
                            <!--============================================-->
                            <mat-menu #moreCarShapesSubmenu xPosition="before">
                                <div
                                    mat-menu-item
                                    class="car-shape-menu-item"
                                    *ngFor="let carShape of carShapesForSubmenuToplevel"
                                    (click)="selectCarShapeFromList(carShape)"
                                    [matTooltip]="carShape.tooltip"
                                    [disabled]="report.car.shape === carShape.id">
                                    <img
                                        class="car-data-submenu-item-image"
                                        [src]="'/assets/images/icons/' + carShape.image"
                                        alt="" />
                                    {{ carShape.title }}
                                </div>

                                <!--********** Bikes Submenu **********-->
                                <div mat-menu-item class="car-shape-menu-item" [matMenuTriggerFor]="bikesSubmenu">
                                    <img
                                        class="car-data-submenu-item-image"
                                        src="/assets/images/icons/motorcycle_64x32.png"
                                        alt="" />
                                    Zweiräder
                                </div>

                                <!--********** Trucks Submenu **********-->
                                <div mat-menu-item class="car-shape-menu-item" [matMenuTriggerFor]="trucksSubmenu">
                                    <img
                                        class="car-data-submenu-item-image"
                                        src="/assets/images/icons/truck_64x32.png"
                                        alt="" />
                                    LKW & Bus
                                </div>

                                <!--********** Camping Submenu **********-->
                                <div mat-menu-item class="car-shape-menu-item" [matMenuTriggerFor]="campingSubmenu">
                                    <img
                                        class="car-data-submenu-item-image"
                                        src="/assets/images/icons/motor-home_64x32.png"
                                        alt="" />
                                    Wohnen
                                </div>
                            </mat-menu>

                            <!--============================================-->
                            <!-- Camping Submenu -->
                            <!--============================================-->
                            <mat-menu #campingSubmenu>
                                <div
                                    mat-menu-item
                                    class="car-shape-menu-item"
                                    *ngFor="let carShape of carShapesForSubmenuCamping"
                                    (click)="selectCarShapeFromList(carShape)"
                                    [matTooltip]="carShape.tooltip"
                                    [disabled]="report.car.shape === carShape.id">
                                    <img
                                        class="car-data-submenu-item-image"
                                        [src]="'/assets/images/icons/' + carShape.image"
                                        alt="" />
                                    {{ carShape.title }}
                                </div>
                            </mat-menu>
                            <!--============================================-->
                            <!-- END Camping Submenu -->
                            <!--============================================-->

                            <!--============================================-->
                            <!-- Trucks Submenu -->
                            <!--============================================-->
                            <mat-menu #trucksSubmenu>
                                <div
                                    mat-menu-item
                                    class="car-shape-menu-item"
                                    *ngFor="let carShape of carShapesForSubmenuTrucks"
                                    (click)="selectCarShapeFromList(carShape)"
                                    [matTooltip]="carShape.tooltip"
                                    [disabled]="report.car.shape === carShape.id">
                                    <img
                                        class="car-data-submenu-item-image"
                                        [src]="'/assets/images/icons/' + carShape.image"
                                        alt="" />
                                    {{ carShape.title }}
                                </div>
                            </mat-menu>
                            <!--============================================-->
                            <!-- END Trucks Submenu -->
                            <!--============================================-->

                            <!--============================================-->
                            <!-- Bikes Submenu -->
                            <!--============================================-->
                            <mat-menu #bikesSubmenu>
                                <div
                                    mat-menu-item
                                    class="car-shape-menu-item"
                                    *ngFor="let carShape of carShapesForSubmenuBikes"
                                    (click)="selectCarShapeFromList(carShape)"
                                    [matTooltip]="carShape.tooltip"
                                    [disabled]="report.car.shape === carShape.id">
                                    <img
                                        class="car-data-submenu-item-image"
                                        [src]="'/assets/images/icons/' + carShape.image"
                                        alt="" />
                                    {{ carShape.title }}
                                </div>
                            </mat-menu>
                            <!--============================================-->
                            <!-- END Bikes Submenu -->
                            <!--============================================-->

                            <!--============================================-->
                            <!-- END Car Shapes Submenu -->
                            <!--============================================-->
                        </div>

                        <!--============================================-->
                        <!-- Submenu Icon -->
                        <!--============================================-->
                        <mat-icon
                            id="car-shape-submenu-icon"
                            *ngIf="!isReportLocked()"
                            class="toolbar-icon"
                            [matMenuTriggerFor]="carShapeSubmenu">
                            more_vert
                        </mat-icon>
                        <!--============================================-->
                        <!-- END Submenu Icon -->
                        <!--============================================-->
                    </div>

                    <!--============================================-->
                    <!-- Submenu -->
                    <!--============================================-->
                    <mat-menu #carShapeSubmenu>
                        <!--********** Show Custom Car Shape Label Input **********-->
                        <div
                            mat-menu-item
                            *ngIf="!inputForCustomCarShapeShown"
                            (click)="showInputForCustomCarShapeLabel()"
                            matTooltip="Wähle eine beliebige Skizze und überschreibe den Namen der Fahrzeugart">
                            Andere Bezeichnung verwenden
                        </div>

                        <!--********** Hide Custom Car Shape Label Input **********-->
                        <div
                            mat-menu-item
                            *ngIf="inputForCustomCarShapeShown"
                            (click)="hideInputForCustomCarShapeLabel()">
                            Andere Bezeichnung ausblenden
                        </div>
                    </mat-menu>
                    <!--============================================-->
                    <!-- END Submenu -->
                    <!--============================================-->
                </div>
                <!--============================================-->
                <!-- END Car Shape -->
                <!--============================================-->

                <!--============================================-->
                <!-- Engine Type -->
                <!--============================================-->
                <div
                    id="engine-types-container"
                    class="advanced-car-data-row"
                    *ngIf="doesVehicleHaveMotor() && !isBicycleOrPedelec">
                    <h3>Motor</h3>
                    <div class="options-row" [ngClass]="{ grid: isSmallScreen() }">
                        <div
                            class="option-container"
                            *ngFor="let engineType of engineTypes"
                            [ngClass]="{
                                active: report.car['runsOn' + engineType.id],
                                unselectable: isReportLocked(),
                            }"
                            (click)="!isReportLocked() && toggleEngineType(engineType.id, $event)"
                            matTooltip="Strg + Klick erlaubt die Auswahl einer zusätzlichen Motorart, z. B. Diesel & Elektro als Hybrid."
                            (keydown)="triggerClickEventOnSpaceBarPress($event)"
                            tabindex="0">
                            <mat-icon *ngIf="engineType.icon" class="option-icon">{{ engineType.icon }}</mat-icon>
                            <img
                                *ngIf="engineType.image"
                                class="option-image"
                                src="/assets/images/icons/{{ engineType.image }}"
                                alt="{{ engineType.title }}" />
                            <div class="option-title-container">
                                <span class="option-title">{{ engineType.title }}</span>
                            </div>
                        </div>

                        <div
                            id="engine-type-more-options-container"
                            class="more-options option-container"
                            *ngIf="!showInputForCustomEngineType && !isReportLocked()"
                            (click)="toggleInputForCustomEngineType()">
                            <img class="option-image" src="/assets/images/icons/add-grey.png" alt="Mehr Optionen" />
                            <div class="option-title-container">
                                <span class="option-title">Anderer</span>
                            </div>
                        </div>
                        <div class="input-for-custom-value" *ngIf="showInputForCustomEngineType">
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Motortyp"
                                    [(ngModel)]="report.car.runsOnSomethingElse"
                                    (change)="unselectAllEngineTypes(); saveReport()"
                                    [axAutofocus]="true"
                                    [disabled]="report.state === 'done'" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Engine Type -->
                <!--============================================-->

                <!--============================================-->
                <!-- Axles -->
                <!--============================================-->
                <div *ngIf="displayAxles" id="axles-and-powered-axles-container" class="advanced-car-data-row">
                    <div id="axles-container">
                        <h3>
                            Achsen
                            <!--============================================-->
                            <!-- Warning: VIN Result with 0 Axles -->
                            <!--============================================-->
                            <mat-icon
                                id="no-axles-warning-icon"
                                *ngIf="
                                    (report.car.datIdentification ||
                                        report.car.gtmotiveIdentification ||
                                        report.car.audatexIdentification) &&
                                    report.car.numberOfAxles < 1
                                "
                                matTooltip="Bitte wähle die Anzahl der Achsen, damit die Reifengrafik und die Textbausteine korrekt ins Gutachten gedruckt werden können.">
                                warning
                            </mat-icon>
                            <!--============================================-->
                            <!-- END Warning: VIN Result with 0 Axles -->
                            <!--============================================-->
                        </h3>

                        <!--============================================-->
                        <!-- Warning: Car but only 1 axle -->
                        <!--============================================-->
                        <div
                            class="info-note warning-note one-liner-note margin-bottom-10"
                            *ngIf="(report.car.shape | vehicleRequiresTwoOrMoreAxles) && report.car.axles.length === 1">
                            <mat-icon>warning</mat-icon>
                            <div>Dieses Fahrzeug hat wahrscheinlich mehr als eine Achse.</div>
                        </div>
                        <!--============================================-->
                        <!-- END Warning: Car but only 1 axle -->
                        <!--============================================-->
                        <div class="options-row" [ngClass]="{ grid: isSmallScreen() }">
                            <div
                                class="option-container"
                                *ngFor="let axleId of axles"
                                [ngClass]="{
                                    active: axleId <= report.car.numberOfAxles,
                                    unselectable: isReportLocked(),
                                }"
                                (click)="selectNumberOfAxles(axleId); saveReport()"
                                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                                tabindex="0">
                                <img class="option-image" src="/assets/images/icons/axle_48.png" alt="Achse" />
                                <div class="option-title-container">
                                    <span
                                        class="option-title"
                                        [ngClass]="{ 'below-selected-number': axleId < report.car.numberOfAxles }">
                                        {{ axleId }}
                                    </span>
                                </div>
                            </div>

                            <div
                                id="axles-more-options-container"
                                class="more-options option-container"
                                *ngIf="!showInputForCustomNumberOfAxles && !isReportLocked()"
                                (click)="toggleInputForCustomNumberOfAxles()">
                                <img class="option-image" src="/assets/images/icons/add-grey.png" alt="Mehr" />
                                <div class="option-title-container">
                                    <span class="option-title">Mehr</span>
                                </div>
                            </div>
                            <div class="input-for-custom-value" *ngIf="showInputForCustomNumberOfAxles">
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="Anzahl Axen"
                                        [(ngModel)]="report.car.numberOfAxles"
                                        [disabled]="report.state === 'done'"
                                        (change)="selectNumberOfAxles(report.car.numberOfAxles); saveReport()"
                                        [axAutofocus]="true" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div id="powered-axles-container" *ngIf="doesVehicleHaveMotor()">
                        <h3>Davon Angetrieben</h3>
                        <div class="options-row" [ngClass]="{ grid: isSmallScreen() }">
                            <div
                                class="option-container"
                                *ngFor="let poweredAxleId of poweredAxles"
                                [ngClass]="{
                                    active:
                                        report.car.numberOfPoweredAxles !== null &&
                                        poweredAxleId <= report.car.numberOfPoweredAxles,
                                    unselectable: isReportLocked(),
                                    unavailable: poweredAxleId > report.car.numberOfAxles,
                                }"
                                (click)="selectNumberOfPoweredAxles(poweredAxleId); saveReport()"
                                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                                tabindex="0">
                                <img
                                    *ngIf="poweredAxleId === 0"
                                    class="option-image"
                                    [ngClass]="{ 'more-than-one-active': report.car.numberOfPoweredAxles !== 0 }"
                                    src="/assets/images/icons/axle-powered-crossed-out_48.png"
                                    alt="Angetriebene Achse" />
                                <img
                                    *ngIf="poweredAxleId > 0"
                                    class="option-image"
                                    src="/assets/images/icons/axle-powered_48.png"
                                    alt="Angetriebene Achse" />
                                <div class="option-title-container">
                                    <span
                                        class="option-title"
                                        [ngClass]="{
                                            'below-selected-number': poweredAxleId < report.car.numberOfPoweredAxles,
                                        }">
                                        {{ poweredAxleId }}
                                    </span>
                                </div>
                            </div>

                            <div
                                id="powered-axles-more-options-container"
                                class="more-options option-container"
                                *ngIf="!showInputForCustomNumberOfPoweredAxles && !isReportLocked()"
                                (click)="toggleInputForCustomNumberOfPoweredAxles()">
                                <img class="option-image" src="/assets/images/icons/add-grey.png" alt="Mehr" />
                                <div class="option-title-container">
                                    <span class="option-title">Mehr</span>
                                </div>
                            </div>
                            <div class="input-for-custom-value" *ngIf="showInputForCustomNumberOfPoweredAxles">
                                <mat-form-field>
                                    <input
                                        matInput
                                        placeholder="Angetriebene Axen"
                                        [(ngModel)]="report.car.numberOfPoweredAxles"
                                        [disabled]="report.state === 'done'"
                                        (change)="saveReport()"
                                        [axAutofocus]="true" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Axles -->
                <!--============================================-->

                <!--============================================-->
                <!-- Doors -->
                <!--============================================-->
                <div *ngIf="displayDoors" id="doors-container" class="advanced-car-data-row">
                    <h3>Türen</h3>
                    <div class="options-row" [ngClass]="{ grid: isSmallScreen() }">
                        <div
                            class="option-container"
                            *ngFor="let doorId of doors"
                            [ngClass]="{
                                active: report.car.numberOfDoors !== null && doorId <= report.car.numberOfDoors,
                                unselectable: isReportLocked(),
                            }"
                            (click)="!isReportLocked() && selectNumberOfDoors(doorId)"
                            (keydown)="triggerClickEventOnSpaceBarPress($event)"
                            tabindex="0">
                            <img
                                *ngIf="doorId === 0"
                                class="option-image"
                                [ngClass]="{ 'more-than-one-active': report.car.numberOfDoors !== 0 }"
                                src="/assets/images/icons/door-crossed-out_48.png"
                                alt="Autositz" />
                            <img
                                *ngIf="doorId > 0"
                                class="option-image"
                                src="/assets/images/icons/door_48.png"
                                alt="Autositz" />
                            <div class="option-title-container">
                                <span
                                    class="option-title"
                                    [ngClass]="{ 'below-selected-number': doorId < report.car.numberOfDoors }">
                                    {{ doorId }}
                                </span>
                            </div>
                        </div>
                        <div
                            id="doors-more-options-container"
                            class="more-options option-container"
                            *ngIf="!showInputForCustomNumberOfDoors && !isReportLocked()"
                            (click)="toggleInputForCustomNumberOfDoors()">
                            <img class="option-image" src="/assets/images/icons/add-grey.png" alt="Mehr" />
                            <div class="option-title-container">
                                <span class="option-title">Mehr</span>
                            </div>
                        </div>
                        <div class="input-for-custom-value" *ngIf="showInputForCustomNumberOfDoors">
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Anzahl Türen"
                                    [(ngModel)]="report.car.numberOfDoors"
                                    [disabled]="report.state === 'done'"
                                    (change)="saveReport()"
                                    [axAutofocus]="true" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Doors -->
                <!--============================================-->

                <!--============================================-->
                <!-- Seats -->
                <!--============================================-->
                <div id="seats-container" class="advanced-car-data-row" *ngIf="doesVehicleHaveSeats(report.car.shape)">
                    <h3>Sitze</h3>
                    <div class="options-row" [ngClass]="{ grid: isSmallScreen() }">
                        <div
                            class="option-container"
                            *ngFor="let seatId of seats"
                            [ngClass]="{
                                active: report.car.numberOfSeats !== null && seatId <= report.car.numberOfSeats,
                                unselectable: isReportLocked(),
                            }"
                            (click)="!isReportLocked() && selectNumberOfSeats(seatId)"
                            (keydown)="triggerClickEventOnSpaceBarPress($event)"
                            tabindex="0">
                            <img
                                *ngIf="seatId === 0"
                                class="option-image"
                                [ngClass]="{ 'more-than-one-active': report.car.numberOfSeats !== 0 }"
                                src="/assets/images/icons/car-seat-crossed-out_48.png"
                                alt="Autositz" />
                            <img
                                *ngIf="seatId > 0"
                                class="option-image"
                                src="/assets/images/icons/car-seat_48.png"
                                alt="Autositz" />
                            <div class="option-title-container">
                                <span
                                    class="option-title"
                                    [ngClass]="{ 'below-selected-number': seatId < report.car.numberOfSeats }">
                                    {{ seatId }}
                                </span>
                            </div>
                        </div>
                        <div
                            id="seats-more-options-container"
                            class="more-options option-container"
                            *ngIf="!showInputForCustomNumberOfSeats && !isReportLocked()"
                            (click)="toggleInputForCustomNumberOfSeats()">
                            <img class="option-image" src="/assets/images/icons/add-grey.png" alt="Mehr" />
                            <div class="option-title-container">
                                <span class="option-title">Mehr</span>
                            </div>
                        </div>
                        <div class="input-for-custom-value" *ngIf="showInputForCustomNumberOfSeats">
                            <mat-form-field>
                                <input
                                    matInput
                                    number-input
                                    placeholder="Anzahl Sitze"
                                    [minimumFractionDigits]="0"
                                    [maximumFractionDigits]="0"
                                    [(number)]="report.car.numberOfSeats"
                                    [disabled]="report.state === 'done'"
                                    (numberChange)="saveReport()"
                                    [axAutofocus]="true" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Seats -->
                <!--============================================-->

                <!--============================================-->
                <!-- Previous Owners -->
                <!--============================================-->
                <div id="previous-owners-container" class="advanced-car-data-row">
                    <h3>Vorbesitzer</h3>
                    <previous-owners
                        [report]="report"
                        [isReportLocked]="isReportLocked()"
                        [isSmallScreen]="isSmallScreen()"
                        (onReportChange)="saveReport()"></previous-owners>
                </div>

                <!--============================================-->
                <!-- Custom Car Properties -->
                <!--============================================-->
                <div *ngIf="showCustomCarPropertyInputs()">
                    <h3>
                        Individuelle Fahrzeugdaten
                        <span
                            class="help-indicator"
                            matTooltip='Hier kannst du individuelle Fahrzeugdaten angeben. Diese werden an die Tabelle "Fahrzeugdaten" im Gutachten angehängt.
                    
                        Damit kannst du individuelle, wertbestimmende Eigenschaften (z. B. Komponenten beim Fahrrad) direkt in den Fahrzeugdaten angeben.'>
                            ?
                        </span>
                    </h3>
                    <custom-car-properties
                        [isReportLocked]="isReportLocked()"
                        [report]="report"
                        (dataFieldsChanged)="saveReport()"></custom-car-properties>
                </div>
            </div>

            <div
                *ngIf="!isReportLocked() && selectedAdvancedCarDataTab === 'advancedCarData'"
                id="buttons-at-bottom-of-card">
                <div
                    *ngIf="report.car.customProperties?.length < 1"
                    id="add-custom-car-property-button"
                    (click)="addCustomCarProperty(); saveReport()"
                    class="label"
                    matTooltip="Mit individuellen Fahrzeugdaten kannst du spezielle wertbestimmende Eigenschaften direkt in der Fahrzeugdatentabelle im Gutachten angeben.">
                    <mat-icon class="small-icon outlined">add_circle</mat-icon>
                    <span>Individuelle Fahrzeugdaten</span>
                </div>
            </div>

            <!--============================================-->
            <!-- Equipments -->
            <!--============================================-->
            <equipment-selector
                *ngIf="selectedAdvancedCarDataTab === 'equipments'"
                [report]="report"
                [user]="user"
                [builtinEquipmentFromParent]="carEquipment?.equipmentPositions"></equipment-selector>

            <!--============================================-->
            <!-- Custom Fields -->
            <!--============================================-->
            <custom-field-display
                fieldLocation="car-data_equipment"
                [report]="report"
                (reportChange)="saveReport()"
                [disabled]="isReportLocked()"></custom-field-display>
            <!--============================================-->
            <!-- END Custom Fields -->
            <!--============================================-->
            <!--============================================-->
            <!-- END Equipments -->
            <!--============================================-->
        </div>
    </div>
    <!--============================================-->
    <!-- ENDE Advanced Car Data -->
    <!--============================================-->
</div>

<multiple-cars-identification-helper
    *ngIf="report && identificationResponsesPerVehicle.length"
    [identificationResponsesPerVehicle]="identificationResponsesPerVehicle"
    (identificationResponsePerVehicleChosen)="
        processDatCarSelectorChangeEventAfterIdentificationRequest($event); closeMultipleCarsIdentificationHelper()
    "
    (close)="closeMultipleCarsIdentificationHelper()"></multiple-cars-identification-helper>

import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { Report } from '@autoixpert/models/reports/report';
import { User } from '@autoixpert/models/user/user';
import { isDatValuationInputDataComplete } from './is-dat-valuation-input-data-complete';

export function getDatValuationOpenerTooltip({
    report,
    user,
    valuationProvider,
}: {
    report: Report;
    user: User;
    valuationProvider: 'quick' | 'detailed';
}): string {
    if (!isDatUserComplete(user)) {
        return 'Zugangsdaten in den Einstellungen eingeben';
    }

    if (report.state === 'done') {
        return 'Gesperrt, weil Gutachten bereits abgeschlossen';
    }

    if (!isDatValuationInputDataComplete(report)) {
        const missingFields = [];

        if (!report.car.datIdentification.datEuropaCode || !report.car.datIdentification.marketIndex) {
            missingFields.push('DAT€Code (Fahrzeugauswahl, z. B. durch VIN-Abfrage)');
        }
        if (!report.car.firstRegistration) {
            missingFields.push('Erstzulassung (Fahrzeugauswahl)');
        }
        if (!report.car.mileage && !report.car.mileageMeter && !report.car.mileageAsStated) {
            missingFields.push('km-Stand (abgelesen, geschätzt oder angegeben)');
        }

        if (missingFields.length) {
            if (missingFields.length === 1) {
                // If the DAT€Code is set, but the Market Index is not, the user might need some extra help.
                if (report.car.datIdentification.datEuropaCode && !report.car.datIdentification.marketIndex) {
                    return 'Es werden sowohl der DAT€Code als auch der dazugehörige Marktindex benötigt. Dafür im Screen Fahrzeugauswahl entweder die VIN-Abfrage ausführen oder den DAT€Code-Selektor über die Lupe im Feld DAT€Code verwenden.';
                } else {
                    return `${missingFields[0]} muss ausgefüllt sein.`;
                }
            } else {
                const lastField = missingFields.pop();
                return `${missingFields.join(', ')} und ${lastField} müssen ausgefüllt sein.`;
            }
        }
    }

    if (valuationProvider === 'quick') {
        return 'Schnellbewertung ohne Konfiguration';
    }
    return 'Bewertung mit detaillierter Konfiguration über die DAT-Oberfläche';
}

import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
    selector: '[elementCreated]',
    exportAs: 'elementCreated',
})
export class ElementCreatedDirective<HostElement extends HTMLElement = HTMLElement> implements AfterViewInit {
    constructor(private readonly elRef: ElementRef<HostElement>) {}

    @Output() elementCreated = new EventEmitter<{ element: HostElement }>();

    ngAfterViewInit() {
        this.elementCreated.emit({ element: this.elRef.nativeElement });
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SetupSteps } from '@autoixpert/models/teams/setup-steps';
import { Team } from '@autoixpert/models/teams/team';
import { TutorialState } from '@autoixpert/models/user/onboarding/tutorial-state';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from './logged-in-user.service';
import { TeamService } from './team.service';
import { UserService } from './user.service';

@Injectable()
export class TutorialStateService {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private teamService: TeamService,
    ) {
        this.subscribeToUserAndTeam();
    }

    private team: Team = new Team();
    private user: User = new User();
    public firstStepsPanelOpeningRequests$: Subject<void> = new Subject();
    /**
     * This subject is currently not in use. While we track the tutorial steps, their completion is never displayed.
     */
    public tutorialStepCompleted: Subject<keyof TutorialState> = new Subject();

    public openIntroVideosPanel() {
        this.firstStepsPanelOpeningRequests$.next();
    }

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    private subscribeToUserAndTeam() {
        this.loggedInUserService.getUser$().subscribe((user) => {
            this.user = user;

            if (this.user && !this.user.tutorialState) {
                this.user.tutorialState = new TutorialState();
                this.saveUser();
            }
        });
        this.loggedInUserService.getTeam$().subscribe((team) => {
            this.team = team;

            if (this.team?.userInterfaceStates && !this.team.userInterfaceStates?.setupSteps) {
                this.team.userInterfaceStates.setupSteps = new SetupSteps();
                this.teamService.put(this.team);
            }
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Team-wide Setup
    //****************************************************************************/
    public markTeamSetupStepComplete(step: keyof SetupSteps) {
        this.team.userInterfaceStates.setupSteps[step] = true;
        this.teamService.put(this.team);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team-wide Setup
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  User-based Tutorial
    //****************************************************************************/
    public markUserTutorialStepComplete(step: keyof TutorialState) {
        if (!this.user.tutorialState[step]) {
            // Mark the step as done even if the containing tutorial is not visible. Otherwise, locking a report while
            // having selected the 2nd or 3rd tutorial won't close the info note on the benefits of locking a report.
            this.user.tutorialState[step] = true;
            this.saveUser();

            // Emit an event so that the (minified) tutorial can open and display the trophy overlay.
            this.tutorialStepCompleted.next(step);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END User-based Tutorial
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Save the user to the server
     */
    private saveUser() {
        this.loggedInUserService.setUser(this.user);

        // Save the new preferences to the server.
        this.userService.put(this.user).catch((response) => {
            console.error('Error saving the user tutorial state.', response);
        });
    }
}

import { Report } from '../../models/reports/report';
import { mayCarOwnerDeductTaxes } from '../report/may-car-owner-deduct-taxes';

/**
 * Based on whether the claimant may deduct taxes, we use either the net or the gross values.
 */
export function getApplicableRepairCosts(report: Report): number {
    if (!report.damageCalculation) {
        return null;
    }

    return (
        (mayCarOwnerDeductTaxes(report)
            ? report.damageCalculation.repair.repairCostsNet
            : report.damageCalculation.repair.repairCostsGross) || 0
    );
}

import { BillingAccount } from '../../billing/billing-account';
import { CommunicationRecipient } from './communication-recipient';

export class FactoringProvider extends CommunicationRecipient {
    constructor() {
        super('factoringProvider');
    }

    bankAccount: BillingAccount = new BillingAccount();
}

import { Component } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { fadeInAndOutAnimation } from '@autoixpert/animations/fade-in-and-out.animation';
import { generateId } from '@autoixpert/lib/generate-id';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { ApiUser } from '@autoixpert/models/user/api-user';
import { AccessRights } from '@autoixpert/models/user/user';
import { ApiUserService } from 'src/app/shared/services/api-user.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
    selector: 'external-api-users',
    templateUrl: './external-api-users.component.html',
    styleUrls: ['./external-api-users.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class ExternalApiUsersComponent {
    trackById = trackById;
    private _apiUsers: ApiUser[];
    protected addApiUserPending = false;
    protected apiUserEditMode: string = undefined;
    protected createdApiKey: string | false = false;

    constructor(
        private readonly apiUserService: ApiUserService,
        private toastService: ToastService,
    ) {}

    async ngOnInit() {
        this._apiUsers = await this.apiUserService.find().toPromise();
    }

    get apiUsers() {
        return (this._apiUsers ?? []).sort(
            (user1, user2) => new Date(user1.createdAt).getTime() - new Date(user2.createdAt).getTime(),
        );
    }

    protected async createApiUser() {
        if (this.addApiUserPending) return;

        this.addApiUserPending = true;
        const apiKey = generateId();
        const apiUser = new ApiUser({
            _id: generateId(),
            active: true,
            apiKey,
            title: 'Unbenannt',
            accessRights: {
                bankAccountSync: true,
                editContacts: true,
                editTextsAndDocumentBuildingBlocks: true,
                invoiceList: true,
                lockReports: true,
                reporting: true,
                seeAllInvoices: true,
                seeAllPayments: true,
                seeAllReports: true,
                seeReportFees: true,
                seeSettings: true,
            },
        });
        await this.apiUserService.create(apiUser, { waitForServer: true });
        this._apiUsers = await this.apiUserService.find().toPromise();
        this.addApiUserPending = false;
        this.createdApiKey = apiKey;
    }

    editApiUser(apiUser: ApiUser) {
        this.apiUserEditMode = apiUser._id;
    }

    async deactivateApiUser(apiUser: ApiUser) {
        apiUser.active = false;
        await this.apiUserService.put(apiUser);
    }

    async activateApiUser(apiUser: ApiUser) {
        apiUser.active = true;
        await this.apiUserService;
    }

    async deleteApiUser(apiUser: ApiUser) {
        await this.apiUserService.delete(apiUser._id, { waitForServer: true });
        this._apiUsers = await this.apiUserService.find().toPromise();
    }

    async handleApiUserTitleChange(apiUser: ApiUser, event: Event) {
        const title = (event.target as HTMLInputElement).value;
        this.apiUserEditMode = undefined;
        apiUser.title = title;
        await this.apiUserService.put(apiUser);
    }

    async handleAccessRightsChange(
        apiUser: ApiUser,
        event: MatSlideToggleChange & {
            accessRight: keyof AccessRights;
        },
    ) {
        apiUser.accessRights[event.accessRight] = event.checked;
        await this.apiUserService.put(apiUser);
    }

    copyToClipboard(text: string) {
        navigator.clipboard.writeText(text);
        this.toastService.info('API Key kopiert', 'Der API Key wurde in die Zwischenablage kopiert.');
    }

    closeApiKeyDialog() {
        this.createdApiKey = false;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DamageTotalAnalyticsRecord } from '@autoixpert/models/analytics/damage-total-analytics.record';
import { AnalyticsFilterOptions, prepareDefaultParametersForAnalyticsQuery } from './default-analytics-query-parameter';

@Injectable()
export class DamageTotalAnalyticService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getDamageTotalAnalytics(filterOptions: AnalyticsFilterOptions): Observable<DamageTotalAnalyticsRecord[]> {
        let queryParams = prepareDefaultParametersForAnalyticsQuery({
            dateField: 'completionDate',
            ...filterOptions,
        });

        queryParams = queryParams.append('net', filterOptions.net);

        return this.httpClient.get<DamageTotalAnalyticsRecord[]>(`${this.apiPath}/analytics/damageTotal`, {
            params: queryParams,
        });
    }
}

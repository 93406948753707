import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { flatten } from 'lodash-es';
import { DateTime } from 'luxon';
import moment from 'moment';
import { FileUploader } from 'ng2-file-upload';
import { FileItem } from 'ng2-file-upload/file-upload/file-item.class';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { addMissingReportDocumentTypesToDefaultDocumentOrderItems } from '@autoixpert/lib/add-missing-report-document-types-to-default-document-order-items';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { GERMAN_DATE_FORMAT } from '@autoixpert/lib/ax-luxon';
import { matchConditions } from '@autoixpert/lib/document-building-blocks/match-conditions';
import { adaptDocumentOrder } from '@autoixpert/lib/documents/adapt-document-order';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { getDocumentOrderForRecipient } from '@autoixpert/lib/documents/get-document-order-for-recipient';
import { getDocumentOrderItemForDocument } from '@autoixpert/lib/documents/get-document-order-item-for-document';
import { getDocumentOrdersOfCommunicationRecipientsFromReport } from '@autoixpert/lib/documents/get-document-orders-of-communication-recipients-from-report';
import { getOrderedDocuments } from '@autoixpert/lib/documents/get-ordered-documents';
import { removeDocumentFromReport } from '@autoixpert/lib/documents/remove-document-from-report';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { resetDocumentOrderToDefault } from '@autoixpert/lib/documents/reset-document-order-to-default';
import { generateId } from '@autoixpert/lib/generate-id';
import { getCommunicationRecipientsFromReport } from '@autoixpert/lib/involved-parties/get-communication-recipients-from-report';
import { getOutgoingMessageScheduledAt } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-scheduled-at';
import { getOutgoingMessageStatus } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-status';
import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { Translator } from '@autoixpert/lib/placeholder-values/translator';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { httpRetry } from '@autoixpert/lib/rxjs-http-retry';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { getDefaultDocumentOrderByTypeAndCommunicationRecipientRole } from '@autoixpert/lib/teams/preferences/get-default-document-order-by-type-and-communication-recipient-role';
import {
    removeMissingPlaceholdersFromHTML,
    removeMissingPlaceholdersFromText,
    replaceMissingPlaceholders,
} from '@autoixpert/lib/template-engine/replace-missing-placeholders';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { translateRecipientRole } from '@autoixpert/lib/translators/translate-recipient-role';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { isTeamMatureCustomer } from '@autoixpert/lib/users/is-team-mature-customer';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import {
    DocumentMetadata,
    DocumentType,
    DocxWatermarkType,
    SignableDocumentType,
    signableDocumentTypes,
} from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { DocumentOrderItem } from '@autoixpert/models/documents/document-order-item';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { DocumentTypeForFileNamePattern } from '@autoixpert/models/file-name-patterns/file-name-pattern';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { OutgoingEmailMessage, OutgoingMessage, OutgoingMessageSchedule } from '@autoixpert/models/outgoing-message';
import { DocumentGroup } from '@autoixpert/models/reports/document-group';
import { CommunicationRecipient } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { Report } from '@autoixpert/models/reports/report';
import { SignablePdfTemplateConfig } from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { DefaultDocumentOrderItem } from '@autoixpert/models/teams/default-document-order/default-document-order-item';
import { Team } from '@autoixpert/models/teams/team';
import { TextTemplate } from '@autoixpert/models/text-templates/text-template';
import { EmailSignature } from '@autoixpert/models/user/preferences/email-signature';
import { User } from '@autoixpert/models/user/user';
import { TEST_PERIOD_DURATION_IN_DAYS } from '@autoixpert/static-data/test-period-duration-in-days';
import { HTMLInputEvent } from 'src/app/shared/libraries/types/html-input-element';
import { environment } from '../../../../environments/environment';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../../shared/animations/run-child-animations.animation';
import { sentEmailItemAnimation } from '../../../shared/animations/sent-email-item.animation';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { slideOutVertical } from '../../../shared/animations/slide-out-vertical.animation';
import {
    VideoPlayerDialogComponent,
    VideoPlayerDialogData,
} from '../../../shared/components/video-player-dialog/video-player-dialog.component';
import { getCrashback24ErrorHandlers } from '../../../shared/libraries/error-handlers/get-crashback24-error-handlers';
import { getDatErrorHandlers } from '../../../shared/libraries/error-handlers/get-dat-error-handlers';
import { getPersaldoErrorHandlers } from '../../../shared/libraries/error-handlers/get-persaldo-error-handlers';
import { findRecordById } from '../../../shared/libraries/find-record-by-id';
import { congratsDialogNumberOfReportsThresholds } from '../../../shared/libraries/gamification/congrats-dialog-number-of-reports-thresholds';
import { getMissingAccessRightTooltip } from '../../../shared/libraries/get-missing-access-right-tooltip';
import { getProductName } from '../../../shared/libraries/get-product-name';
import { isRenderedDocumentType } from '../../../shared/libraries/is-rendered-document-type';
import { clipString } from '../../../shared/libraries/strings/clip-string';
import { extractMissingPlaceholdersFromText } from '../../../shared/libraries/template-engine/extract-missing-placeholders-from-text';
import { trackById } from '../../../shared/libraries/track-by-id';
import { hasAccessRight } from '../../../shared/libraries/user/has-access-right';
import { ReportsAnalyticsService } from '../../../shared/services/analytics/reports-analytics.service';
import { RevenueAnalyticsService } from '../../../shared/services/analytics/revenue-analytics.service';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { AXRESTClient } from '../../../shared/services/ax-restclient';
import { Crashback24Service } from '../../../shared/services/crashback24.service';
import { DocumentOrderConfigService } from '../../../shared/services/document-order-config.service';
import { DownloadService } from '../../../shared/services/download.service';
import { EmailService } from '../../../shared/services/email.service';
import { EmailSignatureService } from '../../../shared/services/emailSignature.service';
import { FieldGroupConfigService } from '../../../shared/services/field-group-config.service';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { KeyboardService } from '../../../shared/services/keyboard.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { NewWindowService } from '../../../shared/services/new-window.service';
import { OutgoingMessageService } from '../../../shared/services/outgoing-message.service';
import { PersaldoService } from '../../../shared/services/persaldo.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { ReportService } from '../../../shared/services/report.service';
import { SignablePdfTemplateConfigService } from '../../../shared/services/signable-pdf-template-config.service';
import { TeamService } from '../../../shared/services/team.service';
import { TemplatePlaceholderValuesService } from '../../../shared/services/template-placeholder-values.service';
import { TextTemplateService } from '../../../shared/services/textTemplate.service';
import { ToastService } from '../../../shared/services/toast.service';
import { TutorialStateService } from '../../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { UserService } from '../../../shared/services/user.service';
import { DocxWatermarkSettingsDialogComponent } from './docx-watermark-settings-dialog/docx-watermark-settings-dialog.component';
import {
    NumberOfReportsCongratsDialogComponent,
    NumberOfReportsCongratsDialogData,
} from './number-of-reports-congrats-dialog/number-of-reports-congrats-dialog.component';

@Component({
    selector: 'print-and-transmission',
    templateUrl: 'print-and-transmission.component.html',
    styleUrls: ['print-and-transmission.component.scss'],
    animations: [
        sentEmailItemAnimation(),
        runChildAnimations(),
        slideInAndOutVertically(),
        fadeInAndOutAnimation(),
        slideOutVertical(),
    ],
})
export class PrintAndTransmissionComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastService: ToastService,
        private httpClient: HttpClient,
        private reportDetailsService: ReportDetailsService,
        protected reportService: ReportService,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private teamService: TeamService,
        private downloadService: DownloadService,
        private emailService: EmailService,
        private apiErrorService: ApiErrorService,
        protected userPreferences: UserPreferencesService,
        private textTemplateService: TextTemplateService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private invoiceService: InvoiceService,
        private tutorialStateService: TutorialStateService,
        private dialog: MatDialog,
        private fieldGroupConfigService: FieldGroupConfigService,
        private persaldoService: PersaldoService,
        private crashback24Service: Crashback24Service,
        private reportsAnalyticsService: ReportsAnalyticsService,
        private revenueAnalyticsService: RevenueAnalyticsService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        private emailSignatureService: EmailSignatureService,
        private networkStatusService: NetworkStatusService,
        private newWindowService: NewWindowService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private signablePdfTemplateConfigService: SignablePdfTemplateConfigService,
        private keyboardService: KeyboardService,
        private outgoingMessageService: OutgoingMessageService,
    ) {}

    reportId: string;
    report: Report;
    user: User;
    team: Team;

    private subscriptions: Subscription[] = [];

    public productName = getProductName();

    // The selected document group determines what documents are shown on the documents pane
    public selectedDocumentGroup: DocumentGroup = 'report';
    // The documents which are shown for the currently selected recipient. Typically, only cover letters are filtered, all other documents are always shown.
    public filteredDocuments: DocumentMetadata[] = [];

    // Message templates
    public messageTemplateSelectorShown: boolean = false;

    // Missing Placeholders
    public missingPlaceholdersInCoverLetters: string[] = [];

    /**
     * Set to true if the user clicks on a document toggle while holding shift on his keyboard. That allows him to execute the toggle
     * function for all documents at the same time.
     * This is a shortcut for the respective activate/deactivate all documents in the three-dot menu.
     */
    private userClickedOnDocumentToggleWithShift: boolean = false;

    // A reference to the document currently hovered with a dragged file is saved here. If no document
    // is hovered, this is null.
    public documentHoveredByFile: DocumentMetadata = null;
    public fileOverNewDocumentDropZone: boolean = false;
    // When using the three-dot-menu to upload a file, a reference is saved in this attribute. The reference is used
    // after the user chose a file and the file input fires a change event.
    public nextSingleUploadDocument: DocumentMetadata = null;
    // If autoiXpert reacts to every mouseout event, the indicator starts blinking even when hovering child elements
    // such as the document title or the on-off-switch. Avoid that by using a timer.
    private mouseoutFileuploadHover = null;
    private mouseoutNewDocumentHover = null;
    // Sometimes, the detection of the onDragEnd event does not work correctly. Thus, we should remove the upload after a second
    // of no other onDragOver event on the body
    public fileOverBodyTimeoutCache: number = null;
    // Becomes true when the user drags files over the window. The drop zone can then be shown.
    public fileIsOverBody: boolean = false;
    // Uploader instance for uploading photos to the server
    public uploader: FileUploader;
    public renameModeActiveForDocuments = new Map<DocumentMetadata, boolean>();

    // Users can upload custom PDFs. In that case, show a loading indicator.
    public pendingDocumentUploads: Map<DocumentMetadata, boolean> = new Map<DocumentMetadata, boolean>();
    // User clicked a document to be downloaded.
    public pendingDocumentDownloads: Map<DocumentMetadata, boolean> = new Map<DocumentMetadata, boolean>();
    public fullDocumentLoading: boolean = false;

    // Indicate whether an export to ADELTA.FINANZ is currently running.
    public adeltaFinanzExportPending: boolean = false;
    public kfzvsExportPending: boolean = false;
    public persaldoExportPending: boolean = false;
    public crashback24ExportPending: boolean = false;

    // Email
    public emailTransmissionPending: boolean = false;
    public emailJustSentInfo: false | { label: string; icon: string } = false;

    public selectedRecipientRole: CommunicationRecipient['role'];

    // Outgoing messages
    protected outgoingMessages: OutgoingMessage[] = [];

    // Associated invoice
    public reportInvoice: Invoice;
    private repairConfirmationInvoice: Invoice;
    private invoiceParamsInvoice: Invoice;
    public invoiceParamsDifferFromInvoice: boolean = false;

    // Building Block Overwriter
    public buildingBlockOverwriterShown: boolean;

    // File Name Pattern Editor
    public documentForFileNameEditor: {
        documentType: DocumentTypeForFileNamePattern;
        documentOrderConfigId?: string;
    };

    // Full Document Configuration Editor
    public editDefaultDocumentsDialogVisible: boolean = false;

    // Full Document Config Info Note
    public fullDocumentConfigNoteVisible: boolean = false;

    // List of custom documents that the user can select to add to the current report
    protected customDocumentOrderConfigs: DocumentOrderConfig[] = [];

    /**
     * Mobile devices do not allow download of multiple pdf files since they are opened in the same tab.
     * We discussed this and agreed, that opening the full document in the current tab is the best UI for mobile users.
     * If the invoice is not included in the full document (e-invoice must not be embedded in the full document), the user has to download the invoice separately.
     */
    public isIosDevice: boolean = false;

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        this.isIosDevice = this.checkForIosDevice();

        const routeSubscription = this.route.parent.params.subscribe((params) => (this.reportId = params['reportId']));
        const reportSubscription = this.reportDetailsService.get(this.reportId).subscribe(async (report) => {
            this.report = report;
            this.loadOutgoingMessages();

            // Select appropriate document group
            this.initializeDocumentGroup();
            // When jumping to these documents from the expert statement or the repair confirmation, select the appropriate group.
            this.route.queryParams.subscribe({
                next: (queryParams) => {
                    if (queryParams['dokumentgruppe'] === 'Reparaturbestätigung') {
                        this.selectDocumentGroup('repairConfirmation');
                    }
                    if (queryParams['dokumentgruppe'] === 'Stellungnahme') {
                        // Documents of the expert statement are located within the report group.
                        this.selectDocumentGroup('report');
                    }
                },
            });

            this.loadCustomDocumentOrderConfigs();

            // Automatically select the first recipient in the list.
            this.selectRecipient(this.recipients[0]);

            // If changes are made to the report.documents array, recalculate the filteredDocuments array in this component.
            this.registerPatchWebsocketEvent();
            this.registerOutgoingMessagesWebsocketEvents();

            this.reportInvoice = await this.invoiceService.getReportInvoice(this.report);
            this.getInvoiceParamsInvoice();
            this.compareReportInvoiceWithInvoiceParamsInvoice();
            this.getRepairConfirmationInvoice();
            this.updateCustomerSignatureDocumentTitles();

            this.joinAsRealtimeEditor();
        });
        this.subscriptions.push(
            routeSubscription,
            reportSubscription,
            // Update the team & user in case they were updated in a different tab.
            this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)),
            this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)),
        );

        this.initializeUploader();
    }

    get recipients() {
        return getCommunicationRecipientsFromReport({ report: this.report });
    }

    get selectedRecipient(): CommunicationRecipient {
        return this.recipients.find((recipient) => recipient.role === this.selectedRecipientRole);
    }

    /**
     * Select one of the recipients to send a report to.
     */
    public selectRecipient(recipient: CommunicationRecipient) {
        this.selectedRecipientRole = recipient.role;

        this.filterDocuments();
        this.insertDefaultTextTemplates().then(() => {
            this.replaceMissingPlaceholders();
        });
    }

    get documentOrders(): DocumentOrder[] {
        return this.selectedDocumentGroup === 'repairConfirmation'
            ? this.report.repairConfirmation.documentOrders
            : this.report.documentOrders;
    }

    private async loadCustomDocumentOrderConfigs(): Promise<void> {
        this.customDocumentOrderConfigs = await this.documentOrderConfigService
            .find({
                type: 'customDocument',
                'customDocumentConfig.availableInReportTypes': this.report.type,
            })
            .toPromise();
    }

    /**
     * The title of a customer signature document might change after the user changed values in the report.
     * Because we do not perform the check which customer signature document currently matches the conditions after
     * each change in the report, we need to somewhere update the titles. We might improve this in the future.
     *
     * This function determines which customer signature documents currently match the report values and updates the
     * titles of their document metadata (report.documents). This way we make sure to always display the correct title
     * of even not yet signed default signature documents (e. g. declaration of assignment).
     */
    private async updateCustomerSignatureDocumentTitles(): Promise<void> {
        // Load the signable pdf configs
        const signablePdfTemplateConfigs = await this.loadSignablePdfTemplateConfigs();

        // And check if the user actually edited their names. If not -> nothing to do here.
        const signableDocumentsHaveCustomNames = signablePdfTemplateConfigs.some((config) => !!config.title);

        if (signableDocumentsHaveCustomNames) {
            const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
                reportId: this.report._id,
            });
            const placeholderValueTree = getPlaceholderValueTree({
                fieldGroupConfigs: await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary(),
            });

            // Default Signable Documents
            const defaultSignatureDocuments: DocumentType[] = [
                'declarationOfAssignment',
                'consentDataProtection',
                'revocationInstruction',
                'powerOfAttorney',
            ];

            for (const signatureDocumentType of defaultSignatureDocuments) {
                // Check if the standard signature document has a custom title
                const customTitle = await this.getSignablePdfTemplateTitle(
                    signatureDocumentType,
                    signablePdfTemplateConfigs,
                    placeholderValues,
                    placeholderValueTree,
                );

                if (customTitle) {
                    // If so, update the title of the document meta data in this report (if it exists)
                    const documentMetaData = this.report.documents.find(
                        (document) => document.type === signatureDocumentType,
                    );
                    if (documentMetaData) {
                        documentMetaData.title = customTitle;
                    }
                }
            }

            // Custom Signable Documents
            for (const customDocument of this.report.documents.filter(
                (document) => document.type === 'customDocument' && !!document.customDocumentOrderConfigId,
            )) {
                const customTitle = await this.getSignablePdfTemplateTitle(
                    'customDocument',
                    signablePdfTemplateConfigs,
                    placeholderValues,
                    placeholderValueTree,
                    customDocument.customDocumentOrderConfigId,
                );

                if (customTitle) {
                    customDocument.title = customTitle;
                }
            }

            this.saveReport();
        }
    }

    private async loadSignablePdfTemplateConfigs() {
        try {
            return await this.signablePdfTemplateConfigService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `PDF-Vorlagen nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    private async getSignablePdfTemplateTitle(
        documentType: DocumentType,
        signablePdfTemplateConfigs: SignablePdfTemplateConfig[],
        placeholderValues: PlaceholderValues,
        placeholderValueTree: PlaceholderValueTree,
        customDocumentOrderConfigId?: DocumentOrderConfig['_id'],
    ): Promise<string> {
        const matchingConfigs: SignablePdfTemplateConfig[] = matchConditions({
            // Filter: must match tab + must not be archived.
            elementsWithConditions: signablePdfTemplateConfigs.filter(
                (signablePdfTemplateConfig) =>
                    signablePdfTemplateConfig.documentType === documentType &&
                    !signablePdfTemplateConfig.archivedAt &&
                    (!customDocumentOrderConfigId ||
                        signablePdfTemplateConfig.customDocumentOrderConfigId === customDocumentOrderConfigId),
            ),
            placeholderValues: placeholderValues,
            placeholderValueTree: placeholderValueTree,
        });

        // No matching config. So we do not have a custom title
        if (!matchingConfigs.length) return;

        return matchingConfigs[0].title;
    }

    // Contains the cover letter from the selected document group for the selected recipient.
    get activeCoverLetter(): DocumentMetadata {
        return this.filteredDocuments.find((document) => document.type === 'letter');
    }

    /**
     * Contains the documents of the selected document group that are relevant for the selected recipient.
     */
    public filterDocuments({ retryCounter = 0 }: { retryCounter?: number } = {}): void {
        /**
         * The document order tries to heal itself and afterwards calls the filterDocuments() method recursively. To prevent
         * infinity loops if an error occurs, use a retry counter.
         */
        if (retryCounter > 20) {
            this.toastService.error(
                'Dokumentenreihenfolge defekt',
                'Die Reihenfolge der Dokumente ist defekt und konnte nicht wiederhergestellt werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
            );
            return;
        }

        if (!this.report.documents || !this.selectedRecipientRole) {
            this.filteredDocuments = [];
        }

        /**
         * Letters may have a recipient role. Show only the document that is linked to the selected recipient or which are available for all (linked to none).
         * Other recipient-specific documents such as an expert statement must still be added to all
         * document orders since an expert statement addressed to the insurance may still be sent to the lawyer and the claimant.
         */
        const filteredDocuments = this.report.documents.filter((document) => {
            if (
                document.type !== 'letter' ||
                (document.type === 'letter' && document.recipientRole === this.selectedRecipientRole)
            ) {
                return true;
            }
        });

        const documentOrder = getDocumentOrderForRecipient(this.documentOrders, this.selectedRecipient.role);
        if (!documentOrder) {
            this.toastService.error(
                'Dokumentenreihenfolge nicht gefunden',
                `Keine Reihenfolge für Empfänger "${translateRecipientRole(
                    this.selectedRecipient.role,
                )}". Bitte wende dich an die <a href='/Hilfe'>Hotline</a>`,
            );
            this.filteredDocuments = this.report.documents;
            return;
        }

        let sortedDocuments: DocumentMetadata[];
        try {
            sortedDocuments = getOrderedDocuments({
                documentOrder,
                documents: filteredDocuments,
            });
        } catch (error) {
            if (error.code === 'DOCUMENT_REFERENCE_IN_DOCUMENT_ORDER_BROKEN') {
                const errorData: {
                    documentOrderItem: DocumentOrderItem;
                    documentOrder: DocumentOrder;
                    documents: DocumentMetadata[];
                } = error.data;

                console.log(
                    `Found a document order item "${errorData.documentOrderItem._id}" without a matching document in the report.documents array in report "${this.report._id}". Removing it from the document orders...`,
                );

                removeDocumentFromReport({
                    /**
                     * Since the document does not exist, we need to create a stub to make the default functions work.
                     */
                    document: { _id: errorData.documentOrderItem._id } as DocumentMetadata,
                    report: this.report,
                    documentGroup: 'both',
                });

                /**
                 * The offline-sync service ensures that there are not too many requests to the server because it throttles.
                 */
                this.saveReport();

                return this.filterDocuments({ retryCounter: retryCounter++ });
            }

            this.toastService.error(
                'Dokumenten-Reihenfolge beschädigt',
                'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
            );
            return;
        }

        //*****************************************************************************
        //  Append Potentially Missing Document Order Items
        //****************************************************************************/
        /**
         * Append all documents which were not in any document order.
         * A user should see all documents, even if a document is not in the full document config.
         */
        const documentOrderItemsOfAllRecipients: DocumentOrderItem[] = flatten(
            getDocumentOrdersOfCommunicationRecipientsFromReport({
                report: this.report,
                documentGroup: 'both',
            }).map((documentOrder) => documentOrder.items),
        );
        const documentsWithoutDocumentOrderItems: DocumentMetadata[] = [];
        for (const reportDocument of this.report.documents) {
            const documentExistsInDocumentOrders: boolean = !!findRecordById(
                documentOrderItemsOfAllRecipients,
                reportDocument._id,
            );
            if (!documentExistsInDocumentOrders) {
                documentsWithoutDocumentOrderItems.push(reportDocument);
            }
        }
        if (documentsWithoutDocumentOrderItems.length) {
            for (const reportDocument of documentsWithoutDocumentOrderItems) {
                /**
                 * Remove the document from the documents array to enable adding it back with the standard methods which expect
                 * that the document does not yet exist.
                 */
                removeFromArray(reportDocument, this.report.documents);

                addDocumentToReport(
                    {
                        newDocument: reportDocument,
                        report: this.report,
                        documentGroup: this.selectedDocumentGroup,
                        team: this.team,
                    },
                    {
                        /**
                         * This is important for letters which may exist once per recipient.
                         */
                        allowMultiple: true,
                    },
                );
            }

            void this.saveReport();

            // Filter again with the fixed document order.
            return this.filterDocuments({ retryCounter: retryCounter++ });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Append Potentially Missing Document Order Items
        /////////////////////////////////////////////////////////////////////////////*/

        this.filteredDocuments = sortedDocuments;
    }

    /**
     * If the letter and email are empty, add the default templates.
     */
    private async insertDefaultTextTemplates(): Promise<void> {
        if (!this.activeCoverLetter?.subject && !this.activeCoverLetter?.body) {
            await this.insertDefaultLetterTemplate();
        }

        const activeEmail = this.selectedRecipient.outgoingMessageDraft[this.selectedDocumentGroup];
        if (!activeEmail?.subject && !activeEmail?.body) {
            const wasDefaultTemplateInserted: boolean = await this.insertDefaultEmailTemplate();
            /**
             * If the email signature was added even though no default text template exists, a user would
             * insert his own signature when visiting the print and transmission screen. Often, however,
             * another colleague would actually send the email - now with the wrong signature.
             */
            if (wasDefaultTemplateInserted) {
                await this.insertEmailSignature();
            }
        }
    }

    public initializeDocumentGroup(): void {
        const storageKey: string = this.getDocumentGroupStorageKey();
        const lastSelectedDocumentGroup = store.session.get(storageKey);

        // Try the last selected document group if there is one
        if (this.isDocumentGroupAvailable(lastSelectedDocumentGroup)) {
            this.selectDocumentGroup(lastSelectedDocumentGroup);
        }
        // ... otherwise open the repair confirmation group
        else if (this.report.repairConfirmation) {
            this.selectDocumentGroup('repairConfirmation');
            return;
        }
        // ...and the last resort is the report
        else {
            this.selectDocumentGroup('report');
        }
    }

    public isDocumentGroupAvailable(documentGroupName: DocumentGroupName): boolean {
        switch (documentGroupName) {
            case 'report':
                return true;
            case 'repairConfirmation':
                return !!(this.report.repairConfirmation && this.report.repairConfirmation?.documentOrders?.length);
            default:
                return false;
        }
    }

    public getClaimantDenomination() {
        return Translator.claimantDenomination(this.report?.type);
    }

    //*****************************************************************************
    //  Handling Documents
    //****************************************************************************/

    public openDocumentBuildingBlocksInNewTab(): void {
        window.open('/Einstellungen/Textbausteine');
    }

    public toggleHeaderAndFooterOnDocuments(): void {
        this.userPreferences.printDocumentsWithoutHeaderAndFooter =
            !this.userPreferences.printDocumentsWithoutHeaderAndFooter;
    }

    public selectDocumentGroup(documentGroupName: DocumentGroupName): void {
        if (!this.isDocumentGroupAvailable(documentGroupName)) return;

        this.selectedDocumentGroup = documentGroupName;

        // Remember the setting in session storage
        const storageKey: string = this.getDocumentGroupStorageKey();
        store.session.set(storageKey, documentGroupName);
    }

    /**
     * Activate all currently visible documents for the applicable full document config.
     */
    public activateVisibleDocuments() {
        for (const document of this.filteredDocuments) {
            const fullDocumentConfigPart = this.getDocumentOrderItemByDocument(document);
            fullDocumentConfigPart.includedInFullDocument = true;
        }
        this.saveReport();
    }

    /**
     * Deactivate all currently visible documents for the applicable full document config.
     */
    public deactivateVisibleDocuments() {
        for (const document of this.filteredDocuments) {
            const fullDocumentConfigPart = this.getDocumentOrderItemByDocument(document);
            fullDocumentConfigPart.includedInFullDocument = false;
        }
        this.saveReport();
    }

    /**
     * The click event is fired before the change event. Jot down if the user held shift while clicking.
     */
    public handleToggleClick(event: MouseEvent) {
        this.userClickedOnDocumentToggleWithShift = event.shiftKey;
    }

    public handleToggleChange(event: MatLegacySlideToggleChange) {
        if (this.userClickedOnDocumentToggleWithShift) {
            // This toggle was activated --> activate all documents.
            if (event.checked) {
                this.activateVisibleDocuments();
            } else {
                this.deactivateVisibleDocuments();
            }
            /**
             * If the user clicked with shift this marked should be reset. If the user later would toggle a document
             * with his keyboard, the click event would not fire. If this marker would not be reset, toggling via the
             * keyboard would still activate/deactivate all documents. That's unexpected behavior.
             */
            this.userClickedOnDocumentToggleWithShift = false;
        }
        // Activating or deactivating all documents already causes a report save. This line must therefore only be executed if a single toggle is changed.
        else {
            this.saveReport();
        }
    }

    /**
     * There is an applicable DocumentReference for each document.
     * If a document is included or excluded from the full document is stored in the full document config and not in the document.
     */
    public getDocumentOrderItemByDocument(document: DocumentMetadata): DocumentOrderItem {
        if (!document) {
            return null;
        }

        let documentOrder: DocumentOrder;
        try {
            documentOrder = getDocumentOrderForRecipient(this.documentOrders, this.selectedRecipient.role);
        } catch (error) {
            console.error('Error loading the document order for the selected recipient', error);
            this.toastService.error(
                'Dokumentenreihenfolge nicht gefunden',
                `Keine Gesamt-PDF Reihenfolge für Empfänger "${translateRecipientRole(
                    this.selectedRecipient.role,
                )}" und Dokument-Gruppe "${
                    this.selectedDocumentGroup
                }". Bitte wende dich an die <a href='/Hilfe'>Hotline</a>.`,
            );
            return;
        }

        return getDocumentOrderItemForDocument(documentOrder, document);
    }

    /**
     * Deactivate Document for current recipient
     */
    public deactivateFromCurrentFullDocument(document: DocumentMetadata) {
        this.getDocumentOrderItemByDocument(document).includedInFullDocument = false;
    }

    /**
     * Removes the DocumentReference for a given document from the full document config of the active document group.
     * Removes from each recipient.
     */
    private removeDocumentFromFullDocument(document: DocumentMetadata) {
        removeDocumentFromReport({
            document,
            report: this.report,
            documentGroup: this.selectedDocumentGroup,
        });
        this.saveReport();
    }

    private getDocumentGroupStorageKey(): string {
        return `documentGroup-${this.report._id}`;
    }

    public onDocumentReordered(event: CdkDragDrop<string[]>): void {
        if (event.previousIndex === event.currentIndex) {
            return;
        }
        //*****************************************************************************
        //  Sort Document Order
        //****************************************************************************/
        const documentOrder = getDocumentOrderForRecipient(this.documentOrders, this.selectedRecipient.role);
        if (!documentOrder) {
            this.toastService.error(
                'Dokumentenreihenfolge nicht gefunden',
                `Keine Reihenfolge für Empfänger "${translateRecipientRole(
                    this.selectedRecipient.role,
                )}". Bitte wende dich an die <a href='/Hilfe'>Hotline</a>`,
            );
            return;
        }

        moveItemInArray(documentOrder.items, event.previousIndex, event.currentIndex);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Sort Document Order
        /////////////////////////////////////////////////////////////////////////////*/

        // Refresh the view
        this.filterDocuments();

        const isRepairConfirmation = this.selectedDocumentGroup !== 'report';
        /**
         * Optionally, sort all recipients' document orders equally.
         *
         * The repair confirmation document group is so simple that we decided, that those documents are always sorted the same
         * for all recipients.
         */
        if (isRepairConfirmation || !this.team.preferences.allowCustomDocumentOrderPerRecipient) {
            for (const documentOrderToBeSorted of this.documentOrders) {
                if (documentOrderToBeSorted === documentOrder) {
                    continue;
                }

                adaptDocumentOrder({
                    templateOrder: documentOrder,
                    orderToBeSorted: documentOrderToBeSorted,
                    documents: this.report.documents,
                });
            }
        }

        // Save the new order back to the server.
        void this.saveReport();

        // Info Note
        if (
            !isRepairConfirmation &&
            !this.user.userInterfaceStates.fullDocumentConfigNoteShown &&
            this.isAdmin(this.user._id, this.team)
        ) {
            this.fullDocumentConfigNoteVisible = true;
        }
    }

    public closeFullDocumentConfigNote() {
        this.fullDocumentConfigNoteVisible = false;
        this.user.userInterfaceStates.fullDocumentConfigNoteShown = true;
        this.saveUser();
    }

    //*****************************************************************************
    //  Team Default Document Orders
    //****************************************************************************/
    /**
     * Open the dialog to allow a team to configure default order and set the default included/excluded flag per document.
     */
    public showEditDefaultDocumentOrdersDialog(): void {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        let hasDefaultDocumentOrderBeenChanged: boolean = false;
        for (const defaultDocumentOrderGroup of this.team.preferences.defaultDocumentOrderGroups) {
            // Invoice and repair confirmation do not contain regular report documents, so their default document orders do not need to be completed.
            if (
                defaultDocumentOrderGroup.types.includes('invoice') ||
                defaultDocumentOrderGroup.types.includes('repairConfirmation')
            ) {
                continue;
            }
            for (const documentOrder of defaultDocumentOrderGroup.documentOrders) {
                const numberOfAddedDocumentItems: number = addMissingReportDocumentTypesToDefaultDocumentOrderItems(
                    documentOrder.items,
                );
                if (numberOfAddedDocumentItems) {
                    hasDefaultDocumentOrderBeenChanged = true;
                }
            }
        }

        if (hasDefaultDocumentOrderBeenChanged) {
            this.saveTeam();
        }

        this.editDefaultDocumentsDialogVisible = true;
    }

    /**
     * Sort and activate the documents for the currently selected recipient based on the default document order config.
     * This is helpful when the user reordered the documents or changed the activation status and wants to undo all changes.
     */
    protected applyDefaultDocumentOrder(): void {
        const currentDocumentOrder = getDocumentOrderForRecipient(this.documentOrders, this.selectedRecipient.role);
        let defaultDocumentOrder;

        try {
            defaultDocumentOrder = getDefaultDocumentOrderByTypeAndCommunicationRecipientRole({
                team: this.team,
                defaultDocumentOrderGroupType:
                    this.selectedDocumentGroup === 'repairConfirmation' ? 'repairConfirmation' : this.report.type,
                communicationRecipientRole: this.selectedRecipient.role,
            });
        } catch (error) {
            throw new AxError({
                code: 'GETTING_DEFAULT_DOCUMENT_ORDER_FAILED',
                message:
                    'The default document order could not be retrieved when resetting the document order for a report.',
                data: {
                    reportType: this.report.type,
                    communicationRecipientRole: this.selectedRecipient.role,
                },
                error,
            });
        }

        // Reset the order and activation status
        resetDocumentOrderToDefault({
            templateOrder: defaultDocumentOrder,
            orderToBeSorted: currentDocumentOrder,
            documents: this.report.documents,
            activateNewDocumentsByDefault: this.userPreferences.activateDocumentsAfterUpload,
        });

        // Close the hint, that the document order has been changed.
        this.fullDocumentConfigNoteVisible = false;

        // Save changes
        void this.saveReport();

        // Refresh the view
        this.filterDocuments();

        // Notify user
        this.toastService.info(
            'Reihenfolge zurückgesetzt',
            'Die Reihenfolge und Aktivierung der Dokumente wurden auf den Standard für den ausgewählten Empfänger zurückgesetzt.',
        );
    }

    /**
     * Adds a given document to all full document configs of a team.
     * In recipient specific configs, the included property will be the same as in the current report.
     * In all recipient independent configs, the included property of the current recipient will be used.
     */
    public addDocumentToTeamDefaultFullDocumentConfig(document: DocumentMetadata) {
        if (this.selectedDocumentGroup !== 'report') return;

        if (document.type === 'manuallyUploadedPdf' && !document.uploadedDocumentId) {
            console.error(
                "UploadedDocumentId not set for manually uploaded document. Won't add to team.preferences.fullDocumentConfig",
            );
            return;
        }

        for (const defaultDocumentOrderGroup of this.team.preferences.defaultDocumentOrderGroups) {
            for (const defaultDocumentOrder of defaultDocumentOrderGroup.documentOrders) {
                const existingDefaultDocumentOrderItem = defaultDocumentOrder.items.find(
                    (defaultDocumentOrderItem) =>
                        defaultDocumentOrderItem.documentType === document.type &&
                        // Manually uploaded PDFs are not unique by their type ("manuallyUploadedPdf") but by their uploadedDocumentId.
                        defaultDocumentOrderItem.uploadedDocumentId === document.uploadedDocumentId &&
                        // Custom documents are not unique by their type ("customDocument") but by their customDocumentOrderConfigId.
                        defaultDocumentOrderItem.customDocumentOrderConfigId === document.customDocumentOrderConfigId,
                );
                if (!existingDefaultDocumentOrderItem) {
                    defaultDocumentOrder.items.push(
                        new DefaultDocumentOrderItem({
                            documentType: document.type,
                            uploadedDocumentId: document.uploadedDocumentId,
                            customDocumentOrderConfigId: document.customDocumentOrderConfigId,
                        }),
                    );
                }
            }
        }

        console.log(`Added "${document.type}" to team's default document orders.`);
        void this.saveTeam();
    }

    /**
     * Removes a given document from all full document configs of a team.
     */
    public removeDocumentFromTeamDefaultFullDocumentConfig(document: DocumentMetadata) {
        if (this.selectedDocumentGroup !== 'report') return;

        if (document.type === 'manuallyUploadedPdf' && !document.uploadedDocumentId) {
            console.error(
                "UploadedDocumentId not set for manually uploaded document. Won't remove it from the team's default document orders.",
            );
            return;
        }

        for (const defaultDocumentOrderGroup of this.team.preferences.defaultDocumentOrderGroups) {
            for (const defaultDocumentOrder of defaultDocumentOrderGroup.documentOrders) {
                const existingDefaultDocumentOrderItem = defaultDocumentOrder.items.find(
                    (defaultDocumentOrderItem) =>
                        defaultDocumentOrderItem.documentType === document.type &&
                        // Manually uploaded PDFs are not unique by their type ("manuallyUploadedPdf") but by their uploadedDocumentId.
                        defaultDocumentOrderItem.uploadedDocumentId === document.uploadedDocumentId &&
                        // Custom documents are not unique by their type ("customDocument") but by their customDocumentOrderConfigId.
                        defaultDocumentOrderItem.customDocumentOrderConfigId === document.customDocumentOrderConfigId,
                );
                if (existingDefaultDocumentOrderItem) {
                    removeFromArray(existingDefaultDocumentOrderItem, defaultDocumentOrder.items);
                }
            }
        }

        console.log(`Removed "${document.type}" from team's default document orders.`);
        void this.saveTeam();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team Default Document Orders
    /////////////////////////////////////////////////////////////////////////////*/

    public handleDocumentClick(document: DocumentMetadata, $event: MouseEvent) {
        if ($event.shiftKey) {
            $event.preventDefault();
            void this.downloadDocument(document, 'docx');
        } else if (this.keyboardService.isKeyDown('e') || this.keyboardService.isKeyDown('d')) {
            if (!isRenderedDocumentType(document.type)) {
                this.toastService.info(
                    'Wasserzeichen nur bei Textbaustein-basierten Dokumenten',
                    `Das Wasserzeichen wird technisch schon auf DOCX-Ebene hinzugefügt, nicht erst auf den PDFs. Dadurch ist es nur bei Textbaustein-basierten Dokumenten nutzbar, die von ${getProductName()} generiert werden.<br><br>Falls du für dieses Dokument ein Wasserzeichen einfügen möchtest, füge es über eine externe Applikation hinzu und lade es erneut hoch.`,
                    {
                        showProgressBar: true,
                    },
                );
                return;
            }

            if (this.keyboardService.isKeyDown('e')) {
                /**
                 * The shortcut "e" is used to toggle "Entwurf".
                 */
                if (document.docxWatermarkType === 'draft') {
                    this.deactivateWatermark(document);
                } else {
                    this.activateWatermarkConfig({
                        documentMetadata: document,
                        docxWatermarkType: 'draft',
                    });
                }
            } else if (this.keyboardService.isKeyDown('d')) {
                /**
                 * The shortcut "d" is used to toggle "Duplikat".
                 */
                if (document.docxWatermarkType === 'duplicate') {
                    this.deactivateWatermark(document);
                } else {
                    this.activateWatermarkConfig({
                        documentMetadata: document,
                        docxWatermarkType: 'duplicate',
                    });
                }
            }
        } else {
            void this.downloadDocument(document, 'pdf');
        }
    }

    public async downloadDocument(document: DocumentMetadata, format: 'pdf' | 'docx' = 'pdf'): Promise<void> {
        /**
         * Before trying to download a document, check internet connection and re-connect if offline. This ensures that the user
         * needs to reconnect manually as little as possible, e.g. because he accumulated a long reconnect timeout but has a solid
         * Internet connection by now already.
         */
        if (!this.networkStatusService.isOnline()) {
            try {
                await this.networkStatusService.detectNetworkStatus();
            } catch (error) {
                this.toastService.offline(
                    'Offline nicht verfügbar',
                    'Dokumente können heruntergeladen werden, sobald du wieder online bist.',
                );
                return;
            }
        }

        // Prevent moochers from using our DOCX download. It seems that most regular testers don't use the DOCX export anyways.
        if (format === 'docx' && !this.isTeamMatureCustomer()) {
            this.toastService.warn('DOCX-Download noch nicht verfügbar', this.getMessageAboutBlockedDocxDownload());
            return;
        }

        this.pendingDocumentDownloads.set(document, true);

        const { downloadPath, httpParams } = this.getDownloadPathAndParams({ document, format });

        // Prevent errors in case of unknown doc type
        if (!downloadPath) {
            this.toastService.warn('Dokument nicht verfügbar', "Bitte wende dich an die <a href='/Hilfe'>Hotline</a>");
            console.error('DOWNLOAD_SUFFIX_NOT_AVAILABLE', 'Trying to map for this doc type: ', document.type);
            return;
        }

        try {
            /**
             * Before we had this statement, some users reported that their documents contained old content. This is because the
             * document was generated before the report was saved -> A typical race condition.
             */
            await this.reportService.pushToServer();

            const response = await this.httpClient
                .get(`/api/v0/${downloadPath}`, {
                    responseType: 'blob',
                    observe: 'response',
                    params: httpParams,
                })
                .toPromise();

            this.downloadService.downloadBlobResponseWithHeaders(response);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    DOCUMENT_NOT_FOUND_ON_REPORT: {
                        title: 'Dokument nicht gefunden',
                        body: `Das Dokument "${translateDocumentType(error.data?.documentMetadataType)}" konnte nicht im Gutachten gefunden werden.<br><br>Bitte lösche das Dokument und füge es neu ein.`,
                    },
                },
                defaultHandler: {
                    title: 'Fehler beim Download',
                    body: 'Die PDF-Datei konnte nicht heruntergeladen werden.',
                },
            });
        } finally {
            this.pendingDocumentDownloads.delete(document);
        }
    }

    private getDownloadPathAndParams({ document, format }: { document: DocumentMetadata; format: 'pdf' | 'docx' }): {
        downloadPath: string;
        httpParams: HttpParams;
    } {
        let downloadPath: string;
        let httpParams = new HttpParams();

        // If a manually uploaded pdf was uploaded and format pdf is requested, the backend will return the userUploadedDocument.
        switch (document.type) {
            case 'declarationOfAssignment':
            case 'consentDataProtection':
            case 'powerOfAttorney':
            case 'revocationInstruction':
            case 'garageInformation':
            case 'photoPortfolio':
            case 'manualCalculation':
            case 'diminishedValueProtocol':
            case 'repairConfirmation':
            case 'classicanalyticsValuation':
            case 'afzzertCertificate':
            case 'report':
            case 'customResidualValueBidList':
            case 'autoonlineResidualValueBidList':
            case 'winvalueResidualValueBidList':
            case 'cartvResidualValueBidList':
            case 'carcasionResidualValueBidList':
                httpParams = httpParams.append('format', format);
                downloadPath = `reports/${this.reportId}/documents/${document.type}`;
                break;
            case 'letter':
                httpParams = httpParams.append('format', format);
                downloadPath = `reports/${this.reportId}/documents/letters/${document._id}`;
                break;
            case 'expertStatement':
                httpParams = httpParams.append('format', format);
                downloadPath = `reports/${this.reportId}/documents/expertStatements/${document.expertStatementId}`;
                break;
            case 'datDamageCalculation':
                httpParams = httpParams.append('format', format);
                downloadPath = `reports/${this.reportId}/documents/datDamageCalculation`;
                break;
            case 'datMarketAnalysis':
                downloadPath = `reports/${this.reportId}/documents/datValuation`;
                break;
            case 'audatexMarketAnalysis':
                downloadPath = `reports/${this.reportId}/documents/audatexValuation`;
                break;
            case 'winvalueMarketAnalysis':
                downloadPath = `reports/${this.reportId}/documents/winvalueMarketAnalysis`;
                break;
            case 'cartvMarketAnalysis':
                downloadPath = `reports/${this.reportId}/documents/cartvMarketAnalysis`;
                break;
            case 'valuepilotMarketAnalysis':
                downloadPath = `reports/${this.reportId}/documents/valuepilotMarketAnalysis`;
                break;
            case 'invoice':
                // Custom invoices
                if (document.invoiceId) {
                    httpParams = httpParams.append('format', format);
                    downloadPath = `invoices/${document.invoiceId}/documents/invoice`;
                }
                // Invoice generated from report which does not have an invoiceId, even if the report has been closed and the invoice has been created.
                else {
                    httpParams = httpParams.append('format', format);
                    httpParams = httpParams.append('documentGroup', this.selectedDocumentGroup);
                    downloadPath = `reports/${this.reportId}/documents/invoice`;
                }
                break;
            case 'datValuationProtocol':
                httpParams = httpParams.append('protocol', true);
                downloadPath = `reports/${this.reportId}/documents/datValuation`;
                break;
            case 'manuallyUploadedPdf':
                downloadPath = `reports/${this.reportId}/documents/userUploads/${document.uploadedDocumentId}`;
                break;
            case 'customDocument':
                const isSignableCustomDocument = this.report.signableDocuments.find(
                    (signableDocument) =>
                        signableDocument.customDocumentOrderConfigId === document.customDocumentOrderConfigId,
                );
                const urlDocumentType = isSignableCustomDocument ? 'customSignatureDocument' : 'customDocument';
                httpParams = httpParams.append('format', format);
                downloadPath = `reports/${this.reportId}/documents/${urlDocumentType}?customDocumentOrderConfigId=${document.customDocumentOrderConfigId}`;
                break;
            default:
                console.error('DOWNLOAD_OF_UNKNOWN_DOC_TYPE', document.type);
                this.toastService.error(
                    'Unbekannter Dokumenttyp',
                    'Bitte setze dich mit dem autoiXpert-Support in Verbindung.',
                );
        }
        if (this.userPreferences.printDocumentsWithoutHeaderAndFooter) {
            // If this is the first query param, add a question mark. Otherwise, an ampersand.
            httpParams = httpParams.append('printWithoutHeaderAndFooter', true);
        }
        return { downloadPath, httpParams };
    }

    public isTeamMatureCustomer(): boolean {
        return isTeamMatureCustomer({ team: this.team });
    }

    public getMessageAboutBlockedDocxDownload(): string {
        const testPeriodEndDate: string = DateTime.fromISO(this.team.createdAt)
            .plus({ day: TEST_PERIOD_DURATION_IN_DAYS })
            .setLocale('de')
            .toLocaleString(GERMAN_DATE_FORMAT);
        return `Falls du diese Funktion vor dem ${testPeriodEndDate} (Ende des Testzeitraums) nutzen möchtest, kontaktiere uns bitte persönlich. Wir schalten sie gerne für dich frei.\n\nDieses Datum bleibt auch von einer Bestellung unberührt.`;
    }

    public async downloadFullDocument(): Promise<void> {
        // Prevent the user from triggering a download while it's still in progress.
        if (this.fullDocumentLoading) {
            return;
        }

        /**
         * Before trying to download a document, check internet connection and re-connect if offline. This ensures that the user
         * needs to reconnect manually as little as possible, e.g. because he accumulated a long reconnect timeout but has a solid
         * Internet connection by now already.
         */
        if (!this.networkStatusService.isOnline()) {
            try {
                await this.networkStatusService.detectNetworkStatus();
            } catch (error) {
                this.toastService.offline(
                    'Offline nicht verfügbar',
                    'Dokumente können heruntergeladen werden, sobald du wieder online bist.',
                );
                return;
            }
        }

        this.fullDocumentLoading = true;

        let documentGroupPath: string = '';

        switch (this.selectedDocumentGroup) {
            case 'report':
                documentGroupPath = `fullReports`;
                break;
            case 'repairConfirmation':
                documentGroupPath = `fullRepairConfirmations`;
                break;
            default:
                throw Error('TRYING_TO_DOWNLOAD_FULL_DOCUMENT_OF_UNKNOWN_DOCUMENT_GROUP');
        }

        let url = `/api/v0/reports/${this.reportId}/documents/${documentGroupPath}/${this.selectedRecipientRole}`;
        if (this.userPreferences.printDocumentsWithoutHeaderAndFooter) {
            url += '?printWithoutHeaderAndFooter=true';
        }
        try {
            /**
             * Before we had this statement, some users reported that their documents contained old content. This is because the
             * document was generated before the report was saved -> A typical race condition.
             */
            await this.reportService.pushToServer();

            //*****************************************************************************
            //  Download Invoice Separately
            //****************************************************************************/
            let invoicePromise: Promise<HttpResponse<Blob>>;
            if (this.isInvoiceActive() && this.report.feeCalculation.invoiceParameters.isElectronicInvoiceEnabled) {
                if (this.isIosDevice) {
                    console.log('iOS Device detected. Invoice is not downloaded separately.');
                } else {
                    this.toastService.info(
                        'Rechnung wird separat heruntergeladen',
                        'Eine gültige E-Rechnung muss laut Gesetz eine eigene Datei sein, weshalb sie als zweite Datei neben der Gesamt-PDF heruntergeladen wird.',
                    );

                    const invoiceDocument: DocumentMetadata = this.filteredDocuments.find(
                        (document) => document.type === 'invoice',
                    );

                    const { downloadPath, httpParams } = this.getDownloadPathAndParams({
                        document: invoiceDocument,
                        format: 'pdf',
                    });

                    invoicePromise = this.httpClient
                        .get(`/api/v0/${downloadPath}`, {
                            responseType: 'blob',
                            observe: 'response',
                            params: httpParams,
                        })
                        .toPromise();
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Download Invoice Separately
            /////////////////////////////////////////////////////////////////////////////*/

            const [fullReportExceptInvoiceResponse, invoiceResponse]: [HttpResponse<Blob>, HttpResponse<Blob>] =
                await Promise.all([
                    this.httpClient
                        .get(url, {
                            responseType: 'blob',
                            observe: 'response',
                        })
                        .toPromise(),
                    invoicePromise,
                ]);

            this.downloadService.downloadBlobResponseWithHeaders(fullReportExceptInvoiceResponse);
            if (invoiceResponse) {
                /**
                 * FileSaver.js does not download multiple files at once on Safari (MacOS and iOS). It only downloads the last file.
                 * Wrapping the second saveAs-call in a setTimeout fixes this issue.
                 * wrapping both calls in a setTimeout does not work, therefore we have to apply this fix here and not in the app.component.ts to ensure both files are downloaded in different async event cycles.
                 * See here: https://github.com/eligrey/FileSaver.js/issues/729#issue-1016036112
                 */
                setTimeout(() => {
                    this.downloadService.downloadBlobResponseWithHeaders(invoiceResponse);
                }, 0);
            }
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: () =>
                    this.toastService.error(
                        'Fehler beim Download',
                        'Die PDF-Datei konnte nicht heruntergeladen werden.',
                    ),
            });
        } finally {
            this.fullDocumentLoading = false;
        }
    }

    public isDocxFileAvailable(document: DocumentMetadata): boolean {
        return isRenderedDocumentType(document.type) || document.type === 'datDamageCalculation';
    }

    public mayDocumentBeRenamed(document: DocumentMetadata): boolean {
        return new Array<DocumentType>(
            'manuallyUploadedPdf',
            'datDamageCalculation',
            'manualCalculation',
            'photoPortfolio',
        ).includes(document.type);
    }

    public mayDocumentBeDeleted(document: DocumentMetadata): boolean {
        return new Array<DocumentType>(
            'manuallyUploadedPdf',
            'datDamageCalculation',
            'manualCalculation',
            'photoPortfolio',
            'customDocument',
        ).includes(document.type);
    }

    public toggleRenamingUploadedDocument(document: DocumentMetadata): void {
        this.renameModeActiveForDocuments.set(document, !this.renameModeActiveForDocuments.get(document));
    }

    /**
     * Listens for the user to hit the enter key. If done so, deactivate the edit mode.
     */
    public renameKeydownListener(event: KeyboardEvent, document: DocumentMetadata): void {
        switch (event.key) {
            case 'Enter':
            case 'Escape':
                this.toggleRenamingUploadedDocument(document);
                break;
        }
    }

    /**
     * Users may upload custom PDF files. This method allows deleting those custom PDFs.
     */
    public async deleteCustomDocument(document: DocumentMetadata): Promise<void> {
        // If the document is permanent, confirm deletion.
        if (document.permanentUserUploadedDocument) {
            // User needs access right editTextsAndDocumentBuildingBlocks to remove permanent documents
            if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
                this.toastService.error(
                    'Permanenter Anhang nicht gelöscht',
                    `Um permanente Anhänge dauerhaft löschen zu können, benötigst du das Zugriffsrecht ${translateAccessRightToGerman('editTextsAndDocumentBuildingBlocks')}.`,
                );
                return;
            }

            const confirmDecision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Permanenten Anhang löschen?',
                        content:
                            'Er ist dann auch in anderen Gutachten nicht mehr verfügbar, in denen er bisher eingefügt wurde.',
                        confirmLabel: 'Löschen',
                        cancelLabel: 'Doch behalten',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();

            // If the user didn't confirm the deletion, abort.
            if (!confirmDecision) return;
        }

        removeDocumentFromReport({ document, report: this.report, documentGroup: this.selectedDocumentGroup });
        this.filterDocuments();

        // Remove from this reports full document config
        this.removeDocumentFromFullDocument(document);

        // A PDF has already been uploaded for this report document.
        if (document.uploadedDocumentId) {
            try {
                await this.removeUploadedDocumentFromServer(document);
            } catch (error) {
                this.toastService.warn(
                    'PDF-Datei nicht gelöscht',
                    'Bitte lade die Seite neu. Existiert das Dokument immer noch, kontaktiere bitte die Hotline.',
                );
            }

            if (document.permanentUserUploadedDocument) {
                this.removePermanentDocumentFromPreferences(document);
                this.toastService.info(
                    'Permanenter Anhang gelöscht',
                    'Möglicherweise hast du diese PDF-Datei auch in anderen Gutachten verwendet. Dort ist sie nun nicht mehr verfügbar.',
                );
            }
        }
        this.saveReport();
    }

    public async removeUploadedDocument(document: DocumentMetadata) {
        await this.removeUploadedDocumentFromServer(document);
        this.toastService.success('Löschen erfolgreich');

        document.uploadedDocumentId = null;
        this.saveReport();
    }

    private async removeUploadedDocumentFromServer(document: DocumentMetadata): Promise<any> {
        try {
            return await this.httpClient
                .delete(`/api/v0/reports/${this.reportId}/documents/userUploads/${document.uploadedDocumentId}`)
                .toPromise();
        } catch (error) {
            this.toastService.error(
                'Löschen fehlgeschlagen',
                'Das hochgeladene Dokument konnte nicht gelöscht werden.',
            );
        }
    }

    public persistCustomDocument(document: DocumentMetadata): void {
        // If the document is already marked persistent, no need to do anything.
        if (document.permanentUserUploadedDocument && this.isPermanentDocumentActiveForFutureReports(document)) {
            this.toastService.info(
                'Anhang aktualisieren',
                'Das Dokument wird bereits an zukünftige Gutachten angehängt. Der Name und der Status (aktiv/inaktiv) werden aktualisiert.',
            );
        }

        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        document.permanentUserUploadedDocument = true;
        this.saveReport();

        // Save the document to the team. When creating a report, the document will be added to the report as a manually uploaded pdf. The file will be referenced by the same ID
        // to increase performance (no copying of PDF files) and to save space on AWS S3.
        this.team.permanentUploadedDocuments.push(document);
        this.saveTeam();

        // Since this document is now permanent, it will be added to the default document order.
        this.addDocumentToTeamDefaultFullDocumentConfig(document);

        this.toastService.success(
            'Anhang permanent',
            'Dieses Dokument wird an alle zukünftigen Gutachten angehängt, bis es gelöscht wird.',
        );
    }

    public isPermanentDocumentActiveForFutureReports(document: DocumentMetadata): boolean {
        return !!this.team.permanentUploadedDocuments.find(
            (permanentDocument) => permanentDocument._id === document._id,
        );
    }

    private removePermanentDocumentFromPreferences(document: DocumentMetadata): void {
        const existingDocument = this.team.permanentUploadedDocuments.find(
            (permanentDocument) => permanentDocument.uploadedDocumentId === document.uploadedDocumentId,
        );
        if (existingDocument) {
            this.team.permanentUploadedDocuments.splice(
                this.team.permanentUploadedDocuments.indexOf(existingDocument),
                1,
            );
        }

        /**
         * This default document order defines where a new document would be added. Remove the now obsolete item from
         * that list.
         */
        this.removeDocumentFromTeamDefaultFullDocumentConfig(document);
    }

    public removePermanentDocumentForFutureReports(document: DocumentMetadata) {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        this.removePermanentDocumentFromPreferences(document);
        this.toastService.success(
            'Anhang deaktiviert',
            'Künftigen Gutachten wird dieses Dokument nicht mehr angehängt.',
        );
    }

    /**
     * If the user decides to remove a document that he made permanent (= it's added to every new report automatically),
     * he can choose to remove it for all future reports or only for this one. This method is for the latter.
     */
    public deletePermanentDocumentFromThisReport(document: DocumentMetadata) {
        this.removeDocumentFromFullDocument(document);
        this.filterDocuments();
        this.saveReport();
    }

    public getTooltipForDocument(document: DocumentMetadata) {
        const tooltipParts: string[] = [];

        if (!this.isDocumentAvailable(document)) {
            switch (document.type) {
                case 'report':
                    tooltipParts.push(
                        'Es müssen zuerst eine Schadenskalkulation durchgeführt und der Anspruchsteller angegeben werden.',
                    );
                    break;
                case 'invoice':
                    if (this.selectedDocumentGroup === 'report') {
                        tooltipParts.push('Zuerst muss ein Rechnungsbetrag angegeben werden.');
                    }
                    if (this.selectedDocumentGroup === 'repairConfirmation') {
                        tooltipParts.push('Zuerst muss die Rechnung erstellt werden.');
                    }
                    break;
                case 'repairConfirmation':
                    tooltipParts.push(
                        'Zuerst müssen das Reparaturergebnis und das Besichtigungsdatum angegeben werden.',
                    );
                    break;
                case 'letter':
                    tooltipParts.push('Es muss mindesten ein Betreff oder eine Nachricht angegeben werden.');
                    break;
            }
        }

        if (this.getDocumentOrderItemByDocument(document).includedInFullDocument) {
            tooltipParts.push('Das Dokument wird in die Gesamt-PDF gedruckt und als E-Mail-Anhang beigefügt.');
        } else {
            tooltipParts.push('Das Dokument wird nicht gedruckt und nicht per E-Mail versendet.');
        }

        tooltipParts.push('Umschalt ⇧ + Klick aktiviert/deaktiviert alle sichtbaren Dokumente.');

        return tooltipParts.join('\n\n');
    }

    public isDocumentAvailable(document: DocumentMetadata): boolean {
        // If the user uploaded a manually uploaded pdf to a specific document type, it is always available.
        if (document.uploadedDocumentId) {
            return true;
        }

        switch (document.type) {
            // Always available if uploaded.
            case 'afzzertCertificate':
            case 'audatexMarketAnalysis':
            case 'autoonlineResidualValueBidList':
            case 'carcasionResidualValueBidList':
            case 'cartvMarketAnalysis':
            case 'cartvResidualValueBidList':
            case 'classicanalyticsValuation':
            case 'manuallyUploadedPdf':
            case 'consentDataProtection':
            case 'customDocument':
            case 'customResidualValueBidList':
            case 'datDamageCalculation':
            case 'datMarketAnalysis':
            case 'datValuationProtocol':
            case 'declarationOfAssignment':
            case 'garageInformation':
            case 'photoPortfolio':
            case 'powerOfAttorney':
            case 'repairConfirmation':
            case 'report':
            case 'revocationInstruction':
            case 'valuepilotMarketAnalysis':
            case 'winvalueMarketAnalysis':
            case 'winvalueResidualValueBidList':
                return true;
            case 'letter':
            case 'expertStatement':
                return !!(document.subject || document.body);
            case 'invoice':
                // An invoiceId indicates that there's an invoice object that will be used
                if (document.invoiceId) {
                    return true;
                }
                if (this.selectedDocumentGroup === 'report') {
                    /**
                     * Either the invoice has a value larger than 0 or the invoice has a value of 0 but the assessor's fee was set
                     * (the entire invoice was discounted to 0), consider the invoice to be available.
                     */
                    return this.invoiceParamsInvoice?.totalNet > 0 || !!this.report.feeCalculation.assessorsFee;
                }
                if (this.selectedDocumentGroup === 'repairConfirmation') {
                    return !!this.report.repairConfirmation.invoiceParameters._id;
                }
                break;
            case 'manualCalculation':
                // TODO Move Audatex & GT Motive to their own document types.
                return (
                    this.report.damageCalculation?.repair.calculationProvider === 'manual' ||
                    this.report.damageCalculation?.repair.calculationProvider === 'audatex' ||
                    this.report.damageCalculation?.repair.calculationProvider === 'gtmotive'
                );
            case 'diminishedValueProtocol':
                return !!this.report.valuation.diminishedValueCalculation;
            default:
                console.error('CHECKING_AVAILABILITY_OF_UNKNOWN_DOC_TYPE', document.type);
                this.toastService.error(
                    'Unbekannter Dokumenttyp',
                    'Bitte setze dich mit dem autoiXpert-Support in Verbindung.',
                );
        }
    }

    public clipString = clipString;

    public getDocumentTooltip(document: DocumentMetadata): string {
        let tooltip: string = 'Dokument herunterladen';

        if (document.type === 'expertStatement') {
            tooltip = `${tooltip} - ${document.subject}`;
        }

        return tooltip;
    }

    public isDocumentMissingSignature(document: DocumentMetadata): boolean {
        let isCustomSignableDocument: boolean = false;
        if (document.type === 'customDocument') {
            isCustomSignableDocument =
                this.customDocumentOrderConfigs.find(
                    (customDocumentOrderConfig) =>
                        customDocumentOrderConfig._id === document.customDocumentOrderConfigId,
                )?.customDocumentConfig?.documentTypeGroup === 'signature';
        }

        /**
         * Custom documents need the document order config to be present to determine whether they are signable or not.
         * They are checked separately from the other signable documents.
         */
        const nonCustomSignableDocuments: SignableDocumentType[] = [...signableDocumentTypes];
        removeFromArray('customDocument', nonCustomSignableDocuments);

        const isSignableDocument =
            nonCustomSignableDocuments.includes(document.type as SignableDocumentType) || isCustomSignableDocument;

        // Don't perform the check for documents outside the signable documents group declared above.
        if (!isSignableDocument) return;

        // If a custom upload exists, we cannot know if the signature is missing.
        if (document.uploadedDocumentId) return false;

        const signableDocument = this.report.signableDocuments.find(
            (signature) =>
                signature.documentType === document.type &&
                signature.customDocumentOrderConfigId === document.customDocumentOrderConfigId,
        );

        if (!signableDocument) {
            this.toastService.error(
                'Unterschriften-Dokument fehlt',
                `Für das Dokument (${translateDocumentType(
                    document.type,
                )}) fehlt das DocumentMetadata-Objekt. Das ist ein technischer Fehler. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
            );
            return;
        }

        // If no signatures have been initialized on a signable document, the signatures must be missing. The case "signable document doesn't require a signature" is very unlikely to happen.
        if (!signableDocument.signatures.length) return true;

        const areSomeSignaturesMissing = signableDocument.signatures.some((signature) => !signature.hash);
        // A custom declaration of assignment does not require a signature.
        return areSomeSignaturesMissing && !document.uploadedDocumentId;
    }

    //*****************************************************************************
    //  Document File Upload
    //****************************************************************************/
    // Event handler which listens to the mousein and mouseout event if the user drags a file
    public onFileOverDropZone(fileOver: boolean, documentHoveredByFile: DocumentMetadata): void {
        // If the user's mouse and the dragged file left the dropzone, hide the dropzone.
        if (!fileOver) {
            this.mouseoutFileuploadHover = setTimeout(() => {
                this.documentHoveredByFile = null;
            }, 500);
        } else {
            clearTimeout(this.mouseoutFileuploadHover);
            this.documentHoveredByFile = documentHoveredByFile;
            // When the upload drop zone for existing documents is hovered, mark the drop zone for a new document as not hovered.
            this.fileOverNewDocumentDropZone = false;
            this.onFileOverBody();
        }
    }

    public onFileOverNewDocumentDropZone(fileOver: boolean): void {
        // If the user's mouse and the dragged file left the dropzone, hide the dropzone.
        if (!fileOver) {
            this.mouseoutNewDocumentHover = setTimeout(() => {
                this.fileOverNewDocumentDropZone = false;
            }, 500);
        } else {
            clearTimeout(this.mouseoutNewDocumentHover);
            this.fileOverNewDocumentDropZone = true;
            // When the new document drop zone is hovered, mark the drop zone to upload existing documents as not hovered.
            this.documentHoveredByFile = null;
            this.onFileOverBody();
        }
    }

    /**
     * Handle ng2FileSelect input upload.
     * Separate this call since typecasting is not possible in the template and 'EventTarget' has no property 'files'
     */
    public async handleFileUploadEvent(event: Event, document: DocumentMetadata) {
        // Ensure that the (change) output fires when the same document is selected again by clearing the input value.
        (event as HTMLInputEvent).target.value = '';
        this.onFileDrop((event as HTMLInputEvent).target.files, document);
    }

    /**
     * Uploads a document:
     *  - If dropped on an existing document, replaces the document.
     *  - Else creates a new document.
     *  Allows only one pdf file at the time, needs to be online.
     */
    public async onFileDrop(fileList, document: DocumentMetadata): Promise<void> {
        // Hide the drop zone as soon as content is dropped
        this.documentHoveredByFile = null;
        this.fileOverNewDocumentDropZone = false;
        this.fileIsOverBody = false;

        if (fileList.length > 1) {
            this.toastService.error(
                'Nur eine Datei erlaubt',
                'Nur eine Datei kann pro Dokument-Typ hochgeladen werden.',
            );
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Dokumente können hochgeladen werden, sobald du wieder online bist.',
            );
            return;
        }

        // Validate queue
        for (const item of this.uploader.queue) {
            // Of course, we don't have to update the same document multiple times.
            if (item.isReady || item.isUploading || item.isUploaded) {
                continue;
            }

            // If the mime type is no PDF, remove the document from the queue
            if (!item._file.type.includes('pdf')) {
                console.error('The given file is not a PDF.', item);
                this.toastService.error('Nur PDF erlaubt', 'Bitte lade eine PDF-Datei hoch.');
                this.uploader.queue.splice(this.uploader.queue.indexOf(item), 1);
                return;
            }

            /**
             * We handle two different cases:
             * 1. Drop on an existing document replaces the existing document.
             *  - If it is a manually uploaded pdf, the existing file it will be deleted.
             *  - The new file is uploaded with a new id and linked to the existing document.
             * 2. The document is new:
             *  - We get the title from the file name.
             *  - We create a new document.
             *  - We create a new full document part for this report.
             */
            const isNewDocument = document === null;

            // Generate a document ID for each new uploaded document
            const uploadedDocumentId = generateId();

            /**
             * Overwrite an existing document by removing its uploaded document file before uploading the new one.
             *
             * Do not remove the uploaded file of a permanent user uploaded document.
             * This is a safety measure to prevent the user from accidentally deleting a permanent document.
             * With the current implementation, the following edge case is not covered.
             * But would be complex to avoid since we cannot tell if a permanent uploaded document is/was used in another report.
             * Even if the document id is not in the teams permanent uploaded documents, the document could have been a user uploaded document before and still be used in another report.
             * 1. User uploads a manually uploaded pdf for a permanent user uploaded document
             * 2. User uploads another manually uploaded pdf for the same permanent user uploaded document
             * -> The first manually uploaded pdf is not deleted from the server.
             * To improve this, we would need to keep track about the users documents in the database. This may be added in the future to improve storage handling.
             */
            if (!isNewDocument && document.uploadedDocumentId && !document.permanentUserUploadedDocument) {
                await this.removeUploadedDocumentFromServer(document);
            }

            if (isNewDocument) {
                // Extract the basename (file name without file extension)
                const basename = item._file.name.substr(0, item._file.name.lastIndexOf('.'));
                document = new DocumentMetadata({
                    type: 'manuallyUploadedPdf',
                    title: basename,
                    uploadedDocumentId: uploadedDocumentId,
                    createdBy: this.user._id,
                });
                addDocumentToReport(
                    {
                        report: this.report,
                        team: this.team,
                        documentGroup: this.selectedDocumentGroup,
                        newDocument: document,
                    },
                    {
                        includedInFullDocument: !!this.userPreferences.activateDocumentsAfterUpload,
                        // Type manuallyUploadedPdf is allowed multiple times
                        allowMultiple: true,
                    },
                );
                // Refresh the view to reflect the new document.
                this.filterDocuments();
            } else {
                document.uploadedDocumentId = uploadedDocumentId;
            }

            // Show a loading icon next to the document name.
            this.pendingDocumentUploads.set(document, true);

            // Save the report to the server before uploading the binary files. Our server is set to block uploads whose metadata doesn't exist on the report.
            try {
                await this.reportDetailsService.patch(this.report, { waitForServer: true });
            } catch (error) {
                console.error('Uploading ' + document?.title + ' failed.');
                this.pendingDocumentUploads.delete(document);
            }

            // Upload binary files.
            await this.upload(item, document);

            this.pendingDocumentUploads.delete(document);
            this.filterDocuments();
        }
    }

    /**
     * Triggered when a PDF file is dropped or the file upload button is clicked.
     * Parameter is a native fileList of the dropped files.
     */
    public async upload(item: FileItem, document: DocumentMetadata): Promise<any> {
        const formData = new FormData();
        formData.append('uploadedDocumentId', document.uploadedDocumentId);
        formData.append('document', item._file);

        item.isUploading = true;

        try {
            await this.httpClient
                .post<any>(AXRESTClient.marryToBaseUrl(`/reports/${this.reportId}/documents/userUploads`), formData)
                .pipe(
                    httpRetry({
                        delayMs: 2000,
                    }),
                )
                .toPromise();
        } catch (error) {
            this.uploader.queue.splice(this.uploader.queue.indexOf(item));
            item.isUploading = false;
            this.pendingDocumentUploads.delete(document);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Upload fehlgeschlagen',
                    body: `Die Datei für das Dokument "${document.title}" konnte nicht hochgeladen werden. Bitte versuche es erneut.<br><br>Bleibt das Problem bestehen, wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        }

        this.tutorialStateService.markUserTutorialStepComplete('customPdfUploadedToReport');
        this.uploader.queue.splice(this.uploader.queue.indexOf(item));
    }

    private initializeUploader(): void {
        // Enable file upload when dragging & dropping files on the individual documents
        this.uploader = new FileUploader({
            url: AXRESTClient.marryToBaseUrl(`/reports/${this.reportId}/documents/userUploads`),
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
        });
    }

    /**
     * Show drop zone
     */
    @HostListener('body:dragover', ['$event'])
    public onFileOverBody() {
        clearTimeout(this.fileOverBodyTimeoutCache);

        this.fileIsOverBody = true;

        this.fileOverBodyTimeoutCache = window.setTimeout(() => {
            this.fileIsOverBody = false;
        }, 500);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document File Upload
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Portfolio
    //****************************************************************************/
    public addPhotoPortfolioDocument(): void {
        const documentType: DocumentType = 'photoPortfolio';
        const newDocument = new DocumentMetadata({
            type: documentType,
            title: translateDocumentType(documentType),
            createdBy: this.user._id,
        });

        addDocumentToReport({
            report: this.report,
            team: this.team,
            documentGroup: this.selectedDocumentGroup,
            newDocument,
        });
        this.saveReport();

        // Ensure the new document is shown in the list.
        this.filterDocuments();
    }

    public doesPhotoPortfolioExist(): boolean {
        /**
         * The photo portfolio may only exist in the report document group, not in the repair confirmation because the repair confirmation is such a plain document
         * that assessors will never need to add a photo portfolio for that. Also, the document building block "Fotoanlage" always shows the photo from the report
         * group, not from the repair confirmation.
         */
        return !!this.report.documents.find((documentMetadata) => documentMetadata.type === 'photoPortfolio');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Portfolio
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handling Documents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Adelta Finanz Button
    //****************************************************************************/
    public isAdeltafinanzExportAllowed(): boolean {
        if (this.selectedDocumentGroup === 'report') {
            return (
                this.report.state === 'done' &&
                !this.report.invoiceExportedToAdeltafinanz &&
                this.isFactoringEnabled() &&
                this.areAdeltafinanzCredentialsComplete()
            );
        }
        if (this.selectedDocumentGroup === 'repairConfirmation') {
            return false;
        }
    }

    private isFactoringEnabled(): boolean {
        if (this.selectedDocumentGroup === 'repairConfirmation') {
            return this.report.repairConfirmation.invoiceParameters.factoringEnabled;
        } else {
            return this.report.feeCalculation.invoiceParameters.factoringEnabled;
        }
    }

    public areAdeltafinanzCredentialsComplete(): boolean {
        return !!(
            this.user.adeltaFinanzUser &&
            this.user.adeltaFinanzUser.customerNumber &&
            this.user.adeltaFinanzUser.password &&
            this.user.adeltaFinanzUser.username
        );
    }

    public getTooltipForAdeltafinanzButton(): string {
        if (this.selectedDocumentGroup === 'report') {
            if (this.report.invoiceExportedToAdeltafinanz) {
                return 'Die Rechnung wurde bereits zu ADELTA.FINANZ exportiert.';
            }
        }

        if (this.selectedDocumentGroup === 'repairConfirmation') {
            return 'Rechnungen für Reparaturbestätigungen können nicht zu ADELTA.FINANZ exportiert werden. Bitte nutze das Web-Interface von ADELTA.';
        }

        if (!this.areAdeltafinanzCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den Einstellungen eingegeben werden.';
        }
        if (!this.isFactoringEnabled()) {
            let screenWithFactoringEnablement = '';
            switch (this.selectedDocumentGroup) {
                case 'report':
                    screenWithFactoringEnablement = 'Unfall & Beteiligte';
                    break;
            }
            return `Das Factoring muss für diese Rechnung aktiviert werden. Siehe Screen ${screenWithFactoringEnablement}.`;
        }
        if (this.selectedDocumentGroup === 'report' && this.report.state !== 'done') {
            return 'Das Gutachten muss erst abgeschlossen werden.';
        }
        return 'Rechnung zu ADELTA.FINANZ exportieren';
    }

    public exportInvoiceToAdeltaFinanz(): void {
        this.adeltaFinanzExportPending = true;
        this.toastService.info('Exportiere zu ADELTA.FINANZ...', 'Dies kann einige Sekunden dauern');

        // Remember the value at the time of triggering the request. If the value changes until the request comes back, the callback will be messed with.
        const documentGroup = this.selectedDocumentGroup;

        this.httpClient
            .post<
                { invoice: Invoice } | AxError
            >('/api/v0/reports/' + this.report._id + `/adeltafinanz/invoices?documentGroup=${documentGroup}`, {})
            .subscribe({
                next: (response) => {
                    // If the response is an error
                    if ((response as AxError).code) {
                        if ((response as AxError).code === 'ADELTA_FINANZ_BUSINESS_DEBTOR_VERIFICATION_IN_PROGRESS') {
                            this.toastService.info(
                                'Debitor in Prüfung',
                                'ADELTA.FINANZ prüft den neuen gewerblichen Rechnungsempfänger. Diese Prüfung dauert laut ADELTA.FINANZ zwischen einer und 48 Stunden. Bitte exportiere die Rechnung nach spätestens 48 h erneut.',
                            );
                        }
                    } else {
                        this.toastService.success(
                            'Export erfolgreich',
                            'Deine Rechnung wurde erfolgreich an ADELTA.FINANZ übermittelt.',
                        );

                        switch (documentGroup) {
                            case 'report':
                                this.report.invoiceExportedToAdeltafinanz = true;
                                break;
                        }
                        this.saveReport();
                    }
                    this.adeltaFinanzExportPending = false;
                },
                error: (error) => {
                    this.adeltaFinanzExportPending = false;
                    console.error('An error occurred when exporting the invoice to ADELTA.FINANZ', { error });
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            // Common errors
                            ADELTA_FINANZ_INVOICE_EXISTS: {
                                title: 'Rechnungsnummer bereits vergeben',
                                body: 'Eine Rechnung mit dieser Nummer wurde bereits zu ADELTA.FINANZ exportiert.',
                            },
                            ADELTA_FINANZ_PROCESS_ALREADY_CONTAINS_INVOICE: {
                                title: 'Vorgang hat bereits Rechnung',
                                body: 'Dieser ADELTA-Vorgang hat bereits eine Rechnung. Pro Vorgang kann nur eine Rechnung übermittelt werden. Bitte prüfe den Vorgang in deinem ADELTA-Web-Portal.',
                            },
                            ADELTA_FINANZ_ADDRESS_PARAMETER_ERROR: {
                                title: 'Adressdaten abgelehnt',
                                body: 'Die Adresse konnte nicht gefunden werden. Bitte prüfe die Adressdaten des Rechnungsempfängers (Anpsruchsteller/Fahrzeughalter des geschädigten Fahrzeugs).',
                            },
                            ADELTA_FINANZ_INVALID_PAYMENT_TERMS_IN_DAYS: {
                                title: 'Ungültiges Zahlungsziel',
                                body: 'ADELTA.FINANZ unterstützt nur 30 oder 60 Tage Zahlungsziel. Wenn du zu ADELTA.FINANZ exportieren möchtest, passe bitte das Zahlungsziel der Rechnung an.',
                            },
                            ADELTA_FINANZ_INVALID_PAYMENT_DISCOUNT: {
                                title: 'Ungültiges Skonto',
                                body: 'ADELTA.FINANZ unterstützt nur 1% und/oder 2% Skonto. Wenn du zu ADELTA.FINANZ exportieren möchtest, reduziere bitte das Skonto auf diese Werte.',
                            },
                            ADELTA_FINANZ_NOT_AUTHENTICATED: {
                                title: 'Ungültige Zugangsdaten',
                                body: 'Die Zugangsdaten zu ADELTA.FINANZ wurden abgelehnt. Bitte überprüfe deine ADELTA.FINANZ-Zugangsdaten in den Einstellungen.',
                            },
                            INVALID_INSURANCE_ADDRESS: {
                                title: 'Ungültige Versicherungs-Adresse',
                                body: 'Die Adresse der Versicherung ist ungültig und kann deshalb nicht von ADELTA.FINANZ übernommen werden. Bitte prüfe die Adresse.',
                            },
                            INVALID_LAWYER_OF_CLAIMANT_ADDRESS: {
                                title: 'Anwalts-Adresse ungültig',
                                body: 'Die Adresse des Anwalts des Anspruchstellers ist ungültig und kann deshalb nicht von ADELTA.FINANZ übernommen werden. Bitte prüfe die Adresse des Anwalts.',
                            },
                            INVALID_EXPERT_ADDRESS: {
                                title: 'Gutachter-Adresse ungültig',
                                body: 'Die Adresse des Gutachters (deine Adresse) ist ungültig und kann deshalb nicht von ADELTA.FINANZ übernommen werden. Bitte prüfe deine Adresse in den Einstellungen.',
                            },

                            // Less common errors
                            ADELTA_FINANZ_INVALID_CUSTOMER_NUMER: {
                                title: 'Ungültige Kundennummer',
                                body: 'Die autoiXpert Kundennummer wurde von ADELTA.FINANZ nicht akzeptiert. Bitte kontaktiere die Hotline.',
                            },
                            ADELTA_FINANZ_OTHER_LIMIT_REQUEST_ALREADY_IN_PROGRESS: (error) => ({
                                title: 'Limit-Anfrage läuft bereits',
                                body: `Bitte warte ab, bis ADELTA.FINANZ diese beantwortet hat oder kontaktiere ADELTA.FINANZ direkt.<br>Aktuelle Höhe: ${error.data.askedLimitInProgress} EUR`,
                            }),
                            ADELTA_FINANZ_CUSTOMER_IN_REVIEW: {
                                title: 'Debitor in Prüfung',
                                body: 'Der gewerbliche Debitor befindet sich noch in Prüfung durch ADELTA.FINANZ. Bitte versuche es später noch einmal.',
                            },
                            ADELTA_FINANZ_DEBTOR_NOT_FOUND: {
                                title: 'Debitor nicht gefunden',
                                body: 'Der Debitor konnte bei ADELTA.FINANZ nicht gefunden werden. Bitte wende dich an die Hotline.',
                            },
                            ADELTA_FINANZ_DEBTOR_NUMBER_EXISTS_ALREADY: {
                                title: 'Debitor existiert bereits',
                                body: 'Es wurde versucht, einen Debitor anzulegen, den ADELTA.FINANZ schon kennt. Bitte die Hotline kontaktieren.',
                            },
                            ADELTA_FINANZ_DEBTOR_EXISTS_ALREADY: {
                                title: 'Debitor existiert bereits',
                                body: 'Es wurde versucht, einen Debitor anzulegen, den ADELTA.FINANZ schon kennt. Bitte die Hotline kontaktieren.',
                            },
                            INVALID_ADDRESS: (error) => ({
                                title: 'Ungültige Adresse',
                                body: `Die Adresse "${error.data.invalidAddress}" ist ungültig. Bitte prüfe alle eingegebenen Adressen.`,
                            }),
                            ADELTA_FINANZ_WRONG_PROCESS_ID_FORMAT: {
                                title: 'Ungültige ADELTA-Prozess-ID',
                                body: `Bitte kontaktiere die Hotline.`,
                            },
                        },
                        defaultHandler: {
                            title: 'Übertragung fehlgeschlagen',
                            body: 'Die Rechnung konnte nicht an ADELTA.FINANZ übermittelt werden.',
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Adelta Finanz Button
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  KfzVS Button
    //****************************************************************************/
    public isKfzvsExportAllowed(): boolean {
        if (this.kfzvsExportPending) return false;

        if (this.selectedDocumentGroup === 'report') {
            return this.report.state === 'done' && this.isFactoringEnabled() && this.areKfzvsCredentialsComplete();
        }
        if (this.selectedDocumentGroup === 'repairConfirmation') {
            const repairConfirmation = this.report.repairConfirmation;
            return (
                repairConfirmation.invoiceParameters._id &&
                repairConfirmation.invoiceParameters.factoringEnabled &&
                this.areKfzvsCredentialsComplete()
            );
        }
    }

    private areKfzvsCredentialsComplete(): boolean {
        return this.user.kfzvsUser && !!this.user.kfzvsUser.customerNumber;
    }

    public getTooltipForKfzvsButton(): string {
        if (this.report.invoiceExportedToKfzvs) {
            return 'Die Rechnung wurde bereits zu KfzVS exportiert.';
        }
        if (!this.areKfzvsCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den Einstellungen eingegeben werden.';
        }
        if (!this.isFactoringEnabled()) {
            let screenWithFactoringEnablement = '';
            switch (this.selectedDocumentGroup) {
                case 'report':
                    screenWithFactoringEnablement = 'Unfall & Beteiligte';
                    break;
                case 'repairConfirmation':
                    screenWithFactoringEnablement = 'Reparaturbestätigung';
                    break;
            }
            return `Das Factoring muss für diese Rechnung aktiviert werden. Siehe Screen ${screenWithFactoringEnablement}.`;
        }
        if (
            this.selectedDocumentGroup === 'repairConfirmation' &&
            !this.report.repairConfirmation.invoiceParameters._id
        ) {
            return 'Die Reparaturbestätigung muss erst abgeschlossen werden.';
        }
        if (this.selectedDocumentGroup === 'report' && this.report.state !== 'done') {
            return 'Das Gutachten muss erst abgeschlossen werden.';
        }

        return 'Rechnung zu KfzVS exportieren';
    }

    public exportInvoiceToKfzvs(): void {
        if (!this.isKfzvsExportAllowed()) {
            this.toastService.info('Export nicht möglich', this.getTooltipForKfzvsButton());
            return;
        }

        if (this.report.invoiceExportedToKfzvs) {
            this.dialog
                .open(ConfirmDialogComponent, {
                    data: {
                        heading: 'Erneut zu KfzVS exportieren',
                        content:
                            'Möglicherweise solltest du KfzVS kontaktieren, um die bisher übertragene Rechnung entfernen zu lassen.',
                        confirmLabel: 'Beam it up, Scotty!',
                        cancelLabel: 'Lieber nicht',
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result) {
                        this.report.invoiceExportedToKfzvs = false;
                        this.exportInvoiceToKfzvs();
                    }
                });
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der Export zu KfzVS ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.kfzvsExportPending = true;
        this.toastService.info('Exportiere zu KfzVS...', 'Dies kann einige Sekunden dauern');

        this.httpClient
            .post(
                '/api/v0/reports/' + this.report._id + `/kfzvs/invoices?documentGroup=${this.selectedDocumentGroup}`,
                {},
            )
            .subscribe({
                next: () => {
                    this.toastService.success(
                        'Export erfolgreich',
                        'Deine Rechnung wurde erfolgreich an KfzVS übermittelt.',
                    );

                    this.report.invoiceExportedToKfzvs = true;
                    this.saveReport();
                    this.kfzvsExportPending = false;
                },
                error: (error) => {
                    this.kfzvsExportPending = false;
                    console.error('An error occurred when exporting the invoice to KfzVS', { error });
                    // noinspection FallThroughInSwitchStatementJS
                    switch (error.code) {
                        // Common errors
                        case 'MISSING_PARAMETER_INVOICE_NUMBER':
                            this.toastService.error(
                                'Rechnung nicht gefunden',
                                'Das Gutachten ist mit keiner Rechnung verknüpft. Bitte öffne das Gutachten und schließe es erneut ab.',
                            );
                            break;
                        case 'MISSING_KFZVS_USER':
                            this.toastService.error(
                                'KfzVS-User fehlt',
                                'Die KfzVS-Kundennummer kann nicht gefunden werden. Bitte trage die Kundennummer nach.',
                            );
                            break;
                        case 'INVOICE_NOT_FOUND':
                            this.toastService.error(
                                'Rechnung nicht gefunden',
                                'Die Rechnung zu diesem Gutachten konnte nicht gefunden werden. Bitte öffne das Gutachten und schließe es erneut ab.',
                            );
                            break;
                        case 'MISSING_INVOICE_DATE':
                            this.toastService.error('Rechnungsdatum fehlt', 'Bitte definiere ein Rechnungsdatum.');
                            break;
                        case 'SENDING_KFZVS_EMAIL_FAILED':
                            this.toastService.error(
                                'E-Mail nicht gesendet',
                                'Die E-Mail mit der Rechnung konnte nicht an KfzVS gesendet werden. Bitte prüfe deine E-Mail-Zugangsdaten in den Einstellungen.',
                            );
                            break;
                        case 'DOCUMENT_NOT_FOUND_ON_REPORT':
                            if (error.data.documentMetadataType === 'declarationOfAssignment') {
                                this.toastService.error(
                                    'Dokument fehlt',
                                    `Das Dokument "Abtretung" fehlt. Bitte füge es im Reiter Unfall & Beteiligte im Abschnitt <i>Unterschriften</i> hinzu.`,
                                );
                                break;
                            }
                        // Don't break unless a toast has been thrown.
                        // break;
                        default:
                            this.toastService.error(
                                'Übertragung fehlgeschlagen',
                                'Die Rechnung konnte nicht an KfzVS übermittelt werden.',
                            );
                    }
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END KfzVS Button
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Persaldo Button
    //****************************************************************************/
    public isPersaldoExportAllowed(): boolean {
        if (this.persaldoExportPending) return false;

        return (
            this.selectedDocumentGroup === 'report' &&
            this.report.state === 'done' &&
            this.isFactoringEnabled() &&
            this.arePersaldoCredentialsComplete() &&
            this.areRequiredDocumentsActive()
        );
    }

    public arePersaldoCredentialsComplete(): boolean {
        return !!(this.user.persaldoUser?.username && this.user.persaldoUser?.password);
    }

    public areRequiredDocumentsActive(): boolean {
        const activeDocuments: DocumentMetadata[] = this.getActiveDocuments();

        return (
            activeDocuments.some((documentMetadata) => documentMetadata.type === 'invoice') &&
            activeDocuments.some((documentMetadata) => documentMetadata.type === 'report') &&
            activeDocuments.some((documentMetadata) => documentMetadata.type === 'declarationOfAssignment')
        );
    }

    public getTooltipForPersaldoButton(): string {
        if (!this.arePersaldoCredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den Einstellungen eingegeben werden.';
        }
        if (!this.isFactoringEnabled()) {
            let screenWithFactoringEnablement = '';
            switch (this.selectedDocumentGroup) {
                case 'report':
                    screenWithFactoringEnablement = 'Unfall & Beteiligte';
                    break;
                case 'repairConfirmation':
                    screenWithFactoringEnablement = 'Reparaturbestätigung';
                    break;
            }
            return `Das Factoring muss für diese Rechnung aktiviert werden. Siehe Screen ${screenWithFactoringEnablement}.`;
        }
        // if (this.selectedDocumentGroup === 'repairConfirmation' && !this.report.repairConfirmation.invoiceParameters._id) {
        //     return 'Die Reparaturbestätigung muss erst abgeschlossen werden.';
        // }
        if (this.selectedDocumentGroup === 'report' && this.report.state !== 'done') {
            return 'Das Gutachten muss erst abgeschlossen werden.';
        }
        if (this.report.persaldoCaseNumber) {
            return `Die Rechnung wurde unter der Fallnummer ${this.report.persaldoCaseNumber} zu Goya Mobility exportiert. Du kannst sie erneut exportieren, aber nur unter veränderter Rechnungsnummer.`;
        }
        if (!this.areRequiredDocumentsActive()) {
            return `Die Dokumente "Gutachten", "Rechnung" und "Abtretung" müssen für den Export mindestens aktiv sein.`;
        }

        return 'Alle aktiven Dokumente zu Goya Mobility exportieren';
    }

    public exportInvoiceToPersaldo(): void {
        if (!this.isPersaldoExportAllowed()) {
            this.toastService.info('Export nicht möglich', this.getTooltipForPersaldoButton());
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der Export zu Persaldo ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.persaldoExportPending = true;
        this.toastService.info('Exportiere zu Goya Mobility...', 'Dies kann einige Sekunden dauern');

        this.persaldoService.exportInvoice(this.report, this.selectedDocumentGroup).subscribe({
            next: ({ persaldoCaseNumber }) => {
                const persaldoBaseUrl = environment.production
                    ? 'https://factoringtech.io'
                    : 'https://test.factoringtech.io';
                this.toastService.success(
                    'Export erfolgreich',
                    `Deine Rechnung wurde erfolgreich an Goya Mobility übermittelt.\n\nFalls du externe Belege abrechnest, <a href='${persaldoBaseUrl}/Case/Edit/${persaldoCaseNumber}' target='_blank' rel='noopener'>lade sie bitte manuell hoch</a>.`,
                    { timeOut: 6000 },
                );

                this.report.persaldoCaseNumber = persaldoCaseNumber;
                this.saveReport();
                this.persaldoExportPending = false;
            },
            error: (error) => {
                this.persaldoExportPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getPersaldoErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'Goya-Mobility-Export fehlgeschlagen',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Persaldo Button
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  crashback24 Button
    //****************************************************************************/
    public isCrashback24ExportAllowed({
        forceReexport,
    }: {
        forceReexport?: boolean;
    } = {}): boolean {
        if (this.crashback24ExportPending) return false;

        return (
            this.areCrashback24CredentialsComplete() &&
            (!this.report.crashback24ProcessId || forceReexport) &&
            this.isCrashback24Lawyer()
        );
    }

    public areCrashback24CredentialsComplete(): boolean {
        return !!(this.user.crashback24User?.username && this.user.crashback24User?.password);
    }

    public isCrashback24Lawyer(): boolean {
        return (
            this.report.claimant.representedByLawyer &&
            this.report.lawyer.contactPerson.organization === 'crashback24 GmbH'
        );
    }

    public getTooltipForCrashback24Button(): string {
        // Relevant if the credentials used to be complete but are not complete anymore.
        if (!this.areCrashback24CredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den Einstellungen eingegeben werden.';
        }

        if (!this.areCrashback24CredentialsComplete()) {
            return 'Die Zugangsdaten müssen erst in den Einstellungen eingegeben werden.';
        }

        if (!this.isCrashback24Lawyer()) {
            return '"crashback24 GmbH" muss als Organisation des Anwalts eingetragen sein. Nutze dafür gerne die Adresse, die autoiXpert anlegt, wenn du deine crashback24-Zugangsdaten eingibst.';
        }

        if (this.report.crashback24ProcessId) {
            return `Der Vorgang wurde unter der ID ${this.report.crashback24ProcessId} zu crashback24 exportiert. Du kannst Änderungen nur über die crashback24 Oberfläche durchführen.\n\nEin Klick öffnet die Oberfläche. Umschalt ⇧ + Klick exportiert den Vorgang erneut.`;
        }

        return 'Vorgang zu crashback24 exportieren';
    }

    private createCrashback24Process({
        forceReexport,
    }: {
        forceReexport?: boolean;
    } = {}): void {
        if (!this.isCrashback24ExportAllowed({ forceReexport })) {
            this.toastService.info('Export nicht möglich', this.getTooltipForCrashback24Button());
            return;
        }

        this.crashback24ExportPending = true;
        this.toastService.info('Exportiere zu crashback24...', 'Dies kann einige Sekunden dauern');

        this.crashback24Service.createProcess(this.report).subscribe({
            next: ({ crashback24ProcessId }) => {
                const crashback24Url = environment.production
                    ? 'https://crashback24.42dbs.de/'
                    : 'https://test-crashback.42dbs.de/';
                this.toastService.success(
                    'Export erfolgreich',
                    `Dein Vorgang wurde erfolgreich an crashback24 übermittelt.\n\nErgänze die Daten, die nicht in autoiXpert abgefragt werden, über <a href='${crashback24Url}' target='_blank' rel='noopener'>die crashback24-Oberfläche</a>.`,
                    { timeOut: 6000 },
                );

                this.report.crashback24ProcessId = crashback24ProcessId;
                this.saveReport();
                this.crashback24ExportPending = false;
            },
            error: (error) => {
                this.crashback24ExportPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getCrashback24ErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'crashback24-Export fehlgeschlagen',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    private openCrashback24Process() {
        const crashback24Url = environment.production
            ? 'https://crashback24.42dbs.de/'
            : 'https://test-crashback.42dbs.de/';
        window.open(crashback24Url, '_blank', 'noopener');
    }

    public handleCrashback24Click($event: MouseEvent) {
        // If the user holds shift while clicking, export the process again even if there is a process ID already.
        if ($event.shiftKey) {
            $event.preventDefault();
            this.createCrashback24Process({ forceReexport: true });
        } else if (this.report.crashback24ProcessId) {
            // If there is a process ID, open the process (or rather the crashback24 login because they don't support accessing the process directly)
            this.openCrashback24Process();
        } else {
            this.createCrashback24Process();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END crashback24 Button
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save to backend
    //****************************************************************************/

    public async saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        return this.reportDetailsService.saveReport(this.report, { waitForServer });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save to backend
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Message Templates
    //****************************************************************************/
    public showMessageTemplateSelector(): void {
        this.messageTemplateSelectorShown = true;
    }

    public hideMessageTemplateSelector(): void {
        this.messageTemplateSelectorShown = false;
    }

    //*****************************************************************************
    //  Default Letter & Email Templates
    //****************************************************************************/
    public insertTemplateText(textTemplate: TextTemplate): void {
        this.activeCoverLetter.subject = textTemplate.subject;
        this.activeCoverLetter.body = textTemplate.body;
        this.replaceMissingPlaceholders();

        this.saveReport();
    }

    private async insertDefaultLetterTemplate(): Promise<any> {
        // Abort if the report is from type that wouldn't make sense for the default liability template.
        const allowedReportTypes: Report['type'][] = ['liability', 'partialKasko', 'fullKasko'];
        if (!allowedReportTypes.includes(this.report.type)) return;

        const coverLetterDefaultTemplateId =
            this.userPreferences.defaultCoverLetterTemplates[this.selectedDocumentGroup]?.[this.selectedRecipientRole];
        // Abort if the default text template was deleted in the meantime.
        if (!coverLetterDefaultTemplateId) {
            return;
        }

        // Only insert default if target message is empty
        if (!this.activeCoverLetter.subject && !this.activeCoverLetter.body) {
            let textTemplate: TextTemplate;

            try {
                textTemplate = await this.findMessageTemplateById(coverLetterDefaultTemplateId, 'coverLetter');
            } catch (error) {
                this.toastService.error(
                    'Abfragen der Standard-Anschreiben-Vorlage fehlgeschlagen',
                    'Bitte aktualisiere die Seite und versuche es erneut. Sollte das Problem weiterhin bestehen, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
                return;
            }
            if (!textTemplate) {
                this.toastService.error(
                    'Standard-Anschreiben-Vorlage nicht gefunden',
                    'Bitte wähle eine andere Standard-Anschreiben-Vorlage über die Vorlagenverwaltung.',
                );
                return;
            }

            const placeholderValues: PlaceholderValues = await this.templatePlaceholderValuesService.getReportValues({
                reportId: this.report?._id,
                letterDocument: this.activeCoverLetter,
            });
            const fieldGroupConfigs =
                await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

            this.activeCoverLetter.subject = replacePlaceholders({
                textWithPlaceholders: textTemplate.subject,
                placeholderValues,
                fieldGroupConfigs,
                isHtmlAllowed: false,
            });
            this.activeCoverLetter.body = replacePlaceholders({
                textWithPlaceholders: textTemplate.body,
                placeholderValues,
                fieldGroupConfigs,
            });

            // Wait until report was saved, because we might later modify the email body
            // to insert the signature. Without await, the signature gets overwritten.
            await this.saveReport();
        }
    }

    /**
     * Inserts the default email template.
     *
     * @return True if a default template was inserted, false otherwise.
     */
    private async insertDefaultEmailTemplate(): Promise<boolean> {
        // Abort if the report is from type that wouldn't make sense for the default liability template.
        const allowedReportTypes: Report['type'][] = ['liability', 'partialKasko', 'fullKasko'];
        if (!allowedReportTypes.includes(this.report.type)) return;

        // Shorthand
        const activeEmail = this.selectedRecipient.outgoingMessageDraft[this.selectedDocumentGroup];
        const emailDefaultTemplateId =
            this.userPreferences.defaultEmailTemplates[this.selectedDocumentGroup]?.[this.selectedRecipientRole];

        // Abort if the default text template was deleted in the meantime.
        if (!emailDefaultTemplateId) {
            return false;
        }

        // Only insert default if target message is empty
        if (!activeEmail.subject && !activeEmail.body) {
            let textTemplate: TextTemplate;

            try {
                textTemplate = await this.findMessageTemplateById(emailDefaultTemplateId, 'email');
            } catch (error) {
                this.toastService.error(
                    'Abfragen der Standard-E-Mail-Vorlage fehlgeschlagen',
                    'Bitte aktualisiere die Seite und versuche es erneut. Sollte das Problem weiterhin bestehen, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
                return;
            }
            if (!textTemplate) {
                this.toastService.error(
                    'Standard-E-Mail-Vorlage nicht gefunden',
                    'Bitte wähle eine andere Standard-E-Mail-Vorlage über die Vorlagenverwaltung.',
                );
                return;
            }
            const placeholderValues: PlaceholderValues = await this.templatePlaceholderValuesService.getReportValues({
                reportId: this.report?._id,
                letterDocument: this.activeCoverLetter,
            });
            const fieldGroupConfigs =
                await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

            activeEmail.subject = replacePlaceholders({
                textWithPlaceholders: textTemplate.subject,
                placeholderValues,
                fieldGroupConfigs,
                isHtmlAllowed: false,
            });
            activeEmail.body = replacePlaceholders({
                textWithPlaceholders: textTemplate.body,
                placeholderValues,
                fieldGroupConfigs,
            });

            this.saveReport();

            return true;
        } else {
            return false;
        }
    }

    /**
     * The email signature will be inserted if the user selected a default template that will be inserted when the component
     * is being initialized.
     */
    private async insertEmailSignature(): Promise<void> {
        let emailSignatures: EmailSignature[] = this.emailSignatureService.getAllFromCurrentInMemoryCache();
        /**
         * If there are no email signatures in the memory cache, query the server.
         * The server is not queried immediately to increase performance. Email signatures are inserted very often
         * but changed rarely, so a good in-memory cache increases speed.
         * The server is queried at all to ensure the user can still insert email signatures even if the app has not fully synced
         * (and populated the in-memory cache) after the user refreshed the page.
         */
        if (!emailSignatures.length) {
            emailSignatures = await this.emailSignatureService.find({ createdBy: this.user._id }).toPromise();
        }
        // If there are no email signatures, don't try to insert one.
        if (!emailSignatures.length) {
            return;
        }

        const emailSignatureForThisUser = emailSignatures.find(
            (emailSignature) => emailSignature.createdBy === this.user._id,
        );
        if (emailSignatureForThisUser?.content) {
            const activeEmail = this.selectedRecipient.outgoingMessageDraft[this.selectedDocumentGroup];
            if (!activeEmail.body) {
                /**
                 * Gmail adds two line breaks before the signature so that the user can easily click in this space to position his cursor. Since there
                 * is another line break right before the signature, add one here if the field is empty.
                 */
                activeEmail.body = '<p><br class="ql-line-break"></p>';
            }
            activeEmail.body += `<p><br class="ql-line-break"></p>${emailSignatureForThisUser.content}`;
        }
        this.saveReport();
    }

    private async findMessageTemplateById(
        templateId: string,
        templateType: 'coverLetter' | 'email',
    ): Promise<TextTemplate> {
        const textTemplates: TextTemplate[] = await this.textTemplateService
            .find({
                type: templateType,
            })
            .toPromise();

        return textTemplates.find((textTemplate) => textTemplate._id === templateId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Message Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Missing Placeholders
    //****************************************************************************/
    /**
     * Checks for missing placeholders.
     */
    public determineMissingPlaceholders(): void {
        const subjectMatches = this.activeCoverLetter.subject
            ? extractMissingPlaceholdersFromText(this.activeCoverLetter.subject)
            : [];
        const bodyMatches = this.activeCoverLetter.body
            ? extractMissingPlaceholdersFromText(this.activeCoverLetter.body)
            : [];

        this.missingPlaceholdersInCoverLetters = [
            // If a placeholder is missing multiple times, only display once.
            ...new Set([...subjectMatches, ...bodyMatches]),
        ];
    }

    /**
     * Replaces all missing placeholders from the current active cover letter.
     */
    public async replaceMissingPlaceholders() {
        // Create the placeholder values only if there are missing placeholders
        this.determineMissingPlaceholders();
        if (!this.missingPlaceholdersInCoverLetters.length) return;

        const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.report?._id,
            letterDocument: this.activeCoverLetter,
        });
        const fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
        const placeholderValueTree = getPlaceholderValueTree({ fieldGroupConfigs });

        this.activeCoverLetter.subject = replaceMissingPlaceholders({
            placeholderValues,
            placeholderValueTree,
            text: this.activeCoverLetter.subject,
            isHtmlAllowed: false,
        });
        this.activeCoverLetter.body = replaceMissingPlaceholders({
            placeholderValues,
            placeholderValueTree,
            text: this.activeCoverLetter.body,
            isHtmlAllowed: true,
        });

        // If placeholders are still missing, display the warning.
        // The user may remove the remaining placeholders or add more information to the report and replace missing placeholders again.
        this.determineMissingPlaceholders();

        void this.saveReport();
    }

    /**
     * Removes all missing placeholders from the current active cover letter.
     */
    public removeMissingPlaceholders(): void {
        this.activeCoverLetter.subject = removeMissingPlaceholdersFromText(this.activeCoverLetter.subject);
        this.activeCoverLetter.body = removeMissingPlaceholdersFromHTML(this.activeCoverLetter.body);

        // Since we removed all missing placeholders, we don't need the warning anymore.
        this.missingPlaceholdersInCoverLetters = [];

        void this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Missing Placeholders
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Send E-Mail and Download Letter
    //****************************************************************************/
    public async sendEmail({
        email: sentEmailTemplate,
        schedule,
    }: {
        email: OutgoingEmailMessage;
        schedule?: OutgoingMessageSchedule;
    }) {
        this.emailTransmissionPending = true;

        /** Hold on the current values of recipient and document group at the
         * time the user started sending the email.
         * Otherwise, switching the value while transmission is in progress, would
         * assign the email to the wrong recipient/group.
         */
        const selectedRecipient = this.selectedRecipient;
        const documentGroup: DocumentGroupName = this.selectedDocumentGroup;

        /**
         * Hold on to this object if we must restore it later in case of a transmission error. This object contains the email object
         * with placeholders for recipients, no sentAt date and so on. The object "sentEmailTemplate" already contains all "rendered"
         * email recipients, a sentAt date etc., so that should not be persisted on the report in case of error.
         */
        const originalEmailWithRecipientPlaceholders: OutgoingEmailMessage =
            selectedRecipient.outgoingMessageDraft[documentGroup];

        // The email must be synced to the server before triggering the "send" API endpoint because the "send" API endpoint reads its contents from the report object.
        selectedRecipient.outgoingMessageDraft[documentGroup] = sentEmailTemplate;
        await this.saveReport({ waitForServer: true });

        // Always create a outgoing message with information about the email that was scheduled, sent or failed.
        const outgoingMessage = new OutgoingEmailMessage({
            type: 'document-email',
            source: 'report',
            documentGroup: this.selectedDocumentGroup,
            reportId: this.report?._id,
            recipientType: selectedRecipient.role,
            subject: sentEmailTemplate.subject,
            body: sentEmailTemplate.body,
            scheduledAt: schedule ? getOutgoingMessageScheduledAt(schedule)?.toISO() : undefined,
            attachedDocuments: sentEmailTemplate.attachedDocuments,
            isDatVxsAttached: sentEmailTemplate.isDatVxsAttached,
            areAttachmentsSeparated: sentEmailTemplate.areAttachmentsSeparated,
            multiplePdfAttachments: sentEmailTemplate.multiplePdfAttachments,
            isElectronicInvoiceEnabled: this.report.feeCalculation.invoiceParameters.isElectronicInvoiceEnabled,
            email: {
                toRecipients: sentEmailTemplate.email?.toRecipients,
                ccRecipients: sentEmailTemplate.email?.ccRecipients,
                bccRecipients: sentEmailTemplate.email?.bccRecipients,
            },
        });

        try {
            if (!schedule) {
                outgoingMessage.sentAt = moment().format();
            }

            await this.outgoingMessageService.create(outgoingMessage, { waitForServer: true });

            // Ensure that email sending works (e.g., generate documents), only dry run if scheduled
            await this.emailService.triggerReportEmail(outgoingMessage, { dryRun: !!schedule });

            if (!schedule) {
                outgoingMessage.deliveredAt = moment().format();

                // Only set the email as received if the email was sent successfully.
                selectedRecipient.receivedEmail = true;
            } else {
                outgoingMessage.scheduledAt = getOutgoingMessageScheduledAt(schedule).toISO();
            }

            this.outgoingMessages.push(outgoingMessage);
            await this.outgoingMessageService.put(outgoingMessage);
        } catch (error) {
            selectedRecipient.outgoingMessageDraft[documentGroup] = originalEmailWithRecipientPlaceholders;
            this.saveReport();

            outgoingMessage.failedAt = moment().format();
            outgoingMessage.error = {
                code: error?.code,
                message: error?.message,
                data: error?.data,
            };
            this.outgoingMessages.push(outgoingMessage);
            await this.outgoingMessageService.put(outgoingMessage);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    MISSING_EMAIL_BODY: {
                        title: 'E-Mail-Text fehlt',
                        body: 'Bitte ändere den E-Mail-Text noch einmal, damit er gespeichert wird. Dann sollte der Versand funktionieren.',
                    },
                    // Include possible errors for the DAT VXS file
                    ...getDatErrorHandlers(),
                },
                defaultHandler: {
                    title: 'E-Mail nicht gesendet',
                    body: 'Ein Fehler ist aufgetreten',
                },
            });
        } finally {
            this.emailTransmissionPending = false;
        }

        selectedRecipient.outgoingMessageDraft[documentGroup] = new OutgoingEmailMessage();
        // Keep the same attribute on whether the user wants to add a VXS export to the garage email.
        selectedRecipient.outgoingMessageDraft[documentGroup].isDatVxsAttached = sentEmailTemplate.isDatVxsAttached;

        // Success info
        const status = getOutgoingMessageStatus(outgoingMessage);
        switch (status) {
            case 'scheduled': {
                this.emailJustSentInfo = { label: 'E-Mail wurde vorgemerkt', icon: 'schedule' };
                break;
            }
            case 'failed': {
                this.emailJustSentInfo = { label: 'E-Mail konnte nicht gesendet werden', icon: 'error' };
                break;
            }
            default: {
                this.emailJustSentInfo = { label: 'E-Mail wurde gesendet', icon: 'check' };
                break;
            }
        }
        window.setTimeout(() => {
            this.emailJustSentInfo = false;
        }, 2000);

        // Save the report again to save the sentEmail in the sentDocumentEmails array
        this.saveReport();
    }

    public setLetterReceived(recipient: CommunicationRecipient, value: boolean) {
        recipient.receivedLetter = value;
        this.saveReport();
    }

    public getActiveDocuments(): DocumentMetadata[] {
        return this.filteredDocuments.filter((document) => {
            const isIncluded = !!this.getDocumentOrderItemByDocument(document)?.includedInFullDocument;
            const isAvailable = this.isDocumentAvailable(document);
            return isIncluded && isAvailable;
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Send E-Mail and Download Letter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Outgoing Messages
    //****************************************************************************/
    private registerOutgoingMessagesWebsocketEvents() {
        const createUpdatesSubscription: Subscription =
            this.outgoingMessageService.createdFromExternalServerOrLocalBroadcast$.subscribe({
                next: (createdOutgoingMessage: OutgoingEmailMessage) => {
                    // Only add the outgoing message if reportId matches
                    if (createdOutgoingMessage.reportId !== this.report._id) {
                        return;
                    }

                    if (
                        this.outgoingMessages.some(
                            (outgoingMessage) => outgoingMessage._id === createdOutgoingMessage._id,
                        )
                    ) {
                        return;
                    }
                    this.outgoingMessages.push(createdOutgoingMessage);
                },
            });
        this.subscriptions.push(createUpdatesSubscription);

        const patchUpdatesSubscription: Subscription =
            this.outgoingMessageService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
                next: (patchedEvent: PatchedEvent<OutgoingEmailMessage>) => {
                    if (!this.outgoingMessages) return;

                    // Only update the outgoing message if reportId matches
                    if (patchedEvent.patchedRecord.reportId !== this.report._id) {
                        return;
                    }

                    // If this view holds the record being updated, update it.
                    const outgoingMessage = this.outgoingMessages.find(
                        (outgoingMessage) => outgoingMessage._id === patchedEvent.patchedRecord._id,
                    );
                    if (outgoingMessage) {
                        applyOfflineSyncPatchEventToLocalRecord({
                            localRecord: outgoingMessage,
                            patchedEvent,
                        });
                    }
                },
            });

        this.subscriptions.push(patchUpdatesSubscription);
    }

    private async loadOutgoingMessages(): Promise<void> {
        this.outgoingMessages = await this.outgoingMessageService
            .find({ reportId: this.report._id, source: 'report' })
            .toPromise();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Outgoing Messages
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sent E-Mail
    //****************************************************************************/
    public editAsNew(outgoingMessage: OutgoingMessage) {
        const outgoingMessageCopy: OutgoingEmailMessage = new OutgoingEmailMessage(outgoingMessage);
        outgoingMessageCopy._id = generateId();

        this.selectedRecipient.outgoingMessageDraft[this.selectedDocumentGroup] = outgoingMessageCopy;
        this.saveReport();
    }

    public stopPropagation(event: Event): void {
        event.stopPropagation();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sent E-Mail
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Completing a report
    //****************************************************************************/
    public userMayLockReport(): boolean {
        return this.user.accessRights.lockReports;
    }

    public getLockReportButtonTooltip(): string {
        // Report locked
        if (this.report.state === 'done') {
            if (!this.user.accessRights.lockReports) {
                return 'Entsperren nicht möglich, da Zugriffsrecht fehlt.';
            }
            return 'Gutachten öffnen';
        }
        // Report unlocked
        else {
            if (!this.user.accessRights.lockReports) {
                return 'Abschluss nicht möglich, da Zugriffsrecht fehlt.';
            }
            return 'Gutachten abschließen & Rechnung ins Rechnungsmodul übertragen. Aufschließen ist jederzeit möglich.';
        }
    }

    protected async toggleLockReport(): Promise<void> {
        await this.reportDetailsService.toggleLockReport(this.report);

        if (isReportLocked(this.report)) {
            // Update the invoice, because that might be updated after locking the report
            this.reportInvoice = await this.invoiceService.getReportInvoice(this.report);
            this.compareReportInvoiceWithInvoiceParamsInvoice();

            this.tutorialStateService.markUserTutorialStepComplete('reportLocked');
            await this.displayCongratsDialogOnNumberOfReports();
        }
    }

    public getReportLockedMessage(): string {
        if (!this.report.lockedAt || !this.report.lockedBy) {
            return '';
        }
        const dateFromNow = moment(this.report.lockedAt).fromNow();
        const dateFromNowCapitalized = dateFromNow[0].toUpperCase() + dateFromNow.slice(1);

        const lockedByUser = this.userService.getTeamMemberFromCache(this.report.lockedBy);

        // The lockedByUser may be undefined if the teamMember cache has not yet been populated after a refresh of the page.
        if (!lockedByUser) {
            return `${dateFromNowCapitalized} abgeschlossen.`;
        }

        return `${dateFromNowCapitalized} von ${lockedByUser.firstName} ${lockedByUser.lastName} abgeschlossen.`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Completing a report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Congratulations Dialog
    //****************************************************************************/
    private async displayCongratsDialogOnNumberOfReports(): Promise<void> {
        if (!this.networkStatusService.isOnline()) {
            return;
        }

        const user = this.loggedInUserService.getUser();
        let numberOfReports: number;
        try {
            ({ numberOfReports } = await this.reportsAnalyticsService.getNumberOfLockedReports().toPromise());
        } catch (error) {
            // Fail silently since user would not miss the dialog if it doesn't show up.
            console.error("Couldn't get the number of locked reports to display the congrats dialog.", error);
        }

        /**
         * Number of reports must be higher than the number of reports for which the user was already shown the dialog.
         * This prevents re-displaying the dialog after the user re-opened a report.
         */
        const highestPassedThreshold: number = this.getHighestReachedThreshold(numberOfReports);

        /**
         * Display dialog only, if there is a newly-passed threshold.
         */
        const userHasPassedThresholdBefore: boolean =
            (user.gamification.congratsDialogDiplayedAtNumberOfReports ?? 0) >= highestPassedThreshold;

        /**
         * - The user must be passing a threshold
         * and
         * - he must not have been congratulated on a number of reports higher than the threshold.
         */
        if (highestPassedThreshold && !userHasPassedThresholdBefore) {
            /**
             * Querying the revenue is expensive since we use the endpoints of the analytics services.
             * Therefore query only if we know that we will display the dialog.
             *
             * We have no dedicated endpoint to query the total revenue of a team.
             * Therefore, we query the revenue grouped by months and sum them up.
             * Other than the previously used endpoint for revenue by assessor, this endpoint does not include any lookups from the invoices in the reports collection, and therefore does not produce too much load on the database.
             */
            let revenue: number = 0;
            if (user.accessRights.reporting) {
                try {
                    const revenuesByMonth = await this.revenueAnalyticsService
                        .getRevenueByMonth({
                            from: moment(this.loggedInUserService.getTeam().createdAt).format('YYYY-MM-DD'),
                            to: moment().format('YYYY-MM-DD'),
                        })
                        .toPromise();

                    // Sum the revenue of all months to get the total
                    revenue = revenuesByMonth.reduce(
                        (previousValue, currentValue) => previousValue + currentValue.totalRevenueNet,
                        0,
                    );
                } catch (error) {
                    // Fail silently since user would not miss the dialog if it doesn't show up.
                    console.error("Couldn't get the total revenue for the congrats dialog.", error);
                }
            }

            /**
             * Open Dialog
             */
            this.openNumberOfReportsCongratsDialog({
                numberOfReports,
                numberOfReportsThreshold: highestPassedThreshold,
                revenue,
            });

            /**
             * Save user
             */
            user.gamification.congratsDialogDiplayedAtNumberOfReports = numberOfReports;
            this.loggedInUserService.setUser(user);

            try {
                await this.userService.put(user);
            } catch (error) {
                // Display this error since the user would be annoyed if the congrats dialog would show up each time after locking a report.
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Nutzer nicht gespeichert',
                        body: "Beim Speichern der Dialog-Schwelle. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }
        }
    }

    private getHighestReachedThreshold(numberOfReports: number): number {
        let highestThreshold: number = 0;
        for (const threshold of congratsDialogNumberOfReportsThresholds) {
            if (threshold > highestThreshold && numberOfReports >= threshold) {
                highestThreshold = threshold;
            }
        }
        return highestThreshold;
    }

    private openNumberOfReportsCongratsDialog(data: NumberOfReportsCongratsDialogData): void {
        this.dialog.open<NumberOfReportsCongratsDialogComponent, NumberOfReportsCongratsDialogData>(
            NumberOfReportsCongratsDialogComponent,
            {
                data,
            },
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Congratulations Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Building Block Overwriter
    //****************************************************************************/
    public getBuildingBlockOverwriterTooltip(): string {
        if (this.isReportLocked()) {
            return 'Nicht möglich, weil Gutachten abgeschlossen';
        }
        return 'Texte für dieses Gutachten anpassen';
    }

    public showBuildingBlockOverwriter() {
        if (this.isReportLocked()) {
            this.toastService.info(
                'Gutachten abgeschlossen',
                'Öffne das Gutachten, um Textbausteine zu überschreiben.',
            );
            return;
        }
        this.buildingBlockOverwriterShown = true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Building Block Overwriter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  File Name Pattern
    //****************************************************************************/
    public openFileNamePatternEditor(documentType: DocumentTypeForFileNamePattern, documentOrderConfigId?: string) {
        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.error(
                `Zugriffsrecht "${translateAccessRightToGerman('editTextsAndDocumentBuildingBlocks')}" erforderlich`,
                'Bitte kontaktiere deinen Administrator.',
            );
            return;
        }

        this.documentForFileNameEditor = {
            documentType,
            documentOrderConfigId,
        };
    }

    /**
     * For now, we only allow customizing the full report document (with or without report separately) and each document that we render ourselves.
     */
    public isFileNameCustomizable(documentMetadata: DocumentMetadata): boolean {
        return isRenderedDocumentType(documentMetadata.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END File Name Pattern
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DOCX Watermark
    //****************************************************************************/
    /**
     * For now, we only allow customizing the full report document (with or without report separately) and each document that we render ourselves.
     */
    protected isWatermarkPossible(documentMetadata: DocumentMetadata): boolean {
        return isRenderedDocumentType(documentMetadata.type);
    }

    protected isWatermarkActive(documentMetadata: DocumentMetadata): boolean {
        return !!documentMetadata.docxWatermarkType;
    }

    protected getWatermarkTypeGerman(watermarkType: DocxWatermarkType): DocxWatermarkTypeGerman {
        switch (watermarkType) {
            case 'draft':
                return 'Entwurf';
            case 'duplicate':
                return 'Duplikat';
            default:
                throw new AxError({
                    code: 'UNKNOWN_WATERMARK_TYPE',
                    message: `The given watermark type is unknown.`,
                    data: {
                        watermarkType,
                    },
                });
        }
    }

    protected getFirstLetterOfWatermarkTypeGerman(documentMetadata: DocumentMetadata): string {
        const watermarkTypeGerman: DocxWatermarkTypeGerman = this.getWatermarkTypeGerman(
            documentMetadata.docxWatermarkType,
        );
        return watermarkTypeGerman[0];
    }

    protected getWatermarkActiveIconTooltip(documentMetadata: DocumentMetadata) {
        const watermarkTypeGerman: DocxWatermarkTypeGerman = this.getWatermarkTypeGerman(
            documentMetadata.docxWatermarkType,
        );

        return `Ein ${watermarkTypeGerman}-Wasserzeichen wird abgedruckt.`;
    }

    protected activateWatermarkConfig({
        documentMetadata,
        docxWatermarkType,
    }: {
        documentMetadata: DocumentMetadata;
        docxWatermarkType: DocxWatermarkType;
    }) {
        documentMetadata.docxWatermarkType = docxWatermarkType;
        void this.saveReport();
    }

    protected deactivateWatermark(documentMetadata: DocumentMetadata) {
        delete documentMetadata.docxWatermarkType;
        void this.saveReport();
    }

    protected openDocxWatermarkSettings() {
        if (this.mayUserOpenWatermarkSettings()) {
            this.dialog.open<DocxWatermarkSettingsDialogComponent>(DocxWatermarkSettingsDialogComponent, {
                autoFocus: 'dialog',
            });
        } else {
            this.toastService.info(
                `Zugriffsrecht "${translateAccessRightToGerman('editTextsAndDocumentBuildingBlocks')}" erforderlich`,
                'Das Dokument-Wasserzeichen darf nur mit entsprechendem Recht bearbeitet werden. Bitte kontaktiere deinen Administrator.',
            );
        }
    }

    protected getDocxWatermarkSettingsTooltip(): string {
        if (this.mayUserOpenWatermarkSettings()) {
            return 'Wasserzeichen-Vorlagen bearbeiten';
        } else {
            return `Wasserzeichen-Vorlagen dürfen nur mit dem Zugriffsrecht "${translateAccessRightToGerman('editTextsAndDocumentBuildingBlocks')}" bearbeitet werden.`;
        }
    }

    protected mayUserOpenWatermarkSettings(): boolean {
        return hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DOCX Watermark
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Associated Invoice
    //****************************************************************************/

    private async getRepairConfirmationInvoice(): Promise<void> {
        if (!this.report.repairConfirmation || !this.report.repairConfirmation.invoiceParameters._id) return;

        this.repairConfirmationInvoice = await this.invoiceService.get(
            this.report.repairConfirmation.invoiceParameters._id,
        );
    }

    private getInvoiceParamsInvoice(): void {
        this.invoiceParamsInvoice = this.invoiceService.createInvoiceFromReport({
            report: this.report,
            user: this.user,
            team: this.team,
        });
    }

    private compareReportInvoiceWithInvoiceParamsInvoice(): void {
        if (!this.invoiceParamsInvoice || !this.reportInvoice) return;

        this.invoiceParamsDifferFromInvoice = !this.invoiceService.areInvoicesEqual(
            this.invoiceParamsInvoice,
            this.reportInvoice,
        ).result;
    }

    public navigateToFeesScreen(): void {
        this.router.navigate(['../Rechnung'], { relativeTo: this.route });
    }

    public getInvoiceForSelectedDocumentGroup(): Invoice {
        let invoice: Invoice;

        switch (this.selectedDocumentGroup) {
            case 'report':
                invoice = this.reportInvoice;
                break;
            case 'repairConfirmation':
                invoice = this.repairConfirmationInvoice;
                break;
        }

        return invoice;
    }

    protected isInvoiceActive(): boolean {
        const invoiceDocument: DocumentMetadata = this.filteredDocuments.find(
            (document) => document.type === 'invoice',
        );

        /**
         * The invoice document is always a part of the list but it may also be unavailable because the user
         * has not completed the invoice yet.
         */
        if (!invoiceDocument || !this.isDocumentAvailable(invoiceDocument)) {
            return false;
        }

        return this.getDocumentOrderItemByDocument(invoiceDocument).includedInFullDocument;
    }

    public isInvoiceLocked(): boolean {
        const invoice: Invoice = this.getInvoiceForSelectedDocumentGroup();

        if (!invoice) return false;

        return !!invoice.lockedAt;
    }

    public invoiceLockedAt(): string {
        const invoice: Invoice = this.getInvoiceForSelectedDocumentGroup();

        if (!invoice) return '';

        return invoice.lockedAt;
    }

    public lockInvoice(): void {
        const invoice: Invoice = this.getInvoiceForSelectedDocumentGroup();

        if (!invoice) throw Error('BOOKING_INVOICE_THAT_DOES_NOT_EXIST');

        invoice.lockedAt = moment().format();
        invoice.lockedBy = this.user._id;
    }

    public async saveInvoice(): Promise<void> {
        const invoice: Invoice = this.getInvoiceForSelectedDocumentGroup();

        try {
            await this.invoiceService.put(invoice);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Rechnung nicht gespeichert',
                    body: 'Bitte kontaktiere die Hotline.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Associated Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Documents
    //****************************************************************************/

    /**
     * Add the given custom document to the current report. This function adds it to the documents array
     * and documentOrders array of the report.
     */
    protected addCustomDocument(customDocumentOrderConfig: DocumentOrderConfig): void {
        if (
            this.report.documents.find(
                (document) => document.customDocumentOrderConfigId === customDocumentOrderConfig._id,
            )
        ) {
            this.toastService.info(
                'Dokument bereits hinzugefügt',
                'Das gewählte Dokument wurde bereits hinzugefügt und kann nur einmal eingefügt werden.',
            );
            return;
        }

        const document = new DocumentMetadata({
            type: 'customDocument',
            title: customDocumentOrderConfig.titleLong,
            customDocumentOrderConfigId: customDocumentOrderConfig._id,
            createdBy: this.user._id,
        });
        addDocumentToReport(
            {
                report: this.report,
                team: this.team,
                documentGroup: this.selectedDocumentGroup,
                newDocument: document,
            },
            {
                includedInFullDocument: !!this.userPreferences.activateDocumentsAfterUpload,
                // Type customDocument is allowed multiple times
                allowMultiple: true,
            },
        );
        // Refresh the view to reflect the new document.
        this.filterDocuments();

        this.saveReport();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Documents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab: 'printAndTransmission',
        });
    }

    /**
     * On each websocket put event, update the derived local array "filteredDocuments" from the report.documents array.
     */
    private registerPatchWebsocketEvent() {
        const patchUpdatesSubscription = this.reportService.patchedFromExternalServerOrLocalBroadcast$
            .pipe(
                // Only consider updates made to the current report.
                filter(({ patchedRecord }) => patchedRecord._id === this.report._id),
            )
            .subscribe({
                next: () => {
                    this.filterDocuments();
                },
            });

        this.subscriptions.push(patchUpdatesSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public trackById = trackById;

    public openNewWindow(url: string) {
        this.newWindowService.open(url);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Videos
    //****************************************************************************/
    public openVideo(heading: string, videoUrl: string) {
        this.dialog.open<VideoPlayerDialogComponent, VideoPlayerDialogData>(VideoPlayerDialogComponent, {
            data: {
                heading,
                videoUrl,
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Videos
    /////////////////////////////////////////////////////////////////////////////*/
    private checkForIosDevice(): boolean {
        // Detect iOS devices, source: https://stackoverflow.com/a/9039885/21074566
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                navigator.platform,
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    }

    public isReportLocked(): boolean {
        return this.report && this.report.state === 'done';
    }

    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }
    public async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    protected readonly isAdmin = isAdmin;
    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

export type DocumentGroupName = 'report' | 'repairConfirmation';

type DocxWatermarkTypeGerman = 'Entwurf' | 'Duplikat';

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getPaymentStatus } from '@autoixpert/lib/invoices/get-payment-status';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { PaymentStatus } from '@autoixpert/models/invoices/payment-status';

@Component({
    selector: 'payment-status',
    templateUrl: 'payment-status.component.html',
    styleUrls: ['payment-status.component.scss'],
})
export class PaymentStatusComponent {
    @Input() invoice: Invoice;
    @Input() displaySmall = false;
    /**
     * Use the payment status color as the background instead of border and font color. This is useful
     * if the payment status is shown in front of a dark or colorful background instead of a white background.
     */
    @Input() invertedColors: boolean = false;
    @Output() statusClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    public get paymentStatus(): PaymentStatus {
        return getPaymentStatus(this.invoice);
    }

    public emitStatusClick(event: MouseEvent) {
        this.statusClick.emit(event);
    }
}

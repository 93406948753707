import { DateTime } from 'luxon';
import { generateId } from '../../lib/generate-id';
import { DocumentBuildingBlockCondition } from './document-building-block-condition';
import {
    DocumentBuildingBlockConditionGroup,
    DocumentBuildingBlockConditionGroupOperator,
} from './document-building-block-condition-group';

export class DocumentBuildingBlockVariant implements DocumentBuildingBlockConditionGroup {
    constructor(options?: Partial<DocumentBuildingBlockVariant>) {
        Object.assign(this, options);
    }

    _id: string = generateId();

    // Conditions that are checked for this variant, operator is used to combine them.
    conditionsOperator: DocumentBuildingBlockConditionGroupOperator = 'and';
    conditions: Array<DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup> = [];

    // If this variant is the default variant, it is used if no other variant's conditions match.
    isDefaultVariant: boolean = false;

    heading: string = null;
    content: string = null;
    changedByUser: boolean = false;
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt?: string = DateTime.now().setZone('UTC').toISO();
    createdAt?: string = DateTime.now().setZone('UTC').toISO();
}

import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'video-player-dialog',
    templateUrl: 'video-player-dialog.component.html',
    styleUrls: ['video-player-dialog.component.scss'],
})
export class VideoPlayerDialogComponent {
    constructor(
        private dialog: MatDialogRef<VideoPlayerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VideoPlayerDialogData,
        private toastService: ToastService,
        private domSanitizer: DomSanitizer,
    ) {}

    /**
     *  Autoplay: Add the ?autoplay=1 option to the video URL
     */
    public trustedVideoUrl;

    ngOnInit() {
        let videoUrl: string = this.data.videoUrl;
        if (!videoUrl.includes('?')) {
            videoUrl += '?';
        }
        videoUrl += '&rel=0';
        this.trustedVideoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    }
}

export interface VideoPlayerDialogData {
    heading: string;
    videoUrl: string;
}

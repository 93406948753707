import { get } from 'lodash-es';
import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { signableDocumentTypes } from '../../models/documents/document-metadata';
import { DocumentOrderConfig } from '../../models/documents/document-order-config';
import { CustomFeeSet } from '../../models/reports/assessors-fee/custom-fee-set';
import { Report } from '../../models/reports/report';
import {
    RenderedDocumentBuildingBlock,
    SignableDocumentsRenderingConfig,
} from '../../models/signable-documents/signable-document-rendering-config';
import { SignablePdfTemplateConfig } from '../../models/signable-documents/signable-pdf-template-config';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { getFullRecipientName } from '../placeholder-values/get-full-recipient-name';
import { PlaceholderValues } from '../placeholder-values/get-placeholder-values';
import { getFullUserContactPerson } from '../users/get-full-user-contact-person';

/**
 * Generates a SignableDocumentsRenderingConfig object that contains all necessary data for rendering signable documents via the shared component.
 * This function is used both by the frontend to create the rendering config in place as well as the claimant signature form backend service to create the rendering config.
 */
export function getSignableDocumentsRenderingConfig({
    user,
    team,
    report,
    customFeeSets,
    fieldGroupConfigs,
    responsibleAssessor,
    renderedBuildingBlocks,
    reportPlaceholderValues,
    documentOrderConfigs,
    signablePdfTemplateConfigs,
}: {
    user: User;
    team: Team;
    report: Report;
    responsibleAssessor: User;
    customFeeSets: CustomFeeSet[];
    fieldGroupConfigs: FieldGroupConfig[];
    documentOrderConfigs: DocumentOrderConfig[];
    renderedBuildingBlocks: RenderedDocumentBuildingBlock[];
    signablePdfTemplateConfigs: SignablePdfTemplateConfig[];
    reportPlaceholderValues: PlaceholderValues;
}): SignableDocumentsRenderingConfig {
    // Complete assessor name
    let completeAssessorName: string;
    if (report.responsibleAssessor && responsibleAssessor) {
        completeAssessorName = getFullRecipientName({
            contactPerson: {
                organization: responsibleAssessor.organization,
                salutation: responsibleAssessor.salutation,
                lastName: responsibleAssessor.lastName,
                firstName: responsibleAssessor.firstName,
            },
        }).join('<br>');
    } else {
        completeAssessorName = getFullRecipientName({
            contactPerson: {
                organization: user.organization,
                salutation: user.salutation,
                lastName: user.lastName,
                firstName: user.firstName,
            },
        }).join('<br>');
    }

    // Responsible Assessor Contact Person
    const responsibleAssessorContactPerson = getFullUserContactPerson({
        user: responsibleAssessor ?? user,
        officeLocations: team.officeLocations,
        officeLocationId: report.officeLocationId,
    });

    // Only include report placeholder values that are needed by the signable PDF templates
    const filteredReportPlaceholderValues: PlaceholderValues = {};
    const placeholderRegEx = /{[^%].+?}/g;

    // Iterate over all text elements of all pages of all signable PDF templates in order to find all placeholders
    for (const signablePdfTemplateConfig of signablePdfTemplateConfigs) {
        const textElements = signablePdfTemplateConfig.pages.flatMap((page) => page.textElements);
        for (const textElement of textElements) {
            const matches = textElement.content.match(placeholderRegEx) ?? [];
            for (const match of matches) {
                const placeholder = match.substring(1, match.length - 1);
                const placeholderValue = get(reportPlaceholderValues, placeholder);
                if (placeholderValue) {
                    filteredReportPlaceholderValues[placeholder] = placeholderValue;
                }
            }
        }
    }

    // Create config
    return {
        fieldGroupConfigs,
        renderedBuildingBlocks,
        signablePdfTemplateConfigs,
        reportPlaceholderValues: filteredReportPlaceholderValues,
        customDocumentOrderConfigs: documentOrderConfigs
            .filter((config) => config.type === 'customDocument')
            .map((config) => ({
                _id: config._id,
                customDocumentConfig: {
                    title: config.titleShort,
                    googleMaterialIconName: config.customDocumentConfig?.googleMaterialIconName,
                },
            })),

        report: {
            _id: report._id,
            type: report.type,
            state: report.state,
            token: report.token,
            accident: report.accident,
            actualCustomer: report.actualCustomer,
            orderPlacedByClaimant: report.orderPlacedByClaimant,
            authorOfDamage: report.authorOfDamage,
            documents: report.documents,
            car: report.car,
            claimant: report.claimant,
            insurance: report.insurance,
            lawyer: report.lawyer,
            officeLocationId: report.officeLocationId,
            ownerOfAuthorOfDamagesCar: report.ownerOfAuthorOfDamagesCar,
            ownerOfClaimantsCar: report.ownerOfClaimantsCar,
            responsibleAssessor: report.responsibleAssessor,
            feeCalculation: report.feeCalculation,
            // Sort signable documents by order of signableDocumentTypes (otherwise displayed in the order they were activated)
            signableDocuments: report.signableDocuments.sort(
                (doc1, doc2) =>
                    signableDocumentTypes.indexOf(doc1.documentType) - signableDocumentTypes.indexOf(doc2.documentType),
            ),
            _schemaVersion: report._schemaVersion,
            _documentVersion: report._documentVersion,
        },

        feeTable: {
            customFeeSets,
            vksFeeConfig: team.preferences.vksFeeConfig,
            bvskFeeConfig: team.preferences.bvskFeeConfig,
            cgfFeeConfig: team.preferences.cgfFeeConfig,
            defaultFeeTableLiability: team.preferences.defaultFeeTableLiability,
        },

        participantSummary: {
            completeAssessorName,
            responsibleAssessorContactPerson,
        },
    };
}

import { filters as FabricFilters, config, getEnv, setFilterBackend } from 'fabric';
import { debounceAx } from '../../../../shared/libraries/debounce-ax';
import { PhotoEditorComponent } from './photo-editor.component';
import { FilterManagerOptions, Filters } from './photo-editor.interfaces';

export class FilterManager {
    private editor: PhotoEditorComponent;
    filters: Filters;

    /**
     * Helper function to debounce saving filter changes to the history (undo/redo) by 500 ms. By that we prevent hundreds of history entries
     * when moving the slider for example.
     */
    private readonly saveHistoryDebounced = debounceAx(() => this.editor.canvasManager.saveHistory(), 500);

    constructor(parameters: FilterManagerOptions) {
        this.editor = parameters.editor;

        this.getDefaultFilters();
    }

    public adjustBrightness(brightness): void {
        this.filters['brightness'].value = brightness;
    }

    public adjustContrast(contrast): void {
        this.filters['contrast'].value = contrast;
    }

    public adjustSaturation(saturation): void {
        this.filters['saturation'].value = saturation;
    }

    public adjustSharpness(sharpness): void {
        this.filters['sharpness'].value = sharpness;
    }

    /**
     * Convert the view's filter slider values to Fabric.js filters and apply the to the image.
     * @return {Promise<void>}
     */
    public apply({ saveToHistory = false }: { saveToHistory?: boolean } = {}): void {
        const fabricPhoto = this.editor.canvasManager.fabricPhoto;
        fabricPhoto.filters = [];

        if (+this.filters['brightness'].value !== 0) {
            fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Brightness({
                // Change the scale from [-100, 100] to [-1, 1]
                brightness: +this.filters['brightness'].value / 100,
            });
        }
        if (+this.filters['contrast'].value !== 0) {
            fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Contrast({
                // Contrast may be a value between -1 and 1. The value returned from the range object, however, lies between -100 and 100.
                // Transform this here.
                contrast: +this.filters['contrast'].value / 100,
            });
        }
        if (+this.filters['saturation'].value !== 0) {
            fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Saturation({
                saturation: +this.filters['saturation'].value / 100,
            });
        }

        if (+this.filters['sharpness'].value > 0) {
            let kernelCenter = +this.filters['sharpness'].value / 10,
                kernelOuter = (kernelCenter - 1) / -4;

            if (kernelCenter === 0) {
                kernelCenter = 1;
                kernelOuter = 0;
            }
            // Sharpen.
            // A great tutorial about how convolution works can be found at https://docs.gimp.org/en/plug-in-convmatrix.html
            fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Convolute({
                matrix: [0, kernelOuter, 0, kernelOuter, kernelCenter, kernelOuter, 0, kernelOuter, 0],
            });
        }
        // If the user wants a negative sharpness, the image is blurred
        else if (+this.filters['sharpness'].value < 0) {
            const degreeOfBlur = (+this.filters['sharpness'].value / 10) * -1,
                // For three iterations, this is 9, for 4 it is 16 and so on.
                blurMatrixLength = 9, //Math.pow(blurMatrixDimension, 2),
                matrix = [],
                matrixElement = 1 / blurMatrixLength;

            for (let i = 0; i < blurMatrixLength; i++) {
                /**
                 * e.g.
                 * [
                 *   1/9, 1/9, 1/9,
                 *   1/9, 1/9, 1/9,
                 *   1/9, 1/9, 1/9
                 * ]
                 * or
                 * [
                 *   1/25, 1/25, 1/25, 1/25, 1/25,
                 *   1/25, 1/25, 1/25, 1/25, 1/25,
                 *   1/25, 1/25, 1/25, 1/25, 1/25,
                 *   1/25, 1/25, 1/25, 1/25, 1/25,
                 *   1/25, 1/25, 1/25, 1/25, 1/25
                 * ]
                 */
                matrix.push(matrixElement);
            }
            for (let i = 0; i < degreeOfBlur; i++) {
                fabricPhoto.filters[fabricPhoto.filters.length] = new FabricFilters.Convolute({
                    matrix: matrix,
                });
            }
        }

        // This function takes a while (~100ms to 1s).
        fabricPhoto.applyFilters();
        this.editor.blurPatternManager.applyBlurPatternPhotosFilters();
        this.editor.canvasManager.canvas.requestRenderAll();

        if (saveToHistory) {
            this.saveHistoryDebounced();
        }
    }

    /**
     * Reset all filters to their standard value.
     * Use apply() if you wish to see the reset changes.
     */
    public reset() {
        for (const filterName in this.filters) {
            if (!this.filters.hasOwnProperty(filterName)) continue;
            this.filters[filterName].value = 0;
        }
    }

    /**
     * If the WebGL canvas used for applying filters is too small, photos are cropped when applying filters. If it is too big,
     * rendering photos with filters takes too long. This function is used to set the ideal WebGL canvas size (textureSize) for
     * each photo.
     * @param textureSize
     */
    public adjustFilterBackend(textureSize) {
        /**
         * Round up to full multiples of 4 since that's what WebGL texture size needs to be.
         * Original error:
         * Failed to construct 'ImageData': The input data length is not a multiple of (4 * width).
         */
        textureSize = Math.ceil(textureSize / 4) * 4;
        if (config.textureSize !== textureSize) {
            config.textureSize = textureSize;
            // Make fabric reinitialize the filter backend with the new texture size.
            setFilterBackend(null);
            // console.log(`Adjusted the WebGL filter backend's canvas to ${textureSize}x${textureSize}.`);
        } else {
            // console.log(`The filter backend needs no adjustment because the texture size of ${textureSize} remained equal.`);
        }
    }

    public isDeviceStrongEnoughForFilterBackend(): boolean {
        // We need to call queryWebGL once before the max texture size is available (otherwise WebGLProbe.isSupported always returns false).
        const { WebGLProbe } = getEnv();
        WebGLProbe.queryWebGL(document.createElement('canvas'));

        return WebGLProbe.isSupported(config.textureSize);
    }

    /**
     * Sets the filters to the value set on the photo currently loaded in the editor.
     */
    public loadFilterValuesFromPhoto() {
        // If the current photo does not have any fabric info on it yet (e.g. because it's new), remove any previously set filter values.
        if (!this.editor.photo.versions[this.editor.photoVersion].fabricJsInformation) {
            return this.reset();
        }
        this.adjustBrightness(
            this.editor.photo.versions[this.editor.photoVersion].fabricJsInformation.axFilters.brightness.value,
        );
        this.adjustContrast(
            this.editor.photo.versions[this.editor.photoVersion].fabricJsInformation.axFilters.contrast.value,
        );
        this.adjustSaturation(
            this.editor.photo.versions[this.editor.photoVersion].fabricJsInformation.axFilters.saturation.value,
        );
        this.adjustSharpness(
            this.editor.photo.versions[this.editor.photoVersion].fabricJsInformation.axFilters.sharpness.value,
        );
    }

    public getFiltersArray() {
        const filtersArray = [];
        for (const filterName in this.filters) {
            if (!this.filters.hasOwnProperty(filterName)) continue;
            filtersArray.push(this.filters[filterName]);
        }
        return filtersArray;
    }

    private getDefaultFilters() {
        this.filters = getDefaultFilters();
    }

    public filterChangeHandler(filterName: keyof Filters, filterValue: number) {
        switch (filterName) {
            case 'brightness':
                this.adjustBrightness(filterValue);
                break;
            case 'contrast':
                this.adjustContrast(filterValue);
                break;
            case 'saturation':
                this.adjustSaturation(filterValue);
                break;
            case 'sharpness':
                this.adjustSharpness(filterValue);
                break;
            default:
                throw new Error('Unknown image filter name.');
        }
    }
}

export function getDefaultFilters() {
    return {
        brightness: {
            id: 'brightness',
            label: 'Helligkeit',
            value: 0,
            step: 1,
        },
        contrast: {
            id: 'contrast',
            label: 'Kontrast',
            value: 0,
            step: 1,
        },
        saturation: {
            id: 'saturation',
            label: 'Sättigung',
            value: 0,
            step: 1,
        },
        sharpness: {
            id: 'sharpness',
            label: 'Schärfe',
            value: 0,
            step: 10,
        },
    };
}

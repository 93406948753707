import { Injectable } from '@angular/core';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { computeInvoiceTotalNet } from '@autoixpert/lib/invoices/compute-invoice-total';
import { createInvoice } from '@autoixpert/lib/invoices/create-invoice';
import { determineDocumentMetadataTitle } from '@autoixpert/lib/invoices/determine-document-metadata-title';
import { setPaymentStatus } from '@autoixpert/lib/invoices/set-payment-status';
import { isApproximatelyEqual } from '@autoixpert/lib/is-approximately-equal';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Invoice, PaymentReminders } from '@autoixpert/models/invoices/invoice';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Payment } from '@autoixpert/models/invoices/payment';
import { Report } from '@autoixpert/models/reports/report';
import { InvoiceNumberConfig, ReportTokenConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { ApiErrorService } from './api-error.service';
import { InvoiceNumberJournalEntryService } from './invoice-number-journal-entry.service';
import { InvoiceNumberService } from './invoice-number.service';
import { InvoiceService } from './invoice.service';
import { LoggedInUserService } from './logged-in-user.service';
import { ReportDetailsService } from './report-details.service';
import { ReportTokenService } from './report-token.service';
import { ReportService } from './report.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';
import { ToastService } from './toast.service';

/**
 * This service includes methods to cancel invoices fully or in parts.
 *
 * This service is separate from the InvoiceService to avoid a direct dependency of the InvoiceService
 * on the ReportService.
 */
@Injectable({
    providedIn: 'root',
})
export class InvoiceCancellationService {
    constructor(
        private invoiceService: InvoiceService,
        private reportService: ReportService,
        private apiErrorService: ApiErrorService,
        private invoiceNumberService: InvoiceNumberService,
        private reportTokenService: ReportTokenService,
        private loggedInUserService: LoggedInUserService,
        private syncIssueNotificationService: SyncIssueNotificationService,
        private reportDetailsService: ReportDetailsService,
        private toastService: ToastService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    /**
     * Cancels an invoice and creates a new cancellation invoice. Returns the cancellation invoice.
     */
    public async createFullCancellationInvoice({
        rootInvoice,
    }: {
        rootInvoice: Invoice;
    }): Promise<{ updatedOriginalInvoice: Invoice; cancellationInvoice: Invoice }> {
        //*****************************************************************************
        //  Generate Cancellation Invoice
        //****************************************************************************/
        const cancellationInvoiceTemplate = await this.generateCancellationInvoice({
            rootInvoice,
            lineItems: rootInvoice.lineItems.map((lineItem) => ({
                ...lineItem,
                // Revert all line items.
                unitPrice: lineItem.unitPrice * -1,
            })),
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Generate Cancellation Invoice
        /////////////////////////////////////////////////////////////////////////////*/

        // Create references between the cancelling and the original invoice (vice-versa after cancelling invoice was successfully pushed)
        cancellationInvoiceTemplate.rootInvoiceId = rootInvoice._id;

        // Full cancellation invoices (and their canceled counterparts) are excluded from all analytics for simplicity.
        // The user is only interested in income-effective invoices. Partially canceled & partial cancellation invoices are still included in the analytics module.
        cancellationInvoiceTemplate.isFullCancellationInvoice = true;

        //*****************************************************************************
        //  Create Cancellation Invoice on Server
        //****************************************************************************/
        // Will fail if invoice number already exists
        let cancellationInvoice: Invoice;
        try {
            cancellationInvoice = await this.invoiceService.create(cancellationInvoiceTemplate, {
                waitForServer: true,
            });
            await this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceNumberGeneratedOnInvoiceCancellation',
                invoiceNumber: cancellationInvoice.number,
                invoiceId: cancellationInvoice._id,
                documentType: 'invoice',
            });
        } catch (error) {
            if (error.code === 'INVOICE_NUMBER_EXISTS') {
                this.syncIssueNotificationService.resolve({
                    databaseService: this.invoiceService,
                    recordId: cancellationInvoiceTemplate._id,
                });
                throw new AxError({
                    code: 'CANCELLATION_INVOICE_NUMBER_EXISTS',
                    message:
                        'Es existiert bereits eine Rechnung mit derselben Rechnungsnummer wie die Storno-Rechnung.',
                    error,
                });
            } else {
                throw error;
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Create Cancellation Invoice on Server
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Update Original Invoice
        //****************************************************************************/
        // Add a pointer to the cancellation invoice to facilitate navigation between the records.
        rootInvoice.idsOfCancellationInvoices.push(cancellationInvoiceTemplate._id);

        /**
         * Mark the original invoice as canceled by adding a cancellation payment.
         *
         * If there are existing payments, they need to be kept. Example: A company claimant already paid the VAT but now
         * the entire report and its invoice are cancelled. The VAT payment must be sent back to cancel the invoice entirely.
         */
        rootInvoice.payments.push(
            new Payment({
                type: 'partialCancellation',
                shortPaymentStatus: null,
                // Invoice total is negative in a cancellation invoice. The payment must be positive though to take the place of a payment, marking the amount as not outstanding any more.
                amount: Math.abs(cancellationInvoice.totalGross),
                notes: null,
                cancellationInvoiceId: cancellationInvoice._id,
                date: todayIso(),
                createdBy: this.loggedInUserService.getUser().createdBy,
            }),
        );

        // Fully canceled invoices (and their cancellation invoice counterparts) are excluded from all analytics for simplicity.
        // The user is only interested in income-effective invoices. Partially canceled & partial cancellation invoices are still included in the analytics module.
        rootInvoice.isFullyCanceled = true;
        rootInvoice.hasOutstandingPayments = false;

        let updatedOriginalInvoice: Invoice;
        try {
            updatedOriginalInvoice = await this.invoiceService.put(rootInvoice, { waitForServer: true });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Original-Rechnung nicht aktualisiert`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Update Original Invoice
        /////////////////////////////////////////////////////////////////////////////*/

        return {
            updatedOriginalInvoice,
            cancellationInvoice,
        };
    }
    /**
     * Creates a partial cancellation invoice, i.e. one that has only a limited number of line items.
     */
    public async createPartialCancellationInvoice({
        rootInvoice,
        lineItems,
    }: {
        rootInvoice: Invoice;
        lineItems: LineItem[];
    }): Promise<Invoice> {
        //*****************************************************************************
        //  Generate Cancellation Invoice
        //****************************************************************************/
        const cancellationInvoiceTemplate = await this.generateCancellationInvoice({
            rootInvoice,
            lineItems,
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Generate Cancellation Invoice
        /////////////////////////////////////////////////////////////////////////////*/

        // Connect this invoice to the original invoice.
        rootInvoice.idsOfCancellationInvoices.push(cancellationInvoiceTemplate._id);

        // Mark original invoice as fully cancelled if necessary
        const totalCanceledAmount = Math.abs(computeInvoiceTotalNet({ lineItems: lineItems }));
        rootInvoice.isFullyCanceled = isApproximatelyEqual(totalCanceledAmount, rootInvoice.totalNet);

        //*****************************************************************************
        //  Create Cancellation Invoice on Server
        //****************************************************************************/
        // Will fail if invoice number already exists
        let cancellationInvoice: Invoice;
        try {
            cancellationInvoice = await this.invoiceService.create(cancellationInvoiceTemplate, {
                waitForServer: true,
            });

            await this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceNumberGeneratedOnInvoiceCancellation',
                invoiceNumber: cancellationInvoice.number,
                invoiceId: cancellationInvoice._id,
                documentType: 'invoice',
            });
        } catch (error) {
            if (error.code === 'INVOICE_NUMBER_EXISTS') {
                this.syncIssueNotificationService.resolve({
                    databaseService: this.invoiceService,
                    recordId: cancellationInvoice._id,
                });
                throw new AxError({
                    code: 'CANCELLATION_INVOICE_NUMBER_EXISTS',
                    message:
                        'Es existiert bereits eine Rechnung mit derselben Rechnungsnummer wie die Storno-Rechnung.',
                    error,
                });
            } else {
                throw error;
            }
        }

        setPaymentStatus(rootInvoice);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Create Cancellation Invoice on Server
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Update Original Invoice on Server
        //****************************************************************************/
        try {
            await this.invoiceService.put(rootInvoice, { waitForServer: true });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Original-Rechnung nicht aktualisiert`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Update Original Invoice on Server
        /////////////////////////////////////////////////////////////////////////////*/

        return cancellationInvoice;
    }

    /**
     * Generates a cancellation invoice locally.
     * MUST NOT be called externally, only through one of the public methods.
     *
     * This methods
     * - copies data (involved parties, report Data)
     * - resets data (payment reminders)
     * - computes data (totals)
     *
     * The caller is responsible for writing the invoice to the server.
     */
    private async generateCancellationInvoice({
        rootInvoice,
        lineItems,
    }: {
        rootInvoice: Invoice;
        lineItems: LineItem[];
    }): Promise<Invoice> {
        const invoiceData: Partial<Invoice> = this.invoiceService.getInvoiceCoreData(rootInvoice);

        // Invoices are initialized with due date and next payment reminder date.
        // Cancellation invoices do not have these fields, therefore override them here.
        invoiceData.type = 'creditNote';
        invoiceData.dueDate = null;
        invoiceData.nextPaymentReminderAt = null;
        //*****************************************************************************
        //  Involved Parties
        //****************************************************************************/
        if (rootInvoice.claimant) {
            invoiceData.claimant = {
                ...rootInvoice.claimant,
                paymentReminders: new PaymentReminders(),
            };
        }
        if (rootInvoice.lawyer) {
            invoiceData.lawyer = { ...rootInvoice.lawyer, paymentReminders: new PaymentReminders() };
        }
        if (rootInvoice.insurance) {
            invoiceData.insurance = {
                ...rootInvoice.insurance,
                paymentReminders: new PaymentReminders(),
            };
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Involved Parties
        /////////////////////////////////////////////////////////////////////////////*/

        invoiceData.rootInvoiceId = rootInvoice._id;

        // A cancellation invoice should not thank the receiver for his order, as usual invoices do.
        invoiceData.intro = null;

        // The line items have already been reverted.
        invoiceData.lineItems = lineItems;

        // It's a full cancellation invoice if the totals match up.
        invoiceData.isFullCancellationInvoice =
            Math.abs(computeInvoiceTotalNet({ lineItems: invoiceData.lineItems })) >=
            Math.abs(computeInvoiceTotalNet(rootInvoice));

        invoiceData.date = todayIso();

        const cancellationInvoice: Invoice = createInvoice({
            user: this.loggedInUserService.getUser(),
            team: this.loggedInUserService.getTeam(),
            invoiceData,
        });
        determineDocumentMetadataTitle(cancellationInvoice);

        //*****************************************************************************
        //  Get Report Data
        //****************************************************************************/
        let associatedReport: Report;
        if (rootInvoice.reportIds?.length > 0) {
            associatedReport = await this.reportService.get(rootInvoice.reportIds?.[0]);
        }

        const responsibleAssessorId: string =
            associatedReport?.responsibleAssessor ?? this.loggedInUserService.getUser()._id;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Get Report Data
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Invoice Number
        //****************************************************************************/
        const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(
            cancellationInvoice.officeLocationId,
        );

        let invoiceNumberBasedOnReportToken = false,
            reportTokenConfig: ReportTokenConfig;
        if (associatedReport) {
            reportTokenConfig = this.reportTokenService.getReportTokenConfig(associatedReport.officeLocationId);
            invoiceNumberBasedOnReportToken =
                reportTokenConfig.syncWithInvoiceCounter && reportTokenConfig.leadingCounter === 'reportToken';
        }

        if (invoiceNumberBasedOnReportToken) {
            // Cancellation invoice number based on report
            await this.createCancellationInvoiceNumberBasedOnReportToken(
                associatedReport,
                reportTokenConfig,
                cancellationInvoice,
                rootInvoice,
                responsibleAssessorId,
            );
        } else {
            // Regular cancellation invoice number
            await this.createRegularCancellationInvoiceNumber(
                invoiceNumberConfig,
                cancellationInvoice,
                rootInvoice,
                responsibleAssessorId,
                associatedReport,
            );
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Invoice Number
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Report Data
        //****************************************************************************/
        if (rootInvoice.reportsData?.[0]?.token) {
            cancellationInvoice.reportsData = JSON.parse(JSON.stringify(rootInvoice.reportsData));
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Report Data
        /////////////////////////////////////////////////////////////////////////////*/

        // The invoice can be marked as paid because there are no outstanding payments.
        rootInvoice.hasOutstandingPayments = false;

        return cancellationInvoice;
    }

    /**
     * Determine and assign an invoice number for the given cancellation invoice.
     */
    private async createRegularCancellationInvoiceNumber(
        invoiceNumberConfig: InvoiceNumberConfig,
        cancellationInvoice: Invoice,
        rootInvoice: Invoice,
        responsibleAssessorId: string,
        associatedReport: Report,
    ) {
        if (invoiceNumberConfig.useCancellationInvoiceSuffix) {
            // Use the invoice number of the canceled invoice and add the cancellationSuffix
            cancellationInvoice.number = `${rootInvoice.number}${invoiceNumberConfig.cancellationSuffix || ''}`;
        } else {
            cancellationInvoice.number = await this.invoiceNumberService.generateInvoiceNumber({
                officeLocationId: cancellationInvoice.officeLocationId,
                responsibleAssessorId,
                report: associatedReport,
            });
        }
    }

    /**
     * Determine and assign an invoice number that is based on the report token for the given cancellation invoice.
     */
    private async createCancellationInvoiceNumberBasedOnReportToken(
        associatedReport: Report,
        reportTokenConfig: ReportTokenConfig,
        cancellationInvoice: Invoice,
        rootInvoice: Invoice,
        responsibleAssessorId: string,
    ) {
        // Check the stored cancellation invoice number settings from the report (because the settings might have changed in the meantime)
        const savedConfig = associatedReport.invoiceNumberConfig;

        // Determine whether to use a regular invoice number or suffix
        const useSuffix = savedConfig
            ? savedConfig.useCancellationInvoiceSuffixForReportInvoices
            : reportTokenConfig.useCancellationInvoiceSuffixForReportInvoices;
        const cancellationSuffix = savedConfig
            ? savedConfig.reportInvoiceCancellationSuffix
            : reportTokenConfig.reportInvoiceCancellationSuffix;

        if (useSuffix) {
            // Use the invoice number of the canceled invoice and add the cancellationSuffix
            cancellationInvoice.number = `${rootInvoice.number}${cancellationSuffix || ''}`;
        } else {
            cancellationInvoice.number = await this.invoiceNumberService.generateInvoiceNumber({
                officeLocationId: cancellationInvoice.officeLocationId,
                responsibleAssessorId,
                report: associatedReport,
            });

            // Finally save the report (because report invoice counter was increased)
            this.saveReport(associatedReport);
        }
    }

    /**
     * Save reports to the server.
     */
    private async saveReport(report: Report): Promise<void> {
        if (isReportLocked(report)) {
            return;
        }
        try {
            await this.reportDetailsService.patch(report);
        } catch (error) {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', report, { error });
        }
    }
}

import { Valuation } from '../models/reports/market-value/valuation';

export const fullTaxationRate = 0.19;
export const fullTaxationRateSecondHalfOf2020 = 0.16;
export const marginTaxationRate = 0.024;
export const marginTaxationRateSecondHalfOf2020 = 0.02;
export const neutralTaxationRate = 0;

export function getVatRateForTaxationType(taxationType: Valuation['taxationType']): 0 | 0.02 | 0.024 | 0.16 | 0.19 {
    switch (taxationType) {
        case 'full':
            return fullTaxationRate;
        case 'full_16':
            return fullTaxationRateSecondHalfOf2020;
        case 'margin':
            return marginTaxationRate;
        case 'margin_reduced':
            return marginTaxationRateSecondHalfOf2020;
        default:
            return neutralTaxationRate;
    }
}

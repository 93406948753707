import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, EventEmitter, Injector, Input, Output, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import moment from 'moment';
import { Observable, Subscription, of as observableOf } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { ensureValueIsInArray } from '@autoixpert/lib/arrays/ensure-value-is-in-array';
import { findRecordById } from '@autoixpert/lib/arrays/find-record-by-id';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { getAudatexSalesPersonForZipCode } from '@autoixpert/lib/audatex-sales/get-audatex-sales-person-for-zip-code';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import { isQapterixpertTeam } from '@autoixpert/lib/is-qapterixpert-team';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import {
    AccessRightGerman,
    translateAccessRightToGerman,
} from '@autoixpert/lib/users/translate-access-right-to-german';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { Team } from '@autoixpert/models/teams/team';
import { AccessRights, User } from '@autoixpert/models/user/user';
import { UserRegistration } from '@autoixpert/models/user/user-registration/user-registration';
import { fadeInAndOutAnimation } from 'src/app/shared/animations/fade-in-and-out.animation';
import { getAskYourAdminTooltip } from 'src/app/shared/libraries/get-ask-your-admin-tooltip';
import { ApiErrorService } from 'src/app/shared/services/api-error.service';
import { AxLicenseService } from 'src/app/shared/services/ax-license.service';
import { InvoiceService } from 'src/app/shared/services/invoice.service';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { NetworkStatusService } from 'src/app/shared/services/network-status.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { TeamService } from 'src/app/shared/services/team.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { TutorialStateService } from 'src/app/shared/services/tutorial-state.service';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';
import { UserService } from 'src/app/shared/services/user.service';
import { runChildAnimations } from '../../../shared/animations/run-child-animations.animation';
import { CostCenterPopoverComponent } from './cost-center-popover/cost-center-popover.component';

@Component({
    selector: 'team-members',
    templateUrl: './team-members.component.html',
    styleUrls: ['./team-members.component.scss'],
    animations: [fadeInAndOutAnimation(), runChildAnimations()],
})
export class TeamMembersComponent {
    constructor(
        public userPreferences: UserPreferencesService,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private teamService: TeamService,
        private toastService: ToastService,
        public dialog: MatDialog,
        private apiErrorService: ApiErrorService,
        public tutorialStateService: TutorialStateService,
        private reportService: ReportService,
        private invoiceService: InvoiceService,
        private networkStatusService: NetworkStatusService,
        private axLicenseService: AxLicenseService,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input() public team: Team;
    @Input() public user: User;

    @Output() userToAdministrateSelected = new EventEmitter<User>();

    @ViewChildren('costCenterPopoverAnchor') costCenterPopoverAnchors;
    public costCenterVisibleForTeamMembers: User[] = [];

    public teamMembers: User[] = [];
    public userRegistrations: UserRegistration[] = [];

    public teamMemberInvitationDialogShown: boolean;
    public teamMemberToReactivate: User;
    public teamMemberInInitialsEditMode: User;

    private subscriptions: Subscription[] = [];

    public userForEditInitialsDialog: User;

    async ngOnInit(): Promise<void> {
        this.registerUserWebsocketEvents();
        this.loadPendingInvitations();
        this.loadTeamMembers();
    }

    private loadPendingInvitations(): void {
        this.userService.fetchUserRegistrations(this.team._id).subscribe({
            next: (userRegistrations) => {
                this.userRegistrations = userRegistrations;
            },
            error: (error) => {
                console.error('Nutzer-Registrierungen nicht gefunden', error);
            },
        });
    }

    /**
     * Load all team members.
     */
    private async loadTeamMembers() {
        try {
            this.teamMembers = await this.userService.getAllTeamMembers(this.team, this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team-Mitglieder nicht geladen',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                },
            });
        }
    }

    public getActiveTeamMembers() {
        return (
            this.teamMembers
                .filter((user) => user.active)
                // Sort by firstName, then lastName, then email
                .sort(
                    (u1, u2) =>
                        (u1.firstName || '').localeCompare(u2.firstName || '') ||
                        (u1.lastName || '').localeCompare(u2.lastName || '') ||
                        (u1.email || '').localeCompare(u2.email || ''),
                )
        );
    }
    public getInactiveTeamMembers() {
        return this.teamMembers.filter((user) => !user.active);
    }

    //*****************************************************************************
    //  Team Members
    //****************************************************************************/
    /**
     * Whether the team members are not only an array of strings but full user objects.
     */
    public areTeamMembersPopulated(): boolean {
        return this.teamMembers.some((teamMember) => teamMember._id);
    }

    public editTeamMemberInitials(teamMember: User): void {
        this.teamMemberInInitialsEditMode = teamMember;
    }

    /**
     * Open dialog to book additional user.
     * If teamMember is provided, this teamMember will be activated.
     * Otherwise a user registration invite will be sent.
     */
    public openTeamMemberInvitationDialog(teamMember?: User): void {
        this.teamMemberToReactivate = teamMember;
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Neue Nutzer können eingeladen werden, sobald du wieder online bist.',
            );
            return;
        }
        this.teamMemberInvitationDialogShown = true;
    }

    /**
     * Hides the team member invitation dialog.
     * Clears teamMember to reactivate.
     */
    public hideTeamMemberInvitationDialog(): void {
        this.teamMemberToReactivate = null;
        this.teamMemberInvitationDialogShown = false;
    }

    public isAdmin(teamMember: User): boolean {
        if (!teamMember) return false;

        return isAdmin(teamMember._id, this.team);
    }

    /**
     * Deactivates a team member.
     *  - immediately, e.g. if fired
     *  - to the end of the current billing period (with scheduler)
     */
    public async deactivateTeamMember(teamMember: User, { instant = false }: { instant?: boolean } = {}) {
        if (!this.userIsAdmin()) {
            this.toastService.error('Nur als Admin möglich');
            return;
        }
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Du kannst Nutzer nur deaktivieren, wenn du selbst online bist.',
            );
            return;
        }

        if (isQapterixpertTeam(this.team)) {
            this.openEmailToAudatexSalesRequestingDeactivatingAUser(teamMember);
            return;
        }

        /**
         * We allow the user to use the account until we bill it the next time.
         *  - if a team is tester, he can deactivate immediately. User licenses are only billed when the team license starts.
         *  - if a team is customer, he can deactivate to his next date
         */

        let deactivateImmediately = instant;
        if (this.team.accountStatus === 'test' || this.team.accountStatus === 'partner') {
            deactivateImmediately = true;
        }

        /**
         * Only show dialog if user has not already a deactivateAt flag.
         */
        let paidUntilDate: string;
        if (!deactivateImmediately) {
            const teamLicense = await this.axLicenseService.getCurrentTeamLicense(this.team);
            if (!teamLicense) {
                this.toastService.error(
                    'Nutzer nicht deaktiviert',
                    'Es konnte keine Team-Lizenz gefunden werden. Bitte kontaktiere die Hotline.',
                );
                return;
            }
            paidUntilDate = teamLicense.endDate;
            deactivateImmediately = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Sofort deaktivieren?',
                        content: `Wenn du den Nutzer ${teamMember.firstName} ${
                            teamMember.lastName
                        } deaktivierst, kann dieser sich nicht mehr anmelden.
                   Du hast diesen Nutzer bereits bis zum ${moment(paidUntilDate).format(
                       'DD.MM.YY',
                   )} bezahlt. Willst du, dass wir den Nutzer zum ${moment(paidUntilDate).format(
                       'DD.MM.YY',
                   )} automatisch deaktivieren?`,
                        confirmLabel: 'Sofort deaktivieren',
                        cancelLabel: `zum ${moment(paidUntilDate).format('DD.MM.')} deaktivieren`,
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();
        }

        if (deactivateImmediately === true) {
            /**
             * The user is deactivated immediately.
             */
            try {
                await this.userService.deactivateUser(this.team, teamMember);
                return;
            } catch (error) {
                this.toastService.error(
                    'Nutzer nicht deaktiviert',
                    'Der Nutzer konnte nicht deaktiviert werden. Bitte versuche es erneut oder kontaktiere die Hotline.',
                );
                return;
            }
        }

        /**
         * The user will be deactivated at the end of the current billing period.
         */
        teamMember.deactivateAt = paidUntilDate;

        // Save to server
        try {
            await this.userService.put(teamMember, { waitForServer: true });
        } catch (error) {
            this.toastService.error(
                'Nutzer nicht deaktiviert',
                'Für den Nutzer konnte keine Deaktivierung vorgemerkt werden. Bitte versuche es erneut oder kontaktiere die Hotline.',
            );
        }
    }

    /**
     * ReactivateTeamMember removes the deactivateAt flag of a user if it is not already deactivated.
     * If a user is already deactivated, a new license has to be ordered (with dialog).
     */
    public async reactivateTeamMember(teamMember: User) {
        if (!this.userIsAdmin()) {
            this.toastService.offline('Nur als Admin möglich');
            return;
        }
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Du kannst Nutzer nur deaktivieren, wenn du selbst online bist.',
            );
            return;
        }

        /**
         * Team member is active but has deactivation date -> clear deactivation date.
         */
        if (teamMember.active) {
            teamMember.deactivateAt = null;
            this.userService.put(teamMember);
            return;
        }

        /**
         * Within the test period, a user can be reactivated immediately. Even if the team has already ordered autoiXpert,
         * the user will only be billed after the team becomes a customer because user licenses are only created when teh team
         * license starts.
         */
        if (this.team.accountStatus === 'test' || this.team.accountStatus === 'partner') {
            teamMember.active = true;
            this.userService.put(teamMember);
            return;
        }

        /**
         * Team member is not active open order dialog since new license is required.
         */
        this.openTeamMemberInvitationDialog(teamMember);
    }

    public acceptTeamMemberInitials(): void {
        // Prevent empty initials
        if (!this.teamMemberInInitialsEditMode.initials) {
            return;
        }

        this.saveUser(this.teamMemberInInitialsEditMode);
        this.teamMemberInInitialsEditMode = null;
    }

    //*****************************************************************************
    //  Cost Center
    //****************************************************************************/
    public isCostCenterVisible(teamMember: User): boolean {
        return !!teamMember.costCenter || this.costCenterVisibleForTeamMembers.includes(teamMember);
    }

    public async addCostCenterToTeamMember(teamMember: User): Promise<void> {
        ensureValueIsInArray(teamMember, this.costCenterVisibleForTeamMembers);
        this.openCostCenterPopover(teamMember);
    }

    public openCostCenterPopover(teamMember: User): void {
        // This component contains multiple team members. Therefore we need to find the correct anchor for the popover.
        const indexOfTeamMember = this.getActiveTeamMembers().indexOf(teamMember);
        const teamMemberAnchor = this.costCenterPopoverAnchors.toArray()[indexOfTeamMember];

        const overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(teamMemberAnchor)
                .withPositions([
                    // Below the anchor
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                ])
                .withDefaultOffsetY(15)
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        overlayRef.backdropClick().subscribe(() => {
            overlayRef.detach();
            removeFromArray(teamMember, this.costCenterVisibleForTeamMembers);
        });

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
            ],
        });

        const componentRef = overlayRef.attach(
            new ComponentPortal(CostCenterPopoverComponent, null, overlayRefInjector),
        );

        componentRef.instance.teamMember = teamMember;

        this.subscriptions.push(
            componentRef.instance.userChange.subscribe(() => {
                this.saveUser(teamMember);
            }),
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Cost Center
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Access Rights
    //****************************************************************************/
    handleAccessRightsChange(teamMember: User, event: MatSlideToggleChange & { accessRight: keyof AccessRights }) {
        switch (event.accessRight) {
            case 'seeAllReports': {
                this.handleChangeOfAccessRightSeeAllReports(teamMember);
                this.saveUser(teamMember);
                break;
            }

            case 'invoiceList': {
                this.deactivateAccessRight(teamMember.accessRights, 'seeAllInvoices', event);
                this.saveUser(teamMember);
                break;
            }

            case 'seeAllInvoices': {
                this.handleChangeOfAccessRightSeeAllInvoices(teamMember);
                this.saveUser(teamMember);
                break;
            }

            case 'seeSettings': {
                this.toggleAccessRightSettings(teamMember, event);
                this.saveUser(teamMember);
                break;
            }

            default: {
                this.saveUser(teamMember);
                break;
            }
        }
    }

    /**
     * Add or remove an admin by adding/removing his ID from the team.administrators array.
     *
     * If the admin is about to remove himself, ask him to confirm. (That's the observable stuff)
     *
     * The admin may always add or remove someone else.
     * @param teamMember
     * @param slideToggleChange
     */
    public async toggleAdmin(teamMember: User, slideToggleChange: MatSlideToggleChange): Promise<void> {
        if (this.isAdmin(teamMember)) {
            // If the user is about to remove himself as admin, ask for confirmation.
            if (teamMember._id === this.user._id) {
                const isConfirmed = await this.dialog
                    .open(ConfirmDialogComponent, {
                        data: {
                            heading: 'Dich selbst als Admin entfernen',
                            content:
                                'Anschließend kannst du keine Zugriffsrechte mehr bearbeiten, inklusive deiner eigenen.',
                            confirmLabel: 'Ich weiß, was ich tue',
                            cancelLabel: 'Lieber nicht',
                            confirmColorRed: true,
                        },
                    })
                    .afterClosed()
                    .toPromise();

                if (!isConfirmed) {
                    // The slide toggle is always moved. Reset it if the user decided to abort the operation
                    slideToggleChange.source.toggle();
                    return;
                }
            }

            this.team.administrators.splice(this.team.administrators.indexOf(teamMember._id), 1);
            void this.saveTeam();
        }
        // Add someone as admin
        else {
            this.team.administrators.push(teamMember._id);
            void this.saveTeam();
        }
    }

    public deactivateAccessRight(
        accessRights: AccessRights,
        accessRight: keyof User['accessRights'],
        valueOfPartnerRight: MatSlideToggleChange,
    ): void {
        // Don't deactivate if the partner right (the right just changed) is turned on.
        if (valueOfPartnerRight.checked) return;

        accessRights[accessRight] = false;
    }

    /**
     * Deactivating the access right to see all reports should remove locally all reports to be later fetched from the server which knows which report is eligibile to be shown.
     */
    public async handleChangeOfAccessRightSeeAllReports(teamMember: User) {
        // Only react to change if the logged-in user's access right has been changed.
        if (this.user !== teamMember) return;

        // If the access right has been activated, we don't need to clear reports because that actually increases the accessible reports.
        if (teamMember.accessRights.seeAllReports) return;

        let reportSyncOutstanding: boolean;
        try {
            reportSyncOutstanding = await this.reportService.hasOutstandingSyncs();
        } catch (error) {
            console.error(
                'Error determining outstanding syncs. It is safer to consider changes to be present so the user may decide what to do.',
                { error },
            );
            reportSyncOutstanding = true;
        }

        // Are there pending changes needing to be synced? Let the user confirm their removal.
        if (reportSyncOutstanding) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    heading: 'Synchronisierung ausstehend',
                    content:
                        'Es gibt Daten, die noch nicht zum Server geschickt wurden. Beim Ändern der Zugriffsrechte gehen sie verloren. Was möchtest du tun?',
                    confirmLabel: 'Daten lokal entfernen',
                    cancelLabel: "Ich überleg's mir nochmal.",
                    confirmColorRed: true,
                },
            });
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) {
                    await this.reportService.clearDatabase();
                } else {
                    teamMember.accessRights.seeAllReports = true;
                    await this.saveUser(teamMember);
                }
            });
            return;
        } else {
            // No outstanding sync -> Remove without question.
            await this.reportService.clearDatabase();
        }
    }

    /**
     * Deactivating the access right to see all invoices should remove locally all invoices to re-fetch them later with the right access rights on the server.
     */
    public async handleChangeOfAccessRightSeeAllInvoices(teamMember: User) {
        // Only react to change if the logged-in user's access right has been changed.
        if (this.user !== teamMember) return;

        // If the access right has been activated, we don't need to clear invoices because that actually increases the accessible invoices.
        if (teamMember.accessRights.seeAllInvoices) return;

        let invoiceSyncOutstanding: boolean;
        try {
            invoiceSyncOutstanding = await this.invoiceService.hasOutstandingSyncs();
        } catch (error) {
            console.error(
                'Error determining outstanding syncs. It is safter to consider changes to be present so the user may decide what to do.',
                { error },
            );
            invoiceSyncOutstanding = true;
        }

        // Are there pending changes needing to be synced? Let the user confirm their removal.
        if (invoiceSyncOutstanding) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    heading: 'Synchronisierung ausstehend',
                    content:
                        'Es gibt Daten, die noch nicht zum Server geschickt wurden. Beim Ändern der Zugriffsrechte gehen sie verloren. Was möchtest du tun?',
                    confirmLabel: 'Daten lokal entfernen',
                    cancelLabel: "Ich überleg's mir nochmal.",
                    confirmColorRed: true,
                },
            });
            dialogRef.afterClosed().subscribe(async (result) => {
                if (result) {
                    await this.invoiceService.clearDatabase();
                } else {
                    teamMember.accessRights.seeAllInvoices = true;
                    await this.saveUser(teamMember);
                }
            });
            return;
        } else {
            // No outstanding sync -> Remove without question.
            await this.invoiceService.clearDatabase();
        }
    }

    /**
     * If the user is about to deactivate the settings for himself, ask him to confirm.
     *
     * Prevent the last person from removing their access to settings.
     */
    public async toggleAccessRightSettings(teamMember: User, slideToggleChange: MatSlideToggleChange): Promise<void> {
        if (!slideToggleChange.checked) {
            // Prevent removing the last settings access right.
            const numberOfUsersWithSettingsAccessRight: number = this.teamMembers.filter(
                (teamMember) => teamMember.accessRights.seeSettings,
            ).length;
            if (numberOfUsersWithSettingsAccessRight < 2) {
                this.toastService.info(
                    'Letzter Nutzer mit Einstellungen',
                    'Damit dein Team seine Einstellungen noch bearbeiten kann, muss mindestens ein Nutzer dieses Zugriffsrecht behalten.',
                );

                // The slide toggle is always moved. Reset it if the user decided to abort the operation.
                // SetTimeout is necessary because otherwise, the internal `_checked` property of the slide toggle won't be toggled properly,
                // leading to slideToggleChange.checked having the wrong value on the second click.
                setTimeout(() => slideToggleChange.source.toggle(), 0);
                return;
            }

            // Remove access right for the currently signed-in user.
            if (teamMember._id === this.user._id) {
                const blockSettingsForSelf = await this.dialog
                    .open(ConfirmDialogComponent, {
                        data: {
                            heading: 'Zugriff auf Einstellungen für dich selbst blockieren?',
                            content: 'Anschließend kannst du keine Einstellungen mehr bearbeiten.',
                            confirmLabel: 'Ich weiß, was ich tue',
                            cancelLabel: 'Lieber nicht',
                            confirmColorRed: true,
                        },
                    })
                    .afterClosed()
                    .toPromise();

                if (!blockSettingsForSelf) {
                    // The slide toggle is always moved. Reset it if the user decided to abort the operation
                    setTimeout(() => slideToggleChange.source.toggle(), 0);
                    return;
                }
            }
        }

        teamMember.accessRights.seeSettings = !teamMember.accessRights.seeSettings;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Access Rights
    /////////////////////////////////////////////////////////////////////////////*/

    private registerUserWebsocketEvents(): void {
        // Create
        const createdSubscription = this.userService.createdFromExternalServerOrLocalBroadcast$.subscribe({
            next: (createdRecord) => {
                this.teamMembers.push(createdRecord);
            },
        });

        // Patch
        const patchedSubscription = this.userService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
            next: (patchedEvent: PatchedEvent<User>) => {
                const targetUser = findRecordById(this.teamMembers, patchedEvent.patchedRecord._id);

                if (targetUser) {
                    applyOfflineSyncPatchEventToLocalRecord({
                        localRecord: targetUser,
                        patchedEvent,
                    });
                }
            },
        });

        // Delete
        const deletedSubscription = this.userService.deletedInLocalDatabase$.subscribe({
            next: (deletedRecordId) => {
                const targetUser = this.teamMembers.find((teamMember) => deletedRecordId === teamMember._id);

                removeFromArray(targetUser, this.teamMembers);
            },
        });

        this.subscriptions.push(createdSubscription, patchedSubscription, deletedSubscription);
    }

    //*****************************************************************************
    //  Pending Invitations
    //****************************************************************************/
    public get pendingInvitations(): UserRegistration[] {
        const emailAddressesOfExistingUsers: string[] = this.teamMembers.map((member) => member.email);

        // Pending invitations must
        // - not contain a password. The ones that do, are not pending any more.
        // - not match any existing user's e-mail address. We cannot solely rely on the email match because as soon as a user is deleted, settled invitations would then be considered pending.
        return this.userRegistrations.filter(
            (registration) =>
                !registration.password && !emailAddressesOfExistingUsers.includes(registration.contactPerson.email),
        );
    }

    protected handlePendingInvitationAccessRightsChange(
        pendingInvitation: UserRegistration,
        event: MatSlideToggleChange & { accessRight: keyof AccessRights },
    ): void {
        switch (event.accessRight) {
            case 'invoiceList': {
                this.deactivateAccessRight(pendingInvitation.futureAccessRights, 'seeAllInvoices', event);
                this.saveUserRegistration(pendingInvitation);
                break;
            }

            default: {
                this.saveUserRegistration(pendingInvitation);
                break;
            }
        }
    }

    public addPendingInvitation(userRegistration: UserRegistration): void {
        this.userRegistrations.push(userRegistration);

        // Reload user registrations to have access to email verification token
        this.loadPendingInvitations();
    }

    /**
     * A new user's access rights may be configured on the UserRegistration before the User object is created.
     * @param userRegistration
     */
    public saveUserRegistration(userRegistration: UserRegistration): void {
        this.userService.patchUserRegistration(this.team._id, userRegistration).subscribe({
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'UserRegistration nicht aktualisiert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    public removeUserRegistration(userRegistration: UserRegistration): void {
        const index: number = this.userRegistrations.indexOf(userRegistration);

        this.userRegistrations.splice(index, 1);

        this.userService.revokeInvitation(this.team._id, userRegistration.contactPerson.email).subscribe({
            error: (error) => {
                // Add the registration back into the view if it could not be deleted
                this.userRegistrations.splice(index, 0, userRegistration);

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        USER_REGISTRATION_MISSING: {
                            title: 'Einladung nicht gefunden',
                            body: 'Bitte kontaktiere die Hotline.',
                        },
                        USER_REGISTRATION_ALREADY_FINISHED: {
                            title: 'Einladung bereits abgeschlossen',
                            body: 'Bitte aktualisiere die Seite und entferne dann den vollständigen Nutzer, falls du ihn nicht in deinem Team haben möchtest.',
                        },
                    },
                    defaultHandler: {
                        title: 'Einladung nicht widerrufen',
                        body: 'Bitte kontaktiere die Hotline.',
                    },
                });
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Pending Invitations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Request Additional User From Audatex Sales
    //****************************************************************************/

    /**
     * Send contact details to Audatex to ask for an additional user.
     */
    protected openEmailToAudatexSalesRequestingAdditionalUser() {
        const user: User = this.user;
        const team: Team = this.team;

        const contactData = `Meine Kontaktdaten:
${user.organization || ''}
${user.firstName} ${user.lastName}
${team.billing.address.streetAndHouseNumberOrLockbox}
${team.billing.address.zip} ${team.billing.address.city}
${user.phone || 'Telefon: '}
${user.email}`;

        const subject: string = `Zusatznutzer für Qapter-iXpert - ${team.billing.address.organization}`;
        const salesPerson = getAudatexSalesPersonForZipCode(team.billing.address.zip);

        if (!salesPerson) {
            this.toastService.error('Vertriebler nicht auffindbar', 'Stimmt die Postleitzahl?');
            return;
        }

        const toRecipientString: string = salesPerson.email;
        const ccRecipientString = 'backoffice@ax-ao.de';

        const message: string = `Hallo ${salesPerson.gender === 'female' ? 'Frau' : 'Herr'} ${
            salesPerson.lastName ?? 'XXX'
        },

ich interessiere mich für einen Zusatznutzer in Qapter-iXpert.

${contactData}

Bitte fügen Sie einen Nutzer hinzu für:

E-Mail: 
Vor- & Nachname:

Mit freundlichen Grüßen
${user.firstName} ${user.lastName}`;

        const link = `mailto:${toRecipientString}?subject=${encodeURIComponent(
            subject,
        )}&cc=${ccRecipientString}&body=${encodeURIComponent(message)}`;
        window.open(link);
    }
    /**
     * Send contact details to Audatex to ask to cancel a user.
     */
    protected openEmailToAudatexSalesRequestingDeactivatingAUser(userToDeactivate: User) {
        const admin: User = this.user;
        const team: Team = this.team;

        const contactData = `Meine Kontaktdaten:
${admin.organization || ''}
${admin.firstName} ${admin.lastName}
${team.billing.address.streetAndHouseNumberOrLockbox}
${team.billing.address.zip} ${team.billing.address.city}
${admin.phone || 'Telefon: '}
${admin.email}`;

        const subject: string = `Zusatznutzer in Qapter-iXpert deaktivieren - ${team.billing.address.organization}`;
        const salesPerson = getAudatexSalesPersonForZipCode(team.billing.address.zip);

        if (!salesPerson) {
            this.toastService.error('Vertriebler nicht auffindbar', 'Stimmt die Postleitzahl?');
            return;
        }

        const toRecipientString: string = salesPerson.email;
        const ccRecipientString = 'backoffice@ax-ao.de';

        const message: string = `Hallo ${salesPerson.gender === 'female' ? 'Frau' : 'Herr'} ${
            salesPerson.lastName ?? 'XXX'
        },

bitte deaktivieren Sie den Nutzer

${userToDeactivate.firstName} ${userToDeactivate.lastName} (${userToDeactivate.email})

zum nächstmöglichen Zeitpunkt.

${contactData}

Bitte bestätigen Sie mir die Deaktivierung kurz schriftlich. Vielen Dank.

Mit freundlichen Grüßen
${admin.firstName} ${admin.lastName}`;

        const link = `mailto:${toRecipientString}?subject=${encodeURIComponent(
            subject,
        )}&cc=${ccRecipientString}&body=${encodeURIComponent(message)}`;
        window.open(link);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Request Additional User From Audatex Sales
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team Members
    /////////////////////////////////////////////////////////////////////////////*/

    public userIsAdmin(): boolean {
        return this.isAdmin(this.user);
    }

    public async saveUser(user: User = this.user, { waitForServer }: { waitForServer?: boolean } = {}): Promise<void> {
        // If the logged-in user has been updated, propagate those changes to all components.
        if (user === this.user) {
            this.loggedInUserService.setUser(user);
        }
        try {
            await this.userService.put(user, { waitForServer });
        } catch (error) {
            this.toastService.error('Fehler beim Speichern');
        }
    }

    public getAskYourAdminTooltip(): string {
        return getAskYourAdminTooltip(this.team, this.teamMembers);
    }

    public isQapterixpert() {
        return isQapterixpert();
    }

    public isQapterixpertTeam() {
        return isQapterixpertTeam(this.team);
    }

    //*****************************************************************************
    //  Destroy
    //****************************************************************************/
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Destroy
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save
    //****************************************************************************/
    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, defer } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { ToastService } from '../../../shared/services/toast.service';
import { PhotoGridComponent, PhotoGroupName } from '../shared/photos-grid/photo-grid.component';

@Component({
    selector: 'report-photos',
    templateUrl: 'photos.component.html',
    styleUrls: ['photos.component.scss'],
})
export class PhotosComponent {
    constructor(
        private toastService: ToastService,
        private reportDetailsService: ReportDetailsService,
        private route: ActivatedRoute,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        private networkStatusService: NetworkStatusService,
    ) {}

    public reportId: string;
    public report: Report;

    @ViewChild('photoGridComponent') photoGridComponent: PhotoGridComponent;

    // Photo Editor
    photoEditorShown: boolean = false;
    initialPhotoForEditor: Photo;
    photoGroupForEditor: PhotoGroupName;

    // Car Registration Scanner
    public photoForCarRegistrationScanner: Photo;
    carRegistrationScannerDialogShown: boolean = true;

    private subscriptions: Subscription[] = [];
    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.reportId = this.route.parent.snapshot.params['reportId'];

        const reportServiceSubscription = this.reportDetailsService.get(this.reportId).subscribe({
            next: (report) => {
                this.report = report;
                this.joinAsRealtimeEditor();
            },
        });

        this.subscriptions.push(reportServiceSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Registration Recognition
    //****************************************************************************/

    public selectPhotoForCarRegistrationScanner(photo: Photo): void {
        // The offline message will be shown in the method showCarRegistrationScannerDialog(), so no need to show a toast here.
        if (!this.networkStatusService.isOnline()) {
            return;
        }

        this.photoForCarRegistrationScanner = photo;
    }

    public showCarRegistrationScannerDialog(): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Fahrzeugscheine können ausgelesen werden, sobald du wieder online bist.',
            );
            return;
        }

        this.carRegistrationScannerDialogShown = true;
    }

    public hideCarRegistrationScannerDialog(): void {
        this.carRegistrationScannerDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Registration Recognition
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Editor
    //****************************************************************************/
    public setupPhotoEditor({ photo, photoGroup }: { photo: Photo; photoGroup: PhotoGroupName }): void {
        this.initialPhotoForEditor = photo;
        this.photoGroupForEditor = photoGroup;
    }

    public openPhotoEditor(): void {
        this.photoEditorShown = true;
    }

    public closeEditor(): void {
        this.photoEditorShown = false;
        this.photoGridComponent.reloadChangedPhotoThumbnails();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Editor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Lease Return
    //****************************************************************************/

    /**
     * When deleting a photo in this view, make sure its removed from any associated
     * lease return items as well.
     * @param deletedPhotos
     */
    public removePhotosFromLeaseReturnItems(deletedPhotos: Photo[]): void {
        const allowedReportTypes = new Array<Report['type']>('leaseReturn', 'usedVehicleCheck');
        if (!allowedReportTypes.includes(this.report.type)) return;

        let changesMade: boolean;
        // In every section...
        if (this.report.leaseReturn) {
            for (const section of this.report.leaseReturn.sections) {
                // ... and in every item...
                for (const item of section.items) {
                    // ...for each deleted photo, remove all IDs from the item's photoIds array.
                    for (const deletedPhoto of deletedPhotos) {
                        const index = item.photoIds.indexOf(deletedPhoto._id);
                        if (index > -1) {
                            item.photoIds.splice(index, 1);
                            changesMade = true;
                        }
                    }
                }
            }
        }

        // Save back to server
        if (changesMade) {
            this.saveReport();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lease Return
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab: 'photos',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Helpers
    //****************************************************************************/

    public isReportLocked(): boolean {
        return this.report?.state === 'done';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Helpers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public saveReport$: Observable<Report> = defer(() => {
        return fromPromise(this.reportDetailsService.patch(this.report));
    });

    public async saveReport() {
        return this.reportDetailsService.patch(this.report);
    }

    public async handlePhotosChange({
        photos,
        callback,
    }: {
        callback?: (photos: Photo[]) => Promise<void>;
        photos: Photo[];
    }) {
        if (!callback) {
            return this.saveReport();
        }

        await this.reportDetailsService.saveReport(this.report, { waitForServer: true });
        await callback(photos);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Component deactivation (navigate away)
    //****************************************************************************/
    async canDeactivate() {
        return await this.photoGridComponent.canDeactivate();
    }

    async forceDeactivate() {
        await this.photoGridComponent.forceDeactivate();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Component deactivation (navigate away)
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}

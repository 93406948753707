import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InternalNote } from '@autoixpert/models/internal-note';
import { Task } from '@autoixpert/models/tasks/task';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';

@Component({
    selector: 'task-details-comments',
    templateUrl: './task-details-comments.component.html',
    styleUrls: ['./task-details-comments.component.scss'],
})
export class TaskDetailsCommentsComponent {
    constructor(private loggedInUserService: LoggedInUserService) {}

    @Input() task: Task;
    @Output('taskChanged') taskChanged: EventEmitter<Task> = new EventEmitter<Task>();

    newNote: InternalNote;
    user: User;

    async ngOnInit() {
        this.user = await this.loggedInUserService.getUser();
    }

    addNote() {
        this.newNote = new InternalNote({
            createdBy: this.user._id,
        });
        this.task.notes.push(this.newNote);
    }

    deleteNote(note: InternalNote) {
        const indexToDelete = this.task.notes.indexOf(note);
        this.task.notes.splice(indexToDelete, 1);
        this.emitTaskChange();
    }

    emitTaskChange() {
        this.taskChanged.emit(this.task);
    }
}

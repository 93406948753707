import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { isAudatexTestAccount } from '@autoixpert/lib/users/is-audatex-test-account';
import { User } from '@autoixpert/models/user/user';
import { slideOutVertical } from '../../../../shared/animations/slide-out-vertical.animation';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';

@Component({
    selector: 'audatex-calculation-insertion-dialog',
    templateUrl: 'audatex-calculation-insertion-dialog.component.html',
    styleUrls: ['audatex-calculation-insertion-dialog.component.scss'],
    animations: [slideOutVertical()],
})
export class AudatexCalculationInsertionDialog {
    constructor(private loggedInUserService: LoggedInUserService) {}

    @Input() calculationText: string;
    private originalCalculationText: string;

    @Output() calculationTextChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    private user: User;

    public duplexInterfaceNoteVisible: boolean = true;

    ngOnInit() {
        this.originalCalculationText = this.calculationText;
        this.user = this.loggedInUserService.getUser();
    }

    public isAudatexTestAccount() {
        return isAudatexTestAccount(this.user.audatexUser);
    }

    public emitCalculationChange(): void {
        if (this.originalCalculationText === this.calculationText) return;

        this.calculationTextChange.emit(this.calculationText);
    }

    public closeDialog(): void {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
            case 'Enter':
                if (event.ctrlKey || event.altKey) {
                    this.emitCalculationChange();
                    this.closeDialog();
                }
                break;
        }
    }
}

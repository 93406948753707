import { DateTime } from 'luxon';
import { Subtype } from '../../helper-types/subtype';
import { DocumentTypeGroupName } from '../../lib/documents/get-document-type-groups-from-document-order-configs';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { ReportType, reportTypes } from '../reports/report';
import { DocumentBuildingBlock } from './document-building-block';
import { RenderedDocumentType, renderedDocumentTypes } from './document-metadata';

export type DocumentTemplateType = ReportType | Exclude<RenderedDocumentType, 'report'>;

/**
 * This is the *template* document order config that's required for creating the DOCX templates which are again required
 * to render DOCX and PDF documents like the report or the invoice.
 */
export class DocumentOrderConfig implements DataTypeBase {
    constructor(options: Partial<DocumentOrderConfig>) {
        Object.assign(this, options);

        if (!this._id) {
            this._id = generateId();
        }
    }

    _id: string = null;
    type: DocumentTemplateType = null;
    /**
     * The title is displayed in the document order selection in the building block editor and in the
     * signature cards in the accident data screen.
     */
    titleShort: string;
    /**
     * This title is used for document order items in the print and transmission screen.
     */
    titleLong: string;

    /**
     * Only relevant if the type is "customDocument".
     *
     * Custom documents are used to create either
     * - a new report attachment such as an info sheet for an attorney or the author of the damage for his insurance.
     * - a signable document such as an order form for having the car repaired in a specific garage.
     *   Signable documents always exist as a regular document order config which can create a rendered document. But
     *   they can be overwritten through a signable PDF document.
     */
    customDocumentConfig?: {
        /**
         * Internal notes to help the assessor understand/remember the purpose of the document.
         * This will be printed in the tooltip of the document.
         */
        description: string;
        /**
         * The document is only available in the selected report types. Some documents such as a garage order form
         * are only relevant in damage reports and should not be added to valuation reports.
         */
        availableInReportTypes: ReportType[];
        /**
         * If not created on report creation, the assessor may create the document though the push of a custom
         * button (report attachment) or through activating the signable customer document.
         */
        isCreatedOnReportCreation: boolean;
        /**
         * We decided that there are only use cases for report attachments, signable documents and totally
         * custom report documents, e. g. a liability report for land machines. But totally custom report
         * documents are a lot of work to implement since they need a configurable user interface. Thus,
         * we only implemented report attachments and signable documents.
         */
        documentTypeGroup: Subtype<DocumentTypeGroupName, 'reportAttachment' | 'signature'>;
        /**
         * Example: 'handyman' for a hammer and a screwdriver which symbolize the garage in case the
         * assessor also lets the customer sign the garage order (German "Werkstattauftrag").
         */
        googleMaterialIconName: string;
    };
    documentBuildingBlockIds: DocumentBuildingBlock['_id'][] = [];
    createdBy: string = null;
    teamId: string = null;
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    updatedAt: string = DateTime.now().setZone('UTC').toISO();

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

export const documentTemplateTypes: DocumentTemplateType[] = [
    ...reportTypes,
    ...(renderedDocumentTypes.filter((renderedDocumentType) => renderedDocumentType !== 'report') as any as Exclude<
        RenderedDocumentType,
        'report'
    >[]),
];

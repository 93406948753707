import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { AudatexTaskListItem } from '@autoixpert/models/reports/damage-calculation/audatex-task-list-item';
import { Report } from '@autoixpert/models/reports/report';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { capitalize } from '../../../../shared/libraries/text-transformation/capitalize';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { AudatexTaskService } from '../../../../shared/services/audatex/audatex-task.service';

@Component({
    selector: 'connect-audatex-task-dialog',
    templateUrl: 'connect-audatex-task-dialog.component.html',
    styleUrls: ['connect-audatex-task-dialog.component.scss'],
    animations: [fadeInAndSlideAnimation(), dialogEnterAndLeaveAnimation()],
})
export class ConnectAudatexTaskDialogComponent implements OnInit {
    constructor(
        private dialog: MatDialogRef<ConnectAudatexTaskDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConnectAudatexTaskDossierDialogData,
        private audatexTaskService: AudatexTaskService,
        private apiErrorService: ApiErrorService,
    ) {}

    public audatexTasks: AudatexTaskListItem[] = [];
    public audatexTasksPending: boolean;
    public selectedAudatexTask: AudatexTaskListItem;

    public customAudatexTaskId: string;
    public audatexTaskIdInputShown: boolean;

    @Input() licensePlate: string;
    @Input() vin: string;

    @Output() close: EventEmitter<Report['audatexTaskId']> = new EventEmitter();

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.licensePlate = this.data.licensePlate;
        this.vin = this.data.vin;

        this.loadAudatexTasks();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Select Audatex Task
    //****************************************************************************/
    public selectAudatexTask(audatexTask: AudatexTaskListItem): void {
        this.selectedAudatexTask = audatexTask;
    }

    private preselectAudatexTaskWithMatchingVehicle(): void {
        // Match by license plate
        if (this.licensePlate) {
            const matchingAudatexTask = this.audatexTasks.find(
                (audatexTask) => audatexTask.licensePlate === this.licensePlate,
            );
            if (matchingAudatexTask) {
                this.selectAudatexTask(matchingAudatexTask);
                return;
            }
        }

        // Match by VIN
        if (this.vin) {
            const matchingAudatexTask = this.audatexTasks.find((audatexTask) => audatexTask.vin === this.vin);
            if (matchingAudatexTask) {
                this.selectAudatexTask(matchingAudatexTask);
                return;
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select Audatex Task
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Audatex Tasks
    //****************************************************************************/
    private async loadAudatexTasks(): Promise<void> {
        this.audatexTasksPending = true;

        try {
            this.audatexTasks = await this.audatexTaskService.findAll();
            this.audatexTasksPending = false;
            this.preselectAudatexTaskWithMatchingVehicle();
        } catch (error) {
            this.audatexTasksPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                //handlers       : {
                //    ...getAudatexErrorHandlers()
                //},
                defaultHandler: {
                    title: 'Audatex-Vorgänge konnten nicht geladen werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Audatex Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Brand Icons
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date & Time
    //****************************************************************************/
    public capitalize(text: string): string {
        return capitalize(text);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date & Time
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Dossier ID
    //****************************************************************************/
    public showAudatexTaskIdInput(): void {
        // Clear the selection to indicate to the user which dossier will be used.
        this.selectedAudatexTask = null;
        this.audatexTaskIdInputShown = true;
    }

    public hideAudatexTaskIdInput(): void {
        this.audatexTaskIdInputShown = false;
        this.customAudatexTaskId = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Dossier ID
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  License Plate
    //****************************************************************************/
    /**
     * Audatex format: "UL MP 845"
     * autoiXpert format: "UL-MP-845"
     * @param licensePlateAudatexFormat
     */
    public getLicensePlateInAxFormat(licensePlateAudatexFormat: string): string {
        if (!licensePlateAudatexFormat) return;

        return licensePlateAudatexFormat.replace(/\s/g, '-');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END License Plate
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Connection
    //****************************************************************************/
    public handleConnectionButtonClick(): void {
        if (this.selectedAudatexTask || this.customAudatexTaskId) {
            this.emitAudatexTaskSelection();
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Connection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/
    public emitAudatexTaskSelection(): void {
        let audatexTaskId: Report['audatexTaskId'];
        if (this.audatexTaskIdInputShown) {
            audatexTaskId = this.customAudatexTaskId;
        } else {
            audatexTaskId = this.selectedAudatexTask.audatexTaskId;
        }
        this.dialog.close(audatexTaskId);
    }

    public closeDialog(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface ConnectAudatexTaskDossierDialogData {
    licensePlate: string;
    vin: string;
}

import { ObjectDifference } from './get-list-of-differences';

/**
 * Conflicts exist if...
 * - The key is the same
 * - the new values differ (falsy values [null, undefined, empty string] are treated as equal)
 *
 * Non-conflicting = All changes that don't count as conflicting. If there are no conflicting changes, all changes are non-conflicting.
 */
export function filterConflictingChanges(
    localChanges: ObjectDifference[],
    remoteChanges: ObjectDifference[],
): { conflictingChanges: ObjectDifference[]; nonConflictingChanges: ObjectDifference[] } {
    const conflictingChanges = remoteChanges.filter((remoteChange) =>
        localChanges.find((localChange) => {
            const keysAreEqual: boolean = localChange.key === remoteChange.key;
            const bothNewValuesAreFalsy: boolean = !localChange.newValue && !remoteChange.newValue;
            let newValuesAreEqual: boolean;
            if (bothNewValuesAreFalsy) {
                newValuesAreEqual = true;
            } else if (Array.isArray(localChange.newValue)) {
                /**
                 * When comparing arrays, their new values are equal if their JSON representation is equal.
                 * Objects can't reach this point because they would have been flattened in `getListOfDifferences`.
                 */
                newValuesAreEqual = JSON.stringify(localChange.newValue) === JSON.stringify(remoteChange.newValue);
            }
            // Primitives may be compared directly.
            else {
                newValuesAreEqual = localChange.newValue === remoteChange.newValue;
            }

            return keysAreEqual && !newValuesAreEqual;
        }),
    );

    const nonConflictingChanges = remoteChanges.filter(
        (remoteChange) => !conflictingChanges.find((conflictingChange) => remoteChange.key === conflictingChange.key),
    );

    return {
        conflictingChanges,
        nonConflictingChanges,
    };
}

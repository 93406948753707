import { Directive, ElementRef, HostBinding, Input, OnInit, ViewContainerRef } from '@angular/core';
import {
    AugmentedDataIndicatorComponent,
    CssNumberOrNumberWithUnit,
} from '../components/augmented-data-indicator/augmented-data-indicator.component';

/**
 * A wand icon and vertical line that indicate that the data next to it has been automatically
 * determined by the software. The indicator is positioned absolute to the right outside of the host of this directive.
 * It spans the whole height from the top to the bottom of the host.
 */
@Directive({
    selector: '[augmentedDataIndicator]',
})
export class AugmentedDataIndicatorDirective implements OnInit {
    constructor(
        private element: ElementRef,
        private viewContainerRef: ViewContainerRef,
    ) {}

    /**
     * Makes the absolute position of the AugmentedDataIndicatorComponent work.
     */
    @HostBinding('style.position') hostPosition: string = 'relative';

    /**
     * Optional offset to the top. Useful if the vertical line should not reach
     * or surpass the top of the parent.
     */
    @Input() indicatorOffsetTop: CssNumberOrNumberWithUnit = 0;

    /**
     * Optional offset to the bottom. Useful if the vertical line should not reach
     * the bottom of the parent.
     */
    @Input() indicatorOffsetBottom: CssNumberOrNumberWithUnit = 0;

    /**
     * Tooltip shown when the user hovers the wand icon.
     */
    @Input() indicatorTooltip: string = '';

    ngOnInit() {
        // dynamically create the component
        const componentRef = this.viewContainerRef.createComponent(AugmentedDataIndicatorComponent);

        // pass inputs (only works for initial bindings)
        componentRef.instance.offsetBottom = this.indicatorOffsetBottom;
        componentRef.instance.offsetTop = this.indicatorOffsetTop;
        componentRef.instance.tooltip = this.indicatorTooltip;

        // attach it to the host element
        const host = this.element.nativeElement;
        host.insertBefore(componentRef.location.nativeElement, host.firstChild);
    }
}

import { Car } from '@autoixpert/models/reports/car-identification/car';
import { User } from '@autoixpert/models/user/user';
import { isAudatexUserComplete } from './is-audatex-user-complete';
import { isDatUserComplete } from './is-dat-user-complete';
import { isGtmotiveUserComplete } from './is-gtmotive-user-complete';

export function areCredentialsForCarIdentificationProviderComplete(
    calculationProvider: Car['identificationProvider'],
    user: User,
): boolean {
    if (!calculationProvider) {
        return undefined;
    }

    switch (calculationProvider) {
        case 'dat':
            return isDatUserComplete(user);
        case 'audatex':
            return isAudatexUserComplete(user);
        case 'gtmotive':
            return isGtmotiveUserComplete(user);
    }
}

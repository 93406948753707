import { CommunicationRecipient } from './communication-recipient';

export class Claimant extends CommunicationRecipient {
    constructor(template?: Partial<Claimant>) {
        super('claimant');

        if (template) {
            Object.assign(this, template);
        }
    }

    isOwner: boolean = true; // Often, the claimant may also be the owner of the car
    representedByLawyer: boolean = false; // Does the claimant use a lawyer with whom we communicate also?#9
}

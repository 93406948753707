<div id="forgot-password-card">
    <div id="head-container">
        <h1 id="container-headline">Passwort zurücksetzen</h1>
    </div>
    <!--============================================-->
    <!-- Request Reset Email
    <!-============================================-->
    <form *ngIf="!emailRequestSubmitted">
        <div id="email-container">
            <mat-form-field class="mat-block">
                <input matInput type="email" placeholder="E-Mail/Nutzername" [(ngModel)]="email" name="email" />
            </mat-form-field>
        </div>
        <div id="button-container" *ngIf="!emailRequestSubmitted">
            <button type="submit" class="button" (click)="requestPasswordReset()">Zurücksetzen</button>
        </div>
    </form>

    <div id="response-container" *ngIf="emailRequestSubmitted">
        <div id="success-message-container">
            <h2>E-Mail gesendet!</h2>
            Wir haben Anweisungen zum Rücksetzen deines Passworts an das Postfach
            <strong>{{ email }}</strong>
            gesendet.
        </div>
    </div>
    <!--============================================-->
    <!-- END Request Reset Email
    <!-============================================-->

    <div id="footer-container">
        <a id="cancel-forgot-password" (click)="navigateToLogin()" href="javascript: void(0);">Login</a>
    </div>
</div>

import { DateTime } from 'luxon';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { DocumentBuildingBlockCondition } from './document-building-block-condition';
import {
    DocumentBuildingBlockConditionGroup,
    DocumentBuildingBlockConditionGroupOperator,
} from './document-building-block-condition-group';
import { DocumentBuildingBlockVariant } from './document-building-block-variant';

export class DocumentBuildingBlock implements DataTypeBase, DocumentBuildingBlockConditionGroup {
    constructor(options: Partial<DocumentBuildingBlock> = {}) {
        Object.assign(this, options);
    }

    _id: string = generateId();
    /**
     * Default document building blocks have an explicit placeholder. Custom blocks have a title only.
     * With this placeholder, we can keep the standard document building block up-to-date since it can be referenced
     * in migrations.
     */
    placeholder: string = null;
    title: string = null;
    variants: DocumentBuildingBlockVariant[] = [];
    /** True if the user created the block himself (not included in aX standard)  */
    custom?: boolean = null;
    isSectionHeading?: boolean;
    customPartialHash?: string;
    config: DocumentBuildingBlockConfig = new DocumentBuildingBlockConfig();
    createdBy: string = null;
    teamId: string = null;

    // Overarching conditions that are checked for all variants
    conditionsOperator: DocumentBuildingBlockConditionGroupOperator = 'and';
    conditions: Array<DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup> = [];

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();

    _documentVersion: number = 0;
    _schemaVersion = 2 as const;
}

/**
 * Config contains all possible settings for all building blocks.
 * Usually, a block only has one or a few of these settings set.
 */
export class DocumentBuildingBlockConfig {
    // General (apply to all building blocks)
    pageBreakBefore?: boolean; // Add a DOCX flag <w:pageBreakBefore/> to the first paragraph of this document building block.
    pageBreakAfter?: boolean; // Add a DOCX page break (<w:br type="page">) to the end of the last paragraph of this document building block.
    keepNextParagraphConnected?: boolean; // Add "keepNext" property to paragraph in DOCX file to prevent a page break within the building block.
    excludeFromReportPageCount?: boolean; // Report document pages may be counted as part of the invoice.

    // Photo Attachment
    photoHeight?: number; // Determine the photo height in the photo attachment within DOCX files.
    photoCompression?: number; // Set the JPEG compression level. Number between 0 (high quality) and 100 (low quality). Default is 30.
    hidePhotoNumbers?: boolean; // Set to true to remove the heading before each photo.
    // Title Page
    showOrganizationInsteadOfPersonOnTitlePage?: boolean; // For people with a foreign name, it might be interesting to show the organization on the title page instead of their first and last name.
    transparentBackgroundOfClaimantNameOnTitlePage?: boolean; // In the standard, the box containing the claimant's name is colored. You may opt in to a transparent background though.

    // Involved Parties
    showClaimantIban?: boolean; // Show the claimant's bank account number (IBAN) in the participants table (Beteiligte).
    showClaimantPhoneAndEmail?: boolean; // Show the phone number and email address in the participants table (Beteiligte).
    showLawyerPhoneAndEmail?: boolean; // Show the phone number and email address in the participants table (Beteiligte).
    showInsurancePhoneAndEmail?: boolean; // Show the phone number and email address in the participants table (Beteiligte).
    showGaragePhoneAndEmail?: boolean; // Show the phone number and email address in the participants table (Beteiligte).
    showAuthorOfDamage?: boolean; // Show the contact data of the author of damage in the participants table (Beteiligte).
    accidentDateNotDeterminedLabel?: boolean; // A label that may be printed in the DOCX involved party table in case no accident date was determined. If empty, the entire line "accident date" is omitted.
    accidentLocationNotDeterminedLabel?: boolean; // A label that may be printed in the DOCX involved party table in case no accident location was determined. If empty, the entire line "accident location" is omitted.
    authorOfDamageNotDeterminedLabel?: boolean; // A label that may be printed in the DOCX involved party table in case no author of damage was determined. If empty, the entire section "author of damage" is omitted.
    insuranceNotDeterminedLabel?: boolean; // A label that may be printed in the DOCX involved party table in case no insurance was determined. If empty, the entire section "insurance" is omitted.
    garageNotDeterminedLabel?: boolean; // A label that may be printed in the DOCX involved party table in case no garage was determined. If empty, the entire section "garage" is omitted.

    // Report Summary
    showRoadworthiness?: boolean; // Show the roadworthiness of the car.
    showBatteryStatus?: boolean; // Show the battery status of an electric car.
    replacementValueNotDeterminedLabel?: string; // Show a label instead of the replacement value in summaries (ZusammenfassungHaftpflichtschaden, ZusammenfassungKaskoschaden & ZusammenfassungKurzgutachten)
    residualValueNotDeterminedLabel?: string; // A label that may be printed in the DOCX summary in case no residual value was determined. If empty, the entire summary line "Restwert" is omitted.
    residualValueNoBidsLabel?: string; // A label that may be printed in the DOCX summary in case no bids were received for the residual value. If empty, the entire summary line "Restwert" is omitted.
    diminishedValueNotDeterminedLabel?: string; // A label that may be printed in the DOCX summary in case no diminished Value was determined. If empty, the entire summary line "Merkantiler Minderwert" is omitted.
    deductibleNotDeterminedLabel?: string; // A label that may be printed in the DOCX summary in case no deductible was determined. If empty, the entire summary line "Davon Selbstbeteiligung" is omitted.
    labelForBenefitAdjustment?: string; // Default: "Neu-für-alt". Some appraisers would like to call it "Wertverbesserung" or "Vorteilsausgleich".

    showGarageFees?: boolean; // Show the garage fees.

    // Repair Confirmation
    showGarage?: boolean; // Set to true if the garage is supposed to be shown on the repair confirmation.

    // Market Value Analysis
    showMarketValueCorridors?: boolean; // Print a table with min, max and average numbers of the respective market value analysis.

    // Valuation Reports
    datValuationPdfImageWidth?: number; // Determine the width of the PDF pages converted to PNGs for a DAT valuation.

    // GT Motive calculation
    gtmotiveCalculationPdfImageWidth?: number; // Determine the width of the PDF pages converted to PNGs for a GT Motive calculation.

    // Letters
    useFirstAndLastNameAsSender?: boolean; // Use the first and last name of the sender in the letter window. Useful for people who operate their business from home, and thus don't have their company name printed on their mailbox.
    showTaxInfo?: boolean; // Print vatId or europeanVatId. If false, no vatId is printed at all.

    // Paint Thickness
    hidePaintThicknessVehicleSketch?: boolean; // Set to true if the paint thickness vehicle sketch should not be printed in the report.
    hidePaintThicknessTable?: boolean; // Set to true if the paint thickness table should not be printed in the report.
}

import { Component, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
    selector: 'html-intrusion-dialog',
    templateUrl: 'html-intrusion-dialog.component.html',
    styleUrls: ['html-intrusion-dialog.component.scss'],
})
export class HtmlIntrusionDialogComponent {
    constructor(
        private dialog: MatDialogRef<HtmlIntrusionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: HtmlIntrusionDialogData,
    ) {}

    public confirm(): void {
        this.dialog.close(true);
    }

    public cancel(): void {
        this.dialog.close(false);
    }
}

export interface HtmlIntrusionDialogData {
    intrusionProvider: 'kaspersky' | 'avast';
}

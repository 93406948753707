// Import means of localization
import { registerLocaleData } from '@angular/common';
// Import angular modules
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import { SimpleNotificationsModule } from 'angular2-notifications/dist';
import { environment } from '../environments/environment';
import { AnalyticsModule } from './analytics/analytics.module';
// Import the root component
import { AppComponent } from './app.component';
// Import configured RouterModule
import { routing } from './app.routing';
import { ContactsModule } from './contacts/contacts.module';
import { DatabaseSyncInitializerService } from './global/database-sync-initializer/database-sync-initializer.service';
import { HelpPanelComponent } from './global/help-panel/help-panel.component';
import { IntroVideosPanelComponent } from './global/intro-videos-panel/intro-videos-panel.component';
import { StorageUsagePanelComponent } from './global/local-storage-usage-panel/storage-usage-panel.component';
import { ProfessionalAssociationsComponent } from './global/professional-associations/professional-associations.component';
import { SyncIssueIndicatorComponent } from './global/sync-issue-indicator/sync-issue-indicator.component';
import { SyncIssueListPanelComponent } from './global/sync-issue-list-panel/sync-issue-list-panel.component';
import { WelcomeSplashScreenComponent } from './global/welcome-splash-screen/welcome-splash-screen.component';
import { HelpModule } from './help/help.module';
import { InvoicesModule } from './invoices/invoices.module';
import { LoginModule } from './login/login.module';
import { PreferencesModule } from './preferences/preferences.module';
import { SubscriptionDialogComponent } from './preferences/subscription-dialog/subscription-dialog.component';
// Import aX modules
import { ReportsModule } from './reports/reports.module';
import { DowntimeNotificationComponent } from './shared/components/downtime-notification/downtime-notification.component';
import { DefaultOfPaymentNotificationComponent } from './shared/components/payment-reminder-notification/default-of-payment-notification.component';
import { ConvertToAxErrorInterceptor } from './shared/interceptors/convert-to-ax-error.interceptor';
import { JwtVerificationInterceptor } from './shared/interceptors/jwt-verification.interceptor';
import { OfflineErrorInterceptor } from './shared/interceptors/offline-error.interceptor';
import { RefreshFrontendInterceptor } from './shared/interceptors/refresh-frontend.interceptor';
// Import Interceptors
import { SetAuthorizationHeaderInterceptor } from './shared/interceptors/set-authorization-header.interceptor';
import { SetFrontendVersionHeaderInterceptor } from './shared/interceptors/set-frontend-version-header.interceptor';
import { SetSocketIdHeaderInterceptor } from './shared/interceptors/set-socket-id-header.interceptor';
import { RequestRegistryInterceptor } from './shared/interceptors/sync-registry.interceptor';
import { AxAngularErrorHandler } from './shared/libraries/error-handlers/ax-angular-error-handler';
import { AccessRightGuard } from './shared/services/access-right-guard.service';
import { AppVersionUpdateService } from './shared/services/app-version-update.service';
import { AppVersionService } from './shared/services/app-version.service';
import { AuthGuard } from './shared/services/auth-guard.service';
// Import aX components
// Import services
import { AXRESTClient } from './shared/services/ax-restclient';
import { CanDeactivateGuard } from './shared/services/can-deactivate-guard.service';
import { DirectDebitMandateService } from './shared/services/direct-debit-mandate.service';
import { DownloadService } from './shared/services/download.service';
import { DowntimeNotificationService } from './shared/services/downtime-notification.service';
import { FeathersSocketioService } from './shared/services/feathers-socketio.service';
import { FrontendLogService } from './shared/services/frontend-log.service';
import { HelpPanelService } from './shared/services/help-panel.service';
import { HelpcenterSearchService } from './shared/services/helpcenter-search.service';
import { KeyboardService } from './shared/services/keyboard.service';
import { LoggedInUserService } from './shared/services/logged-in-user.service';
import { NetworkStatusService } from './shared/services/network-status.service';
import { NewWindowService } from './shared/services/new-window.service';
import { axNotificationService } from './shared/services/notification.service';
import { OrderAndCancellationService } from './shared/services/order-and-cancellation.service';
import { ProfilePictureFileService } from './shared/services/profile-picture-file.service';
import { RequestRegistryService } from './shared/services/request-registry.service';
import { ScreenTitleService } from './shared/services/screen-title.service';
import { SyncIssueNotificationService } from './shared/services/sync-issue-notification.service';
import { TeamService } from './shared/services/team.service';
import { TutorialStateService } from './shared/services/tutorial-state.service';
import { UserPreferencesService } from './shared/services/user-preferences.service';
import { UserService } from './shared/services/user.service';
import { SharedModule } from './shared/shared.module';
import { TasksModule } from './tasks/tasks.module';

registerLocaleData(localeDE);

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        routing,
        ReportsModule,
        ContactsModule,
        TasksModule,
        InvoicesModule,
        AnalyticsModule,
        HammerModule,
        HelpModule,
        LoginModule,
        PreferencesModule,
        HttpClientModule,
        SimpleNotificationsModule.forRoot({
            timeOut: 3000,
            showProgressBar: false,
            preventDuplicates: true,
        }),
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    declarations: [
        AppComponent,
        DowntimeNotificationComponent,
        IntroVideosPanelComponent,
        HelpPanelComponent,
        StorageUsagePanelComponent,
        DefaultOfPaymentNotificationComponent,
        SubscriptionDialogComponent,
        SyncIssueIndicatorComponent,
        SyncIssueListPanelComponent,
        ProfessionalAssociationsComponent,
        WelcomeSplashScreenComponent,
    ],
    bootstrap: [AppComponent],
    providers: [
        AXRESTClient,
        AccessRightGuard,
        AppVersionService,
        AppVersionUpdateService,
        AuthGuard,
        CanDeactivateGuard,
        DatabaseSyncInitializerService,
        DirectDebitMandateService,
        DownloadService,
        DowntimeNotificationService,
        FeathersSocketioService,
        FrontendLogService,
        HelpPanelService,
        HelpcenterSearchService,
        KeyboardService,
        LoggedInUserService,
        NetworkStatusService,
        NewWindowService,
        OrderAndCancellationService,
        ProfilePictureFileService,
        RequestRegistryService,
        ScreenTitleService,
        SyncIssueNotificationService,
        TeamService,
        Title,
        TutorialStateService,
        UserPreferencesService,
        UserService,
        UserService,
        axNotificationService,
        {
            provide: LOCALE_ID,
            useValue: 'de',
        },
        //*****************************************************************************
        //  Request Interceptors
        //****************************************************************************/
        /**
         * Order matters. For requests: A -> B -> C.
         *
         * After the JWT interceptor forces a logout of unauthenticated users, the Observable chain stops.
         * Hence, the in-flight request would not be removed from the registry if this happens after the JWT interceptor.
         */
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestRegistryInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SetAuthorizationHeaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SetSocketIdHeaderInterceptor,
            multi: true,
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Request Interceptors
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Response Interceptors
        //****************************************************************************/
        /**
         * Order matters. For responses: C -> B -> A, so the last defined interceptor's response handler is executed first.
         */
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshFrontendInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtVerificationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OfflineErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ConvertToAxErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SetFrontendVersionHeaderInterceptor,
            multi: true,
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Response Interceptors
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Sentry
        //****************************************************************************/
        {
            provide: ErrorHandler,
            // Our error handler sends unhandled errors to Sentry.
            useClass: AxAngularErrorHandler,
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Sentry
        /////////////////////////////////////////////////////////////////////////////*/
    ],
})
export class AppModule {}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'dat-or-audatex-valuation-result-row',
    templateUrl: './dat-or-audatex-valuation-result-row.component.html',
    styleUrls: ['./dat-or-audatex-valuation-result-row.component.scss'],
})
export class DatOrAudatexValuationResultRowComponent {
    @Input() valueTitle: string;
    @Input() subtitleText: string;
    @Input() subtitleIcon: string;
    @Input() valueNet: number;
    @Input() valueGross: number;
    @Input() tooltipNet =
        'Netto. Dieser Wert ist relevant, wenn der Fahrzeughalter vorsteuerabzugsberechtigt ist und wird in der DAT Bewertung ausgewiesen.';
    @Input() tooltipGross =
        'Brutto. Dieser Wert ist relevant, wenn der Fahrzeughalter nicht vorsteuerabzugsberechtigt ist und wird in der DAT Bewertung ausgewiesen. Klicke, um beide Werte zu übernehmen.';
    @Input() primaryValue: 'net' | 'gross';
    @Input() disabled: boolean;
    @Input() helpIndicator: string;
}

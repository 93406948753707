/**
 * Validates and extracts components of a German tax ID (German "Steuernummer").
 *
 * A valid German tax number has the format: "(F)FF/BBB/UUU(U)P"
 * where:
 * - FFFF: District number (4 digits)
 * - BBB: Office number (3 digits)
 * - UUUU: Unique number (4 digits)
 *
 * Those are number formats created by the Bundesländer. There are, however, also nation-wide unique formats:
 * 12 or 13 digits without separators. They are not checked here since they are not relevant for our users. They
 * are used within Elster, for example.
 *
 * Source: https://de.wikipedia.org/wiki/Steuernummer
 */
function extractPartsFromStandardGermanTaxId(
    germanTaxId: string,
): { districtNumber: string; officeNumber: string; uniqueNumber: string; checkDigit: string } | null {
    if (!germanTaxId) {
        return null;
    }

    const standardSchemaRegularExpressions: RegExp[] = [
        /**
         * Baden-Württemberg
         */
        /^(\d{2})(\d{3})\/(\d{4})(\d)$/,
        /**
         * Bayern
         * Brandenburg
         * Mecklenburg-Vorpommern
         * Saarland (according to the example on Wikipedia, the first part may be 2 or 3 digits)
         * Sachsen
         * Sachsen-Anhalt
         * Thüringen
         */
        /^(\d{3})\/(\d{3})\/(\d{4})(\d)$/,
        /**
         * Berlin
         * Hamburg
         * Niedersachsen
         * Rheinland-Pfalz
         * Schleswig-Holstein
         */
        /^(\d{2})\/(\d{3})\/(\d{4})(\d)$/,
        /**
         * Bremen
         * Like Berlin but with spaces instead of slashes. We make spaces optional since they are hard for the user to get right.
         */
        /^(\d{2}) ?(\d{3}) ?(\d{4})(\d)$/,
        /**
         * Hessen
         * Like Bremen but with a leading 0.
         */
        /^0(\d{2}) ?(\d{3}) ?(\d{4})(\d)$/,
        /**
         * Nordrhein-Westfalen
         * Like Bayern but positions 2 and 3 are swapped.
         */
        /^(\d{3})\/(\d{4})\/(\d{3})(\d)$/,
        // /**
        //  * Vereinheitlichtes Bundesschema zur elektronischen Übermittlung (13-stellige Steuernummer)
        //  */
        // /^(\d{13})$/,
        // /**
        //  * Vereinheitlichtes Bundesschema (12-stellige Steuernummer)
        //  */
        // /^(\d{12})$/,
    ];

    for (const regex of standardSchemaRegularExpressions) {
        const match = germanTaxId.match(regex);
        if (match) {
            return {
                districtNumber: match[1],
                officeNumber: match[2],
                uniqueNumber: match[3],
                checkDigit: match[4],
            };
        }
    }

    return null;
}

export function isGermanTaxIdValid(germanTaxId: string): boolean {
    return !!extractPartsFromStandardGermanTaxId(germanTaxId);
}

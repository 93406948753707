import { Invoice } from '../../models/invoices/invoice';

export function getInvoiceRecipientName({ invoice, fallback }: { invoice: Invoice; fallback?: string }): string {
    const organization = invoice?.recipient?.contactPerson?.organization || '';
    const firstName = invoice?.recipient?.contactPerson?.firstName || '';
    const lastName = invoice?.recipient?.contactPerson?.lastName || '';

    if (organization) {
        return organization;
    }

    if (firstName || lastName) {
        return `${firstName} ${lastName}`.trim();
    }

    return fallback || `Kein Name`;
}

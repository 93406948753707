import { User } from '@autoixpert/models/user/user';

/**
 * Are either the market analysis or the residual value credentials complete?
 */
export function isWinvalueUserComplete(user: User): boolean {
    return areWinvalueMarketAnalysisCredentialsComplete(user) || areWinvalueResidualValueCredentialsComplete(user);
}

export function areWinvalueMarketAnalysisCredentialsComplete(user: User): boolean {
    return !!user?.winvalueUser?.apiKey;
}

export function areWinvalueResidualValueCredentialsComplete(user: User): boolean {
    return !!(user?.winvalueUser && user.winvalueUser.customerNumber && user.winvalueUser.password);
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container display-flex">
        <div id="left-column" class="card card-with-padding">
            <h2>GTÜ+/EGUB Import</h2>
            <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

            <!--============================================-->
            <!-- Authentication -->
            <!--============================================-->
            <div id="logo-and-authentication-row">
                <div
                    id="gtue-logo-container"
                    [ngClass]="{ 'cursor-pointer': !userIsLoggedIn }"
                    (click)="!userIsLoggedIn ? loginGtue() : null">
                    <img src="/assets/images/logos/gtue-logo.png" />
                </div>
                <div id="authentication-details-container">
                    <!--********** Login Button **********-->
                    <div id="login-button" *ngIf="!userIsLoggedIn">
                        <button (click)="loginGtue()">Login</button>
                    </div>

                    <!--********** Login indicator **********-->
                    <div *ngIf="userIsLoggedIn">
                        <div id="office-number-container">
                            <div id="office-number-label-and-text-container">
                                <div class="label">Büronummer</div>
                                <!--********** Office ID **********-->
                                {{ team.gtue?.officeId }}
                            </div>

                            <!--********** Logout **********-->
                            <mat-icon
                                class="toolbar-icon"
                                (click)="logoutGtue()"
                                matTooltip="GTÜ-Verbindung trennen, z. B. um die Büronummer zu wechseln.">
                                logout
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Authentication -->
            <!--============================================-->

            <ng-container *ngIf="userIsLoggedIn">
                <!--============================================-->
                <!-- Sub Offices -->
                <!--============================================-->

                <!--********** Display Toggle **********-->
                <div id="sub-offices-toggle">
                    <mat-slide-toggle
                        [disabled]="subOffices?.length <= 1"
                        [matTooltip]="
                            subOffices?.length <= 1
                                ? 'Du hast nur ein Unterbüro. Nur wenn du mehrere GTÜ-Unterbüros besitzt, kannst du sie beim Import einzeln auswählen.'
                                : ''
                        "
                        [(ngModel)]="includeAllSubOffices"></mat-slide-toggle>
                    <span matTooltip="Daten aus allen Unterbüros importieren">Alle Unterbüros</span>
                </div>

                <!--********** List **********-->
                <div *ngIf="!includeAllSubOffices" id="sub-offices-list">
                    <div *ngFor="let office of subOffices" class="sub-office-entry">
                        <mat-checkbox [(ngModel)]="office.include">{{ office.label || office.location }}</mat-checkbox>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Sub Offices -->
                <!--============================================-->

                <div class="import-settings-container" *ngIf="userIsLoggedIn">
                    <div class="date-range-container">
                        <!-- Don't let prettier format the following inline elements (to prevent whitespace between heading and help indicator) -->
                        <!-- prettier-ignore -->
                        <h4>Zeitraum<span class="help-indicator" matTooltip="Es können maximal die letzten 27 Monate importiert werden (Vorgabe der DSGVO).">?</span></h4>
                        <date-range-picker
                            [(dateFrom)]="importFromDate"
                            [(dateTo)]="importToDate"
                            [stretch]="true"></date-range-picker>
                    </div>

                    <!-- Start date out of range hint -->
                    <div
                        class="info-note warning-note"
                        *ngIf="isStartDateOutsideAllowedImportRange()"
                        @slideInAndOutVertically>
                        <mat-icon>warning</mat-icon>
                        <div class="info-note-text">
                            <h3>Startdatum zu früh</h3>
                            <div>
                                Rechnungen vor dem
                                <b>{{ minStartDateForImport | moment }}</b>
                                werden nicht importiert. Aufgrund einer Vorgabe der DSGVO kann die GTÜ nur Rechnungen
                                der letzten maximal 27 Monate bereitstellen.
                            </div>
                        </div>
                    </div>

                    <div class="payment-due-container">
                        <h4>Zahlungsziel</h4>
                        <mat-form-field>
                            <input matInput [(ngModel)]="daysUntilDue" placeholder="Ab Rechnungsdatum (Tage)" />
                        </mat-form-field>
                    </div>

                    <div class="info-with-icon-row">
                        <div class="info-icon-wrapper"><mat-icon class="regular-size-icon">file_copy</mat-icon></div>
                        <span>Bereits existierende Rechnungen werden nicht erneut importiert.</span>
                    </div>

                    <div class="info-with-icon-row">
                        <div class="info-icon-wrapper"><mat-icon class="regular-size-icon">credit_card</mat-icon></div>
                        <!-- Don't let prettier format the following inline elements (to prevent whitespace between anchor and full stop) -->
                        <!-- prettier-ignore -->
                        <span>
                    Importierte Rechnungen müssen per Kontenabgleich oder manuell als <span class="payment-status">Bezahlt</span>
                    markiert werden.
                </span>
                    </div>

                    <div class="info-note margin-top-20">
                        <mat-icon>hourglass_top</mat-icon>
                        <div>
                            <h3>GTÜ-Import dauert oft länger</h3>
                            Manchmal sogar mehrere Minuten (ca. 20 Sekunden für 10 Rechnungen). Du kannst den Dialog
                            schließen, nachdem du den Import gestartet hast. Später erscheinen die Rechnungen in der
                            Rechnungsliste.
                        </div>
                    </div>

                    <div class="dialog-buttons-container">
                        <button (click)="importInvoices()" [ngClass]="{ disabled: importInProgress }">
                            <img
                                src="/assets/images/icons/loading-indicator-white.svg"
                                alt=""
                                class="pending-icon"
                                matTooltip="Der Import kann je nach Anzahl an Rechnungen etwas länger dauern. Du kannst diesen Dialog schließen und später wiederkommen."
                                *ngIf="importInProgress" />
                            Importieren
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>

        <div id="right-column" *ngIf="importHistories.length" @slideInAndOutHorizontally>
            <div class="card">
                <h2>Importe</h2>

                <table id="import-history-list">
                    <tr class="import-history-list-entry" *ngFor="let importHistory of importHistories">
                        <!--********** Date **********-->
                        <td class="import-date" [matTooltip]="importHistory.description">
                            <div class="display-flex flex-align-center">
                                <mat-icon class="margin-right-8">today</mat-icon>
                                <div>
                                    <div matTooltip="{{ importHistory.createdAt | moment: 'ddd, DD.MM.YYYY' }}"></div>
                                    {{ importHistory.createdAt | moment }}
                                    <div class="label">{{ importHistory.createdAt | moment: 'HH:mm [Uhr]' }}</div>
                                </div>
                            </div>
                        </td>

                        <!--********** Number of imported objects **********-->
                        <td class="number-of-imported-objects-cell text-align-end">
                            <span class="number-of-imported-objects" matTooltip="Anzahl importierter Datensätze">
                                {{ importHistory.numberOfObjects | number: '1.0-0' }}
                            </span>
                        </td>

                        <td class="import-history-submenu">
                            <mat-icon
                                class="toolbar-icon"
                                [matMenuTriggerFor]="importHistorySubmenu"
                                *ngIf="!importHistoryDeletionPending">
                                more_vert
                            </mat-icon>
                            <mat-menu #importHistorySubmenu>
                                <a mat-menu-item class="menu-item-with-icon" (click)="rewindImport(importHistory)">
                                    <mat-icon>delete</mat-icon>
                                    Importierte Datensätze löschen
                                </a>
                            </mat-menu>

                            <!--********** Pending Indicator **********-->
                            <img
                                src="/assets/images/icons/loading-indicator.svg"
                                *ngIf="importHistoryDeletionPending"
                                matTooltip="Lösche Daten..." />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';

@Injectable()
export class RestorePhotosFromReportService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public restorePhotos({
        reportId,
        photosToRestore,
    }: {
        reportId: string;
        photosToRestore: Photo[];
    }): Promise<PhotoRestoreResponse> {
        return this.httpClient
            .post<PhotoRestoreResponse>(`${this.apiPath}/reports/${reportId}/photoRestore`, {
                photosToRestore,
            })
            .toPromise();
    }
}

export interface PhotoRestoreResponse {
    restoredPhotos: Photo[];
    errors: AxError[];
}

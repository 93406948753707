import { animate, style, transition, trigger } from '@angular/animations';

export function slideOutHorizontally(leaveDuration = 200) {
    return trigger('slideOutHorizontally', [
        transition(':leave', [
            animate(
                `${leaveDuration}ms ease`,
                style({
                    opacity: 0,
                    transform: 'translateX(50px)',
                }),
            ),
        ]),
    ]);
}

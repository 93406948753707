import { DateTime } from 'luxon';
import { Invoice } from '../../models/invoices/invoice';
import { makeLuxonDateTime } from '../ax-luxon';
import { toIsoDate } from '../date/iso-date';
import { IsoDate } from '../date/iso-date.types';

export function calculateDueDate(invoiceDate: Invoice['date'], daysUntilDue: number = 0): IsoDate {
    const baseDate = invoiceDate ? makeLuxonDateTime(invoiceDate) : DateTime.now();
    return toIsoDate(baseDate.plus({ day: daysUntilDue }));
}

import { DocumentOrder } from '../../models/documents/document-order';
import { DocumentGroup } from '../../models/reports/document-group';
import { CommunicationRecipient } from '../../models/reports/involved-parties/communication-recipient';
import { Report } from '../../models/reports/report';
import { getDocumentOrderForRecipient } from './get-document-order-for-recipient';

/**
 * This function is used when document orders are created, e.g. on report initialization/change or when creating
 * a repair confirmation. Repair confirmations have their own document group within which document orders exist
 * for each recipient.
 */
export function addDocumentOrderToReportForRecipient({
    report,
    communicationRecipient,
    documentGroup,
}: {
    report: Report;
    communicationRecipient: CommunicationRecipient;
    documentGroup: DocumentGroup;
}) {
    const documentOrders =
        documentGroup === 'repairConfirmation' ? report.repairConfirmation.documentOrders : report.documentOrders;

    try {
        getDocumentOrderForRecipient(documentOrders, communicationRecipient.role);
    } catch (error) {
        if (error.code === 'DOCUMENT_ORDER_NOT_FOUND_FOR_GIVEN_RECIPIENT') {
            documentOrders.push(
                new DocumentOrder({
                    recipientRole: communicationRecipient.role,
                }),
            );
        } else {
            throw error;
        }
    }
}

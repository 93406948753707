import { animate, style, transition, trigger } from '@angular/animations';

export function fadeInAndSlideAnimation({
    duration = 200,
    direction = 'fromTop',
    name = 'fadeInAndSlide',
    slideDistance = 20,
}: {
    duration?: number;
    direction?: 'fromBottom' | 'fromTop';
    name?: string;
    slideDistance?: number;
} = {}) {
    const translationOffset = direction === 'fromTop' ? `-${slideDistance}px` : `${slideDistance}px`;

    return trigger(name, [
        transition(':enter', [
            style({
                opacity: 0,
                transform: `translate3d(0, ${translationOffset}, 0)`,
            }),
            animate(
                `${duration}ms ease-in-out`,
                style({
                    opacity: 1,
                    transform: 'translate3d(0, 0, 0)',
                }),
            ),
        ]),
        transition(':leave', [
            animate(
                `${duration}ms ease-in-out`,
                style({
                    opacity: 0,
                    transform: `translate3d(0, ${translationOffset}, 0)`,
                }),
            ),
        ]),
    ]);
}

import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { DefaultDaysUntilDueConfig } from '@autoixpert/models/user/preferences/default-days-until-due-config';

export function getDefaultDaysUntilDue({ reportType, team }: { reportType: Report['type']; team: Team }): number {
    if (!reportType) return;

    const reportSpecificDaysUntilDueConfig: DefaultDaysUntilDueConfig =
        team.preferences.defaultDaysUntilDueConfigs.find((entry) => entry.reportType === reportType);
    const defaultDaysUntilDueConfig: DefaultDaysUntilDueConfig = team.preferences.defaultDaysUntilDueConfigs.find(
        (entry) => entry.reportType === 'default',
    );

    if (reportSpecificDaysUntilDueConfig) {
        return reportSpecificDaysUntilDueConfig.numberOfDays;
    } else if (defaultDaysUntilDueConfig) {
        return defaultDaysUntilDueConfig.numberOfDays;
    }
}

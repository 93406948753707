import { NgModule } from '@angular/core';
import { CompareTaskWithToday } from './compare-task-with-today';
import { ConvertToEuroPipe } from './convert-to-euro.pipe';
import { CustomBidsLabelPipe } from './custom-bids-label.pipe';
import { HtmlToPlaintextPipe } from './html-to-plaintext.pipe';
import { IncludesPipe } from './includes.pipe';
import { InvoiceDateAndOptionalDueDatePipe } from './invoice-date-and-optional-due-date.pipe';
import { MomentCalendarPipe, MomentFromNowPipe, MomentPipe, MomentWithoutUtcOffsetPipe } from './moment.pipe';
import { RelativeDatePipe } from './relative-date.pipe';
import { SelectedBidsSummaryLabelPipe } from './selected-bids-summary-label.pipe';
import { ShouldIncludeBiddersWithoutBidPipe } from './should-include-bidders-without-bid.pipe';
import { TranslateDocumentOrderConfigNamePipe } from './translate-document-order-config.pipe';
import { TranslateDocumentTypeGroupNamePipe } from './translate-document-type-group.pipe';
import { TranslateTristatePipe } from './translate-tristate.pipe';
import { UserInitialsPipe } from './user-initials.pipe';

/**
 * This module contains all the shared pipes used in the application.
 * It is necessary to avoid the Angular error: Pipes should be declared only once.
 */
@NgModule({
    declarations: [
        ConvertToEuroPipe,
        CustomBidsLabelPipe,
        HtmlToPlaintextPipe,
        IncludesPipe,
        InvoiceDateAndOptionalDueDatePipe,
        MomentCalendarPipe,
        MomentFromNowPipe,
        MomentPipe,
        MomentWithoutUtcOffsetPipe,
        SelectedBidsSummaryLabelPipe,
        ShouldIncludeBiddersWithoutBidPipe,
        TranslateDocumentOrderConfigNamePipe,
        TranslateDocumentTypeGroupNamePipe,
        TranslateTristatePipe,
        UserInitialsPipe,
        InvoiceDateAndOptionalDueDatePipe,
        RelativeDatePipe,
        CompareTaskWithToday,
    ],
    exports: [
        ConvertToEuroPipe,
        CustomBidsLabelPipe,
        HtmlToPlaintextPipe,
        IncludesPipe,
        InvoiceDateAndOptionalDueDatePipe,
        MomentCalendarPipe,
        MomentFromNowPipe,
        MomentPipe,
        MomentWithoutUtcOffsetPipe,
        SelectedBidsSummaryLabelPipe,
        ShouldIncludeBiddersWithoutBidPipe,
        TranslateDocumentOrderConfigNamePipe,
        TranslateDocumentTypeGroupNamePipe,
        TranslateTristatePipe,
        UserInitialsPipe,
        InvoiceDateAndOptionalDueDatePipe,
        RelativeDatePipe,
        CompareTaskWithToday,
    ],
})
export class SharedPipesModule {}

<nav
    class="module-process-bar card"
    *ngIf="report"
    [ngClass]="{
        'process-bar-sticky': userPreferences.navBarSticky || !!isSmallScreen('medium'),
        'process-bar-hidden': isProcessBarDynamicallyHidden,
    }">
    <div id="lock-icon-container" *ngIf="report && report.lockedAt" @reportStateLock>
        <mat-icon
            class="outlined"
            [matTooltip]="getTooltipForLock()"
            (click)="userMayLockReport() && toggleReportLockedState()"
            [ngClass]="{ disabled: !userMayLockReport() }">
            {{ report.state === 'done' ? 'lock' : 'lock_open_right' }}
        </mat-icon>
    </div>
    <div class="process-bar-elements-container">
        <!--********** Accident & Involved Parties **********-->
        <div class="process-bar-element-container">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('accidentData')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('accidentData') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="accidentData"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.accidentData"></realtime-edit-indicator>
        </div>

        <!--********** Car data **********-->
        <div class="process-bar-element-container">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('carData')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('carData') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="carData"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.carData"></realtime-edit-indicator>
        </div>

        <!--********** Car Condition - Damage **********-->
        <div class="process-bar-element-container" *ngIf="isReportTabVisible('carCondition')">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('carCondition')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('carCondition') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="carCondition"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.carCondition"></realtime-edit-indicator>
        </div>

        <!--********** Car Condition - Oldtimer **********-->
        <div class="process-bar-element-container" *ngIf="isReportTabVisible('carConditionOldtimer')">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('carConditionOldtimer')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('carConditionOldtimer') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="carConditionOldtimer"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.carConditionOldtimer"></realtime-edit-indicator>
        </div>

        <!--********** Photos **********-->
        <div class="process-bar-element-container">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('photos')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('photos') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="photos"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.photos"></realtime-edit-indicator>
        </div>

        <!--********** Damage Calculation **********-->
        <div class="process-bar-element-container" *ngIf="isReportTabVisible('damageCalculation')">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('damageCalculation')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('damageCalculation') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="damageCalculation"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.damageCalculation"></realtime-edit-indicator>
        </div>

        <!--********** Valuation - New cars**********-->
        <div class="process-bar-element-container" *ngIf="isReportTabVisible('valuation')">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('valuation')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('valuation') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="valuation"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.valuation"></realtime-edit-indicator>
        </div>

        <!--********** Valuation - Oldtimers **********-->
        <div class="process-bar-element-container" *ngIf="isReportTabVisible('valuationOldtimer')">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('valuationOldtimer')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('valuationOldtimer') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="valuationOldtimer"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.valuationOldtimer"></realtime-edit-indicator>
        </div>

        <!--********** Lease Return **********-->
        <div class="process-bar-element-container" *ngIf="isReportTabVisible('leaseReturnChecklist')">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('leaseReturnChecklist')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('leaseReturnChecklist') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="leaseReturnChecklist"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.leaseReturnChecklist"></realtime-edit-indicator>
        </div>

        <!--********** Invoice Audit **********-->
        <div class="process-bar-element-container" *ngIf="isReportTabVisible('invoiceAudit')">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('invoiceAudit')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('invoiceAudit') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="invoiceAudit"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.invoiceAudit"></realtime-edit-indicator>
        </div>

        <!--********** Invoice **********-->
        <div class="process-bar-element-container" *ngIf="user?.accessRights.seeReportFees">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('fees')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('fees') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="fees"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.fees"></realtime-edit-indicator>
        </div>

        <!--********** Print & Transmission **********-->
        <div class="process-bar-element-container">
            <a
                class="process-bar-element"
                [routerLink]="getLinkFragmentForReportTabName('printAndTransmission')"
                [routerLinkActive]="['active']">
                <span class="process-bar-element-label">
                    {{ translateReportTabName('printAndTransmission') }}
                    <report-progress-indicator-icon
                        *ngIf="report && reportProgressConfig"
                        [report]="report"
                        [reportProgressConfig]="reportProgressConfig"
                        reportTabName="printAndTransmission"></report-progress-indicator-icon>
                </span>
            </a>
            <realtime-edit-indicator
                *ngIf="realtimeEditorsWithinTab"
                class="realtime-edit-indicator"
                [realtimeEditorColorMap]="realtimeEditorColorMap"
                [realtimeEditSessions]="realtimeEditorsWithinTab.printAndTransmission"></realtime-edit-indicator>
        </div>
    </div>

    <!--********** Tasks Icon **********-->
    <div class="process-bar-icon-container" *ngIf="tasks.length || tasksIconVisible">
        <mat-icon
            class="tasks-icon"
            tasks-list-overlay-anchor
            #tasksListOverlayAnchor="tasksListOverlayAnchor"
            [tasks]="tasks"
            [report]="report"
            #tasksIcon
            (click)="showTasksIcon()"
            (change)="saveReport()"
            [matTooltip]="getTooltipForTaskOverlayAnchor(tasks)">
            task_alt
        </mat-icon>
    </div>

    <!--********** Notes Icon **********-->
    <div class="process-bar-icon-container" *ngIf="report.notes.length || notesIconVisible" matTooltip="Notizen">
        <mat-icon
            class="internal-notes-icon"
            internal-notes-panel-anchor
            [notes]="report.notes"
            #notesIcon
            (click)="showNotesIcon()"
            (change)="saveReport()">
            notes
        </mat-icon>
    </div>

    <!--********** Repair Confirmation Icon **********-->
    <div
        class="process-bar-icon-container"
        *ngIf="report.repairConfirmation"
        (click)="navigateToRepairConfirmation()"
        matTooltip="Reparaturbestätigung">
        <a class="process-bar-element" [routerLink]="['Reparaturbestätigung']" [routerLinkActive]="['active']">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                enable-background="new 0 0 24 24"
                xml:space="preserve">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#A1A1A1"
                    d="M19.692,4.524l-4.166,4.161L12.939,6.1l4.093-4.09 c-2.175-1.017-4.835-0.648-6.631,1.145C8.719,4.835,8.283,7.278,9.07,9.366L1.867,16.56c-0.878,0.877-0.878,2.301,0,3.179
                    c0.878,0.876,2.303,0.876,3.182,0l7.109-7.101c2.158,0.963,4.774,0.578,6.544-1.191C20.582,9.57,20.893,6.747,19.692,4.524z"></path>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#A1A1A1"
                    d="M13.652,18.171l2.183,2.149l5.041-5.508l1.449,1.474l-6.307,7.076l-3.763-3.72L13.652,18.171z"></path>
            </svg>
        </a>
    </div>

    <!--********** Expert Statement Icon **********-->
    <div
        class="process-bar-icon-container"
        *ngIf="report.expertStatements?.length"
        (click)="navigateToExpertStatement()"
        matTooltip="Stellungnahme">
        <a class="process-bar-element" [routerLink]="['Stellungnahme']" [routerLinkActive]="['active']">
            <mat-icon class="selection-toolbar-photo-group-icon">comment</mat-icon>
        </a>
    </div>

    <!--============================================-->
    <!-- Report Type -->
    <!--============================================-->
    <div
        class="process-bar-icon-container report-type-abbreviation-container"
        report-type-dialog-anchor
        [class.disabled]="isReportLocked(report)"
        (changedReportType)="changeReportType($event)"
        [report]="report">
        <report-type-chip [reportType]="report.type" [showLiability]="true"></report-type-chip>
    </div>
    <!--============================================-->
    <!-- END Report Type -->
    <!--============================================-->

    <!--============================================-->
    <!-- Submenu -->
    <!--============================================-->
    <div class="process-bar-option">
        <mat-icon id="process-bar-more-icon" class="toolbar-icon" [matMenuTriggerFor]="processBarMenu">
            more_vert
        </mat-icon>
        <mat-menu #processBarMenu="matMenu" xPosition="before">
            <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="subCaseMenu">
                <mat-icon class="outlined">add_box</mat-icon>
                Untervorgänge
            </a>

            <mat-menu #subCaseMenu>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="navigateToRepairConfirmation()"
                    matTooltip="Bestätigung einer Reparatur ohne Rechnung, z. B. weil der Anspruchsteller das Fahrzeug selbst repariert hat.{{
                        '\n\n'
                    }}Wenn der Anspruchsteller nochmal einen Schaden hat und die erfolgte Reparatur nicht nachweisen kann, fällt der erneute Schaden ggf. aus der Haftung der Versicherung, weil die Versicherung den Anspruchsteller nicht besser stellen muss als vor dem Unfall."
                    *ngIf="['liability', 'shortAssessment', 'partialKasko', 'fullKasko'].includes(report.type)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        xml:space="preserve">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="#A1A1A1"
                            d="M19.692,4.524l-4.166,4.161L12.939,6.1l4.093-4.09 c-2.175-1.017-4.835-0.648-6.631,1.145C8.719,4.835,8.283,7.278,9.07,9.366L1.867,16.56c-0.878,0.877-0.878,2.301,0,3.179
                    c0.878,0.876,2.303,0.876,3.182,0l7.109-7.101c2.158,0.963,4.774,0.578,6.544-1.191C20.582,9.57,20.893,6.747,19.692,4.524z"></path>
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="#A1A1A1"
                            d="M13.652,18.171l2.183,2.149l5.041-5.508l1.449,1.474l-6.307,7.076l-3.763-3.72L13.652,18.171z"></path>
                    </svg>
                    Reparaturbestätigung
                </a>

                <!--********** Expert Statement **********-->
                <a mat-menu-item class="menu-item-with-icon" (click)="navigateToExpertStatement()">
                    <mat-icon>comment</mat-icon>
                    Stellungnahme
                </a>

                <!--********** Amendment Report **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="openAmendmentReportDialog()"
                    matTooltip="Das Gutachten und die Kalkulation werden kopiert.{{
                        '\n\n'
                    }}Ein Nachtrag ist dann erforderlich, wenn sich der Umfang der Kalkulation nach Einreichung des Gutachtens bei der Versicherung verändert hat.">
                    <mat-icon>note_add</mat-icon>
                    Nachtrag
                </a>

                <!--********** Invoice Audit **********-->
                <a
                    mat-menu-item
                    *ngIf="report.damageCalculation?.repair && report.insurance?.contactPerson?.organization"
                    class="menu-item-with-icon"
                    (click)="openInvoiceAuditDialog()"
                    matTooltip="Die tatsächlichen Reparaturkosten werden in einem neuen Gutachten mit den kalkulierten Reparaturkosten gegenübergestellt und Abweichungen begründet. Die Summe der Kalkulation sowie Fahrzeug, Anspruchsteller und Versicherung werden aus dem aktuellen Gutachten übernommen.">
                    <mat-icon>euro</mat-icon>
                    Rechnungsprüfung
                </a>
            </mat-menu>

            <!--********** Aufgaben **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="showTasksIcon(); openTasksPanel()">
                <mat-icon>task_alt</mat-icon>
                Aufgaben
            </a>

            <!--********** Notes **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="showNotesIcon(); openNotesPanel()">
                <mat-icon>notes</mat-icon>
                Notizen
            </a>

            <!--********** Custom Field Config Edit Mode **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="toggleCustomFieldEditMode()"
                matTooltip="Bearbeitungsmodus für eigene Felder{{
                    '\n\n'
                }}Eigene Felder können an verschiedenen Orten im Gutachtenprozess eingefügt werden."
                [disabled]="!user?.accessRights.editTextsAndDocumentBuildingBlocks">
                <mat-icon>tune</mat-icon>
                <ng-container *ngIf="!fieldGroupConfigService.isEditModeEnabled">Felder bearbeiten</ng-container>
                <ng-container *ngIf="fieldGroupConfigService.isEditModeEnabled">Feldbearbeitung beenden</ng-container>
            </a>

            <!--********** Freeze Process Bar **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="!isSmallScreen('medium')"
                (click)="toggleNavBarSticky()">
                <mat-icon>push_pin</mat-icon>
                <p
                    *ngIf="!userPreferences.navBarSticky"
                    matTooltip="Einstellen, dass die Prozessleiste automatisch mitscrollt, damit sie immer sichtbar ist.">
                    Prozessleiste fixieren
                </p>
                <p
                    *ngIf="userPreferences.navBarSticky"
                    matTooltip="Einstellen, dass die Prozessleiste beim Herunter-Scrollen verschwindet (Standard).">
                    Prozessleiste lösen
                </p>
            </a>
        </mat-menu>
    </div>
    <!--============================================-->
    <!-- END Submenu -->
    <!--============================================-->
</nav>
<div class="module-router-outlet-container">
    <router-outlet></router-outlet>
</div>

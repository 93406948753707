import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { CarEquipment } from '@autoixpert/models/reports/car-identification/car-equipment';

@Injectable()
export class AudatexKbaCodeService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getKbaCodeResult(reportId: string, kbaCode: string): Observable<AudatexKbaCodeResult[]> {
        return this.httpClient.get<AudatexKbaCodeResult[]>(
            `${this.apiPath}/reports/${reportId}/audatex/kbaCode/${kbaCode}`,
        );
    }
}

export interface AudatexKbaCodeResult {
    newCarInformation: Car;
    carEquipment: CarEquipment;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { ContactPerson, OrganizationType } from '@autoixpert/models/contacts/contact-person';
import { NewWindowService } from '../../services/new-window.service';

@Component({
    selector: 'electronic-invoice-toggle',
    templateUrl: './electronic-invoice-toggle.component.html',
    styleUrls: ['./electronic-invoice-toggle.component.scss'],
})
export class ElectronicInvoiceToggleComponent implements OnInit {
    //*****************************************************************************
    //  Inputs & Outputs
    //****************************************************************************/
    @Input({ required: true }) protected disabled: boolean;

    @Input({ required: true }) protected isElectronicInvoiceStatusFrozen: boolean;
    @Output() protected isElectronicInvoiceStatusFrozenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input({ required: true }) protected isElectronicInvoiceEnabled: boolean;
    @Output() protected isElectronicInvoiceEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _invoiceRecipientContactPerson: ContactPerson;
    @Input({ required: true }) protected set invoiceRecipientContactPerson(value: ContactPerson) {
        this._invoiceRecipientContactPerson = value;
        this.activateElectronicInvoiceAccordingToRecipient();
    }
    get invoiceRecipientContactPerson(): ContactPerson {
        return this._invoiceRecipientContactPerson;
    }

    @Input({ required: true }) protected invoiceDate: IsoDate;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Inputs & Outputs
    /////////////////////////////////////////////////////////////////////////////*/

    constructor(private newWindowService: NewWindowService) {}

    public ngOnInit() {
        /**
         * When the component loads, try to determine whether the electronic invoice should be enabled automatically.
         * If the user set it explicitly before, the function does nothing.
         */
        this.activateElectronicInvoiceAccordingToRecipient();
    }

    /**
     * It is not enough to link the input property through ngModel for the ngModel in the component which embeds this
     * component to work. Therefore, we need to emit the change event manually.
     */
    protected handleElectronicInvoiceToggleChange(): void {
        this.isElectronicInvoiceEnabledChange.emit(this.isElectronicInvoiceEnabled);
    }

    //*****************************************************************************
    //  Warning About Misconfiguration
    //****************************************************************************/
    protected isElectronicInvoiceWarningShown(): boolean {
        return !!this.getReasonForElectronicInvoiceWarning();
    }

    protected getTooltipForElectronicInvoiceWarning(): string {
        const reason = this.getReasonForElectronicInvoiceWarning();
        switch (reason) {
            case 'recipientIsCompanyButElectronicInvoiceIsDisabled':
                return `Der Rechnungsempfänger hat einen Firmennamen, gilt vermutlich als Unternehmen und sollte von dir eine E-Rechnung bekommen.`;
            case 'recipientMayDeductTaxesButElectronicInvoiceIsDisabled':
                return `Weil du den Rechnungsempfänger als vorsteuerabzugsberechtigt markiert hast, gilt er als Unternehmen und sollte von dir eine E-Rechnung bekommen.`;
            default:
                return `Es gibt einen Grund, warum die E-Rechnung für diese Rechnung nicht empfohlen wird. Der Grund "${reason}" ist aber noch nicht für das User Interface übersetzt. Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`;
        }
    }

    private getReasonForElectronicInvoiceWarning(): ElectronicInvoiceWarningReason | undefined {
        /**
         * Electronic invoices need not be enabled for invoices before 2025 according to German law.
         */
        if (this.invoiceDate && DateTime.fromISO(this.invoiceDate) < DateTime.fromISO('2025-01-01')) {
            return undefined;
        }

        if (!this.isElectronicInvoiceEnabled) {
            if (this.invoiceRecipientContactPerson?.organization) {
                return 'recipientIsCompanyButElectronicInvoiceIsDisabled';
            }
            if (this.invoiceRecipientContactPerson?.mayDeductTaxes) {
                return 'recipientMayDeductTaxesButElectronicInvoiceIsDisabled';
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Warning About Misconfiguration
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Automatically Activate Electronic Invoice
    //****************************************************************************/
    /**
     * By setting the electronic invoice state manually, lock it.
     */
    protected freezeElectronicInvoiceStatus() {
        /**
         * This needs to be set explicitly so this component has the correct state before the next change detection
         * cycle runs and updates this component's property through the parent component's [(ngModel)] binding.
         */
        this.isElectronicInvoiceStatusFrozen = true;
        this.isElectronicInvoiceStatusFrozenChange.emit(true);
    }

    protected unfreezeElectronicInvoiceStatus(): void {
        if (this.disabled) return;
        /**
         * This needs to be set explicitly so this component has the correct state before the next change detection
         * cycle runs and updates this component's property through the parent component's [(ngModel)] binding.
         */
        this.isElectronicInvoiceStatusFrozen = false;
        this.isElectronicInvoiceStatusFrozenChange.emit(false);
        this.activateElectronicInvoiceAccordingToRecipient();
    }

    /**
     * Depending on who receives the invoice, the electronic invoice should be activated automatically.
     */
    protected activateElectronicInvoiceAccordingToRecipient(): void {
        /**
         * Prevent automatically overwriting the electronic invoice status if the user has explicitly set it before or
         * the report has been locked before.
         */
        if (this.isElectronicInvoiceStatusFrozen) {
            return;
        }

        /**
         * Don't continue, if no invoice recipient has been set yet because all following code needs to know the VAT status of the invoice recipient.
         */
        if (!this.invoiceRecipientContactPerson) {
            return;
        }

        /**
         * These recipients are always considered business recipients.
         */
        const businessOrganizationTypes = new Array<OrganizationType>('garage', 'insurance', 'lawyer', 'leaseProvider');
        if (businessOrganizationTypes.includes(this.invoiceRecipientContactPerson.organizationType)) {
            this.isElectronicInvoiceEnabledChange.emit(true);
            return;
        }

        /**
         * Invoice recipients who may deduct taxes are always considered business recipients.
         *
         * Invoice recipients who have a company name are very likely to be business recipients, so we use this indicator
         * as a reliable default.
         */
        this.isElectronicInvoiceEnabledChange.emit(
            !!this.invoiceRecipientContactPerson.mayDeductTaxes || !!this.invoiceRecipientContactPerson.organization,
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Automatically Activate Electronic Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    protected openElectronicInvoiceFaq(): void {
        this.newWindowService.open(
            'https://www.bundesfinanzministerium.de/Content/DE/FAQ/e-rechnung.html',
            '_blank',
            'noopener',
        );
    }
}

type ElectronicInvoiceWarningReason =
    | 'recipientIsCompanyButElectronicInvoiceIsDisabled'
    | 'recipientMayDeductTaxesButElectronicInvoiceIsDisabled';

import { Invoice } from '../../models/invoices/invoice';
import { PaymentStatus } from '../../models/invoices/payment-status';
import { getPaymentStatus } from './get-payment-status';

/**
 * Sets the hasOutstandingPayments flag on an invoice based on its status.
 */
export function setPaymentStatus(
    invoice: Pick<
        Invoice,
        | 'type'
        | 'totalGross'
        | 'idsOfCancellationInvoices'
        | 'payments'
        | 'documents'
        | 'dueDate'
        | 'isFullCancellationInvoice'
        | 'hasOutstandingPayments'
        | 'hasPaymentReminder'
    >,
): void {
    const paymentStatus = getPaymentStatus(invoice);
    const paidStatues: PaymentStatus[] = ['paid', 'overpaid', 'fullyCanceled', 'creditNote', 'fullCancellation'];
    invoice.hasOutstandingPayments = !paidStatues.includes(paymentStatus);
}

import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';
import { ContactPerson } from '../../contacts/contact-person';
import { AccessRights } from '../user';

export class UserRegistration {
    constructor(template: Partial<UserRegistration> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    emailVerificationToken: string; // A unique token sent to the user to validate his email address.
    emailVerified: boolean; // Set to true as soon as the user confirms his email address.
    sentEmailVerificationEmailsCount: number; // Record how often the user resent an email. This prevents spam and helps the user to

    // The formatted phone number the SMS code has been dispatched to.
    unverifiedPhoneNumber: string;
    // 4-digit code sent via SMS to verify the registered phone number belongs to the person using it.
    phoneNumberVerificationCode: string;
    // Formatted in compliance with E.164
    verifiedPhoneNumber: string;
    sentVerificationSmsCount: number;
    // How often has the user failed to enter the right code?
    numberOfWrongCodeInputs: number;

    // Analytics
    companyType: string;
    jobExperience: string;
    marketingChannel: string;

    contactPerson: ContactPerson = new ContactPerson();
    password: string;
    testAccountExpirationDate: string;
    teamId: string; // The team is optional. Only required if a user is added to an existing team. It is passed to the registration process via a URL parameter if another team member is invited.
    invitedBy: ContactPerson = new ContactPerson(); // Tell the user who invited him.
    futureAccessRights: FutureAccessRights = new FutureAccessRights();
    // Allow automatic deactivation
    deactivateAt: string = null;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

export class FutureAccessRights extends AccessRights {
    constructor(template: Partial<FutureAccessRights> = {}) {
        super();
        Object.assign(this, template);
    }

    isAdmin: boolean = true;
}

import { DateTime } from 'luxon';
import { OutgoingMessage, OutgoingMessageStatus } from '@autoixpert/models/outgoing-message';

/**
 * Determines the current status of an outgoing message based on its status fields.
 * Status precedence (from highest to lowest):
 * 1. read - Message has been read by recipient
 * 2. delivered - Message has been delivered to recipient
 * 3. sent - Message has been sent
 * 4. failed - Message sending failed
 * 5. scheduled - Message is scheduled for sending
 * 6. draft - Message has not been sent or scheduled yet
 */
export function getOutgoingMessageStatus(outgoingMessage: OutgoingMessage): OutgoingMessageStatus {
    if (outgoingMessage.readAt) {
        return 'read';
    }
    if (outgoingMessage.deliveredAt) {
        return 'delivered';
    }
    if (outgoingMessage.failedAt) {
        return 'failed';
    }
    if (outgoingMessage.sentAt) {
        // Automatically mark as failed if sending took longer than 1 minute
        if (DateTime.fromISO(outgoingMessage.sentAt).diffNow().minutes > 1) {
            return 'failed';
        }
        return 'sent';
    }
    if (outgoingMessage.scheduledAt) {
        return 'scheduled';
    }
    return 'draft';
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Subscription } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { getPaymentStatus } from '@autoixpert/lib/invoices/get-payment-status';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Task } from '@autoixpert/models/tasks/task';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { InvoiceCancellationService } from 'src/app/shared/services/invoice-cancellation.service';
import { runChildAnimations } from '../../shared/animations/run-child-animations.animation';
import { getTooltipForTaskOverlayAnchor } from '../../shared/components/tasks/tasks-panel/get-tooltip-for-task-overlay-anchor';
import { getInvoiceApiErrorHandlers } from '../../shared/libraries/error-handlers/get-invoice-api-error-handlers';
import { confirmInvoiceDeletion } from '../../shared/libraries/invoices/confirm-invoice-deletion';
import { updateScreenTitleInvoice } from '../../shared/libraries/invoices/update-screen-title-invoice';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { CanComponentDeactivate } from '../../shared/services/can-deactivate-guard.service';
import { InvoiceDetailsService } from '../../shared/services/invoice-details.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { TaskService } from '../../shared/services/task.service';
import { ToastService } from '../../shared/services/toast.service';

@Component({
    selector: 'invoice-details',
    templateUrl: 'invoice-details.component.html',
    styleUrls: ['invoice-details.component.scss'],
    animations: [runChildAnimations()],
})
export class InvoiceDetailsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    constructor(
        private invoiceDetailsService: InvoiceDetailsService,
        private invoiceService: InvoiceService,
        private invoiceCancellationService: InvoiceCancellationService,
        private route: ActivatedRoute,
        private screenTitleService: ScreenTitleService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private dialog: MatDialog,
        private router: Router,
        private taskService: TaskService,
        private networkStatusService: NetworkStatusService,
    ) {}

    public invoice: Invoice;

    public user: User;
    public team: Team;

    // Tasks / ToDos
    protected tasks: Task[] = [];
    protected tasksIconVisible: boolean;
    @ViewChild('tasksIcon', { read: ElementRef }) tasksIcon: ElementRef;

    // Notes overlay
    public notesIconVisible: boolean;
    @ViewChild('notesIcon', { read: ElementRef }) notesIcon: ElementRef;

    public partialCancellationDialogShown: boolean;

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        // Get the invoice based on the params
        const routeSubscription = this.route.params.subscribe(async (params) => {
            try {
                this.invoice = await this.invoiceDetailsService.get(params['invoiceId']);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Rechnung nicht geladen',
                        body: 'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }

            if (this.invoice) {
                // Tasks
                this.loadTasks();

                updateScreenTitleInvoice({
                    invoice: this.invoice,
                    screenTitleService: this.screenTitleService,
                });

                // Add context to errors in our error monitoring tool Sentry.
                Sentry.setTag('invoiceId', this.invoice._id);
            }
        });

        this.registerWebsocketEventHandlers();

        this.subscriptions.push(routeSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tasks
    //****************************************************************************/
    protected async loadTasks() {
        try {
            this.tasks = await this.taskService.find({ 'associatedInvoice.invoiceId': this.invoice._id }).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Aufgaben nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    public showTasksIcon(): void {
        this.tasksIconVisible = true;
    }

    /**
     * Trigger the click handler that's added by the tasks-panel-anchor directive.
     */
    public openTasksPanel() {
        // Use timeout to give Angular time to insert the icon.
        window.setTimeout(() => {
            // If the icon is not visible yet, take another try in a few cycles.
            if (!this.tasksIcon) {
                this.openTasksPanel();
                return;
            }
            this.tasksIcon.nativeElement.click();
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Notes
    //****************************************************************************/

    public showNotesIcon(): void {
        this.notesIconVisible = true;
    }

    /**
     * Trigger the click handler that's added by the internal-notes-panel-anchor directive.
     */
    public openNotesPanel() {
        // Use timeout to give Angular time to insert the icon.
        window.setTimeout(() => {
            // If the icon is not visible yet, take another try in a few cycles.
            if (!this.notesIcon) {
                this.openNotesPanel();
                return;
            }
            this.notesIcon.nativeElement.click();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Notes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Cancellation
    //****************************************************************************/
    public async cancelInvoice(): Promise<void> {
        try {
            await this.invoiceCancellationService.createFullCancellationInvoice({
                rootInvoice: this.invoice,
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getInvoiceApiErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Rechnung konnte nicht storniert werden',
                    body: 'Bitte versuche es noch einmal. Falls es nicht funktioniert, kontaktiere die Hotline.',
                },
            });
        }

        this.toastService.success('Rechnung storniert');
    }

    public showInvoiceCancellationDialog() {
        this.partialCancellationDialogShown = true;
    }

    public hideInvoiceCancellationDialog() {
        this.partialCancellationDialogShown = false;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Cancellation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public async saveInvoice() {
        try {
            await this.invoiceService.put(this.invoice);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Rechnung nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * Delete invoice.
     *
     * If the invoice cancels another invoice, revert that first.
     */
    public async deleteInvoice(invoice: Invoice): Promise<void> {
        // Locked invoices may only be deleted if the current user is a team admin.
        if (!this.invoiceService.isDeletingInvoiceAllowed(invoice, this.user, this.team)) {
            this.toastService.error(
                'Gebuchte Rechnung',
                'Eine bereits gebuchte Rechnung kann nur vom Administrator deines Teams gelöscht werden.',
            );
            return;
        }

        const invoiceShouldBeDeleted: boolean = await confirmInvoiceDeletion({ dialogService: this.dialog });
        if (!invoiceShouldBeDeleted) {
            return;
        }

        // Clear cancelled status in case of cancellation invoices
        try {
            await this.invoiceService.safeDelete(invoice, [invoice]);
            // Clear the invoice object after deletion, so the canDeactivate check does not try to delete the invoice once again
            this.invoice = null;
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Rechnung nicht gelöscht`,
                    body: `Die Rechnung konnte nicht gelöscht werden. Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        if (this.user.accessRights.invoiceList) {
            this.navigateToInvoiceList();
        } else {
            this.navigateToReportList();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public navigateToInvoiceList(): void {
        this.router.navigateByUrl('/Rechnungen');
    }

    public navigateToReportList(): void {
        this.router.navigateByUrl('/Gutachten');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Checks whether the current invoice is empty and if so, automatically deletes it. In case the
     * user filled out some information, but the total net is zero, ask if the invoice can be deleted.
     */
    private async discardInvoiceIfEmpty(): Promise<void> {
        if (this.invoice.number) {
            // In case the user has already generated/entered a new invoice number, he probably does not want
            // to delete the invoice, otherwise there is a gap in the invoice number counter.
            return;
        }

        const noLineItems = this.invoice.lineItems.every(
            (lineItem) => !lineItem.description && !lineItem.unit && !lineItem.unitPrice && !lineItem.quantity,
        );
        const contactPerson = this.invoice.recipient.contactPerson;
        const recipient = contactPerson.organization || contactPerson.firstName || contactPerson.lastName;
        let shouldDeleteInvoice = false;

        if (!recipient && noLineItems && !this.invoice.intro) {
            // Invoice is completely empty -> we can safely delete it
            shouldDeleteInvoice = true;
        } else if (this.invoice.totalNet === 0) {
            // Invoice total net is zero but some fields are filled out -> ask user if we can delete it
            shouldDeleteInvoice = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Leere Rechnung verwerfen?',
                        content: 'Der Rechnungsbetrag liegt bei 0 €. Kann diese Rechnung gelöscht werden?',
                        confirmLabel: 'Weg damit!',
                        cancelLabel: 'Behalten.',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();
        }

        if (shouldDeleteInvoice) {
            // If not online, simply don't delete. This allows the user to navigate away without deletion.
            if (!this.networkStatusService.isOnline()) {
                return;
            }

            try {
                await this.invoiceService.safeDelete(this.invoice, [this.invoice]);
            } catch (error) {
                console.error('An error occurred while deleting an empty invoice.', { error });
            }
        }
    }

    //*****************************************************************************
    //  Websocket Events
    //****************************************************************************/
    private registerWebsocketEventHandlers() {
        this.registerPatchWebsocketEvent();
        this.registerDeletionWebsocketEvent();
    }

    /**
     * On each websocket put event, update local records.
     * @private
     */
    private registerPatchWebsocketEvent() {
        const patchUpdatesSubscription: Subscription =
            this.invoiceService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
                next: (patchedEvent: PatchedEvent<Invoice>) => {
                    if (!this.invoice) return;

                    // If this view holds the record being updated, update it.
                    if (this.invoice._id === patchedEvent.patchedRecord._id) {
                        applyOfflineSyncPatchEventToLocalRecord({
                            localRecord: this.invoice,
                            patchedEvent,
                        });
                    }
                },
            });

        this.subscriptions.push(patchUpdatesSubscription);
    }

    /**
     * On each websocket delete event, leave the editor.
     * @private
     */
    private registerDeletionWebsocketEvent() {
        const deletionsSubscription: Subscription = this.invoiceService.deletedInLocalDatabase$.subscribe({
            next: (deletedRecordId) => {
                if (!this.invoice) return;

                // If this view holds the record being updated, remove it.
                if (this.invoice._id === deletedRecordId) {
                    // Clear the invoice object after deletion, so the canDeactivate check does not try to delete the invoice once again
                    this.invoice = null;
                    this.navigateToInvoiceList();
                }
            },
        });

        this.subscriptions.push(deletionsSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Websocket Events
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Before leaving this page, check if the current invoice is empty and if so, delete it.
     * That way we prevent that empty invoices are unintentionally saved and block other features (like DATEV export).
     */
    async canDeactivate(): Promise<boolean> {
        if (!this.invoice) return true;

        if (!this.invoice.lockedAt) {
            await this.discardInvoiceIfEmpty();
        }

        return true;
    }

    async ngOnDestroy() {
        this.invoiceDetailsService.clear();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        // Prevent errors on other pages from showing the wrong report ID in Sentry.
        Sentry.setTag('invoiceId', undefined);
    }

    protected readonly getPaymentStatus = getPaymentStatus;
    protected readonly getTooltipForTaskOverlayAnchor = getTooltipForTaskOverlayAnchor;
}

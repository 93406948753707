<!--============================================-->
<!-- Label Configs -->
<!--============================================-->
<div
    class="label-option"
    *ngFor="let labelConfig of labelConfigs"
    [ngClass]="{ selected: isLabelSelected(labelConfig) }"
    (click)="toggleLabel(labelConfig); closeOverlay()">
    <div class="label-option-inner-container">
        <!--********** Color **********-->
        <!--suppress CssUnknownProperty -->
        <div class="label-color" [style.--labelColor]="labelConfig.color"></div>

        <!--********** Label Name **********-->
        <div class="label-name" [matTooltip]="labelConfig.name">{{ labelConfig.name }}</div>

        <!--********** Label Active Indicator **********-->
        <mat-icon
            class="label-active-indicator"
            (click)="toggleLabel(labelConfig); repositionOverlay(); $event.stopPropagation()">
            check
        </mat-icon>
    </div>
</div>
<!--============================================-->
<!-- END Label Configs -->
<!--============================================-->
<!--============================================-->
<!-- Label Config Stubs - For Orphaned Labels -->
<!--============================================-->
<section id="orphaned-label-section" *ngIf="labelConfigStubsForOrphanedLabels.length">
    <div id="orphaned-label-heading-container">
        <span id="orphaned-label-heading">Gelöschte Labels</span>
        <span
            class="help-indicator"
            matTooltip="Die zentralen Label-Vorlagen für diese Labels wurden gelöscht. Damit kannst du nicht mehr nach ihnen filtern.{{
                '\n\n'
            }}Die Labels können problemlos informativ stehen bleiben.{{
                '\n\n'
            }}Um diese Labels anderen Datensätzen zuzuweisen, musst du das Label aber über den Bearbeiten-Stift neu anlegen.">
            ?
        </span>
    </div>
    <div
        class="label-option"
        *ngFor="let labelConfigStub of labelConfigStubsForOrphanedLabels"
        [ngClass]="{ selected: isLabelSelected(labelConfigStub) }"
        (click)="toggleLabel(labelConfigStub); closeOverlay()">
        <div class="label-option-inner-container">
            <!--********** Color **********-->
            <!--suppress CssUnknownProperty -->
            <div class="label-color" [style.--labelColor]="labelConfigStub.color"></div>

            <!--********** Label Name **********-->
            <div class="label-name" [matTooltip]="labelConfigStub.name">{{ labelConfigStub.name }}</div>

            <!--********** Label Active Indicator **********-->
            <mat-icon
                class="label-active-indicator"
                (click)="toggleLabel(labelConfigStub); repositionOverlay(); $event.stopPropagation()">
                check
            </mat-icon>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Label Config Stubs - For Orphaned Labels -->
<!--============================================-->

<!--============================================-->
<!-- Start Edit Mode -->
<!--============================================-->
<div class="edit-mode-trigger" labelManagerAnchor [labelGroup]="labelGroup" [(labelConfigs)]="labelConfigs">
    <mat-icon class="small-icon">edit</mat-icon>
    <div class="edit-mode-trigger-label label">Bearbeiten</div>
</div>
<!--============================================-->
<!-- END Start Edit Mode -->
<!--============================================-->

import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import { DowntimeNotification } from '@autoixpert/models/notifications/downtime-notification';
import { DowntimeNotificationService } from '../../services/downtime-notification.service';

@Component({
    selector: 'downtime-notification',
    templateUrl: 'downtime-notification.component.html',
    styleUrls: ['downtime-notification.component.scss'],
})
export class DowntimeNotificationComponent {
    constructor(private downtimeNotificationService: DowntimeNotificationService) {}

    protected notificationNavigationCounter: number = 0;
    protected filteredDowntimeNotifications: DowntimeNotification[];
    private subscriptions: Subscription[] = [];

    @Output() numberOfDowntimeNotificationsChange: EventEmitter<number> = new EventEmitter();

    ngOnInit() {
        this.checkForDowntimeNotification();
        this.subscribeToDowntimeNotificationChange();
    }

    private checkForDowntimeNotification() {
        const downtimeNotifications = this.downtimeNotificationService.filteredDowntimeNotificationsForUser.filter(
            (downtime) => downtime.showInContexts?.length < 1,
        );

        /**
         * Sort the downtimeNotifications - newest first.
         */
        this.filteredDowntimeNotifications = downtimeNotifications.sort((downtimeA, downtimeB) =>
            moment(downtimeA.validFrom).isBefore(moment(downtimeB.validFrom)) ? -1 : 1,
        );

        /**
         * Ensure that a valid notificationNavigationCounter is set.
         */
        if (this.notificationNavigationCounter > this.filteredDowntimeNotifications.length - 1) {
            this.notificationNavigationCounter = this.filteredDowntimeNotifications.length - 1;
        }
        if (this.notificationNavigationCounter < 0) {
            this.notificationNavigationCounter = 0;
        }
    }

    private subscribeToDowntimeNotificationChange() {
        this.subscriptions.push(
            this.downtimeNotificationService.mayHaveChanged$$.subscribe(() => {
                this.checkForDowntimeNotification();
                this.numberOfDowntimeNotificationsChange.emit(this.filteredDowntimeNotifications.length);
            }),
        );
    }

    public downtimeIsToday(downtime: DowntimeNotification): boolean {
        return moment(downtime.validFrom).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY');
    }

    /**
     * Increase the notificationNavigationCounter by one.
     * Ensure that the counter does not exceed the length of the filteredDowntimeNotifications array.
     */
    public navigateToNextNotification() {
        if (this.notificationNavigationCounter < this.filteredDowntimeNotifications.length - 1) {
            this.notificationNavigationCounter++;
        }
    }

    public navigateToPreviousNotification() {
        if (this.notificationNavigationCounter > 0) {
            this.notificationNavigationCounter--;
        }
    }

    public removeDowntimeNotificationFromSession(downtime: DowntimeNotification) {
        // Move down the index if the deleted downtime is the last element within the array.
        if (this.filteredDowntimeNotifications.indexOf(downtime) === this.filteredDowntimeNotifications.length - 1) {
            this.navigateToPreviousNotification();
        }
        this.downtimeNotificationService.removeDowntimeNotificationFromSession(downtime);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected readonly isQapterixpert = isQapterixpert;
}

import { isEqual } from 'lodash-es';

/**
 * Checks if two objects are equal.
 * @param valueA First object to compare
 * @param valueB Second object to compare
 * @param ignoreId Flag if the _id property should be ignored when comparing.
 */
export function isEqualAx(valueA: any, valueB: any, ignoreId: boolean = false): boolean {
    /**
     * Falsy values do not require a deep comparison. Especially undefined cannot be copied via JSON copy below.
     */
    if (!valueA || !valueB) {
        return valueA === valueB;
    }

    /**
     * Work on copies of the objects to prevent constructors within objects to cause the detection of a difference.
     * This happened in manual positions of the garage fee set in autoiXpert. A manual position on a garage contact person has
     * a constructor but the exported fee set saved on the report object does not.
     *
     * For details, see https://github.com/lodash/lodash/issues/2362#issuecomment-220762626
     */
    const copyOfValueA = JSON.parse(JSON.stringify(valueA));
    const copyOfValueB = JSON.parse(JSON.stringify(valueB));

    // Ignoring the _id can be useful when comparing two database records for equality
    if (ignoreId && copyOfValueA._id && copyOfValueB._id) {
        delete copyOfValueA._id;
        delete copyOfValueB._id;
    }

    return isEqual(copyOfValueA, copyOfValueB);
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportTypeGerman, translateReportType } from '@autoixpert/lib/report/translate-report-type';
import { ReportType, reportTypes } from '@autoixpert/models/reports/report';
import { runChildAnimations } from '../../animations/run-child-animations.animation';

@Component({
    selector: 'selected-report-types',
    templateUrl: 'selected-report-types.component.html',
    styleUrls: ['selected-report-types.component.scss'],
    animations: [runChildAnimations()],
})
export class SelectedReportTypesComponent {
    constructor() {}

    @Input() selectedReportTypes: ReportType[] = [];
    @Output() selectedReportTypesChange: EventEmitter<ReportType[]> = new EventEmitter();
    @Output() dialogOpened: EventEmitter<void> = new EventEmitter();
    @Output() dialogClosed: EventEmitter<void> = new EventEmitter();

    // By the number of pixels, we try to estimate how much text we may display before shortening it to "XXX +  Y weitere".
    //@Input() maxWidthInPixels: number = null;

    // Used to disable the dialog, e.g. when only displaying the selected reports.
    @Input() disabled: boolean;

    /**
     * Hides all categories except for damage reports (liability, short assessment, full kasko, partial kasko).
     */
    @Input() showOnlyDamageReportTypes: boolean = false;

    /**
     * Hide the short assessment within the damage report types.
     */
    @Input() hideShortAssessment: boolean = false;

    public isReportTypeDialogVisible: boolean;

    //*****************************************************************************
    //  Report Type List
    //****************************************************************************/
    public getListOfSelectedReportTypesGerman(): ReportTypeGerman[] {
        return this.selectedReportTypes.map((reportType) => translateReportType(reportType));
    }

    public getDisplayStringOfSelectedReportTypes(): string {
        if (reportTypes.every((reportType) => this.selectedReportTypes.includes(reportType))) {
            return 'Alle';
        }

        const reportTypesGerman: ReportTypeGerman[] = this.getListOfSelectedReportTypesGerman();

        const displayedTypes: ReportTypeGerman[] = reportTypesGerman.slice(0, 2);
        const hiddenTypes: ReportTypeGerman[] = reportTypesGerman.slice(2);

        return (
            [
                `${displayedTypes.join(', ')}`,
                hiddenTypes.length
                    ? `${hiddenTypes.length} ${hiddenTypes.length === 1 ? 'weiterer' : 'weitere'}`
                    : null,
            ]
                // Filter out falsy values.
                .filter(Boolean)
                .join(' + ')
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Type List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Dialog
    //****************************************************************************/
    public openDialog() {
        if (!this.disabled) {
            this.isReportTypeDialogVisible = true;
            this.dialogOpened.emit();
        }
    }

    public closeDialog() {
        this.isReportTypeDialogVisible = false;
        this.dialogClosed.emit();
    }

    public emitSelectedReportTypesChange() {
        this.selectedReportTypesChange.emit(this.selectedReportTypes);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { ReportsByAssessorAnalyticsRecord } from '@autoixpert/models/analytics/reports-analytics.model';
import { RevenueByAssessorAnalyticsRecord } from '@autoixpert/models/analytics/revenue-analytics.model';
import { AnalyticsFilterComponent } from '../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { ReportsAnalyticsService } from '../../shared/services/analytics/reports-analytics.service';
import { RevenueAnalyticsService } from '../../shared/services/analytics/revenue-analytics.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { AnalyticsSettingsMenuComponent } from '../analytics-settings-menu/analytics-settings-menu.component';
import {
    getHeightOfHorizontalBarChart,
    negativeColor,
    seriesDimension1,
    setDefaultChartOptions,
} from '../default-chart-options';

@Component({
    selector: 'revenue-by-assessor',
    templateUrl: './revenue-by-assessor.component.html',
    styleUrls: ['./revenue-by-assessor.component.scss'],
})
export class RevenueByAssessorComponent implements OnInit {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;
    @ViewChild(AnalyticsSettingsMenuComponent, { static: true }) protected settings: AnalyticsSettingsMenuComponent;

    constructor(
        private revenueAnalyticsService: RevenueAnalyticsService,
        private reportsAnalyticsService: ReportsAnalyticsService,
        private apiErrorService: ApiErrorService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    public chartTitle: string = 'Umsatz nach Sachverständigem';
    private chart: ApexCharts;
    private chartOptions: apexcharts.ApexOptions;

    public analyticsPending: boolean = false;

    public totalRevenue: number = 0;
    public outstandingRevenue: number = 0;

    public records: RevenueAndReportsPerMonthByAssessorAnalyticsRecord[] = [];

    public filterAnalyticsFrom: string;
    public filterAnalyticsTo: string;

    ngOnInit() {
        setDefaultChartOptions();

        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateBackToAnalyticsList(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    public rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/

    public refreshLocalData(): void {
        this.calculateTotalRevenue();
        this.calculateOutstandingRevenue();
        this.drawChart();
    }

    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.records.length) return;

        // Bar Values
        const revenue: number[] = this.records.map((record) =>
            this.settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross,
        );
        const outstandingRevenue: number[] = this.records.map((record) =>
            this.settings.useNetValues ? record.outstandingRevenueNet : record.outstandingRevenueGross,
        );

        this.chartOptions = {
            chart: {
                type: 'bar',
                height: getHeightOfHorizontalBarChart(this.records.length),
            },
            colors: [seriesDimension1, negativeColor],
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            series: [
                {
                    name: 'Umsatz',
                    data: revenue,
                },
                {
                    name: 'Davon noch offen',
                    data: outstandingRevenue,
                },
            ],
            xaxis: {
                // Categories go into the Y axis when the bar chart is horizontal but...
                categories: this.records.map((record) => (record.assessorName || '').toUpperCase()),
                // ... all styling for the final xaxis is determined here.
                labels: {
                    formatter: (value) => currencyFormatterEuro(+value),
                    style: {
                        fontFamily: "'Source Sans Pro', sans-serif",
                    },
                },
            },
            yaxis: {
                labels: {
                    show: true,
                },
            },
            tooltip: {
                y: {
                    formatter: (revenueByAssessor) => currencyFormatterEuro(revenueByAssessor),
                },
            },
        };

        // Clear before re-drawing
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
        this.chart.render();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public calculateTotalRevenue(): void {
        this.totalRevenue = this.records.reduce(
            (total, currentItem) =>
                total + (this.settings.useNetValues ? currentItem.totalRevenueNet : currentItem.totalRevenueGross),
            0,
        );
    }

    public calculateOutstandingRevenue(): void {
        this.outstandingRevenue = this.records.reduce(
            (total, currentItem) =>
                total +
                (this.settings.useNetValues ? currentItem.outstandingRevenueNet : currentItem.outstandingRevenueGross),
            0,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    public updateAnalytics(): void {
        this.analyticsPending = true;

        const revenueByAssessor$ = this.revenueAnalyticsService.getRevenueByAssessor({
            from: this.filterAnalyticsFrom,
            to: this.filterAnalyticsTo,
            officeLocationIds: this.filter.officeLocationIds,
            reportLabelIds: this.filter.reportLabelConfigIds,
            invoiceLabelIds: this.filter.invoiceLabelConfigIds,
        });

        const numberOfReports$ = this.reportsAnalyticsService.getReportsByAssessor({
            from: this.filterAnalyticsFrom,
            to: this.filterAnalyticsTo,
            officeLocationIds: this.filter.officeLocationIds,
            reportLabelIds: this.filter.reportLabelConfigIds,
            // Don't filter the number of reports by invoice label
        });

        forkJoin([revenueByAssessor$, numberOfReports$]).subscribe({
            next: ([revenueByMonth, reportsByMonth]) => {
                this.records = [];
                for (const assessorsRevenue of revenueByMonth) {
                    // Get the matching number of reports for an assessor. The order may vary between the two.
                    const matchingReportsByAssessorRecord: ReportsByAssessorAnalyticsRecord = reportsByMonth.find(
                        (record) => record.assessorId === assessorsRevenue.assessorId,
                    );
                    const numberOfReports: number = matchingReportsByAssessorRecord
                        ? matchingReportsByAssessorRecord.numberOfReports
                        : 0;

                    this.records.push({
                        assessorName: assessorsRevenue.assessorName,
                        assessorId: assessorsRevenue.assessorId,
                        numberOfReports,
                        totalRevenueNet: assessorsRevenue.totalRevenueNet,
                        totalRevenueGross: assessorsRevenue.totalRevenueGross,
                        outstandingRevenueNet: assessorsRevenue.outstandingRevenueNet,
                        outstandingRevenueGross: assessorsRevenue.outstandingRevenueGross,
                        idsOfAllInvoices: assessorsRevenue.idsOfAllInvoices,
                        idsOfRegularInvoices: assessorsRevenue.idsOfRegularInvoices,
                        idsOfCreditNotes: assessorsRevenue.idsOfCreditNotes,
                        idsOfOutstandingInvoices: assessorsRevenue.idsOfOutstandingInvoices,
                    });
                }
                this.analyticsPending = false;
                this.calculateTotalRevenue();
                this.calculateOutstandingRevenue();
                setTimeout(() => {
                    this.drawChart();
                }, 0);
            },
            error: (error) => {
                this.analyticsPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ACCESS_DENIED: {
                            title: 'Zugriffsrecht fehlt',
                            body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                            forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                        },
                    },
                    defaultHandler: {
                        title: 'Fehler bei Auswertung',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface RevenueAndReportsPerMonthByAssessorAnalyticsRecord extends RevenueByAssessorAnalyticsRecord {
    numberOfReports: number;
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, HostListener, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { saveAs } from 'file-saver';
import { DateTime, Settings } from 'luxon';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { detectBrowser } from '@autoixpert/lib/browser/detect-browser';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { deviceHasSmallScreen } from '@autoixpert/lib/device-detection/device-has-small-screen';
import { isBeta } from '@autoixpert/lib/environment/is-beta';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import { translateDatabaseServiceName } from '@autoixpert/lib/server-sync/translate-database-service-name';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { ServerError } from '@autoixpert/models/errors/ax-error';
import { Team } from '@autoixpert/models/teams/team';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { environment } from '../environments/environment';
import { DatabaseSyncInitializerService } from './global/database-sync-initializer/database-sync-initializer.service';
import { HelpPanelComponent, HelpPanelPage } from './global/help-panel/help-panel.component';
import { HELP_PANEL_START_PAGE_TOKEN } from './global/help-panel/help-panel.tokens';
import { IntroVideosPanelComponent } from './global/intro-videos-panel/intro-videos-panel.component';
import { StorageUsagePanelComponent } from './global/local-storage-usage-panel/storage-usage-panel.component';
import { ProfessionalAssociationsComponent } from './global/professional-associations/professional-associations.component';
import {
    IS_AUTHENTICATED_AS_ADMIN_TOKEN,
    WelcomeSplashScreenComponent,
} from './global/welcome-splash-screen/welcome-splash-screen.component';
import {
    SubscriptionDialogComponent,
    SubscriptionDialogComponentConfig,
} from './preferences/subscription-dialog/subscription-dialog.component';
import { fadeInAndOutAnimation } from './shared/animations/fade-in-and-out.animation';
import { slideInHorizontally } from './shared/animations/slide-in-horizontally.animation';
import {
    HtmlIntrusionDialogComponent,
    HtmlIntrusionDialogData,
} from './shared/components/html-intrusion-dialog/html-intrusion-dialog.component';
import { getAnydeskLink } from './shared/libraries/device-detection/get-anydesk-link';
import { getProductName } from './shared/libraries/get-product-name';
import { sendJwtToOtherAutoixpertDomain } from './shared/libraries/send-jwt-to-other-autoixpert-domain';
import { StorageSpaceManager } from './shared/libraries/storage-space-manager/storage-space-manager.class';
import { ApiErrorService } from './shared/services/api-error.service';
import { AppVersionUpdateService } from './shared/services/app-version-update.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { DownloadService } from './shared/services/download.service';
import { FeathersSocketioService } from './shared/services/feathers-socketio.service';
import { HelpPanelService } from './shared/services/help-panel.service';
import { InvoiceHistoryService } from './shared/services/invoice-history.service';
import { LoggedInUserService } from './shared/services/logged-in-user.service';
import { NetworkStatus, NetworkStatusService } from './shared/services/network-status.service';
import { PhotoBlobUrlCacheService } from './shared/services/photo-blob-url-cache.service';
import { RequestRegistryService } from './shared/services/request-registry.service';
import { ScreenTitleService } from './shared/services/screen-title.service';
import { TaskService } from './shared/services/task.service';
import { TeamService } from './shared/services/team.service';
import { TutorialStateService } from './shared/services/tutorial-state.service';
import { UserPreferencesService } from './shared/services/user-preferences.service';
import { UserService } from './shared/services/user.service';

@Component({
    selector: 'autoixpert-app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    animations: [fadeInAndOutAnimation(), slideInHorizontally()],
})
export class AppComponent implements OnInit, OnDestroy {
    constructor(
        private screenTitleService: ScreenTitleService,
        private loggedInUserService: LoggedInUserService,
        private router: Router,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private requestRegistry: RequestRegistryService,
        private downloadService: DownloadService,
        private authenticationService: AuthenticationService,
        private teamService: TeamService,
        private userService: UserService,
        public appVersionUpdateService: AppVersionUpdateService,
        private overlayService: Overlay,
        private injector: Injector,
        public userPreferences: UserPreferencesService,
        private tutorialStateService: TutorialStateService,
        private dialogService: MatDialog,
        private helpPanelService: HelpPanelService,
        private photoBlobUrlCacheService: PhotoBlobUrlCacheService,
        private feathersSocketioService: FeathersSocketioService,
        private networkStatusService: NetworkStatusService,
        public databaseSyncInitializerService: DatabaseSyncInitializerService /* This service must be injected because it initializes sync services. */,
        private invoiceHistoryService: InvoiceHistoryService,
        private apiErrorService: ApiErrorService,
        private matIconRegistry: MatIconRegistry,
        private taskService: TaskService,
    ) {
        ////*****************************************************************************
        ////  Handle AxErrors in console.log
        ////****************************************************************************/
        //window.console.log   = handleAxErrorInConsoleLog(window.console.log);
        //window.console.warn  = handleAxErrorInConsoleLog(window.console.warn);
        //window.console.error = handleAxErrorInConsoleLog(window.console.error);
        ///////////////////////////////////////////////////////////////////////////////*/
        ////  END Handle AxErrors in console.log
        ///////////////////////////////////////////////////////////////////////////////*/
    }

    public user: User;
    public team: Team;

    // Navigation is hidden on mobile by CSS by default. This overwrites this default behavior.
    public isVisibleOnMobile: boolean = false;

    public browser: string;
    public doesBrowserSupportArrayAtMethod: boolean;

    screenTitle: string;
    screenSubtitle: string;
    licensePlateInTitle: string;
    reportTokenInTitle: string;

    /**
     * Set to true if the JWT contains the field autoixpertAdminAuthentication === true. It indicates that this session was started
     * using an admin token by a global autoiXpert admin, usually for checking the activity of an expired account.
     * That's useful for talking with people who tested autoiXpert in the past.
     */
    public isAdminAuthentication: boolean;

    // Online/Offline indicator
    public networkStatus: NetworkStatus;
    public manualReconnectionInProgress: boolean;

    @ViewChild('helpIcon', { static: false }) helpIcon: HTMLElement;
    public isAnydeskLogoAnimationRunning: boolean = false;
    public testPeriodNotificationShown: boolean = true; // Flag remembering if the user actively closed the notification. Will be reset when re-loading the app or logging in again.
    public numberOfDowntimeNotifications: number; // If there are multiple notifications in the header, we decrease the font size through CSS.

    // Subscription Dialog
    public subscriptionDialogRef: MatDialogRef<SubscriptionDialogComponent, boolean>;

    // Welcome Splash Screen
    public welcomeSplashScreenRef: OverlayRef;

    // First Steps Panel
    public firstStepsPanelOverlayRef: OverlayRef;
    public professionalAssociationsOverlayRef: OverlayRef;

    // Set up handle for the central sync registry. This is the base for the sync indicator in the head runner.
    public pendingRequestsRegistryLength: number = 0;

    public isSwitchToQapterixpertOverlayVisible: boolean;

    public isLogoutPending: boolean;
    public doesLogoutTakeLonger: boolean;

    public numberOfDueTasks: number = 0;
    public get badgeForDueTasks(): string {
        if (!this.userPreferences.taskList_showBadgeOfDueTasks) {
            return null;
        }
        if (this.numberOfDueTasks > 99) {
            return '99+';
        }
        if (this.numberOfDueTasks > 0) {
            return this.numberOfDueTasks.toString();
        }
        return null;
    }

    private renewJwtInterval: number;
    private subscriptions: Subscription[] = [];

    async ngOnInit() {
        this.setIconFont();
        // Static setup
        AppComponent.configureMoment();

        this.subscribeToScreenTitleChanges();
        this.subscribeToDownloadService();
        this.subscribeToRequestRegistry();
        this.subscribeToNetworkStatusChanges(); // Display network online/offline status

        if (window.location.href.includes('oneTimeAdminToken')) {
            await this.loginAsAdmin();
        } else if (window.location.href.includes('useCrossDomainLogin')) {
            await this.loginCrossDomain();
        }

        this.loggedInUserService.recoverUserAndTeamFromLocalStorage();

        this.checkForIntactCachedData();

        // Tell the service worker to keep checking for new app versions
        this.appVersionUpdateService.startAppVersionUpdateChecker();

        // Refresh user if logged in
        this.user = this.loggedInUserService.getUser();
        if (this.user) {
            await this.getUserFromServerAndSubscribeToCachedUser();
        } else {
            this.subscribeToCachedUser$();
        }

        this.subscribeToCachedTeam();

        this.subscribeToOpenHelpPanelRequests();
        this.subscribeToFirstStepsPanelOpeningRequests();

        // Renew the JWT when the page reloads.
        await this.authenticationService.renewJwt();
        /**
         * Renew (if required, see function implementation) JWT every 30 minutes.
         *
         * Alternative: Renew every 24 hours. Evaluating if JWT needs renewal every 30 minutes ensures that even if the
         * device sleeps after 24 hours of loading the page, the user still probably gets a new JWT while he's working.
         */
        this.renewJwtInterval = window.setInterval(
            async () => {
                await this.authenticationService.renewJwt();
            },
            30 * 60 * 1000,
        );

        this.browser = detectBrowser().browser;
        this.doesBrowserSupportArrayAtMethod = 'at' in Array.prototype;

        // Refresh team and user data if the user is logged in.
        await this.loadTeamAndUsersFromServer();

        // When reloading the app, the sync should be triggered.
        void this.databaseSyncInitializerService.sync();
        // Only used tasks are cached locally. To load all tasks of a user (e.g. for the notification badge), we need to fetch them from the server.
        void this.syncOpenTasks();

        /**
         * Ensure the socket used for real-time edit sessions is authenticated using the JWT from the local storage.
         *
         * Only try to authenticate the socket if the user is logged in. Otherwise, authentication won't work.
         */
        if (this.user) {
            try {
                await this.feathersSocketioService.app.authenticate();
            } catch (error) {
                console.log('🔑⛔ Authenticating the socket failed. Since this is optional, fail silently.', { error });
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        if (this.renewJwtInterval) {
            window.clearInterval(this.renewJwtInterval);
        }
    }

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/

    private async getUserFromServerAndSubscribeToCachedUser(): Promise<void> {
        this.subscribeToCachedUser$();

        const user = await this.userService.get(this.user._id);
        this.loggedInUserService.setUser(user);
    }

    private subscribeToCachedUser$(): void {
        this.loggedInUserService.getUser$().subscribe({
            next: async (user: User) => {
                this.user = user;
                this.isAdminAuthentication = this.authenticationService.isAdminAuthentication();

                // If the user logged out, the user may be empty and throw an error here.
                if (user) {
                    Sentry.setUser({
                        id: user._id,
                    });
                    Sentry.setTag('teamId', user.teamId);

                    // TODO Remove after 2024-12-01
                    const userPreferences = user.preferences as UserPreferences & {
                        tutorialVisible: boolean;
                        selectedTutorial: any;
                    };
                    if (
                        typeof userPreferences?.tutorialVisible !== 'undefined' ||
                        typeof userPreferences.selectedTutorial !== 'undefined'
                    ) {
                        delete userPreferences.tutorialVisible;
                        delete userPreferences.selectedTutorial;
                        await this.userService.put(user);
                    }

                    // Show the warning every time the user logs in again
                    this.testPeriodNotificationShown = true;

                    this.team = this.loggedInUserService.getTeam();

                    this.subscribeToTeamUpdates();
                    this.openSubscriptionDialogIfRequired();

                    // Open Intro Videos before splash screen so have the splash screen overlay the intro videos.
                    if (this.user?.userInterfaceStates.introVideosVisible) {
                        this.openIntroVideosPanel();
                    }

                    if (
                        // Never display for paying customers, because this could be an additional user being added years later.
                        this.team.accountStatus === 'test' &&
                        !this.team.userInterfaceStates?.welcomeSplashScreenClosed &&
                        // Don't open while another dialog is open.
                        !this.subscriptionDialogRef
                    ) {
                        this.openWelcomeSplashScreen();
                    }

                    // Join live updates on the logged-in user.
                    this.userService
                        .joinUpdateChannel(this.user._id)
                        .catch(() =>
                            console.log(
                                `Joining the user update channel failed. Update channels are optional, so fail silently.`,
                            ),
                        );

                    // Ask users for their associations if they're serious with autoiXpert (> 10 reports finished).
                    if (
                        user.gamification.congratsDialogDiplayedAtNumberOfReports >= 10 &&
                        isAdmin(user._id, this.team) &&
                        // TODO Remove after Christmas 2024.
                        // This helps that the panel doesn't open if the migrations have already happened but the client is still in an old state.
                        (DateTime.now().toISODate() as IsoDate) > '2024-12-24' &&
                        !this.team.userInterfaceStates?.submittedProfessionalAssociations
                    ) {
                        this.openProfessionalAssociationPanel();
                    }

                    this.displaySwitchToQapterixpertOverlay();
                } else {
                    /**
                     * Do stuff when the user logs out.
                     */
                    this.closeFirstStepsPanel();

                    // Remove warnings related to billing, e.g. overdue payments.
                    //this.team = null;
                }
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    private subscribeToCachedTeam() {
        // Get team immediately through the replay subject and listen to
        this.loggedInUserService.getTeam$().subscribe({
            next: (team) => {
                this.team = team;
            },
        });
    }

    /**
     * In order to have the lastest team version available on the client,
     * trigger a pull request right away.
     */
    public async loadTeamAndUsersFromServer() {
        if (this.user) {
            // Fetching the team should propagate changes to this component through the socket emissions triggered by each fetch.
            // If we're offline, the service knows that and only triggers a local fetch, which simply doesn't do anything. That's ok.
            const team: Team = (await this.teamService.find({ _id: this.user.teamId }).toPromise())?.[0];

            await this.userService.find({ _id: { $in: team.members } }).toPromise();
        }
    }

    private subscribeToTeamUpdates(): void {
        this.teamService.patchedFromExternalServerOrLocalBroadcast$.subscribe(() => {
            this.openSubscriptionDialogIfRequired();
        });
    }

    private subscribeToDueTasks(): void {
        if (this.userPreferences.taskList_showBadgeOfDueTasks) {
            const taskSubscription = this.taskService.numberOfDueTasks$$.subscribe((numberOfDueTasks) => {
                this.numberOfDueTasks = numberOfDueTasks;
            });

            this.subscriptions.push(taskSubscription);
        }
    }

    /**
     * Load the tasks of a user for offline caching: all open tasks and all tasks that are due today or in the future.
     */
    private async syncOpenTasks() {
        if (this.user) {
            await this.taskService
                .find({
                    $or: [
                        { assigneeId: this.user._id, isCompleted: false },
                        { dueDate: { $gte: toIsoDate(moment()) } },
                    ],
                })
                .toPromise();
            this.subscribeToDueTasks();
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Icon Font
    //****************************************************************************/
    private setIconFont() {
        // Register Material Symbols as Icon Font (instead of Material Icons)
        // Advantage is, that this is a variable font with new icons and the possibility
        // to change weight and fill style with CSS.
        this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Icon Font
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Screen Title, License Plate & Token
    //****************************************************************************/
    private subscribeToScreenTitleChanges() {
        this.screenTitleService.getScreenTitleObservable().subscribe((title) => {
            this.screenTitle = title;
            // Run change detection cycle after child updated this component's property. Prevents "ExpressionChangedAfterItHasBeenChecked" error.
            this.changeDetectorRef.detectChanges();
        });
        this.screenTitleService.getScreenSubtitleObservable().subscribe((subtitle) => {
            // I don't need subtitles, I went to the fricken American University in Cairo!!!
            this.screenSubtitle = subtitle;
            // Run change detection cycle after child updated this component's property. Prevents "ExpressionChangedAfterItHasBeenChecked" error.
            this.changeDetectorRef.detectChanges();
        });
        this.screenTitleService.getLicensePlateObservable().subscribe((licensePlate) => {
            this.licensePlateInTitle = licensePlate;
            // Run change detection cycle after child updated this component's property. Prevents "ExpressionChangedAfterItHasBeenChecked" error.
            this.changeDetectorRef.detectChanges();
        });
        this.screenTitleService.getReportTokenObservable().subscribe((reportToken) => {
            this.reportTokenInTitle = reportToken;
            // Run change detection cycle after child updated this component's property. Prevents "ExpressionChangedAfterItHasBeenChecked" error.
            this.changeDetectorRef.detectChanges();
        });
    }

    public switchLicensePlateAndReportToken(): void {
        const reportAttributesInHeadRunner = this.userPreferences.reportAttributesInHeadRunner;
        if (
            !reportAttributesInHeadRunner ||
            (reportAttributesInHeadRunner.includes('licensePlate') &&
                reportAttributesInHeadRunner.includes('reportToken'))
        ) {
            this.userPreferences.reportAttributesInHeadRunner = ['reportToken'];
        } else if (
            !reportAttributesInHeadRunner.includes('licensePlate') &&
            reportAttributesInHeadRunner.includes('reportToken')
        ) {
            this.userPreferences.reportAttributesInHeadRunner = ['licensePlate'];
        } else {
            this.userPreferences.reportAttributesInHeadRunner = ['licensePlate', 'reportToken'];
        }
    }

    public getTooltipForChangingReportAttributesInHeadRunner() {
        const reportAttributesInHeadRunner = this.userPreferences.reportAttributesInHeadRunner;
        if (
            !reportAttributesInHeadRunner ||
            (reportAttributesInHeadRunner.includes('licensePlate') &&
                reportAttributesInHeadRunner.includes('reportToken'))
        ) {
            return 'Nur Aktenzeichen anzeigen';
        } else if (
            !reportAttributesInHeadRunner.includes('licensePlate') &&
            reportAttributesInHeadRunner.includes('reportToken')
        ) {
            return 'Nur Nummernschild anzeigen';
        } else {
            return 'Aktenzeichen & Nummernschild anzeigen';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Screen Title, License Plate & Token
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Christmas Background
    //****************************************************************************/
    public isItChristmasTime(): boolean {
        // There is no Christmas on beta, muhahahahahaha. No seriously, that's better for creating screenshots.
        if (isBeta()) {
            return false;
        }
        const now = moment();
        return now.month() === 11 && now.date() > 20 && now.date() <= 31;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Christmas Background
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Subscription Management
    //****************************************************************************/
    public openSubscriptionDialog(): void {
        // Don't open twice
        if (this.subscriptionDialogRef) return;

        this.subscriptionDialogRef = this.dialogService.open<
            SubscriptionDialogComponent,
            SubscriptionDialogComponentConfig,
            boolean
        >(SubscriptionDialogComponent, {
            data: {
                team: this.team,
                user: this.user,
                display: {
                    fullBooking: true,
                },
            },
            maxWidth: '800px',
            /**
             * Admins who used the one time admin token may close this dialog. Regular user logins using email and password may not. Otherwise, they would
             * be able to use autoiXpert for free forever.
             */
            disableClose: !this.isAdminAuthentication,
        });
        this.subscriptionDialogRef.afterClosed().subscribe({
            next: (orderPlaced) => {
                if (orderPlaced) {
                    // Load the user to trigger a reload of the team in the AppComponent to remove the yellow "test-ends-soon" badge
                    this.loggedInUserService.setUser(this.user);
                }
            },
        });
    }

    private openSubscriptionDialogIfRequired() {
        const accountStatus = this.team.accountStatus;

        // Paying customer but no payment method? -> Make user chooses payment method.
        if (
            /**
             * Only paying customers or test accounts who already ordered need to enter their payment info.
             */
            (accountStatus === 'paying' || (accountStatus === 'test' && this.team.becameCustomerAt)) &&
            // Qapter-iXpert users do not need to enter payment info since Audatex pays autoiXpert for those users.
            !this.team.audatexFeatures?.qapterixpertLayout &&
            !this.team.billing.paymentMethod
        ) {
            this.openSubscriptionDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Subscription Management
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Welcome Splash Screen
    //****************************************************************************/
    public openWelcomeSplashScreen(): void {
        // Don't open twice
        if (this.welcomeSplashScreenRef) return;

        this.welcomeSplashScreenRef = this.overlayService.create({
            width: '100vw',
            height: '100vh',
            positionStrategy: this.overlayService.position().global().top('0').left('0'),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Close panel when clicking the backdrop.
        this.welcomeSplashScreenRef.detachments().subscribe(async () => {
            this.welcomeSplashScreenRef = null;

            // If the user has been logged out due to an invalid JWT.
            if (!this.team) return;

            // If we log in as an admin, don't hide the splash screen forever.
            if (!this.isAdminAuthentication) {
                this.team.userInterfaceStates.welcomeSplashScreenClosed = true;
                await this.teamService.put(this.team);
            }
        });

        //*****************************************************************************
        //  Injector
        //****************************************************************************/
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: IS_AUTHENTICATED_AS_ADMIN_TOKEN,
                    useValue: this.isAdminAuthentication,
                },
                {
                    provide: OverlayRef,
                    useValue: this.welcomeSplashScreenRef,
                },
            ],
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Injector
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Component Portal
        //****************************************************************************/
        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<WelcomeSplashScreenComponent>(
            WelcomeSplashScreenComponent,
            null,
            injector,
        );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Component Portal
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Attach Component to Portal Outlet
        //****************************************************************************/
        this.welcomeSplashScreenRef.attach(componentPortal);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Attach Component to Portal Outlet
        /////////////////////////////////////////////////////////////////////////////*/
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Welcome Splash Screen
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Header Runner Notifications General
    //****************************************************************************/
    public areManyHeaderRunnerNotificationsVisible(): boolean {
        return this.testPeriodNotificationVisible() && this.numberOfDowntimeNotifications > 0;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Header Runner Notifications General
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Test Period Notification
    //****************************************************************************/
    /**
     * Determine whether to show or hide the notification about the remaining days in the test.
     *
     * - Don't show if there is no user. The existence of a user indicated that the user is logged in. A missing user means he's logged out.
     */
    public testPeriodNotificationVisible(): boolean {
        return (
            this.user &&
            this.team &&
            (this.team.accountStatus === 'test' || this.team.accountStatus === 'deactivated') &&
            !this.team.becameCustomerAt &&
            this.testPeriodNotificationShown
        );
    }

    public hideTestPeriodNotification(): void {
        this.testPeriodNotificationShown = false;
    }

    public getDaysLeftInTestPeriod(): number {
        if (!this.team) return;
        if (!this.team.expirationDate) {
            return;
        }
        if (this.team.accountStatus === 'test' || this.team.accountStatus === 'deactivated') {
            const today: Moment = moment().startOf('day');
            const endOfTestPeriod: Moment = moment(this.team.expirationDate).startOf('day');

            return endOfTestPeriod.diff(today, 'days');
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Test Period Notification
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Logout
    //****************************************************************************/
    async confirmLogout() {
        /**
         * If autoiXpert customer support agents are logged in into this account, there is no need to ask for confirmation since
         * they usually know what happens when they log out and since they need to log out and in again multiple times a day.
         */
        if (this.isAdminAuthentication) {
            await this.logout();
        } else {
            const decision = await this.dialogService
                .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Wirklich ausloggen?',
                        content:
                            'Bei jedem Login werden eine Menge Daten für die Offline-Fähigkeit synchronisiert, was je nach Datenmenge in deinem Account (vor allem Kontakte) bis zu 2 min dein System verlangsamen kann 🐌<br><br><strong>Wir empfehlen:</strong><br>Falls dies dein Gerät ist und es geschützt durch ein Passwort oder Face-ID ist, bleib eingeloggt.',
                        confirmLabel: 'Ausloggen',
                        cancelLabel: 'Eingeloggt bleiben',
                        confirmColorRed: true,
                        headerImagePath: '/assets/images/illustrations/sunset_600.jpg',
                    },
                    panelClass: 'dialog-without-padding',
                    width: '450px',
                })
                .afterClosed()
                .toPromise();

            if (decision) {
                await this.logout();
            }
        }
    }

    async logout(forceRemoveUnsycnedRecords?: boolean): Promise<void> {
        this.isLogoutPending = true;
        const logoutTakesLongerTimeoutId = setTimeout(() => {
            this.doesLogoutTakeLonger = true;
        }, 5000);

        try {
            await StorageSpaceManager.clearData(forceRemoveUnsycnedRecords);
        } catch (error) {
            if (error.code === 'UNSYNCED_RECORDS_EXIST') {
                // Un-blur the screen.
                this.isLogoutPending = false;
                clearTimeout(logoutTakesLongerTimeoutId);

                const dialogRef = this.dialogService.open<ConfirmDialogComponent, ConfirmDialogData>(
                    ConfirmDialogComponent,
                    {
                        data: {
                            heading: 'Synchronisierung ausstehend',
                            content: `Es gibt ${
                                translateDatabaseServiceName(error.data.databaseServiceName).plural
                            }-Daten, die noch nicht zum Server geschickt wurden.\n\nBeim Logout gehen sie verloren. Was möchtest du tun?`,
                            confirmLabel: 'Daten für immer löschen',
                            cancelLabel: 'Stopp! Eingeloggt bleiben.',
                            confirmColorRed: true,
                        },
                    },
                );
                dialogRef.afterClosed().subscribe(async (result) => {
                    if (result) {
                        await this.logout(true);
                    }
                });
                return;
            } else {
                throw new ServerError({
                    code: 'CLEANING_DATA_ON_LOGOUT_FAILED',
                    message:
                        'The data from various database services could not be cleaned on logout due to a technical issue.',
                    error,
                });
            }
        }

        // Remove the logged-in user from the caching service.
        this.loggedInUserService.clearUser();
        /**
         * If the previously logged-in user had to re-authenticate because his JWT expired while trying to access a certain report, that report URL is
         * still saved in this.loggedInUserService.forwardUrl. To prevent the user who logs in next to be redirected to the same report URL to which
         * the user probably does not have access,
         */
        this.loggedInUserService.forwardUrl = undefined;

        /**
         * Remove cached photo URLs to ensure they are re-loaded when the user logs in again. If there is a caching-issue logging out and logging in
         * solves the issues.
         */
        this.photoBlobUrlCacheService.clear();

        // Unauthenticate the socket until a user logs in again.
        await this.authenticationService.unauthenticateSocket();

        window.setTimeout(() => (this.isLogoutPending = false), 500);
        clearTimeout(logoutTakesLongerTimeoutId);
    }

    public getProductName = getProductName;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Logout
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Admin Token & JWT via URL
    //****************************************************************************/
    /**
     * Before logging in with another user, we must clear any existing user data.
     * @private
     */
    private async clearUserForNewAuthentication() {
        // Remove the logged-in user from the caching service.
        // - This must happen synchronously before any async tasks so that other components know we're logged out.
        // - Skip navigation because that would clear the oneTimeAdminToken query param.
        this.loggedInUserService.clearUser(true);

        await StorageSpaceManager.clearData(true);

        // Unauthenticate the socket until a user logs in again.
        await this.authenticationService.unauthenticateSocket();
    }

    private async loginAsAdmin() {
        await this.clearUserForNewAuthentication();
        //console.log("Cleared user for admin auth.");
        await this.authenticateWithAdminToken();
    }

    private async authenticateWithAdminToken() {
        const params = await this.route.queryParams.pipe(first()).toPromise();

        if (params['oneTimeAdminToken']) {
            try {
                await this.authenticationService.tryAuthentication({
                    oneTimeAdminToken: params['oneTimeAdminToken'],
                    strategy: 'oneTimeAdminToken',
                });
                //console.log("Authenticated with oneTimeAdminToken");
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Authentifizierung mit Admin-Token gescheitert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            }
        } else {
            console.log('No admin token recognized. Params:', params);
            console.log('Route:', this.route);
        }
    }

    private async loginCrossDomain() {
        // Get the JWT from local storage before it's cleared by this.clearUserForNewAuthentication().
        const jwt = localStorage.getItem('jwt-for-cross-domain-login');

        console.log('Clear user for JWT auth.');
        await this.clearUserForNewAuthentication();

        if (jwt) {
            await this.authenticateWithCrossDomainJwt(jwt);
        } else {
            console.log(
                'No JWT was present at localStorage["jwt-for-cross-domain-login"], so no authentication is tried.',
            );
        }
    }

    private async authenticateWithCrossDomainJwt(jwt) {
        const params = await this.route.queryParams.pipe(first()).toPromise();

        if (params['forwardUrl'] && params['forwardUrl'] !== '/Login') {
            this.loggedInUserService.forwardUrl = params['forwardUrl'];
        }

        if (params['useCrossDomainLogin']) {
            await this.authenticationService.tryAuthentication({
                /**
                 * This local storage key is defined in sendJwtToOtherAutoixpertDomain().
                 */
                accessToken: jwt,
                strategy: 'jwt',
            });
            console.log('Authenticated with JWT');
        } else {
            console.log('No JWT recognized. Params:', params);
            console.log('Route:', this.route);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Admin Token & JWT via URL
    /////////////////////////////////////////////////////////////////////////////*/

    private subscribeToDownloadService(): void {
        this.downloadService.download$.subscribe({
            next: (downloadInfo) => {
                saveAs(downloadInfo.file, downloadInfo.fileName);
            },
        });
    }

    private subscribeToRequestRegistry(): void {
        this.requestRegistry.pendingRequestsRegistryLength.subscribe({
            next: (currentLength: number) => {
                // If all requests have finished, leave the animation running for a little longer than necessary. Most requests are just so fast that you can't see the sync.
                if (currentLength === 0) {
                    setTimeout(() => {
                        this.pendingRequestsRegistryLength = 0;
                        // Run change detection cycle after child updated this component's property.
                        //this.changeDetectorRef.detectChanges();
                    }, 500);
                } else {
                    this.pendingRequestsRegistryLength = currentLength;
                    // Run change detection cycle after child updated this component's property
                    //this.changeDetectorRef.detectChanges();
                }
            },
            error: () => console.error('Error in the observer of getPendingSyncRegistry in app.component'),
        });
    }

    //*****************************************************************************
    //  Head Runner
    //****************************************************************************/
    public isQapterixpert(): boolean {
        return isQapterixpert();
    }

    //*****************************************************************************
    //  Help Panel
    //****************************************************************************/
    private subscribeToOpenHelpPanelRequests(): void {
        this.helpPanelService.openHelpPanel$.subscribe({
            next: (page) => this.openHelpPanel(page),
        });
    }

    public openHelpPanel(page: HelpPanelPage = 'main'): void {
        const overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.helpIcon)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                ])
                .withPush(true),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        overlayRef.backdropClick().subscribe(() => overlayRef.detach());

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
                {
                    provide: HELP_PANEL_START_PAGE_TOKEN,
                    useValue: page,
                },
            ],
        });

        overlayRef.attach(new ComponentPortal(HelpPanelComponent, null, overlayRefInjector));
    }

    public downloadAnydesk(): void {
        this.isAnydeskLogoAnimationRunning = true;
        window.location.href = getAnydeskLink();

        setTimeout(() => {
            this.isAnydeskLogoAnimationRunning = false;
        }, 1_000);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Help Panel
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice History
    //****************************************************************************/
    public async openInvoiceHistory() {
        try {
            const link = await this.invoiceHistoryService.find(this.team._id);
            window.open(link, '_blank', 'noopener');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    FASTBILL_CUSTOMER_NOT_FOUND: {
                        title: 'FastBill-Eintrag nicht gefunden',
                        body: 'Der Kunde konnte nicht in FastBill gefunden werden. Das Öffnen der Rechnungshistorie ist nicht möglich.',
                    },
                },
                defaultHandler: {
                    title: 'Bisherige Rechnungen konnten nicht geöffnet werden.',
                    body: "Bitte logge dich aus und wieder ein. Tritt der Fehler erneut auf, kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice History
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  IndexedDB Storage Usage Panel
    //****************************************************************************/
    public openStorageUsagePanel(): void {
        const overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService.position().global().top('70px').right('20px'),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        overlayRef.backdropClick().subscribe(() => overlayRef.detach());

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
            ],
        });

        overlayRef.attach(new ComponentPortal(StorageUsagePanelComponent, null, overlayRefInjector));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END IndexedDB Storage Usage Panel
    /////////////////////////////////////////////////////////////////////////////*/

    public openPreferences() {
        this.router.navigate(['/Einstellungen']);
    }

    public navigateHome() {
        this.router.navigate(['/']);
    }

    public navigate(route): void {
        this.router.navigate(route);
    }

    public async openUpdateBlockedByIntrusionDialog() {
        const dialogRef = this.dialogService.open<HtmlIntrusionDialogComponent, HtmlIntrusionDialogData>(
            HtmlIntrusionDialogComponent,
            {
                data: {
                    intrusionProvider: this.appVersionUpdateService.detectedIntrusion,
                },
            },
        );

        const decision: boolean = await dialogRef.afterClosed().toPromise();
        if (decision) {
            document.location.reload();
        } else {
            console.warn(
                'The user decided to keep Kaspersky. Updates are prevented until the next reload to keep lots of errors from being thrown.',
            );
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Head Runner
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Online/Offline Status
    //****************************************************************************/
    private subscribeToNetworkStatusChanges() {
        this.networkStatusService.networkStatusChange$.subscribe({
            next: (status) => {
                this.networkStatus = status;
            },
        });
    }

    public getOfflineIndicatorTooltip(): string {
        let tooltip: string = 'Keine Verbindung zu den autoiXpert-Servern. Klicke für Verbindungsaufbau.';
        if (this.getSecondsUntilReconnect() > 0) {
            tooltip += `\n\nVerbindungsversuch in ${this.getTimeStringUntilReconnect()}.`;
        } else {
            tooltip += '\n\nVerbindungsversuch läuft...';
        }

        return tooltip;
    }

    private getSecondsUntilReconnect(): number {
        return this.networkStatusService.secondsUntilOnlineDetection$.getValue();
    }

    /**
     * If > 1 min, display time in minutes. Otherwise, display time in seconds.
     */
    public getTimeStringUntilReconnect(): string {
        const timeUntilReconnect: number = this.networkStatusService.secondsUntilOnlineDetection$.getValue();

        if (timeUntilReconnect > 60) {
            const minutes: number = Math.floor(timeUntilReconnect / 60);
            return `${minutes} min`;
        } else {
            return `${timeUntilReconnect} s`;
        }
    }

    /**
     * Manually check for network. Used if the automatic timeout is still too long.
     */
    public async triggerNetworkDetection() {
        // Don't trigger network detection twice.
        if (this.manualReconnectionInProgress) return;

        this.manualReconnectionInProgress = true;
        try {
            await Promise.allSettled([
                this.networkStatusService.detectNetworkStatus(),
                /**
                 * Wait for at least one second until the label for a manual connection attempt is hidden again. Usually, the websocket fails faster
                 * but that makes it really hard for the user to read the label "Verbindungsversuch...".
                 */
                new Promise((resolve) => setTimeout(resolve, 1_000)),
            ]);
        } catch (error) {
            console.log('Reconnection failed. This is normal during offline mode, so fail silently.');
        }
        this.manualReconnectionInProgress = false;
    }

    /**
     * Detect if the user has an Internet connection when the user focuses the tab. This shortens perceived offline time massively
     * since the user goes online as soon as
     */
    @HostListener('window:focus', ['$event'])
    async reconnectOnFocus(): Promise<void> {
        // Only try to reconnect if the device was considered offline before.
        if (this.networkStatusService.isOnline()) {
            return;
        }

        try {
            await this.networkStatusService.detectNetworkStatus();
        } catch (error) {
            console.log('Establishing network connection failed after focussing this window.', { error });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Online/Offline Status
    /////////////////////////////////////////////////////////////////////////////*/

    private static configureMoment() {
        moment.locale('de');

        // Configure luxon, the successor of moment.js.
        Settings.defaultZone = 'Europe/Berlin';
        Settings.defaultLocale = 'de';
    }

    /**
     * If the user is logged in but any of the local data is missing, log him out. After a new login, the local data should be downloaded again.
     */
    private checkForIntactCachedData(): void {
        if (
            this.authenticationService.getLocalJwt() &&
            (!this.loggedInUserService.getUser() || !this.loggedInUserService.getTeam())
        ) {
            this.loggedInUserService.clearUser();
        }
    }

    //*****************************************************************************
    //  Mobile
    //****************************************************************************/
    public toggleNavigationOnMobile(): void {
        this.isVisibleOnMobile = !this.isVisibleOnMobile;
    }

    public hideNavigationOnMobile(): void {
        this.isVisibleOnMobile = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Mobile
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Professional Association Panel
    //****************************************************************************/

    public openProfessionalAssociationPanel(): void {
        // Prevent opening the overlay twice.
        if (this.professionalAssociationsOverlayRef) return;

        if (deviceHasSmallScreen()) return;

        this.professionalAssociationsOverlayRef = this.overlayService.create({
            hasBackdrop: true,
            positionStrategy: this.overlayService.position().global().bottom('20px').right('85px'),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: this.professionalAssociationsOverlayRef,
                },
            ],
        });

        this.professionalAssociationsOverlayRef.attach(
            new ComponentPortal(ProfessionalAssociationsComponent, null, overlayRefInjector),
        );

        this.professionalAssociationsOverlayRef.detachments().subscribe({
            next: () => {
                this.professionalAssociationsOverlayRef = null;
            },
        });

        this.professionalAssociationsOverlayRef
            .backdropClick()
            .subscribe(() => this.professionalAssociationsOverlayRef.detach());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Professional Association Panel
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  First Steps Tutorial
    //****************************************************************************/
    private subscribeToFirstStepsPanelOpeningRequests() {
        this.tutorialStateService.firstStepsPanelOpeningRequests$.subscribe({
            next: () => {
                this.openIntroVideosPanel();
                this.user.userInterfaceStates.introVideosVisible = true;
                this.saveUser();
            },
        });
    }

    public openIntroVideosPanel(): void {
        if (this.firstStepsPanelOverlayRef) {
            return;
        }

        this.firstStepsPanelOverlayRef = this.overlayService.create({
            hasBackdrop: false,
            positionStrategy: this.overlayService.position().global().bottom('20px').right('35px'),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: this.firstStepsPanelOverlayRef,
                },
            ],
        });

        this.firstStepsPanelOverlayRef.attach(new ComponentPortal(IntroVideosPanelComponent, null, overlayRefInjector));

        this.firstStepsPanelOverlayRef.detachments().subscribe({
            next: () => {
                this.firstStepsPanelOverlayRef = null;
            },
        });
    }

    public closeFirstStepsPanel(): void {
        if (this.firstStepsPanelOverlayRef) {
            this.firstStepsPanelOverlayRef.dispose();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END First Steps Tutorial
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  IndexedDB Issues
    //****************************************************************************/

    /**
     * Set to true if the user's browser does not grant access to IndexedDB. Possible reasons:
     * - Incognito mode in Firefox. Incognito mode in Chrome allows using IndexedDB.
     * - IndexedDB not implemented <-- This should rarely be the case.
     */
    public isIndexeddbUnusable(): boolean {
        return this.userService.localDb.isIndexeddbUnusable;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END IndexedDB Issues
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Qapter-iXpert Forwarder
    //****************************************************************************/
    /**
     * If a Qapter-iXpert team logs into autoiXpert, display a fullscreen overlay
     * that they need to switch to the Qapter-iXpert URL.
     */
    public displaySwitchToQapterixpertOverlay() {
        // Only consider autoiXpert
        if (isQapterixpert()) return;

        if (this.team.audatexFeatures.qapterixpertLayout) {
            this.isSwitchToQapterixpertOverlayVisible = true;
        }
    }

    public hideSwitchToQapterixpertOverlay() {
        this.isSwitchToQapterixpertOverlayVisible = false;
    }

    /**
     * If the user must switch from autoiXpert to Qapter-iXpert, we log him in immediately.
     */
    public async logIntoQapterixpert() {
        const domain = environment.production ? 'app.qapter-ixpert.de' : 'qx.autoixpert.lokal';
        await sendJwtToOtherAutoixpertDomain({
            domain,
            jwt: this.authenticationService.getLocalJwt(),
        });
        window.location.href = `https://${domain}/Login?useCrossDomainLogin=true&forwardUrl=${encodeURIComponent(
            // In case the URI was previously encoded, decode it first. Otherwise, it would be double-encoded.
            decodeURIComponent(this.router.url),
        )}`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END QapteriXpert Forwarder
    /////////////////////////////////////////////////////////////////////////////*/

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    protected readonly isAdmin = isAdmin;
}

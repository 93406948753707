import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject, InjectionToken } from '@angular/core';
import { getUnpaidAmount } from '@autoixpert/lib/invoices/get-unpaid-amount';
import { round } from '@autoixpert/lib/numbers/round';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { NewWindowService } from '../../services/new-window.service';

/**
 * A menu-like list of invoices.
 *
 * It loads all invoices whose IDs have been passed.
 */
@Component({
    selector: 'invoice-list-overlay',
    templateUrl: 'invoice-list-overlay.component.html',
    styleUrls: ['invoice-list-overlay.component.scss'],
    animations: [fadeInAndSlideAnimation()],
})
export class InvoiceListOverlayComponent {
    constructor(
        @Inject(INVOICES_TOKEN) public invoices: Invoice[],
        @Inject(DISPLAY_NET_VALUES_TOKEN) public displayNetValues: string[],
        @Inject(VALUE_DISPLAY_TYPE_TOKEN) public valueDisplayType: 'totalAmount' | 'unpaidAmount',
        private newWindowService: NewWindowService,
        private overlayRef: OverlayRef,
    ) {}

    //*****************************************************************************
    //  Invoice Details
    //****************************************************************************/

    //*****************************************************************************
    //  Short Payments
    //****************************************************************************/
    public doesInvoiceHaveShortPay(invoice: Invoice): boolean {
        return invoice.payments.some((payment) => payment.type === 'shortPayment');
    }

    public getInvoiceUnpaidShortPaymentSumGross(invoice: Invoice): number {
        return invoice.payments
            .filter((payment) => payment.type === 'shortPayment')
            .reduce((total, payment) => total + payment.amount, 0);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Short Payments
    /////////////////////////////////////////////////////////////////////////////*/

    public getUnpaidAmount(invoice: Invoice, netOrGross: 'net' | 'gross'): number {
        const unpaidAmountGross = getUnpaidAmount(invoice);
        if (netOrGross === 'gross') {
            return unpaidAmountGross;
        } else {
            return round(unpaidAmountGross / (1 + invoice.vatRate));
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Details
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public openInvoice(invoice: Invoice) {
        this.newWindowService.open(`/Rechnungen/${invoice._id}`);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }
}

export const INVOICES_TOKEN = new InjectionToken('INVOICES_TOKEN');
export const DISPLAY_NET_VALUES_TOKEN = new InjectionToken('DISPLAY_NET_GROSS_INFO_TOKEN');
export const VALUE_DISPLAY_TYPE_TOKEN = new InjectionToken('DISPLAY_VALUE_TOKEN');

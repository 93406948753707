import { DocumentMetadata } from '../../models/documents/document-metadata';

export const defaultDamageReportDocuments: DocumentMetadata[] = [
    new DocumentMetadata({
        type: 'letter',
        title: 'Anschreiben',
        recipientRole: 'claimant',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'letter',
        title: 'Anschreiben',
        recipientRole: 'garage',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'letter',
        title: 'Anschreiben',
        recipientRole: 'lawyer',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'letter',
        title: 'Anschreiben',
        recipientRole: 'insurance',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'report',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'invoice',
        title: 'Rechnung',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'garageInformation',
        title: 'Werkstatt-Information',
        createdBy: undefined,
    }),
];

export const defaultValuationReportDocuments: DocumentMetadata[] = [
    new DocumentMetadata({
        type: 'letter',
        title: 'Anschreiben',
        recipientRole: 'claimant',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'letter',
        title: 'Anschreiben',
        recipientRole: 'garage',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'report',
        createdBy: undefined,
    }),
    new DocumentMetadata({
        type: 'invoice',
        title: 'Rechnung',
        createdBy: undefined,
    }),
];

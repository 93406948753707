import { LoggedInUserService } from '../services/logged-in-user.service';

export function logoutAfterNotAuthenticatedError({
    loggedInUserService,
}: {
    loggedInUserService: LoggedInUserService;
}) {
    /**
     * Logout and redirect to login screen. Remember what page the user was on.
     *
     * In case of more than one failed request, the second and later requests would trigger setting
     * the forwardUrl again. At that time, the router would already be at '/Login'. Don't set the login screen as the
     * forward URL route.
     *
     * Also, do not include query parameters since they cannot be processed by the Angular router when using this.router.navigate([this.loggedInUserService.forwardUrl]).
     */
    if (window.location.pathname !== '/Login') {
        loggedInUserService.forwardUrl = window.location.pathname;
    }
    loggedInUserService.clearUser();
}

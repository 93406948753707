import { DocumentOrderConfig } from '../../models/documents/document-order-config';
import { translateDocumentType } from './translate-document-type';

export function translateDocumentOrderConfigName({
    documentOrderConfig,
    shortVersion,
}: {
    documentOrderConfig: DocumentOrderConfig;
    shortVersion?: boolean;
}): string {
    /**
     * Claimant signature documents may have a custom title.
     */
    return (
        (shortVersion ? documentOrderConfig?.titleShort : documentOrderConfig?.titleLong) ||
        translateDocumentType(documentOrderConfig?.type, shortVersion)
    );
}

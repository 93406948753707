export function capitalize(text: string): string {
    return text[0].toUpperCase() + text.slice(1);
}

export function capitalizeHtml(htmlText: string): string {
    if (!htmlText) {
        return htmlText;
    }
    /**
     * This may be optimized. But currently, it matches HTML code from Quill well:
     * - single line of code, no line breaks
     * - no spaces between tags
     *
     * Examples:
     * - It should match the ">" and first "p" of "partial" in <p>partial</p>
     * - It should match the ">" and first "p" of "partial" in <p><span>partial</span></p>
     */
    return htmlText.replace(/>[^<\n]/, (match) => `>${capitalize(match[1])}`);
}

<h2>Aktenzeichen</h2>

<!--***********************************************
** Sub Menu
************************************************-->
<div id="report-token-preferences-toolbar-container">
    <mat-icon
        class="toolbar-icon"
        (click)="addReportTokenConfig()"
        matTooltip="Weiteren Aktenzeichenkreis hinzufügen. So kannst du pro Standort unabhängige Aktenzeichen vergeben.">
        add
    </mat-icon>
</div>
<!--***********************************************
** END Sub Menu
************************************************-->
<ng-container *ngFor="let reportTokenConfig of reportTokenConfigs">
    <h3 class="report-token-config-headline" *ngIf="reportTokenConfigs.length > 1">
        <span *ngIf="!reportTokenConfigTitlesInEditMode.has(reportTokenConfig)">
            {{ reportTokenConfig.title || getOfficeLocationTitle(reportTokenConfig) }}
        </span>

        <mat-form-field *ngIf="reportTokenConfigTitlesInEditMode.has(reportTokenConfig)">
            <input
                matInput
                [placeholder]="getOfficeLocationTitle(reportTokenConfig)"
                [(ngModel)]="reportTokenConfig.title"
                (change)="saveTeam()" />
            <span matSuffix>
                <mat-icon
                    class="input-toolbar-icon"
                    (click)="leaveTitleEditMode(reportTokenConfig)"
                    matTooltip="Bearbeitung beenden">
                    check
                </mat-icon>
            </span>
        </mat-form-field>

        <mat-icon
            id="default-icon"
            class="medium-icon toolbar-icon"
            [ngClass]="{ active: reportTokenConfig.isDefault }"
            [matTooltip]="getSetAsDefaultTooltip(reportTokenConfig)">
            star
        </mat-icon>
    </h3>
    <div class="report-token-config">
        <div class="report-token-preferences-submenu" *ngIf="reportTokenConfigs.length > 1">
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="reportTokenSubmenu">more_vert</mat-icon>
        </div>
        <mat-menu #reportTokenSubmenu>
            <!--********** Default **********-->
            <a
                mat-menu-item
                *ngIf="!reportTokenConfig.isDefault"
                class="menu-item-with-icon"
                (click)="setAsDefault(reportTokenConfig)"
                [matTooltip]="getSetAsDefaultTooltip(reportTokenConfig)">
                <mat-icon>star</mat-icon>
                als Standard setzen
            </a>

            <!--********** Rename **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="startTitleEditMode(reportTokenConfig)"
                [matTooltip]="'Aktenzeichenkreis umbenennen.'">
                <mat-icon>edit</mat-icon>
                Umbenennen
            </a>

            <!--********** Copy to other locations **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                matTooltip="Einstellungen des gewählten Aktenzeichenzählers auf alle anderen Zähler übertragen."
                [class.disabled]="reportTokenConfigs.length <= 1"
                [matMenuTriggerFor]="copySettingsSubmenu">
                <mat-icon>content_copy</mat-icon>
                Einstellungen übertragen

                <mat-menu #copySettingsSubmenu>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="copyTokenSettingsToAllLocations(reportTokenConfig)"
                        matTooltip="Kopiere das Aktenzeichenmuster auf alle anderen Aktenzeichenzähler.">
                        Muster
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="copySyncedCounterSettingsToAllLocations(reportTokenConfig)"
                        matTooltip="Kopiere die Einstellungen zur Kopplung von Aktenzeichen- und Rechnungszähler auf alle anderen Aktenzeichenzähler.

                    Umfasst
                    - den führenden Zähler (Aktenzeichen oder Rechnungsnummer)
                    - Gutachtenrechnungs-Muster
                    - Unterzähler-Einstellungen
                    - Storno-Einstellungen.">
                        Kopplung mit Rechnungszähler
                    </a>
                </mat-menu>
            </a>

            <!--********** Löschen **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon alert"
                (click)="deleteReportTokenConfig(reportTokenConfig)"
                [matTooltip]="
                    reportTokenConfig.isDefault
                        ? 'Das Standard-Aktenzeichen kann nicht gelöscht werden.'
                        : 'Aktenzeichenkreis entfernen.'
                "
                [class.disabled]="reportTokenConfig.isDefault">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>
        <!--***********************************************
        ** Token settings
        ************************************************-->

        <token-pattern-editor
            #tokenPatternEditor
            [team]="team"
            [user]="user"
            [teamMembers]="teamMembers"
            [reportTokenConfig]="reportTokenConfig"
            [counterIdToSyncWith]="getInvoiceNumberCounterIdToSyncWith(reportTokenConfig)"
            [disabled]="!userIsAdmin()"
            [showOfficeLocation]="reportTokenConfigs.length > 1"
            [allConfigs]="reportTokenConfigs"
            [showBorderBottom]="true"
            [(isExpanded)]="reportTokenConfigExpandedStates[reportTokenConfig._id]"
            [canCollapse]="reportTokenConfigs.length >= collapseThreshold"></token-pattern-editor>

        <!--***********************************************
        ** END Token settings
        ************************************************-->

        <!--============================================-->
        <!-- Sync with Report Token Counter -->
        <!--============================================-->
        <preferences-row
            iconName="assignment"
            *ngIf="
                reportTokenConfigExpandedStates[reportTokenConfig._id] || reportTokenConfigs.length < collapseThreshold
            "
            @slideInAndOutVertically>
            <span class="">Rechnungsnummern koppeln</span>
            <div class="label light">Gutachten-Rechnungen: Rechnungsnummern & Aktenzeichen im Einklang generieren?</div>

            <mat-slide-toggle
                right
                [disabled]="!userIsAdmin()"
                (change)="syncWithInvoiceCounterSettingChanged(reportTokenConfig)"
                [(ngModel)]="reportTokenConfig.syncWithInvoiceCounter"></mat-slide-toggle>

            <preferences-row *ngIf="reportTokenConfig.syncWithInvoiceCounter" @slideInAndOutVertically>
                <span class="">Wer führt?</span>
                <div class="label light">
                    Soll sich die Rechnungsnummer nach dem Aktenzeichen richten oder andersherum?
                </div>

                <button
                    id="leading-counter-button"
                    right
                    class="flat with-shadow"
                    (click)="openLeadingCounterDialog(reportTokenConfig)"
                    [ngClass]="{ disabled: !userIsAdmin() }"
                    [disabled]="!userIsAdmin()">
                    <mat-icon class="moderate-icon margin-right-5 outlined">
                        {{ reportTokenConfig.leadingCounter === 'reportToken' ? 'assignment' : 'euro_symbol' }}
                    </mat-icon>
                    {{ reportTokenConfig.leadingCounter === 'reportToken' ? 'Aktenzeichen' : 'Re-Zähler' }} führt
                    <mat-icon class="small-icon margin-left-5 label">edit</mat-icon>
                </button>
            </preferences-row>

            <preferences-row
                *ngIf="reportTokenConfig.syncWithInvoiceCounter && reportTokenConfig.leadingCounter === 'reportToken'"
                @slideInAndOutVertically>
                <span>Muster für Gutachten-Rechnungen</span>
                <div class="label light">Nach welcher Logik werden Nummern für Rechnungen in Gutachten erzeugt?</div>

                <button
                    right
                    id="report-invoice-number-example-button"
                    class="flat with-shadow"
                    (click)="openReportInvoiceNumberConfigDialog(reportTokenConfig)"
                    [ngClass]="{ disabled: !userIsAdmin() }"
                    [disabled]="!userIsAdmin()">
                    {{ getInvoiceNumberPreviewString(reportTokenConfig) }}
                    <mat-icon class="small-icon margin-left-5 label">edit</mat-icon>
                </button>
            </preferences-row>

            <div helpSection class="help-links">
                <span class="help-link">
                    <mat-icon class="small-icon outlined margin-right-5">help</mat-icon>
                    <a
                        target="_blank"
                        href="https://wissen.autoixpert.de/hc/de/articles/360003993192-Gutachtennummer-Aktenzeichen-einstellen">
                        Hilfe-Artikel
                    </a>
                </span>
                <!--                <span class="help-link">-->
                <!--                    <mat-icon class="small-icon outlined margin-right-5">play_circle</mat-icon>-->
                <!--                    <a target="_blank" href="">Video</a>-->
                <!--                </span>-->
            </div>
        </preferences-row>
        <!--============================================-->
        <!-- END Sync with Report Token Counter -->
        <!--============================================-->

        <!-- Optional Disclosure Indicator -->
        <div
            class="disclosure-icon"
            *ngIf="tokenPatternEditor.canCollapse"
            [matTooltip]="
                reportTokenConfigExpandedStates[reportTokenConfig._id]
                    ? 'Einstellungen verbergen'
                    : 'Weitere Einstellungen anzeigen'
            "
            (click)="
                reportTokenConfigExpandedStates[reportTokenConfig._id] =
                    !reportTokenConfigExpandedStates[reportTokenConfig._id]
            ">
            <mat-icon class="medium-icon">
                {{ reportTokenConfigExpandedStates[reportTokenConfig._id] ? 'unfold_less' : 'unfold_more' }}
            </mat-icon>
        </div>
    </div>
</ng-container>

<h3>Allgemein</h3>

<!--============================================-->
<!-- Insert automatically -->
<!--============================================-->
<preferences-row iconName="input">
    <span>Automatisch einfügen</span>
    <span class="label light">
        {{
            isAnyConfigSyncedWithInvoiceCounter()
                ? 'Sobald ein Vorgang angelegt wird, werden Aktenzeichen & Rechnungsnummer gesetzt.'
                : 'Sobald ein Vorgang angelegt wird, wird das Aktenzeichen gesetzt.'
        }}
    </span>

    <!-- Wrapper element necessary for tooltip, because disabled toggle does not show tooltips -->
    <div
        right
        [matTooltip]="
            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                : ''
        ">
        <mat-slide-toggle
            [(ngModel)]="team.preferences.generateReportTokenOnCreate"
            (ngModelChange)="saveTeam()"
            [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"></mat-slide-toggle>
    </div>
</preferences-row>

<!--============================================-->
<!-- END Insert automatically -->
<!--============================================-->

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { lightenAccentColor } from '@autoixpert/lib/colors/lighten-accent-color';
import { DocumentLayoutConfig, DocumentLayoutGroup } from '@autoixpert/models/documents/document-layout-group';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { getAskYourAdminTooltip } from '../../../../shared/libraries/get-ask-your-admin-tooltip';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { DocumentLayoutGroupService } from '../../../../shared/services/document-layout-group.service';
import { NewWindowService } from '../../../../shared/services/new-window.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { TutorialStateService } from '../../../../shared/services/tutorial-state.service';
import { DocumentLayoutConfigComponent } from '../document-layout-config/document-layout-config.component';

@Component({
    selector: 'document-layout-group',
    templateUrl: './document-layout-group.component.html',
    styleUrls: ['./document-layout-group.component.scss'],
})
export class DocumentLayoutGroupComponent implements OnInit {
    constructor(
        private documentLayoutGroupService: DocumentLayoutGroupService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private tutorialStateService: TutorialStateService,
        private newWindowService: NewWindowService,
    ) {}

    // This document layout group will be displayed and configured through this component.
    @Input() public documentLayoutGroup: DocumentLayoutGroup;
    // The document order configs are required to associate a layout config with a specific document type.
    @Input() public documentOrderConfigs: DocumentOrderConfig[];
    // Used for ensuring that every office location is associated with a document layout group only once.
    @Input() public availableOfficeLocations: OfficeLocation[];

    @Input() public showTitle: boolean;
    @Input() public showOfficeLocations: boolean;

    @Input() public user: User;
    @Input() public team: Team;
    @Input() public teamMembers: User[];
    @Input() disabled: boolean = false;

    @Output() default = new EventEmitter<DocumentLayoutGroup>();
    @Output() delete = new EventEmitter<DocumentLayoutGroup>();

    public isTitleEditModeActive: boolean = false;

    /**
     * This is used to allow triggering the file upload directly after creating a new document layout config.
     */
    @ViewChildren(DocumentLayoutConfigComponent)
    documentLayoutConfigQueryList: QueryList<DocumentLayoutConfigComponent>;

    @ViewChild('accentColorpicker', { static: false }) accentColorpicker: ElementRef;
    @ViewChild('damageSketchArrowAndCrossColorpicker', { static: false })
    damageSketchArrowAndCrossColorpicker: ElementRef;
    @ViewChild('secondaryAccentColorPicker', { static: false })
    secondaryAccentColorPicker: ElementRef;
    protected showDamageSketchArrowAndCrossColor: boolean = false;
    protected showSecondaryAccentColor: boolean = false;

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    ngOnInit() {
        // If the color was set before, show the color palette immediately so the user understands that a color was set.
        if (this.documentLayoutGroup.damageSketchArrowAndCrossColor) {
            this.showDamageSketchArrowAndCrossColor = true;
        }

        if (this.documentLayoutGroup.secondaryAccentColorDocx) {
            this.showSecondaryAccentColor = true;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Title
    //****************************************************************************/
    public enterTitleEditMode(): void {
        this.isTitleEditModeActive = true;
    }

    public leaveTitleEditMode(): void {
        this.isTitleEditModeActive = false;
    }

    public leaveTitleEditModeOnEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.leaveTitleEditMode();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Title
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Default
    //****************************************************************************/
    public getSetAsDefaultTooltip(): string {
        const tooltip = this.documentLayoutGroup.isDefault ? '' : 'Als Standard setzen.\n\n';

        return `${tooltip}Die Standard-Layoutgruppe gilt für alle Standorte, die mit keiner Layoutgruppe verknüpft sind.`;
    }

    public setAsDefault() {
        this.documentLayoutGroup.isDefault = true;
        this.saveDocumentLayoutGroup();

        // Allow the parent component to remove the default flags from all other layout configs.
        this.default.emit(this.documentLayoutGroup);
    }

    /**
     * When a document layout config within this group was set as default, remove the default flag from the old
     * default document layout config.
     */
    public removeDefaultFromOtherLayoutConfigs(newDefaultDocumentLayoutConfig: DocumentLayoutConfig) {
        for (const documentLayoutConfig of this.documentLayoutGroup.configs) {
            if (documentLayoutConfig._id !== newDefaultDocumentLayoutConfig._id && documentLayoutConfig.isDefault) {
                documentLayoutConfig.isDefault = false;
            }
        }
        this.saveDocumentLayoutGroup();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default
    /////////////////////////////////////////////////////////////////////////////*/

    public getAskYourAdminTooltip(): string {
        return getAskYourAdminTooltip(this.team, this.teamMembers);
    }

    //*****************************************************************************
    //  Colors
    //****************************************************************************/
    public openAccentColorpicker(): void {
        // Simulate a click on the hidden color input element
        const event = new MouseEvent('click');
        const colorpickerHTMLElement = this.accentColorpicker.nativeElement;
        colorpickerHTMLElement.dispatchEvent(event);
    }

    public setAccentColorDocx(accentColorDocx: string): void {
        this.documentLayoutGroup.accentColorDocx = accentColorDocx;
        this.saveDocumentLayoutGroup();

        this.tutorialStateService.markTeamSetupStepComplete('headerAndFooterTemplate');
    }

    public openDamageSketchArrowAndCrossColorpicker(): void {
        // Simulate a click on the hidden color input element
        const event = new MouseEvent('click');
        const colorpickerHTMLElement = this.damageSketchArrowAndCrossColorpicker.nativeElement;
        colorpickerHTMLElement.dispatchEvent(event);
    }

    protected setDamageSketchArrowAndCrossColor(accentColorDocx: string): void {
        this.documentLayoutGroup.damageSketchArrowAndCrossColor = accentColorDocx;
        this.saveDocumentLayoutGroup();
    }

    public resetDamageSketchArrowAndCrossColor(): void {
        this.documentLayoutGroup.damageSketchArrowAndCrossColor = null;
        this.saveDocumentLayoutGroup();
    }

    protected showArrowAndCrossColor(): void {
        this.showDamageSketchArrowAndCrossColor = true;
        this.setDamageSketchArrowAndCrossColor(this.documentLayoutGroup.accentColorDocx);
    }

    public hideArrowAndCrossColor(): void {
        this.showDamageSketchArrowAndCrossColor = false;

        this.resetDamageSketchArrowAndCrossColor();

        this.saveDocumentLayoutGroup();
    }

    protected setSecondaryAccentColor(secondaryAccentColorDocx: string): void {
        this.documentLayoutGroup.secondaryAccentColorDocx = secondaryAccentColorDocx;
        this.saveDocumentLayoutGroup();
    }

    protected showSecondaryAccentColorSettings(): void {
        this.showSecondaryAccentColor = true;
        this.setSecondaryAccentColor(lightenAccentColor(this.documentLayoutGroup.accentColorDocx));
    }

    protected openSecondaryAccentColorPicker(): void {
        // Simulate a click on the hidden color input element
        const event = new MouseEvent('click');
        const colorPickerHTMLElement = this.secondaryAccentColorPicker.nativeElement;
        colorPickerHTMLElement.dispatchEvent(event);
    }

    protected resetSecondaryAccentColor(): void {
        this.documentLayoutGroup.secondaryAccentColorDocx = null;
        this.saveDocumentLayoutGroup();
    }

    protected hideSecondaryAccentColor(): void {
        this.showSecondaryAccentColor = false;
        this.resetSecondaryAccentColor();
        this.saveDocumentLayoutGroup();
    }

    protected openHelpCenterArticleOnSecondaryAccentColor(): void {
        this.newWindowService.open(
            'https://wissen.autoixpert.de/hc/de/articles/23094912795666-Abweichende-zweite-Akzentfarbe-einstellen',
            '_blank',
            'noopener',
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Colors
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Event handler for when a document layout config changes.
     */
    public saveDocumentLayoutGroup() {
        this.documentLayoutGroupService.put(this.documentLayoutGroup);
    }

    //*****************************************************************************
    //  Create & Delete Document Layout Config
    //****************************************************************************/
    public addDocumentLayoutConfig() {
        const defaultDocumentLayoutConfig = this.documentLayoutGroup.configs.find(
            (documentLayoutConfig) => documentLayoutConfig.isDefault === true,
        );

        const documentLayoutConfig = new DocumentLayoutConfig({
            // Use the default's template style by default.
            documentTemplateStyle: defaultDocumentLayoutConfig.documentTemplateStyle,
            title: 'Neues Briefpapier',
        });
        this.documentLayoutGroup.configs.push(documentLayoutConfig);
        this.saveDocumentLayoutGroup();

        // Ensure the new document layout config has a valid component for which the file upload may be triggered.
        this.changeDetectorRef.detectChanges();
        const newDocumentLayoutConfigComponent = this.documentLayoutConfigQueryList.find(
            (documentLayoutConfigComponent) =>
                documentLayoutConfigComponent.documentLayoutConfig._id === documentLayoutConfig._id,
        );
        newDocumentLayoutConfigComponent.openFileSelector();
    }

    public removeDocumentLayoutConfig(documentLayoutConfig: DocumentLayoutConfig) {
        removeFromArray(documentLayoutConfig, this.documentLayoutGroup.configs);
    }

    /**
     * When the layout group is deleted, delete all layout configs, too.
     */
    public async deleteDocumentLayoutGroup(): Promise<void> {
        if (this.documentLayoutGroup.isDefault) {
            this.toastService.error(
                'Standard nicht löschbar',
                'Die als Standard markierte Kopf- & Fußzeile kann nicht gelöscht werden.',
            );
            return;
        }

        //*****************************************************************************
        //  Document Layout Configs
        //****************************************************************************/
        const promises: Promise<any>[] = [];
        for (const documentLayoutConfig of this.documentLayoutGroup.configs) {
            const documentLayoutConfigComponent = this.documentLayoutConfigQueryList.find(
                (documentLayoutConfigComponent) =>
                    documentLayoutConfigComponent.documentLayoutConfig._id === documentLayoutConfig._id,
            );
            promises.push(documentLayoutConfigComponent.removeHeaderAndFooterTemplateFromServer());
        }

        try {
            await Promise.all(promises);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Kopf- & Fußzeilen nicht gelöscht',
                    body: 'Es ist ein technischer Fehler aufgetreten. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Document Layout Configs
        /////////////////////////////////////////////////////////////////////////////*/

        // Deleting this will cause an event through the offline-sync service base that is being listened to in this component.
        this.documentLayoutGroupService.delete(this.documentLayoutGroup._id);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create & Delete Document Layout Config
    /////////////////////////////////////////////////////////////////////////////*/
}

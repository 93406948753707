import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { getReportCustomerName } from '@autoixpert/lib/report/report-customer-name';
import { Report } from '@autoixpert/models/reports/report';
import { trackById } from 'src/app/shared/libraries/track-by-id';
import { ReportService } from 'src/app/shared/services/report.service';
import { ToastService } from 'src/app/shared/services/toast.service';

/**
 * Dropdown for selecting an example report.
 * This example report is used to:
 *   - Preview the evaluation of the conditions of a document building block or a document building block variant.
 *   - Preview the text with the placeholders replaced by the example data.
 */
@Component({
    selector: 'select-report-dropdown',
    templateUrl: './select-report-dropdown.component.html',
    styleUrls: ['./select-report-dropdown.component.scss'],
    host: { '[class.dark]': 'theme === "dark"', '[class.light]': 'theme === "light"' },
})
export class SelectReportDropdownComponent implements AfterViewInit {
    constructor(
        private router: Router,
        private toastService: ToastService,
        private reportService: ReportService,
    ) {}

    @ViewChild('exampleReportDropdownTrigger') exampleReportDropdownTrigger?: MatMenuTrigger;
    @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
    @ViewChild(CdkVirtualScrollViewport) virtualScroll?: CdkVirtualScrollViewport;

    @Input() report: Report | null = null;
    @Input() theme: 'dark' | 'light' = 'dark';
    @Output() reportSelected = new EventEmitter<Report | null>();

    public reports: Report[] = [];
    public filteredReports: Report[] = [];

    protected searchTerm = '';
    public searchTermSubscription: Subscription;
    public searchTerm$: Subject<string> = new Subject<string>();
    public searchPending: boolean = false;
    public initialSearchResults: boolean = false;

    readonly trackById = trackById;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected getReportCustomerName = getReportCustomerName;

    focusInput() {
        this.searchInput?.nativeElement?.focus();
    }

    handleSearchInputChange($event) {
        const searchQuery: string = ($event.target.value || '').trim();
        this.updateSearchTerm(searchQuery);
        this.filterReportsAutocomplete();
    }

    public initializeReportConnectionInput(): void {
        this.searchTermSubscription = this.searchTerm$
            .pipe(
                filter((searchTerm) => this.hasValidSearchTerm(searchTerm)),
                tap(() => {
                    this.searchPending = true;
                }),
                debounceTime(300),
                switchMap((searchTerm) =>
                    this.reportService.searchReportsWithoutPagination({ $search: searchTerm, $limit: 20 }),
                ),
            )
            .subscribe({
                next: (reports) => {
                    this.searchPending = false;
                    this.reports = reports;
                    this.sortReports();
                    this.filterReportsAutocomplete();
                },
                error: () => {
                    this.toastService.error(
                        'Fehler bei Suche',
                        "Bitte kontaktiere die <a href='/Hilfe' target='_blank'>Hotline</a>.",
                    );
                    this.searchPending = false;
                },
            });
    }

    public filterReportsAutocomplete(): void {
        const searchTerms = (this.searchTerm || '').split(' ');

        this.filteredReports = this.reports.filter((report) => {
            const propertiesToBeSearched = [
                (report.token || '').toLowerCase(),
                (report.car.licensePlate || '').toLowerCase(),
                (report.claimant.contactPerson.organization || '').toLowerCase(),
                (report.claimant.contactPerson.firstName || '').toLowerCase(),
                (report.claimant.contactPerson.lastName || '').toLowerCase(),
            ];

            return searchTerms.every((searchTerm) => {
                searchTerm = (searchTerm || '').toLowerCase();
                return propertiesToBeSearched.some((property) => property.includes(searchTerm));
            });
        });
    }

    public sortReports(): void {
        this.reports.sort((reportA, reportB) => {
            const tokenA = (reportA.token || '').toLowerCase().trim();
            const tokenB = (reportB.token || '').toLowerCase().trim();

            return tokenA.localeCompare(tokenB);
        });
    }

    public updateSearchTerm(searchTerm: string): void {
        this.searchTerm = searchTerm;
        this.searchTerm$.next(searchTerm);
    }

    public navigateToReport(report: Report): void {
        this.router.navigateByUrl(`/Gutachten/${report._id}`);
    }

    public navigateToInvoice(invoiceId: string): void {
        this.router.navigateByUrl(`/Rechnungen/${invoiceId}/Editor`);
    }

    public handleOpenInNewClick(reportId: string, event: MouseEvent): void {
        window.open(`/Gutachten/${reportId}`);
        // Prevent the click from selecting the autocomplete entry
        event.stopPropagation();
    }

    public clearSearchInput() {
        if (this.searchInput.nativeElement) {
            this.searchInput.nativeElement.value = '';
        }

        this.searchTerm = '';
        this.updateSearchTerm('');
        this.filterReportsAutocomplete();
    }

    public handleReportClick(report: Report) {
        this.exampleReportDropdownTrigger?.closeMenu();
        this.reportSelected.emit(report);
    }

    public handleRemoveReportClick(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.exampleReportDropdownTrigger?.closeMenu();
        this.reportSelected.emit(null);
    }

    /**
     * Ensure that a search term has 3 or more characters. Otherwise, searches like "a" are so unspecific
     * that our backend server collapses under the load because for large customer accounts, thousand of reports
     * are loaded into memory.
     *
     * Three characters allow for searches like "BMW" oder "Audi".
     *
     * Also allow searches if there are two or more search words separated by space. That allows to search for license plates
     * like "PB SL".
     */
    public hasValidSearchTerm(searchTerm: string) {
        if (!searchTerm || typeof searchTerm !== 'string') return;

        // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
        const searchTermParts = searchTerm
            .trim()
            .split(' ')
            .filter((searchTerm) => !!searchTerm.trim());
        return searchTermParts.some((searchTermPart) => searchTermPart.length >= 3);
    }

    initializeOverlay() {
        this.searchPending = false;
        this.clearSearchInput();
        this.focusInput();
        this.virtualScroll?.checkViewportSize();

        if (!this.initialSearchResults) {
            this.initialSearchResults = true;
            this.searchPending = true;
            this.reportService
                .find({
                    $limit: 20,
                    $sort: { createdAt: -1 },
                    // Exclude reports in trash
                    deletedAt: null,
                })
                .pipe(take(1))
                .subscribe((reports) => {
                    this.searchPending = false;
                    this.reports = reports;
                    this.sortReports();
                    this.filterReportsAutocomplete();
                });
        }
    }

    ngAfterViewInit() {
        this.initializeReportConnectionInput();
    }
}

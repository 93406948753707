import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { getUserFullName } from '@autoixpert/lib/users/get-user-full-name';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { trackById } from '../../libraries/track-by-id';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'user-selector-list',
    templateUrl: './user-selector-list.component.html',
    styleUrls: ['./user-selector-list.component.scss'],
    animations: [
        fadeInAndSlideAnimation({
            duration: 150,
            slideDistance: 10,
        }),
    ],
})
export class UserSelectorListComponent {
    readonly trackById = trackById;

    constructor(
        private userService: UserService,
        private loggedInUserService: LoggedInUserService,
    ) {}

    ngOnInit() {
        this.loggedInUser = this.loggedInUserService.getUser();
        this.allUsers = this.userService.getAllTeamMembersFromCache();

        // Search for users when the search term changes
        this.subscriptions.push(
            this.searchUsers$$
                .pipe(
                    distinctUntilChanged((a, b) => a.searchTerm === b.searchTerm),
                    tap(() => this.applySearchTermToUsers(this.searchTerm)),
                    debounceTime(300),
                    switchMap(({ searchTerm }) => this.searchForUsers({ searchTerm })),
                )
                .subscribe(),
            // Subscribe to user changes to update the user list & profile pictures
            this.userService.patchedInLocalDatabase$.subscribe(({ patchedRecord: user }) => {
                this.allUsers = this.allUsers.map((existingUser) =>
                    existingUser._id === user._id ? user : existingUser,
                );
            }),
        );

        // Initialize the user list
        this.searchUsers$$.next({ searchTerm: '' });
    }

    @Input() isDisabled: boolean = false;
    @Input() theme: 'light' | 'dark' = 'light';
    @Input() loggedInUserIsFirstResult: boolean = false;
    @Input() isNoSelectedUserAllowed: boolean = false;

    @Output() userSelected = new EventEmitter<User>();

    // This is used to close the list when the user clicks outside of the list if the list is in an overlay
    @Output() closeList = new EventEmitter<void>();

    @ViewChild(CdkVirtualScrollViewport) searchResultsViewport?: CdkVirtualScrollViewport;
    @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

    protected loggedInUser?: User;
    protected selectedUser?: User;
    protected allUsers: User[] = [];
    protected filteredUsers: User[] = [];

    private subscriptions: Subscription[] = [];

    private searchUsers$$ = new Subject<{ searchTerm: string }>();

    protected searchTerm: string = '';
    protected focusedUserIndex: number = 0;
    protected isSearchPending: boolean = false;

    protected getUserFullName(user: User | undefined): string {
        return getUserFullName(user, 'Nutzer auswählen');
    }

    //*****************************************************************************
    //  User Selection Menu
    //****************************************************************************/
    protected setFocusedUserIndex(index: number): void {
        this.focusedUserIndex = index;
    }

    protected moveFocusedUserIndex(delta: 1 | -1): void {
        const newIndex = (this.focusedUserIndex + delta + this.filteredUsers.length) % this.filteredUsers.length;
        this.setFocusedUserIndex(newIndex);
    }

    protected confirmFocusedUserSelection(): void {
        const focusedUser = this.filteredUsers[this.focusedUserIndex];
        if (focusedUser) {
            this.emitUserSelection(focusedUser);
        }
    }

    protected handleSearchInputChange(event: Event): void {
        const searchTerm = ((event.target as HTMLInputElement).value || '').trim();
        this.searchTerm = searchTerm;
        this.searchUsers$$.next({ searchTerm });
    }

    protected clearAndFocusSearchInput() {
        this.searchTerm = '';
        const searchInputEl = this.searchInput?.nativeElement;
        this.searchUsers$$.next({ searchTerm: '' });
        if (searchInputEl) {
            searchInputEl.value = '';
            searchInputEl.focus();
        }
    }

    private applySearchTermToUsers(searchTerm: string) {
        this.filteredUsers = this.allUsers.filter((user) => {
            // Allow all orders of search term fragments (split by whitespace and comma)
            const searchFragments = searchTerm
                .split(/[\s,]+/)
                .map((fragment) => fragment.trim())
                .filter((fragment) => !!fragment);

            return searchFragments.every((fragment) => {
                const searchRegex = new RegExp(fragment, 'i');
                return searchRegex.test(user.firstName || '') || searchRegex.test(user.lastName || '');
            });
        });
        this.sortFilteredUsers();

        setTimeout(() => this.searchResultsViewport?.checkViewportSize());
    }

    private searchForUsers({ searchTerm }: { searchTerm: string }) {
        this.isSearchPending = true;
        return this.userService
            .find({
                active: true,
                $sort: 'firstName',
                $limit: 50,
            })
            .pipe(
                tap((users) => {
                    this.allUsers = [...users];
                    this.isSearchPending = false;
                    this.focusedUserIndex = 0;
                    this.applySearchTermToUsers(searchTerm);
                }),
            );
    }

    private sortFilteredUsers() {
        // Sort by firstName, then lastName, then email
        this.filteredUsers = this.filteredUsers.sort((u1, u2) => {
            if (this.loggedInUserIsFirstResult) {
                if (u1._id === this.loggedInUser?._id) return -1;
                if (u2._id === this.loggedInUser?._id) return 1;
            }

            return (
                (u1.firstName || '').localeCompare(u2.firstName || '') ||
                (u1.lastName || '').localeCompare(u2.lastName || '') ||
                (u1.email || '').localeCompare(u2.email || '')
            );
        });
    }

    protected emitUserSelection(user: User) {
        this.userSelected.emit(user);
        this.closeOverlay();
    }

    protected initializeMenu() {
        this.focusedUserIndex = 0;
        this.clearAndFocusSearchInput();
        this.filteredUsers = [...this.allUsers];
        this.sortFilteredUsers();

        this.searchResultsViewport?.checkViewportSize();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END User Selection Menu
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    protected closeOverlay() {
        this.closeList.emit();
    }

    //*****************************************************************************
    //  Host Bindings & Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeOverlay();
                break;
        }
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Host Bindings & Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

import { generateId } from '../../lib/generate-id';

export class CustomFieldDropdownOption {
    constructor(template: Partial<CustomFieldDropdownOption> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId(); // Necessary for merging arrays during sync between tabs and devices.
    value: string;
}

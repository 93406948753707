import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    Output,
} from '@angular/core';
import { Label } from '@autoixpert/models/labels/label';
import { LabelGroup } from '@autoixpert/models/labels/label-config';
import { LabelDropdownOverlayComponent } from './label-dropdown-overlay.component';

@Directive({
    selector: '[labelDropdown]',
    exportAs: 'labelDropdownAnchor',
})
export class LabelDropdownAnchorDirective {
    constructor(
        private overlayService: Overlay,
        private elementRef: ElementRef,
        private injector: Injector,
    ) {}

    @Input() selectedLabels: Label[] = [];
    @Input() labelGroup: LabelGroup;

    @Output() selectedLabelsChange: EventEmitter<Label[]> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    private overlayRef: OverlayRef;
    private componentRef: ComponentRef<LabelDropdownOverlayComponent>;

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openOverlay($event: MouseEvent) {
        // Prevent opening the detail view of a report or task.
        $event.stopPropagation();
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 5,
                    },
                ])
                .withPush(true)
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
            // minWidth: 200,
            // maxWidth: 250,
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
            this.close.emit();
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<LabelDropdownOverlayComponent>(
            LabelDropdownOverlayComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.overlayRef,
                    },
                ],
            }),
        );

        // Attach Component to Portal Outlet
        this.componentRef = this.overlayRef.attach(componentPortal);

        // Update component properties.
        this.componentRef.instance.selectedLabels = this.selectedLabels;
        this.componentRef.instance.labelGroup = this.labelGroup;

        // Subscribe to changes in component.
        this.componentRef.instance.selectedLabelsChange.subscribe((selectedLabels) => {
            this.selectedLabelsChange.emit(selectedLabels);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class OriginalRenderedDocumentBuildingBlocksService {
    constructor(private httpClient: HttpClient) {}

    private apiPath: string = '/api/v0';

    public find(reportId: string): Observable<any> {
        return this.httpClient.get(`${this.apiPath}/reports/${reportId}/originalRenderedDocumentBuildingBlocks`);
    }
}

import { Report } from '../../models/reports/report';

export function getDatVehicleValueByPriceType({
    report,
    vehicleValueType,
    netOrGross,
}: {
    report: Report;
    vehicleValueType: Report['valuation']['vehicleValueType'];
    netOrGross: 'net' | 'gross';
}): number {
    const datValueType = getDatVehicleValueForPriceType(vehicleValueType);

    switch (datValueType) {
        case 'dealerPurchasePrice':
            return netOrGross === 'net'
                ? report.valuation.datValuation.dealerPurchasePrice
                : report.valuation.datValuation.dealerPurchasePriceGross;
        case 'dealerSalesPrice':
            return netOrGross === 'net'
                ? report.valuation.datValuation.dealerSalesPrice
                : report.valuation.datValuation.dealerSalesPriceGross;
        case null:
            return null;
        default:
            throw Error('UNKNOWN_VEHICLE_VALUE_TYPE');
    }
}

export function getDatVehicleValueForPriceType(
    vehicleValueType: Report['valuation']['vehicleValueType'],
): 'dealerPurchasePrice' | 'dealerSalesPrice' | null {
    switch (vehicleValueType) {
        case 'dealerPurchase':
        case 'presentValue':
            return 'dealerPurchasePrice';
        case 'dealerSales':
        case 'marketValue':
        case 'replacementValue' /* Replacement value and dealer sales price are equal because dealers usually check platforms like mobile.de for prices of old cars and determine the purchase price for cars before they buy them. (According to Mr. Möhrle, GTÜ)*/:
            return 'dealerSalesPrice';
        case null:
        case undefined:
            return null;
        default:
            throw Error('UNKNOWN_VEHICLE_VALUE_TYPE');
    }
}

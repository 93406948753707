<!-- Assessor picker -->
<div
    class="assessor-picker"
    *ngIf="assessors.length > 1 || assessorIds.length || showWithSingleAssessor"
    [ngClass]="{ active: assessorIds.length > 0 }">
    <mat-icon class="assessor-picker-icon">person</mat-icon>
    <div class="ax-header-select" [ngClass]="{ active: assessorIds.length > 0 }" [matMenuTriggerFor]="assessorsMenu">
        <!-- Label -->
        <div class="ax-header-select-label">
            <!-- No assessor picked placeholder -->
            <span *ngIf="assessorIds.length === 0">Gutachter</span>

            <!-- Single assessor picked -->
            <span *ngIf="assessorIds.length === 1">
                {{ getAssessorNameById(assessorIds[0]) }}
            </span>

            <!-- Multiple assessors picked -->
            <span *ngIf="assessorIds.length > 1">{{ assessorIds.length }} Gutachter</span>
        </div>

        <!-- Dropdown icon -->
        <mat-icon>arrow_drop_down</mat-icon>
    </div>
</div>

<!-- Assessors picker overlay -->
<mat-menu #assessorsMenu class="assessor-menu">
    <!--********** Clear **********-->
    <a mat-menu-item (click)="clearAssessors()">
        <div class="clear-menu-item">Kein Gutachterfilter</div>
    </a>

    <!--********** Assessors **********-->
    <ng-container *ngFor="let assessor of assessors; trackBy: trackById">
        <a mat-menu-item class="menu-item-with-icon" (click)="toggleAssessorId(assessor._id)">
            <!--********** Assessor **********-->
            <div class="assessor-container flex-grow">
                <div class="assessor-item">
                    <!--********** Assessor Title **********-->
                    <div class="assessor-title" [matTooltip]="getAssessorNameById(assessor._id)">
                        {{ getAssessorNameById(assessor._id) }}
                    </div>
                </div>
            </div>

            <!--********** Multiselect **********-->
            <mat-checkbox
                [checked]="assessorIds.includes(assessor._id)"
                (change)="toggleAssessorId(assessor._id)"
                (click)="$event.stopPropagation()"></mat-checkbox>
        </a>

        <!-- No assessors placeholder -->
        <div *ngIf="assessors.length === 0" class="no-assessor-placeholder">Keine Gutachter vorhanden.</div>
    </ng-container>
</mat-menu>

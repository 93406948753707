import { animate, style, transition, trigger } from '@angular/animations';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyFloatLabelType as FloatLabelType } from '@angular/material/legacy-form-field';
import { Observable, Subscription, of } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import {
    CustomAutocompleteEntry,
    CustomAutocompleteEntryType,
} from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';
import { ApiErrorService } from '../../services/api-error.service';
import { CustomAutocompleteEntriesService } from '../../services/custom-autocomplete-entries.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'autocomplete-with-memory',
    templateUrl: 'autocomplete-with-memory.component.html',
    styleUrls: ['autocomplete-with-memory.component.scss'],
    host: {
        class: 'mat-block',
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteWithMemory),
            multi: true,
        },
    ],
    animations: [
        // Animate the path of the checkmark, so that it builds up gradually
        // This is based on SVG line animation (https://css-tricks.com/svg-line-animation-works/)
        trigger('animateCheckmarkPath', [
            transition(':enter', [
                // stroke-dashoffset must be the length of the checkmark path. Retrieved using path.getTotalLength()
                style({ 'stroke-dashoffset': '22.850756' }),
                animate(`300ms 0ms ease-in-out`, style({ 'stroke-dashoffset': 0 })),
            ]),
        ]),
        // Animate a small circle around the checkmark that gets bigger while fading out
        trigger('animateCheckmarkCircle', [
            transition(':enter', [
                style({ transform: 'scale(1)', opacity: 0.5 }),
                animate(`600ms 0ms ease-in-out`, style({ transform: 'scale(2)', opacity: 0 })),
            ]),
        ]),
        // Fade out the whole checkmark (container) at the end. Separate animation necessary
        // because :leave breaks when the animation is placed on an inner html element
        trigger('fadeOutCheckmark', [transition(':leave', [animate(`150ms`, style({ opacity: 0 }))])]),
        fadeInAndOutAnimation(),
    ],
})
export class AutocompleteWithMemory implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    constructor(
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private toastService: ToastService,
        private apiErrorService: ApiErrorService,
        private loggedInUserService: LoggedInUserService,
        private dialog: MatDialog,
    ) {}

    @Input() placeholder: string;
    @Input() floatLabel: FloatLabelType;
    @Input() autocompleteEntryType: CustomAutocompleteEntryType;
    @Input() defaultAutocompleteEntries: CustomAutocompleteEntry[] = [];
    // If there are multiple autocompletes, it's more performant to load the entries once externally and pass them to this component.
    @Input() loadedAutocompleteEntries: CustomAutocompleteEntry[];
    @Input() axAutofocus: boolean;

    @Input() useTextarea: boolean;
    @Input() disabled: boolean;
    @Output() change: EventEmitter<string> = new EventEmitter<string>();
    @Output() blur: EventEmitter<{ isAutocompleteOpen: boolean }> = new EventEmitter();
    @Output() entryAdded: EventEmitter<CustomAutocompleteEntry> = new EventEmitter<CustomAutocompleteEntry>();
    @Output() entryRemoved: EventEmitter<CustomAutocompleteEntry> = new EventEmitter<CustomAutocompleteEntry>();

    /**
     * Hint displayed below the input field. Fades in/out when set and removed.
     */
    @Input() hint: string;

    @ViewChild('autocomplete') autocomplete: MatAutocomplete;

    public user: User;

    public value: string;
    private onNgModelChange: (value: string) => void;
    private onTouched: () => void;

    public autocompleteEntries: CustomAutocompleteEntry[] = [];
    public filteredAutocompleteEntries: CustomAutocompleteEntry[] = [];

    private subscriptions: Subscription[] = [];

    /**
     * Flag that toggles the green confirmation checkmark that is
     * shown when the user pressed the plus icon to remember an option.
     */
    protected showConfirmationIcon: boolean = false;

    /**
     * Stores the ID of the timeout that removes the checkmark icon (that is
     * shown after the user remembered an option). Used to cancel the timeout.
     */
    private hideConfirmationIconTimeout: number;

    /**
     * How long the confirmation checkmark is shown (until it fades out).
     */
    private readonly confirmationDurationInMs = 1500;

    ngOnInit() {
        this.retrieveAutocompleteEntries();

        this.user = this.loggedInUserService.getUser();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['loadedAutocompleteEntries']) {
            this.autocompleteEntries = [...this.defaultAutocompleteEntries, ...this.loadedAutocompleteEntries];
            this.sortAutocompleteEntries();
            this.filterAutocomplete();
        }
    }

    //*****************************************************************************
    //  Autocomplete
    //****************************************************************************/
    //*****************************************************************************
    //  Custom Autocomplete Entries
    //****************************************************************************/

    public retrieveAutocompleteEntries(): void {
        // If the parent provided entries already, don't reload automatically.
        let autocompleteEntriesSource: Observable<CustomAutocompleteEntry[]>;
        if (this.loadedAutocompleteEntries) {
            autocompleteEntriesSource = of(this.loadedAutocompleteEntries);
        } else {
            autocompleteEntriesSource = this.customAutocompleteEntriesService.find({
                type: this.autocompleteEntryType,
            });
        }

        const subscription = autocompleteEntriesSource.subscribe({
            next: (entries) => {
                this.autocompleteEntries = [...this.defaultAutocompleteEntries, ...entries];

                this.sortAutocompleteEntries();
                this.filterAutocomplete();
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Eigene Autocomplete-Werte konnten nicht geholt werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });

        this.subscriptions.push(subscription);
    }

    public customEntryExists(entry: string): boolean {
        return !!this.autocompleteEntries.find((existingEntry) => existingEntry.value === entry);
    }

    public rememberCustomEntry(newEntry: string): void {
        // Don't remember empty items
        if (!(newEntry || '').trim()) return;

        // Don't remember duplicates
        if (this.customEntryExists(newEntry)) return;

        const newAutocompleteEntry = new CustomAutocompleteEntry({
            type: this.autocompleteEntryType,
            value: newEntry,
        });

        this.autocompleteEntries.push(newAutocompleteEntry);
        // Push into the shared array too.
        this.loadedAutocompleteEntries?.push(newAutocompleteEntry);

        this.sortAutocompleteEntries();

        this.entryAdded.emit(newAutocompleteEntry);

        this.customAutocompleteEntriesService.create(newAutocompleteEntry);

        // tell the user that the action was successful
        this.showInPlaceConfirmation();
    }

    /**
     * Fade in a small green checkmark icon as suffix the input field to
     * confirm that the option was remembered. Fades out automatically after
     * a defined amount of time.
     */
    private showInPlaceConfirmation() {
        // Clear the previous timeout so we don't remove the confirmation icon
        // in case the user triggered the animation again before the timeout finished
        clearTimeout(this.hideConfirmationIconTimeout);
        this.showConfirmationIcon = true;

        this.hideConfirmationIconTimeout = window.setTimeout(() => {
            this.showConfirmationIcon = false;
        }, this.confirmationDurationInMs);
    }

    public async forgetAutocompleteEntry(autocompleteEntry: CustomAutocompleteEntry): Promise<void> {
        // Do not remove the entry if the user is not allowed to.
        if (!this.user?.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.toastService.info(
                'Vorlage nicht gelöscht',
                'Du hast keine Berechtigung, Textbausteine und Vorlagen zu verändern. Bitte wende dich an deinen Administrator.',
            );
            return;
        }

        // For standard options (that include a tooltip), ask the user for confirmation
        if (autocompleteEntry.tooltip) {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Standard-Eintrag löschen?',
                        content:
                            'Er wird möglicherweise in Textbausteinbedingungen verwendet.\nLegst du später einen Eintrag mit gleichem Text wieder an, greifen die Textbausteinbedingungen wieder, die Tooltips werden allerdings nicht wiederhergestellt.',
                        confirmLabel: 'Trotzdem löschen',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) return;
        }

        removeFromArray(autocompleteEntry, this.autocompleteEntries);
        // Remove from the shared array too.
        if (this.loadedAutocompleteEntries) {
            removeFromArray(autocompleteEntry, this.loadedAutocompleteEntries);
        }
        this.filterAutocomplete();

        this.entryRemoved.emit(autocompleteEntry);

        this.customAutocompleteEntriesService.delete(autocompleteEntry._id);

        // If the user deletes an option we always hide the checkmark icon. This
        // ensures that there will never be the plus + checkmark icon at the same time.
        this.showConfirmationIcon = false;
    }

    private sortAutocompleteEntries(): void {
        this.autocompleteEntries.sort((entryA, entryB) => {
            // If the index is the same, sort by title. This sorts the explicitly indexed positions to the front. After that, we sort alphabetically.
            if (entryA.sortIndex == entryB.sortIndex) {
                return entryA.value.localeCompare(entryB.value);
            }
            // Sort smaller index to the front.
            return entryA.sortIndex - entryB.sortIndex;
        });
    }

    public filterAutocomplete(): void {
        const searchTerms = (this.value || '').toLowerCase().split(' ');
        this.filteredAutocompleteEntries = this.autocompleteEntries.filter((entry) => {
            // Show all entries including all search terms, no matter the position, to allow the user to find an entry whose wording he does not fully know.
            const entryValue = entry.value.toLowerCase();
            return searchTerms.every((searchTerm) => entryValue.includes(searchTerm));
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Control Value Accessor
    //****************************************************************************/
    // The ControlValueAccessor allows us to use [(ngModel)] on this component.

    public writeValue(value: string) {
        this.value = value;
    }

    public registerOnChange(fn: any) {
        this.onNgModelChange = fn;
    }

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Control Value Accessor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitNgModelChange() {
        this.onNgModelChange(this.value);
        this.onTouched();
    }

    public emitChange() {
        this.change.emit(this.value);
    }

    public emitBlur() {
        this.blur.emit({ isAutocompleteOpen: this.autocomplete.isOpen });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

<ng-container *ngIf="report.car.afzzertProcess">
    <h2>Altfahrzeugzertifikat</h2>

    <!--============================================-->
    <!-- Old Vehicle Certificate Settings - Read Mode -->
    <!--============================================-->
    <div id="settings-container">
        <!--********** Target Date **********-->
        <div
            id="certificate-date"
            class="flex-center-content"
            matTooltip="Datum des Altfahrzeugzertifikats"
            datePickerWithInputAnchor
            [disabled]="isReportLocked()"
            [class.disabled]="isReportLocked()"
            [(date)]="report.car.afzzertProcess.certificateDate"
            (dateChange)="saveReport()">
            <mat-icon class="calendar-icon medium-icon">today</mat-icon>
            <span *ngIf="report.car.afzzertProcess.certificateDate">
                {{ report.car.afzzertProcess.certificateDate | moment: 'DD.MM.YYYY' }}
            </span>
            <span *ngIf="!report.car.afzzertProcess.certificateDate" class="certificate-date-missing">Datum fehlt</span>
        </div>
    </div>
    <!--============================================-->
    <!-- END Old Vehicle Certificate Settings - Read Mode -->
    <!--============================================-->

    <div id="afzzert-logo-positioner" class="display-flex flex-center-content">
        <div
            id="afzzert-logo-container"
            class="display-flex"
            (click)="createAfzzertProcess()"
            [matTooltip]="getTooltipForExportIcon()"
            [class.certificate-imported]="isCertificateImported()"
            [class.disabled]="!areCredentialsComplete() || !isExportAllowed() || isReportLocked()">
            <img
                id="afzzert-logo"
                src="/assets/images/logos/afzzert.svg"
                alt="Afz-Zert. – Gesellschaft für Altfahrzeugzertifikate" />

            <div id="status-indicator-container" *ngIf="isProcessStarted() || isAfzzertApiPending" @fadeInAndOut>
                <ng-container *ngIf="!isAfzzertApiPending">
                    <!-- The certificate was not yet imported. Let the user click again to open Afz-Zert.'s website -->
                    <mat-icon
                        class="certificate-work-in-progress-indicator small-icon"
                        (click)="openAfzzertWebsite()"
                        *ngIf="isProcessStarted() && !isCertificateImported()"
                        matTooltip="Zertifikatserstellung gestartet. Bitte vervollständige die notwendigen Daten über die Website von Afz-Zert.{{
                            '\n\n'
                        }}Zum Öffnen klicken.">
                        edit
                    </mat-icon>

                    <!-- Certificate imported successfully. -->
                    <mat-icon
                        class="certificate-imported-indicator small-icon"
                        matTooltip="Zertifikat wurde importiert"
                        *ngIf="isCertificateImported()">
                        check
                    </mat-icon>
                </ng-container>

                <!-- Server request pending -->
                <img
                    class="pending-icon"
                    src="/assets/images/icons/loading-indicator.svg"
                    *ngIf="isAfzzertApiPending"
                    matTooltip="Warte auf Afz-Zert. Server..." />
            </div>
        </div>
    </div>
    <!--***********************************************
    ** Submenu
    ************************************************-->
    <mat-icon class="toolbar-icon card-submenu" [matMenuTriggerFor]="afzzertSubmenu" *ngIf="isProcessStarted()">
        more_vert
    </mat-icon>
    <mat-menu #afzzertSubmenu="matMenu">
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="isCertificateImported()"
            (click)="downloadCertificatePdf()"
            matTooltip="PDF-Datei des bereits importierten Zertifikats herrunterladen">
            <mat-icon>file_download</mat-icon>
            Zertifikats-PDF runterladen
        </a>

        <a
            mat-menu-item
            class="menu-item-with-icon"
            [class.disabled]="!areCredentialsComplete() || !isProcessStarted() || isReportLocked()"
            (click)="importCertificate()"
            matTooltip="Zertifikat importieren">
            <mat-icon>file_download</mat-icon>
            Zertifikat importieren
        </a>

        <a
            mat-menu-item
            class="menu-item-with-icon alert"
            [class.disabled]="!isProcessStarted() || isReportLocked()"
            (click)="deleteAfzzertProcess()"
            *ngIf="isProcessStarted()"
            matTooltip="Zertifikat löschen">
            <mat-icon>delete</mat-icon>
            Löschen
        </a>
    </mat-menu>
    <!--***********************************************
    ** END Submenu
    ************************************************-->

    <!--============================================-->
    <!-- Custom Fields -->
    <!--============================================-->
    <custom-field-display
        fieldLocation="damage-calculation_old-vehicle-certificates"
        [report]="report"
        (reportChange)="saveReport()"
        [disabled]="isReportLocked()"></custom-field-display>
    <!--============================================-->
    <!-- END Custom Fields -->
    <!--============================================-->
</ng-container>

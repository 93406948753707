import { generateId } from '../../lib/generate-id';
import { DocumentBuildingBlockCondition } from './document-building-block-condition';

export type DocumentBuildingBlockConditionGroupOperator = 'and' | 'or';

export class DocumentBuildingBlockConditionGroup {
    constructor(options?: Partial<DocumentBuildingBlockConditionGroup>) {
        Object.assign(this, options);
    }

    _id: string = generateId();

    // Conditions that are checked for this variant, operator is used to combine them.
    conditions: Array<DocumentBuildingBlockConditionGroup | DocumentBuildingBlockCondition> = [];
    conditionsOperator: DocumentBuildingBlockConditionGroupOperator = 'and';
}

<div id="sync-issue-list-panel-container" class="card">
    <!--============================================-->
    <!-- Header -->
    <!--============================================-->
    <header>
        <!--********** Icon & Headline **********-->
        <div id="header-icon-and-headlines-container">
            <!--********** Panel Icon **********-->
            <div id="panel-header-icon-container" class="emoji">🛠</div>

            <!--********** Headline **********-->
            <div id="headline-container">
                <h2 id="panel-headline">Sync-Probleme lösen</h2>
                <div id="panel-tagline">Manche Daten wurden vom Server abgelehnt.</div>
            </div>
        </div>

        <!--********** Close Icon **********-->
        <mat-icon class="dialog-close-icon" (click)="close()">close</mat-icon>

        <!--********** Number of Sync Problems **********-->
        <div id="number-of-sync-problems-container">
            <div id="number-of-sync-problems">
                {{ getNumberOfSyncIssues() }} {{ getNumberOfSyncIssues() === 1 ? 'Sync-Problem' : 'Sync-Probleme' }}
            </div>
        </div>
    </header>
    <!--============================================-->
    <!-- END Header -->
    <!--============================================-->

    <!--============================================-->
    <!-- Sync Issue List -->
    <!--============================================-->
    <section id="sync-issue-list">
        <!--********** Sync Issue Item **********-->
        <div
            class="sync-issue-item"
            *ngFor="let syncIssue of getSyncIssues(); trackBy: trackById"
            @slideOutHorizontally>
            <!--============================================-->
            <!-- Type & Title -->
            <!--============================================-->
            <!--********** Record Type Icon **********-->
            <div class="section-icon-container">
                <div class="record-type-icon-container" [ngSwitch]="syncIssue.databaseService.serviceName">
                    <mat-icon *ngSwitchCase="'carEquipment'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        directions_car
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'carEquipmentTemplate'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        directions_car
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'contactPerson'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        contacts
                    </mat-icon>
                    <mat-icon
                        *ngSwitchCase="'customAutocompleteEntry'"
                        [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        input
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'customFeeSet'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        table_view
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'customFieldConfig'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        input
                    </mat-icon>
                    <mat-icon
                        *ngSwitchCase="'documentBuildingBlock'"
                        [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        grid_view
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'documentOrderConfig'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        description
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'importHistory'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        history
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'invoiceTemplate'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        euro_symbol
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'invoice'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        euro_symbol
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'leaseReturnTemplate'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        task_alt
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'originalPhotoFile'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        image
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'renderedPhotoFile'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        image
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'reportProgressConfig'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        done_all
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'report'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        assignment
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'signablePdfTemplate'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        description
                    </mat-icon>
                    <mat-icon
                        *ngSwitchCase="'signablePdfTemplateConfig'"
                        [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        description
                    </mat-icon>
                    <mat-icon
                        *ngSwitchCase="'signablePdfTemplateImage'"
                        [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        description
                    </mat-icon>
                    <mat-icon
                        *ngSwitchCase="'signablePdfTemplateThumbnail'"
                        [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        description
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'team'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        groups
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'textTemplate'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        post_add
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'user'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        person
                    </mat-icon>
                    <mat-icon *ngSwitchCase="'watermarkImageFile'" [matTooltip]="getRecordTypeIconTooltip(syncIssue)">
                        image
                    </mat-icon>
                    <mat-icon *ngSwitchDefault [matTooltip]="syncIssue.databaseService.serviceName">
                        question_mark
                    </mat-icon>
                </div>
            </div>

            <!--********** Sync Error Title & Readable Record Name **********-->
            <div class="sync-issue-title-and-readable-record-name">
                <div class="readable-name label semibold">
                    {{ getRecordTypeGerman(syncIssue) }}
                    <span
                        matTooltip="Gutachten-Aktenzeichen"
                        *ngIf="syncIssue.databaseService.serviceName === 'report' && getReportToken(syncIssue)">
                        {{ getReportToken(syncIssue) }}
                    </span>

                    <span
                        matTooltip="Rechnungsnummer"
                        *ngIf="syncIssue.databaseService.serviceName === 'invoice' && getInvoiceNumber(syncIssue)">
                        {{ getInvoiceNumber(syncIssue) }}
                    </span>

                    <span
                        matTooltip="Benutzer"
                        *ngIf="syncIssue.databaseService.serviceName === 'user' && getUserName(syncIssue)">
                        {{ getUserName(syncIssue) }}
                    </span>
                </div>

                <div class="sync-issue-heading">{{ syncIssue.heading }}</div>
            </div>
            <!--============================================-->
            <!-- END Type & Title -->
            <!--============================================-->

            <!--============================================-->
            <!-- Reason -->
            <!--============================================-->
            <div class="section-icon-container">
                <mat-icon matTooltip="Ursache">new_releases</mat-icon>
            </div>
            <div class="label">{{ syncIssue.reason }}</div>
            <!--============================================-->
            <!-- END Reason -->
            <!--============================================-->

            <!--============================================-->
            <!-- Solution -->
            <!--============================================-->
            <div class="section-icon-container">
                <mat-icon class="solution-color" matTooltip="Lösungsvorschlag">lightbulb</mat-icon>
            </div>
            <div
                class="label solution-color"
                [innerHTML]="
                    syncIssue.solution ||
                    'Lade bitte die Seite neu. Wenn das nicht hilft, kontaktiere die <a href=\'/Hilfe\' target=\'_blank\'>Hotline</a>.'
                "></div>
            <!--============================================-->
            <!-- END Solution -->
            <!--============================================-->

            <!--============================================-->
            <!-- Technical Data -->
            <!--============================================-->
            <div class="section-icon-container">
                <mat-icon class="technical-data-color" matTooltip="Technische Daten">memory</mat-icon>
            </div>
            <div class="technical-data-color technical-data-text">
                <div
                    class="technical-error"
                    *ngIf="syncIssue.error"
                    (click)="logErrorToConsole(syncIssue.error)"
                    matTooltip="Den technischen Error in die Konsole schreiben.">
                    {{ syncIssue.error.code ? syncIssue.error.code : syncIssue.error.toString() }}
                </div>
                <div
                    *ngIf="
                        syncIssue.error?.code === 'SCHEMA_VALIDATION_FAILED' ||
                        syncIssue.error?.code === 'MONGOOSE_CAST_TO_EMBEDDED_FAILED'
                    "
                    matTooltip="{{ syncIssue.error.data.invalidPath || syncIssue.error.data.invalidField }}">
                    Path: {{ syncIssue.error.data.invalidPath || syncIssue.error.data.invalidField }}
                </div>
                <div matTooltip="Datensatz-ID">ID: {{ syncIssue.recordId }}</div>
            </div>
            <!--============================================-->
            <!-- END Technical Data -->
            <!--============================================-->

            <!--============================================-->
            <!-- Buttons -->
            <!--============================================-->
            <!--********** Placeholder in grid **********-->
            <div>&nbsp;</div>
            <!--********** Button & 3-Dot-Menu **********-->
            <div class="sync-issue-action-buttons">
                <button
                    class="sync-issue-primary-action-button glass"
                    (click)="resetAssociatedRecord(syncIssue)"
                    matTooltip="Ersetze den lokalen Datenstand durch den aktuellen Server-Stand.">
                    Lokale Änderungen verwerfen
                </button>
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="recordCanBeOpened(syncIssue)"
                    (click)="openRecord(syncIssue)"
                    matTooltip="Datensatz öffnen">
                    edit
                </mat-icon>
                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="syncIssueSubmenu">more_vert</mat-icon>
                <mat-menu #syncIssueSubmenu>
                    <a mat-menu-item class="menu-item-with-icon" (click)="triggerServiceSync(syncIssue)">
                        <mat-icon>sync</mat-icon>
                        Erneut synchronisieren
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="syncIssue.error?.code === 'SERVER_SHADOW_MISSING'"
                        (click)="postRecordAgain(syncIssue)"
                        matTooltip="Falls der Datensatz nicht auf dem Server gefunden werden kann, kannst du ihn noch einmal neu auf dem Server anlegen lassen.">
                        <mat-icon>cloud_upload</mat-icon>
                        Auf Server erstellen
                    </a>
                    <a mat-menu-item class="menu-item-with-icon" (click)="createFrontendLog(syncIssue)">
                        <mat-icon>bug_report</mat-icon>
                        An Hotline senden
                    </a>
                </mat-menu>
            </div>
            <!--============================================-->
            <!-- END Buttons -->
            <!--============================================-->

            <!--============================================-->
            <!-- Syncing Overlay -->
            <!--============================================-->
            <div class="syncing-overlay" *ngIf="isServiceSyncing(syncIssue.databaseService)" @fadeInAndOut>
                <img src="/assets/images/icons/loading-indicator.svg" alt="" class="pending-icon" />
                Synchronisiere...
            </div>
            <!--============================================-->
            <!-- END Syncing Overlay -->
            <!--============================================-->
        </div>
    </section>
    <!--============================================-->
    <!-- END Sync Issue List -->
    <!--============================================-->

    <!--============================================-->
    <!-- Log out Hint -->
    <!--============================================-->
    <section id="logout-hint">
        Wenn Probleme dauerhaft bestehen,
        <br />
        logge dich aus und wieder ein.
    </section>
    <!--============================================-->
    <!-- END Log out Hint -->
    <!--============================================-->

    <!--============================================-->
    <!-- Version Hint -->
    <!--============================================-->
    <div *ngIf="currentVersion" class="frontend-version-container full-width">
        <span [matTooltip]="'Version vom ' + (currentVersion?.createdAt | date: 'dd.MM.yyyy HH:mm')">
            Version&nbsp;{{ currentVersion?.commit }}
        </span>
    </div>
    <!--============================================-->
    <!-- END Version Hint -->
    <!--============================================-->
</div>

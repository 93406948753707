import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessRights } from '@autoixpert/models/user/user';
import { AccessRightGuard } from '../shared/services/access-right-guard.service';
import { CanDeactivateGuard } from '../shared/services/can-deactivate-guard.service';
import { GtueRedirectPageComponent } from './gtue-redirect-page/gtue-redirect-page.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { InvoiceEditorComponent } from './invoice-editor/invoice-editor.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoicePrintAndSendComponent } from './invoice-print-and-send/invoice-print-and-send.component';
import { InvoicesComponent } from './invoices.component';

const routes: Routes = [
    {
        path: 'Rechnungen',
        component: InvoicesComponent,
        children: [
            {
                path: '',
                component: InvoiceListComponent,
                pathMatch: 'full',
                data: {
                    requiredAccessRight: 'invoiceList',
                } as { requiredAccessRight: keyof AccessRights },
                canActivate: [AccessRightGuard],
            },
            {
                path: ':invoiceId',
                component: InvoiceDetailsComponent,
                canDeactivate: [CanDeactivateGuard],
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'Editor',
                    },
                    {
                        path: 'Editor',
                        component: InvoiceEditorComponent,
                    },
                    {
                        path: 'Druck-und-Versand',
                        component: InvoicePrintAndSendComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'gtue/auth/callback',
        component: GtueRedirectPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InvoicesRoutingModule {}

/**
 * How large can the dimensions of an image be if it has to fit inside a given rectangle?
 */
export function scaleImageToFit({
    imageWidth,
    imageHeight,
    containerWidth,
    containerHeight,
}: {
    imageWidth: number;
    imageHeight: number;
    containerWidth: number;
    containerHeight: number;
}): { containedHeight: number; containedWidth: number } {
    // Get limiting dimension by scaling the image metadata to the same height as the canvas.
    // Then compare if the image's width is larger than the canvas's.
    // If so, width is the limiting factor. Otherwise, it's height.
    const imageWidthScaledToCanvas = (containerHeight / imageHeight) * imageWidth;
    const limitingDimension: 'width' | 'height' = imageWidthScaledToCanvas >= containerWidth ? 'width' : 'height';

    // If limiting dimension is width, scale down the image targeting the width.
    if (limitingDimension === 'width') {
        return {
            containedWidth: containerWidth,
            containedHeight: (containerWidth / imageWidth) * imageHeight,
        };
    } else {
        return {
            containedWidth: (containerHeight / imageHeight) * imageWidth,
            containedHeight: containerHeight,
        };
    }
}

import { AudatexUser } from '../../models/user/third-party-accounts/audatex-user';

/**
 * Is a Qapter account a test account?
 * @param audatexUser
 */
export function isAudatexTestAccount(audatexUser: AudatexUser) {
    // Regular test accounts
    return /^Qapter-Demo\.\d{1,3}@audatex.de/.test(audatexUser?.username);
}

import { Directive, EventEmitter, Output } from '@angular/core';

/**
 * Apply to an element whose initialization you want to listen to.
 *
 * Use cases:
 * - Listen to initialization of an element within *ngFor
 * - Listen to initialization of an element with *ngIf
 */
@Directive({
    selector: '[init-notifier]',
})
export class InitNotifierDirective {
    @Output() initialized: EventEmitter<void> = new EventEmitter();

    ngOnInit() {
        this.initialized.emit();
    }
}

import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { Report } from '@autoixpert/models/reports/report';

export function isReportTabVisible({
    reportTabName,
    reportType,
}: {
    reportTabName: ReportTabName;
    reportType: Report['type'];
}): boolean {
    switch (reportTabName) {
        case 'accidentData':
        case 'carData':
        case 'photos':
        case 'fees':
        case 'printAndTransmission':
            return true;
        case 'carCondition':
            return reportType !== 'oldtimerValuationSmall' && reportType !== 'invoiceAudit';
        case 'carConditionOldtimer':
            return reportType === 'oldtimerValuationSmall';
        case 'damageCalculation':
            return reportType !== 'valuation' && reportType !== 'oldtimerValuationSmall';
        case 'valuation':
            return reportType === 'valuation';
        case 'valuationOldtimer':
            return reportType === 'oldtimerValuationSmall';
        case 'leaseReturnChecklist':
            return reportType === 'leaseReturn' || reportType === 'usedVehicleCheck';
        case 'invoiceAudit':
            return reportType === 'invoiceAudit';
    }
}

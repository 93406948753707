import { ClaimantSignature } from '@autoixpert/models/signable-documents/claimant-signature';
import { SignableDocument } from '@autoixpert/models/signable-documents/signable-document';
import {
    SignablePdfTemplateConfig,
    SignatureElement,
} from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { getAllSignatureElementsFromSignablePdfTemplateConfig } from './get-all-signature-elements-from-signable-pdf-template-config';

/**
 * SignableDocuments are created without signatures at first, so we must fill them
 * here as soon as we know which template will be used.
 *
 * This method adds one signature for each signature element within a PDF based document.
 */
export function addMissingClaimantSignaturesOnPdfTemplateDocument({
    signablePdfTemplateConfig,
    signableDocument,
}: {
    signablePdfTemplateConfig: SignablePdfTemplateConfig;
    signableDocument: SignableDocument;
}): void {
    // Extract all signature elements from all the possible pages within a PDF document.
    const signatureElements: SignatureElement[] =
        getAllSignatureElementsFromSignablePdfTemplateConfig(signablePdfTemplateConfig);

    // Clear all unnecessary signatures, e.g. the one from the document building block document.
    const existingSignatures: ClaimantSignature[] = [...signableDocument.signatures];
    signableDocument.signatures.length = 0;

    for (const signatureElement of signatureElements) {
        // If it exists already, keep it.
        const existingSignature = existingSignatures.find(
            (claimantSignature) => claimantSignature.signatureSlotId === signatureElement._id,
        );
        if (existingSignature) {
            signableDocument.signatures.push(existingSignature);
            continue;
        }

        // If it doesn't exist, create it.
        signableDocument.signatures.push(
            new ClaimantSignature({
                documentType: signableDocument.documentType,
                customDocumentOrderConfigId: signableDocument.customDocumentOrderConfigId,
                signatureSlotId: signatureElement._id,
            }),
        );
    }
}

import { SchwackeDowntimeCompensationGroupMapping } from './schwacke-downtime-compensation-group-mapping';

/**
 * This table is the most widely-used downtime compensation table as provided by DAT.
 *
 * The GPJ publishers table is a different one and is used by Audatex (separate file).
 */
export const downtimeCompensationGroupMappingsEurotaxCars: SchwackeDowntimeCompensationGroupMapping[] = [
    // Cars. Source: DAT & Allianz: https://www.allianzdirect.de/kfz-versicherung/nutzungsausfallentschaedigung-ratgeber/
    { groupName: 'A', compensationPerDay: 23 },
    { groupName: 'B', compensationPerDay: 29 },
    { groupName: 'C', compensationPerDay: 35 },
    { groupName: 'D', compensationPerDay: 38 },
    { groupName: 'E', compensationPerDay: 43 },
    { groupName: 'F', compensationPerDay: 50 },
    { groupName: 'G', compensationPerDay: 59 },
    { groupName: 'H', compensationPerDay: 65 },
    { groupName: 'J', compensationPerDay: 79 },
    { groupName: 'K', compensationPerDay: 119 },
    { groupName: 'L', compensationPerDay: 175 },
];

// Motorcycles
export const downtimeCompensationGroupMappingsEurotaxMotorcycles: SchwackeDowntimeCompensationGroupMapping[] = [
    {
        groupName: '1',
        customLabel: 'bis 50 ccm',
        compensationPerDay: 10,
    },
    {
        groupName: '2',
        customLabel: 'bis 80 ccm',
        compensationPerDay: 15,
    },
    {
        groupName: '3',
        customLabel: 'bis 7 kW',
        compensationPerDay: 20,
    },
    {
        groupName: '4',
        customLabel: 'bis 13 kW',
        compensationPerDay: 22,
    },
    {
        groupName: '5',
        customLabel: 'bis 20 kW',
        compensationPerDay: 25,
    },
    {
        groupName: '6',
        customLabel: 'bis 37 kW',
        compensationPerDay: 30,
    },
    {
        groupName: '7',
        customLabel: 'bis 57 kW',
        compensationPerDay: 45,
    },
    {
        groupName: '8',
        customLabel: 'bis 72 kW',
        compensationPerDay: 55,
    },
    {
        groupName: '9',
        customLabel: 'über 72 kW oder 1.200 ccm',
        compensationPerDay: 65,
    },
];

<div class="dialog-overlay" (click)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container" [ngClass]="{ 'scan-pending': scanPending }">
        <!--============================================-->
        <!-- Photo -->
        <!--============================================-->
        <div id="photo-container" [ngClass]="{ 'photo-recognized': recognitionResponse }">
            <!--============================================-->
            <!-- Photo Upload -->
            <!--============================================-->
            <div
                id="photo-upload-container"
                *ngIf="!photoId && !photoSource"
                (click)="fileUpload.click()"
                ng2FileDrop
                [ngClass]="{ 'file-over-body': fileIsOverBody, 'file-over-dropzone': fileIsOverDropZone }"
                (fileOver)="onFileOverDropZone($event)"
                (onFileDrop)="onFileDrop()"
                [uploader]="uploader">
                <mat-icon
                    class="dialog-close-icon"
                    (click)="closeDialog(); stopPropagation($event)"
                    matTooltip="Schließen">
                    close
                </mat-icon>
                <h2 id="photo-upload-heading">Fahrzeugschein auslesen</h2>
                <img id="photo-upload-icon" src="/assets/images/icons/car-registration.png" alt="" />

                <!--********** Upload Tips **********-->
                <div id="photo-upload-tips-container">
                    <div class="photo-upload-tip">
                        <mat-icon class="photo-upload-tip-icon medium-icon outlined">aspect_ratio</mat-icon>
                        <div class="photo-upload-tip-text">Gerade & flach fotografieren</div>
                    </div>
                    <div class="photo-upload-tip">
                        <mat-icon class="photo-upload-tip-icon medium-icon outlined">brightness_low</mat-icon>
                        <div class="photo-upload-tip-text">Gleichmäßige Belichtung</div>
                    </div>
                    <div class="photo-upload-tip">
                        <mat-icon class="photo-upload-tip-icon medium-icon outlined">all_out</mat-icon>
                        <div class="photo-upload-tip-text">Fahrzeugschein füllt Bild aus</div>
                    </div>
                </div>

                <!--********** Upload Button **********-->
                <button id="photo-upload-button">
                    <mat-icon class="button-icon">file_upload</mat-icon>
                    Foto hochladen
                </button>

                <div class="label">oder per Drag & Drop ablegen</div>

                <!--********** File Input (invisible) **********-->
                <input type="file" ng2FileSelect [uploader]="uploader" #fileUpload accept="image/jpeg" />
            </div>
            <!--============================================-->
            <!-- END Photo Upload -->
            <!--============================================-->

            <!--********** Photo Sizing Container **********-->
            <div id="photo-sizing-container" [style.width]="photoWidth + 'px'" [style.height]="photoHeight + 'px'">
                <img
                    id="photo"
                    *ngIf="photoSource"
                    [src]="photoSource"
                    alt=""
                    (load)="determineNaturalDimensions($event); resizePhoto()" />
                <div
                    class="data-location-marker"
                    *ngFor="let highlightArea of availableHighlightAreas"
                    [style.top]="'calc(' + highlightArea.topLeft.y + '% - 10px)'"
                    [style.left]="'calc(' + highlightArea.topLeft.x + '% - 20px)'"
                    [style.width]="'calc(' + highlightArea.width + '% + 40px)'"
                    [style.height]="'calc(' + highlightArea.height + '% + 20px)'"
                    [ngClass]="{
                        selected: selectedHighlightArea === highlightArea,
                        'connected-input-hovered': highlightAreaOfHoveredInput === highlightArea,
                        'extra-letter-spacing': needsExtraLetterSpacing(highlightArea),
                    }"
                    (click)="selectHighlightArea(highlightArea.name)"
                    @fadeOut>
                    <div class="data-location-marker-value-container">
                        <div class="data-location-marker-value" *ngIf="getCurrentValue(highlightArea.name)">
                            <!--********** Full Value **********-->
                            <ng-container *ngIf="!needsExtraLetterSpacing(highlightArea)">
                                {{ getCurrentValue(highlightArea.name) }}
                            </ng-container>

                            <!--********** Single Letters with Spacing **********-->
                            <ng-container *ngIf="needsExtraLetterSpacing(highlightArea)">
                                <div *ngFor="let letter of getArrayFrom(getCurrentValue(highlightArea.name))">
                                    {{ letter }}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- Upload Indicators -->
                <!--============================================-->
                <mat-icon
                    class="uploading-icon"
                    matTooltip="Bild lädt hoch..."
                    *ngIf="getUploadItem() && !getUploadItem().failed">
                    swap_horiz
                </mat-icon>
                <mat-icon
                    class="error-icon"
                    matTooltip="Upload nicht möglich. Bitte mit funktionierender Internetverbindung erneut versuchen."
                    *ngIf="getUploadItem()?.failed">
                    close
                </mat-icon>
                <div
                    class="upload-progress-bar"
                    *ngIf="getUploadItem() && getUploadItem().progressPercent <= 100 && !getUploadItem()?.failed"
                    [style.width]="getUploadItem()?.progressPercent + '%'"></div>
                <!--============================================-->
                <!-- END Upload Indicators -->
                <!--============================================-->
            </div>
            <!--********** Scan Line **********-->
            <div id="data-scan-line-wrapper" @fadeOut *ngIf="scanPending">
                <div id="data-scan-line"></div>
            </div>

            <div id="better-scan-result-tips-container">
                <!--********** Tips: How to get better scan results **********-->
                <div class="scan-result-tip-container" *ngIf="betterScanResultsTipVisible" @fadeOut>
                    <mat-icon class="scan-result-tip-icon medium-icon outlined">info</mat-icon>
                    {{ misrecognizedFieldNames.length }}
                    {{ misrecognizedFieldNames.length === 1 ? 'Feld' : 'Felder' }} zu prüfen:
                    {{ getMisrecognizedFieldNamesString() }}. &nbsp;
                    <mat-icon
                        class="scan-result-tip-close-icon toolbar-icon small-icon"
                        (click)="betterScanResultsTipVisible = false">
                        close
                    </mat-icon>
                </div>

                <!--********** Scan Warning **********-->
                <div class="scan-result-tip-container" *ngFor="let warning of scanWarnings" @fadeOut>
                    <mat-icon class="scan-result-tip-icon medium-icon warning">warning</mat-icon>
                    {{ warning }}
                    <mat-icon
                        class="scan-result-tip-close-icon toolbar-icon small-icon"
                        (click)="closeScanWarning(warning)">
                        close
                    </mat-icon>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Photo -->
        <!--============================================-->

        <!--============================================-->
        <!-- Inputs -->
        <!--============================================-->
        <div id="data-inputs-card" class="card card-with-padding" *ngIf="recognitionResponse" @slideInHorizontally>
            <h2 id="data-inputs-heading">Fahrzeugschein</h2>

            <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

            <section id="car-owner-section">
                <mat-form-field
                    class="mat-block"
                    (mouseenter)="showHighlightAreaOfHoveredInput('organization')"
                    (mouseleave)="hideHighlightAreaOfHoveredInput()"
                    (focus)="selectHighlightArea('organization')"
                    (blur)="selectHighlightArea(undefined)">
                    <input
                        matInput
                        placeholder="Firma"
                        [(ngModel)]="carOwner.organization"
                        [axAutofocus]="selectedHighlightArea?.name === 'organization'" />
                </mat-form-field>

                <div class="name-group">
                    <!--********** Salutation **********-->
                    <mat-form-field class="salutation">
                        <input
                            matInput
                            placeholder="Anrede"
                            [(ngModel)]="carOwner.salutation"
                            [matAutocomplete]="salutationOptions"
                            (focus)="filterSalutations(carOwner.salutation)"
                            (input)="filterSalutations(carOwner.salutation)" />
                    </mat-form-field>
                    <mat-autocomplete #salutationOptions="matAutocomplete">
                        <mat-option
                            *ngFor="let salutation of filteredSalutations"
                            [value]="salutation"
                            (mousedown)="$event.preventDefault()">
                            {{ salutation }}
                        </mat-option>
                    </mat-autocomplete>

                    <!--********** First Name **********-->
                    <mat-form-field
                        class="first-name"
                        (mouseenter)="showHighlightAreaOfHoveredInput('firstName')"
                        (mouseleave)="hideHighlightAreaOfHoveredInput()">
                        <input
                            matInput
                            placeholder="Vorname"
                            [(ngModel)]="carOwner.firstName"
                            (focus)="selectHighlightArea('firstName')"
                            (blur)="selectHighlightArea(undefined)"
                            [axAutofocus]="selectedHighlightArea?.name === 'firstName'" />
                    </mat-form-field>

                    <!--********** Last Name **********-->
                    <mat-form-field
                        class="last-name"
                        (mouseenter)="showHighlightAreaOfHoveredInput('lastName')"
                        (mouseleave)="hideHighlightAreaOfHoveredInput()">
                        <input
                            matInput
                            placeholder="Nachname"
                            [(ngModel)]="carOwner.lastName"
                            (focus)="selectHighlightArea('lastName')"
                            (blur)="selectHighlightArea(undefined)"
                            [axAutofocus]="selectedHighlightArea?.name === 'lastName'" />
                    </mat-form-field>
                </div>

                <mat-form-field
                    class="mat-block"
                    (mouseenter)="showHighlightAreaOfHoveredInput('street')"
                    (mouseleave)="hideHighlightAreaOfHoveredInput()">
                    <input
                        matInput
                        placeholder="Straße & Hausnr. oder Postfach"
                        [(ngModel)]="carOwner.streetAndHouseNumberOrLockbox"
                        (focus)="selectHighlightArea('street')"
                        (blur)="selectHighlightArea(undefined)"
                        [matAutocomplete]="streetAutocomplete.autocomplete"
                        [axAutofocus]="selectedHighlightArea?.name === 'street'" />
                </mat-form-field>
                <address-autocompletion
                    #streetAutocomplete
                    [externalAddressAutocompletionEnabled]="true"
                    (addressSelected)="insertAddressAutocompletion($event)"
                    [value]="carOwner.streetAndHouseNumberOrLockbox"
                    [addressContext]="carOwner"></address-autocompletion>

                <div class="place-group">
                    <mat-form-field
                        class="zipCode"
                        (mouseenter)="showHighlightAreaOfHoveredInput('zip')"
                        (mouseleave)="hideHighlightAreaOfHoveredInput()">
                        <input
                            matInput
                            placeholder="PLZ"
                            name="zipCode"
                            [(ngModel)]="carOwner.zip"
                            [street]="carOwner.streetAndHouseNumberOrLockbox"
                            [city]="carOwner.city"
                            zip-city-input
                            (cityChange)="insertCityIntoModel($event)"
                            (focus)="selectHighlightArea('zip')"
                            (blur)="selectHighlightArea(undefined)"
                            [axAutofocus]="selectedHighlightArea?.name === 'zip'" />
                    </mat-form-field>
                    <mat-form-field
                        class="place"
                        (mouseenter)="showHighlightAreaOfHoveredInput('city')"
                        (mouseleave)="hideHighlightAreaOfHoveredInput()">
                        <input
                            matInput
                            placeholder="Ort"
                            name="place"
                            [(ngModel)]="carOwner.city"
                            (focus)="selectHighlightArea('city')"
                            (blur)="selectHighlightArea(undefined)"
                            [axAutofocus]="selectedHighlightArea?.name === 'city'" />
                    </mat-form-field>
                </div>

                <!--============================================-->
                <!-- Contact data matching -->
                <!--============================================-->
                <div *ngIf="!phoneAlreadySet || !emailAlreadySet">
                    <!-- If the user already selected phone+email in the report, we don't suggest anything -->

                    <!-- Phone + Email input fields shown when the user selected a partial match or we have an exact match -->
                    <div *ngIf="selectedMatchingContactPerson?.phone || selectedMatchingContactPerson?.email">
                        <div
                            augmentedDataIndicator
                            indicatorTooltip="Daten wurden automatisch aus deinen Kontakten eingefügt. Hast du Felder schon im Gutachten ausgefüllt, bleiben deine Eingaben erhalten. Die Felder werden dann hier nicht angezeigt."
                            indicatorOffsetBottom="1.25em"
                            indicatorOffsetTop="-1.25em">
                            <div
                                class="phone-numbers-container"
                                [ngClass]="{
                                    'second-phone-shown':
                                        secondPhoneNumberShown || selectedMatchingContactPerson.phone2,
                                }"
                                *ngIf="suggestedMatchingContactPerson.phone && !phoneAlreadySet">
                                <!-- show the phone input field if the user has not already entered one in the report
                                     and the matched contact person has one set. We use the suggestedMatchingContactPerson here because
                                     otherwise the input field would disappear when the user deletes the current input -->
                                <mat-form-field class="phone mat-block">
                                    <input
                                        matInput
                                        type="tel"
                                        placeholder="Telefon"
                                        [(ngModel)]="selectedMatchingContactPerson.phone"
                                        autocomplete="do-not-fill-with-native-autocomplete-random-string" />
                                    <mat-icon
                                        matSuffix
                                        class="input-toolbar-icon phone-number-suffix"
                                        matTooltip="Telefonnummer hinzufügen"
                                        (click)="this.secondPhoneNumberShown = true">
                                        add
                                    </mat-icon>
                                </mat-form-field>
                                <mat-form-field
                                    class="phone2 mat-block"
                                    *ngIf="secondPhoneNumberShown || selectedMatchingContactPerson.phone2">
                                    <input
                                        matInput
                                        type="tel"
                                        placeholder="Telefon 2"
                                        [(ngModel)]="selectedMatchingContactPerson.phone2"
                                        autocomplete="do-not-fill-with-native-autocomplete-random-string" />
                                    <mat-icon
                                        matSuffix
                                        class="input-toolbar-icon phone-number-suffix"
                                        matTooltip="Zweite Telefonnummer entfernen"
                                        (click)="
                                            this.secondPhoneNumberShown = false;
                                            this.selectedMatchingContactPerson.phone2 = null
                                        ">
                                        close
                                    </mat-icon>
                                </mat-form-field>
                            </div>

                            <mat-form-field
                                class="mat-block"
                                *ngIf="suggestedMatchingContactPerson.email && !emailAlreadySet">
                                <!-- show the email input field if the user has not already entered one in the report
                                     and the matched contact person has one set. We use the suggestedMatchingContactPerson here because
                                     otherwise the input field would disappear when the user deletes the current input -->
                                <input
                                    matInput
                                    placeholder="E-Mail"
                                    emailAutocomplete
                                    [(ngModel)]="selectedMatchingContactPerson.email"
                                    autocomplete="do-not-fill-with-native-autocomplete-random-string" />
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Info note shown when we only have a partial match -->
                    <div
                        class="info-note"
                        *ngIf="
                            suggestedMatchingContactPerson &&
                            !selectedMatchingContactPerson &&
                            !hideContactPersonInfoNote
                        "
                        @slideInAndOutVertically>
                        <!-- Show the info note as long as the user has not dismissed it or confirmed to use the data -->
                        <mat-icon>import_contacts</mat-icon>
                        <div class="info-note-contact-data">
                            <mat-icon
                                class="info-note-close-icon toolbar-icon"
                                matTooltip="Meldung ausblenden"
                                (click)="hideContactPersonInfoNote = true">
                                close
                            </mat-icon>

                            <h3>Ähnlichen Kontakt gefunden</h3>
                            Person in Kontakten mit gleichem Namen aber abweichender Adresse gefunden.
                            <br />
                            <br />
                            <span>
                                {{
                                    getContactPersonFullNameWithOrganization(
                                        {
                                            firstName: suggestedMatchingContactPerson.firstName,
                                            lastName: suggestedMatchingContactPerson.lastName,
                                            organization: suggestedMatchingContactPerson.organization,
                                        },
                                        ', '
                                    )
                                }},
                                <span
                                    class="partial-match"
                                    [matTooltip]="
                                        'Straße oder Hausnummer weicht ab. Adresse aus Fahrzeugschein: ' +
                                        carOwner.streetAndHouseNumberOrLockbox
                                    ">
                                    {{ suggestedMatchingContactPerson.streetAndHouseNumberOrLockbox }}
                                </span>
                                <br />
                                <br />

                                <div
                                    class="phone-numbers-container"
                                    [ngClass]="{
                                        'second-phone-shown':
                                            secondPhoneNumberShown || suggestedMatchingContactPerson.phone2,
                                    }"
                                    *ngIf="suggestedMatchingContactPerson.phone && !phoneAlreadySet">
                                    <mat-form-field class="phone mat-block">
                                        <input
                                            matInput
                                            placeholder="Telefon"
                                            disabled="true"
                                            [(ngModel)]="suggestedMatchingContactPerson.phone" />
                                    </mat-form-field>
                                    <mat-form-field
                                        class="phone2 mat-block"
                                        *ngIf="secondPhoneNumberShown || suggestedMatchingContactPerson.phone2">
                                        <input
                                            matInput
                                            type="tel"
                                            placeholder="Telefon 2"
                                            disabled="true"
                                            [(ngModel)]="suggestedMatchingContactPerson.phone2" />
                                    </mat-form-field>
                                </div>

                                <mat-form-field
                                    class="mat-block"
                                    *ngIf="suggestedMatchingContactPerson.email && !emailAlreadySet">
                                    <input
                                        matInput
                                        placeholder="E-Mail"
                                        disabled="true"
                                        [(ngModel)]="suggestedMatchingContactPerson.email" />
                                </mat-form-field>
                            </span>

                            <div class="info-note-buttons-row">
                                <div class="info-note-button-primary" (click)="selectMatchingContactPerson()">
                                    Übernehmen
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Contact data matching -->
                <!--============================================-->

                <!--********** Is Claimant **********-->
                <div class="checkbox-group parent-of-an-only-child">
                    <mat-checkbox
                        [(ngModel)]="ownerIsClaimant"
                        (ngModelChange)="checkIfContactDataIsAlreadySet()"
                        matTooltip="Bestimmt den Beteiligten, für den die Daten übernommen werden">
                        Halter ist Anspruchsteller
                    </mat-checkbox>
                </div>
            </section>

            <!--============================================-->
            <!-- Car -->
            <!--============================================-->
            <section id="car-section">
                <div id="license-plate-container">
                    <license-plate
                        [(licensePlate)]="car.licensePlate"
                        [displayLarge]="true"
                        (mouseenter)="showHighlightAreaOfHoveredInput('licensePlate')"
                        (mouseleave)="hideHighlightAreaOfHoveredInput()"
                        (focus)="selectHighlightArea('licensePlate')"
                        (blur)="selectHighlightArea(undefined)"
                        [showTypeSwitcher]="false"
                        [autofocus]="selectedHighlightArea?.name === 'licensePlate'"></license-plate>
                </div>

                <!--********** VIN & Model Code **********-->
                <vin-input
                    [(car)]="car"
                    [report]="report"
                    [reportId]="report._id"
                    [reportType]="report.type"
                    [vinQueryAllowed]="false"
                    (mouseenter)="showHighlightAreaOfHoveredInput('vin')"
                    (mouseleave)="hideHighlightAreaOfHoveredInput()"
                    (focus)="selectHighlightArea('vin')"
                    (blur)="selectHighlightArea(undefined)"
                    [autofocus]="selectedHighlightArea?.name === 'vin'"
                    (previousReportSelected)="insertCarFromPreviousReport($event)"></vin-input>

                <!--********** Next General Inspection **********-->
                <general-inspection-input
                    [(nextGeneralInspection)]="car.nextGeneralInspection"
                    (mouseenter)="showHighlightAreaOfHoveredInput('nextGeneralInspection')"
                    (mouseleave)="hideHighlightAreaOfHoveredInput()"
                    (focus)="selectHighlightArea('nextGeneralInspection')"
                    (blur)="selectHighlightArea(undefined)"
                    [autofocus]="selectedHighlightArea?.name === 'nextGeneralInspection'"
                    [warning]="
                        car.nextGeneralInspection ? '' : outdatedGeneralInspectionWarning
                    "></general-inspection-input>

                <!--********** Registration Dates **********-->
                <div class="double-input-container">
                    <date-input
                        [(date)]="car.firstRegistration"
                        (mouseenter)="showHighlightAreaOfHoveredInput('firstRegistration')"
                        (mouseleave)="hideHighlightAreaOfHoveredInput()"
                        (focus)="selectHighlightArea('firstRegistration')"
                        (blur)="selectHighlightArea(undefined)"
                        [autofocus]="selectedHighlightArea?.name === 'firstRegistration'"
                        [warning]="getFirstRegistrationWarning()"
                        placeholder="Erste Zulassung"
                        matTooltip="Im Fahrzeugschein: Feld 'B'"></date-input>
                    <date-input
                        [(date)]="car.latestRegistration"
                        (mouseenter)="showHighlightAreaOfHoveredInput('latestRegistration')"
                        (mouseleave)="hideHighlightAreaOfHoveredInput()"
                        (focus)="selectHighlightArea('latestRegistration')"
                        (blur)="selectHighlightArea(undefined)"
                        [autofocus]="selectedHighlightArea?.name === 'latestRegistration'"
                        [warning]="getLatestRegistrationWarning()"
                        placeholder="Letzte Zulassung"
                        matTooltip="Im Fahrzeugschein: Feld 'I'"></date-input>
                </div>

                <!--                &lt;!&ndash;********** Tire Dimensions **********&ndash;&gt;-->
                <!--                <div class="double-input-container">-->
                <!--                    <mat-form-field class="mat-block" (mouseenter)="highlightArea('tireDimensionFirstAxis')" (focus)="highlightArea('tireDimensionFirstAxis')" (blur)="highlightArea(undefined)" (mouseleave)="highlightArea(undefined)" >-->
                <!--                        <input matInput placeholder="Reifendimension 1. Achse" [(ngModel)]="tireDimensionFirstAxis">-->
                <!--                    </mat-form-field>-->
                <!--                    <mat-form-field class="mat-block" (mouseenter)="highlightArea('tireDimensionSecondAxis')" (focus)="highlightArea('tireDimensionSecondAxis')" (blur)="highlightArea(undefined)" (mouseleave)="highlightArea(undefined)" >-->
                <!--                        <input matInput placeholder="Reifendimension 2. Achse" [(ngModel)]="tireDimensionSecondAxis">-->
                <!--                    </mat-form-field>-->
                <!--                </div>-->
            </section>
            <!--============================================-->
            <!-- END Car -->
            <!--============================================-->

            <section class="dialog-buttons-container">
                <button (click)="mergeValuesIntoReport(); closeDialog()">Übernehmen</button>
                <button class="flat" (click)="closeDialog()">Abbrechen</button>
            </section>
        </div>
        <!--============================================-->
        <!-- END Inputs -->
        <!--============================================-->
    </div>
</div>

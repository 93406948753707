import { AxError } from '../models/errors/ax-error';

export class CarRegistrationScannerResponse {
    carOwner: CarRegistrationScannerCarOwner = new CarRegistrationScannerCarOwner();
    car: CarRegistrationScannerCar = new CarRegistrationScannerCar();
    warnings: AxError[] = [];
}

export class CarRegistrationScannerCarOwner {
    organization: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    lastName: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    firstName: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    streetAndHouseNumber: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    zip: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    city: CarRegistrationScannerValue = new CarRegistrationScannerValue();
}

export class CarRegistrationScannerCar {
    licensePlate: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    vin: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    firstRegistration: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    latestRegistration: CarRegistrationScannerValue = new CarRegistrationScannerValue();
    nextGeneralInspection: CarRegistrationScannerValue = new CarRegistrationScannerValue();
}

export class CarRegistrationScannerValue {
    value: string = '';
    boundingBox: {
        topLeft: Point;
        topRight: Point;
        bottomRight: Point;
        bottomLeft: Point;
    } = {
        topLeft: new Point(),
        topRight: new Point(),
        bottomRight: new Point(),
        bottomLeft: new Point(),
    };
}

export class Point {
    x: number;
    y: number;
}

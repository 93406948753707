import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { generateId } from '@autoixpert/lib/generate-id';
import { translateDatabaseServiceName } from '@autoixpert/lib/server-sync/translate-database-service-name';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { AppVersionUpdateService } from 'src/app/shared/services/app-version-update.service';
import { AppVersionService } from 'src/app/shared/services/app-version.service';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../shared/animations/fade-in-and-slide.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { slideOutDialogVertical } from '../../shared/animations/slide-out-dialog-vertical.animation';
import { slideOutHorizontally } from '../../shared/animations/slide-out-horizontally.animation';
import { OfflineSyncBlobServiceBase } from '../../shared/libraries/database/offline-sync-blob-service.base';
import { OfflineSyncServiceBase } from '../../shared/libraries/database/offline-sync-service.base';
import { trackById } from '../../shared/libraries/track-by-id';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { FrontendLogService } from '../../shared/services/frontend-log.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ReportService } from '../../shared/services/report.service';
import {
    SyncIssueNotification,
    SyncIssueNotificationService,
} from '../../shared/services/sync-issue-notification.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'sync-issue-list-panel',
    templateUrl: 'sync-issue-list-panel.component.html',
    styleUrls: ['sync-issue-list-panel.component.scss'],
    animations: [
        slideOutDialogVertical(),
        slideInAndOutVertically(),
        slideOutHorizontally(400),
        fadeInAndSlideAnimation(),
        fadeInAndOutAnimation(),
        trigger('fadeInAndOutAfterAnother', [
            state(
                'void',
                style({
                    opacity: 0,
                }),
            ),
            transition(':enter, :leave', [animate('0.5s ease-in-out')]),
        ]),
    ],
})
export class SyncIssueListPanelComponent {
    constructor(
        private overlayRef: OverlayRef,
        private syncIssueNotificationService: SyncIssueNotificationService,
        private reportService: ReportService,
        private invoiceService: InvoiceService,
        private toastService: ToastService,
        private router: Router,
        private userService: UserService,
        private appVersionService: AppVersionService,
        private frontendLogService: FrontendLogService,
        private apiErrorService: ApiErrorService,
    ) {}

    public syncingServices: (OfflineSyncServiceBase<any> | OfflineSyncBlobServiceBase)[] = [];

    private reports: Map<Report['_id'], Report> = new Map();
    private invoices: Map<Invoice['_id'], Invoice> = new Map();

    //*****************************************************************************
    //  Number of Sync Issues
    //****************************************************************************/
    public getNumberOfSyncIssues(): number {
        return this.syncIssueNotificationService.find().length;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Number of Sync Issues
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sync Issue List
    //****************************************************************************/
    public getSyncIssues(): SyncIssueNotification[] {
        return this.syncIssueNotificationService.find();
    }

    public getRecordTypeGerman(syncIssue: SyncIssueNotification): string {
        return translateDatabaseServiceName(syncIssue.databaseService.serviceName).singular;
    }

    /**
     * Display the German name of a database service as tooltip, e.g. "Gutachten" or "Dokumentvorlagen".
     * @param syncIssue
     */
    public getRecordTypeIconTooltip(syncIssue: SyncIssueNotification): string {
        return translateDatabaseServiceName(syncIssue.databaseService.serviceName).singular;
    }

    public async resetAssociatedRecord(syncIssue: SyncIssueNotification) {
        await syncIssue.databaseService.reset(syncIssue.recordId);
        await this.syncIssueNotificationService.resolve(syncIssue);
        this.toastService.success('Lokale Änderungen entfernt');

        this.closeDialogIfNoIssuesPresent();
    }

    /**
     * If all issues have been resolved, close the dialog.
     */
    public closeDialogIfNoIssuesPresent() {
        if (this.getNumberOfSyncIssues() === 0) {
            this.close();
        }
    }

    /**
     * Trigger syncing all records from a service.
     * @param syncIssue
     */
    public async triggerServiceSync(syncIssue: SyncIssueNotification) {
        const databaseService = syncIssue.databaseService;

        // There can only be one sync per database service at a time.
        if (this.syncingServices.includes(syncIssue.databaseService)) return;

        this.syncingServices.push(databaseService);
        try {
            await databaseService.pushToServer();
        } catch (error) {
            this.toastService.error(
                'Fehler beim Synchronisieren',
                'Bitte versuche, deine lokalen Änderungen zurückzusetzen oder kontaktiere die Hotline.',
            );
            console.error(`Push to server failed for database service ${databaseService.serviceName}.`);
        }
        removeFromArray(databaseService, this.syncingServices);
    }

    public isServiceSyncing(databaseService: OfflineSyncServiceBase<any> | OfflineSyncBlobServiceBase): boolean {
        return this.syncingServices.includes(databaseService);
    }

    /**
     * We could observe, that sometimes around autoiXpert client updates, records are locally marked as created and ready to be patched yet haven't arrived on the server.
     * When patching them, it fails due to the SERVER_SHADOW_MISSING.
     *
     * This method allows the user to mark the record as new so that the service continues by posting it to the server.
     */
    public async postRecordAgain(syncIssue: SyncIssueNotification) {
        await syncIssue.databaseService.localDb.setCreationChangeRecord(syncIssue.recordId);
        await this.triggerServiceSync(syncIssue);
    }

    protected async createFrontendLog(syncIssue: SyncIssueNotification) {
        try {
            await this.frontendLogService.create({
                service: syncIssue.databaseService.serviceName,
                resourceId: syncIssue.recordId,
                payload: {
                    ...syncIssue,
                    databaseService: syncIssue.databaseService.serviceName,
                },
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Frontend-Log nicht angelegt',
                    body: 'Das Frontend-Log der Sync-Issue konnte nicht auf den Server geschrieben werden.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sync Issue List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Expand Additional Info
    //****************************************************************************/
    public logErrorToConsole(error: any): void {
        // Wrap the error in an object to ensure that AxError.data properties are printed to the console. The console's native behavior is to only print the error name and message.
        console.log({ error });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Expand Additional Info
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Record Identifiers
    //****************************************************************************/
    /**
     * The user will identify their report by means of its report token.
     * @param syncIssue
     */
    public getReportToken(syncIssue: SyncIssueNotification): Report['token'] {
        if (!this.reports.has(syncIssue.recordId)) {
            this.fetchReport(syncIssue);
            return;
        }

        return this.reports.get(syncIssue.recordId)?.token;
    }

    /**
     * Fetch the report and set it on the Map.
     * @param syncIssue
     * @private
     */
    private async fetchReport(syncIssue: SyncIssueNotification) {
        // Set the key right away so that this method won't be called multiple times, causing a screen freeze because an infinite number of promises are generated.
        this.reports.set(syncIssue.recordId, undefined);
        const report: Report = await this.reportService.get(syncIssue.recordId);
        this.reports.set(report._id, report);
    }

    /**
     * The user will identify their invoice by means of its number.
     * @param syncIssue
     */
    public getInvoiceNumber(syncIssue: SyncIssueNotification): Invoice['number'] {
        if (!this.invoices.has(syncIssue.recordId)) {
            this.fetchInvoice(syncIssue);
            return;
        }

        return this.invoices.get(syncIssue.recordId)?.number;
    }

    /**
     * Fetch the invoice and set it on the Map.
     * @param syncIssue
     * @private
     */
    private async fetchInvoice(syncIssue: SyncIssueNotification) {
        // Set the key right away so that this method won't be called multiple times, causing a screen freeze because an infinite number of promises are generated.
        this.invoices.set(syncIssue.recordId, undefined);
        const invoice: Invoice = await this.invoiceService.get(syncIssue.recordId);
        this.invoices.set(invoice._id, invoice);
    }

    protected getUserName(syncIssue: SyncIssueNotification): string {
        return this.userService.getTeamMembersName(syncIssue.recordId);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Record Identifiers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Open Record
    //****************************************************************************/
    public recordCanBeOpened(syncIssue: SyncIssueNotification) {
        switch (syncIssue.databaseService.serviceName) {
            case 'report':
            case 'invoice':
                return true;

            /**
             * We have not yet implemented a way to open these records.
             */
            case 'carEquipment':
            case 'carEquipmentTemplate':
            case 'contactPerson':
            case 'customAutocompleteEntry':
            case 'customFeeSet':
            case 'fieldGroupConfig':
            case 'documentBuildingBlock':
            case 'documentOrderConfig':
            case 'importHistory':
            case 'invoiceTemplate':
            case 'leaseReturnTemplate':
            case 'originalPhotoFile':
            case 'renderedPhotoFile':
            case 'reportProgressConfig':
            case 'team':
            case 'textTemplate':
            case 'user':
            case 'watermarkImageFile':
                return false;
        }
    }

    public openRecord(syncIssue: SyncIssueNotification) {
        switch (syncIssue.databaseService.serviceName) {
            case 'invoice':
                this.router.navigate(['Rechnungen', syncIssue.recordId]);
                break;
            case 'report':
                this.router.navigate(['Gutachten', syncIssue.recordId]);
                break;

            case 'carEquipment':
            case 'carEquipmentTemplate':
            case 'contactPerson':
            case 'customAutocompleteEntry':
            case 'customFeeSet':
            case 'fieldGroupConfig':
            case 'documentBuildingBlock':
            case 'documentOrderConfig':
            case 'importHistory':
            case 'invoiceTemplate':
            case 'leaseReturnTemplate':
            case 'originalPhotoFile':
            case 'renderedPhotoFile':
            case 'reportProgressConfig':
            case 'team':
            case 'textTemplate':
            case 'user':
            case 'watermarkImageFile':
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Open Record
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Frontend Version
    //****************************************************************************/
    get currentVersion() {
        return this.appVersionService.currentVersion?.appData;
    }

    get latestVersion() {
        return this.appVersionService.latestVersion?.appData;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Frontend Version
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public trackById = trackById;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public close(): void {
        this.overlayRef.detach();
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls
    /////////////////////////////////////////////////////////////////////////////*/
}

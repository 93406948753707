import { getMapFromRecords } from '@autoixpert/lib/server-sync/get-map-from-records';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { DocumentOrderItem } from '../../models/documents/document-order-item';

/**
 * Copies the order of the template to the source, effectively making the two orders equal.
 */
export function adaptDocumentOrder({
    templateOrder,
    orderToBeSorted,
    documents,
}: {
    templateOrder: DocumentOrder;
    orderToBeSorted: DocumentOrder;
    documents: DocumentMetadata[];
}): void {
    if (templateOrder === orderToBeSorted) {
        return;
    }

    const documentMap: Map<DocumentMetadata['_id'], DocumentMetadata> = getMapFromRecords(documents);

    orderToBeSorted.items.sort((itemToBeSortedA, itemToBeSortedB) => {
        /**
         * If the item that's supposed to be sorted is a report letter, its document ID will deviate in the template, so compare the
         * document type.
         * For other documents, the document IDs will be identical across document orders. Use those IDs for comparison. Comparing
         * by ID instead of type is a better fit if there are multiple instances of the same document type within one document order.
         */
        let templateItemA: DocumentOrderItem = templateOrder.items.find(
            (templateDocumentOrderItem) => templateDocumentOrderItem._id === itemToBeSortedA._id,
        );
        if (!templateItemA) {
            const documentToBeSortedA = documentMap.get(itemToBeSortedA._id);
            templateItemA = templateOrder.items.find((templateDocumentOrderItem) => {
                const templateDocumentToBeSorted: DocumentMetadata = documentMap.get(templateDocumentOrderItem._id);
                return templateDocumentToBeSorted.type === documentToBeSortedA.type;
            });
        }
        /**
         * If the item that's supposed to be sorted is a report letter, its document ID will deviate in the template, so compare the
         * document type.
         * For other documents, the document IDs will be identical across document orders. Use those IDs for comparison. Comparing
         * by ID instead of type is a better fit if there are multiple instances of the same document type within one document order.
         */
        let templateItemB: DocumentOrderItem = templateOrder.items.find(
            (templateDocumentOrderItem) => templateDocumentOrderItem._id === itemToBeSortedB._id,
        );
        if (!templateItemB) {
            const documentToBeSortedB = documentMap.get(itemToBeSortedB._id);
            templateItemB = templateOrder.items.find((templateDocumentOrderItem) => {
                const templateDocumentToBeSorted: DocumentMetadata = documentMap.get(templateDocumentOrderItem._id);
                return templateDocumentToBeSorted.type === documentToBeSortedB.type;
            });
        }

        return templateOrder.items.indexOf(templateItemA) - templateOrder.items.indexOf(templateItemB);
    });
}

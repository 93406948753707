<!--********** Net & Gross **********-->
<div class="net-and-gross-inputs-container">
    <!--********** Net Amount **********-->
    <currency-input
        *ngIf="taxationType !== 'neutral'"
        [lightMode]="lightMode"
        placeholder="{{ label }} (netto)"
        [(value)]="netValue"
        (valueChange)="handleChangeOnNetValue()"></currency-input>
    <div
        *ngIf="taxationType !== 'neutral'"
        class="net-and-gross-transformation-indicator-container cursor-pointer"
        (click)="netAndGrossLinked = !netAndGrossLinked"
        [ngClass]="{
            active: netAndGrossLinked,
            'amount-conversion-from-net-to-gross-in-progress': amountConversionDirection === 'netToGross',
            'amount-conversion-from-gross-to-net-in-progress': amountConversionDirection === 'grossToNet',
        }"
        [matTooltip]="
            netAndGrossLinked
                ? 'Brutto- & Netto-Werte werden automatisch ineinander umgerechnet. Klicke, um die Werte getrennt eingeben zu können.'
                : 'Brutto- & Netto-Werte werden nicht umgerechnet. Klicke, um die Werte automatisch umzurechnen.'
        ">
        <mat-icon class="net-and-gross-transformation-indicator">repeat</mat-icon>
    </div>
    <!--********** Gross Amount **********-->
    <currency-input
        [lightMode]="lightMode"
        [ngClass]="{ 'net-and-gross-input': taxationType === 'neutral' }"
        [placeholder]="label + (taxationType === 'neutral' ? ' (brutto = netto)' : ' (brutto)')"
        [(value)]="grossValue"
        (valueChange)="handleChangeOnGrossValue()"></currency-input>
</div>

import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { Car, CarShape } from '@autoixpert/models/reports/car-identification/car';
import { Tire } from '@autoixpert/models/reports/car-identification/tire';
import {
    PaintThicknessMeasurement,
    PaintThicknessPosition,
} from '@autoixpert/models/reports/paint-thickness-measurement';
import { PaintThicknessScale, defaultPaintThicknessScale } from '@autoixpert/models/reports/paint-thickness-scale';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../../../../shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../../../../shared/animations/run-child-animations.animation';
import { getCarContourImageName } from '../../../../../shared/libraries/car/get-car-contour-image-name';
import { getCarTireImageName } from '../../../../../shared/libraries/car/get-car-tire-image-name';
import { isAxleConfigurationRequired } from '../../../../../shared/libraries/car/is-axle-configuration-required';
import { hasAccessRight } from '../../../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../../../shared/services/api-error.service';
import { LoggedInUserService } from '../../../../../shared/services/logged-in-user.service';
import { ReportDetailsService } from '../../../../../shared/services/report-details.service';
import { TeamService } from '../../../../../shared/services/team.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { UserService } from '../../../../../shared/services/user.service';
import { getPaintThicknessPositionsForCarShape } from '../get-paint-thickness-positions-for-car-shape';
import {
    PaintThicknessInputComponent,
    PaintThicknessMeasurementTypeSelection,
} from '../paint-thickness-input/paint-thickness-input.component';

@Component({
    selector: 'paint-thickness',
    templateUrl: './paint-thickness.component.html',
    styleUrls: ['./paint-thickness.component.scss'],
    animations: [fadeInAndOutAnimation(), runChildAnimations()],
})
export class PaintThicknessComponent implements OnInit {
    @Input() report: Report;
    @Input() tires: Tire[] = [];
    @Input() paintThicknessCommentShown;

    protected team: Team;
    protected user: User;

    protected paintThicknessPositions: PaintThicknessMeasurement['position'][] = [];
    protected paintThicknessEditScalesDialogShown = false;
    protected selectedPaintThicknessScale: PaintThicknessScale;
    protected customPaintThicknessPositionsUpdatedIconShown = false;
    protected showPaintThicknessCommentTextTemplates: boolean = false;

    @ViewChildren(PaintThicknessInputComponent)
    paintThicknessInputs: QueryList<PaintThicknessInputComponent>;
    @ViewChildren('customPositionInput')
    customPositionInputs: QueryList<ElementRef>;

    constructor(
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        protected userService: UserService,
        private reportDetailsService: ReportDetailsService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        this.initializePaintThicknessSketch();
    }

    //*****************************************************************************
    //  Paint Thickness Measurement
    //****************************************************************************/

    /**
     * The user edited the thresholds for the currently selected scale. -> Update the color of the input fields.
     */
    protected handleScaleChange(): void {
        this.updateAllInputFields();
    }

    /**
     * Any data that might change the color of the paint-thickness-inputs changed. We now need to manually
     * update the paint thickness inputs so that they can adapt their color depending on their type.
     * This is necessary because the type is determined only when the reference to the scale (passed as input
     * to the paint-thickness-inputs) changes. That way we don't need to determine the type in a function call
     * from the angular template (reducing change detection load).
     */
    private updateAllInputFields(): void {
        for (const input of this.paintThicknessInputs) {
            input.determineType();
        }
    }

    /**
     * User selected a new paint thickness scale from the dropdown or edit dialog. We are given
     * the ID of the scale and need to fetch the scale so we can pass it to other components on this page.
     */
    protected updateSelectedPaintThicknessScale(newScaleId: string): void {
        this.report.car.paintThicknessSelectedScaleId = newScaleId;
        this.loadSelectedPaintThicknessScale();

        void this.saveReport();
    }

    /**
     * Load the paint thickness scale that was selected for this reports car.
     */
    protected loadSelectedPaintThicknessScale(): void {
        let selectedScale = this.team.preferences.paintThicknessMeasurementScales.find(
            (scale) => scale._id === this.report.car.paintThicknessSelectedScaleId,
        );

        if (!selectedScale) {
            // If the selected scale was deleted -> just select the first one in the list of scales
            selectedScale = this.team.preferences.paintThicknessMeasurementScales[0];
            this.report.car.paintThicknessSelectedScaleId = selectedScale._id;
        }

        this.selectedPaintThicknessScale = selectedScale;
    }

    /**
     * Determine which paint thickness positions to show to the user.
     * Activating one adds it to the report.
     */
    protected initializePaintThicknessSketch(): void {
        if (!this.report.car.paintThicknessMeasurements) {
            this.report.car.paintThicknessMeasurements = [];
            void this.saveReport();
        }

        if (!this.team.preferences.paintThicknessMeasurementScales) {
            // Add default scale if the user has no scales yet
            this.team.preferences.paintThicknessMeasurementScales = [defaultPaintThicknessScale];
            void this.saveTeam();
        }

        if (!this.report.car.paintThicknessSelectedScaleId) {
            // Select a scale for this report -> use the users default, otherwise use our standard scale
            this.report.car.paintThicknessSelectedScaleId =
                this.team.preferences.paintThicknessMeasurementScales.find((scale) => scale.isDefault)?._id ??
                defaultPaintThicknessScale._id;
        }

        this.loadSelectedPaintThicknessScale();

        // Initialize the list of valid paint thickness measurement positions for the current car shape
        this.paintThicknessPositions = getPaintThicknessPositionsForCarShape(this.report.car.shape);

        // Determine, whether this is the first initialization before populating the measurements array
        const isFirstInitialization =
            !this.report.car.paintThicknessMeasurements || this.report.car.paintThicknessMeasurements.length === 0;

        // Remove any non-applicable position from the measurements (happens if the car shape was changed)
        this.removeInvalidPaintThicknessPositions();

        // Now add the positions for the selected car shape
        this.addPaintThicknessPositionsForCurrentCarShape();

        // Load custom positions for the current car shape (if the user remembered any before)
        if (isFirstInitialization) {
            this.loadCustomPaintThicknessPositionsIfSaved();
        }

        void this.saveReport();
    }

    /**
     * Remove any non-applicable position from the measurements (happens if the car shape was changed).
     */
    private removeInvalidPaintThicknessPositions(): void {
        const measurementsToRemove = [];
        for (const measurement of this.report.car.paintThicknessMeasurements) {
            if (measurement.position !== 'custom' && !this.paintThicknessPositions.includes(measurement.position)) {
                measurementsToRemove.push(measurement);
            }
        }

        measurementsToRemove.forEach((measurement) => {
            removeFromArray(measurement, this.report.car.paintThicknessMeasurements);
        });
    }

    /**
     * For every paint thickness position that is valid for the currently selected car shape create a measurement
     * item in the report.car.paintThicknessMeasurements array. In case the user already started editing the paint
     * thickness and then switches the car shape, we keep the valid items and only add the new ones at their respective
     * position.
     */
    private addPaintThicknessPositionsForCurrentCarShape(): void {
        for (const position of this.paintThicknessPositions) {
            const existingItem = this.report.car.paintThicknessMeasurements.find(
                (paintThicknessItem) => paintThicknessItem.position === position,
            );

            if (!existingItem) {
                const expectedPosition = this.paintThicknessPositions.indexOf(position);

                // Try to insert the measurement at the correct position in the array, so that the order of items
                // is kept (important for table) even when items are added after switching the car shape.
                if (expectedPosition !== -1) {
                    this.report.car.paintThicknessMeasurements.splice(
                        expectedPosition,
                        0,
                        new PaintThicknessMeasurement(position),
                    );
                } else {
                    this.report.car.paintThicknessMeasurements.push(new PaintThicknessMeasurement(position));
                }
            }
        }
    }

    public getPaintThicknessMeasurementByPosition(
        paintThicknessPosition: PaintThicknessPosition,
    ): PaintThicknessMeasurement {
        return this.report.car.paintThicknessMeasurements.find(
            (existingMeasurement) => existingMeasurement.position === paintThicknessPosition,
        );
    }

    protected addCustomPaintThicknessMeasurement(): void {
        const customMeasurement = new PaintThicknessMeasurement('custom');

        this.report.car.paintThicknessMeasurements.push(customMeasurement);
        void this.saveReport();

        setTimeout(() => {
            // Wait for the new input row to be rendered and then focus the new input field
            this.customPositionInputs.get(this.customPositionInputs.length - 1).nativeElement.focus();
        }, 0);
    }

    protected deleteCustomPaintThicknessMeasurement(measurement: PaintThicknessMeasurement): void {
        removeFromArray(measurement, this.report.car.paintThicknessMeasurements);
        void this.saveReport();
    }

    protected customPaintThicknessPositions(): PaintThicknessMeasurement[] {
        if (!this.report.car.paintThicknessMeasurements) {
            return [];
        }

        return this.report.car.paintThicknessMeasurements.filter((measurement) => measurement.position === 'custom');
    }

    /**
     * The user selected to update the manual type of all input fields. Let's go through all of them,
     * update the manual type and re-render them.
     */
    protected updateManualTypeForAll(newSelection: PaintThicknessMeasurementTypeSelection): void {
        for (const measurement of this.report.car.paintThicknessMeasurements) {
            measurement.manualType = newSelection.type;
            if (newSelection.type === 'none') {
                measurement.noMeasurementReason = newSelection.noMeasurementReason;
            } else {
                // Clear the reason why measurement was not possible
                delete measurement.noMeasurementReason;
            }
        }
        this.updateAllInputFields();

        void this.saveReport();
    }

    /**
     * Remember the current list of custom paint thickness positions for the currently selected
     * car shape. Next time the user opens the paint thickness card for the same car shape, we
     * automatically add the custom positions again.
     */
    protected rememberCustomPaintThicknessMeasurements(): void {
        if (!this.user.preferences.paintThicknessCustomPositions) {
            this.user.preferences.paintThicknessCustomPositions = {} as Record<CarShape, string[]>;
        }

        const carShape: CarShape = this.report.car.shape ?? 'sedan';
        this.user.preferences.paintThicknessCustomPositions[carShape] = this.report.car.paintThicknessMeasurements
            .filter((measurement) => measurement.position === 'custom')
            .map((position) => position.title);

        this.customPaintThicknessPositionsUpdatedIconShown = true;
        window.setTimeout(() => {
            this.customPaintThicknessPositionsUpdatedIconShown = false;
        }, 1000);

        void this.saveUser();
    }

    /**
     * If the user saved custom paint thickness measurement positions before, load and add them to the current list.
     */
    private loadCustomPaintThicknessPositionsIfSaved(): void {
        const rememberedPositions =
            this.user.preferences.paintThicknessCustomPositions?.[this.report.car.shape ?? 'sedan'];

        if (rememberedPositions) {
            for (const position of rememberedPositions) {
                const newPosition = new PaintThicknessMeasurement('custom');
                newPosition.title = position;
                this.report.car.paintThicknessMeasurements.push(newPosition);
            }
        }
    }

    public isAxleConfiguratorRequired(car: Car): boolean {
        if (!car) {
            return false;
        }

        return isAxleConfigurationRequired(car);
    }

    /**
     * Save reports to the server.
     */
    public async saveReport(): Promise<void> {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
        }
    }

    protected async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    protected readonly isReportLocked = isReportLocked;
    protected readonly getCarTireImageName = getCarTireImageName;
    protected readonly getCarContourImageName = getCarContourImageName;
    protected readonly hasAccessRight = hasAccessRight;
}

import { AxError, AxErrorParameters, ServerError } from '../../models/errors/ax-error';

/**
 * When creating a catch-all-error, e.g. to handle all errors that might have arisen in a partner interface, it's important to keep the type of error. Otherwise,
 * the error reporting (currently Slack) would inflate since a "Forbidden" error would become a ServerError through this catch-all.
 */
export function keepErrorType(
    errorParameters: AxErrorParameters | AxError,
    {
        defaultErrorConstructor = ServerError,
    }: {
        defaultErrorConstructor?: typeof AxError;
    } = {},
): AxError {
    // Handle either an object that would be passed to an AxError constructor (errorParameters.error) or an AxError instance itself (errorParameters.causedBy).
    let causedByError;
    if ('causedBy' in errorParameters) {
        causedByError = errorParameters.causedBy;
    } else {
        causedByError = errorParameters.error;
    }

    // If the error was a BadRequest, NotFound or other client error, we do not need to be notified via the Slack channel #server-alerts. If the
    // error was caused, e.g., by a system outage of S3, we definitely want to be notified. So, keep the error type of the underlying error.
    const errorConstructor =
        causedByError instanceof AxError ? (causedByError.constructor as any) : defaultErrorConstructor;

    return new errorConstructor(errorParameters);
}

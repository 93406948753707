/**
 * Ensure characters that would break the XML/HTML structure are encoded properly. This method should be applied to all values
 * inserted into XML/HTML.
 */
export function escapeXml(unsafeString: string): string {
    if (!unsafeString) {
        return unsafeString;
    }

    /**
     * Numbers etc. cannot be replaced. They can be returned without conversion.
     */
    if (typeof unsafeString !== 'string') {
        return unsafeString;
    }

    return unsafeString.replace(/[<>&'"]/g, function (c) {
        switch (c) {
            case '<':
                return '&lt;';
            case '>':
                return '&gt;';
            case '&':
                return '&amp;';
            case "'":
                return '&apos;';
            case '"':
                return '&quot;';
        }
    });
}

export function unescapeXml(safeString: string): string {
    if (!safeString) {
        return safeString;
    }

    /**
     * Numbers etc. cannot be replaced. They can be returned without conversion.
     */
    if (typeof safeString !== 'string') {
        return safeString;
    }

    return safeString
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&apos;/g, "'")
        .replace(/&quot;/g, '"');
}

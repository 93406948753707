import { OfficeLocation } from '../../models/teams/office-location';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { getFullUserContactPerson } from '../users/get-full-user-contact-person';
import { getOfficeLocation } from '../users/get-office-location';
import { enableLineBreaks } from './enable-line-breaks';
import { getContactPersonPlaceholderObject } from './get-contact-person-placeholder-object';
import { AssessorGerman } from './placeholder-values.types';

/**
 * Returns a placeholder object containing
 * - contact person properties (Vorname, Nachname, Straße etc.)
 * - user seal
 * - professional qualification
 */
export function getUserPlaceholderObject({
    user,
    team,
    officeLocationId,
    printOrganization,
}: {
    user: User;
    team: Team;
    officeLocationId: string;
    printOrganization?: boolean;
}): AssessorGerman {
    const nameParts = [];

    //*****************************************************************************
    //  Merge User and Office Location
    //****************************************************************************/
    const assessorContactPerson = getFullUserContactPerson({
        user: user,
        officeLocations: team.officeLocations,
        officeLocationId: officeLocationId,
    });
    const officeLocation: OfficeLocation = getOfficeLocation({
        defaultOfficeLocationId: user.defaultOfficeLocationId,
        officeLocations: team.officeLocations,
        officeLocationId,
    });
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Merge User and Office Location
    /////////////////////////////////////////////////////////////////////////////*/

    if (printOrganization) {
        nameParts.push(assessorContactPerson.organization);
    }
    nameParts.push(`${assessorContactPerson.firstName} ${assessorContactPerson.lastName}`);

    const assessorContactPersonPlaceholders = getContactPersonPlaceholderObject(assessorContactPerson);

    return {
        ...assessorContactPersonPlaceholders,
        /**
         * The assessor's phone number is more specific than the office location's phone number. If users want to use
         * the office location's phone number, they should remove their personal phone number from their profile.
         */
        Telefon: assessorContactPersonPlaceholders.Telefon || officeLocation.phone || '',
        Standortname: officeLocation.title,
        Standortkürzel: officeLocation.initials || officeLocation.title?.slice(0, 3) || '',
        StandortTelefon: officeLocation.phone || '',
        // NameKomplett is overwritten since the assessor does not need a salutation such as "Herr" and "Frau".
        NameKomplett: enableLineBreaks(nameParts.join('\n')),
        Stempel: user.sealHash || '',
        Logo: team.logoHash || '',
        Handelsregisternummer: team.commercialRegisterId || '',
        Handelsregister: team.commercialRegisterName || '',
        NameUnterStempelVerstecken: user.hideNameBelowSeal,
        BeruflicheQualifikation: user.occupationalQualification || '',
    };
}

import { DateTime } from 'luxon';
import { BadRequest, UnprocessableEntity } from '../../models/errors/ax-error';
import { RepairExecutionType } from '../../models/reports/repair-confirmation';
import { Report } from '../../models/reports/report';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { toGermanDate } from '../ax-luxon';
import { getContactPersonPlaceholderObject } from './get-contact-person-placeholder-object';
import { getUserPlaceholderObject } from './get-user-placeholder-object';
import { PlaceholderValuesRepairConfirmation } from './placeholder-values.types';
import { Translator } from './translator';

export async function getTemplatePlaceholderValuesRepairConfirmation({
    report,
    user,
    team,
    teamMembers,
}: {
    report: Report;
    user: User;
    team: Team;
    teamMembers: User[];
}): Promise<PlaceholderValuesRepairConfirmation> {
    if (!report) {
        throw new BadRequest({
            code: 'MISSING_REPORT',
            message:
                'Defining placeholders does not work without the report parameter. Please make sure the correct hooks and parameters are set.',
        });
    }

    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
    const photos = report.photos.filter((photo) => photo.versions.repairConfirmation.included);

    const repairConfirmation = report.repairConfirmation;

    //*****************************************************************************
    //  Assessor
    //****************************************************************************/
    let assessor: User = user;
    if (repairConfirmation.assessor !== user._id) {
        assessor = teamMembers.find((teamMember) => teamMember._id === repairConfirmation.assessor);
    }
    let visitingAssessor: User = user;
    if (repairConfirmation.visitingAssessor !== user._id) {
        visitingAssessor = teamMembers.find((teamMember) => teamMember._id === repairConfirmation.visitingAssessor);
    }

    if (!assessor) {
        throw new UnprocessableEntity({
            code: 'REPAIR_CONFIRMATION_RESPONSIBLE_ASSESSOR_UNKNOWN',
            message: `Generating template placeholders failed because the responsible assessor of the repair confirmation is not selected or not part of the team.`,
            data: {
                placeholderCategory: 'responsibleAssessor',
                responsibleAssessor: repairConfirmation.assessor,
            },
        });
    }
    if (!visitingAssessor) {
        throw new UnprocessableEntity({
            code: 'REPAIR_CONFIRMATION_VISITING_ASSESSOR_UNKNOWN',
            message: `Generating template placeholders failed because the visiting assessor of the repair confirmation is not selected or not part of the team.`,
            data: {
                placeholderCategory: 'visitingAssessor',
                visitingAssessor: repairConfirmation.visitingAssessor,
            },
        });
    }

    const assessorPlaceholderObject = getUserPlaceholderObject({
        user: assessor,
        team,
        officeLocationId: report.officeLocationId,
    });
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Assessor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Completion Date
    //****************************************************************************/
    const completionDateFormatted = toGermanDate(
        repairConfirmation.completionDate || repairConfirmation.date || DateTime.now(),
    );
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Completion Date
    /////////////////////////////////////////////////////////////////////////////*/

    return {
        Sachverständiger: assessorPlaceholderObject,
        Reparaturbestätigung: {
            Datum: toGermanDate(repairConfirmation.date || DateTime.now()),
            anwesenderSachverständiger: `${visitingAssessor.firstName} ${visitingAssessor.lastName}`,
            zuständigerSachverständiger: `${assessor.firstName} ${assessor.lastName}`,
            ReparaturArt:
                translateRepairExecutionType(repairConfirmation.repairExecutionType) ||
                '[WERT DES PLATZHALTERS NICHT GEFÜLLT]',
            ReparaturdauerInArbeitstagen: repairConfirmation.actualReparationDowntime || '',
            Kommentar: repairConfirmation.comment || '',
            AnzahlFotos: photos.length || 0,
            Fertigstellungsdatum: completionDateFormatted,
        },
        Anschreiben: {
            Absender: assessorPlaceholderObject,
            Empfänger: {
                ...getContactPersonPlaceholderObject(report.claimant.contactPerson),
                Typ: Translator.letterRecipient(report.claimant.role),
            },
            Datum: completionDateFormatted,
            Betreff: '',
            Inhalt: '',
        },
    };
    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
}

function translateRepairExecutionType(repairExecutionType: RepairExecutionType) {
    let repairExecutionTypeGerman = '';

    switch (repairExecutionType) {
        case 'repaired':
            repairExecutionTypeGerman = 'repariert';
            break;
        case 'professional':
            repairExecutionTypeGerman = 'fach- & sachgerecht';
            break;
        case 'withUsedParts':
            repairExecutionTypeGerman = 'mit Gebrauchtteilen';
            break;
        case 'partial':
            repairExecutionTypeGerman = 'teilweise';
            break;
        case 'roadworthy':
            repairExecutionTypeGerman = 'verkehrssicher';
            break;
    }

    return repairExecutionTypeGerman;
}

import { HttpClient } from '@angular/common/http';
import { Query as FeathersQuery } from '@feathersjs/feathers';
import { DateTime } from 'luxon';
import qs from 'qs';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { pluralize } from '@autoixpert/lib/pluralize';
import { FlattenedChangePaths } from '@autoixpert/lib/server-sync/flatten-change-paths';
import { DataTypeBase, DatabaseServiceName, DeletedRecord } from '@autoixpert/models/indexed-db/database.types';

export class AxHttpSync<DataType extends DataTypeBase> {
    public readonly serviceName: DatabaseServiceName; // Name of the service in the backend that this database shall sync with. Singular, such as "report" for the endpoint "/reports"
    protected serviceNamePlural: string; // Plural name, such as "reports" or "contactPeople"-> Is explicitly passed or inferred automatically.
    protected apiPathPrefix: string; // For paths like "/bankAccountSync/bankAccounts" this value's would be "/bankAccountSync"

    protected httpClient: HttpClient;

    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        apiPathPrefix?: string; // e. g. "/admin"
        httpClient: HttpClient;
    }) {
        this.serviceName = params.serviceName;
        this.serviceNamePlural = params.serviceNamePlural ?? pluralize(this.serviceName);
        this.apiPathPrefix = params.apiPathPrefix;

        this.httpClient = params.httpClient;
    }

    public async createRemote(record: DataType): Promise<DataType> {
        return await this.httpClient
            .post<DataType>(`${this.getEndpointBasePath()}/${this.serviceNamePlural}`, record)
            .toPromise();
    }

    public async findRemote(query?: FeathersQuery): Promise<DataType[]> {
        const serviceUrl = `${this.getEndpointBasePath()}/${this.serviceNamePlural}`;

        /**
         * If a single ID is queried via an $in array, convert it to a simpler format {_id : string}.
         * This makes debugging in the network tab of the browser developer tools easier.
         */
        if (Array.isArray(query?._id?.$in) && query._id.$in.length === 1) {
            query._id = query._id.$in[0];
        }

        // A single ID can be converted to 'reports/abc-id', everything else will be added as a query string.
        if (Object.keys(query).length === 1 && typeof query._id === 'string') {
            const record = await this.httpClient.get<DataType>(`${serviceUrl}/${query._id}`).toPromise();
            return [record];
        } else {
            /**
             * Convert query object to URL-compatible string.
             */
            const queryString = query
                ? qs.stringify(query, {
                      /**
                       * By default, qs converts null to empty string. Change this behavior to keep null values.
                       */
                      strictNullHandling: true,
                  })
                : '';

            return await this.httpClient
                .get<DataType[]>(`${serviceUrl}${queryString ? `?${queryString}` : ''}`)
                .toPromise();
        }
    }

    public async patchRemote(recordId: DataType['_id'], deltaPatch: FlattenedChangePaths): Promise<void> {
        await this.httpClient
            .patch<Partial<DataType>>(`${this.getEndpointBasePath()}/${this.serviceNamePlural}/${recordId}`, deltaPatch)
            .toPromise();
    }

    public async deleteRemote(recordId: DataType['_id']): Promise<void> {
        await this.httpClient
            .delete<DataType>(`${this.getEndpointBasePath()}/${this.serviceNamePlural}/${recordId}`)
            .toPromise();
    }

    public async removeDeletedMarker(recordId: DataType['_id']): Promise<void> {
        try {
            await this.httpClient
                .delete<DataType>(`${this.getEndpointBasePath()}/${this.serviceNamePlural}Deleted/${recordId}`)
                .toPromise();
        } catch (error) {
            // If the deleted marker was not found on the server, don't throw an error. The target state is reached.
            if (error.code !== 'RESOURCE_NOT_FOUND') {
                throw error;
            }
        }
    }

    /**
     * For the client to know that a records has been deleted on the server by another client, the server must keep
     * deletion records. This method gets them, optionally since the last sync by means of the params object.
     * @private
     */
    public async findDeletedRemote({
        syncRecordsDeletedAfter,
    }: {
        syncRecordsDeletedAfter?: DateTime;
    } = {}): Promise<DeletedRecord[]> {
        let queryString = '';

        if (syncRecordsDeletedAfter) {
            const query = {
                createdAt: { $gt: syncRecordsDeletedAfter.toISO() },
            };

            queryString = query && Object.keys(query).length ? qs.stringify(query) : '';
        }
        return await this.httpClient
            .get<
                DeletedRecord[]
            >(`${this.getEndpointBasePath()}/${this.serviceNamePlural}Deleted${queryString ? `?${queryString}` : ''}`)
            .toPromise();
    }

    private getEndpointBasePath() {
        if (this.apiPathPrefix) {
            return `${apiBasePath}/${this.apiPathPrefix}`;
        }
        return apiBasePath;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server
    /////////////////////////////////////////////////////////////////////////////*/
}

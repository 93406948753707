import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Team } from '@autoixpert/models/teams/team';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { FeathersSocketioService } from './feathers-socketio.service';
import { LoggedInUserService } from './logged-in-user.service';
import { TeamService } from './team.service';
import { UserService } from './user.service';

@Injectable()
export class AuthenticationService {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private userService: UserService,
        private feathersSocketioService: FeathersSocketioService,
        private httpClient: HttpClient,
    ) {}

    /**
     * Emits the authentication response if authentication was successful.
     * Used to trigger common tasks after all means of authentication, such as syncing with the server.
     */
    public authenticationSuccess: Subject<AuthenticationResponse> = new Subject();

    async tryAuthentication(options: TryAuthenticationOptions): Promise<AuthenticationResponse> {
        const authenticationPayload: TryAuthenticationOptions = {
            strategy: 'local',
        };
        if (options.email) {
            authenticationPayload.strategy = 'local';
            authenticationPayload.email = options.email;
            authenticationPayload.password = options.password;
        } else if (options.oneTimeAdminToken) {
            authenticationPayload.strategy = 'oneTimeAdminToken';
            authenticationPayload.oneTimeAdminToken = options.oneTimeAdminToken;
        } else if (options.accessToken) {
            authenticationPayload.strategy = 'jwt';
            authenticationPayload.accessToken = options.accessToken;
        }

        const authenticationResponse: AuthenticationResponse = await this.httpClient
            .post<AuthenticationResponse>('/api/v0/authentication', authenticationPayload)
            .toPromise();
        authenticationResponse.user.preferences = Object.assign(
            new UserPreferences(),
            authenticationResponse.user.preferences,
        );
        this.setLocalJwt(authenticationResponse.accessToken);

        this.loggedInUserService.setPersistentLocalStorage(options.rememberMe);
        this.loggedInUserService.setTeam(authenticationResponse.team);
        this.loggedInUserService.setUser(authenticationResponse.user);

        // After authentication, refresh all team members and the team data and save them to the local database.
        this.userService.find().subscribe();
        await this.teamService.localDb.createLocal(authenticationResponse.team, 'externalServer');
        await this.teamService.localDb.putServerShadow(authenticationResponse.team);

        this.authenticationSuccess.next(authenticationResponse);

        return authenticationResponse;
    }

    public getJwtPayload(): JwtPayload | undefined {
        const currentJwt = this.getLocalJwt();
        if (!currentJwt) {
            return;
        }

        return JSON.parse(atob(currentJwt.split('.')[1])); // Parts: [0] -> Header, [1] -> Payload, [2] -> Signature
    }

    public isAdminAuthentication(): boolean {
        const jwtPayload = this.getJwtPayload();
        return !!jwtPayload?.autoixpertAdminAuthentication;
    }

    /**
     * If the current JWT will expire in less than three days, re-new it.
     */
    public async renewJwt(): Promise<void> {
        // Extract JWT's payload
        const currentJwtPayload: JwtPayload = this.getJwtPayload();
        if (!currentJwtPayload) {
            return;
        }
        const expirationDate: Moment = moment(currentJwtPayload.exp * 1000); // Convert from seconds to milliseconds
        const renewalDate = moment(expirationDate).subtract(3, 'days');
        const now = moment();

        if (now.isBefore(renewalDate)) {
            // console.log('JWT is still valid for more than 3 days. No renewal needed.');
            return;
        }

        // Renew JWT
        let response: AuthenticationResponse;
        try {
            response = await this.httpClient
                .post<AuthenticationResponse>('/api/v0/authentication', {
                    strategy: 'jwt',
                })
                .toPromise();
        } catch (error) {
            /**
             * AX_NOT_AUTHENTICATED will be handled within the application, so it is not wrapped in another error.
             */
            if (error.code === 'AX_NOT_AUTHENTICATED') {
                throw error;
            }
            throw new AxError({
                code: 'REVALIDATING_JWT_FAILED',
                message: 'The JSON Web Token (JWT) could not be revalidated. Have a look at the causedBy property.',
                data: {
                    currentJwtPayload,
                },
                error,
            });
        }

        this.setLocalJwt(response?.accessToken);
    }

    private setLocalJwt(jwt: string): void {
        store.set('autoiXpertJWT', jwt);
    }

    public getLocalJwt(): string {
        return store.get('autoiXpertJWT');
    }

    // Mark the socket as unauthenticated. This happens when a user logs out.
    public async unauthenticateSocket() {
        /**
         * feathersSocketioService.app.logout() does three things:
         * - DELETE authentication on server
         * - remove JWT locally
         * - mark the socket as unauthenticated.
         *
         * We don't need to delete a session on the server since JWTs are stateless (no server state), we remove the JWT locally in the LoggedInUserService.
         * Marking the socket as unauthenticated is done by reset().
         */
        // this.feathersSocketioService.app.logout();
        await this.feathersSocketioService.app.authentication.reset();

        /**
         * Ensure that the socket is closed on the server so that the user on this device may log in as someone else. If the server
         * still had the socket open, the socket would keep the team data of the previously logged-in user at context.params.team --> access issue.
         *
         * The network service automatically unauthenticates this client feathers app through "this.feathersSocketioService.app.authentication.reset()" in the
         * disconnect event listener. It also reconnects automatically.
         */
        this.feathersSocketioService.socket.disconnect();
    }
}

export interface AuthenticationResponse {
    accessToken: string;
    authentication: any;
    user: User;
    team: Team;
}

interface TryAuthenticationOptions {
    strategy: 'local' | 'jwt' | 'oneTimeAdminToken';
    email?: string;
    password?: string;
    oneTimeAdminToken?: string;
    accessToken?: string;
    rememberMe?: boolean;
}

interface JwtPayload {
    aud: string; // audience = the domain this JWT is intended for
    exp: number; // expiration in seconds since 1970-01-01 00:00:00.000
    iat: number; // issued at
    iss: string; // issuer
    jti: string; // JWT ID
    sub: string; // Subject. Currently not used by aX.
    userId: string; // autoiXpert User ID
    autoixpertAdminAuthentication?: undefined | boolean;
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class DatAuthenticationService {
    constructor(private httpClient: HttpClient) {}

    async getJwt(): Promise<DatJwtResponse> {
        const existingDatJwt = store.get('datJwt');

        // If the DAT user's JWT is valid for at least 3 more minutes (to account for network delays in the aX-DAT communication and time differences in the DAT & aX server times), use the existing JWT.
        if (existingDatJwt) {
            const expirationDate = DatAuthenticationService.getJwtExpirationDate(existingDatJwt);

            if (moment().isBefore(expirationDate.subtract(3, 'minute'))) {
                console.log(
                    `Using still valid JWT for authentication against the DAT. Expiration date: ${expirationDate.toISOString()}`,
                );
                return existingDatJwt;
            }
        }
        const newDatJwt: string = await this.httpClient.get<string>('/api/v0/dat/authentication').toPromise();
        console.log(
            `Created a new DAT JWT. Expiration date: ${DatAuthenticationService.getJwtExpirationDate(
                newDatJwt,
            ).toISOString()}`,
        );
        store.set('datJwt', newDatJwt);
        return newDatJwt;
    }

    static getJwtExpirationDate(jwt: string): moment.Moment {
        const [, payloadBase64] = jwt.split('.');
        const payload = JSON.parse(atob(payloadBase64));

        // The DAT saves a JavaScript-typical unix timestamp in milliseconds, so we can use the timestamp in moment's constructor here.
        return moment(payload.iat).add(30, 'minutes');
    }

    static getDatJwtHeaders(datJwt: string): HttpHeaders {
        // If the DAT credentials are given instead of the DAT JWT, don't add headers.
        if (typeof datJwt !== 'string') {
            return undefined;
        }

        return new HttpHeaders().append('dat-jwt', datJwt);
    }
}

export type DatJwtResponse = string;

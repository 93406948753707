export class EmergencyRepair {
    constructor(template: Partial<EmergencyRepair> = {}) {
        Object.assign(this, template);
    }

    state: 'notRecommended' | 'recommended' | 'carriedOut' = null;
    workItems: string[] = [];
    costs: number = null;
    comment: string = null;
}

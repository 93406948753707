import { escapeRegExp } from 'lodash-es';

/**
 * What properties shall be searched with a search term?
 * @param searchTerm
 * @protected
 */
export function get$SearchMongoQueryReports(searchTerm: string): [string, any] {
    const searchTerms = searchTerm.split(' ');
    // An array equal to the number of search terms given in the input field.
    const searchesPerSearchTerm = [];

    // All search terms must match at least one of the specified document fields.
    // Tech: Create an individual $and condition for every search term. The search term again consists of conditions linked with $or.
    for (const searchTerm of searchTerms) {
        const mongodbRegex = { $regex: new RegExp(escapeRegExp(searchTerm), 'i') };
        searchesPerSearchTerm.push({
            $or: [
                // When changing this, update the query in the backend as well (interpret-search-parameters.ts for /reports endpoint)
                { token: mongodbRegex },
                { 'claimant.contactPerson.lastName': mongodbRegex },
                { 'claimant.contactPerson.firstName': mongodbRegex },
                { 'claimant.contactPerson.organization': mongodbRegex },
                { 'claimant.caseNumber': mongodbRegex },
                { 'car.licensePlate': mongodbRegex },
                { 'garage.contactPerson.organization': mongodbRegex },
                { 'lawyer.contactPerson.organization': mongodbRegex },
                { 'lawyer.caseNumber': mongodbRegex },
                { 'car.vin': mongodbRegex },
                { 'car.make': mongodbRegex },
                { 'car.model': mongodbRegex },
                { 'insurance.contactPerson.organization': mongodbRegex },
                { 'insurance.caseNumber': mongodbRegex },
                { 'authorOfDamage.insuranceNumber': mongodbRegex },
                { 'claimant.contactPerson.phone': mongodbRegex },
                { 'claimant.contactPerson.email': mongodbRegex },
                { 'ownerOfClaimantsCar.contactPerson.lastName': mongodbRegex },
                { 'ownerOfClaimantsCar.contactPerson.firstName': mongodbRegex },
                { 'ownerOfClaimantsCar.contactPerson.organization': mongodbRegex },
                { 'authorOfDamage.contactPerson.lastName': mongodbRegex },
                { 'authorOfDamage.contactPerson.firstName': mongodbRegex },
                { 'authorOfDamage.contactPerson.organization': mongodbRegex },
                { 'ownerOfAuthorOfDamagesCar.contactPerson.lastName': mongodbRegex },
                { 'ownerOfAuthorOfDamagesCar.contactPerson.firstName': mongodbRegex },
                { 'ownerOfAuthorOfDamagesCar.contactPerson.organization': mongodbRegex },
            ],
        });
    }

    return ['$and', searchesPerSearchTerm];
}

import { Place } from '@autoixpert/models/contacts/place';
import { mapLocationToQueryString } from './map-location-to-query-string';

/**
 *
 * @param origins
 * @param destinations
 * @param alwaysBackToOrigin - After each destination, lead back to origins. If deactivated, proceed to next destination immediately.
 */
export function openDirectionsOnGoogleMaps({
    origins,
    destinations,
}: {
    origins: Place[];
    destinations: Place[];
}): Window {
    const routeElements: Place[] = [];
    for (const index in origins) {
        routeElements.push(origins[index], destinations[index]);
    }

    let queryString = '';
    let lastRouteElement: string;
    for (const routeElement of routeElements) {
        const nextRouteElement = mapLocationToQueryString(routeElement);
        /**
         * Only add route elements if they deviate so the user may see a clear route without duplicates.
         * Duplicate elements exist because the route is calculated like this:
         * origin[0] --> destination[0]
         * destination[0] --> origin[0]
         * origin[1] --> destination[1]
         * destination[1] --> origin[1]
         * That's required for calculating the distance via the Google API (backend-autoixpert) but not useful for creating
         * the Google Maps link for the user through the browser.
         */
        if (lastRouteElement === nextRouteElement) {
            continue;
        }
        queryString += `${nextRouteElement}/`;
        lastRouteElement = nextRouteElement;
    }

    // Replace spaces with + symbols
    queryString.replace(' ', '+');

    return window.open(`https://www.google.com/maps/dir/${queryString}`);
}

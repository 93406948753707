<div class="access-rights-container">
    <!--********** Administrator **********-->
    <div *ngIf="!config?.administrator?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                config.administrator?.tooltip ??
                'Administratoren dürfen die Kontaktdaten des Teams und die Zugriffsrechte verwalten.'
            ">
            Administrator
        </div>
        <mat-slide-toggle
            [ngModel]="isAdmin"
            (change)="isAdminChange.emit($event)"
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.administrator?.disabled"></mat-slide-toggle>
    </div>

    <!--********** All reports **********-->
    <div *ngIf="!config?.seeAllReports?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                config.seeAllReports?.tooltip ??
                'Alle Gutachten des gesamten Teams sehen. Ohne dieses Recht sieht der Nutzer nur seine eigenen Gutachten.'
            ">
            {{ translateAccessRight('seeAllReports') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.seeAllReports"
            (change)="
                accessRightsChange.emit({
                    accessRight: 'seeAllReports',
                    checked: $event.checked,
                    source: $event.source,
                })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.seeAllReports?.disabled"></mat-slide-toggle>
    </div>

    <!--********** Lock reports **********-->
    <div *ngIf="!config?.lockReports?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="config.lockReports?.tooltip ?? 'Gutachten abschließen und abgeschlossene öffnen'">
            {{ translateAccessRight('lockReports') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.lockReports"
            (change)="
                accessRightsChange.emit({ accessRight: 'lockReports', checked: $event.checked, source: $event.source })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.lockReports?.disabled"></mat-slide-toggle>
    </div>

    <!--********** Edit Texttemplates / Document Building Blocks **********-->
    <div *ngIf="!config?.editTextsAndDocumentBuildingBlocks?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                config.editTextsAndDocumentBuildingBlocks?.tooltip ??
                'Ermöglicht die Bearbeitung von:\n- Textbausteinen\n- Briefpapier\n- Dokument-Wasserzeichen-Vorlagen\n- Textvorlagen\n- Autocomplete-Einträgen'
            ">
            {{ translateAccessRight('editTextsAndDocumentBuildingBlocks') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.editTextsAndDocumentBuildingBlocks"
            (change)="
                accessRightsChange.emit({
                    accessRight: 'editTextsAndDocumentBuildingBlocks',
                    checked: $event.checked,
                    source: $event.source,
                })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.editTextsAndDocumentBuildingBlocks?.disabled"></mat-slide-toggle>
    </div>

    <!--********** Report Fees **********-->
    <div *ngIf="!config?.seeReportFees?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                config.seeReportFees?.tooltip ??
                'Nur mit diesem Recht darf der Nutzer den Rechnungs-Reiter im Gutachtenprozess sehen und die Rechnungs-PDF in Druck & Versand herunterladen.'
            ">
            {{ translateAccessRight('seeReportFees') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.seeReportFees"
            (change)="
                accessRightsChange.emit({
                    accessRight: 'seeReportFees',
                    checked: $event.checked,
                    source: $event.source,
                })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.seeReportFees?.disabled"></mat-slide-toggle>
    </div>

    <!--********** Edit Contacts **********-->
    <div *ngIf="!config?.editContacts?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                config.editContacts?.tooltip ??
                'Kontakte bearbeiten oder entfernen. Ohne dieses Recht kann er bestehende Kontakte nutzen oder neue erstellen.'
            ">
            {{ translateAccessRight('editContacts') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.editContacts"
            (change)="
                accessRightsChange.emit({ accessRight: 'editContacts', checked: $event.checked, source: $event.source })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.editContacts?.disabled"></mat-slide-toggle>
    </div>

    <!--********** Invoices **********-->
    <div *ngIf="!config?.invoiceList?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="config.invoiceList?.tooltip ?? 'Rechnungen in der Rechnungsliste anzeigen'">
            {{ translateAccessRight('invoiceList') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.invoiceList"
            (change)="
                accessRightsChange.emit({ accessRight: 'invoiceList', checked: $event.checked, source: $event.source })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.invoiceList?.disabled"></mat-slide-toggle>
    </div>

    <!--********** See all Invoices **********-->
    <div *ngIf="!config?.seeAllInvoices?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                'Rechnungen aller Team-Mitglieder in der Rechnungsliste anzeigen. Ist dieses Recht nicht erteilt, kann der Nutzer nur seine eigenen Rechnungen sehen.' +
                '\n\n' +
                'Nur mit diesem Recht darf der Nutzer den Bankkontoabgleich nutzen.'
            ">
            {{ translateAccessRight('seeAllInvoices') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.seeAllInvoices"
            (change)="
                accessRightsChange.emit({
                    accessRight: 'seeAllInvoices',
                    checked: $event.checked,
                    source: $event.source,
                })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.seeAllInvoices?.disabled"></mat-slide-toggle>
    </div>

    <!--********** Bank Account Sync **********-->
    <div *ngIf="!config?.bankAccountSync?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                config.bankAccountSync?.tooltip ??
                'Rechnungen als bezahlt markieren lassen, wenn auf dem verknüpften Bankkonto eine Zahlung dafür eingeht.'
            ">
            {{ translateAccessRight('bankAccountSync') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.bankAccountSync"
            (change)="
                accessRightsChange.emit({
                    accessRight: 'bankAccountSync',
                    checked: $event.checked,
                    source: $event.source,
                })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.bankAccountSync?.disabled"></mat-slide-toggle>
    </div>

    <!--********** Reporting / Analytics **********-->
    <div *ngIf="!config?.reporting?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                config.reporting?.tooltip ??
                'Auswertungen zu Umsatz, Honoraren, etc. abrufen.\n\nWenn ein Nutzer die Zugriffsrechte Alle Gutachten bzw. Alle Rechnungen nicht besitzt, kann er nur eigene Gutachten bzw. Rechnungen auswerten.'
            ">
            {{ translateAccessRight('reporting') }}
        </div>
        <mat-slide-toggle
            [(ngModel)]="accessRights.reporting"
            (change)="
                accessRightsChange.emit({ accessRight: 'reporting', checked: $event.checked, source: $event.source })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.reporting?.disabled"></mat-slide-toggle>
    </div>

    <!--********** Settings Page **********-->
    <div *ngIf="!config?.seeSettings?.hidden" class="access-right">
        <div
            class="access-right-name"
            [matTooltip]="
                config.seeSettings?.tooltip ??
                'Darf der Nutzer die Einstellungen-Seite öffnen und ggf. Passwörter zu Schnittstellenpartnern einsehen?'
            ">
            {{ translateAccessRight('seeSettings') }}
        </div>
        <mat-slide-toggle
            [ngModel]="accessRights.seeSettings"
            (change)="
                accessRightsChange.emit({ accessRight: 'seeSettings', checked: $event.checked, source: $event.source })
            "
            [matTooltip]="askYourAdminTooltip"
            [disabled]="config.seeSettings?.disabled"></mat-slide-toggle>
    </div>
</div>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { Car, NumberOfPreviousOwnersType } from '@autoixpert/models/reports/car-identification/car';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { triggerClickEventOnSpaceBarPress } from 'src/app/shared/libraries/trigger-click-event-on-space-bar-press';
import { getMissingAccessRightTooltip } from '../../../../shared/libraries/get-missing-access-right-tooltip';
import { hasAccessRight } from '../../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { TeamService } from '../../../../shared/services/team.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
    selector: 'previous-owners',
    templateUrl: './previous-owners.component.html',
    styleUrls: ['./previous-owners.component.scss'],
})
export class PreviousOwnersComponent implements OnInit, OnDestroy {
    @Input() report: Report;
    @Input() isReportLocked: boolean;
    @Input() isSmallScreen: boolean;
    @Input() maxPreviousOwners: number = 7;

    @Output() onReportChange = new EventEmitter();

    private subscriptions: Subscription[] = [];
    protected team: Team;
    protected user: User;

    constructor(
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private teamService: TeamService,
        private apiErrorService: ApiErrorService,
    ) {}

    ngOnInit() {
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));
        this.subscriptions.push(this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)));
    }

    /**
     * Display icons for the previous owners.
     * Usually, 7 previous owners are displayed.
     * In some cases (e.g. bicycles), to save space, only a smaller amount of previous owners are displayed.
     */
    get previousOwnersForUI(): Car['numberOfPreviousOwners'][] {
        const maxPreviousOwners = this.maxPreviousOwners > 7 ? 7 : this.maxPreviousOwners;
        // Zero-based array
        return Array(maxPreviousOwners + 1)
            .fill(0)
            .map((_, i) => `${i}` as NumberOfPreviousOwnersType);
    }

    public selectNumberOfPreviousOwners(numberOfPreviousOwners: Car['numberOfPreviousOwners']) {
        // If the same number is selected again -> de-select
        if (this.report.car.numberOfPreviousOwners === numberOfPreviousOwners) {
            this.report.car.numberOfPreviousOwners = null;
        }
        // different number
        else {
            this.report.car.numberOfPreviousOwners = numberOfPreviousOwners;
        }
        this.emitChange();
    }

    protected showInputForSourceOfPreviousOwners(): void {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        this.team.preferences.sourceOfNumberOfPreviousOwnersShown = true;
        void this.saveTeam();
    }

    protected hideInputForSourceOfPreviousOwners(): void {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        if (this.report.car.sourceOfNumberOfPreviousOwners) {
            this.toastService.info(
                'Quelle bleibt sichtbar',
                'Das Feld wird automatisch eingeblendet, solange ein Wert im Feld steht. Bei neuen Gutachten wird das Feld ausgeblendet.',
            );
        }
        this.team.preferences.sourceOfNumberOfPreviousOwnersShown = false;
        void this.saveTeam();
    }

    //*****************************************************************************
    //  Helpers
    //****************************************************************************/

    protected emitChange() {
        this.onReportChange.emit();
    }

    public async saveTeam(): Promise<Team> {
        try {
            return await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * Get the function to trigger a click event on pressing the space bar from the
     * shared module.
     *
     * @type {(event:KeyboardEvent)=>void}
     */
    protected triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;
    protected Number = Number;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Helpers
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    protected readonly isAdmin = isAdmin;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
    protected readonly hasAccessRight = hasAccessRight;
}

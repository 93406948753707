import { PhotoVersion } from '../../models/reports/damage-description/photo';
import { Report } from '../../models/reports/report';
import { enableLineBreaks } from './enable-line-breaks';
import { PhotoGerman } from './placeholder-values.types';

export function getPhotosPlaceholderValues({
    photos,
    photoVersion,
}: {
    photos: Report['photos'];
    photoVersion: PhotoVersion;
}): PhotoGerman[] {
    const photosGerman: PhotoGerman[] = [];

    let counter = 0;
    for (const photo of photos) {
        const detailedPhotoDescriptions = [];
        // If the photo is not included, skip it.
        if (!photo.versions[photoVersion].included) {
            continue;
        }

        // If no fabric.js object was added to this photo, no need to render shapes.
        if (photo.versions[photoVersion].fabricJsInformation) {
            // Iterate over all objects created by Fabric.js for this photo. Extract the description from each numberToken.
            photo.versions[photoVersion].fabricJsInformation.objects.forEach((fabricObject) => {
                // Only do something with objects of type numberToken. The numberToken object's property "fabricObject.type" is "group" but we save the value "numberToken" in "fabricObject.data.axType".
                if (fabricObject.data && fabricObject.data.axType === 'numberToken') {
                    let numberTokenNumber = '';
                    let numberTokenColor = '';
                    const numberTokenDescription = fabricObject.data.axPhotoDescription;
                    // Find the text within the numberToken
                    fabricObject.objects.forEach((objectWithinNumberToken) => {
                        if (objectWithinNumberToken.type.toLowerCase() === 'text') {
                            numberTokenNumber = objectWithinNumberToken.text;
                        }
                        if (objectWithinNumberToken.type.toLowerCase() === 'circle') {
                            numberTokenColor = objectWithinNumberToken.stroke;
                        }
                    });
                    detailedPhotoDescriptions.push({
                        Fotobeschreibungsnummer: `${numberTokenNumber}|${numberTokenColor}`,
                        Fotobeschreibung: numberTokenDescription || '<p>Keine Beschreibung vorhanden.</p>',
                    });
                }
            });
        }

        // The photo number must start with 1 in the DOCX file since humans start counting with 1 instead of 0.
        const photoNumber = ++counter;
        photosGerman.push({
            Fotonummer: photoNumber,
            // Describing the photo in general. "FotoFlietext" has the same margin left as the photo itself.
            AllgemeineFotobeschreibung: photo.description
                ? enableLineBreaks(photo.description, { paragraphStyle: 'FotoFlietext' })
                : '',

            DetaillierteFotobeschreibungen: detailedPhotoDescriptions,
            // This is for rendering the photo file. The photo ID allows getting the correct image from the list of rendered photo buffers
            // for the DOCX document.
            Foto: photo._id,
        });
    }

    return photosGerman;
}

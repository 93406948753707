import { DamagedPart, RepairTypeGerman } from '@autoixpert/models/reports/damage-calculation/damaged-part';
import { Repair } from '@autoixpert/models/reports/damage-description/repair';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';

/**
 * Apply a blacklist to filter irrelevant damaged part descriptions.
 */
export function filterDamagedPartDescriptions(
    damagedParts: Repair['damagedParts'],
    blacklist: string[] = [],
): Repair['damagedParts'] {
    // Merge predefined blacklist (to be extended from customer feedback) with user-defined blacklist.
    const mergedBlacklist = ['verbringung', ...blacklist.map((blacklisted) => blacklisted.toLowerCase())];

    return damagedParts.filter((part) => {
        if (!part.repairType) {
            return false;
        }

        // Yes, the description can be null. Seen in DAT calculations in the wild.
        if (!part.description) {
            return false;
        }

        const lowercased = part.description.toLowerCase() || '';
        return !mergedBlacklist.some((blacklisted) => lowercased.includes(blacklisted.toLowerCase()));
    });
}
export function translateDamagedPartDescription(description: string): string {
    const words = description.split(' ');

    const transformedWords = words.map((word) => {
        let lowercased = word.toLowerCase();

        // Correct umlauts (Audatex has only ue or ue)
        // Replacing all occurrences of ae, oe or ue should be fine since they do do only appear in as umlaut in german words.
        // Uncritical exception: English names, e.g. Joe
        lowercased = lowercased.replaceAll('ae', 'ä');
        lowercased = lowercased.replaceAll('oe', 'ö');
        lowercased = lowercased.replaceAll('ue', 'ü');

        // Handle the currently only known exception ('Quer', e.g. in 'Querlenker')
        lowercased = lowercased.replaceAll('qür', 'quer');

        switch (lowercased) {
            case 'v':
            case 'v.':
                return 'vorne';
            case 'v.i.':
                return 'vorne innen';
            case 'v.a.':
                return 'vorne außen';
            case 'h':
            case 'h.':
                return 'hinten';
            case 'h.i.':
                return 'hinten innen';
            case 'h.a.':
                return 'hinten außen';
            case 'a':
            case 'a.':
                return 'außen';
            case 'i':
            case 'i.':
                return 'innen';
            case 'l':
            case 'l.':
                return 'links';
            case 'r':
            case 'r.':
                return 'rechts';

            case 'seitl.':
                return 'seitlich';

            case 'verkl':
                return 'Verkleidung';
            case 'stossf':
                return 'Stoßfänger';
            case 'stoßf.-verkleidung':
                return 'Stoßfängerverkleidung';

            case 'sra-waschdüse':
                return 'Scheibenreinigungsanlage-Reinigungsdüse';

            case 'bef.-satz':
                return 'Befestigungssatz';

            case 'kpl':
            case 'kpl.':
                return 'komplett';

            case 'a-säule':
                return 'A-Säule';
            case 'b-säule':
                return 'B-Säule';
            case 'c-säule':
                return 'C-Säule';
            case 'd-säule':
                return 'D-Säule';

            default:
                return lowercased.charAt(0).toUpperCase() + lowercased.slice(1).toLowerCase();
        }
    });
    return transformedWords.join(' ');
}

export function generateDamageDescriptionFromCalculation({
    userPreferences,
    damagedParts,
}: {
    damagedParts: Repair['damagedParts'];
    userPreferences: Pick<
        UserPreferences,
        | 'damageDescriptionFromRepairParts_listFormat'
        | 'damageDescriptionFromRepairParts_repairExtension'
        | 'damageDescriptionFromRepairParts_includeRepairType'
        | 'damageDescriptionFromRepairParts_includeComments'
        | 'damageDescriptionFromRepairParts_blacklistedTokens'
    >;
}): string {
    // Ignore parts that are only assembled, disassembled, ...
    const ignoredRepairTypes: RepairTypeGerman[] = [
        'aus- und einbauen',
        'einstellen',
        'zerlegen',
        'zusammenbauen',
        'zerlegen und zusammenbauen',
        'freilegen',
        'fertigstellen',
        'entlüften',
        'hohlraumversiegeln',
        'auswuchten',
        'kosten',
    ];
    const filteredDamagedParts = damagedParts.filter(
        (damagedPart) => !ignoredRepairTypes.includes(damagedPart.repairType as RepairTypeGerman),
    );
    return generateTextFromCalculation({
        damagedParts: filteredDamagedParts,
        listFormat: userPreferences.damageDescriptionFromRepairParts_listFormat,
        repairExtension: userPreferences.damageDescriptionFromRepairParts_repairExtension,
        includeComments: userPreferences.damageDescriptionFromRepairParts_includeComments,
        includeRepairType: userPreferences.damageDescriptionFromRepairParts_includeRepairType,
        blacklistedTokens: userPreferences.damageDescriptionFromRepairParts_blacklistedTokens,
    });
}

export function generateRepairInstructionsFromCalculation({
    userPreferences,
    damagedParts,
}: {
    damagedParts: Repair['damagedParts'];
    userPreferences: Pick<
        UserPreferences,
        | 'repairInstructionsFromRepairParts_listFormat'
        | 'repairInstructionsFromRepairParts_repairExtension'
        | 'repairInstructionsFromRepairParts_includeComments'
        | 'repairInstructionsFromRepairParts_blacklistedTokens'
    >;
}): string {
    return generateTextFromCalculation({
        damagedParts,
        listFormat: userPreferences.repairInstructionsFromRepairParts_listFormat,
        repairExtension: userPreferences.repairInstructionsFromRepairParts_repairExtension,
        includeComments: userPreferences.repairInstructionsFromRepairParts_includeComments,
        includeRepairType: true,
        blacklistedTokens: userPreferences.repairInstructionsFromRepairParts_blacklistedTokens,
    });
}

function generateTextFromCalculation({
    damagedParts,
    listFormat,
    repairExtension,
    includeComments,
    includeRepairType,
    blacklistedTokens,
}: {
    damagedParts: Repair['damagedParts'];
    listFormat: UserPreferences['damageDescriptionFromRepairParts_listFormat'];
    repairExtension: UserPreferences['damageDescriptionFromRepairParts_repairExtension'];
    includeRepairType: UserPreferences['damageDescriptionFromRepairParts_includeRepairType'];
    includeComments: UserPreferences['damageDescriptionFromRepairParts_includeComments'];
    blacklistedTokens: UserPreferences['damageDescriptionFromRepairParts_blacklistedTokens'];
}): string {
    let filteredDamagedParts = filterDamagedPartDescriptions(damagedParts, blacklistedTokens);

    if (repairExtension === 'newList') {
        let formattedText: string;
        const repairExtensionParts = filteredDamagedParts.filter((damagedPart) => damagedPart.isRepairExtension);

        // Format a list of all parts which are not repair extension parts.
        formattedText = formatPartDescriptionsAsList({
            filteredDamagedParts: filteredDamagedParts.filter((damagedPart) => !damagedPart.isRepairExtension),
            listFormat,
            includeComments,
            includeRepairType,
        });

        // Format and append a list of all repair extension parts.
        if (repairExtensionParts.length) {
            formattedText += '<p>Teile aus Reparaturkostenausweitung:</p>';
            formattedText += formatPartDescriptionsAsList({
                filteredDamagedParts: repairExtensionParts,
                listFormat,
                includeComments,
                includeRepairType,
            });
        }
        return formattedText;
    }

    // Ignore repair extension parts.
    if (repairExtension === 'ignore') {
        filteredDamagedParts = filteredDamagedParts.filter((damagedPart) => !damagedPart.isRepairExtension);
    }

    return formatPartDescriptionsAsList({ filteredDamagedParts, listFormat, includeComments, includeRepairType });
}

/**
 * Takes an array of damaged part objects and formats them as a list in the desired format.
 * This function is extracted and may be reused for formatting the repair extension parts.
 *
 */
function formatPartDescriptionsAsList({
    filteredDamagedParts,
    listFormat,
    includeComments,
    includeRepairType,
}: {
    filteredDamagedParts: Repair['damagedParts'];
    listFormat: UserPreferences['damageDescriptionFromRepairParts_listFormat'];
    includeRepairType: UserPreferences['damageDescriptionFromRepairParts_includeRepairType'];
    includeComments: UserPreferences['damageDescriptionFromRepairParts_includeComments'];
}): string {
    const damagedPartDescriptions: string[] = filteredDamagedParts.map((damagedPart) => {
        const description = [translateDamagedPartDescription(damagedPart.description)];
        if (includeComments && damagedPart.comment) {
            description.push(damagedPart.comment);
        }
        if (includeRepairType && damagedPart.repairType) {
            description.push(damagedPart.repairType);
        }
        return description.join(' ');
    });

    if (listFormat === 'bulletList') {
        // Format as bullet list.
        const htmlWithBullets = damagedPartDescriptions
            .map((photoDescription) => `<li>${photoDescription}</li>`)
            .join('');
        return `<ul>${htmlWithBullets}</ul>`;
    } else if (listFormat === 'comma') {
        return damagedPartDescriptions.join(', ');
    } else if (listFormat === 'newline') {
        return damagedPartDescriptions.join('<br />');
    }
}

<!--============================================-->
<!-- Brands -->
<!--============================================-->
<section id="brands-section" class="section" [ngClass]="{ 'in-edit-mode': isBrandAutocompleteVisible }">
    <div class="heading-and-items-container">
        <div class="heading-container">
            <div
                matTooltip="Diese Marken werden von der Werkstatt unterstützt.{{
                    '\n\n'
                }}Wenn du Marken hinzufügst, wird diese Werkstatt automatisch als Markenwerkstatt gekennzeichnet.{{
                    '\n\n'
                }}Du kannst bei der Werkstattauswahl nach Marken suchen.">
                Marken
            </div>
        </div>

        <!--********** Brand Icons **********-->
        <div class="items-container">
            <!--********** Brand Icons **********-->
            <div
                class="item-container"
                *ngFor="let brand of garageContact.garageBrands"
                [ngClass]="{ 'item-with-icon': iconForCarBrandExists(brand) }"
                @fadeInAndOut>
                <img
                    *ngIf="iconForCarBrandExists(brand)"
                    [src]="iconFilePathForCarBrand(brand, 'large')"
                    matTooltip="Dies ist eine Markenwerkstatt für {{ brand }}" />
                <span
                    class="semibold"
                    *ngIf="!iconForCarBrandExists(brand)"
                    matTooltip="Dies ist eine Markenwerkstatt für {{ brand }}">
                    {{ brand }}
                </span>

                <!--********** Removal Icon **********-->
                <div
                    class="remove-item-icon-container"
                    *ngIf="!disabled"
                    (click)="removeBrand(brand)"
                    matTooltip="Marke löschen">
                    <mat-icon>close</mat-icon>
                </div>
            </div>

            <!--********** New Brand Icon **********-->
            <div
                class="new-item-icon-container"
                *ngIf="!disabled && !isBrandAutocompleteVisible"
                (click)="showBrandAutocomplete()"
                matTooltip="Marke hinzufügen">
                <mat-icon>add</mat-icon>
            </div>
        </div>
    </div>

    <!--============================================-->
    <!-- Autocomplete - Brands -->
    <!--============================================-->
    <ng-container *ngIf="isBrandAutocompleteVisible">
        <div class="autocomplete-container">
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Neue Marke"
                    [(ngModel)]="brandAutocompleteValue"
                    [matAutocomplete]="brandAutocomplete"
                    (keydown)="addBrandOnEnter($event)"
                    (ngModelChange)="filterBrands()"
                    (focus)="filterBrands()"
                    [axAutofocus]="true"
                    [disabled]="disabled" />
            </mat-form-field>
            <mat-autocomplete #brandAutocomplete>
                <mat-option *ngFor="let brand of filteredBrands" [value]="brand" (mousedown)="$event.preventDefault()">
                    <div class="autocomplete-option" [ngClass]="{ 'no-icon': !iconForCarBrandExists(brand) }">
                        <!--********** Option Image **********-->
                        <div class="autocomplete-option-image-container">
                            <img
                                class="autocomplete-option-image"
                                [src]="iconFilePathForCarBrand(brand, 'large')"
                                *ngIf="iconForCarBrandExists(brand)" />
                        </div>

                        <!--********** Option Name **********-->
                        <span class="autocomplete-option-name">{{ brand }}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>

            <div class="autocomplete-toolbar">
                <mat-icon
                    class="toolbar-icon"
                    (click)="addBrand(brandAutocompleteValue); clearBrandAutocomplete()"
                    matTooltip="Marke hinzufügen und Eingabefeld schließen">
                    check
                </mat-icon>
            </div>
        </div>

        <!--********** Leave Edit Mode **********-->
        <div class="leave-edit-mode-button-container">
            <button class="flat" (click)="hideBrandAutocomplete()">Bearbeitung beenden</button>
        </div>
    </ng-container>
    <!--============================================-->
    <!-- END Autocomplete - Brands -->
    <!--============================================-->
</section>
<!--============================================-->
<!-- END Brands -->
<!--============================================-->
<div class="section-divider"></div>
<!--============================================-->
<!-- Garage Characteristics -->
<!--============================================-->
<section id="infos-section" class="section" [ngClass]="{ 'in-edit-mode': isGarageCharacteristicAutocompleteVisible }">
    <div class="heading-and-items-container">
        <div class="heading-container">
            <div
                matTooltip="Was ist besonders an dieser Werkstatt?{{
                    '\n\n'
                }}Du kannst bei der Werkstattauswahl nach Merkmalen suchen.">
                Merkmale
            </div>
        </div>

        <!--********** Brand Icons **********-->
        <div class="items-container">
            <!--********** Brand Icons **********-->
            <div
                class="item-container"
                *ngFor="let garageCharacteristic of garageContact.garageCharacteristics"
                [ngClass]="{ 'item-with-icon': iconForGarageCharacteristicExists(garageCharacteristic) }"
                [ngSwitch]="getIconNameForGarageCharacteristic(garageCharacteristic)"
                @fadeInAndOut>
                <!--********** Characteristics Icons **********-->
                <mat-icon
                    class="characteristics-icon electric-bolt"
                    *ngSwitchCase="'electricVehicles'"
                    [matTooltip]="garageCharacteristic">
                    electric_bolt
                </mat-icon>
                <img
                    class="characteristics-icon"
                    src="/assets/images/logos/dat-logo-no-text.png"
                    *ngSwitchCase="'calculationSystemDat'"
                    [matTooltip]="garageCharacteristic" />
                <img
                    class="characteristics-icon"
                    src="/assets/images/logos/logo-solera-icon-only.svg"
                    *ngSwitchCase="'calculationSystemAudatex'"
                    [matTooltip]="garageCharacteristic" />
                <img
                    class="characteristics-icon"
                    src="/assets/images/icons/motorcycle_64x32.png"
                    *ngSwitchCase="'motorcycles'"
                    [matTooltip]="garageCharacteristic" />
                <img
                    class="characteristics-icon"
                    src="/assets/images/icons/caravan-trailer_64x32.png"
                    *ngSwitchCase="'caravans'"
                    [matTooltip]="garageCharacteristic" />
                <img
                    class="characteristics-icon"
                    src="/assets/images/icons/truck_64x32.png"
                    *ngSwitchCase="'utilityVehicles'"
                    [matTooltip]="garageCharacteristic" />
                <span
                    class="semibold"
                    *ngIf="!iconForGarageCharacteristicExists(garageCharacteristic)"
                    [matTooltip]="garageCharacteristic">
                    {{ garageCharacteristic }}
                </span>

                <!--********** Removal Icon **********-->
                <div
                    class="remove-item-icon-container"
                    *ngIf="!disabled"
                    (click)="removeGarageCharacteristic(garageCharacteristic)"
                    matTooltip="Merkmal löschen">
                    <mat-icon>close</mat-icon>
                </div>
            </div>

            <!--********** New Brand Icon **********-->
            <div
                class="new-item-icon-container"
                *ngIf="!disabled && !isGarageCharacteristicAutocompleteVisible"
                (click)="showGarageCharacteristicAutocomplete()"
                matTooltip="Merkmal hinzufügen">
                <mat-icon>add</mat-icon>
            </div>
        </div>
    </div>

    <!--============================================-->
    <!-- Autocomplete - Characteristics -->
    <!--============================================-->
    <ng-container *ngIf="isGarageCharacteristicAutocompleteVisible">
        <div class="autocomplete-container">
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Neues Merkmal"
                    [(ngModel)]="garageCharacteristicAutocompleteValue"
                    [matAutocomplete]="infoAutocomplete"
                    (keydown)="addGarageCharacteristicOnEnter($event)"
                    (ngModelChange)="filterGarageCharacteristics()"
                    (focus)="filterGarageCharacteristics()"
                    [axAutofocus]="true"
                    [disabled]="disabled" />
            </mat-form-field>
            <mat-autocomplete #infoAutocomplete>
                <mat-option
                    *ngFor="let garageCharacteristic of filteredGarageCharacteristics"
                    [value]="garageCharacteristic"
                    (mousedown)="$event.preventDefault()">
                    <div
                        class="autocomplete-option"
                        [ngClass]="{ 'no-icon': !iconForGarageCharacteristicExists(garageCharacteristic) }"
                        [ngSwitch]="getIconNameForGarageCharacteristic(garageCharacteristic)">
                        <div class="autocomplete-option-image-container">
                            <!--********** Characteristics Icons **********-->
                            <mat-icon
                                class="autocomplete-option-image electric-bolt"
                                *ngSwitchCase="'electricVehicles'">
                                electric_bolt
                            </mat-icon>
                            <img
                                class="autocomplete-option-image"
                                src="/assets/images/logos/dat-logo-no-text.png"
                                *ngSwitchCase="'calculationSystemDat'" />
                            <img
                                class="autocomplete-option-image"
                                src="/assets/images/logos/logo-solera-icon-only.svg"
                                *ngSwitchCase="'calculationSystemAudatex'" />
                            <img
                                class="autocomplete-option-image"
                                src="/assets/images/icons/motorcycle_64x32.png"
                                *ngSwitchCase="'motorcycles'" />
                            <img
                                class="autocomplete-option-image"
                                src="/assets/images/icons/caravan-trailer_64x32.png"
                                *ngSwitchCase="'caravans'" />
                            <img
                                class="autocomplete-option-image"
                                src="/assets/images/icons/truck_64x32.png"
                                *ngSwitchCase="'utilityVehicles'" />
                        </div>

                        <span class="autocomplete-option-name">{{ garageCharacteristic }}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>

            <div class="autocomplete-toolbar">
                <mat-icon
                    class="toolbar-icon"
                    (click)="
                        addGarageCharacteristic(garageCharacteristicAutocompleteValue);
                        clearGarageCharacteristicAutocomplete()
                    "
                    matTooltip="Merkmal hinzufügen und Eingabefeld schließen">
                    check
                </mat-icon>
            </div>
        </div>

        <!--********** Leave Edit Mode **********-->
        <div class="leave-edit-mode-button-container">
            <button class="flat" (click)="hideGarageCharacteristicAutocomplete()">Bearbeitung beenden</button>
        </div>
    </ng-container>
    <!--============================================-->
    <!-- END Autocomplete - Characteristics -->
    <!--============================================-->
</section>
<!--============================================-->
<!-- END Garage Characteristics -->
<!--============================================-->

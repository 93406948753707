/**
 * Return a list of placeholders whose values are missing.
 *
 * Process:
 * - Match all occurrences of "WERT_XXX_FEHLT".
 * - Extract and return XXX.
 */
export function extractMissingPlaceholdersFromText(text: string): string[] {
    const matches = text.matchAll(/WERT_(.+?)_FEHLT/g); // ? = Lazy matching, otherwise we get one huge match instead of many exact matches.
    const missingPlaceholders: string[] = [];

    for (const [fullMatch, placeholderName] of matches) {
        missingPlaceholders.push(placeholderName);
    }

    return missingPlaceholders;
}

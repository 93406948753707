import { Pipe, PipeTransform } from '@angular/core';
import { convertToEuro } from '@autoixpert/lib/placeholder-values/convert-to-euro';

/**
 * Convert the given amount to a formatted euro string with the given number of decimal
 * places and the optional setting to omit these, if they are all zero.
 * E.g. "123456" -> "123.456,00 €"
 */
@Pipe({
    name: 'convertToEuro',
})
export class ConvertToEuroPipe implements PipeTransform {
    transform(value: number, decimalPlaces: number = 2, omitDecimalsIfZero = false): string {
        return convertToEuro(value, { decimalPlaces, omitDecimalsIfZero });
    }
}

import { generateId } from '../../../lib/generate-id';

export class ResidualValueBid {
    constructor(options?: BidOptions) {
        Object.assign(this, options);
    }

    bidID: string = `AX-${generateId()}`;
    bidRank: number = null;
    bidValue: BidValue = {
        currency: 'EUR',
        value: null,
    };
    notInterested?: boolean;
    bidder: Bidder = {
        contactPersonId: null,
        address: {
            city: null,
            companyName: null,
            contact: null,
            country: null,
            emailAddress: null,
            fax: null,
            postcode: null,
            street: null,
            telephone: null,
            telephone2: null,
        },
    };
    bindingDate: string = null;
    dateOfBid: string = null;
    regional: boolean = null;
    linkToSellCar?: string; // Only used by WinValue
    origin?: 'autoonline' | 'winvalue' | 'cartv' | 'carcasion' | 'axResidualValueRequest' | 'custom' = 'custom';
    selected: boolean = false; // Selected for printing
}

export interface BidValue {
    currency: string;
    value: number;
}

export class Bidder {
    contactPersonId?: string;
    address: {
        city: string;
        companyName: string;
        contact: string;
        country: string;
        emailAddress: string;
        fax: string;
        postcode: string;
        street: string;
        telephone: string;
        telephone2: string;
    };
}

interface BidOptions {
    bidID?: string;
    bidRank?: number;
    bidValue?: BidValue;
    notInterested?: boolean;
    bidder?: Bidder;
    bindingDate?: string;
    dateOfBid?: string;
    regional?: boolean;
    detailsShown?: boolean;
    origin?: 'winvalue' | 'cartv' | 'carcasion' | 'custom';
}

export type ResidualValueExchangeName = 'autoonline' | 'winvalue' | 'cartv' | 'carcasion' | 'own';

<div id="documents-grid">
    <!--============================================-->
    <!-- Search Bar -->
    <!--============================================-->
    <div id="search-bar" class="card">
        <mat-form-field id="reports-search-field" class="search-field mat-block" floatLabel="never">
            <input
                matInput
                [(ngModel)]="signablePdfTemplateConfigSearchTerm"
                (input)="filterAndSortDocuments()"
                placeholder="Suchen" />
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
    <!--============================================-->
    <!-- END Search Bar -->
    <!--============================================-->

    <!--============================================-->
    <!-- Signable PDF Configs -->
    <!--============================================-->
    <div
        class="signable-pdf-item card dark-card"
        *ngFor="let signablePdfTemplateConfig of filteredSignablePdfTemplateConfigs; trackBy: trackById"
        [ngClass]="{ archived: signablePdfTemplateConfig.archivedAt }"
        @shrinkOut
        [@.disabled]="disableAnimations">
        <!--********** Thumbnail Column **********-->
        <div class="signable-pdf-thumbnail-column">
            <div class="signable-pdf-thumbnail-container">
                <!--********** No PDF uploaded **********-->
                <ng-container *ngIf="!signablePdfTemplateConfig.pdfUploaded">
                    <!--********** Dropzone **********-->
                    <div class="signable-document-thumbnail">
                        <img src="/assets/images/paper-container-blue-auroras.svg" alt="" />
                        <div
                            dragDropFileUpload
                            [uploadApiPath]="'/signablePdfTemplates'"
                            [uploadId]="signablePdfTemplateConfig._id"
                            [allowedFileExtensions]="['.pdf']"
                            [ngClass]="{ 'is-upload-pending': pendingCopyUploads.has(signablePdfTemplateConfig._id) }"
                            (onUploadSuccessful)="onSuccessfulDocumentUpload($event, signablePdfTemplateConfig)"
                            class="signable-pdf-upload-container">
                            <img class="drag-drop-loading" src="/assets/images/icons/loading-indicator-white.svg" />
                            <mat-icon class="drag-drop-initial">file_upload</mat-icon>
                            <span class="drag-drop-initial">PDF hochladen</span>
                        </div>
                    </div>
                </ng-container>

                <!--********** PDF uploaded **********-->
                <div *ngIf="signablePdfTemplateConfig.pdfUploaded">
                    <!--********** No Thumbnail Present **********-->
                    <img
                        class="signable-document-thumbnail-placeholder"
                        src="/assets/images/paper-container-blue-auroras.svg"
                        alt="" />
                    <!--********** Thumbnail Present **********-->
                    <img
                        *ngIf="signablePdfTemplateConfig.pages.length"
                        (click)="openSignablePdfTemplateConfigInEditor(signablePdfTemplateConfig)"
                        class="signable-document-thumbnail-image"
                        onload="this.classList.add('loaded')"
                        [src]="getThumbnailImageBlobUrl(signablePdfTemplateConfig)" />
                </div>
            </div>

            <!--********** Document Type **********-->
            <div
                class="document-type-indicator"
                *ngIf="signablePdfTemplateConfig.documentType"
                [ngSwitch]="signablePdfTemplateConfig.documentType"
                [matTooltip]="
                    getCustomDocumentOrderConfig(signablePdfTemplateConfig)?.titleShort ||
                    translateDocumentType(signablePdfTemplateConfig.documentType)
                ">
                <mat-icon *ngSwitchCase="'declarationOfAssignment'">how_to_vote</mat-icon>
                <mat-icon *ngSwitchCase="'revocationInstruction'">undo</mat-icon>
                <mat-icon *ngSwitchCase="'consentDataProtection'" class="">fingerprint</mat-icon>
                <img *ngSwitchCase="'powerOfAttorney'" src="/assets/images/icons/lawyer-white.svg" />
                <ng-container *ngSwitchCase="'customDocument'">
                    <mat-icon class="option-image customer-signature-document-icon">
                        {{
                            getCustomDocumentOrderConfig(signablePdfTemplateConfig)?.customDocumentConfig
                                ?.googleMaterialIconName || 'description'
                        }}
                    </mat-icon>
                </ng-container>
            </div>
        </div>

        <!--********** Info Column **********-->
        <div class="signable-pdf-info-column">
            <h2
                class="signable-pdf-title cursor-pointer"
                (click)="openSignablePdfTemplateConfigTitleEditDialog(signablePdfTemplateConfig)"
                matTooltip="{{ signablePdfTemplateConfig.title ?? '' }}">
                {{
                    signablePdfTemplateConfig.title ??
                        getCustomDocumentOrderConfig(signablePdfTemplateConfig)?.titleShort ??
                        translateDocumentType(signablePdfTemplateConfig.documentType)
                }}
            </h2>
            <!--============================================-->
            <!-- Lawyer Address Display / Autocomplete -->
            <!--============================================-->
            <ng-container *ngIf="isContactForLawyerVisible(signablePdfTemplateConfig)">
                <!--********** Lawyer assigned **********-->
                <div class="signable-pdf-assigned-lawyer" *ngIf="getAssignedLawyer(signablePdfTemplateConfig)">
                    <div class="lawyer-label-and-title">
                        <span class="signable-pdf-assigned-lawyer-label">Kanzlei</span>
                        <div
                            class="lawyer-title text-overflow-ellipsis"
                            matTooltip="Wenn diese Kanzlei ({{
                                getAssignedLawyer(signablePdfTemplateConfig)
                            }}) im Gutachten ausgewählt wird, wird diese Anwaltsvollmacht zur Unterschrift angeboten.">
                            {{ getAssignedLawyer(signablePdfTemplateConfig) }}
                        </div>
                    </div>
                    <!--********** Unlink Lawyer **********-->
                    <mat-icon
                        class="assigned-lawyer-delete toolbar-icon"
                        (click)="removeConditionForLawyer(signablePdfTemplateConfig)"
                        matTooltip="Kanzleiauswahl löschen">
                        delete
                    </mat-icon>
                </div>

                <!--============================================-->
                <!-- Lawyer Unassigned - Autocomplete -->
                <!--============================================-->
                <mat-form-field
                    *ngIf="!getAssignedLawyer(signablePdfTemplateConfig)"
                    class="signable-pdf-assigned-lawyer-input search-field light-mode">
                    <mat-icon matPrefix>search</mat-icon>
                    <input
                        matInput
                        [ngModel]="lawyerAutocompleteSearchTerms.get(signablePdfTemplateConfig._id)"
                        (ngModelChange)="
                            lawyerAutocompleteSearchTerms.set(signablePdfTemplateConfig._id, lawyerSearchInput.value);
                            filterLawyerAutocompleteValues(signablePdfTemplateConfig)
                        "
                        (focus)="filterLawyerAutocompleteValues(signablePdfTemplateConfig)"
                        [matAutocomplete]="lawyerAutocomplete"
                        #lawyerSearchInput
                        placeholder="Kanzlei"
                        matTooltip="Wenn diese Kanzlei in einem Gutachten ausgewählt wird, wird die hochgeladene PDF-Datei dem Kunden zum Unterschreiben angeboten." />

                    <!--********** Autocomplete **********-->
                    <mat-autocomplete #lawyerAutocomplete>
                        <mat-option
                            *ngFor="
                                let lawyerEntry of lawyerAutocompleteSearchResults.get(signablePdfTemplateConfig._id)
                            "
                            class="option-with-label"
                            matTooltip="{{
                                lawyerEntry.organization ?? lawyerEntry.firstName + ' ' + lawyerEntry.lastName
                            }}"
                            (mousedown)="$event.preventDefault()"
                            (onSelectionChange)="
                                createConditionForLawyer(signablePdfTemplateConfig, lawyerEntry, $event)
                            ">
                            <!--********** Organization / Name **********-->
                            {{ lawyerEntry.organization ?? lawyerEntry.firstName + ' ' + lawyerEntry.lastName }}

                            <!--********** City **********-->
                            <div class="label">{{ lawyerEntry.city }}</div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <!--============================================-->
                <!-- END Lawyer Unassigned - Autocomplete -->
                <!--============================================-->
            </ng-container>
            <!--============================================-->
            <!-- END Lawyer Address Display / Autocomplete -->
            <!--============================================-->

            <!--============================================-->
            <!-- Edit Conditions Button -->
            <!--============================================-->

            <div class="signable-pdf-button-grid">
                <!--********** A button would be nicer but the specificity of button styling in the styles.scss is very high due to many :not classes **********-->
                <div
                    *ngIf="!isContactForLawyerVisible(signablePdfTemplateConfig)"
                    role="button"
                    class="signable-pdf-info-button signable-pdf-edit"
                    (click)="openSignablePdfTemplateConfigConditions(signablePdfTemplateConfig)"
                    matTooltip="Wann soll dieses Dokument zur Unterschrift angeboten werden? Beispiele: Bestimmte Gutachtentypen oder bestimmte Beteiligte.">
                    <mat-icon>tune</mat-icon>
                    <span *ngIf="signablePdfTemplateConfig.conditions?.length > 0">
                        {{ signablePdfTemplateConfig.conditions.length }}
                        {{ signablePdfTemplateConfig.conditions.length === 1 ? 'Bedingung' : 'Bedingungen' }}
                        bearbeiten
                    </span>
                    <span *ngIf="signablePdfTemplateConfig.conditions?.length == 0">Bedingungen setzen</span>
                </div>

                <!--============================================-->
                <!-- END Edit Conditions Button -->
                <!--============================================-->

                <!--============================================-->
                <!-- Edit Placeholder Button -->
                <!--============================================-->

                <!--********** TODO better use button -> but specifity with :not classes **********-->
                <div
                    role="button"
                    class="signable-pdf-info-button signable-pdf-edit"
                    *ngIf="signablePdfTemplateConfig.pdfUploaded && signablePdfTemplateConfig.pages.length > 0"
                    (click)="openSignablePdfTemplateConfigInEditor(signablePdfTemplateConfig)">
                    <span class="signable-pdf-edit-icon">&#123; &#125;</span>
                    <span>Platzhalter bearbeiten</span>
                </div>
            </div>

            <!--============================================-->
            <!-- END Edit Placeholder Button -->
            <!--============================================-->
        </div>

        <!--********** Toolbar Icon **********-->
        <div class="signable-pdf-toolbar">
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="signableDocumentItemSubmenu">more_vert</mat-icon>
            <mat-menu #signableDocumentItemSubmenu>
                <!--********** We provide both the condition interface and a simpler contact autocomplete for the power of attorney document. **********-->
                <ng-container *ngIf="signablePdfTemplateConfig.documentType === 'powerOfAttorney'">
                    <a
                        mat-menu-item
                        *ngIf="signablePdfTemplateConfig.useContactForPowerOfAttorney"
                        (click)="setUseContactForPowerOfAttorney(signablePdfTemplateConfig, false)"
                        class="menu-item-with-icon"
                        matTooltip="Flexible Bedingungen statt des Suchfelds verwenden. Geeignet, falls mehr als nur der Kanzleiname oder Vor-/Nachname steuern soll, welches Dokument verwendet wird.">
                        <mat-icon>tune</mat-icon>
                        Bedingungen verwenden
                    </a>
                    <a
                        mat-menu-item
                        *ngIf="!signablePdfTemplateConfig.useContactForPowerOfAttorney"
                        (click)="setUseContactForPowerOfAttorney(signablePdfTemplateConfig, true)"
                        class="menu-item-with-icon"
                        matTooltip="Statt der Bedingungen ein einfacheres Suchfeld für Kontakte verwenden. Am besten nutzbar, wenn eine Vollmacht eindeutig über den Kanzleinamen eindeutig identifizierbar ist.">
                        <mat-icon>person</mat-icon>
                        Kontakt-Autocomplete verwenden
                    </a>
                </ng-container>

                <!--********** Rename **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="openSignablePdfTemplateConfigTitleEditDialog(signablePdfTemplateConfig)"
                    matTooltip="Die PDF-Vorlage herunterladen">
                    <mat-icon>edit</mat-icon>
                    Umbenennen
                </a>

                <!--********** Download **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="downloadSignablePdfTemplate(signablePdfTemplateConfig)"
                    matTooltip="Die PDF-Vorlage herunterladen">
                    <mat-icon>download</mat-icon>
                    Herunterladen
                </a>

                <!--********** Copy Config **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="copySignablePdfTemplateConfig(signablePdfTemplateConfig)"
                    matTooltip="Die PDF-Vorlage kopieren. Bestehende Unterschriftsdokumente greifen dann weiterhin auf diese Vorlage zu, während neue Unterschriftsdokumente auf die Kopie zugreifen können. Damit vermeidest du nachträgliche Änderungen an unterschriebenen Dokumenten.">
                    <mat-icon>content_copy</mat-icon>
                    Kopieren
                </a>

                <!--********** Editing Custom Document Order Config **********-->
                <a
                    mat-menu-item
                    *ngIf="signablePdfTemplateConfig.customDocumentOrderConfigId"
                    class="menu-item-with-icon"
                    matTooltip="Optionen zur Dokumentvorlage, auf der dieses PDF-Dokument basiert."
                    [matMenuTriggerFor]="customDocumentOrderConfigSubmenu">
                    <mat-icon>article_shortcut</mat-icon>
                    Dokumentvorlage
                </a>
                <mat-menu #customDocumentOrderConfigSubmenu="matMenu">
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        matTooltip="Bearbeiten Sie die Dokumentvorlage, die dieser PDF zugrunde liegt."
                        (click)="
                            openDocumentOrderConfigCreationDialogForId(
                                signablePdfTemplateConfig.customDocumentOrderConfigId
                            )
                        ">
                        <mat-icon>edit</mat-icon>
                        Bearbeiten
                    </a>
                    <a
                        mat-menu-item
                        matTooltip="Löschen Sie die Dokumentvorlage, die dieser PDF zugrunde liegt."
                        class="menu-item-with-icon alert"
                        (click)="deleteDocumentOrderConfigById(signablePdfTemplateConfig.customDocumentOrderConfigId)">
                        <mat-icon>delete</mat-icon>
                        Löschen
                    </a>
                </mat-menu>

                <!--********** Remove PDF **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    *ngIf="signablePdfTemplateConfig.pdfUploaded"
                    (click)="removePdfFile(signablePdfTemplateConfig)"
                    matTooltip="Die PDF-Vorlage entfernen, um sie durch eine andere zu ersetzen">
                    <mat-icon>block</mat-icon>
                    PDF entfernen
                </a>

                <!--********** Archive **********-->
                <a
                    mat-menu-item
                    *ngIf="!signablePdfTemplateConfig.archivedAt"
                    class="menu-item-with-icon"
                    (click)="deactivateSignablePdfTemplateConfig(signablePdfTemplateConfig)"
                    matTooltip="Die PDF-Vorlage deaktivieren.{{
                        '\n\n'
                    }}In allen Gutachten, in denen sie verwendet wurde, bleibt sie erhalten. Falls sie nicht verwendet wurde, wird sie gelöscht.">
                    <mat-icon>inventory_2</mat-icon>
                    Archivieren
                </a>

                <!--********** Unarchive **********-->
                <a
                    mat-menu-item
                    *ngIf="signablePdfTemplateConfig.archivedAt"
                    class="menu-item-with-icon"
                    (click)="reactivateSignablePdfTemplateConfig(signablePdfTemplateConfig)"
                    matTooltip="Die PDF-Vorlage reaktivieren, damit sie wieder bei neuen Gutachten verwendet werden kann.">
                    <mat-icon>undo</mat-icon>
                    Reaktivieren
                </a>

                <!--********** Delete **********-->
                <a
                    mat-menu-item
                    *ngIf="signablePdfTemplateConfig.archivedAt"
                    class="menu-item-with-icon"
                    (click)="deleteSignablePdfTemplateConfig(signablePdfTemplateConfig)"
                    matTooltip="Die Vorlage wird endgültig gelöscht. Dokumente, die auf dieser Vorlage basieren, können nach dem Löschen nicht mehr heruntergeladen werden.">
                    <mat-icon>delete</mat-icon>
                    Löschen
                </a>
            </mat-menu>
        </div>

        <!--********** Archived Marker **********-->
        <div
            class="archived-marker cursor-pointer"
            *ngIf="signablePdfTemplateConfig.archivedAt"
            (click)="reactivateSignablePdfTemplateConfig(signablePdfTemplateConfig)"
            matTooltip="Archiviert. Wird nicht mehr für neue Gutachten verwendet. Wird noch vorgehalten, damit du alte Gutachten noch generieren kannst. Klicke, um die Vorlage zu reaktivieren.">
            <mat-icon class="small-icon">inventory_2</mat-icon>
        </div>
    </div>
    <!--============================================-->
    <!-- END Signable PDF Configs -->
    <!--============================================-->

    <!--============================================-->
    <!-- New Signable PDF Template Config -->
    <!--============================================-->

    <div
        id="add-signable-pdf-button"
        class="add-signable-pdf-card"
        *ngIf="!showSignableDocumentTypesForNewDocument"
        matTooltip="Neue PDF-Vorlage hochladen und konfigurieren"
        (click)="openShowSignableDocumentTypesForNewConfig()">
        <div id="add-signable-pdf-icon-container">
            <mat-icon id="add-signable-pdf-icon">add</mat-icon>
        </div>
        <div id="add-signable-pdf-text">Neues Dokument</div>
    </div>

    <div
        id="add-signable-pdf-select-type"
        class="add-signable-pdf-card"
        *ngIf="showSignableDocumentTypesForNewDocument">
        <div class="document-type-options-grid">
            <div
                class="document-type-option"
                (click)="createSignablePdfTemplateConfig('declarationOfAssignment')"
                [matTooltip]="translateDocumentType('declarationOfAssignment')">
                <mat-icon>how_to_vote</mat-icon>
                {{ translateDocumentType('declarationOfAssignment', true) }}
            </div>
            <div
                class="document-type-option"
                (click)="createSignablePdfTemplateConfig('revocationInstruction')"
                [matTooltip]="translateDocumentType('revocationInstruction')">
                <mat-icon>undo</mat-icon>
                {{ translateDocumentType('revocationInstruction', true) }}
            </div>
            <div
                class="document-type-option"
                (click)="createSignablePdfTemplateConfig('consentDataProtection')"
                [matTooltip]="translateDocumentType('consentDataProtection')">
                <mat-icon>fingerprint</mat-icon>
                {{ translateDocumentType('consentDataProtection', true) }}
            </div>
            <div
                class="document-type-option"
                (click)="createSignablePdfTemplateConfig('powerOfAttorney')"
                [matTooltip]="translateDocumentType('powerOfAttorney')">
                <img src="/assets/images/icons/lawyer-blue.svg" />
                {{ translateDocumentType('powerOfAttorney', true) }}
            </div>

            <!-- Custom signable documents -->
            <ng-container *ngFor="let customDocumentOrderConfig of customDocumentOrderConfigs; trackBy: trackById">
                <div
                    class="document-type-option"
                    (click)="createSignablePdfTemplateConfig('customDocument', customDocumentOrderConfig._id)"
                    [matTooltip]="customDocumentOrderConfig.titleShort">
                    <mat-icon>{{ customDocumentOrderConfig.customDocumentConfig.googleMaterialIconName }}</mat-icon>
                    <span class="document-type-option-label">{{ customDocumentOrderConfig.titleShort }}</span>
                    <mat-icon
                        *ngIf="user.accessRights.editTextsAndDocumentBuildingBlocks"
                        (click)="$event.stopPropagation()"
                        class="medium-icon toolbar-icon"
                        [matMenuTriggerFor]="customDocumentOrderConfigMenu">
                        more_vert
                    </mat-icon>
                    <mat-menu #customDocumentOrderConfigMenu="matMenu">
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="openDocumentOrderConfigCreationDialog(customDocumentOrderConfig)">
                            <mat-icon>edit</mat-icon>
                            Bearbeiten
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon alert"
                            (click)="deleteDocumentOrderConfig(customDocumentOrderConfig)">
                            <mat-icon>delete</mat-icon>
                            Löschen
                        </a>
                    </mat-menu>
                </div>
            </ng-container>

            <div
                *ngIf="user.accessRights.editTextsAndDocumentBuildingBlocks"
                id="new-document-type-option"
                class="document-type-option"
                (click)="openDocumentOrderConfigCreationDialog()">
                <mat-icon>add</mat-icon>
                Neuer Typ
                <svg width="150" height="75" id="dotted-border">
                    <rect
                        width="148"
                        x="1"
                        y="1"
                        height="73"
                        fill="none"
                        rx="5"
                        ry="5"
                        stroke-width="1"
                        stroke-dasharray="4,4"
                        stroke-dashoffset="0"
                        stroke-linecap="square" />
                </svg>
            </div>
        </div>
        <mat-icon
            id="document-type-close-icon"
            class="dialog-close-icon"
            matTooltip="Auswahl schließen"
            (click)="closeShowSignableDocumentTypesForNewConfig()">
            close
        </mat-icon>
    </div>

    <!--============================================-->
    <!-- END New Signable PDF Template Config -->
    <!--============================================-->
</div>

<!--============================================-->
<!-- About this Screen Column -->
<!--============================================-->
<div id="about-this-screen-column" class="card card-with-padding with-primary-gradient">
    <h2>PDFs unterschreiben</h2>
    <p>
        Lade hier PDFs hoch, die deine Kunden
        <strong>unterschreiben</strong>
        können.
    </p>
    <div class="rounded-spacer"></div>
    <p>
        Du kannst deine PDFs automatisch ausfüllen lassen, indem du
        <strong>Platzhalter</strong>
        darauf definierst.
    </p>
    <div class="rounded-spacer"></div>
    <p>
        Mithilfe der
        <strong>Bedingungen</strong>
        bestimmst du, wann welches Dokument verwendet wird.
    </p>
    <div class="rounded-spacer"></div>

    <div class="video-card card" (click)="openNewWindow('https://youtu.be/a_jncFLL4kc')">
        <div class="video-thumbnail-container">
            <img
                class="video-thumbnail"
                src="/assets/images/video-thumbnails/tour-video-thumbnail.png"
                alt="Tour Video" />
            <img class="video-play-icon" src="/assets/images/icons/play.svg" alt="Play" />
        </div>
        <div class="video-title">PDFs vorbereiten</div>
        <div class="video-duration">3:05 min</div>
    </div>

    <mat-icon class="background-icon outlined">info</mat-icon>
</div>
<!--============================================-->
<!-- END About this Screen Column -->
<!--============================================-->

<!--============================================-->
<!-- Condition Editor -->
<!--============================================-->

<div
    class="dialog-overlay"
    *ngIf="signablePdfTemplateConfigForConditions"
    (mousedown)="handleConditionOverlayClick($event)"
    @dialogEnterAndLeave>
    <div class="dialog-container" id="signable-pdf-condition-editor">
        <h2 class="margin-bottom-10">Auswahlbedingungen</h2>
        <div class="text-align-center margin-bottom-30">
            Wann soll diese Vorlage verwendet werden?
            <!--********** Example: Report Type **********-->
            <div class="label">z. B. für bestimmte Gutachtentypen.</div>
        </div>

        <!--********** Close Icon **********-->
        <mat-icon
            class="dialog-close-icon"
            (click)="closeAndDiscardSignablePdfTemplateConfigConditions()"
            matTooltip="Schließen">
            close
        </mat-icon>

        <!--********** Condition Editor **********-->
        <document-building-block-condition-group-editor
            [availableDocumentPlaceholders]="availableDocumentPlaceholders"
            [placeholderValueTree]="placeholderValueTree"
            [fieldGroupConfigs]="fieldGroupConfigs"
            [conditionGroup]="signablePdfTemplateConfigForConditions"></document-building-block-condition-group-editor>

        <!-- Save & Cancel buttons -->
        <div class="buttons-container">
            <button
                (click)="isSavingAllowed() && closeAndSaveSignablePdfTemplateConfigConditions()"
                [class.disabled]="!isSavingAllowed()"
                [matTooltip]="getSavingButtonTooltip()">
                Speichern
            </button>
            <button class="cancel-button flat light" (click)="closeAndDiscardSignablePdfTemplateConfigConditions()">
                Abbrechen
            </button>
        </div>
    </div>
</div>

<!--============================================-->
<!-- END Condition Editor -->
<!--============================================-->

<!--============================================-->
<!-- Placeholder Editor -->
<!--============================================-->
<div
    class="dialog-overlay"
    (mousedown)="handleOverlayClickOnPdfTemplateConfigEditor($event)"
    *ngIf="signablePdfTemplateConfigForEditor"
    @dialogEnterAndLeave>
    <div id="signable-pdf-template-config-editor-dialog-container" class="dialog-container">
        <signable-pdf-template-editor
            [signablePdfTemplateConfig]="signablePdfTemplateConfigForEditor"
            (close)="closeSignablePdfTemplateConfigInEditor()"></signable-pdf-template-editor>
    </div>
</div>
<!--============================================-->
<!-- END Placeholder Editor -->
<!--============================================-->

<!--============================================-->
<!-- Title Editor -->
<!--============================================-->

<div *ngIf="signablePdfTemplateConfigTitleForEditDialog" class="dialog-overlay" @dialogEnterAndLeave>
    <div class="dialog-container dialog-edit-signable-pdf-template-config-title">
        <mat-icon class="dialog-close-icon" (click)="closeSignablePdfTemplateConfigTitleEditDialog()">close</mat-icon>
        <h2>Umbenennen</h2>
        <!--********** Title **********-->
        <mat-form-field class="mat-block">
            <input
                matInput
                placeholder="Titel"
                [axAutofocus]="true"
                [(ngModel)]="signablePdfTemplateConfigTitleForEditDialog.title"
                (change)="saveSignablePdfTemplateConfigTitleEditDialog()"
                matTooltip="Der Titel kann dem Kunden beim Unterschreiben angezeigt werden." />
        </mat-form-field>

        <div class="dialog-buttons-container">
            <button (click)="closeSignablePdfTemplateConfigTitleEditDialog()">Bearbeitung beenden</button>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Title Editor -->
<!--============================================-->

<!-- Document order config creation dialog-->
<document-order-config-creation-dialog
    *ngIf="documentOrderConfigCreationDialogShown"
    [location]="'signable-pdf-templates'"
    [documentOrderConfigToEdit]="documentOrderConfigCreationDialogConfigToEdit"
    (documentOrderConfigCreated)="handleDocumentOrderConfigCreated($event)"
    (close)="closeDocumentOrderConfigCreationDialog()"
    @runChildAnimations></document-order-config-creation-dialog>

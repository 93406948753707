import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import IBAN from 'iban';
import moment, { Moment } from 'moment';
import { iconForCarBrandExists, iconNameForCarBrand } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { ContactPerson, OrganizationType } from '@autoixpert/models/contacts/contact-person';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { slideInAndOutHorizontally } from '../../animations/slide-in-and-out-horizontally.animation';
import { IbanChangeEvent } from '../../directives/iban-input.directive';
import { EMAIL_LIST_WITH_SEMICOLON_VALIDATION_REGEX } from '../../libraries/contacts/email-validation-regex';
import { getSalutations } from '../../libraries/contacts/get-salutations';
import { defaultSalutations } from '../../libraries/default-salutations';
import { getFileNameForInsuranceLogo, insuranceLogoExists } from '../../libraries/insurances/insurance-logo-exists';
import { replaceObjectProperties } from '../../libraries/objects/replace-object-properties';
import { AdeltaLiquidityService } from '../../services/adelta-liquidity.service';
import { ApiErrorService } from '../../services/api-error.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ToastService } from '../../services/toast.service';
import { MatQuillComponent } from '../mat-quill/mat-quill.component';

@Component({
    selector: 'contact-person-form',
    templateUrl: 'contact-person-form.component.html',
    styleUrls: ['contact-person-form.component.scss'],
    animations: [slideInAndOutHorizontally(), fadeInAndSlideAnimation()],
})
export class ContactPersonFormComponent implements OnInit {
    constructor(
        private adeltaLiquidityService: AdeltaLiquidityService,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input('contact-person') contactPerson: ContactPerson;
    @Input('organization-placeholder') organizationPlaceholder: string = 'Firma';
    @Input('show-organization') showOrganization: boolean = true;
    @Input('show-person') showPerson: boolean = true;
    @Input('show-address') showAddress: boolean = true;
    @Input('show-birthdate') showBirthdate: boolean = false;
    @Input('show-liquidity-check') showLiquidityCheck: boolean = false;
    @Input('show-email') showEmail: boolean = true;
    @Input('show-phone-number') showPhoneNumber: boolean = true;
    @Input('second-phone-number-shown') secondPhoneNumberShown: boolean = false;
    @Input('show-website') showWebsite: boolean = false;
    @Input('show-notes') showNotes: boolean = false;
    @Input('show-dat-customer-number') showDatCustomerNumber: boolean = false;
    @Input('show-suggestions-if-empty') showSuggestionsIfEmpty: boolean = false;
    // Relevant for adding a claimant IBAN.
    @Input('show-iban') showIban: boolean = false;
    @Input('show-collective-invoice') showCollectiveInvoice: boolean = false;
    @Input('show-vat-fields') showVatFields: boolean = false;
    @Input('show-debtor-number') showDebtorNumber: boolean = false;
    @Input('show-insurance-company-number') showInsuranceCompanyNumber: boolean = false; // Only insurances
    @Input('center-vat-deduction-checkbox') centerVatDeductionCheckbox: boolean = false;
    @Input('show-contact-reset') showContactReset: boolean = false;
    // Display in which property a search match has been found. Currently used for garage brands and special garage characteristics.
    @Input('show-search-matches') showSearchMatches: boolean = false;
    // Extend the aX-internal search with results from Google Maps or Open Street Maps
    @Input() useMapsAutocompleteForPublicPlaces: boolean = true;
    @Input() disabled: boolean = false;
    /**
     * Show contact people with one of these organization types in the autocomplete.
     */
    @Input() organizationTypes: OrganizationType[] = [];
    // May be used for adding contact people from open reports to the autocompletes.
    @Input() additionalContactPeopleForAutocomplete: ContactPerson[] = [];

    // Which fields should be checked in search string?
    @Input() filterByFields: FilterFields[] = null;

    @Output() nameChange: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();
    @Output() contactPersonChange: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();
    @Output() contactPersonSelection: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();
    @Output() liquidityCheckError: EventEmitter<string> = new EventEmitter<string>();
    @Output() contactReset: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();

    @ViewChild('notesInput', { static: false }) notesInput: MatQuillComponent;
    // This viewChild is necessary to autofocus the input when the checkbox is clicked
    @ViewChild('vatIdInput', { static: false }) public vatIdInput: ElementRef;

    salutationFormControl: UntypedFormControl;

    /**
     * Regex for validating the email address of the contact person.
     */
    protected EMAIL_VALIDATION_REGEX = EMAIL_LIST_WITH_SEMICOLON_VALIDATION_REGEX;

    salutations: string[] = defaultSalutations;
    filteredSalutations: string[];

    birthdayYear: Moment;

    adeltafinanzAddressIncorrect: boolean = false;
    adeltafinanzRequestPending: boolean = false;
    user: User;

    // IBAN validity check
    public isIbanInvalid: boolean;

    ngOnInit() {
        this.salutationFormControl = new UntypedFormControl();
        this.filteredSalutations = this.salutations.slice();
        this.user = this.loggedInUserService.getUser();
        this.setUpBirthdayDatepicker();
    }

    /**
     * Dispatch an event only if any component of the name change. See template to see when that is.
     */
    public dispatchNameChangeEvent(): void {
        this.nameChange.emit(this.contactPerson);
    }

    /**
     * Dispatch an event if any part of the contactPerson changes.
     */
    public dispatchContactPersonChangeEvent(): void {
        this.contactPersonChange.emit(this.contactPerson);
    }

    public dispatchAutocompleteSelectionEvent(): void {
        this.contactPersonSelection.emit(this.contactPerson);
    }

    /**
     * Called when the user clicks on an address autocomplete option.
     */
    public insertAddressAutocompletionInReport(addressResult: Partial<ContactPerson>) {
        Object.keys(addressResult).forEach((key) => {
            this.contactPerson[key] = addressResult[key];
        });

        this.dispatchNameChangeEvent();
        this.dispatchContactPersonChangeEvent();
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Called when the user clicks on an autocomplete option.
     */
    public insertContactPersonInReport(contactPerson: ContactPerson): void {
        // Keep the reference but copy over all properties.
        replaceObjectProperties({
            targetObject: this.contactPerson,
            sourceObject: contactPerson,
            propertiesToKeep: ['_id'],
        });

        this.dispatchNameChangeEvent();
        this.dispatchContactPersonChangeEvent();
        this.dispatchAutocompleteSelectionEvent();
    }

    public async clearContactPerson() {
        const existingContactPerson: ContactPerson = JSON.parse(JSON.stringify(this.contactPerson));

        replaceObjectProperties({
            targetObject: this.contactPerson,
            sourceObject: new ContactPerson({
                organizationType: this.contactPerson.organizationType,
            }),
            propertiesToKeep: ['_id'],
        });

        // Clear garage-specific properties
        if (this.contactPerson.organizationType === 'garage') {
            this.contactPerson.garageFeeSets = [];
            this.contactPerson.garageBrands = [];
            this.contactPerson.isBrandCertified = false;
            this.contactPerson.garageCharacteristics = [];
        }
        this.contactReset.emit(this.contactPerson);
        this.nameChange.emit(this.contactPerson);

        // Allow restoring the data
        this.toastService
            .info('Kontaktdaten entfernt', 'Zum Wiedereinfügen hier klicken.', {
                showProgressBar: true,
                timeOut: 10_000,
            })
            .click.subscribe(() => {
                replaceObjectProperties({
                    targetObject: this.contactPerson,
                    sourceObject: existingContactPerson,
                    propertiesToKeep: ['_id'],
                });
                this.contactReset.emit(this.contactPerson);
                this.nameChange.emit(this.contactPerson);
            });
    }

    //*****************************************************************************
    //  Insurance Logo
    //****************************************************************************/
    public insuranceLogoExists(name: string): boolean {
        return insuranceLogoExists(name);
    }

    public getIconNameForInsurance(name: string): string {
        return getFileNameForInsuranceLogo(name);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Insurance Logo
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Garage Brand Icons
    //****************************************************************************/
    public iconNameForCarBrand = iconNameForCarBrand;
    public iconForCarBrandExists = iconForCarBrandExists;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Autocomplete for Salutation
    //****************************************************************************/
    public filterSalutations(filterTerm: string) {
        this.filteredSalutations = getSalutations(filterTerm);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Autocomplete for Salutation
    /////////////////////////////////////////////////////////////////////////////*/

    public showSecondPhoneNumber(): void {
        this.secondPhoneNumberShown = true;
    }

    public removeSecondPhoneNumber(): void {
        this.secondPhoneNumberShown = false;
        this.contactPerson.phone2 = null;
    }

    /*****************************************************************************
     /  ADELTA.FINANZ Liquidity Check
     /****************************************************************************/

    public checkLiquidity(): void {
        if (
            !this.contactPerson ||
            !this.contactPerson.firstName ||
            !this.contactPerson.lastName ||
            !this.contactPerson.zip ||
            !this.contactPerson.city
        ) {
            this.dispatchLiquidityCheckError('ADDRESS_INCOMPLETE');
            this.adeltafinanzAddressIncorrect = true;
            return;
        }

        // Clear this in case it was set
        this.adeltafinanzAddressIncorrect = false;
        this.adeltafinanzRequestPending = true;

        // The API is pretty fast. Too fast for the user to see a loading state. Throttle that a bit.
        const requestStarted = moment();

        this.adeltaLiquidityService.checkLiquidity(this.contactPerson).subscribe({
            next: (response) => {
                if (
                    ['NEW_DEBTOR_IS_FINANCIALLY_STABLE', 'EXISTING_DEBTOR_IS_FINANCIALLY_STABLE'].includes(
                        response.code,
                    )
                ) {
                    this.contactPerson.financiallyStable = true;
                }

                if (['NEW_DEBTOR_REJECTED', 'EXISTING_DEBTOR_REJECTED'].includes(response.code)) {
                    this.contactPerson.financiallyStable = false;
                }

                if (response.code === 'DEBTOR_VERIFICATION_IN_PROGRESS') {
                    this.toastService.info(
                        'Debitor in Prüfung',
                        'ADELTA prüft den Debitor innerhalb von 48 Stunden. Bitte führe die Liquiditäts-Abfrage dann erneut aus.',
                    );
                }

                this.dispatchContactPersonChangeEvent();

                // Delay by one second if the request is faster than one second.
                const requestTime = moment().valueOf() - requestStarted.valueOf();
                const delay = requestTime > 1000 ? 0 : 1000 - requestTime;
                window.setTimeout(() => {
                    this.adeltafinanzRequestPending = false;
                }, delay);
            },
            error: (error) => {
                this.dispatchLiquidityCheckError(error.code);
                this.adeltafinanzRequestPending = false;

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ADDRESS_INCOMPLETE: {
                            action: () => {
                                this.adeltafinanzAddressIncorrect = true;
                            },
                        },
                        ADELTA_FINANZ_ADDRESS_PARAMETER_ERROR: {
                            action: () => {
                                this.adeltafinanzAddressIncorrect = true;
                            },
                        },
                        INVALID_ADDRESS: {
                            action: () => {
                                this.adeltafinanzAddressIncorrect = true;
                            },
                        },
                        ADELTA_FINANZ_DEBTOR_NOT_FOUND: {
                            action: () => {
                                this.adeltafinanzAddressIncorrect = false;
                            },
                        },
                    },
                    defaultHandler: {
                        title: 'Liquidität nicht geprüft',
                        body: 'Die Liquidität kann nicht geprüft werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            },
        });
    }

    public dispatchLiquidityCheckError(errorCode: string): void {
        this.liquidityCheckError.emit(errorCode);
    }

    public getLiquidityIconTooltip(): string {
        if (this.contactPerson.organization) {
            return 'Die Bonitätsprüfung kann live nur für Privatpersonen erfolgen. ADELTA.FINANZ prüft gewerbliche Debitoren manuell, was bis zu 48 Stunden dauern kann.';
        }
        if (this.adeltafinanzRequestPending) {
            return 'Prüfe Bonität...';
        }
        if (this.adeltafinanzAddressIncorrect) {
            return 'Aufgrund eines Adressfehlers konnte die Liquidität nicht geprüft werden.';
        }
        if (this.contactPerson.financiallyStable == null) {
            return 'Liquidität bei ADELTA.FINANZ abfragen';
        }
        if (this.contactPerson.financiallyStable === true) {
            return 'Liquidität durch ADELTA.FINANZ bestätigt';
        }
        if (this.contactPerson.financiallyStable === false) {
            return 'Liquidität durch ADELTA.FINANZ abgelehnt. Ankauf nur ohne Finanzierung möglich.';
        }
    }

    /*****************************************************************************
     //  END ADELTA.FINANZ Liquidity Check
     /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Birthday
    //****************************************************************************/
    public setUpBirthdayDatepicker(): void {
        this.birthdayYear = moment().subtract(18, 'years');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Birthday
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * The ZipCityDirective only updates the view's input, but not the model. Do that here.
     * @param {string} city
     */
    public insertCityIntoModel(city: string): void {
        if (city !== this.contactPerson.city) {
            this.contactPerson.city = city;
            this.dispatchContactPersonChangeEvent();
        }
    }

    //*****************************************************************************
    //  IBAN
    //****************************************************************************/
    public handleIbanChangeEvent(result: IbanChangeEvent): void {
        this.isIbanInvalid = !result.isValid;
        this.contactPerson.iban = this.formatIban(this.contactPerson.iban);
    }

    // TODO This shall be removed as soon as the iban-input handles formatting fully by itself.
    public formatIban(iban: string): string {
        // The printFormat method cannot handle null or undefined
        return IBAN.printFormat(iban ?? '', ' ');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END IBAN
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  VAT
    //****************************************************************************/
    public focusVatIdInput(event: MatCheckboxChange): void {
        setTimeout(() => {
            if (event.checked && this.vatIdInput) {
                (this.vatIdInput.nativeElement as HTMLInputElement).focus();
            }
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END VAT
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public stopEvent(event): void {
        event.stopImmediatePropagation();
    }

    public translateOrganizationType(contactPerson: ContactPerson) {
        switch (contactPerson.organizationType) {
            case 'lawyer':
                return 'Anwalt';
            case 'claimant':
                return 'Anspruchsteller';
            case 'insurance':
                return 'Versicherung';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/
}

type NameFilter = Extract<
    keyof ContactPerson,
    'organization' | 'firstName' | 'lastName' | 'streetAndHouseNumberOrLockbox' | 'city'
>;
type FilterFields = 'organization' | 'firstName' | 'lastName' | 'city' | 'streetAndHousNumberOrLockbox';

<mat-form-field class="mat-block">
    <input
        matInput
        name="monat"
        [placeholder]="placeholder"
        [value]="dateString"
        [disabled]="disabled"
        [matDatepicker]="datepicker"
        (focus)="emitFocusEvent($event)"
        autocomplete="off"
        (dateChange)="handleInputMonthSelection($event)"
        [axAutofocus]="autofocus" />
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-hint *ngIf="warning" class="warning">{{ warning }}</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="datepicker" [disableRipple]="true">
        <mat-icon matDatepickerToggleIcon class="datepicker-toggle-icon" matTooltip="Monat auswählen">today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker
        #datepicker
        (monthSelected)="handleDatepickerMonthSelection($event)"
        startView="multi-year"></mat-datepicker>
</mat-form-field>

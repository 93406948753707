<!-- Don't let prettier format the following inline elements (to prevent whitespace between heading and help indicator) -->
<!-- prettier-ignore -->
<h2 matDialogTitle>{{data.heading}}<span *ngIf="data.helpIndicatorText" class="help-indicator" matTooltip="{{ data.helpIndicatorText }}">?</span></h2>
<mat-dialog-content>
    {{ data.content }}
    <div id="user-input-container">
        <mat-form-field *ngIf="!data.autocompleteEntryType" [style.width]="data.inputFieldWidth">
            <input
                matInput
                [(ngModel)]="userInput"
                (keydown)="handleKeydownInInput($event)"
                [placeholder]="data.placeholder || 'Titel'" />
        </mat-form-field>
        <autocomplete-with-memory
            *ngIf="data.autocompleteEntryType"
            [(ngModel)]="userInput"
            [autocompleteEntryType]="data.autocompleteEntryType"
            (keydown)="handleKeydownInInput($event)"
            [placeholder]="data.placeholder || 'Titel'"></autocomplete-with-memory>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button id="stay-button" (click)="confirm()">{{ data.confirmLabel || 'Ja' }}</button>
    <button id="leave-button" class="secondary" (click)="cancel()">{{ data.cancelLabel || 'Abbrechen' }}</button>
</mat-dialog-actions>

import { DocumentOrder } from '../documents/document-order';
import { InvoiceParameters } from '../invoices/invoice-parameters';
import { User } from '../user/user';

export class RepairConfirmation {
    date: string = null; // The date the assessor appraised the car
    assessor: User['_id'] = null;
    visitingAssessor: User['_id'] = null;
    repairExecutionType: RepairExecutionType = null;
    comment: string = null;
    invoiceParameters: InvoiceParameters = new InvoiceParameters();
    actualReparationDowntime: string = null;
    completionDate: string = null; // The date the repair confirmation has been finished and signed
    /**
     * Contains one list for each involved party. Each list sorts and activates the documents in the report.documents array for
     * sending emails or downloading the full PDF.
     *
     * Another documentOrders array exists directly on the report since there are two document groups (report and repairConfirmation).
     */
    documentOrders?: DocumentOrder[] = [];
}

export const repairExecutionTypes = [
    'repaired',
    'professional',
    'withUsedParts',
    'partial',
    'roadworthy',
    null,
] as const;
export type RepairExecutionType = (typeof repairExecutionTypes)[number];

<div id="team-members-container" class="team-members-container">
    <!--============================================-->
    <!-- Team Members -->
    <!--============================================-->
    <div class="team-member" *ngFor="let teamMember of getActiveTeamMembers()" @fadeInAndOut>
        <div class="team-member-header">
            <!-- Evaluate the developerTools setting in the parent component, since we need the display-internal-id component not to be visible in the DOM in order to correct the styles with :has() -->
            <display-internal-id
                *ngIf="userPreferences.developerTools_displayIds"
                [internalId]="teamMember._id"
                entityName="Nutzer"></display-internal-id>
            <user-avatar
                [userId]="teamMember._id"
                class="cursor-pointer"
                (click)="userForEditInitialsDialog = teamMember"
                [size]="50"></user-avatar>
            <div class="team-member-details-container">
                <div class="team-member-name" [matTooltip]="teamMember.email">
                    {{ teamMember.firstName }} {{ teamMember.lastName }}
                </div>
                <div class="team-member-subtitle-row label">
                    <span class="team-member-entry-date">
                        <ng-container *ngIf="!teamMember.deactivateAt">
                            seit {{ teamMember.createdAt | moment }}
                        </ng-container>
                        <ng-container *ngIf="teamMember.deactivateAt">
                            Wird am {{ teamMember.deactivateAt | moment }} deaktiviert
                        </ng-container>
                    </span>
                    <div
                        #costCenterPopoverAnchor
                        (click)="openCostCenterPopover(teamMember)"
                        class="team-member-cost-center"
                        [matTooltip]="
                            'Kostenstelle: ' +
                            (teamMember.costCenter ||
                                'nicht gesetzt. Klicke, um eine Kostenstelle für den Mitarbeiter zu setzen.') +
                            '\n\n' +
                            'Die Kostenstelle wird in DATEV exportiert und ermöglicht es dir, bzw. deinem Steuerberater, unternehmerische Auswertungen wie z. B. den Betriebsabrechnungsbogen pro Sachverständigen oder Abteilung zu erstellen. Die Nutzer-Kostenstelle wird in DATEV im Feld KOST1 angezeigt.'
                        ">
                        <ng-container *ngIf="isCostCenterVisible(teamMember)">
                            <mat-icon class="small-icon outlined">paid</mat-icon>
                            <span>{{ teamMember.costCenter || 'Kostenstelle' }}</span>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!--
                        There is multifold logic in here:
                        * Only admins may delete other users.
                        * Even admins cannot delete the last remaining admin.
                        * Qapter-iXpert teams must not delete users on their own. The Audatex support must do that and update the customer's monthly invoice.
                        -->
            <div class="team-member-toolbar-container" *ngIf="userIsAdmin()">
                <mat-icon class="toolbar-icon team-member-submenu-icon" [matMenuTriggerFor]="teamMemberSubmenu">
                    more_vert
                </mat-icon>
            </div>

            <!-- Team member sub-menu -->
            <mat-menu #teamMemberSubmenu>
                <!-- Nutzer verwalten -->
                <a
                    *ngIf="userIsAdmin()"
                    mat-menu-item
                    (click)="userToAdministrateSelected.emit(teamMember)"
                    matTooltip="Die Einstellungen dieses Nutzers bearbeiten. Dafür wird dieser Nutzer in der Nutzerauswahl links ausgewählt.">
                    Einstellungen verwalten
                </a>

                <!-- Initials and Profile Picture Color -->
                <a
                    *ngIf="userIsAdmin()"
                    mat-menu-item
                    (click)="userForEditInitialsDialog = teamMember"
                    matTooltip="Die Initialen & die Farbe des Nutzers verändern. Diese können z. B. im Aktenzeichen verwendet werden.">
                    Initialen & Farbe verändern
                </a>

                <!-- Cost center  -->
                <a
                    mat-menu-item
                    (click)="addCostCenterToTeamMember(teamMember)"
                    matTooltip="Die Kostenstelle wird in DATEV exportiert und ermöglicht es dir, unternehmerische Auswertungen wie z. B. den Betriebsabrechnungsbogen pro Sachverständigen oder Abteilung zu erstellen. Die Sachverständigen-Kostenstelle wird in DATEV im Feld KOST1 angezeigt.">
                    {{ teamMember.costCenter ? 'Kostenstelle bearbeiten' : 'Kostenstelle hinzufügen' }}
                </a>

                <!-- Deaktivieren -->
                <a
                    mat-menu-item
                    *ngIf="!teamMember.deactivateAt && !(isAdmin(teamMember) && team.administrators.length === 1)"
                    (click)="deactivateTeamMember(teamMember)"
                    matTooltip="Ein deaktivierter Nutzer kann sich nicht mehr anmelden und keine Informationen aus dem Account mehr sehen. Die Gutachten des Nutzers bleiben im System jedoch erhalten.">
                    Team-Mitglied deaktivieren
                </a>

                <!-- Reaktivieren -->
                <a mat-menu-item *ngIf="teamMember.deactivateAt" (click)="reactivateTeamMember(teamMember)">
                    wieder aktivieren
                </a>

                <!-- Sofort deaktivieren -->
                <a
                    mat-menu-item
                    *ngIf="teamMember.deactivateAt && !(isAdmin(teamMember) && team.administrators.length === 1)"
                    (click)="deactivateTeamMember(teamMember, { instant: true })"
                    matTooltip="Ein deaktivierter Nutzer kann sich nicht mehr anmelden und keine Informationen aus dem Account mehr sehen. Die Gutachten des Nutzers bleiben im System jedoch erhalten.">
                    sofort deaktivieren
                </a>
            </mat-menu>
        </div>
        <div class="access-rights-container">
            <access-rights
                [isAdmin]="isAdmin(teamMember)"
                [accessRights]="teamMember.accessRights"
                [accessRightsDisplayConfig]="{
                    administrator: {
                        disabled: !isAdmin(user) || (isAdmin(teamMember) && team.administrators.length === 1),
                    },
                    seeAllReports: { disabled: !isAdmin(user) },
                    lockReports: { disabled: !isAdmin(user) },
                    editTextsAndDocumentBuildingBlocks: { disabled: !isAdmin(user) },
                    seeReportFees: { disabled: !isAdmin(user) },
                    editContacts: { disabled: !isAdmin(user) },
                    invoiceList: { disabled: !isAdmin(user) },
                    seeAllInvoices: { disabled: !isAdmin(user) || !teamMember.accessRights.invoiceList },
                    bankAccountSync: { disabled: !isAdmin(user) || !teamMember.accessRights.invoiceList },
                    reporting: { disabled: !isAdmin(user) },
                    seeSettings: { disabled: !isAdmin(user) },
                }"
                [askYourAdminTooltip]="isAdmin(user) ? '' : getAskYourAdminTooltip()"
                (isAdminChange)="toggleAdmin(teamMember, $event)"
                (accessRightsChange)="handleAccessRightsChange(teamMember, $event)"></access-rights>

            <div class="no-read-access-for-access-rights" *ngIf="!isAdmin(user) && teamMember._id !== user._id">
                Zugriffsrechte nur für Admins sichtbar
                <mat-icon
                    matTooltip="Administratoren können die Zugriffsrechte aller Nutzer sehen. Kontaktiere einen Admin, wenn du Rechte anpassen möchtest.">
                    visibility_off
                </mat-icon>
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Team Members -->
    <!--============================================-->

    <!--============================================-->
    <!-- Pending Invitations -->
    <!--============================================-->
    <ng-container *ngIf="isAdmin(user)">
        <div class="team-member pending-invitation" *ngFor="let pendingInvitation of pendingInvitations" @fadeInAndOut>
            <div class="pending-invitation-header">
                <div class="pending-invitation-header-name-and-status">
                    <div class="pending-invitation-email">{{ pendingInvitation.contactPerson.email }}</div>
                    <div class="invitation-sent-label label">Einladung gesendet</div>
                </div>
                <mat-icon
                    class="pending-invitation-header-close-icon"
                    (click)="removeUserRegistration(pendingInvitation)"
                    matTooltip="Einladung widerrufen">
                    delete
                </mat-icon>
            </div>
            <!--============================================-->
            <!-- Access Rights - Pending Invitation -->
            <!--============================================-->
            <access-rights
                [isAdmin]="pendingInvitation.futureAccessRights.isAdmin"
                [accessRights]="pendingInvitation.futureAccessRights"
                [accessRightsDisplayConfig]="{
                    administrator: { disabled: !isAdmin(user) },
                    seeAllReports: { disabled: !isAdmin(user) },
                    lockReports: { disabled: !isAdmin(user) },
                    editTextsAndDocumentBuildingBlocks: { disabled: !isAdmin(user) },
                    seeReportFees: { disabled: !isAdmin(user) },
                    editContacts: { disabled: !isAdmin(user) },
                    invoiceList: { disabled: !isAdmin(user) },
                    seeAllInvoices: { disabled: !isAdmin(user) || !pendingInvitation.futureAccessRights.invoiceList },
                    bankAccountSync: { disabled: !isAdmin(user) || !pendingInvitation.futureAccessRights.invoiceList },
                    reporting: { disabled: !isAdmin(user) },
                    seeSettings: { disabled: !isAdmin(user) },
                }"
                [askYourAdminTooltip]="isAdmin(user) ? '' : getAskYourAdminTooltip()"
                (isAdminChange)="pendingInvitation.futureAccessRights.isAdmin = $event.checked"
                (accessRightsChange)="
                    handlePendingInvitationAccessRightsChange(pendingInvitation, $event)
                "></access-rights>
            <!--============================================-->
            <!-- END Access Rights - Pending Invitation -->
            <!--============================================-->
        </div>
    </ng-container>
    <!--============================================-->
    <!-- END Pending Invitations -->
    <!--============================================-->

    <!--============================================-->
    <!-- Add User -->
    <!--============================================-->
    <div id="add-team-member-container" *ngIf="userIsAdmin()">
        <!-- Qapter-iXpert customers may only add users through Audatex support. -->
        <div
            id="add-team-member-inner-container"
            *ngIf="!isQapterixpert()"
            (click)="openTeamMemberInvitationDialog()"
            matTooltip="Team-Mitglied hinzufügen">
            <div id="add-team-member-icon-container">
                <mat-icon id="add-team-member-icon">add</mat-icon>
            </div>
            <div id="add-team-member-text">Neuer Nutzer</div>
        </div>

        <div class="label text-align-center" *ngIf="isQapterixpert()">
            Um Nutzer hinzuzufügen
            <br />
            wende dich an den
            <a
                href="javascript:void(0)"
                (click)="openEmailToAudatexSalesRequestingAdditionalUser()"
                target="_blank"
                rel="noopener">
                Audatex-Vertrieb.
            </a>
        </div>
    </div>
    <!--============================================-->
    <!-- END Add User -->
    <!--============================================-->
</div>

<!--============================================-->
<!-- Deactivated Users -->
<!--============================================-->
<h3 *ngIf="getInactiveTeamMembers().length > 0">Deaktivierte Nutzer</h3>
<div class="team-members-container inactive-team-members-container">
    <div class="team-member inactive" *ngFor="let teamMember of getInactiveTeamMembers()" @fadeInAndOut>
        <div class="team-member-header">
            <div class="team-member-initials-container">
                <div class="team-meber-initials" [ngClass]="{ 'three-letters': teamMember.initials?.length === 3 }">
                    {{ teamMember.initials }}
                </div>
            </div>
            <div class="team-member-details-container" [matTooltip]="teamMember.email">
                <div class="team-member-name">{{ teamMember.firstName }} {{ teamMember.lastName }}</div>
                <div class="team-member-entry-date label" *ngIf="teamMember.deactivateAt">
                    <span>deaktiviert seit {{ teamMember.deactivateAt | moment }}</span>
                </div>
            </div>
            <div class="team-member-toolbar-container" *ngIf="isAdmin(user) && !isQapterixpertTeam()">
                <mat-icon class="toolbar-icon team-member-submenu-icon" [matMenuTriggerFor]="teamMemberSubmenu">
                    more_vert
                </mat-icon>
            </div>
            <mat-menu #teamMemberSubmenu>
                <a mat-menu-item (click)="reactivateTeamMember(teamMember)">Reaktivieren</a>
            </mat-menu>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Deactivated Users -->
<!--============================================-->

<team-member-invitation-dialog
    *ngIf="teamMemberInvitationDialogShown"
    (invitationSent)="addPendingInvitation($event)"
    [teamMemberToReactivate]="teamMemberToReactivate"
    (close)="hideTeamMemberInvitationDialog()"></team-member-invitation-dialog>

<user-initials-edit-dialog
    *ngIf="userForEditInitialsDialog"
    [user]="userForEditInitialsDialog"
    (userChange)="saveUser(userForEditInitialsDialog)"
    (close)="userForEditInitialsDialog = null"
    @runChildAnimations></user-initials-edit-dialog>

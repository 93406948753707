import { getMapFromRecords } from '@autoixpert/lib/server-sync/get-map-from-records';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { AxError } from '@autoixpert/models/errors/ax-error';

export function getOrderedDocuments({
    documents,
    documentOrder,
    excludeDeactivatedDocuments,
}: {
    documents: DocumentMetadata[];
    documentOrder: DocumentOrder;
    /**
     * Relevant when getting the documents for generating the Full PDF or sending documents via email.
     */
    excludeDeactivatedDocuments?: boolean;
}): DocumentMetadata[] {
    const orderedDocuments: DocumentMetadata[] = [];

    const documentMap: Map<DocumentMetadata['_id'], DocumentMetadata> = getMapFromRecords(documents);

    for (const documentOrderItem of documentOrder.items) {
        if (excludeDeactivatedDocuments && !documentOrderItem.includedInFullDocument) {
            continue;
        }

        const document: DocumentMetadata = documentMap.get(documentOrderItem._id);
        if (!document) {
            throw new AxError({
                code: 'DOCUMENT_REFERENCE_IN_DOCUMENT_ORDER_BROKEN',
                message: `The given document order contains an item that references a document which does not exist in the given documents array.`,
                data: {
                    documentOrderItem,
                    documentOrder,
                    documents,
                },
            });
        } else {
            orderedDocuments.push(document);
        }
    }

    return orderedDocuments;
}

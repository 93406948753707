import { IDBPDatabase, IDBPTransaction, StoreKey, StoreNames, openDB } from 'idb';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { pluralize } from '@autoixpert/lib/pluralize';
import { AxError } from '@autoixpert/models/errors/ax-error';
import {
    BlobDataType,
    BlobObjectStoreAndIndexMigration,
    BlobObjectStoreAndIndexMigrations,
    BlobRecordMigrations,
    DatabaseIndexeddbBlobStructure,
} from '@autoixpert/models/indexed-db/database-blob.types';
import {
    AxIndexeddbDump,
    AxIndexeddbDumpRecords,
    ChangeRecord,
    DatabaseServiceName,
} from '@autoixpert/models/indexed-db/database.types';
import { FrontendLogService } from '../../services/frontend-log.service';
import { StorageSpaceManager } from '../storage-space-manager/storage-space-manager.class';

/**
 * A very simple IndexedDB wrapper that can store blobs. It also keeps track of change
 */
export class AxIndexedDBBlob {
    public readonly serviceName: DatabaseServiceName; // Name of the service in the backend that this database shall sync with. Singular, such as "report" for the endpoint "/reports"
    protected serviceNamePlural: string; // Plural name, such as "reports" or "contactPeople"-> Is explicitly passed or inferred automatically.

    /**
     * The promise-less connection object should be used if available because awaiting the promise may result in ~100ms due to the event loop.
     *
     * The promise can be used if the connection has not yet been established so that calls to IndexedDB do not cause an error.
     * @private
     */
    private indexeddbConnectionPromise: Promise<IDBPDatabase<DatabaseIndexeddbBlobStructure>>;
    private indexeddbConnection: IDBPDatabase<DatabaseIndexeddbBlobStructure>;

    /**
     * Set to true if the user's browser does not grant access to IndexedDB. Possible reasons:
     * - Incognito mode in Firefox. Incognito mode in Chrome allows using IndexedDB.
     * - IndexedDB not implemented <-- This should rarely be the case.
     */
    public isIndexeddbUnusable: boolean = false;

    //*****************************************************************************
    //  Migrations
    //****************************************************************************/
    /**
     * Additional recordMigrations may be added in the constructor of a class extending this base class. These recordMigrations do not migrate the database
     * but the records within the database.
     */
    protected recordMigrations: BlobRecordMigrations = {};
    /**
     * These functions will be executed in the order of the key (which must be a timestamp). This ensures that both centrally defined schema changes (object stores, indexes, ...)
     * (in this class) and indexes per service (in the inheriting class) can be created. Indexes per service are relevant for sorting by a specific field, e.g. sorting
     * a contact person by organization.
     *
     * To add a schema migration in the inheriting class, pass another object like this at objectStoreAndIndexMigrations to this class' constructor.
     * The timestamp should be set to the time when the schema migration was created by the developer.
     */
    protected objectStoreAndIndexMigrations: BlobObjectStoreAndIndexMigrations = {
        '2022-01-01T00:00:00.000+01:00': async (database) => {
            database.createObjectStore('records');
            database.createObjectStore('blobContentHashes');
            database.createObjectStore('created', { keyPath: '_id' });
            database.createObjectStore('deleted', { keyPath: '_id' });
            database.createObjectStore('lastAccessed', { keyPath: '_id' });
        },
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Migrations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Local CRUD Events
    //****************************************************************************/
    public createdInLocalDatabase$ = new Subject<BlobDataType>();
    public deletedInLocalDatabase$ = new Subject<BlobDataType['_id']>();
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local CRUD Events
    /////////////////////////////////////////////////////////////////////////////*/

    constructor(params: {
        serviceName: DatabaseServiceName;
        frontendLogService: FrontendLogService;
        serviceNamePlural?: string;
        recordMigrations?: BlobRecordMigrations;
        objectStoreAndIndexMigrations?: BlobObjectStoreAndIndexMigrations;
    }) {
        this.serviceName = params.serviceName;
        this.serviceNamePlural = params.serviceNamePlural ?? pluralize(this.serviceName);
        this.recordMigrations = params.recordMigrations || {};
        this.objectStoreAndIndexMigrations = {
            ...this.objectStoreAndIndexMigrations,
            ...(params.objectStoreAndIndexMigrations || {}),
        };

        this.openDatabase();
    }

    //*****************************************************************************
    //  Open Database
    //****************************************************************************/
    public openDatabase() {
        //*****************************************************************************
        //  IndexedDB Object Store & Index Migrations
        //****************************************************************************/
        const sortedIndexeddbMigrations: BlobObjectStoreAndIndexMigration[] = [];
        const indexeddbMigrationsKeys = Object.keys(this.objectStoreAndIndexMigrations).sort(
            (timestampA, timestampB) => {
                return DateTime.fromISO(timestampA) < DateTime.fromISO(timestampB) ? -1 : 1;
            },
        );
        for (const indexeddbMigrationsKey of indexeddbMigrationsKeys) {
            sortedIndexeddbMigrations.push(this.objectStoreAndIndexMigrations[indexeddbMigrationsKey]);
        }

        /**
         * This custom promise only resolves if the openDB request *including the upgrade migrations* succeeded.
         */
        this.indexeddbConnectionPromise = new Promise<IDBPDatabase<DatabaseIndexeddbBlobStructure>>(
            (resolve, reject) => {
                openDB(this.getDatabaseName(), sortedIndexeddbMigrations.length, {
                    //*****************************************************************************
                    //  Execute Version Upgrades
                    //****************************************************************************/
                    upgrade: async (
                        database: IDBPDatabase<DatabaseIndexeddbBlobStructure>,
                        oldDatabaseVersion: number,
                        newDatabaseVersion: number | null,
                        transaction: IDBPTransaction<
                            DatabaseIndexeddbBlobStructure,
                            StoreNames<DatabaseIndexeddbBlobStructure>[],
                            'versionchange'
                        >,
                    ) => {
                        for (const index in sortedIndexeddbMigrations) {
                            // The first migration at index 0 migrates to version 1, index 1 migrates to version 2 etc.
                            const migrationVersion = parseInt(index) + 1;
                            /**
                             * Only execute the database migration that's relevant for this version change. Otherwise, changes on the database would be executed multiple times.
                             */
                            if (migrationVersion > oldDatabaseVersion) {
                                try {
                                    await sortedIndexeddbMigrations[index](database, transaction);
                                } catch (error) {
                                    // Error Message: "Failed to execute 'transaction' on 'IDBDatabase': A version change transaction is running."
                                    if (error.code === 11) {
                                        const migrationError = new AxError({
                                            code: 'TRANSACTION_WAS_RUNNING_DURING_INDEXEDDB_MIGRATION',
                                            message:
                                                'Please check if all calls within your migration use the transaction passed as a parameter to the upgrade method. Details: https://stackoverflow.com/questions/33709976/uncaught-invalidstateerror-failed-to-execute-transaction-on-idbdatabase-a',
                                            error,
                                            data: {
                                                serviceName: this.serviceName,
                                                migrationVersion,
                                                oldDatabaseVersion,
                                            },
                                        });
                                        reject(migrationError);
                                    }

                                    const migrationError = new AxError({
                                        code: 'EXECUTING_INDEXEDDB_MIGRATION_FAILED',
                                        message: `The IndexedDB migration in the service "${this.serviceName}" failed to execute.`,
                                        error,
                                        data: {
                                            serviceName: this.serviceName,
                                            migrationVersion,
                                            oldDatabaseVersion,
                                        },
                                    });
                                    reject(migrationError);
                                }
                            }
                        }
                    },
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Execute Version Upgrades
                    /////////////////////////////////////////////////////////////////////////////*/
                    //*****************************************************************************
                    //  Register Close Handler
                    //****************************************************************************/
                    // If the connection is closed by the browser (possibly connection timeouts after having been open
                    // for a long while), re-establish the connection.
                    terminated: () => {
                        console.warn(`[${this.serviceName}] Terminated IndexedDB connection. Re-opening.`);
                        this.openDatabase();
                    },
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Register Close Handler
                    /////////////////////////////////////////////////////////////////////////////*/

                    // After all upgrades ran through, resolve.
                }).then(resolve, reject);
            },
        )
            .then((indexeddbConnection) => {
                this.indexeddbConnection = indexeddbConnection;
                return indexeddbConnection;
            })
            .catch((error) => {
                console.error(`Opening the IndexedDB connection for the service "${this.serviceName}" failed.`, {
                    error,
                });
                if (error.name === 'InvalidStateError') {
                    this.isIndexeddbUnusable = true;
                }
                throw error;
            });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END IndexedDB Object Store & Index Migrations
        /////////////////////////////////////////////////////////////////////////////*/
    }

    private getDatabaseName(): string {
        return `${this.serviceNamePlural}-blobs`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Open Database
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Local CRUD Operations (IndexedDB)
    //****************************************************************************/
    /**
     * Create a record in the local database and propagate this change to all components subscribing to this event.
     * This method should be called both when a user creates a record locally and when an event is received from the server
     * about another device having created a record.
     *
     * @param record
     * @param source - If the record has been created in this browser context (local) or in another place (external) such as the server or another tab.
     */
    public async createLocal(record: BlobDataType, source?: 'local' | 'external'): Promise<BlobDataType>;
    public async createLocal(records: BlobDataType[], source?: 'local' | 'external'): Promise<BlobDataType[]>;
    public async createLocal(
        records: BlobDataType | BlobDataType[],
        source: 'local' | 'external' = 'local',
    ): Promise<BlobDataType | BlobDataType[]> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        let inputWasArray = true;
        if (!Array.isArray(records)) {
            records = [records];
            inputWasArray = false;
        }

        /**
         * Use a single transaction to speed up the full sync mechanism.
         */
        const transaction = indexeddbConnection.transaction(
            ['records', 'blobContentHashes', 'created', 'deleted', 'lastAccessed'],
            'readwrite',
        );

        for (const record of records) {
            /**
             * In order to increase the perceived performance for the user, we do an optimistic write and rather handle the failure exception
             * instead of waiting for the transaction to be done.
             */
            this.createdInLocalDatabase$.next(record);
            transaction
                .objectStore('records')
                .put(record.blob, record._id)
                .catch(async (error) => {
                    await StorageSpaceManager.checkNeedForDataEjection({ error });
                    console.error('Error creating record in "createLocal".', error, record);
                    this.deletedInLocalDatabase$.next(record._id);
                    throw error;
                });
            transaction
                .objectStore('blobContentHashes')
                .put(record.blobContentHash, record._id)
                .catch(async (error) => {
                    await StorageSpaceManager.checkNeedForDataEjection({ error });
                    console.error('Error creating blob content hash in "createLocal".', error, record);
                    this.deletedInLocalDatabase$.next(record._id);
                    throw error;
                });
            // If the record has been created on this device/tab, make a 'created' metadata record. This will create this record
            // on the server on next sync.
            if (source === 'local') {
                transaction
                    .objectStore('created')
                    .put({ _id: record._id })
                    .catch((error) => {
                        console.error('Error creating "created" entry in "createLocal".', error, record);
                        throw error;
                    });
                /**
                 * If this record was previously deleted locally and is now restored or created again with the same ID, remove the
                 * deleted record.
                 * A record may be created with the same ID in the case of claimant signatures. They have the format `${reportId}-declarationOfAssignment`.
                 */
                await transaction.objectStore('deleted').delete(record._id);
            }
            this.setLastAccessed(record._id, transaction).catch((error) => {
                console.error('Error setting lastAccessed entry in "createLocal".', error, record);
                throw error;
            });
        }

        // This is the signal that everything was successfully committed to the database.
        await transaction.done;

        if (inputWasArray) {
            return records;
        } else {
            return records[0];
        }
    }

    /**
     * Used internally to get the current state of a record. That's relevant e.g. when patching records to the server.
     * @param recordId
     * @private
     */
    public async getLocal(recordId: BlobDataType['_id']): Promise<BlobDataType | undefined> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const [blob, blobContentHash] = await Promise.all([
            /**
             * Do not use a single transaction for getting the record and writing the last accessed timestamp to increase read speed. If the transaction
             * is started in "readwrite" mode, both the "records" store and the "lastAccessed" store are write-locked. FOr many parallel reads as with the photos
             * in the report list, this decreases performance drastically.
             */
            db.get('records', recordId),
            db.get('blobContentHashes', recordId),
            this.setLastAccessed(recordId),
        ]);

        if (!blob) {
            return undefined;
        }

        return {
            _id: recordId,
            blob,
            blobContentHash,
        };
    }

    /**
     * Check if an entry exists locally without querying its content. That's faster than executing a getLocal() call.
     */
    public async existsLocal(recordId: BlobDataType['_id']): Promise<boolean> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const numberOfRecordsWithGivenId = await db.count('records', recordId);

        return numberOfRecordsWithGivenId > 0;
    }

    public async findLocalByIds(recordIds: BlobDataType['_id'][]): Promise<BlobDataType[]> {
        const timerLabel = `findByIds - ${this.serviceName} - ${Math.random()}`;
        ((window as any).offlineSyncDebugging === '*' ||
            (window as any).offlineSyncDebugging?.includes?.(this.serviceName)) &&
            console.time(timerLabel);
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const transaction = await db.transaction(['records', 'blobContentHashes'], 'readonly');
        const foundRecords: BlobDataType[] = [];
        for (const recordId of recordIds) {
            const [blob, blobContentHash] = await Promise.all([
                transaction.objectStore('records').get(recordId),
                transaction.objectStore('blobContentHashes').get(recordId),
            ]);
            if (blob) {
                foundRecords.push({
                    _id: recordId,
                    blob,
                    blobContentHash,
                });
            }
        }

        /**
         * The last accessed date is used when deciding which data to eject when storage space becomes scarce.
         */
        this.setBatchLastAccessed(foundRecords.map((record) => record._id)).catch((error) =>
            console.error('Error setting the lastAccessed timestamp of found records.', foundRecords, error),
        );

        ((window as any).offlineSyncDebugging === '*' ||
            (window as any).offlineSyncDebugging?.includes?.(this.serviceName)) &&
            console.timeEnd(timerLabel);
        return foundRecords;
    }

    public async findLocalByKeyRange(keyRange: IDBKeyRange): Promise<BlobDataType[]> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const [keys, blobs, blobContentHashes]: [BlobDataType['_id'][], Blob[], string[]] = await Promise.all([
            db.getAllKeys('records', keyRange),
            db.getAll('records', keyRange),
            db.getAll('blobContentHashes', keyRange),
        ]);

        const records: BlobDataType[] = [];
        for (const index in keys) {
            records.push({
                _id: keys[index],
                blob: blobs[index],
                blobContentHash: blobContentHashes[index],
            });
        }

        return records;
    }

    /**
     * Find all records the ID of which starts with a given prefix.
     * Useful for finding all records whose ID is composed of the report ID and another record ID, e.g. photo files.
     */
    public async findLocalByPrefix(prefix: string): Promise<BlobDataType[]> {
        /**
         * "\uffff" is the last character in the UTF-8 charset, so it defines the last possible character that may follow the prefix.
         * Source: https://stackoverflow.com/a/20917631/1027464
         */
        return this.findLocalByKeyRange(IDBKeyRange.bound(prefix, prefix + '\uffff'));
    }

    public async deleteLocal(recordId: BlobDataType['_id'], source?: 'local' | 'external'): Promise<void>;
    public async deleteLocal(recordIds: BlobDataType['_id'][], source?: 'local' | 'external'): Promise<void>;
    public async deleteLocal(
        recordIds: BlobDataType['_id'] | BlobDataType['_id'][],
        source: 'local' | 'external' = 'local',
    ): Promise<void> {
        if (!Array.isArray(recordIds)) {
            recordIds = [recordIds];
        }

        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const transaction = indexeddbConnection.transaction(
            ['created', 'deleted', 'records', 'blobContentHashes', 'lastAccessed'],
            'readwrite',
        );

        for (const recordId of recordIds) {
            const recordExistsLocallyOnly: boolean = !!(await transaction.objectStore('created').get(recordId));
            if (recordExistsLocallyOnly) {
                // Local-only records are simply purged. No record change will be saved in order to update the server because the record does not exist there.
                await transaction.objectStore('created').delete(recordId);
            } else {
                // If the record has been deleted on this device/tab, make a 'deleted' metadata record. This will delete this record
                // on the server on next sync.
                if (source === 'local') {
                    await transaction.objectStore('deleted').put({ _id: recordId });
                }
            }
            transaction
                .objectStore('records')
                .delete(recordId)
                .catch((error) => {
                    console.error('Error deleting record in "deleteLocal".', error, recordId);
                    throw error;
                });
            transaction
                .objectStore('blobContentHashes')
                .delete(recordId)
                .catch((error) => {
                    console.error('Error deleting blobContentHash in "deleteLocal".', error, recordId);
                    throw error;
                });
            await this.deleteLastAccessed(recordId, transaction);

            // Use optimistic deletion instead of waiting for the transaction to complete for maximum performance.
            this.deletedInLocalDatabase$.next(recordId);
        }

        await transaction.done;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local CRUD Operations (IndexedDB)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Local Changes
    //****************************************************************************/
    /**
     * Write
     */
    public async setCreationChangeRecord(recordId: BlobDataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.put('created', { _id: recordId });
    }

    public async setDeletionChangeRecord(recordId: BlobDataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.put('deleted', { _id: recordId });
    }

    /**
     * Read
     */
    public async getCreationChangeRecords(): Promise<ChangeRecord[]> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        return await indexeddbConnection.getAll('created');
    }

    public async getDeletionChangeRecords(): Promise<ChangeRecord[]> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        return await indexeddbConnection.getAll('deleted');
    }

    /**
     * Delete
     */
    public async deleteCreationChangeRecord(recordId: BlobDataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.delete('created', recordId);
    }

    public async deleteDeletionChangeRecord(recordId: BlobDataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        await indexeddbConnection.delete('deleted', recordId);
    }

    /**
     * Use this in case the status of this local record matters for dealing with the server.
     * Example: Photos. Only when the original photo was uploaded, will its status change from "created" to "undefined". Then, the client
     *          may access the backend-autoixpert to have thumbnails generated (which is faster than for the client to download all original
     *          photos and render them itself).
     */
    public async getLocalStatus(recordId: BlobDataType['_id']): Promise<'created' | 'deleted' | undefined> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        const transaction = indexeddbConnection.transaction(['created', 'deleted'], 'readonly');

        const [recordWasCreatedLocally, recordWasDeletedLocally]: boolean[] = (
            await Promise.all([
                transaction.objectStore('created').get(recordId),
                transaction.objectStore('deleted').get(recordId),
            ])
        ).map(Boolean);

        if (recordWasCreatedLocally) {
            return 'created';
        } else if (recordWasDeletedLocally) {
            return 'deleted';
        }
        return undefined;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Local Changes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Reset Local
    //****************************************************************************/
    /**
     * If the user wants to discard his local changes, replace the local record with the server shadow (= version on the server).
     */
    public async resetLocal(recordId: BlobDataType['_id']): Promise<void> {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = indexeddbConnection.transaction(
            ['created', 'deleted', 'records', 'blobContentHashes', 'lastAccessed'],
            'readwrite',
        );

        // Depending on what update state the record was in, we emit different events.
        const recordWasCreatedLocally: boolean = !!(await transaction.objectStore('created').get(recordId));
        const recordWasDeletedLocally: boolean = !!(await transaction.objectStore('deleted').get(recordId));
        const blob: Blob = await transaction.objectStore('records').get(recordId);
        const blobContentHash: BlobDataType['blobContentHash'] = await transaction
            .objectStore('blobContentHashes')
            .get(recordId);

        // Emit events based on the update state of the record.
        // Local only -> Remove the record altogether. Let the components know the record has been deleted.
        if (recordWasCreatedLocally) {
            await transaction.objectStore('records').delete(recordId);
            await transaction.objectStore('blobContentHashes').delete(recordId);
            this.deletedInLocalDatabase$.next(recordId);
        } else if (recordWasDeletedLocally) {
            this.createdInLocalDatabase$.next({
                _id: recordId,
                blob,
                blobContentHash,
            });
        }

        // Delete any meta records that a change has happened.
        await transaction.objectStore('created').delete(recordId);
        await transaction.objectStore('deleted').delete(recordId);

        //noinspection ES6MissingAwait -> We don't need to wait for the lastAccessed to be written.
        await this.setLastAccessed(recordId, transaction);

        await transaction.done;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reset Local
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Last Access
    //****************************************************************************/
    /**
     * To know which records have been used the least (it's been the longest time ago),
     * we must record the timestamp when they were accessed.
     *
     * @param recordId
     * @param transaction
     * @private
     */
    private async setLastAccessed(
        recordId: BlobDataType['_id'],
        transaction?: IDBPTransaction<DatabaseIndexeddbBlobStructure, any, 'readwrite'>,
    ): Promise<StoreKey<IDBPTransaction<DatabaseIndexeddbBlobStructure>, 'lastAccessed'>> {
        try {
            const putValue = {
                _id: recordId,
                lastAccessedAt: DateTime.now().toISO(),
            };
            if (transaction) {
                return transaction.objectStore('lastAccessed').put(putValue);
            } else {
                const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
                return indexeddbConnection.put('lastAccessed', putValue);
            }
        } catch (error) {
            await StorageSpaceManager.checkNeedForDataEjection({ error });
            throw error;
        }
    }

    private async setBatchLastAccessed(recordIds: BlobDataType['_id'][]) {
        const indexeddbConnection = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = indexeddbConnection.transaction('lastAccessed', 'readwrite');
        for (const recordId of recordIds) {
            try {
                await transaction.objectStore('lastAccessed').put({
                    _id: recordId,
                    lastAccessedAt: DateTime.now().toISO(),
                });
            } catch (error) {
                await StorageSpaceManager.checkNeedForDataEjection({ error });
                throw error;
            }
        }
    }

    private async deleteLastAccessed(
        recordId: BlobDataType['_id'],
        transaction: IDBPTransaction<DatabaseIndexeddbBlobStructure, any, 'readwrite'>,
    ) {
        await transaction.objectStore('lastAccessed').delete(recordId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Last Access
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Database Size
    //****************************************************************************/
    public async getDatabaseSize(): Promise<number> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);

        let databaseSizeInBytes: number = 0;

        /**
         * Count the size of the content of all object stores in this database.
         * There is one database per DatabaseService (e.g. "reports", "contactPeople", ...).
         */
        for (const objectStoreName of db.objectStoreNames) {
            try {
                let cursor = await db.transaction(objectStoreName, 'readonly').store.openCursor();

                while (cursor) {
                    // The records object store contains blobs. All other object stores such as created, deleted or lastAccessed contain JSON objects.
                    if (cursor.value instanceof Blob) {
                        databaseSizeInBytes += cursor.value.size;
                    } else {
                        databaseSizeInBytes += JSON.stringify(cursor.value)?.length || 0;
                    }
                    cursor = await cursor.continue();
                }
            } catch (error) {
                console.error(
                    `Getting the size of the object store "${objectStoreName}" in the service "${this.serviceName}" failed.`,
                    { error },
                );
            }
        }

        return databaseSizeInBytes;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Database Size
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Eject Old Data
    //****************************************************************************/
    async ejectData(lastAccessedBefore?: DateTime) {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(
            ['created', 'deleted', 'records', 'blobContentHashes', 'lastAccessed'],
            'readwrite',
        );
        const keys = await transaction.objectStore('records').getAllKeys();

        for (const recordId of keys) {
            /**
             * Check if the record was last accessed before the time threshold. No threshold means that all records should be deleted
             * if they don't have outstanding syncs to the server.
             */
            const lastAccessedRecord = await transaction.objectStore('lastAccessed').get(recordId);
            const lastAccessedAt = lastAccessedRecord
                ? DateTime.fromISO(lastAccessedRecord?.lastAccessedAt)
                : undefined;
            const lastAccessedBeforeThreshold =
                !lastAccessedAt || !lastAccessedBefore || lastAccessedAt < lastAccessedBefore;

            /**
             * Check if all changes were synced to the server. No local changes should be deleted because of storage space issues
             * if they have not yet been synced to the server. Otherwise, useful data that the user entered while being offline
             * might get lost.
             */
            const hasCreatedEntry = await transaction.objectStore('created').get(recordId);
            const hasDeletedEntry = await transaction.objectStore('deleted').get(recordId);
            const hasOutstandingSyncs = hasCreatedEntry || hasDeletedEntry;

            if (lastAccessedBeforeThreshold && !hasOutstandingSyncs) {
                await Promise.allSettled([
                    transaction.objectStore('created').delete(recordId),
                    transaction.objectStore('deleted').delete(recordId),
                    transaction.objectStore('records').delete(recordId),
                    transaction.objectStore('blobContentHashes').delete(recordId),
                    transaction.objectStore('lastAccessed').delete(recordId),
                ]);
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Eject Old Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invalidate Cache
    //****************************************************************************/
    public async clearObjectStores() {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(db.objectStoreNames, 'readwrite');
        // Remove content from all object stores
        await Promise.allSettled(
            [...db.objectStoreNames].map((objectStoreName) =>
                transaction
                    .objectStore(objectStoreName)
                    .clear()
                    .catch((error) =>
                        console.error(
                            `[offline-sync ${this.serviceName}] Clearing the object store ${objectStoreName} while invalidating the cache failed.`,
                            { error },
                        ),
                    ),
            ),
        );
    }

    public async getNumberOfOutstandingSyncs(): Promise<number> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(['created', 'deleted'], 'readonly');
        /**
         * Check if there are any changes that have not yet been synced to the server.
         * This check is relevant for when the user logs out. He may choose between discarding these unsynced items, too, or staying logged in.
         */
        const [numberOfCreatedEntries, numberOfDeletedEntries]: number[] = await Promise.all([
            transaction.objectStore('created').count(),
            transaction.objectStore('deleted').count(),
        ]);
        return numberOfCreatedEntries + numberOfDeletedEntries;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invalidate Cache
    /////////////////////////////////////////////////////////////////////////////*/
    //*****************************************************************************
    //  Debugging
    //****************************************************************************/
    public async dumpData(): Promise<AxIndexeddbDump> {
        const db = this.indexeddbConnection || (await this.indexeddbConnectionPromise);
        const transaction = db.transaction(db.objectStoreNames, 'readonly');

        const axIndexeddbDump: AxIndexeddbDump = {};

        // Remove content from all object stores
        for (const objectStoreName of db.objectStoreNames) {
            try {
                const objectStoreKeys = await transaction.objectStore(objectStoreName).getAllKeys();
                const objectStoreContents = await transaction.objectStore(objectStoreName).getAll();

                const dumpRecords: AxIndexeddbDumpRecords = {};
                for (let i = 0; i < objectStoreKeys.length; i++) {
                    const key = objectStoreKeys[i];
                    const value = objectStoreContents[i];
                    dumpRecords[key] = value instanceof Blob ? { size: value.size, type: value.type } : value;
                }
                axIndexeddbDump[objectStoreName] = dumpRecords;
            } catch (error) {
                console.error(
                    `[offline-sync ${this.serviceName}] Dumping the object store ${objectStoreName} for debugging failed.`,
                    { error },
                );
            }
        }
        return axIndexeddbDump;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Debugging
    /////////////////////////////////////////////////////////////////////////////*/
}

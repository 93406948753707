import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

/**
 * Applies an IntersectionObserver to the element and emits an event when the element is intersecting into the viewport.
 * Used to implement infinite scrolling without subscribing to scroll events.
 */
@Directive({
    selector: '[intersectsViewport]',
})
export class IntersectionDirective {
    constructor(private element: ElementRef) {}

    @Input() root: HTMLElement | null = null;
    @Input() rootMargin = '0px 0px 0px 0px';
    @Input() threshold = 0;
    @Input() isContinuous = false;

    @Output() intersectsViewport = new EventEmitter<void>();

    intersectionObserverSubscription: Subscription = null;

    ngOnInit() {
        this.createAndObserve();
    }

    ngOnDestroy() {
        this.intersectionObserverSubscription?.unsubscribe();
    }

    _isIntersecting = false;

    createAndObserve() {
        const options: IntersectionObserverInit = {
            root: this.root,
            rootMargin: this.rootMargin,
            threshold: this.threshold,
        };

        this.intersectionObserverSubscription = new Observable<boolean>((subscriber) => {
            const intersectionObserver = new IntersectionObserver((entries) => {
                const { isIntersecting } = entries[0];
                subscriber.next(isIntersecting);

                isIntersecting && !this.isContinuous && intersectionObserver.disconnect();
            }, options);

            intersectionObserver.observe(this.element.nativeElement);

            return {
                unsubscribe() {
                    intersectionObserver.disconnect();
                },
            };
        }).subscribe((status) => {
            if (status) {
                this.intersectsViewport.emit();
            }
            this._isIntersecting = status;
        });
    }
}

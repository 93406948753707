import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NotificationsService } from 'angular2-notifications/dist';
import { Subscription } from 'rxjs';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { enhancedDocumentBuildingBlocks } from '@autoixpert/lib/document-building-blocks/enhanced-document-building-blocks';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { Team } from '@autoixpert/models/teams/team';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { DocumentPartialService } from '../../shared/services/document-partial.service';
import { DownloadService } from '../../shared/services/download.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { TeamService } from '../../shared/services/team.service';

@Component({
    selector: 'document-building-block-config-dialog',
    templateUrl: 'document-building-block-config-dialog.component.html',
    styleUrls: ['document-building-block-config-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), fadeInAndOutAnimation()],
})
export class DocumentBuildingBlockConfigDialogComponent {
    constructor(
        private teamService: TeamService,
        private loggedInUserService: LoggedInUserService,
        private documentPartialService: DocumentPartialService,
        private downloadService: DownloadService,
        private toastService: NotificationsService,
        private apiErrorService: ApiErrorService,
    ) {}

    public team: Team;

    @Input() buildingBlock: DocumentBuildingBlock;

    @Output() buildingBlockChange: EventEmitter<DocumentBuildingBlock> = new EventEmitter<DocumentBuildingBlock>();
    // Deleting templates on close instead of on change is better for performance. Triggering multiple deletes on change is so slow that changing the radio options lags.
    @Output() close: EventEmitter<{ templateDeletionRequired: boolean; buildingBlock: DocumentBuildingBlock }> =
        new EventEmitter<{ templateDeletionRequired: boolean; buildingBlock: DocumentBuildingBlock }>();

    // Please keep alphabetical order.
    public placeholdersOfBlocksWithCustomOptions: string[] = [
        'BeteiligteBewertung',
        'BeteiligteHaftpflichtschaden',
        'BeteiligteKaskoschaden',
        'Bewertung',
        'Brieffenster',
        'BrieffensterRechnung',
        'DeckblattMitFoto',
        'DeckblattOhneFoto',
        'DeckblattBewertung',
        'Fotoanlage',
        'FotoanlageZweispaltig',
        'Lackschichtdicke',
        'Reparaturkostenkalkulation',
        'Werkstattkostensätze',
        'Wiederbeschaffungswert',
        'ZusammenfassungHaftpflichtschaden',
        'ZusammenfassungKaskoschaden',
        'ZusammenfassungKurzgutachten',
        'ZusammenfassungReparaturbestätigung',
        'ZusammenfassungWerkstattinformation',
    ];

    private templateDeletionRequired: boolean;

    public hasDocxPartial: boolean = false;

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        this.determineIfDocxPartialExists();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    protected get claimantLabel(): string {
        switch (this.buildingBlock?.placeholder) {
            case 'BeteiligteBewertung':
                return 'Auftraggeber/Leasingnehmer';
            case 'BeteiligteHaftpflichtschaden':
                return 'Anspruchsteller/Kunde';
            case 'BeteiligteKaskoschaden':
                return 'Versicherungsnehmer';
        }
        return 'Kunde';
    }

    //*****************************************************************************
    //  Page Break
    //****************************************************************************/
    public togglePageBreakBefore(): void {
        this.buildingBlock.config.pageBreakBefore = !this.buildingBlock.config.pageBreakBefore;
    }

    public togglePageBreakAfter(): void {
        this.buildingBlock.config.pageBreakAfter = !this.buildingBlock.config.pageBreakAfter;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Page Break
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keep Next Paragraph Connected
    //****************************************************************************/
    public async setKeepNextParagraphConnectedDefault(value: boolean): Promise<void> {
        this.team.preferences.documentBuildingBlocks.keepNextParagraphConnected = value;
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keep Next Paragraph Connected
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Compression
    //****************************************************************************/
    public keepPhotoCompressionBetween0And99() {
        if (this.buildingBlock.config.photoCompression > 99) {
            this.buildingBlock.config.photoCompression = 99;
        } else if (this.buildingBlock.config.photoCompression < 0) {
            this.buildingBlock.config.photoCompression = 0;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Compression
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog(): void {
        this.close.emit({
            buildingBlock: this.buildingBlock,
            templateDeletionRequired: this.templateDeletionRequired,
        });
    }

    public emitBuildingBlockChange(): void {
        this.buildingBlockChange.emit(this.buildingBlock);
        this.templateDeletionRequired = true;
    }

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.close.emit();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DOCX Partials
    //****************************************************************************/
    public downloadDocxPartial(): void {
        this.documentPartialService.get(this.buildingBlock._id).subscribe({
            next: (response) => {
                this.downloadService.downloadBlobResponseWithHeaders(response);
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Download fehlgeschlagen',
                        body: `Bitte versuche es erneut. Falls der Fehler wieder auftritt, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                    },
                });
            },
        });
    }

    private async uploadDocxPartial(documentPartialUploadFile: File): Promise<void> {
        try {
            await this.documentPartialService.create(this.buildingBlock._id, documentPartialUploadFile);
            // Create a hash from the current datetime so when rendering the document template backend-autoixpert knows that it needs to get this document partial DOCX file.
            this.buildingBlock.customPartialHash = simpleHash(new Date().toISOString());
            this.emitBuildingBlockChange();
            this.determineIfDocxPartialExists();
            this.toastService.success(`Upload erfolgreich`);
        } catch (error) {
            this.toastService.error(
                'Upload fehlgeschlagen',
                `Bitte versuche es erneut. Falls der Fehler wieder auftritt, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
            );
            console.error('UPLOADING_DOCUMENT_PARTIAL_FAILED', { error });
        }
    }

    public async onChangeDocumentPartialUploadFile(eventTarget: EventTarget, documentPartialUploadFile: File) {
        const fileInput: HTMLInputElement = eventTarget as any;

        // If the mime type is not a DOCX, remove the file from the queue
        if (
            documentPartialUploadFile.type &&
            documentPartialUploadFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            console.error('The given file is not a DOCX file.', documentPartialUploadFile);
            this.toastService.error('Bitte lade eine DOCX-Datei hoch', '');
            fileInput.value = null;
            return;
        }

        // Warn about file size
        if (documentPartialUploadFile.size > 4000 * 1024) {
            this.toastService.error(
                'Datei zu groß',
                'Bitte lade nur Vorlagen unter 4 MB hoch. Versuche dazu, Logos und andere Grafiken zu verkleinern oder als JPG abzuspeichern, das kleinere Dateigrößen als eine PNG bietet.\n\nManchmal hilft es auch, die Grafik einmal zu entfernen und frisch in Word einzufügen.',
            );
            fileInput.value = null;
        }
        await this.uploadDocxPartial(documentPartialUploadFile);

        // Allow uploading the same file again in case of an upload error. If the input value was not reset, no change event would be fired when the same (but updated) file is selected.
        fileInput.value = null;
    }

    public async removeDocxPartial() {
        try {
            await this.documentPartialService.remove(this.buildingBlock._id);
            this.buildingBlock.customPartialHash = null;
            this.determineIfDocxPartialExists();
            this.emitBuildingBlockChange();
        } catch (error) {
            this.toastService.error(
                'Löschen fehlgeschlagen',
                `Bitte versuche es erneut. Falls der Fehler wieder auftritt, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
            );
            console.error('REMOVING_DOCUMENT_PARTIAL_FAILED', { error });
        }
    }

    private determineIfDocxPartialExists(): void {
        this.hasDocxPartial =
            !!this.buildingBlock.customPartialHash ||
            enhancedDocumentBuildingBlocks.includes(this.buildingBlock.placeholder);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DOCX Partials
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                event.stopPropagation();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.closeDialog();
                    event.stopPropagation();
                }
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

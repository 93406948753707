import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { OfflineSyncWithInMemoryCacheServiceBase } from '../libraries/database/offline-sync-with-in-memory-cache.service-base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class DocumentOrderConfigService extends OfflineSyncWithInMemoryCacheServiceBase<DocumentOrderConfig> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'documentOrderConfig',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            objectStoreAndIndexMigrations: undefined,
            recordMigrations: [],
        });
    }

    public findDefault(): Promise<DocumentOrderConfig[]> {
        return this.httpClient.get<DocumentOrderConfig[]>(`/api/v0/defaultDocumentOrderConfigs`).toPromise();
    }

    /**
     * Remove all documents from all reports that are based on the given (custom) DocumentOrderConfig.
     * This is necessary, when custom documents got deleted and need to be removed from all reports.
     */
    public async removeCustomDocumentOrderConfigFromAllReports(
        documentOrderConfigId: DocumentOrderConfig['_id'],
    ): Promise<DocumentOrderConfigCleanupResponse> {
        return await this.httpClient
            .post<DocumentOrderConfigCleanupResponse>('/api/v0/documentOrderConfigCleanup', {
                documentOrderConfigId,
            })
            .toPromise();
    }
}

export interface DocumentOrderConfigCleanupResponse {
    numberOfReportsFromWhichDocumentOrderConfigWasRemoved: number;
}

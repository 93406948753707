import { TextFieldModule } from '@angular/cdk/text-field';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { PlaceholderAutocompleteInput } from './placeholder-autocomplete-input.component';

@NgModule({
    imports: [
        FormsModule,
        TextFieldModule,
        BrowserModule,
        MatLegacyAutocompleteModule,
        MatLegacyFormFieldModule,
        SharedDirectivesModule,
        MatLegacyInputModule,
        MatLegacyTooltipModule,
    ],
    declarations: [PlaceholderAutocompleteInput],
    exports: [PlaceholderAutocompleteInput],
})
export class PlaceholderAutocompleteInputModule {}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Report } from '@autoixpert/models/reports/report';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { paintTypes } from '../car-condition/car-condition.component';

@Component({
    selector: 'oldtimer-valuation-large-condition',
    templateUrl: 'oldtimer-valuation-large-condition.component.html',
    styleUrls: ['oldtimer-valuation-large-condition.component.scss'],
})
export class OldtimerValuationLargeConditionComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private reportDetailsService: ReportDetailsService,
        public userPreferences: UserPreferencesService,
        private toastService: ToastService,
    ) {}

    public report: Report;
    private subscriptions: Subscription[] = [];

    public paintTypes: string[] = paintTypes;
    public filteredPaintTypes: string[] = [];
    public filteredSpecialEquipment: string[] = [];
    public specialEquipment: string[] = [
        'Elektrische Fensterheber',
        'H-Kennzeichen',
        'Hardtop',
        'Klimaanlage',
        'Lederausstattung',
        'Radio',
        'Rechtslenker',
        'Schiebedach',
        'Sonderräder',
        'Sportsitze',
    ];
    @ViewChild('specialEquipmentAutocomplete', { static: false }) specialEquipmentAutocomplete: MatAutocomplete;

    // Car condition descriptions
    public selectedDescriptionTab: 'aboutCar' | 'exterior' | 'interior' | 'engine' | 'underbody' = 'aboutCar';

    ngOnInit() {
        const reportSubscription = this.route.parent.params
            .pipe(switchMap((params) => this.reportDetailsService.get(params['reportId'])))
            .subscribe((report) => {
                this.report = report;
            });

        this.subscriptions.push(reportSubscription);
    }

    /**
     * Filter paint types autocomplete. Case-insensitive. Search terms are not split on spaces.
     * @param searchTerm
     */
    public filterPaintTypesAutocomplete(searchTerm: string) {
        if (!searchTerm) {
            this.filteredPaintTypes = [...this.paintTypes];
            return;
        }

        searchTerm = searchTerm.toLowerCase();

        this.filteredPaintTypes = this.paintTypes.filter((paintType) => paintType.toLowerCase().includes(searchTerm));
    }

    //*****************************************************************************
    //  Sepcial Equipment
    //****************************************************************************/
    public filterSpecialEquipmentAutocomplete(searchTerm: string): void {
        this.filteredSpecialEquipment = [...this.specialEquipment];

        if (!searchTerm) return;

        const searchTerms: string[] = searchTerm.toLowerCase().split(' ');

        // All search terms must be included in the equipment name to match
        this.filteredSpecialEquipment = this.specialEquipment
            // Don't show items already selected
            .filter((equipment) => !this.report.car.specialEquipment.includes(equipment))
            .filter((equipment) => searchTerms.every((searchTerm) => equipment.includes(searchTerm)));
    }

    /**
     * On blur or hitting certain keys (enter, comma, space), trigger adding a device as a chip.
     *
     * @param chipInputEvent
     */
    public enterSpecialEquipment(chipInputEvent: MatChipInputEvent) {
        if (this.specialEquipmentAutocomplete.isOpen) {
            return;
        }

        const inputValue = (chipInputEvent.value || '').trim();

        this.addSpecialEquipment(inputValue);

        // Clear input
        this.clearSpecialEquipmentInputAndResetAutocomplete(chipInputEvent.input);
    }

    /**
     * Add a device as a chip unless the same device has already been added.
     *
     * @param specialEquipment
     */
    public addSpecialEquipment(specialEquipment: string): void {
        // Don't add duplicates
        if (this.report.car.specialEquipment.includes(specialEquipment)) {
            this.toastService.info(`Wert '${specialEquipment}' bereits vorhanden`);
            return;
        }

        // Add chip if non-empty
        if (specialEquipment) {
            this.report.car.specialEquipment.push(specialEquipment);
        }
    }

    /**
     * Select autocomplete option for special equipment.
     * @param event
     * @param inputElement
     * @param autocompleteTrigger
     */
    public selectSpecialEquipmentFromAutocomplete(
        event: MatAutocompleteSelectedEvent,
        inputElement: HTMLInputElement,
        autocompleteTrigger: MatAutocompleteTrigger,
    ): void {
        this.addSpecialEquipment(event.option.value);
        this.clearSpecialEquipmentInputAndResetAutocomplete(inputElement);
        // Re-open panel
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 0);
    }

    /**
     * Clear the chip input and re-filter its autocomplete.
     * @param input
     */
    public clearSpecialEquipmentInputAndResetAutocomplete(input: HTMLInputElement): void {
        input.value = '';
        this.filterSpecialEquipmentAutocomplete('');
    }

    public removeSpecialEquipment(equipment: string): void {
        this.report.car.specialEquipment.splice(this.report.car.specialEquipment.indexOf(equipment), 1);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sepcial Equipment
    /////////////////////////////////////////////////////////////////////////////*/

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    //*****************************************************************************
    //  Condition Description Tabs
    //****************************************************************************/
    public selectDescriptionTab(tab: this['selectedDescriptionTab']): void {
        this.selectedDescriptionTab = tab;
    }

    // TODO Implement actual completion feedback
    public aboutCarPartiallyComplete(): boolean {
        return false;
    }

    public exteriorPartiallyComplete(): boolean {
        return false;
    }

    public interiorPartiallyComplete(): boolean {
        return false;
    }

    public enginePartiallyComplete(): boolean {
        return false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Condition Description Tabs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Average Grade
    //****************************************************************************/
    public getAverageGrade(): string {
        return '2+';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Average Grade
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save Methods
    //****************************************************************************/
    /**
     * Save reports to the server.
     */
    public async saveReport(): Promise<void> {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Methods
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Component Clean-up
    //****************************************************************************/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Component Clean-up
    /////////////////////////////////////////////////////////////////////////////*/
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AXRESTClient {
    private static baseUrl = '/api/v0'; // TODO Let this value depend on a config file so developers can define how they want to access the node.js API (either through a webserver or directly

    constructor(private httpClient: HttpClient) {}

    /**
     * Performs a request with `get` http method.
     */
    public get(url: string, options?: RequestOptions): Promise<any> {
        return this.httpClient.get(AXRESTClient.marryToBaseUrl(url), options).toPromise();
    }

    /**
     * Performs a request with `post` http method.
     */
    public post(url: string, body: any, options?: RequestOptions): Promise<any> {
        return this.httpClient.post(AXRESTClient.marryToBaseUrl(url), body, options).toPromise();
    }

    /**
     * Performs a request with `put` http method.
     */
    public patch(url: string, body: any, options?: RequestOptions): Promise<any> {
        return this.httpClient.patch(AXRESTClient.marryToBaseUrl(url), body, options).toPromise();
    }

    /**
     * Performs a request with `delete` http method.
     */
    public delete(url: string, options?: RequestOptions): Promise<any> {
        return this.httpClient.delete(AXRESTClient.marryToBaseUrl(url), options).toPromise();
    }

    public static marryToBaseUrl(url: string) {
        return this.baseUrl + url;
    }

    /**
     * No matter if the server response contains an error in HTML-format (nginx) or JSON (Node.js), extract a JS object.
     * @param response
     * @return {any}
     */
    // static extractErrorFromResponse(response) {
    //     let error;
    //
    //     // HTML errors are generated by nginx, e.g. if the Node.js servers are unreachable
    //     if (response.headers.get('content-type')
    //             .includes('text/html')) {
    //         error = {
    //             status     : response.status,
    //             statusText : response.statusText,
    //             message    : response.text(),
    //             code       : response.status === 502 ? "SERVER_UNREACHABLE" : "UNKNOWN",
    //             url        : response.url,
    //         };
    //     }
    //     else {
    //         error = response.json();
    //     }
    //
    //     return error;
    // }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sync Process Registry
    /////////////////////////////////////////////////////////////////////////////*/
}

interface RequestOptions {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    params?: {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

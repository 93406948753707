import { ValuepilotEquipmentOptions } from '@autoixpert/models/reports/market-value/valuepilot-market-analysis';

export function translateValuepilotEquipmentOption(
    equipmentOptionEnglish: keyof ValuepilotEquipmentOptions,
): ValuepilotEquipmentOptionGerman {
    switch (equipmentOptionEnglish) {
        case 'airconditioningAutomatic':
            return 'Klimaautomatik';
        case 'airconditioningManual':
            return 'Klima (manuell)';
        case 'leatherSeats':
            return 'Lederausstattung';
        case 'xenonHeadlights':
            return 'Xenon';
        case 'hitch':
            return 'Anhängerkupplung';
        case 'particulateFilter':
            return 'Rußpartikelfilter';
        case 'headupDisplay':
            return 'Head-Up Display';
        case 'navigationSystem':
            return 'Navigation';
        case 'sunRoof':
            return 'Schiebedach';
    }
}

export type ValuepilotEquipmentOptionGerman =
    | 'Klimaautomatik'
    | 'Klima (manuell)'
    | 'Lederausstattung'
    | 'Xenon'
    | 'Anhängerkupplung'
    | 'Rußpartikelfilter'
    | 'Head-Up Display'
    | 'Navigation'
    | 'Schiebedach';

import { IsoDate } from '../../../lib/date/iso-date.types';
import { generateId } from '../../../lib/generate-id';
import { User } from '../../user/user';

export class Visit {
    constructor(visitTemplate?: Partial<Visit>) {
        if (visitTemplate) {
            Object.assign(this, visitTemplate);
        }
    }

    _id: string = generateId();
    locationName: string = null;
    street: string = null; // Straße
    zip: string = null; // PLZ
    city: string = null; // Ort des Treffens
    date: IsoDate = null; // Datum des Besuchs
    time: string = null; // Uhrzeit des Besuchs
    carAssemblyState: string = null; // Fahrzeugzustand (zerlegt|unzerlegt)
    sameStateAsInAccident: boolean = null;
    conditions: string = null; // Besichtigungsbedingungen
    auxiliaryDevices: string[] = [];
    comment: string = null;
    assessor: User['_id'] = null; // Sachverständiger (User ID)
    assessorWasPresent: boolean = true; // Gutachter war anwesend. Da das der Normalfall ist, wird es sofort aktiviert.
    claimantWasPresent: boolean = null; // Geschädigter war anwesend
    garageTechnicianWasPresent: boolean = null; // Werkstattmeister war anwesend
    ownerOfClaimantsCarWasPresent: boolean = null; // Halter des geschädigten Fahrzeugs war anwesend
    driverOfClaimantsCarWasPresent: boolean = null; // Führer des geschädigten Fahrzeugs war anwesend
    otherPeoplePresent: string[] = []; // Andere Anwesende
}

import { Injectable } from '@angular/core';
import { CounterPattern } from '@autoixpert/lib/counters/counter-pattern';
import { matchAssociatedOfficeLocation } from '@autoixpert/lib/counters/match-associated-office-location';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Report } from '@autoixpert/models/reports/report';
import { ReportInvoiceNumberConfig } from '@autoixpert/models/reports/report-invoice-number-config';
import { ReportTokenConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { getReportInvoiceNumberPatternWithoutSubCounter } from '../libraries/preferences/get-report-invoice-number-pattern-without-sub-counter';
import { FieldGroupConfigService } from './field-group-config.service';
import { InvoiceNumberOrReportTokenCounterService } from './invoice-number-or-report-token-counter.service';
import { LoggedInUserService } from './logged-in-user.service';
import { ReportTokenService } from './report-token.service';
import { ReportService } from './report.service';
import { TemplatePlaceholderValuesService } from './template-placeholder-values.service';
import { TutorialStateService } from './tutorial-state.service';
import { UserService } from './user.service';

@Injectable()
export class InvoiceNumberService {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private counterService: InvoiceNumberOrReportTokenCounterService,
        private tutorialStateService: TutorialStateService,
        private reportTokenService: ReportTokenService,
        private invoiceNumberOrReportTokenCounterService: InvoiceNumberOrReportTokenCounterService,
        private reportService: ReportService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private fieldGroupConfigService: FieldGroupConfigService,
    ) {}

    public getInvoiceNumberConfig(officeLocationId?: OfficeLocation['_id']) {
        const team: Team = this.loggedInUserService.getTeam();

        const invoiceNumberConfig = matchAssociatedOfficeLocation(
            team.invoicing.invoiceNumberConfigs,
            officeLocationId,
        );
        if (!invoiceNumberConfig) {
            throw new AxError({
                code: 'NO_MATCHING_INVOICE_NUMBER_CONFIG',
                message: `The invoice number config for the report and location could not be found. Check if a invoice number config exists for the office location.`,
            });
        }

        return invoiceNumberConfig;
    }

    /**
     * The invoice number consists of the counter (which will be queried from the server) and the configured pattern.
     */
    public async generateInvoiceNumber({
        officeLocationId,
        responsibleAssessorId,
        report,
        reportToken,
    }: {
        officeLocationId: OfficeLocation['_id'];
        /**
         * Either
         * - the assessor of an associated report or
         * - the logged-in user in case of an invoice without a report.
         */
        responsibleAssessorId?: string;
        report?: Report;
        /**
         * Optional report token that should be used to generate an invoice number based on a report token (otherwise the token from the given report is used)
         */
        reportToken?: string;
    }): Promise<string> {
        const user: User = this.loggedInUserService.getUser();
        if (!user) {
            return;
        }

        let basedOnReportToken: boolean = false,
            reportTokenConfig: ReportTokenConfig,
            reportTokenForInvoice: string,
            originalReport: Report;
        if (report) {
            reportTokenConfig = this.reportTokenService.getReportTokenConfig(report.officeLocationId);

            // Check if we need a separate counter per report token
            basedOnReportToken =
                reportTokenConfig.syncWithInvoiceCounter && reportTokenConfig.leadingCounter === 'reportToken';

            reportTokenForInvoice = reportToken ?? report.token;

            if (basedOnReportToken) {
                if (!report.invoiceNumberConfig) {
                    // Initialize the invoice config for the report (used to keep the same settings for a report even if the global settings changed)
                    report.invoiceNumberConfig = new ReportInvoiceNumberConfig({
                        reportInvoiceNumberPattern: reportTokenConfig.reportInvoiceNumberPattern,
                        startReportInvoiceSubCounterWithSecondInvoice:
                            reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice,
                        useCancellationInvoiceSuffixForReportInvoices:
                            reportTokenConfig.useCancellationInvoiceSuffixForReportInvoices,
                        reportInvoiceCancellationSuffix: reportTokenConfig.reportInvoiceCancellationSuffix,
                        separateCounterForAmendmentReportsAndInvoiceAudits:
                            reportTokenConfig.separateCounterForAmendmentReportsAndInvoiceAudits,
                        count: 0,
                    });
                }

                // If we have report based invoice numbers, check if the report is an amendment or invoice audit.
                // Then check if these should generate their own invoice number or it should be based on the original report.
                const originalReportId = report.originalReportId || report.invoiceAudit?.reportId;
                const useTokenAndCounterFromOriginalReport =
                    !!originalReportId &&
                    !report.invoiceNumberConfig.separateCounterForAmendmentReportsAndInvoiceAudits;

                if (useTokenAndCounterFromOriginalReport) {
                    originalReport = await this.reportService.get(originalReportId);
                    reportTokenForInvoice = originalReport.token;

                    if (!originalReport.invoiceNumberConfig) {
                        // If the amendment initialized an invoiceNumberConfig but the original report has none,
                        // copy the settings from the amendment to the original report
                        originalReport.invoiceNumberConfig = report.invoiceNumberConfig;
                    }
                }
            }

            if (basedOnReportToken && !reportTokenForInvoice) {
                // To create an invoice number based on the report token, the user must first select the report token
                throw new AxError({
                    code: 'REPORT_TOKEN_MISSING',
                    message:
                        'The invoice number could not be derived from the report token because the report token is empty.',
                });
            }
        }

        const invoiceNumberConfig = this.getInvoiceNumberConfig(officeLocationId);
        const invoiceNumberCount: number = basedOnReportToken
            ? await this.nextInvoiceNumberForInvoiceBasedOnReport(originalReport ?? report)
            : await this.counterService.getAndIncreaseCount(invoiceNumberConfig._id);

        let invoiceNumberPattern = new CounterPattern(
            basedOnReportToken ? report.invoiceNumberConfig.reportInvoiceNumberPattern : invoiceNumberConfig.pattern,
        );

        if (
            basedOnReportToken &&
            invoiceNumberCount === 0 &&
            report.invoiceNumberConfig.startReportInvoiceSubCounterWithSecondInvoice
        ) {
            // Omit the sub counter if necessary (so that the first invoice number starts without a counter like "GA-009"
            // instead of "GA-009/1"
            invoiceNumberPattern = new CounterPattern(
                getReportInvoiceNumberPatternWithoutSubCounter(report.invoiceNumberConfig),
            );
        }

        /**
         * Get the responsible assessor in case the token contains initials.
         * A repair confirmation could have a different assessor than report, provide override.
         */
        let associatedUser: User;
        if (invoiceNumberPattern.includesUserInitials()) {
            if (responsibleAssessorId) {
                associatedUser = this.userService.getTeamMemberFromCache(responsibleAssessorId);
            }
            // If no responsible assessor is provided, use the logged-in user's credentials. E.g. if a manual invoice without a report is created.
            else {
                associatedUser = this.loggedInUserService.getUser();
            }
        }

        /*
         * Get the officeLocation in case the token contains office location initials.
         */
        let associatedOfficeLocation: OfficeLocation;
        if (invoiceNumberPattern.includesOfficeLocationInitials()) {
            const team = this.loggedInUserService.getTeam();
            associatedOfficeLocation = team.officeLocations.find(
                (officeLocation) => officeLocation._id === officeLocationId,
            );
        }

        this.tutorialStateService.markUserTutorialStepComplete('invoiceNumberRetrieved');

        if (report) {
            const placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
                reportId: report._id,
            });
            const fieldGroupConfigs =
                await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

            return invoiceNumberPattern.fill(invoiceNumberCount, {
                reportType: report.type,
                associatedUser,
                associatedOfficeLocation,
                reportToken: reportTokenForInvoice,
                reportId: report._id,
                placeholderValues,
                fieldGroupConfigs,
            });
        } else {
            return invoiceNumberPattern.fill(invoiceNumberCount, {
                associatedUser,
                associatedOfficeLocation,
                reportToken: reportTokenForInvoice,
            });
        }
    }

    /**
     * In case the invoice number is based on the report token (separate counter from 1 for each report)
     * this function determines the next counter value.
     */
    private async nextInvoiceNumberForInvoiceBasedOnReport(report: Report): Promise<number> {
        // Save the report and wait for the result, so that the invoiceNumberConfig is available on the server (for generating the next invoice number)
        await this.reportService.put(report, { waitForServer: true });

        const currentCount =
            await this.invoiceNumberOrReportTokenCounterService.getAndIncreaseReportBasedInvoiceNumberCount(report._id);

        // If the user wants the first invoice to skip the counter, we need to decrease the count for all following invoices
        return currentCount + (report.invoiceNumberConfig.startReportInvoiceSubCounterWithSecondInvoice ? 0 : 1);
    }

    /**
     * Check if the invoice number of the given report is the latest created with the respective report token counter.
     */
    async isInvoiceNumberLatest(report: Report): Promise<boolean> {
        const counterConfig = this.getInvoiceNumberConfig(report.officeLocationId);
        const currentCounter = await this.invoiceNumberOrReportTokenCounterService.get(counterConfig._id);

        // Find the index of the counter within the report token by searching for the placeholder {###} (can contain one or more hash characters)
        // Only escaping the first curly bracelet with backslash is necessary
        const counterPlaceholderRegex = /\{#+}/;

        const counterInInvoiceExecResult: RegExpExecArray = counterPlaceholderRegex.exec(counterConfig.pattern);

        // There may be no counter in the invoice number pattern (assessors increase their invoice numbers manually)
        const match = counterInInvoiceExecResult?.[0];

        if (match) {
            // Count the number of curly bracelets in the pattern. We need them to find the index of
            // the counter in the actual invoice number (which does not include the curly bracelets anymore)
            const numberOfCurlyBraceletsBeforeCounter = (
                counterConfig.pattern.substring(0, counterInInvoiceExecResult.index).match(/[{}]/g) || []
            ).length;

            // Find the position of the invoice counter within the invoice number
            const counterStartIndex = counterInInvoiceExecResult.index - numberOfCurlyBraceletsBeforeCounter;
            const counterEndIndex = counterStartIndex + (match.length - 1); // -1 to exclude the closing curly bracelet

            // Get the counter
            const extractedCounter = report.feeCalculation?.invoiceParameters?.number?.substring(
                counterStartIndex,
                counterEndIndex,
            );

            // And parse the invoice number
            const parsedCounter = parseInt(extractedCounter, 10);

            // So we can finally determine, if it is the latest number generated
            return parsedCounter === currentCounter.count;
        }

        // If the invoice number pattern does not contain the counter placeholder -> we can't determine if it is the last number or not
        return false;
    }
}

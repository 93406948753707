<div id="recipients-container" class="card">
    <div id="recipients-list">
        <div
            class="recipient-container"
            *ngFor="let recipient of recipients"
            [ngClass]="{ active: selectedRecipient === recipient }"
            (click)="selectRecipient(recipient)">
            <div
                class="role-icon-container"
                [ngClass]="{ 'received-message': recipient.receivedEmail || recipient.receivedLetter }"
                [matTooltip]="translateRecipientRole(recipient.role)">
                <img
                    [src]="'/assets/images/icons/' + recipient.role + '-white_16.png'"
                    [ngClass]="{ 'financial-services-provider': recipient.role === 'factoringProvider' }" />
                <img
                    class="checkmark checkmark-email"
                    *ngIf="recipient.receivedEmail"
                    src="/assets/images/icons/checkmark-blue_16.png"
                    matTooltip="hat E-Mail empfangen"
                    @fadeInAndOut />
                <img
                    class="checkmark checkmark-letter"
                    *ngIf="recipient.receivedLetter"
                    src="/assets/images/icons/sent-by-mail_16.png"
                    matTooltip="hat Brief empfangen"
                    @fadeInAndOut />
            </div>
        </div>
    </div>
</div>

import { PaintMaterialSurchargeUnit } from '../../models/contacts/garage-fee-set';

export function getAbsolutePaintMaterialSurcharge({
    materialSurchargeUnit,
    paintCostsWithoutSurcharge,
    applicablePaintMaterialSurchargeValue,
}: {
    materialSurchargeUnit: PaintMaterialSurchargeUnit;
    paintCostsWithoutSurcharge: number;
    /**
     * This is a percentage value, an index value (30% === 130 index) or a flat fee.
     */
    applicablePaintMaterialSurchargeValue: number;
}): number {
    switch (materialSurchargeUnit) {
        case 'flatFee':
            return applicablePaintMaterialSurchargeValue;
        case 'percent':
            return (paintCostsWithoutSurcharge * applicablePaintMaterialSurchargeValue) / 100;
        case 'materialIndex':
            return (paintCostsWithoutSurcharge * (applicablePaintMaterialSurchargeValue - 100)) / 100;
        /**
         * Since material points and units only exist within the DAT or Audatex, we have no way of knowing
         * them and return null for the paint material surcharge instead.
         */
        case 'materialPointsOrUnits':
            return null;
        default:
            return null;
    }
}

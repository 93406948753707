import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../abstract-services/api-error.abstract.service';
import { AxError } from '../../models/errors/ax-error';

/**
 * Handlers can either be defined as a plain object or as a function returning this plain object.
 */
export function resolveErrorHandlerObjectOrFunction(
    handlerOrHandlerCreator: ApiErrorHandler | ApiErrorHandlerCreator,
    error: AxError,
): ApiErrorHandler {
    if (typeof handlerOrHandlerCreator === 'function') {
        try {
            return (handlerOrHandlerCreator as ApiErrorHandlerCreator)(error);
        } catch (error) {
            console.error(
                `Error creating API Error Service handler object through function "${handlerOrHandlerCreator.name}".`,
                { error },
            );
            throw error;
        }
    }
    return handlerOrHandlerCreator;
}

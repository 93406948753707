<div class="card">
    <div id="card-header">
        <h3>Gemeldete Störung</h3>
        <img src="/assets/images/logos/dat-logo-no-text.png" />
    </div>
    <div id="card-body">
        <div *ngFor="let message of applicableMessages" class="notification-message">
            <div class="message-title">Produkt: {{ message.datProductName }}</div>
            <div *ngIf="message.message" [innerHtml]="message.message" class="message-body label"></div>
            <div *ngIf="!message.message" class="message-body label italic">Keine Details verfügbar.</div>
        </div>
        <div class="label">
            Die Probleme sind bei der DAT bekannt und es wird an einer Lösung gearbeitet. Probiere es später nochmal.
        </div>
    </div>
</div>

import { Tire } from '../../models/reports/car-identification/tire';

export function isTireInfoComplete(tire: TireStatusCheck): boolean {
    if (!tire) return false;

    return !!(tire.type && tire.treadInMm && tire.manufacturer && tire.season);
}

export function tireProgressStatus(tire: TireStatusCheck): string {
    if (!tire?.type && !tire.treadInMm && !tire.manufacturer && !tire.season) {
        return 'empty';
    }
    return isTireInfoComplete(tire) ? 'complete' : 'partial';
}

type TireStatusCheck = Pick<Tire, 'type' | 'treadInMm' | 'manufacturer' | 'season'>;

import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { findRecordById } from '@autoixpert/lib/arrays/find-record-by-id';
import { joinListToHumanReadableString } from '@autoixpert/lib/arrays/join-list-to-human-readable-string';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { matchConditions } from '@autoixpert/lib/document-building-blocks/match-conditions';
import { sortBuildingBlocksAccordingToTemplateConfig } from '@autoixpert/lib/document-building-blocks/sort-building-blocks-according-to-template-config';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { addMissingClaimantSignaturesOnPdfTemplateDocument } from '@autoixpert/lib/documents/add-missing-claimant-signatures-on-pdf-template-document';
import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { setupAllClaimantSignaturesOnBuildingBlockDocuments } from '@autoixpert/lib/documents/setup-all-claimant-signatures-on-building-block-documents';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { shouldPowerOfAttorneyDocumentBeVisible } from '@autoixpert/lib/signable-documents/should-power-of-attorney-document-be-visible';
import { getClaimantSignatureFileName } from '@autoixpert/lib/signature-pad/get-claimant-signature-file-name';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { DocumentMetadata, DocumentType, SignableDocumentType } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { CustomFeeSet } from '@autoixpert/models/reports/assessors-fee/custom-fee-set';
import { Report } from '@autoixpert/models/reports/report';
import { ClaimantSignature } from '@autoixpert/models/signable-documents/claimant-signature';
import { SignableDocument } from '@autoixpert/models/signable-documents/signable-document';
import { SignablePdfTemplateConfig } from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { bvskFeeTable2024 } from '@autoixpert/static-data/fee-tables/bvsk-fee-table-2024';
import { cgfFeeTable2023 } from '@autoixpert/static-data/fee-tables/cgf-fee-table-2023';
import { hukFeeTable2025 } from '@autoixpert/static-data/fee-tables/huk-fee-table-2025';
import { vksFeeTable2021 } from '@autoixpert/static-data/fee-tables/vks-fee-table-2021';
import { getDocumentsApiErrorHandlers } from 'src/app/shared/libraries/error-handlers/get-documents-api-error-handlers';
import { getRelativeDate } from 'src/app/shared/libraries/get-relative-date';
import { selectFeeSet } from 'src/app/shared/libraries/select-fee-set';
import { CustomFeeSetService } from 'src/app/shared/services/custom-fee-set.service';
import { DocumentOrderConfigService } from 'src/app/shared/services/document-order-config.service';
import { fadeInAndSlideAnimation } from '../../../../shared/animations/fade-in-and-slide.animation';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';
import { hasAccessRight } from '../../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { ClaimantSignatureFileService } from '../../../../shared/services/claimant-signature-file.service';
import { DocumentBuildingBlockService } from '../../../../shared/services/document-building-block.service';
import { DownloadService } from '../../../../shared/services/download.service';
import { FieldGroupConfigService } from '../../../../shared/services/field-group-config.service';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { SignablePdfTemplateConfigService } from '../../../../shared/services/signable-pdf-template-config.service';
import { TeamService } from '../../../../shared/services/team.service';
import { TemplatePlaceholderValuesService } from '../../../../shared/services/template-placeholder-values.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';
import { UserService } from '../../../../shared/services/user.service';

@Component({
    selector: 'customer-signatures-card',
    templateUrl: 'customer-signatures-card.component.html',
    styleUrls: ['customer-signatures-card.component.scss'],
    animations: [fadeInAndSlideAnimation(), runChildAnimations()],
})
export class CustomerSignaturesCardComponent implements OnInit, OnDestroy {
    // General properties
    user: User;
    team: Team;
    @Input() report: Report;

    trackById = trackById;

    public signableDocumentDownloadPending: boolean = false;
    public customerSignaturesDialogShown: boolean = false;
    public remoteSignatureDialogShown: boolean = false;
    public factoringProviderExists: boolean = false;

    // A few properties are required for choosing the right document setup.
    private signablePdfTemplateConfigs: SignablePdfTemplateConfig[];
    private placeholderValues: PlaceholderValues;
    private placeholderValueTree: PlaceholderValueTree;

    public standardSignableDocumentOrderConfigs: {
        [key in Exclude<SignableDocumentType, 'customDocument'>]: DocumentOrderConfig;
    } = {
        declarationOfAssignment: null,
        revocationInstruction: null,
        consentDataProtection: null,
        powerOfAttorney: null,
    };
    protected standardSignablePDFDocumentTitles: {
        declarationOfAssignment?: string;
        revocationInstruction?: string;
        consentDataProtection?: string;
        powerOfAttorney?: string;
    } = {};
    /**
     * When users upload PDF files for custom documents, they can assign a name for each of them. This map stores these custom names
     * so they can be displayed instead of the generic title of the document order config.
     */
    protected customSignablePdfDocumentTitles: Map<DocumentOrderConfig['_id'], string> = new Map();

    public customDocumentOrderConfigs: DocumentOrderConfig[] = [];

    public latestBvskTable = bvskFeeTable2024;
    public latestVksTable = vksFeeTable2021;
    public latestHukTable = hukFeeTable2025;
    public latestCgfTable = cgfFeeTable2023;

    private subscriptions: Subscription[] = [];

    constructor(
        private thisComponentElementRef: ElementRef<HTMLElement>,
        private reportDetailsService: ReportDetailsService,
        private toastService: ToastService,
        private teamService: TeamService,
        private loggedInUserService: LoggedInUserService,
        private httpClient: HttpClient,
        private apiErrorService: ApiErrorService,
        private downloadService: DownloadService,
        public userPreferences: UserPreferencesService,
        private dialog: MatDialog,
        private claimantSignatureFileService: ClaimantSignatureFileService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private signablePdfTemplateConfigService: SignablePdfTemplateConfigService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private customFeeSetService: CustomFeeSetService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private documentBuildingBlockService: DocumentBuildingBlockService,
        private userService: UserService,
    ) {}

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));
        this.determineIfFactoringProviderExists();

        const documentOrderConfigs =
            await this.documentOrderConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        for (const documentOrderConfig of documentOrderConfigs) {
            if (documentOrderConfig.type in this.standardSignableDocumentOrderConfigs) {
                this.standardSignableDocumentOrderConfigs[documentOrderConfig.type] = documentOrderConfig;
            } else if (
                documentOrderConfig.customDocumentConfig?.documentTypeGroup === 'signature' &&
                documentOrderConfig.customDocumentConfig?.availableInReportTypes.includes(this.report.type)
            ) {
                /**
                 * Through custom documents, assessors may let the customer sign documents that would
                 * never make it into autoiXpert's default documents, such as a garage order form.
                 */
                this.customDocumentOrderConfigs.push(documentOrderConfig);
            }
        }

        /**
         * If the query parameter "remoteSignature" exists, scroll this card component into view and open the required
         * signature dialog.
         */
        if (this.route.snapshot.queryParams.remoteSignature) {
            // Delete the query parameter
            this.router.navigate([], {
                queryParams: { remoteSignature: null },
                queryParamsHandling: 'merge',
                replaceUrl: true,
            });
            this.thisComponentElementRef?.nativeElement?.scrollIntoView({ behavior: 'auto', block: 'center' });

            // Open the remote signature dialog or signature dialog (based on status)
            if (this.getRemoteSignatureStatus() === 'signatures-submitted') {
                this.openCustomerSignaturesDialog();
            } else {
                this.openRemoteSignatureDialog();
            }
        }

        this.lookupSignablePDFDocumentTitles();
    }

    /**
     * In case the user has uploaded PDF files for the standard or custom signable documents, there might
     * also be custom titles for these. Let's fetch them, so we can display them instead of
     * the default title.
     */
    protected async lookupSignablePDFDocumentTitles(): Promise<void> {
        this.signablePdfTemplateConfigs ??= await this.loadSignablePdfTemplateConfigs();

        const signableDocumentsHaveCustomNames = this.signablePdfTemplateConfigs.some((config) => !!config.title);

        if (signableDocumentsHaveCustomNames) {
            this.standardSignablePDFDocumentTitles.declarationOfAssignment =
                await this.getSignablePdfTemplateTitle('declarationOfAssignment');
            this.standardSignablePDFDocumentTitles.revocationInstruction =
                await this.getSignablePdfTemplateTitle('revocationInstruction');
            this.standardSignablePDFDocumentTitles.consentDataProtection =
                await this.getSignablePdfTemplateTitle('consentDataProtection');
            await this.lookupPowerOfAttorneyDocumentTitle();

            // Custom Documents
            for (const customDocumentOrderConfig of this.customDocumentOrderConfigs) {
                const customTitle = await this.getSignablePdfTemplateTitle(
                    'customDocument',
                    customDocumentOrderConfig._id,
                );
                if (customTitle) {
                    this.customSignablePdfDocumentTitles.set(customDocumentOrderConfig._id, customTitle);
                }
            }
        }
    }

    private async lookupPowerOfAttorneyDocumentTitle() {
        this.standardSignablePDFDocumentTitles.powerOfAttorney =
            await this.getSignablePdfTemplateTitle('powerOfAttorney');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signable Documents
    //****************************************************************************/

    public openCustomerSignaturesDialog(): void {
        if (this.isReportLocked()) return;

        if (!this.report.signableDocuments.length) {
            this.toastService.info(
                'Kein Dokument gewählt',
                'Bitte wähle zuerst mindestens ein Dokument aus, das unterschrieben werden soll.',
            );
            return;
        }
        this.customerSignaturesDialogShown = true;
    }

    public closeCustomerSignaturesDialog(): void {
        this.customerSignaturesDialogShown = false;
    }

    private determineIfFactoringProviderExists(): void {
        this.factoringProviderExists = !!this.userPreferences.factoringProvider.contactPerson.organization;
    }

    //*****************************************************************************
    //  Add / Remove Signable Documents
    //****************************************************************************/
    /**
     * Add or remove one of the documents required to be signed by the claimant.
     */
    public async toggleSignableDocument(
        signableDocumentType: SignableDocumentType,
        customDocumentOrderConfigId?: string,
    ): Promise<void> {
        const signableDocument: SignableDocument = this.getSignableDocumentByType(
            signableDocumentType,
            customDocumentOrderConfigId,
        );

        if (this.isReportLocked()) return;

        const selectedDocumentTypesIndex = this.report.signableDocuments.indexOf(signableDocument);
        /**
         * Remove existing
         */
        if (selectedDocumentTypesIndex > -1) {
            await this.removeSignableDocument(signableDocumentType, customDocumentOrderConfigId);
        } else {
            /**
             * Add missing
             */
            await this.addSignableDocument(signableDocumentType, customDocumentOrderConfigId);
        }

        await this.saveReport();
    }

    public async addSignableDocument(signableDocumentType: SignableDocumentType, customDocumentOrderConfigId?: string) {
        let signableDocument: SignableDocument = this.report.signableDocuments.find(
            (signableDocument) =>
                signableDocument.documentType === signableDocumentType &&
                (!customDocumentOrderConfigId ||
                    customDocumentOrderConfigId === signableDocument.customDocumentOrderConfigId),
        );

        if (!signableDocument) {
            signableDocument = new SignableDocument({
                documentType: signableDocumentType,
                customDocumentOrderConfigId,
            });
            // Whether a signable document is active.
            this.report.signableDocuments.push(signableDocument);
        }

        // Add the document itself to the list of documents (visible in the print & transmission screen).
        const existingDocumentMetadata: DocumentMetadata = this.report.documents.find(
            (documentMetadata) =>
                documentMetadata.type === signableDocumentType &&
                (customDocumentOrderConfigId
                    ? documentMetadata.customDocumentOrderConfigId === customDocumentOrderConfigId
                    : true),
        );
        if (!existingDocumentMetadata) {
            await this.addDocumentMetadata(signableDocumentType, signableDocument, customDocumentOrderConfigId);
        }

        /**
         * No matter if the document was marked as to-be-signed or not earlier, perform setup steps such as choosing
         * the PDF or document building block-based document template.
         *
         * This may also update the chosen document template after selecting another lawyer.
         */
        await this.setupDocument(signableDocument);

        // Reload custom PDF title for lawyer
        this.lookupPowerOfAttorneyDocumentTitle();
    }

    private async removeSignableDocument(
        signableDocumentType: SignableDocumentType,
        customDocumentOrderConfigId?: string,
    ) {
        const signableDocument = this.getSignableDocumentByType(signableDocumentType, customDocumentOrderConfigId);

        const documentHasSignatures = signableDocument.signatures.some((claimantSignature) => claimantSignature.hash);
        if (documentHasSignatures) {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Unterschrift löschen?',
                        content: 'Dieses Dokument wurde bereits unterschrieben. Wirklich entfernen?',
                        confirmLabel: 'Unterschrift löschen',
                        cancelLabel: 'Besser nicht',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();

            if (!decision) return;
        }

        const { removedItem } = removeFromArray(signableDocument, this.report.signableDocuments);
        if (removedItem) {
            this.removeDocumentMetadata(signableDocument.documentType, signableDocument.customDocumentOrderConfigId);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add / Remove Signable Documents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Customer Signatures Remote Collection Dialog
    //****************************************************************************/
    public openRemoteSignatureDialog(): void {
        if (this.isReportLocked()) {
            // Show toast
            this.toastService.info('Gutachten abgeschlossen', 'Öffne es erneut, um Änderungen vorzunehmen.');
            return;
        }

        if (!this.report.signableDocuments.length) {
            this.toastService.info(
                'Kein Dokument gewählt',
                'Bitte wähle zuerst mindestens ein Dokument aus, das unterschrieben werden soll.',
            );
            return;
        }
        this.remoteSignatureDialogShown = true;
    }

    public closeRemoteSignatureDialog(): void {
        this.remoteSignatureDialogShown = false;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Customer Signatures Remote Collection Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Setup
    //****************************************************************************/
    /**
     * Depending on the document type (based on building blocks or signable PDF)
     * a different number of signatures must be initialized because PDF documents
     * can contain more than one signature.
     */
    private async setupDocument(signableDocument: SignableDocument) {
        // Try to find PDF template.
        const signablePdfTemplateConfig = await this.matchSignablePdfTemplateConfig(signableDocument);

        // Match found.
        if (signablePdfTemplateConfig) {
            addMissingClaimantSignaturesOnPdfTemplateDocument({
                signablePdfTemplateConfig,
                signableDocument,
            });
            signableDocument.pdfTemplateId = signablePdfTemplateConfig._id;
            return;
        }

        // Get all building blocks.
        let documentBuildingBlocks: DocumentBuildingBlock[];
        let documentOrderConfigs: DocumentOrderConfig[];

        //*****************************************************************************
        //  Building Blocks
        //****************************************************************************/
        try {
            documentBuildingBlocks = await this.documentBuildingBlockService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Textbausteine nicht geladen`,
                    body: `Bitte warte ein paar Minuten, bis sich alle Daten synchronisiert haben. Falls es nach 5 min bei guter Internetverbindung immer noch nicht funktioniert, kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Building Blocks
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Document Order Configs
        //****************************************************************************/
        try {
            documentOrderConfigs = await this.documentOrderConfigService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Dokument-Reihenfolgen nicht geladen`,
                    body: `Bitte warte ein paar Minuten, bis sich alle Daten synchronisiert haben. Falls es nach 5 min bei guter Internetverbindung immer noch nicht funktioniert, kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Document Order Configs
        /////////////////////////////////////////////////////////////////////////////*/

        const documentOrderConfig = documentOrderConfigs.find((documentOrderConfig) => {
            if (documentOrderConfig.type === 'customDocument') {
                return signableDocument.customDocumentOrderConfigId === documentOrderConfig._id;
            } else {
                return signableDocument.documentType === documentOrderConfig.type;
            }
        });

        // Sort blocks by the order structure of the document
        const sortedBlocks = sortBuildingBlocksAccordingToTemplateConfig(
            documentBuildingBlocks,
            documentOrderConfig.documentBuildingBlockIds,
        );

        /**
         * Each signable document needs at least one signature.
         * Initialize the first signature to ensure, missing signatures are displayed correct.
         *
         * If the document contains multiple signatures (declarationOfAssignment with fee table),
         * customer-signatures-dialog.component.ts will add additional signatures in the setupDocuments() function.
         */
        setupAllClaimantSignaturesOnBuildingBlockDocuments({
            signableDocument,
            buildingBlocks: sortedBlocks,
        });
    }

    /**
     * Signable PDF template configs are matched through
     * - document type
     * - conditions
     */
    private async matchSignablePdfTemplateConfig(
        signableDocument: SignableDocument,
    ): Promise<SignablePdfTemplateConfig> {
        /**
         * Always load the latest placeholder values. We used to cache, but this would not show the latest values if
         * e. g. a field (lawyer name) or a custom field changed.
         */
        this.placeholderValues = await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.report?._id,
            letterDocument: this.report.documents.find(
                (reportDocument) =>
                    reportDocument.type === signableDocument?.documentType &&
                    reportDocument.customDocumentOrderConfigId === signableDocument?.customDocumentOrderConfigId,
            ),
        });

        // Should never be cached in case custom fields changed.
        this.placeholderValueTree = getPlaceholderValueTree({
            fieldGroupConfigs: await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary(),
        });

        // Always get the freshest version of the
        this.signablePdfTemplateConfigs = await this.loadSignablePdfTemplateConfigs();

        const matchingConfigs: SignablePdfTemplateConfig[] = matchConditions({
            // Filter: must match tab + must not be archived.
            elementsWithConditions: this.signablePdfTemplateConfigs.filter(
                (signablePdfTemplateConfig) =>
                    // Match the type
                    signablePdfTemplateConfig.documentType === signableDocument.documentType &&
                    // A PDF template must be uploaded for the template to be considered.
                    signablePdfTemplateConfig.pdfUploaded &&
                    // Match the custom document ID if provided.
                    signablePdfTemplateConfig.customDocumentOrderConfigId ===
                        signableDocument.customDocumentOrderConfigId &&
                    // The template must not have been archived.
                    !signablePdfTemplateConfig.archivedAt,
            ),
            placeholderValues: this.placeholderValues,
            placeholderValueTree: this.placeholderValueTree,
        });

        // No matching config. The user must use the text block documents instead of PDFs.
        if (!matchingConfigs.length) return;

        // More than one config? Inform user and link to settings.
        if (matchingConfigs.length > 1) {
            this.toastService.warn(
                'Mehrere PDF-Dateien gefunden',
                `Es gibt mehrere PDF-Dateien, deren Bedingungen für das Dokument ${this.translateDocumentTitle(
                    signableDocument,
                )} zutreffen. Bitte gib in den <a href='/Einstellungen/Unterschreibbare-PDF-Dokumente'>Einstellungen</a> eindeutige Bedingungen an.\n\nEs wird die erste gefundene Datei verwendet.`,
            );
        }

        return matchingConfigs[0];
    }

    private async getSignablePdfTemplateTitle(
        documentType: DocumentType,
        customDocumentOrderConfigId?: DocumentOrderConfig['_id'],
    ): Promise<string> {
        this.placeholderValues ??= await this.templatePlaceholderValuesService.getReportValues({
            reportId: this.report?._id,
            letterDocument: this.report.documents.find((reportDocument) => reportDocument.type === documentType),
        });
        this.placeholderValueTree ??= getPlaceholderValueTree({
            fieldGroupConfigs: await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary(),
        });

        const matchingConfigs: SignablePdfTemplateConfig[] = matchConditions({
            // Filter: must match tab + must not be archived.
            elementsWithConditions: this.signablePdfTemplateConfigs.filter(
                (signablePdfTemplateConfig) =>
                    signablePdfTemplateConfig.documentType === documentType &&
                    !signablePdfTemplateConfig.archivedAt &&
                    (!customDocumentOrderConfigId ||
                        signablePdfTemplateConfig.customDocumentOrderConfigId === customDocumentOrderConfigId),
            ),
            placeholderValues: this.placeholderValues,
            placeholderValueTree: this.placeholderValueTree,
        });

        // No matching config. So we do not have a custom title
        if (!matchingConfigs.length) return;

        return matchingConfigs[0].title;
    }

    private async loadSignablePdfTemplateConfigs() {
        try {
            return await this.signablePdfTemplateConfigService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `PDF-Vorlagen nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Setup
    /////////////////////////////////////////////////////////////////////////////*/

    public isDocumentSigned(signableDocumentType: SignableDocumentType, customDocumentOrderConfigId?: string): boolean {
        const signableDocument: SignableDocument = this.getSignableDocumentByType(
            signableDocumentType,
            customDocumentOrderConfigId,
        );

        if (!signableDocument) return false;

        return !!signableDocument.signatures.find((claimantSignature) => claimantSignature.hash);
    }

    public getSignableDocumentByType(
        documentType: DocumentType,
        customDocumentOrderConfigId?: string,
    ): SignableDocument {
        return this.report.signableDocuments.find(
            (signableDocument) =>
                signableDocument.documentType === documentType &&
                (!customDocumentOrderConfigId ||
                    customDocumentOrderConfigId === signableDocument.customDocumentOrderConfigId),
        );
    }

    //*****************************************************************************
    //  Add / Remove Document Metadata
    //****************************************************************************/
    /**
     * Add a document placeholder to the array used in the Print & Send Component.
     */
    private async addDocumentMetadata(
        documentType: SignableDocumentType,
        signableDocument: SignableDocument,
        customDocumentOrderConfigId?: string,
    ): Promise<void> {
        let documentOrderConfig: DocumentOrderConfig;
        const signablePdfTemplateConfig = await this.matchSignablePdfTemplateConfig(signableDocument);

        if (documentType === 'customDocument') {
            documentOrderConfig = findRecordById(this.customDocumentOrderConfigs, customDocumentOrderConfigId);
        } else {
            documentOrderConfig = this.standardSignableDocumentOrderConfigs[documentType];
        }

        if (!documentOrderConfig) {
            this.toastService.error(
                'Dokument-Konfiguration nicht gefunden',
                `Die Konfiguration des Dokuments "${documentType}${
                    customDocumentOrderConfigId ? `:${customDocumentOrderConfigId}` : ''
                }" (technisch "DocumentOrderConfig") konnte nicht gefunden werden. Bitte prüfe deine Dokument-Konfiguration in den <a href="/Einstellungen/Textbausteine" target="_blank">Textbausteinen</a> und den <a href="/Einstellungen/Unterschreibbare-PDF-Dokumente" target="_blank">PDF-Kundenunterschriftsdokumenten</a>.`,
            );
            return;
        }

        const newDocument = new DocumentMetadata({
            type: documentType,
            title:
                signablePdfTemplateConfig?.title ||
                documentOrderConfig.titleLong ||
                translateDocumentType(documentType),
            customDocumentOrderConfigId,
            createdBy: this.user._id,
        });

        // If it needs to be added, insert it at the configured position or at the end
        addDocumentToReport({
            report: this.report,
            team: this.team,
            documentGroup: 'report',
            newDocument,
        });
    }

    /**
     * Remove a document from the array used in the Print & Send Component.
     */
    private removeDocumentMetadata(documentType: SignableDocumentType, customDocumentOrderConfigId?: string) {
        removeDocumentTypeFromReport({
            documentType,
            customDocumentOrderConfigId,
            report: this.report,
            documentGroup: 'report',
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add / Remove Document Metadata
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Preference: Which documents are active by default?
    //****************************************************************************/
    public async rememberDocumentSettings(): Promise<void> {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            this.toastService.info(
                `Nur für Nutzer mit Zugriffsrecht ${translateAccessRightToGerman('editTextsAndDocumentBuildingBlocks')} erlaubt`,
                'Bitte kontaktiere einen solchen Nutzer, ob er die Standarddokumente verändern kann.',
            );
            return;
        }

        this.team.preferences.factoringEnabled = this.report.feeCalculation.invoiceParameters.factoringEnabled;
        this.team.preferences.documentsToBeSigned = this.report.signableDocuments.map((signableDocument) => {
            if (signableDocument.documentType === 'customDocument') {
                return [signableDocument.documentType, signableDocument.customDocumentOrderConfigId].join('-');
            }

            return signableDocument.documentType;
        });

        try {
            await this.teamService.put(this.team);
            this.toastService.success('Auswahl gemerkt', 'Bei neuen Gutachten wird sie automatisch vorselektiert.');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Dokumente nicht gemerkt',
                    body: "Die zu unterschreibenden Dokumente konnten nicht gemerkt werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Preference: Which documents are active by default?
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Download PDF
    //****************************************************************************/
    public downloadSignableDocument(documentType: SignableDocumentType, customDocumentOrderConfigId?: string): void {
        // Find document by type and by custom document id if provided (only when the documentType is 'custom').
        const signableDocument = this.report.documents.find(
            (document) =>
                document.type === documentType &&
                (!customDocumentOrderConfigId || customDocumentOrderConfigId === document.customDocumentOrderConfigId),
        );

        // If the document is inactive, don't proceed to download it.
        if (!signableDocument) {
            this.toastService.info(
                'Dokument inaktiv',
                'Das Dokument kann erst heruntergeladen werden, wenn es aktiviert wurde.',
            );
            return;
        }

        this.signableDocumentDownloadPending = true;

        const urlDocumentType = documentType === 'customDocument' ? 'customSignatureDocument' : documentType;
        let downloadUrl = `/api/v0/reports/${this.report._id}/documents/${urlDocumentType}?format=pdf`;

        if (customDocumentOrderConfigId) {
            downloadUrl += `&customDocumentOrderConfigId=${customDocumentOrderConfigId}`;
        }

        this.httpClient
            .get(downloadUrl, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe({
                next: (response) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                    this.signableDocumentDownloadPending = false;
                },
                error: (error) => {
                    this.signableDocumentDownloadPending = false;
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ...getDocumentsApiErrorHandlers(this.router),
                            DOCUMENT_BUILDING_BLOCK_PLACEHOLDER_VALUE_TYPE_NOT_DEFINED: (error) => ({
                                title: 'Fehlerhafter Textbaustein',
                                body: `Eine Bedingung der Variante ${error.data.variantIndex + 1} des Textbausteins "${
                                    error.data.documentBuildingBlock.title
                                }" ist ungültig.<br><a href="/Einstellungen/Textbausteine?search=${
                                    error.data.documentBuildingBlock.title
                                }" target="_blank">Textbausteine bearbeiten</a>`,
                            }),
                        },
                        defaultHandler: {
                            title: 'Dokument nicht verfügbar',
                            body: "Bitte versuche es später noch einmal oder wende dich an die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Download PDF
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Title
    //****************************************************************************/
    public translateDocumentTitle({
        documentType,
        customDocumentOrderConfigId,
    }: {
        documentType: SignableDocumentType;
        customDocumentOrderConfigId?: string;
    }): string {
        if (documentType === 'customDocument') {
            const customDocumentConfig = this.customDocumentOrderConfigs.find(
                (customDocumentOrderConfig) => customDocumentOrderConfig._id === customDocumentOrderConfigId,
            );
            return customDocumentConfig?.titleShort || 'Unbekanntes Dokument';
        }

        return translateDocumentType(documentType);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Title
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Power of Attorney
    //****************************************************************************/
    /**
     * Whether the document switch icon should be visible on the signatures card.
     */
    public shouldPowerOfAttorneyDocumentBeVisible(): boolean {
        return shouldPowerOfAttorneyDocumentBeVisible(this.report);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Power of Attorney
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Transfer Customer Signatures
    //****************************************************************************/
    public areSomeDocumentsMissingSignatures(): boolean {
        const documentsWithSignatures = this.getDocumentsWithSignatures();

        // If no signatures have been provided, everything is ok.
        if (!documentsWithSignatures.length) return false;

        return !!this.getDocumentsMissingSignatures().length;
    }

    public getDocumentsWithSignatures(): SignableDocument[] {
        return this.getVisibleSignableDocuments().filter((signableDocument) =>
            signableDocument.signatures.some((signature) => signature.hash),
        );
    }

    public getDocumentsMissingSignatures(): SignableDocument[] {
        const visibleSignableDocuments = this.getVisibleSignableDocuments();
        return visibleSignableDocuments.filter((signableDocument) =>
            signableDocument.signatures.some((signature) => !signature.hash),
        );
    }

    public getDocumentsMissingSignaturesTooltip(): string {
        return joinListToHumanReadableString(
            this.getDocumentsMissingSignatures().map((signableDocument) => {
                return this.translateDocumentTitle(signableDocument);
            }),
        );
    }

    public getVisibleSignableDocuments(): SignableDocument[] {
        return this.report.signableDocuments.filter((signableDocument) => {
            let isDocumentVisible: boolean = true;
            if (
                signableDocument.documentType === 'powerOfAttorney' &&
                !shouldPowerOfAttorneyDocumentBeVisible(this.report)
            ) {
                isDocumentVisible = false;
            }

            return isDocumentVisible;
        });
    }

    /**
     * If documents are missing a signature while others have it, copy the signature to them.
     */
    public async transferCustomerSignatures() {
        const documentsWithSignatures: SignableDocument[] = this.getDocumentsWithSignatures();
        const documentsMissingSignatures: SignableDocument[] = this.getDocumentsMissingSignatures();

        const documentWithExistingSignature = documentsWithSignatures[0];

        // The hash determines if a signature has been provided.
        const existingSignature: ClaimantSignature = documentWithExistingSignature?.signatures.find(
            (signature) => signature.hash,
        );

        // Sanity Check
        if (!existingSignature) {
            this.toastService.error(
                'Keine Unterschrift gefunden',
                "Es existiert keine Unterschrift, um sie zu kopieren. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
            );
            return;
        }

        //*****************************************************************************
        //  Let user confirm
        //****************************************************************************/
        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Mit dem Kunden abgestimmt?',
                    content:
                        'Die Unterschrift solltest du nur kopieren, wenn das der explizite Wunsch des Kunden ist.\n\nDenk daran: Die Fälschung von Unterschriften ist strafbar.',
                    confirmLabel: 'Alles geklärt',
                    cancelLabel: 'Ich schaue nochmal...',
                },
            })
            .afterClosed()
            .toPromise();

        if (!decision) return;

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Let user confirm
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Download binary file
        //****************************************************************************/
        let existingSignatureBlob: Blob;
        const signatureFileName: string = getClaimantSignatureFileName({
            reportId: this.report._id,
            documentType: documentWithExistingSignature.documentType,
            customDocumentOrderConfigId: documentWithExistingSignature.customDocumentOrderConfigId,
            signatureElementId: existingSignature.signatureSlotId,
        });
        try {
            existingSignatureBlob = await this.claimantSignatureFileService.get(
                signatureFileName,
                existingSignature.hash,
            );
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Kundenunterschrifts-Datei fehlt`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Download binary file
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Set signature metadata
        //****************************************************************************/

        for (const incompleteDocument of documentsMissingSignatures) {
            for (const incompleteSignature of incompleteDocument.signatures) {
                // Don't overwrite existing signatures.
                if (incompleteSignature.hash) {
                    continue;
                }

                incompleteSignature.hash = existingSignature.hash;
                incompleteSignature.date = existingSignature.date;

                const signatureFileName: string = getClaimantSignatureFileName({
                    reportId: this.report._id,
                    documentType: incompleteDocument.documentType,
                    customDocumentOrderConfigId: incompleteDocument.customDocumentOrderConfigId,
                    signatureElementId: incompleteSignature.signatureSlotId,
                });

                try {
                    await this.claimantSignatureFileService.create({
                        _id: signatureFileName,
                        blob: existingSignatureBlob,
                        blobContentHash: incompleteSignature.hash,
                    });
                } catch (error) {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: `Unterschrift nicht hochgeladen`,
                            body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                        },
                    });
                }
            }
        }
        await this.saveReport();
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Set signature metadata
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /**
     * Hides the notice on missing signatures for this report only.
     * There's also a user preference for hiding it for good.
     */
    public closeTransferSignatureNoticeForThisReport() {
        this.report.transferMissingSignaturesNoticeClosed = true;
        this.saveReport();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Transfer Customer Signatures
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public userIsAdmin(): boolean {
        return isAdmin(this.user._id, this.team);
    }

    public isReportLocked(): boolean {
        return this.report && this.report.state === 'done';
    }

    public isQapterixpert(): boolean {
        return isQapterixpert();
    }

    public displayInfoToastForFileUpload() {
        const toast = this.toastService.info(
            'Hochladen unter Druck & Versand',
            'Lade unterschriebene Dokumente im Reiter <i>Druck & Versand</i> über das Dreipunktmenü eines Dokumentes hoch.',
        );
        toast.click.subscribe(() => this.router.navigate(['../Druck-und-Versand'], { relativeTo: this.route }));
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save to server
    //****************************************************************************/
    public async saveReport(): Promise<void> {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.warn('Gutachten nicht synchronisiert', 'Ist eine Internetverbindung vorhanden?');
            console.error('An error occurred while saving the report via the ReportService.', { error });
        }
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save to server
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fee Set Selection
    //****************************************************************************/
    public customFeeSets: CustomFeeSet[];

    public selectFeeSet({
        feeTableName,
        yearOfFeeTable,
        customFeeSet,
    }: {
        feeTableName: Report['feeCalculation']['selectedFeeTable'];
        yearOfFeeTable: number; // 2024
        customFeeSet?: CustomFeeSet;
    }) {
        if (this.isReportLocked()) {
            this.toastService.info('Gutachten abgeschlossen', 'Öffne es erneut, um Änderungen vorzunehmen.');
            return;
        }

        const { error } = selectFeeSet({
            feeTableName,
            yearOfFeeTable,
            report: this.report,
            teamPreferences: this.team.preferences,
            selectedCustomFeeSet: customFeeSet,
        });

        if (error === 'No custom fee set selected') {
            this.toastService.error(
                'Honorartabelle nicht verfügbar',
                'Die Tabelle konnte nicht geladen werden. Bitte versuche er später erneut oder kontaktiere die Hotline.',
            );
            return;
        }

        this.saveReport();
    }

    public getCustomFeeSets(): void {
        this.customFeeSetService.find().subscribe({
            next: (customFeeSets) => {
                this.customFeeSets = customFeeSets;
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Honorartabelle nicht geladen',
                        body: 'Die Tabelle konnte nicht geladen werden. Bitte versuche er später erneut oder kontaktiere die Hotline.',
                    },
                });
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fee Set Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Remote Signature Status
    //****************************************************************************/
    getRemoteSignatureStatus():
        | undefined
        | 'link-generated'
        | 'deadline-passed'
        | 'signatures-submitted'
        | 'signatures-checked' {
        if (this.report.remoteSignatureConfig?.claimantSignaturesChecked) {
            return 'signatures-checked';
        }

        if (this.report.remoteSignatureConfig?.claimantSubmittedAt) {
            return 'signatures-submitted';
        }

        if (
            this.report.remoteSignatureConfig?.signatureDeadlineAt &&
            new Date(this.report.remoteSignatureConfig.signatureDeadlineAt) < new Date()
        ) {
            return 'deadline-passed';
        }

        if (this.report.remoteSignatureConfig) {
            return 'link-generated';
        }
        return undefined;
    }

    getDeadlineSettingsDate() {
        const offsetAmount = this.userPreferences.remoteSignatureDeadlineOffsetAmount;
        const offsetUnit = this.userPreferences.remoteSignatureDeadlineOffsetUnit;
        return getRelativeDate(offsetAmount, offsetUnit).toDate();
    }

    resetRemoteSignatureDeadline() {
        // Update the deadline of the current report based on the new settings
        const date = this.getDeadlineSettingsDate();
        const hours = moment(this.report.remoteSignatureConfig.signatureDeadlineAt).hours();
        const minutes = moment(this.report.remoteSignatureConfig.signatureDeadlineAt).minutes();
        moment(date).hours(hours).minutes(minutes);

        this.report.remoteSignatureConfig.signatureDeadlineAt = this.getDeadlineSettingsDate().toISOString();
        this.saveReport();
    }

    markRemoteSignatureAsChecked() {
        this.report.remoteSignatureConfig.claimantSignaturesChecked = true;
        this.saveReport();
    }

    deleteRemoteSignature() {
        this.report.remoteSignatureConfig = undefined;
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remote Signature Status
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getCrashback24ErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        CRASHBACK24_USER_NOT_FOUND: {
            title: 'Benutzername ungültig',
            body: `crashback24 hat zurückgemeldet, dass der Benutzername nicht existiert. Bitte prüfe diesen in den <a href="/Einstellungen?section=crashback24-container" target="_blank">Einstellungen</a>.`,
        },
        LOGGING_INTO_CRASHBACK24_FAILED: {
            title: 'Zugangsdaten ungültig',
            body: `crashback24 hat zurückgemeldet, dass deine Zugangsdaten nicht stimmen. Bitte prüfe diese in den <a href="/Einstellungen?section=crashback24-container" target="_blank">Einstellungen</a>.`,
        },
        CREATING_CRASHBACK24_PROCESS_INSTANCE_FAILED: {
            title: `Vorgang nicht erstellt`,
            body: `Der crashback24-Vorgang konnte nicht angelegt werden. Bitte versuche es erneut. Sollte das nicht funktionieren, melde dich gerne bei der <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        RETRIEVING_DOCUMENTS_TO_CRASHBACK24_FAILED: {
            title: `Dokumente nicht auffindbar`,
            body: `Die an crashback24 zu versendenden Dokumente konnten nicht generiert werden. Bitte lade die Dokumente Gutachten, Rechnung and Abtretungserklärung einzeln herunter, um genauere Fehlermeldungen zu bekommen.`,
        },
        UPLOADING_DOCUMENTS_TO_CRASHBACK24_FAILED: {
            title: `Dokumente nicht übertragen`,
            body: `crashback24 konnte die Dokumente nicht annehmen. Das kann ein temporäres Problem sein. Versuche es erneut.`,
        },
        CRASHBACK24_METADATA_CHILD_NOT_FOUND: {
            title: `crashback24-Metadaten nicht gefunden`,
            body: `crashback24 hat möglicherweise etwas an der Schnittstellen-Struktur geändert, sodass der Export nicht mehr funktioniert. Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
    };
}

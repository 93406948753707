import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Report } from '@autoixpert/models/reports/report';
import { InvoiceNumberOrReportTokenCounter } from '@autoixpert/models/teams/invoice-number-or-report-token-counter';
import { NetworkStatusService } from './network-status.service';

@Injectable()
export class InvoiceNumberOrReportTokenCounterService {
    constructor(
        private httpClient: HttpClient,
        private networkStatusService: NetworkStatusService,
    ) {}

    /**
     * This subject is used to notify the app about changes to the counter.
     * In preferences overview, the invoice number counter may be used for the report token counter.
     * In this case, the reportTokenCounter component needs to be notified about changes to the invoice number counter.
     *
     * Since we have the server state on the server and this use case is not very common, we do not store all counter values locally.
     * The listening components are notified and have to fetch the counter value from the server.
     */
    private counterChangedSubject$ = new Subject<InvoiceNumberOrReportTokenCounter['_id']>();
    readonly counterChanged$ = this.counterChangedSubject$.asObservable();
    public listenOnChangedCounter(): Observable<InvoiceNumberOrReportTokenCounter['_id']> {
        return this.counterChanged$;
    }

    /**
     * This method is used when a new count should be generated.
     * The server will handle resetting the counter if necessary. See the property "resetCycle" for details.
     */
    public async getAndIncreaseCount(counterId: string, { retries }: { retries?: number } = {}): Promise<number> {
        if (!this.networkStatusService.isOnline()) {
            throw new AxError({
                code: 'REPORT_TOKEN_COUNTER_REQUIRES_INTERNET_CONNECTION',
                message: `Please ensure this device has internet access. The report token counter is not available when this device is offline.`,
            });
        }

        try {
            /**
             * Server handles increase and counter reset. Result is the new counter value.
             */
            const newCounter = await this.httpClient
                .post<InvoiceNumberOrReportTokenCounter>(`/api/v0/invoiceNumberOrReportTokenCounterIncreases`, {
                    invoiceNumberOrReportTokenCounterId: counterId,
                })
                .toPromise();
            return newCounter.count;
        } catch (error) {
            // If the counter was updated in the meantime, try it once more. If the update fails after the retry, throw an error.
            if (!retries) {
                return await this.getAndIncreaseCount(counterId, { retries: 1 });
            }
            throw new AxError({
                code: 'RETRIEVING_INCREASED_COUNTER_VALUE_FROM_SERVER_FAILED',
                message: `The counter could not be increased on the server.`,
                data: {
                    counterId,
                },
                error,
            });
        }
    }

    /**
     * Decrease the counter with the given ID by one. This is helpful to reset the counter after deleting the latest report/invoice.
     */
    async decreaseCountByOne(counterId: string): Promise<void> {
        if (!this.networkStatusService.isOnline()) {
            throw new AxError({
                code: 'REPORT_TOKEN_COUNTER_REQUIRES_INTERNET_CONNECTION',
                message: `Please ensure this device has internet access. The report token counter is not available when this device is offline.`,
            });
        }

        const counter = await this.get(counterId);

        // Decrease counter by one
        counter.count--;

        // Save the new counter
        try {
            await this.patch(counter);
        } catch (error) {
            throw new AxError({
                code: 'DECREASING_COUNTER_VALUE_AND_PUSHING_TO_SERVER_FAILED',
                message: `The counter could not be decreased on the server.`,
                data: {
                    counterId,
                },
                error,
            });
        }
    }

    //*****************************************************************************
    //  Report-based Invoice Numbers
    //****************************************************************************/

    /**
     * This method is used when a new count for report-based invoice numbers should be generated.
     */
    public async getAndIncreaseReportBasedInvoiceNumberCount(
        reportId: Report['_id'],
        { numberOfPerformedRetries }: { numberOfPerformedRetries?: number } = {},
    ): Promise<number> {
        if (!this.networkStatusService.isOnline()) {
            throw new AxError({
                code: 'REPORT_BASED_INVOICE_NUMBER_COUNTER_REQUIRES_INTERNET_CONNECTION',
                message: `Please ensure this device has internet access. The report-based invoice number counter is not available when this device is offline.`,
            });
        }

        try {
            /**
             * Server handles increase. Result is the new counter value.
             */
            return await this.httpClient
                .post<number>(`/api/v0/reportBasedInvoiceNumberCounterIncreases`, {
                    reportId,
                })
                .toPromise();
        } catch (error) {
            // If the counter was updated in the meantime, try it once more. If the update fails after the retry, throw an error.
            if (!numberOfPerformedRetries) {
                return await this.getAndIncreaseReportBasedInvoiceNumberCount(reportId, {
                    numberOfPerformedRetries: 1,
                });
            }
            throw new AxError({
                code: 'RETRIEVING_INCREASED_REPORT_BASED_INVOICE_COUNTER_VALUE_FROM_SERVER_FAILED',
                message: `The counter could not be increased on the server.`,
                data: {
                    reportId,
                },
                error,
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report-based Invoice Numbers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Settings
    //****************************************************************************/
    public async get(counterId: string): Promise<InvoiceNumberOrReportTokenCounter> {
        // Server handles counter reset if necessary.
        return await this.httpClient
            .get<InvoiceNumberOrReportTokenCounter>(`/api/v0/invoiceNumberOrReportTokenCounters/${counterId}`)
            .toPromise();
    }

    public async create(counter: InvoiceNumberOrReportTokenCounter): Promise<InvoiceNumberOrReportTokenCounter> {
        return this.httpClient
            .post<InvoiceNumberOrReportTokenCounter>(`/api/v0/invoiceNumberOrReportTokenCounters`, counter)
            .toPromise();
    }

    public async delete(
        counterId: InvoiceNumberOrReportTokenCounter['_id'],
    ): Promise<InvoiceNumberOrReportTokenCounter> {
        return this.httpClient
            .delete<InvoiceNumberOrReportTokenCounter>(`/api/v0/invoiceNumberOrReportTokenCounters/${counterId}`)
            .toPromise();
    }

    public async patch(counter: InvoiceNumberOrReportTokenCounter): Promise<InvoiceNumberOrReportTokenCounter> {
        const updatedCounter = await this.httpClient
            .patch<InvoiceNumberOrReportTokenCounter>(
                `/api/v0/invoiceNumberOrReportTokenCounters/${counter._id}`,
                counter,
            )
            .toPromise();

        /**
         * Notify other components (eg. reportTokenPreference) about the change.
         * They fetch the current value from the server themselves.
         * To prevent race conditions, notify them after the server has been updated.
         */
        this.counterChangedSubject$.next(counter._id);
        return updatedCounter;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Settings
    /////////////////////////////////////////////////////////////////////////////*/
}

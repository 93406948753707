import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    NgZone,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { DomSanitizer } from '@angular/platform-browser';
import Quill, { RangeStatic } from 'quill';
import BlotFormatter from 'quill-blot-formatter';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { sanitizeHtmlExceptStyle } from '@autoixpert/lib/html/sanitize-html-except-style';
import { stripHtml } from '../../libraries/strip-html';
import { ImageUploader } from './quill-image-uploader/quill-image-uploader';
import { LineBreak, lineBreakMatcher } from './quill-line-break';

//*****************************************************************************
//  Use Description
//****************************************************************************/
// This component creates a quill editor based on the npm module "ngx-quill" within a mat-form-field
// so that all Angular Material styles are kept.
/////////////////////////////////////////////////////////////////////////////*/
//  END Use Description
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Quill Formats, Modules, Blots
//****************************************************************************/
const Delta = Quill.import('delta');

Quill.register(LineBreak);

/**
 * These two Quill modules enable image upload and image resizing.
 */
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/blotFormatter', BlotFormatter);

/////////////////////////////////////////////////////////////////////////////*/
//  END Quill Formats, Modules, Blots
/////////////////////////////////////////////////////////////////////////////*/

@Component({
    selector: 'mat-quill',
    templateUrl: './mat-quill.component.html',
    styleUrls: ['./mat-quill.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: MatQuillComponent,
        },
    ],
})
export class MatQuillComponent implements OnInit, MatFormFieldControl<string>, ControlValueAccessor, AfterViewInit {
    constructor(
        private domSanitizer: DomSanitizer,
        private zone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        // Enable [(ngModel)]
        @Optional() @Self() public ngControl: NgControl,
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    //*****************************************************************************
    //  Properties
    //****************************************************************************/
    static nextId = 0;
    // Create a custom ID that's used for associating labels and hints of the parent mat-form-field.
    @HostBinding() public id = `mat-quill-${MatQuillComponent.nextId++}`;
    controlType = 'mat-quill';
    stateChanges = new Subject<void>();
    private _placeholder: string;
    focused = false;
    touched = false;
    private _required = false;
    private _disabled = false;
    // For people with screen readers. Needs to be implemented to adhere to MatFormFieldControl.
    _ariaDescribedByIds: string[];

    // This contains the editor contents as HTML.
    private valueCacheHtml: string;
    /**
     * This contains the editor contents without HTML tags, so it's not quite the pure text as the user would read it since
     * line breaks (<br>) are removed, not converted to \n's. But this is still enough to determine if the editor is empty.
     */
    private valueCacheText: string;
    // This is set to true if a user makes changes to Quill (text-change event is fired)
    private isValueCacheInvalid: boolean;
    /**
     * Contains the value of this editor when the user focuses the editor. On blur, the handler compares the current editor value
     * to this value. If they deviate, a change event is fired.
     */
    private valueOnFocus: string;

    // Private event handlers registered for NgModel integration.
    private ngModelOnChangeHandler: (changeEvent: any) => void;
    private ngModelOnBlurHandler: (blurEvent: any) => void;

    // Keep track of subscriptions from which this component needs to unsubscribe when being destroyed.
    private textChangeSubscription: Subscription;
    private selectionChangeSubscription: Subscription;

    @ViewChild('toolbar', { static: false }) toolbarDivReference: ElementRef<HTMLDivElement>;
    @ViewChild('editor', { static: false }) editorContainerDivReference: ElementRef<HTMLDivElement>;
    @ViewChild('colorpicker', { static: false }) colorpicker: ElementRef<HTMLInputElement>;
    /**
     * Used for storing a selected color across text color selections. We assume that the user wants to mark multiple
     * text selections in the same text color. With this field, the HTML input remembers the last selected color.
     */
    public color: string = '#da0000';
    /**
     * Before opening the color picker, the current text selection in Quill is saved into this variable so that the user
     * may click outside of Quill to close the color picker. Clicking outside of Quill sets the current text selection to
     * null and therefore prevents styling the selected text in the color picker's change handler.
     */
    private textSelectionBeforeOpeningColorPicker: RangeStatic;

    /**
     * The type of the change event depends on the global Quill config. Possible values are HTML, markdown or JSON.
     */
    @Output() change = new EventEmitter<any>();
    @Output() selectionChange = new EventEmitter<[QuillRange, QuillRange, QuillEventSource]>();
    @Output() blur = new EventEmitter<void>();
    @Output() focus = new EventEmitter<void>();

    public quillInstance: Quill;

    /**
     * Email Quills allow adding images and have no spacing between paragraphs (we derived that from Gmail and Microsoft Outlook).
     */
    @Input() isEmailQuill: boolean = false;
    /**
     * The theme "snow" shows the toolbar right above the textarea. That's only required for defining the email signature in the preferences
     * overview component.
     * The theme "bubble" is the default and shows a tooltip-like toolbar.
     */
    @Input() theme: 'bubble' | 'snow' = 'bubble';
    @Input() autofocus: boolean = false;
    @Input() minRows: number = null;
    @Input() maxRows: number = null;
    private LINE_HEIGHT: number = 21;

    /**
     * Quill's keyboard module catches keydown events before MatAutocomplete can. That causes a placeholder that's chosen with the arrow keys
     * and enter to be followed by a line break (<p>). That's now what the user wants, however. We use this reference to MatAutocomplete
     * to determine its status (open vs closed) when Quill registers an enter key. If it's open, Quill will ignore the enter key.
     */
    @Input() matAutocomplete: MatAutocomplete;

    /**
     * This function handles image uploads, e.g. for email signature images. It should be overwritten through
     * an input parameter in the HTML template where this component is used.
     * The function must return a Promise that resolves with a link to the image.
     */
    @Input() imageUploadAsync: (file: File) => Promise<string> = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Properties
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Getters & Setters
    //****************************************************************************/
    @Input()
    get value(): string {
        if (!this.editorContainerDivReference) return null;

        /**
         * If a change occurred since the last call of this getter function, get the current content from Quill.
         * Since getting the content is computationally expensive, the result is cached.
         */
        if (this.isValueCacheInvalid) {
            let html: string = this.quillInstance.root.innerHTML;
            const text = stripHtml(html) || '';
            if (text.trim() === '') {
                html = null;
            }
            this.valueCacheHtml = html;
            this.valueCacheText = text;

            // The value cache has been refreshed, so use it until changes are made to the Quill editor.
            this.isValueCacheInvalid = false;
        }

        return this.valueCacheHtml;
    }

    set value(value: string) {
        if (!this.quillInstance) return;

        // If the same content is set again, don't do anything.
        if (value === this.value) {
            // console.log('The same content was put into Quill. This causes unnecessary computational effort. Skip setting the value.', value);
            return;
        }

        if (value === null || typeof value === 'undefined') {
            this.quillInstance.setText('');
        } else {
            const sanitizedValue: string = sanitizeHtmlExceptStyle(value, this.domSanitizer);

            const quillDelta = this.quillInstance.clipboard.convert(sanitizedValue);
            this.quillInstance.setContents(quillDelta);
        }

        this.isValueCacheInvalid = true;

        // Quill fixes invalid HTML structures when a new value is set. Emit a change event if that changed the HTML code.
        const newValue = this.value;
        if (newValue !== value) {
            this.change.emit(newValue);
        }

        // Let the change detector run.
        this.stateChanges.next();
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    set placeholder(placeholder: string) {
        this._placeholder = placeholder;
        this.stateChanges.next();
    }

    /**
     * This property indicates whether the Quill editor is empty. That's relevant for determining whether the mat-form-field label
     * should float or not.
     */
    get empty(): boolean {
        return !this.valueCacheText;
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        /**
         * If the theme is "snow", the Quill toolbar is always shown. Since the toolbar and the mat-form-field label both exist at the top, don't move the label
         * when the user clicks into the field. Instead, always let the mat-form-field label float.
         */
        return this.theme === 'snow' || this.focused || !this.empty;
    }

    @Input()
    get required() {
        return this._required;
    }

    set required(required) {
        this._required = coerceBooleanProperty(required);
        this.stateChanges.next();
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        // TODO If disabling the editor does not work, call this.setDisabledState(value).

        this.stateChanges.next();
    }

    get errorState(): boolean {
        // Currently, not format errors are available.
        return false;
    }

    setDescribedByIds(ids: string[]) {
        this._ariaDescribedByIds = ids;
    }

    private blockEventsFromReachingWindow = (event: KeyboardEvent) => {
        event.stopPropagation();
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Getters & Setters
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnInit(): void {
        // Do nothing but required by some implemented Angular classes.
    }

    ngAfterViewInit() {
        /**
         * The Quill initialization must run outside of Angular so that keydown, keyup and other events do not cause change detection.
         */
        this.zone.runOutsideAngular(() => {
            const quillFormats = ['align', 'bold', 'break', 'color', 'italic', 'line-break', 'list', 'underline'];
            if (this.isEmailQuill) {
                quillFormats.push(
                    // Images like company logos or instagram icons can be added in email signatures.
                    'image',
                    // Links to the company website can be added in email signatures or regular links in email bodies.
                    'link',
                    /**
                     * Allow height and width to be added to a Quill image from HTML via quillInstance.clipboard.convert(htmlString).
                     * Source: https://github.com/kensnyder/quill-image-resize-module/issues/10
                     */
                    'height',
                    'width',
                );
            }

            this.quillInstance = new Quill(this.editorContainerDivReference.nativeElement, {
                /**
                 * Useful for seeing when Quill Deltas are applied, e.g. due to this component's "set value" or insertText().
                 */
                // debug   : 'log',
                theme: this.theme,
                modules: {
                    clipboard: {
                        /**
                         * Setting this to false prevents an unwanted line break to be added before an unordered list. This is the default in Quill v2 but since
                         * autoiXpert currently (2021-11-10) uses Quill v1, this needs to be set to false explicitly.
                         * For details, see https://github.com/quilljs/quill/issues/2905.
                         */
                        matchVisual: false,
                        matchers: [['BR.ql-line-break', lineBreakMatcher]],
                    },
                    keyboard: {
                        bindings: {
                            //*****************************************************************************
                            //  Allow <br> Breaks
                            //****************************************************************************/
                            linebreak: {
                                key: 13,
                                shiftKey: true,
                                handler: function (range) {
                                    // Converting 0-based index to 1-based cursor position.
                                    const cursorPosition = range.index;
                                    const characterBeforeCursor = this.quill.getText(cursorPosition, 1);
                                    const characterAfterCursor = this.quill.getText(cursorPosition + 1, 1);
                                    this.quill.insertEmbed(range.index, 'line-break', true, 'user');
                                    /**
                                     * Since browsers interpreting "<p>Test<br></p>" do not display a break after the "Test" in the paragraph because line breaks before
                                     * closing </p> tags are ignored (See https://stackoverflow.com/a/62523690/1027464 why that is by design), we need to enter two
                                     * line breaks for the user to continue typing on a new line if the user hits Shift+Enter at the end of a paragraph.
                                     *
                                     * "nextChar.length === 0" is true if Shift+Enter was hit at the end of the Quill editor.
                                     * "currentChar === '\n'" is true if Shift+Enter was hit at the end of a paragraph that's followed by another paragraph.
                                     */
                                    if (characterAfterCursor.length === 0 || characterBeforeCursor === '\n') {
                                        this.quill.insertEmbed(range.index, 'line-break', true, 'user');
                                    }
                                    this.quill.setSelection(range.index + 1, 'silent');
                                },
                            },
                            /**
                             * Ctrl+P shows paragraph markers at the end of every paragraph. That allows distinguishing between paragraphs and breaks.
                             */
                            paragraphMarks: {
                                key: 'P',
                                // Ctrl on Windows, Cmd on Mac
                                shortKey: true,
                                handler: function () {
                                    this.quill.root.classList.toggle('show-paragraph-marks');
                                },
                            },
                            /////////////////////////////////////////////////////////////////////////////*/
                            //  END Allow <br> Breaks
                            /////////////////////////////////////////////////////////////////////////////*/
                            /**
                             * This list autofill does not create a bullet list when entering a dash ("-") since assessors like to use these dashes to create
                             * lists. autoiXpert's unordered lists in DOCX/PDF files, however, are always bullets. That would prevent dashes in lists.
                             */
                            'list autofill': {
                                key: ' ',
                                collapsed: true,
                                format: { list: false },
                                prefix: /^\s*?(\d+\.|\*|\[ ?]|\[x])$/,
                                handler: function (range, context) {
                                    const length = context.prefix.length;
                                    const [line, offset] = this.quill.getLine(range.index);
                                    if (offset > length) return true;
                                    let value;
                                    switch (context.prefix.trim()) {
                                        case '[]':
                                        case '[ ]':
                                            value = 'unchecked';
                                            break;
                                        case '[x]':
                                            value = 'checked';
                                            break;
                                        case '*':
                                            value = 'bullet';
                                            break;
                                        default:
                                            value = 'ordered';
                                    }
                                    this.quill.insertText(range.index, ' ', 'user');
                                    this.quill.history.cutoff();
                                    const delta = new Delta()
                                        .retain(range.index - offset)
                                        .delete(length + 1)
                                        .retain(line.length() - 2 - offset)
                                        .retain(1, { list: value });
                                    this.quill.updateContents(delta, 'user');
                                    this.quill.history.cutoff();
                                    this.quill.setSelection(range.index - length, 'silent');
                                },
                            },
                        },
                    },
                    toolbar: {
                        container: this.toolbarDivReference.nativeElement,
                        handlers: {
                            //table : function () {
                            //    // "this" is the toolbar module.
                            //    this.quill.getModule('better-table').insertTable(2, 3);
                            //},
                            color: (...args) => {
                                this.textSelectionBeforeOpeningColorPicker = this.quillInstance.getSelection();

                                // Ensure the color is changed to the last selected value as soon as the user clicks the color picker icon.
                                // Make sure this is done before focusing and opening the color picker. Otherwise the color picker will immediately
                                // close or not even show up on Safari browsers.
                                this.setTextColor(this.color);

                                this.colorpicker.nativeElement.focus();
                                this.colorpicker.nativeElement.click();
                            },
                        },
                    },
                    // Only activate this module if this Quill instance was configured to handle images at all.
                    imageUploader: this.imageUploadAsync
                        ? {
                              upload: this.imageUploadAsync,
                          }
                        : null,
                    // Include handles to resize images. "{}" loads the default config.
                    // Only activate this module if this Quill instance was configured to handle images at all.
                    blotFormatter: this.imageUploadAsync ? {} : null,
                },
                // Do not let Quill show a placeholder. Instead, mat-form-fields show a placeholder in autoiXpert.
                placeholder: null,
                /**
                 * Keep the tooltip within the Quill editor left and right bounds to prevent the tooltip from being cut off by
                 * overflow configurations as seen in the photo editor.
                 */
                bounds: this.editorContainerDivReference.nativeElement,
                formats: quillFormats,
            });

            //*****************************************************************************
            //  Make Quill & MatAutocomplete get along with "Enter" key
            //****************************************************************************/
            // root is a reference to the .ql-editor div which is the contenteditable.
            this.quillInstance.root.addEventListener('keydown', this.preventEnterWithOpenAutocomplete);
            // Add Quill's regular event listeners back.
            this.quillInstance.getModule('keyboard').listen();
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Make Quill & MatAutocomplete get along with "Enter" key
            /////////////////////////////////////////////////////////////////////////////*/

            /**
             * The email editor for Quill has no paragraph margins since that's more common (e.g. in Gmail). Quill's default in autoiXpert,
             * however, defines margin-top values so that the paragraphs look like in the DOCX documents.
             */
            if (this.isEmailQuill) {
                this.quillInstance.root.classList.add('ql-email');
            }

            //*****************************************************************************
            //  Focus & Blur
            //****************************************************************************/
            this.quillInstance.root.addEventListener('focus', this.focusHandler);
            this.quillInstance.root.addEventListener('blur', this.blurHandler);
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Focus & Blur
            /////////////////////////////////////////////////////////////////////////////*/
        });

        if (this.autofocus) {
            // We're within the ngAfterViewInit hook. Angular doesn't like it if this hook updates model properties, such as the focused state.
            // Throw the execution of focus() on the event loop so that it will only be called after this hook has successfully run, without any side effects having happened.
            window.setTimeout(() => {
                this.quillInstance.focus();
            });
        }

        //*****************************************************************************
        //  Content Change & Selection Change
        //****************************************************************************/
        this.zone.runOutsideAngular(() => {
            // Convert the jQuery-style event from Quill to an Observable.
            this.textChangeSubscription = fromEvent<[QuillDelta, QuillDelta, QuillEventSource]>(
                this.quillInstance,
                'text-change',
            )
                //.pipe(
                //    debounceTime(500)
                //)
                .subscribe(() => {
                    this.emitChange();
                });

            this.selectionChangeSubscription = fromEvent<[QuillRange, QuillRange, QuillEventSource]>(
                this.quillInstance,
                'selection-change',
            ).subscribe(([range, oldRange, eventSource]) => {
                /**
                 * Zone.js and therefore NgZone do not know about Quill's events, so trigger change detection manually by executing the following statements via this.zone.run().
                 */
                this.zone.run(() => {
                    this.selectionChange.emit([range, oldRange, eventSource]);
                });
            });
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Content Change & Selection Change
        /////////////////////////////////////////////////////////////////////////////*/
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    private emitChange() {
        /**
         * Zone.js and therefore NgZone do not know about Quill's events, so trigger change detection manually by executing the following statements via this.zone.run().
         */
        this.zone.run(() => {
            this.isValueCacheInvalid = true;
            if (this.ngModelOnChangeHandler) {
                this.ngModelOnChangeHandler(this.value);
            }
        });
    }

    onContainerClick(event: MouseEvent) {
        // Focus if the click was on a MatFormField container element, not within the editor.
        if ((event.target as Element).contains(this.editorContainerDivReference.nativeElement)) {
            this.quillInstance.focus();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Toolbar Functions
    //****************************************************************************/
    handleTextColorChange(event: Event) {
        /**
         * Prevent event propagation because probably the surrounding MatFormField captures the change events of all inputs within the MatFormField and sets
         * the ngModel-bound variable to this event --> not good. We want the bound variable to contain HTML code.
         */
        event.stopImmediatePropagation();
        event.preventDefault();
        const color: string = (event.target as HTMLInputElement).value;
        this.setTextColor(color);
    }

    setTextColor(color: string) {
        this.color = color;

        this.quillInstance.formatText(
            this.textSelectionBeforeOpeningColorPicker.index,
            this.textSelectionBeforeOpeningColorPicker.length,
            'color',
            color,
            'user',
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Toolbar Functions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Min & Max Line Height
    //****************************************************************************/
    getEditorHeight(): { [key: string]: string } {
        const ngStylesObject = {
            minHeight: null,
            maxHeight: null,
        };
        if (this.minRows) {
            ngStylesObject.minHeight = `${this.minRows * this.LINE_HEIGHT}px`;
        }
        if (this.maxRows) {
            ngStylesObject.maxHeight = `${this.maxRows * this.LINE_HEIGHT}px`;
        }

        return ngStylesObject;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Min & Max Line Height
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  ngModel Implementation
    //****************************************************************************/
    /**
     * Programmatic changes from model to view are propagated through this function.
     */
    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(onChangeHandler: (onChangeEvent: any) => void): void {
        this.ngModelOnChangeHandler = onChangeHandler;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     */
    registerOnTouched(onTouchedHandler: (onChangeEvent: any) => void): void {
        this.ngModelOnBlurHandler = onTouchedHandler;
    }

    setDisabledState(isDisabled: boolean): void {
        /**
         * If the quill instance has not yet been created, do not execute the methods. Implementing this method is required when
         * creating a mat-form-field component but apparently, this method does not need to work immediately to disable a field.
         */
        isDisabled ? this.quillInstance?.disable() : this.quillInstance?.enable();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END ngModel Implementation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Event Handlers
    //****************************************************************************/
    public preventEnterWithOpenAutocomplete = (event) => {
        if (event.key === 'Enter' && this.matAutocomplete?.isOpen) {
            event.preventDefault();
        }
    };

    public focusInput(): void {
        this.quillInstance.focus();
    }

    public focusHandler = () => {
        //console.log('focus event triggered');
        /**
         * Zone.js and therefore NgZone do not know about Quill's events since Quill was created outside of zone.js (see runOutsideOfAngular()), so trigger change detection manually
         * by executing the following statements via this.zone.run().
         */
        this.zone.run(() => {
            /**
             * Prevent all other keydown events while the focus is in the content-editable. That ensures
             * that Angular's change detection is not run too often.
             * Angular Material registers keydown global event listeners for layers which are added by things
             * like tooltips. Those keydown listeners trigger change detection which makes the application feel slow when
             * the user types in the content-editable.
             */
            window.document.addEventListener('keydown', this.blockEventsFromReachingWindow);

            /**
             * When the user focuses the quill editor, mark the parent form field as focused, too.
             */
            if (!this.focused) {
                this.focused = true;
                this.valueOnFocus = this.value;
                // The state change needs to be propagated only if this component was not focussed before. If the component was focused before, too,
                // the user just repositioned the cursor - that's not a focus event.
                this.stateChanges.next();
                this.changeDetectorRef.detectChanges();
            }
            this.focus.emit();
        });
    };

    public blurHandler = (event) => {
        /**
         * Quill v1 fires a blur event on the Quill container when content from the clipboard is pasted (clipboard.js:113). That's because
         * Quill's clipboard container must have the focus when pasting so that the content is pasted into the container. The container contents
         * are then translated into the required Quill Delta format with which they are added to the editor.
         *
         * If we would not reject this blur event here,
         *
         * The "relatedTarget" is the element that is being focused when this blur event is fired.
         */
        if (event.relatedTarget === this.quillInstance.getModule('clipboard')?.container) {
            //console.log('blur event rejected');
            return;
        }
        //console.log('blur event triggered');

        if (event.relatedTarget?.closest('.toolbar') || event.relatedTarget?.closest('input[type="color"]')) {
            // Don't trigger the blur event when using the toolbar (or color picker, which is not nested in the toolbar)
            return;
        }

        /**
         * Zone.js and therefore NgZone do not know about Quill's events since Quill was created outside of zone.js (see runOutsideOfAngular()), so trigger change detection manually
         * by executing the following statements via this.zone.run().
         */
        this.zone.run(() => {
            /**
             * Unregister blocking keydown events from reaching window.
             */
            window.document.removeEventListener('keydown', this.blockEventsFromReachingWindow);

            /**
             * When the user blurs the quill editor, mark the parent form field as blurred, too.
             */
            this.touched = true;
            this.focused = false;

            if (this.ngModelOnBlurHandler) {
                this.ngModelOnBlurHandler(true);
            }

            // If the current value deviates from the value the editor had when the user focused the editor, fire a change event.
            const content = this.value;
            if (content !== this.valueOnFocus) {
                this.change.emit(content);

                // Can't be used since the change event would sometimes be of type String (above) and sometimes of type Event (here)
                ///**
                // * Trigger a change event that's propagated (bubbled) through the parent DOM. That way, parent container can define a single "change" handler to
                // * capture all of their children's change events.
                // */
                //this.quillInstance.root.dispatchEvent(new Event('change', {bubbles : true}));
            }

            this.blur.emit();
            this.stateChanges.next();
        });
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        // Clean up after ourselves. No need to keep the state changes subject running - that would create a memory leak.
        this.stateChanges.complete();
        // Prevent memory leaks by removing the subscription as soon as Angular removes this Quill instance.
        this.textChangeSubscription.unsubscribe();
        this.selectionChangeSubscription.unsubscribe();

        this.quillInstance.root.removeEventListener('keydown', this.preventEnterWithOpenAutocomplete);
        this.quillInstance.root.removeEventListener('focus', this.focusHandler);
        this.quillInstance.root.removeEventListener('blur', this.blurHandler);
    }
}

/**
 * user: Default. A user action (typing, toolbar click, copy/paste, ...) triggered the change.
 * silent: An API action caused the change. Quill does not recommend using this at all since this change is not recorded in the Quill history.
 */
type QuillEventSource = 'user' | 'silent';
// Quill's type "Delta" can currently (2021-09-21) not be imported since the quill-delta module does not have an ES6 module syntax.
type QuillDelta = any;

interface QuillRange {
    index: number;
    length: number;
}

import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { removeDocumentFromReport } from '@autoixpert/lib/documents/remove-document-from-report';
import { computeInvoiceTotalGross, computeInvoiceTotalNet } from '@autoixpert/lib/invoices/compute-invoice-total';
import { createInvoiceFromInvoiceParameters } from '@autoixpert/lib/invoices/create-invoice-from-invoice-parameters';
import { round } from '@autoixpert/lib/numbers/round';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { InvoiceParameters } from '@autoixpert/models/invoices/invoice-parameters';
import { InvoiceTemplate } from '@autoixpert/models/invoices/invoice-template';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { ExpertStatement } from '@autoixpert/models/reports/expert-statement';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { TextTemplate } from '@autoixpert/models/text-templates/text-template';
import { User } from '@autoixpert/models/user/user';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { LineItemUnitAutocompleteEntry } from '../../../invoices/invoice-editor/invoice-editor.component';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../../shared/animations/run-child-animations.animation';
import { getInvoiceApiErrorHandlers } from '../../../shared/libraries/error-handlers/get-invoice-api-error-handlers';
import { getVatRateForTeam } from '../../../shared/libraries/get-vat-rate-2020';
import { getDefaultDaysUntilDue } from '../../../shared/libraries/invoices/get-default-days-until-due';
import { convertHtmlToPlainText } from '../../../shared/libraries/strip-html';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { DownloadService } from '../../../shared/services/download.service';
import { InvoiceNumberService } from '../../../shared/services/invoice-number.service';
import { InvoiceTemplateService } from '../../../shared/services/invoice-template.service';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { lineItemUnitsStatic } from '../../../shared/static-data/line-item-units';
import { PhotoGridComponent } from '../shared/photos-grid/photo-grid.component';

@Component({
    selector: 'expert-statement',
    templateUrl: 'expert-statement.component.html',
    styleUrls: ['expert-statement.component.scss'],
    animations: [fadeInAndOutAnimation(), runChildAnimations()],
})
export class ExpertStatementComponent {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private reportDetailsService: ReportDetailsService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private downloadService: DownloadService,
        private invoiceService: InvoiceService,
        private invoiceNumberService: InvoiceNumberService,
        private httpClient: HttpClient,
        public userPreferences: UserPreferencesService,
        private loggedInUserService: LoggedInUserService,
        private invoiceTemplateService: InvoiceTemplateService,
        private networkStatusService: NetworkStatusService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    public report: Report;
    public user: User;
    private team: Team;

    public selectedExpertStatement: ExpertStatement;
    public selectedTab: 'statement' | 'invoice' = 'statement';

    // Statement
    public selectedExpertStatementDocument: DocumentMetadata;
    public relevantRecipients: ContactPerson[] = [];
    public messageTemplateSelectorShown: boolean;
    public textTemplateSelectorVisible: boolean = false;
    private DEFAULT_EXPERT_STATEMENT_TITLE = 'Stellungnahme';
    public expertStatementPreviewDownloadPending: boolean;
    public invoiceRecipientContactPersonEditorShown: boolean;
    public lastInvoiceNumber: string;

    //*****************************************************************************
    //  Invoice Properties
    //****************************************************************************/
    public invoicePreviewDownloadPending: boolean;
    public invoiceCreationPending: boolean;
    // Unit autocomplete
    public lineItemUnits: LineItemUnitAutocompleteEntry[] = lineItemUnitsStatic;
    public filteredLineItemUnits: LineItemUnitAutocompleteEntry[] = [];
    // Invoice Templates
    public invoiceTemplatesShown: boolean = false;
    public selectedInvoiceTemplate: InvoiceTemplate;
    public invoiceTemplateTitleDialogShown: boolean = false;
    public invoiceTemplates: InvoiceTemplate[] = [];
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Properties
    /////////////////////////////////////////////////////////////////////////////*/

    // Photos
    public photos: Photo[] = [];
    public photoEditorVisible: boolean;
    public initialPhotoForEditor: Photo;

    private subscriptions: Subscription[] = [];

    // Allow uploading photos through the PhotoGridComponent when the user hits the plus icon in the photo toolbar.
    @ViewChild('photoGridComponent') public photoGridComponent: PhotoGridComponent;

    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));

        const paramsSubscription = this.route.parent.params
            .pipe(switchMap((params) => this.reportDetailsService.get(params['reportId'])))
            .subscribe((report) => {
                this.report = report;
                // Statement
                this.findRelevantRecipients();
                this.createFirstStatement();

                let expertStatement = this.report.expertStatements[0];
                if (this.route.snapshot.queryParams['Stellungnahme']) {
                    expertStatement = this.report.expertStatements.find(
                        (expertStatement) => expertStatement._id === this.route.snapshot.queryParams['Stellungnahme'],
                    );
                }
                this.selectExpertStatement(expertStatement);

                this.resetInvoiceIfEmpty(this.selectedExpertStatement);

                // Invoice
                this.getInvoiceTemplates();

                if (this.route.snapshot.queryParams['tab']) {
                    switch (this.route.snapshot.queryParams['tab']) {
                        case 'Rechnung':
                            this.selectedTab = 'invoice';
                            break;
                        default:
                            this.selectedTab = 'statement';
                            break;
                    }
                }

                // Photos
                this.photos = this.report.photos.filter((photo) => photo.versions.expertStatement.included);
            });

        this.subscriptions.push(paramsSubscription);
    }

    //*****************************************************************************
    //  Statement List
    //****************************************************************************/
    public selectExpertStatement(expertStatement: ExpertStatement): void {
        this.selectedExpertStatement = expertStatement;
        this.selectedExpertStatementDocument = this.getMatchingDocumentData(expertStatement);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Statement List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tabs
    //****************************************************************************/
    public selectTab(tab: this['selectedTab']): void {
        this.selectedTab = tab;
        if (this.selectedTab === 'invoice') {
            this.showTemplatesIfInvoiceEmpty();
        }
    }

    public statementStatus(expertStatementDocument: DocumentMetadata): 'empty' | 'partiallyComplete' | 'complete' {
        if (expertStatementDocument.subject && expertStatementDocument.body) {
            return 'complete';
        }

        if (expertStatementDocument.subject || expertStatementDocument.body) {
            return 'partiallyComplete';
        }
        return 'empty';
    }

    public invoiceStatus(): 'empty' | 'partiallyComplete' | 'complete' {
        if (this.selectedExpertStatement.invoiceParameters._id) {
            return 'complete';
        }

        if (this.getInvoiceTotalNet() || this.selectedExpertStatement.invoiceParameters.number) {
            return 'partiallyComplete';
        }
        return 'empty';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tabs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Statement
    //****************************************************************************/
    private createFirstStatement(): void {
        if (!this.report.expertStatements?.length) {
            this.report.expertStatements = [];
            this.addExpertStatement();
        }
    }

    private findRelevantRecipients(): void {
        this.relevantRecipients = [
            this.report.insurance?.contactPerson,
            this.report.lawyer?.contactPerson,
            this.report.claimant.contactPerson,
        ].filter(Boolean);
    }

    public addExpertStatement(): void {
        const newExpertStatement = new ExpertStatement();

        // Set default recipient to insurance
        // Use recipient of first statement, or if that does not exist, the insurance
        // TODO As soon as the backend supports a full recipient, uncommen this
        // const recipient: ContactPerson = this.report.expertStatements[0]?.recipient ?? this.report.insurance.contactPerson;
        // newExpertStatement.recipient                   = JSON.parse(JSON.stringify(recipient));
        newExpertStatement.createdBy = this.user._id;
        newExpertStatement.createdAt = moment().format();
        newExpertStatement.updatedAt = moment().format();

        /**
         * Invoice Parameters
         */
        newExpertStatement.invoiceParameters.lineItems = [
            new LineItem({
                description: '<p>Honorar Stellungnahme</p>',
                quantity: 1,
                unit: 'Pauschal',
            }),
        ];

        /**
         * User Preference default recipient for expert statement
         * Only assign the default recipient, if the recipient is part of the report.
         * E.G. lawyer may be the default recipient for liability reports, but does not exist for lease returns.
         */
        const defaultRecipient = this.userPreferences.defaultRecipientExpertStatement
            ? this.userPreferences.defaultRecipientExpertStatement
            : 'claimant';
        if (this.report[defaultRecipient]) {
            newExpertStatement.invoiceParameters.recipientRole = defaultRecipient;
        } else {
            newExpertStatement.invoiceParameters.recipientRole = 'claimant';
        }

        newExpertStatement.invoiceParameters.daysUntilDue = getDefaultDaysUntilDue({
            reportType: this.report.type,
            team: this.team,
        });

        this.updateVatRate(newExpertStatement);

        this.report.expertStatements.push(newExpertStatement);

        // Add document to report
        this.addExpertStatementDocument(newExpertStatement);

        this.saveReport();
    }

    public deleteExpertStatement(expertStatement: ExpertStatement): void {
        const index = this.report.expertStatements.indexOf(expertStatement);
        if (index > -1) {
            this.report.expertStatements.splice(index, 1);

            // If there is an invoice, remove it from report documents
            if (expertStatement.invoiceParameters._id) {
                this.deleteInvoice(expertStatement);
            }

            this.removeExpertStatementDocument(expertStatement._id);

            this.saveReport();

            // If the last statement has been deleted, navigate away
            if (!this.report.expertStatements.length) {
                this.navigateToPrintAndTransmission();
            }

            /**
             * If the currently selected statement is being deleted, select the next one.
             */
            if (expertStatement === this.selectedExpertStatement) {
                /**
                 * Either select the one after (that, after deletion, moved up one spot) or the expert statement before.
                 */
                const nextStatement = this.report.expertStatements[index] || this.report.expertStatements[index - 1];
                if (nextStatement) {
                    this.selectExpertStatement(nextStatement);
                }
            }
        }
    }

    //*****************************************************************************
    //  Statement - Document Metadata
    //****************************************************************************/
    private addExpertStatementDocument(expertStatement: ExpertStatement): void {
        const newDocument = new DocumentMetadata({
            type: 'expertStatement',
            title: this.DEFAULT_EXPERT_STATEMENT_TITLE,
            expertStatementId: expertStatement._id,
            recipientRole: this.userPreferences.defaultRecipientExpertStatement
                ? this.userPreferences.defaultRecipientExpertStatement
                : this.report.claimant.representedByLawyer && this.report.lawyer
                  ? 'lawyer'
                  : 'insurance',
            createdBy: this.user._id,
            createdAt: moment().format(),
            updatedAt: moment().format(),
        });

        // Some report types don't have a lawyer or insurance. This may conflict with the default saved in their user preferences. Implement a fallback.
        if (
            (newDocument.recipientRole === 'lawyer' && !this.report.lawyer) ||
            (newDocument.recipientRole === 'insurance' && !this.report.insurance)
        ) {
            newDocument.recipientRole = 'claimant';
        }
        addDocumentToReport(
            {
                team: this.team,
                report: this.report,
                newDocument: newDocument,
                documentGroup: 'report',
            },
            { allowMultiple: true },
        );
    }

    private removeExpertStatementDocument(expertStatementId: string): void {
        const document = this.report.documents.find(
            (document) => document.type === 'expertStatement' && document.expertStatementId === expertStatementId,
        );
        if (!document) {
            console.log("Couldn't find expert statement document with expertStatementId", expertStatementId);
            return;
        }
        removeDocumentFromReport({
            report: this.report,
            documentGroup: 'report',
            document,
        });
    }

    public getMatchingDocumentData(expertStatement: ExpertStatement): DocumentMetadata {
        return this.report.documents.find((documentEntry) => documentEntry.expertStatementId === expertStatement._id);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Statement - Document Metadata
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Statement - Recipient
    //****************************************************************************/

    public getRecipient(
        expertStatementDocument: DocumentMetadata = this.selectedExpertStatementDocument,
    ): ContactPerson {
        switch (expertStatementDocument.recipientRole) {
            // Some report types (e.g. short assessment) may not have an insurance or a lawyer.
            case 'insurance':
                return this.report.insurance?.contactPerson;
            case 'claimant':
                return this.report.claimant.contactPerson;
            case 'lawyer':
                return this.report.lawyer?.contactPerson;
            case 'garage':
                return this.report.garage?.contactPerson;
        }
    }

    public setRecipientRole(role: DocumentMetadata['recipientRole']): void {
        this.selectedExpertStatementDocument.recipientRole = role;
    }

    public setInvoiceRecipientRole(recipientRole: InvoiceParameters['recipientRole']): void {
        this.selectedExpertStatement.invoiceParameters.recipientRole = recipientRole;
    }

    public getInvoiceRecipient(
        invoiceRecipient: InvoiceParameters = this.selectedExpertStatement.invoiceParameters,
    ): ContactPerson {
        switch (invoiceRecipient.recipientRole) {
            case 'insurance':
                return this.report.insurance?.contactPerson;
            case 'claimant':
                return this.report.claimant.contactPerson;
            case 'lawyer':
                return this.report.lawyer?.contactPerson;
            case 'garage':
                return this.report.garage?.contactPerson;
            case 'ownerOfClaimantsCar':
                return this.report.ownerOfClaimantsCar?.contactPerson;
            case 'custom':
                return this.selectedExpertStatement.invoiceParameters.recipient;
        }
    }

    public showInvoiceRecipientContactPersonEditor(): void {
        if (!this.selectedExpertStatement.invoiceParameters.recipient) {
            this.selectedExpertStatement.invoiceParameters.recipient = new ContactPerson();
        }

        this.invoiceRecipientContactPersonEditorShown = true;
    }

    public hideInvoiceRecipientContactPersonEditor(): void {
        this.invoiceRecipientContactPersonEditorShown = false;
    }

    /**
     * Give the user the choice which recipient he wants to be set on the creation of the statement
     */
    public setDefaultRecipient(recipient: 'lawyer' | 'insurance' | 'claimant' | 'garage', event: MouseEvent) {
        this.userPreferences.defaultRecipientExpertStatement = recipient;
        event.stopPropagation();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Statement - Recipient
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Statement - Message Templates
    //****************************************************************************/
    public showMessageTemplateSelector(): void {
        this.messageTemplateSelectorShown = true;
    }

    public hideMessageTemplateSelector(): void {
        this.messageTemplateSelectorShown = false;
    }

    public insertTemplateText(textTemplate: TextTemplate): void {
        this.selectedExpertStatementDocument.subject = textTemplate.subject;
        this.selectedExpertStatementDocument.body = textTemplate.body;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Statement - Message Templates
    /////////////////////////////////////////////////////////////////////////////*/

    public downloadExpertStatementPreview(expertStatement: ExpertStatement): void {
        // Prevent parallel download
        if (this.expertStatementPreviewDownloadPending) return;

        this.expertStatementPreviewDownloadPending = true;
        this.httpClient
            .get(`/api/v0/reports/${this.report._id}/documents/expertStatements/${expertStatement._id}?format=pdf`, {
                responseType: 'blob',
                observe: 'response',
            })
            .subscribe({
                next: (response) => {
                    this.expertStatementPreviewDownloadPending = false;
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.expertStatementPreviewDownloadPending = false;
                    console.error('Error downloading the PDF buffer.', { error });
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Fehler beim Download',
                            body: 'Die PDF-Datei konnte nicht heruntergeladen werden.',
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Statement
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice
    //****************************************************************************/
    async handleInvoiceNumberChange() {
        await this.saveReport();

        const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.report.officeLocationId);
        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceNumberChangedManually',
            documentType: 'expertStatement',
            invoiceNumber: this.selectedExpertStatement.invoiceParameters.number,
            previousInvoiceNumber: this.lastInvoiceNumber,
            reportId: this.report._id,
            invoiceNumberConfigId: invoiceNumberConfig._id,
            expertStatementId: this.selectedExpertStatement._id,
        });
    }

    /**
     * Add the fixed invoice positions to a fresh invoice object,
     * and create the invoice on the server.
     */
    public async createInvoice(): Promise<void> {
        // Since we wouldn't always use the up-to-date counter, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Nur online kann sichergestellt werden, dass der Zähler über alle Geräte hinweg eindeutig vergeben wird.',
            );
            return;
        }

        this.invoiceCreationPending = true;

        // Hold on to the selected statement for callback
        const selectedExpertStatement = this.selectedExpertStatement;

        // Set date
        if (!selectedExpertStatement.invoiceParameters.date) {
            selectedExpertStatement.invoiceParameters.date = todayIso();
        }

        // Get a copy without empty items
        const invoice = createInvoiceFromInvoiceParameters({
            invoiceParameters: selectedExpertStatement.invoiceParameters,
            report: this.report,
            user: this.user,
            team: this.team,
        });

        // If the total net is negative, it's a credit note. This is probably no relevant use case but keeps existing behavior.
        if (invoice.totalNet < 0) {
            invoice.type = 'creditNote';
        }

        //*****************************************************************************
        //  Set invoice number
        //****************************************************************************/
        // If the invoice doesn't have a number yet...
        if (!invoice.number) {
            let invoiceNumber: string;
            try {
                invoiceNumber = await this.invoiceNumberService.generateInvoiceNumber({
                    officeLocationId: this.report.officeLocationId,
                    responsibleAssessorId: this.report.responsibleAssessor,
                    report: this.report,
                });
                const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(
                    this.report.officeLocationId,
                );
                await this.invoiceNumberJournalEntryService.create({
                    entryType: 'invoiceNumberGeneratedOnCreation',
                    documentType: 'expertStatement',
                    reportId: this.report._id,
                    invoiceId: invoice._id,
                    invoiceNumber,
                    invoiceNumberConfigId: invoiceNumberConfig?._id,
                    expertStatementId: this.selectedExpertStatement._id,
                });
            } catch (error) {
                console.error('ERROR_GENERATING_INVOICE_NUMBER', { error });
                this.toastService.error('Keine Rechnungsnummer', 'Rechnungsnummer konnte nicht generiert werden');
                this.invoiceCreationPending = false;
                return;
            }
            this.selectedExpertStatement.invoiceParameters.number = invoiceNumber;
            invoice.number = invoiceNumber;
        }
        //*****************************************************************************
        //  END Set invoice number
        //****************************************************************************/

        //*****************************************************************************
        //  Check for Duplicate Invoice Number
        //****************************************************************************/
        // Check if the invoice number within the same annual invoice cycle already exists.
        try {
            const invoicesWithSameNumber = await this.invoiceService.httpSync.findRemote({
                number: invoice.number,
                date: {
                    $gt: moment(invoice.date).startOf('year').format(),
                    $lt: moment(invoice.date).endOf('year').format(),
                },
            });

            if (invoicesWithSameNumber.length) {
                this.toastService.error(
                    `Die Rechnungsnummer ${invoice.number} existiert bereits`,
                    'Bitte wähle eine andere Rechnungsnummer und versuche es erneut.',
                );
                this.invoiceCreationPending = false;
                return;
            }
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: ``,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Check for Duplicate Invoice Number
        /////////////////////////////////////////////////////////////////////////////*/

        /////////////////////////////////////////////////////////////////////////////*/
        //  Create invoice
        /////////////////////////////////////////////////////////////////////////////*/
        try {
            await this.invoiceService.create(invoice, { waitForServer: true });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getInvoiceApiErrorHandlers(),
                },
                defaultHandler: { title: 'Rechnung konnte nicht generiert werden' },
            });
        } finally {
            this.invoiceCreationPending = false;
        }

        this.toastService.success(`Rechnung ${invoice.number} erstellt`, 'Du findest sie in der Rechnungsliste');
        this.selectedExpertStatement.invoiceParameters._id = invoice._id;
        this.insertInvoiceDocument(invoice);

        await this.saveReport();
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Create invoice
        /////////////////////////////////////////////////////////////////////////////*/
    }

    private insertInvoiceDocument(invoice: Invoice) {
        const newDocument = new DocumentMetadata({
            type: 'invoice',
            title: `Rechnung ${this.DEFAULT_EXPERT_STATEMENT_TITLE} - ${invoice.number}`,
            invoiceId: invoice._id,
            createdBy: this.user._id,
            createdAt: moment().format(),
            updatedAt: moment().format(),
        });

        addDocumentToReport(
            {
                team: this.team,
                report: this.report,
                newDocument: newDocument,
                documentGroup: 'report',
            },
            { allowMultiple: true },
        );
    }

    public async downloadInvoicePreview(): Promise<void> {
        // Prevent a second download request
        if (this.invoicePreviewDownloadPending) {
            return;
        }

        this.invoicePreviewDownloadPending = true;

        const invoice: Invoice = createInvoiceFromInvoiceParameters({
            invoiceParameters: this.selectedExpertStatement.invoiceParameters,
            report: this.report,
            user: this.user,
            team: this.team,
        });

        try {
            const response = await this.invoiceService.getInvoicePreview(invoice);
            this.downloadService.downloadBlobResponseWithHeaders(response);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Fehler beim Download`,
                    body: `Die Rechnungsvorschau konnte nicht erstellt werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        } finally {
            this.invoicePreviewDownloadPending = false;
        }
    }

    /**
     * After an invoice has been locked, it can be re-opened and edited. This deletes the invoice record.
     * The revised version must be re-submitted.
     */
    public async deleteInvoice(expertStatement: ExpertStatement): Promise<void> {
        /**
         * We want instant feedback in the UI.
         * Therefore delete the ID outside of the callback (see below). For the DELETE call, we need to hold on to this ID here though.
         */
        const oldInvoiceId: string = expertStatement.invoiceParameters._id;

        const invoiceDocument: DocumentMetadata = this.report.documents.find(
            (document) => document.type === 'invoice' && document.invoiceId === oldInvoiceId,
        );
        if (!invoiceDocument) {
            console.log("Couldn't find invoice of expert statement with invoiceId", oldInvoiceId);
        }

        /**
         * If there is no other expert statement, the UI will switch to print and transmission.
         * Print and transmission does not update the screen if the invoice document is deleted.
         * Therefore a user would see the old invoice in print and transmission and has to reload the screen to get rid of it.
         *
         * To avoid this, we remove the invoice document from the report before the invoice is deleted.
         * If there is an error (e.g. invoice is locked), add a copy of the invoice document back to the report.
         */
        const copyOfInvoiceDocument = JSON.parse(JSON.stringify(invoiceDocument));
        removeDocumentFromReport({
            report: this.report,
            documentGroup: 'report',
            document: invoiceDocument,
        });

        let existingInvoice: Invoice;
        try {
            existingInvoice = await this.invoiceService.get(expertStatement.invoiceParameters._id);
        } catch (error) {
            if (error.code === 'RESOURCE_NOT_FOUND') {
                // If the invoice has been deleted in the invoice list, it's ok that it cannot be found here.
                expertStatement.invoiceParameters._id = null;
                this.saveReport();
                return;
            }

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Rechnung konnte nicht abgerufen werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                },
            });
        }
        // Only delete unlocked invoices
        if (existingInvoice.lockedAt) {
            this.toastService.error(
                `Rechnung bereits gebucht`,
                `Sie wurde nicht gelöscht. Bitte prüfe, ob du die <a href="/Rechnungen/${existingInvoice._id}" target="_blank">alte Rechnung ${existingInvoice.number}</a> stornieren solltest, falls du nun eine neue anlegen möchtest.`,
            );
            // Keep the reference to the locked invoice in the report.
            if (copyOfInvoiceDocument) {
                addDocumentToReport(
                    {
                        team: this.team,
                        report: this.report,
                        newDocument: copyOfInvoiceDocument,
                        documentGroup: 'report',
                    },
                    { allowMultiple: true },
                );
            }
            return;
        } else {
            // Opening a locked repair confirmation invoice removes this invoice so that the edited invoice can be saved anew.
            await this.invoiceService.delete(oldInvoiceId);
        }

        expertStatement.invoiceParameters._id = null;
        await this.saveReport();
    }

    public async resetInvoiceIfEmpty(expertStatement: ExpertStatement) {
        if (expertStatement.invoiceParameters._id) {
            try {
                await this.invoiceService.get(expertStatement.invoiceParameters._id);
            } catch {
                this.toastService.error(
                    'Rechnung existiert nicht mehr',
                    'Die Rechnung wurde zurückgesetzt und kann neu angelegt werden.',
                );
            }
        }
    }

    public async generateInvoiceNumber(): Promise<void> {
        // Since we wouldn't always use the up-to-date counter, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Nur online kann sichergestellt werden, dass der Zähler über alle Geräte hinweg eindeutig vergeben wird.',
            );
            return;
        }

        let invoiceNumber: string;
        try {
            invoiceNumber = await this.invoiceNumberService.generateInvoiceNumber({
                officeLocationId: this.report.officeLocationId,
                responsibleAssessorId: this.report.responsibleAssessor,
                report: this.report,
            });
            const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(this.report.officeLocationId);
            await this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceNumberGeneratedManually',
                documentType: 'expertStatement',
                reportId: this.report._id,
                invoiceNumber,
                invoiceNumberConfigId: invoiceNumberConfig?._id,
                expertStatementId: this.selectedExpertStatement._id,
            });
        } catch (error) {
            console.error('Error when generating an invoice number: ', { error });
            this.toastService.error(
                'Rechnungsnummer konnte nicht generiert werden.',
                "Bitte prüfe deine Internetverbindung oder wende dich an die <a href='/Hilfe'>Hotline</a>.",
            );
            return;
        }
        this.selectedExpertStatement.invoiceParameters.number = invoiceNumber;
        this.saveReport();
    }

    public updateVatRate(expertStatement: ExpertStatement): void {
        expertStatement.invoiceParameters.vatRate = getVatRateForTeam(
            this.team.invoicing.vatRate,
            expertStatement.invoiceParameters.date,
        );
        if (this.team.invoicing.vatRate === 0) {
            expertStatement.invoiceParameters.vatExemptionReason = 'smallBusiness';
        }
    }

    public addInvoiceLineItem(): void {
        if (this.isInvoiceLocked()) {
            return;
        }

        this.selectedExpertStatement.invoiceParameters.lineItems.push(
            new LineItem({
                position: this.selectedExpertStatement.invoiceParameters.lineItems.length + 1,
                quantity: 1,
                active: true,
            }),
        );
    }

    public removeInvoiceLineItem(invoiceLineItem: LineItem): void {
        const index = this.selectedExpertStatement.invoiceParameters.lineItems.indexOf(invoiceLineItem);
        this.selectedExpertStatement.invoiceParameters.lineItems.splice(index, 1);
    }

    public isInvoiceLocked(): boolean {
        return !!this.selectedExpertStatement.invoiceParameters._id;
    }

    public getInvoiceTotalNet(): number {
        return this.selectedExpertStatement.invoiceParameters.lineItems
            .filter((lineItem) => lineItem.active)
            .reduce((total, currentValue) => total + (currentValue.unitPrice || 0) * (currentValue.quantity || 0), 0);
    }

    public getInvoiceTotalGross(): number {
        return round(this.getInvoiceTotalNet() * (1 + this.selectedExpertStatement.invoiceParameters.vatRate));
    }

    public navigateToPrintAndTransmission(): void {
        this.router.navigate(['Druck-und-Versand'], {
            relativeTo: this.route.parent,
            queryParams: {
                dokumentgruppe: 'Stellungnahme',
            },
        });
    }

    //*****************************************************************************
    //  Line Item Unit Autocomplete
    //****************************************************************************/
    public filterLineItemAutocomplete(searchTerm: string): void {
        this.filteredLineItemUnits = [...this.lineItemUnits];

        if (!searchTerm) return;

        this.filteredLineItemUnits = this.lineItemUnits.filter((entry) =>
            entry.label.toLowerCase().includes(searchTerm.toLowerCase()),
        );
    }

    public insertLineItemUnitBasedOnQuantity(lineItem: LineItem, selectedLabel: string): void {
        const autocompleteEntry: LineItemUnitAutocompleteEntry = this.lineItemUnits.find(
            (entry) =>
                entry.label === selectedLabel ||
                entry.singularValue === selectedLabel ||
                entry.pluralValue === selectedLabel,
        );

        if (!autocompleteEntry) return;

        lineItem.unit = lineItem.quantity > 1 ? autocompleteEntry.pluralValue : autocompleteEntry.singularValue;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Line Item Unit Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Templates
    //****************************************************************************/

    private showTemplatesIfInvoiceEmpty(): void {
        const invoiceEmpty = this.selectedExpertStatement.invoiceParameters.lineItems.every(
            (lineItem) => !lineItem.description,
        );
        if (invoiceEmpty && this.invoiceTemplates?.length) {
            this.invoiceTemplatesShown = true;
        }
    }

    private getInvoiceTemplates(): void {
        this.invoiceTemplateService.find({ type: 'expertStatementInvoice' }).subscribe({
            next: (invoiceTemplates) => {
                this.invoiceTemplates = invoiceTemplates;
            },
            error: (err) => {
                this.toastService.error('Vorlagen konnten nicht vom Server geholt werden');
                console.error('COULD_NOT_RETRIEVE_INVOICE_TEMPLATES', err);
            },
        });
    }

    /**
     * Get the default title value passed to the save-as-template dialog.
     *
     * The default is either the subject or the first line item. HTML code is stripped.
     */
    public getInvoiceTemplateTitle(): string {
        return convertHtmlToPlainText(
            this.selectedExpertStatementDocument.subject ||
                this.selectedExpertStatement.invoiceParameters.lineItems[0].description ||
                '',
        );
    }

    public async addInvoiceToTemplates(templateTitle: string): Promise<void> {
        // Template = invoice without _id to avoid ID conflicts
        const invoiceCopy: Invoice = JSON.parse(JSON.stringify(this.selectedExpertStatement.invoiceParameters));

        const invoiceTemplate: InvoiceTemplate = new InvoiceTemplate();
        invoiceTemplate.type = 'expertStatementInvoice';
        invoiceTemplate.title = templateTitle;
        invoiceTemplate.intro = invoiceCopy.intro;
        invoiceTemplate.daysUntilDue = invoiceCopy.daysUntilDue;
        invoiceTemplate.lineItems = invoiceCopy.lineItems;
        invoiceTemplate.totalNet = computeInvoiceTotalNet(invoiceCopy);
        invoiceTemplate.totalGross = computeInvoiceTotalGross(invoiceCopy);

        this.invoiceTemplates.push(invoiceTemplate);

        try {
            await this.invoiceTemplateService.create(invoiceTemplate);
            this.toastService.success('Vorlage hinzugefügt', `Titel: ${templateTitle}`);
        } catch (error) {
            this.toastService.error('Vorlage konnte nicht hinzugefügt werden');
            console.error('COULD_NOT_ADD_INVOICE_TEMPLATE', { error });
            // Remove template in case of error
            this.invoiceTemplates.splice(this.invoiceTemplates.indexOf(invoiceTemplate), 1);
        }
    }

    public showInvoiceTemplates(): void {
        this.invoiceTemplatesShown = true;
    }

    public hideInvoiceTemplates(): void {
        this.invoiceTemplatesShown = false;
        this.selectedInvoiceTemplate = null;
    }

    public showInvoiceTemplateTitleDialog(): void {
        this.invoiceTemplateTitleDialogShown = true;
    }

    public selectInvoiceTemplate(invoiceTemplate: InvoiceTemplate): void {
        this.selectedInvoiceTemplate = invoiceTemplate;
    }

    public async deleteInvoiceTemplate(invoiceTemplate: InvoiceTemplate): Promise<void> {
        this.invoiceTemplates.splice(this.invoiceTemplates.indexOf(invoiceTemplate), 1);

        if (!this.invoiceTemplates.length) {
            this.hideInvoiceTemplates();
        }

        try {
            await this.invoiceTemplateService.delete(invoiceTemplate._id);
        } catch (error) {
            this.toastService.error('Vorlage konnte nicht gelöscht werden');
            console.error('COULD_NOT_DELETE_INVOICE_TEMPLATE', { error });
            this.invoiceTemplates.push(invoiceTemplate);
        }
    }

    public useInvoiceTemplate(invoiceTemplate: InvoiceTemplate) {
        const copyOfTemplate: Invoice = JSON.parse(JSON.stringify(invoiceTemplate));

        Object.assign<InvoiceParameters, Partial<Invoice>>(this.selectedExpertStatement.invoiceParameters, {
            lineItems: copyOfTemplate.lineItems,
            intro: copyOfTemplate.intro,
        });

        this.saveReport();

        this.hideInvoiceTemplates();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Templates
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photos
    //****************************************************************************/
    public setupPhotoEditor({ photo }: { photo: Photo }): void {
        this.initialPhotoForEditor = photo;
    }

    public openPhotoEditor(): void {
        this.photoEditorVisible = true;
    }

    public closeEditor() {
        this.photoEditorVisible = false;
        this.photoGridComponent.reloadChangedPhotoThumbnails();
    }

    public navigateToPhotoList(): void {
        this.router.navigate(['Fotos'], {
            relativeTo: this.route.parent,
            queryParams: {
                fotogruppe: 'Stellungnahme',
            },
        });
    }

    public deletePhotos(photos: Photo[]) {
        for (const photo of photos) {
            removeFromArray(photo, this.report.photos);
        }
        this.saveReport();
    }

    public savePhotos() {
        /**
         * Remove all photos used in this expert statement from the photo list of the report. They will be added to the beginning of the photo
         * list after that. This ensures that the order is correct.
         */
        this.photos.forEach((expertStatementPhoto) => {
            const indexInPhotoList: number = this.report.photos.findIndex(
                (reportPhoto) => reportPhoto._id === expertStatementPhoto._id,
            );
            if (indexInPhotoList > -1) {
                this.report.photos.splice(indexInPhotoList, 1);
            }
        });
        /**
         * Add all new expert statement photos to the beginning of the report photo list.
         */
        this.report.photos.unshift(...this.photos);

        this.saveReport();
    }

    public openPhotoFileSelector(): void {
        this.photoGridComponent.selectFilesForUpload();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Locked
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report && this.report.state === 'done';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Locked
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public async saveReport() {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Gutachten nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

<mat-form-field floatLabel="never" *ngIf="calculationItem">
    <textarea
        matInput
        placeholder="Beschreibung"
        [(ngModel)]="calculationItem.description"
        [matAutocomplete]="itemDescriptionAutocompleteHandle"
        (focus)="filterItemDescriptionAutocomplete(calculationItem.description)"
        (ngModelChange)="filterItemDescriptionAutocomplete(calculationItem.description)"
        [disabled]="disabled"
        (blur)="onChange.emit()"
        (keydown)="onKeyDown($event)"
        [axAutofocus]="true"
        cdkTextareaAutosize
        [cdkAutosizeMaxRows]="8"></textarea>

    <mat-icon
        matSuffix
        class="remember-item-description-icon input-toolbar-icon"
        *ngIf="calculationItem.description && !itemDescriptionAutocompleteEntryExists(calculationItem.description)"
        matTooltip="Option merken"
        (click)="
            rememberItemDescriptionAutocompleteEntry(calculationItem.description);
            filterItemDescriptionAutocomplete(calculationItem.description)
        ">
        add
    </mat-icon>
</mat-form-field>

<!--============================================-->
<!-- Item Description Autocomplete -->
<!--============================================-->
<mat-autocomplete #itemDescriptionAutocompleteHandle>
    <mat-option
        *ngFor="let itemDescriptionAutocompleteEntry of filteredItemDescriptionAutocompleteEntries"
        (mousedown)="$event.preventDefault()"
        [value]="itemDescriptionAutocompleteEntry.value">
        <div class="item-description-autocomplete-inner-wrapper">
            <div class="item-description-autocomplete-label" [matTooltip]="itemDescriptionAutocompleteEntry.value">
                {{ itemDescriptionAutocompleteEntry.value }}
            </div>
            <mat-icon
                class="item-description-autocomplete-delete-icon"
                (click)="deleteItemDescriptionAutocompleteEntry(itemDescriptionAutocompleteEntry)"
                matTooltip="Vorlage löschen">
                delete
            </mat-icon>
        </div>
    </mat-option>
</mat-autocomplete>
<!--============================================-->
<!-- END Item Description Autocomplete -->
<!--============================================-->

<span *ngIf="!hideLabel" class="label">{{ paintThicknessMeasurement.title }}</span>

<div
    class="input-container"
    [class.edit-multiple-values]="paintThicknessMeasurement.values?.length > 1 && isEditing"
    [class.disable-actions]="disableActions">
    <!-- Editing a single or multiple values -->
    <ng-container *ngIf="!(paintThicknessMeasurement.values?.length > 1 && !isEditing)">
        <div
            *ngFor="let value of paintThicknessMeasurement.values; let i = index; trackBy: trackByIndex"
            class="paint-thickness-input"
            [attr.data-id]="paintThicknessMeasurement._id"
            [style.--status-color]="PaintThicknessColorMap[type]"
            (click)="inputField.focus()"
            [class.manually-overwritten]="paintThicknessMeasurement.manualType">
            <input
                #inputField
                id="input-field"
                matInput
                number-input
                [class.four-digits]="inputField.value.length >= 4"
                [readonly]="disabled"
                [class.disabled]="disabled"
                [minimumFractionDigits]="0"
                [maximumFractionDigits]="0"
                autocomplete="off"
                (click)="isEditing = true"
                (keydown)="keyDownOnInput($event)"
                (change)="numberChanged()"
                [(number)]="paintThicknessMeasurement.values[i]" />
            <span class="unit">µm</span>

            <span
                *ngIf="!type"
                class="color-circle"
                [matMenuTriggerFor]="paintThicknessTypeMenu"
                (click)="$event.stopPropagation()"></span>

            <mat-icon
                *ngIf="!!type"
                class="status-icon small-icon outlined"
                [matTooltip]="PaintThicknessLabelMap[type]"
                [matMenuTriggerFor]="paintThicknessTypeMenu"
                (click)="$event.stopPropagation()">
                {{ type === 'original' ? 'check' : type === 'none' ? 'block' : 'warning' }}
            </mat-icon>

            <mat-icon class="delete-icon small-icon outlined" (click)="deleteValue(i)">delete</mat-icon>
        </div>
    </ng-container>

    <!-- Summary of multiple values -->
    <ng-container *ngIf="paintThicknessMeasurement.values?.length > 1 && !isEditing">
        <div
            class="paint-thickness-input"
            [attr.data-id]="paintThicknessMeasurement._id"
            (click)="clickedSummaryLabel($event)"
            [style.--status-color]="PaintThicknessColorMap[type]"
            [class.manually-overwritten]="paintThicknessMeasurement.manualType">
            <span class="summary-label">
                {{ getPaintThicknessSummaryLabel(paintThicknessMeasurement.values) }}
            </span>
            <span class="unit">µm</span>

            <span *ngIf="!type" class="color-circle"></span>

            <mat-icon
                *ngIf="!!type"
                class="status-icon small-icon outlined"
                [matTooltip]="PaintThicknessLabelMap[type]"
                [matMenuTriggerFor]="paintThicknessTypeMenu">
                {{ type === 'original' ? 'check' : type === 'none' ? 'block' : 'warning' }}
            </mat-icon>
        </div>
    </ng-container>

    <mat-menu #paintThicknessTypeMenu="matMenu" class="paint-thickness-type-menu">
        <a
            class="menu-item-with-icon paint-thickness-menu-automatic-detection"
            mat-menu-item
            (click)="selectManualPaintThicknessType({ type: null })"
            [disabled]="disabled">
            Automatisch ermitteln
        </a>
        <ng-container *ngFor="let paintThicknessType of paintThicknessTypes">
            <a
                *ngIf="paintThicknessType !== 'none'"
                mat-menu-item
                class="menu-item-with-icon"
                (click)="selectManualPaintThicknessType({ type: paintThicknessType })"
                [disabled]="disabled">
                <span
                    class="paint-thickness-type-menu-circle"
                    [style.background-color]="PaintThicknessColorMap[paintThicknessType]"></span>

                {{ PaintThicknessLabelMap[paintThicknessType] }}
            </a>
        </ng-container>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="selectManualPaintThicknessType({ type: 'none' })"
            [disabled]="disabled"
            [matMenuTriggerFor]="noMeasurementReasonSubmenu">
            <mat-icon class="paint-thickness-type-menu-icon outlined small-icon">block</mat-icon>

            {{ paintThicknessMeasurement.noMeasurementReason || PaintThicknessLabelMap['none'] }}
        </a>

        <mat-menu #noMeasurementReasonSubmenu class="paint-thickness-type-menu">
            <a mat-menu-item (click)="setNoMeasurementReason({ reason: 'Kunststoff' })" [disabled]="disabled">
                Kunststoff
            </a>
            <a mat-menu-item (click)="setNoMeasurementReason({ reason: 'Glas' })" [disabled]="disabled">Glas</a>
            <a mat-menu-item (click)="setNoMeasurementReason({ reason: 'Stoff' })" [disabled]="disabled">Stoff</a>
            <a mat-menu-item (click)="setNoMeasurementReason({ reason: 'nicht messbar' })" [disabled]="disabled">
                nicht messbar
            </a>
        </mat-menu>

        <a mat-menu-item [matMenuTriggerFor]="applyToAllSubMenu" class="menu-item-with-icon" [disabled]="disabled">
            Für alle setzen
        </a>
        <mat-menu #applyToAllSubMenu class="paint-thickness-type-menu">
            <a
                class="menu-item-with-icon paint-thickness-menu-automatic-detection"
                mat-menu-item
                (click)="selectManualPaintThicknessType({ type: null, forAll: true })"
                [disabled]="disabled">
                Automatisch ermitteln
            </a>

            <ng-container *ngFor="let paintThicknessType of paintThicknessTypes">
                <a
                    *ngIf="paintThicknessType !== 'none'"
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="selectManualPaintThicknessType({ type: paintThicknessType, forAll: true })"
                    [disabled]="disabled">
                    <span
                        class="paint-thickness-type-menu-circle"
                        [style.background-color]="PaintThicknessColorMap[paintThicknessType]"></span>

                    {{ PaintThicknessLabelMap[paintThicknessType] }}
                </a>
            </ng-container>
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="selectManualPaintThicknessType({ type: 'none', forAll: true })"
                [disabled]="disabled"
                [matMenuTriggerFor]="noMeasurementReasonSubmenuForAll">
                <mat-icon class="paint-thickness-type-menu-icon outlined small-icon">block</mat-icon>

                {{ paintThicknessMeasurement.noMeasurementReason || PaintThicknessLabelMap['none'] }}
            </a>

            <mat-menu #noMeasurementReasonSubmenuForAll class="paint-thickness-type-menu">
                <a
                    mat-menu-item
                    (click)="setNoMeasurementReason({ reason: 'Kunststoff', forAll: true })"
                    [disabled]="disabled">
                    Kunststoff
                </a>
                <a
                    mat-menu-item
                    (click)="setNoMeasurementReason({ reason: 'Glas', forAll: true })"
                    [disabled]="disabled">
                    Glas
                </a>
                <a
                    mat-menu-item
                    (click)="setNoMeasurementReason({ reason: 'Stoff', forAll: true })"
                    [disabled]="disabled">
                    Stoff
                </a>
                <a
                    mat-menu-item
                    (click)="setNoMeasurementReason({ reason: 'nicht messbar', forAll: true })"
                    [disabled]="disabled">
                    nicht messbar
                </a>
            </mat-menu>
        </mat-menu>
    </mat-menu>
</div>

<div
    *ngIf="!disableActions && !disabled"
    class="action-button add-icon"
    [matTooltip]="'Weiteren Messwert für diese Position hinzufügen (Enter)'">
    <mat-icon class="small-icon outlined" (click)="addValue()">add_circle</mat-icon>
</div>
<div
    *ngIf="!disableActions"
    class="action-button comment-icon"
    [class.position-bottom]="commentIconPosition === 'bottom'"
    [class.always-visible]="paintThicknessMeasurement.comment"
    [matTooltip]="
        'Kommentar zu Messwerten an dieser Position, der in der Lackschicht-Tabelle im Gutachten abgedruckt wird.'
    ">
    <mat-icon class="small-icon outlined" (click)="commentPopoverShown = true">comment</mat-icon>
</div>

<div *ngIf="commentPopoverShown" class="comment-popover card">
    <mat-form-field class="mat-block">
        <input
            matInput
            placeholder="Kommentar"
            [(ngModel)]="paintThicknessMeasurement.comment"
            (change)="this.valueChange.emit()"
            [axAutofocus]="true"
            [disabled]="disabled" />
    </mat-form-field>
</div>

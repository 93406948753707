import { DateTime } from 'luxon';
import { ObjectStoreAndIndexMigrations, RecordMigrations } from '@autoixpert/models/indexed-db/database.types';
import { OutgoingMessageDraftStructure } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { Report } from '@autoixpert/models/reports/report';

export const reportServiceObjectStoreAndIndexMigrations: ObjectStoreAndIndexMigrations<Report> = {
    '2022-01-07T01:00:00.000+01:00': async (database, transaction) => {
        // Add an index for the report state to increase performance when querying for groups of records.
        const recordsObjectStore = transaction.objectStore('records');
        recordsObjectStore.createIndex('state', 'state');
    },
};

export const reportRecordMigrations: RecordMigrations<Report> = {
    '11': (report: Report & { valuation: { vehicleValueVatDeductible: boolean } }) => {
        console.log('Converting report to _schemaVersion 11');
        if (report.valuation?.hasOwnProperty('vehicleValueVatDeductible')) {
            delete report.valuation.vehicleValueVatDeductible;
        }
        return report;
    },
    /**
     * Convert year of fee table from ISO date string to year number.
     */
    '12': (report: Report & { feeCalculation: { yearOfFeeTable: string | number } }) => {
        console.log('Converting report to _schemaVersion 12');
        if (report.feeCalculation?.yearOfFeeTable && typeof report.feeCalculation.yearOfFeeTable === 'string') {
            report.feeCalculation.yearOfFeeTable = DateTime.fromISO(report.feeCalculation.yearOfFeeTable).year;
        }
        return report;
    },
    /**
     * Remove all sentDocumentEmails and sentStandardEmails from the report object (they live in a dedicated outgoingMessages collection now).
     * This is the frontend equivalent for the backend migration_20250310_1400_migrate_sent_emails_to_outgoing_messages.
     */
    '13': (report: Report) => {
        console.log('Converting report to _schemaVersion 13');
        if (report.claimant) {
            report.claimant.outgoingMessageDraft = new OutgoingMessageDraftStructure();
            delete (report.claimant as any).documentEmail;
            delete (report.claimant as any).standardEmail;
            delete (report.claimant as any).sentDocumentEmails;
            delete (report.claimant as any).sentStandardEmails;
        }

        if (report.garage) {
            report.garage.outgoingMessageDraft = new OutgoingMessageDraftStructure();
            delete (report.garage as any).documentEmail;
            delete (report.garage as any).standardEmail;
            delete (report.garage as any).sentDocumentEmails;
            delete (report.garage as any).sentStandardEmails;
        }

        if (report.insurance) {
            report.insurance.outgoingMessageDraft = new OutgoingMessageDraftStructure();
            delete (report.insurance as any).documentEmail;
            delete (report.insurance as any).standardEmail;
            delete (report.insurance as any).sentDocumentEmails;
            delete (report.insurance as any).sentStandardEmails;
        }

        if (report.lawyer) {
            report.lawyer.outgoingMessageDraft = new OutgoingMessageDraftStructure();
            delete (report.lawyer as any).documentEmail;
            delete (report.lawyer as any).standardEmail;
            delete (report.lawyer as any).sentDocumentEmails;
            delete (report.lawyer as any).sentStandardEmails;
        }

        if (report.leaseProvider) {
            report.leaseProvider.outgoingMessageDraft = new OutgoingMessageDraftStructure();
            delete (report.leaseProvider as any).documentEmail;
            delete (report.leaseProvider as any).standardEmail;
            delete (report.leaseProvider as any).sentDocumentEmails;
            delete (report.leaseProvider as any).sentStandardEmails;
        }

        if (report.seller) {
            report.seller.outgoingMessageDraft = new OutgoingMessageDraftStructure();
            delete (report.seller as any).documentEmail;
            delete (report.seller as any).standardEmail;
            delete (report.seller as any).sentDocumentEmails;
            delete (report.seller as any).sentStandardEmails;
        }

        return report;
    },
    //*****************************************************************************
    //  Remove markAsDuplicate from all documents
    //****************************************************************************/
    '14': (report: Report) => {
        if (report.documents?.length) {
            for (const document of report.documents) {
                delete (document as any).markAsDuplicate;
            }
        }
        report._documentVersion++;

        return report;
    },

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove markAsDuplicate from all documents
    /////////////////////////////////////////////////////////////////////////////*/
};

import { HttpParams } from '@angular/common/http';
import { LabelConfig } from '@autoixpert/models/labels/label-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { User } from '@autoixpert/models/user/user';
import { getTimeRangeParams } from '../../libraries/get-time-range-params';

/**
 * All possible filter options that can be passed to the backend. Most of them are not
 * mandatory.
 */
export interface AnalyticsFilterOptions {
    dateField?: string;
    from: string;
    to: string;
    net?: boolean;
    officeLocationIds?: OfficeLocation['_id'][];
    assessorIds?: User['_id'][];
    reportLabelIds?: LabelConfig['_id'][];
    invoiceLabelIds?: LabelConfig['_id'][];
}

/**
 * Prepare default parameters for analytics query:
 * - Time range parameters
 * - Office location filter
 * - Assessor filter
 * - Report label filter
 * - Invoice label filter
 */
export function prepareDefaultParametersForAnalyticsQuery({
    dateField,
    from,
    to,
    officeLocationIds,
    assessorIds,
    reportLabelIds,
    invoiceLabelIds,
}: AnalyticsFilterOptions): HttpParams {
    let params = getTimeRangeParams({
        field: dateField,
        from,
        to,
    });

    // Only add office locations filter if at least one office location is selected (no results otherwise)
    if (officeLocationIds && officeLocationIds.length > 0) {
        officeLocationIds.forEach((officeLocationId) => {
            params = params.append('officeLocationIds[]', officeLocationId);
        });
    }

    // Only add assessor filter if at least one assessor is selected (no results otherwise)
    if (assessorIds && assessorIds.length > 0) {
        assessorIds.forEach((assessorId) => {
            params = params.append('assessorIds[]', assessorId);
        });
    }

    // Only add report label filter if at least one report label is selected (no results otherwise)
    if (reportLabelIds && reportLabelIds.length > 0) {
        reportLabelIds.forEach((reportLabelId) => {
            params = params.append('reportLabelIds[]', reportLabelId);
        });
    }

    // Only add invoice label filter if at least one invoice label is selected (no results otherwise)
    if (invoiceLabelIds && invoiceLabelIds.length > 0) {
        invoiceLabelIds.forEach((invoiceLabelId) => {
            params = params.append('invoiceLabelIds[]', invoiceLabelId);
        });
    }

    return params;
}

import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';
import { DataTypeBase } from '../../indexed-db/database.types';

export class EmailSignature implements DataTypeBase {
    constructor(options: Partial<EmailSignature>) {
        Object.assign(this, options);

        if (!this._id) {
            this._id = generateId();
        }
    }

    _id: string = null;
    content: string = null;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string = null;
    teamId: string = null;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

import { generateId } from '../../../lib/generate-id';
import { Tire } from './tire';

export class Axle {
    constructor(template: Partial<Axle> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId(); // Necessary to allow three-way merge.
    axleNumber: number;
    axlePosition: 'front' | 'rear';
    axleLoad: number;
    outerLeftTire?: Tire; // Semi trucks and trailers
    leftTire?: Tire;
    centerTire?: Tire; // Motorcycles
    rightTire?: Tire;
    outerRightTire?: Tire; // Semi trucks and trailers
    isSteerable?: boolean;
}

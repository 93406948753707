<div class="dialog-overlay" @dialogEnterAndLeave>
    <div class="dialog-container contains-scrollable-area">
        <!--============================================-->
        <!-- Table of Contents -->
        <!--============================================-->
        <div class="table-of-contents-column">
            <div class="table-of-contents-container card dark-card">
                <div class="table-of-contents-heading-container">
                    <h2 class="table-of-contents-heading">Inhalt</h2>
                    <mat-icon
                        class="table-of-contents-submenu-icon toolbar-icon"
                        [matMenuTriggerFor]="tableOfContentsHeadingSubmenu">
                        more_vert
                    </mat-icon>
                    <mat-menu #tableOfContentsHeadingSubmenu>
                        <mat-option
                            (click)="resetAllDocumentBuildingBlocks(); emitEditedBuildingBlockChange()"
                            matTooltip="Alle Änderungen werden verworfen.">
                            Bausteine auf Standard zurücksetzen
                        </mat-option>
                    </mat-menu>
                </div>
                <div class="table-of-contents-loader" *ngIf="generatedBuildingBlocksPending">
                    <img class="pending-icon" src="/assets/images/icons/loading-indicator.svg" alt="Lade-Icon" />
                </div>
                <div
                    class="table-of-contents-list"
                    *ngIf="!generatedBuildingBlocksPending && generatedBuildingBlocks.length">
                    <div
                        class="table-of-contents-item-container"
                        *ngFor="let generatedBuildingBlock of generatedBuildingBlocks">
                        <div
                            class="table-of-contents-list-item clickable"
                            (click)="
                                scrollIntoView(
                                    '#block-' + generatedBuildingBlock.placeholder,
                                    generatedBuildingBlock.placeholder
                                )
                            ">
                            <mat-icon
                                class="edited-icon small-icon"
                                [ngClass]="{ visible: buildingBlockIsChanged(generatedBuildingBlock) }"
                                matTooltip="Dieser Baustein wurde bearbeitet">
                                edit
                            </mat-icon>
                            <span class="table-of-contents-text-container">
                                {{
                                    generatedBuildingBlock.heading ||
                                        generatedBuildingBlock.title ||
                                        'Keine Überschrift'
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Table of Contents -->
        <!--============================================-->

        <!--============================================-->
        <!-- Building Block Column -->
        <!--============================================-->
        <div id="building-blocks-column">
            <!--============================================-->
            <!-- Head Runner -->
            <!--============================================-->
            <div id="head-runner" class="card margin-bottom-10">
                <!--********** Heading **********-->
                <h2 class="no-margin">Textbausteine anpassen</h2>

                <!--********** Search Field **********-->
                <mat-form-field class="search-field" floatLabel="never">
                    <input matInput [(ngModel)]="searchTerm" (input)="filterBuildingBlocks()" placeholder="Suchen" />
                    <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>

                <!--********** Close Button **********-->
                <button (click)="closeDialog()">Schließen</button>
            </div>
            <!--============================================-->
            <!-- END Head Runner -->
            <!--============================================-->

            <!--============================================-->
            <!-- Building Block Card -->
            <!--============================================-->
            <div id="building-blocks-card" class="card">
                <!--============================================-->
                <!-- Instructions -->
                <!--============================================-->
                <div id="instructions-info-note" class="info-note">
                    <mat-icon>info</mat-icon>
                    <div>
                        Verändere die zusammengestellten Textbausteine für dieses Gutachten. Für dauerhafte oder
                        wiederkehrende Änderungen bearbeite deine
                        <span class="link" (click)="navigateToDocumentBuildingBlocks()">
                            Textbausteine in den Einstellungen.
                        </span>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Instructions -->
                <!--============================================-->

                <!--============================================-->
                <!-- Building Blocks -->
                <!--============================================-->
                <div id="building-block-list">
                    <div
                        [id]="'block-' + buildingBlock.placeholder"
                        class="building-block-container"
                        [ngClass]="{
                            'front-page-block': buildingBlock.placeholder.includes('Deckblatt'),
                            'in-edit-mode': buildingBlocksInEditMode.has(buildingBlock),
                        }"
                        *ngFor="let buildingBlock of filteredGeneratedBuildingBlocks">
                        <!--============================================-->
                        <!-- Read Mode -->
                        <!--============================================-->
                        <div
                            class="building-block-container-read-mode"
                            *ngIf="!buildingBlocksInEditMode.has(buildingBlock)"
                            (dblclick)="enterEditMode(buildingBlock)"
                            [class.may-not-be-printed]="mayDocumentBuildingBlockNotBePrinted(buildingBlock)">
                            <mat-icon
                                class="edited-icon medium-icon"
                                [ngClass]="{ visible: buildingBlockIsChanged(buildingBlock) }"
                                matTooltip="Dieser Baustein wurde bearbeitet">
                                edit
                            </mat-icon>
                            <div class="heading-and-toolbar-container">
                                <!--********** Heading **********-->
                                <h2 class="building-block-heading">
                                    <span *ngIf="buildingBlock.heading">
                                        {{ buildingBlock.heading }}
                                        <span
                                            class="label building-block-placeholder"
                                            *ngIf="buildingBlock.title !== buildingBlock.heading"
                                            matTooltip="Titel des Bausteins in der Textbausteinverwaltung">
                                            {{ buildingBlock.title }}
                                        </span>
                                    </span>
                                    <span
                                        *ngIf="!buildingBlock.heading"
                                        class="no-heading"
                                        matTooltip="Es wird keine Überschrift abgedruckt.{{
                                            '\n\n'
                                        }}Zur Orientierung wird dir der Titel des Textbausteins angezeigt.">
                                        [{{ buildingBlock.title }}]
                                    </span>
                                </h2>

                                <!--********** Toolbar **********-->
                                <div class="heading-toolbar">
                                    <!--********** Edit Building Block **********-->
                                    <mat-icon
                                        class="toolbar-icon"
                                        (click)="enterEditMode(buildingBlock)"
                                        matTooltip="Textbaustein bearbeiten">
                                        edit
                                    </mat-icon>

                                    <!--********** Submenu **********-->
                                    <mat-icon
                                        class="toolbar-icon"
                                        *ngIf="
                                            buildingBlock.heading ||
                                            buildingBlock.content ||
                                            buildingBlockIsChanged(buildingBlock)
                                        "
                                        [matMenuTriggerFor]="buildingBlockReadModeSubmenu">
                                        more_vert
                                    </mat-icon>

                                    <mat-menu #buildingBlockReadModeSubmenu>
                                        <!--********** Empty Building Block **********-->
                                        <!-- Hiding is the same as clearing its contents. -->
                                        <a
                                            mat-menu-item
                                            class="menu-item-with-icon"
                                            *ngIf="buildingBlock.heading || buildingBlock.content"
                                            (click)="emptyBuildingBlock(buildingBlock)"
                                            matTooltip="Baustein leeren">
                                            <mat-icon>delete</mat-icon>
                                            Nicht abdrucken
                                        </a>

                                        <!--********** Reset Building Block **********-->
                                        <a
                                            mat-menu-item
                                            class="menu-item-with-icon"
                                            *ngIf="buildingBlockIsChanged(buildingBlock)"
                                            (click)="resetBuildingBlock(buildingBlock)"
                                            matTooltip="Eigene Änderungen verwerfen">
                                            <mat-icon>undo</mat-icon>
                                            Baustein zurücksetzen
                                        </a>
                                    </mat-menu>
                                </div>
                            </div>

                            <!--============================================-->
                            <!-- Building Block Graphic -->
                            <!--============================================-->
                            <div
                                class="building-block-graphic"
                                *ngIf="
                                    !buildingBlock.placeholder.startsWith('Deckblatt') &&
                                    documentBuildingBlocksEnhancedWithGraphic.includes(buildingBlock.placeholder)
                                "
                                matTooltip="Zun Ansicht dieser erweiterten Abbildung lade die PDF oder DOCX-Datei dieses Dokumentes herunter."
                                [ngSwitch]="buildingBlock.placeholder">
                                <ng-container *ngSwitchCase="'Brieffenster'">Anschrift</ng-container>
                                <ng-container *ngSwitchCase="'Inhaltsverzeichnis'">Inhaltsverzeichnis</ng-container>
                                <ng-container *ngSwitchCase="'Zustand'">Zustandstabelle</ng-container>
                                <ng-container *ngSwitchCase="'Bereifung'">Reifengrafik oder -tabelle</ng-container>
                                <ng-container *ngSwitchCase="'Reparaturkostenkalkulation'">
                                    Reparaturkostenkalkulation
                                </ng-container>
                                <ng-container *ngSwitchCase="'Anstoßskizze'">Anstoßgrafik</ng-container>
                                <!--**********
                                This block "ZustandDesFahrzeugsBeiBesichtigung" does not contain any graphic but it contains a special loop which allows one document building block per visit. This format
                                is unique, so building an interface to edit it would not be worth it. Instead, it should be updated via MS Word.
                                **********-->
                                <ng-container *ngSwitchCase="'ZustandDesFahrzeugsBeiBesichtigung'">
                                    Zustand des Fahrzeugs bei Besichtigung
                                </ng-container>
                                <ng-container *ngSwitchCase="'Werkstattkostensätze'">
                                    ggf. Stundensätze der Werkstatt
                                </ng-container>
                                <ng-container *ngSwitchCase="'Gutachtenfertigstellung'">
                                    Unterschrift & Stempel
                                </ng-container>
                                <ng-container *ngSwitchCase="'Fotoanlage'">Fotos</ng-container>
                                <ng-container *ngSwitchDefault>Tabelle</ng-container>
                            </div>
                            <!--============================================-->
                            <!-- END Building Block Graphic -->
                            <!--============================================-->

                            <!--********** Block will be printed **********-->
                            <div
                                class="building-block-content"
                                *ngIf="!mayDocumentBuildingBlockNotBePrinted(buildingBlock)"
                                [quillContent]="buildingBlock.content"></div>

                            <!--********** Block won't be printed **********-->
                            <div
                                class="building-block-content"
                                *ngIf="mayDocumentBuildingBlockNotBePrinted(buildingBlock)">
                                <span class="label">Textbaustein wird ohne Inhalt nicht gedruckt.</span>
                            </div>
                        </div>
                        <!--============================================-->
                        <!-- END Read Mode -->
                        <!--============================================-->

                        <!--============================================-->
                        <!-- Edit Mode -->
                        <!--============================================-->
                        <div
                            class="building-block-container-edit-mode"
                            *ngIf="buildingBlocksInEditMode.has(buildingBlock)">
                            <mat-icon
                                class="edited-icon medium-icon"
                                [ngClass]="{ visible: buildingBlockIsChanged(buildingBlock) }"
                                matTooltip="Dieser Baustein wurde bearbeitet">
                                edit
                            </mat-icon>

                            <mat-form-field class="building-block-heading-form-field mat-block">
                                <input
                                    matInput
                                    placeholder="Überschrift"
                                    [(ngModel)]="buildingBlock.heading"
                                    (change)="emitEditedBuildingBlockChange()" />
                            </mat-form-field>
                            <div class="edit-toolbar">
                                <mat-icon
                                    (click)="leaveEditMode(buildingBlock)"
                                    class="toolbar-icon"
                                    matTooltip="Bearbeitung beenden">
                                    close
                                </mat-icon>

                                <!--********** Submenu **********-->
                                <mat-icon
                                    class="toolbar-icon"
                                    *ngIf="buildingBlockIsChanged(buildingBlock)"
                                    [matMenuTriggerFor]="buildingBlockEditModeSubmenu">
                                    more_vert
                                </mat-icon>
                                <mat-menu #buildingBlockEditModeSubmenu>
                                    <a
                                        mat-menu-item
                                        (click)="resetBuildingBlock(buildingBlock)"
                                        matTooltip="Eigene Änderungen verwerfen">
                                        Baustein zurücksetzen
                                    </a>
                                </mat-menu>
                            </div>

                            <mat-form-field class="building-block-content-form-field mat-block">
                                <mat-quill
                                    placeholder="Inhalt"
                                    [(ngModel)]="buildingBlock.content"
                                    (change)="emitEditedBuildingBlockChange()"></mat-quill>
                            </mat-form-field>
                            <div class="building-block-edit-mode-buttons text-align-center">
                                <button
                                    (click)="leaveEditMode(buildingBlock); markBuildingBlockAsEdited(buildingBlock)">
                                    Bearbeitung beenden
                                </button>
                            </div>
                        </div>
                        <!--============================================-->
                        <!-- END Edit Mode -->
                        <!--============================================-->

                        <!--***********************************************
                        ** Enhanced Document Building Block
                        ************************************************-->
                        <div
                            class="building-block-enhanced"
                            *ngIf="enhancedDocumentBuildingBlocks.includes(buildingBlock.placeholder)"
                            (click)="openEnhancedDocumentBuildingBlockHelp(buildingBlock)"
                            [matTooltip]="'autoiXpert ergänzt Elemente beim Druck.\n\nFür Details klicken.'">
                            <div class="label">erweitert</div>
                            <mat-icon class="icon">verified_user</mat-icon>
                        </div>
                        <!--***********************************************
                        ** END Enhanced Document Building Block
                        ************************************************-->
                    </div>
                </div>
                <!--============================================-->
                <!-- END Building Blocks -->
                <!--============================================-->
            </div>
            <!--============================================-->
            <!-- END Building Block Card -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Building Block Column -->
        <!--============================================-->
    </div>
</div>

import { generateId } from '../../../lib/generate-id';
import { ContactPerson } from '../../contacts/contact-person';

export class InvolvedParty {
    // Via the constructor, the parent class Report creates instances of each InvolvedParty.
    constructor(role: InvolvedPartyRole) {
        this.role = role;
        this.contactPerson = new ContactPerson({
            organizationType: role,
        });
    }

    _id: string = generateId();
    role: InvolvedPartyRole = null;
    contactPerson: ContactPerson = null;
}

export type CommunicationRecipientRole = 'claimant' | 'garage' | 'lawyer' | 'insurance' | 'leaseProvider';

export const communicationRecipientRoles: CommunicationRecipientRole[] = [
    'claimant',
    'garage',
    'lawyer',
    'insurance',
    'leaseProvider',
];

export type InvolvedPartyRole =
    | CommunicationRecipientRole
    | 'ownerOfClaimantsCar'
    | 'authorOfDamage'
    | 'ownerOfAuthorOfDamagesCar'
    | 'factoringProvider'
    | 'seller'; // The seller within an acquisition report

export const involvedPartyRoles: InvolvedPartyRole[] = [
    ...communicationRecipientRoles,
    'ownerOfClaimantsCar',
    'authorOfDamage',
    'ownerOfAuthorOfDamagesCar',
    'factoringProvider',
    'seller',
];

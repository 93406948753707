import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { ResponsibleInsuranceResponse } from '@autoixpert/external-apis/gdv/responsible-insurance-response';
import { Report } from '@autoixpert/models/reports/report';
import { getLicensePlateForGdvResponsibleInsuranceQuery } from '../../libraries/gdv/get-license-plate-for-gdv-responsible-insurance-query';

@Injectable()
export class GdvResponsibleInsuranceService {
    constructor(private httpClient: HttpClient) {}

    public find(report: Report): Promise<ResponsibleInsuranceResponse> {
        const licensePlate: string = getLicensePlateForGdvResponsibleInsuranceQuery(report);

        const params: HttpParams = new HttpParams()
            .append('licensePlate', licensePlate)
            .append(
                'insuranceType',
                report.type === 'liability' || report.type === 'shortAssessment' ? 'liability' : 'kasko',
            )
            .append('accidentDate', report.accident.date);

        return this.httpClient
            .get<ResponsibleInsuranceResponse>(`${apiBasePath}/reports/${report._id}/gdvResponsibleInsurance`, {
                params,
            })
            .toPromise();
    }
}

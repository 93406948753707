import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { Report } from '@autoixpert/models/reports/report';
import { getDocumentOrdersOfCommunicationRecipientsFromReport } from './get-document-orders-of-communication-recipients-from-report';
import { removeDocument } from './remove-document';

/**
 * Remove the document metadata object from report.documents and remove its reference from all involved parties' document orders.
 */
export function removeDocumentFromReport({
    document,
    report,
    documentGroup = 'both',
}: {
    document: DocumentMetadata;
    report: Report;
    documentGroup: DocumentGroupForDocumentRemoval;
}): void {
    const documentOrders: DocumentOrder[] = getDocumentOrdersOfCommunicationRecipientsFromReport({
        report,
        documentGroup,
    });

    removeDocument({
        document: document,
        documents: report.documents,
        documentOrders,
    });
}

export type DocumentGroupForDocumentRemoval = 'report' | 'repairConfirmation' | 'both';

<mat-form-field class="mat-block">
    <!--============================================-->
    <!-- Input / Textarea -->
    <!--============================================-->
    <input
        matInput
        *ngIf="!useTextarea"
        [placeholder]="inputPlaceholder"
        [(ngModel)]="text"
        (ngModelChange)="emitNgModelChange($event)"
        [matAutocomplete]="placeholderAutocomplete"
        (focus)="inputFocused.emit(); openAutocompleteOnMatchingPlaceholder(inputElement)"
        (keyup)="openAutocompleteOnMatchingPlaceholder(inputElement)"
        [axAutofocus]="axAutofocus"
        [disabled]="disabled"
        [matTooltip]="tooltip"
        #inputElement />
    <textarea
        matInput
        *ngIf="useTextarea"
        [placeholder]="inputPlaceholder"
        [(ngModel)]="text"
        (ngModelChange)="emitNgModelChange($event)"
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="1"
        [cdkAutosizeMaxRows]="8"
        #cdkTextareaAutosizeDirective="cdkTextareaAutosize"
        [matAutocomplete]="placeholderAutocomplete"
        (keyup)="openAutocompleteOnMatchingPlaceholder(inputElement)"
        (focus)="inputFocused.emit(); openAutocompleteOnMatchingPlaceholder(inputElement)"
        [axAutofocus]="axAutofocus"
        [disabled]="disabled"
        [matTooltip]="tooltip"
        #inputElement></textarea>
    <!--============================================-->
    <!-- END Input / Textarea -->
    <!--============================================-->

    <!--============================================-->
    <!-- Autocomplete -->
    <!--============================================-->
    <mat-autocomplete #placeholderAutocomplete>
        <mat-option
            *ngFor="let placeholder of filteredPlaceholders"
            class="compact-option"
            [value]="placeholder"
            (mousedown)="$event.preventDefault()"
            (onSelectionChange)="handleAutocompleteSelection($event)">
            {{ placeholder }}
        </mat-option>
    </mat-autocomplete>
    <!--============================================-->
    <!-- END Autocomplete -->
    <!--============================================-->
    <!-- The matSuffix attribute needs to be placed here instead of on the projected content. Otherwise it won't be
         recognized by the mat-form-field as suffix -->
    <ng-container matSuffix>
        <!-- Let parent components define a suffix for the input -->
        <ng-content select="[placeholder-autocomplete-input-suffix]"></ng-content>
    </ng-container>
</mat-form-field>

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, EventEmitter, HostListener, Injector, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { isReportLocked } from '@autoixpert/lib/report/is-report-locked';
import { Report, ReportType } from '@autoixpert/models/reports/report';
import { User } from '@autoixpert/models/user/user';
import {
    REPORT_TYPE_CHANGE_SUBJECT,
    REPORT_TYPE_DIALOG,
    ReportTypeDialogComponent,
} from './report-type-dialog.component';

@Directive({
    selector: '[report-type-dialog-anchor]',
})
export class ReportTypeDialogAnchor {
    constructor(
        private overlayService: Overlay,
        private injector: Injector,
        private elementRef: ElementRef,
    ) {}

    @Input() report: Report;

    @Output() reportType: Report['type'];
    @Output() changedReportType: EventEmitter<ReportType | 'repairConfirmation'> = new EventEmitter();
    @Output() panelClose: EventEmitter<void> = new EventEmitter();

    public user: User;
    private overlayRef: OverlayRef;

    @HostListener('click', ['$event'])
    public openReportTypeDialog() {
        if (this.overlayRef || (this.report && isReportLocked(this.report))) return;

        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'report-dialog-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                ])
                .withPush(false)
                .withViewportMargin(20), // withPush is default true but causes overlay to size 100% viewport.
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        // Detach instead of dispose to allow the dialog to fade out. Disposal is handled after the animation finishes.
        this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        const changeListener = new Subject<ReportType | 'repairConfirmation'>();
        changeListener.subscribe((reportType) => this.changedReportType.emit(reportType));

        const panelCloseListener = new Subject<void>();
        panelCloseListener.subscribe(() => this.panelClose.emit());

        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: REPORT_TYPE_DIALOG,
                    useValue: this.report,
                },
                {
                    provide: REPORT_TYPE_CHANGE_SUBJECT,
                    useValue: changeListener,
                },
                {
                    provide: OverlayRef,
                    useValue: this.overlayRef,
                },
            ],
        });

        const componentPortal = new ComponentPortal<ReportTypeDialogComponent>(
            ReportTypeDialogComponent,
            null,
            injector,
        );

        this.overlayRef.attach(componentPortal);
    }
}

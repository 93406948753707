/**
 * Reparation duration is at least the sum of the garage and lacquer shop workdays.
 * All Fractions in the duration have to be rounded up, since we assume that it is not realistic to finish the repair earlier.
 *
 * There may be two different cases:
 * - If the lacquer shop is located right with the garage (or it is the same company), garage and lacquer shop may work on the car at the same day.
 *   For example, the garage repairs in the morning and the lacquer shop in the afternoon.
 * - If the lacquer shop is located at a different place, the car has to be transported to the lacquer shop.
 *   The repair at the lacquer shop will therefore start on the next morning, even if the garage finished on the morning the day before.
 *
 * We acknowledge this by rounding up the sum of the garage and lacquer shop workdays:
 * - If the fraction days of garage and lacquer shop sum up to less than 1, we assume that it is possible that they work on the same day (minDays) or that they need an extra day(maxDays).
 * - If the fraction days sum up to more than 1, we assume that the need both days full (minDays = maxDays).
 *
 * Examples:
 * - Garage 1 days, lacquer shop 1.2 days --> 3 days
 * - Garage 1.2 days, lacquer shop 1.2 days --> min: 3 days, max: 4 days
 * - Garage 1.8 days, lacquer shop 1.8 days --> 4 days
 */
export function calculateRepairDuration({
    garageLaborDays = 0,
    lacquerLaborDays = 0,
}: {
    garageLaborDays?: number;
    lacquerLaborDays?: number;
}): { minDays: number; maxDays: number } {
    const sumFractionDays = (garageLaborDays % 1) + (lacquerLaborDays % 1);

    let minDays = 0;
    const maxDays = Math.max(1, Math.ceil(garageLaborDays) + Math.ceil(lacquerLaborDays));

    if (sumFractionDays > 1) {
        // If the sum of the fraction days is more than 1, we assume that both shops need both days full.
        minDays = maxDays;
    } else {
        // If the sum of the fraction days is less than 1, we assume that it is possible that they work on the same day (minDays) or that they need an extra day(maxDays).
        minDays = Math.max(1, Math.ceil(garageLaborDays + lacquerLaborDays));
    }

    return { minDays, maxDays };
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ShortPaymentPayersByInsuranceAnalyticsRecord,
    ShortPaymentReasonsByInsuranceAnalyticsRecord,
    ShortPaymentResolutionsByInsuranceAnalyticsRecord,
    ShortPaymentStatusByInsuranceAnalyticsRecord,
} from '@autoixpert/models/analytics/revenue-analytics.model';
import { AnalyticsFilterOptions, prepareDefaultParametersForAnalyticsQuery } from './default-analytics-query-parameter';

@Injectable()
export class ShortPaymentAnalyticsService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getShortPaymentStatusByInsurance(
        filterOptions: AnalyticsFilterOptions,
    ): Promise<ShortPaymentStatusByInsuranceAnalyticsRecord[]> {
        return this.httpClient
            .get<ShortPaymentStatusByInsuranceAnalyticsRecord[]>(
                `${this.apiPath}/analytics/shortPaymentsStatusByInsurance`,
                {
                    params: prepareDefaultParametersForAnalyticsQuery({
                        dateField: 'date',
                        ...filterOptions,
                    }),
                },
            )
            .toPromise();
    }
    public getShortPaymentReasonsByInsurance(
        filterOptions: AnalyticsFilterOptions,
    ): Promise<ShortPaymentReasonsByInsuranceAnalyticsRecord[]> {
        return this.httpClient
            .get<ShortPaymentReasonsByInsuranceAnalyticsRecord[]>(
                `${this.apiPath}/analytics/shortPaymentsReasonsByInsurance`,
                {
                    params: prepareDefaultParametersForAnalyticsQuery({
                        dateField: 'date',
                        ...filterOptions,
                    }),
                },
            )
            .toPromise();
    }
    public getShortPaymentResolutionsByInsurance(
        filterOptions: AnalyticsFilterOptions,
    ): Promise<ShortPaymentResolutionsByInsuranceAnalyticsRecord[]> {
        return this.httpClient
            .get<ShortPaymentResolutionsByInsuranceAnalyticsRecord[]>(
                `${this.apiPath}/analytics/shortPaymentResolutionsByInsurance`,
                {
                    params: prepareDefaultParametersForAnalyticsQuery({
                        dateField: 'date',
                        ...filterOptions,
                    }),
                },
            )
            .toPromise();
    }
    public getShortPaymentPayersByInsurance(
        filterOptions: AnalyticsFilterOptions,
    ): Promise<ShortPaymentPayersByInsuranceAnalyticsRecord[]> {
        return this.httpClient
            .get<ShortPaymentPayersByInsuranceAnalyticsRecord[]>(
                `${this.apiPath}/analytics/shortPaymentPayersByInsurance`,
                {
                    params: prepareDefaultParametersForAnalyticsQuery({
                        dateField: 'date',
                        ...filterOptions,
                    }),
                },
            )
            .toPromise();
    }
}

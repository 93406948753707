/**
 * Trigger a click event on the target element of the keyboard event if
 * pressing the space bar triggered the keyboard event.
 * @param event
 */
export function triggerClickEventOnSpaceBarPress(event: KeyboardEvent): void {
    if (event.key === ' ') {
        event.target.dispatchEvent(new MouseEvent('click'));
        event.preventDefault();
    }
}

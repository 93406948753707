<mat-form-field class="mat-block">
    <input
        matInput
        name="monat"
        [placeholder]="placeholder"
        [value]="dateString"
        [disabled]="disabled"
        [matDatepicker]="datepicker"
        (dateChange)="handleInputYearSelection($event)" />
    <mat-datepicker-toggle matSuffix [for]="datepicker" [disableRipple]="true">
        <mat-icon matDatepickerToggleIcon class="datepicker-toggle-icon" matTooltip="Jahr auswählen">today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker
        #datepicker
        startView="multi-year"
        (yearSelected)="handleDatepickerYearSelection($event)"></mat-datepicker>
</mat-form-field>

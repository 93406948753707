import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Report } from '@autoixpert/models/reports/report';

@Component({
    selector: 'valuation-result-edit-value-popover',
    templateUrl: './valuation-result-edit-original-price-popover.component.html',
    styleUrls: ['./valuation-result-edit-original-price-popover.component.scss'],
})
export class ValuationResultEditOriginalPricePopoverComponent {
    @Input() report: Report;
    @Output() valueChange: EventEmitter<void> = new EventEmitter<void>();
}

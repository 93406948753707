import { Report } from '@autoixpert/models/reports/report';
import { getVatRateForTaxationType } from '../car-valuation/taxation-rates';
import { mayCarOwnerDeductTaxes } from '../report/may-car-owner-deduct-taxes';
import { getTotalCorrectionsGross, getTotalCorrectionsNet } from './valuation-total-corrections';

export function calculateValuationValues(report: Report) {
    // Shorthand
    const valuation = report.valuation;

    // If the user only provided one base value, assume that value stream also the correction target.
    if (valuation.baseValueDealerPurchaseGross && !valuation.baseValueDealerSalesGross) {
        valuation.correctionsTarget = 'dealerPurchase';
    } else if (!valuation.baseValueDealerPurchaseGross && valuation.baseValueDealerSalesGross) {
        valuation.correctionsTarget = 'dealerSales';
    }

    const totalCorrectionsGross = getTotalCorrectionsGross(report);
    const totalCorrectionsNet = getTotalCorrectionsNet(report);

    /**
     * AutoiXpert allows a very flexible way of applying corrections to the vehicle value.
     * Each case needs to be understandable having only the printed values in the reports.
     * Therefore the calculation logic ensures, that all values are calculated using the visible values.
     * And that the ratio between net and gross values is always correct.
     *
     * The assessor may decide whether the corrections should be applied to the purchase or sales price.
     * In most valuations, only one of the two values is printed. In this case, the corrections must be calculated using the visible values.
     * If both values are printed, the assessor may decide which value is the main value.
     * The second value is then calculated from the first value using the trade margin.
     *
     * Depending on the vat deduction of the claimant, autoiXpert also ensures, that corrections are applied in an understandable way:
     * If the claimant may deduct VAT, the net value is corrected by the net value of the corrections.
     * The gross value is then calculated from the corrected net value, to ensure the correct VAT rate between net an gross values.
     * If the claimant may not deduct VAT, the gross value is corrected by the gross value of the corrections and net is calculated.
     */
    switch (valuation.correctionsTarget) {
        case 'dealerPurchase':
            //*****************************************************************************
            //  Purchase Price
            //****************************************************************************/
            // Vehicle value corresponds to the purchase price.
            if (!valuation.vehicleValueOverwritten) {
                if (mayCarOwnerDeductTaxes(report)) {
                    // Net Value
                    valuation.vehicleValueNet = valuation.baseValueDealerPurchaseNet
                        ? valuation.baseValueDealerPurchaseNet + totalCorrectionsNet
                        : null;

                    // Gross value
                    valuation.vehicleValueGross = valuation.vehicleValueNet
                        ? valuation.vehicleValueNet * (mayCarOwnerDeductTaxes(report) ? 1.19 : 1)
                        : null;
                } else {
                    // Gross value
                    valuation.vehicleValueGross = valuation.baseValueDealerPurchaseGross
                        ? valuation.baseValueDealerPurchaseGross + totalCorrectionsGross
                        : null;

                    // Net Value
                    valuation.vehicleValueNet = valuation.vehicleValueGross
                        ? valuation.vehicleValueGross / (mayCarOwnerDeductTaxes(report) ? 1.19 : 1)
                        : null;
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Purchase Price
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Derive Dealer Sales Price
            //****************************************************************************/
            // Sales price = purchase price / (1 - marginPercent/100)
            if (!valuation.secondVehicleValueOverwritten) {
                // Gross value
                valuation.secondVehicleValueGross =
                    (valuation.vehicleValueGross || 0) / (1 - valuation.tradeMarginGrossPercent / 100);

                // Net Value
                valuation.secondVehicleValueNet =
                    (valuation.secondVehicleValueGross || 0) / (1 + getVatRateForTaxationType(valuation.taxationType));
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Derive Dealer Sales Price
            /////////////////////////////////////////////////////////////////////////////*/

            break;
        case 'dealerSales':
            //*****************************************************************************
            //  Dealer Sales Price
            //****************************************************************************/
            if (!valuation.secondVehicleValueOverwritten) {
                if (mayCarOwnerDeductTaxes(report)) {
                    // Net value
                    valuation.secondVehicleValueNet = valuation.baseValueDealerSalesNet
                        ? valuation.baseValueDealerSalesNet + totalCorrectionsNet
                        : null;

                    // Gross value
                    valuation.secondVehicleValueGross = valuation.secondVehicleValueNet
                        ? valuation.secondVehicleValueNet * (1 + getVatRateForTaxationType(valuation.taxationType))
                        : null;
                } else {
                    // Gross value
                    valuation.secondVehicleValueGross = valuation.baseValueDealerSalesGross
                        ? valuation.baseValueDealerSalesGross + totalCorrectionsGross
                        : null;

                    // Net value
                    valuation.secondVehicleValueNet = valuation.secondVehicleValueGross
                        ? valuation.secondVehicleValueGross / (1 + getVatRateForTaxationType(valuation.taxationType))
                        : null;
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Dealer Sales Price
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Derive Dealer Purchase Price
            //****************************************************************************/
            // Purchase price = sales price * (1 - marginPercent/100)
            if (!valuation.vehicleValueOverwritten) {
                // Gross value
                valuation.vehicleValueGross =
                    (valuation.secondVehicleValueGross || 0) * (1 - (valuation.tradeMarginGrossPercent || 0) / 100);

                // Net Value
                // If the owner may deduct VAT, the net and gross values should differ.
                valuation.vehicleValueNet =
                    (valuation.vehicleValueGross || 0) / (mayCarOwnerDeductTaxes(report) ? 1.19 : 1);
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Derive Dealer Purchase Price
            /////////////////////////////////////////////////////////////////////////////*/
            break;
    }
}

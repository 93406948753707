import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Payment } from '@autoixpert/models/invoices/payment';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';

@Component({
    selector: 'quick-payment',
    templateUrl: 'quick-payment.component.html',
    styleUrls: ['quick-payment.component.scss'],
})
export class QuickPaymentComponent {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private tutorialStateService: TutorialStateService,
    ) {}

    @Input() invoice: Invoice;
    @Output() remainderPayment: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit() {}

    public recordRemainderPayment(): void {
        const paidAmount = this.invoice.payments.reduce((sum, payment) => sum + payment.amount, 0);

        this.invoice.payments.push(
            new Payment({
                amount: this.invoice.totalGross - paidAmount,
                date: todayIso(),
                createdAt: moment().format(),
                type: 'payment',
                createdBy: this.loggedInUserService.getUser()._id,
            }),
        );

        this.invoice.hasOutstandingPayments = false;

        this.tutorialStateService.markUserTutorialStepComplete('paymentAdded');
    }

    public emitFullPaymentEvent() {
        this.remainderPayment.emit();
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { GoogleMapsPredictionItem } from '@autoixpert/external-apis/google-maps/places-response';
import { iconForCarBrandExists, iconNameForCarBrand } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { AddressAutocompletion } from '@autoixpert/models/address-autocompletion';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { User } from '@autoixpert/models/user/user';
import { areContactPeopleEqual } from '../../libraries/are-contact-people-equal';
import { applyMongoQuery } from '../../libraries/database/apply-mongo-query';
import { getFileNameForInsuranceLogo, insuranceLogoExists } from '../../libraries/insurances/insurance-logo-exists';
import { ApiErrorService } from '../../services/api-error.service';
import { ContactPersonService } from '../../services/contact-person.service';
import { AddressAutocompletionService } from '../../services/google/address-autocompletion.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { NetworkStatusService } from '../../services/network-status.service';
import { ToastService } from '../../services/toast.service';
import { FeathersQuery } from '../../types/feathers-query';

@Component({
    selector: 'address-autocompletion',
    templateUrl: './address-autocompletion.component.html',
    styleUrls: ['./address-autocompletion.component.scss'],
})
export class AddressAutocompletionComponent implements OnInit, OnChanges, OnDestroy {
    constructor(
        private toastService: ToastService,
        private contactPersonService: ContactPersonService,
        private loggedInUserService: LoggedInUserService,
        private addressAutocompletionService: AddressAutocompletionService,
        private apiErrorService: ApiErrorService,
        private networkStatusService: NetworkStatusService,
    ) {}

    // Address autocompletion with external service (custom Open-Street-Map implementation)
    @Input() externalAddressAutocompletionEnabled: boolean = false;
    // Organization autocompletion with google maps
    @Input() externalOrganizationAutocompletionEnabled: boolean = false;
    // Contact search
    @Input() contactAutocompletionEnabled: boolean = false;

    // Show additional search matches (garage brands)
    @Input() showSearchMatches: boolean = false;

    @Input() showFirstAndLastName: boolean = false;

    @Input() allowDeletion: boolean = true;

    // Filter the autoixpert contacts by organization type
    @Input() organizationTypeFilter: ContactPerson['organizationType'][];
    @Input() additionalContactPeopleForAutocomplete: ContactPerson[] = [];
    @Input() showSuggestionsIfEmpty: boolean = false;
    protected loadedSuggestionsForEmptyState = false;

    @Input() disabled: boolean;

    /**
     * The context of the address autocompletion improves the search accuracy.
     */
    @Input() addressContext: Partial<
        Pick<ContactPerson, 'city' | 'organization' | 'firstName' | 'lastName' | 'streetAndHouseNumberOrLockbox'>
    >;

    /**
     * Bind to the value of the connected input field.
     */
    @Input() value: string;

    @ViewChild('autocomplete') autocomplete: MatAutocomplete;

    /**
     * Emits if the user has selected an address from Google Maps or Open Street Maps.
     */
    @Output() addressSelected = new EventEmitter<Partial<ContactPerson>>();

    /**
     * Emits if the user selects a known ContactPerson from their address book.
     */
    @Output() contactPersonSelected = new EventEmitter<ContactPerson>();

    protected user: User;

    private subscriptions: Subscription[] = [];

    // Custom Open-Street-Map autocompletion for addresses
    public addressAutocompletionPredictions: AddressAutocompletion[] = [];
    public addressAutocompletionSearchTerm$ = new BehaviorSubject<string>('');

    // Google Maps autocompletion for organizations
    public organizationAutocompletionPredictions: GoogleMapsPredictionItem[] = [];
    public organizationAutocompletionSearchTerm$ = new BehaviorSubject<string>('');
    public organizationPredictionSessionToken: string;

    // Autoixpert contact predictions
    public contactAutocompletionPredictions: ContactPerson[] = [];
    public contactAutocompletionSearchTerm$ = new BehaviorSubject<{
        searchAfterNumberOfElements: number;
        searchAfterPaginationToken: string;
        filterAndSortParams: FeathersQuery;
        searchTerm: string;
        numberOfItemsToLoad: number;
    }>(null);
    public contactAutocompletionPredictionsFromServer: ContactPerson[] = [];
    public possibleRelevantContacts: ContactPerson[] = [];
    private lastContactPaginationTokenFromServer = null; // for online pagination
    private numberOfLoadedContacts = 0; // for offline pagination
    protected recordsLimitReached = false;
    public searchMatchesMap: Map<string, { propertyGerman: 'Marke' | 'Merkmal'; value: string }> = new Map();

    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();

        if (this.externalAddressAutocompletionEnabled) {
            this.setupExternalAddressSearch();
        }
        if (this.externalOrganizationAutocompletionEnabled) {
            this.setupExternalOrganizationSearch();
        }
        if (this.contactAutocompletionEnabled) {
            this.setupContactSearch();
        }
    }

    /**
     * Display suggestions in empty inputs.
     * This is helpful where user usually need the same contacts (e.g. lawyers, garages, insurance).
     * Do not load the initial suggestions if the input already contains text -> then use the server search and avoid unnecessary loading of data.
     *
     * Further improvements:
     * - Load more contacts when scrolling to allow selecting a contact without need for type. We saw that some users select their lawyers like this.
     * Mark decided that this is no valid use case. Better way would be to mark contacts as favorites.
     *
     */
    ngAfterViewInit() {
        if (this.showSuggestionsIfEmpty) {
            this.subscriptions.push(
                this.autocomplete?.opened.subscribe(async () => {
                    if (!this.value) {
                        if (!this.loadedSuggestionsForEmptyState) {
                            /**
                             * Load relevant contacts and display them directly (e.g. for lawyers, garage or insurance)
                             */
                            this.possibleRelevantContacts = await this.contactPersonService
                                .find({
                                    organizationType: { $in: this.organizationTypeFilter },
                                    // Limit to only 15 contacts
                                    $limit: 15,
                                })
                                .toPromise();
                            this.updateContactPersonResults('');
                        }
                    }
                    // Disable the loading spinner
                    this.loadedSuggestionsForEmptyState = true;
                }),
            );
        }
    }

    /**
     * Fetch new address predictions when the value changes.
     * Do not fetch predictions if the context (e.g. city) changes - we assume, the autocomplete is not visible then.
     */
    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && this.autocomplete?._isOpen) {
            this.triggerAutocompletionSearch();
        }
    }

    /**
     * This function triggers the autocomplete search for the external address and organization search.
     * The updates are only processed, if the search is enabled in ngOnInit.
     */
    private triggerAutocompletionSearch() {
        this.updateExternalAddressSearchTerm();
        this.updateContactPersonSearch();
        /**
         *  Trigger the external organization search (paid Google Maps service) only:
         *  - if the external address search is enabled and
         *  - if a Google Maps Session Token is available -> the search was already started
         *      - the contact search returned no results and Google Maps was triggered as a fallback
         *      - the user requested the external organization search
         */
        if (this.organizationPredictionSessionToken) {
            this.updateExternalOrganizationSearchTerm();
        }
    }

    public startExternalOrganizationSearch() {
        this.updateExternalOrganizationSearchTerm();
    }

    //*****************************************************************************
    //  Address Autocompletion
    //****************************************************************************/
    public insertAddressAutocompletion(address: AddressAutocompletion) {
        // Angular provides no way to disable the automatic assignment of the selected value to the input.
        // Therefore we have to delay the event emission to the next tick, to override the value.
        // This seems a bit hacky, but it works and allows the parent component to assign the selected location.
        // Useful, e.g. to format the accident location in a different way than the contact person address.
        setTimeout(() => {
            this.addressSelected.emit({
                streetAndHouseNumberOrLockbox: `${address.street} ${address.houseNumber}`.trim(),
                zip: address.zip,
                city: address.city,
            });
        });
    }

    /**
     * Trigger the external maps search with the city and street.
     */
    private updateExternalAddressSearchTerm() {
        // Only search, if the input is not disabled
        if (this.disabled) {
            return;
        }

        // No search if offline
        if (!this.networkStatusService.isOnline()) {
            return;
        }

        const searchTerms: string[] = [this.value, this.addressContext?.city];
        this.addressAutocompletionSearchTerm$.next(searchTerms.filter(Boolean).join(' ') ?? '');
    }

    private setupExternalAddressSearch() {
        this.subscriptions.push(
            this.addressAutocompletionSearchTerm$
                .pipe(
                    // Only emit if the search term has changed
                    distinctUntilChanged(),

                    // Debounce the search term to prevent too many requests
                    debounceTime(100),

                    // Cancel previous requests and only keep the latest one
                    switchMap((searchTerm) => {
                        // Serverside search
                        return this.getExternalAddressAutocompletion(searchTerm);
                    }),
                )
                .subscribe((predictions: AddressAutocompletion[]) => {
                    this.addressAutocompletionPredictions = predictions;
                }),
        );
    }

    private async getExternalAddressAutocompletion(searchTerm: string): Promise<AddressAutocompletion[]> {
        // No search for short tokens
        if (!searchTerm || searchTerm.length < 3) {
            return [];
        }

        try {
            return await this.addressAutocompletionService.getAddressPredictions(searchTerm);
        } catch (error) {
            // Fail silently
            this.apiErrorService.logErrorSilently(
                new AxError({
                    code: 'GETTING_ADDRESS_AUTOCOMPLETION_FAILED',
                    message: `Have a look at the causedBy property.`,
                    data: {
                        searchTerm,
                    },
                    error,
                }),
            );
            return [];
        }
    }

    public formatStreetAndHouseNumber(address: AddressAutocompletion): string {
        return `${address.street} ${address.houseNumber}`.trim();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Address Autocompletion
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  External Organization Autocompletion
    //****************************************************************************/
    public async insertOrganizationAutocompletion(mapsPrediction: GoogleMapsPredictionItem) {
        // Load place details from google maps
        const { place: addressFromMaps, sessionToken } = await this.addressAutocompletionService.getOrganizationDetails(
            {
                placeId: mapsPrediction.placeId,
                sessionToken: this.organizationPredictionSessionToken,
            },
        );

        // Reset the session token and the predictions
        this.organizationPredictionSessionToken = sessionToken;
        this.organizationAutocompletionPredictions = [];
        if (!addressFromMaps) return;

        // In this case, the timeout is not necessary, because we already awaited the place details.
        this.addressSelected.emit({
            organization: addressFromMaps.organization,
            firstName: '',
            lastName: '',
            streetAndHouseNumberOrLockbox: `${addressFromMaps.route} ${addressFromMaps.streetNumber}`.trim(),
            zip: addressFromMaps.postalCode,
            city: addressFromMaps.locality,
        });
    }

    /**
     * Trigger the external maps search with the city and street.
     */
    private updateExternalOrganizationSearchTerm() {
        // Only search, if the input is not disabled
        if (this.disabled) {
            return;
        }

        // No search if offline
        if (!this.networkStatusService.isOnline()) {
            return;
        }

        const searchTerms: string[] = [this.value, this.addressContext?.city];
        this.organizationAutocompletionSearchTerm$.next(searchTerms.filter(Boolean).join(' ') ?? '');
    }

    private setupExternalOrganizationSearch() {
        this.subscriptions.push(
            this.organizationAutocompletionSearchTerm$
                .pipe(
                    // Only emit if the search term has changed
                    distinctUntilChanged(),

                    // Debounce the search term to prevent too many requests
                    debounceTime(300),

                    // Cancel previous requests and only keep the latest one
                    switchMap((searchTerm) => {
                        // Serverside search
                        return this.getExternalOrganizationAutocompletion(searchTerm);
                    }),
                )
                .subscribe((predictions: GoogleMapsPredictionItem[]) => {
                    this.organizationAutocompletionPredictions = predictions;
                }),
        );
    }

    private async getExternalOrganizationAutocompletion(searchTerm: string): Promise<GoogleMapsPredictionItem[]> {
        // No search for short tokens
        if (!searchTerm || searchTerm.length < 3) {
            return [];
        }

        try {
            const { sessionToken, predictions } = await this.addressAutocompletionService.getOrganizationPredictions({
                userInput: searchTerm,
                sessionToken: this.organizationPredictionSessionToken,
            });
            this.organizationPredictionSessionToken = sessionToken;
            return predictions;
        } catch (error) {
            // Fail silently
            this.apiErrorService.logErrorSilently(
                new AxError({
                    code: 'GETTING_ADDRESS_AUTOCOMPLETION_FAILED',
                    message: `Have a look at the causedBy property.`,
                    data: {
                        searchTerm,
                    },
                    error,
                }),
            );
            return [];
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END External Organization Autocompletion
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Autoixpert Contacts
    //****************************************************************************/
    public insertContactAutocompletion(selectedContactPerson: ContactPerson) {
        // Angular provides no way to disable the automatic assignment of the selected value to the input.
        // Therefore we have to delay the event emission to the next tick, to override the value.
        // This seems a bit hacky, but it works and allows the parent component to assign the selected location.
        // Useful, e.g. to format the accident location in a different way than the contact person address.
        setTimeout(() => {
            this.contactPersonSelected.emit(selectedContactPerson);
        });
    }

    private buildSearchTerm(): string {
        // TODO: We may improve the search behavior by transmitting the search terms in a more structured way.
        // We could search for the first name, last name, organization, city, etc. in the correct fields.
        // For now, we keep it how it was
        const searchTerms: string[] = [
            this.value,
            this.addressContext?.city,
            this.addressContext?.organization,
            this.addressContext?.lastName,
        ];
        return searchTerms.filter(Boolean).join(' ') ?? '';
    }

    protected loadMoreContacts() {
        if (this.recordsLimitReached) {
            this.toastService.info(
                'Bereits alle passende Kontakte geladen.',
                'Verändere deinen Suchbegriff oder entferne die Werte aus anderen Feldern (z. B. die Adresse) um weitere Ergebnisse zu sehen.',
            );
        }
        this.contactAutocompletionSearchTerm$.next({
            searchAfterNumberOfElements: this.numberOfLoadedContacts,
            searchAfterPaginationToken: this.lastContactPaginationTokenFromServer,
            filterAndSortParams: this.getFilterAndSortQuery(),
            searchTerm: this.buildSearchTerm(),
            numberOfItemsToLoad: 8,
        });
    }

    private updateContactPersonSearch() {
        // Only search, if the input is not disabled
        if (this.disabled) {
            return;
        }

        this.lastContactPaginationTokenFromServer = null;
        this.numberOfLoadedContacts = 0;

        this.contactAutocompletionSearchTerm$.next({
            searchAfterNumberOfElements: this.numberOfLoadedContacts,
            searchAfterPaginationToken: this.lastContactPaginationTokenFromServer,
            filterAndSortParams: this.getFilterAndSortQuery(),
            searchTerm: this.buildSearchTerm(),
            numberOfItemsToLoad: 8,
        });
    }

    private getFilterAndSortQuery(): FeathersQuery {
        return { $sort: { organization: 1 }, organizationType: { $in: this.organizationTypeFilter } };
    }

    /**
     * Execute server search only for search terms that are long enough to reduce server load.
     */
    private isQualifiedSearchTerm(searchTerm: string): boolean {
        const searchTermParts =
            searchTerm
                ?.trim()
                ?.split(' ')
                ?.filter((searchTerm) => !!searchTerm.trim()) ?? [];
        return searchTermParts.some((searchTermPart) => searchTermPart.length >= 3);
    }

    /**
     * Every time the search term changes, trigger a server search.
     *
     * Subscribe to the stream of search terms and trigger a search on the server.
     * Searches are only performed one second after the user stopped typing, and if the
     * search term is three letters or longer.
     */
    public setupContactSearch(): void {
        this.subscriptions.push(
            this.contactAutocompletionSearchTerm$
                .pipe(
                    // Some initial events do not have a payload.
                    filter((payload) => !!payload),
                    // Only search for more than three characters.
                    filter(({ searchTerm }) => {
                        const qualifiedForServerSearch = this.isQualifiedSearchTerm(searchTerm);
                        if (!qualifiedForServerSearch && this.showSuggestionsIfEmpty) {
                            this.updateContactPersonResults(searchTerm);
                        }
                        return qualifiedForServerSearch;
                    }),

                    debounceTime(250),

                    switchMap(async (payload) => {
                        const numberOfReportsToLoad = payload.numberOfItemsToLoad || 15;
                        const isInitialLoad = !this.numberOfLoadedContacts;
                        let loadedContacts: ContactPerson[] = [];

                        /**
                         * Load reports from server (if online) or from IndexedDB (if offline).
                         */
                        try {
                            const { records, lastPaginationToken } =
                                await this.contactPersonService.getContactsFromServerOrIndexedDB({
                                    searchTerm: payload.searchTerm,
                                    searchAfterPaginationToken: payload.searchAfterPaginationToken,
                                    skip: payload.searchAfterNumberOfElements || 0,
                                    limit: numberOfReportsToLoad,
                                    query: payload.filterAndSortParams,
                                });
                            loadedContacts = records;

                            // Update pagination of component
                            this.lastContactPaginationTokenFromServer = lastPaginationToken;
                            this.numberOfLoadedContacts += loadedContacts.length;
                        } catch (error) {
                            this.apiErrorService.handleAndRethrow({
                                axError: error,
                                handlers: {},
                                defaultHandler: {
                                    title: 'Kontakte nicht geladen',
                                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                                },
                            });
                        }
                        return { loadedContacts, isInitialLoad, numberOfReportsToLoad, searchTerm: payload.searchTerm };
                    }),
                )
                .subscribe(({ loadedContacts, isInitialLoad, numberOfReportsToLoad, searchTerm }) => {
                    // If there are less reports than the limit, we have reached the end of the list.
                    this.recordsLimitReached = loadedContacts.length < numberOfReportsToLoad;

                    if (isInitialLoad && loadedContacts.length === 0) {
                        // Google Maps Search only, if no autoiXpert contacts where found
                        this.updateExternalOrganizationSearchTerm();
                    }

                    if (loadedContacts.length === 0 && !searchTerm) {
                        return;
                    }

                    // If this is the first load, update the in-memory cache with the loaded reports.
                    if (isInitialLoad) {
                        this.contactAutocompletionPredictionsFromServer = loadedContacts;
                    } else {
                        this.contactAutocompletionPredictionsFromServer.push(...loadedContacts);
                    }
                    this.updateContactPersonResults(searchTerm);
                }),
        );
    }

    public updateContactPersonResults(searchTerm: string) {
        const recordsForLocalSearch: ContactPerson[] = [];
        // Source A: (optional) local contacts for display on empty search field / short search term
        if (this.showSuggestionsIfEmpty && this.possibleRelevantContacts.length > 0) {
            recordsForLocalSearch.push(...this.possibleRelevantContacts);
        }

        // Source B: (optional) additional, search results (e.g. from open reports)
        // Only relevant, if initial suggestions are shown or a server search was executed
        if (this.showSuggestionsIfEmpty || this.isQualifiedSearchTerm(searchTerm)) {
            recordsForLocalSearch.push(...this.additionalContactPeopleForAutocomplete);
        }

        const localResults = this.executeSearchOnLocalRecords(searchTerm, recordsForLocalSearch);

        // Source C: server search results (when qualified search term)
        const searchResults: ContactPerson[] = this.contactAutocompletionPredictionsFromServer;
        /**
         * Merge local and server search results.
         *
         * Remove duplicates from the local search results (to display the trash bin icon instead of the "from open reports icon").
         * Include all server-side results since the server search may return more results, e.g. due to ascii folding
         */
        for (const localResult of localResults) {
            if (
                searchResults.some(
                    (possibleDuplicate) =>
                        possibleDuplicate._id === localResult._id ||
                        areContactPeopleEqual(possibleDuplicate, localResult),
                )
            ) {
                continue;
            }
            searchResults.push(localResult);
        }

        this.contactAutocompletionPredictions = searchResults;
    }

    private executeSearchOnLocalRecords(searchTerm: string, records: ContactPerson[]): ContactPerson[] {
        const searchResult: ContactPerson[] = applyMongoQuery(
            { $search: searchTerm },
            records,
            this.contactPersonService.localDb.get$SearchMongoQuery,
        );

        // eslint-disable-next-line prefer-const
        let sortBy = 'organization';
        // Sort search result by filter criterion
        searchResult.sort((resultA, resultB) => {
            let comparisonStringA: string;
            let comparisonStringB: string;
            switch (sortBy) {
                case 'organization':
                    comparisonStringA =
                        (resultA.organization || '') + (resultA.firstName || '') + (resultA.lastName || '');
                    comparisonStringB =
                        (resultB.organization || '') + (resultB.firstName || '') + (resultB.lastName || '');
                    break;
                case 'firstName':
                    comparisonStringA =
                        (resultA.firstName || '') + (resultA.lastName || '') + (resultA.organization || '');
                    comparisonStringB =
                        (resultB.firstName || '') + (resultB.lastName || '') + (resultB.organization || '');
                    break;
                case 'lastName':
                    comparisonStringA =
                        (resultA.lastName || '') + (resultA.firstName || '') + (resultA.organization || '');
                    comparisonStringB =
                        (resultB.lastName || '') + (resultB.firstName || '') + (resultB.organization || '');
                    break;
                case 'streetAndHouseNumberOrLockbox':
                    comparisonStringA =
                        (resultA.streetAndHouseNumberOrLockbox || '') + resultA.city + (resultA.organization || '');
                    comparisonStringB =
                        (resultB.streetAndHouseNumberOrLockbox || '') + resultB.city + (resultB.organization || '');
                    break;
                case 'city':
                    comparisonStringA =
                        resultA.city + (resultA.streetAndHouseNumberOrLockbox || '') + (resultA.organization || '');
                    comparisonStringB =
                        resultB.city + (resultB.streetAndHouseNumberOrLockbox || '') + (resultB.organization || '');
                    break;
            }
            return comparisonStringA.localeCompare(comparisonStringB);
        });

        //*****************************************************************************
        //  Find Search Matches in Brand & Characteristics
        //****************************************************************************/
        /**
         * Record if a search matched any of the invisible properties (not shown in autocomplete).
         */
        const searchTerms = searchTerm
            .toLowerCase()
            .split(' ')
            // Remove empty string as search term.
            .filter(Boolean);

        this.searchMatchesMap = new Map();

        const garages = searchResult.filter((contact) => contact.organizationType === 'garage');
        searchTerms.every((searchTerm) => {
            for (const garage of garages) {
                // Brands are not visible in the UI -> Add an explicit search match.
                for (const brand of garage.garageBrands || []) {
                    if (brand.toLowerCase().includes(searchTerm)) {
                        this.searchMatchesMap.set(garage._id, { propertyGerman: 'Marke', value: brand });
                        break;
                    }
                }

                // Characteristics are not visible in the UI -> Add an explicit search match.
                for (const characteristic of garage.garageCharacteristics || []) {
                    if (characteristic.toLowerCase().includes(searchTerm)) {
                        this.searchMatchesMap.set(garage._id, { propertyGerman: 'Merkmal', value: characteristic });
                        break;
                    }
                }
            }
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Find Search Matches in Brand & Characteristics
        /////////////////////////////////////////////////////////////////////////////*/

        return searchResult;
    }

    /**
     * Remove an entry and re-add it if the server connection failed.
     */
    public async removeAutocompleteEntry(contactPerson: ContactPerson): Promise<void> {
        // Remove from available records and the passed collection immediately. Re-add later if an error occurs.
        const indexInAvailableCollection = this.contactAutocompletionPredictionsFromServer.indexOf(contactPerson);
        if (indexInAvailableCollection > -1) {
            this.contactAutocompletionPredictionsFromServer.splice(indexInAvailableCollection, 1);
        }

        const indexInFilteredCollection = this.contactAutocompletionPredictions.indexOf(contactPerson);
        if (indexInFilteredCollection > -1) {
            this.contactAutocompletionPredictions.splice(indexInFilteredCollection, 1);
        }

        const indexInPossibleContacts = this.possibleRelevantContacts.indexOf(contactPerson);
        if (indexInPossibleContacts > -1) {
            this.possibleRelevantContacts.splice(indexInPossibleContacts, 1);
        }

        try {
            await this.contactPersonService.delete(contactPerson._id);
        } catch (error) {
            this.toastService.error('Adresse konnte nicht gelöscht werden');
            console.error('CONTACT_PERSON_AUTOCOMPLETE_OPTION_COULD_NOT_BE_DELETED', { error });

            // Re-add the contact person to the list
            this.contactAutocompletionPredictionsFromServer.splice(indexInAvailableCollection, 0, contactPerson);
            this.contactAutocompletionPredictions.splice(indexInFilteredCollection, 0, contactPerson);
            this.possibleRelevantContacts.splice(indexInPossibleContacts, 0, contactPerson);

            // Re-add to the local cache
            await this.contactPersonService.undelete(contactPerson);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Autoixpert Contacts
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Insurance Logo
    //****************************************************************************/
    public insuranceLogoExists(name: string): boolean {
        return insuranceLogoExists(name);
    }

    public getIconNameForInsurance(name: string): string {
        return getFileNameForInsuranceLogo(name);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Insurance Logo
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Garage Fee Set
    //****************************************************************************/
    protected getGarageFeeSetDateLabel(garage: ContactPerson): string {
        const hasFeeSets: boolean = garage.garageFeeSets?.length > 0;

        if (!hasFeeSets) return 'Keine Kostensätze';

        const latestFeeSetDate = this.getLatestFeeSetDate(garage.garageFeeSets);

        if (!latestFeeSetDate) return 'kein Datum';

        return DateTime.fromISO(latestFeeSetDate).toFormat('dd.MM.yy');
    }

    protected getLatestFeeSetDate(garageFeeSets: GarageFeeSet[]): IsoDate {
        if (!garageFeeSets) return undefined;

        const dates = garageFeeSets.map((feeSet) => feeSet.validFrom).filter(Boolean);

        // The youngest date will be last.
        dates.sort();

        return dates[dates.length - 1];
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage Fee Set
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Garage Brand Icons
    //****************************************************************************/
    public iconNameForCarBrand = iconNameForCarBrand;
    public iconForCarBrandExists = iconForCarBrandExists;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    public stopEvent(event): void {
        event.stopImmediatePropagation();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub?.unsubscribe());
    }
}

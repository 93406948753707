import moment from 'moment';
import { generateId } from '@autoixpert/lib/generate-id';
import { createInvoice } from '@autoixpert/lib/invoices/create-invoice';
import { round } from '@autoixpert/lib/numbers/round';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { DefaultDaysUntilDueConfig } from '../../models/user/preferences/default-days-until-due-config';
import { getVehicleValueGross } from '../car-valuation/get-vehicle-value';
import { toIsoDate, todayIso } from '../date/iso-date';
import { IsoDate } from '../date/iso-date.types';
import { computeInvoiceTotalNet } from './compute-invoice-total';
import { getInvoiceReportData } from './get-invoice-report-data-from-report';
import { getInvolvedPartiesForInvoiceFromReport } from './get-involved-parties-for-invoice-from-report';

/**
 * These are the names (description of invoice line item) we give each default fee when the invoice is generated
 * from a report.
 */
export const LINE_ITEM_DESC_PHOTOS = '<p>Lichtbilder</p>';
export const LINE_ITEM_DESC_PHOTOS_FIXED = '<p>Lichtbilder (pauschal)</p>';
export const LINE_ITEM_DESC_PHOTO_COPIES = '<p>Lichtbildkopien</p>';
export const LINE_ITEM_DESC_PHOTO_COPIES_FIXED = '<p>Lichtbildkopien (pauschal)</p>';
export const LINE_ITEM_DESC_TRAVEL_EXPENSES = '<p>Fahrtkosten</p>';
export const LINE_ITEM_DESC_TRAVEL_EXPENSES_FIXED = '<p>Fahrtkosten (pauschal)</p>';
export const LINE_ITEM_DESC_WRITING_FEES_FIXED = '<p>Schreibkosten (pauschal)</p>';
export const LINE_ITEM_DESC_WRITING_FEES = '<p>Schreibkosten</p>';
export const LINE_ITEM_DESC_WRITING_COPY_FEES = '<p>Schreibkosten Kopie</p>';
export const LINE_ITEM_DESC_WRITING_COPY_FEES_FIXED = '<p>Schreibkosten Kopie (pauschal)</p>';
export const LINE_ITEM_DESC_POSTAGE_AND_PHONE_FEES = '<p>Porto &amp; Telefon (pauschal)</p>';

/**
 * Generate an Invoice from a Report.
 */
export function createInvoiceFromReport({ report, user, team }: { report: Report; user: User; team: Team }) {
    //*****************************************************************************
    //  Create Invoice Positions
    //****************************************************************************/
    const invoiceLineItems: LineItem[] = [];

    if (report.feeCalculation.assessorsFee) {
        /**
         * Get description in this order:
         * 1) Manual description on feeCalculation object
         * 2) Users preferences
         * 3) Automatic, dynamic algorithm
         */
        let description = report.feeCalculation.assessorsFeeDescription;

        if (!description) {
            switch (report.type) {
                case 'liability':
                    description =
                        team.preferences.assessorsFeeDescriptionLiability ||
                        '<p>KFZ-Schadensgutachten</p>' +
                            '<p>- Datenerfassung</p>' +
                            '<p>- Fahrzeugbeschädigungen aufnehmen</p>' +
                            `${
                                report.car.unrepairedPreviousDamage
                                    ? '<p>- Altschäden fotografieren und dokumentieren</p>'
                                    : ''
                            }` +
                            '<p>- Festlegung wirtschaftlichster Reparaturweg</p>' +
                            '<p>- Erstellung einer Schadenskalkulation</p>' +
                            `${
                                getVehicleValueGross(report.valuation, 'replacementValue')
                                    ? '<p>- Ermittlung des Wiederbeschaffungswerts</p>'
                                    : ''
                            }`;
                    break;
                case 'shortAssessment':
                    description =
                        team.preferences.assessorsFeeDescriptionShortAssessment || report.useCostEstimateHeading
                            ? '<p>Kostenvoranschlag</p>'
                            : '<p>Kurzgutachten</p>';
                    break;
                case 'partialKasko':
                    description =
                        team.preferences.assessorsFeeDescriptionPartialKasko || '<p>Kaskoschadengutachten</p>';
                    break;
                case 'fullKasko':
                    description = team.preferences.assessorsFeeDescriptionFullKasko || '<p>Kaskoschadengutachten</p>';
                    break;
                case 'valuation':
                    description = team.preferences.assessorsFeeDescriptionValuation || '<p>Fahrzeugbewertung</p>';
                    break;
                case 'oldtimerValuationSmall':
                    description =
                        team.preferences.assessorsFeeDescriptionOldtimerValuationSmall || '<p>Fahrzeugbewertung</p>';
                    break;
                case 'leaseReturn':
                    description = team.preferences.assessorsFeeDescriptionLeaseReturn || '<p>Fahrzeugbewertung</p>';
                    break;
                case 'usedVehicleCheck':
                    description =
                        team.preferences.assessorsFeeDescriptionUsedVehicleCheck || '<p>Gebrauchtwagen-Check</p>';
                    break;
                case 'invoiceAudit':
                    description = team.preferences.assessorsFeeDescriptionInvoiceAudit || '<p>Rechnungsprüfung</p>';
                    break;
            }
        }

        invoiceLineItems.push({
            _id: generateId(),
            description,
            quantity: 1,
            unitPrice: report.feeCalculation.assessorsFee,
            unit: '',
            active: true,
            position: null,
        });
    }

    //*****************************************************************************
    //  Photos
    //****************************************************************************/
    const numberOfActivePhotos: number = report.photos.filter((photo) => photo.versions.report.included).length;
    const numberOfPhotos: number = report.feeCalculation.useManualNumberOfPhotos
        ? report.feeCalculation.manualNumberOfPhotos
        : numberOfActivePhotos;

    // Fixed price for photos
    if (report.feeCalculation.usePhotosFixedPrice && report.feeCalculation.photosFixedPrice) {
        invoiceLineItems.push({
            _id: generateId(),
            description: LINE_ITEM_DESC_PHOTOS_FIXED,
            quantity: 1,
            unitPrice: report.feeCalculation.photosFixedPrice,
            unit: '',
            active: true,
            position: null,
        });
    }
    // Number of photos * price per photo
    // "!report.feeCalculation.usePhotosFixedPrice" is important here to cover the case that a user entered nothing in the fixed price field
    // but set report.feeCalculation.usePhotosFixedPrice to true.
    else if (!report.feeCalculation.usePhotosFixedPrice && numberOfPhotos && report.feeCalculation.pricePerPhoto) {
        invoiceLineItems.push({
            _id: generateId(),
            description: LINE_ITEM_DESC_PHOTOS,
            // quantity    : report.photos.length,
            quantity: numberOfPhotos,
            unitPrice: report.feeCalculation.pricePerPhoto,
            unit: 'Stück',
            active: true,
            position: null,
        });
    }

    // Only add this position if a second set of photos shall be billed.
    if (report.feeCalculation.secondPhotoSet) {
        // Fixed price for copied set of photos
        if (report.feeCalculation.usePhotosFixedPrice && report.feeCalculation.secondPhotoSetFixedPrice) {
            invoiceLineItems.push({
                _id: generateId(),
                description: LINE_ITEM_DESC_PHOTO_COPIES_FIXED,
                quantity: 1,
                unitPrice: report.feeCalculation.secondPhotoSetFixedPrice,
                unit: '',
                active: true,
                position: null,
            });
        }
        // Number of photos * price per photo
        // "!report.feeCalculation.usePhotosFixedPrice" is important here to cover the case that a user entered nothing in the fixed price field
        // but set report.feeCalculation.usePhotosFixedPrice to true.
        else if (
            !report.feeCalculation.usePhotosFixedPrice &&
            report.photos.length &&
            report.feeCalculation.pricePerSecondPhoto
        ) {
            invoiceLineItems.push({
                _id: generateId(),
                description: LINE_ITEM_DESC_PHOTO_COPIES,
                quantity: numberOfPhotos,
                unitPrice: report.feeCalculation.pricePerSecondPhoto,
                unit: 'Stück',
                active: true,
                position: null,
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Travel Expenses
    //****************************************************************************/
    if (report.feeCalculation.useTravelExpensesFixedPrice && report.feeCalculation.travelExpensesFixedPrice) {
        invoiceLineItems.push({
            _id: generateId(),
            description: LINE_ITEM_DESC_TRAVEL_EXPENSES_FIXED,
            quantity: 1,
            unitPrice: report.feeCalculation.travelExpensesFixedPrice,
            unit: '',
            active: true,
            position: null,
        });
    } else if (report.feeCalculation.useTravelExpensesFixedPrice && !report.feeCalculation.travelExpensesFixedPrice) {
        invoiceLineItems.push({
            _id: generateId(),
            description: LINE_ITEM_DESC_TRAVEL_EXPENSES_FIXED,
            quantity: 0,
            unitPrice: report.feeCalculation.travelExpensesFixedPrice || 0,
            unit: '',
            active: true,
            position: null,
        });
    } else if (report.feeCalculation.pricePerKilometer && report.feeCalculation.numberOfKilometers) {
        invoiceLineItems.push({
            _id: generateId(),
            description: LINE_ITEM_DESC_TRAVEL_EXPENSES,
            quantity: report.feeCalculation.numberOfKilometers,
            unitPrice: report.feeCalculation.pricePerKilometer,
            unit: 'km',
            active: true,
            position: null,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Travel Expenses
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Writing Fees
    //****************************************************************************/
    if (report.feeCalculation.useWritingFeesFixedPrice) {
        if (report.feeCalculation.writingFeesFixedPrice) {
            invoiceLineItems.push({
                _id: generateId(),
                description: LINE_ITEM_DESC_WRITING_FEES_FIXED,
                quantity: 1,
                unitPrice: report.feeCalculation.writingFeesFixedPrice,
                unit: '',
                active: true,
                position: null,
            });
        }

        // Copy fees (fixed price)
        if (report.feeCalculation.useWritingCopyFees && report.feeCalculation.writingCopyFeesFixedPrice) {
            invoiceLineItems.push({
                _id: generateId(),
                description: LINE_ITEM_DESC_WRITING_COPY_FEES_FIXED,
                quantity: 1,
                unitPrice: report.feeCalculation.writingCopyFeesFixedPrice,
                unit: '',
                active: true,
                position: null,
            });
        }
    } else if (report.feeCalculation.numberOfPages) {
        if (report.feeCalculation.pricePerPage) {
            invoiceLineItems.push({
                _id: generateId(),
                description: LINE_ITEM_DESC_WRITING_FEES,
                quantity: report.feeCalculation.numberOfPages,
                unitPrice: report.feeCalculation.pricePerPage,
                unit: report.feeCalculation.numberOfPages === 1 ? 'Seite' : 'Seiten',
                active: true,
                position: null,
            });
        }

        if (report.feeCalculation.useWritingCopyFees && report.feeCalculation.pricePerPageCopy) {
            invoiceLineItems.push({
                _id: generateId(),
                description: LINE_ITEM_DESC_WRITING_COPY_FEES,
                quantity: report.feeCalculation.numberOfPages,
                unitPrice: report.feeCalculation.pricePerPageCopy,
                unit: report.feeCalculation.numberOfPages === 1 ? 'Seite' : 'Seiten',
                active: true,
                position: null,
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Writing Fees
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Postage & Phone Fees
    //****************************************************************************/
    if (report.feeCalculation.postageAndPhoneFees) {
        invoiceLineItems.push({
            _id: generateId(),
            description: LINE_ITEM_DESC_POSTAGE_AND_PHONE_FEES,
            quantity: 1,
            unitPrice: report.feeCalculation.postageAndPhoneFees,
            unit: '',
            active: true,
            position: null,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Postage & Phone Fees
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Fees
    //****************************************************************************/
    // Add all custom fees set to active to the invoice line items array. They will be displayed in the DOCX file.
    report.feeCalculation.invoiceParameters.lineItems
        .filter((customFee) => customFee.active)
        .forEach((customFee) => {
            invoiceLineItems.push({
                _id: generateId(),
                description: customFee.description,
                quantity: customFee.quantity,
                unitPrice: customFee.unitPrice,
                unit: customFee.unit,
                active: customFee.active,
                position: null,
                lineItemTemplateId: customFee.lineItemTemplateId,
            });
        });
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Fees
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create Invoice Positions
    /////////////////////////////////////////////////////////////////////////////*/

    let defaultDaysUntilDue: number;
    const reportSpecificDaysUntilDueConfig: DefaultDaysUntilDueConfig =
        team.preferences.defaultDaysUntilDueConfigs.find((entry) => entry.reportType === report?.type);
    const defaultDaysUntilDueConfig: DefaultDaysUntilDueConfig = team.preferences.defaultDaysUntilDueConfigs.find(
        (entry) => entry.reportType === 'default',
    );
    if (reportSpecificDaysUntilDueConfig) {
        defaultDaysUntilDue = reportSpecificDaysUntilDueConfig.numberOfDays;
    } else if (defaultDaysUntilDueConfig) {
        defaultDaysUntilDue = defaultDaysUntilDueConfig.numberOfDays;
    }

    const dateOfSupply: IsoDate = report.completionDate
        ? report.completionDate
        : report.feeCalculation.invoiceParameters.date
          ? toIsoDate(report.feeCalculation.invoiceParameters.date)
          : todayIso();
    const daysUntilDueShorthand = report.feeCalculation.invoiceParameters.daysUntilDue;
    const daysUntilDue: number =
        daysUntilDueShorthand || daysUntilDueShorthand === 0 ? daysUntilDueShorthand : defaultDaysUntilDue;
    const totalNet = computeInvoiceTotalNet({ lineItems: report.feeCalculation.invoiceParameters.lineItems });
    const vatRate = report.feeCalculation.invoiceParameters.vatRate ?? team.invoicing.vatRate;
    const totalGross = round(totalNet * (1 + vatRate));

    // Create a temporary invoice object that is not saved across calls since this
    // is only a preview. The "real" invoice will be rendered when the report is
    // locked.
    const invoiceData: Invoice = new Invoice({
        _id: report.feeCalculation.invoiceParameters._id || undefined, // Old frontend versions may send null IDs. Use undefined instead so that the DB generates an ID for it.
        number: report.feeCalculation.invoiceParameters.number,
        type: 'invoice',
        printReportSummary: true,
        intro: `<p>Wir danken für Ihren Auftrag und erlauben uns, die folgenden Positionen in Rechnung zu stellen.</p>`,
        subject: `Gutachten ${report.token || ''}`,
        date: report.feeCalculation.invoiceParameters.date
            ? toIsoDate(report.feeCalculation.invoiceParameters.date)
            : todayIso(),
        dateOfSupply,
        lineItems: invoiceLineItems,
        totalNet,
        totalGross,
        vatRate,
        vatExemptionReason: report.feeCalculation.invoiceParameters.vatExemptionReason,
        factoringEnabled: report.feeCalculation.invoiceParameters.factoringEnabled,
        isElectronicInvoiceEnabled: report.feeCalculation.invoiceParameters.isElectronicInvoiceEnabled,
        officeLocationId: report.officeLocationId,
        associatedAssessorId: report.responsibleAssessor,
        daysUntilDue,
        bankAccount: report.feeCalculation.invoiceParameters.factoringEnabled
            ? user.preferences.factoringProvider.bankAccount
            : team.invoicing.bankAccount,
        // If factoring is enabled, never use the second bank account since factoring providers always have only one bank account.
        secondBankAccount:
            !report.feeCalculation.invoiceParameters.factoringEnabled && team.invoicing.secondBankAccount?.iban
                ? team.invoicing.secondBankAccount
                : undefined,
        reportIds: [report._id],
        // If the team preferences say so, lock the invoice immediately. If this is for a preview, the object won't be saved, so that's no problem.
        lockedAt: team.preferences.bookInvoiceWhenLockingReport ? moment().format() : null,
        lockedBy: team.preferences.bookInvoiceWhenLockingReport ? user._id : null,
        createdBy: user._id,
        teamId: user.teamId,
    });

    invoiceData.reportsData = [getInvoiceReportData({ report })];

    /**
     * Involved Parties
     */
    Object.assign(
        invoiceData,
        getInvolvedPartiesForInvoiceFromReport(report, report.feeCalculation.invoiceParameters.recipientRole),
    );

    return createInvoice({ invoiceData, user, team });
}

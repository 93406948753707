<!--============================================-->
<!-- Table of Contents -->
<!--============================================-->
<div class="table-of-contents-column">
    <div class="table-of-contents-container card dark-card">
        <div class="table-of-contents-heading-container cursor-pointer">
            <h2 class="table-of-contents-heading" [mat-menu-trigger-for]="documentTypeSelectionMenu">
                <span
                    class="table-of-contents-heading-text text-overflow-ellipsis"
                    [matTooltip]="selectedDocumentOrderConfig | translateDocumentOrderConfigName: true">
                    {{ selectedDocumentOrderConfig | translateDocumentOrderConfigName: true }}
                </span>
                <mat-icon>arrow_drop_down</mat-icon>
            </h2>
            <mat-icon
                class="table-of-contents-submenu-icon toolbar-icon"
                [matMenuTriggerFor]="tableOfContentsHeadingSubmenu">
                more_vert
            </mat-icon>
            <mat-menu #tableOfContentsHeadingSubmenu>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="resetDocumentBuildingBlockOrder()"
                    [matTooltip]="
                        'Die Referenzen zu den standardmäßig in diesem Dokument enthaltenen Bausteinen wiederherstellen, falls sie entfernt worden sind.\n\nDiese Aktion lässt die Bausteine inhaltlich so, wie du sie angepasst hast, fügt sie aber wieder der Liste hinzu.'
                    ">
                    <mat-icon>low_priority</mat-icon>
                    Standard-Reihenfolge wiederherstellen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="resetAllDocumentBuildingBlocks()"
                    [matTooltip]="
                        'Alle Textbausteine des aktuellen Dokuments werden inhaltlich auf den Standard zurückgesetzt.\n\nDas brauchst du in der Regel nur, wenn du Textbausteine zum Ausprobieren verändert hast und sie jetzt nochmal neu konfigurieren möchtest.'
                    ">
                    <mat-icon>restore</mat-icon>
                    Bausteine auf Standard zurücksetzen
                </a>
            </mat-menu>
        </div>
        <mat-menu #documentTypeSelectionMenu>
            <!-- Alphabetical order -->
            <!-- Everything but invoice documents and customer signature documents -->
            <mat-optgroup
                *ngFor="let documentTypeGroup of documentTypeGroups"
                [label]="translateDocumentTypeGroup(documentTypeGroup.name)">
                <!-- Document -->
                <mat-option
                    class="compact-option"
                    [class.mat-option-with-icon]="documentOrderConfig.type === 'customDocument'"
                    *ngFor="let documentOrderConfig of documentTypeGroup.documentOrderConfigs"
                    (click)="selectDocumentOrderConfig(documentOrderConfig)">
                    <span>{{ documentOrderConfig | translateDocumentOrderConfigName: true }}</span>
                    <mat-icon
                        *ngIf="
                            documentOrderConfig.type === 'customDocument' &&
                            user.accessRights.editTextsAndDocumentBuildingBlocks
                        "
                        (click)="$event.stopPropagation()"
                        class="small-icon toolbar-icon"
                        [matMenuTriggerFor]="customDocumentOrderConfigMenu">
                        more_vert
                    </mat-icon>
                    <mat-menu #customDocumentOrderConfigMenu="matMenu">
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="
                                openDocumentOrderConfigCreationDialog(
                                    documentOrderConfig.customDocumentConfig.documentTypeGroup,
                                    documentOrderConfig
                                )
                            ">
                            <mat-icon>edit</mat-icon>
                            Bearbeiten
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon alert"
                            (click)="deleteDocumentOrderConfig(documentOrderConfig)">
                            <mat-icon>delete</mat-icon>
                            Löschen
                        </a>
                    </mat-menu>
                </mat-option>

                <!-- Add document -->
                <mat-option
                    *ngIf="['reportAttachment', 'signature'].includes(documentTypeGroup.name)"
                    class="compact-option new-document-button"
                    (click)="openDocumentOrderConfigCreationDialog(documentTypeGroup.name)">
                    + Neue Dokumentvorlage
                </mat-option>
            </mat-optgroup>
        </mat-menu>
        <div class="table-of-contents-loader" *ngIf="!selectedDocumentOrderConfig">
            <img class="pending-icon" src="/assets/images/icons/loading-indicator.svg" alt="Lade-Icon" />
        </div>
        <div
            class="table-of-contents-list"
            cdkDropList
            (cdkDropListDropped)="reorderDocumentOrderStructure($event); filterAndSortBuildingBlocks()"
            *ngIf="selectedDocumentOrderConfig">
            <div
                class="table-of-contents-item-container"
                *ngFor="let documentBuildingBlockId of selectedDocumentOrderConfig.documentBuildingBlockIds"
                cdkDrag
                [cdkDragStartDelay]="10"
                [ngClass]="{
                    'page-break-before': showPageBreakBefore(documentBuildingBlockId),
                    'page-break-after': showPageBreakAfter(documentBuildingBlockId),
                }"
                [matTooltip]="getBuildingBlockTooltip(getBuildingBlock(documentBuildingBlockId))">
                <!--********** Section Heading **********-->
                <div
                    class="table-of-contents-list-item clickable"
                    [ngClass]="{ heading: getBuildingBlock(documentBuildingBlockId)?.isSectionHeading }"
                    (click)="scrollIntoView('#block-' + documentBuildingBlockId, documentBuildingBlockId)">
                    <!--********** Drag Handle **********-->
                    <!-- On touch devices, scrolling may accidentally drag elements. So, only let them drag by moving the handle. -->
                    <mat-icon class="drag-handle" *ngIf="!isTouchOnly()">drag_indicator</mat-icon>
                    <mat-icon class="drag-handle" *ngIf="isTouchOnly()" cdkDragHandle>drag_indicator</mat-icon>

                    <span class="table-of-contents-text-container">
                        {{ getBuildingBlock(documentBuildingBlockId)?.title }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <a
        class="video-tutorial-container card dark-card outlined"
        [ngClass]="{ elevated: !user?.userInterfaceStates.buildingBlockTutorialSeen }"
        (click)="user.userInterfaceStates.buildingBlockTutorialSeen = true; saveUser()"
        href="https://youtu.be/56Y6tv4qDfk"
        rel="noopener"
        target="_blank"
        matTooltip="Anleitung zu Bausteinen, Varianten & Bedingungen">
        <mat-icon class="video-tutorial-icon">play_circle</mat-icon>
        <div class="video-tutorial-title">
            Video-Anleitung &nbsp;
            <span class="label">(3:28 min)</span>
        </div>
    </a>
</div>
<!--============================================-->
<!-- END Table of Contents -->
<!--============================================-->
<div id="layout-container">
    <!--============================================-->
    <!-- Head Runner -->
    <!--============================================-->
    <div id="building-blocks-head-runner" class="card">
        <mat-checkbox
            [(ngModel)]="userPreferences.showOnlyDocumentBuildingBlocksOfSelectedDocument"
            (change)="filterAndSortBuildingBlocks()">
            Nur aktive Bausteine
        </mat-checkbox>

        <mat-form-field class="search-field" floatLabel="never">
            <input matInput [(ngModel)]="searchTerm" (input)="filterAndSortBuildingBlocks()" placeholder="Suchen" />
            <mat-icon matPrefix>search</mat-icon>
        </mat-form-field>
        <div id="head-runner-toolbar">
            <!-- Add new building block button -->
            <mat-icon
                class="toolbar-icon"
                (click)="createDocumentBuildingBlock(); filterAndSortBuildingBlocks()"
                matTooltip="Neuer Textbaustein">
                add
            </mat-icon>

            <!-- Demo data selection toggle -->
            <mat-icon
                class="toolbar-icon"
                [style.color]="exampleDataActive ? 'var(--primary-color)' : undefined"
                (click)="toggleExampleDataActive()"
                matTooltip="Im Vorschau-Modus werden Platzhalter in Textbausteinen mit Daten aus dem ausgewählten Gutachten oder der ausgewählten Rechnung befüllt.">
                preview
            </mat-icon>

            <mat-icon
                class="toolbar-icon"
                [style.color]="viewOnlyCountedBlocksForCountingReportPdfPages ? 'var(--primary-color)' : undefined"
                (click)="toggleViewOnlyBlocksForCountingReportPdfPages(); filterAndSortBuildingBlocks()"
                matTooltip="Nur beim Seitenzählen berücksichtigte Bausteine anzeigen.">
                calculate
            </mat-icon>

            <!-- Compact view toggle -->
            <mat-icon
                class="toolbar-icon"
                *ngIf="!compactViewActive"
                (click)="toggleCompactView()"
                matTooltip="Kompakt-Ansicht">
                view_comfy
            </mat-icon>
            <mat-icon
                class="toolbar-icon"
                *ngIf="compactViewActive"
                (click)="toggleCompactView()"
                matTooltip="Erweiterte Ansicht">
                view_compact
            </mat-icon>
        </div>
    </div>
    <!--============================================-->
    <!-- END Head Runner -->
    <!--============================================-->

    <!--============================================-->
    <!-- Example data selection runner -->
    <!--============================================-->
    <div
        *ngIf="exampleDataActive"
        id="example-data-selection-runner"
        class="card dark-card"
        [style.position]="exampleDataPinned ? 'sticky' : undefined">
        <!-- Example data selection info -->
        <div class="example-data-selection-info">
            <div class="example-data-selection-info-first-row">
                <div>Vorschau</div>
                <mat-icon
                    class="outlined"
                    matTooltip="Beispiel-Daten werden in den Textbausteinen angezeigt, um dir die Bearbeitung zu erleichtern.">
                    help
                </mat-icon>
            </div>
            <div class="example-data-selection-info-second-row">für Platzhalter & Textbausteinauswahl</div>
        </div>

        <!-- Example data selection -->
        <div class="example-data-selection">
            <!-- Example report selection-->
            <select-report-dropdown
                [report]="selectedExampleReport"
                (reportSelected)="handleExampleReportSelected($event)"></select-report-dropdown>

            <!-- Example invoice selection-->
            <select-invoice-dropdown
                [invoice]="selectedExampleInvoice"
                [reportId]="selectedExampleReport?._id"
                (invoiceSelected)="handleExampleInvoiceSelected($event)"></select-invoice-dropdown>
        </div>

        <!-- Buttons row -->
        <div class="example-data-selection-buttons-row">
            <!-- Pin toggle button -->
            <div
                class="example-data-selection-pin-button"
                [matTooltip]="exampleDataPinned ? 'Beispiel-Daten lösen' : 'Beispiel-Daten fixieren'"
                (click)="toggleExampleDataPinned()">
                <mat-icon class="toolbar-icon" [style.color]="exampleDataPinned ? 'var(--primary-color)' : undefined">
                    push_pin
                </mat-icon>
            </div>

            <!-- Close button -->
            <div class="example-data-selection-close-button" (click)="toggleExampleDataActive()">
                <mat-icon class="toolbar-icon">close</mat-icon>
            </div>
        </div>
    </div>

    <!--============================================-->
    <!-- END Example data selection runner -->
    <!--============================================-->

    <!--============================================-->
    <!-- Document Building Blocks -->
    <!--============================================-->
    <div
        [id]="'block-' + buildingBlock._id"
        class="document-building-block card card-with-padding"
        *ngFor="let buildingBlock of filteredBuildingBlocks"
        [attr.data-building-block-id]="buildingBlock._id"
        [ngClass]="{ 'compact-view': compactViewActive }">
        <document-type-tag-list
            class="building-block-tag-list"
            [allDocumentOrderConfigs]="documentOrderConfigs"
            [selectedDocumentOrderConfigIds]="getConnectedDocumentOrderConfigIds(buildingBlock._id)"
            [disabled]="!user.accessRights.editTextsAndDocumentBuildingBlocks"
            (change)="handleDocumentTypeChanges(buildingBlock._id, $event)"></document-type-tag-list>
        <div class="building-block-enhanced-container">
            <!--********** Enhanced Building Block **********-->
            <div
                class="building-block-has-extensions"
                *ngIf="enhancedDocumentBuildingBlocks.includes(buildingBlock.placeholder)"
                (click)="openEnhancedDocumentBuildingBlockHelp(buildingBlock)"
                [matTooltip]="'autoiXpert ergänzt Elemente beim Druck.\n\nFür Details klicken.'">
                <div class="label">erweitert</div>
                <mat-icon class="icon">verified_user</mat-icon>
            </div>

            <!--********** Custom Partial **********-->
            <div class="building-block-has-custom-partial">
                <mat-icon *ngIf="buildingBlock.customPartialHash" matTooltip="Eigene DOCX-Vorlage wird verwendet.">
                    person
                </mat-icon>
            </div>
        </div>

        <!--********** Block Header row **********-->
        <div class="building-block-header-row">
            <!-- Building block conditions -->
            <div
                *ngIf="buildingBlock.conditions && buildingBlock.conditions.length > 0; else noConditionsPlaceholder"
                class="building-block-conditions">
                <!-- Background ellipsis -->
                <div class="building-block-conditions-background-ellipsis"></div>

                <!-- Background triangle -->
                <div class="building-block-conditions-background-triangle"></div>

                <!-- Background blue -->
                <div class="building-block-conditions-background-blur"></div>

                <document-building-block-conditions-preview
                    [buildingBlockConditionsPreview]="true"
                    [conditionsGroup]="buildingBlock"
                    [buildingBlockTitle]="buildingBlock.title"
                    [placeholderValueTree]="placeholderValueTree"
                    [isDisabled]="!user?.accessRights.editTextsAndDocumentBuildingBlocks"
                    [previewActive]="exampleDataActive"
                    [placeholderValues]="selectedReportPlaceholderValues"
                    (conditionPreviewClick)="
                        openBuildingBlockEditor(buildingBlock)
                    "></document-building-block-conditions-preview>
            </div>

            <ng-template #noConditionsPlaceholder>
                <!-- Add button -->
                <div class="building-block-conditions-add-button-container">
                    <div
                        class="building-block-conditions-add-button"
                        *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                        (click)="openBuildingBlockEditor(buildingBlock)">
                        <mat-icon class="small-icon">add</mat-icon>
                        Bedingungen für Baustein
                    </div>
                </div>
            </ng-template>

            <!-- Building block title -->
            <h2 class="building-block-heading" *ngIf="!blockTitlesInEditMode.has(buildingBlock)">
                {{ buildingBlock.title }}
                <mat-icon
                    class="building-block-heading-edit-icon input-toolbar-icon small-icon"
                    *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                    (click)="enterBlockTitleEditMode(buildingBlock)"
                    matTooltip="Titel bearbeiten. Der Titel wird nur dir angezeigt. Abgedruckt wird die Überschrift der Varianten.">
                    edit
                </mat-icon>
            </h2>

            <!-- Building block title input -->
            <div class="building-block-heading-input-container" *ngIf="blockTitlesInEditMode.has(buildingBlock)">
                <mat-form-field>
                    <input
                        #blockTitleInput
                        matInput
                        placeholder="Titel"
                        [ngModel]="newBlockTitlesInEditMode.get(buildingBlock._id)"
                        (ngModelChange)="newBlockTitlesInEditMode.set(buildingBlock._id, $event)"
                        (keydown)="confirmBlockTitleChangeModeOnEnter($event, buildingBlock)"
                        [axAutofocus]="true" />
                    <mat-icon
                        matSuffix
                        class="input-toolbar-icon"
                        [class.disabled]="!isBuildingBlockTitleValid(blockTitleInput.value, buildingBlock._id)"
                        (click)="confirmBuildingBlockTitleChange(buildingBlock)"
                        [matTooltip]="
                            !isBuildingBlockTitleValid(blockTitleInput.value, buildingBlock._id)
                                ? 'Bitte wähle einen anderen Titel'
                                : 'Auswahl speichern'
                        ">
                        check
                    </mat-icon>
                    <mat-hint *ngIf="isBlockTitleAlreadyTaken(blockTitleInput.value, buildingBlock._id)" class="error">
                        Es existiert bereits ein Textbaustein mit diesem Namen.
                    </mat-hint>
                    <mat-hint *ngIf="newBlockTitlesInEditMode.get(buildingBlock._id) === ''" class="error">
                        Der Titel darf nicht leer sein.
                    </mat-hint>
                </mat-form-field>
            </div>

            <!-- Building block sub-menu -->
            <div class="building-block-submenu-container">
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                    (click)="addVariant(buildingBlock)"
                    matTooltip="Neue Variante">
                    add
                </mat-icon>
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                    (click)="openBuildingBlockConfiguration(buildingBlock)"
                    matTooltip="Einstellungen">
                    settings
                </mat-icon>
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                    [matMenuTriggerFor]="buildingBlockSubmenu">
                    more_vert
                </mat-icon>
                <mat-menu #buildingBlockSubmenu>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="buildingBlock.custom"
                        (click)="removeDocumentBuildingBlock(buildingBlock)">
                        <mat-icon>delete</mat-icon>
                        Textbaustein löschen
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="!buildingBlock.custom"
                        (click)="resetDocumentBuildingBlock(buildingBlock)"
                        [matTooltip]="getResetBuildingBlockTooltip(buildingBlock)">
                        <mat-icon>restore</mat-icon>
                        Standard wiederherstellen
                    </a>
                </mat-menu>
            </div>
        </div>

        <!--============================================-->
        <!-- Info Note: Values for Fee Agreement -->
        <!--============================================-->
        <div id="info-note-fee-agreement" class="info-note" *ngIf="buildingBlock.placeholder === 'Honorarvereinbarung'">
            <mat-icon>euro_symbol</mat-icon>
            <div>
                Die Werte für die
                <strong>Nebenkosten-Platzhalter</strong>
                werden automatisch aus den Werten ausgelesen, die du im Gutachten-Prozess im Reiter Rechnung über den
                <a href="https://wissen.autoixpert.de/hc/de/articles/360007303431" rel="noopener" target="_blank">
                    Button "Gebühren merken" festgelegt hast.
                </a>
            </div>
        </div>
        <!--============================================-->
        <!-- END Info Note: Values for Fee Agreement -->
        <!--============================================-->

        <div
            class="building-block-variant-list"
            cdkDropList
            (cdkDropListDropped)="reorderVariantsArray(buildingBlock, $event)">
            <div
                class="building-block-variant"
                *ngFor="let variant of buildingBlock.variants"
                [attr.data-variant-id]="variant._id"
                [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                (dblclick)="openDocumentBlockVariantEditor(buildingBlock, variant)"
                cdkDrag>
                <div class="conditions-list card dark-card">
                    <document-building-block-conditions-preview
                        [buildingBlockConditionsPreview]="false"
                        [conditionsGroup]="variant"
                        [buildingBlockTitle]="buildingBlock.title"
                        [isDefaultVariant]="variant.isDefaultVariant"
                        [placeholderValueTree]="placeholderValueTree"
                        [isDisabled]="!user?.accessRights.editTextsAndDocumentBuildingBlocks"
                        [previewConditionEvaluationResult]="
                            selectedExampleDataVariantConditionsEvaluationResults[variant._id] ?? null
                        "
                        [previewActive]="exampleDataActive"
                        [placeholderValues]="selectedReportPlaceholderValues"
                        (conditionPreviewClick)="
                            openDocumentBlockVariantEditor(buildingBlock, variant)
                        "></document-building-block-conditions-preview>
                </div>

                <div class="variant-text-container">
                    <mat-icon class="drag-handle" matTooltip="per Drag & Drop verschieben" cdkDragHandle>
                        drag_indicator
                    </mat-icon>
                    <div class="variant-heading" *ngIf="variant.heading">{{ getVariantHeading(variant) }}</div>
                    <div
                        class="variant-content"
                        *ngIf="variant.content"
                        [quillContent]="getVariantContent(variant)"></div>
                    <div class="variant-heading no-heading-placeholder" *ngIf="!variant.heading && !variant.content">
                        Kein Inhalt vorhanden
                    </div>
                    <div class="variant-text-toolbar">
                        <mat-icon
                            class="variant-text-toolbar-icon toolbar-icon"
                            *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                            (click)="openDocumentBlockVariantEditor(buildingBlock, variant)"
                            matTooltip="Variante bearbeiten">
                            edit
                        </mat-icon>
                        <mat-icon
                            class="variant-text-toolbar-icon toolbar-icon"
                            *ngIf="user?.accessRights.editTextsAndDocumentBuildingBlocks"
                            [matMenuTriggerFor]="variantSubmenu">
                            more_vert
                        </mat-icon>
                        <mat-menu #variantSubmenu="matMenu">
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="copyVariant(buildingBlock, variant); saveDocumentBuildingBlock(buildingBlock)">
                                <mat-icon>content_copy</mat-icon>
                                Kopieren
                            </a>

                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="toggleIsDefaultVariant(buildingBlock, variant)"
                                matTooltip="Die Auffang-Variante wird gedruckt, wenn sonst keine Variante dieses Bausteins gedruckt wird. Besonders gut geeignet für den Standard-Text eines Bausteins.">
                                <mat-icon>web_stories</mat-icon>
                                Auffang-Variante
                            </a>

                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="!buildingBlock.custom && variant.changedByUser"
                                (click)="resetVariant(buildingBlock, variant)">
                                <mat-icon>restore</mat-icon>
                                Standard wiederherstellen
                            </a>

                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="
                                    removeVariant(buildingBlock, variant); saveDocumentBuildingBlock(buildingBlock)
                                ">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </a>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="additional-information-on-building-block-container">
            <div class="label" matTooltip="{{ buildingBlock.updatedAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                Geändert: {{ buildingBlock.updatedAt | moment }}
            </div>
        </div>
        <div
            class="flex-center-content"
            *ngIf="
                buildingBlock.placeholder === 'DeckblattMitFoto' || buildingBlock.placeholder === 'DeckblattOhneFoto'
            ">
            <div
                class="title-option-container margin-right-50"
                [ngClass]="{ active: activeCoverPage() === 'DeckblattMitFoto' }"
                (click)="toggleCoverPage('DeckblattMitFoto')">
                <img
                    class="margin-right-20"
                    src="/assets/images/icons/front-page-with-photo.png"
                    alt="Deckblatt mit Foto" />
                Deckblatt
                <br />
                mit Foto
            </div>
            <div
                class="title-option-container"
                [ngClass]="{ active: activeCoverPage() === 'DeckblattOhneFoto' }"
                (click)="toggleCoverPage('DeckblattOhneFoto')">
                <img
                    class="margin-right-20"
                    src="/assets/images/icons/front-page-without-photo.png"
                    alt="Deckblatt ohne Foto" />
                Deckblatt
                <br />
                ohne Foto
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Document Building Blocks -->
    <!--============================================-->
</div>

<!-- Building block variant editor -->
<document-building-block-variant-editor
    *ngIf="documentBuildingBlockVariantEditorShown"
    [buildingBlock]="currentlyEditedBuildingBlock"
    [variant]="currentlyEditedVariant"
    [initialTopPosition]="editorInitialTopPosition"
    [placeholderValues]="selectedReportPlaceholderValues"
    (variantChange)="processVariantEditorChangeEvent($event)"
    (close)="closeDocumentBlockVariantEditor()"
    (openBuildingBlockEditor)="closeVariantEditorAndOpenBuildingBlockEditor()"
    @runChildAnimations></document-building-block-variant-editor>

<!-- Building block config editor -->
<document-building-block-config-dialog
    *ngIf="buildingBlockForConfig"
    [buildingBlock]="buildingBlockForConfig"
    (buildingBlockChange)="saveDocumentBuildingBlock($event)"
    (close)="hideBuildingBlockConfiguration()"
    @runChildAnimations></document-building-block-config-dialog>

<!-- Building block editor -->
<document-building-block-editor
    *ngIf="documentBuildingBlockEditorShown"
    [buildingBlock]="currentlyEditedBuildingBlock"
    [placeholderValues]="selectedReportPlaceholderValues"
    (buildingBlockChange)="processConditionsEditorChangeEvent($event)"
    (close)="closeBuildingBlockEditor()"
    @runChildAnimations></document-building-block-editor>

<!-- Document order config creation dialog-->
<document-order-config-creation-dialog
    *ngIf="documentOrderConfigCreationDialogShownWithType"
    [location]="'document-building-blocks'"
    [documentOrderConfigToEdit]="documentOrderConfigCreationDialogConfigToEdit"
    [documentType]="documentOrderConfigCreationDialogShownWithType"
    (documentOrderConfigCreated)="handleDocumentOrderConfigCreated($event)"
    (close)="closeDocumentOrderConfigCreationDialog()"
    @runChildAnimations></document-order-config-creation-dialog>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInAndOutAnimation } from 'src/app/shared/animations/fade-in-and-out.animation';

@Component({
    selector: 'preference-section-deactivated',
    templateUrl: './preference-section-deactivated.component.html',
    styleUrls: ['./preference-section-deactivated.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class PreferenceSectionDeactivatedComponent {
    @Input() isDeactivated = false;
    @Output() switchToLoggedInUser = new EventEmitter<never>();
}

import { RequireSome } from '../../helper-types/require-some';
import { IsoDate } from '../../lib/date/iso-date.types';
import { InvoiceInvolvedPartyRole } from './invoice';

export class PaymentReminder {
    constructor(template: RequireSome<PaymentReminder, 'recipientRole' | 'level'>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    level: PaymentReminderLevel = null; // 0 = Zahlungserinnerung; 1 = 1. Mahnung; 2 = 2. Mahnung; 3 = 3. Mahnung;
    amountNet: number = null; // Dunning amount (excl. fee)
    feeNet: number = null;
    dueDate: IsoDate = null; // Date when the bill has to be finally paid
    date: IsoDate = null; // Date when the payment reminder is written
    totalNet: number = null;
    totalGross: number = null;
    recipientRole: InvoiceInvolvedPartyRole = null;
    senderId: string = null; // The ID of the user who created the reminder.
}

export type PaymentReminderLevel = 'level0' | 'level1' | 'level2' | 'level3';

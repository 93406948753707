import { Car } from '../../models/reports/car-identification/car';
import { Report } from '../../models/reports/report';

/**
 * Display internal combustion engine specific fields only if the vehicle has an internal combustion engine.
 * Battery electric vehicles (BEV) and fuel cell vehicles (FCEV) do not have an internal combustion engine.
 * A hybrid vehicle may have both, an internal combustion engine and an electric motor.
 */
export function doesVehicleHaveInternalCombustionEngine(report: Report): boolean {
    return (
        report.car.runsOnGasoline ||
        report.car.runsOnDiesel ||
        report.car.runsOnLPG ||
        report.car.runsOnNaturalGasoline ||
        report.car.runsOnBiodiesel ||
        !!report.car.runsOnSomethingElse
    );
}

/**
 * Display electric vehicle specific fields only if the vehicle has a battery electric engine.
 *
 * Returns true for fully electric and hybrid vehicles.
 */
export function doesVehicleHaveBatteryElectricEngine(report: Report): boolean {
    return report.car.runsOnElectricity;
}

/**
 * Some car shapes do not have an engine.
 * Therefore engine specific fields should not be displayed for these car shapes.
 */
export function doesVehicleShapeHaveEngine(carShape: Car['shape']): boolean {
    // Display the internal combustion engine fields as default
    if (!carShape) return true;
    return !new Array<Car['shape']>(
        'trailer',
        'semiTrailer',
        'caravanTrailer',
        'bicycle',
        'e-bike',
        'pedelec',
    ).includes(carShape);
}

import { AxError } from '../../../models/errors/ax-error';
import { removeFromArray } from '../../arrays/remove-from-array';
import { filterConflictingChanges } from '../filter-conflicting-changes';
import { getListOfDifferences } from '../get-list-of-differences';
import { insertChangesIntoRecord } from '../insert-changes-into-record';
import { isChangeDealingWithArrayOfObjectsWithIds } from './is-change-dealing-with-array-of-objects-with-ids';

export function mergeChangesBetweenArraysOfObjectsWithIds({
    serverShadowArray,
    localArray,
    remoteArray,
    forceMergeConflictingChanges,
}: {
    serverShadowArray: ObjectWithId[];
    localArray: ObjectWithId[];
    remoteArray: ObjectWithId[];

    /**
     * Used for changes that originated in another browser tab. They are sent through a BroadcastChannel and
     * must be force merged. If we did not force-merge, a race condition between the BroadcastChannel and the IndexedDB
     * may cause conflicting changes to be detected where there are none. Especially with arrays such as report.photos,
     * that leads to unpredictable merge results.
     */
    forceMergeConflictingChanges?: boolean;
}): ObjectWithId[] {
    /**
     * The server shadow array may be null or undefined if the property was not yet initialized when the last
     * server sync happened. This function may have been reached if both the remote and the local properties
     * have been initialized in the meantime.
     */
    if (!Array.isArray(serverShadowArray)) {
        serverShadowArray = [];
    }

    // We cannot merge if the array elements don't have IDs. It's required for comparing elements that might have moved.
    if (!isChangeDealingWithArrayOfObjectsWithIds(localArray, remoteArray)) {
        console.error(
            "[Merge arrays] Couldn't merge because some array is missing IDs. Keep the local array. (serverShadowArray, localArray, remoteArray): ",
            serverShadowArray,
            localArray,
            remoteArray,
        );
        throw new AxError({
            code: 'MERGING_ARRAYS_FAILED_SINCE_ELEMENTS_ARE_MISSING_IDS',
            message: 'Could not merge arrays because some array is missing IDs.',
            data: {
                serverShadowElementsWithoutId: serverShadowArray.filter((element) => !element._id),
                localElementsWithoutId: localArray.filter((element) => !element._id),
                remoteElementsWithoutId: remoteArray.filter((element) => !element._id),

                serverShadowArray,
                localArray,
                remoteArray,
            },
        });
    }

    //*****************************************************************************
    //  Merge Sort Order
    //****************************************************************************/
    /**
     * Get IDs of all arrays to compare sort order.
     */
    const serverShadowIds: string[] = serverShadowArray.map((element) => element._id);
    const localIds: string[] = localArray.map((element) => element._id);
    const remoteIds: string[] = remoteArray.map((element) => element._id);

    // The order changes, too, if the content changes (elements added or removed).
    const orderHasChangedLocally = serverShadowIds.join(',') !== localIds.join(',');
    const orderHasChangedRemotely = serverShadowIds.join(',') !== remoteIds.join(',');
    const orderHasChanged = orderHasChangedLocally || orderHasChangedRemotely;
    const isOrderConflict = orderHasChangedLocally && orderHasChangedRemotely;

    // IDs of elements in final order.
    let finalSortOrder: string[];

    if (orderHasChanged) {
        console.log(
            '[Merge arrays] Order has changed (either locally or remotely). (serverShadowArray, localArray, remoteArray):',
            serverShadowArray,
            localArray,
            remoteArray,
        );

        //*****************************************************************************
        //  Order Conflict
        //****************************************************************************/
        // Order has changed both locally and remotely -> order conflict. Merge remote additions and deletions locally.
        if (isOrderConflict && !forceMergeConflictingChanges) {
            console.log('[Merge arrays] Order has changed both locally and remotely.');

            // Start with the last known local state and apply changes from remote.
            finalSortOrder = [...localIds];

            /**
             * Additions = Present now, but not present before.
             *
             * Comparison with localIds is relevant with Broadcast Channels (while offline) which don't update the server shadow.
             */
            const remoteAdditions: string[] = remoteIds.filter(
                (remoteId) => !serverShadowIds.includes(remoteId) && !localIds.includes(remoteId),
            );
            const remoteDeletions: string[] = serverShadowIds.filter(
                (serverShadowId) => !remoteIds.includes(serverShadowId),
            ); // Deletions = Present before, but not present now.

            //*****************************************************************************
            //  Add New Elements
            //****************************************************************************/
            // Add all remote additions to local IDs. Try to add them as close to their predecessor as possible.
            for (const remoteAddition of remoteAdditions) {
                const remoteIndex = remoteIds.indexOf(remoteAddition);

                // To insert the remote addition at the right position, we must identify the element it was inserted after.
                let predecessorId: string | undefined = undefined;
                // Check if any of the predecessors still exists.
                for (let i = remoteIndex - 1; i >= 0; i--) {
                    const predecessor: string = remoteIds[i];
                    const predecessorExistsInLocalIds: boolean = localIds.includes(predecessor);
                    if (predecessorExistsInLocalIds) {
                        predecessorId = predecessor;
                        break;
                    }
                }

                // If the remote predecessor's ID exists locally as well, insert the new element after that.
                // We use the finalSortOrder instead of the localIds to consider already inserted elements as reference points for later ones.
                if (predecessorId != undefined) {
                    const index = finalSortOrder.indexOf(predecessorId);
                    finalSortOrder.splice(index + 1, 0, remoteAddition);
                }
                // No predecessor -> Simply insert at the end. This is the case for the first item of the additions if it's at index 0 within the remote IDs.
                else {
                    finalSortOrder.push(remoteAddition);
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Add New Elements
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Remove Deleted Elements
            //****************************************************************************/
            for (const remoteDeletion of remoteDeletions) {
                removeFromArray(remoteDeletion, finalSortOrder);
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Remove Deleted Elements
            /////////////////////////////////////////////////////////////////////////////*/
        }
        ///////////////////////////////////////////////////////////////////////////*/
        //  END Order Conflict
        /////////////////////////////////////////////////////////////////////////////*/
        /**
         * Order has changed remotely only -> Use that order.
         * Forcing to merge conflicting changes (occurs when syncing local Broadcast Channel changes) -> Use remote order.
         */
        else if (orderHasChangedRemotely || (isOrderConflict && forceMergeConflictingChanges)) {
            finalSortOrder = [...remoteIds];
        } else {
            /**
             * Order has changed locally only
             */
            finalSortOrder = [...localIds];
        }
    } else {
        // If the order hasn't changed, we could use either localIds or remoteIds since they're the same.
        finalSortOrder = [...localIds];
    }

    if (!finalSortOrder.length) {
        console.warn('[Merge arrays] After merging, the finalSortOrder is empty.');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Merge Sort Order
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Merge Elements
    //****************************************************************************/

    const sortedMergeResult: { _id: string }[] = [];

    /**
     * For each element, get the changes and merge.
     */
    for (const elementId of finalSortOrder) {
        // Retrieve the right element from each array (server shadow, local, remote) by its ID.
        const serverShadowElement = serverShadowArray.find((element) => element._id === elementId);
        const localElement = localArray.find((element) => element._id === elementId);
        const remoteElement = remoteArray.find((element) => element._id === elementId);

        // If the local element is missing from the local array, this must mean that the element has been created on the remote.
        if (!localElement) {
            if (remoteElement) {
                sortedMergeResult.push(remoteElement);
            }
            continue;
        }

        const localChanges = getListOfDifferences(serverShadowElement, localElement);
        const remoteChanges = getListOfDifferences(serverShadowElement, remoteElement);

        const { nonConflictingChanges } = filterConflictingChanges(localChanges, remoteChanges);

        // Only merge non-conflicting changes. The conflicting ones are dropped so that the localChanges win.
        insertChangesIntoRecord(localElement, nonConflictingChanges);

        sortedMergeResult.push(localElement);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Merge Elements
    /////////////////////////////////////////////////////////////////////////////*/

    return sortedMergeResult;
}

interface ObjectWithId {
    _id: string;
}

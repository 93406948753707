import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Trim the value of a field when the user leaves the field.
 * Just add the attribute "axTrim" to the element.
 * Example: <input type="text" id="organization" axTrim>
 */
@Directive({
    selector: 'input[axTrim], textarea[axTrim]',
})
export class TrimDirective {
    constructor(
        private el: ElementRef<HTMLInputElement | HTMLTextAreaElement>,
        private ngControl: NgControl,
    ) {}

    @HostListener('change') trimOnChange() {
        if (!this.el.nativeElement.value) return;
        if (typeof this.el.nativeElement.value !== 'string') return;

        const trimmedValue = this.el.nativeElement.value.trim();
        // Only trigger setting the value again if the value has changed to prevent unnecessary computations.
        if (this.el.nativeElement.value !== trimmedValue) {
            this.el.nativeElement.value = trimmedValue;
            this.ngControl.control.setValue(trimmedValue);
        }
    }
}

import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { FieldConfig } from '../custom-fields/field-config';
import { DataTypeBase } from '../indexed-db/database.types';

export class TextTemplate implements DataTypeBase {
    constructor(options: RequireSome<TextTemplate, 'type'>) {
        Object.assign(this, options);

        if (!this._id) {
            this._id = generateId();
        }
    }

    _id: string = null;
    idSetByUser: string = null;
    dragOrderPosition: number = null;
    title: string = null; // Überschrift
    subject: string = null; // Betreff (nur bei Typen Anschreiben & E-Mail)
    body: string = null; // Textkörper
    type: TextTemplateType = null; // coverLetter, email, unrepairedPreviousDamage, repairedPreviousDamage
    role: TextTemplateRole = null; // What role may this templates be used for? -> each involved party
    customFieldConfigId?: FieldConfig['_id'] = null; // Only filled if this template is used for multiLine custom fields.
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string = null;
    teamId: string = null;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

export const textTemplateTypes = [
    'batteryDamage',
    'batteryStateOfHealth',
    'highVoltageSystemCheck',
    'coverLetter',
    'email',
    'invoiceEmail',
    'unrepairedPreviousDamage',
    'repairedPreviousDamage',
    'invoiceLetter',
    'oldtimerValuationVehicleDescription',
    'damageDescription',
    'plausibility',
    'damageCircumstances',
    'meantimeDamage',
    'paintBlending',
    'diminishedValueComment',
    'replacementValueRemark',
    'residualValueRemark',
    'residualValueRequestComment',
    'carComment',
    'valueIncreaseRemark',
    'axisMeasurement',
    'carBodyMeasurement',
    'expertStatement',
    'expertStatementLetter',
    'remoteSignatureEmail',
    'visitComment',
    'repairInstructions',
    'technicalDiminishedValueComment',
    'downtimeCompensationComment',
    'compatibility',
    'repairCalculationComment',
    'repairConfirmationComment',
    'repairRisksRemark',
    'mileageRemark',
    'customField', // When used, a customFieldConfigId must be set.
    'assessorsFeeDescription',
    'partialInvoiceCancellationInternalNote',
    'paintThicknessComment',
    'invoiceIntro',
    'amendmentReason',
    'retrofitComment',
] as const;
export type TextTemplateType = (typeof textTemplateTypes)[number];

export const textTemplateRoles = [
    'claimant',
    'ownerOfClaimantsCar',
    'authorOfDamage',
    'ownerOfAuthorOfDamagesCar',
    'garage',
    'lawyer',
    'insurance',
    'leaseProvider',
    'factoringProvider',
    'seller',
    'invoiceRecipient',
    'recipient',
] as const;
export type TextTemplateRole = (typeof textTemplateRoles)[number];

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnalyticsFilterComponent } from '../analytics-filter/analytics-filter.component';

@Component({
    selector: 'analytics-filter-button',
    templateUrl: './analytics-filter-button.component.html',
    styleUrls: ['./analytics-filter-button.component.scss'],
})
export class AnalyticsFilterButtonComponent implements OnInit, OnDestroy {
    @Input() isExpanded: boolean = false;

    /**
     * Reference to the analytics-filter element (filter dropdown), which is a separate element.
     * That is necessary because the filter button is located in a different place than the dropdown.
     */
    @Input() filterRef: AnalyticsFilterComponent;

    /**
     * Label listing all selected filters when at least one filter is selected.
     */
    protected activeFiltersLabel: string = '';
    private subscriptions: Subscription[] = [];

    ngOnInit() {
        this.getAnalyticsFilterExpandedStateFromLocalStorage();

        this.subscriptions.push(
            this.filterRef.filterChange.subscribe(() => {
                this.activeFiltersLabel = this.getActiveFiltersLabel();
            }),
        );

        this.subscriptions.push(
            this.filterRef.initialized.subscribe(() => {
                this.activeFiltersLabel = this.getActiveFiltersLabel();
            }),
        );
    }

    /**
     * Open or close the filter dropdown. This toggles the visibility of the filter dropdown
     * as well as the visual expanded state of this filter button.
     */
    protected toggleExpandedState(): void {
        this.isExpanded = !this.isExpanded;
        this.filterRef.isExpanded = this.isExpanded;
        this.rememberExpandedStateLocally();
    }

    private getAnalyticsFilterExpandedStateFromLocalStorage(): void {
        try {
            this.isExpanded = store.get('analyticsFilterOpen', false);
        } catch (err: unknown) {
            // Catch errors in case the browser does not support local storage or the storage is full
            // Do nothing as this feature is not critical
        }

        this.filterRef.isExpanded = this.isExpanded;
    }

    rememberExpandedStateLocally(): void {
        try {
            store.set('analyticsFilterOpen', this.isExpanded);
        } catch (err: unknown) {
            // Catch errors in case the browser does not support local storage or the storage is full
            // Do nothing as this feature is not critical
        }
    }

    protected getActiveFiltersLabel(): string {
        const filterNames = [];

        if (this.filterRef.assessorIds?.length > 0) filterNames.push('Gutachter');
        if (this.filterRef.officeLocationIds?.length > 0) filterNames.push('Standort');
        if (this.filterRef.reportLabelConfigIds?.length > 0) filterNames.push('GA-Label');
        if (this.filterRef.invoiceLabelConfigIds?.length > 0) filterNames.push('RE-Label');

        if (filterNames.length === 0) return null;

        return `${filterNames.length} Filter: ${filterNames.join(', ')}`;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

<h2 matDialogTitle>DATEV-Export</h2>
<mat-icon class="dialog-close-icon" matDialogClose matTooltip="Schließen">close</mat-icon>
<mat-dialog-content class="margin-bottom-30">
    <div class="label text-align-center margin-bottom-30">
        Exportiere Rechnungen inkl. strukturierter Daten für deinen Steuerberater
    </div>

    <!--============================================-->
    <!-- Warn when missing admin rights  -->
    <!--============================================-->
    <div class="info-note-admin-right" *ngIf="!userHasAdminRights">
        <div class="info-note warning-note">
            <mat-icon class="medium-icon">warning</mat-icon>
            <div>
                <h3>Admin-Rechte erforderlich</h3>
                <div>Wende dich an deinen Administrator, um die Einstellungen für den DATEV-Export zu verändern.</div>
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Warn when missing admin rights  -->
    <!--============================================-->

    <div id="datev-logo-and-inputs-container" class="display-flex">
        <!--============================================-->
        <!-- DATEV Logo Column -->
        <!--============================================-->
        <div id="datev-logo-column" class="flex-center-content">
            <div id="datev-logo-container">
                <img src="/assets/images/logos/datev.png" alt="" />
            </div>
        </div>
        <!--============================================-->
        <!-- END DATEV Logo Column -->
        <!--============================================-->

        <!--============================================-->
        <!-- Inputs -->
        <!--============================================-->
        <!--********** Export by invoice date **********-->
        <div
            class="large-selection-box"
            [ngClass]="{ selected: datevConfig.exportByInvoiceOrPaymentDate === 'invoiceDate' }"
            (click)="selectExportType('invoiceDate')"
            matTooltip="Alle Rechnungen, deren Rechnungsdatum innerhalb der Datumsgrenzen des Exports liegen, werden exportiert.">
            <div class="large-selection-box-active-marker">
                <mat-icon>check</mat-icon>
            </div>
            <div class="semibold">nach Rechnungsdatum</div>
            <div class="label">für Sollversteuerung</div>
        </div>

        <!--********** Export by payment date **********-->
        <div
            class="large-selection-box"
            [ngClass]="{ selected: datevConfig.exportByInvoiceOrPaymentDate === 'paymentDate' }"
            (click)="selectExportType('paymentDate')"
            matTooltip="Alle Rechnungen, für die innerhalb der Datumsgrenzen eine Zahlung eingegangen ist, werden exportiert.">
            <div class="large-selection-box-active-marker">
                <mat-icon>check</mat-icon>
            </div>
            <div class="semibold">nach Zahlungsdatum</div>
            <div class="label">für Istversteuerung</div>
        </div>
        <!--============================================-->
        <!-- END Inputs -->
        <!--============================================-->
    </div>

    <div
        id="datev-advanced-settings-toggle"
        class="cursor-pointer"
        (click)="areAdvancedOptionsVisible = !areAdvancedOptionsVisible"
        [ngClass]="{ open: areAdvancedOptionsVisible }">
        <span>
            <b>Erweiterte Einstellungen</b>
            (optional)
        </span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
    <div
        id="datev-export-settings-container"
        (mouseDown)="userHasAdminRights || displayMissingAccessRightsError()"
        *ngIf="areAdvancedOptionsVisible"
        @slideInAndOutVertically>
        <preferences-row iconName="people_outline" [noHorizontalPadding]="true">
            <span>Sammelkonto (Sammeldebitor)</span>
            <div class="label light">
                Du kannst pro Rechnungsempfänger ein Kundenkonto (Debitorenkonto) angeben. Wenn beim Empfänger kein
                Kundenkonto angegeben ist (lohnt sich meistens nur für wiederkehrende Kunden) wird dieses Sammelkonto
                verwendet.
            </div>

            <mat-form-field right class="without-hint-spacing">
                <input
                    id="suffix-input-field"
                    matInput
                    [disabled]="!userHasAdminRights"
                    placeholder="Sammelkonto"
                    pattern="[0-9]*"
                    [(ngModel)]="datevConfig.defaultBusinessPartnerAccountNo" />
            </mat-form-field>
        </preferences-row>
        <preferences-row iconName="account_balance_wallet" [noHorizontalPadding]="true">
            <span>Gegenkonto</span>
            <div class="label light">
                Wenn du nichts einträgst wird hier das Konto 8400 vergeben, bei dem DATEV in den Unternehmen mit SKR03
                die Umsatzsteuer automatisch verbucht.
            </div>

            <mat-form-field right class="without-hint-spacing">
                <input
                    id="suffix-input-field"
                    matInput
                    [disabled]="!userHasAdminRights"
                    pattern="[0-9]*"
                    placeholder="Gegenkonto"
                    [matAutocomplete]="accountNoAutocomplete"
                    [(ngModel)]="datevConfig.accountNo" />
            </mat-form-field>
            <mat-autocomplete #accountNoAutocomplete="matAutocomplete">
                <mat-option
                    value="4400"
                    matTooltip="Automatikkonto für Umsätze mit 19% Mehrwertsteuer im Kontenrahmen SKR 04.">
                    <span>4400</span>
                    <span class="label">SKR 04</span>
                </mat-option>
                <mat-option
                    value="8400"
                    matTooltip="Automatikkonto für Umsätze mit 19% Mehrwertsteuer im Kontenrahmen SKR 03 (Standard in autoiXpert).">
                    <span>8400</span>
                    <span class="label">SKR 03</span>
                </mat-option>
            </mat-autocomplete>
        </preferences-row>
        <preferences-row iconName="point_of_sale" [noHorizontalPadding]="true">
            <span>Kostenstelle</span>
            <div class="label light">
                Kostenstellen helfen dir, Rechnungen und Ausgaben einzelner Bereiche des Unternehmens auszuwerten (z. B.
                Betriebsabrechnungsbogen in DATEV). Du kannst in autoiXpert Kostenstellen pro Sachverständiger oder pro
                Standort angeben, ansonsten wird die hier eingetragene Kostenstelle verwendet.
            </div>

            <mat-form-field right class="without-hint-spacing">
                <input
                    id="suffix-input-field"
                    matInput
                    [disabled]="!userHasAdminRights"
                    placeholder="Kostenstelle"
                    [(ngModel)]="datevConfig.defaultCostCenter" />
            </mat-form-field>
        </preferences-row>
        <preferences-row iconName="textsms" [noHorizontalPadding]="true">
            <span>Belegtext</span>
            <div class="label light">
                Der Belegtext (auch: Buchungssatz) enthält wichtige Informationen zu der Buchung die dir helfen, die
                Buchung schneller zu verstehen (maximal 30 Zeichen werden angezeigt). Du kannst Platzhalter verwenden
                (z. B. &#123;Anspruchsteller.Kennzeichen&#125;)
            </div>

            <placeholder-autocomplete-input
                id="booking-text-input"
                right
                [disabled]="!userHasAdminRights"
                [(text)]="datevConfig.bookingTextPattern"></placeholder-autocomplete-input>
        </preferences-row>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="triggerExport()">Exportieren</button>
    <button
        class="secondary"
        (click)="closeDialog()"
        matTooltip="Änderungen werden gespeichert, aber der Export nicht gestartet.">
        Schließen
    </button>
</mat-dialog-actions>

import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { MongoQuery } from '@autoixpert/lib/database-query/mongo-query.type';
import { toIsoDate, todayIso } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { invoiceSanityChecksForDatevExportErrors } from '@autoixpert/lib/datev/execute-invoice-sanity-checks-for-datev-export';
import { getPaymentStatus } from '@autoixpert/lib/invoices/get-payment-status';
import { getQueryForInvoicesByPaymentDate } from '@autoixpert/lib/invoices/get-query-for-invoices-by-payment-date';
import { getUnpaidAmount } from '@autoixpert/lib/invoices/get-unpaid-amount';
import { round } from '@autoixpert/lib/numbers/round';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { InvoiceAnalytics } from '@autoixpert/models/analytics/invoice-analytics';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Payment } from '@autoixpert/models/invoices/payment';
import { PaymentStatus } from '@autoixpert/models/invoices/payment-status';
import { Label } from '@autoixpert/models/labels/label';
import { LabelConfig } from '@autoixpert/models/labels/label-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { DatevInvoiceExportService } from 'src/app/shared/services/invoices/datev-invoice-export.service';
import { FeathersQuery } from 'src/app/shared/types/feathers-query';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../shared/animations/run-child-animations.animation';
import { slideOutSide } from '../../shared/animations/slide-out-side.animation';
import {
    VideoPlayerDialogComponent,
    VideoPlayerDialogData,
} from '../../shared/components/video-player-dialog/video-player-dialog.component';
import { getDocumentsApiErrorHandlers } from '../../shared/libraries/error-handlers/get-documents-api-error-handlers';
import { getInvoiceApiErrorHandlers } from '../../shared/libraries/error-handlers/get-invoice-api-error-handlers';
import { confirmInvoiceDeletion } from '../../shared/libraries/invoices/confirm-invoice-deletion';
import { isSmallScreen } from '../../shared/libraries/is-small-screen';
import { openInNewTabOnMiddleClick } from '../../shared/libraries/open-in-new-tab-on-middle-click';
import { trackById } from '../../shared/libraries/track-by-id';
import { hasAccessRight } from '../../shared/libraries/user/has-access-right';
import { InvoiceAnalyticsService } from '../../shared/services/analytics/invoice-analytics.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { DownloadService } from '../../shared/services/download.service';
import { InvoiceCancellationService } from '../../shared/services/invoice-cancellation.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LabelConfigService } from '../../shared/services/label-config.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { TeamService } from '../../shared/services/team.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserService } from '../../shared/services/user.service';
import {
    DatevInvoicesExportDialogComponent,
    DatevInvoicesExportDialogData,
    DatevInvoicesExportDialogReturnValue,
} from '../datev-invoices-export-dialog/datev-invoices-export-dialog.component';

@Component({
    selector: 'invoice-list',
    templateUrl: 'invoice-list.component.html',
    styleUrls: ['invoice-list.component.scss'],
    animations: [fadeInAndOutAnimation(), runChildAnimations(), slideOutSide()],
})
export class InvoiceListComponent implements OnInit, OnDestroy {
    constructor(
        private invoiceService: InvoiceService,
        private invoiceCancellationService: InvoiceCancellationService,
        private invoiceAnalyticsService: InvoiceAnalyticsService,
        private screenTitleService: ScreenTitleService,
        private router: Router,
        private route: ActivatedRoute,
        public userPreferences: UserPreferencesService,
        private teamService: TeamService,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private downloadService: DownloadService,
        private httpClient: HttpClient,
        private apiErrorService: ApiErrorService,
        private tutorialStateService: TutorialStateService,
        private dialog: MatDialog,
        private networkStatusService: NetworkStatusService,
        private labelConfigService: LabelConfigService,
        private datevInvoiceExportService: DatevInvoiceExportService,
        private userService: UserService,
    ) {}

    public invoices: Invoice[] = [];
    public filteredInvoices: Invoice[] = [];
    public selectedInvoice: Invoice = null;

    public user: User;
    public team: Team;

    // Invoice totals graph
    public barHeightPaidInvoices: number;
    public barHeightDueInvoices: number;
    public barHeightOverdueInvoices: number;

    public invoiceAnalytics: InvoiceAnalytics = new InvoiceAnalytics();
    public invoiceAnalyticsPending: boolean = false;

    public invoiceSearchMobileVisible: boolean = false;

    // Search
    public searchTerm: string;
    private searchTerm$: Subject<string> = new Subject<string>();
    public atlasSearchMatches = new Set<Invoice['_id']>();

    // Filter options
    public filterInvoicesFrom: IsoDate;
    public filterInvoicesTo: IsoDate;
    public dateFilterType: 'invoiceDate' | 'paymentDate' = 'invoiceDate';
    public filterOfficeLocations: OfficeLocation['_id'][] = [];
    protected labelConfigs: LabelConfig[] = [];

    // Pagination
    public allInvoicesLoadedWithCurrentFilters: boolean = false;
    private lastInvoicePaginationTokenFromServer: string = null;
    private numberOfLoadedInvoices: number = 0;
    loadMoreInvoices$$ = new Subject<LoadMoreInvoicesPayload>();
    public isLoadMoreInvoicesPending = false;

    // Payment Dialog
    public invoiceForPaymentDialog: Invoice;
    public initialPaymentTypeForPaymentDialog: Payment['type'];
    public paymentsDialogShown: boolean;

    // Payment Reminder Dialog
    public invoiceForPaymentReminderDialog: Invoice;
    public paymentReminderDialogShown: boolean;

    // Bank Account Sync
    public gocardlessBankAccountListShown: boolean;
    public bankTransactionListShown: boolean;

    // DATEV export
    public datevInvoiceExportInProgress: boolean = false;
    private numberOfDownloadedInvoicePdfs: number = 0; // This is used for the payments export as well.
    private numberOfDatevInvoices: number = 0;

    // GTÜ Import
    protected gtueInvoiceImportDialogShown: boolean = false;
    protected gtueImportAccessRightLabel = translateAccessRightToGerman('seeAllInvoices');

    // Create collective invoice dialog
    protected createCollectiveInvoiceDialogShown: boolean = false;

    // Invoice Cancellation Dialog
    public invoiceForPartialCancellationDialog: Invoice;

    private subscriptions: Subscription[] = [];

    public manuallyShownNotesIcons: Map<Invoice, boolean> = new Map();

    // Cache todays date as Iso-Date to prevent instantiation of many moment() for date comparison
    private todayAsIsoDate: IsoDate = null;

    //Infinite Scrolling
    public screenHeight: number;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.todayAsIsoDate = todayIso();
        this.loadTeam();

        this.setupGtueInvoiceImport();

        this.screenHeight = window.screen.availHeight;

        this.readInvoiceListDateRangeLocally();
        this.readOfficeLocationsLocally();

        // Pagination
        this.subscribeToLoadMoreInvoices();
        this.initialLoadInvoices();

        this.updateInvoiceAnalytics();

        this.setUpSearchTermSubject();

        this.loadLabelConfigs();

        this.registerWebsocketEventHandlers();

        this.screenTitleService.setScreenTitle({ screenTitle: 'Rechnungen' });

        this.tutorialStateService.markUserTutorialStepComplete('invoiceListOpened');
    }

    private loadTeam(): void {
        // Get the currently logged-in user from cache.
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(
            this.loggedInUserService.getTeam$().subscribe((team) => {
                this.team = team;
            }),
        );
    }

    /**
     * Read the query parameters. They might contain a flag that requests to immediately
     * open the GTÜ invoice import dialog.
     */
    private setupGtueInvoiceImport() {
        this.gtueInvoiceImportDialogShown = this.route.snapshot.queryParamMap.get('showGtueImportDialog') === 'true';
        if (this.gtueInvoiceImportDialogShown) {
            // Remove the query parameter, so that a page reload does not open the dialog again
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { showGtueImportDialog: null },
                queryParamsHandling: 'merge',
            });
        }
    }

    protected openCreateCollectiveInvoiceDialog(): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Um alle Gutachten für die Sammelrechnung laden zu können, musst du mit dem Internet verbunden sein.',
            );
            return;
        }

        this.createCollectiveInvoiceDialogShown = true;
    }

    protected hideCreateCollectiveInvoiceDialog(): void {
        this.createCollectiveInvoiceDialogShown = false;
    }

    /**
     * Every time the search term changes, trigger a search.
     *
     * Subscribe to the stream of search terms and trigger a search on the server.
     * Server side searches are only performed 300ms after the user stopped typing, and if the
     * search term is three letters or longer.
     *
     * Client side search is triggered always.
     */
    private setUpSearchTermSubject() {
        const subscription = this.searchTerm$
            .pipe(
                tap((searchTerm) => {
                    if (!searchTerm) {
                        this.getFirstPageOfInvoices();
                        return;
                    }
                }),
                map((searchTerm) => searchTerm.trim()),
                debounceTime(300),
                filter((searchTerm) => {
                    if (!searchTerm || typeof searchTerm !== 'string') return;

                    // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
                    const searchTermParts = searchTerm
                        .trim()
                        .split(' ')
                        .filter((searchTerm) => !!searchTerm.trim());
                    return searchTermParts.some((searchTermPart) => searchTermPart.length >= 3);
                }),
            )
            .subscribe(() => {
                this.atlasSearchMatches.clear();
                return this.loadMoreInvoices$$.next({
                    searchTerm: this.formatSearchTerm(),
                    filterAndSortParams: this.getFilterAndSortQuery(),
                });
            });

        this.subscriptions.push(subscription);
    }

    private formatSearchTerm() {
        if (!this.searchTerm) return undefined;

        // In case someone searches for numbers, replace the German comma as decimal point delimiter with a period.
        return (
            this.searchTerm
                /**
                 * Remove periods as thousands delimiter, but only in numbers, not in
                 * - dates
                 * - possible user-generated invoice number patterns with periods.
                 *
                 * Replace period in:
                 * 101.000,45
                 * 5.432,24
                 *
                 * Don't replace in:
                 * 01.02.2023
                 * RE11.035
                 * 5432 (no period)
                 * 5432,24 (no period)
                 * 101.100 (could be an invoice number)
                 */
                .replace(/\b(\d{1,3})\.(\d{3}[,])\b/, '$1$2')
                // replace comma with period
                .replace(/(\d+),(\d+)/, '$1.$2')
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Token of Connected Report
    //****************************************************************************/

    public toggleShowReportToken(): void {
        this.userPreferences.displayInvoiceWithReportToken = !this.userPreferences.displayInvoiceWithReportToken;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Token of Connected Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter + Sort
    //****************************************************************************/
    public filterAndSortInvoices(): void {
        this.filterInvoices();

        this.sortInvoices(this.userPreferences.sortInvoiceListBy);
    }

    private sortInvoices(sortStrategy: string): void {
        const sortDirection = this.userPreferences.sortInvoiceListDescending ? -1 : 1;

        switch (sortStrategy) {
            case 'paymentStatus':
                // Sort due to the top, paid to the bottom
                this.filteredInvoices.sort((invoiceA, invoiceB) => {
                    const paymentStatusA = this.translateInvoiceStatusToNumber(
                        getPaymentStatus(invoiceA, this.todayAsIsoDate),
                    );
                    const paymentStatusB = this.translateInvoiceStatusToNumber(
                        getPaymentStatus(invoiceB, this.todayAsIsoDate),
                    );

                    /**
                     * Sort order (descending)
                     * 1) Draft
                     * 2) Due
                     * 3) Overdue
                     * 4) Partially Paid
                     * 5) Paid
                     * 6) Shortened
                     */

                    // If equal, sort by date. Younger invoices come first.
                    if (paymentStatusA === paymentStatusB) {
                        return moment(invoiceA.date).isBefore(invoiceB.date) ? 1 : -1;
                    }
                    // Payment status must be different now
                    return (paymentStatusA < paymentStatusB ? -1 : 1) * sortDirection;
                });
                break;

            case 'date':
                this.filteredInvoices.sort((invoiceA, invoiceB) => {
                    const dateA: Moment = moment(invoiceA.date).startOf('day'); // Setting to start of day ignores hours and minutes. We only want to compare the date.
                    const dateB: Moment = moment(invoiceB.date).startOf('day');
                    const numberA = invoiceA.number || '';
                    const numberB = invoiceB.number || '';

                    // The same date may have a different notation. Ex: 2018-01-01T22:00:00.000+02:00 and 2018-01-02T00:00:00.000Z
                    if (dateA.isSame(dateB)) {
                        return numberA.localeCompare(numberB) * sortDirection;
                    }
                    // Date A is before Date B
                    if (dateA.isBefore(dateB)) return -sortDirection;
                    // Date A is after Date B
                    return sortDirection;
                });
                break;

            case 'total':
                this.filteredInvoices.sort((invoiceA, invoiceB) => {
                    const totalA: number = invoiceA.totalNet;
                    const totalB: number = invoiceB.totalNet;

                    if (totalA > totalB) return sortDirection;
                    if (totalA < totalB) return -sortDirection;
                    return 0;
                });
                break;

            case 'lastName':
                this.filteredInvoices.sort((invoiceA, invoiceB) => {
                    const lastNameA: string = invoiceA.recipient.contactPerson.lastName || '';
                    const lastNameB: string = invoiceB.recipient.contactPerson.lastName || '';
                    const firstNameA: string = invoiceA.recipient.contactPerson.firstName || '';
                    const firstNameB: string = invoiceB.recipient.contactPerson.firstName || '';

                    const comparisonResult = lastNameA.localeCompare(lastNameB);

                    if (comparisonResult === 0) {
                        return firstNameA.localeCompare(firstNameB) * sortDirection;
                    } else {
                        return comparisonResult * sortDirection;
                    }
                });
                break;

            case 'invoiceNumber':
                this.filteredInvoices.sort((invoiceA, invoiceB) => {
                    const invoiceNumberA: string = invoiceA.number || '';
                    const invoiceNumberB: string = invoiceB.number || '';

                    return invoiceNumberA.localeCompare(invoiceNumberB) * sortDirection;
                });
                break;

            case 'officeLocation':
                this.filteredInvoices.sort((invoiceA, invoiceB) => {
                    // If office location is the same, sort by invoice number
                    if (invoiceA.officeLocationId === invoiceB.officeLocationId) {
                        const invoiceNumberA: string = invoiceA.number || '';
                        const invoiceNumberB: string = invoiceB.number || '';
                        return invoiceNumberA.localeCompare(invoiceNumberB) * sortDirection;
                    }

                    // Sort by office location ID to be consistent with server-side sorting
                    return invoiceA._id.localeCompare(invoiceB._id) * sortDirection;
                });
                break;
            default:
                throw Error('SORTING_STRATEGY_NOT_ALLOWED');
        }
    }

    private translateInvoiceStatusToNumber(status: PaymentStatus): number {
        switch (status) {
            // No payment at all
            case 'due':
                return 1;
            case 'overdue':
                return 2;

            // Reminded
            case 'paymentReminderLevel0':
                return 50;
            case 'paymentReminderLevel1':
                return 51;
            case 'paymentReminderLevel2':
                return 52;
            case 'paymentReminderLevel3':
                return 53;

            // At least one payment
            case 'partiallyPaid':
                return 99;
            case 'paid':
                return 100;
            case 'overpaid':
                return 101;

            // Negative invoice
            case 'creditNote':
                return 500;

            // Canceled
            case 'fullCancellation':
                return 900;
            case 'fullyCanceled':
                return 901;
        }
    }

    private filterInvoices(): void {
        this.filteredInvoices = [...this.invoices];

        this.applyQuickFilter();
        this.applySearchFilter();
    }

    private applyQuickFilter(): void {
        if (!this.userPreferences.invoiceListQuickFilter || this.userPreferences.invoiceListQuickFilter === 'none') {
            return;
        }

        // Filter out cancellation and canceled invoices.
        // Except for filter 'imported', that one should show all invoices (canceled or not).
        if (this.userPreferences.invoiceListQuickFilter !== 'imported') {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
                const paymentStatus = getPaymentStatus(invoice, this.todayAsIsoDate);
                return paymentStatus !== 'fullCancellation' && paymentStatus !== 'fullyCanceled';
            });
        }

        if (this.userPreferences.invoiceListQuickFilter === 'onlyDue') {
            const today: string = todayIso();
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
                return invoice.hasOutstandingPayments && invoice.dueDate >= today;
            });
        }
        if (this.userPreferences.invoiceListQuickFilter === 'onlyPaid') {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
                return !invoice.hasOutstandingPayments;
            });
        }
        if (this.userPreferences.invoiceListQuickFilter === 'onlyOverdue') {
            const today: string = todayIso();
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
                return invoice.hasOutstandingPayments && invoice.dueDate < today;
            });
            return;
        }
        if (this.userPreferences.invoiceListQuickFilter === 'partial') {
            this.filteredInvoices = this.getAllInvoicesOfPaymentStatus(['partiallyPaid']);
        }
        if (this.userPreferences.invoiceListQuickFilter === 'unpaid') {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
                return invoice.hasOutstandingPayments;
            });
        }
        if (this.userPreferences.invoiceListQuickFilter === 'hasOpenPaymentReminder') {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => invoice.hasPaymentReminder);
        }

        //*****************************************************************************
        //  Short Payments
        //****************************************************************************/
        if (this.userPreferences.invoiceListQuickFilter === 'shortPaid') {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) =>
                invoice.payments.some((payment) => payment.type === 'shortPayment'),
            );
        }
        if (this.userPreferences.invoiceListQuickFilter === 'unsettledShortPayments') {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) =>
                invoice.payments.some(
                    (payment) => payment.type === 'shortPayment' && payment.shortPaymentStatus === 'outstandingClaim',
                ),
            );
        }
        if (this.userPreferences.invoiceListQuickFilter === 'settledShortPayments') {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) =>
                invoice.payments.some(
                    (payment) =>
                        payment.type === 'shortPayment' &&
                        (payment.shortPaymentStatus === 'paid' || payment.shortPaymentStatus === 'writtenOff'),
                ),
            );
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Short Payments
        /////////////////////////////////////////////////////////////////////////////*/
        if (this.userPreferences.invoiceListQuickFilter === 'toBeReminded') {
            const today: IsoDate = todayIso();
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
                if (!invoice.hasOutstandingPayments) return false;

                if (!invoice.nextPaymentReminderAt && invoice.dueDate <= today) {
                    return true;
                }

                // Due date of payment reminder is in the past.
                if (this.isPaymentReminderOverdue(invoice)) {
                    return true;
                }
            });
        }
        if (this.userPreferences.invoiceListQuickFilter === 'labels') {
            const activeLabelNames = this.userPreferences.invoiceList_labelsForFilter;

            this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
                return invoice.labels.some((label) => activeLabelNames.includes(label.name));
            });
        }

        if (this.userPreferences.invoiceListQuickFilter === 'imported') {
            this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
                return invoice.importedFromThirdPartySystem === 'gtue';
            });
        }
    }

    private applySearchFilter(): void {
        if (!this.searchTerm) {
            return;
        }

        const searchTerms = this.searchTerm.toLowerCase().split(' ');

        this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
            // Display all invoices which are matched from the atlas search
            if (this.atlasSearchMatches.has(invoice._id)) {
                return true;
            }

            const propertiesToBeSearched: (string | number)[] = [
                invoice.recipient.contactPerson.organization,
                invoice.recipient.contactPerson.lastName,
                invoice.recipient.contactPerson.firstName,
                invoice.number,
                invoice.lawyer?.contactPerson?.organization,
                invoice.lawyer?.contactPerson?.firstName,
                invoice.lawyer?.contactPerson?.lastName,
                invoice.insurance?.contactPerson?.organization,
                ...(invoice.reportsData?.map((data) => data.token) ?? []),
                ...(invoice.reportsData?.map((data) => data.licensePlate) ?? []),
                ...(invoice.reportsData?.map((data) => data.lawyerCaseNumber) ?? []),
                ...(invoice.reportsData?.map((data) => data.insuranceCaseNumber) ?? []),
                ...(invoice.reportsData?.map((data) => data.carMake) ?? []),
                ...(invoice.reportsData?.map((data) => data.carModel) ?? []),
                // ISO string
                invoice.date,
                // Format: 29. Dezember 2018
                moment(invoice.date).format('DD MMMM YYYY'),
                moment(invoice.date).format('DD.MM.YYYY'),
                invoice.totalGross,
            ];

            return searchTerms.every((searchTerm) => {
                return propertiesToBeSearched.some((propertyToBeSearched) => {
                    // strings
                    if (typeof propertyToBeSearched === 'string') {
                        return propertyToBeSearched && propertyToBeSearched.toLowerCase().includes(searchTerm);
                    }
                    // numbers
                    else {
                        // with decimal places
                        if (searchTerm.includes(',')) {
                            // replace comma with period to properly parse numbers in German format
                            return propertyToBeSearched === +searchTerm.replace(/\./g, '').replace(/,/, '.');
                        }
                        // integers
                        else {
                            // find all invoices whose total, rounded down to integers, equals the search term. (Search term 401 finds invoice.total of 401.50)
                            const searchTermInteger = parseInt(searchTerm);
                            return (
                                propertyToBeSearched >= searchTermInteger &&
                                propertyToBeSearched < searchTermInteger + 1
                            );
                        }
                    }
                });
            });
        });
    }

    public rememberDateRange(): void {
        store.set('invoiceListDateRangeFrom', this.filterInvoicesFrom);
        store.set('invoiceListDateRangeTo', this.filterInvoicesTo);
    }

    protected rememberOfficeLocations(): void {
        store.set('invoiceListOfficeLocations', JSON.stringify(this.filterOfficeLocations ?? []));
    }

    private readInvoiceListDateRangeLocally(): void {
        this.filterInvoicesFrom = store.get('invoiceListDateRangeFrom');
        this.filterInvoicesTo = store.get('invoiceListDateRangeTo');
    }

    private readOfficeLocationsLocally(): void {
        try {
            this.filterOfficeLocations = JSON.parse(store.get('invoiceListOfficeLocations') || '[]');
        } catch (error) {
            // Ignore error if the stored value is not a valid JSON string as this is an optional feature.
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter + Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Totals Header
    //****************************************************************************/

    public async updateInvoiceAnalytics() {
        // We decided to include the invoice summary analytics in the access right "reporting" because it _is_ a form of reporting.
        if (!this.user?.accessRights.reporting) {
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            return;
        }

        this.invoiceAnalyticsPending = true;

        try {
            const invoiceAnalytics = await this.getInvoiceAnalytics().toPromise();
            this.invoiceAnalytics = invoiceAnalytics;
            this.setInvoiceTotalGraph();
        } catch (error) {
            console.error('Error loading invoice Analytics', error);
        }
        this.invoiceAnalyticsPending = false;
    }

    /**
     * Calculate the height in % of each bar in the invoice totals graph
     */
    public setInvoiceTotalGraph() {
        const highestTotal = Math.max(this.totalPaidAmount, this.totalDueAmount, this.totalOverdueAmount);

        this.barHeightPaidInvoices = (this.totalPaidAmount / highestTotal) * 100;
        this.barHeightDueInvoices = (this.totalDueAmount / highestTotal) * 100;
        this.barHeightOverdueInvoices = (this.totalOverdueAmount / highestTotal) * 100;
    }

    /**
     * Includes:
     * - paid and overpaid amounts on paid invoices
     * - paid amounts on unpaid invoices (partial payments)
     */
    public get totalPaidAmount(): number {
        return this.invoiceAnalytics.paid.paidAmount;
    }

    /**
     * Unpaid total on due invoices.
     *
     * Paid and writtenOff short payments are subtracted from the total due amount.
     * Short payments that are not paid or written off are not subtracted, since we threat them as outstanding (e.g. payments dialog).
     */
    public get totalDueAmount(): number {
        return this.invoiceAnalytics.unpaidAndDue.dueAmount;
    }

    /**
     * Unpaid total on overdue invoices.
     *
     * Paid and writtenOff short payments are subtracted from the total due amount.
     * Short payments that are not paid or written off are not subtracted, since we threat them as outstanding (e.g. payments dialog).
     */
    public get totalOverdueAmount(): number {
        return this.invoiceAnalytics.unpaidAndOverdue.overdueAmount;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Totals Header
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Bank Account Sync
    //****************************************************************************/
    public showBankAccountList(): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Bankkonten sind einsehbar, sobald du wieder online bist.',
            );
            return;
        }

        this.gocardlessBankAccountListShown = true;
    }

    public hideBankAccountList(): void {
        this.gocardlessBankAccountListShown = false;
    }

    public showBankTransactionList(): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der Bankkontoabgleich ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.bankTransactionListShown = true;

        if (!this.user.userInterfaceStates.firstBankAccountSyncNoteClosed) {
            this.user.userInterfaceStates.firstBankAccountSyncNoteClosed = true;
            this.saveUser();
        }
    }

    public hideBankTransactionList(): void {
        this.bankTransactionListShown = false;
    }

    public handleBankAccountUpdate(numberOfBankAccounts: number): void {
        this.team.bankAccountSync.numberOfActiveBankAccounts = numberOfBankAccounts;
    }

    public showGocardlessBankAccountList() {
        this.gocardlessBankAccountListShown = true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bank Account Sync
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Video
    //****************************************************************************/
    public openVideo(heading: string, videoUrl: string) {
        this.dialog.open<VideoPlayerDialogComponent, VideoPlayerDialogData>(VideoPlayerDialogComponent, {
            data: {
                heading,
                videoUrl,
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Video
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Excel Export
    //****************************************************************************/
    public setIncludeReportDataInInvoiceAndPaymentsExcel(includeReportData: boolean) {
        this.userPreferences.invoiceExportWithReportData = includeReportData;
    }

    public exportInvoicesAndPaymentsExcel(): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der Rechnungsexport ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        let params = new HttpParams();
        params = params.set('includeReportCustomFields', this.userPreferences.invoiceExportWithReportData);

        if (this.filterInvoicesFrom) {
            params = params.set('date[$gte]', this.filterInvoicesFrom satisfies IsoDate);
        }
        if (this.filterInvoicesTo) {
            params = params.set('date[$lte]', this.filterInvoicesTo satisfies IsoDate);
        }

        // Only add office locations filter if at least one office location is selected (no results otherwise)
        if (this.filterOfficeLocations && this.filterOfficeLocations.length > 0) {
            this.filterOfficeLocations.forEach((officeLocationId) => {
                params = params.append('officeLocationIds[]', officeLocationId);
            });
        }

        this.httpClient
            .get(`/api/v0/exports/invoicesAndPayments`, {
                observe: 'response',
                responseType: 'blob',
                params,
            })
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    console.error('ERROR_EXPORTING_INVOICES', { error });
                    this.toastService.error(
                        'Rechnungsexport gescheitert',
                        'Bitte kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                    );
                },
            });
    }

    public exportPaymentsExcel(): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der Zahlungsexport ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        let params = new HttpParams().append('dateFilterType', this.dateFilterType);

        if (this.filterInvoicesFrom) {
            params = params.set('date[$gte]', this.filterInvoicesFrom satisfies IsoDate);
        }
        if (this.filterInvoicesTo) {
            params = params.set('date[$lte]', this.filterInvoicesTo satisfies IsoDate);
        }

        this.httpClient
            .get(`/api/v0/exports/payments`, {
                observe: 'response',
                responseType: 'blob',
                params,
            })
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    console.error('ERROR_EXPORTING_PAYMENTS', { error });
                    this.toastService.error(
                        'Zahlungsexport gescheitert',
                        'Bitte kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                    );
                },
            });
    }

    public exportOpenItemsExcel(): void {
        let params = new HttpParams().append('dateFilterType', this.dateFilterType);

        if (this.filterInvoicesFrom) {
            params = params.set('date[$gte]', this.filterInvoicesFrom satisfies IsoDate);
        }
        if (this.filterInvoicesTo) {
            params = params.set('date[$lte]', this.filterInvoicesTo satisfies IsoDate);
        }

        this.httpClient
            .get(`/api/v0/exports/openItems`, {
                observe: 'response',
                responseType: 'blob',
                params,
            })
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    console.error('ERROR_EXPORTING_OPEN_ITEMS', error);
                    this.toastService.error(
                        'Export der Offene-Posten-Liste gescheitert',
                        'Bitte kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                    );
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Excel Export
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DATEV Invoice Export
    //****************************************************************************/
    public async exportInvoicesDatev(): Promise<void> {
        // Don't allow creating multiple DATEV exports at once. The user might doubleclick on the icon but exporting is compute-expensive for both the server and the client.
        if (this.datevInvoiceExportInProgress) {
            return;
        }

        // The DATEV export requires PDF invoice documents that can only be generated on the server.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Es werden die Rechnungs-PDFs benötigt, die nur auf dem Server generiert werden können. Du kannst den DATEV-Export nutzen, sobald du wieder online bist.',
            );
            return;
        }

        const datevSubscriptions = [
            this.datevInvoiceExportService.processedInvoicesCount$.subscribe((count) => {
                this.numberOfDownloadedInvoicePdfs = count;
            }),
            this.datevInvoiceExportService.totalInvoicesCount$.subscribe((count) => {
                this.numberOfDatevInvoices = count;
            }),
        ];
        this.datevInvoiceExportInProgress = true;

        // Date filter
        const { fromDate, toDate } = this.getDatevExportDateLimits();

        try {
            const { file, filename } = await this.datevInvoiceExportService.getInvoicesByInvoiceDateZip({
                fromDate,
                toDate,
                filterOfficeLocations: this.filterOfficeLocations,
                dateFilterType: this.team.datev.exportByInvoiceOrPaymentDate,
            });
            this.downloadService.downloadFile(file, filename);

            this.datevInvoiceExportInProgress = false;
        } catch (error) {
            console.error('ERROR_CREATING_DATEV_ARCHIVE', { error });

            this.datevInvoiceExportInProgress = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...invoiceSanityChecksForDatevExportErrors,
                    NO_PAYMENTS_FOR_THE_GIVEN_TIMEFRAME: {
                        title: 'Keine Zahlungen gefunden',
                        body: 'Für den ausgewählten Zeitraum konnten keine Zahlungen gefunden werden. Bitte ändere den ausgewählten Zeitraum oder trage Zahlungen über die Rechnungsliste ein.',
                    },
                    REPORT_NOT_FOUND: {
                        title: 'Gutachten nicht gefunden',
                        body: 'Das Gutachten zu dieser Rechnung konnte nicht gefunden werden.',
                    },
                    GETTING_INVOICE_PDF_FAILED: (error) => ({
                        title: `PDF der Rechnung Nr. "${error.data?.invoice?.number}" nicht abrufbar`,
                        body: `Die PDF konnte nicht erzeugt werden.<br><br>Klicke, um die Rechnung zu öffnen.`,
                        toastClick: (error) => {
                            window.open(`Rechnungen/${error.data?.invoice?._id}`);
                        },
                    }),
                    GETTING_LINKED_REPORT_FAILED: (error) => ({
                        title: 'Verknüpftes Gutachten',
                        body: `Es liegt ein Problem mit dem Gutachten zu der Rechnung Nr. "${error.data?.invoice?.number}" vor.<br><br>Klicke, um die Rechnung zu öffnen.`,
                        forceDisplayAsTopHandler: true,
                        toastClick: (error) => {
                            window.open(`Rechnungen/${error.data?.invoiceId}`);
                        },
                    }),
                },
                defaultHandler: {
                    title:
                        this.team.datev.exportByInvoiceOrPaymentDate === 'invoiceDate'
                            ? 'DATEV-Export gescheitert'
                            : 'DATEV-Export für Zahlungen gescheitert',
                    body: 'Bitte versuche es erneut. Tritt der Fehler weiterhin auf, kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                },
            });
        } finally {
            datevSubscriptions.forEach((subscription) => subscription.unsubscribe());
        }
    }

    public async openDatevExportSettingsDialog(): Promise<void> {
        const { datevConfig, startExport } = await this.dialog
            .open<
                DatevInvoicesExportDialogComponent,
                DatevInvoicesExportDialogData,
                DatevInvoicesExportDialogReturnValue
            >(DatevInvoicesExportDialogComponent, {
                width: '1000px',
                maxWidth: '95vw',
                data: {
                    datevConfig: JSON.parse(JSON.stringify(this.team.datev)),
                },
            })
            .afterClosed()
            .toPromise();

        // If configuration changed, save that back to the server.
        if (!isEqual(this.team.datev, datevConfig)) {
            Object.assign(this.team.datev, datevConfig);

            try {
                await this.teamService.put(this.team, { waitForServer: true });
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Team nicht gespeichert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }
        }

        if (startExport) {
            this.exportInvoicesDatev();
        }
    }

    public getDatevInvoiceExportProgressLabel(): string {
        if (this.numberOfDatevInvoices === 0) {
            return `Rechnungen ermitteln...`;
        } else if (this.numberOfDownloadedInvoicePdfs < this.numberOfDatevInvoices) {
            return `Rechnung ${this.numberOfDownloadedInvoicePdfs + 1} von ${this.numberOfDatevInvoices}...`;
        } else if (this.numberOfDownloadedInvoicePdfs === this.numberOfDatevInvoices) {
            return `Erstelle ZIP...`;
        }
    }

    public getDatevExportDateLimits(): { fromDate: IsoDate; toDate: IsoDate } {
        let fromDate: string;
        let toDate: string;

        // If a from date is set, use that to export invoices.
        if (this.filterInvoicesFrom) {
            fromDate = this.filterInvoicesFrom;
        }
        if (this.filterInvoicesTo) {
            toDate = this.filterInvoicesTo;
        }

        // If no date range is specified, take the last month by default. That's the usual export an assessor would use.
        if (!fromDate && !toDate) {
            fromDate = moment().subtract(1, 'month').startOf('month').format();
            toDate = moment().subtract(1, 'month').endOf('month').format();
        }

        return {
            fromDate: toIsoDate(fromDate),
            toDate: toIsoDate(toDate),
        };
    }
    //*****************************************************************************
    //  END DATEV Invoice Export
    //****************************************************************************/

    //*****************************************************************************
    //  GTÜ Invoice Import
    //****************************************************************************/

    protected importGtueInvoices(): void {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der Rechnungsimport ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.gtueInvoiceImportDialogShown = true;
    }

    protected hideGtueInvoiceImportDialog(): void {
        this.gtueInvoiceImportDialogShown = false;
    }

    protected gtueImportWasSuccessful(): void {
        // Reload invoices, so we see the recently imported ones
        this.getFirstPageOfInvoices();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END GTÜ Invoice Import
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Getters for Invoice properties
    //****************************************************************************/

    private getAllInvoicesOfPaymentStatus(paymentStatuses: PaymentStatus[]): Invoice[] {
        return this.filteredInvoices.filter((invoice) => {
            // TODO We should separate payment status from reminder status. Currently, when selecting partially paid invoices, all partially paid invoices with a payment reminder are filtered out here.
            const calculatedPaymentStatus = getPaymentStatus(invoice, this.todayAsIsoDate);
            return paymentStatuses.includes(calculatedPaymentStatus);
        });
    }

    public getUnpaidAmount(invoice: Invoice): number {
        return getUnpaidAmount(invoice);
    }

    /**
     * If the invoice list is filtered by payment date, we display partial payments.
     * @param invoice
     */
    public getPaidAmountWithinDateFilter(invoice: Invoice): number {
        let relevantPayments: Payment[] = invoice.payments;

        // Filter by fromDate
        if (this.filterInvoicesFrom) {
            const dateFromMoment = moment(this.filterInvoicesFrom);
            relevantPayments = relevantPayments.filter((payment) => {
                if (!payment.date) return;

                return moment(payment.date).isAfter(dateFromMoment, 'days');
            });
        }

        // Filter by toDate
        if (this.filterInvoicesTo) {
            const dateToMoment = moment(this.filterInvoicesTo);
            relevantPayments = relevantPayments.filter((payment) => {
                if (!payment.date) return;

                return moment(payment.date).isBefore(dateToMoment, 'days');
            });
        }

        const paidAmount = relevantPayments.reduce((sum, payment) => sum + payment.amount, 0);
        return round(paidAmount);
    }

    public getVat(invoice: Invoice): number {
        const vat = invoice.totalGross - invoice.totalNet;
        return round(vat);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Getters for Invoice properties
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Getters for Report Properties
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Getters for Report Properties
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  View Handlers
    //****************************************************************************/

    protected updateInvoiceLocally(invoice: Invoice): void {
        const index = this.invoices.findIndex((i) => i._id === invoice._id);
        if (index === -1) {
            return;
        }

        this.invoices[index] = invoice;
        this.filterAndSortInvoices();
    }

    public selectDateRange(range: 'week' | 'lastThirtyDays' | 'month' | 'quarter' | 'year' | 'all'): void {
        switch (range) {
            case 'week':
            case 'month':
            case 'quarter':
            case 'year':
                this.filterInvoicesFrom = toIsoDate(moment().startOf(range).format());
                this.filterInvoicesTo = toIsoDate(moment().endOf(range).format());
                break;
            case 'lastThirtyDays':
                this.filterInvoicesFrom = toIsoDate(moment().subtract(30, 'days').startOf('day').format());
                this.filterInvoicesTo = toIsoDate(moment().endOf('day').format());
                break;
            case 'all':
                this.filterInvoicesFrom = null;
                this.filterInvoicesTo = null;
                break;
            default:
                throw Error('INVALID_DATE_RANGE_FOR_INVOICE_FILTER: ' + range);
        }

        this.getFirstPageOfInvoices();
        this.updateInvoiceAnalytics();
        this.rememberDateRange();
    }

    public selectSortStrategy(
        sortStrategy: 'date' | 'lastName' | 'total' | 'paymentStatus' | 'invoiceNumber' | 'officeLocation',
    ) {
        this.userPreferences.sortInvoiceListBy = sortStrategy;

        if (!this.allInvoicesLoadedWithCurrentFilters) {
            this.getFirstPageOfInvoices();
        }

        this.sortInvoices(sortStrategy);
    }

    public selectQuickFilter(quickFilter: UserPreferences['invoiceListQuickFilter']) {
        this.userPreferences.invoiceListQuickFilter = quickFilter;

        // Clear the selected labels when the label filter gets deselected
        if (quickFilter !== 'labels') {
            this.userPreferences.invoiceList_labelsForFilter = [];
        }

        this.getFirstPageOfInvoices();

        this.filterAndSortInvoices();
    }

    public openPaymentsDialog(invoice: Invoice, initialPaymentType?: Payment['type']): void {
        // Don't open payments for cancellation invoices
        if (invoice.totalGross < 0) return;

        this.invoiceForPaymentDialog = invoice;

        // This may be undefined. The payment dialog then uses its default type.
        this.initialPaymentTypeForPaymentDialog = initialPaymentType;

        this.paymentsDialogShown = true;
    }

    public hidePaymentsDialog(): void {
        this.paymentsDialogShown = false;
        this.invoiceForPaymentDialog = null;
    }

    //*****************************************************************************
    //  Payment Reminders
    //****************************************************************************/
    public openPaymentReminderDialog(invoice: Invoice): void {
        this.invoiceForPaymentReminderDialog = invoice;
        this.paymentReminderDialogShown = true;
    }

    public hidePaymentReminderDialog(): void {
        this.paymentReminderDialogShown = false;
        this.invoiceForPaymentReminderDialog = null;
    }

    /**
     * Returns true for both unpaid and partially paid invoices. Partially paid invoices don't trigger the
     * payment status 'overdue'.
     * @param invoice
     */
    public isInvoiceOverdue(invoice: Invoice): boolean {
        if (!invoice.dueDate) return;

        const paymentStatus = getPaymentStatus(invoice, this.todayAsIsoDate);
        return (
            paymentStatus === 'overdue' ||
            (paymentStatus === 'partiallyPaid' && moment(invoice.dueDate).isSameOrBefore(undefined, 'days'))
        );
    }

    /**
     * Is the youngest payment reminder overdue?
     *
     * This method only returns true if the invoice is unpaid.
     *
     * @param invoice
     */
    public isPaymentReminderOverdue(invoice: Invoice): boolean {
        if (!invoice.hasOutstandingPayments) return false;
        if (!invoice.nextPaymentReminderAt) return undefined;

        // String comparison is more performant than date-comparison with instantiation of two moment objects.
        return invoice.nextPaymentReminderAt <= this.todayAsIsoDate;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payment Reminders
    /////////////////////////////////////////////////////////////////////////////*/

    public updateSearchTerm() {
        this.searchTerm$.next(this.searchTerm);
    }

    public toggleSortDirection(): void {
        this.userPreferences.sortInvoiceListDescending = !this.userPreferences.sortInvoiceListDescending;

        if (!this.allInvoicesLoadedWithCurrentFilters) {
            this.getFirstPageOfInvoices();
        }

        this.filterAndSortInvoices();
    }

    public selectInvoice(invoice: Invoice): void {
        this.selectedInvoice = invoice;
    }

    public async saveInvoice(invoice: Invoice): Promise<void> {
        try {
            await this.invoiceService.put(invoice);
        } catch (error) {
            this.toastService.error('Rechnung konnte nicht gespeichert werden');
            console.error('INVOICE_COULD_NOT_BE_SAVED', { error });
        }
    }

    public isDeletingInvoiceAllowed(invoice: Invoice): boolean {
        // If this invoice is not yet booked/locked, every team member may delete it.
        return this.invoiceService.isDeletingInvoiceAllowed(invoice, this.user, this.team);
    }

    public async deleteInvoice(invoice: Invoice): Promise<void> {
        // Locked invoices may only be deleted if the current user is a team admin.
        if (!this.isDeletingInvoiceAllowed(invoice)) {
            this.toastService.error(
                'Gebuchte Rechnung',
                'Eine bereits gebuchte Rechnung kann nur vom Administrator deines Teams gelöscht werden.',
            );
            return;
        }

        const invoiceShouldBeDeleted: boolean = await confirmInvoiceDeletion({ dialogService: this.dialog });
        if (!invoiceShouldBeDeleted) {
            return;
        }

        const index = this.invoices.indexOf(invoice);
        try {
            this.invoices.splice(index, 1);
            await this.deleteInvoiceFromServer(invoice);
        } catch (error) {
            console.error(error);
            // Add invoice back to local invoice array.
            this.invoices.splice(index, 0, invoice);
            this.filterAndSortInvoices();
            return;
        }
        this.filterAndSortInvoices();
        this.updateInvoiceAnalytics();

        /**
         * Restore invoice on click.
         */
        const infoToast = this.toastService.info(
            `Rechnung wurde gelöscht`,
            `Klicke, um die Rechnung wiederherzustellen.`,
            { showProgressBar: true, timeOut: 10000 },
        ); //RestorationToast
        this.subscriptions.push(
            infoToast.click.subscribe(async () => {
                this.invoices.splice(index, 0, invoice);
                try {
                    await this.invoiceService.undelete(invoice);
                    this.toastService.success('Rechnung wiederhergestellt.');
                    this.filterAndSortInvoices();
                } catch (error) {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: `Rechnung nicht erstellt`,
                            body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                        },
                    });
                }
            }),
        );
    }

    public downloadInvoicePdf(invoice: Invoice): void {
        this.toastService.info('Rechnung wird heruntergeladen');

        const invoiceDocument: DocumentMetadata = invoice.documents.find(
            (documentMetadata) => documentMetadata.type === 'invoice',
        );
        let downloadPath: string = `/api/v0/invoices/${invoice._id}/documents/invoice?format=pdf`;
        if (invoiceDocument.uploadedDocumentId) {
            downloadPath = `/api/v0/invoices/${invoice._id}/documents/userUploads/${invoiceDocument.uploadedDocumentId}`;
        }

        this.httpClient
            .get(downloadPath, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                },
                error: (error) => {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ...getDocumentsApiErrorHandlers(this.router),
                        },
                        defaultHandler: {
                            title: 'Rechnung konnte nicht heruntergeladen werden',
                            body: "Bitte generiere sie noch einmal oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    public navigateToInvoice(invoiceId: string): void {
        this.router.navigate([invoiceId], {
            relativeTo: this.route,
        });
    }

    public openInvoiceInNewTabOnMiddleClick(invoice: Invoice, event: MouseEvent) {
        openInNewTabOnMiddleClick(event, `/Rechnungen/${invoice._id}`);
    }

    public openInvoiceInNewTab(invoice: Invoice, event: MouseEvent, matMenuTrigger: MatMenuTrigger) {
        window.open(`/Rechnungen/${invoice._id}`);
        // Prevent the click from selecting the autocomplete entry
        event.stopPropagation();

        // Since event propagation stopped, the click won't close the menu automatically.
        matMenuTrigger.closeMenu();
    }

    /**
     * If the middle mouse click on the license plate is not stopped from bubbling, it will trigger both
     * the handler on the license plate and the handler on the table row.
     * @param event
     */
    public stopMiddleMouseClickFromBubbling(event: MouseEvent) {
        if (event.button === 1) {
            event.stopPropagation();
        }
    }

    public lockInvoice(invoice: Invoice): void {
        invoice.lockedAt = moment().format();
        invoice.lockedBy = this.user._id;
        this.saveInvoice(invoice);
    }

    //*****************************************************************************
    //  Invoice Cancellation
    //****************************************************************************/
    public async cancelInvoice(invoice: Invoice): Promise<void> {
        let cancellationInvoice: Invoice;
        try {
            cancellationInvoice = (
                await this.invoiceCancellationService.createFullCancellationInvoice({
                    rootInvoice: invoice,
                })
            )?.cancellationInvoice;
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getInvoiceApiErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Rechnung konnte nicht storniert werden',
                    body: 'Bitte versuche es noch einmal. Falls es nicht funktioniert, kontaktiere die Hotline.',
                },
            });
        }

        this.navigateToInvoice(cancellationInvoice._id);
    }

    public showInvoiceCancellationDialog(invoice: Invoice) {
        this.invoiceForPartialCancellationDialog = invoice;
    }

    public hideInvoiceCancellationDialog() {
        this.invoiceForPartialCancellationDialog = null;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Cancellation
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Reset paging of data records.
     */
    public resetInvoicesPagingHistory(): void {
        this.invoices = [];
        this.allInvoicesLoadedWithCurrentFilters;

        this.lastInvoicePaginationTokenFromServer = undefined;
        this.numberOfLoadedInvoices = 0;

        this.atlasSearchMatches.clear();
    }

    public getFirstPageOfInvoices(): void {
        this.resetInvoicesPagingHistory();
        this.initialLoadInvoices();
    }
    public async createNewInvoice(): Promise<void> {
        let newInvoice: Invoice;
        try {
            newInvoice = this.invoiceService.generateNewInvoice();
            await this.invoiceService.create(newInvoice);
        } catch (error) {
            console.error('COULD_NOT_ADD_NEW_INVOICE', { error });
            this.toastService.error(
                'Rechnung nicht erstellt',
                'Bitte versuche es erneut oder kontaktiere die aX Hotline.',
            );
            return;
        }
        this.navigateToInvoice(newInvoice._id);
        this.tutorialStateService.markUserTutorialStepComplete('customInvoiceCreated');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Short Payments
    //****************************************************************************/
    public doesInvoiceHaveShortPay(invoice: Invoice): boolean {
        return invoice.payments.some((payment) => payment.type === 'shortPayment');
    }

    public getInvoiceUnpaidShortPaymentSumGross(invoice: Invoice): number {
        return invoice.payments
            .filter((payment) => payment.type === 'shortPayment')
            .reduce((total, payment) => total + payment.amount, 0);
    }

    public areAllShortPaymentsSettled(invoice: Invoice): boolean {
        return invoice.payments
            .filter((payment) => payment.type === 'shortPayment')
            .every(
                (shortPayment) =>
                    shortPayment.shortPaymentStatus === 'paid' || shortPayment.shortPaymentStatus === 'writtenOff',
            );
    }

    public isAdmin() {
        return isAdmin(this.user._id, this.team);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Short Payments
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Info Notes
    //****************************************************************************/
    public isConnectBankAccountNoteVisible(): boolean {
        // Don't show info note for starting users to not overwhelm them.
        if (!this.invoices?.length) return;

        const hasBankAccounts: boolean = this.hasBankAccounts();
        return !hasBankAccounts && !this.user?.userInterfaceStates.bankAccountSyncAvailabilityNoteClosed;
    }

    public isFirstSyncInfoNoteVisible(): boolean {
        // Don't show info note for starting users to not overwhelm them.
        if (!this.invoices?.length) return;

        const hasBankAccounts: boolean = this.hasBankAccounts();

        return (
            hasBankAccounts &&
            !this.user?.userInterfaceStates.firstBankAccountSyncNoteClosed &&
            hasAccessRight(this.user, 'bankAccountSync')
        );
    }

    private hasBankAccounts(): boolean {
        return !!this.team?.bankAccountSync.numberOfActiveBankAccounts;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Info Notes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Quick Filter - Labels
    //****************************************************************************/
    protected async loadLabelConfigs() {
        try {
            this.labelConfigs = await this.labelConfigService.find({ labelGroup: 'invoice' }).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Label-Konfigs konnten nicht geladen werden`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        this.labelConfigs.sort(sortByProperty(['dragOrderPosition', 'name']));
    }

    protected getLabelConfigByLabelName(labelName: Label['name']): LabelConfig {
        return this.labelConfigs.find((labelConfig) => labelConfig.name === labelName);
    }

    protected toggleSelectLabelConfigForFilter(labelName: Label['name']) {
        toggleValueInArray(labelName, this.userPreferences.invoiceList_labelsForFilter);

        // Assign the array to itself to trigger the save mechanism.
        this.userPreferences.invoiceList_labelsForFilter = [...this.userPreferences.invoiceList_labelsForFilter];

        // Select filter type and trigger search.
        if (this.userPreferences.invoiceList_labelsForFilter.length) {
            // Loading the search results anew must be performed on every label change.
            this.selectQuickFilter('labels');
        } else {
            this.selectQuickFilter('none');
        }
    }

    protected async selectedLabelsForInvoiceChanged(): Promise<void> {
        await this.saveInvoice(this.selectedInvoice);

        if (this.userPreferences.invoiceList_labelsForFilter.length) {
            // If there is an active filter for labels, it might happen that the user removed a label
            // that was the only reason an invoice showed up in the filter and needs to be removed after the
            // removal of the label.
            this.filterAndSortInvoices();

            const reportIsStillInSearchResults = this.selectedInvoice.labels.some((label) =>
                this.userPreferences.invoiceList_labelsForFilter.includes(label.name),
            );

            if (!reportIsStillInSearchResults) {
                // In case the removal of a label caused the selected invoice to not show up in the results
                // anymore, we also need to clear the selection (otherwise it will still be displayed in the details pane)
                this.selectedInvoice = null;
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Quick Filter - Labels
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    /**
     * Delete invoice.
     *
     * If the invoice cancels another invoice, revert that first.
     */
    private async deleteInvoiceFromServer(invoice: Invoice): Promise<void> {
        // Delete invoice and check for canceledInvoice references
        await this.invoiceService.safeDelete(invoice, this.invoices);

        this.updateInvoiceAnalytics();
    }

    public initialLoadInvoices() {
        this.loadMoreInvoices$$.next({
            filterAndSortParams: this.getFilterAndSortQuery(),
            numberOfItemsToLoad: 15,
        });
    }

    public triggerLoadMoreInvoices() {
        this.loadMoreInvoices$$.next({
            searchTerm: this.formatSearchTerm(),
            searchAfterNumberOfElements: this.numberOfLoadedInvoices,
            searchAfterPaginationToken: this.lastInvoicePaginationTokenFromServer,
            filterAndSortParams: this.getFilterAndSortQuery(),
        });
    }

    private subscribeToLoadMoreInvoices() {
        const loadMoreInvoicesSubscription = this.loadMoreInvoices$$
            .pipe(
                // Only trigger a load if the parameters have changed.
                // This is required since the scroll observer may trigger multiple times with the same parameters.
                // It is important to have the filters and sort params in the payload to cancel the request if the user changes the filter or sort.
                // Since distinctUntilChanged may hold a reference to objects or arrays, we need to deep copy the payload in order to compare the values.
                map((payload) => JSON.parse(JSON.stringify(payload))),
                distinctUntilChanged(isEqual),
                switchMap(async (payload) => {
                    const numberOfInvoicesToLoad = payload.numberOfItemsToLoad || 15;
                    const isInitialLoad = !this.numberOfLoadedInvoices;
                    let loadedInvoices: Invoice[] = [];
                    this.isLoadMoreInvoicesPending = true;

                    /**
                     * Load invoices from server (if online) or from IndexedDB (if offline).
                     */
                    try {
                        const { records, lastPaginationToken } =
                            await this.invoiceService.getInvoicesFromServerOrIndexedDB({
                                searchTerm: payload.searchTerm,
                                searchAfterPaginationToken: payload.searchAfterPaginationToken,
                                skip: payload.searchAfterNumberOfElements || 0,
                                limit: numberOfInvoicesToLoad,
                                query: payload.filterAndSortParams,
                            });
                        loadedInvoices = records;

                        // Update pagination of component
                        this.lastInvoicePaginationTokenFromServer = lastPaginationToken;
                        this.numberOfLoadedInvoices += loadedInvoices.length;
                    } catch (error) {
                        this.apiErrorService.handleAndRethrow({
                            axError: error,
                            handlers: {
                                INVALID_DATE_RANGE: {
                                    title: 'Ungültiger Datumsfilter',
                                    body: 'Stelle sicher, dass der Datumsfilter so eingestellt ist, dass das Enddatum nach dem Startdatum liegt.',
                                },
                            },
                            defaultHandler: {
                                title: `Rechnungen nicht geladen`,
                                body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                            },
                        });
                    }
                    this.isLoadMoreInvoicesPending = false;
                    return { loadedInvoices, isInitialLoad, numberOfInvoicesToLoad, searchTerm: payload.searchTerm };
                }),
            )
            .subscribe(({ loadedInvoices, isInitialLoad, numberOfInvoicesToLoad, searchTerm }) => {
                // If there are less reports than the limit, we have reached the end of the list.
                this.allInvoicesLoadedWithCurrentFilters = loadedInvoices.length < numberOfInvoicesToLoad;

                if (loadedInvoices.length === 0 && !searchTerm && !isInitialLoad) {
                    return;
                }

                // If the request was for a search term, add the report ids to the search matches.
                if (searchTerm) {
                    loadedInvoices.forEach((report) => {
                        this.atlasSearchMatches.add(report._id);
                    });
                }

                // If this is the first load, update the in-memory cache with the loaded reports.
                if (isInitialLoad) {
                    this.invoices = loadedInvoices;
                } else {
                    // Append all new reports to the list.
                    this.addFreshInvoicesToList(loadedInvoices);
                }

                this.filterAndSortInvoices();
            });

        this.subscriptions.push(loadMoreInvoicesSubscription);
    }

    /**
     * Get the query to load invoices from the server.
     */
    public getFilterAndSortQuery(): FeathersQuery {
        let queryParams: MongoQuery<Invoice> = {};

        //*****************************************************************************
        //  Limit time frame
        //****************************************************************************/
        // Filter invoices by their invoice date
        if (this.dateFilterType === 'invoiceDate') {
            if (this.filterInvoicesFrom || this.filterInvoicesTo) {
                queryParams.date = {};
            }

            if (this.filterInvoicesFrom) {
                queryParams.date.$gte = DateTime.fromISO(this.filterInvoicesFrom).toISODate();
            }
            if (this.filterInvoicesTo) {
                queryParams.date.$lte = DateTime.fromISO(this.filterInvoicesTo).toISODate();
            }
        } else if (this.dateFilterType === 'paymentDate') {
            const query = getQueryForInvoicesByPaymentDate({
                fromDate: this.filterInvoicesFrom,
                toDate: this.filterInvoicesTo,
            });
            queryParams = {
                ...queryParams,
                ...query,
            };
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Limit time frame
        /////////////////////////////////////////////////////////////////////////////*/

        // Filter by office locations
        if (this.filterOfficeLocations && this.filterOfficeLocations.length > 0) {
            queryParams.officeLocationId = { $in: this.filterOfficeLocations };
        }

        // Determine sort order
        const sortDescending = this.userPreferences.sortInvoiceListDescending ? -1 : 1;
        switch (this.userPreferences.sortInvoiceListBy) {
            case 'date':
                queryParams.$sort = {
                    date: sortDescending,
                    // A date is often the same -> set additional sort criterion
                    number: sortDescending,
                };
                break;
            case 'invoiceNumber':
                queryParams.$sort = {
                    number: sortDescending,
                };
                break;
            case 'lastName':
                queryParams.$sort = {
                    'recipient.contactPerson.lastName': sortDescending,
                    'recipient.contactPerson.firstName': sortDescending,
                };
                break;
            case 'paymentStatus':
                queryParams.$sort = {
                    // Atlas Search does not support sorting by booleans. The payment status sort is therefore only viable when offline.
                    // hasOutstandingPayments: sortDescending
                    dueDate: sortDescending,
                };
                break;
            case 'total':
                queryParams.$sort = {
                    totalGross: sortDescending,
                };
                break;
            case 'officeLocation':
                queryParams.$sort = {
                    officeLocationId: sortDescending,
                };
                break;
            default:
                throw Error('The API does not support sorting by this key: ' + this.userPreferences.sortInvoiceListBy);
        }

        // Determine filters
        switch (this.userPreferences.invoiceListQuickFilter) {
            case 'none':
                break;
            case 'onlyOverdue':
                queryParams.hasOutstandingPayments = true;
                queryParams.dueDate = { $lt: todayIso() };
                break;
            case 'onlyDue':
                queryParams.hasOutstandingPayments = true;
                queryParams.dueDate = { $gte: todayIso() };
                break;
            case 'onlyPaid':
                queryParams.hasOutstandingPayments = false;
                break;
            case 'unpaid':
                queryParams.hasOutstandingPayments = true;
                break;
            case 'partial': {
                queryParams.hasOutstandingPayments = true;
                queryParams['payments.type'] = 'payment' satisfies Payment['type'];
                break;
            }
            case 'hasOpenPaymentReminder': {
                queryParams.hasOutstandingPayments = true;
                queryParams.hasPaymentReminder = true;
                break;
            }
            case 'toBeReminded': {
                queryParams.hasOutstandingPayments = true;
                queryParams.nextPaymentReminderAt = {
                    $lte: todayIso(),
                };
                break;
            }
            case 'shortPaid':
                queryParams['payments.type'] = 'shortPayment' satisfies Payment['type'];
                break;
            case 'settledShortPayments':
                queryParams['payments'] = {
                    $elemMatch: {
                        type: 'shortPayment',
                        shortPaymentStatus: { $ne: 'outstandingClaim' },
                    } satisfies Partial<Record<keyof Payment, any>>,
                };
                break;
            case 'unsettledShortPayments':
                queryParams['payments'] = {
                    $elemMatch: {
                        type: 'shortPayment',
                        shortPaymentStatus: 'outstandingClaim',
                    } satisfies Partial<Payment>,
                };
                break;
            case 'labels':
                if (this.userPreferences.invoiceList_labelsForFilter.length > 0) {
                    queryParams['labels.name'] = {
                        $in: this.userPreferences.invoiceList_labelsForFilter,
                    };
                }
                break;
            case 'imported':
                queryParams.importedFromThirdPartySystem = {
                    $in: ['gtue', 'dynarex'] satisfies Invoice['importedFromThirdPartySystem'][],
                };
                break;
        }

        /**
         * If a filter is set, exclude
         * - cancellation invoices
         * - canceled invoices
         * - credit notes
         * Except when filter is 'imported', that one should show all invoices (canceled or not).
         */
        if (
            this.userPreferences.invoiceListQuickFilter &&
            this.userPreferences.invoiceListQuickFilter !== 'none' &&
            this.userPreferences.invoiceListQuickFilter !== 'imported'
        ) {
            queryParams.isFullCancellationInvoice = false;
            queryParams.isFullyCanceled = false;
            queryParams.type = 'invoice';
        }

        return queryParams;
    }

    /**
     * Add invoices that are not yet in this component's list to that list.
     */
    public addFreshInvoicesToList(newInvoices: Invoice[]): void {
        const uniqueInvoices = newInvoices.filter((newInvoice) => {
            // Only consider invoices that do not match an existing invoice
            return !this.invoices.find((existingInvoice) => existingInvoice._id === newInvoice._id);
        });

        if (uniqueInvoices.length !== newInvoices.length) {
            const filteredOutInvoices: Invoice[] = newInvoices.filter(
                (newInvoice) => !uniqueInvoices.find((uniqueInvoice) => uniqueInvoice._id === newInvoice._id),
            );
            console.warn(`[invoice-list] Some invoices were filtered out.`, filteredOutInvoices);
        }

        this.invoices.push(...uniqueInvoices);
    }

    private getInvoiceAnalytics(): Observable<InvoiceAnalytics> {
        return this.invoiceAnalyticsService
            .find({
                from: this.filterInvoicesFrom,
                to: this.filterInvoicesTo,
                dateFilterType: this.dateFilterType,
                officeLocationIds: this.filterOfficeLocations,
            })
            .pipe(
                tap({
                    error: (error) => {
                        this.apiErrorService.handleAndRethrow({
                            axError: error,
                            handlers: {
                                // Overwrite default in order to be more specific.
                                CLIENT_IS_OFFLINE: {
                                    title: 'Auswertungen offline nicht verfügbar',
                                    body: `Stelle eine Internetverbindung her, um diese Funktion zu verwenden.`,
                                    toastType: 'offline',
                                },
                                AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN: {
                                    title: 'Auswertungen offline nicht verfügbar',
                                    body: `Die autoiXpert-Server sind aktuell nicht erreichbar. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                                    toastType: 'offline',
                                },
                            },
                            defaultHandler: {
                                title: 'Auswertungen nicht geladen',
                                body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                            },
                        });
                    },
                }),
            );
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Websocket Events
    //****************************************************************************/
    private registerWebsocketEventHandlers() {
        this.registerCreationWebsocketEvent();
        this.registerPatchWebsocketEvent();
        this.registerDeletionWebsocketEvent();
    }

    /**
     * On each websocket create event, push that record to the list.
     * @private
     */
    private registerCreationWebsocketEvent() {
        const creationsSubscription: Subscription =
            this.invoiceService.createdFromExternalServerOrLocalBroadcast$.subscribe({
                next: (newRecord) => {
                    /**
                     * The events would cause records to be added to the list as soon as the records are added to the indexedDB.
                     * This causes lot's of rerenders and makes the application feel slow.
                     * Therefore we ignore the events while records are being loaded.
                     */
                    if (!this.isLoadMoreInvoicesPending) {
                        this.addFreshInvoicesToList([newRecord]);
                        this.filterAndSortInvoices();
                    }
                },
            });

        this.subscriptions.push(creationsSubscription);
    }

    /**
     * On each websocket put event, update local records.
     * @private
     */
    private registerPatchWebsocketEvent() {
        const patchUpdatesSubscription: Subscription =
            this.invoiceService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
                next: (patchedEvent: PatchedEvent<Invoice>) => {
                    // If this view holds the record being updated, update it.
                    const matchingInvoice = this.filteredInvoices.find(
                        (invoice) => invoice._id === patchedEvent.patchedRecord._id,
                    );
                    if (matchingInvoice) {
                        applyOfflineSyncPatchEventToLocalRecord({
                            localRecord: matchingInvoice,
                            patchedEvent,
                        });
                        this.filterAndSortInvoices();
                    }
                },
            });

        this.subscriptions.push(patchUpdatesSubscription);
    }

    /**
     * On each websocket delete event, delete local record.
     * @private
     */
    private registerDeletionWebsocketEvent() {
        const deletionsSubscription: Subscription = this.invoiceService.deletedInLocalDatabase$.subscribe({
            next: (deletedRecordId) => {
                // If this view holds the record being updated, remove it.
                const matchingInvoice = this.invoices.find((invoice) => invoice._id === deletedRecordId);
                if (matchingInvoice) {
                    removeFromArray(matchingInvoice, this.invoices);

                    this.filterAndSortInvoices();
                }
            },
        });

        this.subscriptions.push(deletionsSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Websocket Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public createNewInvoiceOnCtrlN(event: KeyboardEvent) {
        // Make sure the user is not inside an input
        if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
            return;
        }

        // cover Windows an Mac machines
        if (event.key === 'n') {
            event.preventDefault();
            this.createNewInvoice();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Notes
    //****************************************************************************/
    /**
     * If notes exist already, the notes icon will be displayed automatically.
     * This method allows displaying the notes icon manually, e.g. through the submenu of a report record.
     * @param invoice
     */
    public showNotesManually(invoice: Invoice) {
        this.manuallyShownNotesIcons.set(invoice, true);
    }

    public hideIconForEmptyNotes(invoice: Invoice) {
        if (!invoice.notes.length) {
            this.manuallyShownNotesIcons.delete(invoice);
        }
    }

    protected getPaymentStatus(invoice: Invoice) {
        return getPaymentStatus(invoice, this.todayAsIsoDate);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Notes
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    protected readonly trackById = trackById;
    protected readonly isSmallScreen = isSmallScreen;
}

/**
 * Payload for the loadMoreInvoices$ observable.
 */
type LoadMoreInvoicesPayload = {
    searchTerm?: string;
    filterAndSortParams: FeathersQuery;
    numberOfItemsToLoad?: number;
    searchAfterPaginationToken?: string;
    searchAfterNumberOfElements?: number;
};

import { set } from 'lodash-es';
import { ObjectDifference } from './get-list-of-differences';

export function insertChangesIntoRecord<DataType extends object>(
    record: DataType,
    changeList: ObjectDifference[],
): DataType {
    for (const change of changeList) {
        set(record, change.key, change.newValue);
    }

    return record;
}

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { ContactPersonService } from '../../services/contact-person.service';
import { ToastService } from '../../services/toast.service';

/**
 * Display, edit and select from a list of contact people.
 */
@Component({
    selector: 'contact-people-manager',
    templateUrl: 'contact-people-manager.component.html',
    styleUrls: ['contact-people-manager.component.scss'],
})
export class ContactPeopleManager {
    constructor(
        private contactPersonService: ContactPersonService,
        private toastService: ToastService,
    ) {}

    @Input() organizationType: ContactPerson['organizationType'];

    /**
     * If a contact person has been added, edited or removed, the parent components might need to reload their list.
     */
    @Output() contactPersonReloadRequired: EventEmitter<void> = new EventEmitter<void>();
    @Output() contactPersonSelection: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

    public availableContactPeople: ContactPerson[] = [];
    public filteredContactPeople: ContactPerson[] = [];
    public searchTerm: string;

    public selectedContactPerson: ContactPerson;
    public contactPersonInEditMode: ContactPerson;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.getAllAvailableContactPeople();
    }

    private getAllAvailableContactPeople(): void {
        this.contactPersonService.find({ organizationType: this.organizationType }).subscribe({
            next: (contactPeople) => {
                this.availableContactPeople = contactPeople;
                this.sortAndFilterContactPeople();
            },
            error: (err) => {
                this.toastService.error('Fehler', 'Konnte Kontakte nicht vom Server holen');
                console.error('ERR_RETRIEVING_CONTACT_PEOPLE_FROM_SERVER', err);
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter & Sort
    //****************************************************************************/
    public sortAndFilterContactPeople(): void {
        this.sortAvailableFavorites();
        this.filterContactPeople();
    }

    public filterContactPeople(): void {
        if (!this.searchTerm) {
            this.filteredContactPeople = [...this.availableContactPeople];
            return;
        }

        const searchTerms = this.searchTerm.toLowerCase().split(' ');

        this.filteredContactPeople = this.availableContactPeople.filter((favorite) => {
            const propertiesToBeSearched = [
                favorite.organization || '',
                favorite.lastName || '',
                favorite.firstName || '',
                favorite.streetAndHouseNumberOrLockbox || '',
                favorite.zip || '',
                favorite.city || '',
            ].map((property) => property && property.toLowerCase());

            return searchTerms.every((searchTerm) => {
                return propertiesToBeSearched.some((property) => property.includes(searchTerm));
            });
        });
    }

    public sortAvailableFavorites(): void {
        this.availableContactPeople.sort((favoriteA, favoriteB) => {
            const organizationA = favoriteA.organization || '';
            const organizationB = favoriteB.organization || '';

            // The two strings differ
            const comparisonResultOrganization = organizationA.localeCompare(organizationB);
            if (comparisonResultOrganization !== 0) {
                return comparisonResultOrganization;
            }

            const nameA = `${favoriteA.lastName} ${favoriteA.firstName}`;
            const nameB = `${favoriteB.lastName} ${favoriteB.firstName}`;
            return nameA.localeCompare(nameB);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter & Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Selecting
    //****************************************************************************/
    public selectContactPerson(selectedContactPerson: ContactPerson): void {
        this.selectedContactPerson = selectedContactPerson;
    }

    public async acceptContactPerson(): Promise<void> {
        if (!this.selectedContactPerson) {
            return;
        }
        this.contactPersonSelection.emit(this.selectedContactPerson);
        await this.closeSelector();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Selecting
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Edit Mode
    //****************************************************************************/
    public async addContactPerson(): Promise<void> {
        const newContactPerson = new ContactPerson({ organizationType: this.organizationType });
        this.availableContactPeople.push(newContactPerson);
        this.contactPersonInEditMode = newContactPerson;

        try {
            await this.contactPersonService.create(newContactPerson);
        } catch (error) {
            this.toastService.error('Kontakt konnte nicht gespeichert werden');
            console.error('ERR_CONTACT_PERSON_COULD_NOT_BE_SAVED_TO_SERVER', { error });
            return;
        }
        this.emitContactPersonReloadRequired();
    }

    public async removeContactPerson(contactPerson: ContactPerson): Promise<void> {
        const index = this.availableContactPeople.indexOf(contactPerson);
        this.availableContactPeople.splice(index, 1);
        this.sortAndFilterContactPeople();

        if (this.selectedContactPerson === contactPerson) {
            this.selectedContactPerson = null;
        }

        try {
            await this.contactPersonService.delete(contactPerson._id);
        } catch (error) {
            this.toastService.error(
                'Kontakt konnte nicht gelöscht werden',
                'Bitte prüfe deine Internetverbindung. Scheitert das Löschen weiterhin, kontaktiere bitte den aX-Support.',
            );
            console.error('ERR_COULD_NOT_REMOVE_CONTACT_PERSON_FROM_SERVER', { error });
        }

        this.emitContactPersonReloadRequired();
    }

    public editContactPerson(favorite): void {
        this.contactPersonInEditMode = favorite;
    }

    public async leaveEditMode(): Promise<void> {
        if (this.contactPersonInEditModeIsEmpty()) {
            await this.removeContactPerson(this.contactPersonInEditMode);
        }

        this.sortAndFilterContactPeople();
        this.contactPersonInEditMode = null;
    }

    public contactPersonInEditModeIsEmpty(): boolean {
        if (!this.contactPersonInEditMode) return false;

        return (
            !this.contactPersonInEditMode.organization &&
            !this.contactPersonInEditMode.lastName &&
            !this.contactPersonInEditMode.streetAndHouseNumberOrLockbox
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Common
    //****************************************************************************/
    public async handleOverlayClick(event: MouseEvent): Promise<void> {
        if (event.currentTarget === event.target) {
            await this.closeSelector();
        }
    }

    public async closeSelector(): Promise<void> {
        await this.leaveEditMode();
        this.close.emit(true);
    }

    public getTooltipForAddIcon(): string {
        switch (this.organizationType) {
            case 'visitLocationFavorite':
                return 'Neuer Favorit';
            case 'intermediary':
                return 'Neuer Vermittler';
        }
    }

    public emitContactPersonReloadRequired() {
        this.contactPersonReloadRequired.emit();
    }

    @HostListener('window:keydown', ['$event'])
    public async handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                await this.closeSelector();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    await this.leaveEditMode();
                }
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Common
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public async saveContactPerson(): Promise<void> {
        await this.contactPersonService.put(this.contactPersonInEditMode);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/
}

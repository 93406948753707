import Quill from 'quill';

const Delta = Quill.import('delta');

/**
 * Relevant for pasting content through Ctrl+V.
 */
export function lineBreakMatcher() {
    const newDelta = new Delta();
    newDelta.insert({ 'line-break': '' });
    return newDelta;
}

const Embed = Quill.import('blots/embed');

/**
 * Do not extend Quill's Break since that is handled in a special way by Quill's Scroll.deleteAt. The code reference lives at
 * quill/blots/scroll.js:63. This seems to assume that breaks can only occur at the end of a paragraph. Since autoiXpert allows
 * line-breaks everywhere, this assumption causes issues in very special cases. See https://autoixpert.slack.com/archives/CGJ9ECESJ/p1638457799053000
 * for an example video.
 */
export class LineBreak extends Embed {
    static tagName = 'BR';
    static blotName = 'line-break';
    static className = 'ql-line-break';

    insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref);
    }

    length() {
        return 1;
    }

    value() {
        /**
         * Ensure this break (µ) looks different from a paragraph (\n) so that Quill can differentiate between the two when
         * inserting new break blots in the keyboard binding for Shift+Enter.
         *
         * This µ is never synced to the server since autoiXpert syncs HTML (in this case <br> tags) to the server only.
         *
         * _Explanation_
         * Since browsers interpreting "<p>Test<br></p>" do not display a break after the "Test" in the paragraph because line breaks before
         * closing </p> tags are ignored (See https://stackoverflow.com/a/62523690/1027464 why that is by design), we need to enter two
         * line breaks for the user to continue typing on a new line if the user hits Shift+Enter at the end of a paragraph.
         */
        return 'µ';
    }
}

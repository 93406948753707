import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    NumberOfReportsAnalyticsResponse,
    ReportsByAssessorAnalyticsRecord,
    ReportsByMonthAnalyticsRecord,
} from '@autoixpert/models/analytics/reports-analytics.model';
import { AnalyticsFilterOptions, prepareDefaultParametersForAnalyticsQuery } from './default-analytics-query-parameter';

@Injectable()
export class ReportsAnalyticsService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getReportsByMonth(filterOptions: AnalyticsFilterOptions): Observable<ReportsByMonthAnalyticsRecord[]> {
        return this.httpClient.get<ReportsByMonthAnalyticsRecord[]>(`${this.apiPath}/analytics/reportsByMonth`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'completionDate',
                ...filterOptions,
            }),
        });
    }

    public getReportsByAssessor(filterOptions: AnalyticsFilterOptions): Observable<ReportsByAssessorAnalyticsRecord[]> {
        return this.httpClient.get<ReportsByAssessorAnalyticsRecord[]>(`${this.apiPath}/analytics/reportsByAssessor`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'completionDate',
                ...filterOptions,
            }),
        });
    }

    /**
     * Gets the number of reports for each user or, if he has the analytics access right, for the entire team.
     */
    public getNumberOfLockedReports(): Observable<NumberOfReportsAnalyticsResponse> {
        return this.httpClient.get<NumberOfReportsAnalyticsResponse>(`${this.apiPath}/analytics/numberOfReports`, {
            params: {
                state: 'done',
            },
        });
    }
}

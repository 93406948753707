export function getIconNameForGarageCharacteristic(garageCharacteristic: string): string {
    switch (garageCharacteristic) {
        case 'Elektrofahrzeuge':
            return 'electricVehicles';
        case 'Motorrad/Motorräder':
            return 'motorcycles';
        case 'Wohnwagen/Wohnwägen':
            return 'caravans';
        case 'Nutzfahrzeuge':
            return 'utilityVehicles';
        case 'Kalkulation mit DAT':
            return 'calculationSystemDat';
        case 'Kalkulation mit Audatex':
            return 'calculationSystemAudatex';
    }
}

export function iconForGarageCharacteristicExists(garageCharacteristic: string): boolean {
    return [
        'Elektrofahrzeuge',
        'Motorrad/Motorräder',
        'Wohnwagen/Wohnwägen',
        'Nutzfahrzeuge',
        'Kalkulation mit DAT',
        'Kalkulation mit Audatex',
    ].includes(garageCharacteristic);
}

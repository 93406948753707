<div *ngIf="isExpanded" class="analytics-filter" @slideInAndOutVertically>
    <div class="custom-filters"><ng-content></ng-content></div>

    <!--********** Assessor Filter **********-->
    <assessor-picker
        *ngIf="showAssessorFilter"
        [(assessorIds)]="assessorIds"
        (assessorIdsChange)="assessorChanged()"></assessor-picker>

    <!--********** Office Locations Filter **********-->
    <office-locations-picker
        *ngIf="showOfficeLocationFilter"
        [(officeLocationIds)]="officeLocationIds"
        (officeLocationIdsChange)="officeLocationChanged()"></office-locations-picker>

    <!--********** Report Label Filter **********-->
    <label-picker
        *ngIf="showReportLabelFilter"
        [(labelConfigIds)]="reportLabelConfigIds"
        (labelConfigIdsChange)="reportLabelsChanged()"></label-picker>

    <!--********** Invoice Label Filter **********-->
    <label-picker
        *ngIf="showInvoiceLabelFilter"
        [labelGroup]="'invoice'"
        [(labelConfigIds)]="invoiceLabelConfigIds"
        (labelConfigIdsChange)="invoiceLabelsChanged()"></label-picker>

    <!--********** Reset Filters Button **********-->
    <mat-icon
        id="reset-filters-button"
        class="toolbar-icon medium-icon"
        (click)="resetAllFilters()"
        [matTooltip]="'Alle Filter zurücksetzen'">
        filter_alt_off
    </mat-icon>
</div>

import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { LegacyFloatLabelType } from '@angular/material/legacy-form-field';
import moment from 'moment';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';

@Component({
    selector: 'date-range-picker',
    templateUrl: 'date-range-picker.component.html',
    styleUrls: ['date-range-picker.component.scss'],
})
export class DateRangePickerComponent {
    _dateFrom: IsoDate;
    // When a value is set from outside, ensure removing field content causes an event emit by changing the last
    // emitted value to the value set from outside. A change to null will thus be a difference and the emit function
    // allows an emission.
    @Input() set dateFrom(value: string) {
        this._dateFrom = toIsoDate(value);
        this.lastEmittedDateFrom = value;
        this.lastEmittedDateRange.dateFrom = value;
    }

    _dateTo: IsoDate;
    // When a value is set from outside, ensure removing field content causes an event emit by changing the last
    // emitted value to the value set from outside. A change to null will thus be a difference and the emit function
    // allows an emission.
    @Input() set dateTo(value: string) {
        this._dateTo = toIsoDate(value);
        this.lastEmittedDateTo = value;
        this.lastEmittedDateRange.dateTo = value;
    }

    // Date Filter Type
    @Input() dateFilterType: 'invoiceDate' | 'paymentDate';

    @Input() placeholderDateFrom: string;
    @Input() placeholderDateTo: string;

    /**
     * Whether the date range picker should take up the available space. Default is false,
     * in which case the date inputs have a fixed width. If set to true, the date input fields
     * will take up 50% of the available width each and the more menu icon button will be positioned
     * absolute to the right (so when used inside of cards the icon is inside the padding area).
     */
    @HostBinding('class.stretch')
    @Input()
    stretch: boolean = false;

    @Input() floatPlaceholder: LegacyFloatLabelType = 'auto';

    @Output() dateFromChange: EventEmitter<IsoDate> = new EventEmitter();
    @Output() dateToChange: EventEmitter<IsoDate> = new EventEmitter();
    @Output() dateRangeChange: EventEmitter<DateRange> = new EventEmitter();
    @Output() dateFilterTypeChange: EventEmitter<this['dateFilterType']> = new EventEmitter();
    private lastEmittedDateFrom: string = null;
    private lastEmittedDateTo: string = null;
    private lastEmittedDateRange: DateRange = {
        dateFrom: null,
        dateTo: null,
    };

    public setDateRange(dateRange: VerbalDateRange): void {
        const oldDateFromValue = this._dateFrom;
        const oldDateToValue = this._dateTo;

        const startDate: moment.Moment = moment();
        const endDate: moment.Moment = moment();

        switch (dateRange) {
            //*****************************************************************************
            //  Current Ranges
            //****************************************************************************/
            case 'currentYear':
                startDate.startOf('year');
                endDate.endOf('year');
                break;
            case 'currentQuarter':
                startDate.startOf('quarter');
                endDate.endOf('quarter');
                break;
            case 'currentMonth':
                startDate.startOf('month');
                endDate.endOf('month');
                break;
            case 'currentWeek':
                startDate.startOf('week');
                endDate.endOf('week');
                break;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Current Ranges
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Latest Ranges
            //****************************************************************************/
            case 'lastTwelveMonths':
                // Subtract 11 because the 12th is the current month
                startDate.subtract(11, 'months').startOf('month');
                endDate.endOf('month');
                break;
            case 'lastThreeMonths':
                startDate.subtract(2, 'months').startOf('month');
                endDate.endOf('month');
                break;
            case 'lastThirtyDays':
                startDate.subtract(29, 'days');
                break;
            case 'lastSevenDays':
                startDate.subtract(6, 'days');
                break;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Latest Ranges
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Previous Ranges
            //****************************************************************************/
            case 'previousYear':
                startDate.subtract(1, 'year').startOf('year');
                endDate.subtract(1, 'year').endOf('year');
                break;
            case 'previousQuarter':
                startDate.subtract(1, 'quarter').startOf('quarter');
                endDate.subtract(1, 'quarter').endOf('quarter');
                break;
            case 'previousMonth':
                startDate.subtract(1, 'month').startOf('month');
                endDate.subtract(1, 'month').endOf('month');
                break;
            case 'previousWeek':
                startDate.subtract(1, 'week').startOf('week');
                endDate.subtract(1, 'week').endOf('week');
                break;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Previous Ranges
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Single Months
            //****************************************************************************/
            case 'monthJanuary':
                startDate.month(0).startOf('month');
                endDate.month(0).endOf('month');
                break;
            case 'monthFebruary':
                startDate.month(1).startOf('month');
                endDate.month(1).endOf('month');
                break;
            case 'monthMarch':
                startDate.month(2).startOf('month');
                endDate.month(2).endOf('month');
                break;
            case 'monthApril':
                startDate.month(3).startOf('month');
                endDate.month(3).endOf('month');
                break;
            case 'monthMay':
                startDate.month(4).startOf('month');
                endDate.month(4).endOf('month');
                break;
            case 'monthJune':
                startDate.month(5).startOf('month');
                endDate.month(5).endOf('month');
                break;
            case 'monthJuly':
                startDate.month(6).startOf('month');
                endDate.month(6).endOf('month');
                break;
            case 'monthAugust':
                startDate.month(7).startOf('month');
                endDate.month(7).endOf('month');
                break;
            case 'monthSeptember':
                startDate.month(8).startOf('month');
                endDate.month(8).endOf('month');
                break;
            case 'monthOctober':
                startDate.month(9).startOf('month');
                endDate.month(9).endOf('month');
                break;
            case 'monthNovember':
                startDate.month(10).startOf('month');
                endDate.month(10).endOf('month');
                break;
            case 'monthDecember':
                startDate.month(11).startOf('month');
                endDate.month(11).endOf('month');
                break;
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Single Months
            /////////////////////////////////////////////////////////////////////////////*/
        }

        this._dateFrom = toIsoDate(startDate.format());
        this._dateTo = toIsoDate(endDate.format());

        if (oldDateFromValue !== this._dateFrom) {
            this.emitDateFromChange();
        }

        if (oldDateToValue !== this._dateTo) {
            this.emitDateToChange();
        }

        if (oldDateFromValue !== this._dateFrom || oldDateToValue !== this._dateTo) {
            this.emitDateRangeChange();
        }
    }

    /**
     * Allow the user to get all invoices...
     * - whose invoice date lies in the date range or
     * - that received a payment within the date limits.
     * @param dateFilterType
     */
    public setDateFilterType(dateFilterType: this['dateFilterType']) {
        this.dateFilterType = dateFilterType;
        this.emitDateFilterTypeChange();
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitDateFromChange(): void {
        const emitValue: IsoDate = this._dateFrom
            ? toIsoDate(moment(this._dateFrom).startOf('day').format())
            : this._dateFrom;
        // Only emit on change.
        if (this.lastEmittedDateFrom === emitValue) {
            return;
        }
        this.dateFromChange.emit(emitValue);
        this.lastEmittedDateFrom = emitValue;
    }

    public emitDateToChange(): void {
        const emitValue: IsoDate = this._dateTo ? toIsoDate(moment(this._dateTo).endOf('day').format()) : this._dateTo;
        // Only emit on change.
        if (this.lastEmittedDateTo === emitValue) {
            return;
        }
        this.dateToChange.emit(emitValue);
        this.lastEmittedDateTo = emitValue;
    }

    public emitDateRangeChange(): void {
        const emitValue = {
            dateFrom: toIsoDate(moment(this._dateFrom).startOf('day').format()),
            dateTo: toIsoDate(moment(this._dateTo).endOf('day').format()),
        };
        // Only emit on change.
        if (
            this.lastEmittedDateRange.dateFrom === emitValue.dateFrom &&
            this.lastEmittedDateRange.dateTo === emitValue.dateTo
        ) {
            return;
        }
        this.dateRangeChange.emit(emitValue);
        this.lastEmittedDateRange = emitValue;
    }

    public emitDateFilterTypeChange(): void {
        this.dateFilterTypeChange.emit(this.dateFilterType);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

export type VerbalDateRange =
    | 'currentYear'
    | 'currentQuarter'
    | 'currentMonth'
    | 'currentWeek'
    | 'lastTwelveMonths'
    | 'lastThreeMonths'
    | 'lastThirtyDays'
    | 'lastSevenDays'
    | 'previousYear'
    | 'previousQuarter'
    | 'previousMonth'
    | 'previousWeek'
    | 'monthJanuary'
    | 'monthFebruary'
    | 'monthMarch'
    | 'monthApril'
    | 'monthMay'
    | 'monthJune'
    | 'monthJuly'
    | 'monthAugust'
    | 'monthSeptember'
    | 'monthOctober'
    | 'monthNovember'
    | 'monthDecember';

export interface DateRange {
    dateFrom: string;
    dateTo: string;
}

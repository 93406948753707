import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isBuildingBlockConditionMet } from '@autoixpert/lib/document-building-blocks/choose-document-building-blocks';
import { isDocumentBuildingBlockConditionGroup } from '@autoixpert/lib/document-building-blocks/is-document-building-block-condition-group';
import { PlaceholderValueTree } from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { DocumentBuildingBlockConditionGroup } from '@autoixpert/models/documents/document-building-block-condition-group';
import { trackById } from 'src/app/shared/libraries/track-by-id';
import { ToastService } from '../../shared/services/toast.service';

/**
 * Component for displaying a preview of the conditions of a document building block or a document building block variant.
 * Both consumers fulfill the same interface: DocumentBuildingBlockConditionGroup
 */
@Component({
    selector: 'document-building-block-conditions-preview',
    templateUrl: './document-building-block-conditions-preview.component.html',
    styleUrls: ['./document-building-block-conditions-preview.component.scss'],
})
export class DocumentBuildingBlockConditionsPreviewComponent {
    public readonly trackById = trackById;
    public readonly isBuildingBlockConditionMet = isBuildingBlockConditionMet;
    public readonly isDocumentBuildingBlockConditionGroup = isDocumentBuildingBlockConditionGroup;

    @Input() placeholderValueTree: PlaceholderValueTree;

    // Whether this is a building block conditions preview (instead of a building block variant conditions preview). Needed to determine if light or dark mode should be used
    @Input() buildingBlockConditionsPreview: boolean = false;

    // Either a document building block or a document building block variant
    @Input() conditionsGroup: DocumentBuildingBlockConditionGroup;

    // Needed for error message when there is an invalid placeholder value type
    @Input() buildingBlockTitle: string;

    // Only relevant for document building block variants
    @Input() isDefaultVariant: boolean;

    // Disabled if the user does not have the necessary access rights
    @Input() isDisabled: boolean;

    // Placeholder values for the selected report or invoice, used to display whether the condition would be fulfilled for the selected report
    @Input() placeholderValues: PlaceholderValues | undefined;

    // Whether the conditions preview is active and the result of the conditions evaluation is displayed
    @Input() previewActive: boolean;

    // Condition evaluation result passed by parent component
    @Input() previewConditionEvaluationResult?: boolean | null;

    @Output() conditionPreviewClick = new EventEmitter<void>();
    @Output() removeIsDefaultVariant = new EventEmitter<void>();

    // If there is an invalid condition, only tell the user once on this page (because evaluateConditionGroupWithPlaceholderValues is called multiple times from the template).
    private invalidPlaceholderNameInConditionWarningWasShown = false;

    constructor(private readonly toastService: ToastService) {}

    evaluateConditionGroupWithPlaceholderValues(): { result: boolean } | false {
        if (!this.previewActive) {
            return false;
        }

        // Check if the condition preview evaluation value has been passed by parent component
        if (this.previewConditionEvaluationResult !== undefined && this.previewConditionEvaluationResult !== null) {
            return { result: this.previewConditionEvaluationResult };
        }

        // Calculate the condition evaluation result if all necessary data is available (default variant cannot compute the result by itself)
        if (!this.conditionsGroup || !this.placeholderValues || !this.placeholderValueTree || this.isDefaultVariant) {
            return false;
        }

        let result: boolean = false;
        try {
            result = isBuildingBlockConditionMet({
                condition: this.conditionsGroup,
                placeholderValues: this.placeholderValues,
                placeholderValueTree: this.placeholderValueTree,
            });
        } catch (error) {
            if (error.code === 'DOCUMENT_BUILDING_BLOCK_PLACEHOLDER_VALUE_TYPE_NOT_DEFINED') {
                // There is an invalid placeholder value type, which often happens, if the user deleted a custom field config but that field is still being used in a condition
                if (!this.invalidPlaceholderNameInConditionWarningWasShown) {
                    this.toastService.warn(
                        'Ungültiger Platzhalter',
                        `Eine Bedingung des Textbausteins "${this.buildingBlockTitle}" verwendet einen ungültigen Platzhalter. Das passiert zum Beispiel, wenn ein eigenes Feld gelöscht wurde, aber noch in einer Bedingung verwendet wird.`,
                        { timeOut: 0 },
                    );
                    this.invalidPlaceholderNameInConditionWarningWasShown = true;
                }
            } else {
                // Only handle the invalid placeholder value type error, because that one can be solved by the user. All other errors should be rethrown and reported to sentry
                throw error;
            }
        }

        return { result };
    }

    /**
     * Returns the top padding for the conditions preview, necessary to fix the issue where a variant conditions top-level operator
     * overlaps with the operator of the first condition group.
     */
    getTopPadding() {
        if (
            !this.buildingBlockConditionsPreview &&
            this.conditionsGroup &&
            isDocumentBuildingBlockConditionGroup(this.conditionsGroup.conditions[0])
        ) {
            if (this.conditionsGroup.conditionsOperator === 'or') {
                return 25;
            }
            return 20;
        }

        return 15;
    }
}

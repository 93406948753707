import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import moment from 'moment';
import { AxLicense, AxLicenseType } from '@autoixpert/models/billing/ax-license';
import { Team } from '@autoixpert/models/teams/team';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class AxLicenseService extends OfflineSyncServiceBase<AxLicense> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'axLicenses',
            httpClient,
            networkStatusService,
            frontendLogService,
            syncIssueNotificationService,
            serviceWorker,
        });
    }

    // TODO: only get active licenses

    public async getCurrentTeamLicense(team: Team) {
        const now = moment().toISOString();

        const billedLicenses: AxLicense[] = await this.find({
            teamId: team._id,
            type: AxLicenseType.TeamAccount,
            endDate: { $gte: now },
            startDate: { $lte: now },
        }).toPromise();
        return billedLicenses?.[0];
    }

    /**
     * Gets all current licenses of a team.
     */
    public async getAllCurrentLicenses(team: Team) {
        const now = moment().format('YYYY-MM-DD');

        const billedLicenses: AxLicense[] = await this.find({
            teamId: team._id,
            $or: [{ endDate: { $gte: now } }, { endDate: null }],
            startDate: { $lte: now },
        }).toPromise();
        return billedLicenses;
    }

    /**
     * Get the number of active additional user licenses of a team.
     */
    public async getCurrentNumberOfPaidAdditionalUsers(team: Team) {
        const now = moment().format('YYYY-MM-DD');

        const billedLicenses = await this.find({
            teamId: team._id,
            type: AxLicenseType.AdditionalUser,
            $or: [{ endDate: { $gte: now } }, { endDate: null }],
            startDate: { $lte: now },
        }).toPromise();
        return billedLicenses.length;
    }
}

import { CustomCarPropertyAutocomplete } from '../../models/reports/car-identification/custom-car-property';

export const defaultCustomCarPropertiesForBicycles: CustomCarPropertyAutocomplete[] = [
    new CustomCarPropertyAutocomplete({
        title: 'Art',
        values: ['Citybike', 'Mountainbike', 'Rennrad', 'Lastenrad'],
    }),
    new CustomCarPropertyAutocomplete({
        addAsDefault: true,
        title: 'Rahmen Hersteller / Typ',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        addAsDefault: true,
        title: 'Rahmenmaterial',
        values: ['Aluminium', 'Karbon', 'Stahl'],
    }),
    new CustomCarPropertyAutocomplete({
        addAsDefault: true,
        title: 'Rahmengröße',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        title: 'Schaltung (vorne/hinten)',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        title: 'Bremsen (vorne/hinten)',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        title: 'Federung (vorne/hinten)',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        title: 'Sattel / Sattelstütze',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        title: 'Vorbau / Lenker',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        addAsDefault: true,
        title: 'Elektromotor Hersteller / Typ',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        addAsDefault: true,
        title: 'Akku Hersteller / Typ',
        values: [],
    }),
    new CustomCarPropertyAutocomplete({
        title: 'Bordcomputer Hersteller / Typ',
        values: [],
    }),
];

import { formatNumber } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    MatLegacyAutocomplete as MatAutocomplete,
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import 'moment-duration-format';
import { Subscription } from 'rxjs';
import { isRemappingRequiredForAudatexWageRates } from '@autoixpert/external-apis/audatex/get-audatex-wage-rates';
import { getApplicableReplacementValue } from '@autoixpert/lib/car-valuation/get-applicable-replacement-value';
import { getApplicableResidualValue } from '@autoixpert/lib/car-valuation/get-applicable-residual-value';
import { doesVehicleHaveBatteryElectricEngine } from '@autoixpert/lib/car/get-engine-type';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { calculateRepairDuration } from '@autoixpert/lib/damage-calculation-values/calculate-repair-duration';
import {
    downtimeCompensationGroupMappingsEurotaxCars,
    downtimeCompensationGroupMappingsEurotaxMotorcycles,
} from '@autoixpert/lib/damage-calculation-values/downtime-compensation/downtime-compensation-group-mappings-eurotax';
import { getApplicableRepairCosts } from '@autoixpert/lib/damage-calculation-values/get-applicable-repair-costs';
import { getRestorationValue } from '@autoixpert/lib/damage-calculation-values/get-restoration-effort';
import { replacementValueCorrectionExists } from '@autoixpert/lib/damage-calculation-values/replacement-value-correction/replacement-value-correction-exists';
import { setReportDocumentInclusionStatusByType } from '@autoixpert/lib/documents/set-report-document-inclusion-status-by-type';
import {
    Translator,
    VehicleValueLabelGerman,
    VehicleValueLabelGermanShort,
} from '@autoixpert/lib/placeholder-values/translator';
import { isAmendmentReport } from '@autoixpert/lib/report/is-amendment-report';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { getFullUserContactPerson } from '@autoixpert/lib/users/get-full-user-contact-person';
import { isAfzzertUserComplete } from '@autoixpert/lib/users/is-afzzert-user-complete';
import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { DamageCalculation } from '@autoixpert/models/reports/damage-calculation/damage-calculation';
import { AudatexDowntimeCompensation } from '@autoixpert/models/reports/damage-calculation/downtime-compensation/audatex-downtime-compensation';
import { DatDowntimeCompensationResponse } from '@autoixpert/models/reports/damage-calculation/downtime-compensation/dat-downtime-compensation-response';
import { Repair } from '@autoixpert/models/reports/damage-description/repair';
import { Valuation, ValuationVehicleValueType } from '@autoixpert/models/reports/market-value/valuation';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { CustomAutocompleteEntry } from '@autoixpert/models/text-templates/custom-autocomplete-entry';
import { User } from '@autoixpert/models/user/user';
import { getVatRateForTaxationType } from '@autoixpert/static-data/taxation-rates';
import {
    DescriptionFromRepairCalculationDialogComponent,
    DescriptionFromRepairCalculationDialogResult,
} from 'src/app/shared/components/description-from-repair-calculation-dialog/description-from-repair-calculation-dialog.component';
import { generateRepairInstructionsFromCalculation } from 'src/app/shared/libraries/damage-calculation/translate-damaged-parts';
import { getValuationPriceLabelTooltip } from 'src/app/shared/libraries/dat-helpers/get-valuation-price-label-tooltip';
import { determineTaxationTypes } from 'src/app/shared/libraries/report/determine-taxation-type';
import { setVehicleValue } from 'src/app/shared/libraries/report/set-vehicle-value';
import { AddressAutocompletionService } from 'src/app/shared/services/google/address-autocompletion.service';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../../shared/animations/run-child-animations.animation';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { slideOutSide } from '../../../shared/animations/slide-out-side.animation';
import { slideOutVertical } from '../../../shared/animations/slide-out-vertical.animation';
import { MatQuillComponent } from '../../../shared/components/mat-quill/mat-quill.component';
import { determineDamageType } from '../../../shared/libraries/damage-calculation/determine-damage-type';
import { getAudatexErrorHandlers } from '../../../shared/libraries/error-handlers/get-audatex-error-handlers';
import { getDatErrorHandlers } from '../../../shared/libraries/error-handlers/get-dat-error-handlers';
import { getSelectedGarageFeeSet } from '../../../shared/libraries/garages/get-selected-garage-fee-set';
import { getMissingAccessRightTooltip } from '../../../shared/libraries/get-missing-access-right-tooltip';
import { getRentalCarClasses } from '../../../shared/libraries/get-rental-car-classes';
import { replaceObjectProperties } from '../../../shared/libraries/objects/replace-object-properties';
import { isKaskoCase } from '../../../shared/libraries/report-properties/is-kasko-case';
import { hasAccessRight } from '../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { AudatexTaskService } from '../../../shared/services/audatex/audatex-task.service';
import { ContactPersonService } from '../../../shared/services/contact-person.service';
import { CustomAutocompleteEntriesService } from '../../../shared/services/custom-autocomplete-entries.service';
import { DatAuthenticationService } from '../../../shared/services/dat-authentication.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { NewWindowService } from '../../../shared/services/new-window.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { TeamService } from '../../../shared/services/team.service';
import { ToastService } from '../../../shared/services/toast.service';
import { TutorialStateService } from '../../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { UserService } from '../../../shared/services/user.service';

@Component({
    selector: 'damage-calculation',
    templateUrl: 'damage-calculation.component.html',
    styleUrls: ['damage-calculation.component.scss'],
    animations: [
        slideOutSide(),
        // Slide animation for the label "Reparaturkosten kalkulieren mit"
        slideInAndOutVertically(),
        slideOutVertical(),
        fadeInAndOutAnimation(),
        runChildAnimations(),
    ],
})
export class DamageCalculationComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private newWindowService: NewWindowService,
        private httpClient: HttpClient,
        private reportDetailsService: ReportDetailsService,
        private contactPersonService: ContactPersonService,
        public userPreferences: UserPreferencesService,
        private apiErrorService: ApiErrorService,
        private dialog: MatDialog,
        private tutorialStateService: TutorialStateService,
        private datAuthenticationService: DatAuthenticationService,
        private audatexTaskService: AudatexTaskService,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
        private networkStatusService: NetworkStatusService,
        private customAutocompleteEntriesService: CustomAutocompleteEntriesService,
        private addressAutocompletionService: AddressAutocompletionService,
        private userService: UserService,
    ) {}
    // References for usage from html template
    protected doesVehicleHaveBatteryElectricEngine = doesVehicleHaveBatteryElectricEngine;
    protected replacementValueCorrectionExists = replacementValueCorrectionExists;
    protected Math = Math;

    public reportId: string;
    public report: Report;
    public user: User;
    public team: Team;
    private subscriptions: Subscription[] = [];

    @ViewChild('generateRepairInstructionsMenuTrigger') generateRepairInstructionsMenuTrigger: MatMenuTrigger;

    // Garages
    public garagesFromOpenReports: ContactPerson[] = [];
    public garageFeesDialogShown = false;

    // DEKRA Fees
    public editDekraFeeZip: boolean = false;
    public dekraZipToCityLookupPending: boolean;
    public dekraFeesCustomZipWarnings: string[] = [];

    // Special Costs Dialog
    public specialCostsDialogShown = false;

    // Downtime Compensation
    public downtimeCompensationRequestPending: boolean;

    // Eurotax-Schwacke
    public downtimeCompensationGroupsForCarsEurotax = downtimeCompensationGroupMappingsEurotaxCars;
    public downtimeCompensationGroupsForMotorcyclesEurotax = downtimeCompensationGroupMappingsEurotaxMotorcycles;

    public rentalCarClasses = getRentalCarClasses();
    @ViewChild('downtimeCompensationRemark') downtimeCompensationRemark: MatQuillComponent;
    public downtimeCompensationCommentShown = false;
    public downtimeCompensationTextTemplatesShown: boolean = false;

    // Repair Details
    public paintBlendingTextTemplatesShown = false;
    public axisMeasurementTextTemplatesShown = false;
    public carBodyMeasurementTextTemplatesShown = false;
    public highVoltageSystemCheckTextTemplatesShown = false;
    public readonly repairRisks: string[] = ['Motor', 'Getriebe', 'Achsen', 'Hochvoltbatterie'];
    public repairRisksAutocompleteEntries: CustomAutocompleteEntry[] = [];
    public filteredRepairRisks: string[] = [
        ...this.repairRisks,
        ...this.repairRisksAutocompleteEntries.map((risk) => risk.value),
    ];
    @ViewChild('repairRisksAutocomplete') repairRisksAutocomplete: MatAutocomplete = null;
    public repairInstructionsTextTemplatesShown = false;
    public repairRisksTextTemplatesShown = false;

    public replacementValueRemarkShown = false;
    public replacementValueTextTemplateSelectorShown = false;
    public residualValueRemarkShown = false;
    public technicalDiminishedValueCommentShown = false;
    public diminishedValueWarningNotificationShown: boolean = false;
    public hideDiminishedValueWarningClaimantTaxationNotification: boolean = false;
    public valueIncreaseRemarkShown = false;
    public residualValueTextTemplateSelectorShown = false;
    public technicalDiminishedValueTextTemplatesShown = false;
    public valueIncreaseTextTemplateSelectorShown = false;

    // ********** Diminished Value Calculator **********
    public diminishedValueCalculatorShown = false;

    // Replacement Value
    protected replacementValueCorrectionsDialogShown: boolean = false;

    // Helper variable to pass the info to the dialog that the vehicle base value input should be focused
    // directly after opening it. Used when the user clicks the disabled replacement value input field.
    protected replacementValueCorrectionsDialogFocusInput: boolean = false;

    // The repair costs for calculating the relevant values
    get applicableRepairCosts(): number {
        return getApplicableRepairCosts(this.report);
    }

    // The replacement value to compare repair costs with differs for private persons (gross value) and companies (net value; amount depends on taxation type)
    get applicableReplacementValue(): number {
        return getApplicableReplacementValue(this.report);
    }

    // The replacement value to compare repair costs with differs for private persons (gross value) and companies (net value; amount depends on taxation type)
    get applicableResidualValue(): number {
        return getApplicableResidualValue(this.report);
    }

    get restorationValue(): number {
        if (!this.report) {
            return null;
        }
        return getRestorationValue(this.report);
    }

    // ********* Residual Value **********
    public repairDurationFormatInvalid = false;
    public replacementTimeFormatInvalid = false;

    @ViewChild('replacementValueRemark') replacementValueRemark: MatQuillComponent;
    @ViewChild('residualValueRemark') residualValueRemark: MatQuillComponent;
    @ViewChild('technicalDiminishedValueRemark') technicalDiminishedValueRemark: MatQuillComponent;
    @ViewChild('valueIncreaseRemark') valueIncreaseRemark: MatQuillComponent;
    @ViewChild('axisMeasurementRemark') axisMeasurementRemark: MatQuillComponent;
    @ViewChild('carBodyMeasurementRemark') carBodyMeasurementRemark: MatQuillComponent;
    @ViewChild('highVoltageSystemCheckRemark') highVoltageSystemCheckRemark: MatQuillComponent;
    @ViewChild('repairRisksRemark') repairRisksRemark: MatQuillComponent;

    // Repair
    public standardAxisMeasurementOptions: { value: Repair['axisMeasurement']; label: string }[] = [
        {
            label: '',
            value: null,
        },
        {
            label: 'erforderlich',
            value: 'required',
        },
        {
            label: 'nicht erforderlich',
            value: 'notRequired',
        },
        {
            label: 'bereits durchgeführt',
            value: 'carriedOut',
        },
    ];
    public standardCarBodyMeasurementOptions: { value: Repair['carBodyMeasurement']; label: string }[] = [
        {
            label: '',
            value: null,
        },
        {
            label: 'erforderlich',
            value: 'required',
        },
        {
            label: 'nicht erforderlich',
            value: 'notRequired',
        },
        {
            label: 'bereits durchgeführt',
            value: 'carriedOut',
        },
    ];
    public standardHighVoltageSystemCheckOptions: { value: Repair['highVoltageSystemCheck']; label: string }[] = [
        {
            label: '',
            value: null,
        },
        {
            label: 'erforderlich',
            value: 'required',
        },
        {
            label: 'nicht erforderlich',
            value: 'notRequired',
        },
        {
            label: 'bereits durchgeführt',
            value: 'carriedOut',
        },
    ];

    public axisMeasurementCommentShown = false;
    public carBodyMeasurementCommentShown = false;
    public highVoltageSystemCheckCommentShown = false;
    public repairRisksCommentShown = false;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit(): void {
        this.team = this.loggedInUserService.getTeam();
        this.user = this.loggedInUserService.getUser();

        const routeSubscription = this.route.parent.params.subscribe((params) => (this.reportId = params['reportId']));
        const reportSubscription = this.reportDetailsService.get(this.reportId).subscribe((report) => {
            this.report = report;

            if (this.report.type === 'valuation') {
                // Valuation reports do not have the damage calculation tab.
                // Redirect to the valuation tab instead.
                this.router.navigate(['Gutachten', report._id, 'Bewertung']);
            }

            if (this.report.damageCalculation) {
                if (!this.report.damageCalculation.repair.repairRisks) {
                    this.report.damageCalculation.repair.repairRisks = [];
                }
            }

            // Show comments
            this.showDowntimeCompensationComment();
            this.showReplacementValueRemark();
            this.showResidualValueRemark();
            this.showTechnicalDiminishedValueRemark();
            this.showValueIncreaseComment();
            this.showAxisMeasurementComment();
            this.showCarBodyMeasurementComment();
            this.showHighVoltageSystemCheckComment();
            this.showRepairRisksComment();

            // Check format of duration input
            this.checkRepairDurationFormat();
            this.checkReplacementTimeFormat();

            this.retrieveCustomRiskAutocompleteEntry();

            // Must be loaded after report so that the current report's contacts are excluded.
            this.loadGaragesFromOpenReports();

            this.initializeTaxationTypeForTrucks();

            this.joinAsRealtimeEditor();
        });

        this.subscriptions.push(routeSubscription, reportSubscription);
    }

    private showDowntimeCompensationComment(): void {
        if (this.report.damageCalculation?.downtimeCompensationComment) {
            this.downtimeCompensationCommentShown = true;
        }
    }

    private showReplacementValueRemark(): void {
        if (this.report.valuation.vehicleValueRemark) {
            this.replacementValueRemarkShown = true;
        }
    }

    private showResidualValueRemark(): void {
        if (this.report.valuation.residualValueRemark) {
            this.residualValueRemarkShown = true;
        }
    }

    private showTechnicalDiminishedValueRemark(): void {
        if (this.report.valuation.technicalDiminishedValueComment) {
            this.technicalDiminishedValueCommentShown = true;
        }
    }

    private showValueIncreaseComment(): void {
        if (this.report.valuation.valueIncreaseRemark) {
            this.valueIncreaseRemarkShown = true;
        }
    }

    /**
     * Commercial vehicles (such as trucks, trailers, buses, etc.) are usually traded between
     * companies (not individuals) so the taxation type is very likely to be 'regular'.
     * https://wissen.autoixpert.de/hc/de/articles/360015374411
     */
    private initializeTaxationTypeForTrucks(): void {
        if (
            !this.report.valuation.taxationType &&
            (['semiTruck', 'truck', 'trailer', 'bus'] as Array<Car['shape']>).includes(this.report.car.shape)
        ) {
            this.report.valuation.taxationType = 'full';
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    public setTaxationTypeIfNotSet(): void {
        if (!this.report.valuation.taxationType) {
            this.report.valuation.taxationType = determineTaxationTypes(this.report);
        }
    }

    //*****************************************************************************
    //  Garage & Repair
    //****************************************************************************/
    public async loadGaragesFromOpenReports() {
        this.garagesFromOpenReports = await this.contactPersonService.getContactPeopleFromOpenReports({
            organizationTypes: ['garage'],
            excludedReportIds: [this.report._id],
        });
    }

    public getFullUserContactPerson(): ContactPerson {
        return getFullUserContactPerson({
            user: this.user,
            officeLocations: this.team.officeLocations,
            officeLocationId: this.report.officeLocationId,
        });
    }

    public deactivateRepairOrderIssuedIfDekraFeesUsed() {
        if (this.report.damageCalculation?.repair && this.report.damageCalculation.repair.useDekraFees) {
            this.report.damageCalculation.repair.repairOrderIssued = null;
        }
    }

    //*****************************************************************************
    //  DEKRA Fees
    //****************************************************************************/
    public getZipForDekraFees(): string {
        return (
            this.report.damageCalculation.repair.customDekraFeeZip ||
            this.report.claimant.contactPerson.zip ||
            this.getFullUserContactPerson().zip
        );
    }

    /**
     * Only used to indicate to the user for which place the ZIP belongs that he's using to fetch DEKRA fees.
     */
    public getCityForDekraFees(): string {
        return (
            this.report.damageCalculation.repair.customDekraFeeCity ||
            this.report.claimant.contactPerson.city ||
            this.getFullUserContactPerson().city
        );
    }

    public toggleCustomDekraFeesZipInput(): void {
        this.editDekraFeeZip = !this.editDekraFeeZip;
    }

    public async lookupCityForDekraZip() {
        // Getting the DEKRA fee ZIP code is only necessary for damage reports, not for pure evaluation reports because they do not contain a damage calculation.
        if (!this.report.damageCalculation) {
            return;
        }

        const customZip = this.report.damageCalculation.repair.customDekraFeeZip;

        // No custom ZIP -> nothing to do.
        if (!customZip) {
            this.report.damageCalculation.repair.customDekraFeeCity = null;
            await this.saveReport();
            return;
        }

        // Custom ZIP exists -> Lookup makes sense.
        this.dekraZipToCityLookupPending = true;

        try {
            const cityForZip = await this.addressAutocompletionService.getCityFromZip(customZip);
            this.report.damageCalculation.repair.customDekraFeeCity = cityForZip;
        } catch (error) {
            this.toastService.error(
                'Postleitzahl nicht gefunden',
                'Die angegebene Postleitzahl kann keinem Ort zugeordnet werden. Bitte versuche es mit einer anderen.',
            );
        }
        this.dekraZipToCityLookupPending = false;

        await this.saveReport();
    }

    /**
     * On hitting enter, cose the ZIP input.
     */
    public leaveCustomDekraFeesZipInputOnEnter(keydownEvent: KeyboardEvent) {
        if (keydownEvent.key === 'Enter') {
            const isZipValid: boolean = this.validateCustomDekraFeeZip();

            if (!isZipValid) return;

            this.toggleCustomDekraFeesZipInput();
            this.lookupCityForDekraZip();
        }
    }

    public getDekraFeesZipSourceTooltip(): string {
        if (this.report.damageCalculation.repair.customDekraFeeZip) {
            return `Die manuell gesetzte Postleitzahl "${this.report.damageCalculation.repair.customDekraFeeZip}" wird verwendet.`;
        } else if (this.report.claimant.contactPerson.zip) {
            return `Die Postleitzahl des Anspruchstellers (${this.getZipForDekraFees()}) wird verwendet.`;
        } else {
            return `Deine Postleitzahl (${this.getZipForDekraFees()}) wird verwendet, weil beim Anspruchsteller keine hinterlegt ist.`;
        }
    }

    public validateCustomDekraFeeZip(): boolean {
        this.dekraFeesCustomZipWarnings = [];

        if (!this.report.damageCalculation.repair.customDekraFeeZip) {
            return true;
        }

        if (!/^\d{5}$/.test(this.getZipForDekraFees())) {
            this.dekraFeesCustomZipWarnings.push('Die Postleitzahl muss aus 5 Ziffern bestehen.');
        }

        return this.dekraFeesCustomZipWarnings.length === 0;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DEKRA Fees
    /////////////////////////////////////////////////////////////////////////////*/

    public clearGarage() {
        replaceObjectProperties({
            targetObject: this.report.garage.contactPerson,
            sourceObject: new ContactPerson({
                organizationType: this.report.garage.contactPerson.organizationType,
            }),
            propertiesToKeep: ['_id'],
        });
    }

    //*****************************************************************************
    //  Garage Fees
    //****************************************************************************/
    public getSelectedGarageFeeSet(): GarageFeeSet {
        return getSelectedGarageFeeSet(this.report.garage);
    }

    public selectDefaultGarageFeeSet(garageContactPerson: ContactPerson) {
        this.report.garage.selectedFeeSetId = garageContactPerson.garageFeeSets?.find(
            (feeSet) => feeSet.isDefault,
        )?._id;
    }

    public selectDefaultGarageFeeSetIfEmpty() {
        if (!this.report.garage.selectedFeeSetId) {
            this.selectDefaultGarageFeeSet(this.report.garage.contactPerson);
            this.saveReport();
        }
    }

    public clearSelectedGarageFeeSet() {
        this.report.garage.selectedFeeSetId = null;
    }

    public showGarageFeesDialog(): void {
        // guard against empty organization names
        if (!this.report.garage.contactPerson.organization) {
            this.toastService.info('Bitte gib einen Werkstattnamen an.', '');
            return;
        }

        this.garageFeesDialogShown = true;
    }

    public closeGarageFeesDialog(): void {
        this.garageFeesDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage Fees
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage & Repair
    /////////////////////////////////////////////////////////////////////////////*/

    public selectValuationProvider(provider: Valuation['valuationProvider']): void {
        this.report.valuation.valuationProvider = provider;
    }

    public translateVehicleValueType(
        valueType: ValuationVehicleValueType,
    ): VehicleValueLabelGerman | VehicleValueLabelGermanShort {
        return Translator.vehicleValueLabel(valueType);
    }

    /**
     * Calculate and store the net vehicle value based on its gross counterpart.
     */
    public calculateVehicleValueNetFromGross(): void {
        const vatRate = getVatRateForTaxationType(this.report.valuation.taxationType);
        this.report.valuation.vehicleValueNet =
            Math.round((this.report.valuation.vehicleValueGross / (1 + vatRate)) * 100) / 100;
    }

    public setVehicleValue(vehicleValueSelected: VehicleValueSelected): void {
        setVehicleValue({
            ...vehicleValueSelected,
            report: this.report,
            userPreferences: this.userPreferences,
        });

        this.saveReport();
    }

    /**
     * Shorthand to set the gross vehicle value. Used to insert the replacement value in a liability report.
     * @param valueGross
     */
    public setVehicleValueGross({ vehicleValueGross }: { vehicleValueGross: number }) {
        this.setVehicleValue({
            valueNet: null,
            valueGross: vehicleValueGross,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Market Analysis
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Replacement Value
    //****************************************************************************/
    public reportRequiresReplacementValue(): boolean {
        return Array<Report['type']>('liability', 'shortAssessment', 'fullKasko', 'partialKasko').includes(
            this.report.type,
        );
    }

    public setReplacementValueTaxationType(taxationType: Valuation['taxationType']): void {
        this.report.valuation.taxationType = taxationType;
    }

    public toggleReplacementValueRemark(): void {
        this.replacementValueRemarkShown = !this.replacementValueRemarkShown;
    }

    /**
     * Open a dialog to increase or decrease the replacement value due to repaired or unrepaired damages
     */
    protected openReplacementValueCorrectionDialog(focusBaseValueInput: boolean = false): void {
        this.replacementValueCorrectionsDialogFocusInput = focusBaseValueInput;
        this.replacementValueCorrectionsDialogShown = true;
    }

    /**
     * Sum of the corrections (increases and decreases). Can be positive or negative.
     *
     * This is bad practice because its a function call used within the
     * template, but moving all this code in the template would bloat it.
     * We need to find an alternative for this in the future.
     */
    protected replacementValueCorrectionsSum(): number {
        if (!this.report.valuation.replacementValueCorrectionConfig) {
            // If no corrections exist -> sum is zero
            return 0;
        }

        return (
            this.report.valuation.replacementValueCorrectionConfig.totalIncrease -
            this.report.valuation.replacementValueCorrectionConfig.totalDecrease
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Replacement Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Time Value
    //****************************************************************************/
    /**
     * Whether the report requires a vehicle's time value on the card "Vehicle Value".
     */
    public reportRequiresTimeValue(): boolean {
        return Array<Report['type']>('leaseReturn', 'usedVehicleCheck').includes(this.report.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Time Value
    /////////////////////////////////////////////////////////////////////////////*/

    public toggleResidualValueRemark(): void {
        this.residualValueRemarkShown = !this.residualValueRemarkShown;
    }

    //*****************************************************************************
    //  Diminished Value Calculator
    //****************************************************************************/
    public acceptDiminishedValue(diminishedValue: number): void {
        if (this.isReportLocked()) {
            return;
        }

        this.report.valuation.diminishedValue = diminishedValue;
        this.saveReport();

        this.tutorialStateService.markUserTutorialStepComplete('diminishedMarketValueCalculated');
    }

    public showDiminishedValueCalculator(): void {
        this.diminishedValueCalculatorShown = true;
    }

    public hideDiminishedValueNotification() {
        this.diminishedValueWarningNotificationShown = false;
    }

    protected hideDiminishedValueClaimantTaxationNotification() {
        this.hideDiminishedValueWarningClaimantTaxationNotification = true;

        // Update the taxation type of the diminished value calculation so that the notification does not appear again
        this.report.valuation.diminishedValueCalculation.isBasedOnNetValues =
            !!this.report.claimant.contactPerson.mayDeductTaxes;
        this.saveReport();
    }

    public hideDiminishedValueCalculator(): void {
        this.diminishedValueCalculatorShown = false;
    }

    /**
     * If the user deletes the diminished value, deactivate the diminished value protocol too.
     */
    public deactivateDiminishedValueProtocolIfNecessary() {
        if (!this.report.valuation.diminishedValue) {
            setReportDocumentInclusionStatusByType({
                report: this.report,
                documentGroup: 'report',
                includedInFullDocument: false,
                documentType: 'diminishedValueProtocol',
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Diminished Value Calculator
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Technical Diminished Value
    //****************************************************************************/
    public toggleTechnicalDiminishedValueInput(): void {
        // When hiding, notify the user that the input stays visible if there's a value.

        if (this.report.valuation.technicalDiminishedValue) {
            if (this.userPreferences.technicalDiminishedValueShown) {
                this.toastService.info(
                    'Feld bleibt sichtbar',
                    'Wenn eine technische Wertminderung existiert, wird das Feld unabhängig deiner Einstellungen angezeigt.',
                );
            } else {
                this.toastService.info(
                    'Feld bereits sichtbar',
                    'Wenn eine technische Wertminderung existiert, wird das Feld unabhängig deiner Einstellungen angezeigt.\n\nIn Gutachten ohne technischen Minderwert wird das Feld nun auch immer angezeigt.',
                    { timeOut: 5000 },
                );
            }
        }

        this.userPreferences.technicalDiminishedValueShown = !this.userPreferences.technicalDiminishedValueShown;
    }

    public toggleTechnicalDiminishedValueRemark(): void {
        this.technicalDiminishedValueCommentShown = !this.technicalDiminishedValueCommentShown;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Technical Diminished Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Value Increase
    //****************************************************************************/

    public toggleValueIncreaseInput(): void {
        // When hiding, notify the user that the input stays visible if there's a value.

        if (this.report.valuation.valueIncrease) {
            if (this.userPreferences.valueIncreaseShown) {
                this.toastService.info(
                    'Feld bleibt sichtbar',
                    'Wenn eine Wertverbesserung existiert, wird das Feld unabhängig deiner Einstellungen angezeigt.',
                );
            } else {
                this.toastService.info(
                    'Feld bereits sichtbar',
                    'Wenn eine Wertverbesserung existiert, wird das Feld unabhängig deiner Einstellungen angezeigt.\n\nIn Gutachten ohne Wertverbesserung wird das Feld nun auch immer angezeigt.',
                    { timeOut: 5000 },
                );
            }
        }

        this.userPreferences.valueIncreaseShown = !this.userPreferences.valueIncreaseShown;
    }

    public toggleValueIncreaseRemark(): void {
        this.valueIncreaseRemarkShown = !this.valueIncreaseRemarkShown;
    }

    /**
     * If the input is readonly, tell the user the reason: He has entered a value increase in the calculation. You cannot enter both.
     */
    public handleClickOnValueIncreaseFormField() {
        if (this.report.damageCalculation.repair.increasedValue) {
            this.toastService.info(
                'Wert schreibgeschützt',
                'Die Wertverbesserung stammt aus der Kalkulation. \n\nFalls du sie ändern möchtest, passe sie dort an und importiere die Kalkulation erneut.',
            );
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Value Increase
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Repair Duration
    //****************************************************************************/
    public calculateRepairDuration(): void {
        if (
            !this.report.damageCalculation.repair.garageLaborHours &&
            !this.report.damageCalculation.repair.lacquerLaborHours
        ) {
            return;
        }

        // If the user selected a manual calculation, use autoixpert's selected work fraction unit.
        // For other calculation providers, the server already normalizes the duration to hours.
        let workFractionUnit = 1;
        if (this.report.damageCalculation.repair.calculationProvider === 'manual') {
            workFractionUnit = getSelectedGarageFeeSet(this.report.garage)?.selectedWorkFractionUnit ?? 1;
        }

        const garageLaborHours: number = this.report.damageCalculation.repair.garageLaborHours / workFractionUnit;
        const lacquerLaborHours: number = this.report.damageCalculation.repair.lacquerLaborHours / workFractionUnit;
        const hoursPerWorkday = this.getHoursInRepairWorkday();
        const { minDays, maxDays } = calculateRepairDuration({
            garageLaborDays: garageLaborHours / hoursPerWorkday,
            lacquerLaborDays: lacquerLaborHours / hoursPerWorkday,
        });

        if (minDays === maxDays) {
            this.report.damageCalculation.downtimeInWorkdaysDueToReparation = maxDays + '';
        } else {
            this.report.damageCalculation.downtimeInWorkdaysDueToReparation = `${minDays}-${maxDays}`;
        }
        this.saveReport();
    }

    public getRepairDurationTooltip(): string {
        // If the user selected a manual calculation, use autoixpert's selected work fraction unit.
        // For other calculation providers, the server already normalizes the duration to houors.
        let workFractionUnit = 1;
        if (this.report.damageCalculation.repair.calculationProvider === 'manual') {
            workFractionUnit = getSelectedGarageFeeSet(this.report.garage)?.selectedWorkFractionUnit ?? 1;
        }

        const hoursPerWorkday = this.getHoursInRepairWorkday();

        const garageLaborHours: number = this.report.damageCalculation.repair.garageLaborHours / workFractionUnit;
        const lacquerLaborHours: number = this.report.damageCalculation.repair.lacquerLaborHours / workFractionUnit;

        const garageLaborHoursFormatted: string = formatNumber(garageLaborHours, 'de', '1.1-1');
        const garageLaborDays: number = garageLaborHours / hoursPerWorkday;
        const garageLaborDaysFormatted: string = formatNumber(garageLaborDays, 'de', '1.1-1');
        let tooltip = `Reparaturdauer ermitteln (${hoursPerWorkday}h-Tage)\n\nWerkstatt: ${garageLaborHoursFormatted} h (${garageLaborDaysFormatted} ${
            garageLaborDays === 1 ? 'Tag' : 'Tage'
        })`;

        if (this.report.damageCalculation.repair.lacquerLaborHours) {
            const lacquerLaborHoursFormatted: string = formatNumber(lacquerLaborHours, 'de', '1.1-1');
            const lacquerLaborDays: number = lacquerLaborHours / hoursPerWorkday;
            const lacquerLaborDaysFormatted: string = formatNumber(lacquerLaborDays, 'de', '1.1-1');

            tooltip += `\nLackiererei: ${lacquerLaborHoursFormatted} h (${lacquerLaborDaysFormatted} ${
                lacquerLaborDays === 1 ? 'Tag' : 'Tage'
            })

             Beim Klick werden Arbeitstage für Werkstatt & Lackierbetrieb einzeln ermittelt, dann aufsummiert und eingefügt.`;
        }

        return tooltip;
    }

    public durationFormatValid(durationInput: string): boolean {
        const durationRegex = /^\d+(-\d+)?$/;

        return durationRegex.test(durationInput);
    }

    public checkRepairDurationFormat(): void {
        this.repairDurationFormatInvalid =
            this.report.damageCalculation?.downtimeInWorkdaysDueToReparation &&
            !this.durationFormatValid(this.report.damageCalculation.downtimeInWorkdaysDueToReparation);
    }

    public checkReplacementTimeFormat(): void {
        this.replacementTimeFormatInvalid =
            this.report.damageCalculation?.replacementTimeInWorkdays &&
            !this.durationFormatValid(this.report.damageCalculation.replacementTimeInWorkdays);
    }

    public setHoursInRepairWorkday(days: number) {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        this.team.preferences.hoursInRepairWorkday = days;
        this.saveTeam();

        // If the downtime has already been calculated, re-calculate it with the new setting.
        if (this.report.damageCalculation.downtimeInWorkdaysDueToReparation) {
            this.calculateRepairDuration();
        }
    }

    public getHoursInRepairWorkday() {
        return this.team.preferences.hoursInRepairWorkday;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Repair Duration
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Repair Instruction
    //****************************************************************************/
    public generateRepairInstructionsFromCalculation() {
        if (this.report.damageCalculation?.repair?.calculationProvider === 'gtmotive') {
            this.toastService.error(
                'Für GTmotive nicht verfügbar',
                'Der Reparaturweg kann nur aus einer Audatex- oder DAT-Kalkulation befüllt werden.',
            );
            return;
        }

        if (
            this.report.damageCalculation?.repair?.calculationProvider !== 'audatex' &&
            this.report.damageCalculation?.repair?.calculationProvider !== 'dat'
        ) {
            this.toastService.error(
                'Nur mit Audatex- oder DAT-Kalkulation',
                'Der Reparaturweg kann nur aus einer Audatex- oder DAT-Kalkulation befüllt werden. Erstelle eine Kalkulation.',
            );
            return;
        }

        if (!this.report.damageCalculation?.repair?.damagedParts?.length) {
            this.toastService.error(
                'Keine Teile verfügbar',
                'Stelle sicher, dass die Kalkulation Teile und Arbeitspositionen enthält und importiere die Kalkulation erneut.',
            );
            return;
        }

        this.report.damageCalculation.repair.repairInstructions =
            (this.report.damageCalculation.repair.repairInstructions || '') +
            generateRepairInstructionsFromCalculation({
                userPreferences: this.userPreferences,
                damagedParts: this.report.damageCalculation.repair.damagedParts,
            });
        this.saveReport();
    }

    public async openRepairInstructionsFromCalculationConfigurationDialog() {
        const dialogRef = this.dialog.open(DescriptionFromRepairCalculationDialogComponent, {
            panelClass: 'dialog-without-padding',
            data: {
                damagedParts: this.report.damageCalculation.repair.damagedParts,
                report: this.report,
                context: 'repairInstructionsFromRepairParts',
            },
        });
        const dialogResult: DescriptionFromRepairCalculationDialogResult = await dialogRef.afterClosed().toPromise();
        if (dialogResult?.generatedText) {
            this.report.damageCalculation.repair.repairInstructions =
                (this.report.damageCalculation.repair.repairInstructions || '') + dialogResult.generatedText;
        }
        this.saveReport();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Repair Instruction
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Downtime Compensation
    //****************************************************************************/
    public toggleProvisioningCosts(): void {
        if (this.report.damageCalculation.useProvisioningCosts) {
            // Switch away from provisioning costs to downtime costs
            this.report.damageCalculation.provisioningCosts = null;
        } else {
            // Switch away from downtime compensation costs to provisioning costs
            this.report.damageCalculation.downtimeCompensationGroup = null;
            this.report.damageCalculation.downtimeCompensationPerWorkday = null;
            this.report.damageCalculation.rentalCarClass = null;
        }
        this.report.damageCalculation.useProvisioningCosts = !this.report.damageCalculation.useProvisioningCosts;
    }

    public toggleRentalCarCostsShown(): void {
        this.userPreferences.showRentalCarCosts = !this.userPreferences.showRentalCarCosts;
    }

    public requestingDowntimeCompensationPricesAllowed(): boolean {
        if (!this.report.car.firstRegistration) {
            return false;
        }

        switch (this.report.car.identificationProvider) {
            case 'audatex':
                return !!this.report.audatexTaskId;
            case 'dat':
                if (this.report.car.datIdentification.datEuropaCode && this.report.car.datIdentification.marketIndex) {
                    return true;
                }
                return !!this.report.car.vin;
            case 'gtmotive':
                return !!this.report.car.vin;
        }
    }

    public getRentalPricesIconTooltip(): string {
        if (this.report.car.identificationProvider === 'audatex') {
            if (!this.report.car.firstRegistration || !this.report.car.vin) {
                return 'Die Erstzulassung und die VIN müssen definiert sein, um Mietwagenpreise abzurufen.';
            }
            if (!this.report.audatexTaskId) {
                return 'Bitte lege zuerst einen Audatex-Vorgang an, z. B. durch die VIN-Abfrage oder das Verknüpfen einer Kalkulation.';
            }
            return 'Nutzungsausfallgruppe & Nutzungsausfall pro Tag mit Audatex ermitteln';
        } else {
            if (
                !this.report.car.firstRegistration ||
                !(this.report.car.datIdentification.datEuropaCode || this.report.car.vin)
            ) {
                return 'Die Erstzulassung und entweder der DAT€Code inkl. Marktindex oder die VIN müssen definiert sein, um Mietwagenpreise abzurufen.';
            } else if (
                !this.report.car.vin &&
                this.report.car.datIdentification.datEuropaCode &&
                !this.report.car.datIdentification.marketIndex
            ) {
                return 'Der DAT€Code und der Marktindex müssen definiert sein, um Mietwagenpreise abzurufen.';
            }
            return 'Nutzungsausfallgruppe & Nutzungsausfall pro Tag anhand des DAT-Mietwagenspiegels ermitteln';
        }
    }

    /**
     * Depending on the selected downtime compensation group and the identification provider, set the monetary value.
     * @param downtimeCompensationGroupName
     */
    public setDowntimeCompensationPerWorkday(downtimeCompensationGroupName: string): void {
        if (!downtimeCompensationGroupName) {
            this.report.damageCalculation.downtimeCompensationPerWorkday = null;
            return;
        }

        const downtimeCompensationGroupMappings = [
            ...this.downtimeCompensationGroupsForCarsEurotax,
            ...this.downtimeCompensationGroupsForMotorcyclesEurotax,
        ];
        this.report.damageCalculation.downtimeCompensationPerWorkday = downtimeCompensationGroupMappings.find(
            (downtimeCompensationGroupMapping) => {
                return downtimeCompensationGroupMapping.groupName === downtimeCompensationGroupName;
            },
        )?.compensationPerDay;
    }

    public getDowntimeCompensation(): void {
        if (this.report.car.identificationProvider === 'audatex') {
            this.getAudatexDowntimeCompensation();
        } else {
            this.getDatDowntimeCompensation();
        }
    }

    private async getDatDowntimeCompensation() {
        if (!this.requestingDowntimeCompensationPricesAllowed()) {
            this.toastService.info(this.getRentalPricesIconTooltip());
            return;
        }

        // Since the DAT downtime compensation database is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der DAT-Mietwagenspiegel ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.downtimeCompensationRequestPending = true;
        const datJwt = await this.datAuthenticationService.getJwt();

        let downtimeCompensationResponse: DatDowntimeCompensationResponse;
        try {
            downtimeCompensationResponse = await this.httpClient
                .get<DatDowntimeCompensationResponse>(`/api/v0/reports/${this.report._id}/datDowntimeCompensation`, {
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                })
                .toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDatErrorHandlers(),
                    FIRST_REGISTRATION_REQUIRED: {
                        title: 'Erstzulassung benötigt',
                        body: 'Bitte prüfe die Erstzulassung in der Fahrzeugauswahl.',
                    },
                    DAT_FIRST_REGISTRATION_IN_THE_FUTURE: {
                        title: 'Erstzulassung zu weit in Zukunft',
                        body: 'Laut DAT liegt die Erstzulassung für das gewählte Modell zu weit in der Zukunft.',
                    },
                    DAT_FIRST_REGISTRATION_TOO_FAR_IN_THE_PAST: {
                        title: 'Erstzulassung zu weit in Vergangenheit',
                        body: 'Laut DAT liegt die Erstzulassung für das gewählte Modell zu weit in der Vergangenheit. Es sind keine Mietwagenpreise verfügbar.',
                    },
                    DAT_MISSING_DOWNTIME_COMPENSATION_ACCESS: {
                        title: 'Mietwagenspiegel-Lizenz fehlt',
                        body: 'Das ist ein Problem mit deinem DAT-Account, kann aber meist schnell nachgebucht werden.\n\nBitte kontaktiere den <a href="https://www.dat.de/unternehmen/kontakt/" target="_blank" rel="noopener">DAT-Vertrieb</a>.',
                    },
                },
                defaultHandler: (error) => {
                    console.error('Error retrieving the downtime compensation data.', { error });
                    return {
                        title: 'Nutzungsausfalldaten nicht geladen',
                        body:
                            (error.data && error.data.datErrorMessage
                                ? `Fehlermeldung der DAT: ${error.data.datErrorMessage}<br><br>`
                                : '') +
                            'Wende dich bei weiteren Fragen gerne an die <a href="/Hilfe" target="_blank">autoiXpert Hotline</a>.',
                    };
                },
            });
        } finally {
            this.downtimeCompensationRequestPending = false;
        }
        this.report.damageCalculation.downtimeCompensationGroup =
            downtimeCompensationResponse.downtimeCompensationGroup;
        this.report.damageCalculation.originalDowntimeCompensationGroup =
            downtimeCompensationResponse.originalDowntimeCompensationGroup;
        this.report.damageCalculation.downtimeCompensationPerWorkday =
            downtimeCompensationResponse.downtimeCompensationPerWorkday;
        this.report.damageCalculation.rentalCarClass = downtimeCompensationResponse.rentalCarClass;

        // If data is incomplete, tell the user about it.
        if (
            !downtimeCompensationResponse.downtimeCompensationGroup ||
            !downtimeCompensationResponse.downtimeCompensationPerWorkday ||
            !downtimeCompensationResponse.rentalCarClass
        ) {
            this.toastService.warn(
                'Daten unvollständig',
                'Der DAT-Mietwagenspiegel hat keine vollständigen Daten für dieses Fahrzeug.',
            );
        }

        await this.saveReport();

        this.downtimeCompensationRequestPending = false;
    }

    private async getAudatexDowntimeCompensation(): Promise<void> {
        if (!this.requestingDowntimeCompensationPricesAllowed()) {
            this.toastService.info(this.getRentalPricesIconTooltip());
            return;
        }

        // Since the Audatex downtime compensation is an online-only feature, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Mietwagenpreise von Audatex sind verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.downtimeCompensationRequestPending = true;
        try {
            const downtimeCompensationResponse = await this.audatexTaskService.getDowntimeCompensation(this.report._id);
            Object.assign<DamageCalculation, Partial<AudatexDowntimeCompensation>>(this.report.damageCalculation, {
                downtimeCompensationGroup: downtimeCompensationResponse.downtimeCompensationGroup,
                downtimeCompensationPerWorkday: downtimeCompensationResponse.downtimeCompensationPerWorkday,
                originalDowntimeCompensationGroup: downtimeCompensationResponse.originalDowntimeCompensationGroup,
                numberOfDowntimeCompensationClassReductions:
                    downtimeCompensationResponse.numberOfDowntimeCompensationClassReductions,
                rentalCarClass: downtimeCompensationResponse.rentalCarClass,
            });

            // If data is incomplete, tell the user about it.
            if (
                !downtimeCompensationResponse.downtimeCompensationPerWorkday &&
                !downtimeCompensationResponse.downtimeCompensationGroup &&
                !downtimeCompensationResponse.rentalCarClass
            ) {
                if (downtimeCompensationResponse.vinQueriedThroughBreClientUserInterface) {
                    this.toastService.error(
                        'Keine Daten bei Audatex',
                        'Audatex kennt für dieses Fahrzeug keine Nutzungsausfalldaten. Du kannst aber manuell eine Ausfallgruppe auswählen.',
                    );
                } else {
                    const toast = this.toastService.error(
                        'VIN-Abfrage in Qapter notwendig',
                        'Qapter gibt die Kosten pro Tag & die Mietwagenklasse leider nur zurück, nachdem du eine VIN-Abfrage in der Qapter-Oberfläche gemacht hast.<br><br>Klicke, um Qapter zu öffnen.',
                    );
                    const subscription = toast.click.subscribe({
                        next: () => {
                            subscription.unsubscribe();
                            this.newWindowService.open(
                                `https://www.audanet.de/axnlogin/opentask.do` +
                                    `?task=${this.report.audatexTaskId}` +
                                    `&username=${this.user.audatexUser?.username}` +
                                    `&password=${encodeURIComponent(this.user.audatexUser?.password || '')}` +
                                    `&work=CompleteAssessment` +
                                    `&usenewui=true` +
                                    `&step=VehicleIdentification`,
                                '_blank',
                                'noopener',
                            );
                        },
                    });
                }
            }
            // Some data is present, but the rental car class is missing.
            else if (!downtimeCompensationResponse.rentalCarClass) {
                this.toastService.warn(
                    'Keine Mietwagenklasse',
                    'Qapter kennt für dieses Fahrzeug keine Mietwagenklasse. Wenn du sie benötigst, solltest du die Daten mit einer anderen Quelle recherchieren.',
                );
            }

            this.saveReport();

            this.downtimeCompensationRequestPending = false;
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getAudatexErrorHandlers(),
                    FIRST_REGISTRATION_REQUIRED: {
                        title: 'Erstzulassung benötigt',
                        body: 'Bitte prüfe die Erstzulassung in der Fahrzeugauswahl.',
                    },
                    DOWNTIME_COMPENSATION_GROUP_MISSING: {
                        title: 'Nutzungsausfall unbekannt',
                        body: 'Manchmal hilft es, direkt in Qapter eine erneute KBA-Abfrage auszuführen.',
                        partnerLogo: 'audatex',
                    },
                },
                defaultHandler: {
                    title: 'Nutzungsausfalldaten nicht geladen',
                    body: 'Wende dich bei weiteren Fragen gerne an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        } finally {
            this.downtimeCompensationRequestPending = false;
        }
    }

    public getOriginalDowntimeCompensationGroupTooltip(): string {
        const tooltipParts: string[] = ['Originale Ausfallgruppe'];

        if (this.report.damageCalculation.numberOfDowntimeCompensationClassReductions) {
            tooltipParts.push(
                `Wegen des Fahrzeugalters reduziert um ${this.report.damageCalculation.numberOfDowntimeCompensationClassReductions} Stufen`,
            );
        } else if (this.report.car.identificationProvider === 'audatex') {
            tooltipParts.push(
                `\nHast du das Fahrzeug über die VIN-Abfrage in der Qapter-Oberfläche identifiziert, hat Audatex den Abzug der altersbedingten Klassen schon selbst vorgenommen. Die Audatex-Schnittstelle gibt dann die originale und die reduzierte Klasse gleich zurück.`,
            );
        }

        return tooltipParts.join('. ');
    }

    public getRentalCarClassTooltip(rentalCarClassId: number): string {
        if (rentalCarClassId >= 90) {
            return 'Mietwagenklassen ab 90 sind Sonderklassen und werden ohne Ordnungszahl abgedruckt.';
        }
        // Classes 21 - 31 are transporter classes without any special labels
        if (rentalCarClassId > 20 && rentalCarClassId <= 31) {
            return 'Die DAT unterscheidet in der Nomenklatur nicht zwischen den Transporterklassen.';
        }
        return '';
    }

    public toggleDowntimeCompensationRemark(): void {
        this.downtimeCompensationCommentShown = !this.downtimeCompensationCommentShown;
    }

    public focusDowntimeCompensationRemark(): void {
        setTimeout(() => {
            if (this.downtimeCompensationRemark) {
                this.downtimeCompensationRemark.quillInstance.focus();
            }
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Downtime Compensation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Special Costs
    //****************************************************************************/
    public showSpecialCostsDialog(): void {
        this.specialCostsDialogShown = true;
    }

    public hideSpecialCostsDialog(): void {
        this.specialCostsDialogShown = false;
    }

    public closeSpecialCostNotification(): void {
        this.user.userInterfaceStates.hideSpecialCostsNotification = true;
        this.saveUser();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Special Costs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Value Summary Graph
    //****************************************************************************/

    public getReplacementValueBarWidth() {
        return (this.applicableReplacementValue / this.getHighestValueInValueSummaryGraph()) * 100;
    }

    public getRestorationValueBarWidth() {
        return (this.restorationValue / this.getHighestValueInValueSummaryGraph()) * 100;
    }

    public getCorrectedRepairCostsGrossBarWidth() {
        return (this.applicableRepairCosts / this.getHighestValueInValueSummaryGraph()) * 100;
    }

    public getResidualValueBarWidth() {
        return (this.applicableResidualValue / this.getHighestValueInValueSummaryGraph()) * 100;
    }

    public getHighestValueInValueSummaryGraph(): number {
        const propertiesToDisplay: number[] = [
            this.applicableReplacementValue,
            this.applicableResidualValue,
            this.restorationValue,
        ];
        return Math.max(...propertiesToDisplay);
    }

    public getGraphTooltipReplacementValue(): string {
        return mayCarOwnerDeductTaxes(this.report)
            ? 'Wiederbeschaffungswert (netto)'
            : 'Wiederbeschaffungswert (brutto)';
    }

    public getGraphTooltipRepairCostsPlusDiminishedValue(): string {
        let tooltip = this.getGraphTooltipRepairCosts();

        // Include the info about the value increase only if necessary since its occurrence is an exception.
        if (this.report.valuation.diminishedValue || this.report.valuation.technicalDiminishedValue) {
            tooltip += ' + Minderwert';
        }
        return tooltip;
    }

    public getGraphTooltipRepairCosts(): string {
        const repairCosts = mayCarOwnerDeductTaxes(this.report)
            ? 'Reparaturkosten (netto)'
            : 'Reparaturkosten (brutto)';

        if (this.report.damageCalculation.repair.newForOldNet) {
            return `${repairCosts} ohne Wertverbesserung`;
        }
        return `${repairCosts}`;
    }

    /**
     * This tooltip should be added to each tooltip on the ratio of repair costs and the replacement value.
     */
    public getGraphTooltipAdditionAboutRepairCostRatio(): string {
        return mayCarOwnerDeductTaxes(this.report)
            ? 'Weil der Anspruchsteller zum Vorsteuerabzug berechtigt ist, wurden Netto- mit Nettowerten in Relation gesetzt. Es können sich andere Verhältnisse als beim Privatmann ergeben, falls der WBW differenzbesteuert oder steuerneutral ist. Für mehr Infos klicken.'
            : 'Weil der Anspruchsteller nicht zum Vorsteuerabzug berechtigt ist, wurden Brutto- mit Bruttowerten in Relation gesetzt. Für mehr Infos klicken.';
    }

    public getGraphTooltipResidualValue(): string {
        return mayCarOwnerDeductTaxes(this.report) ? 'Restwert (netto)' : 'Restwert (brutto)';
    }

    public mayCarOwnerDeductTaxes() {
        return mayCarOwnerDeductTaxes(this.report);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Value Summary Graph
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Damage Class
    //****************************************************************************/
    public getTooltipForInclude130PercentRuleInAutomaticSelection(): string {
        let mainTooltip: string =
            'Soll der Reparaturschaden auf 130 % automatisch gesetzt werden, wenn die Reparaturkosten zwischen 100% und 130% des WBW liegen?';

        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            mainTooltip += `\n\nÄnderbar mit Zugriffsrecht ${translateAccessRightToGerman(
                'editTextsAndDocumentBuildingBlocks',
            )}.`;
        }

        return mainTooltip;
    }

    public getTooltipForIncludeEconomicTotalLossInAutomaticSelection(): string {
        let mainTooltip: string =
            'Soll der wirtschaftliche Totalschaden automatisch gesetzt werden, wenn gilt: RK > WBW - RW ?';

        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            mainTooltip += `\n\nÄnderbar mit Zugriffsrecht ${translateAccessRightToGerman(
                'editTextsAndDocumentBuildingBlocks',
            )}.`;
        }

        return mainTooltip;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damage Class
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Repair
    //****************************************************************************/
    private showAxisMeasurementComment(): void {
        if (this.report.damageCalculation?.repair.axisMeasurementComment) {
            this.axisMeasurementCommentShown = true;
        }
    }

    private showCarBodyMeasurementComment(): void {
        if (this.report.damageCalculation?.repair.carBodyMeasurementComment) {
            this.carBodyMeasurementCommentShown = true;
        }
    }

    private showHighVoltageSystemCheckComment(): void {
        if (this.report.damageCalculation?.repair.highVoltageSystemCheckComment) {
            this.highVoltageSystemCheckCommentShown = true;
        }
    }

    private showRepairRisksComment(): void {
        if (this.report.damageCalculation?.repair.repairRisksComment) {
            this.repairRisksCommentShown = true;
        }
    }

    public toggleAxisMeasurementRemark(): void {
        this.axisMeasurementCommentShown = !this.axisMeasurementCommentShown;
    }

    public toggleCarBodyMeasurementRemark(): void {
        this.carBodyMeasurementCommentShown = !this.carBodyMeasurementCommentShown;
    }

    public toggleHighVoltageSystemCheckRemark(): void {
        this.highVoltageSystemCheckCommentShown = !this.highVoltageSystemCheckCommentShown;
    }

    public toggleRepairRisksRemark(): void {
        this.repairRisksCommentShown = !this.repairRisksCommentShown;
    }

    //*****************************************************************************
    //  Repair Risks
    //****************************************************************************/
    /**
     * On blur or hitting certain keys (enter, comma, space), trigger adding a device as a chip.
     *
     * @param chipInputEvent
     */
    public enterRepairRisk(chipInputEvent: MatChipInputEvent) {
        if (this.repairRisksAutocomplete.isOpen) {
            return;
        }

        const inputValue = (chipInputEvent.value || '').trim();

        this.addRepairRisk(inputValue);

        // Clear input
        this.clearInputAndResetAutocomplete(chipInputEvent.chipInput.inputElement);
    }

    /**
     * Add a device as a chip unless the same device has already been added.
     *
     * @param repairRisk
     */
    public addRepairRisk(repairRisk: string): void {
        // Don't add duplicates
        if (this.report.damageCalculation.repair.repairRisks.includes(repairRisk)) {
            this.toastService.info(`Wert '${repairRisk}' bereits vorhanden`);
            return;
        }

        // Add chip if non-empty
        if (repairRisk) {
            this.report.damageCalculation.repair.repairRisks.push(repairRisk);
        }
    }

    public removeRepairRisk(repairRisk: string): void {
        this.report.damageCalculation.repair.repairRisks.splice(
            this.report.damageCalculation.repair.repairRisks.indexOf(repairRisk),
            1,
        );
    }

    public selectRepairRiskFromAutocomplete(
        event: MatAutocompleteSelectedEvent,
        inputElement: HTMLInputElement,
        autocompleteTrigger: MatAutocompleteTrigger,
    ): void {
        this.addRepairRisk(event.option.value);
        this.clearInputAndResetAutocomplete(inputElement);
        setTimeout(() => {
            autocompleteTrigger.openPanel();
        }, 0);
    }

    public retrieveCustomRiskAutocompleteEntry() {
        this.customAutocompleteEntriesService.find({ type: 'repairRisks' }).subscribe({
            next: (entries) => {
                this.repairRisksAutocompleteEntries = entries;
            },
        });
    }

    public customRepairRiskAutocompleteEntryExists(entry: string): boolean {
        return !!this.repairRisksAutocompleteEntries.find((existingEntry) => existingEntry.value === entry);
    }

    public async rememberCustomRiskAutocompleteEntry(risks: Repair['repairRisks']) {
        const newEntries = risks.filter((risk) => !this.customRepairRiskAutocompleteEntryExists(risk));

        if (!risks.length) {
            this.toastService.warn('Keine neuen Einträge', 'Es konnten keine neuen Risiken gespeichert werden.');
            return;
        }

        for (const risk of newEntries) {
            const newAutocompleteEntry = new CustomAutocompleteEntry({
                type: 'repairRisks',
                value: risk,
            });

            try {
                await this.customAutocompleteEntriesService.create(newAutocompleteEntry);
                this.repairRisksAutocompleteEntries.push(newAutocompleteEntry);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Autocomplete-Wert nicht gespeichert',
                        body: "Der Wert für das Autocomplete-Feld konnte nicht gespeichert werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }

            this.toastService.success(`Risiko "${risk}" gemerkt`, 'Es wurde der Vorschlagsliste hinzugefügt.');
        }
    }

    public async removeCustomRiskAutocompleteEntry(riskEntry: string) {
        const entryToBeRemoved = this.repairRisksAutocompleteEntries.find((entry) => entry.value === riskEntry);

        try {
            await this.customAutocompleteEntriesService.delete(entryToBeRemoved._id);
            this.repairRisksAutocompleteEntries.splice(
                this.repairRisksAutocompleteEntries.indexOf(entryToBeRemoved),
                1,
            );
            this.filteredRepairRisks.splice(this.filteredRepairRisks.indexOf(riskEntry), 1);
            this.removeRepairRisk(riskEntry);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Autocomplete-Wert nicht gelöscht',
                    body: "Der Wert für das Autocomplete-Feld konnte nicht gelöscht werden. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public isCustomRepairRiskEntry(input: string): boolean {
        return this.repairRisksAutocompleteEntries.some((entry) => entry.value === input);
    }

    /**
     * Reduce the entries of the autocomplete for auxiliary devices
     * to the ones containing the search term.
     *
     * @param searchTerm
     */
    public filterRepairRiskAutocomplete(searchTerm: string) {
        const inputValue = (searchTerm || '').trim().toLowerCase();

        this.filteredRepairRisks = [];

        const repairRisksAutocompleteEntriesToString = this.repairRisksAutocompleteEntries.map((entry) => entry.value);

        this.filteredRepairRisks.push(...this.repairRisks, ...repairRisksAutocompleteEntriesToString);

        // Shown entries = Not yet selected && matching search term
        this.filteredRepairRisks = this.filteredRepairRisks.filter(
            (device) =>
                !(this.report.damageCalculation.repair.repairRisks ?? []).includes(device) &&
                device.toLowerCase().includes(inputValue),
        );
    }

    public clearInputAndResetAutocomplete(input: HTMLInputElement): void {
        input.value = '';
        this.filterRepairRiskAutocomplete('');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Repair Risks
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Repair
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        let currentTab: ReportTabName;
        switch (this.report.type) {
            case 'valuation':
            case 'oldtimerValuationSmall':
                currentTab = 'valuation';
                break;
            default:
                currentTab = 'damageCalculation';
        }

        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/

    public openHelpcenterArticleOnDamageClass() {
        this.newWindowService.open('https://wissen.autoixpert.de/hc/de/articles/13546263425426', '_blank', 'noopener');
    }

    public openWindow(url: string) {
        this.newWindowService.open(url);
    }

    public isKaskoCase(): boolean {
        return isKaskoCase(this.report.type);
    }

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    public isAmendmentReport(): boolean {
        return isAmendmentReport(this.report);
    }

    public focusReplacementValueRemark(): void {
        setTimeout(() => {
            if (this.replacementValueRemark) {
                this.replacementValueRemark.quillInstance.focus();
            }
        }, 0);
    }

    public focusResidualValueRemark(): void {
        setTimeout(() => {
            if (this.residualValueRemark) {
                this.residualValueRemark.quillInstance.focus();
            }
        }, 0);
    }

    public focusTechnicalDiminishedValueRemark(): void {
        setTimeout(() => {
            if (this.technicalDiminishedValueRemark) {
                this.technicalDiminishedValueRemark.quillInstance.focus();
            }
        }, 0);
    }

    public focusValueIncreaseRemark(): void {
        setTimeout(() => {
            if (this.valueIncreaseRemark) {
                this.valueIncreaseRemark.quillInstance.focus();
            }
        }, 0);
    }

    public focusAxisMeasurementRemark(): void {
        setTimeout(() => {
            if (this.axisMeasurementRemark) {
                this.axisMeasurementRemark.quillInstance.focus();
            }
        }, 0);
    }

    public focusCarBodyMeasurementRemark(): void {
        setTimeout(() => {
            if (this.carBodyMeasurementRemark) {
                this.carBodyMeasurementRemark.quillInstance.focus();
            }
        }, 0);
    }

    public focusHighVoltageSystemCheckRemark(): void {
        setTimeout(() => {
            if (this.highVoltageSystemCheckRemark) {
                this.highVoltageSystemCheckRemark.quillInstance.focus();
            }
        }, 0);
    }

    public focusRepairRisksRemark(): void {
        setTimeout(() => {
            if (this.repairRisksRemark) {
                this.repairRisksRemark.quillInstance.focus();
            }
        }, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save to the server
    //****************************************************************************/

    /**
     * Save reports to the server.
     */
    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        if (this.isReportLocked()) {
            return;
        }

        return this.reportDetailsService.patch(this.report, { waitForServer }).catch((error) => {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
            throw error;
        });
    }

    public saveTeam(): Promise<Team> {
        return this.teamService.put(this.team);
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save to the server
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    protected readonly iconForCarBrandExists = iconForCarBrandExists;
    protected readonly iconFilePathForCarBrand = iconFilePathForCarBrand;
    protected readonly isRemappingRequiredForAudatexWageRates = isRemappingRequiredForAudatexWageRates;
    protected readonly determineDamageType = determineDamageType;
    protected readonly isAfzzertUserComplete = isAfzzertUserComplete;
    protected readonly getValuationPriceLabelTooltip = getValuationPriceLabelTooltip;
    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
    protected readonly isAudatexUserComplete = isAudatexUserComplete;
}

export interface VehicleValueSelected {
    valueType?: Valuation['vehicleValueType'];
    taxationType?: Valuation['taxationType'];
    valuationProvider?: Valuation['valuationProvider'];
    valueNet: number;
    valueGross: number;
}

import { setPaymentStatus } from '@autoixpert/lib/invoices/set-payment-status';
import { ObjectStoreAndIndexMigrations, RecordMigrations } from '@autoixpert/models/indexed-db/database.types';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { ReportData } from '@autoixpert/models/invoices/report-data';
import { OutgoingEmailMessage } from '@autoixpert/models/outgoing-message';

export const invoiceServiceObjectStoreAndIndexMigrations: ObjectStoreAndIndexMigrations<Invoice> = {};

export const invoiceRecordMigrations: RecordMigrations<Invoice> = {
    //*****************************************************************************
    //  Remove isPaid, add hasOutstandingPayments
    //****************************************************************************/
    '7': (invoice: Invoice) => {
        delete (invoice as any).isPaid;

        setPaymentStatus(invoice);
        invoice._documentVersion++;

        return invoice;
    },
    /**
     * Remove all sentDocumentEmails and sentStandardEmails from the invoice object (they live in a dedicated outgoingMessages collection now).
     * This is the frontend equivalent for the backend migration_20250310_1400_migrate_sent_emails_to_outgoing_messages.
     */
    '8': (invoice: Invoice) => {
        if (invoice.claimant) {
            invoice.claimant.outgoingMessageDraft = new OutgoingEmailMessage();
            delete (invoice.claimant as any).documentEmail;
            delete (invoice.claimant as any).standardEmail;
            delete (invoice.claimant as any).sentDocumentEmails;
            delete (invoice.claimant as any).sentStandardEmails;
        }

        if (invoice.recipient) {
            invoice.recipient.outgoingMessageDraft = new OutgoingEmailMessage();
            delete (invoice.recipient as any).documentEmail;
            delete (invoice.recipient as any).standardEmail;
            delete (invoice.recipient as any).sentDocumentEmails;
            delete (invoice.recipient as any).sentStandardEmails;
        }

        if (invoice.insurance) {
            invoice.insurance.outgoingMessageDraft = new OutgoingEmailMessage();
            delete (invoice.insurance as any).documentEmail;
            delete (invoice.insurance as any).standardEmail;
            delete (invoice.insurance as any).sentDocumentEmails;
            delete (invoice.insurance as any).sentStandardEmails;
        }

        if (invoice.lawyer) {
            invoice.lawyer.outgoingMessageDraft = new OutgoingEmailMessage();
            delete (invoice.lawyer as any).documentEmail;
            delete (invoice.lawyer as any).standardEmail;
            delete (invoice.lawyer as any).sentDocumentEmails;
            delete (invoice.lawyer as any).sentStandardEmails;
        }

        if (invoice.factoringProvider) {
            invoice.factoringProvider.outgoingMessageDraft = new OutgoingEmailMessage();
            delete (invoice.factoringProvider as any).documentEmail;
            delete (invoice.factoringProvider as any).standardEmail;
            delete (invoice.factoringProvider as any).sentDocumentEmails;
            delete (invoice.factoringProvider as any).sentStandardEmails;
        }

        invoice._documentVersion++;
        return invoice;
    },

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove isPaid, add hasOutstandingPayments
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Change reportId and reportData to an array of report IDs / report datas (backend migrations: migration_20250227_160000_changeReportIdOnInvoicesToArray
    //  and migration_20250311_080000_changeReportDataOnInvoicesToArray)
    //****************************************************************************/
    '9': (invoice: Invoice & { reportId: string; reportData: ReportData }) => {
        // reportId from string -> array
        if (invoice.reportId) {
            invoice.reportIds = [invoice.reportId];
        } else {
            invoice.reportIds = [];
        }

        delete invoice.reportId;

        // reportData from object -> array of objects
        if (invoice.reportData && invoice.reportData.type) {
            invoice.reportsData = [invoice.reportData];
        } else {
            invoice.reportsData = [];
        }

        delete invoice.reportData;

        invoice._documentVersion++;

        return invoice;
    },
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Change reportId to an array of report IDs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Remove markAsDuplicate from all documents
    //****************************************************************************/
    '10': (invoice: Invoice) => {
        if (invoice.documents?.length) {
            for (const document of invoice.documents) {
                delete (document as any).markAsDuplicate;
            }
        }

        invoice._documentVersion++;

        return invoice;
    },

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove markAsDuplicate from all documents
    /////////////////////////////////////////////////////////////////////////////*/
};

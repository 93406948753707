import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CopyPhotosToReportService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public copyPhotos({
        sourceReportId,
        targetReportId,
        sourceAndTargetPhotoIds,
    }: {
        sourceReportId: string;
        targetReportId: string;
        sourceAndTargetPhotoIds: PhotoCopyMap[];
    }): Promise<PhotoCopyResponse> {
        return this.httpClient
            .post<PhotoCopyResponse>(`${this.apiPath}/reports/${sourceReportId}/photoCopy`, {
                targetReportId,
                sourceAndTargetPhotoIds,
            })
            .toPromise();
    }
}

export interface PhotoCopyMap {
    sourcePhotoId: string;
    targetPhotoId: string;
}

export interface PhotoCopyResponse {
    success: boolean;
    numberOfCopiedPhotos: number;
}

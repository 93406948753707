<div id="invoices-summary-container" class="card card-with-padding">
    <h2 id="invoice-list-heading" matTooltip="Summen aller in der Liste sichtbaren Rechnungen">Aktuelle Umsätze</h2>

    <!-- Office locations picker -->
    <office-locations-picker
        [(officeLocationIds)]="filterOfficeLocations"
        (officeLocationIdsChange)="
            getFirstPageOfInvoices(); updateInvoiceAnalytics(); rememberOfficeLocations()
        "></office-locations-picker>

    <!-- Date range picker -->
    <div id="date-range-container">
        <date-range-picker
            [(dateFrom)]="filterInvoicesFrom"
            [(dateTo)]="filterInvoicesTo"
            [(dateFilterType)]="dateFilterType"
            (dateFilterTypeChange)="getFirstPageOfInvoices(); updateInvoiceAnalytics()"
            (dateRangeChange)="
                getFirstPageOfInvoices(); updateInvoiceAnalytics(); rememberDateRange()
            "></date-range-picker>

        <div id="date-range-quick-selects-container">
            <button class="date-range-quick-select flat" (click)="selectDateRange('month')">Monat</button>
            <button class="date-range-quick-select flat" (click)="selectDateRange('year')">Jahr</button>
            <button class="date-range-quick-select flat" (click)="selectDateRange('all')">Alle</button>
        </div>
    </div>
    <div id="invoice-totals-summary-container" *ngIf="user.accessRights.reporting">
        <img
            id="invoice-analytics-pending-indicator"
            src="/assets/images/icons/loading-indicator.svg"
            [style.visibility]="invoiceAnalyticsPending ? '' : 'hidden'" />
        <div id="invoice-totals-graph">
            <div id="invoice-graph-bars-container">
                <div
                    id="invoice-graph-bar-paid"
                    class="invoice-graph-bar"
                    [style.height]="barHeightPaidInvoices + '%'"
                    matTooltip="Bezahlt: {{ totalPaidAmount | number: '1.0-0' }} €"></div>
                <div
                    id="invoice-graph-bar-open"
                    class="invoice-graph-bar"
                    [style.height]="barHeightDueInvoices + '%'"
                    matTooltip="Fällig: {{ totalDueAmount | number: '1.0-0' }} €"></div>
                <div
                    id="invoice-graph-bar-due"
                    class="invoice-graph-bar"
                    [style.height]="barHeightOverdueInvoices + '%'"
                    matTooltip="In Verzug: {{ totalOverdueAmount | number: '1.0-0' }} €"></div>
            </div>
            <div id="invoice-graph-labels">
                <div class="invoice-graph-bar-label label" matTooltip="Bezahlt">B</div>
                <div class="invoice-graph-bar-label label" matTooltip="Fällig">F</div>
                <div class="invoice-graph-bar-label label" matTooltip="In Verzug">V</div>
            </div>
        </div>
        <div
            id="paid-invoices-total"
            class="invoice-total-container"
            (click)="selectQuickFilter('onlyPaid')"
            matTooltip="{{
                invoiceAnalytics.paid.numberOfInvoices > 1
                    ? invoiceAnalytics.paid.numberOfInvoices + ' vollständig bezahlte Rechnungen'
                    : 'Eine vollständig bezahlte Rechnung'
            }} ({{
                invoiceAnalytics.paid.paidAmount | number: '1.0-0'
            }} €). Außerdem sind Teilzahlungen von noch nicht vollständig bezahlten Rechnungen enthalten ({{
                invoiceAnalytics.paid.includedPartialPayments | number: '1.0-0'
            }} €).
            Werte brutto angegeben. {{
                invoiceAnalytics.yearOfPaidInvoice
                    ? dateFilterType === 'paymentDate'
                        ? 'Es werden nur Zahlungen aus dem aktuellen Jahr berücksichtigt.'
                        : 'Es werden nur Zahlungen zu Rechnungen, die im aktuellen Jahr gestellt worden sind, berücksichtigt.'
                    : ''
            }} ">
            <div class="invoice-summary-total">{{ totalPaidAmount | number: '1.0-0' }} €</div>
            <div class="label">
                {{ invoiceAnalytics.paid.numberOfInvoices }} bezahlt
                {{ invoiceAnalytics.yearOfPaidInvoice ? ' (' + invoiceAnalytics.yearOfPaidInvoice + ')' : '' }}
            </div>
        </div>
        <div
            id="open-invoices-total"
            class="invoice-total-container"
            (click)="selectQuickFilter('onlyDue')"
            matTooltip="{{
                invoiceAnalytics.unpaidAndDue.numberOfInvoices > 1
                    ? invoiceAnalytics.unpaidAndDue.numberOfInvoices + ' unbezahlte, noch nicht überfällige Rechnungen.'
                    : 'Eine unbezahlte, noch nicht überfällige Rechnung.'
            }} Teilzahlungen ({{
                invoiceAnalytics.unpaidAndDue.subtractedPartialPayments | number: '1.0-0'
            }} €, inkl. bezahlter Kürzungen), abgeschriebene Kürzungen ({{
                invoiceAnalytics.unpaidAndDue.subtractedWrittenOffShortPayments | number: '1.0-0'
            }} €) und Teilstornos ({{
                invoiceAnalytics.unpaidAndDue.subtractedCancelledAmount | number: '1.0-0'
            }} €) sind abgezogen.
            Ungeklärte Kürzungen sind enthalten. Werte brutto angegeben, es werden alle Rechnungen berücksichtigt.">
            <div class="invoice-summary-total">{{ totalDueAmount | number: '1.0-0' }} €</div>
            <div class="label">{{ invoiceAnalytics.unpaidAndDue.numberOfInvoices }} offen</div>
        </div>
        <div
            id="due-invoices-total"
            class="invoice-total-container"
            (click)="selectQuickFilter('onlyOverdue')"
            matTooltip="{{
                invoiceAnalytics.unpaidAndOverdue.numberOfInvoices > 1
                    ? invoiceAnalytics.unpaidAndOverdue.numberOfInvoices + ' unbezahlte Rechnungen'
                    : 'Eine unbezahlte Rechnung'
            }}, deren Zahlungsziel überschritten ist. Teilzahlungen ({{
                invoiceAnalytics.unpaidAndOverdue.subtractedPartialPayments | number: '1.0-0'
            }} €, inkl. bezahlter Kürzungen), abgeschriebene Kürzungen ({{
                invoiceAnalytics.unpaidAndOverdue.subtractedWrittenOffShortPayments | number: '1.0-0'
            }} €) und Teilstornos ({{
                invoiceAnalytics.unpaidAndOverdue.subtractedCancelledAmount | number: '1.0-0'
            }} €) sind abgezogen.
            Ungeklärte Kürzungen sind enthalten. Werte brutto angegeben, es werden alle Rechnungen berücksichtigt.">
            <div class="invoice-summary-total">{{ totalOverdueAmount | number: '1.0-0' }} €</div>
            <div class="label">{{ invoiceAnalytics.unpaidAndOverdue.numberOfInvoices }} in Verzug</div>
        </div>
    </div>
</div>
<section id="invoice-list-and-info-panel-section">
    <div id="invoice-list-container" class="card">
        <div id="invoice-list-header">
            <div id="sort-and-filter-container">
                <!--============================================-->
                <!-- Sort Direction -->
                <!--============================================-->
                <div class="sort-select-and-direction-container hide-on-small-screens">
                    <div
                        id="sort-select-container"
                        matTooltip="Sortierung"
                        class="ax-header-select"
                        [matMenuTriggerFor]="sortMenu"
                        [ngSwitch]="userPreferences.sortInvoiceListBy">
                        <span *ngSwitchCase="'invoiceNumber'">Nach Rechnungsnummer</span>
                        <span *ngSwitchCase="'lastName'">Nach Name</span>
                        <span *ngSwitchCase="'total'">Nach Betrag</span>
                        <span *ngSwitchCase="'paymentStatus'">Nach Status</span>
                        <span *ngSwitchCase="'date'">Nach Rechnungsdatum</span>
                        <span *ngSwitchCase="'officeLocation'">Nach Standort</span>
                        <div class="ax-header-select-arrow"></div>
                    </div>
                    <mat-menu #sortMenu="matMenu">
                        <a mat-menu-item (click)="selectSortStrategy('invoiceNumber')">Nach Rechnungsnummer</a>
                        <a mat-menu-item (click)="selectSortStrategy('lastName')">Nach Nachname</a>
                        <a mat-menu-item (click)="selectSortStrategy('total')">Nach Betrag</a>
                        <a mat-menu-item (click)="selectSortStrategy('paymentStatus')">Nach Status</a>
                        <a mat-menu-item (click)="selectSortStrategy('date')">Nach Rechnungsdatum</a>
                        <a mat-menu-item (click)="selectSortStrategy('officeLocation')">Nach Standort</a>
                    </mat-menu>
                    <mat-icon
                        id="sort-direction-indicator"
                        class="toolbar-icon"
                        [ngClass]="{ flipped: userPreferences.sortInvoiceListDescending }"
                        (click)="toggleSortDirection()"
                        [matTooltip]="
                            userPreferences.sortInvoiceListDescending
                                ? 'Sortierung absteigend'
                                : 'Sortierung aufsteigend'
                        ">
                        arrow_upward
                    </mat-icon>
                </div>
                <!--============================================-->
                <!-- END Sort Direction -->
                <!--============================================-->

                <!--============================================-->
                <!-- Quick Filters -->
                <!--============================================-->
                <mat-icon
                    class="color-blue medium-icon margin-right-8"
                    *ngIf="userPreferences.invoiceListQuickFilter && userPreferences.invoiceListQuickFilter !== 'none'">
                    filter_alt
                </mat-icon>
                <div
                    #quickFilterMenuTrigger="matMenuTrigger"
                    class="quick-filter-select-container ax-header-select"
                    matTooltip="Schnellfilter"
                    [matMenuTriggerFor]="quickFilterMenu"
                    [ngSwitch]="userPreferences.invoiceListQuickFilter || 'none'"
                    [ngClass]="{ active: userPreferences.invoiceListQuickFilter !== 'none' }"
                    (click)="loadLabelConfigs()">
                    <span *ngSwitchCase="'none'" class="ax-header-select-label">Kein Filter</span>
                    <span *ngSwitchCase="'onlyDue'" class="ax-header-select-label">Offene</span>
                    <span *ngSwitchCase="'onlyOverdue'" class="ax-header-select-label">Fällige</span>
                    <span *ngSwitchCase="'onlyPaid'" class="ax-header-select-label">Bezahlte</span>
                    <span *ngSwitchCase="'unpaid'" class="ax-header-select-label">Alle Unbezahlten</span>
                    <span *ngSwitchCase="'partial'" class="ax-header-select-label">Teilbezahlt</span>
                    <span *ngSwitchCase="'hasOpenPaymentReminder'" class="ax-header-select-label">Gemahnt</span>
                    <span *ngSwitchCase="'toBeReminded'" class="ax-header-select-label">Zu mahnen</span>
                    <span *ngSwitchCase="'shortPaid'" class="ax-header-select-label">Alle Kürzungen</span>
                    <span *ngSwitchCase="'settledShortPayments'" class="ax-header-select-label">
                        Geklärte Kürzungen
                    </span>
                    <span *ngSwitchCase="'unsettledShortPayments'" class="ax-header-select-label">
                        Ungeklärte Kürzungen
                    </span>

                    <!--********** Label Filter **********-->
                    <span *ngSwitchCase="'labels'" class="ax-header-select-label">
                        <div class="label-item" *ngIf="userPreferences.invoiceList_labelsForFilter.length === 1">
                            <!--********** Label Color **********-->
                            <!--suppress CssUnknownProperty -->
                            <div
                                class="label-color"
                                [style.--labelColor]="
                                    getLabelConfigByLabelName(userPreferences.invoiceList_labelsForFilter[0])?.color
                                "></div>

                            <!--********** Label Name **********-->
                            <div class="label-name" [matTooltip]="userPreferences.invoiceList_labelsForFilter[0]">
                                {{ userPreferences.invoiceList_labelsForFilter[0] }}
                            </div>
                        </div>
                        <ng-container *ngIf="userPreferences.invoiceList_labelsForFilter.length > 1">
                            {{ userPreferences.invoiceList_labelsForFilter.length }} Labels
                        </ng-container>
                    </span>

                    <span *ngSwitchCase="'imported'" class="ax-header-select-label">Aus Fremdsystem</span>
                    <!--********** Select Arrow **********-->
                    <div class="ax-header-select-arrow"></div>
                </div>
                <mat-menu #quickFilterMenu="matMenu">
                    <a mat-menu-item (click)="selectQuickFilter('none')">Kein Filter</a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('onlyDue')"
                        matTooltip="Unbezahlt und das Zahlungsziel noch nicht erreicht">
                        Offene
                    </a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('onlyOverdue')"
                        matTooltip="Unbezahlt und das Zahlungsziel überschritten">
                        Fällig
                    </a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('onlyPaid')"
                        matTooltip="Vollständig bezahlt oder gar überbezahlt">
                        Bezahlte
                    </a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('partial')"
                        matTooltip="Mindestens eine Zahlung ist eingegangen">
                        Teilbezahlt
                    </a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('unpaid')"
                        matTooltip="Nicht vollständig bezahlt, unabhängig davon, ob das Zahlungsziel schon erreicht wurde oder nicht">
                        Alle Unbezahlten
                    </a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('hasOpenPaymentReminder')"
                        matTooltip="Unbezahlt und mindestens eine Mahnstufe wurde erzeugt">
                        Gemahnt
                    </a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('toBeReminded')"
                        matTooltip="Zahlungsziel oder - falls bereits eine Mahnung erstellt wurde - Mahnfrist ist abgelaufen">
                        Zu mahnen
                    </a>
                    <a mat-menu-item [matMenuTriggerFor]="shortPaymentQuickFiltersSubmenu">Kürzungen</a>
                    <a mat-menu-item [matMenuTriggerFor]="labelFilterSubmenu">Labels</a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('imported')"
                        matTooltip="Rechnungen, die aus Fremdsystemen wie EGUB/GTÜ+ oder aus deinem alten Gutachtensystem importiert wurden.">
                        Aus Fremdsystem
                    </a>
                </mat-menu>

                <!--********** Short Payments **********-->
                <mat-menu #shortPaymentQuickFiltersSubmenu>
                    <a mat-menu-item (click)="selectQuickFilter('shortPaid')">Alle Kürzungen</a>
                    <a mat-menu-item (click)="selectQuickFilter('settledShortPayments')">Geklärte Kürzungen</a>
                    <a mat-menu-item (click)="selectQuickFilter('unsettledShortPayments')">Ungeklärte Kürzungen</a>
                </mat-menu>

                <!--********** Labels **********-->
                <mat-menu #labelFilterSubmenu class="label-filter-submenu">
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngFor="let labelConfig of labelConfigs"
                        (click)="toggleSelectLabelConfigForFilter(labelConfig.name)">
                        <!--********** Label **********-->
                        <div class="label-item-container flex-grow">
                            <div class="label-item">
                                <!--********** Label Color **********-->
                                <!--suppress CssUnknownProperty -->
                                <div
                                    class="label-color"
                                    [style.--labelColor]="getLabelConfigByLabelName(labelConfig.name)?.color"></div>

                                <!--********** Label Name **********-->
                                <div class="label-name" [matTooltip]="labelConfig.name">
                                    {{ labelConfig.name }}
                                </div>
                            </div>
                        </div>

                        <!--********** Multiselect **********-->
                        <mat-checkbox
                            [checked]="userPreferences.invoiceList_labelsForFilter.includes(labelConfig.name)"
                            (change)="toggleSelectLabelConfigForFilter(labelConfig.name)"
                            (click)="$event.stopPropagation()"
                            matTooltip="Zeige die Rechnungen mit diesem Label an.{{
                                '\n\n'
                            }}Labels können im grauen Info-Panel rechts der Rechnungsliste vergeben werden."></mat-checkbox>
                    </a>

                    <!-- No labels placeholder -->
                    <div *ngIf="labelConfigs.length === 0" class="no-labels-placeholder">Keine Labels vorhanden.</div>
                </mat-menu>

                <div class="quick-filters-info-container">
                    <!--********** Date Filter Warning **********-->
                    <div class="info-note one-liner-note" *ngIf="filterInvoicesFrom || filterInvoicesTo" @fadeInAndOut>
                        Datumsfilter aktiv
                        <mat-icon
                            id="remove-date-filter-icon"
                            class="toolbar-icon medium-icon no-color"
                            (click)="selectDateRange('all')"
                            matTooltip="Datumsfilter entfernen">
                            close
                        </mat-icon>
                    </div>

                    <!--********** Office Locations Filter Warning **********-->
                    <div class="info-note one-liner-note" *ngIf="filterOfficeLocations?.length > 0" @fadeInAndOut>
                        Standortfilter aktiv
                        <mat-icon
                            id="remove-office-locations-filter-icon"
                            class="toolbar-icon medium-icon no-color"
                            (click)="filterOfficeLocations = []; getFirstPageOfInvoices(); updateInvoiceAnalytics()"
                            matTooltip="Standortfilter entfernen">
                            close
                        </mat-icon>
                    </div>
                </div>

                <!--============================================-->
                <!-- END Quick Filters -->
                <!--============================================-->
            </div>

            <!--============================================-->
            <!-- Search Field -->
            <!--============================================-->
            <mat-form-field class="search-field hide-on-small-screens" floatLabel="never">
                <input
                    matInput
                    placeholder="Suchen"
                    (input)="filterAndSortInvoices()"
                    [(ngModel)]="searchTerm"
                    (ngModelChange)="updateSearchTerm()"
                    [matTooltip]="
                        'Diese Daten werden durchsucht: \n• Rechnungsnummer\n• Aktenzeichen des Gutachtens\n• Kennzeichen (Format GZ-AB-1234)\n• Anspruchsteller Name, Telefon, Email\n• Anwalt Name, Aktenzeichen\n• Versicherung\n• Schadennummer\n\nSuchworte müssen mindestens drei Zeichen lang sein.'
                    " />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
            <!--============================================-->
            <!-- END Search Field -->
            <!--============================================-->

            <div class="toolbar">
                <!--********** Invoice Search (mobile) **********-->
                <div
                    class="invoice-search-button-mobile"
                    [class.search-visible]="invoiceSearchMobileVisible"
                    (click)="invoiceSearchMobileVisible = !invoiceSearchMobileVisible">
                    <mat-icon>search</mat-icon>
                </div>

                <!--********** New Invoice **********-->
                <div class="split-button attract-focus">
                    <div
                        class="new-invoice-button split-button-left"
                        (click)="createNewInvoice()"
                        matTooltip="Neue Rechnung (N)">
                        <mat-icon id="new-invoice-button-icon" class="small-icon">add</mat-icon>
                        <span id="new-invoice-button-label">Rechnung</span>
                    </div>
                    <!--********** Other Invoice Types **********-->
                    <div
                        class="split-button-right"
                        matTooltip="Andere Rechnungstypen"
                        [matMenuTriggerFor]="otherInvoiceTypeSubmenu">
                        <mat-icon class="small-icon">arrow_drop_down</mat-icon>
                    </div>
                </div>

                <mat-menu #otherInvoiceTypeSubmenu="matMenu">
                    <a mat-menu-item class="menu-item-with-icon" (click)="openCreateCollectiveInvoiceDialog()">
                        <mat-icon>note_stack_add</mat-icon>
                        Sammelrechnung
                    </a>
                </mat-menu>

                <!--********** Show Bank Transaction List Icon **********-->
                <mat-icon
                    class="toolbar-icon"
                    (click)="showBankTransactionList()"
                    *ngIf="user?.accessRights.bankAccountSync && team?.bankAccountSync?.numberOfActiveBankAccounts > 0"
                    [ngClass]="{ 'disc-pulse-blue': isFirstSyncInfoNoteVisible() }"
                    matTooltip="Zahlungseingänge abgleichen">
                    swap_horiz
                </mat-icon>

                <!--********** Toggle Details Pane **********-->
                <mat-icon
                    class="toolbar-icon outlined hide-on-small-screens"
                    (click)="userPreferences.invoiceList_infoPanelShown = !userPreferences.invoiceList_infoPanelShown"
                    matTooltip="Details">
                    info
                </mat-icon>

                <!--********** DATEV export progress **********-->
                <div
                    id="datev-invoice-export-progress"
                    *ngIf="datevInvoiceExportInProgress"
                    matTooltip="Fortschritt des DATEV-Exports (Ausgangsrechnungen)">
                    <img id="datev-invoice-export-loading-indicator" src="/assets/images/icons/loading-indicator.svg" />
                    <div id="datev-invoice-export-progress-label">
                        {{ getDatevInvoiceExportProgressLabel() }}
                    </div>
                </div>

                <!--********** Submenu **********-->
                <mat-icon
                    class="toolbar-icon"
                    [matMenuTriggerFor]="invoiceListSubmenu"
                    #invoiceListSubmenuTrigger="matMenuTrigger">
                    more_vert
                </mat-icon>
                <mat-menu #invoiceListSubmenu>
                    <!--********** Bank Accounts **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="user?.accessRights.bankAccountSync"
                        (click)="showBankAccountList()"
                        matTooltip="Verknüpfte Bankkonten anzeigen">
                        <mat-icon>account_balance</mat-icon>
                        Bankkonten
                    </a>

                    <a mat-menu-item class="menu-item-with-icon" [mat-menu-trigger-for]="exportSubmenu">
                        <mat-icon>file_download</mat-icon>
                        Exportieren
                    </a>
                    <mat-menu #exportSubmenu>
                        <!--********** DATEV **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon option-with-label datev-export-menu-item"
                            *ngIf="!datevInvoiceExportInProgress"
                            (click)="exportInvoicesDatev()">
                            <img class="medium-icon" src="/assets/images/logos/datev_19.png" />
                            <div class="option-text">
                                <div matTooltip="DATEV Unternehmen online Export (Ausgangsrechnungen)">
                                    DATEV Rechnungen
                                </div>
                                <div class="label">
                                    <mat-icon
                                        *ngIf="filterOfficeLocations.length"
                                        class="export-location-filter-indicator"
                                        [matTooltip]="'Standortfilter aktiv'">
                                        filter_alt
                                    </mat-icon>
                                    <span matTooltip="Zeitraum über Datumsfilter der Rechnungsliste einstellbar">
                                        {{ getDatevExportDateLimits().fromDate | moment: 'DD.MM.YY' }} -
                                        {{ getDatevExportDateLimits().toDate | moment: 'DD.MM.YY' }}
                                    </span>
                                </div>
                            </div>
                            <mat-icon
                                class="toolbar-icon"
                                (click)="
                                    openDatevExportSettingsDialog();
                                    $event.stopPropagation();
                                    invoiceListSubmenuTrigger.closeMenu()
                                "
                                matTooltip="Einstellungen">
                                settings
                            </mat-icon>
                        </a>

                        <!--********** Excel overview over invoices **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon option-with-label"
                            (click)="exportInvoicesAndPaymentsExcel()">
                            <mat-icon class="excel-export-icon">file_download</mat-icon>
                            <div class="option-text">
                                <div
                                    matTooltip="Export von Rechnungen, Zahlungen & Kürzungen im CSV-Format (Excel) zur Verarbeitung außerhalb von autoiXpert.">
                                    Rechnungsliste
                                </div>
                                <div class="label">
                                    <mat-icon
                                        *ngIf="filterOfficeLocations.length"
                                        class="export-location-filter-indicator"
                                        [matTooltip]="'Standortfilter aktiv'">
                                        filter_alt
                                    </mat-icon>
                                    <span matTooltip="Zeitraum über Datumsfilter der Rechnungsliste einstellbar">
                                        {{ getDatevExportDateLimits().fromDate | moment: 'DD.MM.YY' }} -
                                        {{ getDatevExportDateLimits().toDate | moment: 'DD.MM.YY' }}
                                    </span>
                                </div>
                            </div>

                            <mat-icon
                                (click)="$event.stopPropagation()"
                                class="toolbar-icon"
                                [matMenuTriggerFor]="invoiceListExportDetailsSubmenu"
                                matTooltip="Welche Felder sollen exportiert werden?">
                                settings
                            </mat-icon>
                            <mat-menu #invoiceListExportDetailsSubmenu>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="!userPreferences.invoiceExportWithReportData"
                                    (click)="
                                        $event.stopPropagation();
                                        setIncludeReportDataInInvoiceAndPaymentsExcel(true);
                                        invoiceListSubmenuTrigger.closeMenu()
                                    ">
                                    inkl. eigene Felder (Gutachten)
                                </a>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="userPreferences.invoiceExportWithReportData"
                                    (click)="
                                        $event.stopPropagation();
                                        setIncludeReportDataInInvoiceAndPaymentsExcel(false);
                                        invoiceListSubmenuTrigger.closeMenu()
                                    ">
                                    ohne eigene Felder (Gutachten)
                                </a>
                            </mat-menu>
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="exportPaymentsExcel()"
                            matTooltip="Export von Zahlungen & Kürzungen im CSV-Format (Excel) zur Verarbeitung außerhalb von autoiXpert.">
                            <mat-icon class="excel-export-icon">file_download</mat-icon>
                            Zahlungsliste Excel
                        </a>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="exportOpenItemsExcel()"
                            matTooltip="Export von nicht vollständig bezahlten Rechnungen im CSV-Format (Excel) zur Verarbeitung außerhalb von autoiXpert.">
                            <mat-icon class="excel-export-icon">file_download</mat-icon>
                            Offene-Posten Liste Excel
                        </a>
                    </mat-menu>

                    <a mat-menu-item class="menu-item-with-icon" [mat-menu-trigger-for]="importSubmenu">
                        <mat-icon>publish</mat-icon>
                        Importieren
                    </a>
                    <mat-menu #importSubmenu>
                        <a
                            #gtueImportButton
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="importGtueInvoices()"
                            [disabled]="!user.accessRights.seeAllInvoices"
                            [matTooltip]="
                                'Rechnungen aus GTÜ+ (EGUB) importieren.' +
                                (gtueImportButton.disabled
                                    ? ' Um diese Funktion nutzen zu können, benötigen Sie das Zugriffsrecht ' +
                                      gtueImportAccessRightLabel
                                    : '')
                            ">
                            <mat-icon>publish</mat-icon>
                            GTÜ-Import
                        </a>
                    </mat-menu>

                    <!--********** Invoice List Settings **********-->
                    <a mat-menu-item class="menu-item-with-icon" [mat-menu-trigger-for]="columnsSubmenu">
                        <mat-icon>view_column</mat-icon>
                        Spalten
                    </a>

                    <mat-menu #columnsSubmenu>
                        <a mat-menu-item class="mat-menu-item" (click)="toggleShowReportToken()">
                            {{
                                userPreferences.displayInvoiceWithReportToken
                                    ? 'Aktenzeichen ausblenden'
                                    : 'Aktenzeichen einblenden'
                            }}
                        </a>
                    </mat-menu>
                </mat-menu>
            </div>
        </div>
        <!--============================================-->
        <!-- Search Field -->
        <!--============================================-->

        <div id="invoice-search-mobile" *ngIf="invoiceSearchMobileVisible">
            <mat-form-field class="search-field" floatLabel="never">
                <input
                    matInput
                    placeholder="Suchen"
                    [(ngModel)]="searchTerm"
                    (ngModelChange)="updateSearchTerm()"
                    [matTooltip]="
                        'Diese Daten werden durchsucht: \n• Rechnungsnummer\n• Aktenzeichen des Gutachtens\n• Kennzeichen (Format GZ-AB-1234)\n• Anspruchsteller Name, Telefon, Email\n• Anwalt Name, Aktenzeichen\n• Versicherung\n• Schadennummer\n\nSuchworte müssen mindestens drei Zeichen lang sein.'
                    " />
                <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
        </div>
        <!--============================================-->
        <!-- END Search Field -->
        <!--============================================-->

        <!--============================================-->
        <!-- Bank Account Info Notes -->
        <!--============================================-->
        <div class="info-note-padding-container" *ngIf="isConnectBankAccountNoteVisible()" @fadeInAndOut>
            <div class="info-note">
                <mat-icon>account_balance</mat-icon>
                <div class="info-note-text">
                    <h3>Bankkonto verknüpfen</h3>
                    <div>
                        <span
                            class="link"
                            (click)="
                                showBankAccountList();
                                user.userInterfaceStates.bankAccountSyncAvailabilityNoteClosed = true;
                                saveUser()
                            ">
                            Verbinde dein Bankkonto
                        </span>
                        , um Rechnungen automatisch als bezahlt markieren zu lassen, sobald eine Zahlung eingeht.
                        <span
                            class="link"
                            (click)="
                                openVideo('Intro Kontoabgleich', 'https://www.youtube.com/embed/c0lKE_ggrxE?rel=0')
                            ">
                            Video ansehen (1:30 min)
                        </span>
                    </div>
                </div>
                <mat-icon
                    class="toolbar-icon"
                    (click)="user.userInterfaceStates.bankAccountSyncAvailabilityNoteClosed = true; saveUser()">
                    close
                </mat-icon>
            </div>
        </div>

        <!--********** Start first Sync **********-->
        <div class="info-note-padding-container" *ngIf="isFirstSyncInfoNoteVisible()" @fadeInAndOut>
            <div class="info-note success-note">
                <mat-icon class="outlined">check_circle</mat-icon>
                <div class="info-note-text">
                    <h3>Zahlungseingänge auslesen</h3>
                    <div>
                        Starte den Kontoabgleich über das Symbol
                        <mat-icon id="bank-account-sync-icon-within-info-note">swap_horiz</mat-icon>
                        oben rechts.
                    </div>
                </div>
                <mat-icon
                    class="toolbar-icon"
                    (click)="user.userInterfaceStates.firstBankAccountSyncNoteClosed = true; saveUser()">
                    close
                </mat-icon>
            </div>
        </div>
        <!--============================================-->
        <!-- END Bank Account Info Notes -->
        <!--============================================-->

        <table id="invoice-list">
            <tr
                class="invoice-list-item"
                *ngFor="let invoice of filteredInvoices; trackBy: trackById"
                (click)="selectInvoice(invoice)"
                [ngClass]="{ selected: selectedInvoice?._id === invoice._id }"
                (dblclick)="navigateToInvoice(invoice._id)"
                (mousedown)="openInvoiceInNewTabOnMiddleClick(invoice, $event)">
                <td class="payment-status-column hide-on-small-screens">
                    <div class="payment-status-container">
                        <payment-status
                            [invoice]="invoice"
                            (statusClick)="openPaymentsDialog(invoice)"></payment-status>
                        <quick-payment
                            *ngIf="getUnpaidAmount(invoice) > 0"
                            [invoice]="invoice"
                            (remainderPayment)="saveInvoice(invoice)"></quick-payment>
                    </div>
                </td>
                <td class="invoice-total" [ngClass]="{ canceled: getPaymentStatus(invoice) === 'fullyCanceled' }">
                    <div class="position-relative" (click)="openPaymentsDialog(invoice)">
                        <span matTooltip="Brutto. Netto: {{ invoice.totalNet || 0 | number: '1.2-2' }} €">
                            {{ invoice.totalGross || 0 | number: '1.2-2' }} €
                        </span>

                        <!--********** Shortpaid amount **********-->
                        <div
                            class="invoice-shortpay-amount"
                            *ngIf="doesInvoiceHaveShortPay(invoice)"
                            (click)="openPaymentsDialog(invoice)">
                            <!--********** Short payment icon **********-->
                            <!--                        <mat-icon class="shortpay-icon short-payment-icon-rotated">content_cut</mat-icon>-->
                            <!-- The SVG has thicker scissor blades, and it's therefore better legible in small sizes. -->
                            <svg
                                class="shortpay-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="1264.278 0 2267.717 2267.719"
                                xml:space="preserve"
                                matTooltip="Gekürzt um: {{
                                    getInvoiceUnpaidShortPaymentSumGross(invoice) | number: '1.2-2'
                                }} € | {{
                                    (getInvoiceUnpaidShortPaymentSumGross(invoice) / invoice.totalGross) * 100
                                        | number: '1.0-0'
                                }} %">
                                <path
                                    d="M2875.046,1480.612c-0.013,0-0.02,0-0.025,0c-16.579,0-32.953,1.214-49.077,3.487l-141.15-178.406
                                                        c-82.692,104.55-160.819,203.32-223.939,283.123l88.555,111.908c-11.038,27.721-18.608,56.994-22.281,87.309
                                                        c-11.153,92.17,14.524,183.133,72.302,256.143c66.922,84.586,167.549,133.066,276.08,133.066
                                                        c78.986,0,153.672-25.653,215.964-74.056c73.901-57.381,120.776-140.021,131.981-232.435
                                                        c11.173-92.17-14.499-183.44-72.297-256.508C3084.211,1529.676,2983.577,1480.612,2875.046,1480.612z M3090.086,1855.321
                                                        c-6.923,57.072-35.85,107.921-81.466,143.334c-38.418,29.853-84.484,45.597-133.201,45.597
                                                        c-66.922,0-128.982-29.948-170.266-82.133c-35.682-45.09-51.528-101.282-44.64-158.219c6.903-57.084,35.831-107.702,81.44-143.135
                                                        c38.418-29.852,84.477-45.314,133.182-45.314c0,0,0.007,0,0.02,0c66.915,0,128.982,29.596,170.284,81.78
                                                        C3081.135,1742.353,3096.989,1798.384,3090.086,1855.321z"></path>
                                <path
                                    d="M1963.815,1485.024c-15.898-2.164-32.036-3.326-48.371-3.326c-108.518,0-209.139,48.512-276.061,133.099
                                                        c-57.777,73.047-83.456,164.035-72.296,256.212c11.192,92.413,58.041,174.919,131.924,232.318
                                                        c62.336,48.403,137.041,73.985,216.021,73.985c108.531,0,209.158-48.519,276.086-133.111c57.777-73.016,83.45-163.99,72.29-256.161
                                                        c-3.673-30.347-11.27-59.582-22.333-87.251c110.611-139.803,773.652-977.839,916.6-1158.455
                                                        c72.933-92.202,87.849-198.448,43.127-307.248c-32.557-79.2-85.802-131.558-88.056-133.748
                                                        c-7.217-7.025-16.868-10.916-26.873-10.916c-0.828,0-1.662,0.025-2.496,0.083c-10.878,0.707-20.954,5.992-27.714,14.545
                                                        l-661.172,833.741c-81.017,102.424-223.215,284.01-223.215,284.01L1963.815,1485.024z M2130.033,1855.315
                                                        c-6.916,57.078-35.85,107.927-81.466,143.34c-38.418,29.853-84.483,45.597-133.201,45.597c-66.91,0-128.97-29.948-170.258-82.133
                                                        c-35.689-45.09-51.536-101.288-44.647-158.225c6.917-57.085,35.837-107.696,81.454-143.129
                                                        c38.412-29.852,84.471-45.314,133.175-45.314c0,0,0.007,0,0.02,0c66.922,0,128.989,29.596,170.29,81.78
                                                        C2121.082,1742.353,2136.936,1798.377,2130.033,1855.315z M2395.268,1172.478c27.784,0,50.323,22.558,50.323,50.329
                                                        c0,27.785-22.539,50.323-50.323,50.323c-27.778,0-50.323-22.538-50.323-50.323
                                                        C2344.951,1195.03,2367.49,1172.478,2395.268,1172.478z"></path>
                                <path
                                    d="M1632.879,542.327c71.608,90.571,274.127,346.506,472.177,596.786c66.022-83.482,143.718-181.707,223.932-283.124
                                                        l-594.114-750.947c-6.768-8.553-16.837-13.832-27.714-14.544c-0.835-0.058-1.669-0.084-2.497-0.084
                                                        c-10.005,0-19.663,3.892-26.873,10.917c-2.254,2.19-55.492,54.554-88.048,133.754
                                                        C1545.024,343.893,1559.947,450.132,1632.879,542.327z"></path>
                            </svg>
                            <!--********** Are all short payments accepted? **********-->
                            <mat-icon
                                *ngIf="areAllShortPaymentsSettled(invoice)"
                                class="all-short-payments-accepted-icon"
                                matTooltip="Über alle Kürzungen wurde entschieden (abgeschrieben oder gezahlt).">
                                check
                            </mat-icon>
                        </div>

                        <!--********** Unpaid amount **********-->
                        <div
                            class="invoice-unpaid-amount hide-on-small-screens"
                            *ngIf="
                                dateFilterType !== 'paymentDate' &&
                                getUnpaidAmount(invoice) > 0 &&
                                getUnpaidAmount(invoice) !== invoice.totalGross
                            ">
                            <span
                                *ngIf="getUnpaidAmount(invoice) === getVat(invoice)"
                                class="vat-note"
                                matTooltip="Offener Betrag entspricht der MwSt.">
                                MwSt.
                            </span>
                            <span
                                *ngIf="getUnpaidAmount(invoice) === invoice.totalNet && invoice.vatRate > 0"
                                class="vat-note"
                                matTooltip="Offener Betrag entspricht dem Nettobetrag. Die MwSt. wurde also bezahlt.">
                                netto
                            </span>
                            <span matTooltip="Offener Betrag">{{ getUnpaidAmount(invoice) | number: '1.2-2' }} €</span>
                        </div>

                        <!--********** Paid amount within date filter **********-->
                        <div
                            class="invoice-paid-amount-within-date-filter hide-on-small-screens"
                            *ngIf="
                                dateFilterType === 'paymentDate' &&
                                getPaidAmountWithinDateFilter(invoice) > 0 &&
                                getPaidAmountWithinDateFilter(invoice) !== invoice.totalGross
                            ">
                            <span matTooltip="Im Filterzeitraum gezahlter Teilbetrag.">
                                {{ getPaidAmountWithinDateFilter(invoice) | number: '1.2-2' }} €
                            </span>
                        </div>
                    </div>
                    <!-- Mobile only: Payment status-->
                    <div class="payment-status-mobile">
                        <payment-status
                            [invoice]="invoice"
                            [displaySmall]="true"
                            (statusClick)="openPaymentsDialog(invoice)"></payment-status>
                    </div>
                </td>
                <!-- Desktop only -->
                <td class="recipient-name-column hide-on-small-screens">
                    <div *ngIf="invoice.recipient.contactPerson.organization" class="recipient-organization">
                        <div>{{ invoice.recipient.contactPerson.organization }}</div>
                        <div class="label">
                            {{ invoice.recipient.contactPerson.firstName }}
                            {{ invoice.recipient.contactPerson.lastName }}
                        </div>
                    </div>
                    <div
                        *ngIf="
                            !invoice.recipient.contactPerson.organization &&
                            (invoice.recipient.contactPerson.firstName || invoice.recipient.contactPerson.lastName)
                        "
                        class="recipient-person">
                        {{ invoice.recipient.contactPerson.firstName }}
                        {{ invoice.recipient.contactPerson.lastName }}
                    </div>
                    <div
                        *ngIf="
                            !invoice.recipient.contactPerson.organization &&
                            !invoice.recipient.contactPerson.firstName &&
                            !invoice.recipient.contactPerson.lastName
                        "
                        class="recipient-none">
                        Kein Empfänger
                    </div>
                </td>
                <!--
                Mobile Only: Use a flex to display all necessary information in one cell.
                This allows to expand the recipient name as long as possible.
            -->
                <td class="invoice-data-column-mobile" *ngIf="isSmallScreen('large')">
                    <div class="invoice-data-container-mobile">
                        <div class="recipient-name-container" (click)="navigateToInvoice(invoice._id)">
                            <div *ngIf="invoice.recipient.contactPerson.organization" class="recipient-organization">
                                <div>{{ invoice.recipient.contactPerson.organization }}</div>
                                <div class="label">
                                    {{ invoice.recipient.contactPerson.firstName }}
                                    {{ invoice.recipient.contactPerson.lastName }}
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    !invoice.recipient.contactPerson.organization &&
                                    (invoice.recipient.contactPerson.firstName ||
                                        invoice.recipient.contactPerson.lastName)
                                "
                                class="recipient-person">
                                {{ invoice.recipient.contactPerson.firstName }}
                                {{ invoice.recipient.contactPerson.lastName }}
                            </div>
                            <div
                                *ngIf="
                                    !invoice.recipient.contactPerson.organization &&
                                    !invoice.recipient.contactPerson.firstName &&
                                    !invoice.recipient.contactPerson.lastName
                                "
                                class="recipient-none">
                                Kein Empfänger
                            </div>

                            <div class="label invoice-details-mobile">
                                <span class="invoice-number-mobile">{{ invoice.number }}</span>
                                <span *ngIf="invoice.date" class="invoice-date-mobile">
                                    {{ invoice.date | moment }}
                                </span>
                                <span
                                    class="invoice-unpaid-amount"
                                    *ngIf="
                                        getUnpaidAmount(invoice) > 0 &&
                                        getUnpaidAmount(invoice) !== invoice.totalGross &&
                                        dateFilterType !== 'paymentDate'
                                    ">
                                    offen: {{ getUnpaidAmount(invoice) | number: '1.2-2' }} €
                                </span>
                            </div>
                        </div>
                        <div class="toolbar-icons-mobile">
                            <!--********** Notes **********-->
                            <mat-icon
                                class="invoice-record-toolbar-icon"
                                *ngIf="invoice.notes?.length || manuallyShownNotesIcons.has(invoice)"
                                matTooltip="Notizen"
                                internal-notes-panel-anchor
                                [notes]="invoice.notes"
                                (change)="saveInvoice(invoice)"
                                (panelClose)="hideIconForEmptyNotes(invoice)"
                                (click)="showNotesManually(invoice)"
                                [autoOpenWhenVisible]="manuallyShownNotesIcons.has(invoice)">
                                notes
                            </mat-icon>
                            <!--********** Locked At **********-->
                            <mat-icon
                                class="invoice-record-toolbar-icon medium-icon"
                                *ngIf="invoice.lockedAt"
                                matTooltip="Gebucht am {{ invoice.lockedAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                                lock
                            </mat-icon>
                        </div>
                    </div>
                </td>
                <td class="invoice-date hide-on-small-screens">
                    <span *ngIf="invoice.date" [matTooltip]="invoice | invoiceDateAndOptionalDueDate">
                        {{ invoice.date | moment }}
                    </span>

                    <span class="label" *ngIf="!invoice.date">Noch kein Datum</span>

                    <!--********** Payment Reminder Overdue **********-->
                    <div
                        class="invoice-due-date warning label"
                        *ngIf="invoice.type === 'invoice' && isPaymentReminderOverdue(invoice)">
                        <div
                            class="display-flex flex-align-center"
                            matTooltip="{{
                                invoice.dueDate === invoice.nextPaymentReminderAt
                                    ? 'Zahlungsziel überschritten'
                                    : 'Mahnfrist abgelaufen'
                            }}: {{ invoice.nextPaymentReminderAt | momentCalendar: 'day' }}">
                            <mat-icon class="invoice-payment-reminder-date-overdue">notifications</mat-icon>
                            <span (click)="openPaymentReminderDialog(invoice)">
                                {{ invoice.nextPaymentReminderAt | moment }}
                            </span>
                        </div>

                        <!--********** Change next payment reminder date **********-->
                        <mat-icon
                            class="change-next-payment-reminder-date-icon"
                            datePickerWithInputAnchor
                            [(date)]="invoice.nextPaymentReminderAt"
                            (dateChange)="saveInvoice(invoice)"
                            matTooltip="Mahnfrist bearbeiten">
                            edit
                        </mat-icon>
                    </div>
                </td>
                <td class="invoice-number hide-on-small-screens">
                    <span matTooltip="Rechnungsnummer">{{ invoice.number }}</span>
                </td>

                <!--********** Report Token **********-->
                <td
                    *ngIf="userPreferences.displayInvoiceWithReportToken"
                    class="hide-on-small-screens report-token-column">
                    <div id="report-token-container">
                        <!-- Empty label for collective invoice (because there are probably multiple) -->
                        {{
                            invoice.reportIds?.length > 1
                                ? invoice.reportIds.length + ' Gutachten'
                                : (invoice.reportsData?.[0]?.token ?? '')
                        }}
                    </div>
                </td>

                <!--********** License Plate **********-->
                <td class="license-plate-container report-licensePlate-column hide-on-small-screens">
                    <ng-container *ngIf="!invoice.importedFromThirdPartySystem">
                        <ng-container *ngIf="!invoice.reportIds || invoice.reportIds?.length <= 1">
                            <a
                                [routerLink]="'/Gutachten/' + invoice.reportIds[0]"
                                *ngIf="invoice.reportsData?.[0]?.licensePlate"
                                (mousedown)="stopMiddleMouseClickFromBubbling($event)">
                                <license-plate [licensePlate]="invoice.reportsData[0].licensePlate"></license-plate>
                            </a>
                            <div *ngIf="!(invoice.reportIds?.length > 0)" class="label">Kein Gutachten verknüpft</div>
                            <div
                                *ngIf="invoice.reportIds?.length > 0 && !invoice.reportsData?.[0]?.licensePlate"
                                class="label">
                                Kein Kennzeichen
                            </div>
                        </ng-container>
                        <!-- Empty label for collective invoice (because there are probably multiple) -->
                        <div *ngIf="invoice.reportIds?.length > 1" class="label"></div>
                    </ng-container>

                    <!--********** Imported from 3rd-party system **********-->
                    <div
                        class="label"
                        *ngIf="invoice.importedFromThirdPartySystem"
                        [ngSwitch]="invoice.importedFromThirdPartySystem">
                        Importiert aus
                        <span *ngSwitchCase="'dynarex'">Dynarex</span>
                        <span *ngSwitchCase="'gtue'">
                            <img
                                class="imported-from-gtue-logo"
                                src="/assets/images/logos/gtue-logo_50.png"
                                alt="GTÜ Logo"
                                matTooltip="GTÜ" />
                        </span>
                        <span *ngSwitchDefault>Fremdsystem</span>
                    </div>
                </td>
                <td class="report-car-model-column hide-on-small-screens">
                    <div
                        class="manufacturer-and-model-container"
                        *ngIf="invoice.reportIds?.length > 0 && invoice.reportsData?.length > 0">
                        <ng-container *ngIf="!invoice.reportIds || invoice.reportIds?.length <= 1">
                            <!--********** Manufacturer **********-->
                            <img
                                class="car-make-icon"
                                [src]="iconFilePathForCarBrand(invoice.reportsData[0].carMake)"
                                *ngIf="
                                    invoice.reportsData[0]?.carMake &&
                                    iconForCarBrandExists(invoice.reportsData[0].carMake)
                                " />

                            <!--********** Model **********-->
                            <span
                                *ngIf="invoice.reportsData[0]?.carModel"
                                [matTooltip]="invoice.reportsData[0].carModel">
                                {{ invoice.reportsData[0].carMake }}
                                {{ invoice.reportsData[0].carModel | cleanBaseModel }}
                            </span>

                            <!--********** No Data Placeholder **********-->
                            <span
                                class="label"
                                *ngIf="!invoice.reportsData[0]?.carMake && !invoice.reportsData[0]?.carModel">
                                Kein Hersteller
                            </span>
                        </ng-container>
                        <!-- Empty label for collective invoice (because there are probably multiple) -->
                        <div *ngIf="invoice.reportIds?.length > 1" class="label"></div>
                    </div>
                </td>
                <td class="invoice-record-toolbar" *ngIf="!isSmallScreen('large')">
                    <div class="invoice-record-toolbar-icon-container">
                        <!--********** Notes **********-->
                        <mat-icon
                            class="invoice-record-toolbar-icon"
                            *ngIf="invoice.notes?.length || manuallyShownNotesIcons.has(invoice)"
                            matTooltip="Notizen"
                            internal-notes-panel-anchor
                            [notes]="invoice.notes"
                            (change)="saveInvoice(invoice)"
                            (click)="showNotesManually(invoice)"
                            (panelClose)="hideIconForEmptyNotes(invoice)"
                            [autoOpenWhenVisible]="manuallyShownNotesIcons.has(invoice)">
                            notes
                        </mat-icon>
                        <!--********** Collective Invoice **********-->
                        <mat-icon
                            class="invoice-record-toolbar-icon medium-icon outlined"
                            *ngIf="invoice.isCollectiveInvoice"
                            matTooltip="Sammelrechnung über {{ invoice.reportIds?.length }} Gutachten">
                            note_stack_add
                        </mat-icon>
                        <!--********** Locked At **********-->
                        <mat-icon
                            class="invoice-record-toolbar-icon medium-icon"
                            *ngIf="invoice.lockedAt"
                            matTooltip="Gebucht am {{ invoice.lockedAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                            lock
                        </mat-icon>
                    </div>
                </td>
                <td class="submenu-column">
                    <div class="submenu-container">
                        <mat-icon
                            class="toolbar-icon"
                            [matMenuTriggerFor]="invoiceItemSubmenu"
                            #invoiceItemSubmenuTrigger="matMenuTrigger">
                            more_vert
                        </mat-icon>
                    </div>
                    <mat-menu #invoiceItemSubmenu="matMenu">
                        <!--********** Open Invoice **********-->
                        <a mat-menu-item class="menu-item-with-icon" (click)="navigateToInvoice(invoice._id)">
                            <mat-icon>visibility</mat-icon>
                            <div class="option-text">Öffnen</div>

                            <!--********** Open in New Tab **********-->
                            <mat-icon
                                class="toolbar-icon"
                                (click)="openInvoiceInNewTab(invoice, $event, invoiceItemSubmenuTrigger)"
                                matTooltip="In neuem Tab öffnen.{{
                                    '\n\n'
                                }}Auch möglich, indem du mit dem Mausrad auf eine Rechnungszeile klickst.">
                                open_in_new
                            </mat-icon>
                        </a>

                        <!--********** Download PDF **********-->
                        <a mat-menu-item class="menu-item-with-icon" (click)="downloadInvoicePdf(invoice)">
                            <mat-icon>get_app</mat-icon>
                            PDF herunterladen
                        </a>

                        <!--********** Notes **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="!manuallyShownNotesIcons.has(invoice)"
                            (click)="showNotesManually(invoice)">
                            <mat-icon>notes</mat-icon>
                            Notizen
                        </a>

                        <!--********** Lock Invoice **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="!invoice.lockedBy"
                            (click)="lockInvoice(invoice)"
                            matTooltip="Festgeschriebene Rechnungen können nicht mehr bearbeitet werden.">
                            <mat-icon>lock</mat-icon>
                            Festschreiben
                        </a>

                        <!--********** Payment Reminder **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            *ngIf="
                                getPaymentStatus(invoice) !== 'fullyCanceled' &&
                                getPaymentStatus(invoice) !== 'fullCancellation'
                            "
                            (click)="openPaymentReminderDialog(invoice)">
                            <mat-icon>notifications</mat-icon>
                            Mahnen
                        </a>

                        <!--********** Cancel Invoice **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            [matMenuTriggerFor]="invoiceCancellationSubmenu"
                            *ngIf="
                                getPaymentStatus(invoice) !== 'fullyCanceled' &&
                                !invoice.rootInvoiceId &&
                                invoice.importedFromThirdPartySystem !== 'gtue'
                            ">
                            <mat-icon>block</mat-icon>
                            Rechnung stornieren
                        </a>

                        <mat-menu #invoiceCancellationSubmenu>
                            <!--********** Full Cancellation **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="cancelInvoice(invoice)"
                                matTooltip="Rechnung vollständig stornieren. Die Originalrechnung wird auch als storniert angezeigt.">
                                <mat-icon>undo</mat-icon>
                                Vollständig
                            </a>

                            <!--********** Partial Cancellation **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="showInvoiceCancellationDialog(invoice)">
                                <mat-icon>rotate_left</mat-icon>
                                Teilweise
                            </a>
                        </mat-menu>

                        <!--********** Record Short Payment **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="openPaymentsDialog(invoice, 'shortPayment')"
                            *ngIf="
                                getPaymentStatus(invoice) !== 'fullyCanceled' &&
                                !invoice.rootInvoiceId &&
                                invoice.importedFromThirdPartySystem !== 'gtue'
                            ">
                            <mat-icon class="short-payment-icon-rotated">content_cut</mat-icon>
                            Kürzung erfassen
                        </a>

                        <!--********** Delete Invoice **********-->
                        <a
                            mat-menu-item
                            class="menu-item-with-icon alert"
                            (click)="deleteInvoice(invoice)"
                            [class.disabled]="!isDeletingInvoiceAllowed(invoice)"
                            [matTooltip]="
                                isDeletingInvoiceAllowed(invoice)
                                    ? ''
                                    : 'Gebuchte Rechnungen können nur von einem Administrator deines Teams gelöscht werden.'
                            ">
                            <mat-icon>delete</mat-icon>
                            Rechnung löschen
                        </a>
                    </mat-menu>
                </td>
            </tr>
            <tr *ngIf="filteredInvoices.length === 0">
                <td class="no-invoice-item-placeholder">
                    <h3>Keine Rechnungen gefunden</h3>
                    <!-- Don't let prettier format the following inline elements (to prevent whitespace between anchor and comma) -->
                    <!-- prettier-ignore -->
                    <p *ngIf="invoices.length === 0">
                        <a
                            href="https://wissen.autoixpert.de/hc/de/articles/360015874751-Gutachten-abschlie%C3%9Fen"
                            target="_blank"
                            rel="noopener">
                            Schließe ein Gutachten ab</a>, um die Rechnung automatisch schreiben zu lassen.
                    </p>
                    <p *ngIf="invoices.length > 0">
                        {{ invoices.length }} Rechnungen durch Filter & Suche ausgeblendet.
                    </p>
                </td>
            </tr>
        </table>
        <div id="pending-icon-container" *ngIf="isLoadMoreInvoicesPending">
            <img class="pending-icon" src="/assets/images/icons/loading-indicator.svg" alt="Loading Indicator" />
        </div>
        <div
            (intersectsViewport)="triggerLoadMoreInvoices()"
            *ngIf="
                filteredInvoices.length > 0 &&
                !isLoadMoreInvoicesPending &&
                !allInvoicesLoadedWithCurrentFilters &&
                !searchTerm
            ">
            <span>Mehr Rechnungen laden</span>
        </div>
    </div>

    <!--============================================-->
    <!-- Info Panel -->
    <!--============================================-->
    <invoice-info-panel
        *ngIf="userPreferences.invoiceList_infoPanelShown"
        [invoice]="selectedInvoice"
        (close)="userPreferences.invoiceList_infoPanelShown = false"
        (paymentReminderButtonClicked)="openPaymentReminderDialog($event)"
        (labelsChanged)="selectedLabelsForInvoiceChanged()"
        @slideOutSide></invoice-info-panel>
    <!--============================================-->
    <!-- END Info Panel -->
    <!--============================================-->
</section>
<div id="more-invoices-button-container">
    <button
        class="show-more-button"
        (click)="!allInvoicesLoadedWithCurrentFilters && triggerLoadMoreInvoices()"
        [ngClass]="{ disabled: allInvoicesLoadedWithCurrentFilters }"
        [matTooltip]="allInvoicesLoadedWithCurrentFilters ? 'Alle Rechnungen geladen' : ''">
        Mehr anzeigen
    </button>
    <div
        id="number-of-visible-invoices-container"
        [ngClass]="{
            'fixed-positioning': !allInvoicesLoadedWithCurrentFilters,
            'is-info-panel-open': userPreferences.invoiceList_infoPanelShown,
        }"
        matTooltip="Geladene Rechnungen">
        <ng-container *ngIf="!allInvoicesLoadedWithCurrentFilters">
            <img src="/assets/images/icons/loading-indicator.svg" *ngIf="isLoadMoreInvoicesPending" />
            {{ filteredInvoices.length }} Rechnungen
        </ng-container>
        <ng-container *ngIf="allInvoicesLoadedWithCurrentFilters">
            Alle {{ filteredInvoices.length }} Rechnungen geladen
        </ng-container>
    </div>
</div>

<!--============================================-->
<!-- Dialogs -->
<!--============================================-->
<payments-dialog
    *ngIf="paymentsDialogShown"
    [invoice]="invoiceForPaymentDialog"
    [initialView]="initialPaymentTypeForPaymentDialog"
    (invoiceChange)="saveInvoice($event)"
    (cancellationInvoiceCreated)="addFreshInvoicesToList([$event]); filterAndSortInvoices()"
    (close)="hidePaymentsDialog(); updateInvoiceAnalytics()"
    @runChildAnimations></payments-dialog>

<payment-reminder-dialog
    *ngIf="paymentReminderDialogShown"
    [invoice]="invoiceForPaymentReminderDialog"
    (close)="hidePaymentReminderDialog()"
    @runChildAnimations></payment-reminder-dialog>

<gocardless-bank-account-list
    *ngIf="gocardlessBankAccountListShown"
    (numberOfBankAccountsChange)="handleBankAccountUpdate($event)"
    (close)="hideBankAccountList()"
    @runChildAnimations></gocardless-bank-account-list>

<bank-transaction-list
    *ngIf="bankTransactionListShown"
    (close)="hideBankTransactionList()"
    (invoiceUpdated)="updateInvoiceLocally($event)"
    @runChildAnimations></bank-transaction-list>

<partial-invoice-cancellation-dialog
    *ngIf="invoiceForPartialCancellationDialog"
    [originalInvoice]="invoiceForPartialCancellationDialog"
    (close)="hideInvoiceCancellationDialog()"
    (cancellationInvoiceCreated)="getFirstPageOfInvoices()"
    @runChildAnimations></partial-invoice-cancellation-dialog>

<gtue-invoice-import-dialog
    *ngIf="gtueInvoiceImportDialogShown"
    (close)="hideGtueInvoiceImportDialog()"
    (rewindSuccessful)="getFirstPageOfInvoices()"
    (importSuccessful)="gtueImportWasSuccessful()"></gtue-invoice-import-dialog>

<create-collective-invoice-dialog
    *ngIf="createCollectiveInvoiceDialogShown"
    (close)="hideCreateCollectiveInvoiceDialog()"
    [user]="user"
    [team]="team"
    (invoiceWasCreated)="navigateToInvoice($event)"></create-collective-invoice-dialog>
<!--============================================-->
<!-- END Dialogs -->
<!--============================================-->

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!--********** Dialog Title **********-->
        <h2>Vorgangsarten</h2>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <!--============================================-->
        <!-- Damage Reports -->
        <!--============================================-->
        <h3>Schadengutachten</h3>
        <div class="report-types-list">
            <ng-container *ngFor="let reportType of damageReportTypes">
                <div
                    *ngIf="!hideShortAssessment || reportType !== 'shortAssessment'"
                    class="report-type"
                    (click)="toggleReportType(reportType)"
                    [ngClass]="{ active: selectedReportTypes.includes(reportType) }">
                    <mat-icon class="report-type-icon small-icon">local_offer</mat-icon>
                    <div class="report-type-title">{{ translateReportType(reportType) }}</div>
                </div>
            </ng-container>
        </div>
        <!--============================================-->
        <!-- END Damage Reports -->
        <!--============================================-->

        <ng-container *ngIf="!showOnlyDamageReportTypes">
            <!--============================================-->
            <!-- Valuation Reports -->
            <!--============================================-->
            <h3>Wertgutachten</h3>
            <div class="report-types-list">
                <div
                    class="report-type"
                    *ngFor="let reportType of valuationReportTypes"
                    (click)="toggleReportType(reportType)"
                    [ngClass]="{ active: selectedReportTypes.includes(reportType) }">
                    <mat-icon class="report-type-icon small-icon">local_offer</mat-icon>
                    <div class="report-type-title">{{ translateReportType(reportType) }}</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Valuation Reports -->
            <!--============================================-->

            <!--============================================-->
            <!-- Others -->
            <!--============================================-->
            <h3>Sonstiges</h3>
            <div class="report-types-list">
                <div
                    class="report-type"
                    *ngFor="let reportType of otherReportTypes"
                    (click)="toggleReportType(reportType)"
                    [ngClass]="{ active: selectedReportTypes.includes(reportType) }">
                    <mat-icon class="report-type-icon small-icon">local_offer</mat-icon>
                    <div class="report-type-title">{{ translateReportType(reportType) }}</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Others -->
            <!--============================================-->
        </ng-container>

        <div class="dialog-buttons-container">
            <button (click)="closeDialog()">Schließen</button>
        </div>
    </div>
</div>

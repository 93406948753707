import { CarShape } from '@autoixpert/models/reports/car-identification/car';
import { PaintThicknessPosition } from '@autoixpert/models/reports/paint-thickness-measurement';

/**
 * Every car shape has different positions where paint thickness is measured. This function returns the list
 * of positions valid for the given car shape.
 */
export function getPaintThicknessPositionsForCarShape(shape: CarShape): PaintThicknessPosition[] {
    switch (shape) {
        case 'bicycle':
        case 'e-bike':
        case 'pedelec':
        case 'motorcycle':
            return [
                'frontLeft',
                'frontCenter',
                'frontRight',
                'centerLeft',
                'centerRight',
                'rearLeft',
                'rearCenter',
                'rearRight',
            ];

        case 'trailer':
        case 'caravanTrailer':
            return [
                'frontLeft',
                'frontCenter',
                'frontRight',
                'centerLeft',
                'centerRight',
                'rearLeft',
                'rearCenter',
                'rearRight',
            ];

        case 'motorHome':
            return [
                'fenderFrontLeft',
                'hood',
                'fenderFrontRight',
                'doorDriver',
                'doorFrontPassenger',
                'doorBackPassengerLeft',
                'roof',
                'doorBackPassengerRight',
                'fenderRearLeft',
                'fenderRearRight',
                'rearLeft',
                'roofRear',
                'rearCenter',
                'rearRight',
            ];

        case 'truck':
            return [
                // Front
                'frontLeft',
                'frontCenter',
                'frontRight',
                // Driver's cabin
                'roof',
                'doorDriver',
                'doorFrontPassenger',
                // Platform
                'leftWallFront',
                'leftWallCenter',
                'leftWallRear',
                'ceilingFront',
                'ceilingCenter',
                'ceilingRear',
                'rightWallFront',
                'rightWallCenter',
                'rightWallRear',
                // Rear
                'rearLeft',
                'rearCenter',
                'rearRight',
            ];

        case 'semiTruck':
            return [
                'frontLeft',
                'frontCenter',
                'frontRight',
                'doorDriver',
                'doorFrontPassenger',
                'roof',
                'frameLeft',
                'frameRight',
                'rearLeft',
                'rearCenter',
                'rearRight',
            ];

        case 'semiTrailer':
            return [
                // Front
                'frontLeft',
                'frontCenter',
                'frontRight',
                // Center
                'leftWallFront',
                'leftWallCenter',
                'leftWallRear',
                'ceilingFront',
                'ceilingCenter',
                'ceilingRear',
                'rightWallFront',
                'rightWallCenter',
                'rightWallRear',
                // Rear
                'rearLeft',
                'rearCenter',
                'rearRight',
            ];

        case 'bus':
            return [
                // Front
                'frontLeft',
                'frontCenter',
                'frontRight',
                'doorDriver',
                'doorFrontPassenger',
                // Center
                'roof',
                'leftWallCenter',
                'leftWallRear',
                'rightWallCenter',
                'rightWallRear',
                // Rear
                'rearLeft',
                'rearCenter',
                'rearRight',
            ];
        default:
            return [
                'fenderFrontLeft',
                'hood',
                'fenderFrontRight',
                'doorDriver',
                'doorFrontPassenger',
                'doorBackPassengerLeft',
                'roof',
                'doorBackPassengerRight',
                'fenderRearLeft',
                'fenderRearRight',
                'rearCenter',
            ];
    }
}

import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import {
    FeeSetHasChangedDialogComponent,
    FeeSetHasChangedDialogDecision,
    FeeSetHasChangedDialogResult,
} from 'src/app/reports/details/shared/fee-set-has-changed-dialog/fee-set-has-changed-dialog.component';

export async function confirmRetransmissionOfGarageFees({
    dialogService,
}: {
    dialogService: MatLegacyDialog;
}): Promise<FeeSetHasChangedDialogDecision> {
    const result = await dialogService
        .open<FeeSetHasChangedDialogComponent, any, FeeSetHasChangedDialogResult>(FeeSetHasChangedDialogComponent)
        .afterClosed()
        .toPromise();

    // If the user clicks the backdrop or uses the escape key, translate to match our interface.
    if (!result) {
        return {
            openCalculation: false,
            retransmitFeeSet: false,
        };
    }

    return result;
}

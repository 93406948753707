import { Component, Input, OnInit } from '@angular/core';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { User } from '@autoixpert/models/user/user';
import { ApiErrorService } from '../../services/api-error.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { UserService } from '../../services/user.service';

/**
 * Displays a simple note used twice throughout the application.
 *
 * Not much logic in here. But it keeps the maintenance of the link and logos in one place.
 */
@Component({
    selector: 'audatex-wage-rate-translation-note',
    templateUrl: './audatex-wage-rate-translation-note.component.html',
    styleUrls: ['./audatex-wage-rate-translation-note.component.scss'],
})
export class AudatexWageRateTranslationNoteComponent implements OnInit {
    constructor(
        public userPreferences: UserPreferencesService,
        private userService: UserService,
        private apiErrorService: ApiErrorService,
        private loggedInUserService: LoggedInUserService,
    ) {}

    @Input() carManufacturer: Car['make'];
    protected user: User;

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    protected readonly iconForCarBrandExists = iconForCarBrandExists;
    protected readonly iconFilePathForCarBrand = iconFilePathForCarBrand;
}

<h2 matDialogTitle [ngSwitch]="data.intrusionProvider">
    <ng-container *ngSwitchCase="'kaspersky'">Kaspersky blockiert Update</ng-container>
    <ng-container *ngSwitchCase="'avast'">Avast blockiert Update</ng-container>
    <ng-container *ngSwitchDefault>Etwas blockiert das Update</ng-container>
</h2>
<mat-dialog-content [ngSwitch]="data.intrusionProvider">
    <!--============================================-->
    <!-- Kaspersky -->
    <!--============================================-->
    <ng-container *ngSwitchCase="'kaspersky'">
        <p>Kaspersky fügt in die neuen Update-Dateien Code ein, der das Update verhindert.</p>
        <p>Bitte deinstalliere oder konfiguriere Kaspersky und lade die Seite neu.</p>
        <p>
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/18506568548626-Kaspersky-verhindert-Update-von-autoiXpert"
                rel="noopener"
                target="_blank">
                Artikel zu den Details.
            </a>
        </p>
    </ng-container>
    <!--============================================-->
    <!-- END Kaspersky -->
    <!--============================================-->

    <!--============================================-->
    <!-- Avast -->
    <!--============================================-->
    <ng-container *ngSwitchCase="'avast'">
        <p>Avast fügt in die neuen Update-Dateien Code ein, der das Update verhindert.</p>
        <p>
            Bitte deinstalliere oder konfiguriere Avast (Antivirus und eventuelle Seitenprogramme von Avast) und lade
            die Seite neu.
        </p>
        <p>
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/18506568548626-Kaspersky-verhindert-Update-von-autoiXpert"
                rel="noopener"
                target="_blank">
                Artikel zu den Details
            </a>
            (behandelt Kaspersky, trifft aber gleichermaßen auf Avast zu)
        </p>
    </ng-container>
    <!--============================================-->
    <!-- END Avast -->
    <!--============================================-->

    <!--============================================-->
    <!-- Default -->
    <!--============================================-->
    <ng-container *ngSwitchDefault>
        <p>
            Irgend ein Programm auf deinem Computer fügt in die neuen Update-Dateien Code ein, der das Update
            verhindert.
        </p>
        <p>
            Manchmal ist die Ursache ein Antivirusprogramm. Bitte deinstalliere es oder konfiguriere es so, dass
            autoiXpert nicht mehr betroffen ist, und lade die Seite neu.
        </p>
        <p>
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/18506568548626-Kaspersky-verhindert-Update-von-autoiXpert"
                rel="noopener"
                target="_blank">
                Artikel zu den Details
            </a>
            (behandelt Kaspersky, trifft aber gleichermaßen auf andere Antivirensoftwares zu)
        </p>
    </ng-container>
    <!--============================================-->
    <!-- END Default -->
    <!--============================================-->
</mat-dialog-content>
<mat-dialog-actions>
    <button id="confirm-button" (click)="confirm()">Erledigt. Neu laden!</button>
    <button id="abort-button" class="secondary" (click)="cancel()">Updates weiterhin blockieren</button>
</mat-dialog-actions>

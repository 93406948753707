import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject, InjectionToken } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { translateReportType } from '@autoixpert/lib/report/translate-report-type';
import { Report } from '@autoixpert/models/reports/report';
import { RepairConfirmationCreationInstructionsDialogComponent } from '../../../reports/repair-confirmation-creation-instructions-dialog/repair-confirmation-creation-instructions-dialog.component';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';

@Component({
    selector: 'report-type-dialog',
    templateUrl: 'report-type-dialog.component.html',
    styleUrls: ['report-type-dialog.component.scss'],
    animations: [
        fadeInAndSlideAnimation({ direction: 'fromBottom', name: 'fadeInAndSlideChangeNotification' }),
        fadeInAndSlideAnimation({ direction: 'fromTop', name: 'fadeInAndSlideDialog' }),
    ],
})
export class ReportTypeDialogComponent {
    /**
     * The use case will be automatically set when selecting a report type and the this.report property is filled or not.
     */
    public useCase: 'reportTypeChange' | 'reportCreation';
    public itemClicked: boolean = false;

    public selectedType: Report['type'];

    private repairConfirmationDialogRef: MatLegacyDialogRef<RepairConfirmationCreationInstructionsDialogComponent>;
    private customInvoiceDialogRef: MatLegacyDialogRef<ConfirmDialogComponent>;

    constructor(
        @Inject(REPORT_TYPE_DIALOG) public report: Report,
        @Inject(REPORT_TYPE_CHANGE_SUBJECT) public reportTypeChange: Subject<Report['type'] | 'repairConfirmation'>,
        private dialog: MatDialog,
        private overlayRef: OverlayRef,
        private router: Router,
    ) {}

    ngOnInit() {
        // Report won't be present when dialog is used for report creation.
        if (this.report) {
            this.selectedType = this.report.type;
        }
        this.useCase = this.report ? 'reportTypeChange' : 'reportCreation';
    }

    //*****************************************************************************
    //  Change Report Type
    //****************************************************************************/
    public setReportType(type: Report['type']) {
        this.selectedType = type;

        if (!this.report) {
            this.emitReportTypeChange();
        }

        this.itemClicked = true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Change Report Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Repair Confirmation
    //****************************************************************************/
    /**
     * Many customers expect the repair confirmation to be a separate case type, especially
     * if they've used AudaFusion before.
     * This dialog tells them how to add a repair confirmation to an existing case, optionally
     * creating a new base case first.
     */
    public async openDialogForRepairConfirmation() {
        this.repairConfirmationDialogRef = this.dialog.open<
            RepairConfirmationCreationInstructionsDialogComponent,
            undefined,
            { action: 'none' | 'createReportWithoutTokenOrInvoice' }
        >(RepairConfirmationCreationInstructionsDialogComponent);

        const decision = await this.repairConfirmationDialogRef.afterClosed().toPromise();

        // Guard against the user hitting escape.
        if (decision) {
            if (decision.action === 'createReportWithoutTokenOrInvoice') {
                this.reportTypeChange.next('repairConfirmation');
            }
            this.close();
        }

        this.repairConfirmationDialogRef = undefined;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Repair Confirmation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Invoice
    //****************************************************************************/
    /**
     * Many customers expect the invoice to be creatable from the report list.
     */
    public async openDialogForNewInvoice() {
        this.customInvoiceDialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(
            ConfirmDialogComponent,
            {
                data: {
                    heading: 'Freie Rechnung',
                    content:
                        'Rechnungen ohne Gutachten kannst du in der Rechnungsliste über den Button oben rechts schreiben.',
                    confirmLabel: 'Rechnungsliste öffnen',
                    cancelLabel: 'Abbrechen',
                },
            },
        );

        const decision = await this.customInvoiceDialogRef.afterClosed().toPromise();

        if (decision) {
            this.overlayRef.detach();
            await this.router.navigate(['/Rechnungen']);
        }

        this.customInvoiceDialogRef = undefined;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Translation
    //****************************************************************************/
    public translateReportType = translateReportType;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Translation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitReportTypeChange() {
        // Emit change event
        this.reportTypeChange.next(this.selectedType);

        // Close dialog
        this.overlayRef.detach();
        this.itemClicked = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public close(): void {
        this.overlayRef.detach();
    }

    @HostBinding('@fadeInAndSlideDialog')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlideDialog.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcut(event: KeyboardEvent) {
        // Ignore shortcuts while child dialog is open.
        if (this.repairConfirmationDialogRef) return;

        switch (event.key) {
            case 'Escape':
                this.close();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls
    /////////////////////////////////////////////////////////////////////////////*/
}

export const REPORT_TYPE_DIALOG = new InjectionToken<Subject<Report>>('REPORT_TYPE_DIALOG');
export const REPORT_TYPE_CHANGE_SUBJECT = new InjectionToken<Subject<Report['type']>>('REPORT_TYPE_CHANGE_SUBJECT');

import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrderItem } from '@autoixpert/models/documents/document-order-item';
import { Report } from '@autoixpert/models/reports/report';
import { DocumentGroupForDocumentRemoval } from './remove-document-from-report';
import { setReportDocumentInclusionStatus } from './set-report-document-inclusion-status';

/**
 * Activate or deactivate a document for inclusion as an email attachment or as a part of the full report PDF.
 */
export function setReportDocumentInclusionStatusByType({
    documentType,
    report,
    documentGroup = 'both',
    includedInFullDocument,
}: {
    documentType: DocumentMetadata['type'];
    report: Report;
    documentGroup: DocumentGroupForDocumentRemoval;
    includedInFullDocument: DocumentOrderItem['includedInFullDocument'];
}): void {
    const documents = report.documents.filter((document) => document.type === documentType);

    for (const document of documents) {
        setReportDocumentInclusionStatus({
            document,
            report,
            documentGroup,
            includedInFullDocument,
        });
    }
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container dialog-scrollable-area">
        <div class="card-toolbar">
            <mat-icon
                class="toolbar-icon"
                (click)="showPaymentPeriodSettings()"
                *ngIf="!paymentPeriodSettingsShown"
                matTooltip="Mahnfristen einstellen">
                settings
            </mat-icon>
            <mat-icon
                *ngIf="this.paymentPeriodSettingsShown === false"
                class="toolbar-icon"
                [matMenuTriggerFor]="paymentReminderCardMenu">
                more_vert
            </mat-icon>
            <mat-menu #paymentReminderCardMenu="matMenu">
                <mat-option (click)="resetAllPaymentReminders()">Alle Mahnungen löschen</mat-option>
            </mat-menu>
        </div>
        <ng-container *ngIf="!paymentPeriodSettingsShown">
            <h2>Erinnern & Mahnen</h2>

            <!--============================================-->
            <!-- Recipient -->
            <!--============================================-->
            <!-- Only show the recipient section if there is more than one possible recipient. Otherwise, the reminder recipient is always the invoice recipient -->
            <section id="recipient-section" *ngIf="invoice.insurance || invoice.lawyer || invoice.claimant">
                <h3>Empfänger</h3>
                <div class="options-row grid">
                    <!--********** Invoice Recipient **********-->
                    <div
                        class="option-container"
                        (click)="selectRecipientRole('invoiceRecipient')"
                        [ngClass]="{ active: selectedPaymentReminder.recipientRole === 'invoiceRecipient' }"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0">
                        <img
                            src="/assets/images/icons/invoiceRecipient-blue_48.png"
                            alt=""
                            class="option-image recipient-icon" />
                        <div class="option-title-container">
                            <div class="option-title">Rechnungsempfänger</div>
                        </div>
                    </div>

                    <!--********** Claimant **********-->
                    <div
                        class="option-container"
                        *ngIf="invoice.claimant && !isClaimantEqualToInvoiceRecipient()"
                        (click)="selectRecipientRole('claimant')"
                        [ngClass]="{ active: selectedPaymentReminder.recipientRole === 'claimant' }"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0">
                        <img
                            src="/assets/images/icons/claimant-blue_48.png"
                            alt=""
                            class="option-image recipient-icon" />
                        <div class="option-title-container">
                            <div class="option-title">Anspruchsteller</div>
                        </div>
                    </div>
                    <div
                        class="option-container"
                        *ngIf="invoice.insurance?.contactPerson.organization"
                        (click)="selectRecipientRole('insurance')"
                        [ngClass]="{ active: selectedPaymentReminder.recipientRole === 'insurance' }"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0">
                        <img
                            src="/assets/images/icons/insurance-shield_48.png"
                            alt=""
                            class="option-image recipient-icon" />
                        <div class="option-title-container">
                            <div class="option-title">Versicherung</div>
                        </div>
                    </div>
                    <div
                        class="option-container"
                        *ngIf="invoice.lawyer?.contactPerson.organization || invoice.lawyer?.contactPerson.lastName"
                        (click)="selectRecipientRole('lawyer')"
                        [ngClass]="{ active: selectedPaymentReminder.recipientRole === 'lawyer' }"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0">
                        <img src="/assets/images/icons/lawyer-blue_48.png" alt="" class="option-image recipient-icon" />
                        <div class="option-title-container">
                            <div class="option-title">Anwalt</div>
                        </div>
                    </div>
                </div>
            </section>
            <!--============================================-->
            <!-- END Recipient -->
            <!--============================================-->

            <!--============================================-->
            <!-- Reminder Level -->
            <!--============================================-->
            <section id="reminder-level-section">
                <h3>Mahnstufe</h3>
                <div class="options-row grid">
                    <div
                        class="option-container"
                        (click)="selectPaymentReminderLevel('level0')"
                        [ngClass]="{
                            active: selectedPaymentReminder && selectedPaymentReminder.level === 'level0',
                            'payment-reminder-exists': paymentReminderExists('level0'),
                        }"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0"
                        [matTooltip]="
                            paymentReminderExists('level0')
                                ? 'Ein Mahndokument dieser Stufe existiert.'
                                : 'Erstelle ein Mahndokument für diese Stufe.'
                        ">
                        <img src="/assets/images/icons/payment-reminder-blue.png" alt="" class="option-image" />
                        <div class="option-title-container">
                            <div class="option-title">Erinnerung</div>
                        </div>
                    </div>
                    <div
                        class="option-container"
                        (click)="selectPaymentReminderLevel('level1')"
                        [ngClass]="{
                            active: selectedPaymentReminder && selectedPaymentReminder.level === 'level1',
                            'payment-reminder-exists': paymentReminderExists('level1'),
                        }"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0"
                        [matTooltip]="
                            paymentReminderExists('level1')
                                ? 'Ein Mahndokument dieser Stufe existiert.'
                                : 'Erstelle ein Mahndokument für diese Stufe.'
                        ">
                        <img src="/assets/images/icons/payment-reminder-yellow.png" alt="" class="option-image" />
                        <div class="option-title-container">
                            <div class="option-title">1. Mahnung</div>
                        </div>
                    </div>
                    <div
                        class="option-container"
                        (click)="selectPaymentReminderLevel('level2')"
                        [ngClass]="{
                            active: selectedPaymentReminder && selectedPaymentReminder.level === 'level2',
                            'payment-reminder-exists': paymentReminderExists('level2'),
                        }"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0"
                        [matTooltip]="
                            paymentReminderExists('level2')
                                ? 'Ein Mahndokument dieser Stufe existiert.'
                                : 'Erstelle ein Mahndokument für diese Stufe.'
                        ">
                        <img src="/assets/images/icons/payment-reminder-red.png" alt="" class="option-image" />
                        <div class="option-title-container">
                            <div class="option-title">2. Mahnung</div>
                        </div>
                    </div>
                    <div
                        class="option-container"
                        (click)="selectPaymentReminderLevel('level3')"
                        [ngClass]="{
                            active: selectedPaymentReminder && selectedPaymentReminder.level === 'level3',
                            'payment-reminder-exists': paymentReminderExists('level3'),
                        }"
                        (keydown)="triggerClickEventOnSpaceBarPress($event)"
                        tabindex="0"
                        [matTooltip]="
                            paymentReminderExists('level3')
                                ? 'Ein Mahndokument dieser Stufe existiert.'
                                : 'Erstelle ein Mahndokument für diese Stufe.'
                        ">
                        <img src="/assets/images/icons/payment-reminder-purple.png" alt="" class="option-image" />
                        <div class="option-title-container">
                            <div class="option-title">3. Mahnung</div>
                        </div>
                    </div>
                </div>
            </section>
            <!--============================================-->
            <!-- END Reminder Level -->
            <!--============================================-->

            <!--============================================-->
            <!-- Inputs -->
            <!--============================================-->
            <section id="inputs-section" [ngClass]="{ 'read-only': selectedPaymentReminder.wasCreatedBefore }">
                <!--********** Existing Payment Reminder **********-->
                <div
                    *ngIf="selectedPaymentReminder.wasCreatedBefore"
                    class="already-created-payment-reminder-notification">
                    <mat-icon class="medium-icon outlined">draft</mat-icon>
                    <div class="label existing-payment-reminder-label">
                        Am {{ selectedPaymentReminder.date | date: 'dd.MM.yyyy' }} von
                        {{ selectedPaymentReminderAssessorName }} erstellt.
                    </div>
                    <mat-icon
                        id="delete-payment-reminder-icon"
                        class="toolbar-icon cursor-pointer outlined"
                        (click)="deleteSelectedPaymentReminder()"
                        matTooltip="Diese Mahnung löschen. Das Dokument aus Druck & Versand wird ebenfalls entfernt. Du kannst die Mahnung danach neu anlegen.">
                        delete
                    </mat-icon>
                </div>

                <!--********** Edit Payment Reminder Fields **********-->
                <div id="inputs-row">
                    <div id="payment-reminder-amount-container">
                        <currency-input
                            [disabled]="selectedPaymentReminder.wasCreatedBefore"
                            [(value)]="selectedPaymentReminder.amountNet"
                            placeholder="Mahnbetrag"></currency-input>
                        <mat-icon
                            *ngIf="!selectedPaymentReminder.wasCreatedBefore"
                            class="small-icon"
                            (click)="insertUnpaidAmount()"
                            matTooltip="Offenen Betrag einfügen">
                            refresh
                        </mat-icon>
                    </div>
                    <date-input
                        id="payment-reminder-date"
                        [disabled]="selectedPaymentReminder.wasCreatedBefore"
                        [(date)]="selectedPaymentReminder.date"
                        placeholder="Mahndatum"></date-input>
                    <date-input
                        [disabled]="selectedPaymentReminder.wasCreatedBefore"
                        [(date)]="selectedPaymentReminder.dueDate"
                        placeholder="Zahlung bis"></date-input>
                </div>

                <!--********** Responsible user **********-->
                <div *ngIf="!selectedPaymentReminder.wasCreatedBefore">
                    <mat-form-field class="mat-block">
                        <mat-select placeholder="Ersteller" [(ngModel)]="selectedPaymentReminder.senderId">
                            <mat-option *ngFor="let assessor of teamMembers" [value]="assessor._id">
                                {{ assessor.firstName }} {{ assessor.lastName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </section>
            <!--============================================-->
            <!-- END Inputs -->
            <!--============================================-->

            <!--============================================-->
            <!-- Buttons -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button
                    [disabled]="selectedPaymentReminder.wasCreatedBefore"
                    [matTooltip]="
                        selectedPaymentReminder.wasCreatedBefore
                            ? 'Das Dokument wurde für diesen Empfänger bereits erstellt.'
                            : 'Erstellt das Dokument. Du kannst das Dokument bei Druck & Versand versenden.'
                    "
                    [ngClass]="{ disabled: selectedPaymentReminder.wasCreatedBefore }"
                    (click)="createAndSaveNewPaymentReminder(); closeEditor()">
                    <ng-container [ngSwitch]="selectedPaymentReminder.level">
                        <span *ngSwitchCase="'level0'">Erinnerung</span>
                        <span *ngSwitchCase="'level1'">1. Mahnung</span>
                        <span *ngSwitchCase="'level2'">2. Mahnung</span>
                        <span *ngSwitchCase="'level3'">3. Mahnung</span>
                    </ng-container>
                    &nbsp;erstellen
                </button>
                <button class="secondary" (click)="previewPaymentReminderDocument()">
                    <img
                        src="/assets/images/icons/loading-indicator.svg"
                        class="pending-icon"
                        *ngIf="previewDownloadPending" />
                    Vorschau
                </button>
            </div>
            <!--============================================-->
            <!-- END Buttons -->
            <!--============================================-->
        </ng-container>

        <ng-container *ngIf="paymentPeriodSettingsShown">
            <h2 class="margin-bottom-10">Mahnfristen</h2>
            <div class="label text-align-center margin-bottom-30">
                Dauer, bis eine gemahnte Rechnung wieder
                <br />
                als zu mahnen angezeigt wird.
            </div>
            <!--============================================-->
            <!-- Payment Period Settings -->
            <!--============================================-->
            <section id="payment-period-settings-section">
                <div id="payment-period-settings-row">
                    <div class="payment-period-setting-container">
                        <mat-form-field class="period-setting-time-offset">
                            <input
                                matInput
                                placeholder="Erinnerung"
                                number-input
                                [minimumFractionDigits]="0"
                                [maximumFractionDigits]="0"
                                [(number)]="userPreferences.paymentReminderLevel0TimeOffset" />
                            <span matSuffix>Tage</span>
                        </mat-form-field>
                        <mat-form-field class="period-setting-time-offset-unit">
                            <mat-select
                                placeholder="Einheit"
                                [(ngModel)]="userPreferences.paymentReminderLevel0TimeOffsetUnit">
                                <mat-option value="businessDays">Werktage (Mo - Fr)</mat-option>
                                <mat-option value="calendarDays">Kalendertage</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="payment-period-setting-container">
                        <mat-form-field class="period-setting-time-offset">
                            <input
                                matInput
                                placeholder="1. Mahnung"
                                number-input
                                [minimumFractionDigits]="0"
                                [maximumFractionDigits]="0"
                                [(number)]="userPreferences.paymentReminderLevel1TimeOffset" />
                            <span matSuffix>Tage</span>
                        </mat-form-field>
                        <mat-form-field class="period-setting-time-offset-unit">
                            <mat-select
                                placeholder="Einheit"
                                [(ngModel)]="userPreferences.paymentReminderLevel1TimeOffsetUnit">
                                <mat-option value="businessDays">Werktage (Mo - Fr)</mat-option>
                                <mat-option value="calendarDays">Kalendertage</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="payment-period-setting-container">
                        <mat-form-field class="period-setting-time-offset">
                            <input
                                matInput
                                placeholder="2. Mahnung"
                                number-input
                                [minimumFractionDigits]="0"
                                [maximumFractionDigits]="0"
                                [(number)]="userPreferences.paymentReminderLevel2TimeOffset" />
                            <span matSuffix>Tage</span>
                        </mat-form-field>
                        <mat-form-field class="period-setting-time-offset-unit">
                            <mat-select
                                placeholder="Einheit"
                                [(ngModel)]="userPreferences.paymentReminderLevel2TimeOffsetUnit">
                                <mat-option value="businessDays">Werktage (Mo - Fr)</mat-option>
                                <mat-option value="calendarDays">Kalendertage</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="payment-period-setting-container">
                        <mat-form-field class="period-setting-time-offset">
                            <input
                                matInput
                                placeholder="3. Mahnung"
                                number-input
                                [minimumFractionDigits]="0"
                                [maximumFractionDigits]="0"
                                [(number)]="userPreferences.paymentReminderLevel3TimeOffset" />
                            <span matSuffix>Tage</span>
                        </mat-form-field>
                        <mat-form-field class="period-setting-time-offset-unit">
                            <mat-select
                                placeholder="Einheit"
                                [(ngModel)]="userPreferences.paymentReminderLevel3TimeOffsetUnit">
                                <mat-option value="businessDays">Werktage (Mo - Fr)</mat-option>
                                <mat-option value="calendarDays">Kalendertage</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </section>
            <!--============================================-->
            <!-- END Payment Period Settings -->
            <!--============================================-->
            <!--============================================-->
            <!-- Buttons Period Settings -->
            <!--============================================-->
            <div class="dialog-buttons-container">
                <button (click)="hidePaymentPeriodSettings()">Schließen</button>
            </div>
            <!--============================================-->
            <!-- END Buttons Period Settings -->
            <!--============================================-->
        </ng-container>
    </div>
</div>

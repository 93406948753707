import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExternalApiTeamCredits } from '@autoixpert/models/teams/external-api-team-credits';

@Injectable()
export class ExternalApiTeamCreditsService {
    constructor(private readonly httpClient: HttpClient) {}

    find() {
        return this.httpClient.get<ExternalApiTeamCredits>('/api/v0/externalApiTeamCredits');
    }
}

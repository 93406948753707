import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { translateAccessRightToGerman } from '@autoixpert/lib/users/translate-access-right-to-german';
import { HexColor } from '@autoixpert/models/hex-color';
import { ColorPickerPatch } from '@autoixpert/models/teams/color-picker-patch';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { TeamService } from '../../services/team.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    animations: [
        fadeInAndSlideAnimation({
            duration: 150,
            slideDistance: 10,
        }),
    ],
    host: {
        '[class.card]': 'true',
    },
})
export class ColorPickerComponent implements OnInit, AfterViewInit {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    @Input() initiallySelectedColor: HexColor;
    @Output() colorSelected: EventEmitter<HexColor> = new EventEmitter();

    protected user: User;
    protected team: Team;

    protected viewInitializationInProgress: boolean = true;

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        if (!Array.isArray(this.team.preferences.colorPickerPatches)) {
            this.team.preferences.colorPickerPatches = [];
            this.saveTeam();
        }
    }

    ngAfterViewInit() {
        this.viewInitializationInProgress = false;
        this.changeDetectorRef.detectChanges();
    }

    protected isEditModeActive: boolean;

    //*****************************************************************************
    //  Add Color
    //****************************************************************************/
    protected addColor() {
        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.displayError_missingAccessRight();
            return;
        }

        this.team.preferences.colorPickerPatches.push(new ColorPickerPatch());
        this.saveTeam();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add Color
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Selection
    //****************************************************************************/
    /**
     * Open the browser's color picker if...
     * - the patch is pristine (= no color set yet).
     * - edit mode is active.
     */
    public handleColorPatchClick({
        colorPatch,
        colorInputElement,
    }: {
        colorPatch: ColorPickerPatch;
        colorInputElement: HTMLInputElement;
    }) {
        // Disallow if the color has already been set, unless we're in edit mode.
        if (colorPatch.hexCode && !this.isEditModeActive) {
            this.emitColorSelected(colorPatch.hexCode);
            return;
        }

        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.displayError_missingAccessRight();
            return;
        }

        colorInputElement.click();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Edit Mode
    //****************************************************************************/
    protected enterEditMode() {
        if (!this.user.accessRights.editTextsAndDocumentBuildingBlocks) {
            this.displayError_missingAccessRight();
            return;
        }

        this.isEditModeActive = true;
    }
    protected leaveEditMode() {
        this.isEditModeActive = false;
    }

    /**
     * Remove color at specified index.
     *
     * We don't pass the color because the color may not be unique, hence targeting the wrong index.
     */
    protected async deleteColor(colorPatch: ColorPickerPatch) {
        const colorToDelete: HexColor = colorPatch.hexCode;
        const index = this.team.preferences.colorPickerPatches.indexOf(colorPatch);

        removeFromArray(colorPatch, this.team.preferences.colorPickerPatches);
        this.saveTeam();

        const toast = this.toastService.info('Farbe entfernt', 'Zum Wiederherstellen klicken.');
        toast.click.subscribe(() => {
            this.team.preferences.colorPickerPatches.splice(index, 0, new ColorPickerPatch({ hexCode: colorToDelete }));
            this.saveTeam();
        });
    }

    protected saveTeam() {
        this.teamService.put(this.team);
    }

    protected displayError_missingAccessRight() {
        this.toastService.error(
            'Zugriffsrecht fehlt',
            `Das Recht ${translateAccessRightToGerman(
                'editTextsAndDocumentBuildingBlocks',
            )} ist für die Anpassung der Farben erforderlich. Bitte kontaktiere deinen Admin.`,
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    protected emitColorSelected(color: HexColor) {
        this.colorSelected.emit(color);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;
}

import { animate, group, query, style, transition, trigger } from '@angular/animations';

export function successMessageOverlayAnimation({ distance = 20 }: { distance?: number } = {}) {
    return trigger('successMessageOverlay', [
        transition(
            ':enter',
            group([
                // Animate the overlay container.
                style({
                    opacity: 0,
                }),
                animate(
                    '300ms ease',
                    style({
                        opacity: 1,
                    }),
                ),

                // Animate direct child elements.
                query(':scope > *', [
                    style({
                        transform: `translate3d(0, ${distance}px, 0)`,
                        opacity: 0,
                    }),
                    animate(
                        '300ms ease',
                        style({
                            transform: 'translate3d(0, 0, 0)',
                            opacity: 1,
                        }),
                    ),
                ]),
            ]),
        ),
        transition(':leave', [
            group([
                animate(
                    '300ms ease',
                    style({
                        opacity: 0,
                    }),
                ),
                // Animate direct child elements.
                query(':scope > *', [
                    animate(
                        '300ms ease',
                        style({
                            transform: `translate3d(0, ${distance}px, 0)`,
                            opacity: 1,
                        }),
                    ),
                ]),
            ]),
        ]),
    ]);
}

import { generateId } from '../../lib/generate-id';
import { FieldConfig } from './field-config';

/**
 * Stores custom field value and reference to a field config on the report.
 *
 * The actual field written onto a report.
 *
 * The central configuration of fields is done through CustomFieldConfig objects.
 */
export class CustomField {
    constructor(template: Partial<CustomField> = {}) {
        Object.assign(this, template);
    }

    /**
     * ID unique to each record.
     */
    _id: string = generateId();

    /**
     * Reference to the template/settings object that this field has been cloned from.
     */
    fieldConfigId: string;

    // Saved to improve error handling if the reference to the custom field config breaks, e.g. because the field was deleted. The name is easier for
    // a user and for the autoiXpert team to understand than a random ID.
    name: FieldConfig['name'];

    value: number | string | boolean | any;
}

import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject, InjectionToken } from '@angular/core';
import { fadeOutAnimation } from '@autoixpert/animations/fade-out.animation';

export const IS_AUTHENTICATED_AS_ADMIN_TOKEN = new InjectionToken<boolean>('IS_AUTHENTICATED_AS_ADMIN_TOKEN');

@Component({
    selector: 'welcome-splash-screen',
    templateUrl: './welcome-splash-screen.component.html',
    styleUrls: ['./welcome-splash-screen.component.scss'],
    animations: [fadeOutAnimation()],
})
export class WelcomeSplashScreenComponent {
    constructor(
        private overlayRef: OverlayRef,
        @Inject(IS_AUTHENTICATED_AS_ADMIN_TOKEN) public isAuthenticatedAsAdminToken: boolean,
    ) {}
    public closeSplashScreen() {
        this.overlayRef.detach();
    }

    @HostBinding('@fadeOut')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeOut.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                event.stopPropagation();
                this.closeSplashScreen();
                break;
        }
    }
}

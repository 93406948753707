<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!--============================================-->
        <!-- Left Column - Garage Info & Fee Sets -->
        <!--============================================-->
        <div id="left-column">
            <h2>Kostensätze</h2>

            <!--============================================-->
            <!-- Garage Info -->
            <!--============================================-->
            <div id="garage-info-container">
                <!--********** Garage Icon **********-->
                <div id="garage-info-icon-container">
                    <mat-icon class="medium-icon">build</mat-icon>
                </div>

                <!--********** Garage Name & Location **********-->
                <div id="garage-name-and-location-container" class="text-overflow-ellipsis">
                    <div id="garage-name" class="text-overflow-ellipsis" [matTooltip]="garageContact.organization">
                        {{ garageContact.organization || 'kein Name' }}
                    </div>
                    <div class="label text-overflow-ellipsis">{{ garageContact.zip }} {{ garageContact.city }}</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Garage Info -->
            <!--============================================-->

            <!--============================================-->
            <!-- Fee Set List -->
            <!--============================================-->
            <section id="fee-sets-section">
                <div id="fee-set-list" cdkDropList (cdkDropListDropped)="reorderGarageFeeSets($event)">
                    <!--********** Item **********-->
                    <div
                        class="fee-set-list-item"
                        *ngFor="let feeSet of garageContact.garageFeeSets"
                        cdkDrag
                        (click)="selectFeeSet(feeSet)"
                        [ngClass]="{ selected: selectedFeeSet === feeSet }">
                        <!--********** Title & Date **********-->
                        <div class="fee-set-title-and-date-container">
                            <!--********** Title **********-->
                            <div class="fee-set-title text-overflow-ellipsis" [matTooltip]="feeSet.title">
                                <ng-container *ngIf="feeSet.title">{{ feeSet.title }}</ng-container>
                                <span *ngIf="!feeSet.title" class="italic">kein Titel</span>
                            </div>
                            <!--********** Date **********-->
                            <div class="label" matTooltip="angefragt am">{{ feeSet.validFrom | moment }}</div>
                        </div>

                        <!--********** Is Default **********-->
                        <mat-icon
                            class="default-marker medium-icon"
                            [ngClass]="{ 'is-default': feeSet.isDefault }"
                            (click)="markFeeSetAsFavorite(feeSet)"
                            [matTooltip]="
                                feeSet.isDefault
                                    ? 'Dieser Kostensatz wird standardmäßig vorbelegt'
                                    : 'Bei der Werkstattauswahl vorauswählen'
                            ">
                            star
                        </mat-icon>

                        <!--********** Submenu **********-->
                        <mat-icon [matMenuTriggerFor]="feeSetItemSubmenu" class="toolbar-icon">more_vert</mat-icon>
                        <mat-menu #feeSetItemSubmenu>
                            <!--********** Copy **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                [disabled]="disabled"
                                (click)="copyFeeSet(feeSet)"
                                matTooltip="Kostensätze kopieren">
                                <mat-icon>content_copy</mat-icon>
                                Kopieren
                            </a>

                            <!--********** Delete **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                [disabled]="disabled"
                                (click)="deleteFeeSet(feeSet)"
                                matTooltip="Kostensätze löschen">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </a>
                        </mat-menu>
                    </div>
                </div>

                <!--********** New Fee Set **********-->
                <div class="dashed-button" (click)="createFeeSet()" [class.disabled]="disabled">+ Neue Kostensätze</div>
            </section>
            <!--============================================-->
            <!-- END Fee Set List -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Left Column - Garage Info & Fee Sets -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column - Editor -->
        <!--============================================-->
        <div id="right-column" *ngIf="selectedFeeSet">
            <mat-icon class="dialog-close-icon" (click)="closeGarageFeesDialog()" matTooltip="Schließen">
                close
            </mat-icon>

            <h2 class="text-align-center">{{ selectedFeeSet.title || 'Kostensätze' }} bearbeiten</h2>

            <!--============================================-->
            <!-- Title, Date and Work Fraction Units -->
            <!--============================================-->
            <section id="header-data" class="section-card">
                <!--********** Title **********-->
                <mat-form-field id="title-input" class="light-mode">
                    <input
                        matInput
                        placeholder="Titel"
                        [(ngModel)]="selectedFeeSet.title"
                        (change)="emitDataChange()"
                        [disabled]="disabled" />
                </mat-form-field>

                <!--********** Date **********-->
                <div id="fee-set-date">
                    <date-input
                        placeholder="Angefragt am"
                        [(date)]="selectedFeeSet.validFrom"
                        (dateChange)="handleValidFromDateUserChange()"
                        [lightMode]="true"
                        [disabled]="disabled"></date-input>
                    <mat-icon
                        class="toolbar-icon"
                        *ngIf="isIconForValidFromDateVisible()"
                        (click)="setValidFromDateToFormerValue()"
                        matTooltip="Das Datum wurde automatisch auf den heutigen Wert angepasst. Klicke hier, um den vorherigen Wert ({{
                            getValidFromDateFormerValue() | date
                        }}) einzufügen.">
                        undo
                    </mat-icon>
                </div>

                <!--********** Work Fraction Unit **********-->
                <div id="wage-unit-selection-container">
                    <div id="wage-unit-hours-container">
                        <div
                            id="wage-unit-hours"
                            (click)="selectWorkFractionUnit(1)"
                            [ngClass]="{ active: selectedFeeSet.selectedWorkFractionUnit === 1, disabled: disabled }"
                            matTooltip="Arbeitslohn pro Stunde">
                            Std
                        </div>
                    </div>
                    <div id="wage-unit-labor-fractions-container">
                        <span class="label">AW:</span>
                        <div
                            class="wage-unit-labor-fraction"
                            (click)="selectWorkFractionUnit(10)"
                            [ngClass]="{ active: selectedFeeSet.selectedWorkFractionUnit === 10, disabled: disabled }"
                            matTooltip="10er Arbeitswerte">
                            10
                        </div>
                        <div
                            class="wage-unit-labor-fraction"
                            (click)="selectWorkFractionUnit(12)"
                            [ngClass]="{ active: selectedFeeSet.selectedWorkFractionUnit === 12, disabled: disabled }"
                            matTooltip="12er Arbeitswerte">
                            12
                        </div>
                        <div
                            class="wage-unit-labor-fraction"
                            (click)="selectWorkFractionUnit(100)"
                            [ngClass]="{ active: selectedFeeSet.selectedWorkFractionUnit === 100, disabled: disabled }"
                            matTooltip="100er Arbeitswerte">
                            100
                        </div>
                    </div>
                </div>
            </section>
            <!--============================================-->
            <!-- END Title, Date and Work Fraction Units -->
            <!--============================================-->

            <!--============================================-->
            <!-- Plausibility Check Warning -->
            <!--============================================-->
            <div id="plausibility-check-warning-note-container">
                <!--********** Too High **********-->
                <div class="info-note warning-note" *ngIf="areGarageFeesImplausiblyHigh()" @fadeInAndSlide>
                    <mat-icon class="medium-icon warning">warning</mat-icon>
                    <div>
                        Mindestens eine Lohnangabe wirkt sehr hoch für die ausgewählte Zeiteinheit
                        <ng-container [ngSwitch]="selectedFeeSet.selectedWorkFractionUnit">
                            <ng-container *ngSwitchCase="1">"Stunden"</ng-container>
                            <ng-container *ngSwitchCase="10">"10er AW"</ng-container>
                            <ng-container *ngSwitchCase="12">"12er AW"</ng-container>
                            <ng-container *ngSwitchCase="100">"100er AW"</ng-container>
                        </ng-container>
                        <span>.</span>
                    </div>
                </div>

                <!--********** Too Low **********-->
                <div class="info-note warning-note" *ngIf="areGarageFeesImplausiblyLow()" @fadeInAndSlide>
                    <mat-icon class="medium-icon warning">warning</mat-icon>
                    <div>
                        Mindestens eine Lohnangabe wirkt sehr niedrig für die ausgewählte Zeiteinheit
                        <ng-container [ngSwitch]="selectedFeeSet.selectedWorkFractionUnit">
                            <ng-container *ngSwitchCase="1">"Stunden"</ng-container>
                            <ng-container *ngSwitchCase="10">"10er AW"</ng-container>
                            <ng-container *ngSwitchCase="12">"12er AW"</ng-container>
                            <ng-container *ngSwitchCase="100">"100er AW"</ng-container>
                        </ng-container>
                        <span>.</span>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Plausibility Check Warning -->
            <!--============================================-->

            <!--============================================-->
            <!-- Main Wages -->
            <!--============================================-->
            <section id="main-wages-container" class="section-card">
                <!--============================================-->
                <!-- Wage Level Titles -->
                <!--============================================-->
                <div id="wage-levels-container">
                    <!--********** Level 1 **********-->
                    <div class="wage-level-row section-heading-and-icon-container">
                        <div class="wage-level-heading">Stufe 1</div>
                        <mat-icon
                            class="toolbar-icon"
                            *ngIf="!secondWageLevelShown"
                            (click)="showNextWageLevel()"
                            matTooltip="Stufe 2 einblenden {{ '\n\n' }}Nur erforderlich, falls die Stufen abweichen.">
                            add
                        </mat-icon>
                    </div>

                    <!--********** Level 2 **********-->
                    <div class="wage-level-row" *ngIf="secondWageLevelShown">
                        <div class="wage-level-heading">Stufe 2</div>
                        <mat-icon
                            class="toolbar-icon"
                            *ngIf="!thirdWageLevelShown"
                            (click)="showNextWageLevel()"
                            matTooltip="Stufe 3 einblenden">
                            add
                        </mat-icon>
                    </div>

                    <!--********** Level 3 **********-->
                    <div class="wage-level-row" *ngIf="thirdWageLevelShown">
                        <div class="wage-level-heading">Stufe 3</div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Wage Level Titles -->
                <!--============================================-->

                <!--============================================-->
                <!-- Mechanics -->
                <!--============================================-->
                <div class="wage-group">
                    <!--********** Title **********-->
                    <div class="wage-group-title-container">
                        <mat-icon class="medium-icon">build</mat-icon>
                        <div class="wage-group-title">Mechanik</div>
                    </div>

                    <!--********** Wage Inputs **********-->
                    <currency-input
                        placeholder="Mechanik 1"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.mechanics.firstLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        [tabindex]="1"
                        [disabled]="disabled"></currency-input>
                    <currency-input
                        placeholder="Mechanik 2"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.mechanics.secondLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        *ngIf="secondWageLevelShown"
                        [tabindex]="2"
                        [disabled]="disabled"></currency-input>
                    <currency-input
                        placeholder="Mechanik 3"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.mechanics.thirdLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        *ngIf="thirdWageLevelShown"
                        [tabindex]="3"
                        [disabled]="disabled"></currency-input>
                </div>
                <!--============================================-->
                <!-- END Mechanics -->
                <!--============================================-->

                <!--============================================-->
                <!-- Electrics -->
                <!--============================================-->
                <div class="wage-group">
                    <!--********** Title **********-->
                    <div class="wage-group-title-container">
                        <mat-icon class="medium-icon">electric_bolt</mat-icon>
                        <div class="wage-group-title">Elektrik</div>
                    </div>

                    <!--********** Wage Inputs **********-->
                    <currency-input
                        placeholder="Elektrik 1"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.electrics.firstLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        [tabindex]="1"
                        [disabled]="disabled"></currency-input>
                    <currency-input
                        placeholder="Elektrik 2"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.electrics.secondLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        *ngIf="secondWageLevelShown"
                        [tabindex]="2"
                        [disabled]="disabled"></currency-input>
                    <currency-input
                        placeholder="Elektrik 3"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.electrics.thirdLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        *ngIf="thirdWageLevelShown"
                        [tabindex]="3"
                        [disabled]="disabled"></currency-input>
                </div>
                <!--============================================-->
                <!-- END Electrics -->
                <!--============================================-->

                <!--============================================-->
                <!-- Car Body -->
                <!--============================================-->
                <div class="wage-group">
                    <!--********** Title **********-->
                    <div class="wage-group-title-container">
                        <mat-icon class="medium-icon">directions_car</mat-icon>
                        <div class="wage-group-title">Karosserie</div>
                    </div>

                    <!--********** Wage Inputs **********-->
                    <currency-input
                        placeholder="Karosserie 1"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.carBody.firstLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        [tabindex]="1"
                        [disabled]="disabled"></currency-input>
                    <currency-input
                        placeholder="Karosserie 2"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.carBody.secondLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        *ngIf="secondWageLevelShown"
                        [tabindex]="2"
                        [disabled]="disabled"></currency-input>
                    <currency-input
                        placeholder="Karosserie 3"
                        floatLabel="never"
                        [(value)]="selectedFeeSet.carBody.thirdLevel"
                        (valueChange)="emitDataChange()"
                        tooltip="Netto"
                        *ngIf="thirdWageLevelShown"
                        [tabindex]="3"
                        [disabled]="disabled"></currency-input>

                    <!--********** Delete Icon **********-->
                    <mat-icon
                        id="remove-wage-level-icon"
                        class="toolbar-icon"
                        *ngIf="secondWageLevelShown && !disabled"
                        (click)="hideLastWageLevel()"
                        matTooltip="Diese Stufe entfernen">
                        delete
                    </mat-icon>
                </div>
                <!--============================================-->
                <!-- END Car Body -->
                <!--============================================-->

                <!--============================================-->
                <!-- Note: Audatex Wage Rate Translation -->
                <!--============================================-->
                <div
                    id="audatex-wage-rate-translation-note-container"
                    *ngIf="
                        car &&
                        !user?.userInterfaceStates.audatexWageRateTranslationNoteClosed &&
                        isRemappingRequiredForAudatexWageRates({
                            manufacturer: car.make,
                            carShape: car.shape,
                        })
                    ">
                    <audatex-wage-rate-translation-note
                        [carManufacturer]="car.make"></audatex-wage-rate-translation-note>
                </div>
                <!--============================================-->
                <!-- END Note: Audatex Wage Rate Translation -->
                <!--============================================-->
            </section>
            <!--============================================-->
            <!-- END Main Wages -->
            <!--============================================-->

            <!--============================================-->
            <!-- Surcharges -->
            <!--============================================-->
            <section id="surcharges-section" class="section-card">
                <div class="section-heading-and-icon-container">
                    <mat-icon class="section-icon">add_circle</mat-icon>
                    <div>
                        <h3 class="section-heading no-margin">Aufschläge</h3>
                        <div class="label">und Dellen</div>
                    </div>
                </div>

                <!--********** Spare Parts Surcharge **********-->
                <mat-form-field class="form-fields-reduced-vertical-space">
                    <input
                        matInput
                        number-input
                        placeholder="Ersatzteile (UPE)"
                        [(number)]="selectedFeeSet.sparePartsSurcharge"
                        [minimumFractionDigits]="0"
                        (numberChange)="emitDataChange()"
                        class="text-align-end"
                        [disabled]="disabled"
                        matTooltip="Aufschlag einer Werkstatt auf die Ersatzteilpreise gemäß Hersteller {{
                            '\n\n'
                        }}(UPE = Unverbindliche Preisempfehlung des Herstellers)" />
                    <span matSuffix>%</span>
                </mat-form-field>

                <!--********** Small Parts Surcharge **********-->
                <div id="small-parts-surcharge-container">
                    <!--********** Small Parts Value **********-->
                    <mat-form-field class="form-fields-reduced-vertical-space">
                        <input
                            matInput
                            number-input
                            placeholder="Kleinersatzteile"
                            [(number)]="selectedFeeSet.smallPartsSurcharge"
                            (change)="emitDataChange()"
                            [minimumFractionDigits]="selectedFeeSet.smallPartsUnit === 'percent' ? 0 : 2"
                            [maximumFractionDigits]="2"
                            class="text-align-end"
                            [disabled]="disabled" />
                    </mat-form-field>

                    <!--********** Small Parts Unit **********-->
                    <mat-form-field id="small-parts-surcharge-unit-select" class="form-fields-reduced-vertical-space">
                        <mat-select
                            [(ngModel)]="selectedFeeSet.smallPartsUnit"
                            (selectionChange)="emitDataChange()"
                            [disabled]="disabled"
                            class="text-align-center">
                            <mat-select-trigger [ngSwitch]="selectedFeeSet.smallPartsUnit">
                                <span *ngSwitchCase="'percent'">%</span>
                                <span *ngSwitchCase="'flatFee'">€</span>
                            </mat-select-trigger>
                            <mat-option value="percent">Prozent %</mat-option>
                            <mat-option value="flatFee">Pauschal - €</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!--********** Dents **********-->
                <currency-input
                    placeholder="Dellen ausbeulen"
                    class="form-fields-reduced-vertical-space"
                    [(value)]="selectedFeeSet.dentsWage"
                    (valueChange)="emitDataChange()"
                    tooltip="Netto"
                    [disabled]="disabled"></currency-input>
            </section>
            <!--============================================-->
            <!-- END Surcharges -->
            <!--============================================-->

            <!--============================================-->
            <!-- Paint -->
            <!--============================================-->
            <section id="paint-section" class="section-card">
                <!--============================================-->
                <!-- Transport -->
                <!--============================================-->
                <div id="transport-container">
                    <!--********** Heading **********-->
                    <div
                        class="section-heading-and-icon-container"
                        matTooltip="Falls eine Werkstatt selbst nicht lackieren kann, wird das Fahrzeug zu einer Lackiererei verbracht. Dieser Aufwand muss abgerechnet werden.">
                        <mat-icon class="section-icon">navigation</mat-icon>
                        <h3 class="section-heading no-margin">Verbringung</h3>
                    </div>

                    <!--********** Calculation Type **********-->
                    <div>
                        <div class="selection-bar-label">Abrechnungsart</div>
                        <div class="selection-bar">
                            <!--********** None **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectTransportCalculationType('none')"
                                [ngClass]="{
                                    selected: selectedFeeSet.transport.calculationType === 'none',
                                    disabled: disabled,
                                }"
                                matTooltip="Keine Verbringung">
                                <mat-icon class="small-icon">block</mat-icon>
                            </div>

                            <!--********** Fixed Fee **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectTransportCalculationType('fixedPrice')"
                                [ngClass]="{
                                    selected: selectedFeeSet.transport.calculationType === 'fixedPrice',
                                    disabled: disabled,
                                }"
                                matTooltip="Pauschalpreis">
                                <mat-icon class="small-icon">euro_symbol</mat-icon>
                            </div>

                            <!--********** Mechanics **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectTransportCalculationType('mechanics')"
                                [ngClass]="{
                                    selected: selectedFeeSet.transport.calculationType === 'mechanics',
                                    disabled: disabled,
                                }"
                                matTooltip="Abrechnung nach Aufwand - Stundensatz Mechanik">
                                <mat-icon class="small-icon">build</mat-icon>
                            </div>

                            <!--********** Electrics **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectTransportCalculationType('electrics')"
                                [ngClass]="{
                                    selected: selectedFeeSet.transport.calculationType === 'electrics',
                                    disabled: disabled,
                                }"
                                matTooltip="Abrechnung nach Aufwand - Stundensatz Elektrik">
                                <mat-icon class="small-icon">electric_bolt</mat-icon>
                            </div>

                            <!--********** Car Body **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectTransportCalculationType('carBody')"
                                [ngClass]="{
                                    selected: selectedFeeSet.transport.calculationType === 'carBody',
                                    disabled: disabled,
                                }"
                                matTooltip="Abrechnung nach Aufwand - Stundensatz Karosserie">
                                <mat-icon class="small-icon">directions_car</mat-icon>
                            </div>
                        </div>
                    </div>

                    <!--********** Time & Costs **********-->
                    <div class="car-paint-last-column">
                        <!--********** None **********-->
                        <mat-form-field
                            style="visibility: hidden"
                            *ngIf="getTransportCalculationTypeCategory() === 'none'">
                            <input matInput placeholder="" />
                        </mat-form-field>

                        <!--********** Fixed Price **********-->
                        <currency-input
                            class="flex-grow"
                            *ngIf="getTransportCalculationTypeCategory() === 'fixedPrice'"
                            placeholder="Pauschalpreis"
                            [(value)]="selectedFeeSet.transport.fixedPrice"
                            (valueChange)="emitDataChange()"
                            [disabled]="disabled"></currency-input>

                        <div
                            id="time-required-and-total-container"
                            *ngIf="getTransportCalculationTypeCategory() === 'onTimeBasis'">
                            <!--********** Time Required **********-->
                            <mat-form-field class="flex-grow">
                                <input
                                    matInput
                                    number-input
                                    placeholder="Aufwand"
                                    [(number)]="selectedFeeSet.transport.timeRequired"
                                    (numberChange)="emitDataChange()"
                                    [disabled]="disabled" />
                                <span matSuffix>Std</span>
                            </mat-form-field>

                            <!--********** Total **********-->
                            <currency-input
                                placeholder="Summe"
                                [value]="getTransportCosts()"
                                [disabled]
                                matTooltip="Gesamtkosten für Verbringung"></currency-input>
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Transport -->
                <!--============================================-->

                <!--============================================-->
                <!-- Paint Wages -->
                <!--============================================-->
                <div id="paint-wages-container">
                    <!--********** Heading **********-->
                    <div class="section-heading-and-icon-container">
                        <mat-icon class="section-icon">color_lens</mat-icon>
                        <h3 class="section-heading no-margin">Lackierung</h3>
                    </div>

                    <!--********** Paint System **********-->
                    <div>
                        <div class="selection-bar-label">Lacksystem</div>
                        <div class="selection-bar">
                            <!--********** AZT **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectPaintSystem('allianzCenterForTechnology')"
                                [ngClass]="{
                                    selected: selectedFeeSet.carPaint.paintSystem === 'allianzCenterForTechnology',
                                    disabled: disabled,
                                }"
                                matTooltip="Allianz Zentrum für Technik (AZT) - unabhängig vom jeweiligen Hersteller ermittelte Lackwerte. Lohn und Material werden proportional zur Teilefläche ermittelt und über Ergebnisse von Zeitstudien gewichtet.">
                                AZT
                            </div>

                            <!--********** Manufacturer **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectPaintSystem('manufacturer')"
                                [ngClass]="{
                                    selected: selectedFeeSet.carPaint.paintSystem === 'manufacturer',
                                    disabled: disabled,
                                }"
                                matTooltip="Vom jeweiligen Hersteller vorgegebene Lackwerte">
                                Hersteller
                            </div>

                            <!--********** Eurolack **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectPaintSystem('eurolack')"
                                [ngClass]="{
                                    selected: selectedFeeSet.carPaint.paintSystem === 'eurolack',
                                    disabled: disabled,
                                }"
                                matTooltip="Von der DAT entwickeltes Lacksystem, das Lohn & Material proportional zur Oberfläche eines Bauteils angibt.">
                                Eurolack
                            </div>
                        </div>
                    </div>

                    <!--********** Paint Wage **********-->
                    <div class="car-paint-last-column">
                        <currency-input
                            placeholder="Lacklohn"
                            [(value)]="selectedFeeSet.carPaint.wage"
                            (valueChange)="emitDataChange()"
                            tooltip="Netto"
                            [disabled]="disabled"></currency-input>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Paint Wages -->
                <!--============================================-->

                <!--============================================-->
                <!-- Paint Material -->
                <!--============================================-->
                <div id="paint-material-container">
                    <!--********** Heading **********-->
                    <div class="section-heading-and-icon-container">
                        <mat-icon class="section-icon">opacity</mat-icon>
                        <h3 class="section-heading no-margin">Lackmaterial</h3>
                    </div>

                    <!--********** Paint System **********-->
                    <div>
                        <div class="selection-bar-label">Berechnung des Aufschlags</div>
                        <div class="selection-bar">
                            <!--********** Percentage **********-->
                            <div
                                class="selection-bar-item"
                                [ngClass]="{
                                    selected: !selectedFeeSet.carPaint.materialSurchargeUnit,
                                    disabled: disabled,
                                }"
                                (click)="selectPaintMaterialSurchargeUnit(null)"
                                matTooltip="Kein Aufschlag für Lackmaterial">
                                <mat-icon class="small-icon">block</mat-icon>
                            </div>

                            <!--********** Percentage **********-->
                            <div
                                class="selection-bar-item"
                                [ngClass]="{
                                    selected: selectedFeeSet.carPaint.materialSurchargeUnit === 'percent',
                                    disabled: disabled,
                                }"
                                (click)="selectPaintMaterialSurchargeUnit('percent')"
                                matTooltip="Lackmaterial wird prozentual zum Lohn aufgeschlagen">
                                <mat-icon class="small-icon">percent</mat-icon>
                            </div>

                            <!--********** Material Index **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectPaintMaterialSurchargeUnit('materialIndex')"
                                [ngClass]="{
                                    selected: selectedFeeSet.carPaint.materialSurchargeUnit === 'materialIndex',
                                    disabled: disabled,
                                }"
                                matTooltip="Materialindex. Standard: 100 %.{{
                                    '\n\n'
                                }}Der Lackierbetrieb kann diesen Wert erhöhen, wenn die eigenen Einkaufspreise höher liegen als vom Lacksystem ermittelt.{{
                                    '\n\n'
                                }}DAT: Verwendbar in den Lack-Systemen Eurolack & AZT.{{
                                    '\n'
                                }}Audatex: Verwendbar für BMW/Mini und Mercedes.">
                                Index
                            </div>

                            <!--********** Material Points or Units **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectPaintMaterialSurchargeUnit('materialPointsOrUnits')"
                                *ngIf="selectedFeeSet.carPaint.paintSystem !== 'allianzCenterForTechnology'"
                                [ngClass]="{
                                    selected: selectedFeeSet.carPaint.materialSurchargeUnit === 'materialPointsOrUnits',
                                    disabled: disabled,
                                }"
                                [matTooltip]="
                                    selectedFeeSet.carPaint.paintSystem === 'manufacturer'
                                        ? 'Material-Einheiten'
                                        : 'Materialpunkte'
                                ">
                                <!-- Paint Systems use different wording: Manufacturer = Materialeinheiten. Eurolack = Materialpunkte. -->
                                {{ selectedFeeSet.carPaint.paintSystem === 'manufacturer' ? 'ME' : 'MP' }}
                            </div>

                            <!--********** Flat Fee **********-->
                            <div
                                class="selection-bar-item"
                                (click)="selectPaintMaterialSurchargeUnit('flatFee')"
                                [ngClass]="{
                                    selected: selectedFeeSet.carPaint.materialSurchargeUnit === 'flatFee',
                                    disabled: disabled,
                                }"
                                matTooltip="Lackmaterial wird pauschal pro Stunde aufgeschlagen">
                                <mat-icon class="small-icon">euro_symbol</mat-icon>
                            </div>
                        </div>
                    </div>

                    <!--============================================-->
                    <!-- Material Surcharge -->
                    <!--============================================-->
                    <div class="car-paint-last-column">
                        <!--********** Default **********-->
                        <div class="material-surcharge-row">
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Materialaufschlag"
                                    value="Standard"
                                    matTooltip="Falls kein abweichender Lacktyp erkannt wird, wird dieser Standardwert verwendet."
                                    disabled />
                            </mat-form-field>
                            <mat-form-field class="material-surcharge-value-form-field">
                                <input
                                    matInput
                                    number-input
                                    [(number)]="selectedFeeSet.carPaint.materialSurchargeDefault"
                                    (numberChange)="emitDataChange()"
                                    [matTooltip]="getSuffixForMaterialSurchargeUnit().includes('€') ? 'Netto' : ''"
                                    [disabled]="disabled || !selectedFeeSet.carPaint.materialSurchargeUnit" />
                                <span matSuffix>{{ getSuffixForMaterialSurchargeUnit() }}</span>

                                <!-- When using an index of 130%, prevent user from entering merely 30%. -->
                                <mat-hint
                                    class="warning"
                                    *ngIf="
                                        selectedFeeSet.carPaint.materialSurchargeUnit === 'materialIndex' &&
                                        selectedFeeSet.carPaint.materialSurchargeDefault < 80
                                    ">
                                    Meist > 100%
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <!--********** Custom rows **********-->
                        <div
                            class="material-surcharge-row"
                            *ngFor="let surchargeRow of selectedFeeSet.carPaint.materialSurchargesByPaintType">
                            <!--********** Paint Type **********-->
                            <mat-form-field class="material-surcharge-paint-type" floatLabel="never">
                                <mat-select
                                    placeholder="Lacktyp"
                                    [(ngModel)]="surchargeRow.paintType"
                                    (valueChange)="emitDataChange()"
                                    [disabled]="disabled">
                                    <mat-option value="uni">Uni</mat-option>
                                    <mat-option value="metallic">Metallic</mat-option>
                                    <mat-option value="perlcolor">Perleffekt</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!--********** Value **********-->
                            <mat-form-field class="material-surcharge-value-form-field">
                                <input
                                    matInput
                                    number-input
                                    [(number)]="surchargeRow.value"
                                    (numberChange)="emitDataChange()"
                                    [disabled]="disabled" />
                                <span matSuffix>{{ getSuffixForMaterialSurchargeUnit() }}</span>

                                <!-- When using an index of 130%, prevent user from entering merely 30%. -->
                                <mat-hint
                                    class="warning"
                                    *ngIf="
                                        selectedFeeSet.carPaint.materialSurchargeUnit === 'materialIndex' &&
                                        surchargeRow.value < 80
                                    ">
                                    Meist > 100%
                                </mat-hint>
                            </mat-form-field>

                            <mat-icon
                                class="delete-paint-material-surcharge-icon toolbar-icon"
                                *ngIf="!disabled"
                                (click)="removeMaterialSurchargeForPaintType(surchargeRow)"
                                matTooltip="Lacktyp entfernen">
                                delete
                            </mat-icon>
                        </div>

                        <!--********** Add Custom Row **********-->
                        <div class="text-align-center">
                            <button
                                class="flat"
                                (click)="addMaterialSurchargeForPaintType()"
                                [class.disabled]="disabled">
                                + Materialaufschlag
                            </button>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Material Surcharge -->
                    <!--============================================-->
                </div>
                <!--============================================-->
                <!-- END Paint Material -->
                <!--============================================-->
            </section>
            <!--============================================-->
            <!-- END Paint -->
            <!--============================================-->

            <!--============================================-->
            <!-- Custom Calculation Items -->
            <!--============================================-->

            <div
                class="show-section-card-button label"
                *ngIf="!selectedFeeSet.customCalculationItems?.length"
                (click)="initializeCustomCalculationItems()"
                [class.disabled]="disabled"
                matTooltip="Manuelle Positionen einblenden">
                <mat-icon class="small-icon outlined">add_circle</mat-icon>
                Manuelle Positionen
            </div>

            <section
                id="custom-calculation-items-section"
                class="section-card"
                *ngIf="selectedFeeSet.customCalculationItems?.length">
                <!--********** Icon & Heading **********-->
                <div class="section-heading-and-icon-container">
                    <mat-icon class="section-icon outlined">add_circle</mat-icon>
                    <div>
                        <h3 class="no-margin">Manuelle Positionen</h3>
                        <div class="label">z. B. Sonderaufschläge</div>
                    </div>
                </div>

                <!--********** Items **********-->
                <div id="custom-calculation-item-list">
                    <div
                        class="custom-calculation-item"
                        *ngFor="let customCalculationItem of selectedFeeSet.customCalculationItems; let index = index">
                        <!--********** Title **********-->
                        <mat-form-field class="custom-calculation-item-title">
                            <input
                                #customCalculationItemDescriptionInput
                                matInput
                                placeholder="Titel"
                                [(ngModel)]="customCalculationItem.title"
                                (change)="emitDataChange()"
                                [disabled]="disabled" />
                        </mat-form-field>

                        <!--********** Value **********-->
                        <currency-input
                            class="custom-calculation-item-value"
                            placeholder="Wert"
                            [(value)]="customCalculationItem.valueNet"
                            (valueChange)="emitDataChange()"
                            [disabled]="disabled"
                            (keydown.tab)="
                                index === selectedFeeSet.customCalculationItems.length - 1
                                    ? addCustomCalculationItem()
                                    : null
                            "></currency-input>

                        <mat-icon
                            *ngIf="!disabled"
                            class="custom-calculation-item-delete-icon toolbar-icon"
                            (click)="removeCustomCalculationItem(customCalculationItem)"
                            matTooltip="Löschen">
                            delete
                        </mat-icon>
                    </div>

                    <!--********** Add New **********-->
                    <div class="text-align-center">
                        <button class="flat" (click)="addCustomCalculationItem()" *ngIf="!disabled">
                            + Manuelle Position
                        </button>
                    </div>

                    <!--********** Hide **********-->
                    <mat-icon
                        class="hide-section-card-icon toolbar-icon"
                        *ngIf="!selectedFeeSet.customCalculationItems.length"
                        (click)="hideCustomCalculationItems()"
                        matTooltip="Manuelle Positionen ausblenden">
                        close
                    </mat-icon>
                </div>
            </section>
            <!--============================================-->
            <!-- END Custom Calculation Items -->
            <!--============================================-->

            <!--============================================-->
            <!-- Audatex Calculation Config Codes -->
            <!--============================================-->
            <ng-container *ngIf="isAudatexUser()">
                <div
                    class="show-section-card-button label"
                    *ngIf="!selectedFeeSet.audatexConfigCodes?.length"
                    (click)="initializeAudatexConfigCodes()"
                    [class.disabled]="disabled"
                    matTooltip="Randfahnencodes einblenden">
                    <mat-icon class="small-icon">flag</mat-icon>
                    Randfahnencodes
                </div>

                <section
                    id="audatex-calculation-config-codes-section"
                    class="section-card"
                    *ngIf="selectedFeeSet.audatexConfigCodes?.length">
                    <!--********** Icon & Heading **********-->
                    <div class="section-heading-and-icon-container">
                        <mat-icon class="section-icon">flag</mat-icon>
                        <div>
                            <h3 class="no-margin">Randfahnencodes</h3>
                            <div class="label">nur für Audatex</div>
                        </div>
                    </div>

                    <!--********** Items **********-->
                    <div>
                        <div
                            class="audatex-config-code-item"
                            *ngFor="let audatexConfigCodeRow of selectedFeeSet.audatexConfigCodes">
                            <!--********** Title **********-->
                            <mat-form-field class="audatex-config-code-title">
                                <mat-select
                                    placeholder="Code"
                                    [(ngModel)]="audatexConfigCodeRow.numericalCode"
                                    (selectionChange)="emitDataChange()"
                                    [disabled]="disabled">
                                    <mat-select-trigger>
                                        {{ audatexConfigCodeRow.numericalCode }} - {{ audatexConfigCodeRow.title }}
                                    </mat-select-trigger>

                                    <!--********** Option Groups **********-->
                                    <mat-optgroup
                                        *ngFor="let audatexConfigCodeGroup of possibleAudatexConfigCodeGroups"
                                        [label]="audatexConfigCodeGroup.title">
                                        <!--********** Option **********-->
                                        <mat-option
                                            *ngFor="let audatexConfigCodeOption of audatexConfigCodeGroup.codes"
                                            [value]="audatexConfigCodeOption.numericalCode"
                                            (click)="selectConfigCode(audatexConfigCodeRow, audatexConfigCodeOption)"
                                            [disabled]="
                                                isAudatexCalculationConfigCodeTransmittedAutomatically(
                                                    audatexConfigCodeOption.numericalCode
                                                )
                                            ">
                                            <div class="audatex-config-code-option">
                                                <!--********** Numerical Code **********-->
                                                <div class="audatex-config-code-option-numerical-code">
                                                    {{ audatexConfigCodeOption.numericalCode }}
                                                </div>

                                                <!--********** Title **********-->
                                                <div class="audatex-config-code-option-title">
                                                    {{ audatexConfigCodeOption.title }}
                                                </div>

                                                <!--********** Value Type **********-->
                                                <div
                                                    class="audatex-config-code-option-unit"
                                                    [ngSwitch]="audatexConfigCodeOption.valueType">
                                                    <mat-icon
                                                        class="small-icon"
                                                        *ngSwitchCase="'percent'"
                                                        matTooltip="Prozentuale Angabe">
                                                        percent
                                                    </mat-icon>
                                                    <mat-icon
                                                        class="small-icon"
                                                        *ngSwitchCase="'currency'"
                                                        matTooltip="Pauschalbetrag in Euro">
                                                        euro_symbol
                                                    </mat-icon>
                                                    <mat-icon
                                                        class="small-icon"
                                                        *ngSwitchCase="'workFractionUnits'"
                                                        matTooltip="Arbeitswerte">
                                                        schedule
                                                    </mat-icon>
                                                </div>
                                            </div>
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>

                            <!--********** Value **********-->
                            <!--********** Currency **********-->
                            <currency-input
                                class="audatex-config-code-value"
                                placeholder="Wert"
                                *ngIf="audatexConfigCodeRow.valueType === 'currency'"
                                [(value)]="audatexConfigCodeRow.value"
                                (valueChange)="emitDataChange()"
                                [disabled]="disabled"></currency-input>

                            <!--********** Percentage **********-->
                            <mat-form-field
                                class="audatex-config-code-value"
                                *ngIf="audatexConfigCodeRow.valueType === 'percent'">
                                <input
                                    matInput
                                    number-input
                                    placeholder="Wert"
                                    [(number)]="audatexConfigCodeRow.value"
                                    [minimumFractionDigits]="0"
                                    (numberChange)="emitDataChange()"
                                    [disabled]="disabled" />
                                <span matSuffix>%</span>
                            </mat-form-field>

                            <!--********** Work Fraction Units **********-->
                            <mat-form-field
                                class="audatex-config-code-value"
                                *ngIf="audatexConfigCodeRow.valueType === 'workFractionUnits'">
                                <input
                                    matInput
                                    number-input
                                    placeholder="Wert"
                                    [(number)]="audatexConfigCodeRow.value"
                                    (numberChange)="emitDataChange()"
                                    [minimumFractionDigits]="0"
                                    [disabled]="disabled" />
                                <span matSuffix>AW</span>
                            </mat-form-field>

                            <mat-icon
                                *ngIf="!disabled"
                                class="audatex-config-code-delete-icon toolbar-icon"
                                (click)="removeAudatexCalculationConfigurationCode(audatexConfigCodeRow)"
                                matTooltip="Löschen">
                                delete
                            </mat-icon>
                        </div>

                        <!--********** Add New **********-->
                        <div class="text-align-center">
                            <button class="flat" (click)="addAudatexCalculationConfigurationCode()" *ngIf="!disabled">
                                + Randfahnencode
                            </button>
                        </div>

                        <!--********** Hide **********-->
                        <mat-icon
                            class="hide-section-card-icon toolbar-icon"
                            *ngIf="!selectedFeeSet.audatexConfigCodes.length"
                            (click)="hideAudatexConfigCodes()"
                            matTooltip="Randfahnencodes ausblenden">
                            close
                        </mat-icon>
                    </div>

                    <a
                        id="link-to-list-of-audatex-config-codes"
                        class="label"
                        href="https://www.audatex.de/fileadmin/user_upload/audatex/Downloads/EILNP.pdf"
                        target="_blank"
                        rel="noopener">
                        Liste der Codes
                    </a>
                </section>
            </ng-container>
            <!--============================================-->
            <!-- END Audatex Calculation Config Codes -->
            <!--============================================-->

            <div class="dialog-buttons-container">
                <button *ngIf="getChangedFeeSets().length === 0" (click)="closeGarageFeesDialog()">
                    Bearbeitung beenden
                </button>
                <button
                    *ngIf="getChangedFeeSets().length > 0"
                    (click)="closeGarageFeesDialogWithSyncing()"
                    matTooltip="Änderungen für dieses Gutachten übernehmen und ins Kontaktmanagement schreiben.{{
                        '\n\n'
                    }}Damit stehen diese Kostensätze für alle zukünftigen Gutachten mit dieser Werkstatt zur Verfügung.">
                    Übernehmen
                </button>
                <button
                    *ngIf="existingGarageContact && getChangedFeeSets().length > 0"
                    (click)="closeGarageFeesDialog()"
                    class="secondary"
                    matTooltip="Daten nicht ins Kontaktmanagement übernehmen. Relevant, falls du Kostensätze an einem alten Gutachten ändern musst und diese nicht die aktuellen Kostensätze der Werkstatt überschreiben sollen.">
                    Nur für Gutachten übernehmen
                </button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Right Column - Editor -->
        <!--============================================-->
    </div>
</div>

import { CarShape } from '../../models/reports/car-identification/car';
import { Report } from '../../models/reports/report';

/**
 * Some vehicle types do not have an airbags.
 *
 * We move this logic into a separate function to make it easier to update it in the future for new car shapes (e.g. boats).
 */
export function carHasAirbags(report: Report): boolean {
    const carShapesWithoutAirbags: CarShape[] = [
        'bicycle',
        'e-bike',
        'pedelec',
        'motorcycle',
        'trailer',
        'semiTrailer',
        'caravanTrailer',
    ];
    return !carShapesWithoutAirbags.includes(report.car.shape);
}

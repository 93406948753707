import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import moment from 'moment';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { getAvailablePlaceholders } from '@autoixpert/lib/document-building-blocks/get-available-placeholders';
import { isDocumentBuildingBlockCondition } from '@autoixpert/lib/document-building-blocks/is-document-building-block-condition-group';
import { generateId } from '@autoixpert/lib/generate-id';
import {
    PlaceholderValueTree,
    getPlaceholderValueTree,
} from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentBuildingBlockCondition } from '@autoixpert/models/documents/document-building-block-condition';
import { SignableDocumentType } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { Report } from '@autoixpert/models/reports/report';
import {
    SignablePdfPage,
    SignablePdfTemplateConfig,
} from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import { DefaultDocumentOrderItem } from '@autoixpert/models/teams/default-document-order/default-document-order-item';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { isDocumentBuildingBlockSavingConditionAllowed } from 'src/app/shared/components/document-building-block-condition-group-editor/document-building-block-condition-group-editor.utils';
import { DocumentOrderConfigService } from 'src/app/shared/services/document-order-config.service';
import { FieldGroupConfigService } from 'src/app/shared/services/field-group-config.service';
import { runChildAnimations } from '../../../shared/animations/run-child-animations.animation';
import { shrinkOut } from '../../../shared/animations/shrink-out.animation';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { ContactPersonService } from '../../../shared/services/contact-person.service';
import { DownloadService } from '../../../shared/services/download.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../../shared/services/network-status.service';
import { NewWindowService } from '../../../shared/services/new-window.service';
import { ReportService } from '../../../shared/services/report.service';
import { ScreenTitleService } from '../../../shared/services/screen-title.service';
import { SignablePdfTemplateConfigService } from '../../../shared/services/signable-pdf-template-config.service';
import { SignablePdfTemplateImageService } from '../../../shared/services/signable-pdf-template-image.service';
import { SignablePdfTemplateThumbnailService } from '../../../shared/services/signable-pdf-template-thumbnail.service';
import { SignablePdfTemplateService } from '../../../shared/services/signable-pdf-template.service';
import { TeamService } from '../../../shared/services/team.service';
import { ToastService } from '../../../shared/services/toast.service';
import { TutorialStateService } from '../../../shared/services/tutorial-state.service';

/**
 * Upload and manage PDF files that the end customer may sign and fill out checkboxes on them.
 *
 * The assessor may add
 * - placeholders that are printed into a PDF file.
 * - an area on which the claimant may sign the document.
 * - predefined checkboxes that the end user may fill out. For each checkbox, a tick is printed into the PDF.
 */
@Component({
    selector: 'signable-pdf-templates-list',
    templateUrl: './signable-pdf-templates-list.component.html',
    styleUrls: ['./signable-pdf-templates-list.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), shrinkOut(), runChildAnimations()],
})
export class SignablePdfTemplatesListComponent implements OnInit, OnDestroy {
    trackById = trackById;

    constructor(
        private screenTitleService: ScreenTitleService,
        private contactPersonService: ContactPersonService,
        private signablePdfTemplateService: SignablePdfTemplateService,
        private signablePdfTemplateConfigService: SignablePdfTemplateConfigService,
        private signablePdfTemplateThumbnailService: SignablePdfTemplateThumbnailService,
        private signablePdfTemplateImageService: SignablePdfTemplateImageService,
        private networkStatusService: NetworkStatusService,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
        private downloadService: DownloadService,
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private apiErrorService: ApiErrorService,
        private reportService: ReportService,
        private httpClient: HttpClient,
        private dialog: MatDialog,
        private newWindowService: NewWindowService,
        private tutorialStateService: TutorialStateService,
        private documentOrderConfigService: DocumentOrderConfigService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private dialogService: MatDialog,
        private teamService: TeamService,
    ) {}

    user: User;
    team: Team;

    // Uploader instance for uploading pdf templates to the server
    public uploader: FileUploader;

    public signablePdfTemplateConfigSearchTerm: string;

    public signablePdfTemplateConfigs: SignablePdfTemplateConfig[] = [];
    public filteredSignablePdfTemplateConfigs: SignablePdfTemplateConfig[] = [];
    public documentThumbnails = new Map<SignablePdfTemplateConfig['_id'], SafeResourceUrl>();

    public showSignableDocumentTypesForNewDocument = false;

    public lawyers: ContactPerson[] = [];

    public lawyerAutocompleteSearchTerms: Map<SignablePdfTemplateConfig['_id'], string> = new Map();
    public lawyerAutocompleteSearchResults: Map<SignablePdfTemplateConfig['_id'], ContactPerson[]> = new Map();

    public signablePdfTemplateConfigTitleForEditDialog: SignablePdfTemplateConfig;

    /**
     * Must be filled to open the conditions editor.
     */
    public signablePdfTemplateConfigForConditions: SignablePdfTemplateConfig;

    /**
     * Must be filled to open the placeholder editor.
     */
    public signablePdfTemplateConfigForEditor: SignablePdfTemplateConfig;

    public customDocumentOrderConfigs: DocumentOrderConfig[] = [];

    /**
     * When making a copy of a config, we upload the PDF programmatically.
     * Since that doesn't trigger the dragDropFileUpload directive's spinner, we must add it ourselves.
     */
    pendingCopyUploads: Map<SignablePdfTemplateConfig['_id'], true> = new Map();

    private subscriptions: Subscription[] = [];

    protected documentOrderConfigCreationDialogConfigToEdit: DocumentOrderConfig = null;
    protected documentOrderConfigCreationDialogShown: boolean = false;
    protected disableAnimations = false;

    // Conditions eidtor
    public fieldGroupConfigs: FieldGroupConfig[] = [];
    public placeholderValueTree: PlaceholderValueTree;
    public availableDocumentPlaceholders: string[] = [];

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        this.subscriptions.push(
            // Update the team & user in case they were updated in a different tab.
            this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)),
            this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)),
        );
        this.screenTitleService.setScreenTitle({ screenTitle: 'Unterschreibbare PDF-Dokumente' });

        await this.loadAndFilterDocuments();

        this.loadLawyers();
        this.loadAllThumbnailImages();

        this.openDocumentAccordingToQueryParameters();
        this.loadFieldGroupConfigsAndPlaceholderValueTree();
    }

    private async loadAndFilterDocuments(): Promise<void> {
        // Temporarily disable the shrink out animation, because we will reload the items and don't want the animation all over the page
        this.disableAnimations = true;

        await this.loadCustomDocumentOrderConfigs();

        // Filter Documents
        this.signablePdfTemplateConfigs = await this.signablePdfTemplateConfigService.find().toPromise();
        this.filterAndSortDocuments();
        this.disableAnimations = false;
    }

    private async loadCustomDocumentOrderConfigs(): Promise<void> {
        this.customDocumentOrderConfigs = await this.documentOrderConfigService
            .find({
                'customDocumentConfig.documentTypeGroup': 'signature',
                type: 'customDocument',
            })
            .toPromise();
    }

    private async loadAllThumbnailImages() {
        await Promise.all(
            this.signablePdfTemplateConfigs.map(async (document) => {
                await this.loadThumbnailImageToMap(document);
            }),
        );
    }

    private async loadFieldGroupConfigsAndPlaceholderValueTree() {
        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();

        this.placeholderValueTree = getPlaceholderValueTree({
            fieldGroupConfigs: this.fieldGroupConfigs,
        });

        this.availableDocumentPlaceholders = this.getAvailableDocumentPlaceholders();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Placeholders
    //****************************************************************************/
    public getAvailableDocumentPlaceholders(): string[] {
        return getAvailablePlaceholders({
            fieldGroupConfigs: this.fieldGroupConfigs,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Placeholders
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter & Sort
    //****************************************************************************/
    public filterAndSortDocuments() {
        this.filteredSignablePdfTemplateConfigs = [...this.signablePdfTemplateConfigs];

        this.applySearchFilter();
        this.sortDocuments();
    }

    /**
     * Sort by document type.
     */
    private sortDocuments() {
        this.filteredSignablePdfTemplateConfigs.sort((itemA, itemB) => {
            // Sort archived entries to the back
            if (itemA.archivedAt && !itemB.archivedAt) return 1;
            if (itemB.archivedAt && !itemA.archivedAt) return -1;

            const documentTypeA = this.mapDocumentTypeToSortIndex(itemA);
            const documentTypeB = this.mapDocumentTypeToSortIndex(itemB);

            if (documentTypeA === documentTypeB) {
                return sortByProperty(['title', 'createdAt'])(itemA, itemB);
            }

            return documentTypeA < documentTypeB ? -1 : 1;
        });
    }

    private mapDocumentTypeToSortIndex(signablePdfTemplateConfig: SignablePdfTemplateConfig): number {
        switch (signablePdfTemplateConfig.documentType) {
            case 'declarationOfAssignment':
                return 1;
            case 'revocationInstruction':
                return 2;
            case 'consentDataProtection':
                return 3;
            case 'powerOfAttorney':
                return 4;
        }
    }

    private applySearchFilter(): void {
        if (!this.signablePdfTemplateConfigSearchTerm) {
            return;
        }

        const searchTerms = this.signablePdfTemplateConfigSearchTerm.trim().toLowerCase().split(' ');

        this.filteredSignablePdfTemplateConfigs = this.filteredSignablePdfTemplateConfigs.filter(
            (signablePdfTemplateConfig) => {
                // Remember updating the tooltip on the search field
                const propertiesToBeSearched: string[] = [
                    // Conditions
                    ...signablePdfTemplateConfig.conditions
                        .map((condition) => (isDocumentBuildingBlockCondition(condition) ? condition : undefined))
                        .filter((condition) => condition)
                        .map((condition) => condition.comparisonValue),

                    // Titel
                    signablePdfTemplateConfig.title,

                    // Typ
                    translateDocumentType(signablePdfTemplateConfig.documentType),
                ];

                // Every search term must match in at least one property.
                return searchTerms.every((searchTerm) => {
                    return propertiesToBeSearched.some((propertyToBeSearched) => {
                        return propertyToBeSearched?.toLowerCase().includes(searchTerm);
                    });
                });
            },
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter & Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Edit Title for Signable PDF Template Config
    //****************************************************************************/

    public openSignablePdfTemplateConfigTitleEditDialog(signableDocument: SignablePdfTemplateConfig): void {
        // Don't allow non-admins to edit.
        this.signablePdfTemplateConfigTitleForEditDialog = signableDocument;
    }

    public closeSignablePdfTemplateConfigTitleEditDialog(): void {
        this.signablePdfTemplateConfigTitleForEditDialog = null;
    }

    public saveSignablePdfTemplateConfigTitleEditDialog(): void {
        // remove empty string
        if (this.signablePdfTemplateConfigTitleForEditDialog.title === '') {
            this.signablePdfTemplateConfigTitleForEditDialog.title = undefined;
        }
        this.signablePdfTemplateConfigService.put(this.signablePdfTemplateConfigTitleForEditDialog);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Title for Signable PDF Template Config
    /////////////////////////////////////////////////////////////////////////////*/
    //*****************************************************************************
    //  View Helpers
    //****************************************************************************/
    public isSavingAllowed(): boolean {
        return isDocumentBuildingBlockSavingConditionAllowed(
            this.signablePdfTemplateConfigForConditions,
            this.placeholderValueTree,
        );
    }

    public propertyPathExists(propertyPath: string): boolean {
        return !!get(this.placeholderValueTree, propertyPath);
    }

    public getSavingButtonTooltip(): string {
        if (!this.isSavingAllowed()) {
            return 'Bitte alle Bedingungen vollständig ausfüllen.';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Helpers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Creation Dialog
    //****************************************************************************/
    public async openShowSignableDocumentTypesForNewConfig() {
        this.showSignableDocumentTypesForNewDocument = true;
    }

    public async closeShowSignableDocumentTypesForNewConfig() {
        this.showSignableDocumentTypesForNewDocument = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Creation Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CRUD Signable PDF Template Config
    //****************************************************************************/

    public async createSignablePdfTemplateConfig(
        selectedType: SignableDocumentType,
        customDocumentOrderConfigId?: string,
    ): Promise<void> {
        const newSignablePdfTemplateConfig = new SignablePdfTemplateConfig({
            documentType: selectedType,
            customDocumentOrderConfigId,
            useContactForPowerOfAttorney: selectedType === 'powerOfAttorney' ? true : undefined,
        });
        this.signablePdfTemplateConfigs.push(newSignablePdfTemplateConfig);
        this.filteredSignablePdfTemplateConfigs.push(newSignablePdfTemplateConfig);
        this.showSignableDocumentTypesForNewDocument = false;

        await this.signablePdfTemplateConfigService.create(newSignablePdfTemplateConfig);
    }

    /**
     * Make a copy of the data structure.
     *
     * This does not copy the binary files (PDF and images of PDF pages).
     */
    public async copySignablePdfTemplateConfig(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        // Make copy + add to list
        const copy: SignablePdfTemplateConfig = JSON.parse(JSON.stringify(signablePdfTemplateConfig));
        copy._id = generateId();
        copy.title = `${signablePdfTemplateConfig.title} (Kopie)`;
        copy.pdfUploaded = false;
        copy.createdAt = moment().format();

        // Insert locally
        const index = this.signablePdfTemplateConfigs.indexOf(signablePdfTemplateConfig);
        this.signablePdfTemplateConfigs.splice(index + 1, 0, copy);

        // Update list
        this.filterAndSortDocuments();

        await this.signablePdfTemplateConfigService.create(copy, { waitForServer: true });

        // Copy PDF file
        this.pendingCopyUploads.set(copy._id, true);
        // The PDF file is immutable, therefore blobContentHash can be undefined
        const pdfFile = await this.signablePdfTemplateService.get(signablePdfTemplateConfig._id, undefined);
        await this.signablePdfTemplateService.create(
            {
                _id: copy._id,
                blob: pdfFile,
                blobContentHash: undefined,
            },
            { waitForServer: true },
        );
        copy.pdfUploaded = true;
        await this.signablePdfTemplateConfigService.put(copy);

        this.pendingCopyUploads.delete(copy._id);

        await this.loadThumbnailImageToMap(copy);
    }

    public async deactivateSignablePdfTemplateConfig(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        signablePdfTemplateConfig.archivedAt = DateTime.now().toISO();

        await this.signablePdfTemplateConfigService.put(signablePdfTemplateConfig);

        const isInUse: boolean = await this.hasTemplateBeenUsed(signablePdfTemplateConfig);
        // If usage couldn't be determined, abort.
        if (isInUse == null) return;

        // If the template have not yet been used, offer to remove it completely.
        if (isInUse === false) {
            await this.deleteSignablePdfTemplateConfig(signablePdfTemplateConfig);
            const infoToast = this.toastService.info(
                'Ungenutzte Vorlage gelöscht',
                'Weil die Vorlage in keinem Gutachten verwendet wurde, wurde sie gelöscht.<br><br>Zum Wiederherstellen klicken.',
                { timeOut: 10_000 },
            );
            const clickSubscription = infoToast.click.subscribe(async () => {
                this.signablePdfTemplateConfigs.push(signablePdfTemplateConfig);
                this.filteredSignablePdfTemplateConfigs.push(signablePdfTemplateConfig);
                this.showSignableDocumentTypesForNewDocument = false;

                await this.signablePdfTemplateConfigService.create(signablePdfTemplateConfig);
            });
            const timeoutSubscription = infoToast.timeoutEnd.subscribe(() => {
                clickSubscription.unsubscribe();
                timeoutSubscription.unsubscribe();
            });

            return;
        }
    }

    public async reactivateSignablePdfTemplateConfig(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        signablePdfTemplateConfig.archivedAt = null;

        await this.signablePdfTemplateConfigService.put(signablePdfTemplateConfig);
    }

    /**
     * Delete record and its resources.
     *
     * If the record has already been used within reports, keep it and its resources but mark is as archived.
     */
    public async deleteSignablePdfTemplateConfig(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Es kann nicht festgestellt werden, ob Vorlagen bereits verwendet werden. Bitte versuche es mit einer aktiven Internetverbindung noch einmal.',
            );
            return;
        }

        const reportIdsAndTokensUsingTemplate =
            await this.getReportIdsAndTokensUsingTemplate(signablePdfTemplateConfig);
        if (reportIdsAndTokensUsingTemplate.length > 0) {
            // Only show the first ten to prevent overloading the screen.
            const listElements: string[] = reportIdsAndTokensUsingTemplate.slice(0, 10).map((reportIdAndToken) => {
                return `<li><a href="/Gutachten/${reportIdAndToken._id}" target="_blank">${
                    reportIdAndToken.token || '[Kein Aktenzeichen]'
                }</a></li>`;
            });
            if (reportIdsAndTokensUsingTemplate.length > 10) {
                listElements.push('<li>...</li>');
            }
            const reportTokenListHumanReadable: string = `<ul>${listElements.join('')}</ul>`;
            const deleteUsedTemplateAnyway = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Vorlage wird verwendet',
                        content: `Das Dokument sollte nicht gelöscht werden, weil es in ${reportIdsAndTokensUsingTemplate.length} Gutachten noch verwendet wird.<br><br>Die Aktenzeichen lauten:${reportTokenListHumanReadable}`,
                        textAlign: 'left',
                        confirmLabel: 'Trotzdem löschen',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!deleteUsedTemplateAnyway) {
                return;
            }
        }

        // Not in use -> Remove everything. No problem.
        await this.removeAllResourcesForTemplateConfig(signablePdfTemplateConfig);
        await this.signablePdfTemplateConfigService.delete(signablePdfTemplateConfig._id);
        removeFromArray(signablePdfTemplateConfig, this.signablePdfTemplateConfigs);
        this.filterAndSortDocuments();
    }

    /**
     * Remove all binary resources associated with a signablePdfTemplateConfig.
     * - PDF template
     * - PDF template pages' images
     * - thumbnail
     *
     * Also, mark the record as having no PDF uploaded to it.
     */
    public async removeAllResourcesForTemplateConfig(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        signablePdfTemplateConfig.pdfUploaded = false;
        try {
            await Promise.all([
                this.signablePdfTemplateService.delete(signablePdfTemplateConfig._id),
                this.signablePdfTemplateImageService.deleteAllPageImages(signablePdfTemplateConfig),
                this.signablePdfTemplateThumbnailService.delete(signablePdfTemplateConfig._id),
            ]);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Löschen der PDF oder Bilder gescheitert`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Signable PDF Template Config
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Usage of Templates
    //****************************************************************************/
    /**
     * Check if the template has been used in any reports yet.
     *
     * If so, deleting or changing it will keep a deactivated copy so that old
     * report documents can still be rendered.
     */
    public async hasTemplateBeenUsed(signablePdfTemplateConfig: SignablePdfTemplateConfig): Promise<boolean | null> {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Es kann nicht festgestellt werden, ob Vorlagen bereits verwendet werden. Bitte versuche es mit einer aktiven Internetverbindung noch einmal.',
            );
            return null;
        }

        const numberOfUsages: number = (await this.getReportIdsAndTokensUsingTemplate(signablePdfTemplateConfig))
            ?.length;

        return !!numberOfUsages;
    }

    private async getReportIdsAndTokensUsingTemplate(
        signablePdfTemplateConfig: SignablePdfTemplateConfig,
    ): Promise<Pick<Report, '_id' | 'token'>[]> {
        /**
         * Use the HTTP client instead of the ReportService since the report service always syncs the entire report object. This can be way more lightweight, though.
         * Also, the probability is low that reports are cached locally for this query.
         */
        return await this.httpClient
            .get<Pick<Report, '_id' | 'token'>[]>('/api/v0/reports', {
                params: {
                    'signableDocuments.pdfTemplateId': signablePdfTemplateConfig._id,
                    $select: ['_id', 'token'],
                },
            })
            .toPromise();
    }

    /**
     * Get all IDs and tokens from reports that currently contain a document based on the given
     * DocumentOrderConfig.
     */
    private async getReportIdsAndTokensUsingDocumentOrderConfig(
        documentOrderConfig: DocumentOrderConfig,
    ): Promise<Pick<Report, '_id' | 'token'>[]> {
        /**
         * Use the HTTP client instead of the ReportService since the report service always syncs the entire report object. This can be way more lightweight, though.
         * Also, the probability is low that reports are cached locally for this query.
         */
        return await this.httpClient
            .get<Pick<Report, '_id' | 'token'>[]>('/api/v0/reports', {
                params: {
                    'documents.customDocumentOrderConfigId': documentOrderConfig._id,
                    $select: ['_id', 'token'],
                },
            })
            .toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Usage of Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document Type
    //****************************************************************************/
    public translateDocumentType = translateDocumentType;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Thumbnail Images
    //****************************************************************************/

    public getThumbnailImageBlobUrl(signableDocument: SignablePdfTemplateConfig) {
        return this.documentThumbnails.get(signableDocument._id);
    }

    private async loadThumbnailImageToMap(signableDocument: SignablePdfTemplateConfig) {
        if (signableDocument.pages.length === 0) return;

        // Thumbnail is immutable, therefore blobContentHash is undefined
        const thumbnailImageBlob: Blob = await this.signablePdfTemplateThumbnailService.get(
            signableDocument._id,
            undefined,
        );
        const sanitizedThumbailImageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(thumbnailImageBlob),
        );
        this.documentThumbnails.set(signableDocument._id, sanitizedThumbailImageUrl);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Thumbnail Images
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Lawyers
    //****************************************************************************/

    public setUseContactForPowerOfAttorney(signableDocument: SignablePdfTemplateConfig, useContact: boolean) {
        signableDocument.useContactForPowerOfAttorney = useContact;
        this.signablePdfTemplateConfigService.put(signableDocument);
    }

    public isContactForLawyerVisible(signableDocument: SignablePdfTemplateConfig) {
        return signableDocument.documentType === 'powerOfAttorney' && signableDocument.useContactForPowerOfAttorney;
    }

    public getAssignedLawyer(signablePdfTemplateConfig: SignablePdfTemplateConfig): string {
        return signablePdfTemplateConfig.conditions
            .map((condition) => (isDocumentBuildingBlockCondition(condition) ? condition : undefined))
            .find((condition) => condition?.propertyPath === 'Anspruchsteller.Anwalt.Organisation')?.comparisonValue;
    }

    public async removeConditionForLawyer(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        signablePdfTemplateConfig.conditions = [];

        await this.signablePdfTemplateConfigService.put(signablePdfTemplateConfig);
    }

    //*****************************************************************************
    //  Lawyer Autocomplete
    //****************************************************************************/
    public async loadLawyers() {
        this.lawyers = await this.contactPersonService.find({ organizationType: 'lawyer' }).toPromise();
    }

    public filterLawyerAutocompleteValues(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        const searchTerms: string[] = (this.lawyerAutocompleteSearchTerms.get(signablePdfTemplateConfig._id) || '')
            .toLowerCase()
            .split(' ');

        const searchResult: ContactPerson[] = this.lawyers
            .filter((lawyer) => {
                // Remember updating the tooltip on the search field
                const propertiesToBeSearched: string[] = [
                    lawyer.organization,
                    lawyer.lastName,
                    lawyer.firstName,
                    lawyer.city,
                    lawyer.streetAndHouseNumberOrLockbox,
                ];

                // Every search term must match in at least one property.
                return searchTerms.every((searchTerm) => {
                    return propertiesToBeSearched.some((propertyToBeSearched) => {
                        return propertyToBeSearched?.toLowerCase().includes(searchTerm);
                    });
                });
            })
            // For UI performance reasons, limit the number of results.
            .slice(0, 50);

        this.lawyerAutocompleteSearchResults.set(signablePdfTemplateConfig._id, searchResult);
    }

    /**
     * When the user selects a lawyer from the autocomplete, we create a condition like on document building blocks
     * so that the right document can be selected for a given lawyer in the report process.
     */
    public async createConditionForLawyer(
        signablePdfTemplateConfig: SignablePdfTemplateConfig,
        selectedLawyer: ContactPerson,
        event: MatOptionSelectionChange,
    ) {
        // Ignore the call to this function from the de-selected option. Angular fires the onSelectionChanged event twice; once for the old option and once for the new selected option.
        if (!event.source.selected) return;

        if (selectedLawyer.organization) {
            signablePdfTemplateConfig.conditions = [
                {
                    _id: generateId(),
                    propertyPath: 'Anspruchsteller.Anwalt.Organisation',
                    operator: 'equal',
                    comparisonValue: selectedLawyer.organization.trim(),
                },
            ];
        } else {
            signablePdfTemplateConfig.conditions = [
                {
                    _id: generateId(),
                    propertyPath: 'Anspruchsteller.Anwalt.Vorname',
                    operator: 'equal',
                    comparisonValue: selectedLawyer.firstName?.trim(),
                },
                {
                    _id: generateId(),
                    propertyPath: 'Anspruchsteller.Anwalt.Nachname',
                    operator: 'equal',
                    comparisonValue: selectedLawyer.lastName?.trim(),
                },
            ];
        }

        await this.signablePdfTemplateConfigService.put(signablePdfTemplateConfig);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lawyer Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lawyers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Document File Upload
    //****************************************************************************/
    /**
     * Load pages array and thumbnail from server.
     *
     * This method may be called either
     * - to upload a PDF template for the first time.
     * - to upload a replacement PDF template.
     * -- Then, we must check if the number of pages matches and act accordingly (remove excessive image files from server and page objects from data model).
     */
    public async onSuccessfulDocumentUpload(
        uploadResponses: Record<string, any>[],
        signableDocumentTemplateConfig: SignablePdfTemplateConfig,
    ) {
        const numberOfPages: number = uploadResponses[0].numberOfPages;

        if (!signableDocumentTemplateConfig.pages) {
            signableDocumentTemplateConfig.pages = [];
        }

        /**
         * If the user uploaded a new PDF to an existing config (= replaced a PDF file), the number of pages may differ.
         * Offer:
         * - clip excessive pages
         * - abort operation and remove images just rendered
         */
        if (numberOfPages < signableDocumentTemplateConfig.pages.length) {
            const removeExcessivePages = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Seitenzahl weicht ab',
                        content: `Bisher: ${signableDocumentTemplateConfig.pages.length}\nNeu: ${numberOfPages}`,
                        confirmLabel: 'Nicht mehr enthaltene Seiten löschen',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();

            //*****************************************************************************
            //  Remove Excessive Pages
            //****************************************************************************/
            if (removeExcessivePages) {
                // Remove pages not included in new PDF: Both in data structure and their binary images.
                const excessivePages = signableDocumentTemplateConfig.pages.slice(
                    numberOfPages - 1 /* 1-based index */,
                );
                for (const excessivePage of excessivePages) {
                    removeFromArray(excessivePage, signableDocumentTemplateConfig.pages);
                }

                await this.signablePdfTemplateConfigService.put(signableDocumentTemplateConfig);

                try {
                    // Remove binary images.
                    await this.signablePdfTemplateImageService.deleteSomePageImages({
                        signableDocumentTemplateConfig,
                        pageNumbers: excessivePages.map((page) => page.pageNumber),
                    });
                    await this.signablePdfTemplateThumbnailService.delete(signableDocumentTemplateConfig._id);
                } catch (error) {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: `Manche Bilddateien nicht gelöscht`,
                            body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                        },
                    });
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Remove Excessive Pages
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Remove all Pages (Full revert of upload)
            //****************************************************************************/
            else {
                // User canceled -> Delete the PDF file & all images that have just been rendered after uploading the new PDF file.
                try {
                    await this.signablePdfTemplateService.delete(signableDocumentTemplateConfig._id);
                    await this.signablePdfTemplateImageService.deleteAllPageImages(signableDocumentTemplateConfig);
                    await this.signablePdfTemplateThumbnailService.delete(signableDocumentTemplateConfig._id);
                } catch (error) {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: `Bilddateien nicht gelöscht`,
                            body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                        },
                    });
                }
                return;
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Remove all Pages (Full revert of upload)
            /////////////////////////////////////////////////////////////////////////////*/
        }

        //*****************************************************************************
        //  Initialize Missing Pages
        //****************************************************************************/
        // Initialize all missing pages. numberOfPages is 1-based, therefore we may use "<" instead of "<=".
        for (let i = 0; i < numberOfPages; i++) {
            if (!signableDocumentTemplateConfig.pages[i]) {
                signableDocumentTemplateConfig.pages[i] = new SignablePdfPage({
                    pageNumber: i + 1, // Bump to 1-based index (human-readable)
                });
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Initialize Missing Pages
        /////////////////////////////////////////////////////////////////////////////*/

        signableDocumentTemplateConfig.pdfUploaded = true;

        //*****************************************************************************
        //  Save
        //****************************************************************************/
        await this.signablePdfTemplateConfigService.put(signableDocumentTemplateConfig);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Save
        /////////////////////////////////////////////////////////////////////////////*/

        try {
            this.loadThumbnailImageToMap(signableDocumentTemplateConfig);
        } catch (error) {
            this.toastService.error(
                'Datei konnte nicht verarbeitet werden',
                'Die hochgeladene Datei konnte nicht verarbeitet werden.',
            );
            console.error(error);
            return;
        }

        if (signableDocumentTemplateConfig.documentType === 'declarationOfAssignment') {
            this.tutorialStateService.markTeamSetupStepComplete('ownDeclarationOfAssignment');
        }
    }

    /**
     * Use cases:
     * - User changed their company logo
     * - User did minor reformatting (e.g. text in bold)
     * - Scan with higher resolution replaces bad scan.
     *
     * Technical steps:
     * - If the template isn't in use anywhere, trigger the upload dialog right away.
     * - If the template is in use, archive the current record. Make a copy to keep the config but don't keep the binary file. Trigger the upload right away.
     */
    public async removePdfFile(signablePdfTemplateConfig: SignablePdfTemplateConfig) {
        const isInUse: boolean = await this.hasTemplateBeenUsed(signablePdfTemplateConfig);

        // If usage couldn't be determined, abort.
        if (isInUse == null) return;

        if (isInUse) {
            const decision: boolean = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Vorlage in Verwendung',
                        content:
                            'Die PDF-Vorlage wurde bereits in Gutachten verwendet. Änderungen wirken sich auch auf unterschriebene Dokumente aus.\n\nLege ggf. eine Kopie an.',
                        confirmLabel: 'Das ist mir bewusst',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();

            if (!decision) {
                return;
            }
        }

        await this.removeAllResourcesForTemplateConfig(signablePdfTemplateConfig);
        await this.signablePdfTemplateConfigService.put(signablePdfTemplateConfig);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document File Upload
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Edit Conditions
    //****************************************************************************/

    public openSignablePdfTemplateConfigConditions(signableDocument: SignablePdfTemplateConfig) {
        // Add at least one row.
        if (!signableDocument.conditions.length) {
            signableDocument.conditions.push(new DocumentBuildingBlockCondition());
        }

        // Operator is set
        if (!signableDocument.conditionsOperator) {
            signableDocument.conditionsOperator = 'and';
        }

        this.signablePdfTemplateConfigForConditions = signableDocument;
    }

    public closeAndSaveSignablePdfTemplateConfigConditions() {
        this.signablePdfTemplateConfigService.put(this.signablePdfTemplateConfigForConditions);
        this.signablePdfTemplateConfigForConditions = null;
    }

    public closeAndDiscardSignablePdfTemplateConfigConditions() {
        // Reload the conditions from the server.
        this.signablePdfTemplateConfigService
            .get(this.signablePdfTemplateConfigForConditions._id)
            .then((signableDocument) => {
                this.filteredSignablePdfTemplateConfigs = this.filteredSignablePdfTemplateConfigs.map((config) =>
                    config._id === signableDocument._id ? signableDocument : config,
                );
            });
        this.signablePdfTemplateConfigForConditions = null;
    }

    public async onSignablePdfTemplateConfigConditionChange(
        conditions: DocumentBuildingBlockCondition[],
        signableDocument: SignablePdfTemplateConfig,
    ) {
        if (!conditions) {
            this.toastService.error(
                'Technischer Fehler',
                "Eine oder mehrere Bedingungen konnten nicht gespeichert werden. Bitte probiere es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
            );
            console.error('conditions is empty', conditions);
            return;
        }
        signableDocument.conditions = conditions;
        await this.signablePdfTemplateConfigService.put(signableDocument);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Conditions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Edit Placeholders
    //****************************************************************************/

    public openSignablePdfTemplateConfigInEditor(signableDocument: SignablePdfTemplateConfig) {
        this.signablePdfTemplateConfigForEditor = signableDocument;
    }

    public closeSignablePdfTemplateConfigInEditor() {
        this.signablePdfTemplateConfigForEditor = null;
    }

    public handleOverlayClickOnPdfTemplateConfigEditor(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeSignablePdfTemplateConfigInEditor();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Placeholders
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Download PDF
    //****************************************************************************/

    public async downloadSignablePdfTemplate(signableDocument: SignablePdfTemplateConfig) {
        const blobResponse = await this.signablePdfTemplateService.get(signableDocument._id, undefined);
        try {
            await this.downloadService.downloadFile(blobResponse, 'unterschreibbare-datei.pdf');
        } catch (error) {
            console.error('Error downloading the DOCX buffer.', { error });
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Fehler beim Download',
                    body: 'Die Vorlage konnte nicht heruntergeladen werden.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Download PDF
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom signable documents
    /////////////////////////////////////////////////////////////////////////////*/
    getCustomDocumentOrderConfig(
        signablePdfTemplateConfig: SignablePdfTemplateConfig,
    ): DocumentOrderConfig | undefined {
        return this.customDocumentOrderConfigs.find(
            (customDocumentOrderConfig) =>
                customDocumentOrderConfig._id === signablePdfTemplateConfig.customDocumentOrderConfigId,
        );
    }
    //*****************************************************************************
    //  Custom signable documents
    //****************************************************************************/

    //*****************************************************************************
    //  Open PDF Document According to Query Params
    //****************************************************************************/
    public openDocumentAccordingToQueryParameters() {
        this.route.queryParams.pipe(take(1)).subscribe((queryParams: Params & { id: string }) => {
            if (queryParams.id) {
                const signablePdfTemplateConfig = this.signablePdfTemplateConfigs.find(
                    (signablePdfTemplateConfig) => signablePdfTemplateConfig._id === queryParams.id,
                );

                if (signablePdfTemplateConfig) {
                    this.openSignablePdfTemplateConfigInEditor(signablePdfTemplateConfig);
                } else {
                    this.toastService.error(
                        'PDF-Vorlage nicht gefunden',
                        `Die Vorlage mit der ID ${queryParams.id} konnte nicht gefunden werden.`,
                    );
                }
            }
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Open PDF Document According to Query Params
    /////////////////////////////////////////////////////////////////////////////*/

    protected openDocumentOrderConfigCreationDialogForId(documentOrderConfigId: DocumentOrderConfig['_id']) {
        this.openDocumentOrderConfigCreationDialog(
            this.customDocumentOrderConfigs.find((config) => config._id === documentOrderConfigId),
        );
    }

    protected openDocumentOrderConfigCreationDialog(configToEdit: DocumentOrderConfig = null) {
        if (configToEdit) {
            this.documentOrderConfigCreationDialogConfigToEdit = configToEdit;
        }

        this.documentOrderConfigCreationDialogShown = true;
    }

    /**
     * User created a new custom document config -> save it and reload data on page.
     */
    protected async handleDocumentOrderConfigCreated(newDocumentOrderConfig: DocumentOrderConfig) {
        if (this.documentOrderConfigCreationDialogConfigToEdit) {
            // Patch an existing config
            await this.documentOrderConfigService.put(newDocumentOrderConfig, { waitForServer: true });

            // Reload the order configs, so that the name/icon of the order config is updated if changed
            await this.loadAndFilterDocuments();
        } else {
            // Create a new config
            await this.documentOrderConfigService.create(newDocumentOrderConfig, { waitForServer: true });

            // Reload the order configs, so that the new config is shown in the list
            await this.loadAndFilterDocuments();
        }

        // Add the document to the team's default document orders
        for (const defaultDocumentOrderGroup of this.team.preferences.defaultDocumentOrderGroups) {
            for (const defaultDocumentOrder of defaultDocumentOrderGroup.documentOrders) {
                const existingDefaultDocumentOrderItem = defaultDocumentOrder.items.find(
                    (defaultDocumentOrderItem) =>
                        defaultDocumentOrderItem.documentType === newDocumentOrderConfig.type &&
                        // Custom documents are not unique by their type ("customDocument") but by their customDocumentOrderConfigId.
                        defaultDocumentOrderItem.customDocumentOrderConfigId === newDocumentOrderConfig._id,
                );
                if (!existingDefaultDocumentOrderItem) {
                    defaultDocumentOrder.items.push(
                        new DefaultDocumentOrderItem({
                            documentType: 'customDocument',
                            customDocumentOrderConfigId: newDocumentOrderConfig._id,
                        }),
                    );
                }
            }
        }

        await this.saveTeam();
    }

    /**
     * Close the dialog for creating and editing custom document order configs.
     */
    protected closeDocumentOrderConfigCreationDialog() {
        this.documentOrderConfigCreationDialogShown = false;
        this.documentOrderConfigCreationDialogConfigToEdit = null;
    }

    protected async deleteDocumentOrderConfigById(documentOrderConfigId: string): Promise<void> {
        await this.deleteDocumentOrderConfig(
            this.customDocumentOrderConfigs.find((config) => config._id === documentOrderConfigId),
        );
    }

    /**
     * Remove a document from all default document orders of a team.
     */
    public removeDocumentFromTeamDefaultFullDocumentConfig(documentOrderConfig: DocumentOrderConfig) {
        for (const defaultDocumentOrderGroup of this.team.preferences.defaultDocumentOrderGroups) {
            for (const defaultDocumentOrder of defaultDocumentOrderGroup.documentOrders) {
                const existingDefaultDocumentOrderItem = defaultDocumentOrder.items.find(
                    (defaultDocumentOrderItem) =>
                        defaultDocumentOrderItem.documentType === documentOrderConfig.type &&
                        // Custom documents are not unique by their type ("customDocument") but by their customDocumentOrderConfigId.
                        defaultDocumentOrderItem.customDocumentOrderConfigId === documentOrderConfig._id,
                );
                if (existingDefaultDocumentOrderItem) {
                    removeFromArray(existingDefaultDocumentOrderItem, defaultDocumentOrder.items);
                }
            }
        }

        console.log(`Removed "${documentOrderConfig.type}" from team's default document orders.`);
    }

    /**
     * Delete the given custom document order config.
     * - Removes the config from the collection documentorderconfigs
     * - Checks in which reports the document has been used and displays them to the user
     * - If deletion was confirmed -> delete the document from all reports (report.documents)
     * - Removes all SignablePdfTemplateConfigs from the collection signablepdftemplateconfigs that are based on this document
     * - Removes all uploaded PDF files
     * - Removes the document from all default document orders
     */
    protected async deleteDocumentOrderConfig(documentOrderConfig: DocumentOrderConfig): Promise<void> {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Es kann nicht festgestellt werden, ob Dokumentvorlagen bereits verwendet werden. Bitte versuche es mit einer aktiven Internetverbindung noch einmal.',
            );
            return;
        }

        // Check if used in reports and if so, delete them from the report.documents
        const reportIdsAndTokensUsingDocument =
            await this.getReportIdsAndTokensUsingDocumentOrderConfig(documentOrderConfig);
        if (reportIdsAndTokensUsingDocument.length > 0) {
            // Only show the first ten to prevent overloading the screen.
            const listElements: string[] = reportIdsAndTokensUsingDocument.slice(0, 10).map((reportIdAndToken) => {
                return `<li><a href="/Gutachten/${reportIdAndToken._id}/Druck-und-Versand" target="_blank">${
                    reportIdAndToken.token || '[Kein Aktenzeichen]'
                }</a></li>`;
            });
            if (reportIdsAndTokensUsingDocument.length > 10) {
                listElements.push('<li>...</li>');
            }
            const reportTokenListHumanReadable: string = `<ul>${listElements.join('')}</ul>`;
            const deleteUsedTemplateAnyway = await this.dialogService
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Dokumentvorlage wird verwendet',
                        content: `Das Dokument sollte nicht gelöscht werden, weil es in ${reportIdsAndTokensUsingDocument.length} Gutachten noch verwendet wird. Löscht du das Dokument trotzdem, wird es aus den genannten Gutachten entfernt.<br><br>Die Aktenzeichen lauten:${reportTokenListHumanReadable}`,
                        textAlign: 'left',
                        confirmLabel: 'Trotzdem löschen',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();
            if (deleteUsedTemplateAnyway) {
                // Remove the custom document from all reports
                const result = await this.documentOrderConfigService.removeCustomDocumentOrderConfigFromAllReports(
                    documentOrderConfig._id,
                );

                this.toastService.success(
                    `Eigenes Dokument aus allen Gutachten entfernt`,
                    `Das Dokument "${documentOrderConfig.titleLong}" wurde aus ${
                        result.numberOfReportsFromWhichDocumentOrderConfigWasRemoved
                    } Gutachten entfernt.`,
                );
            } else {
                return;
            }
        }

        // Delete the document order config itself
        await this.documentOrderConfigService.delete(documentOrderConfig._id, { waitForServer: true });
        removeFromArray(documentOrderConfig, this.customDocumentOrderConfigs);

        // And also remove it from the "remembered" list of documents to be signed in a new report
        removeFromArray(`customDocument-${documentOrderConfig._id}`, this.team.preferences.documentsToBeSigned);

        // Remove from all default document orders
        this.removeDocumentFromTeamDefaultFullDocumentConfig(documentOrderConfig);
        await this.saveTeam();

        // Finally remove all SignablePdfTemplateConfigs (and related data) that were based on the document order config
        const signablePdfsBasedOnOrderConfig = this.signablePdfTemplateConfigs.filter(
            (pdfConfig) => pdfConfig.customDocumentOrderConfigId === documentOrderConfig._id,
        );
        for (const signablePdfConfig of signablePdfsBasedOnOrderConfig) {
            await this.removeAllResourcesForTemplateConfig(signablePdfConfig);
            await this.signablePdfTemplateConfigService.delete(signablePdfConfig._id);
            removeFromArray(signablePdfConfig, this.signablePdfTemplateConfigs);
        }

        // Reload the filtered documents, so that the removed signable pdf template configs are no longer shown
        this.filterAndSortDocuments();
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/

    public handleConditionOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeAndDiscardSignablePdfTemplateConfigConditions();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  New Window
    //****************************************************************************/
    public openNewWindow(url: string) {
        this.newWindowService.open(url);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END New Window
    /////////////////////////////////////////////////////////////////////////////*/

    public async saveTeam(): Promise<Team> {
        try {
            return await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

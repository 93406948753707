import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getFullName } from '@autoixpert/lib/placeholder-values/get-full-name';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { User } from '@autoixpert/models/user/user';
import { trackById } from '../../libraries/track-by-id';
import { ApiErrorService } from '../../services/api-error.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'assessor-picker',
    templateUrl: './assessor-picker.component.html',
    styleUrls: ['./assessor-picker.component.scss'],
})
export class AssessorPickerComponent implements OnInit {
    readonly trackById = trackById;
    protected assessors: User[] = [];

    @Input() assessorIds: User['_id'][] = [];
    @Input() showWithSingleAssessor = false;
    @Output() assessorIdsChange = new EventEmitter<OfficeLocation['_id'][]>();

    constructor(
        private readonly userService: UserService,
        private readonly apiErrorService: ApiErrorService,
    ) {}

    async ngOnInit() {
        // Get users from cache first, then from IndexedDB and the server.
        this.assessors = this.userService.getAllTeamMembersFromCache();
        try {
            this.assessors = await this.userService.find().toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Nutzer konnten nicht geladen werden',
                    body: 'Besteht eine Verbindung zum Internet?',
                },
            });
        }
    }

    protected getAssessorById(assessorId: User['_id']): User | undefined {
        return this.assessors.find((user) => user._id === assessorId);
    }

    protected getAssessorNameById(userId: User['_id']): string {
        const assessor = this.getAssessorById(userId);
        return assessor ? getFullName(assessor) : '';
    }

    protected toggleAssessorId(assessorId: User['_id']): void {
        const assessorIds = this.assessorIds;
        const index = assessorIds.indexOf(assessorId);
        if (index === -1) {
            assessorIds.push(assessorId);
        } else {
            assessorIds.splice(index, 1);
        }

        this.assessorIdsChange.emit(assessorIds);
    }

    protected clearAssessors() {
        this.assessorIds = [];
        this.assessorIdsChange.emit(this.assessorIds);
    }
}

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import IBAN from 'iban';
import { DateTime } from 'luxon';
import moment from 'moment';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { getCanceledAmount } from '@autoixpert/lib/invoices/get-canceled-amount';
import { getOutstandingShortPaymentsAmount } from '@autoixpert/lib/invoices/get-outstanding-short-payments-amount';
import { getPaidAmount } from '@autoixpert/lib/invoices/get-paid-amount';
import { getUnpaidAmount } from '@autoixpert/lib/invoices/get-unpaid-amount';
import { getWrittenOffAmount } from '@autoixpert/lib/invoices/get-written-off-amount';
import { setPaymentStatus } from '@autoixpert/lib/invoices/set-payment-status';
import { isCursorInInputOrTextarea } from '@autoixpert/lib/keyboard-events/isCursorInInputOrTextarea';
import { round } from '@autoixpert/lib/numbers/round';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import { Payment } from '@autoixpert/models/invoices/payment';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../shared/animations/fade-in-and-slide.animation';
import { slideInAndOutHorizontally } from '../../shared/animations/slide-in-and-out-horizontally.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { successMessageOverlayAnimation } from '../../shared/animations/success-message-overlay.animation';
import { subjectIsVatRelated } from '../../shared/libraries/text-templates/subject-is-vat-related';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { InvoiceCancellationService } from '../../shared/services/invoice-cancellation.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NewWindowService } from '../../shared/services/new-window.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'payments-dialog',
    templateUrl: 'payments-dialog.component.html',
    styleUrls: ['payments-dialog.component.scss'],
    animations: [
        fadeInAndOutAnimation(),
        fadeInAndSlideAnimation({
            duration: 300,
            name: 'fadeInAndSlideSlower',
        }),
        dialogEnterAndLeaveAnimation(),
        slideInAndOutVertically(300, 400),
        slideInAndOutHorizontally(),
        successMessageOverlayAnimation(),
    ],
})
export class PaymentsDialogComponent {
    constructor(
        public userPreferences: UserPreferencesService,
        private tutorialStateService: TutorialStateService,
        private loggedInUserService: LoggedInUserService,
        private router: Router,
        private dialog: MatDialog,
        private toastService: ToastService,
        private userService: UserService,
        private invoiceService: InvoiceService,
        private invoiceCancellationService: InvoiceCancellationService,
        private apiErrorService: ApiErrorService,
        private newWindowService: NewWindowService,
    ) {}

    public user: User;

    @Input() invoice: Invoice;
    @Input() initialView: Payment['type'];
    @Input() createNewInstanceOnInit: boolean = false;
    @Output() invoiceChange: EventEmitter<Invoice> = new EventEmitter<Invoice>();
    @Output() cancellationInvoiceCreated: EventEmitter<Invoice> = new EventEmitter<Invoice>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    // This is always a copy of the one in the list to allow saving and canceling edits.
    public selectedPayment: Payment;
    public justSavedOverlayVisible: boolean;

    public confirmDialogOpen: boolean;
    public amountFieldWarning = '';

    // Cancellation invoices for cancellation payments.
    public cancellationInvoices: Map<Invoice['_id'], Invoice> = new Map();
    public cancellationInvoiceCreationPending: Map<Payment['_id'], boolean> = new Map();

    ngOnInit() {
        // First step: setup general data.
        this.user = this.loggedInUserService.getUser();

        this.loadNewPaymentIntoEditMode(this.initialView);

        // When opening the screen, sort the payments by date. That's the most logical order.
        this.sortPaymentsByDate();

        this.loadConnectedCancellationInvoices();
    }

    //*****************************************************************************
    //  View Getters
    //****************************************************************************/
    /**
     * Return the amount of money still required to be paid.
     *
     * A negative return value means the customer has paid too much.
     */
    public get unpaidAmount(): number {
        return getUnpaidAmount(this.invoice);
    }

    public get paidAmount(): number {
        return getPaidAmount(this.invoice);
    }

    public get outstandingShortPaymentsSum(): number {
        return getOutstandingShortPaymentsAmount(this.invoice);
    }

    public get writtenOffShortPaymentsSum(): number {
        return getWrittenOffAmount(this.invoice);
    }

    public get nonPaidShortPayments(): number {
        return this.outstandingShortPaymentsSum + this.writtenOffShortPaymentsSum;
    }

    public get canceledAmount(): number {
        return getCanceledAmount(this.invoice);
    }

    /**
     * This includes all entered payments, no matter their type or status.
     *
     * Mostly used to display what percentage of the invoice's possible payment amount has been entered.
     */
    public get sumOfEnteredPayments(): number {
        return (
            this.paidAmount + this.outstandingShortPaymentsSum + this.writtenOffShortPaymentsSum + this.canceledAmount
        );
    }

    public getPaymentTitle(payment: Payment): string {
        if (payment.title) {
            return payment.title;
        }

        // No title present
        if (payment.type === 'shortPayment') {
            return payment.reasonForShortPayment || 'Kürzung';
        }
        if (payment.type === 'partialCancellation') {
            if (round(payment.amount) === round(this.invoice.totalGross)) {
                return 'Storno';
            }
            return 'Teilgutschrift';
        }
        if (payment.amount === this.invoice.totalNet) {
            return 'Netto-Betrag';
        }
        if (payment.amount === this.invoice.totalGross) {
            return 'Gesamtsumme';
        }
        if (payment.amount === this.invoice.totalGross - this.invoice.totalNet) {
            return 'Mehrwertsteuer';
        }
        if (payment.amount > this.invoice.totalGross) {
            return 'Überzahlung';
        }
        return 'Teilzahlung';
    }

    public savingAllowed(): boolean {
        return !!(this.selectedPayment.amount && this.selectedPayment.date);
    }

    public getFullUserName(userId: string) {
        return this.userService.getTeamMembersName(userId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END View Getters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sorting
    //****************************************************************************/
    private sortPaymentsByDate() {
        this.invoice.payments.sort((paymentA, paymentB) => {
            const dateA = DateTime.fromISO(paymentA.date).toISODate();
            const dateB = DateTime.fromISO(paymentB.date).toISODate();

            if (dateA === dateB) {
                /**
                 * Sort order if dates are equal:
                 * 1) partial cancellations
                 * 2) payments
                 * 3) short payments
                 */
                const typeA = paymentA.type === 'payment' ? 1 : paymentA.type === 'shortPayment' ? 2 : 0;
                const typeB = paymentB.type === 'payment' ? 1 : paymentB.type === 'shortPayment' ? 2 : 0;

                return typeA === typeB ? 0 : typeA - typeB;
            }

            return dateA < dateB ? -1 : 1;
        });
        this.saveInvoice();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sorting
    /////////////////////////////////////////////////////////////////////////////*/

    public async selectPayment(payment: Payment) {
        this.selectedPayment = payment;
    }

    public selectPaymentType(paymentType: Payment['type'], payment: Payment = this.selectedPayment) {
        if (payment.type === paymentType) return;

        if (paymentType !== 'shortPayment') {
            payment.shortPaymentStatus = null;
            payment.reasonForShortPayment = null;
            payment.shortPaymentResolution = null;
        }

        if (paymentType === 'shortPayment') {
            payment.shortPaymentStatus ??= 'outstandingClaim';
        }

        payment.type = paymentType;
    }

    public insertPaymentAmount(amountType: 'net' | 'gross' | 'vat' | 'remainder' | number): void {
        // The amount cannot be changed with partial cancellations except the user deletes the cancellation invoice and re-creates it.
        if (this.selectedPayment.type === 'partialCancellation') {
            return;
        }

        let amount: number;
        switch (amountType) {
            case 'net':
                amount = this.invoice.totalNet;
                break;
            case 'gross':
                amount = this.invoice.totalGross;
                break;
            case 'vat':
                amount = this.invoice.totalGross - this.invoice.totalNet;
                break;
            case 'remainder':
                amount = this.unpaidAmount;
                break;
            default:
                if (!isNaN(amountType)) {
                    amount = this.invoice.totalGross * (amountType / 100);
                }
        }

        // Round the value because calculations like 50% of the total amount might result in fractions of a cent
        this.selectedPayment.amount = round(amount);

        this.saveInvoice();
    }

    public selectShortPaymentStatus(shortPaymentStatus: Payment['shortPaymentStatus']): void {
        if (this.selectedPayment.shortPaymentStatus === shortPaymentStatus) return;

        this.selectedPayment.shortPaymentStatus = shortPaymentStatus;

        // If a user records a later payment for this short payment, the payment should show up for the day of
        // marking the payment when querying for invoices by payment date.
        if (shortPaymentStatus === 'paid') {
            this.selectedPayment.date = todayIso();
            this.toastService.info(
                'Zahlungsdatum aktualisiert',
                'Falls du nach Zahlungsdatum filterst, taucht die Rechnung nun im richtigen Monat auf.\n\nDu kannst das Datum überschreiben.',
            );
        }
    }

    public handleClickOutsideCards(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            // Trigger saving the data before leaving the dialog.
            if (isCursorInInputOrTextarea()) {
                (document.activeElement as HTMLElement).blur();
            }

            this.emitCloseEvent();
        }
    }

    public togglePaymentNotesShown(): void {
        this.userPreferences.paymentNotesShown = !this.userPreferences.paymentNotesShown;
    }

    /**
     * If the user falsely entered only short payments, we offer him switching all
     * of them to regular payments at once.
     */
    public convertShortPaymentsToRegularPayments(): void {
        const shortPayments = this.invoice.payments.filter((payment) => payment.type === 'shortPayment');
        shortPayments.forEach((payment) => this.selectPaymentType('payment', payment));
        this.saveInvoice();
    }

    //*****************************************************************************
    //  CRUD Payments
    //****************************************************************************/
    public loadNewPaymentIntoEditMode(paymentType: Payment['type'] = 'payment'): void {
        const newPayment = new Payment({
            // Must be set through the method because it selects defaults based on type.
            type: undefined,
            date: todayIso(),
            createdBy: this.user._id,
        });

        // this.invoice.payments.push(newPayment);
        this.selectPayment(newPayment);

        if (paymentType) {
            this.selectPaymentType(paymentType);
        }
    }

    public isPaymentInList(payment: Payment): boolean {
        return !!this.invoice.payments.find((existingPayment) => existingPayment._id === payment._id);
    }

    /**
     * After filling out the payment details, add the payment object to the invoice's payment array.
     */
    public addPaymentToList(payment: Payment) {
        if (!this.savingAllowed()) {
            this.toastService.info('Bitte gib einen Betrag ein.');
            return;
        }

        this.invoice.payments.push(payment);
        this.saveInvoice();

        // Display success message
        this.justSavedOverlayVisible = true;
        window.setTimeout(() => {
            this.justSavedOverlayVisible = false;

            // After entering a payment, it feels natural that you can enter another one.
            // Do this only after the timeout of the overlay has ended so that the overlay doesn't jump when switching payment types.
            this.loadNewPaymentIntoEditMode();
        }, 1000);

        this.tutorialStateService.markUserTutorialStepComplete('paymentAdded');
    }

    /**
     * A user may change the amount of a payment.
     * When creating a new payment, setting an amount is enforced.
     * When editing, we cannot enforce setting an mount != 0..
     * But we convert 'null' to 0 since our aggregations for paid/short-paid amounts cannot handle null values.
     *
     * We do not enforce positive amounts because the user may want to enter a negative amount if a customer has overpaid.
     */
    public handleAmountChange() {
        this.selectedPayment.amount = this.selectedPayment.amount ?? 0;
    }

    public saveInvoice(): void {
        this.determinePaymentStatus();
        this.invoiceChange.emit(this.invoice);
    }

    /**
     * Remove a payment from the invoice.
     *
     * If that payment has been created through the bank transaction list
     * screen, notify the user of possible consequences.
     */
    public async deletePayment(payment: Payment): Promise<void> {
        // Make the user confirm if he tries to delete a payment created in the bank transaction list.
        if (payment.associatedBankTransactionId) {
            this.confirmDialogOpen = true;
            const continueDecision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Zahlung aus Kontoabgleich',
                        content:
                            'Dies ist eine Zahlung, die über den Bankkontoabgleich hinzugefügt wurde.\n\nWenn du sie hier löschst, bleibt möglicherweise eine verwaiste Zuordnung im Bankkontoabgleich zurück.',
                        confirmLabel: 'Löschen, das soll so sein',
                        cancelLabel: 'Doch behalten',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();

            this.confirmDialogOpen = false;

            if (!continueDecision) return;
        }

        if (payment.type === 'partialCancellation') {
            this.toastService.warn(
                'Teilgutschrift',
                'Dieser Eintrag kann nur gelöscht werden, indem du die dazugehörige Teilgutschrift (Rechnung) löschst.',
            );
            return;
        }

        const index = this.invoice.payments.indexOf(payment);
        if (index > -1) {
            this.invoice.payments.splice(index, 1);

            this.saveInvoice();
        }

        this.loadNewPaymentIntoEditMode();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Payments
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Determine Payment Status
    //****************************************************************************/
    public determinePaymentStatus() {
        setPaymentStatus(this.invoice);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Determine Payment Status
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Cancellation Invoices / Credit Notes
    //****************************************************************************/
    public async createCancellationInvoiceForShortPayment(payment: Payment) {
        // Don't trigger this method twice.
        if (this.cancellationInvoiceCreationPending.has(payment._id)) return;

        this.cancellationInvoiceCreationPending.set(payment._id, true);

        let cancellationInvoice: Invoice;
        try {
            cancellationInvoice = await this.invoiceCancellationService.createPartialCancellationInvoice({
                rootInvoice: this.invoice,
                lineItems: [
                    new LineItem({
                        quantity: 1,
                        // Get the net value.
                        unitPrice: Math.abs(payment.amount / (1 + this.invoice.vatRate)) * -1,
                        unit: 'Pauschal',
                        position: 1,
                        active: true,
                        description:
                            'Kürzung' + (payment.reasonForShortPayment ? `: ${payment.reasonForShortPayment}` : ''),
                    }),
                ],
            });
        } catch (error) {
            this.cancellationInvoiceCreationPending.delete(payment._id);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Teilgutschrift nicht angelegt`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        this.cancellationInvoiceCreationPending.delete(payment._id);
        payment.cancellationInvoiceId = cancellationInvoice._id;
        this.cancellationInvoiceCreated.emit(cancellationInvoice);

        // Add to local dictionary for the UI.
        this.cancellationInvoices.set(cancellationInvoice._id, cancellationInvoice);

        this.saveInvoice();
    }

    public async disconnectCancellationInvoiceFromShortPayment(payment: Payment) {
        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Verknüpfung trennen?',
                    content:
                        'Die Verknüpfung kann gelöst werden, aber die Teilgutschrift wird nicht automatisch gelöscht.\n\nFalls du das möchtest, musst du sie von Hand löschen.',
                    confirmLabel: 'Trennen',
                    cancelLabel: 'Behalten',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();
        if (!decision) return;

        payment.cancellationInvoiceId = null;

        this.saveInvoice();
    }

    /**
     * Short payments may be connected to a cancellation invoice / credit note. The payment only knows the ID, we want
     * to display the invoice number, though. Therefore, all connected invoices must be loaded.
     */
    private async loadConnectedCancellationInvoices() {
        const invoiceIds: string[] = this.invoice.payments
            .map((payment) => payment.cancellationInvoiceId)
            .filter(Boolean);

        if (invoiceIds.length) {
            const connectedCancellationInvoices: Invoice[] = await this.invoiceService
                .find({
                    _id: { $in: invoiceIds },
                })
                .toPromise();

            for (const connectedCancellationInvoice of connectedCancellationInvoices) {
                this.cancellationInvoices.set(connectedCancellationInvoice._id, connectedCancellationInvoice);
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Cancellation Invoices / Credit Notes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  VAT Letter Note
    //****************************************************************************/
    /**
     * Navigate to Print & Transmission and pass a parameter that triggers creation of the VAT letter.
     */
    public createVatLetter() {
        this.router.navigate(['Rechnungen', this.invoice._id, 'Druck-und-Versand'], {
            queryParams: {
                createVatLetter: true,
            },
        });
    }

    /**
     * Whether an invoice includes a letter that's related to VAT.
     */
    public invoiceHasVatLetter(): boolean {
        return this.invoice.documents
            .filter((doc) => doc.type === 'letter')
            .some((letter) => subjectIsVatRelated(letter.subject));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END VAT Letter Note
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Bank Account Sync
    //****************************************************************************/
    public getBankAccountSyncIconTooltip(payment: Payment): string {
        let tooltip = `Am ${moment(payment.createdAt).format('DD.MM.YYYY')} über den Bankkontoabgleich erfasst.`;
        if (payment.iban) {
            tooltip += `\nIBAN: ${this.formatIban(payment.iban)}`;
        }
        return tooltip;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bank Account Sync
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  IBAN Formatter
    //****************************************************************************/
    public formatIban(iban: string): string {
        // The printFormat method cannot handle null or undefined
        return IBAN.printFormat(iban ?? '', ' ');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END IBAN Formatter
    /////////////////////////////////////////////////////////////////////////////*/

    public closeDialog(): void {
        this.emitCloseEvent();
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    private emitCloseEvent() {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public openInvoiceInNewTab(invoiceId: string) {
        this.newWindowService.open(`/Rechnungen/${invoiceId}`);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                // Don't close the entire dialog if the user only meant to close the confirm dialog.
                if (this.confirmDialogOpen) return;

                this.emitCloseEvent();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.saveInvoice();
                }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

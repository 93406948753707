/**
 * Check if at least one value is present in an array.
 */
export function arrayIncludesAnyOfTheseValues({
    array,
    searchItems,
}: {
    /**
     * Since a string is an array of characters which supports the includes() function, we can pass a
     * string as well.
     */
    array: string | any[];
    searchItems: any[];
}): boolean {
    return searchItems.some((searchItem) => (array || []).includes(searchItem));
}

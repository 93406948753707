import { getValueForPlaceholder } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { PlaceholderValueTree } from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';

// Regex: find only the text
const MATCH_IN_TEXT = /WERT_(.+?)_FEHLT/g;

// Regex: find missing Placeholder and its closest HTML span tag
const MATCH_IN_HTML = /<span[^<]*WERT_(.+?)_FEHLT[^>]*\/span>/g;

/**
 * Return the given string or html with all occurrences of "WERT_XXX_FEHLT" replaced.
 * If no value for a placeholder is available, the original text will be kept.
 *
 * Process:
 * - Match all occurrences of "WERT_XXX_FEHLT".
 * - Extract and return the remaining given string, with multiple spaces next to each other removed.
 */
export function replaceMissingPlaceholders({
    placeholderValues,
    placeholderValueTree,
    text,
    isHtmlAllowed = false,
}: {
    placeholderValues: PlaceholderValues;
    placeholderValueTree: PlaceholderValueTree;
    text: string;
    isHtmlAllowed?: boolean;
}): string {
    const matchRegex = isHtmlAllowed ? MATCH_IN_HTML : MATCH_IN_TEXT;

    if (!text) return text;

    return (
        text
            // Replace all placeholder values
            .replaceAll(matchRegex, (textToReplace, matchedPlaceholder: string) => {
                const placeholderValue = getValueForPlaceholder({
                    placeholderValues,
                    placeholderValueTree,
                    matchedPlaceholder,
                });
                if (placeholderValue) {
                    return placeholderValue;
                }
                return textToReplace;
            })
            // Remove double whitespace or tabs (but not new lines)
            .replaceAll(/ {2,}/g, ' ')
    );
}

/**
 * Return the given string with all occurrences of "WERT_XXX_FEHLT" replaced.
 */
export function removeMissingPlaceholdersFromText(text: string): string {
    // Remove double whitespace (but not new lines)
    return text.replaceAll(MATCH_IN_TEXT, '').replaceAll(/ {2,}/g, ' ');
}

/**
 * Return the given html with all occurrences of "<span...WERT_XXX_FEHLT.../span>" replaced.
 */
export function removeMissingPlaceholdersFromHTML(text: string): string {
    return text.replaceAll(MATCH_IN_HTML, '');
}

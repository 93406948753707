import { PaintThicknessMeasurement, PaintThicknessType } from '../../models/reports/paint-thickness-measurement';
import { PaintThicknessScale } from '../../models/reports/paint-thickness-scale';

/**
 * Given a paint thickness measurement (multiple values for the same car part), determine the type of measurement (e.g. thin, original, too thick etc.).
 * For this decision, only the highest measurement is taken into account.
 */
export function getPaintThicknessType(
    measurement: PaintThicknessMeasurement,
    paintThicknessConfig: PaintThicknessScale,
): PaintThicknessType {
    const hasNumber = measurement.values?.find((value) => typeof value === 'number');

    if (measurement && hasNumber) {
        const maxValue = Math.max(...measurement.values);
        return paintThicknessConfig.rangeDefinitions.find(
            (config) => maxValue >= (config.minValue ?? -Infinity) && maxValue <= (config.maxValue ?? Infinity),
        ).type;
    }

    return null;
}

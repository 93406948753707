import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { CustomFieldLocation } from './custom-field-location';
import { FieldConfig } from './field-config';

/**
 * A FieldGroupConfig contains the config of a group of fields for a given location of custom fields in autoiXpert.
 *
 * Ex: There's one FieldGroupConfig each for
 * - accident data -> claimant
 * - accident data -> report settings
 * - ...
 */
export class FieldGroupConfig implements DataTypeBase {
    constructor(template: RequireSome<FieldGroupConfig, 'fieldLocation'>) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    fieldLocation: CustomFieldLocation;
    fieldConfigs: FieldConfig[] = [];

    _schemaVersion = 1 as const;
    _documentVersion: number = 0;
    createdBy: string = null;
    teamId: string = null;
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

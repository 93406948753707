import { DataType, GraphModel, Tensor1D, Tensor2D, Tensor3D, Tensor4D } from '@tensorflow/tfjs';

export interface SerializedTensor {
    shape: number[];
    data: number[];
    dtype: DataType;
}

export type LicensePlateRedactionModel = GraphModel;

/**
 * Image info
 * dtype: Float32Array(8)
 * shape: [1, 4, 2]
 * example: [512, 512, 512, 512, 1, 1, 0, 0
 */
export type ImageInfoModelOutput = Tensor3D;

/**
 * How many objects were detected in the image
 * dtype: Int32Array(1)
 * shape: [1]
 * example: [12]
 */
export type NumDetectionsModelOutput = Tensor1D;

/**
 * Segmentation masks for each box (100 boxes, mask is 28x28)
 * dtype: Float32Array(78400)
 * shape: [1, 100, 28, 28]
 * example: [0.45729777216911316, ..., 0.4664008915424347]
 */
export type DetectionMaskModelOutput = Tensor4D;

/**
 * Coordinates of the bounding boxes
 * dtype: Float32Array(400)
 * shape: [1, 100, 4]
 * example: [329.40740966796875, 63.00675964355469, 382.9943542480469, 121.95292663574219, ..., 0, 0, 0, 0]
 */
export type DetectionBoxesModelOutput = Tensor3D;

/**
 * Detection classes for each box
 * dtype: Int32Array(100)
 * shape: [1, 100]
 * example: [1, 1, ..., 1]
 *
 */
export type DetectionClassesModelOutput = Tensor2D;

/**
 * Detection scores for each box
 * dtype: Float32Array(100)
 * shape: [1, 100]
 * example: [0.9516406655311584, 0.548071026802063, 0.23943878710269928, ..., -0, -0, -0]
 */
export type DetectionScoresModelOutput = Tensor1D;

export type LicensePlateRedactionModelOutputUnion =
    | ImageInfoModelOutput
    | NumDetectionsModelOutput
    | DetectionBoxesModelOutput
    | DetectionClassesModelOutput
    | DetectionScoresModelOutput
    | DetectionMaskModelOutput;

/**
 * License plate redaction model output (parsed from predictions array)
 */
export interface LicensePlateRedactionModelOutput {
    imageInfo: ImageInfoModelOutput;
    numDetections: NumDetectionsModelOutput;
    detectionBoxes: DetectionBoxesModelOutput;
    detectionClasses: DetectionClassesModelOutput;
    detectionScores: DetectionScoresModelOutput;
    detectionMasks: DetectionMaskModelOutput;
}

/**
 * License plate redaction image masks for each box (100 boxes with 512x512 masks)
 * dtype: Float32Array(26214400)
 * shape: [100, 512, 512, 1]
 */
export type LicensePlateRedactionImageMasks = Tensor4D;
export type LicensePlateRedactionImageMask = Tensor3D;

export interface LicensePlateRedactionImageMaskInfo {
    mask: LicensePlateRedactionImageMask;
    score: number;
}

export interface LicensePlateRedactionPolygonPoint {
    x: number;
    y: number;
}

export interface LicensePlateRedactionPolygon {
    score: number;
    points: LicensePlateRedactionPolygonPoint[];
}

/** License plate redaction web worker communication (message) */
export enum LicensePlateRedactionWorkerMessageType {
    INIT_MODEL = 'INIT_MODEL',
    PROCESS_IMAGE = 'PROCESS_IMAGE',
}

export interface LicensePlateRedactionWorkerMessage {
    id: string;
    type: LicensePlateRedactionWorkerMessageType;
}

export interface LicensePlateRedactionWorkerInitModelMessage extends LicensePlateRedactionWorkerMessage {
    type: LicensePlateRedactionWorkerMessageType.INIT_MODEL;
    modelUrl: string;
}

export interface LicensePlateRedactionWorkerProcessImageMessage extends LicensePlateRedactionWorkerMessage {
    type: LicensePlateRedactionWorkerMessageType.PROCESS_IMAGE;
    imageTensor: SerializedTensor;
}

export type LicensePlateRedactionWorkerMessageUnion =
    | LicensePlateRedactionWorkerInitModelMessage
    | LicensePlateRedactionWorkerProcessImageMessage;

/** License plate redaction web worker communication (response) */
export enum LicensePlateRedactionWorkerResponseType {
    INIT_MODEL_RESPONSE = 'INIT_MODEL_RESPONSE',
    PROCESS_IMAGE_RESPONSE = 'PROCESS_IMAGE_RESPONSE',
}

export interface LicensePlateRedactionWorkerResponse {
    id: string;
    type: LicensePlateRedactionWorkerResponseType;
    error?: string;
}

export interface LicensePlateRedactionWorkerInitModelResponse extends LicensePlateRedactionWorkerResponse {
    type: LicensePlateRedactionWorkerResponseType.INIT_MODEL_RESPONSE;
    modelUrl: string;
}

export interface LicensePlateRedactionWorkerProcessImageResponse extends LicensePlateRedactionWorkerResponse {
    type: LicensePlateRedactionWorkerResponseType.PROCESS_IMAGE_RESPONSE;
    modelOutput?: SerializedTensor[];
}

export type LicensePlateRedactionWorkerResponseUnion =
    | LicensePlateRedactionWorkerInitModelResponse
    | LicensePlateRedactionWorkerProcessImageResponse;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateId } from '@autoixpert/lib/generate-id';

/**
 * Service to upload/download images that are contained in email signatures.
 */
@Injectable()
export class EmailSignatureImageService {
    constructor(private httpClient: HttpClient) {}

    /**
     * Resolves with the URL at which this image is hosted.
     */
    public async create(teamId: string, imageFile: Blob): Promise<string> {
        const imageId = `${teamId}-${generateId()}`;

        const formData = new FormData();
        formData.append('_id', imageId);
        formData.append('email-signature-image', imageFile);

        await this.httpClient.post<any>(`/api/v0/emailSignatureImages`, formData).toPromise();

        return `${window.location.origin}/api/v0/emailSignatureImages/${imageId}`;
    }

    /**
     * This is not needed for displaying images within the email body because the backend API's endpoint for loading
     * these images does not require authentication so that external clients may view the image, too, if it is not inlined
     * into the email.
     */
    public get(_id: string): Promise<any> {
        return this.httpClient
            .get(`/api/v0/emailSignatureImages/${_id}`, {
                responseType: 'blob',
                observe: 'response',
            })
            .toPromise();
    }

    public remove(_id: string): Promise<any> {
        return this.httpClient.delete<any>(`/api/v0/emailSignatureImages/${_id}`).toPromise();
    }
}

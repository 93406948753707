import moment from 'moment';
import { getAllTiresOfVehicle } from '@autoixpert/lib/tires/get-all-tires-of-vehicle';
import { Car } from '@autoixpert/models/reports/car-identification/car';

/**
 * Get the properties of a car that could be copied from one report to a later one,
 * that is the ones that have likely not changed in the meantime.
 * @param car
 */
export function getCopyableCarProperties(car: Car): Car {
    const copyOfPreviousReportCar: Car = JSON.parse(JSON.stringify(car));
    // Don't copy all properties that may have changed since the last time
    delete copyOfPreviousReportCar.mileage;
    delete copyOfPreviousReportCar.mileageMeter;
    delete copyOfPreviousReportCar.roadworthiness;

    // If the general inspection has expired, delete it. Otherwise, we can keep it.
    if (
        copyOfPreviousReportCar.nextGeneralInspection &&
        moment(copyOfPreviousReportCar.nextGeneralInspection).isBefore(undefined, 'days')
    ) {
        delete copyOfPreviousReportCar.nextGeneralInspection;
    }
    //*****************************************************************************
    //  Remove tire tread
    //****************************************************************************/
    const tires = getAllTiresOfVehicle(copyOfPreviousReportCar.axles);
    for (const tire of tires) {
        tire.treadInMm = null;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove tire tread
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Remove conditions
    //****************************************************************************/
    // The condition of the car usually changes between reports. At least, the assessor should
    // take another look at them according to Alex Held.
    delete copyOfPreviousReportCar.paintCondition;
    delete copyOfPreviousReportCar.generalCondition;
    delete copyOfPreviousReportCar.autobodyCondition;
    delete copyOfPreviousReportCar.undercarriageCondition;
    delete copyOfPreviousReportCar.interiorCondition;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove conditions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summarize previous damages from old report
    //****************************************************************************/

    // Move damages and damage descriptions to the repairedPreviousDamages section. We *assume* that all damages have been repaired since the last report on the car.
    if (!copyOfPreviousReportCar.repairedPreviousDamage) {
        copyOfPreviousReportCar.repairedPreviousDamage = '';
    } else {
        copyOfPreviousReportCar.repairedPreviousDamage += '\n\n';
    }

    const previousDamages: string[] = [];

    if (copyOfPreviousReportCar.damages?.length) {
        for (const damage of copyOfPreviousReportCar.damages) {
            const descriptionParts: string[] = [];
            if (damage.title) {
                descriptionParts.push(damage.title);
            }
            if (damage.description) {
                descriptionParts.push(damage.description);
            }
            if (descriptionParts.length) {
                previousDamages.push(descriptionParts.join('\n'));
            }
        }
    }

    // Airbags
    if (copyOfPreviousReportCar.airbagsReleased) {
        previousDamages.push(
            'Die Airbags haben vormals ausgelöst.' +
                (copyOfPreviousReportCar.airbagsComment ? ' ' + copyOfPreviousReportCar.airbagsComment : ''),
        );
    }

    // Meantime damages
    if (copyOfPreviousReportCar.meantimeDamage) {
        previousDamages.push(copyOfPreviousReportCar.meantimeDamage);
    }

    // Damage Description
    if (copyOfPreviousReportCar.damageDescription) {
        previousDamages.push(copyOfPreviousReportCar.damageDescription);
    }

    copyOfPreviousReportCar.repairedPreviousDamage += previousDamages.join('\n\n');

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summarize previous damages from old report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //
    //****************************************************************************/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END
    /////////////////////////////////////////////////////////////////////////////*/

    delete copyOfPreviousReportCar.damages;
    delete copyOfPreviousReportCar.airbagsReleased;
    delete copyOfPreviousReportCar.airbagsComment;
    delete copyOfPreviousReportCar.meantimeDamage;
    delete copyOfPreviousReportCar.damageDescription;

    return copyOfPreviousReportCar;
}

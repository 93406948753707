import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabelConfig } from '@autoixpert/models/labels/label-config';
import { trackById } from '../../libraries/track-by-id';
import { LabelConfigService } from '../../services/label-config.service';

@Component({
    selector: 'label-picker',
    templateUrl: './label-picker.component.html',
    styleUrls: ['./label-picker.component.scss'],
})
export class LabelPickerComponent implements OnInit {
    @Input() labelConfigIds: LabelConfig['_id'][] = [];
    @Input() showWithSingleLabel = true;
    @Input() labelGroup: LabelConfig['labelGroup'] = 'report';
    @Input() showCollectiveInvoiceRestrictionsNote: boolean = false;
    @Output() labelConfigIdsChange = new EventEmitter<LabelConfig['_id'][]>();

    protected labelConfigs: LabelConfig[] = [];

    constructor(private labelConfigService: LabelConfigService) {}

    async ngOnInit() {
        this.labelConfigs = await this.labelConfigService.find({ labelGroup: this.labelGroup }).toPromise();
    }

    protected getLabelById(labelId: LabelConfig['_id']): LabelConfig | undefined {
        return this.labelConfigs.find((label) => label._id === labelId);
    }

    protected getLabelTitleById(labelId: LabelConfig['_id']): string {
        return this.getLabelById(labelId)?.name || 'Unbenanntes Label';
    }

    protected toggleLabelConfigId(labelConfigId: LabelConfig['_id']): void {
        const labelConfigIds = this.labelConfigIds;
        const index = labelConfigIds.indexOf(labelConfigId);
        if (index === -1) {
            labelConfigIds.push(labelConfigId);
        } else {
            labelConfigIds.splice(index, 1);
        }

        this.labelConfigIdsChange.emit(labelConfigIds);
    }

    protected clearLabelConfigs() {
        this.labelConfigIds = [];
        this.labelConfigIdsChange.emit(this.labelConfigIds);
    }

    readonly trackById = trackById;
}

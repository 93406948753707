<div id="api-users-container" class="api-users-container" [ngClass]="{ empty: apiUsers.length === 0 }">
    <!--============================================-->
    <!-- API Users -->
    <!--============================================-->
    <div
        class="api-user"
        [ngClass]="{ inactive: !apiUser.active }"
        *ngFor="let apiUser of apiUsers; trackBy: trackById"
        @fadeInAndOut>
        <div class="api-user-header">
            <div class="api-user-icon-container">
                <mat-icon class="api-user-icon">key</mat-icon>
            </div>

            <div class="api-user-details-container">
                <div *ngIf="apiUserEditMode !== apiUser._id; else titleInput" class="api-user-title">
                    {{ apiUser.title }}
                </div>
                <ng-template #titleInput>
                    <input
                        class="api-user-title-input"
                        placeholder="Unbenannt"
                        [value]="apiUser.title"
                        (blur)="handleApiUserTitleChange(apiUser, $event)"
                        (elementCreated)="$event.element.focus(); $any($event.element).select()"
                        (keydown.enter)="handleApiUserTitleChange(apiUser, $event)" />
                </ng-template>

                <div class="api-user-entry-date label">erstellt am {{ apiUser.createdAt | moment }}</div>
            </div>

            <div class="api-user-toolbar-container">
                <mat-icon class="toolbar-icon api-user-submenu-icon" [matMenuTriggerFor]="apiUserSubmenu">
                    more_vert
                </mat-icon>
            </div>

            <!-- API User sub-menu -->
            <mat-menu #apiUserSubmenu>
                <!-- Bezeichnung ändern -->
                <a mat-menu-item matTooltip="Bezeichnung ändern" (click)="editApiUser(apiUser)">Bezeichnung ändern</a>

                <!-- Deaktivieren -->
                <a mat-menu-item matTooltip="Deaktivieren" *ngIf="apiUser.active" (click)="deactivateApiUser(apiUser)">
                    Deaktivieren
                </a>

                <!-- Aktivieren -->
                <a mat-menu-item matTooltip="Aktivieren" *ngIf="!apiUser.active" (click)="activateApiUser(apiUser)">
                    Aktivieren
                </a>

                <!-- Löschen -->
                <a mat-menu-item matTooltip="Löschen" (click)="deleteApiUser(apiUser)">Löschen</a>
            </mat-menu>
        </div>
        <access-rights
            [isAdmin]="false"
            [accessRights]="apiUser.accessRights"
            [accessRightsDisplayConfig]="{
                administrator: { hidden: true },
                seeAllReports: {
                    hidden: false,
                    tooltip: 'Gutachten abfragen oder bearbeiten. Das Anlegen von Gutachten ist immer möglich.',
                },
                editContacts: {
                    hidden: false,
                    tooltip: 'Kontakte bearbeiten. Das Abfragen von Kontakten ist immer möglich.',
                },
                seeAllInvoices: {
                    hidden: false,
                    tooltip: 'Rechnungen abfragen.',
                },
                invoiceList: { hidden: true },
                lockReports: { hidden: true },
                bankAccountSync: { hidden: true },
                seeReportFees: { hidden: true },
                reporting: { hidden: true },
                editTextsAndDocumentBuildingBlocks: { hidden: true },
                seeSettings: { hidden: true },
            }"
            (accessRightsChange)="handleAccessRightsChange(apiUser, $event)"></access-rights>
    </div>
    <!--============================================-->
    <!-- END API Users -->
    <!--============================================-->

    <!--============================================-->
    <!-- Add API User -->
    <!--============================================-->
    <div id="add-api-user-container">
        <div id="add-api-user-inner-container" (click)="createApiUser()" matTooltip="Zugangsschlüssel hinzufügen">
            <div id="add-api-user-icon-container">
                <mat-icon *ngIf="!addApiUserPending" id="add-api-user-icon">add</mat-icon>
                <img *ngIf="addApiUserPending" src="/assets/images/icons/loading-indicator.svg" class="pending-icon" />
            </div>

            <div id="add-api-user-text">Neuer Zugangsschlüssel</div>
        </div>
        <!--============================================-->
        <!-- END Add API User -->
        <!--============================================-->
    </div>
</div>

<!--============================================-->
<!-- API Key Dialog -->
<!--============================================-->
<div *ngIf="createdApiKey" class="dialog-overlay">
    <div class="dialog-container">
        <h2>Zugangsschlüssel erstellt</h2>

        <div>
            <p class="label">
                Der Zugangsschlüssel wurde erfolgreich erstellt. Kopieren Sie ihn und bewahren Sie ihn sicher auf.
            </p>
        </div>

        <form>
            <mat-form-field class="mat-block">
                <input matInput [value]="createdApiKey" readonly />
            </mat-form-field>
            <div id="buttons-container">
                <button type="submit" (click)="copyToClipboard(createdApiKey)">Kopieren</button>
                <button class="secondary" (click)="closeApiKeyDialog()">Schließen</button>
            </div>
        </form>
    </div>
</div>
<!--============================================-->
<!-- END API Key Dialog -->
<!--============================================-->

<nav class="module-process-bar card">
    <div class="process-bar-elements-container">
        <div class="process-bar-element-container">
            <a class="process-bar-element" [routerLink]="'Editor'" [routerLinkActive]="['active']">Rechnung</a>
        </div>
        <div class="process-bar-element-container">
            <a class="process-bar-element" [routerLink]="'Druck-und-Versand'" [routerLinkActive]="['active']">
                Druck & Versand
            </a>
        </div>
    </div>

    <!--********** Tasks Icon **********-->
    <div class="process-bar-icon-container" *ngIf="tasks.length || tasksIconVisible">
        <mat-icon
            class="tasks-icon"
            tasks-list-overlay-anchor
            [tasks]="tasks"
            [invoice]="invoice"
            #tasksIcon
            (click)="showTasksIcon()"
            (change)="saveInvoice()"
            [matTooltip]="getTooltipForTaskOverlayAnchor(tasks)">
            task_alt
        </mat-icon>
    </div>

    <!--********** Notes Icon **********-->
    <div class="process-bar-icon-container" *ngIf="invoice?.notes.length || notesIconVisible" matTooltip="Notizen">
        <mat-icon
            class="internal-notes-icon"
            internal-notes-panel-anchor
            [notes]="invoice.notes"
            #notesIcon
            (click)="showNotesIcon()"
            (change)="saveInvoice()">
            notes
        </mat-icon>
    </div>

    <!--============================================-->
    <!-- Submenu -->
    <!--============================================-->
    <div class="process-bar-option">
        <mat-icon id="process-bar-more-icon" [matMenuTriggerFor]="processBarMenu">more_vert</mat-icon>
        <mat-menu #processBarMenu="matMenu" xPosition="before">
            <!--********** Aufgaben **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="showTasksIcon(); openTasksPanel()">
                <mat-icon>task_alt</mat-icon>
                Aufgaben
            </a>

            <!--********** Notes **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="showNotesIcon(); openNotesPanel()">
                <mat-icon>notes</mat-icon>
                Notizen
            </a>

            <!--********** Cancel Invoice **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                [matMenuTriggerFor]="invoiceCancellationSubmenu"
                *ngIf="
                    invoice &&
                    getPaymentStatus(invoice) !== 'fullyCanceled' &&
                    !invoice.rootInvoiceId &&
                    invoice.importedFromThirdPartySystem !== 'gtue'
                ">
                <mat-icon>block</mat-icon>
                Rechnung stornieren
            </a>

            <mat-menu #invoiceCancellationSubmenu>
                <!--********** Full Cancellation **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="cancelInvoice()"
                    matTooltip="Rechnung vollständig stornieren. Die Originalrechnung wird auch als storniert angezeigt.">
                    <mat-icon>undo</mat-icon>
                    Vollständig
                </a>

                <!--********** Partial Cancellation **********-->
                <a mat-menu-item class="menu-item-with-icon" (click)="showInvoiceCancellationDialog()">
                    <mat-icon>rotate_left</mat-icon>
                    Teilweise
                </a>
            </mat-menu>

            <!--********** Delete Invoice **********-->
            <a mat-menu-item class="menu-item-with-icon alert" (click)="deleteInvoice(this.invoice)">
                <mat-icon>delete</mat-icon>
                Rechnung löschen
            </a>
        </mat-menu>
    </div>
    <!--============================================-->
    <!-- END Submenu -->
    <!--============================================-->
</nav>
<div class="module-router-outlet-container">
    <router-outlet></router-outlet>
</div>

<partial-invoice-cancellation-dialog
    *ngIf="partialCancellationDialogShown"
    [originalInvoice]="invoice"
    (close)="hideInvoiceCancellationDialog()"
    @runChildAnimations></partial-invoice-cancellation-dialog>

<!-- Office locations picker -->
<div
    class="office-locations-picker"
    *ngIf="officeLocations.length > 1 || officeLocationIds.length || showWithSingleLocation"
    [ngClass]="{ active: officeLocationIds.length > 0 }">
    <mat-icon class="office-locations-picker-icon">location_on</mat-icon>
    <div
        class="ax-header-select"
        [ngClass]="{ active: officeLocationIds.length > 0 }"
        [matMenuTriggerFor]="officeLocationsMenu">
        <!-- Label -->
        <div class="ax-header-select-label">
            <!-- No office location picked placeholder -->
            <span *ngIf="officeLocationIds.length === 0">Standort</span>

            <!-- Single office location picked -->
            <span *ngIf="officeLocationIds.length === 1">
                {{ getOfficeLocationTitleById(officeLocationIds[0]) }}
            </span>

            <!-- Multiple office locations picked -->
            <span *ngIf="officeLocationIds.length > 1">{{ officeLocationIds.length }} Standorte</span>
        </div>

        <!-- Dropdown icon -->
        <mat-icon>arrow_drop_down</mat-icon>
    </div>
</div>

<!-- Office locations picker overlay -->
<mat-menu #officeLocationsMenu class="office-locations-menu">
    <!--********** Clear **********-->
    <a mat-menu-item (click)="clearOfficeLocations()">
        <div class="clear-menu-item">Kein Standortfilter</div>
    </a>

    <!--********** Office Locations **********-->
    <ng-container *ngFor="let officeLocation of officeLocations; trackBy: trackById">
        <a mat-menu-item class="menu-item-with-icon" (click)="toggleOfficeLocationId(officeLocation._id)">
            <!--********** Office Location **********-->
            <div class="office-location-container flex-grow">
                <div class="office-location-item">
                    <!-- <mat-icon>home</mat-icon> -->

                    <!--********** Office Location Title **********-->
                    <div class="office-location-title" [matTooltip]="getOfficeLocationTitleById(officeLocation._id)">
                        {{ getOfficeLocationTitleById(officeLocation._id) }}
                    </div>
                </div>
            </div>

            <!--********** Multiselect **********-->
            <mat-checkbox
                [checked]="officeLocationIds.includes(officeLocation._id)"
                (change)="toggleOfficeLocationId(officeLocation._id)"
                (click)="$event.stopPropagation()"
                matTooltip="Zeige die Rechnungen mit diesem Standort an."></mat-checkbox>
        </a>

        <!-- No office locations placeholder -->
        <div *ngIf="officeLocations.length === 0" class="no-office-locations-placeholder">
            Keine Standorte vorhanden.
        </div>
    </ng-container>
</mat-menu>

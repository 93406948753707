import { FieldConfig } from '@autoixpert/models/custom-fields/field-config';

export function convertFieldTitleToPlaceholderName(customFieldTitle: FieldConfig['name']): FieldConfig['placeholder'] {
    // Remove special characters
    const cleanedString: string = (customFieldTitle || '')
        .replace(/[()/.,_?{}]/g, '')
        // Replace hyphens with spaces
        .replace(/-/g, ' ');

    const words: string[] = cleanedString
        .toLowerCase()
        .split(' ')
        // Remove empty words, e.g. if multiple spaces were entered next to each other.
        .filter(Boolean);

    // Capitalize all words.
    const camelCaseString: string = words.map((word) => `${word[0].toUpperCase()}${word.substring(1)}`).join('');

    // Keep the original case of the first letter.
    return `${cleanedString[0]}${camelCaseString.substring(1)}`;
}

<div class="audatex-wage-rate-translation-note info-note">
    <img
        class="audatex-wage-rate-translation-note-car-manufacturer-logo"
        *ngIf="carManufacturer && iconForCarBrandExists(carManufacturer)"
        [src]="iconFilePathForCarBrand(carManufacturer, 'small')"
        alt="Hersteller-Logo" />
    <mat-icon *ngIf="!(carManufacturer && iconForCarBrandExists(carManufacturer))" class="outlined">info</mat-icon>
    <span>
        Lohnfaktoren werden je nach Hersteller
        <a href="https://wissen.autoixpert.de/hc/de/articles/18426912725650" target="_blank" rel="noopener">
            zu Audatex übersetzt.
        </a>
        Auch für
        <strong>{{ carManufacturer }}.</strong>
    </span>
    <mat-icon
        class="medium-icon toolbar-icon"
        (click)="user.userInterfaceStates.audatexWageRateTranslationNoteClosed = true; saveUser()"
        matTooltip="Meldung dauerhaft ausblenden">
        close
    </mat-icon>
</div>

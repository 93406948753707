/**
 * From an object with depth > 1, generate an object of depth = 1 which has deep paths as keys.
 *
 * This generates an object matching the MongoDB path syntax.
 *
 * @param changes
 * @param paths
 */
export function flattenChangePaths(changes: { [key: string]: any }, paths: string[] = []): FlattenedChangePaths {
    let flattened: { [key: string]: any } = {};

    for (const property in changes) {
        // Objects
        if (!Array.isArray(changes[property]) && changes[property] !== null && typeof changes[property] === 'object') {
            // If an object property is changed from null to an object, using paths to put the object is prevented by MongoDB. Do not flatten those objects.
            if (changes[property]._flatten === false) {
                const { _flatten, ...otherProperties } = changes[property];
                flattened = {
                    ...flattened,
                    [[...paths, property].join('.')]: otherProperties,
                };
            } else {
                flattened = {
                    ...flattened,
                    ...flattenChangePaths(changes[property], [...paths, property]),
                };
            }
        }
        // Arrays and primitive values
        else {
            const key: string = [...paths, property].join('.');
            flattened[key] = changes[property];
        }
    }
    return flattened;
}

export interface FlattenedChangePaths {
    [key: string]: any;
}

//// For debugging
//(window as any).flattenChangePaths = flattenChangePaths;

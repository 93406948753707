import { addDefaultDocumentsToInvoice } from '@autoixpert/lib/documents/add-default-documents-to-invoice';
import { addDocumentToInvoice } from '@autoixpert/lib/documents/add-document-to-invoice';
import { addMissingDocumentOrdersToInvoice } from '@autoixpert/lib/documents/add-missing-document-orders-to-invoice';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '../../models/user/user';
import { computeInvoiceTotalGross, computeInvoiceTotalNet } from './compute-invoice-total';
import { setPaymentStatus } from './set-payment-status';

export function createInvoice({
    invoiceData,
    user,
    team,
}: {
    invoiceData: Partial<Invoice>;
    user: User;
    team: Team;
}): Invoice {
    const invoice: Invoice = new Invoice({ ...invoiceData, createdBy: user._id, teamId: team._id });

    if (!invoice.documentOrders?.length) {
        addMissingDocumentOrdersToInvoice({ invoice });

        /**
         * If the invoice parameters were only created with documents but without the document orders, add all documents
         * again with the utility function that adds DocumentOrderItems to every DocumentOrder.
         *
         * We do this to handle incomplete invoice objects which arise from incomplete objects like report.repairConfirmation.invoiceParameters.
         */
        if (invoice.documents?.length) {
            const existingDocuments: DocumentMetadata[] = invoice.documents;

            invoice.documents = [];
            for (const existingDocument of existingDocuments) {
                /**
                 * This adds documents and document order items properly.
                 */
                addDocumentToInvoice({
                    invoice,
                    team,
                    newDocument: existingDocument,
                });
            }
        }
    }

    if (!invoice.documents?.length) {
        addDefaultDocumentsToInvoice({
            invoice,
            user,
            team,
        });
    }

    invoice.totalNet = computeInvoiceTotalNet(invoice);

    if (invoice.importedFromThirdPartySystem !== 'gtue') {
        // GTÜ invoices are imported with their total gross. So don't recalculate the gross,
        // otherwise we might end up with a different amount (due to rounding).
        invoice.totalGross = computeInvoiceTotalGross(invoice);
    }

    setPaymentStatus(invoice);

    return invoice;
}

import { Component, EventEmitter, HostListener, NgZone, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { fadeInAndOutAnimation } from '@autoixpert/animations/fade-in-and-out.animation';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { isProduction } from '@autoixpert/lib/environment/is-production';
import { BankAccountForSync } from '@autoixpert/models/bank-account-sync/bank-account-for-sync';
import { BankAccountSync, Team } from '@autoixpert/models/teams/team';
import {
    FinancialInstitutionsService,
    GocardlessFinancialInstitution,
} from 'src/app/shared/services/Banking/financial-institutions.service';
import { fadeInAnimation } from '../../shared/animations/fade-in.animation';
import {
    VideoPlayerDialogComponent,
    VideoPlayerDialogData,
} from '../../shared/components/video-player-dialog/video-player-dialog.component';
import { BankAccountService } from '../../shared/services/Banking/bank-account.service';
import {
    GocardlessBankAccountConnectionService,
    GocardlessRequisitionBankAccount,
} from '../../shared/services/Banking/gocardless-bank-account-connection.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NewWindowService } from '../../shared/services/new-window.service';
import { TeamService } from '../../shared/services/team.service';
import { ToastService } from '../../shared/services/toast.service';

@Component({
    selector: 'gocardless-bank-account-list',
    templateUrl: 'gocardless-bank-account-list.component.html',
    styleUrls: ['gocardless-bank-account-list.component.scss'],
    animations: [dialogEnterAndLeaveAnimation(), fadeInAnimation(), fadeInAndOutAnimation()],
})
export class GocardlessBankAccountListComponent {
    constructor(
        private bankAccountService: BankAccountService,
        private financialInstitutionsService: FinancialInstitutionsService,
        private bankAccountConnectionService: GocardlessBankAccountConnectionService,
        private toastService: ToastService,
        private apiErrorService: ApiErrorService,
        private newWindowService: NewWindowService,
        private teamService: TeamService,
        private loggedInUserService: LoggedInUserService,
        private ngZone: NgZone,
        private dialog: MatDialog,
    ) {}

    private team: Team;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() numberOfBankAccountsChange: EventEmitter<number> = new EventEmitter();

    public bankAccountsPending: boolean;
    public bankAccounts: BankAccountForSync[] = [];

    // Create new bank account
    public bankAccountConnectionCreationPending: boolean;

    // Edit bank account
    public bankAccountTitlesInEditMode: Map<BankAccountForSync, void> = new Map();

    // Select financial institution
    protected financialInstitutionsGroups: FinancialInstitutionsGroup[] = [];
    protected selectingFinancialInstitution: 'OVERVIEW' | 'CATEGORY' | 'COUNTRY' | 'BANK_ACCOUNTS' | false = false;
    protected selectingFinancialInstitutionCountry: 'DE' | 'AT' = 'DE';
    protected financialInstitutionsCategory?: string;
    protected financialInstitutionsSearch?: string;
    protected financialInstitutions: GocardlessFinancialInstitution[] = [];
    protected financialInstitutionsFiltered: GocardlessFinancialInstitution[] = [];
    protected financialInstitutionsLoading: boolean = false;
    protected bankAccountsToConnect: GocardlessRequisitionBankAccount[] = [];
    protected selectedBankAccountIds: string[] = [];
    protected requisitionId: string;

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.loadBankAccounts();

        const teamSubscription = this.loggedInUserService.getTeam$().subscribe({
            next: (team) => {
                this.team = team;
            },
        });

        this.subscriptions.push(teamSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Bank Accounts
    //****************************************************************************/
    private loadBankAccounts(): void {
        this.bankAccountsPending = true;

        this.bankAccountService.find().subscribe({
            next: (bankAccounts) => {
                this.bankAccounts = bankAccounts;
                this.bankAccountsPending = false;

                // Repair the number if it was somehow corrupted
                this.updateNumberOfBankAccountsOnTeam();
            },
            error: (error) => {
                this.bankAccountsPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Bankkonten konnten nicht geladen werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Bank Accounts
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Financial Institution Selection
    //****************************************************************************/
    protected trackByFinancialInstitutionGroupId(_: number, group: FinancialInstitutionsGroup): string {
        return group.id;
    }

    protected startFinancialInstitutionSelection(): void {
        this.selectingFinancialInstitution = 'OVERVIEW';
        if (this.financialInstitutions.length === 0) {
            this.loadFinancialInstitutions();
        }
    }

    protected navigateToFinancialInstitutionCountrySelection() {
        this.selectingFinancialInstitution = 'COUNTRY';
    }

    protected selectFinancialInstitutionCountry(country: 'DE' | 'AT') {
        this.selectingFinancialInstitution = 'OVERVIEW';
        this.selectingFinancialInstitutionCountry = country;
        this.loadFinancialInstitutions();
    }

    protected handleBackButtonClick() {
        switch (this.selectingFinancialInstitution) {
            case 'COUNTRY':
                this.selectingFinancialInstitution = 'OVERVIEW';
                break;

            case 'CATEGORY':
                this.selectingFinancialInstitution = 'OVERVIEW';
                break;
        }
    }

    protected async loadFinancialInstitutions() {
        try {
            this.financialInstitutionsLoading = true;
            const financialInstitutions = await this.financialInstitutionsService
                .find({ country: this.selectingFinancialInstitutionCountry })
                .toPromise();
            this.financialInstitutionsLoading = false;

            this.financialInstitutions = financialInstitutions;
            this.filterFinancialInstitutions();
            this.filterFinancialInstitutionGroups();
        } catch (err) {
            this.financialInstitutionsLoading = false;
            this.apiErrorService.handleAndRethrow({
                axError: err,
                handlers: {},
                defaultHandler: {
                    title: 'Banken konnten nicht geladen werden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    protected filterFinancialInstitutions() {
        const searchTerm = this.financialInstitutionsSearch?.toLowerCase().trim();
        const selectedGroup = this.financialInstitutionsGroups.find(
            ({ id }) => id === this.financialInstitutionsCategory,
        );

        this.financialInstitutionsFiltered = this.financialInstitutions.filter((financialInstitution) => {
            // Filter by country
            if (!financialInstitution.countries.includes(this.selectingFinancialInstitutionCountry)) {
                return false;
            }

            // Filter by selected financial institution group
            if (selectedGroup) {
                if (financialInstitution.logo !== selectedGroup.logo) {
                    return false;
                }
            }

            // Filter by search term
            if (searchTerm) {
                return financialInstitution.name.toLowerCase().includes(searchTerm);
            }
            return true;
        });
    }

    protected filterFinancialInstitutionGroups() {
        this.financialInstitutionsGroups = financialInstitutionsGroups
            .map((group) => {
                if (typeof group === 'string') {
                    const financialInstitution = this.financialInstitutions.find(({ id }) => id === group);
                    if (financialInstitution) {
                        return {
                            id: group,
                            directSelection: financialInstitution.id,
                            name: financialInstitution.name,
                            logo: financialInstitution.logo,
                            countries: financialInstitution.countries,
                        };
                    }
                    return undefined;
                }
                return group;
            })
            .filter((group) => !!group)
            // Filter by country
            .filter(({ countries }) => countries.includes(this.selectingFinancialInstitutionCountry))
            // Add Sandbox finance for development/ beta
            .concat(
                ...(isProduction()
                    ? []
                    : [
                          {
                              id: 'SANDBOX_FINANCE',
                              name: 'Sandbox Finance',
                              directSelection: 'SANDBOXFINANCE_SFIN0000',
                              logo: 'https://cdn-logos.gocardless.com/ais/SANDBOXFINANCE_SFIN0000.png',
                              countries: ['DE'],
                          },
                      ]),
            );
    }

    protected clearFinancialInstitutionsSearch() {
        this.financialInstitutionsSearch = '';
        this.filterFinancialInstitutions();
    }

    protected handleFinancialInstitutionGroupClick(group: FinancialInstitutionsGroup) {
        if (group.directSelection) {
            this.handleFinancialInstitutionSelected(group.directSelection);
            return;
        }

        this.financialInstitutionsCategory = group.id;
        this.selectingFinancialInstitution = 'CATEGORY';
        this.filterFinancialInstitutions();
    }

    protected handleFinancialInstitutionSelected(financialInstitutionId: string) {
        this.openBankAccountConnectionProcess({ financialInstitutionId });
    }

    protected navigateBack() {
        this.selectingFinancialInstitution = 'OVERVIEW';
        this.financialInstitutionsCategory = undefined;
        this.clearFinancialInstitutionsSearch();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Financial Institution Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Bank Account Selection
    //****************************************************************************/

    protected toggleBankAccountSelection(bankAccount: GocardlessRequisitionBankAccount) {
        const index = this.selectedBankAccountIds.indexOf(bankAccount.id);
        if (index === -1) {
            this.selectedBankAccountIds.push(bankAccount.id);
        } else {
            this.selectedBankAccountIds.splice(index, 1);
        }
    }

    protected getGocardlessBankAccountDetails(bankAccount: GocardlessRequisitionBankAccount): string {
        const parts: string[] = [bankAccount.bankName, bankAccount.iban, bankAccount.bic];
        return parts.filter((part) => !!part).join(' | ');
    }

    protected confirmBankAccountsSelection() {
        if (this.bankAccountConnectionCreationPending) return;

        if (this.selectedBankAccountIds.length === 0) {
            this.toastService.error('Kein Bankkonto ausgewählt', 'Bitte wähle mindestens ein Bankkonto aus.');
            return;
        }

        this.bankAccountConnectionCreationPending = true;
        for (const accountId of this.selectedBankAccountIds) {
            this.handleBankAccountConnectionFlowFinished({
                requisitionId: this.requisitionId,
                accountId,
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bank Account Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Bank Account Connection
    //****************************************************************************/
    /**
     * Open the connection process with Gocardless.
     */
    public async openBankAccountConnectionProcess({ financialInstitutionId }: { financialInstitutionId: string }) {
        if (this.bankAccountConnectionCreationPending) {
            return;
        }

        this.bankAccountConnectionCreationPending = true;

        try {
            const { link, requisitionId } = await this.bankAccountConnectionService
                .create({ financialInstitutionId })
                .toPromise();

            this.newWindowService.open(link);
            // Register visibility API to detect when the user closes the GoCardless window and returns to autoiXpert.
            const visibilityChangeHandler = () => {
                if (document.visibilityState === 'visible') {
                    document.removeEventListener('visibilitychange', visibilityChangeHandler);
                    this.ngZone.run(async () => {
                        try {
                            const { status, accounts } = await this.bankAccountConnectionService
                                .get({ requisitionId })
                                .toPromise();

                            // Check if the connection flow has been finished ("LN" means linked, documentation can be found here: https://developer.gocardless.com/bank-account-data/statuses#statuses)
                            if (status === 'LN') {
                                // If multiple bank accounts, let user choose which ones to connect
                                if (accounts.length > 1) {
                                    this.requisitionId = requisitionId;
                                    this.bankAccountsToConnect = accounts;
                                    this.selectingFinancialInstitution = 'BANK_ACCOUNTS';
                                    this.selectedBankAccountIds = accounts.map(({ id }) => id);
                                    this.clearFinancialInstitutionsSearch();
                                    this.bankAccountConnectionCreationPending = false;
                                    return;
                                }

                                // If only one bank account, finish the connection flow immediately
                                this.handleBankAccountConnectionFlowFinished({
                                    requisitionId,
                                    accountId: accounts[0].id,
                                });
                                return;
                            }
                            this.handleBankAccountConnectionFlowAborted();
                        } catch (error) {
                            this.handleBankAccountConnectionFlowError(error);
                        }
                    });
                }
            };

            document.addEventListener('visibilitychange', visibilityChangeHandler);
        } catch (error) {
            this.bankAccountConnectionCreationPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'GoCardless Verbindungsprozess nicht verfügbar',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    private handleBankAccountConnectionFlowFinished({
        requisitionId,
        accountId,
    }: {
        requisitionId: string;
        accountId: string;
    }): void {
        this.bankAccountConnectionService.patch({ requisitionId, accountId }).subscribe({
            next: ({ bankAccount }) => {
                this.bankAccounts.push(bankAccount);
                this.toastService.success(
                    'Bankkonto verbunden',
                    'Du findest deine Bankkonten im 3-Punkt-Menü oben rechts.',
                );
                this.updateNumberOfBankAccountsOnTeam();
                this.closeDialog();
            },
            error: (error) => {
                this.bankAccountConnectionCreationPending = false;
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        DUPLICATE_BANK_ACCOUNT: (error) => ({
                            title: 'Konto bereits verknüpft',
                            body: `IBAN: ${error.data.iban}
                            
                            Du kannst aber weitere andere Konten verknüpfen.`,
                        }),
                        CONSENT_NOT_RETRIEVED_OR_SAVED: {
                            title: 'Verbindung nicht möglich',
                            body: 'Bitte versuche, dein Konto noch einmal zu verbinden. Dieses Mal über die sogenannte <strong>PSD2-Schnittstelle</strong>.',
                        },
                    },
                    defaultHandler: {
                        title: 'Bankkonto konnte nicht verbunden werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    private handleBankAccountConnectionFlowAborted(): void {
        this.bankAccountConnectionCreationPending = false;
        this.toastService.info('Vorgang abgebrochen', 'Du kannst aber jederzeit einen neuen Anlauf starten.', {
            timeOut: 5000,
        });
    }

    private handleBankAccountConnectionFlowError(error: any): void {
        this.bankAccountConnectionCreationPending = false;
        this.apiErrorService.handleAndRethrow({
            axError: error,
            handlers: {
                NO_BANK_ACCOUNT_GRANTED: {
                    title: 'Kein Bankkonto ausgewählt',
                    body: 'Es wurde kein Zugriff auf ein Bankkonto erteilt. Bitte versuche es erneut und erlaube den Zugriff auf mindestens ein Bankkonto.',
                },
            },
            defaultHandler: {
                title: 'Ein Fehler ist aufgetreten',
                body: "Versuche es erneut oder kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>",
            },
        });
    }

    public disconnectBankAccount(bankAccount: BankAccountForSync): void {
        const { index } = removeFromArray(bankAccount, this.bankAccounts);

        this.bankAccountService.delete(bankAccount._id).subscribe({
            next: () => {
                this.toastService.success('Bankkonto getrennt');
                this.updateNumberOfBankAccountsOnTeam();
            },
            error: (error) => {
                this.bankAccounts.splice(index, 0, bankAccount);
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Bankkonto konnte nicht getrennt werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    private async updateNumberOfBankAccountsOnTeam(): Promise<void> {
        if (!this.team.bankAccountSync) {
            this.team.bankAccountSync = new BankAccountSync();
        }

        // Don't update if they're already equal.
        if (this.team.bankAccountSync.numberOfActiveBankAccounts === this.bankAccounts.length) return;

        this.team.bankAccountSync.numberOfActiveBankAccounts = this.bankAccounts.length;
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
        this.numberOfBankAccountsChange.emit(this.team.bankAccountSync.numberOfActiveBankAccounts);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bank Account Connection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Bank Account Management
    //****************************************************************************/
    public getBankAccountDetails(bankAccount: BankAccountForSync): string {
        const parts: string[] = [bankAccount.bankName, bankAccount.iban, bankAccount.bic];

        return parts.filter((part) => !!part).join(' | ');
    }

    public renameBankAccount(bankAccount: BankAccountForSync): void {
        this.bankAccountTitlesInEditMode.set(bankAccount);
    }

    public leaveEditMode(bankAccount: BankAccountForSync): void {
        this.bankAccountTitlesInEditMode.delete(bankAccount);
    }

    public saveBankAccount(bankAccount: BankAccountForSync): void {
        this.bankAccountService.patch(bankAccount).subscribe({
            error: (error) =>
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Bankkonto nicht gespeichert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                }),
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bank Account Management
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Videos
    //****************************************************************************/
    public openVideo(heading: string, videoUrl: string) {
        this.dialog.open<VideoPlayerDialogComponent, VideoPlayerDialogData>(VideoPlayerDialogComponent, {
            data: {
                heading,
                videoUrl,
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Videos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    public closeDialog(): void {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

/**
 * Grouping of financial institutions for easier selection in the first step of the bank account connection process.
 */
interface FinancialInstitutionsGroup {
    id: string;
    name: string;

    /** Whether this group has only one member and therefore can be directly selected */
    directSelection?: string;

    /** Logo is used to filter financial institutions by */
    logo: string;

    countries: string[];
}

/**
 * Manually create financial institutions groups (taken from Klarna UI) as the GoCardless API does not provide this information.
 * Only store minimal amount of data and fetch the remaining financial institution data from the API to ensure we do not have stale information.
 */
const financialInstitutionsGroups: Array<FinancialInstitutionsGroup | string> = [
    {
        id: 'SPARKASSE',
        name: 'Sparkassen',
        logo: 'https://storage.googleapis.com/gc-prd-institution_icons-production/DE/PNG/sparkasse.png',
        countries: ['DE'],
    },
    {
        id: 'VOLKSBANK',
        name: 'Volksbanken',
        countries: ['DE'],
        logo: 'https://cdn-logos.gocardless.com/ais/VOLKSBANK_NIEDERGRAFSCHAFT_GENODEF1HOO.png',
    },
    'RAIFFEISEN_AT_RZBAATWW',
    {
        id: 'ERSTE_BANK',
        name: 'Erste Bank und Sparkassen',
        logo: 'https://cdn-logos.gocardless.com/ais/KARNTNER_SPARKASSE_AG_KSPKAT2KXXX.png',
        countries: ['AT'],
    },
    'UNICREDIT_BKAUATWW',
    'BAWAG_BAWAATWW',
    'EASYBANK_BAWAATWW',
    'N26_NTSBDEB1',
    'POSTBANK_PBNKDEFFXXX',
    'COMMERZBANK_COBADEFF',
    'DEUTSCHE_BANK_DEUTDEFF',
    'ING_INGDDEFF',
    'REVOLUT_REVOLT21',
    'BANK_NINENINE_SPBAATWW',
    'COMDIRECT_COBADEHD',
    {
        id: 'SPARDA',
        name: 'Sparda-Bank',
        countries: ['DE'],
        logo: 'https://storage.googleapis.com/gc-prd-institution_icons-production/DE/PNG/spardabank.png',
    },

    'C24_DEFFDEFF',
    'WISE_TRWIGB22',
    'TARGOBANK_CMCIDEDD',
    'DKB_BYLADEM1',
    'BUNQ_BUNQNL2A',
    'QONTO_QNTODEB2XXX',
    'UNICREDIT_HYVEDEMM',
    'OBERBANK_OBKLHUHB',
];

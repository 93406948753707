<div id="subscription-dialog-container">
    <h2 *ngIf="displayOptions.fullBooking">Account buchen</h2>
    <h2 *ngIf="!displayOptions.fullBooking && displayOptions.directDebit">Zahlungsmittel bearbeiten</h2>
    <h2 *ngIf="!displayOptions.fullBooking && displayOptions.invoiceAddress">Rechnungsadresse bearbeiten</h2>
    <mat-icon mat-dialog-close *ngIf="dialogMayBeClosed" class="dialog-close-icon">close</mat-icon>
    <!--============================================-->
    <!-- Payment Cycle & Method -->
    <!--============================================-->
    <h3 *ngIf="displayOptions.fullBooking || displayOptions.directDebit">Zahlweise</h3>
    <div
        id="payment-options-row"
        class="options-row"
        *ngIf="displayOptions.fullBooking || displayOptions.directDebit"
        [ngClass]="{ 'only-payment-method-options': !displayOptions.fullBooking }">
        <ng-container *ngIf="displayOptions.fullBooking">
            <!--********** Monthly **********-->
            <div
                class="option-container"
                [ngClass]="{ active: selectedPaymentCycle === 'monthly' }"
                (click)="setPaymentCycle('monthly')">
                <mat-icon class="option-image">today</mat-icon>
                <div class="option-title-container">
                    <span class="option-title">Monatlich</span>
                </div>
            </div>

            <!--********** Annually **********-->
            <div
                class="option-container"
                [ngClass]="{ active: selectedPaymentCycle === 'annually' }"
                (click)="setPaymentCycle('annually')">
                <mat-icon class="option-image">refresh</mat-icon>
                <div class="option-title-container">
                    <span class="option-title">Jährlich</span>
                </div>
            </div>

            <!--********** Divider **********-->
            <div id="vertical-divider-between-cycle-and-method"></div>
        </ng-container>

        <!--********** Direct Debit Mandate **********-->
        <div
            class="option-container"
            [ngClass]="{ active: selectedPaymentMethod === 'directDebit' }"
            (click)="setPaymentMethod('directDebit')">
            <mat-icon class="option-image">credit_card</mat-icon>
            <div class="option-title-container">
                <span class="option-title">Lastschrift</span>
            </div>
        </div>

        <!--********** Invoice **********-->
        <div
            class="option-container"
            [ngClass]="{
                active: selectedPaymentMethod === 'invoice',
                unselectable: selectedPaymentCycle === 'monthly',
            }"
            (click)="setPaymentMethod('invoice')"
            [matTooltip]="selectedPaymentCycle === 'monthly' ? 'Rechnung nur bei jährlicher Zahlung möglich' : ''">
            <mat-icon class="option-image">receipt</mat-icon>
            <div class="option-title-container">
                <span class="option-title">Überweisung</span>
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Payment Cycle & Method -->
    <!--============================================-->

    <!--============================================-->
    <!-- Audatex Add-on -->
    <!--============================================-->
    <section id="audatex-addon-section" *ngIf="displayOptions.fullBooking">
        <div id="audatex-addon-heading" class="semibold">
            Audatex Add-on
            <span
                class="help-indicator"
                matTooltip="Schnittstelle zu Audatex für VIN-Abfragen, Kalkulationen und Bewertungen. Separater Qapter-Account von Audatex erforderlich.">
                ?
            </span>
        </div>
        <div id="audatex-addon-switch-container">
            <div
                id="audatex-addon-switch-background"
                [ngClass]="{ on: audatexAddon, off: !audatexAddon }"
                (click)="toggleAudatexAddon()">
                <div id="audatex-addon-switch" [ngClass]="{ on: audatexAddon, off: !audatexAddon }">&nbsp;</div>
            </div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Audatex Add-on -->
    <!--============================================-->

    <!--============================================-->
    <!-- Payment Amount -->
    <!--============================================-->
    <section id="payment-summary" *ngIf="displayOptions.fullBooking">
        <div id="payment-summary-label" class="label">
            Betrag pro Monat
            <br />
            zzgl. MwSt.
        </div>

        <div
            id="number-of-users-container"
            matTooltip="Anzahl aktuell registrierter Nutzer. Du kannst auch nach Abschluss jederzeit Nutzer hinzufügen.">
            <img id="number-of-users-icon" src="/assets/images/icons/person-blue.svg" alt="" />
            <div>{{ activeTeamMembers.length }}</div>
        </div>

        <div
            *ngIf="additionalDocumentLayouts"
            id="number-of-document-layouts-container"
            matTooltip="Anzahl eingerichteter Dokumentenlayouts. Du kannst auch nach Abschluss jederzeit weitere Layouts hinzufügen.">
            <mat-icon id="number-of-document-layouts-icon">content_copy</mat-icon>
            <div>{{ documentLayoutGroups.length }}</div>
        </div>

        <div id="payment-summary-amount-container">
            <div id="payment-summary-amount" [matTooltip]="getAccountCostsTooltip()">
                {{ getAccountCosts('perMonth') | number: '1.0-2' }} €
            </div>
            <div class="label" *ngIf="selectedPaymentCycle === 'annually'">
                {{ getAccountCosts('perYear') | number: '1.0-2' }} € pro Jahr
            </div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Payment Amount -->
    <!--============================================-->

    <!--============================================-->
    <!-- Start Date -->
    <!--============================================-->
    <section id="start-date-section" *ngIf="displayOptions.fullBooking">
        <div id="start-date-container">
            <ng-container *ngIf="!startDateInEditMode">
                <span id="start-date-label" class="label" (click)="enterStartDateEditMode()">
                    Ich möchte zum {{ startDate | moment }}
                    <span *ngIf="isStartDateEndOfTestAccount()">(nach Ende des Testaccounts)</span>
                    starten.
                </span>
                <mat-icon id="start-date-edit-icon" class="toolbar-icon small-icon" (click)="enterStartDateEditMode()">
                    edit
                </mat-icon>
            </ng-container>
            <ng-container *ngIf="startDateInEditMode">
                <div>Ich möchte starten zum:</div>
                <date-input
                    [(date)]="startDate"
                    placeholder="Startdatum"
                    [warning]="isStartDateInThePast() ? 'Muss in Zukunft liegen' : ''"></date-input>
                <mat-icon class="toolbar-icon" (click)="leaveStartDateEditMode()" matTooltip="Datum übernehmen">
                    check
                </mat-icon>
            </ng-container>
        </div>
        <div class="info-note warning-note" *ngIf="lateStartDateCausesInterruption()">
            <mat-icon>warning</mat-icon>
            <div>
                Mit einem Starttermin nach Auslauf des Test-Accounts kannst du autoiXpert eine Zeit lang nicht
                verwenden.
                <br />
                Überlege, ob du den Starttermin nicht eher setzen möchtest.
            </div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Start Date -->
    <!--============================================-->

    <!--============================================-->
    <!-- Invoice Address -->
    <!--============================================-->
    <section
        id="invoice-section"
        *ngIf="displayOptions.fullBooking || displayOptions.invoiceAddress || selectedPaymentMethod === 'invoice'">
        <h3>Rechnungsanschrift</h3>

        <!--********** Organisation **********-->
        <mat-form-field class="mat-block">
            <input matInput [(ngModel)]="billingAddress.organization" placeholder="Organisation" />
        </mat-form-field>

        <!--********** First & Last Name **********-->
        <div class="name-group">
            <!--********** First Name **********-->
            <mat-form-field class="first-name">
                <input matInput placeholder="Vorname" [(ngModel)]="billingAddress.firstName" />
            </mat-form-field>

            <!--********** Last Name **********-->
            <mat-form-field class="last-name">
                <input matInput placeholder="Nachname" [(ngModel)]="billingAddress.lastName" />
            </mat-form-field>
        </div>

        <!--********** Street & House Number **********-->
        <mat-form-field class="mat-block">
            <input
                matInput
                [(ngModel)]="billingAddress.streetAndHouseNumberOrLockbox"
                [matAutocomplete]="streetAutocompletion.autocomplete"
                placeholder="Straße & Hausnr." />
            <address-autocompletion
                #streetAutocompletion
                [value]="billingAddress.streetAndHouseNumberOrLockbox"
                [externalAddressAutocompletionEnabled]="true"
                [addressContext]="{ city: billingAddress.city }"
                (addressSelected)="insertAddressIntoModel($event)"></address-autocompletion>
        </mat-form-field>
        <div class="place-group">
            <mat-form-field class="zipCode">
                <input
                    matInput
                    [(ngModel)]="billingAddress.zip"
                    placeholder="PLZ"
                    zip-city-input
                    [street]="billingAddress.streetAndHouseNumberOrLockbox"
                    [city]="billingAddress.city"
                    (cityChange)="insertCityIntoModel($event)" />
            </mat-form-field>
            <mat-form-field class="place">
                <input matInput [(ngModel)]="billingAddress.city" placeholder="Ort" />
            </mat-form-field>
        </div>
    </section>
    <!--============================================-->
    <!-- END Invoice Address -->
    <!--============================================-->

    <!--============================================-->
    <!-- Direct Debit Mandate -->
    <!--============================================-->
    <section
        id="direct-debit-section"
        *ngIf="selectedPaymentMethod === 'directDebit' && (displayOptions.fullBooking || displayOptions.directDebit)">
        <h3>SEPA-Mandat</h3>

        <div id="iban-and-bic-container">
            <!--********** IBAN **********-->
            <mat-form-field class="mat-block">
                <input
                    matInput
                    iban-input
                    placeholder="IBAN"
                    [(ngModel)]="bankAccount.iban"
                    (ibanChange)="handleIbanChangeEvent($event)"
                    (bicRecognized)="handleBicRecognition($event)"
                    (input)="isIbanInvalid = false" />
                <mat-hint *ngIf="isIbanInvalid" class="error">IBAN ungültig</mat-hint>
            </mat-form-field>

            <!--********** BIC **********-->
            <mat-form-field id="bic-form-field">
                <input matInput placeholder="BIC" [(ngModel)]="bankAccount.bic" (input)="capitalizeBic()" />
            </mat-form-field>
        </div>

        <div id="account-owner-and-bankname-container">
            <!--********** Bank Account Owner **********-->
            <mat-form-field class="mat-block">
                <input matInput placeholder="Kontoinhaber" [(ngModel)]="bankAccount.owner" />
            </mat-form-field>

            <!--********** Bank Name **********-->
            <mat-form-field id="bank-name-form-field">
                <input matInput placeholder="Name der Bank" [(ngModel)]="bankAccount.bankName" />
            </mat-form-field>
        </div>

        <div id="direct-debit-conditions-container">
            <mat-checkbox [(ngModel)]="directDebitConditionsAcknowledged"></mat-checkbox>
            <div class="label" (click)="directDebitConditionsAcknowledged = !directDebitConditionsAcknowledged">
                Der genannte Kontoinhaber ermächtigt die autoiXpert GmbH & Co. KG, Zahlungen von seinem Konto mittels
                Lastschrift einzuziehen. Zugleich weist der genannte Kontoinhaber sein Kreditinstitut an, die von der
                autoiXpert GmbH & Co. KG auf sein Konto gezogenen Lastschriften einzulösen.
            </div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Direct Debit Mandate -->
    <!--============================================-->

    <!--============================================-->
    <!-- Data Protection & Terms and Conditions -->
    <!--============================================-->
    <div id="terms-and-contitions" class="label" *ngIf="displayOptions.fullBooking">
        Es gelten unsere
        <a href="https://www.autoixpert.de/Nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a>
        & unsere
        <a href="https://www.autoixpert.de/Datenschutz" target="_blank">Datenschutzerklärung</a>
        .
    </div>
    <!--============================================-->
    <!-- END Data Protection & Terms and Conditions -->
    <!--============================================-->

    <div id="dialog-buttons-container">
        <button (click)="placeOrder()" [ngClass]="{ disabled: !orderPlacementAllowed() || orderPlacementPending }">
            <img
                class="pending-icon"
                src="/assets/images/icons/loading-indicator-white.svg"
                *ngIf="orderPlacementPending" />
            <ng-container *ngIf="displayOptions.fullBooking">Kostenpflichtig bestellen</ng-container>
            <ng-container *ngIf="!displayOptions.fullBooking && displayOptions.directDebit">
                <ng-container *ngIf="selectedPaymentMethod === 'directDebit'">Per Lastschrift zahlen</ng-container>
                <ng-container *ngIf="selectedPaymentMethod === 'invoice'">Per Überweisung zahlen</ng-container>
            </ng-container>
            <ng-container *ngIf="!displayOptions.fullBooking && displayOptions.invoiceAddress">
                Rechnungsadresse speichern
            </ng-container>
        </button>
        <button class="secondary" (click)="close()" *ngIf="dialogMayBeClosed">Abbrechen</button>
    </div>
</div>

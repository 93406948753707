import { GarageFeeSet } from '../../../models/contacts/garage-fee-set';
import { Garage } from '../../../models/reports/involved-parties/garage';
import { findRecordById } from '../../arrays/find-record-by-id';

/**
 * Extract the selected fee set from all fee sets of a garage.
 * @param garage
 */
export function getSelectedGarageFeeSet(garage: Garage): GarageFeeSet {
    if (!garage?.contactPerson?.garageFeeSets) {
        return;
    }

    return findRecordById(garage.contactPerson.garageFeeSets, garage.selectedFeeSetId);
}

<div class="mat-quill">
    <div class="toolbar" #toolbar>
        <span class="ql-formats">
            <button type="button" class="ql-bold"></button>
            <button type="button" class="ql-italic"></button>
            <button type="button" class="ql-underline"></button>
        </span>
        <span class="ql-formats">
            <button type="button" class="ql-list" value="ordered"></button>
            <button type="button" class="ql-list" value="bullet"></button>
        </span>
        <span class="ql-formats">
            <select class="ql-align">
                <option selected="selected"></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
            </select>
        </span>
        <!--        <span class="ql-formats">-->
        <!--            <button type="button" class="ql-link"></button>-->
        <!--            <button type="button" class="ql-image"></button>-->
        <!--            <button type="button" class="ql-table"></button>-->
        <!--        </span>-->
        <span class="ql-formats">
            <button type="button" class="ql-color"></button>
        </span>
        <span class="ql-formats" *ngIf="isEmailQuill">
            <button type="button" class="ql-image"></button>
            <button type="button" class="ql-link"></button>
        </span>
        <span class="ql-formats">
            <button type="button" class="ql-clean"></button>
        </span>
    </div>
    <div class="editor" #editor [ngStyle]="getEditorHeight()"></div>
    <input
        type="color"
        title="Farbe auswählen"
        [value]="color"
        (change)="handleTextColorChange($event)"
        #colorpicker
        [disabled]="disabled" />
</div>

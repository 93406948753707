import { Report } from '../../models/reports/report';
import { getRepairEffortToReplacementValueRatio } from './get-repair-effort-to-replacement-value-ratio';

/**
 * In order to calculate the assessor's fee, depending on the damage type the damage value is calculated differently.
 * Return value and description to have same logic for tooltip and value
 */
export function getBaseForFeeCalculation(report: Report): { value: number; description: string } {
    if (!report.damageCalculation?.damageType) return { value: null, description: null };

    // Rules say: Only the "real" total loss justifies the replacement value as the base for calculating the fee. For economic total loss, repairCosts + reducedMarketValue must be used.
    // Market says: There are many assessors who interpret the economic loss differently from the norm: the repair costs are higher than the replacement value.
    // So we simply use the ratio to determine whether the replacement value should be used as the base fee.
    // Amendment: If the repair costs are not defined, the ratio is zero. That is only possible if the car is wrecked so bad that the assessor does not have to determine repair costs -> use replacement value.
    // noinspection FallThroughInSwitchStatementJS
    switch (report.damageCalculation.damageType) {
        case 'totalLoss':
        // 130% rule is always above the total loss threshold of 100%.
        case 'repairableDamageTo130Percent':
            return {
                value: report.valuation.vehicleValueGross || 0,
                description: 'Wiederbeschaffungswert',
            };
        case 'economicTotalLoss': {
            const ratio = getRepairEffortToReplacementValueRatio(report);
            if (ratio > 1 || ratio === 0) {
                return {
                    value: report.valuation.vehicleValueGross || 0,
                    description: 'Wiederbeschaffungswert',
                };
            }
        }
        // We want a fall-through here if the economic total loss is used for repairable damages (thus, ratio < 1).
        case 'repairableDamage':
        default:
            return {
                value:
                    report.damageCalculation.repair.repairCostsNet +
                    Math.abs(report.valuation.diminishedValue || 0) +
                    Math.abs(report.valuation.technicalDiminishedValue || 0),
                description: 'Reparaturkosten (netto) + Minderwert (steuerneutral)',
            };
    }
}

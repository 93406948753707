import { generateId } from '../../../lib/generate-id';

/**
 * Custom Car Properties are used to store additional information about a car.
 * These information do not fit into the predefined fields of a car and
 * improve identification for different vehicle types (e.g. bicycles, boats, ...)
 */
export class CustomCarProperty {
    constructor(template: Partial<CustomCarProperty> = {}) {
        Object.assign(this, template);
    }
    _id = generateId();
    title: string; // Title is displayed in the left column of the car data table.
    value: string; // Value is displayed in the right column of the car data table.
}

export class CustomCarPropertyAutocomplete {
    constructor(template: Partial<CustomCarPropertyAutocomplete> = {}) {
        Object.assign(this, template);
    }
    _id = generateId();
    title: CustomCarProperty['title'];
    values: CustomCarProperty['value'][] = [];

    // Add this property as default (currently if the vehicle shape is set to bicycle or pedelec)
    addAsDefault = false;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AbstractFieldGroupConfigService } from '@autoixpert/abstract-services/field-group-config.abstract.service';
import { FieldConfig } from '@autoixpert/models/custom-fields/field-config';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { OfflineSyncWithInMemoryCacheServiceBase } from '../libraries/database/offline-sync-with-in-memory-cache.service-base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class FieldGroupConfigService
    extends OfflineSyncWithInMemoryCacheServiceBase<FieldGroupConfig>
    implements AbstractFieldGroupConfigService
{
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'fieldGroupConfig',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
        });
    }

    public async removeCustomFieldFromAllReports(
        customFieldConfigId: FieldConfig['_id'],
    ): Promise<CustomFieldConfigCleanupResponse> {
        return await this.httpClient
            .post<CustomFieldConfigCleanupResponse>('/api/v0/customFieldConfigCleanup', {
                customFieldConfigId,
            })
            .toPromise();
    }

    /**
     * Stores the information, whether the global editing mode of custom fields is enabled.
     *
     * This information will be discarded at the end of a browser session, i.e. on reload.
     */
    public isEditModeEnabled: boolean = false;
}

export interface CustomFieldConfigCleanupResponse {
    numberOfReportsFromWhichCustomFieldWasRemoved: number;
}

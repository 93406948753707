import moment from 'moment';
import { createInvoice } from '@autoixpert/lib/invoices/create-invoice';
import { Invoice, InvoiceInvolvedParty } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { ContactPerson } from '../../models/contacts/contact-person';
import { InvoiceParameters } from '../../models/invoices/invoice-parameters';
import { InvolvedParty } from '../../models/reports/involved-parties/involved-party';
import { getInvoiceReportData } from './get-invoice-report-data-from-report';
import { getInvolvedPartiesForInvoiceFromReport } from './get-involved-parties-for-invoice-from-report';

/**
 * Invoice Parameters exist in three locations of a report:
 * - report.fees
 * - report.repairConfirmation
 * - report.expertStatements[]
 *
 * This function creates an invoice from those invoice parameters and other required data.
 */
export function createInvoiceFromInvoiceParameters({
    invoiceParameters,
    report,
    user,
    team,
}: {
    invoiceParameters: InvoiceParameters;
    report: Report;
    user: User;
    team: Team;
}) {
    // Make a copy of the invoice params. Goal: Transformations leave the original unchanged
    const invoiceParametersCopy: InvoiceParameters = JSON.parse(JSON.stringify(invoiceParameters));

    let invoiceRecipientContactPerson: ContactPerson;
    if (invoiceParametersCopy.recipientRole === 'custom') {
        invoiceRecipientContactPerson = invoiceParametersCopy.recipient;
    } else {
        /**
         * If no explicit recipient was chosen, assume the claimant. That's a smart default because most invoices
         * are addressed to the claimant.
         */
        const involvedParty: InvolvedParty = report[invoiceParametersCopy.recipientRole] || report.claimant;
        invoiceRecipientContactPerson = JSON.parse(JSON.stringify(involvedParty.contactPerson));
    }

    const invoiceData: Invoice = new Invoice({
        recipient: new InvoiceInvolvedParty({
            role: 'invoiceRecipient',
            contactPerson: invoiceRecipientContactPerson,
        }),
        type: 'invoice',
        number: invoiceParametersCopy.number,
        date: invoiceParametersCopy.date,
        daysUntilDue: invoiceParametersCopy.daysUntilDue,
        lineItems: invoiceParametersCopy.lineItems.filter((lineItem) => lineItem.active), // Remove all inactive line items
        vatRate: invoiceParametersCopy.vatRate,
        vatExemptionReason: invoiceParametersCopy.vatExemptionReason,

        officeLocationId: report.officeLocationId,
        associatedAssessorId: report.responsibleAssessor,
        secondBankAccount: team.invoicing.secondBankAccount?.iban ? team.invoicing.secondBankAccount : undefined,

        isElectronicInvoiceEnabled: invoiceParameters.isElectronicInvoiceEnabled,
        isElectronicInvoiceStatusFrozen: invoiceParameters.isElectronicInvoiceStatusFrozen,

        reportIds: [report._id],
        reportsData: [getInvoiceReportData({ report })],

        // If the team preferences say so, lock the invoice immediately. If this is for a preview, the object won't be saved, so that's no problem.
        lockedAt: team.preferences.bookInvoiceWhenLockingReport ? moment().format() : null,
        lockedBy: team.preferences.bookInvoiceWhenLockingReport ? user._id : null,

        createdBy: user._id,
        teamId: user.teamId,
    });

    /**
     * Involved Parties
     */
    const reportInvolvedParties = getInvolvedPartiesForInvoiceFromReport(report, invoiceParametersCopy.recipientRole);
    /**
     * The recipient is already present in the invoice parameters which is more specific for this invoice in case of
     * expert statements and repair confirmations than the custom recipient from the report.
     */
    delete reportInvolvedParties.recipient;
    Object.assign(invoiceData, reportInvolvedParties);

    return createInvoice({ invoiceData, user, team });
}

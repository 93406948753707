<!--============================================-->
<!-- Tile View
---=============================================-->
<photo-grid
    *ngIf="report"
    [report]="report"
    [photos]="report.photos"
    [gridToolbarShown]="true"
    [floatingActionButtonShown]="true"
    [photoGroupResidualValueExchangeAvailable]="
        report.type !== 'shortAssessment' && report.type !== 'oldtimerValuationSmall'
    "
    [photoGroupRepairConfirmationAvailable]="!!report.repairConfirmation"
    [photoGroupExpertStatementAvailable]="!!report.expertStatements?.length"
    [photoGroupFilterShown]="report.type !== 'shortAssessment' && report.type !== 'oldtimerValuationSmall'"
    [allowCarRegistrationScan]="true"
    [disableKeyboardShortcuts]="photoEditorShown"
    (photoChange)="handlePhotosChange($event)"
    (photoDeletion)="removePhotosFromLeaseReturnItems($event)"
    (carRegistrationScannerClick)="selectPhotoForCarRegistrationScanner($event); showCarRegistrationScannerDialog()"
    (openPhotoEditor)="setupPhotoEditor($event); openPhotoEditor()"
    [disabled]="isReportLocked()"
    #photoGridComponent></photo-grid>
<!--============================================-->
<!-- ENDE Tile View
---=============================================-->

<car-registration-scanner-dialog
    *ngIf="photoForCarRegistrationScanner && carRegistrationScannerDialogShown"
    [report]="report"
    [photoId]="photoForCarRegistrationScanner._id"
    (reportChange)="saveReport()"
    (close)="hideCarRegistrationScannerDialog()"></car-registration-scanner-dialog>

<photo-editor
    *ngIf="photoEditorShown"
    [report]="report"
    [photos]="report.photos"
    [initialPhoto]="initialPhotoForEditor"
    [photoVersion]="photoGroupForEditor"
    (change)="saveReport()"
    (photoWasDeleted)="photoGridComponent.rememberDeletedPhoto($event)"
    (close)="closeEditor()"
    [disabled]="isReportLocked()"
    disabledMessage="Entsperre das Gutachten, bevor du Fotos bearbeiten kannst."></photo-editor>

import { addDocumentToInvoice } from '@autoixpert/lib/documents/add-document-to-invoice';
import { getDocumentOrderForRecipient } from '@autoixpert/lib/documents/get-document-order-for-recipient';
import { getInvolvedPartiesFromInvoice } from '@autoixpert/lib/involved-parties/get-involved-parties-from-invoice';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { Invoice, InvoiceInvolvedParty } from '@autoixpert/models/invoices/invoice';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '../../models/user/user';

/**
 * These documents are added for each new invoice if no documents array exists.
 */
const defaultInvoiceDocumentTemplates: Pick<DocumentMetadata, 'title' | 'type' | 'recipientRole'>[] = [
    {
        title: 'Rechnung',
        type: 'invoice',
        recipientRole: 'invoiceRecipient',
    },
];

export function addDefaultDocumentsToInvoice({
    invoice,
    user,
    team,
}: {
    invoice: Invoice;
    user: User;
    team: Team;
}): void {
    //*****************************************************************************
    //  Add Document Orders per Recipient
    //****************************************************************************/
    /**
     * Documents may be sorted individually per recipient. Create a document order object for each recipient.
     */
    const invoiceInvolvedParties: InvoiceInvolvedParty[] = getInvolvedPartiesFromInvoice({ invoice });
    for (const invoiceInvolvedParty of invoiceInvolvedParties) {
        try {
            getDocumentOrderForRecipient(invoice.documentOrders, invoiceInvolvedParty.role);
        } catch (error) {
            if (error.code === 'DOCUMENT_ORDER_NOT_FOUND_FOR_GIVEN_RECIPIENT') {
                invoice.documentOrders.push(
                    new DocumentOrder({
                        recipientRole: invoiceInvolvedParty.role,
                    }),
                );
            } else {
                throw error;
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add Document Orders per Recipient
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Add Required Documents
    //****************************************************************************/
    for (const defaultDocumentTemplate of defaultInvoiceDocumentTemplates) {
        // Some documents (e.g. letter) are different per recipient role -> match type and recipientRole.
        // Only add documents if they dont exist.
        if (
            invoice.documents.findIndex(
                (existingDocument) =>
                    existingDocument.type === defaultDocumentTemplate.type &&
                    existingDocument.recipientRole === defaultDocumentTemplate.recipientRole,
            ) < 0
        ) {
            addDocumentToInvoice({
                /**
                 * Pass a copy to avoid altering the original document template if the invoice and its documents are
                 * changed anywhere else in the application.
                 */
                newDocument: new DocumentMetadata({ ...defaultDocumentTemplate, createdBy: user._id }),
                team,
                invoice,
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add Required Documents
    /////////////////////////////////////////////////////////////////////////////*/
}

import {
    SignablePdfTemplateConfig,
    SignatureElement,
} from '@autoixpert/models/signable-documents/signable-pdf-template-config';

/**
 * Pick all signature elements from all pages within a signable PDF document.
 */
export function getAllSignatureElementsFromSignablePdfTemplateConfig(
    signablePdfTemplateConfig: SignablePdfTemplateConfig,
): SignatureElement[] {
    return signablePdfTemplateConfig.pages.reduce((allSignatureElements, page) => {
        allSignatureElements.push(...page.signatureElements);
        return allSignatureElements;
    }, []);
}

'use strict';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoggedInUserService } from './logged-in-user.service';

@Injectable()
export class AuthGuard {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private router: Router,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.loggedInUserService.isLoggedIn() === true) {
            //console.log('User logged in. He is allowed to access this route.');
            return true;
        } else {
            console.log(`User not logged in. Access to the route "${state.url}" denied.`);
            this.loggedInUserService.forwardUrl = state.url;
            this.router.navigate(['/Login']);
            return false;
        }
    }
}

import { TireSeason } from './tire';

export class SpareTireEquipment {
    type: 'spareWheel' | 'compactSpareWheel' | 'tireRepairKit' = null;
    dimension: string = null;
    treadInMm: number = null;
    season: TireSeason = null;
    customType?: string;
    manufacturer: string = null;
    comment: string = null;
}

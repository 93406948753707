<div id="three-columns-layout-container" *ngIf="report">
    <!--============================================-->
    <!-- Left Column: Condition & Tires -->
    <!--============================================-->
    <div class="one-third-data-column">
        <!--********** Condition **********-->
        <div class="card card-with-padding">
            <h2>Zustand</h2>
            <!--********** Paint Type **********-->
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Lackart"
                    [(ngModel)]="report.car.paintType"
                    [matAutocomplete]="paintTypeAutocomplete"
                    [disabled]="isReportLocked()"
                    (ngModelChange)="filterPaintTypesAutocomplete(report.car.paintType)"
                    (focus)="filterPaintTypesAutocomplete(report.car.paintType)" />
            </mat-form-field>
            <mat-autocomplete #paintTypeAutocomplete>
                <mat-option
                    *ngFor="let paintType of filteredPaintTypes"
                    [value]="paintType"
                    (mousedown)="$event.preventDefault()"
                    (click)="saveReport()">
                    {{ paintType }}
                </mat-option>
            </mat-autocomplete>

            <!--********** Paint Color **********-->
            <mat-form-field class="mat-block">
                <input
                    matInput
                    [(ngModel)]="report.car.paintColor"
                    placeholder="Lackfarbe"
                    [matTooltip]="report.car.paintColorCode ? 'Farbcode: ' + report.car.paintColorCode : ''"
                    [disabled]="isReportLocked()" />
            </mat-form-field>

            <!--********** Mileage **********-->
            <div id="mileage-container">
                <mat-form-field class="mat-block">
                    <input
                        matInput
                        [(number)]="report.car.mileageMeter"
                        placeholder="Laufleistung abgelesen"
                        [disabled]="isReportLocked()"
                        matTooltip="Aktueller Wert des Tachos"
                        number-input
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0" />
                </mat-form-field>
                <mat-form-field class="mat-block" id="mileage-input-container">
                    <input
                        matInput
                        placeholder="Laufleistung geschätzt"
                        [(number)]="report.car.mileage"
                        [disabled]="isReportLocked()"
                        matTooltip="Weicht ab, falls z. B. der Tacho getauscht wurde."
                        number-input
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0" />
                </mat-form-field>
                <mat-form-field id="mileage-unit-select">
                    <mat-select
                        [(ngModel)]="report.car.mileageUnit"
                        placeholder="Einheit"
                        (selectionChange)="saveReport()"
                        [disabled]="isReportLocked()">
                        <mat-option *ngFor="let mileageUnit of ['km', 'mi']" [value]="mileageUnit">
                            {{ mileageUnit }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!--********** General Inspection **********-->
            <general-inspection-input
                [(nextGeneralInspection)]="report.car.nextGeneralInspection"
                (nextGeneralInspectionChange)="saveReport()"
                [disabled]="isReportLocked()"></general-inspection-input>

            <!--********** Special Equipment **********-->
            <mat-form-field class="mat-block">
                <mat-chip-list #specialEquipmentChipList>
                    <mat-chip
                        *ngFor="let specialEquipment of report.car.specialEquipment"
                        (removed)="removeSpecialEquipment(specialEquipment); saveReport()"
                        [removable]="report.state !== 'done'"
                        [disabled]="report.state === 'done'">
                        {{ specialEquipment }}
                        <mat-icon matChipRemove *ngIf="report.state !== 'done'">close</mat-icon>
                    </mat-chip>
                    <!-- The input should be inside the list or else the chips are rendered below the input. -->
                    <input
                        placeholder="Sonderausstattung"
                        #specialEquipmentInput
                        #specialEquipmentAutocompleteTrigger="matAutocompleteTrigger"
                        [matAutocomplete]="auxiliaryDevicesAutocomplete"
                        [matChipInputFor]="specialEquipmentChipList"
                        (matChipInputTokenEnd)="enterSpecialEquipment($event); saveReport()"
                        [disabled]="report.state === 'done'" />
                    <!--                    (input)="filterSpecialEquipmentAutocomplete($event.target.value)"-->
                    <!--                    (focus)="filterSpecialEquipmentAutocomplete($event.target.value)"-->
                </mat-chip-list>
                <mat-autocomplete
                    #auxiliaryDevicesAutocomplete="matAutocomplete"
                    (mousedown)="$event.preventDefault()"
                    (optionSelected)="
                        selectSpecialEquipmentFromAutocomplete(
                            $event,
                            specialEquipmentInput,
                            specialEquipmentAutocompleteTrigger
                        );
                        saveReport()
                    ">
                    <mat-option
                        *ngFor="let specialEquipmentItem of filteredSpecialEquipment"
                        [value]="specialEquipmentItem">
                        {{ specialEquipmentItem }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <!--********** Tires **********-->
        <tires-editor [car]="report.car" (tireChange)="saveReport()" [disabled]="isReportLocked()"></tires-editor>
    </div>
    <!--============================================-->
    <!-- END Left Column: Condition & Tires -->
    <!--============================================-->

    <!--============================================-->
    <!-- Center & Right Column: Condition Descriptions -->
    <!--============================================-->
    <div id="condition-descriptions-column" class="two-thirds-data-column">
        <!--============================================-->
        <!-- Description Tabs -->
        <!--============================================-->
        <div id="condition-and-total-grade-container">
            <div id="condition-tabs-container" class="card dark-card">
                <!--********** About the vehicle **********-->
                <div
                    class="condition-tab"
                    [ngClass]="{ active: selectedDescriptionTab === 'aboutCar' }"
                    (click)="selectDescriptionTab('aboutCar')">
                    <div class="condition-tab-icon-container">
                        <mat-icon class="condition-tab-icon">description</mat-icon>
                        <mat-icon
                            *ngIf="aboutCarPartiallyComplete()"
                            class="condition-tab-completed-icon"
                            @fadeInAndOut
                            matTooltip="Bearbeitet">
                            check
                        </mat-icon>
                    </div>
                    <div>
                        Über das
                        <br />
                        Fahrzeug
                    </div>
                </div>

                <!--********** Exterior **********-->
                <div
                    class="condition-tab"
                    [ngClass]="{ active: selectedDescriptionTab === 'exterior' }"
                    (click)="selectDescriptionTab('exterior')">
                    <div class="condition-tab-icon-container">
                        <mat-icon class="condition-tab-icon">directions_car</mat-icon>
                        <mat-icon
                            *ngIf="exteriorPartiallyComplete()"
                            class="condition-tab-completed-icon"
                            @fadeInAndOut
                            matTooltip="Bearbeitet">
                            check
                        </mat-icon>
                    </div>
                    <div>Außenbereich</div>
                </div>

                <!--********** Interior **********-->
                <div
                    class="condition-tab"
                    [ngClass]="{ active: selectedDescriptionTab === 'interior' }"
                    (click)="selectDescriptionTab('interior')">
                    <div class="condition-tab-icon-container">
                        <mat-icon class="condition-tab-icon">airline_seat_recline_extra</mat-icon>
                        <mat-icon
                            *ngIf="interiorPartiallyComplete()"
                            class="condition-tab-completed-icon"
                            @fadeInAndOut
                            matTooltip="Bearbeitet">
                            check
                        </mat-icon>
                    </div>
                    <div>Innenbereich</div>
                </div>

                <!--********** Engine & Power Unit **********-->
                <div
                    class="condition-tab"
                    [ngClass]="{ active: selectedDescriptionTab === 'engine' }"
                    (click)="selectDescriptionTab('engine')">
                    <div class="condition-tab-icon-container">
                        <img class="condition-tab-icon" src="/assets/images/icons/engine.svg" />
                        <mat-icon
                            *ngIf="enginePartiallyComplete()"
                            class="condition-tab-completed-icon"
                            @fadeInAndOut
                            matTooltip="Bearbeitet">
                            check
                        </mat-icon>
                    </div>
                    <div>
                        Motor &
                        <br />
                        Antrieb
                    </div>
                </div>

                <!--********** Underbody **********-->
                <div
                    class="condition-tab"
                    [ngClass]="{ active: selectedDescriptionTab === 'underbody' }"
                    (click)="selectDescriptionTab('underbody')">
                    <div class="condition-tab-icon-container">
                        <mat-icon class="condition-tab-icon">refresh</mat-icon>
                        <mat-icon
                            *ngIf="interiorPartiallyComplete()"
                            class="condition-tab-completed-icon"
                            @fadeInAndOut
                            matTooltip="Bearbeitet">
                            check
                        </mat-icon>
                    </div>
                    <div>Unterboden</div>
                </div>
            </div>

            <div id="average-grade-container" class="card card-dark">
                <div id="average-grade-label" class="label">Gesamtnote</div>
                <div id="average-grade">{{ getAverageGrade() }}</div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Description Tabs -->
        <!--============================================-->

        <!--============================================-->
        <!-- Description Inputs -->
        <!--============================================-->

        <div id="condition-inputs-container" class="card card-with-padding">
            <!--============================================-->
            <!-- About the Vehicle Tab-->
            <!--============================================-->
            <ng-container *ngIf="selectedDescriptionTab === 'aboutCar'">
                <h3>Außenbereich</h3>

                <!--********** About the car **********-->
                <div class="description-with-grade-row">
                    <mat-form-field class="mat-block">
                        <mat-quill
                            placeholder="Über das Fahrzeug"
                            [(ngModel)]="report.car.repairedPreviousDamage"
                            [disabled]="isReportLocked()"
                            (change)="saveReport()"></mat-quill>
                        <div matSuffix *ngIf="!isReportLocked()">
                            <!--                            <mat-icon class="input-toolbar-icon" (click)="showPreviousDamageTextTemplateSelector = true" matTooltip="Textvorlagen">playlist_add</mat-icon>-->
                        </div>
                    </mat-form-field>
                    <!--                    <text-block-selector [(text)]="report.car.repairedPreviousDamage"-->
                    <!--                                            (textChange)="saveReport()"-->
                    <!--                                            associatedUserPreference="previousDamageStandardText"-->
                    <!--                                            *ngIf="showPreviousDamageTextTemplateSelector"-->
                    <!--                                            placeholderForResultingText="Vorschäden (repariert)"-->
                    <!--                                            (close)="showPreviousDamageTextTemplateSelector = false"-->
                    <!--                                            [textTemplateType]="'repairedPreviousDamage'"></text-block-selector>-->
                </div>
            </ng-container>
            <!--============================================-->
            <!-- END Previous Damages Tab -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Description Inputs -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Center & Right Column: Condition Descriptions -->
    <!--============================================-->
</div>

import moment from 'moment';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';

export function addCustomResidualValueBidList({ report, user, team }: { report: Report; user: User; team: Team }) {
    addDocumentToReport(
        {
            team,
            report,
            newDocument: new DocumentMetadata({
                /**
                 * A fixed ID prevents duplicate residual value bid lists in this scenario:
                 * - User has two devices
                 * - User is offline
                 * - User adds a custom bid on each device. This would add two custom bid list documents with a unique ID.
                 * - When going back online, both devices add a custom bid list --> 2 in total.
                 *
                 * This fixed ID per report ensures that the mergeArray algorithm knows that this array entry should be unique.
                 */
                _id: `${report._id}-customResidualValueBidList`,
                type: 'customResidualValueBidList',
                title: 'Eigene Restwertgebote',
                uploadedDocumentId: null,
                permanentUserUploadedDocument: false,
                createdAt: moment().format(),
                createdBy: user._id,
            }),
            documentGroup: 'report',
        },
        { insertAfterFallback: 'report' },
    );
}

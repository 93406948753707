import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInAndSlideAnimation } from '@autoixpert/animations/fade-in-and-slide.animation';
import { WorkdaySettings } from '@autoixpert/models/user/preferences/workweek-settings';
import { ToastService } from '../../../../shared/services/toast.service';

/**
 * Configure a workday.
 * - is the weekday a workday?
 * - how many hours are you usually working?
 */
@Component({
    selector: 'workday-settings-column',
    templateUrl: './workday-settings-column.component.html',
    styleUrls: ['./workday-settings-column.component.scss'],
    animations: [
        fadeInAndSlideAnimation({
            direction: 'fromBottom',
        }),
    ],
    host: {
        '[class.active]': 'this.workdaySettings.active',
        '[class.inactive]': '!this.workdaySettings.active',
    },
})
export class WorkdaySettingsColumnComponent {
    constructor(private toastService: ToastService) {}

    @Input() workdaySettings: WorkdaySettings;
    @Output() workdaySettingsChange = new EventEmitter<WorkdaySettings>();

    protected emitChange() {
        this.workdaySettingsChange.emit(this.workdaySettings);
    }

    public showInstructionToast() {
        this.toastService.info('Ziehe den Balken unten größer oder kleiner, um die Arbeitszeit zu verändern.');
    }
}

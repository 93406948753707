import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

/**
 * Combine error handlers related to DEKRA fees (average regional repair fees gathered by DEKRA).
 */
export function getDekraErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        REQUESTING_DEKRA_FEES_FAILED: {
            title: 'DEKRA-Sätze nicht abrufbar',
            body: 'Die DEKRA-Server sind nicht erreichbar.<br><br>Bitte prüfe, ob du über <a href="https://www.dekra.de/de/stundenverrechnungssaetze" target="_blank" rel=”noopener”>DEKRA.de</a> die Kostensätze abrufen kannst.',
            partnerLogo: 'dekra',
        },
        /**
         * This error occurs if no fee set exists at all, usually because of a wrong ZIP code.
         * There are more specific errors below if only the semi-truck error messages are missing.
         */
        NO_DEKRA_FEES_FOUND: (error) => ({
            title: 'Keine DEKRA-Sätze vorhanden',
            body: `Für die Postleitzahl "${error.data.zip}" sind keine DEKRA-Sätze vorhanden.<br><br>Bitte prüfe die Postleitzahl auf <a href="https://www.dekra.de/de/stundenverrechnungssaetze" target="_blank" rel=”noopener”>DEKRA.de</a> oder trage die Sätze manuell in der Kalkulationsoberfläche ein.`,
            partnerLogo: 'dekra',
        }),
        /**
         * If the DEKRA returned a fee set but the values are empty, throw an error because this fee set cannot be used.
         */
        DEKRA_SEMI_TRUCK_OR_BUS_FEES_NOT_FOUND: (error) => ({
            title: 'DEKRA-Sätze ohne LKW-Sätze',
            body: `Für die Postleitzahl "${error.data.zip}" weist die DEKRA keine LKW-Verrechnungssätze aus.<br>Das kannst du nachvollziehen auf <a href="https://www.dekra.de/de/stundenverrechnungssaetze" target="_blank" rel=”noopener”>DEKRA.de</a>.<br><br><strong>Mögliche Lösung:</strong> Die Sätze bei einer Werkstatt anfragen und manuell in der Kalkulationsoberfläche eintragen.`,
            partnerLogo: 'dekra',
        }),
        DEKRA_CAR_FEES_NOT_FOUND: (error) => ({
            title: 'DEKRA-Sätze ohne PKW-Sätze',
            body: `Für die Postleitzahl "${error.data.zip}" weist die DEKRA keine PKW-Verrechnungssätze aus.<br>Das kannst du nachvollziehen auf <a href="https://www.dekra.de/de/stundenverrechnungssaetze" target="_blank" rel=”noopener”>DEKRA.de</a>.<br><br><strong>Mögliche Lösung:</strong> Die Sätze bei einer Werkstatt anfragen und manuell in der Kalkulationsoberfläche eintragen.`,
            partnerLogo: 'dekra',
        }),

        ZIP_FOR_DEKRA_FEES_REQUIRED: {
            title: 'Postleitzahl notwendig für DEKRA-Sätze',
            body: `Bitte gib eine Postleitzahl beim Anspruchsteller ein. Die Nutzung der DEKRA-Sätze wurde deaktiviert.`,
        },
        ZIP_FOR_DEKRA_FEES_IN_WRONG_FORMAT: (error) => ({
            title: 'Postleitzahl für DEKRA-Sätze im falschen Format',
            body: `Bitte gib eine gültige Postleitzahl beim Anspruchsteller ein.<br><br>Aktuelle Postleitzahl: ${error.data.zip}`,
        }),
    };
}

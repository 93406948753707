import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { User } from '@autoixpert/models/user/user';
import { OfflineSyncBlobServiceBase } from '../libraries/database/offline-sync-blob-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class ProfilePictureFileService extends OfflineSyncBlobServiceBase {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected domSanitizer: DomSanitizer,
    ) {
        super({
            serviceName: 'userProfilePicture',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            formDataBlobFieldName: 'photo',
        });
    }

    private profilePictureUrlCache = new Map<string, ProfilePictureUrlCacheEntry>();

    async getProfilePictureUrl({
        userId,
        profilePictureHash,
    }: {
        userId: User['_id'];
        profilePictureHash: User['profilePictureHash'];
    }): Promise<SafeUrl> {
        const cacheEntry: ProfilePictureUrlCacheEntry = this.profilePictureUrlCache.get(userId);
        if (cacheEntry) {
            if (cacheEntry.profilePictureHash && cacheEntry.profilePictureHash === profilePictureHash) {
                return cacheEntry.url;
            } else {
                this.profilePictureUrlCache.delete(userId);
                window.URL.revokeObjectURL(cacheEntry.url.toString());
            }
        }

        const profilePictureBlob: Blob = await this.get(userId, profilePictureHash);
        const safeUrl: SafeUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(profilePictureBlob));

        this.profilePictureUrlCache.set(userId, { profilePictureHash, url: safeUrl });

        return safeUrl;
    }
}

interface ProfilePictureUrlCacheEntry {
    profilePictureHash: string;
    url: SafeUrl;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';
import { Moment } from 'moment';
import { Car } from '@autoixpert/models/reports/car-identification/car';

@Component({
    selector: 'general-inspection-input',
    templateUrl: 'general-inspection-input.component.html',
    styleUrls: ['general-inspection-input.component.scss'],
})
export class GeneralInspectionInputComponent {
    @Input() set nextGeneralInspection(value: Car['nextGeneralInspection']) {
        this._nextGeneralInspection = value;
        this.updateInspectionSticker(this._nextGeneralInspection);
    }

    @Input() hint: string;
    @Input() warning: string;
    @Input() disabled: boolean = false;
    @Input() autofocus: boolean = false;

    @Output() nextGeneralInspectionChange: EventEmitter<Car['nextGeneralInspection']> = new EventEmitter<
        Car['nextGeneralInspection']
    >();
    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();

    public _nextGeneralInspection: Car['nextGeneralInspection'];
    inspectionStickerRotation: number = 0;
    inspectionStickerColor: string = '#ff99cb';
    inspectionDateIsInThePast: boolean;
    inspectionDateOverdueTooltip: string = '';
    inspectionDateIsFarInTheFuture: boolean;

    //*****************************************************************************
    //  Set HU sticker layout
    //****************************************************************************/

    /**
     * Set up the inspection sticker (HU-Plakette). Its rotation depends on the month,
     * its color on the year.
     *
     * If no parameter is provided, it assumes the next general inspection is due
     * today/this month.
     * @param date
     */
    public updateInspectionSticker(date: string): void {
        const dateMoment = date ? moment(date) : undefined;

        this.setInspectionStickerRotation(dateMoment);
        this.setInspectionStickerColor(dateMoment);

        this.displayWarningIfInspectionDateIsUnrealistic(dateMoment);
    }

    private setInspectionStickerRotation(date: Moment) {
        if (!date) {
            this.inspectionStickerRotation = 0;
            return;
        }

        /**
         * Add one because moment return zero-based month numbers.
         */
        const inspectionMonth = date.month() + 1;

        /**
         * A circle has 360 degrees. If you divide it into 12 parts, each part covers
         * 30 degrees.
         *
         * @type {number}
         */
        this.inspectionStickerRotation = inspectionMonth * 30;
    }

    private setInspectionStickerColor(date: Moment) {
        if (!date) {
            this.inspectionStickerColor = '#ffffff';
            return;
        }

        const inspectionYear = date.year();

        let inspectionColor: string = '#ffffff';

        switch (inspectionYear % 6) {
            case 0:
                inspectionColor = '#D25D44';
                break;
            case 1:
                inspectionColor = '#FF9AB9';
                break;
            case 2:
                inspectionColor = '#50AF47';
                break;
            case 3:
                inspectionColor = '#E79105';
                break;
            case 4:
                inspectionColor = '#1AA8F3';
                break;
            case 5:
                inspectionColor = '#F5F111';
                break;
        }

        this.inspectionStickerColor = inspectionColor;
    }

    private displayWarningIfInspectionDateIsUnrealistic(date: Moment) {
        if (!date) {
            this.inspectionDateIsInThePast = null;
            this.inspectionDateIsFarInTheFuture = null;
            this.inspectionDateOverdueTooltip = null;
            return;
        }

        const beginningOfThisMonth: Moment = moment().startOf('month');

        this.inspectionDateIsInThePast = date.isBefore(beginningOfThisMonth);
        this.inspectionDateIsFarInTheFuture = date.isAfter(moment().add(36, 'months'));

        this.inspectionDateOverdueTooltip = `Die HU ist ${moment(date).endOf('month').fromNow()} abgelaufen`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Set HU sticker layout
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitDateChange($event: Car['nextGeneralInspection']): void {
        this.nextGeneralInspectionChange.emit($event);
    }

    public emitFocusEvent(event: FocusEvent): void {
        this.focus.emit(event);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

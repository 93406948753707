import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { DeviceType, DeviceTypeGerman } from '@autoixpert/models/device-type';
import { RealtimeEditSession } from '@autoixpert/models/realtime-editing/realtime-edit-session';
import { RealtimeEditorColorMap } from '@autoixpert/models/realtime-editing/realtime-editor-color-map';
import { User } from '@autoixpert/models/user/user';
import { getDeviceType } from '../../../../shared/libraries/device-detection/get-device-type';
import { trackById } from '../../../../shared/libraries/track-by-id';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { UserService } from '../../../../shared/services/user.service';

@Component({
    selector: 'realtime-edit-indicator',
    templateUrl: 'realtime-edit-indicator.component.html',
    styleUrls: ['realtime-edit-indicator.component.scss'],
    animations: [
        trigger('fadeInAndSlideSmall', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translate3d(0, -5px, 0)',
                }),
                animate(
                    `200ms`,
                    style({
                        opacity: 1,
                        transform: 'translate3d(0, 0, 0)',
                    }),
                ),
            ]),
            transition(':leave', [
                animate(
                    `200ms`,
                    style({
                        opacity: 0,
                        transform: 'translate3d(0, -5px, 0)',
                    }),
                ),
            ]),
        ]),
    ],
})
export class RealtimeEditIndicatorComponent {
    constructor(
        private userService: UserService,
        private loggedInUserService: LoggedInUserService,
    ) {}

    @Input() realtimeEditSessions: RealtimeEditSession[];
    @Input() realtimeEditorColorMap: RealtimeEditorColorMap;

    //*****************************************************************************
    //  User Data
    //****************************************************************************/
    public getUserInitials(userId: string): string {
        const user: User = this.userService.getTeamMemberFromCache(userId);

        return user.initials;
    }

    public getUsersFullName(userId: string): string {
        return this.userService.getTeamMembersName(userId);
    }

    public getRealtimeEditSessionTooltip(realtimeEditSession: RealtimeEditSession): string {
        const loggedInUser = this.loggedInUserService.getUser();

        if (!loggedInUser) return;

        if (realtimeEditSession.createdBy === loggedInUser._id) {
            const currentDeviceType: DeviceType = getDeviceType();
            if (currentDeviceType === 'desktop' && realtimeEditSession.deviceType === currentDeviceType) {
                return `Du hast diesen Vorgang auch in einem anderen Browser-Fenster geöffnet.`;
            } else {
                return `Du hast diesen Vorgang auch am ${this.getDeviceTypeGerman(
                    realtimeEditSession.deviceType,
                )} geöffnet.`;
            }
        } else {
            return `${this.getUsersFullName(
                realtimeEditSession.createdBy,
            )} arbeitet auch in diesem Vorgang\n(am ${this.getDeviceTypeGerman(realtimeEditSession.deviceType)})`;
        }
    }

    public getDeviceTypeGerman(deviceType: DeviceType): DeviceTypeGerman {
        switch (deviceType) {
            case 'desktop':
                return 'Computer';
            case 'tablet':
                return 'Tablet';
            case 'phone':
                return 'Smartphone';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END User Data
    /////////////////////////////////////////////////////////////////////////////*/

    public trackById = trackById;
}

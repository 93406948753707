<div class="dialog-overlay" (click)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <h2>Einstellungen</h2>
        <div id="building-block-name" class="label">{{ buildingBlock.title || 'Kein Titel' }}</div>
        <div class="card-toolbar">
            <mat-icon
                id="table-of-contents-submenu-icon"
                class="toolbar-icon"
                [matMenuTriggerFor]="advancedConfigSubmenu">
                more_vert
            </mat-icon>
            <mat-menu #advancedConfigSubmenu>
                <a mat-menu-item class="menu-item-with-icon" (click)="downloadDocxPartial()" *ngIf="hasDocxPartial">
                    <mat-icon>get_app</mat-icon>
                    DOCX-Vorlage herunterladen
                </a>
                <a mat-menu-item class="menu-item-with-icon" (click)="documentPartialFileUpload.click()">
                    <mat-icon>publish</mat-icon>
                    DOCX-Vorlage hochladen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="removeDocxPartial()"
                    *ngIf="buildingBlock.customPartialHash">
                    <mat-icon>delete</mat-icon>
                    DOCX-Vorlage löschen
                </a>
            </mat-menu>
            <input
                type="file"
                #documentPartialFileUpload
                accept=".docx"
                (change)="onChangeDocumentPartialUploadFile($event.target, $any($event.target).files[0])" />
        </div>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <div id="config-columns">
            <!--============================================-->
            <!-- Shared Config Column -->
            <!--============================================-->
            <div id="shared-config-column">
                <!--============================================-->
                <!-- Page Break -->
                <!--============================================-->
                <section id="page-break-section">
                    <h3>Seitenumbrüche erzwingen</h3>
                    <div class="options-row">
                        <!--********** Page Break Before **********-->
                        <div
                            class="option-container"
                            [ngClass]="{ active: buildingBlock.config.pageBreakBefore }"
                            (click)="togglePageBreakBefore(); emitBuildingBlockChange()">
                            <img class="option-image" src="/assets/images/icons/page-break-before.svg" />
                            <div class="option-title">vor Baustein</div>
                        </div>

                        <!--********** Page Break After **********-->
                        <div
                            class="option-container"
                            [ngClass]="{ active: buildingBlock.config.pageBreakAfter }"
                            (click)="togglePageBreakAfter(); emitBuildingBlockChange()">
                            <img class="option-image" src="/assets/images/icons/page-break-after.svg" />
                            <div class="option-title">nach Baustein</div>
                        </div>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Page Break -->
                <!--============================================-->

                <!--============================================-->
                <!-- Keep Next Paragraph Connected -->
                <!--============================================-->
                <section id="keep-next-paragraph-connected-section">
                    <div class="heading-and-submenu-icon-container">
                        <h3>Text am Seitenumbruch</h3>

                        <span
                            class="help-indicator"
                            matTooltip="Falls ein Baustein nicht mehr vollständig auf eine Seite passt, soll er umgebrochen werden oder vollständig auf die nächste Seite geschoben werden?">
                            ?
                        </span>

                        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="pageBreakSubmenu">more_vert</mat-icon>
                        <mat-menu #pageBreakSubmenu>
                            <mat-option
                                *ngIf="!team.preferences.documentBuildingBlocks.keepNextParagraphConnected"
                                (click)="setKeepNextParagraphConnectedDefault(true)"
                                matTooltip="Der Standard bestimmt das Verhalten aller Bausteine, für die keine eigene Option gewählt wurde.">
                                Standard setzen: Zusammenhalten
                            </mat-option>
                            <mat-option
                                *ngIf="team.preferences.documentBuildingBlocks.keepNextParagraphConnected"
                                (click)="setKeepNextParagraphConnectedDefault(false)"
                                matTooltip="Der Standard bestimmt das Verhalten aller Bausteine, für die keine eigene Option gewählt wurde.">
                                Standard setzen: Trennen, falls erforderlich
                            </mat-option>
                        </mat-menu>
                    </div>
                    <mat-radio-group
                        [(ngModel)]="buildingBlock.config.keepNextParagraphConnected"
                        (change)="emitBuildingBlockChange()">
                        <!--********** Keep together **********-->
                        <mat-radio-button
                            [value]="true"
                            matTooltip="Dieser Baustein wird am Seitenumbruch nicht getrennt. Überschrift & Text werden bei Bedarf geschlossen auf die nächste Seite geschoben.">
                            zusammenhalten
                        </mat-radio-button>

                        <!--********** Allow split **********-->
                        <mat-radio-button
                            [value]="false"
                            matTooltip="Passt dieser Baustein nicht auf die aktuelle Seite, kann er ab dem zweiten Absatz umbrechen. {{
                                '\n\n'
                            }}Der erste Absatz und die Überschrift werden stets zusammen abgedruckt, damit zu keinem Zeitpunkt eine Überschrift allein steht.">
                            trennen, falls erforderlich
                        </mat-radio-button>

                        <!--********** Fall back to default **********-->
                        <mat-radio-button [value]="undefined" matTooltip="Verhalten wie im Standard">
                            Standard
                            <span
                                class="label"
                                [ngSwitch]="team.preferences.documentBuildingBlocks.keepNextParagraphConnected">
                                &nbsp;-&nbsp;
                                <ng-container *ngSwitchCase="true">zusammenhalten</ng-container>
                                <ng-container *ngSwitchCase="false">trennen, falls erforderlich</ng-container>
                            </span>
                        </mat-radio-button>
                    </mat-radio-group>
                </section>
                <!--============================================-->
                <!-- END Keep Next Paragraph Connected -->
                <!--============================================-->

                <!--***********************************************
                ** Counting Report Document Pages
                ************************************************-->
                <section id="exclude-from-report-page-count-section">
                    <div class="heading-and-submenu-icon-container">
                        <h3>Seiten zählen</h3>

                        <span
                            class="help-indicator"
                            matTooltip="Seiten der Gutachten-PDF können für die Schreibkosten auf deinen Ausgangsrechnungen automatisch gezählt werden. Du kannst definieren, welche vom Zählen ausgeschlossen werden sollen.{{
                                '\n\n'
                            }}Die Urteile der Gerichte dazu gehen auseinander. Oft geht es um diese Bausteine:
- Deckblatt
- Inhaltsverzeichnis
- Kalkulation
- Fotoanlage

Standardmäßig werden die Fotoanlage und die Kalkulationsseiten ausgeklammert.">
                            ?
                        </span>
                    </div>

                    <div class="checkbox-group parent-of-an-only-child">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.excludeFromReportPageCount"
                            (change)="emitBuildingBlockChange()">
                            Textbaustein vom Zählen ausschließen
                        </mat-checkbox>
                    </div>
                </section>
                <!--***********************************************
                ** END Counting Report Document Pages
                ************************************************-->
            </div>
            <!--============================================-->
            <!-- END Shared Config Column -->
            <!--============================================-->

            <!--============================================-->
            <!-- Options Custom To Certain Building Blocks -->
            <!--============================================-->
            <div
                id="custom-config-column"
                *ngIf="placeholdersOfBlocksWithCustomOptions.includes(buildingBlock.placeholder)">
                <!-- Some building blocks have their own options -->

                <!--============================================-->
                <!-- Transparent background of claimant name -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder === 'DeckblattOhneFoto'">
                    <h3>Anspruchsteller</h3>
                    <div class="checkbox-group parent-of-an-only-child">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.transparentBackgroundOfClaimantNameOnTitlePage"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Die Box um den Namen des Anspruchstellers soll ohne farbigen Hintergrund gedruckt werden.">
                            Transparente Anspruchsteller-Box
                        </mat-checkbox>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Transparent background of claimant name -->
                <!--============================================-->

                <!--============================================-->
                <!-- Show Organization Instead of Personal Name -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder.includes('Deckblatt')">
                    <h3>Sachverständiger oder Büro</h3>
                    <div class="checkbox-group parent-of-an-only-child">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showOrganizationInsteadOfPersonOnTitlePage"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Auf dem Deckblatt den Namen des Büros statt des Namens des zuständigen Sachverständigen abdrucken">
                            Büroname statt Sachverständigem
                        </mat-checkbox>
                    </div>

                    <ng-container
                        *ngIf="['DeckblattBewertung', 'DeckblattMitFoto'].includes(buildingBlock.placeholder)">
                        <h3>Deckblatt-Foto</h3>
                        <div class="checkbox-group parent-of-an-only-child">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    number-input
                                    placeholder="Fotohöhe"
                                    [(number)]="buildingBlock.config.photoHeight"
                                    (change)="emitBuildingBlockChange()"
                                    [maximumFractionDigits]="0"
                                    [minimumFractionDigits]="0"
                                    matTooltip="Fotohöhe im Gutachten einstellen.{{
                                        '\n\n'
                                    }}Die Standard-Höhe liegt bei 290 px." />
                                <span matSuffix matTooltip="Pixel">px</span>
                            </mat-form-field>
                        </div>
                    </ng-container>
                </section>
                <!--============================================-->
                <!-- END Show Organization Instead of Personal Name -->
                <!--============================================-->

                <!--============================================-->
                <!-- Hide Involved Party Details -->
                <!--============================================-->
                <section
                    *ngIf="
                        buildingBlock.placeholder.includes('Beteiligte') ||
                        buildingBlock.placeholder === 'ZusammenfassungWerkstattinformation'
                    ">
                    <h3>Telefon & E-Mail</h3>
                    <div class="checkbox-group">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showClaimantPhoneAndEmail"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Telefonnummer & E-Mail des {{
                                claimantLabel
                            }} in der Beteiligtenliste des Dokuments einblenden">
                            {{ claimantLabel }}
                        </mat-checkbox>
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showLawyerPhoneAndEmail"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Telefonnummer & E-Mail des Anwalts in der Beteiligtenliste des Dokuments einblenden">
                            Anwalt
                        </mat-checkbox>
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showInsurancePhoneAndEmail"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Telefonnummer & E-Mail der Versicherung in der Beteiligtenliste des Dokuments einblenden">
                            Versicherung
                        </mat-checkbox>
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showGaragePhoneAndEmail"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Telefonnummer & E-Mail der Werkstatt in der Beteiligtenliste des Dokuments einblenden"
                            *ngIf="buildingBlock.placeholder !== 'ZusammenfassungWerkstattinformation'">
                            Werkstatt
                        </mat-checkbox>
                    </div>
                </section>

                <section *ngIf="buildingBlock.placeholder === 'ZusammenfassungWerkstattinformation'">
                    <h3>Weitere Beteiligte</h3>
                    <div class="checkbox-group">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showAuthorOfDamage"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Kontaktinformationen des Unfallgegners in der Beteiligtenliste des Dokuments einblenden">
                            Unfallgegner
                        </mat-checkbox>
                    </div>
                </section>

                <section *ngIf="buildingBlock.placeholder === 'BeteiligteHaftpflichtschaden'">
                    <h3>Bankverbindung</h3>
                    <div class="checkbox-group">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showClaimantIban"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="IBAN des Anspruchstellers in der Beteiligtenliste des Dokuments einblenden">
                            IBAN des Anspruchstellers
                        </mat-checkbox>
                    </div>
                </section>

                <section *ngIf="buildingBlock.placeholder === 'BeteiligteHaftpflichtschaden'">
                    <div class="heading-and-submenu-icon-container">
                        <h3>Platzhalter</h3>
                        <span
                            class="help-indicator"
                            matTooltip="Definiere Platzhalter-Texte, die in der Beteiligten-Tabelle abgedruckt werden, wenn die
                        folgenden Daten nicht erfasst wurden. Der ZAK-Verband empfiehlt beispielsweise die Angabe 'nicht bekannt'.">
                            ?
                        </span>
                    </div>
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            placeholder="Kein Unfalldatum"
                            [(ngModel)]="buildingBlock.config.accidentDateNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Unfalldatum', falls kein Unfalldatum angegeben wurde.{{
                                '\n\n'
                            }}Unfalldatum wird nicht in Tabelle gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>

                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            placeholder="Kein Unfallort"
                            [(ngModel)]="buildingBlock.config.accidentLocationNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Unfallort', falls kein Unfallort angegeben wurde.{{
                                '\n\n'
                            }}Unfallort wird nicht in Tabelle gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>

                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            placeholder="Kein Unfallgegner"
                            [(ngModel)]="buildingBlock.config.authorOfDamageNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Unfallgegner', falls keine Informationen zu Unfallgegner angegeben wurden.{{
                                '\n\n'
                            }}Abschnitt Unfallgegner wird nicht in Tabelle gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>

                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            placeholder="Keine Versicherung"
                            [(ngModel)]="buildingBlock.config.insuranceNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Versicherung', falls keine Informationen zu Versicherung angegeben wurden.{{
                                '\n\n'
                            }}Abschnitt Versicherung wird nicht in Tabelle gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>

                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            placeholder="Keine Werkstatt"
                            [(ngModel)]="buildingBlock.config.garageNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Werkstatt', falls keine Informationen zu Werkstatt angegeben wurden.{{
                                '\n\n'
                            }}Abschnitt Werkstatt wird nicht in Tabelle gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>
                </section>
                <!--============================================-->
                <!-- END Hide Involved Party Details -->
                <!--============================================-->

                <!--============================================-->
                <!-- Photos -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder === 'Fotoanlage'">
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            number-input
                            placeholder="Fotohöhe"
                            [(number)]="buildingBlock.config.photoHeight"
                            (change)="emitBuildingBlockChange()"
                            [maximumFractionDigits]="0"
                            [minimumFractionDigits]="0"
                            matTooltip="Fotohöhe im Gutachten einstellen.{{
                                '\n\n'
                            }}Die Standard-Höhe liegt bei 350 px." />
                        <span matSuffix matTooltip="Pixel">px</span>
                    </mat-form-field>

                    <div
                        id="photo-compression-container"
                        matTooltip="Kompression der Fotos einstellen.{{
                            '\n\n'
                        }}0 % fügt die Bilder unkomprimiert ein, 99 % komprimiert maximal. Die Standard-Kompression ist ein guter Kompromiss und liegt bei 30 %.{{
                            '\n\n'
                        }}Stelle eine geringere Komprimierung ein, wenn Fotos unscharf abgedruckt werden. Nutze eine höhere, wenn die Dateigröße deiner PDFs verkleinert werden soll.">
                        <div class="label">Foto-Komprimierung</div>
                        <div id="photo-compression-slider-container">
                            <mat-slider
                                thumbLabel
                                [min]="0"
                                [max]="99"
                                [(ngModel)]="buildingBlock.config.photoCompression"
                                (input)="buildingBlock.config.photoCompression = $event.value"
                                (change)="emitBuildingBlockChange()"></mat-slider>
                            <div class="value">
                                <mat-form-field class="mat-block">
                                    <input
                                        matInput
                                        class="number-input"
                                        [(ngModel)]="buildingBlock.config.photoCompression"
                                        (change)="keepPhotoCompressionBetween0And99(); emitBuildingBlockChange()" />
                                    <span matSuffix>%</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    *ngIf="
                        buildingBlock.placeholder === 'Fotoanlage' ||
                        buildingBlock.placeholder === 'FotoanlageZweispaltig'
                    ">
                    <div class="checkbox-group parent-of-an-only-child">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.hidePhotoNumbers"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Im Standard wird in der Fotoanlage eine Positionsnummer pro Foto angezeigt. Das kann ausgeblendet werden.">
                            Fotonummer ausblenden
                        </mat-checkbox>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Photos -->
                <!--============================================-->

                <!--============================================-->
                <!-- Garage Fees -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder === 'Werkstattkostensätze'">
                    <h3>Kostensätze</h3>
                    <div class="checkbox-group parent-of-an-only-child">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showGarageFees"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Im Standard werden die Kostensätze nicht abgedruckt, weil sie aus der Kalkulation ableitbar sind. Hier können sie eingeblendet werden.">
                            Kostensätze abdrucken
                        </mat-checkbox>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Garage Fees -->
                <!--============================================-->

                <!--============================================-->
                <!-- Garage in Repair Confirmation Summary -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder === 'ZusammenfassungReparaturbestätigung'">
                    <h3>Zusammenfassung</h3>
                    <div class="checkbox-group parent-of-an-only-child">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showGarage"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Im Standard wird die Werkstatt aus dem Gutachten nicht abgedruckt. Hier kann sie hinzugefügt werden.">
                            Werkstatt aus Gutachten abdrucken
                        </mat-checkbox>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Garage in Repair Confirmation Summary -->
                <!--============================================-->

                <!--============================================-->
                <!-- Values not Determined Label -->
                <!--============================================-->
                <section
                    *ngIf="
                        buildingBlock.placeholder === 'ZusammenfassungKurzgutachten' ||
                        buildingBlock.placeholder === 'ZusammenfassungKaskoschaden' ||
                        buildingBlock.placeholder === 'ZusammenfassungHaftpflichtschaden' ||
                        buildingBlock.placeholder === 'ZusammenfassungWerkstattinformation'
                    ">
                    <h3>Zusammenfassung</h3>

                    <mat-form-field
                        class="mat-block"
                        *ngIf="
                            buildingBlock.placeholder === 'ZusammenfassungKurzgutachten' ||
                            buildingBlock.placeholder === 'ZusammenfassungKaskoschaden' ||
                            buildingBlock.placeholder === 'ZusammenfassungHaftpflichtschaden' ||
                            buildingBlock.placeholder === 'ZusammenfassungWerkstattinformation'
                        ">
                        <input
                            matInput
                            placeholder="Platzhalter: Kein Wiederbeschaffungswert"
                            [(ngModel)]="buildingBlock.config.replacementValueNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Wiederbeschaffungswert', falls kein Wiederbeschaffungswert ermittelt wurde.{{
                                '\n\n'
                            }}Wiederbeschaffungswert wird nicht in Zusammenfassung gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>
                    <mat-form-field
                        class="mat-block"
                        *ngIf="
                            buildingBlock.placeholder === 'ZusammenfassungHaftpflichtschaden' ||
                            buildingBlock.placeholder === 'ZusammenfassungWerkstattinformation'
                        ">
                        <input
                            matInput
                            placeholder="Platzhalter: Kein Restwert ermittelt"
                            [(ngModel)]="buildingBlock.config.residualValueNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Restwert', falls kein Restwert ermittelt wurde.{{
                                '\n\n'
                            }}Restwert wird nicht in Zusammenfassung gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>
                    <mat-form-field
                        class="mat-block"
                        *ngIf="
                            buildingBlock.placeholder === 'ZusammenfassungHaftpflichtschaden' ||
                            buildingBlock.placeholder === 'ZusammenfassungWerkstattinformation'
                        ">
                        <input
                            matInput
                            placeholder="Platzhalter: Keine Restwertgebote"
                            [(ngModel)]="buildingBlock.config.residualValueNoBidsLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Restwert', falls keine Restwertgebote eingegangen sind.{{
                                '\n\n'
                            }}Restwert wird nicht in Zusammenfassung gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>
                    <mat-form-field
                        class="mat-block"
                        *ngIf="
                            buildingBlock.placeholder === 'ZusammenfassungHaftpflichtschaden' ||
                            buildingBlock.placeholder === 'ZusammenfassungWerkstattinformation'
                        ">
                        <input
                            matInput
                            placeholder="Platzhalter: Keine Wertminderung ermittelt"
                            [(ngModel)]="buildingBlock.config.diminishedValueNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile 'Merkantiler Minderwert', falls keine Wertminderung ermittelt wurde.{{
                                '\n\n'
                            }}Wertminderung wird nicht in Zusammenfassung gedruckt, falls Feld leer (Standard)." />
                    </mat-form-field>

                    <!--********** Benefit Adjustment **********-->
                    <mat-form-field
                        class="mat-block"
                        *ngIf="buildingBlock.placeholder === 'ZusammenfassungHaftpflichtschaden'">
                        <input
                            matInput
                            placeholder="Label: Neu-für-alt"
                            [(ngModel)]="buildingBlock.config.labelForBenefitAdjustment"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Ersetze das Label Neu-für-alt durch einen beliebigen anderen Begriff, z. B. Vorteilsausgleich.{{
                                '\n\n'
                            }}Ist dieses Feld leer, druckt autoiXpert das Label Neu-füt-alt ab." />
                    </mat-form-field>

                    <mat-form-field
                        class="mat-block"
                        *ngIf="buildingBlock.placeholder === 'ZusammenfassungKaskoschaden'">
                        <input
                            matInput
                            placeholder="Platzhalter: Keine Selbstbeteiligung angegeben"
                            [(ngModel)]="buildingBlock.config.deductibleNotDeterminedLabel"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Wert in Zeile &quot;Selbstbeteiligung&quot; falls keine Selbstbeteiligung angegeben wurde.{{
                                '\n\n'
                            }}Bleibt dieser Alternativ-Wert leer, wird die Zeile Selbstbeteiligung nicht in der Zusammenfassung gedruckt." />
                    </mat-form-field>

                    <div
                        class="checkbox-group"
                        *ngIf="buildingBlock.placeholder === 'ZusammenfassungHaftpflichtschaden'">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showRoadworthiness"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Lass die Fahrfähigkeit in der Zusammenfassung abdrucken">
                            Fahrfähigkeit abdrucken
                        </mat-checkbox>
                    </div>
                    <div
                        class="checkbox-group"
                        *ngIf="buildingBlock.placeholder === 'ZusammenfassungHaftpflichtschaden'">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showBatteryStatus"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Lass bei Elektro-Fahrzeugen eine Zeile zur Batterie abdrucken. Die Zeile wird nur gedruckt, wenn die Batterie auch dem Fahrzeughalter gehört.">
                            Batteriestatus abdrucken
                        </mat-checkbox>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Values not Determined Label -->
                <!--============================================-->

                <!--============================================-->
                <!-- Market Value Corridors -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder === 'Wiederbeschaffungswert'">
                    <h3>Transparenz</h3>
                    <div class="checkbox-group parent-of-an-only-child">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.showMarketValueCorridors"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Tabelle mit dem kleinsten, dem größten und dem durchschnittlichen Gebrauchtwagenangebot pro Marktwertanalyse abdrucken">
                            Marktwert-Korridore abdrucken
                        </mat-checkbox>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Market Value Corridors -->
                <!--============================================-->

                <!--============================================-->
                <!-- DAT Valuation -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder === 'Bewertung'">
                    <h3>DAT Bewertung</h3>
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            number-input
                            placeholder="Breite der Bewertungs-Seiten"
                            [(number)]="buildingBlock.config.datValuationPdfImageWidth"
                            (change)="emitBuildingBlockChange()"
                            [maximumFractionDigits]="0"
                            [minimumFractionDigits]="0"
                            matTooltip="Breite der Bewertungsseiten im Gutachten einstellen.{{
                                '\n\n'
                            }}Die Standard-Breite liegt bei 720 px." />
                        <span matSuffix matTooltip="Pixel">px</span>
                    </mat-form-field>
                </section>
                <!--============================================-->
                <!-- END DAT Valuation -->
                <!--============================================-->

                <!--============================================-->
                <!-- Repair Calculation -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder === 'Reparaturkostenkalkulation'">
                    <h3>Kalkulation</h3>
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            number-input
                            placeholder="Breite der GT Motive Seiten"
                            [(number)]="buildingBlock.config.gtmotiveCalculationPdfImageWidth"
                            (change)="emitBuildingBlockChange()"
                            [maximumFractionDigits]="0"
                            [minimumFractionDigits]="0"
                            matTooltip="Breite der GT Motive Kalkulationsseiten im Gutachten einstellen. Nicht relevant, falls du nur mit Audatex oder DAT kalkulierst.{{
                                '\n\n'
                            }}Die Standard-Breite liegt bei 720 px." />
                        <span matSuffix matTooltip="Pixel">px</span>
                    </mat-form-field>
                </section>
                <!--============================================-->
                <!-- END Repair Calculation -->
                <!--============================================-->

                <!--============================================-->
                <!-- Letters -->
                <!--============================================-->
                <section
                    *ngIf="
                        buildingBlock.placeholder === 'Brieffenster' ||
                        buildingBlock.placeholder === 'BrieffensterRechnung'
                    ">
                    <h3>Brieffenster</h3>
                    <div class="checkbox-group">
                        <mat-checkbox
                            [(ngModel)]="buildingBlock.config.useFirstAndLastNameAsSender"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Du kannst deinen Vor- & Nachnamen als Absender im Brieffenster abdrucken lassen, z. B. falls dein Firmenname noch nicht am Briefkasten steht.">
                            Vor- & Nachname als Absender
                        </mat-checkbox>

                        <mat-checkbox
                            *ngIf="buildingBlock.placeholder === 'BrieffensterRechnung'"
                            [(ngModel)]="buildingBlock.config.showTaxInfo"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Drucke deine Steuernummer oberhalb des Datums rechts neben dem Adressfenster ab. Hast du keine Steuernummer eingetragen, wird die Umsatzsteuer-ID abgedruckt.">
                            Steuerinformationen anzeigen
                        </mat-checkbox>
                    </div>
                    <!-- Advise user to activate the other building block's option too. -->
                    <div
                        class="info-note margin-top-10"
                        *ngIf="buildingBlock.config.useFirstAndLastNameAsSender"
                        @fadeInAndOut>
                        <mat-icon class="outlined">info</mat-icon>
                        <div>
                            Denke daran, diese Option auch im Baustein
                            <span class="italic">
                                {{
                                    buildingBlock.placeholder === 'Brieffenster'
                                        ? 'Brieffenster Rechnung'
                                        : 'Brieffenster'
                                }}
                            </span>
                            zu aktivieren.
                        </div>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Letters -->
                <!--============================================-->

                <!--============================================-->
                <!-- Print Paint Thickness Sketch -->
                <!--============================================-->
                <section *ngIf="buildingBlock.placeholder === 'Lackschichtdicke'">
                    <h3>Fahrzeugskizze und Tabelle</h3>
                    <div class="checkbox-group">
                        <mat-checkbox
                            [ngModel]="!buildingBlock.config.hidePaintThicknessVehicleSketch"
                            (ngModelChange)="buildingBlock.config.hidePaintThicknessVehicleSketch = !$event"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Die Lackschichtdicke-Messwerte an einer Fahrzeugskizze im Gutachten abdrucken.">
                            Skizze abdrucken
                        </mat-checkbox>
                        <mat-checkbox
                            [ngModel]="!buildingBlock.config.hidePaintThicknessTable"
                            (ngModelChange)="buildingBlock.config.hidePaintThicknessTable = !$event"
                            (change)="emitBuildingBlockChange()"
                            matTooltip="Die Lackschichtdicke-Messwerte in einer Tabelle im Gutachten abdrucken. Wird die Tabelle nicht abgedruckt, werden Kommentare pro Bauteil und eigene Positionen nicht abgedruckt.">
                            Tabelle abdrucken
                        </mat-checkbox>
                    </div>
                    <div class="info-note warning-note" *ngIf="buildingBlock.config.hidePaintThicknessTable">
                        <mat-icon>warning</mat-icon>
                        Ohne Tabelle werden auch Kommentare pro Bauteil nicht im Gutachten abgedruckt. Aktiviere die
                        Tabelle, um alle Kommentare abzudrucken.
                    </div>
                </section>
                <!--============================================-->
                <!-- END Print Paint Thickness Sketch -->
                <!--============================================-->
            </div>
            <!--============================================-->
            <!-- END Options Custom To Certain Building Blocks -->
            <!--============================================-->
        </div>

        <div class="dialog-buttons-container">
            <button (click)="closeDialog()">Schließen</button>
        </div>
    </div>
</div>

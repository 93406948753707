import { generateId } from '../../lib/generate-id';

export class InvoiceLineItemTemplate {
    constructor(options: Partial<InvoiceLineItemTemplate> = {}) {
        Object.assign(this, options);
    }

    _id: string = generateId();

    description: string = '';
    quantity: number = null;
    unitPrice: number = null;
    unit: string = null;

    createdBy: string;
    teamId: string;

    createdAt: string;
    updatedAt: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

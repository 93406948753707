<div class="card dark-card on-dark-background">
    <div class="padding-wrapper">
        <net-and-gross-currency-input
            [lightMode]="true"
            label="Listenpreis inkl. Ausstattung"
            matTooltip="Listenpreis inkl. Ausstattung"
            [(netValue)]="report.valuation.originalPriceWithEquipmentNet"
            (netValueChange)="valueChange.emit()"
            [(grossValue)]="report.valuation.originalPriceWithEquipmentGross"
            (grossValueChange)="valueChange.emit()"
            taxationType="full"></net-and-gross-currency-input>
    </div>
</div>

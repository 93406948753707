import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { DocumentMetadata } from '../../models/documents/document-metadata';
import { Invoice } from '../../models/invoices/invoice';
import { CustomFeeSet } from '../../models/reports/assessors-fee/custom-fee-set';
import { CarEquipment } from '../../models/reports/car-identification/car-equipment';
import { Report } from '../../models/reports/report';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { toGermanDate } from '../ax-luxon';
import { getPhotoVersionFromDocumentMetadata } from '../photos/get-photo-version-from-document-metadata';
import { getHideBiddersWithoutBidSetting } from '../residual-value-request/get-hide-bidders-without-bid-setting';
import { translateDocumentType } from '../translators/translate-document-type';
import { getTemplatePlaceholderValuesBilling } from './get-template-placeholder-values-billing';
import { getTemplatePlaceholderValuesCustomResidualValueBids } from './get-template-placeholder-values-custom-residual-value-bids';
import { getTemplatePlaceholderValuesInvoice } from './get-template-placeholder-values-invoice';
import { getTemplatePlaceholderValuesLetterOrExpertStatement } from './get-template-placeholder-values-letter-or-expert-statement';
import { getTemplatePlaceholderValuesOldtimerValuation } from './get-template-placeholder-values-oldtimer-valuation';
import {
    getPlaceholderValuesPaymentReminderDocument,
    getTemplatePlaceholderValuesPaymentRemindersForInvoice,
} from './get-template-placeholder-values-payment-reminder';
import { getTemplatePlaceholderValuesRepairConfirmation } from './get-template-placeholder-values-repair-confirmation';
import { getTemplatePlaceholderValuesReport } from './get-template-placeholder-values-report';
import {
    PlaceholderValuesBilling,
    PlaceholderValuesCustomerSignatureDocuments,
    PlaceholderValuesDocument,
    PlaceholderValuesInvoice,
    PlaceholderValuesLetter,
    PlaceholderValuesOldtimerValuation,
    PlaceholderValuesPaymentRemindersForInvoice,
    PlaceholderValuesPaymentRemindersForPaymentReminderDocument,
    PlaceholderValuesRepairConfirmation,
    PlaceholderValuesReport,
} from './placeholder-values.types';

/**
 * The placeholders that will be inserted into the document through document building blocks or directly through the document template.
 */
export async function getPlaceholderValues({
    user,
    team,
    teamMembers,
    report,
    carEquipment,
    fieldGroupConfigs,
    customFeeSets,
    invoice,
    canceledInvoice,
    documentMetadata,
    documentGroup,
}: PlaceholderValuesParameters): Promise<PlaceholderValues> {
    // The bank account must always be set since it's sometimes part of assessors' footers.
    let placeholderValues: PlaceholderValues = getTemplatePlaceholderValuesBilling({
        team,
        user,
        invoice: invoice || report?.feeCalculation?.invoiceParameters,
    });

    // Add document metadata placeholders
    placeholderValues.Dokument = {
        Typ: translateDocumentType(documentMetadata.type),
        Gruppe: documentGroup,
    };

    // The report object is undefined for instance on an individual invoice without a link to a report.
    if (report) {
        placeholderValues = {
            ...placeholderValues,
            ...(await getTemplatePlaceholderValuesReport({
                user,
                team,
                report,
                carEquipment,
                teamMembers,
                customFeeSets,
                photoVersion: getPhotoVersionFromDocumentMetadata(documentMetadata),
                fieldGroupConfigs,
            })),
        };

        if (report.type === 'oldtimerValuationSmall') {
            placeholderValues = {
                ...placeholderValues,
                ...(await getTemplatePlaceholderValuesOldtimerValuation({ report })),
            };
        }
    }

    if (invoice) {
        placeholderValues = {
            ...placeholderValues,
            ...(await getTemplatePlaceholderValuesInvoice({
                invoice,
                canceledInvoice,
                user,
                team,
            })),
            /**
             * If an invoice is associated with the document currently being generated, placeholder values
             * like "ErsteMahnung", "HöchsteMahnung" etc. are available.
             */
            ...getTemplatePlaceholderValuesPaymentRemindersForInvoice({
                invoice,
                user,
                team,
                teamMembers,
            }),
        };
    }

    switch (documentMetadata.type) {
        case 'repairConfirmation':
            placeholderValues = {
                ...placeholderValues,
                ...(await getTemplatePlaceholderValuesRepairConfirmation({
                    report,
                    user,
                    team,
                    teamMembers,
                })),
            };
            break;
        case 'paymentReminderLevel0':
        case 'paymentReminderLevel1':
        case 'paymentReminderLevel2':
        case 'paymentReminderLevel3':
            placeholderValues = {
                ...placeholderValues,
                ...getPlaceholderValuesPaymentReminderDocument({
                    invoice,
                    user,
                    team,
                    teamMembers,
                    paymentReminderDocumentMetadata: documentMetadata,
                }),
                ...(await getTemplatePlaceholderValuesLetterOrExpertStatement({
                    user,
                    team,
                    letterDocument: documentMetadata,
                    report,
                    invoice,
                    teamMembers,
                })),
            };
            break;
        case 'customDocument':
        case 'declarationOfAssignment':
        case 'consentDataProtection':
        case 'revocationInstruction':
        case 'powerOfAttorney': {
            const signatures =
                report.signableDocuments.find(
                    (signableDocument) => signableDocument.documentType === documentMetadata.type,
                )?.signatures ?? [];
            placeholderValues = {
                ...placeholderValues,
                // This forces a re-rendering of the document if the signature changed.
                UnterschriftKundeHash: signatures.map((signature) => signature.hash).join(', '),
                // If there is no signature, leave the date empty so the user may print a blanc document where the claimant may add the date manually with a pen.
                UnterschriftKundeDatum: signatures[0]?.date ? toGermanDate(signatures[0].date) : '',
                // This forces a re-rendering of the document if the signature changed.
                ZweiteUnterschriftKundeHash: signatures.map((signature) => signature.hash).join(', '),
                // If there is no signature, leave the date empty so the user may print a blanc document where the claimant may add the date manually with a pen.
                ZweiteUnterschriftKundeDatum: signatures[1]?.date ? toGermanDate(signatures[1].date) : '',
            };
            break;
        }
        case 'customResidualValueBidList': {
            // Check if the user wants to print the bidders without a bid. Preference in report overwrites preference in team.
            const includeBiddersWithNoBid = getHideBiddersWithoutBidSetting(report, team);

            // Simulate that only custom bids exist
            const placeholderValuesCustomBids = getTemplatePlaceholderValuesCustomResidualValueBids(
                report.valuation.customResidualValueBids,
                includeBiddersWithNoBid,
            );
            placeholderValues.Restwert.HöchstesAusgewähltesGebot = placeholderValuesCustomBids.HöchstesEigenesGebot;
            placeholderValues.Restwert.WeitereAusgewählteGebote = placeholderValuesCustomBids.WeitereEigeneGebote;
            break;
        }
        case 'expertStatement':
        case 'letter':
            placeholderValues = {
                ...placeholderValues,
                ...(await getTemplatePlaceholderValuesLetterOrExpertStatement({
                    user,
                    team,
                    letterDocument: documentMetadata,
                    report,
                    invoice,
                    teamMembers,
                })),
            };
            break;
    }

    return placeholderValues;
}

export interface PlaceholderValuesParameters {
    user: User;
    team?: Team;
    teamMembers?: User[];
    report?: Report;
    carEquipment: CarEquipment;
    fieldGroupConfigs: FieldGroupConfig[];
    customFeeSets: CustomFeeSet[];
    invoice?: Invoice;
    canceledInvoice?: Invoice;
    documentMetadata: DocumentMetadata;
    documentGroup?: string;
}

export type PlaceholderValues = Partial<
    PlaceholderValuesDocument &
        PlaceholderValuesReport &
        PlaceholderValuesOldtimerValuation &
        PlaceholderValuesBilling &
        PlaceholderValuesInvoice &
        PlaceholderValuesRepairConfirmation &
        PlaceholderValuesPaymentRemindersForInvoice &
        PlaceholderValuesPaymentRemindersForPaymentReminderDocument &
        PlaceholderValuesLetter &
        PlaceholderValuesCustomerSignatureDocuments
>;

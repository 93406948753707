import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http/index';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { flatten } from 'lodash-es';
import { DateTime } from 'luxon';
import moment from 'moment';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Observable, Subscription, of as observableOf, of } from 'rxjs';
import { filter, skip, switchMap, tap } from 'rxjs/operators';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { GERMAN_DATE_FORMAT } from '@autoixpert/lib/ax-luxon';
import { isNameOrOrganizationFilled } from '@autoixpert/lib/contact-people/is-name-or-organization-filled';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { adaptDocumentOrder } from '@autoixpert/lib/documents/adapt-document-order';
import { addDocumentToInvoice } from '@autoixpert/lib/documents/add-document-to-invoice';
import { addMissingDocumentOrdersToInvoice } from '@autoixpert/lib/documents/add-missing-document-orders-to-invoice';
import { getDocumentOrderForRecipient } from '@autoixpert/lib/documents/get-document-order-for-recipient';
import { getDocumentOrderItemForDocument } from '@autoixpert/lib/documents/get-document-order-item-for-document';
import { getDocumentOrdersOfInvolvedPartiesFromInvoice } from '@autoixpert/lib/documents/get-document-orders-of-involved-parties-from-invoice';
import { getOrderedDocuments } from '@autoixpert/lib/documents/get-ordered-documents';
import { removeDocumentFromInvoice } from '@autoixpert/lib/documents/remove-document-from-invoice';
import { replacePlaceholders } from '@autoixpert/lib/documents/replace-document-building-block-placeholders';
import { generateId } from '@autoixpert/lib/generate-id';
import { getOutgoingMessageScheduledAt } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-scheduled-at';
import { getOutgoingMessageStatus } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-status';
import { httpRetry } from '@autoixpert/lib/rxjs-http-retry';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { translateRecipientRole } from '@autoixpert/lib/translators/translate-recipient-role';
import { isTeamMatureCustomer } from '@autoixpert/lib/users/is-team-mature-customer';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { DocumentOrderItem } from '@autoixpert/models/documents/document-order-item';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { Invoice, InvoiceInvolvedParty, InvoiceInvolvedPartyRole } from '@autoixpert/models/invoices/invoice';
import { OutgoingEmailMessage, OutgoingMessage, OutgoingMessageSchedule } from '@autoixpert/models/outgoing-message';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { TextTemplate } from '@autoixpert/models/text-templates/text-template';
import { EmailSignature } from '@autoixpert/models/user/preferences/email-signature';
import { User } from '@autoixpert/models/user/user';
import { TEST_PERIOD_DURATION_IN_DAYS } from '@autoixpert/static-data/test-period-duration-in-days';
import { fadeInAndOutAnimation } from 'src/app/shared/animations/fade-in-and-out.animation';
import { runChildAnimations } from '../../shared/animations/run-child-animations.animation';
import { sentEmailItemAnimation } from '../../shared/animations/sent-email-item.animation';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { getInvoiceApiErrorHandlers } from '../../shared/libraries/error-handlers/get-invoice-api-error-handlers';
import { findRecordById } from '../../shared/libraries/find-record-by-id';
import { subjectIsVatRelated } from '../../shared/libraries/text-templates/subject-is-vat-related';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { AXRESTClient } from '../../shared/services/ax-restclient';
import { DownloadService } from '../../shared/services/download.service';
import { EmailService } from '../../shared/services/email.service';
import { EmailSignatureService } from '../../shared/services/emailSignature.service';
import { FieldGroupConfigService } from '../../shared/services/field-group-config.service';
import { InvoiceDetailsService } from '../../shared/services/invoice-details.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { OutgoingMessageService } from '../../shared/services/outgoing-message.service';
import { ReportService } from '../../shared/services/report.service';
import { TemplatePlaceholderValuesService } from '../../shared/services/template-placeholder-values.service';
import { TextTemplateService } from '../../shared/services/textTemplate.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';

@Component({
    selector: 'invoice-print-and-send',
    templateUrl: 'invoice-print-and-send.component.html',
    styleUrls: ['invoice-print-and-send.component.scss'],
    animations: [
        sentEmailItemAnimation(),
        slideInAndOutVertically(200, 200),
        runChildAnimations(),
        fadeInAndOutAnimation(),
    ],
})
export class InvoicePrintAndSendComponent implements OnInit {
    constructor(
        private invoiceService: InvoiceService,
        private reportService: ReportService,
        private route: ActivatedRoute,
        private toastService: ToastService,
        private httpClient: HttpClient,
        private invoiceDetailsService: InvoiceDetailsService,
        private loggedInUserService: LoggedInUserService,
        private downloadService: DownloadService,
        private emailService: EmailService,
        private emailSignatureService: EmailSignatureService,
        private apiErrorService: ApiErrorService,
        public userPreferences: UserPreferencesService,
        private textTemplateService: TextTemplateService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private templatePlaceholderValuesService: TemplatePlaceholderValuesService,
        private tutorialStateService: TutorialStateService,
        private networkStatusService: NetworkStatusService,
        private outgoingMessageService: OutgoingMessageService,
    ) {}

    public invoiceId: string;
    public invoice: Invoice;
    public report: Report;
    public user: User;
    public team: Team;

    private subscriptions: Subscription[] = [];

    public recipients: InvoiceInvolvedParty[] = [];

    // The documents which are shown for the currently selected recipient. Typically, only cover letters are filtered, all other documents are always shown.
    public filteredDocuments: DocumentMetadata[] = [];

    // Invoice Letter
    public invoiceLetterTemplatesShown: boolean = false;
    public invoiceLetterTemplates: TextTemplate[] = [];

    // Text templates
    public templateSelectorShown: boolean = false;
    public invoiceLetterPreviewPending: boolean = false;
    public placeholderValuesForCurrentInvoice: any;
    private fieldGroupConfigs: FieldGroupConfig[] = [];

    // Documents pane
    public documentHoveredByFile: DocumentMetadata = null; // A reference to the document currently hovered with a dragged file is saved here. If no document is hovered, this is null.
    public fileOverNewDocumentDropZone: boolean = false;
    public nextSingleUploadDocument: DocumentMetadata = null; // When using the three-dot-menu to upload a file, a reference is saved in this attribute. The reference is used after the user chose a file and the file input fires a change event.
    private mouseoutFileuploadHover: number = null; // If autoiXpert reacts to every mouseout event fired (unfortunately including the mouseout events of children), the indicator starts blinking even when hovering child elements such as the document title or the on-off-switch. Avoid that by using a timer.
    private mouseoutNewDocumentHover: number = null;
    public fileOverBodyTimeoutCache = null; // Sometimes, the detection of the onDragEnd event does not work correctly. Thus, we should remove the upload after a second of no other onDragOver event on the body
    public fileIsOverBody: boolean = false; // Becomes true when the user drags files over the window. The drop zone can then be shown.
    public uploader: FileUploader; // Uploader instance for uploading photos to the server
    public renameModeActiveForDocuments = new Map<DocumentMetadata, boolean>();
    /**
     * Set to true if the user clicks on a document toggle while holding shift on his keyboard. That allows him to execute the toggle
     * function for all documents at the same time.
     * This is a shortcut for the respective activate/deactivate all documents in the three-dot menu.
     */
    private userClickedOnDocumentToogleWithShift: boolean = false;

    public pendingDocumentUploads: Map<DocumentMetadata, boolean> = new Map<DocumentMetadata, boolean>();
    public pendingDocumentDownloads: Map<DocumentMetadata, boolean> = new Map<DocumentMetadata, boolean>();
    public fullDocumentLoading: boolean = false;

    // Email
    public emailTransmissionPending: boolean = false;
    public emailJustSentInfo: false | { label: string; icon: string } = false;

    // Invoice letter
    public selectedRecipient: InvoiceInvolvedParty;
    public invoiceLetterInEditMode: DocumentMetadata;
    public recipientOfInvoiceLetterInEditMode: InvoiceInvolvedParty;
    private DEFAULT_DOCUMENT_TITLE = 'Neues Dokument';

    // Outgoing Messages
    protected outgoingMessages: OutgoingMessage[] = [];

    async ngOnInit() {
        const routeSubscription = this.route.parent.params.subscribe(async (params) => {
            this.invoiceId = params['invoiceId'];
            this.invoice = await this.invoiceDetailsService.get(this.invoiceId);
            // Abort if the cache does not hold an invoice
            if (!this.invoice) return;

            this.setUpRecipients();

            // Don't await this to keep control flow within this setup method.
            this.getReport();

            // Automatically select the first recipient in the list.
            this.selectRecipient(this.recipients[0]);

            // Wait for placeholders before inserting templates.
            this.placeholderValuesForCurrentInvoice = await this.templatePlaceholderValuesService.getInvoiceValues({
                invoiceId: this.invoice._id,
                letterDocument: this.invoiceLetterInEditMode,
            });

            this.getInvoiceLetterTemplates();
            this.insertDefaultEmailTemplate();
            this.insertVatLetterBasedOnQueryParams();
            this.filterDocuments();
        });

        this.subscriptions.push(
            routeSubscription,
            // Update the team & user in case they were updated in a different tab.
            this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)),
            this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)),
        );

        this.registerWebsocketEventHandlers();
        this.loadOutgoingMessages();

        this.initializeUploader();

        this.fieldGroupConfigs = await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary();
    }

    /**
     * Get a report if one is linked to this invoice.
     */
    private async getReport(): Promise<void> {
        if (this.invoice.reportIds?.length > 0) {
            this.report = await this.reportService.get(this.invoice.reportIds?.[0]);
            this.setUpRecipients();
        }
    }

    private setUpRecipients() {
        // Stand-alone recipients of the invoice
        this.recipients = [this.invoice.recipient];

        // Extend recipients by involved parties from report (except for collective invoice, which is addressed to the invoice recipient only)
        if (this.report && !this.invoice.isCollectiveInvoice) {
            if (!this.invoice.claimant) {
                this.invoice.claimant = new InvoiceInvolvedParty({
                    role: 'claimant',
                });
            }

            this.recipients = [...this.recipients, this.invoice.lawyer, this.invoice.insurance]
                // Lawyer or insurance can be null if they are left blank within the associated report.
                .filter(Boolean);

            //*****************************************************************************
            //  Update Insurance & Lawyer from Report
            //****************************************************************************/
            // Attach contact people from report to invoice. Involved parties on the report are mostly optional, depending on report type.
            this.invoice.claimant.contactPerson = this.report.claimant.contactPerson;

            if (this.report.lawyer && isNameOrOrganizationFilled(this.report.lawyer.contactPerson)) {
                if (!this.invoice.lawyer) {
                    this.invoice.lawyer = new InvoiceInvolvedParty({ role: 'lawyer' });
                    addMissingDocumentOrdersToInvoice({ invoice: this.invoice });
                }
                this.invoice.lawyer.contactPerson = this.report.lawyer.contactPerson;
            }
            if (this.report.insurance && isNameOrOrganizationFilled(this.report.insurance.contactPerson)) {
                if (!this.invoice.insurance) {
                    this.invoice.insurance = new InvoiceInvolvedParty({ role: 'insurance' });
                    addMissingDocumentOrdersToInvoice({ invoice: this.invoice });
                }
                this.invoice.insurance.contactPerson = this.report.insurance.contactPerson;
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Update Insurance & Lawyer from Report
            /////////////////////////////////////////////////////////////////////////////*/

            // If claimant differs from invoice recipient, add him to the recipients array
            const claimant = this.report.claimant.contactPerson;
            const invoiceRecipient = this.invoice.recipient.contactPerson;
            const propertiesToCompare: (keyof ContactPerson)[] = [
                'organization',
                'firstName',
                'lastName',
                'streetAndHouseNumberOrLockbox',
                'zip',
                'city',
                'email',
            ];
            if (propertiesToCompare.some((property) => claimant[property] !== invoiceRecipient[property])) {
                // Add claimant behind recipient
                this.recipients.splice(this.recipients.indexOf(this.invoice.recipient) + 1, 0, this.invoice.claimant);
                this.invoice.claimant.contactPerson = this.report.claimant.contactPerson;
            }
        }

        this.selectRecipient(this.recipients[0]);
    }

    /**
     * Select one of the recipients to send an invoice to.
     */
    public selectRecipient(recipient: InvoiceInvolvedParty) {
        this.selectedRecipient = recipient;

        this.filterDocuments();
        this.getInvoiceLetterTemplates();

        this.insertDefaultEmailTemplate();
    }

    //*****************************************************************************
    //  Invoice Letter Editor
    //****************************************************************************/

    public createNewInvoiceLetter(): void {
        const invoiceLetter = new DocumentMetadata({
            type: 'letter',
            date: todayIso(),
            recipientRole: this.selectedRecipient.role,
            title: this.DEFAULT_DOCUMENT_TITLE,
            createdBy: this.user._id,
        });

        this.addDocumentToFullDocument(invoiceLetter);

        // Display the newly created document.
        this.filterDocuments();

        this.editInvoiceLetter(invoiceLetter);

        if (this.invoiceLetterTemplates.length > 0) {
            this.invoiceLetterTemplatesShown = true;
        }

        this.saveInvoice();

        this.tutorialStateService.markUserTutorialStepComplete('customDocumentCreatedInInvoice');
    }

    public editInvoiceLetter(invoiceLetter: DocumentMetadata): void {
        if (invoiceLetter.type !== 'letter') {
            console.error("You must only select documents of type 'letter'.");
            throw Error('DOCUMENT_TYPE_NOT_ALLOWED');
        }

        this.invoiceLetterInEditMode = invoiceLetter;
        this.recipientOfInvoiceLetterInEditMode = this.recipients.find(
            (recipient) => recipient.role === this.invoiceLetterInEditMode.recipientRole,
        );
    }

    public leaveDocumentEditor(): void {
        this.invoiceLetterInEditMode = null;
    }

    public previewInvoiceLetter(): void {
        if (!this.isDocumentAvailable(this.invoiceLetterInEditMode)) return;

        this.invoiceLetterPreviewPending = true;

        this.httpClient
            .get(
                `/api/v0/invoices/${this.invoice._id}/documents/letters/${this.invoiceLetterInEditMode._id}?format=pdf`,
                {
                    observe: 'response',
                    responseType: 'blob',
                },
            )
            .subscribe({
                next: (response) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                    this.invoiceLetterPreviewPending = false;
                },
                error: (error) => {
                    this.invoiceLetterPreviewPending = false;
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            REPORT_RECIPIENT_INVALID: {
                                title: 'Empfänger nicht gefunden',
                                body: 'Die Kontaktdaten des Empfängers konnte nicht gefunden werden. Ist ein Gutachten verknüpft?',
                            },
                        },
                        defaultHandler: {
                            title: 'Fehler bei Vorschau',
                            body: 'Die Vorschau konnte nicht generiert werden. Bitte kontaktiere die Hotline.',
                        },
                    });
                },
            });
    }

    public removeInvoiceLetter(invoiceLetter: DocumentMetadata): void {
        this.invoice.documents.splice(this.invoice.documents.indexOf(invoiceLetter), 1);

        // If the deleted document was selected, clear the editor.
        if (this.invoiceLetterInEditMode === invoiceLetter) {
            this.invoiceLetterInEditMode = null;
        }

        this.saveInvoice();
    }

    public deriveDocumentTitleFromInvoiceLetterSubject(): void {
        // If the document title has been changed before, don't do anything.
        if (this.invoiceLetterInEditMode.title !== this.DEFAULT_DOCUMENT_TITLE) {
            return;
        }

        this.invoiceLetterInEditMode.title = this.invoiceLetterInEditMode.subject;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Letter Editor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Letter Templates
    //****************************************************************************/
    public getInvoiceLetterTemplates(): void {
        this.getInvoiceLetterTemplates$().subscribe({
            next: (templates) => {
                this.invoiceLetterTemplates = templates;
            },
        });
    }

    private getInvoiceLetterTemplates$(): Observable<TextTemplate[]> {
        return this.textTemplateService.find({
            type: 'invoiceLetter',
            role: this.selectedRecipient.role,
        });
    }

    private replacePlaceholders(templateWithPlaceholders: string): string {
        return replacePlaceholders({
            textWithPlaceholders: templateWithPlaceholders,
            placeholderValues: this.placeholderValuesForCurrentInvoice,
            fieldGroupConfigs: this.fieldGroupConfigs,
        });
    }

    public useInvoiceLetterTemplate(template: TextTemplate): void {
        this.invoiceLetterInEditMode.subject = this.replacePlaceholders(template.subject);
        this.invoiceLetterInEditMode.body = this.replacePlaceholders(template.body);
        this.invoiceLetterInEditMode.title = template.title;

        this.hideInvoiceLetterTemplates();
        // Required to make the document printable immediately. If saveInvoice() wouldn't be called, the server would not know which subject and message to use.
        this.saveInvoice();
    }

    public hideInvoiceLetterTemplates(): void {
        this.invoiceLetterTemplatesShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Letter Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  VAT Letter Query Param
    //****************************************************************************/
    /**
     * The payments dialog may pass a parameter that triggers either
     * - creation of the VAT letter - if the default template exists - or
     * - opening the template selection.
     */
    public insertVatLetterBasedOnQueryParams() {
        const queryParams = this.route.snapshot.queryParams as InvoicePrintAndTransmissionQueryParams;
        if (queryParams.createVatLetter) {
            // If a letter already exists, abort.
            const existingVatLetter: DocumentMetadata = this.invoice.documents.find(
                (doc) => doc.type === 'letter' && subjectIsVatRelated(doc.subject),
            );
            if (existingVatLetter) {
                return;
            }

            // If templates haven't arrived yet, trigger a second fetch. That's better than intermingling this method with the original fetch method.
            const invoiceLetterTemplates = this.invoiceLetterTemplates?.length
                ? of(this.invoiceLetterTemplates)
                : this.getInvoiceLetterTemplates$().pipe(skip(1) /*Skip cache*/);
            invoiceLetterTemplates.subscribe({
                next: (invoiceLetterTemplates) => {
                    // Create a new letter document.
                    this.createNewInvoiceLetter();

                    // Find the right template and fill it in.
                    const vatLetterTemplate: TextTemplate = invoiceLetterTemplates.find((template) =>
                        subjectIsVatRelated(template.subject),
                    );
                    if (vatLetterTemplate) {
                        this.useInvoiceLetterTemplate(vatLetterTemplate);
                    }
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END VAT Letter Query Param
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Email Templates
    //****************************************************************************/
    private insertDefaultEmailTemplate(): Promise<boolean> {
        const emailDefaultTemplateId =
            this.userPreferences.defaultInvoiceEmailTemplates['invoiceEmail'] &&
            this.userPreferences.defaultInvoiceEmailTemplates['invoiceEmail'][this.selectedRecipient.role];

        // Default template must exist
        if (!emailDefaultTemplateId) {
            return Promise.resolve(false);
        }

        // Only insert default if target message is empty
        if (!this.selectedRecipient.outgoingMessageDraft.subject && !this.selectedRecipient.outgoingMessageDraft.body) {
            return new Promise<boolean>((resolve, reject) => {
                this.findMessageTemplateById(emailDefaultTemplateId).subscribe({
                    next: async (textTemplate) => {
                        const placeholderValues = await this.templatePlaceholderValuesService.getInvoiceValues({
                            invoiceId: this.invoice._id,
                            letterDocument: this.invoiceLetterInEditMode,
                        });

                        this.selectedRecipient.outgoingMessageDraft.subject = replacePlaceholders({
                            textWithPlaceholders: textTemplate.subject,
                            placeholderValues,
                            fieldGroupConfigs: this.fieldGroupConfigs,
                            isHtmlAllowed: false,
                        });
                        this.selectedRecipient.outgoingMessageDraft.body = replacePlaceholders({
                            textWithPlaceholders: textTemplate.body,
                            placeholderValues,
                            fieldGroupConfigs: this.fieldGroupConfigs,
                        });

                        /**
                         * If the email signature was added even though no default text template exists, a user would
                         * insert his own signature when visiting the print and transmission screen. Often, however,
                         * another colleague would actually send the email - now with the wrong signature.
                         */
                        await this.insertEmailSignature();

                        this.saveInvoice();
                        resolve(true);
                    },
                    error: reject,
                });
            });
        }
    }

    /**
     * The email signature will be inserted if the user selected a default template that will be inserted when the component
     * is being initialized.
     */
    private async insertEmailSignature(): Promise<void> {
        let emailSignatures: EmailSignature[] = this.emailSignatureService.getAllFromCurrentInMemoryCache();
        /**
         * If there are no email signatures in the memory cache, query the server.
         * The server is not queried immediately to increase performance. Email signatures are inserted very often
         * but changed rarely, so a good in-memory cache increases speed.
         * The server is queried at all to ensure the user can still insert email signatures even if the app has not fully synced
         * (and populated the in-memory cache) after the user refreshed the page.
         */
        if (!emailSignatures.length) {
            emailSignatures = await this.emailSignatureService.find({ createdBy: this.user._id }).toPromise();
        }
        // If there are no email signatures, don't try to insert one.
        if (!emailSignatures.length) {
            return;
        }

        const emailSignatureForThisUser = emailSignatures.find(
            (emailSignature) => emailSignature.createdBy === this.user._id,
        );
        if (emailSignatureForThisUser?.content) {
            const activeEmail = this.selectedRecipient.outgoingMessageDraft;
            if (!activeEmail.body) {
                /**
                 * Gmail adds two line breaks before the signature so that the user can easily click in this space to position his cursor. Since there
                 * is another line break right before the signature, add one here if the field is empty.
                 */
                activeEmail.body = '<p><br class="ql-line-break"></p>';
            }
            activeEmail.body += `<p><br class="ql-line-break"></p>${emailSignatureForThisUser.content}`;
        }
    }

    private findMessageTemplateById(templateId: string): Observable<TextTemplate> {
        return this.textTemplateService
            .find({
                type: 'invoiceEmail',
                role: this.selectedRecipient.role,
            })
            .pipe(
                switchMap((textTemplates) => observableOf(...textTemplates)),
                filter((textTemplate) => textTemplate._id === templateId),
            );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Email Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Full Document
    //****************************************************************************/

    /**
     * There is an applicable DocumentReference for each document.
     * If a document is included or excluded from the full document, it is stored in the full document config and not in the document.
     */
    public getDocumentOrderItemForDocument(document: DocumentMetadata): DocumentOrderItem {
        if (!document) {
            return null;
        }

        let documentOrder: DocumentOrder;
        try {
            documentOrder = getDocumentOrderForRecipient(this.invoice.documentOrders, this.selectedRecipient.role);
        } catch (error) {
            console.error('Error loading the document order for the selected recipient', error);
            this.toastService.error(
                'Dokumentenreihenfolge nicht gefunden',
                `Keine Gesamt-PDF Reihenfolge für Empfänger "${translateRecipientRole(
                    this.selectedRecipient.role,
                )}". Bitte wende dich an die <a href='/Hilfe'>Hotline</a>.`,
            );
            return;
        }

        return getDocumentOrderItemForDocument(documentOrder, document);
    }

    /**
     * Adds a DocumentReference for a given document to the full document config.
     * Appends to each recipient.
     */
    private addDocumentToFullDocument(document: DocumentMetadata, active = true): void {
        addDocumentToInvoice(
            {
                newDocument: document,
                team: this.team,
                invoice: this.invoice,
            },
            {
                includedInFullDocument: active,
                /**
                 * All invoice documents which are added in this screen may exist multiple times. The only document
                 * that may not exist multiple times is the invoice document itself but that's created when the invoice object
                 * is created.
                 */
                allowMultiple: true,
            },
        );
        this.saveInvoice();
    }

    /**
     * Contains the documents of the selected document group that are relevant for the selected recipient.
     */
    public filterDocuments({ retryCounter = 0 }: { retryCounter?: number } = {}): void {
        /**
         * The document order tries to heal itself and afterwards calls the filterDocuments() method recursively. To prevent
         * infinity loops if an error occurs, use a retry counter.
         */
        if (retryCounter > 20) {
            this.toastService.error(
                'Dokumentenreihenfolge defekt',
                'Die Reihenfolge der Dokumente ist defekt und konnte nicht wiederhergestellt werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
            );
            return;
        }

        if (!this.invoice.documents || !this.selectedRecipient) {
            this.filteredDocuments = [];
        }

        const documentOrder = getDocumentOrderForRecipient(this.invoice.documentOrders, this.selectedRecipient.role);
        if (!documentOrder) {
            this.toastService.error(
                'Dokumentenreihenfolge nicht gefunden',
                `Keine Reihenfolge für Empfänger "${translateRecipientRole(
                    this.selectedRecipient.role,
                )}". Bitte wende dich an die <a href='/Hilfe'>Hotline</a>`,
            );
            this.filteredDocuments = [...this.invoice.documents];
            return;
        }

        let sortedDocuments: DocumentMetadata[];
        try {
            sortedDocuments = getOrderedDocuments({
                documentOrder,
                documents: this.invoice.documents,
            });
        } catch (error) {
            if (error.code === 'DOCUMENT_REFERENCE_IN_DOCUMENT_ORDER_BROKEN') {
                const errorData: {
                    documentOrderItem: DocumentOrderItem;
                    documentOrder: DocumentOrder;
                    documents: DocumentMetadata[];
                } = error.data;

                console.log(
                    `Found a document order item "${errorData.documentOrderItem._id}" without a matching document in the invoice.documents array in invoice "${this.invoice._id}". Removing it from the document orders...`,
                );

                removeDocumentFromInvoice({
                    /**
                     * Since the document does not exist, we need to create a stub to make the default functions work.
                     */
                    document: { _id: errorData.documentOrderItem._id } as DocumentMetadata,
                    invoice: this.invoice,
                });

                /**
                 * The offline-sync service ensures that there are not too many requests to the server because it throttles.
                 */
                this.saveInvoice();

                return this.filterDocuments({ retryCounter: retryCounter++ });
            }

            this.toastService.error(
                'Dokumenten-Reihenfolge beschädigt',
                'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
            );
            return;
        }

        //*****************************************************************************
        //  Append Potentially Missing Document Order Items
        //****************************************************************************/
        /**
         * Append all documents which were not in any document order.
         * A user should see all documents, even if a document is not in the full document config.
         */
        const documentOrderItemsOfAllRecipients: DocumentOrderItem[] = flatten(
            getDocumentOrdersOfInvolvedPartiesFromInvoice({
                invoice: this.invoice,
            }).map((documentOrder) => documentOrder.items),
        );
        const documentsWithoutDocumentOrderItems: DocumentMetadata[] = [];
        for (const invoiceDocument of this.invoice.documents) {
            const documentExistsInDocumentOrders: boolean = !!findRecordById(
                documentOrderItemsOfAllRecipients,
                invoiceDocument._id,
            );
            if (!documentExistsInDocumentOrders) {
                documentsWithoutDocumentOrderItems.push(invoiceDocument);
            }
        }

        if (documentsWithoutDocumentOrderItems.length) {
            for (const invoiceDocument of documentsWithoutDocumentOrderItems) {
                /**
                 * Remove the document from the documents array to enable adding it back with the standard methods which expect
                 * that the document does not yet exist.
                 */
                removeFromArray(invoiceDocument, this.invoice.documents);

                addDocumentToInvoice(
                    {
                        newDocument: invoiceDocument,
                        invoice: this.invoice,
                        team: this.team,
                    },
                    {
                        /**
                         * This is important for letters which may exist multiple times in an invoice. Letters
                         * in invoices are created manually by the user.
                         */
                        allowMultiple: true,
                    },
                );
            }

            void this.saveInvoice();

            // Filter again with the fixed document order.
            return this.filterDocuments({ retryCounter: retryCounter++ });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Append Potentially Missing Document Order Items
        /////////////////////////////////////////////////////////////////////////////*/

        this.filteredDocuments = sortedDocuments;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Full Document
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handling Documents
    //****************************************************************************/

    public toggleHeaderAndFooterOnDocuments(): void {
        this.userPreferences.printDocumentsWithoutHeaderAndFooter =
            !this.userPreferences.printDocumentsWithoutHeaderAndFooter;
    }

    public onDocumentReordered(event: CdkDragDrop<string[]>): void {
        if (event.previousIndex === event.currentIndex) {
            return;
        }
        //*****************************************************************************
        //  Sort Document Order
        //****************************************************************************/
        const documentOrder = getDocumentOrderForRecipient(this.invoice.documentOrders, this.selectedRecipient.role);
        if (!documentOrder) {
            this.toastService.error(
                'Dokumentenreihenfolge nicht gefunden',
                `Keine Reihenfolge für Empfänger "${translateRecipientRole(
                    this.selectedRecipient.role,
                )}". Bitte wende dich an die <a href='/Hilfe'>ootline</a>`,
            );
            return;
        }

        moveItemInArray(documentOrder.items, event.previousIndex, event.currentIndex);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Sort Document Order
        /////////////////////////////////////////////////////////////////////////////*/

        // Refresh the view
        this.filterDocuments();

        /**
         * Sort all other recipients' document orders equally.
         * In contrast to the report documents, the invoice documents cannot be sorted individually per recipient. We might add that feature
         * if we hear that need from the market.
         */
        for (const documentOrderToBeSorted of this.invoice.documentOrders) {
            if (documentOrderToBeSorted === documentOrder) {
                continue;
            }

            adaptDocumentOrder({
                templateOrder: documentOrder,
                orderToBeSorted: documentOrderToBeSorted,
                documents: this.invoice.documents,
            });
        }

        // Save the new order back to the server.
        void this.saveInvoice();
    }

    public handleDocumentClick(document: DocumentMetadata, $event: MouseEvent) {
        if ($event.shiftKey) {
            $event.preventDefault();
            this.downloadDocument(document, 'docx');
        } else {
            this.downloadDocument(document, 'pdf');
        }
    }

    protected isInvoiceActive(): boolean {
        const invoiceDocument: DocumentMetadata = this.filteredDocuments.find(
            (document) => document.type === 'invoice',
        );

        /**
         * The invoice document is always a part of the list but it may also be unavailable because the user
         * has not completed the invoice yet.
         */
        if (!invoiceDocument || !this.isDocumentAvailable(invoiceDocument)) {
            return false;
        }

        return this.getDocumentOrderItemByDocument(invoiceDocument).includedInFullDocument;
    }

    public async downloadDocument(document: DocumentMetadata, format: 'pdf' | 'docx' = 'pdf'): Promise<void> {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Dokumente können heruntergeladen werden, sobald du wieder online bist.',
            );
            return;
        }

        // Prevent moochers from using our DOCX download. It seems that most regular testers don't use the DOCX export anyways.
        if (format === 'docx' && !this.isTeamMatureCustomer()) {
            this.toastService.warn('DOCX-Download noch nicht verfügbar', this.getMessageAboutBlockedDocxDownload());
            return;
        }

        this.pendingDocumentDownloads.set(document, true);

        const { downloadPath, httpParams } = this.getDownloadPathAndParams({ document, format });

        // Prevent errors in case of an unknown doc type
        if (!downloadPath) {
            this.toastService.warn('Dokument nicht verfügbar', "Bitte wende dich an die <a href='/Hilfe'>Hotline</a>.");
            console.error('DOWNLOAD_SUFFIX_NOT_AVAILABLE', 'Trying to map for this doc type: ', document.type);
            return;
        }

        try {
            /**
             * Before we had this statement, some users reported that their documents contained old content. This is because the
             * document was generated before the report was saved -> A typical race condition.
             */
            await this.invoiceService.pushToServer();

            const response = await this.httpClient
                .get(`/api/v0/${downloadPath}`, {
                    responseType: 'blob',
                    observe: 'response',
                    params: httpParams,
                })
                .toPromise();

            this.downloadService.downloadBlobResponseWithHeaders(response);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Fehler beim Download',
                    body: 'Die PDF-Datei konnte nicht heruntergeladen werden.',
                },
            });
        } finally {
            this.pendingDocumentDownloads.delete(document);
        }
    }

    private getDownloadPathAndParams({ document, format }: { document: DocumentMetadata; format: 'pdf' | 'docx' }): {
        downloadPath: string;
        httpParams: HttpParams;
    } {
        let downloadPath: string;
        let httpParams = new HttpParams();

        // If a manually uploaded pdf was uploaded and format pdf is requested, the backend will return the userUploadedDocument.
        switch (document.type) {
            case 'invoice':
                httpParams = httpParams.append('format', format);
                downloadPath = `invoices/${this.invoiceId}/documents/invoice`;
                break;
            case 'paymentReminderLevel0':
            case 'paymentReminderLevel1':
            case 'paymentReminderLevel2':
            case 'paymentReminderLevel3':
                httpParams = httpParams.append('format', format);
                downloadPath = `invoices/${this.invoiceId}/documents/${document.type}/${document._id}`;
                break;
            case 'letter':
                httpParams = httpParams.append('format', format);
                downloadPath = `invoices/${this.invoiceId}/documents/letters/${document._id}`;
                break;
            case 'manuallyUploadedPdf':
                downloadPath = `invoices/${this.invoiceId}/documents/userUploads/${document.uploadedDocumentId}`;
                break;
            default:
                console.error('DOWNLOAD_OF_UNKNOWN_DOC_TYPE', document.type);
                this.toastService.error(
                    'Unbekannter Dokumenttyp',
                    'Bitte setze dich mit dem autoiXpert-Support in Verbindung.',
                );
        }
        if (this.userPreferences.printDocumentsWithoutHeaderAndFooter) {
            httpParams = httpParams.append('printWithoutHeaderAndFooter', true);
        }
        return { downloadPath, httpParams };
    }

    public isTeamMatureCustomer(): boolean {
        return isTeamMatureCustomer({ team: this.team });
    }

    public getMessageAboutBlockedDocxDownload(): string {
        const testPeriodEndDate: string = DateTime.fromISO(this.team.createdAt)
            .plus({ day: TEST_PERIOD_DURATION_IN_DAYS })
            .setLocale('de')
            .toLocaleString(GERMAN_DATE_FORMAT);
        return `Falls du diese Funktion vor dem ${testPeriodEndDate} (Ende des Testzeitraums) nutzen möchtest, kontaktiere uns bitte persönlich. Wir schalten sie gerne für dich frei.\n\nDieses Datum bleibt auch von einer Bestellung unberührt.`;
    }

    public async downloadFullDocument(): Promise<void> {
        // Prevent the user from triggering a download while it's still in progress.
        if (this.fullDocumentLoading) {
            return;
        }

        this.fullDocumentLoading = true;

        let url = `/api/v0/invoices/${this.invoiceId}/documents/fullInvoice/${this.selectedRecipient.role}`;
        if (this.userPreferences.printDocumentsWithoutHeaderAndFooter) {
            url += '?printWithoutHeaderAndFooter=true';
        }
        try {
            /**
             * Before we had this statement, some users reported that their documents contained old content. This is because the
             * document was generated before the report was saved -> A typical race condition.
             */
            await this.invoiceService.pushToServer();

            //*****************************************************************************
            //  Download Invoice Separately
            //****************************************************************************/
            let invoicePromise: Promise<HttpResponse<Blob>>;
            if (this.isInvoiceActive() && this.invoice.isElectronicInvoiceEnabled) {
                this.toastService.info(
                    'Rechnung wird separat heruntergeladen',
                    'Eine gültige E-Rechnung muss laut Gesetz eine eigene Datei sein, weshalb sie als zweite Datei neben der Gesamt-PDF heruntergeladen wird.',
                );

                const invoiceDocument: DocumentMetadata = this.filteredDocuments.find(
                    (document) => document.type === 'invoice',
                );

                const { downloadPath, httpParams } = this.getDownloadPathAndParams({
                    document: invoiceDocument,
                    format: 'pdf',
                });

                invoicePromise = this.httpClient
                    .get(`/api/v0/${downloadPath}`, {
                        responseType: 'blob',
                        observe: 'response',
                        params: httpParams,
                    })
                    .toPromise();
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Download Invoice Separately
            /////////////////////////////////////////////////////////////////////////////*/

            const [fullInvoiceExceptInvoiceResponse, invoiceResponse]: [HttpResponse<Blob>, HttpResponse<Blob>] =
                await Promise.all([
                    this.httpClient
                        .get(url, {
                            responseType: 'blob',
                            observe: 'response',
                        })
                        .toPromise(),
                    invoicePromise,
                ]);

            this.downloadService.downloadBlobResponseWithHeaders(fullInvoiceExceptInvoiceResponse);
            if (invoiceResponse) {
                /**
                 * FileSaver.js does not download multiple files at once on Safari (MacOS and iOS). It only downloads the last file.
                 * Wrapping the second saveAs-call in a setTimeout fixes this issue.
                 * wrapping both calls in a setTimeout does not work, therefore we have to apply this fix here and not in the app.component.ts to ensure both files are downloaded in different async event cycles.
                 * See here: https://github.com/eligrey/FileSaver.js/issues/729#issue-1016036112
                 */
                setTimeout(() => {
                    this.downloadService.downloadBlobResponseWithHeaders(invoiceResponse);
                }, 0);
            }
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: () =>
                    this.toastService.error(
                        'Fehler beim Download',
                        'Die PDF-Datei konnte nicht heruntergeladen werden.',
                    ),
            });
        } finally {
            this.fullDocumentLoading = false;
        }
    }

    public toggleRenamingUploadedDocument(document: DocumentMetadata): void {
        this.renameModeActiveForDocuments.set(document, !this.renameModeActiveForDocuments.get(document));
    }

    /**
     * Listens for the user to hit the enter key. If done so, deactivate the edit mode.
     */
    public renameKeydownListener(event: KeyboardEvent, document: DocumentMetadata): void {
        switch (event.key) {
            case 'Enter':
            case 'Escape':
                this.toggleRenamingUploadedDocument(document);
                break;
        }
    }

    public deleteCustomDocument(document: DocumentMetadata): void {
        removeDocumentFromInvoice({
            invoice: this.invoice,
            document,
        });

        // Clear the editor
        if (this.invoiceLetterInEditMode === document) {
            this.invoiceLetterInEditMode = null;
        }

        if (document.type.includes('paymentReminderLevel')) {
            const paymentReminderLevel = this.getPaymentReminderLevel(document);
            if (document.recipientRole === 'invoiceRecipient') {
                this.invoice.recipient.paymentReminders[paymentReminderLevel] = null;
            } else {
                this.invoice[document.recipientRole].paymentReminders[paymentReminderLevel] = null;
            }
        }

        this.filterDocuments();

        // If a PDF file has been uploaded for this document, delete it, too.
        if (document.uploadedDocumentId) {
            this.removeUploadedDocumentFromServer(document).subscribe({
                next: () => {
                    this.saveInvoice();
                },
                error: (error) => {
                    console.error(
                        'The PDF document could not be deleted from the server. It should be cleaned up by an internal scheduler which removes old resources without a reference.',
                        error,
                    );
                },
            });
        }
        // The document entry was created but no PDF has been uploaded yet.
        else {
            this.saveInvoice();
        }
    }

    public getPaymentReminderLevel(document: DocumentMetadata) {
        switch (document.type) {
            case 'paymentReminderLevel0':
                return 'level0';
            case 'paymentReminderLevel1':
                return 'level1';
            case 'paymentReminderLevel2':
                return 'level2';
            case 'paymentReminderLevel3':
                return 'level3';
        }
    }

    public removeUploadedDocument(document: DocumentMetadata) {
        this.removeUploadedDocumentFromServer(document).subscribe({
            next: () => {
                this.toastService.success('Löschen erfolgreich');
            },
        });
        document.uploadedDocumentId = null;
        this.saveInvoice();
    }

    private removeUploadedDocumentFromServer(document: DocumentMetadata) {
        return this.httpClient
            .delete(`/api/v0/invoices/${this.invoiceId}/documents/userUploads/${document.uploadedDocumentId}`)
            .pipe(
                tap({
                    error: () => {
                        this.toastService.error(
                            'Löschen fehlgeschlagen',
                            'Das hochgeladene Dokument konnte nicht gelöscht werden.',
                        );
                    },
                }),
            );
    }

    /**
     * Activate all currently visible documents for the applicable full document config.
     */
    public activateVisibleDocuments() {
        for (const document of this.invoice.documents) {
            const fullDocumentConfigPart = this.getDocumentOrderItemForDocument(document);
            fullDocumentConfigPart.includedInFullDocument = true;
        }
        void this.saveInvoice();
    }

    /**
     * Deactivate all currently visible documents for the applicable full document config.
     */
    public deactivateVisibleDocuments() {
        for (const document of this.invoice.documents) {
            const fullDocumentConfigPart = this.getDocumentOrderItemForDocument(document);
            fullDocumentConfigPart.includedInFullDocument = false;
        }
        void this.saveInvoice();
    }

    /**
     * The click event is fired before the change event. Jot down if the user held shift while clicking.
     */
    public handleToggleClick(event: MouseEvent) {
        this.userClickedOnDocumentToogleWithShift = event.shiftKey;
    }

    public handleToggleChange(event: MatLegacySlideToggleChange) {
        if (this.userClickedOnDocumentToogleWithShift) {
            // This toggle was activated --> activate all documents.
            if (event.checked) {
                this.activateVisibleDocuments();
            } else {
                this.deactivateVisibleDocuments();
            }
            /**
             * If the user clicked with shift this marked should be reset. If the user later would toggle a document
             * with his keyboard, the click event would not fire. If this marker would not be reset, toggling via the
             * keyboard would still activate/deactivate all documents. That's unexpected behavior.
             */
            this.userClickedOnDocumentToogleWithShift = false;
        }
        // Activating or deactivating all documents already causes an invoice save. This line must therefore only be executed if a single toggle is changed.
        else {
            this.saveInvoice();
        }
    }

    /**
     * There is an applicable DocumentReference for each document.
     * If a document is included or excluded from the full document is stored in the full document config and not in the document.
     */
    public getDocumentOrderItemByDocument(document: DocumentMetadata): DocumentOrderItem {
        if (!document) {
            return null;
        }

        let documentOrder: DocumentOrder;
        try {
            documentOrder = getDocumentOrderForRecipient(this.invoice.documentOrders, this.selectedRecipient.role);
        } catch (error) {
            console.error('Error loading the document order for the selected recipient', error);
            this.toastService.error(
                'Dokumentenreihenfolge nicht gefunden',
                `Keine Gesamt-PDF Reihenfolge für Empfänger "${translateRecipientRole(
                    this.selectedRecipient.role,
                )}" und Dokument-Gruppe "invoice". Bitte wende dich an die <a href='/Hilfe'>Hotline</a>.`,
            );
            return;
        }

        return getDocumentOrderItemForDocument(documentOrder, document);
    }

    /**
     * Deactivate Document for current recipient
     */
    public deactivateFromCurrentFullDocument(document: DocumentMetadata) {
        this.getDocumentOrderItemByDocument(document).includedInFullDocument = false;
    }

    public getTooltipForDocument(document: DocumentMetadata) {
        const tooltipParts: string[] = [];

        if (this.getDocumentOrderItemForDocument(document).includedInFullDocument) {
            tooltipParts.push('Das Dokument wird in die Gesamt-PDF gedruckt und als E-Mail-Anhang beigefügt.');
        } else {
            tooltipParts.push('Das Dokument wird nicht gedruckt und nicht per E-Mail versendet.');
        }

        tooltipParts.push('Umschalt ⇧ + Klick aktiviert/deaktiviert alle sichtbaren Dokumente.');

        return tooltipParts.join('\n\n');
    }

    public isDocumentAvailable(document: DocumentMetadata): boolean {
        // If the user uploaded a manually uploaded pdf to a specific document type, it is always available.
        if (document.uploadedDocumentId) {
            return true;
        }

        switch (document.type) {
            case 'invoice':
            case 'paymentReminderLevel0':
            case 'paymentReminderLevel1':
            case 'paymentReminderLevel2':
            case 'paymentReminderLevel3':
            case 'manuallyUploadedPdf':
                //always available if uploaded
                return true;
            case 'letter':
                return !!(document.subject || document.body);
            default:
                console.error('CHECKING_AVAILABILITY_OF_UNKNOWN_DOC_TYPE', document.type);
                this.toastService.error(
                    'Unbekannter Dokumenttyp',
                    'Bitte setze dich mit dem autoiXpert-Support in Verbindung.',
                );
        }
    }

    //*****************************************************************************
    //  Document File Upload
    //****************************************************************************/
    // Event handler which listens to the mousein and mouseout event if the user drags a file
    public onFileOverDropZone(fileOver: boolean, documentHoveredByFile: DocumentMetadata): void {
        // If the user's mouse and the dragged file left the dropzone, hide the dropzone.
        if (!fileOver) {
            this.mouseoutFileuploadHover = window.setTimeout(() => {
                this.documentHoveredByFile = null;
            }, 500);
        } else {
            clearTimeout(this.mouseoutFileuploadHover);
            this.documentHoveredByFile = documentHoveredByFile;
            // When the upload drop zone for existing documents is hovered, mark the drop zone for a new document as not hovered.
            this.fileOverNewDocumentDropZone = false;
            this.onFileOverBody();
        }
    }

    public onFileOverNewDocumentDropZone(fileOver: boolean): void {
        // If the user's mouse and the dragged file left the dropzone, hide the dropzone.
        if (!fileOver) {
            this.mouseoutNewDocumentHover = window.setTimeout(() => {
                this.fileOverNewDocumentDropZone = false;
            }, 500);
        } else {
            clearTimeout(this.mouseoutNewDocumentHover);
            this.fileOverNewDocumentDropZone = true;
            // When the new document drop zone is hovered, mark the drop zone to upload existing documents as not hovered.
            this.documentHoveredByFile = null;
            this.onFileOverBody();
        }
    }

    public async onFileDrop(fileList, document: DocumentMetadata): Promise<void> {
        // Hide the drop zone as soon as content is dropped
        this.documentHoveredByFile = null;
        this.fileOverNewDocumentDropZone = false;
        this.fileIsOverBody = false;

        if (fileList.length > 1) {
            this.toastService.error(
                'Nur eine Datei erlaubt',
                'Nur eine Datei kann pro Dokument-Typ hochgeladen werden.',
            );
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Dateien können hochgeladen werden, sobald du wieder online bist.',
            );
            return;
        }

        // Validate queue
        for (const item of this.uploader.queue) {
            // Of course, we don't have to update the same document multiple times.
            if (item.isReady || item.isUploading || item.isUploaded) {
                continue;
            }
            // If the mime type is no PDF, remove the document from the queue
            if (!item._file.type.includes('pdf')) {
                console.error('The given file is not a PDF.', item);
                this.toastService.error('Nur PDF erlaubt', 'Bitte lade eine PDF-Datei hoch.');
                this.uploader.queue.splice(this.uploader.queue.indexOf(item), 1);
                return;
            }

            /**
             * We handle two different cases:
             * 1. Drop on an existing document replaces the existing document.
             *  - If it is a manually uploaded pdf, the existing file will be deleted.
             *  - The new file is uploaded with a new id and linked to the existing document.
             * 2. The document is new:
             *  - We get the title from the file name.
             *  - We create a new document.
             *  - We create a new full document part for this report.
             */
            const isNewDocument = document === null;

            // Generate a document ID for each new uploaded document
            const uploadedDocumentId = generateId();

            /**
             * Overwrite an existing document by removing its uploaded document file before uploading the new one.
             *
             * Do not remove the uploaded file of a permanent user uploaded document.
             * This is a safety measure to prevent the user from accidentally deleting a permanent document.
             * With the current implementation, the following edge case is not covered.
             * But would be complex to avoid since we cannot tell if a permanent uploaded document is/was used in another report.
             * Even if the document id is not in the teams permanent uploaded documents, the document could have been a user uploaded document before and still be used in another report.
             * 1. User uploads a manually uploaded pdf for a permanent user uploaded document
             * 2. User uploads another manually uploaded pdf for the same permanent user uploaded document
             * -> The first manually uploaded pdf is not deleted from the server.
             * To improve this, we would need to keep track about the users documents in the database. This may be added in the future to improve storage handling.
             */
            if (!isNewDocument && document.uploadedDocumentId && !document.permanentUserUploadedDocument) {
                await this.removeUploadedDocumentFromServer(document);
            }

            // If the document is not dropped on a document, create a new doucment.
            if (isNewDocument) {
                // Extract the basename (file name without file extension)
                const basename = item._file.name.substr(0, item._file.name.lastIndexOf('.'));
                document = new DocumentMetadata({
                    type: 'manuallyUploadedPdf',
                    title: basename,
                    uploadedDocumentId: uploadedDocumentId,
                    createdBy: this.user._id,
                });
                addDocumentToInvoice(
                    {
                        invoice: this.invoice,
                        team: this.team,
                        newDocument: document,
                    },
                    {
                        includedInFullDocument: !!this.userPreferences.activateDocumentsAfterUpload,
                        // Type manuallyUploadedPdf is allowed multiple times
                        allowMultiple: true,
                    },
                );
                // Refresh the view to reflect the new document.
                this.filterDocuments();
            } else {
                document.uploadedDocumentId = uploadedDocumentId;
            }

            // Save the invoice to the server before uploading the binary files. This is due to security reasons.
            // Cache would be the immediate response, therefore it's disabled.
            // Save the invoice to the server before uploading the binary files. Our server is set to block uploads whose metadata doesn't exist on the invoice.
            try {
                await this.invoiceService.put(this.invoice, { waitForServer: true });
            } catch (error) {
                console.error('Uploading ' + document?.title + ' failed.');
                this.pendingDocumentUploads.delete(document);
            }

            // Upload binary files.
            await this.upload(item, document);

            this.pendingDocumentUploads.delete(document);
            this.filterDocuments();
        }
    }

    /**
     * Triggered when a PDF file is dropped or the file upload button is clicked.
     * Parameter is a native fileList of the dropped files.
     */
    public upload(item: FileItem, document: DocumentMetadata): Promise<any> {
        const formData = new FormData();
        formData.append('uploadedDocumentId', document.uploadedDocumentId);
        formData.append('document', item._file);

        // Show a loading icon next to the document name.
        this.pendingDocumentUploads.set(document, true);
        item.isUploading = true;

        return this.httpClient
            .post<any>(AXRESTClient.marryToBaseUrl(`/invoices/${this.invoiceId}/documents/userUploads`), formData)
            .pipe(
                httpRetry({
                    delayMs: 2000,
                }),
                tap({
                    next: () => {
                        this.pendingDocumentUploads.delete(document);
                        this.uploader.queue.splice(this.uploader.queue.indexOf(item));
                    },
                    error: () => {
                        this.pendingDocumentUploads.delete(document);
                        this.uploader.queue.splice(this.uploader.queue.indexOf(item));
                    },
                }),
            )
            .toPromise();
    }

    private initializeUploader(): void {
        // Enable file upload when dragging & dropping files on the individual documents
        this.uploader = new FileUploader({
            url: `/api/v0/invoices/${this.invoiceId}/documents/userUploads`,
            authToken: `Bearer ${store.get('autoiXpertJWT')}`,
        });
    }

    /**
     * Show drop zone
     */
    @HostListener('body:dragover', ['$event'])
    public onFileOverBody() {
        clearTimeout(this.fileOverBodyTimeoutCache);

        this.fileIsOverBody = true;

        this.fileOverBodyTimeoutCache = setTimeout(() => {
            this.fileIsOverBody = false;
        }, 500);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Document File Upload
    /////////////////////////////////////////////////////////////////////////////*/

    public async lockInvoice(): Promise<void> {
        /**
         * Validate if the XRechnung parameters are correct before locking an invoice. Otherwise, the user may be unable
         * to export invoices to DATEV in the future. The DATEV export exports the invoice PDFs and during invoice PDF
         * generation, the XRechnung is validated.
         */
        if (this.invoice.isElectronicInvoiceEnabled) {
            try {
                await this.invoiceService.getXrechnung(this.invoice._id);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'XRechnung nicht geprüft',
                        body: 'Weil diese Rechnung eine E-Rechnung ist, wird das technische Format "XRechnung" beim Festschreiben geprüft. Diese Prüfung schlug aber fehl.<br><br>Bitte versuche es erneut. Falls der Fehler weiterhin besteht, wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }
        }
        this.invoice.lockedAt = moment().format();
        this.invoice.lockedBy = this.user._id;

        void this.saveInvoice();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handling Documents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Outgoing Messages
    //****************************************************************************/
    private async loadOutgoingMessages(): Promise<void> {
        this.outgoingMessages = await this.outgoingMessageService
            .find({ invoiceId: this.invoiceId, source: 'invoice' })
            .toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Outgoing Messages
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save to backend
    //****************************************************************************/

    public async saveInvoice({ waitForServer }: { waitForServer?: boolean } = {}): Promise<void> {
        try {
            await this.invoiceService.put(this.invoice, { waitForServer });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getInvoiceApiErrorHandlers(),
                    FEATHERS_ERROR: () => {
                        if (error.data.feathersErrorName === 'Conflict') {
                            return {
                                title: 'Eine Rechnung mit dieser ID existiert bereits',
                                body: 'Es wurde keine Rechnung geschrieben. Bitte wende Dich an den autoiXpert-Support',
                            };
                        } else {
                            return {
                                title: 'Ein Fehler ist aufgetreten',
                                body: 'Die Rechnung konnte nicht gespeichert werden. Bitte wende Dich an den autoiXpert-Support',
                            };
                        }
                    },
                },
                defaultHandler: {
                    title: 'Rechnung nicht gespeichert',
                    body: 'Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save to backend
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Message Templates
    //****************************************************************************/
    public showMessageTemplateSelector(): void {
        this.templateSelectorShown = true;
    }

    public hideMessageTemplateSelector(): void {
        this.templateSelectorShown = false;
    }

    public insertTemplateText(textTemplate: TextTemplate): void {
        this.useInvoiceLetterTemplate(textTemplate);
        this.saveInvoice();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Message Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Send E-Mail and Download Letter
    //****************************************************************************/
    public async sendEmail({
        email: sentEmailTemplate,
        schedule,
    }: {
        email: OutgoingEmailMessage;
        schedule?: OutgoingMessageSchedule;
    }): Promise<void> {
        this.emailTransmissionPending = true;

        /** Hold on the current value of recipient at the
         * time the user started sending the email.
         * Otherwise, switching the value while transmission is in progress, would
         * assign the email to the wrong recipient.
         */
        const selectedRecipient = this.selectedRecipient;

        /**
         * Hold on to this object if we must restore it later in case of a transmission error. This object contains the email object
         * with placeholders for recipients, no sentAt date and so on. The object "sentEmailTemplate" already contains all "rendered"
         * email recipients, a sentAt date etc., so that should not be persisted on the invoice in case of error.
         */
        const originalEmailWithRecipientPlaceholders = selectedRecipient.outgoingMessageDraft;

        // The email must be synced to the server before triggering the "send" API endpoint because the "send" API endpoint reads its contents from the invoice object.
        selectedRecipient.outgoingMessageDraft = sentEmailTemplate;
        await this.saveInvoice({ waitForServer: true });

        // Always create a outgoing message with information about the email that was scheduled, sent or failed.
        const outgoingMessage = new OutgoingMessage({
            type: 'document-email',
            source: 'invoice',
            reportId: this.report?._id,
            invoiceId: this.invoice?._id,
            recipientType: this.selectedRecipient.role,
            subject: sentEmailTemplate.subject,
            body: sentEmailTemplate.body,
            scheduledAt: schedule ? getOutgoingMessageScheduledAt(schedule).toISO() : undefined,
            areAttachmentsSeparated: sentEmailTemplate.areAttachmentsSeparated,
            attachedDocuments: sentEmailTemplate.attachedDocuments,
            multiplePdfAttachments: sentEmailTemplate.multiplePdfAttachments,
            email: {
                toRecipients: sentEmailTemplate.email?.toRecipients,
                ccRecipients: sentEmailTemplate.email?.ccRecipients,
                bccRecipients: sentEmailTemplate.email?.bccRecipients,
            },
            error: {},
        });

        try {
            if (!schedule) {
                outgoingMessage.sentAt = moment().format();
            }

            await this.outgoingMessageService.create(outgoingMessage, { waitForServer: true });

            // Ensure that email sending works (e.g., generate documents), only dry run if scheduled
            await this.emailService.triggerInvoiceEmail(outgoingMessage, { dryRun: !!schedule });

            if (!schedule) {
                outgoingMessage.deliveredAt = moment().format();

                // Only set the email as received if the email was sent successfully.
                selectedRecipient.receivedEmail = true;
            } else {
                outgoingMessage.scheduledAt = getOutgoingMessageScheduledAt(schedule).toISO();
            }

            this.outgoingMessages.push(outgoingMessage);
            await this.outgoingMessageService.put(outgoingMessage);
        } catch (error) {
            this.emailTransmissionPending = false;
            selectedRecipient.outgoingMessageDraft = originalEmailWithRecipientPlaceholders;
            this.saveInvoice();

            outgoingMessage.failedAt = moment().format();
            outgoingMessage.error = {
                code: error?.code,
                message: error?.message,
                data: error?.data,
            };
            this.outgoingMessages.push(outgoingMessage);
            await this.outgoingMessageService.put(outgoingMessage);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    MISSING_EMAIL_BODY: {
                        title: 'E-Mail-Text fehlt',
                        body: 'Bitte ändere den E-Mail-Text noch einmal, damit er gespeichert wird. Dann sollte der Versand funktionieren.',
                    },
                },
                defaultHandler: {
                    title: 'E-Mail nicht gesendet',
                    body: 'Ein Fehler ist aufgetreten',
                },
            });
        }

        this.selectedRecipient.outgoingMessageDraft = new OutgoingEmailMessage();

        // Success info
        const status = getOutgoingMessageStatus(outgoingMessage);
        switch (status) {
            case 'scheduled': {
                this.emailJustSentInfo = { label: 'E-Mail wurde vorgemerkt', icon: 'schedule' };
                break;
            }
            case 'failed': {
                this.emailJustSentInfo = { label: 'E-Mail konnte nicht gesendet werden', icon: 'error' };
                break;
            }
            default: {
                this.emailJustSentInfo = { label: 'E-Mail wurde gesendet', icon: 'check' };
                break;
            }
        }
        window.setTimeout(() => {
            this.emailJustSentInfo = false;
        }, 2000);

        // Save the invoice again to save the sentEmail in the sentDocumentEmails array
        await this.saveInvoice();
        this.emailTransmissionPending = false;
    }

    public getActiveDocuments(): DocumentMetadata[] {
        return this.filteredDocuments.filter((document) => {
            const isIncluded = !!this.getDocumentOrderItemForDocument(document)?.includedInFullDocument;
            const isAvailable = this.isDocumentAvailable(document);
            return isIncluded && isAvailable;
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Send E-Mail and Download Letter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sent E-Mail
    //****************************************************************************/

    public editAsNew(outgoingMessage: OutgoingMessage) {
        const outgoingMessageCopy = new OutgoingEmailMessage(outgoingMessage);
        outgoingMessageCopy._id = generateId();

        this.selectedRecipient.outgoingMessageDraft = outgoingMessageCopy;
        this.saveInvoice();
    }

    public stopPropagation(event: Event): void {
        event.stopPropagation();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sent E-Mail
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Websocket Events
    //****************************************************************************/
    private registerWebsocketEventHandlers() {
        this.registerPatchWebsocketEvent();
        this.registerOutgoingMessagesWebsocketEvents();
    }

    /**
     * On each websocket put event, update local records.
     * @private
     */
    private registerPatchWebsocketEvent() {
        const patchUpdatesSubscription: Subscription =
            this.invoiceService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
                next: (patchedEvent: PatchedEvent<Invoice>) => {
                    if (!this.invoice) return;

                    // If this view holds the record being updated, update it.
                    if (this.invoice._id === patchedEvent.patchedRecord._id) {
                        applyOfflineSyncPatchEventToLocalRecord({
                            localRecord: this.invoice,
                            patchedEvent,
                        });
                    }
                },
            });

        this.subscriptions.push(patchUpdatesSubscription);
    }

    private registerOutgoingMessagesWebsocketEvents() {
        const createUpdatesSubscription: Subscription =
            this.outgoingMessageService.createdFromExternalServerOrLocalBroadcast$.subscribe({
                next: (createdOutgoingMessage: OutgoingMessage) => {
                    // Only add the outgoing message if invoiceId matches
                    if (createdOutgoingMessage.invoiceId !== this.invoiceId) {
                        return;
                    }

                    if (
                        this.outgoingMessages.some(
                            (outgoingMessage) => outgoingMessage._id === createdOutgoingMessage._id,
                        )
                    ) {
                        return;
                    }
                    this.outgoingMessages.push(createdOutgoingMessage);
                },
            });
        this.subscriptions.push(createUpdatesSubscription);

        const patchUpdatesSubscription: Subscription =
            this.outgoingMessageService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
                next: (patchedEvent: PatchedEvent<OutgoingMessage>) => {
                    if (!this.outgoingMessages) return;

                    // Only update the outgoing message if invoiceId matches
                    if (patchedEvent.patchedRecord.invoiceId !== this.invoiceId) {
                        return;
                    }

                    // If this view holds the record being updated, update it.
                    const outgoingMessage = this.outgoingMessages.find(
                        (outgoingMessage) => outgoingMessage._id === patchedEvent.patchedRecord._id,
                    );
                    if (outgoingMessage) {
                        applyOfflineSyncPatchEventToLocalRecord({
                            localRecord: outgoingMessage,
                            patchedEvent,
                        });
                    }
                },
            });

        this.subscriptions.push(patchUpdatesSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Websocket Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}

interface InvoicePrintAndTransmissionQueryParams {
    createVatLetter: boolean;
}

/**
 * Checks if a European VAT ID has a valid format.
 */
export function isEuropeanVatIdValid(vatId: string): boolean {
    if (!vatId) {
        return false;
    }

    const vatIdPatterns: { [countryCode: string]: RegExp } = {
        AT: /^ATU\d{8}$/, // Austria
        BE: /^BE0\d{9}$/, // Belgium
        BG: /^BG\d{9,10}$/, // Bulgaria
        CY: /^CY\d{8}[A-Z]$/, // Cyprus
        CZ: /^CZ\d{8,10}$/, // Czech Republic
        DE: /^DE\d{9}$/, // Germany
        DK: /^DK\d{8}$/, // Denmark
        EE: /^EE\d{9}$/, // Estonia
        EL: /^EL\d{9}$/, // Greece
        ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/, // Spain
        FI: /^FI\d{8}$/, // Finland
        FR: /^FR[A-Z0-9]{2}\d{9}$/, // France
        GB: /^GB\d{9,12}$/, // Great Britain
        HR: /^HR\d{11}$/, // Croatia
        HU: /^HU\d{8}$/, // Hungary
        IE: /^IE\d{7}[A-Z]{1,2}$/, // Ireland
        IT: /^IT\d{11}$/, // Italy
        LT: /^LT\d{9,12}$/, // Lithuania
        LU: /^LU\d{8}$/, // Luxembourg
        LV: /^LV\d{11}$/, // Latvia
        MT: /^MT\d{8}$/, // Malta
        NL: /^NL[A-Z0-9]{12}$/, // Netherlands
        PL: /^PL\d{10}$/, // Poland
        PT: /^PT\d{9}$/, // Portugal
        RO: /^RO\d{2,10}$/, // Romania
        SE: /^SE\d{12}$/, // Sweden
        SI: /^SI\d{8}$/, // Slovenia
        SK: /^SK\d{10}$/, // Slovakia
        XI: /^XI\d{9,12}$/, // Northern Ireland
    };

    const countryCode = vatId.slice(0, 2).toUpperCase();
    const pattern = vatIdPatterns[countryCode];

    return pattern ? pattern.test(vatId) : false;
}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
    CorrectionItemRepairExecutionType,
    ReplacementValueCorrectionItem,
} from '@autoixpert/models/reports/replacement-value-corrections/replacement-value-correction-item';
import { Report } from '@autoixpert/models/reports/report';
import { AutocompleteWithMemory } from 'src/app/shared/components/autocomplete-with-memory/autocomplete-with-memory.component';

@Component({
    selector: 'replacement-value-correction-line',
    templateUrl: './replacement-value-correction-line.component.html',
    styleUrls: ['./replacement-value-correction-line.component.scss'],
    host: {
        // Valuation reports do not need the background color
        '[class.background-decrease]': 'report.type !== "valuation" && type === "decrease"',
        '[class.background-increase]': 'report.type !== "valuation" && type === "increase"',
    },
})
export class ReplacementValueCorrectionLineComponent {
    @Input() correctionItem: ReplacementValueCorrectionItem;
    @Input() report: Report;
    @Input() isLast: boolean;
    @Input() repairExecutionTypeShown: boolean = false;
    @Input() type: 'decrease' | 'increase';

    @Output() valuesChanged = new EventEmitter();
    @Output() triggerAddItem = new EventEmitter();
    @Output() triggerRemoveItem = new EventEmitter<ReplacementValueCorrectionItem>();

    @ViewChild('descriptionInput') descriptionInput: AutocompleteWithMemory;

    get isReportLocked() {
        return this.report.state === 'done';
    }

    /**
     * User changed the repair execution type. Save the choice and report.
     */
    protected handleRepairExecutionTypeChange(type: CorrectionItemRepairExecutionType): void {
        if (this.isReportLocked) return;

        // Deactivate the option when clicking again.
        if (this.correctionItem.repairExecutionType === type) {
            this.correctionItem.repairExecutionType = null;
        }
        // Or select the option.
        else {
            this.correctionItem.repairExecutionType = type;
        }
        this.emitChanges();
    }

    public handleTab() {
        if (this.isLast) {
            this.triggerAddItem.emit();
        }
    }

    public focus() {
        this.descriptionInput.axAutofocus = true;
    }

    public removeCorrectionItem() {
        this.triggerRemoveItem.emit(this.correctionItem);
    }

    public emitChanges() {
        this.valuesChanged.emit();
    }
}

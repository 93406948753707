import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { EMPTY, Observable, concat, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { createEmptyReport } from '@autoixpert/lib/report/create/create-empty-report';
import { changeReportType } from '@autoixpert/lib/reports/change-report-type';
import { excludeDeletedReports } from '@autoixpert/lib/reports/exclude-deleted-reports';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { Label } from '@autoixpert/models/labels/label';
import { CarEquipment } from '@autoixpert/models/reports/car-identification/car-equipment';
import { Report, ReportType } from '@autoixpert/models/reports/report';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { LiveSyncWithInMemoryCacheServiceBase } from '../libraries/database/live-sync-with-in-memory-cache.service-base';
import { get$SearchMongoQueryReports } from '../libraries/database/search-query-translators/get-$search-mongo-query-reports';
import { keysOfQuickSearchReports } from '../libraries/report-properties/keys-of-quick-search-reports';
import { FeathersQuery } from '../types/feathers-query';
import { ApiErrorService } from './api-error.service';
import { CarEquipmentService } from './car-equipment.service';
import { DocumentOrderConfigService } from './document-order-config.service';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FieldGroupConfigService } from './field-group-config.service';
import { FrontendLogService } from './frontend-log.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { reportRecordMigrations, reportServiceObjectStoreAndIndexMigrations } from './report.service-migrations';
import { SyncIssueNotificationService } from './sync-issue-notification.service';
import { ToastService } from './toast.service';
import { UserPreferencesService } from './user-preferences.service';
import { UserService } from './user.service';

@Injectable()
export class ReportService extends LiveSyncWithInMemoryCacheServiceBase<Report> {
    // private serverUpdateSubject: Subject<Report> = new Subject();
    // private debouncedServerUpdateCalls$: ConnectableObservable<PatchReportResponse>;

    /**
     * In order to prevent flickering in the report list, we cache the open reports - which the user sees first - in memory.
     *
     * This cache only helps to start from the second opening of the report list, because the first open populates the cache for the first time.
     */
    private cachedOpenReports: Report[] = [];
    private cachedDeletedReports: Report[] = [];

    /**
     * First five done reports.
     * This cache is updated each time the top 5 done reports in the reports list change
     */
    private cachedDoneReports: Report[] = [];

    constructor(
        protected toastService: ToastService,
        private userPreferences: UserPreferencesService,
        private loggedInUserService: LoggedInUserService,
        protected httpClient: HttpClient,
        private apiErrorService: ApiErrorService,
        private userService: UserService,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected feathersSocketioService: FeathersSocketioService,
        private carEquipmentService: CarEquipmentService,
        private fieldGroupConfigService: FieldGroupConfigService,
        private documentOrderConfigService: DocumentOrderConfigService,
    ) {
        super({
            serviceName: 'report',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            feathersSocketioClient: feathersSocketioService,
            recordMigrations: reportRecordMigrations,
            objectStoreAndIndexMigrations: reportServiceObjectStoreAndIndexMigrations,
            keysOfQuickSearchRecords: keysOfQuickSearchReports,
            get$SearchMongoQuery: get$SearchMongoQueryReports,
            toastNotificationService: toastService,
        });

        /**
         * When the report is updated in another tab on this device or on a completely different device, merge those changes into this tab's components.
         */
        this.patchedFromExternalServerOrLocalBroadcast$.subscribe((patchedEvent) => {
            const existingReport = [...this.cachedOpenReports, ...this.cachedDoneReports].find(
                (existingReport) => existingReport._id === patchedEvent.patchedRecord._id,
            );

            if (existingReport) {
                // Update the record in the report cache so that the report list is loaded instantly without flickering.
                applyOfflineSyncPatchEventToLocalRecord({
                    localRecord: existingReport,
                    patchedEvent,
                });
            }
        });
    }

    //*****************************************************************************
    //  New Report
    //****************************************************************************/
    /**
     * Create a fresh report object and create it both locally and on the server.
     * @returns {Observable<Report>}
     */
    public async createEmptyReport({
        reportType,
        waitForServer,
    }: { reportType?: Report['type']; waitForServer?: boolean } = {}): Promise<Report> {
        const user = this.loggedInUserService.getUser();

        // Initialize the new report with type and responsible assessor.
        const reportData: Partial<Report> = {
            type: reportType,
        };

        const responsibleAssessorId = this.userPreferences.responsibleAssessor ?? user._id;

        let responsibleAssessor: User = user;
        if (user._id !== reportData.responsibleAssessor) {
            /*
             * Optimize for performance: If the currently logged-in user is the responsible assessor, use his data from localStorage instead of querying IndexedDB
             * or the server.
             * In very rare cases, this may cause an issue: If the logged-in has an old user record of his colleague in his local IndexedDB that points to another
             * office location, this old office location will be used. Since that happens so rarely in comparison to how often a report is created, we opted for
             * fast report creation.
             */
            responsibleAssessor =
                (await this.userService.localDb.getLocal(responsibleAssessorId)) ||
                (await this.userService.get(responsibleAssessorId));
        }

        const newReport = createEmptyReport({
            reportData,
            creator: this.loggedInUserService.getUser(),
            team: this.loggedInUserService.getTeam(),
            responsibleAssessor: responsibleAssessor,
            fieldGroupConfigs: await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary(),
            customDocumentOrderConfigs:
                await this.documentOrderConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary(),
        });

        // Create empty car equipment. Once filled, that object is so large that we outsourced it to its own collection.
        this.carEquipmentService
            .create(
                new CarEquipment({
                    reportId: newReport._id,
                    createdBy: newReport.createdBy,
                    teamId: newReport.teamId,
                }),
            )
            .catch((error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Ausstattungs-Objekt nicht angelegt',
                        body: 'Damit können in diesem Gutachten keine Ausstattungen angelegt oder verändert werden. Bitte lösche das Gutachten und lege ein neues an.',
                    },
                });
            });

        // Add this to the report cache so that the report list is loaded instantly without flickering.
        this.cachedOpenReports.push(newReport);

        return this.create(newReport, { waitForServer });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END New Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Specific Filters
    //****************************************************************************/
    /**
     * Returns all reports that match a certain claimant license plate.
     * This method only queries the server, not the local cache.
     *
     * @param {string} licensePlate
     * @returns {Observable<Report[]>}
     */
    public findByLicensePlate(licensePlate: string): Observable<Report[]> {
        return this.find({
            'car.licensePlate': licensePlate,
            ...excludeDeletedReports,
        });
    }

    public findByVin(vin: string): Observable<Report[]> {
        return this.find({
            'car.vin': vin,
            ...excludeDeletedReports,
        });
    }

    public findReportsById(reportIds: string[]): Observable<Report[]> {
        if (!reportIds || !reportIds.length) {
            return of([]);
        }

        return this.find({
            _id: {
                $in: reportIds,
            },
        });
    }

    /**
     * Returns an array of all reports with the given report token.
     * This is useful to check if a report token was already assigned.
     */
    public async getByReportToken({ reportToken }: { reportToken: Report['token'] }): Promise<Report[]> {
        return await this.find({
            token: reportToken,
        }).toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Specific Filters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report State Filters
    //****************************************************************************/
    public getAllOpenReports(): Observable<Report[]> {
        const query: FeathersQuery = {
            state: 'recorded',
        };

        const cachedOpenReportsInMemory$ = this.cachedOpenReports.length ? of(this.cachedOpenReports) : EMPTY;

        const indexeddbOrRemote$ = this.find(query).pipe(
            tap({
                next: (reports) => {
                    // Make a copy so that editing the array in the report list component - which will be the array passed along here - doesn't alter the cache in this service.
                    this.cachedOpenReports = [...reports];
                },
            }),
        );

        return concat(cachedOpenReportsInMemory$, indexeddbOrRemote$);
    }

    public getAllDeletedReports(): Observable<Report[]> {
        const query: FeathersQuery = {
            state: 'deleted',
        };

        const cachedDeletedReportsInMemory$ = this.cachedDeletedReports.length ? of(this.cachedDeletedReports) : EMPTY;

        const indexeddbOrRemote$ = this.find(query).pipe(
            tap({
                next: (reports) => {
                    // Make a copy so that editing the array in the report list component - which will be the array passed along here - doesn't alter the cache in this service.
                    this.cachedDeletedReports = [...reports];
                },
            }),
        );

        return concat(cachedDeletedReportsInMemory$, indexeddbOrRemote$);
    }

    /**
     * Return done reports using the given filter and sort parameters.
     * Use a pagination token (searchAfterPaginationToken) to get the next page of results when online.
     * Use skip to get the next page of results when offline.
     */
    public async getDoneReportsFromServerOrIndexedDB({
        searchAfterPaginationToken,
        skip,
        limit = 10,
        filterAndSortParams,
        searchTerm,
    }: {
        searchAfterPaginationToken?: string;
        skip?: number;
        limit: number;
        filterAndSortParams: ReportFilterAndSortParams;
        searchTerm?: string;
    }): Promise<{
        records: Report[];
        lastPaginationToken?: string;
    }> {
        const query = transformFilterToReportQuery(filterAndSortParams);
        query.state = 'done';

        if (searchTerm && searchTerm.length >= 3) {
            query.$search = searchTerm;
        }

        query.$limit = limit;
        if (searchAfterPaginationToken) {
            query.$searchAfterPaginationToken = searchAfterPaginationToken;
        }
        if (skip) {
            query.$skip = skip;
        }

        return await this.findWithPaginationToken(query);
    }

    /**
     * Return reports for collective invoice using the given filter and sort parameters.
     * Use a pagination token (searchAfterPaginationToken) to get the next page of results when online.
     */
    public async getReportsForCollectiveInvoice({
        searchAfterPaginationToken,
        skip,
        limit = 10,
        filterAndSortParams,
        searchTerm,
    }: {
        searchAfterPaginationToken?: string;
        skip?: number;
        limit: number;
        filterAndSortParams: ReportFilterAndSortParams;
        searchTerm?: string;
    }): Promise<{
        records: Report[];
        lastPaginationToken?: string;
    }> {
        const query = transformFilterToReportQuery(filterAndSortParams);
        query['feeCalculation.isCollectiveInvoice'] = true;
        query['feeCalculation.collectiveInvoiceId'] = { $exists: false };

        if (searchTerm && searchTerm.length >= 3) {
            query.$search = searchTerm;
        }

        query.$limit = limit;
        if (searchAfterPaginationToken) {
            query.$searchAfterPaginationToken = searchAfterPaginationToken;
        }
        if (skip) {
            query.$skip = skip;
        }

        return await this.findWithPaginationToken(query);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report State Filters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Search & Date Filter
    //****************************************************************************/
    /**
     * Search locally and then on the server for a given query in a given sort order.
     * This search is used in dropdowns, e.g. select an example report for the document building block or link an invoice to an report.
     */
    public searchReportsWithoutPagination({
        $search,
        $limit,
    }: {
        $search?: string;
        $limit?: number;
    }): Observable<Report[]> {
        // If no parameters are given, don't execute the search. Otherwise, that would cause the client to request all reports from the server.
        if (!$search) {
            return EMPTY;
        }

        // Search term
        const query: FeathersQuery = {
            ...excludeDeletedReports,
            $search: $search || undefined,
            $limit: $limit || undefined,
        };

        return this.find(query);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Search & Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    public async changeReportType(report: Report, targetReportType: ReportType) {
        changeReportType({
            report,
            targetReportType,
            user: this.loggedInUserService.getUser(),
            team: this.loggedInUserService.getTeam(),
            fieldGroupConfigs: await this.fieldGroupConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary(),
            documentOrderConfigs: await this.documentOrderConfigService.getAllFromInMemoryCacheAndPopulateIfNecessary(),
        });
    }

    //*****************************************************************************
    //  Clear Report Data
    //****************************************************************************/
    /**
     * Since we implemented a local report cache, that must be cleared as well when the data store cleaner is called.
     */
    public clearDatabase() {
        this.cachedOpenReports = [];
        this.cachedDoneReports = [];
        return super.clearDatabase();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Clear Report Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Caching and Pagination for Report List
    //****************************************************************************/
    public getDoneReportsFromCache(): Report[] {
        return this.cachedDoneReports;
    }

    public updateDoneReportsCache(reports: Report[]) {
        this.cachedDoneReports = reports;
        console.log(`Cached ${reports.length} done reports.`);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Caching and Pagination for Report List
    /////////////////////////////////////////////////////////////////////////////*/
}
export interface ReportFilterAndSortParams {
    involvedUsers: User['_id'][];
    labels: Label['name'][];
    completedAfter: string;
    completedBefore: string;
    mustHaveExpertStatement: boolean;
    mustHaveRepairConfirmation: boolean;
    reportType: ReportType;
    sortBy: UserPreferences['sortReportListBy'];
    sortDescending: UserPreferences['sortReportListDescending'];
}

/**
 * Get finished reports both locally and from the server (Observable).
 *
 * Parameters:
 * involvedUsers - Array of IDs of the users involved in the report. A user is involved if he is either the creator or the responsible assessor.
 */
function transformFilterToReportQuery({
    involvedUsers,
    labels,
    completedAfter,
    completedBefore,
    mustHaveExpertStatement,
    mustHaveRepairConfirmation,
    reportType,
    sortBy,
    sortDescending,
}: ReportFilterAndSortParams): FeathersQuery {
    const query: FeathersQuery = {};

    // Sorting on the server is necessary to get the right reports when using paging
    const sortDirection = sortDescending ? -1 : 1;

    switch (sortBy) {
        case 'name':
            query.$sort = {
                'claimant.contactPerson.lastName': sortDirection,
                'claimant.contactPerson.firstName': sortDirection,
                'claimant.contactPerson.organization': sortDirection,
            };
            break;
        case 'licensePlate':
            query.$sort = {
                'car.licensePlate': sortDirection,
            };
            break;
        case 'token':
            query.$sort = {
                token: sortDirection,
            };
            break;
        case 'createdAt':
            query.$sort = {
                createdAt: sortDirection,
            };
            break;
        case 'carBrand':
            query.$sort = {
                'car.make': sortDirection,
            };
            break;
        /**
         * Since this query only queries done reports, the automaticDate filter is correctly set to "completionDate".
         */
        case 'automaticDate':
            query.$sort = {
                completionDate: sortDirection,
            };
            break;
        case 'updatedAt':
            query.$sort = {
                updatedAt: sortDirection,
            };
            break;
        case 'accidentDate':
            query.$sort = {
                'accident.date': sortDirection,
            };
            break;
        case 'orderDate':
            query.$sort = {
                orderDate: sortDirection,
            };
            break;
        case 'firstVisitDate':
            query.$sort = {
                'visits.0.date': sortDirection,
            };
            break;
    }

    if (involvedUsers?.length) {
        // query.$involvedUsers = involvedUsers;
        // Initialize $and array.
        query.$and = query.$and ?? [];

        query.$and.push({
            $or: [{ createdBy: { $in: involvedUsers } }, { responsibleAssessor: { $in: involvedUsers } }],
        });
    }

    if (completedAfter) {
        query.completionDate = {
            ...query.completionDate,
            $gt: completedAfter,
        };
    }

    if (completedBefore) {
        query.completionDate = {
            ...query.completionDate,
            $lt: completedBefore,
        };
    }

    if (mustHaveExpertStatement) {
        query['expertStatements.0'] = {
            $exists: true,
        };
    }

    if (mustHaveRepairConfirmation) {
        query.repairConfirmation = { $ne: null };
    }

    if (reportType) {
        query.type = reportType;
    }

    if (labels && labels.length > 0) {
        query['labels.name'] = {
            $in: labels,
        };
    }

    return query;
}

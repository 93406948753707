import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { Report } from '@autoixpert/models/reports/report';
import { getSelectedGarageFeeSet } from '../garages/get-selected-garage-fee-set';

/**
 * Retains the current garage fees on the report.
 *
 * In order to check whether the current garage fees differ from the ones exported to a calculation
 */
export function persistExportedGarageFeeSet(report: Report, selectedDekraFeeSet: GarageFeeSet) {
    // In case of DEKRA fee set.
    if (selectedDekraFeeSet) {
        report.damageCalculation.repair.exportedFeeSet = selectedDekraFeeSet;
        return;
    }

    const selectedFeeSet = getSelectedGarageFeeSet(report.garage);
    if (selectedFeeSet) {
        report.damageCalculation.repair.exportedFeeSet = JSON.parse(JSON.stringify(selectedFeeSet));
    } else {
        report.damageCalculation.repair.exportedFeeSet = null;
    }
}

import { DateTime } from 'luxon';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { todayIso } from '../../lib/date/iso-date';
import { IsoDate } from '../../lib/date/iso-date.types';
import { ContactPersonReference } from '../contacts/contact-person-reference';
import { CustomFieldGroup } from '../custom-fields/custom-field-group';
import { DocumentMetadata } from '../documents/document-metadata';
import { GdvOrderDetails } from '../gdv/gdv-order-details';
import { DataTypeBase } from '../indexed-db/database.types';
import { InternalNote } from '../internal-note';
import { Label } from '../labels/label';
import { ManuallySetProgressStatus } from '../report-progress/manually-set-progress-status';
import { SignableDocument } from '../signable-documents/signable-document';
import { Accident } from './accident';
import { FeeCalculation } from './assessors-fee/fee-calculation';
import { Car } from './car-identification/car';
import { DamageCalculation } from './damage-calculation/damage-calculation';
import { LeaseReturn } from './damage-calculation/lease-return';
import { Photo } from './damage-description/photo';
import { Visit } from './damage-description/visit';
import { EditedDocumentBuildingBlock } from './edited-document-building-block';
import { ExpertStatement } from './expert-statement';
import { InvoiceAudit } from './invoice-audit';
import { AuthorOfDamage } from './involved-parties/author-of-damage';
import { Claimant } from './involved-parties/claimant';
import { CommunicationRecipient } from './involved-parties/communication-recipient';
import { FactoringProvider } from './involved-parties/factoring-provider';
import { Garage } from './involved-parties/garage';
import { Insurance } from './involved-parties/insurance';
import { InvolvedParty } from './involved-parties/involved-party';
import { Valuation } from './market-value/valuation';
import { RemoteSignatureConfig } from './remote-signature-config';
import { RepairConfirmation } from './repair-confirmation';
import { ReportInvoiceNumberConfig } from './report-invoice-number-config';

export class Report implements DataTypeBase {
    constructor(template?: Partial<Report>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    _id: string;
    _externalId: string;

    type: ReportType;
    useCostEstimateHeading?: boolean; // Only used for short assessments.
    token: string;
    responsibleAssessor: string;
    officeLocationId: string;
    // On which date has the order been placed?
    orderDate: IsoDate = todayIso();
    // At what time has the order been placed? (ISO Datetime)
    orderTime?: string;
    orderPlacedByClaimant: boolean = true;
    actualCustomer?: string;
    orderingMethod?: string;
    notes: InternalNote[] = [];

    // TODO Make a real contact person from this.
    intermediary: ContactPersonReference = new ContactPersonReference();

    //*****************************************************************************
    //  Vehicle
    //****************************************************************************/
    sourceOfTechnicalData?: string;
    vinWasChecked?: boolean;
    vinFoundInPreviousReportIds?: string[]; // Persist the previous report IDs so we do not have to query them every time.
    vinFoundInLegacyReports?: string[]; // Persist the legacy report IDs so we do not have to query them every time.
    vinPreviousReportsCheckedAt?: string;
    testDriveCarriedOut?: boolean; // Testfahrt durchgeführt
    errorLogReadOut?: boolean; // Fehlerspeicher ausgelesen

    car: Car = new Car();
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Vehicle
    /////////////////////////////////////////////////////////////////////////////*/

    valuation: Valuation = new Valuation();
    damageCalculation?: DamageCalculation;

    //*****************************************************************************
    //  Involved Parties
    //****************************************************************************/
    claimant: Claimant = new Claimant();
    authorOfDamage?: AuthorOfDamage;
    ownerOfClaimantsCar: InvolvedParty = new InvolvedParty('ownerOfClaimantsCar');
    ownerOfAuthorOfDamagesCar?: InvolvedParty;
    // The seller is relevant for acquisition reports.
    seller?: CommunicationRecipient;
    garage?: Garage;
    lawyer?: CommunicationRecipient;
    insurance?: Insurance;
    leaseProvider?: CommunicationRecipient;
    factoringProvider?: FactoringProvider;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Involved Parties
    /////////////////////////////////////////////////////////////////////////////*/

    accident?: Accident;
    /**
     * Signable Documents
     * There are two types of signable documents in the UI based on:
     * - text building blocks: AutoiXpert generates the document from a text template and adds a table with header data. This document only supports a single signature.
     * - PDF templates: The user can upload a PDF document. The user determines where text will be printed into the PDF. The signer may submit a signature or check a checkbox.
     */
    signableDocuments: SignableDocument[] = [];

    feeCalculation: FeeCalculation = new FeeCalculation();
    photos: Photo[] = [];
    // Always include a visit. It's required for every report.
    visits: Visit[] = [
        new Visit({
            carAssemblyState: 'unzerlegt',
            sameStateAsInAccident: false,
        }),
    ];

    /**
     * German "Fiktive Abrechnung"
     * The claimant wishes to get the money that a repair would have cost instead of having the insurance pay for the repair directly.
     */
    isNotionalSettlement: boolean;

    invoiceAudit: InvoiceAudit;
    leaseReturn?: LeaseReturn;
    repairConfirmation?: RepairConfirmation;
    expertStatements?: ExpertStatement[] = [];

    remoteSignatureConfig?: RemoteSignatureConfig;

    /**
     * This is the list of documents that are associated with this report: valuations, the report itself, letters, the invoice etc.
     */
    documents: DocumentMetadata[] = [];
    /**
     * Contains one list for each involved party. Each list sorts and activates the documents in the report.documents array for
     * sending emails or downloading the full PDF.
     *
     * Another documentOrders array exists directly on the repair confirmation since there are two document
     * groups (report and repairConfirmation).
     */
    documentOrders: DocumentOrder[] = [];

    // Contains a list of manually entered document building blocks to overwrite the default for this report.
    editedDocumentBuildingBlocks?: EditedDocumentBuildingBlock[] = [];
    /**
     * Contains a list of fields that were edited for this report. Custom fields allow the user to add data to the report that
     * should not be added to the autoiXpert standard because it's a very custom field.
     * Examples:
     *  - Does the claimant have legal insurance?
     *  - Print the DAT valuation PDF into the vehicle valuation PDF only if a checkbox is set.
     *
     * Not all custom fields present in the team's custom field config need to be in every report.
     */
    customFieldGroups?: CustomFieldGroup[] = [];
    /**
     * The user may override a status automatic value. These overrides are stored here.
     */
    manuallySetProgressStatus: ManuallySetProgressStatus[] = [];

    invoiceExportedToAdeltafinanz?: boolean;
    invoiceExportedToKfzvs?: boolean;
    persaldoCaseNumber?: number;
    crashback24ProcessId?: number;

    //*****************************************************************************
    //  Closed Notifications
    //****************************************************************************/
    // Notifications that can be closed on a per report basis.
    transferMissingSignaturesNoticeClosed: boolean;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Closed Notifications
    /////////////////////////////////////////////////////////////////////////////*/

    audatexTaskId: string; // Audatex tasks include both calculation and valuation.
    gtmotiveEstimateId?: string; // GT Motive estimates include both VIN data and repair calculation data.

    // Label
    labels: Label[] = [];

    /**
     * Invoice Counter per Report Token
     * This is relevant in case the invoice number is derived from the report token and is increased by one for each
     * report invoice.
     *
     * *Example*
     * - report token pattern: GA-{###}
     * - invoice number pattern: {reportToken}/{#}
     * - report token: GA-001
     * - invoice numbers: GA-001/1, GA-001/2, GA-001/3, ...
     */
    invoiceNumberConfig?: ReportInvoiceNumberConfig;

    amendmentReportId: string; // ID of the report amending this report (Nachtrag).
    originalReportId: string; // ID of the original report this report is an amendment (Nachtrag) to.
    amendmentReason: string; // Reason for the amendment (Nachtrag).
    completionDate?: IsoDate;

    gdvOrderDetails?: GdvOrderDetails;

    state: ReportState = 'recorded';
    lockedAt?: string;
    lockedBy?: string;
    deletedAt?: string;
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();

    createdBy: string;
    teamId: string;

    _documentVersion: number = 0;
    _schemaVersion = 14 as const;
}

/**
 * We have 5 predefined document recipients.
 * There is an issue to allow custom recipients (AX-2599)
 */
export const reportRecipientRoles = ['claimant', 'garage', 'insurance', 'lawyer', 'leaseProvider'] as const;
export type ReportRecipientRole = (typeof reportRecipientRoles)[number];

/**
 * Wir brauchen getrennte Prozesse für Teil- & Vollkasko
 * Teilkasko: Reguliert Fremdeinwirkung (Brand, Wild, Diebstahl, Unwetter, etc.)
 * Vollkasko: Reguliert eigenverschuldete Beschädigung des Fahrzeugs (jemandem reingefahren, Leitplanke rasiert, beim Parken eine Mauer angefahren). Die Schadenart (Brand, Wild, etc.) brauchen wir beim Vollkaskoschaden nicht einzublenden.
 */
export type ReportType =
    | 'liability'
    | 'shortAssessment'
    | 'partialKasko'
    | 'fullKasko'
    | 'valuation'
    | 'oldtimerValuationSmall'
    | 'leaseReturn'
    | 'usedVehicleCheck'
    | 'invoiceAudit';

export type ReportState = 'recorded' | 'done' | 'deleted';

export const reportTypes: ReportType[] = [
    'liability',
    'shortAssessment',
    'partialKasko',
    'fullKasko',
    'valuation',
    'oldtimerValuationSmall',
    'leaseReturn',
    'usedVehicleCheck',
    'invoiceAudit',
];

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Report } from '@autoixpert/models/reports/report';
import { calculateTotalGrossFees } from '../../../libraries/fees/calculate-total-gross-fees';

@Component({
    selector: 'tr[collective-invoice-report-list-row]',
    templateUrl: './collective-invoice-report-list-row.component.html',
    styleUrls: ['./collective-invoice-report-list-row.component.scss'],
})
export class CollectiveInvoiceReportListRowComponent {
    @Input() report: Report;
    @Input() checked: boolean = false;
    @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
    protected readonly calculateTotalGrossFees = calculateTotalGrossFees;
}

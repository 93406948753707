import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { getFullName } from '@autoixpert/lib/placeholder-values/get-full-name';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'change-username-dialog',
    templateUrl: 'change-username-dialog.component.html',
    styleUrls: ['change-username-dialog.component.scss'],
})
export class ChangeUsernameDialogComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ChangeUsernameDialogComponent>,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
    ) {}

    @Input() editedUser: User;
    protected loggedInUser: User;

    protected previousUsername: string = null;
    protected newUsername: string = null;
    protected password: string = null;

    protected passwordShown: boolean = false;

    protected warning: string = null;
    protected loading: boolean = false;

    ngOnInit() {
        this.loggedInUser = this.loggedInUserService.getUser();
        this.editedUser ??= this.loggedInUser;
        this.previousUsername = this.editedUser.email;
    }

    /**
     * Save new username to the server.
     */
    public async saveNewUsername(): Promise<void> {
        // Don't allow updates if the form is incomplete.
        if (!this.password || !this.newUsername || this.previousUsername === this.newUsername) {
            return;
        }

        this.warning = null;
        this.loading = true;

        try {
            await this.userService.updateUsername(this.editedUser, this.password, this.newUsername).toPromise();

            // Update users from server, so we get the latest changes
            await this.userService.find().toPromise();
        } catch (error) {
            switch (error.code) {
                case 'PASSWORD_MISSING':
                    this.warning = 'Das Passwort darf nicht leer sein.';
                    break;
                case 'WRONG_PASSWORD':
                    this.warning = 'Das Passwort ist nicht korrekt.';
                    break;
                case 'USER_EXISTS':
                    this.warning = 'Nutzername bereits vergeben.';
                    break;
                default:
                    this.toastService.error('Neuer Benutzername konnte nicht gesetzt werden');
                    console.error('ERR_USERNAME_NOT_SET', { error });
            }
            this.loading = false;
            return;
        }

        // Close the dialog and show a success message.
        this.loading = false;
        this.dialogRef.close();
        this.toastService.success('Neuer Nutzername gespeichert.');
    }

    protected isUpdateForOtherUser(): boolean {
        return this.loggedInUser?._id !== this.editedUser?._id;
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    /**
     * The tooltip for the save button depends on an array
     * of parameters. This method sets the right tooltip text.
     */
    public getSaveButtonTooltip(): string {
        if (!this.newUsername) {
            return 'Bitte gib einen neuen Benutzernamen ein.';
        }

        if (!this.password) {
            return 'Bitte gib dein Passwort ein.';
        }

        /**
         * If the password is set for the currently logged-in user, ensure it's different from the previous one.
         *
         * If the password is set for a different user, this check is not necessary.
         */
        if (this.editedUser?._id === this.loggedInUser._id && this.previousUsername === this.newUsername) {
            return 'Der neue Benutzername muss sich vom bisherigen Nutzernamen unterscheiden.';
        }
    }

    public triggerFormOnEnterKey(event: KeyboardEvent): void {
        if (event.key === 'Enter' && event.ctrlKey) {
            this.saveNewUsername();
        }
    }

    protected readonly getFullName = getFullName;
}

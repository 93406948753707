import { DocumentMetadata, DocumentType } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { getOrderedDocuments } from './get-ordered-documents';

export function getDocumentsFromDocumentOrderByType({
    documentOrder,
    documents,
    documentType,
    uploadedDocumentId,
}: {
    documentOrder: DocumentOrder;
    documents: DocumentMetadata[];
    documentType: DocumentType;
    uploadedDocumentId: DocumentMetadata['uploadedDocumentId'];
}): DocumentMetadata[] {
    const orderedDocuments: DocumentMetadata[] = getOrderedDocuments({
        documentOrder,
        documents,
    });

    return orderedDocuments.filter((existingDocument) => {
        /**
         * There may always be multiple manually uploaded PDFs like "terms & conditions" (German "AGBs" or "Allgemeine Geschäftsbedingungen")
         * or custom honorary agreements.
         *
         * Instead of by type alone, manually uploaded PDFs are identified by type and their uploaded document ID. Only the
         * combination makes them unique.
         */
        if (documentType === 'manuallyUploadedPdf' && existingDocument.type === 'manuallyUploadedPdf') {
            return existingDocument.uploadedDocumentId === uploadedDocumentId;
        }

        /**
         * Custom documents are identified by their custom document ID. Only the combination makes them unique.
         */
        if (documentType === 'customDocument' && existingDocument.type === 'customDocument') {
            return existingDocument.customDocumentOrderConfigId === uploadedDocumentId;
        }

        return existingDocument.type === documentType;
    });
}

import { Photo } from '@autoixpert/models/reports/damage-description/photo';

export function isPhotoEmpty(version: keyof Photo['versions'], photo: Photo): boolean {
    if (
        !photo.versions[version].fabricJsInformation ||
        JSON.parse(JSON.stringify(photo.versions[version].fabricJsInformation)) === '{}'
    ) {
        return true;
    }

    let areFiltersSet = false;
    for (const axFilterName in photo.versions[version].fabricJsInformation.axFilters) {
        if (photo.versions[version].fabricJsInformation.axFilters[axFilterName].value !== 0) {
            areFiltersSet = true;
            break;
        }
    }

    let areShapesPresent = false;
    for (const fabricObject of photo.versions[version].fabricJsInformation.objects) {
        // If this is the photo, don't count this. The photo is always present and does not count as a shape.
        // Exclude the watermark, too. That does not count since that might always be present. Shapes should be copied anyway.
        if (
            fabricObject.data &&
            (fabricObject.data.axType === 'photo' ||
                ['watermarkImage', 'watermarkText'].includes(fabricObject.data.axType))
        ) {
            continue;
        }

        // If this is an object that is not a photo, it must be a shape.
        areShapesPresent = true;
        break;
    }

    // Return true if neither filters nor shapes are set.
    return !areFiltersSet && !areShapesPresent;
}

import { Component } from '@angular/core';
import { isQapterixpert } from '@autoixpert/lib/is-qapterixpert';
import { isInsideOfficeHours } from '@autoixpert/lib/support/is-inside-office-hours';
import { User } from '@autoixpert/models/user/user';
import { getAnydeskLink } from '../../shared/libraries/device-detection/get-anydesk-link';
import { getProductName } from '../../shared/libraries/get-product-name';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';

@Component({
    selector: 'helpcenter',
    templateUrl: 'helpcenter.component.html',
    styleUrls: ['helpcenter.component.scss'],
})
export class HelpcenterComponent {
    constructor(
        private screenTitleService: ScreenTitleService,
        private loggedInUserSerivce: LoggedInUserService,
    ) {}

    public user: User;
    public productName: 'autoiXpert' | 'Qapter-iXpert';

    ngOnInit() {
        this.screenTitleService.setScreenTitle({ screenTitle: 'Hilfe & Support' });
        this.user = this.loggedInUserSerivce.getUser();

        this.productName = getProductName();
    }

    public isQapterixpert() {
        return isQapterixpert();
    }

    public getAnydeskLink(): string {
        return getAnydeskLink();
    }

    protected readonly isInsideOfficeHours = isInsideOfficeHours;
}

import { get } from 'lodash-es';
import { isDocumentBuildingBlockConditionGroup } from '@autoixpert/lib/document-building-blocks/is-document-building-block-condition-group';
import { PlaceholderValueTree } from '@autoixpert/lib/placeholder-values/get-placeholder-value-tree';
import { DocumentBuildingBlockCondition } from '@autoixpert/models/documents/document-building-block-condition';
import { DocumentBuildingBlockConditionGroup } from '@autoixpert/models/documents/document-building-block-condition-group';

/**
 * Check if a condition or condition group is allowed to be saved.
 * This utility function is currently used by multiple consumers:
 *  - DocumentBuildingBlockEditorComponent
 *  - DocumentBuildingBlockVariantEditorComponent
 *
 * @param condition Condition or condition group to check
 * @param placeholderValueTree Placeholder value tree
 * @returns True if the condition is allowed to be saved
 */
export function isDocumentBuildingBlockSavingConditionAllowed(
    condition: DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup,
    placeholderValueTree: PlaceholderValueTree,
): boolean {
    // Condition group
    if (isDocumentBuildingBlockConditionGroup(condition)) {
        return (condition.conditions ?? []).every((condition) =>
            isDocumentBuildingBlockSavingConditionAllowed(condition, placeholderValueTree),
        );
    }

    // Condition
    if (!condition.propertyPath || !condition.operator || !get(placeholderValueTree, condition.propertyPath)) {
        return false;
    }

    switch (condition.operator) {
        case 'truthy':
        case 'falsy':
        case 'empty':
        case 'unknown':
        case 'notEmpty':
            // These operators don't require a comparison value
            return true;
        default:
            // all others do require one. It must not be empty.
            return (
                condition.comparisonValue !== null &&
                condition.comparisonValue !== '' &&
                typeof condition.comparisonValue !== 'undefined'
            );
    }
}

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { NetworkStatusService } from '../services/network-status.service';

/**
 * If a 404 Not Found error occurs and the client is offline, throw a specific "You're offline" error.
 */
@Injectable()
export class OfflineErrorInterceptor implements HttpInterceptor {
    constructor(private networkStatusService: NetworkStatusService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        /**
         * Only check if a request failed due to missing connectivity to our own servers. Requests to third party sites may be ignored because they don't indicate that our own servers are offline.
         *
         * Don't handle requests to the '/echo/' endpoint of the autoiXpert backend since that is part of the network detection. If that failed, another network detection would be triggered --> infinity loop.
         */
        if (
            (request.url.startsWith('/') || request.url.includes(window.location.host)) &&
            !request.url.includes('/echo/')
        ) {
            return next.handle(request).pipe(
                /**
                 * Since this interceptor runs after the "ensure AxError" interceptor, the error is always an AxError instead of a response.
                 */
                catchError((axError: AxError) => {
                    if (axError.code === 'GENERIC_API_ERROR') {
                        /**
                         * An HTTP status code === 0 is returned if the device has no Internet connection.
                         *
                         * HTTP status code 504 "Gateway Timeout (from service worker)" means the service worker cannot reach the autoiXpert servers. That's usually the case when
                         * the client just went offline by disconnecting his Wi-Fi network. For details, read https://angular.io/guide/service-worker-devops
                         */
                        if (axError.data?.httpStatusCode === 0 || axError.data?.httpStatusCode === 504) {
                            axError = new AxError({
                                code: 'CLIENT_IS_OFFLINE',
                                message:
                                    'This client is offline. Please make sure the internet connection is working, then try the request again.',
                                data: {
                                    request,
                                },
                                error: axError,
                            });

                            this.networkStatusService.detectNetworkStatus();
                        } else if (axError.data?.responseBody?.code === 'BAD_GATEWAY') {
                            /**
                             * An HTTP 502 Bad Gateway error is returned from the nginx if the backend servers are down.
                             */
                            axError = new AxError({
                                code: 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN',
                                message:
                                    'This client can reach the autoiXpert load balancer but the load balancer cannot reach the autoiXpert backend servers.',
                                data: {
                                    request,
                                },
                                error: axError,
                            });

                            this.networkStatusService.detectNetworkStatus();
                        }
                    }

                    // In all other cases, re-throw the error.
                    return throwError(axError);
                }),
            );
        }

        // Proceed with other interceptors.
        return next.handle(request);
    }
}

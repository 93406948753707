import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import moment from 'moment';
import { detectBrowser } from '@autoixpert/lib/browser/detect-browser';
import { isTouchOnly } from '../../libraries/is-small-screen';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'time-input',
    templateUrl: 'time-input.component.html',
    styleUrls: ['time-input.scss'],
})
export class TimeInputComponent {
    constructor(private toastService: ToastService) {}

    private _date: moment.Moment;
    private _formatString: string = 'HH:mm';
    public formattedTimeForChild: string; // Property to bind to the child mat-input

    // Only fallback to text input on Safari desktop, as the time input has the "12:30" placeholder bug.
    protected inputType = detectBrowser().browser === 'Safari' && !isTouchOnly() ? 'text' : 'time';

    @ViewChild('timeInput') timeInput?: ElementRef<HTMLInputElement>;
    @Output() timeChange: EventEmitter<string> = new EventEmitter();
    @Input() placeholder: string = 'Uhrzeit';
    @Input() disabled: boolean = false;

    // If the incoming time changes, format it as ISO and pass it to the template binding
    @Input() set time(valueFromParent: string) {
        if (valueFromParent) {
            // Parse the input string
            this._date = this._parseRawDate(valueFromParent);

            // Only set template binding if date is valid
            if (this._date.isValid()) {
                this.formattedTimeForChild = this._date.format(this._formatString);
            } else {
                this.formattedTimeForChild = '';
            }
        } else {
            this.formattedTimeForChild = '';
        }
    }

    /**
     * Parse the value that the user entered into the template input and emit a change event containing the parsed value in ISO format.
     * @param inputValue
     */
    public parseInputValueFromTemplate(inputValue: string) {
        if (inputValue) {
            const isoDate = this._parseRawDate(inputValue);

            // make sure there is a proper internal date
            if (!this._date || !this._date.isValid()) {
                this._date = moment();
            }

            // Check whether parsing successful and the variable contains something.
            if (isoDate.isValid()) {
                // assign the time to the date assigned to the component
                this._date.hours(isoDate.hours());
                this._date.minutes(isoDate.minutes());
                this._date.seconds(isoDate.seconds());

                // Format the value from the template correctly and pass it back to the template binding. Then, emit the ISO string to the parent component.
                this.formattedTimeForChild = this._date.format(this._formatString);
                this.timeChange.emit(this._date.format());
            } else {
                // Emit an empty date to the parent component
                this.timeChange.emit('');
            }
        } else {
            this.timeChange.emit('');
        }
    }

    /**
     * Parse the raw date with these centrally determined formats. Returns an ISO-formatted string if possible. Return void if the format was wrong.
     * @param rawDate
     * @returns {moment.Moment}
     * @private
     */
    private _parseRawDate(rawDate: string): moment.Moment {
        // Return void if the rawDate is empty. Else, go on.
        const isoDate = moment(rawDate, ['HH:mm', 'H:mm', 'Hmm', 'H', moment.ISO_8601]);

        if (isoDate.isValid()) {
            return isoDate;
        } else {
            if (rawDate.toLowerCase() === 'j') {
                // Shortcut j -> inserts current time
                return moment();
            }

            this.toastService.warn('Uhrzeit ungültig', 'Bitte gib sie im Format HH:mm an.');
            return isoDate;
        }
    }

    focus() {
        this.timeInput?.nativeElement.focus();
    }
}

<main id="invoice-number-journal-container">
    <!--============================================-->
    <!-- Stub -->
    <!--============================================-->
    <section id="invoice-number-journal-section" class="card preference-section-container">
        <!-- Search, Sort and Filter -->
        <div id="invoice-number-journal-header">
            <!-- Date filters -->
            <div class="invoice-number-journal-header-filter-and-sort">
                <!--============================================-->
                <!-- Sorting -->
                <!--============================================-->
                <div id="sort-select-and-direction-container">
                    <div
                        id="sort-journal-entries-by-container"
                        class="ax-header-select"
                        matTooltip="Sortierung"
                        [matMenuTriggerFor]="sortMenu"
                        [ngSwitch]="userPreferences.invoiceNumberJournalSortBy">
                        <span *ngSwitchCase="'invoiceNumber'">Nach Rechnungsnummer</span>
                        <span *ngSwitchCase="'documentType'">Nach Rechnungstyp</span>
                        <span *ngSwitchCase="'invoiceNumberCounter'">Nach Zählerkreis</span>
                        <span *ngSwitchCase="'journalEntryType'">Nach Erzeugung</span>
                        <span *ngSwitchCase="'createdAt'">Nach Datum</span>
                        <span *ngSwitchCase="'createdBy'">Nach Ersteller</span>
                        <div class="ax-header-select-arrow"></div>
                    </div>
                    <mat-menu #sortMenu="matMenu">
                        <a mat-menu-item (click)="selectSortStrategy('invoiceNumber')">Nach Rechnungsnummer</a>
                        <a mat-menu-item (click)="selectSortStrategy('createdAt')">Nach Datum</a>
                    </mat-menu>
                    <mat-icon
                        id="sort-direction-indicator"
                        class="toolbar-icon"
                        [ngClass]="{ flipped: userPreferences.sortInvoiceNumberJournalDescending }"
                        (click)="toggleSortDirection()"
                        [matTooltip]="
                            userPreferences.sortInvoiceNumberJournalDescending
                                ? 'Sortierung absteigend'
                                : 'Sortierung aufsteigend'
                        ">
                        arrow_upward
                    </mat-icon>
                </div>
                <!--============================================-->
                <!-- END Sorting -->
                <!--============================================-->

                <!--============================================-->
                <!-- Filtering -->
                <!--============================================-->
                <div
                    id="quick-filter-select-container"
                    class="ax-header-select"
                    matTooltip="Schnellfilter"
                    [matMenuTriggerFor]="quickFilterMenu"
                    [ngSwitch]="userPreferences.invoiceNumberJournalQuickFilter">
                    <span *ngSwitchDefault>Kein Filter</span>

                    <span class="ax-header-select-label" *ngSwitchCase="'createdBy'">
                        <ng-container *ngIf="userPreferences.invoiceNumberJournal_usersForFilter.length === 1">
                            {{ getUserFullName(userPreferences.invoiceNumberJournal_usersForFilter[0]) }}
                        </ng-container>
                        <ng-container *ngIf="userPreferences.invoiceNumberJournal_usersForFilter.length > 1">
                            {{ userPreferences.invoiceNumberJournal_usersForFilter.length }} Nutzer
                        </ng-container>
                    </span>

                    <span
                        *ngSwitchCase="'documentType'"
                        [ngSwitch]="userPreferences.invoiceNumberJournal_documentTypeForFilter">
                        <span class="ax-header-select-label" *ngSwitchCase="'report'">Gutachten</span>
                        <span class="ax-header-select-label" *ngSwitchCase="'expertStatement'">Stellungnahme</span>
                        <span class="ax-header-select-label" *ngSwitchCase="'invoice'">freie Rechnung</span>
                        <span class="ax-header-select-label" *ngSwitchCase="'repairConfirmation'">
                            Reparaturbestätigung
                        </span>
                    </span>

                    <span *ngSwitchCase="'hasNotes'" class="ax-header-select-label" matTooltip="Einträge mit Notizen">
                        Hat Notizen
                    </span>

                    <div class="ax-header-select-arrow"></div>
                </div>
                <mat-menu #quickFilterMenu="matMenu">
                    <a mat-menu-item class="no-filter-menu-item" (click)="resetQuickFilter()">Kein Filter</a>
                    <a mat-menu-item [matMenuTriggerFor]="createdByFilter">Erstellt von</a>
                    <a
                        mat-menu-item
                        [matMenuTriggerFor]="documentTypeFilter"
                        matTooltip="Nur bestimmte Gutachtentypen, z. B. Haftpflicht oder Bewertung">
                        Rechnungstyp
                    </a>
                    <!-- Deactivated for now as Atlas Search does not support this filter yet.
                    <a mat-menu-item (click)="selectQuickFilter('hasNotes')">Hat Notizen</a>
                    -->
                </mat-menu>

                <mat-menu #documentTypeFilter>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilterDocumentType('report'); selectQuickFilter('documentType')">
                        Gutachten
                    </a>
                    <a
                        mat-menu-item
                        (click)="selectQuickFilterDocumentType('expertStatement'); selectQuickFilter('documentType')">
                        Stellungnahme
                    </a>

                    <a
                        mat-menu-item
                        (click)="selectQuickFilterDocumentType('invoice'); selectQuickFilter('documentType')">
                        freie Rechnung
                    </a>

                    <a
                        mat-menu-item
                        (click)="
                            selectQuickFilterDocumentType('repairConfirmation'); selectQuickFilter('documentType')
                        ">
                        Reparaturbestätigung
                    </a>
                </mat-menu>

                <!--********** Created by **********-->
                <mat-menu #createdByFilter>
                    <a
                        mat-menu-item
                        class="user-filter__user-menu-item"
                        *ngFor="let user of users"
                        (click)="toggleSelectUserForFilter(user._id)">
                        <!--********** Profile Picture **********-->
                        <user-avatar class="user-filter-profile-picture" [userId]="user._id"></user-avatar>

                        <!--********** Name **********-->
                        <div class="user-filter__user-name">
                            {{ getUserFullName(user._id) }}
                        </div>

                        <!--********** Multiselect **********-->
                        <!-- Stop Mouseup event to prevent closing the menu after selecting an user. -->
                        <mat-checkbox
                            [checked]="userPreferences.invoiceNumberJournal_usersForFilter.includes(user._id)"
                            (change)="toggleSelectUserForFilter(user._id)"
                            (click)="$event.stopPropagation()"
                            matTooltip="Zeige die Einträge dieses Nutzers an"></mat-checkbox>
                    </a>
                </mat-menu>
                <!--============================================-->
                <!-- END Filtering -->
                <!--============================================-->
            </div>

            <!--============================================-->
            <!-- Search Field -->
            <!--============================================-->
            <div id="search-field-container">
                <mat-form-field
                    id="search-field"
                    class="search-field mat-block"
                    floatLabel="never"
                    [matTooltip]="
                        'Diese Daten werden durchsucht: \n• Rechnungsnummer\n\nSuchworte müssen mindestens drei Zeichen lang sein.'
                    ">
                    <input
                        matInput
                        [(ngModel)]="searchTerm"
                        placeholder="Suchen"
                        (ngModelChange)="updateSearchTerm$()" />
                    <mat-icon matPrefix>search</mat-icon>
                    <mat-icon
                        matSuffix
                        id="date-limit-toggle-icon"
                        class="input-toolbar-icon"
                        *ngIf="!dateLimitInputsShown"
                        (click)="toggleDateLimitInputs()"
                        matTooltip="Erstellungsdatum eingrenzen">
                        today
                    </mat-icon>
                </mat-form-field>
                <div id="date-limit-inputs-container" *ngIf="dateLimitInputsShown">
                    <date-range-picker
                        [(dateFrom)]="dateLimitFrom"
                        [(dateTo)]="dateLimitTo"
                        (dateRangeChange)="
                            updateDateLimitFrom($event.dateFrom);
                            updateDateLimitTo($event.dateTo);
                            loadInvoiceNumberJournalEntries()
                        "
                        [floatPlaceholder]="'never'"></date-range-picker>

                    <mat-icon
                        class="toolbar-icon"
                        (click)="toggleDateLimitInputs()"
                        matTooltip="Datumsgrenzen entfernen">
                        close
                    </mat-icon>
                </div>
            </div>
            <!--============================================-->
            <!-- END Search Field -->
            <!--============================================-->

            <!-- Settings -->
            <div></div>
            <mat-icon class="toolbar-icon settings-icon" [matMenuTriggerFor]="settingsMenu">more_vert</mat-icon>

            <mat-menu #settingsMenu="matMenu">
                <a mat-menu-item class="menu-item-with-icon" (click)="exportInvoiceNumberJournal()">
                    <mat-icon>download</mat-icon>
                    Einträge exportieren
                </a>
            </mat-menu>
        </div>

        <!-- Journal entries table -->
        <div id="invoice-number-journal-entries-table">
            <!-- Table headers row -->
            <div class="invoice-number-journal-entries-table-headers-row">
                <div class="invoice-number-cell table-cell">Rechnungsnummer</div>
                <div class="journal-entry-reference-cell table-cell">Rechnungstyp</div>
                <div class="invoice-number-counter-cell table-cell">Zählerkreis</div>
                <div class="journaly-entry-activity-cell table-cell">Erzeugt</div>
                <div class="journaly-entry-created-at-cell table-cell">Datum</div>
                <div class="journaly-entry-notes-cell table-cell"></div>
                <div class="journaly-entry-created-by-cell table-cell">Ersteller</div>
                <div class="settings-cell"></div>
            </div>

            <!-- Journal entry rows -->
            <ng-container *ngFor="let journalEntry of invoiceNumberJournalEntries; trackBy: trackById">
                <div class="invoice-number-journal-entries-table-row">
                    <!-- Invoice number counter event description -->
                    <div
                        *ngIf="getInvoiceNumberCounterEventInfo(journalEntry) as info"
                        class="journal-entry-counter-event-cell table-cell">
                        <div class="journal-entry-event-marker" [matTooltip]="'Änderung einer Einstellung'"></div>
                        <span>{{ info.description }}</span>

                        <svg
                            *ngIf="info.details"
                            [matTooltip]="info.details"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="#5f6368">
                            <path
                                d="M780-160 640-300l57-56 43 43v-487h80v488l44-44 56 56-140 140Zm-660-80v-163l295-294q24-24 57.5-23t56.5 25l48 50q23 23 22.5 56T576-533L283-240H120Zm80-80h50l162-162-25-25-25-25-162 162v50Zm269-219-50-50 50 50Z" />
                        </svg>
                    </div>

                    <!-- Invoice number -->
                    <div
                        *ngIf="journalEntry.invoiceNumber || journalEntry.previousInvoiceNumber"
                        class="invoice-number-cell table-cell">
                        <!-- ********** Invoice number created **********-->
                        <ng-container *ngIf="journalEntry.invoiceNumber && !journalEntry.previousInvoiceNumber">
                            <span>{{ journalEntry.invoiceNumber }}</span>

                            <!-- Amendment report creation -->
                            <div *ngIf="journalEntry.entryType === 'invoiceNumberGeneratedOnAmendmentReportCreation'">
                                <mat-icon
                                    [matTooltip]="'Diese Rechnungsnummer wurde bei der Anlage des Nachtrags erzeugt.'">
                                    note_add
                                </mat-icon>
                            </div>
                        </ng-container>

                        <!-- ********** Invoice number deleted **********-->
                        <ng-container *ngIf="!journalEntry.invoiceNumber && journalEntry.previousInvoiceNumber">
                            <span>{{ journalEntry.previousInvoiceNumber }}</span>
                            <mat-icon [matTooltip]="'Diese Rechnungsnummer wurde gelöscht.'">delete</mat-icon>
                        </ng-container>

                        <!-- ********** Invoice number changed **********-->
                        <ng-container *ngIf="journalEntry.invoiceNumber && journalEntry.previousInvoiceNumber">
                            <!-- New invoice number-->
                            <span>{{ journalEntry.invoiceNumber }}</span>

                            <!-- Old invoice number -->
                            <div
                                *ngIf="journalEntry.previousInvoiceNumber"
                                [matTooltip]="
                                    'Diese Rechnungsnummer überschrieb die Rechnungsnummer ' +
                                    journalEntry.previousInvoiceNumber +
                                    '.'
                                ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 -960 960 960"
                                    width="24px"
                                    fill="#5f6368">
                                    <path
                                        d="M780-160 640-300l57-56 43 43v-487h80v488l44-44 56 56-140 140Zm-660-80v-163l295-294q24-24 57.5-23t56.5 25l48 50q23 23 22.5 56T576-533L283-240H120Zm80-80h50l162-162-25-25-25-25-162 162v50Zm269-219-50-50 50 50Z" />
                                </svg>
                            </div>
                        </ng-container>
                    </div>

                    <!-- Journal entry reference -->
                    <div
                        *ngIf="getJournalEntryReference(journalEntry) as journalEntryReference"
                        class="journal-entry-reference-cell table-cell">
                        <span>{{ journalEntryReference.label }}</span>

                        <a
                            *ngIf="!journalEntryReference.deletedAt && !journalEntryReference.repairConfirmationDeleted"
                            [href]="journalEntryReference.url"
                            target="_blank">
                            <mat-icon class="toolbar-icon open-in-new-icon">open_in_new</mat-icon>
                        </a>

                        <span
                            class="journal-entry-highlight"
                            [matTooltip]="
                                'Gelöscht am ' +
                                (journalEntryReference.deletedAt | moment: 'DD.MM.YYYY - HH:mm') +
                                ' Uhr'
                            "
                            *ngIf="journalEntryReference.deletedAt">
                            &nbsp;(gelöscht)
                        </span>

                        <span
                            class="journal-entry-highlight"
                            [matTooltip]="'Reparaturbestätigung wurde gelöscht.'"
                            *ngIf="journalEntryReference.repairConfirmationDeleted">
                            &nbsp;(gelöscht)
                        </span>
                    </div>

                    <!-- Invoice number counter-->
                    <div
                        class="invoice-number-counter-cell table-cell"
                        *ngIf="getJournalEntryInvoiceNumberOrReportCounter(journalEntry) as counter">
                        <mat-icon *ngIf="counter.type === 'invoice'">euro_symbol</mat-icon>
                        <mat-icon *ngIf="counter.type === 'report'">assignment</mat-icon>
                        <mat-icon *ngIf="!counter.type">block</mat-icon>

                        {{ counter.label }}
                    </div>

                    <div class="journaly-entry-activity-cell table-cell">
                        <ng-container [ngSwitch]="journalEntry.entryType">
                            <!-- invoiceNumberGeneratedOnCreation -->
                            <ng-container *ngSwitchCase="'invoiceNumberGeneratedOnCreation'">
                                <mat-icon class="activity-icon">start</mat-icon>
                                bei Anlage
                            </ng-container>

                            <!-- invoiceNumberGeneratedOnAmendmentReportCreation -->
                            <ng-container *ngSwitchCase="'invoiceNumberGeneratedOnAmendmentReportCreation'">
                                <mat-icon class="activity-icon">start</mat-icon>
                                bei Anlage
                            </ng-container>

                            <!-- invoiceNumberGeneratedOnAmendmentReportCreation -->
                            <ng-container *ngSwitchCase="'invoiceNumberGeneratedOnInvoiceCancellation'">
                                <mat-icon class="activity-icon">start</mat-icon>
                                bei Anlage
                            </ng-container>

                            <!-- invoiceNumberGeneratedManually -->
                            <ng-container *ngSwitchCase="'invoiceNumberGeneratedManually'">
                                <mat-icon class="activity-icon">download</mat-icon>
                                von Nutzer generiert
                            </ng-container>

                            <!-- invoiceOrReportCounterChangedByResetCycle -->
                            <ng-container *ngSwitchCase="'invoiceOrReportCounterChangedByResetCycle'">
                                <mat-icon class="activity-icon">settings_backup_restore</mat-icon>
                                turnusmäßig
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                                <mat-icon class="journal-entry-highlight">back_hand</mat-icon>
                                <span class="journal-entry-highlight">manuell gesetzt</span>
                            </ng-container>
                        </ng-container>
                    </div>

                    <!-- Created at -->
                    <div class="journaly-entry-created-at-cell table-cell">
                        {{ journalEntry.createdAt | moment: 'DD.MM.YYYY - HH:mm' }}
                    </div>

                    <!-- Notes -->
                    <div class="journaly-entry-notes-cell">
                        <mat-icon
                            [id]="'notes-icon-' + journalEntry._id"
                            class="toolbar-icon"
                            *ngIf="journalEntry.notes?.length || notesIconManuallyShown[journalEntry._id]"
                            internal-notes-panel-anchor
                            #notesPanelAnchor="internalNotesPanelAnchor"
                            [notes]="journalEntry.notes"
                            matTooltip="Notizen"
                            (change)="saveInvoiceNumberJournalEntry(journalEntry)"
                            (click)="showNotesManually(journalEntry)"
                            (panelClose)="hideIconForEmptyNotes(journalEntry)">
                            notes
                        </mat-icon>
                    </div>

                    <!-- Created by -->
                    <div class="journaly-entry-created-by-cell table-cell">
                        <user-avatar [size]="24" [userId]="journalEntry.createdBy" [showTooltip]="true"></user-avatar>
                    </div>

                    <!-- Settings -->
                    <div class="settings-cell">
                        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="journalEntryMenu">more_vert</mat-icon>
                    </div>

                    <!-- Journal entry menu -->
                    <mat-menu #journalEntryMenu="matMenu">
                        <a mat-menu-item (click)="showNotesManually(journalEntry)">
                            <mat-icon>notes</mat-icon>
                            <span>Notizen</span>
                        </a>
                    </mat-menu>
                </div>
            </ng-container>

            <!-- Empty table placeholder -->
            <div *ngIf="!invoiceNumberJournalEntries.length && initialized">
                <div class="no-journal-entries-placeholder">
                    <mat-icon class="outlined">info</mat-icon>
                    <div>Keine Einträge gefunden</div>
                </div>
            </div>
        </div>
    </section>

    <!-- Load more button   -->
    <div class="load-more-button-container" *ngIf="initialized">
        <button
            class="show-more-button"
            [ngClass]="{ disabled: allJournalEntriesLoadedWithCurrentFilters }"
            [disabled]="allJournalEntriesLoadedWithCurrentFilters"
            (click)="loadInvoiceNumberJournalEntries({ loadMore: true })">
            Mehr anzeigen
        </button>
    </div>
    <!--============================================-->
    <!-- END Stub -->
    <!--============================================-->
</main>

export function isSmallScreen(
    belowBreakpoint: 'small' | 'medium' | 'large' | 'extraLarge' | 'extraExtraLarge' = 'extraLarge',
): boolean {
    let pixels: number;

    // Breakpoints are taken from the responsive-breakpoints.scss
    switch (belowBreakpoint) {
        case 'small':
            pixels = 576;
            break;
        case 'medium':
            pixels = 768;
            break;
        case 'large':
            pixels = 992;
            break;
        case 'extraLarge':
            pixels = 1200;
            break;
        case 'extraExtraLarge':
            pixels = 1400;
            break;
    }
    return window.matchMedia(`(max-width: ${pixels}px)`).matches;
}

/**
 * Determine whether the current device is a touch device.
 * This means that the device has a touch screen and no mouse.
 * This cannot be determined with 100% certainty.
 * Try leaning on not touch if unsure.
 * The check is based on the following sources:
 *  - window.matchMedia('(pointer: coarse)').matches
 *  - window.ontouchstart
 *  - navigator.maxTouchPoints
 *
 * https://stackoverflow.com/questions/7838680/detecting-that-the-browser-has-no-mouse-and-is-touch-only
 * https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
 */
export function isTouchOnly(): boolean {
    try {
        if (typeof window === 'undefined') return false;

        // Check matchMedia
        let pointerCoarse = false;
        if (typeof window?.matchMedia === 'function') {
            pointerCoarse = !!window?.matchMedia('(pointer: coarse)')?.matches;
        }

        // Check ontouchstart
        const touchStart = typeof window?.ontouchstart !== 'undefined';

        // Check maxTouchPoints
        let maxTouchPoints = 0;
        if (typeof navigator?.maxTouchPoints === 'number') {
            maxTouchPoints = navigator?.maxTouchPoints;
        }

        return pointerCoarse && touchStart && maxTouchPoints > 0;
    } catch (err) {
        console.warn('isTouchOnly errored: ' + err);
    }

    return false;
}

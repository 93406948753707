import { DocumentType, ExternalDocumentType, RenderedDocumentType } from '../../models/documents/document-metadata';
import { Report, ReportType } from '../../models/reports/report';

export const documentTypeGermanShortVersionDictionary = {
    liability: 'Haftpflicht',
    valuation: 'Bewertung',
    manualCalculation: 'Man. Kalkulation',
    diminishedValueProtocol: 'Minderwert',
    declarationOfAssignment: 'Abtretung',
    revocationInstruction: 'Widerruf',
    consentDataProtection: 'Datenschutz',
    powerOfAttorney: 'Vollmacht',
};

export const documentTypeGermanDictionary: { [key in DocumentType | ReportType]: string } = {
    afzzertCertificate: 'Altfahrzeugzertifikat',
    audatexMarketAnalysis: 'Audatex Bewertung',
    autoonlineResidualValueBidList: 'AUTOonline Gebotsliste',
    carcasionResidualValueBidList: 'car.casion Gebotsliste',
    cartvMarketAnalysis: 'CARTV Marktwertanalyse',
    cartvResidualValueBidList: 'CARTV Gebotsliste',
    manuallyUploadedPdf: 'Eigens hochgeladenes PDF-Dokument',
    customDocument: 'Eigenes Dokument',
    classicanalyticsValuation: 'Classic Analytics Bewertung',
    consentDataProtection: 'Einwilligung Datenschutz',
    customResidualValueBidList: 'Eigene Gebotsliste',
    datDamageCalculation: 'DAT Kalkulation',
    datMarketAnalysis: 'DAT Bewertung',
    datValuationProtocol: 'DAT Bewertungsprotokoll',
    declarationOfAssignment: 'Abtretung/Auftrag',
    diminishedValueProtocol: 'Minderwertprotokoll',
    expertStatement: 'Stellungnahme',
    fullKasko: 'Vollkasko',
    garageInformation: 'Werkstattinformation',
    invoice: 'Rechnung',
    invoiceAudit: 'Rechnungsprüfung',
    leaseReturn: 'Zustandsbericht',
    letter: 'Anschreiben',
    liability: 'Haftpflichtschaden',
    manualCalculation: 'Manuelle Kalkulation',
    oldtimerValuationSmall: 'Oldtimer (klein)',
    partialKasko: 'Teilkasko',
    paymentReminderLevel0: 'Zahlungserinnerung',
    paymentReminderLevel1: '1. Mahnung',
    paymentReminderLevel2: '2. Mahnung',
    paymentReminderLevel3: '3. Mahnung',
    photoPortfolio: 'Fotomappe',
    powerOfAttorney: 'Anwaltliche Vollmacht',
    repairConfirmation: 'Reparaturbestätigung',
    report: 'Gutachten',
    revocationInstruction: 'Widerrufsbelehrung',
    shortAssessment: 'Kurzgutachten',
    usedVehicleCheck: 'Gebrauchtwagen-Check',
    valuation: 'Fahrzeugbewertung',
    valuepilotMarketAnalysis: 'ValuePilot Marktwertanalyse',
    winvalueMarketAnalysis: 'WinValue Marktwertanalyse',
    winvalueResidualValueBidList: 'WinValue Gebotsliste',
};

export function translateDocumentType(documentType: DocumentTypes, shortVersion = false) {
    let documentTypeGerman: string;

    switch (documentType) {
        case 'manuallyUploadedPdf':
            throw Error('Please use title for custom documents');
        case null:
        case undefined:
            documentTypeGerman = '';
            break;
        default:
            documentTypeGerman = shortVersion
                ? documentTypeGermanShortVersionDictionary[documentType] || documentTypeGermanDictionary[documentType]
                : documentTypeGermanDictionary[documentType];
            if (!documentTypeGerman) console.error(`NO_TRANSLATION_FOR_DOCUMENT_TYPE: Type "${documentType}"`);
    }

    return documentTypeGerman;
}

export type DocumentTypes = Report['type'] | RenderedDocumentType | ExternalDocumentType;

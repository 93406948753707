import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';

export function getLinkFragmentForReportTabName(reportTabName: ReportTabName): string {
    switch (reportTabName) {
        case 'accidentData':
            return 'Beteiligte';
        case 'carData':
            return 'Fahrzeug';
        case 'carCondition':
            return 'Fahrzeugzustand';
        case 'carConditionOldtimer':
            return 'Wertgutachten-Zustand';
        case 'photos':
            return 'Fotos';
        case 'damageCalculation':
            return 'Schadenskalkulation';
        case 'valuation':
            return 'Bewertung';
        case 'valuationOldtimer':
            return 'Wertgutachten-Bewertung';
        case 'leaseReturnChecklist':
            return 'Prüfung';
        case 'invoiceAudit':
            return 'Rechnungsprüfung';
        case 'fees':
            return 'Rechnung';
        case 'printAndTransmission':
            return 'Druck-und-Versand';
    }
}

import { ResidualValueBid } from '../models/reports/residual-value/residual-value-bid';

/**
 * Sorts the given residual value bids by highest bid value first and not interested bids last.
 */
export const residualValueBidSortFunction = (bidA: ResidualValueBid, bidB: ResidualValueBid) => {
    // Sort offers that are "not interested" to the end of the list
    if (bidA.notInterested && bidB.notInterested) {
        return 0;
    } else if (bidA.notInterested) {
        return 1;
    } else if (bidB.notInterested) {
        return -1;
    }

    const valueA = bidA.bidValue.value;
    const valueB = bidB.bidValue.value;

    // Everything else is sorted by bid value (highest first)
    if (valueA === valueB) return 0;
    else return valueA > valueB ? -1 : 1;
};

import moment from 'moment';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { getInvoicePdfFilename, getInvoiceXmlFilename } from './get-datev-export-filenames';

/**
 * Create the document.xml which must be put into the DATEV ZIP.
 */
export function getDatevExportDocumentXml({ invoices }: { invoices: Invoice[] }): string {
    return `<?xml version="1.0" encoding="utf-8"?>
<archive xmlns="http://xml.datev.de/bedi/tps/document/v06.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://xml.datev.de/bedi/tps/document/v06.0 Document_v060.xsd" version="6.0" generatingSystem="autoiXpert">
<header>
    <date>${moment().format('YYYY-MM-DDTHH:mm:ss')}</date>
    <description>Belegsatzdaten Ausgangsrechnung</description>
</header>
<content>
    ${invoices.map(convertInvoiceToDatevDocumentXml).join('\n')}
</content>
</archive>`;
}

function convertInvoiceToDatevDocumentXml(invoice: Invoice): string {
    return `<document>
    <extension xsi:type="accountsReceivableLedger" datafile="${getInvoiceXmlFilename(invoice)}">
        <property value="${moment(invoice.date).format('YYYY-MM')}" key="1" />
        <property value="Ausgangsrechnungen" key="3"/>
    </extension>
    <extension xsi:type="File" name="${getInvoicePdfFilename(invoice)}"/>
</document>`;
}

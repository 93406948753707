import { getInvolvedPartiesFromInvoice } from '@autoixpert/lib/involved-parties/get-involved-parties-from-invoice';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Invoice, InvoiceInvolvedParty } from '@autoixpert/models/invoices/invoice';

export function getDocumentOrdersOfInvolvedPartiesFromInvoice({ invoice }: { invoice: Invoice }): DocumentOrder[] {
    /**
     * This might become a custom array in the future when we enable custom involved parties.
     */
    const involvedParties: InvoiceInvolvedParty[] = getInvolvedPartiesFromInvoice({ invoice });

    const documentOrders: DocumentOrder[] = [];
    for (const involvedParty of involvedParties) {
        const documentOrder = invoice.documentOrders.find(
            (documentOrder) => documentOrder.recipientRole === involvedParty.role,
        );
        if (!documentOrder) {
            throw new AxError({
                code: 'FINDING_INVOICE_DOCUMENT_ORDER_FAILED',
                message: 'The document order for the given involved party role could not be found in the invoice.',
                data: {
                    communicationRecipientRole: involvedParty.role,
                },
            });
        }
        documentOrders.push(documentOrder);
    }
    return documentOrders;
}

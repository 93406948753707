<div class="card">
    <div class="padding-wrapper">
        <mat-form-field class="mat-block">
            <input
                matInput
                #costCenterInput
                placeholder="Kostenstelle"
                [(ngModel)]="teamMember.costCenter"
                (change)="emitChange()"
                matTooltip="Die Kostenstelle wird in DATEV exportiert und ermöglicht es dir, bzw. deinem Steuerberater, unternehmerische Auswertungen wie z. B. den Betriebsabrechnungsbogen pro Sachverständigen oder Abteilung zu erstellen. Die Nutzer-Kostenstelle wird in DATEV im Feld KOST1 angezeigt." />
        </mat-form-field>
    </div>
</div>

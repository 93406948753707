<table id="info-table">
    <!--============================================-->
    <!-- Min-Width Workaround -->
    <!--============================================-->
    <tr id="min-width-workaround">
        <td class="info-table-label"></td>
        <td class="info-table-value">
            <div></div>
        </td>
        <td class="info-table-label"></td>
        <td class="info-table-value">
            <div></div>
        </td>
    </tr>
    <!--============================================-->
    <!-- END Min-Width Workaround -->
    <!--============================================-->

    <!--***********************************************
        ** Standard Info Table
        ************************************************-->
    <!--********** Everything but the valuation reports have very similar info table contents. **********-->
    <ng-container *ngIf="!['valuation', 'oldtimerValuationSmall', 'partialKasko', 'fullKasko'].includes(report.type)">
        <!--============================================-->
        <!-- Claimant & Author of Damage -->
        <!--============================================-->
        <tr>
            <!--********** Claimant Name **********-->
            <td class="info-table-label emphasis">
                {{ documentType === 'powerOfAttorney' ? 'Mandant' : 'Auftraggeber' }}
            </td>
            <td class="info-table-value">
                <div [innerHTML]="getCompleteClaimantName()"></div>
                {{ report.claimant.contactPerson.streetAndHouseNumberOrLockbox }}
                <br />
                {{ report.claimant.contactPerson.zip }} {{ report.claimant.contactPerson.city }}
            </td>

            <!--********** Author of Damage Name **********-->
            <td class="emphasis">Versicherter</td>
            <td class="">
                <ng-container *ngIf="report.authorOfDamage">
                    <div [innerHTML]="getCompleteOwnerOfAuthorOfDamagesCarName()"></div>
                    <ng-container *ngIf="report.authorOfDamage.isOwner">
                        {{ report.authorOfDamage.contactPerson.streetAndHouseNumberOrLockbox }}
                        <br />
                        {{ report.authorOfDamage.contactPerson.zip }}
                        {{ report.authorOfDamage.contactPerson.city }}
                    </ng-container>
                    <ng-container *ngIf="!report.authorOfDamage.isOwner">
                        {{ report.ownerOfAuthorOfDamagesCar.contactPerson.streetAndHouseNumberOrLockbox }}
                        <br />
                        {{ report.ownerOfAuthorOfDamagesCar.contactPerson.zip }}
                        {{ report.ownerOfAuthorOfDamagesCar.contactPerson.city }}
                    </ng-container>
                </ng-container>
            </td>
        </tr>

        <tr>
            <!--********** Claimant License Plate **********-->
            <td class="info-table-label">Amtl. Kennzeichen</td>
            <td class="info-table-value">{{ report.car.licensePlate }}</td>

            <!--********** Author of Damage License Plate **********-->
            <td class="info-table-label">Amtl. Kennzeichen</td>
            <td class="info-table-value">{{ report.authorOfDamage?.licensePlate }}</td>
        </tr>

        <tr class="last-row">
            <!--********** May Deduct Taxes **********-->
            <td class="info-table-label">Vorsteuerabzug</td>
            <td class="info-table-value">
                {{ report.claimant.contactPerson.mayDeductTaxes | translateTristate }}
            </td>
        </tr>
        <!--============================================-->
        <!-- END Claimant & Author of Damage -->
        <!--============================================-->

        <!--============================================-->
        <!-- Assessor & Insurance -->
        <!--============================================-->
        <tr id="assessor-and-insurance-row">
            <ng-container *ngIf="documentType !== 'powerOfAttorney'">
                <!--********** Assessor Address **********-->
                <td class="info-table-label emphasis" rowspan="3">Sachverständiger</td>
                <td class="info-table-value" rowspan="3">
                    <div [innerHTML]="getCompleteAssessorName()"></div>
                    <div>
                        {{ responsibleAssessorContactPerson.streetAndHouseNumberOrLockbox }}
                        <br />
                        {{ responsibleAssessorContactPerson.zip }}
                        {{ responsibleAssessorContactPerson.city }}
                    </div>
                </td>
            </ng-container>
            <ng-container *ngIf="documentType === 'powerOfAttorney'">
                <!--********** Lawyer Address **********-->
                <td class="info-table-label emphasis" rowspan="3">Anwalt</td>
                <td class="info-table-value" rowspan="3">
                    <div [innerHTML]="getCompleteLawyerName()"></div>
                    <div>
                        {{ report.lawyer?.contactPerson.streetAndHouseNumberOrLockbox }}
                        <br />
                        {{ report.lawyer?.contactPerson.zip }}
                        {{ report.lawyer?.contactPerson.city }}
                    </div>
                </td>
            </ng-container>

            <!--********** Insurance Name **********-->
            <td class="info-table-label emphasis">Versicherung</td>
            <td class="info-table-value">{{ report.insurance?.contactPerson.organization }}</td>
        </tr>

        <tr>
            <!--********** Damage Date **********-->
            <td class="info-table-label">Schadentag</td>
            <td class="info-table-value">
                <ng-container *ngIf="report.accident?.date">
                    {{ report.accident?.date | moment: 'DD.MM.YYYY' }}
                </ng-container>
            </td>
        </tr>
        <tr>
            <!--********** Insurance Number **********-->
            <td class="info-table-label">Versicherungsnr.</td>
            <td class="info-table-value">{{ report.authorOfDamage?.insuranceNumber }}</td>
        </tr>

        <tr class="last-table-row">
            <!--********** Report Token **********-->
            <td class="info-table-label">Aktenzeichen</td>
            <td class="info-table-value">
                {{ documentType === 'powerOfAttorney' ? report.lawyer?.caseNumber : report.token }}
            </td>

            <!--********** Damage Case Number **********-->
            <td class="info-table-label">Schadennummer</td>
            <td class="info-table-value">{{ report.insurance?.caseNumber }}</td>
        </tr>
        <!--============================================-->
        <!-- END Assessor & Insurance -->
        <!--============================================-->
    </ng-container>
    <!--***********************************************
        ** END Standard Info Table
        ************************************************-->

    <!--***********************************************
        ** Kasko Info Table
        ************************************************-->
    <!--********** Everything but the valuation reports have very similar info table contents. **********-->
    <ng-container *ngIf="['partialKasko', 'fullKasko'].includes(report.type)">
        <!--============================================-->
        <!-- Car Owner & Insurance -->
        <!--============================================-->
        <tr>
            <!--********** Claimant Name **********-->
            <td class="info-table-label emphasis" rowspan="2">
                {{ documentType === 'powerOfAttorney' ? 'Mandant' : 'Versicherter' }}
            </td>
            <td class="info-table-value" rowspan="2">
                <div [innerHTML]="getCompleteCarOwnerName()"></div>
                {{
                    report.claimant.isOwner
                        ? report.claimant.contactPerson.streetAndHouseNumberOrLockbox
                        : report.ownerOfClaimantsCar.contactPerson.streetAndHouseNumberOrLockbox
                }}
                <br />
                {{
                    report.claimant.isOwner
                        ? report.claimant.contactPerson.zip
                        : report.ownerOfClaimantsCar.contactPerson.zip
                }}
                {{
                    report.claimant.isOwner
                        ? report.claimant.contactPerson.city
                        : report.ownerOfClaimantsCar.contactPerson.city
                }}
            </td>

            <!--********** Insurance Name **********-->
            <td class="info-table-label emphasis">Versicherung</td>
            <td class="info-table-value">{{ report.insurance?.contactPerson.organization }}</td>
        </tr>

        <tr>
            <!--********** Damage Date **********-->
            <td class="info-table-label">Schadentag</td>
            <td class="info-table-value">
                <ng-container *ngIf="report.accident?.date">
                    {{ report.accident.date | moment: 'DD.MM.YYYY' }}
                </ng-container>
            </td>
        </tr>

        <tr>
            <!--********** Claimant License Plate **********-->
            <td class="info-table-label">Amtl. Kennzeichen</td>
            <td class="info-table-value">{{ report.car.licensePlate }}</td>

            <!--********** Insurance Number **********-->
            <td class="info-table-label">Versicherungsnr.</td>
            <td class="info-table-value">{{ report.authorOfDamage?.insuranceNumber }}</td>
        </tr>

        <tr id="last-row-of-claimant-and-author-of-damage">
            <!--********** May Deduct Taxes **********-->
            <td class="info-table-label">Vorsteuerabzug</td>
            <td class="info-table-value">
                {{ report.claimant.contactPerson.mayDeductTaxes | translateTristate }}
            </td>

            <!--********** Damage Case Number **********-->
            <td class="info-table-label">Schadennummer</td>
            <td class="info-table-value">{{ report.insurance?.caseNumber }}</td>
        </tr>
        <!--============================================-->
        <!-- END Car Owner & Insurance -->
        <!--============================================-->

        <!--============================================-->
        <!-- Assessor -->
        <!--============================================-->
        <tr id="assessor-row">
            <ng-container *ngIf="documentType !== 'powerOfAttorney'">
                <!--********** Assessor Address **********-->
                <td class="info-table-label emphasis">Sachverständiger</td>
                <td class="info-table-value">
                    <div [innerHTML]="getCompleteAssessorName()"></div>
                    <div>
                        {{ responsibleAssessorContactPerson.streetAndHouseNumberOrLockbox }}
                        <br />
                        {{ responsibleAssessorContactPerson.zip }}
                        {{ responsibleAssessorContactPerson.city }}
                    </div>
                </td>
            </ng-container>
            <ng-container *ngIf="documentType === 'powerOfAttorney'">
                <!--********** Lawyer Address **********-->
                <td class="info-table-label emphasis">Anwalt</td>
                <td class="info-table-value">
                    <div [innerHTML]="getCompleteLawyerName()"></div>
                    <div>
                        {{ report.lawyer?.contactPerson.streetAndHouseNumberOrLockbox }}
                        <br />
                        {{ report.lawyer?.contactPerson.zip }}
                        {{ report.lawyer?.contactPerson.city }}
                    </div>
                </td>
            </ng-container>
        </tr>

        <tr class="last-table-row">
            <!--********** Report Token **********-->
            <td class="info-table-label">Aktenzeichen</td>
            <td class="info-table-value">
                {{ documentType === 'powerOfAttorney' ? report.lawyer?.caseNumber : report.token }}
            </td>
        </tr>
        <!--============================================-->
        <!-- END Assessor -->
        <!--============================================-->
    </ng-container>
    <!--***********************************************
        ** END Kasko Info Table
        ************************************************-->

    <!--***********************************************
        ** Valuation Reports Info Table
        ************************************************-->

    <!--********** Valuation reports have only the customer and the assessor in the info table. **********-->
    <ng-container *ngIf="['valuation', 'oldtimerValuationSmall'].includes(report.type)">
        <!--============================================-->
        <!-- Customer & Assessor -->
        <!--============================================-->
        <tr>
            <!--********** Claimant Name **********-->
            <td class="info-table-label emphasis">
                {{ documentType === 'powerOfAttorney' ? 'Mandant' : 'Kunde' }}
            </td>
            <td class="info-table-value">
                <div [innerHTML]="getCompleteClaimantName()"></div>
                {{ report.claimant.contactPerson.streetAndHouseNumberOrLockbox }}
                <br />
                {{ report.claimant.contactPerson.zip }} {{ report.claimant.contactPerson.city }}
            </td>

            <ng-container *ngIf="documentType !== 'powerOfAttorney'">
                <!--********** Assessor Address **********-->
                <td class="info-table-label emphasis">Sachverständiger</td>
                <td class="info-table-value">
                    <div [innerHTML]="getCompleteAssessorName()"></div>
                    <div>
                        {{ responsibleAssessorContactPerson.streetAndHouseNumberOrLockbox }}
                        <br />
                        {{ responsibleAssessorContactPerson.zip }}
                        {{ responsibleAssessorContactPerson.city }}
                    </div>
                </td>
            </ng-container>
            <ng-container *ngIf="documentType === 'powerOfAttorney'">
                <!--********** Lawyer Address **********-->
                <td class="info-table-label emphasis">Anwalt</td>
                <td class="info-table-value">
                    <div [innerHTML]="getCompleteLawyerName()"></div>
                    <div>
                        {{ report.lawyer?.contactPerson.streetAndHouseNumberOrLockbox }}
                        <br />
                        {{ report.lawyer?.contactPerson.zip }}
                        {{ report.lawyer?.contactPerson.city }}
                    </div>
                </td>
            </ng-container>
        </tr>

        <tr>
            <!--********** Claimant License Plate **********-->
            <td class="info-table-label">Amtl. Kennzeichen</td>
            <td class="info-table-value">{{ report.car.licensePlate }}</td>

            <!--********** Author of Damage License Plate **********-->
            <td class="info-table-label">Aktenzeichen</td>
            <td class="info-table-value">
                {{ documentType === 'powerOfAttorney' ? report.lawyer.caseNumber : report.token }}
            </td>
        </tr>
        <!--============================================-->
        <!-- END Customer & Assessor -->
        <!--============================================-->
    </ng-container>
    <!--***********************************************
        ** END Valuation Reports Info Table
        ************************************************-->
</table>

import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';
import { Timestamps } from '../../timestamps';

export class DiminishedValueCalculation implements Timestamps {
    _id: string = generateId();
    // Car
    originalPrice: number = null; // Neupreis
    ageInMonths: number = null; // Alter in Monaten
    ageFactorTroemner: number = null; // Neuwertigkeit (Trömner)
    replacementValue: number = null; // Wiederbeschaffungswert
    timeValue: number = null; // Veräußerungswert (Wiederbeschaffungswert abzgl. Händlerspanne; bei Verkauf auf dem Privatmarkt sind beide Werte gleich)
    mileage: number = null;
    mileageUnit: 'km' | 'mi' | 'h' = 'km';
    marketabilityBvsk: number = null;
    marketabilityMfm: number = null;
    marketabilityTroemner: number = null;
    previousDamageBvsk: number = null;
    previousDamageMfm: number = null;
    previousDamageTroemner: number = null;

    // Repair
    totalRepairCosts: number = null; // Gesamt-Reparaturkosten
    substantialRepairCosts: number = null; // Erhebliche Reparaturkosten
    laborCosts: number = null; // Lohnkosten
    materialCosts: number = null; // Materialkosten
    damageLevel: number = null; // Schadenumfang
    damageIntensity: number = null; // Intensität
    vehicleDamageTroemner: number = null; // Fahrzeugschaden (Trömner)

    // Methods
    methods: DiminishedValueCalculationMethod[] = [];

    /**
     * Is this calculation using the new method of calculating the diminished value according to the verdict of the BGH
     * of July 2024?
     * New method = reduce the input parameters for VAT-deducting claimants by 19%.
     */
    isBasedOnNetValues?: boolean = true;
    /**
     * Are the inputs to the diminished value calculator reduced by VAT to satisfy the verdict of the BGH from July 2024?
     * May only be true or false, not null.
     */
    reduceInputValuesByVat?: boolean;
    /**
     * Has the user manually overwritten the flag?
     */
    reduceInputValuesByVatManuallyOverwritten?: boolean;

    comment: string;

    createdBy: string = null;
    lockedAt: string = null;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

export type DiminishedValueCalculationMethod =
    | 'mfm'
    | 'bvsk'
    | 'halbgewachs'
    | 'hamburgModel'
    | 'ruhkopf'
    | 'dvgt'
    | 'troemner';

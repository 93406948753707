import { ContactPerson } from '../../models/contacts/contact-person';
import { InvoiceInvolvedParty } from '../../models/invoices/invoice';
import { InvoiceParameters } from '../../models/invoices/invoice-parameters';
import { Report } from '../../models/reports/report';
import { isNameOrOrganizationFilled } from '../contact-people/is-name-or-organization-filled';
import { getInvoiceRecipientByRole } from './get-invoice-recipient-by-role';

export function getInvolvedPartiesForInvoiceFromReport(
    report: Report,
    recipientRole: InvoiceParameters['recipientRole'],
): {
    recipient: InvoiceInvolvedParty;
    claimant: InvoiceInvolvedParty;
    insurance: InvoiceInvolvedParty;
    lawyer: InvoiceInvolvedParty;
} {
    const recipientContactPerson: ContactPerson = getInvoiceRecipientByRole(recipientRole, report);

    const recipient: InvoiceInvolvedParty = new InvoiceInvolvedParty({
        contactPerson: recipientContactPerson,
        role: 'invoiceRecipient',
    });

    const claimant: InvoiceInvolvedParty = isNameOrOrganizationFilled(report.claimant?.contactPerson)
        ? new InvoiceInvolvedParty({
              contactPerson: report.claimant.contactPerson,
              role: 'claimant',
          })
        : null;

    const lawyer: InvoiceInvolvedParty = isNameOrOrganizationFilled(report.lawyer?.contactPerson)
        ? new InvoiceInvolvedParty({
              contactPerson: report.lawyer.contactPerson,
              role: 'lawyer',
          })
        : null;

    const insurance: InvoiceInvolvedParty = isNameOrOrganizationFilled(report.insurance?.contactPerson)
        ? new InvoiceInvolvedParty({
              contactPerson: report.insurance.contactPerson,
              role: 'insurance',
          })
        : null;

    return {
        recipient,
        claimant,
        lawyer,
        insurance,
    };
}

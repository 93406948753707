import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { FeathersQuery } from '../../types/feathers-query';

export function getTaskFilterQuery(userPreferences: UserPreferences, loggedInUser: User): FeathersQuery {
    const query: FeathersQuery = {};

    // Filter
    switch (userPreferences.taskList_quickFilter) {
        case 'onlyOwn':
            query.assigneeId = loggedInUser._id;
            break;

        case 'assignedUser':
            if (userPreferences.taskList_usersForFilter?.length > 0) {
                query.assigneeId = { $in: userPreferences.taskList_usersForFilter };
            }
            break;

        case 'onlyDue':
            query.dueDate = { $gt: new Date() };
            break;

        case 'labels':
            if (userPreferences.taskList_labelsForFilter?.length > 0) {
                query['labels.name'] = { $in: userPreferences.taskList_labelsForFilter };
            }
            break;
    }

    return query;
}

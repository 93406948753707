import { Component } from '@angular/core';

/**
 * A component for flat (white) buttons with a shadow
 */

@Component({
    selector: 'white-button-with-shadow',
    templateUrl: './white-button-with-shadow.component.html',
    styleUrls: ['./white-button-with-shadow.component.scss'],
})
export class WhiteButtonWithShadowComponent {}

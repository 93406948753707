import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HelpPanelPage } from '../../global/help-panel/help-panel.component';

/**
 * Allows opening the help panel from anywhere.
 */
@Injectable()
export class HelpPanelService {
    public openHelpPanel$: Subject<HelpPanelPage> = new Subject<HelpPanelPage>();

    public openHelpPanel(page: HelpPanelPage): void {
        this.openHelpPanel$.next(page);
    }
}

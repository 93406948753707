import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'invoice-template-title-dialog',
    templateUrl: 'invoice-template-title-dialog.component.html',
    styleUrls: ['invoice-template-title-dialog.component.scss'],
})
export class InvoiceTemplateTitleDialogComponent {
    constructor() {}

    @Input() title: string = '';

    @Output() useTitle: EventEmitter<string> = new EventEmitter<string>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    public emitUseTitleEvent(): void {
        this.useTitle.emit(this.title);
        this.closeDialog();
    }

    public closeDialog(): void {
        this.close.emit();
    }
}

import { Invoice } from '../../models/invoices/invoice';
import { getCanceledAmount } from './get-canceled-amount';
import { getPaidAmount } from './get-paid-amount';
import { getWrittenOffAmount } from './get-written-off-amount';

/**
 * Returns the amount that remains to be paid.
 *
 * Unpaid = invoiceTotal - paidOrSettledAmount (regular payments or settled short payments) - canceledAmount
 */
export function getUnpaidAmount(invoice: Invoice): number {
    // Since this function is called in templates, this check improves performance for change detection
    if (!invoice.hasOutstandingPayments) return 0;

    const unpaidAmount: number =
        invoice.totalGross - getPaidAmount(invoice) - getCanceledAmount(invoice) - getWrittenOffAmount(invoice);
    const rounded: number = Math.round(unpaidAmount * 100) / 100;

    // Overpaid invoices may return values below 0. Overpaid must be treated as paid, i. e. unpaidAmount = 0.
    return Math.max(rounded, 0);
}

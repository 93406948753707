import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { BadRequest } from '@autoixpert/models/errors/ax-error';
import { ImportHistory } from '@autoixpert/models/import-history';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class ImportHistoryService extends OfflineSyncServiceBase<ImportHistory> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'importHistory',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
        });
    }

    /**
     *
     * @param importType
     */
    public findByImportType(importType: ImportHistory['importType']): Observable<ImportHistory[]> {
        if (!importType) {
            throw new BadRequest({
                code: 'IMPORT_TYPE_MISSING',
                message: 'Please provide an import type.',
                data: {
                    importType,
                },
            });
        }

        return super.find({ importType });
    }

    /**
     * Get import history entries for a specific import type and source system.
     * This is used to only display import histories for the GTÜ invoice import.
     */
    public findByImportTypeAndSourceSystem(
        importType: ImportHistory['importType'],
        sourceSystem: ImportHistory['sourceSystem'],
    ): Observable<ImportHistory[]> {
        if (!importType) {
            throw new BadRequest({
                code: 'IMPORT_TYPE_MISSING',
                message: 'Please provide an import type.',
                data: {
                    importType,
                },
            });
        }

        if (!sourceSystem) {
            throw new BadRequest({
                code: 'SOURCE_SYSTEM_MISSING',
                message: 'Please provide a source system.',
                data: {
                    importType,
                },
            });
        }

        return super.find({ importType, sourceSystem });
    }

    /**
     * @deprecated
     * On this service, use the method findByImportType instead.
     */
    public find(feathersQuery: Parameters<OfflineSyncServiceBase<ImportHistory>['find']>) {
        return super.find();
    }
}

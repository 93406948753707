<div
    id="report-progress-panel-container"
    class="card"
    [ngClass]="{ 'with-position-indicator': isPositionIndicatorShown }">
    <!--============================================-->
    <!-- Header -->
    <!--============================================-->
    <header>
        <!--********** Headline & Counter **********-->
        <div id="headline-and-progress-counter-container" class="display-flex flex-center-content flex-space-between">
            <h2 id="headline">{{ translateReportTabName() }}</h2>
            <div class="progress-bar-label label" matTooltip="Anzahl abgeschlossener Positionen">
                {{ getCompletedItems(checklist).length }} von {{ checklist.length }}
            </div>
        </div>

        <!--********** Card Toolbar **********-->
        <div class="card-toolbar">
            <mat-icon class="toolbar-icon white-icon" [matMenuTriggerFor]="headerSubmenu">more_vert</mat-icon>
            <mat-menu #headerSubmenu>
                <a mat-menu-item *ngIf="!editModeActive" (click)="enterEditMode()">Positionen bearbeiten</a>
                <a mat-menu-item *ngIf="editModeActive" (click)="leaveEditMode()">Bearbeitung beenden</a>
            </mat-menu>
        </div>

        <!--********** Progress Bar **********-->
        <div class="progress-bar-container">
            <div class="progress-bar-background">
                <div
                    class="progress-bar-fill"
                    [style.width]="(getCompletedItems(checklist).length / checklist.length) * 100 + '%'"></div>
            </div>
        </div>

        <!--********** Report Type **********-->
        <div class="label" *ngIf="editModeActive" matTooltip="Die Prüfliste kann pro Gutachtentyp angepasst werden.">
            {{ getReportTypeGerman() }}
        </div>
    </header>
    <!--============================================-->
    <!-- END Header -->
    <!--============================================-->

    <!--============================================-->
    <!-- Read Mode -->
    <!--============================================-->
    <section id="read-only-mode-section" class="checklist-section" *ngIf="!editModeActive">
        <table class="checklist-table">
            <tr *ngFor="let item of checklist">
                <!--********** Status Icon **********-->
                <td
                    class="status-icon-cell cursor-pointer"
                    [ngSwitch]="getChecklistItemStatus(item)"
                    [matMenuTriggerFor]="statusOverrideMenu">
                    <mat-icon class="status-icon done-icon" *ngSwitchCase="'complete'" matTooltip="Erledigt">
                        check
                    </mat-icon>
                    <mat-icon class="status-icon" *ngSwitchCase="'partiallyComplete'" matTooltip="In Bearbeitung">
                        rule
                    </mat-icon>
                    <mat-icon
                        class="status-icon empty-icon"
                        [ngClass]="{ visible: getManualStatusEntry(item) }"
                        *ngSwitchCase="'empty'"
                        matTooltip="Unbearbeitet">
                        more_horiz
                    </mat-icon>
                    <div
                        class="manual-status-indicator"
                        *ngIf="getManualStatusEntry(item)"
                        matTooltip="Status wurde manuell gesetzt. Auf automatisch stellen, um den Status wieder automatisch berechnen zu lassen.">
                        M
                    </div>
                </td>

                <!--********** Override Status Manually **********-->
                <mat-menu #statusOverrideMenu>
                    <!--********** Done **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="setStatusManually(item, 'complete'); saveReport()">
                        <mat-icon class="status-icon done-icon">check</mat-icon>
                        Erledigt
                    </a>

                    <!--********** Partially Complete **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="setStatusManually(item, 'partiallyComplete'); saveReport()">
                        <mat-icon class="status-icon">rule</mat-icon>
                        In Bearbeitung
                    </a>

                    <!--********** Empty **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="setStatusManually(item, 'empty'); saveReport()">
                        <mat-icon class="status-icon">more_horiz</mat-icon>
                        Unbearbeitet
                    </a>

                    <!--********** Automatic **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="getManualStatusEntry(item)"
                        (click)="setStatusManually(item, 'automatic'); saveReport()"
                        matTooltip="Status wieder automatisch berechnen lassen">
                        <mat-icon class="status-icon">refresh</mat-icon>
                        Automatisch
                    </a>
                </mat-menu>

                <!--********** Item Title **********-->
                <td class="item-label-cell">
                    <div
                        class="item-label text-overflow-ellipsis"
                        (dblclick)="enterEditMode()"
                        [matTooltip]="
                            getChecklistItemLabelAndTooltip(item.name).tooltip ||
                            getChecklistItemLabelAndTooltip(item.name).label
                        ">
                        {{ getChecklistItemLabelAndTooltip(item.name)?.label }}

                        <!--********** Residual Value Threshold **********-->
                        <span
                            class="label"
                            *ngIf="
                                item.name === 'residualValue' &&
                                item.isRequired &&
                                reportProgressConfig.residualValueThresholdInPercent
                            "
                            (click)="enterEditMode()"
                            matTooltip="Ab diesem Verhältnis von Reparaturaufwand zu Wiederbeschaffungswert wird die Angabe des Restwerts als Pflichtposition behandelt, weil ein Ersatzfahrzeug in Frage kommt. Klicke, um den Wert anzupassen.">
                            &nbsp;ab {{ reportProgressConfig.residualValueThresholdInPercent }} %
                        </span>
                    </div>
                </td>

                <!--********** is Required **********-->
                <td class="item-is-required-cell">
                    <mat-icon class="item-is-required-icon" *ngIf="isItemRequired(item)" matTooltip="Pflichtposition">
                        star
                    </mat-icon>
                </td>
            </tr>
        </table>
    </section>
    <!--============================================-->
    <!-- Read Mode -->
    <!--============================================-->

    <!--============================================-->
    <!-- Edit Mode -->
    <!--============================================-->
    <section id="edit-mode-section" class="checklist-section" *ngIf="editModeActive">
        <table class="checklist-table">
            <tr *ngFor="let availableItem of availableChecklistItems">
                <!--********** Checkbox **********-->
                <td class="activation-checkbox-cell">
                    <mat-checkbox
                        [(ngModel)]="availableItem.activated"
                        (change)="saveReportProgressConfig(); refreshChecklist()"></mat-checkbox>
                </td>

                <!--********** Item Title **********-->
                <td class="item-label-cell">
                    <div
                        class="item-label text-overflow-ellipsis"
                        [matTooltip]="
                            getChecklistItemLabelAndTooltip(availableItem.name).tooltip ||
                            getChecklistItemLabelAndTooltip(availableItem.name).label
                        ">
                        {{ getChecklistItemLabelAndTooltip(availableItem.name)?.label }}
                    </div>

                    <!--********** Residual Value Threshold **********-->
                    <mat-form-field
                        id="residual-value-threshold-form-field"
                        *ngIf="availableItem.name === 'residualValue' && availableItem.isRequired">
                        <input
                            matInput
                            number-input
                            placeholder="Grenzwert RK zu WBW"
                            [minimumFractionDigits]="0"
                            [maximumFractionDigits]="0"
                            [(number)]="reportProgressConfig.residualValueThresholdInPercent"
                            (numberChange)="saveReportProgressConfig()"
                            matTooltip="Ist der Restwert als Pflichtposition markiert und liegt das Verhältnis aus Reparaturkosten zu Wiederbeschaffungsaufwand über diesem Wert,
                               wird die Angabe eines Restwerts zur Pflicht." />
                        <span matSuffix>%</span>
                    </mat-form-field>
                </td>

                <!--********** is Required **********-->
                <td class="item-is-required-cell">
                    <mat-icon
                        class="item-is-required-icon cursor-pointer"
                        [ngClass]="{ active: availableItem.isRequired }"
                        (click)="toggleRequirement(availableItem); saveReportProgressConfig()"
                        matTooltip="Pflichtposition">
                        star
                    </mat-icon>
                </td>
            </tr>
        </table>
        <div id="leave-edit-mode-button-container" class="text-align-center">
            <button (click)="leaveEditMode()">Bearbeitung beenden</button>
        </div>
    </section>
    <!--============================================-->
    <!-- END Edit Mode -->
    <!--============================================-->
</div>

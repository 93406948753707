import moment from 'moment';
import { Valuation } from '@autoixpert/models/reports/market-value/valuation';
import { Report } from '@autoixpert/models/reports/report';
import { getVatRate } from '../get-vat-rate-2020';

export function determineTaxationTypes(report: Report): Valuation['taxationType'] {
    let taxationType: Valuation['taxationType'];
    const date: string = report.car.firstRegistration || report.car.productionYear;

    // If the user has not entered any dates, we still want to assume a default to prevent errors with external platforms or the printed PDF.
    if (!date) {
        return 'full';
    }

    const carBirthday: moment.Moment = moment(date);
    const carAge: number = moment().diff(carBirthday, 'years');

    if (carAge >= 10) {
        taxationType = 'neutral';
    } else if (carAge > 3) {
        /**
         * During the period of reduced VATs, set the margin_reduced value.
         */
        taxationType = getVatRate(report.completionDate) === 0.16 ? 'margin_reduced' : 'margin';
    } else {
        taxationType = getVatRate(report.completionDate) === 0.16 ? 'full_16' : 'full';
    }

    return taxationType;
}

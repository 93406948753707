import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Injector,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { PANEL_CLOSE_SUBJECT_TOKEN } from '@autoixpert/injection-tokens/common-overlay-injection-tokens';
import { InternalNote } from '@autoixpert/models/internal-note';
import {
    CHANGE_SUBJECT_TOKEN,
    INTERNAL_NOTES_TOKEN,
    InternalNotesPanelComponent,
} from './internal-notes-panel.component';

@Directive({
    selector: '[internal-notes-panel-anchor]',
    exportAs: 'internalNotesPanelAnchor',
    host: {
        '[class.internal-notes-icon]': 'true',
    },
})
export class InternalNotesPanelAnchorDirective {
    constructor(
        private elementRef: ElementRef,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input() notes: InternalNote[];
    @Input() autoOpenWhenVisible: boolean;

    @Output() change: EventEmitter<InternalNote[]> = new EventEmitter<InternalNote[]>();
    @Output() panelClose: EventEmitter<void> = new EventEmitter<void>();

    private overlayRef: OverlayRef;

    get hostElement() {
        return this.elementRef.nativeElement;
    }

    ngOnInit() {
        if (this.autoOpenWhenVisible) {
            this.openNotesPanel();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['notes'] && !changes['notes'].firstChange) {
            this.reopenNotesPanelIfOpen();
        }
    }

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openNotesPanel() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                ])
                .withPush(false) // withPush being true (default) causes any overlay to grow up to 100% of the viewport, overflowing the bottom.
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        /**
         * We must hear from the overlay when the notes change to trigger saving the record
         * within the parent of this anchor directive.
         * The overlay cannot do that because notes exist on different models, such as Report and Invoice.
         */
        const changeListener = new Subject<InternalNote[]>();
        const changeSubscription = changeListener.subscribe((internalNotes) => this.change.emit(internalNotes));

        /**
         * Also listen for the close event of the parent.
         */
        const panelCloseListener = new Subject<void>();
        const closeSubscription = panelCloseListener.subscribe(() => this.panelClose.emit());

        // Close panel when clicking the backdrop.
        const detachmentSubscription = this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
            detachmentSubscription.unsubscribe();
            changeSubscription.unsubscribe();
            closeSubscription.unsubscribe();
        });
        //*****************************************************************************
        //  Injector
        //****************************************************************************/
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: CHANGE_SUBJECT_TOKEN,
                    useValue: changeListener,
                },
                {
                    provide: PANEL_CLOSE_SUBJECT_TOKEN,
                    useValue: panelCloseListener,
                },
                {
                    provide: INTERNAL_NOTES_TOKEN,
                    useValue: this.notes,
                },
                {
                    provide: OverlayRef,
                    useValue: this.overlayRef,
                },
            ],
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Injector
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Component Portal
        //****************************************************************************/
        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<InternalNotesPanelComponent>(
            InternalNotesPanelComponent,
            null,
            injector,
        );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Component Portal
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Attach Component to Portal Outlet
        //****************************************************************************/
        this.overlayRef.attach(componentPortal);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Attach Component to Portal Outlet
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/

    public closeNotesPanel() {
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
    }

    /**
     * Used to refresh the data in the notes panel. The injector only passes the notes to the child once on creation.
     * If the notes array is replaced from the outside of this anchor, the child cannot be updated unless we re-open it.
     */
    public reopenNotesPanelIfOpen() {
        if (this.overlayRef) {
            this.closeNotesPanel();
            this.openNotesPanel();
        }
    }

    //*****************************************************************************
    //  Host Class Binding
    //****************************************************************************/
    @HostBinding('class.highlighted-icon-blue')
    public get isHostIconHighlightedInBlue(): boolean {
        return this.notes?.length > 0;
    }

    @HostBinding('class.has-important-note')
    public get hasImportantNote(): boolean {
        return this.notes?.some((note) => note.isImportant);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Host Class Binding
    /////////////////////////////////////////////////////////////////////////////*/
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LabelConfig } from '@autoixpert/models/labels/label-config';
import { OfflineSyncServiceBase } from '../libraries/database/offline-sync-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class LabelConfigService extends OfflineSyncServiceBase<LabelConfig> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
    ) {
        super({
            serviceName: 'labelConfig',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            objectStoreAndIndexMigrations: undefined,
            recordMigrations: [],
        });
    }
}

export class InvoiceAnalytics {
    /**
     * If no date filter was requested, the analytics will only include invoices that have been issued (default)
     * or have been paid in the current year (query.dateFilterType === 'paymentDate').
     *
     * In this case, the yearOfPaidInvoice will be set to the current year.
     * The yearOfPaidInvoice will be empty if the client has requested a specific date range.
     */
    yearOfPaidInvoice: string;

    /**
     * Includes:
     * - paid and overpaid amounts on paid invoices
     * - paid amounts on unpaid invoices (partial payments)
     */
    paid: {
        paidAmount: number;
        numberOfInvoices: number;

        includedPartialPayments: number;
    } = {
        paidAmount: null,
        numberOfInvoices: null,
        includedPartialPayments: null,
    };

    /**
     * Unpaid total on due invoices.
     *
     * Paid and writtenOff short payments are subtracted from the total due amount.
     * Short payments that are not paid or written off are not subtracted, since we threat them as outstanding (e.g. payments dialog).
     */
    unpaidAndDue: {
        dueAmount: number;
        numberOfInvoices: number;

        subtractedPartialPayments: number;
        subtractedWrittenOffShortPayments: number;
        subtractedCancelledAmount: number;
    } = {
        dueAmount: null,
        numberOfInvoices: null,
        subtractedPartialPayments: null,
        subtractedWrittenOffShortPayments: null,
        subtractedCancelledAmount: null,
    };

    /**
     * Unpaid total on overdue invoices.
     *
     * Paid and writtenOff short payments are subtracted from the total due amount.
     * Short payments that are not paid or written off are not subtracted, since we threat them as outstanding (e.g. payments dialog).
     */
    unpaidAndOverdue: {
        overdueAmount: number;
        numberOfInvoices: number;

        subtractedPartialPayments: number;
        subtractedWrittenOffShortPayments: number;
        subtractedCancelledAmount: number;
    } = {
        overdueAmount: null,
        numberOfInvoices: null,
        subtractedPartialPayments: null,
        subtractedWrittenOffShortPayments: null,
        subtractedCancelledAmount: null,
    };
}

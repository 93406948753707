<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!--============================================-->
        <!-- Left column - available scales -->
        <!--============================================-->
        <div id="left-column">
            <h2>Lacktypen</h2>

            <!--============================================-->
            <!-- List of available scales -->
            <!--============================================-->
            <section id="paint-thickness-scales-section">
                <div
                    id="paint-thickness-scale-list"
                    cdkDropList
                    (cdkDropListDropped)="reorderPaintThicknessScales($event)">
                    <!--********** Item **********-->
                    <div
                        class="paint-thickness-scale-list-item"
                        *ngFor="let scale of paintThicknessScales"
                        cdkDrag
                        (click)="selectPaintThicknessScale(scale)"
                        [ngClass]="{ selected: selectedPaintThicknessScale === scale }">
                        <!--********** Title & Date **********-->
                        <div class="paint-thickness-scale-title-and-date-container">
                            <!--********** Title **********-->
                            <div class="paint-thickness-scale-title text-overflow-ellipsis" [matTooltip]="scale.name">
                                <ng-container *ngIf="scale.name">{{ scale.name }}</ng-container>
                                <span *ngIf="!scale.name" class="italic">Kein Name</span>
                            </div>
                        </div>

                        <!--********** Is Default **********-->
                        <mat-icon
                            class="default-marker medium-icon"
                            [ngClass]="{ 'is-default': scale.isDefault }"
                            (click)="markPaintThicknessScaleAsFavorite(scale)"
                            [matTooltip]="
                                scale.isDefault ? 'Diese Skala wird standardmäßig verwendet' : 'Als Standard setzen'
                            ">
                            star
                        </mat-icon>

                        <!--********** Submenu **********-->
                        <mat-icon [matMenuTriggerFor]="paintThicknessScaleItemSubmenu" class="toolbar-icon">
                            more_vert
                        </mat-icon>
                        <mat-menu #paintThicknessScaleItemSubmenu>
                            <!--********** Copy **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                [disabled]="disabled"
                                (click)="copyPaintThicknessScale(scale)"
                                matTooltip="Kostensätze kopieren">
                                <mat-icon>content_copy</mat-icon>
                                Kopieren
                            </a>

                            <!--********** Delete **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                [disabled]="disabled"
                                *ngIf="paintThicknessScales?.length > 1"
                                (click)="deletePaintThicknessScale(scale)"
                                matTooltip="Kostensätze löschen">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </a>
                        </mat-menu>
                    </div>
                </div>

                <!--********** New Scale **********-->
                <div class="dashed-button" (click)="createPaintThicknessScale()" [class.disabled]="disabled">
                    + Neue Skala
                </div>
            </section>
            <!--============================================-->
            <!-- END List of available scales -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Left column - available scales -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column - Editor -->
        <!--============================================-->
        <div id="right-column">
            <mat-icon class="dialog-close-icon" (click)="closePaintThicknessScaleDialog()" matTooltip="Schließen">
                close
            </mat-icon>

            <h2 class="text-align-center">{{ selectedPaintThicknessScale?.name || 'Skala' }} bearbeiten</h2>

            <div class="scale-name-wrapper">
                <mat-form-field class="mat-block scale-name-input light-mode">
                    <input
                        matInput
                        placeholder="Name"
                        [(ngModel)]="selectedPaintThicknessScale.name"
                        (change)="emitDataChange()"
                        [disabled]="disabled" />
                </mat-form-field>
            </div>

            <h3>Schwellenwerte</h3>

            <paint-thickness-scale-selector
                [hideScaleDropdown]="true"
                [allowEditing]="true"
                [paintThicknessScale]="selectedPaintThicknessScale"
                (scaleWasEdited)="handleScaleChange(); emitDataChange()"></paint-thickness-scale-selector>

            <div class="dialog-buttons-container">
                <button (click)="closePaintThicknessScaleDialog()">Bearbeitung beenden</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Right Column - Editor -->
        <!--============================================-->
    </div>
</div>

import { DateTime } from 'luxon';
import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { User } from '../user/user';

export class DowntimeNotification implements DataTypeBase {
    constructor(template: RequireSome<DowntimeNotification, 'affectedUserGroup'>) {
        Object.assign(this, template);
    }
    _id: string = generateId();
    affectedUserGroup: AffectedUserGroup;
    type: 'manual' | 'dat-generated' = 'manual';
    title: string; // Internal use only
    message: string; // HTML Content
    validFrom: string;
    validUntil: string;
    createdBy: User['_id'];
    numberOfDaysVisibleInAdvance: number;

    // Display error messages in the application next to the action, e. g. VIN errors next to the VIN input field.
    showInContexts: ProcessContext[] = [];

    // Show the DAT product name in the UI - this is helpful for the user
    datProductName: string;
    // The key is needed to match the downtime notification to incoming DAT status updates
    datServiceKey: string;

    // Notify the autoiXpert team in Slack if a downtime notification is created automatically
    // Keep the reference to close the thread if the downtime notification is deleted
    slackMessageId: string;
    // We need the id of the slack channel (using the channel name linke 'server-alerts' does not work)
    slackChannelId: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

export const processContexts = ['vinQuery', 'calculation', 'valuation', 'rentalCarPrices'] as const;
export type ProcessContext = (typeof processContexts)[number];

/**
 * Display downtime notifications from external partners only to users that are customers of the external service.
 */
export type AffectedUserGroup =
    | 'all'
    | 'dat'
    | 'qapter'
    | 'autoonline'
    | 'gtmotive'
    | 'cartv'
    | 'winvalue'
    | 'carcasion'
    | 'gdv'
    | 'autoixpert'
    | 'qapterixpert';

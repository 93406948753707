<!--============================================-->
<!-- Template Selection -->
<!--============================================-->
<section id="lease-return-templates-section" *ngIf="areLeaseReturnTemplatesVisible">
    <div id="lease-return-templates-card" class="card card-with-padding">
        <h2>Prüfleitfaden wählen</h2>

        <ng-container *ngIf="!leaseReturnTemplatesPending && leaseReturnTemplates.length">
            <div id="lease-return-templates-list" class="margin-bottom-15">
                <div
                    class="large-selection-box"
                    *ngFor="let leaseReturnTemplate of leaseReturnTemplates"
                    (click)="selectLeaseReturnTemplate(leaseReturnTemplate)"
                    (dblclick)="useLeaseReturnTemplate(leaseReturnTemplate); hideLeaseReturnTemplates()"
                    [ngClass]="{ selected: selectedLeaseReturnTemplate === leaseReturnTemplate }">
                    <div class="lease-return-template-title">
                        {{ leaseReturnTemplate.title }}
                    </div>
                    <div class="large-selection-box-active-marker">
                        <mat-icon>check</mat-icon>
                    </div>
                </div>
            </div>
            <div id="lease-return-templates-buttons-container" class="text-align-center">
                <button
                    class="margin-bottom-10"
                    [disabled]="isReportLocked()"
                    (click)="useLeaseReturnTemplate(selectedLeaseReturnTemplate); hideLeaseReturnTemplates()"
                    [ngClass]="{ disabled: !selectedLeaseReturnTemplate || isReportLocked() }">
                    Übernehmen
                </button>
                <div>
                    <span class="link" (click)="hideLeaseReturnTemplates(); openTemplateManagement()">
                        Prüfleitfaden-Vorlagen
                    </span>
                </div>
            </div>
        </ng-container>

        <!--********** No Templates **********-->
        <div
            id="no-lease-return-templates-placeholder"
            class="text-align-center"
            *ngIf="!leaseReturnTemplatesPending && !leaseReturnTemplates.length">
            <h3>Keine Prüfleitfäden</h3>
            <p>Lege selbst fest, welche Positionen für einen Kunden geprüft werden sollen.</p>
            <button (click)="hideLeaseReturnTemplates(); openTemplateManagement()">Prüfleitfäden bearbeiten</button>
        </div>

        <!--********** Pending **********-->
        <div id="lease-return-templates-pending" class="text-align-center" *ngIf="leaseReturnTemplatesPending">
            <img class="margin-bottom-10" src="/assets/images/icons/loading-indicator.svg" alt="" />
            <h3>Lade Prüfleitfäden...</h3>
        </div>
    </div>
</section>
<!--============================================-->
<!-- END Template Selection -->
<!--============================================-->

<!--============================================-->
<!-- Item List & Editor -->
<!--============================================-->
<div
    id="item-list-and-editor-container"
    *ngIf="!areLeaseReturnTemplatesVisible && report?.leaseReturn && !templateManagementVisible"
    @blockChildAnimationOnLoad>
    <!--============================================-->
    <!-- List Column -->
    <!--============================================-->
    <div id="list-column">
        <!--============================================-->
        <!-- Item List Header -->
        <!--============================================-->
        <div id="lease-return-title-container" class="card icon-hidden-until-hovered-parent">
            <!--********** Title **********-->
            <!--********** The default "Zustandsbericht" exists in the placeholder values, too. If changing this, default change the default in the placeholder values as well. **********-->
            <h2
                class="no-margin text-overflow-ellipsis"
                matTooltip="Der Titel bleibt intern und wird nicht abgedruckt.">
                {{ report.leaseReturn.title || 'Mein Prüfleitfaden' }}
            </h2>

            <!--============================================-->
            <!-- Relative Residual Value -->
            <!--============================================-->
            <div id="time-value-wrapper" *ngIf="report.type === 'leaseReturn'">
                <div id="time-value-container" *ngIf="!relativeResidualValueInEditMode">
                    <span
                        class="cursor-pointer"
                        (click)="enterRelativeResidualValueEditMode()"
                        [class.disabled]="isReportLocked()"
                        matTooltip="Zeitwert des Fahrzeugs.{{
                            '\n\n'
                        }}Der Minderwert kann mit diesem Faktor von den Reparaturkosten abgeleitet werden. Wird automatisch vorbefüllt, falls Neupreis & Zeitwert bekannt sind.">
                        {{ report.leaseReturn.relativeResidualValue * 100 | number: '1.0-2' }} %
                    </span>
                    <mat-icon
                        id="time-value-edit-icon"
                        class="toolbar-icon small-icon icon-hidden-until-hovered"
                        *ngIf="!isReportLocked()"
                        (click)="enterRelativeResidualValueEditMode()">
                        edit
                    </mat-icon>
                    <mat-icon
                        class="toolbar-icon small-icon icon-hidden-until-hovered"
                        *ngIf="!isReportLocked()"
                        (click)="updateRelativeResidualValue()"
                        matTooltip="Neu berechnen">
                        refresh
                    </mat-icon>
                </div>
                <mat-form-field id="time-value-form-field" class="mat-block" *ngIf="relativeResidualValueInEditMode">
                    <input
                        matInput
                        number-input
                        placeholder="Zeitwert"
                        [(number)]="relativeResidualValue"
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="2"
                        (numberChange)="storeRelativeResidualValueOnReport(); saveReport()"
                        matTooltip="Zeitwert des Fahrzeugs.{{
                            '\n\n'
                        }}Der Minderwert kann mit diesem Faktor von den Reparaturkosten abgeleitet werden. Wird automatisch vorbefüllt, falls Neupreis & Zeitwert bekannt sind." />
                    <span matSuffix>
                        %
                        <mat-icon
                            class="input-toolbar-icon"
                            (click)="leaveRelativeResidualValueEditMode()"
                            matTooltip="Bearbeitung beenden">
                            check
                        </mat-icon>
                    </span>
                </mat-form-field>
            </div>
            <!--============================================-->
            <!-- END Relative Residual Value -->
            <!--============================================-->

            <!--********** Toolbar **********-->
            <div class="horizontal-toolbar">
                <!--********** Create New Section **********-->
                <mat-icon
                    class="toolbar-icon"
                    [class.disabled]="isReportLocked()"
                    (click)="createSectionInReport(); saveReport()"
                    matTooltip="Neuer Abschnitt">
                    add
                </mat-icon>

                <!--********** Collapse All Sections **********-->
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="!sectionsWithItemsCollapsed.size"
                    (click)="collapseAllSections()"
                    matTooltip="Alle Abschnitte zuklappen">
                    unfold_less
                </mat-icon>

                <!--********** Expand All Sections **********-->
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="sectionsWithItemsCollapsed.size > 0"
                    (click)="expandAllSections()"
                    matTooltip="Alle Abschnitte aufklappen">
                    unfold_more
                </mat-icon>

                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="headRunnerSubmenu">more_vert</mat-icon>
                <mat-menu #headRunnerSubmenu>
                    <a
                        *ngIf="!isReportLocked()"
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="showLeaseReturnTemplates()">
                        <mat-icon>swap_horiz</mat-icon>
                        Anderen Prüfleitfaden auswählen
                    </a>
                    <a mat-menu-item class="menu-item-with-icon" (click)="saveLeaseReturnAsTemplate()">
                        <mat-icon>check</mat-icon>
                        Als Vorlage speichern
                    </a>
                    <a mat-menu-item class="menu-item-with-icon" (click)="openTemplateManagement()">
                        <mat-icon>edit</mat-icon>
                        Prüfleitfaden-Vorlagen
                    </a>
                </mat-menu>
            </div>
        </div>
        <!--============================================-->
        <!-- END Item List Header -->
        <!--============================================-->

        <!--============================================-->
        <!-- Item List -->
        <!--============================================-->
        <div
            id="section-list"
            cdkDropList
            cdkDropListLockAxis="y"
            [cdkDropListDisabled]="isReportLocked()"
            (cdkDropListDropped)="handleSectionDrop($event); saveReport()">
            <section class="lease-return-section card" *ngFor="let section of report.leaseReturn.sections" cdkDrag>
                <!--********** Section Header **********-->
                <div class="section-header" (dblclick)="toggleSectionItemsExpandedOnDoubleClick(section, $event)">
                    <mat-icon class="drag-handle medium-icon" cdkDragHandle>drag_indicator</mat-icon>
                    <!--============================================-->
                    <!-- Section Title -->
                    <!--============================================-->
                    <div class="section-title-container edit-title-icon-parent">
                        <!--********** Read Mode **********-->
                        <ng-container *ngIf="!sectionsInEditMode.has(section)">
                            <h2 class="no-margin text-overflow-ellipsis" (dblclick)="enterEditModeForSection(section)">
                                {{ section.title || 'Neuer Abschnitt' }}
                            </h2>
                            <mat-icon
                                class="edit-title-icon small-icon toolbar-icon"
                                *ngIf="!isReportLocked()"
                                matTooltip="Titel bearbeiten"
                                (click)="enterEditModeForSection(section)">
                                edit
                            </mat-icon>
                            <div class="number-of-remaining-required-items" matTooltip="Offene Pflichtpositionen">
                                {{ getRemainingRequiredItems(section).length }}
                            </div>
                            <div
                                class="number-of-collapsed-items label"
                                *ngIf="sectionsWithItemsCollapsed.has(section) && section.items.length">
                                <span
                                    class="link"
                                    (click)="expandSection(section)"
                                    matTooltip="Klicken, um die Positionen einzublenden">
                                    {{ section.items.length }}
                                    {{ section.items.length === 1 ? 'Position' : 'Positionen' }}
                                </span>
                            </div>
                        </ng-container>

                        <!--********** Edit Mode **********-->
                        <mat-form-field
                            class="mat-block section-title-form-field"
                            floatLabel="never"
                            *ngIf="sectionsInEditMode.has(section)">
                            <input
                                matInput
                                placeholder="Titel"
                                [(ngModel)]="section.title"
                                (change)="saveReport()"
                                (keydown)="leaveEditModeForSectionOnEnter($event, section)"
                                [axAutofocus]="true" />
                            <mat-icon
                                matSuffix
                                class="input-toolbar-icon"
                                (click)="leaveEditModeForSection(section)"
                                matTooltip="Bearbeitung beenden">
                                check
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <!--============================================-->
                    <!-- END Section Title -->
                    <!--============================================-->

                    <div class="section-toolbar">
                        <mat-icon
                            class="toolbar-icon"
                            [class.disabled]="isReportLocked()"
                            (click)="createItemInReport(section); saveReport(); expandAllSections()"
                            matTooltip="Neue Prüfposition">
                            add
                        </mat-icon>
                        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="sectionSubmenu">more_vert</mat-icon>
                        <mat-menu #sectionSubmenu>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="!sectionsWithItemsCollapsed.has(section)"
                                (click)="collapseSection(section)">
                                <mat-icon>unfold_less</mat-icon>
                                Abschnitt zuklappen
                            </a>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="sectionsWithItemsCollapsed.has(section)"
                                (click)="expandSection(section)">
                                <mat-icon>unfold_more</mat-icon>
                                Abschnitt aufklappen
                            </a>
                            <a
                                mat-menu-item
                                [class.disabled]="isReportLocked()"
                                class="menu-item-with-icon"
                                (click)="deleteSection(section); saveReport()">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </a>
                        </mat-menu>
                    </div>
                </div>

                <!--********** Items **********-->
                <div
                    class="lease-return-item-list"
                    cdkDropList
                    [cdkDropListDisabled]="isReportLocked()"
                    cdkDropListLockAxis="y"
                    (cdkDropListDropped)="handleItemDrop(section, $event); saveReport()"
                    *ngIf="!sectionsWithItemsCollapsed.has(section)">
                    <div
                        class="lease-return-item record"
                        *ngFor="let item of section.items"
                        [ngClass]="{ active: selectedItem === item, complete: isItemComplete(item) }"
                        (click)="selectItem(item)"
                        cdkDrag>
                        <mat-icon class="drag-handle medium-icon" cdkDragHandle>drag_indicator</mat-icon>
                        <div
                            class="item-title edit-title-icon-parent"
                            *ngIf="!itemsInEditMode.has(item)"
                            (dblclick)="enterEditModeForItem(item)">
                            <span>{{ item.title || NEW_ITEM_TITLE }}</span>
                            <mat-icon
                                class="edit-title-icon small-icon toolbar-icon"
                                matTooltip="Titel bearbeiten"
                                *ngIf="!isReportLocked()"
                                (click)="enterEditModeForItem(item)">
                                edit
                            </mat-icon>
                            <mat-icon
                                class="required-position-icon active small-icon toolbar-icon"
                                *ngIf="item.isRequired"
                                matTooltip="Pflichtposition">
                                star
                            </mat-icon>
                            <div
                                class="neutral-taxation-status-icon"
                                *ngIf="item.isVatNeutral"
                                matTooltip="Diese Prüfposition wird als steuerneutral behandelt.">
                                0%
                            </div>
                        </div>
                        <div class="item-title" *ngIf="itemsInEditMode.has(item)">
                            <mat-form-field class="mat-block" floatLabel="never">
                                <input
                                    matInput
                                    placeholder="Titel"
                                    [(ngModel)]="item.title"
                                    (change)="saveReport()"
                                    (keydown)="leaveEditModeOnEnter($event, item)"
                                    [axAutofocus]="true" />
                                <mat-icon
                                    matSuffix
                                    class="input-toolbar-icon"
                                    (click)="leaveEditModeForItem(item)"
                                    matTooltip="Bearbeitung beenden">
                                    check
                                </mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="item-status-and-toolbar">
                            <!--********** Number of Photos **********-->
                            <div class="number-of-photos" matTooltip="Anzahl Fotos" *ngIf="item.photoIds.length">
                                <mat-icon class="number-of-photos-icon medium-icon">camera_alt</mat-icon>
                                <span>{{ item.photoIds.length }}</span>
                            </div>

                            <!--********** Comment **********-->
                            <mat-icon
                                class="toolbar-icon"
                                *ngIf="item.comment"
                                [matTooltip]="convertHtmlToPlainText(item.comment)">
                                notes
                            </mat-icon>
                            <div class="toolbar-icon-placeholder" *ngIf="!item.comment"></div>

                            <!--********** Submenu **********-->
                            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="itemSubmenu">more_vert</mat-icon>
                            <mat-menu #itemSubmenu>
                                <a
                                    mat-menu-item
                                    [class.disabled]="isReportLocked()"
                                    class="menu-item-with-icon"
                                    (click)="deleteItem(item, section); saveReport()">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!--============================================-->
        <!-- END Item List -->
        <!--============================================-->

        <!--============================================-->
        <!-- Summary -->
        <!--============================================-->
        <div
            id="summary"
            class="card dark-card"
            *ngIf="totalRepairCostsNet || totalAboveAverageWearCostsNet"
            [ngClass]="{ pinned: !!userPreferences.isLeaseReturnSummaryPinned }">
            <!--********** Repair Costs **********-->
            <div class="summary-costs-container">
                <div class="summary-label">Reparatur</div>
                <div class="summary-value">{{ totalRepairCostsNet | number: '1.2-2' }} €</div>
            </div>

            <!--********** Diminished Value **********-->
            <div class="summary-costs-container" *ngIf="report.type === 'leaseReturn'">
                <div class="summary-label">Minderwert</div>
                <div class="summary-value">{{ totalAboveAverageWearCostsNet | number: '1.2-2' }} €</div>
            </div>

            <!--********** Bar Graph - Lease Return **********-->
            <div class="summary-graph" *ngIf="report.type === 'leaseReturn'">
                <div class="summary-graph-bar" matTooltip="Verhältnis Minderwert zu Reparaturkosten">
                    <div
                        class="summary-graph-bar-fill"
                        [style.width]="(totalAboveAverageWearCostsNet / totalRepairCostsNet) * 100 + '%'"></div>
                    <div class="summary-graph-percentage">
                        {{ (totalAboveAverageWearCostsNet / totalRepairCostsNet) * 100 | number: '1.0-0' }} %
                    </div>
                </div>
            </div>

            <!--********** Bar Graph - Used Vehicle Check **********-->
            <div class="summary-graph" *ngIf="report.type === 'usedVehicleCheck' && report.valuation.vehicleValueNet">
                <div class="summary-graph-bar" matTooltip="Verhältnis Reparaturkosten zu Fahrzeugwert">
                    <div
                        class="summary-graph-bar-fill"
                        [style.width]="
                            ((totalRepairCostsNet * 1.19) / report.valuation.vehicleValueGross) * 100 + '%'
                        "></div>
                    <div class="summary-graph-percentage">
                        {{
                            ((totalRepairCostsNet * 1.19) / report.valuation.vehicleValueGross) * 100 | number: '1.0-0'
                        }}
                        %
                    </div>
                </div>
            </div>

            <mat-icon
                [ngClass]="{ active: !!userPreferences.isLeaseReturnSummaryPinned }"
                (click)="userPreferences.isLeaseReturnSummaryPinned = !userPreferences.isLeaseReturnSummaryPinned"
                matTooltip="Zusammenfassung {{ userPreferences.isLeaseReturnSummaryPinned ? 'loslösen' : 'anpinnen' }}">
                push_pin
            </mat-icon>
        </div>
        <!--============================================-->
        <!-- END Summary -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END List Column -->
    <!--============================================-->

    <!--********** Required because assigning margins to the columns doesn't work. **********-->
    <div id="column-spacer"></div>

    <!--============================================-->
    <!-- Editor Column -->
    <!--============================================-->
    <div id="editor-column">
        <div *ngIf="selectedItem" @fadeInAndOut>
            <!--============================================-->
            <!-- Editor -->
            <!--============================================-->
            <div id="editor" class="card card-with-padding" *ngIf="selectedItem">
                <!--********** Name of selected item **********-->
                <div id="editor-heading-container">
                    <h2 id="editor-heading">
                        <mat-icon
                            id="select-previous-item-icon"
                            class="toolbar-icon"
                            (click)="selectNextLeaseReturnItem('previous')"
                            matTooltip="Vorheriges Element auswählen">
                            keyboard_arrow_left
                        </mat-icon>
                        {{ selectedItem.title || NEW_ITEM_TITLE }}
                        <mat-icon
                            id="select-next-item-icon"
                            class="toolbar-icon"
                            (click)="selectNextLeaseReturnItem('next')"
                            matTooltip="Vorheriges Element auswählen">
                            keyboard_arrow_right
                        </mat-icon>
                    </h2>
                </div>

                <!--============================================-->
                <!-- Editor Inputs -->
                <!--============================================-->
                <section id="editor-inputs-section">
                    <!--********** Repair Costs Icon **********-->
                    <div id="repair-costs-icon-container" class="editor-cost-type-icon-container">
                        <mat-icon class="editor-cost-type-icon">build</mat-icon>
                    </div>

                    <!--********** Repair Costs Input **********-->
                    <div class="position-relative">
                        <!--********** Net **********-->
                        <currency-input
                            *ngIf="report.leaseReturn.leaseReturnItemTaxationType === 'net'"
                            placeholder="Reparaturkosten (netto)"
                            [(value)]="selectedItem.repairCostsNet"
                            (valueChange)="
                                calculateRepairCostsGross();
                                saveReport();
                                calculateSummaryValues();
                                drawLeaseReturnSummaryGraph()
                            "
                            [lightMode]="true"
                            matTooltip="Reparaturkosten (netto)"
                            [disabled]="isReportLocked()"></currency-input>

                        <!--********** Gross **********-->
                        <currency-input
                            *ngIf="report.leaseReturn.leaseReturnItemTaxationType === 'gross'"
                            placeholder="Reparaturkosten (brutto)"
                            [(value)]="selectedItem.repairCostsGross"
                            (valueChange)="
                                calculateRepairCostsNet();
                                saveReport();
                                calculateSummaryValues();
                                drawLeaseReturnSummaryGraph()
                            "
                            [lightMode]="true"
                            matTooltip="Reparaturkosten (brutto)"
                            [disabled]="isReportLocked()"></currency-input>
                        <mat-icon id="tax-setting-icon" class="toolbar-icon" [matMenuTriggerFor]="taxSettingMenu">
                            more_vert
                        </mat-icon>
                        <mat-menu #taxSettingMenu>
                            <a mat-menu-item [mat-menu-trigger-for]="taxSettingSubmenu">Eingabemodus</a>
                            <a
                                mat-menu-item
                                [class.disabled]="isReportLocked()"
                                (click)="toggleLeaseReturnItemAsVatNeutral(selectedItem); calculateSummaryValues()">
                                {{
                                    selectedItem.isVatNeutral
                                        ? 'Prüfposition ist regelbesteuert'
                                        : 'Prüfposition ist steuerneutral'
                                }}
                            </a>
                        </mat-menu>
                        <mat-menu #taxSettingSubmenu>
                            <a
                                mat-menu-item
                                class="taxation-label"
                                [ngClass]="{ active: report.leaseReturn.leaseReturnItemTaxationType === 'gross' }"
                                (click)="setLeaseReturnTaxationType('gross'); calculateSummaryValues()"
                                matTooltip="Brutto-Reparaturkosten eingeben">
                                Brutto (inkl. 19 %)
                            </a>
                            <a
                                mat-menu-item
                                class="taxation-label"
                                [ngClass]="{ active: report.leaseReturn.leaseReturnItemTaxationType === 'net' }"
                                (click)="setLeaseReturnTaxationType('net'); calculateSummaryValues()"
                                matTooltip="Netto-Reparaturkosten eingeben">
                                Netto (ohne 19 %)
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Editor Inputs Toolbar **********-->
                    <div id="editor-inputs-toolbar" *ngIf="report.type === 'leaseReturn'">
                        <!--********** DAT **********-->
                        <!--                    <img id="dat-damage-calculation-icon" class="toolbar-icon" src="/assets/images/logos/dat-logo-no-text.png" (click)="openDatDamageCalculation()"-->
                        <!--                         matTooltip="Teilepreis in DAT auswählen">-->

                        <!--********** Copy Value **********-->
                        <mat-icon
                            id="copy-value-icon"
                            class="toolbar-icon"
                            [class.disabled]="isReportLocked()"
                            (click)="
                                setDiminishedValue(
                                    report.leaseReturn.leaseReturnItemTaxationType === 'net'
                                        ? selectedItem.repairCostsNet
                                        : selectedItem.repairCostsGross
                                );
                                saveReport();
                                calculateSummaryValues();
                                drawLeaseReturnSummaryGraph()
                            "
                            matTooltip="Vollen Wert übernehmen">
                            reply
                        </mat-icon>

                        <!--********** Percentage **********-->
                        <div
                            id="copy-percentage-icon"
                            class="toolbar-icon"
                            [class.disabled]="isReportLocked()"
                            (click)="
                                setDiminishedValue(
                                    (report.leaseReturn.leaseReturnItemTaxationType === 'net'
                                        ? selectedItem.repairCostsNet
                                        : selectedItem.repairCostsGross) * report.leaseReturn.relativeResidualValue
                                );
                                saveReport();
                                calculateSummaryValues();
                                drawLeaseReturnSummaryGraph()
                            "
                            matTooltip="Abschlag ({{
                                report.leaseReturn.relativeResidualValue * 100 | number
                            }} %) von den Reparaturkosten verwenden">
                            %
                        </div>

                        <!--********** No Value **********-->
                        <mat-icon
                            class="toolbar-icon"
                            [class.disabled]="isReportLocked()"
                            (click)="
                                setDiminishedValue(0);
                                saveReport();
                                calculateSummaryValues();
                                drawLeaseReturnSummaryGraph()
                            "
                            matTooltip="Minderwert auf 0 € setzen">
                            remove_circle
                        </mat-icon>
                    </div>

                    <!--********** Diminished Value **********-->
                    <!--********** Netto **********-->
                    <currency-input
                        placeholder="Minderwert"
                        [(value)]="selectedItem.aboveAverageWearCostsNet"
                        *ngIf="
                            report.type === 'leaseReturn' && report.leaseReturn.leaseReturnItemTaxationType === 'net'
                        "
                        [baseValue]="selectedItem.repairCostsNet"
                        (valueChange)="
                            calculateDiminishedValueGross();
                            saveReport();
                            calculateSummaryValues();
                            drawLeaseReturnSummaryGraph()
                        "
                        [lightMode]="true"
                        matTooltip="Vorbelegter Betrag entspricht den Reparaturkosten (netto) multipliziert mit dem Zeitwert."
                        [disabled]="isReportLocked()"></currency-input>

                    <!--********** Brutto **********-->
                    <currency-input
                        placeholder="Minderwert"
                        [(value)]="selectedItem.aboveAverageWearCostsGross"
                        *ngIf="
                            report.type === 'leaseReturn' && report.leaseReturn.leaseReturnItemTaxationType === 'gross'
                        "
                        [baseValue]="selectedItem.repairCostsGross"
                        (valueChange)="
                            calculateDiminishedValueNet();
                            saveReport();
                            calculateSummaryValues();
                            drawLeaseReturnSummaryGraph()
                        "
                        [lightMode]="true"
                        matTooltip="Vorbelegter Betrag entspricht den Reparaturkosten (brutto) multipliziert mit dem Zeitwert."
                        [disabled]="isReportLocked()"></currency-input>

                    <!--********** Above Average Costs Icon **********-->
                    <div id="above-average-wear-costs-icon-container" class="editor-cost-type-icon-container">
                        <mat-icon class="editor-cost-type-icon" *ngIf="report.type === 'leaseReturn'">
                            trending_down
                        </mat-icon>
                        <mat-icon class="editor-cost-type-icon" *ngIf="report.type === 'usedVehicleCheck'">
                            build
                        </mat-icon>
                    </div>
                </section>
                <!--============================================-->
                <!-- END Editor Inputs -->
                <!--============================================-->

                <!--********** Description **********-->
                <mat-form-field class="mat-block">
                    <mat-quill
                        placeholder="Beschreibung"
                        [(ngModel)]="selectedItem.comment"
                        (change)="saveReport()"
                        [disabled]="isReportLocked()"
                        #descriptionQuill></mat-quill>
                    <mat-icon
                        matSuffix
                        *ngIf="templateItemAssociatedWithSelectedItem"
                        class="input-toolbar-icon"
                        (click)="showTextTemplatesDialog()"
                        matTooltip="Textvorlagen">
                        playlist_add
                    </mat-icon>
                </mat-form-field>

                <!--********** Quick Templates **********-->
                <section id="quick-templates-section">
                    <!--********** Default Repair Options **********-->
                    <div id="repair-options-list">
                        <!--********** Everything OK **********-->
                        <div
                            class="text-template-item default-option"
                            [class.disabled]="isReportLocked()"
                            (click)="appendTextToDescription('<p>in Ordnung</p>'); saveReport()">
                            <mat-icon class="repair-option-icon">check</mat-icon>
                            <span class="repair-option-label" matTooltip="in Ordnung">In Ordnung</span>
                        </div>

                        <!--********** Signs of Wear **********-->
                        <div
                            class="text-template-item default-option"
                            [class.disabled]="isReportLocked()"
                            (click)="appendTextToDescription('<p>Gebrauchsspuren</p>'); saveReport()">
                            <mat-icon class="repair-option-icon">fingerprint</mat-icon>
                            <span class="repair-option-label" matTooltip="Gebrauchsspuren">Gebrauchsspuren</span>
                        </div>

                        <!--********** Smart Repair **********-->
                        <div
                            class="text-template-item default-option"
                            [class.disabled]="isReportLocked()"
                            (click)="appendTextToDescription('<p>Smart Repair</p>'); saveReport()">
                            <mat-icon class="repair-option-icon">photo_filter</mat-icon>
                            <span class="repair-option-label">Smart Repair</span>
                        </div>

                        <!--********** Replace **********-->
                        <div
                            class="text-template-item default-option"
                            [class.disabled]="isReportLocked()"
                            (click)="appendTextToDescription('<p>ersetzen</p>'); saveReport()">
                            <mat-icon class="repair-option-icon">cached</mat-icon>
                            <span class="repair-option-label">Ersetzen</span>
                        </div>

                        <!--********** Lackieren **********-->
                        <div
                            class="text-template-item default-option"
                            [class.disabled]="isReportLocked()"
                            (click)="appendTextToDescription('<p>lackieren</p>'); saveReport()">
                            <mat-icon class="repair-option-icon">color_lens</mat-icon>
                            <span class="repair-option-label">Lackieren</span>
                        </div>

                        <!--********** Repair **********-->
                        <div
                            class="text-template-item default-option"
                            [class.disabled]="isReportLocked()"
                            (click)="appendTextToDescription('<p>instandsetzen</p>'); saveReport()">
                            <mat-icon class="repair-option-icon">build</mat-icon>
                            <span class="repair-option-label">Instandsetzen</span>
                        </div>

                        <!--********** Custom Entries **********-->
                        <div
                            class="text-template-item custom-option"
                            *ngFor="let textTemplate of templateItemAssociatedWithSelectedItem?.commentTemplates">
                            <div
                                class="text-template-item-label text-overflow-ellipsis"
                                (click)="appendTextToDescription(textTemplate.body); saveReport()"
                                [matTooltip]="convertHtmlToPlainText(textTemplate.body)"
                                [quillContent]="textTemplate.body"></div>
                            <mat-icon
                                class="text-template-edit-icon small-icon toolbar-icon"
                                (click)="showTextTemplatesDialog()"
                                matTooltip="Vorlagen bearbeiten">
                                edit
                            </mat-icon>
                        </div>

                        <!--********** Create new entry **********-->
                        <div
                            id="create-new-text-template"
                            class="text-template-item"
                            (click)="showTextTemplatesDialog()"
                            *ngIf="templateItemAssociatedWithSelectedItem?.commentTemplates">
                            + Neue Vorlage
                        </div>
                    </div>
                </section>
            </div>
            <!--============================================-->
            <!-- END Editor -->
            <!--============================================-->

            <!--============================================-->
            <!-- Photos -->
            <!--============================================-->
            <photo-grid
                *ngIf="report"
                [report]="report"
                [photos]="photosOfSelectedItem"
                [floatingActionButtonShown]="photosOfSelectedItem.length > 0"
                [disableKeyboardShortcuts]="photoEditorShown"
                (photoChange)="syncItemPhotosWithReportPhotos($event.photos); saveReport()"
                (photoDeletion)="removePhotosFromReportPhotos($event)"
                (openPhotoEditor)="handleEditorOpenClick($event); openPhotoEditor()"
                [disabled]="isReportLocked()"></photo-grid>

            <!--============================================-->
            <!-- END Photos -->
            <!--============================================-->
        </div>

        <!--============================================-->
        <!-- Placeholder: No Selected Item -->
        <!--============================================-->
        <div class="card card-with-padding" *ngIf="!selectedItem" @fadeInAndOut>
            <div id="placeholder-no-item-selected" class="text-align-center">
                <img src="/assets/images/icons/no-data-to-display.png" alt="" />
                <h3>Wähle eine Prüfposition</h3>
                <p>oder erstelle über die Plus-Symbole eine neue.</p>
            </div>
        </div>
        <!--============================================-->
        <!-- END Placeholder: No Selected Item -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Editor Column -->
    <!--============================================-->
</div>

<!-- Photo editor belongs to #editor-column but needs to be outside of the #editor-column element.
     This is because the editor (positioned absolute + z-index higher than process bar)
     needs to be above the process bar (positioned sticky). This works as long as the process bar
     and editor are within the same stacking context, but the editor-column creates a new stacking
     context, so we move photo-editor outside of it. -->
<photo-editor
    *ngIf="photoEditorShown"
    [report]="report"
    [initialPhoto]="initialPhotoForEditor"
    photoVersion="report"
    (change)="saveReport()"
    (close)="closeEditor()"
    [disabled]="isReportLocked()"
    disabledMessage="Entsperre das Gutachten, bevor du Fotos bearbeiten kannst."></photo-editor>

<!--============================================-->
<!-- END Item List & Editor -->
<!--============================================-->

<!--============================================-->
<!-- Text Template Dialog -->
<!--============================================-->
<lease-return-text-template-dialog
    *ngIf="textTemplateDialogShown"
    [leaseReturnTemplateFromParent]="associatedLeaseReturnTemplate"
    [textTemplates]="templateItemAssociatedWithSelectedItem.commentTemplates"
    [textTemplateTitle]="selectedItem.title"
    (close)="hideTextTemplatesDialog()"></lease-return-text-template-dialog>
<!--============================================-->
<!-- END Text Template Dialog -->
<!--============================================-->

<!--============================================-->
<!-- Template Management -->
<!--============================================-->
<lease-return-template-management
    *ngIf="templateManagementVisible"
    [report]="report"
    (close)="closeTemplateManagement(); showLeaseReturnTemplatesIfEmpty()"></lease-return-template-management>
<!--============================================-->
<!-- END Template Management -->
<!--============================================-->

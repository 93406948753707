import { Team } from '@autoixpert/models/teams/team';
import { OriginX, OriginY } from '../../../types/fabric-type-extensions';

export function translateAnchorToOrigin(anchor: Team['preferences']['watermark']['anchor']): {
    originX: OriginX;
    originY: OriginY;
} {
    let originX: OriginX;
    let originY: OriginY;

    switch (anchor) {
        case 'topLeft':
            originX = 'left';
            originY = 'top';
            break;
        case 'topRight':
            originX = 'right';
            originY = 'top';
            break;
        case 'center':
            originX = 'center';
            originY = 'center';
            break;
        case 'bottomLeft':
            originX = 'left';
            originY = 'bottom';
            break;
        case 'bottomRight':
            originX = 'right';
            originY = 'bottom';
            break;
    }

    return {
        originX,
        originY,
    };
}

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { User } from '@autoixpert/models/user/user';

@Component({
    selector: 'cost-center-popover',
    templateUrl: './cost-center-popover.component.html',
    styleUrls: ['./cost-center-popover.component.scss'],
})
export class CostCenterPopoverComponent {
    @Input() teamMember: User;
    @Output() userChange: EventEmitter<void> = new EventEmitter();
    @ViewChild('costCenterInput', { static: false }) costCenterInput: ElementRef;

    /**
     * Focus the cost center input, even if angular recycled the component.
     */
    ngAfterViewInit() {
        this.costCenterInput.nativeElement.focus();
    }

    public focus() {}

    public emitChange() {
        this.userChange.emit();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, interval } from 'rxjs';
import { filter, skip, take } from 'rxjs/operators';
import { AxError } from '@autoixpert/models/errors/ax-error';

@Injectable({
    providedIn: 'root',
})
export class NetworkStatusService {
    //*****************************************************************************
    //  Network Events
    //****************************************************************************/
    public networkStatusChange$: BehaviorSubject<NetworkStatus> = new BehaviorSubject<NetworkStatus>({
        status: 'online',
    });
    /**
     * May be used to trigger syncs to the autoiXpert backend.
     */
    public networkBackOnline$: Subject<NetworkStatus> = new Subject();
    /**
     * May be used so that components may react to the user going offline, e.g. by showing him a message.
     */
    public networkWentOffline$: Subject<NetworkStatus> = new Subject();
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Network Events
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * If the client is offline, this subject propagates the number of seconds
     */
    public secondsUntilOnlineDetection$: BehaviorSubject<number> = new BehaviorSubject(0);
    /**
     * Contains a reference to the subscription of the countdown timer until the next network detection is run.
     */
    private networkDetectionTimerSubscription: Subscription | null = null;

    /**
     * Defined while the network status is being detected. This prevents multiple network detections to be started in case multiple requests fail due to a missing Internet connection.
     */
    private networkStatusDetectionPromise: Promise<NetworkStatus>;

    //*****************************************************************************
    //  Constructor
    //****************************************************************************/
    constructor(private httpClient: HttpClient) {
        /**
         * Notify database services as soon as the user has a connection to the autoiXpert servers.
         */
        this.networkBackOnline$ = this.networkStatusChange$.pipe(
            // Skip the initial value which is the BehaviorSubject's first online status.
            skip(1),
            filter((networkStatus) => networkStatus.status === 'online'),
        ) as Subject<NetworkStatus>;

        this.networkWentOffline$ = this.networkStatusChange$.pipe(
            // Skip the initial value which is the BehaviorSubject's first online status.
            skip(1),
            filter((networkStatus) => networkStatus.status === 'offline'),
        ) as Subject<NetworkStatus>;

        this.networkBackOnline$.subscribe(() => {
            this.stopNetworkDetectionTimer();
        });
        /**
         * Try to connect to the autoiXpert backend after a while to find out whether this device is back online.
         */
        this.networkWentOffline$.subscribe(() => {
            this.startNetworkDetectionTimer();
        });

        /**
         * When the browser thinks we got back online, try to see if we can reach the autoiXpert servers.
         */
        window.addEventListener('online', () => {
            this.detectNetworkStatus();
        });
        /**
         * When the browser thinks we went offline, mark this device as offline.
         */
        window.addEventListener('offline', () => {
            this.detectNetworkStatus();
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Constructor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Detect General Network Status (aX Backend & Internet)
    //****************************************************************************/
    /**
     * Ensure that the network detection is triggered only once in parallel. If multiple requests run in parallel and fail for the same reason, there is no need
     * for one network detection for each failed request.
     */
    public async detectNetworkStatus(): Promise<NetworkStatus> {
        if (this.networkStatusDetectionPromise) {
            return this.networkStatusDetectionPromise;
        }

        try {
            this.networkStatusDetectionPromise = this._detectNetworkStatus();
            await this.networkStatusDetectionPromise;
        } catch (error) {
            this.networkStatusDetectionPromise = undefined;
            throw error;
        }

        const networkStatusDetectionPromise = this.networkStatusDetectionPromise;
        this.networkStatusDetectionPromise = undefined;

        return networkStatusDetectionPromise;
    }

    /**
     * Check if the user can connect to the autoiXpert backend and/or the Internet. That allows the application to let the user know where the problem lies:
     * - No Internet: The user can connect to WiFi etc.
     * - No autoiXpert backend server connection: The backend is probably being updated or is down for another reason. The autoiXpert team needs to fix this.
     */
    private async _detectNetworkStatus(): Promise<NetworkStatus> {
        let networkStatus: NetworkStatus;

        const isConnectionToAutoixpertServersAvailable: boolean = await this.isConnectionToAutoixpertServersAvailable();
        /**
         * If the backend can be reached, we assume the user has access to the Internet as well.
         * That's almost always the case except for on a local development environment where the user may have no internet connection but can connect to the backend.
         */
        if (isConnectionToAutoixpertServersAvailable) {
            networkStatus = { status: 'online' };
        } else {
            let isInternetConnectionAvailable: boolean;

            try {
                isInternetConnectionAvailable = await this.isInternetConnectionAvailable();
            } catch (error) {
                /**
                 * We assume that a timeout does not occur because Google is down (they're hopefully always online) but because the user has a really slow Internet connection. In that
                 * case, pass the error along so that the offline-setter can mark this device's connection as too slow.
                 */
                if (error.code === 'INTERNET_DETECTION_TIMED_OUT') {
                    networkStatus = {
                        status: 'offline',
                        offlineReason: 'no-internet-connection',
                    };
                } else {
                    throw error;
                }
            }

            /**
             * The user has no connection to the autoiXpert backend but the user can reach google --> autoiXpert servers are down, e.g. because of a backend update.
             */
            if (isInternetConnectionAvailable) {
                networkStatus = {
                    status: 'offline',
                    offlineReason: 'ax-servers-not-reachable',
                };
            } else {
                /**
                 * The user is completely offline in contrast to having a very slow Internet connection.
                 */
                networkStatus = {
                    status: 'offline',
                    offlineReason: 'no-internet-connection',
                };
            }
        }

        this.setStatus(networkStatus);
        return networkStatus;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Detect General Network Status (aX Backend & Internet)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Detect Connection to AX Servers
    //****************************************************************************/
    private async isConnectionToAutoixpertServersAvailable(): Promise<boolean> {
        try {
            return await new Promise<boolean>((resolve, reject) => {
                /**
                 * The image must be loaded within a certain amount of time. If that did not work, the user has a very slow internet connection and is considered offline.
                 */
                const timeoutId = setTimeout(() => {
                    reject(
                        new AxError({
                            code: 'CONNECTION_TO_AUTOIXPERT_SERVERS_DETECTION_TIMED_OUT',
                            message:
                                'Detecting if this device is connected to the autoiXpert backend servers took too long.',
                        }),
                    );
                }, 1_500);

                const randomValue: string = Math.round(Math.random() * 10_000_000).toString();
                /**
                 * This random value is not echoed back from the server in case the backend is down. In that case, the load balancer in front of the backend services
                 * returns another string such as a JSON object or an HTML file.
                 */
                this.httpClient
                    .get<string>(`/api/v0/echo/${randomValue}`)
                    .toPromise()
                    /**
                     * Catch request failures such as when the server cannot be reached due to a missing Internet connection or when the backend is down.
                     */
                    .catch(() => {
                        clearTimeout(timeoutId);
                        resolve(false);
                    })
                    .then((returnValueFromServer) => {
                        // Since the request succeeded, don't watch for a timeout anymore.
                        clearTimeout(timeoutId);

                        if (randomValue === returnValueFromServer) {
                            resolve(true);
                        } else {
                            reject(
                                new AxError({
                                    code: 'ECHO_FROM_AUTOIXPERT_SERVERS_RETURNED_WRONG_VALUE',
                                    message:
                                        'The echo from the autoiXpert backend servers deviated from the value that was sent to the echo endpoint.',
                                    data: {
                                        valueSentToServer: randomValue,
                                        valueReceivedFromServer: returnValueFromServer,
                                    },
                                }),
                            );
                        }
                    });
            });
        } catch (error) {
            /**
             * Wrap the error object in an object so that it is not printed on the console. That stack trace spams the console. We still want to be able to investigate it, though. The wrapper object
             * is printed in an expandable way. That's great.
             */
            console.log('⛔ The autoiXpert backend servers cannot be reached.', { error });
            return false;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Detect Connection to AX Servers
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Detect Internet Status
    //****************************************************************************/
    /**
     * Detect whether an Internet connection is available at all by loading a Google image.
     * This assumes that Google is always online.
     */
    private async isInternetConnectionAvailable(): Promise<boolean> {
        console.log('An application component asked for internet detection.');

        try {
            await new Promise<void>((resolve, reject) => {
                /**
                 * The image must be loaded within a certain amount of time. If that did not work, the user has a very slow internet connection and is considered offline.
                 */
                const timeoutId = setTimeout(() => {
                    reject(
                        new AxError({
                            code: 'INTERNET_DETECTION_TIMED_OUT',
                            message: 'Detecting if this device is connected to the internet took too long.',
                        }),
                    );
                }, 1_500);

                const img = document.createElement('img');
                img.onerror = reject;
                img.onload = () => {
                    // Since the request succeeded, don't watch for a timeout anymore.
                    clearTimeout(timeoutId);
                    resolve();
                };
                /**
                 * Set a unique timestamp to prevent the image from being served from cache.
                 * Use a Google image that should have a pretty consistent URL and that should always
                 * be reachable.
                 */
                img.src = `https://www.google.com/images/errors/robot.png?timestamp=${Date.now()}`;
            });

            return true;
        } catch (error) {
            /**
             * We assume that a timeout does not occur because Google is down (they're hopefully always online) but because the user has a really slow Internet connection. In that
             * case, pass the error along so that the offline-setter can mark this device's connection as too slow.
             */
            if (error.code === 'INTERNET_DETECTION_TIMED_OUT') {
                console.log('Internet detection timed out.');
                throw error;
            }

            /**
             * If Google could not be reached, assume the user is offline.
             */
            console.log('Network detection image could not be loaded.', { error });
            return false;
        }
    }

    /**
     * Shothand for detecting if the user's Internet connection is so slow that autoiXpert cannot be reasonably used.
     * This is used for checking if the application needs to check for backend connectivity after the application detected a slow Internet connection. If the
     * Internet is still slow, this device does not need to check for backend connections.
     */
    private async isInternetConnectionSlow(): Promise<boolean> {
        try {
            await this.isInternetConnectionAvailable();
        } catch (error) {
            return error.code === 'INTERNET_DETECTION_TIMED_OUT';
        }
        return false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Detect Internet Status
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Reconnect After a While
    //****************************************************************************/
    public async startNetworkDetectionTimer(connectionRetries = 0): Promise<void> {
        // If a network detection timer is already running, don't start another one.
        if (this.isNetworkDetectionTimerRunning()) {
            return;
        }

        /**
         * Try often for a couple of times. If that does not work, the user usually has serious Internet problems. We can increase
         * the backoff timeout faster because the user typically needs some time to fix the issue (move to area with better network coverage,
         * ask for WiFi password, ...).
         */
        let seconds: number = 5 * Math.pow(4, connectionRetries);
        /**
         * Retry after a maximum of 15 minutes. High reconnect timeouts (say 75 minutes or 285 minutes) look strange to the user.
         */
        seconds = Math.min(seconds, 15 * 60);

        this.secondsUntilOnlineDetection$.next(seconds);

        return new Promise((resolve, reject) => {
            // Use as many interval elements as the timer is long, e.g. a 10 seconds timer produces 10 elements. Afterward, it completes.
            const timer$ = interval(1000).pipe(take(seconds));

            this.networkDetectionTimerSubscription = timer$.subscribe({
                next: (intervalElement) => {
                    this.secondsUntilOnlineDetection$.next(seconds - (intervalElement + 1));
                },
                error: reject,
                complete: async () => {
                    /**
                     * If this device is considered to have been offline due to a slow Internet connection, only try to reconnect to the server if the user now has a fast enough
                     * Internet connection. Otherwise, he'd jump between online and offline all the time.
                     */
                    if (
                        this.getNetworkStatusSnapshot().offlineReason === 'internet-connection-too-slow' &&
                        (await this.isInternetConnectionSlow())
                    ) {
                        /**
                         * Restart the timer - now with a higher back-off time since the connection retries was increased above.
                         */
                        console.log(
                            `The internet connection is still slow. Do not try to reconnect to the autoiXpert servers.`,
                        );
                        this.stopNetworkDetectionTimer();
                        this.startNetworkDetectionTimer(connectionRetries + 1).then(resolve, reject);
                    } else {
                        // Try to detect whether the user can connect to the backend servers and/or the Internet in general.
                        console.log('Detect internet status.');
                        this.stopNetworkDetectionTimer();
                        const networkStatus: NetworkStatus = await this.detectNetworkStatus();

                        // If the device is still offline, restart the timer with a higher backoff.
                        if (networkStatus.status === 'offline') {
                            this.startNetworkDetectionTimer(connectionRetries + 1).then(resolve, reject);
                        } else {
                            /**
                             * If the client is online now, perfect! Resolve and don't restart the network detection timer.
                             */
                            resolve();
                        }
                    }
                },
            });
        });
    }

    public stopNetworkDetectionTimer() {
        this.networkDetectionTimerSubscription?.unsubscribe();
        this.networkDetectionTimerSubscription = undefined;
        this.secondsUntilOnlineDetection$.next(0);
    }

    private isNetworkDetectionTimerRunning(): boolean {
        return !!(this.networkDetectionTimerSubscription && !this.networkDetectionTimerSubscription.closed);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reconnect After a While
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Shorthand Methods
    //****************************************************************************/
    public isOnline(): boolean {
        return this.networkStatusChange$.getValue().status === 'online';
    }

    /**
     * This returns the current network status once.
     *
     * If you're interested in the network status over time, subscribe to the subject.
     */
    public getNetworkStatusSnapshot(): NetworkStatus {
        return this.networkStatusChange$.getValue();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Shorthand Methods
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Set Network Status / Ensure Only Changes are Propagated
    //****************************************************************************/
    /**
     * Set the current network status explicitly, e.g. if a network request fails due to a timeout.
     */
    private setStatus(newStatus: NetworkStatus): void {
        const currentNetworkStatus = this.networkStatusChange$.getValue();

        // Only emit if the status has changed.
        if (
            newStatus.status !== currentNetworkStatus.status ||
            newStatus.offlineReason !== currentNetworkStatus.offlineReason
        ) {
            this.networkStatusChange$.next(newStatus);
            console.log('New network status:', newStatus);
        } else {
            //console.log("Network status already known:", newStatus);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Set Network Status / Ensure Only Changes are Propagated
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface NetworkStatus {
    /**
     * "initializing" is active until the first request comes back from the server (online) or fails due to missing connectivity (offline).
     *
     * While initializing, the client may send requests to the server assuming the client is online. This improves performance in contrast to waiting
     * for a connection check or a socket connection first.
     * Also, it ensures that requests on page load (typically reloading a currently opened report or so)
     * are actually queried against the server. If the client were considered offline from the start, the first requests would only be handled locally because
     * the client thinks it is offline.
     */
    status: 'online' | 'offline' | undefined;
    offlineReason?: 'no-internet-connection' | 'ax-servers-not-reachable' | 'internet-connection-too-slow' | 'unknown';
}

import { getInvolvedPartiesFromInvoice } from '@autoixpert/lib/involved-parties/get-involved-parties-from-invoice';
import { getDefaultDocumentOrderByTypeAndCommunicationRecipientRole } from '@autoixpert/lib/teams/preferences/get-default-document-order-by-type-and-communication-recipient-role';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Invoice, InvoiceInvolvedParty } from '@autoixpert/models/invoices/invoice';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';
import { Team } from '@autoixpert/models/teams/team';
import { CurrentAndDefaultDocumentOrder, InsertDocumentOptions, insertDocument } from './insert-document';

/**
 * Insert the document metadata object into invoice.documents and add its reference to the correct position in all involved parties' document orders.
 */
export function addDocumentToInvoice(
    {
        newDocument,
        invoice,
        team,
    }: {
        newDocument: DocumentMetadata;
        invoice: Invoice;
        team: Team;
    },
    options: InsertDocumentOptions = {},
): void {
    /**
     * This might become a custom array in the future when we enable custom involved parties.
     */
    const communicationRecipients: InvoiceInvolvedParty[] = getInvolvedPartiesFromInvoice({ invoice });

    const currentAndDefaultDocumentOrders: CurrentAndDefaultDocumentOrder[] = [];

    /**
     * Create pairs of the current document order and default document order (team preferences) for each involved party.
     *
     * The default order defines after which document a new document should be inserted, even if the user manually rearranged
     * the document order in the report.
     */
    for (const communicationRecipient of communicationRecipients) {
        let defaultDocumentOrder: DefaultDocumentOrder;
        try {
            defaultDocumentOrder = getDefaultDocumentOrderByTypeAndCommunicationRecipientRole({
                team,
                defaultDocumentOrderGroupType: 'invoice',
                communicationRecipientRole: communicationRecipient.role,
            });
        } catch (error) {
            throw new AxError({
                code: 'GETTING_DEFAULT_DOCUMENT_ORDER_FAILED',
                message: 'The default document order could not be retrieved when adding a document to an invoice.',
                data: {
                    communicationRecipientRole: communicationRecipient.role,
                },
                error,
            });
        }

        let documentOrder: DocumentOrder;
        try {
            documentOrder = invoice.documentOrders.find(
                (documentOrder) => documentOrder.recipientRole === communicationRecipient.role,
            );
        } catch (error) {
            throw new AxError({
                code: 'FINDING_INVOICE_DOCUMENT_ORDER_FAILED',
                message: 'The document order for the given involved party role could not be found in the invoice.',
                data: {
                    communicationRecipientRole: communicationRecipient.role,
                },
                error,
            });
        }
        currentAndDefaultDocumentOrders.push({
            documentOrder,
            defaultDocumentOrder,
        });
    }

    insertDocument(
        {
            newDocument,
            documents: invoice.documents,
            currentAndDefaultDocumentOrders,
        },
        options,
    );
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-wrapper display-flex">
        <!-- Side bar "Existing damages" which displays the previous damages -->
        <div
            class="existing-damages-column dialog-container"
            *ngIf="report.car.repairedPreviousDamage || report.car.unrepairedPreviousDamage">
            <div class="existing-damages-content">
                <div class="repaired-damages" *ngIf="report.car.repairedPreviousDamage">
                    <h3>
                        <mat-icon>history</mat-icon>
                        Vorschäden
                    </h3>
                    <div class="quill-wrapper">
                        <mat-quill [(ngModel)]="report.car.repairedPreviousDamage" [disabled]="true"></mat-quill>
                    </div>
                </div>
                <div class="unrepaired-damages" *ngIf="report.car.unrepairedPreviousDamage">
                    <h3>
                        <mat-icon>build</mat-icon>
                        Altschäden
                    </h3>
                    <div class="quill-wrapper">
                        <mat-quill [(ngModel)]="report.car.unrepairedPreviousDamage" [disabled]="true"></mat-quill>
                    </div>
                </div>
                <a class="edit-previous-damages-link" (click)="navigateToPreviousDamages()">
                    <mat-icon>edit</mat-icon>
                    Bearbeiten
                </a>
            </div>
        </div>

        <!-- Main content area for editing replacement value corrections -->
        <div class="main-content dialog-container">
            <div class="replacement-value-corrections">
                <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
                <h2>Korrekturen für Fahrzeugwert</h2>
                <div class="vehicle-base-value">
                    <div class="label-column">
                        <h3 class="base-value-title no-margin">Fahrzeug-Grundwert</h3>
                        <span class="label">ohne Schäden</span>
                    </div>
                    <div class="base-value-input-field">
                        <currency-input
                            [(value)]="report.valuation.vehicleBaseValue"
                            (valueChange)="calculateFinalValue()"
                            [autofocus]="focusBaseValueInput"></currency-input>
                        <span
                            class="help-indicator"
                            matTooltip="Der Fahrzeug-Grundwert ist der ursprünglich unter Wiederbeschaffungswert eingegebene Wert ohne Ab- oder Aufwertungen.">
                            ?
                        </span>
                    </div>
                </div>

                <div id="decrease-increase-container">
                    <replacement-value-correction-decreases-increases
                        [report]="report"
                        [team]="team"
                        [user]="user"
                        (valuesChanged)="
                            calculateFinalValue(); saveReport()
                        "></replacement-value-correction-decreases-increases>

                    <div
                        class="decreases-print-sum-only"
                        matTooltip="Entscheide, ob die Beträge jeder einzelnen Ab- und Aufwertung oder nur die Gesamtsumme der Korrekturen im Gutachten abgedruckt wird.">
                        <span>nur Summe abdrucken</span>
                        <mat-slide-toggle
                            #printSumOnlyToggle
                            [disableRipple]="true"
                            [ngModel]="
                                report.valuation.replacementValueCorrectionConfig.printSumOnly != null
                                    ? report.valuation.replacementValueCorrectionConfig.printSumOnly
                                    : !!team.preferences.replacementValueCorrectionsPrintSumOnly
                            "
                            (ngModelChange)="updatePrintSumOnlySettings($event)"></mat-slide-toggle>
                        <mat-icon
                            *ngIf="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                            class="toolbar-icon print-sum-only-settings-icon"
                            [matMenuTriggerFor]="printSumOnlyMenu">
                            more_vert
                        </mat-icon>
                        <mat-menu #printSumOnlyMenu="matMenu">
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="rememberPrintSumOnlySettings()"
                                matTooltip="Die aktuelle Einstellung als Standard für alle Gutachten verwenden.">
                                <mat-icon class="outlined">bookmark_border</mat-icon>
                                Einstellung merken
                            </a>
                        </mat-menu>
                    </div>
                </div>

                <div class="margin-top-20"></div>

                <!-- Summary of the values and final calculation of the adjusted replacement value -->
                <div class="summary-row">
                    <div class="summary-item">
                        <h3 class="text-overflow-ellipsis">Abwertungen</h3>
                        <span class="summary-item-value red">
                            {{
                                report.valuation.replacementValueCorrectionConfig.totalDecrease
                                    | convertToEuro: 2 : true
                            }}
                        </span>
                    </div>

                    <mat-icon>add</mat-icon>

                    <div class="summary-item">
                        <h3 class="text-overflow-ellipsis">Aufwertungen</h3>
                        <span class="summary-item-value green">
                            {{
                                report.valuation.replacementValueCorrectionConfig.totalIncrease
                                    | convertToEuro: 2 : true
                            }}
                        </span>
                    </div>

                    <span class="equals">=</span>

                    <div class="summary-item">
                        <div class="summary-item-label-container">
                            <h3 class="text-overflow-ellipsis">Fahrzeugwert</h3>
                            <span class="label text-overflow-ellipsis">brutto</span>
                        </div>
                        <span class="summary-item-value blue">
                            {{ calculateReplacementValueWithCorrections(this.report) | convertToEuro: 2 : true }}
                        </span>
                    </div>
                </div>

                <!-- Close button -->
                <div class="actions-row">
                    <button (click)="closeDialog()">Schließen</button>
                </div>
                <img class="bottom-graphic" src="/assets/images/replacement-value-corrections-bottom-graphic.svg" />
            </div>
        </div>
    </div>
</div>

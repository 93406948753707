import { ContactPerson } from '../../models/contacts/contact-person';
import { GarageFeeSet, PaintMaterialSurchargeUnit } from '../../models/contacts/garage-fee-set';
import { UnprocessableEntity } from '../../models/errors/ax-error';
import { Invoice, InvoiceInvolvedPartyRole } from '../../models/invoices/invoice';
import { PaymentStatus } from '../../models/invoices/payment-status';
import { KaskoDamageType } from '../../models/reports/accident';
import { CustomFeeSet } from '../../models/reports/assessors-fee/custom-fee-set';
import { FeeCalculation } from '../../models/reports/assessors-fee/fee-calculation';
import { Car, CarShape } from '../../models/reports/car-identification/car';
import { Tire } from '../../models/reports/car-identification/tire';
import { DamageType } from '../../models/reports/damage-calculation/damage-calculation';
import { Repair } from '../../models/reports/damage-description/repair';
import { DiminishedValueCalculationMethod } from '../../models/reports/diminished-value/diminished-value-calculation';
import { InvolvedPartyRole } from '../../models/reports/involved-parties/involved-party';
import { TaxationType, Valuation } from '../../models/reports/market-value/valuation';
import { Report } from '../../models/reports/report';
import {
    PaintMaterialSurchargeUnitGerman,
    PaintSystemGerman,
    TirePositionGerman,
    TireSeasonGerman,
    TristateGerman,
} from './placeholder-values.types';

export type ReportTypeGerman =
    | 'Kostenvoranschlag'
    | 'Kurzgutachten'
    | 'Teilkaskoschaden'
    | 'Vollkaskoschaden'
    | 'Fahrzeugbewertung'
    | 'Fahrzeugbewertung Oldtimer'
    | 'Gebrauchtwagen-Check'
    | 'Haftpflichtschaden'
    | 'Zustandsbericht'
    | 'Rechnungsprüfung';

export type ClaimantDenominationGerman = 'Anspruchsteller' | 'Auftraggeber' | 'Leasingnehmer' | 'Versicherungsnehmer';

export type CarShapeGerman =
    | 'Limousine'
    | 'Kompaktwagen'
    | 'Coupé'
    | 'Kombi'
    | 'SUV'
    | 'Cabriolet'
    | 'Van'
    | 'Transporter'
    | 'Anhänger'
    | 'Pick-Up'
    | 'Sattelzugmaschine'
    | 'Sattelauflieger'
    | 'LKW mit Aufbau'
    | 'Fahrrad'
    | 'Pedelec'
    | string; // The car shape can be anything the user enters manually.

export type TaxationTypeGerman = 'regelbesteuert' | 'differenzbesteuert' | 'steuerneutral';

export type ValuationSourceGerman =
    | 'DAT'
    | 'Audatex'
    | 'Classic Analytics'
    | 'Marktwertanalyse'
    | 'Restwertabfrage'
    | 'Eigene Wertermittlung'
    | 'Sonstige';

export type VehicleValueLabelGerman =
    | 'Händlereinkaufspreis'
    | 'Händlerverkaufspreis'
    | 'Wiederbeschaffungswert'
    | 'Wiederherstellungswert'
    | 'Marktwert'
    | 'Restwert'
    | 'Zeitwert';

export type VehicleValueLabelGermanShort = 'Händler-EK' | 'Händler-VK' | 'WBW';

export type OrganizationTypeGerman =
    | 'Anspruchsteller'
    | 'Versicherung'
    | 'Anwalt'
    | 'Werkstatt'
    | 'Besichtigungsfavorit'
    | 'Bieter'
    | 'Vermittler'
    | 'Fahrzeughalter';

export type InvoiceInvolvedPartyTypeGerman =
    | 'Anspruchsteller'
    | 'Versicherung'
    | 'Anwalt'
    | 'Rechnungsempfänger'
    | 'Leasinggeber';

export type VatExemptionReasonGerman = 'Kleinunternehmer' | 'Innenumsatz Organschaft' | 'Reverse Charge';

export type InvolvedPartyTypeGerman =
    | ClaimantDenominationGerman
    | 'Fahrzeughalter'
    | 'Unfallgegners'
    | 'Fahrzeughalter (Unfallgegner)'
    | 'Werkstatt'
    | 'Anwalt'
    | 'Versicherung'
    | 'Leasinggeber'
    | 'Factoring Provider'
    | 'Verkäufer';

export type DamageCalculationProviderGerman =
    | 'DAT'
    | 'Audatex'
    | 'Audatex-Textimport'
    | 'GT Motive'
    | 'Schätzung'
    | 'Manuell'
    | 'Sonstige';

export type BatteryDamageGerman =
    | 'Sichtprüfung nicht möglich'
    | 'keine Beschädigung festgestellt'
    | 'sichtbare Beschädigung festgestellt';

export const batteryDamageOptionsGerman: BatteryDamageGerman[] = [
    'Sichtprüfung nicht möglich',
    'keine Beschädigung festgestellt',
    'sichtbare Beschädigung festgestellt',
];

export type AxisMeasurementGerman = 'erforderlich' | 'nicht erforderlich' | 'bereits durchgeführt';

export type HighVoltageSystemCheckGerman = 'erforderlich' | 'nicht erforderlich' | 'bereits durchgeführt';

export type CarBodyMeasurementGerman = 'erforderlich' | 'nicht erforderlich' | 'bereits durchgeführt';

export type AssessorFeeTableGerman = 'BVSK' | 'VKS' | 'HUK-Coburg' | 'CGF' | 'Eigene';

export type GarageFeeSetUnitGerman = 'Prozent' | 'Pauschal' | 'Materialpunkte' | 'Materialindex';

export type GarageFeeSetTransportCategory = 'Mechanik' | 'Elektrik' | 'Karosserie' | 'Pauschal' | 'Keine';

// Emission Group 1 does not have any color, so the color is an empty string.
export type EmissionGroupColorGerman = 'Grün' | 'Gelb' | 'Rot' | '';

/**
 * Made changes? Please update the suggestion list for building block conditions in the frontend too.
 */
export class Translator {
    static carShape(carShape: CarShape): CarShapeGerman {
        const translations = {
            sedan: 'Limousine',
            compact: 'Kompaktwagen',
            coupe: 'Coupé',
            stationWagon: 'Kombi',
            suv: 'SUV',
            convertible: 'Cabriolet',
            van: 'Van',
            transporter: 'Transporter',
            trailer: 'Anhänger',
            pickup: 'Pick-Up',
            semiTruck: 'Sattelzugmaschine',
            semiTrailer: 'Sattelauflieger',
            truck: 'LKW mit Aufbau',
            bus: 'Bus',
            motorHome: 'Wohnmobil',
            caravanTrailer: 'Wohnanhänger',
            motorcycle: 'Motorrad',
            bicycle: 'Fahrrad',
            'e-bike': 'E-Bike',
            pedelec: 'Pedelec',
        };
        return translations[carShape] || carShape;
    }

    static reportType(reportType: Report['type'], useCostEstimateHeading = false): ReportTypeGerman {
        let reportTypeGerman: ReportTypeGerman;

        switch (reportType) {
            case 'shortAssessment':
                reportTypeGerman = useCostEstimateHeading ? 'Kostenvoranschlag' : 'Kurzgutachten';
                break;
            case 'partialKasko':
                reportTypeGerman = 'Teilkaskoschaden';
                break;
            case 'fullKasko':
                reportTypeGerman = 'Vollkaskoschaden';
                break;
            case 'valuation':
                reportTypeGerman = 'Fahrzeugbewertung';
                break;
            // TODO Change to a different value since this cannot be differentiated from the future oldtimer valuation large.
            case 'oldtimerValuationSmall':
                reportTypeGerman = 'Fahrzeugbewertung Oldtimer';
                break;
            case 'leaseReturn':
                reportTypeGerman = 'Zustandsbericht';
                break;
            case 'usedVehicleCheck':
                reportTypeGerman = 'Gebrauchtwagen-Check';
                break;
            case 'invoiceAudit':
                reportTypeGerman = 'Rechnungsprüfung';
                break;
            case 'liability':
            default:
                reportTypeGerman = 'Haftpflichtschaden';
                break;
        }

        return reportTypeGerman;
    }

    static claimantDenomination(reportType: Report['type']): ClaimantDenominationGerman {
        switch (reportType) {
            case 'shortAssessment':
            case 'liability':
                return 'Anspruchsteller';
            case 'partialKasko':
            case 'fullKasko':
                return 'Versicherungsnehmer';
            case 'leaseReturn':
                return 'Leasingnehmer';
            case 'valuation':
            case 'oldtimerValuationSmall':
            default:
                return 'Auftraggeber';
        }
    }

    static mileageUnit(mileageUnit: Car['mileageUnit']) {
        switch (mileageUnit) {
            case 'mi':
                return 'mi';
            case 'h':
                return 'h';
            case 'km':
            default:
                return 'km';
        }
    }

    static damageType(damageType: DamageType) {
        // Make the damage type bold.
        switch (damageType) {
            case 'repairableDamage':
                return 'Reparaturschaden';
            case 'repairableDamageTo130Percent':
                return 'Reparaturschaden auf 130 %';
            case 'economicTotalLoss':
                return 'Wirtschaftlicher Totalschaden';
            case 'totalLoss':
                return 'Totalschaden';
        }
    }

    static kaskoDamageType(kaskoDamageType: KaskoDamageType, customLabel: string): string {
        switch (kaskoDamageType) {
            case 'animal':
                return 'Wildschaden';
            case 'fire':
                return 'Brandschaden';
            case 'theft':
                return 'Diebstahl';
            case 'glass':
                return 'Glasschaden';
            case 'hail':
                return 'Hagelschaden';
            case 'other':
                return customLabel;
        }
    }

    /**
     * Translates the ordering method from the English identifier to German.
     * @param orderingMethod
     * @return {string}
     */
    static orderingMethod(orderingMethod) {
        let orderingMethodGerman = '';
        switch (orderingMethod) {
            case 'phone':
                orderingMethodGerman = 'telefonisch';
                break;
            case 'written':
                orderingMethodGerman = 'schriftlich';
                break;
            case 'personal':
                orderingMethodGerman = 'persönlich';
                break;
        }
        return orderingMethodGerman;
    }

    static involvedPartyType(
        involvedPartyType: InvolvedPartyRole,
        reportType: Report['type'],
    ): InvolvedPartyTypeGerman {
        switch (involvedPartyType) {
            case 'claimant':
                return Translator.claimantDenomination(reportType);
            case 'ownerOfClaimantsCar':
                return 'Fahrzeughalter';
            case 'authorOfDamage':
                return 'Unfallgegners';
            case 'ownerOfAuthorOfDamagesCar':
                return 'Fahrzeughalter (Unfallgegner)';
            case 'garage':
                return 'Werkstatt';
            case 'lawyer':
                return 'Anwalt';
            case 'insurance':
                return 'Versicherung';
            case 'leaseProvider':
                return 'Leasinggeber';
            case 'factoringProvider':
                return 'Factoring Provider';
            case 'seller':
                return 'Verkäufer';
        }
    }

    /**
     * Merge the two translators for
     * - involvedParties
     * - invoiceInvolvedParties.
     */
    static letterRecipient(
        involvedPartyType: InvoiceInvolvedPartyRole | InvolvedPartyRole,
        reportType?: Report['type'],
    ) {
        return (
            Translator.involvedPartyType(involvedPartyType as InvolvedPartyRole, reportType) ||
            Translator.invoiceInvolvedPartyType(involvedPartyType as InvoiceInvolvedPartyRole)
        );
    }

    //*****************************************************************************
    //  Contact People
    //****************************************************************************/
    static organizationType(organizationType: ContactPerson['organizationType']): OrganizationTypeGerman | string {
        switch (organizationType) {
            case 'bidder':
                return 'Bieter';
            case 'claimant':
                return 'Anspruchsteller';
            case 'factoringProvider':
                return 'Factoring Provider';
            case 'garage':
                return 'Werkstatt';
            case 'insurance':
                return 'Versicherung';
            case 'intermediary':
                return 'Vermittler';
            case 'lawyer':
                return 'Anwalt';
            case 'leaseProvider':
                return 'Leasinggeber';
            case 'ownerOfAuthorOfDamagesCar':
                return 'Versicherter';
            case 'ownerOfClaimantsCar':
                return 'Fahrzeughalter';
            case 'residualValueRequestRecipient':
                return 'Empfänger Restwertinserate';
            case 'seller':
                return 'Verkäufer';
            case 'visitLocationFavorite':
                return 'Besichtigungsfavorit';
            default:
                return organizationType;
        }
        // Todo:;
        // case 'invoiceRecipient':
        // return 'Rechnungsempfänger';
        // lawyer -> Anwalt oder Rechtsanwalt?
        // ownerOfAuthorOfDamagesCar (Versicherter oder Fahrzeughalter(Unfallgegner))
        // claimant mit Translator claimantDenomination
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact People
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice
    //****************************************************************************/
    static invoiceInvolvedPartyType(involvedPartyType: Invoice['recipient']['role']): InvoiceInvolvedPartyTypeGerman {
        switch (involvedPartyType) {
            case 'claimant':
                return 'Anspruchsteller';
            case 'lawyer':
                return 'Anwalt';
            case 'insurance':
                return 'Versicherung';
            case 'invoiceRecipient':
                return 'Rechnungsempfänger';
            case 'leaseProvider':
                return 'Leasinggeber';
        }
        // TODO: stimmt überein mit organizationType
        // invoiceRecipient fehlt
    }

    static vatExemptionReason(vatExemptionReason: Invoice['vatExemptionReason']): VatExemptionReasonGerman {
        if (!vatExemptionReason) return undefined;

        switch (vatExemptionReason) {
            case 'smallBusiness':
                return 'Kleinunternehmer';
            case 'companyInternalInvoice':
                return 'Innenumsatz Organschaft';
            case 'reverseCharge':
                return 'Reverse Charge';
            default:
                throw new UnprocessableEntity({
                    code: 'VAT_EXEMPTION_REASON_NOT_FOUND',
                    message: `The given VAT Exemption Reason could not be translated to German.`,
                    data: {
                        vatExemptionReason,
                    },
                });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    static replacementValueTaxation(replacementValueTaxationType: TaxationType): TaxationTypeGerman {
        switch (replacementValueTaxationType) {
            case 'full':
            case 'full_16':
                return 'regelbesteuert';
            case 'margin':
            case 'margin_reduced':
                return 'differenzbesteuert';
            case 'neutral':
                return 'steuerneutral';
        }
    }

    static diminishedValueCalculationMethod(calculationMethod: DiminishedValueCalculationMethod): string {
        switch (calculationMethod) {
            case 'mfm':
                return 'MFM';
            case 'bvsk':
                return 'BVSK';
            case 'halbgewachs':
                return 'Halbgewachs';
            case 'hamburgModel':
                return 'Hamburger Modell';
            case 'ruhkopf':
                return 'Ruhkopf/Sahm';
            case 'dvgt':
                return '13. DVGT';
            case 'troemner':
                return 'Dr. Trömner';
        }
    }

    static emissionGroup(emissionGroup: Car['emissionGroup']): string {
        let emissionGroupGerman = '';

        switch (emissionGroup) {
            case null:
            case undefined:
                break;
            default:
                emissionGroupGerman = `Gruppe ${emissionGroup}`;
                break;
        }

        return emissionGroupGerman;
    }

    static emissionGroupColor(emissionGroup: Car['emissionGroup']): EmissionGroupColorGerman {
        switch (emissionGroup) {
            case 2:
                return 'Rot';
            case 3:
                return 'Gelb';
            case 4:
                return 'Grün';
            //case 5:
            //    return 'Blau';
            // The emission group 1 does not have any color.
            case 1:
            default:
                return '';
        }
    }

    static tireType(tire: Pick<Tire, 'season' | 'customType'>): TireSeasonGerman | string {
        if (tire.season === 'custom') {
            // In case the user specified a custom tire type, return that
            return tire.customType;
        }

        // Standard tire types are the seasons summer, winter or all-season
        switch (tire.season) {
            case 'allyear':
                return 'Ganzjahr';
            case 'summer':
                return 'Sommer';
            case 'winter':
                return 'Winter';
        }
    }

    static tirePosition(position: Tire['position'], distinguishBetweenInnerAndOuterTires: boolean): TirePositionGerman {
        switch (position) {
            case 'left':
                return distinguishBetweenInnerAndOuterTires ? 'Links innen' : 'Links';
            case 'right':
                return distinguishBetweenInnerAndOuterTires ? 'Rechts innen' : 'Rechts';
            case 'outerLeft':
                return 'Links außen';
            case 'outerRight':
                return 'Rechts außen';
            case 'center':
                return 'Mitte';
        }
    }

    static numberOfPreviousOwners(numberOfPreviousOwners: Car['numberOfPreviousOwners']): string {
        switch (numberOfPreviousOwners) {
            case 'multiple':
                return 'mehrere';
            case 'unknown':
                return 'unbekannt';
            case null:
            case undefined:
                return '';
            // Numbers such as "2" previous owners
            default:
                return numberOfPreviousOwners;
        }
    }

    static calculationProvider(calculationProvider: Repair['calculationProvider']): DamageCalculationProviderGerman {
        switch (calculationProvider) {
            case 'dat':
                return 'DAT';
            case 'audatex':
                return 'Audatex';
            case 'audatex-textimport':
                return 'Audatex-Textimport';
            case 'gtmotive':
                return 'GT Motive';
            case 'estimate':
                return 'Schätzung';
            case 'manual':
                return 'Manuell';
            case 'other':
            default:
                return 'Sonstige';
        }
    }

    static valuationProvider(
        valuationProvider:
            | Valuation['valuationProvider']
            | Valuation['baseValueDealerSalesProvider']
            | Valuation['baseValueDealerPurchaseProvider'],
    ): ValuationSourceGerman {
        switch (valuationProvider) {
            case 'dat':
                return 'DAT';
            case 'audatex':
                return 'Audatex';
            case 'classicAnalytics':
                return 'Classic Analytics';
            case 'marketValue':
                return 'Marktwertanalyse';
            case 'residualValue':
                return 'Restwertabfrage';
            case 'custom':
                return 'Eigene Wertermittlung';
            default:
                return 'Sonstige';
        }
    }

    static baseValuePurchaseProvider(valuationProvider: Valuation['baseValueDealerPurchaseProvider']): string {
        switch (valuationProvider) {
            case 'dat':
                return 'DAT';
            case 'audatex':
                return 'Audatex';
            case 'winvalue':
                return 'Restwertabfrage (WinValue)';
            case 'cartv':
                return 'Restwertabfrage (CARTV)';
            case 'autoonline':
                return 'Restwertabfrage (AUTOonline)';
            case 'carcasion':
                return 'Restwertabfrage (car.casion)';
            case 'axResidualValueRequest':
                return 'Restwertabfrage';
            default:
                return 'eigene Wertermittlung';
        }
    }

    static baseValueSalesProvider(valuationProvider: Valuation['baseValueDealerSalesProvider']): string {
        switch (valuationProvider) {
            case 'dat':
                return 'DAT';
            case 'datWebscan':
                return 'Marktwertanalyse (DAT)';
            case 'audatex':
                return 'Audatex';
            case 'winvalue':
                return 'Marktwertanalyse (WinValue)';
            case 'cartv':
                return 'Marktwertanalyse (CARTV)';
            case 'carcasion':
                return 'Marktwertanalyse (car.casion)';
            case 'custom':
                return 'Marktwertanalyse';
            default:
                return 'eigene Wertermittlung';
        }
    }

    static vehicleValueLabel(vehicleValueType: Report['valuation']['vehicleValueType']): VehicleValueLabelGerman {
        switch (vehicleValueType) {
            case 'dealerPurchase':
                return 'Händlereinkaufspreis';
            case 'dealerSales':
                return 'Händlerverkaufspreis';
            case 'replacementValue':
                return 'Wiederbeschaffungswert';
            case 'restorationValue':
                return 'Wiederherstellungswert';
            case 'marketValue':
                return 'Marktwert';
            case 'presentValue':
                return 'Zeitwert';
            case 'residualValue':
                return 'Restwert';
        }
    }

    static vehicleValueLabelShort(
        vehicleValueType: Report['valuation']['vehicleValueType'],
    ): VehicleValueLabelGermanShort | VehicleValueLabelGerman {
        switch (vehicleValueType) {
            case 'dealerPurchase':
                return 'Händler-EK';
            case 'dealerSales':
                return 'Händler-VK';
            case 'replacementValue':
                return 'WBW';
            default:
                return this.vehicleValueLabel(vehicleValueType);
        }
    }

    static oldtimerValuationNumberGrades(classicAnalyticsGrade: number): string {
        const gradeScheme = {
            14: '1',
            13: '1-',
            12: '2+',
            11: '2',
            10: '2-',
            9: '3+',
            8: '3',
            7: '3-',
            6: '4+',
            5: '4',
            4: '4-',
            3: '5+',
            2: '5',
        };

        return gradeScheme[classicAnalyticsGrade] || '';
    }

    static oldtimerValuationVerbalGrades(classicAnalyticsGrade: number): string {
        const gradeScheme = {
            14: 'eins',
            13: 'eins minus',
            12: 'zwei plus',
            11: 'zwei',
            10: 'zwei minus',
            9: 'drei plus',
            8: 'drei',
            7: 'drei minus',
            6: 'vier plus',
            5: 'vier',
            4: 'vier minus',
            3: 'fünf plus',
            2: 'fünf',
        };

        return gradeScheme[classicAnalyticsGrade] || '';
    }

    static invoicePaymentStatus(paymentStatus: PaymentStatus): string {
        switch (paymentStatus) {
            case 'due':
                return 'Fällig';
            case 'overdue':
                return 'In Verzug';
            case 'partiallyPaid':
                return 'Teilbezahlt';
            case 'paid':
                return 'Bezahlt';
            case 'fullCancellation':
                return 'Storno';
            case 'fullyCanceled':
                return 'Storniert';
            case 'paymentReminderLevel0':
                return 'Erinnert';
            case 'paymentReminderLevel1':
                return '1. Mahnung';
            case 'paymentReminderLevel2':
                return '2. Mahnung';
            case 'paymentReminderLevel3':
                return '3. Mahnung';
            default:
                return paymentStatus;
        }
    }

    static tristate(value: boolean): TristateGerman {
        switch (value) {
            case true:
                return 'Ja';
            case false:
                return 'Nein';
            default:
                return 'unbekannt';
        }
    }

    static batteryDamage(value: Car['batteryDamage']): BatteryDamageGerman {
        switch (value) {
            case 'visualInspectionNotPossible':
                return 'Sichtprüfung nicht möglich';
            case 'noVisualDamage':
                return 'keine Beschädigung festgestellt';
            case 'visualDamage':
                return 'sichtbare Beschädigung festgestellt';
        }
    }

    static axisMeasurement(value: Repair['axisMeasurement']): AxisMeasurementGerman {
        switch (value) {
            case 'required':
                return 'erforderlich';
            case 'carriedOut':
                return 'bereits durchgeführt';
            case 'notRequired':
                return 'nicht erforderlich';
        }
    }

    static highVoltageSystemCheck(value: Repair['highVoltageSystemCheck']): HighVoltageSystemCheckGerman {
        switch (value) {
            case 'required':
                return 'erforderlich';
            case 'carriedOut':
                return 'bereits durchgeführt';
            case 'notRequired':
                return 'nicht erforderlich';
        }
    }

    static carBodyMeasurement(value: Repair['carBodyMeasurement']): CarBodyMeasurementGerman {
        switch (value) {
            case 'required':
                return 'erforderlich';
            case 'carriedOut':
                return 'bereits durchgeführt';
            case 'notRequired':
                return 'nicht erforderlich';
        }
    }

    static assessorFeeTable(
        feeCalculation: FeeCalculation,
        customFeeSets: CustomFeeSet[],
    ): AssessorFeeTableGerman | string {
        switch (feeCalculation.selectedFeeTable) {
            case 'custom':
                return (
                    customFeeSets.find((feeSet) => feeSet._id === feeCalculation.selectedCustomFeeTableId)?.title ??
                    'Eigene'
                );
            case 'BVSK':
                return 'BVSK';
            case 'HUK':
                return 'HUK-Coburg';
            case 'VKS':
                return 'VKS';
            case 'CGF':
                return 'CGF';
        }
    }

    static carPaintSystem(value: GarageFeeSet['carPaint']['paintSystem']): PaintSystemGerman {
        switch (value) {
            case 'allianzCenterForTechnology':
                return 'AZT';
            case 'manufacturer':
                return 'Hersteller';
            case 'eurolack':
                return 'Eurolack';
        }
    }

    static carPaintMaterialSurchargeUnit(
        paintMaterialSurchargeUnit: PaintMaterialSurchargeUnit,
        paintSystem: GarageFeeSet['carPaint']['paintSystem'],
    ): PaintMaterialSurchargeUnitGerman | undefined | null {
        if (paintMaterialSurchargeUnit == undefined) {
            return undefined;
        }

        switch (paintMaterialSurchargeUnit) {
            case 'percent':
                return '%';
            case 'materialIndex':
                return '%';
            case 'materialPointsOrUnits':
                return paintSystem === 'eurolack' ? '€/MP' : '€/ME';
            case 'flatFee':
                return '€';
        }
    }

    static garageFeeSetUnitsToGerman(value: PaintMaterialSurchargeUnit): GarageFeeSetUnitGerman {
        switch (value) {
            case 'percent':
                return 'Prozent';
            case 'flatFee':
                return 'Pauschal';
            case 'materialIndex':
                return 'Materialindex';
            case 'materialPointsOrUnits':
                return 'Materialpunkte';
        }
    }

    static garageFeeSetUnits(value: GarageFeeSetUnitGerman): PaintMaterialSurchargeUnit {
        switch (value) {
            case 'Prozent':
                return 'percent';
            case 'Pauschal':
                return 'flatFee';
            case 'Materialindex':
                return 'materialIndex';
            case 'Materialpunkte':
                return 'materialPointsOrUnits';
        }
    }

    static garageFeeSetTransportCategoryToGerman(
        value: 'electrics' | 'mechanics' | 'carBody' | 'none' | 'fixedPrice',
    ): GarageFeeSetTransportCategory {
        switch (value) {
            case 'fixedPrice':
                return 'Pauschal';
            case 'mechanics':
                return 'Mechanik';
            case 'electrics':
                return 'Elektrik';
            case 'carBody':
                return 'Karosserie';
            case 'none':
                return 'Keine';
        }
    }

    static garageFeeSetTransportCategory(
        value: GarageFeeSetTransportCategory,
    ): 'electrics' | 'mechanics' | 'carBody' | 'none' | 'fixedPrice' {
        switch (value) {
            case 'Pauschal':
                return 'fixedPrice';
            case 'Mechanik':
                return 'mechanics';
            case 'Elektrik':
                return 'electrics';
            case 'Karosserie':
                return 'carBody';
            case 'Keine':
                return 'none';
        }
    }

    /**
     * Required for numbers that are converted to currencies such as the residual value. If the user leaves these values empty, they should not equal 0 but an empty string. That
     * allows the user to create different conditions: Either empty or 0. That's relevant because some assessors would like to add a different document building block variant
     * to the report if no residual value was determined vs. a value of 0.
     */
    static emptyValueToEmptyString(value: number | string, alternative?: string): string {
        if ([null, undefined].includes(value) || (typeof value === 'string' && value === '')) {
            return '';
        }
        return typeof alternative === 'undefined' ? `${value}` : alternative;
    }
}

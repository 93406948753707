import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { LineItem } from './line-item';

export class InvoiceTemplate implements DataTypeBase {
    constructor(template: Partial<InvoiceTemplate> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    type: 'customInvoice' | 'expertStatementInvoice'; // customInvoice = invoice from invoice editor
    currencyCode: string;
    intro: string;
    title: string;
    daysUntilDue: number;
    euDelivery: boolean;
    lineItems: LineItem[] = [];
    totalNet: number;
    totalGross: number;
    createdBy: string;
    teamId: string;

    createdAt: string;
    updatedAt: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

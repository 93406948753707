import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { isCursorInInputOrTextarea } from '@autoixpert/lib/keyboard-events/isCursorInInputOrTextarea';
import { LabelConfig, LabelGroup } from '@autoixpert/models/labels/label-config';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { ApiErrorService } from '../../services/api-error.service';
import { LabelConfigService } from '../../services/label-config.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'label-manager',
    templateUrl: './label-manager.component.html',
    styleUrls: ['./label-manager.component.scss'],
    animations: [fadeInAndSlideAnimation()],
    host: {
        '[class.card]': 'true',
    },
})
export class LabelManagerComponent {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private apiErrorService: ApiErrorService,
        private labelConfigService: LabelConfigService,
        private overlayRef: OverlayRef,
        private dialog: MatDialog,
        private toastService: ToastService,
    ) {}

    @Input() labelGroup: LabelGroup;

    @Output() labelConfigsChange: EventEmitter<LabelConfig[]> = new EventEmitter();

    protected user: User;
    /**
     * To always have the latest state, this component always loads the labels fresh from the server.
     */
    protected labelConfigs: LabelConfig[] = [];
    protected labelConfigInEditMode: LabelConfig;

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.loadLabelConfigs().then();
    }

    protected async loadLabelConfigs() {
        try {
            this.labelConfigs = await this.labelConfigService.find({ labelGroup: this.labelGroup }).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Label-Konfigs konnten nicht geladen werden`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        this.labelConfigs.sort(sortByProperty(['dragOrderPosition', 'name']));
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CRUD
    //****************************************************************************/
    protected async addLabelConfig() {
        const newLabel: LabelConfig = new LabelConfig({
            labelGroup: this.labelGroup,
            dragOrderPosition: this.labelConfigs.at(-1)?.dragOrderPosition + 1,
            color: '#000000',
        });

        this.labelConfigs.push(newLabel);

        try {
            // Wait for the server so that the dropdown overlay, from which this dialog has been opened,
            // only loads the label configs after the new state has reached the server.
            await this.labelConfigService.create(newLabel);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Label-Konfig nicht angelegt`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        this.enterEditMode(newLabel);
    }

    protected enterEditMode(labelConfig: LabelConfig) {
        // Leave edit mode to save changes to the item edited so far.
        if (this.labelConfigInEditMode) {
            this.leaveEditMode();
        }
        this.labelConfigInEditMode = labelConfig;
    }

    protected leaveEditMode() {
        if (!this.labelConfigInEditMode) return;

        //*****************************************************************************
        //  Duplicate Check
        //****************************************************************************/
        const allOtherLabelConfigs = this.labelConfigs.filter(
            (labelConfig) => labelConfig !== this.labelConfigInEditMode,
        );
        const labelNameExists: boolean = allOtherLabelConfigs.some(
            (labelConfig) => labelConfig.name === this.labelConfigInEditMode.name,
        );
        if (labelNameExists) {
            this.labelConfigInEditMode.name += ' (1)';
            this.leaveEditMode();
            return;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Duplicate Check
        /////////////////////////////////////////////////////////////////////////////*/

        if (this.labelConfigInEditMode && isCursorInInputOrTextarea()) {
            (document.activeElement as HTMLElement).blur();
        }

        // The blur event may have already cleared the labelConfigInEditMode, therefore there must be another check.
        if (!this.labelConfigInEditMode) return;

        // Prevent label from being empty.
        if (!this.labelConfigInEditMode.name?.trim()) {
            this.labelConfigInEditMode.name = 'Label ohne Titel';
            this.saveLabelConfig(this.labelConfigInEditMode);
        }

        this.labelConfigInEditMode = null;
    }

    protected handleKeydownInInput(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.leaveEditMode();
        }
    }

    protected async saveLabelConfig(labelConfig: LabelConfig) {
        await this.labelConfigService.put(labelConfig);
        this.emitLabelConfigChange();
    }

    protected async deleteLabelConfig(labelConfig: LabelConfig) {
        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Label löschen?',
                    content:
                        'Du kannst dann nicht mehr nach diesem Label filtern.\n\nZugewiesene Labels bleiben auf den Datensätzen bestehen, sie bleiben also einsehbar.',
                    confirmLabel: 'Löschen',
                    cancelLabel: 'Das brauche ich noch...',
                    confirmColorRed: true,
                },
            })
            .afterClosed()
            .toPromise();
        if (!decision) return;

        const { index } = removeFromArray(labelConfig, this.labelConfigs);

        try {
            await this.labelConfigService.delete(labelConfig._id);
        } catch (error) {
            this.labelConfigs.splice(index, 0, labelConfig);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Label-Konfig nicht gelöscht',
                    body: "Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }

        this.emitLabelConfigChange();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Reordering the list
    //****************************************************************************/
    protected handleReorderingDrop(event: CdkDragDrop<LabelConfig>): void {
        // Extract from the array...
        const labelConfig = this.labelConfigs.splice(event.previousIndex, 1)[0];
        // ... and insert at new position.
        this.labelConfigs.splice(event.currentIndex, 0, labelConfig);

        // Save order to server
        this.updateDragOrderPositionOnAllLabelConfigs();
    }

    private async updateDragOrderPositionOnAllLabelConfigs() {
        for (const [key, record] of this.labelConfigs.entries()) {
            const oldPosition: number = record.dragOrderPosition;
            record.dragOrderPosition = key;

            // Send updates to server
            if (record.dragOrderPosition !== oldPosition) {
                // Don't use this component's save method which would emit an event for every
                // record being re-ordered.
                await this.labelConfigService.put(record);
            }
        }

        this.emitLabelConfigChange();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reordering the list
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    protected emitLabelConfigChange() {
        this.labelConfigsChange.emit(this.labelConfigs);
    }

    public closeOverlay() {
        this.leaveEditMode();

        this.overlayRef.detach();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
    @HostBinding('@fadeInAndSlide')
    protected fadeInAndSlideAnimation = true;

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeOverlay();
        }
    }
}

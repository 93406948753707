import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploadModule } from 'ng2-file-upload';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CleanBaseModelPipe } from '@autoixpert/pipes/clean-base-model.pipe';
import { BankAccountService } from '../shared/services/Banking/bank-account.service';
import { BankTransactionService } from '../shared/services/Banking/bank-transaction.service';
import { FetchAndMatchTransactionsService } from '../shared/services/Banking/fetch-and-match-transactions.service';
import { FinancialInstitutionsService } from '../shared/services/Banking/financial-institutions.service';
import { GocardlessBankAccountConnectionService } from '../shared/services/Banking/gocardless-bank-account-connection.service';
import { InvoiceAnalyticsService } from '../shared/services/analytics/invoice-analytics.service';
import { InvoiceDetailsService } from '../shared/services/invoice-details.service';
import { InvoiceLineItemTemplateService } from '../shared/services/invoice-line-item-template.service';
import { InvoiceTemplateService } from '../shared/services/invoice-template.service';
import { InvoiceService } from '../shared/services/invoice.service';
import { DatevInvoiceExportService } from '../shared/services/invoices/datev-invoice-export.service';
import { SharedModule } from '../shared/shared.module';
import { BankTransactionListComponent } from './bank-transaction-list/bank-transaction-list.component';
import { DatevInvoicesExportDialogComponent } from './datev-invoices-export-dialog/datev-invoices-export-dialog.component';
import { DatevPaymentsExportDialogComponent } from './datev-payments-export-dialog/datev-payments-export-dialog.component';
import { GocardlessBankAccountListComponent } from './gocardless-bank-account-list/gocardless-bank-account-list.component';
import { GocardlessFinancialInstitutionsListComponent } from './gocardless-bank-account-list/gocardless-financial-institutions-list/gocardless-financial-institutions-list.component';
import { GtueRedirectPageComponent } from './gtue-redirect-page/gtue-redirect-page.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { InvoiceEditorComponent } from './invoice-editor/invoice-editor.component';
import { InvoiceInfoPanelComponent } from './invoice-info-panel/invoice-info-panel.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoicePrintAndSendComponent } from './invoice-print-and-send/invoice-print-and-send.component';
import { InvoicesComponent } from './invoices.component';
import { InvoicesRoutingModule } from './invoices.routing';
import { PartialInvoiceCancellationDialogComponent } from './partial-cancellation-dialog/partial-invoice-cancellation-dialog.component';
import { PaymentReminderDialogComponent } from './payment-reminder-dialog/payment-reminder-dialog.component';
import { PaymentsDialogComponent } from './payments-dialog/payments-dialog.component';
import { QuickPaymentComponent } from './quick-payment/quick-payment.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ScrollingModule,
        InvoicesRoutingModule,
        FileUploadModule,
        DragDropModule,
        InfiniteScrollModule,
        CleanBaseModelPipe,
    ],
    declarations: [
        GocardlessBankAccountListComponent,
        GocardlessFinancialInstitutionsListComponent,
        BankTransactionListComponent,
        DatevInvoicesExportDialogComponent,
        DatevPaymentsExportDialogComponent,
        InvoicesComponent,
        InvoiceListComponent,
        InvoiceDetailsComponent,
        InvoiceEditorComponent,
        PaymentsDialogComponent,
        QuickPaymentComponent,
        PaymentReminderDialogComponent,
        InvoicePrintAndSendComponent,
        PartialInvoiceCancellationDialogComponent,
        InvoiceInfoPanelComponent,
        GtueRedirectPageComponent,
    ],
    providers: [
        GocardlessBankAccountConnectionService,
        FinancialInstitutionsService,
        BankAccountService,
        BankTransactionService,
        DatevInvoiceExportService,
        FetchAndMatchTransactionsService,
        InvoiceService,
        InvoiceAnalyticsService,
        InvoiceDetailsService,
        InvoiceTemplateService,
        InvoiceLineItemTemplateService,
    ],
})
export class InvoicesModule {}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, defer } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { generateId } from '@autoixpert/lib/generate-id';
import { sanitizeHtmlExceptStyle } from '@autoixpert/lib/html/sanitize-html-except-style';
import { round } from '@autoixpert/lib/numbers/round';
import {
    LeaseReturn,
    LeaseReturnItem,
    LeaseReturnSection,
} from '@autoixpert/models/reports/damage-calculation/lease-return';
import {
    LeaseReturnTemplate,
    LeaseReturnTemplateItem,
} from '@autoixpert/models/reports/damage-calculation/lease-return-template';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { User } from '@autoixpert/models/user/user';
import { blockChildAnimationOnLoad } from '../../../shared/animations/block-child-animation-on-load.animation';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { MatQuillComponent } from '../../../shared/components/mat-quill/mat-quill.component';
import { getVatRate } from '../../../shared/libraries/get-vat-rate-2020';
import { convertHtmlToPlainText } from '../../../shared/libraries/strip-html';
import { capitalizeHtml } from '../../../shared/libraries/text-transformation/capitalize';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { LeaseReturnTemplateService } from '../../../shared/services/lease-return-template.service';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { ReportDetailsService } from '../../../shared/services/report-details.service';
import { ReportRealtimeEditorService } from '../../../shared/services/report-realtime-editor.service';
import { ReportService } from '../../../shared/services/report.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { PhotoGroupName } from '../shared/photos-grid/photo-grid.component';

@Component({
    selector: 'lease-return',
    templateUrl: 'lease-return.component.html',
    styleUrls: ['lease-return.component.scss'],
    animations: [fadeInAndOutAnimation(), blockChildAnimationOnLoad(), slideInAndOutVertically()],
})
export class LeaseReturnComponent {
    constructor(
        private route: ActivatedRoute,
        private reportDetailsService: ReportDetailsService,
        private reportService: ReportService,
        private loggedInUserService: LoggedInUserService,
        public userPreferences: UserPreferencesService,
        private toastService: ToastService,
        private leaseReturnTemplateService: LeaseReturnTemplateService,
        private apiErrorService: ApiErrorService,
        private domSanitizer: DomSanitizer,
        private reportRealtimeEditorService: ReportRealtimeEditorService,
    ) {}

    public report: Report;
    public reportId: string;
    public user: User;

    private subscriptions: Subscription[] = [];
    public NEW_ITEM_TITLE: string = 'Neue Position';

    // Template Selection
    public areLeaseReturnTemplatesVisible: boolean;
    public leaseReturnTemplates: LeaseReturnTemplate[] = [];
    public selectedLeaseReturnTemplate: LeaseReturnTemplate;
    public leaseReturnTemplatesPending: boolean;

    // Template originally used to instantiate lease return object
    public associatedLeaseReturnTemplate: LeaseReturnTemplate;

    // Lease Return Head
    public relativeResidualValueInEditMode: boolean;
    public relativeResidualValue: number; // This number is local to the UI and stores the value in full percent (e.g. 64% vs. .64)

    // Items
    public selectedItem: LeaseReturnItem;
    public selectedSection: LeaseReturnSection;
    public sectionsInEditMode: WeakMap<LeaseReturnSection, void> = new WeakMap();
    public itemsInEditMode: WeakMap<LeaseReturnItem, void> = new WeakMap();
    public templateItemAssociatedWithSelectedItem: LeaseReturnTemplateItem;
    public photosOfSelectedItem: Photo[] = [];
    public sectionsWithItemsCollapsed: Map<LeaseReturnSection, void> = new Map();

    @ViewChild('descriptionQuill') private descriptionQuill: MatQuillComponent;

    // Summary
    public totalRepairCostsNet: number;
    public totalAboveAverageWearCostsNet: number;
    // ********** Value Summary Graph **********
    public leaseReturnSummaryGraph: LeaseReturnSummaryGraph = {
        totalRepairCostsNetBarWidth: 0,
        totalAboveAverageWearCostsNetBarWidth: 0,
    };

    // Text Templates
    public textTemplateDialogShown: boolean;

    // Photo Editor
    photoEditorShown: boolean = false;
    initialPhotoForEditor: Photo;
    photoGroupForEditor: PhotoGroupName;

    // Template Management
    public templateManagementVisible: boolean;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        const routeSubscription = this.route.parent.params.subscribe((params) => (this.reportId = params['reportId']));
        const reportSubscription = this.reportDetailsService.get(this.reportId).subscribe((report) => {
            this.report = report;

            this.showLeaseReturnTemplatesIfEmpty();
            if (report.leaseReturn) {
                this.getAssociatedLeaseReturnTemplate();
                this.calculateSummaryValues();
                this.drawLeaseReturnSummaryGraph();
                this.updateRelativeResidualValue();
                // If the relative residual value is zero, enter the edit mode right away.
                if (!report.leaseReturn.relativeResidualValue) {
                    this.enterRelativeResidualValueEditMode();
                }
            }
        });

        this.subscriptions.push(
            routeSubscription,
            reportSubscription,
            // Update the user in case it was updated in a different tab.
            this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)),
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Lease Return Templates
    //****************************************************************************/
    public loadLeaseReturnTemplates(): void {
        this.leaseReturnTemplatesPending = true;

        this.leaseReturnTemplateService.find().subscribe({
            next: (leaseReturnTemplates) => {
                this.leaseReturnTemplates = leaseReturnTemplates;
                this.leaseReturnTemplatesPending = false;
                this.sortLeaseReturnTemplates();

                if (!this.selectedLeaseReturnTemplate) {
                    this.selectLeaseReturnTemplate(leaseReturnTemplates[0]);
                }
            },
            error: () => {
                this.leaseReturnTemplatesPending = false;
            },
        });
    }

    public async getAssociatedLeaseReturnTemplate(): Promise<void> {
        if (this.report.leaseReturn.instantiatedTemplateId) {
            this.associatedLeaseReturnTemplate = await this.leaseReturnTemplateService.get(
                this.report.leaseReturn.instantiatedTemplateId,
            );
        }
    }

    private sortLeaseReturnTemplates(): void {
        this.leaseReturnTemplates.sort((templateA, templateB) => {
            return (templateA.title || '').localeCompare(templateB.title || '');
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Lease Return Templates
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Template Selection
    //****************************************************************************/
    /**
     * Display the template selection dialog if the report's vehicle lease object is still empty.
     */
    public showLeaseReturnTemplatesIfEmpty(): void {
        if (!this.report.leaseReturn) {
            this.showLeaseReturnTemplates();
        }
    }

    public showLeaseReturnTemplates(): void {
        this.areLeaseReturnTemplatesVisible = true;
        this.loadLeaseReturnTemplates();
    }

    public hideLeaseReturnTemplates(): void {
        this.areLeaseReturnTemplatesVisible = false;
    }

    public selectLeaseReturnTemplate(template: LeaseReturnTemplate): void {
        this.selectedLeaseReturnTemplate = template;
    }

    public useLeaseReturnTemplate(leaseReturnTemplate: LeaseReturnTemplate): void {
        this.report.leaseReturn = new LeaseReturn({
            title: leaseReturnTemplate.title,
            instantiatedTemplateId: leaseReturnTemplate._id,
            sections: leaseReturnTemplate.sections.map(
                (section) =>
                    new LeaseReturnSection({
                        title: section.title,
                        items: section.items.map(
                            (item) =>
                                new LeaseReturnItem({
                                    title: item.title,
                                    isRequired: item.isRequired,
                                }),
                        ),
                    }),
            ),
            relativeResidualValue: this.getRelativeResidualValue(),
        });
        this.saveReport();
        this.associatedLeaseReturnTemplate = leaseReturnTemplate;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Template Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Relative Residual Value
    //****************************************************************************/
    /**
     * Get the remaining vehicle value based on time value and original value.
     */
    public getRelativeResidualValue(): number {
        const currentValue = this.report.valuation.vehicleValueGross;
        const originalPrice = this.report.valuation.originalPriceWithEquipmentGross;

        if (currentValue == null || originalPrice == null) return null;

        const relativeResidualValue = currentValue / originalPrice;

        // Round to two decimals, e.g. 0.64 = 64%.
        return Math.round(relativeResidualValue * 100) / 100;
    }

    public updateRelativeResidualValue(): void {
        // Update only if empty
        if (!this.report.leaseReturn.relativeResidualValue) {
            this.report.leaseReturn.relativeResidualValue = this.getRelativeResidualValue();
        }
    }

    public enterRelativeResidualValueEditMode(): void {
        if (this.isReportLocked()) {
            return;
        }

        this.getRelativeResidualValueFromReport();
        this.relativeResidualValueInEditMode = true;
    }

    public leaveRelativeResidualValueEditMode(): void {
        this.relativeResidualValueInEditMode = false;
    }

    /**
     * The report holds the relative value in decimals (e.g. .64), while the user edits it
     * in full numbers (64%). We need to convert back & forth.
     */
    public storeRelativeResidualValueOnReport(): void {
        this.report.leaseReturn.relativeResidualValue = this.relativeResidualValue / 100;
    }

    public getRelativeResidualValueFromReport(): void {
        this.relativeResidualValue = this.report.leaseReturn.relativeResidualValue * 100;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Relative Residual Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Lease Return Item Taxation
    //****************************************************************************/

    public setLeaseReturnTaxationType(netOrGross: LeaseReturn['leaseReturnItemTaxationType']): void {
        this.report.leaseReturn.leaseReturnItemTaxationType = netOrGross;
        this.calculateTaxationTypeCounterPart(netOrGross);
        this.saveReport();
    }

    public toggleLeaseReturnItemAsVatNeutral(item: LeaseReturnItem): void {
        if (this.isReportLocked()) {
            return;
        }

        item.isVatNeutral = !item.isVatNeutral;
        this.saveReport();
    }

    public calculateTaxationTypeCounterPart(netOrGross: LeaseReturn['leaseReturnItemTaxationType']) {
        if (netOrGross === 'gross') {
            this.report.leaseReturn.sections.forEach((section) =>
                section.items.forEach((item) => {
                    item.repairCostsGross = round(item.repairCostsNet * 1.19);
                    item.aboveAverageWearCostsGross = round(item.aboveAverageWearCostsNet * 1.19);
                }),
            );
        } else if (netOrGross === 'net') {
            this.report.leaseReturn.sections.forEach((section) =>
                section.items.forEach((item) => {
                    item.repairCostsNet = round(item.repairCostsGross / 1.19);
                    item.aboveAverageWearCostsNet = round(item.aboveAverageWearCostsGross / 1.19);
                }),
            );
        }
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lease Return Item Taxation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save Lease Return Template
    //****************************************************************************/
    public async saveLeaseReturnAsTemplate(): Promise<void> {
        // Shorthand
        const leaseReturn: LeaseReturn = this.report.leaseReturn;

        // Convert from regular LeaseReturn to LeaseReturnTemplate
        const newTemplate = new LeaseReturnTemplate({
            title: leaseReturn.title || 'Neuer Prüfleitfaden',
            sections: leaseReturn.sections.map((section) => ({
                _id: generateId(),
                title: section.title,
                items: section.items.map((item) => ({
                    _id: generateId(),
                    title: item.title,
                    commentTemplates: [],
                    isRequired: item.isRequired,
                })),
            })),
        });

        await this.leaseReturnTemplateService.create(newTemplate);
        this.toastService.success('Vorlage erstellt', 'Du findest sie in der Prüfleitfadenverwaltung.');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Lease Return Template
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Item List
    //****************************************************************************/

    //*****************************************************************************
    //  Collapse/Expand Sections
    //****************************************************************************/
    /**
     * Add all sections to the "collapsed" Map.
     */
    public collapseAllSections(): void {
        this.sectionsWithItemsCollapsed = new Map(this.report.leaseReturn.sections.map((section) => [section, null]));
    }

    /**
     * Remove all sections from the collapsed list by creating a pristine Map.
     */
    public expandAllSections(): void {
        this.sectionsWithItemsCollapsed = new Map();
    }

    public collapseSection(section: LeaseReturnSection): void {
        this.sectionsWithItemsCollapsed.set(section);
    }

    public expandSection(section: LeaseReturnSection): void {
        this.sectionsWithItemsCollapsed.delete(section);
    }

    /**
     * If the user double-clicked on a section header's white space, expand/collapse the section items.
     * @param section
     * @param event
     */
    public toggleSectionItemsExpandedOnDoubleClick(section: LeaseReturnSection, event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.sectionsWithItemsCollapsed.has(section) ? this.expandSection(section) : this.collapseSection(section);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Collapse/Expand Sections
    /////////////////////////////////////////////////////////////////////////////*/

    public createSectionInReport(): void {
        this.report.leaseReturn.sections.push(
            new LeaseReturnSection({
                items: [new LeaseReturnItem()],
            }),
        );
    }

    //*****************************************************************************
    //  Section Title Edit Mode
    //****************************************************************************/
    public enterEditModeForSection(section: LeaseReturnSection): void {
        this.sectionsInEditMode.set(section);
    }

    public leaveEditModeForSection(section: LeaseReturnSection): void {
        this.sectionsInEditMode.delete(section);
    }

    public leaveEditModeForSectionOnEnter(event: KeyboardEvent, section: LeaseReturnSection): void {
        if (event.key === 'Enter') {
            this.leaveEditModeForSection(section);
            this.saveReport();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Section Title Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Section Drag & Drop
    //****************************************************************************/
    public handleSectionDrop(event: CdkDragDrop<LeaseReturnSection>): void {
        moveItemInArray(this.report.leaseReturn.sections, event.previousIndex, event.currentIndex);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Section Drag & Drop
    /////////////////////////////////////////////////////////////////////////////*/

    public createItemInReport(section: LeaseReturnSection): void {
        if (this.isReportLocked()) {
            return;
        }

        const newItem = new LeaseReturnItem();
        section.items.push(newItem);
        this.enterEditModeForItem(newItem);
    }

    //*****************************************************************************
    //  Item Title Edit Mode
    //****************************************************************************/
    public enterEditModeForItem(item: LeaseReturnItem): void {
        this.itemsInEditMode.set(item);
    }

    public leaveEditModeForItem(item: LeaseReturnItem): void {
        this.itemsInEditMode.delete(item);
    }

    public leaveEditModeOnEnter(event: KeyboardEvent, item: LeaseReturnItem): void {
        if (event.key === 'Enter') {
            this.leaveEditModeForItem(item);
            this.saveReport();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Item Title Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Item Drag & Drop
    //****************************************************************************/
    public handleItemDrop(section: LeaseReturnSection, event: CdkDragDrop<LeaseReturnItem>): void {
        const element = section.items.splice(event.previousIndex, 1)[0];
        section.items.splice(event.currentIndex, 0, element);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Item Drag & Drop
    /////////////////////////////////////////////////////////////////////////////*/

    public getAllItems(): LeaseReturnItem[] {
        return this.report.leaseReturn.sections.reduce((accumulator, section) => {
            accumulator.push(...section.items);
            return accumulator;
        }, []);
    }

    public getRequiredItems(section: LeaseReturnSection): LeaseReturnItem[] {
        return section.items.filter((item) => item.isRequired);
    }

    public getRemainingRequiredItems(section: LeaseReturnSection): LeaseReturnItem[] {
        return this.getRequiredItems(section).filter(
            (item) => item.repairCostsNet == null && item.aboveAverageWearCostsNet == null,
        );
    }

    public selectSection(section: LeaseReturnSection): void {
        this.selectedSection = section;
    }

    public findSectionByItem(item: LeaseReturnItem): LeaseReturnSection {
        return this.report.leaseReturn.sections.find((section) => section.items.includes(item));
    }

    public selectItem(item: LeaseReturnItem): void {
        this.selectedItem = item;
        this.photosOfSelectedItem = this.getPhotosOfItem(item);

        const section = this.findSectionByItem(item);
        this.selectSection(section);
        this.findTemplateItemAssociatedWithSelectedItem();
    }

    /**
     * Text templates are stored on the lease return template.
     * Find the lease return template item associated with the selected item on report.leaseReturn.
     */
    public findTemplateItemAssociatedWithSelectedItem(): void {
        const associatedSection = this.associatedLeaseReturnTemplate.sections.find(
            (section) => section.title === this.selectedSection.title,
        );
        if (associatedSection) {
            this.templateItemAssociatedWithSelectedItem = associatedSection.items.find(
                (item) => item.title === this.selectedItem.title,
            );
            return;
        }
        // If the section cannot be found, try to simply match by the item name
        else {
            const allItemsFromTemplate = this.associatedLeaseReturnTemplate.sections.reduce((accumulator, section) => {
                accumulator.push(...section.items);
                return accumulator;
            }, []);
            this.templateItemAssociatedWithSelectedItem = allItemsFromTemplate.find(
                (item) => item.title === this.selectedItem.title,
            );
        }
    }

    public selectNextLeaseReturnItem(direction: 'next' | 'previous'): void {
        const allItems = this.getAllItems();
        const indexOfCurrentItem: number = allItems.indexOf(this.selectedItem);
        const targetItem = allItems[indexOfCurrentItem + (direction === 'next' ? 1 : -1)];

        if (!targetItem) {
            this.toastService.info(`${direction === 'next' ? 'Letztes' : 'Erstes'} Element erreicht`);
            return;
        }

        this.selectItem(targetItem);
    }

    public isItemComplete(item: LeaseReturnItem): boolean {
        return !!(
            item.repairCostsNet ||
            item.repairCostsGross ||
            item.aboveAverageWearCostsGross ||
            item.aboveAverageWearCostsNet ||
            item.comment
        );
    }

    public calculateRepairCostsGross() {
        this.selectedItem.repairCostsGross = round(this.selectedItem.repairCostsNet * (1 + this.getItemsVatRate()));
    }

    public calculateRepairCostsNet() {
        this.selectedItem.repairCostsNet = round(this.selectedItem.repairCostsGross / (1 + this.getItemsVatRate()));
    }

    public calculateDiminishedValueGross() {
        this.selectedItem.aboveAverageWearCostsGross = round(
            this.selectedItem.aboveAverageWearCostsNet * (1 + this.getItemsVatRate()),
        );
    }

    public calculateDiminishedValueNet() {
        this.selectedItem.aboveAverageWearCostsNet = round(
            this.selectedItem.aboveAverageWearCostsGross / (1 + this.getItemsVatRate()),
        );
    }

    public getItemsVatRate(item: LeaseReturnItem = this.selectedItem): 0 | 0.16 | 0.19 {
        return item.isVatNeutral ? 0 : getVatRate(this.report.orderDate);
    }

    public deleteSection(section: LeaseReturnSection): void {
        if (this.isReportLocked()) {
            return;
        }

        removeFromArray(section, this.report.leaseReturn.sections);
    }

    public deleteItem(item: LeaseReturnItem, section: LeaseReturnSection): void {
        if (this.isReportLocked()) {
            return;
        }

        removeFromArray(item, section.items);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Item List
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public calculateSummaryValues(): void {
        const allItems = this.getAllItems();

        if (this.report.leaseReturn.leaseReturnItemTaxationType === 'net') {
            this.totalRepairCostsNet = allItems.reduce((total, item) => total + item.repairCostsNet, 0);
            this.totalAboveAverageWearCostsNet = allItems.reduce(
                (total, item) => total + item.aboveAverageWearCostsNet,
                0,
            );
        } else if (this.report.leaseReturn.leaseReturnItemTaxationType === 'gross') {
            const allItemsGross = allItems.filter((item) => !item.isVatNeutral);
            const allItemsNet = allItems.filter((item) => item.isVatNeutral);
            this.totalRepairCostsNet =
                allItemsGross.reduce((total, item) => total + item.repairCostsGross, 0) +
                allItemsNet.reduce((total, item) => total + item.repairCostsNet, 0);
            this.totalAboveAverageWearCostsNet =
                allItemsGross.reduce((total, item) => total + item.aboveAverageWearCostsGross, 0) +
                allItemsNet.reduce((total, item) => total + item.aboveAverageWearCostsNet, 0);
        }
    }

    public drawLeaseReturnSummaryGraph(): void {
        const propertiesToDisplay: number[] = [this.totalRepairCostsNet, this.totalAboveAverageWearCostsNet];
        const highestValue = Math.max(...propertiesToDisplay);

        // In case the values have not been initialized or are zero.
        if (!highestValue) {
            this.leaseReturnSummaryGraph.totalRepairCostsNetBarWidth = 0;
            this.leaseReturnSummaryGraph.totalAboveAverageWearCostsNetBarWidth = 0;
            return;
        }

        this.leaseReturnSummaryGraph.totalRepairCostsNetBarWidth = (this.totalRepairCostsNet / highestValue) * 100;
        this.leaseReturnSummaryGraph.totalAboveAverageWearCostsNetBarWidth =
            (this.totalAboveAverageWearCostsNet / highestValue) * 100;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Lease Item Editor
    //****************************************************************************/
    public setDiminishedValue(targetValue: number): void {
        if (this.isReportLocked()) {
            return;
        }

        if (this.report.leaseReturn.leaseReturnItemTaxationType === 'net') {
            this.selectedItem.aboveAverageWearCostsNet = targetValue;
            this.calculateDiminishedValueGross();
        } else {
            this.selectedItem.aboveAverageWearCostsGross = targetValue;
            this.calculateDiminishedValueNet();
        }
    }

    public appendTextToDescription(htmlText: string): void {
        if (this.isReportLocked()) {
            return;
        }

        // Use "trim()" because Quill's empty content is "\n".
        const comment: string = this.descriptionQuill.quillInstance.getText().trim();

        // Remove HTML from custom templates.
        if (!comment) {
            const htmlWithFirstLetterCapitalized = capitalizeHtml(htmlText);
            this.descriptionQuill.quillInstance.setContents(
                this.descriptionQuill.quillInstance.clipboard.convert(
                    sanitizeHtmlExceptStyle(htmlWithFirstLetterCapitalized, this.domSanitizer),
                ),
            );
        } else {
            // Ensure the cursor is at the end, so the template is inserted at the end.
            this.descriptionQuill.quillInstance.setSelection(
                {
                    index: this.descriptionQuill.quillInstance.getLength(),
                    length: 0,
                },
                'user',
            );

            let newContentHtml: string;
            // After a period, capitalize.
            if (comment.endsWith('.')) {
                this.descriptionQuill.quillInstance.insertText(
                    this.descriptionQuill.quillInstance.getSelection().index,
                    ' ',
                );
                newContentHtml = capitalizeHtml(htmlText);
            }
            // After a comma, don't add an extra comma.
            else if (comment.endsWith(',')) {
                this.descriptionQuill.quillInstance.insertText(
                    this.descriptionQuill.quillInstance.getSelection().index,
                    ' ',
                );
                newContentHtml = htmlText;
            }
            // After all other (or none) punctuation marks, add a comma and continue.
            else {
                this.descriptionQuill.quillInstance.insertText(
                    this.descriptionQuill.quillInstance.getSelection().index,
                    ', ',
                );
                newContentHtml = htmlText;
            }
            const sanitizedHtml = sanitizeHtmlExceptStyle(newContentHtml, this.domSanitizer);
            this.descriptionQuill.quillInstance.clipboard.dangerouslyPasteHTML(
                this.descriptionQuill.quillInstance.getSelection().index,
                sanitizedHtml,
                'user',
            );
        }
    }

    public convertHtmlToPlainText = convertHtmlToPlainText;

    //*****************************************************************************
    //  Text Templates
    //****************************************************************************/
    public showTextTemplatesDialog(): void {
        this.textTemplateDialogShown = true;
    }

    public hideTextTemplatesDialog(): void {
        this.textTemplateDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Text Templates
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lease Item Editor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Grid
    //****************************************************************************/
    /**
     * Get photos associated with this item in the order set by item.photoIds.
     * @param item
     * @private
     */
    private getPhotosOfItem(item: LeaseReturnItem): Photo[] {
        const photosSorted: Photo[] = [];

        // Retrieve photos in the order set by item.photoIds
        for (const photoId of item.photoIds) {
            const matchingPhoto = this.report.photos.find((photo) => photo._id === photoId);
            if (matchingPhoto) {
                photosSorted.push(matchingPhoto);
            }
        }
        return photosSorted;
    }

    public syncItemPhotosWithReportPhotos(photos: Photo[]): void {
        let changesMade: boolean;
        for (const photo of photos) {
            // Make sure that all photos in this item also exist in the report.photos array
            const photoExistsInReport: boolean = this.report.photos.some(
                (reportPhoto) => reportPhoto._id === photo._id,
            );
            if (!photoExistsInReport) {
                this.report.photos.push(photo);
                changesMade = true;
            }
        }

        // Make sure all photo IDs are connected to the lease return item.
        const oldPhotos = JSON.stringify(this.selectedItem.photoIds);
        this.selectedItem.photoIds = photos.map((photo) => photo._id);
        if (oldPhotos !== JSON.stringify(this.selectedItem.photoIds)) {
            changesMade = true;
        }

        if (changesMade) {
            this.saveReport();
        }
    }

    /**
     * When deleting photos from an item, remove them from the report.photos array too.
     * @param deletedPhotos
     */
    public removePhotosFromReportPhotos(deletedPhotos: Photo[]): void {
        let changesMade: boolean;

        for (const deletedPhoto of deletedPhotos) {
            const index = this.report.photos.indexOf(deletedPhoto);
            if (index > -1) {
                this.report.photos.splice(index, 1);
                changesMade = true;
            }
        }

        if (changesMade) {
            this.saveReport();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Grid
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photo Editor
    //****************************************************************************/

    public handleEditorOpenClick({ photo, photoGroup }: { photo: Photo; photoGroup: PhotoGroupName }): void {
        this.initialPhotoForEditor = photo;
        this.photoGroupForEditor = photoGroup;
    }

    public openPhotoEditor(): void {
        this.photoEditorShown = true;
    }

    public closeEditor(): void {
        this.photoEditorShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photo Editor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Template Management
    //****************************************************************************/
    public openTemplateManagement(): void {
        this.templateManagementVisible = true;
    }

    public closeTemplateManagement(): void {
        this.templateManagementVisible = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Template Management
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Locked
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report && this.report.state === 'done';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Locked
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Realtime Editors
    //****************************************************************************/
    public joinAsRealtimeEditor() {
        this.reportRealtimeEditorService.joinAsEditor({
            recordId: this.report._id,
            currentTab: 'accidentData',
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Realtime Editors
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    public saveReport(): void {
        this.saveReport$.subscribe({
            error: (error) => {
                this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
                console.error('An error occurred while saving the report via the ReportService.', this.report, {
                    error,
                });
            },
        });
    }

    public saveReport$: Observable<Report> = defer(() => fromPromise(this.reportService.put(this.report)));

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}

interface LeaseReturnSummaryGraph {
    totalRepairCostsNetBarWidth: number;
    totalAboveAverageWearCostsNetBarWidth: number;
}

import { Report } from '@autoixpert/models/reports/report';

export function getReportTypeAbbreviation(reportType: Report['type']) {
    let reportTypeInitials: string;

    if (!reportType) {
        return '';
    }

    switch (reportType) {
        case 'liability':
            reportTypeInitials = 'HS';
            break;
        case 'shortAssessment':
            reportTypeInitials = 'KG';
            break;
        case 'partialKasko':
            reportTypeInitials = 'TK';
            break;
        case 'fullKasko':
            reportTypeInitials = 'VK';
            break;
        case 'valuation':
            reportTypeInitials = 'BE';
            break;
        case 'oldtimerValuationSmall':
            reportTypeInitials = 'OT';
            break;
        case 'leaseReturn':
            reportTypeInitials = 'ZB'; // Zustandsbericht
            break;
        case 'usedVehicleCheck':
            reportTypeInitials = 'GC';
            break;
        case 'invoiceAudit':
            reportTypeInitials = 'RP';
            break;
        // case 'oldtimerValuationLarge':
        //     reportTypeInitials = 'WG';
        //     break;
        default:
            throw Error('NO_ABBREVIATION_FOR_REPORT_TYPE');
    }

    return reportTypeInitials;
}

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';
import { GERMAN_DATE_FULL } from '@autoixpert/lib/date-and-time/date-formats';
import { Invoice } from '@autoixpert/models/invoices/invoice';

/**
 * A pipe that returns a label stating the invoice date and (if set) the due date.
 */
@Pipe({
    name: 'invoiceDateAndOptionalDueDate',
})
export class InvoiceDateAndOptionalDueDatePipe implements PipeTransform {
    transform(invoice: Invoice): string {
        let tooltip = `Rechnungsdatum: ${moment(invoice.date).format(GERMAN_DATE_FULL)}`;

        if (invoice.dueDate) {
            tooltip += ` | Zahlungsziel: ${moment(invoice.dueDate).format(GERMAN_DATE_FULL)}`;
        }

        return tooltip;
    }
}

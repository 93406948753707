<!--***********************************************
** Title
************************************************-->
<div id="title-container" *ngIf="showTitle">
    <h3 *ngIf="!isTitleEditModeActive">
        {{ documentLayoutGroup.title }}
        <mat-icon
            class="title-edit-icon input-toolbar-icon small-icon"
            *ngIf="!disabled"
            (click)="enterTitleEditMode()"
            matTooltip="Titel bearbeiten. Der Titel dient nur der internen Verwendung und wird nicht abgedruckt.">
            edit
        </mat-icon>

        <!--********** Menu **********-->
        <mat-icon class="title-edit-icon input-toolbar-icon small-icon" [matMenuTriggerFor]="documentLayoutGroupMenu">
            more_vert
        </mat-icon>
        <mat-menu #documentLayoutGroupMenu>
            <a
                mat-menu-item
                class="menu-item-with-icon alert"
                (click)="deleteDocumentLayoutGroup()"
                [class.disabled]="documentLayoutGroup.isDefault">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>
    </h3>
    <div class="title-input-container" *ngIf="isTitleEditModeActive">
        <mat-form-field>
            <input
                matInput
                placeholder="Titel"
                [(ngModel)]="documentLayoutGroup.title"
                (change)="saveDocumentLayoutGroup()"
                (keydown)="leaveTitleEditModeOnEnter($event)"
                [axAutofocus]="true" />
            <mat-icon
                matSuffix
                class="input-toolbar-icon"
                (click)="leaveTitleEditMode()"
                matTooltip="Bearbeitung beenden">
                check
            </mat-icon>
        </mat-form-field>
    </div>
</div>
<!--***********************************************
** END Title
************************************************-->

<div id="layout-config-and-color-container">
    <!--***********************************************
    ** Layout Configs
    ************************************************-->
    <div id="document-layout-config-list">
        <document-layout-config
            *ngFor="let documentLayoutConfig of documentLayoutGroup.configs"
            (change)="saveDocumentLayoutGroup()"
            (delete)="removeDocumentLayoutConfig($event); saveDocumentLayoutGroup()"
            (default)="removeDefaultFromOtherLayoutConfigs($event)"
            [disabled]="disabled"
            [documentOrderConfigs]="documentOrderConfigs"
            [documentLayoutGroupId]="documentLayoutGroup._id"
            [documentLayoutConfig]="documentLayoutConfig"></document-layout-config>

        <section id="add-document-layout-config-container">
            <button id="add-document-layout-config-button" class="flat" (click)="addDocumentLayoutConfig()">
                <mat-icon class="small-icon">add</mat-icon>
                Briefpapier
            </button>
        </section>
    </div>
    <!--***********************************************
    ** END Layout Configs
    ************************************************-->

    <div id="accent-color-and-associated-office-locations">
        <!--***********************************************
        ** Colors
        ************************************************-->
        <div id="colors-container">
            <!--********** Accent Color **********-->
            <div class="color-row">
                <div
                    class="color-icon-and-label"
                    (click)="!disabled && openAccentColorpicker()"
                    [class.disabled]="disabled"
                    [matTooltip]="
                        !disabled
                            ? 'Die Akzentfarbe ist ein Hingucker auf deinen Dokumenten. Du und deine Kunden finden sie in Linien, Boxen und hervorgehobenen Werten. Die Farbe sollte zu deinem Logo passen.'
                            : getAskYourAdminTooltip()
                    ">
                    <svg
                        class="icon"
                        [attr.fill]="documentLayoutGroup.accentColorDocx"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                    <div class="label">Akzentfarbe</div>
                </div>

                <input
                    type="color"
                    [ngModel]="documentLayoutGroup.accentColorDocx"
                    (ngModelChange)="setAccentColorDocx($event)"
                    #accentColorpicker
                    [disabled]="disabled" />

                <!--********** Submenu **********-->
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="!showDamageSketchArrowAndCrossColor || !showSecondaryAccentColor"
                    [matMenuTriggerFor]="colorPickerMenu">
                    more_vert
                </mat-icon>
                <!--********** Prevent jumping when the three-dot-menu is hidden by using an invisible and functionless copy. **********-->
                <mat-icon
                    class="toolbar-icon"
                    *ngIf="showDamageSketchArrowAndCrossColor && showSecondaryAccentColor"
                    style="visibility: hidden">
                    more_vert
                </mat-icon>
                <mat-menu #colorPickerMenu>
                    <a
                        mat-menu-item
                        (click)="showArrowAndCrossColor()"
                        matTooltip="Verwende für die Pfeile und Kreuze der Anstoßskizze eine andere Farbe als die Akzentfarbe">
                        Abweichende Farbe für Anstoßskizze
                    </a>
                    <a mat-menu-item (click)="showSecondaryAccentColorSettings()">
                        Abweichende sekundäre Akzentfarbe
                        <span
                            class="help-indicator"
                            (click)="openHelpCenterArticleOnSecondaryAccentColor(); $event.stopPropagation()"
                            matTooltip="Wähle deine eigene sekundäre Akzentfarbe. Diese wird beispielsweise als Hintergrund auf dem Bewertungs-Deckblatt verwendet. Klicke auf das Fragezeichen, um mehr zu erfahren.">
                            ?
                        </span>
                    </a>
                </mat-menu>
            </div>

            <!--********** Secondary Accent Color **********-->
            <div id="secondary-accent-color-container" class="color-row" *ngIf="!disabled && showSecondaryAccentColor">
                <div
                    class="color-icon-and-label"
                    (click)="!disabled && openSecondaryAccentColorPicker()"
                    [class.disabled]="disabled">
                    <!--********** Put the tooltip on the icon and the label instead of the parent to prevent an overlapping tooltip when
                     hovering the delete button. **********-->
                    <svg
                        id="secondary-accent-color-palette-icon"
                        class="icon"
                        [matTooltip]="
                            !disabled
                                ? 'Die helle Variante der Akzentfarbe. Beispielsweise verwendet im Deckblatt Bewertung.'
                                : getAskYourAdminTooltip()
                        "
                        [attr.fill]="documentLayoutGroup.secondaryAccentColorDocx"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                    <div
                        class="label"
                        [matTooltip]="
                            !disabled
                                ? 'Die helle Variante der Akzentfarbe. Beispielsweise verwendet im Deckblatt Bewertung.'
                                : getAskYourAdminTooltip()
                        ">
                        2. Akzentfarbe
                    </div>
                    <mat-icon
                        *ngIf="showSecondaryAccentColor"
                        class="toolbar-icon medium-icon"
                        (click)="hideSecondaryAccentColor()"
                        matTooltip="Die sekundäre Akzentfarbe automatisch von der primären Akzentfarbe ableiten lassen.">
                        delete
                    </mat-icon>
                </div>

                <input
                    type="color"
                    [ngModel]="documentLayoutGroup.secondaryAccentColorDocx"
                    (ngModelChange)="setSecondaryAccentColor($event)"
                    #secondaryAccentColorPicker
                    [disabled]="disabled" />
            </div>
            <!--********** Arrow and Cross Color **********-->
            <div
                id="damage-sketch-arrow-and-cross-color-container"
                class="color-row"
                *ngIf="!disabled && showDamageSketchArrowAndCrossColor">
                <div
                    class="color-icon-and-label"
                    (click)="!disabled && openDamageSketchArrowAndCrossColorpicker()"
                    [class.disabled]="disabled">
                    <!--********** Put the tooltip on the icon and the label instead of the parent to prevent an overlapping tooltip when
                         hovering the delete button. **********-->
                    <svg
                        class="icon"
                        [matTooltip]="
                            !disabled
                                ? 'Die Farbe der Pfeile und Kreuze in der Anstoßskizze.'
                                : getAskYourAdminTooltip()
                        "
                        [attr.fill]="documentLayoutGroup.damageSketchArrowAndCrossColor"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                    <div
                        class="label"
                        [matTooltip]="
                            !disabled
                                ? 'Die Farbe der Pfeile und Kreuze in der Anstoßskizze.'
                                : getAskYourAdminTooltip()
                        ">
                        Pfeilfarbe
                    </div>
                    <mat-icon
                        *ngIf="showDamageSketchArrowAndCrossColor"
                        class="toolbar-icon medium-icon"
                        (click)="hideArrowAndCrossColor()"
                        matTooltip="Verwende für die Pfeile und Kreuze der Anstoßskizze die Akzentfarbe (Standard)">
                        delete
                    </mat-icon>
                </div>

                <input
                    type="color"
                    [ngModel]="documentLayoutGroup.damageSketchArrowAndCrossColor"
                    (ngModelChange)="setDamageSketchArrowAndCrossColor($event)"
                    #damageSketchArrowAndCrossColorpicker
                    [disabled]="disabled" />
            </div>
            <!--***********************************************
            ** END Colors
            ************************************************-->
        </div>

        <!--***********************************************
        ** Office Locations
        ************************************************-->
        <div id="office-locations-container" *ngIf="showOfficeLocations">
            <h3>
                Standorte
                <!--********** Default **********-->
                <mat-icon
                    id="default-icon"
                    class="small-icon toolbar-icon"
                    (click)="setAsDefault()"
                    [ngClass]="{ active: documentLayoutGroup.isDefault }"
                    [matTooltip]="getSetAsDefaultTooltip()">
                    star
                </mat-icon>
            </h3>

            <!--********** If default layout group **********-->
            <div
                id="all-others"
                *ngIf="documentLayoutGroup.isDefault"
                class="label"
                matTooltip="Diese Layoutgruppe wurde als Standard markiert.{{
                    '\n'
                }}Sie wird für alle Standorte verwendet, die keine explizite Zuordnung zu einer anderen Layoutgruppe haben.">
                Alle anderen
            </div>
            <!--********** If not default layout group **********-->
            <office-location-list
                *ngIf="!documentLayoutGroup.isDefault"
                [availableOfficeLocations]="availableOfficeLocations"
                [officeLocations]="team.officeLocations"
                [selectedOfficeLocationIds]="documentLayoutGroup.associatedOfficeLocationIds"
                (change)="saveDocumentLayoutGroup()"></office-location-list>
        </div>
        <!--***********************************************
        ** END Office Locations
        ************************************************-->
    </div>
</div>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { matchAssociatedOfficeLocation } from '@autoixpert/lib/counters/match-associated-office-location';
import {
    CounterResetCycle,
    InvoiceNumberOrReportTokenCounter,
} from '@autoixpert/models/teams/invoice-number-or-report-token-counter';
import {
    InvoiceNumberConfig,
    InvoiceOrReportCounterConfig,
    ReportTokenConfig,
} from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { OfficeLocation } from '@autoixpert/models/teams/office-location';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { slideInAndOutVertically } from '../../shared/animations/slide-in-and-out-vertical.animation';
import { getAskYourAdminTooltip } from '../../shared/libraries/get-ask-your-admin-tooltip';
import { getTokenPreview } from '../../shared/libraries/preferences/get-token-preview';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { InvoiceNumberOrReportTokenCounterService } from '../../shared/services/invoice-number-or-report-token-counter.service';
import { TeamService } from '../../shared/services/team.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';

@Component({
    selector: 'token-pattern-editor',
    templateUrl: 'token-pattern-editor.component.html',
    styleUrls: ['token-pattern-editor.component.scss'],
    animations: [slideInAndOutVertically()],
})
export class TokenPatternEditorComponent implements OnInit, OnDestroy {
    constructor(
        public tutorialStateService: TutorialStateService,
        private counterService: InvoiceNumberOrReportTokenCounterService,
        private teamService: TeamService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
    ) {}

    @Input() team: Team;
    @Input() user: User;
    @Input() teamMembers: User[];

    protected counterType: 'reportToken' | 'invoiceNumber';
    protected counterConfig: InvoiceOrReportCounterConfig;

    @Input() reportTokenConfig: ReportTokenConfig | null;
    @Input() invoiceNumberConfig: InvoiceNumberConfig | null;
    @Input() allConfigs: InvoiceOrReportCounterConfig[] = [];
    public counter: InvoiceNumberOrReportTokenCounter = new InvoiceNumberOrReportTokenCounter();

    @Input() showOfficeLocation: boolean = false;
    @Input() disabled: boolean;

    // Pass invoice number counter to report token config
    @Input() counterIdToSyncWith: string;
    protected counterToSyncWith = new InvoiceNumberOrReportTokenCounter();
    private changedCounterIdSubscription: Subscription;

    @Input() showBorderBottom: boolean = false;

    @Input() isExpanded: boolean = false;
    @Input() canCollapse: boolean = false;

    @Output() isExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Invoice number journal entry
    public previousCounterPattern: string;
    public previousCounterCount: number;

    // Report Token & Invoice Number
    public availableTokenPlaceholders: { title: string; placeholder: string }[] = [
        {
            title: 'Tag',
            placeholder: '{TT}',
        },
        {
            title: 'Monat',
            placeholder: '{MM}',
        },
        {
            title: 'Jahr (4-stellig)',
            placeholder: '{JJJJ}',
        },
        {
            title: 'Jahr (2-stellig)',
            placeholder: '{JJ}',
        },
        {
            title: 'Zähler',
            placeholder: '{###}',
        },
        {
            title: 'Gutachtentyp',
            placeholder: '{TYP}',
        },
        {
            title: 'Initialen',
            placeholder: '{INI}',
        },
        {
            title: 'Standort (Kürzel)',
            placeholder: '{STO}',
        },
    ];
    public popularReportTokenPatterns: { title: string; pattern: string }[] = [
        {
            title: 'Jahr, Monat & Zähler',
            pattern: '{JJJJ}{MM}-{###}',
        },
        {
            title: 'Präfix, Jahr, Monat & Zähler',
            pattern: 'GA-{JJ}{MM}/{###}',
        },
    ];

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        if (this.invoiceNumberConfig && !this.reportTokenConfig) {
            this.counterConfig = this.invoiceNumberConfig;
            this.counterType = 'invoiceNumber';
        } else if (this.reportTokenConfig && !this.invoiceNumberConfig) {
            this.counterConfig = this.reportTokenConfig;
            this.counterType = 'reportToken';
        } else {
            return;
        }
        this.loadCounter();
        this.loadConfigAndCounterToSync();

        /**
         * Update the counter if the counterIdToSyncWith changed.
         */
        this.changedCounterIdSubscription = this.counterService.listenOnChangedCounter().subscribe({
            next: (changedCounterId) => {
                if (changedCounterId === this.counterIdToSyncWith) {
                    this.loadConfigAndCounterToSync();
                }
            },
        });
    }

    ngOnDestroy() {
        this.changedCounterIdSubscription.unsubscribe();
    }

    private async loadCounter() {
        this.counter = await this.counterService.get(this.counterConfig._id);
    }

    private async loadConfigAndCounterToSync() {
        if (this.counterIdToSyncWith) {
            this.counterToSyncWith = await this.counterService.get(this.counterIdToSyncWith);
            this.invoiceNumberConfig = this.getInvoiceNumberCounterToSyncWith(this.reportTokenConfig);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Token Preview
    //****************************************************************************/

    /**
     * Shorthand function to call the getTokenPreview function with all parameters (convenience in template).
     */
    protected getTokenPreviewString(pattern?: string): string {
        return getTokenPreview(
            this.counter,
            this.counterConfig,
            this.counterType,
            this.isInvoiceNumberSyncedAndLeading(),
            this.team,
            this.user,
            pattern,
            this.counterToSyncWith,
        );
    }

    /**
     * Add to existing pattern.
     * @param placeholder
     */
    public insertReportTokenPlaceholder(placeholder: string) {
        if (!this.counterConfig.pattern) {
            this.counterConfig.pattern = '';
        }

        this.counterConfig.pattern += placeholder;
        this.markTutorialStepAsComplete();
    }

    /**
     * Replace the existing pattern.
     * @param pattern
     */
    public async replaceReportTokenPattern(pattern: string) {
        this.counterConfig.pattern = pattern;
        this.markTutorialStepAsComplete();

        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            invoiceNumberConfigId: this.counterType === 'invoiceNumber' ? this.invoiceNumberConfig?._id : undefined,
            reportTokenConfigId: this.counterType === 'reportToken' ? this.reportTokenConfig?._id : undefined,
            counterPattern: this.counterConfig.pattern,
            previousCounterPattern: this.previousCounterPattern,
        });
    }

    public async handleCounterPatternChange() {
        this.markTutorialStepAsComplete();
        await this.saveTeam();
        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            invoiceNumberConfigId: this.counterType === 'invoiceNumber' ? this.invoiceNumberConfig?._id : undefined,
            reportTokenConfigId: this.counterType === 'reportToken' ? this.reportTokenConfig?._id : undefined,
            counterPattern: this.counterConfig.pattern,
            previousCounterPattern: this.previousCounterPattern,
        });
    }

    public async handleCounterCountChange() {
        await this.patchCount();
        this.tutorialStateService.markUserTutorialStepComplete('reportTokenConfigured');
        this.tutorialStateService.markTeamSetupStepComplete('reportTokenAndInvoiceNumberPattern');

        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            invoiceNumberConfigId: this.counterType === 'invoiceNumber' ? this.invoiceNumberConfig?._id : undefined,
            reportTokenConfigId: this.counterType === 'reportToken' ? this.reportTokenConfig?._id : undefined,
            counterCount: this.counter.count,
            previousCounterCount: this.previousCounterCount,
        });
    }

    public getCounterResetCycle(): CounterResetCycle {
        if (this.isInvoiceNumberSyncedAndLeading()) {
            return null;
        }
        return this.counter?.resetCycle;
    }

    public async setCounterResetCycle(cycle: CounterResetCycle) {
        if (this.disabled) {
            this.toastService.info(
                'Admin-Rechte erforderlich',
                'Bitte kontaktiere den Account-Inhaber wegen dieser Einstellung.',
            );
            return;
        }

        if (this.isInvoiceNumberSyncedAndLeading()) {
            // If synced with an invoice counter -> don't let the user change the reset cycle
            return;
        }

        const previousResetCycle = this.counter.resetCycle;
        this.counter.resetCycle = cycle;
        this.counter = await this.counterService.patch(this.counter);
        this.markTutorialStepAsComplete();

        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            invoiceNumberConfigId: this.counterType === 'invoiceNumber' ? this.invoiceNumberConfig?._id : undefined,
            reportTokenConfigId: this.counterType === 'reportToken' ? this.reportTokenConfig?._id : undefined,
            resetCycle: cycle,
            previousResetCycle,
        });
    }

    public tokenHasMoreThanThreeDigits(token: string): boolean {
        return !token?.includes('###');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Token Preview
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Counter Value
    //****************************************************************************/

    public async patchCount() {
        try {
            await this.counterService.patch(this.counter);
        } catch (error) {
            this.toastService.error(
                'Zähler nicht zurückgesetzt',
                'Bitte lade die Seite neu und probiere es noch einmal.',
            );
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Counter Value
    /////////////////////////////////////////////////////////////////////////////*/

    public getSetAsDefaultTooltip(tokenConfig: InvoiceOrReportCounterConfig): string {
        const tooltip = tokenConfig.isDefault ? '' : 'Als Standard setzen.\n\n';
        const explanation =
            this.counterType === 'invoiceNumber'
                ? 'Der Standard-Rechnungskreis gilt für alle Standorte, die mit keinem Rechnungskreis verknüpft sind.'
                : 'Das Standard-Aktenzeichen gilt für alle Standorte, die mit keinem Aktenzeichenkreis verknüpft sind.';

        return `${tooltip}${explanation}`;
    }
    public setAsDefault(newDefaultTokenConfig: InvoiceOrReportCounterConfig) {
        newDefaultTokenConfig.isDefault = true;

        // Remove default from others
        for (const config of this.allConfigs) {
            if (config._id !== newDefaultTokenConfig._id && config.isDefault) {
                config.isDefault = false;
                this.saveTeam();
            }
        }
    }

    //*****************************************************************************
    //  Office Locations
    //****************************************************************************/

    /**
     * Only office locations which are not yet linked to any invoice number config may be added to a invoice number config.
     */
    public getOfficeLocationsWithoutAssociation(): OfficeLocation[] {
        const associatedOfficeLocationIds: OfficeLocation['_id'][] = [];
        for (const invoiceNumberConfig of this.allConfigs) {
            if (Array.isArray(invoiceNumberConfig.associatedOfficeLocationIds)) {
                associatedOfficeLocationIds.push(...invoiceNumberConfig.associatedOfficeLocationIds);
            }
        }

        return this.team.officeLocations.filter(
            (officeLocation) => associatedOfficeLocationIds.includes(officeLocation._id) === false,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Office Locations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Number Counter for Sync
    //****************************************************************************/

    public getInvoiceNumberCounterToSyncWith(reportTokenConfig: ReportTokenConfig): InvoiceNumberConfig {
        return matchAssociatedOfficeLocation<InvoiceNumberConfig>(
            this.team.invoicing.invoiceNumberConfigs,
            reportTokenConfig.associatedOfficeLocationIds?.[0],
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Number Counter for Sync
    /////////////////////////////////////////////////////////////////////////////*/

    protected isInvoiceNumberSyncedAndLeading(): boolean {
        return (
            this.counterType === 'reportToken' &&
            this.reportTokenConfig?.syncWithInvoiceCounter &&
            this.reportTokenConfig?.leadingCounter === 'invoiceNumber'
        );
    }

    //*****************************************************************************
    //  Tutorial
    //****************************************************************************/
    /**
     * Mark the tutorial step associated with the counter type as complete.
     */
    public markTutorialStepAsComplete() {
        if (this.counterType === 'reportToken') {
            this.tutorialStateService.markUserTutorialStepComplete('reportTokenConfigured');
        } else if (this.counterType === 'invoiceNumber') {
            this.tutorialStateService.markUserTutorialStepComplete('invoiceNumberConfigured');
        }
        this.tutorialStateService.markTeamSetupStepComplete('reportTokenAndInvoiceNumberPattern');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tutorial
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tooltips
    //****************************************************************************/
    public getAskYourAdminTooltip(): string {
        return getAskYourAdminTooltip(this.team, this.teamMembers);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tooltips
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Utilities
    //****************************************************************************/
    public isANumber(element: any): boolean {
        return !isNaN(element);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Utilities
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handlers - could we optimize this?
    //****************************************************************************/
    // TODO: can we make the save operation a method of team object?
    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  Handlers - could we optimize this?
    /////////////////////////////////////////////////////////////////////////////*/
}

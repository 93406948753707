import { Damage } from '@autoixpert/models/reports/damage-description/damage';

export const sedanDamages: Damage['position'][] = [
    'frontLeft',
    'frontCenter',
    'frontRight',
    'fenderFrontLeft',
    'hood',
    'fenderFrontRight',
    'doorDriver',
    'windshield',
    'doorFrontPassenger',
    'doorBackPassengerLeft',
    'roof',
    'doorBackPassengerRight',
    'fenderRearLeft',
    'fenderRearRight',
    'rearLeft',
    'rearWindow',
    'rearCenter',
    'rearRight',
];

import { Pipe, PipeTransform } from '@angular/core';
import { convertToEuro } from '@autoixpert/lib/placeholder-values/convert-to-euro';
import { translateDocumentOrderConfigName } from '../lib/translators/translate-document-order-config-name';
import { DocumentOrderConfig } from '../models/documents/document-order-config';

/**
 * DocumentOrderConfig.type === "liability" -> "Haftpflichtschaden" (or "Haftpflicht" if shortVersion is true)
 *
 * For custom documents, the title is used.
 */
@Pipe({
    name: 'translateDocumentOrderConfigName',
})
export class TranslateDocumentOrderConfigNamePipe implements PipeTransform {
    transform(documentOrderConfig: DocumentOrderConfig, shortVersion?: boolean): string {
        return translateDocumentOrderConfigName({
            documentOrderConfig,
            shortVersion,
        });
    }
}

<div id="page-layout" *ngIf="report">
    <!--============================================-->
    <!-- Left Column -->
    <!--============================================-->
    <div id="left-column" class="flex-grow">
        <!--============================================-->
        <!-- Comparison of Projected and Actual (Invoiced) Costs -->
        <!--============================================-->
        <div class="card card-with-padding margin-bottom-30">
            <h2>Gegenüberstellung</h2>

            <!--============================================-->
            <!-- Comparison Table -->
            <!--============================================-->
            <table id="comparison-table">
                <!--********** Table Head **********-->
                <tr>
                    <th><!-- Icon Column --></th>
                    <th><!-- Category Name Column --></th>
                    <th class="spacer"></th>
                    <th>Gutachten</th>
                    <th class="spacer"></th>
                    <th>Fremdrechnung</th>
                    <th class="spacer"></th>
                    <th>Differenz</th>
                    <th class="spacer"></th>
                    <th>Korrekturen</th>
                </tr>

                <!--********** Wages **********-->
                <tr
                    *ngFor="
                        let comparisonRow of [
                            report.invoiceAudit.wages,
                            report.invoiceAudit.spareParts,
                            report.invoiceAudit.paint,
                            report.invoiceAudit.auxiliaryCosts,
                            report.invoiceAudit.otherCosts,
                        ];
                        let index = index
                    ">
                    <!--********** Icon **********-->
                    <td class="comparison-row-icon-cell" [ngSwitch]="comparisonRow.category">
                        <mat-icon class="medium-icon" *ngSwitchCase="'wages'">build</mat-icon>
                        <mat-icon class="medium-icon" *ngSwitchCase="'spareParts'">settings</mat-icon>
                        <mat-icon class="medium-icon" *ngSwitchCase="'paint'">color_lens</mat-icon>
                        <mat-icon class="medium-icon" *ngSwitchCase="'auxiliaryCosts'">reorder</mat-icon>
                        <mat-icon class="medium-icon" *ngSwitchCase="'otherCosts'">workspaces</mat-icon>
                    </td>

                    <!--********** Title **********-->
                    <td class="comparison-row-title-cell" [ngSwitch]="comparisonRow.category">
                        <ng-container *ngSwitchCase="'wages'">Lohn</ng-container>
                        <ng-container *ngSwitchCase="'spareParts'">Ersatzteile</ng-container>
                        <ng-container *ngSwitchCase="'paint'">Lack</ng-container>
                        <ng-container *ngSwitchCase="'auxiliaryCosts'">Nebenkosten</ng-container>
                        <ng-container *ngSwitchCase="'otherCosts'">Sonstiges</ng-container>
                    </td>
                    <td class="spacer"></td>

                    <!--********** Projected Costs (as in report) **********-->
                    <td class="comparison-row-projected-costs-cell">
                        <currency-input
                            placeholder="Gutachten"
                            floatLabel="never"
                            [(value)]="comparisonRow.projectedRepairCosts"
                            (valueChange)="calculateValues(); saveReport()"
                            [tabindex]="index + 1"
                            [disabled]="isReportLocked()"></currency-input>
                    </td>
                    <td class="spacer"></td>

                    <!--********** Actual Costs (as on invoice) **********-->
                    <td class="comparison-row-actual-costs-cell">
                        <currency-input
                            placeholder="Fremdrechnung"
                            floatLabel="never"
                            [(value)]="comparisonRow.actualRepairCosts"
                            (valueChange)="calculateValues(); saveReport()"
                            [tabindex]="index + 6"
                            [disabled]="isReportLocked()"></currency-input>
                    </td>
                    <td class="spacer"></td>

                    <!--********** Difference **********-->
                    <td class="comparison-row-difference-cell">
                        <span *ngIf="comparisonRow.difference > 0">+</span>
                        {{ comparisonRow.difference || 0 | number: '1.2-2' }} €
                        <mat-icon
                            class="number-direction-indicator small-icon"
                            *ngIf="comparisonRow.difference"
                            [ngClass]="{
                                positive: comparisonRow.difference > 0,
                                negative: comparisonRow.difference < 0,
                            }"
                            [matTooltip]="
                                comparisonRow.difference > 0
                                    ? 'Die Fremdrechnung übersteigt den Betrag im Gutachten.'
                                    : 'Der Betrag der Fremdrechnung liegt unterhalb des Betrages im Gutachten.'
                            "
                            @fadeInAndOut>
                            forward
                        </mat-icon>
                    </td>
                    <td class="spacer"></td>

                    <!--********** Corrections Column **********-->
                    <td class="comparison-row-corrections-cell">
                        <span *ngIf="comparisonRow.correctedAmount > 0">+</span>
                        {{ comparisonRow.correctedAmount || 0 | number: '1.2-2' }} €
                        <mat-icon
                            class="number-direction-indicator small-icon"
                            *ngIf="comparisonRow.correctedAmount"
                            [ngClass]="{
                                positive: comparisonRow.correctedAmount > 0,
                                negative: comparisonRow.correctedAmount < 0,
                            }"
                            @fadeInAndOut>
                            forward
                        </mat-icon>
                    </td>
                </tr>
                <tr id="totals-row">
                    <td class="comparison-row-icon-cell">
                        <mat-icon>functions</mat-icon>
                    </td>
                    <td class="comparison-row-title-cell">Summe</td>
                    <td class="spacer"></td>
                    <td class="comparison-row-projected-costs-cell">
                        {{ report.invoiceAudit.columnTotals.projectedRepairCosts || 0 | number: '1.2-2' }} €
                    </td>
                    <td class="spacer"></td>
                    <td class="comparison-row-actual-costs-cell">
                        {{ report.invoiceAudit.columnTotals.actualRepairCosts || 0 | number: '1.2-2' }} €
                    </td>
                    <td class="spacer"></td>
                    <td class="comparison-row-difference-cell">
                        {{ report.invoiceAudit.columnTotals.difference || 0 | number: '1.2-2' }} €
                        <mat-icon
                            class="number-direction-indicator small-icon"
                            *ngIf="report.invoiceAudit.columnTotals.difference"
                            [ngClass]="{
                                positive: report.invoiceAudit.columnTotals.difference > 0,
                                negative: report.invoiceAudit.columnTotals.difference < 0,
                            }"
                            [matTooltip]="
                                report.invoiceAudit.columnTotals.difference > 0
                                    ? 'Die Fremdrechnung übersteigt den Betrag im Gutachten.'
                                    : 'Der Betrag der Fremdrechnung liegt unterhalb des Betrages im Gutachten.'
                            "
                            @fadeInAndOut>
                            forward
                        </mat-icon>
                    </td>
                    <td class="spacer"></td>
                    <td class="comparison-row-corrections-cell">
                        {{ report.invoiceAudit.columnTotals.correctedAmount || 0 | number: '1.2-2' }} €
                        <mat-icon
                            class="number-direction-indicator small-icon"
                            *ngIf="report.invoiceAudit.columnTotals.correctedAmount"
                            [ngClass]="{
                                positive: report.invoiceAudit.columnTotals.correctedAmount > 0,
                                negative: report.invoiceAudit.columnTotals.correctedAmount < 0,
                            }"
                            [matTooltip]="
                                report.invoiceAudit.columnTotals.correctedAmount < 0
                                    ? 'Es würden Abzüge vorgenommen.'
                                    : 'Es wurden keine Abzüge vorgenommen.'
                            "
                            @fadeInAndOut>
                            forward
                        </mat-icon>
                    </td>
                </tr>
            </table>
            <!--============================================-->
            <!-- END Comparison Table -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Comparison of Projected and Actual (Invoiced) Costs -->
        <!--============================================-->

        <!--============================================-->
        <!-- Corrections -->
        <!--============================================-->
        <div class="card card-with-padding">
            <h2 class="no-margin">Korrekturen</h2>
            <p class="label text-align-center margin-bottom-20">
                Nimm Stellung zu den einzelnen Positionen der Fremdrechnung.
                <br />
                Ist eine Position überhöht, trage einen negativen Betrag ein.
                <br />
                Wurde zu wenig abgerechnet, trage einen positiven Betrag ein.
            </p>

            <table id="corrections-table">
                <tr>
                    <th class="text-align-start">Position</th>
                    <th class="text-align-start">Kategorie</th>
                    <th class="text-align-start">Kommentar</th>
                    <th class="text-align-end">Betrag</th>
                </tr>

                <tr
                    class="correction-item"
                    *ngFor="let correctionItem of report.invoiceAudit.correctionItems; let index = index">
                    <!--********** Title **********-->
                    <td class="correction-item-title">
                        <autocomplete-with-memory
                            #correctionItemTitleInput
                            [(ngModel)]="correctionItem.title"
                            [loadedAutocompleteEntries]="correctionItemTitleAutocompleteEntries"
                            (entryAdded)="reassignLoadedTitleAutocompleteEntries()"
                            (entryRemoved)="reassignLoadedTitleAutocompleteEntries()"
                            [autocompleteEntryType]="'invoiceAuditCorrectionItemTitle'"
                            placeholder="Positionstitel"
                            floatLabel="never"
                            [disabled]="isReportLocked()"></autocomplete-with-memory>
                    </td>

                    <!--********** Category **********-->
                    <td class="correction-item-category">
                        <mat-form-field floatLabel="never">
                            <mat-select
                                class="correction-item-category-select"
                                [(ngModel)]="correctionItem.category"
                                (selectionChange)="calculateValues(); saveReport()"
                                [disabled]="isReportLocked()">
                                <!--********** Trigger **********-->
                                <mat-select-trigger [ngSwitch]="correctionItem.category">
                                    <span class="category-select-trigger-inner-wrapper" *ngSwitchCase="'wages'">
                                        <mat-icon class="small-icon">build</mat-icon>
                                        Lohn
                                    </span>
                                    <span class="category-select-trigger-inner-wrapper" *ngSwitchCase="'spareParts'">
                                        <mat-icon class="small-icon">settings</mat-icon>
                                        Ersatzteile
                                    </span>
                                    <span class="category-select-trigger-inner-wrapper" *ngSwitchCase="'paint'">
                                        <mat-icon class="small-icon">color_lens</mat-icon>
                                        Lack
                                    </span>
                                    <span
                                        class="category-select-trigger-inner-wrapper"
                                        *ngSwitchCase="'auxiliaryCosts'">
                                        <mat-icon class="small-icon">reorder</mat-icon>
                                        Nebenkosten
                                    </span>
                                    <span class="category-select-trigger-inner-wrapper" *ngSwitchCase="'otherCosts'">
                                        <mat-icon class="small-icon">workspaces</mat-icon>
                                        Sonstiges
                                    </span>
                                </mat-select-trigger>

                                <!--********** Options **********-->
                                <mat-option class="menu-item-with-icon" value="wages">
                                    <mat-icon>build</mat-icon>
                                    Lohn
                                </mat-option>
                                <mat-option class="menu-item-with-icon" value="spareParts">
                                    <mat-icon>settings</mat-icon>
                                    Ersatzteile
                                </mat-option>
                                <mat-option class="menu-item-with-icon" value="paint">
                                    <mat-icon>color_lens</mat-icon>
                                    Lack
                                </mat-option>
                                <mat-option class="menu-item-with-icon" value="auxiliaryCosts">
                                    <mat-icon>reorder</mat-icon>
                                    Nebenkosten
                                </mat-option>
                                <mat-option class="menu-item-with-icon" value="otherCosts">
                                    <mat-icon>workspaces</mat-icon>
                                    Sonstiges
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>

                    <!--********** Comment **********-->
                    <td class="correction-item-comment">
                        <autocomplete-with-memory
                            [(ngModel)]="correctionItem.comment"
                            placeholder="Kommentar"
                            (change)="saveReport()"
                            [disabled]="isReportLocked()"
                            (entryAdded)="reassignLoadedCommentAutocompleteEntries()"
                            (entryRemoved)="reassignLoadedCommentAutocompleteEntries()"
                            [loadedAutocompleteEntries]="correctionItemCommentAutocompleteEntries"
                            [autocompleteEntryType]="'invoiceAuditCorrectionItemComment'"
                            floatLabel="never"></autocomplete-with-memory>
                    </td>

                    <!--********** Amount **********-->
                    <td class="correction-item-amount-cell">
                        <currency-input
                            [(value)]="correctionItem.correctionAmount"
                            placeholder="Betrag"
                            (change)="calculateValues(); saveReport()"
                            floatLabel="never"
                            [disabled]="isReportLocked()"
                            (keydown.tab)="
                                index === report.invoiceAudit.correctionItems.length - 1 ? addCorrectionItem() : null
                            "></currency-input>

                        <!--********** Deletion Icon **********-->
                        <mat-icon
                            class="delete-correction-item-icon"
                            *ngIf="!isReportLocked()"
                            (click)="deleteCorrectionItem(correctionItem); calculateValues(); saveReport()"
                            matTooltip="Löschen">
                            delete
                        </mat-icon>
                    </td>
                </tr>
            </table>

            <!--********** Correction Buttons **********-->
            <div
                id="correction-buttons-container"
                class="display-flex flex-align-center flex-space-between margin-top-10">
                <!--********** Spacer **********-->
                <div id="correction-button-spacer-left"></div>

                <!--********** New Correction Item **********-->
                <button
                    class="flat"
                    (click)="addCorrectionItem()"
                    matTooltip="Kategorie der letzten Korrekturposition wird vorbelegt.">
                    + Neue Position
                </button>

                <!--********** Number of Correction Items **********-->
                <div class="label">
                    {{ report.invoiceAudit.correctionItems.length }}
                    {{ report.invoiceAudit.correctionItems.length === 1 ? 'Position' : 'Positionen' }}
                </div>
            </div>

            <!--============================================-->
            <!-- Warning about positive correction amounts -->
            <!--============================================-->
            <div
                class="info-note warning-note margin-top-10"
                *ngIf="doCorrectionItemsWithPositiveAmountExist()"
                @fadeInAndOut>
                <mat-icon>warning</mat-icon>
                <div>
                    Sicher, dass du den Betrag der Fremdrechnung mit
                    <strong>positiven Korrekturen</strong>
                    erhöhen möchtest? Falls nicht, trage einen negativen Betrag ein.
                </div>
            </div>
            <!--============================================-->
            <!-- END Warning about positive correction amounts -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Corrections -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Left Column -->
    <!--============================================-->

    <!--============================================-->
    <!-- Right Column -->
    <!--============================================-->
    <div id="right-column">
        <!--============================================-->
        <!-- Results Card -->
        <!--============================================-->
        <div class="card dark-card card-with-padding margin-bottom-30">
            <h2>Ergebnis</h2>

            <!--********** Repair Invoice Total **********-->
            <div class="results-row" *ngIf="report.invoiceAudit.columnTotals.actualRepairCosts">
                <div class="results-row-label">Fremdrechnung</div>
                <div class="results-row-value">
                    {{ report.invoiceAudit.columnTotals.actualRepairCosts | number: '1.2-2' }} €
                </div>
            </div>

            <!--********** Correction Total **********-->
            <div class="results-row" *ngIf="report.invoiceAudit.columnTotals.correctedAmount">
                <div class="results-row-label">Korrekturen</div>
                <div class="results-row-value">
                    {{ report.invoiceAudit.columnTotals.correctedAmount | number: '1.2-2' }} €
                </div>
            </div>

            <!--********** Corrected Repair Costs **********-->
            <div id="corrected-repair-costs-container" class="text-align-center">
                <div class="label">Korrigierte Kosten</div>
                <div class="emphasized-number" matTooltip="Netto">
                    {{ report.invoiceAudit.correctedRepairCostsNet || 0 | number: '1.2-2' }} €
                </div>
                <div class="label" matTooltip="Brutto">
                    {{ report.invoiceAudit.correctedRepairCostsGross || 0 | number: '1.2-2' }} €
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Results Card -->
        <!--============================================-->

        <!--============================================-->
        <!-- Invoice Head Data -->
        <!--============================================-->
        <div class="card card-with-padding">
            <h2>Fremdrechnung</h2>

            <!--********** Invoice Number **********-->
            <mat-form-field class="mat-block">
                <input
                    matInput
                    placeholder="Rechnungsnummer"
                    [(ngModel)]="report.invoiceAudit.repairInvoice.number"
                    (change)="saveReport()" />
            </mat-form-field>

            <!--********** Invoice Date **********-->
            <date-input [(date)]="report.invoiceAudit.repairInvoice.date" (dateChange)="saveReport()"></date-input>
        </div>
        <!--============================================-->
        <!-- END Invoice Head Data -->
        <!--============================================-->

        <!--***********************************************
        ** Connect to past report
        ************************************************-->

        <div class="card dark-card card-with-padding connected-report-card margin-top-20">
            <ng-container *ngIf="reportConnectionInputShown && !connectedReport">
                <h2>Gutachten verknüpfen</h2>

                <!--********** Search field **********-->
                <mat-form-field class="mat-block search-field light-mode">
                    <mat-icon
                        matPrefix
                        id="report-search-icon"
                        matTooltip="Suche in Aktenzeichen, Firmen-, Vor- & Nachname sowie Nummernschild. Suche beginnt ab drei Zeichen">
                        search
                    </mat-icon>
                    <input
                        matInput
                        placeholder="Gutachten suchen"
                        [(ngModel)]="reportSearchTerm"
                        (ngModelChange)="updateReportSearchTerm($event); filterReportsAutocomplete()"
                        name="report-token"
                        [matAutocomplete]="reportSearchAutocomplete"
                        (focus)="filterReportsAutocomplete()" />
                    <img
                        matSuffix
                        src="/assets/images/icons/loading-indicator.svg"
                        alt=""
                        id="report-search-pending-indicator"
                        *ngIf="reportSearchPending"
                        matTooltip="Suche..." />
                </mat-form-field>

                <!--============================================-->
                <!-- Autocomplete -->
                <!--============================================-->
                <mat-autocomplete #reportSearchAutocomplete>
                    <mat-option
                        *ngFor="let previousReport of filteredReports"
                        class="previous-report-option"
                        (mousedown)="$event.preventDefault()"
                        (onSelectionChange)="connectReport(previousReport); saveReport()">
                        <div class="previous-report-option-inner-wrapper">
                            <div
                                class="previous-report-manufacturer-icon-container"
                                (click)="navigateToReport(previousReport)"
                                *ngIf="
                                    previousReport.car.make && iconForCarBrandExists(previousReport.car.make);
                                    else carMakeIconPlaceholder
                                ">
                                <img
                                    [src]="iconFilePathForCarBrand(previousReport.car.make, 'large')"
                                    alt=""
                                    class="car-manufacturer-logo"
                                    [matTooltip]="previousReport.car.make + ' ' + previousReport.car.model" />
                            </div>
                            <ng-template #carMakeIconPlaceholder>
                                <div class="car-make-icon-placeholder"></div>
                            </ng-template>
                            <div class="previous-report-name-and-date-container">
                                <div
                                    *ngIf="previousReport.claimant.contactPerson.organization"
                                    class="previous-report-name-container"
                                    [matTooltip]="previousReport.claimant.contactPerson.organization">
                                    {{ previousReport.claimant.contactPerson.organization }}
                                </div>
                                <div
                                    *ngIf="!previousReport.claimant.contactPerson.organization"
                                    class="previous-report-name-container"
                                    [matTooltip]="
                                        previousReport.claimant.contactPerson.firstName +
                                        ' ' +
                                        previousReport.claimant.contactPerson.lastName
                                    ">
                                    {{ previousReport.claimant.contactPerson.firstName }}
                                    {{ previousReport.claimant.contactPerson.lastName }}
                                </div>
                                <div class="label">
                                    <span
                                        *ngIf="previousReport.token"
                                        [matTooltip]="'Aktenzeichen ' + previousReport.token">
                                        {{ previousReport.token }} -
                                    </span>
                                    <span matTooltip="Erstellt am">
                                        {{ previousReport.createdAt | moment: 'DD.MM.YY' }}
                                    </span>
                                </div>
                            </div>
                            <div class="previous-report-open-in-new-container">
                                <mat-icon
                                    class="previous-report-open-in-new"
                                    (click)="handleOpenInNewClick(previousReport._id, $event)"
                                    matTooltip="In neuem Tab öffnen">
                                    open_in_new
                                </mat-icon>
                            </div>
                        </div>
                    </mat-option>
                </mat-autocomplete>
                <!--============================================-->
                <!-- END Autocomplete -->
                <!--============================================-->
            </ng-container>

            <!--============================================-->
            <!-- Report Details -->
            <!--============================================-->
            <ng-container *ngIf="connectedReport">
                <h2>Gutachten</h2>

                <!--********** Submenu **********-->
                <div class="card-toolbar">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="reportDetailsSubmenu">more_vert</mat-icon>
                    <mat-menu #reportDetailsSubmenu>
                        <a mat-menu-item [routerLink]="['/Gutachten', connectedReport._id]">Gutachten öffnen</a>
                        <mat-option (click)="cutReportConnection(); saveReport()">Verknüpfung aufheben</mat-option>
                    </mat-menu>
                </div>

                <!--********** Details **********-->
                <div id="report-details-list">
                    <div class="report-details-item">
                        <div class="report-details-icon-container">
                            <mat-icon>fingerprint</mat-icon>
                        </div>
                        <div class="report-details-item-value-container">
                            <div class="report-details-item-value">
                                <a
                                    id="connected-report-token-link"
                                    [routerLink]="'/Gutachten/' + connectedReport._id"
                                    *ngIf="connectedReport.token">
                                    {{ connectedReport.token }}
                                </a>
                                <span *ngIf="!connectedReport.token">-</span>
                            </div>
                            <div class="report-details-item-label">Aktenzeichen</div>
                        </div>
                    </div>
                    <div class="report-details-item">
                        <div class="report-details-icon-container">
                            <mat-icon>person</mat-icon>
                        </div>
                        <div class="report-details-item-value-container">
                            <div class="report-details-item-value">
                                <span
                                    *ngIf="connectedReport.claimant.contactPerson.organization"
                                    [matTooltip]="connectedReport.claimant.contactPerson.organization">
                                    {{ connectedReport.claimant.contactPerson.organization }}
                                </span>
                                <span
                                    *ngIf="!connectedReport.claimant.contactPerson.organization"
                                    [matTooltip]="
                                        connectedReport.claimant.contactPerson.firstName +
                                        ' ' +
                                        connectedReport.claimant.contactPerson.lastName
                                    ">
                                    {{ connectedReport.claimant.contactPerson.firstName }}
                                    {{ connectedReport.claimant.contactPerson.lastName }}
                                </span>
                            </div>
                            <div class="report-details-item-label">Anspruchsteller</div>
                        </div>
                    </div>
                    <div id="invoice-audit-license-plate-container" *ngIf="connectedReport.car.licensePlate">
                        <a [routerLink]="'/Gutachten/' + connectedReport._id">
                            <license-plate [licensePlate]="connectedReport.car.licensePlate"></license-plate>
                        </a>
                    </div>
                </div>
            </ng-container>
            <!--============================================-->
            <!-- END Report Details -->
            <!--============================================-->

            <ng-container *ngIf="!reportConnectionInputShown && !connectedReport">
                <h2>Gutachten nicht gefunden</h2>
                <div class="text-align-center">
                    <p>Das verknüpfte Gutachten konnte nicht gefunden werden. Möglicherweise wurde es gelöscht.</p>
                    <button (click)="cutReportConnection(); saveReport()">Verknüpfung lösen</button>
                </div>
            </ng-container>
        </div>

        <!--***********************************************
        ** END Connect to past report
        ************************************************-->
    </div>
    <!--============================================-->
    <!-- END Right Column -->
    <!--============================================-->
</div>

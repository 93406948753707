import { generateId } from '@autoixpert/lib/generate-id';
import { DocumentType } from '@autoixpert/models/documents/document-metadata';

/**
 * This is a reference object that links the document order with an actual document (DocumentMetadata).
 */
export class DefaultDocumentOrderItem {
    constructor(template?: Partial<DefaultDocumentOrderItem>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    _id: string = generateId();

    includedInFullDocument: boolean = true;

    /**
     * Relevant when a new document needs to be inserted into a report or invoice document order.
     *
     * In the team preferences, a default order of document types is defined (e.e. "letter", "report", "datValuation", "invoice"). Let's assume that the report
     * has the current document order "letter", "report", "invoice". When a new datValuation is inserted, the document order is searched for the item that has
     * the type "report" because it comes before "datValuation" in the team preferences. The document order item associated with "datValuation" is then inserted
     * after the "report" item.
     */
    documentType: DocumentType;

    /**
     * Special case "manually uploaded PDF" (user defined a permanent PDF): The type may occur multiple times, so the associated documents' field "uploadedDocumentId"
     * is also taken into account.
     */
    uploadedDocumentId?: string;

    /**
     * In case the user created a custom document based on document building blocks (or a claimant signature document), the custom document order config ID is
     * required to identify the correct document order config since the type "customDocument" may not be unique if there
     * are multiple custom documents.
     */
    customDocumentOrderConfigId?: string;
}

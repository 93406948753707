'use strict';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordResetService } from '../../shared/services/password-reset.service';
import { ToastService } from '../../shared/services/toast.service';

@Component({
    selector: 'ax-login',
    styleUrls: ['forgot-password-card.scss'],
    templateUrl: 'forgot-password-card.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    email: string;
    emailRequestSubmitted: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private passwordResetService: PasswordResetService,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe({
            next: (queryParams) => {
                if (queryParams['user']) {
                    this.email = queryParams['user'];
                }
            },
        });
    }

    /**
     * Request an email with instructions on how to reset the user's password
     */
    public requestPasswordReset(): any {
        if (!this.email) {
            this.toastService.error('Bitte gib eine E-Mailadresse an.');
            return;
        }

        this.emailRequestSubmitted = true;

        this.passwordResetService.requestPasswordReset(this.email).subscribe({
            error: (error) => {
                this.toastService.error('Passwort-Rücksetzung nicht möglich', 'Bitte kontaktiere den aX-Support.');
            },
        });
    }

    /**
     * Navigate back to the login card
     */
    public navigateToLogin(): void {
        this.router.navigate(['/Login']);
    }

    ngOnDestroy() {}
}

import { generateId } from '../../../lib/generate-id';
import { ReportType } from '../../reports/report';

export class DefaultDaysUntilDueConfig {
    constructor(template: Partial<DefaultDaysUntilDueConfig> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    reportType: ReportType | 'default' = null;
    numberOfDays: number;
}

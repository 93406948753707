import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { Report } from '@autoixpert/models/reports/report';

export function clearDatValuationData(report: Report) {
    removeDocumentTypeFromReport({
        report,
        documentType: 'datMarketAnalysis',
        documentGroup: 'report',
    });
    removeDocumentTypeFromReport({
        report,
        documentType: 'datValuationProtocol',
        documentGroup: 'report',
    });
    // Don't reset all values. Keep the settings.
    report.valuation.datValuation.dossierId = null;
    report.valuation.datValuation.originalPriceWithoutEquipmentNet = null;
    report.valuation.datValuation.originalPriceWithoutEquipmentGross = null;
    report.valuation.datValuation.originalPriceWithEquipmentNet = null;
    report.valuation.datValuation.originalPriceWithEquipmentGross = null;
    report.valuation.datValuation.dealerPurchasePrice = null;
    report.valuation.datValuation.dealerPurchasePriceGross = null;
    report.valuation.datValuation.dealerSalesPrice = null;
    report.valuation.datValuation.dealerSalesPriceGross = null;
    report.valuation.datValuation.referenceDate = null;
    report.valuation.datValuation.valuesRetrieved = null;
    report.valuation.valuationProvider = null;
}

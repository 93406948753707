import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { AxError } from '@autoixpert/models/errors/ax-error';

/**
 * Service to trigger fetching transactions from GoCardless and match invoices based on transaction data.
 */
@Injectable()
export class FetchAndMatchTransactionsService {
    constructor(private httpClient: HttpClient) {}

    public trigger(): Promise<FetchAndMatchResponse> {
        return this.httpClient
            .post<FetchAndMatchResponse>(`${apiBasePath}/bankAccountSync/matchTransactionsToInvoices`, {})
            .toPromise();
    }
}

interface FetchAndMatchResponse {
    numberOfTransactions: number;
    transactionErrors: AxError[];
}

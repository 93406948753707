import { Component, EventEmitter, HostBinding, HostListener, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { NewWindowService } from '../../../../shared/services/new-window.service';

@Component({
    selector: 'ax-watermark-dialog',
    templateUrl: './docx-watermark-settings-dialog.component.html',
    styleUrls: ['./docx-watermark-settings-dialog.component.scss'],
    animations: [],
})
export class DocxWatermarkSettingsDialogComponent implements OnInit {
    @Output() close = new EventEmitter<void>();

    constructor(
        private newWindowService: NewWindowService,
        public dialogRef: MatDialogRef<DocxWatermarkSettingsDialogComponent>,
    ) {}

    ngOnInit(): void {}

    //*****************************************************************************
    //  Dialog Handling
    //****************************************************************************/
    protected closeDialog(): void {
        this.close.emit();
        this.dialogRef.close();
    }

    @HostListener('document:keydown.escape', ['$event'])
    protected closeEditorOnEscKey(event: KeyboardEvent): void {
        event.preventDefault();
        this.closeDialog();
    }

    protected handleOverlayClick(event: MouseEvent): void {
        if ((event.target as HTMLElement).classList.contains('dialog-overlay')) {
            this.closeDialog();
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Dialog Handling
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Opens the help center article about watermarks in a new tab
     */
    public openHelpCenterArticle(): void {
        this.newWindowService.open(
            'https://wissen.autoixpert.de/hc/de/articles/25628666939666-Vorlagen-f%C3%BCr-DOCX-Wasserzeichen',
            '_blank',
            'noopener',
        );
    }
}

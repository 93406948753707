import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';

export function getAskYourAdminTooltip(team: Team, teamMembers: User[]) {
    const administrators: User[] = teamMembers.filter((member) => team.administrators.includes(member._id));
    const administratorList: string = administrators.map((admin) => `${admin.firstName} ${admin.lastName}`).join(', ');

    if (administrators.length === 1) {
        return `Diese Funktion steht nur Administratoren zur Verfügung. Dein Team-Admin: ${administratorList}`;
    } else {
        return `Diese Funktion steht nur Administratoren zur Verfügung. Deine Team-Admins: ${administratorList}`;
    }
}

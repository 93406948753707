import { Report } from '../../models/reports/report';

/**
 * Calculate the total photos fee.
 *
 * If the user chose a fixed price, return only that.
 * If he chose a variable price, multiply the number of photos with the price per piece. If
 * he issued a second photo set, double the total fee.
 */
export function getPhotosFeeTotal(report: Pick<Report, 'feeCalculation' | 'photos'>): number {
    let total: number;

    if (report.feeCalculation.usePhotosFixedPrice) {
        total = report.feeCalculation.photosFixedPrice;
        if (report.feeCalculation.secondPhotoSet) {
            total += report.feeCalculation.secondPhotoSetFixedPrice;
        }
    } else {
        const numberOfPhotos: number = report.feeCalculation.useManualNumberOfPhotos
            ? report.feeCalculation.manualNumberOfPhotos
            : report.photos.filter((photo) => photo.versions.report.included).length;
        // The second factor (length) can never not be a number, provided the array exists.
        total = report.feeCalculation.pricePerPhoto * numberOfPhotos;
        if (report.feeCalculation.secondPhotoSet) {
            total += report.feeCalculation.pricePerSecondPhoto * numberOfPhotos;
        }
    }

    return total === null || isNaN(total) ? 0 : total;
}

/**
 * Replace all properties within target object with properties from source object. Use `propertiesToKeep` to keep properties in target object.
 *
 * This method helps to replace an object's contents while keeping its references intact because we don't replace the object, just its contents.
 *
 * Object.assign() has the problem that undefined properties are ignored in the source object.
 * @param targetObject - The original object. Its reference will be kept. All properties are removed (except those defined in propertiesToKeep).
 * @param sourceObject - All own properties will be copied to the targetObject.
 * @param propertiesToKeep - Which properties shall not be deleted and not be copied over?
 */
export function replaceObjectProperties<T extends { _id: string }>({
    targetObject,
    sourceObject,
    propertiesToKeep = ['_id'],
}: {
    targetObject: T;
    sourceObject: T;
    propertiesToKeep: (keyof T)[];
}): T {
    //*****************************************************************************
    //  Clear Existing Object
    //****************************************************************************/
    for (const key in targetObject) {
        // Ignore properties in the prototype chain.
        if (!targetObject.hasOwnProperty(key)) continue;

        // Keep certain properties as configured, such as keeping the ID.
        if (propertiesToKeep.includes(key)) continue;

        delete targetObject[key];
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Clear Existing Object
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Copy Properties
    //****************************************************************************/
    for (const key in sourceObject) {
        // Ignore properties in the prototype chain.
        if (!sourceObject.hasOwnProperty(key)) continue;

        // Don't copy certain properties as configured, such as the ID.
        if (propertiesToKeep.includes(key)) continue;

        targetObject[key] = sourceObject[key];
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy Properties
    /////////////////////////////////////////////////////////////////////////////*/

    return targetObject;
}

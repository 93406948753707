<div id="configuration-settings">
    <!--********** Close Icon **********-->
    <mat-icon class="dialog-close-icon" (click)="close()">close</mat-icon>

    <!--********** Heading **********-->
    <h2 class="margin-bottom-10" *ngIf="context === 'damageDescriptionFromRepairParts'">
        Schadenbeschreibung aus Kalkulation
    </h2>
    <h2 class="margin-bottom-10" *ngIf="context === 'repairInstructionsFromRepairParts'">
        Reparaturweg aus Kalkulation
    </h2>
    <div class="label margin-bottom-30 text-align-center">
        {{ productName }} kann aus der Kalkulation extrahieren, welche Teile repariert, lackiert oder ersetzt werden
        sollen. Du kannst konfigurieren, welche Informationen in den Text übernommen werden sollen.
    </div>

    <!--********** List format **********-->
    <div class="settings-row" id="list-format">
        <div class="settings-icon">
            <mat-icon>format_list_bulleted</mat-icon>
        </div>
        <div class="settings-content">
            <div class="settings-title">Listenformat</div>
            <div class="label">Wie sollen die Reparaturpositionen aufgelistet werden?</div>
        </div>
        <div class="settings-action">
            <mat-select class="styled" [(ngModel)]="userPreferences[context + '_listFormat']">
                <mat-option value="bulletList">Aufzählung</mat-option>
                <mat-option value="comma">Komma</mat-option>
                <mat-option value="newline">Zeilenumbruch</mat-option>
            </mat-select>
        </div>
    </div>

    <!--********** Repair Extension (only applicable for DAT, Qapter has no feature 'repair extensions') **********-->
    <div class="settings-row" id="list-format" *ngIf="report.damageCalculation.repair.calculationProvider === 'dat'">
        <div class="settings-icon">
            <mat-icon>euro</mat-icon>
        </div>
        <div class="settings-content">
            <div class="settings-title">Reparaturkostenausweitung</div>
            <div class="label">Wie sollen Teile der Reparaturkostenausweitung berücksichtigt werden?</div>
        </div>
        <div class="settings-action">
            <mat-select class="styled" [(ngModel)]="userPreferences[context + '_repairExtension']">
                <mat-option value="ignore">ignorieren</mat-option>
                <mat-option value="include">berücksichtigen</mat-option>
                <mat-option value="newList">separate Liste</mat-option>
            </mat-select>
        </div>
    </div>

    <!--********** Describe repair type **********-->
    <div *ngIf="context === 'damageDescriptionFromRepairParts'" class="settings-row" id="include-repair">
        <div class="settings-icon">
            <mat-icon>build</mat-icon>
        </div>
        <div class="settings-content">
            <div class="settings-title">Reparaturweg beschreiben</div>
            <div class="label">
                Sollen nur die Teile aufgelistet werden oder auch der zugehörige Reparaturweg? Z. B. “Motorhaube” vs.
                “Motorhaube instandsetzen”.
            </div>
        </div>
        <div class="settings-action">
            <mat-slide-toggle [(ngModel)]="userPreferences[context + '_includeRepairType']"></mat-slide-toggle>
        </div>
    </div>

    <!--********** Import Comment **********-->
    <div class="settings-row" id="include-comments">
        <div class="settings-icon">
            <mat-icon>chat_bubble</mat-icon>
        </div>
        <div class="settings-content">
            <div class="settings-title">Kommentar zur Reparaturposition importieren</div>
            <div class="label">
                In den Kalkulationslösungen kannst du auch Kommentare zu Einzelpositionen hinterlegen. Sollen diese
                importiert werden, falls vorhanden?
            </div>
        </div>
        <div class="settings-action">
            <mat-slide-toggle [(ngModel)]="userPreferences[context + '_includeComments']"></mat-slide-toggle>
        </div>
    </div>

    <!--********** Exclude Words **********-->
    <div class="settings-row" id="blacklist">
        <div class="settings-icon">
            <mat-icon>filter</mat-icon>
        </div>
        <div class="settings-content">
            <div class="settings-title">Schlagwort-Filter</div>
            <div class="label">
                Wenn diese Wörter in einer Position enthalten sind, wird die Position nicht gelistet. Die Groß- und
                Kleinschreibung wird ignoriert. Einige bekannte Positionen (z. B. Verbringung) werden automatisch
                ignoriert.
            </div>
            <div id="blacklist-input-container">
                <mat-form-field class="mat-block" [floatLabel]="'never'">
                    <mat-chip-list #excludedWordsChipList>
                        <mat-chip
                            *ngFor="let excludedWord of userPreferences[context + '_blacklistedTokens']"
                            (removed)="removeFromBlacklist(excludedWord)">
                            {{ excludedWord }}
                            <mat-icon matChipRemove>close</mat-icon>
                        </mat-chip>
                        <!-- The input should be inside the list or else the chips are rendered below the input. -->
                        <input
                            placeholder="Ignorierte Wörter"
                            [matChipInputFor]="excludedWordsChipList"
                            (matChipInputTokenEnd)="addToBlacklist($event)"
                            matChipInputAddOnBlur="true" />
                    </mat-chip-list>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div id="save-button-container">
        <button
            [ngClass]="{ disabled: useMockData }"
            [matTooltip]="
                useMockData
                    ? 'In diesem Gutachten sind keine Ersatzteile oder Arbeitspositionen aus der Kalkulation verfügbar. Erstelle eine Kalkulation oder importiere die bestehende Kalkulation erneut.'
                    : 'Generiert die Schadenbeschreibung nach den Einstellungen und fügt sie am Ende der bestehenden Schadenbeschreibung ein.'
            "
            (click)="closeAndInsert()">
            Generierten Text einfügen
        </button>
        <button (click)="close()" class="secondary">Schließen</button>
    </div>
</div>

<!--********** Preview **********-->
<div id="configuration-preview">
    <div class="configuration-preview-heading">
        <h3>Vorschau</h3>
        <p class="label">auf Basis deiner Einstellungen</p>
    </div>

    <!--********** Parts of example configuration **********-->
    <h4 *ngIf="useMockData">Teile aus Kalkulation</h4>
    <h4 *ngIf="!useMockData">Teile aus deiner Kalkulation</h4>
    <span
        id="preview-parts-mock-note"
        *ngIf="!useMockData"
        matTooltip="Wir zeigen dir hier der Übersicht halber nur die ersten fünf Positionen deiner Kalkulation an."
        class="label">
        (Auszug)
    </span>
    <span
        id="preview-parts-mock-note"
        *ngIf="useMockData"
        matTooltip="Im Gutachten ist keine Kalkulation verknüpft bzw. aus der Kalkulation sind keine Ersatzteile und Arbeitspositionen importiert."
        class="label">
        (Beispieldaten)
    </span>
    <div class="preview-parts">
        <div *ngFor="let part of exampleDamagedParts" class="preview-part-container">
            <div class="preview-part-header">
                <div class="preview-part-description">{{ part.description }}</div>
                <div
                    *ngIf="userPreferences[context + '_includeRepairType']"
                    class="preview-part-repair label"
                    [matTooltip]="part.repairType">
                    {{ part.repairType?.charAt(0).toUpperCase() }}
                </div>
            </div>
            <div
                *ngIf="userPreferences[context + '_includeRepairType'] && part.comment"
                class="preview-part-repair label">
                {{ part.comment }}
            </div>
        </div>
    </div>

    <!--********** Preview of the result **********-->
    <h4>Ergebnis</h4>
    <div class="preview-result" [quillContent]="exampleDamageDescription"></div>
</div>

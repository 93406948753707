import moment from 'moment';
import papaparse from 'papaparse';
import { getContactPersonFullNameWithOrganization } from '@autoixpert/lib/contact-people/get-contact-person-full-name-with-organization';
import { ServerError } from '@autoixpert/models/errors/ax-error';
import { PaymentWithInvoiceReference } from '../../../../../../shared/lib/datev/get-payments-from-invoice';
import { currencyFormatterEuro } from '../currency-formatter-euro';

/**
 * Converts the given payments to our custom CSV format.
 * People may give this to their accountant in case they are "istbesteuert" which means they have to pay VAT only as soon as their bills actually get paid.
 */
export function convertPaymentsToAxCsv({ payments }: { payments: PaymentWithInvoiceReference[] }): string {
    //*****************************************************************************
    //  DATEV Content - Header and Information about the Transactions
    //****************************************************************************/
    const datevContent = payments.map((payment) => {
        const transactionDescriptionParts: string[] = [];
        if (payment.type === 'shortPayment') {
            transactionDescriptionParts.push('Kürzung');
        }
        transactionDescriptionParts.push(
            getContactPersonFullNameWithOrganization(payment.invoice.recipient.contactPerson),
        );
        if (payment.notes) {
            transactionDescriptionParts.push(payment.notes);
        }
        if (payment.invoice.insurance?.contactPerson?.organization) {
            transactionDescriptionParts.push(
                getContactPersonFullNameWithOrganization(payment.invoice.insurance.contactPerson),
            );
        }

        return {
            Zahlungsbetrag: currencyFormatterEuro(
                payment.type === 'shortPayment' ? payment.amount * -1 : payment.amount,
            ),
            Zahlungsdatum: moment(payment.date || undefined).format('DD.MM.YYYY'),
            Zahler: payment.payer || '',
            Rechnunsnummer: payment.invoice.number || '',
            Leistungsdatum: moment(payment.invoice.dateOfSupply || undefined).format('DD.MM.YYYY'),
            Rechnunsdatum: moment(payment.invoice.date || undefined).format('DD.MM.YYYY'),
            Fälligkeitsdatum: moment(payment.invoice.dueDate || undefined).format('DD.MM.YYYY'),
            Buchungstext: transactionDescriptionParts.join(' - ').substr(0, 60), // Max 60 characters are allowed.
            'ist Kürzung': payment.type === 'shortPayment' ? 'Ja' : 'Nein',
        };
    });

    let datevContentCsv: string;
    try {
        datevContentCsv = papaparse.unparse(datevContent, {
            header: true,
            delimiter: ';',
        });
    } catch (error) {
        throw new ServerError({
            code: 'CONVERTING_PAYMENTS_TO_CSV_FAILED',
            message: 'Translting the payments to the CSV file in autoiXpert format failed.',
            data: {
                payments,
            },
            error,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END DATEV Content - Header and Information about the Transactions
    /////////////////////////////////////////////////////////////////////////////*/

    return datevContentCsv;
}

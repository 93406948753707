import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CredentialPropertyPath } from '@autoixpert/models/user/preferences/credentials-property-path';
import { User } from '@autoixpert/models/user/user';

/**
 * Synchronize the given credentials for a third party provider to all users of the team.
 */
@Injectable()
export class UserCredentialsSynchronizationService {
    constructor(private httpClient: HttpClient) {}

    public synchronize(
        userId: User['_id'],
        propertyPath: CredentialPropertyPath,
    ): Observable<{ numSynchronizedUsers: number }> {
        return this.httpClient.post<{ numSynchronizedUsers: number }>(
            `/api/v0/users/${userId}/synchronizeCredentialsToTeam`,
            {
                propertyPath,
            },
        );
    }
}

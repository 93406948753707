import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { Team } from '@autoixpert/models/teams/team';
import { LoggedInUserService } from './logged-in-user.service';

/**
 * Service to download, upload and remove DOCX partials. DOCX partials are the DOCX (Microsoft Word) templates that contain the logic
 * what a document building block looks like. Many simple document building blocks don't need partials. Their "virtual" partials are
 * created by the backend on the fly.
 */
@Injectable()
export class DocumentPartialService {
    constructor(
        private httpClient: HttpClient,
        private loggedInUserService: LoggedInUserService,
    ) {
        this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team));
    }

    private team: Team;

    public get(documentBuildingBlockId: DocumentBuildingBlock['_id']): Observable<any> {
        return this.httpClient.get(`/api/v0/teams/${this.team._id}/documentPartials/${documentBuildingBlockId}`, {
            responseType: 'blob',
            observe: 'response',
        });
    }

    // Why a union type for "documentType"? We can upload either the direct document templates (invoice, liability, ...) or their respective header and footer templates (headerAndFooterTemplate-invoice, ...)
    public remove(documentBuildingBlockId: DocumentBuildingBlock['_id']): Promise<any> {
        return this.httpClient
            .delete(`/api/v0/teams/${this.team._id}/documentPartials/${documentBuildingBlockId}`)
            .toPromise();
    }

    // Why a union type for "documentType"? We can upload either the direct document templates (invoice, liability, ...) or their respective header and footer templates (headerAndFooterTemplate-invoice, ...)
    public create(documentBuildingBlockId: DocumentBuildingBlock['_id'], documentPartial: Blob): Promise<any> {
        const formData = new FormData();
        formData.append('_id', documentBuildingBlockId);
        formData.append('documentPartial', documentPartial);

        return this.httpClient.post<any>(`/api/v0/teams/${this.team._id}/documentPartials`, formData).toPromise();
    }
}

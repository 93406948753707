<white-button-with-shadow
    #iconPickerTrigger="matMenuTrigger"
    [matMenuTriggerFor]="iconPickerMenu"
    (menuOpened)="menuOpen = true"
    (menuClosed)="menuOpen = false"
    [matTooltip]="'Icon auswählen'"
    class="flat with-shadow">
    <div class="icon-picker-container">
        <mat-icon class="regular-size-icon outlined">{{ selectedIcon }}</mat-icon>
        Icon
    </div>
</white-button-with-shadow>

<mat-menu #iconPickerMenu class="icon-picker-menu">
    <icon-picker-overlay
        *ngIf="menuOpen"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (iconPicked)="handleSelectedIconChange($event)"></icon-picker-overlay>
</mat-menu>

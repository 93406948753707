import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DistanceMatrixResponse } from '@autoixpert/external-apis/google-maps/distance-matrix-response';
import { Place } from '@autoixpert/models/contacts/place';
import { mapLocationsToQueryString } from '../libraries/distance-calculation/map-location-to-query-string';

/**
 * Service to query the Google Distance Matrix API.
 *
 * Includes methods for converting locations to query strings and
 * a method to sum up all legs of a journey.
 */
@Injectable()
export class DistanceService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0/distance';

    /**
     * Get the total distance and the distance elements provided by the Google Maps API
     *
     * @param origins       - provide one or multiple values separated by a pipe | character
     * @param destinations  - provide one or multiple values separated by a pipe | character
     */
    public find(origins: Place[], destinations: Place[]): Observable<DistanceServiceResponse> {
        const originsString: string = mapLocationsToQueryString(origins);
        const destinationsString: string = mapLocationsToQueryString(destinations);

        return this.httpClient.get<DistanceServiceResponse>(`${this.apiPath}`, {
            params: {
                origins: originsString,
                destinations: destinationsString,
            },
        });
    }

    public getTotalDistance(origins: Place[], destinations: Place[]): Observable<any> {
        return this.find(origins, destinations).pipe(map((response) => response.totalDistanceInMeters));
    }
}

export interface DistanceServiceResponse extends DistanceMatrixResponse {
    totalDistanceInMeters: number;
}

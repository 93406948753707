//*****************************************************************************
//  Description
//****************************************************************************/
// This mini-file is required for both the app and migrations to use URL-safe IDs.
//
// Please always import this file in migrations instead of importing nanoid directly.
/////////////////////////////////////////////////////////////////////////////*/
//  END Description
/////////////////////////////////////////////////////////////////////////////*/
import { customAlphabet } from 'nanoid';

/**
 * These characters are URL-safe and double-clickable (one double-click selects the entire ID).
 * A string that's not completely selectable through a double click but URL-safe would be: abc-def.
 *
 * 21 is nanoid's default size. 12 is enough for our purposes (~1 thousand years needed, in order to have a 1% probability of at least one collision.)
 */
const configuredNanoId = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 12);

/**
 *
 * Wrap nanoid in a function without parameters since mongoose models that use a default function for an _id field apparently pass a very large
 * number as a parameter to the default function. That lets nanoid use a large ID size, causes a memory leak and blows up the node.js process.
 */
export function generateId() {
    return configuredNanoId();
}

import { AudatexCalculationConfigCode } from '../../models/contacts/audatex-calculation-config-code';

/**
 * Static collection of Audatex's Randfahnencodes.
 *
 * Used to display config codes in logical groups to the user.
 */
export const audatexCalculationConfigCodeGroups: AudatexCalculationConfigCodeGroup[] = [
    {
        title: '01: Lackiermaterial',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 40,
                title: 'von Lack-Lohn',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 42,
                title: 'Pauschalbetr. Teile',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 43,
                title: 'Pauschalbetr. RBK',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 51,
                title: 'Flaeche  AZT/Schwacke',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 84,
                title: 'Korrektur AZT Lackiervorb./Materialkonst.',
                valueType: 'percent',
            },
        ],
    },
    {
        title: '02: Lackierkosten pauschal',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 52,
                title: 'Teile-Rep',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 53,
                title: 'RBK',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 54,
                title: 'Teile-Rep Teile spezif.',
                valueType: 'workFractionUnits',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 55,
                title: 'Teile-Rep Teile spezif.',
                valueType: 'currency',
            },
        ],
    },
    {
        title: '03: Festbeträge und Sonstiges',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 60,
                title: 'Beschaffung Teile-Rep',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 61,
                title: 'Beschaffung RBK',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 63,
                title: 'Schwemmmaterial pauschal',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 64,
                title: 'Schwemmmaterial vom Lohn',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 65,
                title: 'Polsterarbeiten',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 66,
                title: 'Klebe-/Dichtmaterial',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 74,
                title: 'Vermessen',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 67,
                title: 'Polieren',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 68,
                title: 'Fahrzeug-Verbringung',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 69,
                title: 'Notreparatur',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 70,
                title: 'Unterbodenschutz an Teilen',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 71,
                title: 'Unterbodenschutz RBK',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 72,
                title: 'Hohlraumkonservierung an Teilen',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 73,
                title: 'Hohlraumkonservierung RBK',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 76,
                title: 'Entsorgung in % der Ersatzteile',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 77,
                title: 'Entsorgung als Pauschalbetrag',
                valueType: 'currency',
            },
        ],
    },
    {
        title: '04: Auf- oder Abschläge bei Ersatzteilpreisen',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 20,
                title: 'Abschlag Endsumme',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 21,
                title: 'Aufschlag Endsumme',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 22,
                title: 'Abschlag Einzelpreis',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 23,
                title: 'Aufschlag Einzelpreis',
                valueType: 'percent',
            },
        ],
    },
    {
        title: '05: Kleinersatzteile',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 27,
                title: 'Kleinersatzteile pauschal',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 28,
                title: 'von Summe Ersatzteile',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 29,
                title: 'vom Lohn',
                valueType: 'percent',
            },
        ],
    },
    {
        title: '06: Preisabzug für verwendbare Teile',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 12,
                title: 'V-Achse II',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 13,
                title: 'Teile-Rep',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 14,
                title: 'RBK',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 15,
                title: 'RBK kpl lackiert',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 16,
                title: 'V-Achse',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 17,
                title: 'H-Achse',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 18,
                title: 'H-Achse II',
                valueType: 'currency',
            },
        ],
    },
    {
        title: '08: NFA-Abzüge/Vorteilsausgleich',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 11,
                title: 'pauschal von Ersatzteilen gesamt',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 24,
                title: 'Ersatzteile Teile-Rep',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 25,
                title: 'Ersatzteile Aggr.,Opt',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 56,
                title: 'Lack',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 58,
                title: 'Lack',
                valueType: 'percent',
            },
        ],
    },
    {
        title: '09: Sonstige Abzüge',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 88,
                title: 'Prozent Abzug auf Rep-Kosten (Ohne MWST)',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 89,
                title: 'Pauschal Abzug auf Rep-Kosten (Ohne MWST)',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 90,
                title: 'Vorschaden Dokumentat.m.MwSt',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 91,
                title: 'Selbstbeteilig Dokumentat.',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 95,
                title: 'Vorschaden rechnend o.MwSt',
                valueType: 'currency',
            },
        ],
    },
    {
        title: '10: Kalkulation mit Pauschalbeträgen',
        codes: [
            {
                _id: undefined,
                value: undefined,
                numericalCode: 2,
                title: 'MwSt-Prozentsatz-Änderung',
                valueType: 'percent',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 10,
                title: 'Ersatzteile m. Detail-Text',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 30,
                title: 'Lohn mit Detail-Text',
                valueType: 'currency',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 31,
                title: 'Lohn mit Detail-Text',
                valueType: 'workFractionUnits',
            },
            {
                _id: undefined,
                value: undefined,
                numericalCode: 32,
                title: 'Sonderarbeiten',
                valueType: 'currency',
            },
        ],
    },
];

export function getAllAudatexCalculationConfigCodes(): AudatexCalculationConfigCode[] {
    return audatexCalculationConfigCodeGroups.reduce((allCodes, codeGroup) => {
        allCodes.push(...codeGroup.codes);
        return allCodes;
    }, []);
}

/**
 * UI-only type to group codes logically for the user.
 */
export interface AudatexCalculationConfigCodeGroup {
    title: string;
    codes: AudatexCalculationConfigCode[];
}

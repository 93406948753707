export function currencyFormatterEuro(value: number): string {
    return (
        Number(value)
            .toLocaleString('de', {
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            .replace(',00', '') + ' €'
    );
}

import { SignablePdfTemplateConfig } from '@autoixpert/models/signable-documents/signable-pdf-template-config';

export abstract class AbstractSignablePdfTemplateConfigService {
    public abstract find(): Promise<SignablePdfTemplateConfig[]>;

    public abstract put(
        record: SignablePdfTemplateConfig,
        options?: { waitForServer?: boolean },
    ): Promise<SignablePdfTemplateConfig>;
}

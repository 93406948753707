import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, EventEmitter, HostListener, Injector, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { PANEL_CLOSE_SUBJECT_TOKEN } from '@autoixpert/injection-tokens/common-overlay-injection-tokens';
import { CheckboxElement } from '@autoixpert/models/signable-documents/signable-pdf-template-config';
import {
    CHECKBOX_ELEMENT_CHANGE_SUBJECT_TOKEN,
    CHECKBOX_ELEMENT_IS_REQUIRED_CHANGE_SUBJECT_TOKEN,
    CHECKBOX_ELEMENT_TOKEN,
    PdfCheckboxConfigPanelComponent,
    REQUIRED_FIELD_CHANGE_SUBJECT_TOKEN,
    REQUIRED_FIELD_TOKEN,
} from './pdf-checkbox-config-panel.component';

/**
 * If the user double-clicks a checkbox element, this panel will be opened.
 *
 * This panel allows negating the condition under which this checkbox will be printed.
 */
@Directive({
    selector: '[pdfCheckboxConfigAnchor]',
    exportAs: 'pdfCheckboxConfigAnchor',
})
export class PdfCheckboxConfigAnchorDirective {
    constructor(
        private elementRef: ElementRef,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input() checkboxElement: CheckboxElement;
    @Input() checkboxIsRequired: boolean;
    @Input() checkboxAnswer: boolean;
    @Input() panelDisabled: boolean;

    @Output() panelOpen: EventEmitter<void> = new EventEmitter();
    @Output() panelClose: EventEmitter<void> = new EventEmitter();
    @Output() checkboxConfigChange: EventEmitter<CheckboxElement[]> = new EventEmitter<CheckboxElement[]>();
    @Output() checkboxIsRequiredChange: EventEmitter<boolean> = new EventEmitter();
    @Output() checkboxAnswerChange: EventEmitter<boolean> = new EventEmitter();

    private overlayRef: OverlayRef;

    //*****************************************************************************
    //  Init
    //****************************************************************************/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Anchor Double-Click Handler
    //****************************************************************************/
    @HostListener('dblclick', ['$event'])
    public openCheckboxConfigPanel() {
        if (this.panelDisabled) return;

        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                ])
                .withPush(true)
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
            // Make the same width as the host
            width: Math.max(this.elementRef.nativeElement.clientWidth, 500),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        /**
         * We must hear from the overlay when the config changes to trigger saving the record
         * within the parent of this anchor directive.
         */
        const isRequiredChangeListener = new Subject<CheckboxElement[]>();
        isRequiredChangeListener.subscribe((checkboxElements) => {
            this.checkboxConfigChange.emit(checkboxElements);
            this.checkboxConfigChange.emit();
        });
        const configChangeListener = new Subject<void>();
        configChangeListener.subscribe(() => {
            this.checkboxAnswerChange.emit();
            this.checkboxConfigChange.emit();
        });

        /**
         * Listen for when the user changes the `requiredField` checkbox within the overlay.
         */
        const requiredFieldChangeListener = new Subject<void>();
        requiredFieldChangeListener.subscribe(() => this.checkboxIsRequiredChange.emit());

        /**
         * Also listen for when the user clicks the close icon within the overlay.
         */
        const panelCloseListener = new Subject<void>();
        panelCloseListener.subscribe(() => this.panelClose.emit());

        //*****************************************************************************
        //  Injector
        //****************************************************************************/
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                //*****************************************************************************
                //  Inputs
                //****************************************************************************/
                {
                    provide: CHECKBOX_ELEMENT_TOKEN,
                    useValue: this.checkboxElement,
                },
                {
                    provide: REQUIRED_FIELD_TOKEN,
                    useValue: this.checkboxIsRequired,
                },
                {
                    provide: OverlayRef,
                    useValue: this.overlayRef,
                },
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Inputs
                /////////////////////////////////////////////////////////////////////////////*/

                //*****************************************************************************
                //  Outputs
                //****************************************************************************/
                {
                    provide: CHECKBOX_ELEMENT_IS_REQUIRED_CHANGE_SUBJECT_TOKEN,
                    useValue: isRequiredChangeListener,
                },
                {
                    provide: CHECKBOX_ELEMENT_CHANGE_SUBJECT_TOKEN,
                    useValue: configChangeListener,
                },
                {
                    provide: PANEL_CLOSE_SUBJECT_TOKEN,
                    useValue: panelCloseListener,
                },
                {
                    provide: REQUIRED_FIELD_CHANGE_SUBJECT_TOKEN,
                    useValue: requiredFieldChangeListener,
                },
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Outputs
                /////////////////////////////////////////////////////////////////////////////*/
            ],
        });
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Injector
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Component Portal
        //****************************************************************************/
        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<PdfCheckboxConfigPanelComponent>(
            PdfCheckboxConfigPanelComponent,
            null,
            injector,
        );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Component Portal
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Attach Component to Portal Outlet
        //****************************************************************************/
        this.overlayRef.attach(componentPortal);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Attach Component to Portal Outlet
        /////////////////////////////////////////////////////////////////////////////*/

        this.panelOpen.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Double-Click Handler
    /////////////////////////////////////////////////////////////////////////////*/
}

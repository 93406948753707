<!--********** Valuation provider logo **********-->
<div
    id="valuation-provider-logo"
    class="on-top-of-overlay"
    [ngClass]="{
        'provider-dat': selectedValuationProvider === 'dat',
        'provider-audatex': selectedValuationProvider === 'audatex',
    }">
    <img
        id="dat-logo"
        *ngIf="selectedValuationProvider === 'dat'"
        src="assets/images/logos/dat-logo-no-text.svg"
        alt="DAT" />
    <img
        id="qapter-logo"
        *ngIf="selectedValuationProvider === 'audatex'"
        src="/assets/images/logos/qapterixpert-q-white.svg"
        alt="Qapter" />
</div>
<!--********** Valuation provider indicator bottom **********-->
<div
    id="valuation-provider-indicator-bottom"
    class="on-top-of-overlay"
    [ngClass]="{
        'provider-dat': selectedValuationProvider === 'dat',
        'provider-audatex': selectedValuationProvider === 'audatex',
    }"></div>

<!--============================================-->
<!-- Toolbar -->
<!--============================================-->

<div class="card-toolbar on-top-of-overlay">
    <ng-container *ngIf="datValuationConnected">
        <!--********** Edit / View DAT Valuation **********-->
        <mat-icon
            *ngIf="valuationProvider === 'dat'"
            (click)="openDatValuation()"
            matTooltip="Bewertung öffnen"
            class="toolbar-icon">
            {{ isReportLocked() ? 'visibility' : 'edit' }}
        </mat-icon>

        <!--********** DAT Valuation is excluded from report **********-->
        <mat-icon
            *ngIf="valuationProvider !== 'dat'"
            (click)="includeDatValuationIntoReport()"
            matTooltip="Die DAT Bewertung nicht im Gutachten abdrucken.{{
                '\n\n'
            }}Das ist hilfreich, wenn du die Werte manuell in autoiXpert abändern willst oder Werte einer anderen Quelle, wie z. B. einer Restwertbörse verwenden willst. Klicke, um die Bewertung im Textbaustein 'Bewertung' abzudrucken."
            [class.disabled]="isReportLocked()"
            class="toolbar-icon">
            visibility_off
        </mat-icon>
    </ng-container>

    <ng-container *ngIf="audatexValuationConnected">
        <!--********** Edit / View Audatex Valuation **********-->
        <mat-icon
            *ngIf="valuationProvider === 'audatex'"
            (click)="openAudatexValuation()"
            matTooltip="Bewertung öffnen"
            class="toolbar-icon">
            {{ isReportLocked() ? 'visibility' : 'edit' }}
        </mat-icon>

        <!--********** Audatex Valuation is excluded from report **********-->
        <mat-icon
            *ngIf="valuationProvider !== 'audatex'"
            (click)="includeAudatexValuationIntoReport()"
            matTooltip="Die Audatex Bewertung nicht im Gutachten abdrucken.{{
                '\n\n'
            }}Das ist hilfreich, wenn du die Werte manuell in autoiXpert abändern willst oder Werte einer anderen Quelle, wie z. B. einer Restwertbörse verwenden willst. Klicke, um die Bewertung im Textbaustein 'Bewertung' abzudrucken."
            [class.disabled]="isReportLocked()"
            class="toolbar-icon">
            visibility_off
        </mat-icon>
    </ng-container>

    <mat-icon
        *ngIf="!isReportLocked() || audatexTaskConnected || datValuationConnected"
        [matMenuTriggerFor]="valuationSubMenu"
        class="toolbar-icon">
        more_vert
    </mat-icon>
    <mat-menu #valuationSubMenu>
        <ng-container
            *ngIf="
                report.car.identificationProvider !== 'audatex' &&
                !report.valuation.datValuation?.dossierId &&
                !isReportLocked()
            ">
            <!--********** Start DAT Valuation **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="openDatValuation()"
                [disabled]="
                    !isDatUserComplete(user) ||
                    !isDatValuationInputDataComplete(report) ||
                    !isDatValuationPossible(report) ||
                    isReportLocked()
                "
                [matTooltip]="getDatValuationExportTooltip({ report, user })">
                <mat-icon>open_in_new</mat-icon>
                Detaillierte Bewertung
            </a>

            <!--********** Connect DAT Valuation manually **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="!isReportLocked() && openDatValuationConnectionDialog()"
                [disabled]="!isDatUserComplete(user) || isReportLocked()"
                matTooltip="DAT-Bewertung von DAT.de manuell mit diesem Gutachten verknüpfen">
                <mat-icon>link</mat-icon>
                DAT-Bewertung verknüpfen
            </a>
        </ng-container>

        <ng-container *ngIf="report.valuation.datValuation?.dossierId">
            <!--********** Fetch Data from DAT **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="retrieveDatValuationResults()"
                [disabled]="isReportLocked()">
                <mat-icon>get_app</mat-icon>
                Ergebnisse von DAT holen
            </a>

            <!--********** Transmit Data to DAT **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="transmitDatValuation(true)"
                [disabled]="isReportLocked()">
                <mat-icon>publish</mat-icon>
                Daten zu DAT überspielen
            </a>

            <!--********** Settings **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="!isReportLocked() && showDatValuationSettingsDialog()"
                [disabled]="isReportLocked()">
                <mat-icon>settings</mat-icon>
                DAT-Einstellungen
            </a>

            <!--********** Exclude DAT Valuation from Report **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="report.valuation.valuationProvider === 'dat'"
                (click)="excludeDatOrAudatexValuationFromReport()"
                matTooltip="Die DAT Bewertung nicht im Gutachten abdrucken.{{
                    '\n\n'
                }}Das ist hilfreich, wenn du die Werte manuell in autoiXpert abändern willst oder Werte einer anderen Quelle, wie z. B. einer Restwertbörse verwenden willst.">
                <mat-icon>visibility_off</mat-icon>
                DAT-Bewertung nicht abdrucken
            </a>

            <!--********** Include DAT Valuation into Report **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="report.valuation.valuationProvider !== 'dat'"
                (click)="includeDatValuationIntoReport()"
                matTooltip="Die DAT Bewertung wird im Textbaustein 'Bewertung' (in der Regel vor der Fotoanlage) im Gutachten abgedruckt."
                [disabled]="isReportLocked()">
                <mat-icon>visibility</mat-icon>
                DAT-Bewertung abdrucken
            </a>

            <!--********** Add DAT Valuation as document **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="addDatValuationToDocuments({ displayToast: true })"
                matTooltip="Ermögliche den separaten Druck & Versand der Bewertung, auch außerhalb des Gutachtens."
                [disabled]="isReportLocked()">
                <mat-icon>description</mat-icon>
                Als Dokument verfügbar machen
            </a>

            <!--********** Disconnect DAT Valuation **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="unlinkDatValuation()"
                *ngIf="report.valuation.datValuation?.dossierId"
                matTooltip="DAT-Bewertung von diesem Gutachten trennen. Es bleibt aber anders als beim Löschen auf DAT.de erhalten.">
                <mat-icon>link_off</mat-icon>
                DAT-Bewertung trennen
            </a>
            <!--********** Delete DAT-Valuation **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="resetDatValuation()" [disabled]="isReportLocked()">
                <mat-icon>delete</mat-icon>
                DAT-Bewertung löschen
            </a>
        </ng-container>

        <!--********** Audatex - valuation not yet imported **********-->
        <!--
                    Users have to leave the autoiXpert window to do the Audatex valuation in another tab or browser.
                    If they change their focus back to autoiXpert, we try to load the Audatex valuation.
                    If users use a PWA on their device (e.g. using Edge on Windows), this focus detection won't load.
                    Therefore we have to provide the user a way to load the valuation manually.
                -->
        <ng-container *ngIf="report.audatexTaskId">
            <!--********** Open Valuation in Audatex **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="openAudatexValuation()"
                [class.disabled]="
                    !isAudatexUserComplete(user) ||
                    !isAudatexValuationInputDataComplete(report) ||
                    !isAudatexValuationPossible(report) ||
                    isReportLocked()
                "
                [matTooltip]="getAudatexValuationExportTooltip({ report, user })">
                <mat-icon>{{ isReportLocked() ? 'visibility' : 'edit' }}</mat-icon>
                Audatex Bewertung öffnen
            </a>

            <!--********** Fetch Data from Audatex **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="importAudatexValuation()"
                [disabled]="isReportLocked()"
                [matTooltip]="
                    report.valuation.audatexValuation?.documentHash
                        ? 'Ergebnisse von Audatex aktualisieren'
                        : 'Bereits existierende Bewertung aus dem verknüpften Audatex-Auftrag importieren.'
                ">
                <mat-icon>get_app</mat-icon>
                Ergebnisse von Audatex holen
            </a>
        </ng-container>

        <ng-container
            *ngIf="report.car.identificationProvider !== 'dat' && !audatexValuationConnected && !isReportLocked()">
            <!--********** Start Audatex Valuation **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                [class.disabled]="
                    !isAudatexUserComplete(user) ||
                    !isAudatexValuationInputDataComplete(report) ||
                    !isAudatexValuationPossible(report) ||
                    isReportLocked()
                "
                [matTooltip]="getAudatexValuationExportTooltip({ report, user })"
                (click)="openAudatexValuation()">
                <mat-icon>open_in_new</mat-icon>
                Detaillierte Bewertung
            </a>

            <!--********** Connect Audatex Valuation manually **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="!isReportLocked() && openAudatexValuationConnectionDialog()"
                [disabled]="!isAudatexUserComplete(user) || isReportLocked()"
                matTooltip="Audatex-Bewertung manuell mit diesem Gutachten verknüpfen">
                <mat-icon>link</mat-icon>
                Audatex-Bewertung verknüpfen
            </a>
        </ng-container>
        <!--********** Audatex - valuation already imported **********-->
        <ng-container *ngIf="report.valuation.audatexValuation?.documentHash">
            <!--********** Exclude Audatex Valuation from Report **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="report.valuation.valuationProvider === 'audatex'"
                (click)="excludeDatOrAudatexValuationFromReport()"
                [disabled]="isReportLocked()"
                matTooltip="Die Audatex-Bewertung nicht im Gutachten abdrucken.{{
                    '\n\n'
                }}Das ist hilfreich, wenn du die Werte manuell in autoiXpert abändern willst oder Werte einer anderen Quelle, wie z. B. einer Restwertbörse verwenden willst.">
                <mat-icon>visibility_off</mat-icon>
                Audatex-Bewertung nicht abdrucken
            </a>

            <!--********** Include DAT Valuation into Report **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                *ngIf="report.valuation.valuationProvider !== 'audatex'"
                (click)="includeAudatexValuationIntoReport()"
                matTooltip="Die Audatex-Bewertung wird im Textbaustein 'Bewertung' (in der Regel vor der Fotoanlage) im Gutachten abgedruckt.">
                <mat-icon>visibility</mat-icon>
                Audatex-Bewertung abdrucken
            </a>

            <!--********** Add Audatex Valuation as document **********-->
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="addAudatexValuationToDocuments({ displayToast: true })"
                matTooltip="Ermögliche den separaten Druck & Versand der Bewertung, auch außerhalb des Gutachtens."
                [disabled]="isReportLocked()">
                <mat-icon>description</mat-icon>
                Als Dokument verfügbar machen
            </a>

            <!--********** Delete Audatex-Valuation **********-->
            <a
                mat-menu-item
                *ngIf="report.valuation.valuationProvider === 'audatex'"
                class="menu-item-with-icon"
                (click)="removeAudatexValuation()"
                [disabled]="isReportLocked()">
                <mat-icon>delete</mat-icon>
                Audatex-Bewertung löschen
            </a>
        </ng-container>
    </mat-menu>
</div>

<!--============================================-->
<!-- END Toolbar -->
<!--============================================-->

<!--********** Valuation Header **********-->
<div id="valuation-provider-header">
    <h2>Bewertung</h2>

    <!--********** Reference Date DAT **********-->
    <div
        *ngIf="selectedValuationProvider === 'dat'"
        class="reference-date label"
        datePickerWithInputAnchor
        [disabled]="isReportLocked()"
        [class.disabled]="isReportLocked()"
        [(date)]="report.valuation.datValuation.referenceDate"
        [showTimeInput]="false"
        (dateChange)="saveReport()">
        <mat-icon class="small-icon margin-right-5">today</mat-icon>
        {{ referenceDateLabel }}
        {{ referenceDate | moment }}
    </div>
    <!--********** Reference Date Audatex **********-->
    <div
        *ngIf="selectedValuationProvider === 'audatex'"
        class="reference-date label"
        datePickerWithInputAnchor
        [disabled]="isReportLocked()"
        [class.disabled]="isReportLocked()"
        [(date)]="report.valuation.audatexValuation.referenceDate"
        [showTimeInput]="false"
        (dateChange)="saveReport()">
        <mat-icon class="small-icon margin-right-5">today</mat-icon>
        {{ referenceDateLabel }}
        {{ referenceDate | moment }}
    </div>
    <!--********** Valuate Pro Warning **********-->
    <mat-icon
        *ngIf="user.datUser.isValuateProUser"
        class="warning small-icon dat-valuatepro-info-icon"
        matTooltip="Du nutzt einen DAT valuatePro Account. Damit erhältst du zwar Fahrzeugwerte, aber keine PDF-Dateien über die DAT-Schnittstelle.">
        warning
    </mat-icon>
</div>

<!--============================================-->
<!-- Valuation controls -->
<!--============================================-->
<div class="valuation-controls">
    <!--********** Vehicle value type **********-->
    <mat-form-field *ngIf="selectedValuationProvider === 'dat'" class="no-underline valuation-control-container">
        <mat-select
            [disabled]="isReportLocked()"
            placeholder="Wertangabe"
            class="styled"
            (selectionChange)="setDATTaxationTypeForValueType()"
            [(ngModel)]="report.valuation.datValuation.vehicleValueType">
            <mat-option value="dealerPurchase">Händlereinkaufspreis</mat-option>
            <mat-option value="dealerSales">Händlerverkaufspreis</mat-option>
            <mat-option value="marketValue">Marktwert</mat-option>
            <mat-option value="replacementValue">Wiederbeschaffungswert</mat-option>
            <mat-option value="presentValue">Zeitwert</mat-option>
        </mat-select>
    </mat-form-field>

    <!--********** DAT taxation selector **********-->
    <div
        *ngIf="selectedValuationProvider === 'dat'"
        id="dat-valuation-taxation-settings"
        class="valuation-control-container">
        <div class="label">Besteuerung</div>
        <div class="selection-bar">
            <div
                class="selection-bar-item"
                (click)="setDatValuationTaxationType('neutral')"
                [matTooltip]="
                    neutralTaxationTypeAllowedWithDat()
                        ? 'Ältere Fahrzeuge, die überwiegend auf dem Privatmarkt gehandelt werden. MwSt. kann nicht ausgewiesen werden.'
                        : 'Der neutrale Steuersatz wird von der DAT nur für den Bewertungstyp \'Wiederbeschaffungswert\' unterstützt.'
                "
                [ngClass]="{
                    selected: report.valuation.datValuation.requestedTaxationType === 'neutral',
                    disabled: isReportLocked() || !neutralTaxationTypeAllowedWithDat(),
                }">
                <span class="selection-percentage">0 %</span>
                <span class="selection-label">Neutral</span>
            </div>
            <div
                class="selection-bar-item"
                (click)="setDatValuationTaxationType('margin')"
                matTooltip="Mittelalte Fahrzeuge, die überwiegend im Gebrauchtwagenhandel verfügbar sind. Typisches Fahrzeugalter: 3 bis 10 Jahre."
                [ngClass]="{
                    selected: report.valuation.datValuation.requestedTaxationType === 'margin',
                    disabled: isReportLocked(),
                }">
                <span class="selection-percentage">2,4 %</span>
                <span class="selection-label">Differenz</span>
            </div>
            <div
                class="selection-bar-item"
                (click)="setDatValuationTaxationType('full')"
                [matTooltip]=""
                [ngClass]="{
                    selected: report.valuation.datValuation.requestedTaxationType === 'full',
                    disabled: isReportLocked(),
                }">
                <span class="selection-percentage">19 %</span>
                <span class="selection-label">Regelsatz</span>
            </div>
        </div>

        <!--********** Taxation mismatch: claimant does not deduct taxes, valuation includes VAT **********-->
        <div
            *ngIf="report.valuation.datValuation.requestedTaxationType === 'full' && !mayCarOwnerDeductTaxes(report)"
            class="dat-taxation-mismatch"
            @fadeInAndOut>
            <div class="info-note warning-note">
                <mat-icon class="warning medium-icon">warning</mat-icon>
                <div>
                    <h3>Auftraggeber nicht vorsteuerabzugsberechtigt</h3>
                    In der DAT-Bewertung wird aber Mehrwertsteuer ausgewiesen. Überlege, ob hier der Regelsatz die
                    richtige Besteuerungsart ist.
                </div>
            </div>
        </div>
        <!--********** Taxation mismatch: claimant may deduct taxes, valuation does not include VAT **********-->
        <div
            *ngIf="report.valuation.datValuation.requestedTaxationType !== 'full' && mayCarOwnerDeductTaxes(report)"
            class="dat-taxation-mismatch"
            @fadeInAndOut>
            <div class="info-note warning-note">
                <mat-icon class="warning medium-icon">warning</mat-icon>
                <div>
                    <h3>Auftraggeber vorsteuerabzugsberechtigt</h3>
                    In der DAT-Bewertung wird aber keine Mehrwertsteuer ausgewiesen. Prüfe, ob hier nicht der Regelsatz
                    die richtige Besteuerungsart ist.
                </div>
            </div>
        </div>
    </div>

    <!--********** Trigger Valuation Buttons **********-->
    <div
        *ngIf="
            (selectedValuationProvider === 'dat' && !datValuationRetrieved) ||
            (selectedValuationProvider === 'audatex' && !audatexValuationRetrieved) ||
            (!datValuationConnected && !audatexValuationConnected)
        "
        id="trigger-valuation-button-container"
        class="valuation-control-container">
        <ng-container *ngIf="selectedValuationProvider === 'dat'">
            <!--********** Execute DAT Quick Valuation **********-->
            <div
                id="trigger-dat-quick-valuation"
                [class.disabled]="
                    !isDatUserComplete(user) ||
                    !isDatValuationInputDataComplete(report) ||
                    isReportLocked() ||
                    !isDatValuationPossible(report)
                "
                [matTooltip]="
                    getDatValuationOpenerTooltip({
                        report: report,
                        user: user,
                        valuationProvider: 'quick',
                    })
                "
                (click)="executeDatQuickValuation()">
                <mat-icon>electric_bolt</mat-icon>
                <span class="label">
                    Schnell-
                    <br />
                    bewertung
                </span>
            </div>
            <!--********** Open DAT Valuation UI **********-->
            <div
                id="trigger-dat-detail-valuation"
                [class.disabled]="
                    !isDatUserComplete(user) ||
                    !isDatValuationInputDataComplete(report) ||
                    isReportLocked() ||
                    !isDatValuationPossible(report)
                "
                [matTooltip]="
                    getDatValuationOpenerTooltip({
                        report: report,
                        user: user,
                        valuationProvider: 'detailed',
                    })
                "
                (click)="openDatValuation()">
                <mat-icon>open_in_new</mat-icon>
                <span class="label">
                    Detaillierte
                    <br />
                    Bewertung
                </span>
                <div class="valuation-pending-indicator-container" *ngIf="datValuationConnected" @fadeInAndOut>
                    <mat-icon
                        class="valuation-pending-indicator"
                        matTooltip="Bewertung gestartet. Bitte schließe die Bewertung in valuateExpert ab. Zum Öffnen klicken.">
                        edit
                    </mat-icon>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedValuationProvider === 'audatex'">
            <!--********** Open Audatex Valuation **********-->
            <div
                id="trigger-audatex-valuation"
                [class.disabled]="
                    !isAudatexUserComplete(user) ||
                    !isAudatexValuationInputDataComplete(report) ||
                    !isAudatexValuationPossible(report) ||
                    isReportLocked()
                "
                [matTooltip]="getAudatexValuationExportTooltip({ report, user })"
                (click)="openAudatexValuation()">
                <mat-icon>open_in_new</mat-icon>
                <span class="label">Detaillierte Bewertung</span>
            </div>
        </ng-container>
    </div>
</div>
<!--============================================-->
<!-- END Valuation controls -->
<!--============================================-->

<!--============================================-->
<!-- DAT Valuation Result -->
<!--============================================-->
<div *ngIf="selectedValuationProvider === 'dat' && datValuationRetrieved" id="dat-valuation-results">
    <!--********** DAT original price **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Listenneupreis'"
        [helpIndicator]="'inkl. Ausstattungen'"
        [valueNet]="report.valuation.datValuation.originalPriceWithEquipmentNet"
        [valueGross]="report.valuation.datValuation.originalPriceWithoutEquipmentGross"
        [disabled]="isReportLocked()"
        [primaryValue]="
            report.valuation.datValuation.includeVat ? 'gross' : 'net'
        "></dat-or-audatex-valuation-result-row>

    <!--********** Dealer Purchase Price **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Händler-EK'"
        helpIndicator="Händler-Einkaufspreis"
        [valueNet]="report.valuation.datValuation.dealerPurchasePrice"
        [valueGross]="report.valuation.datValuation.dealerPurchasePriceGross"
        [subtitleText]="purchasePriceLabel"
        [subtitleIcon]="purchasePriceLabel && 'shoppingmode'"
        [disabled]="isReportLocked()"
        (click)="setDatVehicleValue()"
        [primaryValue]="
            report.valuation.datValuation.includeVat ? 'gross' : 'net'
        "></dat-or-audatex-valuation-result-row>

    <!--********** Dealer Sales Price **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Händler-VK'"
        helpIndicator="Händler-Verkaufspreis"
        [valueNet]="report.valuation.datValuation.dealerSalesPrice"
        [valueGross]="report.valuation.datValuation.dealerSalesPriceGross"
        [subtitleText]="salesPriceLabel"
        [subtitleIcon]="salesPriceLabel && 'shoppingmode'"
        [disabled]="isReportLocked()"
        (click)="setDatVehicleValue()"
        [primaryValue]="
            report.valuation.datValuation.includeVat ? 'gross' : 'net'
        "></dat-or-audatex-valuation-result-row>

    <!--********** DAT Web Scan **********-->
    <dat-or-audatex-valuation-result-row
        *ngIf="report.valuation.datValuation.webscanNet || report.valuation.datValuation.webscanGross"
        [valueTitle]="'DAT WebScan'"
        [valueNet]="report.valuation.datValuation.webscanNet"
        [valueGross]="report.valuation.datValuation.webscanGross"
        [disabled]="isReportLocked()"
        (click)="setDatWebScanValue()"
        [primaryValue]="
            report.valuation.datValuation.includeVat ? 'gross' : 'net'
        "></dat-or-audatex-valuation-result-row>
</div>
<!--============================================-->
<!-- END DAT Valuation Result -->
<!--============================================-->

<!--============================================-->
<!-- Audatex Valuation Result -->
<!--============================================-->
<div *ngIf="selectedValuationProvider === 'audatex' && audatexValuationRetrieved" id="audatex-valuation-results">
    <!--********** Audatex original price **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Listenneupreis'"
        [subtitleText]="'inkl. Ausstattungen'"
        [valueNet]="report.valuation.audatexValuation.originalPriceWithEquipmentNet"
        [valueGross]="report.valuation.audatexValuation.originalPriceWithoutEquipmentGross"
        [disabled]="isReportLocked()"
        [primaryValue]="mayCarOwnerDeductTaxes(report) ? 'net' : 'gross'"></dat-or-audatex-valuation-result-row>

    <!--********** Audatex Dealer Purchase Price **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Händlereinkaufspreis'"
        [valueNet]="report.valuation.audatexValuation.dealerPurchasePriceNet"
        [valueGross]="report.valuation.audatexValuation.dealerPurchasePriceGross"
        [disabled]="isReportLocked()"
        helpIndicator='Um diesen Wert zu erhalten, musst du bei Audatex z. B. nach dem Ergebniscode "904: Einkaufswert" abfragen.'
        (click)="
            setAudatexVehicleValue({
                valueType: 'dealerPurchase',
                dealerSalesTaxationType: 'full',
                purchaseNet: report.valuation.audatexValuation.dealerPurchasePriceNet,
                purchaseGross: report.valuation.audatexValuation.dealerPurchasePriceGross,
            })
        "
        [primaryValue]="mayCarOwnerDeductTaxes(report) ? 'net' : 'gross'"></dat-or-audatex-valuation-result-row>

    <!--********** Audatex Dealer Sales Price **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Händlerverkaufspreis'"
        [valueNet]="report.valuation.audatexValuation.dealerSalesPriceNet"
        [valueGross]="report.valuation.audatexValuation.dealerSalesPriceGross"
        [disabled]="isReportLocked()"
        helpIndicator='Um diesen Wert zu erhalten, musst du bei Audatex z. B. nach dem Ergebniscode "914: Verkaufswert" abfragen.'
        (click)="
            setAudatexVehicleValue({
                valueType: 'dealerSales',
                dealerSalesTaxationType: 'full',
                salesNet: report.valuation.audatexValuation.dealerSalesPriceNet,
                salesGross: report.valuation.audatexValuation.dealerSalesPriceGross,
            })
        "
        [primaryValue]="mayCarOwnerDeductTaxes(report) ? 'net' : 'gross'"></dat-or-audatex-valuation-result-row>

    <!--********** Audatex Market Value **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Marktwert'"
        [valueNet]="report.valuation.audatexValuation.marketValueNet"
        [valueGross]="report.valuation.audatexValuation.marketValueGross"
        [disabled]="isReportLocked()"
        helpIndicator='Um diesen Wert zu erhalten, musst du bei Audatex z. B. nach dem Ergebniscode "925: Wert am Markt" abfragen.'
        (click)="
            setAudatexVehicleValue({
                valueType: 'marketValue',
                dealerSalesTaxationType: 'full',
                salesNet: report.valuation.audatexValuation.marketValueNet,
                salesGross: report.valuation.audatexValuation.marketValueGross,
            })
        "
        [primaryValue]="mayCarOwnerDeductTaxes(report) ? 'net' : 'gross'"></dat-or-audatex-valuation-result-row>

    <!--********** Audatex Replacement Value (margin taxation) **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Wiederbeschaffungswert'"
        [subtitleText]="'regelbesteuert'"
        [valueNet]="report.valuation.audatexValuation.replacementValueNet"
        [valueGross]="report.valuation.audatexValuation.replacementValueGross"
        [disabled]="isReportLocked()"
        helpIndicator='Um diesen Wert zu erhalten, musst du bei Audatex z. B. nach dem Ergebniscode "962: Wiederbeschaffungswert" abfragen.'
        (click)="
            setAudatexVehicleValue({
                valueType: 'replacementValue',
                dealerSalesTaxationType: 'full',
                salesNet: report.valuation.audatexValuation.replacementValueNet,
                salesGross: report.valuation.audatexValuation.replacementValueGross,
            })
        "
        [primaryValue]="mayCarOwnerDeductTaxes(report) ? 'net' : 'gross'"></dat-or-audatex-valuation-result-row>

    <!--********** Audatex Replacement Value (margin taxation) **********-->
    <dat-or-audatex-valuation-result-row
        [valueTitle]="'Wiederbeschaffungswert'"
        [subtitleText]="'differenzbesteuert'"
        [valueNet]="report.valuation.audatexValuation.replacementValueDifference"
        [valueGross]="report.valuation.audatexValuation.replacementValueGross"
        [disabled]="isReportLocked()"
        helpIndicator='Um diesen Wert zu erhalten, musst du bei Audatex z. B. nach dem Ergebniscode "962: Wiederbeschaffungswert" abfragen.'
        (click)="
            setAudatexVehicleValue({
                valueType: 'replacementValue',
                dealerSalesTaxationType: 'margin',
                salesNet: report.valuation.audatexValuation.replacementValueDifference,
                salesGross: report.valuation.audatexValuation.replacementValueGross,
            })
        "
        [primaryValue]="mayCarOwnerDeductTaxes(report) ? 'net' : 'gross'"></dat-or-audatex-valuation-result-row>
</div>
<!--============================================-->
<!-- END Audatex Valuation Result -->
<!--============================================-->

<!--============================================-->
<!-- Relative Vehicle Value Graphics -->
<!--============================================-->
<div *ngIf="selectedValuationProvider === 'dat' && datValuationRetrieved" id="vehicle-value-graphic-container">
    <div class="vehicle-value-graphic-labels label">
        <span>Fahrzeugwert</span>
        <span>vom Neupreis</span>
    </div>
    <div id="vehicle-value-graphic">
        <div
            id="bar-new-price"
            matTooltip="{{ relativeVehicleValueGraphics.newPriceLabel }} {{
                relativeVehicleValueGraphics.newPrice | number: '1.0-0'
            }} €"></div>
        <div
            id="bar-sales-price"
            [style]="{ width: relativeVehicleValueGraphics.relativeSales }"
            matTooltip="{{ relativeVehicleValueGraphics.salesLabel }} {{
                relativeVehicleValueGraphics.salesPrice | number: '1.0-0'
            }} €"></div>
        <div
            id="bar-purchase-price"
            [style]="{ width: relativeVehicleValueGraphics.relativePurchase }"
            matTooltip="{{ relativeVehicleValueGraphics.purchaseLabel }} {{
                relativeVehicleValueGraphics.purchasePrice | number: '1.0-0'
            }} €"></div>
    </div>
    <div class="vehicle-value-graphic-labels label">
        <span matTooltip="Brutto-Handelsspanne = (Händler-VK - Händler-EK) / Händler-VK * 100. Alle Werte brutto.">
            Marge: {{ relativeVehicleValueGraphics.tradeMarginGrossPercent | number: '1.0-0' }} %
        </span>
    </div>
</div>

<!--============================================-->
<!-- END Relative Vehicle Value Graphics -->
<!--============================================-->
<!--********** DAT Downtime warning **********-->
<context-sensitive-downtime-notification
    childClass="margin-top-30 margin-left-40 margin-right-40"
    *ngIf="selectedValuationProvider === 'dat'"
    [processContext]="'valuation'"></context-sensitive-downtime-notification>

<!--============================================-->
<!-- Loading Overlay -->
<!--============================================-->
<div id="external-valuation-loading-screen" *ngIf="datValuationRequestPending || audatexValuationRequestPending">
    <img class="peding-icon" src="/assets/images/icons/loading-indicator.svg" alt="Loading Indicator" />
    <h2 *ngIf="datValuationRequestPending" class="loading-indicator-text">Warte auf DAT...</h2>
    <h2 *ngIf="audatexValuationRequestPending" class="loading-indicator-text">Warte auf Audatex...</h2>
</div>
<!--============================================-->
<!-- END Loading Overlay -->
<!--============================================-->

<!--============================================-->
<!-- Excluded Overlay -->
<!--============================================-->
<div id="external-valuation-excluded-overlay" *ngIf="valuationExcludedFromReport" @fadeInAndOut></div>
<!--============================================-->
<!-- END Excluded Overlay -->
<!--============================================-->

<!--********** Valuation results **********-->
<div class="valuation-results"></div>

<!--============================================-->
<!-- DAT Vehicle Valuation -->
<!--============================================-->
<!-- Display the valuation either if the DAT is the vehicle data provider or, if not so, if the DAT credentials are complete. We've built it so that the user may start a DAT valuation even within an Audatex case in autoiXpert. -->
<div
    id="dat-valuation-container"
    class="market-analysis-container"
    [ngClass]="{ 'hidden-on-printout': report.valuation.datValuation?.corridorHiddenOnPrintout }"
    *ngIf="report.type !== 'valuation' && (report.car.identificationProvider === 'dat' || isDatUserComplete(user))">
    <div class="market-analysis-header">
        <div class="market-analysis-logo-container">
            <img
                id="dat-valuation-logo"
                src="/assets/images/logos/dat-logo-no-text.svg"
                (click)="openDatValuation()"
                class="cursor-pointer"
                alt="DAT-Logo"
                matTooltip="Fahrzeugbewertung mit DAT" />
        </div>
        <div class="market-analysis-toolbar has-submenu">
            <mat-icon
                *ngIf="user.datUser.isValuateProUser"
                class="warning small-icon dat-valuatepro-info-icon"
                matTooltip="Du nutzt einen DAT valuatePro Account. Damit erhältst du zwar Fahrzeugwerte, aber keine PDF-Dateien über die DAT-Schnittstelle.">
                warning
            </mat-icon>
            <!--********** Taxation Handle **********-->
            <span
                id="valuation-taxation-type-handle"
                class="label"
                [ngSwitch]="report.valuation.taxationType"
                [matMenuTriggerFor]="taxationHandleMenu">
                <span *ngSwitchCase="null">Steuersatz wählen</span>
                <span *ngSwitchCase="'full'" matTooltip="Regelbesteuert">Regel 19 %</span>
                <span *ngSwitchCase="'full_16'" matTooltip="Regelbesteuert">Regel 16 %</span>
                <span *ngSwitchCase="'margin'" matTooltip="Differenzbesteuert">Diff. 2,4 %</span>
                <span *ngSwitchCase="'margin_reduced'" matTooltip="Differenzbesteuert">Diff. 2 %</span>
                <span *ngSwitchCase="'neutral'">Neutral (Privatmarkt)</span>
            </span>
            <mat-menu #taxationHandleMenu>
                <a
                    mat-menu-item
                    (click)="
                        setDatValuationTaxationType('full');
                        setReplacementValueTaxationType('full');
                        saveReportAndRetrieveValuationResults()
                    "
                    matTooltip="Neue oder junge Fahrzeuge, für die überwiegend MwSt. ausgewiesen werden kann (Verkäufer sind Hersteller, Händler, Gewerbetreibende).">
                    Regelbesteuert - 19 %
                </a>
                <a
                    mat-menu-item
                    (click)="
                        setDatValuationTaxationType('margin');
                        setReplacementValueTaxationType('margin');
                        saveReportAndRetrieveValuationResults()
                    "
                    matTooltip="Mittelalte Fahrzeuge, die überwiegend im Gebrauchtwagenhandel verfügbar sind. Typisches Fahrzeugalter: 3 bis 10 Jahre.">
                    Differenzbesteuert - 2,4 %
                </a>
                <a
                    mat-menu-item
                    (click)="
                        setDatValuationTaxationType('neutral');
                        setReplacementValueTaxationType('neutral');
                        saveReportAndRetrieveValuationResults()
                    "
                    matTooltip="Neutrale Besteuerung (Privatmarkt) - 0%"
                    [disabled]="!neutralTaxationTypeAllowed()"
                    [matTooltip]="
                        neutralTaxationTypeAllowed()
                            ? 'Ältere Fahrzeuge, die überwiegend auf dem Privatmarkt gehandelt werden. MwSt. kann nicht ausgewiesen werden.'
                            : 'Der neutrale Steuersatz wird von der DAT nur für den Bewertungstyp \'Wiederbeschaffungswert\' unterstützt.'
                    ">
                    Neutral (Privatmarkt) - 0 %
                </a>
            </mat-menu>

            <!--********** Open Icon **********-->
            <mat-icon
                class="market-analysis-opener toolbar-icon"
                [class.disabled]="
                    !isDatUserComplete(user) ||
                    !isDatValuationInputDataComplete(report) ||
                    !isDatValuationPossible(report) ||
                    isReportLocked()
                "
                (click)="executeDatQuickValuation()"
                *ngIf="!report.valuation.datValuation.dossierId && !datValuationRequestPending"
                [matTooltip]="getDatValuationExportTooltip({ report, user })">
                open_in_new
            </mat-icon>

            <!--********** Export Pending Indicator **********-->
            <img
                class="market-analysis-pending-icon pending-icon toolbar-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="datValuationRequestPending"
                matTooltip="Kommuniziere mit DAT..." />

            <!--********** Import Icon **********-->
            <mat-icon
                class="market-analysis-import-icon toolbar-icon"
                (click)="retrieveDatValuationResults()"
                *ngIf="
                    report.valuation.datValuation.dossierId &&
                    !report.valuation.vehicleValueNet &&
                    !datValuationRequestPending
                "
                matTooltip="Ergebnis abrufen">
                file_download
            </mat-icon>

            <!--********** Hide Corridor On Printout **********-->
            <mat-icon
                class="toolbar-icon"
                *ngIf="report.valuation.datValuation?.corridorHiddenOnPrintout"
                (click)="toggleHideCorridorOnPrintout(report.valuation.datValuation, 'dat'); saveReport()"
                matTooltip="Für diese Quelle wird kein WBW-Korridor abgedruckt. Klicken, um den Korridor wieder zu aktivieren.">
                visibility_off
            </mat-icon>

            <!--********** 3-Dot-Menu **********-->
            <mat-icon
                *ngIf="!datValuationRequestPending"
                class="toolbar-icon"
                [matMenuTriggerFor]="datValuationSubMenu">
                more_vert
            </mat-icon>
        </div>
    </div>
    <mat-menu #datValuationSubMenu>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="openDatValuation()"
            [disabled]="
                !isDatUserComplete(user) ||
                !isDatValuationInputDataComplete(report) ||
                !isDatValuationPossible(report) ||
                isReportLocked()
            "
            [matTooltip]="getDatValuationExportTooltip({ report, user })">
            <mat-icon>open_in_new</mat-icon>
            Detaillierte Bewertung
        </a>

        <!--********** Connect DAT Valuation manually **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="!isReportLocked() && openDatValuationConnectionDialog()"
            *ngIf="!report.valuation.datValuation?.dossierId"
            [disabled]="isReportLocked()"
            matTooltip="DAT-Bewertung von DAT.de manuell mit diesem Gutachten verknüpfen">
            <mat-icon>link</mat-icon>
            DAT-Bewertung verknüpfen
        </a>

        <!--********** Disconnect DAT Valuation **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="unlinkDatValuation()"
            *ngIf="report.valuation.datValuation?.dossierId"
            matTooltip="DAT-Bewertung von diesem Gutachten trennen. Es bleibt aber anders als beim Löschen auf DAT.de erhalten.">
            <mat-icon>link_off</mat-icon>
            DAT-Bewertung trennen
        </a>

        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="transmitDatValuation()"
            *ngIf="report.valuation.datValuation?.dossierId"
            matTooltip="Relevant, falls du Fahrzeugdaten in autoiXpert nach der Erstellung der Bewertung geändert hast:{{
                '\n'
            }}- Laufleistung{{ '\n' }}- Erstzulassung{{ '\n' }}- usw."
            [disabled]="isReportLocked()">
            <mat-icon>publish</mat-icon>
            Daten zu DAT überspielen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="retrieveDatValuationResults()"
            *ngIf="report.valuation.datValuation?.dossierId">
            <mat-icon>file_download</mat-icon>
            Ergebnis importieren
        </a>

        <a mat-menu-item class="menu-item-with-icon" (click)="showDatValuationSettingsDialog()">
            <mat-icon>settings</mat-icon>
            Einstellungen
        </a>

        <!--********** Toggle Hide Corridor On Printout **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            [disabled]="isReportLocked()"
            *ngIf="report.valuation.datValuation?.dossierId && !report.valuation.datValuation?.corridorHiddenOnPrintout"
            (click)="toggleHideCorridorOnPrintout(report.valuation.datValuation, 'dat'); saveReport()"
            matTooltip="Für diese Quelle keinen WBW-Korridor abdrucken.{{
                '\n\n'
            }}Diese Funktion greift nur, wenn die WBW-Korridortabelle im Textbaustein 'Wiederbeschaffungswert' aktiviert ist.">
            <mat-icon>visibility_off</mat-icon>
            Korridor nicht abdrucken
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="report.valuation.datValuation?.dossierId && report.valuation.datValuation?.corridorHiddenOnPrintout"
            (click)="toggleHideCorridorOnPrintout(report.valuation.datValuation, 'dat'); saveReport()"
            matTooltip="Für diese Quelle den WBW-Korridor abdrucken">
            <mat-icon>visibility</mat-icon>
            Korridor abdrucken
        </a>

        <a
            mat-menu-item
            class="menu-item-with-icon alert"
            (click)="resetDatValuation()"
            *ngIf="report.valuation.valuationProvider">
            <mat-icon>delete</mat-icon>
            Bewertung löschen
        </a>
    </mat-menu>

    <!-- The vehicle value may be 0 if the repair costs are greater than the sales price. -->
    <div
        class="market-analysis-result-data"
        *ngIf="
            getDatVehicleValueByPriceType({
                report,
                vehicleValueType: report.valuation.datValuation.vehicleValueType,
                netOrGross: 'gross',
            }) != null
        ">
        <div class="market-analysis-result-row">
            <div
                class="label"
                [matTooltip]="getValuationPriceLabelTooltip(report.valuation.datValuation.vehicleValueType)">
                {{ translateVehicleValueType(report.valuation.datValuation.vehicleValueType) }}
                {{ report.valuation.datValuation.webscanGross ? '(Statistik)' : '' }}
            </div>
            <div
                class="value"
                (click)="
                    setVehicleValueGross(
                        getDatVehicleValueByPriceType({
                            report,
                            vehicleValueType: report.valuation.datValuation.vehicleValueType,
                            netOrGross: 'gross',
                        })
                    );
                    saveReport()
                "
                [matTooltip]="isReportLocked() ? '' : 'Fahrzeugwert übernehmen'"
                [ngClass]="{ 'cursor-pointer': !isReportLocked() }">
                {{
                    getDatVehicleValueByPriceType({
                        report,
                        vehicleValueType: report.valuation.datValuation.vehicleValueType,
                        netOrGross: 'gross',
                    }) | number: '1.2-2'
                }}
                €
            </div>
        </div>

        <!--********** DAT Web Scan Value **********-->
        <div *ngIf="report.valuation.datValuation.webscanGross" class="market-analysis-result-row max-price">
            <div
                class="label"
                [matTooltip]="
                    'AutoiXpert übernimmt den Brutto-Wert, den du im Tab webScan in der Oberfläche der DAT gesetzt hast.\nDas webScan-Protokoll (PDF) kann von autoiXpert nicht automatisiert ausgelesen und bereitgestellt werden, du kannst es aber in der Oberfläche der DAT herunterladen und dann in autoiXpert hochladen.'
                ">
                Wiederbeschaffungswert (webScan)
            </div>
            <div
                class="value"
                (click)="setVehicleValueGross(report.valuation.datValuation.webscanGross, 'dat'); saveReport()"
                [matTooltip]="isReportLocked() ? '' : 'Fahrzeugwert übernehmen'"
                [ngClass]="{ 'cursor-pointer': !isReportLocked() }">
                {{ report.valuation.datValuation.webscanGross | number: '1.2-2' }} &euro;
            </div>
        </div>

        <!--********** New Car Value **********-->
        <div class="market-analysis-result-row max-price">
            <div class="label" matTooltip="Brutto. Mit Ausstattungen.">Neupreis</div>
            <div class="value">{{ report.valuation.originalPriceWithEquipmentGross | number: '1.2-2' }} &euro;</div>
        </div>
    </div>

    <!--********** DAT Downtime warning **********-->
    <context-sensitive-downtime-notification
        childClass="margin-top-10"
        *ngIf="report.car.identificationProvider === 'dat'"
        [processContext]="'valuation'"></context-sensitive-downtime-notification>
</div>
<!--============================================-->
<!-- END DAT Vehicle Valuation -->
<!--============================================-->

<!--============================================-->
<!-- Audatex Vehicle Valuation -->
<!--============================================-->
<div
    id="audatex-valuation-container"
    class="market-analysis-container"
    [ngClass]="{ 'hidden-on-printout': report.valuation.audatexValuation?.corridorHiddenOnPrintout }"
    *ngIf="report.type !== 'valuation' && report.car.identificationProvider === 'audatex'">
    <div class="market-analysis-header">
        <div class="market-analysis-logo-container">
            <img
                id="audatex-valuation-logo"
                src="/assets/images/logos/audatex-solera.svg"
                alt="Audatex Qapter Logo"
                matTooltip="Fahrzeugbewertung mit Audatex" />
        </div>
        <div class="market-analysis-toolbar has-submenu">
            <!--********** Import Icon **********-->
            <mat-icon
                class="market-analysis-import-icon toolbar-icon"
                (click)="importAudatexValuation()"
                *ngIf="
                    report.audatexTaskId &&
                    !getSelectedAudatexValuationResult().valueGross &&
                    !audatexValuationRequestPending
                "
                matTooltip="Ergebnis abrufen">
                file_download
            </mat-icon>

            <!--********** Open Icon **********-->
            <mat-icon
                class="market-analysis-opener toolbar-icon"
                *ngIf="
                    report.audatexTaskId &&
                    !getSelectedAudatexValuationResult().valueGross &&
                    !audatexValuationRequestPending
                "
                [class.disabled]="
                    !isAudatexUserComplete(user) ||
                    !isAudatexValuationInputDataComplete(report) ||
                    !isAudatexValuationPossible(report) ||
                    isReportLocked()
                "
                (click)="openAudatexValuation()"
                [matTooltip]="getAudatexValuationExportTooltip({ report, user })">
                open_in_new
            </mat-icon>

            <!--********** Export Pending Indicator **********-->
            <img
                class="market-analysis-pending-icon pending-icon toolbar-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="audatexValuationRequestPending"
                matTooltip="Kommuniziere mit Audatex..." />

            <!--********** Hide Corridor On Printout **********-->
            <mat-icon
                class="toolbar-icon"
                *ngIf="report.valuation.audatexValuation?.corridorHiddenOnPrintout"
                (click)="toggleHideCorridorOnPrintout(report.valuation.audatexValuation, 'audatex'); saveReport()"
                matTooltip="Für diese Quelle wird kein WBW-Korridor abgedruckt. Klicken, um den Korridor wieder zu aktivieren.">
                visibility_off
            </mat-icon>

            <!--********** Menu **********-->
            <mat-icon
                class="market-analysis-submenu-icon toolbar-icon"
                *ngIf="getSelectedAudatexValuationResult().valueGross"
                [matMenuTriggerFor]="marketAnalysisAudatexSubMenu">
                more_vert
            </mat-icon>
            <mat-menu #marketAnalysisAudatexSubMenu="matMenu">
                <a mat-menu-item class="menu-item-with-icon" (click)="openAudatexValuation()">
                    <mat-icon>open_in_new</mat-icon>
                    Marktanalyse öffnen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="importAudatexValuation()"
                    *ngIf="!isReportLocked()">
                    <mat-icon>file_download</mat-icon>
                    Ergebnisse importieren
                </a>

                <!--********** Toggle Hide Corridor On Printout **********-->
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    *ngIf="
                        getSelectedAudatexValuationResult().valueGross &&
                        !report.valuation.audatexValuation?.corridorHiddenOnPrintout
                    "
                    (click)="toggleHideCorridorOnPrintout(report.valuation.audatexValuation, 'audatex'); saveReport()"
                    matTooltip="Für diese Quelle keinen WBW-Korridor abdrucken.{{
                        '\n\n'
                    }}Diese Funktion greift nur, wenn die WBW-Korridortabelle im Textbaustein 'Wiederbeschaffungswert' aktiviert ist.">
                    <mat-icon>visibility_off</mat-icon>
                    Korridor nicht abdrucken
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    *ngIf="
                        getSelectedAudatexValuationResult().valueGross &&
                        report.valuation.audatexValuation?.corridorHiddenOnPrintout
                    "
                    (click)="toggleHideCorridorOnPrintout(report.valuation.audatexValuation, 'audatex'); saveReport()"
                    matTooltip="Für diese Quelle den WBW-Korridor abdrucken">
                    <mat-icon>visibility</mat-icon>
                    Korridor abdrucken
                </a>

                <a
                    mat-menu-item
                    class="menu-item-with-icon alert"
                    (click)="removeAudatexValuation()"
                    *ngIf="!isReportLocked()">
                    <mat-icon>delete</mat-icon>
                    Marktanalyse löschen
                </a>
            </mat-menu>
        </div>
    </div>
    <div
        class="market-analysis-result-data"
        *ngIf="
            getSelectedAudatexValuationResult().valueGross ||
            report.valuation.audatexValuation?.originalPriceWithEquipmentGross
        ">
        <div class="market-analysis-result-row" *ngIf="getSelectedAudatexValuationResult().valueGross">
            <div class="label">
                {{ translateVehicleValueType(getSelectedAudatexValuationResult().type) }}
            </div>
            <div
                class="value"
                [matTooltip]="isReportLocked() ? '' : 'Fahrzeugwert übernehmen'"
                [ngClass]="{ 'cursor-pointer': !isReportLocked() }"
                (click)="setVehicleValueGross(getSelectedAudatexValuationResult().valueGross, 'audatex'); saveReport()">
                <mat-icon
                    class="warning medium-icon"
                    *ngIf="audatexGrossValueWasCalculatedWithAutoixpertTaxRate"
                    matTooltip="Der brutto-Wert wurde anhand deines in Audatex berechneten netto-Werts und dem von dir in autoiXpert angegebenen Steuersatzes berechnet.{{
                        '\n\n'
                    }}Passe den Steuersatz am besten direkt in Qapter an, damit keine Widersprüche entstehen.">
                    warning
                </mat-icon>
                <span>{{ getSelectedAudatexValuationResult().valueGross | number: '1.2-2' }} €</span>
            </div>
        </div>
        <!--********** Audatex returns the original vehicle value with the VIN response, not only after a vehicle valuation. That's why this line may be shown even if no valuation was conducted. **********-->
        <div
            class="market-analysis-result-row"
            id="audatex-original-price"
            *ngIf="report.valuation.audatexValuation.originalPriceWithEquipmentGross">
            <div class="label" matTooltip="Brutto. Mit Ausstattungen.">Neupreis</div>
            <div class="value">
                {{ report.valuation.audatexValuation.originalPriceWithEquipmentGross | number: '1.2-2' }}
                &euro;
            </div>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Audatex Vehicle Valuation -->
<!--============================================-->

<!--============================================-->
<!-- CARTV, VALUEpilot, winvalue -->
<!--============================================-->
<div
    class="market-analysis-container"
    *ngFor="let marketAnalysisForUi of filteredMarketAnalysesForUi"
    [ngClass]="{ 'hidden-on-printout': marketAnalysisForUi.marketAnalysis.corridorHiddenOnPrintout }">
    <div class="market-analysis-header">
        <div class="market-analysis-logo-container">
            <img
                class="market-analysis-logo cursor-pointer"
                src="/assets/images/logos/{{ marketAnalysisForUi.logoFileName }}"
                (click)="marketAnalysisForUi.openMarketAnalysis()"
                alt="" />
            <mat-icon
                class="market-analysis-done-indicator small-icon"
                *ngIf="marketAnalysisForUi.marketAnalysis.retrievedAt"
                matTooltip="Marktanalyse am {{
                    marketAnalysisForUi.marketAnalysis.retrievedAt | moment: 'DD.MM.YYYY - HH:mm'
                }} Uhr abgerufen">
                check
            </mat-icon>
        </div>
        <div
            class="market-analysis-toolbar"
            [ngClass]="{ 'has-submenu': marketAnalysisForUi.marketAnalysis.analysisId }">
            <!--********** Search radius handle - market analysis underway or complete **********-->
            <div
                class="market-analysis-search-radius-handle label disabled"
                *ngIf="marketAnalysisForUi.haveResultsBeenImported()"
                [class.show-always]="marketAnalysisForUi.marketAnalysis.searchRadiusInKm"
                [ngSwitch]="marketAnalysisForUi.marketAnalysis.searchRadiusInKm"
                matTooltip="Nur nach Gebrauchtwagen-Angeboten in diesem Radius suchen lassen.{{
                    '\n\n'
                }}Editierbar, bevor eine Marktwertanalyse erstellt wird.">
                <div *ngSwitchCase="500">500 km</div>
                <div *ngSwitchCase="200">200 km</div>
                <div *ngSwitchCase="150">150 km</div>
                <div *ngSwitchCase="100">100 km</div>
                <div *ngSwitchCase="50">50 km</div>
                <div *ngSwitchCase="20">20 km</div>
                <div *ngSwitchCase="10">10 km</div>
            </div>
            <!--********** Search radius handle - market analysis can be configured **********-->
            <div
                class="market-analysis-search-radius-handle label"
                *ngIf="!marketAnalysisForUi.haveResultsBeenImported()"
                [class.show-always]="marketAnalysisForUi.marketAnalysis.searchRadiusInKm"
                [ngSwitch]="marketAnalysisForUi.marketAnalysis.searchRadiusInKm"
                [matMenuTriggerFor]="searchRadiusHandle"
                matTooltip="Nur nach Gebrauchtwagen-Angeboten in bestimmtem Umkreis suchen lassen.">
                <div *ngSwitchCase="500">500 km</div>
                <div *ngSwitchCase="200">200 km</div>
                <div *ngSwitchCase="150">150 km</div>
                <div *ngSwitchCase="100">100 km</div>
                <div *ngSwitchCase="50">50 km</div>
                <div *ngSwitchCase="20">20 km</div>
                <div *ngSwitchCase="10">10 km</div>
                <mat-icon class="toolbar-icon" *ngSwitchDefault>radar</mat-icon>
            </div>
            <mat-menu #searchRadiusHandle>
                <a mat-menu-item (click)="marketAnalysisForUi.setSearchRadius(null)">alle</a>
                <a
                    mat-menu-item
                    *ngFor="let searchRadius of marketAnalysisForUi.allowedSearchRadii"
                    (click)="marketAnalysisForUi.setSearchRadius(searchRadius)">
                    {{ searchRadius }} km
                </a>
            </mat-menu>

            <ng-container
                *ngIf="
                    !marketAnalysisForUi.isReadyForFirstImport() &&
                    !marketAnalysisForUi.haveResultsBeenImported() &&
                    !marketAnalysisForUi.exportPending
                ">
                <mat-icon
                    class="market-analysis-opener toolbar-icon"
                    [class.disabled]="
                        !marketAnalysisForUi.areCredentialsComplete() ||
                        !marketAnalysisForUi.isMarketAnalysisAllowed() ||
                        isReportLocked()
                    "
                    (click)="marketAnalysisForUi.openMarketAnalysis()"
                    [matTooltip]="marketAnalysisForUi.getTooltipForExportIcon()">
                    open_in_new
                </mat-icon>

                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="marketAnalysisSubmenuContainer">more_vert</mat-icon>
                <mat-menu #marketAnalysisSubmenuContainer>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        (click)="!isReportLocked() && marketAnalysisForUi.openManualConnectionDialog()"
                        [disabled]="isReportLocked()"
                        matTooltip="Falls du eine Marktwertanalyse bereits extern gestartet hast, kannst du sie hier nachträglich verknüpfen und die Ergebnisse importieren.">
                        <mat-icon>link</mat-icon>
                        Marktwert-Analyse verknüpfen
                    </a>
                </mat-menu>
            </ng-container>
            <img
                class="market-analysis-pending-icon pending-icon toolbar-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="marketAnalysisForUi.exportPending"
                matTooltip="Exportiere..." />
            <mat-icon
                class="market-analysis-import-icon toolbar-icon"
                (click)="marketAnalysisForUi.importMarketAnalysis()"
                *ngIf="marketAnalysisForUi.isReadyForFirstImport() && !marketAnalysisForUi.importPending"
                matTooltip="Ergebnis abrufen">
                file_download
            </mat-icon>
            <img
                class="market-analysis-pending-icon pending-icon toolbar-icon"
                src="/assets/images/icons/loading-indicator.svg"
                *ngIf="marketAnalysisForUi.importPending"
                matTooltip="Importiere..." />

            <!--********** Hide Corridor On Printout **********-->
            <mat-icon
                class="toolbar-icon"
                *ngIf="marketAnalysisForUi.marketAnalysis.corridorHiddenOnPrintout"
                (click)="
                    toggleHideCorridorOnPrintout(marketAnalysisForUi.marketAnalysis, marketAnalysisForUi.name);
                    saveReport()
                "
                matTooltip="Für diese Quelle wird kein WBW-Korridor abgedruckt. Klicken, um den Korridor wieder zu aktivieren.">
                visibility_off
            </mat-icon>

            <!--********** Submenu **********-->
            <mat-icon
                class="market-analysis-submenu-icon toolbar-icon"
                *ngIf="marketAnalysisForUi.isReadyForFirstImport() || marketAnalysisForUi.marketAnalysis.analysisId"
                [matMenuTriggerFor]="marketAnalysisSubMenu">
                more_vert
            </mat-icon>
        </div>
    </div>

    <!--============================================-->
    <!-- Market Analysis Submenu -->
    <!--============================================-->
    <mat-menu #marketAnalysisSubMenu="matMenu">
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="marketAnalysisForUi.openMarketAnalysis()"
            *ngIf="marketAnalysisForUi.marketAnalysis.analysisId && marketAnalysisForUi.marketAnalysis.autoLoginUrl">
            <mat-icon>open_in_new</mat-icon>
            Marktanalyse öffnen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="marketAnalysisForUi.importMarketAnalysis()"
            *ngIf="
                marketAnalysisForUi.marketAnalysis.analysisId &&
                !isReportLocked() &&
                marketAnalysisForUi.name !== 'valuepilot'
            ">
            <mat-icon>file_download</mat-icon>
            Ergebnisse importieren
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="marketAnalysisForUi.importMarketAnalysis(true)"
            *ngIf="marketAnalysisForUi.marketAnalysis.retrievedAt && !isReportLocked()">
            <mat-icon>description</mat-icon>
            Marktanalyse herunterladen
        </a>

        <!--********** Toggle Hide Corridor On Printout **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="
                marketAnalysisForUi.haveResultsBeenImported() &&
                !marketAnalysisForUi.marketAnalysis.corridorHiddenOnPrintout
            "
            (click)="
                toggleHideCorridorOnPrintout(marketAnalysisForUi.marketAnalysis, marketAnalysisForUi.name); saveReport()
            "
            matTooltip="Für diese Quelle keinen WBW-Korridor abdrucken.{{
                '\n\n'
            }}Diese Funktion greift nur, wenn die WBW-Korridortabelle im Textbaustein 'Wiederbeschaffungswert' aktiviert ist.">
            <mat-icon>visibility_off</mat-icon>
            Korridor nicht abdrucken
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="
                marketAnalysisForUi.haveResultsBeenImported() &&
                marketAnalysisForUi.marketAnalysis.corridorHiddenOnPrintout
            "
            (click)="
                toggleHideCorridorOnPrintout(marketAnalysisForUi.marketAnalysis, marketAnalysisForUi.name); saveReport()
            "
            matTooltip="Für diese Quelle den WBW-Korridor abdrucken">
            <mat-icon>visibility</mat-icon>
            Korridor abdrucken
        </a>

        <a
            mat-menu-item
            class="menu-item-with-icon alert"
            (click)="marketAnalysisForUi.resetMarketAnalysis()"
            *ngIf="!isReportLocked()">
            <mat-icon>delete</mat-icon>
            Marktanalyse löschen
        </a>
    </mat-menu>
    <!--============================================-->
    <!-- END Market Analysis Submenu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Market Analysis Result -->
    <!--============================================-->
    <div class="market-analysis-result-data" *ngIf="marketAnalysisForUi.haveResultsBeenImported()">
        <div class="market-analysis-result-row max-price">
            <div class="label" matTooltip="Höchstes gefundenes Verkaufsangebot">Max.</div>
            <div
                class="value"
                (click)="
                    setVehicleValueGross(marketAnalysisForUi.marketAnalysis.maxCorridor, marketAnalysisForUi.name);
                    saveReport()
                "
                [matTooltip]="isReportLocked() ? '' : 'Fahrzeugwert übernehmen'"
                [ngClass]="{ 'cursor-pointer': !isReportLocked() }">
                {{ marketAnalysisForUi.marketAnalysis.maxCorridor | number: '1.2-2' }}
                &euro;
            </div>
        </div>
        <div class="market-analysis-result-row average-price" *ngIf="marketAnalysisForUi.marketAnalysis.averagePrice">
            <div class="label" matTooltip="Durchschnittlich">Durchschnittlich</div>
            <div
                class="value"
                (click)="
                    setVehicleValueGross(marketAnalysisForUi.marketAnalysis.averagePrice, marketAnalysisForUi.name);
                    saveReport()
                "
                [matTooltip]="isReportLocked() ? '' : 'Fahrzeugwert übernehmen'"
                [ngClass]="{ 'cursor-pointer': !isReportLocked() }">
                {{ marketAnalysisForUi.marketAnalysis.averagePrice | number: '1.2-2' }}
                &euro;
            </div>
        </div>
        <div class="market-analysis-result-row min-price">
            <div class="label" matTooltip="Kleinstes gefundenes Verkaufsangebot">Min.</div>
            <div
                class="value"
                (click)="
                    setVehicleValueGross(marketAnalysisForUi.marketAnalysis.minCorridor, marketAnalysisForUi.name);
                    saveReport()
                "
                [matTooltip]="isReportLocked() ? '' : 'Fahrzeugwert übernehmen'"
                [ngClass]="{ 'cursor-pointer': !isReportLocked() }">
                {{ marketAnalysisForUi.marketAnalysis.minCorridor | number: '1.2-2' }}
                &euro;
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Market Analysis Result -->
    <!--============================================-->

    <!--***********************************************
        ** CarTV Downtime Compensation Notification
        ************************************************-->
    <div
        class="info-note margin-top-10"
        *ngIf="
            marketAnalysisForUi.name === 'cartv' &&
            showCartvDowntimeCompensationNotification &&
            !user?.userInterfaceStates.cartvDowntimeCompensationImportNoticeClosed
        ">
        <mat-icon>info</mat-icon>
        <div>
            <h3>Tipp</h3>
            <div>
                Wenn du bei CARTV einen
                <a href="https://wissen.autoixpert.de/hc/de/articles/5162894117394" target="_blank" rel="noopener">
                    Nutzungsausfall berechnen
                </a>
                lässt, wird dieser automatisch übernommen.
            </div>
        </div>
        <mat-icon
            class="info-note-close-icon toolbar-icon"
            (click)="closeCartvCompensationNotification()"
            matTooltip="Hinweis dauerhaft ausblenden">
            close
        </mat-icon>
    </div>
    <!--***********************************************
        ** END CarTV Downtime Compensation Notification
        ************************************************-->
</div>
<!--============================================-->
<!-- END CARTV, VALUEpilot, winvalue -->
<!--============================================-->

<!--============================================-->
<!-- Custom Market Analysis -->
<!--============================================-->
<div
    class="market-analysis-container"
    *ngFor="let customMarketAnalysis of report.valuation.customMarketAnalyses"
    [ngClass]="{ 'hidden-on-printout': customMarketAnalysis.corridorHiddenOnPrintout }">
    <div class="market-analysis-header">
        <div class="market-analysis-logo-container" [ngSwitch]="(customMarketAnalysis.title || '').toLowerCase()">
            <!--********** DAT-Webscan **********-->
            <div
                class="display-flex flex-align-center cursor-pointer"
                *ngSwitchCase="'dat-webscan'"
                (click)="openEditCustomMarketAnalysisDialog(customMarketAnalysis)">
                <img
                    class="market-analysis-logo margin-right-10"
                    src="/assets/images/logos/dat-logo-no-text.png"
                    alt="" />
                DAT-Webscan
            </div>

            <!--********** Mobile.de **********-->
            <img
                class="market-analysis-logo cursor-pointer"
                *ngSwitchCase="'mobile.de'"
                (click)="openEditCustomMarketAnalysisDialog(customMarketAnalysis)"
                src="/assets/images/logos/mobilede-logo.png"
                alt="" />

            <!--********** PKW.de **********-->
            <img
                class="market-analysis-logo cursor-pointer"
                *ngSwitchCase="'pkw.de'"
                (click)="openEditCustomMarketAnalysisDialog(customMarketAnalysis)"
                src="/assets/images/logos/pkw_de.png"
                alt="" />

            <!--********** AutoScout24 **********-->
            <img
                class="market-analysis-logo cursor-pointer"
                *ngSwitchCase="'autoscout24'"
                (click)="openEditCustomMarketAnalysisDialog(customMarketAnalysis)"
                src="/assets/images/logos/autoscout24-logo.png"
                alt="" />

            <!--********** Other **********-->
            <span
                class="cursor-pointer bold"
                *ngSwitchDefault
                (click)="openEditCustomMarketAnalysisDialog(customMarketAnalysis)">
                {{ customMarketAnalysis.title }}
            </span>

            <!--********** Retrieved At **********-->
            <mat-icon
                class="market-analysis-done-indicator small-icon"
                *ngIf="customMarketAnalysis.retrievedAt"
                matTooltip="Marktanalyse am {{
                    customMarketAnalysis.retrievedAt | moment: 'DD.MM.YYYY - HH:mm'
                }} Uhr erstellt">
                check
            </mat-icon>
        </div>
        <div class="market-analysis-toolbar has-submenu">
            <!--********** Edit **********-->
            <mat-icon
                class="market-analysis-submenu-icon toolbar-icon"
                *ngIf="
                    !customMarketAnalysis.maxCorridor ||
                    !customMarketAnalysis.minCorridor ||
                    !customMarketAnalysis.averagePrice
                "
                (click)="openEditCustomMarketAnalysisDialog(customMarketAnalysis)">
                edit
            </mat-icon>

            <!--********** Hide Corridor On Printout **********-->
            <mat-icon
                class="toolbar-icon"
                *ngIf="customMarketAnalysis.corridorHiddenOnPrintout"
                (click)="toggleHideCorridorOnPrintout(customMarketAnalysis, 'custom'); saveReport()"
                matTooltip="Für diese Quelle wird kein WBW-Korridor abgedruckt. Klicken, um den Korridor wieder zu aktivieren.">
                visibility_off
            </mat-icon>

            <!--********** Submenu **********-->
            <mat-icon
                class="market-analysis-submenu-icon toolbar-icon"
                [matMenuTriggerFor]="customMarketAnalysisSubMenu">
                more_vert
            </mat-icon>
        </div>
    </div>
    <mat-menu #customMarketAnalysisSubMenu>
        <a mat-menu-item class="menu-item-with-icon" (click)="openEditCustomMarketAnalysisDialog(customMarketAnalysis)">
            <mat-icon>edit</mat-icon>
            Bearbeiten
        </a>

        <!--********** Toggle Hide Corridor On Printout **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="!customMarketAnalysis.corridorHiddenOnPrintout"
            (click)="toggleHideCorridorOnPrintout(customMarketAnalysis, 'custom'); saveReport()"
            matTooltip="Für diese Quelle keinen WBW-Korridor abdrucken.{{
                '\n\n'
            }}Diese Funktion greift nur, wenn die WBW-Korridortabelle im Textbaustein 'Wiederbeschaffungswert' aktiviert ist.">
            <mat-icon>visibility_off</mat-icon>
            Korridor nicht abdrucken
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="customMarketAnalysis.corridorHiddenOnPrintout"
            (click)="toggleHideCorridorOnPrintout(customMarketAnalysis, 'custom'); saveReport()"
            matTooltip="Für diese Quelle den WBW-Korridor abdrucken">
            <mat-icon>visibility</mat-icon>
            Korridor abdrucken
        </a>

        <a
            mat-menu-item
            class="menu-item-with-icon alert"
            (click)="deleteCustomMarketAnalysis(customMarketAnalysis); saveReport()"
            *ngIf="!isReportLocked()">
            <mat-icon>delete</mat-icon>
            Löschen
        </a>
    </mat-menu>

    <div
        class="market-analysis-result-data"
        *ngIf="
            customMarketAnalysis.minCorridor || customMarketAnalysis.maxCorridor || customMarketAnalysis.averagePrice
        ">
        <div class="market-analysis-result-row max-price">
            <div class="label" matTooltip="Höchstes gefundenes Verkaufsangebot">Max.</div>
            <div
                class="value cursor-pointer"
                (click)="setVehicleValueGross(customMarketAnalysis.maxCorridor, 'custom'); saveReport()"
                matTooltip="Fahrzeugwert übernehmen">
                {{ customMarketAnalysis.maxCorridor || 0 | number: '1.2-2' }} &euro;
            </div>
        </div>
        <div class="market-analysis-result-row average-price" *ngIf="customMarketAnalysis.averagePrice">
            <div class="label" matTooltip="Durchschnittlich">Durchschnittlich</div>
            <div
                class="value cursor-pointer"
                (click)="setVehicleValueGross(customMarketAnalysis.averagePrice, 'custom'); saveReport()"
                matTooltip="Fahrzeugwert übernehmen">
                {{ customMarketAnalysis.averagePrice || 0 | number: '1.2-2' }} &euro;
            </div>
        </div>
        <div class="market-analysis-result-row min-price">
            <div class="label" matTooltip="Kleinstes gefundenes Verkaufsangebot">Min.</div>
            <div
                class="value cursor-pointer"
                (click)="setVehicleValueGross(customMarketAnalysis.minCorridor, 'custom'); saveReport()"
                matTooltip="Fahrzeugwert übernehmen">
                {{ customMarketAnalysis.minCorridor || 0 | number: '1.2-2' }} &euro;
            </div>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Custom Market Analysis -->
<!--============================================-->

<section class="text-align-center">
    <button
        [disabled]="isReportLocked()"
        class="flat"
        (click)="createCustomMarketAnalysis()"
        matTooltip="Eigenen WBW-Korridor hinzufügen">
        <mat-icon class="small-icon">add</mat-icon>
        Eigene Recherche
    </button>
</section>

<a [href]="pdfDownloadUrl" style="display: none" download="Marktanalyse.pdf" #pdfDownloadAnchor></a>

<!--============================================-->
<!-- Custom Fields -->
<!--============================================-->
<custom-field-display
    fieldLocation="damage-calculation_market-analysis"
    [report]="report"
    (reportChange)="saveReport()"
    [disabled]="isReportLocked()"></custom-field-display>
<!--============================================-->
<!-- END Custom Fields -->
<!--============================================-->

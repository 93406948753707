import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { ConfirmDialogComponent } from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { findRecordById } from '@autoixpert/lib/arrays/find-record-by-id';
import { removeFromArrayById } from '@autoixpert/lib/arrays/remove-from-array-by-id';
import {
    DocumentTypeGroup,
    getDocumentTypeGroupsFromDocumentOrderConfigs,
} from '@autoixpert/lib/documents/get-document-type-groups-from-document-order-configs';
import { DocumentOrderConfig } from '@autoixpert/models/documents/document-order-config';
import { toggleValueInArray } from '../../shared/libraries/arrays/toggle-value-in-array';
import { ToastService } from '../../shared/services/toast.service';

@Component({
    selector: 'document-types-dialog',
    templateUrl: 'document-types-dialog.component.html',
    styleUrls: ['document-types-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class DocumentTypesDialogComponent implements OnInit {
    constructor(
        private dialog: MatDialog,
        private toastService: ToastService,
    ) {}

    @Input() inputDocumentOrderConfigIds: DocumentOrderConfig['_id'][];
    // A list of all document order configs that are available for selection.
    @Input() allDocumentOrderConfigs: DocumentOrderConfig[];
    /**
     * This is a copy of the input document order config IDs which will be changed depending
     * on the user's selection. Working on a copy prevents the input array in the parent component to change.
     */
    private initialDocumentOrderConfigIds: DocumentOrderConfig['_id'][];
    // This array only contains the document order configs that are currently selected by the user.
    private selectedDocumentOrderConfigs: DocumentOrderConfig[];

    // We won't render the power of attorney with a specific template since the document doesn't belong to the assessor's CI.
    // If customers want to use a custom power of attorney, they have to use a custom signable document.
    @Input() disallowPowerOfAttorney: boolean = false;

    @Output() close = new EventEmitter<void>();
    @Output() save = new EventEmitter<DocumentOrderConfigAssociationChange[]>();

    public documentTypeGroups: DocumentTypeGroup[];

    public async ngOnInit() {
        /**
         * Create copies of the input document types to ensure the user does not change the original
         * input array until he saves the changes. That allows him to cancel changes.
         */
        this.initialDocumentOrderConfigIds = JSON.parse(JSON.stringify(this.inputDocumentOrderConfigIds));
        this.selectedDocumentOrderConfigs = this.allDocumentOrderConfigs.filter((documentOrderConfig) => {
            return this.initialDocumentOrderConfigIds.includes(documentOrderConfig._id);
        });

        /**
         * It's important to generate the document type groups after the document order configs have been copied
         * to preserve object references. They are important in functions like this.isDocumentOrderConfigActive().
         */
        this.documentTypeGroups = getDocumentTypeGroupsFromDocumentOrderConfigs({
            documentOrderConfigs: this.allDocumentOrderConfigs,
        });
    }

    public toggleDocumentOrderConfig(documentOrderConfig: DocumentOrderConfig): void {
        if (this.disallowPowerOfAttorney && documentOrderConfig.type === 'powerOfAttorney') {
            this.toastService.error(
                'Anwaltliche Vollmacht nicht auswählbar',
                'Wenn du die anwaltliche Vollmacht anpassen möchtest, verwende die Funktion <a href="/Einstellungen?section=signable-pdf-documents-container">Unterschreibbare PDF-Dokumente</a>.',
            );
            return;
        }
        toggleValueInArray(documentOrderConfig, this.selectedDocumentOrderConfigs);
    }

    public isDocumentOrderConfigActive(documentOrderConfig: DocumentOrderConfig): boolean {
        return this.selectedDocumentOrderConfigs.includes(documentOrderConfig);
    }

    public getDocumentTypeGroupNameAsCssClassObject(documentTypeGroup: DocumentTypeGroup): any {
        return {
            [documentTypeGroup.name]: true,
        };
    }

    private getDocumentOrderConfigAssociationChanges(): DocumentOrderConfigAssociationChange[] {
        const documentOrderConfigChanges: DocumentOrderConfigAssociationChange[] = [];
        for (const initialDocumentOrderConfigId of this.initialDocumentOrderConfigIds) {
            if (!findRecordById(this.selectedDocumentOrderConfigs, initialDocumentOrderConfigId)) {
                documentOrderConfigChanges.push({
                    documentOrderConfig: findRecordById(this.allDocumentOrderConfigs, initialDocumentOrderConfigId),
                    change: 'removed',
                });
            }
        }
        for (const activeDocumentOrderConfig of this.selectedDocumentOrderConfigs) {
            if (!this.initialDocumentOrderConfigIds.includes(activeDocumentOrderConfig._id)) {
                documentOrderConfigChanges.push({
                    documentOrderConfig: activeDocumentOrderConfig,
                    change: 'added',
                });
            }
        }

        return documentOrderConfigChanges;
    }

    private haveDocumentOrderConfigAssociationsChanged(): boolean {
        return this.getDocumentOrderConfigAssociationChanges().length > 0;
    }

    //*****************************************************************************
    //  Select All/Deselect
    //****************************************************************************/
    public selectAllDocumentOrderConfigsInGroup(documentTypeGroup: DocumentTypeGroup): void {
        for (const documentOrderConfigInGroup of documentTypeGroup.documentOrderConfigs) {
            if (!this.isDocumentOrderConfigActive(documentOrderConfigInGroup)) {
                this.toggleDocumentOrderConfig(documentOrderConfigInGroup);
            }
        }
    }

    public deselectAllDocumentTypesInGroup(documentTypeGroup: DocumentTypeGroup): void {
        for (const documentOrderConfigInGroup of documentTypeGroup.documentOrderConfigs) {
            removeFromArrayById(documentOrderConfigInGroup._id, this.selectedDocumentOrderConfigs);
        }
    }

    public areAllDocumentTypesOfGroupSelected(documentTypeGroup: DocumentTypeGroup): boolean {
        return documentTypeGroup.documentOrderConfigs.every((documentOrderConfigInGroup) =>
            this.isDocumentOrderConfigActive(documentOrderConfigInGroup),
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select All/Deselect
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    public async closeDialog(): Promise<void> {
        if (this.haveDocumentOrderConfigAssociationsChanged()) {
            const shallChangesBeDiscarded: boolean = await this.dialog
                .open(ConfirmDialogComponent, {
                    data: {
                        heading: 'Änderungen verwerfen?',
                        content: 'Die Dokumentauswahl wurde geändert. Verwerfen?',
                        confirmLabel: 'Jap, kann weg',
                        cancelLabel: 'Ups, doch nicht',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();

            if (shallChangesBeDiscarded) {
                this.close.emit();
            }
        } else {
            this.close.emit();
        }
    }

    public saveDialog(): void {
        this.save.emit(this.getDocumentOrderConfigAssociationChanges());
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface DocumentOrderConfigAssociationChange {
    documentOrderConfig: DocumentOrderConfig;
    change: 'added' | 'removed';
}

<div class="conditions-preview" (click)="conditionPreviewClick.emit()">
    <!-- Building block conditions preview heading -->
    <div *ngIf="buildingBlockConditionsPreview" class="building-block-conditions-preview-heading">
        <div>Baustein drucken, wenn...</div>
        <mat-icon
            class="outlined"
            [matTooltip]="'Die Varianten dieses Bausteines werden nur gedruckt, wenn diese Bedinungen erfüllt sind.'">
            help
        </mat-icon>
    </div>

    <!-- Is default variant -->
    <div
        *ngIf="isDefaultVariant"
        class="default-variant-info-container"
        matTooltip="Diese Variante wird gedruckt, wenn die Bedingungen für keine andere Variante erfüllt sind.">
        <div class="default-variant-info-icon-container">
            <mat-icon class="small-icon">web_stories</mat-icon>
        </div>
        <div>Auffang-Variante für diesen Baustein</div>
    </div>

    <div
        *ngIf="!isDefaultVariant"
        class="conditions-preview-list"
        [style.padding-top.px]="getTopPadding()"
        [ngClass]="{ disabled: isDisabled }">
        <!-- Condition group operator (only show if "OR" and number of conditions is > 1) -->
        <div
            *ngIf="conditionsGroup.conditionsOperator === 'or' && conditionsGroup.conditions.length > 1"
            class="condition-group-operator"
            [ngSwitch]="conditionsGroup.conditionsOperator"
            [matTooltip]="
                conditionsGroup.conditionsOperator === 'or'
                    ? 'Mindestens eine Bedingung muss erfüllt sein'
                    : 'Alle Bedingungen müssen erfüllt sein'
            ">
            <span *ngSwitchDefault>&</span>
            <mat-icon *ngSwitchCase="'or'">call_split</mat-icon>
        </div>

        <!-- Conditions list -->
        <ng-container *ngFor="let condition of conditionsGroup.conditions; trackBy: trackById">
            <document-building-block-condition-preview
                [buildingBlockConditionsPreview]="buildingBlockConditionsPreview"
                [condition]="condition"
                [placeholderValueTree]="placeholderValueTree"></document-building-block-condition-preview>
        </ng-container>

        <!-- No conditions placeholder -->
        <div
            class="no-conditions-placeholder"
            *ngIf="conditionsGroup?.conditions?.length === 0"
            (click)="conditionPreviewClick.emit()">
            Immer drucken.
            <div class="label">Klicken, um Bedingung zu erstellen.</div>
        </div>
    </div>

    <!-- Placeholder values evaluation result indicator-->
    <ng-container *ngIf="evaluateConditionGroupWithPlaceholderValues() as conditionsPreview">
        <div
            [ngClass]="{
                'placeholder-values-evaluation-result-indicator': true,
                'placeholder-values-evaluation-result-true-indicator': conditionsPreview.result === true,
                'placeholder-values-evaluation-result-false-indicator': conditionsPreview.result === false,
            }">
            <div class="placeholder-values-evaluation-result-indicator-icon">
                <mat-icon
                    *ngIf="conditionsPreview.result === true"
                    matTooltip="Diese Bedingung wird im ausgewählten Dokument erfüllt">
                    check
                </mat-icon>

                <mat-icon
                    *ngIf="conditionsPreview.result === false"
                    matTooltip="Diese Bedingung wird im ausgewählten Dokument nicht erfüllt">
                    close
                </mat-icon>
            </div>
        </div>
    </ng-container>
</div>

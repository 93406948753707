import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Car } from '@autoixpert/models/reports/car-identification/car';
import { CarEquipment } from '@autoixpert/models/reports/car-identification/car-equipment';

@Injectable()
export class GtmotiveVinService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getVinResult(reportId: string, vin: string): Promise<GtmotiveVinResult[]> {
        return this.httpClient
            .get<GtmotiveVinResult[]>(`${this.apiPath}/reports/${reportId}/gtmotive/vin/${vin}`)
            .toPromise();
    }
}

export interface GtmotiveVinResult {
    // Contains only the data present in the VIN. The car has data like the current amount of kilometers on the tachometer. That could not be saved in the VIN.
    newCarInformation: Car;
    carEquipment: CarEquipment;
}

export interface AxErrorParameters {
    code: string;
    message: string;
    data?: any;
    error?: Error | AxError;
    causedBy?: Error | AxError;
    statusCode?: number;
    responseHeaders?: { [key: string]: string };
    stack?: string;
    name?: string;
}

/**
 * Creates a standardized autoiXpert error object. This class should only be used directly to re-created AxErrors received via an API call.
 * This allows creating errors with static error codes more easily through the constructor.
 */
export class AxError extends Error {
    causedBy: Error | AxError;
    code: string;
    statusCode: number;
    responseHeaders?: { [key: string]: string };
    data: any;
    type: 'AxError';
    stack: string;

    /**
     * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
     * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
     */
    name: AxErrorName = 'AxError';
    constructor({ code, message, data, error, statusCode, responseHeaders, stack }: AxErrorParameters) {
        super(message);

        this.code = code;
        this.data = data;
        /**
         * Send the type "AxError" with the error object so that connected microservices recognize the error as an AxError across
         * JSON serialization and deserialization.
         */
        this.type = 'AxError';
        // Set a default status code so that this property comes before "causedBy" when util.inspect'ing this error.
        this.statusCode = statusCode || 500;
        // Set the response headers so that they can be sent to the client. They cannot be set in feathers context as they get lost
        this.responseHeaders = responseHeaders;
        // If this constructor is used for converting a plain-object AxError to a real AxError, keep the original stack.
        this.stack = stack;

        /**
         * Feathers.js adds the entire context object to the context.error.hook property. Since context.error is usually an AxError and
         * the context object is huge when output to the console or to Slack, we make it non-enumerable yet writable. That causes util.inspect
         * (which we use for printing to the console) to skip this property.
         */
        Object.defineProperty(this, 'hook', {
            enumerable: false,
            writable: true,
        });

        // Check because captureStackTrace is a V8-only method. This wouldn't work in Firefox in the frontend.
        if (!this.stack && 'captureStackTrace' in Error) {
            (Error as any).captureStackTrace(this, this.constructor);
        }
        if (error) {
            /**
             * Remove all the unnecessary information about the hook, the service and the app. The error contains a reference to the hook
             * and the hook a reference to the error. That cyclic reference would prevent the error from being JSON.stringify'd.
             */
            if ((error as any).hook) {
                (error as any).hook = {
                    type: (error as any).hook.type,
                    method: (error as any).hook.method,
                };
            }

            /**
             * If this is not an AxError but a regular TypeError or so, ensure that all its properties such as stack, message etc.
             * are included in JSON output.
             */
            if (!(error instanceof AxError) && typeof error === 'object') {
                /**
                 * Firefox can't handle redefining native error's toJSON. It fails with an error 'TypeError: can't redefine non-configurable property "toJSON"'.
                 *
                 * Since this toJSON assignment is useful but not required, fail silently.
                 */
                try {
                    // Use Object.defineProperty instead of a simple assignment to override toJSON functions that are read-only.
                    Object.defineProperty(error, 'toJSON', {
                        value: function errorToJson() {
                            // This adds attributes like "originalMessage" from non-AxError custom errors such as WinvalueSoapError.
                            const otherErrorAttributes: any = {};
                            for (const key in this) {
                                if (!this.hasOwnProperty(key)) continue;

                                otherErrorAttributes[key] = this[key];
                            }

                            //noinspection JSPotentiallyInvalidUsageOfClassThis
                            return {
                                name: this.name,
                                message: this.message,
                                // Code & type are important for DatSoapErrors.
                                code: this.code,
                                type: this.type,
                                stack: this.stack,
                                ...otherErrorAttributes,
                            };
                        },
                    });
                } catch (error) {
                    // Do nothing.
                }
            }

            // Enable sending an error chain to the client.
            this.causedBy = error;
        }
    }

    static from(axErrorParameters: AxErrorParameters): AxError {
        // If this error should be deserialized, the property is called "causedBy" but it should be treated like "error", so that all errors in the error chain are deserialized, too.
        if (axErrorParameters.causedBy && !axErrorParameters.error) {
            axErrorParameters.error = axErrorParameters.causedBy;
        }

        if (
            typeof axErrorParameters.error === 'object' &&
            'type' in axErrorParameters.error &&
            axErrorParameters.error.type === 'AxError'
        ) {
            axErrorParameters.error = AxError.from(axErrorParameters.error);
        }

        // Use either a specific error such as
        const ErrorConstructor: typeof AxError = AxErrorConstructors[axErrorParameters.name] || AxError;

        return new ErrorConstructor(axErrorParameters);
    }

    toString() {
        return `${this.name}: [${this.code || 'non-aX-error'}] ${this.message}`;
    }

    // Overwrite toJSON so inherited properties such as message and stack are shown also
    toJSON() {
        return {
            code: this.code,
            message: this.message,
            data: this.data,
            causedBy: this.causedBy,
            statusCode: this.statusCode,
            name: this.name,
            stack: this.stack,
            type: this.type,
        };
    }
}

//*****************************************************************************
//  Client Errors
//****************************************************************************/
/**
 * If the user sent a request in the wrong format (technical)
 */
export class BadRequest extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'BadRequest';
        this.statusCode = 400;
    }
}

/**
 * User is not authenticated. Do not mistake "unauthorized". See https://httpstatuses.com/401
 */
export class Unauthorized extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'Unauthorized';
        this.statusCode = 401;
    }
}

/**
 * We know who the user is (authenticated) but he does not have the right to access this resource.
 */
export class Forbidden extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'Forbidden';
        this.statusCode = 403;
    }
}

export class NotFound extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'NotFound';
        this.statusCode = 404;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Client Errors
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Technical Server Errors
//****************************************************************************/
export class ServerError extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'ServerError';
        this.statusCode = 500;
    }
}

/**
 * A related API encountered a timeout error.
 */
export class GatewayTimeoutError extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'GatewayTimeoutError';
        this.statusCode = 504;
    }
}

/**
 * A related API is currently unable to handle the request.
 */
export class ServiceUnavailable extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'ServiceUnavailable';
        this.statusCode = 503;
    }
}

/**
 * The service is currently unavailable. That means we might retry the request shortly.
 */
export class NetworkError extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'NetworkError';
        this.statusCode = 503;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Technical Server Errors
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Business Logic Errors
//****************************************************************************/
/**
 * The user submitted a syntactically correct request but the server cannot process the request due to some business reason.
 * An example would be when a user tries to import an unfinished damage calculation from the DAT.
 */
export class UnprocessableEntity extends AxError {
    constructor({ code, message, data, error }: AxErrorParameters) {
        super({
            code,
            message,
            data,
            error,
        });

        /**
         * Explicitly setting the name instead of deriving it from the class name is required to prevent minified code
         * (usually through Angular > Uglify.js) from using the renamed class name (such as "kb" for "NotFound").
         */
        this.name = 'UnprocessableEntity';
        this.statusCode = 422;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Business Logic Errors
/////////////////////////////////////////////////////////////////////////////*/

type AxErrorName =
    | 'AxError'
    | 'BadRequest'
    | 'Unauthorized'
    | 'Forbidden'
    | 'NotFound'
    | 'ServerError'
    | 'GatewayTimeoutError'
    | 'ServiceUnavailable'
    | 'NetworkError'
    | 'UnprocessableEntity';

/**
 * Useful for conversion of serialized AxError objects (JSON) to real error objects.
 */
export const AxErrorConstructors: { [key in AxErrorName]: typeof AxError } = {
    AxError,
    BadRequest,
    Unauthorized,
    Forbidden,
    NotFound,
    ServerError,
    GatewayTimeoutError,
    ServiceUnavailable,
    NetworkError,
    UnprocessableEntity,
};

import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ResidualValueOffer } from '@autoixpert/models/reports/residual-value/residual-value-offer';

@Component({
    selector: 'autoonline-offer-connection-dialog',
    templateUrl: './autoonline-offer-connection-dialog.component.html',
    styleUrls: ['./autoonline-offer-connection-dialog.component.scss'],
})
export class AutoonlineOfferConnectionDialogComponent {
    public autoonlineOfferId: string;
    public offerReadyAt: string;
    public isOfferIdFormatInvalid: boolean;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() connectedOffer: EventEmitter<ResidualValueOffer> = new EventEmitter();

    public validateAutoonlineOfferIdFormat(): void {
        this.isOfferIdFormatInvalid = false;

        // If no offer ID is set, don't show an error message. The error message should only be shown if the field is filled but has the wrong format.
        if (!this.autoonlineOfferId) {
            return;
        }

        const exampleOfferId = '1202303082823262';
        if (this.autoonlineOfferId.length < exampleOfferId.length) {
            this.isOfferIdFormatInvalid = true;
        }
    }

    public getSubmitButtonTooltip(): string {
        if (!this.autoonlineOfferId) {
            return 'Bitte die AUTOonline Objekt-ID eingeben.';
        }
        if (!this.offerReadyAt) {
            return 'Bitte die Gebotsfrist eingeben.';
        }
        return '';
    }

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/

    public closeDialog(): void {
        this.close.emit();
    }

    public emitObjectNumber() {
        const autoonlineOffer = new ResidualValueOffer();
        autoonlineOffer.offerId = `${this.autoonlineOfferId}`;
        autoonlineOffer.readyAt = this.offerReadyAt;
        autoonlineOffer.link = `https://easyonline.autoonline.com/Mainpage.aspx`;
        this.connectedOffer.emit(autoonlineOffer);
        this.closeDialog();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if ((event.target as HTMLElement).classList.contains('dialog-overlay')) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Window Event Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Window Event Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { getDatVehicleValueByPriceType } from '@autoixpert/lib/car-valuation/get-dat-vehicle-value-by-price-type';
import {
    Translator,
    VehicleValueLabelGerman,
    VehicleValueLabelGermanShort,
} from '@autoixpert/lib/placeholder-values/translator';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { Valuation, ValuationVehicleValueType } from '@autoixpert/models/reports/market-value/valuation';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { getVatRateForTaxationType } from '@autoixpert/static-data/taxation-rates';
import { ToastService } from 'src/app/shared/services/toast.service';
import { getTotalCorrectionsGross } from '../../../../../../../shared/lib/valuation/valuation-total-corrections';
import { ValuationResultEditBaseValuePopoverComponent } from './valuation-result-edit-base-value-popover/valuation-result-edit-base-value-popover.component';
import { ValuationResultEditOriginalPricePopoverComponent } from './valuation-result-edit-original-price-popover/valuation-result-edit-original-price-popover.component';

@Component({
    selector: 'valuation-result',
    templateUrl: './valuation-result.component.html',
    styleUrls: ['./valuation-result.component.scss'],
})
export class ValuationResultComponent {
    @ViewChild('originalPricePopoverAnchor', { read: ElementRef }) originalPricePopoverAnchor: ElementRef;
    @ViewChild('vehicleBaseValuePopoverAnchor', { read: ElementRef }) vehicleBaseValuePopoverAnchor: ElementRef;

    constructor(
        private toastService: ToastService,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input() team: Team;
    @Input() report: Report;
    @Input() reportLocked: boolean;
    @Output() private baseValueChange = new EventEmitter();
    @Output() private valuationChange = new EventEmitter();
    @Output() private excludeDatOrAudatexValuation = new EventEmitter();

    protected Math = Math;

    protected highlightOriginalPrice = false;
    protected highlightBaseValue = false;
    protected highlightCorrections = false;
    protected highlightResult = false;

    private subscriptions: Subscription[] = [];

    get valuationDecimalPlaces(): number {
        return this.team.preferences.valuation_showDecimalPlaces ? 2 : 0;
    }

    public highlightOriginalPriceRow() {
        this.highlightOriginalPrice = true;
        setTimeout(() => (this.highlightOriginalPrice = false), 1000);
    }
    public highlightBaseValueRow() {
        this.highlightBaseValue = true;
        setTimeout(() => (this.highlightBaseValue = false), 1000);
    }
    public highlightCorrectionsRow() {
        this.highlightCorrections = true;
        setTimeout(() => (this.highlightCorrections = false), 1000);
    }
    public highlightResultRow() {
        this.highlightResult = true;
        setTimeout(() => (this.highlightResult = false), 1000);
    }

    //*****************************************************************************
    //  Getters for UI
    //****************************************************************************/
    /**
     * Net Values are only relevant, if the car owner may deduct taxes.
     */
    get doesNetValueApply(): boolean {
        return !!mayCarOwnerDeductTaxes(this.report);
    }

    get isPurchasePriceActive(): boolean {
        return this.report.valuation.vehicleValuePurchasePriceActive;
    }
    get isSalesPriceActive(): boolean {
        return this.report.valuation.vehicleValueSalesPriceActive;
    }
    get areBothPricesActive(): boolean {
        return (
            (this.isPurchasePriceActive && this.isSalesPriceActive) ||
            (!this.isPurchasePriceActive && !this.isSalesPriceActive)
        );
    }

    get totalCorrectionsGross() {
        return getTotalCorrectionsGross(this.report);
    }
    get totalCorrectionsNet() {
        return getTotalCorrectionsGross(this.report) / 1.19;
    }

    get areAnyResultsAvailable(): boolean {
        return !!(
            this.report.valuation.vehicleValueGross ||
            this.report.valuation.vehicleValueNet ||
            this.report.valuation.secondVehicleValueGross ||
            this.report.valuation.secondVehicleValueNet ||
            this.report.valuation.baseValueDealerPurchaseGross ||
            this.report.valuation.baseValueDealerPurchaseNet ||
            this.report.valuation.baseValueDealerSalesGross ||
            this.report.valuation.baseValueDealerSalesNet
        );
    }

    get baseValueDealerPurchaseSource(): string {
        switch (this.report.valuation.baseValueDealerPurchaseProvider) {
            case 'dat':
                return 'DAT';
            case 'audatex':
                return 'Audatex';
            case 'winvalue':
                return 'WinValue (Restwert)';
            case 'cartv':
                return 'CARTV (Restwert)';
            case 'autoonline':
                return 'AUTOonline (Restwert)';
            case 'carcasion':
                return 'car.casion (Restwert)';
            case 'axResidualValueRequest':
                return 'eigenes Restwertgebot';
            default:
                return null;
        }
    }

    get baseValueDealerSalesSource(): string {
        switch (this.report.valuation.baseValueDealerSalesProvider) {
            case 'dat':
                return this.report.valuation.datValuation.webscanGross ? 'DAT (Statistik)' : 'DAT';
            case 'datWebscan':
                return 'DAT (webScan)';
            case 'audatex':
                return 'Audatex';
            case 'winvalue':
                return 'WinValue (Marktwert)';
            case 'cartv':
                return 'CARTV (Marktwert)';
            case 'carcasion':
                return 'car.casion (Marktwert)';
            case 'custom':
                return 'Marktwertrecherche';
            default:
                return null;
        }
    }

    get isAnyValuationDone(): boolean {
        return !!this.baseValueDealerPurchaseSource || !!this.baseValueDealerSalesSource;
    }

    public translateValueProvider(
        provider: Valuation['baseValueDealerPurchaseProvider'] | Valuation['baseValueDealerSalesProvider'],
    ): string {
        return Translator.valuationProvider(provider);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Getters for UI
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Length of the SVG circle path. Retrieved using path.getTotalLength().
     * The circle has a dashed stroke, where each dash has the length of the total circle path.
     * By moving the dashoffset (e.g. starting point of first dash) we can animate the progress.
     * Offsetting by 0 means the full circle is filled, offset of whole path length means no fill at all.
     * This is based on SVG line animation (https://css-tricks.com/svg-line-animation-works/).
     */
    get valueRelativeToOriginalPrice() {
        if (this.report.valuation.vehicleValuePurchasePriceActive) {
            return (
                (this.report.valuation.vehicleValueGross ?? 0) /
                (this.report.valuation.originalPriceWithEquipmentGross ?? 0)
            );
        }
        return (
            (this.report.valuation.secondVehicleValueGross ?? 0) /
            (this.report.valuation.originalPriceWithEquipmentGross ?? 0)
        );
    }
    private ratioCirclePathLength = 439.1123352050781;
    protected getRatioCircleValue(): number {
        const progress = this.valueRelativeToOriginalPrice; // Number between 0 - 1 that fills the circle from 0 - 100 %.
        return this.ratioCirclePathLength - progress * this.ratioCirclePathLength;
    }

    public activateSalesPrice() {
        this.report.valuation.vehicleValueSalesPriceActive = true;
        this.valuationChange.emit();
    }

    public activatePurchasePrice() {
        this.report.valuation.vehicleValuePurchasePriceActive = true;
        this.valuationChange.emit();
    }

    public activateEditState() {
        if (this.reportLocked) {
            this.toastService.info('Gutachten abgeschlossen', 'Schließe es auf, damit du Änderungen vornehmen kannst.');
            return;
        }
    }

    protected openEditOriginalPricePopover(event: MouseEvent): void {
        // Prevent browser from jumping
        event.stopPropagation();

        const overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.originalPricePopoverAnchor)
                .withPositions([
                    // Below the anchor
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                ])
                .withDefaultOffsetY(15)
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        overlayRef.backdropClick().subscribe(() => overlayRef.detach());

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
            ],
        });

        const componentRef = overlayRef.attach(
            new ComponentPortal(ValuationResultEditOriginalPricePopoverComponent, null, overlayRefInjector),
        );

        componentRef.instance.report = this.report;

        this.subscriptions.push(
            componentRef.instance.valueChange.subscribe(() => {
                this.saveManualChange();
            }),
        );
    }

    protected openEditVehicleBaseValuePopover(event: MouseEvent): void {
        // Prevent browser from jumping
        event.stopPropagation();

        const overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.vehicleBaseValuePopoverAnchor)
                .withPositions([
                    // Below the anchor
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                ])
                .withDefaultOffsetY(15)
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        overlayRef.backdropClick().subscribe(() => overlayRef.detach());

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
            ],
        });

        const componentRef = overlayRef.attach(
            new ComponentPortal(ValuationResultEditBaseValuePopoverComponent, null, overlayRefInjector),
        );

        componentRef.instance.report = this.report;

        // Subscribe to changes in component.
        this.subscriptions.push(
            componentRef.instance.valueChange.subscribe(() => {
                this.baseValueChange.emit();
            }),
        );
    }

    public translateVehicleValueType(
        valueType: ValuationVehicleValueType,
    ): VehicleValueLabelGerman | VehicleValueLabelGermanShort {
        return Translator.vehicleValueLabel(valueType);
    }

    //*****************************************************************************
    //  Translate Net & Gross
    //****************************************************************************/
    public calculateGrossValue() {
        // Null check necessary because multiplying the tax factor further down causes a type coercion from null to zero.
        if (this.report.valuation.vehicleValueNet === null) {
            return null;
        }

        const taxFactor: number = 1 + getVatRateForTaxationType(this.report.valuation.taxationType);
        return this.report.valuation.vehicleValueNet * taxFactor;
    }

    public calculateNetValue() {
        // Null check necessary because multiplying the tax factor further down causes a type coercion from null to zero.
        if (this.report.valuation.vehicleValueGross === null) {
            return null;
        }

        const taxFactor: number = 1 + getVatRateForTaxationType(this.report.valuation.taxationType);
        return this.report.valuation.vehicleValueGross / taxFactor;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Translate Net & Gross
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * If the taxation type has changed, one of the values must be recalculated.
     * Keep the relevant value depending on the claimants vat status.
     */
    public updateValuesAfterTaxationChange() {
        if (this.doesNetValueApply) {
            this.report.valuation.vehicleValueGross = this.calculateGrossValue();
        } else {
            this.report.valuation.vehicleValueNet = this.calculateNetValue();
        }
    }

    /**
     * Calculate the gross value from the net value and check if it matches the provided gross value.
     * Round on the nearest 10 to prevent warnings after rounding.
     */
    public mismatchBetweenNetAndGrossValue() {
        if (this.report.valuation.taxationType === 'neutral') {
            return false;
        }
        // Round to the nearest 10
        const calculatedGross = Math.round(this.calculateGrossValue() / 10) * 10;
        const providedGross = Math.round(this.report.valuation.vehicleValueGross / 10) * 10;
        return calculatedGross !== providedGross;
    }

    /**
     * Check if the valuation result matches with the valuation providers result.
     * If the user changed the valuation results, he may want to exclude the valuation providers document from the report.
     */
    public mismatchBetweenValuationProviderAndReport() {
        // No valuation provider
        if (this.report.valuation.valuationProvider === 'custom') {
            return false;
        }

        /**
         * DAT: compare gross value
         * DAT does not provide a correct net value for neutral taxation, compare only gross.
         */
        if (this.report.valuation.valuationProvider === 'dat') {
            const datVehicleValueGross = getDatVehicleValueByPriceType({
                report: this.report,
                vehicleValueType: this.report.valuation.datValuation.vehicleValueType,
                netOrGross: 'gross',
            });
            return datVehicleValueGross !== this.report.valuation.vehicleValueGross;
        }

        /**
         * Audatex:
         * It is not clear which value is selected on the audatex valuation.
         * Therefore check if at least one of the Audatex values matches the gross value.
         */
        if (this.report.valuation.valuationProvider === 'audatex') {
            const audatexValuation = this.report.valuation.audatexValuation;
            const audatexGrossValues = [
                audatexValuation.dealerPurchasePriceGross,
                audatexValuation.dealerSalesPriceGross,
                audatexValuation.marketValueGross,
                audatexValuation.replacementValueGross,
                audatexValuation.presentValueGross,
            ];

            return !audatexGrossValues.find((value) => value === this.report.valuation.vehicleValueGross);
        }
    }

    /**
     * Display a helpful message, if the valuation result does not match with the valuation provider's result.
     */
    public mismatchBetweenValuationProviderAndReportMessage() {
        const valuationProviderName = Translator.valuationProvider(this.report.valuation.valuationProvider);
        let message = `Das Ergebnis entspricht nicht der ${valuationProviderName}-Bewertung, die im Gutachten abgedruckt wird.`;
        if (!this.reportLocked) {
            message += `
                
                Du kannst die ${valuationProviderName}-Bewertung aus dem Gutachten ausblenden, indem du die Option "${valuationProviderName}-Bewertung nicht abdrucken" aktivierst oder auf dieses Icon klickst.
                
                Du kannst das Ergebnis der ${valuationProviderName}-Bewertung ins Gutachten übernehmen, indem du auf die Werte in der Spalte "Bewertung" klickst.`;
        }
        return message;
    }

    public excludeValuationFromReport() {
        this.excludeDatOrAudatexValuation.emit();
    }

    /**
     * If no valuation provider was set, set it to custom.
     * If the valuation provider was set, keep it so users may round the values.
     */
    public saveManualChange() {
        if (!this.report.valuation.valuationProvider) {
            this.report.valuation.valuationProvider = 'custom';
        }
        this.valuationChange.emit();
    }

    public scrollIntoView(querySelector: string): void {
        document.querySelector(querySelector).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}

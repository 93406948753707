<!--============================================-->
<!-- Task Details -->
<!--============================================-->
<div class="card task-details-container">
    <!-- Header -->
    <div class="task-details-header">
        <!-- Headline -->
        <mat-icon class="task-details-headline-icon">edit</mat-icon>
        <div class="task-details-headline-text">Aufgabe bearbeiten</div>

        <!-- Settings menu -->
        <mat-icon class="task-details-settings-icon toolbar-icon" [matMenuTriggerFor]="settingsMenu">
            more_vert
        </mat-icon>

        <mat-menu #settingsMenu>
            <!--********** Delete **********-->
            <a mat-menu-item class="menu-item-with-icon alert" (click)="deleteTask()">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>

            <!--********** Created at & created by info **********-->
            <div
                class="task-details-created-info"
                [matTooltip]="
                    'Erstellt von ' + getCreatedByUserName() + ' am ' + (task.createdAt | moment: 'DD.MM.YYYY')
                ">
                <div>Erstellt von {{ getCreatedByUserName() }}</div>
                <div>am {{ task.createdAt | moment: 'DD.MM.YYYY' }}</div>
            </div>
        </mat-menu>

        <!-- Close button -->
        <mat-icon class="task-details-close-icon toolbar-icon" (click)="closeClick.emit()">close</mat-icon>
    </div>

    <!-- Body -->
    <div class="task-details-body">
        <!-- Completed state -->
        <div class="task-details-body-left-column">
            <div class="task-completed">
                <task-done-checkbox
                    [task]="task"
                    (taskCompleted)="taskChange.emit(task)"
                    (taskReopened)="taskChange.emit(task)"></task-done-checkbox>
            </div>
        </div>

        <div class="task-details-body-right-column">
            <!-- Title -->
            <task-title
                class="task-details-title"
                [task]="task"
                (taskChange)="emitTaskChange()"
                (taskDeleteRequest)="deleteTask()"></task-title>

            <!-- Description -->
            <div class="task-details-description" [ngClass]="{ empty: !task.description }">
                <mat-form-field class="mat-block">
                    <mat-quill [(ngModel)]="task.description" (change)="emitTaskChange()"></mat-quill>
                </mat-form-field>
            </div>

            <!-- Bottom row -->
            <div class="task-details-bottom-row">
                <!-- Report or Invoice -->
                <task-associated-invoice-or-report
                    [task]="task"
                    (taskChange)="emitTaskChange()"></task-associated-invoice-or-report>

                <!-- Labels -->
                <div
                    class="task-details-labels"
                    labelDropdown
                    [(selectedLabels)]="task.labels"
                    [labelGroup]="'task'"
                    (selectedLabelsChange)="emitTaskChange()"
                    #labelDropdownAnchor="labelDropdownAnchor">
                    <label-display *ngIf="task.labels.length > 0" [labels]="task.labels"></label-display>
                    <span *ngIf="task.labels.length < 1" class="no-labels-button">+ Label</span>
                </div>

                <!-- Estimated duration -->
                <div class="task-estimated-duration">
                    <task-estimated-duration
                        [ngClass]="{ 'is-not-set': !task.estimatedDuration }"
                        [(estimatedDuration)]="task.estimatedDuration"
                        (estimatedDurationChange)="emitTaskChange()"></task-estimated-duration>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="task-details-footer">
        <!-- Due date -->
        <div
            class="task-due-date cursor-pointer"
            datePickerWithInputAnchor
            [(date)]="task.dueDate"
            [(time)]="task.dueTime"
            [showTimeInput]="true"
            (dateChange)="emitTaskChange()"
            (timeChange)="emitTaskChange()">
            <mat-icon>event</mat-icon>
            <span
                *ngIf="task.dueDate"
                class="due-date-is-{{ task | compareTaskWithToday }}"
                matTooltip="Fällig: {{ task.dueDate | moment: 'DD.MM.YYYY' }}">
                {{ task.dueDate | relativeDate }}
                {{ task.dueTime ? ' ' + task.dueTime + ' Uhr' : '' }}
            </span>
            <div *ngIf="!task.dueDate" class="missing-data-info label italic">ohne Datum</div>
        </div>

        <!-- Assignee -->
        <div
            class="task-assignee"
            userSelectorInputAnchor
            (userSelected)="assignUser($event)"
            matTooltip="zuständige Person">
            <user-avatar *ngIf="task.assigneeId" [size]="20" [userId]="task.assigneeId"></user-avatar>
            <mat-icon *ngIf="!task.assigneeId" class="no-assignee-fallback">person</mat-icon>
            <div *ngIf="task.assigneeId">{{ getUserFullName() }}</div>
            <div *ngIf="!task.assigneeId" class="italic label">kein Nutzer</div>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Task Details -->
<!--============================================-->

<!--============================================-->
<!-- Sub-Tasks -->
<!--============================================-->
<task-details-subtasks [task]="task" (taskChanged)="emitTaskChange()"></task-details-subtasks>
<!--============================================-->
<!-- END Sub-Tasks -->
<!--============================================-->

<!--============================================-->
<!-- Task Comments -->
<!--============================================-->
<task-details-comments [task]="task" (taskChanged)="emitTaskChange()"></task-details-comments>
<!--============================================-->
<!-- END Task Comments -->
<!--============================================-->

import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user/user';

/**
 * Pipe used to display HTML code as plain text, e.g. in tooltips.
 */
@Pipe({
    name: 'userInitials',
})
export class UserInitialsPipe implements PipeTransform {
    transform(user: User): string {
        if (!user) {
            return '';
        }

        return user.initials || `${user.firstName?.charAt(0) || ''}${user.lastName?.charAt(0) || ''}`.toUpperCase();
    }
}

import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import { isAutoonlineUserComplete } from '@autoixpert/lib/users/is-autoonline-user-complete';
import { isCarcasionUserComplete } from '@autoixpert/lib/users/is-carcasion-user-complete';
import { isCarTvUserComplete } from '@autoixpert/lib/users/is-cartv-user-complete';
import { areWinvalueResidualValueCredentialsComplete } from '@autoixpert/lib/users/is-winvalue-user-complete';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import {
    ResidualValueExchangeId,
    SupportedResidualValueExchange,
    supportedResidualValueExchanges,
} from '@autoixpert/models/reports/residual-value/residual-value-offer';
import { User } from '@autoixpert/models/user/user';
import { slideOutDialogVertical } from '../../animations/slide-out-dialog-vertical.animation';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ToastService } from '../../services/toast.service';
import { UserPreferencesService } from '../../services/user-preferences.service';

@Component({
    selector: 'contact-person-editor',
    templateUrl: 'contact-person-editor.component.html',
    styleUrls: ['contact-person-editor.component.scss'],
    animations: [slideOutDialogVertical()],
})
export class ContactPersonEditorComponent implements OnInit {
    constructor(
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        public userPreferences: UserPreferencesService,
    ) {}

    private user: User;

    // Set specific contact people to be shown in the autocomplete
    @Input() contactPeopleForQuickSelection: ContactPerson[];
    // Query the server for all contact people from this group
    @Input() autocompleteGroupNames: ContactPerson['organizationType'][] = ['claimant'];
    @Input() showPhone: boolean = true;
    @Input() showEmail: boolean = true;
    @Input() showWebsite: boolean = true;
    @Input() showDebtorNumber: boolean = true;
    @Input() disabled: boolean = false;
    @Input() showOrganizationTypeSelection: boolean = false;
    @Input() hideIban: boolean = false;
    @Input() vatFieldsAllowed: boolean = false;

    // Work on a copy so that the user gets a chance to abort
    @Input('contactPerson') set contactPersonFromParent(value: ContactPerson) {
        if (!value) {
            console.error('contactPerson must be set. Current value: ', value);
        }
        this.contactPerson = JSON.parse(JSON.stringify(value));
    }

    // Include the contact person if it should be saved, otherwise emit null
    @Output() close: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();
    @Output() contactPersonChange: EventEmitter<ContactPerson> = new EventEmitter<ContactPerson>();

    public filteredContactPeopleForQuickSelection: ContactPerson[] = [];

    public contactPerson: ContactPerson = new ContactPerson();

    // All available residual value exchanges of a user
    public availableResidualValueExchanges: SupportedResidualValueExchange<ResidualValueExchangeId>[] = [];
    public isResidualValueExchangeQuotaVisible: boolean = false;
    protected shouldShowCollectiveInvoiceCheckbox: boolean = false;

    // The possible organization types to be selected
    selectableOrganizationTypes: ContactPerson['organizationType'][] = [
        'claimant',
        'garage',
        'lawyer',
        'insurance',
        'bidder',
        'residualValueRequestRecipient',
        'visitLocationFavorite',
        'intermediary',
        'leaseProvider',
    ];

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.filterContactPeopleForQuickSelection();

        if (
            this.contactPerson.organizationType === 'insurance' &&
            this.contactPerson.availableQuotaOnResidualValueExchanges?.length
        ) {
            this.showResidualValueExchangeQuota();
        }
        this.shouldShowCollectiveInvoiceCheckbox = !!this.contactPerson.receivesCollectiveInvoice;
    }

    //*****************************************************************************
    //  Quick Selection
    //****************************************************************************/
    /**
     * Filter out all empty contact people.
     */
    public filterContactPeopleForQuickSelection(): void {
        if (!this.contactPeopleForQuickSelection) {
            this.contactPeopleForQuickSelection = [];
        }
        this.filteredContactPeopleForQuickSelection = this.contactPeopleForQuickSelection.filter(
            (contactPerson) => contactPerson.organization || contactPerson.firstName || contactPerson.lastName,
        );
    }

    public matchesContactPersonInForm(contactPerson: ContactPerson): boolean {
        return (
            this.contactPerson.organization === contactPerson.organization &&
            this.contactPerson.firstName === contactPerson.firstName &&
            this.contactPerson.lastName === contactPerson.lastName
        );
    }

    //*****************************************************************************
    //  Insurance specific agreements with residual value exchanges
    //****************************************************************************/
    public showResidualValueExchangeQuota(): void {
        if (this.isResidualValueExchangeQuotaVisible) {
            return;
        }

        this.getAvailableResidualValueExchanges();
        this.isResidualValueExchangeQuotaVisible = true;
    }

    private getAvailableResidualValueExchanges() {
        this.availableResidualValueExchanges = [];
        if (isAutoonlineUserComplete(this.user)) {
            this.availableResidualValueExchanges.push(supportedResidualValueExchanges.autoonline);
        }
        if (isCarcasionUserComplete(this.user)) {
            this.availableResidualValueExchanges.push(supportedResidualValueExchanges.carcasion);
        }
        if (isCarTvUserComplete(this.user)) {
            this.availableResidualValueExchanges.push(supportedResidualValueExchanges.cartv);
        }
        if (areWinvalueResidualValueCredentialsComplete(this.user)) {
            this.availableResidualValueExchanges.push(supportedResidualValueExchanges.winvalue);
        }
    }

    doesResidualValueExchangeHaveAgreement(residualValueExchangeId: ResidualValueExchangeId): boolean {
        return this.contactPerson.availableQuotaOnResidualValueExchanges?.includes(residualValueExchangeId);
    }

    toggleResidualValueExchangeAgreement(residualValueExchangeId: ResidualValueExchangeId): void {
        if (!this.contactPerson.availableQuotaOnResidualValueExchanges) {
            this.contactPerson.availableQuotaOnResidualValueExchanges = [];
        }
        toggleValueInArray(residualValueExchangeId, this.contactPerson.availableQuotaOnResidualValueExchanges);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Insurance specific agreements with residual value exchanges
    /////////////////////////////////////////////////////////////////////////////*/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Quick Selection
    /////////////////////////////////////////////////////////////////////////////*/
    public insertContactData(contactPerson: ContactPerson): void {
        const contactPersonCopy: ContactPerson = JSON.parse(JSON.stringify(contactPerson));
        Object.assign(this.contactPerson, contactPersonCopy);
    }

    public selectOrganizationType(organizationType: ContactPerson['organizationType']): void {
        this.contactPerson.organizationType = organizationType;
        if (organizationType === 'garage' && !this.contactPerson.garageFeeSets) {
            this.contactPerson.garageFeeSets = [new GarageFeeSet()];
        }
    }

    public useContactPerson(): void {
        if (!this.contactPerson.organization && !this.contactPerson.firstName && !this.contactPerson.lastName) {
            this.toastService.info(
                'Bitte Namen angeben',
                'Kontakte können nur gespeichert werden, wenn entweder Firmen-, Vor- oder Nachname angegeben wurde.',
            );
            return;
        }

        this.emitContactPersonChangeEvent();
        this.closeSelector(true);
    }

    public emitContactPersonChangeEvent() {
        if (this.contactPerson.receivesCollectiveInvoice) {
            // As soon as the user activated the collective invoice, we keep the checkbox visible
            // even though it might be unchecked again. If the user unchecks it, it won't show up next time visiting the dialog.
            this.shouldShowCollectiveInvoiceCheckbox = true;
        }
        this.contactPersonChange.emit(this.contactPerson);
    }

    public closeSelector(useContactPerson = false): void {
        this.close.emit(useContactPerson ? this.contactPerson : null);
    }

    @HostListener('window:keydown', ['$event'])
    public handleCtrlEnter(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.useContactPerson();
                }
                break;
            case 'Escape':
                this.closeSelector();
                break;
        }
    }

    //*****************************************************************************
    //  Animations
    //****************************************************************************/
    @HostBinding('@slideOutDialogVertical') get slideOut() {
        return true;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Animations
    /////////////////////////////////////////////////////////////////////////////*/
}

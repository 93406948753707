/**
 * Clips a string at maximumLength and adds an ellipsis if the string is longer.
 */
export function clipString(originalString: string, maximumLength: number): string {
    if (!originalString) return '';

    if (originalString.length < maximumLength) return originalString;

    return `${originalString.slice(0, maximumLength)}...`;
}

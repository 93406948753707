import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { round } from '@autoixpert/lib/numbers/round';
import { CustomFeeSet, CustomFeeSetRow } from '@autoixpert/models/reports/assessors-fee/custom-fee-set';
import { OfficialFeeTableName } from '@autoixpert/models/reports/assessors-fee/fee-calculation';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { bvskFeeTable2024 } from '@autoixpert/static-data/fee-tables/bvsk-fee-table-2024';
import { cgfFeeTable2023 } from '@autoixpert/static-data/fee-tables/cgf-fee-table-2023';
import { hukFeeTable2025 } from '@autoixpert/static-data/fee-tables/huk-fee-table-2025';
import { vksFeeTable2021 } from '@autoixpert/static-data/fee-tables/vks-fee-table-2021';
import { slideInAndOutVertically } from '../../../../shared/animations/slide-in-and-out-vertical.animation';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { TeamService } from '../../../../shared/services/team.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
    selector: 'custom-fee-set-dialog',
    templateUrl: 'custom-fee-set-dialog.component.html',
    styleUrls: ['custom-fee-set-dialog.component.scss'],
    animations: [slideInAndOutVertically(200, 200)],
})
export class CustomFeeSetDialogComponent implements OnInit {
    @Input() customFeeSet: CustomFeeSet;
    @Input() report: Report;
    @Input() team: Team;

    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() customFeeSetChange: EventEmitter<CustomFeeSet> = new EventEmitter<CustomFeeSet>();

    public templateTables: TemplateFeeTable[] = [
        new TemplateFeeTable({
            name: 'BVSK',
            feeTable: bvskFeeTable2024.table,
        }),
        new TemplateFeeTable({
            name: 'VKS',
            feeTable: vksFeeTable2021.table,
        }),
        new TemplateFeeTable({
            name: 'HUK',
            feeTable: hukFeeTable2025.table,
        }),
        new TemplateFeeTable({
            name: 'CGF',
            feeTable: cgfFeeTable2023.table,
        }),
    ];

    // Fee Table Generator
    public selectedTemplateTable: TemplateFeeTable;
    public bvskColumns: BvskColumn[] = [
        {
            label: 'HB I',
            index: 1,
        },
        {
            label: 'HB II',
            index: 2,
        },
        {
            label: 'HB III',
            index: 3,
        },
        {
            label: 'HB IV',
            index: 4,
        },
        {
            label: 'Mittelwert HB V',
            index: 7,
        },
    ];
    public selectedBvskColumn: BvskColumn;
    public vksColumns: VksColumn[] = [
        {
            label: 'Grundhonorar von (Minimum)',
            index: 1,
        },
        {
            label: 'Grundhonorar bis (Maximum)',
            index: 2,
        },
    ];
    public selectedVksColumn: VksColumn;
    public cgfColumns: CgfColumn[] = [
        {
            label: 'Grundhonorar von (Minimum)',
            index: 1,
        },
        {
            label: 'Grundhonorar bis (Maximum)',
            index: 2,
        },
    ];
    public selectedCgfColumn: CgfColumn;
    public additionPercent: number;
    public additionAbsolute: number;
    public roundValues: boolean = true;

    public feeTableGenerationPending: boolean = false; // The rendering process takes a little while

    constructor(
        private teamService: TeamService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
    ) {}

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.selectDefaultForFeeTableGenerator();
    }

    public selectDefaultForFeeTableGenerator(): void {
        this.selectedTemplateTable = this.templateTables[0];
        this.selectedBvskColumn = this.bvskColumns[3];
        this.selectedVksColumn = this.vksColumns[1];
        this.selectedCgfColumn = this.cgfColumns[1];
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handling Rows
    //****************************************************************************/
    /**
     * Add row either between two rows or at the end of the table.
     *
     * The values are computed based on the differences of the preceding and following
     * row (for in-between adds) or of the two highest rows (at-the-end adds.
     */
    public addRow(newPosition: number): void {
        const insertAtEnd: boolean = newPosition === this.customFeeSet.fees.length;

        // To add a row at the end, reduce index by one to consider the highest row and its predecessor
        const index: number = insertAtEnd ? this.customFeeSet.fees.length - 1 : newPosition;
        const lowerRow: CustomFeeSetRow = this.customFeeSet.fees[index - 1];
        const higherRow: CustomFeeSetRow = this.customFeeSet.fees[index];

        let newRow: CustomFeeSetRow;

        if (insertAtEnd) {
            newRow = new CustomFeeSetRow({
                // Add full diff
                lowerLimit: higherRow.lowerLimit + (higherRow.lowerLimit - lowerRow.lowerLimit),
                fee: higherRow.fee + (higherRow.fee - lowerRow.fee),
            });
        } else {
            newRow = new CustomFeeSetRow({
                // Add half the difference
                lowerLimit: lowerRow.lowerLimit + (higherRow.lowerLimit - lowerRow.lowerLimit) / 2,
                fee: lowerRow.fee + (higherRow.fee - lowerRow.fee) / 2,
            });
        }

        this.customFeeSet.fees.splice(newPosition, 0, newRow);
    }

    public removeRow(row: CustomFeeSetRow): void {
        const index = this.customFeeSet.fees.indexOf(row);
        this.customFeeSet.fees.splice(index, 1);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handling Rows
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fee Table Generator
    //****************************************************************************/
    public selectTemplateTable(feeTable: TemplateFeeTable): void {
        this.selectedTemplateTable = feeTable;
    }

    public calculateAdditions(base: number): number {
        const preciseValue: number = base * (1 + (this.additionPercent || 0) / 100) + (this.additionAbsolute || 0);

        if (this.roundValues) {
            return round(preciseValue, 0);
        } else {
            return preciseValue;
        }
    }

    public generateFeeTable(): void {
        if (!this.isGeneratingFeeTableAllowed()) return;

        // Give the user feedback. Rendering the fee rows takes a while, making the view appear responseless
        this.feeTableGenerationPending = true;
        window.setTimeout(() => (this.feeTableGenerationPending = false), 500);

        let resultingFeeTable: CustomFeeSetRow[];

        switch (this.selectedTemplateTable.name) {
            case 'BVSK':
                resultingFeeTable = this.selectedTemplateTable.feeTable.map((row) => {
                    return {
                        lowerLimit: row[0],
                        fee: this.calculateAdditions(row[this.selectedBvskColumn.index]),
                    };
                });
                break;
            case 'HUK':
                resultingFeeTable = this.selectedTemplateTable.feeTable.map((row) => {
                    return {
                        lowerLimit: row[0],
                        fee: this.calculateAdditions(row[2]),
                    };
                });
                break;
            case 'VKS':
                resultingFeeTable = this.selectedTemplateTable.feeTable.map((row) => {
                    return {
                        lowerLimit: row[0],
                        fee: this.calculateAdditions(row[this.selectedVksColumn.index]),
                    };
                });
                break;
            case 'CGF':
                resultingFeeTable = this.selectedTemplateTable.feeTable.map((row) => {
                    return {
                        lowerLimit: row[0],
                        fee: this.calculateAdditions(row[this.selectedCgfColumn.index]),
                    };
                });
                break;
        }

        this.customFeeSet.fees = resultingFeeTable;
        this.emitFeeSetChange();
    }

    /*
     * Check whether generating a fee table is possible:
     * - A template table must be selected
     * - For BVSK, VKS and CGF, a column must be selected
     */
    public isGeneratingFeeTableAllowed(): boolean {
        if (!this.selectedTemplateTable) return false;

        switch (this.selectedTemplateTable.name) {
            case 'BVSK':
                return !!this.selectedBvskColumn;
            case 'VKS':
                return !!this.selectedVksColumn;
            case 'CGF':
                return !!this.selectedCgfColumn;
            default:
                return true;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fee Table Generator
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * User changed the availability of the current fee set in different report types. We need to check
     * if this fee set was set as standard and if so, remove/replace it.
     */
    protected availableInReportTypesChanged(): void {
        // Set a new default fee set for this report type if the deleted fee set was the default
        let removedFromReportType = null;
        if (
            !this.customFeeSet.availableInReportTypes.includes('liability') &&
            this.team.preferences.defaultCustomFeeTableIdLiability === this.customFeeSet._id
        ) {
            this.team.preferences.defaultFeeTableLiability = 'BVSK';
            this.team.preferences.defaultCustomFeeTableIdLiability = null;
            removedFromReportType = 'Haftpflicht';
        }
        if (
            !this.customFeeSet.availableInReportTypes.includes('partialKasko') &&
            this.team.preferences.defaultCustomFeeTableIdPartialKasko === this.customFeeSet._id
        ) {
            this.team.preferences.defaultFeeTablePartialKasko = 'BVSK';
            this.team.preferences.defaultCustomFeeTableIdPartialKasko = null;
            removedFromReportType = 'Teilkasko';
        }
        if (
            !this.customFeeSet.availableInReportTypes.includes('fullKasko') &&
            this.team.preferences.defaultCustomFeeTableIdFullKasko === this.customFeeSet._id
        ) {
            this.team.preferences.defaultFeeTableFullKasko = 'BVSK';
            this.team.preferences.defaultCustomFeeTableIdFullKasko = null;
            removedFromReportType = 'Vollkasko';
        }

        if (removedFromReportType) {
            this.saveTeam();
            this.toastService.info(
                'Tabelle war Standard',
                `Die Honorartabelle war der Standard für ${removedFromReportType}. Bitte wähle eine andere Tabelle als Standard, ansonsten wird die BVSK-Tabelle für neue ${removedFromReportType}-Gutachten verwendet.`,
                { timeOut: 10000 },
            );
        }
    }

    public handleOverlayClick(event: MouseEvent) {
        if (event.target === event.currentTarget) {
            this.emitCloseEvent();
        }
    }

    private saveTeam(): void {
        this.teamService.put(this.team).catch((error) => {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Einstellungen nicht gespeichert',
                    body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        });
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitCloseEvent(): void {
        this.close.emit();
    }

    public emitFeeSetChange(): void {
        this.customFeeSetChange.emit(this.customFeeSet);
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.emitCloseEvent();
                break;
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    this.emitCloseEvent();
                }
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

export class TemplateFeeTable {
    constructor(template?: Partial<TemplateFeeTable>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    name: OfficialFeeTableName;
    feeTable: number[][] = [];
}

export type BvskColumn = { label: 'HB I' | 'HB II' | 'HB III' | 'HB IV' | 'Mittelwert HB V'; index: 1 | 2 | 3 | 4 | 7 };
export type VksColumn = { label: 'Grundhonorar von (Minimum)' | 'Grundhonorar bis (Maximum)'; index: 1 | 2 };
export type CgfColumn = { label: 'Grundhonorar von (Minimum)' | 'Grundhonorar bis (Maximum)'; index: 1 | 2 };

import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { NotFound } from '@autoixpert/models/errors/ax-error';

export function getDocumentOrderForRecipient(
    documentOrders: DocumentOrder[],
    recipientRole: DocumentOrder['recipientRole'],
): DocumentOrder {
    const recipientSpecificDocumentOrder: DocumentOrder = documentOrders.find(
        (documentOrder) => documentOrder.recipientRole === recipientRole,
    );
    if (recipientSpecificDocumentOrder) {
        return recipientSpecificDocumentOrder;
    } else {
        throw new NotFound({
            code: 'DOCUMENT_ORDER_NOT_FOUND_FOR_GIVEN_RECIPIENT',
            message: `The document order which defines in which order the report or invoice documents are displayed could not be found. This might be a structural issue.`,
            data: {
                recipientRole,
                documentOrders,
            },
        });
    }
}

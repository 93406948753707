import { OverlayRef } from '@angular/cdk/overlay';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormFieldDataType } from '@autoixpert/helper-types/form-field-data-type';
import { ToastService } from '../../services/toast.service';

/**
 * Overlay with an arbitrary input field type.
 *
 * Will be opened if an element with directive [formFieldOverlayAnchor] is clicked.
 *
 * Currently supported:
 * - text input
 * - number input
 * - currency input
 */
@Component({
    selector: 'form-field-overlay',
    templateUrl: './form-field-overlay.component.html',
    styleUrls: ['./form-field-overlay.component.scss'],
})
export class FormFieldOverlayComponent<FormFieldType> {
    constructor(
        private overlayRef: OverlayRef,
        private toastService: ToastService,
    ) {}

    @Input() value: FormFieldDataType<FormFieldType>;
    @Input() formFieldType: FormFieldType;
    @Input() placeholder: string = 'Platzhalter';
    @Input() suffix: string;

    // Numbers
    // Default set by the anchor.
    @Input() minimumFractionDigits: number;
    @Input() maximumFractionDigits: number;

    @Output() valueChange: EventEmitter<FormFieldDataType<FormFieldType>> = new EventEmitter();

    protected async closeDialog() {
        this.overlayRef.detach();
    }

    protected onChange(value: FormFieldDataType<FormFieldType>) {
        this.valueChange.emit(value);
    }

    /**
     * This method is a workaround because Angular's compiler does currently (Angular v16.2) not
     * support good enough type inference in the HTML template.
     */
    protected handleNumberChange(value: number) {
        if (this.formFieldType === 'number') {
            // Type coercion is necessary to satisfy Angular.
            this.value = value as FormFieldDataType<FormFieldType>;
        }
    }
    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    protected handleKeyboardShortcuts(event) {
        switch (event.key) {
            case 'Enter': // Make sure saving is triggered if the user is still inside an input.
                if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
                    (document.activeElement as HTMLElement).blur();
                }
                void this.closeDialog();
                break;
            case 'Escape':
                void this.closeDialog();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

<div class="dialog-overlay" (click)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container dialog-grid">
        <h2>Dokumentgruppen</h2>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <div id="document-type-groups-container" class="dialog-scrollable-area">
            <div
                class="document-type-group"
                [ngClass]="getDocumentTypeGroupNameAsCssClassObject(documentTypeGroup)"
                *ngFor="let documentTypeGroup of documentTypeGroups">
                <h3 class="document-type-group-name">
                    {{ documentTypeGroup.name | translateDocumentTypeGroupName }}
                    <mat-icon
                        *ngIf="!areAllDocumentTypesOfGroupSelected(documentTypeGroup)"
                        class="selection-icon toolbar-icon medium-icon"
                        (click)="selectAllDocumentOrderConfigsInGroup(documentTypeGroup)"
                        matTooltip="Alle Dokumente in dieser Gruppe auswählen">
                        select_all
                    </mat-icon>
                    <mat-icon
                        *ngIf="areAllDocumentTypesOfGroupSelected(documentTypeGroup)"
                        class="selection-icon toolbar-icon medium-icon"
                        (click)="deselectAllDocumentTypesInGroup(documentTypeGroup)"
                        matTooltip="Alle Dokumente in dieser Gruppe deaktivieren">
                        deselect
                    </mat-icon>
                </h3>

                <div class="document-types-list">
                    <div
                        class="document-type"
                        *ngFor="let documentOrderConfig of documentTypeGroup.documentOrderConfigs"
                        (click)="toggleDocumentOrderConfig(documentOrderConfig)"
                        [matTooltip]="
                            disallowPowerOfAttorney && documentOrderConfig.type === 'powerOfAttorney'
                                ? 'Für eine anwaltliche Vollmacht kann kein Layout definiert werden, weil das Layout typischerweise das des Anwalts ist, nicht deins. Wenn du das Layout des Anwalts verwenden möchtest, verwende die Funktion \'Unterschreibbare PDF-Dokumente\'.'
                                : null
                        "
                        [ngClass]="{
                            active: isDocumentOrderConfigActive(documentOrderConfig),
                            disabled: disallowPowerOfAttorney && documentOrderConfig.type === 'powerOfAttorney',
                        }">
                        <mat-icon class="document-type-icon small-icon">local_offer</mat-icon>
                        <div class="document-type-title">
                            {{ documentOrderConfig | translateDocumentOrderConfigName }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dialog-buttons-container">
            <button (click)="saveDialog()">Speichern</button>
        </div>
    </div>
</div>

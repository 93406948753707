import { Router } from '@angular/router';
import { humanFileSize } from '@autoixpert/lib/strings/human-file-size';
import { translateDocumentOrderConfigName } from '@autoixpert/lib/translators/translate-document-order-config-name';
import { translateDocumentType } from '@autoixpert/lib/translators/translate-document-type';
import {
    AccessRightGerman,
    translateAccessRightToGerman,
} from '@autoixpert/lib/users/translate-access-right-to-german';
import { CustomField } from '@autoixpert/models/custom-fields/custom-field';
import { CustomFieldGroup } from '@autoixpert/models/custom-fields/custom-field-group';
import { getCustomFieldLocationGerman } from '@autoixpert/models/custom-fields/custom-field-location';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';
import { getElectronicInvoiceApiErrorHandlers } from './get-electronic-invoice-api-error-handlers';

export function getDocumentsApiErrorHandlers(router: Router): {
    [key: string]: ApiErrorHandler | ApiErrorHandlerCreator;
} {
    return {
        ...getElectronicInvoiceApiErrorHandlers(),
        DOCX_TEMPLATE_NOT_FOUND: () => ({
            title: 'Druckvorlage fehlt',
            body: `Versuche, deine Kopf- & Fußzeile noch einmal in den <a href='/Einstellungen'>Einstellungen</a> hochzuladen oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        DOCX_DEFAULT_FONT_NOT_FOUND: (error) => ({
            title: 'Standardschrift fehlt',
            body: `Die Standardschriftarten "${error.data.fontNames}" wurden nicht korrekt in der Datei document-foundation.docx eingebettet.\n\nDies kann passieren, wenn die Schriften beim Abspeichern der Datei nicht auf dem System installiert waren.\n\n Bitte kontaktiere die <a href="/Hilfe">Hotline</a>.`,
            forceConsiderErrorHandled: false, // This error should be logged to Sentry, so that we can fix it
        }),
        STANDARD_HEADER_AND_FOOTER_TEMPLATE_MISSING: () => ({
            title: 'Standard Kopf- & Fußzeile fehlt',
            body: `Lade deine Kopf- & Fußzeile noch einmal in den <a href='/Einstellungen'>Einstellungen</a> hoch.`,
        }),
        CUSTOM_HEADER_AND_FOOTER_TEMPLATE_NOT_FOUND: (error) => ({
            title: 'Kopf- & Fußzeile fehlt',
            body: `Lade deine Kopf- & Fußzeile für das Dokument "${translateDocumentOrderConfigName({
                documentOrderConfig: error.data?.documentOrderConfig,
            })}" noch einmal in den <a href='/Einstellungen'>Einstellungen</a> hoch oder entferne sie, damit die Standard Kopf- & Fußzeile verwendet wird.`,
        }),
        PAGE_MARGIN_MISSING: {
            title: 'Kopf- & Fußzeile leer',
            body: `Falls du Text oder eine Grafik eingefügt hast, ist sie wirklich im Kopf-/Fußzeilenbereich der Worddatei gelandet? <a href='/Einstellungen#document-layout-container' target="_blank" rel="noopener">Einstellungen öffnen</a>`,
        },
        DOCUMENT_BUILDING_BLOCK_PLACEHOLDER_VALUE_TYPE_NOT_DEFINED: (error) => ({
            title: 'Fehlerhafter Textbaustein',
            body: `Eine Bedingung der Variante ${error.data.variantIndex + 1} des Textbausteins "${
                error.data.documentBuildingBlock.title
            }" ist ungültig.<br><a href="/Einstellungen/Textbausteine?search=${
                error.data.documentBuildingBlock.title
            }" target="_blank">Textbausteine bearbeiten</a>`,
        }),
        MICROSERVICE_NOT_RUNNING: (error) => ({
            title: `${error.data.apiName} nicht erreichbar`,
            body: `Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        DOCX_IMAGE_NOT_DEFINED: (error) => ({
            title: `Bild für ${error.data.tagName} fehlt`,
            body: `Der Wert, mit dem nach einem Bild gesucht wird, heißt "${error.data.tagValue}". Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        RENDERING_NUMBER_TOKENS_FAILED: {
            title: 'Freitext-Plättchen nicht gerendert',
            body: `Das ist vermutlich etwas Technisches. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        DOCUMENT_TEMPLATE_CONFIG_NOT_FOUND: {
            title: 'Zuordnung von Textbausteinen fehlt',
            body: `Das Objekt, das die Textbausteine mit diesem Dokument-Typ verknüpft, existiert nicht.<br><br>Das ist vermutlich etwas Technisches. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        MORE_THAN_ONE_DOCUMENT_ORDER_CONFIG_FOUND: {
            title: 'Mehrere Briefpapiere vorhanden',
            body: `Für das angeforderte Dokument wurden mehr als ein mögliches Briefpapier hinterlegt. Bitte stelle in den <a href="/Einstellungen#document-layout-container">Einstellungen</a> sicher, dass eindeutig ist, welches Briefpapier verwendet werden soll.`,
        },
        /**
         * This handles placeholder path errors in the DOCX template: partials, header, footer.
         */
        INVALID_PLACEHOLDERS_IN_TEMPLATE: (error) => {
            return {
                title: 'Ungültige Platzhalter in Kopf- & Fußzeile',
                body: `Bitte prüfe den Platzhalter {${error.data.tag}}.<br><br>Zwischen den geschweiften Klammern dürfen nur <a href="https://wissen.autoixpert.de/hc/de/articles/360016612740" target="_blank">gültige Platzhalternamen</a> verwendet werden. Statischer Text kann ohne Klammern stehen.<br><br><a href="/Einstellungen#document-layout-container">Vorlage anpassen</a>`,
            };
        },
        /**
         * Handle placeholder path errors in the heading or content of a document building block.
         */
        REPLACING_PLACEHOLDERS_IN_DOCUMENT_BUILDING_BLOCK_FAILED: (error) => {
            return {
                title: 'Ungültiger Platzhalter in Textbaustein',
                body: `Das Dokument konnte nicht erzeugt werden, weil im Textbaustein <strong>"${(error.data.documentBuildingBlock as DocumentBuildingBlock).title}"</strong> ein ungültiger Platzhalter verwendet wurde.`,
            };
        },
        PLACEHOLDER_DOES_NOT_EXIST_IN_PLACEHOLDER_VALUE_TREE: (error) => {
            return {
                title: 'Ungültiger Platzhalter',
                body: `Der Platzhalter <strong>{${error.data.placeholderTreePath}}</strong> ist nicht in autoiXpert bekannt. Bitte prüfe, ob er existiert und korrekt geschrieben ist.`,
            };
        },

        TEMPLATE_CLOSING_TAG_DOES_NOT_MATCH_OPENING_TAG: (error) => {
            return {
                title: 'Platzhalter-Bedingung nicht korrekt geschlossen',
                body: `Die Bedingung {${error.data.tag}} hat nicht das richtige öffnende und schließende Tag (mit # und /).<br><br>Bitte stelle sicher, dass in deiner Kopf- & Fußzeile sowie in deinen DOCX-Partials alle geöffneten Tags in der richtigen Reihenfolge auch wieder geschlossen werden.`,
            };
        },
        TEMPLATE_UNOPENED_TAG: (error) => {
            return {
                title: 'Ungeöffneter Platzhalter in Vorlage',
                body: `Bitte prüfe in der Kopf- & Fußzeile oder deinen DOCX-Partials die Bedingung rund um {${error.data.tag}}.<br><br>Hier scheint eine Bedingung geschlossen zu werden, der nie geöffnet wurde.`,
            };
        },
        TEMPLATE_UNCLOSED_LOOP: (error) => {
            return {
                title: 'Platzhalter: Schleife ungeschlossen',
                body: `Bitte prüfe in der Kopf- & Fußzeile oder deinen DOCX-Partials den Platzhalter {${error.data.tag}}.<br><br>Eine Schleife muss immer mit dem selben Platzhalter geschlossen werden wie sie geöffnet wurde. Das schließende Element muss mit einem Slash beginnen.`,
            };
        },
        CUSTOM_FIELD_CONFIG_MISSING: (error) => {
            const errorData: CustomFieldErrorData = error.data;

            return {
                title: 'Problem mit eigenem Feld',
                body: `Das eigene Feld "${
                    errorData.customField.name
                }" existiert noch in diesem Gutachten, das Feld wurde aber eigentlich schon für alle Gutachten gelöscht.

Bitte lösche es manuell, indem du die Feld-Bearbeitung startest (Admin-Rechte erforderlich) und das Feld mit dem roten Ausrufezeichen manuell löschst.

<strong>Position:</strong> ${getCustomFieldLocationGerman(errorData.customFieldGroup.fieldLocation)}`,
                forceDisplayAsTopHandler: true,
            };
        },
        INVALID_CHARACTER_IN_DOCUMENT_BUILDING_BLOCK: (error) => {
            // If te invalid char is within a block, the block is given. If it's within a placeholder only (e.g. the name of the lawyer which is only printed inside a DOCX table, not any prose text, then the placeholder name is given.
            if (error.data.invalidRenderedDocumentBuildingBlock) {
                return {
                    title: 'Ungültiges Zeichen in Textbaustein',
                    body: `Bitte lösche das Zeichen aus dem Eingabefeld für den Inhalt des Textbausteins "${
                        error.data.invalidRenderedDocumentBuildingBlock.Überschrift
                    }".<br>Das Zeichen wird als ${
                        error.data.invalidCharacter ? `"${error.data.invalidCharacter}" oder ` : ''
                    }"☒" im Eingabefeld angezeigt.<br><br><strong>Kontext:</strong> "${
                        error.data.invalidContentSnippet
                    }"`,
                };
            }
            if (error.data.invalidPlaceholderValuePath) {
                return {
                    title: 'Ungültiges Zeichen in Platzhalter',
                    body: `Bitte lösche das Zeichen aus dem Eingabefeld für den Platzhalter "${
                        error.data.invalidPlaceholderValuePath
                    }".<br>Das Zeichen wird als ${
                        error.data.invalidCharacter ? `"${error.data.invalidCharacter}"oder "` : ''
                    }☒" im Eingabefeld angezeigt.<br><br><strong>Kontext:</strong> "${
                        error.data.invalidPlaceholderValue
                    }"`,
                };
            }
            if (error.data.invalidStringInAudatexCalculation) {
                return {
                    title: 'Ungültiges Zeichen in Audatex-Kalkulation',
                    body: `Bitte lösche das Zeichen aus der Audatex-Kalkuation.<br>Das Zeichen wird als ${
                        error.data.invalidCharacter ? `"${error.data.invalidCharacter}" oder ` : ''
                    }"☒" im Eingabefeld angezeigt.<br><br><strong>Kontext:</strong> "${
                        error.data.invalidStringInAudatexCalculation
                    }"`,
                };
            }

            return {
                title: 'Ungültiges Zeichen',
                body: 'Irgendwo im Dokument schein ein ungültiges Zeichen zu sein. Typischerweise kommt das durch Textbausteine oder Eingaben im Gutachten rein. Falls du es nicht findest, hilft dir die <a href="/Hilfe" target="_blank">Hotline</a>.',
            };
        },
        REFERENCE_DOCUMENT_BUILDING_BLOCK_NOT_FOUND: (error) => {
            return {
                title: 'Überschriebener Textbaustein existiert nicht mehr',
                body: `Der Textbaustein "${error.data.referencePlaceholder}" wurde für dieses Gutachten über "Textbausteine überschreiben" geändert.<br>Der Textbaustein wurde danach aber über die Textbausteinverwaltung umbenannt oder aus dem Dokument entfernt.<br><br>Bitte füge den Baustein über die Textbausteinverwaltung wieder ein oder setze die geänderten Textbausteine über "Textbausteine überschreiben" zurück.`,
            };
        },
        RENDERING_WORD_DOCX_FAILED: (error) => {
            const errorExplanation = error.data.errorExplanation;
            // Check if we can translate the error into a more user-friendly message.
            const tagNameErrorMatch = errorExplanation.match(
                /The raw tag \"(.+)\" should be the only text in this paragraph\./,
            );
            if (tagNameErrorMatch) {
                const tagName = tagNameErrorMatch[1];
                return {
                    title: 'Platzhalter muss alleine im Absatz stehen',
                    body: `Nach dem Platzhalter <strong>{@${tagName}}</strong> in deiner DOCX-Vorlage darf kein weiterer Text im selben Paragraphen folgen. Hast du vielleicht kürzlich eine neue DOCX-Vorlage der <a href="/Einstellungen/Textbausteine" target="_blank">Textbausteine</a> oder ein neues Briefpapier hochgeladen?`,
                };
            }

            // Print general error message.
            const errorMessage = `Typischerweise passiert das, wenn ein Platzhalter nicht korrekt formatiert ist.`;
            return {
                title: 'Fehler beim Rendern',
                body: [
                    `${errorMessage}<br><br><strong>Technische Rückmeldung:</strong> ${errorExplanation.replace(
                        /\n/g,
                        '<br>',
                    )}`,
                    ...(error.data.placeholders?.length > 0
                        ? [
                              '<br><strong>Betroffene Platzhalter:</strong>',
                              ...error.data.placeholders.map(
                                  (placeholderError) =>
                                      ` - ${placeholderError.placeholder}: ${placeholderError.value} (${placeholderError.message})<br>`,
                              ),
                          ]
                        : []),
                ].join('<br>'),
            };
        },
        LOADING_DAT_DAMAGE_CALCULATION_DOCX_FAILED: {
            title: `Schadenskalkulation nicht geladen`,
            body: `Bitte importiere die Schadenskalkulation erneut von der DAT.`,
        },
        GTMOTIVE_CALCULATION_DOCUMENTS_NOT_FOUND: {
            title: 'Kalkulationsdokument nicht gefunden',
            body: 'Die Kalkulations-PDF von GT Motive konnte nicht gefunden werden. Bitte importiere die Kalkulation erneut.',
            partnerLogo: 'gtmotive',
        },
        INVALID_RECIPIENT_ROLE_ON_LETTER: (error) => ({
            title: `Ungültiger Empfänger`,
            body: `Der Empfänger "${error.data.recipientRole}" auf dem Anschreiben ist ungültig. Das könnte ein technisches Problem sein. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        RESPONSIBLE_ASSESSOR_UNKNOWN: {
            title: `Unbekannter Sachverständiger`,
            body: `Der zuständige Sachverständige ist unbekannt. Bitte prüfe die Gutachten-Eigenschaften im Reiter "Unfall & Beteiligte".`,
        },
        VISIT_ASSESSOR_UNKNOWN: {
            title: `Unbekannter Sachverständiger`,
            body: `Der Sachverständige mindestens einer Besichtigung ist unbekannt. Bitte prüfe die Besichtigungen im Reiter "Unfall & Beteiligte".`,
        },
        REPAIR_CONFIRMATION_RESPONSIBLE_ASSESSOR_UNKNOWN: {
            title: `Unbekannter Sachverständiger`,
            body: `Der zuständige Sachverständige der Reparaturbestätigung ist unbekannt. Bitte prüfe die Reparaturbestätigung.`,
        },
        REPAIR_CONFIRMATION_VISITING_ASSESSOR_UNKNOWN: {
            title: `Unbekannter Sachverständiger`,
            body: `Der besichtigende Sachverständige der Reparaturbestätigung ist unbekannt. Bitte prüfe die Reparaturbestätigung.`,
        },
        DAMAGE_CALCULATION_CACHE_FAILED: {
            title: 'Schadenskalkulation nicht abrufbar',
            body: 'Bitte importiere die Schadenskalkulation erneut.',
        },
        OFFICE_LOCATION_MISSING: {
            title: 'Standort des Sachverständigen fehlt',
            body: 'Wähle einen verfügbaren Standort für den Sachverständigen aus.',
            forceDisplayAsTopHandler: true,
        },
        GENERATING_PLACEHOLDERS_FAILED: (error) => {
            const dictionaryErrorCategories = {
                tires: 'Reifen',
                photos: 'Fotos',
                replacementValue: 'Wiederbeschaffungswert',
                damages: 'Schäden (Anstoßskizze)',
                visits: 'Besichtigungen',
            };

            const placeholderCategory =
                dictionaryErrorCategories[error.data && error.data.placeholderCategory] ||
                (error.data && error.data.placeholderCategory);
            // A try-catch block in the backend limited the error source.
            if (placeholderCategory) {
                return {
                    // Print either the German translation or the English term if no translation has yet happened. That might help the user.
                    title: `Platzhalter für ${placeholderCategory}`,
                    body: `Die Platzhalter konnten nicht generiert werden. Bitte prüfe deine Eingaben.<br><br>Wenn das nicht hilft, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                };
            }
            // A general placeholder issue occurred. Show a more generic error toast.
            else {
                return {
                    // Print either the German translation or the English term if no translation has yet happened. That might help the user.
                    title: `Platzhalter nicht generiert`,
                    body: `Es gibt ein Problem bei der Generierung der Platzhalter. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                };
            }
        },
        GETTING_CAR_EQUIPMENT_FOR_RENDERING_PLACEHOLDER_VALUES_FAILED: {
            title: 'Ausstattungen fehlen',
            body: `Die Ausstattungen konnten nicht geladen werden, sind aber notwendig für die Erzeugung der Platzhalter-Werte. Bitte frage die VIN nochmal ab. Sollte das Problem bestehen, wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        CUSTOM_FIELD_VALUE_DOES_NOT_MATCH_FIELD_TYPE: {
            title: 'Eigener Platzhalter-Wert',
            body: `Ein in deinem Büro angelegtes eigenes Feld kann nicht verarbeitet werden, weil der Wert nicht zu dem Feld-Typ (Datum, Zahl, Checkbox, ...) passt.<b><br>Eigene Platzhalter können kompliziert werden. Wenn du Unterstützung brauchst, kontaktiere gerne die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        CREATING_INVOICE_FROM_REPORT_FAILED_FOR_PLACEHOLDER_VALUES: {
            title: 'Platzhalter-Werte für Gutachten-Rechnung',
            body: `Die Werte der Platzhalter der Gutachten-Rechnung konnten nicht generiert werden. Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },

        MULTIPLE_MATCHING_FILE_NAME_PATTERNS_FOUND: {
            title: 'Mehr als ein Dateinamenmuster',
            body: `Für den aktuellen Vorgangstyp ist mehr als ein Muster definiert. Bitte entferne alle Duplikate im Dialog für Dateinamenanpassung.`,
        },
        BUCKET_NOT_FOUND: (error) => ({
            title: 'Bucket nicht gefunden',
            body: `Bucket: ${error.data.bucket}<br>Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        COMPILING_DOCUMENT_TEMPLATE_FAILED: {
            title: 'Dokument-Vorlage nicht gerendert',
            body: `Jedes von autoiXpert generierte Dokument braucht eine Vorlage. Die Erstellung dieser Vorlage hat nicht funktioniert.<br>Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        GETTING_DOCX_PARTIAL_FAILED: (error) => {
            const documentBuildingBlock: DocumentBuildingBlock = error.data?.documentBuildingBlock;

            return {
                title: 'DOCX-Vorlage für Textbaustein fehlt',
                body: `Die Vorlage für den Textbaustein "${documentBuildingBlock?.title}" fehlt. Bitte lade sie erneut hoch oder entferne sie nochmal manuell über die Textbaustein-Verwaltung.`,
            };
        },
        DEFAULT_PARTIAL_DOCX_NOT_FOUND_IN_FILE_SYSTEM: {
            title: 'Standard-DOCX-Vorlage nicht gefunden',
            body: `Die angefragte DOCX-Vorlage für diesen Textbaustein existiert nicht im Dateisystem des Servers. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>`,
        },
        READING_DEFAULT_PARTIAL_DOCX_FROM_FILE_SYSTEM_FAILED: {
            title: 'Fehler beim Auslesen der Standard-DOCX-Vorlage',
            body: `Die angefragte DOCX-Vorlage für diesen Textbaustein konnte wegen eines technischen Problems nicht aus dem Datei-System des Servers geladen werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>`,
        },
        INVALID_BANK_ACCOUNT_FACTORING_PROVIDER: {
            title: 'Bankverbindung Factoring ungültig',
            body: `Bitte überprüfe die Bankverbindung deines Factoring Providers in den <a href="/Einstellungen" target="_blank">Einstellungen</a> oder deaktiviere Factoring für dieses Gutachten/diese Rechnung.`,
        },
        AUDATEX_VALUATION_DOES_NOT_EXIST: {
            title: 'Bewertungs-PDF nicht gefunden',
            body: 'Bitte importiere die Bewertung erneut von Audatex.',
        },
        DAT_VALUATION_DOES_NOT_EXIST: {
            title: 'Bewertungs-PDF nicht gefunden',
            body: 'Bitte importiere die Bewertung erneut von der DAT.',
        },
        MANUALLY_UPLOADED_PDF_NOT_FOUND: (error) => ({
            title: 'PDF-Datei existiert nicht',
            body: `Die PDF-Datei des Dokuments "${error.data.document.title}" existiert nicht. Bitte lade sie erneut hoch.`,
        }),
        ENCRYPTED_PDF_CANNOT_BE_MERGED: (error) => {
            const documentName = error.data.sourcePdfFilename.replace('.pdf', '');
            return {
                title: `PDF "${documentName}" hat digitale Signatur`,
                body: `Der Ersteller des Dokuments möchte damit die Datei vor Änderungen schützen. Dadurch kann die Datei aber nicht zu einer Gesamt-PDF kombiniert werden.<br><br>Mit "Microsoft Print to PDF" kannst du das <a href="https://wissen.autoixpert.de/hc/de/articles/360019889920" target="_blank" rel="noopener">Problem lösen.</a>`,
            };
        },
        LOADING_DOCUMENT_PHOTOS_FAILED: {
            title: 'Fotos nicht gefunden',
            body: 'Einige Fotos fehlen. Bitte klicke diese Meldung oder öffne den Reiter Fotos und lade alle Fotos erneut hoch, die nicht angezeigt werden können.',
        },
        LEASE_RETURN_PHOTO_NOT_FOUND: (error) => {
            return {
                title: `Foto in Prüfposition fehlt`,
                body: `Bitte entferne das Foto aus der Prüfposition<br><em>${error.data?.leaseReturnSection?.title}</em> > <em>${error.data?.leaseReturnItem?.title}</em>.`,
            };
        },
        GETTING_EXTERNAL_DOCUMENT_FAILED: (error) => {
            if (error.name === 'NotFound') {
                return {
                    title: `Dokument "${error.data.externalDocumentTitle}" fehlt`,
                    body: 'Das Dokument konnte nicht abgerufen werden.',
                };
            } else {
                return {
                    title: `Fehler beim Dokument "${error.data.externalDocumentTitle}"`,
                    body: 'Das Dokument konnte nicht abgerufen werden. Wende dich bei Fragen an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                };
            }
        },
        VALUATION_DOCUMENTS_NOT_FOUND: (error) => ({
            title: `Bewertung nicht gefunden`,
            body: `Die Bewertungs-PDF fehlt. Bitte importiere die ${
                error.data.valuationProvider === 'dat' ? 'DAT' : 'Audatex'
            }-Bewertung erneut.`,
            partnerLogo: error.data.valuationProvider as 'dat' | 'audatex',
            stopReasonChain: true,
        }),
        UNEXPECTED_LAMBDA_OUTPUT_FORMAT: (error) => ({
            title: `Technischer Fehler im Rendering-Service`,
            body: `Der Lambda-Container des Services "${error.data.lambdaFunctionName}" hat ein ungültiges Ergebnis zurückgegeben und muss neu gestartet werden.<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        MISSING_DOCUMENT_BUILDING_BLOCK: (error) => ({
            title: `Textbaustein fehlt`,
            body: `Der Textbaustein mit der ID "${
                error.data?.documentBuildingBlockId
            }" fehlt im Dokument "${translateDocumentType(
                error.data?.documentType,
            )}".<br><br>Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        DOCUMENT_DOES_NOT_EXIST_ON_REPORT: {
            title: `Dokument existiert nicht mehr`,
            body: `Vermutlich wurde das Dokument in einem anderen Browser-Tab oder auf einem anderen Gerät gelöscht.<br><br>Bitte aktualisiere die Seite, damit dir nur noch aktuelle Dokumente angezeigt werden.`,
        },
        LOADING_CLAIMANT_SIGNATURE_FAILED: (error) => ({
            title: `Kunden-Unterschrift fehlt - ${translateDocumentType(error.data?.documentType)}`,
            body: `Das passiert, wenn ein Dokument aktiviert wird, nachdem der Kunde die anderen Dokumente unterschrieben hat.<br><br>Bitte lasse den Kunden auch auf diesem Dokument unterschreiben oder - falls der Kunde einverstanden ist - füge seine Unterschrift als Bild ein.`,
            stopReasonChain: true,
        }),
        FILE_NOT_FOUND: (error) => {
            switch (error.data.bucket) {
                case 'photos-autoixpert':
                    return {
                        title: 'Foto-Datei nicht gefunden',
                        body: 'Die Ursache ist vermutlich ein fehlerhafter Upload. Klicke hier, um zu dem Foto ohne Bild-Datei zu springen.',
                        toastClick: (axError) => {
                            router.navigate(['Gutachten', axError.data.reportId, 'Fotos'], {
                                queryParams: {
                                    selectedPhotos: error.data.photoId,
                                    forceLoadingPhotosFromServer: true,
                                },
                            });
                        },
                    };
                case 'user-uploaded-documents-autoixpert':
                    return {
                        title: 'PDF existiert nicht',
                        body: `Die hochgeladene PDF existiert nicht auf dem Server, womöglich weil beim Upload die Internet-Verbindung instabil war. Bitte lade sie erneut hoch.`,
                    };
                case 'user-seals-autoixpert':
                    return {
                        title: 'Stempel-Datei existiert nicht',
                        body: `Die hochgeladene Stempel-Datei existiert nicht. Bitte lade sie erneut hoch oder wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                    };
                case 'claimant-signatures-autoixpert':
                    return {
                        title: 'Kunden-Unterschrift fehlt',
                        body: `Die Unterschrift-Datei existiert nicht. Bitte lade sie erneut hoch oder wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                    };
                case 'document-templates-autoixpert':
                    return {
                        title: 'DOCX-Vorlage fehlt',
                        body: `Die DOCX-Datei für das Dokument existiert nicht. Bitte lade deine Kopf- & Fußzeile erneut hoch.`,
                    };
                case 'watermark-images-autoixpert':
                    return {
                        title: 'Wasserzeichen fehlt',
                        body: `Die Wasserzeichen-Datei fehlt. Bitte lade sie über den Foto-Editor erneut hoch.`,
                    };
                case 'team-logo-autoixpert':
                    return {
                        title: 'Logo fehlt',
                        body: `Die Logo-Datei fehlt. Bitte lade sie über die Einstellungen erneut hoch.`,
                    };
                case 'docx-watermark-templates-autoixpert':
                    return {
                        title: 'Wasserzeichen-Vorlage fehlt',
                        body: `Bitte lade die Word-Datei mit dem Wasserzeichen über die Wasserzeichen-Einstellungen im Dreipunktmenü neben dem blauen Schalter eines Dokuments erneut hoch.`,
                    };
                default:
                    return {
                        title: 'Datei existiert nicht mehr',
                        body: 'Eine Datei konnte nicht gefunden werden. Bitte wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    };
            }
        },
        LOADING_USER_SEAL_FAILED: {
            title: 'Stempel-Datei existiert nicht',
            body: `Die hochgeladene Stempel-Datei existiert nicht. Bitte lade sie über die Einstellungen erneut hoch oder wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        LOADING_TEAM_LOGO_FAILED: {
            title: 'Logo-Datei existiert nicht',
            body: `Die hochgeladene Logo-Datei existiert nicht. Bitte lade sie über die Einstellungen erneut hoch oder wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        LOADING_AUDATEX_DAMAGE_CALCULATION_TEXT_FAILED: {
            title: 'Audatex-Kalkulationstext nicht gefunden',
            body: 'Bitte lösche die Audatex-Kalkulation und füge den Text noch einmal ein. Wenn der Fehler weiterhin besteht, kontaktiere bitte die <a href="/Hilfe" target="_blank">Hotline</a>',
        },
        MORE_THAN_TWO_AXLES_NOT_SUPPORTED: {
            title: 'Nur eine oder zwei Achsen unterstützt.',
            body: 'Prüfe bitte, ob du die Zahl der Achsen angegeben hast. Falls du mehr als zwei benötigst, bearbeite das Gutachten bitte in Word.',
        },
        MISSING_ACCESS_RIGHT_TO_INVOICE: {
            title: 'Rechnung nicht verfügbar',
            body: 'Das Zugriffsrecht für die Gutachtenrechnung fehlt. Bitte wende dich an deinen Administrator.',
        },
        ACCESS_DENIED: (error) => {
            const accessRightGerman: AccessRightGerman = translateAccessRightToGerman(error.data.accessRight);

            return {
                title: 'Zugriffsrecht fehlt',
                body: `Dir fehlt das Zugriffsrecht "${accessRightGerman}". Bitte wende dich an deinen Administrator.`,
            };
        },
        MERGING_PDFS_FAILED: {
            title: 'Gesamt-PDF nicht erstellbar',
            body: 'Die PDFs konnten wegen eines technischen Grunds nicht zusammengeführt werden. Bitte versuche es erneut. Sollte dieselbe Meldung erneut angezeigt werden, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        COUNTING_PAGES_FAILED_BECAUSE_REPORT_DOCUMENT_IS_USER_UPLOADED: {
            title: 'Seiten einer eigenen PDF nicht zählbar',
            body: 'In Druck & Versand ist eine eigene PDF-Datei für das Gutachten hochgeladen. Weil nicht zu zählende Textbausteine aus externen PDF-Dateien nicht herausgerechnet werden können, kann die Anzahl der Seiten nicht automatisch gezählt werden. Bitte zähle manuell oder entferne die eigene Gutachten-PDF.',
        },

        //*****************************************************************************
        //  Payment Reminders
        //****************************************************************************/
        MISSING_PAYMENT_REMINDER_OBJECT: {
            title: 'Datenstruktur unvollständig',
            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>. Falls das nicht möglich ist, versuche im Mahnen-Dialog die notwendige Auswahl noch einmal anzuklicken.",
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Payment Reminders
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Fill PDF
        //****************************************************************************/
        ELEMENT_TO_FILL_IN_PDF_IS_OUTSIDE_PAGE: (error) => ({
            title: 'PDF kann nicht ausgefüllt werden',
            body: `Ein Element ${
                error.data.element?.content && '(Text mit dem Inhalt: ' + error.data.element.content + ')'
            }, das in das PDF eingefügt werden soll, liegt außerhalb Seite ${
                error.data?.pageNumber
            }. Bitte überprüfe die Einstellungen des Elements.`,
        }),
        INVALID_FORMAT_FOR_CUSTOMER_SIGNATURE_DOCUMENT: {
            title: 'Kundenunterschriften-PDF nicht als DOCX verfügbar',
            body: 'Als DOCX-Datei kannst du nur Dokumente herunterladen, die vollständig von autoiXpert generiert wurden.',
        },
        NO_PDF_UPLOADED_FOR_CUSTOMER_SIGNATURE_DOCUMENT: {
            title: 'Kundenunterschriften-PDF fehlt',
            body: 'Der Datensatz für die Kundenunterschrifts-PDF ist angelegt, aber es wurde keine PDF hochgeladen.<br><br>Bitte prüfe die Konfiguration in den <a href="/Einstellungen/Unterschreibbare-PDF-Dokumente">Einstellungen</a>.',
        },
        FILLING_PDF_FAILED: {
            title: 'Kundenunterschriften-PDF fehlerhaft',
            body: 'Etwas stimmt nicht mit der Kundenunterschrifts-PDF, weshalb sie nicht gerendert werden konnte.<br><br>Bitte prüfe die Konfiguration in den <a href="/Einstellungen/Unterschreibbare-PDF-Dokumente">Einstellungen</a>.',
        },

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Fill PDF
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Cache Uploads
        //****************************************************************************/
        // autoiXpert has no problem supporting large documents, but there are cases where docx documents get extremely large and cause the backend to run out of memory.
        // This is probably not a user error, therefore contact the hotline.
        // We had such a case on 2024-05-07 see slack: https://autoixpert.slack.com/archives/C7ZSY20TY/p1715082925778799
        FILE_TO_STORE_IN_CACHE_TOO_LARGE: (error) => ({
            title: 'Gerendertes Dokument zu groß',
            body: `Eine Datei (S3-Key: ${error.data.key}) ist zu groß (${humanFileSize(error.data.sizeInBytes)}) und konnte nicht in den Server-Cache geladen werden. <br><br>Evtl. hilft das Deaktivieren von Fotos. Falls nicht oder falls du eine übliche Anzahl Fotos hast (15 bis 40), kontaktiere die Hotline.`,
        }),
        RETRIEVED_FILE_FROM_CACHE_TOO_LARGE: (error) => ({
            title: 'Gerendertes Dokument zu groß',
            body: `Eine Datei (S3-Key: ${error.data.key}) ist zu groß (${humanFileSize(error.data.sizeInBytes)}) und konnte nicht aus dem Cache geladen werden. <br><br>Evtl. hilft das Deaktivieren von Fotos. Falls nicht oder falls du eine übliche Anzahl Fotos hast (15 bis 40), kontaktiere die Hotline.`,
        }),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Cache Uploads
        /////////////////////////////////////////////////////////////////////////////*/
    };
}

interface CustomFieldErrorData {
    customFieldGroup: CustomFieldGroup;
    customField: CustomField;
}

import { addDocumentOrderToReportForRecipient } from '@autoixpert/lib/documents/add-document-order-to-report-for-recipient';
import { getCurrentAndDefaultDocumentOrderForCommunicationRecipient } from '@autoixpert/lib/documents/get-current-and-default-document-order-for-communication-recipient';
import { getDocumentsFromDocumentOrderByType } from '@autoixpert/lib/documents/get-documents-from-document-order-by-type';
import { insertDocumentOrderItem } from '@autoixpert/lib/documents/insert-document-order-item';
import { CustomField } from '../../models/custom-fields/custom-field';
import { CustomFieldGroup } from '../../models/custom-fields/custom-field-group';
import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { DocumentOrderConfig } from '../../models/documents/document-order-config';
import { InvoiceParameters } from '../../models/invoices/invoice-parameters';
import { Accident } from '../../models/reports/accident';
import { DamageCalculation } from '../../models/reports/damage-calculation/damage-calculation';
import { InvoiceAudit } from '../../models/reports/invoice-audit';
import { AuthorOfDamage } from '../../models/reports/involved-parties/author-of-damage';
import { Claimant } from '../../models/reports/involved-parties/claimant';
import { CommunicationRecipient } from '../../models/reports/involved-parties/communication-recipient';
import { FactoringProvider } from '../../models/reports/involved-parties/factoring-provider';
import { Insurance } from '../../models/reports/involved-parties/insurance';
import {
    InvolvedParty,
    InvolvedPartyRole,
    communicationRecipientRoles,
} from '../../models/reports/involved-parties/involved-party';
import { Valuation } from '../../models/reports/market-value/valuation';
import { Report } from '../../models/reports/report';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { removeFromArray } from '../arrays/remove-from-array';
import { removeDocumentFromReport } from '../documents/remove-document-from-report';
import { removeUnnecessaryDocumentsFromReport } from '../documents/remove-unnecessary-documents-from-report';
import { addReportTypeSpecificDocuments } from './add-report-type-specific-documents';
import { getDefaultDaysUntilDue } from './default-days-until-due';

/**
 * Adjust the data structure for the target report type.
 * - data structure like involved parties or damage calculation for damage reports but not for most valuation reports.
 * - documents
 */
export function changeReportType({
    report,
    targetReportType,
    user,
    team,
    fieldGroupConfigs,
    documentOrderConfigs,
}: {
    report: Report;
    targetReportType: Report['type'];
    user: User;
    team: Team;
    fieldGroupConfigs: FieldGroupConfig[];
    documentOrderConfigs: DocumentOrderConfig[];
}): void {
    // Already the right type?
    if (report.type === targetReportType) {
        return;
    }

    const oldReportType = report.type;
    report.type = targetReportType;

    // Remove unused documents (and document orders) from the report before altering the report
    // (which might delete communication recipient properties, which in turn breaks the deletion
    // of documents during removeDocumentFromReport, which checks for these properties)
    removeUnnecessaryDocumentsFromReport(report);

    // Some report properties are unique to a specific report type. Remove them for all reports that don't need them to provide a clean data structure.
    if (report.type !== 'leaseReturn' && report.type !== 'usedVehicleCheck') {
        report.leaseReturn = null;
    }
    if (report.type !== 'invoiceAudit') {
        report.invoiceAudit = null;
    }

    //*****************************************************************************
    //  Remove relicts of old report type
    //****************************************************************************/
    // Remove properties that only exist in certain types.
    switch (oldReportType) {
        case 'liability':
            break;
        case 'shortAssessment':
            break;
        case 'partialKasko':
            report.accident.kaskoDamageType = null;
            break;
        case 'fullKasko':
            break;
        case 'valuation':
            break;
        case 'oldtimerValuationSmall':
            break;
        case 'leaseReturn':
            break;
        case 'usedVehicleCheck':
            break;
        case 'invoiceAudit':
            break;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remove relicts of old report type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Add properties of new report type
    //****************************************************************************/
    switch (report.type) {
        //*****************************************************************************
        //  Damage Reports
        //****************************************************************************/
        /**
         * Damage Reports = Documents whose main goal is describing the damage that happened due to an accident.
         */
        case 'liability':
            // Involved Parties
            createInvolvedParty(report, team, 'garage');
            createInvolvedParty(report, team, 'lawyer');
            createInvolvedParty(report, team, 'insurance');
            createInvolvedParty(report, team, 'authorOfDamage');
            createInvolvedParty(report, team, 'ownerOfAuthorOfDamagesCar');

            removeInvolvedParty(report, team, 'leaseProvider');
            removeInvolvedParty(report, team, 'seller');

            // Visits
            report.visits.forEach((visit) => (visit.sameStateAsInAccident = true));

            // Damage Calculation
            createDamageCalculation(report);

            // Accident
            createAccident(report);

            // Valuation
            report.valuation.vehicleValueType = 'replacementValue';

            // If changed from short assessment, there is no selectedFeeTable. That's required for choosing the right invoice recipient, though.
            report.feeCalculation.selectedFeeTable ??= 'BVSK';

            selectInvoiceRecipient(report);

            break;
        case 'shortAssessment':
            // Involved Parties
            createInvolvedParty(report, team, 'garage');
            createInvolvedParty(report, team, 'lawyer');
            createInvolvedParty(report, team, 'insurance');
            createInvolvedParty(report, team, 'authorOfDamage');
            createInvolvedParty(report, team, 'ownerOfAuthorOfDamagesCar');

            removeInvolvedParty(report, team, 'leaseProvider');
            removeInvolvedParty(report, team, 'seller');

            // Damage Calculation
            createDamageCalculation(report);
            // No damage sketch in a short assessment.
            report.car.damages = null;

            // Accident
            createAccident(report);

            // Reset valuation properties since there is no valuation in a short assessment.
            report.valuation = new Valuation();
            report.valuation.vehicleValueType = 'replacementValue';

            // Report
            report.testDriveCarriedOut = null;
            report.errorLogReadOut = null;

            // Downtime compensation
            report.damageCalculation.downtimeCompensationGroup = null;
            report.damageCalculation.replacementTimeInWorkdays = null;
            report.damageCalculation.rentalCarClass = null;
            report.damageCalculation.downtimeCompensationPerWorkday = null;

            // Damage type
            report.damageCalculation.damageType = null;

            // Repair
            report.damageCalculation.repair.plasticRepairRequired = null;
            report.damageCalculation.repair.paintBlendingRequired = null;
            report.damageCalculation.repair.paintBlendingComment = null;

            // Use DEKRA fees only if no garage or garage fees have been selected yet (otherwise the selection would be overwritten)
            if (!report.garage.contactPerson.organization && !report.garage?.contactPerson?.garageFeeSets?.length) {
                report.damageCalculation.repair.useDekraFees = true;
            }

            // Car condition
            report.car.paintCondition = null;
            report.car.generalCondition = null;
            report.car.autobodyCondition = null;
            report.car.interiorCondition = null;
            report.car.airbagsReleased = null;
            report.car.emissionGroup = null;
            report.car.repairedPreviousDamage = null;
            report.car.unrepairedPreviousDamage = null;
            report.car.meantimeDamage = null;
            report.car.damageDescription = null;

            // Accident
            report.accident.circumstances = null;
            report.accident.plausibility = null;
            report.accident.recordedByPolice = null;
            report.accident.caseNumberPolice = null;

            report.visits.forEach((visit) => (visit.sameStateAsInAccident = true));

            break;
        case 'partialKasko':
        case 'fullKasko':
            // Involved Parties
            createInvolvedParty(report, team, 'garage');
            createInvolvedParty(report, team, 'lawyer');
            createInvolvedParty(report, team, 'insurance');
            createInvolvedParty(report, team, 'authorOfDamage');
            createInvolvedParty(report, team, 'ownerOfAuthorOfDamagesCar');

            removeInvolvedParty(report, team, 'leaseProvider');
            removeInvolvedParty(report, team, 'seller');

            // Damage Calculation
            createDamageCalculation(report);

            // Accident
            createAccident(report);

            // Visits
            report.visits.forEach((visit) => (visit.sameStateAsInAccident = true));

            // Valuation
            report.valuation.vehicleValueType = 'replacementValue';
            break;
        case 'invoiceAudit':
            // Involved Parties
            createInvolvedParty(report, team, 'garage');
            createInvolvedParty(report, team, 'lawyer');
            createInvolvedParty(report, team, 'insurance');
            createInvolvedParty(report, team, 'authorOfDamage');
            createInvolvedParty(report, team, 'ownerOfAuthorOfDamagesCar');

            removeInvolvedParty(report, team, 'leaseProvider');
            removeInvolvedParty(report, team, 'seller');

            // Damage Calculation
            createDamageCalculation(report);
            report.damageCalculation.repair.useDekraFees = false;

            // Accident
            createAccident(report);

            // Invoice Audit
            report.invoiceAudit = new InvoiceAudit();

            break;

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Damage Reports
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Value Reports
        //****************************************************************************/
        /**
         * Value Reports = Documents whose main goal is assessing a vehicle's value.
         */
        case 'valuation':
            createInvolvedParty(report, team, 'garage');

            // Activate the dealer purchase price as default
            report.valuation.vehicleValuePurchasePriceActive = true;
            report.valuation.correctionsTarget = 'dealerPurchase';
            report.valuation.vehicleValueType = 'dealerPurchase';
            report.valuation.secondVehicleValueType = 'dealerSales';

            // Remove Accident from visit
            report.visits.forEach((visit) => (visit.sameStateAsInAccident = null));

            // Damage Calculation
            createDamageCalculation(report);

            break;
        case 'oldtimerValuationSmall':
            // Valuation
            report.valuation.vehicleValueType = 'marketValue';
            report.valuation.secondVehicleValueType = 'replacementValue';
            // Remove Accident from visit
            report.visits.forEach((visit) => (visit.sameStateAsInAccident = null));

            break;
        case 'leaseReturn':
            // Involved Parties
            createInvolvedParty(report, team, 'garage');
            createInvolvedParty(report, team, 'leaseProvider');

            removeInvolvedParty(report, team, 'lawyer');
            removeInvolvedParty(report, team, 'insurance');

            // Remove Accident from visit
            report.visits.forEach((visit) => (visit.sameStateAsInAccident = null));

            createDamageCalculation(report);

            // When taking back a leased car, the price a dealer would pay for it is most relevant in most cases.
            report.valuation.vehicleValueType = 'dealerPurchase';

            /**
             * The taxation can only be full or none, because the differential taxation is only used in fictional settlements.
             * No tax (private persons) is reflected by printing the gross value, while full tax prints the net values + VAT.
             * Hence, this property can be static. It must be set for the correct placeholder generation.
             */
            report.valuation.taxationType = 'full';

            report.leaseProvider = new CommunicationRecipient('leaseProvider');

            break;
        case 'usedVehicleCheck':
            // Involved Parties
            createInvolvedParty(report, team, 'garage');
            createInvolvedParty(report, team, 'seller');

            removeInvolvedParty(report, team, 'lawyer');
            removeInvolvedParty(report, team, 'insurance');

            // Remove Accident from visit
            report.visits.forEach((visit) => (visit.sameStateAsInAccident = null));

            /**
             * There is no deviating owner of the claimant's car in the used vehicle check since that's a complexity that does not exist in practice. Until now,
             * we have not heard of a case where a vehicle owner asked someone to have a vehicle check created and ensure that the person's name who was tasked
             * with the order is written on the used vehicle check as well.
             *
             * This ensures the claimant's address is printed. This way, in case of a deviating owner of the claimant's car from a former report type, the owner
             * of the claimant's car is not printed.
             */
            report.claimant.isOwner = true;

            createDamageCalculation(report);

            // When taking back a leased car, the price a dealer would pay for it is most relevant in most cases.
            report.valuation.vehicleValueType = 'dealerPurchase';

            /**
             * The taxation can only be full or none, because the differential taxation is only used in fictional settlements.
             * No tax (private persons) is reflected by printing the gross value, while full tax prints the net values + VAT.
             * Hence, this property can be static. It must be set for the correct placeholder generation.
             */
            report.valuation.taxationType = 'full';

            break;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Value Reports
        /////////////////////////////////////////////////////////////////////////////*/
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add properties of new report type
    /////////////////////////////////////////////////////////////////////////////*/

    // Change documents. They depend on the communication recipients/involved parties.
    addReportTypeSpecificDocuments({ report, team, user, documentOrderConfigs });

    //*****************************************************************************
    //  Custom Fields
    //****************************************************************************/
    /**
     * - Determine target custom fields for a report type.
     * - Move existing fields from existing custom field group to the target groups to keep them.
     * - The non-required fields will disappear because they're not part of the target data.
     */
    const targetCustomFieldGroups: CustomFieldGroup[] = fieldGroupConfigs.map(
        (fieldGroupConfig) =>
            new CustomFieldGroup({
                fieldLocation: fieldGroupConfig.fieldLocation,
                customFields: fieldGroupConfig.fieldConfigs
                    // Only pass in the fields for the right report type.
                    .filter((customFieldConfig) => customFieldConfig.reportTypes.includes(report.type))
                    .map(
                        (customFieldConfig) =>
                            new CustomField({
                                fieldConfigId: customFieldConfig._id,
                                name: customFieldConfig.name,
                                value: customFieldConfig.defaultValue,
                            }),
                    ),
            }),
    );

    // Loop over all groups and move existing fields to target data.
    for (const targetCustomFieldGroup of targetCustomFieldGroups) {
        const existingCustomFieldGroupInReport: CustomFieldGroup = report.customFieldGroups.find(
            (customFieldGroup) => customFieldGroup.fieldLocation === targetCustomFieldGroup.fieldLocation,
        );

        // If field groups aren't existing yet, no need to move over existing field values.
        if (!existingCustomFieldGroupInReport) {
            continue;
        }

        // Move an existing field to the target fields, thereby keeping its value.
        for (const existingField of existingCustomFieldGroupInReport.customFields) {
            const targetIndex: number = targetCustomFieldGroup.customFields.findIndex(
                (targetCustomField) => targetCustomField.fieldConfigId === existingField.fieldConfigId,
            );
            if (targetIndex > -1) {
                targetCustomFieldGroup.customFields.splice(targetIndex, 1, existingField);
            }
        }
    }

    report.customFieldGroups = targetCustomFieldGroups;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Fields
    /////////////////////////////////////////////////////////////////////////////*/

    // TODO Merge with the logic for auxiliary costs per report type. That should be implemented in the future. Steffen Langer on 2022-03-30.

    // Fee Calculation
    report.feeCalculation.invoiceParameters.daysUntilDue = getDefaultDaysUntilDue({
        team,
        reportType: report.type,
    });
    report.feeCalculation.invoiceParameters.factoringEnabled = team.preferences.factoringEnabled;
    selectInvoiceRecipient(report);
}

export function createDamageCalculation(report: Report) {
    if (!report.damageCalculation) {
        report.damageCalculation = new DamageCalculation();
    }

    // Damages
    if (!report.car.damages) {
        report.car.damages = [];
    }
}

function createAccident(report: Report) {
    if (!report.accident) {
        report.accident = new Accident();
    }
}

export function createInvolvedParty(report: Report, team: Team, involvedPartyRole: InvolvedPartyRole) {
    if (!report[involvedPartyRole]) {
        let involvedParty: InvolvedParty;
        switch (involvedPartyRole) {
            case 'claimant':
                involvedParty = new Claimant();
                break;
            case 'ownerOfClaimantsCar':
                involvedParty = new InvolvedParty('ownerOfClaimantsCar');
                break;
            case 'authorOfDamage':
                involvedParty = new AuthorOfDamage();
                break;
            case 'ownerOfAuthorOfDamagesCar':
                involvedParty = new InvolvedParty('ownerOfAuthorOfDamagesCar');
                break;
            case 'garage':
                involvedParty = new CommunicationRecipient('garage');
                break;
            case 'lawyer':
                involvedParty = new CommunicationRecipient('lawyer');
                break;
            case 'insurance':
                involvedParty = new Insurance();
                break;
            case 'leaseProvider':
                involvedParty = new CommunicationRecipient('leaseProvider');
                break;
            case 'factoringProvider':
                involvedParty = new FactoringProvider();
                break;
            case 'seller':
                involvedParty = new CommunicationRecipient('seller');
                break;
        }
        report[involvedPartyRole] = involvedParty as any;

        /**
         * If the new involved party role is a communication recipient,
         * ensure all existing documents are available in the communication recipients document order.
         */
        if (communicationRecipientRoles.includes(involvedPartyRole as any)) {
            const communicationRecipient = report[involvedPartyRole] as CommunicationRecipient;

            addDocumentOrderToReportForRecipient({
                report,
                communicationRecipient,
                documentGroup: 'report',
            });

            const { documentOrder, defaultDocumentOrder } = getCurrentAndDefaultDocumentOrderForCommunicationRecipient({
                report,
                team: team,
                documentGroup: 'report',
                communicationRecipient: communicationRecipient,
            });

            /**
             * Insert all documents into the document order of the new communication recipient.
             * Usually, the document order is empty, but it may contain documents if the report type was changed before.
             * In this case, the document should not be added.
             */
            for (const existingDocument of report.documents) {
                /**
                 * Does the document already exist?
                 */
                const existingDocumentOrderItem = getDocumentsFromDocumentOrderByType({
                    documents: report.documents,
                    documentOrder,
                    documentType: existingDocument.type,
                    uploadedDocumentId: existingDocument.uploadedDocumentId,
                })[0];

                if (!existingDocumentOrderItem) {
                    insertDocumentOrderItem({
                        newDocument: existingDocument,
                        documents: report.documents,
                        documentOrder,
                        defaultDocumentOrder,
                        insertAfterFallback: 'letter',
                    });
                } else {
                    console.log(
                        `The given document (${existingDocument.type}) exists already on the document order of the new communication recipient (${communicationRecipient.role}) and will not be inserted again.`,
                    );
                }
            }
        }
    }
}

/**
 * Remove the given involved party completely from the report. Means that all documents are removed that are targeted
 * at that person and also all related document orders are removed. The InvolvedParty object is finally completely
 * removed from the report object (E.g. in case of removing the lawyer: report.lawyer = null).
 */
function removeInvolvedParty(report: Report, team: Team, involvedPartyRole: InvolvedPartyRole) {
    if (report[involvedPartyRole]) {
        // If the given party is a communication recipient, remove all associated documents and document orders
        if (communicationRecipientRoles.includes(involvedPartyRole as any)) {
            // Remove documents
            const documentsToBeRemoved = report.documents.filter(
                (document) => document.recipientRole === involvedPartyRole,
            );
            for (const document of documentsToBeRemoved) {
                removeDocumentFromReport({ document, report, documentGroup: 'both' });
            }

            // Remove document orders
            const documentOrdersToBeRemoved = report.documentOrders.filter(
                (documentOrder) => documentOrder.recipientRole === involvedPartyRole,
            );
            for (const documentOrder of documentOrdersToBeRemoved) {
                removeFromArray(documentOrder, report.documentOrders);
            }
        }

        // Finally remove the involved party object
        delete report[involvedPartyRole];
    }
}

/**
 * Set the default invoice recipient for each report type.
 */
function selectInvoiceRecipient(report: Report) {
    let defaultInvoiceRecipient: InvoiceParameters['recipientRole'];
    switch (report.type) {
        case 'liability':
        case 'shortAssessment':
        case 'valuation':
        case 'usedVehicleCheck':
        case 'oldtimerValuationSmall':
        case 'invoiceAudit':
            defaultInvoiceRecipient = 'claimant';
            break;
        case 'partialKasko':
        case 'fullKasko':
            defaultInvoiceRecipient = 'insurance';
            break;
        case 'leaseReturn':
            defaultInvoiceRecipient = 'leaseProvider';
            break;
    }

    report.feeCalculation.invoiceParameters.recipientRole = defaultInvoiceRecipient;
}

import { Component, EventEmitter, Output } from '@angular/core';
import { fadeInAndOutAnimation } from 'src/app/shared/animations/fade-in-and-out.animation';

@Component({
    selector: 'synchronize-credentials',
    templateUrl: './synchronize-credentials.component.html',
    styleUrls: ['./synchronize-credentials.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class SynchronizeCredentialsComponent {
    @Output() confirmClick = new EventEmitter<never>();
    @Output() cancelClick = new EventEmitter<never>();

    emitConfirmClick() {
        // TODO: animate fade-out before emitting
        this.confirmClick.emit();
    }

    emitCancelClick() {
        // TODO: animate fade-out before emitting
        this.cancelClick.emit();
    }
}

import { InvoiceNumberJournalEntryDocumentType } from '@autoixpert/models/teams/invoice-number-journal-entry';
import { ContactPerson } from '../../contacts/contact-person';
import { LineItem } from '../../invoices/line-item';
import { Label } from '../../labels/label';
import { CarShape } from '../../reports/car-identification/car';
import { EmailRecipient } from '../../reports/document-email';
import { DatValuationIncludedDocuments, DatVehicleValueType } from '../../reports/market-value/dat-valuation';
import { ResidualValueRegionalRadius, Valuation } from '../../reports/market-value/valuation';
import { Report, ReportType } from '../../reports/report';
import { BankAccount } from '../../teams/team';
import { User } from '../user';
import { DefaultPhotoDescription } from './default-photo-description';
import { WorkweekSettings } from './workweek-settings';

export class UserPreferences {
    reportAttributesInHeadRunner: ('reportToken' | 'licensePlate')[] = ['licensePlate', 'reportToken'];
    // Toggle NavBar to sticky
    navBarSticky: boolean = false;
    // Report List
    showReportDetailsPane: boolean = null;
    reportDetailsPane_reportTokenShown: boolean = true;
    reportDetailsPane_lastEditedShown: boolean = true;
    reportDetailsPane_labelsShown: boolean = true;
    reportDetailsPane_tasksShown: boolean = true;
    reportDetailsPane_involvedPartiesShown: boolean = true;
    reportDetailsPane_progressShown: boolean = true;

    sortReportListBy: ReportListSortTypes = 'automaticDate';
    sortReportListDescending: boolean = true;
    reportListQuickFilter: ReportListQuickFilterType = 'none';
    reportListQuickFilterReportType: ReportType = null;

    /**
     * Filter report list by colleagues. Their ID must exist in the createdBy or the responsibleAssessor property.
     */
    reportListQuickFilterAssessorIds: User['_id'][] = [];
    /**
     * For locked reports, we always show the completion date of the report.
     * For open reports, we let the customer decide, which date to show.
     * Some customers prefer the first visit date since they want to deliver the report short after the first visit.
     */
    reportListSelectedDateRow: ReportListAvailableDateRows = 'created';
    reportListReportTokenShown: boolean = false;
    reportListResponsibleAssessorShown: boolean = false;
    reportListLabelsShown: boolean = false;

    // Invoice List
    sortInvoiceListBy: 'date' | 'lastName' | 'total' | 'paymentStatus' | 'invoiceNumber' | 'officeLocation' =
        'invoiceNumber';
    sortInvoiceListDescending: boolean = true;
    invoiceListQuickFilter:
        | 'none'
        | 'onlyDue'
        | 'onlyPaid'
        | 'onlyOverdue'
        | 'partial'
        | 'unpaid'
        // Payment reminders
        | 'hasOpenPaymentReminder'
        | 'toBeReminded'
        // Short payments
        | 'shortPaid'
        | 'settledShortPayments'
        | 'unsettledShortPayments'
        | 'imported'
        // Labels
        | 'labels' = 'none';
    invoiceList_labelsForFilter: Label['name'][] = [];
    reportList_labelsForFilter: Label['name'][] = [];
    invoiceList_infoPanelShown: boolean = true;
    invoiceList_infoPanelInvoiceNumberShown: boolean = true;
    invoiceList_infoPanelAssociatedReportShown: boolean = true;
    invoiceList_infoPanelLabelsShown: boolean = true;
    invoiceList_infoPanelNotesShown: boolean = true;
    invoiceList_infoPanelTasksShown: boolean = true;
    invoiceList_infoPanelInvolvedPartiesShown: boolean = true;
    paymentNotesShown: boolean = false;
    displayInvoiceWithReportToken: boolean = false;
    invoiceExportWithReportData: boolean = false;
    // Bank Account Sync
    bankTransactionListVisibilityFilter: 'onlySeen' | 'onlyUnseen' = null;
    // Contact List
    sortContactListBy: 'lastName' | 'organization' = 'lastName';
    sortContactListDescending: boolean = false;
    contactListQuickFilter: ContactPerson['organizationType'] = null;
    // Invoice number journal
    invoiceNumberJournal_usersForFilter: User['_id'][] = [];
    invoiceNumberJournal_documentTypeForFilter: InvoiceNumberJournalEntryDocumentType = null;
    invoiceNumberJournalQuickFilter: 'documentType' | 'createdBy' = null;
    sortInvoiceNumberJournalDescending: boolean = true;
    invoiceNumberJournalSortBy: 'invoiceNumber' | 'createdAt' = 'createdAt';
    // Document Building Blocks
    showOnlyDocumentBuildingBlocksOfSelectedDocument: boolean = true;
    documentBuildingBlockPreviewActive: boolean = false;
    documentBuildingBlockPreviewPinned: boolean = false;
    documentBuildingBlockPreviewReportId: string = null;
    documentBuildingBlockPreviewInvoiceId: string = null;
    // When creating a partial cancellation invoice, we add one line item. This is the default title.
    partialCancellationInvoiceLineItemTitle: string;
    // Report properties
    responsibleAssessor: string = null;
    useCostEstimateHeading: boolean = false;
    orderingMethod: Report['orderingMethod'] = null;
    // Contact Person
    claimantIbanShown: boolean = false;
    claimantCaseNumberShown: boolean = false;
    claimantDebtorNumberShown: boolean = false;
    claimantNotesShown: boolean = false;
    // Car Data
    vehicleIdentificationProvider: 'audatex' | 'dat' | 'gtmotive' = null;
    automaticallyInsertLatestRegistration: boolean = true;
    // Photos & Photo Editor
    editorShapeColor: string = '#15a9e8';
    photoEditorKeepToolActive: boolean = false;
    thumbnailSize: ThumbnailSize = 'medium';
    thumbnailSizeMobile: ThumbnailSizeMobile = 'medium';
    defaultPhotoDescriptions: DefaultPhotoDescription[] = [
        'Ansicht vorne links',
        'Ansicht vorne rechts',
        'Ansicht hinten rechts',
        'Ansicht hinten links',
    ].map((description) => new DefaultPhotoDescription({ title: description }));
    alwaysDisplayPhotoDescription: boolean = false;
    // Visits
    auxiliaryDevicesDefault: string[] = [];
    otherPeoplePresentDefault: string[] = [];
    // Car Condition
    spareTireEquipmentShown: boolean = null;
    compatibilityShown: boolean = false;
    emergencyRepairWorkDefault: string[] = [];
    // Compensation
    showRentalCarCosts: boolean;
    valuationFee: number = null;
    leaseReturnFee: number = null;
    usedVehicleCheckFee: number = null;
    oldtimerValuationSmallFee: number = null;
    oldtimerValuationLargeFee: number = null;
    shortAssessmentFee: number = null;
    invoiceAuditFee: number = null;
    // Print and Transmission
    factoringProvider: DefaultFactoringProvider = new DefaultFactoringProvider();
    printDocumentsWithoutHeaderAndFooter: boolean = false;
    activateDocumentsAfterUpload: boolean = true;
    sendDocumentsSeparately: DocumentSeparationSettings = new DocumentSeparationSettings();
    //*****************************************************************************
    //  Damage Calculation
    //****************************************************************************/
    // Residual Value Analysis
    residualValueTimeOffsetAmount: number = 1;
    residualValueTimeOffsetUnit: 'businessDays' | 'calendarDays' | 'hours' = 'businessDays';
    residualValueBindingPeriod: number = 21; // How long are bidders bound to their bid made through our residual value request?
    residualValueDefaultRecipients: string[] = [];
    residualValueRegionalRadius: ResidualValueRegionalRadius = null;
    residualValueTargetTime: string = null;
    // Diminished Value Calculator
    useDiminishedValueMethodMfm: boolean = true;
    useDiminishedValueMethodBvsk: boolean = true;
    useDiminishedValueMethodHalbgewachs: boolean = true;
    useDiminishedValueMethodHamburgModel: boolean = true;
    useDiminishedValueMethodRuhkopf: boolean = true;
    useDiminishedValueMethodDvgt: boolean = true;
    useDiminishedValueMethodTroemner: boolean = true;
    diminishedValueRoundingAmount: number = null;
    diminishedValueRoundingDirection: 'up' | 'down' = 'up';
    diminishedValueCommentStandardText = '';
    // Technical Diminished Value
    technicalDiminishedValueShown: boolean = null;
    // Value Increase
    valueIncreaseShown: boolean = false;
    // Special Costs
    pricePerLiterGasoline: number = null;
    pricePerLiterDiesel: number = null;
    remainingFuelRoundingAmount: number = null;
    remainingFuelRoundingDirection: 'up' | 'down' = 'up';
    disposalCosts: number = null;
    deregistrationAndRegistrationCosts: number = null;
    // Damage Class
    include130PercentRuleInAutomaticSelection: boolean = false;
    includeEconomicTotalLossInAutomaticSelection: boolean = false;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damage Calculation
    /////////////////////////////////////////////////////////////////////////////*/
    // Email
    defaultEmailRecipientsClaimant: DefaultEmailRecipients = new DefaultEmailRecipients();
    defaultEmailRecipientsGarage: DefaultEmailRecipients = new DefaultEmailRecipients();
    defaultEmailRecipientsLawyer: DefaultEmailRecipients = new DefaultEmailRecipients();
    defaultEmailRecipientsInsurance: DefaultEmailRecipients = new DefaultEmailRecipients();
    defaultEmailRecipientsLeaseProvider: DefaultEmailRecipients = new DefaultEmailRecipients();
    defaultEmailRecipientsInvoiceRecipient: DefaultEmailRecipients = new DefaultEmailRecipients();
    // Repair Confirmation
    repairConfirmationFee: number = null;
    // Expert Statement
    defaultRecipientExpertStatement: 'lawyer' | 'insurance' | 'claimant' | 'garage' = 'lawyer';
    // Message Templates
    defaultCoverLetterTemplates: DefaultMessageTemplateReference = new DefaultMessageTemplateReference();
    defaultEmailTemplates: DefaultMessageTemplateReference = new DefaultMessageTemplateReference();
    defaultInvoiceEmailTemplates: DefaultMessageTemplateReference = new DefaultMessageTemplateReference();
    defaultRemoteSignatureEmailTemplates: DefaultMessageTemplateReference = new DefaultMessageTemplateReference();
    // DAT Damage Calculation
    createDocumentMetadataForDamageCalculation: boolean = null;
    // DAT Valuation
    datValuationIncludedDocuments: Valuation['datValuation']['includedDocuments'] = new DatValuationIncludedDocuments();
    datValuationVehicleValueType: DatVehicleValueType = 'replacementValue';
    datValuationVatIncluded: Valuation['datValuation']['requestedVatHandling'] = 'detectFromCarOwnerVatStatus';
    datValuationPriceOrigin: Valuation['datValuation']['priceOrigin'] = 'dat';
    datValuationVatDisplayType: Valuation['datValuation']['vatDisplayType'] = 'relativeToMargin';
    replacementValueSearchRadiusWinvalue: 500 | 200 | 100 | 50 | 20 | 10 = null;
    replacementValueSearchRadiusCartv: 200 | 150 | 100 = null;
    replacementValueSearchRadiusValuepilot: 500 | 200 | 150 | 100 | 50 | 20 | 10 = null;
    attachDatVxs: boolean = null;
    // Estimate Calculation
    useSimpleEstimateCalculation: boolean = false;
    // Oldtimer Valuation
    recalculateOldtimerGradesAutomatically: boolean = true;
    // Lease Return
    isLeaseReturnSummaryPinned: boolean = null;
    // Payment Reminders
    paymentReminderLevel0TimeOffset: number = 14;
    paymentReminderLevel0TimeOffsetUnit: 'businessDays' | 'calendarDays' = 'calendarDays';
    paymentReminderLevel1TimeOffset: number = 14;
    paymentReminderLevel1TimeOffsetUnit: 'businessDays' | 'calendarDays' = 'calendarDays';
    paymentReminderLevel2TimeOffset: number = 14;
    paymentReminderLevel2TimeOffsetUnit: 'businessDays' | 'calendarDays' = 'calendarDays';
    paymentReminderLevel3TimeOffset: number = 14;
    paymentReminderLevel3TimeOffsetUnit: 'businessDays' | 'calendarDays' = 'calendarDays';

    // Remote signature preferences
    remoteSignatureDeadlineOffsetAmount: number = 1;
    remoteSignatureDeadlineOffsetUnit: 'businessDays' | 'calendarDays' | 'hours' = 'businessDays';

    // Task Panels (either the task list overlay or the panels inside the report/invoice details).
    taskPanel_sortToBackOnCompletion: boolean = true;

    // Task List
    taskList_sortBy: 'dueDate' | 'rank' = 'rank';
    taskList_sortDescending: boolean = true;
    taskList_showCompletedTasks: boolean = false;
    taskList_quickFilter: TaskListQuickFilterType = 'none';
    taskList_usersForFilter: User['_id'][] = [];
    taskList_labelsForFilter: Label['name'][] = [];
    taskList_showBadgeOfDueTasks: boolean = true;
    taskList_workweekSettings = new WorkweekSettings();

    // Insurances
    insurance_displayInsuranceCompanyNumber: boolean = false;

    // Paint Thickness
    paintThicknessCustomPositions?: Record<CarShape, string[]> = {} as Record<CarShape, string[]>; // User can remember the custom positions for paint thickness measurements per car shape.

    // Generate damage description from calculation
    damageDescriptionFromRepairParts_listFormat: 'bulletList' | 'comma' | 'newline' = 'bulletList';
    damageDescriptionFromRepairParts_repairExtension: 'ignore' | 'include' | 'newList' = 'ignore';
    damageDescriptionFromRepairParts_includeRepairType: boolean = true;
    damageDescriptionFromRepairParts_includeComments: boolean = true;
    damageDescriptionFromRepairParts_blacklistedTokens: string[] = []; // Ignore positions with one of these words in the description

    // Generate repair instructions from calculation
    repairInstructionsFromRepairParts_listFormat: 'bulletList' | 'comma' | 'newline' = 'bulletList';
    repairInstructionsFromRepairParts_repairExtension: 'ignore' | 'include' | 'newList' = 'ignore';
    repairInstructionsFromRepairParts_includeComments: boolean = true;
    repairInstructionsFromRepairParts_blacklistedTokens: string[] = []; // Ignore positions with the following word in the description

    // External API
    developerTools_displayIds = false; // Display the autoiXpert _id of contacts, reports, ...

    // Email sending delay
    emailSendingDelayAmount: number = 10;
    emailSendingDelayUnit: 'seconds' | 'minutes' | 'hours' = 'seconds';
    // Whether the email delay feature is enabled
    emailSendingDelayEnabled: boolean = false;
}

export type ReportListQuickFilterType =
    | 'onlyOwn'
    | 'startedThisWeek'
    | 'reportWithExpertStatement'
    | 'reportWithRepairConfirmation'
    | 'reportType'
    | 'involvedUser'
    | 'labels'
    | 'none';

export type TaskListQuickFilterType = 'none' | 'onlyOwn' | 'assignedUser' | 'onlyDue' | 'labels';

export class DefaultFactoringProvider {
    contactPerson: ContactPerson = new ContactPerson();
    bankAccount: BankAccount = new BankAccount();
}

// For each document group: which template is the default for each involved party?
export class DefaultMessageTemplateReference {
    report = new MessageTemplateActiveMapReport();
    repairConfirmation = new MessageTemplateActiveMapReport();
    invoiceEmail = new MessageTemplateActiveMapInvoice();
    remoteSignatureEmail = new MessageTemplateActiveMapReport();
}

export class MessageTemplateActiveMapReport {
    claimant: string;
    garage: string;
    lawyer: string;
    insurance: string;
    factoringProvider: string;
}
export class MessageTemplateActiveMapInvoice {
    recipient: string;
    invoiceRecipient: string;
    claimant: string;
    garage: string;
    lawyer: string;
    insurance: string;
    factoringProvider: string;
}

export class CustomFeeConfig {
    constructor(template?: CustomFeeConfig) {
        if (template) {
            Object.assign(this, template);
        }
    }

    customFeeSetId: string;
    row: DefaultFeeSetRow = 'higher';
}

export class DefaultFeeConfig {
    constructor(template?: DefaultFeeConfig) {
        if (template) {
            Object.assign(this, template);
        }
    }

    row: DefaultFeeSetRow = 'higher';
    column: number = 2;
}

export type DefaultFeeSetRow = 'higher' | 'lower';

export class FeePreferences {
    constructor(options: Partial<FeePreferences> = {}) {
        Object.assign(this, options);
    }

    usePhotosFixedPrice: boolean = null;
    photosFixedPrice: number = null;
    pricePerPhoto: number = null;
    secondPhotoSet: boolean = null;
    pricePerSecondPhoto: number = null;
    secondPhotoSetFixedPrice: number = null;
    useTravelExpensesFixedPrice: boolean = null;
    travelExpensesFixedPrice: number = null;
    pricePerKilometer: number = null;
    pricePerPage: number = null;
    pricePerPageCopy: number = null;
    useWritingFeesFixedPrice: boolean = null;
    useWritingCopyFees: boolean = null;
    writingFeesFixedPrice: number = null;
    writingCopyFeesFixedPrice: number = null;
    postageAndPhoneFees: number = null;
    reportInvoiceLineItems: LineItem[] = [];
}

export type ReportListSortTypes =
    | 'name'
    | 'licensePlate'
    | 'token'
    | 'carBrand'
    | 'createdAt'
    | 'updatedAt'
    | 'orderDate'
    | 'automaticDate'
    | 'accidentDate'
    | 'firstVisitDate';
export type FeePreferencesKey = 'defaultFees' | 'liabilityHukFees';
export type ThumbnailSizeMobile = 'small' | 'medium' | 'large';
export type ThumbnailSize = 'tiny' | 'verySmall' | ThumbnailSizeMobile | 'veryLarge';

export class DefaultEmailRecipients {
    toRecipients: EmailRecipient[] = [];
    ccRecipients: EmailRecipient[] = [];
    bccRecipients: EmailRecipient[] = [];
}

/**
 * Whether to send documents separately for a given recipient
 */
export class DocumentSeparationSettings {
    claimant: boolean;
    lawyer: boolean;
    insurance: boolean;
    garage: boolean;
    invoiceRecipient: boolean;
    leaseProvider: boolean;
}

export type ReportListAvailableDateRows = 'order' | 'created' | 'firstVisit' | 'accident';

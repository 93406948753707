import { DocumentLayoutConfig, DocumentLayoutGroup } from '@autoixpert/models/documents/document-layout-group';

/**
 * Use this method instead of concatenating the strings directly to ensure finding all occurrences
 * of the ID generation in case
 * - the ID format changes in the future.
 * - you want to see where header and footer templates are used.
 */
export function getHeaderAndFooterTemplateId({
    documentLayoutGroupId,
    documentLayoutConfigId,
}: {
    documentLayoutGroupId: DocumentLayoutGroup['_id'];
    documentLayoutConfigId: DocumentLayoutConfig['_id'];
}): string {
    return `${documentLayoutGroupId}-${documentLayoutConfigId}`;
}

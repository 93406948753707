<mat-form-field class="mat-block" [floatLabel]="'never'">
    <input
        matInput
        placeholder="Bezeichnung der Eigenschaft"
        [(ngModel)]="property.title"
        [disabled]="isReportLocked"
        [matAutocomplete]="propertyTitleAutocomplete"
        (ngModelChange)="filterPropertyTitlesForAutocomplete()"
        (change)="emitChange()" />
</mat-form-field>
<mat-autocomplete #propertyTitleAutocomplete>
    <mat-option *ngFor="let title of filteredPropertyTitlesAutocomplete" [value]="title" (click)="emitChange()">
        {{ title }}
    </mat-option>
</mat-autocomplete>

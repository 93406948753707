import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Subscription } from 'rxjs';
import { getUserFullName } from '@autoixpert/lib/users/get-user-full-name';
import { User } from '@autoixpert/models/user/user';
import { trackById } from '../../libraries/track-by-id';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'user-selection-dropdown',
    templateUrl: './user-selection-dropdown.component.html',
    styleUrls: ['./user-selection-dropdown.component.scss'],
})
export class UserSelectionDropdownComponent implements OnDestroy {
    readonly trackById = trackById;

    constructor(private userService: UserService) {}

    @Input() isDisabled: boolean = false;
    @Input() theme: 'light' | 'dark' = 'light';
    @Input() loggedInUserIsFirstResult: boolean = false;
    @Input() showUserInfoContainer: boolean = true;
    @Input() showDropdownArrow: boolean = true;

    @Input('selectedUser')
    set selectedUserOrUserId(selectedUserOrUserId: User | string) {
        if (typeof selectedUserOrUserId === 'string') {
            this.userService.get(selectedUserOrUserId).then((user) => {
                this.selectedUser = user;
            });
        } else {
            this.selectedUser = selectedUserOrUserId;
        }
    }

    @Output() userSelected = new EventEmitter<User>();

    @ViewChild('userSelectionMenuTrigger') userSelectionMenuTrigger?: MatMenuTrigger;

    protected loggedInUser?: User;
    protected selectedUser?: User;
    protected allUsers: User[] = [];
    protected filteredUsers: User[] = [];

    private subscriptions: Subscription[] = [];

    protected getUserFullName(user: User | undefined): string {
        return getUserFullName(user, 'Nutzer auswählen');
    }

    protected emitUserSelection(user: User) {
        this.closeMenu();
        this.userSelected.emit(user);
    }

    public openMenu() {
        this.userSelectionMenuTrigger?.openMenu();
    }

    public closeMenu() {
        this.userSelectionMenuTrigger?.closeMenu();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

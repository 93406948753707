import { AxError } from '../../models/errors/ax-error';
import { regularExpressionElementToString } from '../strings/regular-expression-element-to-string';

/**
 * For format details, see
 * - https://de.wikipedia.org/wiki/Autoreifen#Reifengr%C3%B6%C3%9Fe,_-Bezeichnung_und_-Markierung
 * - https://www.reifendirekt.de/Reifenbezeichnung.html
 */
export function extractDataFromTireDimension(input: string): TireDimensionData {
    if (!input) {
        throw new AxError({
            code: 'EMPTY_TIRE_DIMENSION_FORMAT',
            message: `Data could not be extracted from the tire dimension since the dimension is empty.`,
            data: {
                input,
            },
        });
    }
    const regExpParts: RegExp[] = [
        // Match the full line.
        /^/,
        // Width in millimeters
        /(\d{3})/,
        // A separator between the width in millimeters and the height in percent of the width.
        /\//,
        // Height in percent of the width.
        /(\d{2})/,
        /**
         * Default is "R" but older tires (~ 1960s) still have a "D".
         * Very fast tires may carry a "V" or a "Z" before the "R" according to Wikipedia.
         */
        /([RD])/,
        /**
         * Rim diameter (German "Felgendurchmesser") in inches. Typical values are between 15 and 22 inches.
         */
        /(\d{2})/,
        /**
         * Load index. According to reifen-direkt.de, possible values range from 19 to 204.
         *
         * Allow this to be optional since the DAT does not always provide this value.
         */
        /(\d{2,3})?/,
        /**
         * Speed index. According to reifen-direkt.de this may contain either one or two letters.
         *
         * Allow this to be optional since the DAT does not always provide this value.
         */
        /(\w{1,2})?/,
        // Match the full line.
        /$/,
    ];

    /**
     * Examples:
     * - 185/65 R 15 85H
     * - 185/65 D 15 85H    <-- Oldtimer tire with old construction type "D" instead of "R"
     */
    const regularExpressionString: string = regExpParts.reduce(
        (regExpString, regExpPart) => regExpString + regularExpressionElementToString(regExpPart),
        '',
    );
    // Remove spaces since there are various common formats that omit or add spaces, e.g. "185/65 R15 85H" or "185/65 R 15 85 H".
    const inputWithoutSpaces: string = input.replace(/ /g, '').toLocaleUpperCase();
    const matches: RegExpMatchArray | null = inputWithoutSpaces.match(new RegExp(regularExpressionString, 'i'));
    /**
     * If the format is anything we can process, make the output look well-structured.
     */
    if (matches) {
        const [, widthInMm, heightInPercentOfWidth, constructionType, rimDiameterInInches, loadIndex, speedIndex] =
            matches;
        return {
            widthInMm,
            heightInPercentOfWidth,
            constructionType,
            rimDiameterInInches,
            loadIndex,
            speedIndex,
        };
    } else {
        throw new AxError({
            code: 'INVALID_TIRE_DIMENSION_FORMAT',
            message: `Data could not be extracted from the tire dimension since the format is invalid.`,
            data: {
                input,
                regularExpressionString,
            },
        });
    }
}

export interface TireDimensionData {
    widthInMm: string;
    heightInPercentOfWidth: string;
    constructionType: string;
    rimDiameterInInches: string;
    loadIndex: string;
    speedIndex: string;
}

/**
 * Returns a new array with all elements being present only once.
 * @param array
 */
export function removeDuplicates<T>(array: T[]): T[] {
    return [
        // The set avoids duplicates.
        ...new Set<T>(array),
    ];
}

import { InvoiceTypeGerman, getInvoiceTypeGerman } from '@autoixpert/lib/invoices/get-invoice-type-german';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { ScreenTitleService } from '../../services/screen-title.service';

export function updateScreenTitleInvoice({
    invoice,
    screenTitleService,
}: {
    invoice: Invoice;
    screenTitleService: ScreenTitleService;
}): void {
    // Shorthands
    const recipient: ContactPerson = invoice.recipient.contactPerson;

    // Relevant variables
    let recipientName: string;
    let screenTitle: string;

    //*****************************************************************************
    //  Recipient Name
    //****************************************************************************/
    if (recipient.organization) {
        recipientName = recipient.organization;
    } else if (recipient.firstName && recipient.lastName) {
        recipientName = `${recipient.firstName} ${recipient.lastName}`;
    } else if (recipient.firstName) {
        // firstName only
        recipientName = recipient.firstName;
    } else if (recipient.lastName) {
        // firstName only
        recipientName = recipient.lastName;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Recipient Name
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Type
    //****************************************************************************/
    const invoiceType: InvoiceTypeGerman = getInvoiceTypeGerman(invoice);
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Type
    /////////////////////////////////////////////////////////////////////////////*/
    if (recipientName) {
        screenTitle = `${invoiceType} an ${recipientName}`;
    } else if (invoice.number) {
        screenTitle = `${invoiceType} Nr. ${invoice.number}`;
    } else {
        screenTitle = `${invoiceType}`;
    }

    screenTitleService.setScreenTitle({
        screenTitle,
        reportToken: invoice.isCollectiveInvoice ? null : invoice.reportsData?.[0]?.token,
        licensePlate: invoice.isCollectiveInvoice ? null : invoice.reportsData?.[0]?.licensePlate,
    });
}

<div class="dialog-overlay" (click)="handleOverlayClick($event)">
    <div class="dialog-container" @fadeInAndSlide>
        <!--============================================-->
        <!-- Calculation Summary -->
        <!--============================================-->
        <div id="calculation-summary-card" class="card dark-card card-with-padding">
            <h2 id="calculation-summary-heading">Zwischenergebnis</h2>
            <!--============================================-->
            <!-- Summary Items -->
            <!--============================================-->
            <div id="summary-item-list">
                <!--********** Spare Parts **********-->
                <div
                    class="summary-item"
                    (click)="setFilter('spareParts')"
                    [ngClass]="{ selected: filter === 'spareParts' }">
                    <div class="summary-item-label">
                        Ersatzteile
                        <span class="label" matTooltip="UPE-Aufschläge" *ngIf="feeSet.sparePartsSurcharge">
                            + {{ feeSet.sparePartsSurcharge }} %
                        </span>
                        <span class="label" matTooltip="Kleinersatzteileaufschlag" *ngIf="feeSet.smallPartsSurcharge">
                            + {{ feeSet.smallPartsSurcharge }}
                            {{ feeSet.smallPartsUnit === 'percent' ? '%' : '€' }}
                        </span>
                    </div>
                    <div class="summary-item-value" matTooltip="{{ sparePartsTotal | number: '1.2-2' }} €">
                        {{ sparePartsTotal | number: '1.0-0' }} €
                    </div>
                </div>

                <!--********** Auxiliary Costs **********-->
                <div
                    class="summary-item"
                    *ngIf="auxiliaryCostsTotal"
                    (click)="setFilter('auxiliaryCosts')"
                    [ngClass]="{ selected: filter === 'auxiliaryCosts' }">
                    <div class="summary-item-label">Nebenkosten</div>
                    <div class="summary-item-value" matTooltip="{{ auxiliaryCostsTotal | number: '1.2-2' }} €">
                        {{ auxiliaryCostsTotal | number: '1.0-0' }} €
                    </div>
                </div>

                <!--********** Labor Costs **********-->
                <div
                    class="summary-item"
                    (click)="setFilter('laborCosts')"
                    [ngClass]="{ selected: filter === 'laborCosts' }">
                    <div class="summary-item-label">Arbeitslohn</div>
                    <div class="summary-item-value" matTooltip="{{ laborCostsTotal | number: '1.2-2' }} €">
                        {{ laborCostsTotal | number: '1.0-0' }} €
                    </div>
                </div>

                <!--********** Paint Labor Costs **********-->
                <div class="summary-item" (click)="setFilter('paint')" [ngClass]="{ selected: filter === 'paint' }">
                    <div class="summary-item-label">Lacklohn</div>
                    <div class="summary-item-value" matTooltip="{{ paintLaborTotal | number: '1.2-2' }} €">
                        {{ paintLaborTotal | number: '1.0-0' }} €
                    </div>
                </div>

                <!--********** Paint Material Costs **********-->
                <div class="summary-item" (click)="setFilter('paint')" [ngClass]="{ selected: filter === 'paint' }">
                    <div class="summary-item-label">
                        Lackmaterial
                        <span class="label" matTooltip="Materialzuschlag">
                            <ng-container [ngSwitch]="feeSet.carPaint.materialSurchargeUnit">
                                <ng-container *ngSwitchCase="'percent'">
                                    {{ paintMaterialSurcharge.value }} %
                                </ng-container>
                                <ng-container *ngSwitchCase="'materialPointsOrUnits'">
                                    {{ paintMaterialSurcharge.value }} MP
                                </ng-container>
                                <ng-container *ngSwitchCase="'flatFee'">
                                    {{ paintMaterialSurcharge.value }} €
                                </ng-container>
                                <ng-container *ngSwitchCase="'materialIndex'">
                                    {{ paintMaterialSurcharge.value }}er Index
                                </ng-container>
                            </ng-container>
                        </span>
                    </div>
                    <div class="summary-item-value" matTooltip="{{ paintMaterialTotal | number: '1.2-2' }} €">
                        {{ paintMaterialTotal | number: '1.0-0' }} €
                    </div>
                </div>

                <!--********** Repair Costs without value increase **********-->
                <div id="repair-costs-without-value-increase" class="summary-item" *ngIf="valueIncreaseTotal">
                    <div class="summary-item-label">Reparaturkosten</div>
                    <div class="summary-item-value" matTooltip="{{ manualCalculationTotalNet | number: '1.2-2' }} €">
                        {{ manualCalculationTotalNet | number: '1.0-0' }} €
                    </div>
                </div>

                <!--********** Value Increase **********-->
                <div
                    class="summary-item"
                    *ngIf="valueIncreaseTotal"
                    (click)="setFilter('valueIncrease')"
                    [ngClass]="{ selected: filter === 'valueIncrease' }">
                    <div class="summary-item-label" [matTooltip]="getValueIncreaseTooltip()">NfA-Abzüge</div>
                    <div class="summary-item-value" matTooltip="{{ valueIncreaseTotal | number: '1.2-2' }} €">
                        {{ valueIncreaseTotal * -1 | number: '1.0-0' }} €
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Summary Items -->
            <!--============================================-->

            <div id="clear-filter-button-container">
                <button class="flat" *ngIf="filter" (click)="setFilter(null)">Filter entfernen</button>
            </div>

            <div id="summary-total-container">
                <div class="label" *ngIf="!valueIncreaseTotal">Reparaturkosten</div>
                <div class="label" *ngIf="valueIncreaseTotal">Korrigierte Reparaturkosten</div>
                <div
                    class="emphasized-number"
                    matTooltip="{{ correctedManualCalculationTotalNet | number: '1.2-2' }} € netto">
                    {{ correctedManualCalculationTotalNet | number: '1.0-0' }} €
                </div>
                <div class="label" matTooltip="{{ correctedManualCalculationTotalGross | number: '1.2-2' }} € brutto">
                    {{ correctedManualCalculationTotalGross | number: '1.0-0' }} €
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Calculation Summary -->
        <!--============================================-->

        <!--============================================-->
        <!-- Manual Calculation -->
        <!--============================================-->
        <div id="manual-calculation-card" class="card card-with-padding">
            <h2 id="manual-calculation-heading">Manuelle Kalkulation</h2>
            <div class="card-toolbar">
                <mat-icon
                    class="toolbar-icon"
                    (click)="showManualCalculationImportDialog()"
                    matTooltip="Zeilen aus CSV-Datei importieren">
                    file_upload
                </mat-icon>
                <mat-icon class="toolbar-icon" (click)="closeManualCalculation()" matTooltip="Schließen">
                    close
                </mat-icon>
            </div>

            <!--============================================-->
            <!-- Head Runner -->
            <!--============================================-->
            <div id="head-runner">
                <date-input
                    id="date-input"
                    [(date)]="report.damageCalculation.repair.manualCalculation.date"
                    [disabled]="isReportLocked()"></date-input>

                <mat-form-field class="search-field mat-block" floatLabel="never">
                    <input
                        matInput
                        [(ngModel)]="searchTerm"
                        placeholder="Suchen"
                        (ngModelChange)="filterCalculationItems()"
                        [matTooltip]="'Beschreibung und Arbeitspaket-/Ersatzteilnummer werden durchsucht.'" />
                    <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>

                <!--============================================-->
                <!-- Wage Unit Selection -->
                <!--============================================-->
                <div id="wage-unit-selection-container">
                    <div id="wage-unit-hours-container">
                        <div
                            id="wage-unit-hours"
                            (click)="selectWorkFractionUnit(1)"
                            [ngClass]="{ active: feeSet.selectedWorkFractionUnit === 1 }"
                            matTooltip="Arbeitslohn pro Stunde">
                            Std
                        </div>
                    </div>
                    <div id="wage-unit-labor-fractions-container">
                        <span class="label">AW:</span>
                        <div
                            class="wage-unit-labor-fraction"
                            (click)="selectWorkFractionUnit(10)"
                            [ngClass]="{ active: feeSet.selectedWorkFractionUnit === 10 }"
                            matTooltip="10er Arbeitswerte">
                            10
                        </div>
                        <div
                            class="wage-unit-labor-fraction"
                            (click)="selectWorkFractionUnit(12)"
                            [ngClass]="{ active: feeSet.selectedWorkFractionUnit === 12 }"
                            matTooltip="12er Arbeitswerte">
                            12
                        </div>
                        <div
                            class="wage-unit-labor-fraction"
                            (click)="selectWorkFractionUnit(100)"
                            [ngClass]="{ active: feeSet.selectedWorkFractionUnit === 100 }"
                            matTooltip="100er Arbeitswerte">
                            100
                        </div>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Wage Unit Selection -->
                <!--============================================-->
            </div>
            <!--============================================-->
            <!-- END Head Runner -->
            <!--============================================-->

            <!--============================================-->
            <!-- Items -->
            <!--============================================-->
            <section id="calculation-item-list">
                <div id="calculation-item-list-header">
                    <div class="calculation-item-description">Beschreibung</div>
                    <div class="calculation-item-repair-code" matTooltip="Reparaturcode">Code</div>
                    <div class="calculation-item-part-number" matTooltip="Arbeitspaket-/Ersatzteilnummer">APN/ETN</div>
                    <div class="calculation-item-quantity">Menge</div>
                    <div class="calculation-item-unit-price">Einzelpreis</div>
                    <div class="calculation-item-value-increase" matTooltip="Neu-für-Alt-Abzug">NfA %</div>
                    <div class="calculation-item-total-price">Gesamtpreis</div>
                </div>
                <cdk-virtual-scroll-viewport
                    class="cdk-virtual-scroll-vertical-only"
                    itemSize="52.25"
                    minBufferPx="1000"
                    maxBufferPx="1000">
                    <div
                        id="calculation-items-container"
                        cdkDropList
                        (cdkDropListDropped)="
                            reorderCalculationItemsArray($event); filterCalculationItems(); saveChanges()
                        "
                        (change)="saveChanges()">
                        <div
                            class="calculation-item"
                            *cdkVirtualFor="
                                let calculationItem of filteredCalculationItems;
                                trackBy: trackById;
                                let index = index;
                                let last = last
                            "
                            cdkDrag
                            [cdkDragDisabled]="!!filter || isReportLocked()">
                            <!--********** Grab Handle **********-->
                            <mat-icon
                                class="calculation-item-grab-handle"
                                matTooltip="Position verschieben"
                                *ngIf="!isReportLocked()"
                                cdkDragHandle
                                (mousedown)="warnAboutDisabledDrag()">
                                drag_indicator
                            </mat-icon>

                            <!--********** Description **********-->
                            <mat-form-field
                                class="calculation-item-description no-ripple"
                                floatLabel="never"
                                [class.disabled-but-focusable]="isReportLocked()"
                                #calculationItemDescriptionFormField>
                                <input
                                    matInput
                                    (focus)="
                                        openDescriptionOverlay({
                                            calculationItem,
                                            matFormField: calculationItemDescriptionFormField,
                                            index,
                                        })
                                    "
                                    (mousedown)="handleMouseDown($event)"
                                    [ngModel]="convertLineFeedsToSpaces(calculationItem.description)"
                                    placeholder="Beschreibung" />
                                <!--********** Multiline indicator **********-->
                                <mat-icon
                                    class="multiline-indicator medium-icon"
                                    *ngIf="(calculationItem.description || '').includes('\n')"
                                    matSuffix
                                    matTooltip="Mehrzeiliges Element. Klicke in das Feld, um alle Zeilen zu sehen.">
                                    unfold_more
                                </mat-icon>
                            </mat-form-field>

                            <!--********** Code **********-->
                            <mat-form-field class="calculation-item-repair-code" floatLabel="never">
                                <mat-select
                                    #repairCodeSelect
                                    [attr.data-id]="index"
                                    [(ngModel)]="calculationItem.repairCode"
                                    (selectionChange)="saveChanges()"
                                    placeholder="Code"
                                    [disabled]="isReportLocked()">
                                    <mat-select-trigger
                                        [matTooltip]="getRepairCodeSelectTriggerTooltip(calculationItem)">
                                        {{ getLetterOfRepairCode(calculationItem.repairCode) }}
                                        <span
                                            class="label-same-size"
                                            *ngIf="calculationItem.repairCode?.startsWith('E-')">
                                            -&nbsp;
                                            <ng-container [ngSwitch]="calculationItem.repairCode">
                                                <ng-container *ngSwitchCase="'E-spareParts'">ET</ng-container>
                                                <ng-container *ngSwitchCase="'E-laborCosts'">Lo</ng-container>
                                                <ng-container *ngSwitchCase="'E-auxiliaryCosts'">Ne</ng-container>
                                            </ng-container>
                                        </span>
                                    </mat-select-trigger>
                                    <mat-option
                                        *ngFor="let repairCodeToCategoryMap of repairCodeToCategoryMaps"
                                        class="compact-option"
                                        [value]="repairCodeToCategoryMap.code"
                                        (onSelectionChange)="
                                            handleCodeSelection($event, calculationItem, repairCodeToCategoryMap)
                                        ">
                                        <div class="autocomplete-option-wrapper">
                                            <div matTooltip="Reparaturcode">
                                                {{ getLetterOfRepairCode(repairCodeToCategoryMap.code) }} -
                                                {{ repairCodeToCategoryMap.label }}
                                            </div>
                                            <div
                                                class="label"
                                                *ngIf="repairCodeToCategoryMap.code?.startsWith('E')"
                                                matTooltip="Abschnitt in der Kalkulation">
                                                {{
                                                    getCalculationCategoryTranslation(repairCodeToCategoryMap.category)
                                                }}
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!--********** Part Number **********-->
                            <mat-form-field class="calculation-item-part-number" floatLabel="never">
                                <input
                                    matInput
                                    [(ngModel)]="calculationItem.partOrWorkItemNumber"
                                    [placeholder]="
                                        calculationItem.category === 'laborCosts' ||
                                        calculationItem.category === 'paint'
                                            ? 'APN'
                                            : 'ETN'
                                    "
                                    [matTooltip]="
                                        calculationItem.category === 'laborCosts' ||
                                        calculationItem.category === 'paint'
                                            ? 'Die Arbeitspaketnummer ist optional'
                                            : 'Ersatzteilnummer'
                                    "
                                    (keydown.enter)="last && addCalculationItemAndSave()"
                                    [disabled]="isReportLocked()" />
                            </mat-form-field>

                            <!--********** Quantity **********-->
                            <mat-form-field class="calculation-item-quantity">
                                <input
                                    matInput
                                    number-input
                                    [(number)]="calculationItem.quantity"
                                    [minimumFractionDigits]="0"
                                    [maximumFractionDigits]="2"
                                    (keydown.enter)="last && addCalculationItemAndSave()"
                                    [disabled]="isReportLocked()" />
                                <span
                                    matSuffix
                                    class="label-same-size"
                                    *ngIf="
                                        !calculationItem.category ||
                                        calculationItem.category === 'spareParts' ||
                                        calculationItem.category === 'auxiliaryCosts'
                                    ">
                                    Stk.
                                </span>
                                <span
                                    matSuffix
                                    class="label-same-size"
                                    *ngIf="
                                        (calculationItem.category === 'laborCosts' ||
                                            calculationItem.category === 'paint') &&
                                        feeSet.selectedWorkFractionUnit === 1
                                    ">
                                    h
                                </span>
                                <span
                                    matSuffix
                                    class="label-same-size"
                                    *ngIf="
                                        (calculationItem.category === 'laborCosts' ||
                                            calculationItem.category === 'paint') &&
                                        feeSet.selectedWorkFractionUnit > 1
                                    ">
                                    AW
                                </span>
                            </mat-form-field>

                            <!--********** Unit Price **********-->
                            <!-- Labor item: Select from garage fees -->
                            <mat-form-field
                                class="calculation-item-unit-price"
                                floatLabel="never"
                                *ngIf="
                                    calculationItem.category === 'laborCosts' || calculationItem.category === 'paint'
                                "
                                matTooltip="{{ getUnitPriceTooltip(calculationItem) }}. Quelle: Werkstattkostensätze">
                                <mat-select
                                    [(ngModel)]="calculationItem.laborType"
                                    placeholder="Lohn"
                                    (selectionChange)="saveChanges()"
                                    [disabled]="isReportLocked()">
                                    <mat-select-trigger class="calculation-item-unit-price-select-trigger">
                                        {{ calculationItem.unitPrice | number: '1.2-2' }} €
                                    </mat-select-trigger>
                                    <mat-option
                                        *ngFor="let garageWageMap of garageWageMaps"
                                        [value]="garageWageMap.laborType"
                                        (onSelectionChange)="
                                            setUnitPrice($event, calculationItem, garageWageMap.hourlyWage)
                                        ">
                                        <div class="autocomplete-option-wrapper">
                                            <div>{{ garageWageMap.label }}</div>
                                            <div class="label" matTooltip="Quelle: Werkstattkostensätze">
                                                {{ garageWageMap.hourlyWage | number: '1.2-2' }} €
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Parts item: Insert price manually -->
                            <div
                                class="calculation-item-unit-price"
                                *ngIf="
                                    !calculationItem.category ||
                                    calculationItem.category === 'spareParts' ||
                                    calculationItem.category === 'auxiliaryCosts'
                                ">
                                <currency-input
                                    placeholder="Einzelpreis"
                                    floatLabel="never"
                                    [(value)]="calculationItem.unitPrice"
                                    (valueChange)="saveChanges()"
                                    (keydown.enter)="last && addCalculationItemAndSave()"
                                    [disabled]="isReportLocked()"></currency-input>
                            </div>

                            <!--********** Value Increase & Total **********-->
                            <!-- Total input shall expand if value increase is not present-->
                            <div class="value-increase-and-total-container">
                                <!--********** Value Increase **********-->
                                <mat-form-field
                                    floatLabel="never"
                                    class="calculation-item-value-increase"
                                    *ngIf="
                                        calculationItem.category === 'spareParts' ||
                                        calculationItem.category === 'paint'
                                    "
                                    [matTooltip]="
                                        calculationItem.category === 'spareParts'
                                            ? ''
                                            : 'Neu-für-Alt-Abzüge sind nur für Ersatzteile möglich.'
                                    ">
                                    <input
                                        matInput
                                        number-input
                                        [(number)]="calculationItem.valueIncreasePercentage"
                                        (numberChange)="saveChanges()"
                                        placeholder="NfA"
                                        [minimumFractionDigits]="0"
                                        [maximumFractionDigits]="2"
                                        (keydown.enter)="last && addCalculationItemAndSave()"
                                        [disabled]="isReportLocked()" />
                                    <span matSuffix>%</span>
                                    <mat-hint class="warning" *ngIf="calculationItem.valueIncreasePercentage > 100">
                                        > 100%
                                    </mat-hint>
                                </mat-form-field>

                                <!--********** Item Total **********-->
                                <div class="calculation-item-total-price">
                                    <currency-input
                                        #totalPriceInput
                                        [attr.data-id]="index"
                                        totalPriceReadonlyHint
                                        placeholder="Gesamtpreis"
                                        floatLabel="never"
                                        [readonly]="true"
                                        (keydown.tab)="
                                            index === filteredCalculationItems.length - 1
                                                ? addCalculationItemAndSave()
                                                : null
                                        "
                                        [value]="getCalculationItemTotal(calculationItem)"></currency-input>
                                </div>
                            </div>

                            <!--********** Delete Icon **********-->
                            <mat-icon
                                class="calculation-item-submenu-icon"
                                *ngIf="!isReportLocked()"
                                [mat-menu-trigger-for]="calculationItemSubmenu">
                                more_vert
                            </mat-icon>
                            <mat-menu #calculationItemSubmenu>
                                <a
                                    mat-menu-item
                                    (click)="
                                        duplicateCalculationItem(calculationItem);
                                        filterCalculationItems();
                                        saveChanges()
                                    ">
                                    Duplizieren
                                </a>
                                <a
                                    mat-menu-item
                                    (click)="
                                        removeCalculationItem(calculationItem); filterCalculationItems(); saveChanges()
                                    ">
                                    Löschen
                                </a>
                            </mat-menu>
                        </div>

                        <!--********** Visible Count **********-->
                        <div
                            id="number-of-visible-calculation-items"
                            class="label"
                            matTooltip="{{ filteredCalculationItems.length }} von {{
                                report.damageCalculation.repair.manualCalculation.calculationItems.length
                            }} Datensätzen sind sichtbar. Der Rest wird durch Filter ausgeblendet.">
                            <ng-container
                                *ngIf="
                                    filteredCalculationItems.length !==
                                    report.damageCalculation.repair.manualCalculation.calculationItems.length
                                ">
                                {{ filteredCalculationItems.length }} von
                                {{ report.damageCalculation.repair.manualCalculation.calculationItems.length }} sichtbar
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    filteredCalculationItems.length ===
                                    report.damageCalculation.repair.manualCalculation.calculationItems.length
                                ">
                                {{ report.damageCalculation.repair.manualCalculation.calculationItems.length }}
                                <ng-container
                                    *ngIf="
                                        report.damageCalculation.repair.manualCalculation.calculationItems.length !== 1
                                    ">
                                    Positionen
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        report.damageCalculation.repair.manualCalculation.calculationItems.length === 1
                                    ">
                                    Position
                                </ng-container>
                            </ng-container>
                        </div>

                        <!--********** New Item **********-->
                        <div id="new-calculation-item-button-container">
                            <button class="flat" (click)="addCalculationItemAndSave()" *ngIf="!isReportLocked()">
                                + Neue Position
                            </button>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </section>
            <!--============================================-->
            <!-- END Items -->
            <!--============================================-->

            <div class="dialog-buttons-container">
                <button [ngClass]="{ disabled: isReportLocked() }" (click)="saveChanges(); closeManualCalculation()">
                    Schließen
                </button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Manual Calculation -->
        <!--============================================-->
    </div>
</div>

<manual-calculation-import-dialog
    *ngIf="manualCaluclationImportDialogShown"
    (parseResult)="insertImportedItems($event); saveChanges()"
    (close)="manualCaluclationImportDialogShown = false"
    @runChildAnimations></manual-calculation-import-dialog>

import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';
import { DataTypeBase } from '../../indexed-db/database.types';
import { CarEquipmentPosition } from './car-equipment';

export class CarEquipmentTemplate implements DataTypeBase {
    constructor(template: Partial<CarEquipmentTemplate> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string = '';
    possibleEquipments: CarEquipmentPosition[] = [];
    builtinEquipments: CarEquipmentPosition[] = [];
    createdBy: string = null;
    teamId: string = null;
    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

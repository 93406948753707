import { CarShape } from '../../models/reports/car-identification/car';
import { Report } from '../../models/reports/report';

/**
 * Does a car shape require a registration? 

 * Some vehicles, e.g. bicycles or boats do not need a car registration.
 * We do not want to display a placeholder for the number token.
 * Vehicles, that need a registration should display the placeholder if no registration is entered,
 * E.g. for a car which was not yet registered, the remark 'nicht zugelassen' is an important information.
 * Since we may add more vehicle types (e.g. digger,...) in the future, we do not want to hardcode the vehicle types in the docx partials..
 */
export function carRequiresRegistration(report: Report): boolean {
    const carShapesWithoutRegistration: CarShape[] = ['bicycle', 'e-bike', 'pedelec'];
    return !carShapesWithoutRegistration.includes(report.car.shape);
}

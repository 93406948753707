import { AxError } from '@autoixpert/models/errors/ax-error';
import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getBankAccountErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        //*****************************************************************************
        //  General
        //****************************************************************************/
        GOCARDLESS_FETCHING_TRANSACTIONS_FAILED: (error: AxError) => ({
            title: 'Fehler beim Bankkontoabgleich',
            body: `In der Schnittstelle zwischen autoiXpert und GoCardless ist ein Fehler aufgetreten. Bitte versuche es später erneut.

Versuche ggf., dein Konto <a href="https://wissen.autoixpert.de/hc/de/articles/360021571039" target="_blank" rel="noopener">neu zu verknüpfen</a>.
Konto: ${error.data.bankAccount.iban}
            
            <strong>Fehlermeldung</strong>: ${(error.causedBy as AxError)?.data?.responseBody.detail}`,
        }),
        GOCARDLESS_CONNECTION_ERROR: (error: AxError) => ({
            title: 'Verbindungsfehler',
            body: `GoCardless konnte die Verbindung zur Bank nicht herstellen. Bitte versuche es erneut.
Konto: ${error.data.bankAccount.iban}
            
            <strong>Fehlermeldung</strong>: ${(error.causedBy as AxError)?.data?.responseBody.detail}`,
        }),
        GOCARDLESS_SERVICE_ERROR: {
            title: 'Allgemeiner Service-Error bei GoCardless',
            body: 'Probiere es erneut. Falls das Problem weiterhin besteht, melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        GOCARDLESS_UNKNOWN_REQUEST_ERROR: {
            title: 'Unbekannter Request-Error bei GoCardless',
            body: 'Probiere es erneut. Falls das Problem weiterhin besteht, melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
        },
        GOCARDLESS_RATE_LIMIT_EXCEEDED: (error: AxError) => ({
            title: 'Anfrage-Limit erreicht',
            body: `GoCardless hat den Request abgelehnt, weil das Anfrage-Limit erreicht wurde.
Konto: ${error.data.bankAccount.iban}
            
            Bitte probiere es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END General
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Account Issues
        //****************************************************************************/
        GOCARDLESS_ACCOUNT_PROCESSING: (error: AxError) => ({
            title: 'GoCardless verarbeitet das Konto',
            body: `Bitte versuche es später erneut.
Konto: ${error.data.bankAccount.iban}.`,
        }),
        GOCARDLESS_ACCOUNT_SUSPENDED: (error: AxError) => ({
            title: 'GoCardless-Account suspendiert',
            body: `Entweder dein Bankkonto oder der Schnittstellen-Account von GoCardless wurde von GoCardless suspendiert.
Konto: ${error.data.bankAccount.iban}.

            Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        GOCARDLESS_ACCOUNT_INACTIVE: (error: AxError) => ({
            title: 'GoCardless-Account inaktiv',
            body: `Entweder dein Bankkonto oder der Schnittstellen-Account von GoCardless gilt bei GoCardless als inaktiv.
Konto: ${error.data.bankAccount.iban}.

            Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        GOCARDLESS_INVALID_ACCOUNT_ID: (error: AxError) => ({
            title: 'GoCardless-Account-ID ungültig',
            body: `Entweder dein Bankkonto oder der Schnittstellen-Account von GoCardless gelten bei GoCardless als ungültig.
Konto: ${error.data.bankAccount.iban}.

            Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        GOCARDLESS_ACCOUNT_ACCESS_FORBIDDEN: (error: AxError) => ({
            title: 'GoCardless verweigert Zugriff',
            body: `Der GoCardless-User hat keinen Zugriff, dieses Konto zu verarbeiten.
Konto: ${error.data.bankAccount.iban}.

            Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Account Issues
        /////////////////////////////////////////////////////////////////////////////*/

        NO_BANK_ACCOUNT_CONNECTED: {
            title: 'Kein Bankkonto verknüpft',
            body: 'Bitte verknüpfe erst ein Bankkonto über das Dreipunktmenü oben rechts in der Rechnungsliste.',
        },

        //*****************************************************************************
        //  Consent & Token
        //****************************************************************************/
        CONSENT_EXPIRED: (error) => ({
            title: 'Autorisierung für aX abgelaufen',
            body: `Bitte <a href="https://wissen.autoixpert.de/hc/de/articles/360021571039" target="_blank" rel="noopener">verknüpfe dein Konto ${error.data?.bankAccount.iban} erneut.</a>`,
        }),
        GOCARDLESS_ACCESS_EXPIRED: (error) => ({
            title: 'Autorisierung für aX abgelaufen',
            body: `Bitte <a href="https://wissen.autoixpert.de/hc/de/articles/360021571039" target="_blank" rel="noopener">verknüpfe dein Konto ${error.data?.bankAccount.iban} erneut.</a>`,
        }),
        GOCARDLESS_INVALID_TOKEN: (error) => ({
            title: 'Ungültiger GoCardless-Token',
            body: `Bitte <a href="https://wissen.autoixpert.de/hc/de/articles/360021571039" target="_blank" rel="noopener">verknüpfe dein Konto ${error.data?.bankAccount.iban} erneut.</a>`,
        }),

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Consent & Token
        /////////////////////////////////////////////////////////////////////////////*/
    };
}

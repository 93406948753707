import { generateId } from '../../lib/generate-id';

export class OfficeLocation {
    constructor(tmpl: Partial<OfficeLocation> = {}) {
        Object.assign(this, tmpl);
    }

    _id: string = generateId();
    title: string;
    streetAndHouseNumber: string;
    zip: string;
    city: string;
    // When this data is merged into the user's contact data, the user's data takes precedence. These properties allow for a central phone number for each office location, given the user does not have a phone number himself.
    phone: string;
    phone2: string;
    createdBy: string;
    // The initials may be used to include an office location in the invoice number or report token.
    // Customers may enter an abbreviation for the location or an internal ID
    initials: string;
    /**
     * Cost centers are an important group mechanism for accounting analytics in larger companies.
     * A office location in autoixpert may be assigned to a cost center.
     * Multiple office locations may be assigned to the same cost center.
     */
    costCenter: string = null;
}

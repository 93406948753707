import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { formatTireDimension } from '@autoixpert/lib/tires/format-tire-dimension';
import { getAllTiresOfVehicle } from '@autoixpert/lib/tires/get-all-tires-of-vehicle';
import { getAllTiresOnAxle } from '@autoixpert/lib/tires/get-all-tires-on-axle';
import { getAxleOfTire } from '@autoixpert/lib/tires/get-axle-of-tire';
import { isTireInfoComplete } from '@autoixpert/lib/tires/is-tire-info-complete';
import { isTireInfoPartiallyComplete } from '@autoixpert/lib/tires/is-tire-info-partially-complete';
import { Axle } from '@autoixpert/models/reports/car-identification/axle';
import { Car, CarShape } from '@autoixpert/models/reports/car-identification/car';
import { SecondTire, Tire, TireSeason } from '@autoixpert/models/reports/car-identification/tire';
import { fadeInAndOutAnimation } from '../../../../shared/animations/fade-in-and-out.animation';
import { triggerClickEventOnSpaceBarPress } from '../../../../shared/libraries/trigger-click-event-on-space-bar-press';
import { ToastService } from '../../../../shared/services/toast.service';
import { tireManufacturers } from '../../../../shared/static-data/tire-manufacturers';

@Component({
    selector: 'tires-editor',
    templateUrl: 'tires-editor.component.html',
    styleUrls: ['tires-editor.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class TiresEditorComponent implements OnInit, OnChanges {
    constructor(
        private toastService: ToastService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    @Input() car: Car = new Car();

    @Input() set selectedTireRef(tire: Tire) {
        this._selectedTireRef = tire;
        if (!tire) return;
        this.selectAxle(getAxleOfTire(this.car.axles, tire));
    }

    /**
     * Observable that allows the parent component to trigger a re-initialization of the axles and tires in this component.
     *
     * Parents cannot trigger methods in child components except through observables or services.
     */
    @Input() initializeAxles$: Subject<void>;

    @Input() disabled: boolean;

    @Output() tireChange: EventEmitter<Tire> = new EventEmitter<Tire>();
    @Output() tireSelect: EventEmitter<Tire> = new EventEmitter<Tire>();
    @Output() copyToAll: EventEmitter<Tire> = new EventEmitter<Tire>();
    @Output() copyOnAxle: EventEmitter<Tire> = new EventEmitter<Tire>();
    /**
     * Used by the CarCondition component to display the axle sketch after the user
     * selected a new tire by means of the left/right switches.
     *
     * We don't use the tireSelect emitter because that also fires on initialization,
     * causing the parent to initially display the axle sketch.
     */
    @Output() axleSwitchesUsed: EventEmitter<void> = new EventEmitter<void>();
    @Output() tireTabsUsed: EventEmitter<void> = new EventEmitter<void>();

    public _selectedTireRef: Tire;
    public tires: Tire[] = [];
    public tiresOnSelectedAxle: Tire[] = [];
    public selectedAxle: Axle;
    public highlightedTires: Map<Tire, boolean> = new Map<Tire, boolean>();
    public tireManufacturers: string[] = tireManufacturers;
    public filteredTireManufacturers: string[];

    // Properties related to tire comment.
    public tireCommentShown: boolean = false;

    // Custom tire type (season)
    protected customTireTypeShown: boolean = false;

    public copiedToAllTiresIconShown: boolean;
    public copiedToAllTiresOnAxleIconShown: boolean;

    private subscriptions: Subscription[] = [];

    public secondTireSetSelected = false;

    ngOnInit() {
        if (this.initializeAxles$) {
            const subscription = this.initializeAxles$.subscribe({
                next: () => {
                    if (!this.car) return;

                    this.setupTiresFromAxles();
                    // Refresh tires on axle and select first available tire
                    this.filterTiresOnCurrentAxle();
                    if (!this.tiresOnSelectedAxle.includes(this._selectedTireRef)) {
                        this.selectTire(this.tiresOnSelectedAxle[0]);
                    }
                },
            });

            this.subscriptions.push(subscription);
        }
    }

    ngDoCheck() {
        // Unselect the second tire set if it is not available
        // Avoid reference errors in change detection
        this.secondTireSetSelected = this.car.hasSecondTireSet && this.secondTireSetSelected;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['car']) {
            /**
             * In case the user selected a "vehicle" without axles, e. g. a "Tankstellen-Verkaufsmodul",
             * don't crash the application but handle the case gracefully.
             *
             * See Slack-Thread: https://autoixpert.slack.com/archives/CB1U7VBBP/p1729112086965459
             */
            if (!this.car.axles?.length) {
                return;
            }

            this.setupTiresFromAxles();
            this.selectAxle(this.car.axles[0]);
            this.selectFirstTire();
            this.preSelectTireSeason();
        }
    }

    get selectedTireData(): Tire | SecondTire {
        if (!this._selectedTireRef) return null;
        return this.secondTireSetSelected ? this._selectedTireRef.secondTireSet : this._selectedTireRef;
    }

    get carShapeHasSeasonalTires(): boolean {
        const carShapesWithoutSeasonalTires: CarShape[] = ['bicycle', 'e-bike', 'pedelec'];
        return !carShapesWithoutSeasonalTires.includes(this.car.shape);
    }

    //*****************************************************************************
    //  Get Tires from Axles
    //****************************************************************************/
    public setupTiresFromAxles(): void {
        this.tires = getAllTiresOfVehicle(this.car.axles);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get Tires from Axles
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tire State
    //****************************************************************************/
    public isTireInfoPartiallyFilled(tire: Tire): boolean {
        return isTireInfoPartiallyComplete(this.secondTireSetSelected ? tire.secondTireSet : tire);
    }

    public isTireInfoComplete(tire: Tire): boolean {
        return isTireInfoComplete(this.secondTireSetSelected ? tire.secondTireSet : tire);
    }

    public isFirstTireSetPartiallyFilled(): boolean {
        return getAllTiresOfVehicle(this.car.axles).some((tire) => isTireInfoPartiallyComplete(tire));
    }

    public isFirstTireSetComplete(): boolean {
        return getAllTiresOfVehicle(this.car.axles).every((tire) => isTireInfoComplete(tire));
    }

    public isSecondTireSetPartiallyFilled(): boolean {
        return getAllTiresOfVehicle(this.car.axles).some((tire) => isTireInfoPartiallyComplete(tire.secondTireSet));
    }

    public isSecondTireSetComplete(): boolean {
        return getAllTiresOfVehicle(this.car.axles).every((tire) => isTireInfoComplete(tire.secondTireSet));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tire State
    /////////////////////////////////////////////////////////////////////////////*/

    public translateTirePosition(tire: Tire): { label: string; tooltip: string } {
        // Motorcycle
        if (tire.position === 'center') {
            if (tire.axle === 1)
                return {
                    label: 'Vorne',
                    tooltip: null,
                };
            if (tire.axle === 2)
                return {
                    label: 'Hinten',
                    tooltip: null,
                };
        }

        // Regular 4-tire cars
        if (this.tires.length === 4) {
            if (tire.axle === 1) {
                if (tire.position === 'left')
                    return {
                        label: 'VL',
                        tooltip: 'Vorne links',
                    };
                if (tire.position === 'right')
                    return {
                        label: 'VR',
                        tooltip: 'Vorne rechts',
                    };
            }
            if (tire.axle === 2) {
                if (tire.position === 'left')
                    return {
                        label: 'HL',
                        tooltip: 'Hinten links',
                    };
                if (tire.position === 'right')
                    return {
                        label: 'HR',
                        tooltip: 'Hinten rechts',
                    };
            }
        }

        // Dynamic number of axles
        const axle: Axle = this.selectedAxle;
        switch (tire.position) {
            case 'left':
                return axle.outerLeftTire
                    ? {
                          label: 'LI',
                          tooltip: 'Links innen',
                      }
                    : {
                          label: 'Li',
                          tooltip: 'Links',
                      };
            case 'right':
                return axle.outerRightTire
                    ? {
                          label: 'RI',
                          tooltip: 'Rechts innen',
                      }
                    : {
                          label: 'Re',
                          tooltip: 'Rechts',
                      };
            case 'outerLeft':
                return {
                    label: 'LA',
                    tooltip: 'Links außen',
                };
            case 'outerRight':
                return {
                    label: 'RA',
                    tooltip: 'Rechts außen',
                };
            case 'center':
                // This case should never happen
                return {
                    label: 'Mi',
                    tooltip: 'Mittig',
                };
        }
    }

    //*****************************************************************************
    //  Axle Selection
    //****************************************************************************/
    /**
     * Axle selection only applies if there are more than four axles.
     *
     * If there are less than four, we use the simple selector which lets the user
     * select tires across all axles directly.
     */
    public selectAxle(axle: Axle): void {
        this.selectedAxle = axle;
        this.filterTiresOnCurrentAxle();
    }

    public selectFirstTireOnAxle(): void {
        this.selectTire(this.tiresOnSelectedAxle[0]);
    }

    public filterTiresOnCurrentAxle(): void {
        this.tiresOnSelectedAxle = getAllTiresOnAxle(this.selectedAxle);
    }

    public selectAxleByOffset(offset: -1 | 1): void {
        const index = this.car.axles.indexOf(this.selectedAxle);
        const futureAxle: Axle = this.car.axles[index + offset];

        if (futureAxle) {
            this.selectAxle(futureAxle);
            this.selectFirstTireOnAxle();
        } else {
            // Add one to the index to translate from zero-based to one-based (human-readable)
            this.toastService.warn('Auswahl nicht möglich', `Achse ${index + 1 + offset} nicht verfügbar.`);
        }
    }

    public isPreviousAxleAvailable(): boolean {
        return this.car.axles.indexOf(this.selectedAxle) > 0;
    }

    public isNextAxleAvailable(): boolean {
        return this.car.axles.indexOf(this.selectedAxle) < this.car.axles.length - 1;
    }

    public getIndexOfAxle(axle: Axle): number {
        return this.car.axles.indexOf(axle);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Axle Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tire Set Selection
    //****************************************************************************/
    public selectTireSet(tireSet: 'first' | 'second'): void {
        this.secondTireSetSelected = tireSet === 'second';
        // Reselect the tire to update the ui, e.g. whether tire comment or tire type is shown
        this.selectTire(this._selectedTireRef);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tire Set Selection
    /////////////////////////////////////////////////////////////////////////////*/
    //*****************************************************************************
    //  Tire Selection
    //****************************************************************************/
    public selectTire(tire: Tire) {
        this._selectedTireRef = tire;

        // Show the comment field if it's hidden but there is a comment for the selected tire. Don't hide it
        // if there is no comment since usually, an assessor comments all tires or none.
        if (!this.tireCommentShown && this.selectedTireData.comment) {
            this.tireCommentShown = true;
        }

        // Same principle as above, show the custom tire type input, but don't hide it
        if (!this.customTireTypeShown && this.selectedTireData.customType) {
            this.customTireTypeShown = true;
        }

        this.emitTireSelect();
    }

    public selectFirstTire(): void {
        /**
         * Select top and left-most tire
         */
        const leftmostTireOnFirstAxle: Tire =
            this.car.axles[0].outerLeftTire || this.car.axles[0].leftTire || this.car.axles[0].centerTire;
        this.selectTire(leftmostTireOnFirstAxle);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tire Selection
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tire Comment
    //****************************************************************************/

    public toggleTireComment(): void {
        this.tireCommentShown = !this.tireCommentShown;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tire Comment
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tire Manufacturer
    //****************************************************************************/
    public filterTireManufacturerAutocomplete(searchTerm: string): void {
        if (!searchTerm) {
            this.filteredTireManufacturers = [...this.tireManufacturers];
            return;
        }

        searchTerm = searchTerm.toLowerCase();

        this.filteredTireManufacturers = this.tireManufacturers.filter((element: string) => {
            // Make the search case-insensitive
            element = (element || '').toLowerCase();

            // Search only at the beginning of each word inside the searched element
            return element.search('\\b' + searchTerm) > -1;
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tire Manufacturer
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Season
    //****************************************************************************/
    public selectTireSeason(season: TireSeason): void {
        // Select
        if (this.selectedTireData.season !== season) {
            this.selectedTireData.season = season;
        }
        // De-select
        else {
            this.selectedTireData.season = null;
        }

        // Hide and clear the custom season
        this.customTireTypeShown = false;
        this.selectedTireData.customType = null;

        this.emitTireChange();
    }

    /**
     * User wants to enter a custom tire type.
     */
    protected selectCustomTireType(): void {
        this.selectedTireData.season = 'custom';
        this.emitTireChange();
    }

    /**
     * If it's summer time (Apr - Sep), pre-select summer tiresForUI.
     * If it's winter time (Oct - Mar), pre-select winter tiresForUI.
     */
    public preSelectTireSeason() {
        if (!this.car) return;

        if (!this.carShapeHasSeasonalTires) return;

        // Add one so that the month number returned by getMonth() correlates to the human-readable month number.
        // Ex.: getMonth() returns 0 for January.
        const currentMonth = moment().month() + 1;

        let tiresWereChanged = false;
        getAllTiresOfVehicle(this.car.axles).forEach((tire) => {
            // Only set a preference if the tire Type has not been set yet.
            if (tire.season === null) {
                tire.season = currentMonth <= 3 || currentMonth >= 10 ? 'winter' : 'summer';
                tiresWereChanged = true;
            }
        });
        if (tiresWereChanged) {
            this.emitTireChange();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Season
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Copy tire info
    //****************************************************************************/
    public copyValuesToAllTires(): void {
        getAllTiresOfVehicle(this.car.axles).forEach((tire) => {
            this.copyTireInfo(this._selectedTireRef, tire);
            this.highlightTire(tire);
        });

        // Show checkmark in interface
        this.copiedToAllTiresIconShown = true;
        window.setTimeout(() => (this.copiedToAllTiresIconShown = false), 1000);

        this.emitCopyToAll();
        this.emitTireChange();
    }

    public copyValuesToAllTiresOnAxle(): void {
        // Bounce the copyTargets. There is no need to bounce the active tire; it's already expanded since it is selected.
        getAllTiresOnAxle(this.car.axles[this._selectedTireRef.axle - 1]).forEach((tireOnSameAxle) => {
            this.copyTireInfo(this._selectedTireRef, tireOnSameAxle);
            this.highlightTire(tireOnSameAxle);
        });

        // Show checkmark in interface
        this.copiedToAllTiresOnAxleIconShown = true;
        window.setTimeout(() => (this.copiedToAllTiresOnAxleIconShown = false), 1000);

        this.emitCopyOnAxle();
        this.emitTireChange();
    }

    public highlightTire(tire: Tire): void {
        this.highlightedTires.set(tire, true);
        setTimeout(() => this.highlightedTires.delete(tire), 400);
    }

    /**
     * Only show the button if there are more than one tire on the axle (-> Motorcycle)
     * @returns {boolean}
     */
    public showCopyButtonForSameAxle(): boolean {
        return getAllTiresOnAxle(this.car.axles[this._selectedTireRef.axle - 1]).length > 1;
    }

    /**
     * Copy the user-configurable properties from one tire to another
     * @param {Tire} source
     * @param {Tire} target
     */
    private copyTireInfo(source: Tire, target: Tire): void {
        if (this.secondTireSetSelected) {
            target.secondTireSet.type = source.secondTireSet.type;
            target.secondTireSet.treadInMm = source.secondTireSet.treadInMm;
            target.secondTireSet.manufacturer = source.secondTireSet.manufacturer;
            target.secondTireSet.season = source.secondTireSet.season;
            target.secondTireSet.customType = source.secondTireSet.customType;
        } else {
            target.type = source.type;
            target.treadInMm = source.treadInMm;
            target.manufacturer = source.manufacturer;
            target.season = source.season;
            target.customType = source.customType;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy tire info
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    protected navigateToCarDataComponent() {
        this.router.navigate(['../Fahrzeug'], { relativeTo: this.route });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Events
    //****************************************************************************/
    public triggerClickEventOnSpaceBarPress = triggerClickEventOnSpaceBarPress;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tire Format Dimension
    //****************************************************************************/
    public formatTireDimension() {
        this.selectedTireData.type = formatTireDimension(this.selectedTireData.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tire Format Dimension
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitTireChange(): void {
        this.tireChange.emit(this._selectedTireRef);
    }

    public emitTireSelect(): void {
        this.tireSelect.emit(this._selectedTireRef);
    }

    public emitAxleSwitchesUsed(): void {
        this.axleSwitchesUsed.emit();
    }

    public emitTireTabsUsed(): void {
        this.tireTabsUsed.emit();
    }

    public emitCopyToAll(): void {
        this.copyToAll.emit(this._selectedTireRef);
    }

    public emitCopyOnAxle(): void {
        this.copyOnAxle.emit(this._selectedTireRef);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

<div class="dialog-overlay">
    <div class="dialog-container" @blockChildAnimationOnLoad>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <h2 id="special-costs-heading">
            Sonderkosten
            <span
                class="help-indicator"
                [matTooltip]="
                    'Eingaben optional.\n\nRelevant für Totalschäden, wenn das beschädigte Fahrzeug durch ein vergleichbares ersetzt wird.\n\nSonderkosten werden u. a. in der Zusammenfassung des Gutachtens abgedruckt.'
                ">
                ?
            </span>
        </h2>

        <!--============================================-->
        <!-- Remaining Fuel -->
        <!-- currently not relevant for battery electric vehicles-->
        <!--============================================-->
        <section *ngIf="getMainFuelType() !== 'electric'" id="remaining-fuel-section" class="costs-section">
            <div class="section-icon-container" [ngSwitch]="getMainFuelType()">
                <img class="section-icon" *ngSwitchCase="'gasoline'" src="/assets/images/icons/gasoline_48.png" />
                <img class="section-icon" *ngSwitchCase="'diesel'" src="/assets/images/icons/diesel_48.png" />
                <img class="section-icon" *ngSwitchCase="'autogas'" src="/assets/images/icons/autogas_48.png" />
                <img class="section-icon" *ngSwitchCase="'methane'" src="/assets/images/icons/methane_48.png" />
                <img class="section-icon" *ngSwitchCase="'electric'" src="/assets/images/icons/electric_48.png" />
                <img class="section-icon" *ngSwitchCase="'biodiesel'" src="/assets/images/icons/biodiesel_48.png" />
                <img class="section-icon" *ngSwitchCase="'hydrogen'" src="/assets/images/icons/hydrogen_48.png" />
                <img class="section-icon" *ngSwitchDefault alt="" src="/assets/images/icons/fuel.svg" />
            </div>

            <div class="section-heading-container">
                <h3
                    class="section-heading"
                    matTooltip="Menge & Wert des Kraftstoffs im Tank des Fahrzeugs. Relevant für Totalschäden, nicht jedoch für die Reparatur.">
                    Restkraftstoff
                </h3>
                <div class="label" [ngSwitch]="getMainFuelType()" *ngIf="getMainFuelType()">
                    <ng-container *ngSwitchCase="'gasoline'">Benzin</ng-container>
                    <ng-container *ngSwitchCase="'diesel'">Diesel</ng-container>
                    <ng-container *ngSwitchCase="'autogas'">LPG</ng-container>
                    <ng-container *ngSwitchCase="'methane'">Erdgas</ng-container>
                    <ng-container *ngSwitchCase="'electric'">Elektro</ng-container>
                    <ng-container *ngSwitchCase="'biodiesel'">Bio-Diesel</ng-container>
                    <ng-container *ngSwitchCase="'hydrogen'">Wasserstoff</ng-container>
                    <ng-container *ngSwitchDefault>{{ report.car.runsOnSomethingElse }}</ng-container>
                </div>
            </div>

            <ng-container *ngIf="!roundingEditModeActive">
                <!--============================================-->
                <!-- Additional Inputs -->
                <!--============================================-->
                <div class="additional-inputs-container">
                    <!--********** Amount **********-->
                    <mat-form-field id="remaining-fuel-amount">
                        <input
                            matInput
                            number-input
                            placeholder="Menge"
                            [(number)]="report.damageCalculation.remainingFuel.amount"
                            [minimumFractionDigits]="0"
                            [maximumFractionDigits]="1"
                            (numberChange)="calculateTotalFuelCosts(); emitReportChange()"
                            [disabled]="report.state === 'done'" />
                        <span matSuffix>Liter</span>
                    </mat-form-field>

                    <!--********** Price per Liter **********-->
                    <currency-input
                        id="remaining-fuel-price-per-liter"
                        [(value)]="report.damageCalculation.remainingFuel.pricePerLiter"
                        (valueChange)="calculateTotalFuelCosts(); emitReportChange()"
                        placeholder="Preis pro Liter"
                        [disabled]="report.state === 'done'"></currency-input>
                </div>

                <!--============================================-->
                <!-- END Additional Inputs -->
                <!--============================================-->

                <div class="total-input-container">
                    <!--********** Estimated Costs **********-->
                    <div id="estimated-costs-container">
                        <currency-input
                            placeholder="Geschätzte Kosten"
                            [(value)]="report.damageCalculation.remainingFuel.costs"
                            (valueChange)="emitReportChange()"
                            matTooltip="Berechnet: {{ report.damageCalculation.remainingFuel.amount }} l * {{
                                report.damageCalculation.remainingFuel.pricePerLiter | number: '1.2-2'
                            }} € pro l = {{
                                report.damageCalculation.remainingFuel.amount *
                                    report.damageCalculation.remainingFuel.pricePerLiter | number: '1.2-2'
                            }} €"
                            [hint]="getFuelCostsHint()"
                            [disabled]="report.state === 'done'"></currency-input>
                        <mat-icon
                            id="calculate-estimated-costs-icon"
                            class="small-icon toolbar-icon"
                            (click)="calculateTotalFuelCosts(true)"
                            [matTooltip]="
                                userPreferences.remainingFuelRoundingAmount ? 'Neu berechnen & runden' : 'Neu berechnen'
                            ">
                            refresh
                        </mat-icon>
                    </div>
                </div>
            </ng-container>

            <!--============================================-->
            <!-- Rounding Controls -->
            <!--============================================-->
            <!--********** Edit Mode **********-->
            <div id="rounding-edit-mode-section" *ngIf="roundingEditModeActive">
                <div id="rounding-edit-mode-container">
                    auf volle
                    <currency-input
                        id="rounding-amount-input"
                        [(value)]="userPreferences.remainingFuelRoundingAmount"
                        placeholder="Betrag"
                        floatLabel="never"></currency-input>
                    <mat-form-field id="rounding-direction-select">
                        <mat-select [(ngModel)]="userPreferences.remainingFuelRoundingDirection">
                            <mat-option value="up">aufrunden</mat-option>
                            <mat-option value="down">abrunden</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-icon class="toolbar-icon" (click)="leaveRoundingEditMode()" matTooltip="Bearbeitung beenden">
                        check
                    </mat-icon>
                    <mat-icon
                        class="toolbar-icon"
                        (click)="removeRoundingAmount(); leaveRoundingEditMode()"
                        matTooltip="Keine Rundung">
                        delete
                    </mat-icon>
                </div>
            </div>
            <!--============================================-->
            <!-- END Rounding Controls -->
            <!--============================================-->
            <!--********** Submenu **********-->
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="remaingFuelSubmenu">more_vert</mat-icon>
            <mat-menu #remaingFuelSubmenu>
                <mat-option (click)="rememberPricePerLiter()" *ngIf="getMainFuelType() === 'gasoline'">
                    Preis pro Liter Benzin merken
                </mat-option>
                <mat-option (click)="rememberPricePerLiter()" *ngIf="getMainFuelType() === 'diesel'">
                    Preis pro Liter Diesel merken
                </mat-option>
                <mat-option
                    [disabled]="isReportLocked(report)"
                    *ngIf="!roundingEditModeActive"
                    (click)="!isReportLocked(report) && enterRoundingEditMode()">
                    Rundung einstellen
                </mat-option>
                <mat-option *ngIf="roundingEditModeActive" (click)="leaveRoundingEditMode()">
                    Rundungseinstellung verlassen
                </mat-option>
            </mat-menu>
        </section>
        <!--============================================-->
        <!-- END Remaining Fuel -->
        <!--============================================-->

        <!--============================================-->
        <!-- Retrofit -->
        <!--============================================-->
        <section class="costs-section" *ngIf="report.damageCalculation.retrofit">
            <div id="retrofit-heading-icon" class="section-icon-container">
                <mat-icon class="section-icon">build</mat-icon>
            </div>

            <!--********** Heading **********-->
            <div id="retrofit-heading" class="section-heading-container">
                <h3
                    class="section-heading"
                    matTooltip="Ausbau wertvoller, ggf. nachgerüsteter Geräte, z. B. Stereoanlage oder Regalsysteme.">
                    Umbau
                </h3>
            </div>

            <!--********** List **********-->
            <div class="retrofit-container">
                <div *ngFor="let retrofit of report.damageCalculation.retrofit.items" class="retrofit-item-container">
                    <div class="retrofit-input-container">
                        <div class="retrofit-title-container">
                            <mat-form-field id="retrofit-title">
                                <mat-quill
                                    placeholder="Umbau"
                                    [(ngModel)]="retrofit.description"
                                    (change)="emitReportChange()"
                                    [disabled]="report.state === 'done'"></mat-quill>
                            </mat-form-field>
                        </div>
                        <div id="retrofit-cost">
                            <currency-input
                                [(value)]="retrofit.costsGross"
                                (valueChange)="emitReportChange()"
                                placeholder="Kosten"
                                [disabled]="report.state === 'done'"
                                matTooltip="Brutto"></currency-input>
                        </div>

                        <!--********** Remove Icon **********-->
                        <mat-icon
                            *ngIf="!isReportLocked(report)"
                            class="retrofit-delete-icon"
                            (click)="removeRetrofitItem(retrofit)">
                            delete
                        </mat-icon>
                    </div>
                </div>

                <div id="retrofit__add-button-and-sum-container">
                    <!--********** Add retrofit item **********-->
                    <div id="retrofit-add-button">
                        <button
                            class="flat"
                            (click)="addRetrofitItem(); emitReportChange()"
                            [disabled]="isReportLocked(report)">
                            <mat-icon class="small-icon">add</mat-icon>
                            Umbau hinzufügen
                        </button>
                    </div>

                    <!--********** Sum **********-->
                    <div
                        id="retrofit-total-container"
                        *ngIf="report.damageCalculation.retrofit.items.length > 1"
                        @slideInAndOutVertically>
                        <div class="label" matTooltip="Summe aller Umbaukosten (brutto)">
                            Σ {{ getTotalRetrofitCosts() || 0 | number: '1.2-2' }} €
                        </div>
                        <mat-icon
                            id="retrofit-comment-icon"
                            class="outlined"
                            *ngIf="!isReportLocked(report)"
                            (click)="toggleRetrofitComment()"
                            matTooltip="Kommentar einfügen">
                            chat_bubble
                        </mat-icon>
                    </div>
                </div>

                <!--********** Comment **********-->
                <div
                    id="retrofit-comment-container"
                    *ngIf="retrofitCommentShown || report.damageCalculation.retrofit.comment"
                    @slideInAndOutVertically>
                    <mat-form-field class="mat-block">
                        <mat-quill
                            id="retrofit-comment"
                            [(ngModel)]="report.damageCalculation.retrofit.comment"
                            (change)="emitReportChange()"
                            placeholder="Kommentar"
                            [disabled]="report.state === 'done'"></mat-quill>

                        <div matSuffix *ngIf="!isReportLocked(report)" class="align-center">
                            <mat-icon
                                class="input-toolbar-icon"
                                (click)="retrofitCommentTextTemplatesShown = true"
                                matTooltip="Textvorlagen">
                                playlist_add
                            </mat-icon>
                        </div>
                    </mat-form-field>
                </div>
            </div>
        </section>
        <!--============================================-->
        <!-- END Retrofit -->
        <!--============================================-->

        <!--============================================-->
        <!-- Disposal -->
        <!--============================================-->
        <section class="costs-section">
            <div class="section-icon-container">
                <mat-icon class="section-icon">delete</mat-icon>
            </div>

            <div class="section-heading-container">
                <h3
                    class="section-heading"
                    matTooltip="Kosten für die Entsorgung eines Fahrzeugs, falls kein Käufer für das Schadenfahrzeug gefunden wurde.">
                    Entsorgung
                </h3>
            </div>

            <div class="additional-inputs-container"></div>

            <div class="total-input-container">
                <currency-input
                    [(value)]="report.damageCalculation.disposalCosts"
                    (valueChange)="emitReportChange()"
                    placeholder="Entsorgung"
                    [disabled]="report.state === 'done'"></currency-input>
            </div>
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="disposalSubmenu">more_vert</mat-icon>
            <mat-menu #disposalSubmenu>
                <mat-option (click)="rememberDisposalCosts()">Entsorgungskosten merken</mat-option>
            </mat-menu>
        </section>
        <!--============================================-->
        <!-- END Disposal -->
        <!--============================================-->

        <!--============================================-->
        <!-- Deregistration & Registration -->
        <!--============================================-->
        <section class="costs-section">
            <div class="section-icon-container">
                <mat-icon class="section-icon">sync</mat-icon>
            </div>

            <div class="section-heading-container">
                <h3
                    class="section-heading"
                    matTooltip="Kosten für die Abmeldung des Schadenfahrzeugs und Anmeldung eines Ersatzfahrzeugs.">
                    Ab- & Anmeldung
                </h3>
            </div>

            <div class="additional-inputs-container"></div>

            <div class="total-input-container">
                <currency-input
                    [(value)]="report.damageCalculation.deregistrationAndRegistrationCosts"
                    (valueChange)="emitReportChange()"
                    placeholder="Ab- & Anmeldung"
                    [disabled]="report.state === 'done'"></currency-input>
            </div>
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="deregistrationAndRegistrationSubmenu">
                more_vert
            </mat-icon>
            <mat-menu #deregistrationAndRegistrationSubmenu>
                <mat-option (click)="rememberDeregistrationAndRegistrationCosts()">
                    Ab- & Anmeldegebühren merken
                </mat-option>
            </mat-menu>
        </section>
        <!--============================================-->
        <!-- END Deregistration & Registration -->
        <!--============================================-->

        <!--============================================-->
        <!-- Additional Costs -->
        <!--============================================-->
        <section class="costs-section" *ngIf="report.damageCalculation.additionalCosts.length">
            <div class="section-icon-container">
                <mat-icon class="section-icon">menu</mat-icon>
            </div>

            <div class="section-heading-container">
                <h3 class="section-heading">Weitere Kosten</h3>
            </div>

            <div id="additional-cost-items-container" (change)="emitReportChange()">
                <div
                    class="additional-cost-item"
                    *ngFor="let additionalCostItem of report.damageCalculation.additionalCosts; let index = index">
                    <div class="additional-inputs-container">
                        <mat-form-field>
                            <input
                                #additionalCostsTitleInput
                                matInput
                                placeholder="Titel"
                                [(ngModel)]="additionalCostItem.description"
                                [disabled]="report.state === 'done'" />
                        </mat-form-field>
                    </div>

                    <div
                        class="total-input-container"
                        (keydown.tab)="
                            index === report.damageCalculation.additionalCosts.length - 1
                                ? addAdditionalCostItem()
                                : null
                        ">
                        <currency-input
                            [(value)]="additionalCostItem.costs"
                            placeholder="Wert"
                            [disabled]="report.state === 'done'"></currency-input>
                    </div>
                    <mat-icon class="toolbar-icon" (click)="removeAdditionalCostItem(additionalCostItem)">
                        delete
                    </mat-icon>
                </div>
            </div>
        </section>
        <!--============================================-->
        <!-- END Additional Costs -->
        <!--============================================-->

        <section id="add-additional-costs-and-sum-section">
            <div id="spacer"></div>
            <button class="flat" (click)="addAdditionalCostItem()" *ngIf="report.state !== 'done'">
                + Neue Sonderkosten
            </button>
            <div class="label" matTooltip="Summe aller Sonderkosten">
                Σ {{ getTotalSpecialCosts() || 0 | number: '1.2-2' }} €
            </div>
        </section>

        <section class="dialog-buttons-container">
            <button (click)="closeDialog()">Schließen</button>
        </section>
    </div>
</div>

<text-template-selector
    [(text)]="report.damageCalculation.retrofit.comment"
    (textChange)="emitReportChange()"
    [report]="report"
    *ngIf="retrofitCommentTextTemplatesShown"
    placeholderForResultingText="Kommentar Umbaukosten"
    (close)="retrofitCommentTextTemplatesShown = false"
    [textTemplateType]="'retrofitComment'"
    @runChildAnimations></text-template-selector>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntermediaryAnalyticsRecord } from '@autoixpert/models/analytics/intermediary-analytics.model';
import { AnalyticsFilterOptions, prepareDefaultParametersForAnalyticsQuery } from './default-analytics-query-parameter';

@Injectable()
export class IntermediaryAnalyticsService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public getReportsByIntermediary(filterOptions: AnalyticsFilterOptions): Observable<IntermediaryAnalyticsRecord[]> {
        return this.httpClient.get<IntermediaryAnalyticsRecord[]>(`${this.apiPath}/analytics/intermediaries`, {
            params: prepareDefaultParametersForAnalyticsQuery({
                dateField: 'date',
                ...filterOptions,
            }),
        });
    }
}

import { ReportType } from '../../models/reports/report';
import { Team } from '../../models/teams/team';
import { DefaultDaysUntilDueConfig } from '../../models/user/preferences/default-days-until-due-config';

/**
 * Returns the default number of days until due for a given team and report type.
 */
export function getDefaultDaysUntilDue({ team, reportType }: { team: Team; reportType: ReportType }): number {
    const specificDaysUntilDueConfig: DefaultDaysUntilDueConfig = team.preferences.defaultDaysUntilDueConfigs?.find(
        (entry) => entry.reportType === reportType,
    );
    const liabilityDaysUntilDueConfig: DefaultDaysUntilDueConfig = team.preferences.defaultDaysUntilDueConfigs?.find(
        (entry) => entry.reportType === 'liability',
    );
    const defaultDaysUntilDueConfig: DefaultDaysUntilDueConfig = team.preferences.defaultDaysUntilDueConfigs?.find(
        (entry) => entry.reportType === 'default',
    );

    if (specificDaysUntilDueConfig) {
        return specificDaysUntilDueConfig.numberOfDays;
    } else if (liabilityDaysUntilDueConfig) {
        return liabilityDaysUntilDueConfig.numberOfDays;
    }
    if (defaultDaysUntilDueConfig) {
        return defaultDaysUntilDueConfig.numberOfDays;
    }

    return 30;
}

export function joinListToHumanReadableString(
    list: string[],
    delimiter: string = ', ',
    delimiterLastPair: string = ' & ',
): string {
    if (!list.length) return '';

    if (list.length === 1) {
        return list[0];
    }

    list = JSON.parse(JSON.stringify(list));

    const lastElement = list.pop();

    const firstElements: string = list.join(delimiter);

    return [firstElements, lastElement].join(delimiterLastPair);
}

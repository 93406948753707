import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { Team } from '@autoixpert/models/teams/team';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';

@Injectable()
export class LoggedInUserService {
    constructor(private router: Router) {}

    // Set the storage engine. This is session storage by default. If the user checks "remember me" on the login page, the regular local storage will be used.
    private store = store.session;

    private user: User;
    private userSubject = new ReplaySubject<User>(1);

    private team: Team;
    private teamSubject = new ReplaySubject<Team>(1);

    /**
     * Allows to set a URL to which the login should redirect after authentication succeeded.
     * This is set in e.g. the JWT Angular Interceptor that reacts to an invalid JWT. The forwardUrl is
     * then set to the route the user wanted to access before.
     */
    public forwardUrl: string = '';

    //*****************************************************************************
    //  Local Storage
    //****************************************************************************/
    /**
     * Allows user to stay logged in even after the browser window is closed.
     */
    public setPersistentLocalStorage(rememberMe: boolean): LoggedInUserService {
        if (rememberMe === false) {
            this.store = store.session;
        } else {
            // Use localStorage aka persistent storage
            this.store = store;
        }

        return this;
    }

    /**
     * Read logged-in user and his team from local storage and make it available to the app.
     *
     * This may only be called after the app component has been initialized (ngOnInit), not in this service's constructor.
     * Otherwise, when using an admin token, the old user is read out immediately by this service's constructor but the admin
     * token is only recognized and clears the old user later, namely when the app component's ngOnInit is triggered.
     */
    public recoverUserAndTeamFromLocalStorage(): void {
        if (store.get('loggedInUser')) {
            this.user = store.get('loggedInUser');
            this.team = store.get('loggedInUsersTeam');
            this.setPersistentLocalStorage(true);
        }

        // If a user was found, merge his preferences with the default preferences
        if (this.user) {
            // Merge the user's preferences with the default preferences from our code base. This way, required properties are always reflected in the user's preferences without overwriting them.
            this.user.preferences = Object.assign(new UserPreferences(), this.user.preferences);
        }

        // Emit the team before the user because autoiXpert often listens to changes in the user and assumes that the team is already present.
        this.teamSubject.next(this.team);
        this.userSubject.next(this.user);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local Storage
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Set & Get User
    //****************************************************************************/
    /**
     * Set the user persistently. Overwrite an existing user.
     */
    public setUser(user: User): void {
        // If the reference is the same, no need to trigger the subject's emission
        if (this.user !== user) {
            /**
             * Ensure the user object is set before the subject is triggered. This ensures that calls to loggedInUserService.getUser() within a subject subscription
             * returns the correct user object.
             */
            this.user = user;

            this.userSubject.next(user);
            console.log('🧑🏼 Current user', user);
        }
        // Still relevant if properties of the user changed.
        this.persistUser();
    }

    public persistUser() {
        // Persist the user information so the user can be identified after a page reload or opening a new tab/window
        if (this.user) {
            this.store.set('loggedInUser', this.user, true);
        }
        // If the user is undefined (e.g. after a logout), clear the local storage entry.
        else {
            this.store.remove('loggedInUser');
        }
    }

    /**
     * Get the currently logged in user. Returns undefined if no user is logged in.
     */
    public getUser$(): ReplaySubject<User> {
        return this.userSubject;
    }

    /**
     * The non-subject version to get a user. This makes synchronous code a lot easier in scenarios where we can
     * safely assume the user exists.
     */
    public getUser(): User {
        return this.user;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Set & Get User
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Set & Get Team
    //****************************************************************************/
    /**
     * Set the team persistently. Overwrite an existing team.
     */
    public setTeam(team: Team): void {
        // If the reference is the same, no need to trigger the subject's emission
        if (this.team !== team) {
            this.teamSubject.next(team);
        }
        this.team = team;
        // Persist the team information so the team can be loaded after a page reload or opening a new tab/window.
        this.persistTeam();
    }

    public persistTeam() {
        // Persist the user information so the user can be identified after a page reload or opening a new tab/window
        this.store.set('loggedInUsersTeam', this.team, true);
    }

    /**
     * Get the currently logged-in user's team.
     */
    public getTeam$(): ReplaySubject<Team> {
        return this.teamSubject;
    }

    /**
     * The non-subject version to get a team. This makes synchronous code a lot easier in scenarios where we can
     * safely assume the team exists.
     */
    public getTeam(): Team {
        return this.team;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Set & Get Team
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Login Status
    //****************************************************************************/
    /**
     * Check if user is currently logged in.
     */
    public isLoggedIn(): boolean {
        // User must exist & user authentication must not be expired.
        return !!this.user;
    }

    /**
     * Remove the JWT in the cookie and references of the logged-in user in the local storage.
     *
     * This leaves the IndexedDB data intact.
     */
    public clearUser(skipNavigationToLogin: boolean = false): void {
        this.setUser(null);
        this.setTeam(null);

        // Remove everything from localStorage
        store.clear();

        // Navigate to the login if the user is not already there. The user might be logged out straight on the App Component on startup if he is an admin using the one time admin token.
        if (!skipNavigationToLogin && !this.router.url.includes('/Login')) {
            this.router.navigate(['/Login']);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Login Status
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Invoice } from '@autoixpert/models/invoices/invoice';
import { removeInvalidFilenameCharacters } from '../remove-invalid-filename-characters';

export function getInvoiceXmlFilename(invoice: Invoice) {
    return `Ausgangsrechnung_${removeInvalidFilenameCharacters(invoice.number)}.xml`;
}

export function getInvoicePdfFilename(invoice: Invoice) {
    return `Ausgangsrechnung_${removeInvalidFilenameCharacters(invoice.number)}.pdf`;
}

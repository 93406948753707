import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { mergeRecord } from '@autoixpert/lib/server-sync/merge-record';
import { Team } from '@autoixpert/models/teams/team';
import { LiveSyncServiceBase } from '../libraries/database/live-sync.service-base';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FrontendLogService } from './frontend-log.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';
import { teamRecordMigrations } from './team.service-migrations';

@Injectable()
export class TeamService extends LiveSyncServiceBase<Team> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected frontendLogService: FrontendLogService,
        private feathersSocketioService: FeathersSocketioService,
        private loggedInUserService: LoggedInUserService,
    ) {
        super({
            serviceName: 'team',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            feathersSocketioClient: feathersSocketioService,
            objectStoreAndIndexMigrations: undefined,
            recordMigrations: teamRecordMigrations,
        });
        this.registerPatchEventHandlers();
    }

    private registerPatchEventHandlers() {
        this.patchedFromLocalUserInThisTab$.subscribe({
            next: ({ patchedRecord }) => {
                const loggedInUsersTeam: Team = this.loggedInUserService.getTeam();

                /**
                 * The difference between this event handler and the one further down is that this event handler assumes that the team object
                 * has already been modified locally. No "mergeRecord()" is necessary anymore. On the contrary, it would be harmful because
                 * it would create a lag: If the logged-in user's team is changed, written to IndexedDB and then changed again through mergeRecord,
                 * a slow IndexedDB-write might cause a second write of data with an old state.
                 */
                if (loggedInUsersTeam?._id === patchedRecord._id) {
                    this.loggedInUserService.persistTeam();
                }
            },
        });
        this.patchedFromExternalServerOrLocalBroadcast$.subscribe({
            next: ({ patchedRecord }) => {
                //*****************************************************************************
                //  Logged-in User's Team
                //****************************************************************************/
                const loggedInUsersTeam: Team = this.loggedInUserService.getTeam();

                // Only update the right matching user in the loggedInUserService's cache.
                if (loggedInUsersTeam?._id === patchedRecord._id) {
                    mergeRecord<Team>(loggedInUsersTeam, patchedRecord);
                    // Write the team object to local storage to ensure that all team changes are recovered after reloading the page.
                    this.loggedInUserService.persistTeam();
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Logged-in User's Team
                /////////////////////////////////////////////////////////////////////////////*/
            },
        });
    }

    /**
     * Orders an AudatexAddon (which requires a extra license).
     */
    public async orderAudatexAddon(team: Team) {
        await this.httpClient.post(`/api/v0/teams/${team._id}/orderAudatexAddon`, {}).toPromise();
        this.get(team._id);
    }

    /**
     * Cancels an AudatexAddon (which requires a extra license).
     */
    public async cancelAudatexAddon(team: Team) {
        await this.httpClient.delete(`/api/v0/teams/${team._id}/orderAudatexAddon`, {}).toPromise();
        this.get(team._id);
    }
}

import { getDocumentOrderForRecipient } from '@autoixpert/lib/documents/get-document-order-for-recipient';
import { Invoice, InvoiceInvolvedPartyRole } from '@autoixpert/models/invoices/invoice';
import { DocumentOrder } from '../../models/documents/document-order';
import { removeFromArray } from '../arrays/remove-from-array';

export function removeDocumentOrderFromInvoice({
    invoice,
    recipientRole,
}: {
    invoice: Invoice;
    recipientRole: InvoiceInvolvedPartyRole;
}): void {
    /**
     * Documents may be sorted individually per recipient. Create a document order object for each recipient.
     */
    let documentOrder: DocumentOrder;
    try {
        documentOrder = getDocumentOrderForRecipient(invoice.documentOrders, recipientRole);
    } catch (error) {
        if (error.code === 'DOCUMENT_ORDER_NOT_FOUND_FOR_GIVEN_RECIPIENT') {
            // Target state is reached, don't do anything else.
        } else {
            throw error;
        }
    }

    removeFromArray(documentOrder, invoice.documentOrders);
}

<div [class]="['user-selection-menu-container', theme]" (click)="$event.preventDefault(); $event.stopPropagation()">
    <!-- Search -->
    <div [class]="['user-selection-search-container', theme]">
        <!-- Search icon -->
        <mat-icon class="user-selection-search-icon">search</mat-icon>

        <!-- Search input -->
        <input
            #searchInput
            [class]="['user-selection-search-input', theme]"
            type="text"
            placeholder="Suchen"
            (keydown.arrowdown)="moveFocusedUserIndex(1)"
            (keydown.arrowup)="moveFocusedUserIndex(-1)"
            (keydown.enter)="confirmFocusedUserSelection()"
            (input)="handleSearchInputChange($event)" />

        <!-- Search pending indicator -->
        <mat-spinner
            *ngIf="isSearchPending"
            class="user-selection-search-loading-indicator"
            [diameter]="16"></mat-spinner>

        <!-- Clear button -->
        <mat-icon
            *ngIf="!isSearchPending && !!searchTerm"
            [class]="['toolbar-icon', 'user-selection-search-clear-button', theme]"
            [matTooltip]="'Suche zurücksetzen'"
            (click)="clearAndFocusSearchInput()">
            close
        </mat-icon>
    </div>

    <!-- Content -->
    <div [class]="['user-selection-menu-content', theme]" (mousedown)="$event.preventDefault()">
        <!-- Search Results -->
        <cdk-virtual-scroll-viewport
            class="cdk-virtual-scroll-vertical-only"
            [style.height.px]="filteredUsers.length * 50"
            [style.max-height.px]="320"
            [itemSize]="50">
            <div
                *ngIf="!searchTerm && isNoSelectedUserAllowed"
                [class]="['user-selection-menu-item', theme]"
                (mousemove)="setFocusedUserIndex(-1)"
                (click)="emitUserSelection(undefined)">
                <!-- User avatar -->
                <user-avatar class="user-selection-menu-item-avatar" [userId]="" [showEmptyState]="true"></user-avatar>

                <!-- User info -->
                <div class="user-selection-menu-item-info-container">
                    <!-- Full name -->
                    <div class="user-selection-menu-item-full-name">Kein Nutzer</div>
                </div>
            </div>
            <ng-container *cdkVirtualFor="let user of filteredUsers; let index = index; trackBy: trackById">
                <div
                    [class]="['user-selection-menu-item', theme]"
                    (mousemove)="setFocusedUserIndex(index)"
                    (click)="emitUserSelection(user)">
                    <!-- User avatar -->
                    <user-avatar class="user-selection-menu-item-avatar" [userId]="user._id"></user-avatar>

                    <!-- User info -->
                    <div class="user-selection-menu-item-info-container">
                        <!-- Full name -->
                        <div class="user-selection-menu-item-full-name" [matTooltip]="getUserFullName(user)">
                            {{ getUserFullName(user) }}
                        </div>

                        <!-- Role -->
                        <!--********** Will be implemented as soon as we made roles generally available. **********-->
                        <div *ngIf="false" class="user-selection-menu-item-role" [matTooltip]="'Sachverständiger'">
                            Sachverständiger
                        </div>
                    </div>
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>

        <!-- Placeholder -->
        <div *ngIf="!isSearchPending && !filteredUsers.length" class="user-selection-menu-placeholder">
            Kein Nutzer gefunden
        </div>
    </div>
</div>

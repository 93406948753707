import { Axle } from '../../models/reports/car-identification/axle';
import { Tire } from '../../models/reports/car-identification/tire';

export function getAllTiresOnAxle(axle: Axle): Tire[] {
    return [
        // Order is important to ensure a selection order coherent to the axle sketch
        axle.outerLeftTire,
        axle.leftTire,
        axle.centerTire,
        axle.rightTire,
        axle.outerRightTire,
    ].filter((tire) => !!tire);
}

import { MatDateFormats } from '@angular/material/core';

export const AX_MOMENT_DATE_FORMATS: MatDateFormats = {
    display: {
        dateInput: 'DD.MM.YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
        monthYearLabel: 'MMM YYYY',
    },
    parse: {
        dateInput: [
            'DD.MM.YYYY',
            'DD.MM.YY',
            'D.M.YYYY',
            'D.M.YY',
            'D.MM.YY',
            'D.MM.YYYY',
            'DD.M.YY',
            'DD.M.YYYY',
            'DD.MM.',
            'D.M.',
            'MM.YYYY',
            'MM.YY',
            'DD',
        ],
    },
};

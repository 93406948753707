<task-details-subtask-row
    *ngFor="let subtask of task.subtasks"
    class="subtask-row"
    [isNewSubtask]="newSubtask?._id === subtask._id"
    [subtask]="subtask"
    (subtaskAdded)="addTask(subtask)"
    (subtaskChanged)="saveTask()"
    (subtaskDeleted)="deleteTask(subtask)"></task-details-subtask-row>

<div id="add-subtask" (click)="addTask()">
    <span>+ Teilaufgabe</span>
</div>

export class DatVehicleIdentification {
    /**
     * The DAT€Code is a DAT internal string allowing the user to identify additional information of the car. In contrast to the vin, requesting
     * information with the DAT€Code is free. The VIN and the DAT€Code identify different kinds of information (VIN = this specific car, DAT€Code = this type of car, e.g. without
     * special equipment).
     */
    datEuropaCode: string;
    vehicleType: '0' | '1' | '2' | '3' | '4' | '5' | '7' | '8' | '10' | '11' | '14' | '30'; // 1 = car, 2 = transporter, 3 = motorcycle, 4 = semi truck. The DAT sometimes returns 0. Strange.
    manufacturerKey: number;
    baseModelKey: number;
    subModelKey: number;
    subModelVariantKey: number;
    // List of DAT keys referencing the selected DAT classification groups
    selectedClassificationGroupOptions: SelectedClassificationGroupOptions = new SelectedClassificationGroupOptions();
    marketIndex: string; // E.g. DE001. A market index which further specifies a DAT sub model type. Required to get additional car data with DAT's getVehicleData() method.
    marketIndexName: string; // E.g. "DE - Van5 B 180 CDI / d EU5, DPF BlueEfficiency Edition, 2013 - 2014"

    /**
     * The DAT uses numbers for their construction times (not dates). See ConversionFunctionsDatService.constructionTime2Date() for details.
     */
    constructionTimeFrom: number;
    constructionTimeTo: number;

    isValuationPossible: boolean;
    isDamageCalculationPossible: boolean;
}

export class SelectedClassificationGroupOptions {
    motor: string = null; // Motor
    autobody: string = null; // Karosserie
    build: string = null; // Bauart
    gearbox: string = null; // Getriebe
    wheelbase: string = null; // Radstand
    propulsion: string = null; // Antriebsart
    numberOfAxles: string = null; // Anzahl Achsen
    driversCab: string = null; // Fahrerhaus
    tonnage: string = null; // Tonnage/Tragfähigkeit
    suspension: string = null; // Federungsart
    equipmentLine: string = null; // Ausstattungslinie
}

import { Report } from '../../models/reports/report';
import { getVehicleValueGross } from '../car-valuation/get-vehicle-value';
import { convertToEuro } from './convert-to-euro';
import { PlaceholderValuesOldtimerValuation } from './placeholder-values.types';
import { Translator } from './translator';

/**
 * Returns the placeholder values concerning invoice-related data.
 *
 * This is used by the invoice DOCX generation service and by the template
 * placeholder value service that delivers the entire placeholder tree to the frontend.
 * @param invoice
 * @returns {Object}
 */
export async function getTemplatePlaceholderValuesOldtimerValuation({
    report,
}: {
    report: Report;
}): Promise<PlaceholderValuesOldtimerValuation> {
    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
    const replacementValueGross = getVehicleValueGross(report.valuation, 'replacementValue');
    const marketValueGross = getVehicleValueGross(report.valuation, 'marketValue');

    return {
        // TODO Merge the OldtimerValuation object into the valuation object.
        OldtimerBewertung: {
            // Number and optional sign, e.g. "2+" or "3"
            GesamtnoteZahl: Translator.oldtimerValuationNumberGrades(report.valuation?.oldtimerValuationGrades?.total),
            // "zwei plus" or "drei"
            GesamtnoteAusformuliert: Translator.oldtimerValuationVerbalGrades(
                report.valuation?.oldtimerValuationGrades?.total,
            ),
            Marktwert: marketValueGross ? convertToEuro(marketValueGross, { decimalPlaces: 0 }) : '',
            Wiederbeschaffungswert: replacementValueGross
                ? convertToEuro(replacementValueGross, { decimalPlaces: 0 })
                : '',
            Wiederherstellungswert: report.valuation?.restorationValue
                ? convertToEuro(report.valuation.restorationValue, { decimalPlaces: 0 })
                : '',
            Basisfahrzeugwert: report.valuation?.restorationBaseVehicleValue
                ? convertToEuro(report.valuation.restorationBaseVehicleValue, { decimalPlaces: 0 })
                : '',
            Wiederherstellungskosten: report.valuation?.restorationCosts
                ? convertToEuro(report.valuation.restorationCosts, { decimalPlaces: 0 })
                : '',
            Quelle: report.valuation?.valuationProvider === 'classicAnalytics' ? 'Classic Analytics' : '',
            WertbeeinflussendeFaktoren: report.car.specialEquipment?.join(', '),
            // Use this "Faktor" property because our docxtemplater version cannot yet loop over an array of simple strings and get the string value. It can, however, loop over objects and get an object's property.
            WertbeeinflussendeFaktorenArray: (report.car.specialEquipment || []).map((specialEquipmentPosition) => ({
                Faktor: specialEquipmentPosition,
            })),
            Fahrzeugbeschreibung: report.car.comment,
            // Images for how well the
            BewertungenSkala: {
                Blech: Translator.oldtimerValuationNumberGrades(report.valuation?.oldtimerValuationGrades?.body),
                Lack: Translator.oldtimerValuationNumberGrades(report.valuation?.oldtimerValuationGrades?.paint),
                ChromUndZierteile: Translator.oldtimerValuationNumberGrades(
                    report.valuation?.oldtimerValuationGrades?.chrome,
                ),
                DichtungenGummiteile: Translator.oldtimerValuationNumberGrades(
                    report.valuation?.oldtimerValuationGrades?.rubber,
                ),
                Glas: Translator.oldtimerValuationNumberGrades(report.valuation?.oldtimerValuationGrades?.glass),
                Reifen: Translator.oldtimerValuationNumberGrades(report.valuation?.oldtimerValuationGrades?.tires),
                Fahrgastraum: Translator.oldtimerValuationNumberGrades(
                    report.valuation?.oldtimerValuationGrades?.cabin,
                ),
                Motorraum: Translator.oldtimerValuationNumberGrades(
                    report.valuation?.oldtimerValuationGrades?.engineCompartment,
                ),
                Motor: Translator.oldtimerValuationNumberGrades(report.valuation?.oldtimerValuationGrades?.engine),
                Kofferraum: Translator.oldtimerValuationNumberGrades(report.valuation?.oldtimerValuationGrades?.trunk),
            },
            // Kaufpreis                  : report.oldtimerValuation && report.oldtimerValuation.purchasePrice ? convertToEuro(report.oldtimerValuation.purchasePrice) : '',
            // Wiederherstellung          : {
            //     KostenNetto            : report.oldtimerValuation && report.oldtimerValuation.restorationCosts ? convertToEuro(report.oldtimerValuation.restorationCosts) : '',
            //     KostenPositionen       : report.oldtimerValuation && report.oldtimerValuation.restorationCostItems.map(restorationCostItem => ({
            //         Beschreibung : restorationCostItem.description,
            //         Menge        : restorationCostItem.quantity,
            //         Einheit      : restorationCostItem.unit,
            //         Einzelpreis  : convertToEuro(restorationCostItem.unitPrice),
            //         Gesamtpreis  : convertToEuro(restorationCostItem.unitPrice * restorationCostItem.quantity)
            //     })),
            //     KorrigierteKostenNetto : report.oldtimerValuation && convertToEuro((report.oldtimerValuation.restorationCosts || 0) - (report.oldtimerValuation.restorationDeductions || 0)),
            //     Wiederherstellungswert : report.oldtimerValuation && report.oldtimerValuation.restorationValue ? convertToEuro(report.oldtimerValuation.restorationValue, {decimalPlaces: 0}) : '',
            // },
            // Abzüge                     : {
            //     GesamtNetto : report.oldtimerValuation && report.oldtimerValuation.restorationDeductions ? convertToEuro(report.oldtimerValuation.restorationDeductions) : '',
            //     Positionen  : report.oldtimerValuation && report.oldtimerValuation.restorationDeductionItems.map(restorationDeductionItem => ({
            //         Position     : restorationDeductionItem.index,
            //         Beschreibung : restorationDeductionItem.description,
            //         Preis        : convertToEuro(restorationDeductionItem.amount)
            //     })),
            // },
        },
    };
    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { isBeta } from '@autoixpert/lib/environment/is-beta';
import { Report } from '@autoixpert/models/reports/report';
import { AutoixpertResidualValueOffer } from '@autoixpert/models/reports/residual-value/autoixpert-residual-value-offer';
import { ResidualValueInvitation } from '@autoixpert/models/reports/residual-value/residual-value-request';
import { environment } from '../../../environments/environment';
import { ApiErrorService } from './api-error.service';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class ResidualValueRequestService {
    constructor(
        private toastService: ToastService,
        private httpClient: HttpClient,
        private apiErrorService: ApiErrorService,
    ) {}

    create(report: Report) {
        return this.server.create(report._id);
    }

    getBids(report: Report, query?: { [param: string]: string }) {
        return this.server.getBids(report._id, query);
    }

    delete(report: Report) {
        return this.server.delete(report._id);
    }

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    private server = {
        /**
         * Create a residual value request on the server.
         */
        create: (reportId: string): Promise<any> => {
            return this.httpClient
                .post(`/api/v0/reports/${reportId}/residualValueRequest`, {})
                .pipe(
                    tap({
                        next: (responseData: { residualValueId: string }) => {
                            return responseData;
                        },
                    }),
                )
                .toPromise();
        },

        sendInvitations: (reportId: string) => {
            return this.httpClient
                .post(`/api/v0/reports/${reportId}/residualValueInvitations`, {})
                .pipe(
                    tap({
                        next: (responseData: {
                            newInvitationReceipts: AutoixpertResidualValueOffer['invitationReceipts'];
                        }) => {
                            return responseData.newInvitationReceipts;
                        },
                        error: (error) => {
                            this.apiErrorService.handleAndRethrow({
                                axError: error,
                                handlers: {},
                                defaultHandler: {
                                    title: 'E-Mail-Versand',
                                    body: 'Mindestens eine E-Mail konnte nicht versendet werden. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                                },
                            });
                        },
                    }),
                )
                .toPromise();
        },

        getBids: (reportId: string, query: { [param: string]: string }) => {
            return this.httpClient
                .get<ResidualValueInvitation[]>(`/api/v0/reports/${reportId}/residualValueInvitations`, {
                    params: query,
                })
                .pipe(
                    tap({
                        error: (error) => {
                            this.apiErrorService.handleAndRethrow({
                                axError: error,
                                handlers: {},
                                defaultHandler: {
                                    title: 'Gebote nicht abrufbar',
                                    body: 'Die Gebote sind derzeit nicht abrufbar. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                                },
                            });
                        },
                    }),
                )
                .toPromise();
        },

        /**
         * Remove residual value request and all its assets (photos, damage calculation) from server.
         */
        delete: (reportId: string): Observable<any> => {
            return this.httpClient.delete(`/api/v0/reports/${reportId}/residualValueRequest/${reportId}`).pipe(
                catchError(() => {
                    console.warn(
                        `The residual value offer or any of its assets could not be deleted. This should not prevent the user from continuing.`,
                    );
                    return of(true);
                }),
            );
        },
    };

    public openResidualValueOfferPreview(reportId: string): Window {
        const baseUrl: string = isBeta()
            ? 'https://beta.restwert.autoixpert.de'
            : environment.production
              ? 'https://restwert.autoixpert.de'
              : 'http://restwert.autoixpert.lokal';

        return window.open(`${baseUrl}/Inserat/${reportId}/preview`);
    }
}

import { DocumentMetadata } from '../../../models/documents/document-metadata';
import { PaymentReminderLevel } from '../../../models/invoices/payment-reminder';

/**
 * Converts the payment reminder level to the corresponding document type.
 */
export function paymentReminderLevelToDocumentType(
    paymentReminderLevel: PaymentReminderLevel,
): DocumentMetadata['type'] {
    switch (paymentReminderLevel) {
        case 'level0':
            return 'paymentReminderLevel0';
        case 'level1':
            return 'paymentReminderLevel1';
        case 'level2':
            return 'paymentReminderLevel2';
        case 'level3':
            return 'paymentReminderLevel3';
    }
}

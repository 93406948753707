<autogrow-textarea
    #titleInput
    class="task-title-input"
    [style.min-width.px]="task.title ? 20 : isTitleInputFocused ? 150 : 80"
    placeholder="Neue Aufgabe..."
    [value]="task.title"
    [singleLine]="singleLine"
    [darkMode]="darkMode"
    [paddingVertical]="singleLine ? 4 : 4"
    [paddingHorizontal]="singleLine ? 4 : 4"
    (change)="taskChange.emit(task)"
    (valueChange)="task.title = $event.value"
    (focused)="isTitleInputFocused = true"
    (enterPressed)="enterKeyPress.emit({ task })"
    (shiftEnterPressed)="shiftEnterKeyPress.emit({ task })"
    (keydown)="handleKeyboardShortcuts($event)"
    (blurred)="handleTitleBlurred()"
    matTooltip="Titel deiner Aufgabe

Tastatur-Shortcuts
• Aufgabe löschen: Shift+Entf oder Shift+Backspace
• Aufgabe oberhalb der selektierten einfügen: Shift+Enter

Schlüsselwörter (werden beim Verlassen des Feldes ersetzt)
• Aufgabe einem Kollegen zuweisen: @Vorname, @Nachname, @VornameNachname oder @Kürzel
• Datum: :heute, :morgen, :3w (in 3 Wochen) etc.
• Dauer: :10m oder :2h

Beispiel: Zur Post bringen :15m @Amelie"></autogrow-textarea>

import { RequireSome } from '../../../helper-types/require-some';
import { Weekday } from '../../../pipes/weekday.pipe';

/**
 * Defines the settings how many hours a day in a week the user works.
 * This allows the task list to indicate if the user's capacity for tasks is reached.
 */
export class WorkweekSettings {
    monday: WorkdaySettings = new WorkdaySettings({
        weekday: 'monday',
        active: true,
        workingMinutes: 480,
    });
    tuesday: WorkdaySettings = new WorkdaySettings({
        weekday: 'tuesday',
        active: true,
        workingMinutes: 480,
    });
    wednesday: WorkdaySettings = new WorkdaySettings({
        weekday: 'wednesday',
        active: true,
        workingMinutes: 480,
    });
    thursday: WorkdaySettings = new WorkdaySettings({
        weekday: 'thursday',
        active: true,
        workingMinutes: 480,
    });
    friday: WorkdaySettings = new WorkdaySettings({
        weekday: 'friday',
        active: true,
        workingMinutes: 480,
    });
    saturday: WorkdaySettings = new WorkdaySettings({
        weekday: 'saturday',
        active: false,
        workingMinutes: 240,
    });
    sunday: WorkdaySettings = new WorkdaySettings({
        weekday: 'sunday',
        active: false,
        workingMinutes: 240,
    });
}

export class WorkdaySettings {
    constructor(template: RequireSome<WorkdaySettings, 'weekday'>) {
        Object.assign(this, template);
    }
    weekday: Weekday;
    active: boolean = true;
    workingMinutes: number;
}

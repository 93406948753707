import { removeFromArray } from './remove-from-array';

/**
 * Useful if the reference of an array element is not available but the ID is.
 */
export function removeFromArrayById<T extends { _id: string }>(
    id: string,
    array: T[],
): { index: number; removedItem: T } {
    const item = array.find((item) => {
        return item?._id === id;
    });

    return removeFromArray(item, array);
}

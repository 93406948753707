import { generateId } from '@autoixpert/lib/generate-id';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { ObjectStoreAndIndexMigrations, RecordMigrations } from '@autoixpert/models/indexed-db/database.types';

export const documentBuildingBlockServiceObjectStoreAndIndexMigrations: ObjectStoreAndIndexMigrations<DocumentBuildingBlock> =
    {};

export const documentBuildingBlockRecordMigrations: RecordMigrations<DocumentBuildingBlock> = {
    /**
     * Migrate a document building block from version 1 to version 2:
     * - Top-level conditionsOperator and conditions are added to the document building block.
     * - Migrate document building block variants and conditions.
     * - Increase document version to trigger sync to clients.
     */
    '2': (documentBuildingBlock: DocumentBuildingBlock): DocumentBuildingBlock => {
        // Add top-level conditionsOperator and conditions to the document building block
        documentBuildingBlock.conditionsOperator ??= 'and';
        documentBuildingBlock.conditions ??= [];

        // Migrate document building block variants and conditions
        documentBuildingBlock.variants.forEach((variant) => {
            variant._id = variant._id ?? generateId();
            variant.conditionsOperator ??= 'and';
            variant.isDefaultVariant ??= false;
            variant.conditions ??= [];

            // Add _id to all conditions
            variant.conditions.forEach((condition) => {
                condition._id ??= generateId();
            });
        });

        // Increase schema version to 2
        documentBuildingBlock._schemaVersion = 2;

        // Increase document version to trigger sync to clients
        documentBuildingBlock._documentVersion++;

        return documentBuildingBlock;
    },
};

<div class="dialog-overlay">
    <div class="dialog-container">
        <display-internal-id internalId="{{ contactPerson._id }}" entityName="Kontakt"></display-internal-id>
        <h2>
            <ng-container *ngIf="contactPerson._id">Kontakt bearbeiten</ng-container>
            <ng-container *ngIf="!contactPerson._id">Neuer Kontakt</ng-container>
        </h2>
        <div
            class="card-toolbar"
            *ngIf="
                !vatFieldsAllowed ||
                !showDebtorNumber ||
                (contactPerson.organizationType === 'claimant' && !contactPerson.receivesCollectiveInvoice)
            ">
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="contactPersonEditorCardMenu">more_vert</mat-icon>
            <mat-menu #contactPersonEditorCardMenu="matMenu">
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    *ngIf="!showDebtorNumber"
                    (click)="showDebtorNumber = true"
                    matTooltip="Die Kontonummer des Debitors wird über den DATEV-Export an deinen Steuerberater übertragen.">
                    Debitorennummer zeigen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    *ngIf="!vatFieldsAllowed"
                    (click)="vatFieldsAllowed = true"
                    matTooltip="Ob ein Kontakt vorsteuerabzugsberechtigt ist und welche Umsatzsteuer-ID er hat, ist relevant, falls du diesem Kontakt Rechnungen schreibst.">
                    Umsatzsteuer-Felder zeigen
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-toggle"
                    *ngIf="contactPerson.organizationType === 'claimant' && !contactPerson.receivesCollectiveInvoice"
                    (click)="contactPerson.receivesCollectiveInvoice = true; shouldShowCollectiveInvoiceCheckbox = true"
                    matTooltip="Gutachten für diesen Kunden sollen standardmäßig keine Rechnung bei Gutachtenabschluss erzeugen, sondern werden in einer Sammelrechnung abgerechnet.">
                    Erhält Sammelrechnung
                </a>
            </mat-menu>
        </div>
        <mat-icon class="dialog-close-icon" (click)="closeSelector()">close</mat-icon>

        <!--============================================-->
        <!-- Quick Selection -->
        <!--============================================-->
        <section id="quick-selection-section" *ngIf="filteredContactPeopleForQuickSelection.length && !disabled">
            <h3>Schnellwahl</h3>

            <div class="options-row">
                <div
                    class="option-container"
                    *ngFor="let contactPerson of filteredContactPeopleForQuickSelection"
                    [ngClass]="{ active: matchesContactPersonInForm(contactPerson) }"
                    (click)="!disabled && insertContactData(contactPerson)"
                    tabindex="0">
                    <ng-container [ngSwitch]="contactPerson.organizationType">
                        <img
                            class="option-image"
                            src="/assets/images/icons/claimant-blue.svg"
                            *ngSwitchCase="'claimant'" />
                        <img class="option-image" src="/assets/images/icons/garage-blue.svg" *ngSwitchCase="'garage'" />
                        <img class="option-image" src="/assets/images/icons/lawyer-blue.svg" *ngSwitchCase="'lawyer'" />
                        <img
                            class="option-image"
                            src="/assets/images/icons/insurance-blue.svg"
                            *ngSwitchCase="'insurance'" />
                        <mat-icon class="option-image icon-emphasized" *ngSwitchCase="'bidder'">gavel</mat-icon>
                        <mat-icon class="option-image icon-emphasized" *ngSwitchCase="'residualValueRequestRecipient'">
                            gavel
                        </mat-icon>
                        <mat-icon class="option-image" *ngSwitchCase="'visitLocationFavorite'">star</mat-icon>
                        <mat-icon class="option-image" *ngSwitchCase="'intermediary'">record_voice_over</mat-icon>
                        <mat-icon class="option-image" *ngSwitchCase="'leaseProvider'">account_balance</mat-icon>
                    </ng-container>
                    <div class="option-title-container" [ngSwitch]="contactPerson.organizationType">
                        <span class="option-title" *ngSwitchCase="'claimant'">Anspruchsteller</span>
                        <span class="option-title" *ngSwitchCase="'garage'">Werkstatt</span>
                        <span class="option-title" *ngSwitchCase="'lawyer'">Anwalt</span>
                        <span class="option-title" *ngSwitchCase="'insurance'">Versicherung</span>
                        <span class="option-title" *ngSwitchCase="'bidder'">Restwertbieter</span>
                        <span class="option-title" *ngSwitchCase="'residualValueRequestRecipient'">
                            Empfänger Restwertinserate
                        </span>
                        <span class="option-title" *ngSwitchCase="'visitLocationFavorite'">
                            Besichtigungs-
                            <br />
                            favorit
                        </span>
                        <span class="option-title" *ngSwitchCase="'intermediary'">Vermittler</span>
                        <span class="option-title" *ngSwitchCase="'leaseProvider'">Leasinggeber</span>
                    </div>
                </div>
            </div>
        </section>

        <!--============================================-->
        <!-- END Quick Selection -->
        <!--============================================-->

        <!--============================================-->
        <!-- Organization Type -->
        <!--============================================-->
        <section id="organization-type-section" *ngIf="showOrganizationTypeSelection">
            <h3>Typ</h3>
            <div class="options-row grid">
                <div
                    class="option-container"
                    *ngFor="let organizationType of selectableOrganizationTypes"
                    [ngClass]="{ active: contactPerson.organizationType === organizationType, unselectable: disabled }"
                    (click)="!disabled && selectOrganizationType(organizationType)"
                    tabindex="0">
                    <ng-container [ngSwitch]="organizationType">
                        <img
                            class="option-image"
                            src="/assets/images/icons/claimant-blue.svg"
                            *ngSwitchCase="'claimant'" />
                        <img class="option-image" src="/assets/images/icons/garage-blue.svg" *ngSwitchCase="'garage'" />
                        <img class="option-image" src="/assets/images/icons/lawyer-blue.svg" *ngSwitchCase="'lawyer'" />
                        <img
                            class="option-image"
                            src="/assets/images/icons/insurance-blue.svg"
                            *ngSwitchCase="'insurance'" />
                        <mat-icon class="option-image icon-emphasized" *ngSwitchCase="'bidder'">gavel</mat-icon>
                        <mat-icon class="option-image icon-emphasized" *ngSwitchCase="'residualValueRequestRecipient'">
                            gavel
                        </mat-icon>
                        <mat-icon class="option-image" *ngSwitchCase="'visitLocationFavorite'">star</mat-icon>
                        <mat-icon class="option-image" *ngSwitchCase="'intermediary'">record_voice_over</mat-icon>
                        <mat-icon class="option-image" *ngSwitchCase="'leaseProvider'">account_balance</mat-icon>
                    </ng-container>
                    <div class="option-title-container" [ngSwitch]="organizationType">
                        <span class="option-title" *ngSwitchCase="'claimant'">Anspruchsteller</span>
                        <span class="option-title" *ngSwitchCase="'garage'">Werkstatt</span>
                        <span class="option-title" *ngSwitchCase="'lawyer'">Anwalt</span>
                        <span class="option-title" *ngSwitchCase="'insurance'">Versicherung</span>
                        <span class="option-title" *ngSwitchCase="'bidder'">Restwertbieter</span>
                        <span class="option-title" *ngSwitchCase="'residualValueRequestRecipient'">
                            Empfänger Restwertinserate
                        </span>
                        <span class="option-title" *ngSwitchCase="'visitLocationFavorite'">
                            Besichtigungs-
                            <br />
                            favorit
                        </span>
                        <span class="option-title" *ngSwitchCase="'intermediary'">Vermittler</span>
                        <span class="option-title" *ngSwitchCase="'leaseProvider'">Leasinggeber</span>
                    </div>
                </div>
            </div>

            <div id="garage-fees-hint" class="label" *ngIf="contactPerson.organizationType === 'garage'">
                <strong>Tipp:</strong>
                Du kannst für Werkstätten Kostensätze hinterlegen, indem du in der Kontaktliste auf das €-Symbol
                klickst.
            </div>
        </section>
        <!--============================================-->
        <!-- END Organization Type -->
        <!--============================================-->

        <!--============================================-->
        <!-- Contact Person Form -->
        <!--============================================-->
        <contact-person-form
            [contact-person]="contactPerson"
            [organizationTypes]="autocompleteGroupNames"
            [show-phone-number]="showPhone"
            [show-email]="showEmail"
            [show-website]="showWebsite"
            [show-iban]="contactPerson.organizationType === 'claimant' && !hideIban"
            [show-vat-fields]="vatFieldsAllowed"
            [show-debtor-number]="showDebtorNumber"
            [show-collective-invoice]="shouldShowCollectiveInvoiceCheckbox"
            [center-vat-deduction-checkbox]="vatFieldsAllowed"
            [second-phone-number-shown]="true"
            [show-suggestions-if-empty]="false"
            [show-notes]="true"></contact-person-form>
        <!--============================================-->
        <!-- END Contact Person Form -->
        <!--============================================-->

        <!--============================================-->
        <!-- May Deduct Taxes -->
        <!--============================================-->

        <!--============================================-->
        <!-- END May Deduct Taxes -->
        <!--============================================-->

        <!--============================================-->
        <!-- Garage Brands -->
        <!--============================================-->
        <garage-brands
            *ngIf="contactPerson.organizationType === 'garage'"
            [garageContact]="contactPerson"
            (contactPersonChange)="emitContactPersonChangeEvent()"></garage-brands>
        <!--============================================-->
        <!-- END Garage Brands -->
        <!--============================================-->

        <!--============================================-->
        <!-- Residual Value Exchange Quotas -->
        <!--============================================-->
        <section id="residual-value-quotas" *ngIf="contactPerson.organizationType === 'insurance'">
            <div
                class="residual-value-quota-header"
                (click)="!isResidualValueExchangeQuotaVisible && showResidualValueExchangeQuota()">
                <div *ngIf="!isResidualValueExchangeQuotaVisible">
                    Vereinbarung mit Restwertbörsen
                    <span
                        class="help-indicator"
                        matTooltip="Versicherungen könnten Rabatte oder Kontingente für Restwertinserate haben, die sie unter bestimmten Fällen (z. B. Gutachten im Auftrag der Versicherung) mit dem Sachverständigen teilen. Du kannst hier aktivieren, für welche Restwertbörsen du mit dieser Versicherung eine Vereinbarung hast. AutoiXpert kann in dem Fall die Versicherung an die Restwertbörse übertragen, sodass die Restwertbörse das Inserat zuordnen und gemäß der Vereinbarung abrechnen kann.">
                        ?
                    </span>
                </div>
                <h3 *ngIf="isResidualValueExchangeQuotaVisible">
                    Vereinbarung mit Restwertbörsen
                    <span
                        class="help-indicator"
                        matTooltip="Versicherungen könnten Rabatte oder Kontingente für Restwertinserate haben, die sie unter bestimmten Fällen (z. B. Gutachten im Auftrag der Versicherung) mit dem Sachverständigen teilen. Du kannst hier aktivieren, für welche Restwertbörsen du mit dieser Versicherung eine Vereinbarung hast. AutoiXpert kann in dem Fall die Versicherung an die Restwertbörse übertragen, sodass die Restwertbörse das Inserat zuordnen und gemäß der Vereinbarung abrechnen kann.">
                        ?
                    </span>
                </h3>

                <!--********** Add Quota Button **********-->
                <div
                    class="add-residual-value-exchange-quota"
                    *ngIf="!disabled && !isResidualValueExchangeQuotaVisible">
                    <mat-icon>add</mat-icon>
                </div>
            </div>

            <ng-container *ngIf="isResidualValueExchangeQuotaVisible">
                <mat-form-field class="insurance-insurance-company-number mat-block">
                    <input
                        matInput
                        matTooltip="Jede Versicherung hat eine vierstellige Registrierungsnummer bei der BaFin. Diese wird oft als GDV-Nummer bezeichnet und ist notwendig, damit Restwertbörsen ein Inserat direkt mit einer Versicherung abrechnen können."
                        placeholder="VU-Nummer / GDV-Nummer"
                        [(ngModel)]="contactPerson.insuranceCompanyNumber" />
                </mat-form-field>
                <div class="residual-value-exchange-selectors">
                    <div
                        class="residual-value-exchange-container selected"
                        [ngClass]="{ selected: doesResidualValueExchangeHaveAgreement(exchange.id) }"
                        (click)="toggleResidualValueExchangeAgreement(exchange.id)"
                        *ngFor="let exchange of availableResidualValueExchanges">
                        <img
                            class="residual-value-exchange-logo"
                            src="/assets/images/logos/{{ exchange.logoFileName }}" />
                        <div *ngIf="exchange.id === 'carcasion'" class="label">{{ exchange.name }}</div>
                    </div>
                </div>
            </ng-container>
        </section>
        <!--============================================-->
        <!-- END Residual Value Exchange Quotas -->
        <!--============================================-->

        <div id="buttons-container">
            <button id="use-contact-person-button" (click)="useContactPerson()">Übernehmen</button>
            <button (click)="closeSelector()" class="secondary">Abbrechen</button>
        </div>
    </div>
</div>

<div class="dialog-overlay">
    <div class="dialog-container display-flex">
        <!--============================================-->
        <!-- Left Column -->
        <!--============================================-->
        <div id="left-column" class="card card-with-padding">
            <h2>Kontakte importieren</h2>
            <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

            <!--============================================-->
            <!-- Drag & Drop indicator -->
            <!--============================================-->
            <div
                id="drag-and-drop-indicator"
                class="drop-overlay"
                *ngIf="fileIsOverBody"
                [ngClass]="{ 'file-over': fileIsOverDropZone }"
                ng2FileDrop
                (fileOver)="onFileOverDropZone($event)"
                (onFileDrop)="onFileDrop($event)"
                [uploader]="uploader">
                <h2>Datei hierher ziehen</h2>
                <img src="/assets/images/icons/no-data-to-display.png" alt="" />
            </div>
            <!--============================================-->
            <!-- END Drag & Drop indicator -->
            <!--============================================-->

            <!--============================================-->
            <!-- Data Source -->
            <!--============================================-->
            <section id="data-source-section">
                <h3>Datenquelle</h3>
                <div id="data-source-options-row" class="options-row">
                    <div
                        class="option-container"
                        *ngFor="let dataSource of availabeDataSources"
                        [ngClass]="{ active: selectedDataSource === dataSource }"
                        (click)="selectDataSource(dataSource)"
                        tabindex="0">
                        <div class="option-image-container">
                            <ng-container [ngSwitch]="dataSource">
                                <img
                                    class="option-image"
                                    draggable="false"
                                    src="/assets/images/logos/nextsoft.png"
                                    *ngSwitchCase="'nextsoft'" />
                                <img
                                    class="option-image"
                                    draggable="false"
                                    src="/assets/images/logos/easyexpert.png"
                                    *ngSwitchCase="'easyexpert'" />
                                <img
                                    class="option-image"
                                    draggable="false"
                                    src="/assets/images/logos/combiplus.png"
                                    *ngSwitchCase="'combiplus'" />
                                <img
                                    class="option-image"
                                    draggable="false"
                                    src="/assets/images/logos/audatex.svg"
                                    *ngSwitchCase="'audatex'" />
                                <mat-icon class="option-image" *ngSwitchCase="'custom'">people</mat-icon>
                            </ng-container>
                        </div>
                        <div class="option-title-container" [ngSwitch]="dataSource">
                            <span class="option-title" *ngSwitchCase="'nextsoft'">neXtsoft</span>
                            <span class="option-title" *ngSwitchCase="'easyexpert'">EasyExpert</span>
                            <span class="option-title" *ngSwitchCase="'audatex'">Audatex</span>
                            <span class="option-title" *ngSwitchCase="'combiplus'">CombiPlus</span>
                            <span class="option-title" *ngSwitchCase="'custom'">Freier Import</span>
                        </div>
                    </div>
                </div>

                <!--********** Import file description - Custom Import **********-->
                <div class="import-file-description" *ngIf="selectedDataSource === 'custom'">
                    Füge deine Kontakte in die
                    <a href="https://wissen.autoixpert.de/hc/de/articles/360021106780" target="_blank" rel="noopener">
                        CSV-Vorlage aus unserem Wissens-Center
                    </a>
                    ein und lade sie hier hoch.
                </div>
            </section>
            <!--============================================-->
            <!-- END Data Source -->
            <!--============================================-->

            <!--============================================-->
            <!-- Audatex Export Type -->
            <!--============================================-->
            <section id="audatex-export-type-section" *ngIf="selectedDataSource === 'audatex'" @fadeInAndSlide>
                <h3>Wer hat exportiert?</h3>
                <div class="options-row grid">
                    <!--============================================-->
                    <!-- Self-exported -->
                    <!--============================================-->
                    <div
                        class="audatex-export-type-option option-container-with-icon-and-label selection-box option-container"
                        [ngClass]="{ selected: selectedAudatexExportType === 'self' }"
                        (click)="selectedAudatexExportType = 'self'; updateUploaderUrl()">
                        <!--********** Selection Marker **********-->
                        <div class="selection-box-active-marker">
                            <mat-icon>check</mat-icon>
                        </div>

                        <!--********** Option Image **********-->
                        <div class="option-image-container">
                            <mat-icon class="option-image">person</mat-icon>
                        </div>

                        <!--********** Option Title **********-->
                        <div class="option-title">Eigener Export</div>
                        <div class="label">CSV-Datei</div>

                        <div class="option-checklist-container">
                            <!--********** Contact Data **********-->
                            <div class="option-checklist-item">
                                <mat-icon class="small-icon checklist-icon-checked">check</mat-icon>
                                <div class="option-checklist-item-label">Adressdaten</div>
                            </div>

                            <!--********** Garage Fees **********-->
                            <div class="option-checklist-item">
                                <mat-icon class="small-icon checklist-icon-unchecked">close</mat-icon>
                                <div class="option-checklist-item-label">Werkstattkostensätze</div>
                            </div>
                        </div>

                        <a
                            href="https://wissen.autoixpert.de/hc/de/articles/360012501919"
                            class="link"
                            target="_blank"
                            rel="noopener">
                            Anleitung
                        </a>
                    </div>
                    <!--============================================-->
                    <!-- END Self-exported -->
                    <!--============================================-->
                    <!--============================================-->
                    <!-- Exported by Audatex Support -->
                    <!--============================================-->
                    <div
                        class="audatex-export-type-option option-container-with-icon-and-label selection-box option-container"
                        [ngClass]="{ selected: selectedAudatexExportType === 'support' }"
                        (click)="selectedAudatexExportType = 'support'; updateUploaderUrl()">
                        <!--********** Selection Marker **********-->
                        <div class="selection-box-active-marker">
                            <mat-icon>check</mat-icon>
                        </div>

                        <!--********** Option Image **********-->
                        <div class="option-image-container">
                            <mat-icon class="option-image">headset_mic</mat-icon>
                        </div>

                        <!--********** Option Title **********-->
                        <div class="option-title">Audatex-Support</div>
                        <div class="label">ZIP-Datei</div>

                        <div class="option-checklist-container">
                            <!--********** Contact Data **********-->
                            <div class="option-checklist-item">
                                <mat-icon class="small-icon checklist-icon-checked">check</mat-icon>
                                <div class="option-checklist-item-label">Adressdaten</div>
                            </div>

                            <!--********** Garage Fees **********-->
                            <div class="option-checklist-item">
                                <mat-icon class="small-icon checklist-icon-checked">check</mat-icon>
                                <div class="option-checklist-item-label">Werkstattkostensätze</div>
                            </div>
                        </div>

                        <a href="https://www.sv.audatex.de/" class="link" target="_blank" rel="noopener">
                            Audatex-Support
                        </a>
                    </div>
                    <!--============================================-->
                    <!-- END Exported by Audatex Support -->
                    <!--============================================-->
                </div>
            </section>
            <!--============================================-->
            <!-- END Audatex Export Type -->
            <!--============================================-->

            <!--============================================-->
            <!-- File Upload -->
            <!--============================================-->
            <section
                id="file-upload-section"
                *ngIf="
                    selectedDataSource &&
                    (selectedDataSource !== 'audatex' ||
                        (selectedDataSource === 'audatex' && selectedAudatexExportType))
                ">
                <!--********** No File uploaded **********-->
                <ng-container *ngIf="uploader && uploader.queue.length === 0">
                    <!--********** Dropzone **********-->
                    <div id="file-dropzone" class="label">Datei hierher ziehen</div>

                    <!--********** Or **********-->
                    <div>oder</div>

                    <!--********** Upload Button **********-->
                    <div id="file-upload-button-container">
                        <button id="upload-photos" (click)="fileUpload.click()">
                            <mat-icon id="upload-icon" class="medium-icon">publish</mat-icon>
                            <span>Datei hochladen</span>
                        </button>
                        <input
                            type="file"
                            multiple
                            ng2FileSelect
                            [uploader]="uploader"
                            #fileUpload
                            accept=".csv,.xml,.zip" />
                    </div>
                </ng-container>

                <!--********** File has been uploaded **********-->
                <div id="uploaded-file-list-container" *ngIf="uploader && uploader.queue.length > 0">
                    <div class="uploaded-file-container" *ngFor="let item of uploader.queue">
                        <mat-icon class="uploaded-file-attachment-icon">attach_file</mat-icon>
                        <div class="file-name">{{ item._file.name }}</div>
                        <mat-icon class="toolbar-icon" (click)="item.remove()">delete</mat-icon>
                    </div>
                </div>
            </section>
            <!--============================================-->
            <!-- END File Upload -->
            <!--============================================-->

            <!--============================================-->
            <!-- Organization Type -->
            <!--============================================-->
            <section id="organization-type-section" *ngIf="selectedDataSource && selectedDataSource === 'nextsoft'">
                <h3>Gruppe</h3>
                <div class="options-row">
                    <div
                        class="option-container"
                        *ngFor="let organizationType of selectableOrganizationTypes"
                        [ngClass]="{ active: selectedOrganizationType === organizationType }"
                        (click)="selectOrganizationType(organizationType)"
                        tabindex="0">
                        <ng-container [ngSwitch]="organizationType">
                            <img
                                class="option-image"
                                src="/assets/images/icons/claimant-blue.svg"
                                *ngSwitchCase="'claimant'" />
                            <img
                                class="option-image"
                                src="/assets/images/icons/garage-blue.svg"
                                *ngSwitchCase="'garage'" />
                            <img
                                class="option-image"
                                src="/assets/images/icons/lawyer-blue.svg"
                                *ngSwitchCase="'lawyer'" />
                            <img
                                class="option-image"
                                src="/assets/images/icons/insurance-blue.svg"
                                *ngSwitchCase="'insurance'" />
                            <mat-icon class="option-image icon-emphasized" *ngSwitchCase="'bidder'">gavel</mat-icon>
                            <mat-icon class="option-image" *ngSwitchCase="'visitLocationFavorite'">star</mat-icon>
                        </ng-container>
                        <div class="option-title-container" [ngSwitch]="organizationType">
                            <span class="option-title" *ngSwitchCase="'claimant'">Anspruchsteller</span>
                            <span class="option-title" *ngSwitchCase="'garage'">Werkstatt</span>
                            <span class="option-title" *ngSwitchCase="'lawyer'">Rechtsanwalt</span>
                            <span class="option-title" *ngSwitchCase="'bidder'">Restwertbieter</span>
                            <span class="option-title" *ngSwitchCase="'visitLocationFavorite'">
                                Besichtigungs-
                                <br />
                                favorit
                            </span>
                            <span class="option-title" *ngSwitchCase="'insurance'">Versicherung</span>
                        </div>
                    </div>
                </div>
            </section>
            <!--============================================-->
            <!-- END Organization Type -->
            <!--============================================-->

            <div class="dialog-buttons-container">
                <button
                    (click)="uploadImportFile()"
                    [ngClass]="{ disabled: !isUploadAllowed() || fileUploadPending }"
                    [matTooltip]="isUploadAllowed() ? '' : 'Bitte wähle zuerst eine Datenquelle & Gruppe aus'">
                    <img
                        src="/assets/images/icons/loading-indicator-white.svg"
                        alt=""
                        class="pending-icon"
                        *ngIf="fileUploadPending" />
                    Importieren
                </button>
                <button class="secondary" (click)="closeDialog()">Abbrechen</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Left Column -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column -->
        <!--============================================-->
        <div id="right-column" *ngIf="importHistories.length" @slideInAndOutHorizontally>
            <div class="card">
                <h2>Importe</h2>

                <table id="import-history-list">
                    <tr class="import-history-list-entry" *ngFor="let importHistory of importHistories">
                        <!--********** Date **********-->
                        <td class="import-date">
                            <div class="display-flex flex-align-center">
                                <mat-icon class="margin-right-8">today</mat-icon>
                                <div>
                                    <div matTooltip="{{ importHistory.createdAt | moment: 'ddd, DD.MM.YYYY' }}"></div>
                                    {{ importHistory.createdAt | moment }}
                                    <div class="label">{{ importHistory.createdAt | moment: 'HH:mm [Uhr]' }}</div>
                                </div>
                            </div>
                        </td>

                        <!--********** Number of imported objects **********-->
                        <td class="number-of-imported-objects-cell text-align-end">
                            <span class="number-of-imported-objects" matTooltip="Anzahl importierter Datensätze">
                                {{ importHistory.numberOfObjects | number: '1.0-0' }}
                            </span>
                        </td>

                        <td class="import-history-submenu">
                            <mat-icon
                                class="toolbar-icon"
                                [matMenuTriggerFor]="importHistorySubmenu"
                                *ngIf="!importHistoryDeletionPending">
                                more_vert
                            </mat-icon>
                            <mat-menu #importHistorySubmenu>
                                <a mat-menu-item class="menu-item-with-icon" (click)="rewindImport(importHistory)">
                                    <mat-icon>delete</mat-icon>
                                    Importierte Datensätze löschen
                                </a>
                            </mat-menu>

                            <!--********** Pending Indicator **********-->
                            <img
                                src="/assets/images/icons/loading-indicator.svg"
                                *ngIf="importHistoryDeletionPending"
                                matTooltip="Lösche Daten..." />
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Right Column -->
        <!--============================================-->
    </div>
</div>

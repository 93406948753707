<div id="report-list-overlay-container" class="card" *ngIf="reports?.length">
    <!--============================================-->
    <!-- Invoice Table -->
    <!--============================================-->
    <table id="report-list">
        <tr class="report-list-item" *ngFor="let report of reports" (click)="openReport(report)">
            <td class="report-damage-value" *ngIf="reportsWithIdAndDamageValue">
                <span *ngIf="report.token" matTooltip="Honorargrundlage, netto">
                    {{ retrieveDamageValue(report._id) | number: '1.2-2' }} €
                </span>
            </td>

            <td class="report-customer-name">
                <div class="report-customer-organization" *ngIf="report.claimant.contactPerson.organization">
                    {{ report.claimant.contactPerson.organization }}
                </div>
                <div
                    [ngClass]="{ label: report.claimant.contactPerson.organization }"
                    *ngIf="
                        report.claimant.contactPerson.salutation ||
                        report.claimant.contactPerson.firstName ||
                        report.claimant.contactPerson.lastName
                    ">
                    {{ report.claimant.contactPerson.firstName }} {{ report.claimant.contactPerson.lastName }}
                </div>
                <div
                    class="missing-data-info label"
                    *ngIf="
                        !report.claimant.contactPerson.organization &&
                        !report.claimant.contactPerson.firstName &&
                        !report.claimant.contactPerson.lastName
                    ">
                    Kein Name
                </div>
            </td>
            <td class="report-car-model-column">
                <div class="report-car-model-container">
                    <img
                        class="car-make-icon"
                        *ngIf="report.car.make && iconForCarBrandExists(report.car.make)"
                        [src]="iconFilePathForCarBrand(report.car.make)" />
                    <div class="report-car-model-label">
                        <!--********** Make **********-->
                        <span class="car-make" *ngIf="report.car.make">{{ report.car.make }}&nbsp;</span>
                        <span class="missing-data-info label" *ngIf="!report.car.make">Kein Hersteller &nbsp;</span>

                        <!--********** Model **********-->
                        <span *ngIf="report.car.model" class="car-model" [title]="report.car.model">
                            {{ cleanBaseModel(report.car.model) }}
                        </span>
                        <span class="missing-data-info label" *ngIf="!report.car.model">Kein Modell</span>
                    </div>
                </div>
            </td>
            <td class="report-createdAt">
                <!--********** Unlocked Report **********-->
                <span
                    *ngIf="!isReportLocked(report)"
                    matTooltip="Erstellt: {{ report.createdAt | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                    {{ report.createdAt | moment: 'dd, DD.MM.YYYY' }}
                </span>

                <!--********** Locked Report **********-->
                <span
                    *ngIf="isReportLocked(report)"
                    matTooltip="Fertigstellung: {{ report.completionDate | moment: 'DD.MM.YYYY - HH:mm' }} Uhr{{
                        '\n'
                    }}Abgeschlossen: {{ report.lockedAt | moment: 'DD.MM.YYYY - HH:mm' }} Uhr">
                    {{ report.completionDate | moment: 'dd, DD.MM.YYYY' }}
                </span>
            </td>
        </tr>
    </table>
    <!--============================================-->
    <!-- END Invoice Table -->
    <!--============================================-->
</div>

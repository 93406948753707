import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { matchAssociatedOfficeLocation } from '@autoixpert/lib/counters/match-associated-office-location';
import { generateId } from '@autoixpert/lib/generate-id';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { InvoiceNumberOrReportTokenCounter } from '@autoixpert/models/teams/invoice-number-or-report-token-counter';
import { InvoiceNumberConfig, ReportTokenConfig } from '@autoixpert/models/teams/invoice-or-report-counter-config';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { getMissingAccessRightTooltip } from '../../../shared/libraries/get-missing-access-right-tooltip';
import { getReportInvoiceNumberPatternWithoutSubCounter } from '../../../shared/libraries/preferences/get-report-invoice-number-pattern-without-sub-counter';
import { getTokenPreview } from '../../../shared/libraries/preferences/get-token-preview';
import { hasAccessRight } from '../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { InvoiceNumberOrReportTokenCounterService } from '../../../shared/services/invoice-number-or-report-token-counter.service';
import { TeamService } from '../../../shared/services/team.service';
import { ToastService } from '../../../shared/services/toast.service';
import { ReportInvoiceNumberConfigDialogComponent } from '../report-invoice-number-config-dialog/report-invoice-number-config-dialog.component';
import { SelectLeadingCounterDialogComponent } from '../select-leading-counter-dialog/select-leading-counter-dialog.component';

@Component({
    selector: 'report-token-preferences',
    templateUrl: './report-token-preferences.component.html',
    styleUrls: ['./report-token-preferences.component.scss'],
    animations: [slideInAndOutVertically()],
})
export class ReportTokenPreferencesComponent implements OnInit {
    constructor(
        private toastService: ToastService,
        private teamService: TeamService,
        private counterService: InvoiceNumberOrReportTokenCounterService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
        private apiErrorService: ApiErrorService,
        private overlayService: Overlay,
        private injector: Injector,
        private dialog: MatDialog,
    ) {}

    @Input() public user: User;
    @Input() public team: Team;
    @Input() public teamMembers: User[];

    // Token configs
    protected reportTokenConfigs: ReportTokenConfig[] = [];
    /**
     * Expanded state of report token config sections per office location.
     * This is only relevant if more than two report token configs exist.
     * Each record stores the expanded state (boolean) under the configs key (string).
     */
    protected reportTokenConfigExpandedStates: Record<string, boolean> = {};

    public reportTokenConfigTitlesInEditMode: Map<ReportTokenConfig, void> = new Map();

    /**
     * If there are more than this amount of counter configs, we collapse them by default to save space.
     * They can then be expanded and collapsed by the user.
     */
    protected collapseThreshold = 3;

    // References to the settings overlays
    private selectLeadingCounterOverlayRef: OverlayRef;
    private editReportInvoiceNumberConfigOverlayRef: OverlayRef;

    // Because report counters need to be fetched from the server, we cache them here for performance
    // They are used for the token preview, so it does not matter if they are out of sync eventually
    private dummyInvoiceCounter: InvoiceNumberOrReportTokenCounter = new InvoiceNumberOrReportTokenCounter();
    private cachedReportCounters: Record<string, InvoiceNumberOrReportTokenCounter> = {};

    //*****************************************************************************
    //  Initialize
    //****************************************************************************/
    async ngOnInit(): Promise<void> {
        this.reportTokenConfigs = this.team.reportTokenConfigs;
        void this.loadCounters();
    }

    /**
     * Fetch and cache the counters for all report token and invoice number configs.
     */
    private async loadCounters(): Promise<void> {
        this.cachedReportCounters = {};

        for (const config of this.reportTokenConfigs) {
            this.cachedReportCounters[config._id] = await this.counterService.get(config._id);
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialize
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Invoice Number Counter for Sync
    //****************************************************************************/

    public getInvoiceNumberCounterIdToSyncWith(reportTokenConfig: ReportTokenConfig): string {
        return this.getInvoiceNumberCounterToSyncWith(reportTokenConfig)._id;
    }

    public getInvoiceNumberCounterToSyncWith(reportTokenConfig: ReportTokenConfig): InvoiceNumberConfig {
        return matchAssociatedOfficeLocation(
            this.team.invoicing.invoiceNumberConfigs,
            reportTokenConfig.associatedOfficeLocationIds?.[0],
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Number Counter for Sync
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Get a string that lists all office locations (separated with a comma) for the given report token
     * config.
     */
    protected getOfficeLocationTitle(counterConfig: ReportTokenConfig): string {
        return (
            this.team.officeLocations
                .filter((location) => counterConfig.associatedOfficeLocationIds.includes(location._id))
                .map((location) => location.title)
                .join(', ') || (counterConfig.isDefault ? 'Standard' : 'Alle anderen Standorte')
        );
    }

    public getSetAsDefaultTooltip(reportTokenConfig: ReportTokenConfig): string {
        const tooltip = reportTokenConfig.isDefault ? '' : 'Als Standard setzen.\n\n';

        return `${tooltip}Das Standard-Aktenzeichen gilt für alle Standorte, die mit keinem Aktenzeichenkreis verknüpft sind.`;
    }

    public setAsDefault(newDefaultReportTokenConfig: ReportTokenConfig) {
        newDefaultReportTokenConfig.isDefault = true;

        // Remove default from others
        for (const reportTokenConfig of this.reportTokenConfigs) {
            if (reportTokenConfig._id !== newDefaultReportTokenConfig._id && reportTokenConfig.isDefault) {
                reportTokenConfig.isDefault = false;
                this.saveTeam();
            }
        }
    }

    /**
     * Opens the dialog where the user selects which counter should be the leading one.
     */
    protected openLeadingCounterDialog(reportTokenConfig: ReportTokenConfig) {
        if (this.selectLeadingCounterOverlayRef) return;

        // Configure overlay
        this.selectLeadingCounterOverlayRef = this.overlayService.create({
            hasBackdrop: true,
            positionStrategy: this.overlayService.position().global().centerHorizontally().centerVertically(),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
            width: 720,
            maxWidth: '95vw',
        });

        // Close panel when clicking the backdrop.
        this.selectLeadingCounterOverlayRef.backdropClick().subscribe(() => {
            this.selectLeadingCounterOverlayRef.detach();
        });
        this.selectLeadingCounterOverlayRef.detachments().subscribe(() => {
            this.selectLeadingCounterOverlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<SelectLeadingCounterDialogComponent>(
            SelectLeadingCounterDialogComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.selectLeadingCounterOverlayRef,
                    },
                ],
            }),
        );

        // Attach Component to Portal Outlet
        const componentRef = this.selectLeadingCounterOverlayRef.attach(componentPortal);

        // Update component properties.
        componentRef.instance.counterId = reportTokenConfig._id;
        componentRef.instance.selectedCounterType = reportTokenConfig.leadingCounter;

        // Subscribe to changes in component.
        componentRef.instance.selectionChanged.subscribe(({ leadingCounter, counterId }) => {
            const reportTokenConfig = this.reportTokenConfigs.find((config) => config._id === counterId);

            reportTokenConfig.leadingCounter = leadingCounter;

            if (reportTokenConfig.leadingCounter === 'reportToken' && !reportTokenConfig.reportInvoiceNumberPattern) {
                // Set default for report invoice number pattern
                reportTokenConfig.reportInvoiceNumberPattern = '{AZ}/{R}';
                // Set default for starting index
                reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice = true;
            }

            void this.saveTeam();
        });
    }

    /**
     * Opens the dialog where the user can edit settings for the invoice numbers that are based on report tokens.
     */
    public openReportInvoiceNumberConfigDialog(reportTokenConfig: ReportTokenConfig) {
        if (this.editReportInvoiceNumberConfigOverlayRef) return;

        // Configure overlay
        this.editReportInvoiceNumberConfigOverlayRef = this.overlayService.create({
            hasBackdrop: true,
            positionStrategy: this.overlayService.position().global().centerHorizontally().centerVertically(),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
            width: 1000,
            maxWidth: '95vw',
        });

        // Close panel when clicking the backdrop.
        this.editReportInvoiceNumberConfigOverlayRef.backdropClick().subscribe(() => {
            this.editReportInvoiceNumberConfigOverlayRef.detach();
        });
        this.editReportInvoiceNumberConfigOverlayRef.detachments().subscribe(() => {
            this.editReportInvoiceNumberConfigOverlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<ReportInvoiceNumberConfigDialogComponent>(
            ReportInvoiceNumberConfigDialogComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.editReportInvoiceNumberConfigOverlayRef,
                    },
                ],
            }),
        );

        // Attach Component to Portal Outlet
        const componentRef = this.editReportInvoiceNumberConfigOverlayRef.attach(componentPortal);

        // Update component properties.
        componentRef.instance.invoiceNumberConfig = this.getInvoiceNumberCounterToSyncWith(reportTokenConfig);
        componentRef.instance.reportTokenConfig = reportTokenConfig;
    }

    protected getInvoiceNumberPreviewString(reportTokenConfig: ReportTokenConfig): string {
        if (!reportTokenConfig) {
            return '';
        }

        // Use cached counters or fallback to empty counters (starting from 0) -> necessary if user is offline
        const reportTokenCounter = this.cachedReportCounters[reportTokenConfig._id];

        const patternWithoutSubCounter = getReportInvoiceNumberPatternWithoutSubCounter(reportTokenConfig);

        const nextReportToken = getTokenPreview(
            reportTokenCounter,
            reportTokenConfig,
            'reportToken',
            false,
            this.team,
            this.user,
            reportTokenConfig.pattern,
        );

        return getTokenPreview(
            this.dummyInvoiceCounter,
            reportTokenConfig,
            'invoiceNumber',
            false,
            this.team,
            this.user,
            reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice
                ? patternWithoutSubCounter
                : reportTokenConfig.reportInvoiceNumberPattern,
            null,
            nextReportToken,
        );
    }

    /**
     * Determine if any report token config is synced with an invoice counter (leadingCounter => invoiceNumber).
     */
    protected isAnyConfigSyncedWithInvoiceCounter(): boolean {
        return this.reportTokenConfigs.some(
            (config) => config.syncWithInvoiceCounter && config.leadingCounter === 'invoiceNumber',
        );
    }

    protected syncWithInvoiceCounterSettingChanged(reportTokenConfig: ReportTokenConfig): void {
        if (reportTokenConfig.syncWithInvoiceCounter && !reportTokenConfig.leadingCounter) {
            // Set default leading counter
            reportTokenConfig.leadingCounter = 'reportToken';
            // Set default for report invoice number pattern
            reportTokenConfig.reportInvoiceNumberPattern = '{AZ}/{R}';
            // Set default for starting index
            reportTokenConfig.startReportInvoiceSubCounterWithSecondInvoice = true;
        }

        this.saveTeam();

        this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterChanged',
            syncWithInvoiceCounter: reportTokenConfig.syncWithInvoiceCounter,
            reportTokenConfigId: reportTokenConfig._id,
        });
    }

    //*****************************************************************************
    //  CRUD ReportTokenConfig
    //****************************************************************************/
    public async addReportTokenConfig() {
        const newCounter = new InvoiceNumberOrReportTokenCounter({ _id: generateId() });
        try {
            await this.counterService.create(newCounter);
            await this.invoiceNumberJournalEntryService.create({
                entryType: 'invoiceOrReportCounterCreated',
                reportTokenConfigId: newCounter._id,
            });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Zähler nicht angelegt',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }

        const newReportTokenConfig = new ReportTokenConfig({ _id: newCounter._id });
        this.reportTokenConfigs.push(newReportTokenConfig);
        this.saveTeam();

        // Reload the cached counters
        this.loadCounters();
    }

    public async deleteReportTokenConfig(reportTokenConfig: ReportTokenConfig) {
        if (reportTokenConfig.isDefault) {
            this.toastService.error(
                'Aktenzeichen ist Standard',
                'Das Standard-Aktenzeichen kann nicht gelöscht werden. Bitte ändere den Standard und probiere das Löschen erneut.',
            );
            return;
        }

        // Delete counter
        await this.counterService.delete(reportTokenConfig._id);
        await this.invoiceNumberJournalEntryService.create({
            entryType: 'invoiceOrReportCounterDeleted',
            reportTokenConfigId: reportTokenConfig._id,
        });

        // Delete config from team
        removeFromArray(reportTokenConfig, this.reportTokenConfigs);
        this.saveTeam();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD ReportTokenConfig
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Copy Settings to all Counters
    //****************************************************************************/

    protected async copyTokenSettingsToAllLocations(configToCopy: ReportTokenConfig) {
        // Let's make sure the user really wants to overwrite all other counters
        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Einstellungen übertragen',
                    content:
                        'Möchtest du wirklich das Aktenzeichenmuster des gewählten Aktenzeichenzählers auf alle anderen Aktenzeichenzähler übertragen?\n\nDies kann nicht rückgängig gemacht werden.',
                    confirmLabel: 'Ja, überschreiben',
                    cancelLabel: 'Abbrechen',
                },
            })
            .afterClosed()
            .toPromise();
        if (!decision) return;

        for (const tokenConfig of this.reportTokenConfigs) {
            if (tokenConfig._id !== configToCopy._id) {
                if (tokenConfig.pattern !== configToCopy.pattern) {
                    const previousCounterPattern = tokenConfig.pattern;
                    tokenConfig.pattern = configToCopy.pattern;
                    await this.invoiceNumberJournalEntryService.create({
                        entryType: 'invoiceOrReportCounterChanged',
                        reportTokenConfigId: tokenConfig._id,
                        counterPattern: tokenConfig.pattern,
                        previousCounterPattern,
                    });
                }
            }
        }
        void this.saveTeam();
    }

    protected async copySyncedCounterSettingsToAllLocations(configToCopy: ReportTokenConfig) {
        // Let's make sure the user really wants to overwrite all other counters
        const decision = await this.dialog
            .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                data: {
                    heading: 'Einstellungen übertragen',
                    content:
                        'Möchtest du wirklich die Koppelungs-Einstellungen des gewählten Aktenzeichenzählers auf alle anderen Aktenzeichenzähler übertragen?\n\nDies kann nicht rückgängig gemacht werden.',
                    confirmLabel: 'Ja, überschreiben',
                    cancelLabel: 'Abbrechen',
                },
            })
            .afterClosed()
            .toPromise();
        if (!decision) return;

        for (const tokenConfig of this.reportTokenConfigs) {
            if (tokenConfig._id !== configToCopy._id) {
                if (tokenConfig.syncWithInvoiceCounter !== configToCopy.syncWithInvoiceCounter) {
                    tokenConfig.syncWithInvoiceCounter = configToCopy.syncWithInvoiceCounter;
                    await this.invoiceNumberJournalEntryService.create({
                        entryType: 'invoiceOrReportCounterChanged',
                        reportTokenConfigId: tokenConfig._id,
                        syncWithInvoiceCounter: tokenConfig.syncWithInvoiceCounter,
                    });
                }

                if (tokenConfig.leadingCounter !== configToCopy.leadingCounter) {
                    tokenConfig.leadingCounter = configToCopy.leadingCounter;
                    await this.invoiceNumberJournalEntryService.create({
                        entryType: 'invoiceOrReportCounterChanged',
                        reportTokenConfigId: tokenConfig._id,
                        leadingCounter: tokenConfig.leadingCounter,
                    });
                }

                if (configToCopy.leadingCounter === 'reportToken') {
                    // Only copy settings that are currently in use
                    if (
                        tokenConfig.startReportInvoiceSubCounterWithSecondInvoice !==
                        configToCopy.startReportInvoiceSubCounterWithSecondInvoice
                    ) {
                        tokenConfig.startReportInvoiceSubCounterWithSecondInvoice =
                            configToCopy.startReportInvoiceSubCounterWithSecondInvoice;

                        await this.invoiceNumberJournalEntryService.create({
                            entryType: 'invoiceOrReportCounterChanged',
                            reportTokenConfigId: tokenConfig._id,
                            startReportInvoiceSubCounterWithSecondInvoice:
                                tokenConfig.startReportInvoiceSubCounterWithSecondInvoice,
                        });
                    }

                    if (tokenConfig.reportInvoiceNumberPattern !== configToCopy.reportInvoiceNumberPattern) {
                        tokenConfig.reportInvoiceNumberPattern = configToCopy.reportInvoiceNumberPattern;
                        await this.invoiceNumberJournalEntryService.create({
                            entryType: 'invoiceOrReportCounterChanged',
                            reportTokenConfigId: tokenConfig._id,
                            reportInvoiceNumberPattern: tokenConfig.reportInvoiceNumberPattern,
                        });
                    }

                    tokenConfig.useCancellationInvoiceSuffixForReportInvoices =
                        configToCopy.useCancellationInvoiceSuffixForReportInvoices;

                    if (configToCopy.useCancellationInvoiceSuffixForReportInvoices) {
                        // Only copy settings that are currently in use
                        if (
                            tokenConfig.reportInvoiceCancellationSuffix !== configToCopy.reportInvoiceCancellationSuffix
                        ) {
                            const previousReportInvoiceCancellationSuffix = tokenConfig.reportInvoiceCancellationSuffix;
                            tokenConfig.reportInvoiceCancellationSuffix = configToCopy.reportInvoiceCancellationSuffix;
                            await this.invoiceNumberJournalEntryService.create({
                                entryType: 'invoiceOrReportCounterChanged',
                                reportTokenConfigId: tokenConfig._id,
                                reportInvoiceCancellationSuffix: tokenConfig.reportInvoiceCancellationSuffix,
                                previousReportInvoiceCancellationSuffix,
                            });
                        }
                    }
                }
            }
        }
        void this.saveTeam();
    }

    //*****************************************************************************
    //  END Copy Settings to all Counters
    //****************************************************************************/

    //*****************************************************************************
    //  Title Edit Mode
    //****************************************************************************/
    startTitleEditMode(reportTokenConfig: ReportTokenConfig) {
        this.reportTokenConfigTitlesInEditMode.set(reportTokenConfig, undefined);
    }

    leaveTitleEditMode(reportTokenConfig: ReportTokenConfig) {
        this.reportTokenConfigTitlesInEditMode.delete(reportTokenConfig);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Title Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handlers - could we optimize this?
    //****************************************************************************/
    // TODO: can we make the save operation a method of team object?
    public async saveTeam(): Promise<void> {
        try {
            await this.teamService.put(this.team);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Team nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public userIsAdmin(): boolean {
        return isAdmin(this.user._id, this.team);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handlers - can we optimize this?
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

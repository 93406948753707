import { Component, Input } from '@angular/core';

@Component({
    selector: 'preferences-row',
    templateUrl: './preferences-row.component.html',
    styleUrls: ['./preferences-row.component.scss'],
})
export class PreferencesRowComponent {
    @Input() iconName: string = '';
    @Input() noHorizontalPadding: boolean = false;
}

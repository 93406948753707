<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="emitCloseEvent()">close</mat-icon>
        <h2 *ngIf="!teamMemberToReactivate">Nutzer hinzufügen</h2>
        <h2 *ngIf="teamMemberToReactivate">Nutzer reaktivieren</h2>
        <p *ngIf="!teamMemberToReactivate" class="text-align-center">
            Schön, dass du mit autoiXpert wächst!
            <br />
            Was für einen Nutzer möchtest du anlegen?
        </p>
        <p *ngIf="teamMemberToReactivate" class="text-align-center">Wie möchtest du den Nutzer reaktivieren?</p>

        <!--============================================-->
        <!-- Deactivation Date -->
        <!--============================================-->
        <div id="user-subscription-duration-selector">
            <div
                class="large-selection-box"
                (click)="isTemporaryUser = false"
                [ngClass]="{ selected: !isTemporaryUser }">
                <mat-icon class="large-selection-box-option-icon">person</mat-icon>
                <div class="semibold">unbefristeter Nutzer</div>
                <div class="label">z. B. fester Mitarbeiter</div>

                <!--********** Selection Marker **********-->
                <div class="large-selection-box-active-marker">
                    <mat-icon>check</mat-icon>
                </div>
            </div>
            <div class="large-selection-box" (click)="isTemporaryUser = true" [ngClass]="{ selected: isTemporaryUser }">
                <mat-icon class="large-selection-box-option-icon">beach_access</mat-icon>
                <div class="semibold">befristeter Nutzer</div>
                <div class="label">z. B. Urlaubsvertretung, Praktikant</div>

                <!--********** Selection Marker **********-->
                <div class="large-selection-box-active-marker">
                    <mat-icon>check</mat-icon>
                </div>
            </div>
        </div>
        <div *ngIf="isTemporaryUser" id="deactivation-date-input-container" @fadeInAndOut>
            <date-input
                [(date)]="deactivationDate"
                placeholder="Automatisch deaktivieren am"
                matTooltip="Kürzel: 2t = in zwei Tagen, 3w = in drei Wochen, 1m = in einem Monat"></date-input>
        </div>
        <!--============================================-->
        <!-- END Deactivation Date -->
        <!--============================================-->

        <!--============================================-->
        <!-- Pricing Table -->
        <!--============================================-->
        <div id="pricing-table-container">
            <table id="pricing-table">
                <thead>
                    <th></th>
                    <th>
                        <div class="label">Preis/Monat</div>
                    </th>
                </thead>
                <tbody>
                    <!--============================================-->
                    <!-- Display both options during test - With/Without Audatex Add-on -->
                    <!--============================================-->
                    <!--********** Exclude Qapter-iXpert customers from possibly seeing both options. **********-->
                    <ng-container *ngIf="isTestOrPartnerAccount() && !team.audatexFeatures.qapterixpertLayout">
                        <tr>
                            <td class="item-title-cell">
                                <div class="semibold">
                                    mit Audatex Add-on
                                    <span
                                        class="help-indicator"
                                        matTooltip="Ob du das Audatex-Addon buchen möchtest, kannst du bei der Bestellung deines Büro-Accounts angeben.">
                                        ?
                                    </span>
                                </div>
                                <div class="label">Audatex, DAT & GT Motive verfügbar</div>
                            </td>
                            <td class="price-cell">30 €</td>
                        </tr>
                        <tr>
                            <td class="item-title-cell">
                                <div class="semibold">ohne Audatex Add-on</div>
                                <div class="label">DAT & GT Motive verfügbar</div>
                            </td>
                            <td class="price-cell">20 €</td>
                        </tr>
                    </ng-container>
                    <!--============================================-->
                    <!-- END Display both options during test - With/Without Audatex Add-on -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Paying Customer - Only display relevant price -->
                    <!--============================================-->
                    <ng-container *ngIf="!isTestOrPartnerAccount()">
                        <tr *ngIf="team.audatexFeatures.hasAudatexAddon">
                            <td class="item-title-cell">
                                <div class="semibold">Zusatznutzer</div>
                                <div class="label">Audatex, DAT & GT Motive verfügbar</div>
                            </td>
                            <td class="price-cell">30 €</td>
                        </tr>
                        <tr *ngIf="!team.audatexFeatures.hasAudatexAddon">
                            <td class="item-title-cell">
                                <div class="semibold">Zusatznutzer</div>
                                <div class="label">DAT & GT Motive verfügbar</div>
                            </td>
                            <td class="price-cell">20 €</td>
                        </tr>
                    </ng-container>
                    <!--============================================-->
                    <!-- END Paying Customer - Only display relevant price -->
                    <!--============================================-->
                </tbody>
            </table>
            <div class="label text-align-center">
                <span *ngIf="isTestOrPartnerAccount()">
                    Während des Tests sind zusätzliche Nutzer kostenfrei.
                    <br />
                    Für die Zeit danach:
                </span>
                <!--********** Paying Account **********-->
                <span *ngIf="!isTestOrPartnerAccount()">
                    Sobald dein neuer Kollege sich registriert hat, werden wir deine Rechnung automatisch anpassen.
                </span>

                <!--********** Price List **********-->
                <a
                    href="https://www.autoixpert.de/Preise?utm_source=autoixpert-settings&utm_medium=invite-colleagues-dialog"
                    target="_blank"
                    rel="”noopener”">
                    Preisliste ansehen
                </a>
            </div>
        </div>
        <!--============================================-->
        <!-- END Pricing Table -->
        <!--============================================-->

        <div class="send-invitation-container" *ngIf="!teamMemberToReactivate">
            <p class="text-align-center">
                Wir senden deinem neuen Kollegen per E-Mail eine Einladung. Seine Zugriffsrechte kannst du im nächsten
                Schritt vergeben.
            </p>
            <mat-form-field id="email-input-container" class="mat-block">
                <input matInput type="email" [(ngModel)]="email" placeholder="E-Mail" name="invite-coworker-email" />
            </mat-form-field>
            <div class="dialog-buttons-container">
                <button
                    (click)="sendInvitation()"
                    [ngClass]="{ disabled: !isEmailValid() }"
                    [matTooltip]="
                        (isEmailValid() ? '' : 'Bitte eine gültige E-Mailadresse eingeben.') +
                        'Diese E-Mailadresse ist der Login des neuen Nutzers und kann nachträglich nicht mehr geändert werden.'
                    ">
                    <mat-icon *ngIf="!userInvitationPending">send</mat-icon>
                    <img
                        *ngIf="userInvitationPending"
                        src="/assets/images/icons/loading-indicator-white.svg"
                        class="pending-icon" />
                    <span>Einladen</span>
                </button>
            </div>
        </div>
        <div class="send-reactivation-container" *ngIf="teamMemberToReactivate">
            <p class="text-align-center">Dein Kollege wird direkt aktiviert und kann sich wieder anmelden.</p>
            <div class="dialog-buttons-container">
                <button (click)="reactivateTeamMember()" [ngClass]="{ disabled: false }">
                    <mat-icon *ngIf="!userInvitationPending">check</mat-icon>
                    <img
                        *ngIf="userInvitationPending"
                        src="/assets/images/icons/loading-indicator-white.svg"
                        class="pending-icon" />
                    <span>Aktivieren</span>
                </button>
            </div>
        </div>
    </div>
</div>

import { Report } from '../../models/reports/report';

/**
 * Calculate the total travel expenses.
 *
 * If the user chose a fixed amount, return that. Otherwise, multiply the number of kilometers driven by the price per kilometer.
 */
export function getTravelExpensesTotal(report: Pick<Report, 'feeCalculation'>): number {
    let total: number;
    if (report.feeCalculation.useTravelExpensesFixedPrice) {
        total = report.feeCalculation.travelExpensesFixedPrice;
    } else {
        total = report.feeCalculation.numberOfKilometers * report.feeCalculation.pricePerKilometer;
    }
    return total === null || isNaN(total) ? 0 : total;
}

import moment from 'moment';
import 'moment/locale/de';
import { toIsoDate } from './iso-date';
import { IsoDate } from './iso-date.types';

export function getRelativeDate(date: IsoDate | Date, today = toIsoDate(moment())): string {
    if (!date) {
        return '';
    }

    // use 12:00 as the reference time to avoid issues with daylight saving time and timezones
    const todayDate = moment(today).hours(12).minutes(0).seconds(0);
    let givenDate = moment(date);
    if (typeof date === 'string') {
        givenDate = moment(date, 'YYYY-MM-DD');
    }

    // moment.diff does not include the current day, therefore we check isSameDay before.
    if (todayDate.isSame(givenDate, 'day')) {
        return 'heute';
    }

    const diffInDays = givenDate.diff(todayDate, 'days');
    if (diffInDays === -1) {
        return 'gestern';
    }

    // moment.diff does not include the current day, therefore we check isSameDay before.
    // Here we know that 0 means the next day.
    if (diffInDays === 0) {
        return 'morgen';
    }

    // Show the weekday if the date is within the next seven days
    if (diffInDays > 0 && diffInDays < 6) {
        return givenDate.format('dddd');
    }

    // Show only day and month if the date is within the current year
    if (todayDate.year() === givenDate.year()) {
        return givenDate.format('D. MMMM');
    }

    return givenDate.format('D. MMMM YYYY');
}

<div class="dialog-overlay">
    <div class="dialog-container contains-scrollable-area">
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
        <div class="dialog-scrollable-area">
            <h2>Audatex-Kalkulation einfügen</h2>

            <div class="info-note margin-bottom-20" *ngIf="duplexInterfaceNoteVisible" @slideOutVertical>
                <mat-icon class="outlined">info</mat-icon>
                <div>
                    Hier fügst du einen einfachen Kalkulationstext ein, Eckdaten werden ausgelesen. Es gibt auch die
                    <a href="https://wissen.autoixpert.de/hc/de/articles/4405896877842" target="_blank" rel="noopener">
                        komfortable Voll-Schnittstelle
                    </a>
                    zu Audatex Qapter.
                    <p *ngIf="isAudatexTestAccount()">
                        Für die Voll-Schnittstelle muss das Fahrzeug über die Audatex-VIN-Abfrage (Reiter
                        <i>Fahrzeugauswahl</i>
                        ) identifiziert worden sein.
                    </p>
                </div>
                <mat-icon class="info-note-close-icon toolbar-icon" (click)="duplexInterfaceNoteVisible = false">
                    close
                </mat-icon>
            </div>

            <div class="label text-align-center">
                Füge hier die
                <a
                    href="https://wissen.autoixpert.de/hc/de/articles/360022956412-Wie-kann-ich-eine-audatex-Kalkulation-in-autoiXpert-durchf%C3%BChren-"
                    target="_blank">
                    Textversion der Audatex-Kalkulation
                </a>
                ein.
            </div>
            <div class="text-align-center label"></div>
            <mat-form-field class="mat-block">
                <textarea
                    matInput
                    placeholder="Audatex-Kalkulation"
                    [(ngModel)]="calculationText"
                    id="audatex-calculation-textarea"
                    name="audatex-calculation"
                    cdkTextareaAutosize></textarea>
            </mat-form-field>
        </div>
        <div class="buttons-container">
            <button (click)="emitCalculationChange(); closeDialog()">Übernehmen</button>
        </div>
    </div>
</div>

import { Translator } from '@autoixpert/lib/placeholder-values/translator';
import { ChecklistItemName } from '@autoixpert/models/report-progress/report-progress-checklist-item-names';
import { Report } from '@autoixpert/models/reports/report';

export function getChecklistItemLabelAndTooltip({
    checklistItemName,
    report,
}: {
    checklistItemName: ChecklistItemName;
    report: Report;
}): { label: string; tooltip: string } {
    switch (checklistItemName) {
        //*****************************************************************************
        //  Accident Data
        //****************************************************************************/
        case 'claimantNameAndAddress': {
            // Only add a the claimant denomination if there is a chance of confusion with the author of damage.
            const claimantName = Array<Report['type']>('liability', 'shortAssessment').includes(report.type)
                ? Translator.claimantDenomination(report.type)
                : '';
            return {
                label: `${claimantName} Name & Adresse`,
                tooltip: null,
            };
        }
        case 'claimantEmail': {
            // Only add a the claimant denomination if there is a chance of confusion with the author of damage.
            const claimantName = Array<Report['type']>('liability', 'shortAssessment').includes(report.type)
                ? Translator.claimantDenomination(report.type)
                : '';
            return {
                label: `${claimantName} E-Mail`,
                tooltip: null,
            };
        }
        case 'claimantLicensePlate': {
            // Only add a the claimant denomination if there is a chance of confusion with the author of damage.
            const claimantName = Array<Report['type']>('liability', 'shortAssessment').includes(report.type)
                ? Translator.claimantDenomination(report.type)
                : '';
            return {
                label: `${claimantName} Kennzeichen`,
                tooltip: null,
            };
        }
        case 'lawyerNameAndAddress':
            return {
                label: 'Anwalt',
                tooltip: null,
            };
        case 'accidentDate':
            return {
                label: report.type === 'partialKasko' || report.type === 'fullKasko' ? 'Schadentag' : 'Unfalltag',
                tooltip: null,
            };
        case 'partialKaskoDamageType':
            return {
                label: 'Schadenart',
                tooltip: null,
            };
        case 'visitLocationAndDate':
            return {
                label: 'Besichtigung',
                tooltip: 'Besichtigungsort & Datum',
            };
        case 'authorOfDamageLicensePlate':
            return {
                label: 'Unfallgegner Kennzeichen',
                tooltip: null,
            };
        case 'leaseProviderNameAndAddress':
            return {
                label: 'Leasinggeber Name & Adresse',
                tooltip: null,
            };
        case 'leaseContractNumber':
            return {
                label: 'Vertragsnummer',
                tooltip: 'Vertragsnummer Leasing',
            };
        case 'insuranceName':
            return {
                label: 'Versicherungsname',
                tooltip: null,
            };
        case 'insuranceNumber':
            return {
                label: 'Versicherungsnummer',
                tooltip: null,
            };
        case 'insuranceCaseNumber':
            return {
                label: 'Schadennummer',
                tooltip: null,
            };
        case 'declarationOfAssignment': {
            let documentName: 'Abtretung' | 'Auftrag';
            switch (report.type) {
                case 'liability':
                case 'shortAssessment':
                    documentName = 'Abtretung';
                    break;
                default:
                    documentName = 'Auftrag';
            }
            return {
                label: documentName,
                tooltip: null,
            };
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Accident Data
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Car Data
        //****************************************************************************/
        case 'vin':
            return {
                label: 'VIN',
                tooltip: null,
            };
        case 'vinWasChecked':
            return {
                label: 'VIN am Fahrzeug überprüft',
                tooltip: null,
            };
        case 'carMakeAndModel':
            return {
                label: 'Hersteller & Modell',
                tooltip: null,
            };
        case 'productionYear':
            return {
                label: 'Baujahr',
                tooltip: null,
            };
        case 'firstRegistration':
            return {
                label: 'Erstzulassung',
                tooltip: null,
            };
        case 'latestRegistration':
            return {
                label: 'Letzte Zulassung',
                tooltip: null,
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Car Data
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Car Condition
        //****************************************************************************/
        case 'conditionInputs':
            return {
                label: 'Zustandsfelder',
                tooltip: 'Allgemein-, Karosserie-, Lack- & Innenraumzustand',
            };
        case 'roadworthiness':
            return {
                label: 'Fahrfähigkeit',
                tooltip: null,
            };
        case 'mileage':
            return {
                label: 'Laufleistung',
                tooltip: null,
            };
        case 'nextGeneralInspection':
            return {
                label: 'Nächste HU',
                tooltip: null,
            };
        case 'damageSketch':
            return {
                label: 'Anstoßskizze',
                tooltip: null,
            };
        case 'damageDescription':
            return {
                label: 'Schadenbeschreibung',
                tooltip: null,
            };
        case 'paintThickness':
            return {
                label: 'Lackschichtdicke',
                tooltip: null,
            };
        case 'repairedPreviousDamage':
            return {
                label: 'Vorschäden',
                tooltip: null,
            };
        case 'unrepairedPreviousDamage':
            return {
                label: 'Altschäden',
                tooltip: null,
            };
        case 'tires':
            return {
                label: 'Reifen',
                tooltip: null,
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Car Condition
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Photos
        //****************************************************************************/
        case 'photosUploaded':
            return {
                label: 'hochgeladen',
                tooltip: 'Fotos hochgeladen',
            };
        case 'somePhotosNamed':
            return {
                label: 'beschriftet',
                tooltip: 'Einige Fotos beschriftet',
            };
        case 'somePhotosHidden':
            return {
                label: 'ausgeblendet',
                tooltip: 'Einige Fotos ausgeblendet',
            };
        case 'someResidualValueVersionsAdded':
            return {
                label: 'Versionen für Börsen',
                tooltip: 'Einige Fotos haben Versionen für die Restwertbörse',
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Photos
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Damage Calculation
        //****************************************************************************/
        case 'garage':
            return {
                label: 'Werkstatt',
                tooltip: null,
            };
        case 'repairCosts':
            return {
                label: 'Reparaturkosten',
                tooltip: null,
            };
        case 'vehicleValue':
            return {
                label: 'Fahrzeugwert',
                tooltip: null,
            };
        case 'replacementValue':
            return {
                label: 'Wiederbeschaffungswert',
                tooltip: null,
            };
        case 'residualValue':
            return {
                label: 'Restwert',
                tooltip: null,
            };
        case 'diminishedValue':
            return {
                label: 'Minderwert',
                tooltip: null,
            };
        case 'downtimeCompensation':
            return {
                label: 'Nutzungsausfall',
                tooltip: 'Kosten pro Tag und Ausfalldauer',
            };
        case 'damageClass':
            return {
                label: 'Schadensklasse',
                tooltip: null,
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Damage Calculation
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Oldtimer Valuation
        //****************************************************************************/
        case 'oldtimerMarketValue':
            return {
                label: 'Marktwert',
                tooltip: null,
            };
        case 'oldtimerReplacementValue':
            return {
                label: 'Wiederbeschaffungswert',
                tooltip: null,
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Oldtimer Valuation
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Lease Return Checklist
        //****************************************************************************/
        case 'leaseReturnChecklistRepairCosts':
            return {
                label: 'Reparaturkosten',
                tooltip: 'Reparaturkosten in verpflichtenden Elementen angegeben',
            };
        case 'leaseReturnChecklistDiminishedValue':
            return {
                label: 'Minderwert',
                tooltip: 'Minderwert in verpflichtenden Elementen angegeben',
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Lease Return Checklist
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Invoice Audit
        //****************************************************************************/
        case 'invoiceAuditProjectedCosts':
            return {
                label: 'Geschätzte Kosten',
                tooltip: 'Kosten aus Gutachten eingetragen',
            };
        case 'invoiceAuditActualCosts':
            return {
                label: 'Tatsächliche Kosten',
                tooltip: 'Kosten aus Fremdrechnung eingetragen',
            };
        case 'invoiceAuditCorrectedCosts':
            return {
                label: 'Korrekturen',
                tooltip: null,
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Invoice Audit
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Fees
        //****************************************************************************/
        case 'assessorsFee':
            return {
                label: 'Honorar',
                tooltip: null,
            };
        case 'travelExpenses':
            return {
                label: 'Fahrtkosten',
                tooltip: null,
            };
        case 'writingFees':
            return {
                label: 'Schreibkosten',
                tooltip: null,
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Fees
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Print & Transmission
        //****************************************************************************/
        case 'sentToClaimant': {
            const claimantName = Translator.claimantDenomination(report.type);
            return {
                label: claimantName,
                tooltip: `An ${claimantName} versandt`,
            };
        }
        case 'sentToInsurance':
            return {
                label: 'Versicherung',
                tooltip: 'An Versicherung versandt',
            };
        case 'sentToLawyer':
            return {
                label: 'Anwalt',
                tooltip: 'An Anwalt versandt',
            };
        case 'sentToLawyerOrInsurance':
            return {
                label: 'Anwalt oder Versicherung',
                tooltip: 'An Anwalt oder Versicherung versandt',
            };
        case 'sentToGarage':
            return {
                label: 'Werkstatt',
                tooltip: 'An Werkstatt versandt',
            };
        case 'sentToLeaseProvider':
            return {
                label: 'Leasinggeber',
                tooltip: 'An Leasinggeber versandt',
            };
        case 'sentToSeller':
            return {
                label: 'Verkäufer',
                tooltip: 'An Verkäufer versandt',
            };
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Print & Transmission
        /////////////////////////////////////////////////////////////////////////////*/
        default:
            return {
                label: 'Label fehlt',
                tooltip: null,
            };
    }
}

import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { merge, omit } from 'lodash-es';
import moment from 'moment';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { DiminishedValueMethods } from '@autoixpert/lib/damage-calculation-values/diminished-value-methods';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { generateId } from '@autoixpert/lib/generate-id';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { UnprocessableEntity } from '@autoixpert/models/errors/ax-error';
import { DamageCalculation } from '@autoixpert/models/reports/damage-calculation/damage-calculation';
import {
    DiminishedValueCalculation,
    DiminishedValueCalculationMethod,
} from '@autoixpert/models/reports/diminished-value/diminished-value-calculation';
import { Valuation } from '@autoixpert/models/reports/market-value/valuation';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { runChildAnimations } from '../../../../shared/animations/run-child-animations.animation';
import { currencyFormatterEuro } from '../../../../shared/libraries/currency-formatter-euro';
import { shallDiminishedValueCalculatorInputsBeReducedByVat } from '../../../../shared/libraries/diminished-value-calculator/shall-diminished-value-calculator-inputs-be-reduced-by-vat';
import { getMissingAccessRightTooltip } from '../../../../shared/libraries/get-missing-access-right-tooltip';
import { getVatRate } from '../../../../shared/libraries/get-vat-rate-2020';
import { hasAccessRight } from '../../../../shared/libraries/user/has-access-right';
import { LoggedInUserService } from '../../../../shared/services/logged-in-user.service';
import { NewWindowService } from '../../../../shared/services/new-window.service';
import { ReportDetailsService } from '../../../../shared/services/report-details.service';
import { TeamService } from '../../../../shared/services/team.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../../shared/services/user-preferences.service';
import { UserService } from '../../../../shared/services/user.service';

@Component({
    selector: 'diminished-value-calculator',
    templateUrl: 'diminished-value-calculator.component.html',
    styleUrls: ['diminished-value-calculator.component.scss'],
    animations: [runChildAnimations()],
})
export class DiminishedValueCalculatorComponent implements OnInit {
    constructor(
        public userPreferences: UserPreferencesService,
        private reportDetailsService: ReportDetailsService,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private userService: UserService,
        private decimalPipe: DecimalPipe,
        private newWindowService: NewWindowService,
    ) {}

    @Input() report: Report;
    @Input() disabled: boolean;

    @Output() diminishedValue: EventEmitter<number> = new EventEmitter<number>();
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    protected carOwnerMayDeductVat: boolean;

    public mfmMethod: DiminishedValueMethod = new DiminishedValueMethod('mfm', 'MFM', this.calculateMfm.bind(this));
    public bvskMethod: DiminishedValueMethod = new DiminishedValueMethod('bvsk', 'BVSK', this.calculateBvsk.bind(this));
    public halbgewachsMethod: DiminishedValueMethod = new DiminishedValueMethod(
        'halbgewachs',
        'Halbgewachs',
        this.calculateHalbgewachs.bind(this),
    );
    public hamburgModelMethod: DiminishedValueMethod = new DiminishedValueMethod(
        'hamburgModel',
        'Hamburger Modell',
        this.calculateHamburgModel.bind(this),
    );
    public ruhkopfMethod: DiminishedValueMethod = new DiminishedValueMethod(
        'ruhkopf',
        'Ruhkopf/Sahm',
        this.calculateRuhkopf.bind(this),
    );
    public dvgtMethod: DiminishedValueMethod = new DiminishedValueMethod(
        'dvgt',
        '13. DVGT',
        this.calculateDvgt.bind(this),
    );
    public troemnerMethod: DiminishedValueMethod = new DiminishedValueMethod(
        'troemner',
        'Dr. Trömner',
        this.calculateTrömner.bind(this),
    );
    public averageOfAllActiveMethods: DiminishedValueMethod = new DiminishedValueMethod(
        'averageOfAllActiveMethods',
        'Durchschnitt',
        this.calculateAverageOfAllActiveMethods.bind(this),
    );

    // Collection of all methods
    public methods: DiminishedValueMethod[] = [
        this.mfmMethod,
        this.halbgewachsMethod,
        this.ruhkopfMethod,
        this.bvskMethod,
        this.hamburgModelMethod,
        this.dvgtMethod,
        this.troemnerMethod,
    ];

    // Create a default binding value for the view. Otherwise, we'd have to work with *ngIf
    public selectedDiminishedValueCalculation: DiminishedValueCalculation = new DiminishedValueCalculation();

    public commentInputShown: boolean = false;
    public showCommentTextTemplateSelector: boolean = false;

    public roundingEditModeActive: boolean = false;
    @ViewChild('calculatorColumn') calculatorColumn: ElementRef<HTMLDivElement>;

    protected user: User;
    protected team: Team;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        // Don't calculate new values if the report is locked.
        if (!this.disabled) {
            this.getValuesFromReport();
            this.activateMethodsBasedOnPreferences();
            this.calculateAllActiveMethodsAndDrawGraph();
        } else {
            this.selectDiminishedValueCalculation(this.report.valuation.diminishedValueCalculation);
        }

        this.carOwnerMayDeductVat = mayCarOwnerDeductTaxes(this.report);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Exchange With Report
    //****************************************************************************/

    private getValuesFromReport(): void {
        if (!this.report.valuation.diminishedValueCalculation) {
            this.report.valuation.diminishedValueCalculation = new DiminishedValueCalculation();
        }

        // Shorthand
        const diminishedValueCalculation: DiminishedValueCalculation = this.report.valuation.diminishedValueCalculation;
        const damageCalculation: DamageCalculation = this.report.damageCalculation;
        const valuation: Valuation = this.report.valuation;

        // Only get values from report if they are not empty

        // Get age in months
        if (!diminishedValueCalculation.ageInMonths && moment(this.report.car.firstRegistration).isValid()) {
            diminishedValueCalculation.ageInMonths = moment(this.report.accident.date || undefined).diff(
                moment(this.report.car.firstRegistration),
                'months',
            );

            if (diminishedValueCalculation.ageInMonths < 6) {
                // Younger than 6 months
                diminishedValueCalculation.ageFactorTroemner = 1.5;
            } else if (diminishedValueCalculation.ageInMonths < 12) {
                // Between 6 and 12 months
                diminishedValueCalculation.ageFactorTroemner = 1.25;
            } else {
                // Older than 12 months
                diminishedValueCalculation.ageFactorTroemner = 1;
            }
        }

        // Replacement value
        if (!diminishedValueCalculation.replacementValue && valuation.vehicleValueGross)
            diminishedValueCalculation.replacementValue = valuation.vehicleValueGross;

        // Time Value
        if (!diminishedValueCalculation.timeValue) {
            // If taxation is neutral, there's no dealer fee, so replacement value and time value are equal.
            if (valuation.taxationType === 'neutral') {
                diminishedValueCalculation.timeValue = valuation.vehicleValueGross;
            } else if (valuation.vehicleValueGross) {
                /**
                 * According to the MFM method docs (paper booklet, p. 52), a first guess about the time value
                 * can be made by using 90% of the replacement value.
                 * That's in line with DAT & Audatex who assume a 12-13% margin for car dealers (100% replacement value / 1.12 margin = 89,3 % time value)
                 *
                 * Only use this rough estimate if the user has not yet entered his own value.
                 */
                diminishedValueCalculation.timeValue = valuation.vehicleValueGross * 0.9;
            }
        }

        // Mileage meter
        if (!diminishedValueCalculation.mileage && this.report.car.mileageMeter)
            diminishedValueCalculation.mileage = this.report.car.mileageMeter;
        if (!diminishedValueCalculation.mileageUnit)
            diminishedValueCalculation.mileageUnit = this.report.car.mileageUnit || 'km';

        // VAT rate for 2020
        const vatRate = getVatRate(this.report.completionDate);

        // Labor Costs
        if (damageCalculation.repair.garageLaborCostsNet || damageCalculation.repair.auxiliaryCostsNet) {
            // DAT cannot split their auxiliary costs into labor and material. Therefore, we simply add its total to labor.
            if (damageCalculation.repair.calculationProvider === 'dat') {
                diminishedValueCalculation.laborCosts =
                    (damageCalculation.repair.garageLaborCostsNet + damageCalculation.repair.auxiliaryCostsNet) *
                    (1 + vatRate);
            }
            // Audatex's auxiliary costs are already distributed correctly on getting the calculation results
            else {
                diminishedValueCalculation.laborCosts = damageCalculation.repair.garageLaborCostsNet * (1 + vatRate);
            }
        }

        // Material Costs
        if (damageCalculation.repair.sparePartsCostsNet)
            diminishedValueCalculation.materialCosts = damageCalculation.repair.sparePartsCostsNet * (1 + vatRate);

        // Repair Costs Gross
        if (damageCalculation.repair.repairCostsGross)
            diminishedValueCalculation.totalRepairCosts = damageCalculation.repair.repairCostsGross;

        // Original car price
        if (!diminishedValueCalculation.originalPrice && this.report.valuation.originalPriceWithEquipmentGross)
            diminishedValueCalculation.originalPrice = this.report.valuation.originalPriceWithEquipmentGross;

        // When opening old calculations, activate the new calculation method.
        diminishedValueCalculation.isBasedOnNetValues = true;
        this.updateWhetherToReduceInputValuesByVat(diminishedValueCalculation);

        // Select the diminished value calculation
        this.selectDiminishedValueCalculation(this.report.valuation.diminishedValueCalculation);
        this.saveReport();
    }

    private getComparableCalculation(diminishedValueCalculation: DiminishedValueCalculation) {
        const propertiesToOmitForComparison: (keyof DiminishedValueCalculation)[] = [
            '_id',
            'createdAt',
            'updatedAt',
            'lockedAt',
            'createdBy',
        ];

        return omit(diminishedValueCalculation, ...propertiesToOmitForComparison);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Exchange With Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Reduce Input Values by VAT
    //****************************************************************************/
    /**
     * Refresh the flag whether the input values should be reduced by VAT to reflect the decision made by the
     * BGH in July 2024.
     */
    protected updateWhetherToReduceInputValuesByVat(
        diminishedValueCalculation: DiminishedValueCalculation = this.selectedDiminishedValueCalculation,
    ) {
        if (diminishedValueCalculation.lockedAt) return;

        if (diminishedValueCalculation.reduceInputValuesByVatManuallyOverwritten) return;

        const mayDeductTaxes = mayCarOwnerDeductTaxes(this.report);
        diminishedValueCalculation.reduceInputValuesByVat = shallDiminishedValueCalculatorInputsBeReducedByVat({
            mayCarOwnerDeductTaxes: mayDeductTaxes,
            alwaysReduceInputsByVat: this.team.preferences.alwaysReduceInputsToDiminishedValueCalculatorByVat,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reduce Input Values by VAT
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Calculation Methods
    //****************************************************************************/
    public calculateMfm(): void {
        // Reset warning message
        this.mfmMethod.warningMessage = '';

        try {
            this.mfmMethod.result = DiminishedValueMethods.calculateMfm(this.selectedDiminishedValueCalculation);
            this.mfmMethod.inputValid = true;
        } catch (error) {
            this.mfmMethod.inputValid = false;
            if (error instanceof UnprocessableEntity) {
                this.mfmMethod.warningMessage = error.data.warningMessages.join(' ');
            }
        }
    }

    public calculateBvsk(): void {
        // Reset warning message
        this.bvskMethod.warningMessage = '';

        try {
            this.bvskMethod.result = DiminishedValueMethods.calculateBvsk(this.selectedDiminishedValueCalculation);
            this.bvskMethod.inputValid = true;
        } catch (error) {
            this.bvskMethod.inputValid = false;
            if (error instanceof UnprocessableEntity) {
                this.bvskMethod.warningMessage = error.data.warningMessages.join(' ');
            }
        }
    }

    public calculateHalbgewachs(): void {
        // Reset warning message
        this.halbgewachsMethod.warningMessage = '';

        try {
            this.halbgewachsMethod.result = DiminishedValueMethods.calculateHalbgewachs(
                this.selectedDiminishedValueCalculation,
            );
            this.halbgewachsMethod.inputValid = true;
        } catch (error) {
            this.halbgewachsMethod.inputValid = false;
            if (error instanceof UnprocessableEntity) {
                this.halbgewachsMethod.warningMessage = error.data.warningMessages.join(' ');
            }
        }
    }

    public calculateHamburgModel(): void {
        // Reset warning message
        this.hamburgModelMethod.warningMessage = '';

        try {
            this.hamburgModelMethod.result = DiminishedValueMethods.calculateHamburgModel(
                this.selectedDiminishedValueCalculation,
            );
            this.hamburgModelMethod.inputValid = true;
        } catch (error) {
            this.hamburgModelMethod.inputValid = false;
            if (error instanceof UnprocessableEntity) {
                this.hamburgModelMethod.warningMessage = error.data.warningMessages.join(' ');
            }
        }
    }

    public calculateRuhkopf(): void {
        // Reset warning message
        this.ruhkopfMethod.warningMessage = '';

        try {
            this.ruhkopfMethod.result = DiminishedValueMethods.calculateRuhkopf(
                this.selectedDiminishedValueCalculation,
            );
            this.ruhkopfMethod.inputValid = true;
        } catch (error) {
            this.ruhkopfMethod.inputValid = false;
            if (error instanceof UnprocessableEntity) {
                this.ruhkopfMethod.warningMessage = error.data.warningMessages.join(' ');
            }
        }
    }

    public calculateDvgt(): void {
        // Reset warning message
        this.dvgtMethod.warningMessage = '';

        try {
            this.dvgtMethod.result = DiminishedValueMethods.calculateDvgt(this.selectedDiminishedValueCalculation);
            this.dvgtMethod.inputValid = true;
        } catch (error) {
            this.dvgtMethod.inputValid = false;
            if (error instanceof UnprocessableEntity) {
                this.dvgtMethod.warningMessage = error.data.warningMessages.join(' ');
            }
        }
    }

    public calculateTrömner(): void {
        // Reset warning message
        this.troemnerMethod.warningMessage = '';

        try {
            this.troemnerMethod.result = DiminishedValueMethods.calculateTroemner(
                this.selectedDiminishedValueCalculation,
            );
            this.troemnerMethod.inputValid = true;
        } catch (error) {
            this.troemnerMethod.inputValid = false;
            if (error instanceof UnprocessableEntity) {
                this.troemnerMethod.warningMessage = error.data.warningMessages.join(' ');
            }
        }
    }

    public calculateAverageOfAllActiveMethods(): void {
        const activeMethods = this.getActiveMethods();
        const validMethods = activeMethods
            .filter((method) => method.inputValid)
            // Only consider methods whose result is not zero
            .filter((method) => method.result > 0);

        if (activeMethods.length === 0) {
            this.averageOfAllActiveMethods.active = false;
        }
        this.averageOfAllActiveMethods.inputValid = validMethods.length > 0;

        if (!this.averageOfAllActiveMethods.inputValid) {
            this.averageOfAllActiveMethods.warningMessage = 'Kein Durchschnitt ermittelbar';
            return;
        }

        this.averageOfAllActiveMethods.result =
            validMethods.reduce((previousValue, method) => previousValue + method.result, 0) / validMethods.length;
    }

    public getTooltipForAverageMethod(): string {
        const validMethods = this.getActiveMethods()
            .filter((method) => method.inputValid)
            .filter((method) => method.result > 0);
        const validMethodsTitles = validMethods.map((method) => method.title);
        return `In Durchschnitt eingeflossene Ergebnisse: ${validMethodsTitles.join(', ')}.`;
    }

    public rememberActiveMethods(): void {
        this.selectedDiminishedValueCalculation.methods = this.methods
            .filter((method) => method.active)
            .map((method) => method.id)
            .filter((methodId) => methodId !== 'averageOfAllActiveMethods') as any;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Calculation Methods
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  BVSK & MFM Mappings
    //****************************************************************************/
    public mapMarketabilityToMfmAndTroemner(): void {
        const calc = this.selectedDiminishedValueCalculation;

        switch (calc.marketabilityBvsk) {
            case -0.5:
                calc.marketabilityMfm = 0.8;
                calc.marketabilityTroemner = 0.75;
                break;
            case 0:
                calc.marketabilityMfm = 1;
                calc.marketabilityTroemner = 1;
                break;
            case 1:
                calc.marketabilityMfm = 1.2;
                calc.marketabilityTroemner = 1.25;
                break;
            case 2:
                calc.marketabilityMfm = 1.4;
                calc.marketabilityTroemner = 2;
                break;
            default:
                throw Error('UNRECOGNIZED_MARKETABILITY_VALUE_FOR_BVSK');
        }
    }

    public mapPreviousDamageToMfm(): void {
        const calc = this.selectedDiminishedValueCalculation;

        switch (calc.previousDamageBvsk) {
            case 0.5:
                calc.previousDamageMfm = 0.2;
                break;
            case 0.6:
                calc.previousDamageMfm = 0.4;
                break;
            case 0.7:
                calc.previousDamageMfm = 0.6;
                break;
            case 0.8:
                calc.previousDamageMfm = 0.8;
                break;
            case 1:
                calc.previousDamageMfm = 1;
                break;
            default:
                throw Error('UNRECOGNIZED_PREVIOUS_DAMAGE_VALUE_FOR_BVSK');
        }
    }

    public mapDamageIntensityToMfm(): void {
        const calc = this.selectedDiminishedValueCalculation;

        // 0 - 0.5
        if (calc.damageIntensity < 0.6) {
            calc.damageLevel = 0.2;
            return;
        }
        // 0.5 - 1.5
        if (calc.damageIntensity < 1.6) {
            calc.damageLevel = 0.4;
            return;
        }
        // 1.5 - 2.5
        if (calc.damageIntensity < 2.6) {
            calc.damageLevel = 0.6;
            return;
        }
        // 2.5 - 3.5
        if (calc.damageIntensity < 3.6) {
            calc.damageLevel = 0.6;
            return;
        }
        // 3.5 - 4.5
        if (calc.damageIntensity < 4.6) {
            calc.damageLevel = 0.8;
            return;
        }
        // 4.5 - 6.0
        if (calc.damageIntensity < 6.1) {
            calc.damageLevel = 1;
            return;
        }
        // 6.0 - 8.0
        calc.damageLevel = 1;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END BVSK & MFM Mappings
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Graph
    //****************************************************************************/
    public calculateAllActiveMethodsAndDrawGraph(): void {
        const activeMethods = this.getActiveMethods();

        for (const activeMethod of activeMethods) {
            activeMethod.calculate();
        }

        this.calculateAverageOfAllActiveMethods();

        this.drawGraph();
    }

    private drawGraph(): void {
        // Only bother about drawing active methods' bars. Make a copy of the array in order to not alter the order in the view.
        const methods = this.getActiveMethods()
            // Only consider methods with valid input.
            .filter((method) => method.inputValid);

        // Sort the highest to the front.
        methods.sort((methodA, methodB) => {
            if (methodA.result > methodB.result) {
                return -1;
            } else {
                return 1;
            }
        });

        if (methods.length === 0) {
            return;
        }

        const maxValue = methods[0].result;

        for (const method of methods) {
            method.barWidthInPercent = (method.result / maxValue) * 100;
        }

        this.averageOfAllActiveMethods.barWidthInPercent = (this.averageOfAllActiveMethods.result / maxValue) * 100;
    }

    public getGraphNameTooltip(method: DiminishedValueMethod): string {
        if (method.inputValid) {
            return `${method.title} (${this.decimalPipe.transform(method.result, '1.0-0')} €) übernehmen`;
        } else {
            return '';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Graph
    /////////////////////////////////////////////////////////////////////////////*/

    public getActiveMethods(): DiminishedValueMethod[] {
        return this.methods.filter((method) => method.active);
    }

    //*****************************************************************************
    //  Comment
    //****************************************************************************/
    public showCommentInput(): void {
        this.commentInputShown = true;
    }

    public hideCommentInput(): void {
        this.commentInputShown = false;
    }

    public removeComment(): void {
        this.selectedDiminishedValueCalculation.comment = null;
    }

    public insertDiminishedValueCommentStandardText(): void {
        this.selectedDiminishedValueCalculation.comment = this.userPreferences.diminishedValueCommentStandardText || '';
        this.saveReport();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Comment
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Parent Interaction
    //****************************************************************************/
    public emitResult(value: number): void {
        const roundedValue = this.roundDiminishedValue(value);
        this.diminishedValue.emit(roundedValue);
    }

    public handleOverlayClick(event: MouseEvent): void {
        if (event.target === event.currentTarget) {
            this.closeCalculator();
        }
    }

    public closeCalculator(): void {
        this.close.emit();
    }

    public emitResultAndClose(value: number): void {
        this.addArchiveEntry();
        this.addDiminishedValueProtocolToReport();

        /**
         * Merge the selected calculation (either the active one or an archive entry) into the active calculation.
         * Only merge properties related to the calculation. Don't merge properties like _id, createdAt, updatedAt, etc
         * because the _id needs to be unique and the date fields should not be taken from an old archive entry since
         * the user updated the values NOW.
         */
        merge(
            this.report.valuation.diminishedValueCalculation,
            this.getComparableCalculation(this.selectedDiminishedValueCalculation),
        );
        // No matter if the user selected an archive entry or not, don't treat it as locked. Otherwise, using the average of an archive entry will set the _current_ entry as locked.
        this.report.valuation.diminishedValueCalculation.lockedAt = null;

        this.emitResult(value);
        this.closeCalculator();
    }

    public isEmissionOfAverageAllowed(): boolean {
        if (this.getActiveMethods().length === 0) return false;
        if (!this.averageOfAllActiveMethods.inputValid) return false;
        return !this.isReportLocked();
    }

    public async saveReport(): Promise<void> {
        try {
            await this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.toastService.error('Berechnungswerte konnten nicht gespeichert werden.');
            console.error('COULD_NOT_SAVE_REPORT', { error });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Parent Interaction
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Preferences
    //****************************************************************************/
    public setUserPreference(method: DiminishedValueMethod): void {
        const capitalizedId = method.id[0].toUpperCase() + method.id.slice(1);
        this.userPreferences['useDiminishedValueMethod' + capitalizedId] = method.active;
    }

    private activateMethodsBasedOnPreferences(): void {
        // Only fill in user preferences if the report's methods are not yet set.
        if (this.report.valuation.diminishedValueCalculation.methods.length) return;

        for (const method of this.methods) {
            const capitalizedId = method.id[0].toUpperCase() + method.id.slice(1);
            method.active = this.userPreferences['useDiminishedValueMethod' + capitalizedId];
        }
        this.rememberActiveMethods();
        this.saveReport();
    }

    /**
     * User can choose to calculate the diminished value based on net values (in case the claimant is
     * entitled to deduct input tax -> e.g. companies).
     */
    protected toggleAlwaysReduceInputValuesByVat(): void {
        if (!hasAccessRight(this.user, 'editTextsAndDocumentBuildingBlocks')) {
            return;
        }

        this.team.preferences.alwaysReduceInputsToDiminishedValueCalculatorByVat =
            !this.team.preferences.alwaysReduceInputsToDiminishedValueCalculatorByVat;

        // Update the flag on this calculation as well.
        this.updateWhetherToReduceInputValuesByVat();

        void this.teamService.put(this.team);

        // Update the calculation
        this.calculateAllActiveMethodsAndDrawGraph();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Preferences
    /////////////////////////////////////////////////////////////////////////////*/
    public openWindow(url: string) {
        this.newWindowService.open(url, '_blank', 'noopener');
    }
    //*****************************************************************************
    //  Archive
    //****************************************************************************/
    /**
     * Add archive entry if the calculation has not come from the archive
     */
    private addArchiveEntry(): void {
        // Prepare array
        if (!this.report.valuation.archivedDiminishedValueCalculations) {
            this.report.valuation.archivedDiminishedValueCalculations = [];
        }

        // Don't re-add archive entries
        const isSelectedCalculationInArchive: boolean = this.report.valuation.archivedDiminishedValueCalculations.some(
            (archiveEntry) => archiveEntry._id === this.selectedDiminishedValueCalculation._id,
        );
        const lastArchiveEntry: DiminishedValueCalculation =
            this.report.valuation.archivedDiminishedValueCalculations.at(-1);
        const isCalculationIdenticalToLastArchiveEntry: boolean =
            !!lastArchiveEntry &&
            JSON.stringify(this.getComparableCalculation(lastArchiveEntry)) ===
                JSON.stringify(this.getComparableCalculation(this.selectedDiminishedValueCalculation));
        if (isSelectedCalculationInArchive || isCalculationIdenticalToLastArchiveEntry) {
            return;
        }

        const copyOfDiminishedValueCalculation: DiminishedValueCalculation = JSON.parse(
            JSON.stringify(this.selectedDiminishedValueCalculation),
        );
        /**
         * A unique ID is needed to distinguish the archive entry from the active diminished value calculation.
         * Without this, only one archive entry would ever be created because the ID of the archive entry and the
         * selected diminished value calculation would always be the same.
         */
        copyOfDiminishedValueCalculation._id = generateId();

        copyOfDiminishedValueCalculation.lockedAt = moment().format();
        copyOfDiminishedValueCalculation.updatedAt = moment().format();
        copyOfDiminishedValueCalculation.createdAt = moment().format();
        copyOfDiminishedValueCalculation.createdBy = this.loggedInUserService.getUser()._id;

        this.report.valuation.archivedDiminishedValueCalculations.push(copyOfDiminishedValueCalculation);
        this.saveReport();
    }

    public selectDiminishedValueCalculation(calculation: DiminishedValueCalculation): void {
        if (!calculation) return;

        this.selectedDiminishedValueCalculation = calculation;

        // Activate all methods included in the archive entry. Deactivate all others.
        for (const method of this.methods) {
            method.active = this.selectedDiminishedValueCalculation.methods.includes(
                method.id as DiminishedValueCalculationMethod,
            );
        }

        if (this.selectedDiminishedValueCalculation.comment) {
            this.showCommentInput();
        } else {
            this.hideCommentInput();
        }

        this.calculateAllActiveMethodsAndDrawGraph();
    }

    public deleteArchiveEntry(archiveEntry: DiminishedValueCalculation, event: MouseEvent): void {
        event.stopPropagation();

        removeFromArray(archiveEntry, this.report.valuation.archivedDiminishedValueCalculations);

        // If the user deleted the last archive entry, unlock the selected entry to avoid a deadlock situation that the selected entry is locked, but you cannot close the archive.
        if (!this.report.valuation.archivedDiminishedValueCalculations.length) {
            this.leaveArchiveEntryView();
        }

        this.saveReport();
    }

    public leaveArchiveEntryView() {
        this.selectDiminishedValueCalculation(this.report.valuation.diminishedValueCalculation);
    }

    public getFullCreatorName(createdBy: string): string {
        const user = this.userService.getTeamMemberFromCache(createdBy);
        if (!user) {
            return 'Nutzer unbekannt';
        }
        return `${user.firstName} ${user.lastName}`;
    }

    protected getArchiveEntryTooltip(archiveItem: DiminishedValueCalculation): string {
        return `Berechnet aus ${archiveItem.isBasedOnNetValues ? 'Netto' : 'Brutto'}-Werten.`;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Archive
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Options for the view
    //****************************************************************************/
    public damageLevelOptions: DiminishedValueOption[] = [
        {
            value: 0.2,
            description: 'Anbauteile (z. B. Stoßfänger)',
        },
        {
            value: 0.4,
            description: 'Geschraubte Karosserieteile (z. B. Tür, Motorhaube)',
        },
        {
            value: 0.6,
            description: 'Tragende Karosserieteile - geringfügige Arbeiten (z. B. Seitenwand instandsetzen)',
        },
        {
            value: 0.8,
            description:
                'Mittragende Karosserieteile - erhebliche Arbeiten/Austausch (z. B. Kofferboden instandsetzen, Seitenwand erneuern)',
        },
        {
            value: 1.0,
            description: 'Tragende Karosserieteile - erhebliche Arbeiten/Austausch (z. B. Längsträger, Rohbaukarosse)',
        },
    ];

    public marketabilityMfmOptions: DiminishedValueOption[] = [
        {
            value: 0.6,
            description: 'Sehr hohe Nachfrage',
        },
        {
            value: 0.8,
            description: 'Erhöhte Nachfrage',
        },
        {
            value: 1.0,
            description: 'Angebot & Nachfrage ausgeglichen',
        },
        {
            value: 1.2,
            description: 'Weniger Nachfrage als Angebot',
        },
        {
            value: 1.4,
            description: 'Schwer verkäuflich',
        },
    ];

    public previousDamageMfmOptions: DiminishedValueOption[] = [
        {
            value: 0.2,
            description: 'Erheblicher Vorschaden',
        },
        {
            value: 0.4,
            description: 'Hoher Vorschaden',
        },
        {
            value: 0.6,
            description: 'Mittlerer Vorschaden',
        },
        {
            value: 0.8,
            description: 'Geringer Vorschaden',
        },
        {
            value: 1.0,
            description: 'Kein Vorschaden',
        },
    ];

    public marketabilityBvskOptions: DiminishedValueOption[] = [
        {
            value: -0.5,
            description: 'Gute Marktgängigkeit',
        },
        {
            value: 0,
            description: 'Mittlere Marktgängigkeit',
        },
        {
            value: 1,
            description: 'Schlechte Marktgängigkeit',
        },
        {
            value: 2,
            description: 'Sehr lange Standzeiten, Exoten',
        },
    ];

    public previousDamageBvskOptions: DiminishedValueOption[] = [
        {
            value: 0.5,
            description: 'Erheblicher Vorschaden',
        },
        {
            value: 0.6,
            description: 'Hoher Vorschaden',
        },
        {
            value: 0.7,
            description: 'Mittlerer Vorschaden',
        },
        {
            value: 0.8,
            description: 'Geringer Vorschaden. Auch bei leichten Nutzfahrzeugen anzugeben.',
        },
        {
            value: 1.0,
            description: 'Kein Vorschaden',
        },
    ];

    public damageIntensityOptions: DiminishedValueOption[] = [
        {
            value: 0.5,
            title: '0 - 0,5%',
            description: 'Leichte Schäden: Ersatz von Anbauteilen',
        },
        {
            value: 1.5,
            title: '0,5 - 1,5%',
            description: 'Leichte Schäden: Ersatz von Anbau- & geschraubten Teilen',
        },
        {
            value: 2.5,
            title: '1,5 - 2,5%',
            description: 'Richtarbeiten an geschweißten Karosserieteilen',
        },
        {
            value: 3.5,
            title: '2,5 - 3,5%',
            description: 'Ersatz von geschweißten Karosserieteilen & Achsteilen',
        },
        {
            value: 4.5,
            title: '3,5 - 4,5%',
            description: 'Ersatz von geschweißten Karosserieteilen & Achsteilen; erhebliche Richtarbeiten',
        },
        {
            value: 6.0,
            title: '4,5 - 6,0%',
            description: 'Richtarbeiten an Rahmen & Bodenblechen, Richtbankeinsatz',
        },
        {
            value: 8.0,
            title: '6,0 - 8,0%',
            description: 'Ersatz von Rahmenteilen & Bodenblechen, Richtbankeinsatz, Schäden vorn & hinten',
        },
    ];

    public marketabilityTroemnerOptions: DiminishedValueOption[] = [
        {
            value: 0.75,
            description: 'Gute Marktgängigkeit',
        },
        {
            value: 1,
            description: 'Mittlere Marktgängigkeit',
        },
        {
            value: 1.25,
            description: 'Schlechte Marktgängigkeit',
        },
        {
            value: 2,
            description: 'Sehr lange Standzeiten, Exoten',
        },
    ];

    public previousDamageTroemnerOptions: DiminishedValueOption[] = [
        {
            value: 1,
            description: 'Ohne Vorschäden',
        },
        {
            value: 0.75,
            description: 'Ein reparierter oder unreparierter Vorschaden',
        },
        {
            value: 0.5,
            description: 'Mehrere reparierte oder unreparierte Vorschäden',
        },
    ];

    public ageFactorTroemnerOptions: DiminishedValueOption[] = [
        {
            value: 1.5,
            description: 'Für Fahrzeuge EZ bis 6 Monate',
        },
        {
            value: 1.25,
            description: 'Für Fahrzeuge ab 6 bis 12 Monate',
        },
        {
            value: 1,
            description: 'Ab 12 Monate',
        },
        {
            value: 2,
            description: 'Unrestauriertes Fahrzeug',
        },
    ];

    public vehicleDamageTroemnerOptions: DiminishedValueOption[] = [
        {
            value: 0.75,
            title: 'Leichte Schäden I',
            description:
                'Mit Ersatz von Anbauteilen und Lackierarbeiten an der Karosserie ohne Instandsetzungsarbeiten (z. B. Ersatz von Front- oder Heckverkleidungen oder reine Lackierarbeiten ohne Instandsetzungsarbeiten).',
        },
        {
            value: 1.5,
            title: 'Leichte Schäden II',
            description:
                'Mit Ersatz von Anbauteilen und verschraubten Karosserieteilen, ohne Instandsetzungsarbeiten an der Karosserie (z. B. Ersatz von Kotflügeln oder Türen, ohne Instandsetzungsarbeiten).',
        },
        {
            value: 2.5,
            title: 'Mittlere Schäden I',
            description:
                'Mit Ersatz von Anbauteilen und verschraubten Karosserieteilen und Instandsetzungsarbeiten an verschweißten oder geklebten Karosserieteilen (z. B. Ersatz der hinteren Tür und Richtarbeiten am Seitenteil).',
        },
        {
            value: 3.5,
            title: 'Mittlere Schäden II',
            description:
                'Mit Ersatz von Anbauteilen und verschraubten Karosserieteilen, Ersatz von geschweißten oder geklebten Karosserieteilen und Instandsetzungsarbeiten an solchen Teilen oder Ersatz von Achsteilen (z. B. Ersatz der Rückwand).',
        },
        {
            value: 4.5,
            title: 'Starke Schäden I',
            description:
                'Mit Ersatz von Anbauteilen und verschraubten Karosserieteilen, Ersatz von geschweißten oder geklebten Karosserieteilen und erhebliche Instandsetzungsarbeiten an solchen Teilen oder Ersatz von Achsteilen.',
        },
        {
            value: 6,
            title: 'Starke Schäden II',
            description:
                'Ersatz von Anbauteilen, verschraubten, verschweißten oder geklebten Karosserieteilen, Instandsetzungsarbeiten an solchen Teilen sowie Instandsetzungsarbeiten am Rahmen oder Bodenblechen incl. Richtbankeinsatz.',
        },
        {
            value: 8,
            title: 'Starke Schäden III',
            description:
                'Ersatz von Anbauteilen, verschraubten, verschweißten oder geklebten Karosserieteilen, Instandsetzungsarbeiten an solchen Teilen sowie Neuersatz von Rahmenteilen oder Bodenblechen incl. Richtbankeinsatz.',
        },
        {
            value: 10,
            title: 'Mehrere Schäden',
            description: 'Unfallschäden vorn und hinten am Fahrzeug (oder beidseitig) aus einem Unfallereignis.',
        },
    ];
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Options for the view
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Damage Intensity
    //****************************************************************************/
    public damageIntensityHint: string = '';

    public setDamageIntensity(damageIntensity: number, event: MatOptionSelectionChange): void {
        if (!event.source.selected) {
            return;
        }

        this.selectedDiminishedValueCalculation.damageIntensity = damageIntensity;
    }

    public damageIntensityIsValid(): boolean {
        const damageIntensity = this.selectedDiminishedValueCalculation.damageIntensity;
        let valid = true;

        if (isNaN(damageIntensity)) {
            valid = false;
            this.damageIntensityHint = 'Nur Zahlen erlaubt';
        }

        if (damageIntensity > 8 || damageIntensity < 0) {
            valid = false;
            this.damageIntensityHint = 'Wert muss zwischen 0 und 8 liegen';
        }
        return valid;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damage Intensity
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Rounding
    //****************************************************************************/
    public getRoundingTooltip(): string {
        if (this.userPreferences.diminishedValueRoundingAmount === null) {
            return 'Der Minderwert wird ohne Runden übernommen. Zum Bearbeiten klicken.';
        }

        const amount = Number(this.userPreferences.diminishedValueRoundingAmount).toLocaleString('de', {
            maximumFractionDigits: 0,
        });
        const direction = this.userPreferences.diminishedValueRoundingDirection === 'up' ? 'aufrunden' : 'abrunden';

        return `auf volle ${amount} € ${direction}. Zur Bearbeitung klicken.`;
    }

    public enterRoundingEditMode(): void {
        this.roundingEditModeActive = true;
        window.setTimeout(() => {
            this.calculatorColumn.nativeElement.scrollTo({
                top: this.calculatorColumn.nativeElement.scrollHeight,
                behavior: 'smooth',
            });
        });
    }

    public leaveRoundingEditMode(): void {
        this.roundingEditModeActive = false;
    }

    public removeRoundingAmount(): void {
        this.userPreferences.diminishedValueRoundingAmount = null;
    }

    private roundDiminishedValue(value: number): number {
        value = Math.round(value);

        // Zero or null -> Don't round
        if (!this.userPreferences.diminishedValueRoundingAmount) return value;

        const differenceToLeastNaturalDivider: number = value % this.userPreferences.diminishedValueRoundingAmount;

        // No difference -> We're already at a rounded value.
        if (!differenceToLeastNaturalDivider) {
            return value;
        }

        let roundedValue = value - differenceToLeastNaturalDivider;
        if (this.userPreferences.diminishedValueRoundingDirection === 'up') {
            roundedValue += this.userPreferences.diminishedValueRoundingAmount;
        }
        return roundedValue;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Rounding
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Locked State
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Locked State
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Add Dminished Value Protocol to Report
    //****************************************************************************/
    private addDiminishedValueProtocolToReport(): void {
        // If it's not yet present, add document "diminished value protocol" to report
        const daminishedValueProtocol = new DocumentMetadata({
            type: 'diminishedValueProtocol',
            title: 'Minderwertprotokoll',
            createdBy: this.user._id,
            // The diminished value protocol is just important to the insurance.
        });
        addDocumentToReport(
            {
                team: this.loggedInUserService.getTeam(),
                report: this.report,
                newDocument: daminishedValueProtocol,
                documentGroup: 'report',
            },
            { insertAfterFallback: 'report' },
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Add Dminished Value Protocol to Report
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    private keydownListener(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeCalculator();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Listeners
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly currencyFormatterEuro = currencyFormatterEuro;
    protected readonly isAdmin = isAdmin;
    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

export class DiminishedValueMethod {
    constructor(
        id: DiminishedValueMethod['id'],
        title: DiminishedValueMethod['title'],
        calculationMethod: () => number,
    ) {
        this.id = id;
        this.title = title;
        this.calculate = calculationMethod;
    }

    id:
        | 'mfm'
        | 'bvsk'
        | 'halbgewachs'
        | 'hamburgModel'
        | 'ruhkopf'
        | 'dvgt'
        | 'troemner'
        | 'averageOfAllActiveMethods' = null;
    title:
        | 'MFM'
        | 'BVSK'
        | 'Halbgewachs'
        | 'Hamburger Modell'
        | 'Ruhkopf/Sahm'
        | '13. DVGT'
        | 'Dr. Trömner'
        | 'Durchschnitt' = null;
    active: boolean = true;
    inputValid: boolean = false;
    calculate: () => number;
    barWidthInPercent: number = null;
    result: number = null;
    warningMessage: string = '';
}

class DiminishedValueOption {
    value: number;
    title?: string;
    description: string;
}

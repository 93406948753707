<mat-form-field class="mat-block" [floatLabel]="floatLabel">
    <!--********** Input **********-->
    <input
        matInput
        *ngIf="!useTextarea"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [matAutocomplete]="autocomplete"
        [disabled]="disabled"
        [axAutofocus]="axAutofocus"
        (ngModelChange)="filterAutocomplete(); emitNgModelChange()"
        (focus)="filterAutocomplete()"
        (blur)="emitBlur()" />

    <!-- Optional hint that fades in/out -->
    <mat-hint *ngIf="hint" @fadeInAndOut>{{ hint }}</mat-hint>

    <!--********** Textarea **********-->
    <textarea
        matInput
        *ngIf="useTextarea"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [matAutocomplete]="autocomplete"
        [disabled]="disabled"
        [axAutofocus]="axAutofocus"
        (ngModelChange)="filterAutocomplete(); emitNgModelChange()"
        (focus)="filterAutocomplete()"
        (blur)="emitBlur()"
        cdkTextareaAutosize
        cdkAutosizeMaxRows="6"></textarea>

    <!-- Div around the suffix icons is necessary, otherwise animations on :leave break -->
    <div matSuffix>
        <!--********** Use (mousedown) instead of (click) so the blur event fires after the click (/mousedown). Relevant for the photo grid component where blurring hides the input field. **********-->
        <mat-icon
            class="input-toolbar-icon"
            *ngIf="value && !customEntryExists(value) && user?.accessRights.editTextsAndDocumentBuildingBlocks"
            matTooltip="Option merken"
            (mousedown)="rememberCustomEntry(value); filterAutocomplete()">
            add
        </mat-icon>
        <div @fadeOutCheckmark class="remembered-checkmark-container" *ngIf="showConfirmationIcon">
            <div class="checkmark-circle" @animateCheckmarkCircle></div>
            <!-- SVG must be inline, so we can animate the path inside -->
            <svg
                class="remembered-checkmark"
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    @animateCheckmarkPath
                    d="M 4.1042267,12.724931 9.000745,17.57455 20.297262,6.3014832"
                    style="fill: none; stroke-width: 2; stroke-dasharray: 22.85075569152832" />
            </svg>
        </div>
    </div>
</mat-form-field>
<mat-autocomplete #autocomplete (optionSelected)="emitNgModelChange(); emitChange()">
    <mat-option
        *ngFor="let autocompleteEntry of filteredAutocompleteEntries"
        [value]="autocompleteEntry.value"
        (mousedown)="$event.preventDefault()">
        <div class="autocomplete-option-wrapper">
            <div
                class="autocomplete-option-label"
                [matTooltip]="autocompleteEntry.tooltip || autocompleteEntry.value"
                matTooltipShowDelay="700">
                {{ autocompleteEntry.value }}
            </div>
            <mat-icon
                class="autocomplete"
                *ngIf="autocompleteEntry.createdBy !== 'autoixpert-default'"
                [ngClass]="{ disabled: !user?.accessRights.editTextsAndDocumentBuildingBlocks }"
                (click)="forgetAutocompleteEntry(autocompleteEntry); $event.stopPropagation()"
                [matTooltip]="
                    !user?.accessRights.editTextsAndDocumentBuildingBlocks
                        ? 'Du hast keine Berechtigung, Textbausteine und Vorlagen zu verändern. Bitte wende dich an deinen Administrator.'
                        : 'Löschen'
                ">
                delete
            </mat-icon>
        </div>
    </mat-option>
</mat-autocomplete>

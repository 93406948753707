<div class="dialog-container">
    <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

    <div>
        <h2 class="no-margin-top margin-bottom-10">Aktenzeichen & Rechnungsnummer</h2>
        <p class="label no-margin text-align-center">
            Du kannst deine Rechnungsnummer (ReNr.) und dein Aktenzeichen (AZ) automatisch voneinander ableiten lassen.
        </p>
    </div>

    <div class="option-cards text-align-center">
        <div
            class="option-card display-flex flex-direction-column flex-center-content"
            [class.selected]="selectedCounterType === 'reportToken'"
            (click)="selectCounterType('reportToken')">
            <div class="option-card-icon">
                <mat-icon class="outlined">assignment</mat-icon>
            </div>

            <h3>Aktenzeichen führt</h3>
            <p class="label margin-top-10 margin-bottom-10">
                Das Aktenzeichen plus ein optionales Präfix/Suffix bestimmen die Rechnungsnummer.
                <br />
                <br />
                Freie Rechnungen (= ohne Gutachten) laufen weiterhin über ihren eigenen Zählerkreis.
            </p>

            <div class="option-card-example">
                <h4 class="no-margin-top margin-bottom-10">Beispiel</h4>
                <div class="option-card-example-table">
                    <div class="option-card-example-column">
                        <span class="italic">Az.</span>
                        <span>2024-01</span>
                        <span>2024-02</span>
                        <span>2024-03</span>
                    </div>
                    <div class="option-card-example-column">
                        <span></span>
                        <span><mat-icon class="small-icon">arrow_right_alt</mat-icon></span>
                        <span><mat-icon class="small-icon">arrow_right_alt</mat-icon></span>
                        <span><mat-icon class="small-icon">arrow_right_alt</mat-icon></span>
                    </div>
                    <div class="option-card-example-column">
                        <span class="italic">ReNr.</span>
                        <span>2024-01, 2024-01/1</span>
                        <span>2024-02</span>
                        <span>2024-03</span>
                    </div>
                </div>
            </div>

            <p class="label no-margin-bottom">
                <strong>Vor- & Nachteile</strong>
                <br />
                An der Rechnung ist eindeutig ablesbar, zu welchem Gutachten sie gehört. Dafür keine perfekt
                fortlaufenden ReNrn. im Unternehmen.
            </p>
        </div>
        <div
            class="option-card display-flex flex-direction-column flex-center-content"
            [class.selected]="selectedCounterType === 'invoiceNumber'"
            (click)="selectCounterType('invoiceNumber')">
            <div class="option-card-icon">
                <mat-icon>euro_symbol</mat-icon>
            </div>

            <h3>Rechnungszähler führt</h3>
            <p class="label margin-top-10 margin-bottom-10">
                Das Muster des Aktenzeichens ist frei einstellbar, aber der Zähler wird vom Zähler in der ReNr.
                bestimmt.
                <br />
                <br />
                <br />
                <br />
            </p>

            <div class="option-card-example">
                <h4 class="no-margin-top margin-bottom-10">Beispiel</h4>

                <div class="option-card-example-table">
                    <div class="option-card-example-column">
                        <span class="italic">ReNr.</span>
                        <span>RE01</span>
                        <span>RE02</span>
                        <span>RE03</span>
                    </div>
                    <div class="option-card-example-column">
                        <span></span>
                        <span><mat-icon class="small-icon">arrow_right_alt</mat-icon></span>
                        <span><mat-icon class="small-icon">arrow_right_alt</mat-icon></span>
                        <span><mat-icon class="small-icon">arrow_right_alt</mat-icon></span>
                    </div>
                    <div class="option-card-example-column">
                        <span class="italic">Az.</span>
                        <span>GA-01</span>
                        <span>Ohne Gutachten</span>
                        <span>GA-03</span>
                    </div>
                </div>
            </div>

            <p class="label no-margin-bottom">
                <strong>Vor- & Nachteile</strong>
                <br />
                Mögliche Löcher im AZ, wenn Storno- oder Zweitrechnungen in einem Vorgang geschrieben werden. Dafür
                immer zueinander passende AZ und ReNrn.
            </p>
        </div>
    </div>

    <p class="label">Das Muster der Rechnungsnummer ist einstellbar, nachdem du diesen Dialog schließt.</p>

    <div class="info-note warning-note">
        <mat-icon class="warning medium-icon">calendar_today</mat-icon>
        <div>
            <h3>Empfehlung: Zum Jahreswechsel umstellen</h3>
            Wir empfehlen, diese Einstellung erst zum Geschäftsjahreswechsel oder nach Rücksprache mit deinem
            Steuerberater zu verändern, damit deine Rechnungsnummern nachvollziehbar bleiben.
        </div>
    </div>

    <!--********** Close Dialog **********-->
    <div class="dialog-buttons-container">
        <button (click)="closeDialog()" matTooltip="Dialog schließen">Schließen</button>
    </div>
</div>

import { AxError } from '@autoixpert/models/errors/ax-error';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { ApiErrorHandleParameters } from '../../abstract-services/api-error.abstract.service';

/**
 * Since DATEV requires invoices to have a number, a date and a total net amount, this function checks for these properties.
 * Notify the user if any of these properties are missing and exclude the invoices from the export.
 */
export function executeInvoiceSanityChecksForDatevExport(invoices: Invoice[]): [Invoice[], Invoice[]] {
    const incompleteInvoices: Invoice[] = [];
    const invoicesToExport: Invoice[] = [];

    const invoiceNumbers = new Map<string, Invoice>();
    for (const invoice of invoices) {
        // Ignore invoices that are missing the required properties
        if (!invoice.totalNet || !invoice.date || !invoice.number) {
            incompleteInvoices.push(invoice);
            continue;
        }

        // Check for duplicate invoice numbers
        if (invoiceNumbers.has(invoice.number)) {
            throw new AxError({
                code: 'DUPLICATE_INVOICE_NUMBER',
                message: 'Two invoices have the same invoice number. Invoice numbers in DATEV must be unique, though.',
                data: {
                    firstInvoiceWithDuplicateInvoiceNumber: invoice,
                    secondInvoiceWithDuplicateInvoiceNumber: invoiceNumbers.get(invoice.number),
                },
            });
        }
        invoiceNumbers.set(invoice.number, invoice);

        // include the invoice in the export
        invoicesToExport.push(invoice);
    }

    return [incompleteInvoices, invoicesToExport];
}

export const invoiceSanityChecksForDatevExportErrors: ApiErrorHandleParameters['handlers'] = {
    EMPTY_INVOICE_NUMBER: {
        title: 'Leere Rechnungsnummer',
        body: 'Es können nur Rechnungen mit Rechnungsnummer exportiert werden.<br><br>Klicke, um die Rechnung ohne Nummer zu öffnen.',
        toastClick: (error) => window.open(`Rechnungen/${error.data.invoice._id}`),
    },
    EMPTY_INVOICE_DATE: {
        title: 'Leeres Rechnungsdatum',
        body: 'Es können nur Rechnungen mit Rechnungsdatum exportiert werden.<br><br>Klicke, um die Rechnung ohne Datum zu öffnen.',
        toastClick: (error) => window.open(`Rechnungen/${error.data.invoice._id}`),
    },
    EMPTY_INVOICE_TOTAL_NET: {
        title: 'Leere Rechnungssumme',
        body: 'Es können nur Rechnungen mit einem Betrag > 0,00 € exportiert werden.<br><br>Klicke, um die Rechnung ohne Rechnungssumme zu öffnen.',
        toastClick: (error) => window.open(`Rechnungen/${error.data.invoice._id}`),
    },
    DUPLICATE_INVOICE_NUMBER: {
        title: 'Doppelte Rechnungsnummer',
        body: 'Rechnungsnummern müssen eindeutig sein.<br><br>Klicke, um die Rechnungen mit doppelt vergebener Nummer zu öffnen.',
        toastClick: (error) => {
            window.open(`Rechnungen/${error.data.firstInvoiceWithDuplicateInvoiceNumber._id}`);
            window.open(`Rechnungen/${error.data.secondInvoiceWithDuplicateInvoiceNumber._id}`);
        },
    },
};

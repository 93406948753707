import { Report } from '@autoixpert/models/reports/report';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';

/**
 * If the user wants to add a VXS file by default, mark this on the report object.
 */
export function automaticallyAttachVxsIfConfigured({
    report,
    userPreferences,
}: {
    report: Report;
    userPreferences: UserPreferences;
}) {
    if (report.damageCalculation.repair.calculationProvider !== 'dat') return;

    // "== null" means null or undefined. That means the user has not set any value explicitly.
    if (report.garage.outgoingMessageDraft.report.isDatVxsAttached == null) {
        report.garage.outgoingMessageDraft.report.isDatVxsAttached = userPreferences.attachDatVxs;
    }
    // "== null" means null or undefined. That means the user has not set any value explicitly.
    if (report.repairConfirmation && report.garage.outgoingMessageDraft.repairConfirmation.isDatVxsAttached == null) {
        report.garage.outgoingMessageDraft.repairConfirmation.isDatVxsAttached = userPreferences.attachDatVxs;
    }
}

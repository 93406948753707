//*****************************************************************************
//  Description
//****************************************************************************/
// In case we implement another environment (alpha, beta etc.), this environment definition was extracted to a separate module.
/////////////////////////////////////////////////////////////////////////////*/
//  END Description
/////////////////////////////////////////////////////////////////////////////*/
export class Environment {
    static toString(): 'development' | 'production' {
        // Beta should only be recognized to point to another database. Within the code, the behavior must
        // equal the production behavior.
        if (['production', 'beta'].includes(process.env.NODE_ENV)) {
            return 'production';
        }
        return 'development';
    }

    static isDevelopment(): boolean {
        return this.toString() === 'development';
    }

    static isBeta(): boolean {
        return process.env.NODE_ENV === 'beta';
    }

    static isProduction(): boolean {
        return process.env.NODE_ENV === 'production';
    }
}

<div id="general-inspection-container">
    <div id="general-inspection-date-input-container">
        <month-input
            placeholder="Nächste HU"
            [(date)]="_nextGeneralInspection"
            (dateChange)="updateInspectionSticker($event); emitDateChange($event)"
            (focus)="emitFocusEvent($event)"
            matTooltip="Datumsformat 01.2020 oder Jan 2020"
            [disabled]="disabled"
            [autofocus]="autofocus"
            [hint]="hint"
            [warning]="warning"></month-input>
        <div class="general-inspection-warning-container" *ngIf="inspectionDateIsInThePast">
            <span class="label">überfällig</span>
            <mat-icon class="general-inspection-warning-icon" [matTooltip]="inspectionDateOverdueTooltip">
                warning
            </mat-icon>
        </div>
        <div class="general-inspection-warning-container" *ngIf="inspectionDateIsFarInTheFuture">
            <span class="label">weit in Zukunft</span>
            <mat-icon
                class="general-inspection-warning-icon"
                matTooltip="Die HU liegt mehr als 36 Monate in der Zukunft. Ist das Datum korrekt?">
                warning
            </mat-icon>
        </div>
    </div>
    <div id="inspection-sticker-container">
        <div id="inspection-sticker-shadow">
            <img
                id="inspection-sticker"
                src="/assets/images/icons/hu-plakette_128.png"
                [style.transform]="'rotate(' + inspectionStickerRotation + 'deg)'"
                [style.background]="inspectionStickerColor" />
        </div>
    </div>
</div>

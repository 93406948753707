import { Component, HostListener, Input } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { UserPreferencesService } from '../../services/user-preferences.service';

@Component({
    selector: 'display-internal-id',
    templateUrl: './display-internal-id.component.html',
    styleUrls: ['./display-internal-id.component.scss'],
    host: {
        class: 'label',
        matTooltip:
            'Die autoiXpert-interne Kontakt-ID kann für Anfragen über die Externe API verwendet werden. Klicke, um die ID in die Zwischenablage zu kopieren.',
    },
})
export class DisplayInternalIdComponent {
    constructor(
        public userPreferences: UserPreferencesService,
        private toastService: ToastService,
    ) {}

    @Input() internalId: string;
    @Input() entityName: string;

    get tooltipText(): string {
        return `Die autoiXpert-interne ${this.entityName}-ID kann für Anfragen über die Externe API verwendet werden. Klicke, um die ID in die Zwischenablage zu kopieren.`;
    }

    @HostListener('click', ['$event'])
    public copyToClipboard(): void {
        navigator.clipboard.writeText(this.internalId);
        this.toastService.success(`${this.entityName}-ID in Zwischenablage kopiert`);
    }
}

import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { parse } from 'papaparse';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { round } from '@autoixpert/lib/numbers/round';
import { LaborType, ManualCalculationItem } from '@autoixpert/models/reports/damage-calculation/manual-calculation';
import { readCsvStringFromFile } from '../../../../../shared/libraries/imports/read-csv-string-from-file';
import { ToastService } from '../../../../../shared/services/toast.service';

@Component({
    selector: 'manual-calculation-import-dialog',
    templateUrl: 'manual-calculation-import-dialog.component.html',
    styleUrls: ['manual-calculation-import-dialog.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class ManualCalculationImportDialogComponent {
    constructor(private toastService: ToastService) {}

    @Output() parseResult: EventEmitter<ManualCalculationItem[]> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    public csvUploadAndInterpretationPending: boolean;

    //*****************************************************************************
    //  Handle Upload
    //****************************************************************************/
    public async handleCsvFileUpload(event: Event) {
        this.csvUploadAndInterpretationPending = true;
        await this.interpretCsvFileUpload(event);
        this.csvUploadAndInterpretationPending = false;
        this.closeDialog();
    }

    public async interpretCsvFileUpload(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];

        const csvString = await readCsvStringFromFile(file);

        const parseResult = parse(csvString, {
            header: true,
            // The last line of the file may be empty. Prevent errors by skipping empty lines.
            skipEmptyLines: true,
        });

        if (parseResult.errors.length > 0) {
            // Only show the first 5 errors, everything else would exceed the available screen space.
            const errors = parseResult.errors.slice(0, 5).map((err) => err.message);
            this.toastService.error('CSV-Datei enthält Fehler', errors.join('\n\n'));
            return;
        }

        const itemsGerman: ManualCalculationCsvItemGerman[] = parseResult.data as ManualCalculationCsvItemGerman[];

        const calculationItems: ManualCalculationItem[] = itemsGerman.map((itemGerman) => {
            let repairCode: ManualCalculationItem['repairCode'];
            switch (itemGerman.Code) {
                case 'E - ET':
                case 'E - Ersatzteile':
                    repairCode = 'E-spareParts';
                    break;
                case 'E - Lo':
                case 'E - Lohn':
                    repairCode = 'E-laborCosts';
                    break;
                case 'E - Ne':
                case 'E - Nebenkosten':
                    repairCode = 'E-auxiliaryCosts';
                    break;
                case 'I':
                case 'R':
                case 'A':
                case 'L':
                case 'M':
                case 'C':
                case 'N':
                case 'P':
                case 'S':
                case 'T':
                case 'D':
                case 'Z':
                case 'RIS':
                    repairCode = itemGerman.Code || undefined;
                    break;
                default:
                    repairCode = undefined;
            }

            let laborType: LaborType;
            switch (itemGerman.Lohnstufe) {
                case 'Mechanik':
                    laborType = 'mechanics_1';
                    break;
                case 'Elektrik':
                    laborType = 'electrics_1';
                    break;
                case 'Karosserie':
                    laborType = 'carBody_1';
                    break;
                case 'Lack':
                    laborType = 'carPaint';
                    break;
                case 'Dellen':
                    laborType = 'dents';
                    break;
            }

            // Translate German numbers into English format.
            const unitPrice = itemGerman.Einzelpreis
                ? round(+itemGerman.Einzelpreis.replace(/[€.]/g, '').replace(',', '.'))
                : null;

            const quantity = itemGerman.Menge
                ? round(+`${itemGerman.Menge}`.replace(/[€.]/g, '').replace(',', '.'))
                : null;

            return new ManualCalculationItem({
                description: itemGerman.Beschreibung,
                partOrWorkItemNumber: itemGerman['APN/ETN'],
                repairCode: repairCode,
                category: null,
                quantity,
                unit: 'pieces',
                unitPrice,
                laborType: laborType,
                valueIncreasePercentage: itemGerman.NfA ? +itemGerman.NfA : undefined,
            });
        });

        this.parseResult.emit(calculationItems);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Upload
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog() {
        this.close.emit();
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcut(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
        event.stopPropagation();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

interface ManualCalculationCsvItemGerman {
    Beschreibung: string;
    Code:
        | 'E - ET'
        | 'E - Ersatzteile'
        | 'E - Lo'
        | 'E - Lohn'
        | 'E - Ne'
        | 'E - Nebenkosten'
        | 'I'
        | 'R'
        | 'A'
        | 'L'
        | 'M'
        | 'C'
        | 'N'
        | 'P'
        | 'S'
        | 'T'
        | 'D'
        | 'Z'
        | 'RIS';
    'APN/ETN': string;
    Menge: number;
    Einzelpreis: string;
    NfA: number; // 5% comes from the CSV file as 5 -> To calculate with it, transform it to 0.05
    Lohnstufe: 'Mechanik' | 'Elektrik' | 'Karosserie' | 'Lack' | 'Dellen';
    Gesamtpreis: string; // Only contains a message that this field will be calculated automatically.
}

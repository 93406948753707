import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { flattenChangePaths } from '@autoixpert/lib/server-sync/flatten-change-paths';
import {
    ObjectDifference,
    getListOfDifferencesFromFlattenedChangePaths,
} from '@autoixpert/lib/server-sync/get-list-of-differences';
import { insertChangesIntoRecord } from '@autoixpert/lib/server-sync/insert-changes-into-record';
import { AxError } from '@autoixpert/models/errors/ax-error';
import {
    DataTypeBase,
    DatabaseServiceName,
    ObjectStoreAndIndexMigrations,
    RecordMigrations,
} from '@autoixpert/models/indexed-db/database.types';
import { FeathersSocketioService } from '../../services/feathers-socketio.service';
import { FrontendLogService } from '../../services/frontend-log.service';
import { NetworkStatusService } from '../../services/network-status.service';
import { SyncIssueNotificationService } from '../../services/sync-issue-notification.service';
import { ToastService } from '../../services/toast.service';
import { OfflineSyncServiceBase } from './offline-sync-service.base';

/**
 * When a list of records (report list, invoice list etc.) is open, we provide live updates.
 *
 * This service allows registering which record updates the user is currently interested in.
 */
export class LiveSyncServiceBase<DataType extends DataTypeBase> extends OfflineSyncServiceBase<DataType> {
    private feathersSocketioClient: FeathersSocketioService;

    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        httpClient: HttpClient;
        feathersSocketioClient: FeathersSocketioService;
        networkStatusService: NetworkStatusService;
        syncIssueNotificationService: SyncIssueNotificationService;
        frontendLogService: FrontendLogService;
        skipOutstandingSyncsCheckOnLogout?: boolean;
        recordMigrations?: RecordMigrations<DataType>;
        objectStoreAndIndexMigrations?: ObjectStoreAndIndexMigrations<DataType>;
        keysOfQuickSearchRecords?: string[];
        get$SearchMongoQuery?: (searchTerm: string) => [string, any];
        toastNotificationService?: ToastService;
        serviceWorker: SwUpdate;
    }) {
        super({
            serviceName: params.serviceName,
            serviceNamePlural: params.serviceNamePlural,
            httpClient: params.httpClient,
            syncIssueNotificationService: params.syncIssueNotificationService,
            skipOutstandingSyncsCheckOnLogout: params.skipOutstandingSyncsCheckOnLogout,
            frontendLogService: params.frontendLogService,
            networkStatusService: params.networkStatusService,
            recordMigrations: params.recordMigrations,
            objectStoreAndIndexMigrations: params.objectStoreAndIndexMigrations,
            keysOfQuickSearchRecords: params.keysOfQuickSearchRecords,
            get$SearchMongoQuery: params.get$SearchMongoQuery,
            toastNotificationService: params.toastNotificationService,
            serviceWorker: params.serviceWorker,
        });

        this.feathersSocketioClient = params.feathersSocketioClient;

        // Listen to live updates from server.
        this.registerWebsocketEventListeners();

        // Rejoin update channels after the user comes back online
        this.registerBackOnlineHandlers();
    }

    // Update channels
    private joinedUpdateChannels: Set<DataType['_id']> = new Set();

    /**
     * Listen to websocket events from the server and take actions such as create, push, delete.
     *
     * The server only send events for records to whose channels this service has subscribed to.
     * @private
     */
    private registerWebsocketEventListeners() {
        /**
         * Listen for changes coming from the other machines via the socket.io/feathers.js server.
         */
        this.registerWebsocketCreateListener();
        this.registerWebsocketPatchListener();
        this.registerWebsocketDeleteListener();
    }

    /**
     * Handle creations by other users, sent through websockets via the backend server.
     * @private
     */
    private registerWebsocketCreateListener() {
        this.feathersSocketioClient.app.service(this.serviceNamePlural).on('created', async (newRecord: DataType) => {
            console.log(`[live-sync] "created" event received via WebSocket`, newRecord);
            await this.localDb.createLocal(newRecord, 'externalServer');
            await this.localDb.putServerShadow(newRecord);
        });
    }

    /**
     * Handle updates by other users, sent through websockets via the backend server.
     * @private
     */
    private registerWebsocketPatchListener() {
        this.feathersSocketioClient.app.service(this.serviceNamePlural).on('patched', async (changes: DataType) => {
            console.log(`[live-sync] "patched" event received via WebSocket`, changes);
            await this.localDb.patchLocal(changes, 'externalServer');

            //*****************************************************************************
            //  Update Server Shadow
            //****************************************************************************/
            /**
             * All changes coming from a "patched" event from the server must have been applied in the database, too. So apply all these
             * changes to the current server shadow to ensure the correct changes in comparison to the most up-to-date server shadow are synced
             * to the server. That saves bandwidth and reduces accidental overwrites.
             */
            const serverShadow = await this.localDb.getServerShadow(changes._id);
            if (!serverShadow) {
                throw new Error('Server shadow missing when trying to apply changes from PATCH event.');
            }
            const listOfDifferences: ObjectDifference[] = getListOfDifferencesFromFlattenedChangePaths(
                flattenChangePaths(changes),
            );
            insertChangesIntoRecord(serverShadow, listOfDifferences);

            await this.localDb.putServerShadow(serverShadow);
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Update Server Shadow
            /////////////////////////////////////////////////////////////////////////////*/
        });
    }

    /**
     * Handle deletions by other users, sent through websockets via the backend server.
     * @private
     */
    private registerWebsocketDeleteListener() {
        this.feathersSocketioClient.app
            .service(this.serviceNamePlural)
            .on('removed', async (deletedRecord: DataType) => {
                console.log(`[live-sync] "deleted" event received via WebSocket`, deletedRecord);
                await this.localDb.deleteLocal(deletedRecord._id, 'externalServer');
                await this.localDb.deleteServerShadow(deletedRecord._id);
                await this.leaveUpdateChannel(deletedRecord._id).catch(() =>
                    console.warn(
                        `[live-sync] Leaving the update channel "${this.serviceName}/${deletedRecord._id}" failed. That's optional, so fail silently.`,
                    ),
                );
            });
    }

    //*****************************************************************************
    //  Live Update Channels
    //****************************************************************************/
    /**
     * Listen to patches and deletes related to a certain record, identified by its ID.
     */
    public async joinUpdateChannel(recordId: DataType['_id']): Promise<ChannelSubscription> {
        // Save which channels should be listened to, in order to set up the listeners on reconnect.
        this.joinedUpdateChannels.add(recordId);

        if (this.networkStatusService.isOnline()) {
            try {
                await this.feathersSocketioClient.ensureAuthenticatedConnection();
                await this.feathersSocketioClient.app.service(`${this.serviceName}LiveSyncSubscription`).create({
                    recordId,
                });
            } catch (error) {
                throw new AxError({
                    code: 'CREATING_LIVE_SYNC_SUBSCRIPTION_FAILED',
                    message: 'See the error below for further details.',
                    error,
                });
            }
        }

        // For debugging
        //console.log(`joined channel ${this.serviceNamePlural} (ID: ${recordId})`, result);
        return {
            recordId,
            serviceName: this.serviceName,
        };
    }

    /**
     * Do not listen to socket updates for this record anymore.
     */
    public async leaveUpdateChannel(recordId: DataType['_id']): Promise<void> {
        // Ensure this channel is not joined again on reconnect.
        this.joinedUpdateChannels.delete(recordId);

        if (this.networkStatusService.isOnline() && this.feathersSocketioClient.app.authentication?.authenticated) {
            try {
                await this.feathersSocketioClient.ensureAuthenticatedConnection();
                await this.feathersSocketioClient.app
                    .service(`${this.serviceName}LiveSyncSubscription`)
                    .remove(recordId);
                // For debugging.
                //.then((result: any) => {
                //    console.log(`left channel ${this.serviceNamePlural} (ID: ${recordId})`, result);
                //});
            } catch (error) {
                /**
                 * If the user logged out in the meantime, do not throw an error.
                 */
                if (!this.feathersSocketioClient.app.authentication?.authenticated) {
                    return;
                }
                throw new AxError({
                    code: 'REMOVING_LIVE_SYNC_SUBSCRIPTION_FAILED',
                    message:
                        'Letting the server know that this client does not need to be updated through a websocket connection on a certain type of resource failed. See the error below for further details.',
                    error,
                });
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Live Update Channels
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Online after Offline Handlers
    //****************************************************************************/
    /**
     * Register actions to take when the user comes back online after going offline.
     */
    protected registerBackOnlineHandlers(): void {
        this.networkStatusService.networkBackOnline$.subscribe({
            next: async () => {
                if (!this.joinedUpdateChannels.size) {
                    console.log(`[live-sync] No update channels to rejoin for the service ${this.serviceName}.`);
                    return;
                }

                // Start listening for changes of the relevant records again. A disconnection removed this client from all channels.
                for (const joinedUpdateChannel of this.joinedUpdateChannels) {
                    try {
                        await this.joinUpdateChannel(joinedUpdateChannel);
                    } catch (error) {
                        console.warn(
                            `[live-sync] Re-joining the update channel "${this.serviceName}/${joinedUpdateChannel}" after going back online failed. Update channels are optional, so fail silently and skip joining all other update channels.`,
                            { socketAuthenticated: this.feathersSocketioClient.app.authentication.authenticated },
                        );
                        return;
                    }
                }
                console.log(
                    `[live-sync] Rejoined ${this.joinedUpdateChannels.size} update ${this.joinedUpdateChannels.size === 1 ? 'channel' : 'channels'} for the service "${this.serviceName}".`,
                );
            },
            error: (error) => {
                console.error('[live-sync] Network Status Service errored.', error);
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Online after Offline Handlers
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface ChannelSubscription {
    recordId: DataTypeBase['_id'];
    serviceName: string;
}

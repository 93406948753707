'use strict';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AccessRights } from '@autoixpert/models/user/user';
import { LoggedInUserService } from './logged-in-user.service';

/**
 * When using this guard, the route must be passed a 'data' object with a 'requiredAccessRight' property.
 */
@Injectable()
export class AccessRightGuard {
    constructor(private loggedInUserService: LoggedInUserService) {}

    public canActivate(route: ActivatedRouteSnapshot) {
        const user = this.loggedInUserService.getUser();
        const requiredAccessRight = (route.data as { requiredAccessRight: keyof AccessRights }).requiredAccessRight;
        if (!requiredAccessRight) return true;

        if (user.accessRights[requiredAccessRight]) {
            return true;
        } else {
            console.log(`User does not possess access right '${requiredAccessRight}'.`);
            return false;
        }
    }
}

import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu/index';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { getReportTypeAbbreviation } from '@autoixpert/lib/translators/get-report-type-abbreviation';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { ReportListSearchMatch } from '@autoixpert/models/reports/report-list-search-match';
import { ResidualValueOffer } from '@autoixpert/models/reports/residual-value/residual-value-offer';
import { ReportListAvailableDateRows } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { isMiddleClick } from '../../../shared/libraries/is-middle-click';
import { isSmallScreen } from '../../../shared/libraries/is-small-screen';
import { openInNewTabOnMiddleClick } from '../../../shared/libraries/open-in-new-tab-on-middle-click';
import { PhotoBlobUrlCacheService } from '../../../shared/services/photo-blob-url-cache.service';
import { RenderedPhotoFileService } from '../../../shared/services/rendered-photo-file.service';
import { ReportService } from '../../../shared/services/report.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import {
    CopyReportDialogComponent,
    CopyReportDialogComponentConfig,
} from '../../copy-report-dialog/copy-report-dialog.component';

/**
 * Simple display component that subscribes to realtime updates.
 *
 * Creation, deletion and merging updates are handled by the parent.
 */
@Component({
    selector: 'tr[report-list-row]',
    templateUrl: 'report-list-row.component.html',
    styleUrls: ['report-list-row.component.scss'],
    host: {
        '[class.report]': 'true',
        '[class.record]': 'true',
        '[class.active]': 'isSelected',
        '[class.token-shown]': 'this.userPreferences.reportListReportTokenShown || false',
        '[class.labels-shown]': 'this.userPreferences.reportListLabelsShown || false',
        '[class.search-matches-shown]': 'this.searchTerm || false',
    },
})
export class ReportListRowComponent implements OnInit, OnDestroy {
    constructor(
        private reportService: ReportService,
        public userPreferences: UserPreferencesService,
        private renderedPhotoFileService: RenderedPhotoFileService,
        private photoBlobUrlCacheService: PhotoBlobUrlCacheService,
        private router: Router,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private dialog: MatDialog,
    ) {}

    @Input() report: Report;
    @Input() isSelected: boolean;
    @Input() searchTerm: string; // Highlight the report properties which the search term matches.
    @Input() searchMatches: ReportListSearchMatch[]; // Highlight the report properties which the search term matches.
    @Input() assessors: User[] = [];

    @Output() selection: EventEmitter<Report> = new EventEmitter();
    @Output() reportChange: EventEmitter<Report> = new EventEmitter();
    // Trash
    @Output() moveToTrash: EventEmitter<Report> = new EventEmitter();
    @Output() restoreFromTrash: EventEmitter<Report> = new EventEmitter();
    @Output() deleteReportPermanently: EventEmitter<Report> = new EventEmitter();

    public notesIconManuallyShown: boolean;

    private localThumbnailFileUrl: string;
    public localThumbnailFileSafeUrl: SafeResourceUrl;

    public loadingFirstReportPhotoFailed: boolean = false;

    ngOnInit() {
        this.initializePhotoFile();
        this.reportService
            .joinUpdateChannel(this.report._id)
            .catch(() =>
                console.warn(
                    `Joining the update channel "reports/${this.report._id}" failed. Update channels are optional, so fail silently.`,
                ),
            );
    }

    public emitSelection() {
        this.selection.emit(this.report);
    }

    public emitChange() {
        this.reportChange.emit(this.report);
    }

    //*****************************************************************************
    //  Thumbnail Photo
    //****************************************************************************/
    public getFirstReportPhoto(): Photo {
        return this.report.photos.find((photo) => photo.versions.report.included);
    }

    /**
     * Load all photos from the server (including the correct authentication header) and save them to local blobs.
     */
    private async initializePhotoFile(): Promise<Blob> {
        // Photo must be included = active.
        const firstReportPhoto = this.getFirstReportPhoto();

        // No active photo found?
        if (!firstReportPhoto) {
            return;
        }

        // If the photo already exists, don't query a second time.
        if (this.localThumbnailFileUrl) {
            return;
        }

        /**
         * Try to find a blob URL that was already created for this window.document and is still valid (same fabricJsInformation).
         */
        this.localThumbnailFileUrl = this.photoBlobUrlCacheService.get(
            firstReportPhoto._id,
            'report',
            'thumbnail400',
            firstReportPhoto.versions.report.fabricJsInformation,
        );
        if (this.localThumbnailFileUrl) {
            return;
        }

        try {
            const renderedPhotoBlob: Blob = await this.renderedPhotoFileService.getFile({
                reportId: this.report._id,
                photo: firstReportPhoto,
                version: 'report',
                format: 'thumbnail400',
            });
            this.localThumbnailFileUrl = window.URL.createObjectURL(renderedPhotoBlob);
            this.photoBlobUrlCacheService.set(
                firstReportPhoto._id,
                'report',
                'thumbnail400',
                firstReportPhoto.versions.report.fabricJsInformation,
                this.localThumbnailFileUrl,
            );
        } catch (error) {
            // console.warn(`Error retrieving the first thumbnail file for report ${this.report._id}.`);
            this.loadingFirstReportPhotoFailed = true;
        }
    }

    /**
     * Return either the local photo URI or null if the photo is not available.
     */
    public getLocalThumbnailUrl(): SafeResourceUrl {
        // If the safe URL has already been generated, return it.
        if (this.localThumbnailFileSafeUrl) {
            return this.localThumbnailFileSafeUrl;
        }
        // Generate and cache a safe URL from the local URL if the photo exists locally already.
        else if (this.localThumbnailFileUrl) {
            this.localThumbnailFileSafeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                this.localThumbnailFileUrl,
            );
        }
        return this.localThumbnailFileSafeUrl;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Thumbnail Photo
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date row
    //****************************************************************************/
    get selectedDateRow(): ReportListAvailableDateRows {
        return this.userPreferences.reportListSelectedDateRow ?? 'created';
    }

    get firstVisitIsToday(): boolean {
        return moment(this.report.visits[0]?.date).isSame(moment(), 'day');
    }

    get lastVisitDate() {
        if (this.report.visits.length === 1) {
            return undefined;
        }
        const lastVisit = this.report.visits[this.report.visits.length - 1];
        return { date: lastVisit.date, isToday: moment(lastVisit.date).isSame(moment(), 'day') };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date row
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Display Sync Issues
    //****************************************************************************/
    public hasSyncIssues(): boolean {
        return this.reportService.recordIdsWithSyncIssues.has(this.report._id);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Display Sync Issues
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Car Brand Icons
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Residual Value
    //****************************************************************************/

    /**
     * Checks if the given auction is already expired, bids were submitted and no import has occurred, leads to a blue gavel being displayed
     */
    public residualValueMayBeImported(): boolean {
        const residualValueInquiries: ResidualValueOffer[] = [
            this.report.valuation?.winvalueResidualValueOffer,
            this.report.valuation?.cartvResidualValueOffer,
            this.report.valuation?.carcasionResidualValueOffer,
            this.report.valuation?.autoonlineResidualValueOffer,
        ];
        const externalResidualValueExchangesAreDue: boolean = residualValueInquiries.filter(Boolean).some((inquiry) => {
            return this.isResidualValueImportDue(inquiry.readyAt, inquiry.retrievedAt);
        });

        return (
            externalResidualValueExchangesAreDue ||
            this.isResidualValueImportDue(
                this.report.valuation?.autoixpertResidualValueOffer?.closingDate,
                this.report.valuation?.autoixpertResidualValueOffer?.retrievedAt,
            )
        );
    }

    /**
     * Returns a boolean which is determined as the given dates have already happened and the retrievalDate happened after the expiration of the start date
     */
    public isResidualValueImportDue(readyAt: string, retrievedAt: string): boolean {
        const offerHasEnded: boolean = readyAt && moment(readyAt).isBefore();
        const noRetrievalsOrOnlyBeforeExpirationDate: boolean = !retrievedAt || moment(retrievedAt).isBefore(readyAt);
        return offerHasEnded && noRetrievalsOrOnlyBeforeExpirationDate;
    }

    public areAnyResidualValueRequestsRunningOrReadyToBeImported(): boolean {
        const { runningRequests, requestsReadyToBeImported } = this.getResidualValueRequestStatuses();

        return (runningRequests.length || requestsReadyToBeImported.length) > 0;
    }

    /**
     * Determine the status for each residual value exchange.
     *
     * Used to display the gray or blue gavel icon if at least one request is running or even ready to be imported.
     *
     * @returns An object of running requests and the ones ready to be imported back into aX.
     * The arrays are sorted by finishing date in ascending order (last one to finish is last).
     */
    public getResidualValueRequestStatuses() {
        let residualValueOfferStatuses: ResidualValueOfferStatus[] = [];

        // Some reports don't have valuation objects.
        if (this.report.valuation) {
            residualValueOfferStatuses = new Array<ResidualValueOfferStatus>(
                this.report.valuation.autoonlineResidualValueOffer
                    ? {
                          exchangeName: 'AUTOonline',
                          readyAt: this.report.valuation.autoonlineResidualValueOffer.readyAt,
                          status: this.getStatusOfResidualValueOffer({
                              readyAt: this.report.valuation.autoonlineResidualValueOffer.readyAt,
                              retrievedAt: this.report.valuation.autoonlineResidualValueOffer.retrievedAt,
                          }),
                      }
                    : null,
                this.report.valuation.cartvResidualValueOffer
                    ? {
                          exchangeName: 'CarTV',
                          readyAt: this.report.valuation.cartvResidualValueOffer.readyAt,
                          status: this.getStatusOfResidualValueOffer({
                              readyAt: this.report.valuation.cartvResidualValueOffer.readyAt,
                              retrievedAt: this.report.valuation.cartvResidualValueOffer.retrievedAt,
                          }),
                      }
                    : null,
                this.report.valuation.carcasionResidualValueOffer
                    ? {
                          exchangeName: 'car.casion',
                          readyAt: this.report.valuation.carcasionResidualValueOffer.readyAt,
                          status: this.getStatusOfResidualValueOffer({
                              readyAt: this.report.valuation.carcasionResidualValueOffer.readyAt,
                              retrievedAt: this.report.valuation.carcasionResidualValueOffer.retrievedAt,
                          }),
                      }
                    : null,
                this.report.valuation.winvalueResidualValueOffer
                    ? {
                          exchangeName: 'WinValue',
                          readyAt: this.report.valuation.winvalueResidualValueOffer.readyAt,
                          status: this.getStatusOfResidualValueOffer({
                              readyAt: this.report.valuation.winvalueResidualValueOffer.readyAt,
                              retrievedAt: this.report.valuation.winvalueResidualValueOffer.retrievedAt,
                          }),
                      }
                    : null,
                this.report.valuation.autoixpertResidualValueOffer
                    ? {
                          exchangeName: 'Lokaler Restwertverteiler',
                          readyAt: this.report.valuation.autoixpertResidualValueOffer.closingDate,
                          status: this.getStatusOfResidualValueOffer({
                              readyAt: this.report.valuation.autoixpertResidualValueOffer.closingDate,
                              retrievedAt: this.report.valuation.autoixpertResidualValueOffer.retrievedAt,
                          }),
                      }
                    : null,
            ).filter(Boolean);
        }

        const runningRequests = residualValueOfferStatuses.filter((offerStatus) => offerStatus.status === 'running');
        const requestsReadyToBeImported = residualValueOfferStatuses.filter(
            (offerStatus) => offerStatus.status === 'readyToBeImported',
        );

        // Sort so that the longest-running offer is last. We need that item to display when all of them will have finished.
        runningRequests.sort(sortByProperty('readyAt'));
        requestsReadyToBeImported.sort(sortByProperty('readyAt'));

        return {
            runningRequests,
            requestsReadyToBeImported,
        };
    }

    public getResidualValueOffersTooltip(): string {
        const { runningRequests, requestsReadyToBeImported } = this.getResidualValueRequestStatuses();

        let tooltip = '';
        if (runningRequests.length) {
            tooltip = `Das letzte Restwertinserat endet ${moment(runningRequests.at(-1).readyAt).calendar({
                sameElse: '[am] DD.MM.YYYY',
            })}.\n\n`;
        } else if (requestsReadyToBeImported.length) {
            tooltip = `Das letzte Restwertinserat endete ${moment(requestsReadyToBeImported.at(-1).readyAt).calendar({
                sameElse: '[am] DD.MM.YYYY',
            })} und ist bereit zum Import.\n\n`;
        }

        tooltip += `Klicke, um die Restwertverwaltung zu öffnen.`;

        return tooltip;
    }

    public getStatusOfResidualValueOffer({
        readyAt,
        retrievedAt,
    }: {
        readyAt: string;
        retrievedAt: string;
    }): 'notStarted' | 'running' | 'readyToBeImported' | 'finished' {
        if (!readyAt) {
            return 'notStarted';
        }

        if (this.isResidualValueImportDue(readyAt, retrievedAt)) {
            return 'readyToBeImported';
        }

        // Is readyAt in the future? -> Request is running.
        const isRequestRunning = moment(readyAt).isAfter();
        if (isRequestRunning) {
            return 'running';
        }

        return 'finished';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Residual Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Locked State
    //****************************************************************************/
    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    public isReportDeleted(): boolean {
        return this.report.state === 'deleted';
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Locked State
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Search Matches
    //****************************************************************************/
    public getFirstThreeSearchMatches(): ReportListSearchMatch[] {
        return this.searchMatches?.slice(0, 3) ?? [];
    }

    public getSearchMatchName(nameAbbreviation: string): string {
        switch (nameAbbreviation) {
            case 'Rechnungs-Nr.':
                return 'Rechnungsnummer im Gutachten: ';
            case 'RB Rechnungs-Nr.':
                return 'Reparaturbestätigung Rechnungsnummer: ';
            case 'SN Rechnungs-Nr.':
                return 'Stellungnahme Rechnungsnummer: ';
            default:
                return '';
        }
    }

    public getAdditionalMatchesTooltip(): string {
        const searchMatches = this.searchMatches;
        if (!searchMatches) return;

        return searchMatches.map((searchMatch) => `${searchMatch.name}: ${searchMatch.value}`).join('\n');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Search Matches
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Notes
    //****************************************************************************/
    /**
     * If notes exist already, the notes icon will be displayed automatically.
     * This method allows displaying the notes icon manually, e.g. through the submenu of a report record.
     */
    public showNotesManually() {
        this.notesIconManuallyShown = true;
    }

    public hideIconForEmptyNotes() {
        if (!this.report.notes.length) {
            this.notesIconManuallyShown = false;
        }
    }

    public hasImportantNote(report: Report): boolean {
        return report.notes.some((note) => note.isImportant);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Notes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public navigateToReport() {
        this.router.navigate([this.report._id], { relativeTo: this.route });
    }

    /**
     * On mobile devices, one can navigate to the detail view of a record by clicking
     * the record except its toolbar or submenu icon.
     */
    public navigateToDetailsViewOnMobile() {
        if (isSmallScreen()) {
            this.navigateToReport();
        }
    }

    public openReportInNewTab(event: MouseEvent, matMenuTrigger: MatMenuTrigger) {
        window.open(`/Gutachten/${this.report._id}`);
        // Prevent the click from selecting the menu entry
        event.stopPropagation();

        // Since event propagation stopped, the click won't close the menu automatically.
        matMenuTrigger.closeMenu();
    }

    public navigateToDamageCalculation() {
        this.router.navigate([this.report._id, 'Schadenskalkulation'], { relativeTo: this.route });
    }

    public navigateToRepairConfirmation() {
        this.router.navigate([this.report._id, 'Reparaturbestätigung'], { relativeTo: this.route });
    }

    public navigateToExpertStatement() {
        this.router.navigate([this.report._id, 'Stellungnahme'], { relativeTo: this.route });
    }

    public navigateToOriginalReport() {
        this.router.navigate([`./${this.report.originalReportId}`], { relativeTo: this.route });
    }

    public navigateToAmendmentReport() {
        this.router.navigate([`./${this.report.amendmentReportId}`], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Trash
    //****************************************************************************/
    public emitMoveToTrash() {
        this.moveToTrash.emit(this.report);
    }

    public emitRestoreFromTrash() {
        this.restoreFromTrash.emit(this.report);
    }

    public emitDeleteReportPermanently() {
        this.deleteReportPermanently.emit(this.report);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Trash
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Copy Reports & Amendment Reports
    //****************************************************************************/
    public openAmendmentReportDialog() {
        this.dialog.open<CopyReportDialogComponent, CopyReportDialogComponentConfig>(CopyReportDialogComponent, {
            data: {
                sourceReport: this.report,
                createAmendmentReport: true,
            },
        });
    }

    public openCopyReportDialog() {
        this.dialog.open<CopyReportDialogComponent, CopyReportDialogComponentConfig>(CopyReportDialogComponent, {
            data: { sourceReport: this.report },
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy Reports & Amendment Reports
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Copy & Amendment Dialogs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  GDV Status
    //****************************************************************************/

    protected isGdvOrder() {
        return !!this.report.gdvOrderDetails;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END GDV Status
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Remote Signature Status
    //****************************************************************************/
    protected getRemoteSignatureStatus():
        | undefined
        | 'link-generated'
        | 'deadline-passed'
        | 'signatures-submitted'
        | 'signatures-checked' {
        if (this.report.remoteSignatureConfig?.claimantSignaturesChecked) {
            return 'signatures-checked';
        }

        if (this.report.remoteSignatureConfig?.claimantSubmittedAt) {
            return 'signatures-submitted';
        }

        if (
            this.report.remoteSignatureConfig?.signatureDeadlineAt &&
            new Date(this.report.remoteSignatureConfig.signatureDeadlineAt) < new Date()
        ) {
            return 'deadline-passed';
        }

        if (this.report.remoteSignatureConfig) {
            return 'link-generated';
        }
        return undefined;
    }

    protected getRemoteSignatureStatusTooltip(
        signatureStatus: 'link-generated' | 'deadline-passed' | 'signatures-submitted' | 'signatures-checked',
    ) {
        switch (signatureStatus) {
            case 'link-generated':
                return 'Der Link zur Unterschrift wurde generiert.';

            case 'deadline-passed':
                return 'Die Frist zur Unterschrift ist abgelaufen.';

            case 'signatures-submitted':
                return 'Die Unterschrift wurde eingereicht.';

            default:
                return '';
        }
    }

    protected navigateToRemoteSignatureDialog() {
        this.router.navigate([this.report._id, 'Beteiligte'], {
            relativeTo: this.route,
            queryParams: { remoteSignature: true },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Remote Signature Status
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Host bindings
    //****************************************************************************/
    @HostListener('mousedown', ['$event'])
    public handleMouseDown(event: MouseEvent) {
        openInNewTabOnMiddleClick(event, `/Gutachten/${this.report._id}`);
    }

    @HostListener('click', ['$event'])
    public handleHostClick(event: MouseEvent) {
        if (!isMiddleClick(event)) {
            this.emitSelection();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Host bindings
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Translations
    //****************************************************************************/
    public getReportTypeAbbreviation = getReportTypeAbbreviation;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Translations
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.reportService
            .leaveUpdateChannel(this.report._id)
            .catch(() =>
                console.warn(
                    `Leaving the update channel "reports/${this.report._id}" failed. That's optional, so fail silently.`,
                ),
            );
    }

    protected readonly isSmallScreen = isSmallScreen;
}

interface ResidualValueOfferStatus {
    exchangeName: 'WinValue' | 'CarTV' | 'AUTOonline' | 'car.casion' | 'Lokaler Restwertverteiler';
    status: 'notStarted' | 'running' | 'readyToBeImported' | 'finished';
    readyAt: string;
}

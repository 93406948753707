import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS,
    MatLegacyCheckbox as MatCheckbox,
    MatLegacyCheckboxChange as MatCheckboxChange,
    MatLegacyCheckboxDefaultOptions as MatCheckboxDefaultOptions,
} from '@angular/material/legacy-checkbox';

@Component({
    selector: 'tri-state-checkbox',
    templateUrl: './tri-state-checkbox.component.html',
    styleUrls: ['./tri-state-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TriStateCheckboxComponent),
            multi: true,
        },
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: {
                clickAction: 'noop',
                color: 'accent',
            } as MatCheckboxDefaultOptions,
        },
    ],
})
export class TriStateCheckboxComponent implements ControlValueAccessor {
    @Input() options = [null, true, false];
    disabled: boolean;

    @Output() change: EventEmitter<MatCheckboxChange> = new EventEmitter();

    value: any;

    @ViewChild('checkbox') checkbox: ElementRef<MatCheckbox>;

    private onChange: (val: boolean) => void;
    private onTouched: () => void;

    writeValue(value: any) {
        this.value = typeof value === 'undefined' ? this.options[0] : value;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    public chooseNextOption() {
        if (this.disabled) return;

        // Get next option
        this.value = this.options[(this.options.indexOf(this.value) + 1) % this.options.length];
        this.onChange(this.value);
        this.onTouched();

        const matCheckboxChangeEvent = new MatCheckboxChange();
        matCheckboxChangeEvent.source = this.checkbox.nativeElement;
        matCheckboxChangeEvent.checked = this.value;
        this.change.emit(matCheckboxChangeEvent);
    }

    // Registering happens by Angular when (ngModelChange) oder [(ngModel)] is encountered in a template. This is the same as the "@Output() xyzChange: EventEmitter".
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}

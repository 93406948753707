import { DateTime } from 'luxon';
import { generateId } from '../lib/generate-id';

export class InternalNote {
    constructor(template: Partial<InternalNote> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    text: string = null;
    isImportant: boolean = false;
    createdBy: string = null;
    updatedBy: string = null;
    // Use time zone "UTC" to ensure the string    has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

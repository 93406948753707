import { Injectable } from '@angular/core';
import { Report } from '@autoixpert/models/reports/report';

/**
 * A cache object to keep reports in the random access memory (RAM) in contrast to saving them on disk. For saving reports on disk, we use store.js.
 * We cannot use a Map() directly on the service since we later want to implement a cache timeout.
 */
@Injectable()
export class ReportRAMCacheService {
    private reports = new Map<string, Report>();

    /**
     * Create or update a report
     * @param report
     * @return {Map<number, Report>}
     */
    set(report: Report) {
        // Add the report to RAM cache.
        this.reports.set(report._id, report);

        return this.reports;
    }

    /**
     * Get a report by its _id if the cache entry is still valid.
     * @param reportId
     * @return {Report} Return the report with the given reportId or return undefined.
     */
    get(reportId: string): Report {
        return this.reports.get(reportId);
    }

    /**
     * Get all reports from cache.
     * @return {Map<string, Report>}
     */
    getAll(): Map<string, Report> {
        return this.reports;
    }

    /**
     * Remove a sepcific report from the cache.
     * @param reportId
     * @return {boolean}
     */
    delete(reportId: string) {
        if (!this.reports.has(reportId)) {
            console.warn('This reportId does not exist.', reportId);
        }
        return this.reports.delete(reportId);
    }

    /**
     * Remove all reports from the cache.
     * @return {boolean}
     */
    clear() {
        this.reports.forEach((cachedReport) => {
            this.delete(cachedReport._id);
        });

        return true;
    }
}

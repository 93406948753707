import { Component, Input } from '@angular/core';
import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { ChecklistItemStatus } from '@autoixpert/models/report-progress/checklist-item-status';
import { ChecklistItemName } from '@autoixpert/models/report-progress/report-progress-checklist-item-names';
import { ReportProgressConfig } from '@autoixpert/models/report-progress/report-progress-config';
import { ChecklistItem } from '@autoixpert/models/report-progress/report-progress-setting';
import { Report } from '@autoixpert/models/reports/report';
import { cumulateCompletionStatus, determineChecklistItemStatus } from '../determine-checklist-item-status';
import { getAvailableChecklistItemsByTabName } from '../get-available-checklist-items-by-tab-name';
import { isChecklistItemRequired } from '../is-checklist-item-required';

/**
 * Indicates the cumulated status of all checklist items within a tab's checklist.
 */
@Component({
    selector: 'report-progress-indicator-icon',
    templateUrl: 'report-progress-indicator-icon.component.html',
    styleUrls: ['report-progress-indicator-icon.component.scss'],
})
export class ReportProgressIndicatorIconComponent {
    constructor() {}

    @Input() report: Report;
    @Input() reportTabName: ReportTabName;
    @Input() reportProgressConfig: ReportProgressConfig;
    @Input() overlayPositionY: 'top' | 'bottom' = 'bottom';

    ngOnInit() {}

    public getCumulatedChecklistStatus(): ChecklistItemStatus {
        const itemsForTab: ChecklistItem[] = this.getItemsForTab();

        const requiredItems: ChecklistItem[] = this.getRequiredItems(itemsForTab);

        // Determine the status only once and then cache in Map to improve performance.
        const statusMap: Map<ChecklistItemName, ChecklistItemStatus> = new Map();
        for (const item of itemsForTab) {
            statusMap.set(item.name, determineChecklistItemStatus(item.name, this.report));
        }

        //*****************************************************************************
        //  Cumulate Status
        //****************************************************************************/

        // All required items complete => return complete.
        if (requiredItems.length) {
            const areAllRequiredItemsComplete: boolean =
                cumulateCompletionStatus(...requiredItems.map((item) => statusMap.get(item.name))) === 'complete';
            if (areAllRequiredItemsComplete) {
                return 'complete';
            }
        }
        // No required items? -> Check the non-required items.
        else {
            const areItemsComplete: boolean =
                cumulateCompletionStatus(...itemsForTab.map((item) => statusMap.get(item.name))) === 'complete';
            if (areItemsComplete) {
                return 'complete';
            }
        }

        // All items (including non-required) empty => return empty.
        const areAllItemsEmpty: boolean =
            cumulateCompletionStatus(...itemsForTab.map((item) => statusMap.get(item.name))) === 'empty';
        if (areAllItemsEmpty) {
            return 'empty';
        }

        // If no case matched so far, some items must be started, others are still empty.
        return 'partiallyComplete';
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Cumulate Status
        /////////////////////////////////////////////////////////////////////////////*/
    }

    private getItemsForTab(): ChecklistItem[] {
        return getAvailableChecklistItemsByTabName({
            reportProgressConfig: this.reportProgressConfig,
            reportType: this.report.type,
            reportTabName: this.reportTabName,
        }).filter((item) => item.activated);
    }

    public getRequiredItems(allItems: ChecklistItem[] = this.getItemsForTab()): ChecklistItem[] {
        return allItems.filter((item) =>
            isChecklistItemRequired({
                item,
                report: this.report,
                reportProgressConfig: this.reportProgressConfig,
            }),
        );
    }
}

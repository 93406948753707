import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { sleep } from '@autoixpert/lib/sleep';
import { DocxWatermarkType } from '@autoixpert/models/documents/document-metadata';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { blobToBase64 } from '../../../../../shared/libraries/blob-to-base64';
import { hasAccessRight } from '../../../../../shared/libraries/user/has-access-right';
import { ApiErrorService } from '../../../../../shared/services/api-error.service';
import { DocxWatermarkTemplateService } from '../../../../../shared/services/docx-watermark-template.service';
import { DownloadService } from '../../../../../shared/services/download.service';
import { LoggedInUserService } from '../../../../../shared/services/logged-in-user.service';
import { TeamService } from '../../../../../shared/services/team.service';
import { ToastService } from '../../../../../shared/services/toast.service';

@Component({
    selector: 'docx-watermark-settings-card',
    templateUrl: './docx-watermark-settings-card.component.html',
    styleUrls: ['./docx-watermark-settings-card.component.scss'],
})
export class DocxWatermarkSettingsCardComponent {
    @Input() watermarkType: DocxWatermarkType = 'duplicate';

    @ViewChild('fileUpload') private fileUploadElement: ElementRef;

    public isUploadPending: boolean = false;
    public isDownloadPending: boolean = false;

    protected user: User;
    protected team: Team;

    constructor(
        private toastService: ToastService,
        private teamService: TeamService,
        private docxWatermarkTemplateService: DocxWatermarkTemplateService,
        private downloadService: DownloadService,
        private loggedInUserService: LoggedInUserService,
        private apiErrorService: ApiErrorService,
    ) {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();
    }

    //*****************************************************************************
    //  File Upload
    //****************************************************************************/
    protected openFileSelector(): void {
        this.fileUploadElement.nativeElement.click();
    }

    protected async onChangeWatermarkUploadFile(eventTarget: EventTarget): Promise<void> {
        const fileInput: HTMLInputElement = eventTarget as any;
        const watermarkUploadFile: File = fileInput.files[0];

        // Reset the file input for future uploads
        fileInput.value = null;

        //*****************************************************************************
        //  File Validation
        //****************************************************************************/
        if (
            watermarkUploadFile.type &&
            watermarkUploadFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            console.error('The given file is not a DOCX file.', watermarkUploadFile);
            this.toastService.error('Bitte lade eine DOCX-Datei hoch', '');
            return;
        }

        // Warn about file size: max 4 MB.
        if (watermarkUploadFile.size > 4000 * 1024) {
            this.toastService.error(
                'Datei zu groß',
                'Bitte lade nur Vorlagen unter 4 MB hoch. Versuche dazu, Logos und andere Grafiken zu verkleinern oder als JPG abzuspeichern, das kleinere Dateigrößen als eine PNG bietet.\n\nManchmal hilft es auch, die Grafik einmal zu entfernen und frisch in Word einzufügen.',
            );
            return;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END File Validation
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Actual File Upload
        //****************************************************************************/
        try {
            this.isUploadPending = true;

            await Promise.all([
                // Ensure the upload is not too fast so that the user sees the loading spinner.
                sleep(1000),
                // Upload the watermark template using the service
                this.docxWatermarkTemplateService.create(this.watermarkType, watermarkUploadFile),
            ]);

            // Update the team to reflect the changes
            if (!this.team.docxWatermarkHashes) {
                this.team.docxWatermarkHashes = { duplicate: null, draft: null };
            }

            // Mark that this type of watermark has been uploaded
            if (this.watermarkType === 'duplicate') {
                this.team.docxWatermarkHashes.duplicate = simpleHash(await blobToBase64(watermarkUploadFile));
            } else if (this.watermarkType === 'draft') {
                this.team.docxWatermarkHashes.draft = simpleHash(await blobToBase64(watermarkUploadFile));
            }
            void this.teamService.put(this.team);

            this.isUploadPending = false;
            this.toastService.success('Wasserzeichen-Vorlage hochgeladen');
        } catch (error) {
            this.isUploadPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Fehler beim Hochladen',
                    body: 'Die Wasserzeichen-Vorlage konnte nicht hochgeladen werden. Bitte versuche es erneut. Sollte das Problem weiterhin bestehen, kontaktiere bitte die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Actual File Upload
        /////////////////////////////////////////////////////////////////////////////*/
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END File Upload
    /////////////////////////////////////////////////////////////////////////////*/

    protected async downloadWatermark(): Promise<void> {
        try {
            this.isDownloadPending = true;

            const response = await this.docxWatermarkTemplateService.get(this.watermarkType);
            this.downloadService.downloadBlobResponseWithHeaders(response);

            this.isDownloadPending = false;
        } catch (error) {
            this.isDownloadPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Fehler beim Download',
                    body: 'Die Wasserzeichen-Vorlage konnte nicht heruntergeladen werden. Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    protected doesCustomTemplateExist(): boolean {
        return !!this.team.docxWatermarkHashes?.[this.watermarkType];
    }

    //*****************************************************************************
    //  Reset Watermark to Default
    //****************************************************************************/
    protected async resetDocxWatermark(): Promise<void> {
        try {
            // Reset the watermark template using the service
            await this.docxWatermarkTemplateService.resetDefault(this.watermarkType);

            // Mark that this type of watermark has been reset
            if (this.watermarkType === 'duplicate') {
                this.team.docxWatermarkHashes.duplicate = null;
            } else if (this.watermarkType === 'draft') {
                this.team.docxWatermarkHashes.draft = null;
            }

            void this.teamService.put(this.team);

            this.toastService.success('Wasserzeichen zurückgesetzt');
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Fehler beim Zurücksetzen',
                    body: 'Das Wasserzeichen konnte nicht zurückgesetzt werden.',
                },
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Reset Watermark to Default
    /////////////////////////////////////////////////////////////////////////////*/

    protected readonly hasAccessRight = hasAccessRight;
}

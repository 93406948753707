import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthenticationResponse, AuthenticationService } from '../../shared/services/authentication.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatusService } from '../../shared/services/network-status.service';
import { PasswordResetService } from '../../shared/services/password-reset.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { ToastService } from '../../shared/services/toast.service';

@Component({
    selector: 'ax-login-card',
    styleUrls: ['login-card.scss'],
    templateUrl: 'login-card.component.html',
})
export class LoginCardComponent implements OnInit, OnDestroy {
    usernameOrEmail: string;
    password: string;
    passwordVisible = false;
    rememberMe: boolean; // As of 2021-07-14, this property is in fact passed to the server but is never used.
    loginPending: boolean;

    // password reset
    passwordResetShown = false;
    passwordResetRequestId: string;
    newPassword: string;
    newPasswordRequestPending = false;

    errorFeedback = {
        // The user forgot to enter a username
        missingUsername: false,
        // The password was left blank
        missingPassword: false,
        // The password or username are invalid
        wrongCredentials: false,
        // The full account expired, e.g. due to cancellation
        regularAccountExpired: '',
        // The test account expired, e.g. after the test period
        testAccountExpired: '',
        // The account was bought for a date in the future. The user can only log in on or after that date.
        becameCustomerAtInTheFuture: '',
        // The autoiXpert customer did not pay his bills. The account was therefore deactivated.
        overduePayments: false,
        // The user account is temporarily deactivated for security reasons (too many failed login attempts)
        tooManyFailedLoginAttempts: '',
    };

    private subscriptions: Subscription[] = [];

    @ViewChild('passwordField', { static: false }) public passwordFieldModelControl: NgModel;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private loggedInUserService: LoggedInUserService,
        private screenTitleService: ScreenTitleService,
        private toastService: ToastService,
        private passwordResetService: PasswordResetService,
        private networkStatusService: NetworkStatusService,
    ) {}

    ngOnInit() {
        // If the user is already logged in, forward him to his last page or the report overview
        this.subscriptions.push(
            this.route.queryParams.subscribe({
                next: (params) => {
                    if (params['passwordResetRequestId']) {
                        // Remove the old user if it exists.
                        this.loggedInUserService.clearUser();

                        this.passwordResetShown = true;
                        this.passwordResetRequestId = params['passwordResetRequestId'];
                    }
                    if (params['user']) {
                        this.usernameOrEmail = params['user'];
                    }
                },
            }),
            this.loggedInUserService.getUser$().subscribe({
                next: (user) => {
                    if (user) {
                        void this.router.navigate([this.loggedInUserService.forwardUrl || '/']);
                    }
                },
                error: (error) => {
                    console.error('There was a problem determining if the user was logged in before.', { error });
                },
            }),
        );

        this.screenTitleService.setScreenTitle({ screenTitle: 'Login' });
    }

    public togglePasswordVisibility(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    public async verifyCredentials(): Promise<void> {
        // Remove all error messages as soon as the user tries to authenticate.
        this.resetErrorMessages();

        const email = this.usernameOrEmail;

        if (!email || !this.password) {
            this.toastService.error('Zugangsdaten fehlen', 'Bitte gib deine E-Mail-Adresse und dein Passwort ein.');
            return;
        }

        await this.authenticate({
            email: email,
            password: this.password,
        });
    }

    private async authenticate({ email, password }: { email?: string; password?: string }) {
        // Before trying to authenticate, check internet connection and re-connect if offline. If this were not the case, the user would have to reconnect manually if he was offline before.
        if (!this.networkStatusService.isOnline()) {
            try {
                await this.networkStatusService.detectNetworkStatus();
            } catch (error) {
                this.toastService.offline(
                    'Login offline nicht möglich',
                    'Verbinde dich mit dem Internet und versuche es erneut.',
                );
                return;
            }
        }

        this.loginPending = true;

        let authenticationResponse: AuthenticationResponse;
        try {
            authenticationResponse = await this.authenticationService.tryAuthentication({
                strategy: 'local',
                email,
                password,
            });
        } catch (error) {
            this.loginPending = false;
            switch (error.code) {
                case 'AX_INVALID_LOGIN':
                    this.errorFeedback.wrongCredentials = true;
                    break;
                case 'START_DATE_IN_THE_FUTURE':
                    this.errorFeedback.becameCustomerAtInTheFuture = error.data.becameCustomerAt;
                    break;
                case 'TEAM_ACCOUNT_DEACTIVATED_DUE_TO_LONG_OVERDUE_PAYMENTS':
                    this.errorFeedback.overduePayments = true;
                    this.toastService.error(
                        'Zahlungsverzug - Nächste Schritte',
                        'Dieser Account ist wegen Zahlungsverzug gesperrt. Sobald deine <strong>Überweisung</strong> eingeht, kann der Account wieder freigeschaltet werden.<br><br>' +
                            (error.data?.fastbillInvoiceHistoryUrl
                                ? `In deiner <a href="${error.data.fastbillInvoiceHistoryUrl}" target="_blank" rel="noopener">Rechnungsübersicht</a> findest du die offene Rechnung.<br><br>`
                                : '') +
                            'Wende dich bei Fragen an die <a href="mailto:buchhaltung@autoixpert.de" target="_blank" rel=”noopener”>aX-Buchhaltung</a>.',
                    );
                    break;
                case 'TEAM_ACCOUNT_EXPIRED':
                    if (error.data.isTestAccount) {
                        this.errorFeedback.testAccountExpired = error.data.expirationDate;
                    } else {
                        this.errorFeedback.regularAccountExpired = error.data.expirationDate;
                    }
                    break;

                case 'USER_LOGIN_BLOCKED_TOO_MANY_FAILED_ATTEMPTS':
                    this.errorFeedback.tooManyFailedLoginAttempts = error.data.blockedUntil;
                    this.toastService.error(
                        'Zu viele fehlgeschlagene Anmeldungen',
                        `Dein Account wurde aus Sicherheitsgründen bis zum ${moment(
                            new Date(error.data.blockedUntil),
                        ).format(
                            'DD.MM.YYYY [-] HH:mm [Uhr]',
                        )} gesperrt. Bitte versuche es später erneut oder kontaktiere die aX Hotline.`,
                    );
                    break;
                default:
                    this.toastService.error('Login nicht möglich', 'Bitte kontaktiere die aX Hotline.');
            }
            console.error('AUTHENTICATION_ERROR', { error });
            this.loginPending = false;
            return;
        }
        this.loginPending = false;

        // Mark the user's facebook account as customer if he is aX customer.
        if (authenticationResponse.team.accountStatus === 'paying') {
            fbq('trackCustom', 'isCustomer');
        }

        await this.router.navigate([this.loggedInUserService.forwardUrl || '/']);
    }

    public startForgotPasswordProcess(): void {
        void this.router.navigate(['/Login/PasswortVergessen'], {
            queryParams: {
                user: this.usernameOrEmail,
            },
        });
    }

    //*****************************************************************************
    //  Trim Username and Password
    //****************************************************************************/
    // A surprisingly great amount of users had issues copying a trailing whitespace with their username or password. This trims the two values and solves the issue.
    public trimUsernameOrEmail(): void {
        this.usernameOrEmail = this.usernameOrEmail.trim();
    }

    public trimPassword(): void {
        this.password = this.password.trim();
    }

    public convertUsernameToLowerCase(): void {
        if (!this.usernameOrEmail) return;
        this.usernameOrEmail = this.usernameOrEmail.toLowerCase();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Trim Username and Password
    /////////////////////////////////////////////////////////////////////////////*/

    public resetErrorMessages() {
        for (const i in this.errorFeedback) {
            this.errorFeedback[i] = false;
        }
    }

    //*****************************************************************************
    //  Set new password
    //****************************************************************************/
    public setNewPassword(): void {
        if (this.newPasswordRequestPending) {
            return;
        }

        if (!this.newPassword) {
            this.toastService.error('Bitte gib ein Passwort ein.');
            return;
        }

        this.newPasswordRequestPending = true;
        this.passwordResetService.setNewPassword(this.passwordResetRequestId, this.newPassword).subscribe({
            next: async (response) => {
                // This assignment makes sure that browser password managers see the username as well.
                this.usernameOrEmail = response.email;
                this.toastService.success('Passwort gesetzt');
                await this.authenticate({
                    email: this.usernameOrEmail,
                    password: this.newPassword,
                });
            },
            error: (error) => {
                this.toastService.error(
                    'Neues Passwort konnte nicht gesetzt werden',
                    'Bitte kontaktiere die aX Hotline.',
                );
                console.error('NEW_PASSWORT_NOT_SET', { error });
                this.newPasswordRequestPending = false;
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Set new password
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

declare function fbq(methodName: string, eventName: string, params?: any): void;

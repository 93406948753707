import { Pipe, PipeTransform } from '@angular/core';
import { PaintThicknessScaleRangeDefinition } from '@autoixpert/models/reports/paint-thickness-scale';

/**
 * Returns the value for the paint thickness scale. This is the same result as the paintThicknessRangeLabel
 * pipe but as a value, not string (which includes the inequality signs).
 */
@Pipe({
    name: 'paintThicknessRangeValue',
    pure: false,
})
export class PaintThicknessRangeValuePipe implements PipeTransform {
    transform(rangeDef: PaintThicknessScaleRangeDefinition, index: number): unknown {
        switch (index) {
            case 0:
                return `${rangeDef.maxValue + 1}`;
            case 1:
                return `${rangeDef.minValue}`;
            default:
                return `${rangeDef.minValue - 1}`;
        }
    }
}

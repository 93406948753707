export function formatNumberToGermanLocale(
    number: number,
    {
        decimalPlaces = 2,
        omitDecimalsIfZero = false,
    }: {
        /**
         * You may either specify an exact number of decimal places or a range.
         *
         * Exact number "2":
         * - 123.4576 => 123,46
         * - 123 => 123,00
         *
         * Range "{ min: 0, max: 2 }":
         * - 123.4576 => 123,46
         * - 123.4 => 123,4
         * - 123 => 123
         */
        decimalPlaces?: number | { min: number; max: number };
        /**
         * Cut zeros at the end of the number. For example, 1,200 becomes 1,2.
         */
        omitDecimalsIfZero?: boolean;
    } = {},
): string | null | undefined {
    // In case the number is null or undefined, return itself to allow alternative values (`||` operator) next to the function call.
    // Return either explicit null or undefined to satisfy return type signature.
    if (number === undefined) {
        return undefined;
    }
    if (number === null) {
        return null;
    }

    if (typeof number !== 'number') {
        //axLogger.debug(`Tried to convert a non-number to a German locale number. However, we expect a number as input. Input was "${util.inspect(number)}".`);
        // Useful for debugging. Shows the backtrace.
        // throw new Error('Debugging: Invalid input.');
        return `${number}`;
    }

    let formattedNumber: string = number.toLocaleString('de', {
        minimumFractionDigits: typeof decimalPlaces === 'object' ? decimalPlaces.min : decimalPlaces,
        maximumFractionDigits: typeof decimalPlaces === 'object' ? decimalPlaces.max : decimalPlaces,
    });

    if (omitDecimalsIfZero) {
        formattedNumber = formattedNumber.replaceAll(/,?0+$/g, '');
    }

    return formattedNumber;
}

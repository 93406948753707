/**
 * Generate a very light version of the given hex color (7 % transparency), which
 * can be used as a secondary accent color.
 */
export function lightenAccentColor(accentColorHexString: string, transparency: number = 7): string {
    // Convert transparency percentage to a 2-character hex string
    const transparencyValueAsHex = Math.round((transparency / 100) * 255)
        .toString(16)
        .padStart(2, '0');

    const primaryColorWithTransparency = `${accentColorHexString}${transparencyValueAsHex}`;

    return removeAlphaPreserveColor(primaryColorWithTransparency);
}

/**
 * Given a hex color with transparency, removes the alpha channel (transparency) but keeps the perceived color.
 * By default, it assumes the transparent color was placed on top of a white background. You can change that
 * with the second parameter `background`.
 */
function removeAlphaPreserveColor(hexColor, background = '#FFFFFF') {
    // Parse the original hex color and alpha
    if (hexColor.length === 9) {
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        const a = parseInt(hexColor.slice(7, 9), 16) / 255; // Alpha (0-1)

        // Parse the background color
        const bgR = parseInt(background.slice(1, 3), 16);
        const bgG = parseInt(background.slice(3, 5), 16);
        const bgB = parseInt(background.slice(5, 7), 16);

        // Blend the colors
        const newR = Math.round(r * a + bgR * (1 - a));
        const newG = Math.round(g * a + bgG * (1 - a));
        const newB = Math.round(b * a + bgB * (1 - a));

        // Return the resulting hex color
        return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
    }
    return hexColor; // Return unchanged if no alpha channel
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { getOutgoingMessageStatus } from '@autoixpert/lib/outgoing-messages/get-outgoing-message-status';
import { OutgoingMessage, OutgoingMessageStatus } from '@autoixpert/models/outgoing-message';
import { sentEmailItemAnimation } from '../../animations/sent-email-item.animation';
import { OutgoingMessageService } from '../../services/outgoing-message.service';

@Component({
    selector: 'outgoing-message',
    templateUrl: './outgoing-message.component.html',
    styleUrls: ['./outgoing-message.component.scss'],
    animations: [sentEmailItemAnimation()],
})
export class OutgoingMessageComponent implements OnInit, OnDestroy {
    constructor(private outgoingMessageService: OutgoingMessageService) {}

    @Input() message: OutgoingMessage;

    @Output() sendOutgoingMessage = new EventEmitter<OutgoingMessage>();
    @Output() displayOutgoingMessage = new EventEmitter<OutgoingMessage>();
    @Output() deleteOutgoingMessage = new EventEmitter<OutgoingMessage>();
    @Output() editAsNew = new EventEmitter<OutgoingMessage>();

    ngOnInit() {
        if (this.message && this.message._id) {
            this.outgoingMessageService.joinUpdateChannel(this.message._id);

            this.outgoingMessageService.patchedInLocalDatabase$.subscribe((patchedMessage) => {
                if (patchedMessage.patchedRecord._id === this.message._id) {
                    this.message = patchedMessage.patchedRecord;
                }
            });
        }
    }

    protected getOutgoingMessageStatus(outgoingMessage: OutgoingMessage): OutgoingMessageStatus {
        return getOutgoingMessageStatus(outgoingMessage);
    }

    protected getOutgoingMessageStatusIcon(outgoingMessage: OutgoingMessage): string {
        const status = this.getOutgoingMessageStatus(outgoingMessage);
        switch (status) {
            case 'scheduled':
                return 'schedule';

            case 'sent':
                return 'schedule';

            case 'failed':
                return 'error';
        }

        return undefined;
    }

    protected getOutgoingMessageStatusTooltip(outgoingMessage: OutgoingMessage): string {
        const status = this.getOutgoingMessageStatus(outgoingMessage);
        switch (status) {
            case 'draft': {
                return `Entwurf vom ${DateTime.fromISO(outgoingMessage.createdAt).toFormat("dd.MM.yyyy 'um' HH:mm:ss")}.`;
            }
            case 'scheduled': {
                return `E-Mail wird am ${DateTime.fromISO(outgoingMessage.scheduledAt).toFormat("dd.MM.yyyy 'um' HH:mm:ss")} gesendet.`;
            }
            case 'sent': {
                return `E-Mail wird gerade versendet…`;
            }
            case 'delivered': {
                return `E-Mail wurde am ${DateTime.fromISO(outgoingMessage.deliveredAt).toFormat("dd.MM.yyyy 'um' HH:mm:ss")} zugestellt.`;
            }
            case 'read': {
                return `E-Mail wurde am ${DateTime.fromISO(outgoingMessage.readAt).toFormat("dd.MM.yyyy 'um' HH:mm:ss")} vom Empfänger gelesen.`;
            }
            case 'failed': {
                return `E-Mail Versand fehlgeschlagen am ${DateTime.fromISO(outgoingMessage.failedAt).toFormat("dd.MM.yyyy 'um' HH:mm:ss")}: ${outgoingMessage.error?.message}.`;
            }
        }
    }

    protected getOutgoingMessageStatusColor(outgoingMessage: OutgoingMessage): string {
        const status = this.getOutgoingMessageStatus(outgoingMessage);
        switch (status) {
            case 'draft':
                return 'var(--font-medium-grey)';

            case 'scheduled':
                return 'var(--warning-color)';

            case 'sent':
                return 'var(--primary-color)';

            case 'delivered':
                return 'var(--success-color)';

            case 'read':
                return 'var(--success-color)';

            case 'failed':
                return 'var(--error-color)';
        }
    }

    ngOnDestroy() {
        if (this.message && this.message._id) {
            this.outgoingMessageService
                .leaveUpdateChannel(this.message._id)
                .catch(() =>
                    console.warn(
                        `Leaving the update channel "outgoingMessages/${this.message._id}" failed. That's optional, so fail silently.`,
                    ),
                );
        }
    }
}

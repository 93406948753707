import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LegacyFloatLabelType as FloatLabelType } from '@angular/material/legacy-form-field';
import moment from 'moment';
import { Moment } from 'moment';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { IsoDate } from '@autoixpert/lib/date/iso-date.types';
import { deviceHasSmallScreen } from '@autoixpert/lib/device-detection/device-has-small-screen';
import { AxDateAdapter } from './ax-date-adapter';
import { AX_MOMENT_DATE_FORMATS } from './ax-moment-date-formats';

@Component({
    selector: 'date-input',
    templateUrl: 'date-input.component.html',
    styleUrls: ['date-input.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: AxDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: AX_MOMENT_DATE_FORMATS,
        },
    ],
})
export class DateInputComponent implements OnInit {
    // The date string coming from the parent component. This is passed to the input's datepicker.
    public dateString = '';
    public isTablet: boolean = false;
    public _date: moment.Moment;
    public isDateInvalid: boolean;

    ngOnInit() {
        this.isTablet = deviceHasSmallScreen();
    }

    @ViewChild(MatDatepicker) matDatepicker: MatDatepicker<moment.Moment>;

    @Output() focus: EventEmitter<FocusEvent> = new EventEmitter();
    @Output() blur: EventEmitter<void> = new EventEmitter();

    @Input() set date(dateString) {
        this.dateString = dateString;
        if (dateString) {
            this._date = moment(dateString);
            this.startAt = moment(dateString);
        } else {
            this._date = null;
            this.startAt = undefined;
        }
        // Assume that an external input is always valid.
        this.isDateInvalid = false;
    }

    @Output() dateChange: EventEmitter<IsoDate> = new EventEmitter();
    @Input() placeholder: string = 'Datum';
    @Input() floatPlaceholder: FloatLabelType = 'auto';
    @Input() warning: string = '';
    @Input() error: string = '';
    @Input() autofocus: boolean = false;
    @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
    @Input() startAt: Moment;
    /**
     * Disable the inline icon in case you're displaying a datepicker below the input already.
     */
    @Input() showDatepickerIcon: boolean = true;

    @Input() lightMode: boolean = false;
    @Input() disabled: boolean = false;
    @Input('ghost-until-hover') ghostUntilHover: boolean = false;

    @ViewChild('input') input?: ElementRef<HTMLInputElement>;

    public emitDateChange(event: MatDatepickerInputEvent<moment.Moment>): void {
        /**
         * A date or an abbreviation like "g" for "gestern"/yesterday was entered. AxDateAdapter converts abbreviations to real
         * Moment objects.
         */
        if (event.value && event.value.isValid()) {
            // make sure there is a proper internal date
            if (!this._date || !this._date.isValid()) {
                this._date = moment().startOf('day');
            }
            // Assign year, month & day in order to now overwrite the time part of the moment
            this._date.year(event.value.year());
            this._date.month(event.value.month());
            this._date.date(event.value.date());

            // Emit an ISO Date, not an ISO Datetime as moment().format() would.
            this.dateChange.emit(toIsoDate(this._date.format('YYYY-MM-DD')));

            this.isDateInvalid = false;
        }
        // Empty value or invalid date.
        else {
            /**
             * If the user removed the field content, the field is empty, not invalid. If the user entered a string
             * from which no Moment date object could be derived, the date must be marked invalid.
             */
            this.isDateInvalid = !!(event.targetElement as HTMLInputElement).value;
            this.dateChange.emit(null);
        }
    }

    public emitBlurEvent(): void {
        this.blur.emit();
    }

    public emitFocusEvent(event: FocusEvent): void {
        this.focus.emit(event);
    }

    public focusInput({ preventScroll }: { preventScroll?: boolean } = {}) {
        this.input?.nativeElement.focus({ preventScroll });
    }
}

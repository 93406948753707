import { Component, EventEmitter, Input, Output } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { Label } from '@autoixpert/models/labels/label';

/**
 * A component displaying the selected labels.
 */
@Component({
    selector: 'label-display',
    templateUrl: './label-display.component.html',
    styleUrls: ['./label-display.component.scss'],
    host: {
        '[class.dark-mode]': 'this.darkMode',
        '[class.small]': 'this.small',
    },
})
export class LabelDisplayComponent {
    @Input() labels: Label[];
    @Input() darkMode: boolean = false;

    /**
     * Small variant where chips have no background and less padding.
     */
    @Input() small: boolean = false;
    @Output() labelRemoved: EventEmitter<void> = new EventEmitter();

    public removeLabel(label: Label) {
        removeFromArray(label, this.labels);
        this.labelRemoved.emit();
    }
}

<div class="selection-bar" [class.dark]="dark">
    <div
        class="selection-bar-item"
        (click)="taxationType = 'neutral'; taxationTypeChange.emit(taxationType)"
        [matTooltip]="
            'Ältere Fahrzeuge, die überwiegend auf dem Privatmarkt gehandelt werden. MwSt. kann nicht ausgewiesen werden.'
        "
        [ngClass]="{
            selected: taxationType === 'neutral',
            disabled: disabled,
        }">
        <span class="selection-percentage">0 %</span>
        <span class="selection-label">Neutral</span>
    </div>
    <div
        class="selection-bar-item"
        (click)="taxationType = 'margin'; taxationTypeChange.emit(taxationType)"
        matTooltip="Mittelalte Fahrzeuge, die überwiegend im Gebrauchtwagenhandel verfügbar sind. Typisches Fahrzeugalter: 3 bis 10 Jahre."
        [ngClass]="{
            selected: taxationType === 'margin',
            disabled: disabled,
        }">
        <span class="selection-percentage">2,4 %</span>
        <span class="selection-label">Differenz</span>
    </div>
    <div
        class="selection-bar-item"
        (click)="taxationType = 'full'; taxationTypeChange.emit(taxationType)"
        [matTooltip]=""
        [ngClass]="{
            selected: taxationType === 'full',
            disabled: disabled,
        }">
        <span class="selection-percentage">19 %</span>
        <span class="selection-label">Regelsatz</span>
    </div>
</div>

import { DateTime } from 'luxon';
import { IsoDate } from '../../lib/date/iso-date.types';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { InternalNote } from '../internal-note';
import { Invoice } from '../invoices/invoice';
import { Label } from '../labels/label';
import { Report } from '../reports/report';

/**
 * Task model
 *
 * A user may create a task to remind themselves of something they need to do or to assign a task to another user.
 * A task may be linked to a report or invoice, (e.g. check payment, review report, etc.).
 *
 * autoiXpert may provide pre-defined tasks for specific use cases (e.g. reviews, residual value offers, etc.).
 * A user may also create their own tasks.
 */
export class Task implements DataTypeBase {
    constructor(template?: Partial<Task>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    _id = generateId();

    title: string;
    description: string;

    // De-normalized data for easy display and search
    associatedReport?: {
        reportId: Report['_id'];
        token: Report['token'];
        formattedClaimant: string;
        make: Report['car']['make'];
        model: Report['car']['model'];
        licensePlate: Report['car']['licensePlate'];
    };
    associatedInvoice?: {
        invoiceId: Invoice['_id'];
        number: Invoice['number'];
        formattedRecipient: string;
        date: Invoice['date'];
    };

    // Due date (to remind the user)
    dueDate: IsoDate;
    estimatedDuration: number; // Unit (minutes for all) or e.g. 2min, 3h, 4d, 5w, 6m, 7y?

    dueTime: string; // HH:mm

    // Completed - one field for the status (to provide efficient filtering) and one for the timestamp (to display it in the UI)
    // TODO: We decided not to allow multiple states (e.g. waiting, ...), only completed as boolean.
    // We could use a string to allow future extensions though.
    isCompleted: boolean = false;
    completedAt: string;

    // Assignee
    assigneeId: string;

    // Rank: 0.0...01 = top, MAX_Value = bottom
    // Rank 0: Rank is not set, e.g. for new tasks inserted when sorted by due date
    rank: number;

    // Comments
    notes: InternalNote[] = [];

    // Subtasks
    subtasks: Subtask[] = [];

    // Labels
    labels: Label[] = [];

    // Creator, update, team
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string;
    teamId: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

export class Subtask {
    constructor(template: Partial<Subtask> = {}) {
        Object.assign(this, template);
    }

    _id = generateId();
    title: string;
    isCompleted: boolean = false;
    completedAt: string;

    createdAt: string = DateTime.now().setZone('UTC').toISO();
    createdBy: string;
}

import { DateTime } from 'luxon';
import { generateId } from '../../../lib/generate-id';

/**
 * A manual calculation is the alternative to DAT / Audatex damage calculations.
 *
 * For a manual calculation, the user does not need to pay the data provider but also
 * has to do the research for part prices himself.
 */
export class ManualCalculation {
    constructor(template: Partial<ManualCalculation> = {}) {
        Object.assign(this, template);
    }

    calculationItems: ManualCalculationItem[] = [new ManualCalculationItem()];
    date: string = DateTime.now().toISO();
}

export class ManualCalculationItem {
    constructor(template: Partial<ManualCalculationItem> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    description: string = null;
    partOrWorkItemNumber: string = null;
    repairCode: RepairCode = null;
    category: RepairCategory = null;
    quantity: number = null;
    unit: 'pieces' | 'workFractions' = null; // A work fraction of 1 is the same as "hours"
    unitPrice: number = null;
    laborType: LaborType = null;
    valueIncreasePercentage: number = null;
}

export type RepairCode =
    | ''
    | 'E-spareParts' // Ersetzen - Ersatzteile
    | 'E-laborCosts' // Ersetzen - Lohn
    | 'E-auxiliaryCosts' // Ersetzen - Nebenkosten
    | 'I' // Instandsetzen
    | 'R' // Reparieren
    | 'A' // Aus- & Einbau
    | 'L' // Lackieren
    | 'M' // Demontiert lackieren
    | 'C' // Spotrepair
    | 'N' // Nebenkosten
    | 'P' // Prüfung (z. B. Fehlerspeicher auslesen / Sichtprüfung)
    | 'S' // Einstellen
    | 'T' // Technische Prüfung
    | 'D' // Demontieren / Montieren (größere Teile wie Stoßfänger demontieren)
    | 'Z' // Zerlegen (größere Teile in Einzelteile zerlegen)
    | 'RIS'; // Risiko

export type RepairCodeLetter =
    | ''
    | 'E' // Ersetzen
    | 'I' // Instandsetzen
    | 'R' // Reparieren
    | 'A' // Aus- & Einbau
    | 'L' // Lackieren
    | 'M' // Demontiert lackieren
    | 'C' // Spotrepair
    | 'N' // Nebenkosten
    | 'P' // Prüfung (z. B. Fehlerspeicher auslesen / Sichtprüfung)
    | 'S' // Einstellen
    | 'T' // Technische Prüfung
    | 'D' // Demontieren / Montieren (größere Teile wie Stoßfänger demontieren)
    | 'Z' // Zerlegen (größere Teile in Einzelteile zerlegen)
    | 'RIS'; // Risiko

export type LaborType =
    | 'mechanics_1'
    | 'mechanics_2'
    | 'mechanics_3'
    | 'electrics_1'
    | 'electrics_2'
    | 'electrics_3'
    | 'carBody_1'
    | 'carBody_2'
    | 'carBody_3'
    | 'carPaint'
    | 'dents';

export type RepairCategory = 'spareParts' | 'laborCosts' | 'auxiliaryCosts' | 'paint';

export type RepairDifficultyLevel = 1 | 2 | 3;

import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject } from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { ReportTypeGerman, translateReportType } from '@autoixpert/lib/report/translate-report-type';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { ChecklistItemStatus } from '@autoixpert/models/report-progress/checklist-item-status';
import { ManuallySetProgressStatus } from '@autoixpert/models/report-progress/manually-set-progress-status';
import { ChecklistItemName } from '@autoixpert/models/report-progress/report-progress-checklist-item-names';
import { ReportProgressConfig } from '@autoixpert/models/report-progress/report-progress-config';
import { ChecklistItem } from '@autoixpert/models/report-progress/report-progress-setting';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndSlideAnimation } from '../../../../../shared/animations/fade-in-and-slide.animation';
import { ApiErrorService } from '../../../../../shared/services/api-error.service';
import { LoggedInUserService } from '../../../../../shared/services/logged-in-user.service';
import { ReportDetailsService } from '../../../../../shared/services/report-details.service';
import { ReportProgressConfigService } from '../../../../../shared/services/report-progress-config.service';
import { TeamService } from '../../../../../shared/services/team.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { translateReportTabName } from '../../report-tabs/translate-report-tab-name';
import { determineChecklistItemStatus } from '../determine-checklist-item-status';
import { getAvailableChecklistItemsByTabName } from '../get-available-checklist-items-by-tab-name';
import { getChecklistItemLabelAndTooltip } from '../get-checklist-item-label-and-tooltip';
import { isChecklistItemRequired } from '../is-checklist-item-required';
import { IS_POSITION_INDICATOR_SHOWN, REPORT_TAB } from '../report-progress-panel.tokens';

@Component({
    selector: 'report-progress-panel',
    templateUrl: 'report-progress-panel.component.html',
    styleUrls: ['report-progress-panel.component.scss'],
    animations: [fadeInAndSlideAnimation()],
})
export class ReportProgressPanelComponent {
    constructor(
        private overlayRef: OverlayRef,
        private report: Report,
        @Inject(REPORT_TAB) private reportTabName: ReportTabName,
        public reportProgressConfig: ReportProgressConfig,
        private reportProgressConfigService: ReportProgressConfigService,
        private apiErrorService: ApiErrorService,
        private loggedInUserService: LoggedInUserService,
        private reportDetailsService: ReportDetailsService,
        private teamService: TeamService,
        private toastService: ToastService,
        @Inject(IS_POSITION_INDICATOR_SHOWN) public isPositionIndicatorShown: boolean,
    ) {}

    private user: User;
    private team: Team;

    // Checklist
    public checklist: ChecklistItem[] = [];

    // Edit Mode
    public editModeActive: boolean;
    public availableChecklistItems: ChecklistItem[];
    private subscriptions: Subscription[] = [];

    ngOnInit() {
        this.refreshChecklist();
        this.user = this.loggedInUserService.getUser();
        this.subscriptions.push(this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)));
    }

    //*****************************************************************************
    //  Translations
    //****************************************************************************/
    public translateReportTabName(): string {
        return translateReportTabName(this.reportTabName, this.report.type);
    }

    public translateReportType(): ReportTypeGerman {
        return translateReportType(this.report.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Translations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Read-only Checklist
    //****************************************************************************/
    public refreshChecklist(): void {
        // Checklist = activated checklist items.
        this.checklist = getAvailableChecklistItemsByTabName({
            reportProgressConfig: this.reportProgressConfig,
            reportType: this.report.type,
            reportTabName: this.reportTabName,
        }).filter((item) => item.activated);
    }

    public getChecklistItemLabelAndTooltip(checklistItemName: ChecklistItemName): { label: string; tooltip: string } {
        return getChecklistItemLabelAndTooltip({
            checklistItemName,
            report: this.report,
        });
    }

    public getChecklistItemStatus(checklistItem: ChecklistItem): ChecklistItemStatus {
        return determineChecklistItemStatus(checklistItem.name, this.report);
    }

    public getCompletedItems(items: ChecklistItem[]): ChecklistItem[] {
        return items.filter((item) => this.getChecklistItemStatus(item) === 'complete');
    }

    /**
     * Checks if an item is required for the current report type, based on the team's report progress config.
     * @param item
     */
    public isItemRequired(item: ChecklistItem): boolean {
        return isChecklistItemRequired({
            item,
            reportProgressConfig: this.reportProgressConfig,
            report: this.report,
        });
    }

    //*****************************************************************************
    //  Manually Set Status
    //****************************************************************************/
    public getManualStatusEntry(checklistItem: ChecklistItem): ManuallySetProgressStatus {
        return this.report.manuallySetProgressStatus?.find((manualStatus) => manualStatus.name === checklistItem.name);
    }

    /**
     * Override the automatic computation of a status.
     *
     * @param checklistItem
     * @param status
     */
    public setStatusManually(checklistItem: ChecklistItem, status: ChecklistItemStatus | 'automatic'): void {
        let matchingEntry: ManuallySetProgressStatus = this.getManualStatusEntry(checklistItem);

        if (!this.report.manuallySetProgressStatus) {
            this.report.manuallySetProgressStatus = [];
        }

        // Setting to automatic means removing the manually set status from the report.
        if (status === 'automatic') {
            removeFromArray(matchingEntry, this.report.manuallySetProgressStatus);
        } else {
            // If none exists, create new entry.
            if (!matchingEntry) {
                matchingEntry = {
                    name: checklistItem.name,
                    status,
                    createdAt: moment().format(),
                    createdBy: this.user._id,
                };
                this.report.manuallySetProgressStatus.push(matchingEntry);
            }
            // If an entry exists, update status and owner.
            else {
                // Don't re-set an existing status.
                if (matchingEntry.status === status) {
                    return;
                }

                matchingEntry.status = status;
                matchingEntry.createdBy = this.user._id;
                matchingEntry.createdAt = moment().format();
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Manually Set Status
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Read-only Checklist
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Edit Mode
    //****************************************************************************/
    public enterEditMode(): void {
        // Limit to admins
        if (!this.loggedInUserIsAdmin()) {
            this.toastService.info(
                'Bearbeitung nur durch Admins',
                'Bitte kontaktiere deinen Administrator, um die Liste zu bearbeiten.',
            );
            return;
        }

        this.setupAvailableChecklistItems();
        this.editModeActive = true;
    }

    public loggedInUserIsAdmin(): boolean {
        return isAdmin(this.user._id, this.team);
    }

    public leaveEditMode(): void {
        this.editModeActive = false;
    }

    private setupAvailableChecklistItems(): void {
        this.availableChecklistItems = getAvailableChecklistItemsByTabName({
            reportProgressConfig: this.reportProgressConfig,
            reportType: this.report.type,
            reportTabName: this.reportTabName,
        });
    }

    public toggleRequirement(availableItem: ChecklistItem): void {
        availableItem.isRequired = !availableItem.isRequired;
    }

    public async saveReportProgressConfig(): Promise<void> {
        // Only save the config for the report type that was changed.
        try {
            await this.reportProgressConfigService.put(this.reportProgressConfig);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Einstellung nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public saveReport(): void {
        try {
            this.reportDetailsService.patch(this.report);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Gutachten nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public getReportTypeGerman(): ReportTypeGerman {
        return translateReportType(this.report.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls & Animation
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public close(): void {
        this.overlayRef.detach();
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                event.stopPropagation();
                this.close();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls & Animation
    /////////////////////////////////////////////////////////////////////////////*/
    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}

import { Subject } from 'rxjs';
import { generateId } from '@autoixpert/lib/generate-id';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { RealtimeEditProcess } from '@autoixpert/models/realtime-editing/realtime-edit-process';
import { RealtimeEditSession } from '@autoixpert/models/realtime-editing/realtime-edit-session';
import { User } from '@autoixpert/models/user/user';
import { FeathersSocketioService } from '../../services/feathers-socketio.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { NetworkStatusService } from '../../services/network-status.service';
import { getDeviceType } from '../device-detection/get-device-type';

/**
 * Some workflows allow displaying other users editing the same record. This service allows keeping track of those co-editors.
 */
export class RealtimeEditSessionServiceBase {
    constructor(params: {
        process: RealtimeEditProcess;
        feathersClient: FeathersSocketioService;
        networkStatusService: NetworkStatusService;
        loggedInUserService: LoggedInUserService;
    }) {
        this.process = params.process;
        this.feathersClient = params.feathersClient;
        this.networkStatusService = params.networkStatusService;
        this.loggedInUserService = params.loggedInUserService;

        // Translate websocket messages to Subjects
        this.registerWebsocketListeners();

        // Auto-reconnect when back online.
        this.rejoinAsEditorWhenBackOnline();

        this.loggedInUserService.getUser$().subscribe((user) => (this.user = user));
    }

    private readonly process: RealtimeEditProcess;
    protected feathersClient: FeathersSocketioService;
    protected networkStatusService: NetworkStatusService;
    protected loggedInUserService: LoggedInUserService;

    private user: User;

    // This record is being edited
    public realtimeEditSession?: RealtimeEditSession;

    // Public events (Subjects)
    public realtimeEditorJoin$: Subject<RealtimeEditSession> = new Subject();
    public realtimeEditorViewSwitch$: Subject<RealtimeEditSession> = new Subject();
    public realtimeEditorLeave$: Subject<RealtimeEditSession> = new Subject();

    //*****************************************************************************
    //  Join & Leave for this user
    //****************************************************************************/
    /**
     * Register as realtime editor for a specific record. This is mostly the record opened in a detail view by the user.
     *
     * This method is called when initially joining or when switching views.
     *
     * @param recordId
     * @param currentView
     */
    public async joinAsEditor({
        recordId,
        currentTab,
    }: {
        recordId: string;
        currentTab: RealtimeEditSession['currentTab'];
    }): Promise<void> {
        try {
            await this.feathersClient.ensureAuthenticatedConnection();
        } catch (error) {
            // Since live editing is an online-only feature, block if offline. Don't notify the user.
            console.log(
                `[realtime edit sessions] Skip joining as a realtime editor since this tab cannot establish an active socket connection.`,
            );
            return;
        }

        // Some component or service must have tried to join as editor again, but the user has already been logged out.
        if (!this.user) {
            console.warn(
                "[realtime edit sessions] The app tried to join as realtime editor. Blocking because there's no user logged-in.",
            );
            return;
        }

        // Session does not yet exist -> Create it
        if (!this.realtimeEditSession) {
            const realtimeEditSession: RealtimeEditSession = {
                _id: generateId(),
                process: this.process,
                deviceType: getDeviceType(),
                recordId,
                currentTab,
                createdBy: this.user._id,
                // Omitted properties like timestamps will be set by the server.
            };
            this.realtimeEditSession = realtimeEditSession;
            try {
                await this.feathersClient.app.service(`realtimeEditSessions`).create(realtimeEditSession);
                console.log(
                    `[realtime edit sessions] Created a new realtime editor session for this tab.`,
                    realtimeEditSession,
                );
            } catch (error) {
                console.warn(
                    `[realtime edit sessions] Creating this tab's realtime edit session failed. Since realtime edit sessions are optional, fail silently.`,
                    { error },
                );
            }
        }
        // Session already exists -> Update
        else {
            const patch: Partial<RealtimeEditSession> = {
                process: this.process,
                deviceType: getDeviceType(),
                recordId,
                currentTab,
                // Omitted properties like timestamps and createdBy will be set by the server.
            };

            // Is update actually changing anything? -> Prevent redundant patches.
            const updateIsEqualToExistingSessionData: boolean =
                patch.process === this.realtimeEditSession.process &&
                patch.deviceType === this.realtimeEditSession.deviceType &&
                patch.recordId === this.realtimeEditSession.recordId &&
                patch.currentTab === this.realtimeEditSession.currentTab;
            if (updateIsEqualToExistingSessionData) {
                return;
            }

            try {
                await this.feathersClient.app
                    .service(`realtimeEditSessions`)
                    .patch(this.realtimeEditSession._id, patch);
                Object.assign(this.realtimeEditSession, patch);
                console.log(
                    `[realtime edit sessions] Updated this tab's existing realtime editor session.`,
                    this.realtimeEditSession,
                );
            } catch (error) {
                // The feathersClient throws an error directly, not a response _containing_ an error.
                // If the resource has been deleted, e.g. because we restarted the backend server (which removes all current live edit sessions), POST the resource instead.
                if (error.code === 'RESOURCE_NOT_FOUND') {
                    this.realtimeEditSession = null;
                    return await this.joinAsEditor({ recordId, currentTab });
                } else {
                    console.warn(
                        `[realtime edit sessions] Updating this tab's realtime edit session failed. Since realtime edit sessions are optional, fail silently.`,
                        { error },
                    );
                }
            }
        }
    }

    /**
     * Ask server about other editors and emit them as events so that they're treated exactly the same as a new editor just coming in.
     * @param recordId
     */
    public async getOtherEditors(recordId: string): Promise<void> {
        try {
            await this.feathersClient.ensureAuthenticatedConnection();
        } catch (error) {
            // Since live editing is an online-only feature, block if offline. Don't notify the user.
            console.log(
                `[realtime edit sessions] Skip listing other realtime editors since this device cannot establish an active socket connection.`,
            );
            return;
        }

        let allEditors: RealtimeEditSession[];

        try {
            allEditors = await this.feathersClient.app.service(`realtimeEditSessions`).find({
                query: {
                    $excludeOwnSocketConnection: true,
                    process: this.process,
                    recordId,
                },
            });
        } catch (error) {
            throw new AxError({
                code: 'GETTING_REALTIME_EDIT_SESSIONS_FAILED',
                message: `The realtime edit sessions could not be loaded from the server.`,
                data: {
                    recordId,
                },
                error,
            });
        }

        for (const editor of allEditors) {
            this.realtimeEditorJoin$.next(editor);
        }
    }

    /**
     * Unregister as realtime editor. This tells other users to remove this user's avatar.
     */
    public async leaveAsEditor(): Promise<void> {
        if (!this.realtimeEditSession) return;

        try {
            await this.feathersClient.ensureAuthenticatedConnection();
        } catch (error) {
            // Since live editing is an online-only feature, block if offline. Don't notify the user.
            console.log(
                `[realtime edit sessions] Skip leaving as a realtime editor since this device does not have an active socket connection.`,
            );
            return;
        }

        const realtimeEditSession = this.realtimeEditSession;

        // We're not editing this record anymore -> Clear record id locally.
        this.realtimeEditSession = undefined;

        // Online? -> Unregister with server. If we're offline, the consuming component is responsible for re-subscribing when back online.
        if (this.networkStatusService.isOnline()) {
            try {
                await this.feathersClient.app.service(`realtimeEditSessions`).remove(realtimeEditSession._id);
            } catch (error) {
                console.warn(
                    `[realtime edit sessions] Removing a realtime edit session failed. Since realtime edit sessions are optional, fail silently.`,
                    { error },
                );
            }
        }
    }

    private rejoinAsEditorWhenBackOnline() {
        this.networkStatusService.networkBackOnline$.subscribe(() => {
            if (!this.realtimeEditSession) return;

            const recordId = this.realtimeEditSession.recordId;
            const currentTab = this.realtimeEditSession.currentTab;

            // Reset so that joinAsEditor may do a fresh post.
            this.realtimeEditSession = undefined;

            this.joinAsEditor({
                recordId,
                currentTab,
            });
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Join & Leave for this user
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events by Other Users
    //****************************************************************************/
    private registerWebsocketListeners() {
        this.feathersClient.app.service(`realtimeEditSessions`).on('created', (response: RealtimeEditSession) => {
            // Check for same workflow
            if (response.process !== this.process) return;

            // Check for same record
            if (response.recordId !== this.realtimeEditSession?.recordId) return;
            this.realtimeEditorJoin$.next(response);
        });
        this.feathersClient.app.service(`realtimeEditSessions`).on('patched', (response: RealtimeEditSession) => {
            // Check for same workflow
            if (response.process !== this.process) return;

            // Check for same record
            if (response.recordId !== this.realtimeEditSession?.recordId) return;
            this.realtimeEditorViewSwitch$.next(response);
        });
        this.feathersClient.app.service(`realtimeEditSessions`).on('removed', (response: RealtimeEditSession) => {
            // Check for same workflow
            if (response.process !== this.process) return;

            // Check for same record
            if (response.recordId !== this.realtimeEditSession?.recordId) return;
            this.realtimeEditorLeave$.next(response);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events by Other Users
    /////////////////////////////////////////////////////////////////////////////*/
}

import moment from 'moment';
import { Task } from '@autoixpert/models/tasks/task';
import { UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';

/**
 * Filters a list of tasks in memory based on the user preferences.
 */
export function filterTasksInMemory(tasks: Task[], userPreferences: UserPreferences, loggedInUser: User): Task[] {
    // Filter tasks
    switch (userPreferences.taskList_quickFilter) {
        case 'onlyOwn':
            return tasks.filter((task) => task.assigneeId === loggedInUser._id);
        case 'assignedUser':
            return tasks.filter((task) => userPreferences.taskList_usersForFilter.includes(task.assigneeId));
        case 'labels':
            return tasks.filter((task) =>
                task.labels.some((label) => userPreferences.taskList_labelsForFilter.includes(label.name)),
            );
        case 'onlyDue':
            return tasks.filter((task) => moment(task.dueDate).isBefore(moment()));
        default:
            return tasks;
    }
}

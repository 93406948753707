import { getCommunicationRecipientsFromReport } from '@autoixpert/lib/involved-parties/get-communication-recipients-from-report';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { CommunicationRecipient } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { getCurrentAndDefaultDocumentOrderForCommunicationRecipient } from './get-current-and-default-document-order-for-communication-recipient';
import { CurrentAndDefaultDocumentOrder, InsertDocumentOptions, insertDocument } from './insert-document';

/**
 * Insert the document metadata object into report.documents and add its reference to the correct position in all involved parties' document orders.
 */
export function addDocumentToReport(
    {
        newDocument,
        report,
        team,
        documentGroup = 'report',
    }: {
        newDocument: DocumentMetadata;
        report: Report;
        team: Team;
        documentGroup: 'report' | 'repairConfirmation';
    },
    options: InsertDocumentOptions = {},
): void {
    /**
     * This might become a custom array in the future when we enable custom involved parties.
     */
    const communicationRecipients: CommunicationRecipient[] = getCommunicationRecipientsFromReport({ report });

    const currentAndDefaultDocumentOrders: CurrentAndDefaultDocumentOrder[] = [];

    /**
     * Create pairs of the current document order and default document order (team preferences) for each involved party.
     *
     * The default order defines after which document a new document should be inserted, even if the user manually rearranged
     * the document order in the report.
     */
    for (const communicationRecipient of communicationRecipients) {
        /**
         * Don't create DocumentOrderItems into the DocumentOrder of other recipients if this is a recipient-specific
         * document letter. Other recipient-specific documents such as an expert statement must still be added to all
         * document orders since an expert statement addressed to the insurance may still be sent to the lawyer and the claimant.
         */
        if (newDocument.type === 'letter' && newDocument.recipientRole !== communicationRecipient.role) {
            continue;
        }
        const currentAndDefaultDocumentOrder = getCurrentAndDefaultDocumentOrderForCommunicationRecipient({
            report,
            team,
            documentGroup,
            communicationRecipient,
        });

        if (currentAndDefaultDocumentOrder) {
            currentAndDefaultDocumentOrders.push(currentAndDefaultDocumentOrder);
        }
    }

    /**
     * If the document cannot be added to any document order, don't add it to report.documents or invoice.documents, either,
     * because that would create a corrupt data structure. We always expect all report.documents to be present in at least
     * one document order.
     *
     * That's required in case a letter document would be added for a recipient which does not exist in a report/invoice.
     * This basically fixes the outcome instead of the root cause but helps keep the underlying structure clean.
     */
    if (currentAndDefaultDocumentOrders.length) {
        insertDocument(
            {
                newDocument,
                documents: report.documents,
                currentAndDefaultDocumentOrders,
            },
            options,
        );
    }
}

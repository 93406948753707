<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container">
        <!--============================================-->
        <!-- Fee Table Generator -->
        <!--============================================-->
        <div id="fee-table-generator-card" class="card card-with-padding dialog-scrollable-area">
            <h2>Tabelle ableiten</h2>

            <div id="template-tables">
                <div
                    class="template-table"
                    *ngFor="let templateTable of templateTables"
                    (click)="selectTemplateTable(templateTable)"
                    [ngClass]="{ selected: selectedTemplateTable === templateTable }"
                    [ngSwitch]="templateTable.name">
                    <img src="/assets/images/logos/bvsk-logo.png" alt="" *ngSwitchCase="'BVSK'" />
                    <img src="/assets/images/logos/huk-logo.png" alt="" *ngSwitchCase="'HUK'" />
                    <img src="/assets/images/logos/vks-logo.png" alt="" *ngSwitchCase="'VKS'" />
                    <img src="/assets/images/logos/cgf-logo.png" alt="" *ngSwitchCase="'CGF'" />
                </div>
            </div>

            <div
                id="template-table-column"
                *ngIf="
                    selectedTemplateTable &&
                    (selectedTemplateTable.name === 'BVSK' ||
                        selectedTemplateTable.name === 'VKS' ||
                        selectedTemplateTable.name === 'CGF')
                ">
                <h3>Spalte</h3>
                <mat-form-field *ngIf="selectedTemplateTable.name === 'BVSK'" class="mat-block">
                    <mat-select [(ngModel)]="selectedBvskColumn" placeholder="Spalte BVSK">
                        <mat-option *ngFor="let column of bvskColumns" [value]="column">{{ column.label }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="selectedTemplateTable.name === 'VKS'" class="mat-block">
                    <mat-select [(ngModel)]="selectedVksColumn" placeholder="Spalte VKS">
                        <mat-option *ngFor="let column of vksColumns" [value]="column">{{ column.label }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="selectedTemplateTable.name === 'CGF'" class="mat-block">
                    <mat-select [(ngModel)]="selectedCgfColumn" placeholder="Spalte CGF">
                        <mat-option *ngFor="let column of cgfColumns" [value]="column">{{ column.label }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!--============================================-->
            <!-- Additions -->
            <!--============================================-->
            <div id="additions">
                <h3>Aufschläge</h3>
                <div id="addition-inputs-container">
                    <mat-form-field id="addition-percent">
                        <input
                            matInput
                            number-input
                            [(number)]="additionPercent"
                            placeholder="Prozentual"
                            [maximumFractionDigits]="2" />
                        <span matSuffix>%</span>
                    </mat-form-field>
                    <currency-input placeholder="Absolut" [(value)]="additionAbsolute"></currency-input>
                </div>
                <div class="checkbox-group parent-of-an-only-child">
                    <mat-checkbox [(ngModel)]="roundValues" matTooltip="Honorare auf volle Euro runden">
                        Werte runden
                    </mat-checkbox>
                </div>
                <div id="addition-example" class="label" [ngClass]="{ shown: calculateAdditions(1000) !== 1000 }">
                    Bsp: 1.000 € wird zu
                    <ng-container *ngIf="roundValues">{{ calculateAdditions(1000) | number: '1.0-0' }} €</ng-container>
                    <ng-container *ngIf="!roundValues">{{ calculateAdditions(1000) | number: '1.2-2' }} €</ng-container>
                </div>
            </div>
            <!--============================================-->
            <!-- END Additions -->
            <!--============================================-->

            <div id="template-tables-buttons-container">
                <button
                    (click)="generateFeeTable()"
                    [ngClass]="{ disabled: !isGeneratingFeeTableAllowed() || feeTableGenerationPending }"
                    [matTooltip]="isGeneratingFeeTableAllowed() ? '' : 'Bitte Tabelle und ggf. Spalte wählen'">
                    Erstellen
                </button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Fee Table Generator -->
        <!--============================================-->
        <!--============================================-->
        <!-- Fee Table -->
        <!--============================================-->
        <div id="custom-fee-table-card" class="card card-with-padding dialog-scrollable-area">
            <mat-icon class="dialog-close-icon" (click)="emitCloseEvent()">close</mat-icon>
            <h2>Eigene Honorartabelle</h2>
            <div id="title-of-fee-table">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Titel"
                        [(ngModel)]="customFeeSet.title"
                        (change)="emitFeeSetChange()" />
                </mat-form-field>
            </div>
            <div id="year-of-fee-table">
                <mat-form-field matTooltip="Datum im Format YYYY, z. B. 2007">
                    <input
                        matInput
                        type="number"
                        placeholder="Jahr"
                        pattern="[0-9]{4}"
                        [(ngModel)]="customFeeSet.year"
                        (change)="emitFeeSetChange()" />
                </mat-form-field>
            </div>
            <div id="report-types-selector">
                <h3 class="no-margin">Gutachten-Typen</h3>
                <span class="label margin-bottom-10">Für welche Gutachten-Typen gilt diese Tabelle?</span>
                <white-button-with-shadow (click)="selectedReportTypes.openDialog()">
                    <selected-report-types
                        #selectedReportTypes
                        (click)="$event.stopPropagation()"
                        [showOnlyDamageReportTypes]="true"
                        [hideShortAssessment]="true"
                        [(selectedReportTypes)]="customFeeSet.availableInReportTypes"
                        (selectedReportTypesChange)="
                            availableInReportTypesChanged(); emitFeeSetChange()
                        "></selected-report-types>
                </white-button-with-shadow>
            </div>
            <div id="fee-table" (change)="emitFeeSetChange()" *ngIf="customFeeSet">
                <h3 id="fee-set-row-heading">Zeilen</h3>
                <div
                    class="fee-set-row"
                    *ngFor="let feeRow of customFeeSet.fees; let feeRowIndex = index"
                    @slideInAndOutVertically>
                    <div class="add-new-cell">
                        <mat-icon
                            class="toolbar-icon"
                            (click)="addRow(feeRowIndex)"
                            matTooltip="Neue Zeile darüber einfügen">
                            add
                        </mat-icon>
                    </div>
                    <div class="lower-limit-cell">
                        <currency-input [(value)]="feeRow.lowerLimit" placeholder="Schadenhöhe"></currency-input>
                    </div>
                    <div class="fee-cell">
                        <currency-input placeholder="Honorar" [(value)]="feeRow.fee"></currency-input>
                    </div>
                    <div class="remove-fee-cell">
                        <mat-icon
                            class="toolbar-icon"
                            (click)="removeRow(feeRow); emitFeeSetChange()"
                            matTooltip="Zeile löschen">
                            delete
                        </mat-icon>
                    </div>
                </div>
            </div>

            <div id="add-row-button-container">
                <button class="flat" (click)="addRow(customFeeSet.fees.length)">+ Neue Zeile</button>
            </div>

            <div class="dialog-buttons-container sticky">
                <button (click)="emitFeeSetChange(); emitCloseEvent()">Übernehmen</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Fee Table -->
        <!--============================================-->
    </div>
</div>

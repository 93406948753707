import { ContactPerson } from '../../models/contacts/contact-person';
import { Report } from '../../models/reports/report';
import { ResidualValueBid } from '../../models/reports/residual-value/residual-value-bid';
import { toGermanDate } from '../ax-luxon';
import { residualValueBidSortFunction } from '../residual-value-bid-sort-function';
import { convertToEuro } from './convert-to-euro';
import { enableLineBreaks } from './enable-line-breaks';
import { ContactPersonGerman, getContactPersonPlaceholderObject } from './get-contact-person-placeholder-object';
import { CurrencyGerman } from './placeholder-values.types';

const bidSourceTranslator: { [key: string]: string } = {
    winvalue: 'WinValue',
    cartv: 'CarTV',
    axResidualValueRequest: 'autoiXpert',
    custom: 'eigene',
};

export function getTemplatePlaceholderValuesBids(bids: ResidualValueBid[], report: Report): ResidualValueBidGerman[] {
    bids.sort(residualValueBidSortFunction);

    return bids
        .map((bid) => {
            let residualValueOfferId: string;

            switch (bid.origin) {
                case 'cartv':
                    residualValueOfferId = report.valuation.cartvResidualValueOffer.offerId;
                    break;
                case 'winvalue':
                    residualValueOfferId = report.valuation.winvalueResidualValueOffer.offerId;
                    break;
            }

            return getTemplatePlaceholderValuesBid(bid, residualValueOfferId);
        })
        .map((bidPlaceholder, index) => {
            bidPlaceholder.Gebot.Nummer = index + 1;
            return bidPlaceholder;
        });
}

export function getTemplatePlaceholderValuesBid(
    bid: ResidualValueBid,
    residualValueOfferId: string,
): ResidualValueBidGerman {
    //*****************************************************************************
    //  Bidder Name & Address
    //****************************************************************************/
    let bidderNameAndAddress: string;
    // Both company name and contact person's name
    if (bid.bidder.address.companyName && bid.bidder.address.contact) {
        bidderNameAndAddress = `${bid.bidder.address.companyName}\n${bid.bidder.address.contact}`;
    }
    // Contact person's name only
    else if (!bid.bidder.address.companyName && bid.bidder.address.contact) {
        bidderNameAndAddress = bid.bidder.address.contact;
    }
    // Company name only
    else {
        bidderNameAndAddress = bid.bidder.address.companyName;
    }

    if (bid.bidder.address.street || bid.bidder.address.postcode || bid.bidder.address.city) {
        bidderNameAndAddress += '\n';

        if (bid.bidder.address.street) {
            bidderNameAndAddress += `${bid.bidder.address.street}\n${bid.bidder.address.postcode}`;
        } else {
            bidderNameAndAddress += `${bid.bidder.address.postcode}`;
        }

        /**
         * CARTV only provides the city name after the bid request has expired because bidders may delete their bids
         * until the bid request expires.
         * It does provide the ZIP code, though.
         */
        if (bid.bidder.address.city) {
            bidderNameAndAddress += ` ${bid.bidder.address.city}`;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Bidder Name & Address
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Contact Data
    //****************************************************************************/
    let contactData = '';
    if (bid.bidder.address.telephone && bid.bidder.address.emailAddress) {
        contactData = `${bid.bidder.address.emailAddress}\n${bid.bidder.address.telephone}`;
    } else if (!bid.bidder.address.telephone && bid.bidder.address.emailAddress) {
        contactData = bid.bidder.address.emailAddress;
    } else if (bid.bidder.address.telephone && !bid.bidder.address.emailAddress) {
        contactData = bid.bidder.address.telephone;
    }

    // When the claimant calls the residual value exchange to sell his car, he needs to give them an offer ID.
    if (bid.origin === 'cartv') {
        contactData = `${contactData}\ncartv.eu/all4you\nCARTV-Nr.: ${residualValueOfferId}\nService ID: ${bid.bidID}`;
    }
    if (bid.origin === 'winvalue') {
        if (bid.linkToSellCar) {
            contactData += `\n${bid.linkToSellCar.replace('https://', '').replace('www.', '')}`;
        }

        contactData += `\nObjekt-Nr.: ${residualValueOfferId}\nGebots-Nr.: ${bid.bidID}`;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Contact Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
    return {
        Bieter: {
            ...getContactPersonPlaceholderObject(
                new ContactPerson({
                    _id: undefined,
                    organizationType: undefined,
                    salutation: null,
                    firstName: null,
                    lastName: bid.bidder.address.contact,
                    organization: bid.bidder.address.companyName,
                    streetAndHouseNumberOrLockbox: bid.bidder.address.street,
                    zip: bid.bidder.address.postcode,
                    city: bid.bidder.address.city,
                    email: bid.bidder.address.emailAddress,
                    phone: bid.bidder.address.telephone,
                    phone2: bid.bidder.address.telephone2,
                    createdBy: null,
                    teamId: null,
                    _documentVersion: 0,
                    _schemaVersion: 2,
                }),
            ),
            NameAdresse: enableLineBreaks(bidderNameAndAddress),
            Kontaktdaten: enableLineBreaks(contactData),
        },
        Gebot: {
            ID: bid.bidID,
            Wert: convertToEuro(bid.bidValue.value),
            KeinGebot: bid.notInterested || bid.bidValue.value === null,
            Bindefrist: bid.bindingDate ? toGermanDate(bid.bindingDate) : '',
            Datum: bid.dateOfBid ? toGermanDate(bid.dateOfBid) : '',
            Quelle: bidSourceTranslator[bid.origin] || 'unbekannt',
            InseratsId: residualValueOfferId,
            Nummer: null,
        },
    };
    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
}

export interface ResidualValueBidGerman {
    ID?: string;
    Bieter: ContactPersonGerman & {
        NameAdresse: string;
        Kontaktdaten: string;
    };
    Gebot: {
        ID: string;
        Wert: CurrencyGerman;
        KeinGebot: boolean;
        Bindefrist: string;
        Datum: string;
        Quelle: string;
        InseratsId: string;
        Nummer: number;
    };
}

import { Component, Input } from '@angular/core';
import { ExternalApiTeamCredits } from '@autoixpert/models/teams/external-api-team-credits';
import { TeamWebhook } from '@autoixpert/models/teams/team-webhook';
import { ApiUser } from '@autoixpert/models/user/api-user';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';

@Component({
    selector: 'external-api-preferences',
    templateUrl: './external-api-preferences.component.html',
    styleUrls: ['./external-api-preferences.component.scss'],
})
export class ExternalApiPreferencesComponent {
    constructor(public userPreferences: UserPreferencesService) {}

    @Input() teamCredits: ExternalApiTeamCredits;
    @Input() apiUsers: ApiUser[];
    @Input() webhooks: TeamWebhook[];

    public scrollIntoView(querySelector: string): void {
        document.querySelector(querySelector).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }
}

<!--============================================-->
<!-- Valuation Result -->
<!--============================================-->
<div id="valuation-result">
    <svg class="background-info-icon" width="570" height="570">
        <circle cx="295" cy="295" r="275" stroke="#4a5266" fill="none" stroke-width="40"></circle>
    </svg>

    <!-- Reference Date-->
    <div
        class="reference-date label"
        datePickerWithInputAnchor
        [disabled]="reportLocked"
        [class.disabled]="reportLocked"
        [(date)]="report.valuation.referenceDate"
        [showTimeInput]="false"
        (dateChange)="saveManualChange()">
        <mat-icon class="small-icon margin-right-5">today</mat-icon>
        Stichtag: {{ report.valuation.referenceDate | moment }}
        <mat-icon *ngIf="!reportLocked" class="toolbar-icon small-icon outlined">edit</mat-icon>
    </div>
    <!--============================================-->
    <!-- Original Price -->
    <!--============================================-->
    <div class="valuation-result-pulse-container" [ngClass]="{ 'result-row-pulse': highlightOriginalPrice }">
        <div class="valuation-result-card card dark-card edit-icon-shown-on-hover">
            <div class="valuation-result-row">
                <mat-icon class="icon">shoppingmode</mat-icon>
                <span class="title semibold text-align-start">Listenpreis</span>
                <div class="value-container">
                    <span
                        [matTooltip]="
                            'Brutto inkl. Sonderausstattungen. Netto: ' +
                            (report.valuation.originalPriceWithEquipmentNet | convertToEuro)
                        "
                        class="semibold">
                        {{ report.valuation.originalPriceWithEquipmentGross | convertToEuro: valuationDecimalPlaces }}
                    </span>
                </div>
            </div>
            <div class="edit-icon" (click)="openEditOriginalPricePopover($event)" #originalPricePopoverAnchor>
                <mat-icon class="small-icon outlined">edit</mat-icon>
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Original Price -->
    <!--============================================-->

    <!--============================================-->
    <!-- Empty State -->
    <!--============================================-->
    <div *ngIf="!areAnyResultsAvailable" id="valuation-result-empty-state">
        <span>
            Starte, indem du eine Bewertung mit DAT oder Audatex, eine Marktwertanalyse oder eine Restwertabfrage
            durchführst. Oder lege einen eigenen Wert fest.
        </span>

        <button
            (click)="openEditVehicleBaseValuePopover($event)"
            id="valuation-result-custom-value"
            #vehicleBaseValuePopoverAnchor>
            Eigener Wert
        </button>
    </div>
    <!--============================================-->
    <!-- END Empty State -->
    <!--============================================-->

    <ng-container *ngIf="areAnyResultsAvailable">
        <!--============================================-->
        <!-- Base Value -->
        <!--============================================-->
        <div
            (click)="scrollIntoView('#select-base-value')"
            class="valuation-result-pulse-container"
            [ngClass]="{ 'result-row-pulse': highlightBaseValue }">
            <div class="valuation-result-card card dark-card edit-icon-shown-on-hover">
                <div class="valuation-result-row">
                    <mat-icon class="icon">directions_car</mat-icon>
                    <div class="valuation-result-title-container text-align-start scroll-to-anchor">
                        <span class="title semibold">Händler-EK</span>
                        <span class="label" *ngIf="baseValueDealerPurchaseSource">
                            <span [matTooltip]="'Quelle: ' + baseValueDealerPurchaseSource">
                                {{ baseValueDealerPurchaseSource }}
                            </span>
                        </span>
                    </div>
                    <div class="value-container column">
                        <span
                            [matTooltip]="
                                report.valuation.baseValueDealerPurchaseNet
                                    ? 'Netto. Quelle: ' +
                                      translateValueProvider(report.valuation.baseValueDealerPurchaseProvider)
                                    : 'Nettowert nicht ermittelt. Einige Anbieter, z. B. Marktwertanalyse oder Audatex-Bewertung stellen nicht immer einen Nettowert bereit. Nutze den Stift rechts, um selbst einen Nettowert zu hinterlegen.'
                            "
                            class="">
                            {{ report.valuation.baseValueDealerPurchaseNet | convertToEuro: valuationDecimalPlaces }}
                        </span>
                        <span
                            [matTooltip]="
                                'Brutto. Quelle: ' +
                                translateValueProvider(report.valuation.baseValueDealerPurchaseProvider)
                            "
                            class="semibold">
                            {{ report.valuation.baseValueDealerPurchaseGross | convertToEuro: valuationDecimalPlaces }}
                        </span>
                    </div>
                </div>
                <div class="valuation-result-row valuation-result-border">
                    <div class="icon-width-spacer"></div>
                    <div class="valuation-result-title-container text-align-start scroll-to-anchor">
                        <span class="title semibold">Händler-VK</span>
                        <div class="label" *ngIf="baseValueDealerSalesSource">
                            <span [matTooltip]="'Quelle: ' + baseValueDealerSalesSource">
                                {{ baseValueDealerSalesSource }}
                            </span>
                        </div>
                    </div>
                    <div class="value-container column">
                        <span
                            [matTooltip]="
                                report.valuation.baseValueDealerSalesNet
                                    ? 'Netto. Quelle: ' +
                                      translateValueProvider(report.valuation.baseValueDealerSalesProvider)
                                    : 'Nettowert nicht ermittelt. Einige Anbieter, z. B. Marktwertanalyse oder Audatex-Bewertung stellen nicht immer einen Nettowert bereit. Nutze den Stift rechts, um selbst einen Nettowert zu hinterlegen.'
                            ">
                            {{ report.valuation.baseValueDealerSalesNet | convertToEuro: valuationDecimalPlaces }}
                        </span>
                        <span
                            [matTooltip]="
                                'Brutto. Quelle: ' +
                                translateValueProvider(report.valuation.baseValueDealerSalesProvider)
                            "
                            class="semibold">
                            {{ report.valuation.baseValueDealerSalesGross | convertToEuro: valuationDecimalPlaces }}
                        </span>
                    </div>
                </div>
                <div class="edit-icon" (click)="openEditVehicleBaseValuePopover($event)" #vehicleBaseValuePopoverAnchor>
                    <mat-icon class="small-icon outlined">edit</mat-icon>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Base Value -->
        <!--============================================-->

        <div class="arrow-down-circle">
            <mat-icon>keyboard_double_arrow_down</mat-icon>
        </div>

        <!--============================================-->
        <!-- Value corrections -->
        <!--============================================-->
        <div
            (click)="scrollIntoView('#apply-value-correction')"
            class="valuation-result-pulse-container"
            [ngClass]="{ 'result-row-pulse': highlightCorrections }">
            <div class="valuation-result-card card dark-card">
                <div
                    class="valuation-result-row"
                    [ngClass]="{ 'color-red': totalCorrectionsGross < 0, 'color-green': totalCorrectionsGross > 0 }">
                    <mat-icon *ngIf="totalCorrectionsGross <= 0" class="icon">arrow_cool_down</mat-icon>
                    <mat-icon *ngIf="totalCorrectionsGross > 0" class="icon">arrow_warm_up</mat-icon>
                    <div
                        matTooltip="Klicke, um den Korrekturen mit einer Kalkulation zu berechnen oder manuell anzugeben."
                        class="valuation-result-title-container text-align-start scroll-to-anchor">
                        <span class="title semibold">Korrekturen</span>
                    </div>
                    <div class="value-container column">
                        <span
                            [matTooltip]="'Netto. Von Auf- und Abwertungen werden automatisch 19% MwSt. abgezogen.'"
                            class="">
                            {{ totalCorrectionsNet | convertToEuro: valuationDecimalPlaces }}
                        </span>
                        <span class="semibold value-colored">
                            {{ totalCorrectionsGross | convertToEuro: valuationDecimalPlaces }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Value corrections -->
        <!--============================================-->

        <div class="arrow-down-circle">
            <mat-icon>keyboard_double_arrow_down</mat-icon>
        </div>

        <!--============================================-->
        <!-- Result Value -->
        <!--============================================-->
        <div
            (click)="scrollIntoView('#select-value-to-print')"
            class="valuation-result-pulse-container"
            [ngClass]="{ 'result-row-pulse': highlightResult }">
            <div class="valuation-result-card card dark-card">
                <div
                    class="valuation-result-row"
                    [ngClass]="{ 'value-inactive': !report.valuation.vehicleValuePurchasePriceActive }">
                    <mat-icon class="icon icon-primary-color">euro_symbol</mat-icon>
                    <div
                        matTooltip="{{ translateVehicleValueType(report.valuation.vehicleValueType) }}{{
                            '\n\n'
                        }}Klicke, um den Wert zu überschrieben."
                        class="valuation-result-title-container text-align-start scroll-to-anchor">
                        <span class="title semibold">Händler-EK</span>
                        <div
                            *ngIf="
                                report.valuation.vehicleValueType &&
                                report.valuation.vehicleValueType !== 'dealerPurchase'
                            "
                            class="label">
                            <mat-icon class="valuation-label-icon outlined">shoppingmode</mat-icon>
                            <span>{{ translateVehicleValueType(report.valuation.vehicleValueType) }}</span>
                        </div>
                    </div>
                    <div class="value-icon">
                        <mat-icon
                            *ngIf="!report.valuation.vehicleValuePurchasePriceActive"
                            (click)="activatePurchasePrice()"
                            [matTooltip]="
                                'Der Händlereinkaufspreis ist ausgeblendet und wird nicht im Gutachten angezeigt. Klicke, um den Wert einzublenden und anzuzeigen.'
                            "
                            class="valuation-result-icon regular-size-icon">
                            visibility_off
                        </mat-icon>
                    </div>
                    <div class="value-container column">
                        <mat-icon
                            *ngIf="
                                report.valuation.vehicleValuePurchasePriceActive &&
                                report.valuation.vehicleValueOverwritten
                            "
                            (click)="activatePurchasePrice()"
                            [matTooltip]="
                                'Der Händlereinkaufspreis wurde manuell geändert und wird nicht automatisch aktualisiert.'
                            "
                            class="value-overwritten-icon small-icon outlined">
                            keep
                        </mat-icon>
                        <span
                            [matTooltip]="
                                report.valuation.vehicleValueNet
                                    ? 'Netto.'
                                    : 'Nettowert nicht ermittelt. Einige Anbieter, z. B. Marktwertanalyse oder Audatex-Bewertung stellen nicht immer einen Nettowert bereit.'
                            ">
                            {{ report.valuation.vehicleValueNet | convertToEuro: valuationDecimalPlaces }}
                        </span>
                        <span [matTooltip]="'Brutto.'" class="semibold">
                            {{ report.valuation.vehicleValueGross | convertToEuro: valuationDecimalPlaces }}
                        </span>
                    </div>
                </div>
                <div
                    class="valuation-result-row valuation-result-border"
                    [ngClass]="{ 'value-inactive': !report.valuation.vehicleValueSalesPriceActive }">
                    <div class="icon-width-spacer"></div>
                    <div
                        matTooltip="{{ translateVehicleValueType(report.valuation.secondVehicleValueType) }}{{
                            '\n\n'
                        }}Klicke, um den Wert zu überschrieben."
                        class="valuation-result-title-container text-align-start scroll-to-anchor">
                        <span class="title semibold">Händler-VK</span>
                        <div
                            *ngIf="
                                report.valuation.secondVehicleValueType &&
                                report.valuation.secondVehicleValueType !== 'dealerSales'
                            "
                            class="label">
                            <mat-icon class="valuation-label-icon outlined">shoppingmode</mat-icon>
                            <span>{{ translateVehicleValueType(report.valuation.secondVehicleValueType) }}</span>
                        </div>
                    </div>
                    <div class="value-icon">
                        <mat-icon
                            *ngIf="!report.valuation.vehicleValueSalesPriceActive"
                            (click)="activateSalesPrice()"
                            [matTooltip]="
                                'Der Händlerverkaufspreis ist ausgeblendet und wird nicht im Gutachten angezeigt. Klicke, um den Wert einzublenden und anzuzeigen.'
                            "
                            class="valuation-result-icon regular-size-icon">
                            visibility_off
                        </mat-icon>
                    </div>
                    <div class="value-container column">
                        <mat-icon
                            *ngIf="
                                report.valuation.vehicleValueSalesPriceActive &&
                                report.valuation.secondVehicleValueOverwritten
                            "
                            (click)="activatePurchasePrice()"
                            [matTooltip]="
                                'Der Händlereinkaufspreis wurde manuell geändert und wird nicht automatisch aktualisiert.'
                            "
                            class="value-overwritten-icon small-icon outlined">
                            keep
                        </mat-icon>
                        <span
                            [matTooltip]="
                                report.valuation.secondVehicleValueNet
                                    ? 'Netto.'
                                    : 'Nettowert nicht ermittelt. Einige Anbieter, z. B. Marktwertanalyse oder Audatex-Bewertung stellen nicht immer einen Nettowert bereit.'
                            ">
                            {{ report.valuation.secondVehicleValueNet | convertToEuro: valuationDecimalPlaces }}
                        </span>
                        <span [matTooltip]="'Brutto.'" class="semibold">
                            {{ report.valuation.secondVehicleValueGross | convertToEuro: valuationDecimalPlaces }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!--============================================-->
        <!-- END Result Value -->
        <!--============================================-->

        <div
            *ngIf="report.valuation.originalPriceWithEquipmentGross && valueRelativeToOriginalPrice"
            class="ratio-circle"
            [matTooltip]="
                report.valuation.vehicleValuePurchasePriceActive
                    ? 'Händlereinkaufspreis (brutto) im Vergleich zum Listenpreis inkl. Ausstattungen (brutto)'
                    : 'Händlerverkaufspreis (brutto) im Vergleich zum Listenpreis inkl. Ausstattungen (brutto)'
            ">
            <svg width="150" height="150">
                <circle
                    cx="75"
                    cy="75"
                    r="70"
                    stroke="#4d5466"
                    fill="none"
                    stroke-width="10"
                    stroke-linecap="round"></circle>
                <circle
                    class="progress-circle"
                    cx="75"
                    cy="75"
                    r="70"
                    [style.stroke-dashoffset]="getRatioCircleValue()"
                    stroke="url(#ax-blue-gradient)"
                    fill="none"
                    stroke-width="10"
                    stroke-linecap="round"></circle>
                <defs>
                    <linearGradient
                        id="ax-blue-gradient"
                        x1="0"
                        y1="70"
                        x2="140"
                        y2="70"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#15A9E8" />
                        <stop offset="1" stop-color="#0ADFFF" />
                    </linearGradient>
                </defs>
            </svg>
            <div class="circle-labels">
                <span class="ratio-label semibold">{{ Math.round(valueRelativeToOriginalPrice * 100) }} %</span>
                <span class="label">des Neupreises</span>
            </div>
        </div>
    </ng-container>
</div>

<!-- Report or Invoice Preview -->
<div
    *ngIf="task.associatedReport || task.associatedInvoice"
    (click)="navigateToLinkedInvoiceOrReport($event)"
    (mousedown)="navigateToLinkedInvoiceOrReportOnMiddleClick($event)"
    class="report-or-invoice-preview"
    matTooltip="Klick: öffnen
Klick mit Mausrad: in neuem Tab öffnen">
    <!-- Report: Car Brand Icon -->
    <ng-container *ngIf="task.associatedReport">
        <div
            class="report-preview-manufacturer-icon-container"
            [style.margin-right.px]="0"
            *ngIf="
                task.associatedReport.make && iconForCarBrandExists(task.associatedReport.make);
                else carMakeIconPlaceholder
            ">
            <img
                [src]="iconFilePathForCarBrand(task.associatedReport.make, 'small')"
                alt=""
                class="car-manufacturer-logo"
                [matTooltip]="task.associatedReport.make + ' ' + task.associatedReport.model" />
        </div>
        <ng-template #carMakeIconPlaceholder>
            <div class="report-thumbnail-container" [style.margin-right.px]="0">
                <mat-icon>assignment</mat-icon>
            </div>
        </ng-template>
    </ng-container>

    <!-- Invoice: Euro Icon-->
    <mat-icon *ngIf="!task.associatedReport && task.associatedInvoice">euro</mat-icon>

    <!-- Display claimant or recipient name and an identifier -->
    <div class="report-or-invoice-texts">
        <div class="report-or-invoice-heading">
            <ng-container
                *ngIf="task.associatedReport?.formattedClaimant || task.associatedInvoice?.formattedRecipient">
                {{ task.associatedReport?.formattedClaimant || task.associatedInvoice?.formattedRecipient }}
            </ng-container>
            <span
                class="label italic"
                *ngIf="!(task.associatedReport?.formattedClaimant || task.associatedInvoice?.formattedRecipient)">
                <!-- Non-breaking space prevents italic font from being cut off by parent with overflow: hidden to create ellipses. -->
                kein Name &nbsp;
            </span>
        </div>
        <div class="label">
            {{ task.associatedReport?.token || task.associatedInvoice?.number }}
        </div>
    </div>
</div>

<!-- Link Report or Invoice Button -->
<div
    linkTaskToRecordAnchor
    [reportId]="task.associatedReport?.reportId"
    [invoiceId]="task.associatedInvoice?.invoiceId"
    (linkChange)="handleLinkedRecordChange(task, $event)">
    <div
        *ngIf="task.associatedReport || task.associatedInvoice"
        class="unlink-associated-record-button"
        matTooltip="Verknüpfung ändern">
        <mat-icon>edit</mat-icon>
    </div>

    <!-- Missing Data Info -->
    <div
        *ngIf="!task.associatedReport && !task.associatedInvoice"
        class="no-associated-record-button"
        matTooltip="Gutachten oder Rechnung mit der Aufgabe verknüpfen.">
        <mat-icon class="small-icon">link</mat-icon>
        &nbsp; Verknüpfung
    </div>
</div>

import { Component, Input } from '@angular/core';
import { getReportTypeAbbreviation } from '@autoixpert/lib/translators/get-report-type-abbreviation';
import { Report } from '@autoixpert/models/reports/report';

@Component({
    selector: 'report-type-chip',
    templateUrl: './report-type-chip.component.html',
    styleUrls: ['./report-type-chip.component.scss'],
})
export class ReportTypeChipComponent {
    @Input() reportType: Report['type'];

    /**
     * Liability is hidden by default (because it's the most common report type). Setting
     * this property to true, also shows the chip for liability reports.
     */
    @Input() showLiability: boolean = false;

    protected readonly getReportTypeAbbreviation = getReportTypeAbbreviation;
}

import { DateTime } from 'luxon';
import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { BadRequest, NotFound, ServerError, UnprocessableEntity } from '../../models/errors/ax-error';
import { LineItem } from '../../models/invoices/line-item';
import { CustomFeeSet } from '../../models/reports/assessors-fee/custom-fee-set';
import { CarEquipment, CarEquipmentPosition } from '../../models/reports/car-identification/car-equipment';
import { Photo, PhotoVersion } from '../../models/reports/damage-description/photo';
import { DiminishedValueCalculationMethod } from '../../models/reports/diminished-value/diminished-value-calculation';
import { CorrectionItem, InvoiceAuditCategory, InvoiceAuditComparisonRow } from '../../models/reports/invoice-audit';
import { PaintThicknessLabelMap } from '../../models/reports/paint-thickness-measurement';
import { defaultPaintThicknessScale } from '../../models/reports/paint-thickness-scale';
import {
    CorrectionItemRepairExecutionType,
    ReplacementValueCorrectionItem,
} from '../../models/reports/replacement-value-corrections/replacement-value-correction-item';
import { Report } from '../../models/reports/report';
import { ResidualValueBid } from '../../models/reports/residual-value/residual-value-bid';
import { Team } from '../../models/teams/team';
import { User } from '../../models/user/user';
import { joinListToHumanReadableString } from '../arrays/join-list-to-human-readable-string';
import { GERMAN_MONTH_AND_YEAR_FORMAT, GERMAN_TIME_FORMAT, makeLuxonDateTime, toGermanDate } from '../ax-luxon';
import { getDatVehicleValueByPriceType } from '../car-valuation/get-dat-vehicle-value-by-price-type';
import { getVehicleValueGross } from '../car-valuation/get-vehicle-value';
import { fullTaxationRate, getVatRateForTaxationType } from '../car-valuation/taxation-rates';
import { carHasAirbags } from '../car/car-has-airbags';
import { carRequiresRegistration } from '../car/car-requires-registration';
import { doesVehicleHaveBatteryElectricEngine, doesVehicleHaveInternalCombustionEngine } from '../car/get-engine-type';
import { iconForCarBrandExists, iconNameForCarBrand } from '../car/icon-for-car-brand-exists';
import { getApplicablePaintMaterialSurcharge } from '../contact-people/garages/get-applicable-paint-material-surcharge';
import { getSelectedGarageFeeSet } from '../contact-people/garages/get-selected-garage-fee-set';
import { getContactPersonFullNameWithOrganization } from '../contact-people/get-contact-person-full-name-with-organization';
import { DiminishedValueMethods } from '../damage-calculation-values/diminished-value-methods';
import { getBaseForFeeCalculation } from '../damage-calculation-values/get-base-for-fee-calculation';
import { replacementValueCorrectionExists } from '../damage-calculation-values/replacement-value-correction/replacement-value-correction-exists';
import { getSelectedFeeTableColumn } from '../fee-set/get-selected-fee-table-column';
import { getActiveFeeSetLineItemDescriptions } from '../fee-set/report-fee-set-line-item';
import { getVatRate } from '../invoices/get-vat-rate-2020';
import { round } from '../numbers/round';
import { getPaintThicknessType } from '../paint-thickness/get-paint-thickness-type';
import { getRentalCarClasses } from '../rental-classes/get-rental-car-classes';
import { getCarOwner } from '../report/get-car-owner';
import { mayCarOwnerDeductTaxes } from '../report/may-car-owner-deduct-taxes';
import { isDamageReport } from '../reports/is-damage-report';
import { isValuationReport } from '../reports/is-valuation-report';
import { residualValueBidSortFunction } from '../residual-value-bid-sort-function';
import {
    calculateAverageDiminishedValueOfActiveMethods,
    diminishedValueMethodNameToFunction,
} from './calculate-average-diminished-value-of-active-methods';
import { convertToEuro } from './convert-to-euro';
import { convertToPercent } from './convert-to-percent';
import { enableLineBreaks } from './enable-line-breaks';
import { formatNumberToGermanLocale } from './format-number-to-german-locale';
import { getAxlesPlaceholderValues } from './get-axles-placeholder-values';
import { getContactPersonPlaceholderObject } from './get-contact-person-placeholder-object';
import {
    getAuxiliaryCostsCalculationItemsPlaceholders,
    getAuxiliaryCostsTotalPlaceholderObject,
    getCarPaintCalculationItemsPlaceholders,
    getDeductionsCalculationItemsPlaceholders,
    getLaborCostsCalculationItemsPlaceholders,
    getLaborCostsDetailsPlaceholderObject,
    getPaintCostsDetailsPlaceholderObject,
    getSparePartsCalculationItemsPlaceholders,
    getSparePartsTotalPlaceholderObject,
} from './get-manual-calculation-placeholder-values';
import { getPhotosPlaceholderValues } from './get-photos-placeholder-values';
import { getTemplatePlaceholderValuesCustomFields } from './get-template-placeholder-values-custom-fields';
import {
    ResidualValueBidGerman,
    getTemplatePlaceholderValuesBids,
} from './get-template-placeholder-values-residual-value-bid';
import { getTirePlaceholderValues, getTiresPlaceholderValues } from './get-tires-placeholder-values';
import { getUserPlaceholderObject } from './get-user-placeholder-object';
import { getVisitsPlaceholderValues } from './get-visits-placeholder-values';
import {
    AdditionalCostPositionGerman,
    AxleGerman,
    CurrencyGerman,
    CustomCarPropertyGerman,
    CustomMarketAnalysisGerman,
    DamageGerman,
    FeeSetPrintTableCell,
    InvoiceAuditCorrectionItemGerman,
    InvoiceAuditRow,
    LeaseReturnItemGerman,
    LeaseReturnSectionGerman,
    PaintThicknessMeasurementGerman,
    PhotoGerman,
    PhotoTwoColumnLayoutGerman,
    PlaceholderValuesReport,
    ReplacementValueCorrection,
    RetrofitItemGerman,
    TireGerman,
    TirePositionGerman,
    VisitGerman,
    WorkFractionUnitGerman,
    WorkFractionUnitPluralGerman,
} from './placeholder-values.types';
import { removeEquipmentDuplicates } from './remove-equipment-duplicates';
import { stripHtml } from './strip-html';
import { Translator } from './translator';
import { formatLicensePlate, getPowerSources, getTaxByTaxationType, isTotalLossDamage } from './utility-functions';

export async function getTemplatePlaceholderValuesReport({
    report,
    user,
    team,
    teamMembers,
    photoVersion,
    carEquipment,
    fieldGroupConfigs,
    customFeeSets,
}: {
    report: Report;
    user: User;
    team: Team;
    teamMembers: User[];
    photoVersion: PhotoVersion;
    carEquipment: CarEquipment;
    fieldGroupConfigs: FieldGroupConfig[];
    customFeeSets: CustomFeeSet[];
}): Promise<PlaceholderValuesReport> {
    //*****************************************************************************
    //  Parameter Validation
    //****************************************************************************/
    if (!report) {
        throw new BadRequest({
            code: 'REPORT_PARAMETER_MISSING',
            message: 'Defining placeholders does not work without a report.',
        });
    }
    if (!user) {
        throw new BadRequest({
            code: 'USER_PARAMETER_MISSING',
            message:
                'Defining placeholders does not work without a current user. Please make sure you are logged in and do not do any forbidden stuff.',
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Parameter Validation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Expert
    //****************************************************************************/
    // The responsible assessor is either explicitly set or the logged-in user.
    let responsibleAssessor: User = user;

    if (report.responsibleAssessor && report.responsibleAssessor !== user._id) {
        responsibleAssessor = teamMembers.find((user) => user._id === report.responsibleAssessor);
    }

    if (!responsibleAssessor) {
        throw new UnprocessableEntity({
            code: 'RESPONSIBLE_ASSESSOR_UNKNOWN',
            message: `Generating template placeholders failed because the responsible assessor is not part of the team.`,
            data: {
                placeholderCategory: 'responsibleAssessor',
                responsibleAssessor: report.responsibleAssessor,
            },
        });
    }

    const assessorPlaceholderObject = getUserPlaceholderObject({
        user: responsibleAssessor,
        team,
        officeLocationId: report.officeLocationId,
        printOrganization: true,
    });
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Expert
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Involved Parties
    //****************************************************************************/
    const claimantContactPersonPlaceholderObject = getContactPersonPlaceholderObject(report.claimant.contactPerson);
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Involved Parties
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Header Data
    //****************************************************************************/
    const reportCompletionDateFormatted: string = toGermanDate(report.completionDate || DateTime.now());
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Header Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Visits
    //****************************************************************************/
    let visitsPlaceholders: VisitGerman[];
    try {
        visitsPlaceholders = getVisitsPlaceholderValues({
            visits: report.visits,
            reportType: report.type,
            user,
            teamMembers,
        });
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders failed. See data for details.`,
            data: {
                placeholderCategory: 'visits',
                visits: report.visits,
            },
            error,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Visits
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Signable Documents
    //****************************************************************************/
    const claimantSignatureDate: string = report.signableDocuments.find(
        (signableDocument) => signableDocument.documentType === 'declarationOfAssignment',
    )?.signatures[0]?.date;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Signable Documents
    /////////////////////////////////////////////////////////////////////////////*/

    // Used for both lease return and Gutachten.Fotos.
    const reportPhotos: Photo[] = report.photos.filter((photo) => photo.versions.report.included);

    //*****************************************************************************
    //  Lease Return
    //****************************************************************************/
    let leaseReturnSectionsGerman: LeaseReturnSectionGerman[] = [];

    let leaseReturnRepairCostsNet: number = 0;
    let leaseReturnRepairCostsNetVatNeutral: number = 0;
    let leaseReturnRepairCostsGross: number = 0;

    let leaseReturnVehicleValueMinusRepairCostsNet: number = 0;
    let leaseReturnVehicleValueMinusRepairCostsVat: number = 0;
    let leaseReturnVehicleValueMinusRepairCostsGross: number = 0;

    let leaseReturnDiminishedValueNet: number = 0;
    let leaseReturnDiminishedValueNetVatNeutral: number = 0;
    let leaseReturnDiminishedValueGross: number = 0;
    // All photos that are associated with a lease return item will later not be printed in the photo attachment.
    const photosInLeaseReturnItems: Photo[] = [];

    if (report.leaseReturn) {
        let itemCounter: number = 1;
        leaseReturnSectionsGerman = report.leaseReturn.sections.map((section) => {
            let sectionRepairCostsNet: number = 0;
            let sectionRepairCostsNetVatNeutral: number = 0;
            let sectionRepairCostsGross: number = 0;
            let sectionDiminishedValueNet: number = 0;
            let sectionDiminishedValueNetVatNeutral: number = 0;
            let sectionDiminishedValueGross: number = 0;

            const populatedItems = section.items.filter(
                (item) => item.comment || item.repairCostsNet || item.aboveAverageWearCostsNet || item.photoIds?.length,
            );

            const leaseReturnSectionGerman: LeaseReturnSectionGerman = {
                Bezeichnung: section.title,
                ReparaturkostenNetto: 0,
                ReparaturkostenNettoMwStNeutral: 0,
                ReparaturkostenMwSt: 0,
                ReparaturkostenBrutto: 0,
                MinderwertNetto: 0,
                MinderwertNettoMwStNeutral: 0,
                MinderwertMwSt: 0,
                MinderwertBrutto: 0,
                Prüfpositionen: populatedItems.map((item) => {
                    // TODO Add later when the frontend is ready.
                    //// If the diminished value is empty but the repair costs are not empty, calculate the diminished value as the time value of the repair costs.
                    //if (item.aboveAverageWearCostsNet == null && item.repairCostsNet != null) {
                    //    item.aboveAverageWearCostsNet = item.repairCostsNet * report.leaseReturn.relativeResidualValue;
                    //}

                    sectionRepairCostsNet += item.repairCostsNet || 0;
                    sectionRepairCostsNetVatNeutral += item.isVatNeutral ? item.repairCostsNet || 0 : 0;
                    sectionRepairCostsGross += (item.isVatNeutral ? item.repairCostsNet : item.repairCostsGross) || 0;
                    sectionDiminishedValueNet += item.aboveAverageWearCostsNet || 0;
                    sectionDiminishedValueNetVatNeutral += item.isVatNeutral ? item.aboveAverageWearCostsNet || 0 : 0;
                    sectionDiminishedValueGross +=
                        (item.isVatNeutral ? item.aboveAverageWearCostsNet : item.aboveAverageWearCostsGross) || 0;

                    const photosInThisItem: Photo[] = [];
                    const photosColumnA: Photo[] = [];
                    const photosColumnB: Photo[] = [];
                    if (item.photoIds?.length) {
                        for (const photoId of item.photoIds) {
                            // Find the photo belonging to this position and find its photo number in the array of report photos. The photo number
                            // is used by getImageModuleOptions() to get the correct buffer when rendering the DOCX file.
                            const photo: Photo = reportPhotos.find((photo) => photo._id === photoId);
                            if (!photo) {
                                throw new NotFound({
                                    code: 'LEASE_RETURN_PHOTO_NOT_FOUND',
                                    message: `The photo referenced in the leasing return position could not be found on the report.`,
                                    data: {
                                        photoId,
                                        leaseReturnItem: item,
                                        leaseReturnSection: section,
                                        reportPhotos: report.photos,
                                    },
                                });
                            }

                            photosInThisItem.push(photo);
                            // Photos associated with a lease return item will not be printed in the photo attachment.
                            photosInLeaseReturnItems.push(photo);
                        }

                        for (let index = 0; index < photosInThisItem.length; index = index + 2) {
                            const firstPhotoInThisRow: Photo = photosInThisItem[index];
                            const secondPhotoInThisRow: Photo = photosInThisItem[index + 1];
                            /**
                             * This right-aligns photos. That way, the photo list in the DOCX document looks cleaner.
                             */
                            if (firstPhotoInThisRow && secondPhotoInThisRow) {
                                photosColumnA.push(firstPhotoInThisRow);
                                photosColumnB.push(secondPhotoInThisRow);
                            } else {
                                photosColumnB.push(firstPhotoInThisRow);
                            }
                        }
                    }

                    return {
                        Nummer: itemCounter++,
                        Bezeichnung: item.title || '',
                        istPflichtfeld: item.isRequired,
                        Kommentar: item.comment || '',
                        ReparaturkostenNetto: item.repairCostsNet ? convertToEuro(item.repairCostsNet) : 0,
                        ReparaturkostenMwSt: item.repairCostsNet ? convertToEuro(item.repairCostsNet * 0.19) : 0,
                        ReparaturkostenBrutto: item.repairCostsGross
                            ? item.isVatNeutral
                                ? convertToEuro(item.repairCostsNet)
                                : convertToEuro(item.repairCostsGross)
                            : 0,
                        MinderwertNetto: item.aboveAverageWearCostsNet
                            ? convertToEuro(item.aboveAverageWearCostsNet)
                            : 0,
                        MinderwertMwSt: item.aboveAverageWearCostsNet
                            ? convertToEuro(item.aboveAverageWearCostsNet * 0.19)
                            : 0,
                        MinderwertBrutto: item.aboveAverageWearCostsNet
                            ? convertToEuro(item.aboveAverageWearCostsNet * 1.19)
                            : 0,
                        ohneMwSt: item.isVatNeutral,
                        Fotos: photosInThisItem.map((photo) => ({
                            FotoZustandsbericht: photo._id,
                        })),
                        FotosPrüfpositionSpalteA: photosColumnA.map((photo) => ({
                            FotoZustandsbericht: photo._id,
                        })),
                        FotosPrüfpositionSpalteB: photosColumnB.map((photo) => ({
                            FotoZustandsbericht: photo._id,
                        })),

                        // Currently not implemented.
                        //SchadenskalkulationQuelle : Translator.calculationProvider(item.provider),
                        //// Internal value. This forces a cache invalidation of the DOCX/PDF document if the damage calculation document changed. That's required if the user did not change any numeric values (labor costs, total net etc.) but
                        //// a label or so that is otherwise not reflected in the damage calculation placeholders.
                        //DocumentHash : item.documentHash,
                    } as LeaseReturnItemGerman;
                }),
            } as LeaseReturnSectionGerman;

            Object.assign<LeaseReturnSectionGerman, Partial<LeaseReturnSectionGerman>>(leaseReturnSectionGerman, {
                ReparaturkostenNetto: sectionRepairCostsNet ? convertToEuro(sectionRepairCostsNet) : 0,
                ReparaturkostenNettoMwStNeutral: sectionRepairCostsNetVatNeutral
                    ? convertToEuro(sectionRepairCostsNetVatNeutral)
                    : 0,
                ReparaturkostenMwSt: sectionRepairCostsNet
                    ? convertToEuro(sectionRepairCostsGross - sectionRepairCostsNet)
                    : 0,
                ReparaturkostenBrutto: sectionRepairCostsGross ? convertToEuro(sectionRepairCostsGross) : 0,
                MinderwertNetto: sectionDiminishedValueNet ? convertToEuro(sectionDiminishedValueNet) : 0,
                MinderwertNettoMwStNeutral: sectionDiminishedValueNetVatNeutral
                    ? convertToEuro(sectionDiminishedValueNetVatNeutral)
                    : 0,
                MinderwertMwSt: sectionDiminishedValueNet
                    ? convertToEuro(sectionDiminishedValueGross - sectionDiminishedValueNet)
                    : 0,
                MinderwertBrutto: sectionDiminishedValueGross ? convertToEuro(sectionDiminishedValueGross) : 0,
            } as Partial<LeaseReturnSectionGerman>);

            leaseReturnRepairCostsNet += sectionRepairCostsNet;
            leaseReturnRepairCostsNetVatNeutral += sectionRepairCostsNetVatNeutral;
            leaseReturnRepairCostsGross += sectionRepairCostsGross;
            leaseReturnDiminishedValueNet += sectionDiminishedValueNet;
            leaseReturnDiminishedValueNetVatNeutral += sectionDiminishedValueNetVatNeutral;
            leaseReturnDiminishedValueGross += sectionDiminishedValueGross;

            return leaseReturnSectionGerman;
        });

        /**
         * The VAT in the value of a used vehicle always depends on the owner, therefore one cannot select a taxation
         * type.
         */
        if (report.valuation.vehicleValueNet != null && leaseReturnRepairCostsNet != null) {
            leaseReturnVehicleValueMinusRepairCostsNet = report.valuation.vehicleValueNet - leaseReturnRepairCostsNet;
            leaseReturnVehicleValueMinusRepairCostsGross = round(
                leaseReturnVehicleValueMinusRepairCostsNet *
                    (1 + getVatRateForTaxationType(report.valuation.taxationType)),
            );
            leaseReturnVehicleValueMinusRepairCostsVat =
                leaseReturnVehicleValueMinusRepairCostsGross - leaseReturnVehicleValueMinusRepairCostsNet;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lease Return
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Valuation
    //****************************************************************************/

    /**
     * Placeholder Bewertung.Fahrzeugwert has different behaviors:
     * - It refers to the vehicleValue which is the default value for all report types (e.g. used-vehicle-check)
     * - In valuation reports, the user may switch between vehicleValue and secondVehicleValue.
     *   - if only the secondVehicleValue (Dealer Sales Price) is active, the main value is mapped to the
     * secondVehicleValue
     *   - in all other cases, the main value is mapped to the vehicleValue
     */
    let valuationMainNet = report.valuation.vehicleValueNet;
    let valuationMainGross = report.valuation.vehicleValueGross;
    if (
        report.type === 'valuation' &&
        !report.valuation.vehicleValuePurchasePriceActive &&
        report.valuation.vehicleValueSalesPriceActive
    ) {
        valuationMainNet = report.valuation.secondVehicleValueNet;
        valuationMainGross = report.valuation.secondVehicleValueGross;
    }
    const valuationDecimalPlaces = report.type === 'valuation' && !team.preferences.valuation_showDecimalPlaces ? 0 : 2;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Valuation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Photos
    //****************************************************************************/
    let photosPlaceholderValues: PhotoGerman[];
    try {
        photosPlaceholderValues = getPhotosPlaceholderValues({
            // Only keep those photos that are not yet linked to a lease return position. That's usually all photos but in a lease return
            // report, the photos might be listed within the diminished value table already, so they should not appear in the photos attachment
            // a second time.
            photos: report.photos.filter((photo) => !photosInLeaseReturnItems.includes(photo)),
            photoVersion,
        });
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders failed. See data for details.`,
            data: {
                placeholderCategory: 'photos',
            },
            error,
        });
    }

    const photosColumnA: PhotoTwoColumnLayoutGerman[] = [];
    const photosColumnB: PhotoTwoColumnLayoutGerman[] = [];
    for (let index = 0; index < photosPlaceholderValues.length; index = index + 2) {
        photosColumnA.push({
            AllgemeineFotobeschreibung: photosPlaceholderValues[index].AllgemeineFotobeschreibung,
            DetaillierteFotobeschreibungen: photosPlaceholderValues[index].DetaillierteFotobeschreibungen,
            Fotonummer: photosPlaceholderValues[index].Fotonummer,
            FotoZweispaltig: photosPlaceholderValues[index].Foto,
        });
        // Column B does not exist in the last line if there are an odd number of photos.
        if (photosPlaceholderValues[index + 1]) {
            photosColumnB.push({
                AllgemeineFotobeschreibung: photosPlaceholderValues[index + 1].AllgemeineFotobeschreibung,
                DetaillierteFotobeschreibungen: photosPlaceholderValues[index + 1].DetaillierteFotobeschreibungen,
                Fotonummer: photosPlaceholderValues[index + 1].Fotonummer,
                FotoZweispaltig: photosPlaceholderValues[index + 1].Foto,
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Photos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Fee Set Table
    //****************************************************************************/
    let feeSetTableColumnA: FeeSetPrintTableCell[] = [];
    let feeSetTableColumnB: FeeSetPrintTableCell[] = [];
    const feeSetTable = getSelectedFeeTableColumn({
        feeCalculation: report.feeCalculation,
        teamPreferences: team.preferences,
        customFeeSets: customFeeSets,
    });

    const feeTableVatRate = report.feeCalculation?.invoiceParameters.vatRate ?? 0.19;

    if (feeSetTable) {
        // If a full column doesn't have any decimal digits, don't print double zeros for simplicity.
        const damageValuesContainDecimals: boolean = feeSetTable.some(([lowerLimit, fee]) => lowerLimit % 1 !== 0);
        const netFeesContainDecimals: boolean = feeSetTable.some(([lowerLimit, fee]) => fee % 1 !== 0);
        const grossFeesContainDecimals: boolean = feeSetTable.some(
            ([lowerLimit, fee]) => (fee * (1 + feeTableVatRate)) % 1 !== 0,
        );
        const feeTableFormatted = feeSetTable.map(([lowerLimit, fee]) => ({
            Schadenhöhe: convertToEuro(lowerLimit, { decimalPlaces: damageValuesContainDecimals ? 2 : 0 }),
            HonorarNetto: convertToEuro(fee, { decimalPlaces: netFeesContainDecimals ? 2 : 0 }),
            HonorarBrutto: convertToEuro(fee * (1 + feeTableVatRate), {
                decimalPlaces: grossFeesContainDecimals ? 2 : 0,
            }),
            Honorar: convertToEuro(fee * (1 + feeTableVatRate)),
        }));

        const indexHalf = feeSetTable.length <= 20 ? feeSetTable.length : Math.ceil(feeTableFormatted.length / 2);
        feeSetTableColumnA = feeTableFormatted.slice(0, indexHalf);
        feeSetTableColumnB = feeTableFormatted.slice(indexHalf);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Fee Set Table
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Damages
    //****************************************************************************/
    const damages: DamageGerman[] = [];
    try {
        report.car.damages?.forEach((damage) => {
            // Skip inactive damages
            if (damage.active !== true) return false;

            damages.push({
                Titel: damage.title || '',
                Beschreibung: damage.description ? damage.description : '',
            });
        });
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders failed. See data for details.`,
            data: {
                placeholderCategory: 'damages',
            },
            error,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damages
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Paint Thickness
    //****************************************************************************/
    const paintThicknessMeasurements: PaintThicknessMeasurementGerman[] = [];
    let omitPaintThicknessMeasurementValues: boolean = false;
    if (paintThicknessMeasurements) {
        const selectedPaintThicknessScale =
            team.preferences.paintThicknessMeasurementScales?.find(
                (scale) => scale._id === report.car.paintThicknessSelectedScaleId,
            ) ?? defaultPaintThicknessScale;

        // Let's filter out any measurement items without measurement value (except for the ones that have a manual
        // type of 'none', which means the part is not painted -> no measurement possible)
        report.car.paintThicknessMeasurements
            ?.filter((measurement) => measurement.values.find((value) => value != null) || measurement.manualType)
            .forEach((measurement) => {
                const measurementGerman: PaintThicknessMeasurementGerman = {
                    Titel: measurement.title || '',
                    Kommentar: measurement.comment || '',
                    Typ: measurement.manualType
                        ? PaintThicknessLabelMap[measurement.manualType]
                        : PaintThicknessLabelMap[
                              getPaintThicknessType(
                                  measurement,
                                  selectedPaintThicknessScale ?? defaultPaintThicknessScale,
                              )
                          ],
                    Werte: measurement.values,
                    Position: measurement.position,
                };

                if (measurement.noMeasurementReason) {
                    measurementGerman.GrundKeineMessung = measurement.noMeasurementReason;
                }

                paintThicknessMeasurements.push(measurementGerman);
            });
        const measurementsContainAtLeastOneValue = paintThicknessMeasurements.some((measurement) =>
            measurement.Werte.find((value) => value != null),
        );
        omitPaintThicknessMeasurementValues = !measurementsContainAtLeastOneValue;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Paint Thickness
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Vehicle Value
    //****************************************************************************/
    const { taxAbsolute: vehicleValueTaxAbsolute } = getTaxByTaxationType(
        valuationMainGross,
        report.valuation.taxationType,
    );
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Vehicle Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Replacement Value & Tax
    //****************************************************************************/
    const replacementValueGross = getVehicleValueGross(report.valuation, 'replacementValue');
    let replacementValueNet: number;
    let replacementValueTaxAbsolute: number;
    let replacementValueTaxPercent: string;
    let replacementValueWithoutTaxForCompanies: number; // With tax for private persons, without for companies that may deduct taxes
    try {
        // See JIRA issue AX-829 for details.
        ({
            netValue: replacementValueNet,
            taxAbsolute: replacementValueTaxAbsolute,
            taxPercentHumanReadable: replacementValueTaxPercent,
        } = getTaxByTaxationType(replacementValueGross, report.valuation.taxationType));

        // Relevant if the company wants to use fictive settlement. Then, the VAT is not paid by the insurance.
        replacementValueWithoutTaxForCompanies = report.claimant.contactPerson.mayDeductTaxes
            ? replacementValueNet
            : replacementValueGross;
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders failed. See data for details.`,
            data: {
                placeholderCategory: 'replacementValue',
            },
            error,
        });
    }

    const replacementValueCorrectionsExist = replacementValueCorrectionExists(report);

    // Vehicle base value (replacement value without corrections)
    // Fallback: set the base value equal to the WBW, if no corrections exist
    const vehicleBaseValue = replacementValueCorrectionsExist
        ? convertToEuro(report.valuation.vehicleBaseValue)
        : replacementValueGross != null
          ? convertToEuro(replacementValueGross)
          : '';

    let decreases: ReplacementValueCorrection[] = null;
    let increases: ReplacementValueCorrection[] = null;
    let correctionsSum = null;
    let correctionsSumPrefix = null;
    let replacementValueCorrectionsContainDecimals = false;

    // Initialize variables for replacement value corrections only, if they exist
    if (replacementValueCorrectionsExist) {
        // Checking if printed values have decimals to determine if we can omit them completely
        replacementValueCorrectionsContainDecimals = [
            ...report.valuation.replacementValueCorrectionConfig.decreases,
            ...report.valuation.replacementValueCorrectionConfig.increases,
        ].some((item) => item.value % 1 !== 0);

        // Filter out empty correction items (no description + value). This is necessary because the first
        // decrease item can't be deleted from the UI. Also makes sure that no unnecessary empty items are printed.
        const isNotEmptyCorrectionItem = (item: ReplacementValueCorrectionItem) => !!item.description || !!item.value;

        decreases = report.valuation.replacementValueCorrectionConfig.decreases
            .filter(isNotEmptyCorrectionItem)
            .map((item): ReplacementValueCorrection => {
                return {
                    // Only if a value is given -> prepend a minus sign for each value decrease
                    Betrag: `${item.value != null ? '- ' : ''}${convertToEuro(item.value, { decimalPlaces: valuationDecimalPlaces })}`,
                    Beschreibung: enableLineBreaks(item.description || 'Keine Beschreibung'),
                    ReparaturArt: translateRepairExecutionType(item.repairExecutionType),
                };
            });

        increases = report.valuation.replacementValueCorrectionConfig.increases
            .filter(isNotEmptyCorrectionItem)
            .map((item): ReplacementValueCorrection => {
                return {
                    // Only if a value is given -> prepend a plus sign for each value increase
                    Betrag: `${item.value != null ? '+ ' : ''}${convertToEuro(item.value, { decimalPlaces: valuationDecimalPlaces })}`,
                    Beschreibung: enableLineBreaks(item.description || 'Keine Beschreibung'),
                    ReparaturArt: translateRepairExecutionType(item.repairExecutionType),
                };
            });

        // Will be handy to have the sum available in the docx partial
        correctionsSum =
            report.valuation.replacementValueCorrectionConfig.totalIncrease -
            report.valuation.replacementValueCorrectionConfig.totalDecrease;

        // Add the plus or minus sign manually to the sum. which is better than the default currency formatting
        // which would only print the minus sign without a space ("+ 10 €" vs. "10 €" and "- 10 €" vs. "-10€").
        correctionsSumPrefix = correctionsSum >= 0 ? '+' : '-';
    }

    //*****************************************************************************
    //  External Replacement Value Sources
    //****************************************************************************/
    const datReplacementValue = getDatVehicleValueByPriceType({
        report,
        vehicleValueType: report.valuation?.datValuation?.vehicleValueType,
        netOrGross: 'gross',
    });

    let audatexReplacementValue: number = report.valuation?.audatexValuation?.replacementValueGross;
    /**
     * If Audatex sent only the net value, the user created a valuation without tax in Qapter. In that case, calculate
     * the gross value with the tax defined in autoiXpert.
     */
    if (!audatexReplacementValue && report.valuation?.audatexValuation?.replacementValueNet) {
        if (report.valuation.taxationType === 'neutral') {
            audatexReplacementValue = report.valuation?.audatexValuation.replacementValueNet;
        } else {
            audatexReplacementValue =
                report.valuation.audatexValuation.replacementValueNet *
                (1 + getVatRateForTaxationType(report.valuation.taxationType));
        }
    }

    let externalReplacementValueCorridorMinimum: number;
    let externalReplacementValueCorridorMaximum: number;
    let externalReplacementValueCorridorAverage: number;

    const potentialReplacementValueMinimums = [];
    const potentialReplacementValueMaximums = [];
    const potentialReplacementValueAverages = [];

    if (report.valuation.datValuation?.dealerSalesPrice && !report.valuation.datValuation.corridorHiddenOnPrintout) {
        potentialReplacementValueMinimums.push(datReplacementValue);
        potentialReplacementValueMaximums.push(datReplacementValue);
        potentialReplacementValueAverages.push(datReplacementValue);
    }
    if (
        report.valuation.audatexValuation?.replacementValueGross &&
        !report.valuation.audatexValuation.corridorHiddenOnPrintout
    ) {
        potentialReplacementValueMinimums.push(audatexReplacementValue);
        potentialReplacementValueMaximums.push(audatexReplacementValue);
        potentialReplacementValueAverages.push(audatexReplacementValue);
    }
    if (
        report.valuation?.valuepilotValuation?.minCorridor != null &&
        !report.valuation.valuepilotValuation.corridorHiddenOnPrintout
    ) {
        potentialReplacementValueMinimums.push(report.valuation.valuepilotValuation.minCorridor);
        potentialReplacementValueMaximums.push(report.valuation.valuepilotValuation.maxCorridor);
        potentialReplacementValueAverages.push(report.valuation.valuepilotValuation.averagePrice);
    }
    if (
        report.valuation?.winvalueValuation?.minCorridor != null &&
        !report.valuation.winvalueValuation.corridorHiddenOnPrintout
    ) {
        potentialReplacementValueMinimums.push(report.valuation.winvalueValuation.minCorridor);
        potentialReplacementValueMaximums.push(report.valuation.winvalueValuation.maxCorridor);
        potentialReplacementValueAverages.push(report.valuation.winvalueValuation.averagePrice);
    }
    if (
        report.valuation?.cartvValuation?.minCorridor != null &&
        !report.valuation.cartvValuation.corridorHiddenOnPrintout
    ) {
        potentialReplacementValueMinimums.push(report.valuation.cartvValuation.minCorridor);
        potentialReplacementValueMaximums.push(report.valuation.cartvValuation.maxCorridor);
        potentialReplacementValueAverages.push(report.valuation.cartvValuation.averagePrice);
    }
    for (const customMarketAnalysis of report.valuation.customMarketAnalyses) {
        // Don't add the values of excluded corridors. Hodor.
        if (customMarketAnalysis.corridorHiddenOnPrintout) continue;

        if (customMarketAnalysis.minCorridor != null) {
            potentialReplacementValueMinimums.push(report.valuation.cartvValuation.minCorridor);
        }
        if (customMarketAnalysis.maxCorridor != null) {
            potentialReplacementValueMaximums.push(report.valuation.cartvValuation.maxCorridor);
        }
        if (customMarketAnalysis.averagePrice != null) {
            potentialReplacementValueAverages.push(report.valuation.cartvValuation.averagePrice);
        }
    }

    // If at least one market analysis was conducted, calculate the corridor values.
    if (potentialReplacementValueMinimums.length) {
        externalReplacementValueCorridorMinimum = Math.min(...potentialReplacementValueMinimums);
        externalReplacementValueCorridorMaximum = Math.max(...potentialReplacementValueMaximums);

        const sumOfAverages: number = potentialReplacementValueAverages.reduce(
            (previousValue, currentValue) => previousValue + (currentValue || 0),
            0,
        );
        externalReplacementValueCorridorAverage = sumOfAverages / potentialReplacementValueAverages.length;
    }

    const customMarketAnalyses: CustomMarketAnalysisGerman[] = report.valuation.customMarketAnalyses.map(
        (customMarketAnalysis) => ({
            Datum: customMarketAnalysis.retrievedAt ? toGermanDate(customMarketAnalysis.retrievedAt) : '',
            Titel: customMarketAnalysis.title || '',
            KorridorAbdrucken: !customMarketAnalysis.corridorHiddenOnPrintout,
            KorridorVon: customMarketAnalysis.minCorridor != null ? convertToEuro(customMarketAnalysis.minCorridor) : 0,
            KorridorBis: customMarketAnalysis.maxCorridor != null ? convertToEuro(customMarketAnalysis.maxCorridor) : 0,
            Durchschnitt:
                customMarketAnalysis.averagePrice != null ? convertToEuro(customMarketAnalysis.averagePrice) : 0,
        }),
    );

    // Only omit decimal places for all numbers in the whole replacement value section, if not a single number has decimal places
    const allPrintedReplacementValues = [
        ...potentialReplacementValueMinimums,
        ...potentialReplacementValueMaximums,
        ...potentialReplacementValueAverages,
        report.valuation.vehicleBaseValue,
        replacementValueGross,
    ];
    const anyReplacementValueContainsDecimals = allPrintedReplacementValues.some(
        (value) => value != null && value % 1 !== 0,
    );
    const omitDecimalsForReplacementValues =
        !replacementValueCorrectionsContainDecimals && !anyReplacementValueContainsDecimals;

    /////////////////////////////////////////////////////////////////////////////*/
    //  END External Replacement Value Sources
    /////////////////////////////////////////////////////////////////////////////*/
    //*****************************************************************************
    //  END Replacement Value & Tax
    //****************************************************************************/

    //*****************************************************************************
    //  Residual Value
    //****************************************************************************/
    let residualValueBids: ResidualValueBid[];
    let placeholdersChosenResidualValueBids: ResidualValueBidGerman[];
    let selectedBids: ResidualValueBid[];

    let numberOfResidualValueBids = 0;
    let numberOfSelectedResidualValueBids = 0;
    let numberOfRegionalBids = 0;
    let numberOfSuperregionalBids = 0;
    let numberOfLocalInvitedBidders = 0;
    let numberOfLocalBiddersWithoutBid = 0;
    let noResidualValueBidsForOffers = false;

    const hasAutoonlineOfferBeenCreated: boolean = !!report.valuation.autoonlineResidualValueOffer?.offerId;
    const hasCartvResidualOfferBeenCreated: boolean = !!report.valuation.cartvResidualValueOffer?.offerId;
    const hasCarcasionResidualOfferBeenCreated: boolean = !!report.valuation.carcasionResidualValueOffer?.offerId;
    const hasWinvalueResidualOfferBeenCreated: boolean = !!report.valuation.winvalueResidualValueOffer?.offerId;
    const hasAutoixpertResidualOfferBeenCreated: boolean =
        !!report.valuation.autoixpertResidualValueOffer?.createdInReportId;

    // Determine number of residual value offers
    let numberOfResidualValueOffers = 0;
    numberOfResidualValueOffers += hasAutoonlineOfferBeenCreated ? 1 : 0;
    numberOfResidualValueOffers += hasCartvResidualOfferBeenCreated ? 1 : 0;
    numberOfResidualValueOffers += hasCarcasionResidualOfferBeenCreated ? 1 : 0;
    numberOfResidualValueOffers += hasWinvalueResidualOfferBeenCreated ? 1 : 0;
    numberOfResidualValueOffers += hasAutoixpertResidualOfferBeenCreated ? 1 : 0;

    // Determine whether the deadlines all residual value offers have passed
    let allResidualValueOffersExpired = true;
    const now = Date.now();
    allResidualValueOffersExpired &&= new Date(report.valuation.autoonlineResidualValueOffer?.readyAt).getTime() < now;
    allResidualValueOffersExpired &&= new Date(report.valuation.cartvResidualValueOffer?.readyAt).getTime() < now;
    allResidualValueOffersExpired &&= new Date(report.valuation.carcasionResidualValueOffer?.readyAt).getTime() < now;
    allResidualValueOffersExpired &&= new Date(report.valuation.winvalueResidualValueOffer?.readyAt).getTime() < now;
    allResidualValueOffersExpired &&=
        new Date(report.valuation.autoixpertResidualValueOffer?.closingDate).getTime() < now;

    try {
        residualValueBids = [
            ...(report.valuation.autoonlineResidualValueOffer?.bids || []),
            ...(report.valuation.cartvResidualValueOffer?.bids || []),
            ...(report.valuation.carcasionResidualValueOffer?.bids || []),
            ...(report.valuation.winvalueResidualValueOffer?.bids || []),
            ...(report.valuation.customResidualValueBids || []),
        ];
        if (report.valuation.useRegionalBidsOnly) {
            residualValueBids = residualValueBids.filter((bid) => bid.regional);
        }
        // Sort bids from highest to lowest
        residualValueBids.sort(residualValueBidSortFunction);
        selectedBids = residualValueBids.filter((bid) => bid.selected);
        // If the user did not select any bid(s) manually, use the top 3 bids. That streamlines the process for the user by using a sensible default.
        // However, if the value is empty, don't print any bids except the selected. That ensures that document building blocks like "no residual value
        // requests were conducted" don't come along with a table with residual value requests.
        if (!selectedBids.length && report.valuation.residualValue != null) {
            selectedBids = residualValueBids.slice(0, 3);
        }

        // Create placeholders for selected bids.
        placeholdersChosenResidualValueBids = getTemplatePlaceholderValuesBids(selectedBids, report);

        const bidsWithBidValue = residualValueBids.filter((bid) => bid.bidValue.value !== null);
        const localResidualValueBids = residualValueBids.filter(
            (bid) => bid.origin === 'axResidualValueRequest' || bid.origin === 'custom',
        );

        numberOfResidualValueBids = bidsWithBidValue.length;
        numberOfRegionalBids = bidsWithBidValue.filter((bid) => bid.regional).length;
        numberOfSuperregionalBids = bidsWithBidValue.filter((bid) => !bid.regional).length;
        numberOfLocalInvitedBidders = localResidualValueBids.length;
        numberOfLocalBiddersWithoutBid = localResidualValueBids.filter((bid) => bid.bidValue.value === null).length;

        // Count only the selected bids, not all bids. This allows the user to be more flexible in his judgement, e.g. filter non-serious offers.
        numberOfSelectedResidualValueBids = placeholdersChosenResidualValueBids.length;
        noResidualValueBidsForOffers =
            numberOfResidualValueOffers > 0 && selectedBids.filter((bid) => bid.bidValue.value !== null).length === 0;
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders failed. See data for details.`,
            data: {
                placeholderCategory: 'residualValue',
            },
            error,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Residual Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Downtime Compensation
    //****************************************************************************/
    let downtime: string;
    let downtimeUnit: 'Kalendertag' | 'Kalendertage' | 'Arbeitstag' | 'Arbeitstage';
    let downtimeType: 'Ausfalldauer (Wiederbeschaffung)' | 'Ausfalldauer (Reparatur)';

    if (isTotalLossDamage(report)) {
        // ReplacementTimeInWorkdays may be a number (eg. 2) or a string (e.g. 2-3)
        // If no replacement time is set, the value is an empty string to enable check for 'isEmpty' in conditions
        downtime = !report.damageCalculation?.replacementTimeInWorkdays
            ? ''
            : `${report.damageCalculation.replacementTimeInWorkdays}`;
        downtimeUnit = downtime === '1' ? 'Kalendertag' : 'Kalendertage';
        downtimeType = 'Ausfalldauer (Wiederbeschaffung)';
    } else {
        // DowntimeInWorkdaysDueToReparation may be a number (eg. 2) or a string (e.g. 2-3)
        // If no downtime is set, the value is an empty string to enable check for 'isEmpty' in conditions
        downtime = !report.damageCalculation?.downtimeInWorkdaysDueToReparation
            ? ''
            : `${report.damageCalculation.downtimeInWorkdaysDueToReparation}`;

        downtimeUnit = downtime == '1' ? 'Arbeitstag' : 'Arbeitstage';
        downtimeType = 'Ausfalldauer (Reparatur)';
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Downtime Compensation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Garage Fees
    //****************************************************************************/
    const garageFeeSet = getSelectedGarageFeeSet(report.garage);

    let transportCosts: CurrencyGerman = 0;
    if (garageFeeSet?.transport?.calculationType !== 'none') {
        if (garageFeeSet?.transport.calculationType === 'fixedPrice' && garageFeeSet.transport.fixedPrice != null) {
            transportCosts = convertToEuro(garageFeeSet.transport.fixedPrice);
        } else if (garageFeeSet?.[garageFeeSet.transport.calculationType] != null) {
            transportCosts = convertToEuro(
                garageFeeSet[garageFeeSet.transport.calculationType].firstLevel * garageFeeSet.transport.timeRequired ||
                    0,
            );
        }
    }

    const applicablePaintMaterialSurcharge = getApplicablePaintMaterialSurcharge(report.car.paintType, garageFeeSet);
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage Fees
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Diminished Value
    //****************************************************************************/
    const methodResults = {
        mfm: 0,
        bvsk: 0,
        dvgt: 0,
        halbgewachs: 0,
        hamburgModel: 0,
        ruhkopf: 0,
        troemner: 0,
    };
    if (report.valuation.diminishedValueCalculation) {
        for (const methodName in methodResults) {
            if (!methodResults.hasOwnProperty(methodName)) continue;
            try {
                methodResults[methodName] = diminishedValueMethodNameToFunction.get(
                    methodName as DiminishedValueCalculationMethod,
                )(report.valuation.diminishedValueCalculation);
            } catch (error) {
                // Do nothing since this usually means that the user forgot an input. He will see a zero for this method.
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Diminished Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Damage Value
    //****************************************************************************/
    const valueIncreaseDecrease =
        (report.valuation.diminishedValue || 0) +
        (report.valuation.technicalDiminishedValue || 0) -
        /**
         * The value increase may already been subtracted in the damage calculation of DAT/Audatex.
         * We then copy that value into `report.valuation.valueIncrease` for display in the UI.
         * We must not subtract it twice (once in the calculation (subtraction included in corrected repair costs) and
         * once in autoiXpert).
         */
        (report.damageCalculation?.repair.increasedValue ? 0 : report.valuation.valueIncrease || 0);
    // Schadenhöhe = The amount the claimant is paid in case of repair = corrected repair costs (repair costs - new-for-old-deductions) + diminished value - value increase
    // Diminished value & value increase are both tax neutral because there is no "real value changing hands" when the insurance pays the claimant these compensations.
    // Source: https://www.iww.de/ue/archiv/reparatur-von-unfallschaeden-die-130-prozent-rechtsprechung-in-allen-details-f19505 (All but value increase.)
    const damageValueNet: number =
        (report.damageCalculation?.repair.correctedRepairCostsNet || 0) + (valueIncreaseDecrease || 0);
    const damageValueGross: number =
        (report.damageCalculation?.repair.correctedRepairCostsGross || 0) + (valueIncreaseDecrease || 0);

    /**
     * Wiederherstellungsaufwand, not to be confused with _Wiederbeschaffungs_aufwand
     * Take the (non-corrected) repair costs instead of the corrected repair costs since this describes the amount of
     * money both the insurance and the claimant need to pay to restore the vehicle. New-for-old/value increase
     * deductions reduce the insurance's payment, but the claimant still has to pay that amount to the garage. This
     * mindset is more "objective" because it compares total investment value with the 130 % rule (claimant benefit)
     * instead of the insurances' obligation to pay. If this were not the case, a previously more damaged vehicle would
     * be more likely to be repaired because the new-for-old deductions were higher.
     */
    const restorationEffortNet: number =
        (report.damageCalculation?.repair.repairCostsNet || 0) +
        (report.valuation.diminishedValue || 0) +
        (report.valuation.technicalDiminishedValue || 0);
    const restorationEffortGross: number =
        (report.damageCalculation?.repair.repairCostsGross || 0) +
        (report.valuation.diminishedValue || 0) +
        (report.valuation.technicalDiminishedValue || 0);

    const correctedRepairCostsMinusValueIncreaseNet: number =
        (report.damageCalculation?.repair.correctedRepairCostsNet || 0) - (report.valuation.valueIncrease || 0);
    /**
     * Wiederbeschaffungsaufwand, not to be confused with _Wiederherstellungs_aufwand
     * This is the value which the insurance needs to afford to compensate the damage for the claimant. Only relevant
     * in case of a total damage or fictive compensation. The insurance has to pay the claimant the replacement value
     * but gets the old damaged vehicle and can sell it for the replacement value. The difference is the replacement
     * effort.
     */
    const replacementEffort =
        replacementValueGross != null && report.valuation?.residualValue != null
            ? convertToEuro(replacementValueGross - report.valuation.residualValue)
            : 0;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Damage Value
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Tires & Axles
    //****************************************************************************/
    let tiresPlaceholderValues: TireGerman[];
    let secondTiresPlaceholderValues: TireGerman[];
    try {
        tiresPlaceholderValues = getTiresPlaceholderValues({ axles: report.car.axles });
        if (report.car.hasSecondTireSet) {
            secondTiresPlaceholderValues = getTiresPlaceholderValues({
                axles: report.car.axles,
                useSecondTireSet: true,
            });
        }
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders for tires failed. See data for details.`,
            data: {
                placeholderCategory: 'tires',
                axles: report.car.axles,
                carShape: report.car.shape,
            },
            error,
        });
    }

    let axlesPlaceholderValues: AxleGerman[];
    try {
        axlesPlaceholderValues = getAxlesPlaceholderValues({ axles: report.car.axles });
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders for axles failed. See data for details.`,
            data: {
                placeholderCategory: 'tires',
                axles: report.car.axles,
                carShape: report.car.shape,
            },
            error,
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Tires & Axles
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Custom Car Properties
    //****************************************************************************/
    /**
     * Print only properties with filled title and value.
     */
    const customCarPropertiesPlaceholderValues: CustomCarPropertyGerman[] = report.car.customProperties
        ?.filter((customProperty) => customProperty.title && customProperty.value)
        .map((customProperty) => ({ Eigenschaft: customProperty.title, Wert: customProperty.value }));

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Car Properties
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Special Costs
    //****************************************************************************/

    // Retrofit items
    const retrofitItemsGerman: RetrofitItemGerman[] = [];
    if (report.damageCalculation?.retrofit?.items?.length) {
        let itemCounter: number = 1;

        for (const item of report.damageCalculation.retrofit.items) {
            // Ignore empty items.
            if (!item.description && !item.costsGross) {
                continue;
            }

            const retroitem: RetrofitItemGerman = {
                _id: item._id,
                Nummer: itemCounter,
                Bezeichnung: item.description,
                KostenBrutto: item.costsGross ? convertToEuro(item.costsGross) : 0,
            };

            retrofitItemsGerman.push(retroitem);
            itemCounter++;
        }
    }

    // Additional costs
    let additionalCostsSum = 0;
    const additionalCostItemsGerman: AdditionalCostPositionGerman[] = [];
    if (report.damageCalculation?.additionalCosts?.length) {
        for (const additionalCostItem of report.damageCalculation.additionalCosts) {
            // No empty or inactive items.
            if (!additionalCostItem.description && !additionalCostItem.costs) continue;
            // if (!additionalCostItem.active) continue;

            additionalCostItemsGerman.push({
                Bezeichnung: additionalCostItem.description || '',
                Kosten: convertToEuro(additionalCostItem.costs),
            });
            additionalCostsSum += additionalCostItem.costs;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Special Costs
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Equipment
    //****************************************************************************/
    // Remove duplicate equipment positions resulting from data errors within the DAT equipment.
    const uniqueCarEquipmentPositions: CarEquipmentPosition[] = removeEquipmentDuplicates(
        carEquipment?.equipmentPositions,
    );

    const seriesEquipment: string[] =
        uniqueCarEquipmentPositions
            .filter((equipment) => equipment.type === 'series')
            .map((equipment) => equipment.description)
            .sort((equipmentA, equipmentB) => equipmentA.localeCompare(equipmentB)) || [];
    const specialEquipment: string[] =
        uniqueCarEquipmentPositions
            .filter((equipment) => equipment.type === 'special')
            .map((equipment) => equipment.description)
            .sort((equipmentA, equipmentB) => equipmentA.localeCompare(equipmentB)) || [];
    const additionalEquipment: string[] =
        uniqueCarEquipmentPositions
            .filter((equipment) => equipment.type === 'additional')
            .map((equipment) => equipment.description)
            .sort((equipmentA, equipmentB) => equipmentA.localeCompare(equipmentB)) || [];

    const seriesEquipmentColumnA: string[] = seriesEquipment.slice(0, Math.ceil(seriesEquipment.length / 2));
    const seriesEquipmentColumnB: string[] = seriesEquipment.slice(Math.ceil(seriesEquipment.length / 2));
    // If there is an odd number of equipment positions, add an empty one to the second column.
    if (seriesEquipment.length % 2 !== 0) {
        seriesEquipmentColumnB.push('');
    }

    const specialEquipmentColumnA: string[] = specialEquipment.slice(0, Math.ceil(specialEquipment.length / 2));
    const specialEquipmentColumnB: string[] = specialEquipment.slice(Math.ceil(specialEquipment.length / 2));
    // If there is an odd number of equipment positions, add an empty one to the second column.
    if (specialEquipment.length % 2 !== 0) {
        specialEquipmentColumnB.push('');
    }

    const additionalEquipmentColumnA: string[] = additionalEquipment.slice(
        0,
        Math.ceil(additionalEquipment.length / 2),
    );
    const additionalEquipmentColumnB: string[] = additionalEquipment.slice(Math.ceil(additionalEquipment.length / 2));
    // If there is an odd number of equipment positions, add an empty one to the second column.
    if (additionalEquipment.length % 2 !== 0) {
        additionalEquipmentColumnB.push('');
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Equipment
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Manual Calculation
    //****************************************************************************/
    const manualCalculation = report.damageCalculation?.repair.manualCalculation;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Manual Calculation
    /////////////////////////////////////////////////////////////////////////////*/

    // Convert Audi to audi, Alpha Romeo to alpha-romeo
    const manufacturerNameForLogoFile = report.car.make && iconNameForCarBrand(report.car.make);

    //*****************************************************************************
    //  Assessor's Fee
    //****************************************************************************/

    let numberOfPhotos = 0;
    if (!report.feeCalculation.usePhotosFixedPrice) {
        numberOfPhotos = report.feeCalculation.useManualNumberOfPhotos
            ? report.feeCalculation.manualNumberOfPhotos
            : report.photos.filter((photo) => photo.versions.report.included).length;
    }

    const photoFees = report.feeCalculation.usePhotosFixedPrice
        ? report.feeCalculation.secondPhotoSet
            ? report.feeCalculation.photosFixedPrice + report.feeCalculation.secondPhotoSetFixedPrice
            : report.feeCalculation.photosFixedPrice
        : report.feeCalculation.secondPhotoSet
          ? report.feeCalculation.pricePerPhoto * numberOfPhotos +
            report.feeCalculation.pricePerSecondPhoto * numberOfPhotos
          : report.feeCalculation.pricePerPhoto * numberOfPhotos;
    const travelExpenses = report.feeCalculation.useTravelExpensesFixedPrice
        ? report.feeCalculation.travelExpensesFixedPrice
        : report.feeCalculation.pricePerKilometer * report.feeCalculation.numberOfKilometers;
    const writingFees = report.feeCalculation.useWritingFeesFixedPrice
        ? report.feeCalculation.useWritingCopyFees
            ? report.feeCalculation.writingFeesFixedPrice + report.feeCalculation.writingCopyFeesFixedPrice
            : report.feeCalculation.writingFeesFixedPrice
        : report.feeCalculation.useWritingCopyFees
          ? report.feeCalculation.pricePerPage * report.feeCalculation.numberOfPages +
            report.feeCalculation.pricePerPageCopy * report.feeCalculation.numberOfPages
          : report.feeCalculation.pricePerPage * report.feeCalculation.numberOfPages;
    const otherFees = report.feeCalculation.invoiceParameters.lineItems
        .filter((customFee) => customFee.active)
        .reduce((previousValue: number, currentValue: LineItem) => {
            if (isNaN(currentValue.unitPrice)) {
                return previousValue;
            }
            return previousValue + currentValue.unitPrice * currentValue.quantity;
        }, 0);

    const otherFeesLineItemsNetto = getActiveFeeSetLineItemDescriptions(
        report.feeCalculation.invoiceParameters.lineItems,
    );
    const otherFeesLineItemsBrutto = getActiveFeeSetLineItemDescriptions(
        report.feeCalculation.invoiceParameters.lineItems,
        { includeVatTax: true },
    );

    const assessorsFeeBase = getBaseForFeeCalculation(report).value;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Assessor's Fee
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
    let data: PlaceholderValuesReport;
    try {
        data = {
            Gutachten: {
                Aktenzeichen: report.token || '',
                Erstellungsdatum: reportCompletionDateFormatted,
                Fertigstellungsdatum: reportCompletionDateFormatted,
                Auftragsdatum: report.orderDate ? toGermanDate(report.orderDate) : '',
                Auftragszeit: report.orderTime
                    ? `${makeLuxonDateTime(report.orderTime).toLocaleString(GERMAN_TIME_FORMAT)} Uhr`
                    : '',
                // The claimant in valuation reports is called "Auftraggeber" instead of "Anspruchsteller".
                Auftraggeber: report.orderPlacedByClaimant
                    ? Translator.claimantDenomination(report.type)
                    : report.actualCustomer || '',
                AuftragDurchAnspruchsteller: report.orderPlacedByClaimant,
                ArtDerAuftragserteilung: report.orderingMethod ? Translator.orderingMethod(report.orderingMethod) : '',
                FahrzeugscheinLagImOriginalVor: report.sourceOfTechnicalData === 'Fahrzeugschein (Original)',
                FahrzeugscheinLagInKopieVor: report.sourceOfTechnicalData === 'Fahrzeugschein (Kopie)',
                QuelleDerTechnischenDaten: report.sourceOfTechnicalData || '',
                VINAmFahrzeugÜberprüft: report.vinWasChecked || false,
                Typ: Translator.reportType(report.type, report.useCostEstimateHeading),
                AnzahlFotos: reportPhotos.length || 0,
                istTotalschaden: isTotalLossDamage(report),
                FotoDeckblatt: report.photos.length || 0,
                FotoWerkstattInformation: report.photos.length || 0,
                FotoRestwertgebot: report.photos.length || 0,
                FotoZustandsbericht: report.photos.length || 0,
                FotoGebrauchtwagencheck: report.photos.length || 0,
                FotoMinderwertprotokoll: report.photos.length || 0,
                Vermittler: report.intermediary?.name || '',
                fiktiveAbrechnung: Translator.tristate(report.isNotionalSettlement),
                istNachtrag: !!report.originalReportId,
                BegründungNachtrag: report.amendmentReason,
                istKostenvoranschlag: report.useCostEstimateHeading,
                istSchadengutachten: isDamageReport(report.type),
                istWertgutachten: isValuationReport(report.type),
                EigeneFelder: {
                    ...(report.customFieldGroups?.length > 0
                        ? await getTemplatePlaceholderValuesCustomFields({
                              report,
                              fieldGroupConfigs,
                          })
                        : {}),
                },
            },
            // Derived from the chosen assessor in the report. In case no report is linked, e.g. for invoice documents, letters etc., this property is still available through
            // the billing placeholders since it's important for many customers' header and footer (IBAN in the footer of a custom invoice, for example).
            Sachverständiger: assessorPlaceholderObject,
            Anspruchsteller: {
                Anwalt: report.lawyer
                    ? {
                          ...getContactPersonPlaceholderObject(report.lawyer.contactPerson),
                          Aktenzeichen: report.lawyer.caseNumber || '',
                      }
                    : null,
                istDurchAnwaltVertreten: report.claimant.representedByLawyer,
                ...claimantContactPersonPlaceholderObject,
                IBAN: report.claimant.contactPerson.iban || '',
                Kennzeichen: formatLicensePlate(report.car.licensePlate),
                // Contains a colon (:) that's printed as a placeholder for the government seals in the font Euro Plate used for displaying license plates in the DOCX files.
                KennzeichenDocx: formatLicensePlate(report.car.licensePlate, {
                    citySeparatorGermany: ':',
                }),
                istFahrzeughalter: report.claimant.isOwner,
                Typ: Translator.claimantDenomination(report.type),
                Aktenzeichen: report.claimant.caseNumber,
            },
            Fahrzeughalter: getContactPersonPlaceholderObject(getCarOwner(report).contactPerson),
            Auftraggeber: getContactPersonPlaceholderObject(report.claimant.contactPerson),
            Verkäufer: report.seller?.contactPerson
                ? {
                      ...getContactPersonPlaceholderObject(report.seller.contactPerson),
                      Kennzeichen: formatLicensePlate(report.car.licensePlate),
                      // Contains a colon (:) that's printed as a placeholder for the government seals in the font Euro Plate used for displaying license plates in the DOCX files.
                      KennzeichenDocx: formatLicensePlate(report.car.licensePlate, {
                          citySeparatorGermany: ':',
                      }),
                  }
                : null,
            Unfallgegner: report.authorOfDamage
                ? {
                      Versicherung: report.insurance
                          ? {
                                Versicherungsnummer: report.authorOfDamage?.insuranceNumber || '',
                                Schadennummer: report.insurance.caseNumber || '',
                                SchadennummerOderVersicherungsnummer:
                                    report.insurance.caseNumber || report.authorOfDamage?.insuranceNumber || '',
                                Selbstbeteiligung: Translator.emptyValueToEmptyString(
                                    report.insurance.deductible,
                                    convertToEuro(report.insurance.deductible),
                                ),
                                ...getContactPersonPlaceholderObject(report.insurance.contactPerson),
                            }
                          : null,
                      Kennzeichen: formatLicensePlate(report.authorOfDamage.licensePlate),
                      // Contains a colon (:) that's printed as a placeholder for the government seals in the font Euro Plate used for displaying license plates in the DOCX files.
                      KennzeichenDocx: formatLicensePlate(report.authorOfDamage.licensePlate, {
                          citySeparatorGermany: ':',
                      }),
                      istFahrzeughalter: report.authorOfDamage.isOwner,
                      ...getContactPersonPlaceholderObject(report.authorOfDamage.contactPerson),
                  }
                : null,
            FahrzeughalterUnfallgegner: report.authorOfDamage
                ? {
                      ...getContactPersonPlaceholderObject(
                          report.authorOfDamage.isOwner
                              ? report.authorOfDamage.contactPerson
                              : report.ownerOfAuthorOfDamagesCar?.contactPerson,
                      ),
                  }
                : null,
            Leasinggeber: report.leaseProvider?.contactPerson
                ? {
                      ...getContactPersonPlaceholderObject(report.leaseProvider.contactPerson),
                      Vertragsnummer: report.leaseProvider.caseNumber || '',
                  }
                : null,
            Werkstatt: report.garage
                ? {
                      ...getContactPersonPlaceholderObject(report.garage.contactPerson),
                      istMarkenwerkstatt: report.garage.contactPerson?.isBrandCertified,
                      Aktenzeichen: report.garage.caseNumber,
                      Kostensätze: {
                          EinheitArbeitswert: (garageFeeSet?.selectedWorkFractionUnit > 1
                              ? `${garageFeeSet.selectedWorkFractionUnit}er AW`
                              : 'Stunde') as WorkFractionUnitGerman,
                          EinheitArbeitswertPlural: (garageFeeSet?.selectedWorkFractionUnit > 1
                              ? `${garageFeeSet.selectedWorkFractionUnit}er AWs`
                              : 'Stunden') as WorkFractionUnitPluralGerman,
                          // Wage per chosen work fraction unit (Arbeitswert = AW)
                          Mechanik1ProArbeitswert: garageFeeSet?.mechanics.firstLevel
                              ? convertToEuro(garageFeeSet.mechanics.firstLevel)
                              : 0,
                          Mechanik2ProArbeitswert: garageFeeSet?.mechanics.secondLevel
                              ? convertToEuro(garageFeeSet.mechanics.secondLevel)
                              : 0,
                          Mechanik3ProArbeitswert: garageFeeSet?.mechanics.thirdLevel
                              ? convertToEuro(garageFeeSet.mechanics.thirdLevel)
                              : 0,
                          Elektrik1ProArbeitswert: garageFeeSet?.electrics.firstLevel
                              ? convertToEuro(garageFeeSet.electrics.firstLevel)
                              : 0,
                          Elektrik2ProArbeitswert: garageFeeSet?.electrics.secondLevel
                              ? convertToEuro(garageFeeSet.electrics.secondLevel)
                              : 0,
                          Elektrik3ProArbeitswert: garageFeeSet?.electrics.thirdLevel
                              ? convertToEuro(garageFeeSet.electrics.thirdLevel)
                              : 0,
                          Karosserie1ProArbeitswert: garageFeeSet?.carBody.firstLevel
                              ? convertToEuro(garageFeeSet.carBody.firstLevel)
                              : 0,
                          Karosserie2ProArbeitswert: garageFeeSet?.carBody.secondLevel
                              ? convertToEuro(garageFeeSet.carBody.secondLevel)
                              : 0,
                          Karosserie3ProArbeitswert: garageFeeSet?.carBody.thirdLevel
                              ? convertToEuro(garageFeeSet.carBody.thirdLevel)
                              : 0,
                          LackProArbeitswert: garageFeeSet?.carPaint.wage
                              ? convertToEuro(garageFeeSet.carPaint.wage)
                              : 0,
                          DellenProArbeitswert: garageFeeSet?.dentsWage ? convertToEuro(garageFeeSet.dentsWage) : 0,
                          // Hourly wages.
                          Mechanik1: garageFeeSet?.mechanics.firstLevel
                              ? convertToEuro(garageFeeSet.mechanics.firstLevel)
                              : 0,
                          Mechanik2: garageFeeSet?.mechanics.secondLevel
                              ? convertToEuro(garageFeeSet.mechanics.secondLevel)
                              : 0,
                          Mechanik3: garageFeeSet?.mechanics.thirdLevel
                              ? convertToEuro(garageFeeSet.mechanics.thirdLevel)
                              : 0,
                          Elektrik1: garageFeeSet?.electrics.firstLevel
                              ? convertToEuro(garageFeeSet.electrics.firstLevel)
                              : 0,
                          Elektrik2: garageFeeSet?.electrics.secondLevel
                              ? convertToEuro(garageFeeSet.electrics.secondLevel)
                              : 0,
                          Elektrik3: garageFeeSet?.electrics.thirdLevel
                              ? convertToEuro(garageFeeSet.electrics.thirdLevel)
                              : 0,
                          Karosserie1: garageFeeSet?.carBody.firstLevel
                              ? convertToEuro(garageFeeSet.carBody.firstLevel)
                              : 0,
                          Karosserie2: garageFeeSet?.carBody.secondLevel
                              ? convertToEuro(garageFeeSet.carBody.secondLevel)
                              : 0,
                          Karosserie3: garageFeeSet?.carBody.thirdLevel
                              ? convertToEuro(garageFeeSet.carBody.thirdLevel)
                              : 0,
                          Lack: garageFeeSet?.carPaint.wage ? convertToEuro(garageFeeSet.carPaint.wage) : 0,
                          Lacksystem: Translator.carPaintSystem(garageFeeSet?.carPaint.paintSystem),
                          Lackmaterial: applicablePaintMaterialSurcharge
                              ? (garageFeeSet?.carPaint.materialSurchargeUnit === 'flatFee'
                                    ? formatNumberToGermanLocale(applicablePaintMaterialSurcharge.value)
                                    : applicablePaintMaterialSurcharge.value) /* We save 35% as 35, not as .35 */ +
                                ` ${Translator.carPaintMaterialSurchargeUnit(
                                    garageFeeSet?.carPaint.materialSurchargeUnit,
                                    garageFeeSet?.carPaint.paintSystem,
                                )}`
                              : '',
                          LackmaterialEinheit: Translator.carPaintMaterialSurchargeUnit(
                              garageFeeSet?.carPaint.materialSurchargeUnit,
                              garageFeeSet?.carPaint.paintSystem,
                          ),
                          Dellen: garageFeeSet?.dentsWage ? convertToEuro(garageFeeSet.dentsWage) : 0,
                          Verbringung: garageFeeSet?.transport?.calculationType !== 'none',
                          VerbringungJaNein: garageFeeSet?.transport?.calculationType === 'none' ? 'Nein' : 'Ja',
                          Verbringungskosten: transportCosts,
                          Verbringungsaufwand: formatNumberToGermanLocale(garageFeeSet?.transport?.timeRequired, {
                              omitDecimalsIfZero: true,
                          }),
                          MaterialzuschlagUPE: garageFeeSet?.sparePartsSurcharge
                              ? convertToPercent(garageFeeSet.sparePartsSurcharge / 100, 2)
                              : '',
                          KleinUndVerbrauchsmaterial: garageFeeSet?.smallPartsSurcharge
                              ? garageFeeSet.smallPartsUnit === 'flatFee'
                                  ? convertToEuro(garageFeeSet.smallPartsSurcharge)
                                  : `${garageFeeSet.smallPartsSurcharge}`
                              : '',
                          KleinUndVerbrauchsmaterialEinheit: garageFeeSet?.smallPartsUnit === 'flatFee' ? '€' : '%',
                      },
                  }
                : null,
            Fahrzeug: {
                VIN: report.car.vin || '',
                Hersteller: report.car.make || '',
                HerstellerLogo: manufacturerNameForLogoFile,
                HerstellerLogoExistiert: iconForCarBrandExists(manufacturerNameForLogoFile),
                HerstellerLogoGroß: manufacturerNameForLogoFile,
                Modell: report.car.model || '',
                Untertyp: report.car.submodel || '',
                Art: report.car.customShapeLabel || Translator.carShape(report.car.shape) || '',
                Karosserie: report.car.customShapeLabel || Translator.carShape(report.car.shape) || '',
                KarosserieMitReifen: report.car.shape || 'sedan',
                Anstosszeichnung: report.car.shape || 'sedan',
                istMotorrad: report.car.shape === 'motorcycle', // simplifies the logic in the docx template
                istAnhänger: report.car.shape === 'trailer' || report.car.shape === 'caravanTrailer', // simplifies the logic in the docx template
                hatVerbrennungsmotor: doesVehicleHaveInternalCombustionEngine(report) || false,
                istElektrofahrzeug: doesVehicleHaveBatteryElectricEngine(report) || false,
                Schäden: damages,
                Schadenbeschreibung: report.car.damageDescription ? report.car.damageDescription : '',
                Schlüsselnummer: report.car.kbaCode || '', // Equals "KBA-Nummer"
                Antriebsart: getPowerSources(report.car) || '', // Results in "Diesel" and "Diesel & Elektro" and other enumerations.
                Getriebe: report.car.gearboxModelName || '',
                Leistung: Translator.emptyValueToEmptyString(
                    report.car.performanceKW,
                    `${Number(report.car.performanceKW).toLocaleString('de-de', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                    })} kW`,
                ), // May be 0 in case of a trailer, so explicitly check for null.
                AnzahlZylinder: report.car.numberOfCylinders != null ? report.car.numberOfCylinders : '', // May be 0 in case of a trailer, so explicitly check for null.
                Motorbauart: report.car.engineConfiguration || '',
                Hubraum: Translator.emptyValueToEmptyString(
                    report.car.engineDisplacement,
                    `${formatNumberToGermanLocale(report.car.engineDisplacement, { decimalPlaces: 0 })} ccm`,
                ), // May be 0 in case of a trailer, so explicitly check for null.
                Leermasse: Translator.emptyValueToEmptyString(
                    report.car.unloadedWeight,
                    `${formatNumberToGermanLocale(report.car.unloadedWeight, { decimalPlaces: 0 })} kg`,
                ),
                ZulässigeGesamtmasse: Translator.emptyValueToEmptyString(
                    report.car.maximumTotalWeight,
                    `${formatNumberToGermanLocale(report.car.maximumTotalWeight, { decimalPlaces: 0 })} kg`,
                ),
                Länge: Translator.emptyValueToEmptyString(
                    report.car.length,
                    `${formatNumberToGermanLocale(report.car.length, { decimalPlaces: 0 })} mm`,
                ),
                Breite: Translator.emptyValueToEmptyString(
                    report.car.width,
                    `${formatNumberToGermanLocale(report.car.width, { decimalPlaces: 0 })} mm`,
                ),
                Höhe: Translator.emptyValueToEmptyString(
                    report.car.height,
                    `${formatNumberToGermanLocale(report.car.height, { decimalPlaces: 0 })} mm`,
                ),
                Radstand: Translator.emptyValueToEmptyString(
                    report.car.wheelBase,
                    `${formatNumberToGermanLocale(report.car.wheelBase, { decimalPlaces: 0 })} mm`,
                ),
                AnzahlAchsen: report.car.numberOfAxles != null ? report.car.numberOfAxles : '', // May be 0 in case of a trailer, so explicitly check for null.
                AnzahlAngetriebeneAchsen:
                    report.car.numberOfPoweredAxles != null ? report.car.numberOfPoweredAxles : '', // May be 0 in case of a trailer, so explicitly check for null.
                Sitzplätze: report.car.numberOfSeats != null ? report.car.numberOfSeats : '', // May be 0 in case of a trailer, so explicitly check for null.
                AnzahlTüren:
                    report.car.shape === 'motorcycle'
                        ? ''
                        : report.car.numberOfDoors !== null
                          ? report.car.numberOfDoors
                          : '', // May be 0 in case of a trailer, so explicitly check for null.
                Baujahr: report.car.productionYear
                    ? makeLuxonDateTime(report.car.productionYear).toLocaleString({ year: 'numeric' })
                    : '', // Date format: YYYY
                /**
                 * According to Patrick Kohl (2025-02-18), the age of the car must always be calculated from a specific date.
                 * Usually this is the first registration date (placeholder below). Only if first registration is not available (e.g. for e-scooters or fleet vehicles from airports),
                 * the date 'Erstinbetriebnahme' or the production date is used.
                 *
                 * According to Patrick Kohl, there is no use case where the age of the car calculated from only the production year is relevant.
                 * Therefore there is also no market standard how to calculate the age of the car from the production year.
                 * The following placeholder is therefore kept for legacy reasons (we do not use it in our partials but customers may have used the placeholder in their partials).
                 *
                 * luxon diffNow returns a negative value for events in the past, so we need to negate it.
                 */
                AlterInJahrenLautBaujahr: report.car.productionYear
                    ? Math.floor(
                          -1 *
                              makeLuxonDateTime(report.car.productionYear).startOf('year').diffNow('years').as('years'),
                      )
                    : null,
                /**
                 * The car age behaves according to a humans age:
                 * Each year, on the date of the first registration, a car is one year older.
                 *
                 * luxon diffNow returns a negative value for events in the past, so we need to negate it.
                 */
                AlterInJahrenLautErstzulassung: report.car.firstRegistration
                    ? Math.floor(
                          -1 *
                              makeLuxonDateTime(report.car.firstRegistration)
                                  .startOf('day')
                                  .diffNow('years')
                                  .as('years'),
                      )
                    : null,
                istZulassungspflichtig: carRequiresRegistration(report),
                Erstzulassung: report.car.firstRegistration ? toGermanDate(report.car.firstRegistration) : '', // DD.MM.YYYY
                letzteZulassung: report.car.latestRegistration ? toGermanDate(report.car.latestRegistration) : '', // DD.MM.YYYY
                nächsteHU: report.car.nextGeneralInspection
                    ? makeLuxonDateTime(report.car.nextGeneralInspection).toLocaleString(GERMAN_MONTH_AND_YEAR_FORMAT)
                    : '', // MM.YYYY
                nächsteSP: report.car.nextSafetyTest
                    ? makeLuxonDateTime(report.car.nextSafetyTest).toLocaleString(GERMAN_MONTH_AND_YEAR_FORMAT)
                    : '', // MM.YYYY
                LaufleistungAbgelesen: report.car.mileageMeter
                    ? `${formatNumberToGermanLocale(report.car.mileageMeter || 0, { decimalPlaces: 0 })} ${Translator.mileageUnit(
                          report.car.mileageUnit,
                      )}`
                    : '',
                LaufleistungGeschätzt: report.car.mileage
                    ? `${formatNumberToGermanLocale(report.car.mileage || 0, { decimalPlaces: 0 })} ${Translator.mileageUnit(
                          report.car.mileageUnit,
                      )}`
                    : '',
                LaufleistungAngegeben: report.car.mileageAsStated
                    ? `${formatNumberToGermanLocale(report.car.mileageAsStated || 0, { decimalPlaces: 0 })} ${Translator.mileageUnit(
                          report.car.mileageUnit,
                      )}`
                    : '',
                LaufleistungKommentar: report.car.mileageComment || '',
                AnzahlVorbesitzer: Translator.numberOfPreviousOwners(report.car.numberOfPreviousOwners),
                QuelleAnzahlVorbesitzer: report.car.sourceOfNumberOfPreviousOwners || '',
                Bemerkungen: report.car.comment || '',
                Besonderheiten: (report.car.specialFeature || '').trim(),
                Lack: {
                    Farbe: report.car.paintColor || '',
                    Farbcode: report.car.paintColorCode || '',
                    Art: report.car.paintType || '',
                    Zustand: report.car.paintCondition ? report.car.paintCondition : '',
                },
                wurdeLackschichtdickeGemessen: paintThicknessMeasurements.length > 0,
                Lackschichtdicke: paintThicknessMeasurements,
                LackschichtdickeKommentar: report.car.paintThicknessMeasurementComment || '',
                LackschichtdickeOhneKlassifikation:
                    team.preferences.paintThicknessMeasurementExcludeClassification || false,
                LackschichtdickeOhneWerte: omitPaintThicknessMeasurementValues,
                Allgemeinzustand: report.car.generalCondition ? report.car.generalCondition : '',
                Karosseriezustand: report.car.autobodyCondition ? report.car.autobodyCondition : '',
                Innenraumzustand:
                    report.car.shape === 'motorcycle'
                        ? ''
                        : report.car.interiorCondition
                          ? report.car.interiorCondition
                          : '',
                Fahrwerkszustand: report.car.undercarriageCondition ? report.car.undercarriageCondition : '',
                istProbefahrtDurchgeführt: report.testDriveCarriedOut,
                istFehlerspeicherAusgelesen: report.errorLogReadOut,
                hatAirbags: carHasAirbags(report),
                Airbags: {
                    ausgelöst: !!report.car.airbagsReleased,
                    Kommentar: report.car.airbagsComment,
                },
                Reifen: tiresPlaceholderValues,
                ZweiterReifensatz: secondTiresPlaceholderValues,
                hatZweitenReifensatz: report.car.hasSecondTireSet,
                istReifenKommentarVorhanden:
                    tiresPlaceholderValues.some((tirePlaceholderValues) => tirePlaceholderValues.Bemerkung) ||
                    !!(report.car.spareTireEquipment.type === 'spareWheel' && report.car.spareTireEquipment.comment),
                istReifenKommentarImZweitenReifensatzVorhanden: !!secondTiresPlaceholderValues?.some(
                    (secondTirePlaceholderValues) => secondTirePlaceholderValues.Bemerkung,
                ),
                istZwillingsbereifungVorhanden: tiresPlaceholderValues.some((tirePlaceholderValues) =>
                    new Array<TirePositionGerman>('Rechts außen', 'Links außen').includes(
                        tirePlaceholderValues.Position,
                    ),
                ),
                Achsen: axlesPlaceholderValues,
                IndividuelleEigenschaften: customCarPropertiesPlaceholderValues,
                Notbereifung: {
                    istReparaturkitVorhanden: report.car.spareTireEquipment.type === 'tireRepairKit',
                    istNotradVorhanden: report.car.spareTireEquipment.type === 'compactSpareWheel',
                    Ersatzrad:
                        report.car.spareTireEquipment.type === 'spareWheel'
                            ? getTirePlaceholderValues({
                                  tire: {
                                      type: report.car.spareTireEquipment.dimension,
                                      treadInMm: report.car.spareTireEquipment.treadInMm,
                                      manufacturer: report.car.spareTireEquipment.manufacturer,
                                      season: report.car.spareTireEquipment.season,
                                      customType: report.car.spareTireEquipment.customType,
                                      comment: report.car.spareTireEquipment.comment,
                                      axle: 0,
                                      position: null,
                                  },
                              })
                            : null,
                    Kommentar: report.car.spareTireEquipment.comment || '',
                },
                istScheckheftGepflegt: Translator.tristate(report.car.serviceBookComplete),
                letzterService: {
                    Datum: report.car.lastServiceDate ? toGermanDate(report.car.lastServiceDate) : '',
                    Laufleistung: report.car.lastServiceMileage
                        ? `${formatNumberToGermanLocale(report.car.lastServiceMileage || 0, {
                              decimalPlaces: 0,
                          })} ${Translator.mileageUnit(report.car.mileageUnit)}`
                        : '',
                },
                Vorschäden: report.car.repairedPreviousDamage || '',
                Altschäden: report.car.unrepairedPreviousDamage || '',
                Nachschäden: report.car.meantimeDamage || '',
                Fahrfähigkeit: report.car.roadworthiness || '',
                Schadstoffgruppe: Translator.emissionGroup(report.car.emissionGroup),
                SchadstoffgruppeFarbe: Translator.emissionGroupColor(report.car.emissionGroup),
                SchadstoffgruppeBild: report.car.emissionGroup,
                Abgasnorm: report.car.emissionStandard || '',
                Ausstattung: {
                    Serienausstattung: seriesEquipment,
                    SerienausstattungSpalteA: seriesEquipmentColumnA,
                    SerienausstattungSpalteB: seriesEquipmentColumnB,
                    Sonderausstattung: specialEquipment,
                    SonderausstattungSpalteA: specialEquipmentColumnA,
                    SonderausstattungSpalteB: specialEquipmentColumnB,
                    Zusatzausstattung: additionalEquipment,
                    ZusatzausstattungSpalteA: additionalEquipmentColumnA,
                    ZusatzausstattungSpalteB: additionalEquipmentColumnB,
                },
                Hochvoltbatterie: {
                    Kapazität: report.car.batteryCapacity ? `${report.car.batteryCapacity} kWh` : '',
                    FahrzeughalterIstEigentümer: Translator.tristate(report.car.carOwnerIsBatteryOwner),
                    Eigentümer: report.car.carOwnerIsBatteryOwner
                        ? getContactPersonFullNameWithOrganization(getCarOwner(report).contactPerson)
                        : report.car.batteryOwner,
                    Erhaltungszustand: report.car.batteryStateOfHealth ? `${report.car.batteryStateOfHealth} %` : '',
                    ErhaltungszustandKommentar: report.car.batteryStateOfHealthComment || '',
                    Beschädigung: Translator.batteryDamage(report.car.batteryDamage),
                    BeschädigungKommentar: report.car.batteryDamageComment || '',
                },
            },
            Unfall: report.accident
                ? {
                      Ort: report.accident.location || '',
                      Datum: report.accident.date ? toGermanDate(report.accident.date) : '',
                      Uhrzeit: report.accident.time
                          ? `${makeLuxonDateTime(report.accident.time).toLocaleString(GERMAN_TIME_FORMAT)} Uhr`
                          : '',
                      Schadenhergang: report.accident.circumstances ? report.accident.circumstances : '',
                      Plausibilität: report.accident.plausibility ? report.accident.plausibility : '',
                      Kompatibilität: report.accident.compatibility ? report.accident.compatibility : '',
                      polizeilichErfasst: Translator.tristate(
                          report.type === 'shortAssessment' ? null : report.accident.recordedByPolice,
                      ),
                      AktenzeichenPolizei: report.accident.caseNumberPolice || '',
                      Polizeibehörde: report.accident.policeDepartment || '',
                      KaskoSchadenart: report.accident.kaskoDamageType
                          ? Translator.kaskoDamageType(
                                report.accident.kaskoDamageType,
                                report.accident.kaskoDamageTypeCustomLabel,
                            )
                          : '',
                  }
                : null,
            Besichtigungen: visitsPlaceholders,
            ErsteBesichtigung: visitsPlaceholders[0],
            LetzteBesichtigung: visitsPlaceholders[visitsPlaceholders.length - 1],
            AnzahlBesichtigungen: visitsPlaceholders.length,
            Schadenskalkulation: {
                // The damage value "Schadenhöhe" is the sum of the corrected repair costs (repair costs - newForOld [Neu-für-alt]) and the reduced market value (Minderwert) reduced by the value increase (Wertverbesserung).
                // The reduced market value is always a value without Value Added Tax, so it's not included in the MwSt field! See https://www.haufe.de/steuern/kanzlei-co/minderwertausgleich-wegen-schaeden-am-leasingfahrzeug_170_265540.html.
                GesamtNetto: damageValueNet ? convertToEuro(damageValueNet) : 0,
                MwSt: damageValueNet ? convertToEuro(damageValueGross - damageValueNet) : 0,
                MwStProzent: getVatRate(report.completionDate) === 0.16 ? '16 %' : '19 %', // May be simplified after 2021-01-01
                GesamtBrutto: damageValueGross ? convertToEuro(damageValueGross) : 0,
                Minderwert: {
                    Wert: Translator.emptyValueToEmptyString(
                        report.valuation.diminishedValue,
                        convertToEuro(report.valuation.diminishedValue),
                    ),
                    MethodenAnzahl: report.valuation.diminishedValueCalculation?.methods
                        ? report.valuation.diminishedValueCalculation.methods.length
                        : 0,
                    MethodenNamen: report.valuation.diminishedValueCalculation?.methods
                        ? joinListToHumanReadableString(
                              report.valuation.diminishedValueCalculation.methods.map((methodId) =>
                                  Translator.diminishedValueCalculationMethod(methodId),
                              ),
                          )
                        : '',

                    // Values that can be derived from the interface
                    Neupreis: report.valuation.diminishedValueCalculation?.originalPrice
                        ? convertToEuro(report.valuation.diminishedValueCalculation.originalPrice)
                        : 0,
                    NeupreisNetto: report.valuation.diminishedValueCalculation?.originalPrice
                        ? convertToEuro(
                              report.valuation.diminishedValueCalculation.originalPrice / (1 + fullTaxationRate),
                          )
                        : 0,
                    AlterInMonaten: report.valuation.diminishedValueCalculation?.ageInMonths,
                    NeuwertigkeitTrömner: report.valuation.diminishedValueCalculation?.ageFactorTroemner
                        ? formatNumberToGermanLocale(report.valuation.diminishedValueCalculation?.ageFactorTroemner, {
                              decimalPlaces: { min: 0, max: 2 },
                          })
                        : '0',
                    Laufleistung: report.valuation.diminishedValueCalculation?.mileage
                        ? `${formatNumberToGermanLocale(report.valuation.diminishedValueCalculation.mileage || 0, {
                              decimalPlaces: 0,
                          })} ${Translator.mileageUnit(report.valuation.diminishedValueCalculation.mileageUnit)}`
                        : '',
                    ReparaturkostenErheblich: report.valuation.diminishedValueCalculation?.substantialRepairCosts
                        ? convertToEuro(report.valuation.diminishedValueCalculation.substantialRepairCosts)
                        : 0,
                    ReparaturkostenGesamt: report.valuation.diminishedValueCalculation?.totalRepairCosts
                        ? convertToEuro(report.valuation.diminishedValueCalculation.totalRepairCosts)
                        : 0,
                    ReparaturkostenGesamtNetto: report.valuation.diminishedValueCalculation?.totalRepairCosts
                        ? convertToEuro(
                              report.valuation.diminishedValueCalculation.totalRepairCosts / (1 + fullTaxationRate),
                          )
                        : 0,
                    Wiederbeschaffungswert: report.valuation.diminishedValueCalculation?.replacementValue
                        ? convertToEuro(report.valuation.diminishedValueCalculation.replacementValue)
                        : 0,
                    WiederbeschaffungswertNetto: report.valuation.diminishedValueCalculation?.replacementValue
                        ? convertToEuro(
                              report.valuation.diminishedValueCalculation.replacementValue / (1 + fullTaxationRate),
                          )
                        : 0,
                    Veräußerungswert: report.valuation.diminishedValueCalculation?.timeValue
                        ? convertToEuro(report.valuation.diminishedValueCalculation.timeValue)
                        : 0,
                    VeräußerungswertNetto: report.valuation.diminishedValueCalculation?.timeValue
                        ? convertToEuro(report.valuation.diminishedValueCalculation.timeValue / (1 + fullTaxationRate))
                        : 0,
                    MarktgängigkeitBvsk: report.valuation.diminishedValueCalculation?.marketabilityBvsk
                        ? convertToPercent(report.valuation.diminishedValueCalculation?.marketabilityBvsk / 100, 1)
                        : 0,
                    MarktgängigkeitMfm: report.valuation.diminishedValueCalculation?.marketabilityMfm
                        ? formatNumberToGermanLocale(report.valuation.diminishedValueCalculation?.marketabilityMfm, {
                              decimalPlaces: 1,
                          })
                        : '0',
                    MarktgängigkeitTrömner: report.valuation.diminishedValueCalculation?.marketabilityTroemner
                        ? formatNumberToGermanLocale(
                              report.valuation.diminishedValueCalculation?.marketabilityTroemner,
                              { decimalPlaces: { min: 0, max: 2 } },
                          )
                        : '0',
                    VorschadenBvsk: report.valuation.diminishedValueCalculation?.previousDamageBvsk
                        ? formatNumberToGermanLocale(report.valuation.diminishedValueCalculation?.previousDamageBvsk, {
                              decimalPlaces: 1,
                          })
                        : '0',
                    VorschadenMfm: report.valuation.diminishedValueCalculation?.previousDamageMfm
                        ? formatNumberToGermanLocale(report.valuation.diminishedValueCalculation?.previousDamageMfm, {
                              decimalPlaces: 1,
                          })
                        : '0',
                    VorschadenTrömner: report.valuation.diminishedValueCalculation?.previousDamageTroemner
                        ? formatNumberToGermanLocale(
                              report.valuation.diminishedValueCalculation?.previousDamageTroemner,
                              { decimalPlaces: { min: 0, max: 2 } },
                          )
                        : '0',
                    LohnOhneLack: report.valuation.diminishedValueCalculation?.laborCosts
                        ? convertToEuro(report.valuation.diminishedValueCalculation?.laborCosts)
                        : 0,
                    LohnOhneLackNetto: report.valuation.diminishedValueCalculation?.laborCosts
                        ? convertToEuro(
                              report.valuation.diminishedValueCalculation?.laborCosts / (1 + fullTaxationRate),
                          )
                        : 0,
                    Ersatzteile: report.valuation.diminishedValueCalculation?.materialCosts
                        ? convertToEuro(report.valuation.diminishedValueCalculation?.materialCosts)
                        : 0,
                    ErsatzteileNetto: report.valuation.diminishedValueCalculation?.materialCosts
                        ? convertToEuro(
                              report.valuation.diminishedValueCalculation?.materialCosts / (1 + fullTaxationRate),
                          )
                        : 0,
                    SchadenumfangMfm: report.valuation.diminishedValueCalculation?.damageLevel
                        ? formatNumberToGermanLocale(report.valuation.diminishedValueCalculation?.damageLevel, {
                              decimalPlaces: 1,
                          })
                        : '0',
                    SchadensklasseBvsk: report.valuation.diminishedValueCalculation?.damageIntensity
                        ? convertToPercent(report.valuation.diminishedValueCalculation?.damageIntensity / 100, 1)
                        : 0,
                    FahrzeugschadenTrömner: report.valuation.diminishedValueCalculation?.vehicleDamageTroemner
                        ? formatNumberToGermanLocale(
                              report.valuation.diminishedValueCalculation?.vehicleDamageTroemner,
                              { decimalPlaces: { min: 0, max: 2 } },
                          )
                        : '0',
                    Kommentar: report.valuation.diminishedValueCalculation?.comment || '',
                    basiertAufNettowerten: !!report.valuation.diminishedValueCalculation?.isBasedOnNetValues,
                    EingabenUmMwStReduziert: !!report.valuation.diminishedValueCalculation?.reduceInputValuesByVat,
                    // Values that need calculation
                    BVSK: methodResults.bvsk != null ? convertToEuro(methodResults.bvsk) : 0,
                    RuhkopfSahm: methodResults.ruhkopf != null ? convertToEuro(methodResults.ruhkopf) : 0,
                    DVGT: methodResults.dvgt != null ? convertToEuro(methodResults.dvgt) : 0,
                    Halbgewachs: methodResults.halbgewachs != null ? convertToEuro(methodResults.halbgewachs) : 0,
                    HamburgerModell: methodResults.hamburgModel != null ? convertToEuro(methodResults.hamburgModel) : 0,
                    MFM: methodResults.mfm != null ? convertToEuro(methodResults.mfm) : 0,
                    Trömner: methodResults.troemner != null ? convertToEuro(methodResults.troemner) : 0,
                    Durchschnitt: report.valuation.diminishedValueCalculation
                        ? convertToEuro(
                              calculateAverageDiminishedValueOfActiveMethods(
                                  report.valuation.diminishedValueCalculation,
                              ),
                          )
                        : 0,
                    AlterskorrekturMfm: report.valuation.diminishedValueCalculation
                        ? formatNumberToGermanLocale(
                              DiminishedValueMethods.getAgeFactorMfm(
                                  report.valuation.diminishedValueCalculation.ageInMonths,
                              ),
                              { decimalPlaces: 3 },
                          )
                        : '0',
                    XWertRuhkopfSahm: report.valuation.diminishedValueCalculation
                        ? convertToPercent(
                              DiminishedValueMethods.getXValueRuhkopf({
                                  ageInMonths: report.valuation.diminishedValueCalculation.ageInMonths,
                                  totalRepairCosts: report.valuation.diminishedValueCalculation.totalRepairCosts,
                                  timeValue: report.valuation.diminishedValueCalculation.timeValue,
                              }),
                          )
                        : 0,
                    XWertHalbgewachs: report.valuation.diminishedValueCalculation
                        ? convertToPercent(
                              DiminishedValueMethods.getXValueHalbgewachs({
                                  ageInMonths: report.valuation.diminishedValueCalculation.ageInMonths,
                                  totalRepairCosts: report.valuation.diminishedValueCalculation.totalRepairCosts,
                                  timeValue: report.valuation.diminishedValueCalculation.timeValue,
                                  laborCosts: report.valuation.diminishedValueCalculation.laborCosts,
                                  materialCosts: report.valuation.diminishedValueCalculation.materialCosts,
                              }),
                          )
                        : 0,
                    ReparaturZuWiederbeschaffungswert:
                        report.valuation.diminishedValueCalculation?.totalRepairCosts &&
                        report.valuation.diminishedValueCalculation?.replacementValue
                            ? convertToPercent(
                                  report.valuation.diminishedValueCalculation.totalRepairCosts /
                                      report.valuation.diminishedValueCalculation.replacementValue,
                                  1,
                              )
                            : 0,
                },
                TechnischerMinderwert: {
                    Wert: Translator.emptyValueToEmptyString(
                        report.valuation.technicalDiminishedValue,
                        convertToEuro(report.valuation.technicalDiminishedValue),
                    ),
                    Kommentar: report.valuation.technicalDiminishedValueComment || '',
                },
                Wertverbesserung: {
                    Wert: Translator.emptyValueToEmptyString(
                        report.valuation.valueIncrease,
                        convertToEuro(report.valuation.valueIncrease),
                    ),
                    Kommentar: report.valuation.valueIncreaseRemark || '',
                },
                Quelle: report.damageCalculation?.repair.calculationProvider
                    ? Translator.calculationProvider(report.damageCalculation.repair.calculationProvider)
                    : null,
                istPhantomkalkulation: !!report.damageCalculation?.repair.isPhantomCalculation,
                istÜberschlägigeKalkulation: !!report.damageCalculation?.repair.isApproximateCalculation,
                istEinfacheSchätzung: !!report.damageCalculation?.repair.useSimpleEstimateCalculation,
                Schadensklasse: report.damageCalculation?.damageType
                    ? Translator.damageType(report.damageCalculation.damageType)
                    : '',
                Reparatur: report.damageCalculation
                    ? {
                          ReparaturauftragErteilt: Translator.tristate(
                              report.damageCalculation.repair.repairOrderIssued,
                          ),
                          mitDekraSätzen: !!report.damageCalculation.repair.useDekraFees,
                          BeilackierungErforderlich: !!report.damageCalculation.repair.paintBlendingRequired,
                          BeilackierungKommentar: report.damageCalculation.repair.paintBlendingComment || '',
                          Achsvermessung: Translator.axisMeasurement(report.damageCalculation.repair.axisMeasurement),
                          AchsvermessungKommentar: report.damageCalculation.repair.axisMeasurementComment || '',
                          PrüfungHochvoltsystem: Translator.highVoltageSystemCheck(
                              report.damageCalculation.repair.highVoltageSystemCheck,
                          ),
                          PrüfungHochvoltsystemKommentar:
                              report.damageCalculation.repair.highVoltageSystemCheckComment || '',
                          Karosserievermessung: Translator.carBodyMeasurement(
                              report.damageCalculation.repair.carBodyMeasurement,
                          ),
                          KarosserievermessungKommentar:
                              report.damageCalculation.repair.carBodyMeasurementComment || '',
                          InstandsetzungKunststoffteileErforderlich:
                              !!report.damageCalculation.repair.plasticRepairRequired,
                          KostenNetto: report.damageCalculation.repair.repairCostsNet
                              ? convertToEuro(report.damageCalculation.repair.repairCostsNet)
                              : 0,
                          KostenMwSt:
                              report.damageCalculation.repair.repairCostsGross -
                              report.damageCalculation.repair.repairCostsNet
                                  ? convertToEuro(
                                        report.damageCalculation.repair.repairCostsGross -
                                            report.damageCalculation.repair.repairCostsNet,
                                    )
                                  : 0,
                          KostenBrutto: report.damageCalculation.repair.repairCostsGross
                              ? convertToEuro(report.damageCalculation.repair.repairCostsGross)
                              : 0,
                          ErsatzteilkostenNetto: convertToEuro(report.damageCalculation.repair.sparePartsCostsNet),
                          ErsatzteilkostenBrutto: convertToEuro(
                              report.damageCalculation.repair.sparePartsCostsNet * 1.19,
                          ),
                          ArbeitslohnkostenNetto: convertToEuro(report.damageCalculation.repair.garageLaborCostsNet),
                          ArbeitslohnkostenBrutto: convertToEuro(
                              report.damageCalculation.repair.garageLaborCostsNet * 1.19,
                          ),
                          LackierungskostenNetto: convertToEuro(report.damageCalculation.repair.lacquerCostsNet),
                          LackierungskostenBrutto: convertToEuro(
                              report.damageCalculation.repair.lacquerCostsNet * 1.19,
                          ),
                          NebenkostenNetto: convertToEuro(report.damageCalculation.repair.auxiliaryCostsNet),
                          NebenkostenBrutto: convertToEuro(report.damageCalculation.repair.auxiliaryCostsNet * 1.19),
                          NeuFürAltNetto: report.damageCalculation.repair.newForOldNet
                              ? convertToEuro(report.damageCalculation.repair.newForOldNet)
                              : 0,
                          NeuFürAltMwSt:
                              report.damageCalculation.repair.newForOldGross -
                              report.damageCalculation.repair.newForOldNet
                                  ? convertToEuro(
                                        report.damageCalculation.repair.newForOldGross -
                                            report.damageCalculation.repair.newForOldNet,
                                    )
                                  : 0,
                          NeuFürAltBrutto: report.damageCalculation.repair.newForOldGross
                              ? convertToEuro(report.damageCalculation.repair.newForOldGross)
                              : 0,
                          RabattNetto: report.damageCalculation.repair.discountNet
                              ? convertToEuro(report.damageCalculation.repair.discountNet)
                              : 0,
                          RabattMwSt:
                              report.damageCalculation.repair.discountGross -
                              report.damageCalculation.repair.discountNet
                                  ? convertToEuro(
                                        report.damageCalculation.repair.discountGross -
                                            report.damageCalculation.repair.discountNet,
                                    )
                                  : 0,
                          RabattBrutto: report.damageCalculation.repair.discountGross
                              ? convertToEuro(report.damageCalculation.repair.discountGross)
                              : 0,
                          //*****************************************************************************
                          //  Corrected Repair Costs
                          //****************************************************************************/
                          KorrigierteKostenNetto: report.damageCalculation.repair.correctedRepairCostsNet
                              ? convertToEuro(report.damageCalculation.repair.correctedRepairCostsNet)
                              : 0,
                          KorrigierteKostenMwSt:
                              report.damageCalculation.repair.correctedRepairCostsGross -
                              report.damageCalculation.repair.correctedRepairCostsNet
                                  ? convertToEuro(
                                        report.damageCalculation.repair.correctedRepairCostsGross -
                                            report.damageCalculation.repair.correctedRepairCostsNet,
                                    )
                                  : 0,
                          KorrigierteKostenBrutto: report.damageCalculation.repair.correctedRepairCostsGross
                              ? convertToEuro(report.damageCalculation.repair.correctedRepairCostsGross)
                              : 0,
                          /////////////////////////////////////////////////////////////////////////////*/
                          //  END Corrected Repair Costs
                          /////////////////////////////////////////////////////////////////////////////*/
                          //*****************************************************************************
                          // Repair Costs Minus Value Increase
                          //****************************************************************************/
                          /**
                           * Some customers require the diminished value to be separate from the total damage value
                           * ("Schadenhöhe"). At the same time, they wish to print out the repair costs
                           * from which the value increase entered into autoiXpert has been deducted.
                           * That's similar to the *corrected repair costs* but the corrected repair costs are
                           * always taken straight from the calculation system.
                           */
                          KostenMinusWertverbesserungNetto: correctedRepairCostsMinusValueIncreaseNet
                              ? convertToEuro(correctedRepairCostsMinusValueIncreaseNet)
                              : 0,
                          KostenMinusWertverbesserungMwSt: correctedRepairCostsMinusValueIncreaseNet
                              ? convertToEuro(correctedRepairCostsMinusValueIncreaseNet * 0.19)
                              : 0,
                          KostenMinusWertverbesserungBrutto: correctedRepairCostsMinusValueIncreaseNet
                              ? convertToEuro(correctedRepairCostsMinusValueIncreaseNet * 1.19)
                              : 0,
                          /////////////////////////////////////////////////////////////////////////////*/
                          //  END Repair Costs Minus Value Increase
                          /////////////////////////////////////////////////////////////////////////////*/
                          DauerInArbeitstagen: report.damageCalculation.downtimeInWorkdaysDueToReparation || '',
                          DauerEinheit:
                              report.damageCalculation.downtimeInWorkdaysDueToReparation === '1'
                                  ? 'Arbeitstag'
                                  : 'Arbeitstage',
                          Weg: report.damageCalculation.repair.repairInstructions || '',
                          AnzahlRisiken: (report.damageCalculation.repair.repairRisks || []).length,
                          Risiken: (report.damageCalculation.repair.repairRisks || []).join(', '),
                          RisikenKommentar: report.damageCalculation.repair.repairRisksComment || '',
                          KalkulationKommentar: report.damageCalculation.repair.calculationComment || '',
                      }
                    : null,
                ÜberschlägigeKalkulation: report.damageCalculation
                    ? {
                          ErsatzteileKosten: report.damageCalculation.repair.sparePartsCostsNet
                              ? convertToEuro(report.damageCalculation.repair.sparePartsCostsNet)
                              : 0,
                          ArbeitslohnKosten: report.damageCalculation.repair.garageLaborCostsNet
                              ? convertToEuro(report.damageCalculation.repair.garageLaborCostsNet)
                              : 0,
                          LackierungKosten: report.damageCalculation.repair.lacquerCostsNet
                              ? convertToEuro(report.damageCalculation.repair.lacquerCostsNet)
                              : 0,
                          Nebenkosten: report.damageCalculation.repair.auxiliaryCostsNet
                              ? convertToEuro(report.damageCalculation.repair.auxiliaryCostsNet)
                              : 0,
                          RabatteUndVergütungNetto: report.damageCalculation.repair.discountNet
                              ? convertToEuro(report.damageCalculation.repair.discountNet)
                              : 0,
                          RabatteUndVergütungBrutto: report.damageCalculation.repair.discountGross
                              ? convertToEuro(report.damageCalculation.repair.discountGross)
                              : 0,
                          RabatteUndVergütungMwSt: report.damageCalculation.repair.discountGross
                              ? convertToEuro(
                                    report.damageCalculation.repair.discountGross -
                                        report.damageCalculation.repair.discountNet,
                                )
                              : 0,
                      }
                    : null,
                ManuelleKalkulation: {
                    Datum: manualCalculation?.date ? toGermanDate(manualCalculation.date) : '',
                    Positionen: {
                        Ersatzteile: getSparePartsCalculationItemsPlaceholders(
                            manualCalculation?.calculationItems,
                            garageFeeSet,
                        ),
                        Nebenkosten: getAuxiliaryCostsCalculationItemsPlaceholders(manualCalculation?.calculationItems),
                        Arbeitslohn: getLaborCostsCalculationItemsPlaceholders(
                            manualCalculation?.calculationItems,
                            garageFeeSet,
                        ),
                        Lackierung: getCarPaintCalculationItemsPlaceholders(
                            manualCalculation?.calculationItems,
                            garageFeeSet,
                            report.car.paintType,
                        ),
                        Abzüge: getDeductionsCalculationItemsPlaceholders(
                            manualCalculation?.calculationItems,
                            garageFeeSet,
                            report.car.paintType,
                        ),
                    },
                    Summen: {
                        Ersatzteile: getSparePartsTotalPlaceholderObject(
                            manualCalculation?.calculationItems,
                            garageFeeSet,
                        ),
                        Nebenkosten: getAuxiliaryCostsTotalPlaceholderObject(manualCalculation?.calculationItems),
                        Arbeitslohn: getLaborCostsDetailsPlaceholderObject(manualCalculation?.calculationItems),
                        Lackierung: getPaintCostsDetailsPlaceholderObject(
                            manualCalculation?.calculationItems,
                            garageFeeSet,
                            report.car.paintType,
                        ),
                    },
                },
                Restkraftstoff: report.damageCalculation
                    ? {
                          Menge: Translator.emptyValueToEmptyString(report.damageCalculation.remainingFuel?.amount),
                          PreisProLiter: Translator.emptyValueToEmptyString(
                              report.damageCalculation.remainingFuel?.pricePerLiter,
                              convertToEuro(report.damageCalculation.remainingFuel?.pricePerLiter),
                          ),
                          Kosten: Translator.emptyValueToEmptyString(
                              report.damageCalculation.remainingFuel?.costs,
                              convertToEuro(report.damageCalculation.remainingFuel?.costs),
                          ),
                      }
                    : null,
                Umbaukosten: report.damageCalculation?.retrofit?.items?.length
                    ? {
                          Positionen: retrofitItemsGerman,
                          // Filter empty positions using .trim() and .filter(Boolean) to allow the user to specify only a generic nameless line for retrofit items.
                          PositionenBezeichnungen: retrofitItemsGerman.length
                              ? joinListToHumanReadableString(
                                    retrofitItemsGerman
                                        .map((retrofitItemGerman) => stripHtml(retrofitItemGerman.Bezeichnung).trim())
                                        .filter(Boolean),
                                )
                              : '',
                          AnzahlPositionen: retrofitItemsGerman.length,
                          GesamtkostenBrutto:
                              convertToEuro(
                                  report.damageCalculation.retrofit.items.reduce(
                                      (total, value) => total + value.costsGross,
                                      0,
                                  ),
                              ) || 0,
                          Kommentar: report.damageCalculation.retrofit.comment,
                      }
                    : null,
                Entsorgungskosten: Translator.emptyValueToEmptyString(
                    report.damageCalculation?.disposalCosts,
                    convertToEuro(report.damageCalculation?.disposalCosts),
                ),
                AbUndAnmeldegebühren: Translator.emptyValueToEmptyString(
                    report.damageCalculation?.deregistrationAndRegistrationCosts,
                    convertToEuro(report.damageCalculation?.deregistrationAndRegistrationCosts),
                ),
                WeitereKosten: {
                    Summe: additionalCostsSum ? convertToEuro(additionalCostsSum) : '',
                    Positionen: additionalCostItemsGerman,
                },
                '130ProzentGrenzeNetto': replacementValueNet ? convertToEuro(1.3 * replacementValueNet) : '',
                '130ProzentGrenzeBrutto': replacementValueGross ? convertToEuro(1.3 * replacementValueGross) : '',
                '130ProzentGrenzeNachVorsteuerabzugsfähigkeit': replacementValueWithoutTaxForCompanies
                    ? convertToEuro(1.3 * replacementValueWithoutTaxForCompanies)
                    : '',

                // // Contains an array like "[{AudatexKalkulationPdfBilddatei : 0}, {AudatexKalkulationPdfBilddatei : 1}, {AudatexKalkulationPdfBilddatei : 2}]". Each index maps to the array report.audatexCalculationPdfImages which contains
                // // the necessary photo buffers for the DOCX to be rendered.
                // PdfBilder          : audatexCalculationPdfImageIndexes,

                // Internal value. This forces a cache invalidation of the DOCX/PDF document if the damage calculation document changed. That's required if the user did not change any numeric values (labor costs, total net etc.) but
                // a label or so that is otherwise not reflected in the damage calculation placeholders.
                DocumentHash: report.damageCalculation?.repair.documentHash,
            },
            Notreparatur: report.damageCalculation?.emergencyRepair
                ? {
                      Status: report.damageCalculation.emergencyRepair.state || '',
                      KostenBrutto: report.damageCalculation.emergencyRepair.costs
                          ? convertToEuro(report.damageCalculation.emergencyRepair.costs)
                          : '',
                      KostenBruttoCirca: report.damageCalculation.emergencyRepair.costs
                          ? `${
                                ['notRecommended', 'recommended'].includes(
                                    report.damageCalculation.emergencyRepair.state,
                                )
                                    ? 'ca. '
                                    : ''
                            }${convertToEuro(report.damageCalculation.emergencyRepair.costs)}`
                          : null,
                      Kommentar: report.damageCalculation.emergencyRepair.comment || '',
                      Arbeiten: report.damageCalculation.emergencyRepair.workItems
                          ? report.damageCalculation.emergencyRepair.workItems.join(', ')
                          : '',
                  }
                : null,
            Bewertung: {
                // Internal value. This forces a cache invalidation of the DOCX/PDF document if the valuation document changed. That's required if the user did not change any numeric values but
                // a label or so that is otherwise not reflected in the valuation placeholders.
                DatValuationDocumentHash: report.valuation.datValuation?.documentHash,
                AudatexValuationDocumentHash: report.valuation.audatexValuation?.documentHash,
                Stichtag: report.valuation.referenceDate ? toGermanDate(report.valuation.referenceDate) : null,
                Quelle: report.valuation.valuationProvider
                    ? Translator.valuationProvider(report.valuation.valuationProvider)
                    : null,
                QuelleAbgerufen:
                    report.valuation.valuationProvider === 'audatex'
                        ? !!report.valuation.audatexValuation?.valuesRetrieved
                        : report.valuation.valuationProvider === 'dat'
                          ? !!report.valuation.datValuation?.valuesRetrieved
                          : false,

                FahrzeugwertNetto: valuationMainNet != null ? convertToEuro(valuationMainNet, { decimalPlaces: 2 }) : 0,
                FahrzeugwertBrutto:
                    valuationMainGross != null ? convertToEuro(valuationMainGross, { decimalPlaces: 2 }) : 0,
                FahrzeugwertMwSt:
                    vehicleValueTaxAbsolute != null ? convertToEuro(vehicleValueTaxAbsolute, { decimalPlaces: 2 }) : 0,
                FahrzeugwertBezeichnung: Translator.vehicleValueLabel(report.valuation.vehicleValueType),
                // Relevant if the user wants to deduct repair costs with a (DAT) damage calculation instead of with the simple repair cost list in a DAT valuation.
                FahrzeugwertMinusReparaturkostenNetto:
                    valuationMainNet != null && report.damageCalculation?.repair.repairCostsNet != null
                        ? convertToEuro(valuationMainNet - report.damageCalculation.repair.repairCostsNet)
                        : 0,
                FahrzeugwertMinusReparaturkostenBrutto:
                    valuationMainGross != null && report.damageCalculation?.repair.repairCostsGross != null
                        ? convertToEuro(valuationMainGross - report.damageCalculation.repair.repairCostsGross)
                        : 0,
                FahrzeugwertMinusReparaturkostenMwSt:
                    vehicleValueTaxAbsolute != null &&
                    report.damageCalculation?.repair.repairCostsNet != null &&
                    report.damageCalculation.repair.repairCostsGross != null
                        ? convertToEuro(
                              vehicleValueTaxAbsolute -
                                  (report.damageCalculation.repair.repairCostsGross -
                                      report.damageCalculation.repair.repairCostsNet),
                          )
                        : 0,
                Besteuerungsart: Translator.replacementValueTaxation(report.valuation.taxationType),

                // First Vehicle Value (dealer sales price)
                HändlerVerkaufspreis: {
                    Aktiv: !!report.valuation.vehicleValueSalesPriceActive,
                    Netto:
                        report.valuation.secondVehicleValueNet != null
                            ? convertToEuro(report.valuation.secondVehicleValueNet, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    Brutto:
                        report.valuation.secondVehicleValueGross != null
                            ? convertToEuro(report.valuation.secondVehicleValueGross, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    EnthalteneMwSt: convertToEuro(
                        (report.valuation.secondVehicleValueGross ?? 0) - (report.valuation.secondVehicleValueNet ?? 0),
                        { decimalPlaces: valuationDecimalPlaces },
                    ),
                    Besteuerungsart: Translator.replacementValueTaxation(report.valuation.taxationType),
                    Bezeichnung: Translator.vehicleValueLabel(report.valuation.secondVehicleValueType),
                },

                // First Vehicle Value (dealer purchase price)
                HändlerEinkaufspreis: {
                    Aktiv: !!report.valuation.vehicleValuePurchasePriceActive,
                    Netto:
                        report.valuation.vehicleValueNet != null
                            ? convertToEuro(report.valuation.vehicleValueNet, { decimalPlaces: valuationDecimalPlaces })
                            : 0,
                    Brutto:
                        report.valuation.vehicleValueGross != null
                            ? convertToEuro(report.valuation.vehicleValueGross, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    EnthalteneMwSt: convertToEuro(
                        (report.valuation.vehicleValueGross ?? 0) - (report.valuation.vehicleValueNet ?? 0),
                        { decimalPlaces: valuationDecimalPlaces },
                    ),
                    EnthältMwSt: !!mayCarOwnerDeductTaxes(report),
                    Bezeichnung: Translator.vehicleValueLabel(report.valuation.vehicleValueType),
                },

                Grundwert: {
                    HändlerVerkaufspreisNetto:
                        report.valuation.baseValueDealerSalesNet != null
                            ? convertToEuro(report.valuation.baseValueDealerSalesNet, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    HändlerVerkaufspreisBrutto:
                        report.valuation.baseValueDealerSalesGross != null
                            ? convertToEuro(report.valuation.baseValueDealerSalesGross, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    HändlerEinkaufspreisNetto:
                        report.valuation.baseValueDealerPurchaseNet != null
                            ? convertToEuro(report.valuation.baseValueDealerPurchaseNet, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    HändlerEinkaufspreisBrutto:
                        report.valuation.baseValueDealerPurchaseGross != null
                            ? convertToEuro(report.valuation.baseValueDealerPurchaseGross, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    Quelle:
                        report.valuation.correctionsTarget === 'dealerSales'
                            ? report.valuation.baseValueDealerSalesProvider
                                ? Translator.baseValueSalesProvider(report.valuation.baseValueDealerSalesProvider)
                                : null
                            : report.valuation.baseValueDealerPurchaseProvider
                              ? Translator.baseValuePurchaseProvider(report.valuation.baseValueDealerPurchaseProvider)
                              : null,
                },

                AnrechnungKalkulation: {
                    Netto:
                        report.valuation.decreaseFromDamageNet != null
                            ? convertToEuro(report.valuation.decreaseFromDamageNet, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    Brutto:
                        report.valuation.decreaseFromDamageGross != null
                            ? convertToEuro(report.valuation.decreaseFromDamageGross, {
                                  decimalPlaces: valuationDecimalPlaces,
                              })
                            : 0,
                    Prozent: report.valuation.decreaseFromDamagePercentage
                        ? convertToPercent(report.valuation.decreaseFromDamagePercentage / 100)
                        : 0,
                },

                HändlermargeProzent: report.valuation.tradeMarginGrossPercent
                    ? convertToPercent(report.valuation.tradeMarginGrossPercent / 100)
                    : 0,

                HändlermargeBrutto:
                    report.valuation.tradeMarginGrossPercent && report.valuation.secondVehicleValueGross
                        ? convertToEuro(
                              (report.valuation.secondVehicleValueGross * report.valuation.tradeMarginGrossPercent) /
                                  100,
                              { decimalPlaces: valuationDecimalPlaces },
                          )
                        : null,

                KorrekturenAngewendetAuf:
                    report.valuation.correctionsTarget === 'dealerSales' ? 'Verkaufspreis' : 'Einkaufspreis',

                SummeAufAbwertungenGross: convertToEuro(
                    (report.valuation.replacementValueCorrectionConfig?.totalIncrease ?? 0) -
                        (report.valuation.replacementValueCorrectionConfig?.totalDecrease ?? 0) -
                        (report.valuation.decreaseFromDamageGross ?? 0),
                    { decimalPlaces: valuationDecimalPlaces },
                ),

                sindAufAbwertungenVorhanden:
                    !!report.valuation.replacementValueCorrectionConfig?.totalDecrease ||
                    !!report.valuation.replacementValueCorrectionConfig?.totalIncrease ||
                    !!report.valuation.decreaseFromDamageGross,
            },
            Zustandsbericht: report.leaseReturn
                ? {
                      PrüfleitfadenBezeichnung: report.leaseReturn.title || 'Zustandsbericht',
                      Abschnitte: leaseReturnSectionsGerman,
                      Abschlag: report.leaseReturn.relativeResidualValue
                          ? convertToPercent(report.leaseReturn.relativeResidualValue)
                          : 0,
                      ReparaturkostenNetto: leaseReturnRepairCostsNet ? convertToEuro(leaseReturnRepairCostsNet) : 0,
                      ReparaturkostenNettoMwStNeutral: leaseReturnRepairCostsNetVatNeutral
                          ? convertToEuro(leaseReturnRepairCostsNetVatNeutral)
                          : 0,
                      ReparaturkostenNettoRegelbesteuert: leaseReturnRepairCostsNet
                          ? convertToEuro(leaseReturnRepairCostsNet - leaseReturnRepairCostsNetVatNeutral)
                          : 0,
                      ReparaturkostenMwSt: leaseReturnRepairCostsNet
                          ? convertToEuro(leaseReturnRepairCostsGross - leaseReturnRepairCostsNet)
                          : 0,
                      ReparaturkostenBrutto: leaseReturnRepairCostsGross
                          ? convertToEuro(leaseReturnRepairCostsGross)
                          : 0,
                      MinderwertNetto: leaseReturnDiminishedValueNet ? convertToEuro(leaseReturnDiminishedValueNet) : 0,
                      MinderwertNettoMwStNeutral: leaseReturnDiminishedValueNetVatNeutral
                          ? convertToEuro(leaseReturnDiminishedValueNetVatNeutral)
                          : 0,
                      MinderwertNettoRegelbesteuert: leaseReturnDiminishedValueNet
                          ? convertToEuro(leaseReturnDiminishedValueNet - leaseReturnDiminishedValueNetVatNeutral)
                          : 0,
                      MinderwertMwSt: leaseReturnDiminishedValueNet
                          ? convertToEuro(leaseReturnDiminishedValueGross - leaseReturnDiminishedValueNet)
                          : 0,
                      MinderwertBrutto: leaseReturnDiminishedValueGross
                          ? convertToEuro(leaseReturnDiminishedValueGross)
                          : 0,
                      // Relevant if the user wants to deduct repair costs from their checklist.
                      FahrzeugwertMinusReparaturkostenNetto: leaseReturnVehicleValueMinusRepairCostsNet
                          ? convertToEuro(leaseReturnVehicleValueMinusRepairCostsNet)
                          : 0,
                      FahrzeugwertMinusReparaturkostenMwSt: leaseReturnVehicleValueMinusRepairCostsVat
                          ? convertToEuro(leaseReturnVehicleValueMinusRepairCostsVat)
                          : 0,
                      FahrzeugwertMinusReparaturkostenBrutto: leaseReturnVehicleValueMinusRepairCostsGross
                          ? convertToEuro(leaseReturnVehicleValueMinusRepairCostsGross)
                          : 0,
                  }
                : null,
            Rechnungsprüfung: report.invoiceAudit
                ? {
                      Lohn: getInvoiceAuditRowPlaceholders(report.invoiceAudit.wages),
                      Ersatzteile: getInvoiceAuditRowPlaceholders(report.invoiceAudit.spareParts),
                      Lack: getInvoiceAuditRowPlaceholders(report.invoiceAudit.paint),
                      Nebenkosten: getInvoiceAuditRowPlaceholders(report.invoiceAudit.auxiliaryCosts),
                      Sonstiges: getInvoiceAuditRowPlaceholders(report.invoiceAudit.otherCosts),
                      Gesamt: getInvoiceAuditRowPlaceholders(report.invoiceAudit.columnTotals),
                      FremdrechnungDatum: report.invoiceAudit.repairInvoice.date
                          ? toGermanDate(report.invoiceAudit.repairInvoice.date)
                          : '',
                      FremdrechnungNummer: report.invoiceAudit.repairInvoice.number,
                      Korrekturpositionen: {
                          // Correction Items
                          Ersatzteile: getInvoiceAuditCorrectionItemsGermanInCategory(
                              report.invoiceAudit.correctionItems,
                              'spareParts',
                          ),
                          Lohn: getInvoiceAuditCorrectionItemsGermanInCategory(
                              report.invoiceAudit.correctionItems,
                              'wages',
                          ),
                          Lack: getInvoiceAuditCorrectionItemsGermanInCategory(
                              report.invoiceAudit.correctionItems,
                              'paint',
                          ),
                          Nebenkosten: getInvoiceAuditCorrectionItemsGermanInCategory(
                              report.invoiceAudit.correctionItems,
                              'auxiliaryCosts',
                          ),
                          Sonstiges: getInvoiceAuditCorrectionItemsGermanInCategory(
                              report.invoiceAudit.correctionItems,
                              'otherCosts',
                          ),
                          // Sums
                          ErsatzteileSumme: getTotalOfCorrectionCategory(
                              report.invoiceAudit.correctionItems,
                              'spareParts',
                          ),
                          LohnSumme: getTotalOfCorrectionCategory(report.invoiceAudit.correctionItems, 'wages'),
                          LackSumme: getTotalOfCorrectionCategory(report.invoiceAudit.correctionItems, 'paint'),
                          NebenkostenSumme: getTotalOfCorrectionCategory(
                              report.invoiceAudit.correctionItems,
                              'auxiliaryCosts',
                          ),
                          SonstigesSumme: getTotalOfCorrectionCategory(
                              report.invoiceAudit.correctionItems,
                              'otherCosts',
                          ),
                          GesamtSumme: getTotalOfCorrectionCategory(report.invoiceAudit.correctionItems, null),
                          // Item Count
                          AnzahlPositionen: report.invoiceAudit.correctionItems.length || 0,
                      },
                      KorrigierteReparaturkostenNetto: convertToEuro(report.invoiceAudit.correctedRepairCostsNet),
                      KorrigierteReparaturkostenMwSt: convertToEuro(
                          report.invoiceAudit.correctedRepairCostsGross - report.invoiceAudit.correctedRepairCostsNet,
                      ),
                      KorrigierteReparaturkostenBrutto: convertToEuro(report.invoiceAudit.correctedRepairCostsGross),
                  }
                : null,
            Restwert: {
                KostenBrutto: Translator.emptyValueToEmptyString(
                    report.valuation.residualValue,
                    convertToEuro(report.valuation.residualValue),
                ),
                KostenNetto: Translator.emptyValueToEmptyString(
                    report.valuation.residualValue,
                    convertToEuro(report.valuation.residualValue / 1.19),
                ),
                Kommentar: report.valuation.residualValueRemark,
                AnzahlInserate: numberOfResidualValueOffers,
                AnzahlGebote: numberOfResidualValueBids,
                AnzahlRegionaleGebote: numberOfRegionalBids,
                AnzahlÜberregionaleGebote: numberOfSuperregionalBids,
                AnzahlAusgewählteGebote: numberOfSelectedResidualValueBids,
                KeineGeboteTrotzAnfrage: noResidualValueBidsForOffers,
                wurdeInseratErstellt: numberOfResidualValueOffers > 0,
                wurdeAutoonlineInseratErstellt: hasAutoonlineOfferBeenCreated,
                wurdeCarcasionInseratErstellt: hasCarcasionResidualOfferBeenCreated,
                wurdeCartvInseratErstellt: hasCartvResidualOfferBeenCreated,
                wurdeWinvalueInseratErstellt: hasWinvalueResidualOfferBeenCreated,
                wurdeLokalesRestwertInseratErstellt: hasAutoixpertResidualOfferBeenCreated,
                sindAlleInserateAbgelaufen: allResidualValueOffersExpired,
                Gebote: placeholdersChosenResidualValueBids,
                HöchstesAusgewähltesGebot:
                    selectedBids && selectedBids[0] ? placeholdersChosenResidualValueBids[0] : null,
                WeitereAusgewählteGebote: placeholdersChosenResidualValueBids.slice(1),
                NurRegionaleGebote: !!report.valuation.useRegionalBidsOnly,
                LokaleRestwertanfrage: {
                    AnzahlBieterOhneGebot: numberOfLocalBiddersWithoutBid,
                    AnzahlAngefragteBieter: numberOfLocalInvitedBidders,
                },
            },
            Wiederbeschaffung: {
                Besteuerungsart: Translator.replacementValueTaxation(report.valuation.taxationType),
                SteuerbetragAbsolut: replacementValueTaxAbsolute ? convertToEuro(replacementValueTaxAbsolute) : 0,
                SteuerbetragProzentual: replacementValueTaxPercent,
                Fahrzeuggrundwert: vehicleBaseValue,
                OhneNachkommastellen: omitDecimalsForReplacementValues,
                Abwertungen: decreases,
                Aufwertungen: increases,
                sindKorrekturenVorhanden: replacementValueCorrectionsExist,
                // Retrieve setting from team or report. If set, report setting overrides team setting.
                NurSummeKorrekturenDrucken:
                    report.valuation.replacementValueCorrectionConfig?.printSumOnly != null
                        ? report.valuation.replacementValueCorrectionConfig.printSumOnly
                        : !!team.preferences.replacementValueCorrectionsPrintSumOnly,
                // Use absolute value of the correctionSum because we prepend the - or + sign manually.
                // Otherwise we would not have a plus sign for positive numbers and no space between minus sign
                // and the negative number.
                SummeKorrekturen:
                    correctionsSum != null ? `${correctionsSumPrefix} ${convertToEuro(Math.abs(correctionsSum))}` : '',
                KostenNetto: replacementValueNet != null ? convertToEuro(replacementValueNet) : '',
                KostenBrutto: replacementValueGross != null ? convertToEuro(replacementValueGross) : '',
                DauerInKalendertagen: report.damageCalculation?.replacementTimeInWorkdays || '',
                DauerEinheit:
                    report.damageCalculation?.replacementTimeInWorkdays === '1' ? 'Kalendertag' : 'Kalendertage',
                Kommentar: report.valuation.vehicleValueRemark || '',
                // External sources
                KorridorVon:
                    externalReplacementValueCorridorMinimum != null
                        ? convertToEuro(externalReplacementValueCorridorMinimum)
                        : undefined,
                KorridorBis:
                    externalReplacementValueCorridorMaximum != null
                        ? convertToEuro(externalReplacementValueCorridorMaximum)
                        : undefined,
                KorridorDurchschnitt:
                    externalReplacementValueCorridorAverage != null
                        ? convertToEuro(externalReplacementValueCorridorAverage)
                        : undefined,
                CartvKorridorAbdrucken: !report.valuation?.cartvValuation?.corridorHiddenOnPrintout,
                CartvKorridorVon:
                    report.valuation?.cartvValuation?.minCorridor != null
                        ? convertToEuro(report.valuation.cartvValuation.minCorridor)
                        : undefined,
                CartvKorridorBis:
                    report.valuation?.cartvValuation?.maxCorridor != null
                        ? convertToEuro(report.valuation.cartvValuation.maxCorridor)
                        : undefined,
                CartvDurchschnitt:
                    report.valuation?.cartvValuation?.averagePrice != null
                        ? convertToEuro(report.valuation.cartvValuation.averagePrice)
                        : undefined,
                CartvSuchradius: report.valuation?.cartvValuation?.searchRadiusInKm
                    ? `${formatNumberToGermanLocale(report.valuation?.cartvValuation.searchRadiusInKm || 0, { decimalPlaces: 0 })} km`
                    : '',
                ValuepilotKorridorAbdrucken: !report.valuation?.valuepilotValuation?.corridorHiddenOnPrintout,
                ValuepilotKorridorVon:
                    report.valuation?.valuepilotValuation?.minCorridor != null
                        ? convertToEuro(report.valuation.valuepilotValuation.minCorridor)
                        : undefined,
                ValuepilotKorridorBis:
                    report.valuation?.valuepilotValuation?.maxCorridor != null
                        ? convertToEuro(report.valuation.valuepilotValuation.maxCorridor)
                        : undefined,
                ValuepilotSuchradius: report.valuation?.valuepilotValuation?.searchRadiusInKm
                    ? `${formatNumberToGermanLocale(report.valuation?.valuepilotValuation.searchRadiusInKm || 0, { decimalPlaces: 0 })} km`
                    : '',

                WinValueKorridorAbdrucken: !report.valuation?.winvalueValuation?.corridorHiddenOnPrintout,
                WinValueKorridorVon:
                    report.valuation?.winvalueValuation?.minCorridor != null
                        ? convertToEuro(report.valuation.winvalueValuation.minCorridor)
                        : undefined,
                WinValueKorridorBis:
                    report.valuation?.winvalueValuation?.maxCorridor != null
                        ? convertToEuro(report.valuation.winvalueValuation.maxCorridor)
                        : undefined,
                WinValueDurchschnitt:
                    report.valuation?.winvalueValuation?.averagePrice != null
                        ? convertToEuro(report.valuation.winvalueValuation.averagePrice)
                        : undefined,
                WinValueSuchradius: report.valuation?.winvalueValuation?.searchRadiusInKm
                    ? `${formatNumberToGermanLocale(report.valuation?.winvalueValuation.searchRadiusInKm || 0, { decimalPlaces: 0 })} km`
                    : '',
                DatKorridorAbdrucken: !report.valuation?.datValuation?.corridorHiddenOnPrintout,
                DatWert: datReplacementValue != null ? convertToEuro(datReplacementValue) : undefined,
                DatWebScan:
                    report.valuation?.datValuation?.webscanGross != null
                        ? convertToEuro(report.valuation.datValuation.webscanGross)
                        : undefined,
                AudatexKorridorAbdrucken: !report.valuation?.audatexValuation?.corridorHiddenOnPrintout,
                AudatexWert: audatexReplacementValue != null ? convertToEuro(audatexReplacementValue) : undefined,
                AudatexNeupreisBrutto: report.valuation?.audatexValuation?.originalPriceWithEquipmentGross
                    ? convertToEuro(report.valuation.audatexValuation.originalPriceWithEquipmentGross)
                    : undefined,
                EigeneMarktwertanalysen: customMarketAnalyses ?? [],
                AnzahlMarktwertanalysen: potentialReplacementValueAverages.length + (customMarketAnalyses?.length || 0),
            },
            Wiederbeschaffungsaufwand: replacementEffort,
            WiederherstellungsaufwandDurchWiederbeschaffungswertProzent: replacementValueGross
                ? (
                      ((mayCarOwnerDeductTaxes(report) ? restorationEffortNet : restorationEffortGross) /
                          replacementValueWithoutTaxForCompanies) *
                      100
                  ).toFixed(0)
                : '',
            Nutzungsausfall: {
                Art: downtimeType,
                Entschädigungsgruppe: report.damageCalculation?.downtimeCompensationGroup || '',
                EntschädigungsgruppeVorAlterskorrektur:
                    report.damageCalculation?.originalDowntimeCompensationGroup || '',
                EntschädigungProTag: Translator.emptyValueToEmptyString(
                    report.damageCalculation?.downtimeCompensationPerWorkday,
                    convertToEuro(report.damageCalculation?.downtimeCompensationPerWorkday),
                ),
                Dauer: downtime || '',
                DauerEinheit: downtimeUnit,
                Mietwagenklasse: report.damageCalculation?.rentalCarClass || '',
                MietwagenklasseName: report.damageCalculation?.rentalCarClass
                    ? getRentalCarClasses().find(
                          (rentalCarClass) => rentalCarClass.id === report.damageCalculation.rentalCarClass,
                      ).description || ''
                    : '',
                MietwagenklasseKosten: Translator.emptyValueToEmptyString(
                    report.damageCalculation?.rentalCarClassCostsPerDay,
                    convertToEuro(report.damageCalculation?.rentalCarClassCostsPerDay),
                ),
                Kommentar: report.damageCalculation?.downtimeCompensationComment || '',
            },
            Vorhaltekosten: {
                aktiviert: !!report.damageCalculation?.useProvisioningCosts,
                KostenNetto: report.damageCalculation?.provisioningCosts
                    ? convertToEuro(report.damageCalculation.provisioningCosts)
                    : 0,
            },
            Honorar: {
                BetragNetto: Translator.emptyValueToEmptyString(
                    report.feeCalculation.assessorsFee,
                    convertToEuro(report.feeCalculation.assessorsFee),
                ),
                BetragBrutto: Translator.emptyValueToEmptyString(
                    report.feeCalculation.assessorsFee * (1 + feeTableVatRate),
                    convertToEuro(report.feeCalculation.assessorsFee * (1 + feeTableVatRate)),
                ),

                Fotokosten: Translator.emptyValueToEmptyString(photoFees, convertToEuro(photoFees)),
                FotokostenSindPauschal: report.feeCalculation.usePhotosFixedPrice,
                FotokostenPauschalNetto: convertToEuro(report.feeCalculation.photosFixedPrice),
                FotokostenPauschalBrutto: convertToEuro(report.feeCalculation.photosFixedPrice * (1 + feeTableVatRate)),
                PreisProFotoNetto: convertToEuro(report.feeCalculation.pricePerPhoto),
                PreisProFotoBrutto: convertToEuro(report.feeCalculation.pricePerPhoto * (1 + feeTableVatRate)),

                ZweiterFotosatz: report.feeCalculation.secondPhotoSet,
                PreisProZweitFotoNetto: convertToEuro(report.feeCalculation.pricePerSecondPhoto),
                PreisProZweitFotoBrutto: convertToEuro(
                    report.feeCalculation.pricePerSecondPhoto * (1 + feeTableVatRate),
                ),
                PreisZweitFotoPauschalNetto: convertToEuro(report.feeCalculation.secondPhotoSetFixedPrice),
                PreisZweitFotoPauschalBrutto: convertToEuro(
                    report.feeCalculation.secondPhotoSetFixedPrice * (1 + feeTableVatRate),
                ),

                Fahrtkosten: Translator.emptyValueToEmptyString(travelExpenses, convertToEuro(travelExpenses)),
                FahrtkostenSindPauschal: report.feeCalculation.useTravelExpensesFixedPrice,
                FahrtkostenPauschalNetto: convertToEuro(report.feeCalculation.travelExpensesFixedPrice),
                FahrtkostenPauschalBrutto: convertToEuro(
                    report.feeCalculation.travelExpensesFixedPrice * (1 + feeTableVatRate),
                ),
                PreisProKilometerNetto: convertToEuro(report.feeCalculation.pricePerKilometer),
                PreisProKilometerBrutto: convertToEuro(report.feeCalculation.pricePerKilometer * (1 + feeTableVatRate)),

                Schreibkosten: Translator.emptyValueToEmptyString(writingFees, convertToEuro(writingFees)),
                SchreibkostenSindPauschal: report.feeCalculation.useWritingFeesFixedPrice,
                SchreibkostenPauschalNetto: convertToEuro(report.feeCalculation.writingFeesFixedPrice),
                SchreibkostenPauschalBrutto: convertToEuro(
                    report.feeCalculation.writingFeesFixedPrice * (1 + feeTableVatRate),
                ),
                PreisProSeiteNetto: convertToEuro(report.feeCalculation.pricePerPage),
                PreisProSeiteBrutto: convertToEuro(report.feeCalculation.pricePerPage * (1 + feeTableVatRate)),
                AnzahlSeiten: report.feeCalculation.numberOfPages,

                SchreibkostenKopie: report.feeCalculation.useWritingCopyFees,
                SchreibkostenKopiePauschalNetto: convertToEuro(report.feeCalculation.writingCopyFeesFixedPrice),
                SchreibkostenKopiePauschalBrutto: convertToEuro(
                    report.feeCalculation.writingCopyFeesFixedPrice * (1 + feeTableVatRate),
                ),
                PreisProSeiteKopieNetto: convertToEuro(report.feeCalculation.pricePerPageCopy),
                PreisProSeiteKopieBrutto: convertToEuro(report.feeCalculation.pricePerPageCopy * (1 + feeTableVatRate)),

                PortoUndTelefonNetto: convertToEuro(report.feeCalculation.postageAndPhoneFees),
                PortoUndTelefonBrutto: convertToEuro(report.feeCalculation.postageAndPhoneFees * (1 + feeTableVatRate)),

                SonstigeKosten: Translator.emptyValueToEmptyString(otherFees, convertToEuro(otherFees)),
                HatSonstigeKosten: otherFeesLineItemsNetto.length > 0,
                SonstigeKostenPositionenNetto: otherFeesLineItemsNetto.join('\n'),
                SonstigeKostenPositionenBrutto: otherFeesLineItemsBrutto.join('\n'),

                HonorartabelleSpalteA: feeSetTableColumnA,
                HonorartabelleSpalteB: feeSetTableColumnB,
                HonorartabelleName: Translator.assessorFeeTable(report.feeCalculation, customFeeSets),
                Jahr: report.feeCalculation.yearOfFeeTable ? report.feeCalculation.yearOfFeeTable + '' : '', // Date format: YYYY
                Grundlage: Translator.emptyValueToEmptyString(assessorsFeeBase, convertToEuro(assessorsFeeBase)),
            },
            Abtretung: {
                Datum: claimantSignatureDate ? toGermanDate(claimantSignatureDate) : '',
            },
            Fotos: photosPlaceholderValues,
            FotosSpalteA: photosColumnA,
            FotosSpalteB: photosColumnB,
            // True if the assessor creates a lease return report with photos inlined in the items (Prüfpositionen) table.
            sindFotosInPrüfpositionen: photosInLeaseReturnItems.length > 0,
            Anschreiben: {
                Absender: assessorPlaceholderObject,
                Empfänger: {
                    ...claimantContactPersonPlaceholderObject,
                    Typ: Translator.letterRecipient('claimant'),
                },
                Datum: reportCompletionDateFormatted,
                Betreff: '',
                Inhalt: '',
            },
            OnlineUnterschrift: {
                Link: `https://unterschrift.autoixpert.de/Gutachten/${report._id}?token=${report.remoteSignatureConfig?.authenticationToken}`,
                Frist: report.remoteSignatureConfig?.signatureDeadlineAt
                    ? toGermanDate(report.remoteSignatureConfig?.signatureDeadlineAt)
                    : '',
            },
            // The checkmarks used for equipment in the document building block "Ausstattung" are rendered via the image module. They need a value, so we chose the random value 1.
            check: 1,
            // The arrow images used to indicate increase or decrease in the invoice audit summary.
            arrowUpRed: 1,
            arrowDownGreen: 1,
        };
    } catch (error) {
        throw new ServerError({
            code: 'GENERATING_PLACEHOLDERS_FAILED',
            message: `Generating template placeholders failed with a general error.`,
            data: {},
            error,
        });
    }

    return data;
    //*****************************************************************************
    //  Define Placeholders & Replacements
    //****************************************************************************/
}

//*****************************************************************************
//  Invoice Audit
//****************************************************************************/
function getInvoiceAuditRowPlaceholders(row: InvoiceAuditComparisonRow): InvoiceAuditRow {
    return {
        GeplanteKosten: convertToEuro(row.projectedRepairCosts ?? 0),
        AbgerechneteKosten: convertToEuro(row.actualRepairCosts ?? 0),
        Differenz: convertToEuro(row.difference ?? 0),
        SummeKorrekturen: convertToEuro(row.correctedAmount ?? 0),
        DifferenzFloat: row.difference,
        SummeKorrekturenFloat: row.correctedAmount,
    };
}

function getInvoiceAuditCorrectionItemsInCategory(
    items: CorrectionItem[],
    category: InvoiceAuditCategory,
): CorrectionItem[] {
    if (!category) {
        return items;
    }
    return items.filter((item) => item.category === category);
}

function getInvoiceAuditCorrectionItemsGermanInCategory(
    items: CorrectionItem[],
    category: InvoiceAuditCategory,
): InvoiceAuditCorrectionItemGerman[] {
    return getInvoiceAuditCorrectionItemsInCategory(items, category).map((item) => {
        let categoryGerman: InvoiceAuditCorrectionItemGerman['Kategorie'];
        switch (item.category) {
            case 'wages':
                categoryGerman = 'Lohn';
                break;
            case 'spareParts':
                categoryGerman = 'Ersatzteile';
                break;
            case 'paint':
                categoryGerman = 'Lack';
                break;
            case 'auxiliaryCosts':
                categoryGerman = 'Nebenkosten';
                break;
            case 'otherCosts':
                categoryGerman = 'Sonstiges';
                break;
        }

        return {
            Position: item.title || '',
            Kategorie: categoryGerman,
            Kommentar: item.comment || '',
            Betrag: convertToEuro(item.correctionAmount ?? 0),
        };
    });
}

function getTotalOfCorrectionCategory(items: CorrectionItem[], category: InvoiceAuditCategory): CurrencyGerman {
    const total: number = getInvoiceAuditCorrectionItemsInCategory(items, category).reduce(
        (total, item) => total + (item.correctionAmount ? item.correctionAmount : 0),
        0,
    );
    return total ? convertToEuro(total) : convertToEuro(0);
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Invoice Audit
/////////////////////////////////////////////////////////////////////////////*/

/**
 * Human-readable version of the CorrectionItemRepairExecutionType that is printed in the report.
 */
function translateRepairExecutionType(repairExecutionType: CorrectionItemRepairExecutionType) {
    let repairExecutionTypeGerman = '';

    switch (repairExecutionType) {
        case 'repaired':
            repairExecutionTypeGerman = 'Repariert';
            break;
        case 'unprofessional':
            repairExecutionTypeGerman = 'Nicht sach- & fachgerecht repariert';
            break;
        case 'partial':
            repairExecutionTypeGerman = 'Teilweise repariert';
            break;
        case 'none':
            repairExecutionTypeGerman = 'Unrepariert';
            break;
    }

    return repairExecutionTypeGerman;
}

import { get } from 'lodash-es';
import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import {
    PlaceholderValueLeafDefinition,
    PlaceholderValueLeafType,
    PlaceholderValueTree,
    getPlaceholderValueIsHTMLFromLeaf,
    getPlaceholderValueTree,
    getPlaceholderValueTypeFromLeaf,
} from '../placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '../placeholder-values/get-placeholder-values';
import { convertHtmlToPlainText } from '../placeholder-values/strip-html';

// Match all placeholders (ungreedy)
export const placeholderRegEx = /{[^%].+?}/g;
// Use this to remove the "{}" from placeholder matches to get the path.
export const extractPathRegEx = /[{}]/g;

/**
 * Find and replace placeholders.
 *
 * Either the generated placeholder value tree may be passed or the field group configs
 * which allow generating the placeholder value tree on the fly.
 * Which value is available depends on the context from which this function is called.
 */
export function replacePlaceholders({
    textWithPlaceholders,
    placeholderValues,
    placeholderValueTree,
    isHtmlAllowed,
}: {
    textWithPlaceholders: string;
    placeholderValues: PlaceholderValues;
    placeholderValueTree: PlaceholderValueTree;
    isHtmlAllowed?: boolean;
}): string;
export function replacePlaceholders({
    textWithPlaceholders,
    placeholderValues,
    fieldGroupConfigs,
    isHtmlAllowed,
}: {
    textWithPlaceholders: string;
    placeholderValues: PlaceholderValues;
    fieldGroupConfigs: FieldGroupConfig[];
    isHtmlAllowed?: boolean;
}): string;
export function replacePlaceholders({
    textWithPlaceholders,
    placeholderValues,
    placeholderValueTree,
    fieldGroupConfigs,
    isHtmlAllowed = true,
}: {
    textWithPlaceholders: string;
    placeholderValues: PlaceholderValues;
    placeholderValueTree?: PlaceholderValueTree;
    fieldGroupConfigs?: FieldGroupConfig[];
    isHtmlAllowed?: boolean;
}): string {
    if (!textWithPlaceholders) {
        return '';
    }
    if (!placeholderValues) {
        //console.log("Won't use empty template placeholder values.");
        // console.trace();
        console.log('Returned text with placeholders because placeholder value tree is unavailable.');
        return textWithPlaceholders;
    }

    if (typeof textWithPlaceholders !== 'string') {
        throw new Error(
            `Invalid data type for replacing placeholder values. This needs to be a string but is of type "${typeof textWithPlaceholders}".`,
        );
    }

    const placeholderMatches = textWithPlaceholders.match(placeholderRegEx);
    if (placeholderMatches) {
        if (!placeholderValueTree) {
            placeholderValueTree = getPlaceholderValueTree({ fieldGroupConfigs });
        }
        for (const matchedPlaceholder of placeholderMatches) {
            const path = matchedPlaceholder.replace(extractPathRegEx, '');

            // Substitute value is used either with HTML for HTML-containing fields or without red highlights for single-line fields.
            const substituteValueForEmptyPlaceholders = isHtmlAllowed
                ? `<span style="color:red;">WERT_${path || ''}_FEHLT</span>`
                : `WERT_${path || ''}_FEHLT`;

            // Only substitute really empty values, don't replace zero and false.
            const placeholderValueAnyFormat: any = get(placeholderValues, path);
            //*****************************************************************************
            //  Ensure Placeholder Value is a String
            //****************************************************************************/
            // We derived the type definition "PlaceholderValueLeafType | PlaceholderValueLeafDefinition" because the path always points to a leaf. Paths to nodes are simply not allowed.
            const placeholderValueTreeLeaf: PlaceholderValueLeafType | PlaceholderValueLeafDefinition = get(
                placeholderValueTree,
                path,
            ) as any;
            const placeholderValueType: PlaceholderValueLeafType | PlaceholderValueLeafType[] =
                getPlaceholderValueTypeFromLeaf(placeholderValueTreeLeaf);

            let placeholderValue: string;

            if (!isHtmlAllowed && getPlaceholderValueIsHTMLFromLeaf(placeholderValueTreeLeaf)) {
                /**
                 * Convert html to plain text
                 */
                placeholderValue = convertHtmlToPlainText(placeholderValueAnyFormat);
            } else if (placeholderValueType === 'Boolean') {
                if (placeholderValueAnyFormat === true) {
                    placeholderValue = 'Ja';
                } else {
                    /**
                     * False, undefined, null become "Nein".
                     */
                    placeholderValue = 'Nein';
                }
            } else if (Array.isArray(placeholderValueAnyFormat)) {
                /**
                 * E.g. relevant for placeholder "Schadenskalkulation.Minderwert.MethodenNamen".
                 */
                placeholderValue = placeholderValueAnyFormat.join(', ');
            } else {
                /**
                 * Number, string, tristate (contains "Ja", "Nein", "unbekannt" values), ...
                 */
                if (placeholderValueAnyFormat == null || placeholderValueAnyFormat === '') {
                    placeholderValue = substituteValueForEmptyPlaceholders;
                } else {
                    // If the placeholder contains a link, insert an anchor tag.
                    if (typeof placeholderValueAnyFormat === 'string' && placeholderValueAnyFormat.startsWith('http')) {
                        placeholderValue = `<a href="${placeholderValueAnyFormat}" target="_blank">${placeholderValueAnyFormat}</a>`;
                    }
                    // Replace \n with <br> for multiline text if HTML is required.
                    else if (
                        isHtmlAllowed &&
                        typeof placeholderValueAnyFormat === 'string' &&
                        placeholderValueAnyFormat.includes('\n')
                    ) {
                        placeholderValue = placeholderValueAnyFormat.replaceAll(/\n/g, '<br>');
                    } else {
                        placeholderValue = `${placeholderValueAnyFormat}`;
                    }
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Ensure Placeholder Value is a String
            /////////////////////////////////////////////////////////////////////////////*/

            textWithPlaceholders = textWithPlaceholders.replace(matchedPlaceholder, placeholderValue);
        }
    }
    return textWithPlaceholders;
}

/**
 * This function gets the value for a specific placeholder.
 * It is extracted from the logic above and used in specific use cases,
 * e.g. if a former missing placeholder is replaced in a letter.
 * If no value is found, returns null as default value
 * TODO: merge this code into the logic above
 */
export function getValueForPlaceholder({
    placeholderValues,
    placeholderValueTree,
    matchedPlaceholder,
}: {
    placeholderValues: PlaceholderValues;
    placeholderValueTree: PlaceholderValueTree;
    matchedPlaceholder: string;
}) {
    // Only substitute really empty values, don't replace zero and false.
    const placeholderValueAnyFormat: any = get(placeholderValues, matchedPlaceholder);
    //*****************************************************************************
    //  Ensure Placeholder Value is a String
    //****************************************************************************/
    // We derived the type definition "PlaceholderValueLeafType | PlaceholderValueLeafDefinition" because the path always points to a leaf. Paths to nodes are simply not allowed.
    const placeholderValueTreeLeaf: PlaceholderValueLeafType | PlaceholderValueLeafDefinition = get(
        placeholderValueTree,
        matchedPlaceholder,
    ) as any;
    const placeholderValueType: PlaceholderValueLeafType | PlaceholderValueLeafType[] =
        getPlaceholderValueTypeFromLeaf(placeholderValueTreeLeaf);

    let placeholderValue: string;
    if (placeholderValueType === 'Boolean') {
        if (placeholderValueAnyFormat === true) {
            placeholderValue = 'Ja';
        } else {
            // False, undefined, null become "Nein".
            placeholderValue = 'Nein';
        }
    } else if (Array.isArray(placeholderValueAnyFormat)) {
        // E.g. relevant for placeholder "Schadenskalkulation.Minderwert.MethodenNamen".
        placeholderValue = placeholderValueAnyFormat.join(', ');
    } else {
        // Number, string, tristate (contains "Ja", "Nein", "unbekannt" values), ...
        if (placeholderValueAnyFormat == null || placeholderValueAnyFormat === '') {
            placeholderValue = null;
        } else {
            placeholderValue = `${placeholderValueAnyFormat}`;
        }
    }
    return placeholderValue;
}

import {
    DocumentBuildingBlockConditionGroup,
    DocumentBuildingBlockConditionGroupOperator,
} from '@autoixpert/models/documents/document-building-block-condition-group';
import { DocumentBuildingBlockCondition } from '../../models/documents/document-building-block-condition';
import { AxError } from '../../models/errors/ax-error';
import { PlaceholderValueTree } from '../placeholder-values/get-placeholder-value-tree';
import { PlaceholderValues } from '../placeholder-values/get-placeholder-values';
import { isBuildingBlockConditionMet } from './choose-document-building-blocks';

/**
 * Filter elements based on their conditions, compared with given placeholder values.
 */
export function matchConditions<
    T extends {
        conditionsOperator?: DocumentBuildingBlockConditionGroupOperator;
        conditions: Array<DocumentBuildingBlockCondition | DocumentBuildingBlockConditionGroup>;
    },
>({
    elementsWithConditions,
    placeholderValues,
    placeholderValueTree,
}: {
    elementsWithConditions: T[];
    placeholderValues: PlaceholderValues;
    placeholderValueTree: PlaceholderValueTree;
}): T[] {
    return elementsWithConditions.filter((element) => {
        // If T.conditions is an empty array, every() always returns true. So, as expected, no conditions will cause an element to always be chosen.
        const conditionsArrayConcatOperator = element.conditionsOperator === 'or' ? 'some' : 'every';
        return element.conditions[conditionsArrayConcatOperator]((condition) => {
            try {
                return isBuildingBlockConditionMet({
                    placeholderValues,
                    condition,
                    placeholderValueTree,
                });
            } catch (error) {
                // Enhance the error object with relevant data that help the user debug the invalid condition.
                if (!error.data) {
                    error.data = {};
                }
                error.data.element = element;
                error.data.condition = condition;

                throw new AxError({
                    code: 'MATCHING_CONDITION_FAILED',
                    message: `The element could not be chosen because evaluating the conditions failed.`,
                    error,
                });
            }
        });
    });
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Check Conditions
    /////////////////////////////////////////////////////////////////////////////*/
}

import { Valuation } from '../../models/reports/market-value/valuation';

export function getVehicleValueGross(valuation: Valuation, valueType: Valuation['vehicleValueType']): number {
    if (valuation.vehicleValueType === valueType) {
        return valuation.vehicleValueGross;
    } else if (valuation.secondVehicleValueType === valueType) {
        return valuation.secondVehicleValueGross;
    } else {
        return;
    }
}

export function getVehicleValueNet(valuation: Valuation, valueType: Valuation['vehicleValueType']): number {
    if (valuation.vehicleValueType === valueType) {
        return valuation.vehicleValueNet;
    } else if (valuation.secondVehicleValueType === valueType) {
        return valuation.secondVehicleValueNet;
    } else {
        return;
    }
}

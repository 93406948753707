<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <!--============================================-->
        <!-- Left Column - Settings -->
        <!--============================================-->
        <section id="left-column">
            <h2>Einstellungen</h2>

            <mat-slide-toggle [(ngModel)]="userPreferences.taskList_showBadgeOfDueTasks">
                Offene Aufgaben in der Navigation anzeigen
            </mat-slide-toggle>
        </section>
        <!--============================================-->
        <!-- END Left Column - Settings -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column - Working Week -->
        <!--============================================-->
        <section id="right-column">
            <h2>Arbeitszeit</h2>
            <div class="label text-align-center">
                Lege fest, bis wann deine Kapazitätsanzeige im grünen Bereich ist.
                <span
                    class="help-indicator"
                    matTooltip="Die Kapazitätsanzeige findest du in der Aufgabenliste oben rechts. Sie zeigt deine Auslastung heute und für deine nächsten zwei Arbeitstage an.">
                    ?
                </span>
            </div>

            <div id="workday-settings">
                <!--********** Monday **********-->
                <workday-settings-column
                    [workdaySettings]="userPreferences.taskList_workweekSettings.monday"
                    (workdaySettingsChange)="saveUserPreferences()"></workday-settings-column>

                <!--********** Tuesday **********-->
                <workday-settings-column
                    [workdaySettings]="userPreferences.taskList_workweekSettings.tuesday"
                    (workdaySettingsChange)="saveUserPreferences()"></workday-settings-column>

                <!--********** Wednesday **********-->
                <workday-settings-column
                    [workdaySettings]="userPreferences.taskList_workweekSettings.wednesday"
                    (workdaySettingsChange)="saveUserPreferences()"></workday-settings-column>

                <!--********** Thursday **********-->
                <workday-settings-column
                    [workdaySettings]="userPreferences.taskList_workweekSettings.thursday"
                    (workdaySettingsChange)="saveUserPreferences()"></workday-settings-column>

                <!--********** Friday **********-->
                <workday-settings-column
                    [workdaySettings]="userPreferences.taskList_workweekSettings.friday"
                    (workdaySettingsChange)="saveUserPreferences()"></workday-settings-column>

                <!--********** Saturday **********-->
                <workday-settings-column
                    [workdaySettings]="userPreferences.taskList_workweekSettings.saturday"
                    (workdaySettingsChange)="saveUserPreferences()"></workday-settings-column>

                <!--********** Sunday **********-->
                <workday-settings-column
                    [workdaySettings]="userPreferences.taskList_workweekSettings.sunday"
                    (workdaySettingsChange)="saveUserPreferences()"></workday-settings-column>
            </div>
        </section>
        <!--============================================-->
        <!-- END Right Column - Working Week -->
        <!--============================================-->
    </div>
</div>

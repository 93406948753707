import { Component, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { User } from '@autoixpert/models/user/user';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'ax-password-dialog',
    templateUrl: 'ax-password-dialog.component.html',
    styleUrls: ['ax-password-dialog.component.scss'],
})
export class AxPasswordDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<AxPasswordDialogComponent>,
        private toastService: ToastService,
        private authenticationService: AuthenticationService,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
    ) {}

    @Input() editedUser: User;
    loggedInUser: User;

    previousPassword: string = null;
    newPassword: string = null;

    previousPasswordShown: boolean = false;
    newPasswordShown: boolean = false;

    warning: string = null;
    loading: boolean = false;

    ngOnInit() {
        this.loggedInUser = this.loggedInUserService.getUser();
        this.editedUser ??= this.loggedInUser;
    }

    public togglePreviousPassword(): void {
        this.previousPasswordShown = !this.previousPasswordShown;
    }

    public toggleNewPassword(): void {
        this.newPasswordShown = !this.newPasswordShown;
    }

    /**
     * Save new password to the server.
     *
     * If previousPassword is wrong, display a warning.
     * If everything is correct, close the dialog.
     */
    public async saveNewPassword(): Promise<void> {
        // Don't allow updates if the form is incomplete.
        if (!this.previousPassword || !this.newPassword || this.previousPassword === this.newPassword) {
            return;
        }

        this.warning = null;
        this.loading = true;

        this.editedUser._documentVersion++;

        try {
            await this.userService.updatePassword(this.editedUser, this.previousPassword, this.newPassword).toPromise();
        } catch (error) {
            switch (error.code) {
                case 'OLD_PASSWORD_MISSING':
                    this.warning = 'Das alte Passwort darf nicht leer sein.';
                    break;
                case 'WRONG_OLD_PASSWORD':
                    this.warning = 'Das alte Passwort ist nicht korrekt.';
                    break;
                default:
                    this.toastService.error('Neues Passwort konnte nicht gesetzt werden');
                    console.error('ERR_PASSWORD_NOT_SET', { error });
            }
            this.loading = false;
            this.editedUser._documentVersion--;
            return;
        }

        // Only log in again if the password was changed for the currently logged-in user.
        if (!this.isUpdateForOtherUser()) {
            try {
                // After the password was changed, this JWT is invalid. Get a new JWT immediately.
                await this.authenticationService.tryAuthentication({
                    strategy: 'local',
                    email: this.loggedInUser.email,
                    password: this.newPassword,
                });
            } catch (error) {
                console.error('Error authenticating after password change.', { error });
                this.toastService.error(
                    'Zugangsdaten ungültig',
                    "Das neue Passwort wurde zwar gesetzt, aber eine Anmeldung mit den neuen Zugangsdaten ist nicht möglich. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                );
                return;
            }
        }

        // Close the dialog and show a success message.
        this.loading = false;
        this.dialogRef.close();
        this.toastService.success('Passwort gespeichert.');

        // Persist increase of _documentVersion.
        await this.saveUser();
    }

    protected isUpdateForOtherUser(): boolean {
        return this.loggedInUser?._id !== this.editedUser?._id;
    }

    public async saveUser(
        user: User = this.editedUser,
        { waitForServer }: { waitForServer?: boolean } = {},
    ): Promise<void> {
        // If the logged-in user has been updated, propagate those changes to all components.
        if (user._id === this.loggedInUser._id) {
            this.loggedInUserService.setUser(user);
        }
        try {
            await this.userService.put(user, { waitForServer });
        } catch (error) {
            this.toastService.error('Fehler beim Speichern');
        }
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    /**
     * The tooltip for the save button depends on an array
     * of parameters. This method sets the right tooltip text.
     */
    public getSaveButtonTooltip(): string {
        if (!this.previousPassword || !this.newPassword) {
            return 'Bitte beide Passwortfelder ausfüllen';
        }

        /**
         * If the password is set for the currently logged-in user, ensure it's different from the previous one.
         *
         * If the password is set for a different user, this check is not necessary.
         */
        if (this.editedUser?._id === this.loggedInUser._id && this.previousPassword === this.newPassword) {
            return 'Das neue Passwort muss sich vom alten unterscheiden';
        }
    }

    public triggerFormOnEnterKey(event: KeyboardEvent): void {
        if (event.key === 'Enter' && event.ctrlKey) {
            this.saveNewPassword();
        }
    }
}

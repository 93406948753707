<!--============================================-->
<!-- Photo navigation
---=============================================-->
<div id="photos-list-toolbar-container" *ngIf="gridToolbarShown">
    <div id="photos-list-toolbar-left-side">
        <!-- Name first photos -->
        <div id="name-first-photos-container" class="card" *ngIf="photos.length">
            <div
                id="name-first-photos"
                class="label"
                [class.disabled]="disabled"
                (click)="!disabled && addDescriptionsToFirstPhotos()"
                matTooltip="Die ersten Fotos automatisch benennen. Die Beschreibungen sind anpassbar.">
                <mat-icon id="name-first-photos-icon" class="medium-icon">text_rotation_none</mat-icon>
                <span id="name-first-photos-button-label">Beschreibungen einfügen</span>
            </div>
            <mat-icon
                id="name-first-photos-confirmation-icon"
                class="medium-icon"
                [ngClass]="{ visible: photosJustNamed.length }">
                check
            </mat-icon>
            <mat-icon class="toolbar-icon medium-icon" [matMenuTriggerFor]="defaultPhotoDescriptionsSubmenu">
                more_vert
            </mat-icon>
            <mat-menu #defaultPhotoDescriptionsSubmenu>
                <a
                    mat-menu-item
                    (click)="openDefaultPhotoDescriptionsEditor()"
                    matTooltip="Standard-Fotobeschreibungen bearbeiten">
                    <mat-icon>edit</mat-icon>
                    <span>Beschreibungen bearbeiten</span>
                </a>
                <a
                    mat-menu-item
                    (click)="!disabled && convertFilenameToDescription()"
                    [class.disabled]="disabled"
                    matTooltip="Den Dateinamen als Beschreibung einfügen{{ '\n' }}'Vorne links.jpg' > 'Vorne links'{{
                        '\n\n'
                    }}Gilt nur für Fotos ohne in autoiXpert hinzugefügte Beschreibung.{{
                        '\n\n'
                    }}Relevant, falls über den Dateinamen beschriebene Fotos vorliegen, z. B. von der Werkstatt oder einem externen Sachverständigen.">
                    <mat-icon>text_rotation_none</mat-icon>
                    <span>Dateiname zu Beschreibung</span>
                </a>
                <a
                    mat-menu-item
                    (click)="togglePermanentDescription()"
                    matTooltip="Zeige die Bildbeschreibung dauerhaft für alle Bilder an">
                    <mat-icon>push_pin</mat-icon>
                    <span *ngIf="!userPreferences.alwaysDisplayPhotoDescription">Beschreibung immer anzeigen</span>
                    <span *ngIf="userPreferences.alwaysDisplayPhotoDescription">Beschreibung nicht fixieren</span>
                </a>
            </mat-menu>
        </div>

        <!-- License plate redaction -->
        <div id="license-plate-redaction-container" class="card" *ngIf="photos.length">
            <div
                id="license-plate-redaction"
                class="label"
                [class.disabled]="disabled"
                (click)="!disabled && redactLicensePlates()"
                matTooltip="Erstelle Vorschläge zum Schwärzen der Kennzeichen für alle Fotos. Wähle eine Teilmenge der Fotos vorher aus, um nur für diese Fotos Vorschläge zu erstellen.">
                <mat-icon id="license-plate-redaction-icon" class="medium-icon">graphic_eq</mat-icon>
                <span id="license-plate-redaction-button-label">Kennzeichen schwärzen</span>
            </div>

            <mat-icon
                *ngIf="!licensePlateRedactionsQueued.length && !licensePlateRedactionsModelLoading"
                class="toolbar-icon medium-icon"
                [matMenuTriggerFor]="licensePlateRedactionSubmenu">
                more_vert
            </mat-icon>

            <input
                #licensePlatRedactionColorpicker
                type="color"
                class="license-plate-redaction-color-input"
                [ngModel]="team.preferences.licensePlateRedactionColor"
                (ngModelChange)="setLicensePlateRedactionColor($event)" />

            <mat-menu #licensePlateRedactionSubmenu>
                <a
                    mat-menu-item
                    class="license-plate-redaction-color-menu-item"
                    [ngClass]="{ disabled: !isAdmin() }"
                    [matTooltip]="!isAdmin() ? 'Nur Administratoren können die Farbe einstellen.' : ''"
                    (click)="openLicensePlateRedactionColorPicker($event)">
                    <svg
                        class="icon"
                        [attr.fill]="'#A1A1A1'"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>

                    <span>Farbe verwenden</span>

                    <div class="license-plate-redaction-check-icon">
                        <mat-icon *ngIf="!!team.preferences.licensePlateRedactionColor">check</mat-icon>
                    </div>
                </a>

                <!--                <a-->
                <!--                    mat-menu-item-->
                <!--                    [ngClass]="{ disabled: !isAdmin() }"-->
                <!--                    (click)="setLicensePlateRedactionBlur()"-->
                <!--                    [matTooltip]="!isAdmin() ? 'Nur Administratoren können die Unschärfe einstellen.' : ''">-->
                <!--                    <mat-icon>blur_on</mat-icon>-->
                <!--                    <span>Unschärfe verwenden</span>-->

                <!--                    <div class="license-plate-redaction-check-icon">-->
                <!--                        <mat-icon *ngIf="!team.preferences.licensePlateRedactionColor">check</mat-icon>-->
                <!--                    </div>-->
                <!--                </a>-->

                <a mat-menu-item (click)="showLicensePlateRedactionTutorial()">
                    <mat-icon class="outlined">help</mat-icon>
                    <span>Erklärung anzeigen</span>
                </a>
            </mat-menu>

            <!-- License plate redaction model loading indicator -->
            <div
                *ngIf="licensePlateRedactionsModelLoading"
                class="license-plate-redaction-model-loading-indicator label">
                <span id="license-plate-redaction-button-label">Initialisierung</span>
                <mat-spinner [diameter]="15"></mat-spinner>
            </div>

            <!-- License plate redaction progress indicator-->
            <div
                class="license-plate-redaction-progress-container"
                (click)="cancelLicensePlateRedactions()"
                *ngIf="licensePlateRedactionsQueued.length && !licensePlateRedactionsModelLoading">
                <div class="slim-progress-bar-row" @fadeInAndOut>
                    <div class="label"></div>
                    <div class="slim-progress-bar-background margin-right-10">
                        <div
                            class="slim-progress-bar-fill"
                            [style.width]="
                                (licensePlateRedactionsProcessed.length /
                                    (licensePlateRedactionsQueued.length + licensePlateRedactionsProcessed.length)) *
                                    100 +
                                '%'
                            "></div>
                    </div>
                    <div class="label">
                        {{ licensePlateRedactionsProcessed.length }} /
                        {{ licensePlateRedactionsQueued.length + licensePlateRedactionsProcessed.length }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="photos-list-toolbar-right-side">
        <div id="deleted-photos-container" class="card" *ngIf="deletedPhotos?.length > 0" [class.disabled]="disabled">
            <div
                class="label"
                [matTooltip]="
                    'Du hast gerade ' +
                    deletedPhotos.length +
                    (deletedPhotos.length === 1 ? ' Foto' : ' Fotos') +
                    ' gelöscht. Klicke hier zum Wiederherstellen.\n\nDiese Funktion ist verfügbar, bis du diese Seite verlässt.'
                "
                (click)="this.deletedPhotos.length > 1 ? openNumberOfPhotosToRestoreDialog() : restorePhotos(1)">
                <mat-icon id="deleted-photos-restore-from-trash-icon" class="selected-filter-group-icon">
                    restore_from_trash
                </mat-icon>
                <mat-icon
                    id="deleted-photos-trash-icon"
                    class="selected-filter-group-icon"
                    [class.bounce-animation]="isTrashIconAnimationRunning">
                    delete
                </mat-icon>
                <span class="selected-filter-group-label">{{ deletedPhotos.length }}</span>
            </div>
        </div>

        <!--============================================-->
        <!-- Filter Groups
        ---=============================================-->
        <div
            id="filter-group-animation-container"
            [ngClass]="{ 'button-pulse': licensePlateRedactionsFilterGroupPulsing }">
            <div
                id="filter-group-container"
                class="card"
                [ngSwitch]="filterBy"
                [mat-menu-trigger-for]="filterGroupMenu"
                *ngIf="photoGroupFilterShown">
                <div class="selected-filter-group label" *ngSwitchCase="'report'" matTooltip="Fotogruppe Gutachten">
                    <mat-icon class="selected-filter-group-icon">description</mat-icon>
                    <span class="selected-filter-group-label">Gutachten</span>
                </div>
                <div
                    class="selected-filter-group label"
                    *ngSwitchCase="'residualValueExchange'"
                    matTooltip="Fotogruppe Restwertbörse">
                    <mat-icon class="selected-filter-group-icon icon-emphasized">gavel</mat-icon>
                    <span class="selected-filter-group-label">Restwertbörse</span>
                </div>
                <div
                    class="selected-filter-group label"
                    *ngSwitchCase="'repairConfirmation'"
                    matTooltip="Fotogruppe Reparaturbestätigung">
                    <img class="selected-filter-group-icon" src="/assets/images/icons/repair-confirmation.svg" />
                    <span class="selected-filter-group-label">Reparaturbestätigung</span>
                </div>
                <div
                    class="selected-filter-group label"
                    *ngSwitchCase="'expertStatement'"
                    matTooltip="Fotogruppe Stellungnahme">
                    <mat-icon class="selected-filter-group-icon">comment</mat-icon>
                    <span class="selected-filter-group-label">Stellungnahme</span>
                </div>
            </div>
        </div>

        <mat-menu #filterGroupMenu>
            <mat-option (click)="setFilterGroup('report')">
                <mat-icon class="selected-filter-group-icon">description</mat-icon>
                Gutachten
            </mat-option>
            <mat-option
                *ngIf="photoGroupResidualValueExchangeAvailable"
                (click)="setFilterGroup('residualValueExchange')">
                <mat-icon class="selected-filter-group-icon icon-emphasized">gavel</mat-icon>
                Restwertbörse
            </mat-option>
            <mat-option *ngIf="photoGroupRepairConfirmationAvailable" (click)="setFilterGroup('repairConfirmation')">
                <img class="selected-filter-group-icon" src="/assets/images/icons/repair-confirmation.svg" />
                Reparaturbestätigung
            </mat-option>
            <mat-option *ngIf="photoGroupExpertStatementAvailable" (click)="setFilterGroup('expertStatement')">
                <mat-icon class="selected-filter-group-icon">comment</mat-icon>
                Stellungnahme
            </mat-option>
        </mat-menu>
        <!--============================================-->
        <!-- END Filter Groups
        <---============================================-->

        <div id="number-of-photos-container" class="card">
            <span class="label" matTooltip="Fotos in dieser Gruppe von der Gesamtzahl hochgeladener Fotos">
                {{ getPhotosIncludedInPictureGroup(filterBy).length }} von {{ photos.length }}
                {{ isSmallScreen() ? '' : 'Fotos' }}
            </span>
        </div>
        <div
            id="duplicate-photos-container"
            class="card"
            *ngIf="getDuplicatePhotos().length && !isSmallScreen()"
            (click)="handleDuplicatePhotosClick($event)"
            matTooltip="Klicke, um die Duplikate (gleiche Dateigröße) zu selektieren.{{
                '\n\n'
            }}Shift+Klick, um Originale & Duplikate zu selektieren.{{
                '\n\n'
            }}Warum Dateigröße und nicht Dateiname? Manche Browser wie Safari schicken aus (übertriebenen) Datenschutzgründen den Dateinamen beim Upload nicht mit. Im sehr seltenen Ausnahmefall können aber 2 unterschiedliche Fotos dieselbe Dateigröße haben. Ignoriere die Meldung dann einfach.">
            <mat-icon class="warning medium-icon">warning</mat-icon>
            &nbsp;
            <span class="label">
                {{ getDuplicatePhotos().length }} {{ getDuplicatePhotos().length === 1 ? 'Duplikat' : 'Duplikate' }}
            </span>
        </div>
        <div id="view-config-bar" class="card">
            <div
                id="thumbnail-size-toolbar-handle-container"
                class="toolbar-item-container"
                [ngSwitch]="thumbnailSize"
                [matMenuTriggerFor]="thumbnailSizeMenu"
                matTooltip="Bildgröße anpassen">
                <img id="thumbnail-size-icon" src="/assets/images/icons/set-thumbnail-size.png" />
                <ng-container *ngIf="!isSmallScreen()">
                    <span class="thumbnail-size-label label" *ngSwitchCase="'tiny'">Mini</span>
                    <span class="thumbnail-size-label label" *ngSwitchCase="'verySmall'">Sehr klein</span>
                    <span class="thumbnail-size-label label" *ngSwitchCase="'small'">Klein</span>
                    <span class="thumbnail-size-label label" *ngSwitchCase="'medium'">Mittel</span>
                    <span class="thumbnail-size-label label" *ngSwitchCase="'large'">Groß</span>
                    <span class="thumbnail-size-label label" *ngSwitchCase="'veryLarge'">Sehr groß</span>
                </ng-container>
            </div>
            <mat-menu #thumbnailSizeMenu="matMenu" xPosition="before">
                <a *ngIf="!isSmallScreen()" mat-menu-item (click)="setThumbnailSize('tiny')">Mini-Fotos</a>
                <a *ngIf="!isSmallScreen()" mat-menu-item (click)="setThumbnailSize('verySmall')">Sehr kleine Fotos</a>
                <a mat-menu-item (click)="setThumbnailSize('small')">Kleine Fotos</a>
                <a mat-menu-item (click)="setThumbnailSize('medium')">Mittlere Fotos</a>
                <a mat-menu-item (click)="setThumbnailSize('large')">Große Fotos</a>
                <a *ngIf="!isSmallScreen()" mat-menu-item (click)="setThumbnailSize('veryLarge')">Sehr große Fotos</a>
            </mat-menu>
        </div>
    </div>
</div>
<!--============================================-->
<!-- ENDE Photo navigation
---=============================================-->

<!--============================================-->
<!-- Photo Grid -->
<!--============================================-->
<div
    id="photos-container-tile-view"
    muuriGrid
    *ngIf="photos.length !== 0"
    [ngClass]="{
        visible: firstMuuriLayoutCycleFinished,
        'tiny-thumbnail': thumbnailSize === 'tiny',
        'very-small-thumbnail': thumbnailSize === 'verySmall',
        'small-thumbnail': thumbnailSize === 'small',
        'medium-thumbnail': thumbnailSize === 'medium' || !thumbnailSize,
        'large-thumbnail': thumbnailSize === 'large',
        'very-large-thumbnail': thumbnailSize === 'veryLarge',
    }"
    [dragDisabled]="isGridDisabled()"
    (contextmenu)="preventContextMenuOnTouchDevices($event)"
    (layoutEnd)="markFirstMuuriLayoutCycleFinished()"
    (dragStart)="onDragStart()"
    (dragEnd)="onDragEnd()"
    (positionChange)="onPositionChange($event)">
    <!--********** Photo Container **********-->
    <div
        class="photo-container-muuri-item"
        data-id="{{ photo._id }}"
        muuriGridItem
        init-notifier
        (initialized)="loadPhotoThumbnailFile(photo)"
        *ngFor="let photo of photos; let photoIndex = index; trackBy: getPhotoId">
        <div class="photo-container-muuri-item-content">
            <div
                class="photo-container card"
                [ngClass]="{
                    selected: selectedPhotos.includes(photo),
                    'not-included': !photo.versions[filterBy].included,
                    'title-shown':
                        photosJustNamed.includes(photo) ||
                        photoInTitleEditMode === photo ||
                        userPreferences.alwaysDisplayPhotoDescription,
                }"
                [@deletePhoto]="photoDeletionsInProgress.has(photo._id)"
                (click)="toggleSelectionOnCtrlClick(photo, $event)">
                <img
                    [id]="'photo-thumbnail-' + photo._id"
                    class="photo-thumbnail"
                    *ngIf="getLocalThumbnailUrl(photo._id)"
                    [src]="getLocalThumbnailUrl(photo._id)"
                    [ngClass]="{ 'portrait-format': isPhotoInPortraitFormat(photo) }" />

                <!--********** Loading Indicator **********-->
                <div
                    class="photo-loading-container"
                    *ngIf="!getLocalThumbnailUrl(photo._id) && !photoDownloadProblems.has(photo._id)">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    <div
                        class="photo-download-takes-longer-message"
                        *ngIf="doesThumbnail400DownloadTakeLonger(photo._id)">
                        Foto-Download dauert länger.
                        <br />
                        Einen Moment bitte.
                    </div>
                </div>

                <!--********** Download/Render Error **********-->
                <div class="download-error-message" *ngIf="photoDownloadProblems.has(photo._id)">
                    <div class="error-title">{{ photoDownloadProblems.get(photo._id).title }}</div>
                    <div class="error-body">{{ photoDownloadProblems.get(photo._id).body }}</div>
                </div>

                <!--============================================-->
                <!-- Photo Index -->
                <!--============================================-->
                <!-- Only visible when the user is using the "type index to move" input -->
                <div
                    class="photo-index-container"
                    [ngClass]="{ descriptionActive: userPreferences.alwaysDisplayPhotoDescription }"
                    *ngIf="reorderingInputVisible"
                    matTooltip="Foto-Position"
                    @fadeInAndOut>
                    {{ photoIndex + 1 }}
                </div>
                <!--============================================-->
                <!-- END Photo Index -->
                <!--============================================-->

                <!--============================================-->
                <!-- Photo Additional Info Container -->
                <!--============================================-->
                <div class="photo-additional-info-container" [ngClass]="{ hidden: isPhotoBeingRearranged }">
                    <div class="photo-edit-overlay" (click)="openEditor(photo, $event)">
                        <span class="photo-edit-overlay-text">Vergrößern</span>
                    </div>

                    <!--============================================-->
                    <!-- Photo Toolbar Top Right -->
                    <!--============================================-->
                    <div class="photo-toolbar-top-right-container">
                        <!--********** Submenu **********-->
                        <mat-icon
                            [matMenuTriggerFor]="photoContainerSubmenu"
                            (click)="$event.stopPropagation(); selectPhotos([photo])">
                            more_vert
                        </mat-icon>

                        <!--********** Photo Container Submenu **********-->
                        <mat-menu #photoContainerSubmenu="matMenu">
                            <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="photoDownloadSubmenu">
                                <mat-icon>file_download</mat-icon>
                                Herunterladen
                            </a>
                        </mat-menu>

                        <!--********** Download Submenu **********-->
                        <mat-menu #photoDownloadSubmenu="matMenu">
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="
                                    downloadPhotos({ photos: [photo], format: 'original' }); clearPhotoSelection()
                                ">
                                <mat-icon>photo</mat-icon>
                                Ohne Formen
                            </a>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                (click)="
                                    downloadPhotos({ photos: [photo], format: 'rendered' }); clearPhotoSelection()
                                ">
                                <mat-icon>shape_line</mat-icon>
                                Mit Formen
                            </a>
                        </mat-menu>

                        <!--********** Delete **********-->
                        <mat-icon
                            *ngIf="!isPhotoDisabled(photo)"
                            (click)="confirmDeletion(photo)"
                            matTooltip="Foto löschen"
                            matTooltipPosition="above">
                            delete
                        </mat-icon>
                    </div>
                    <!--============================================-->
                    <!-- END Photo Toolbar Top Right -->
                    <!--============================================-->

                    <div class="photo-title-container card">
                        <!--********** Title **********-->
                        <div
                            class="photo-title-and-edit-icon-container"
                            *ngIf="photoInTitleEditMode !== photo"
                            [ngClass]="{ 'cursor-pointer': !isPhotoDisabled(photo) }"
                            (click)="enterPhotoTitleEditMode(photo)">
                            <div class="photo-title" [matTooltip]="getPhotoTooltip(photo)">
                                {{ photo.description || photo.title || photo.originalName }}
                            </div>
                            <mat-icon *ngIf="!isPhotoDisabled(photo)" class="photo-title-edit-icon small-icon">
                                edit
                            </mat-icon>
                        </div>

                        <!--********** Title Edit Mode **********-->
                        <div class="photo-title-input-container" *ngIf="photoInTitleEditMode === photo">
                            <autocomplete-with-memory
                                [(ngModel)]="photo.description"
                                [autocompleteEntryType]="'photoDescription'"
                                [axAutofocus]="true"
                                (change)="emitPhotoChange()"
                                (keydown)="leaveTitleEditModeOnEnter($event)"
                                (blur)="!$event.isAutocompleteOpen && leavePhotoTitleEditMode()"
                                placeholder="Beschreibung"
                                floatLabel="never"
                                [disabled]="isPhotoDisabled(photo)"></autocomplete-with-memory>
                        </div>
                        <!--============================================-->
                        <!-- Photo Toolbar Bottom-->
                        <!---===========================================-->
                        <div
                            class="photo-toolbar-bottom-container"
                            *ngIf="
                                (!photoInTitleEditMode || photoInTitleEditMode !== photo) && !reorderingInputVisible
                            ">
                            <mat-icon
                                class="photo-toolbar-bottom-icon"
                                [class.disabled]="disabled"
                                *ngIf="
                                    (allowCarRegistrationScan && !atLeastOnePhotoHasCarRegistrationDescription()) ||
                                    photoDepictsCarRegistration(photo)
                                "
                                (click)="emitCarRegistrationScannerClick(photo)"
                                matTooltip="Fahrzeugschein auslesen">
                                center_focus_weak
                            </mat-icon>

                            <!--============================================-->
                            <!-- Photo Group Activation -->
                            <!--============================================-->
                            <!--********** Photo Group Report **********-->
                            <mat-icon
                                class="photo-toolbar-bottom-icon"
                                [class.disabled]="disabled"
                                *ngIf="photoGroupReportAvailable"
                                [ngClass]="{ active: photo.versions.report.included }"
                                matTooltip="Fotogruppe Gutachten"
                                (click)="!disabled && togglePhotoGroup(photo, 'report')">
                                description
                            </mat-icon>

                            <!--********** Residual Value Exchange **********-->
                            <mat-icon
                                class="photo-toolbar-bottom-icon icon-emphasized"
                                [class.disabled]="disabled"
                                *ngIf="photoGroupResidualValueExchangeAvailable"
                                [ngClass]="{ active: photo.versions.residualValueExchange.included }"
                                matTooltip="Fotogruppe Restwertbörsen"
                                (click)="!disabled && togglePhotoGroup(photo, 'residualValueExchange')">
                                gavel
                            </mat-icon>

                            <!--********** Repair Confirmation **********-->
                            <div
                                class="photo-toolbar-bottom-icon"
                                *ngIf="photoGroupRepairConfirmationAvailable"
                                [ngClass]="{ active: photo.versions.repairConfirmation.included }"
                                matTooltip="Fotogruppe Reparaturbestätigung"
                                (click)="togglePhotoGroup(photo, 'repairConfirmation')">
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    enable-background="new 0 0 24 24"
                                    xml:space="preserve">
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        fill="#A1A1A1"
                                        d="M19.692,4.524l-4.166,4.161L12.939,6.1l4.093-4.09 c-2.175-1.017-4.835-0.648-6.631,1.145C8.719,4.835,8.283,7.278,9.07,9.366L1.867,16.56c-0.878,0.877-0.878,2.301,0,3.179
                                        c0.878,0.876,2.303,0.876,3.182,0l7.109-7.101c2.158,0.963,4.774,0.578,6.544-1.191C20.582,9.57,20.893,6.747,19.692,4.524z"></path>
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        fill="#A1A1A1"
                                        d="M13.652,18.171l2.183,2.149l5.041-5.508l1.449,1.474l-6.307,7.076l-3.763-3.72L13.652,18.171z"></path>
                                </svg>
                            </div>

                            <!--********** Expert Statement **********-->
                            <mat-icon
                                class="photo-toolbar-bottom-icon"
                                *ngIf="photoGroupExpertStatementAvailable"
                                [ngClass]="{ active: photo.versions.expertStatement.included }"
                                matTooltip="Fotogruppe Stellungnahme"
                                (click)="togglePhotoGroup(photo, 'expertStatement')">
                                comment
                            </mat-icon>
                            <!--============================================-->
                            <!-- END Photo Group Activation -->
                            <!--============================================-->
                        </div>
                        <div
                            class="photo-index-container-with-description"
                            [ngClass]="{ descriptionActive: userPreferences.alwaysDisplayPhotoDescription }"
                            *ngIf="reorderingInputVisible && userPreferences.alwaysDisplayPhotoDescription"
                            matTooltip="Foto-Position"
                            @fadeInAndOut>
                            {{ photoIndex + 1 }}
                        </div>
                        <!--============================================-->
                        <!-- END Photo Toolbar Bottom
                        ---=============================================-->
                    </div>
                </div>
                <!--============================================-->
                <!-- END Photo Additional Info Container -->
                <!--============================================-->

                <!--============================================-->
                <!-- Photo Selection -->
                <!--============================================-->
                <div
                    class="photo-select-icon-container"
                    (click)="clickSelectionMarker(photo, $event)"
                    matTooltip="Foto selektieren/deselektieren"
                    matTooltipPosition="above">
                    <mat-icon class="photo-select-icon small-icon">check</mat-icon>
                </div>
                <!--============================================-->
                <!-- END Photo Selection -->
                <!--============================================-->

                <!--============================================-->
                <!-- Upload -->
                <!--============================================-->
                <mat-icon
                    class="uploading-icon"
                    matTooltip="Bild lädt hoch..."
                    *ngIf="getUploadItem(photo._id) && !getUploadItem(photo._id).failed">
                    swap_horiz
                </mat-icon>
                <!--********** Upload Error **********-->
                <mat-icon
                    class="error-icon"
                    matTooltip="Upload nicht möglich. Bitte mit funktionierender Internetverbindung erneut versuchen. Dazu hier klicken."
                    *ngIf="getUploadItem(photo._id)?.failed"
                    (click)="retryUpload()">
                    close
                </mat-icon>

                <div
                    class="upload-progress-bar"
                    *ngIf="
                        getUploadItem(photo._id) &&
                        getUploadItem(photo._id).progressPercent <= 100 &&
                        !getUploadItem(photo._id)?.failed
                    "
                    [style.width]="getUploadItem(photo._id)?.progressPercent + '%'"></div>
                <!--============================================-->
                <!-- END Upload -->
                <!--============================================-->
            </div>
        </div>
    </div>

    <!--********** Additional Upload Placeholder **********-->
    <!-- Currently not in use, because it does not work well (out of the box) with the muuri grid.
    New photos get added after the placeholder, reordering needs to be disabled etc.
    Currently not worth adjusting the grid functionality, because the floating action button
    is always present in the viewport anyways. -->
    <!--    <div-->
    <!--        *ngIf="photos.length > 0"-->
    <!--        id="upload-placeholder"-->
    <!--        class="photo-container-muuri-item"-->
    <!--        muuriGridItem-->
    <!--        (click)="!isGridDisabled() && multipleFileUpload.click()">-->
    <!--        <div class="photo-container-muuri-item-content">-->
    <!--            <div class="photo-container"><mat-icon class="large-icon">add</mat-icon></div>-->
    <!--        </div>-->
    <!--    </div>-->
</div>
<!--============================================-->
<!-- END Photo Grid -->
<!--============================================-->

<!--============================================-->
<!-- Selection Toolbar
---=============================================-->
<div id="selection-toolbar" *ngIf="selectedPhotos.length !== 0">
    <div id="selection-info-container">
        <div id="number-of-selected-photos">
            {{ selectedPhotos.length }} von {{ photos.length }} {{ photos.length === 1 ? 'Foto' : 'Fotos' }} ausgewählt
        </div>
        <div id="select-or-unselect-all-container">
            <div
                class="toolbar-icon toolbar-button-with-text"
                *ngIf="selectedPhotos.length === photos.length"
                (click)="selectAllPhotos()"
                matTooltip="Alle Fotos abwählen (Strg+A)">
                <mat-icon>radio_button_unchecked</mat-icon>
                <span class="toolbar-button-label">Alle abwählen</span>
            </div>

            <div
                class="toolbar-icon toolbar-button-with-text"
                *ngIf="selectedPhotos.length !== photos.length"
                (click)="selectAllPhotos()"
                matTooltip="Alle Fotos auswählen (Strg + A)">
                <div class="checkmark-icon-container">
                    <mat-icon>radio_button_unchecked</mat-icon>
                    <mat-icon class="toolbar-button-icon-checkmark">check</mat-icon>
                </div>
                <span class="toolbar-button-label">Alle</span>
            </div>

            <div
                class="toolbar-icon toolbar-button-with-text"
                *ngIf="selectedPhotos.length !== photos.length"
                (click)="selectActivatedPhotos()"
                matTooltip="Aktivierte Fotos auswählen (STRG + S)">
                <mat-icon>check_circle</mat-icon>
                <span class="toolbar-button-label">Aktivierte</span>
            </div>

            <div
                class="toolbar-icon toolbar-button-with-text"
                *ngIf="selectedPhotos.length !== photos.length"
                (click)="selectDeactivatedPhotos()"
                matTooltip="Deaktivierte Fotos auswählen (STRG + D)">
                <mat-icon>flaky</mat-icon>
                <span class="toolbar-button-label">Deaktivierte</span>
            </div>
        </div>
    </div>
    <div id="selection-toolbar-photo-groups-container" *ngIf="!reorderingInputVisible">
        <div
            class="selection-toolbar-photo-group-icon-container"
            [ngClass]="{ active: selectedPhotosIncludedInPictureGroup('report'), disabled }"
            matTooltip="Fotogruppe Gutachten"
            (click)="!disabled && toggleSelectedPhotosPhotoGroup('report')">
            <mat-icon class="selection-toolbar-photo-group-icon">description</mat-icon>
        </div>

        <div
            class="selection-toolbar-photo-group-icon-container"
            *ngIf="photoGroupResidualValueExchangeAvailable"
            [ngClass]="{ active: selectedPhotosIncludedInPictureGroup('residualValueExchange'), disabled }"
            matTooltip="Fotogruppe Restwertbörsen"
            (click)="!disabled && toggleSelectedPhotosPhotoGroup('residualValueExchange')">
            <mat-icon class="selection-toolbar-photo-group-icon icon-emphasized">gavel</mat-icon>
        </div>

        <!--********** Repair Confirmation **********-->
        <div
            class="selection-toolbar-photo-group-icon-container"
            *ngIf="photoGroupRepairConfirmationAvailable"
            [ngClass]="{ active: selectedPhotosIncludedInPictureGroup('repairConfirmation') }"
            matTooltip="Fotogruppe Reparaturbestätigung"
            (click)="toggleSelectedPhotosPhotoGroup('repairConfirmation')">
            <svg
                class="selection-toolbar-photo-group-icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                enable-background="new 0 0 24 24"
                xml:space="preserve">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#A1A1A1"
                    d="M19.692,4.524l-4.166,4.161L12.939,6.1l4.093-4.09 c-2.175-1.017-4.835-0.648-6.631,1.145C8.719,4.835,8.283,7.278,9.07,9.366L1.867,16.56c-0.878,0.877-0.878,2.301,0,3.179
                    c0.878,0.876,2.303,0.876,3.182,0l7.109-7.101c2.158,0.963,4.774,0.578,6.544-1.191C20.582,9.57,20.893,6.747,19.692,4.524z"></path>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#A1A1A1"
                    d="M13.652,18.171l2.183,2.149l5.041-5.508l1.449,1.474l-6.307,7.076l-3.763-3.72L13.652,18.171z"></path>
            </svg>
        </div>

        <!--********** Expert Statement **********-->
        <div
            class="selection-toolbar-photo-group-icon-container"
            *ngIf="photoGroupExpertStatementAvailable"
            [ngClass]="{ active: selectedPhotosIncludedInPictureGroup('expertStatement') }"
            matTooltip="Fotogruppe Stellungnahme"
            (click)="toggleSelectedPhotosPhotoGroup('expertStatement')">
            <mat-icon class="selection-toolbar-photo-group-icon">comment</mat-icon>
        </div>
    </div>
    <div id="selection-toolbar-reordering-input-container" *ngIf="reorderingInputVisible && !isGridDisabled()">
        <label class="selection-toolbar-reordering-input-label" for="reordering-input">An Position verschieben:</label>
        <mat-form-field id="selection-toolbar-reordering-form-field">
            <input
                matInput
                id="reordering-input"
                type="number"
                [(ngModel)]="reorderingTargetIndex"
                (keydown)="moveSelectedPhotosToTargetIndexOnEnter($event); emitPhotoChange()"
                #reorderingInput />
        </mat-form-field>
        <mat-icon
            class="toolbar-icon"
            (click)="moveSelectedPhotosToTargetIndex(reorderingTargetIndex); emitPhotoChange()"
            matTooltip="Neue Position annehmen">
            check
        </mat-icon>
        <mat-icon
            class="toolbar-icon margin-right-30"
            (click)="hideReorderingInput()"
            matTooltip="Verschiebung abbrechen">
            close
        </mat-icon>
        <div class="toolbar-spacer-with-line"></div>
        <img
            class="cursor-pointer"
            (click)="moveSelectedPhotosToBeginning()"
            src="/assets/images/icons/move_up_grey_24dp.svg"
            matTooltip="An den Anfang verschieben (Strg+Pos1)" />
        <img
            class="margin-left-10 cursor-pointer"
            (click)="moveSelectedPhotosToEnd()"
            src="/assets/images/icons/move_down_grey_24dp.svg"
            matTooltip="An das Ende verschieben (Strg+Ende)" />
    </div>
    <div id="selection-toolbar-icons-container">
        <!--********** Downloading Photos **********-->
        <div
            id="downloading-photos-container"
            *ngIf="numberOfDownloadingPhotos"
            matTooltip="Fotos werden heruntergeladen">
            <img src="/assets/images/icons/loading-indicator.svg" alt="" class="pending-icon" />
            <!-- If the last photo finished downloading, show that the ZIP archive is being created. Otherwise, if that takes longer, the user may think, nothing is going on -->
            <ng-container *ngIf="downloadedPhotos.length !== numberOfDownloadingPhotos">
                {{ downloadedPhotos.length }} von {{ numberOfDownloadingPhotos }} Fotos
            </ng-container>
            <ng-container *ngIf="downloadedPhotos.length === numberOfDownloadingPhotos">
                Stelle ZIP zusammen...
            </ng-container>
        </div>

        <!--********** Toolbar Icons **********-->
        <div id="toolbar-icons-container">
            <div id="download-photos-button" [matMenuTriggerFor]="photoDownloadSubmenu">
                <mat-icon id="download-photos-icon" matTooltip="Fotos herunterladen">file_download</mat-icon>
                <span id="download-photos-button-label">Herunterladen</span>
            </div>

            <mat-menu #photoDownloadSubmenu>
                <mat-option
                    class="menu-item-with-icon"
                    (click)="downloadPhotos({ photos: selectedPhotos, format: 'original' })">
                    <mat-icon>photo</mat-icon>
                    Ohne Formen
                </mat-option>
                <mat-option
                    class="menu-item-with-icon"
                    (click)="downloadPhotos({ photos: selectedPhotos, format: 'rendered' })">
                    <mat-icon>shape_line</mat-icon>
                    Mit Formen
                </mat-option>
            </mat-menu>

            <mat-icon
                class="toolbar-icon"
                *ngIf="!isGridDisabled()"
                (click)="showReorderingInput()"
                matTooltip="Fotos verschieben">
                low_priority
            </mat-icon>
            <mat-icon
                class="toolbar-icon"
                *ngIf="!isGridDisabled()"
                (click)="forceUploadSelectedPhotos()"
                matTooltip="Originale Fotos erneut zum Server hochladen.{{
                    '\n\n'
                }}Relevant, falls Fotos aus diesem Gutachten auf diesem Gerät dargestellt werden, während auf einem anderen Gerät die Meldung 'Original-Foto fehlt' angezeigt wird.">
                cloud_sync
            </mat-icon>
            <div class="toolbar-spacer-with-line"></div>
            <mat-icon
                class="toolbar-icon"
                *ngIf="!isGridDisabled()"
                (click)="deletePhotos(selectedPhotos)"
                matTooltip="Löschen (Entf)">
                delete
            </mat-icon>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Selection Toolbar
---=============================================-->

<!--============================================-->
<!-- No Photos to Display
---=============================================-->
<div
    id="no-photos-to-display-container"
    *ngIf="photos.length === 0"
    (click)="!isGridDisabled() && multipleFileUpload.click()">
    <div id="example-photo-container" *ngIf="!miniMode">
        <img src="/assets/images/car-photos.png" alt="Beispielbild Auto" />
    </div>
    <div id="upload-intro-container">
        <p>
            Lade deine Fotos mit Drag & Drop
            <br />
            oder über den Uploader hoch.
            <span
                id="upload-image-size-note"
                class="help-indicator"
                matTooltip="Speicherung mit einer Auflösung von bis zu 5 Megapixeln. Größere Dateien können hochgeladen werden, die Reduzierung der Auflösung erfolgt automatisch."
                [matTooltipShowDelay]="0">
                ?
            </span>
        </p>
        <button id="upload-photos" [ngClass]="{ disabled: isGridDisabled() }">
            <img src="/assets/images/icons/upload-white_24.png" alt="Hochladen" />
            <span>Fotos hochladen</span>
        </button>
    </div>
</div>
<!--============================================-->
<!-- END No Photos to Display
---=============================================-->

<!--============================================-->
<!-- Default Photo Descriptions Editor -->
<!--============================================-->
<default-photo-descriptions-editor
    *ngIf="defaultPhotoDescriptionsEditorShown"
    (close)="closeDefaultPhotoDescriptionsEditor()"></default-photo-descriptions-editor>
<!--============================================-->
<!-- END Default Photo Descriptions Editor -->
<!--============================================-->

<div
    class="floating-action-button"
    *ngIf="floatingActionButtonShown"
    (click)="isGridDisabled() ? emitClickWhileDisabled() : multipleFileUpload.click()"
    [matTooltip]="isGridDisabled() ? 'Das Gutachten ist abgeschlossen. Bitte entsperre es erst.' : 'Fotos hochladen'"
    matTooltipPosition="left"
    [ngClass]="{ disabled: isGridDisabled(), 'above-toolbar': selectedPhotos.length !== 0 }">
    <mat-icon>file_upload</mat-icon>
</div>

<input type="file" ng2FileSelect [uploader]="photoFileSelector" multiple #multipleFileUpload accept="image/jpeg" />

<div
    id="drop-overlay"
    *ngIf="fileIsOverBody && !isPhotoBeingRearranged"
    ng2FileDrop
    [class.file-over]="fileIsOverDropZone"
    [class.disabled]="fileIsOverDropZone && isGridDisabled()"
    (fileOver)="onFileOverDropZone($event)"
    (onFileDrop)="onFileDrop()"
    [uploader]="photoFileSelector"></div>

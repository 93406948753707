<div class="dialog-overlay" (click)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container">
        <!--********** Close Icon **********-->
        <mat-icon class="dialog-close-icon" (click)="closeDialog()">close</mat-icon>

        <!--============================================-->
        <!-- Left Column - Record Selection -->
        <!--============================================-->
        <section id="record-selection-column">
            <h2>Eigene Felder</h2>
            <div
                id="field-location"
                class="label text-align-center"
                matTooltip="An dieser Stelle werden deine eigenen Felder angezeigt.">
                {{ translateFieldLocation() }}
            </div>

            <!--********** List of Custom Field Configs **********-->
            <div
                id="field-config-list"
                class="rounded-record-list"
                cdkDropList
                (cdkDropListDropped)="reorderCustomFieldConfigArray($event)">
                <div
                    class="custom-field-config-list-item rounded-record-list-item"
                    *ngFor="let fieldConfig of fieldGroupConfig.fieldConfigs"
                    [ngClass]="{ selected: fieldConfig._id === selectedFieldConfig._id }"
                    (click)="selectFieldConfig(fieldConfig)"
                    cdkDrag>
                    <!--============================================-->
                    <!-- Input Type Icon -->
                    <!--============================================-->
                    <!--********** Single Line **********-->
                    <mat-icon
                        class="field-type-icon"
                        *ngIf="fieldConfig.type === 'singleLineText'"
                        matTooltip="Einzeiliges Textfeld">
                        title
                    </mat-icon>
                    <!--********** Multi Line **********-->
                    <mat-icon
                        class="field-type-icon"
                        *ngIf="fieldConfig.type === 'multiLineText'"
                        matTooltip="Mehrzeiliges Textfeld">
                        text_fields
                    </mat-icon>
                    <!--********** Number **********-->
                    <mat-icon class="field-type-icon" *ngIf="fieldConfig.type === 'number'" matTooltip="Zahlenfeld">
                        123
                    </mat-icon>
                    <!--********** Dropdown **********-->
                    <mat-icon
                        class="field-type-icon"
                        *ngIf="fieldConfig.type === 'select'"
                        matTooltip="Dropdown (Auswahl einer Option aus einer Liste)">
                        list
                    </mat-icon>
                    <!--********** Autocomplete **********-->
                    <mat-icon
                        class="field-type-icon"
                        *ngIf="fieldConfig.type === 'autocomplete'"
                        matTooltip="Autovervollständigung (Vorschläge beim Tippen)">
                        text_rotation_none
                    </mat-icon>
                    <!--********** Currency **********-->
                    <mat-icon class="field-type-icon" *ngIf="fieldConfig.type === 'currency'" matTooltip="Währungsfeld">
                        euro_symbol
                    </mat-icon>
                    <!--********** Date **********-->
                    <mat-icon class="field-type-icon" *ngIf="fieldConfig.type === 'date'" matTooltip="Datumsfeld">
                        today
                    </mat-icon>
                    <!--********** Checkbox **********-->
                    <mat-icon class="field-type-icon" *ngIf="fieldConfig.type === 'boolean'" matTooltip="Checkbox">
                        check_box
                    </mat-icon>
                    <!--********** Tristate Checkbox **********-->
                    <mat-icon
                        class="field-type-icon"
                        *ngIf="fieldConfig.type === 'tristate'"
                        matTooltip="Checkbox Ja/Nein/Unbekannt">
                        help_center
                    </mat-icon>
                    <!--********** Button **********-->
                    <mat-icon class="field-type-icon" *ngIf="fieldConfig.type === 'button'" matTooltip="Button">
                        play_arrow
                    </mat-icon>
                    <!--============================================-->
                    <!-- END Input Type Icon -->
                    <!--============================================-->

                    <!--********** Field Name **********-->
                    <div class="field-name text-overflow-ellipsis" matTooltip="{{ fieldConfig.name }}">
                        <span *ngIf="fieldConfig.name">{{ fieldConfig.name }}</span>
                        <span *ngIf="!fieldConfig.name" class="italic label text-overflow-ellipsis">Kein Titel</span>
                    </div>

                    <mat-icon
                        class="medium-icon"
                        *ngIf="!fieldConfig.reportTypes.includes(this.reportType)"
                        (click)="activateFieldForCurrentReportType(fieldConfig)"
                        [matTooltip]="getVisibilityTooltip(fieldConfig)">
                        visibility_off
                    </mat-icon>

                    <!--********** Submenu **********-->
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="submenu" (click)="$event.stopPropagation()">
                        more_vert
                    </mat-icon>
                    <mat-menu #submenu>
                        <!--********** Delete **********-->
                        <a mat-menu-item class="menu-item-with-icon" (click)="deleteFieldConfig(fieldConfig)">
                            <mat-icon>delete</mat-icon>
                            Löschen
                        </a>
                    </mat-menu>
                </div>
            </div>

            <!--********** Add new field **********-->
            <div id="add-new-field-button" class="dashed-button" (click)="createCustomFieldConfig()">+ Neues Feld</div>
        </section>
        <!--============================================-->
        <!-- END Left Column - Record Selection -->
        <!--============================================-->

        <!--============================================-->
        <!-- Right Column - Detail View -->
        <!--============================================-->
        <div id="detail-column" *ngIf="selectedFieldConfig" @slideInAndOutHorizontally>
            <h2>Feld bearbeiten</h2>
            <!--============================================-->
            <!-- Field Type -->
            <!--============================================-->
            <div id="field-type-grid">
                <!--********** Single Line **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'singleLineText' }"
                    (click)="selectFieldType('singleLineText'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Einzeiliges Textfeld">title</mat-icon>
                    <div class="field-type-title">Text einzeilig</div>
                </div>
                <!--********** Multi Line **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'multiLineText' }"
                    (click)="selectFieldType('multiLineText'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Mehrzeiliges Textfeld">text_fields</mat-icon>
                    <div class="field-type-title">Text mehrzeilig</div>
                </div>
                <!--********** Number **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'number' }"
                    (click)="selectFieldType('number'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Zahlenfeld">123</mat-icon>
                    <div class="field-type-title">Zahl</div>
                </div>
                <!--********** Dropdown **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'select' }"
                    (click)="selectFieldType('select'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Dropdown (Auswahl einer Option aus einer Liste)">
                        list
                    </mat-icon>
                    <div class="field-type-title">Dropdown</div>
                </div>
                <!--********** Autocomplete **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'autocomplete' }"
                    (click)="selectFieldType('autocomplete'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Autovervollständigung (Vorschläge beim Tippen)">
                        text_rotation_none
                    </mat-icon>
                    <div class="field-type-title">Autocomplete</div>
                </div>
                <!--********** Currency **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'currency' }"
                    (click)="selectFieldType('currency'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Währungsfeld">euro_symbol</mat-icon>
                    <div class="field-type-title">Währung</div>
                </div>
                <!--********** Date **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'date' }"
                    (click)="selectFieldType('date'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Datumsfeld">today</mat-icon>
                    <div class="field-type-title">Datum</div>
                </div>
                <!--********** Checkbox **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'boolean' }"
                    (click)="selectFieldType('boolean'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Checkbox">check_box</mat-icon>
                    <div class="field-type-title">Checkbox</div>
                </div>
                <!--********** Tristate Checkbox **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'tristate' }"
                    (click)="selectFieldType('tristate'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Checkbox Ja/Nein/Unbekannt">help_center</mat-icon>
                    <div class="field-type-title">3-Status-Checkbox</div>
                </div>
                <!--********** Button **********-->
                <div
                    class="field-type-container"
                    [ngClass]="{ active: selectedFieldConfig.type === 'button' }"
                    (click)="selectFieldType('button'); emitFieldConfigChange()">
                    <mat-icon class="field-type-icon" matTooltip="Button">play_arrow</mat-icon>
                    <div class="field-type-title">Button</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Field Type -->
            <!--============================================-->

            <!--============================================-->
            <!-- Title & Placeholder -->
            <!--============================================-->
            <section>
                <h3>Feldname</h3>
                <div id="title-and-placeholder-container">
                    <!--********** Title **********-->
                    <div id="title-container">
                        <mat-form-field class="mat-block">
                            <input
                                matInput
                                placeholder="Titel"
                                [(ngModel)]="selectedFieldConfig.name"
                                (ngModelChange)="updatePlaceholder()"
                                (change)="emitFieldConfigChange(); emitFieldConfigChange()"
                                matTooltip="Name des Eingabefeldes"
                                #titleInput />
                            <mat-hint *ngIf="isFieldNameAlreadyTaken(selectedFieldConfig)" class="error">
                                Name bereits vergeben.
                            </mat-hint>
                            <mat-hint *ngIf="selectedFieldConfig.name === ''" class="error">
                                Name sollte nicht leer sein.
                            </mat-hint>
                        </mat-form-field>

                        <!--********** Submenu **********-->
                        <mat-icon
                            class="toolbar-icon"
                            *ngIf="isSuffixAllowed(selectedFieldConfig.type)"
                            [matMenuTriggerFor]="suffixToggleMenu">
                            more_vert
                        </mat-icon>
                        <mat-menu #suffixToggleMenu>
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="!isSuffixInputVisible"
                                (click)="showSuffixInput()"
                                matTooltip="Begriff am rechten Rand des Eingabefeldes anzeigen">
                                <mat-icon>align_horizontal_right</mat-icon>
                                Suffix einblenden
                            </a>

                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="isSuffixInputVisible"
                                (click)="hideSuffixInput(); deleteSuffix(); emitFieldConfigChange()"
                                matTooltip="Begriff am rechten Rand des Eingabefeldes anzeigen">
                                <mat-icon>align_horizontal_right</mat-icon>
                                Suffix entfernen
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Icon Picker **********-->
                    <icon-picker
                        class="icon-picker"
                        *ngIf="selectedFieldConfig.type === 'button'"
                        [icon]="selectedFieldConfig.googleMaterialIconName"
                        (iconPicked)="
                            selectedFieldConfig.googleMaterialIconName = $event.icon; emitFieldConfigChange()
                        "></icon-picker>

                    <!--********** Placeholder Preview **********-->
                    <div id="placeholder-preview-container" *ngIf="selectedFieldConfig.type !== 'button'">
                        <!--********** Label & Value **********-->
                        <div
                            id="placeholder-label-and-value-container"
                            class="text-overflow-ellipsis"
                            matTooltip="{{ selectedFieldConfig.placeholder }}{{
                                '\n\n'
                            }}Nutze den Platzhalter, um in den Einstellungen Textbausteine abhängig von deiner Eingabe im Feld einzublenden.">
                            <div class="label">Platzhalter</div>
                            <div id="placeholder-value" class="text-overflow-ellipsis">
                                {{ selectedFieldConfig.placeholder || 'Name fehlt' }}
                            </div>
                        </div>

                        <!--********** Preview Toolbar **********-->
                        <mat-icon
                            class="toolbar-icon"
                            (click)="copyPlaceholderToClipboard()"
                            matTooltip="Platzhalter in Zwischenablage kopieren">
                            content_copy
                        </mat-icon>
                    </div>
                </div>

                <!--============================================-->
                <!-- Suffix -->
                <!--============================================-->
                <mat-form-field class="mat-block" *ngIf="isSuffixInputVisible" @slideInAndOutVertically>
                    <input
                        matInput
                        [(ngModel)]="selectedFieldConfig.suffix"
                        (change)="emitFieldConfigChange()"
                        placeholder="Suffix"
                        matTooltip="Zum besseren Verständnis eines Feldes kannst du am rechten Rand einen zusätzlichen Begriff anzeigen lassen.{{
                            '\n\n'
                        }}Beispiel: Statt nur &quot;5&quot; für die Reparaturdauer einzugeben, kannst du daraus &quot;5 Arbeitstage&quot; machen." />
                </mat-form-field>
                <!--============================================-->
                <!-- END Suffix -->
                <!--============================================-->
            </section>
            <!--============================================-->
            <!-- END Title & Placeholder -->
            <!--============================================-->

            <!--============================================-->
            <!-- Options -->
            <!--============================================-->
            <section id="options-section">
                <!--============================================-->
                <!-- Dropdown & Autocomplete Options -->
                <!--============================================-->
                <ng-container *ngIf="isDropdownType()">
                    <h4 class="margin-bottom-10">Auswahloptionen</h4>
                    <!--********** Options List **********-->
                    <div
                        id="dropdown-options-list"
                        cdkDropList
                        (cdkDropListDropped)="reorderDropdownOptionsArray($event)">
                        <div
                            class="dropdown-options-row"
                            cdkDrag
                            [cdkDragDisabled]="selectedFieldConfig.dropdownOptions.length < 2"
                            *ngFor="let selectOption of selectedFieldConfig.dropdownOptions">
                            <!--********** Drag Handle **********-->
                            <mat-icon
                                class="dropdown-option-drag-handle"
                                *ngIf="selectedFieldConfig.dropdownOptions.length > 1"
                                cdkDragHandle>
                                drag_indicator
                            </mat-icon>

                            <!--********** Title **********-->
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Titel"
                                    [(ngModel)]="selectOption.value"
                                    (change)="emitFieldConfigChange()" />
                            </mat-form-field>

                            <!--********** Toolbar **********-->
                            <div class="dropdown-option-toolbar">
                                <!--********** Delete **********-->
                                <mat-icon
                                    class="toolbar-icon"
                                    (click)="removeSelectOption(selectOption); emitFieldConfigChange()"
                                    matTooltip="Löschen">
                                    delete
                                </mat-icon>
                            </div>
                        </div>
                    </div>

                    <!--********** Add new Dropdown Option **********-->
                    <div class="text-align-center">
                        <button class="flat" (click)="addDropdownOption(); emitFieldConfigChange()">
                            + Neue Option
                        </button>
                    </div>
                </ng-container>
                <!--============================================-->
                <!-- END Dropdown & Autocomplete Options -->
                <!--============================================-->

                <h4 *ngIf="selectedFieldConfig.type !== 'button'" class="margin-bottom-10">Standardwert</h4>

                <!--============================================-->
                <!-- Default Value -->
                <!--============================================-->
                <!--********** Single Line **********-->
                <mat-form-field
                    class="mat-block"
                    *ngIf="selectedFieldConfig.type === 'singleLineText'"
                    matTooltip="Dieser Standardwert wird bei neuen Gutachten vorbelegt.">
                    <input
                        matInput
                        [(ngModel)]="selectedFieldConfig.defaultValue"
                        (change)="emitFieldConfigChange()"
                        placeholder="Standardwert" />
                    <span matSuffix *ngIf="selectedFieldConfig.suffix">{{ selectedFieldConfig.suffix }}</span>
                </mat-form-field>

                <!--********** Multi Line **********-->
                <mat-form-field
                    class="mat-block"
                    *ngIf="selectedFieldConfig.type === 'multiLineText'"
                    matTooltip="Dieser Standardwert wird bei neuen Gutachten vorbelegt.">
                    <mat-quill
                        [(ngModel)]="selectedFieldConfig.defaultValue"
                        (change)="emitFieldConfigChange()"
                        placeholder="Standardwert"></mat-quill>
                    <span matSuffix *ngIf="selectedFieldConfig.suffix">{{ selectedFieldConfig.suffix }}</span>
                </mat-form-field>

                <!--********** Number **********-->
                <mat-form-field
                    class="mat-block"
                    *ngIf="selectedFieldConfig.type === 'number'"
                    matTooltip="Dieser Standardwert wird bei neuen Gutachten vorbelegt.">
                    <input
                        matInput
                        number-input
                        [(number)]="selectedFieldConfig.defaultValue"
                        (change)="emitFieldConfigChange()"
                        placeholder="Standardwert" />
                    <span matSuffix *ngIf="selectedFieldConfig.suffix">{{ selectedFieldConfig.suffix }}</span>
                </mat-form-field>

                <!--********** Dropdown **********-->
                <mat-form-field
                    class="mat-block"
                    *ngIf="selectedFieldConfig.type === 'select'"
                    matTooltip="Dieser Standardwert wird bei neuen Gutachten vorbelegt.">
                    <mat-select
                        [(ngModel)]="selectedFieldConfig.defaultValue"
                        (selectionChange)="emitFieldConfigChange()"
                        placeholder="Standardwert">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let option of selectedFieldConfig.dropdownOptions" [value]="option.value">
                            <span *ngIf="option.value">{{ option.value }}</span>
                            <span *ngIf="!option.value" class="label italic">Leer</span>
                        </mat-option>
                    </mat-select>
                    <span matSuffix *ngIf="selectedFieldConfig.suffix">{{ selectedFieldConfig.suffix }}</span>
                </mat-form-field>

                <!--********** Autocomplete **********-->
                <mat-form-field
                    class="mat-block"
                    *ngIf="selectedFieldConfig.type === 'autocomplete'"
                    matTooltip="Dieser Standardwert wird bei neuen Gutachten vorbelegt.">
                    <input
                        matInput
                        [(ngModel)]="selectedFieldConfig.defaultValue"
                        (change)="emitFieldConfigChange()"
                        placeholder="Standardwert"
                        [matAutocomplete]="customAutocomplete" />
                    <span matSuffix *ngIf="selectedFieldConfig.suffix">{{ selectedFieldConfig.suffix }}</span>
                    <mat-autocomplete #customAutocomplete (optionSelected)="emitFieldConfigChange()">
                        <mat-option
                            *ngFor="let option of selectedFieldConfig.dropdownOptions"
                            [value]="option.value"
                            (mousedown)="$event.preventDefault()">
                            {{ option.value || 'Noch kein Titel' }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <!--********** Currency **********-->
                <currency-input
                    *ngIf="selectedFieldConfig.type === 'currency'"
                    [(value)]="selectedFieldConfig.defaultValue"
                    (change)="emitFieldConfigChange()"
                    name="currency-default"
                    placeholder="Standardwert"
                    matTooltip="Dieser Standardwert wird bei neuen Gutachten vorbelegt."></currency-input>

                <!--********** Date **********-->
                <date-input
                    *ngIf="selectedFieldConfig.type === 'date'"
                    [(date)]="selectedFieldConfig.defaultValue"
                    (change)="emitFieldConfigChange()"
                    placeholder="Standardwert"
                    matTooltip="Dieser Standardwert wird bei neuen Gutachten vorbelegt."></date-input>

                <!--********** Checkbox **********-->
                <div *ngIf="selectedFieldConfig.type === 'boolean'" class="display-flex flex-align-center">
                    <div class="margin-right-8">Initial aktiv:</div>
                    <mat-checkbox
                        [(ngModel)]="selectedFieldConfig.defaultValue"
                        (change)="emitFieldConfigChange()"
                        matTooltip="Bei neuen Gutachten ist diese Checkbox {{
                            selectedFieldConfig.defaultValue ? 'aktiv' : 'inaktiv'
                        }}."></mat-checkbox>
                </div>

                <!--********** Tristate Checkbox **********-->
                <div *ngIf="selectedFieldConfig.type === 'tristate'" class="display-flex flex-align-center">
                    <div class="margin-right-8">Initial aktiv:</div>
                    <tri-state-checkbox
                        [(ngModel)]="selectedFieldConfig.defaultValue"
                        (change)="emitFieldConfigChange()"
                        matTooltip="Bei neuen Gutachten wird der Wert gleich dem hier ausgewählten vorbelegt."></tri-state-checkbox>
                </div>

                <!--********** Button **********-->
                <ng-container *ngIf="selectedFieldConfig.type === 'button'">
                    <h4 class="margin-bottom-10">Einstellungen</h4>

                    <div class="button-field-type-info">
                        Der Button Feldtyp kann für die Anbindung an externe Systeme genutzt werden. Beim Klick auf den
                        Button wird der Webhook mit der angegebenen URL ausgelöst und beinhaltet den angegebenen
                        Ereignis-Typ. Für mehr Informationen besuche die autoiXpert
                        <a href="https://dev.autoixpert.de/dokumentation/ressourcen/feldtyp-button" target="_blank">
                            Entwickler-Dokumentation
                        </a>
                        .
                    </div>
                    <!-- URL input -->
                    <mat-form-field class="mat-block">
                        <input
                            matInput
                            [(ngModel)]="selectedFieldConfig.webhookUrl"
                            (change)="emitFieldConfigChange()"
                            placeholder="URL" />
                    </mat-form-field>

                    <!-- Event Type input -->
                    <mat-form-field class="mat-block margin-top-10">
                        <input
                            matInput
                            [(ngModel)]="selectedFieldConfig.webhookEventType"
                            (change)="emitFieldConfigChange()"
                            placeholder="Ereignis-Typ" />
                    </mat-form-field>
                </ng-container>
                <!--============================================-->
                <!-- END Default Value -->
                <!--============================================-->
            </section>
            <!--============================================-->
            <!-- END Options -->
            <!--============================================-->

            <!--============================================-->
            <!-- Helpcenter Article -->
            <!--============================================-->
            <div
                id="helpcenter-article-link"
                class="info-note"
                *ngIf="selectedFieldConfig.name && selectedFieldConfig.type !== 'button'"
                @slideInAndOutVertically>
                <mat-icon class="outlined">info</mat-icon>
                <div>
                    Damit deine Eingaben Einfluss auf die Gutachten-PDF haben, solltest du
                    <a
                        href="https://wissen.autoixpert.de/hc/de/articles/10298993447698-Eigene-Felder-im-Gutachten-abdrucken"
                        target="_blank"
                        rel="noopener">
                        einen Textbaustein anlegen
                    </a>
                    oder den Platzhalter in einer Bedingung verwenden.
                </div>
            </div>
            <!--============================================-->
            <!-- END Helpcenter Article -->
            <!--============================================-->

            <!--============================================-->
            <!-- Report Types -->
            <!--============================================-->
            <section id="report-types" (click)="showReportTypesDialog()">
                <mat-icon class="small-icon margin-right-8">local_offer</mat-icon>
                <div
                    id="selected-report-types"
                    matTooltip="In diesen Vorgangstypen wird das Feld angezeigt: {{ getSelectedReportTypesList() }}">
                    {{ getSelectedReportTypesList() || 'in keinem Vorgangstyp aktiv' }}
                </div>
            </section>
            <!--============================================-->
            <!-- END Report Types -->
            <!--============================================-->

            <!--********** Close Editor Button **********-->
            <div class="dialog-buttons-container">
                <button
                    #thisButton
                    [disabled]="!isFieldNameValid(selectedFieldConfig)"
                    [matTooltip]="thisButton.disabled ? 'Bitte wähle einen anderen Feldnamen' : ''"
                    (click)="closeDialog()">
                    Bearbeitung beenden
                </button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Right Column - Detail View -->
        <!--============================================-->
    </div>
</div>

<report-types-dialog
    *ngIf="reportTypesDialogShown"
    [(selectedReportTypes)]="selectedFieldConfig.reportTypes"
    (selectedReportTypesChange)="emitFieldConfigChange()"
    (close)="hideReportTypesDialog()"
    @runChildAnimations></report-types-dialog>

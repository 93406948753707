import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { fadeInAndOutAnimation } from '@autoixpert/animations/fade-in-and-out.animation';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { getSelectedGarageFeeSet } from '@autoixpert/lib/contact-people/garages/get-selected-garage-fee-set';
import { mapDekraResponseToGarageFees } from '@autoixpert/lib/create-garage-fee-set-from-dekra-fees';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { getVatRate } from '@autoixpert/lib/invoices/get-vat-rate-2020';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { isAudatexUserComplete } from '@autoixpert/lib/users/is-audatex-user-complete';
import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { GarageFeeSet } from '@autoixpert/models/contacts/garage-fee-set';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AudatexCalculationText } from '@autoixpert/models/reports/damage-calculation/audatex-calculation-text';
import { DatDossierType } from '@autoixpert/models/reports/damage-calculation/dat-dossier-type';
import { ManualCalculation } from '@autoixpert/models/reports/damage-calculation/manual-calculation';
import { Repair } from '@autoixpert/models/reports/damage-description/repair';
import { DekraResponse } from '@autoixpert/models/reports/dekra-fees/dekra-fees';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fullTaxationRate } from '@autoixpert/static-data/taxation-rates';
import { runChildAnimations } from 'src/app/shared/animations/run-child-animations.animation';
import { slideInAndOutVertically } from 'src/app/shared/animations/slide-in-and-out-vertical.animation';
import { slideOutSide } from 'src/app/shared/animations/slide-out-side.animation';
import { slideOutVertical } from 'src/app/shared/animations/slide-out-vertical.animation';
import { clearRepairCalculationData } from 'src/app/shared/libraries/damage-calculation/clear-repair-calculation-data';
import { confirmRetransmissionOfGarageFees } from 'src/app/shared/libraries/damage-calculation/confirm-retransmission-of-garage-fees';
import { determineDamageType } from 'src/app/shared/libraries/damage-calculation/determine-damage-type';
import { didGarageFeesChange } from 'src/app/shared/libraries/damage-calculation/did-garage-fees-change';
import { getZipForDekraFees } from 'src/app/shared/libraries/damage-calculation/get-zip-for-dekra-fees';
import { persistExportedGarageFeeSet } from 'src/app/shared/libraries/damage-calculation/persist-exported-garage-fee-set';
import { getDekraErrorHandlers } from 'src/app/shared/libraries/error-handlers/get-dekra-error-handlers';
import { getGtmotiveErrorHandlers } from 'src/app/shared/libraries/error-handlers/get-gtmotive-error-handlers';
import { ApiErrorService } from 'src/app/shared/services/api-error.service';
import { AudatexCalculationTextService } from 'src/app/shared/services/audatex-calculation-text.service';
import { AudatexTaskService } from 'src/app/shared/services/audatex/audatex-task.service';
import { DatDamageCalculationService, DatUiCallbacks } from 'src/app/shared/services/dat-damage-calculation.service';
import { DatValuationService } from 'src/app/shared/services/dat-valuation.service';
import {
    GtmotiveEstimateFindResponse,
    GtmotiveEstimateService,
    GtmotivePatchResponse,
} from 'src/app/shared/services/gtmotive/gtmotive-estimate.service';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { NetworkStatusService } from 'src/app/shared/services/network-status.service';
import { NewWindowService } from 'src/app/shared/services/new-window.service';
import { ReportDetailsService } from 'src/app/shared/services/report-details.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { TeamService } from 'src/app/shared/services/team.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';
import { DatAuthenticationService } from '../../../../shared/services/dat-authentication.service';
import { FeeSetHasChangedDialogDecision } from '../fee-set-has-changed-dialog/fee-set-has-changed-dialog.component';

@Component({
    selector: 'damage-calculation-providers',
    templateUrl: './damage-calculation-providers.component.html',
    styleUrls: ['./damage-calculation-providers.component.scss'],
    animations: [
        slideOutSide(),
        // Slide animation for the label "Reparaturkosten kalkulieren mit"
        slideInAndOutVertically(),
        slideOutVertical(),
        fadeInAndOutAnimation(),
        runChildAnimations(),
    ],
})
export class DamageCalculationProvidersComponent implements OnInit, OnDestroy {
    constructor(
        private reportService: ReportService,
        private router: Router,
        public changeDetectorRef: ChangeDetectorRef,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private teamService: TeamService,
        private newWindowService: NewWindowService,
        private httpClient: HttpClient,
        private reportDetailsService: ReportDetailsService,
        public userPreferences: UserPreferencesService,
        private apiErrorService: ApiErrorService,
        private audatexCalculationTextService: AudatexCalculationTextService,
        // NgZone must stay here although the IDE may mark it as unused, because it's required on the component passed to openValuationUI().
        public ngZone: NgZone,
        private dialog: MatDialog,
        private gtmotiveEstimateService: GtmotiveEstimateService,
        private datDamageCalculationService: DatDamageCalculationService,
        private datAuthenticationService: DatAuthenticationService,
        private networkStatusService: NetworkStatusService,
        public datValuationService: DatValuationService,
        public audatexTaskService: AudatexTaskService,
    ) {}

    @Input() report: Report;

    @Output() valuesDidChange = new EventEmitter<void>();
    @Output() showDiminishedValueNotification = new EventEmitter<boolean>();

    user: User;
    team: Team;

    // DAT
    public datDamageCalculationPending = false;
    public connectDatDossierDialogShown = false;
    public connectDatDossierType: DatDossierType = 'damageCalculation';

    // Audatex Online Calculation
    public audatexImportExportPending = false;

    // Audatex Calculation Text
    public audatexCalculationText: AudatexCalculationText = null;
    public audatexCalculationInsertionDialogShown = false;

    // GT Motive Calculation
    public gtmotiveCalculationOpeningPending: boolean;
    public gtmotiveCalculationImportExportPending: boolean;
    public connectGtmotiveEstimateDialogShown: boolean = false;

    // Manual Calculation
    public manualCalculationDialogShown = false;

    // Comments
    public repairCalculationCommentShown = false;
    public repairCalculationCommentTextTemplateSelectorShown = false;

    // Edit Repair Costs manually
    public editRepairCostsInput: boolean;
    public editedRepairCostsInputValue: number;
    public originalRepairCostsNet: number;
    public originalRepairCostsGross: number;

    // Stores the fetched DEKRA fee result for the currently selected DEKRA zip.
    private selectedDekraFeeSet: GarageFeeSet = null;

    subscriptions: Subscription[] = [];
    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit(): void {
        this.team = this.loggedInUserService.getTeam();
        this.user = this.loggedInUserService.getUser();

        this.getAudatexCalculationText();
        this.showRepairCalculationComment();

        this.subscribeDatCalculationPendingState();
        this.subscribeAudatexPendingState();
    }

    private subscribeDatCalculationPendingState(): void {
        this.subscriptions.push(
            this.datDamageCalculationService.isRequestPending$$.subscribe(
                (pending) => (this.datDamageCalculationPending = pending),
            ),
        );
    }

    private subscribeAudatexPendingState(): void {
        this.subscriptions.push(
            this.audatexTaskService.isRequestPending$$.subscribe(
                (pending) => (this.audatexImportExportPending = pending),
            ),
        );
    }

    private showRepairCalculationComment(): void {
        if (this.report.damageCalculation?.repair?.calculationComment) {
            this.repairCalculationCommentShown = true;
        }
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    public emitValueChange() {
        this.valuesDidChange.emit();
    }

    //*****************************************************************************
    //  Generic Damage Calculation Functions
    //****************************************************************************/
    public toggleRepairCalculationComment(): void {
        this.repairCalculationCommentShown = !this.repairCalculationCommentShown;
    }
    public addDamageCalculationReportDocument(): void {
        this.createDamageCalculationReportDocument();
        this.saveReport();
        const toast = this.toastService.success(
            `Dokument erstellt`,
            'Du findest die Kalkulation als separates Dokument im Reiter <i>Druck & Versand</i>.<br><br>Zum Öffnen diese Nachricht klicken.',
        );
        const subscription = toast.click.subscribe(() => {
            subscription.unsubscribe();
            this.router.navigate(['Gutachten', this.report._id, 'Druck-und-Versand']);
        });
    }

    /**
     * Create a document for the damage calculation in the array "report.documents".
     */
    public createDamageCalculationReportDocument(): void {
        const team = this.loggedInUserService.getTeam();

        addDocumentToReport({
            report: this.report,
            team,
            newDocument: new DocumentMetadata({
                type:
                    this.report.damageCalculation.repair.calculationProvider === 'dat'
                        ? 'datDamageCalculation'
                        : 'manualCalculation',
                title:
                    this.report.damageCalculation.repair.calculationProvider === 'dat'
                        ? 'DAT Kalkulation'
                        : 'Kalkulation',
                uploadedDocumentId: null,
                permanentUserUploadedDocument: false,
                createdAt: moment().format(),
                createdBy: this.user._id,
            }),
            documentGroup: 'report',
        });
    }

    public toggleCreateDocumentMetadataForDamageCalculation(): void {
        this.userPreferences.createDocumentMetadataForDamageCalculation =
            !this.userPreferences.createDocumentMetadataForDamageCalculation;
    }

    public async handleResetDamageCalculationClick($event: MouseEvent) {
        if (this.isReportLocked()) {
            return;
        }

        // If the user clicked this menu entry while holding the Shift key, delete the damage calculation immediately.
        if ($event.shiftKey) {
            $event.preventDefault();
            this.resetDamageCalculation();
        } else {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Kalkulation löschen?',
                        content: 'Sicher? Dieser Schritt kann nicht rückgängig gemacht werden.',
                        confirmLabel: 'Weg damit!',
                        cancelLabel: 'Doch nicht...',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();
            if (decision) {
                this.resetDamageCalculation();
            }
        }
    }
    /**
     * Reset the damage calculation. Only execute if there are values present.
     */
    public async resetDamageCalculation() {
        if (this.isReportLocked()) {
            return;
        }

        switch (this.report.damageCalculation.repair.calculationProvider) {
            case 'dat':
                await this.datDamageCalculationService.reset({ report: this.report });
                break;
            case 'audatex':
                // TODO Implement deleting damage calculation from Audatex servers.
                // this.removeAudatexCalculationText();
                this.clearRepairCalculationData();
                break;
            case 'audatex-textimport':
                this.removeAudatexCalculationText();
                this.clearRepairCalculationData();
                break;
            case 'gtmotive':
                this.removeGtmotiveCalculation();
                this.clearRepairCalculationData();
                break;
            case 'manual':
                this.resetManualCalculation();
                // Resetting is done after the dialog was confirmed by the user
                break;
            case 'estimate':
                this.clearRepairCalculationData();
                break;
            default:
                throw Error(
                    `Trying to reset unknown damage calculation of provider "${this.report.damageCalculation.repair.calculationProvider}".`,
                );
        }
        this.emitValueChange();
    }
    private clearRepairCalculationData(): void {
        clearRepairCalculationData(this.report);
        this.emitValueChange();

        this.saveReport();
    }

    public selectCalculationProvider(calculationProvider: Repair['calculationProvider']): void {
        if (this.isReportLocked()) {
            return;
        }

        this.report.damageCalculation.repair.calculationProvider = calculationProvider;
    }

    public isExternalOnlineCalculationProvider(): boolean {
        return new Array<Repair['calculationProvider']>('dat', 'audatex', 'gtmotive').includes(
            this.report.damageCalculation.repair.calculationProvider,
        );
    }

    public calculateTotalsAndGrossValues(): void {
        // Shorthand
        const calculation = this.report.damageCalculation?.repair;

        if (!calculation) return;

        const vatRate: 0.19 | 0.16 = getVatRate(this.report.completionDate);

        calculation.newForOldGross = calculation.newForOldNet * (1 + vatRate);
        calculation.discountGross = calculation.discountNet * (1 + vatRate);

        // Calculate the total repair costs if the input is in extended display
        if (!this.report.damageCalculation.repair.useSimpleEstimateCalculation) {
            calculation.repairCostsNet =
                calculation.sparePartsCostsNet +
                calculation.garageLaborCostsNet +
                calculation.lacquerCostsNet +
                calculation.auxiliaryCostsNet;
        }
        calculation.repairCostsGross = calculation.repairCostsNet * (1 + vatRate);
        calculation.correctedRepairCostsNet =
            calculation.repairCostsNet - calculation.newForOldNet - calculation.discountNet;
        calculation.correctedRepairCostsGross = calculation.correctedRepairCostsNet * (1 + vatRate);
    }

    public toggleRepairCostEstimateView() {
        // Save on report
        this.report.damageCalculation.repair.useSimpleEstimateCalculation =
            !this.report.damageCalculation.repair.useSimpleEstimateCalculation;

        // Save user preference
        this.userPreferences.useSimpleEstimateCalculation =
            this.report.damageCalculation.repair.useSimpleEstimateCalculation;

        // Remove values that become unnecessary in the new view.
        if (this.report.damageCalculation.repair.useSimpleEstimateCalculation) {
            this.report.damageCalculation.repair.sparePartsCostsNet = null;
            this.report.damageCalculation.repair.garageLaborCostsNet = null;
            this.report.damageCalculation.repair.lacquerCostsNet = null;
            this.report.damageCalculation.repair.auxiliaryCostsNet = null;

            // Recalculate the net repair costs.
            this.calculateTotalsAndGrossValues();
        } else {
            this.report.damageCalculation.repair.repairCostsNet = null;
        }

        this.saveReport();
    }

    public areRepairCostsCorrected(): boolean {
        return (
            this.report.damageCalculation.repair.correctedRepairCostsNet !==
            this.report.damageCalculation.repair.repairCostsNet
        );
    }

    public async convertReportType(type: 'liability' | 'shortAssessment'): Promise<void> {
        await this.reportService.changeReportType(this.report, type);
        this.saveReport();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Generic Damage Calculation Functions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Garage Fees Since Last Export
    //****************************************************************************/
    /**
     * Retains the current garage fees on the report.
     *
     * In order to check whether the current garage fees differ from the ones exported to a calculation
     * provider, we must hold on to the values exported last.
     * @private
     */
    private persistExportedGarageFeeSet(): void {
        persistExportedGarageFeeSet(this.report, this.selectedDekraFeeSet);
        this.saveReport();
    }

    /**
     * Check if the user selected a fee set (either garage or dekra).
     */
    private areGarageFeesSelected(): boolean {
        const selectedFeeSet = getSelectedGarageFeeSet(this.report.garage);
        return !!selectedFeeSet || this.report.damageCalculation.repair.useDekraFees;
    }

    /**
     * Show a confirmation dialog that asks the user whether a calculation should be started without
     * a selected garage (or dekra) fee set.
     */
    private async confirmStartingCalculationWithoutFees(): Promise<boolean> {
        return await this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    heading: 'Kalkulation ohne Kostensätze starten?',
                    content:
                        'Aktuell sind weder DEKRA-Stundensätze noch Werkstatt-Kostensätze eingetragen, sodass du die in der Kalkulation manuell nachtragen müsstest.',
                    confirmLabel: "Egal, los geht's!",
                    cancelLabel: 'Ups, trage ich nach.',
                },
            })
            .afterClosed()
            .toPromise();
    }

    /**
     * Determines whether the currently selected garage fees are equal to the latest exported fees (to a calculation provider).
     * This function currently does not respect dekra fee sets, as they are not stored in the garage and exported fee set.
     * @returns True if the garage fees changed, false if not.
     */
    private didGarageFeesChangeSinceLastExport(): boolean {
        return didGarageFeesChange({
            report: this.report,
            garageFeeSet: this.selectedDekraFeeSet || getSelectedGarageFeeSet(this.report.garage),
        });
    }

    /**
     * Fetch the latest version of the DEKRA fee set.
     */
    private async fetchDekraFees(): Promise<GarageFeeSet> {
        try {
            const dekraZip = getZipForDekraFees({ user: this.user, team: this.team, report: this.report });
            const dekraFees = await this.httpClient.get<DekraResponse>(`/api/v0/dekraFees/${dekraZip}`).toPromise();

            return mapDekraResponseToGarageFees(dekraFees, this.report);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getDekraErrorHandlers(),
                },
                defaultHandler: {
                    title: 'DEKRA-Sätze konnten nicht geladen werden.',
                    body: 'Es wurde nicht überprüft, ob sich die Kostensätze seit dem letzten Export verändert haben.',
                },
            });
        }
    }

    /**
     * In case of an updated garage fee set in autoiXpert after the calculation has been started, inform
     * the user about this fact and have him confirm the re-transmission.
     * @private
     */
    private async confirmRetransmissionOfGarageFees(): Promise<FeeSetHasChangedDialogDecision> {
        return await confirmRetransmissionOfGarageFees({ dialogService: this.dialog });
    }

    /**
     * In case the user chose to use the dekra fee set, fetch and store the current fee set.
     * This is necessary so we can compare if the fee sets have changed when opening a calculation.
     *
     * Rethrowing an error causes the calling function to fail. Relevant if DEKRA fees are required, e.g.
     * when creating a new calculation. May be set to false when only checking for updated DEKRA fees
     * when a calculation is reopened.
     */
    private async updateDekraFeeSet({ rethrowError }: { rethrowError: boolean }): Promise<void> {
        if (this.report.damageCalculation.repair.useDekraFees) {
            try {
                this.selectedDekraFeeSet = await this.fetchDekraFees();
            } catch (error) {
                if (rethrowError) {
                    throw error;
                } else {
                    console.warn(
                        `Fetching DEKRA fees failed when opening an existing damage calculation. Fail silently since the user can work with the fees that were exported last.`,
                    );
                }
            }
        } else {
            this.selectedDekraFeeSet = null;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage Fees Since Last Export
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DAT Damage Calculation
    //****************************************************************************/
    protected isDatUserComplete = isDatUserComplete;
    public async openDatDamageCalculation(): Promise<void> {
        if (this.report.car.identificationProvider !== 'dat') {
            this.toastService.error(
                'DAT-Identifikation fehlt',
                'Die DAT-Kalkulation ist nur verfügbar, wenn die VIN-Abfrage im Tab "Fahrzeugauswahl" über DAT durchgeführt wurde.',
            );
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die DAT-Kalkulation ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        if (!isDatUserComplete(this.user)) {
            this.toastService.error(
                'DAT-Zugangsdaten fehlen',
                'Bitte vervollständige deine DAT-Zugangsdaten in den Einstellungen.',
            );
            return;
        }

        //*****************************************************************************
        //  Open Existing Calculation
        //****************************************************************************/
        if (this.report.damageCalculation.repair.datCalculation?.dossierId) {
            await this.updateDekraFeeSet({ rethrowError: false });

            // We could observe that sometimes, reports had the dossier ID but no provider set. Set it again when opening a calculation.
            if (!this.report.damageCalculation.repair.calculationProvider) {
                this.report.damageCalculation.repair.calculationProvider = 'dat';
                this.saveReport();
            }

            //*****************************************************************************
            //  Re-transmit Fee Set If Changed
            //****************************************************************************/
            /**
             * If the fee set changed in the meantime, the user probably wants to reflect this in the damage calculation he's about to open.
             * Ask him if he wants to transmit the new fee set.
             */
            if (this.areGarageFeesSelected() && this.didGarageFeesChangeSinceLastExport()) {
                // If the exported fee set diverges from the current fee set in autoiXpert, ask the user if he wants to re-export.
                const decisionToReexportGarageFeeSet = await this.confirmRetransmissionOfGarageFees();

                if (decisionToReexportGarageFeeSet.retransmitFeeSet) {
                    await this.transmitDamageCalculationPresetsToDat();
                }

                if (!decisionToReexportGarageFeeSet.openCalculation) {
                    return;
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Re-transmit Fee Set If Changed
            /////////////////////////////////////////////////////////////////////////////*/

            this.openDatCalculation();
            return;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Open Existing Calculation
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Start New Calculation
        //****************************************************************************/
        await this.updateDekraFeeSet({ rethrowError: true });

        // In case no fees are selected -> ask user for confirmation
        if (!this.areGarageFeesSelected() && !(await this.confirmStartingCalculationWithoutFees())) {
            return;
        }

        // If a provider has already been set but the dossier ID is missing, the user probably got to this line of code because of double clicking.
        // Abort the second click handler to prevent duplicate calculation records with DAT.
        if (this.report.damageCalculation.repair.calculationProvider) {
            // If it wasn't a double click but for some reason, the DAT dossier ID is missing, point the user in the right direction.
            if (!this.datDamageCalculationPending) {
                this.toastService.info(
                    'Keine DAT-Kalkulation verknüpft',
                    'Versuche, die Kalkulation zu trennen und neu zu verknüpfen oder zu löschen und neu anzulegen.',
                );
            }
            return;
        }

        await this.createDatCalculation();
        this.openDatCalculation();
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Start New Calculation
        /////////////////////////////////////////////////////////////////////////////*/
    }

    public async createDatCalculation() {
        try {
            await this.datDamageCalculationService.create({
                report: this.report,
                selectedDekraFeeSet: this.selectedDekraFeeSet,
            });
        } catch (error) {
            this.datDamageCalculationService.handleAndRethrow({
                axError: error,
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body:
                        `Die Schadenskalkulation konnte nicht erstellt werden.` +
                        (error.data && error.data.datErrorMessage
                            ? `<br><br>Fehlermeldung der DAT: ${error.data.datErrorMessage}`
                            : ''),
                    partnerLogo: 'dat',
                }),
            });
        }
    }

    public openDatCalculation() {
        this.datDamageCalculationService.openDamageCalculationUI({
            report: this.report,
            triggeringComponent: this,
            onFinish: () => this.retrieveDamageCalculationResults(),
        });
    }

    /**
     * Check if the report is locked and ask to unlock in order to import damage calculation.
     * Return true if report is unlocked or was unlocked, false if locked.
     */
    private async checkIfReportIsNotLockedOrAskToUnlock(): Promise<boolean> {
        if (!this.isReportLocked()) {
            return true;
        }
        const reportShouldBeUnlocked: boolean = await this.dialog
            .open(ConfirmDialogComponent, {
                data: {
                    heading: 'Vorgang ist geschlossen',
                    content:
                        'Die Kalkulation kann nicht erneut importiert werden, da der Vorgang bereits abgeschlossen ist.',
                    confirmLabel: 'Vorgang öffnen & Kalkulation importieren',
                    cancelLabel: 'Nee, bleibt alles wie es ist',
                },
                maxWidth: '700px',
            })
            .afterClosed()
            .toPromise();

        // unlock report
        if (reportShouldBeUnlocked) {
            this.report.state = 'recorded';
            this.saveReport();
        }
        return reportShouldBeUnlocked;
    }

    /**
     * Get damage calculation data from DAT. Only execute if contract ID is present. Otherwise, the request would return a "not found" error.
     */
    public async retrieveDamageCalculationResults() {
        const isReportUnlocked = await this.checkIfReportIsNotLockedOrAskToUnlock();
        if (!isReportUnlocked) return;

        try {
            await this.datDamageCalculationService.retrieveResults({ report: this.report });
        } catch (error) {
            this.datDamageCalculationService.handleAndRethrow({
                axError: error,
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body: `Die Schadenskalkulation konnte nicht importiert werden. ${error.data?.datErrorMessage}`,
                    partnerLogo: 'dat',
                }),
            });
        }

        this.emitValueChange();

        // Show an info note in case the values being used for the diminished value calculation have been changed
        if (
            (this.report.valuation.diminishedValue || this.report.valuation.technicalDiminishedValue) &&
            this.report.valuation.diminishedValueCalculation?.totalRepairCosts !==
                this.report.damageCalculation.repair?.repairCostsGross &&
            this.report.damageCalculation.repair?.repairCostsGross
        ) {
            this.showDiminishedValueNotification.emit(true);
        }
    }

    protected async updateFeesAndTransmitDamageCalculationPresetsToDat(): Promise<void> {
        await this.updateDekraFeeSet({ rethrowError: true });

        await this.transmitDamageCalculationPresetsToDat();
    }

    /**
     * Send data to the DAT. Only execute if contract ID is present. Otherwise, the request would return a "not found" error.
     */
    public async transmitDamageCalculationPresetsToDat(): Promise<void> {
        return this.updateDatCalculation();
    }

    public async updateDatCalculation() {
        try {
            await this.datDamageCalculationService.updateDossier({
                report: this.report,
                selectedDekraFeeSet: this.selectedDekraFeeSet,
            });
        } catch (error) {
            this.datDamageCalculationService.handleAndRethrow({
                axError: error,
                defaultHandler: (error) => ({
                    title: 'Fehler in DAT-Schnittstelle',
                    body: `Die Schadenskalkulation konnte nicht importiert werden. ${error.data?.datErrorMessage}`,
                    partnerLogo: 'dat',
                }),
            });
        }
    }

    toggleEditRepairCostsInput(): void {
        this.editRepairCostsInput = !this.editRepairCostsInput;
    }

    public overwriteRepairCosts(newRepairCostsGross: number): void {
        if (!this.editedRepairCostsInputValue) {
            this.toastService.warn('Ungültiger Wert', 'Bitte trage einen gültigen Reparaturkostenbetrag ein.');
            return;
        }

        this.originalRepairCostsGross = this.report.damageCalculation.repair.correctedRepairCostsGross;
        this.originalRepairCostsNet = this.report.damageCalculation.repair.correctedRepairCostsNet;

        this.report.damageCalculation.repair.correctedRepairCostsNet = newRepairCostsGross / 1.19;
        this.report.damageCalculation.repair.correctedRepairCostsGross = newRepairCostsGross;
        this.report.damageCalculation.repair.repairCostsNet = newRepairCostsGross / 1.19;
        this.report.damageCalculation.repair.repairCostsGross = newRepairCostsGross;
        this.editRepairCostsInput = false;
        this.saveReport();
    }

    public resetOverwrittenRepairCosts() {
        this.report.damageCalculation.repair.correctedRepairCostsGross = this.originalRepairCostsGross;
        this.report.damageCalculation.repair.correctedRepairCostsNet = this.originalRepairCostsNet;
        this.report.damageCalculation.repair.repairCostsGross = this.originalRepairCostsGross;
        this.report.damageCalculation.repair.repairCostsNet = this.originalRepairCostsNet;
        this.saveReport();
    }

    public areRepairCostsChanged(): boolean {
        if (!this.originalRepairCostsGross || !this.originalRepairCostsNet) {
            return false;
        }
        return (
            this.originalRepairCostsNet === this.report.damageCalculation.repair.correctedRepairCostsNet ||
            this.originalRepairCostsGross === this.report.damageCalculation.repair.correctedRepairCostsGross
        );
    }

    public getDatCalculationOpenerTooltip(): string {
        if (this.report.car.identificationProvider !== 'dat') {
            return 'DAT-Kalkulation nur verfügbar, wenn die VIN-Abfrage im Tab "Fahrzeugauswahl" über die DAT durchgeführt wurde.';
        }

        if (!isDatUserComplete(this.user)) {
            return 'Zugangsdaten in den Einstellungen eingeben';
        }

        if (this.isReportLocked()) {
            return 'Gesperrt, weil das Gutachten bereits abgeschlossen ist';
        }

        return 'DAT-Kalkulation starten';
    }

    public async openDatCalculationConnectionDialog() {
        try {
            await this.datDamageCalculationService.openDossierConnectionDialog({ report: this.report });
            this.emitValueChange();
        } catch (error) {
            this.datValuationService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'DAT-Vorgang nicht verbunden',
                    body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        }
    }

    //*****************************************************************************
    //  SZF Import (SilverDAT 2)
    //****************************************************************************/
    public openDatCalculateExpertDossierListTab() {
        let urlCalculateExportScreen = `/assets/dat-schadenskalkulation/index.html?dossierList=true&datCustomerNumber=${this.user.datUser.customerNumber}`;

        // If the user uses a myclaim account, open the myclaim interface.
        if (this.user.datUser.isMyclaimUser) {
            urlCalculateExportScreen += '&myclaim=true';
        }
        // If the user is langsteff, use the DAT test environment gold.dat.de
        if (this.user.datUser.username === 'langsteff') {
            urlCalculateExportScreen += '&useTestEnvironment=true';
        }

        this.newWindowService.open(urlCalculateExportScreen);
        // This will automatically refresh as soon as the user comes back to this page.
        this.openDatCalculationConnectionDialog();

        // Expose a global callback for when the calculation has successfully completed. This is called by
        // the file dat-schadenskalkulation.html.
        // noinspection UnnecessaryLocalVariableJS
        const callbacks: DatUiCallbacks = {
            getDatCredentials: () => this.datAuthenticationService.getJwt(),
            done: () => {},
        };
        (<any>window).datDamageCalculation = callbacks;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END SZF Import (SilverDAT 2)
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Clear all imported data without deleting the DAT calculation from DAT.de
     */
    public unlinkDatDamageCalculation(): void {
        this.datDamageCalculationService.unlink({ report: this.report });
        this.emitValueChange();
    }

    public downloadVxs(): void {
        this.datDamageCalculationService.downloadVxs({ report: this.report });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT Damage Calculation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Audatex Online Calculation
    //****************************************************************************/
    protected isAudatexUserComplete = isAudatexUserComplete;

    public getAudatexCalculationOpenerTooltip(): string {
        if (this.report.car.identificationProvider !== 'audatex') {
            return 'Audatex-Kalkulation nur verfügbar, wenn die VIN-Abfrage im Tab "Fahrzeugauswahl" über Audatex durchgeführt wurde.';
        }

        if (!isAudatexUserComplete(this.user)) {
            return 'Zugangsdaten in den Einstellungen eingeben';
        }

        if (this.isReportLocked()) {
            return 'Gesperrt, weil das Gutachten bereits abgeschlossen ist';
        }

        return 'Audatex-Kalkulation starten';
    }

    /**
     * Open Audatex calculation.
     * If a task has not been created yet, create it here.
     */
    public async openAudatexDamageCalculation() {
        if (this.report.car.identificationProvider !== 'audatex') {
            this.toastService.error(
                'Audatex-Identifikation fehlt',
                'Die Audatex-Kalkulation ist nur verfügbar, wenn die VIN-Abfrage im Tab "Fahrzeugauswahl" über Audatex durchgeführt wurde.',
            );
            return;
        }

        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Audatex-Kalkulation ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        // Whether exporting for the first time
        const firstTimeExport: boolean = !this.report.damageCalculation.repair.exportedFeeSet;

        // In case no fees are selected -> ask user for confirmation (only first time)
        if (firstTimeExport && !this.areGarageFeesSelected() && !(await this.confirmStartingCalculationWithoutFees())) {
            return;
        }

        this.report.damageCalculation.repair.calculationProvider = 'audatex';

        /**
         * Create Audatex task if necessary. Then open calculation.
         */
        if (!this.report.audatexTaskId) {
            try {
                await this.audatexTaskService.create({ report: this.report });
            } catch (error) {
                this.audatexTaskService.handleAndRethrow({
                    axError: error,
                    report: this.report,
                    defaultHandler: {
                        title: 'Audatex-Task nicht angelegt',
                        body: 'Das ist ein technisches Problem. Versuche es erneut. Sollte diese Fehlermeldung erneut erscheinen, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }
        }

        await this.updateDekraFeeSet({ rethrowError: firstTimeExport });

        /**
         * The first time the user starts the Audatex damage calculation, export all relevant data from autoiXpert to Audatex.
         *
         * If the user changes data in autoiXpert after the first export, do not overwrite data within Audatex. Otherwise, changes made within Audatex would be overwritten.
         */
        if (firstTimeExport) {
            try {
                await this.exportDataToAudatex({ displaySuccessMessage: false });
            } catch (error) {
                // This allows the user to choose a different calculation if Audatex can't be started for any reason.
                if (firstTimeExport) {
                    this.report.damageCalculation.repair.calculationProvider = undefined;
                    return;
                }
            }
        }

        //*****************************************************************************
        //  Re-transmit Fee Set If Changed
        //****************************************************************************/
        /**
         * If the fee set changed in the meantime, the user probably wants to reflect this in the damage calculation he's about to open.
         * Ask him if he wants to transmit the new fee set.
         */
        if (!firstTimeExport && this.areGarageFeesSelected() && this.didGarageFeesChangeSinceLastExport()) {
            // If the exported fee set diverges from the current fee set in autoiXpert, ask the user if he wants to re-export.
            const decisionToReexportGarageFeeSet = await this.confirmRetransmissionOfGarageFees();

            if (decisionToReexportGarageFeeSet.retransmitFeeSet) {
                await this.exportDataToAudatex({ displaySuccessMessage: true });
            }

            if (!decisionToReexportGarageFeeSet.openCalculation) {
                return;
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Re-transmit Fee Set If Changed
        /////////////////////////////////////////////////////////////////////////////*/

        // Persist provider selection and exportedGarageFeeSet.
        await this.saveReport();

        await this.audatexTaskService.openUI({ report: this.report });
    }

    public async importAudatexCalculation({
        automaticImport = false,
    }: {
        // Set to true if not the user triggered the import but a window focus event.
        automaticImport?: boolean;
    } = {}) {
        const isReportUnlocked = await this.checkIfReportIsNotLockedOrAskToUnlock();
        if (!isReportUnlocked) {
            return;
        }

        try {
            await this.audatexTaskService.retrieveResults({
                report: this.report,
                requireCalculation: true,
                requireValuation: false,
            });

            // Show an info note in case the values being used for the diminished value calculation have been changed
            if (
                (this.report.valuation.diminishedValue || this.report.valuation.technicalDiminishedValue) &&
                this.report.valuation.diminishedValueCalculation?.totalRepairCosts !==
                    this.report.damageCalculation.repair?.repairCostsGross &&
                this.report.damageCalculation.repair?.repairCostsGross
            ) {
                this.showDiminishedValueNotification.emit(true);
            }
        } catch (error) {
            /**
             * Errors in an automatic import should not be shown to the user. When the user switches between his photos and the calculation,
             * he would otherwise be shown errors within autoiXpert all the time.
             */
            if (automaticImport) {
                this.audatexTaskService.abortPendingIndicator();
            }

            this.audatexTaskService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'Kalkulations-Import fehlgeschlagen',
                    body: 'Die Audatex-Kalkulation konnte nicht importiert werden. Versuche es erneut. Erscheint diese Meldung erneut, wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    protected async updateFeesAndExportDataToAudatex(): Promise<void> {
        await this.updateDekraFeeSet({ rethrowError: true });
        await this.exportDataToAudatex({ displaySuccessMessage: true });
    }

    public async exportDataToAudatex({
        displaySuccessMessage = false,
    }: {
        displaySuccessMessage: boolean;
    }): Promise<void> {
        try {
            await this.audatexTaskService.update({
                report: this.report,
                options: {
                    garageFeeSet: this.selectedDekraFeeSet || getSelectedGarageFeeSet(this.report.garage),
                },
            });
            if (displaySuccessMessage) {
                this.toastService.success('Export erfolgreich', 'Die Daten wurden erfolgreich zu Audatex übertragen.');
            }
        } catch (error) {
            this.audatexTaskService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'Export fehlgeschlagen',
                    body: 'Die Daten konnten nicht zu Audatex exportiert werden. Versuche es erneut. Erscheint diese Meldung erneut, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    public async openAudatexTaskConnectionDialog() {
        try {
            await this.audatexTaskService.openTaskConnectionDialog({ report: this.report });
        } catch (error) {
            this.audatexTaskService.handleAndRethrow({
                axError: error,
                report: this.report,
                defaultHandler: {
                    title: 'Audatex-Task nicht verbunden',
                    body: 'Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    /**
     * Clear all imported data without deleting the Audatex task.
     */
    public unlinkAudatexTask(): void {
        this.audatexTaskService.unlink({ report: this.report });
        this.emitValueChange();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex Online Calculation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Audatex Text Calculation
    //****************************************************************************/
    public parseAudatexCalculation(): void {
        if (!this.audatexCalculationText) return;

        this.removePretrailingSpaces();
        this.shortenSeparationDashes();
        this.removeInvalidCharactersFromAudatexCalculationText();

        /**
         * Glossary
         * KL = Lohn*KL*asse (wage class). Some manufacturers distinguish between the difficulty of different types of labor. Their wages are adjusted accordingly. Audatex details exist at https://www.werkstatt.audatex.de/lohnklassifizierungen-lohn-und-lackfaktoren/
         */

        // Work fraction unit
        const workFractionUnitGeneralLabor =
            this.findWorkFractionUnitInAudatexCalculation('A R B E I T S L O H N\\s+ZEITBASIS') || 1; // If not present, don't divide = divide by one
        const workFractionUnitLacquerLabor =
            this.findWorkFractionUnitInAudatexCalculation('L A C K I E R U N G\\s+ZEITBASIS') || 1;

        // General labor hours
        const garageLaborHours =
            this.sumUpWorkFractionsInAudatexCalculation('(?:GESAMT KL|VERMESSEN KL|GESAMT\\s+)') /
            workFractionUnitGeneralLabor;
        const garageLaborCostsNet = this.getSingleValueInAudatexCalculation('GESAMTSUMME ARBEITSLOHN');
        // Auxiliary costs
        const auxiliaryCostsTotal = this.getSingleValueInAudatexCalculation('GESAMTSUMME NEBENKOSTEN');
        // Paint
        const lacquerLaborHours =
            this.sumUpWorkFractionsInAudatexCalculation('ARBEITSLOHN') / workFractionUnitLacquerLabor;
        const lacquerLaborCostsNet = this.sumUpCostsInAudatexCalculation('(?:ARBEITSLOHN|ARBEITSKOSTEN)');
        const lacquerCostsNet = this.getSingleValueInAudatexCalculation('GESAMTSUMME LACKIERUNG');
        const lacquerMaterialCostsNet =
            this.sumUpCostsInAudatexCalculation('MATERIALKOSTEN') || lacquerCostsNet - lacquerLaborCostsNet;
        // Spare parts
        const sparePartsMaterial = this.getSingleValueInAudatexCalculation('GESAMTSUMME ERSATZTEILE');
        // Totals
        const repairCostsNet = this.getSingleValueInAudatexCalculation('R E P A R A T U R K O S T E N\\s+OHNE MWST');
        const repairCostsGross = this.getSingleValueInAudatexCalculation('R E P A R A T U R K O S T E N\\s+MIT MWST');
        const correctedRepairCostsNet = this.getSingleValueInAudatexCalculation('G E S A M T B E T R A G\\s+OHNE MWST');
        const correctedRepairCostsGross = this.getSingleValueInAudatexCalculation(
            'G E S A M T B E T R A G\\s+MIT MWST',
        );
        // Discounts
        const valueIncrease = Math.abs(this.getSingleValueInAudatexCalculation('GESAMTSUMME ABZUG'));

        const repair = this.report.damageCalculation.repair;
        //*****************************************************************************
        //  Garage/Body Shop
        //****************************************************************************/
        repair.sparePartsCostsNet = sparePartsMaterial;
        repair.auxiliaryCostsNet = auxiliaryCostsTotal;

        repair.bodyworkLaborHours = undefined;
        repair.electricLaborHours = undefined;
        repair.mechanicLaborHours = undefined;
        repair.garageLaborHours = garageLaborHours;
        repair.garageLaborCostsNet = garageLaborCostsNet;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Garage/Body Shop
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Lacquer
        //****************************************************************************/
        repair.lacquerMaterialCostsNet = lacquerMaterialCostsNet;
        // Save "undefined" instead of NaN if one or both of the addends are undefined.
        repair.lacquerLaborCostsNet = lacquerLaborCostsNet;
        repair.lacquerLaborHours = lacquerLaborHours;
        repair.lacquerCostsNet = lacquerCostsNet;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Lacquer
        /////////////////////////////////////////////////////////////////////////////*/

        repair.repairCostsNet = repairCostsNet;
        repair.repairCostsGross = repairCostsGross;

        //*****************************************************************************
        //  Subtractions
        //****************************************************************************/
        repair.discountNet = null;
        repair.discountGross = null;
        // TODO Read discounts, new-for-old and value increase from the Audatex calculation separately.
        //  Until now (2021-03-27) we haven't encountered a calculation with these values, yet.
        repair.newForOldNet = valueIncrease;
        repair.newForOldGross = valueIncrease * 1.19;
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Subtractions
        /////////////////////////////////////////////////////////////////////////////*/
        repair.correctedRepairCostsNet = correctedRepairCostsNet || repairCostsNet;
        repair.correctedRepairCostsGross = correctedRepairCostsGross || repairCostsGross;

        // TODO Check for approximate calculation (German "überschlägige Kalkulation")
        repair.isApproximateCalculation = undefined;
        // TODO Check for phantom calculation.
        repair.isPhantomCalculation = undefined;

        repair.calculationProvider = 'audatex';
        repair.documentHash = simpleHash(this.audatexCalculationText.content);
    }

    /**
     * Pasting an Audatex Calculation from Qapter (web-based Audatex software) causes blanks before every line. Remove these blanks.
     */
    private removePretrailingSpaces(): void {
        // Find the group of spaces in the first line that contains text.
        const matches = this.audatexCalculationText.content.match(/^([ ]*)\w.+$/m);

        if (!matches) return;

        const pretrailingSpaces = matches[1];

        const removePretrailingSpacesRegex = new RegExp(`^[ ]{${pretrailingSpaces.length}}`, 'gm');
        this.audatexCalculationText.content = this.audatexCalculationText.content.replace(
            removePretrailingSpacesRegex,
            '',
        );
    }

    private shortenSeparationDashes(): void {
        this.audatexCalculationText.content = this.audatexCalculationText.content.replace(
            '-------------------------------------------------------',
            '------------------------------------------------------',
        );
    }

    private removeInvalidCharactersFromAudatexCalculationText(): void {
        //eslint-disable-next-line no-control-regex
        const invalidCharacterRegex = /[\x00-\x08\x0B\x0C\x0E-\x1F]/;

        if (invalidCharacterRegex.test(this.audatexCalculationText.content)) {
            this.audatexCalculationText.content = this.audatexCalculationText.content.replace(
                /**
                 * Remove all invalid characters that may cause rendering issues in a DOCX file.
                 *
                 * Source: https://stackoverflow.com/questions/21053138/c-sharp-hexadecimal-value-0x12-is-an-invalid-character
                 * In the original stackoverflow answer, "\x26" was included as well. But since that's just a regular "&" character which is serialized when the
                 * document building blocks are added to the DOCX file, there is no need to mark it.
                 */
                //eslint-disable-next-line no-control-regex
                /[\x00-\x08\x0B\x0C\x0E-\x1F]/,
                '',
            );
            console.log(`Found an replaced invalid characters in calculation during Audatex text import.`);
        }
    }

    /**
     * Returns the number of a given audatex calculation value.
     *
     * Restriction: It must be a floating point value at the end of the line that beings with the label
     * passed as a parameter
     *
     * Format in the calculation is similar to
     * ```
     * GESAMTSUMME ARBEITSLOHN .....................................     780.00
     * ```
     * or
     * ```
     * GESAMTSUMME ERSATZTEILE .....................................   1 844.78
     * ```
     *
     * @param label
     * @param firstMatchOnly
     */
    private sumUpCostsInAudatexCalculation(label: string, firstMatchOnly = false): number {
        const regex = new RegExp(`^\\s*${label}.*?([\\d\\s]+\\.\\d{2})(-?)$`, 'gm');

        let match: RegExpMatchArray;
        let totalCosts = 0;
        while ((match = regex.exec(this.audatexCalculationText.content)) !== null) {
            const value = match[1];
            const sign = match[2];
            totalCosts += this.parseAsNumber(`${sign}${value}`);
            if (firstMatchOnly) break;
        }
        return totalCosts;
    }

    /**
     * Find a single value in the Audatex calculation
     * @param label
     */
    private getSingleValueInAudatexCalculation(label: string) {
        return this.sumUpCostsInAudatexCalculation(label, true);
    }

    private sumUpWorkFractionsInAudatexCalculation(label: string): number {
        const regex = new RegExp(`^\\s*${label}.*?([\\d]+) AW`, 'gm');

        let match: RegExpMatchArray;
        let totalHours = 0;
        while ((match = regex.exec(this.audatexCalculationText.content)) !== null) {
            totalHours += this.parseAsNumber(match[1]);
        }
        return totalHours;
    }

    private findWorkFractionUnitInAudatexCalculation(label: string): number {
        const match = this.audatexCalculationText.content.match(new RegExp(`${label}.*?([\\d]+) AW = 1 STD`, 'm'));
        if (!match) {
            return null;
        }
        return this.parseAsNumber(match[1]);
    }

    private parseAsNumber(value: string): number {
        if (!value) {
            return null;
        } else {
            // Remove any whitespaces before parsing
            return parseFloat(value.replace(/\s/g, ''));
        }
    }

    public showAudatexCalculationInsertionDialog(): void {
        if (this.isReportLocked()) return;

        if (!this.audatexCalculationText) {
            this.audatexCalculationText = new AudatexCalculationText({
                _id: this.report._id,
            });
        }

        this.audatexCalculationInsertionDialogShown = true;
    }

    public hideAudatexCalculationInsertionDialog(): void {
        this.audatexCalculationInsertionDialogShown = false;
    }

    public getAudatexCalculationText(): void {
        if (this.report.damageCalculation?.repair.calculationProvider !== 'audatex-textimport') return;

        this.audatexCalculationTextService.get(this.report._id).subscribe({
            next: (calculationText) => {
                this.audatexCalculationText = calculationText;
            },
        });
    }

    public saveAudatexCalculation(): void {
        this.audatexCalculationTextService.upsert(this.audatexCalculationText).subscribe({
            error: (err) => {
                this.toastService.error(
                    'Audatex-Kalkulation nicht gespeichert',
                    'Besteht eine Verbindung zum Internet?',
                );
                console.error(err);
            },
        });
    }

    private removeAudatexCalculationText(): void {
        const audatexCalculationText = this.audatexCalculationText;

        this.audatexCalculationText = null;

        this.audatexCalculationTextService.remove(this.report._id).subscribe({
            error: () => {
                // Restore previous calculation if deletion failed
                this.audatexCalculationText = audatexCalculationText;
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Audatex Text Calculation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  GT Motive Calculation
    //****************************************************************************/
    public areGtmotiveCredentialsComplete(): boolean {
        return !!(this.user?.gtmotiveUser?.userId && this.user.gtmotiveUser.customerId);
    }

    public getGtmotiveCalculationOpenerTooltip(): string {
        if (this.report.car.identificationProvider !== 'gtmotive') {
            return 'GT-Motive-Kalkulation nur verfügbar, wenn die VIN-Abfrage im Tab "Fahrzeugauswahl" über GT-Motive durchgeführt wurde.';
        }
        if (!this.areGtmotiveCredentialsComplete()) {
            return 'Zugangsdaten in den Einstellungen eingeben';
        }

        if (this.isReportLocked()) {
            return 'Gesperrt, weil das Gutachten bereits abgeschlossen ist';
        }

        return 'GT-Motive-Kalkulation starten';
    }

    /**
     * Open GT Motive calculation.
     * If a task has not been created yet, create it here.
     */
    public async openGtmotiveCalculation(): Promise<void> {
        if (this.report.car.identificationProvider !== 'gtmotive') {
            this.toastService.error(
                'GT Motive Identifikation fehlt',
                'Die Kalkulation mit GT Motive ist nur verfügbar, wenn die VIN-Abfrage im Tab "Fahrzeugauswahl" über GT Motive durchgeführt wurde.',
            );
            return;
        }
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Die Kalkulation von GT Motive ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }
        const firstTimeExport: boolean = this.report.damageCalculation.repair.calculationProvider !== 'gtmotive';

        // In case no fees are selected -> ask user for confirmation (only first time)
        if (firstTimeExport && !this.areGarageFeesSelected() && !(await this.confirmStartingCalculationWithoutFees())) {
            return;
        }

        this.report.damageCalculation.repair.calculationProvider = 'gtmotive';

        await this.updateDekraFeeSet({ rethrowError: firstTimeExport });

        /**
         * Create GT Motive estimate if necessary. Then open calculation.
         */
        if (!this.report.gtmotiveEstimateId) {
            try {
                this.gtmotiveCalculationOpeningPending = true;
                const creationResponse = await this.gtmotiveEstimateService.create(this.report._id);
                this.report.gtmotiveEstimateId = creationResponse.gtmotiveEstimateId;

                // Save estimate ID to the server. The server must know the process ID to export the repair garage's wages.
                await this.saveReport({ waitForServer: true });
                this.gtmotiveCalculationOpeningPending = false;
            } catch (error) {
                this.gtmotiveCalculationOpeningPending = false;

                // This allows the user to choose a different calculation if GT Motive can't be started for any reason.
                if (firstTimeExport) {
                    this.report.damageCalculation.repair.calculationProvider = undefined;
                }

                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getGtmotiveErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'GT-Motive-Export fehlgeschlagen',
                        body: 'Die Daten konnten nicht zu GT Motive übertragen werden. Versuche es erneut. Sollte diese Fehlermeldung erneut erscheinen, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }
        }

        //*****************************************************************************
        //  Re-transmit Fee Set If Changed
        //****************************************************************************/
        /**
         * If the fee set changed in the meantime, the user probably wants to reflect this in the damage calculation he's about to open.
         * Ask him if he wants to transmit the new fee set.
         */
        if (!firstTimeExport && this.areGarageFeesSelected() && this.didGarageFeesChangeSinceLastExport()) {
            // If the exported fee set diverges from the current fee set in autoiXpert, ask the user if he wants to re-export.
            const decisionToReexportGarageFeeSet = await this.confirmRetransmissionOfGarageFees();

            if (decisionToReexportGarageFeeSet.retransmitFeeSet) {
                await this.exportDataToGtmotive();
            }

            if (!decisionToReexportGarageFeeSet.openCalculation) {
                this.gtmotiveCalculationOpeningPending = false;
                return;
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Re-transmit Fee Set If Changed
        /////////////////////////////////////////////////////////////////////////////*/

        /**
         * Export all relevant data from autoiXpert to GT Motive when exporting for the first time.
         * Export almost no data if an estimate exists already. This almost empty update seems unnecessary, but it is the only way GT Motive allows
         * retrieving the GT Motive interface URL which is required for opening the damage calculation.
         */
        this.gtmotiveCalculationOpeningPending = true;
        let gtmotivePatchResponse: GtmotivePatchResponse;
        try {
            gtmotivePatchResponse = await this.gtmotiveEstimateService.patch(
                this.report._id,
                this.report.gtmotiveEstimateId,
                !firstTimeExport,
            );
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGtmotiveErrorHandlers(),
                },
                defaultHandler: {
                    title: 'GT Motive Kalkulation nicht geöffnet',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        } finally {
            this.gtmotiveCalculationOpeningPending = false;
        }
        this.newWindowService.open(gtmotivePatchResponse.gtmotiveUserInterfaceUrl);
    }

    public async importGtmotiveCalculation({
        automaticImport = false,
    }: {
        // Set to true if not the user triggered the import but a window focus event.
        automaticImport?: boolean;
    } = {}) {
        const isReportUnlocked = await this.checkIfReportIsNotLockedOrAskToUnlock();
        if (!isReportUnlocked) {
            return;
        }

        this.gtmotiveCalculationImportExportPending = true;

        let gtmotiveFindResponse: GtmotiveEstimateFindResponse;
        try {
            gtmotiveFindResponse = await this.gtmotiveEstimateService.find(this.report._id, {
                importCalculationDocument: true,
            });

            // Show an info note in case the values being used for the diminished value calculation have been changed
            if (
                (this.report.valuation.diminishedValue || this.report.valuation.technicalDiminishedValue) &&
                this.report.valuation.diminishedValueCalculation?.totalRepairCosts !==
                    this.report.damageCalculation.repair?.repairCostsGross &&
                this.report.damageCalculation.repair?.repairCostsGross
            ) {
                this.showDiminishedValueNotification.emit(true);
            }
        } catch (error) {
            this.gtmotiveCalculationImportExportPending = false;
            /**
             * Errors in an automatic import should not be shown to the user. When the user switches between his photos and the calculation,
             * he would otherwise be shown errors within autoiXpert all the time.
             */
            if (automaticImport) {
                return;
            }

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGtmotiveErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Kalkulations-Import fehlgeschlagen',
                    body: 'Die GT-Motive-Kalkulation konnte nicht importiert werden. Versuche es erneut. Erscheint diese Meldung erneut, wende dich an die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
        this.gtmotiveCalculationImportExportPending = false;

        if (!gtmotiveFindResponse.repair.repairCostsNet && !automaticImport) {
            this.toastService.warn(
                'Unfertige Kalkulation',
                'Klicke in der GT-Motive-Oberfläche links auf den Menüeintrag <strong>Kalkulieren</strong>, um Ergebnisse importieren zu können.',
            );
        }

        Object.assign<Repair, GtmotiveEstimateFindResponse['repair']>(
            this.report.damageCalculation.repair,
            gtmotiveFindResponse.repair,
        );

        // If the user wants the damage calculation to be available as an individual document in the print and send screen, create it here.
        if (this.userPreferences.createDocumentMetadataForDamageCalculation) {
            this.createDamageCalculationReportDocument();
        }

        determineDamageType(this.report, this.userPreferences);
        this.saveReport();
    }

    protected async updateFeesAndExportToGtmotive(): Promise<void> {
        await this.updateDekraFeeSet({ rethrowError: true });
        this.exportDataToGtmotive();
    }

    public async exportDataToGtmotive(): Promise<void> {
        this.gtmotiveCalculationImportExportPending = true;
        try {
            await this.gtmotiveEstimateService.patch(this.report._id, this.report.gtmotiveEstimateId);
            this.toastService.success('Export erfolgreich', 'Die Daten wurden erfolgreich zu GT Motive übertragen.');
        } catch (error) {
            this.gtmotiveCalculationImportExportPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGtmotiveErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Export fehlgeschlagen',
                    body: 'Die Daten konnten nicht zu GT Motive exportiert werden.',
                },
            });
        }

        this.gtmotiveCalculationImportExportPending = false;
        this.persistExportedGarageFeeSet();
    }

    public async removeGtmotiveCalculation() {
        try {
            await this.gtmotiveEstimateService.delete(this.report._id);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGtmotiveErrorHandlers(),
                },
                defaultHandler: {
                    title: 'GT Motive Kalkulation nicht gelöscht',
                    body: 'Bitte versuche es erneut. Bleibt der Fehler bestehen, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        // Clear the GT Motive Estimate ID so that we can create a new one after the user has deleted the current one.
        this.report.gtmotiveEstimateId = null;
        await this.saveReport();
    }

    /**
     * Open a selection dialog that allows connecting an Audatex task with this report.
     */
    public openGtmotiveEstimateConnectionDialog(): void {
        this.connectGtmotiveEstimateDialogShown = true;
    }

    public async handleGtmotiveEstimateConnection(gtmotiveEstimateId: Report['gtmotiveEstimateId']) {
        // This happens if the user cancels the selection process.
        if (!gtmotiveEstimateId) return;

        this.report.damageCalculation.repair.calculationProvider = 'gtmotive';
        this.report.gtmotiveEstimateId = gtmotiveEstimateId;

        // Save the report so that the server knows about the new task ID, then retrieve the results.
        try {
            await this.saveReport({ waitForServer: true });
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getGtmotiveErrorHandlers(),
                },
                defaultHandler: {
                    title: 'GT-Motive-Vorgang nicht verbunden',
                    body: `Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
                },
            });
        }

        /**
         * Don't add the automatic import parameter since this can be interpreted as a manual import since the user triggers this import through linking an existing estimate.
         * The user should know why linking the task did not work.
         */
        this.importGtmotiveCalculation();
    }

    /**
     * Clear all imported data without deleting the Audatex task.
     */
    public unlinkGtmotiveEstimate(): void {
        // Prevent unlinking on locked reports.
        if (this.isReportLocked()) return;

        // Ensure unlinking the entire GT Motive estimate.
        this.report.gtmotiveEstimateId = undefined;

        // This triggers this.saveReport(), so no need to execute it again.
        this.clearRepairCalculationData();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END GT Motive Calculation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Manual Calculation
    //****************************************************************************/
    public showManualCalculationDialog(): void {
        if (this.report.damageCalculation.repair.useDekraFees) {
            this.toastService.info(
                'DEKRA-Sätze nicht unterstützt',
                'Bitte trage eine Werkstatt mit Kostensätzen ein, bevor du die manuelle Kalkulation startest.',
            );
            return;
        }

        const garageFeeSet = getSelectedGarageFeeSet(this.report.garage);

        // If neither wage is defined, don't let the user open the manual calculation.
        const atLeastOneWageDefined =
            garageFeeSet &&
            !!(
                garageFeeSet.mechanics.firstLevel ||
                garageFeeSet.electrics.firstLevel ||
                garageFeeSet.carBody.firstLevel ||
                garageFeeSet.carPaint
            );
        if (!atLeastOneWageDefined) {
            this.toastService.info(
                'Kostensätze erforderlich',
                'Bitte trage zuerst eine Werkstatt mit Kostensätzen ein, bevor du die manuelle Kalkulation startest.',
            );
            return;
        }

        this.selectCalculationProvider('manual');

        this.manualCalculationDialogShown = true;
        this.saveReport();
    }

    public hideManualCalculationDialog(): void {
        this.manualCalculationDialogShown = false;

        // Show an info note in case the values being used for the diminished value calculation have been changed
        if (
            (this.report.valuation.diminishedValue || this.report.valuation.technicalDiminishedValue) &&
            this.report.valuation.diminishedValueCalculation?.totalRepairCosts !==
                this.report.damageCalculation.repair?.repairCostsGross &&
            this.report.damageCalculation.repair?.repairCostsGross
        ) {
            this.showDiminishedValueNotification.emit(true);
        }
    }

    private resetManualCalculation(): void {
        this.report.damageCalculation.repair.manualCalculation = new ManualCalculation();
        this.clearRepairCalculationData();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Manual Calculation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    @HostListener('window:visibilitychange', ['$event'])
    protected importPendingMarketAnalyses() {
        if (document.visibilityState === 'visible') {
            if (!this.report) return;

            // Import Audatex calculation
            if (
                this.report.audatexTaskId &&
                this.report.damageCalculation?.repair?.calculationProvider === 'audatex' &&
                !this.report.damageCalculation.repair.correctedRepairCostsNet
            ) {
                this.importAudatexCalculation({ automaticImport: true });
            }

            // Import GT Motive calculation
            if (
                this.report.gtmotiveEstimateId &&
                this.report.damageCalculation?.repair?.calculationProvider === 'gtmotive' &&
                !this.report.damageCalculation.repair.correctedRepairCostsNet
            ) {
                this.importGtmotiveCalculation({ automaticImport: true });
            }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  UTIL
    //***************************************************************************/
    protected readonly fullTaxationRate = fullTaxationRate;

    public isReportLocked(): boolean {
        return this.report.state === 'done';
    }

    /**
     * Save reports to the server.
     */
    public saveReport({ waitForServer }: { waitForServer?: boolean } = {}): Promise<Report> {
        if (this.isReportLocked()) {
            return;
        }

        return this.reportDetailsService.patch(this.report, { waitForServer }).catch((error) => {
            this.toastService.error('Fehler beim Sync', 'Bitte versuche es später erneut');
            console.error('An error occurred while saving the report via the ReportService.', this.report, { error });
            throw error;
        });
    }

    public saveTeam(): Promise<Team> {
        return this.teamService.put(this.team);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END UTIL
    /////////////////////////////////////////////////////////////////////////////*/
}

import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject, InjectionToken } from '@angular/core';
import { extractConstructionTimesFromDatBaseModelName } from '@autoixpert/lib/car/extract-construction-times-from-dat-base-model-name';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { Report } from '@autoixpert/models/reports/report';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { NewWindowService } from '../../services/new-window.service';
import { UserPreferencesService } from '../../services/user-preferences.service';

@Component({
    selector: 'app-report-list-overlay',
    templateUrl: './report-list-overlay.component.html',
    styleUrls: ['./report-list-overlay.component.scss'],
    animations: [fadeInAndSlideAnimation()],
})
export class ReportListOverlayComponent {
    constructor(
        @Inject(REPORTS_TOKEN) public reports: Report[],
        @Inject(REPORTS_DAMAGE_VALUE_TOKEN) public reportsWithIdAndDamageValue: { _id: string; damageValue: number }[],
        private newWindowService: NewWindowService,
        public userPreferences: UserPreferencesService,
        private overlayRef: OverlayRef,
    ) {}

    //*****************************************************************************
    //  damage cost
    //****************************************************************************/
    public retrieveDamageValue(reportId: Report['_id']) {
        return this.reportsWithIdAndDamageValue.find((report) => report._id === reportId)?.damageValue;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END damage cost
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Locked State
    //****************************************************************************/
    public isReportLocked(report: Report): boolean {
        return report.state === 'done';
    }
    //*****************************************************************************
    //  Car Brand Icons
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Car Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Base Model
    //****************************************************************************/
    /**
     * Make base model more readible by removing technical details.
     *
     * DAT base models come with additional info, such as construction times as in
     * "Audi A3 Sportback (8PA)(09.2004->)". This additional info is removed because it's
     * no relevant in this overview.
     * @param baseModel
     */
    public cleanBaseModel(baseModel: string): string {
        return extractConstructionTimesFromDatBaseModelName(baseModel)?.baseModelName;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Base Model
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public openReport(report: Report) {
        this.newWindowService.open(`/Gutachten/${report._id}`);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }
}

export const REPORTS_TOKEN = new InjectionToken('REPORTS_TOKEN');
export const REPORTS_DAMAGE_VALUE_TOKEN = new InjectionToken('REPORTS_DAMAGE_VALUE_TOKEN');

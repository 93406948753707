import { RequireSome } from '../../helper-types/require-some';
import { generateId } from '../../lib/generate-id';
import { DocumentAndSchemaVersion } from '../document-and-schema-version';
import { HexColor } from '../hex-color';
import { Team } from '../teams/team';
import { User } from '../user/user';

/**
 * The configuration for assigning labels to records such as reports and invoices.
 *
 * If the config is updated (name or color are changed), the application logic should
 * update all labels attached to a record.
 *
 * We have convened on this approach because reading happens often, writing (changing a label) occurs
 * rarely.
 */
export class LabelConfig implements DocumentAndSchemaVersion<1> {
    constructor(template: RequireSome<LabelConfig, 'labelGroup'>) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    /**
     * The name of the label within the UI.
     * When changed in this config object, the application should update all assigned labels.
     */
    name: string;
    /**
     * The color of the label within the UI.
     * When changed in this config object, the application should update all assigned labels.
     */
    color: HexColor;
    /**
     * What record type or location does this label belong to?
     * e.g. invoices, reports.
     *
     * This helps to filter the labels depending on the location in the UI.
     */
    labelGroup: LabelGroup;

    dragOrderPosition: number;

    createdBy: User['_id'];
    teamId: Team['_id'];

    updatedAt: string;
    createdAt: string;

    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
}

export type LabelGroup = 'invoice' | 'report' | 'task';

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { LineItem } from '@autoixpert/models/invoices/line-item';
import {
    CorrectionItemType,
    ReplacementValueCorrectionItem,
} from '@autoixpert/models/reports/replacement-value-corrections/replacement-value-correction-item';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { ReplacementValueCorrectionLineComponent } from '../replacement-value-correction-line/replacement-value-correction-line.component';

@Component({
    selector: 'replacement-value-correction-decreases-increases',
    templateUrl: './replacement-value-correction-decreases-increases.component.html',
    styleUrls: ['./replacement-value-correction-decreases-increases.component.scss'],
})
export class ReplacementValueCorrectionDecreasesIncreasesComponent {
    @Input() report: Report;
    @Input() team: Team;
    @Input() user: User;

    @Output() valuesChanged: EventEmitter<void> = new EventEmitter();

    @ViewChildren('descriptionInputDecrease')
    descriptionInputsValueDecrease: QueryList<ReplacementValueCorrectionLineComponent>;
    @ViewChildren('descriptionInputIncrease')
    descriptionInputsValueIncrease: QueryList<ReplacementValueCorrectionLineComponent>;

    get isReportLocked() {
        return this.report.state === 'done';
    }

    /**
     * User dragged & dropped a line item somewhere else. Reorder the list of corrections.
     */
    protected reorderLineItemsArray(event: CdkDragDrop<LineItem[]>, type: CorrectionItemType): void {
        const lineItems = this.getLineItems(type);

        const movedLineItem = lineItems.splice(event.previousIndex, 1)[0];
        // Add the item back at the new position
        lineItems.splice(event.currentIndex, 0, movedLineItem);
        // Save the new order back to the server.
        this.emitChange();
    }

    /**
     * User wants to delete one of the corrections. Remove it from the list.
     */
    protected removeLineItem(lineItem: ReplacementValueCorrectionItem, type: CorrectionItemType): void {
        const lineItems = this.getLineItems(type);

        const index = lineItems.indexOf(lineItem);
        lineItems.splice(index, 1);
        if (lineItems.length === 0 && type === 'decrease') {
            // Don't allow removing all decrease rows. Increase rows can be removed completely.
            this.addLineItem(type);
        }
    }

    /**
     * User wants to add a new line item. Adds an empty one to the list.
     */
    protected addLineItem(type: CorrectionItemType): void {
        const lineItems = this.getLineItems(type);

        const newLineItem = new ReplacementValueCorrectionItem();
        newLineItem.type = type;
        newLineItem.position = lineItems.length + 1;
        lineItems.push(newLineItem);

        // Wait for the new row (including the description input field) to be rendered, then focus it
        setTimeout(() => {
            if (type === 'decrease') {
                this.descriptionInputsValueDecrease.last?.focus();
            } else if (type === 'increase') {
                this.descriptionInputsValueIncrease.last?.focus();
            }
        }, 0);
    }

    /**
     * Calculate the total sum of increases or decreases depending on the type argument. This also
     * updates the calculated replacement value.
     */
    protected calculateTotal(type: CorrectionItemType): void {
        const totalSum = this.getLineItemsTotal(type);

        if (type === 'decrease') {
            this.report.valuation.replacementValueCorrectionConfig.totalDecrease = totalSum;
        } else {
            this.report.valuation.replacementValueCorrectionConfig.totalIncrease = totalSum;
        }
    }

    /**
     * Helper function that sums up the total amount for all corrections in a list.
     */
    private getLineItemsTotal(type: CorrectionItemType): number {
        return this.getLineItems(type).reduce((sum, item) => sum + item.value, 0);
    }

    /**
     * Because the access path is quite long, this helper function returns the list of corrections
     * either for the increases or decreases.
     */
    private getLineItems(type: CorrectionItemType): ReplacementValueCorrectionItem[] {
        return type === 'decrease'
            ? this.report.valuation.replacementValueCorrectionConfig.decreases
            : this.report.valuation.replacementValueCorrectionConfig.increases;
    }

    public emitChange() {
        this.valuesChanged.emit();
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { getUserFullName } from '@autoixpert/lib/users/get-user-full-name';
import { Task } from '@autoixpert/models/tasks/task';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
    selector: 'task-details',
    templateUrl: './task-details.component.html',
    styleUrls: ['./task-details.component.scss'],
})
export class TaskDetailsComponent implements OnInit, OnDestroy {
    constructor(
        private userService: UserService,
        private loggedInUserService: LoggedInUserService,
    ) {}

    @Input() task: Task;

    @Output() closeClick = new EventEmitter<never>();
    @Output() taskChange = new EventEmitter<Task>();
    @Output() taskDeleted = new EventEmitter<Task>();

    protected user: User;
    protected team: Team;
    protected teamMembers: User[] = [];

    async ngOnInit() {
        this.team = await this.loggedInUserService.getTeam();
        this.user = await this.loggedInUserService.getUser();
        this.teamMembers = await this.userService.getAllTeamMembers(this.team, this.user);
    }

    //*****************************************************************************
    //  Assignee
    //****************************************************************************/
    public assignUser(user: User) {
        this.task.assigneeId = user?._id;
        this.emitTaskChange();
    }

    public getUserFullName(): string {
        const assignedUser = this.teamMembers.find((teamMember) => teamMember._id === this.task.assigneeId);
        return getUserFullName(assignedUser);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Assignee
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Creator
    //****************************************************************************/
    protected getCreatedByUserName() {
        const user = this.teamMembers.find((teamMember) => teamMember._id === this.task.createdBy);
        if (!user) {
            return 'Unbekannt';
        }

        return getUserFullName(user);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Creator
    /////////////////////////////////////////////////////////////////////////////*/

    emitTaskChange() {
        this.taskChange.emit(this.task);
    }

    deleteTask() {
        this.taskDeleted.emit(this.task);
    }

    ngOnDestroy(): void {}
}

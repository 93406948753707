import {
    CanvasEvents,
    Circle,
    FabricObject,
    Group,
    Line,
    Point,
    Polygon,
    Rect,
    TPointerEventInfo,
    loadSVGFromURL,
} from 'fabric';
import { determineFabricObjectBlurFactor } from 'src/app/shared/libraries/fabric/ts/object-blur-factor.utils';
import { debounceAx } from '../../../../shared/libraries/debounce-ax';
import { DEFAULT_FABRIC_CONTROL_OPTIONS } from './fabric-js-custom-types';
import { CROP_AREA_OBJECT_ID } from './photo-editor.canvas-manager.objects';
import { PhotoEditorComponent } from './photo-editor.component';
import { Tool, ToolbarManagerOptions, Tools } from './photo-editor.interfaces';

export class ToolbarManager {
    // Reference to the editor component
    public editor: PhotoEditorComponent;

    // An object containing all tool objects
    public tools: Tools;

    // Reference to the currently active tool. When a new tool is selected, the old tool will be deactivated.
    public activeTool: Tool;

    // Which background should be active when adding elements to the canvas. Default is color.
    public activeBackground: 'color' | 'blur' = 'color';

    // The color in which newly created elements should be placed on the canvas. Default is autoiXpert blue.
    public color = '#15a9e8';

    // A list of temporary lines helping the user see where the polygon will be added. Needs to be a component property so the lines can be removed
    // if the user aborts creating a polygon.
    private polygonTemporaryLines: Line[] = [];

    // The preview line connects the last point of the polygon with the current mouse position.
    private polygonPreviewLine: Line;

    /**
     * List of event handlers and their respective event name that are currently installed and need to be
     * removed individually (instead of removing every handler for a specific event type).
     */
    private currentEventHandlers: Array<{ event: keyof CanvasEvents; handler: any }> = [];

    /**
     * Helper function to debounce saving color change to the history (undo/redo) by 500 ms. By that we prevent hundreds of history entries
     * when sliding through the color picker.
     */
    private readonly saveHistoryDebounced = debounceAx(() => this.editor.canvasManager.saveHistory(), 500);

    constructor(parameters: ToolbarManagerOptions) {
        this.editor = parameters.editor;

        this.preventToolsFromBeingUsedBeforePhotoIsLoaded();
        this.defineTools();
    }

    /**
     * Collection of all methods adding objects to the canvas
     */
    public add = {
        /**
         * Let the user add an arrow onto the canvas.
         */
        Arrow: () => {
            this.activateTool(this.tools.arrow);
            this.startInsertShapeMode();

            const canvas = this.editor.canvasManager.canvas;

            // The following code is only executed when the mouse button is pushed and held
            canvas.on('mouse:down', async (options) => {
                const mouseDownCoordinates = canvas.getPointer(options.e);
                // Add svg to canvas where the mouse was clicked
                const svgObjects = await loadSVGFromURL('/assets/images/icons/arrow-blue.svg');

                svgObjects.objects[0].set({
                    top: mouseDownCoordinates.y,
                    left: mouseDownCoordinates.x,
                    centeredRotation: false,
                    data: {
                        originalSize: {
                            height: svgObjects.objects[0].height,
                            width: svgObjects.objects[0].width,
                        },
                    },
                    ...DEFAULT_FABRIC_CONTROL_OPTIONS,
                });
                await this.setObjectFillAndStroke({ filled: true, object: svgObjects.objects[0] });
                canvas.add(svgObjects.objects[0]);
                canvas.requestRenderAll();

                // Adjust size and angle of shape when the mouse moves
                const mouseMoveHandler = this.mouseMoveArrowHandler.bind(this, mouseDownCoordinates, svgObjects);
                this.currentEventHandlers.push({ event: 'mouse:move', handler: mouseMoveHandler });
                canvas.on('mouse:move', mouseMoveHandler);

                // Create event handler and remove it after the element has been set.
                const mouseUpEventHandler = (fabricEvent) => {
                    svgObjects.objects[0]
                        .set({
                            centeredRotation: true,
                        })
                        .setCoords();

                    canvas.fire('object:modified', fabricEvent);

                    if (this.editor.userPreferences.photoEditorKeepToolActive) {
                        this.stopInsertShapeMode();
                        this.activeTool.onclick();
                    } else {
                        this.activeTool.deactivate();
                    }

                    canvas.off('mouse:up', mouseUpEventHandler);
                };

                // Register the event handler
                canvas.on('mouse:up', mouseUpEventHandler);
            });
        },
        /**
         * Let the user drag a circle onto the canvas.
         */
        Circle: this.circleFactory({ filled: false }),
        FilledCircle: this.circleFactory({ filled: true }),
        /**
         * Let the user to drag a rectangle onto the canvas
         */
        Rectangle: this.rectangleFactory({ filled: false }),
        FilledRectangle: this.rectangleFactory({ filled: true }),
        /**
         * Let the user to draw a polygon onto the canvas
         */
        Polygon: this.polygonFactory({ filled: false }),
        FilledPolygon: this.polygonFactory({ filled: true }),
        /**
         * This functions adds a numberToken and allows text to be inserted into its description via a card and an input.
         */
        NumberAndText: () => {
            const canvas = this.editor.canvasManager.canvas;

            const numberAndText = this.editor.canvasManager.objectManager.createNumberToken({
                color: this.color,
            });
            numberAndText.set({
                // Place them in the top left corner
                top: 250,
                left: 250,
            });

            canvas.add(numberAndText);
            canvas.requestRenderAll();

            // Track change to trigger saving
            this.editor.canvasManager.canvas.fire('object:modified', { target: numberAndText });

            // Show the description when adding the numberToken to the canvas.
            numberAndText.fire('mousedblclick');
        },
        Watermark: async () => {
            const watermarkPreferences = this.editor.canvasManager.editor.team.preferences.watermark;

            // If the team has not decided on a type of watermark yet, open the watermark settings.
            if (!watermarkPreferences.type) {
                this.editor.showWatermarkSettings({ setupNecessary: true });
            } else {
                await this.editor.canvasManager.objectManager.drawWatermark();
            }
        },
        /**
         * Open a color picker dialog
         */
        Colorpicker: () => {
            this.editor.colorpicker.nativeElement.focus();
            this.editor.colorpicker.nativeElement.click();
        },
    } as const;

    /**
     * Well, duh.
     * @param angle
     */
    rotateImage(angle = 90): void {
        this.editor.canvasManager.rotateImage(angle);

        // Trigger modification event. Empty object because the event does not refer to a single object
        this.editor.canvasManager.canvas.fire('object:modified');
    }

    cropImage(): void {
        if (this.activeTool !== this.tools.crop) {
            this.activateTool(this.tools.crop);
            this.startCropMode();
        } else {
            this.tools.crop.deactivate();
        }
    }

    zoomIn(): void {
        this.editor.canvasManager.toggleZoomMode();
    }

    resetCanvas() {
        this.editor.canvasManager.reset();

        // Track change to trigger saving
        this.editor.canvasManager.canvas.fire('object:modified');
    }

    private defineTools() {
        this.tools = {
            arrow: {
                id: 'arrow',
                onclick: this.add.Arrow.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                },
            },
            circle: {
                id: 'circle',
                onclick: this.add.Circle.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                },
            },
            filledCircle: {
                id: 'filledCircle',
                onclick: this.add.FilledCircle.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                },
            },
            rectangle: {
                id: 'rectangle',
                onclick: this.add.Rectangle.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                },
            },
            filledRectangle: {
                id: 'filledRectangle',
                onclick: this.add.FilledRectangle.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                },
            },
            polygon: {
                id: 'polygon',
                onclick: this.add.Polygon.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                    this.removePolygonTemporaryLines();
                },
            },
            filledPolygon: {
                id: 'filledPolygon',
                onclick: this.add.FilledPolygon.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                    this.removePolygonTemporaryLines();
                },
            },
            numberAndText: {
                id: 'number-and-text',
                onclick: this.add.NumberAndText.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                },
            },
            rotate: {
                id: 'rotate',
                onclick: this.rotateImage.bind(this),
            },
            crop: {
                id: 'crop',
                onclick: this.cropImage.bind(this),
                deactivate: () => {
                    this.stopCropMode();
                    this.clearActiveTool();
                },
            },
            watermark: {
                id: 'watermark',
                onclick: this.add.Watermark.bind(this),
                deactivate: () => {
                    this.stopInsertShapeMode();
                    this.clearActiveTool();
                },
                active: false,
            },
            colorpicker: {
                id: 'colorpicker',
                onclick: this.add.Colorpicker.bind(this),
            },
            reset: {
                id: 'reset',
                onclick: this.resetCanvas.bind(this),
            },
            delete: {
                id: 'delete',
                onclick: () => {
                    this.editor.canvasManager.objectManager.deleteFocusedObjects();
                },
            },
        };
    }

    /**
     * Ensure that tools can only be used after the fabric.js canvas and photo have been loaded. The tools are tightly
     * coupled with objects like arrows, circles, rectangles, etc. that are added to the canvas when initializing the photo,
     * e.g. because existing arrows are prevented from being accidentally dragged when positioning a new arrow.
     */
    private preventToolsFromBeingUsedBeforePhotoIsLoaded(): void {
        for (const toolKey in this.add) {
            if (this.add.hasOwnProperty(toolKey)) {
                const originalFunction = this.add[toolKey];
                this.add[toolKey] = () => {
                    /**
                     * The fabric.js photo might still be uninitialized when a photo tool is activated through a mouse click or a keydown.
                     */
                    if (!this.editor.canvasManager.fabricPhoto || !this.editor.canvasManager.canvas) {
                        console.log(
                            `Insert shape mode can only be initiated after a photo was loaded since disabling object dragging requires objects to be initialized.`,
                        );
                        return;
                    }
                    originalFunction.bind(this)();
                };
            }
        }
    }

    /**
     * Used when the user picks a certain shape from the toolbox.
     */
    private startInsertShapeMode(): void {
        this.editor.canvasManager.fabricPhoto.set('hoverCursor', 'crosshair');

        // Otherwise existing elements might be dragged when inserting a new shape on top of an existing one.
        this.editor.canvasManager.objectManager.disableObjectDragging();
        // Disable group selection
        this.editor.canvasManager.canvas.selection = false;
    }

    /**
     * Cancel inserting any shapes after their respective tool has been activated.
     */
    public stopInsertShapeMode(): void {
        const canvasManager = this.editor.canvasManager;

        canvasManager.fabricPhoto.set('hoverCursor', 'default');
        canvasManager.objectManager.enableObjectDragging();
        // Restore group selection
        this.editor.canvasManager.canvas.selection = true;
        canvasManager.canvas.off('mouse:down');

        for (const handler of this.currentEventHandlers) {
            canvasManager.canvas.off(handler.event, handler.handler);
        }
    }

    public async confirmCrop(cropArea?: Rect): Promise<void> {
        if (!cropArea) {
            cropArea = this.editor.canvasManager.canvas
                .getObjects()
                .find((object) => object.id === CROP_AREA_OBJECT_ID) as Rect;
        }

        this.clearActiveTool();
        await this.editor.canvasManager.cropImage(cropArea);
        this.stopCropMode();
    }

    /**
     * Used when the user picks the crop tool from the toolbox.
     */
    private async startCropMode(): Promise<void> {
        this.editor.canvasManager.disableHistory();
        const canvasManager = this.editor.canvasManager;
        const fabricPhoto = canvasManager.fabricPhoto;

        const cancelCrop = () => {
            this.clearActiveTool();
            this.stopCropMode();
        };

        // Create the crop selection rectangle (that defines the crop area) and add it to the canvas
        const clipRectangle = await canvasManager.objectManager.createCropSelectionRectangle(
            fabricPhoto,
            this.confirmCrop.bind(this),
            cancelCrop,
        );

        // Otherwise existing elements might be dragged when inserting a new shape on top of an existing one.
        this.editor.canvasManager.objectManager.disableObjectDragging(clipRectangle, 'default');
        // Disable group selection
        this.editor.canvasManager.canvas.selection = false;
    }

    /**
     * Cancel cropping mode after user deactivated crop tool.
     */
    public stopCropMode(): void {
        // Remove the crop rectangle
        this.editor.canvasManager.objectManager.removeCropHelperObjects();

        // Activate object dragging again
        this.editor.canvasManager.objectManager.enableObjectDragging();
        // Enable group selection again
        this.editor.canvasManager.canvas.selection = true;

        this.editor.canvasManager.enableHistory();
    }

    /**
     * When creating a polygon, the editor adds temporary lines indicating where the polygon will be added. These lines should be removed when
     * - finishing a polygon
     * - aborting adding a polygon
     */
    private removePolygonTemporaryLines(): void {
        for (const temporaryLine of this.polygonTemporaryLines) {
            this.editor.canvasManager.canvas.remove(temporaryLine);
            this.polygonTemporaryLines = [];
        }
        if (this.polygonPreviewLine) {
            this.editor.canvasManager.canvas.remove(this.polygonPreviewLine);
            this.polygonPreviewLine = null;
        }
        this.editor.canvasManager.canvas.requestRenderAll();
    }

    /**
     * Set the active tool. Deactivate the previous tool by calling its deactivate method.
     * @param tool
     */
    private activateTool(tool: Tool) {
        if (this.activeTool && this.activeTool.deactivate) {
            this.activeTool.deactivate();
        }
        this.activeTool = tool;
    }

    /**
     * Clears the currently activated tool.
     */
    public clearActiveTool(): void {
        this.activeTool = null;
    }

    public setEditorShapeColor(color: string) {
        // Disable history, because editing the shape color triggers an object:modified event for each shape, which
        // all in turn create history entries and we don't want the user to step through each of them when undoing the color change
        this.editor.canvasManager.disableHistory();

        this.color = color;
        this.editor.userPreferences.editorShapeColor = color;

        // If there are elements selected, adjust their color accordingly. TextAndNumberTokens are groups. When they are selected, the group is returned instead of the individual objects.
        const activeObjects = this.editor.canvasManager.canvas.getActiveObjects() as FabricObject[] | Group[];
        if (activeObjects) {
            for (const activeObject of activeObjects) {
                // If this is a number token, adjust its circle's stroke color.
                if (
                    activeObject.data &&
                    activeObject.data.axType === 'numberToken' &&
                    'forEachObject' in activeObject
                ) {
                    activeObject.forEachObject((object: FabricObject) => {
                        if (object instanceof Circle) {
                            object.set('stroke', this.color);
                        }
                    });
                }
                // All other objects except number tokens.
                else if (!('forEachObject' in activeObject)) {
                    if (activeObject.get('fill') && activeObject.get('fill') !== 'transparent') {
                        activeObject.set({
                            fill: this.color,
                        });
                    }
                    if (activeObject.get('stroke') && activeObject.get('stroke') !== 'transparent') {
                        activeObject.set({
                            stroke: this.color,
                        });
                    }
                }
            }
            this.editor.canvasManager.canvas.requestRenderAll();
            this.editor.canvasManager.canvas.fire('object:modified', { target: activeObjects[0] });
        }

        // Sliding inside the color picker will call this function very often and we don't want each color step
        // to have its own history entry, so we debounce the saveHistory() call.
        this.saveHistoryDebounced();
        this.editor.canvasManager.enableHistory();
    }

    public async handleBlurToolClick() {
        // If there are elements selected, adjust their blur accordingly.
        const activeObjects = this.editor.canvasManager.canvas.getActiveObjects() as FabricObject[];
        if (activeObjects?.length) {
            await this.setEditorShapeBlurPattern(activeObjects);
        }
        // Activate blur background
        else if (this.activeBackground === 'color') {
            this.activeBackground = 'blur';
        }
        // Deactivate blur background
        else {
            this.activeBackground = 'color';
        }
    }

    public async setEditorShapeBlurPattern(activeObjects: FabricObject[]) {
        for (const activeObject of activeObjects) {
            activeObject.set({ strokeWidth: 0 });
            this.editor.blurPatternManager.setObjectBlurPatternData(activeObject, {
                blurFactor: determineFabricObjectBlurFactor({ object: activeObject }),
            });
            console.log({ activeObject });
            await this.editor.blurPatternManager.enlivenObjectBlurPattern(activeObject);
        }
        this.editor.canvasManager.canvas.requestRenderAll();
        this.editor.canvasManager.canvas.fire('object:modified', { target: activeObjects[0] });
    }

    async setObjectFillAndStroke({ filled, object }: { object: FabricObject; filled: boolean }) {
        if (this.activeBackground === 'blur') {
            this.editor.blurPatternManager.setObjectBlurPatternData(object, {
                blurFactor: determineFabricObjectBlurFactor({ object }),
            });
            await this.editor.blurPatternManager.enlivenObjectBlurPattern(object);
        } else {
            object.set({
                fill: filled ? this.color : 'transparent',
                stroke: this.color,
            });
        }
    }

    public get toolDisplayColor() {
        if (this.activeBackground === 'blur') {
            return '#15a9e8';
        }
        return this.color;
    }

    //*****************************************************************************
    //  Factory Functions
    //****************************************************************************/
    // In order to stay DRY, create factory functions for every form that return a function
    // that is either configured with no fill or with the current color fill.

    private circleFactory({ filled = false }: { filled: boolean }) {
        return (): void => {
            this.activateTool(filled ? this.tools.filledCircle : this.tools.circle);
            this.startInsertShapeMode();

            const canvas = this.editor.canvasManager.canvas;

            // The following code is only executed when the mouse button is pushed and held
            canvas.on('mouse:down', async (options) => {
                const mouseDownCoordinates = canvas.getPointer(options.e),
                    originalRadius = 1,
                    originalStrokeWidth = 1.5;

                const circle = new Circle({
                    left: mouseDownCoordinates.x,
                    top: mouseDownCoordinates.y,
                    radius: originalRadius,
                    strokeWidth: originalStrokeWidth,
                    originX: 'center',
                    originY: 'center',
                    ...DEFAULT_FABRIC_CONTROL_OPTIONS,
                });
                await this.setObjectFillAndStroke({ filled, object: circle });
                canvas.add(circle);
                canvas.requestRenderAll();

                // Adjust size of shape when the mouse moves
                const mouseMoveHandler = this.mouseMoveCircleHandler.bind(
                    this,
                    mouseDownCoordinates,
                    originalStrokeWidth,
                    originalRadius,
                    circle,
                );
                this.currentEventHandlers.push({ event: 'mouse:move', handler: mouseMoveHandler });
                canvas.on('mouse:move', mouseMoveHandler);

                // Create event handler and remove it after the element has been set.
                const mouseUpEventHandler = (fabricEvent) => {
                    circle.setCoords();

                    // Ensure the correct blur factor as we only know the dimensions at the end of the object creation process
                    if (this.editor.blurPatternManager.isBlurPatternObject(circle)) {
                        this.editor.blurPatternManager.setObjectBlurPatternData(circle, {
                            blurFactor: determineFabricObjectBlurFactor({ object: circle }),
                        });
                        this.editor.blurPatternManager.enlivenObjectBlurPattern(circle);
                    }

                    // Track change to trigger saving
                    canvas.fire('object:modified', fabricEvent);

                    if (this.editor.userPreferences.photoEditorKeepToolActive) {
                        this.stopInsertShapeMode();
                        this.activeTool.onclick();
                    } else {
                        this.activeTool.deactivate();
                    }

                    canvas.off('mouse:up', mouseUpEventHandler);
                };

                // Register the event handler
                canvas.on('mouse:up', mouseUpEventHandler);
            });
        };
    }

    private rectangleFactory({ filled = false }: { filled: boolean }) {
        return (): void => {
            this.activateTool(filled ? this.tools.filledRectangle : this.tools.rectangle);
            this.startInsertShapeMode();

            const canvas = this.editor.canvasManager.canvas;

            // The following code is only executed when the mouse button is pushed and held
            canvas.on('mouse:down', async (options) => {
                const mouseDownCoordinates = canvas.getPointer(options.e),
                    initialHeight = 1,
                    initialWidth = 1,
                    originalStrokeWidth = 1.5;

                const rectangle = new Rect({
                    left: mouseDownCoordinates.x,
                    top: mouseDownCoordinates.y,
                    height: initialHeight,
                    width: initialWidth,
                    strokeWidth: originalStrokeWidth,
                    originX: 'center',
                    originY: 'center',
                    ...DEFAULT_FABRIC_CONTROL_OPTIONS,
                });

                await this.setObjectFillAndStroke({ filled, object: rectangle });

                canvas.add(rectangle);
                canvas.requestRenderAll();

                // Adjust size of shape when the mouse moves
                const mouseMoveHandler = this.mouseMoveRectangleHandler.bind(
                    this,
                    mouseDownCoordinates,
                    originalStrokeWidth,
                    initialWidth,
                    initialHeight,
                    rectangle,
                );
                this.currentEventHandlers.push({ event: 'mouse:move', handler: mouseMoveHandler });
                canvas.on('mouse:move', mouseMoveHandler);

                // Create event handler and remove it after the element has been set.
                const mouseUpEventHandler = (fabricEvent) => {
                    rectangle.setCoords();

                    // Ensure the correct blur factor as we only know the dimensions at the end of the object creation process
                    if (this.editor.blurPatternManager.isBlurPatternObject(rectangle)) {
                        this.editor.blurPatternManager.setObjectBlurPatternData(rectangle, {
                            blurFactor: determineFabricObjectBlurFactor({ object: rectangle }),
                        });
                        this.editor.blurPatternManager.enlivenObjectBlurPattern(rectangle);
                    }

                    // Track change to trigger saving
                    this.editor.canvasManager.canvas.fire('object:modified', fabricEvent);

                    if (this.editor.userPreferences.photoEditorKeepToolActive) {
                        this.stopInsertShapeMode();
                        this.activeTool.onclick();
                    } else {
                        this.activeTool.deactivate();
                    }

                    canvas.off('mouse:up', mouseUpEventHandler);
                };

                // Register the event handler
                canvas.on('mouse:up', mouseUpEventHandler);
            });
        };
    }

    private polygonFactory({ filled = false }: { filled: boolean }) {
        return (): void => {
            // Temporarily disable the history, otherwise the preview lines / temporary lines will end up in the history state
            this.editor.canvasManager.disableHistory();

            this.activateTool(filled ? this.tools.filledPolygon : this.tools.polygon);
            this.startInsertShapeMode();

            const canvas = this.editor.canvasManager.canvas;
            const originalStrokeWidth = 1.5;
            // If the mouse gets into a square of two times this length (like a radius but in a square) around the first coordinate, the line will "magnetically stick"
            // to the first coordinate.
            const magneticSquareThreshold = 10;

            // A collection of coordinate points that will eventually be used to create the polygon.
            const polygonCoordinates = [];

            let mouseMoveHandler = null;

            // Create event handler and remove it after the element has been set.
            const finishPolygon = async () => {
                const polygon = new Polygon(polygonCoordinates, {
                    strokeWidth: originalStrokeWidth,
                    ...DEFAULT_FABRIC_CONTROL_OPTIONS,
                });
                await this.setObjectFillAndStroke({ filled, object: polygon });

                canvas.add(polygon);

                if (this.polygonPreviewLine) {
                    canvas.remove(this.polygonPreviewLine);
                    this.polygonPreviewLine = null;
                }

                // Track change to trigger saving
                canvas.fire('object:modified', { target: polygon });

                if (this.editor.userPreferences.photoEditorKeepToolActive) {
                    this.stopInsertShapeMode();
                    this.activeTool.onclick();
                } else {
                    this.activeTool.deactivate();
                }

                canvas.off('mouse:move', mouseMoveHandler);
                mouseMoveHandler = null;

                canvas.on('mouse:dblclick', this.editor.canvasManager.zoomModeDoubleclickEventHandler);
                canvas.off('mouse:dblclick', finishPolygon);

                // Enable history again and save the added polygon to the history
                this.editor.canvasManager.enableHistory();
                this.editor.canvasManager.saveHistory(true);
            };

            // Register the event handler
            canvas.off('mouse:dblclick', this.editor.canvasManager.zoomModeDoubleclickEventHandler);
            canvas.on('mouse:dblclick', finishPolygon);

            // The following code is only executed when the mouse button is pushed and held
            canvas.on('mouse:down', (options) => {
                // Remove the preview line which followed the mouse position
                const mouseDownCoordinates = canvas.getPointer(options.e);

                // Add the currently clicked point to the polygon coordinates
                if (polygonCoordinates[0]) {
                    // If the mouse is within a certain square radius of the original point, let the line stick to that point and show a pointer.
                    const diffX = Math.abs(mouseDownCoordinates.x - polygonCoordinates[0].x);
                    const diffY = Math.abs(mouseDownCoordinates.y - polygonCoordinates[0].y);
                    if (diffX < magneticSquareThreshold && diffY < magneticSquareThreshold) {
                        polygonCoordinates.push({
                            x: polygonCoordinates[0].x,
                            y: polygonCoordinates[0].y,
                        });
                    } else {
                        polygonCoordinates.push({
                            x: mouseDownCoordinates.x,
                            y: mouseDownCoordinates.y,
                        });
                    }
                }

                // Set the first point of the polygon
                else {
                    polygonCoordinates.push({
                        x: mouseDownCoordinates.x,
                        y: mouseDownCoordinates.y,
                    });

                    //Add the line following the cursor after the first point was added. It will show the user where the next polygon line will be added.
                    // Adjust size of shape when the mouse moves
                    mouseMoveHandler = this.displayPolygonPreviewLineOnMouseMove.bind(
                        this,
                        polygonCoordinates,
                        magneticSquareThreshold,
                        originalStrokeWidth,
                    );

                    this.currentEventHandlers.push({ event: 'mouse:move', handler: mouseMoveHandler });
                    canvas.on('mouse:move', mouseMoveHandler);
                }

                const currentCoordinateIndex = polygonCoordinates.length - 1;

                // Draw the temporary line from the previous point to the current point
                if (polygonCoordinates.length > 1) {
                    const newTemporaryLine = new Line(
                        [
                            polygonCoordinates[currentCoordinateIndex - 1].x,
                            polygonCoordinates[currentCoordinateIndex - 1].y,
                            mouseDownCoordinates.x,
                            mouseDownCoordinates.y,
                        ],
                        {
                            strokeWidth: originalStrokeWidth,
                            stroke: this.color,
                            originX: 'center',
                            originY: 'center',
                            selectable: false, // Prevent that the user accidentally selects these temporary lines while inserting new polygon coordinates
                        },
                    );
                    this.polygonTemporaryLines.push(newTemporaryLine);
                    canvas.add(newTemporaryLine);
                }

                // If the polygon has an area (more than 2 points), allow the user to click on (or close to) the first point to close the shape.
                if (polygonCoordinates.length > 2) {
                    const diffX = Math.abs(mouseDownCoordinates.x - polygonCoordinates[0].x);
                    const diffY = Math.abs(mouseDownCoordinates.y - polygonCoordinates[0].y);
                    // If the current click is within a certain radius of the original point, finish the polygon.
                    if (diffX < magneticSquareThreshold && diffY < magneticSquareThreshold) {
                        return finishPolygon();
                    }
                }

                canvas.requestRenderAll();
            });
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Factory Functions
    /////////////////////////////////////////////////////////////////////////////*/

    private mouseMoveArrowHandler = (mouseDownCoordinates: Point, svgObjects, optionsMove: TPointerEventInfo): void => {
        const canvas = this.editor.canvasManager.canvas;
        const mouseMoveCoordinates = canvas.getPointer(optionsMove.e);

        // xDiff: How much did the cursor move from the mousedown event on the x-axis?
        // yDiff: Same as xDiff but for the y-axis.
        // scalingFactor: Shape has a minimum size (50). Other than that, calculate the height with pythagoras' theorem.
        const xDiff = mouseDownCoordinates.x - mouseMoveCoordinates.x,
            yDiff = mouseDownCoordinates.y - mouseMoveCoordinates.y,
            scalingFactor =
                Math.max(Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2)), 50) /
                svgObjects.objects[0].get('data').originalSize.height,
            angle = (Math.atan2(yDiff, xDiff) * 180) / Math.PI + 90;
        svgObjects.objects[0].set({
            angle: angle,
            scaleY: scalingFactor,
            scaleX: scalingFactor,
        });
        canvas.requestRenderAll();
    };

    private mouseMoveCircleHandler = (
        mouseDownCoordinates: Point,
        originalStrokeWidth: number,
        originalRadius: number,
        circle: Circle,
        optionsMove: TPointerEventInfo,
    ): void => {
        const canvas = this.editor.canvasManager.canvas;
        const mouseMoveCoordinates = canvas.getPointer(optionsMove.e);

        // xDiff: How much did the cursor move from the mousedown event on the x-axis?
        // yDiff: Same as xDiff but for the y-axis.
        // newRadius: Shape has a minimum size (50). Other than that, calculate the radius with pythagoras' theorem.
        const xDiff = mouseMoveCoordinates.x - mouseDownCoordinates.x,
            yDiff = mouseMoveCoordinates.y - mouseDownCoordinates.y,
            newDiameter = Math.max(Math.max(Math.abs(xDiff), Math.abs(yDiff)), originalRadius),
            newRadius = newDiameter / 2;

        const newCenterX = mouseDownCoordinates.x + newRadius * Math.sign(xDiff);
        const newCenterY = mouseDownCoordinates.y + newRadius * Math.sign(yDiff);

        circle.set({
            left: newCenterX,
            top: newCenterY,
            radius: newRadius,
            strokeWidth: Math.pow((originalStrokeWidth * Math.abs(xDiff + yDiff)) / originalRadius, 1 / 6),
        });

        // Ensure the correct blur factor as the dimensions change during the object creation process
        // Only do this if the factor significantly changes for performance reasons.
        if (this.editor.blurPatternManager.isBlurPatternObject(circle)) {
            const blurFactor = determineFabricObjectBlurFactor({ object: circle });

            if (Math.abs(blurFactor - circle?.data?.axBlurFactor) > 0.04) {
                this.editor.blurPatternManager.setObjectBlurPatternData(circle, {
                    blurFactor,
                });
                this.editor.blurPatternManager.enlivenObjectBlurPattern(circle);
            }
        }

        canvas.requestRenderAll();
    };

    private mouseMoveRectangleHandler = (
        mouseDownCoordinates: Point,
        originalStrokeWidth: number,
        initialWidth: number,
        initialHeight: number,
        rectangle: Rect,
        optionsMove: TPointerEventInfo,
    ) => {
        const canvas = this.editor.canvasManager.canvas;
        const mouseMoveCoordinates = canvas.getPointer(optionsMove.e);

        // xDiff: How much did the cursor move from the mousedown event on the x-axis?
        // yDiff: Same as xDiff but for the y-axis.
        // scalingFactor: Shape has a minimum size (50). Other than that, calculate the height with pythagoras' theorem.
        const xDiff = mouseMoveCoordinates.x - mouseDownCoordinates.x,
            yDiff = mouseMoveCoordinates.y - mouseDownCoordinates.y,
            newWidth = Math.max(Math.abs(xDiff), initialWidth),
            newHeight = Math.max(Math.abs(yDiff), initialHeight);

        const newCenterX = mouseDownCoordinates.x + (newWidth / 2) * Math.sign(xDiff);
        const newCenterY = mouseDownCoordinates.y + (newHeight / 2) * Math.sign(yDiff);

        rectangle.set({
            top: newCenterY,
            left: newCenterX,
            width: newWidth,
            height: newHeight,
            strokeWidth: Math.pow(
                originalStrokeWidth * (Math.abs(xDiff / initialWidth) + Math.abs(yDiff / initialHeight)),
                1 / 6,
            ),
        });

        // Ensure the correct blur factor as the dimensions change during the object creation process
        // Only do this if the factor significantly changes for performance reasons.
        if (this.editor.blurPatternManager.isBlurPatternObject(rectangle)) {
            const blurFactor = determineFabricObjectBlurFactor({ object: rectangle });

            if (Math.abs(blurFactor - rectangle?.data?.axBlurFactor) > 0.04) {
                this.editor.blurPatternManager.setObjectBlurPatternData(rectangle, {
                    blurFactor,
                });
                this.editor.blurPatternManager.enlivenObjectBlurPattern(rectangle);
            }
        }

        // Render everything
        canvas.requestRenderAll();
    };

    /**
     * This function updates the preview line on each mouse move.
     */
    private displayPolygonPreviewLineOnMouseMove = (
        polygonCoordinates: Array<Point>,
        magneticSquareThreshold: number,
        originalStrokeWidth: number,
        optionsMove: TPointerEventInfo,
    ) => {
        const canvas = this.editor.canvasManager.canvas;
        const mouseMoveCoordinates = canvas.getPointer(optionsMove.e);
        const lastPolygonCoordinate = polygonCoordinates[polygonCoordinates.length - 1];

        /**
         *
         * Draw the polygon preview line only if a mouse event is triggered.
         * This avoids the line being visible in a static position on devices that do not support mouse events.
         */
        if (!this.polygonPreviewLine) {
            this.polygonPreviewLine = new Line(
                [polygonCoordinates[0].x, polygonCoordinates[0].y, mouseMoveCoordinates.x, mouseMoveCoordinates.y],
                {
                    strokeWidth: originalStrokeWidth,
                    stroke: this.color,
                    originX: 'center',
                    originY: 'center',
                },
            );

            canvas.add(this.polygonPreviewLine);
        }

        this.polygonPreviewLine.set('x1', lastPolygonCoordinate.x);
        this.polygonPreviewLine.set('y1', lastPolygonCoordinate.y);

        // If the mouse is within a certain square radius of the original point, let the line stick to that point and show a pointer.
        const diffX = Math.abs(mouseMoveCoordinates.x - polygonCoordinates[0].x);
        const diffY = Math.abs(mouseMoveCoordinates.y - polygonCoordinates[0].y);
        if (diffX < magneticSquareThreshold && diffY < magneticSquareThreshold) {
            this.editor.canvasManager.fabricPhoto.set('hoverCursor', 'pointer');
            this.polygonPreviewLine.set('x2', polygonCoordinates[0].x);
            this.polygonPreviewLine.set('y2', polygonCoordinates[0].y);
        } else {
            this.editor.canvasManager.fabricPhoto.set('hoverCursor', 'crosshair');
            this.polygonPreviewLine.set('x2', mouseMoveCoordinates.x);
            this.polygonPreviewLine.set('y2', mouseMoveCoordinates.y);
        }

        // Render everything
        canvas.requestRenderAll();
    };
}

import { FieldConfig } from '../../models/custom-fields/field-config';
import { FieldGroupConfig } from '../../models/custom-fields/field-group-config';
import { ServerError } from '../../models/errors/ax-error';
import { PaintMaterialSurchargeUnitGerman, PaintSystemGerman } from './placeholder-values.types';
import {
    InvoiceInvolvedPartyTypeGerman,
    InvolvedPartyTypeGerman,
    VatExemptionReasonGerman,
    batteryDamageOptionsGerman,
} from './translator';

const contactPersonPlaceholderTypes: PlaceholderValueTree = {
    Brieffenster: {
        type: 'String',
        isDocx: true,
    },
    Anrede: 'String',
    AnredeDekliniert: 'String',
    Briefanrede: 'String',
    Vorname: 'String',
    Nachname: 'String',
    Organisation: 'String',
    NameKomplett: {
        type: 'String',
        isDocx: true,
    },
    /**
     * Why this long name?
     * - Dekliniert = Salutations for company and person are put into the Kasus Dativ.
     * - The placeholder "NameKomplett" is multiline. To better distinguish it, we appended "einzeilig" to the new placeholder.
     */
    NameKomplettDekliniertUndEinzeilig: 'String',
    Straße: 'String',
    Postleitzahl: 'String',
    Ort: 'String',
    Telefon: 'String',
    Telefon2: 'String',
    EMail: 'String',
    UmsatzsteuerId: 'String',
    Website: 'String',
};

const visitPlaceholderType: PlaceholderValueTree = {
    BesichtigungBei: 'String',
    Straße: 'String',
    Postleitzahl: 'String',
    Ort: 'String',
    Datum: 'String',
    Uhrzeit: 'String',
    Fahrzeugzustand: {
        type: 'String',
        autocomplete: [
            'unzerlegt',
            'freigelegt',
            'zerlegt',
            'erst unzerlegt, dann freigelegt',
            'erst unzerlegt, dann zerlegt',
        ],
    },
    istÄnderungSeitUnfallErsichtlich: 'Boolean',
    Nummer: 'Number',
    warSachverständigerAnwesend: 'Boolean',
    warAnspruchstellerAnwesend: 'Boolean',
    warMechanikerAnwesend: 'Boolean',
    warFahrzeughalterAnwesend: 'Boolean',
    andereAnwesende: 'String',
    anwesenderSachverständiger: 'String',
    Anwesende: 'String',
    Bedingungen: 'String',
    Hilfsmittel: ['String'],
    Bemerkungen: {
        type: 'String',
        isHtml: true,
    },
};

const oldtimerValuationScale: PlaceholderValueLeafDefinition = {
    type: 'String',
    enum: ['1', '1-', '2+', '2', '2-', '3+', '3', '3-', '4+', '4', '4-', '5+', '5'],
};

const carShape: PlaceholderValueLeafDefinition = {
    type: 'String',
    autocomplete: [
        'Limousine',
        'Kompaktwagen',
        'Coupé',
        'Kombi',
        'SUV',
        'Cabriolet',
        'Van',
        'Transporter',
        'Anhänger',
        'Pick-Up',
        'Sattelzugmaschine',
        'Sattelauflieger',
        'LKW mit Aufbau',
        'Bus',
        'Wohnmobil',
        'Wohnanhänger',
        'Motorrad',
    ],
};

const invoiceRecipientTypeGerman: PlaceholderValueLeafDefinition = {
    type: 'String',
    autocomplete: new Array<InvoiceInvolvedPartyTypeGerman>(
        'Anspruchsteller',
        'Versicherung',
        'Anwalt',
        'Rechnungsempfänger',
        'Leasinggeber',
    ),
};

const paymentPlaceholderTypes: PlaceholderValueTree = {
    Betrag: 'Number',
    Datum: 'Date',
    Zahler: 'String',
    InterneBemerkungen: 'String',
    istKürzung: 'Boolean',
    EmpfängerIBAN: 'String',
};

const letterRecipientTypeGerman: PlaceholderValueLeafDefinition = {
    type: 'String',
    autocomplete: new Array<InvolvedPartyTypeGerman | InvoiceInvolvedPartyTypeGerman>(
        'Anspruchsteller',
        'Auftraggeber',
        'Leasingnehmer',
        'Versicherungsnehmer',
        'Fahrzeughalter',
        'Unfallgegners',
        'Fahrzeughalter (Unfallgegner)',
        'Werkstatt',
        'Anwalt',
        'Versicherung',
        'Leasinggeber',
        'Factoring Provider',
        'Verkäufer',
        'Rechnungsempfänger',
    ),
};

const paymentReminderPlaceholderTypes: PlaceholderValueTree = {
    Stufe: 'Number',
    Datum: 'Date',
    Zahlungsziel: 'Date',
    ZahlungszielTage: 'Number',
    Mahnbetrag: 'Number',
    Absender: contactPersonPlaceholderTypes,
    Empfänger: {
        ...contactPersonPlaceholderTypes,
        Typ: invoiceRecipientTypeGerman,
    },
    Ersteller: contactPersonPlaceholderTypes,
};

//*****************************************************************************
//  Description
//****************************************************************************/
// This is used for the frontend and backend to know how to compare the values located
// at these locations in the placeholder values object when working with
// document building blocks.
// ATTENTION: Types can generally not be inferred from the placeholder value
/////////////////////////////////////////////////////////////////////////////*/
//  END Description
/////////////////////////////////////////////////////////////////////////////*/

export function getPlaceholderValueTree({ fieldGroupConfigs }: { fieldGroupConfigs: FieldGroupConfig[] }) {
    const placeholderValueTree: PlaceholderValueTree = {
        Steuern: {
            Steuernummer: 'String',
            UmsatzsteuerId: 'String',
            SteuernummerOderUmsatzsteuerId: 'String',
        },
        Gutachten: {
            Aktenzeichen: 'String',
            Erstellungsdatum: 'Date',
            Fertigstellungsdatum: 'Date',
            Auftragsdatum: 'Date',
            Auftragszeit: 'String',
            Auftraggeber: 'String',
            AuftragDurchAnspruchsteller: 'Boolean',
            ArtDerAuftragserteilung: {
                type: 'String',
                enum: ['telefonisch', 'schriftlich', 'persönlich'],
            },
            FahrzeugscheinLagImOriginalVor: 'Boolean',
            FahrzeugscheinLagInKopieVor: 'Boolean',
            QuelleDerTechnischenDaten: 'String',
            VINAmFahrzeugÜberprüft: 'Boolean',
            Typ: {
                type: 'String',
                enum: [
                    'Fahrzeugbewertung Oldtimer',
                    'Fahrzeugbewertung',
                    'Gebrauchtwagen-Check',
                    'Haftpflichtschaden',
                    'Kostenvoranschlag',
                    'Kurzgutachten',
                    'Teilkaskoschaden',
                    'Vollkaskoschaden',
                    'Zustandsbericht',
                    'Rechnungsprüfung',
                ],
            },
            AnzahlFotos: 'Number',
            istTotalschaden: 'Boolean',
            Vermittler: 'String',
            fiktiveAbrechnung: 'Tristate',
            istNachtrag: 'Boolean',
            BegründungNachtrag: {
                type: 'String',
                isHtml: true,
            },
            EigeneFelder: {},
        },
        Sachverständiger: {
            ...contactPersonPlaceholderTypes,
            BeruflicheQualifikation: {
                type: 'String',
                isHtml: true,
            },
            Standortname: 'String',
            Standortkürzel: 'String',
            StandortTelefon: 'String',
        },
        Anspruchsteller: {
            Anwalt: {
                ...contactPersonPlaceholderTypes,
                Aktenzeichen: 'String',
                Notizen: {
                    type: 'String',
                    isHtml: true,
                },
            },
            IBAN: 'String',
            istDurchAnwaltVertreten: 'Boolean',
            ...contactPersonPlaceholderTypes,
            Kennzeichen: 'String',
            // Not available for querying to the user. Only printed in DOCX files.
            //KennzeichenDocx              : 'String',
            istVorsteuerabzugsberechtigt: 'Tristate',
            istFahrzeughalter: 'Boolean',
            Aktenzeichen: 'String',
        },
        Fahrzeughalter: {
            ...contactPersonPlaceholderTypes,
            istVorsteuerabzugsberechtigt: 'Tristate',
        },
        Unfallgegner: {
            Versicherung: {
                Versicherungsnummer: 'String',
                Schadennummer: 'String',
                SchadennummerOderVersicherungsnummer: 'String',
                Selbstbeteiligung: 'Number',
                ...contactPersonPlaceholderTypes,
            },
            Kennzeichen: 'String',
            // Not available for querying to the user. Only printed in DOCX files.
            //KennzeichenDocx              : 'String',
            istFahrzeughalter: 'Boolean',
            ...contactPersonPlaceholderTypes,
        },
        FahrzeughalterUnfallgegner: {
            ...contactPersonPlaceholderTypes,
        },
        Leasinggeber: {
            ...contactPersonPlaceholderTypes,
            Vertragsnummer: 'String',
        },
        FactoringProvider: {
            erhältRechnung: 'Boolean',
            ...contactPersonPlaceholderTypes,
        },
        Werkstatt: {
            ...contactPersonPlaceholderTypes,
            istMarkenwerkstatt: 'Boolean',
            Aktenzeichen: 'String',
            Kostensätze: {
                EinheitArbeitswert: {
                    type: 'String',
                    enum: ['Stunde', '10er AW', '12er AW', '100er AW'],
                },
                Mechanik1ProArbeitswert: 'Number',
                Mechanik2ProArbeitswert: 'Number',
                Mechanik3ProArbeitswert: 'Number',
                Elektrik1ProArbeitswert: 'Number',
                Elektrik2ProArbeitswert: 'Number',
                Elektrik3ProArbeitswert: 'Number',
                Karosserie1ProArbeitswert: 'Number',
                Karosserie2ProArbeitswert: 'Number',
                Karosserie3ProArbeitswert: 'Number',
                LackProArbeitswert: 'Number',
                DellenProArbeitswert: 'Number',
                Mechanik1: 'Number',
                Mechanik2: 'Number',
                Mechanik3: 'Number',
                Elektrik1: 'Number',
                Elektrik2: 'Number',
                Elektrik3: 'Number',
                Karosserie1: 'Number',
                Karosserie2: 'Number',
                Karosserie3: 'Number',
                Lack: 'Number',
                Lacksystem: {
                    type: 'String',
                    enum: new Array<PaintSystemGerman>('AZT', 'Hersteller', 'Eurolack'),
                },
                Lackmaterial: 'Number',
                LackmaterialEinheit: {
                    type: 'String',
                    enum: new Array<PaintMaterialSurchargeUnitGerman>('%', '€', '€/ME', '€/MP'),
                },
                Dellen: 'Number',
                Verbringung: 'Boolean',
                Verbringungskosten: 'Number',
                Verbringungsaufwand: 'Number',
                MaterialzuschlagUPE: 'Number',
                KleinUndVerbrauchsmaterial: 'Number',
                KleinUndVerbrauchsmaterialEinheit: {
                    type: 'String',
                    enum: ['%', '€'],
                },
            },
        },
        Fahrzeug: {
            VIN: 'String',
            Hersteller: 'String',
            Modell: 'String',
            // Use only for placing an image. Not relevant for insert conditions.
            // HerstellerLogo          : report.car.make && report.car.make.toLowerCase() || ""
            //     .replace(/\s/g, '-'), // Convert Audi to audi, Alpha Romeo to alpha-romeo
            //HerstellerLogoExistiert : 'Boolean',
            Untertyp: 'String',
            Art: carShape,
            Karosserie: carShape,
            // Use only for placing an image. Not relevant for insert conditions.
            // KarosserieMitReifen     : report.car.shape || "sedan",
            // Anstosszeichnung        : report.car.shape || "sedan",
            // Cannot use arrays as insert conditions
            // "Schäden"               : damages,
            wurdeLackschichtdickeGemessen: 'Boolean',
            istMotorrad: 'Boolean',
            istAnhänger: 'Boolean',
            hatVerbrennungsmotor: 'Boolean',
            istElektrofahrzeug: 'Boolean',
            Schadenbeschreibung: {
                type: 'String',
                isHtml: true,
            },
            Schlüsselnummer: 'String',
            Antriebsart: {
                type: 'String',
                autocomplete: ['Diesel', 'Elektro', 'Benzin', 'Autogas', 'Erdgas'],
            },
            Getriebe: 'String',
            Leistung: 'Number',
            AnzahlZylinder: 'Number',
            Motorbauart: 'String',
            Hubraum: 'Number',
            Leermasse: 'Number',
            ZulässigeGesamtmasse: 'Number',
            Länge: 'Number',
            Breite: 'Number',
            Höhe: 'Number',
            Radstand: 'Number',
            AnzahlAchsen: 'Number',
            AnzahlAngetriebeneAchsen: 'Number',
            Sitzplätze: 'Number',
            AnzahlTüren: 'Number',
            // "Baujahr" is a 4-digit number in the placeholder value object, not a date.
            Baujahr: 'Number',
            AlterInJahrenLautBaujahr: 'Number',
            AlterInJahrenLautErstzulassung: 'Number',
            // Some vehicles, e.g. bicycles or boats do not need a car registration.
            // We do not want to display a placeholder for the number token.
            // Vehicles, that need a registration should display the placeholder if no registration is entered,
            // E.g. for a car which was not yet registered, the remark 'nicht zugelassen' is an important information.
            // Since we may add more vehicle types (e.g. digger,...) in the future, we do not want to hardcode the vehicle types in the docx partials..
            istZulassungspflichtig: 'Boolean',
            Erstzulassung: 'Date',
            letzteZulassung: 'Date',
            nächsteHU: 'Date-month-only',
            nächsteSP: 'Date-month-only',
            LaufleistungAbgelesen: 'Number',
            LaufleistungGeschätzt: 'Number',
            LaufleistungAngegeben: 'Number',
            LaufleistungKommentar: {
                type: 'String',
                isHtml: true,
            },
            AnzahlVorbesitzer: {
                type: 'String',
                enum: ['1', '2', '3', '4', '5', '6', '7', 'mehrere', 'unbekannt'],
            },
            QuelleAnzahlVorbesitzer: 'String',
            Bemerkungen: {
                type: 'String',
                isHtml: true,
            },
            Besonderheiten: {
                type: 'String',
                isHtml: true,
            },
            Lack: {
                Farbe: 'String',
                Art: 'String',
                Zustand: 'String',
            },
            Allgemeinzustand: 'String',
            Karosseriezustand: 'String',
            Innenraumzustand: 'String',
            Fahrwerkszustand: 'String',
            istProbefahrtDurchgeführt: 'Boolean',
            istFehlerspeicherAusgelesen: 'Boolean',
            hatAirbags: 'Boolean',
            Airbags: {
                ausgelöst: 'Boolean',
                Kommentar: {
                    type: 'String',
                    isHtml: true,
                },
            },
            // For internal usage only. Don't expose these to the user since they are too complex.
            //Reifen: TireGerman[];
            //ZweiterRadsatz: TireGerman[];
            //Achsen: AxleGerman[];
            hatZweitenReifensatz: 'Boolean',
            istReifenKommentarVorhanden: 'Boolean',
            istZwillingsbereifungVorhanden: 'Boolean',
            Notbereifung: {
                istReparaturkitVorhanden: 'Boolean',
                istNotradVorhanden: 'Boolean',
                Ersatzrad: {
                    Dimension: 'String',
                    Hersteller: 'String',
                    Art: 'String',
                    Profil: 'String',
                    Bemerkung: 'String',
                },
                Kommentar: {
                    type: 'String',
                    isHtml: true,
                },
            },
            istScheckheftGepflegt: 'Tristate',
            Vorschäden: {
                type: 'String',
                isHtml: true,
            },
            Altschäden: {
                type: 'String',
                isHtml: true,
            },
            Nachschäden: {
                type: 'String',
                isHtml: true,
            },
            Fahrfähigkeit: {
                type: 'String',
                autocomplete: [
                    'verkehrssicher',
                    'nutzungsfähig',
                    'fahrfähig',
                    'nicht fahrfähig',
                    'rollfähig',
                    'nicht rollfähig',
                ],
            },
            Schadstoffgruppe: {
                type: 'String',
                enum: ['Gruppe 1', 'Gruppe 2', 'Gruppe 3', 'Gruppe 4'],
            },
            SchadstoffgruppeFarbe: {
                type: 'String',
                enum: [
                    'Grün',
                    'Gelb',
                    'Rot',
                    '', // The emission group 1 does not have any color.
                ],
            },
            Abgasnorm: 'String',
            Hochvoltbatterie: {
                Kapazität: 'Number',
                FahrzeughalterIstEigentümer: 'Tristate',
                Eigentümer: 'String',
                Erhaltungszustand: 'Number',
                ErhaltungszustandKommentar: {
                    type: 'String',
                    isHtml: true,
                },
                Beschädigung: {
                    type: 'String',
                    enum: batteryDamageOptionsGerman,
                },
                BeschädigungKommentar: {
                    type: 'String',
                    isHtml: true,
                },
            },
            // Derzeit
            // Ausstattung: {}
        },
        Unfall: {
            Ort: 'String',
            Datum: 'Date',
            Uhrzeit: 'Time',
            Schadenhergang: {
                type: 'String',
                isHtml: true,
            },
            Plausibilität: {
                type: 'String',
                isHtml: true,
            },
            Kompatibilität: {
                type: 'String',
                isHtml: true,
            },
            polizeilichErfasst: 'Tristate',
            AktenzeichenPolizei: 'String',
            Polizeibehörde: 'String',
            KaskoSchadenart: 'String',
        },
        // Arrays are unsuitable for insert conditions
        // Besichtigungen             : visitsArray,
        // This is a virtual property that only exists inside the "Besichtigungen" array. This exists so the user can use placeholders within the context of the
        // "Besichtigungen" loop.
        Besichtigung: visitPlaceholderType,
        ErsteBesichtigung: visitPlaceholderType,
        LetzteBesichtigung: visitPlaceholderType,
        AnzahlBesichtigungen: 'Number',
        Schadenskalkulation: {
            GesamtNetto: 'Number',
            MwSt: 'Number',
            MwStProzent: {
                type: 'String',
                enum: ['16 %', '19 %'],
            },
            GesamtBrutto: 'Number',
            Minderwert: {
                Wert: 'Number',
                MethodenAnzahl: 'Number',
                MethodenNamen: {
                    type: ['String'],
                    enum: ['MFM', 'BVSK', 'Halbgewachs', 'Hamburger Modell', 'Ruhkopf/Sahm', '13. DVGT'],
                },

                Neupreis: 'Number',
                AlterInMonaten: 'Number',
                NeuwertigkeitTrömner: 'Number',
                Laufleistung: 'Number',
                ReparaturkostenErheblich: 'Number',
                ReparaturkostenGesamt: 'Number',
                Wiederbeschaffungswert: 'Number',
                Veräußerungswert: 'Number',
                MarktgängigkeitBvsk: 'Number',
                MarktgängigkeitMfm: 'Number',
                MarktgängigkeitTrömner: 'Number',
                VorschadenBvsk: 'Number',
                VorschadenMfm: 'Number',
                VorschadenTrömner: 'Number',
                LohnOhneLack: 'Number',
                Ersatzteile: 'Number',
                SchadenumfangMfm: 'Number',
                SchadensklasseBvsk: 'Number',
                FahrzeugschadenTrömner: 'Number',
                Kommentar: {
                    type: 'String',
                    isHtml: true,
                },
                basiertAufNettowerten: 'Boolean',
                EingabenUmMwStReduziert: 'Boolean',
                BVSK: 'Number',
                RuhkopfSahm: 'Number',
                DVGT: 'Number',
                Halbgewachs: 'Number',
                HamburgerModell: 'Number',
                MFM: 'Number',
                Trömner: 'Number',
                Durchschnitt: 'Number',
                AlterskorrekturMfm: 'Number',
                XWertRuhkopfSahm: 'Number',
                XWertHalbgewachs: 'Number',
                ReparaturZuWiederbeschaffungswert: 'Number',
            },
            TechnischerMinderwert: {
                Wert: 'Number',
                Kommentar: {
                    type: 'String',
                    isHtml: true,
                },
            },
            Wertverbesserung: {
                Wert: 'Number',
                Kommentar: {
                    type: 'String',
                    isHtml: true,
                },
            },
            Quelle: {
                type: 'String',
                enum: ['DAT', 'Audatex', 'Audatex-Textimport', 'Schätzung', 'Manuell', 'Sonstige'],
            },
            istPhantomkalkulation: 'Boolean',
            istÜberschlägigeKalkulation: 'Boolean',
            Schadensklasse: {
                type: 'String',
                enum: [
                    'Reparaturschaden',
                    'Reparaturschaden auf 130 %',
                    'Wirtschaftlicher Totalschaden',
                    'Totalschaden',
                ],
            },
            Reparatur: {
                mitDekraSätzen: 'Boolean',
                ReparaturauftragErteilt: 'Tristate',
                istFreigegeben: 'Boolean',
                BeilackierungErforderlich: 'Boolean',
                BeilackierungKommentar: {
                    type: 'String',
                    isHtml: true,
                },
                Achsvermessung: {
                    type: 'String',
                    enum: ['erforderlich', 'nicht erforderlich', 'bereits durchgeführt'],
                },
                AchsvermessungKommentar: {
                    type: 'String',
                    isHtml: true,
                },
                PrüfungHochvoltsystem: {
                    type: 'String',
                    enum: ['erforderlich', 'nicht erforderlich', 'bereits durchgeführt'],
                },
                PrüfungHochvoltsystemKommentar: {
                    type: 'String',
                    isHtml: true,
                },
                Karosserievermessung: {
                    type: 'String',
                    enum: ['erforderlich', 'nicht erforderlich', 'bereits durchgeführt'],
                },
                KarosserievermessungKommentar: {
                    type: 'String',
                    isHtml: true,
                },
                InstandsetzungKunststoffteileErforderlich: 'Boolean',

                KostenNetto: 'Number',
                KostenMwSt: 'Number',
                KostenBrutto: 'Number',
                DauerInArbeitstagen: 'String',
                DauerEinheit: 'String',
                ErsatzteilkostenNetto: 'Number',
                ErsatzteilkostenBrutto: 'Number',
                ArbeitslohnkostenNetto: 'Number',
                ArbeitslohnkostenBrutto: 'Number',
                NebenkostenNetto: 'Number',
                NebenkostenBrutto: 'Number',
                LackierungskostenNetto: 'Number',
                LackierungskostenBrutto: 'Number',
                NeuFürAltNetto: 'Number',
                NeuFürAltMwSt: 'Number',
                NeuFürAltBrutto: 'Number',
                RabattNetto: 'Number',
                RabattMwSt: 'Number',
                RabattBrutto: 'Number',
                // Reparatur.Weg = Reparaturweg
                Weg: {
                    type: 'String',
                    isHtml: true,
                },
                AnzahlRisiken: 'Number',
                Risiken: 'String',
                RisikenKommentar: {
                    type: 'String',
                    isHtml: true,
                },
                KalkulationKommentar: {
                    type: 'String',
                    isHtml: true,
                },
            },
            Restkraftstoff: {
                Menge: 'Number',
                PreisProLiter: 'Number',
                Kosten: 'Number',
            },
            Umbaukosten: {
                PositionenBezeichnungen: 'String',
                AnzahlPositionen: 'Number',
                GesamtkostenBrutto: 'Number',
                Kommentar: {
                    type: 'String',
                    isHtml: true,
                },
            },
            Entsorgungskosten: 'Number',
            AbUndAnmeldegebühren: 'Number',
            WeitereKosten: {
                Summe: 'Number',
                // Not available for the client since this is hard to parse.
                //Positionen: {
                //    Bezeichnung: 'String',
                //    Kosten: 'Number',
                //}[];
            },
            '130ProzentGrenzeNetto': 'Number',
            '130ProzentGrenzeBrutto': 'Number',
            '130ProzentGrenzeNachVorsteuerabzugsfähigkeit': 'Number',
            // Only a placeholder for inserting the damage calculation. This is not a public placeholder.
            // Details          : "Zu diesem Gutachten existiert noch keine Schadenskalkulation."
        },
        Notreparatur: {
            Status: {
                type: 'String',
                autocomplete: ['empfohlen', 'nicht empfohlen', 'bereits durchgeführt'],
            },
            KostenBrutto: 'Number',
            KostenBruttoCirca: 'String',
            Kommentar: {
                type: 'String',
                isHtml: true,
            },
            Arbeiten: 'String',
        },
        Bewertung: {
            // General valuation properties
            Quelle: {
                type: 'String',
                enum: ['DAT', 'Audatex', 'Eigene Wertermittlung', 'Sonstige'],
            },
            QuelleAbgerufen: 'Boolean',
            Stichtag: 'String',

            /**
             * These placeholders existed before the redesign valuation.
             * They are kept for legacy reasons.
             *
             * Fahrzeugwert maps to the active price (purchase or sales price)
             * If both are active, use the sales price, since this is the most important one for valuations.
             * Background: Most valuations are for the tax inspector when a car is transferred from a company to a private person.
             */

            FahrzeugwertNetto: 'Number',
            FahrzeugwertBrutto: 'Number',
            FahrzeugwertMwSt: 'Number',
            FahrzeugwertBezeichnung: 'String',
            FahrzeugwertMinusReparaturkostenNetto: 'Number',
            FahrzeugwertMinusReparaturkostenBrutto: 'Number',
            FahrzeugwertMinusReparaturkostenMwSt: 'Number',
            Besteuerungsart: {
                type: 'String',
                enum: ['regelbesteuert', 'differenzbesteuert', 'steuerneutral'],
            },

            // =====================
            // Redesign Valuation

            // First Vehicle Value (dealer selling price)
            HändlerVerkaufspreis: {
                Aktiv: 'Boolean',
                Netto: 'Number',
                Brutto: 'Number',
                Besteuerungsart: {
                    type: 'String',
                    enum: ['regelbesteuert', 'differenzbesteuert', 'steuerneutral'],
                },
                EnthalteneMwSt: 'Number',
                Bezeichnung: 'String',
            },

            // Second Vehicle Value (dealer purchase price)
            HändlerEinkaufspreis: {
                Aktiv: 'Boolean',
                Netto: 'Number',
                Brutto: 'Number',
                EnthalteneMwSt: 'Number',
                EnthältMwSt: 'Boolean',
                Bezeichnung: 'String',
            },

            //  Vehicle Base Value
            Grundwert: {
                HändlerVerkaufspreisNetto: 'Number',
                HändlerVerkaufspreisBrutto: 'Number',
                HändlerEinkaufspreisNetto: 'Number',
                HändlerEinkaufspreisBrutto: 'Number',
                Quelle: 'String',
            },

            HändlermargeProzent: 'Number',
            HändlermargeBrutto: 'Number',
            KorrekturenAngewendetAuf: {
                type: 'String',
                enum: ['Einkaufspreis', 'Verkaufspreis'],
            },

            // Correction and decrease from damage calculation
            AnrechnungKalkulation: { Netto: 'Number', Brutto: 'Number', Prozent: 'Number' },

            SummeAufAbwertungenGross: 'Number',
            sindAufAbwertungenVorhanden: 'Boolean',
        },
        OldtimerBewertung: {
            GesamtnoteZahl: oldtimerValuationScale,
            GesamtnoteAusformuliert: 'String',
            Marktwert: 'Number',
            Wiederbeschaffungswert: 'Number',
            Wiederherstellungswert: 'Number',
            Basisfahrzeugwert: 'Number',
            Wiederherstellungskosten: 'Number',
            Quelle: 'String',
            WertbeeinflussendeFaktoren: 'String',
            // The array element is for use in partials only. The user should not be able to use this in his templates or his document building block variant conditions.
            // WertbeeinflussendeFaktorenArray : null,
            Fahrzeugbeschreibung: {
                type: 'String',
                isHtml: true,
            },
            BewertungenSkala: {
                Blech: oldtimerValuationScale,
                Lack: oldtimerValuationScale,
                ChromUndZierteile: oldtimerValuationScale,
                DichtungenGummiteile: oldtimerValuationScale,
                Glas: oldtimerValuationScale,
                Reifen: oldtimerValuationScale,
                Fahrgastraum: oldtimerValuationScale,
                Motorraum: oldtimerValuationScale,
                Motor: oldtimerValuationScale,
                Kofferraum: oldtimerValuationScale,
            },
        },
        Zustandsbericht: {
            PrüfleitfadenBezeichnung: 'String',
            Abschlag: 'Number',
            ReparaturkostenNetto: 'Number',
            ReparaturkostenNettoMwStNeutral: 'Number',
            ReparaturkostenNettoRegelbesteuert: 'Number',
            ReparaturkostenMwSt: 'Number',
            ReparaturkostenBrutto: 'Number',
            MinderwertNetto: 'Number',
            MinderwertNettoMwStNeutral: 'Number',
            MinderwertNettoRegelbesteuert: 'Number',
            MinderwertMwSt: 'Number',
            MinderwertBrutto: 'Number',
            FahrzeugwertMinusReparaturkostenNetto: 'Number',
            FahrzeugwertMinusReparaturkostenBrutto: 'Number',
            FahrzeugwertMinusReparaturkostenMwSt: 'Number',
        },
        Rechnungsprüfung: {
            Lohn: {
                GeplanteKosten: 'Number',
                AbgerechneteKosten: 'Number',
                Differenz: 'Number',
                SummeKorrekturen: 'Number',
            },
            Ersatzteile: {
                GeplanteKosten: 'Number',
                AbgerechneteKosten: 'Number',
                Differenz: 'Number',
                SummeKorrekturen: 'Number',
            },
            Lack: {
                GeplanteKosten: 'Number',
                AbgerechneteKosten: 'Number',
                Differenz: 'Number',
                SummeKorrekturen: 'Number',
            },
            Nebenkosten: {
                GeplanteKosten: 'Number',
                AbgerechneteKosten: 'Number',
                Differenz: 'Number',
                SummeKorrekturen: 'Number',
            },
            Sonstiges: {
                GeplanteKosten: 'Number',
                AbgerechneteKosten: 'Number',
                Differenz: 'Number',
                SummeKorrekturen: 'Number',
            },
            Gesamt: {
                GeplanteKosten: 'Number',
                AbgerechneteKosten: 'Number',
                Differenz: 'Number',
                SummeKorrekturen: 'Number',
            },
            FremdrechnungDatum: 'String',
            FremdrechnungNummer: 'String',
            Korrekturpositionen: {
                ErsatzteileSumme: 'Number',
                LohnSumme: 'Number',
                LackSumme: 'Number',
                NebenkostenSumme: 'Number',
                SonstigesSumme: 'Number',
                GesamtSumme: 'Number',
                AnzahlPositionen: 'Number',
            },
            KorrigierteReparaturkostenNetto: 'Number',
            KorrigierteReparaturkostenMwSt: 'Number',
            KorrigierteReparaturkostenBrutto: 'Number',
        },
        Restwert: {
            KostenBrutto: 'Number',
            AnzahlInserate: 'Number',
            AnzahlGebote: 'Number',
            AnzahlRegionaleGebote: 'Number',
            AnzahlÜberregionaleGebote: 'Number',
            AnzahlAusgewählteGebote: 'Number',
            KeineGeboteTrotzAnfrage: 'Boolean',
            wurdeInseratErstellt: 'Boolean',
            wurdeAutoonlineInseratErstellt: 'Boolean',
            wurdeCarcasionInseratErstellt: 'Boolean',
            wurdeCartvInseratErstellt: 'Boolean',
            wurdeWinvalueInseratErstellt: 'Boolean',
            wurdeLokalesRestwertInseratErstellt: 'Boolean',
            sindAlleInserateAbgelaufen: 'Boolean',
            HöchstesAusgewähltesGebot: {
                ID: 'String',
                Bieter: {
                    Brieffenster: 'String',
                    Anrede: 'String',
                    Briefanrede: 'String',
                    Vorname: 'String',
                    Nachname: 'String',
                    Organisation: 'String',
                    NameKomplett: {
                        type: 'String',
                        isDocx: true,
                    },
                    Straße: 'String',
                    Postleitzahl: 'String',
                    Ort: 'String',
                    Telefon: 'String',
                    Telefon2: 'String',
                    EMail: 'String',
                    NameAdresse: 'String',
                    Kontaktdaten: 'String',
                },
                Gebot: {
                    ID: 'String',
                    Wert: 'Number',
                    Bindefrist: 'String',
                    Datum: 'String',
                    Quelle: 'String',
                    InseratsId: 'String',
                    Nummer: 'Number',
                },
            },
            Kommentar: {
                type: 'String',
                isHtml: true,
            },
            // Only used by the docx generator because handling this array is too complex for users in the document building block configuration screen.
            // Gebote : [],
            // WeitereAusgewählteGebote : [],
            NurRegionaleGebote: 'Boolean',
            LokaleRestwertanfrage: {
                AnzahlBieterOhneGebot: 'Number',
                AnzahlAngefragteBieter: 'Number',
            },
        },
        Wiederbeschaffung: {
            Besteuerungsart: {
                type: 'String',
                enum: ['regelbesteuert', 'differenzbesteuert', 'steuerneutral'],
            },
            SteuerbetragAbsolut: 'String',
            SteuerbetragProzentual: 'String',
            KostenNetto: 'Number',
            KostenBrutto: 'Number',
            Fahrzeuggrundwert: 'Number',
            sindKorrekturenVorhanden: 'Boolean',
            SummeKorrekturen: 'String',
            DauerInKalendertagen: 'String',
            DauerEinheit: 'String',
            Kommentar: {
                type: 'String',
                isHtml: true,
            },
            KorridorVon: 'Number',
            KorridorBis: 'Number',
            KorridorDurchschnitt: 'Number',
            CartvKorridorVon: 'Number',
            CartvKorridorBis: 'Number',
            CartvDurchschnitt: 'Number',
            CartvSuchradius: 'Number',
            ValuepilotKorridorVon: 'Number',
            ValuepilotKorridorBis: 'Number',
            ValuepilotSuchradius: 'Number',
            WinValueKorridorVon: 'Number',
            WinValueKorridorBis: 'Number',
            WinValueDurchschnitt: 'Number',
            WinValueSuchradius: 'Number',
            DatWert: 'Number',
            DatWebScan: 'Number',
            AudatexWert: 'Number',
            AudatexNeupreisBrutto: 'Number',
        },
        Wiederbeschaffungsaufwand: 'Number',
        WiederherstellungsaufwandDurchWiederbeschaffungswertProzent: 'Number',
        Nutzungsausfall: {
            Art: 'String',
            Entschädigungsgruppe: 'String',
            EntschädigungsgruppeVorAlterskorrektur: 'String',
            EntschädigungProTag: 'Number',
            Dauer: 'String',
            DauerEinheit: 'String',
            Mietwagenklasse: 'Number',
            MietwagenklasseName: 'String',
            MietwagenklasseKosten: 'Number',
            Kommentar: {
                type: 'String',
                isHtml: true,
            },
        },
        Vorhaltekosten: {
            aktiviert: 'Boolean',
            KostenNetto: 'Number',
        },
        Reparaturbestätigung: {
            Datum: 'Date',
            zuständigerSachverständiger: 'String',
            anwesenderSachverständiger: 'String',
            ReparaturArt: {
                type: 'String',
                enum: ['repariert', 'fach- & sachgerecht', 'teilweise', 'mit Gebrauchtteilen', 'verkehrssicher'],
            },
            ReparaturdauerInArbeitstagen: 'Number',
            Kommentar: {
                type: 'String',
                isHtml: true,
            },
        },
        Honorar: {
            BetragNetto: 'Number',
            BetragBrutto: 'Number',

            Fotokosten: 'Number',
            FotokostenSindPauschal: 'Boolean',
            FotokostenPauschalNetto: 'Number',
            FotokostenPauschalBrutto: 'Number',
            PreisProFotoNetto: 'Number',
            PreisProFotoBrutto: 'Number',

            ZweiterFotosatz: 'Boolean',
            PreisProZweitFotoNetto: 'Number',
            PreisProZweitFotoBrutto: 'Number',
            PreisZweitFotoPauschalNetto: 'Number',
            PreisZweitFotoPauschalBrutto: 'Number',

            Fahrtkosten: 'Number',
            FahrtkostenSindPauschal: 'Boolean',
            FahrtkostenPauschalNetto: 'Number',
            FahrtkostenPauschalBrutto: 'Number',
            PreisProKilometerNetto: 'Number',
            PreisProKilometerBrutto: 'Number',

            Schreibkosten: 'Number',
            SchreibkostenSindPauschal: 'Boolean',
            SchreibkostenPauschalNetto: 'Number',
            SchreibkostenPauschalBrutto: 'Number',
            PreisProSeiteNetto: 'Number',
            PreisProSeiteBrutto: 'Number',

            SchreibkostenKopie: 'Boolean',
            SchreibkostenKopiePauschalNetto: 'Number',
            SchreibkostenKopiePauschalBrutto: 'Number',
            PreisProSeiteKopieNetto: 'Number',
            PreisProSeiteKopieBrutto: 'Number',

            SonstigeKosten: 'Number',
            HatSonstigeKosten: 'Boolean',
            PortoUndTelefonNetto: 'Number',
            PortoUndTelefonBrutto: 'Number',

            SonstigeKostenPositionenNetto: 'String',
            SonstigeKostenPositionenBrutto: 'String',

            HonorartabelleName: {
                type: 'String',
                autocomplete: ['BVSK', 'HUK-Coburg', 'VKS', 'CGF'],
            },
            Jahr: 'Number',
            Grundlage: 'Number',
        },
        //*****************************************************************************
        //  Declaration Of Assignment
        //****************************************************************************/
        Abtretung: {
            Datum: 'Date',
        },
        UnterschriftKundeDatum: 'String',
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Declaration Of Assignment
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Letter
        //****************************************************************************/
        /**
         * This placeholder may be used in a document building block like "Zahlungserinnerung". This makes only sense in the context of a letter, though.
         */
        Anschreiben: {
            Betreff: 'String',
            Inhalt: {
                type: 'String',
                isHtml: true,
            },
            Empfänger: {
                ...contactPersonPlaceholderTypes,
                Typ: letterRecipientTypeGerman,
            },
            Datum: 'Date',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Letter
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Invoice & Payment Reminder
        //****************************************************************************/
        Rechnung: {
            Nummer: 'String',
            Datum: 'String',
            Leistungsdatum: 'String',
            istLeistungsdatumGleichRechnungsdatum: 'Boolean',
            BeginnLeistungszeitraum: 'String',
            istSammelrechnung: 'Boolean',
            istEuLieferung: 'Boolean',
            fälligAm: 'String',
            GesamtNetto: 'Number',
            MwSt: 'Number',
            MwStProzentsatz: {
                type: 'String',
                enum: ['0 %', '19 %', '20 %'],
            },
            GrundFürNullProzentMwSt: {
                type: 'String',
                enum: new Array<VatExemptionReasonGerman>(
                    'Kleinunternehmer',
                    'Innenumsatz Organschaft',
                    'Reverse Charge',
                ),
            },
            GesamtBrutto: 'Number',
            bezahlterBetrag: 'Number',
            gekürzterBetrag: 'Number',
            offenerBetrag: 'Number',
            ersteZahlung: paymentPlaceholderTypes,
            letzteZahlung: paymentPlaceholderTypes,
            istStornoRechnung: 'Boolean',
            stornierteRechnung: {
                Nummer: 'String',
                Datum: 'String',
            },
            Empfänger: {
                ...contactPersonPlaceholderTypes,
                Typ: invoiceRecipientTypeGerman,
            },
            Absender: contactPersonPlaceholderTypes,
            Betreff: 'String',
            Einleitung: {
                type: 'String',
                isHtml: true,
            },
            Zahlungsziel: 'Number',
        },

        // Only available within payment reminder documents.
        Mahnung: paymentReminderPlaceholderTypes,
        VorherigeMahnung: paymentReminderPlaceholderTypes,

        // Available in every document that's connected to an invoice.
        Zahlungserinnerung: paymentReminderPlaceholderTypes,
        ErsteMahnung: paymentReminderPlaceholderTypes,
        ZweiteMahnung: paymentReminderPlaceholderTypes,
        DritteMahnung: paymentReminderPlaceholderTypes,
        HöchsteMahnung: paymentReminderPlaceholderTypes,

        Bankverbindung: {
            IBAN: 'String',
            BIC: 'String',
            Kontoinhaber: 'String',
            Bank: 'String',
        },
        ZweiteBankverbindung: {
            IBAN: 'String',
            BIC: 'String',
            Kontoinhaber: 'String',
            Bank: 'String',
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Invoice & Payment Reminder
        /////////////////////////////////////////////////////////////////////////////*/

        OnlineUnterschrift: {
            Frist: 'Date',
            Link: 'String',
        },

        Dokument: {
            Typ: 'String',
            Gruppe: 'String',
        },

        // DOCX-only. There is no need to the user to be able to see this.
        //Fotos: PhotoGerman[];
        //FotosSpalteA: PhotoGerman[];
        //FotosSpalteB: PhotoGerman[];
    };

    //*****************************************************************************
    //  Custom Fields
    //****************************************************************************/
    if (fieldGroupConfigs?.length) {
        for (const fieldGroupConfig of fieldGroupConfigs) {
            // Only add fields the names of which are not empty. That would lead to placeholders like "Gutachten.EigeneFelder.undefined".
            const validFieldConfigs: FieldConfig[] = fieldGroupConfig.fieldConfigs.filter(
                (fieldConfig) => fieldConfig.name,
            );

            for (const fieldConfig of validFieldConfigs) {
                placeholderValueTree.Gutachten['EigeneFelder'][fieldConfig.placeholder] =
                    getPlaceholderValueLeafTypeFromCustomFieldConfig(fieldConfig);
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Custom Fields
    /////////////////////////////////////////////////////////////////////////////*/

    return placeholderValueTree;
}

function getPlaceholderValueLeafTypeFromCustomFieldConfig(
    fieldConfig: FieldConfig,
): PlaceholderValueLeafType | PlaceholderValueLeafDefinition {
    switch (fieldConfig.type) {
        case 'singleLineText':
            return 'String';
        case 'multiLineText':
            return {
                type: 'String',
                isHtml: true,
            };
        case 'select':
            return {
                type: 'String',
                enum: fieldConfig.dropdownOptions.map((dropdownOption) => dropdownOption.value),
            };
        case 'autocomplete':
            return {
                type: 'String',
                autocomplete: fieldConfig.dropdownOptions.map((dropdownOption) => dropdownOption.value),
            };
        // Although currency values are strings like "10,51 €" they are converted to numbers when being compared within a document building block variant condition.
        case 'currency':
        case 'number':
            return 'Number';
        case 'boolean':
            return 'Boolean';
        case 'tristate':
            return 'Tristate';
        case 'date':
            return 'Date';
        case 'button':
            return 'Boolean';
        default:
            throw new ServerError({
                code: 'CUSTOM_FIELD_VALUE_DOES_NOT_MATCH_FIELD_TYPE',
                message: `The given custom field config type cannot be mapped to a placeholder value tree leaf. This is a technical issue.`,
                data: {},
            });
    }
}

export function getPlaceholderValueTypeFromLeaf(
    placeholderValueTreeLeaf: PlaceholderValueLeafType | PlaceholderValueLeafDefinition,
) {
    if (typeof placeholderValueTreeLeaf === 'object') {
        return placeholderValueTreeLeaf.type;
    }
    return placeholderValueTreeLeaf;
}
export function getPlaceholderValueIsHTMLFromLeaf(
    placeholderValueTreeLeaf: PlaceholderValueLeafType | PlaceholderValueLeafDefinition,
) {
    if (typeof placeholderValueTreeLeaf === 'object') {
        return placeholderValueTreeLeaf.isHtml;
    }
    return false;
}

export type PlaceholderValueLeafType =
    | 'Number'
    | 'String'
    | 'Date'
    | 'Date-month-only'
    | 'Time'
    | 'Boolean'
    | 'Tristate';

export interface PlaceholderValueTree {
    [key: string]:
        | PlaceholderValueLeafType
        | PlaceholderValueLeafType[]
        | PlaceholderValueLeafDefinition
        | PlaceholderValueTree;
}

export interface PlaceholderValueLeafDefinition {
    type: PlaceholderValueLeafType | PlaceholderValueLeafType[];
    enum?: string[];
    autocomplete?: string[];
    /**
     * Set this to true if
     * 1. this placeholder value may be used in a text template or document building block (not the case for placeholder values that are rendered in OfficeOpenXML immediately such as invoice item descriptions or the Audatex text calculation).
     * 2. the placeholder value was entered through Quill (rich text editor).
     *
     * This flag allows the function that replaces the placeholders in document building blocks and text templates to strip the opening and closing <p> tags from the placeholder values if the placeholder is
     * embedded in a <p> tag in the document building block.
     */
    isHtml?: boolean;
    /**
     * Set to true if
     * 1. this placeholder value mey be used in a text template or document building block
     * 2. the placeholder value is generated using the function "enableLineBreaks()"
     *
     * This flag causes the placeholder value to be converted back to plain text and then to HTML:
     * - From "<w:p><w:r><w:t>Organization</w:t><w:br/>FirstName LastName</w:t></w:r></w:p>"
     * - Via "Organization\nFirstName LastName"
     * - To "<p>Organization</p><p>FirstName LastName</p>"
     */
    isDocx?: boolean;
}

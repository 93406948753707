<div id="task-list-overlay-container" class="card" @fadeInAndSlide>
    <div class="small-overlay-content">
        <div class="small-overlay-headline-container">
            <mat-icon class="small-overlay-headline-icon">task_alt</mat-icon>
            <h2 class="small-overlay-heading">Aufgaben</h2>
        </div>

        <div class="card-toolbar">
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="taskListSettings">settings</mat-icon>
            <mat-menu #taskListSettings>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    (click)="
                        userPreferences.taskPanel_sortToBackOnCompletion =
                            !userPreferences.taskPanel_sortToBackOnCompletion
                    "
                    matTooltip="Lege fest, ob eine Aufgabe automatisch nach unten geschoben wird, sobald du sie abschließt. Das sorgt für Übersichtlichkeit.">
                    <mat-icon>move_down</mat-icon>
                    Bei Abschluss ans Ende schieben
                    <mat-slide-toggle
                        class="margin-left-15 slide-toggle-small"
                        (click)="$event.stopPropagation()"
                        [ngModel]="userPreferences.taskPanel_sortToBackOnCompletion"
                        (ngModelChange)="
                            userPreferences.taskPanel_sortToBackOnCompletion =
                                !userPreferences.taskPanel_sortToBackOnCompletion
                        "></mat-slide-toggle>
                </a>
            </mat-menu>
        </div>

        <!--============================================-->
        <!-- Task List -->
        <!--============================================-->
        <div id="task-list" cdkDropList (cdkDropListDropped)="onTaskDropped($event)" *ngIf="sortedTasks.length">
            <div
                class="task-row"
                cdkDrag
                *ngFor="let task of sortedTasks"
                [ngClass]="{ 'task-status-done': task.isCompleted }">
                <div class="task-status-and-drag-indicator-column">
                    <!--********** Task Status **********-->
                    <task-done-checkbox
                        [task]="task"
                        (taskCompleted)="sortToBack(task); saveTask(task)"
                        (taskReopened)="saveTask(task)"></task-done-checkbox>

                    <!--********** Drag Indicator **********-->
                    <mat-icon cdkDragHandle class="drag-indicator medium-icon">drag_indicator</mat-icon>
                </div>

                <!--********** Title & Date **********-->
                <div
                    class="task-title-and-date-container"
                    (dblclick)="openTaskDetailsOnDirectDoubleClick({ event: $event, task })">
                    <task-title
                        [task]="task"
                        [singleLine]="false"
                        [shouldAutofocus]="!task.title"
                        (taskChange)="saveTask(task)"
                        (enterKeyPress)="createNewTask({ position: 'after', referenceTask: task })"
                        (shiftEnterKeyPress)="createNewTask({ position: 'before', referenceTask: task })"
                        (taskDeleteRequest)="deleteTask(task)"></task-title>

                    <!--********** Date **********-->
                    <div
                        datePickerWithInputAnchor
                        [showTimeInput]="true"
                        class="due-date"
                        [(date)]="task.dueDate"
                        (dateChange)="saveTask(task)">
                        <span
                            *ngIf="task.dueDate"
                            class="due-date-is-{{ task | compareTaskWithToday }}"
                            matTooltip="Fällig: {{ task.dueDate | moment: 'DD.MM.YYYY' }}">
                            {{ task.dueDate | relativeDate }}
                            {{ task.dueTime ? ' ' + task.dueTime + ' Uhr' : '' }}
                        </span>
                        <div *ngIf="!task.dueDate" class="missing-data-info label italic">ohne Datum</div>
                    </div>
                </div>

                <!--********** Time Estimate **********-->
                <task-estimated-duration
                    [(estimatedDuration)]="task.estimatedDuration"
                    (estimatedDurationChange)="saveTask(task)"></task-estimated-duration>

                <!--********** Assignee **********-->
                <user-avatar
                    [userId]="task.assigneeId"
                    userSelectorInputAnchor
                    [showEmptyState]="true"
                    (userSelected)="task.assigneeId = $event._id; saveTask(task)"></user-avatar>

                <!--********** Submenu **********-->
                <mat-icon class="submenu-icon" [matMenuTriggerFor]="taskSubmenu">more_vert</mat-icon>
                <mat-menu #taskSubmenu>
                    <!--********** Open Details **********-->
                    <a mat-menu-item class="menu-item-with-icon" (click)="openTaskDetailsDialog(task)">
                        <mat-icon>open_in_new</mat-icon>
                        Details öffnen
                    </a>

                    <!--********** Delete **********-->
                    <a mat-menu-item class="menu-item-with-icon alert" (click)="deleteTask(task)">
                        <mat-icon>delete</mat-icon>
                        Löschen
                    </a>
                </mat-menu>
            </div>
        </div>
        <!--============================================-->
        <!-- END Task List -->
        <!--============================================-->

        <!--============================================-->
        <!-- Placeholder: No Tasks -->
        <!--============================================-->
        <div id="placeholder-no-tasks" *ngIf="!sortedTasks.length">
            <div id="placeholder-no-tasks-icon-container" (click)="createNewTask({ position: 'last' })">
                <mat-icon>task_alt</mat-icon>
            </div>
            <div class="semibold">Keine Aufgaben</div>
        </div>
        <!--============================================-->
        <!-- END Placeholder: No Tasks -->
        <!--============================================-->
    </div>

    <!--============================================-->
    <!-- Add Task Button -->
    <!--============================================-->
    <div class="small-overlay-main-button" (click)="createNewTask({ position: 'last' })">
        <span>+ Neue Aufgabe</span>
    </div>
    <!--============================================-->
    <!-- END Add Task Button -->
    <!--============================================-->
</div>

import { ensureArray } from '@autoixpert/lib/arrays/ensure-array';

/**
 * Callback for the Array.sort method. Sorts an array in place by the properties provided, starting with the first one and proceeding with the following properties if values are equal.
 *
 * If comparing strings only, consider using sortByStringProperties.
 */
export function sortByProperty<T>(
    key: keyof T | (keyof T)[],
    sortOrder: 'ascending' | 'descending' = 'ascending',
): (itemA: T, itemB: T) => number {
    const keys = ensureArray(key);

    // The key to sort by. Secondary keys may be passed on when values are equal on first attempt.
    const primaryKey = keys[0];
    const secondaryKeys = keys.slice(1);

    return (itemA, itemB) => {
        const propertyA = itemA[primaryKey];
        const propertyB = itemB[primaryKey];
        const sortDirection: 1 | -1 = sortOrder === 'ascending' ? 1 : -1;

        if (propertyA === propertyB) {
            // No further properties to compare.
            if (!keys[1]) {
                return 0;
            }
            // Compare secondary properties.
            return sortByProperty(secondaryKeys)(itemA, itemB);
        }

        if (typeof propertyA === 'string' && typeof propertyB === 'string') {
            return propertyA.localeCompare(propertyB) * sortDirection;
        }

        return (propertyA < propertyB ? -1 : 1) * sortDirection;
    };
}

import { defaultPaintThicknessPositionTitles } from '../../static-data/reports/paint-thickness-position-titles';

export const paintThicknessTypes = [
    'thin',
    'original',
    'secondCoatOfPaint',
    'thinFillerLayer',
    'thickFillerLayer',
    'none',
];

export type PaintThicknessType = (typeof paintThicknessTypes)[number];

export const PaintThicknessColorMap: { [T in PaintThicknessType]: string } = {
    thin: '#49DCF2',
    original: '#53D57B',
    secondCoatOfPaint: '#F4CA16',
    thinFillerLayer: '#ED1111',
    thickFillerLayer: '#D400BE',
    none: '#DA0000',
};

export const PaintThicknessLabelMap: { [T in PaintThicknessType]: string } = {
    thin: 'zu dünn',
    original: 'originale Lackschicht',
    secondCoatOfPaint: 'zweite Lackschicht',
    thinFillerLayer: 'dünne Spachtelschicht',
    thickFillerLayer: 'dicke Spachtelschicht',
    none: 'nicht messbar',
};

export class PaintThicknessMeasurement {
    constructor(position: PaintThicknessPosition) {
        this._id = position;
        this.position = position;

        this.title = defaultPaintThicknessPositionTitles[position] ?? '';
    }

    _id: string;
    position: PaintThicknessPosition;
    title: string = '';
    comment: string = '';
    values: number[] = [null];

    // User can override the automatically (from scale) derived type
    // If manually set, this is the type the user chose
    manualType?: PaintThicknessType;

    // Reason why measurement was not possible
    noMeasurementReason?: string;
}

export type PaintThicknessPosition =
    | 'frontLeft'
    | 'frontCenter'
    | 'frontRight'
    | 'fenderFrontLeft'
    | 'hood'
    | 'fenderFrontRight'
    | 'doorDriver'
    | 'windshield'
    | 'doorFrontPassenger'
    | 'doorBackPassengerLeft'
    | 'roof'
    | 'doorBackPassengerRight'
    | 'fenderRearLeft'
    | 'fenderRearRight'
    | 'centerLeft'
    | 'centerRight'
    | 'rearLeft'
    | 'rearWindow'
    | 'roofRear'
    | 'rearCenter'
    | 'rearRight'
    // Truck & Semi Trailer
    | 'leftWallFront'
    | 'leftWallCenter'
    | 'leftWallRear'
    | 'ceilingFront'
    | 'ceilingCenter'
    | 'ceilingRear'
    | 'rightWallFront'
    | 'rightWallCenter'
    | 'rightWallRear'
    // Semi Truck
    | 'frameLeft'
    | 'frameRight'
    | 'custom';

<!--********** Layout Container **********-->
<div *ngIf="report" id="two-column-layout-container">
    <!--********** Left column **********-->
    <div id="left-column">
        <!--********** Select base value **********-->
        <div id="select-base-value" class="card">
            <h2 class="no-margin text-align-start">
                Grundwert
                <span
                    class="help-indicator"
                    matTooltip="Bestimmung des Ausgangswertes, auf den Korrekturen angewendet werden. Nicht zu verwechseln mit dem Grundwert der DAT-Bewertung, auf den innerhalb der DAT ebenfalls Korrekturen, Auf- und Abschläge und Anpassungsfaktoren (z. B. Reifen und Anzahl Halter) angewendet werden können.">
                    ?
                </span>
            </h2>

            <div class="base-value-providers">
                <!--********** DAT / Audatex Valuation **********-->
                <dat-or-audatex-valuation
                    [user]="user"
                    [team]="team"
                    [report]="report"
                    (baseValueSelected)="setBaseValue($event); saveReport()"></dat-or-audatex-valuation>

                <div class="market-value-and-residual-value-providers">
                    <!--********** Market value providers **********-->
                    <div class="market-value-card">
                        <div class="collapsible-header" (click)="expandMarketValueCard()">
                            <h2>Marktwert</h2>
                            <mat-icon *ngIf="!marketValueCardExpanded">unfold_more</mat-icon>
                        </div>

                        <market-value-overview
                            *ngIf="marketValueCardExpanded"
                            [report]="report"
                            [user]="user"
                            (vehicleValueGrossSelected)="handleMarketValueChange($event)"></market-value-overview>
                    </div>

                    <!--********** Residual value providers **********-->
                    <div class="residual-value-card">
                        <div
                            class="collapsible-header"
                            (click)="expandResidualValueCard()"
                            [ngClass]="{ collapsed: !residualValueCardExpanded }"
                            [matTooltip]="
                                residualValueCardExpanded ? '' : 'Klicken, um die Restwertberechnung einzublenden'
                            ">
                            <h2>Restwert</h2>
                            <mat-icon *ngIf="!residualValueCardExpanded">unfold_more</mat-icon>
                        </div>

                        <residual-value-overview
                            *ngIf="residualValueCardExpanded"
                            [report]="report"
                            [user]="user"
                            [team]="team"
                            [showValuationResult]="true"
                            (setVehicleValue)="handleResidualValueChange($event)"></residual-value-overview>
                    </div>
                </div>
            </div>
        </div>

        <!--********** Apply value corrections **********-->
        <div id="apply-value-correction" class="card">
            <h2 class="no-margin text-align-start">Korrekturen</h2>

            <valuation-calculation
                [user]="user"
                [team]="team"
                [report]="report"
                (decreaseFromDamageChanged)="calculateFinalVehicleValues()"></valuation-calculation>

            <replacement-value-correction-decreases-increases
                [report]="report"
                [team]="team"
                [user]="user"
                (valuesChanged)="calculateFinalVehicleValues()"></replacement-value-correction-decreases-increases>
        </div>

        <!--********** Select vehicle value to print **********-->
        <div id="select-value-to-print" class="print-settings card">
            <h2 class="no-margin text-align-start">Werte drucken</h2>
            <div class="label">
                Welche Werte sollen in den autoiXpert-eigenen Dokumenten abgedruckt werden? Das Dokument von Audatex/DAT
                wird davon nicht beeinflusst.
            </div>
            <div *ngIf="correctionSumGross != 0" class="label">
                Korrekturen werden gleichermaßen vom EK und VK abgezogen. Du kannst alle Werte jederzeit überschreiben.
            </div>

            <div class="print-settings-value-cards-container">
                <!--********** Dealer Purchase Price **********-->
                <vehicle-value-settings-card
                    [heading]="'Händler-EK'"
                    [showVatDeductible]="true"
                    [(isActive)]="report.valuation.vehicleValuePurchasePriceActive"
                    [(isOverwritten)]="report.valuation.vehicleValueOverwritten"
                    (isOverwrittenChange)="calculateFinalVehicleValues()"
                    [isVatDeductible]="mayCarOwnerDeductTaxes"
                    [(vehicleValueType)]="report.valuation.vehicleValueType"
                    [correctionsSum]="correctionSumGross"
                    [isCorrectionsTarget]="report.valuation.correctionsTarget === 'dealerPurchase'"
                    (selectedAsCorrectionTarget)="report.valuation.correctionsTarget = 'dealerPurchase'"
                    [(netValue)]="report.valuation.vehicleValueNet"
                    [(grossValue)]="report.valuation.vehicleValueGross"
                    (anyValueChange)="saveReport()"
                    [showDecimalPlaces]="team.preferences.valuation_showDecimalPlaces"
                    [disabled]="isReportLocked()"></vehicle-value-settings-card>

                <!--============================================-->
                <!-- Trade Margin -->
                <!--============================================-->
                <section
                    id="trade-margin-wrapper"
                    [ngClass]="{
                        active:
                            report.valuation.vehicleValuePurchasePriceActive &&
                            report.valuation.vehicleValueSalesPriceActive,
                    }">
                    <!--********** Left Arrow **********-->
                    <mat-icon
                        id="trade-margin-arrow-left"
                        class="trade-margin-arrow"
                        [ngClass]="{ reversed: report.valuation.correctionsTarget === 'dealerSales' }">
                        arrow_forward_ios
                    </mat-icon>

                    <!--********** Card **********-->
                    <div id="trade-margin-card" [ngClass]="{ warning: !report.valuation.tradeMarginGrossPercent }">
                        <div
                            id="trade-margin-percent"
                            form-field-overlay-anchor
                            form-field-overlay-placeholder="Marge"
                            [formFieldType]="'number'"
                            [(ngModel)]="report.valuation.tradeMarginGrossPercent"
                            formFieldSuffix="%"
                            [minimumFractionDigits]="0"
                            [maximumFractionDigits]="0"
                            (change)="
                                report.valuation.hasTradeMarginBeenChangedManually = true;
                                calculateFinalVehicleValues();
                                saveReport()
                            "
                            matTooltip="Marge = (Verkaufspreis - Einkaufspreis) / Verkaufspreis{{
                                '\n\n'
                            }}Die Marge wird standardmäßig aus Ein- und Verkaufspreis im Bereich Grundwert gemäß Formel bestimmt.{{
                                '\n\n'
                            }}Mithilfe der Marge wird aus dem Wert, dem die Korrekturen zufließen, der andere abgeleitet.{{
                                '\n\n'
                            }}Klicken, um die Marge selbst zu bestimmen.">
                            {{ report.valuation.tradeMarginGrossPercent || 0 | number: '1.0-0' }} %
                        </div>

                        <div class="label">Marge</div>

                        <!--********** Marker: Changed Manually **********-->
                        <div
                            id="trade-margin-marker-changed-manually"
                            *ngIf="report.valuation.hasTradeMarginBeenChangedManually"
                            (click)="
                                report.valuation.hasTradeMarginBeenChangedManually = false;
                                calculateTradeMarginFromVehicleBaseValue();
                                calculateFinalVehicleValues();
                                saveReport()
                            "
                            matTooltip="Manuell überschrieben.{{
                                '\n\n'
                            }}Klicke, um die Marge wieder automatisch aus den Verhältnissen der Fahrzeuggrundwerte (EK und VK) berechnen zu lassen."
                            @fadeInAndOut>
                            <mat-icon class="small-icon">keep</mat-icon>
                        </div>

                        <!--********** Marker: Target Value is overwritten **********-->
                        <div
                            id="trade-margin-marker-target-value-overwritten"
                            *ngIf="
                                (report.valuation.correctionsTarget === 'dealerPurchase' &&
                                    report.valuation.secondVehicleValueOverwritten) ||
                                (report.valuation.correctionsTarget === 'dealerSales' &&
                                    report.valuation.vehicleValueOverwritten)
                            "
                            matTooltip="Der Zielwert wurde manuell überschrieben. Es findet keine automatische Berechnung anhand der Marge statt.">
                            <mat-icon class="small-icon warning">warning</mat-icon>
                        </div>
                    </div>

                    <mat-icon
                        id="trade-margin-arrow-right"
                        class="trade-margin-arrow"
                        [ngClass]="{ reversed: report.valuation.correctionsTarget === 'dealerSales' }">
                        arrow_forward_ios
                    </mat-icon>
                </section>

                <!--============================================-->
                <!-- END Trade Margin -->
                <!--============================================-->

                <!--********** Dealer Sales Price **********-->
                <vehicle-value-settings-card
                    [heading]="'Händler-VK'"
                    [showTaxationType]="true"
                    [(isActive)]="report.valuation.vehicleValueSalesPriceActive"
                    [(isOverwritten)]="report.valuation.secondVehicleValueOverwritten"
                    [(vehicleValueType)]="report.valuation.secondVehicleValueType"
                    [(taxationType)]="report.valuation.taxationType"
                    (taxationTypeChange)="calculateFinalVehicleValues()"
                    [correctionsSum]="correctionSumGross"
                    [isCorrectionsTarget]="report.valuation.correctionsTarget === 'dealerSales'"
                    (selectedAsCorrectionTarget)="report.valuation.correctionsTarget = 'dealerSales'; saveReport()"
                    [(netValue)]="report.valuation.secondVehicleValueNet"
                    [(grossValue)]="report.valuation.secondVehicleValueGross"
                    (isOverwrittenChange)="calculateFinalVehicleValues()"
                    (anyValueChange)="saveReport()"
                    [showDecimalPlaces]="team.preferences.valuation_showDecimalPlaces"
                    [disabled]="isReportLocked()"></vehicle-value-settings-card>
            </div>
        </div>
    </div>

    <!--********** Right column **********-->
    <div id="right-column" class="one-third-data-column">
        <!--********** Video Tutorial **********-->
        <a
            *ngIf="!user?.userInterfaceStates.valuationTutorialSeen"
            class="video-tutorial-container card dark-card outlined elevated"
            (click)="user.userInterfaceStates.valuationTutorialSeen = true; saveUser()"
            href="https://youtu.be/DY8BDLJbcYE"
            rel="noopener"
            target="_blank"
            matTooltip="Anleitung zur Fahrzeugbewertung">
            <mat-icon class="video-tutorial-icon">play_circle</mat-icon>
            <div class="video-tutorial-title">
                Video-Anleitung &nbsp;
                <span class="label">(3:16 min)</span>
            </div>
        </a>
        <!--********** Valuation Result **********-->
        <div class="card dark-card card-with-padding" id="valuation-result-card">
            <!--********** Card Toolbar **********-->
            <div
                class="card-toolbar"
                *ngIf="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks') && !isReportLocked()">
                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="valuationPrintValuesSubmenu">more_vert</mat-icon>
                <mat-menu #valuationPrintValuesSubmenu>
                    <!--********** Decide whether decimal places are included or not **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-toggle"
                        (click)="
                            team.preferences.valuation_showDecimalPlaces =
                                !team.preferences.valuation_showDecimalPlaces;
                            saveTeam()
                        "
                        matTooltip="Nachkommastellen auf dem Deckblatt und im Gutachten anzeigen">
                        Nachkommastellen anzeigen
                        <mat-slide-toggle
                            class="slide-toggle-small margin-left-15"
                            [(ngModel)]="team.preferences.valuation_showDecimalPlaces"
                            (ngModelChange)="saveTeam()"
                            (click)="$event.stopPropagation()"></mat-slide-toggle>
                    </a>
                </mat-menu>
            </div>
            <h2 class="margin-bottom-10">Ergebnis</h2>
            <valuation-result
                #valuationResultComponent
                [team]="team"
                [report]="report"
                [reportLocked]="isReportLocked()"
                (excludeDatOrAudatexValuation)="excludeDatOrAudatexValuationFromReport()"
                (baseValueChange)="calculateFinalVehicleValues()"
                (valuationChange)="saveReport()"></valuation-result>
        </div>

        <!--********** Video Tutorial **********-->
        <a
            *ngIf="user?.userInterfaceStates.valuationTutorialSeen"
            class="video-tutorial-container card dark-card outlined"
            href="https://youtu.be/DY8BDLJbcYE"
            rel="noopener"
            target="_blank"
            matTooltip="Anleitung zur Fahrzeugbewertung">
            <mat-icon class="video-tutorial-icon">play_circle</mat-icon>
            <div class="video-tutorial-title">
                Video-Anleitung &nbsp;
                <span class="label">(3:16 min)</span>
            </div>
        </a>
    </div>
</div>

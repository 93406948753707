import { DocumentMetadata } from '../../models/documents/document-metadata';
import { Report } from '../../models/reports/report';
import { formatLicensePlate } from '../placeholder-values/utility-functions';
import { ContentTypeOrFileExtension, getDocumentFileExtension } from './get-document-file-extension';

/**
 * In case no other file name pattern has been defined, use this method to determine the file name.
 *
 * Only usable for documents within a report or with invoice documents that have a connected report.
 */
export function addClaimantAndLicensePlateToDocumentTitle(
    documentTitle: DocumentMetadata['title'],
    report: Report,
    contentTypeOrFileExtension: ContentTypeOrFileExtension,
): string {
    const claimantName = `${report.claimant.contactPerson.firstName || ''} ${
        report.claimant.contactPerson.lastName || ''
    }`;
    const licensePlate = formatLicensePlate(report.car.licensePlate);
    const fileExtension = getDocumentFileExtension(contentTypeOrFileExtension);

    const basename = `${documentTitle} ${claimantName} ${licensePlate}`
        // Replace excess spaces
        .replace(/ {2,}/g, ' ')
        .trim();

    return `${basename}.${fileExtension}`;
}

import { DocumentGroup } from '../../models/reports/document-group';
import { Report } from '../../models/reports/report';
import { getCommunicationRecipientsFromReport } from '../involved-parties/get-communication-recipients-from-report';
import { addDocumentOrderToReportForRecipient } from './add-document-order-to-report-for-recipient';

/**
 * Documents may be sorted individually per recipient. Create a document order object for each recipient.
 *
 * This function is used when document orders are created, e.g. on report initialization/change or when creating
 * a repair confirmation. Repair confirmations have their own document group within which document orders exist
 * for each recipient.
 */
export function addDocumentOrderToReportPerRecipient({
    report,
    documentGroup,
}: {
    report: Report;
    documentGroup: DocumentGroup;
}) {
    const communicationRecipients = getCommunicationRecipientsFromReport({ report });
    for (const communicationRecipient of communicationRecipients) {
        addDocumentOrderToReportForRecipient({
            report,
            communicationRecipient,
            documentGroup,
        });
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Photo } from '@autoixpert/models/reports/damage-description/photo';

/**
 * This service provides a way for other components to trigger events such
 * as re-rendering the layout inside the Muuri directives.
 */
@Injectable()
export class PhotoMuuriGridService {
    constructor() {}

    // Reset the sizes and positioning of photos in the grid. Re-calculate the grid size.
    public layoutTriggered$: Subject<void> = new Subject();

    // Move items from one position to another.
    public moveItems$: Subject<MuuriMoveItemsMap> = new Subject();

    // Arrange items in a given order.
    public arrangeItems$: Subject<Photo['_id'][]> = new Subject();

    public triggerLayout(): void {
        this.layoutTriggered$.next();
    }

    public moveItems(moveItemsMap: MuuriMoveItemsMap): void {
        this.moveItems$.next(moveItemsMap);
    }

    public arrangeItems(sortOrder: Photo['_id'][]): void {
        this.arrangeItems$.next(sortOrder);
    }
}

export interface MuuriMoveItemsMap {
    fromIndexes: number[];
    targetPosition: number;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { IntermediaryAnalyticsRecord } from '@autoixpert/models/analytics/intermediary-analytics.model';
import { AnalyticsFilterComponent } from '../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { IntermediaryAnalyticsService } from '../../shared/services/analytics/intermediary-analytics.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { AnalyticsSettingsMenuComponent } from '../analytics-settings-menu/analytics-settings-menu.component';
import { setDefaultChartOptions } from '../default-chart-options';

@Component({
    selector: 'intermediaries-analytics',
    templateUrl: './intermediaries.component.html',
    styleUrls: ['./intermediaries.component.scss'],
})
export class IntermediariesComponent implements OnInit {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;
    @ViewChild(AnalyticsSettingsMenuComponent, { static: true }) protected settings: AnalyticsSettingsMenuComponent;

    constructor(
        private intermediaryAnalyticsService: IntermediaryAnalyticsService,
        private apiErrorService: ApiErrorService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    public chartTitle: string = 'Umsatz nach Vermittler';
    private barChart: ApexCharts;
    private barChartOptions: apexcharts.ApexOptions;
    private doughnutChart: ApexCharts;
    private doughnutChartOptions: apexcharts.ApexOptions;
    public analyticsPending: boolean = false;

    public totalRevenue: number = 0;
    public totalNumberOfReports: number = 0;

    public records: IntermediaryAnalyticsRecord[] = [];

    public filterAnalyticsFrom: string;
    public filterAnalyticsTo: string;

    ngOnInit() {
        setDefaultChartOptions();

        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateBackToAnalyticsList(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    public rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/

    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.records.length) return;

        const revenueSeries = [...this.getIntermediaryRevenuesForChart().firstIntermediaryRevenues];
        if (this.getIntermediaryRevenuesForChart().others !== null) {
            revenueSeries.push(this.getIntermediaryRevenuesForChart().others);
        }

        const categoriesEntryOthers: IntermediaryAnalyticsRecord = {
            intermediary: 'Sonstige',
            totalRevenueNet: null,
            totalRevenueGross: null,
            totalNumberOfReports: null,
            idsOfAllInvoices: [],
            idsOfRegularInvoices: [],
            idsOfCreditNotes: [],
            idsOfOutstandingInvoices: [],
            outstandingRevenueNet: null,
            outstandingRevenueGross: null,
        };

        this.barChartOptions = {
            chart: {
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            series: [
                {
                    name: 'Umsatz des Vermittlers',
                    data: revenueSeries,
                },
            ],
            xaxis: {
                // Categories go into the Y axis when the bar chart is horizontal but...
                categories: (this.records.length <= 5
                    ? this.records
                    : [...this.records.slice(0, 5), categoriesEntryOthers]
                )
                    // Upper case transformation is necessary to allow Apexcharts to calculate the required space right.
                    .map((record) => (record.intermediary || 'Ohne Vermittler').toUpperCase()),
                // ... all styling for the final xaxis is determined here.
                labels: {
                    formatter: (value) => currencyFormatterEuro(+value),
                    style: {
                        fontFamily: "'Source Sans Pro', sans-serif",
                    },
                },
            },
            yaxis: {
                labels: {
                    show: true,
                },
            },
            tooltip: {
                y: {
                    formatter: (revenueByIntermediary) => currencyFormatterEuro(revenueByIntermediary),
                },
            },
        };

        // Clear before re-drawing
        if (this.barChart) {
            this.barChart.destroy();
        }

        this.barChart = new ApexCharts(document.querySelector('#bar-chart'), this.barChartOptions);
        this.barChart.render();

        //*****************************************************************************
        //  Doughnut Chart
        //****************************************************************************/
        this.doughnutChartOptions = {
            chart: {
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            value: {
                                formatter: (value) => currencyFormatterEuro(+value),
                                fontFamily: "'Source Sans Pro', sans-serif",
                            },
                        },
                    },
                },
            },
            legend: {
                show: true,
                fontFamily: "'Source Sans Pro', sans-serif",
            },
            series: revenueSeries,
            labels: (this.records.length <= 5
                ? this.records
                : [...this.records.slice(0, 5), categoriesEntryOthers]
            ).map((record) => {
                // Upper case transformation is necessary to allow Apexcharts to calculate the required space right.
                return record.intermediary || 'Ohne Vermittler';
            }),
            tooltip: {
                y: {
                    formatter: (revenueByIntermediary) => currencyFormatterEuro(revenueByIntermediary),
                },
            },
        };

        // Clear before re-drawing
        if (this.doughnutChart) {
            this.doughnutChart.destroy();
        }

        this.doughnutChart = new ApexCharts(document.querySelector('#doughnut-chart'), this.doughnutChartOptions);
        this.doughnutChart.render();
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Doughnut Chart
        /////////////////////////////////////////////////////////////////////////////*/
    }

    /**
     * If there are more than 5 intermediaries, sum up the remainder.
     */
    private getIntermediaryRevenuesForChart(): { firstIntermediaryRevenues: number[]; others: number } {
        const intermediaryRevenues: number[] = this.records.map((record) =>
            this.settings.useNetValues ? record.totalRevenueNet : record.totalRevenueGross,
        );
        let others: number = null;
        const firstIntermediaryRevenues: number[] = intermediaryRevenues.slice(0, 5);

        if (intermediaryRevenues.length > 5) {
            others = intermediaryRevenues.slice(5).reduce((total, revenue) => total + revenue, 0);
        }

        return {
            firstIntermediaryRevenues,
            others,
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public refreshLocalData(): void {
        this.calculateTotalRevenue();
        this.drawChart();
    }

    public calculateTotalRevenue(): void {
        this.totalRevenue = this.records.reduce(
            (total, currentItem) =>
                total + (this.settings.useNetValues ? currentItem.totalRevenueNet : currentItem.totalRevenueGross),
            0,
        );
    }

    public calculateTotalNumberOfReports(): void {
        this.totalNumberOfReports = this.records.reduce(
            (total, currentItem) => total + currentItem.totalNumberOfReports,
            0,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    public updateAnalytics(): void {
        this.analyticsPending = true;

        this.intermediaryAnalyticsService
            .getReportsByIntermediary({
                from: this.filterAnalyticsFrom,
                to: this.filterAnalyticsTo,
                officeLocationIds: this.filter.officeLocationIds,
                assessorIds: this.filter.assessorIds,
                reportLabelIds: this.filter.reportLabelConfigIds,
                invoiceLabelIds: this.filter.invoiceLabelConfigIds,
            })
            .subscribe({
                next: (records) => {
                    this.records = records;

                    this.analyticsPending = false;
                    this.calculateTotalRevenue();
                    this.calculateTotalNumberOfReports();
                    setTimeout(() => {
                        this.drawChart();
                    }, 0);
                },
                error: (error) => {
                    this.analyticsPending = false;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ACCESS_DENIED: {
                                title: 'Zugriffsrecht fehlt',
                                body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                                forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                            },
                        },
                        defaultHandler: {
                            title: 'Fehler bei Auswertung',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/
}

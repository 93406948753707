<div class="dialog-overlay" (click)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container" #contentContainer>
        <div id="bid-list-header-container" class="card">
            <div id="bid-count-container">
                <h2 id="bid-list-header-heading">
                    <span *ngIf="selectedBids.length > 0" matTooltip="Ausgewählte Gebote">
                        {{ selectedBids.length }} von
                    </span>
                    <span matTooltip="Angezeigte Gebote">{{ filteredBids.length }}&nbsp;</span>
                    <span *ngIf="selectedBids.length === 0">{{ filteredBids.length === 1 ? 'Gebot' : 'Gebote' }}</span>
                    <span *ngIf="selectedBids.length > 0">{{ filteredBids.length === 1 ? 'Gebot' : 'Geboten' }}</span>
                </h2>
                <div id="number-of-hidden-bids" class="label" *ngIf="bids.length - filteredBids.length > 0">
                    {{ bids.length - filteredBids.length }} ausgeblendet
                </div>
            </div>
            <div id="regional-bids-filter-container">
                <mat-checkbox
                    *ngIf="!disabled"
                    [(ngModel)]="useRegionalBidsOnly"
                    (ngModelChange)="deselectNonMatchingBids(); filterAndSortBids(); emitRegionalFilterChange()"
                    matTooltip="Nur regionale Gebote anzeigen & drucken">
                    nur regionale
                </mat-checkbox>
            </div>
            <!--============================================-->
            <!-- Filters -->
            <!--============================================-->
            <div id="bid-origin-filter-container">
                <!--********** AUTOonline **********-->
                <div
                    class="origin-logo-container"
                    [ngClass]="{ active: showAutoonlineBids }"
                    (click)="toggleAutoonlineFilter()"
                    matTooltip="Gebote von AUTOonline ein-/ausblenden">
                    <img src="/assets/images/logos/autoonline-logo.svg" alt="" />
                </div>

                <!--********** CARTV **********-->
                <div
                    class="origin-logo-container"
                    [ngClass]="{ active: showCartvBids }"
                    (click)="toggleCartvFilter()"
                    matTooltip="Gebote von CarTV ein-/ausblenden">
                    <img src="/assets/images/logos/cartv-logo.svg" alt="" />
                </div>

                <!--********** Winvalue **********-->
                <div
                    class="origin-logo-container"
                    [ngClass]="{ active: showWinvalueBids }"
                    (click)="toggleWinvalueFilter()"
                    matTooltip="Gebote von Winvalue ein-/ausblenden">
                    <img src="/assets/images/logos/winvalue.png" alt="" />
                </div>

                <!--********** Car.Casion **********-->
                <div
                    class="origin-logo-container"
                    [ngClass]="{ active: showCarcasionBids }"
                    (click)="toggleCarcasionFilter()"
                    matTooltip="Gebote von car.casion ein-/ausblenden">
                    <img id="car-casion-logo" src="/assets/images/logos/car-casion.png" alt="" />
                    car.casion
                </div>

                <!--********** Custom **********-->
                <div
                    class="origin-logo-container"
                    [ngClass]="{ active: showCustomBids }"
                    (click)="toggleCustomBidsFilter()"
                    matTooltip="Eigene Gebote ein-/ausblenden">
                    <span>Eigene</span>
                </div>
            </div>
            <!--============================================-->
            <!-- END Filters -->
            <!--============================================-->
            <div id="bid-list-header-toolbar">
                <mat-icon class="toolbar-icon" *ngIf="!disabled" (click)="addCustomBid()" matTooltip="Neues Gebot">
                    add
                </mat-icon>
                <mat-icon
                    class="toolbar-icon"
                    [matMenuTriggerFor]="bidSelectorSettingsMenu"
                    #bidSelectorSettingsSubmenuTrigger="matMenuTrigger">
                    more_vert
                </mat-icon>
                <mat-menu #bidSelectorSettingsMenu="matMenu">
                    <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="customResidualValueSheetMenu">
                        Gebotsblatt (eigenes)
                    </a>
                    <mat-menu #customResidualValueSheetMenu>
                        <a
                            mat-menu-item
                            class="menu-item-with-icon"
                            (click)="toggleHideBiddersWithoutBid()"
                            [matTooltip]="
                                'Mit dieser Option entscheidest du, ob auch Bieter, die kein Gebot abgegeben haben, im eigenen Gebotsblatt dieses Gutachtens abgedruckt werden sollen.\n\n' +
                                (isAdmin(user._id, team)
                                    ? ' Klicke auf das Lesezeichen, um die aktuelle Einstellung für alle Gutachten zu übernehmen.'
                                    : ' Administratoren können diese Einstellung auch für alle Gutachten speichern.')
                            ">
                            Bieter ohne Gebot
                            {{
                                (report.valuation.includeBiddersWithNoBidInCustomResidualValueSheet
                                | shouldIncludeBiddersWithoutBid
                                    : team.preferences.includeBiddersWithNoBidInCustomResidualValueSheet)
                                    ? 'ausblenden'
                                    : 'einblenden'
                            }}
                            <mat-icon
                                class="toolbar-icon outlined"
                                *ngIf="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                                (click)="
                                    rememberHideBiddersWithNoBidSettings();
                                    $event.stopPropagation();
                                    bidSelectorSettingsSubmenuTrigger.closeMenu()
                                "
                                matTooltip="Die aktuelle Einstellung für alle Gutachten verwenden.">
                                bookmark_border
                            </mat-icon>
                        </a>
                    </mat-menu>
                </mat-menu>
                <mat-icon class="close-icon" (click)="closeBidSelector()">close</mat-icon>
            </div>
        </div>

        <div id="bids-in-edit-mode-container" *ngIf="bidsInEditMode.length > 0" class="card">
            <div class="bid-in-edit-mode" *ngFor="let bid of bidsInEditMode">
                <div class="bid-in-edit-mode-details">
                    <div class="bid-in-edit-mode-core-details-hover-area">
                        <div class="bid-in-edit-mode-core-details-container">
                            <h3>{{ bid.notInterested ? 'Kein Gebot' : 'Gebot' }}</h3>
                            <div class="bid-in-edit-mode-core-details">
                                <div [class.not-interested]="bid.notInterested">
                                    <div class="bid-in-edit-mode-bid-input">
                                        <currency-input
                                            [disabled]="bid.notInterested"
                                            placeholder="Wert"
                                            [(value)]="bid.bidValue.value"
                                            [matTooltip]="
                                                bid.notInterested
                                                    ? 'Du hast ausgewählt, dass der Bieter kein Gebot abgegeben hat. Um ein Gebot hinzuzufügen, klicke auf das Plus-Symbol rechts neben dem Eingabefeld.'
                                                    : ''
                                            "></currency-input>
                                        <mat-icon
                                            class="input-external-icon show-icon-on-card-hover"
                                            (click)="toggleNoBidPlaced(bid)"
                                            [matTooltip]="
                                                bid.notInterested
                                                    ? 'Gebot hinzufügen'
                                                    : 'Bieter hat kein Gebot abgegeben'
                                            ">
                                            {{ bid.notInterested ? 'add' : 'block' }}
                                        </mat-icon>
                                    </div>
                                    <date-input
                                        placeholder="Gültig bis"
                                        [(date)]="bid.bindingDate"
                                        [disabled]="report.state === 'done' || bid.notInterested"
                                        matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"></date-input>
                                </div>
                                <div class="checkbox-group">
                                    <mat-checkbox [(ngModel)]="bid.regional">Regional</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bid-in-edit-mode-contact-details-container">
                        <h3>Kontaktdaten</h3>
                        <div class="bid-in-edit-mode-contact-details-line-one">
                            <mat-form-field>
                                <input
                                    placeholder="Bieter Firma"
                                    matInput
                                    [matAutocomplete]="biddersAutocomplete.autocomplete"
                                    [(ngModel)]="bid.bidder.address.companyName"
                                    [disabled]="report.state === 'done'"
                                    #bidderOrganizationSearchField />
                            </mat-form-field>
                            <address-autocompletion
                                #biddersAutocomplete
                                [value]="bid.bidder.address.companyName"
                                [addressContext]="{ city: bid.bidder.address.city }"
                                [disabled]="report.state === 'done'"
                                [contactAutocompletionEnabled]="true"
                                [organizationTypeFilter]="['bidder', 'residualValueRequestRecipient', 'garage']"
                                [showSuggestionsIfEmpty]="true"
                                [externalOrganizationAutocompletionEnabled]="true"
                                (addressSelected)="insertBidderAddressFromAutocomplete($event, bid)"
                                (contactPersonSelected)="
                                    selectBidderFromAutocomplete($event, bid)
                                "></address-autocompletion>

                            <mat-form-field>
                                <input
                                    placeholder="Ansprechpartner"
                                    matInput
                                    [matAutocomplete]="biddersAutocompleteForContact.autocomplete"
                                    [(ngModel)]="bid.bidder.address.contact"
                                    [disabled]="report.state === 'done'"
                                    #bidderOrganizationSearchField />
                            </mat-form-field>
                            <address-autocompletion
                                #biddersAutocompleteForContact
                                [value]="bid.bidder.address.contact"
                                [addressContext]="{ city: bid.bidder.address.city }"
                                [disabled]="report.state === 'done'"
                                [contactAutocompletionEnabled]="true"
                                [organizationTypeFilter]="['bidder', 'residualValueRequestRecipient', 'garage']"
                                (contactPersonSelected)="
                                    selectBidderFromAutocomplete($event, bid)
                                "></address-autocompletion>
                        </div>

                        <div class="bid-in-edit-mode-contact-details-line-two">
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Telefon 1"
                                    [(ngModel)]="bid.bidder.address.telephone"
                                    [disabled]="report.state === 'done'" />
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Telefon 2"
                                    [(ngModel)]="bid.bidder.address.telephone2"
                                    [disabled]="report.state === 'done'" />
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="E-Mail"
                                    [(ngModel)]="bid.bidder.address.emailAddress"
                                    [disabled]="report.state === 'done'" />
                            </mat-form-field>
                        </div>
                        <div class="bid-in-edit-mode-contact-details-line-two">
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Straße"
                                    [matAutocomplete]="bidderStreetAutocomplete.autocomplete"
                                    [(ngModel)]="bid.bidder.address.street"
                                    [disabled]="report.state === 'done'" />
                                <address-autocompletion
                                    #bidderStreetAutocomplete
                                    [value]="bid.bidder.address.street"
                                    [addressContext]="{ city: bid.bidder.address.city }"
                                    [disabled]="report.state === 'done'"
                                    [externalAddressAutocompletionEnabled]="true"
                                    (addressSelected)="
                                        insertBidderAddressFromAutocomplete($event, bid)
                                    "></address-autocompletion>
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="PLZ"
                                    [(ngModel)]="bid.bidder.address.postcode"
                                    [disabled]="report.state === 'done'"
                                    zip-city-input
                                    [street]="bid.bidder.address.street"
                                    (cityChange)="insertCityIntoModel(bid.bidder, $event)" />
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="Ort"
                                    [(ngModel)]="bid.bidder.address.city"
                                    [disabled]="report.state === 'done'"
                                    #zipCityInput />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="bid-in-edit-mode-buttons-container">
                    <button (click)="saveBid(bid); leaveEditMode(bid); filterAndSortBids()">Speichern</button>
                    <button class="flat" (click)="leaveEditMode(bid); filterAndSortBids()">Verwerfen</button>
                </div>
            </div>
        </div>

        <div id="bid-list-container" class="card">
            <div id="no-bids-placeholder-container" *ngIf="bids.length === 0">
                <h2>Keine Gebote vorhanden</h2>
            </div>

            <div id="no-bids-visible-placeholder-container" *ngIf="bids.length > 0 && filteredBids.length === 0">
                <h2>Keine Gebote sichtbar</h2>
                <span>{{ bids.length }} {{ bids.length === 1 ? 'Gebot' : 'Gebote' }} durch Filter ausgeblendet.</span>
            </div>

            <table id="bid-list" *ngIf="filteredBids.length > 0">
                <tr
                    class="bid-list-item"
                    *ngFor="let bid of filteredBids"
                    (dblclick)="bid.origin === 'custom' && editCustomBid(bid)"
                    [ngClass]="{ selected: bid.selected, inEditMode: bidsInEditMode.includes(bid) }">
                    <td class="bid-selection-checkbox-container">
                        <mat-checkbox
                            [(ngModel)]="bid.selected"
                            [disabled]="disabled"
                            (change)="saveReport()"
                            matTooltip="Gebot abdrucken"></mat-checkbox>
                    </td>
                    <td *ngIf="bid.bidValue.value !== null" class="bid-value-container">
                        {{ bid.bidValue.value | number }}
                        {{ bid.bidValue.currency === 'EUR' ? '€' : bid.bidValue.currency }}
                    </td>
                    <td
                        *ngIf="bid.bidValue.value === null"
                        class="bid-value-container bid-value-no-interest-icon"
                        [matTooltip]="bid.notInterested ? 'Kein Interesse' : 'Kein Gebot abgegeben'">
                        <mat-icon>block</mat-icon>
                    </td>
                    <td class="bid-origin-container" [ngSwitch]="bid.origin">
                        <img
                            class="cartv-logo"
                            *ngSwitchCase="'autoonline'"
                            src="/assets/images/logos/autoonline-logo.svg"
                            matTooltip="AUTOonline" />
                        <img
                            class="cartv-logo"
                            *ngSwitchCase="'cartv'"
                            src="/assets/images/logos/cartv-logo.svg"
                            matTooltip="CARTV" />
                        <img
                            class="winvalue-logo"
                            *ngSwitchCase="'winvalue'"
                            src="/assets/images/logos/winvalue.png"
                            matTooltip="WinValue" />
                        <img
                            class="carcasion-logo"
                            *ngSwitchCase="'carcasion'"
                            src="/assets/images/logos/car-casion.png"
                            matTooltip="car.casion" />
                        <span *ngSwitchCase="'axResidualValueRequest'">Lokale Restwertanfrage</span>
                        <span *ngSwitchCase="'custom'">Eigenes Gebot</span>
                        <div class="bid-id" *ngIf="bidDetailsShown.get(bid)">ID {{ bid.bidID }}</div>
                    </td>
                    <td class="bidder-regional-container" [class.disabled]="disabled">
                        <mat-icon
                            class="bid-is-regional"
                            *ngIf="bid.regional"
                            matTooltip="regionales Gebot"
                            (click)="toggleRegional(bid)">
                            place
                        </mat-icon>
                        <mat-icon *ngIf="!bid.regional" matTooltip="Überregionales Gebot" (click)="toggleRegional(bid)">
                            location_off
                        </mat-icon>
                    </td>
                    <td class="bidder-company-container">
                        <div class="bidder-company-name">
                            <span class="bidder-company-name-text">{{ bid.bidder.address.companyName }}</span>
                            <!--********** cartv returns the placeholder "Angebot vor Ablauf" as the bidder's name until the offer expired. That way bidders can withdraw their bid without a loss in reputation until the end of the offer. **********-->
                            <span
                                *ngIf="bid.bidder.address.companyName === 'Angebot vor Ablauf'"
                                class="help-indicator"
                                matTooltip="Cartv liefert die Adressdaten der Bieter erst mit Ablauf des Inserats.">
                                ?
                            </span>
                        </div>
                        <div
                            *ngIf="bidDetailsShown.get(bid) && bid.bindingDate"
                            class="bidder-binding-date"
                            matTooltip="Angebot gültig bis">
                            Gültig bis
                            {{ bid.bindingDate | moment }}
                        </div>
                    </td>
                    <td class="bidder-contact-person-container">
                        <div class="bidder-contact-person">{{ bid.bidder.address.contact }}</div>
                        <div *ngIf="bidDetailsShown.get(bid) && bid.bidder.address.telephone" class="bidder-phone">
                            <a href="tel:{{ bid.bidder.address.telephone }}">{{ bid.bidder.address.telephone }}</a>
                        </div>
                        <div *ngIf="bidDetailsShown.get(bid) && bid.bidder.address.emailAddress" class="bidder-email">
                            <a href="mailto:{{ bid.bidder.address.emailAddress }}">
                                {{ bid.bidder.address.emailAddress }}
                            </a>
                        </div>
                    </td>
                    <td class="bidder-country-zip-and-city">
                        <div
                            class="bidder-country-zip-and-city-link link"
                            (click)="openBidderAddressOnGoogleMaps(bid)"
                            matTooltip="Klicken, um Entfernung des Bieters zum Anspruchsteller anzuzeigen">
                            <div *ngIf="bidDetailsShown.get(bid)" class="bidder-street">
                                {{ bid.bidder.address.street }}
                            </div>
                            {{
                                !bid.bidder.address.country || bid.bidder.address.country === 'DE'
                                    ? ''
                                    : bid.bidder.address.country + ' - '
                            }}
                            {{ bid.bidder.address.postcode }}
                            {{ bid.bidder.address.city }}
                        </div>
                    </td>
                    <td class="bid-list-item-submenu-icon-container">
                        <mat-icon class="bid-item-sub-menu-trigger" [matMenuTriggerFor]="subMenuBid">
                            more_vert
                        </mat-icon>
                        <mat-menu #subMenuBid="matMenu">
                            <mat-option (click)="toggleContactDetails(bid)">
                                {{ bidDetailsShown.get(bid) ? 'Details verbergen' : 'Details anzeigen' }}
                            </mat-option>
                            <mat-option *ngIf="bid.origin === 'custom' && !disabled" (click)="editCustomBid(bid)">
                                Bearbeiten
                            </mat-option>
                            <mat-option *ngIf="bid.origin === 'custom'" (click)="downloadBidDocument(bid, 'pdf')">
                                Gebot als PDF
                            </mat-option>
                            <mat-option *ngIf="bid.origin === 'custom'" (click)="downloadBidDocument(bid, 'docx')">
                                Gebot in Word bearbeiten
                            </mat-option>
                            <mat-option
                                *ngIf="
                                    (bid.origin === 'custom' || bid.origin === 'axResidualValueRequest') && !disabled
                                "
                                (click)="removeCustomBid(bid); filterAndSortBids()">
                                Gebot löschen
                            </mat-option>
                        </mat-menu>
                    </td>
                </tr>
            </table>

            <!-- Small hint that is shown, when bidders without bid are hidden. Clicking this note will fade in these bidders -->
            <div
                class="bid-list-show-not-interested-bid-container"
                *ngIf="hideBiddersWithoutBid && numberOfBiddersWithoutBid > 0">
                <span
                    class="bid-list-show-not-interested-bid-link"
                    (click)="hideBiddersWithoutBid = false; this.filterAndSortBids()"
                    matTooltip="Bieter ohne Gebot werden automatisch ausgeblendet, sobald mindestens drei Gebote eingegangen sind.">
                    {{ numberOfBiddersWithoutBid }}
                    {{ numberOfBiddersWithoutBid === 1 ? 'weiterer' : 'weitere' }} Bieter ohne Gebot
                </span>
            </div>

            <!-- Info note that asks the user if bidders without bids should be included in the custom residual value sheet
                 The info note is shown when there are bidders without bid, the note hasn't been dismissed yet and the setting
                 has not been activated yet (for team or report) -->
            <div
                *ngIf="
                    numberOfBiddersWithoutBid > 0 &&
                    !(
                        report.valuation.includeBiddersWithNoBidInCustomResidualValueSheet
                        | shouldIncludeBiddersWithoutBid
                            : team.preferences.includeBiddersWithNoBidInCustomResidualValueSheet
                    ) &&
                    !team.userInterfaceStates.isInfoDismissed_activateBiddersWithNoBidForCustomResidualValueSheet
                "
                class="info-note">
                <mat-icon class="outlined">edit_document</mat-icon>
                <div class="info-note-text">
                    <h3>Bieter ohne Gebot im eigenen Gebotsblatt ausgeblendet</h3>

                    <p>
                        Derzeit werden nur Bieter mit einem Gebot im eigenen Gebotsblatt aufgelistet. Möchtest du auch
                        alle Bieter abdrucken, die kein Gebot abgegeben haben? Du kannst diese Einstellung jederzeit
                        über das Dreipunktmenü in diesem Dialog nur für das aktuelle Gutachten oder für alle Gutachten
                        (Admin-Rechte erforderlich) definieren.
                    </p>
                    <p>
                        Die Auswahl der Gebote, die im Gutachten abgedruckt werden, ist von dieser Einstellung nicht
                        betroffen. Diese kannst du weiterhin über die Checkbox in jeder Zeile individuell auswählen.
                    </p>

                    <div class="info-note-buttons-row">
                        <div class="info-note-button-primary" (click)="toggleHideBiddersWithoutBid()">
                            Bieter ohne Gebot hinzufügen
                        </div>
                        <div class="info-note-button-secondary" (click)="dismissIncludeBiddersWithoutBidInfo()">
                            Weglassen
                        </div>
                    </div>
                </div>
            </div>

            <div id="actions-container">
                <button
                    *ngIf="!disabled"
                    [class.disabled]="selectedBids.length === 0"
                    (click)="selectedBids.length > 0 && acceptBids()"
                    [matTooltip]="
                        selectedBids.length === 0
                            ? 'Bitte zuerst ein Gebot auswählen'
                            : 'Höchstes ausgewähltes Gebot als Restwert übernehmen'
                    ">
                    Restwert übernehmen
                </button>
                <button *ngIf="disabled" (click)="closeBidSelector()">Schließen</button>
            </div>
        </div>
    </div>
</div>

import { PaintMaterialSurcharge } from '@autoixpert/models/contacts/garage-fee-set';
import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

/**
 * Map of error handlers that catch errors dealing with the repair calculation.
 *
 * Repair calculation system specific errors (e.g. DAT authentication) are located in their respective error handlers
 * files because those errors are relevant for other parts of autoiXpert, too, e.g. the preferences component where
 * username and password are checked.
 */
export function getRepairCalculationErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        EMPTY_CAR_PAINT_MATERIAL_SURCHARGE_VALUE: (error) => ({
            title: 'Lackmaterial-Aufschlag leer',
            body: `Für die aktuelle Lackart "${translatePaintType(
                error.data.paintType,
            )}" existiert kein Lackmaterial-Aufschlag in den Werkstattkostensätzen. Bitte trage einen Lackmaterial-Aufschlag ein.`,
        }),
    };
}

function translatePaintType(paintType: PaintMaterialSurcharge['paintType']) {
    switch (paintType) {
        case 'uni':
            return 'Uni';
        case 'metallic':
            return 'Metallic';
        case 'perlcolor':
            return 'Perleffekt';
        default:
            return 'Standard';
    }
}

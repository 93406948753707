import { HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { generateId } from '@autoixpert/lib/generate-id';
import { RequestRegistryService } from '../services/request-registry.service';

@Injectable()
export class RequestRegistryInterceptor implements HttpInterceptor {
    constructor(private requestRegistry: RequestRegistryService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const requestId = generateId();
        this.addSyncProcessToRegistry(requestId, request);

        // Remove the request from the registry on its way back. Do so in success and error cases.
        return next.handle(request).pipe(
            tap({
                next: (httpEvent) => {
                    // Only remove the process when the final response is coming in. Don't remove it on the first (possible) XHR ProgressEvent that is passed down this chain.
                    if (httpEvent.type === HttpEventType.Response) {
                        this.removeProcessFromRegistry(requestId);
                    }
                },
                error: () => {
                    this.removeProcessFromRegistry(requestId);
                },
            }),
        );
    }

    //*****************************************************************************
    //  Sync registry methods
    //****************************************************************************/
    private addSyncProcessToRegistry(id: string, request: HttpRequest<any>): string {
        // Don't include GET requests because the request interceptor shall only show the user whether
        // his changes are still being saved. GET requests are read-only, and therefore not relevant.
        if (request.method === 'GET') {
            return;
        }

        this.requestRegistry.pendingRequestsRegistry.set(id, request);

        this.requestRegistry.pendingRequestsRegistryLength.next(this.requestRegistry.pendingRequestsRegistry.size);

        return id;
    }

    private removeProcessFromRegistry(id: string): boolean {
        const success = this.requestRegistry.pendingRequestsRegistry.delete(id);
        this.requestRegistry.pendingRequestsRegistryLength.next(this.requestRegistry.pendingRequestsRegistry.size);
        return success;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sync registry methods
    /////////////////////////////////////////////////////////////////////////////*/
}

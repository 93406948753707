import { getAllTiresOfVehicle } from '@autoixpert/lib/tires/get-all-tires-of-vehicle';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { ChecklistItemStatus } from '@autoixpert/models/report-progress/checklist-item-status';
import { ManuallySetProgressStatus } from '@autoixpert/models/report-progress/manually-set-progress-status';
import { ChecklistItemName } from '@autoixpert/models/report-progress/report-progress-checklist-item-names';
import { LeaseReturnItem } from '@autoixpert/models/reports/damage-calculation/lease-return';
import { Report } from '@autoixpert/models/reports/report';
import { getSelectedGarageFeeSet } from '../../../../shared/libraries/garages/get-selected-garage-fee-set';
import { getVehicleValueGross } from '../../../../shared/libraries/get-vehicle-value';
import { hasPaintThicknessMeasurement } from '../../car-condition/paint-thickness/has-paint-thickness-measurement';
import { isPaintThicknessMeasurementComplete } from '../../car-condition/paint-thickness/is-paint-thickness-measurement-complete';

// TODO Remove all the ternary operators and instead bring the settings in order which checklist item is called by which report type.
export function determineChecklistItemStatus(name: ChecklistItemName, report: Report): ChecklistItemStatus {
    // Check if a manual status exists
    const manualStatus: ManuallySetProgressStatus = report.manuallySetProgressStatus?.find(
        (manualStatus) => manualStatus.name === name,
    );
    if (manualStatus) {
        return manualStatus.status;
    }

    switch (name) {
        //*****************************************************************************
        //  Accident Data
        //****************************************************************************/
        case 'claimantNameAndAddress':
            // Shorthand
            const claimantContact: ContactPerson = report.claimant.contactPerson;

            return getCompletionStatus(
                claimantContact.lastName || claimantContact.organization,
                claimantContact.streetAndHouseNumberOrLockbox,
                claimantContact.zip,
                claimantContact.city,
            );
        case 'claimantEmail':
            return getCompletionStatus(report.claimant.contactPerson.email);
        case 'claimantLicensePlate':
            return getCompletionStatus(report.car.licensePlate);
        case 'lawyerNameAndAddress':
            // Shorthand
            const lawyerContact: ContactPerson = report.lawyer.contactPerson;

            return getCompletionStatus(
                lawyerContact.lastName || lawyerContact.organization,
                lawyerContact.streetAndHouseNumberOrLockbox,
                lawyerContact.zip,
                lawyerContact.city,
            );
        case 'accidentDate':
            return getCompletionStatus(report.accident.date);
        case 'partialKaskoDamageType':
            return getCompletionStatus(report.accident.kaskoDamageType);
        case 'visitLocationAndDate':
            // We simplify and only regard the first visit.
            const firstVisit = report.visits[0];
            if (!firstVisit) return 'empty';

            return getCompletionStatus(
                firstVisit.locationName || (firstVisit.street && firstVisit.city),
                firstVisit.date,
            );
        case 'authorOfDamageLicensePlate':
            return getCompletionStatus(report.authorOfDamage.licensePlate);
        case 'leaseProviderNameAndAddress':
            // Shorthand
            const leaseProviderContact: ContactPerson = report.claimant.contactPerson;

            return getCompletionStatus(
                leaseProviderContact.lastName || leaseProviderContact.organization,
                leaseProviderContact.streetAndHouseNumberOrLockbox,
                leaseProviderContact.zip,
                leaseProviderContact.city,
            );
        case 'leaseContractNumber':
            return getCompletionStatus(report.leaseProvider?.caseNumber);

        case 'insuranceName':
            return getCompletionStatus(report.insurance?.contactPerson.organization);
        case 'insuranceNumber':
            return getCompletionStatus(report.authorOfDamage?.insuranceNumber);
        case 'insuranceCaseNumber':
            return getCompletionStatus(report.insurance?.caseNumber);
        case 'declarationOfAssignment':
            return getCompletionStatus(
                report.signableDocuments
                    ?.find((signableDocument) => signableDocument.documentType === 'declarationOfAssignment')
                    ?.signatures.some((signature) => signature.hash),
            );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Accident Data
        /////////////////////////////////////////////////////////////////////////////*/
        //*****************************************************************************
        //  Car Data
        //****************************************************************************/
        case 'vin':
            return getCompletionStatus(report.car.vin);
        case 'vinWasChecked':
            return getCompletionStatus(report.vinWasChecked);
        case 'carMakeAndModel':
            return getCompletionStatus(report.car.make, report.car.model);
        case 'productionYear':
            return getCompletionStatus(report.car.productionYear);
        case 'firstRegistration':
            return getCompletionStatus(report.car.firstRegistration);
        case 'latestRegistration':
            return getCompletionStatus(report.car.latestRegistration);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Car Data
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Car Condition
        //****************************************************************************/
        case 'conditionInputs':
            return getCompletionStatus(
                report.car.generalCondition,
                report.car.autobodyCondition,
                report.car.paintCondition,
            );
        case 'roadworthiness':
            return getCompletionStatus(report.car.roadworthiness);
        case 'mileage':
            return getCompletionStatus(report.car.mileageMeter || report.car.mileage || report.car.mileageAsStated);
        case 'nextGeneralInspection':
            return getCompletionStatus(report.car.nextGeneralInspection);
        case 'damageSketch':
            return getCompletionStatus(report.car.damages?.length > 0);
        case 'damageDescription':
            return getCompletionStatus(report.car.damageDescription);
        case 'paintThickness':
            if (!report.car.paintThicknessMeasurements) return 'empty';

            return isPaintThicknessMeasurementComplete(report)
                ? 'complete'
                : hasPaintThicknessMeasurement(report)
                  ? 'partiallyComplete'
                  : 'empty';
        case 'repairedPreviousDamage':
            return getCompletionStatus(report.car.repairedPreviousDamage);
        case 'unrepairedPreviousDamage':
            return getCompletionStatus(report.car.unrepairedPreviousDamage);
        case 'tires':
            const allTires = getAllTiresOfVehicle(report.car.axles);
            const allRelevantValuesOnAllTires = allTires
                .map((tire) => [tire.type, tire.treadInMm, tire.manufacturer])
                .flat(); // Exclude season because that's always filled.
            return getCompletionStatus(...allRelevantValuesOnAllTires);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Car Condition
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Photos
        //****************************************************************************/
        case 'photosUploaded':
            return getCompletionStatus(report.photos.length > 0);
        case 'somePhotosNamed':
            return getCompletionStatus(report.photos.some((photo) => photo.description));
        // case "somePhotosHidden":
        //     return getCompletionStatus();
        case 'someResidualValueVersionsAdded':
            return getCompletionStatus(
                report.photos.some((photo) => photo.versions.residualValueExchange.fabricJsInformation),
            );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Photos
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Damage Calculation
        //****************************************************************************/
        case 'garage':
            if (report.damageCalculation?.repair.useDekraFees) {
                return getCompletionStatus(true);
            } else {
                const garageContact: ContactPerson = report.garage.contactPerson;
                return getCompletionStatus(
                    garageContact.organization,
                    garageContact.streetAndHouseNumberOrLockbox,
                    garageContact.zip,
                    garageContact.city,
                    getSelectedGarageFeeSet(report.garage)?.carBody.firstLevel, // If any value is set, we assume the fee set is complete.
                );
            }
        case 'repairCosts':
            return getCompletionStatus(report.damageCalculation.repair.repairCostsNet);
        case 'vehicleValue':
            return getCompletionStatus(report.valuation.vehicleValueNet);
        case 'replacementValue':
            return getCompletionStatus(getVehicleValueGross(report.valuation, 'replacementValue'));
        case 'residualValue':
            return getCompletionStatus(report.valuation.residualValue);
        case 'diminishedValue':
            return getCompletionStatus(report.valuation.diminishedValue);
        case 'downtimeCompensation':
            return getCompletionStatus(
                report.damageCalculation.downtimeCompensationPerWorkday,
                report.damageCalculation.downtimeInWorkdaysDueToReparation ||
                    report.damageCalculation.replacementTimeInWorkdays,
            );
        case 'damageClass':
            return getCompletionStatus(report.damageCalculation.damageType);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Damage Calculation
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Oldtimer Valuation
        //****************************************************************************/
        case 'oldtimerMarketValue':
            return getCompletionStatus(getVehicleValueGross(report.valuation, 'marketValue'));
        case 'oldtimerReplacementValue':
            return getCompletionStatus(getVehicleValueGross(report.valuation, 'replacementValue'));
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Oldtimer Valuation
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Lease Return
        //****************************************************************************/
        case 'leaseReturnChecklistRepairCosts': {
            // The leaseReturn object is undefined until a template is selected. Therefore assign a surrogate array instead of checking sections of undefined.
            const requiredLeaseReturnItems: LeaseReturnItem[] = report.leaseReturn?.sections.reduce(
                (accumulator, section) => {
                    accumulator.push(...section.items.filter((item) => item.isRequired));
                    return accumulator;
                },
                [],
            ) ?? [false];
            return getCompletionStatus(...requiredLeaseReturnItems.map((item) => item.repairCostsNet));
        }
        case 'leaseReturnChecklistDiminishedValue': {
            // The leaseReturn object is undefined until a template is selected. Therefore assign a surrogate array instead of checking sections of undefined.
            const requiredLeaseReturnItems: LeaseReturnItem[] = report.leaseReturn?.sections.reduce(
                (accumulator, section) => {
                    accumulator.push(...section.items.filter((item) => item.isRequired));
                    return accumulator;
                },
                [],
            ) ?? [false];
            return getCompletionStatus(...requiredLeaseReturnItems.map((item) => item.aboveAverageWearCostsNet));
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Lease Return
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Invoice Audit
        //****************************************************************************/
        case 'invoiceAuditProjectedCosts':
            return getCompletionStatus(
                !!report.invoiceAudit.columnTotals.projectedRepairCosts &&
                    report.invoiceAudit.columnTotals.projectedRepairCosts > 0,
            );
        case 'invoiceAuditActualCosts':
            return getCompletionStatus(
                !!report.invoiceAudit.columnTotals.actualRepairCosts &&
                    report.invoiceAudit.columnTotals.actualRepairCosts > 0,
            );
        case 'invoiceAuditCorrectedCosts':
            // corrected costs are complete if the user added a row AND each row has an amount or comment (otherwise it's partially complete)
            return getCompletionStatus(
                report.invoiceAudit.correctionItems?.length > 0,
                ...(report.invoiceAudit.correctionItems ?? []).map((item) => item.correctionAmount || item.comment),
            );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Invoice Audit
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Fees
        //****************************************************************************/
        case 'assessorsFee':
            return getCompletionStatus(report.feeCalculation.assessorsFee);
        case 'travelExpenses':
            return getCompletionStatus(
                report.feeCalculation.useTravelExpensesFixedPrice
                    ? report.feeCalculation.travelExpensesFixedPrice
                    : report.feeCalculation.numberOfKilometers && report.feeCalculation.pricePerKilometer,
            );
        case 'writingFees':
            return getCompletionStatus(
                report.feeCalculation.useWritingFeesFixedPrice
                    ? report.feeCalculation.writingFeesFixedPrice
                    : report.feeCalculation.numberOfPages && report.feeCalculation.pricePerPage,
            );
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Fees
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Print & Transmission
        //****************************************************************************/
        case 'sentToClaimant':
            return getCompletionStatus(report.claimant.receivedEmail || report.claimant.receivedLetter);
        case 'sentToInsurance':
            return getCompletionStatus(report.insurance?.receivedEmail || report.insurance?.receivedLetter);
        case 'sentToLawyer':
            return getCompletionStatus(report.lawyer?.receivedEmail || report.lawyer?.receivedLetter);
        case 'sentToLawyerOrInsurance':
            return getCompletionStatus(
                report.insurance?.receivedEmail ||
                    report.insurance?.receivedLetter ||
                    report.lawyer?.receivedEmail ||
                    report.lawyer?.receivedLetter,
            );
        case 'sentToGarage':
            return getCompletionStatus(report.garage?.receivedEmail || report.garage?.receivedLetter);
        case 'sentToLeaseProvider':
            return getCompletionStatus(report.leaseProvider?.receivedEmail || report.leaseProvider?.receivedLetter);
        case 'sentToSeller':
            return getCompletionStatus(report.seller?.receivedEmail || report.seller?.receivedLetter);
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Print & Transmission
        /////////////////////////////////////////////////////////////////////////////*/
    }
}

/**
 * Return completion status based on whether all passed values (one is possible too!)...
 * - ... are truthy -> complete
 * - ... are falsy -> empty
 * - ... are neither -> partiallyComplete
 * @param values
 */
function getCompletionStatus(...values: any[]): ChecklistItemStatus {
    if (values.every((value) => !!value || value === 0)) return 'complete';
    if (values.every((value) => !value)) return 'empty';
    return 'partiallyComplete';
}

export function cumulateCompletionStatus(...statuses: ChecklistItemStatus[]): ChecklistItemStatus {
    if (statuses.every((status) => status === 'complete')) return 'complete';
    if (statuses.every((status) => status === 'empty')) return 'empty';
    return 'partiallyComplete';
}

import { Axle } from '../../models/reports/car-identification/axle';
import { getTirePlaceholderValues } from './get-tires-placeholder-values';
import { AxleGerman } from './placeholder-values.types';

export function getAxlesPlaceholderValues({ axles }: { axles: Axle[] }): AxleGerman[] {
    const axlesGerman: AxleGerman[] = [];

    for (const axle of axles) {
        axlesGerman.push(
            getAxlePlaceholderValues({
                axle,
            }),
        );
    }

    return axlesGerman;
}

export function getAxlePlaceholderValues({ axle }: { axle: Axle }): AxleGerman {
    return {
        AchsenNummer: axle.axleNumber,
        VorneOderHinten: axle.axlePosition === 'rear' ? 'hinten' : 'vorne',
        Achslast: axle.axleLoad,
        ReifenLinksAußen: axle.outerLeftTire && getTirePlaceholderValues({ axle, tire: axle.outerLeftTire }),
        ReifenLinks: axle.leftTire && getTirePlaceholderValues({ axle, tire: axle.leftTire }),
        ReifenMitte: axle.centerTire && getTirePlaceholderValues({ axle, tire: axle.centerTire }),
        ReifenRechts: axle.rightTire && getTirePlaceholderValues({ axle, tire: axle.rightTire }),
        ReifenRechtsAußen: axle.outerRightTire && getTirePlaceholderValues({ axle, tire: axle.outerRightTire }),
        istLenkachse: axle.isSteerable,
    };
}

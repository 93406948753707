<div id="electronic-invoice-label-and-icons">
    E-Rechnung
    <span
        class="help-indicator"
        (click)="openElectronicInvoiceFaq()"
        matTooltipShowDelay="300"
        matTooltip="Rechnungen mit einem Unternehmen als Rechnungsempfänger müssen seit dem 01.01.2025 als E-Rechnung geschrieben werden. Das gilt auch für Unternehmen wie Kleinunternehmer und Ärzte, die nicht vorsteuerabzugsberechtigt sind.{{
            '\n\n'
        }}Klicke für die FAQ-Seite des Bundesfinanzministeriums.{{ '\n\n' }}Beispiele:{{
            '\n'
        }}- Dein Kunde (= Rechnungsempfänger) ist eine Privatperson. Auch wenn du die Rechnung per E-Mail an die Versicherung schickst, ist keine E-Rechnung notwendig, weil der Rechnungsempfänger eine Privatperson ist.{{
            '\n'
        }}- Du bist Kasko-Gutachter und stellst die Rechnung direkt an die Versicherung. E-Rechnung ist notwendig.">
        ?
    </span>
    <mat-icon
        class="small-icon warning"
        *ngIf="isElectronicInvoiceWarningShown()"
        [matTooltip]="getTooltipForElectronicInvoiceWarning()">
        warning
    </mat-icon>
</div>
<mat-slide-toggle
    [(ngModel)]="isElectronicInvoiceEnabled"
    (change)="freezeElectronicInvoiceStatus(); handleElectronicInvoiceToggleChange()"
    [matTooltip]="
        isElectronicInvoiceEnabled
            ? 'Rechnungs-PDF erhält einen XRechnung-XML-Anhang und wird damit zur E-Rechnung. Klicke zum Deaktivieren.'
            : 'E-Rechnung aktivieren'
    "
    [disabled]="disabled"></mat-slide-toggle>
<!--********** Electronic Invoice Status Frozen **********-->
<mat-icon
    id="electronic-invoice-status-frozen-icon"
    class="small-icon"
    *ngIf="!disabled && isElectronicInvoiceStatusFrozen"
    (click)="unfreezeElectronicInvoiceStatus(); activateElectronicInvoiceAccordingToRecipient()"
    matTooltip="E-Rechnungs-Status festgeschrieben durch manuelle Änderung.{{
        '\n\n'
    }}Klicken, um die E-Rechnung wieder automatisch aktivieren zu lassen, wenn sich Infos des Rechnungsempfängers ändern.">
    push_pin
</mat-icon>

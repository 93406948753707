import { Component, EventEmitter, Input, Output } from '@angular/core';
import { translateRecipientRole } from '@autoixpert/lib/translators/translate-recipient-role';
import { InvoiceInvolvedPartyRole } from '@autoixpert/models/invoices/invoice';
import { InvolvedPartyRole } from '@autoixpert/models/reports/involved-parties/involved-party';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';
import { trackById } from '../../libraries/track-by-id';

/**
 * Column of recipient icons (claimant, garage, lawyer, insurance)
 *
 * Used to display and select involved parties.
 */
@Component({
    selector: 'recipients-column',
    templateUrl: 'recipients-column.component.html',
    styleUrls: ['recipients-column.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class RecipientsColumnComponent<InvolvedPartyType> {
    @Input() recipients: ({
        receivedEmail: boolean;
        receivedLetter: boolean;
        role: InvolvedPartyRole | InvoiceInvolvedPartyRole;
    } & InvolvedPartyType)[] = [];
    @Input() selectedRecipient: InvolvedPartyType;

    @Output() selectedRecipientChange: EventEmitter<InvolvedPartyType> = new EventEmitter<InvolvedPartyType>();

    public selectRecipient(recipient: InvolvedPartyType): void {
        this.selectedRecipientChange.emit(recipient);
    }

    public translateRecipientRole = translateRecipientRole;

    public trackById = trackById;
}

import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { getFullRecipientName } from '@autoixpert/lib/placeholder-values/get-full-recipient-name';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { SignableDocumentType } from '@autoixpert/models/documents/document-metadata';
import { SignableDocumentsRenderingConfig } from '@autoixpert/models/signable-documents/signable-document-rendering-config';

@Component({
    selector: 'customer-signature-participants-summary',
    templateUrl: './customer-signature-participants-summary.component.html',
    styleUrls: ['./customer-signature-participants-summary.component.scss'],
})
export class CustomerSignatureParticipantsSummaryComponent {
    constructor(private domSanitizer: DomSanitizer) {}

    @Input() config: SignableDocumentsRenderingConfig;
    @Input() documentType: SignableDocumentType;

    get report() {
        return this.config.report;
    }

    get responsibleAssessorContactPerson(): ContactPerson {
        return this.config.participantSummary.responsibleAssessorContactPerson;
    }

    public getCompleteClaimantName(): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(
            getFullRecipientName({ contactPerson: this.report.claimant.contactPerson }).join('<br>'),
        );
    }

    public getCompleteCarOwnerName(): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(
            getFullRecipientName({
                contactPerson: this.report.claimant.isOwner
                    ? this.report.claimant.contactPerson
                    : this.report.ownerOfClaimantsCar.contactPerson,
            }).join('<br>'),
        );
    }

    public getCompleteOwnerOfAuthorOfDamagesCarName(): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(
            getFullRecipientName({
                contactPerson: this.report.authorOfDamage.isOwner
                    ? this.report.authorOfDamage.contactPerson
                    : this.report.ownerOfAuthorOfDamagesCar.contactPerson,
            }).join('<br>'),
        );
    }

    public getCompleteLawyerName(): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(
            getFullRecipientName({ contactPerson: this.report.lawyer.contactPerson }).join('<br>'),
        );
    }

    public getCompleteAssessorName(): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(this.config.participantSummary.completeAssessorName);
    }
}

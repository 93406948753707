import { getPhotosFeeTotal } from '@autoixpert/lib/fee-calculation/get-photos-fee-total';
import { getPostageAndPhoneFees } from '@autoixpert/lib/fee-calculation/get-postage-and-phone-fees-total';
import { getTravelExpensesTotal } from '@autoixpert/lib/fee-calculation/get-travel-expenses-total';
import { getWritingFeesTotal } from '@autoixpert/lib/fee-calculation/get-writing-fees-total';
import { Report } from '@autoixpert/models/reports/report';
import { calculateOtherFeesTotal } from './calculate-other-fees-total';

export function calculateTotalNetFees(report: Report): number {
    let total =
        report.feeCalculation.assessorsFee +
        getPhotosFeeTotal(report) +
        getTravelExpensesTotal(report) +
        getWritingFeesTotal(report) +
        getPostageAndPhoneFees(report) +
        calculateOtherFeesTotal(report);
    if (isNaN(total)) {
        total = 0;
    }
    return total;
}

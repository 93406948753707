<div class="dialog-overlay" (mousedown)="handleClickOutsideCards($event)" @dialogEnterAndLeave>
    <div class="dialog-container" (mousedown)="handleClickOutsideCards($event)">
        <!--============================================-->
        <!-- Chart Column -->
        <!--============================================-->
        <div id="left-column">
            <section id="chart-card" class="card dark-card card-with-padding">
                <!--********** Chart **********-->
                <div id="bar-chart">
                    <div id="bar-chart-background">
                        <!--********** Payments Bar **********-->
                        <div
                            id="bar-chart-fill-payments"
                            class="bar-chart-fill"
                            [style.height]="(paidAmount / invoice.totalGross) * 100 + '%'"
                            matTooltip="Bezahlt: {{ paidAmount | number: '1.2-2' }} € | {{
                                (paidAmount / invoice.totalGross) * 100 | number: '1.0-0'
                            }} %"></div>
                        <!--********** Short Payments Bar **********-->
                        <div
                            id="bar-chart-fill-short-payments"
                            class="bar-chart-fill"
                            [style.height]="(outstandingShortPaymentsSum / invoice.totalGross) * 100 + '%'"
                            matTooltip="Gekürzt: {{ outstandingShortPaymentsSum | number: '1.2-2' }} € | {{
                                (outstandingShortPaymentsSum / invoice.totalGross) * 100 | number: '1.0-0'
                            }} %"></div>

                        <!--********** Written-off Short Payments Bar **********-->
                        <div
                            id="bar-chart-fill-written-off"
                            class="bar-chart-fill"
                            [style.height]="(writtenOffShortPaymentsSum / invoice.totalGross) * 100 + '%'"
                            matTooltip="Abgeschriebene Kürzungen (nicht einholbar): {{
                                writtenOffShortPaymentsSum | number: '1.2-2'
                            }} € | {{
                                (writtenOffShortPaymentsSum / invoice.totalGross) * 100 | number: '1.0-0'
                            }} %"></div>

                        <!--********** Unpaid Bar **********-->
                        <div
                            id="bar-chart-fill-unpaid"
                            class="bar-chart-fill"
                            [style.height]="(unpaidAmount / invoice.totalGross) * 100 + '%'"
                            matTooltip="Noch offen: {{ unpaidAmount | number: '1.2-2' }} € | {{
                                (unpaidAmount / invoice.totalGross) * 100 | number: '1.0-0'
                            }} %"></div>

                        <!--********** Cancellations Bar **********-->
                        <div
                            id="bar-chart-fill-canceled"
                            class="bar-chart-fill"
                            [style.height]="(canceledAmount / invoice.totalGross) * 100 + '%'"
                            matTooltip="Gutgeschrieben: {{ canceledAmount | number: '1.2-2' }} € | {{
                                (canceledAmount / invoice.totalGross) * 100 | number: '1.0-0'
                            }} %"></div>
                    </div>
                    <div
                        id="bar-chart-label"
                        class="label"
                        matTooltip="{{
                            (sumOfEnteredPayments / invoice.totalGross) * 100 | number: '1.0-0'
                        }}% des ursprünglichen Rechnungsbetrags wurden in Zahlungen, Kürzungen oder Stornierungen erfasst.">
                        {{ (sumOfEnteredPayments / invoice.totalGross) * 100 | number: '1.0-0' }}%
                    </div>
                </div>
                <div id="reporting-numbers-container">
                    <!--********** Unpaid / Open amount **********-->
                    <div class="label">Offen</div>
                    <div
                        class="reporting-number"
                        id="unpaid-amount"
                        (click)="insertPaymentAmount('remainder')"
                        matTooltip="Offenen Betrag in die Zahlung einfügen"
                        [ngClass]="{
                            'partial-payment': unpaidAmount > 0,
                            'no-payment': unpaidAmount === invoice.totalGross,
                        }">
                        {{ unpaidAmount | number: '1.2-2' }} €
                    </div>

                    <!--********** Payments **********-->
                    <div class="label">Zahlungen</div>
                    <div class="reporting-number" matTooltip="Bezahlt: {{ paidAmount | number: '1.2-2' }} €">
                        {{ paidAmount | number: '1.2-2' }} €
                    </div>

                    <!--********** Short Payments **********-->
                    <ng-container *ngIf="nonPaidShortPayments">
                        <div class="label">Gekürzt</div>
                        <div
                            class="reporting-number"
                            matTooltip="Gekürzt: {{ nonPaidShortPayments | number: '1.2-2' }} € | {{
                                (nonPaidShortPayments / invoice.totalGross) * 100 | number: '1.0-0'
                            }} %">
                            {{ nonPaidShortPayments | number: '1.2-2' }} €
                        </div>
                    </ng-container>

                    <!--********** Cancellations **********-->
                    <div class="label">Gutschriften</div>
                    <div
                        id="reporting-number-canceled"
                        class="reporting-number"
                        matTooltip="Gutgeschrieben: {{ canceledAmount | number: '1.2-2' }} €{{
                            '\n\n'
                        }}Der Rechnungsbetrag wird um diesen Betrag gemindert.">
                        {{ canceledAmount | number: '1.2-2' }} €
                    </div>

                    <!--********** Invoice Total **********-->
                    <div class="label">Rechnungsbetrag</div>
                    <div
                        class="reporting-number"
                        id="invoice-total"
                        matTooltip="Rechnungsbetrag in die Zahlung einfügen"
                        (click)="insertPaymentAmount('gross')">
                        {{ invoice.totalGross | number: '1.2-2' }} €
                    </div>
                </div>
            </section>
        </div>
        <!--============================================-->
        <!-- END Chart Column -->
        <!--============================================-->

        <!--============================================-->
        <!-- Record Payments -->
        <!--============================================-->
        <div id="center-column">
            <section
                id="record-payments-card"
                class="card"
                [ngClass]="{ 'short-payment-selected': selectedPayment.type === 'shortPayment' }">
                <!--********** Card Toolbar **********-->
                <div class="card-toolbar">
                    <mat-icon class="toolbar-icon" [matMenuTriggerFor]="recordPaymentSubmenu">more_vert</mat-icon>
                    <mat-menu #recordPaymentSubmenu="matMenu">
                        <mat-option (click)="togglePaymentNotesShown()">
                            {{ userPreferences.paymentNotesShown ? 'Bemerkungen ausblenden' : 'Bemerkungen anzeigen' }}
                        </mat-option>
                    </mat-menu>
                    <!--<mat-icon class="toolbar-icon" (click)="closeDialog()" matTooltip="Dialog schließen">close</mat-icon>-->
                </div>

                <!-- Scroll container is necessary because the background icons and the invoice number shall not scroll together with the rest of the content.-->
                <div id="record-payments-scroll-container" class="card-with-padding">
                    <h2 *ngIf="!isPaymentInList(selectedPayment)">
                        {{ selectedPayment.type === 'payment' ? 'Zahlung erfassen' : 'Kürzung erfassen' }}
                    </h2>
                    <h2 *ngIf="isPaymentInList(selectedPayment)">
                        <ng-container *ngIf="selectedPayment.type === 'payment'">Zahlung bearbeiten</ng-container>
                        <ng-container *ngIf="selectedPayment.type === 'shortPayment'">Kürzung bearbeiten</ng-container>
                        <ng-container *ngIf="selectedPayment.type === 'partialCancellation'">
                            Teilgutschrift
                        </ng-container>
                    </h2>

                    <div id="payment-date-and-amount-container">
                        <currency-input
                            [(value)]="selectedPayment.amount"
                            (valueChange)="handleAmountChange(); saveInvoice()"
                            [placeholder]="selectedPayment.type === 'payment' ? 'Zahlung' : 'Betrag'"
                            [baseValue]="invoice.totalGross"
                            [warning]="amountFieldWarning"
                            [autofocus]="!selectedPayment.amount"
                            [disabled]="selectedPayment.type === 'partialCancellation'"></currency-input>
                        <date-input
                            [(date)]="selectedPayment.date"
                            (dateChange)="saveInvoice()"
                            placeholder="Datum"
                            [disabled]="selectedPayment.type === 'partialCancellation'"></date-input>
                    </div>

                    <!--********** Payer **********-->
                    <autocomplete-with-memory
                        *ngIf="selectedPayment.type === 'payment'"
                        autocompleteEntryType="paymentPayer"
                        [(ngModel)]="selectedPayment.payer"
                        placeholder="Zahler"></autocomplete-with-memory>

                    <!--********** Short Payment Reason **********-->
                    <autocomplete-with-memory
                        *ngIf="selectedPayment.type === 'shortPayment'"
                        autocompleteEntryType="shortPaymentReason"
                        [(ngModel)]="selectedPayment.reasonForShortPayment"
                        placeholder="Kürzungsgrund"
                        (change)="saveInvoice()"
                        matTooltip="Warum wurde die Rechnung gekürzt? Auswertbar in Auswertungen."></autocomplete-with-memory>

                    <!--********** Notes **********-->
                    <autocomplete-with-memory
                        *ngIf="userPreferences.paymentNotesShown || selectedPayment.notes"
                        autocompleteEntryType="paymentInternalNote"
                        [(ngModel)]="selectedPayment.notes"
                        placeholder="Interne Bemerkungen"
                        (change)="saveInvoice()"
                        [disabled]="selectedPayment.type === 'partialCancellation'"
                        matTooltip="Wird in Excel-Exporten ausgegeben (z. B. für den Steuerberater), steht aber nicht auf der Rechnung."></autocomplete-with-memory>

                    <!--============================================-->
                    <!-- Payment Amount Quick Action Buttons -->
                    <!--============================================-->
                    <div id="payment-amount-quick-buttons">
                        <!--********** Regular Payment only **********-->
                        <ng-container *ngIf="selectedPayment.type === 'payment'">
                            <!--********** Net Amount **********-->
                            <button
                                class="flat"
                                (click)="insertPaymentAmount('net')"
                                matTooltip="Netto-Rechnungsbetrag einfügen">
                                Netto
                            </button>

                            <!--********** VAT **********-->
                            <button
                                class="flat"
                                (click)="insertPaymentAmount('vat')"
                                matTooltip="Mehrwertsteuer einfügen">
                                MwSt.
                            </button>
                        </ng-container>

                        <!--********** Short Payment only **********-->
                        <ng-container *ngIf="selectedPayment.type === 'shortPayment'">
                            <!--********** Partial Guilt **********-->
                            <button
                                class="flat"
                                (click)="insertPaymentAmount(25)"
                                matTooltip="25% des Rechnungsbetrages einfügen, z. B. bei Teilschuld des Anspruchstellers">
                                25%
                            </button>
                            <button
                                class="flat"
                                (click)="insertPaymentAmount(50)"
                                matTooltip="50% des Rechnungsbetrages einfügen, z. B. bei Teilschuld des Anspruchstellers">
                                50%
                            </button>
                        </ng-container>

                        <!--********** Remainder **********-->
                        <button
                            class="flat"
                            *ngIf="selectedPayment.type !== 'partialCancellation'"
                            (click)="insertPaymentAmount('remainder')"
                            matTooltip="Offenen Betrag einfügen">
                            Rest
                        </button>
                    </div>
                    <!--============================================-->
                    <!-- END Payment Amount Quick Action Buttons -->
                    <!--============================================-->

                    <!--********** Short Payment Status **********-->
                    <div
                        id="short-payment-status-container"
                        *ngIf="selectedPayment.type === 'shortPayment'"
                        @slideInAndOutVertically>
                        <div class="selection-bar-label">Kürzungsstatus</div>
                        <div class="selection-bar">
                            <!--********** Outstanding claim (unpaid) **********-->
                            <div
                                class="selection-bar-item"
                                [ngClass]="{ selected: selectedPayment.shortPaymentStatus === 'outstandingClaim' }"
                                (click)="selectShortPaymentStatus('outstandingClaim'); saveInvoice()"
                                matTooltip="Offen{{
                                    '\n\n'
                                }}Die Kürzung ist noch ungeklärt, z. B. weil noch eine Stellungnahme geschrieben werden soll.{{
                                    '\n\n'
                                }}Auswertbar in Auswertungen und Rechnungsliste.">
                                <mat-icon class="selection-bar-item-icon">hourglass_top</mat-icon>
                            </div>
                            <!--********** Written off (will never be paid) **********-->
                            <div
                                class="selection-bar-item"
                                [ngClass]="{ selected: selectedPayment.shortPaymentStatus === 'writtenOff' }"
                                (click)="selectShortPaymentStatus('writtenOff'); saveInvoice()"
                                matTooltip="Abgeschrieben{{
                                    '\n\n'
                                }}Du hast die Kürzung akzeptiert und möchtest sie ausbuchen.{{
                                    '\n\n'
                                }}Auswertbar in Auswertungen und Rechnungsliste.">
                                <mat-icon class="selection-bar-item-icon">highlight_off</mat-icon>
                            </div>
                            <!--********** Paid **********-->
                            <div
                                class="selection-bar-item"
                                [ngClass]="{ selected: selectedPayment.shortPaymentStatus === 'paid' }"
                                (click)="selectShortPaymentStatus('paid'); saveInvoice()"
                                matTooltip="Bezahlt{{
                                    '\n\n'
                                }}Der gekürzte Betrag wurde schlussendlich doch noch bezahlt, z. B. weil du eine Stellungnahme abgegeben oder gar geklagt hast.{{
                                    '\n\n'
                                }}Auswertbar in Auswertungen und Rechnungsliste.">
                                <mat-icon class="selection-bar-item-icon">euro_symbol</mat-icon>
                            </div>
                        </div>
                    </div>

                    <section
                        id="short-payment-paid-section"
                        *ngIf="selectedPayment.shortPaymentStatus === 'paid'"
                        @slideInAndOutVertically>
                        <!--********** Who paid in the end? **********-->
                        <autocomplete-with-memory
                            autocompleteEntryType="shortPaymentPayer"
                            [(ngModel)]="selectedPayment.payer"
                            placeholder="Zahler"
                            (change)="saveInvoice()"
                            matTooltip="Wer hat die Nachzahlung vorgenommen?{{
                                '\n\n'
                            }}Diese Angabe kannst du später in den Auswertungen kumuliert einsehen."></autocomplete-with-memory>

                        <!--********** When was the short payment paid? **********-->
                        <autocomplete-with-memory
                            autocompleteEntryType="shortPaymentResolution"
                            [(ngModel)]="selectedPayment.shortPaymentResolution"
                            placeholder="Lösung/Grund"
                            (change)="saveInvoice()"
                            matTooltip="Was hat schlussendlich für die Nachzahlung gesorgt?{{
                                '\n\n'
                            }}Diese Angabe kannst du später in den Auswertungen kumuliert einsehen, um deine Erfolgschancen zu erhöhen, indem du die wirksamsten Methoden künftig immer anwendest."></autocomplete-with-memory>
                    </section>

                    <div id="payment-dialog-buttons-container">
                        <!--********** Add Payment **********-->
                        <button
                            id="add-payment-button"
                            *ngIf="!isPaymentInList(selectedPayment)"
                            (click)="addPaymentToList(selectedPayment)"
                            [matTooltip]="savingAllowed() ? '' : 'Bitte Belegdatum und Betrag angeben'">
                            Speichern
                        </button>

                        <!--********** Close Dialog **********-->
                        <button
                            id="close-dialog-button"
                            *ngIf="isPaymentInList(selectedPayment)"
                            (click)="closeDialog()"
                            matTooltip="Dialog schließen">
                            Schließen
                        </button>
                    </div>
                </div>
                <!--********** Invoice Number **********-->
                <div id="invoice-number" class="label" matTooltip="Rechnungsnummer">
                    {{ invoice.number }}
                </div>

                <!--============================================-->
                <!-- Type Selection Overlay -->
                <!--============================================-->

                <!--============================================-->
                <!-- END Type Selection Overlay -->
                <!--============================================-->

                <!--============================================-->
                <!-- Just-saved Overlay -->
                <!--============================================-->
                <div class="just-saved-overlay" *ngIf="justSavedOverlayVisible" @successMessageOverlay>
                    <mat-icon class="just-saved-success-message-icon">check</mat-icon>
                    <div class="just-saved-success-message">Gespeichert</div>
                </div>
                <!--============================================-->
                <!-- END Just-saved Overlay -->
                <!--============================================-->
            </section>
        </div>
        <!--============================================-->
        <!-- END Record Payments -->
        <!--============================================-->

        <!--============================================-->
        <!-- Payment List -->
        <!--============================================-->
        <div id="right-column">
            <section id="payment-list-card" class="card">
                <div id="no-payments-placeholder" *ngIf="invoice.payments.length === 0">Noch keine Zahlungen</div>
                <div id="payment-list" *ngIf="invoice.payments.length > 0">
                    <div
                        class="payment-list-item record"
                        *ngFor="let payment of invoice.payments"
                        [ngClass]="{ active: selectedPayment._id === payment._id }"
                        (click)="selectPayment(payment)">
                        <!--============================================-->
                        <!-- Icon -->
                        <!--============================================-->
                        <div class="payment-type-icon-container" [ngSwitch]="payment.type">
                            <!--********** Regular Payment **********-->
                            <mat-icon
                                class="payment-type-icon-regular-payment"
                                *ngSwitchCase="'payment'"
                                matTooltip="Zahlung">
                                euro_symbol
                            </mat-icon>

                            <!--********** Short Payments **********-->
                            <mat-icon
                                *ngSwitchCase="'shortPayment'"
                                class="payment-type-icon-short-payment short-payment-icon-rotated"
                                matTooltip="Kürzung">
                                content_cut
                            </mat-icon>

                            <!--********** Partial Cancellations **********-->
                            <mat-icon
                                class="payment-type-icon-partial-cancellation outlined"
                                *ngSwitchCase="'partialCancellation'"
                                matTooltip="Ein Teilbetrag wurde durch eine Gutschrift ausgebucht">
                                remove_circle
                            </mat-icon>

                            <!--********** Payment Created by Bank Account Sync **********-->
                            <div
                                class="secondary-payment-info-icon-container"
                                *ngIf="payment.associatedBankTransactionId">
                                <mat-icon
                                    class="bank-account-sync-icon"
                                    [matTooltip]="getBankAccountSyncIconTooltip(payment)">
                                    swap_horiz
                                </mat-icon>
                            </div>

                            <!--********** Short Payment Outstanding **********-->
                            <div
                                class="secondary-payment-info-icon-container"
                                *ngIf="payment.shortPaymentStatus === 'outstandingClaim'">
                                <mat-icon
                                    class="bank-account-sync-icon"
                                    matTooltip="Diese Kürzung ist noch nicht final geklärt.">
                                    hourglass_top
                                </mat-icon>
                            </div>
                            <!--********** Short Payment Written Off **********-->
                            <div
                                class="secondary-payment-info-icon-container"
                                *ngIf="payment.shortPaymentStatus === 'writtenOff'">
                                <mat-icon
                                    class="bank-account-sync-icon"
                                    matTooltip="Diese Kürzung wurde als uneinbringbar akzeptiert. Diese Kürzung wird nicht weiter verfolgt.">
                                    highlight_off
                                </mat-icon>
                            </div>
                            <!--********** Short Payment Paid **********-->
                            <div
                                class="secondary-payment-info-icon-container"
                                *ngIf="payment.shortPaymentStatus === 'paid'">
                                <mat-icon
                                    class="bank-account-sync-icon"
                                    matTooltip="Diese Kürzung wurde schlussendlich doch noch bezahlt.">
                                    euro_symbol
                                </mat-icon>
                            </div>
                        </div>
                        <!--============================================-->
                        <!-- END Icon -->
                        <!--============================================-->

                        <!--============================================-->
                        <!-- Title & Notes -->
                        <!--============================================-->
                        <div class="payment-details-container">
                            <div class="payment-title">{{ getPaymentTitle(payment) }}</div>
                            <div class="payment-notes" *ngIf="payment.notes" [quillContent]="payment.notes"></div>

                            <!--********** Payment Date & Payer **********-->
                            <div class="payment-date-and-payer label">
                                <!--********** Date **********-->
                                <span
                                    class="payment-date"
                                    matTooltip="Zahlung vom {{ payment.date | moment: 'dddd, DD.MM.YYYY' }} {{
                                        '\n\n'
                                    }} Erfasst von {{
                                        payment.createdBy ? getFullUserName(payment.createdBy) : 'Unbekannt'
                                    }} am {{ payment.createdAt | moment }}">
                                    {{ payment.date | moment }}
                                </span>

                                <!--********** Divider **********-->
                                <span *ngIf="payment.date && payment.payer">|</span>

                                <!--********** Payer **********-->
                                <span class="payer" matTooltip="Zahler">&nbsp;{{ payment.payer }}</span>
                            </div>

                            <!--********** Create cancellation invoice **********-->
                            <!-- If a short payment has been written off, we offer to create a partial credit note. -->
                            <button
                                class="create-cancellation-invoice-button glass"
                                *ngIf="
                                    payment.type === 'shortPayment' &&
                                    payment.shortPaymentStatus === 'writtenOff' &&
                                    !payment.cancellationInvoiceId
                                "
                                (click)="createCancellationInvoiceForShortPayment(payment); $event.stopPropagation()"
                                matTooltip="Für diese abgeschriebene Kürzung kannst du eine Teilgutschrift erzeugen, damit deine Buchhaltung einen Beleg dafür hat, dass du das Geld nicht mehr bekommen wirst.{{
                                    '\n\n'
                                }}Beim DATEV-Export wird der Beleg automatisch exportiert.">
                                <div
                                    class="button-overlay"
                                    *ngIf="cancellationInvoiceCreationPending.has(payment._id)"
                                    @fadeInAndOut>
                                    <img class="pending-icon" src="/assets/images/icons/loading-indicator.svg" />
                                </div>
                                Teilgutschrift erstellen
                            </button>

                            <!--********** Link to cancellation invoice **********-->
                            <div
                                class="link-to-cancellation-invoice display-flex flex-align-center"
                                *ngIf="payment.cancellationInvoiceId">
                                <!--********** Invoice Exists **********-->
                                <div
                                    class="link"
                                    *ngIf="cancellationInvoices.get(payment.cancellationInvoiceId)"
                                    matTooltip="Für diesen Eintrag wurde eine Gutschrift erstellt. Klicken, um sie im neuen Tab zu öffnen."
                                    (click)="openInvoiceInNewTab(payment.cancellationInvoiceId)">
                                    {{ cancellationInvoices.get(payment.cancellationInvoiceId)?.number }}
                                </div>
                                <!--********** Invoice Not Found **********-->
                                <span
                                    class="text-warning"
                                    *ngIf="!cancellationInvoices.get(payment.cancellationInvoiceId)"
                                    matTooltip="Die verlinkte Rechnung wurde nicht gefunden. Womöglich wurde sie gelöscht oder dir fehlt das Zugriffsrecht darauf.">
                                    Rechnung fehlt
                                </span>

                                <!--********** Disconnect Invoice **********-->
                                <mat-icon
                                    class="small-icon toolbar-icon"
                                    *ngIf="payment.type !== 'partialCancellation'"
                                    (click)="
                                        disconnectCancellationInvoiceFromShortPayment(payment); $event.stopPropagation()
                                    "
                                    matTooltip="Rechnung von Kürzung trennen. Die Rechnung bleibt trotzdem in der Rechnungsliste erhalten.">
                                    close
                                </mat-icon>
                            </div>
                        </div>
                        <!--============================================-->
                        <!-- END Title & Notes -->
                        <!--============================================-->

                        <!--============================================-->
                        <!-- Amount -->
                        <!--============================================-->
                        <div class="payment-amount">
                            <ng-container *ngIf="payment.amount">{{ payment.amount | number: '1.2-2' }} €</ng-container>
                            <ng-container *ngIf="!payment.amount">- €</ng-container>
                        </div>
                        <!--============================================-->
                        <!-- END Amount -->
                        <!--============================================-->

                        <!--============================================-->
                        <!-- Toolbar -->
                        <!--============================================-->
                        <div class="payment-toolbar">
                            <!--********** Submenu **********-->
                            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="paymentSubmenu">more_vert</mat-icon>
                            <mat-menu #paymentSubmenu>
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon alert"
                                    (click)="deletePayment(payment)"
                                    [disabled]="selectedPayment.type === 'partialCancellation'">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </div>
                        <!--============================================-->
                        <!-- END Toolbar -->
                        <!--============================================-->
                    </div>

                    <!--============================================-->
                    <!-- New Payment in Edit Mode -->
                    <!--============================================-->
                    <!--********** To make the UI feel more natural, we display a record symbolizing the record in edit mode that has not yet been added to the payments array. **********-->
                    <div
                        id="new-payment-in-edit-mode-placeholder"
                        class="payment-list-item record active"
                        *ngIf="!isPaymentInList(selectedPayment)"
                        matTooltip="Speichere den Datensatz links, damit er hier eingefügt wird.">
                        <div class="payment-type-icon-container">
                            <mat-icon>edit</mat-icon>
                        </div>
                        <div class="payment-details-container semibold">
                            {{ selectedPayment.type === 'payment' ? 'Neue Zahlung' : 'Neue Kürzung' }}
                        </div>
                        <div class="payment-amount">- €</div>
                    </div>
                    <!--============================================-->
                    <!-- END New Payment in Edit Mode -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- VAT Letter Note -->
                    <!--============================================-->
                    <!--********** Only display if exactly the VAT amount is unpaid, if there *is* VAT on this invoice (not the case for small entrepreneurs [German "Kleinunternehmer"]) and the invoice doesn't have a VAT letter yet. **********-->
                    <div
                        id="vat-letter-note"
                        *ngIf="paidAmount === invoice.totalNet && invoice.vatRate !== 0 && !invoiceHasVatLetter()"
                        @fadeInAndOut>
                        <div class="info-note">
                            <mat-icon class="medium-icon">description</mat-icon>
                            <div>
                                <h3>Mehrwertsteuer fehlt</h3>
                                <p>Fordere den Rechnungsempfänger auf, die Mehrwertsteuer auszugleichen.</p>
                                <button (click)="createVatLetter()">
                                    <mat-icon class="button-icon">add</mat-icon>
                                    MwSt-Anschreiben
                                </button>
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END VAT letter -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Warning: Only Short Payments Recorded -->
                    <!--============================================-->
                    <div
                        id="only-short-payments-warning"
                        *ngIf="paidAmount === 0 && nonPaidShortPayments > 0"
                        @fadeInAndOut>
                        <div class="info-note warning-note">
                            <mat-icon class="warning medium-icon">warning</mat-icon>
                            <div>
                                <h3>Nur Kürzungen erfasst</h3>
                                Wurden dir wirklich {{ nonPaidShortPayments | number: '1.2-2' }} € ({{
                                    (nonPaidShortPayments / invoice.totalGross) * 100 | number: '1.0-0'
                                }}%) gekürzt?
                                <br />
                                <br />
                                Erfasse eine Zahlung für den bezahlten und eine Zahlung für den gekürzten
                                Differenzbetrag.
                                <br />
                                <br />
                                <div class="link" (click)="convertShortPaymentsToRegularPayments()">
                                    {{ nonPaidShortPayments | number: '1.2-2' }} € wurden mir überwiesen
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Warning: Only Short Payments Recorded -->
                    <!--============================================-->
                </div>

                <div class="flex-center-content padding-10">
                    <button class="flat" (click)="loadNewPaymentIntoEditMode('payment')">+ Zahlung</button>
                    <button class="flat" (click)="loadNewPaymentIntoEditMode('shortPayment')">+ Kürzung</button>
                </div>
            </section>
        </div>
        <!--============================================-->
        <!-- END Payment List -->
        <!--============================================-->
    </div>
</div>

import { captureException } from '@sentry/angular';
import { DateTime, DurationLike } from 'luxon';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { OfflineSyncBlobServiceBase } from '../database/offline-sync-blob-service.base';
import { OfflineSyncServiceBase } from '../database/offline-sync-service.base';

/**
 * App-wide service allowing the app to free disk space in case the client is running out of it.
 *
 * It clears old data in multiple time steps: older than 4 weeks, older than 2 weeks etc.
 */
export class StorageSpaceManager {
    /**
     * Remove data which was last accessed before the given durations in the past from now.
     */
    static timeThresholdDurations: DurationLike[] = [{ weeks: 4 }, { weeks: 2 }, { week: 1 }, { days: 3 }];

    static services = new Map<StorageService['serviceName'], StorageService>();

    static async checkNeedForDataEjection({
        timeThresholdIndex = 0,
        error,
    }: { timeThresholdIndex?: number; error?: Error } = {}) {
        const { quota, usage } = (await navigator.storage.estimate?.()) ?? {}; // Safari doesn't support estimate (as of v15.4)
        if (!usage || !quota) {
            console.warn(
                'This browser does not support estimating storage usage and storage quota. It cannot be determined if removing old objects is necessary.',
            );
            return;
        }

        const usagePercent = Math.round((usage / quota) * 100);
        console.log(
            `Detected a storage usage of ${usagePercent} %. Max quota lies around ${Math.round(quota / 1_000_000)} MB.`,
        );

        // Handle QuotaExceededErrors -> Eject old data.
        const isQuotaExceeded = error?.name === 'QuotaExceededError';

        // Log QuotaExceededErrors to Sentry, so that we know that and how often they're happening.
        if (isQuotaExceeded) {
            captureException(error);
        }

        if (usagePercent > 90 || isQuotaExceeded) {
            const timeThresholdDuration = StorageSpaceManager.timeThresholdDurations[timeThresholdIndex];
            if (timeThresholdDuration) {
                await StorageSpaceManager.ejectData(DateTime.now().minus(timeThresholdDuration));
                await StorageSpaceManager.checkNeedForDataEjection({ timeThresholdIndex: timeThresholdIndex + 1 });
            } else {
                /**
                 * No duration was found, so eject everything. There is no need to trigger clean up again afterwards since removing all data is the best we can do.
                 * Ejecting all data could only fail if the data ejectors of the individual data stores are falsely implemented.
                 */
                await StorageSpaceManager.ejectData();
            }
        }
    }

    /**
     * Eject data that was accessed before the given date time. If no date time is specified, remove all data except records that still need to be synced
     * to the server.
     * This function is called if the user gets close to or hits a storage limit on his device. Ejecting old data keeps the app operational.
     */
    static async ejectData(lastAccessedBefore?: DateTime) {
        for (const [databaseServiceName, databaseService] of StorageSpaceManager.services) {
            try {
                await databaseService.ejectData(lastAccessedBefore);
            } catch (error) {
                console.error(
                    `Ejecting data failed within the data manager for service "${databaseServiceName}". This is a technical issue.`,
                    { error },
                );
            }
        }

        return true;
    }

    /**
     * This is called during logout. If the user has unsynced local changes, an error is thrown instead of deleting the
     */
    static async clearData(forceRemoveUnsyncedChanges?: boolean) {
        /**
         * Skip the check for unsynced changes if the user explicitly wants to remove all records.
         */
        if (!forceRemoveUnsyncedChanges) {
            // Check all services for remaining records in parallel.
            await Promise.all(
                [...StorageSpaceManager.services].map(
                    async ([databaseServiceName, databaseService]: [StorageService['serviceName'], StorageService]) => {
                        let hasOutstandingSyncs: boolean;
                        try {
                            hasOutstandingSyncs = await databaseService.hasOutstandingSyncs();
                        } catch (error) {
                            console.error(
                                'Error determining outstanding syncs. It is safter to consider changes to be present so the user may decide what to do.',
                                { error },
                            );
                            hasOutstandingSyncs = true;
                        }

                        if (hasOutstandingSyncs) {
                            throw new AxError({
                                code: 'UNSYNCED_RECORDS_EXIST',
                                message: `There are unsynced changes that would be removed by ejecting all data. Please specify the forceRemoveUnsyncedChanges parameter if you intend to remove unsynced changes, too.`,
                                data: {
                                    databaseServiceName,
                                },
                            });
                        }
                    },
                ),
            );
        }

        await Promise.all(
            [...StorageSpaceManager.services].map(
                async ([databaseServiceName, databaseService]: [StorageService['serviceName'], StorageService]) => {
                    // Remove all data when the user logs out.
                    try {
                        return await databaseService.clearDatabase();
                    } catch (error) {
                        console.error(
                            `Cleaning data failed within the data manager for service "${databaseServiceName}". This is a technical issue.`,
                            { error },
                        );
                        throw error;
                    }
                },
            ),
        );

        return true;
    }

    static registerService(service: StorageService) {
        StorageSpaceManager.services.set(service.serviceName, service);
    }

    static async getDeviceStorageStatistics(): Promise<DeviceStorageStatistics> {
        const { quota, usage } = (await navigator.storage.estimate?.()) ?? {}; // Safari doesn't support estimate (as of v15.4)

        return {
            deviceLimitBytes: quota,
            storageUsageBytes: usage,
            services: Array.from(StorageSpaceManager.services.values()),
        };
    }
}

export interface DeviceStorageStatistics {
    deviceLimitBytes: number;
    storageUsageBytes: number;

    /**
     * Use the services array to get the database size through service.getDatabaseSize(). That's a promise-based API that allows
     * the service's database sizes to be computed in parallel when the component wants to list all services by size.
     */
    services: StorageService[];
}

export type StorageService = OfflineSyncServiceBase<any> | OfflineSyncBlobServiceBase;

//// For debugging only.
//(window as any).StorageSpaceManager = StorageSpaceManager;

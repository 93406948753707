import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Injector,
    Input,
    OnDestroy,
    Output,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormFieldDataType } from '@autoixpert/helper-types/form-field-data-type';
import { FormFieldOverlayComponent } from './form-field-overlay.component';

/**
 * Anchor that opens an overlay with an arbitrary input field type.
 *
 * Currently supported:
 * - text input
 * - number input
 * - currency input
 */
@Directive({
    selector: '[form-field-overlay-anchor]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormFieldOverlayAnchorDirective),
            multi: true,
        },
    ],
})
export class FormFieldOverlayAnchorDirective<FormFieldType extends 'text' | 'number' | 'currency'>
    implements ControlValueAccessor, OnDestroy
{
    constructor(
        private elementRef: ElementRef,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    private value: FormFieldDataType<FormFieldType>;
    @Input() formFieldType: FormFieldType;
    @Input('form-field-overlay-placeholder') formFieldOverlayPlaceholder: string;
    @Input() formFieldSuffix: string;

    // Number input
    @Input() minimumFractionDigits: number = 0;
    @Input() maximumFractionDigits: number = 2;

    @Output() change: EventEmitter<FormFieldDataType<FormFieldType>> = new EventEmitter();

    private onChange: (value: FormFieldDataType<FormFieldType>) => void;
    private onTouched: () => void;

    private overlayRef: OverlayRef;

    private subscriptions: Subscription[] = [];

    //*****************************************************************************
    //  Anchor Click Handler
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public openEditDialog() {
        // Avoid duplicate panels.
        if (this.overlayRef) return;

        // Configure overlay
        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                ])
                .withPush(false) // withPush being true (default) causes any overlay to grow up to 100% of the viewport, overflowing to the bottom.
                .withViewportMargin(20),
            scrollStrategy: this.overlayService.scrollStrategies.reposition(),
        });

        // Close panel when clicking the backdrop.
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });

        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<FormFieldOverlayComponent<FormFieldType>>(
            FormFieldOverlayComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.overlayRef,
                    },
                ],
            }),
        );

        const componentRef = this.overlayRef.attach(componentPortal);
        componentRef.instance.value = this.value;
        componentRef.instance.formFieldType = this.formFieldType;
        componentRef.instance.placeholder = this.formFieldOverlayPlaceholder;
        componentRef.instance.suffix = this.formFieldSuffix;
        componentRef.instance.minimumFractionDigits = this.minimumFractionDigits;
        componentRef.instance.maximumFractionDigits = this.maximumFractionDigits;

        const subscription_valueChange = componentRef.instance.valueChange.subscribe(
            (value: FormFieldDataType<FormFieldType>) => {
                this.value = value;
                this.onChange(value);
                this.change.emit(value);
            },
        );

        this.subscriptions.push(subscription_valueChange);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Click Handler
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    // ControlValueAccessor Implementation
    //****************************************************************************/
    writeValue(value: FormFieldDataType<FormFieldType>): void {
        this.value = value;
    }

    registerOnChange(fn: (value: FormFieldDataType<FormFieldType>) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    // ControlValueAccessor END
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.overlayRef?.detach();

        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

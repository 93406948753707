import { DateTime } from 'luxon';
import { DateTimeFormatOptions } from 'luxon/src/misc';

/**
 * Luxon's default is the Internationalization API's default. That prints 1.1.2025 instead of 01.01.2025.
 * Since autoiXpert usually wants the more common 01.01.2025, we define our own format here.
 *
 * Source: https://github.com/moment/luxon/issues/828
 */
// DD.MM.YYYY, e.g. 15.02.2034
export const GERMAN_DATE_FORMAT: DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
// MM.YYYY, e.g. 02.2034
export const GERMAN_MONTH_AND_YEAR_FORMAT: DateTimeFormatOptions = { year: 'numeric', month: '2-digit' };
// HH:ii, e.g. 15:38
export const GERMAN_TIME_FORMAT: DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };

export function makeLuxonDateTime(date: string | DateTime | Date): DateTime {
    let dateTime: DateTime;
    if (date instanceof DateTime) {
        dateTime = date;
    } else if (date instanceof Date) {
        dateTime = DateTime.fromJSDate(date);
    } else {
        dateTime = DateTime.fromISO(date);
    }

    return dateTime;
}

export function toGermanDate(date: string | DateTime | Date): string {
    return makeLuxonDateTime(date).toLocaleString(GERMAN_DATE_FORMAT);
}

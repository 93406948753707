<div id="report-type-container" class="card">
    <div class="report-type-background-container">
        <div class="report-type-item-container" [ngClass]="{ selected: itemClicked === true }">
            <!--============================================-->
            <!-- Damage Reports -->
            <!--============================================-->
            <h3 class="h3-covered">Schadengutachten</h3>

            <div class="report-type-item-row">
                <!--********** Liability **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'liability' }"
                    (click)="setReportType('liability')"
                    matTooltip="Der Klassiker: Zwei Fahrzeuge hatten einen Unfall. Herzstück des Unfallgutachtens ist die Schadenkalkulation.

            Dokumentiere alle am Unfall beteiligten Parteien, den Zustand des Fahrzeuges vor und nach dem Unfall und berechne den nötigen Aufwand für die Reparatur.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'liability'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img class="report-type-icon" src="/assets/images/icons/liability_96.png" alt="liability" />
                    <div class="report-type-label">{{ translateReportType('liability') }}</div>
                </div>

                <!--********** Short Assessment **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'shortAssessment' }"
                    (click)="setReportType('shortAssessment')"
                    matTooltip="Gutachten bei Bagatellschäden u. ä.

                       Du kannst wählen, ob die Überschrift Kurzgutachten oder Kostenvoranschlag verwendet werden soll.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'shortAssessment'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img class="report-type-icon" src="/assets/images/icons/clock_96.png" alt="shortAssessment" />
                    <div class="report-type-label">{{ translateReportType('shortAssessment') }}</div>
                </div>

                <!--********** Partial Kasko **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'partialKasko' }"
                    (click)="setReportType('partialKasko')"
                    matTooltip="Gutachten für Fahrzeuge, die durch äußere Einflüsse beschädigt wurden, aber nicht durch ein anderes Fahrzeug.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'partialKasko'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img
                        class="report-type-icon"
                        src="/assets/images/icons/cloud-lightning_96.png"
                        alt="partialKasko" />
                    <div class="report-type-label">{{ translateReportType('partialKasko') }}</div>
                </div>

                <!--********** Full Kasko **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'fullKasko' }"
                    (click)="setReportType('fullKasko')"
                    matTooltip="Gutachten für Fahrzeuge, die selbstverschuldet beschädigt wurden.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'fullKasko'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img class="report-type-icon" src="/assets/images/icons/shield_96.png" alt="fullKasko" />
                    <div class="report-type-label">{{ translateReportType('fullKasko') }}</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Damage Reports -->
            <!--============================================-->

            <!--============================================-->
            <!-- Valuation Reports -->
            <!--============================================-->
            <h3>Wertgutachten</h3>
            <div class="report-type-item-row">
                <!--********** Valuation **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'valuation' }"
                    (click)="setReportType('valuation')"
                    matTooltip="Die Bewertung ermittelt den Marktwert eines Autos. Dabei nimmt der Sachverständige das Fahrzeug genau unter die Lupe, um den Zustand wertrelevanter Teile und eventuelle Mängel zu beurteilen.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'valuation'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img class="report-type-icon" src="/assets/images/icons/euro-symbol_96.png" alt="valuation" />
                    <div class="report-type-label">{{ translateReportType('valuation') }}</div>
                </div>

                <!--********** Classic Cars (Oldtimer) Valuation Small **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'oldtimerValuationSmall' }"
                    (click)="setReportType('oldtimerValuationSmall')"
                    matTooltip="Das kleine Wertgutachten wird von Oldtimer-Besitzern benötigt, um gegenüber der Versicherung den Wert ihres Fahrzeuges zu beweisen. Mit der DOCX-Datei des kleinen Wertgutachtens kann man heute schon das große Wertgutachten erstellen.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'oldtimerValuationSmall'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img
                        class="report-type-icon"
                        src="/assets/images/icons/award_96.png"
                        alt="oldtimerValuationSmall" />
                    <div class="report-type-label">{{ translateReportType('oldtimerValuationSmall') }}</div>
                </div>

                <!--********** Lease Return **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'leaseReturn' }"
                    (click)="setReportType('leaseReturn')"
                    matTooltip="Zustandsbericht, auch genannt Leasingrückläufer-, Rücknahme- oder Minderwertgutachten. Du kannst dir Prüfleitfäden anlegen, um möglichst alle Bereiche des Fahrzeugs zu prüfen, um den Minderwert durch Schäden zu ermitteln.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'leaseReturn'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img class="report-type-icon" src="/assets/images/icons/percent_96.png" alt="leaseReturn" />
                    <div class="report-type-label">Zustands&shy;bericht</div>
                </div>

                <!--********** Used Vehicle Check **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'usedVehicleCheck' }"
                    (click)="setReportType('usedVehicleCheck')"
                    matTooltip="Anders als im Zustandsbericht wird nicht der Minderwert, sondern der aktuelle Fahrzeugwert ausgewiesen.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'usedVehicleCheck'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img class="report-type-icon" src="/assets/images/icons/check_circle.png" alt="usedVehicleCheck" />
                    <div class="report-type-label">Gebraucht&shy;wagencheck</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Valuation Reports -->
            <!--============================================-->

            <!--============================================-->
            <!-- Extras / Other Document Types -->
            <!--============================================-->
            <h3>Extras</h3>
            <div class="report-type-item-row">
                <!--********** Invoice Audit **********-->
                <div
                    class="report-type-item"
                    [ngClass]="{ selected: selectedType === 'invoiceAudit' }"
                    (click)="setReportType('invoiceAudit')"
                    matTooltip="Überprüfe auf Bitte der Versicherung, inwiefern die Rechnung einer Werkstatt zu einem Gutachten passt.">
                    <div class="large-selection-box-active-marker" *ngIf="selectedType === 'invoiceAudit'">
                        <mat-icon>check</mat-icon>
                    </div>
                    <img class="report-type-icon" src="/assets/images/icons/euro-symbol_96.png" alt="invoiceAudit" />
                    <div class="report-type-label">Rechnungs&shy;prüfung</div>
                </div>

                <!--********** Repair Confirmation **********-->
                <!-- Only displayed if this dialog is used for creation, as opposed to changing the type. -->
                <div
                    class="report-type-item"
                    *ngIf="!report"
                    (click)="openDialogForRepairConfirmation()"
                    matTooltip="Bestätigung, dass ein Fahrzeug in Eigenleistung (nicht in einer Werkstatt gegen Rechnung) instandgesetzt wurde.">
                    <img
                        class="report-type-icon"
                        src="/assets/images/icons/repair-confirmation-gradient-blue.png"
                        alt="Reparaturbestätigung" />
                    <div class="report-type-label">Reparatur&shy;bestätigung</div>
                </div>

                <!--********** Invoice Audit **********-->
                <div
                    class="report-type-item"
                    *ngIf="!report"
                    (click)="openDialogForNewInvoice()"
                    matTooltip="Rechnung unabhängig von einem Gutachten anlegen.">
                    <img class="report-type-icon" src="/assets/images/icons/euro-symbol_96.png" alt="Freie Rechnung" />
                    <div class="report-type-label">Freie Rechnung</div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Extras / Other Document Types -->
            <!--============================================-->
        </div>
    </div>

    <!--============================================-->
    <!-- Accept Changes Notification -->
    <!--============================================-->
    <div
        id="report-type-change-notification"
        *ngIf="useCase === 'reportTypeChange' && selectedType !== report.type"
        @fadeInAndSlideChangeNotification>
        <div id="report-type-change-notification-text">
            Beim Wechsel des Gutachtentyps werden überflüssige Daten (z. B. Restwertinserate, Marktwertanalysen)
            entfernt.
        </div>
        <button id="report-change-button" (click)="emitReportTypeChange()">Ändern</button>
    </div>
    <!--============================================-->
    <!-- END Accept Changes Notification -->
    <!--============================================-->
</div>

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { SyncIssueNotificationService } from '../../shared/services/sync-issue-notification.service';
import { SyncIssueListPanelComponent } from '../sync-issue-list-panel/sync-issue-list-panel.component';

@Component({
    selector: 'sync-issue-indicator',
    templateUrl: 'sync-issue-indicator.component.html',
    styleUrls: ['sync-issue-indicator.component.scss'],
})
export class SyncIssueIndicatorComponent {
    constructor(
        private syncIssueNotificationService: SyncIssueNotificationService,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @ViewChild('syncIssueIndicator') syncIssueIndicator: ElementRef<HTMLDivElement>;

    ngOnInit() {}

    //*****************************************************************************
    //  Sync Issue Notifications
    //****************************************************************************/
    public hasSyncIssueNotifications(): boolean {
        return this.syncIssueNotificationService.hasNotifications();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sync Issue Notifications
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sync Issue List (Panel)
    //****************************************************************************/
    public openSyncIssueList(): void {
        const overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.syncIssueIndicator)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                ])
                .withPush(true),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        overlayRef.backdropClick().subscribe(() => overlayRef.detach());

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
            ],
        });

        overlayRef.attach(new ComponentPortal(SyncIssueListPanelComponent, null, overlayRefInjector));
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sync Issue List (Panel)
    /////////////////////////////////////////////////////////////////////////////*/
}

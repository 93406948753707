<div id="safety-test-container">
    <div id="safety-test-date-input-container">
        <month-input
            placeholder="Nächste SP"
            [(date)]="_nextSafetyTest"
            (dateChange)="updateInspectionSticker($event); emitDateChange($event)"
            (focus)="emitFocusEvent($event)"
            matTooltip="Nächste Sicherheitsprüfung. Datumsformat 01.2020 oder Jan 2020"
            [disabled]="disabled"
            [autofocus]="autofocus"
            [hint]="hint"
            [warning]="warning"></month-input>
        <div class="safety-test-warning-container" *ngIf="inspectionDateIsInThePast">
            <span class="label">überfällig</span>
            <mat-icon class="safety-test-warning-icon" [matTooltip]="inspectionDateOverdueTooltip">warning</mat-icon>
        </div>
        <div class="safety-test-warning-container" *ngIf="inspectionDateIsFarInTheFuture">
            <span class="label">weit in Zukunft</span>
            <mat-icon
                class="safety-test-warning-icon"
                matTooltip="Die SP liegt mehr als 24 Monate in der Zukunft. Ist das Datum korrekt?">
                warning
            </mat-icon>
        </div>
    </div>
    <div id="inspection-sticker-container">
        <img id="inspection-sticker" src="/assets/images/icons/safety-test-sticker.png" />
        <!--============================================-->
        <!-- Pointer -->
        <!--============================================-->
        <div
            id="inspection-sticker-pointer"
            [style.transform]="'translate3d(-50%, calc(-50% - 3px), 0) rotate(' + inspectionStickerRotation + 'deg)'">
            <svg xmlns="http://www.w3.org/2000/svg" width="23.219" height="33.063" viewBox="0 0 23.219 33.063">
                <defs>
                    <style>
                        .cls-1 {
                            fill: #fff;
                            stroke: #000;
                            stroke-linejoin: round;
                            stroke-width: 1px;
                            fill-rule: evenodd;
                        }
                    </style>
                </defs>
                <path
                    class="cls-1"
                    d="M3.865,11.2L15,0.986,25.985,11.1l0.106,21.87-22.12.106Z"
                    transform="translate(-3.375 -0.5)"
                    [style.fill]="inspectionStickerColor" />
            </svg>
            <div id="inspection-sticker-year" *ngIf="getInspectionYear()">
                SP
                <br />
                {{ getInspectionYear() }}
            </div>
        </div>
        <!--============================================-->
        <!-- END Pointer -->
        <!--============================================-->
    </div>
</div>

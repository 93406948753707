<div id="login-card">
    <form *ngIf="!passwordResetShown">
        <!-- Header -->
        <div id="login-card-head-container">
            <h1 class="login-card-container-headline">Login</h1>
            <!-- TODO Implement adding others users to the quick login. Maybe add the user to the quick login automatically if he logged in before. -->
            <!--<div id="login-card-container-plus"-->
            <!--(click)="addUserOnThisPC()">+-->
            <!--</div>-->
        </div>

        <!-- Either known user or email field -->
        <div id="login-card-user-container">
            <div id="login-card-unknown-user-container">
                <mat-form-field>
                    <input
                        matInput
                        type="text"
                        placeholder="E-Mail"
                        [(ngModel)]="usernameOrEmail"
                        (ngModelChange)="resetErrorMessages()"
                        (change)="trimUsernameOrEmail()"
                        (input)="convertUsernameToLowerCase()"
                        autocomplete="username"
                        name="usernameOrEmail"
                        #usernameOrEmailField="ngModel"
                        [axAutofocus]="true"
                        required />
                </mat-form-field>
                <div class="error-container" *ngIf="errorFeedback.missingUsername">
                    <div
                        class="alert alert-danger"
                        *ngIf="
                            usernameOrEmailField.dirty && (!usernameOrEmailField.valid || errorFeedback.missingUsername)
                        ">
                        Die E-Mail darf nicht leer sein.
                    </div>
                </div>
            </div>
        </div>

        <!-- Password -->
        <div id="login-card-password-container">
            <mat-form-field>
                <input
                    matInput
                    [type]="passwordVisible ? 'text' : 'password'"
                    placeholder="Passwort"
                    [(ngModel)]="password"
                    (ngModelChange)="resetErrorMessages()"
                    (change)="trimPassword()"
                    autocomplete="current-password"
                    name="password"
                    #passwordField="ngModel"
                    required />
                <mat-icon
                    class="password-show-toggle small-icon"
                    (click)="togglePasswordVisibility()"
                    [ngClass]="{ 'password-visible': passwordVisible }">
                    remove_red_eye
                </mat-icon>
            </mat-form-field>
            <div class="error-container">
                <div
                    class="alert alert-danger"
                    *ngIf="passwordField.dirty && (!passwordField.valid || errorFeedback.missingPassword)">
                    Passwort darf nicht leer sein.
                </div>
                <div class="alert alert-danger" *ngIf="errorFeedback.wrongCredentials">
                    Benutzername oder Passwort inkorrekt.
                </div>
                <div class="alert alert-danger" *ngIf="errorFeedback.regularAccountExpired">
                    Dein Account ist am {{ errorFeedback.regularAccountExpired | moment }} ausgelaufen. Kontaktiere den
                    <a href="mailto:vertrieb@autoixpert.de" target="_blank" rel="”noopener”">aX-Vertrieb</a>
                    .
                </div>
                <div class="alert alert-danger" *ngIf="errorFeedback.testAccountExpired">
                    Dein Test-Account ist am {{ errorFeedback.testAccountExpired | moment }} ausgelaufen. Kontaktiere
                    den
                    <a href="mailto:vertrieb@autoixpert.de" target="_blank" rel="”noopener”">aX-Vertrieb</a>
                    .
                </div>
                <div class="alert alert-danger" *ngIf="errorFeedback.becameCustomerAtInTheFuture">
                    Dein Account wird am {{ errorFeedback.becameCustomerAtInTheFuture | moment }} aktiviert. Du brauchst
                    ihn doch früher? Kontaktiere den
                    <a href="mailto:vertrieb@autoixpert.de" target="_blank" rel="”noopener”">aX-Vertrieb</a>
                    .
                </div>
                <div class="alert alert-danger" *ngIf="errorFeedback.overduePayments">
                    Wegen Zahlungsverzug gesperrt.
                </div>
                <div class="alert alert-danger" *ngIf="errorFeedback.tooManyFailedLoginAttempts">
                    Zu viele fehlgeschlagene Anmeldungen. Account bis
                    {{ errorFeedback.tooManyFailedLoginAttempts | moment: 'DD.MM.YYYY [-] HH:mm [Uhr]' }} gesperrt
                </div>
            </div>
        </div>

        <!-- Remember Me Checkbox -->
        <div id="remember-me-container">
            <mat-checkbox id="remember-me" name="remeberMe" [(ngModel)]="rememberMe">Eingeloggt bleiben</mat-checkbox>
        </div>

        <!-- Submit button -->
        <div id="login-card-button-container">
            <button type="submit" class="button" (click)="verifyCredentials()" [ngClass]="{ disabled: loginPending }">
                <img class="pending-icon" src="/assets/images/icons/loading-indicator-white.svg" *ngIf="loginPending" />
                Einloggen
            </button>
        </div>

        <!-- Footer -->
        <div id="login-card-footer-container">
            <div class="right-column">
                <a id="login-card-forgot-password" (click)="startForgotPasswordProcess()" href="javascript: void(0);">
                    Passwort vergessen?
                </a>
            </div>
        </div>
    </form>
    <!--============================================-->
    <!-- Set New Password -->
    <!--============================================-->
    <form *ngIf="passwordResetShown">
        <h1 class="login-card-container-headline">Passwort setzen</h1>
        <mat-form-field id="new-password-container">
            <input
                matInput
                placeholder="Neues Passwort"
                [(ngModel)]="newPassword"
                name="newPassword"
                [type]="passwordVisible ? 'text' : 'password'"
                [axAutofocus]="true"
                autocomplete="new-password" />
            <mat-icon
                class="password-show-toggle small-icon"
                (click)="togglePasswordVisibility()"
                [ngClass]="{ 'password-visible': passwordVisible }">
                remove_red_eye
            </mat-icon>
        </mat-form-field>
        <div id="new-password-button-container">
            <button (click)="setNewPassword()" type="submit" [ngClass]="{ disabled: newPasswordRequestPending }">
                <img
                    class="pending-icon"
                    src="/assets/images/icons/loading-indicator-white.svg"
                    *ngIf="newPasswordRequestPending" />
                Passwort setzen
            </button>
        </div>
    </form>
    <!--============================================-->
    <!-- END Set New Password -->
    <!--============================================-->
</div>

<div class="document-order-group-container">
    <!--============================================-->
    <!-- Default Document Order Group Header -->
    <!--============================================-->
    <div class="document-order-group-header">
        <div class="document-order-group-title text-overflow-ellipsis">
            <h3 *ngIf="!isTitleEditModeActive" class="flex-grow">
                <span>{{ defaultDocumentOrderGroup.title }}</span>
                <mat-icon
                    class="title-edit-icon input-toolbar-icon small-icon"
                    (click)="enterTitleEditMode()"
                    matTooltip="Titel bearbeiten. Der Titel dient nur der internen Verwendung und wird nicht abgedruckt.">
                    edit
                </mat-icon>
            </h3>
            <div class="title-input-container" *ngIf="isTitleEditModeActive">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="Titel"
                        [(ngModel)]="defaultDocumentOrderGroup.title"
                        (change)="defaultDocumentOrderGroupChanged.emit()"
                        [axAutofocus]="true" />
                    <mat-icon
                        matSuffix
                        class="input-toolbar-icon"
                        (click)="leaveTitleEditMode()"
                        matTooltip="Bearbeitung beenden">
                        check
                    </mat-icon>
                </mat-form-field>
            </div>

            <!--============================================-->
            <!-- Assigned Report Type -->
            <!--============================================-->
            <div>
                <div class="assigned-document-order-groups label" (click)="onShowReportTypesDialog()">
                    <mat-icon class="document-order-group-icon small-icon">local_offer</mat-icon>
                    <span class="text-overflow-ellipsis">{{ getListOfAssignedReportTypes() }}</span>
                </div>
                <div *ngIf="isReportTypeDialogOpen" class="dialog-overlay">
                    <div id="document-order-groups-dialog" class="dialog-container dialog-grid">
                        <h2>Gutachtentypen</h2>
                        <mat-icon class="dialog-close-icon" (click)="onCloseReportTypesDialog()">close</mat-icon>

                        <div class="document-order-groups-list">
                            <div
                                class="document-order-group"
                                *ngFor="let reportType of reportTypes"
                                (click)="toggleReportType(reportType)"
                                [ngClass]="{
                                    active: isReportTypeAssigned(reportType),
                                    disabled: isReportTypeDisabled(reportType),
                                }"
                                [matTooltip]="
                                    isReportTypeDisabled(reportType)
                                        ? 'Dieser Gutachtentyp wird bereits in einer anderen Konfiguration verwendet.'
                                        : ''
                                ">
                                <mat-icon class="document-order-group-icon small-icon">local_offer</mat-icon>
                                <div class="document-order-group-title">
                                    {{ translateReportType(reportType) }}
                                </div>
                            </div>
                        </div>

                        <div class="dialog-buttons-container">
                            <button (click)="onCloseReportTypesDialog()">Schließen</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Assigned Report Type -->
            <!--============================================-->
        </div>

        <!--********** Menu **********-->
        <!-- Hide menu on default config since delete (which is the only operation) is not available. -->
        <mat-icon
            *ngIf="!defaultDocumentOrderGroup.types.includes('reportDefault')"
            class="input-toolbar-icon"
            [matMenuTriggerFor]="documentLayoutGroupMenu">
            more_vert
        </mat-icon>
        <mat-menu #documentLayoutGroupMenu>
            <a
                mat-menu-item
                class="menu-item-with-icon"
                (click)="deleteDefaultDocumentOrderGroup()"
                [class.disabled]="defaultDocumentOrderGroup.types.includes('reportDefault')">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>
    </div>
    <!--============================================-->
    <!-- END Default Document Order Group Header -->
    <!--============================================-->

    <!--============================================-->
    <!-- Default Document Order (config per recipient) -->
    <!--============================================-->
    <div class="document-orders-list">
        <div
            *ngFor="let defaultDocumentOrder of defaultDocumentOrderGroup.documentOrders"
            class="config-per-recipient"
            [ngClass]="{ selected: this.selectedDefaultDocumentOrder === defaultDocumentOrder }"
            (click)="selectDefaultDocumentOrder(defaultDocumentOrder)">
            <ng-container *ngIf="defaultDocumentOrder.recipientRole === 'default'">
                <mat-icon class="recipient-type-icon">grading</mat-icon>
                <span class="text-overflow-ellipsis flex-grow">Standard-Reihenfolge</span>
            </ng-container>
            <ng-container *ngIf="defaultDocumentOrder.recipientRole !== 'default'">
                <img
                    class="icon recipient-type-icon"
                    [src]="'/assets/images/icons/' + defaultDocumentOrder.recipientRole + '-gray.svg'" />

                <span class="text-overflow-ellipsis flex-grow">
                    {{ translateRecipientRole(defaultDocumentOrder.recipientRole) }}
                </span>
            </ng-container>

            <!--********** Menu **********-->
            <mat-icon
                *ngIf="defaultDocumentOrder.recipientRole !== 'default'"
                class="input-toolbar-icon"
                [matMenuTriggerFor]="configPerRecipientMenu">
                more_vert
            </mat-icon>
            <mat-menu #configPerRecipientMenu>
                <a mat-menu-item class="menu-item-with-icon" (click)="deleteDefaultDocumentOrder(defaultDocumentOrder)">
                    <mat-icon>delete</mat-icon>
                    Löschen
                </a>
            </mat-menu>
        </div>
    </div>
    <!--============================================-->
    <!-- Default END Document Order (config per recipient) -->
    <!--============================================-->
</div>

<!--********** Add Default Document Order **********-->
<div class="add-default-document-order">
    <section
        class="text-align-center"
        [matTooltip]="availableRecipientRoles.length < 1 ? 'Es sind bereits alle Empfänger zugeordnet.' : ''">
        <button
            class="flat"
            [matTooltip]="
                team.preferences.allowCustomDocumentOrderPerRecipient
                    ? 'Du kannst die Dokumente pro Empfänger (z. B. Versicherung) abweichend sortieren und aktivieren/deaktivieren'
                    : 'Du kannst die Dokumente pro Empfänger (z. B. Versicherung) abweichend aktivieren/deaktivieren, aber nicht abweichend sortieren'
            "
            [matMenuTriggerFor]="recipientRoleMenu"
            [ngClass]="{ disabled: availableRecipientRoles.length < 1 }"
            [disabled]="availableRecipientRoles.length < 1">
            <mat-icon class="small-icon">add</mat-icon>
            Empfängertyp
        </button>
        <mat-menu #recipientRoleMenu="matMenu">
            <a
                mat-menu-item
                *ngFor="let recipientRole of availableRecipientRoles"
                class="menu-item-with-icon"
                (click)="addDefaultDocumentOrder(recipientRole)">
                <img class="icon" [src]="'/assets/images/icons/' + recipientRole + '-gray.svg'" />
                {{ translateRecipientRole(recipientRole) }}
            </a>
        </mat-menu>
    </section>
</div>

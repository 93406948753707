import { getCommunicationRecipientsFromReport } from '@autoixpert/lib/involved-parties/get-communication-recipients-from-report';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { CommunicationRecipient } from '@autoixpert/models/reports/involved-parties/communication-recipient';
import { Report } from '@autoixpert/models/reports/report';
import { DocumentGroupForDocumentRemoval } from './remove-document-from-report';

export function getDocumentOrdersOfCommunicationRecipientsFromReport({
    report,
    documentGroup = 'both',
}: {
    report: Report;
    documentGroup: DocumentGroupForDocumentRemoval;
}): DocumentOrder[] {
    /**
     * This might become a custom array in the future when we enable custom involved parties.
     */
    const communicationRecipients: CommunicationRecipient[] = getCommunicationRecipientsFromReport({ report });

    const documentOrders: DocumentOrder[] = [];
    for (const communicationRecipient of communicationRecipients) {
        let documentGroupDocumentOrders: DocumentOrder[];
        if (documentGroup === 'repairConfirmation') {
            documentGroupDocumentOrders = report.repairConfirmation?.documentOrders || [];
        } else if (documentGroup === 'report') {
            documentGroupDocumentOrders = report.documentOrders;
        } else if (documentGroup === 'both') {
            documentGroupDocumentOrders = [
                ...(report.repairConfirmation?.documentOrders || []),
                ...report.documentOrders,
            ];
        }

        // Use filter() instead of find() because documentGroupDocumentOrders may contain both report and repair confirmation document orders.
        const documentGroupOrderForRecipient = documentGroupDocumentOrders.filter(
            (documentOrder) => documentOrder.recipientRole === communicationRecipient.role,
        );

        if (documentGroupOrderForRecipient.length === 0) {
            throw new AxError({
                code: 'FINDING_REPORT_DOCUMENT_ORDER_FAILED',
                message: 'The document order for the given involved party role could not be found in the report.',
                data: {
                    communicationRecipientRole: communicationRecipient.role,
                },
            });
        }
        documentOrders.push(...documentGroupOrderForRecipient);
    }
    return documentOrders;
}

<div class="overlay" (mousedown)="handleOverlayClick($event)" *ngIf="descriptionShown"></div>
<mat-checkbox
    [ngModel]="!!damage"
    (change)="emitDamageToggle(damagePosition)"
    [disabled]="disabled"
    [matTooltip]="damage?.title"
    [matTooltipPosition]="side === 'left' ? 'left' : 'right'">
    <!-- Tooltip Position: side values are left, center or right. Center and right sides shall show their tooltip on the right-hand side -->
</mat-checkbox>
<div class="damage-container" [ngClass]="{ 'left-side': side === 'left' }" *ngIf="damage">
    <div class="description-icon-container">
        <img
            src="/assets/images/icons/add-grey_16.png"
            *ngIf="!(damage.title || damage.description)"
            (click)="toggleDescription()" />
        <img
            src="/assets/images/icons/document-grey_16.png"
            *ngIf="damage.title || damage.description"
            (click)="toggleDescription()" />
        <div class="description-container card card-with-padding" *ngIf="descriptionShown">
            <h2>
                Schadenbeschreibung
                <span
                    class="help-indicator"
                    matTooltip="Diese Beschreibungen werden unterhalb der Schadenskizze abgedruckt. Für einen zusammenhängenden Fließtext nutze die Schadenbeschreibung außerhalb dieses Dialogs bei den Feldern für Vor- & Altschäden.">
                    ?
                </span>
            </h2>
            <mat-form-field class="mat-block">
                <input matInput [(ngModel)]="damage.title" placeholder="Überschrift" [disabled]="disabled" />
            </mat-form-field>

            <mat-form-field class="mat-block">
                <mat-quill
                    [(ngModel)]="damage.description"
                    placeholder="Beschreibung"
                    (change)="emitDamageChange()"
                    [autofocus]="true"
                    [disabled]="disabled"></mat-quill>
            </mat-form-field>
            <img class="close-icon" src="/assets/images/icons/close.png" (click)="toggleDescription()" />
            <img class="pointing-triangle" src="/assets/images/pointing-triangle.png" />
        </div>
    </div>
</div>

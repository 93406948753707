<div id="date-and-time-input-container">
    <!--********** Date Input **********-->
    <input
        type="text"
        name="datum"
        [value]="_date"
        (dateChange)="_date = $event.value; emitDateChange()"
        placeholder="Datum"
        [matDatepicker]="datepicker"
        autocomplete="off"
        #mainInput />

    <!--********** Time Input **********-->
    <input
        *ngIf="showTimeInput"
        type="text"
        name="uhrzeit"
        [value]="_time ? _time + ' Uhr' : ''"
        (change)="handleTimeChange($any($event.target).value)"
        placeholder="Uhrzeit"
        autocomplete="off" />

    <!-- Never visible. Enables input parsing (g -> gestern (yesterday)). -->
    <mat-datepicker #datepicker></mat-datepicker>
    <mat-icon class="toolbar-icon medium-icon" (click)="clearDate()" matTooltip="Datum entfernen">delete</mat-icon>
</div>

<!--********** Date Picker **********-->
<mat-calendar
    [(selected)]="_date"
    (selectedChange)="handleDatePicked()"
    startView="month"
    [startAt]="_date"></mat-calendar>

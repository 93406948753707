import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNameOrOrganizationFilled } from '@autoixpert/lib/contact-people/is-name-or-organization-filled';
import { InvoiceTypeGerman, getInvoiceTypeGerman } from '@autoixpert/lib/invoices/get-invoice-type-german';
import { getUserFullName } from '@autoixpert/lib/users/get-user-full-name';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { User } from '@autoixpert/models/user/user';
import { UserService } from 'src/app/shared/services/user.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { NewWindowService } from '../../shared/services/new-window.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';

@Component({
    selector: 'invoice-info-panel',
    templateUrl: './invoice-info-panel.component.html',
    styleUrls: ['./invoice-info-panel.component.scss'],
    host: {
        // The card class adds box-shadow.
        '[class.card]': 'true',
        '[class.dark-card]': 'true',
    },
})
export class InvoiceInfoPanelComponent {
    constructor(
        private invoiceService: InvoiceService,
        private newWindowService: NewWindowService,
        private userService: UserService,
        protected userPreferences: UserPreferencesService,
    ) {}

    @Input() invoice: Invoice;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() paymentReminderButtonClicked: EventEmitter<Invoice> = new EventEmitter();
    @Output() labelsChanged: EventEmitter<void> = new EventEmitter();

    ngOnInit() {}

    public getUserFullName(userId: string) {
        return this.userService.getTeamMembersName(userId);
    }

    //*****************************************************************************
    //  Invoice Type
    //****************************************************************************/
    protected getInvoiceTypeGerman(): InvoiceTypeGerman {
        if (!this.invoice) return undefined;

        return getInvoiceTypeGerman(this.invoice);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invoice Type
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Data
    //****************************************************************************/
    public openReportInNewTab() {
        this.newWindowService.open(`/Gutachten/${this.invoice.reportIds[0]}`);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Server Communication
    //****************************************************************************/
    protected saveInvoice() {
        this.invoiceService.put(this.invoice);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Server Communication
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closePanel() {
        this.close.emit();
    }

    public emitPaymentReminderClickedEvent() {
        this.paymentReminderButtonClicked.emit(this.invoice);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
    protected readonly isNameOrOrganizationFilled = isNameOrOrganizationFilled;
}

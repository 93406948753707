import { Injectable } from '@angular/core';
import { simpleHash } from '@autoixpert/lib/simple-hash';
import { Photo, PhotoFormat, PhotoVersion } from '@autoixpert/models/reports/damage-description/photo';

@Injectable()
export class PhotoBlobUrlCacheService {
    private photoBlobUrlCache = new Map<string, PhotoBlobUrlCacheEntry>();

    public get(
        photoId: Photo['_id'],
        version: PhotoVersion,
        format: PhotoFormat,
        currentFabricJsInformation: Photo['versions']['report']['fabricJsInformation'],
    ): string | null {
        const cacheId: string = this.getCacheId(photoId, version, format);
        const cacheHit = this.photoBlobUrlCache.get(cacheId);
        if (cacheHit) {
            if (cacheHit.fabricJsInformationHash !== simpleHash(JSON.stringify(currentFabricJsInformation))) {
                this.photoBlobUrlCache.delete(cacheId);
                return null;
            }
            return cacheHit.photoBlobUrl;
        }
        return null;
    }

    public set(
        photoId: Photo['_id'],
        version: PhotoVersion,
        format: PhotoFormat,
        currentFabricJsInformation: Photo['versions']['report']['fabricJsInformation'],
        photoBlobUrl: string,
    ): void {
        const cacheId: string = this.getCacheId(photoId, version, format);
        this.photoBlobUrlCache.set(cacheId, {
            fabricJsInformationHash: simpleHash(JSON.stringify(currentFabricJsInformation)),
            photoBlobUrl,
        });
    }

    private getCacheId(photoId: Photo['_id'], version: PhotoVersion, format: PhotoFormat) {
        return `${photoId}-${version}-${format}`;
    }

    /**
     * Used when the user is logged-out.
     */
    public clear() {
        this.photoBlobUrlCache.clear();
    }
}

export interface PhotoBlobUrlCacheEntry {
    fabricJsInformationHash: string;
    photoBlobUrl: string;
}

import { ExternalSalesPerson } from '../../models/account-management/external-sales-person';
import { GLOBAL_ADMIN_USERS } from '../../static-data/access-rights/global-admins';

export function getAudatexSalesPersonForZipCode(zipCode: string): ExternalSalesPerson {
    const firstTwoDigits: string = (zipCode || '').slice(0, 2);

    if (!firstTwoDigits) return null;

    const allAxTeamMembers = GLOBAL_ADMIN_USERS;

    switch (firstTwoDigits) {
        case '40':
        case '41':
        case '42':
        case '45':
        case '48':
        case '50':
        case '51':
        case '52':
        case '53':
        case '54':
        case '55':
        case '56':
        case '57':
        case '60':
        case '61':
        case '63':
        case '65':
        case '66':
            return {
                firstName: 'Ulli',
                lastName: 'Maschmeier',
                email: 'ulrich.maschmeier@ax-ao.de',
                gender: 'male',
                isBeingDuzedBy: allAxTeamMembers,
            };
        case '17':
        case '18':
        case '19':
        case '20':
        case '21':
        case '22':
        case '23':
        case '24':
        case '25':
        case '26':
        case '27':
        case '28':
        case '29':
        case '30':
        case '31':
        case '32':
        case '33':
        case '34':
        case '35':
        case '36':
        case '37':
        case '38':
        case '39':
        case '44':
        case '46':
        case '47':
        case '49':
        case '58':
        case '59':
        case '97':
        case '98':
        case '99':
            return {
                firstName: 'Tom',
                lastName: 'Riemenschneider',
                email: 'tom.riemenschneider@ax-ao.de',
                gender: 'male',
                isBeingDuzedBy: allAxTeamMembers,
            };

        case '01':
        case '02':
        case '03':
        case '04':
        case '06':
        case '07':
        case '08':
        case '09':
        case '10':
        case '11':
        case '12':
        case '13':
        case '14':
        case '15':
        case '16':
        case '92':
        case '95':
            return {
                firstName: 'Tom',
                lastName: 'Riemenschneider',
                email: 'tom.riemenschneider@ax-ao.de',
                gender: 'male',
                isBeingDuzedBy: allAxTeamMembers,
            };
        // Nathalie has asked us to transfer all sales activity to Tom, because she's switched roles into management.
        // return {
        //     firstName: 'Nathalie',
        //     lastName: 'Kirst',
        //     email: 'nathalie.kirst@ax-ao.de',
        //     gender: 'female',
        //     isBeingDuzedBy: allAxTeamMembers,
        // };
        case '64':
        case '67':
        case '68':
        case '69':
        case '70':
        case '71':
        case '72':
        case '73':
        case '74':
        case '75':
        case '76':
        case '77':
        case '78':
        case '79':
        case '80':
        case '81':
        case '82':
        case '83':
        case '84':
        case '85':
        case '86':
        case '87':
        case '88':
        case '89':
        case '90':
        case '91':
        case '93':
        case '94':
        case '96':
            return {
                firstName: 'Kemal',
                lastName: 'Veran',
                email: 'kemal.veran@ax-ao.de',
                gender: 'male',
                isBeingDuzedBy: allAxTeamMembers,
            };
    }
}

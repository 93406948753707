import { MarketAnalysis } from './market-analysis';

export class ValuepilotMarketAnalysis extends MarketAnalysis {
    valuepilotEquipmentOptions: ValuepilotEquipmentOptions = new ValuepilotEquipmentOptions();
}

export class ValuepilotEquipmentOptions {
    airconditioningAutomatic: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
    airconditioningManual: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
    leatherSeats: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
    xenonHeadlights: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
    hitch: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
    particulateFilter: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
    headupDisplay: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
    navigationSystem: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
    sunRoof: ValuepilotEquipmentOption = new ValuepilotEquipmentOption();
}

export class ValuepilotEquipmentOption {
    active: boolean;
    recognizedEquipmentTitle: string; // If we activated an item automatically, we store and display the title of the equipment leading to the activation.
}

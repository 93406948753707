<!--============================================-->
<!-- Task List -->
<!--============================================-->
<div id="task-list" cdkDropList (cdkDropListDropped)="onTaskDropped($event)" *ngIf="sortedTasks.length">
    <div class="task-row" cdkDrag *ngFor="let task of sortedTasks" [ngClass]="{ 'task-status-done': task.isCompleted }">
        <div class="task-status-and-drag-indicator-column">
            <!--********** Task Status **********-->
            <task-done-checkbox
                [task]="task"
                (taskCompleted)="sortToBack(task); saveTask(task)"
                (taskReopened)="saveTask(task)"></task-done-checkbox>

            <!--********** Drag Indicator **********-->
            <mat-icon cdkDragHandle class="drag-indicator medium-icon">drag_indicator</mat-icon>
        </div>

        <!--********** Title & Date **********-->
        <div
            class="task-title-and-date-container"
            (dblclick)="openTaskDetailsOnDirectDoubleClick({ event: $event, task })">
            <task-title
                [task]="task"
                [singleLine]="false"
                [darkMode]="true"
                [shouldAutofocus]="!task.title"
                (taskChange)="saveTask(task)"
                (enterKeyPress)="createNewTask({ position: 'after', referenceTask: task })"
                (shiftEnterKeyPress)="createNewTask({ position: 'before', referenceTask: task })"
                (taskDeleteRequest)="deleteTask(task)"></task-title>

            <!--********** Date **********-->
            <div
                datePickerWithInputAnchor
                [showTimeInput]="true"
                class="due-date"
                [(date)]="task.dueDate"
                (dateChange)="saveTask(task)">
                <span
                    *ngIf="task.dueDate"
                    class="due-date-is-{{ task | compareTaskWithToday }}"
                    matTooltip="Fällig: {{ task.dueDate | moment: 'DD.MM.YYYY' }}">
                    {{ task.dueDate | relativeDate }}
                    {{ task.dueTime ? ' ' + task.dueTime + ' Uhr' : '' }}
                </span>
                <div *ngIf="!task.dueDate" class="missing-data-info label italic">ohne Datum</div>
            </div>
        </div>

        <!--********** Time Estimate **********-->
        <!--        <task-estimated-duration-->
        <!--            [(estimatedDuration)]="task.estimatedDuration"-->
        <!--            (estimatedDurationChange)="saveTask(task)"></task-estimated-duration>-->

        <!--********** Assignee **********-->
        <user-avatar
            [userId]="task.assigneeId"
            userSelectorInputAnchor
            [showEmptyState]="true"
            [size]="20"
            (userSelected)="task.assigneeId = $event._id; saveTask(task)"></user-avatar>

        <!--********** Submenu **********-->
        <mat-icon class="submenu-icon" [matMenuTriggerFor]="taskSubmenu">more_vert</mat-icon>
        <mat-menu #taskSubmenu>
            <!--********** Open Details **********-->
            <a mat-menu-item class="menu-item-with-icon" (click)="openTaskDetailsDialog(task)">
                <mat-icon>open_in_new</mat-icon>
                Details öffnen
            </a>

            <!--********** Delete **********-->
            <a mat-menu-item class="menu-item-with-icon alert" (click)="deleteTask(task)">
                <mat-icon>delete</mat-icon>
                Löschen
            </a>
        </mat-menu>
    </div>
</div>
<!--============================================-->
<!-- END Task List -->
<!--============================================-->

<!--============================================-->
<!-- Add Task Button -->
<!--============================================-->
<div id="add-task-button" (click)="createNewTask({ position: 'last' })">
    <mat-icon>add</mat-icon>
    <span>Neue Aufgabe</span>
</div>
<!--============================================-->
<!-- END Add Task Button -->
<!--============================================-->

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getInvoiceNumberOrReportTokenCounterErrorHandlers(): {
    [key: string]: ApiErrorHandler | ApiErrorHandlerCreator;
} {
    return {
        EMPTY_INVOICE_NUMBER_COUNTER: {
            title: 'Leerer Zähler für Rechnungsnummer',
            body: 'Du lässt dein Aktenzeichen und die Rechnungsnummer synchron generieren. Bitte setze deinen aktuellen Zähler für Rechnungsnummern in den <a href="/Einstellungen?section=invoice-number-container" target="_blank">Einstellungen</a>.',
        },
        INVOICE_NUMBER_OR_REPORT_TOKEN_COUNTER_OBJECT_NOT_FOUND: {
            title: `Zähler-Objekt nicht gefunden`,
            body: `Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        REPORT_TOKEN_MISSING: {
            title: `Aktenzeichen fehlt`,
            body: `Deine Rechnungsnummern basieren auf dem Aktenzeichen des Gutachtens. Um die Rechnungsnummer zu generieren, ziehe zuerst ein Aktenzeichen.`,
        },
        REPORT_TOKEN_OR_INVOICE_NUMBER_PATTERN_MISSING: {
            title: `Aktenzeichen- oder Rechnungsnummernmuster fehlt`,
            body: `Die Platzhalter, die dein Aktenzeichen oder deine Rechnungsnummer ausmachen, wurden beim Ersetzen nicht gefunden. Bitte prüfe dein Aktenzeichen und deine Rechnungsnummer in den <a href="/Einstellungen?section=invoice-number-container" target="_blank">Einstellungen</a>.`,
        },
    };
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import moment, { Moment } from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { arrayIncludesAnyOfTheseValues } from '@autoixpert/lib/arrays/array-includes-any-of-these-values';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { removeFromArrayById } from '@autoixpert/lib/arrays/remove-from-array-by-id';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { getContactPersonFullNameWithOrganization } from '@autoixpert/lib/contact-people/get-contact-person-full-name-with-organization';
import { isNameOrOrganizationFilled } from '@autoixpert/lib/contact-people/is-name-or-organization-filled';
import { ReportTypeGerman, Translator } from '@autoixpert/lib/placeholder-values/translator';
import { moveReportToTrash, restoreReportFromTrash } from '@autoixpert/lib/report/is-report-deleted';
import { applyOfflineSyncPatchEventToLocalRecord } from '@autoixpert/lib/server-sync/apply-offline-sync-patch-event-to-local-record';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { PatchedEvent } from '@autoixpert/models/indexed-db/database.types';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Label } from '@autoixpert/models/labels/label';
import { LabelConfig } from '@autoixpert/models/labels/label-config';
import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { ReportProgressConfig } from '@autoixpert/models/report-progress/report-progress-config';
import { Report, ReportType } from '@autoixpert/models/reports/report';
import { ReportListSearchMatch } from '@autoixpert/models/reports/report-list-search-match';
import { Team } from '@autoixpert/models/teams/team';
import { ReportListQuickFilterType, UserPreferences } from '@autoixpert/models/user/preferences/user-preferences';
import { User } from '@autoixpert/models/user/user';
import { InvoiceNumberJournalEntryService } from 'src/app/shared/services/invoice-number-journal-entry.service';
import { runChildAnimations } from '../../shared/animations/run-child-animations.animation';
import { slideOutSide } from '../../shared/animations/slide-out-side.animation';
import { slideOutVertical } from '../../shared/animations/slide-out-vertical.animation';
import { toggleValueInArray } from '../../shared/libraries/arrays/toggle-value-in-array';
import { getInvoiceNumberOrReportTokenCounterErrorHandlers } from '../../shared/libraries/error-handlers/get-invoice-number-or-report-token-counter-error-handlers';
import { generateRepairConfirmationObject } from '../../shared/libraries/report/generate-repair-confirmation-object';
import { isNeverType } from '../../shared/libraries/types/is-never-type';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { DownloadService } from '../../shared/services/download.service';
import { InvoiceNumberOrReportTokenCounterService } from '../../shared/services/invoice-number-or-report-token-counter.service';
import { InvoiceNumberService } from '../../shared/services/invoice-number.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LabelConfigService } from '../../shared/services/label-config.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { NetworkStatus, NetworkStatusService } from '../../shared/services/network-status.service';
import { ReportProgressConfigService } from '../../shared/services/report-progress-config.service';
import { ReportTokenAndInvoiceNumberService } from '../../shared/services/report-token-and-invoice-number.service';
import { ReportTokenService } from '../../shared/services/report-token.service';
import { ReportFilterAndSortParams, ReportService } from '../../shared/services/report.service';
import { ScreenTitleService } from '../../shared/services/screen-title.service';
import { ToastService } from '../../shared/services/toast.service';
import { TutorialStateService } from '../../shared/services/tutorial-state.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserService } from '../../shared/services/user.service';
import { isReportTabVisible } from '../details/shared/is-report-tab-visible';
import { getLinkFragmentForReportTabName } from '../details/shared/report-tabs/get-link-fragment-for-report-tab-name';
import { translateReportTabName } from '../details/shared/report-tabs/translate-report-tab-name';

@Component({
    selector: 'report-list',
    templateUrl: 'report-list.component.html',
    styleUrls: ['report-list.component.scss'],
    animations: [runChildAnimations(), slideOutSide(), slideOutVertical()],
})
export class ReportListComponent implements OnInit, AfterViewInit {
    constructor(
        private reportService: ReportService,
        private router: Router,
        private route: ActivatedRoute,
        private loggedInUserService: LoggedInUserService,
        private userService: UserService,
        private screenTitleService: ScreenTitleService,
        public userPreferences: UserPreferencesService,
        private toastService: ToastService,
        private tutorialStateService: TutorialStateService,
        private apiErrorService: ApiErrorService,
        private reportProgressConfigService: ReportProgressConfigService,
        private networkStatusService: NetworkStatusService,
        private reportTokenAndInvoiceNumberService: ReportTokenAndInvoiceNumberService,
        private invoiceNumberOrReportTokenCounterService: InvoiceNumberOrReportTokenCounterService,
        private invoiceNumberJournalEntryService: InvoiceNumberJournalEntryService,
        private reportTokenService: ReportTokenService,
        private httpClient: HttpClient,
        private ngZone: NgZone,
        private dialog: MatDialog,
        private downloadService: DownloadService,
        private invoiceService: InvoiceService,
        private labelConfigService: LabelConfigService,
        private invoiceNumberService: InvoiceNumberService,
    ) {}

    openReports: Report[] = [];
    deletedReports: Report[] = [];
    doneReports: Report[] = [];
    filteredOpenReports: Report[] = [];
    filteredDeletedReports: Report[] = [];
    filteredDoneReports: Report[] = [];
    searchMatchesOpenReports: WeakMap<Report, ReportListSearchMatch[]> = new WeakMap();
    searchMatchesDoneReports: WeakMap<Report, ReportListSearchMatch[]> = new WeakMap();
    atlasSearchMatches = new Set<Report['_id']>();
    selectedReport: Report;
    showTrash: boolean = false;
    reportRefreshInProgress: boolean;

    /**
     * List of invoice IDs that belong to the currently selected report -> displayed in the info pane
     */
    protected associatedInvoiceIdsOfSelectedReport: Invoice['_id'][] = [];

    /**
     * Pagination
     */
    loadMoreDoneReports$$ = new Subject<LoadMoreDoneReportsPayload>();
    // Keep track of the last page (for server and indexedDB)
    lastReportPaginationTokenFromServer: string;
    numberOfLoadedReports: number = 0;

    reportsLoaded: boolean; // Don't render before the local response has arrived. This increases performance.
    allReportsLoadedWithCurrentFilters = false;
    public isLoadMoreDoneReportsPending: boolean = false;
    public reportLimitReached: boolean = false;

    user: User;
    team: Team;

    searchTerm: string = '';
    dateLimitFrom: string = '';
    dateLimitTo: string = '';
    searchTerm$: Subject<string> = new Subject<string>();
    dateLimitFrom$: Subject<string> = new Subject<string>();
    dateLimitTo$: Subject<string> = new Subject<string>();
    protected labelConfigs: LabelConfig[] = [];

    private subscriptions: Subscription[] = [];
    private websocketSubscriptions: Subscription[] = [];
    dateLimitInputsShown: boolean = false;

    // Assessor Filter
    /**
     * The assessors in the current team.
     */
    public assessors: User[] = [];

    // Details Pane
    public reportProgressConfig: ReportProgressConfig;

    // Import Legacy Reports Dialog
    public importLegacyReportsDialogShown: boolean;

    // Export Reports
    public reportExportPending: boolean;

    /**
     * The height of this component, including padding and borders.
     */
    public routerOutletHeight;

    // All available report tabs
    public readonly reportTabNames = [
        'accidentData',
        'carData',
        'carCondition',
        'photos',
        'damageCalculation',
        'valuation',
        'leaseReturnChecklist',
        'fees',
        'printAndTransmission',
    ] as const;

    protected animationsDisabled: boolean = true;
    /*****************************************************************************
     /  Initialization
     /****************************************************************************/
    ngOnInit() {
        this.subscriptions.push(
            // Update the team & user in case they were updated in a different tab.
            this.loggedInUserService.getUser$().subscribe((user) => (this.user = user)),
            this.loggedInUserService.getTeam$().subscribe((team) => (this.team = team)),
        );

        this.subscribeToLoadMoreDoneReports();
        this.subscribeToSearchTermChanges();

        // To get the largest available height of the router, we must subtract the height of the head runner from the screen height.
        this.routerOutletHeight = window.screen.availHeight - 100;

        /**
         * Load open and done reports.
         * This also registers websocket event handlers.
         */
        this.initialLoadReports().then(() => {
            /**
             * Sync all visible reports with the server. Load reports only queries open
             * and the last couple done reports, but no deleted reports. By syncing all
             * visible reports, we're notified by updates to those reports too.
             */
            this.syncAllLoadedReports();
        });

        this.screenTitleService.setScreenTitle({ screenTitle: 'Meine Gutachten' });
        this.loadAssessorsFromTeam();

        this.checkDataIntegrity();
        this.getReportProgressConfig();

        this.loadLabelConfigs();

        // Sync when back online
        this.syncVisibleReportsWhenGoingBackOnline();
    }

    ngAfterViewInit() {
        /**
         * Block any animations while the screen is initializing. This prevents e.g. the report details panel from sliding in
         * every time you open the report list.
         */
        setTimeout(() => (this.animationsDisabled = false), 100);
    }

    /*****************************************************************************
     /  END Initialization
     /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Create new report and navigate to that report instantly.
     */
    public async createReport(
        typeSelectedInDialog: Report['type'] | 'repairConfirmation' = 'liability',
    ): Promise<void> {
        const reportType: Report['type'] =
            typeSelectedInDialog === 'repairConfirmation' ? 'liability' : typeSelectedInDialog;

        const newReport: Report = await this.reportService.createEmptyReport({ reportType });
        const shouldTokenBeGeneratedOnCreate = this.reportTokenService.shouldTokenBeGeneratedOnCreate();

        /**
         * A separate repair confirmation shall not trigger increasing the token counter or draw an invoice number,
         * which are both reserved for full reports.
         * The invoice for the repair confirmation can be generated in the respective screen, but it's different from
         * the report's invoice disabled here.
         */
        if (typeSelectedInDialog === 'repairConfirmation') {
            newReport.token = 'Nur Rep.-Best.';
            newReport.feeCalculation.skipWritingInvoice = true;
            newReport.repairConfirmation = generateRepairConfirmationObject({ team: this.team });
            await this.saveReport(newReport);
        } else {
            if (shouldTokenBeGeneratedOnCreate) {
                await this.generateReportTokenOnCreate(newReport);
            }
        }

        this.navigate([newReport._id]);

        this.tutorialStateService.markUserTutorialStepComplete('liabilityReportCreated');
    }

    //*****************************************************************************
    //  Head Runner
    //****************************************************************************/
    /**
     * Display warning if the user either
     * - has a few open reports but has never locked any (zero done reports) -> He should start locking reports.
     * - has lots of open reports
     */
    public isWarningAboutTooManyOpenReportsVisible(): boolean {
        const fewOpenAndNoLockedReports: boolean =
            this.reportsLoaded && !this.filteredDoneReports.length && this.filteredOpenReports.length > 10;
        const lotsOfOpenReports: boolean = this.filteredOpenReports.length > 50;

        return (fewOpenAndNoLockedReports || lotsOfOpenReports) && !this.showTrash;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Head Runner
    /////////////////////////////////////////////////////////////////////////////*/

    public async generateReportTokenOnCreate(report: Report) {
        // Since we wouldn't always use the up-to-date counter, block this feature if offline.
        if (!this.networkStatusService.isOnline()) {
            this.toastService.info(
                'Aktenzeichen offline nicht generiert',
                'Bitte vergib ein Aktenzeichen, sobald du wieder online bist.',
            );
            return;
        }

        /**
         * Depending on the leading counter type generate either
         * - Invoice number leading: both invoice number and report token with the same counter
         * - Report token leading: regular report token and invoice number based on the report token
         * - Not synced: report token only
         */
        const leadingCounter = this.reportTokenService.getLeadingCounter(report);

        // Generate invoice number and report token
        if (leadingCounter === 'invoiceNumber') {
            try {
                const { reportToken, invoiceNumber } =
                    await this.reportTokenAndInvoiceNumberService.generateReportTokenAndInvoiceNumber(report);
                this.reportTokenAndInvoiceNumberService.writeToReport(report, reportToken, invoiceNumber);

                const invoiceNumberConfig = this.invoiceNumberService.getInvoiceNumberConfig(report.officeLocationId);
                await this.invoiceNumberJournalEntryService.create({
                    entryType: 'invoiceNumberGeneratedOnCreation',
                    documentType: 'report',
                    reportId: report._id,
                    invoiceNumber,
                    invoiceNumberConfigId: invoiceNumberConfig._id,
                });
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        ...getInvoiceNumberOrReportTokenCounterErrorHandlers(),
                    },
                    defaultHandler: {
                        title: 'Aktenzeichen & Rechnungsnummer nicht generiert',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>aX-Hotline</a>.",
                    },
                });
            }
        }
        // Report token only or report token + report token based invoice number
        else {
            try {
                // Set the token before generating the invoice number, which might be based on the token
                report.token = await this.reportTokenService.generateReportToken(report);
                this.tutorialStateService.markUserTutorialStepComplete('reportTokenRetrieved');

                if (leadingCounter === 'reportToken') {
                    const invoiceNumber = await this.invoiceNumberService.generateInvoiceNumber({
                        officeLocationId: report.officeLocationId,
                        responsibleAssessorId: report.responsibleAssessor,
                        report,
                    });
                    report.feeCalculation.invoiceParameters.number = invoiceNumber;

                    const reportTokenConfig = this.reportTokenService.getReportTokenConfig(report.officeLocationId);

                    await this.invoiceNumberJournalEntryService.create({
                        entryType: 'invoiceNumberGeneratedOnCreation',
                        documentType: 'report',
                        reportId: report._id,
                        invoiceNumber,
                        reportTokenConfigId: reportTokenConfig._id,
                    });
                    this.tutorialStateService.markUserTutorialStepComplete('invoiceNumberRetrieved');
                }
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {
                        EMPTY_REPORT_TOKEN_COUNTER: {
                            title: 'Leerer Zähler für Aktenzeichen',
                            body: 'Bitte setze deinen aktuellen Zähler für Aktenzeichen in den <a href="/Einstellungen?section=report-token-container" target="_blank">Einstellungen</a>.',
                        },
                    },
                    defaultHandler: {
                        title: 'Generierung des Aktenzeichens fehlgeschlagen',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            }
        }

        this.screenTitleService.setScreenTitleForReport(report);
        await this.saveReport(report);
    }

    //*****************************************************************************
    //  Report Trash
    //****************************************************************************/
    /**
     * Mark the report as deleted without actually erasing its data.
     * @param report
     */
    public async moveReportToTrash(report: Report): Promise<void> {
        // Display special message if invoice number is set
        const hasInvoiceNumber = !!report.feeCalculation?.invoiceParameters?.number;
        const linkedInvoiceId = report.feeCalculation?.invoiceParameters?._id;

        /*
         * Handle linked Invoice:
         * - 1. User has permissions to see & delete invoice
         *      -> ask: delete invoice too?
         * - 2. User cannot delete invoice
         *      a) User has no permission to see the invoice
         *      b) User has permission to see the invoice but has no permisson to delete the invoice
         *      c) Cannot find invoice
         *      -> ask: delete report anyway?
         */
        if (linkedInvoiceId) {
            let invoice: Invoice;

            // Check if user has permission to see the invoice
            try {
                invoice = await this.invoiceService.get(linkedInvoiceId);
            } catch {
                // Do nothing since not all users are allowed to see all invoices
            }

            // Check if user has permission to see & delete the invoice
            const isDeleteingInvoiceAllowed =
                invoice && this.invoiceService.isDeletingInvoiceAllowed(invoice, this.user, this.team);
            if (isDeleteingInvoiceAllowed) {
                // Ask - invoice was already generated, do you want to delete it?
                const invoiceShouldBeDeleted: boolean = await this.dialog
                    .open(ConfirmDialogComponent, {
                        data: {
                            heading: 'Rechnung löschen?',
                            content: `Für dieses Gutachten ist bereits eine Rechnung erstellt (${report.feeCalculation.invoiceParameters.number}). Soll die Rechnung auch gelöscht werden? Die Rechnungsnummer wird dabei nicht automatisch neu vergeben. \n
                        Das Löschen der Rechnung kann zu steuerlichen Nachteilen führen, weil das Ändern bereits versendeter Rechnungen die Ermittlung deines Gewinns und damit deine Steuerlast beeinflusst. Außerdem können Lücken in deinem Rechnungskreis entstehen. In beiden Fällen behält sich das Finanzamt vor, Maßnahmen zur Ermittlung deiner tatsächlichen Steuerlast zu ergreifen, die eventuell nachteilhaft für dich sind.`,
                            confirmLabel: 'Gutachten mitsamt Rechnung löschen',
                            cancelLabel: 'Rechnung behalten',
                            confirmColorRed: true,
                        },
                        maxWidth: '700px',
                    })
                    .afterClosed()
                    .toPromise();

                if (invoiceShouldBeDeleted) {
                    await this.invoiceService.safeDelete(invoice);
                }
            } else {
                // Ask - invoice was already generated but cannot be deleted, delete report but keep invoice?
                const reportShouldBeDeleted: boolean = await this.dialog
                    .open(ConfirmDialogComponent, {
                        data: {
                            heading: 'Rechnung bereits erstellt',
                            content: `
                            Für dieses Gutachten ist bereits eine Rechnung erstellt (${
                                report.feeCalculation.invoiceParameters.number
                            }).
                            ${
                                invoice
                                    ? 'Diese ist bereits abgeschlossen und kann nur von einem Administrator gelöscht werden.'
                                    : 'Du hast nicht ausreichend Berechtigungen, um diese Rechnung zu sehen oder sie existiert nicht mehr.'
                            }
                            \n
                            Willst du das Gutachten trotzdem löschen?
                        `,
                            confirmLabel: 'Gutachten löschen',
                            cancelLabel: 'Doch lieber nicht',
                            confirmColorRed: true,
                        },
                        maxWidth: '700px',
                    })
                    .afterClosed()
                    .toPromise();
                if (!reportShouldBeDeleted) {
                    return;
                }
            }
        } else if (hasInvoiceNumber) {
            // Ask - invoice number was already generated but no invoice
            const reportShouldBeDeleted: boolean = await this.dialog
                .open(ConfirmDialogComponent, {
                    data: {
                        heading: 'Rechnungsnummer bereits vergeben',
                        content: `Für dieses Gutachten ist bereits eine Rechnungsnummer vergeben (${report.feeCalculation.invoiceParameters.number}). Wenn du das Gutachten löschst, wird die Rechnungsnummer von autoiXpert nicht automatisch erneut vergeben.`,
                        confirmLabel: 'Gutachten löschen',
                        cancelLabel: 'Doch lieber behalten',
                        confirmColorRed: true,
                    },
                    maxWidth: '700px',
                })
                .afterClosed()
                .toPromise();
            if (!reportShouldBeDeleted) {
                return;
            }
        }

        /**
         * Ask the user to automatically reset the report token or invoice number counter, if this report
         * has a report token (likely increased the token counter) and is the latest report and no invoice
         * has been written yet (not locked).
         */
        if (report.token && !report.lockedAt && this.networkStatusService.isOnline()) {
            if (this.reportTokenService.isInvoiceNumberSyncedAndLeading(report)) {
                // If invoice number is synced and leading -> reset invoice number counter
                const isLatestInvoiceNumber = await this.invoiceNumberService.isInvoiceNumberLatest(report);

                if (isLatestInvoiceNumber) {
                    // Ask user to reset the report token and invoice number counter
                    const decision = await this.dialog
                        .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                            data: {
                                heading: 'Rechnungszähler zurücksetzen?',
                                content:
                                    'Für das gelöschte Gutachten wurden bereits Aktenzeichen und Rechnungsnummer gezogen.<br> Möchtest du den Rechnungszähler um eins verringern?',
                                confirmLabel: 'Zurücksetzen',
                                cancelLabel: 'Abbrechen',
                                confirmColorRed: false,
                            },
                        })
                        .afterClosed()
                        .toPromise();
                    if (decision) {
                        const counterId = this.invoiceNumberService.getInvoiceNumberConfig(report.officeLocationId)._id;
                        await this.invoiceNumberOrReportTokenCounterService.decreaseCountByOne(counterId);

                        this.toastService.success(
                            'Rechnungszähler zurückgesetzt',
                            'Der Rechnungszähler wurde um eins verringert.',
                        );
                    }
                }
            } else {
                // Reset report token counter
                const isLatestReportToken = await this.reportTokenService.isReportTokenLatest(report);

                if (isLatestReportToken) {
                    // Ask user to reset the report token counter
                    const decision = await this.dialog
                        .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                            data: {
                                heading: 'Aktenzeichenzähler zurücksetzen?',
                                content:
                                    'Für das gelöschte Gutachten wurde bereits ein Aktenzeichen gezogen.<br> Möchtest du den Aktenzeichenzähler um eins verringern?',
                                confirmLabel: 'Zurücksetzen',
                                cancelLabel: 'Abbrechen',
                                confirmColorRed: false,
                            },
                        })
                        .afterClosed()
                        .toPromise();

                    if (decision) {
                        const counterId = this.reportTokenService.getReportTokenConfig(report.officeLocationId)._id;
                        await this.invoiceNumberOrReportTokenCounterService.decreaseCountByOne(counterId);

                        this.toastService.success(
                            'Aktenzeichenzähler zurückgesetzt',
                            'Der Aktenzeichenzähler wurde um eins verringert.',
                        );
                    }
                }
            }
        }

        /**
         * If this is an amendment report, remove the reference from the original report.
         */
        if (report.originalReportId) {
            // Get the report that this report is an amendment to.
            let originalReport: Report;
            try {
                originalReport = await this.reportService.get(report.originalReportId);
            } catch (error) {
                console.error('Error getting the original report when moving an amendment report to trash.');
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Original-Gutachten nicht verfügbar',
                        body: 'Das Original-Gutachten zu diesem Nachtragsgutachten konnte nicht geladen werden. Die Referenz zu diesem Gutachten konnte deshalb nicht entfernt werden.',
                        toastType: 'warn',
                    },
                });
            }

            if (originalReport) {
                originalReport.amendmentReportId = null;

                // Save original report.
                try {
                    await this.reportService.put(originalReport);
                } catch (error) {
                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {},
                        defaultHandler: {
                            title: 'Original-Gutachten nicht aktualisiert',
                            body: 'Die Referenz vom Original-Gutachten zu diesem Nachtragsgutachten konnte nicht entfernt werden.',
                            toastType: 'warn',
                        },
                    });
                }
            }
        }

        // Move the current report to the trash.
        moveReportToTrash(report);
        this.saveReport(report);

        this.removeReportFromOpenOrDoneArray(report);
        this.deletedReports.push(report);
        this.filterAndSortReports();

        // Allow restoring report.
        const infoToast = this.toastService.info(
            `In Papierkorb verschoben`,
            `Klicke, um das Gutachten wiederherzustellen.`,
            { showProgressBar: true, timeOut: 10000 },
        ); //RestorationToast
        infoToast.click.subscribe(() => {
            this.restoreReportFromTrash(report);
        });
    }

    //*****************************************************************************
    //  Report List Export
    //****************************************************************************/

    public async exportReportsAsCSV() {
        if (!this.networkStatusService.isOnline()) {
            this.toastService.offline(
                'Offline nicht verfügbar',
                'Der Rechnungsexport ist verfügbar, sobald du wieder online bist.',
            );
            return;
        }

        this.reportExportPending = true;

        this.httpClient
            .get(`/api/v0/exports/reports`, {
                observe: 'response',
                responseType: 'blob',
            })
            .subscribe({
                next: (response: HttpResponse<Blob>) => {
                    this.downloadService.downloadBlobResponseWithHeaders(response);
                    this.reportExportPending = false;
                },
                error: (error) => {
                    this.reportExportPending = false;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        defaultHandler: {
                            title: 'Gutachten-Export gescheitert',
                            body: 'Bitte kontaktiere den <a href="/Hilfe" target="_blank">autoiXpert-Support</a>.',
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report List Export
    /////////////////////////////////////////////////////////////////////////////*/

    public async restoreReportFromTrash(report: Report): Promise<void> {
        //*****************************************************************************
        //  Amendment Report
        //****************************************************************************/
        /**
         * If this is an amendment report, add the reference back to the original report.
         */
        if (report.originalReportId) {
            let originalReport: Report;
            try {
                originalReport = await this.reportService.get(report.originalReportId);
                originalReport.amendmentReportId = report._id;
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Original-Gutachten nicht verfügbar',
                        body: 'Das Original-Gutachten zu diesem Nachtragsgutachten konnte nicht geladen werden. Die Referenz zu diesem Gutachten konnte deshalb nicht wiederhergestellt werden.<br><br>Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                    },
                });
            }

            // Save original report.
            try {
                await this.reportService.put(originalReport);
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Original-Gutachten nicht aktualisiert',
                        body: 'Die Referenz vom Original-Gutachten zu diesem Nachtragsgutachten konnte nicht wiederhergestellt werden.',
                    },
                });
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Amendment Report
        /////////////////////////////////////////////////////////////////////////////*/

        /**
         * Move the current report out of the trash.
         */
        restoreReportFromTrash(report);
        // Always add the restored report to openReports, because they are more likely to appear in the users viewport
        this.openReports.push(report);

        /**
         * Remove report from list of deleted reports.
         */
        removeFromArrayById(report._id, this.deletedReports);

        this.saveReport(report);

        this.filterAndSortReports();
        this.toastService.success(`Gutachten wiederhergestellt`);
    }

    public toggleTrashView(): void {
        this.showTrash = !this.showTrash;
        this.screenTitleService.setScreenTitle({
            screenTitle: this.showTrash ? 'Papierkorb - Meine Gutachten' : 'Meine Gutachten',
        });
        this.refreshReports();
    }

    /**
     * Remove report from list and delete it on the server.
     */
    public async deleteReportPermanently(report: Report): Promise<void> {
        this.selectedReport = null;
        this.associatedInvoiceIdsOfSelectedReport = [];
        removeFromArrayById(report._id, this.deletedReports);

        try {
            await this.reportService.delete(report._id);
        } catch (error) {
            this.filterAndSortReports();

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Löschen fehlgeschlagen',
                    body: 'Versuche es erneut. Sollte das Gutachten weiterhin nicht löschbar sein, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }

        this.toastService.success(
            'Gutachten endgültig gelöscht',
            report.token ? `Gutachten Nr. ${report.token} unwiderruflich gelöscht` : null,
        );

        // Update the UI to remove the deleted report from the list.
        this.filterAndSortReports();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Trash
    /////////////////////////////////////////////////////////////////////////////*/

    private removeReportFromOpenOrDoneArray(report: Report): Report[] {
        // Look for the report in both groups
        const indexInOpenReports = this.openReports.findIndex((openReport) => openReport._id === report._id);
        const indexInDoneReports = this.doneReports.findIndex((doneReport) => doneReport._id === report._id);

        if (indexInOpenReports !== -1) {
            this.openReports.splice(indexInOpenReports, 1);
            return this.openReports;
        } else if (indexInDoneReports !== -1) {
            this.doneReports.splice(indexInDoneReports, 1);
            return this.doneReports;
        } else {
            throw new Error('REPORT_EXISTS_IN_NEITHER_GROUP');
        }
    }

    /**
     * Navigate to another route. Configured to take routes relative to the current route.
     *
     * @param urlParts
     */
    public navigate(urlParts: any[]) {
        this.router.navigate(urlParts, { relativeTo: this.route });
    }

    //*****************************************************************************
    //  Translations
    //****************************************************************************/
    public translateReportTabName(tabName: ReportTabName): string {
        return translateReportTabName(tabName, this.selectedReport.type);
    }

    public getLinkSectionByReportTabName(tabName: ReportTabName): string {
        return getLinkFragmentForReportTabName(tabName);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Translations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter & Sort
    //****************************************************************************/

    public selectSortStrategy(strategy: UserPreferences['sortReportListBy']): void {
        this.userPreferences.sortReportListBy = strategy;
        // The reports loaded so far are not necessarily in the right order, i.e. the right reports to be loaded first in the current order.
        this.resetLoadHistoryAndReloadReports();
    }

    public selectQuickFilter(quickFilter: ReportListQuickFilterType) {
        this.userPreferences.reportListQuickFilter = quickFilter;

        // The report type property is irrelevant for any filters except the report type quick filter.
        if (quickFilter !== 'reportType') {
            this.userPreferences.reportListQuickFilterReportType = null;
        }
        if (quickFilter !== 'involvedUser') {
            this.userPreferences.reportListQuickFilterAssessorIds = [];
        }
        // Clear the selected labels when the label filter gets deselected
        if (quickFilter !== 'labels') {
            this.userPreferences.reportList_labelsForFilter = [];
        }

        this.resetLoadHistoryAndReloadReports();
    }

    public selectQuickFilterReportType(reportType: ReportType) {
        this.userPreferences.reportListQuickFilterReportType = reportType;
    }

    public toggleSortDirection(): void {
        this.userPreferences.sortReportListDescending = !this.userPreferences.sortReportListDescending;
        this.resetLoadHistoryAndReloadReports();
    }

    /**
     * When a filter or sort is changed, reset the done reports.
     * If the client is online, we do not load reports from the IndexedDB or the cache to avoid reports to 'hang' at the end of the list.
     * Reports 'hang' at the end of the list, if they are returned from the indexedDB but are further back in the sorted report list on the server.
     */
    public async resetLoadHistoryAndReloadReports() {
        // Reset pagination to start with a fresh load.
        this.lastReportPaginationTokenFromServer = null;
        this.numberOfLoadedReports = 0;
        this.reportLimitReached = false;

        // Reset the done reports and their cache to ensure that the reports are reloaded from the top of the list.
        this.doneReports = [];

        // Do not reset the cache when searching, if the user comes back to the component, we display the cached reports and not the search results.
        // Same behavior for coming back from the trash view.
        if (!this.searchTerm && !this.showTrash) {
            this.reportService.updateDoneReportsCache([]);
        }

        await this.initialLoadReports();
    }

    /**
     * Rerun all sorting and filtering functions on the report set so that the report list visible to the user is updated.
     */
    public filterAndSortReports() {
        this.filteredOpenReports = [...this.openReports];
        this.filteredDoneReports = [...this.doneReports];
        this.filteredDeletedReports = [...this.deletedReports];

        this.filterReports();
        this.sortReports();
    }

    /**
     * Find reports that are matched by all search words supplied.
     * If the search term is empty, reset the searchResult to all reports.
     */
    private filterReports() {
        // Search Filter
        this.applySearchFilterToGroup(this.filteredOpenReports, 'filteredOpenReports');
        this.applySearchFilterToGroup(this.filteredDoneReports, 'filteredDoneReports');

        // Date Filter
        this.applyDateFilterToGroup(this.filteredOpenReports, 'filteredOpenReports');
        this.applyDateFilterToGroup(this.filteredDoneReports, 'filteredDoneReports');

        this.applyQuickFilter();
    }

    /**
     * Filter open and done reports locally by the selected quick filter.
     *
     * Server search is implemented separately.
     */
    private applyQuickFilter(): void {
        if (!this.userPreferences.reportListQuickFilter || this.userPreferences.reportListQuickFilter === 'none') {
            return;
        }

        switch (this.userPreferences.reportListQuickFilter) {
            case 'onlyOwn': {
                this.filteredOpenReports = this.filteredOpenReports.filter(
                    (report) => report.createdBy === this.user._id || report.responsibleAssessor === this.user._id,
                );
                this.filteredDoneReports = this.filteredDoneReports.filter(
                    (report) => report.createdBy === this.user._id || report.responsibleAssessor === this.user._id,
                );
                break;
            }
            case 'startedThisWeek': {
                const beginningOfTheWeek: Moment = moment().startOf('week');

                this.filteredOpenReports = this.filteredOpenReports.filter((report) =>
                    moment(report.createdAt).isAfter(beginningOfTheWeek),
                );
                this.filteredDoneReports = this.filteredDoneReports.filter((report) =>
                    moment(report.createdAt).isAfter(beginningOfTheWeek),
                );
                break;
            }
            case 'reportWithExpertStatement': {
                this.filteredOpenReports = this.filteredOpenReports.filter((report) => report.expertStatements?.length);
                this.filteredDoneReports = this.filteredDoneReports.filter((report) => report.expertStatements?.length);
                break;
            }
            case 'reportWithRepairConfirmation': {
                this.filteredOpenReports = this.filteredOpenReports.filter((report) => report.repairConfirmation);
                this.filteredDoneReports = this.filteredDoneReports.filter((report) => report.repairConfirmation);
                break;
            }
            case 'reportType': {
                this.filteredOpenReports = this.filteredOpenReports.filter(
                    (report) => report.type === this.userPreferences.reportListQuickFilterReportType,
                );
                this.filteredDoneReports = this.filteredDoneReports.filter(
                    (report) => report.type === this.userPreferences.reportListQuickFilterReportType,
                );
                break;
            }
            case 'involvedUser': {
                this.filteredOpenReports = this.filteredOpenReports.filter((report) =>
                    arrayIncludesAnyOfTheseValues({
                        array: this.userPreferences.reportListQuickFilterAssessorIds,
                        searchItems: [report.createdBy, report.responsibleAssessor],
                    }),
                );
                this.filteredDoneReports = this.filteredDoneReports.filter((report) =>
                    arrayIncludesAnyOfTheseValues({
                        array: this.userPreferences.reportListQuickFilterAssessorIds,
                        searchItems: [report.createdBy, report.responsibleAssessor],
                    }),
                );
                break;
            }
            case 'labels':
                const activeLabelNames = this.userPreferences.reportList_labelsForFilter;

                this.filteredOpenReports = this.filteredOpenReports.filter((report) => {
                    return report.labels.some((label) => activeLabelNames.includes(label.name));
                });

                this.filteredDoneReports = this.filteredDoneReports.filter((report) => {
                    return report.labels.some((label) => activeLabelNames.includes(label.name));
                });
                break;
            default:
                isNeverType(this.userPreferences.reportListQuickFilter);
        }
    }

    //*****************************************************************************
    //  Quick Filter - Labels
    //****************************************************************************/
    protected async loadLabelConfigs() {
        try {
            this.labelConfigs = await this.labelConfigService.find({ labelGroup: 'report' }).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Label-Konfigs konnten nicht geladen werden`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }

        this.labelConfigs.sort(sortByProperty(['dragOrderPosition', 'name']));
    }

    protected getLabelConfigByLabelName(labelName: Label['name']): LabelConfig {
        return this.labelConfigs.find((labelConfig) => labelConfig.name === labelName);
    }

    protected toggleSelectLabelConfigForFilter(labelName: Label['name']) {
        toggleValueInArray(labelName, this.userPreferences.reportList_labelsForFilter);

        // Assign the array to itself to trigger the save mechanism.
        this.userPreferences.reportList_labelsForFilter = [...this.userPreferences.reportList_labelsForFilter];

        // Select filter type and trigger search.
        if (this.userPreferences.reportList_labelsForFilter.length) {
            // Loading the search results anew must be performed on every label change.
            this.selectQuickFilter('labels');
        } else {
            this.selectQuickFilter('none');
        }
    }

    protected async selectedLabelsForReportChanged(): Promise<void> {
        await this.saveReport(this.selectedReport);

        if (this.userPreferences.reportList_labelsForFilter.length) {
            // If there is an active filter for labels, it might happen that the user removed a label
            // that was the only reason a report showed up in the filter and needs to be removed after the
            // removal of the label.
            this.filterAndSortReports();

            const reportIsStillInSearchResults = this.selectedReport.labels.some((label) =>
                this.userPreferences.reportList_labelsForFilter.includes(label.name),
            );

            if (!reportIsStillInSearchResults) {
                // In case the removal of a label caused the selected report to not show up in the results
                // anymore, we also need to clear the selection (otherwise it will still be displayed in the details pane)
                this.selectedReport = null;
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Quick Filter - Labels
    /////////////////////////////////////////////////////////////////////////////*/

    private applySearchFilterToGroup(reportGroup: Report[], nameOfReportGroup: string): void {
        if (!this.searchTerm) {
            return;
        }

        // Reset maps with match infos
        if (nameOfReportGroup === 'filteredDoneReports') {
            this.searchMatchesDoneReports = new WeakMap();
        } else {
            this.searchMatchesOpenReports = new WeakMap();
        }

        const searchTerms = this.searchTerm.trim().toLowerCase().split(' ');

        const searchResult: Report[] = reportGroup.filter((report) => {
            // Remember updating the tooltip on the search field
            const propertiesToBeSearched: ReportListSearchMatch[] = [
                {
                    name: 'Aktenzeichen',
                    value: report.token,
                },
                {
                    name: 'Anspruchsteller',
                    value: getContactPersonFullNameWithOrganization(report.claimant.contactPerson),
                },
                {
                    name: 'Anspruchsteller (Halter)',
                    value: report.ownerOfClaimantsCar
                        ? getContactPersonFullNameWithOrganization(report.ownerOfClaimantsCar.contactPerson)
                        : null,
                },
                {
                    name: 'Anspruchsteller Telefon',
                    value: (report.claimant.contactPerson.phone || '').replace(/\s\(\)\/-/, ''),
                },
                {
                    name: 'Anspruchsteller E-Mail',
                    value: report.claimant.contactPerson.email,
                },
                {
                    name: 'Anspruchsteller Aktenzeichen',
                    value: report.claimant.caseNumber,
                },
                {
                    name: 'Werkstatt',
                    value: report.garage?.contactPerson.organization, // The garage does not have to exist
                },
                {
                    name: 'Anwalt',
                    value: report.lawyer ? getContactPersonFullNameWithOrganization(report.lawyer.contactPerson) : null, // The lawyer does not have to exist
                },
                {
                    name: 'Anwalt Aktenzeichen',
                    value: report.lawyer?.caseNumber,
                },
                {
                    name: 'Versicherung',
                    value: report.insurance?.contactPerson.organization,
                },
                {
                    name: 'Versicherungsschein',
                    value: report.authorOfDamage?.insuranceNumber,
                },
                {
                    name: 'Schadennummer',
                    value: report.insurance?.caseNumber,
                },
                {
                    name: 'Kennzeichen',
                    value: report.car.licensePlate,
                },
                {
                    name: 'VIN',
                    value: report.car.vin,
                },
                {
                    name: 'Hersteller',
                    value: report.car.make,
                },
                {
                    name: 'Modell',
                    value: report.car.model,
                },
                {
                    name: 'Unfallgegner',
                    value: report.authorOfDamage
                        ? getContactPersonFullNameWithOrganization(report.authorOfDamage.contactPerson)
                        : null,
                },
                {
                    name: 'Unfallgegner (Halter)',
                    value: report.ownerOfAuthorOfDamagesCar
                        ? getContactPersonFullNameWithOrganization(report.ownerOfAuthorOfDamagesCar.contactPerson)
                        : null,
                },
                {
                    name: 'Fahrzeugfarbe',
                    value: report.car.paintColor,
                },
                {
                    name: 'Rechnungs-Nr.',
                    value: report.feeCalculation.invoiceParameters.number,
                },
                {
                    name: 'RB Rechnungs-Nr.',
                    value: report.repairConfirmation?.invoiceParameters.number,
                },
                {
                    name: 'SN Rechnungs-Nr.',
                    value: report.expertStatements?.at(-1)?.invoiceParameters.number,
                },
            ];

            const matchInSearchTerm = searchTerms.every((searchTerm) => {
                const searchMatchesMap =
                    nameOfReportGroup === 'filteredDoneReports'
                        ? this.searchMatchesDoneReports
                        : this.searchMatchesOpenReports;
                const searchMatches: ReportListSearchMatch[] = [];

                propertiesToBeSearched.forEach((propertyToBeSearched) => {
                    if (propertyToBeSearched.value && propertyToBeSearched.value.toLowerCase().includes(searchTerm)) {
                        // Remember what properties matched for each report group.
                        searchMatches.push(propertyToBeSearched);
                    }
                });

                // If at least one match occurred, add them to the match Set
                if (searchMatches.length) {
                    // Initialize Set
                    if (!searchMatchesMap.has(report)) {
                        searchMatchesMap.set(report, []);
                    }

                    // Add new matches
                    const existingMatches = searchMatchesMap.get(report);
                    for (const match of searchMatches) {
                        if (!existingMatches.includes(match)) {
                            existingMatches.push(match);
                        }
                    }
                }

                return !!searchMatches.length;
            });

            // If Atlas Search classified a report as a match, we display it even if we do not identify the matches in the frontend.
            // Atlas Search sometimes returns more results, e.g. Şahin if searching for Sahin. The frontend search does not find this since we do not convert non-latin characters to ASCII (e.g. Ş -> S).
            return this.atlasSearchMatches.has(report._id) || matchInSearchTerm;
        });

        // Assign the search result to the respective reportGroup
        if (nameOfReportGroup === 'filteredDoneReports') {
            this.filteredDoneReports = searchResult;
        } else {
            this.filteredOpenReports = searchResult;
        }
    }

    private applyDateFilterToGroup(reportGroup: Report[], nameOfReportGroup: string): void {
        if (!this.dateLimitFrom && !this.dateLimitTo) {
            return;
        }

        const searchResult: Report[] = reportGroup.filter((report) => {
            const dateToCheck: Moment = moment(report.state === 'done' ? report.completionDate : report.createdAt);
            const lowerDateLimit: Moment = moment(this.dateLimitFrom).startOf('day');
            const upperDateLimit: Moment = moment(this.dateLimitTo).endOf('day');

            if (this.dateLimitFrom && this.dateLimitTo) {
                return dateToCheck.isAfter(lowerDateLimit) && dateToCheck.isBefore(upperDateLimit);
            }

            if (this.dateLimitFrom) {
                return dateToCheck.isAfter(lowerDateLimit);
            }

            if (this.dateLimitTo) {
                return dateToCheck.isBefore(upperDateLimit);
            }
        });

        // Assign the search result to the respective reportGroup
        if (nameOfReportGroup === 'filteredDoneReports') {
            this.filteredDoneReports = searchResult;
        } else {
            this.filteredOpenReports = searchResult;
        }
    }

    public sortReports() {
        this.sortReportGroup(this.filteredOpenReports);
        this.sortReportGroup(this.filteredDoneReports);
        this.sortReportGroup(this.filteredDeletedReports);
    }

    /**
     * Sort the reports array. Used for the sort dropdown in the view.
     *
     * For each value provided by the dropdown, there is a defined sorting chain by which the list is sorted.
     * @param reportGroup
     */
    private sortReportGroup(reportGroup: Report[]): void {
        const sortStrategy = this.userPreferences.sortReportListBy ?? 'createdAt';
        const sortAscending = this.userPreferences.sortReportListDescending ? -1 : 1;

        if (sortStrategy === 'name') {
            reportGroup.sort((reportA, reportB) => {
                const contactA = reportA.claimant.contactPerson;
                const contactB = reportB.claimant.contactPerson;

                // First, sort by "lastName", then by "firstName"
                const lastNameA = contactA.lastName || '';
                const lastNameB = contactB.lastName || '';

                // First, sort by last name
                if (lastNameA !== lastNameB) {
                    return lastNameA.localeCompare(lastNameB) * sortAscending;
                }

                // If last names are equal, sort by first name
                if (contactA.firstName !== contactB.firstName) {
                    return (contactA.firstName || '').localeCompare(contactB.firstName || '') * sortAscending;
                }

                // If first names are equal (or neither first- nor lastname are set), sort by organization
                return (contactA.organization || '').localeCompare(contactB.organization || '') * sortAscending;
            });
        }

        //*****************************************************************************
        //  Dates
        //****************************************************************************/
        if (sortStrategy === 'automaticDate') {
            reportGroup.sort((reportA, reportB) => {
                let dateA: Moment;
                let dateB: Moment;

                // Only need to check either report
                if (reportA.state === 'done') {
                    dateA = moment(reportA.completionDate);
                    dateB = moment(reportB.completionDate);
                } else {
                    dateA = moment(reportA.createdAt);
                    dateB = moment(reportB.createdAt);
                }

                if (dateA.isAfter(dateB)) return sortAscending;
                if (dateA.isBefore(dateB)) return -sortAscending;

                const nameA =
                    (reportA.claimant.contactPerson.lastName || '') + (reportA.claimant.contactPerson.firstName || '');
                const nameB =
                    (reportB.claimant.contactPerson.lastName || '') + (reportB.claimant.contactPerson.firstName || '');
                // If equal, compare name
                return nameA.localeCompare(nameB) * sortAscending;
            });
        }

        if (sortStrategy === 'createdAt') {
            reportGroup.sort((reportA, reportB) => {
                const dateA: Moment = moment(reportA.createdAt);
                const dateB: Moment = moment(reportB.createdAt);

                if (dateA.isAfter(dateB)) return sortAscending;
                if (dateA.isBefore(dateB)) return -sortAscending;

                const nameA =
                    (reportA.claimant.contactPerson.lastName || '') + (reportA.claimant.contactPerson.firstName || '');
                const nameB =
                    (reportB.claimant.contactPerson.lastName || '') + (reportB.claimant.contactPerson.firstName || '');
                // If equal, compare name
                return nameA.localeCompare(nameB) * sortAscending;
            });
        }

        if (sortStrategy === 'updatedAt') {
            reportGroup.sort((reportA, reportB) => {
                const dateA: Moment = moment(reportA.updatedAt);
                const dateB: Moment = moment(reportB.updatedAt);

                if (dateA.isAfter(dateB)) return sortAscending;
                if (dateA.isBefore(dateB)) return -sortAscending;

                const nameA =
                    (reportA.claimant.contactPerson.lastName || '') + (reportA.claimant.contactPerson.firstName || '');
                const nameB =
                    (reportB.claimant.contactPerson.lastName || '') + (reportB.claimant.contactPerson.firstName || '');
                // If equal, compare name
                return nameA.localeCompare(nameB) * sortAscending;
            });
        }

        if (sortStrategy === 'orderDate') {
            reportGroup.sort((reportA, reportB) => {
                if (reportA.orderDate && reportB.orderDate) {
                    const dateA: Moment = moment(reportA.orderDate);
                    const dateB: Moment = moment(reportB.orderDate);

                    if (dateA.isAfter(dateB)) return sortAscending;
                    if (dateA.isBefore(dateB)) return -sortAscending;

                    const nameA =
                        (reportA.claimant.contactPerson.lastName || '') +
                        (reportA.claimant.contactPerson.firstName || '');
                    const nameB =
                        (reportB.claimant.contactPerson.lastName || '') +
                        (reportB.claimant.contactPerson.firstName || '');
                    // If equal, compare name
                    return nameA.localeCompare(nameB) * sortAscending;
                } else if (!reportA.orderDate && reportB.orderDate) {
                    return -sortAscending;
                } else if (reportA.orderDate && !reportB.orderDate) {
                    return sortAscending;
                }
            });
        }

        if (sortStrategy === 'accidentDate') {
            reportGroup.sort((reportA, reportB) => {
                if (reportA.accident?.date && reportB.accident?.date) {
                    const dateA: Moment = moment(reportA.accident.date);
                    const dateB: Moment = moment(reportB.accident.date);

                    if (dateA.isAfter(dateB)) return sortAscending;
                    if (dateA.isBefore(dateB)) return -sortAscending;

                    const nameA =
                        (reportA.claimant.contactPerson.lastName || '') +
                        (reportA.claimant.contactPerson.firstName || '');
                    const nameB =
                        (reportB.claimant.contactPerson.lastName || '') +
                        (reportB.claimant.contactPerson.firstName || '');
                    // If equal, compare name
                    return nameA.localeCompare(nameB) * sortAscending;
                } else if (!reportA.accident?.date && reportB.accident?.date) {
                    return -sortAscending;
                } else if (reportA.accident?.date && !reportB.accident?.date) {
                    return sortAscending;
                }
            });
        }

        if (sortStrategy === 'firstVisitDate') {
            reportGroup.sort((reportA, reportB) => {
                if (reportA.visits?.[0]?.date && reportB.visits?.[0]?.date) {
                    const dateA: Moment = moment(reportA.visits[0].date);
                    const dateB: Moment = moment(reportB.visits[0].date);

                    if (dateA.isAfter(dateB)) return sortAscending;
                    if (dateA.isBefore(dateB)) return -sortAscending;

                    const nameA =
                        (reportA.claimant.contactPerson.lastName || '') +
                        (reportA.claimant.contactPerson.firstName || '');
                    const nameB =
                        (reportB.claimant.contactPerson.lastName || '') +
                        (reportB.claimant.contactPerson.firstName || '');
                    // If equal, compare name
                    return nameA.localeCompare(nameB) * sortAscending;
                } else if (!reportA.visits?.[0]?.date && reportB.visits?.[0]?.date) {
                    return -sortAscending;
                } else if (reportA.visits?.[0]?.date && !reportB.visits?.[0]?.date) {
                    return sortAscending;
                }
            });
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Dates
        /////////////////////////////////////////////////////////////////////////////*/

        if (sortStrategy === 'licensePlate') {
            reportGroup.sort((reportA, reportB) => {
                const licensePlateA = (reportA.car.licensePlate || '').toLowerCase();
                const licensePlateB = (reportB.car.licensePlate || '').toLowerCase();
                const lastNameA = reportA.claimant.contactPerson.lastName || '';
                const lastNameB = reportB.claimant.contactPerson.lastName || '';

                const comparisonResult = licensePlateA.localeCompare(licensePlateB);

                // If equal, compare names
                if (comparisonResult === 0) {
                    return lastNameA.localeCompare(lastNameB) * sortAscending;
                }
                return comparisonResult * sortAscending;
            });
        }

        if (sortStrategy === 'token') {
            reportGroup.sort((reportA, reportB) => {
                if (reportA.token === null && reportB.token !== null) return -1;
                if (reportA.token !== null && reportB.token === null) return 1;

                const tokenA = (reportA.token || '').toLowerCase();
                const tokenB = (reportB.token || '').toLowerCase();

                const comparisonResult = tokenA.localeCompare(tokenB);

                return comparisonResult * sortAscending;
            });
        }

        if (sortStrategy === 'carBrand') {
            reportGroup.sort((reportA, reportB) => {
                const carMakeAndModelA = (reportA.car.make || '').concat(reportA.car.model || '').toLowerCase();
                const carMakeAndModelB = (reportB.car.make || '').concat(reportB.car.model || '').toLowerCase();
                const lastNameA = reportA.claimant.contactPerson.lastName || '';
                const lastNameB = reportB.claimant.contactPerson.lastName || '';

                const comparisonResult = carMakeAndModelA.localeCompare(carMakeAndModelB);

                // If equal, compare names
                if (comparisonResult === 0) {
                    return lastNameA.localeCompare(lastNameB) * sortAscending;
                }
                return comparisonResult * sortAscending;
            });
        }
    }

    public updateSearchTerm(): void {
        this.searchTerm$.next(this.searchTerm);
    }

    /**
     * Subscribe to the stream of search terms.
     *
     * The subscription handles filters about the search term, debouncing and triggers the reload of reports.
     */
    private subscribeToSearchTermChanges() {
        const searchTerm$Subscription = this.searchTerm$
            .pipe(
                /**
                 * Ensure that a search term has 3 or more characters. Otherwise, searches like "a" are so unspecific
                 * that our backend server collapses under the load because for large customer accounts, thousand of reports
                 * are loaded into memory.
                 *
                 * Three characters allow for searches like "BMW" oder "Audi".
                 *
                 * Also allow searches if there are two or more search words separated by space. That allows to search for license plates
                 * like "PB SL".
                 */
                filter((searchTerm) => {
                    if (!searchTerm) {
                        this.resetLoadHistoryAndReloadReports();
                        return;
                    }
                    if (typeof searchTerm !== 'string') {
                        return;
                    }

                    // Prevent strings like "PB " or "PB  T " to count as multiple search terms.
                    const searchTermParts = searchTerm
                        .trim()
                        .split(/(?:-| )+/)
                        .filter((searchTerm) => !!searchTerm.trim());
                    return (
                        // Search if there are at least two search terms or one search term with at least 3 characters.
                        // This allows searching for license plates with two short tokens *e.g. "PB SL".
                        searchTermParts.some((searchTermPart) => searchTermPart.length >= 3) ||
                        searchTermParts.length > 1
                    );
                }),
                map((searchTerm) => searchTerm.trim()),
            )
            .pipe(debounceTime(300))
            .subscribe(() => {
                this.atlasSearchMatches.clear();
                this.lastReportPaginationTokenFromServer = null;
                return this.loadMoreDoneReports$$.next({
                    filterAndSortParams: this.getFilterForLoadMoreDoneReports(),
                    searchTerm: this.searchTerm,
                });
            });

        this.subscriptions.push(searchTerm$Subscription);
    }

    public toggleDateLimitInputs(): void {
        if (this.dateLimitInputsShown) {
            this.dateLimitFrom = null;
            this.dateLimitTo = null;
            this.updateDateLimitFrom(null);
            this.updateDateLimitTo(null);
            this.filterAndSortReports();
        }

        this.dateLimitInputsShown = !this.dateLimitInputsShown;
    }

    public updateDateLimitFrom(date: string): void {
        this.dateLimitFrom$.next(date);
    }

    public updateDateLimitTo(date: string): void {
        this.dateLimitTo$.next(date);
    }

    //*****************************************************************************
    //  Handle Server Search
    //****************************************************************************/
    /**
     * Every time the search term changes, trigger a server search.
     *
     * Subscribe to the stream of search terms and trigger a search on the server.
     */

    /**
     * Add a report to the list that corresponds to its status. Prevent duplicates.
     *
     * @param {Report} report
     */
    private addReportToList(report: Report): void {
        let targetCollection: Report[];

        // If reports are synced, exclude deleted records when sorting into groups.
        if (report.state === 'deleted') return;

        if (report.state === 'done') {
            targetCollection = this.doneReports;
        } else {
            targetCollection = this.openReports;
        }

        // If the report already exists, skip it
        if (targetCollection.find((existingReport) => existingReport._id === report._id)) {
            return;
        }

        targetCollection.push(report);
    }

    /**
     * Returns all reports that don't yet exist in the collection they would go into.
     * @returns {Report[]}
     */
    private filterOutExistingDoneReports(reports: Report[]): Report[] {
        return reports.filter((newReport) => {
            // Only include new reports without a matching ID
            return !this.doneReports.find((existingReport) => existingReport._id === newReport._id);
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Server Search
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  END Filter & Sort
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Make a report the selected report. Used for selection highlighting.
     * @param report
     */
    public selectReport(report: Report) {
        this.selectedReport = report;

        if (this.userPreferences.showReportDetailsPane) {
            this.loadAssociatedInvoices();
        }
    }

    public getUserFullName(userId: string) {
        return this.userService.getTeamMembersName(userId);
    }

    //*****************************************************************************
    //  Retrieve Report Data
    //****************************************************************************/
    /**
     * Sync all visible reports, reset the load counter and load all open and the latest done reports.
     */
    public async refreshReports() {
        this.reportRefreshInProgress = true;

        if (!this.networkStatusService.isOnline()) {
            const networkStatus: NetworkStatus = await this.networkStatusService.detectNetworkStatus();
            if (networkStatus.status === 'offline') {
                this.toastService.offline(
                    'Offline nicht verfügbar',
                    'Gutachten können erst wieder aktualisiert werden, wenn du eine Verbindung zu den autoiXpert Servern hast.',
                );
                this.reportRefreshInProgress = false;
                return;
            }
        }

        try {
            await this.syncAllLoadedReports();
            await this.resetLoadHistoryAndReloadReports();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Gutachten nicht aktualisiert',
                    body: 'Die Gutachten konnten nicht aktualisiert werden.',
                },
            });
        }
        this.reportRefreshInProgress = false;
    }

    private async initialLoadReports() {
        if (this.showTrash) {
            try {
                await this.getAllDeletedReports$().toPromise();
                this.reportsLoaded = true;
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Gelöschte Gutachten konnten nicht geladen werden',
                        body: '',
                    },
                });
            }
        } else {
            try {
                await Promise.all([this.getAllOpenReports$().toPromise(), this.initializeDoneReportsFromCache()]);
                this.reportsLoaded = true;
            } catch (error) {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Gutachten konnten nicht geladen werden',
                        body: '',
                    },
                });
            }
        }

        /**
         * As soon as all reports are loaded, listen for changes to them.
         */
        this.registerWebsocketEventHandlers();
    }

    //*****************************************************************************
    //  Load Done Reports
    //****************************************************************************/

    /**
     * The component displays 5 done reports from in-memory-cache for fast user feedback.
     * If the In-Memory-Cache is empty, the first 5 reports are loaded from the server.

     * Loading additional reports from Server is done when the user scrolls to the bottom of the list.
     */
    async initializeDoneReportsFromCache(): Promise<void> {
        const reportsInCache = this.reportService.getDoneReportsFromCache();
        if (reportsInCache.length > 0) {
            this.doneReports = reportsInCache;
            return;
        }

        // After logging in, the cache is empty. Load the first reports from the server.
        console.log('No done reports in cache. Loading first reports from server or IndexedDB.');
        this.loadMoreDoneReports$$.next({
            numberOfItemsToLoad: 5,
            filterAndSortParams: this.getFilterForLoadMoreDoneReports(),
        });
    }

    /**
     * Trigger to load more done reports, e.g. on scroll or on button click
     */
    async triggerLoadMoreDoneReports() {
        if (this.allReportsLoadedWithCurrentFilters) {
            this.toastService.info('Keine weiteren Gutachten', 'Alle verfügbaren Gutachten wurden geladen.');
            return;
        }
        this.loadMoreDoneReports$$.next({
            filterAndSortParams: this.getFilterForLoadMoreDoneReports(),
            searchTerm: this.searchTerm,
            searchAfterNumberOfElements: this.numberOfLoadedReports,
            searchAfterPaginationToken: this.lastReportPaginationTokenFromServer,
        });
    }

    private subscribeToLoadMoreDoneReports() {
        const loadMoreDoneReportsSubscription = this.loadMoreDoneReports$$
            .pipe(
                // Only trigger a load if the parameters have changed.
                // This is required since the scroll observer may trigger multiple times with the same parameters.
                // It is important to have the filters and sort params in the payload to cancel the request if the user changes the filter or sort.
                // Since distinctUntilChanged may hold a reference to objects or arrays, we need to deep copy the payload in order to compare the values.
                map((payload) => JSON.parse(JSON.stringify(payload))),
                distinctUntilChanged(isEqual),
                switchMap(async (payload) => {
                    const numberOfReportsToLoad = payload.numberOfItemsToLoad || 15;
                    const isInitialLoad = !this.numberOfLoadedReports;
                    let loadedReports: Report[] = [];
                    this.isLoadMoreDoneReportsPending = true;

                    /**
                     * Load reports from server (if online) or from IndexedDB (if offline).
                     */
                    try {
                        const { records, lastPaginationToken } =
                            await this.reportService.getDoneReportsFromServerOrIndexedDB({
                                searchTerm: payload.searchTerm,
                                searchAfterPaginationToken: payload.searchAfterPaginationToken,
                                skip: payload.searchAfterNumberOfElements || 0,
                                limit: numberOfReportsToLoad,
                                filterAndSortParams: payload.filterAndSortParams,
                            });
                        loadedReports = records;

                        // Update pagination of component
                        this.lastReportPaginationTokenFromServer = lastPaginationToken;
                        this.numberOfLoadedReports += loadedReports.length;
                    } catch (error) {
                        this.apiErrorService.handleAndRethrow({
                            axError: error,
                            handlers: {
                                INVALID_DATE_RANGE: {
                                    title: 'Ungültiger Datumsfilter',
                                    body: 'Stelle sicher, dass der Datumsfilter so eingestellt ist, dass das Enddatum nach dem Startdatum liegt.',
                                },
                            },
                            defaultHandler: {
                                title: 'Gutachten nicht geladen',
                                body: 'Die Gutachten konnten nicht vom Server oder der lokalen Datenbank geladen werden.',
                            },
                        });
                    }
                    this.isLoadMoreDoneReportsPending = false;
                    return { loadedReports, isInitialLoad, numberOfReportsToLoad, searchTerm: payload.searchTerm };
                }),
            )
            .subscribe(({ loadedReports, isInitialLoad, numberOfReportsToLoad, searchTerm }) => {
                // If there are less reports than the limit, we have reached the end of the list.
                this.allReportsLoadedWithCurrentFilters = loadedReports.length < numberOfReportsToLoad;

                if (loadedReports.length === 0) {
                    return;
                }

                // If the request was for a search term, add the report ids to the search matches.
                if (searchTerm) {
                    loadedReports.forEach((report) => {
                        this.atlasSearchMatches.add(report._id);
                    });
                }

                // If this is the first load, update the in-memory cache with the loaded reports.
                if (isInitialLoad && !searchTerm) {
                    this.doneReports = loadedReports;
                    if (!this.searchTerm) {
                        this.reportService.updateDoneReportsCache(loadedReports.slice(0, 5));
                    }
                } else {
                    // Append all new reports to the list.
                    const doneReportsWithoutDuplicates: Report[] = this.filterOutExistingDoneReports(loadedReports);
                    this.doneReports.push(...doneReportsWithoutDuplicates);
                }
                this.filterAndSortReports();
            });

        this.subscriptions.push(loadMoreDoneReportsSubscription);
    }

    getFilterForLoadMoreDoneReports(): ReportFilterAndSortParams {
        let involvedUsers: User['_id'][] = [];

        switch (this.userPreferences.reportListQuickFilter) {
            case 'onlyOwn':
                involvedUsers = [this.user._id];
                break;
            case 'involvedUser':
                involvedUsers = [...this.userPreferences.reportListQuickFilterAssessorIds];
                break;
        }

        return {
            involvedUsers,
            labels:
                this.userPreferences.reportListQuickFilter === 'labels'
                    ? this.userPreferences.reportList_labelsForFilter
                    : [],
            completedAfter: this.dateLimitFrom,
            completedBefore: this.dateLimitTo,
            mustHaveExpertStatement: this.userPreferences.reportListQuickFilter === 'reportWithExpertStatement',
            mustHaveRepairConfirmation: this.userPreferences.reportListQuickFilter === 'reportWithRepairConfirmation',
            reportType:
                this.userPreferences.reportListQuickFilter === 'reportType'
                    ? this.userPreferences.reportListQuickFilterReportType
                    : undefined,
            sortBy: this.userPreferences.sortReportListBy ?? 'automaticDate',
            sortDescending: this.userPreferences.sortReportListDescending || false,
        };
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Done Reports
    /////////////////////////////////////////////////////////////////////////////*/

    private getAllOpenReports$(): Observable<Report[]> {
        return this.reportService.getAllOpenReports().pipe(
            tap({
                next: (openReports: Report[]) => {
                    this.openReports = openReports;

                    this.filterAndSortReports();

                    // Reports in the IndexedDB may be open, although they were moved to trash on the server. Remove them from the local IndexedDB.
                    this.removeOutdatedOpenReportsFromIndexedDb();

                    // TODO: remove after 2025-04-14 once the migration is finished
                    // Remove properties that were not properly removed by frontend migration
                    this.openReports.forEach((report) => {
                        let modified = false;
                        if (typeof (report.claimant as any)?.documentEmails === 'object') {
                            delete (report.claimant as any).documentEmails;
                            modified = true;
                        }

                        if (typeof (report.claimant as any)?.standardEmails === 'object') {
                            delete (report.claimant as any).standardEmails;
                            modified = true;
                        }

                        if (typeof (report.garage as any)?.documentEmails === 'object') {
                            delete (report.garage as any).documentEmails;
                            modified = true;
                        }

                        if (typeof (report.garage as any)?.standardEmails === 'object') {
                            delete (report.garage as any).standardEmails;
                            modified = true;
                        }

                        if (typeof (report.insurance as any)?.documentEmails === 'object') {
                            delete (report.insurance as any).documentEmails;
                            modified = true;
                        }

                        if (typeof (report.insurance as any)?.standardEmails === 'object') {
                            delete (report.insurance as any).standardEmails;
                            modified = true;
                        }

                        if (typeof (report.lawyer as any)?.documentEmails === 'object') {
                            delete (report.lawyer as any).documentEmails;
                            modified = true;
                        }

                        if (typeof (report.lawyer as any)?.standardEmails === 'object') {
                            delete (report.lawyer as any).standardEmails;
                            modified = true;
                        }

                        if (typeof (report.leaseProvider as any)?.documentEmails === 'object') {
                            delete (report.leaseProvider as any).documentEmails;
                            modified = true;
                        }

                        if (typeof (report.leaseProvider as any)?.standardEmails === 'object') {
                            delete (report.leaseProvider as any).standardEmails;
                            modified = true;
                        }

                        if (typeof (report.seller as any)?.documentEmails === 'object') {
                            delete (report.seller as any).documentEmails;
                            modified = true;
                        }

                        if (typeof (report.seller as any)?.standardEmails === 'object') {
                            delete (report.seller as any).standardEmails;
                            modified = true;
                        }

                        for (const reportDocument of report.documents) {
                            if ((reportDocument as DocumentMetadata & { markAsDuplicate: any }).markAsDuplicate) {
                                delete (reportDocument as DocumentMetadata & { markAsDuplicate: any }).markAsDuplicate;
                                modified = true;
                            }
                        }

                        if (modified) {
                            this.reportService.put(report);
                        }
                    });
                },
            }),
        );
    }

    private getAllDeletedReports$(): Observable<Report[]> {
        return this.reportService.getAllDeletedReports().pipe(
            tap({
                next: (deletedReports: Report[]) => {
                    this.deletedReports = deletedReports;

                    this.filterAndSortReports();
                },
            }),
        );
    }

    /**
     * Check for newer document versions of the reports in the openReports and doneReports array.
     * @private
     */
    private async syncAllLoadedReports(): Promise<Report[]> {
        if (!this.networkStatusService.isOnline()) {
            return;
        }

        const reportIds: string[] = [
            ...this.openReports,

            // Sync at max 10 of the done reports, even if more have been loaded. -> Reduce load.
            ...this.doneReports.slice(0, 10),
        ].map((report) => report._id);

        // Calling find will emit patch events on all reports that have newer document versions available.
        return this.reportService
            .find({
                _id: {
                    $in: reportIds,
                },
            })
            .toPromise();
    }

    /**
     * Remove all open reports from the IndexedDB which are not part of the newly loaded reports (this.openReports).
     * Before this, autoiXpert had an issue: When a report was open, it was written into IndexedDB. If another deleted the report,
     * this did not propagate to the first user's device, e.g. because autoiXpert was not running on his PC/tablet.
     * When the first user later opened the report list while online, only reports which were in the state "recorded" on the server would be refreshed in the user's local
     * IndexedDB, so the report that the second user moved to trash would not be updated. Since only the server records were displayed, the first user would not notice that
     * he had an outdated IndexedDB entry.
     * When the first user opened the report list while offline, only the IndexedDB would be searched and the report that was previously moved to trash on the server would
     * be shown in the list of open reports again --> bug.
     * By removing all opened reports from the client's IndexedDB that were not returned by the server query, this issue is prevented.
     */
    private removeOutdatedOpenReportsFromIndexedDb() {
        /**
         * Change detection is not necessary here since this can never alter the user interface. It only alters the IndexedDB records.
         * Not triggering change detection improves performance.
         */
        this.ngZone.runOutsideAngular(async () => {
            const indexeddbOpenReportIds: Report['_id'][] = await this.reportService.localDb.findKeysFromIndexByPrefix(
                'recorded',
                'state',
            );

            // Open reports within report list
            const openReportIdsFromList = new Set<Report['_id']>(this.openReports.map((report) => report._id));

            // Outdated = not in server response and not modified locally.
            const outdatedIndexedDbReportIds: Report['_id'][] = [];
            const localStatusMap = await this.reportService.localDb.getLocalStatusOfManyRecords(indexeddbOpenReportIds);
            for (const dbReportId of indexeddbOpenReportIds) {
                // Modified locally -> Don't mark for deletion.
                const modifiedLocally: boolean = localStatusMap.has(dbReportId);
                if (modifiedLocally) {
                    continue;
                }

                // The report is not in the current list (as returned by the server) but exists in IndexedDB -> Mark for deletion.
                // This happens if a report is locked or deleted on another device, and if the change isn't propageted live to this device.
                if (!openReportIdsFromList.has(dbReportId)) {
                    outdatedIndexedDbReportIds.push(dbReportId);
                }
            }

            // Deletion
            if (outdatedIndexedDbReportIds.length) {
                // Remove only locally, do not send a DELETE request to the server, so use "external" as the event source.
                await this.reportService.localDb.deleteLocal(outdatedIndexedDbReportIds, 'externalServer');
            }
        });
    }

    public async saveReport(report: Report) {
        try {
            await this.reportService.put(report);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Gutachten nicht gespeichert',
                    body: 'Bitte versuche es erneut. Sollte das Gutachten weiterhin nicht gespeichert werden können, kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.',
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Report Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Associated Invoices
    //****************************************************************************/
    private loadAssociatedInvoices() {
        this.invoiceService.find({ reportIds: this.selectedReport._id }).subscribe({
            next: (invoices) => {
                this.associatedInvoiceIdsOfSelectedReport = invoices.map((invoice) => invoice._id);
            },
            error: (error) => {
                console.error('Error getting the associated invoices from the server.', { error });
                this.toastService.error(
                    'Assoziierte Rechnungen nicht geladen',
                    'Bitte melde dich bei der <a href="/Hilfe" target="_blank">Hotline</a>.',
                );
            },
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Associated Invoices
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Websocket Events
    //****************************************************************************/
    private registerWebsocketEventHandlers() {
        this.registerCreationWebsocketEvent();
        this.registerPatchWebsocketEvent();
        this.registerDeletionWebsocketEvent();
    }

    private unregisterWebsocketEventHandlers() {
        for (const websocketSubscription of this.websocketSubscriptions) {
            websocketSubscription.unsubscribe();
        }
    }

    /**
     * On each websocket create event, push that record to the list.
     * @private
     */
    private registerCreationWebsocketEvent() {
        const creationsSubscription: Subscription =
            this.reportService.createdFromExternalServerOrLocalBroadcast$.subscribe({
                next: (newRecord) => {
                    /**
                     * The events would cause reports to be added to the report list as soon as the records are added to the indexedDB.
                     * This causes lot's of rerenders and makes the application feel slow.
                     * Therefore we ignore the events when reports are loaded.
                     *
                     * TODO: We may optimize this to only add reports which have been locked and therefore have to jump from the open to the done list.
                     */
                    if (newRecord.state !== 'done' && !this.isLoadMoreDoneReportsPending) {
                        this.addReportToList(newRecord);
                        this.filterAndSortReports();
                    }
                },
            });

        this.websocketSubscriptions.push(creationsSubscription);
    }

    /**
     * On each websocket put event, update local records.
     * @private
     */
    private registerPatchWebsocketEvent() {
        const patchUpdatesSubscription: Subscription =
            this.reportService.patchedFromExternalServerOrLocalBroadcast$.subscribe({
                next: (patchedEvent: PatchedEvent<Report>) => {
                    // If this view holds the record being updated, update it.
                    const matchingReport = [...this.openReports, ...this.doneReports].find(
                        (report) => report._id === patchedEvent.patchedRecord._id,
                    );
                    if (matchingReport) {
                        applyOfflineSyncPatchEventToLocalRecord({
                            localRecord: matchingReport,
                            patchedEvent,
                        });
                        this.filterAndSortReports();
                    }
                },
            });

        this.websocketSubscriptions.push(patchUpdatesSubscription);
    }

    /**
     * On each websocket delete event, delete local record.
     * @private
     */
    private registerDeletionWebsocketEvent() {
        const deletionsSubscription: Subscription = this.reportService.deletedInLocalDatabase$.subscribe({
            next: (deletedRecordId) => {
                // If this view holds the record being updated, remove it.
                const matchingReport = [...this.openReports, ...this.doneReports].find(
                    (space) => space._id === deletedRecordId,
                );
                if (matchingReport) {
                    if (matchingReport.state === 'recorded') {
                        removeFromArray(matchingReport, this.openReports);

                        // Open reports are also updated in real time. Quit being notified about changes if the record has been deleted.
                        this.reportService
                            .leaveUpdateChannel(matchingReport._id)
                            .catch(() =>
                                console.warn(
                                    `Leaving the update channel "reports/${matchingReport._id}" failed. That's optional, so fail silently.`,
                                ),
                            );
                    } else {
                        // Report is done & locked -> Remove from done reports.
                        removeFromArray(matchingReport, this.doneReports);
                    }
                    this.filterAndSortReports();
                }
            },
        });

        this.websocketSubscriptions.push(deletionsSubscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Websocket Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Network Status Changes
    //****************************************************************************/
    private syncVisibleReportsWhenGoingBackOnline() {
        // Only subscribe after the first load has finished.
        if (!this.reportsLoaded) {
            // Try again in 500ms if the load has not finished yet.
            setTimeout(() => this.syncVisibleReportsWhenGoingBackOnline(), 500);
            return;
        }

        /**
         * When going back online, sync all currently visible reports.
         *
         * Problem solved: If we only loaded the open reports and updated the list based on them, reports that have
         * been locked or moved to the trash on another client, would not be included in the response of the open reports
         * query. By syncing all reports visible in the list, the service emits update/patch events which in turn updates the view.
         */
        const subscription = this.networkStatusService.networkBackOnline$.subscribe({
            next: () => {
                this.syncAllLoadedReports();
            },
        });
        this.subscriptions.push(subscription);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Network Status Changes
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Assessor Filter
    //****************************************************************************/

    private loadAssessorsFromTeam() {
        this.assessors = this.userService
            .getAllTeamMembersFromCache()
            .filter((teamMember) => teamMember.active && teamMember.isAssessor);

        /**
         * If not a single assessor could be found, the service must still be waiting for the server response. Try again later.
         */
        if (!this.assessors.length) {
            window.setTimeout(() => this.loadAssessorsFromTeam(), 500);
            return;
        }
    }

    public toggleSelectAssessorForFilter(assessorId: User['_id']) {
        toggleValueInArray(assessorId, this.userPreferences.reportListQuickFilterAssessorIds);

        // Trigger the services setter to save the preferences in the user object.
        // eslint-disable-next-line no-self-assign
        this.userPreferences.reportListQuickFilterAssessorIds = this.userPreferences.reportListQuickFilterAssessorIds;

        // If no assessor is selected, the quick filter shall be cleared.
        this.userPreferences.reportListQuickFilter = this.userPreferences.reportListQuickFilterAssessorIds.length
            ? 'involvedUser'
            : null;

        this.refreshReports();
    }

    public getAssessorFullName(userId: string): string {
        const user: User = this.assessors.find((assessor) => assessor._id === userId);

        if (!user) {
            return '';
        }

        return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Assessor Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Report Details Pane
    //****************************************************************************/

    public toggleReportDetailsPane(): void {
        this.userPreferences.showReportDetailsPane = !this.userPreferences.showReportDetailsPane;

        if (this.userPreferences.showReportDetailsPane && this.selectedReport) {
            this.loadAssociatedInvoices();
        }
    }

    // Get config for report progress indicators.
    private getReportProgressConfig(): void {
        const reportProgressConfigSubscription = this.reportProgressConfigService.find().subscribe({
            next: (configs) => {
                this.reportProgressConfig = configs[0];
            },
        });
        this.subscriptions.push(reportProgressConfigSubscription);
    }

    public isReportTabVisible(reportTabName: ReportTabName): boolean {
        return isReportTabVisible({
            reportTabName,
            reportType: this.selectedReport.type,
        });
    }

    protected getReportTypeGerman(): ReportTypeGerman {
        if (!this.selectedReport) return undefined;

        return Translator.reportType(this.selectedReport.type);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Report Details Pane
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Legacy Reports
    //****************************************************************************/
    public showLegacyReportsImportDialog() {
        this.importLegacyReportsDialogShown = true;
    }

    public hideLegacyReportsImportDialog() {
        this.importLegacyReportsDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Legacy Reports
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Permissions
    //****************************************************************************/
    public isUserAdmin(): boolean {
        return isAdmin(this.user?._id, this.team);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Permissions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Integrity
    //****************************************************************************/
    public checkDataIntegrity(): void {
        // TODO move from localStorage to the new IndexedDB structure.
        const locallyCachedReports: Report[] = store
            .keys()
            .filter((key) => key.startsWith('report-'))
            .map((reportKey) => store.get(reportKey));
        const localLiabilityReportWithoutRepairObject = locallyCachedReports.find(
            (report) => report.type === 'liability' && !report.damageCalculation.repair,
        );
        if (localLiabilityReportWithoutRepairObject) {
            this.toastService.info(
                'Alte Datenstruktur gefunden',
                'Wir loggen dich aus, damit du einwandfreie Daten vom Server laden kannst.',
                { timeOut: 5000 },
            );
            this.loggedInUserService.clearUser();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Integrity
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public createNewReportOnCtrlN(event) {
        // Make sure the user is not inside an input
        if (document.activeElement.nodeName === 'INPUT' || document.activeElement.nodeName === 'TEXTAREA') {
            return;
        }

        // cover Windows an Mac machines
        if (event.key === 'n') {
            event.preventDefault();
            this.createReport();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Angular Performance Optimizations
    //****************************************************************************/
    /**
     * For Angular to recycle rendered elements even if the underlying array changed (e.g. after getting the report from the cache first, then from the server),
     * we must give Angular another identifier. If the identifier is the same, the DOM element will not be re-rendered. We use the Photo's _id property since that doesn't
     * change through an update.
     * @param index
     * @param report
     */
    public getReportId(index: number, report: Report): string {
        return report._id;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Angular Performance Optimizations
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
        this.unregisterWebsocketEventHandlers();
    }

    protected readonly isNameOrOrganizationFilled = isNameOrOrganizationFilled;
}

/**
 * Payload for the loadMoreDoneReports$ observable.
 */
type LoadMoreDoneReportsPayload = {
    searchTerm?: string;
    filterAndSortParams: ReportFilterAndSortParams;
    numberOfItemsToLoad?: number;
    searchAfterPaginationToken?: string;
    searchAfterNumberOfElements?: number;
};

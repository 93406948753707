import moment from 'moment';
import { ServerError } from '../../models/errors/ax-error';
import { Report } from '../../models/reports/report';
import { User } from '../../models/user/user';
import { GERMAN_DATE_FULL } from '../date-and-time/date-formats';
import { VisitGerman } from './placeholder-values.types';
import { Translator } from './translator';

export function getVisitsPlaceholderValues({
    visits,
    reportType,
    user,
    teamMembers,
}: {
    visits: Report['visits'];
    reportType: Report['type'];
    user: User;
    teamMembers: User[];
}): VisitGerman[] {
    // If the user chose not to add any visits, do not list them in the report.
    return visits
        .filter((visit) => !!(visit.locationName || visit.street || visit.zip || visit.date))
        .map((visit) => {
            const anwesende = [];
            if (visit.assessorWasPresent) anwesende.push('Sachverständiger');
            if (visit.claimantWasPresent) anwesende.push(Translator.claimantDenomination(reportType));
            if (visit.driverOfClaimantsCarWasPresent) anwesende.push('Fahrzeugführer');
            if (visit.garageTechnicianWasPresent) anwesende.push('Werkstattmitarbeiter');
            if (visit.ownerOfClaimantsCarWasPresent) anwesende.push('Fahrzeughalter');
            if (visit.otherPeoplePresent) anwesende.push(...visit.otherPeoplePresent);

            //*****************************************************************************
            //  Assessor
            //****************************************************************************/
            let assessor: User;
            if (visit.assessorWasPresent) {
                // If the assessor is either undefined or the current user, use the user object as assessor.
                if (!visit.assessor || visit.assessor === user._id) {
                    assessor = user;
                }
                // Otherwise, try to find the team member with the ID matching the assessor ID
                else {
                    assessor = teamMembers.find((user) => user._id === visit.assessor);
                }

                // If neither the user or another team member is a match
                if (!assessor) {
                    throw new ServerError({
                        code: 'VISIT_ASSESSOR_UNKNOWN',
                        message: `Generating template placeholders failed because a visit's assesor is not part of the team.`,
                        data: {
                            placeholderCategory: 'visit.assessor',
                        },
                    });
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Assessor
            /////////////////////////////////////////////////////////////////////////////*/

            return {
                BesichtigungBei: visit.locationName || '',
                Straße: visit.street || '',
                Postleitzahl: visit.zip || '',
                Ort: visit.city || '',
                Datum: visit.date ? moment(visit.date).format(GERMAN_DATE_FULL) : '',
                Uhrzeit: visit.time ? moment(visit.time).format('HH:mm [Uhr]') : '',
                // Only show the "anwesenderSachverständiger" if he was actually present. Use the contact person if one matches or use the assessor ID
                anwesenderSachverständiger: visit.assessorWasPresent
                    ? assessor.firstName + ' ' + assessor.lastName
                    : '',
                Anwesende: anwesende.join(', '),
                Fahrzeugzustand: visit.carAssemblyState,
                istÄnderungSeitUnfallErsichtlich: !visit.sameStateAsInAccident,
                warSachverständigerAnwesend: visit.assessorWasPresent,
                warAnspruchstellerAnwesend: visit.claimantWasPresent,
                warMechanikerAnwesend: visit.garageTechnicianWasPresent,
                warFahrzeughalterAnwesend: visit.ownerOfClaimantsCarWasPresent,
                andereAnwesende: visit.otherPeoplePresent?.length ? visit.otherPeoplePresent.join(', ') : '',
                Bedingungen: visit.conditions,
                Hilfsmittel: (visit.auxiliaryDevices || []).join(', '),
                Bemerkungen: visit.comment,
                Nummer: visits.indexOf(visit) + 1,
            };
        });
}

import { DocumentBuildingBlock } from '../../models/documents/document-building-block';
import { toCamelCaseWithUpperCaseFirstLetter } from '../strings/camel-case';

export function getDocumentBuildingBlockPlaceholder(
    documentBuildingBlock: Pick<DocumentBuildingBlock, 'placeholder' | 'title'>,
) {
    // If a default placeholder exists, use that one.
    if (documentBuildingBlock.placeholder) {
        return documentBuildingBlock.placeholder;
    } else {
        /**
         * If no placeholder exists, create one from the building block's title. Ensure that no characters are put into the placeholder
         * that might break JavaScript.
         */
        const titleWithoutSpecialChars = (documentBuildingBlock.title || '').replace(/[^\w\sÄÖÜäöüß]/g, '');
        return toCamelCaseWithUpperCaseFirstLetter(titleWithoutSpecialChars);
    }
}

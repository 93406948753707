import { BlobDataType } from '../models/indexed-db/database-blob.types';

/**
 * Dependency Injection Token for ClaimantSignatureFileService
 * Needed for shared components, as the implementation is different in the frontend and claimant-signature-forms
 */
export abstract class AbstractClaimantSignatureFileService {
    public abstract get(recordId: BlobDataType['_id'], blobContentHash: BlobDataType['blobContentHash']): Promise<Blob>;
    public abstract delete(recordId: BlobDataType['_id'], options?: { waitForServer?: boolean }): Promise<void>;
    public abstract create(record: BlobDataType, options?: { waitForServer?: boolean }): Promise<BlobDataType>;
}

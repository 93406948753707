import { ContactPerson } from '../../models/contacts/contact-person';
import { NotFound, ServerError } from '../../models/errors/ax-error';
import { OfficeLocation } from '../../models/teams/office-location';
import { User } from '../../models/user/user';
import { getSenderEmail } from './get-email-from-options';
import { getOfficeLocation } from './get-office-location';

/**
 * Get a full contact person object based on the user, the team's office locations and an optional overwriting officeLocationId.
 */
export function getFullUserContactPerson({
    user,
    officeLocations,
    officeLocationId,
}: {
    user: User;
    officeLocations: OfficeLocation[];
    // If omitted, the default location of the user is used.
    officeLocationId?: string;
}): ContactPerson {
    const officeLocation = getOfficeLocation({
        defaultOfficeLocationId: user.defaultOfficeLocationId,
        officeLocations,
        officeLocationId,
    });

    if (!officeLocation) {
        throw new NotFound({
            code: 'OFFICE_LOCATION_MISSING',
            message: 'Please check all parameters.',
            data: {
                user,
                officeLocations,
                officeLocationId,
            },
        });
    }

    return {
        _id: null,
        _externalId: null,
        organization: user.organization,
        salutation: user.salutation,
        firstName: user.firstName,
        lastName: user.lastName,
        streetAndHouseNumberOrLockbox: officeLocation.streetAndHouseNumber,
        zip: officeLocation.zip,
        city: officeLocation.city,
        email: getSenderEmail(user),
        phone: user.phone || officeLocation.phone, // User phone data takes precedence over office location phone.
        phone2: user.phone2 || officeLocation.phone2, // User phone data takes precedence over office location phone.
        birthdate: null,
        addressAddition: null,
        organizationType: null,
        createdBy: null,
        teamId: null,
        updatedAt: null,
        createdAt: null,
        website: null,
        notes: null,
        _documentVersion: 0,
        _schemaVersion: 2,
    };
}

<mat-form-field class="mat-block" [floatLabel]="'never'">
    <input
        matInput
        placeholder="Inhalt der Eigenschaft"
        [(ngModel)]="property.value"
        [disabled]="isReportLocked"
        [matAutocomplete]="propertyValueAutocomplete"
        (ngModelChange)="filterPropertyValuesForAutocomplete()"
        (change)="emitChange()" />
</mat-form-field>
<mat-autocomplete #propertyValueAutocomplete>
    <mat-option *ngFor="let value of filteredPropertyValuesAutocomplete" [value]="value" (click)="emitChange()">
        {{ value }}
    </mat-option>
</mat-autocomplete>

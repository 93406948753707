export class Damage {
    constructor(position: DamagePosition) {
        this._id = position;
        this.position = position;
    }

    _id: string;
    position: DamagePosition;
    active: boolean = false;
    title: string = '';
    description: string = '';
}

export type DamagePosition =
    | 'frontLeft'
    | 'frontCenter'
    | 'frontRight'
    | 'fenderFrontLeft'
    | 'hood'
    | 'fenderFrontRight'
    | 'doorDriver'
    | 'windshield'
    | 'doorFrontPassenger'
    | 'doorBackPassengerLeft'
    | 'roof'
    | 'doorBackPassengerRight'
    | 'fenderRearLeft'
    | 'fenderRearRight'
    | 'centerLeft'
    | 'centerRight'
    | 'rearLeft'
    | 'rearWindow'
    | 'roofRear'
    | 'rearCenter'
    | 'rearRight'
    // Truck & Semi Trailer
    | 'leftWallFront'
    | 'leftWallCenter'
    | 'leftWallRear'
    | 'ceilingFront'
    | 'ceilingCenter'
    | 'ceilingRear'
    | 'rightWallFront'
    | 'rightWallCenter'
    | 'rightWallRear'
    // Semi Truck
    | 'frameLeft'
    | 'frameRight';

import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { ObjectStoreAndIndexMigrations, RecordMigrations } from '@autoixpert/models/indexed-db/database.types';

export const contactPersonServiceObjectStoreAndIndexMigrations: ObjectStoreAndIndexMigrations<ContactPerson> = {
    '2022-01-01T01:00:00.000+01:00': async (database, transaction) => {
        // Add an index for the schema version to increase performance when querying for records that need to be updated on page load.
        const recordsObjectStore = transaction.objectStore('records');
        recordsObjectStore.createIndex('lastName', 'lastName');
        recordsObjectStore.createIndex('organization', 'organization');
    },
    '2022-01-06T01:00:00.000+01:00': async (database, transaction) => {
        // Add an index for the organization type to increase performance when querying for groups of records.
        const recordsObjectStore = transaction.objectStore('records');
        recordsObjectStore.createIndex('organizationType', 'organizationType');
    },
};

export const contactPersonRecordMigrations: RecordMigrations<ContactPerson> = {};

import { DateTime } from 'luxon';
import { generateId } from '../../lib/generate-id';
import { DataTypeBase } from '../indexed-db/database.types';
import { ChecklistItem } from './report-progress-setting';

/**
 * For each report type, a team may specify which checklist items they want to display and which of those are required.
 */
export class ReportProgressConfig implements DataTypeBase {
    constructor(template: Partial<ReportProgressConfig>) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    liability: ChecklistItem[] = [];
    partialKasko: ChecklistItem[] = [];
    fullKasko: ChecklistItem[] = [];
    valuation: ChecklistItem[] = [];
    shortAssessment: ChecklistItem[] = [];
    oldtimerValuationSmall: ChecklistItem[];
    leaseReturn: ChecklistItem[] = [];
    usedVehicleCheck: ChecklistItem[] = [];
    invoiceAudit: ChecklistItem[] = [];
    residualValueThresholdInPercent: number = 70; // Threshold above which a residual value is required.
    _documentVersion: number = 0;
    _schemaVersion = 1 as const;
    createdBy: string;
    teamId: string;

    // Use time zone "UTC" to ensure the string has a Z at the end. That's the format, the autoiXpert backend uses, too.
    updatedAt: string = DateTime.now().setZone('UTC').toISO();
    createdAt: string = DateTime.now().setZone('UTC').toISO();
}

import { CommunicationRecipient } from './communication-recipient';

export class Insurance extends CommunicationRecipient {
    constructor() {
        super('insurance');
    }

    /**
     * German "Selbstbeteiligung"
     * Relevant in kasko cases. The amount of money the claimant needs to pay before the insurance covers higher expenses.
     */
    deductible: number;
}

export const availableCarBrandIcons: string[] = [
    'abarth',
    'aiways',
    'alfa-romeo',
    'alpina',
    'alpine',
    'aprilia',
    'aston-martin',
    'audi',
    'bentley',
    'bmw',
    'buick',
    'cadillac',
    'chevrolet',
    'chrysler',
    'citroen',
    'corvette',
    'cupra',
    'dacia',
    'daihatsu',
    'dodge',
    'ducati',
    'ferrari',
    'fiat',
    'ford',
    'gmc',
    'harley-davidson',
    'honda',
    'honda-motorräder',
    'hyundai',
    'infiniti',
    'iveco',
    'jaguar',
    'jeep',
    'kaessbohrer-setra',
    'kawasaki',
    'kia',
    'kögel',
    'krone',
    'ktm',
    'lamborghini',
    'lancia',
    'land-rover',
    'lexus',
    'lotus',
    'mclaren',
    'man',
    'maserati',
    'mazda',
    'mercedes-benz',
    'mini',
    'mitsubishi',
    'nissan',
    'opel',
    'peugeot',
    'porsche',
    'renault',
    'rolls-royce',
    'rover',
    'saab',
    'schmitz-cargobull',
    'schwarzmüller',
    'seat',
    'setra',
    'skoda',
    'smart',
    'subaru',
    'suzuki',
    'tesla',
    'toyota',
    'trabant',
    'volkswagen',
    'volvo',
    'yamaha',
];

export function iconForCarBrandExists(carBrand: string): boolean {
    if (!carBrand) {
        return false;
    }
    return availableCarBrandIcons.includes(iconNameForCarBrand(carBrand));
}

/**
 * Transform the car brand to a string without spaces in lowercase to conform with the brand icon image files.
 * @param {string} carBrand
 * @returns {string}
 */
export function iconNameForCarBrand(carBrand: string): string {
    if (!carBrand) {
        return '';
    }

    if (carBrand.toLowerCase() === 'kässbohrer/setra') {
        return 'setra';
    }

    if (carBrand.toLowerCase() === 'citroën') {
        return 'citroen';
    }

    if (carBrand.toLowerCase() === 'honda (motorräder)') {
        return 'honda-motorräder';
    }

    return carBrand.toLowerCase().replace(/\s/g, '-');
}

/**
 * Return the full path to a car brand image in the (shared) assets folder.
 */
export function iconFilePathForCarBrand(carBrand: string, size: 'small' | 'large' = 'small'): string {
    const iconFolder = '/assets/manufacturer-logos';
    const iconSize = size === 'small' ? '44' : '128';

    return `${iconFolder}/${iconNameForCarBrand(carBrand)}_${iconSize}.png`;
}

/**
 * If a string contains more characters than a car brand name (e.g. residual value bidder group titles),
 * this method extracts the car brand from the longer string.
 * @param carBrand
 */
export function extractCarBrandIconName(carBrand: string): string {
    const carBrandLowerCase: string = carBrand.toLowerCase();

    for (const carBrandIcon of availableCarBrandIcons) {
        //eslint-disable-next-line no-useless-escape
        const brandRegex = new RegExp(`\\b${carBrandIcon.replace('-', '[-\\s]')}\\b`, 'i');
        if (brandRegex.test(carBrand) || carBrandIcon.includes(carBrandLowerCase)) {
            return carBrandIcon;
        }
    }
}

import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, last, map, takeUntil, tap } from 'rxjs/operators';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { AxHttpSyncBlob } from '@autoixpert/lib/database/ax-http-sync-blob.class';
import { httpRetry } from '@autoixpert/lib/rxjs-http-retry';
import { BlobDataType } from '@autoixpert/models/indexed-db/database-blob.types';
import { DatabaseServiceName } from '@autoixpert/models/indexed-db/database.types';
import {
    Photo,
    PhotoConfiguration,
    PhotoFormat,
    PhotoVersion,
} from '@autoixpert/models/reports/damage-description/photo';
import { Report } from '@autoixpert/models/reports/report';
import { OfflineSyncBlobServiceBase } from '../libraries/database/offline-sync-blob-service.base';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class OriginalPhotoService extends OfflineSyncBlobServiceBase {
    // Contains a list of photo upload metadata. This is important so the user can navigate between views and still see the upload progress of individual photos.
    private photoUploads = new Map<Photo['_id'], PhotoUpload>();
    /**
     * When a photo is loaded from the file system into the browser, the photo needs to be reduced to a certain photo width
     * before it can be uploaded. During that time, no attempt may be started to render the thumbnail or an error will occur
     * because the original file is neither available in the local IndexedDB nor on the server.
     */
    public photosWaitingForInitialResizingOrIndexeddb = new Set<Photo['_id']>();
    // A new value is emitted when the user cancels a photo upload.
    public uploadCancelled$ = new Subject<Photo['_id']>();

    private photoDownloads = new Map<string, Promise<Blob>>();
    // Set to true if the photo takes a little longer to load. Let the user know that he should be patient.
    private photoDownloadTakesLonger = new Map<string, boolean>();

    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
    ) {
        super({
            serviceName: 'originalPhotoFile',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            frontendLogService,
            // The upload is overwritten in this service's custom HTTP Sync class.
            formDataBlobFieldName: null,
        });

        /**
         * Overwrite the HTTP Sync service because watermark images have a custom path: It includes the team ID.
         */
        this.httpSync = new AxHttpSyncOriginalPhotoFile({
            serviceName: this.serviceName,
            serviceNamePlural: this.serviceNamePlural,
            httpClient: this.httpClient,
            // The upload is overwritten in this service's custom HTTP Sync class.
            formDataBlobFieldName: null,
            photoUploads: this.photoUploads,
            uploadCancelled$: this.uploadCancelled$,
            photoDownloads: this.photoDownloads,
            photoDownloadTakesLonger: this.photoDownloadTakesLonger,
        });
    }

    //*****************************************************************************
    //  Upload & Download Handlers
    //****************************************************************************/
    /**
     * photoUploads should only be edited through this service but individual uploads should be readable within a component, too. That's why we created this simple getter.
     */
    public getPhotoUpload(photoId: Photo['_id']): PhotoUpload {
        return this.photoUploads.get(photoId);
    }

    public cancelUpload(photoId: Photo['_id']): void {
        this.uploadCancelled$.next(photoId);
    }

    /**
     * Returns true if the photo has been loaded into the local IndexedDB but has not yet been fully uploaded to the server.
     * This function can be used to distinguish between loading a photo from the server (should only be done if the photo exists on the
     * server) or rendering a photo from a locally stored original photo file.
     */
    public async isPhotoUploadIncomplete(reportId: string, photoId: Photo['_id']): Promise<boolean> {
        const statusOfLocalOriginalPhotoRecord = await this.localDb.getLocalStatus(`${reportId}-${photoId}`);

        // If the status is still "created", the photo was not yet uploaded, so it's still waiting to be uploaded or is being uploaded.
        return statusOfLocalOriginalPhotoRecord === 'created';
    }

    /**
     * Returns true if the photo download is in progress.
     */
    public isPhotoDownloading(
        reportId: string,
        photoId: string,
        version: keyof PhotoConfiguration,
        format: PhotoFormat,
    ): boolean {
        return this.photoDownloads.has(getPhotoDownloadId(reportId, photoId, version, format));
    }

    /**
     * Returns true if the photo download takes longer than usually.
     */
    public doesPhotoDownloadTakeLonger(
        reportId: string,
        photoId: string,
        version: keyof PhotoConfiguration,
        format: PhotoFormat,
    ): boolean {
        return this.photoDownloadTakesLonger.has(getPhotoDownloadId(reportId, photoId, version, format));
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Upload & Download Handlers
    /////////////////////////////////////////////////////////////////////////////*/

    public async create(
        record: Omit<BlobDataType, 'blobContentHash'>,
        options: { waitForServer?: boolean } = {},
    ): Promise<BlobDataType> {
        return super.create(
            {
                ...record,
                // Since the original photo file is never changed, no need to provide a content hash.
                blobContentHash: undefined,
            },
            options,
        );
    }

    public async get(recordId: BlobDataType['_id']): Promise<Blob> {
        // Since the original photo file is never changed, no need to provide a content hash.
        return super.get(recordId, undefined);
    }
}

class AxHttpSyncOriginalPhotoFile extends AxHttpSyncBlob {
    // Contains a list of photo upload metadata. This is important so the user can navigate between views and still see the upload progress of individual photos.
    // This is passed as a reference from the offline-sync-blob service instance.
    private readonly photoUploads: Map<Photo['_id'], PhotoUpload>;
    // A new value is emitted when the user cancels a photo upload. This is passed as a reference from the offline-sync-blob service instance.
    private readonly uploadCancelled$: Subject<Photo['_id']>;
    private readonly photoDownloads: Map<string, Promise<Blob>>;
    // Set to true if the photo takes a little longer to load. Let the user know that he should be patient.
    private readonly photoDownloadTakesLonger: Map<string, boolean>;

    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        httpClient: HttpClient;
        formDataBlobFieldName: string;
        acceptHeaderContent?: string;
        photoUploads: Map<Photo['_id'], PhotoUpload>;
        uploadCancelled$: Subject<Photo['_id']>;
        photoDownloads: Map<string, Promise<Blob>>;
        photoDownloadTakesLonger: Map<string, boolean>;
    }) {
        super(params);

        this.photoUploads = params.photoUploads;
        this.uploadCancelled$ = params.uploadCancelled$;
        this.photoDownloads = params.photoDownloads;
        this.photoDownloadTakesLonger = params.photoDownloadTakesLonger;
    }

    //*****************************************************************************
    //  Create Remote
    //****************************************************************************/
    public async createRemote(record: { _id: BlobDataType['_id']; blob: Blob }): Promise<void> {
        /**
         * A photo ID consists of `${reportId}-${photoId}`.
         */
        const [reportId, photoId] = record._id.split('-');
        // Get the signed AWS S3 photo upload URL. That's required for uploading to AWS S3 without sending autoiXpert's private keys to the client.
        const { signedPhotoUploadUrl } = await this.getSignedPhotoUploadUrl(reportId, photoId);

        // Remember that we're uploading a file.
        this.photoUploads.set(photoId, {
            file: record.blob,
            progressPercent: 0,
        });

        await this.httpClient
            .put(signedPhotoUploadUrl, record.blob, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(
                httpRetry({
                    delayMs: 2000,
                    retryCallback: () => {
                        this.photoUploads.get(photoId).progressPercent = 0;
                    },
                }),
                // Only keep uploading until cancelled
                takeUntil(this.uploadCancelled$.pipe(filter((value) => value === photoId))),
                tap({
                    next: (event) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress: {
                                const uploadingPhoto = this.photoUploads.get(photoId);
                                if (uploadingPhoto) {
                                    uploadingPhoto.progressPercent = Math.round((100 * event.loaded) / event.total);
                                }
                                break;
                            }
                            case HttpEventType.Response:
                                // Remove the uploadItem entry for this photo since the upload completed.
                                this.photoUploads.delete(photoId);
                                break;
                        }
                    },
                    error: () => {
                        const photoUpload = this.photoUploads.get(photoId);
                        photoUpload.failed = true;
                        photoUpload.progressPercent = 0;
                    },
                }),
                /**
                 * Emit a value from this "piped" observable only after the HTTP observable completed so that
                 * the .toPromise() call below creates a promise that resolves not with the first HTTP progress event
                 * but with the completion event.
                 */
                last(),
            )
            .toPromise();
    }

    /**
     * Get a URL that's signed by a key in the autoiXpert backend so that AWS S3 knows that this link may be used for uploading
     * a file to AWS S3 directly without another form of authentication.
     * @param reportId
     * @param photoId
     * @private
     */
    private getSignedPhotoUploadUrl(
        reportId: Report['_id'],
        photoId: Photo['_id'],
    ): Promise<SignedPhotoUploadUrlObject> {
        return this.httpClient
            .get<SignedPhotoUploadUrlResponse>(`/api/v0/reports/${reportId}/signedPhotoUploadUrls`, {
                params: new HttpParams({
                    fromObject: {
                        'photoIds[]': [photoId],
                    },
                }),
            })
            .pipe(map((signedPhotoUploadUrlResponse: SignedPhotoUploadUrlResponse) => signedPhotoUploadUrlResponse[0]))
            .toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create Remote
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Find Remote & Get Offline-First
    //****************************************************************************/
    public async getRemote(recordId: BlobDataType['_id']): Promise<Blob> {
        const [reportId, photoId] = recordId.split('-');
        const format: PhotoFormat = 'original';
        const version: PhotoVersion = 'report';
        const downloadId = getPhotoDownloadId(reportId, photoId, version, format);

        /**
         * Prevent downloading a photo twice to save bandwidth. Instead, resolve with the same promise for both requests
         * to this findRemote call.
         */
        if (!this.photoDownloads.has(downloadId)) {
            // If the download takes longer, let the user know. The component may act on this Map object and show a note.
            const photoTakesLongerTimeout = setTimeout(() => {
                this.photoDownloadTakesLonger.set(downloadId, true);
            }, 8_000);

            // Use httpClient.request (instead of httpClient.get) to send a request without Angular's standard "Cache-Control: no-cache" headers.
            const photoBlobPromise: Promise<Blob> = this.httpClient
                .request(
                    'GET',
                    `${apiBasePath}/reports/${reportId}/photoFiles/${photoId}?format=${format}&version=${version}`,
                    {
                        headers: new HttpHeaders().set('Accept', 'image/jpeg, image/png, */*'),
                        observe: 'response',
                        responseType: 'blob',
                    },
                )
                .pipe(
                    map((response) => response.body),
                    tap({
                        complete: () => {
                            // Remove the entry so that new calls to this function getFile() create a new server request.
                            this.photoDownloads.delete(downloadId);
                            this.photoDownloadTakesLonger.delete(downloadId);

                            // Since the download completed, remove the warning about a download that takes a little longer.
                            clearTimeout(photoTakesLongerTimeout);
                        },
                        error: () => {
                            // Remove the entry so that new calls to this function getFile() create a new server request.
                            this.photoDownloads.delete(downloadId);
                            this.photoDownloadTakesLonger.delete(downloadId);
                            clearTimeout(photoTakesLongerTimeout);
                        },
                    }),
                )
                .toPromise();
            this.photoDownloads.set(downloadId, photoBlobPromise);
        }

        return this.photoDownloads.get(downloadId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Find Remote & Get Offline-First
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Delete Remote
    //****************************************************************************/
    /**
     * Add the correct photo files URL with the report ID to the delete photo call.
     * @param photoFileRecordId
     * @private
     */
    public async deleteRemote(photoFileRecordId: BlobDataType['_id']): Promise<void> {
        const [reportId, photoId] = photoFileRecordId.split('-');
        await this.httpClient.delete(`/api/v0/reports/${reportId}/photoFiles/${photoId}`).toPromise();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Delete Remote
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface PhotoUpload {
    file: Blob;
    progressPercent: number;
    failed?: boolean;
}

export type SignedPhotoUploadUrlObject = {
    photoId: Photo['_id'];
    signedPhotoUploadUrl: string;
};
export type SignedPhotoUploadUrlResponse = SignedPhotoUploadUrlObject[];

/**
 * Returns a string that's used for identifying a photo download. One photo instance can have multiple downloads, depending on the version, format etc.
 */
function getPhotoDownloadId(
    reportId: string,
    photoId: string,
    version: keyof PhotoConfiguration,
    format: PhotoFormat,
): string {
    return `${reportId}-${photoId}-${version}-${format}`;
}

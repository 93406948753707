/**
 * Depending on the tax deduction status of the claimant (or the vehicle's actual owner),
 * the inputs to the diminished value calculator should be reduced by VAT.
 * That, in turn, reduced the diminished value by the amount of VAT which is in accordance with the latest verdict of
 * the BGH from July 2024.
 *
 * Claimant may...
 * ... deduct VAT -> Company. Reduce inputs by VAT.
 * ... possibly deduct VAT -> It's safer to use inputs reduced by VAT. Reason: assessors prefer a slightly too low diminished value
 *     (claimant receives a little less money) instead of a too high value (which leads to short payments by insurance or even questioning of the entire report)
 * ... for sure not deduct VAT -> mostly private person. No need to reduce the inputs and hence the diminished value. Reducing would penalize the claimant.
 */
export function shallDiminishedValueCalculatorInputsBeReducedByVat({
    mayCarOwnerDeductTaxes,
    alwaysReduceInputsByVat,
}: {
    mayCarOwnerDeductTaxes: boolean | null;
    alwaysReduceInputsByVat: boolean;
}): boolean {
    if (alwaysReduceInputsByVat) return true;

    if (mayCarOwnerDeductTaxes === true || mayCarOwnerDeductTaxes === null) return true;

    // We're sure the claimant may not deduct VAT. Therefore, the inputs to the diminished value calculator must also not be reduced.
    return false;
}

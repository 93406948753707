/**
 * In our reports we have default invoice items (like assessor fee, photo or writing fees) and custom line items.
 * The default ones are stored on the report (not as line items, but dedicated fields) and later converted to line items with varying descriptions.
 * For the revenue-by-invoice-item analytics page we need to group different items like "Schreibkosten (pauschal)" and "Schreibkosten" to single categories ("e.g. "Schreibkosten").
 * The following are the titles we give each of those groups when they are shown in a graph or CSV file.
 */
export enum AnalyticsDefaultInvoiceItemNames {
    ASSESSOR_FEE = 'Honorar',
    PHOTOS_FEE = 'Fotogebühr',
    TRAVEL_EXPENSES = 'Fahrtkosten',
    WRITING_FEES = 'Schreibkosten',
    POSTAGE_AND_PHONE_FEES = 'Porto & Telefon',
}

/**
 * Get all enum values of the AnalyticsDefaultInvoiceItemNames (for convenience).
 */
export function getAnalyticsDefaultInvoiceItemNames(): string[] {
    return Object.values(AnalyticsDefaultInvoiceItemNames);
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Query as FeathersQuery } from '@feathersjs/feathers';
import { Observable } from 'rxjs';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { BankTransaction } from '@autoixpert/models/bank-account-sync/bank-transaction';

@Injectable()
export class BankTransactionService {
    constructor(private httpClient: HttpClient) {}

    public find(
        skip: number,
        limit: number,
        feathersQuery?: FeathersQuery | Partial<BankTransaction>,
    ): Observable<BankTransaction[]> {
        let queryParams = new HttpParams();
        // Sort order is important for paging.
        queryParams = queryParams.set('$sort[valueDate]', -1);
        queryParams = queryParams.set('$skip', skip);
        queryParams = queryParams.set('$limit', limit);

        return this.httpClient.get<BankTransaction[]>(`${apiBasePath}/bankAccountSync/transactions`, {
            params: queryParams,
        });
    }

    public update(transaction: BankTransaction): Promise<BankTransaction> {
        return this.httpClient
            .patch<BankTransaction>(`${apiBasePath}/bankAccountSync/transactions/${transaction._id}`, transaction)
            .toPromise();
    }

    public acceptConnectedInvoice(transactions: BankTransaction[]): Promise<{ numberOfUpdatedTransactions: number }> {
        return this.httpClient
            .post<any>(`${apiBasePath}/bankAccountSync/invoiceConnectionApprovals`, transactions)
            .toPromise();
    }

    public async markTransactionsAsSeen(transactions: BankTransaction[]): Promise<void> {
        const BATCH_SIZE = 200;

        // Batching is necessary if customers have first initial bank transaction syncs of 500+ items.
        for (let i = 0; i < transactions.length; i += BATCH_SIZE) {
            const transactionBatch: BankTransaction[] = transactions.slice(i, i + BATCH_SIZE);

            // Prepare HTTP params.
            let params: HttpParams = new HttpParams();
            for (const transaction of transactionBatch) {
                params = params.append('_id[$in]', transaction._id);
            }

            await this.httpClient
                .patch<void>(
                    `${apiBasePath}/bankAccountSync/transactions`,
                    {
                        seen: true,
                    },
                    {
                        params,
                    },
                )
                .toPromise();
        }
    }
}

import { getDeviceType } from './get-device-type';

export function getAnydeskLink(): string {
    if (window.navigator.userAgent.includes('Windows')) {
        return 'https://download.anydesk.com/AnyDesk.exe';
    } else if (window.navigator.userAgent.includes('Mac')) {
        // iPad or iPhone
        if (getDeviceType() === 'tablet' || getDeviceType() === 'phone') {
            return 'https://itunes.apple.com/us/app/anydesk/id1176131273';
        }
        // Other Apple devices such as a desktop Mac.
        return 'https://download.anydesk.com/anydesk.dmg';
    } else if (window.navigator.userAgent.includes('Android')) {
        /**
         * Open the Google Play Store link for android devices.
         */
        return 'https://play.google.com/store/apps/details?id=com.anydesk.anydeskandroid&hl=de&gl=DE';
    }
    // If nothing matches, open the AnyDesk website.
    else {
        return 'https://anydesk.com/de/downloads';
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';
import {
    getIconNameForGarageCharacteristic,
    iconForGarageCharacteristicExists,
} from '../../libraries/garages/icon-for-garage-characteristic-exists';
import { ManufacturerService } from '../../services/manufacturer.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'garage-brands',
    templateUrl: 'garage-brands.component.html',
    styleUrls: ['garage-brands.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class GarageBrandsComponent {
    constructor(
        private manufacturerService: ManufacturerService,
        private toastService: ToastService,
    ) {}

    @Input() garageContact: ContactPerson;
    @Input() disabled: boolean;

    @Output() contactPersonChange: EventEmitter<ContactPerson> = new EventEmitter();

    // Brand Autocomplete
    public isBrandAutocompleteVisible: boolean;
    public brandAutocompleteValue: string;
    public availableBrands: string[] = [];
    public filteredBrands: string[] = [];

    // Info Autocomplete
    public isGarageCharacteristicAutocompleteVisible: boolean;
    public garageCharacteristicAutocompleteValue: string;
    public availableGarageCharacteristics: string[] = [
        'Elektrofahrzeuge',
        'Kalkulation mit DAT',
        'Kalkulation mit Audatex',
        'Motorrad/Motorräder',
        'Nutzfahrzeuge',
        'Wohnwagen/Wohnwägen',
    ];
    public filteredGarageCharacteristics: string[] = [];

    //*****************************************************************************
    //  Brand Icons
    //****************************************************************************/
    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Brand Icons
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Brand Autocomplete
    //****************************************************************************/
    public showBrandAutocomplete() {
        if (this.disabled) {
            this.toastService.warn('Schreibschutz aktiv', 'Entsperre den Datensatz erst.');
            return;
        }

        this.isBrandAutocompleteVisible = true;

        // When starting the edit mode, if there aren't any brands yet, load them once.
        if (!this.availableBrands.length) {
            this.loadCarBrands();
        }
    }

    public hideBrandAutocomplete() {
        this.isBrandAutocompleteVisible = false;
    }

    public addBrand(brand: string) {
        if (!this.garageContact.garageBrands) {
            this.garageContact.garageBrands = [];
            this.emitContactPersonChange();
        }

        if (!brand) return;

        if (this.garageContact.garageBrands.includes(brand)) {
            this.toastService.info('Marke bereits vorhanden');
            return;
        }

        this.garageContact.garageBrands.push(brand);

        if (this.garageContact.garageBrands.length) {
            this.garageContact.isBrandCertified = true;
        }

        this.emitContactPersonChange();
    }

    public addBrandOnEnter(event: KeyboardEvent) {
        switch (event.key) {
            case 'Enter':
                this.addBrand(this.brandAutocompleteValue);
                this.clearBrandAutocomplete();
                break;
        }
    }

    public removeBrand(brand: string) {
        removeFromArray(brand, this.garageContact.garageBrands);

        if (!this.garageContact.garageBrands.length) {
            this.garageContact.isBrandCertified = false;
        }

        this.emitContactPersonChange();
    }

    public filterBrands(): void {
        const searchTerm: string = this.brandAutocompleteValue;

        this.filteredBrands = [...this.availableBrands];
        if (searchTerm) {
            this.filteredBrands = this.availableBrands.filter((manufacturer) => {
                return manufacturer?.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }
    }

    private async loadCarBrands() {
        this.availableBrands = (await this.manufacturerService.find().toPromise())?.map(
            (manufacturer) => manufacturer.name,
        );

        // As soon as results arrive, display them.
        this.filterBrands();
    }

    public clearBrandAutocomplete() {
        this.brandAutocompleteValue = null;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Brand Autocomplete
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Garage Characteristics Icons
    //****************************************************************************/
    public getIconNameForGarageCharacteristic = getIconNameForGarageCharacteristic;
    public iconForGarageCharacteristicExists = iconForGarageCharacteristicExists;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage Characteristics Icons
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  GarageCharacteristic Autocomplete
    //****************************************************************************/
    public showGarageCharacteristicAutocomplete() {
        if (this.disabled) {
            this.toastService.warn('Schreibschutz aktiv', 'Entsperre den Datensatz erst.');
            return;
        }

        this.isGarageCharacteristicAutocompleteVisible = true;

        // When starting the edit mode, if there aren't any garageCharacteristics yet, load them once.
        if (!this.availableGarageCharacteristics.length) {
            this.loadCarGarageCharacteristics();
        }
    }

    public hideGarageCharacteristicAutocomplete() {
        this.isGarageCharacteristicAutocompleteVisible = false;
    }

    public addGarageCharacteristic(garageCharacteristic: string) {
        if (!this.garageContact.garageCharacteristics) {
            this.garageContact.garageCharacteristics = [];
            this.emitContactPersonChange();
        }

        if (!garageCharacteristic) return;

        if (this.garageContact.garageCharacteristics.includes(garageCharacteristic)) {
            this.toastService.info('Werkstatt-Merkmal bereits vorhanden');
            return;
        }

        this.garageContact.garageCharacteristics.push(garageCharacteristic);
        this.emitContactPersonChange();
    }

    public addGarageCharacteristicOnEnter(event: KeyboardEvent) {
        switch (event.key) {
            case 'Enter':
                this.addGarageCharacteristic(this.garageCharacteristicAutocompleteValue);
                this.clearGarageCharacteristicAutocomplete();
                break;
        }
    }

    public removeGarageCharacteristic(garageCharacteristic: string) {
        removeFromArray(garageCharacteristic, this.garageContact.garageCharacteristics);
        this.emitContactPersonChange();
    }

    public filterGarageCharacteristics(): void {
        const searchTerm: string = this.garageCharacteristicAutocompleteValue;

        this.filteredGarageCharacteristics = [...this.availableGarageCharacteristics];
        if (searchTerm) {
            this.filteredGarageCharacteristics = this.availableGarageCharacteristics.filter((manufacturer) => {
                return manufacturer?.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }
    }

    private async loadCarGarageCharacteristics() {
        this.availableGarageCharacteristics = (await this.manufacturerService.find().toPromise())?.map(
            (manufacturer) => manufacturer.name,
        );

        // As soon as results arrive, display them.
        this.filterGarageCharacteristics();
    }

    public clearGarageCharacteristicAutocomplete() {
        this.garageCharacteristicAutocompleteValue = null;
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitContactPersonChange() {
        this.contactPersonChange.emit(this.garageContact);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/
}

<div
    class="payment-status"
    [ngSwitch]="paymentStatus"
    (click)="emitStatusClick($event)"
    [ngClass]="{
        small: displaySmall,
        'inverted-colors': invertedColors,
        'fully-canceled': paymentStatus === 'fullyCanceled',
        'full-cancellation': paymentStatus === 'fullCancellation',
        'credit-note': paymentStatus === 'creditNote',
        due: paymentStatus === 'due',
        overdue: paymentStatus === 'overdue',
        'payment-reminder-level0': paymentStatus === 'paymentReminderLevel0',
        'payment-reminder-level1': paymentStatus === 'paymentReminderLevel1',
        'payment-reminder-level2': paymentStatus === 'paymentReminderLevel2',
        'payment-reminder-level3': paymentStatus === 'paymentReminderLevel3',
        'partially-paid': paymentStatus === 'partiallyPaid',
        overpaid: paymentStatus === 'overpaid',
        paid: paymentStatus === 'paid',
    }">
    <span *ngSwitchCase="'fullyCanceled'" matTooltip="Diese Rechnung wurde vollständig storniert.">Storniert</span>
    <span *ngSwitchCase="'fullCancellation'" matTooltip="Diese Rechnung storniert eine andere Rechnung vollständig.">
        Storno
    </span>
    <span
        *ngSwitchCase="'creditNote'"
        matTooltip="Diese Rechnung hat einen negativen Betrag. Ggf. bezieht sie sich auf eine Referenzrechnung.">
        Gutschrift
    </span>
    <span
        *ngSwitchCase="'due'"
        matTooltip="{{ invoice.dueDate | momentFromNow }} überfällig | am {{ invoice.dueDate | moment }}">
        Offen
    </span>
    <span
        *ngSwitchCase="'overdue'"
        matTooltip="{{ invoice.dueDate | momentFromNow: true }} überfällig | seit {{ invoice.dueDate | moment }}">
        In Verzug
    </span>
    <span *ngSwitchCase="'paymentReminderLevel0'">Erinnert</span>
    <span *ngSwitchCase="'paymentReminderLevel1'">1. Mahnung</span>
    <span *ngSwitchCase="'paymentReminderLevel2'">2. Mahnung</span>
    <span *ngSwitchCase="'paymentReminderLevel3'">3. Mahnung</span>
    <span *ngSwitchCase="'partiallyPaid'">Teilbezahlt</span>
    <span *ngSwitchCase="'overpaid'" matTooltip="Die Summe der Zahlungen übersteigt den Rechnungsbetrag">
        Überzahlt
    </span>
    <span *ngSwitchCase="'paid'">Bezahlt</span>
    <!--    <span *ngSwitchCase="'shortened'" matTooltip="Mindestens eine Zahlung wurde als Kürzung markiert">Gekürzt</span>-->
</div>

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RevenueByInvoiceItemAnalyticsSummary } from '@autoixpert/models/analytics/revenue-by-invoice-item-analytics.model';
import { DownloadService } from '../download.service';
import { AnalyticsFilterOptions, prepareDefaultParametersForAnalyticsQuery } from './default-analytics-query-parameter';

@Injectable()
export class RevenueByInvoiceItemAnalyticsService {
    constructor(
        private httpClient: HttpClient,
        private downloadService: DownloadService,
    ) {}

    private apiPath = '/api/v0';

    /**
     * Total revenue of reports matching the given filter aggregated
     * by invoice item description.
     */
    public getRevenueByInvoiceItemSummary(
        filterOptions: AnalyticsFilterOptions,
    ): Promise<RevenueByInvoiceItemAnalyticsSummary> {
        const params = prepareDefaultParametersForAnalyticsQuery({
            dateField: 'feeCalculation.invoiceParameters.date',
            ...filterOptions,
        });

        return this.httpClient
            .get<RevenueByInvoiceItemAnalyticsSummary>(`${this.apiPath}/analytics/revenueByInvoiceItemSummary`, {
                params,
            })
            .toPromise();
    }

    /**
     * Get a CSV file including all reports matching the given filter together with detailed
     * information about the invoice items of each report and additional info related to the report (garage, lawyer, etc.)
     * that can be used by assessors to build their own analytics sheet in Excel.
     */
    public async downloadRecordsAsCsv(filterOptions: AnalyticsFilterOptions): Promise<HttpResponse<Blob>> {
        const params = prepareDefaultParametersForAnalyticsQuery({
            dateField: 'feeCalculation.invoiceParameters.date',
            ...filterOptions,
        });

        const response = await this.httpClient
            .get(`${this.apiPath}/exports/reportsWithInvoiceItems`, {
                params: params,
                observe: 'response',
                responseType: 'blob',
            })
            .toPromise();

        this.downloadService.downloadBlobResponseWithHeaders(response);
        return response;
    }
}

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getDataRelocationErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        /**
         * If a team is being moved from production to beta or vice versa, this error is thrown.
         * @constructor
         */
        DATA_IS_BEING_MOVED_BETWEEN_BETA_AND_PRODUCTION: () => ({
            title: 'Account wird gerade aktualisiert',
            body: `Du bekommst gerade eine neue Version von autoiXpert. Im Normalfall ist der Umzug in 3-10 Minuten abgeschlossen, je nach Größe deiner Daten.`,
        }),
    };
}

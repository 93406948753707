import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvoiceInvolvedParty } from '@autoixpert/models/invoices/invoice';
import { OutgoingMessage } from '@autoixpert/models/outgoing-message';
import { EmailRecipient } from '@autoixpert/models/reports/document-email';
import { DocumentGroupName } from '../../reports/details/print-and-transmission/print-and-transmission.component';

@Injectable()
export class EmailService {
    constructor(private httpClient: HttpClient) {}

    public sendStandardEmail({
        subject,
        body,
        toRecipients,
        ccRecipients,
        bccRecipients,
    }: {
        subject: string;
        body: string;
        toRecipients: EmailRecipient[];
        ccRecipients?: EmailRecipient[];
        bccRecipients?: EmailRecipient[];
    }): Promise<EmailServiceResponse> {
        return this.httpClient
            .post<EmailServiceResponse>('/api/v0/standardEmails', {
                subject,
                body,
                toRecipients,
                ccRecipients,
                bccRecipients,
            })
            .toPromise();
    }

    /**
     * This request tells the server to get the respective email from the
     * report structure and send an email with the contents.
     *
     * The POST request does not send the contents itself. That's why it needs to
     * be given the document group:
     * "Which DocumentEmail shall I take to send an e-mail?"
     *
     * @param {string} reportId
     * @param {string} recipientRole
     * @param {"report" | "repairConfirmation" | "expertStatement"} documentGroup
     * @returns {Promise<EmailServiceResponse>}
     */
    public triggerReportEmail(
        outgoingMessage: OutgoingMessage,
        { dryRun }: { dryRun?: boolean } = {},
    ): Promise<EmailServiceResponse> {
        // Is provided role allowed?
        const recipientRole = outgoingMessage.recipientType;
        const allowedRecipients = ['claimant', 'garage', 'lawyer', 'insurance', 'factoringProvider', 'leaseProvider'];
        if (!allowedRecipients.includes(recipientRole)) {
            throw Error('RECIPIENT_NOT_ALLOWED' + `The email service cannot send an email for ${recipientRole}`);
        }

        return this.httpClient
            .post<EmailServiceResponse>(
                `/api/v0/reports/${outgoingMessage.reportId}/emails`,
                {
                    outgoingMessageId: outgoingMessage._id,
                },
                { params: { dryRun } },
            )
            .toPromise();
    }

    public triggerInvoiceEmail(
        outgoingMessage: OutgoingMessage,
        { dryRun }: { dryRun?: boolean } = {},
    ): Promise<EmailServiceResponse> {
        return this.httpClient
            .post<EmailServiceResponse>(
                `/api/v0/invoices/${outgoingMessage.invoiceId}/emails`,
                {
                    outgoingMessageId: outgoingMessage._id,
                },
                { params: { dryRun } },
            )
            .toPromise();
    }
}

export interface EmailServiceResponse {
    success: boolean;
    messageId: string;
}

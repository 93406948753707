import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { UserPreferencesService } from 'src/app/shared/services/user-preferences.service';

@Component({
    selector: 'task-list-settings',
    templateUrl: './task-list-settings.component.html',
    styleUrls: ['./task-list-settings.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class TaskListSettingsComponent {
    constructor(public userPreferences: UserPreferencesService) {}

    @Output() close = new EventEmitter();

    public saveUserPreferences() {
        // Re-assign to save back settings, even if the user didn't have a settings object before. This saves the default.
        // Simply calling userPreferences.save() doesn't work if the user didn't have a settings object yet.
        // eslint-disable-next-line no-self-assign
        this.userPreferences.taskList_workweekSettings = this.userPreferences.taskList_workweekSettings;
    }

    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardEvents(keyboardEvent: KeyboardEvent) {
        switch (keyboardEvent.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }

    public closeDialog() {
        this.close.emit();
    }
}

import { canvasToBlob } from './canvas-to-blob';
import { getCanvasDrawingContextForPhotos } from './get-canvas-drawing-context-for-photos';
import { getHtmlImageFromFileOrBlob } from './get-html-image-from-file-or-blob';

/**
 * Resize a given photo file to a given target width.
 *
 * This function is used when uploading photos from the file system into the browser
 * to reduce the photo file size to the least possible size.
 * @param photoFile
 * @param targetWidth
 */
export async function resizePhoto({
    photoFileOrBlob,
    targetWidth,
}: {
    photoFileOrBlob: File | Blob;
    targetWidth: number;
}): Promise<ResizePhotoResult> {
    const image: HTMLImageElement = await getHtmlImageFromFileOrBlob(photoFileOrBlob);

    //*****************************************************************************
    //  Determine Target Dimensions
    //****************************************************************************/
    let targetHeight: number;
    // For downscaling and image quality reduction, use a canvas. That allows the browser to use hardware acceleration.
    if (image.width > targetWidth) {
        const imageScalingFactor = targetWidth / image.width;
        targetHeight = Math.floor(image.height * imageScalingFactor);
        // Target width does not have to be adjusted.
    }
    // If the image is smaller than the target width, don't scale it up (quality loss!) but keep it at the image's dimensions.
    else {
        targetHeight = image.height;
        targetWidth = image.width;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Determine Target Dimensions
    /////////////////////////////////////////////////////////////////////////////*/

    const { canvasForResizing, drawingContextForResizing } = getCanvasDrawingContextForPhotos();

    canvasForResizing.height = targetHeight;
    canvasForResizing.width = targetWidth;

    drawingContextForResizing.drawImage(image, 0, 0, targetWidth, targetHeight);

    const resizedPhotoBlob: Blob = await canvasToBlob(canvasForResizing);

    return {
        photoBlob: new Blob([resizedPhotoBlob], { type: photoFileOrBlob.type }),
        dimensions: {
            width: targetWidth,
            height: targetHeight,
        },
    };
}

export interface PhotoDimensions {
    width: number;
    height: number;
}

export interface ResizePhotoResult {
    photoBlob: Blob;
    dimensions: PhotoDimensions;
}

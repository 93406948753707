import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Rank, Tensor, browser, loadGraphModel } from '@tensorflow/tfjs';
import { Subject } from 'rxjs';
import { filter, shareReplay, take } from 'rxjs/operators';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { generateId } from '@autoixpert/lib/generate-id';
import {
    MODEL_IMAGE_SIZE,
    deserializeTensor,
    filterLicensePlateRedactionImageMasks,
    getLicensePlateRedactionImageMaskPolygon,
    logLicensePlateRedactionModelOutput,
    mergeOverlappingLicensePlateRedactionPolygons,
    parseLicensePlateRedactionModelOutput,
    reframeBoxMasksToImageMasks,
    scaleLicensePlateRedactionPolygonToDimensions,
    serializeTensor,
} from './license-plate-redaction-model-output.utils';
import {
    LicensePlateRedactionModel,
    LicensePlateRedactionModelOutputUnion,
    LicensePlateRedactionPolygon,
    LicensePlateRedactionWorkerMessageType,
    LicensePlateRedactionWorkerMessageUnion,
    LicensePlateRedactionWorkerProcessImageMessage,
    LicensePlateRedactionWorkerProcessImageResponse,
    LicensePlateRedactionWorkerResponseUnion,
} from './license-plate-redaction-model.interfaces';

@Injectable()
export class LicensePlateRedactionService {
    private readonly modelVersion = '21.2.0';
    private readonly modelBaseUrl = `https://license-plate-redaction-autoixpert.s3.eu-central-1.amazonaws.com/release/${this.modelVersion}/model.json`;
    private model: LicensePlateRedactionModel;
    private readonly worker: LicensePlateRedactionWebWorkerClient | undefined;

    /** Configuration */
    private readonly config = {
        /** Version of the model. */
        modelVersion: this.modelVersion,

        /** Whether to preserve the aspect ratio of the original image when resizing it to the model input dimensions. */
        keepAspectRatio: false,

        /** Minimum threshold for the score of a detection box. The score is a probability between 0 and 1 whether the detected object is a license plate. */
        boxScoreTreshold: 0.75,

        /** Minimum threshold for a pixel's mask value in order to be considered part of a license plate. The value is a probability between 0 and whether the pixel is part of a license plate. */
        maskValueThreshold: 0.8,

        /** Tolerance for colinear neighbor detection in the convex hull polygon algorithm. The higher the value, the more points are aggregated into a polygon point. */
        colinearNeighborDetectionTolerance: 0.01,
    };

    constructor(private httpClient: HttpClient) {
        // Check if web workers are supported, if so create a new worker
        if (typeof Worker !== 'undefined') {
            this.worker = new LicensePlateRedactionWebWorkerClient();
        }
    }

    public async loadModel(): Promise<void> {
        // Check if web workers are supported, if so load the model in a worker
        if (this.worker) {
            await this.worker.loadModel(this.modelBaseUrl);
            return;
        }

        // Otherwise, load the model in the main thread
        if (this.model) return;
        this.model = await loadGraphModel(this.modelBaseUrl);
    }

    public hasModelBeenLoaded(): boolean {
        return !!this.model;
    }

    /**
     * Redact license plates in an image locally in the browser.
     */
    async redactLicensePlateLocally({
        image,
        findAtLeastOneLicensePlateAboveScoreThreshold,
    }: {
        image: {
            width: number;
            height: number;
            blob: Blob;
        };
        /** Optionally include at least one redaction if the score is at least this one. */
        findAtLeastOneLicensePlateAboveScoreThreshold?: number;
    }): Promise<LicensePlateRedactionResult> {
        const { boxScoreTreshold, maskValueThreshold, colinearNeighborDetectionTolerance } = this.config;
        //console.log(this.config);

        // Initialize the model
        await this.loadModel();

        // Create image element from blob
        const imageElement = document.createElement('img');
        imageElement.width = image.width;
        imageElement.height = image.height;
        imageElement.src = URL.createObjectURL(image.blob);
        await new Promise((resolve) => (imageElement.onload = resolve));

        // Create a canvas element - keep image aspect ratio
        const canvas = document.createElement('canvas');
        canvas.width = this.config.keepAspectRatio ? image.width : Math.max(image.width, image.height);
        canvas.height = this.config.keepAspectRatio ? image.height : Math.max(image.width, image.height);
        const context = canvas.getContext('2d');

        // Pad with black background
        context.fillStyle = 'black';
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.drawImage(imageElement, 0, 0);
        const pixels = context.getImageData(0, 0, canvas.width, canvas.height);

        // Convert the image to a tensor, resize, and cast to int32
        const imageTensor = browser
            .fromPixels(pixels)
            .resizeNearestNeighbor([MODEL_IMAGE_SIZE, MODEL_IMAGE_SIZE])
            .expandDims();

        // Remove DOM nodes after processing
        imageElement.remove();
        canvas.remove();

        // Perform model inference and parse the output
        let predictions: LicensePlateRedactionModelOutputUnion[];
        if (this.worker) {
            predictions = await this.worker.executeModel({ imageTensor });
        } else {
            predictions = (await this.model.executeAsync(imageTensor)) as LicensePlateRedactionModelOutputUnion[];
        }

        const output = parseLicensePlateRedactionModelOutput(predictions);
        // logLicensePlateRedactionModelOutput({ output });

        const { imageMasks } = reframeBoxMasksToImageMasks({ output });
        const { selectedImageMasks } = await filterLicensePlateRedactionImageMasks({
            output,
            imageMasks,
            boxScoreTreshold,
            findAtLeastOneLicensePlateAboveScoreThreshold,
        });

        const polygons = selectedImageMasks.map(
            (imageMaskInfo) =>
                getLicensePlateRedactionImageMaskPolygon({
                    imageMaskInfo,
                    maskValueThreshold: maskValueThreshold, //  Math.min(maskValueThreshold, imageMaskInfo.score),
                    colinearNeighborDetectionTolerance,
                }).polygon,
        );

        const mergedPolygons = mergeOverlappingLicensePlateRedactionPolygons({ polygons });

        const redactions = mergedPolygons.map((polygon) =>
            scaleLicensePlateRedactionPolygonToDimensions({ polygon, width: canvas.width, height: canvas.height }),
        );

        return { modelVersion: this.modelVersion, redactions, image: { width: image.width, height: image.height } };
    }

    /**
     * Redact license plates in an image via the backend.
     */
    public redactLicensePlateRemote({
        reportId,
        photoIds,
    }: {
        reportId: string;
        photoIds?: string[];
    }): Promise<LicensePlateRedactionResult> {
        return this.httpClient
            .post<LicensePlateRedactionResult>(
                `${apiBasePath}/reports/${reportId}/licensePlateRedaction`,
                {
                    photoIds,
                },
                {},
            )
            .toPromise();
    }
}

export interface LicensePlateRedactionResult {
    modelVersion: string;
    redactions: LicensePlateRedactionPolygon[];
    image: { width: number; height: number };
}

class LicensePlateRedactionWebWorkerClient {
    private readonly worker: Worker;
    private readonly message$$ = new Subject<LicensePlateRedactionWorkerResponseUnion>();

    constructor() {
        try {
            this.worker = new Worker(new URL('./license-plate-redaction.web-worker', import.meta.url));
            this.worker.onmessage = ({ data: response }: { data: LicensePlateRedactionWorkerResponseUnion }) => {
                this.message$$.next(response);
            };
        } catch (err) {
            // Web workers are not supported
        }
    }

    private async postMessage<TResponse extends LicensePlateRedactionWorkerResponseUnion>(
        message: LicensePlateRedactionWorkerMessageUnion,
    ): Promise<TResponse> {
        this.worker.postMessage(message);
        return this.message$$
            .pipe(
                filter(({ id }) => id === message.id),
                take(1),
            )
            .toPromise() as Promise<TResponse>;
    }

    public async loadModel(modelUrl: string): Promise<void> {
        await this.postMessage({
            id: 'init-model-' + generateId(),
            type: LicensePlateRedactionWorkerMessageType.INIT_MODEL,
            modelUrl,
        });
    }

    public async executeModel({
        imageTensor,
    }: {
        imageTensor: Tensor<Rank>;
    }): Promise<LicensePlateRedactionModelOutputUnion[]> {
        // Serialize the tensor
        const message: LicensePlateRedactionWorkerProcessImageMessage = {
            id: 'execute-model-' + generateId(),
            type: LicensePlateRedactionWorkerMessageType.PROCESS_IMAGE,
            imageTensor: await serializeTensor(imageTensor),
        };
        const result = await this.postMessage<LicensePlateRedactionWorkerProcessImageResponse>(message);

        if (result.modelOutput) {
            return result.modelOutput.map((tensor) =>
                deserializeTensor(tensor),
            ) as LicensePlateRedactionModelOutputUnion[];
        }
        return [];
    }
}

<div (keydown.escape)="selectedTask = null" class="left-column">
    <!--============================================-->
    <!-- Tasks list -->
    <!--============================================-->
    <div id="task-list-container" class="card">
        <!--============================================-->
        <!-- Header -->
        <!--============================================-->
        <header>
            <div class="filter-and-sort-container">
                <!--============================================-->
                <!-- Sort -->
                <!--============================================-->

                <!-- Configure sort -->
                <div class="sort-button" [matMenuTriggerFor]="sortMenu">
                    <ng-container [ngSwitch]="userPreferences.taskList_sortBy">
                        <span class="ax-header-select-label" *ngSwitchCase="'rank'">Eigene Sortierung</span>
                        <span class="ax-header-select-label" *ngSwitchCase="'dueDate'">Nach Fälligkeitsdatum</span>
                        <mat-icon class="label filter-dropdown-icon">arrow_drop_down</mat-icon>
                    </ng-container>
                </div>

                <mat-menu #sortMenu>
                    <a mat-menu-item (click)="selectSortBy('rank')" class="no-filter-menu-item">Eigene Sortierung</a>
                    <a mat-menu-item (click)="selectSortBy('dueDate')">Nach Fälligkeitsdatum</a>
                </mat-menu>

                <!-- Configure sort direction -->
                <mat-icon
                    *ngIf="userPreferences.taskList_sortBy !== 'rank'"
                    id="sort-direction-indicator"
                    [ngClass]="{ flipped: userPreferences.taskList_sortDescending }"
                    (click)="toggleSortDirection()"
                    [matTooltip]="
                        userPreferences.taskList_sortDescending ? 'Sortierung absteigend' : 'Sortierung aufsteigend'
                    ">
                    arrow_upward
                </mat-icon>

                <!--============================================-->
                <!-- END Sort -->
                <!--============================================-->

                <!--============================================-->
                <!-- Filters -->
                <!--============================================-->

                <!--********** Anchor **********-->
                <div
                    [matMenuTriggerFor]="tasksFilter"
                    class="filter-button ax-header-select"
                    [ngClass]="{
                        active: userPreferences.taskList_quickFilter && userPreferences.taskList_quickFilter !== 'none',
                    }">
                    <mat-icon class="filter-icon">filter_list</mat-icon>
                    <ng-container [ngSwitch]="userPreferences.taskList_quickFilter">
                        <span class="ax-header-select-label" *ngSwitchCase="'none'">Kein Filter</span>
                        <span class="ax-header-select-label" *ngSwitchCase="'onlyOwn'">Meine Aufgaben</span>
                        <span class="ax-header-select-label" *ngSwitchCase="'assignedUser'">
                            <ng-container *ngIf="userPreferences.taskList_usersForFilter.length === 1">
                                {{ getAssessorFullName(userPreferences.taskList_usersForFilter[0]) }}
                            </ng-container>
                            <ng-container *ngIf="userPreferences.taskList_usersForFilter.length > 1">
                                {{ userPreferences.taskList_usersForFilter.length }} Nutzer
                            </ng-container>
                        </span>
                        <span class="ax-header-select-label" *ngSwitchCase="'onlyDue'">Fällige</span>
                        <span *ngSwitchCase="'labels'" class="ax-header-select-label">
                            <div class="label-item" *ngIf="userPreferences.taskList_labelsForFilter.length === 1">
                                <!--********** Label Color **********-->
                                <!--suppress CssUnknownProperty -->
                                <div
                                    class="label-color"
                                    [style.--labelColor]="
                                        getLabelConfigByLabelName(userPreferences.taskList_labelsForFilter[0])?.color
                                    "></div>

                                <!--********** Label Name **********-->
                                <div class="label-name" [matTooltip]="userPreferences.taskList_labelsForFilter[0]">
                                    {{ userPreferences.taskList_labelsForFilter[0] }}
                                </div>
                            </div>
                            <ng-container *ngIf="userPreferences.taskList_labelsForFilter.length > 1">
                                {{ userPreferences.taskList_labelsForFilter.length }} Labels
                            </ng-container>
                        </span>
                        <mat-icon
                            *ngIf="
                                userPreferences.taskList_quickFilter && userPreferences.taskList_quickFilter !== 'none'
                            "
                            class="label filter-dropdown-icon">
                            arrow_drop_down
                        </mat-icon>
                    </ng-container>
                </div>

                <!--********** Top Level **********-->
                <mat-menu #tasksFilter>
                    <!--********** Toggle displaying done tasks **********-->
                    <a
                        mat-menu-item
                        (click)="
                            userPreferences.taskList_showCompletedTasks = !userPreferences.taskList_showCompletedTasks;
                            applyFilterAndSort()
                        "
                        class="menu-item-with-icon">
                        Erledigte anzeigen
                        <mat-slide-toggle
                            class="slide-toggle-small margin-left-15"
                            [(ngModel)]="userPreferences.taskList_showCompletedTasks"
                            (ngModelChange)="applyFilterAndSort()"
                            (click)="$event.stopPropagation()"></mat-slide-toggle>
                    </a>

                    <!--********** Remove filters **********-->
                    <a mat-menu-item (click)="selectQuickFilter('none')" class="no-filter-menu-item">Kein Filter</a>

                    <!--********** My Tasks **********-->
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('onlyOwn')"
                        matTooltip="Zeige nur mir zugewiesene Aufgaben.">
                        Meine Aufgaben
                    </a>

                    <!--********** Assignee **********-->
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('assignedUser')"
                        [matMenuTriggerFor]="assigneeFilter"
                        matTooltip="Zeige nur die Aufgaben ausgewählter Nutzer.">
                        Nutzer
                    </a>

                    <!--********** Due Date **********-->
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('onlyDue')"
                        matTooltip="Zeige nur die Aufgaben, die heute fällig werden oder bereits überfällig sind.">
                        Fällige
                    </a>

                    <!--********** Labels **********-->
                    <a
                        mat-menu-item
                        (click)="selectQuickFilter('labels')"
                        [matMenuTriggerFor]="labelFilterSubmenu"
                        matTooltip="Zeige nur die Aufgaben mit ausgewählten Labels.">
                        Label
                    </a>
                </mat-menu>

                <!--********** Assignee **********-->
                <mat-menu #assigneeFilter>
                    <a
                        mat-menu-item
                        class="assessor-filter__assessor-menu-item"
                        *ngFor="let assessor of assessors"
                        (click)="toggleAssigneeForFilter(assessor._id)">
                        <!--********** Profile Picture **********-->
                        <user-avatar class="assignee-filter-profile-picture" [userId]="assessor._id"></user-avatar>

                        <!--********** Name **********-->
                        <div class="assessor-filter__assessor-name">
                            {{ getAssessorFullName(assessor._id) }}
                        </div>

                        <!--********** Multiselect **********-->
                        <!-- Stop Mouseup event to prevent closing the menu after selecting an assessor. -->
                        <mat-checkbox
                            [checked]="isAssessorInFilter(assessor._id)"
                            (change)="toggleAssigneeForFilter(assessor._id)"
                            (click)="$event.stopPropagation()"
                            matTooltip="Zeige die Gutachten dieses Nutzers an"></mat-checkbox>
                    </a>
                </mat-menu>

                <!--********** Labels **********-->
                <mat-menu #labelFilterSubmenu class="label-filter-submenu">
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngFor="let labelConfig of labelConfigs"
                        (click)="toggleSelectLabelConfigForFilter(labelConfig.name)">
                        <!--********** Label **********-->
                        <div class="label-item-container flex-grow">
                            <div class="label-item">
                                <!--********** Label Color **********-->
                                <!--suppress CssUnknownProperty -->
                                <div
                                    class="label-color"
                                    [style.--labelColor]="getLabelConfigByLabelName(labelConfig.name)?.color"></div>

                                <!--********** Label Name **********-->
                                <div class="label-name" [matTooltip]="labelConfig.name">
                                    {{ labelConfig.name }}
                                </div>
                            </div>
                        </div>

                        <!--********** Multiselect **********-->
                        <mat-checkbox
                            [checked]="userPreferences.taskList_labelsForFilter.includes(labelConfig.name)"
                            (change)="toggleSelectLabelConfigForFilter(labelConfig.name)"
                            (click)="$event.stopPropagation()"
                            matTooltip="Zeige die Rechnungen mit diesem Label an.{{
                                '\n\n'
                            }}Labels können im grauen Info-Panel rechts der Rechnungsliste vergeben werden."></mat-checkbox>
                    </a>

                    <!-- No labels placeholder -->
                    <div *ngIf="labelConfigs.length === 0" class="no-labels-placeholder">Keine Labels vorhanden.</div>
                </mat-menu>

                <!--============================================-->
                <!-- END Filters -->
                <!--============================================-->
            </div>

            <!-- Search -->
            <div class="search-container">
                <mat-form-field id="contacts-search-field" class="search-field mat-block" floatLabel="never">
                    <input
                        matInput
                        (input)="filterAndSortInMemory()"
                        [(ngModel)]="searchTerm"
                        (ngModelChange)="updateSearchTerm()"
                        placeholder="Suchen"
                        [matTooltip]="
                            'Diese Daten werden durchsucht: \n• Vorname, Nachname, Organisation\n• E-Mail\n• Telefon\n• Straße\n• Stadt\n• PLZ\n• Werkstatt-Marken\n• Werkstatt-Eigenschaften\n\nSuchworte müssen mindestens drei Zeichen lang sein.'
                        " />
                    <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>
            </div>

            <div class="capacity-and-buttons-container">
                <!-- Capacity overview -->
                <div class="capacity-container">
                    <task-capacity-preview
                        *ngFor="let dayCapacity of daysForCapacity"
                        [day]="dayCapacity.date"
                        [workdaySettings]="dayCapacity.workdaySettings"></task-capacity-preview>
                </div>

                <!-- Add new task -->
                <div class="add-task-button" (click)="addNewTask('first')" matTooltip="Neue Aufgabe">
                    <mat-icon id="new-task-button-icon" class="small-icon">add</mat-icon>
                    <span id="new-task-button-label">Aufgabe</span>
                </div>

                <!-- Toggle Details Pane -->
                <div (click)="toggleDetailView()" class="details-toggle" matTooltip="Detailansicht öffnen.">
                    <mat-icon class="outlined">info</mat-icon>
                </div>

                <!-- Settings -->
                <div (click)="isSettingsDialogOpen = true" class="settings-button"><mat-icon>settings</mat-icon></div>
            </div>
        </header>
        <!--============================================-->
        <!-- END Header -->
        <!--============================================-->

        <!--============================================-->
        <!-- Empty State -->
        <!--============================================-->
        <div *ngIf="tasks.length === 0 && !isLoadMoreTasksPending" id="no-tasks-to-display-container">
            <img
                id="no-tasks-to-display-icon"
                src="/assets/images/icons/no-data-to-display.png"
                (click)="addNewTask('first')" />

            <div class="semibold">Deine persönliche Aufgabenliste</div>
            <p class="label">Füge Aufgaben hier oder direkt im Gutachten / in der Rechnung hinzu.</p>

            <div id="no-tasks-to-display-buttons">
                <button (click)="addNewTask('first')" class="primary">Erste Aufgabe anlegen</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Empty State -->
        <!--============================================-->

        <!--============================================-->
        <!-- Loading State -->
        <!--============================================-->
        <div *ngIf="tasks.length === 0 && isLoadMoreTasksPending" id="no-tasks-to-display-container">
            <div id="loading-spinner">
                <div id="loading-state-circle"></div>
                <mat-spinner id="loading-state-spinner" [strokeWidth]="4" [diameter]="144"></mat-spinner>
            </div>

            <div class="semibold">Aufgaben werden geladen...</div>
        </div>
        <!--============================================-->
        <!-- END Loading State -->
        <!--============================================-->

        <!--============================================-->
        <!-- Tasks -->
        <!--============================================-->
        <div class="task-table" cdkDropList="" (cdkDropListDropped)="onTaskDropped($event)">
            <!--============================================-->
            <!-- Task Row -->
            <!--============================================-->
            <div
                class="task-row record"
                *ngFor="let task of filteredTasks; trackBy: trackById"
                cdkDrag
                [ngClass]="{ selected: selectedTask?._id === task._id, completed: task.isCompleted }"
                [cdkDragDisabled]="this.userPreferences.taskList_sortBy !== 'rank'"
                (dblclick)="openDetailView(task)"
                (click)="selectTask(task)"
                (keydown.arrowup)="navigateToTask('prev')"
                (keydown.arrowdown)="navigateToTask('next')"
                @slideInAndOutVertically
                [@.disabled]="disableRowAnimation">
                <!-- Drag Handle, injected from parent -->
                <div class="task-row-prefix">
                    <mat-icon *ngIf="this.userPreferences.taskList_sortBy === 'rank'" class="drag-handle" cdkDragHandle>
                        drag_indicator
                    </mat-icon>
                </div>

                <div class="task-row-content">
                    <div class="task-completed">
                        <task-done-checkbox
                            [task]="task"
                            (taskCompleted)="handleCompletedTask(task)"
                            (taskReopened)="saveTask(task)"></task-done-checkbox>
                    </div>

                    <!--============================================-->
                    <!-- Task Title & Labels -->
                    <!--============================================-->
                    <div class="task-title" (click)="openDetailView(task)">
                        <!--********** Title **********-->
                        <!-- dblclick shall not bubble so that text selection doesn't open detail view -->
                        <task-title
                            #titleInput
                            [shouldAutofocus]="selectedTask?._id === task._id"
                            [task]="task"
                            [singleLine]="true"
                            (taskChange)="saveTask(task)"
                            (enterKeyPress)="addNewTask('after', task)"
                            (shiftEnterKeyPress)="addNewTask('before', task)"
                            (taskDeleteRequest)="deleteTask(task)"
                            (click)="selectTask(task, $event); $event.stopPropagation()"
                            (dblclick)="$event.stopPropagation()"></task-title>

                        <div (click)="openDetailView(task)" class="task-row-spacer"></div>
                    </div>

                    <!--********** Labels **********-->
                    <div
                        class="task-labels"
                        labelDropdown
                        [ngClass]="{ 'no-labels': !task.labels.length }"
                        [(selectedLabels)]="task.labels"
                        [labelGroup]="'task'"
                        (click)="selectTask(task, $event)"
                        (selectedLabelsChange)="saveTask(task)"
                        #labelDropdownAnchor="labelDropdownAnchor">
                        <label-display *ngIf="task.labels.length > 0" [labels]="task.labels"></label-display>
                        <span *ngIf="task.labels.length < 1" class="no-labels-button">+ Label</span>
                    </div>
                    <!--============================================-->
                    <!-- END Task Title & Labels -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Due Date -->
                    <!--============================================-->
                    <div class="task-due-date">
                        <div
                            datePickerWithInputAnchor
                            (click)="selectTask(task, $event)"
                            class="cursor-pointer"
                            [(date)]="task.dueDate"
                            [(time)]="task.dueTime"
                            [showTimeInput]="true"
                            (dateChange)="saveTask(task)"
                            (timeChange)="saveTask(task)">
                            <span
                                *ngIf="task.dueDate"
                                class="due-date-is-{{ task | compareTaskWithToday }}"
                                matTooltip="Fällig: {{ task.dueDate | moment: 'DD.MM.YYYY' }}">
                                {{ task.dueDate | relativeDate }}
                                {{ task.dueTime ? ' ' + task.dueTime + ' Uhr' : '' }}
                            </span>
                            <div *ngIf="!task.dueDate" class="missing-data-info label italic">ohne Datum</div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Due Date -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Associated Report or Invoice -->
                    <!--============================================-->
                    <task-associated-invoice-or-report
                        [task]="task"
                        (taskChange)="saveTask(task)"
                        (click)="selectTask(task, $event)"></task-associated-invoice-or-report>

                    <!--============================================-->
                    <!-- END Associated Report or Invoice -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Estimated Duration -->
                    <!--============================================-->
                    <div *ngIf="!isDetailViewOpen" class="task-estimated-duration">
                        <task-estimated-duration
                            (click)="selectTask(task, $event)"
                            [ngClass]="{ 'is-not-set': !task.estimatedDuration }"
                            [estimatedDuration]="task.estimatedDuration"
                            (estimatedDurationChange)="
                                handleEstimatedDurationChange(task, $event)
                            "></task-estimated-duration>
                    </div>
                    <!--============================================-->
                    <!-- END Estimated Duration -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Assignee -->
                    <!--============================================-->
                    <div *ngIf="!isDetailViewOpen" class="task-assignee">
                        <user-avatar
                            [userId]="task.assigneeId"
                            userSelectorInputAnchor
                            [showEmptyState]="true"
                            (click)="selectTask(task, $event)"
                            (userSelected)="assignUser(task, $event)"
                            matTooltip="zuständige Person"></user-avatar>
                    </div>
                    <!--============================================-->
                    <!-- END Assignee -->
                    <!--============================================-->

                    <!--============================================-->
                    <!-- Submenu -->
                    <!--============================================-->
                    <div class="task-submenu">
                        <mat-icon
                            (click)="selectTask(task, $event)"
                            class="task-submenu-option"
                            [matMenuTriggerFor]="taskOptionsMenu">
                            more_vert
                        </mat-icon>

                        <mat-menu #taskOptionsMenu="matMenu" xPosition="before">
                            <!--********** Delete **********-->
                            <a mat-menu-item class="menu-item-with-icon alert" (click)="deleteTask(task)">
                                <mat-icon>delete</mat-icon>
                                Löschen
                            </a>
                        </mat-menu>
                    </div>
                    <!--============================================-->
                    <!-- END Submenu -->
                    <!--============================================-->
                </div>

                <div class="task-row-suffix"></div>
            </div>

            <!--============================================-->
            <!-- END Task Row -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Tasks -->
        <!--============================================-->

        <!--============================================-->
        <!-- Add new task -->
        <!--============================================-->
        <div
            (intersectsViewport)="triggerLoadMoreTasks()"
            *ngIf="filteredTasks.length > 0 && !isLoadMoreTasksPending && !allTasksLoadedWithCurrentFilters">
            <span>Mehr Aufgaben laden</span>
        </div>
        <div id="add-new-task-container" role="button" (click)="addNewTask('last')">
            <div class="add-new-task-button">
                <span>+ Aufgabe</span>
            </div>
            <!--============================================-->
            <!-- END Add new task -->
            <!--============================================-->
        </div>
    </div>
    <!--============================================-->
    <!-- END Tasks list -->
    <!--============================================-->

    <div id="more-tasks-button-container" *ngIf="filteredTasks.length > 0">
        <button
            class="show-more-button"
            (click)="!allTasksLoadedWithCurrentFilters && triggerLoadMoreTasks()"
            [ngClass]="{ disabled: allTasksLoadedWithCurrentFilters }"
            [matTooltip]="allTasksLoadedWithCurrentFilters ? 'Alle Aufgaben geladen' : ''">
            Mehr anzeigen
        </button>
        <div
            id="number-of-visible-invoices-container"
            [ngClass]="{
                'fixed-positioning': !allTasksLoadedWithCurrentFilters,
            }"
            matTooltip="Geladene Aufgaben">
            <ng-container *ngIf="!allTasksLoadedWithCurrentFilters">
                <img src="/assets/images/icons/loading-indicator.svg" *ngIf="isLoadMoreTasksPending" />
                {{ filteredTasks.length }} Aufgaben
            </ng-container>
            <ng-container *ngIf="allTasksLoadedWithCurrentFilters">
                Alle {{ filteredTasks.length }} Aufgaben geladen
            </ng-container>
        </div>
    </div>
</div>

<div *ngIf="isDetailViewOpen && selectedTask" class="right-column" @slideOutSide>
    <!--============================================-->
    <!-- Task Details -->
    <!--============================================-->
    <task-details
        [task]="selectedTask"
        (taskChange)="saveTask($event)"
        (taskDeleted)="selectedTask = null; deleteTask($event)"
        (closeClick)="isDetailViewOpen = false"></task-details>
    <!--============================================-->
    <!-- END Task Details -->
    <!--============================================-->
</div>

<task-list-settings
    *ngIf="isSettingsDialogOpen"
    (close)="isSettingsDialogOpen = false"
    @runChildAnimations></task-list-settings>

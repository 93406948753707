import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import IBAN from 'iban';
import { DateTime } from 'luxon';
import moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { getPaymentStatus } from '@autoixpert/lib/invoices/get-payment-status';
import { setPaymentStatus } from '@autoixpert/lib/invoices/set-payment-status';
import { getFullRecipientName } from '@autoixpert/lib/placeholder-values/get-full-recipient-name';
import {
    BankTransaction,
    InvoiceMatchPropertyName,
    InvoicePropertyMatch,
    SuggestedInvoice,
} from '@autoixpert/models/bank-account-sync/bank-transaction';
import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Payment } from '@autoixpert/models/invoices/payment';
import { User } from '@autoixpert/models/user/user';
import { isSmallScreen } from 'src/app/shared/libraries/is-small-screen';
import { fadeInAndOutAnimation } from '../../shared/animations/fade-in-and-out.animation';
import { fadeInAnimation } from '../../shared/animations/fade-in.animation';
import { fadeOutAnimation } from '../../shared/animations/fade-out.animation';
import { slideInHorizontally } from '../../shared/animations/slide-in-horizontally.animation';
import { slideOutVertical } from '../../shared/animations/slide-out-vertical.animation';
import {
    VideoPlayerDialogComponent,
    VideoPlayerDialogData,
} from '../../shared/components/video-player-dialog/video-player-dialog.component';
import { getBankAccountErrorHandlers } from '../../shared/libraries/bank-account-sync/get-bank-account-sync-error-handlers';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { AxAngularErrorHandlerData } from '../../shared/libraries/error-handlers/ax-angular-error-handler-data';
import { getFileNameForInsuranceLogo } from '../../shared/libraries/insurances/insurance-logo-exists';
import { BankTransactionService } from '../../shared/services/Banking/bank-transaction.service';
import { FetchAndMatchTransactionsService } from '../../shared/services/Banking/fetch-and-match-transactions.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { InvoiceService } from '../../shared/services/invoice.service';
import { LoggedInUserService } from '../../shared/services/logged-in-user.service';
import { ToastService } from '../../shared/services/toast.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'bank-transaction-list',
    templateUrl: 'bank-transaction-list.component.html',
    styleUrls: ['bank-transaction-list.component.scss'],
    animations: [
        fadeInAnimation(),
        dialogEnterAndLeaveAnimation(),
        slideInHorizontally(350),
        fadeOutAnimation(),
        fadeInAndOutAnimation(),
        slideOutVertical(),
    ],
})
export class BankTransactionListComponent {
    constructor(
        private bankTransactionService: BankTransactionService,
        private apiErrorService: ApiErrorService,
        private invoiceService: InvoiceService,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private fetchAndMatchTransactionsService: FetchAndMatchTransactionsService,
        public userPreferences: UserPreferencesService,
        private dialog: MatDialog,
        private userService: UserService,
    ) {}

    protected user: User;

    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() invoiceUpdated: EventEmitter<Invoice> = new EventEmitter();

    // Transactions - infinite scroll
    public loadedAllTransactions: boolean = false;
    public loadingTransactionsPending: boolean = false;
    private numberOfLoadedTransactions: number = 0;

    // Transaction list
    public initialLoadPending: boolean = true;
    public fetchAndMatchPending: boolean;
    public transactions: BankTransaction[] = [];
    public filteredTransactions: BankTransaction[] = [];
    public selectedTransaction: BankTransaction;
    public transactionListSearchTerm: string = '';
    public transactionsSum: number = 0;
    public insuranceLogoFileNames: Map<string, string> = new Map();

    // Category Filters
    public linkFilter: 'onlyLinked' | 'onlyUnlinked';
    public seenFilter: 'onlySeen' | 'onlyUnseen';

    // Visible invoices
    // Either the suggested invoices for a transaction or the search result of a manual search query.
    public visibleInvoices: Invoice[] = [];

    // Connected invoices
    private connectedInvoices: Invoice[] = [];

    // Partial payment amount
    public partialPaymentAmount: number = 0;
    public partialPaymentMenuOpen: boolean = false;

    // Suggested Invoices
    public suggestedInvoices: Invoice[] = [];
    public invoicesPending: boolean;

    // Payment Dialog
    public invoiceForPaymentsDialog: Invoice;
    public paymentsDialogShown: boolean;

    // Invoice Search
    public invoiceSearchInputShown: boolean;
    public invoiceSearchTerm: string;
    public invoiceSearchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private invoiceServerSearchSubscription: Subscription;
    public invoicesSearchResult: Invoice[] = [];
    public MAXIMUM_NUMBER_OF_SEARCH_RESULTS: number = 10;

    // Intro Video
    public bankAccountSyncVideoLink: string = 'https://www.youtube.com/embed/c0lKE_ggrxE?rel=0';

    private subscriptions: Subscription[] = [];
    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        // Update the user in case it was updated in a different tab.
        this.subscriptions.push(
            this.loggedInUserService.getUser$().subscribe({
                next: (user) => {
                    this.user = user;
                },
            }),
        );

        this.triggerFetchAndMatchTransactions();
        this.connectInvoiceServerSearch();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Transactions
    //****************************************************************************/
    /**
     * Trigger the server to fetch transactions from GoCardless and suggest invoices.
     * @private
     */
    private async triggerFetchAndMatchTransactions(): Promise<void> {
        this.fetchAndMatchPending = true;

        let transactionErrors: AxError[];
        try {
            // Retrieve new transactions from GoCardless
            const fetchAndMatchResponse = await this.fetchAndMatchTransactionsService.trigger();

            transactionErrors = fetchAndMatchResponse.transactionErrors;
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ...getBankAccountErrorHandlers(),
                },
                defaultHandler: {
                    title: 'Fehler bei Konto-Abgleich',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        } finally {
            this.fetchAndMatchPending = false;

            //*****************************************************************************
            //  Handle Transaction Errors
            //****************************************************************************/
            if (transactionErrors) {
                try {
                    this.apiErrorService.handleAndRethrow({
                        axError: transactionErrors,
                        handlers: {
                            ...getBankAccountErrorHandlers(),
                        },
                        defaultHandler: {
                            title: 'Abgleich für alle Bankkonten gescheitert',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                } catch (error) {
                    /**
                     * Do not rethrow this error to allow loading additional transactions in case only one bank account
                     * failed but the other continues to work.
                     */
                    if (!(error.data as AxAngularErrorHandlerData).didFrontendHandleError) {
                        this.apiErrorService.logErrorSilently(error);
                    }
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Handle Transaction Errors
            /////////////////////////////////////////////////////////////////////////////*/

            this.loadAdditionalTransactions();
        }
    }

    public onTransactionListScroll(): void {
        if (!this.loadedAllTransactions && !this.loadingTransactionsPending) {
            this.loadAdditionalTransactions();
        }
    }

    private async loadAdditionalTransactions() {
        if (this.loadedAllTransactions) {
            console.log("All transactions have been loaded. Don't load more.");
            return;
        }

        // Display loading spinner
        this.loadingTransactionsPending = true;

        // Suggestions column is hidden during initial load.
        // On successive loads, the column stays visible.
        if (this.numberOfLoadedTransactions === 0) {
            this.initialLoadPending = true;
        }

        const loadingBatchSize = 40;

        try {
            const loadedTransactions = await this.bankTransactionService
                .find(this.numberOfLoadedTransactions, loadingBatchSize)
                .toPromise();

            this.numberOfLoadedTransactions += loadedTransactions.length;

            // Document if all transactions have been loaded
            if (loadedTransactions.length !== loadingBatchSize) {
                this.loadedAllTransactions = true;
            }
            this.loadingTransactionsPending = false;
            this.initialLoadPending = false;

            // Update the UI
            this.transactions.push(...loadedTransactions);
            this.sortTransactions();
            this.setDefaultFilterAccordingToPreferences();
            this.filterTransactions();
            this.getInsuranceLogoFilePathForTransactions();
        } catch (error: unknown) {
            this.loadingTransactionsPending = false;
            this.initialLoadPending = false;

            this.toastService.error(
                'Zahlungen konnten nicht geladen werden',
                'Bitte versuche es erneut oder kontaktiere die <a href="/Hilfe">Hotline</a>.',
            );
            throw new AxError({
                code: 'LOADING_TRANSACTIONS_FAILED',
                message: 'Failed to load bank transactions',
                error: error as Error,
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Transactions
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sort & Filter
    //****************************************************************************/
    public filterTransactions(): void {
        this.filteredTransactions = [...this.transactions];

        this.applyLinkFilter();
        this.applySeenFilter();
        this.applySearchFilter();
        this.determineTransactionsSum();
    }

    public selectLinkFilter(filter: this['linkFilter']): void {
        this.linkFilter = filter;
        this.filterTransactions();
    }

    private applyLinkFilter(): void {
        if (!this.linkFilter) return;

        let transactionMustHaveConnectedInvoice: boolean;

        switch (this.linkFilter) {
            case 'onlyLinked':
                transactionMustHaveConnectedInvoice = true;
                break;
            case 'onlyUnlinked':
                transactionMustHaveConnectedInvoice = false;
                break;
        }

        this.filteredTransactions = this.filteredTransactions.filter((transaction) =>
            transactionMustHaveConnectedInvoice
                ? this.hasTransactionConnectedInvoices(transaction)
                : !this.hasTransactionConnectedInvoices(transaction),
        );
    }

    public selectSeenFilter(filter: this['seenFilter']): void {
        this.seenFilter = filter;
        this.filterTransactions();
        this.userPreferences.bankTransactionListVisibilityFilter = this.seenFilter;
    }

    private applySeenFilter(): void {
        if (!this.seenFilter) return;

        let transactionMustBeSeen: boolean;

        switch (this.seenFilter) {
            case 'onlySeen':
                transactionMustBeSeen = true;
                break;
            case 'onlyUnseen':
                transactionMustBeSeen = false;
                break;
        }

        this.filteredTransactions = this.filteredTransactions.filter((transaction) =>
            transactionMustBeSeen ? transaction.seen : !transaction.seen,
        );
    }

    private applySearchFilter(): void {
        const searchTerm: string = this.transactionListSearchTerm;

        if (!searchTerm) return;

        const searchTerms: string[] = searchTerm.toLowerCase().split(' ');

        this.filteredTransactions = this.filteredTransactions.filter((transaction) => {
            const propertiesToBeSearched: string[] = [
                transaction.reference,
                currencyFormatterEuro(transaction.amount.amount / 100),
                transaction.counterParty.holderName,
                transaction.iban,
            ]
                // Ignore empty values
                .filter((prop) => !!prop)
                // Search shall be case-insensitive
                .map((prop) => (prop || '').toLowerCase());

            return searchTerms.every((searchTermPart) => {
                return propertiesToBeSearched.some((property) => property.includes(searchTermPart));
            });
        });
    }

    private setDefaultFilterAccordingToPreferences(): void {
        this.seenFilter = this.userPreferences.bankTransactionListVisibilityFilter || null;
    }

    private sortTransactions(): void {
        this.transactions.sort((transactionA, transactionB) => {
            if (transactionA.valueDate !== transactionB.valueDate) {
                // Sort higher dates to the front (youngest first)
                return transactionA.valueDate > transactionB.valueDate ? -1 : 1;
            } else {
                return (transactionA.reference || '').localeCompare(transactionB.reference || '');
            }
        });
    }

    private determineTransactionsSum(): void {
        this.transactionsSum =
            this.filteredTransactions.reduce((total, transaction) => total + transaction.amount.amount, 0) / 100;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sort & Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Select Transaction
    //****************************************************************************/
    public async selectTransaction(transaction: BankTransaction): Promise<void> {
        this.selectedTransaction = transaction;
        this.suggestedInvoices = [];
        this.hideInvoiceSearchInput();
        await this.loadSuggestedInvoices(transaction);
        await this.loadConnectedInvoices(transaction);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Select Transaction
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Transaction Icon
    //****************************************************************************/
    public getIconNameForInsurance(payerName: string): string {
        if (payerName?.startsWith('Deutsche Verrechnungsstelle')) {
            return 'kfzvs.svg';
        }

        return this.insuranceLogoFileNames.get(payerName);
    }

    private getInsuranceLogoFilePathForTransactions(): void {
        for (const transaction of this.transactions) {
            const fileName: string = getFileNameForInsuranceLogo(transaction.counterParty.holderName);
            if (fileName) {
                this.insuranceLogoFileNames.set(transaction.counterParty.holderName, `insurances/${fileName}`);
            }
        }
    }

    public getPayerInitials(transaction: BankTransaction): string {
        const nameParts: string[] = (transaction.counterParty.holderName || '').split(' ').slice(0, 2);
        return nameParts
            .map((namePart) => namePart[0])
            .join('')
            .toUpperCase();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Transaction Icon
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Highlight Matches in Reference
    //****************************************************************************/
    public getReferenceWithHighlightedMatches(transaction: BankTransaction): string {
        // We mostly don't use the "reference" property but the "bankReferences.[un]structured" ones because
        // the reference property simply holds the two concatenated bankReferences which are often the same.
        let referenceWithHighlightedMatches: string =
            transaction.bankReferences.unstructured ?? transaction.bankReferences.structured;

        if (!referenceWithHighlightedMatches) return;

        // Only things that can actually appear in the reference, should be highlighted.
        const possibleMatchesInReference: InvoicePropertyMatch['propertyName'][] = [
            'invoiceNumber',
            'invoiceRecipientName',
            'dateOfAccident',
            'caseNumberInsurance',
            'insuranceNumber',
            'caseNumberLawyer',
            'licensePlate',
            'reportToken',
        ];
        const matches: InvoicePropertyMatch[] = transaction.suggestedInvoices
            .map((suggestion) => suggestion.matchedProperties)
            .flat()
            .filter((match) => possibleMatchesInReference.includes(match.propertyName));

        // De-duplicate matches to prevent nesting highlight HTML elements.
        const uniqueMatches: InvoicePropertyMatch[] = [];
        for (const match of matches) {
            const existingMatch = uniqueMatches.find((existingMatch) => existingMatch.value === match.value);
            if (existingMatch) continue;
            uniqueMatches.push(match);
        }

        // For each match, highlight its value within the reference
        for (const match of uniqueMatches) {
            referenceWithHighlightedMatches = referenceWithHighlightedMatches.replace(
                match.value,
                `<span class="match-highlight">${match.value}</span>`,
            );
        }

        return referenceWithHighlightedMatches;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Highlight Matches in Reference
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Booking Date
    //****************************************************************************/
    public getMonthWithoutPeriod(date: string): string {
        return moment(date).format('MMM')?.replace('.', '');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Booking Date
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public openInvoice(invoiceId: string): void {
        window.open(`/Rechnungen/${invoiceId}`);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Mark As Done
    //****************************************************************************/
    public toggleMarkAsDone(transaction: BankTransaction): void {
        transaction.markedAsDone = !transaction.markedAsDone;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Mark As Done
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Seen / Unseen
    //****************************************************************************/
    public async markTransactionsAsSeenAndCloseDialog(): Promise<void> {
        try {
            await this.markTransactionsAsSeen();
        } catch (response) {
            console.log('Ignoring error on marking transactions as seen', response);
        }
        this.closeDialog();
    }

    private async markTransactionsAsSeen(): Promise<void> {
        const unseenTransactions: BankTransaction[] = this.transactions.filter((transaction) => !transaction.seen);

        return await this.bankTransactionService.markTransactionsAsSeen(unseenTransactions).catch((error) => {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Zahlungseingänge nicht als gelesen markiert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Seen / Unseen
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Videos
    //****************************************************************************/
    public openVideo(heading: string, videoUrl: string) {
        this.dialog.open<VideoPlayerDialogComponent, VideoPlayerDialogData>(VideoPlayerDialogComponent, {
            data: {
                heading,
                videoUrl,
            },
        });
    }

    public hideTutorialVideo(): void {
        this.user.userInterfaceStates.bankTransactionListTutorialVideoSeen = true;
        this.saveUser();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Videos
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Suggested Invoices
    //****************************************************************************/
    public async loadSuggestedInvoices(transaction: BankTransaction): Promise<void> {
        if (!transaction.suggestedInvoices.length) return;

        this.invoicesPending = true;

        const invoiceIds: string[] = transaction.suggestedInvoices
            .map((suggestedInvoice) => suggestedInvoice.invoiceId)
            .filter((invoiceId) => !!invoiceId);

        try {
            this.suggestedInvoices = await this.invoiceService.find({ _id: { $in: invoiceIds } }).toPromise();
            this.sortSuggestedInvoices();
            this.displayInvoices(this.suggestedInvoices);
            this.invoicesPending = false;
        } catch (error) {
            this.invoicesPending = false;
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Rechnungsvorschläge nicht abrufbar',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * Sort suggested invoices based on the order in which the server matched them.
     * @private
     */
    private sortSuggestedInvoices(): void {
        const invoices = [...this.suggestedInvoices];
        this.suggestedInvoices = [];
        for (const suggestedInvoiceReference of this.selectedTransaction.suggestedInvoices) {
            const matchingInvoice = invoices.find((invoice) => invoice._id === suggestedInvoiceReference.invoiceId);
            if (matchingInvoice) {
                this.suggestedInvoices.push(matchingInvoice);
            }
        }
    }

    public initializePartialPaymentMenu(invoice: Invoice): void {
        const invoiceRemainingAmount =
            invoice.totalGross - this.getInvoicePaymentsSum(this.selectedTransaction, invoice._id);
        this.partialPaymentAmount = Math.min(invoiceRemainingAmount, this.getCollectivePaymentRemainingAmount());
    }

    /**
     * Get the match data for a specific invoice from the transaction's matchedInvoices array.
     * @param transaction
     * @param suggestedInvoiceId
     */
    public getInvoiceMatchData(transaction: BankTransaction, suggestedInvoiceId: string): SuggestedInvoice {
        return transaction.suggestedInvoices?.find((matchedInvoice) => matchedInvoice.invoiceId === suggestedInvoiceId);
    }

    public propertyHasBeenMatchedForAnySuggestedInvoice(
        transaction: BankTransaction,
        property: InvoiceMatchPropertyName,
    ): boolean {
        return transaction.suggestedInvoices.some((suggestedInvoice) =>
            this.propertyHasBeenMatched(transaction, suggestedInvoice.invoiceId, property),
        );
    }
    /**
     * Whether a given property was matched for a specific suggested invoice.
     *
     * Returns false for invoices of a search result.
     */
    public propertyHasBeenMatched(
        transaction: BankTransaction,
        suggestedInvoiceId: string,
        property: InvoiceMatchPropertyName,
    ): boolean {
        return !!this.getInvoiceMatchData(transaction, suggestedInvoiceId)?.matchedProperties.find(
            (matchedProperty) => matchedProperty.propertyName === property,
        );
    }

    public getMatchedPropertyLabel(propertyMatch: InvoicePropertyMatch): string {
        switch (propertyMatch.propertyName) {
            case 'invoiceNumber':
                return 'Re-Nr.';
            case 'amountGross':
                return 'Brutto';
            case 'amountNet':
                return 'Netto';
            case 'valueAddedTax':
                return 'MwSt.';
            case 'invoiceRecipientName':
                return 'Empfänger';
            case 'licensePlate':
                return 'Kennzeichen';
            case 'caseNumberInsurance':
                return 'Schadennr.';
            case 'insuranceNumber':
                return 'Vers.-Nr.';
            case 'reportToken':
                return 'Aktenzeichen';
            case 'dateOfAccident':
                return 'Unfalltag';
        }
    }

    public getMatchedPropertyTooltip(propertyMatch: InvoicePropertyMatch): string {
        let propertyLabel: string = this.getMatchedPropertyLabel(propertyMatch);
        let propertyValue: string = propertyMatch.value;

        // Expand abbreviated labels
        if (propertyMatch.propertyName === 'invoiceNumber') {
            propertyLabel = 'Rechnungsnummer';
        }

        // Format license plate for display
        if (propertyMatch.propertyName === 'licensePlate') {
            propertyValue = propertyValue.replace(/-/g, ' ');
        }

        return `${propertyLabel}: ${propertyValue}`;
    }

    //*****************************************************************************
    //  Recipient
    //****************************************************************************/
    public getShortRecipientName(contactPerson: ContactPerson): string {
        return contactPerson.organization
            ? contactPerson.organization
            : `${contactPerson.firstName || ''} ${contactPerson.lastName || ''}`.trim();
    }

    public getFullRecipientName(contactPerson: ContactPerson): string {
        return getFullRecipientName({ contactPerson: contactPerson }).join(' | ');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Recipient
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Connect & Disconnect Invoice
    //****************************************************************************/
    public async toggleConnectInvoice(suggestedInvoice: Invoice): Promise<void> {
        if (
            this.selectedTransaction.connectedInvoices.find(
                (connectedInvoice) => connectedInvoice.invoiceId === suggestedInvoice._id,
            )
        ) {
            await this.disconnectInvoice(this.selectedTransaction, suggestedInvoice);
        } else {
            await this.connectInvoice(this.selectedTransaction, suggestedInvoice);
        }
    }

    /**
     * Get tooltip for the unlink icon displayed on the connected invoice button.
     * @param transaction
     */
    public getUnlinkInvoiceIconTooltip(transaction: BankTransaction, invoiceId: string): string {
        const connectedInvoice = transaction.connectedInvoices.find(
            (connectedInvoice) => connectedInvoice.invoiceId === invoiceId,
        );
        // If the invoice has been approved, a payment will be removed.
        if (connectedInvoice?.approvedByUser) {
            const invoice = this.connectedInvoices.find((invoice) => invoice._id === invoiceId);
            const payment = invoice?.payments.find(
                (payment) => payment.associatedBankTransactionId === transaction.transactionId,
            );
            const amount = payment?.amount || transaction.amount.amount / 100;
            const amountFormatted: string = currencyFormatterEuro(amount);
            return `Erfasste Zahlung über ${amountFormatted} entfernen und Rechnung trennen.`;
        } else {
            return 'Vorgeschlagene Rechnung trennen';
        }
    }

    public async handleInvoiceDisconnectionInTransactionList(
        transaction: BankTransaction,
        invoiceId: string,
    ): Promise<void> {
        const connectedInvoice = transaction.connectedInvoices.find(
            (connectedInvoice) => connectedInvoice.invoiceId === invoiceId,
        );
        if (!connectedInvoice) {
            return;
        }
        // Get associated invoice
        try {
            const invoice = await this.invoiceService.get(connectedInvoice.invoiceId);
            await this.disconnectInvoice(transaction, invoice);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    RESOURCE_NOT_FOUND: {
                        title: 'Rechnung nicht gefunden',
                        body: 'Wurde sie vielleicht in der Zwischenzeit gelöscht? \n\nDie Rechnung wurde von dieser Transaktion entfernt, damit deine Daten wieder sauber sind.',
                        action: async () => {
                            // Disconnect invoice from transaction
                            this.disconnectInvoice(transaction, invoiceId);
                            await this.saveTransaction(transaction);
                        },
                    },
                },
                defaultHandler: {
                    title: 'Fehler beim Trennen der Rechnung',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public async connectInvoice(transaction: BankTransaction, invoice: Invoice, amount: number = null): Promise<void> {
        // Remove existing one
        transaction.connectedInvoices = transaction.connectedInvoices.filter(
            (connectedInvoice) => connectedInvoice.invoiceId !== invoice._id,
        );

        // Connect invoice to transaction
        transaction.connectedInvoices.push({
            invoiceId: invoice._id,
            invoiceNumber: invoice.number,
            approvedByUser: true,
            approvedAt: DateTime.now().toISO(),
        });
        await this.saveTransaction(transaction);

        // If the invoice already has a payment with the same transaction ID, don't add another one
        if (invoice.payments.find((payment) => payment.associatedBankTransactionId === transaction.transactionId))
            return;

        // Record payment in invoice
        invoice.payments.push(
            new Payment({
                type: 'payment',
                amount: amount || transaction.amount.amount / 100,
                payer: transaction.counterParty?.holderName,
                associatedBankTransactionId: transaction.transactionId,
                iban: transaction.iban,
                createdAt: moment().format(),
                updatedAt: moment().format(),
                createdBy: this.user._id,
                date: toIsoDate(transaction.bookingDate),
            }),
        );

        const paymentStatus = getPaymentStatus(invoice);
        setPaymentStatus(invoice);

        await this.saveInvoice(invoice);
        this.invoiceUpdated.emit(invoice);

        // Add invoice to connected invoices (replace existing invoice if it exists)
        this.connectedInvoices = this.connectedInvoices.filter(
            (connectedInvoice) => connectedInvoice._id !== invoice._id,
        );
        this.connectedInvoices.push(invoice);

        // Only select transaction after saving payment to invoice so that the new status will be reflected on the invoice that's loaded from the server on selecting a transaction.
        if (paymentStatus === 'overpaid') {
            this.toastService.warn(
                `Rechnung ${invoice.number || ''} überbezahlt`,
                'Prüfe am besten, ob alle Zahlungen richtig zugeordnet wurden.',
            );

            // Select transaction so that the overpaid invoice becomes visible.
            await this.selectTransaction(transaction);
        }

        this.replacePaymentsOfSuggestedInvoiceTwin(invoice);
    }

    public async disconnectInvoice(transaction: BankTransaction, invoice: Invoice | string): Promise<void> {
        const invoiceId = typeof invoice === 'string' ? invoice : invoice._id;
        // Disconnect invoice from transaction
        transaction.connectedInvoices = transaction.connectedInvoices.filter(
            (connectedInvoice) => connectedInvoice.invoiceId !== invoiceId,
        );
        await this.saveTransaction(transaction);
        if (typeof invoice !== 'string') {
            await this.removePaymentInInvoice(transaction, invoice);
        }
    }

    private async removePaymentInInvoice(transaction: BankTransaction, invoice: Invoice): Promise<void> {
        // Remove payment in invoice
        const matchingPaymentIndex = invoice.payments.findIndex(
            (payment) => payment.associatedBankTransactionId === transaction.transactionId,
        );
        if (matchingPaymentIndex > -1) {
            invoice.payments.splice(matchingPaymentIndex, 1);

            setPaymentStatus(invoice);
            await this.saveInvoice(invoice);
            this.invoiceUpdated.emit(invoice);

            this.replacePaymentsOfSuggestedInvoiceTwin(invoice);
        }
    }

    /**
     * Mark connection as userApproved and add payment
     */
    public async approveConnectedInvoice(transaction: BankTransaction, invoiceId: string): Promise<void> {
        const connectedInvoice = transaction.connectedInvoices.find(
            (connectedInvoice) => connectedInvoice.invoiceId === invoiceId,
        );
        if (!connectedInvoice) {
            this.toastService.error(
                'Verbundene Rechnung nicht gefunden',
                "Bitte kontaktiere die <a href='https://www.autoixpert.de/Kontakt.html'>Hotline</a>",
            );
            return;
        }
        try {
            const invoice = await this.invoiceService.get(connectedInvoice.invoiceId);
            await this.connectInvoice(transaction, invoice);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    RESOURCE_NOT_FOUND: {
                        title: 'Rechnung nicht gefunden',
                        body: 'Prüfe bitte in der Rechnungsliste, ob die verknüpfte Rechnung noch existiert.',
                    },
                },
                defaultHandler: {
                    title: 'Rechnung nicht gefunden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * Mark the connected invoice on the transaction as not yet approved by the user and remove the payment that might have been added before.
     */
    public async unapproveConnectedInvoice(transaction: BankTransaction, invoiceId: string): Promise<void> {
        const connectedInvoice = transaction.connectedInvoices.find(
            (connectedInvoice) => connectedInvoice.invoiceId === invoiceId,
        );
        if (!connectedInvoice) {
            return;
        }
        try {
            const invoice = await this.invoiceService.get(connectedInvoice.invoiceId);
            connectedInvoice.approvedByUser = false;
            await this.saveTransaction(transaction);
            // Remove payment and save both transaction and invoice.
            this.removePaymentInInvoice(transaction, invoice);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    RESOURCE_NOT_FOUND: {
                        title: 'Rechnung nicht gefunden',
                        body: 'Prüfe bitte in der Rechnungsliste, ob die verknüpfte Rechnung noch existiert.',
                    },
                },
                defaultHandler: {
                    title: 'Rechnung nicht gefunden',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public async approveAllUnapprovedInvoices(): Promise<void> {
        // Get all unapproved transactions
        const unapprovedTransactions: BankTransaction[] = this.getTransactionsRequiringApproval();

        // Mark as accepted locally
        for (const unapprovedTransaction of unapprovedTransactions) {
            for (const connectedInvoice of unapprovedTransaction.connectedInvoices) {
                connectedInvoice.approvedByUser = true;
                connectedInvoice.approvedAt = DateTime.now().toISO();
            }
        }

        try {
            await this.bankTransactionService.acceptConnectedInvoice(unapprovedTransactions);
        } catch (error) {
            // Undo marking the connection as user-approved
            for (const unapprovedTransaction of unapprovedTransactions) {
                for (const connectedInvoice of unapprovedTransaction.connectedInvoices) {
                    connectedInvoice.approvedByUser = false;
                }
            }
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Vorschläge nicht annehmbar',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /**
     * Get all transactions that require approval:
     * - Unapproved and
     * - not marked as done.
     */
    public getTransactionsRequiringApproval(): BankTransaction[] {
        return this.filteredTransactions.filter((transaction) => {
            // Require an automatically recognized invoice.
            return (
                transaction.connectedInvoices?.some(
                    (connectedInvoice) =>
                        connectedInvoice?.invoiceId &&
                        // The transaction must not be approved to require approval.
                        !connectedInvoice.approvedByUser,
                ) &&
                // If the transaction is marked as done, we exclude them from the approval process.
                !transaction.markedAsDone
            );
        });
    }

    /**
     * If the updated invoice (payment added or removed) exists within the visible invoices on the right-hand side,
     * replace that existing invoice with the latest version.
     * @param sourceInvoice
     * @private
     */
    private replacePaymentsOfSuggestedInvoiceTwin(sourceInvoice: Invoice): void {
        const targetInvoice = this.visibleInvoices.find((visibleInvoice) => visibleInvoice._id === sourceInvoice._id);
        if (targetInvoice) {
            targetInvoice.payments = sourceInvoice.payments;
        }
    }

    protected isInvoiceConnectedToTransaction(transaction: BankTransaction, invoiceId: string): boolean {
        return transaction.connectedInvoices?.some((connectedInvoice) => connectedInvoice.invoiceId === invoiceId);
    }

    protected isInvoiceSuggestionDiscarded(transaction: BankTransaction, invoiceId: string): boolean {
        return (
            // Has at least one connected invoice
            this.hasTransactionConnectedInvoices(transaction) &&
            // Is not connected to the transaction
            !this.isInvoiceConnectedToTransaction(transaction, invoiceId)
        );
    }

    protected hasTransactionConnectedInvoices(transaction: BankTransaction): boolean {
        return transaction.connectedInvoices?.some((connectedInvoice) => connectedInvoice?.invoiceId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Connect & Disconnect Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Search Invoice
    //****************************************************************************/
    public showInvoiceSearchInput(): void {
        this.invoiceSearchTerm = null;
        this.invoiceSearchInputShown = true;
        this.invoicesSearchResult = [];
        this.displayInvoices(this.invoicesSearchResult);
    }

    public hideInvoiceSearchInput(): void {
        this.invoiceSearchInputShown = false;
        this.displayInvoices(this.suggestedInvoices);
    }

    public connectInvoiceServerSearch(): void {
        this.invoiceServerSearchSubscription = this.invoiceSearchTerm$
            .pipe(
                tap((searchTerm) => {
                    if (!searchTerm) {
                        this.invoicesSearchResult = [];
                        this.displayInvoices(this.invoicesSearchResult);
                    }
                }),
                // Filter out unnecessary or inaccurate queries
                filter((searchTerm) => searchTerm && searchTerm.length >= 3),
                map((searchTerm) => searchTerm.trim()),
                // Display search loading icon
                tap(() => {
                    this.invoicesPending = true;
                }),
                // Only start a request after the user stopped typing for 300ms to reduce server load
                debounceTime(800),
                switchMap((searchTerm) =>
                    this.invoiceService.find({
                        $search: searchTerm,
                        $limit: this.MAXIMUM_NUMBER_OF_SEARCH_RESULTS,
                    }),
                ),
            )
            .subscribe((foundInvoices) => {
                this.invoicesSearchResult = foundInvoices;
                this.displayInvoices(this.invoicesSearchResult);
                this.invoicesPending = false;
            });

        this.subscriptions.push(this.invoiceServerSearchSubscription);
    }

    public updateInvoiceSearchTerm() {
        this.invoiceSearchTerm$.next(this.invoiceSearchTerm);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Search Invoice
    /////////////////////////////////////////////////////////////////////////////*/

    public displayInvoices(invoices: Invoice[]): void {
        this.visibleInvoices = invoices;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Suggested Invoices
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Format IBAN
    //****************************************************************************/
    public formatIban(iban: string): string {
        // The printFormat method cannot handle null or undefined
        return IBAN.printFormat(iban ?? '', ' ');
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Format IBAN
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Payments Dialog
    //****************************************************************************/
    public openPaymentsDialog(invoice: Invoice): void {
        this.invoiceForPaymentsDialog = invoice;
        this.paymentsDialogShown = true;
    }

    public closePaymentsDialog(): void {
        this.invoiceForPaymentsDialog = null;
        this.paymentsDialogShown = false;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Payments Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Transaction
    //****************************************************************************/
    private async loadConnectedInvoices(transaction: BankTransaction): Promise<void> {
        const connectedInvoiceIds = transaction.connectedInvoices.map((connectedInvoice) => connectedInvoice.invoiceId);
        this.connectedInvoices = await this.invoiceService
            .find({
                _id: { $in: connectedInvoiceIds },
            })
            .toPromise();
    }

    protected getTransactionPayments(transaction: BankTransaction): Payment[] {
        return this.connectedInvoices
            .flatMap((connectedInvoice) => connectedInvoice.payments)
            .filter((payment) => payment.associatedBankTransactionId === transaction.transactionId);
    }

    protected getInvoicePayments(transaction: BankTransaction, invoiceId: string): Payment[] {
        const connectedInvoice = this.connectedInvoices.find((invoice) => invoice._id === invoiceId);
        return (connectedInvoice?.payments || []).filter(
            (payment) => payment.associatedBankTransactionId === transaction.transactionId,
        );
    }

    protected getTransactionPaymentsSum(transaction: BankTransaction): number {
        return this.getTransactionPayments(transaction).reduce((sum, payment) => sum + payment.amount, 0);
    }

    protected getCollectivePaymentProgress(): number {
        return (
            (this.getTransactionPaymentsSum(this.selectedTransaction) /
                (this.selectedTransaction.amount.amount / 100)) *
            100
        );
    }

    protected getCollectivePaymentRemainingAmount(): number {
        return this.selectedTransaction.amount.amount / 100 - this.getTransactionPaymentsSum(this.selectedTransaction);
    }

    protected isCollectivePayment(): boolean {
        if (!this.selectedTransaction) {
            return false;
        }

        // Collective payment if there are multiple connected invoices
        if (this.selectedTransaction.connectedInvoices.length > 1) {
            return true;
        }

        // Collective payment if the single payment is not equal to the total gross of the invoice
        if (this.selectedTransaction.connectedInvoices.length === 1) {
            const connectedInvoice = this.connectedInvoices.find(
                (connectedInvoice) => connectedInvoice._id === this.selectedTransaction.connectedInvoices[0].invoiceId,
            );
            if (connectedInvoice) {
                const paymentsSum = this.getInvoicePaymentsSum(this.selectedTransaction, connectedInvoice._id);
                return paymentsSum && paymentsSum !== this.selectedTransaction.amount.amount / 100;
            }
        }

        return false;
    }

    protected getInvoicePaymentsSum(transaction: BankTransaction, invoiceId: string): number {
        return this.getInvoicePayments(transaction, invoiceId).reduce((sum, payment) => sum + payment.amount, 0);
    }

    public async saveTransaction(transaction: BankTransaction): Promise<void> {
        try {
            await this.bankTransactionService.update(transaction);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Buchungssatz nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    public async splitPayment(transaction: BankTransaction, invoice: Invoice): Promise<void> {
        await this.connectInvoice(transaction, invoice, this.partialPaymentAmount);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Transaction
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Save Methods
    //****************************************************************************/
    protected async saveInvoice(invoice: Invoice): Promise<void> {
        try {
            await this.invoiceService.put(invoice);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Rechnung nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    protected async saveUser(): Promise<void> {
        try {
            await this.userService.put(this.user);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: 'Benutzer nicht gespeichert',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Methods
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public closeDialog(): void {
        this.close.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    public isSmallScreen = isSmallScreen;

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcut(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                // Don't close this dialog if the Escape key was meant to close the child dialog
                if (this.paymentsDialogShown) return;

                this.markTransactionsAsSeenAndCloseDialog();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}

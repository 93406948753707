import { generateId } from '../../lib/generate-id';
import { HexColor } from '../hex-color';

export class ColorPickerPatch {
    constructor(template: Partial<ColorPickerPatch> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    hexCode: HexColor;
}

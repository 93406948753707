import { HttpClient } from '@angular/common/http';
import { captureException } from '@sentry/angular';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { AxHttpSyncBlob } from '@autoixpert/lib/database/ax-http-sync-blob.class';
import { pluralize } from '@autoixpert/lib/pluralize';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { BlobDataType, BlobEdit } from '@autoixpert/models/indexed-db/database-blob.types';
import { DatabaseServiceName } from '@autoixpert/models/indexed-db/database.types';
import { FrontendLogService } from '../../services/frontend-log.service';
import { NetworkStatusService } from '../../services/network-status.service';
import { SyncIssueNotificationService } from '../../services/sync-issue-notification.service';
import { StorageSpaceManager } from '../storage-space-manager/storage-space-manager.class';
import { AxIndexedDBBlob } from './ax-indexed-db-blob.class';
import { handlePushToServerFailureForSpecificRecord } from './handle-push-to-server-failure-for-specific-record';

/**
 * Manage blobs for various types of data in IndexedDB, e.g. original photo files, rendered photo files, watermark images, profile pictures.
 *
 * Services may extend this base class to inherit functionality. This class should not be instantiated directly.
 */
export class OfflineSyncBlobServiceBase {
    public readonly serviceName: DatabaseServiceName; // Name of the service in the backend that this database shall sync with. Singular, such as "report" for the endpoint "/reports"
    protected serviceNamePlural: string; // Plural name, such as "reports" or "contactPeople"-> Is explicitly passed or inferred automatically.
    protected networkStatusService: NetworkStatusService;
    protected frontendLogService: FrontendLogService;
    protected syncIssueNotificationService: SyncIssueNotificationService;

    /**
     * Abstraction layer over:
     *   - IndexedDB
     *   - the corresponding CRUD events
     *   - local data migrations
     *   - last access tracking and old data ejection
     *
     * This property may be overwritten if the mechanism how data is stored changes, e.g. for photo blob files.
     */
    public localDb: AxIndexedDBBlob;
    /**
     * Abstraction layer over server communication for directly reading and writing. This does
     * not include Websocket communication e.g. for realtime-editing in the report details component.
     *
     * This property may be overwritten if the mechanism to sync with the server is changed, e.g. for photo blob files.
     */
    public httpSync: AxHttpSyncBlob;

    //*****************************************************************************
    //  Local CRUD Events
    //****************************************************************************/
    public readonly createdInLocalDatabase$: Subject<BlobDataType>;
    public readonly deletedInLocalDatabase$: Subject<BlobDataType['_id']>;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Local CRUD Events
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Contains a Map that contains the recordId and the error code why the sync failed.
     */
    public recordIdsWithSyncIssues = new Map<BlobDataType['_id'], string>();

    //*****************************************************************************
    //  Debounce Patch Calls
    //****************************************************************************/
    /**
     * Goal: Only trigger an actual put every X ms, despite the client requiring a put more often.
     * This effectively throttles the amount of server put calls.
     * @private
     */
    private pushToServerTimeoutId: number;
    private pushToServerCompleted$: Subject<number>;
    private pushToServerPromise: Promise<number>;
    //private PUSH_TO_SERVER_DEBOUNCE_TIME_IN_MS = 300;
    private PUSH_TO_SERVER_DEBOUNCE_TIME_IN_MS = 1_000;

    /**
     * While a push-to-server is in progress, no second push-to-server should be started. Instead, a promise-then-function is
     * created on this.pushToServerPromise which causes another push to start when the current push-to-server completes.
     *
     * This prevents race conditions that cause DOCUMENT_VERSION_TOO_HIGH errors by ensuring that no two pushs with the same record but with a different _documentVersion
     * are sent to the server at the same time.
     */
    private pushToServerInProgress: boolean;
    private followupPushToServerPromise: Promise<number>;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Debounce Patch Calls
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Constructor
    //****************************************************************************/
    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        httpClient: HttpClient;
        networkStatusService: NetworkStatusService;
        frontendLogService?: FrontendLogService;
        syncIssueNotificationService: SyncIssueNotificationService;
        skipOutstandingSyncsCheckOnLogout?: boolean;
        formDataBlobFieldName: string;
    }) {
        this.serviceName = params.serviceName;
        this.frontendLogService = params.frontendLogService;
        this.serviceNamePlural = params.serviceNamePlural ?? pluralize(this.serviceName);
        this.networkStatusService = params.networkStatusService;
        this.syncIssueNotificationService = params.syncIssueNotificationService;

        this.localDb = new AxIndexedDBBlob({
            serviceName: params.serviceName,
            serviceNamePlural: params.serviceNamePlural,
            frontendLogService: this.frontendLogService,
        });

        this.createdInLocalDatabase$ = this.localDb.createdInLocalDatabase$;
        this.deletedInLocalDatabase$ = this.localDb.deletedInLocalDatabase$;

        this.httpSync = new AxHttpSyncBlob({
            serviceName: params.serviceName,
            serviceNamePlural: params.serviceNamePlural,
            httpClient: params.httpClient,
            formDataBlobFieldName: params.formDataBlobFieldName,
        });

        /**
         * Multiple purposes:
         * - Allow old data to be removed in case of little IndexedDB storage space.
         * - Check for unsynced changes on logout. Clear data if there are no unsynced changes or the user wants to remove all unsynced changes.
         */
        StorageSpaceManager.registerService(this);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Constructor
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Create
    //****************************************************************************/
    public async create(record: BlobDataType, options: { waitForServer?: boolean } = {}): Promise<BlobDataType> {
        let localResult: BlobDataType;

        /**
         * In some cases, the local Db rejects blobs.
         * One example: mobile Safari in private mode does not allow IndexedDB to store blobs, see: https://github.com/jakearchibald/idb/issues/58#issuecomment-411758812
         */
        try {
            localResult = await this.localDb.createLocal(record);
        } catch (error) {
            throw new AxError({
                code: 'SAVING_BLOB_ON_INDEXEDDB_FAILED',
                message:
                    'The record could not be saved to the local IndexedDB. This may be caused due to the browser being in incognito mode or a different browser side error.',
                data: {
                    serviceName: this.serviceName,
                    recordId: record._id,
                },
                error,
            });
        }

        if (options.waitForServer) {
            // If creating the record on the server is required, throw an error if offline.
            if (!this.networkStatusService.isOnline()) {
                throw new AxError({
                    code: 'CANNOT_CREATE_RECORD_ON_SERVER_WHILE_OFFLINE',
                    message:
                        'The create call required the record to be created on the server but the client is offline.',
                });
            }

            // Wait for server sync before returning.
            await this.pushToServer();
            handlePushToServerFailureForSpecificRecord({
                recordId: record._id,
                syncIssueNotificationService: this.syncIssueNotificationService,
                recordIdsWithSyncIssues: this.recordIdsWithSyncIssues,
                serviceName: this.serviceName,
            });
        }
        // Check online status before even trying to send data through the socket to avoid Socket.io buffering partial calls.
        else if (this.networkStatusService.isOnline()) {
            void this.pushToServer();
        }
        return localResult;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Create
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Get
    //****************************************************************************/

    /**
     * Returns a local record. If online, pulls changes from server before returning the local record.
     *
     * TODO Maybe implement a batch mode for getting these blobs, e.g. for getting all thumbnails for the report overview.
     */
    public async get(recordId: BlobDataType['_id'], blobContentHash: BlobDataType['blobContentHash']): Promise<Blob> {
        let localRecord: BlobDataType = await this.localDb.getLocal(recordId);

        if (localRecord) {
            // Great, the cached version is the one we need! Return it.
            if (localRecord.blobContentHash === blobContentHash) {
                return localRecord.blob;
            } else {
                const recordExistsLocallyOnly: boolean = (await this.localDb.getLocalStatus(recordId)) === 'created';
                /**
                 * Ensure a cache miss does not delete the record locally if it has not yet been uploaded to the server.
                 * This may happen if a component has an old hash with which it queries the local IndexedDB.
                 */
                if (!recordExistsLocallyOnly) {
                    /**
                     * Since the hash value is outdated, delete the locally saved version without propagating this change to the server, thus treat this as an "external"
                     * change, not a user-triggered change.
                     * The client will try to load the current record from the server below.
                     */
                    await this.localDb.deleteLocal(recordId, 'external');
                }
                localRecord = undefined;
            }
        }

        if (!localRecord) {
            if (this.networkStatusService.isOnline()) {
                localRecord = {
                    _id: recordId,
                    blob: await this.httpSync.getRemote(recordId),
                    /**
                     * We assume that the content that came from the server equals the hash that was used to request this locally saved blob.
                     * That may not be the case if the blob was updated on the server in the meantime. Since that's very rare and usually does not
                     * have a lot of consequences (maybe an outdated rendered photo preview), go for performance and reuse the hash. Hashing a blob it CPU-intensive.
                     */
                    blobContentHash,
                };

                /**
                 * Do not await writing this cache entry for performance reasons.
                 *
                 * Consider this an external change since getting this blob should not make this client write it back to the server as new during the next pushToServer.
                 */
                this.localDb.createLocal(localRecord, 'external').catch((error) => {
                    console.error(
                        `[offline-sync ${this.serviceName}] Writing blob to cache failed in "${this.serviceName}" service.`,
                        { error },
                    );
                });
            } else {
                throw new AxError({
                    code: 'BLOB_CANNOT_BE_FETCHED_FROM_SERVER_WHILE_OFFLINE',
                    message: `Since this device is offline and the requested resource does not exist locally, there is no way to provide it. Please go back online.`,
                    data: {
                        serviceName: this.serviceName,
                        recordId,
                        blobContentHash,
                    },
                });
            }
        }

        return localRecord.blob;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Get
    /////////////////////////////////////////////////////////////////////////////*/

    public async delete(recordId: BlobDataType['_id'], options: { waitForServer?: boolean } = {}): Promise<void> {
        const localResult = await this.localDb.deleteLocal(recordId);

        if (options.waitForServer) {
            // If deleting the record on the server is required, throw an error if offline.
            if (!this.networkStatusService.isOnline()) {
                throw new AxError({
                    code: 'CANNOT_DELETE_RECORD_ON_SERVER_WHILE_OFFLINE',
                    message: 'The delete call required the server to be updated but the client is offline.',
                });
            }

            // Wait for server sync before returning.
            await this.pushToServer();
            handlePushToServerFailureForSpecificRecord({
                recordId: recordId,
                syncIssueNotificationService: this.syncIssueNotificationService,
                recordIdsWithSyncIssues: this.recordIdsWithSyncIssues,
                serviceName: this.serviceName,
            });
        } else if (this.networkStatusService.isOnline()) {
            void this.pushToServer();
        }

        return localResult;
    }

    /**
     * Reset a record to resolve sync issues that are caused by a local misconfiguration, e.g. due to an old
     * version on the client having created a record in an old format.
     */
    public async reset(recordId: BlobDataType['_id']): Promise<void> {
        await this.localDb.resetLocal(recordId);

        // Remove from sync issues if present.
        this.recordIdsWithSyncIssues.delete(recordId);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Public CRUD-API
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Push all changes (creations, deletions) to the server.
     *
     * Calls are throttled by 300 ms. All callers within a throttled group receive the same
     * shared promise which will be resolved once the push to server triggered by the latest
     * call to this method has finished.
     *
     * Example:
     * Caller A:    0 ms - Receives Promise A
     * Caller B: +200 ms - Receives Promise A
     *           +300 ms - Promise A is resolved or rejected, depending on the result of _pushToServer.
     * ---
     * Caller C: +500 ms - Receives Promise B
     * Caller D: + 50 ms - Received Promise B
     *           +300 ms - Promise B is resolved or rejected, depending on the result of _pushToServer.
     */
    public async pushToServer(): Promise<number> {
        // First caller sets up the promise that resolves when the push-to-server completes.
        if (!this.pushToServerPromise) {
            //console.log('Push-to-server promise is being created.');
            // Create subject if no current timer is running.
            this.pushToServerCompleted$ = new Subject();
            // Create & save promise that resolves when the subject completes
            this.pushToServerPromise = new Promise((resolve, reject) => {
                this.pushToServerCompleted$.subscribe({
                    next: (numberOfChangedRecords: number) => {
                        //console.log('Push-to-server next.');

                        resolve(numberOfChangedRecords);
                    },
                    error: (error) => {
                        //console.log('Push-to-server failed.');

                        // Allow a new promise/subject to be created next time this.pushToServer() is called.
                        this.pushToServerPromise = null;
                        this.pushToServerCompleted$ = null;

                        reject(error);
                    },
                    /**
                     * Complete is called if the subject completes without error. See https://reactivex.io/documentation/contract.html.
                     */
                    complete: () => {
                        //console.log('Push-to-server complete.');
                        // Allow a new promise/subject to be created next time this.pushToServer() is called.
                        this.pushToServerPromise = null;
                        this.pushToServerCompleted$ = null;
                    },
                });
            });
        }

        /**
         * If a push-to-server is currently active, schedule the second one for later to prevent DOCUMENT_VERSION_TOO_HIGH errors. See details at the definition of this.pushToServerInProgress.
         */
        if (this.pushToServerInProgress) {
            console.log(`⏱ [offline-sync ${this.serviceName}] Push-to-server in progress. Return follow-up promise.`);
            /**
             * Only register a then-function if none has been registered for the current push-to-server. Otherwise, multiple push-to-server would be triggered when the current push-to-server
             * completes. That would cause too many requests or (since there is a timeout catching those calls) would increase the debugging complexity due to multiple unintuitive pushToServer() calls.
             */
            if (!this.followupPushToServerPromise) {
                /**
                 * Push changes to the server again since a pushToServer() throttle timeout was reached while an earlier pushToServer() was still in progress.
                 */
                console.log(
                    `⏱ [offline-sync ${this.serviceName}] Creating a follow-up promise to push to the server after the current push-to-server completes.`,
                );
                this.followupPushToServerPromise = this.pushToServerPromise.then(() => {
                    //console.log('⏱ Previous pushToServer() completed. Start the follow-up to sync changes that were introduced in the meantime.');
                    /**
                     * Since the follow-up push to server is triggered when currentPushToServerCompletedSubject emits the next value, allow the next push-to-server registration for the next time pushToServer() is called multiple times
                     * at the same time.
                     */
                    this.followupPushToServerPromise = null;

                    return this.pushToServer().then((pushToServerResult) => {
                        console.log(`✅ [offline-sync ${this.serviceName}] Follow-up push-to-server completed.`);
                        return pushToServerResult;
                    });
                });
            }
            return this.followupPushToServerPromise;
        }

        /**
         * If a timer is already running, clear it and start a new one. This enables throttling.
         */
        if (this.pushToServerTimeoutId) {
            window.clearTimeout(this.pushToServerTimeoutId);
            //console.log('Timeout pushToServer() cleared.');
        }

        // Set timeout: complete subject
        this.pushToServerTimeoutId = window.setTimeout(async () => {
            //console.log('Timeout pushToServer() reached.');
            /**
             * As soon as this timeout is reached, let the this.pushToServerPromise be recreated next time pushToServer() is called. This is necessary
             * so that multiple pushToServer() calls can be executed one after another.
             */
            this.pushToServerTimeoutId = null;

            this.pushToServerInProgress = true;

            let numberOfPushedRecords: number;
            try {
                numberOfPushedRecords = await this._pushToServerUnthrottled();
            } catch (error) {
                let errorMessage: [string, string];
                //*****************************************************************************
                //  Handle Closed IndexedDB Connection
                //****************************************************************************/
                /**
                 * Some browsers kill the IndexedDB connection after some time. This alert tells the user that's the case.
                 * The automatic re-opening of the IndexedDB connection in the file ax-indexed-db-blob.class.ts should avoid
                 * our code ever running into this error handler branch.
                 */
                if (error.name === 'InvalidStateError') {
                    errorMessage = [
                        'Lokaler Speicher nicht erreichbar.',
                        `[Service: ${this.serviceName}] Die Verbindung zur IndexedDB wurde geschlossen.\n\nBitte lade die Seite neu, damit der Sync wieder funktioniert.`,
                    ];

                    /**
                     * Capture in Sentry for better transparency how often this occurs.
                     */
                    const axError = new AxError({
                        code: 'BLOB_PUSH_TO_SERVER_FAILED_DUE_TO_CLOSED_INDEXEDDB_CONNECTION',
                        message:
                            'The BLOB push to server failed because the connection to the IndexedDB was closed unexpectedly.',
                        data: {
                            serviceName: this.serviceName,
                        },
                    });
                    captureException(axError);

                    // The toast service is rarely passed to this base class. Notify the user through an alert.
                    window.alert(errorMessage.join('\n\n'));
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Handle Closed IndexedDB Connection
                /////////////////////////////////////////////////////////////////////////////*/
                throw error;
            }
            this.pushToServerInProgress = false;

            this.pushToServerCompleted$.next(numberOfPushedRecords);
            this.pushToServerCompleted$.complete();
        }, this.PUSH_TO_SERVER_DEBOUNCE_TIME_IN_MS);

        // Return a shared promise to all throttled calls.
        return this.pushToServerPromise;
    }

    /**
     * This method has been extracted to simplify the throttling code within pushToServer.
     *
     * Returns the number of pushed records.
     */
    private async _pushToServerUnthrottled(): Promise<number> {
        const [creationChangeRecords, deletionChangeRecords] = await Promise.all([
            this.localDb.getCreationChangeRecords(),
            this.localDb.getDeletionChangeRecords(),
        ]);

        //*****************************************************************************
        //  Derive Required Edits
        //****************************************************************************/
        /**
         * Contains exactly one object per record if the record was created or deleted.
         * Contains no object for unmodified records since those don't need to be synced.
         *
         * The RecordEdit object contains info about how the record has been edited locally (created, deleted).
         */
        const edits = new Map<BlobDataType['_id'], BlobEdit>();

        const allChangeRecords: { _id: string }[] = [...creationChangeRecords, ...deletionChangeRecords];

        // Create a map entry for each record, no matter if it has been created or deleted.
        for (const record of allChangeRecords) {
            edits.set(record._id, {
                // The actual flags will be set later.
                created: false,
                deleted: false,
            });
        }

        for (const creationChangeRecord of creationChangeRecords) {
            const edit = edits.get(creationChangeRecord._id);
            edit.created = true;
        }

        for (const deletionChangeRecord of deletionChangeRecords) {
            const edit = edits.get(deletionChangeRecord._id);
            edit.deleted = true;
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Derive Required Edits
        /////////////////////////////////////////////////////////////////////////////*/

        const editEntries: [BlobDataType['_id'], BlobEdit][] = [...edits.entries()];

        /**
         * Sync changes to server.
         *
         * A record cannot be created, patched or deleted at the same time. A local-only created record will be automatically removed
         * locally by the central deletion routine. A locally created record that was locally updated still counts as "created".
         *
         * Execute server requests in parallel. That increases performance in comparison to syncing each record on its own.
         */
        const promiseSettledResult = await Promise.allSettled(
            editEntries.map(async ([recordId, edit]) => {
                try {
                    //*****************************************************************************
                    //  Record Created
                    //****************************************************************************/
                    /**
                     * Scenario 1: Record was created and not edited --> create on server.
                     * Scenario 2: Record was created and edited --> create on server.
                     */
                    if (edit.created) {
                        const blobRecord: BlobDataType = await this.localDb.getLocal(recordId);
                        if (!blobRecord) {
                            throw new Error(
                                `Record "${recordId}" not found for creating a record on the remote server.`,
                            );
                        }

                        try {
                            await this.httpSync.createRemote({
                                _id: recordId,
                                blob: blobRecord.blob,
                            });

                            // In case the record has sync issues before, clear that.
                            this.recordIdsWithSyncIssues.delete(recordId);

                            /**
                             * In contrast to the regular offline-sync service, this blob service does not use optimistic deletes since
                             * there is no need to reflect the deletion in the IndexedDB immediately because blobs are typically updated
                             * less frequently (or not at all as in the case of original photo files).
                             * This ensures that blobs are always correctly created. With optimistic writes, we had issues with that.
                             */
                            await this.localDb.deleteCreationChangeRecord(recordId);

                            // In case a notification was added to the SyncIssueNotificationService, remove it since this sync issue was resolved.
                            this.syncIssueNotificationService.resolve({
                                databaseService: this,
                                recordId,
                            });
                        } catch (error) {
                            /**
                             * If the record with this _id already exists on the server, consider this record created.
                             */
                            if (error?.code === 'ID_COLLISION') {
                                console.log(
                                    `[offline-sync ${this.serviceName}] This record should have been created on the server but it already exists. Do not try to create it on the server anymore.`,
                                    recordId,
                                );
                            } else {
                                console.log(`[offline-sync ${this.serviceName}] Create on server failed.`, { error });

                                this.recordIdsWithSyncIssues.set(recordId, error.code);
                                // Show a notification to the user that there were sync issues so that the user may resolve them.
                                this.syncIssueNotificationService.create({
                                    heading: 'Anlage fehlgeschlagen',
                                    reason: 'Der Datensatz konnte nicht auf dem Server angelegt werden.',
                                    databaseService: this,
                                    recordId,
                                    error: error,
                                });

                                // Add records back that were optimistically deleted.
                                await this.localDb.setCreationChangeRecord(recordId);

                                throw error;
                            }
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Record Created
                    /////////////////////////////////////////////////////////////////////////////*/

                    //*****************************************************************************
                    //  Record Deleted
                    //****************************************************************************/
                    /**
                     * Scenario 1: Record exists on server and was locally deleted and not edited --> delete on server.
                     * Scenario 2: Record exists on server and was locally deleted and edited --> delete on server.
                     */
                    if (edit.deleted) {
                        try {
                            await this.httpSync.deleteRemote(recordId);

                            // In case the record has sync issues before, clear that.
                            this.recordIdsWithSyncIssues.delete(recordId);

                            /**
                             * In contrast to the regular offline-sync service, this blob service does not use optimistic deletes since
                             * there is no need to reflect the deletion in the IndexedDB immediately because blobs are typically updated
                             * less frequently (or not at all as in the case of original photo files).
                             * This ensures that blobs are always correctly deleted. With optimistic writes, we had issues with that.
                             */
                            await this.localDb.deleteDeletionChangeRecord(recordId);

                            // In case a notification was added to the SyncIssueNotificationService, remove it since this sync issue was resolved.
                            this.syncIssueNotificationService.resolve({
                                databaseService: this,
                                recordId: recordId,
                            });
                        } catch (error) {
                            if (error?.name === 'NotFound') {
                                // If the server record could not be deleted simply because it was already deleted, consider this a success.
                            } else {
                                console.error(`[offline-sync ${this.serviceName}] Delete on server failed.`, { error });

                                this.recordIdsWithSyncIssues.set(recordId, error.code);
                                // Show a notification to the user that there were sync issues so that the user may resolve them.
                                this.syncIssueNotificationService.create({
                                    heading: 'Löschen fehlgeschlagen',
                                    reason: 'Der Datensatz konnte nicht auf dem Server gelöscht werden.',
                                    databaseService: this,
                                    recordId: recordId,
                                    error: error,
                                });

                                // Add records back that were optimistically deleted.
                                await this.localDb.setDeletionChangeRecord(recordId);

                                throw error;
                            }
                        }
                    }
                    /////////////////////////////////////////////////////////////////////////////*/
                    //  END Record Deleted
                    /////////////////////////////////////////////////////////////////////////////*/
                } catch (error) {
                    // Because Promise.allSettled() swallows errors, we must explicitly log them here.
                    console.error(
                        `[offline-sync ${this.serviceName}] An error occurred while pushing blobs to server.`,
                        { error },
                    );

                    // Error is currently swallowed by Promise.allSettled() but in case someone uses the returned array, this promise will be properly rejected.
                    throw error;
                }
            }),
        );

        const rejectedPromiseResults: PromiseRejectedResult[] = (
            promiseSettledResult as PromiseRejectedResult[]
        ).filter((result) => result.status === 'rejected');
        if (rejectedPromiseResults.length) {
            throw new AxError({
                code: 'OFFLINE_SYNC_PUSH_TO_SERVER_FAILED',
                message: 'There were issues syncing changes to the server. See data for a list of errors.',
                data: {
                    serviceName: this.serviceName,
                    errors: rejectedPromiseResults.map((result) => result.reason),
                },
            });
        }

        // How many records have been changed?
        return edits.size;
    }

    //*****************************************************************************
    //  Invalidate Cache / Remove Data
    //****************************************************************************/
    public async hasOutstandingSyncs(): Promise<boolean> {
        return !!(await this.localDb.getNumberOfOutstandingSyncs());
    }

    /**
     * Used for clearing all object stores when logging out.
     */
    public async clearDatabase() {
        await this.localDb.clearObjectStores();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Invalidate Cache / Remove Data
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Database Size
    //****************************************************************************/
    /**
     * Used in storage usage panel component.
     */
    public async getDatabaseSize() {
        return this.localDb.getDatabaseSize();
    }

    /**
     * Used through StorageSpaceManager.registerService(this).
     */
    public async ejectData(lastAccessedBefore?: DateTime): Promise<void> {
        await this.localDb.ejectData(lastAccessedBefore);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Database Size
    /////////////////////////////////////////////////////////////////////////////*/
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Report } from '@autoixpert/models/reports/report';
import { User } from '@autoixpert/models/user/user';

@Injectable()
export class Crashback24Service {
    constructor(private httpClient: HttpClient) {}

    public checkCredentials(userId: User['_id']): Observable<{ success: true }> {
        return this.httpClient.post<{ success: true }>(`/api/v0/users/${userId}/crashback24/authentication`, null);
    }

    public createProcess(report: Report): Observable<CreateProcessResponse> {
        return this.httpClient.post<CreateProcessResponse>(`/api/v0/reports/${report._id}/crashback24/exports`, null);
    }
}

export interface CreateProcessResponse {
    crashback24ProcessId: number;
}

import { Component, HostListener, Inject } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MarketAnalysis } from '@autoixpert/models/reports/market-value/market-analysis';
import { ToastService } from '../../../../shared/services/toast.service';

/**
 * Connect a market analysis of Winvalue or CARTV by hand.
 *
 * This is usually necessary if
 * - the market analysis has been started outside autoiXpert.
 * - the market analysis has been removed because the user switched report types.
 */
@Component({
    selector: 'market-analysis-connection-dialog',
    templateUrl: './market-analysis-connection-dialog.component.html',
    styleUrls: ['./market-analysis-connection-dialog.component.scss'],
})
export class MarketAnalysisConnectionDialogComponent {
    constructor(
        private dialogRef: MatDialogRef<MarketAnalysisConnectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: MarketAnalysisConnectionDialogData,
        private toastService: ToastService,
    ) {}

    ngOnInit() {
        switch (this.dialogData.provider) {
            case 'cartv':
                this.marketAnalysisProviderName = 'CARTV';
                break;
            case 'valuepilot':
                this.marketAnalysisProviderName = 'VALUEpilot';
                break;
            case 'winvalue':
                this.marketAnalysisProviderName = 'WinValue';
                break;
        }
    }

    public marketAnalysisProviderName: 'WinValue' | 'CARTV' | 'VALUEpilot';
    public marketAnalysisId: number;

    public getSubmitButtonTooltip(): string {
        if (!this.marketAnalysisId) {
            return 'Bitte die Marktanalyse-ID eingeben.';
        }
        return '';
    }

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public emitAnalysisId() {
        if (!this.marketAnalysisId) {
            this.toastService.error('Bitte die Marktanalyse-ID eingeben.');
            return;
        }

        const marketAnalysis = new MarketAnalysis();
        marketAnalysis.analysisId = `${this.marketAnalysisId}`;

        this.dialogRef.close(marketAnalysis || true);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close Dialog
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close Dialog
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Window Event Listeners
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Window Event Listeners
    /////////////////////////////////////////////////////////////////////////////*/
}

export interface MarketAnalysisConnectionDialogData {
    provider: 'cartv' | 'valuepilot' | 'winvalue';
}

export type ExternalMarketAnalysisProvider = 'cartv' | 'valuepilot' | 'winvalue';

import { ContactPerson } from '@autoixpert/models/contacts/contact-person';
import { InvoiceParameters } from '@autoixpert/models/invoices/invoice-parameters';
import { Report } from '@autoixpert/models/reports/report';

export function getInvoiceRecipientByRole(
    recipientRole: InvoiceParameters['recipientRole'],
    report: Report,
): ContactPerson {
    switch (recipientRole) {
        case 'garage':
            return report.garage?.contactPerson;
        case 'insurance':
            return report.insurance?.contactPerson;
        case 'lawyer':
            return report.lawyer?.contactPerson;
        case 'leaseProvider':
            return report.leaseProvider?.contactPerson;
        case 'custom':
            return report.feeCalculation?.invoiceParameters?.recipient;
        case 'claimant':
            return report.claimant?.contactPerson;
        case 'ownerOfClaimantsCar':
            return report.ownerOfClaimantsCar?.contactPerson;
        default:
            return report.claimant?.contactPerson;
    }
}

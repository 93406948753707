import { ContactPerson } from '../../models/contacts/contact-person';

/**
 * This method checks if at least one of the properties
 * - organization
 * - firstName
 * - lastName
 * is filled.
 *
 * Use case:
 * Sometimes, we only want to copy an involved party from a report to an invoice if
 * the contact person is filled out.
 */
export function isNameOrOrganizationFilled(contactPerson: ContactPerson): boolean {
    if (!contactPerson) return null;

    return !!contactPerson.organization || !!contactPerson.firstName || !!contactPerson.lastName;
}

import { escapeRegExp } from 'lodash-es';

/**
 * What properties shall be searched with a search term?
 * @param searchTerm
 * @protected
 */
export function get$SearchMongoQueryTasks(searchTerm: string): [string, any] {
    const searchTerms = searchTerm.split(' ');
    // An array equal to the number of search terms given in the input field.
    const searchesPerSearchTerm = [];

    // All search terms must match at least one of the specified document fields.
    // Tech: Create an individual $and condition for every search term. The search term again consists of conditions linked with $or.
    for (const searchTerm of searchTerms) {
        const mongodbRegex = { $regex: new RegExp(escapeRegExp(searchTerm), 'i') };
        searchesPerSearchTerm.push({
            $or: [{ title: mongodbRegex }, { description: mongodbRegex }],
        });
    }

    return ['$and', searchesPerSearchTerm];
}

import { DocumentBuildingBlock } from '../../models/documents/document-building-block';

/**
 * Sort document building blocks by the provided document order structure.
 *
 * If a block either doesn't exist in the structure or in the provided blocks array, it will be skipped.
 */
export function sortBuildingBlocksAccordingToTemplateConfig(
    buildingBlocks: DocumentBuildingBlock[],
    documentBuildingBlockIds: DocumentBuildingBlock['_id'][],
): DocumentBuildingBlock[] {
    const sortedBlocks: DocumentBuildingBlock[] = [];

    for (const documentBuildingBlockId of documentBuildingBlockIds) {
        const matchingBlock = buildingBlocks.find((block) => block._id === documentBuildingBlockId);
        if (matchingBlock) {
            sortedBlocks.push(matchingBlock);
        }
    }

    return sortedBlocks;
}

import { CarEquipmentPosition } from '../../models/reports/car-identification/car-equipment';

/**
 * The DAT has some vehicles where the same equipment is listed under different equipment IDs in the valuation equipment and the calculation equipment. To prevent
 * duplicates in the DOCX/PDF report, ensure equipment position descriptions are unique.
 *
 * This should *not* be done when importing equipment because then equipment positions would be missing for either the calculation or the valuation.
 *
 * Example VIN: WVWZZZ1KZCW112321. Equipment position "Schadstoffarm nach Abgasnorm Euro 5" exists for both valuation and calculation equipment.
 */
export function removeEquipmentDuplicates(equipmentPositions: CarEquipmentPosition[]): CarEquipmentPosition[] {
    if (!equipmentPositions) {
        return [];
    }

    const equipmentDescriptionMap = new Map<string, CarEquipmentPosition>();
    for (const equipmentPosition of equipmentPositions) {
        equipmentDescriptionMap.set(equipmentPosition.description, equipmentPosition);
    }
    return Array.from(equipmentDescriptionMap.values());
}

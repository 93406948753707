import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacySelectChange } from '@angular/material/legacy-select/index';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { PaintThicknessLabelMap } from '@autoixpert/models/reports/paint-thickness-measurement';
import { PaintThicknessScale, defaultPaintThicknessScale } from '@autoixpert/models/reports/paint-thickness-scale';
import { getMissingAccessRightTooltip } from '../../../../../shared/libraries/get-missing-access-right-tooltip';
import { hasAccessRight } from '../../../../../shared/libraries/user/has-access-right';

@Component({
    selector: 'paint-thickness-scale-selector',
    templateUrl: './paint-thickness-scale-selector.component.html',
    styleUrls: ['./paint-thickness-scale-selector.component.scss'],
})
export class PaintThicknessScaleSelectorComponent {
    @Input() paintThicknessScale: PaintThicknessScale = defaultPaintThicknessScale;
    @Input() disabled: boolean = false;
    @Input() hideScaleDropdown: boolean = false;
    @Input() allowEditing: boolean = false;
    @Input() userIsAllowedToEditScales: boolean = false;
    @Input() availablePaintThicknessScales: PaintThicknessScale[] = [];
    @Output() openEditScaleDialogClicked: EventEmitter<void> = new EventEmitter<void>();
    @Output() scaleWasEdited: EventEmitter<void> = new EventEmitter<void>();
    @Output() scaleWasSelected: EventEmitter<string> = new EventEmitter<string>();

    protected editPaintThicknessScales(): void {
        if (!this.userIsAllowedToEditScales) {
            return;
        }
        this.openEditScaleDialogClicked.emit();
    }
    protected configSelected(changeEvent: MatLegacySelectChange): void {
        this.paintThicknessScale = this.availablePaintThicknessScales.find(
            (config) => config._id === changeEvent.source.value,
        );
        this.scaleWasSelected.emit(this.paintThicknessScale._id);
    }

    /**
     * When the user changed a threshold value in the UI, we first need to translate the value (e.g. <= 70) into the min and max
     * value of the selected interval. Next up we need to check if the new value interferes with any of the neighbouring intervals.
     * E.g.: If the new value is smaller than the maxValue of the previous interval, we need to update the previous interval as well
     * (and the other way round).
     */
    protected userChangedRangeValue(inputElement: EventTarget, index: number): void {
        const newValue = (inputElement as HTMLInputElement).value;
        const parsedValue = parseInt(newValue, 10);
        const scale = this.paintThicknessScale.rangeDefinitions[index];

        switch (index) {
            case 0:
                if (parsedValue > scale.maxValue) {
                    this.updateRangeValueIntervalsAfterIndex(index, parsedValue);
                }

                scale.maxValue = parsedValue - 1;
                this.paintThicknessScale.rangeDefinitions[1].minValue = parsedValue;

                break;
            case 1:
                if (parsedValue > scale.maxValue) {
                    this.updateRangeValueIntervalsAfterIndex(index, parsedValue);
                }

                scale.minValue = parsedValue;
                this.paintThicknessScale.rangeDefinitions[0].maxValue = parsedValue - 1;

                break;
            default:
                if (parsedValue > scale.maxValue) {
                    this.updateRangeValueIntervalsAfterIndex(index, parsedValue + 1);
                }

                if (parsedValue < scale.minValue) {
                    this.updateRangeValueIntervalsBeforeIndex(index, parsedValue + 1);
                }

                scale.minValue = parsedValue + 1;
                this.paintThicknessScale.rangeDefinitions[index - 1].maxValue = parsedValue;

                break;
        }

        this.scaleWasEdited.emit();
    }

    /**
     * Update the maxValue of the interval (that the user edited) and check each following interval (to the right).
     * If the new maxValue of the selected interval is larger than a minValue of any following interval, we need to
     * update them.
     */
    private updateRangeValueIntervalsAfterIndex(afterIndex: number, selectedValue: number): void {
        let currentValue = selectedValue;

        for (let i = afterIndex; i < this.paintThicknessScale.rangeDefinitions.length; i++) {
            if (currentValue <= this.paintThicknessScale.rangeDefinitions[i].maxValue) {
                break;
            }

            // The last interval always has a maxValue of +Infinity (because it is open ended)
            const isLastItem = i === this.paintThicknessScale.rangeDefinitions.length - 1;
            this.paintThicknessScale.rangeDefinitions[i].maxValue = isLastItem ? Infinity : currentValue;

            if (i + 1 < this.paintThicknessScale.rangeDefinitions.length) {
                this.paintThicknessScale.rangeDefinitions[i + 1].minValue = currentValue + 1;
            }

            currentValue++;
        }
    }

    /**
     * Update the minValue of the interval (that the user edited) and check each previous interval (to the left).
     * If the new minValue of the selected interval is smaller than a maxValue of any previous interval, we need to
     * update them.
     */
    private updateRangeValueIntervalsBeforeIndex(beforeIndex: number, selectedValue: number): void {
        let currentValue = selectedValue;

        for (let i = beforeIndex; i >= 0; i--) {
            if (currentValue >= this.paintThicknessScale.rangeDefinitions[i].minValue) {
                break;
            }

            // The first interval always has a minValue of -Infinity (because it is open ended)
            const isFirstItem = i === 0;
            this.paintThicknessScale.rangeDefinitions[i].minValue = isFirstItem ? -Infinity : currentValue;

            if (i - 1 >= 0) {
                this.paintThicknessScale.rangeDefinitions[i - 1].maxValue = currentValue - 1;
            }

            currentValue--;
        }
    }

    protected readonly PaintThicknessLabelMap = PaintThicknessLabelMap;
    protected readonly trackById = trackById;
    protected readonly hasAccessRight = hasAccessRight;
    protected readonly getMissingAccessRightTooltip = getMissingAccessRightTooltip;
}

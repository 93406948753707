import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { getPaintThicknessSummaryLabel } from '@autoixpert/lib/paint-thickness/get-paint-thickness-summary-label';
import { getPaintThicknessType } from '@autoixpert/lib/paint-thickness/get-paint-thickness-type';
import { trackByIndex } from '@autoixpert/lib/track-by/track-by-index';
import {
    PaintThicknessColorMap,
    PaintThicknessLabelMap,
    PaintThicknessMeasurement,
    PaintThicknessType,
    paintThicknessTypes,
} from '@autoixpert/models/reports/paint-thickness-measurement';
import { PaintThicknessScale } from '@autoixpert/models/reports/paint-thickness-scale';

export interface PaintThicknessMeasurementTypeSelection {
    type: PaintThicknessType;
    noMeasurementReason?: string;
}

@Component({
    selector: 'paint-thickness-input',
    templateUrl: './paint-thickness-input.component.html',
    styleUrls: ['./paint-thickness-input.component.scss'],
    host: {
        '[class.left]': "this.side === 'left'",
        '[class.center]': "this.side === 'center'",
        '[class.right]': "this.side === 'right'",
        '[class.is-editing]': 'this.isEditing',
    },
})
export class PaintThicknessInputComponent implements OnInit {
    @ViewChildren('inputField') inputFields: QueryList<ElementRef>;

    @Input()
    get paintThicknessMeasurement(): PaintThicknessMeasurement {
        return this._paintThicknessMeasurement;
    }

    set paintThicknessMeasurement(value: PaintThicknessMeasurement) {
        this._paintThicknessMeasurement = value;

        this.determineType();
    }

    @Input()
    get paintThicknessScale(): PaintThicknessScale {
        return this._paintThicknessScale;
    }

    set paintThicknessScale(value: PaintThicknessScale) {
        this._paintThicknessScale = value;
        this.determineType();
    }

    private _paintThicknessMeasurement: PaintThicknessMeasurement = null;
    private _paintThicknessScale: PaintThicknessScale;
    @Input() hideLabel: boolean = false;
    @Input() commentIconPosition: 'right' | 'bottom' = 'right';
    @Input() disableActions: boolean = false;
    @Input() disabled: boolean = false;
    @Input() type: PaintThicknessType = null;
    @Input() paintThicknessPosition: PaintThicknessMeasurement['position'];

    @Output() valueChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() updateManualTypeForAll = new EventEmitter<PaintThicknessMeasurementTypeSelection>();

    protected side: 'left' | 'center' | 'right';
    protected isEditing: boolean = false;
    protected commentPopoverShown: boolean = false;

    @HostListener('document:click', ['$event'])
    clickedOutside(event) {
        // Whenever the user clicks outside this component (even if clicking the same component but other instance)
        // we want to stop editing and show the summary label again

        const belongsToComponentButIsNotInDomCurrently =
            event.target.closest('.paint-thickness-input')?.dataset.id === this.paintThicknessMeasurement._id;

        if (belongsToComponentButIsNotInDomCurrently) {
            // It might happen, that the user clicked the summary label, which gets hidden by ngIf. In that case
            // the check elementRef.nativeElement.contains(event.target) will return false, because the element
            // is not in the DOM anymore. Hence, we have our own check with a custom data-id attribute to determine
            // whether the clicked element belongs to this instance
            return;
        }

        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isEditing = false;
            this.commentPopoverShown = false;
        }
    }

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        this.side = this.getPaintThicknessMeasurementSide();
        this.determineType();
    }

    public determineType(): void {
        if (!this.paintThicknessMeasurement) {
            return;
        }

        if (this.paintThicknessMeasurement.manualType) {
            this.type = this.paintThicknessMeasurement.manualType;
        } else {
            this.type = getPaintThicknessType(this.paintThicknessMeasurement, this._paintThicknessScale);
        }
    }

    //*****************************************************************************
    //  Positioning
    //****************************************************************************/
    /**
     * Get the side of a paint thickness measurement relative to the car body.
     *
     * The return value is used as a class which positions the measurement labels accordingly.
     */
    public getPaintThicknessMeasurementSide(): 'left' | 'center' | 'right' {
        switch (this.paintThicknessPosition) {
            case 'frontLeft':
            case 'fenderFrontLeft':
            case 'doorBackPassengerLeft':
            case 'fenderRearLeft':
            case 'centerLeft':
            case 'rearLeft':
            case 'leftWallFront':
            case 'leftWallCenter':
            case 'leftWallRear':
            case 'frameLeft':
            case 'doorDriver':
                return 'left';

            case 'hood':
            case 'windshield':
            case 'roof':
            case 'rearWindow':
            case 'roofRear':
            case 'ceilingFront':
            case 'ceilingCenter':
            case 'ceilingRear':
            case 'frontCenter':
            case 'rearCenter':
                return 'center';

            case 'doorFrontPassenger':
            case 'frontRight':
            case 'fenderFrontRight':
            case 'doorBackPassengerRight':
            case 'fenderRearRight':
            case 'centerRight':
            case 'rearRight':
            case 'rightWallFront':
            case 'rightWallCenter':
            case 'rightWallRear':
            case 'frameRight':
                return 'right';
        }
    }

    protected numberChanged(): void {
        this.determineType();
        this.emitValueChange();
    }

    protected keyDownOnInput(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            // When user pressed Enter -> add next value to measurement and focus the new input
            this.addValue();
            setTimeout(() => {
                this.inputFields.get(this.inputFields.length - 1).nativeElement.focus();
            }, 0);
        }
    }

    protected addValue(): void {
        if (this.disabled) {
            return;
        }

        this.paintThicknessMeasurement.values.push(null);
        this.isEditing = true;
        this.emitValueChange();
    }

    protected deleteValue(index: number): void {
        this.paintThicknessMeasurement.values.splice(index, 1);
        this.emitValueChange();
    }

    protected clickedSummaryLabel(event: MouseEvent): void {
        if ((event.target as HTMLElement).classList.contains('status-icon')) {
            // Do nothing -> User wants to open the manual type selection popover
        } else {
            this.isEditing = true;
        }
    }

    protected selectManualPaintThicknessType({
        type,
        forAll = false,
    }: {
        type: PaintThicknessType | null;
        forAll?: boolean;
    }): void {
        if (this.disabled) {
            return;
        }

        this.paintThicknessMeasurement.manualType = type;
        this.determineType();

        if (type !== 'none') {
            // Clear the reason why measurement was not possible
            delete this.paintThicknessMeasurement.noMeasurementReason;
        }

        if (forAll) {
            const newTypeAndReason: PaintThicknessMeasurementTypeSelection = { type };

            if (type === 'none' && this.paintThicknessMeasurement.noMeasurementReason) {
                newTypeAndReason.noMeasurementReason = this.paintThicknessMeasurement.noMeasurementReason;
            }

            this.updateManualTypeForAll.emit(newTypeAndReason);
        }

        this.emitValueChange();
    }

    protected setNoMeasurementReason({ reason, forAll = false }: { reason: string; forAll?: boolean }): void {
        this.paintThicknessMeasurement.noMeasurementReason = reason;

        this.selectManualPaintThicknessType({ type: 'none', forAll });
        this.emitValueChange();
    }

    protected emitValueChange(): void {
        this.valueChange.emit();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Positioning
    /////////////////////////////////////////////////////////////////////////////*/

    protected readonly PaintThicknessColorMap = PaintThicknessColorMap;
    protected readonly paintThicknessTypes = paintThicknessTypes;
    protected readonly PaintThicknessLabelMap = PaintThicknessLabelMap;
    protected readonly trackByIndex = trackByIndex;
    protected readonly getPaintThicknessSummaryLabel = getPaintThicknessSummaryLabel;
}

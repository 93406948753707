import { Invoice } from '../../models/invoices/invoice';
import { Payment } from '../../models/invoices/payment';
import { reduceByProperty } from '../arrays/reduce-by-property';

/**
 * Adds up all payments as well as all settled short payments (all expect the outstanding ones).
 *
 * Paid or settled = regularPayments + writtenOffShortPayments + paidShortPayments
 */
export function getPaidAmount(invoice: Pick<Invoice, 'totalGross' | 'payments'>): number {
    const settledPaymentsAndShortPayments: Payment[] = invoice.payments.filter(
        (payment) =>
            payment.type === 'payment' || (payment.type === 'shortPayment' && payment.shortPaymentStatus === 'paid'),
    );
    const settledAmount: number = reduceByProperty(settledPaymentsAndShortPayments, 'amount');
    const rounded: number = Math.round(settledAmount * 100) / 100;

    // Overpaid invoices may return values below 0. Overpaid must be treated as paid, i. e. unpaidAmount = 0.
    return Math.max(rounded, 0);
}

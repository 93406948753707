<!-- Example report dropdown-->
<div
    [matMenuTriggerFor]="exampleReportDropdown"
    #exampleReportDropdownTrigger="matMenuTrigger"
    class="example-report-dropdown-trigger"
    (menuOpened)="initializeOverlay()">
    <!-- Icon -->
    <div
        class="report-preview-manufacturer-icon-container"
        [style.margin-right.px]="0"
        *ngIf="report?.car?.make && iconForCarBrandExists(report?.car?.make); else carMakeIconPlaceholder">
        <img
            [src]="iconFilePathForCarBrand(report?.car.make, 'large')"
            alt=""
            class="car-manufacturer-logo"
            [matTooltip]="report?.car.make + ' ' + report?.car.model" />
    </div>
    <ng-template #carMakeIconPlaceholder>
        <div class="report-thumbnail-container" [style.margin-right.px]="0">
            <mat-icon>assignment</mat-icon>
        </div>
    </ng-template>

    <div class="example-report-dropdown-info-container">
        <!-- Customer name -->
        <div
            *ngIf="getReportCustomerName({ report, fallback: 'Gutachten' }) as customerName"
            class="example-report-dropdown-trigger-title"
            [matTooltip]="customerName">
            {{ customerName }}
        </div>

        <!-- Token and createdAt -->
        <div *ngIf="report?.createdAt" class="example-report-dropdown-trigger-id label">
            <span *ngIf="report?.token" [matTooltip]="'Aktenzeichen ' + report?.token">{{ report?.token }} -</span>
            <span matTooltip="Erstellt am {{ report?.createdAt | moment: 'DD.MM.YY' }}">
                {{ report?.createdAt | moment: 'DD.MM.YY' }}
            </span>
        </div>
    </div>

    <!-- Dropdown indicator -->
    <div class="example-report-dropdown-trigger-dropdown-indicator">
        <mat-icon>arrow_drop_down</mat-icon>
    </div>

    <!-- Remove button -->
    <mat-icon
        *ngIf="!!report"
        class="toolbar-icon"
        [style.margin-left.px]="-10"
        (click)="handleRemoveReportClick($event)"
        matTooltip="Gutachten entfernen">
        close
    </mat-icon>
</div>

<!-- Example report dropdown overlay-->
<mat-menu
    #exampleReportDropdown="matMenu"
    class="example-report-dropdown-overlay {{ theme === 'dark' ? 'dark' : 'light' }}">
    <div class="example-report-dropdown-overlay-container" (click)="$event.preventDefault(); $event.stopPropagation()">
        <!-- Search input -->
        <div class="example-report-dropdown-search-container">
            <!-- Search icon -->
            <mat-icon>search</mat-icon>

            <!-- Search input -->
            <input #searchInput type="text" placeholder="Suchen" (input)="handleSearchInputChange($event)" />

            <!-- Search loading indicator-->
            <div class="example-report-dropdown-search-loading-indicator">
                <mat-spinner *ngIf="searchPending" [diameter]="15"></mat-spinner>
            </div>
        </div>

        <!-- Reports search results list-->
        <cdk-virtual-scroll-viewport
            #virtualScroll
            [itemSize]="50"
            minBufferPx="1000"
            maxBufferPx="1000"
            [style.height.px]="filteredReports.length * 50"
            *ngIf="filteredReports?.length"
            class="example-report-dropdown-search-results cdk-virtual-scroll-vertical-only">
            <ng-container *cdkVirtualFor="let report of filteredReports; trackBy: trackById">
                <!-- Report search result -->
                <div class="report-preview-option" (click)="handleReportClick(report)">
                    <div class="report-preview-option-inner-wrapper">
                        <div
                            class="report-preview-manufacturer-icon-container"
                            (click)="navigateToReport(report)"
                            *ngIf="
                                report?.car.make && iconForCarBrandExists(report?.car.make);
                                else carMakeIconPlaceholder
                            ">
                            <img
                                [src]="iconFilePathForCarBrand(report?.car.make, 'large')"
                                alt=""
                                class="car-manufacturer-logo"
                                [matTooltip]="report?.car.make + ' ' + report?.car.model" />
                        </div>
                        <ng-template #carMakeIconPlaceholder>
                            <div class="report-thumbnail-container">
                                <mat-icon>assignment</mat-icon>
                            </div>
                        </ng-template>

                        <div class="report-preview-name-and-date-container">
                            <!-- Organization name -->
                            <div
                                *ngIf="report?.claimant?.contactPerson?.organization; else noOrganization"
                                class="report-preview-name-container"
                                [matTooltip]="report?.claimant?.contactPerson?.organization">
                                {{ report?.claimant?.contactPerson?.organization }}
                            </div>

                            <ng-template #noOrganization>
                                <!-- First and last name -->
                                <div
                                    *ngIf="
                                        report?.claimant?.contactPerson?.firstName ||
                                            report?.claimant?.contactPerson?.lastName;
                                        else noName
                                    "
                                    class="report-preview-name-container"
                                    [matTooltip]="
                                        report?.claimant?.contactPerson?.firstName +
                                        ' ' +
                                        report?.claimant?.contactPerson?.lastName
                                    ">
                                    {{ report?.claimant?.contactPerson?.firstName }}
                                    {{ report?.claimant?.contactPerson?.lastName }}
                                </div>

                                <!-- Name fallback -->
                                <ng-template #noName>
                                    <div class="report-preview-name-container">Kein Name</div>
                                </ng-template>
                            </ng-template>

                            <!-- Token and creation date -->
                            <div class="label">
                                <span *ngIf="report?.token" [matTooltip]="'Aktenzeichen ' + report?.token">
                                    {{ report?.token }} -
                                </span>
                                <span matTooltip="Erstellt am">
                                    {{ report?.createdAt | moment: 'DD.MM.YY' }}
                                </span>
                            </div>
                        </div>
                        <div class="report-preview-open-in-new-container">
                            <mat-icon
                                class="report-preview-open-in-new"
                                (click)="handleOpenInNewClick(report?._id, $event)"
                                matTooltip="In neuem Tab öffnen">
                                open_in_new
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>

        <!-- Placeholder -->
        <div *ngIf="filteredReports.length === 0" class="example-report-dropdown-search-results-placeholder">
            {{ hasValidSearchTerm(searchTerm) && !searchPending ? 'Keine Ergebnisse' : 'Tippe, um zu suchen' }}
        </div>
    </div>
</mat-menu>

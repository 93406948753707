import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DownloadService } from '../download.service';
import { ToastService } from '../toast.service';

@Injectable()
export class AssociatedReportsService {
    constructor(
        private httpClient: HttpClient,
        private downloadService: DownloadService,
    ) {}

    private apiPath = '/api/v0';

    public getRecords(reportIds: string[]): Promise<HttpResponse<Blob>> {
        let params = new HttpParams();

        for (const id of reportIds) {
            params = params.append('reportIds[]', id);
        }

        return this.httpClient
            .get(`${this.apiPath}/exports/reports`, {
                observe: 'response',
                responseType: 'blob',
                params: params,
            })
            .toPromise();
    }

    public async downloadRecordsAsCsv(reportIds: string[]): Promise<void> {
        const response = await this.getRecords(reportIds);
        this.downloadService.downloadBlobResponseWithHeaders(response);
    }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { EMPTY, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { getFullName } from '@autoixpert/lib/placeholder-values/get-full-name';
import { mergeRecord } from '@autoixpert/lib/server-sync/merge-record';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { FutureAccessRights, UserRegistration } from '@autoixpert/models/user/user-registration/user-registration';
import { LiveSyncServiceBase } from '../libraries/database/live-sync.service-base';
import { FeathersQuery } from '../types/feathers-query';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FrontendLogService } from './frontend-log.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';
import { userRecordMigrations } from './user.service-migrations';

/**
 * Manage the User objects of the team members of the logged-in user's team.
 */
@Injectable()
export class UserService extends LiveSyncServiceBase<User> {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        private loggedInUserService: LoggedInUserService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        private feathersSocketioService: FeathersSocketioService,
    ) {
        super({
            serviceName: 'user',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            feathersSocketioClient: feathersSocketioService,
            objectStoreAndIndexMigrations: undefined,
            recordMigrations: userRecordMigrations,
        });
        this.registerCreateWebsocketEvent();
        this.registerPatchEventHandlers();
        this.registerDeleteWebsocketEvent();

        this.populateTeamMemberCache();
    }

    private teamMembers: User[] = [];

    /**
     * Cache team members in this class to allow synchronous querying, which we do a lot throughout the application.
     *
     * We don't intend to implement a sync on init. A sync on init would also get the users but wouldn't populate the cache.
     */
    public find(feathersQuery: FeathersQuery = {}): Observable<User[]> {
        const loggedInUser: User = this.loggedInUserService.getUser();

        if (!loggedInUser) return EMPTY;

        return super.find(feathersQuery).pipe(
            tap({
                next: (teamMembers) => {
                    this.teamMembers = teamMembers;
                },
            }),
        );
    }

    /**
     * Load users from IndexedDB and store them in cache for faster and synchronous retrieval.
     *
     * The cache is also updated on each find().
     *
     * @private
     */
    public async populateTeamMemberCache(): Promise<void> {
        const loggedInUser: User = this.loggedInUserService.getUser();

        if (!loggedInUser) {
            // If no user is present, subscribe once to the next user coming in.
            this.loggedInUserService
                .getUser$()
                .pipe(filter(Boolean), first())
                .subscribe(() => this.populateTeamMemberCache());
            return;
        }

        this.teamMembers = await this.localDb.findLocal();
    }

    public getAllTeamMembersFromCache(): User[] {
        return this.teamMembers;
    }

    public getTeamMemberFromCache(userId: string): User {
        return this.teamMembers.find((teamMember) => teamMember._id === userId);
    }

    public getTeamMembersName(userId: string): string {
        const teamMember = this.getTeamMemberFromCache(userId);
        return teamMember
            ? getFullName({
                  firstName: teamMember.firstName,
                  lastName: teamMember.lastName,
              })
            : null;
    }

    //*****************************************************************************
    //  Live Sync
    //****************************************************************************/
    /**
     * Update the cache on live updates.
     *
     * @param userId
     * @param oldPassword
     * @param newPassword
     */
    /**
     * On each websocket put event, update local records.
     * @private
     */

    private registerCreateWebsocketEvent() {
        this.createdInLocalDatabase$.subscribe({
            next: (createdRecord) => {
                const teamMember: User = this.teamMembers.find((teamMember) => createdRecord._id === teamMember._id);

                /**
                 * Only add if the user doesn't exist. Per definition, this frontend service only allows viewing team members, so all users returned
                 * from the API are team members.
                 */
                if (!teamMember) {
                    this.teamMembers.push(createdRecord);
                    return;
                }
            },
        });
    }

    private registerPatchEventHandlers() {
        this.patchedFromLocalUserInThisTab$.subscribe({
            next: ({ patchedRecord }) => {
                const loggedInUser = this.loggedInUserService.getUser();

                /**
                 * The difference between this event handler and the one further down is that this event handler assumes that the user object
                 * has already been modified locally. No "mergeRecord()" is necessary anymore. On the contrary, it would be harmful because
                 * it would create a lag: If the logged-in user is changed, written to IndexedDB and then changed again through mergeRecord,
                 * a slow IndexedDB-write might cause a second write of data with an old state.
                 */
                if (loggedInUser?._id === patchedRecord._id) {
                    this.loggedInUserService.persistUser();
                }
            },
        });
        this.patchedFromExternalServerOrLocalBroadcast$.subscribe({
            next: ({ patchedRecord }) => {
                //*****************************************************************************
                //  Logged-in User
                //****************************************************************************/
                const loggedInUser = this.loggedInUserService.getUser();

                // Only update the right matching user in the loggedInUserService's cache.
                if (loggedInUser?._id === patchedRecord._id) {
                    mergeRecord<User>(loggedInUser, patchedRecord);
                    // Write the user object to local storage to ensure that all user changes are recovered after reloading the page.
                    this.loggedInUserService.persistUser();
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Logged-in User
                /////////////////////////////////////////////////////////////////////////////*/

                //*****************************************************************************
                //  Any Cached User
                //****************************************************************************/
                const teamMember: User = this.teamMembers.find((teamMember) => patchedRecord._id === teamMember._id);

                if (teamMember) {
                    // We use an object merge instead of replacing the entire object because Angular causes the UI to jump because we use *ngIf="user" on many components. That causes a full re-render of the view if the user is replaced.
                    mergeRecord<User>(teamMember, patchedRecord);
                } else {
                    /**
                     * Add if the user doesn't exist. Per definition, this frontend service only allows viewing team members, so all users returned
                     * from the API are team members.
                     */
                    this.teamMembers.push(patchedRecord);
                }
                /////////////////////////////////////////////////////////////////////////////*/
                //  END Any Cached User
                /////////////////////////////////////////////////////////////////////////////*/
            },
        });
    }

    private registerDeleteWebsocketEvent() {
        this.deletedInLocalDatabase$.subscribe({
            next: (deletedRecordId) => {
                const targetUser = this.teamMembers.find((teamMember) => deletedRecordId === teamMember._id);

                removeFromArray(targetUser, this.teamMembers);
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Live Sync
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Password
    //****************************************************************************/
    /**
     * Set a new password. The User object's correct old password must be provided unless the logged-in user is a global admin.
     *
     * The caller must increase the _documentVersion of the User object before calling this method.
     */
    public updatePassword(user: User, oldPassword: string, newPassword: string): Observable<any> {
        return this.httpClient.patch<any>(`${apiBasePath}/users/${user._id}`, {
            oldPassword,
            password: newPassword,
            _documentVersion: user._documentVersion,
            _schemaVersion: user._schemaVersion,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Password
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Username (E-Mail)
    //****************************************************************************/
    /**
     * Set a username. The User object's correct password must be provided unless the logged-in user is a global admin.
     */
    public updateUsername(user: User, password: string, newUsername: string): Observable<any> {
        return this.httpClient.patch<any>(`${apiBasePath}/users/${user._id}`, {
            passwordForVerification: password,
            email: newUsername,
            _documentVersion: user._documentVersion + 1,
            _schemaVersion: user._schemaVersion,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Username (E-Mail)
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  User Registrations
    //****************************************************************************/
    /**
     * Sends an invitation email to the specified email address.
     */
    public invite(
        teamId: string,
        email: string,
        invitingUserRegistrationId: string,
        deactivationDate: string,
    ): Observable<UserRegistration> {
        const defaultAccessRights = new FutureAccessRights();

        return this.httpClient.post<UserRegistration>(`/api/v0/teams/${teamId}/userRegistrations`, {
            contactPerson: {
                email,
            },
            teamId: teamId,
            invitingUserRegistrationId: invitingUserRegistrationId,
            futureAccessRights: defaultAccessRights,
            deactivateAt: deactivationDate,
        });
    }

    public fetchUserRegistrations(teamId: string): Observable<UserRegistration[]> {
        return this.httpClient.get<UserRegistration[]>(`/api/v0/teams/${teamId}/userRegistrations`);
    }

    public revokeInvitation(teamId: string, email: string): Observable<UserRegistration> {
        // The ID at the endpoint could be any string (in this case the value of 'email').
        return this.httpClient.delete<UserRegistration>(`/api/v0/teams/${teamId}/userRegistrations/${email}`, {
            params: {
                'contactPerson.email': email,
                unfinishedRegistration: 'true',
            },
        });
    }

    public patchUserRegistration(teamId: string, userRegistration: UserRegistration): Observable<UserRegistration> {
        return this.httpClient.patch<UserRegistration>(
            `/api/v0/teams/${teamId}/userRegistrations/${userRegistration._id}`,
            {
                futureAccessRights: userRegistration.futureAccessRights,
            },
            {
                params: {
                    emailVerificationToken: userRegistration.emailVerificationToken,
                },
            },
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END User Registrations
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Activation / Deactivation
    //****************************************************************************/
    public async orderUser(team: Team, user: User) {
        await this.httpClient.post(`/api/v0/teams/${team._id}/orderUser`, { userId: user._id }).toPromise();

        await this.get(user._id);
    }

    public async deactivateUser(team: Team, user: User) {
        let params = new HttpParams();
        params = params.append('userId', user._id);
        await this.httpClient.delete(`/api/v0/teams/${team._id}/orderUser/${user._id}`, { params: params }).toPromise();
        await this.get(user._id);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Activation / Deactivation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Team Members
    //****************************************************************************/
    /**
     * Returns all team members.
     * If provided, the current user is replaced with the existing javascript object.
     */
    public async getAllTeamMembers(team: Team, currentUser: User): Promise<User[]> {
        let allTeamMembers = await this.find({
            // Ensure only members of the current team are loaded. This prevents all users from being loaded for global admins.
            _id: {
                $in: team.members,
            },
        }).toPromise();
        allTeamMembers = allTeamMembers.sort(sortByProperty('createdAt'));

        if (currentUser) {
            // Replace the current user with the user object, so that we edit the same object. Otherwise, editing the team member "Max" would be an object different from this.user "Max".
            // Why not overwrite this.user instead? Keeping this.user has the advantage that a change to that object is reflected immediately in other components that received their user object
            // through the local service.
            const index = allTeamMembers.indexOf(allTeamMembers.find((member) => member._id === currentUser._id));
            if (index > -1) {
                allTeamMembers.splice(index, 1, currentUser);
            }
        }
        return allTeamMembers;
    }

    /**
     * Returns an array of all active users of a team.
     * If provided, the current user is replaced with the existing javascript object.
     */
    public async getActiveTeamMembers(team: Team, currentUser?: User) {
        const allTeamMembers = await this.getAllTeamMembers(team, currentUser);
        return allTeamMembers.filter((user) => user.active);
    }
    /**
     * Returns an array of all inactive users of a team.
     * If provided, the current user is replaced with the existing javascript object.
     */
    public async getInactiveTeamMembers(team: Team, currentUser?: User) {
        const allTeamMembers = await this.getAllTeamMembers(team, currentUser);
        return allTeamMembers.filter((user) => !user.active);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team Members
    /////////////////////////////////////////////////////////////////////////////*/
}

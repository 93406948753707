import { escape, unescape } from 'lodash-es';

export const prefix = '<w:p><w:r><w:t>';
export const suffix = '</w:t></w:r></w:p>';
export const lineBreak = '</w:t><w:br/><w:t>';

/**
 * docxtemplater does not support line breaks in text, so we must add them ourselves.
 * Since this function returns XML, the placeholder must be referenced with an @ in the template, e.g. {@placeholdername}
 */
export function enableLineBreaks(
    inputString,
    {
        escapeInputString = true,
        removeLastKeepNext = true,
        keepLines = true,
        keepNext = true,
        paragraphStyle = 'Flietext',
    }: {
        escapeInputString?: boolean;
        removeLastKeepNext?: boolean;
        keepLines?: boolean;
        keepNext?: boolean;
        paragraphStyle?: string;
    } = {},
) {
    // This text maps the paragraph to the DOCX style "Fließtext" (or any other given paragraphStyle). Word drops special chars such as "ß" in their style IDs,
    // so the w:val value is "Flietext".
    // w:keepNext keeps the paragraph on one page with the next paragraph.
    // w:keepLines keeps the lines of one paragraph together
    const prefixLongTextStyle = `<w:p><w:pPr><w:pStyle w:val="${paragraphStyle}"/>${keepNext ? '<w:keepNext/>' : ''}${
        keepLines ? '<w:keepLines/>' : ''
    }</w:pPr><w:r><w:t>`;

    if (escapeInputString) inputString = escape(inputString);

    // Replace two breaks (\n\n) with a new paragraph. Replace one break (\n) with a new line.
    // If there are two breaks (= a new paragraph), start with paragraph styling for long text building blocks.
    // For the regular expression part "[\r\t\f\v ]":
    // We need to match whitespaces between two breaks and treat them as non-existent so
    // users can (accidentally) add whitespaces in empty lines in their document building blocks. If we would not recognize whitespaces with this RegEx, the two breaks would not be
    // recognized if there was a space on the empty line.
    // Then, we need only to recognize \r\t\f\v and space (" ") and not a newline (\n) so that the user can add empty paragraphs. If we would replace as many newlines as * can find,
    // \n\n\n\n\n\n would be reduced to one paragraph. Users need empty paragraphs in the document building blocks for formatting purposes.
    // If the paragraph style was explicitly set, include it in the Word XML.
    let returnString = '';
    if (inputString.includes('\n\n') || !removeLastKeepNext || paragraphStyle !== 'Flietext') {
        returnString += prefixLongTextStyle;
    } else {
        returnString += prefix;
    }
    returnString +=
        inputString
            // Insert a new paragraph on every double-line-break.
            .replace(/\n[\r\t\f\v ]*\n/g, suffix + prefixLongTextStyle)
            // Insert regular line breaks.
            .replace(/\n/g, lineBreak) + suffix;

    // All paragraphs in this text should keep the following paragraph next to themselves except for the last. The next paragraph after
    // the last paragraph usually does not belong to this input string anymore. The exception are texts that have tables or lists after
    // them such as the document building block "ZusammenfassungReparaturkosten" which is followed by a table with the top n bids. That's why
    // removing the last "keep next" is configurable.
    if (removeLastKeepNext) {
        const lastIndexOfLongTextStyleParagraph = returnString.lastIndexOf(prefixLongTextStyle);
        if (lastIndexOfLongTextStyleParagraph !== -1) {
            const textBefore = returnString.substring(0, lastIndexOfLongTextStyleParagraph);
            const paragraphOpening = returnString.substring(
                lastIndexOfLongTextStyleParagraph,
                lastIndexOfLongTextStyleParagraph + prefixLongTextStyle.length,
            );
            const textAfter = returnString.substring(lastIndexOfLongTextStyleParagraph + prefixLongTextStyle.length);

            returnString = textBefore + paragraphOpening.replace(/<w:keepNext\/>/, '') + textAfter;
        }
    }

    return returnString;
}

/**
 * "*?" means that it can be any character but the RegEx is non greedy.
 * Before the question mark was added, the issue was that in a letter window placeholder, e.g. "Versicherung.Brieffenster",
 * if the street was empty, both the address and the street would have been removed, although only the empty street name
 * should have been removed.
 * Example input: <w:p><w:pPr><w:pStyle w:val="Flietext"/><w:keepNext/><w:keepLines/></w:pPr><w:r><w:t>ADAC Autoversicherung AG</w:t></w:r></w:p><w:p><w:pPr><w:pStyle w:val="Flietext"/><w:keepLines/></w:pPr><w:r><w:t>53289 Bonn</w:t></w:r></w:p>
 */
const regExpLongPrefix = new RegExp('<w:p><w:pPr>.*?</w:pPr><w:r><w:t>', 'g');

export function removeLineBreaks(inputString, unescapeInputString = true) {
    // Handle empty values.
    if (!inputString || typeof inputString !== 'string') return inputString;

    // This function is called on every placeholder that's used in a document building block. Improve performance by only replacing values that contain OfficeOpenXML paragraphs.
    if (inputString.includes('<w:p>')) {
        inputString = inputString
            .replace(new RegExp(lineBreak, 'g'), '\n')
            .replace(regExpLongPrefix, '')
            .replace(new RegExp(prefix, 'g'), '')
            // A closing paragraph element should become a line break. In case of one-liners, the input string will be trimmed at the end.
            .replace(new RegExp(suffix, 'g'), '\n');
    }

    // Ensure special character are printed correctly.
    if (unescapeInputString) inputString = unescape(inputString);

    return inputString.trim();
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Damage } from '@autoixpert/models/reports/damage-description/damage';
import { defaultDamageTitles } from '../../../../shared/static-data/damages/damage-titles';

@Component({
    selector: 'damage-checkbox',
    templateUrl: 'damage-checkbox.component.html',
    styleUrls: ['damage-checkbox.component.scss'],
    host: {
        '[class.left]': "this.side === 'left'",
        '[class.center]': "this.side === 'center'",
        '[class.right]': "this.side === 'right'",
        '[class.active]': '!!this.damage',
    },
})
export class DamageCheckboxComponent implements OnInit {
    @Input() damage: Damage;
    @Input() damagePosition: Damage['position'];
    @Input() descriptionShown: boolean; // Must come from the parent because the parent may close all other open description overlays if another one is opened.
    @Input() disabled: boolean;

    @Output() damageToggle: EventEmitter<Damage['position']> = new EventEmitter();
    @Output() damageChange: EventEmitter<Damage> = new EventEmitter();
    @Output() descriptionOpened: EventEmitter<Damage['position']> = new EventEmitter();
    @Output() descriptionClosed: EventEmitter<Damage['position']> = new EventEmitter();

    public side: 'left' | 'center' | 'right';

    ngOnInit() {
        this.side = this.getDamageSide();
    }

    //*****************************************************************************
    //  Positioning
    //****************************************************************************/
    /**
     * Get the side of a damage relative to the car body.
     *
     * The return value is used as a class which positions the checkbox accordingly.
     */
    public getDamageSide(): 'left' | 'center' | 'right' {
        switch (this.damagePosition) {
            case 'frontLeft':
            case 'fenderFrontLeft':
            case 'doorBackPassengerLeft':
            case 'fenderRearLeft':
            case 'centerLeft':
            case 'rearLeft':
            case 'leftWallFront':
            case 'leftWallCenter':
            case 'leftWallRear':
            case 'frameLeft':
            case 'doorDriver':
                return 'left';

            case 'hood':
            case 'windshield':
            case 'roof':
            case 'rearWindow':
            case 'roofRear':
            case 'ceilingFront':
            case 'ceilingCenter':
            case 'ceilingRear':
            case 'frontCenter':
            case 'rearCenter':
                return 'center';

            case 'doorFrontPassenger':
            case 'frontRight':
            case 'fenderFrontRight':
            case 'doorBackPassengerRight':
            case 'fenderRearRight':
            case 'centerRight':
            case 'rearRight':
            case 'rightWallFront':
            case 'rightWallCenter':
            case 'rightWallRear':
            case 'frameRight':
                return 'right';
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Positioning
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Description
    //****************************************************************************/
    /**
     * Toggle description and let parent component know about that so that all other
     * descriptions may be closed.
     */
    public toggleDescription() {
        if (!this.descriptionShown) {
            // If a title hasn't been set, set one.
            if (!this.damage.title) {
                this.damage.title = defaultDamageTitles[this.damagePosition];
                this.emitDamageChange();
            }

            this.descriptionOpened.emit(this.damagePosition);
            this.registerKeyboardShortcuts();
        } else {
            // Save the current description
            this.emitDamageChange();
            this.descriptionClosed.emit(this.damagePosition);
            this.unregisterKeyboardShortcuts();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Description
    /////////////////////////////////////////////////////////////////////////////*/

    public emitDamageToggle(damagePosition: Damage['position']): void {
        this.damageToggle.emit(damagePosition);
    }

    public emitDamageChange(event?: Event): void {
        this.damageChange.emit(this.damage);

        event?.stopPropagation();
    }

    //*****************************************************************************
    //  Overlay Click
    //****************************************************************************/
    public handleOverlayClick(event: MouseEvent): void {
        // Only close editor if the overlay has been clicked directly. Ignore bubbling events from the dialog.
        if (event.target === event.currentTarget) {
            this.toggleDescription();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Click
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/

    public registerKeyboardShortcuts(): void {
        window.addEventListener('keydown', this.closeDialogOnEscapeKey);
        window.addEventListener('keydown', this.closeDialogOnCtrlEnter);
    }

    public unregisterKeyboardShortcuts(): void {
        window.removeEventListener('keydown', this.closeDialogOnEscapeKey);
        window.removeEventListener('keydown', this.closeDialogOnCtrlEnter);
    }

    closeDialogOnEscapeKey = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            // Typescript type assertion
            const target = event.target as HTMLElement;

            // Make sure the change event gets triggered to fire a save
            target.blur();

            this.toggleDescription();
        }
    };

    closeDialogOnCtrlEnter = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 'Enter') {
            // Typescript type assertion
            const target = event.target as HTMLElement;

            // Make sure the change event gets triggered to fire a save
            target.blur();

            this.toggleDescription();
        }
    };
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

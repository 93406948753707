<div id="layout-container">
    <!--============================================-->
    <!-- Menu -->
    <!--============================================-->
    <div id="menu-column"></div>
    <!--============================================-->
    <!-- END Menu -->
    <!--============================================-->

    <!--============================================-->
    <!-- Reporting Column -->
    <!--============================================-->
    <div id="reporting-column">
        <!--============================================-->
        <!-- Graph -->
        <!--============================================-->
        <div id="graph-container" class="card card-with-padding">
            <!--============================================-->
            <!-- Graph Header -->
            <!--============================================-->
            <div id="graph-header">
                <!--********** Report Name **********-->
                <div id="report-name-container">
                    <mat-icon
                        class="toolbar-icon"
                        matTooltip="Zurück zur Übersicht"
                        (click)="navigateBackToAnalyticsList()">
                        arrow_back
                    </mat-icon>
                    <h2>
                        {{ chartTitle }}
                        <span
                            class="help-indicator"
                            matTooltip="Summe der Grundhonorare abgeschlossener Gutachten. Nebenkosten sind hier nicht eingerechnet, aber in der Umsatzauswertung zu finden.">
                            ?
                        </span>
                    </h2>
                </div>

                <analytics-filter-button [filterRef]="filter"></analytics-filter-button>

                <!--********** Date Filter **********-->
                <date-range-picker
                    [(dateFrom)]="filterAnalyticsFrom"
                    [(dateTo)]="filterAnalyticsTo"
                    (dateRangeChange)="updateAnalytics(); rememberDateRange()"></date-range-picker>
            </div>

            <!--============================================-->
            <!-- END Graph Header -->
            <!--============================================-->

            <analytics-filter
                (filterChange)="updateAnalytics()"
                (initialized)="filtersLoaded()"
                [showAssessorFilter]="false"
                [showInvoiceLabelFilter]="false"></analytics-filter>

            <div class="chart-container">
                <div class="chart-placeholder" *ngIf="analyticsPending">
                    <img class="loading-indicator" src="/assets/images/icons/loading-indicator.svg" alt="" />
                    Lade Auswertung ...
                </div>
                <div class="chart-placeholder" *ngIf="!analyticsPending && records.length === 0">
                    <img class="no-records-to-display-image" src="/assets/images/icons/no-data-to-display.png" />
                    <div class="no-data-found-message">Keine Daten gefunden</div>
                    Probiere doch andere Filter.
                </div>
                <div id="chart" *ngIf="!analyticsPending && records.length"></div>
            </div>

            <!--============================================-->
            <!-- Graph Summary -->
            <!--============================================-->
            <div id="graph-summary-container" class="card">
                <!--********** Total **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">
                        Gesamt
                        <span class="series-color analytics-series-1"></span>
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">{{ totalRevenue | number: '1.0-0' }} €</div>
                </div>

                <!--********** Unpaid Absolute**********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label">Gutachten</div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalNumberOfReports | number: '1.0-0' }}
                    </div>
                </div>

                <!--********** Average **********-->
                <div class="graph-summary-item-container">
                    <!--********** Label **********-->
                    <div class="graph-summary-item-label" matTooltip="Durchschnittliches Honorar pro Gutachten">
                        Durchschn. Honorar
                    </div>

                    <!--********** Value **********-->
                    <div class="graph-summary-item-value">
                        {{ totalRevenue / totalNumberOfReports || 0 | number: '1.0-0' }} €
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Graph Summary -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- END Graph -->
        <!--============================================-->

        <!--============================================-->
        <!-- Series List -->
        <!--============================================-->
        <div id="series-list-card" class="card card-with-padding">
            <div id="series-list-toolbar">
                <mat-icon
                    class="toolbar-icon"
                    (click)="downloadRecordsAsCsv()"
                    matTooltip="Auswertung als CSV herunterladen">
                    file_download
                </mat-icon>
            </div>
            <div id="series-list-table-container">
                <table id="series-list">
                    <thead>
                        <tr>
                            <th class="series-entry-title-heading">Sachverständiger</th>
                            <th class="series-entry-value-heading">
                                <span class="series-color analytics-series-1"></span>
                                Gesamthonorar (netto)
                            </th>
                            <th class="series-entry-value-heading">
                                <span class="series-color analytics-series-2"></span>
                                Davon bezahlt
                                <span
                                    class="help-indicator"
                                    [matTooltip]="
                                        'Diese Quote wird angenähert: Zu wie viel Prozent wurden alle Rechnungen des Sachverständigen aus dem gewählten Zeitraum bezahlt?\n\nFalls du Sammelrechnungen erzeugt hast: Falls sie Gutachten verschiedener Gutachter enthalten, kann das System nicht erkennen, welchem Gutachter welches Honorar zuzuschreiben ist. Deshalb kann die Quote für diese Fälle ungenau sein.'
                                    ">
                                    ?
                                </span>
                            </th>
                            <th class="series-entry-value-heading">
                                Gutachten
                                <span
                                    class="help-indicator"
                                    matTooltip="Im ausgewählten Zeitraum fertiggestellte Gutachten (Fertigstellungsdatum)">
                                    ?
                                </span>
                            </th>
                            <th class="series-entry-value-heading">Honorar pro Gutachten</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="series-entry-row" *ngFor="let record of records">
                            <td class="series-entry-title-cell">{{ record.assessorName }}</td>
                            <td class="series-entry-value">{{ record.assessorsFeeTotal | number: '1.0-0' }} €</td>
                            <td class="series-entry-value">
                                <span
                                    matTooltip="Bezahlt: {{
                                        record.paymentsOfAssociatedInvoices | number: '1.0-0'
                                    }} € von {{ record.totalGrossOfAssociatedInvoices | number: '1.0-0' }} €">
                                    {{ record.invoicePaymentQuota * 100 | number: '1.0-1' }} %
                                </span>
                                <div
                                    class="label"
                                    matTooltip="Gesamthonorar * Quote{{
                                        '\n\n'
                                    }}Hochgerechnet wurden die Honorare des Sachverständigen in dieser Höhe bezahlt. Relevant, falls du deine Mitarbeiter gemessen am tatsächlich gezahlten Umsatz entlohnst.">
                                    {{ record.assessorsFeeTotal * record.invoicePaymentQuota | number: '1.0-0' }} €
                                </div>
                            </td>
                            <td class="series-entry-value">{{ record.numberOfReports }} Stk.</td>
                            <td class="series-entry-value">
                                {{ record.assessorsFeeTotal / record.numberOfReports || 0 | number: '1.0-0' }} €
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!--============================================-->
        <!-- END Series List -->
        <!--============================================-->
    </div>
    <!--============================================-->
    <!-- END Reporting Column -->
    <!--============================================-->
</div>

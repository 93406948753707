/**
 * We want the user to long-press a photo or list item to start dragging. This is not possible when a contextmenu
 * opens on long-press, so we disable the context menu here.
 */
import { isTouchOnly } from './is-small-screen';

export function preventContextMenuOnTouchDevices(event: MouseEvent) {
    if (isTouchOnly()) {
        event.preventDefault();
        return false;
    }
}

<div class="dialog-overlay entry-dialog-container" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container">
        <h2>car.casion-Inserat verknüpfen</h2>
        <mat-icon class="dialog-close-icon" (click)="closeDialog()" matTooltip="Schließen">close</mat-icon>
        <mat-form-field class="mat-block">
            <input
                matInput
                axTrim
                [(ngModel)]="carcasionOfferId"
                placeholder="car.casion Objekt-Nummer"
                [axAutofocus]="true" />
        </mat-form-field>
        <div class="date-and-time-group">
            <date-input
                required
                [(date)]="offerReadyAt"
                placeholder="Gebotsfrist"
                matTooltip="Kürzel: h = heute, g = gestern, v = vorgestern, 2t = in zwei Tagen, -3w = vor drei Wochen, 1m = in einem Monat"></date-input>
            <time-input required [(time)]="offerReadyAt"></time-input>
        </div>
        <div class="dialog-buttons-container">
            <button
                (click)="carcasionOfferId && offerReadyAt && emitObjectNumber()"
                [class.disabled]="!carcasionOfferId || !offerReadyAt"
                [matTooltip]="getSubmitButtonTooltip()">
                Verknüpfen
            </button>
        </div>
    </div>
</div>

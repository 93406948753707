import { Task } from '@autoixpert/models/tasks/task';

/**
 * Returns all undone tasks (= without a completion date).
 */
export function getOpenTasks(tasks: Task[]): Task[] {
    if (!tasks) return [];

    return tasks.filter((task) => !task.isCompleted);
}

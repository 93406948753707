import { Component } from '@angular/core';

@Component({
    selector: 'help-module',

    template: '<router-outlet></router-outlet>',
})
export class HelpComponent {
    constructor() {}

    ngOnInit() {}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { AxHttpSyncBlob } from '@autoixpert/lib/database/ax-http-sync-blob.class';
import { BlobDataType } from '@autoixpert/models/indexed-db/database-blob.types';
import { DatabaseServiceName } from '@autoixpert/models/indexed-db/database.types';
import { OfflineSyncBlobServiceBase } from '../libraries/database/offline-sync-blob-service.base';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';

@Injectable()
export class WatermarkImageFileService extends OfflineSyncBlobServiceBase {
    constructor(
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        private loggedInUserService: LoggedInUserService,
    ) {
        super({
            serviceName: 'watermarkImageFile',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            formDataBlobFieldName: 'watermarkImage',
        });

        /**
         * Overwrite the HTTP Sync service because watermark images have a custom path: It includes the team ID.
         */
        this.httpSync = new AxHttpSyncWatermarkImageFile({
            serviceName: this.serviceName,
            serviceNamePlural: this.serviceNamePlural,
            httpClient: this.httpClient,
            formDataBlobFieldName: 'watermarkImage',
            loggedInUserService: this.loggedInUserService,
        });
    }
}

class AxHttpSyncWatermarkImageFile extends AxHttpSyncBlob {
    private readonly loggedInUserService: LoggedInUserService;

    constructor(params: {
        serviceName: DatabaseServiceName;
        serviceNamePlural?: string;
        httpClient: HttpClient;
        formDataBlobFieldName: string;
        acceptHeaderContent?: string;
        loggedInUserService: LoggedInUserService;
    }) {
        super(params);

        this.loggedInUserService = params.loggedInUserService;
    }

    public async createRemote(record: { _id: BlobDataType['_id']; blob: Blob }): Promise<void> {
        return super.createRemote(record, {
            apiUrl: `${apiBasePath}/teams/${this.loggedInUserService.getUser().teamId}/watermarkImage`,
        });
    }

    public async getRemote(recordId: string): Promise<Blob> {
        return super.getRemote(recordId, {
            apiUrl: `${apiBasePath}/teams/${this.loggedInUserService.getUser().teamId}/watermarkImage`,
        });
    }

    public async deleteRemote(recordId: string): Promise<void> {
        return super.deleteRemote(recordId, {
            apiUrl: `${apiBasePath}/teams/${this.loggedInUserService.getUser().teamId}/watermarkImage`,
        });
    }
}

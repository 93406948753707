<ng-container *ngIf="team && counterConfig">
    <div
        class="token-column-layout-container"
        [class.show-border-bottom]="showBorderBottom"
        [class.collapsed]="!isExpanded">
        <!--********** Token Pattern & Counter **********-->
        <div class="report-token-pattern-and-counter-container">
            <!--============================================-->
            <!-- Pattern -->
            <!--============================================-->
            <div class="report-token-pattern-column">
                <!--********** Pattern Input **********-->
                <placeholder-autocomplete-input
                    class="report-token-pattern-input"
                    [(text)]="counterConfig.pattern"
                    placeholder="Muster"
                    [disabled]="disabled"
                    (inputFocused)="previousCounterPattern = counterConfig.pattern"
                    (change)="handleCounterPatternChange()"
                    [tooltip]="
                        disabled
                            ? getAskYourAdminTooltip()
                            : 'Format: GA-{JJ}-{###}. Die Anzahl der Rautezeichen bestimmt die Zahl der Ziffern.\n\nWeitere Platzhalter:\n{JJ} oder {JJJJ} für Jahreszahl,\n{MM} für Monat und\n{TT} für den Tag.\n{TYP} für Gutachtentyp,\n{INI} für die Initialen des zuständigen Sachverständigen.'
                    ">
                    <!--********** Insert Placeholders **********-->
                    <mat-icon
                        placeholder-autocomplete-input-suffix
                        class="report-token-input-submenu-trigger toolbar-icon"
                        [matMenuTriggerFor]="reportTokenPatternPlaceholderSubmenu"
                        matTooltip="Platzhalter einfügen">
                        playlist_add
                    </mat-icon>
                </placeholder-autocomplete-input>

                <!--********** Submenu: Placeholders **********-->
                <mat-menu #reportTokenPatternPlaceholderSubmenu>
                    <a
                        mat-menu-item
                        *ngFor="let tokenPlaceholder of availableTokenPlaceholders"
                        (click)="insertReportTokenPlaceholder(tokenPlaceholder.placeholder); saveTeam()">
                        {{ tokenPlaceholder.title }}
                    </a>
                    <a
                        mat-menu-item
                        [matMenuTriggerFor]="popularPatternsSubmenu"
                        *ngIf="popularReportTokenPatterns.length">
                        Beliebte Muster
                    </a>
                </mat-menu>

                <!--********** Submenu Popular Patterns **********-->
                <mat-menu #popularPatternsSubmenu>
                    <mat-option
                        class="option-with-label"
                        *ngFor="let popularReportTokenPattern of popularReportTokenPatterns"
                        (click)="replaceReportTokenPattern(popularReportTokenPattern.pattern)">
                        {{ popularReportTokenPattern.title }}
                        <div class="label">{{ getTokenPreviewString(popularReportTokenPattern.pattern) }}</div>
                    </mat-option>
                </mat-menu>
            </div>
            <!--============================================-->
            <!-- END Pattern -->
            <!--============================================-->

            <!--============================================-->
            <!-- Counter -->
            <!--============================================-->
            <div class="report-token-counter-column">
                <mat-form-field>
                    <!--********** Report Token & Invoice Counter not in sync **********-->
                    <input
                        matInput
                        number-input
                        *ngIf="!isInvoiceNumberSyncedAndLeading()"
                        class="report-token-input"
                        placeholder="Zähler"
                        [(number)]="counter.count"
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0"
                        (focus)="previousCounterCount = counter.count"
                        (change)="handleCounterCountChange()"
                        [matTooltip]="disabled ? getAskYourAdminTooltip() : ''"
                        [disabled]="disabled" />

                    <!--********** Counters in sync **********-->
                    <input
                        matInput
                        number-input
                        *ngIf="isInvoiceNumberSyncedAndLeading()"
                        class="report-token-input"
                        placeholder="Zähler"
                        [minimumFractionDigits]="0"
                        [maximumFractionDigits]="0"
                        [number]="counterToSyncWith?.count || 0"
                        matTooltip="Der Zähler der Rechnungsnummer wird verwendet"
                        disabled />
                </mat-form-field>
            </div>
            <!--============================================-->
            <!-- END Counter -->
            <!--============================================-->

            <!--============================================-->
            <!-- Counter Reset Cycle -->
            <!--============================================-->
            <mat-form-field id="reset-counter-dropdown" class="no-underline" *ngIf="getCounterResetCycle()">
                <mat-select
                    class="styled"
                    [ngModel]="getCounterResetCycle()"
                    placeholder="Zurücksetzen"
                    (selectionChange)="setCounterResetCycle($event.value)"
                    [disabled]="disabled || isInvoiceNumberSyncedAndLeading()">
                    <mat-select-trigger [ngSwitch]="getCounterResetCycle()">
                        <mat-icon class="medium-icon icon-light">calendar_today</mat-icon>
                        <span *ngSwitchCase="'never'">Nie</span>
                        <span *ngSwitchCase="'annually'">Jährlich</span>
                        <span *ngSwitchCase="'monthly'">Monatlich</span>
                        <span *ngSwitchCase="'daily'">Täglich</span>
                    </mat-select-trigger>
                    <mat-option value="never">Nie</mat-option>
                    <mat-option value="annually">Jährlich</mat-option>
                    <mat-option value="monthly">Monatlich</mat-option>
                    <mat-option value="daily">Täglich</mat-option>
                </mat-select>
            </mat-form-field>
            <!--============================================-->
            <!-- END Counter Reset Cycle -->
            <!--============================================-->
        </div>

        <!--============================================-->
        <!-- Warnings -->
        <!--============================================-->
        <div
            class="info-note warning-note flex-align-center"
            *ngIf="getTokenPreviewString().includes('{') || getTokenPreviewString().includes('}')">
            <mat-icon>warning</mat-icon>
            <div>"&#123;" und "&#125;" bitte nur als Teil des Platzhalters verwenden.</div>
        </div>

        <div
            class="info-note warning-note flex-align-center"
            *ngIf="tokenHasMoreThanThreeDigits(counterConfig.pattern)">
            <mat-icon>warning</mat-icon>
            <div>
                Wir empfehlen drei oder mehr #-Platzhalter, damit auch Zähler ab 100 richtig abgebildet werden können.
            </div>
        </div>
        <!--============================================-->
        <!-- END Warnings -->
        <!--============================================-->

        <!--============================================-->
        <!-- Preview -->
        <!--============================================-->
        <preferences-row iconName="visibility" *ngIf="isExpanded || !canCollapse" @slideInAndOutVertically>
            <span class="label light" *ngIf="counterType === 'reportToken'">Nächstes Aktenzeichen</span>
            <span class="label light" *ngIf="counterType === 'invoiceNumber'">Nächste Rechnungsnummer</span>
            <div
                class="token-preview margin-top-5"
                *ngIf="counterConfig.pattern && isANumber(counter?.count) && getTokenPreviewString()">
                {{ getTokenPreviewString() }}
            </div>
        </preferences-row>
        <!--============================================-->
        <!-- END Preview -->
        <!--============================================-->

        <!--============================================-->
        <!-- Office Location -->
        <!--============================================-->
        <preferences-row
            iconName="home"
            *ngIf="showOfficeLocation && (isExpanded || !canCollapse)"
            @slideInAndOutVertically>
            <div>
                <office-location-list
                    [availableOfficeLocations]="getOfficeLocationsWithoutAssociation()"
                    [officeLocations]="team.officeLocations"
                    [selectedOfficeLocationIds]="counterConfig.associatedOfficeLocationIds"
                    [horizontalDisplay]="true"
                    (change)="saveTeam()"></office-location-list>
            </div>

            <mat-icon
                right
                id="default-icon"
                class="medium-icon toolbar-icon"
                (click)="setAsDefault(counterConfig)"
                [ngClass]="{ active: counterConfig.isDefault }"
                [matTooltip]="getSetAsDefaultTooltip(counterConfig)">
                star
            </mat-icon>
        </preferences-row>

        <!--============================================-->
        <!-- END Office Location -->
        <!--============================================-->
    </div>
</ng-container>

import { get } from 'lodash-es';
import { extractChanges } from './extract-changes';
import { FlattenedChangePaths, flattenChangePaths } from './flatten-change-paths';

/**
 * Get the differences between two objects as an array of differences.
 * @param oldRecord
 * @param newRecord
 */
export function getListOfDifferences<DataType>(oldRecord: DataType, newRecord: DataType): ObjectDifference[] {
    const changedValues = Object.entries(flattenChangePaths(extractChanges(oldRecord, newRecord)));
    const changeList: ObjectDifference[] = [];

    // For each change, get both the new and the old value.
    for (const [key, value] of changedValues) {
        changeList.push({
            key,
            oldValue: get(oldRecord, key),
            newValue: value,
        });
    }

    // console.log(diff(oldRecord, newRecord));
    // console.log(diff(oldRecord, newRecord));
    return changeList;
}

export function getListOfDifferencesFromFlattenedChangePaths(
    flattenedChangePaths: FlattenedChangePaths,
): ObjectDifference[] {
    const changedValues = Object.entries(flattenedChangePaths);
    const changeList: ObjectDifference[] = [];

    // For each change, get both the new and the old value.
    for (const [key, value] of changedValues) {
        changeList.push({
            key,
            oldValue: undefined, // Old value is not relevant here. It's included in the type though because we need it elsewhere to detect conflicts in updates.
            newValue: value,
        });
    }

    return changeList;
}

export interface ObjectDifference {
    key: string;
    oldValue: any;
    newValue: any;
}

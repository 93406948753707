import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CopyResidualValueDocumentsToReportService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public copyResidualValueDocuments({
        sourceReportId,
        targetReportId,
        residualValueDocumentTypes,
    }: {
        sourceReportId: string;
        targetReportId: string;
        residualValueDocumentTypes: string[];
    }): Promise<ResidualValueDocumentCopyResponse> {
        return this.httpClient
            .post<ResidualValueDocumentCopyResponse>(`${this.apiPath}/reports/${sourceReportId}/residualValueCopy`, {
                sourceReportId,
                targetReportId,
                residualValueDocumentTypes,
            })
            .toPromise();
    }
}

export interface ResidualValueDocumentCopyResponse {
    success: boolean;
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TaxationType } from '@autoixpert/models/reports/market-value/valuation';

@Component({
    selector: 'taxation-type-selector',
    templateUrl: './taxation-type-selector.component.html',
    styleUrls: ['./taxation-type-selector.component.scss'],
})
export class TaxationTypeSelectorComponent {
    @Input() taxationType: TaxationType = 'full';
    @Output() taxationTypeChange: EventEmitter<TaxationType> = new EventEmitter<TaxationType>();
    @Input() disabled: boolean = false;
    @Input() dark: boolean = false;
}

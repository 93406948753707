import { ContactPerson } from '../contacts/contact-person';
import { Invoice } from './invoice';

/**
 * The parameters for generating an invoice from a report.
 */
export class InvoiceParameters {
    constructor(template?: Partial<InvoiceParameters>) {
        if (template) {
            Object.assign(this, template);
        }
    }

    _id: Invoice['_id']; // Needed to store whether an invoice has already been generated.
    recipientRole: 'claimant' | 'garage' | 'insurance' | 'lawyer' | 'ownerOfClaimantsCar' | 'leaseProvider' | 'custom';
    // This recipient is only filled if the user has provided a custom recipient outside the involved parties. Recipient role must be set to 'custom' for this to have an effect.
    recipient: ContactPerson;
    number: Invoice['number'];
    date: Invoice['date'];
    daysUntilDue: Invoice['daysUntilDue'];
    lineItems: Invoice['lineItems'] = [];
    vatRate: Invoice['vatRate'];
    vatExemptionReason: Invoice['vatExemptionReason'];
    factoringEnabled: Invoice['factoringEnabled'];

    isElectronicInvoiceEnabled: Invoice['isElectronicInvoiceEnabled'];
    /**
     * This is true if the user explicitly set the electronic invoice status in the report invoice parameters. This flag
     * prevents the status from being changed when the invoice recipient's data changes or when the user enters the
     * report invoice screen.
     *
     * This flag does not exist on invoices since invoices have no automatic electronic invoice status changes.
     */
    isElectronicInvoiceStatusFrozen: boolean;
}

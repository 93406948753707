import { OverlayRef } from '@angular/cdk/overlay';
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { Task } from '@autoixpert/models/tasks/task';
import { fadeInAndOutAnimation } from '../../../animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../../animations/fade-in-and-slide.animation';
import { ApiErrorService } from '../../../services/api-error.service';
import { TaskService } from '../../../services/task.service';

@Component({
    selector: 'task-details-dialog',
    templateUrl: './task-details-dialog.component.html',
    styleUrls: ['./task-details-dialog.component.scss'],
    animations: [
        fadeInAndSlideAnimation({
            duration: 150,
            slideDistance: 10,
        }),
        fadeInAndOutAnimation(),
    ],
})
export class TaskDetailsDialogComponent {
    constructor(
        private overlayRef: OverlayRef,
        private taskService: TaskService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() task: Task;
    @Output() taskChange: EventEmitter<Task> = new EventEmitter();
    @Output() taskDeleted: EventEmitter<Task> = new EventEmitter();

    //*****************************************************************************
    //  Save Task
    //****************************************************************************/
    public async saveTask(task: Task) {
        try {
            await this.taskService.put(task);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Aufgabe nicht gespeichert`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Save Task
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Close
    //****************************************************************************/

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Close
    /////////////////////////////////////////////////////////////////////////////*/
    public closeOverlay() {
        this.overlayRef.detach();
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent) {
        switch (event.key) {
            case 'Escape':
                this.closeOverlay();
                break;
        }
    }
}

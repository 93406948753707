import { AnimationEvent } from '@angular/animations';
import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, HostListener, Inject, InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { fadeInAndSlideAnimation } from '@autoixpert/animations/fade-in-and-slide.animation';
import { PANEL_CLOSE_SUBJECT_TOKEN } from '@autoixpert/injection-tokens/common-overlay-injection-tokens';
import { TextElement } from '@autoixpert/models/signable-documents/signable-pdf-template-config';

/**
 * If the user double-clicks a text element, this panel will be opened.
 *
 * This panel contains a textarea to edit the text element.
 *
 * The PdfTextElementInputAnchorDirective is responsible for opening this overlay panel.
 */
@Component({
    selector: 'pdf-text-element-input-panel',
    templateUrl: './pdf-text-element-input-panel.component.html',
    styleUrls: ['./pdf-text-element-input-panel.component.scss'],
    animations: [fadeInAndSlideAnimation()],
})
export class PdfTextElementInputPanelComponent {
    constructor(
        @Inject(TEXT_ELEMENT_TOKEN) public textElement: TextElement,
        @Inject(TEXT_ELEMENT_CHANGE_SUBJECT_TOKEN) private changeSubject: Subject<TextElement>,
        @Inject(PANEL_CLOSE_SUBJECT_TOKEN) private panelCloseSubject: Subject<void>,
        private overlayRef: OverlayRef,
    ) {}

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.closeOnBackdropClick();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    public emitChange() {
        this.changeSubject.next(this.textElement);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Overlay Controls & Animation
    //****************************************************************************/
    /**
     * Trigger the dialog's content hide animation
     */
    public closePanel(): void {
        this.overlayRef.detach();

        // Notify the anchor that the panel has been closed.
        this.panelCloseSubject.next();
        this.panelCloseSubject.complete();

        // Unsubscribe subscribers after the dialog has been closed.
        this.changeSubject.complete();
    }

    /**
     * Close dialog on backdrop click. Don't close if notes are still being edited.
     */
    public closeOnBackdropClick(): void {
        this.overlayRef.backdropClick().subscribe(() => {
            this.overlayRef.detach();
        });
    }

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;

    /**
     * Detaching only removes the portal. After the portal has been removed and its animation has finished, remove the overlay (portal outlet).
     * @param event
     */
    @HostListener('@fadeInAndSlide.done', ['$event'])
    public disposeOverlayCompletely(event: AnimationEvent): void {
        if (event.toState === 'void') {
            this.overlayRef.dispose();
        }
    }

    @HostListener('window:keydown', ['$event'])
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                event.stopImmediatePropagation();
                this.closePanel();
                break;
        }
    }

    public leaveEditModeOnCtrlEnter(event: KeyboardEvent) {
        switch (event.key) {
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    // Trigger change handler before removing the input through Angular.
                    (event.target as HTMLInputElement).blur();

                    this.closePanel();
                    break;
                }
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Overlay Controls & Animation
    /////////////////////////////////////////////////////////////////////////////*/
}

export const TEXT_ELEMENT_TOKEN = new InjectionToken<TextElement[]>('TEXT_ELEMENT_TOKEN');
export const TEXT_ELEMENT_CHANGE_SUBJECT_TOKEN = new InjectionToken<Subject<TextElement[]>>(
    'TEXT_ELEMENT_CHANGE_SUBJECT_TOKEN',
);

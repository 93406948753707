import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Moment } from 'moment';
import { AutogrowTextareaComponent } from '@autoixpert/components/autogrow-textarea/autogrow-textarea.component';
import { toIsoDate } from '@autoixpert/lib/date/iso-date';
import { Task } from '@autoixpert/models/tasks/task';
import { User } from '@autoixpert/models/user/user';
import { parseEstimatedDuration } from '../../../libraries/tasks/parse-estimated-duration';
import { LoggedInUserService } from '../../../services/logged-in-user.service';
import { ToastService } from '../../../services/toast.service';
import { UserService } from '../../../services/user.service';
import { isDateShorthand, parseDateShorthands } from '../../date-input/parse-date-shorthands';

@Component({
    selector: 'task-title',
    templateUrl: './task-title.component.html',
    styleUrls: ['./task-title.component.scss'],
    host: {
        '[class.dark-mode]': 'this.darkMode',
    },
})
export class TaskTitleComponent {
    constructor(
        private userService: UserService,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
    ) {}

    @ViewChild(AutogrowTextareaComponent) autogrowTextarea: AutogrowTextareaComponent;

    @Input() shouldAutofocus: boolean = false;
    @Input() task: Task;
    @Input() singleLine: boolean = false;
    /**
     * Default: false
     */
    @Input() darkMode: boolean = false;

    @Output() taskChange = new EventEmitter<Task>();
    @Output() emptyTitleBlurred = new EventEmitter<Task>();
    @Output() enterKeyPress = new EventEmitter<{ task: Task }>();
    @Output() shiftEnterKeyPress = new EventEmitter<{ task: Task }>();
    @Output() taskDeleteRequest = new EventEmitter<{ task: Task }>();

    protected user: User;
    protected isTitleInputFocused: boolean;

    //*****************************************************************************
    //  Lifecycle Hooks
    //****************************************************************************/
    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
    }

    ngAfterViewInit() {
        if (this.shouldAutofocus) {
            this.focus();
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lifecycle Hooks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Focus
    //****************************************************************************/
    public focus() {
        this.autogrowTextarea?.focus();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Focus
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Keywords in Title
    //****************************************************************************/
    /**
     * Emit if the title is blurred while being empty.
     * We use this event to delete a new task without content.
     */
    public handleTitleBlurred() {
        this.isTitleInputFocused = false;
        if (!this.task.title) {
            this.emptyTitleBlurred.emit(this.task);
            return;
        }

        /**
         * The RegExp matches keywords starting with : or @ but not email addresses.
         * :heute den Müll leeren
         * Mit Matthias @morgen sprechen wegen GA 123
         * Gutachten an info@autoixpert.de schicken
         */
        const assignee: string = this.task.title.match(/(?<![\w\d])[@]([\w\d]+)/)?.[1];
        const durationAndDateKeywords: string[] = [...this.task.title.matchAll(/(?<![\w\d])[:]([\w\d]+)/g)].map(
            (matchArray) => matchArray[1],
        );

        //*****************************************************************************
        //  Match Assignee
        //****************************************************************************/
        const teamMembers = this.userService.getAllTeamMembersFromCache();

        // Assignee
        if (assignee) {
            let hasMatchBeenHandled: boolean;

            //*****************************************************************************
            //  Myself
            //****************************************************************************/
            if (assignee.toLowerCase() === 'ich') {
                this.task.assigneeId = this.user._id;
                hasMatchBeenHandled = true;
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Myself
            /////////////////////////////////////////////////////////////////////////////*/

            //*****************************************************************************
            //  Other Team Members
            //****************************************************************************/
            for (const teamMember of teamMembers) {
                const keywordLowercase = assignee.toLowerCase();
                if (
                    teamMember.firstName.toLowerCase() === keywordLowercase ||
                    teamMember.lastName.toLowerCase() === keywordLowercase ||
                    `${teamMember.firstName.toLowerCase()}${teamMember.lastName.toLowerCase()}` === keywordLowercase ||
                    teamMember.initials.toLowerCase() === keywordLowercase
                ) {
                    this.task.assigneeId = teamMember._id;
                    hasMatchBeenHandled = true;
                    break;
                }
            }
            /////////////////////////////////////////////////////////////////////////////*/
            //  END Other Team Members
            /////////////////////////////////////////////////////////////////////////////*/

            if (hasMatchBeenHandled) {
                this.task.title = this.task.title
                    .replace(new RegExp(`[@]${assignee}`), '')
                    // Replace multiple spaces created by replacing a keyword.
                    .replaceAll(/\s{2,}/g, ' ')
                    // If keyword is replaced at the end, trailing spaces my occur.
                    .trim();
            }
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Match Assignee
        /////////////////////////////////////////////////////////////////////////////*/

        // Extract keywords and apply attributes to task.
        for (const keyword of durationAndDateKeywords) {
            // If handled, the keyword will be removed from the title.
            let matchHasBeenHandled: boolean;

            // Time estimate (5m -> 5 minutes, 2h -> two hours)
            if (keyword.match(/^\d+[mh]$/)?.[0]) {
                try {
                    this.task.estimatedDuration = parseEstimatedDuration(keyword);
                } catch (error) {
                    this.toastService.error('Ungültige Zeitschätzung erkannt', `Eingabe: ${keyword}`);
                }

                matchHasBeenHandled = true;
            }

            // Dates
            else if (isDateShorthand(keyword)) {
                const targetDate: Moment = parseDateShorthands(keyword);
                this.task.dueDate = toIsoDate(targetDate);
                matchHasBeenHandled = true;
            }

            if (matchHasBeenHandled) {
                this.task.title = this.task.title
                    .replace(new RegExp(`[:]${keyword}`), '')
                    // Replace multiple spaces created by replacing a keyword.
                    .replaceAll(/\s{2,}/g, ' ')
                    // If keyword is replaced at the end, trailing spaces my occur.
                    .trim();
            }
        }

        this.taskChange.emit(this.task);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Keywords in Title
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Handle Keyboard Shortcuts
    //****************************************************************************/
    public handleKeyboardShortcuts(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Delete':
            case 'Backspace':
                if (event.shiftKey) {
                    this.taskDeleteRequest.emit({ task: this.task });
                    event.stopPropagation();
                    return;
                }
                break;
            case 'Escape':
                if (!this.task.title) {
                    this.taskDeleteRequest.emit({ task: this.task });
                    event.stopPropagation();
                }
                break;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Handle Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

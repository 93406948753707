import { OfficialFeeTable } from '@autoixpert/models/reports/assessors-fee/fee-calculation';

// Array shape: [damageValue, net fee lower, net fee upper]
export const vksFeeTable2021: OfficialFeeTable = {
    year: 2021,
    table: [
        [500, 164, 260],
        [750, 199, 315],
        [1_000, 230, 347],
        [1_250, 284, 384],
        [1_500, 312, 415],
        [1_750, 341, 444],
        [2_000, 361, 468],
        [2_250, 382, 495],
        [2_500, 404, 517],
        [2_750, 420, 540],
        [3_000, 438, 561],
        [3_500, 471, 603],
        [4_000, 504, 646],
        [4_500, 526, 685],
        [5_000, 547, 724],
        [5_500, 582, 766],
        [6_000, 600, 796],
        [6_500, 634, 820],
        [7_000, 660, 844],
        [7_500, 674, 870],
        [8_000, 706, 900],
        [8_500, 730, 930],
        [9_000, 757, 960],
        [9_500, 789, 1_005],
        [10_000, 815, 1_071],
        [12_500, 911, 1_260],
        [15_000, 1_015, 1_365],
        [17_500, 1_143, 1_543],
        [20_000, 1_242, 1_680],
        [22_500, 1_350, 1_806],
        [25_000, 1_445, 1_995],
        [27_500, 1_579, 2_089],
        [30_000, 1_632, 2_236],
        [32_500, 1_790, 2_362],
        [35_000, 1_850, 2_625],
    ],
};

import { isMiddleClick } from './is-middle-click';

/**
 * Open the given URL in case the given mouse event was triggered using the middle mouse button or CMD + click (for macOS users).
 */
export const openInNewTabOnMiddleClick = (event: MouseEvent, url: string): void => {
    // event.button === 1 -> middle mouse button
    // event.metaKey -> Allows Apple users to use command + click because they don't have a middle mouse button.
    if (isMiddleClick(event)) {
        event.preventDefault();
        event.stopPropagation();
        window.open(url);
    }
};

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { ArrayElement } from '@autoixpert/helper-types/array-element';
import { toggleValueInArray } from '@autoixpert/lib/arrays/toggle-value-in-array';
import { iconForCarBrandExists, iconNameForCarBrand } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { ShortPaymentStatusByInsuranceAnalyticsRecord } from '@autoixpert/models/analytics/revenue-analytics.model';
import { AnalyticsFilterComponent } from '../../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../../shared/libraries/currency-formatter-euro';
import { ShortPaymentAnalyticsService } from '../../../shared/services/analytics/short-payment-analytics.service';
import { ApiErrorService } from '../../../shared/services/api-error.service';
import { AnalyticsSettingsMenuComponent } from '../../analytics-settings-menu/analytics-settings-menu.component';
import {
    negativeColor,
    setDefaultChartOptions,
    shortPaymentColor,
    successColor,
    undeterminedColor,
} from '../../default-chart-options';

@Component({
    selector: 'short-payment-status',
    templateUrl: './short-payments-status.component.html',
    styleUrls: ['./short-payments-status.component.scss'],
})
export class ShortPaymentsStatusComponent implements OnInit {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;
    @ViewChild(AnalyticsSettingsMenuComponent, { static: true }) protected settings: AnalyticsSettingsMenuComponent;

    constructor(
        private shortPaymentAnalyticsService: ShortPaymentAnalyticsService,
        private apiErrorService: ApiErrorService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    public chartTitle: string = 'Kürzungen';

    private chart: ApexCharts;
    private chartOptions: apexcharts.ApexOptions;
    public analyticsPending: boolean = false;

    public totalShortPayments: number = 0;
    public totalOfOutstandingShortPayments: number = 0;

    public records: ShortPaymentStatusByInsuranceAnalyticsRecord[] = [];
    public filteredRecords: this['records'] = [];
    public recordsHiddenFromChart: this['records'] = [];
    private maximumGraphCategories: number = 10;

    public filterAnalyticsFrom: string;
    public filterAnalyticsTo: string;

    ngOnInit() {
        setDefaultChartOptions();

        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateBackToAnalyticsList(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    public rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/
    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.records.length) return;

        // If we have to summarize the remaining revenues, add the "others" revenue to the chart series
        const { firstFewRevenues, otherRevenue } = this.getShortPaymentsForChart();
        if (otherRevenue) {
            firstFewRevenues.push(otherRevenue);
        }

        const colors: string[] = [shortPaymentColor, successColor, negativeColor, undeterminedColor];

        this.chartOptions = {
            chart: {
                type: 'bar',
                stacked: true,
            },
            colors: colors,
            stroke: {
                colors,
                width: 3,
            },
            series: [
                {
                    name: 'Summe Kürzungen',
                    data: firstFewRevenues.map((record) =>
                        this.settings.useNetValues ? record.allShortPaymentsSumNet : record.allShortPaymentsSumGross,
                    ),
                    group: 'allShortPayments',
                },
                {
                    name: 'Davon schließlich bezahlt',
                    data: firstFewRevenues.map((record) =>
                        this.settings.useNetValues ? record.paidShortPaymentsSumNet : record.paidShortPaymentsSumGross,
                    ),
                    group: 'paymentStatusSubGroup',
                },
                {
                    name: 'Davon abgeschrieben',
                    data: firstFewRevenues.map((record) =>
                        this.settings.useNetValues
                            ? record.writtenOffShortPaymentsSumNet
                            : record.writtenOffShortPaymentsSumGross,
                    ),
                    group: 'paymentStatusSubGroup',
                },
                {
                    name: 'Davon ungeklärt',
                    data: firstFewRevenues.map((record) =>
                        this.settings.useNetValues
                            ? record.outstandingShortPaymentsSumNet
                            : record.outstandingShortPaymentsSumGross,
                    ),
                    group: 'paymentStatusSubGroup',
                },
            ],
            xaxis: {
                // Categories go into the Y axis when the bar chart is horizontal but...
                categories: firstFewRevenues.map((record) => (record.insurance || 'Ohne Versicherung').toUpperCase()),
                labels: {
                    style: {
                        fontFamily: "'Source Sans Pro', sans-serif",
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: (value) => currencyFormatterEuro(+value),
                },
            },
            tooltip: {
                y: {
                    formatter: (revenueByIntermediary) => currencyFormatterEuro(revenueByIntermediary),
                },
            },
        };

        // Clear before re-drawing
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
        this.chart.render();
    }

    /**
     * If there are more than 5 intermediaries, sum up the remainder.
     */
    private getShortPaymentsForChart(): {
        firstFewRevenues: ShortPaymentStatusByInsuranceAnalyticsRecord[];
        otherRevenue: ShortPaymentStatusByInsuranceAnalyticsRecord;
    } {
        const firstFewRevenues: ShortPaymentStatusByInsuranceAnalyticsRecord[] = this.filteredRecords.slice(
            0,
            this.maximumGraphCategories,
        );
        let otherRevenue: ShortPaymentStatusByInsuranceAnalyticsRecord = null;

        if (this.records.length > this.maximumGraphCategories) {
            const totalRevenueNet: number = this.filteredRecords
                .slice(this.maximumGraphCategories)
                .reduce((total, record) => total + record.totalRevenueNet, 0);
            const totalRevenueGross: number = this.filteredRecords
                .slice(this.maximumGraphCategories)
                .reduce((total, record) => total + record.totalRevenueGross, 0);

            otherRevenue = {
                insurance: 'Sonstige',
                totalRevenueNet,
                totalRevenueGross,
                // Empty array and null values are not relevant for drawing the chart.
                idsOfRegularInvoices: [],
                idsOfShortPaidInvoices: [],
                idsOfInvoicesWithOutstandingShortPayments: [],
                idsOfInvoicesWithWrittenOffShortPayments: [],
                idsOfInvoicesWithPaidShortPayments: [],
                allShortPaymentsSumNet: null,
                allShortPaymentsSumGross: null,
                outstandingShortPaymentsSumNet: null,
                outstandingShortPaymentsSumGross: null,
                writtenOffShortPaymentsSumNet: null,
                writtenOffShortPaymentsSumGross: null,
                paidShortPaymentsSumNet: null,
                paidShortPaymentsSumGross: null,
                shortPaymentQuota: null,
            };
        }

        return {
            firstFewRevenues,
            otherRevenue,
        };
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Hide Records
    //****************************************************************************/
    public filterRecords() {
        this.filteredRecords = [...this.records].filter((record) => !this.recordsHiddenFromChart.includes(record));
    }

    public toggleHideRecord(record: ArrayElement<this['records']>) {
        toggleValueInArray(record, this.recordsHiddenFromChart);
    }

    public isRecordHidden(record: ArrayElement<this['records']>): boolean {
        return this.recordsHiddenFromChart.includes(record);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Hide Records
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public refreshLocalData(): void {
        this.filterRecords();

        this.calculateTotalShortPayments();
        this.calculateTotalOfOutstandingShortPayments();

        setTimeout(() => {
            this.drawChart();
        });
    }

    public calculateTotalShortPayments(): void {
        this.totalShortPayments = this.records.reduce(
            (total, currentItem) =>
                total +
                (this.settings.useNetValues
                    ? currentItem.allShortPaymentsSumNet
                    : currentItem.allShortPaymentsSumGross),
            0,
        );
    }

    public calculateTotalOfOutstandingShortPayments(): void {
        this.totalOfOutstandingShortPayments = this.records.reduce(
            (total, currentItem) =>
                total +
                (this.settings.useNetValues
                    ? currentItem.outstandingShortPaymentsSumNet
                    : currentItem.outstandingShortPaymentsSumGross),
            0,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Data Table
    //****************************************************************************/
    iconForCarBrandExists = iconForCarBrandExists;
    iconNameForCarBrand = iconNameForCarBrand;

    public sortRecords(
        sortColumns: { column: keyof ShortPaymentStatusByInsuranceAnalyticsRecord; ascending: 1 | -1 }[],
    ): void {
        this.records.sort((recordA, recordB) => {
            let sortResult: number = 0;

            for (const sortColumnConfig of sortColumns) {
                // Only continue if we could not determine the right order (records are equal)
                if (sortResult !== 0) break;

                // Necessary for typing
                const recordAColumn = recordA[sortColumnConfig.column];
                const recordBColumn = recordB[sortColumnConfig.column];

                if (typeof recordAColumn === 'number' && typeof recordBColumn === 'number') {
                    if (recordAColumn === recordBColumn) {
                        sortResult = 0;
                    } else {
                        sortResult =
                            recordAColumn < recordBColumn
                                ? sortColumnConfig.ascending * -1
                                : sortColumnConfig.ascending;
                    }
                }
                if (typeof recordAColumn === 'string' && typeof recordBColumn === 'string') {
                    sortResult = recordAColumn.localeCompare(recordBColumn) * sortColumnConfig.ascending;
                }
            }

            return sortResult;
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Data Table
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    public async updateAnalytics() {
        this.analyticsPending = true;

        try {
            this.records = await this.shortPaymentAnalyticsService.getShortPaymentStatusByInsurance({
                from: this.filterAnalyticsFrom,
                to: this.filterAnalyticsTo,
                officeLocationIds: this.filter.officeLocationIds,
                assessorIds: this.filter.assessorIds,
                reportLabelIds: this.filter.reportLabelConfigIds,
                invoiceLabelIds: this.filter.invoiceLabelConfigIds,
            });
        } catch (error) {
            this.analyticsPending = false;

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {
                    ACCESS_DENIED: {
                        title: 'Zugriffsrecht fehlt',
                        body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                        forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                    },
                },
                defaultHandler: {
                    title: 'Fehler bei Auswertung',
                    body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                },
            });
        }
        this.sortRecords([
            {
                column: 'allShortPaymentsSumNet',
                ascending: -1,
            },
            {
                column: 'outstandingShortPaymentsSumNet',
                ascending: -1,
            },
        ]);
        this.analyticsPending = false;
        this.refreshLocalData();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/
}

<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)">
    <div class="dialog-container contains-scrollable-area">
        <div id="calculator-column" class="dialog-column card-with-padding" #calculatorColumn>
            <h2 id="calculator-heading">Wertminderung ermitteln</h2>

            <div class="card-toolbar">
                <mat-icon class="close-icon" (click)="closeCalculator()">close</mat-icon>
            </div>
            <section id="methods-section">
                <div id="methods-icon-container">
                    <mat-icon id="methods-icon">trending_down</mat-icon>
                </div>
                <div id="method-list">
                    <div class="method-container" *ngFor="let method of methods">
                        <mat-checkbox
                            [(ngModel)]="method.active"
                            (change)="
                                setUserPreference(method);
                                rememberActiveMethods();
                                calculateAllActiveMethodsAndDrawGraph();
                                saveReport()
                            "
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            {{ method.title }}
                        </mat-checkbox>
                    </div>
                </div>
            </section>

            <!--============================================-->
            <!-- Car -->
            <!--============================================-->
            <section id="car-and-repair-section" *ngIf="selectedDiminishedValueCalculation.methods.length">
                <div id="car-data-container">
                    <h3 id="car-data-heading">Fahrzeug</h3>

                    <!--********** Replacement Value **********-->
                    <currency-input
                        [(value)]="selectedDiminishedValueCalculation.replacementValue"
                        placeholder="Wiederbeschaffungswert"
                        matTooltip="Brutto"
                        (valueChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                        *ngIf="bvskMethod.active || troemnerMethod.active"
                        [warning]="
                            !disabled &&
                            !selectedDiminishedValueCalculation.lockedAt &&
                            report.valuation.vehicleValueGross != null &&
                            selectedDiminishedValueCalculation.replacementValue !== report.valuation.vehicleValueGross
                                ? 'Im Gutachten: ' + currencyFormatterEuro(report.valuation.vehicleValueGross)
                                : ''
                        "
                        [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"></currency-input>

                    <!--********** Liquidation Value **********-->
                    <currency-input
                        [(value)]="selectedDiminishedValueCalculation.timeValue"
                        placeholder="Veräußerungswert"
                        (valueChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                        *ngIf="mfmMethod.active || ruhkopfMethod.active || halbgewachsMethod.active"
                        [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"
                        matTooltip="Verkaufspreis (brutto) des Fahrzeugs vor dem Unfall.{{
                            '\n\n'
                        }}Unterschied zum WBW: WBW enthält die Händlerspanne, der Veräußerungswert nicht.{{
                            '\n\n'
                        }}Wird vorbelegt bei Privatmarktfahrzeugen mit dem WBW oder sonst mit 90 % des WBW.{{
                            '\n\n'
                        }}Hintergrund: Die 90 % berücksichtigen, dass bei 100 % schon ca. 12 % Marge für den Händler berücksichtigt sind. 90 % * 112 % ~= 100 %."></currency-input>

                    <!--********** Mileage **********-->
                    <div id="mileage-container" *ngIf="hamburgModelMethod.active || dvgtMethod.active">
                        <mat-form-field>
                            <input
                                matInput
                                id="mileage"
                                number-input
                                placeholder="Laufleistung"
                                [(number)]="selectedDiminishedValueCalculation.mileage"
                                (numberChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                                [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"
                                [minimumFractionDigits]="0"
                                [maximumFractionDigits]="0" />
                        </mat-form-field>
                        <mat-form-field id="mileage-unit">
                            <mat-select
                                [(ngModel)]="selectedDiminishedValueCalculation.mileageUnit"
                                (selectionChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                                [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                                <mat-option value="km">km</mat-option>
                                <mat-option value="mi">mi</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!--********** Age in Months **********-->
                    <mat-form-field
                        *ngIf="
                            mfmMethod.active ||
                            halbgewachsMethod.active ||
                            ruhkopfMethod.active ||
                            dvgtMethod.active ||
                            hamburgModelMethod.active
                        ">
                        <input
                            matInput
                            placeholder="Alter"
                            id="age-input"
                            [(number)]="selectedDiminishedValueCalculation.ageInMonths"
                            (numberChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"
                            number-input
                            [minimumFractionDigits]="0"
                            [maximumFractionDigits]="0" />
                        <span matSuffix>Monate</span>
                    </mat-form-field>

                    <!--********** Age Factor (Trömner) **********-->
                    <mat-form-field *ngIf="troemnerMethod.active">
                        <mat-select
                            placeholder="Neuwertigkeit (Trömner)"
                            [(ngModel)]="selectedDiminishedValueCalculation.ageFactorTroemner"
                            (selectionChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.ageFactorTroemner | number: '1.1-2' }}
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let ageFactorTroemnerOption of ageFactorTroemnerOptions"
                                [value]="ageFactorTroemnerOption.value"
                                class="option-container">
                                <div class="option-value">
                                    {{ ageFactorTroemnerOption.value | number: '1.1-2' }}
                                </div>
                                <div class="option-description label">
                                    {{ ageFactorTroemnerOption.description }}
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Original Price **********-->
                    <currency-input
                        [(value)]="selectedDiminishedValueCalculation.originalPrice"
                        placeholder="Neupreis"
                        (valueChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                        *ngIf="
                            mfmMethod.active ||
                            halbgewachsMethod.active ||
                            ruhkopfMethod.active ||
                            troemnerMethod.active
                        "
                        [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"
                        matTooltip="Brutto"></currency-input>

                    <!--********** Marketability (BVSK) **********-->
                    <mat-form-field *ngIf="bvskMethod.active">
                        <mat-select
                            placeholder="Marktgängigkeit (BVSK)"
                            [(ngModel)]="selectedDiminishedValueCalculation.marketabilityBvsk"
                            (selectionChange)="
                                mapMarketabilityToMfmAndTroemner();
                                calculateAllActiveMethodsAndDrawGraph();
                                saveReport()
                            "
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.marketabilityBvsk | number: '1.1-1' }} %
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let marketabilityBvskOption of marketabilityBvskOptions"
                                [value]="marketabilityBvskOption.value"
                                class="option-container">
                                <div class="option-value">{{ marketabilityBvskOption.value | number: '1.1-1' }} %</div>
                                <div class="option-description label">{{ marketabilityBvskOption.description }}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Previous Damage (BVSK) **********-->
                    <mat-form-field *ngIf="bvskMethod.active">
                        <mat-select
                            placeholder="Vorschaden (BVSK)"
                            [(ngModel)]="selectedDiminishedValueCalculation.previousDamageBvsk"
                            (selectionChange)="
                                mapPreviousDamageToMfm(); calculateAllActiveMethodsAndDrawGraph(); saveReport()
                            "
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.previousDamageBvsk | number: '1.1-1' }}
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let previousDamageBvskOption of previousDamageBvskOptions"
                                [value]="previousDamageBvskOption.value"
                                class="option-container">
                                <div class="option-value">{{ previousDamageBvskOption.value | number: '1.1-1' }}</div>
                                <div class="option-description label">{{ previousDamageBvskOption.description }}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Marketability (MFM) **********-->
                    <mat-form-field *ngIf="mfmMethod.active">
                        <mat-select
                            placeholder="Marktgängigkeit (MFM)"
                            [(ngModel)]="selectedDiminishedValueCalculation.marketabilityMfm"
                            (selectionChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.marketabilityMfm | number: '1.1-1' }}
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let marketabilityMfmOption of marketabilityMfmOptions"
                                [value]="marketabilityMfmOption.value"
                                class="option-container">
                                <div class="option-value">{{ marketabilityMfmOption.value | number: '1.1-1' }}</div>
                                <div class="option-description label">{{ marketabilityMfmOption.description }}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Previous Damage (MFM) **********-->
                    <mat-form-field *ngIf="mfmMethod.active">
                        <mat-select
                            placeholder="Vorschaden (MFM)"
                            [(ngModel)]="selectedDiminishedValueCalculation.previousDamageMfm"
                            (selectionChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.previousDamageMfm | number: '1.1-1' }}
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let previousDamageMfmOption of previousDamageMfmOptions"
                                [value]="previousDamageMfmOption.value"
                                class="option-container">
                                <div class="option-value">{{ previousDamageMfmOption.value | number: '1.1-1' }}</div>
                                <div class="option-description label">{{ previousDamageMfmOption.description }}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Marketability (Trömner) **********-->
                    <mat-form-field *ngIf="troemnerMethod.active">
                        <mat-select
                            placeholder="Marktgängigkeit (Trömner)"
                            [(ngModel)]="selectedDiminishedValueCalculation.marketabilityTroemner"
                            (selectionChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.marketabilityTroemner | number: '1.1-2' }}
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let marketabilityTroemnerOption of marketabilityTroemnerOptions"
                                [value]="marketabilityTroemnerOption.value"
                                class="option-container">
                                <div class="option-value">
                                    {{ marketabilityTroemnerOption.value | number: '1.1-2' }}
                                </div>
                                <div class="option-description label">
                                    {{ marketabilityTroemnerOption.description }}
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Previous Damage (Trömner) **********-->
                    <mat-form-field *ngIf="troemnerMethod.active">
                        <mat-select
                            placeholder="Vorschaden (Trömner)"
                            [(ngModel)]="selectedDiminishedValueCalculation.previousDamageTroemner"
                            (selectionChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.previousDamageTroemner | number: '1.1-2' }}
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let previousDamageTroemnerOption of previousDamageTroemnerOptions"
                                [value]="previousDamageTroemnerOption.value"
                                class="option-container">
                                <div class="option-value">
                                    {{ previousDamageTroemnerOption.value | number: '1.1-2' }}
                                </div>
                                <div class="option-description label">
                                    {{ previousDamageTroemnerOption.description }}
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--============================================-->
                <!-- END Car -->
                <!--============================================-->

                <!--============================================-->
                <!-- Repair -->
                <!--============================================-->
                <div id="repair-data-container">
                    <h3 id="repair-data-heading">Reparatur</h3>

                    <!--********** Total Repair Costs **********-->
                    <currency-input
                        [(value)]="selectedDiminishedValueCalculation.totalRepairCosts"
                        placeholder="Gesamt-Reparaturkosten"
                        (valueChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                        *ngIf="
                            mfmMethod.active || halbgewachsMethod.active || ruhkopfMethod.active || dvgtMethod.active
                        "
                        [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"
                        matTooltip="Brutto"></currency-input>

                    <!--********** Substantial Repair Costs **********-->
                    <currency-input
                        [(value)]="selectedDiminishedValueCalculation.substantialRepairCosts"
                        placeholder="Erhebliche RK (netto)"
                        *ngIf="hamburgModelMethod.active"
                        (valueChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                        matTooltip="Erhebliche Reparaturkosten (netto), d. h. der Anteil vom Lohn für Richt- & Instandsetzungsarbeiten. Dieser Wert muss kleiner sein als der Wert für das Feld Lohn."
                        [warning]="
                            selectedDiminishedValueCalculation.substantialRepairCosts !== null &&
                            selectedDiminishedValueCalculation.laborCosts !== null &&
                            selectedDiminishedValueCalculation.substantialRepairCosts >
                                selectedDiminishedValueCalculation.laborCosts
                                ? 'Muss kleiner sein als Lohn'
                                : ''
                        "
                        [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"></currency-input>

                    <!--********** Labor Costs **********-->
                    <currency-input
                        [(value)]="selectedDiminishedValueCalculation.laborCosts"
                        placeholder="Lohn (ohne Lack)"
                        *ngIf="halbgewachsMethod.active || hamburgModelMethod.active"
                        (valueChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                        matTooltip="ohne Lackierlohnkosten, brutto"
                        [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"></currency-input>

                    <!--********** Material Costs **********-->
                    <currency-input
                        [(value)]="selectedDiminishedValueCalculation.materialCosts"
                        placeholder="Ersatzteile"
                        *ngIf="halbgewachsMethod.active || hamburgModelMethod.active"
                        (valueChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                        matTooltip="inkl. Ersatzteile, brutto"
                        [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"></currency-input>

                    <!--********** Damage Intensity (BVSK) **********-->
                    <mat-form-field *ngIf="bvskMethod.active">
                        <input
                            matInput
                            number-input
                            placeholder="Schadenklasse (BVSK)"
                            [(number)]="selectedDiminishedValueCalculation.damageIntensity"
                            (numberChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled"
                            (change)="mapDamageIntensityToMfm()"
                            [minimumFractionDigits]="1"
                            [maximumFractionDigits]="1"
                            [matAutocomplete]="damageIntensityAutocomplete" />
                        <span matSuffix>&nbsp;%</span>
                        <mat-hint
                            class="warning"
                            *ngIf="
                                !selectedDiminishedValueCalculation.lockedAt &&
                                selectedDiminishedValueCalculation.damageIntensity &&
                                !damageIntensityIsValid()
                            ">
                            {{ damageIntensityHint }}
                        </mat-hint>
                        <mat-autocomplete #damageIntensityAutocomplete class="damage-intensity-autocomplete">
                            <mat-option
                                *ngFor="let damageIntensityOption of damageIntensityOptions"
                                [value]="damageIntensityOption.value"
                                class="option-container"
                                (mousedown)="$event.preventDefault()"
                                (onSelectionChange)="
                                    setDamageIntensity(damageIntensityOption.value, $event);
                                    mapDamageIntensityToMfm();
                                    calculateAllActiveMethodsAndDrawGraph();
                                    saveReport()
                                ">
                                <div class="option-value">{{ damageIntensityOption.title }}</div>
                                <div class="option-description label">{{ damageIntensityOption.description }}</div>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!--********** Damage Level (MFM) **********-->
                    <mat-form-field *ngIf="mfmMethod.active">
                        <mat-select
                            placeholder="Schadenumfang (MFM)"
                            [(ngModel)]="selectedDiminishedValueCalculation.damageLevel"
                            (selectionChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.damageLevel | number: '1.1-1' }}
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let damageLevelOption of damageLevelOptions"
                                [value]="damageLevelOption.value"
                                class="option-container">
                                <div class="option-value">{{ damageLevelOption.value | number: '1.1-1' }}</div>
                                <div class="option-description label">{{ damageLevelOption.description }}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--********** Vehicle Damage (Trömner) **********-->
                    <mat-form-field *ngIf="troemnerMethod.active">
                        <mat-select
                            placeholder="Fahrzeugschaden (Trömner)"
                            panelClass="mat-select-width-400"
                            [(ngModel)]="selectedDiminishedValueCalculation.vehicleDamageTroemner"
                            (selectionChange)="calculateAllActiveMethodsAndDrawGraph(); saveReport()"
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt || disabled">
                            <mat-select-trigger>
                                {{ selectedDiminishedValueCalculation.vehicleDamageTroemner | number: '1.1-2' }}
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let vehicleDamageOption of vehicleDamageTroemnerOptions"
                                [value]="vehicleDamageOption.value"
                                class="option-container">
                                <div class="option-value">
                                    {{ vehicleDamageOption.value | number: '1.1-2' }} - {{ vehicleDamageOption.title }}
                                </div>
                                <div class="option-description label">{{ vehicleDamageOption.description }}</div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </section>
            <!--============================================-->
            <!-- END Repair -->
            <!--============================================-->
            <!--============================================-->
            <!-- Results Graph -->
            <!--============================================-->
            <div id="reduce-input-values-by-vat-row">
                <div id="reduce-input-values-heading-container">
                    <h3 id="graph-section-heading" class="no-margin">Berechneter Minderwert</h3>
                    <!--********** Claimant may deduct VAT **********-->
                    <div class="label" [ngSwitch]="carOwnerMayDeductVat">
                        Anspruchsteller Vorsteuerabzug:
                        <span *ngSwitchCase="true">Ja</span>
                        <span *ngSwitchCase="false">Nein</span>
                        <span *ngSwitchCase="null">Unbekannt</span>
                    </div>
                </div>

                <div id="reduce-input-values-divider"></div>

                <div id="reduce-input-values-control-container">
                    <div class="display-flex flex-align-center">
                        Eingaben um MwSt. reduzieren
                        <span
                            class="help-indicator"
                            (click)="openWindow('https://wissen.autoixpert.de/hc/de/articles/20091365279122')"
                            matTooltip="Entscheide, ob im Einklang mit einem BGH-Urteil aus Juli 2024 die Werte Wiederbeschaffungswert und Reparaturkosten um die MwSt. reduziert werden sollen, wenn sie in die Minderwertmethoden einfließen.{{
                                '\n\n'
                            }}Die Reduktion findet im Hintergrund im Rahmen der Berechnung der Resultate statt; die Eingabefelder behalten stets die Bruttowerte.{{
                                '\n\n'
                            }}Klicke für mehr Infos.">
                            ?
                        </span>
                    </div>

                    <div id="reduce-inputs-values-by-vat-control-and-settings-container">
                        <mat-icon
                            *ngIf="selectedDiminishedValueCalculation.reduceInputValuesByVatManuallyOverwritten"
                            class="toolbar-icon medium-icon"
                            (click)="
                                selectedDiminishedValueCalculation.reduceInputValuesByVatManuallyOverwritten = false;
                                updateWhetherToReduceInputValuesByVat();
                                calculateAllActiveMethodsAndDrawGraph();
                                saveReport()
                            "
                            matTooltip="Schalter manuell überschrieben.{{
                                '\n\n'
                            }}Beim nächsten Öffnen des Dialogs in diesem Gutachten wird der Schalter nicht gemäß deiner Einstellungen automatisch aktiviert/deaktiviert, sondern behält seinen Wert. Andere Gutachten werden davon nicht berührt.{{
                                '\n\n'
                            }}Klicke, um in diesem Gutachten wieder den Automatismus greifen zu lassen.">
                            push_pin
                        </mat-icon>

                        <mat-slide-toggle
                            [(ngModel)]="selectedDiminishedValueCalculation.reduceInputValuesByVat"
                            (change)="
                                selectedDiminishedValueCalculation.reduceInputValuesByVatManuallyOverwritten = true;
                                calculateAllActiveMethodsAndDrawGraph();
                                saveReport()
                            "
                            [disabled]="!!selectedDiminishedValueCalculation.lockedAt"></mat-slide-toggle>

                        <mat-icon class="toolbar-icon" [matMenuTriggerFor]="settingsForVatSubtraction">
                            settings
                        </mat-icon>

                        <mat-menu #settingsForVatSubtraction>
                            <!--********** Reduce Inputs automatically based on VAT deducibility **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="team.preferences.alwaysReduceInputsToDiminishedValueCalculatorByVat"
                                (click)="toggleAlwaysReduceInputValuesByVat()"
                                [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                                [matTooltip]="
                                    hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                        ? 'Aktuell werden immer die um die MwSt. reduzierten Nettowerte deiner Eingaben zur Berechnung der Minderwertmethoden verwendet, auch wenn der Anspruchsteller nicht zum Vorsteuerabzug berechtigt ist.' +
                                          '\n\n' +
                                          'Klicke um automatisch zu entscheiden: Wenn der Anspruchsteller zum Vorsteuerabzug berechtigt ist oder dies unbekannt ist, lasse die um die MwSt. reduzierten Nettowerte deiner Eingaben zur Berechnung der Minderwertmethoden verwenden.' +
                                          '\n\n' +
                                          'Wenn er gesichert nicht zum Vorsteuerabzug berechtigt ist, müssen die Eingabewerte nicht reduziert werden. Eine Reduktion würde zu einem zu geringen Minderwert für Privatleute führen.' +
                                          '\n' +
                                          'Es handelt sich dem Namen nach trotzdem um Nettowerte, weil keine Steuer inklusive ist.'
                                        : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                ">
                                Automatisch entscheiden
                            </a>

                            <!--********** Always reduce inputs **********-->
                            <a
                                mat-menu-item
                                class="menu-item-with-icon"
                                *ngIf="!team.preferences.alwaysReduceInputsToDiminishedValueCalculatorByVat"
                                (click)="toggleAlwaysReduceInputValuesByVat()"
                                [disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                                [matTooltip]="
                                    hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                        ? 'Aktuell wird automatisch berechnet, ob die Eingabewerte um die MwSt. reduziert werden. Vorsteuerabzug...' +
                                          '\n' +
                                          'ja: reduzieren' +
                                          '\n' +
                                          'unbekannt: reduzieren' +
                                          '\n' +
                                          'nein: nicht reduzieren' +
                                          '\n\n' +
                                          'Klicke, um immer die um die MwSt. reduzierten Nettowerte deiner Eingaben zur Berechnung der Minderwertmethoden zu verwenden, auch wenn der Anspruchsteller nicht zum Vorsteuerabzug berechtigt ist.' +
                                          '\n\n' +
                                          'Viele Anwälte im Markt empfehlen diese Methode der Sicherheit halber.' +
                                          '\n' +
                                          'Wir empfehlen jedoch, dass automatisch auf Basis der Vorsteuerabzugsfähigkeit entschieden wird, ob die Brutto-Eingaben um die MwSt. reduziert werden, damit Privatleute nicht einen geringeren Minderwert bekommen als nötig.'
                                        : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                ">
                                Immer um MwSt. reduzieren
                            </a>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <section id="graph-section">
                <div
                    class="graph-row"
                    *ngFor="let method of getActiveMethods()"
                    [ngClass]="{ acceptable: method.inputValid }">
                    <div
                        class="graph-bar-name label"
                        [matTooltip]="getGraphNameTooltip(method)"
                        (click)="!disabled && method.inputValid && emitResultAndClose(method.result)">
                        <mat-icon class="use-this-value-icon small-icon">file_download</mat-icon>
                        {{ method.title }}
                    </div>
                    <div class="graph-bar-container">
                        <div class="graph-bar" [style.width]="method.barWidthInPercent + '%'" *ngIf="method.inputValid">
                            <div class="graph-bar-value label">{{ method.result | number: '1.0-0' }} €</div>
                        </div>
                        <mat-icon
                            class="graph-bar-warning-sign small-icon"
                            *ngIf="!method.inputValid"
                            [matTooltip]="method.warningMessage">
                            warning
                        </mat-icon>
                    </div>
                </div>
                <!--********** Average **********-->
                <div class="graph-row" *ngIf="getActiveMethods().length > 0">
                    <div
                        class="graph-bar-name label"
                        matTooltip="{{ averageOfAllActiveMethods.title }} ({{
                            averageOfAllActiveMethods.result | number: '1.0-0'
                        }} €) übernehmen"
                        (click)="
                            !disabled &&
                                averageOfAllActiveMethods.inputValid &&
                                emitResultAndClose(averageOfAllActiveMethods.result)
                        ">
                        <mat-icon class="use-this-value-icon small-icon">file_download</mat-icon>
                        {{ averageOfAllActiveMethods.title }}
                    </div>
                    <div class="graph-bar-container">
                        <div
                            class="graph-bar"
                            [style.width]="averageOfAllActiveMethods.barWidthInPercent + '%'"
                            *ngIf="averageOfAllActiveMethods.inputValid"
                            [matTooltip]="getTooltipForAverageMethod()">
                            <div class="graph-bar-value label">
                                {{ averageOfAllActiveMethods.result | number: '1.0-0' }} €
                            </div>
                        </div>
                        <mat-icon
                            class="graph-bar-warning-sign small-icon"
                            *ngIf="!averageOfAllActiveMethods.inputValid"
                            matTooltip="Bitte prüfe, ob alle Angaben korrekt sind.">
                            warning
                        </mat-icon>
                    </div>
                </div>
            </section>
            <!--============================================-->
            <!-- END Results Graph -->
            <!--============================================-->

            <!--============================================-->
            <!-- Comment -->
            <!--============================================-->
            <section id="comment-section" *ngIf="commentInputShown">
                <h3>Kommentar</h3>
                <div class="input-with-standard-text-button">
                    <mat-form-field id="comment" class="mat-block">
                        <mat-quill
                            placeholder="Kommentar"
                            [(ngModel)]="selectedDiminishedValueCalculation.comment"
                            (change)="saveReport()"
                            [disabled]="disabled"></mat-quill>
                        <mat-icon
                            class="input-toolbar-icon"
                            matSuffix
                            *ngIf="!disabled"
                            (click)="showCommentTextTemplateSelector = true"
                            matTooltip="Textvorlagen">
                            playlist_add
                        </mat-icon>
                    </mat-form-field>
                    <div
                        class="standard-text-button-container"
                        *ngIf="
                            userPreferences.diminishedValueCommentStandardText &&
                            !selectedDiminishedValueCalculation.comment &&
                            !disabled
                        ">
                        <button
                            class="secondary"
                            (click)="insertDiminishedValueCommentStandardText()"
                            matTooltip="Standardtext einfügen">
                            Standardtext
                        </button>
                    </div>
                    <text-template-selector
                        [(text)]="selectedDiminishedValueCalculation.comment"
                        (textChange)="saveReport()"
                        [associatedUserPreference]="'diminishedValueCommentStandardText'"
                        [report]="report"
                        *ngIf="showCommentTextTemplateSelector"
                        placeholderForResultingText="Kommentar Minderwert"
                        (close)="showCommentTextTemplateSelector = false"
                        [textTemplateType]="'diminishedValueComment'"
                        @runChildAnimations></text-template-selector>
                </div>
            </section>
            <!--============================================-->
            <!-- END Comment -->
            <!--============================================-->

            <!--============================================-->
            <!-- Rounding & Buttons -->
            <!--============================================-->
            <section id="rounding-and-buttons-container">
                <!--============================================-->
                <!-- Rounding -->
                <!--============================================-->
                <!--********** Readonly mode **********-->
                <div
                    id="rounding-readonly-container"
                    *ngIf="!disabled && !roundingEditModeActive"
                    [matTooltip]="getRoundingTooltip()"
                    (click)="enterRoundingEditMode()">
                    <ng-container *ngIf="userPreferences.diminishedValueRoundingAmount !== null">
                        {{ userPreferences.diminishedValueRoundingAmount | number: '1.0-0' }} €
                        <mat-icon class="medium-icon" *ngIf="userPreferences.diminishedValueRoundingDirection === 'up'">
                            keyboard_arrow_up
                        </mat-icon>
                        <mat-icon
                            class="medium-icon"
                            *ngIf="userPreferences.diminishedValueRoundingDirection === 'down'">
                            keyboard_arrow_down
                        </mat-icon>
                    </ng-container>
                    <ng-container *ngIf="userPreferences.diminishedValueRoundingAmount === null">
                        Nicht runden
                    </ng-container>
                </div>

                <!--********** Edit Mode **********-->
                <div id="rounding-edit-mode-section" *ngIf="roundingEditModeActive">
                    <h3 id="roundind-heading">Runden</h3>
                    <div id="rounding-edit-mode-container">
                        auf volle
                        <currency-input
                            id="rounding-amount-input"
                            [(value)]="userPreferences.diminishedValueRoundingAmount"
                            placeholder="Betrag"
                            floatLabel="never"></currency-input>
                        <mat-form-field id="rounding-direction-select">
                            <mat-select [(ngModel)]="userPreferences.diminishedValueRoundingDirection">
                                <mat-option value="up">aufrunden</mat-option>
                                <mat-option value="down">abrunden</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-icon
                            class="toolbar-icon"
                            (click)="leaveRoundingEditMode()"
                            matTooltip="Bearbeitung beenden">
                            check
                        </mat-icon>
                        <mat-icon
                            class="toolbar-icon"
                            (click)="removeRoundingAmount(); leaveRoundingEditMode()"
                            matTooltip="Keine Rundung">
                            delete
                        </mat-icon>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Rounding -->
                <!--============================================-->

                <!--============================================-->
                <!-- Buttons -->
                <!--============================================-->
                <div id="buttons-section" *ngIf="!roundingEditModeActive">
                    <ng-container *ngIf="!disabled">
                        <button
                            (click)="
                                isEmissionOfAverageAllowed() && emitResultAndClose(averageOfAllActiveMethods.result)
                            "
                            [ngClass]="{
                                disabled: getActiveMethods().length === 0 || !averageOfAllActiveMethods.inputValid,
                            }"
                            [matTooltip]="
                                getActiveMethods().length === 0 || !averageOfAllActiveMethods.inputValid
                                    ? 'Es muss mindestens eine gültige Berechnungsmethode existieren'
                                    : ''
                            ">
                            Durchschnitt übernehmen
                        </button>
                        <button class="flat" (click)="showCommentInput()" *ngIf="!commentInputShown">
                            Kommentar einfügen
                        </button>
                        <button
                            class="flat"
                            (click)="hideCommentInput(); removeComment(); saveReport()"
                            *ngIf="commentInputShown">
                            Kommentar entfernen
                        </button>
                    </ng-container>

                    <button *ngIf="disabled" (click)="closeCalculator()">Schließen</button>
                </div>
                <!--============================================-->
                <!-- END Buttons -->
                <!--============================================-->
            </section>
            <!--============================================-->
            <!-- END Rounding & Buttons -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- Archive -->
        <!--============================================-->
        <div
            id="archive-column"
            class="dialog-column"
            *ngIf="
                report.valuation.archivedDiminishedValueCalculations &&
                report.valuation.archivedDiminishedValueCalculations.length > 0
            ">
            <h2 id="archive-heading">Archiv</h2>
            <div id="archive-list">
                <div
                    class="archive-list-item"
                    *ngFor="let archiveItem of report.valuation.archivedDiminishedValueCalculations; let index = index"
                    (click)="selectDiminishedValueCalculation(archiveItem)"
                    [matTooltip]="getArchiveEntryTooltip(archiveItem)"
                    [ngClass]="{ active: selectedDiminishedValueCalculation === archiveItem }">
                    <div class="archive-list-item-number">
                        {{ index + 1 }}
                    </div>
                    <div class="archive-list-item-text flex-grow">
                        <div class="archive-liste-item-date">
                            {{ archiveItem.lockedAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}
                        </div>
                        <div class="archive-liste-item-created-by label">
                            {{ getFullCreatorName(archiveItem.createdBy) }}
                        </div>
                    </div>
                    <mat-icon
                        class="toolbar-icon"
                        *ngIf="!disabled"
                        matTooltip="Löschen"
                        (click)="deleteArchiveEntry(archiveItem, $event)">
                        delete
                    </mat-icon>
                </div>
            </div>
            <!-- In order to be able to close an archived entry and edit an existing one, the calculator must be in edit mode (= not disabled). -->
            <div id="archive-button-container" *ngIf="selectedDiminishedValueCalculation.lockedAt">
                <button (click)="leaveArchiveEntryView()">Archiveintrag schließen</button>
            </div>
        </div>
        <!--============================================-->
        <!-- END Archive -->
        <!--============================================-->
    </div>
</div>

export function translateSchoolGradeToPointGrade(schoolGrade: SchoolGrade): number {
    if (!schoolGrade) return null;

    const gradeNumber: number = +schoolGrade[0];
    const tendency: '+' | '-' | '' = schoolGrade[1] as '+' | '-' | '';

    let pointGrade: number = 17 - gradeNumber * 3;

    if (tendency === '+') {
        pointGrade++;
    } else if (tendency === '-') {
        pointGrade--;
    }

    return pointGrade;
}

export function translatePointGradeToSchoolGrade(pointGrade: number): SchoolGrade {
    if (pointGrade === null) return '-';

    // (17 - 14) / 3 === 1; (17 - 13) / 3 === 1.33; (17 - 11) / 3 === 2;
    const gradeNumber: number = Math.round((17 - pointGrade) / 3);

    // 13 % 3 === 1; 12 % 3 === 0; 11 % 3 === 2;
    let tendency: '+' | '-' | '' = '';
    if (pointGrade % 3 === 1) {
        tendency = '-';
    } else if (pointGrade % 3 === 0) {
        tendency = '+';
    }

    return ('' + gradeNumber + tendency) as SchoolGrade;
}

export type SchoolGrade = '1' | '1-' | '2+' | '2' | '2-' | '3+' | '3' | '3-' | '4+' | '4' | '4-' | '5+' | '5' | '-';

export const tireManufacturers: string[] = [
    'APlus',
    'Accelera',
    'Achilles',
    'Antares',
    'Aoteli',
    'Apollo',
    'Atlas',
    'Atturo',
    'Avon',
    'Avon Vintage',
    'BF Goodrich',
    'Barum',
    'Blacklion',
    'Bridgestone',
    'Camac',
    'Coker',
    'Comforser',
    'Constancy',
    'Continental',
    'Cooper',
    'Davanti',
    'Debica',
    'Delinte',
    'Double Star',
    'Dunlop',
    'Duraturn',
    'Duro',
    'Durun',
    'Effiplus',
    'Event',
    'Evergreen',
    'Excelsior',
    'Falken',
    'Farroad',
    'Federal',
    'Firestone',
    'Formula',
    'Fortuna',
    'Fulda',
    'GT Radial',
    'General',
    'Gislaved',
    'Goform',
    'Goodride',
    'Goodyear',
    'Gremax',
    'Gripmax',
    'HI FLY',
    'Habilead',
    'Haida',
    'Hankook',
    'Headway',
    'Heidenau',
    'Horizon',
    'Imperial',
    'Infinity',
    'Insa Turbo',
    'Joyroad',
    'Kama',
    'Kelly',
    'Kenda',
    'Keter',
    'Kinforest',
    'King Meiler',
    'Kingstar',
    'Kleber',
    'Kormoran',
    'Kumho',
    'Landsail',
    'Lanvigator',
    'Lassa',
    'Laufenn',
    'Lexani',
    'Linglong',
    'Malatesta',
    'Maloya',
    'Marix',
    'Marshal',
    'Master',
    'Mastersteel',
    'Matador',
    'Maxtrek',
    'Maxxis',
    'Mazzini',
    'Mentor',
    'Meteor',
    'Metzeler',
    'Michelin',
    'Michelin Collection',
    'Mickey Thompson',
    'Minerva',
    'Nankang',
    'Nereus',
    'Nexen',
    'Nokian',
    'Nordexx',
    'Nortenha',
    'Pace',
    'Petlas',
    'Phoenix',
    'Pirelli',
    'Platin',
    'Pneumant',
    'PowerTrac',
    'Premiorri',
    'Primewell',
    'Profil',
    'Radar',
    'Radburg',
    'Recip',
    'Retro',
    'Riken',
    'Roadstone',
    'Rosava',
    'Rotalla',
    'Rotex',
    'Rovelo',
    'Runway',
    'Sailun',
    'Sava',
    'Security',
    'Seiberling',
    'Semperit',
    'Silverstone',
    'Sonar',
    'Star Performer',
    'Starco',
    'Starfire',
    'Starmaxx',
    'Sumitomo',
    'Sunny',
    'Superia',
    'Syron',
    'Tecar',
    'Tigar',
    'Torque',
    'Toyo',
    'Tracmax',
    'Tristar',
    'Uniroyal',
    'Viking',
    'Voltyre',
    'Vredestein',
    'Wanli',
    'Waymaster',
    'Westlake',
    'Windforce',
    'Winter Tact',
    'Yokohama',
];

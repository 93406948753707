<div class="dialog-overlay" (mousedown)="handleOverlayClick($event)" @dialogEnterAndLeave>
    <div class="dialog-container contains-scrollable-area">
        <!--============================================-->
        <!-- Document Selector -->
        <!--============================================-->
        <div id="document-selector-container">
            <div id="document-selector" class="card dark-card">
                <!--********** Declaration of Assignment **********-->
                <div
                    class="document-selector-item"
                    *ngIf="doesAvailableTabsInclude({ documentType: 'declarationOfAssignment' })"
                    [ngClass]="{ selected: selectedTab?.documentType === 'declarationOfAssignment' }"
                    (click)="triggerTabSwitch({ documentType: 'declarationOfAssignment' })"
                    matTooltip="{{
                        report.type === 'liability' || report.type === 'shortAssessment' ? 'Abtretung' : 'Auftrag'
                    }}">
                    <mat-icon class="document-selector-icon">how_to_vote</mat-icon>
                    <mat-icon
                        *ngIf="isDocumentSigned({ documentType: 'declarationOfAssignment' })"
                        class="document-completed-icon"
                        @fadeInAndOut
                        matTooltip="Unterschrieben">
                        check
                    </mat-icon>
                </div>

                <!--********** Cancellation Policy **********-->
                <div
                    class="document-selector-item"
                    *ngIf="doesAvailableTabsInclude({ documentType: 'revocationInstruction' })"
                    [ngClass]="{ selected: selectedTab?.documentType === 'revocationInstruction' }"
                    (click)="triggerTabSwitch({ documentType: 'revocationInstruction' })"
                    matTooltip="Widerruf">
                    <mat-icon class="document-selector-icon">undo</mat-icon>
                    <mat-icon
                        *ngIf="isDocumentSigned({ documentType: 'revocationInstruction' })"
                        class="document-completed-icon"
                        @fadeInAndOut
                        matTooltip="Unterschrieben">
                        check
                    </mat-icon>
                </div>

                <!--********** Privacy Statement **********-->
                <div
                    class="document-selector-item"
                    *ngIf="doesAvailableTabsInclude({ documentType: 'consentDataProtection' })"
                    [ngClass]="{ selected: selectedTab?.documentType === 'consentDataProtection' }"
                    (click)="triggerTabSwitch({ documentType: 'consentDataProtection' })"
                    matTooltip="Datenschutz">
                    <mat-icon class="document-selector-icon">fingerprint</mat-icon>
                    <mat-icon
                        *ngIf="isDocumentSigned({ documentType: 'consentDataProtection' })"
                        class="document-completed-icon"
                        @fadeInAndOut
                        matTooltip="Unterschrieben">
                        check
                    </mat-icon>
                </div>

                <!--********** Lawyer Order **********-->
                <div
                    class="document-selector-item"
                    *ngIf="
                        doesAvailableTabsInclude({ documentType: 'powerOfAttorney' }) &&
                        shouldPowerOfAttorneyDocumentBeVisible()
                    "
                    [ngClass]="{ selected: selectedTab?.documentType === 'powerOfAttorney' }"
                    (click)="triggerTabSwitch({ documentType: 'powerOfAttorney' })"
                    matTooltip="Anwalt">
                    <img src="/assets/images/icons/lawyer-white.svg" alt="" class="document-selector-icon" />
                    <mat-icon
                        *ngIf="isDocumentSigned({ documentType: 'powerOfAttorney' })"
                        class="document-completed-icon"
                        @fadeInAndOut
                        matTooltip="Unterschrieben">
                        check
                    </mat-icon>
                </div>

                <!--********** Custom signature documents tabs **********-->
                <ng-container *ngFor="let tab of availableCustomDocumentTabs; trackBy: trackById">
                    <div
                        class="document-selector-item"
                        [ngClass]="{
                            selected:
                                selectedTab?.documentType === tab.documentType &&
                                (!tab.customDocumentOrderConfigId ||
                                    selectedTab?.customDocumentOrderConfigId === tab.customDocumentOrderConfigId),
                        }"
                        (click)="triggerTabSwitch(tab)"
                        matTooltip="{{ tab.title }}">
                        <mat-icon class="document-selector-icon">{{ tab.icon }}</mat-icon>
                        <mat-icon
                            *ngIf="isDocumentSigned(tab)"
                            class="document-completed-icon"
                            @fadeInAndOut
                            matTooltip="Unterschrieben">
                            check
                        </mat-icon>
                    </div>
                </ng-container>

                <!--********** Sign All Documents At Once **********-->
                <div
                    id="sign-all-documents-at-once-tab-selector"
                    class="document-selector-item"
                    *ngIf="doesAvailableTabsInclude({ documentType: 'signAllDocumentsAtOnce' })"
                    [ngClass]="{ selected: selectedTab?.documentType === 'signAllDocumentsAtOnce' }"
                    (click)="triggerTabSwitch({ documentType: 'signAllDocumentsAtOnce' })"
                    matTooltip="Alle Dokumente auf einmal unterschreiben">
                    <div id="sign-all-documents-at-once-tab-selector-inner-container">
                        <mat-icon class="document-selector-icon">gesture</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <!--============================================-->
        <!-- END Document Selector -->
        <!--============================================-->

        <div
            class="dialog-scrollable-area"
            [ngClass]="{ padding: !selectedSignablePdfTemplateConfig }"
            #dialogScrollableArea>
            <div class="card-toolbar">
                <mat-icon class="toolbar-icon" [matMenuTriggerFor]="dialogSubmenu" *ngIf="userIsAdmin()">
                    more_vert
                </mat-icon>
                <mat-menu #dialogSubmenu>
                    <!--********** Sign All Documents At Once **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="!team.preferences.signAllDocumentsAtOnce"
                        [matTooltip]="
                            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : ''
                        "
                        [class.disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                        (click)="setPreferenceToSignDocumentsAtOnce(true)">
                        <mat-icon>gesture</mat-icon>
                        Dokumente auf einmal unterschreiben
                    </a>
                    <a
                        mat-menu-item
                        class="menut-item-with-icon"
                        *ngIf="team.preferences.signAllDocumentsAtOnce"
                        [matTooltip]="
                            !hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')
                                ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                                : ''
                        "
                        [class.disabled]="!hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
                        (click)="setPreferenceToSignDocumentsAtOnce(false)">
                        <mat-icon>grid_view</mat-icon>
                        Dokumente einzeln unterschreiben
                    </a>

                    <!--********** Edit Document Building Blocks **********-->
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="!selectedSignablePdfTemplateConfig"
                        (click)="openDocumentBuildingBlocks()"
                        [disabled]="selectedTab?.documentType === 'signAllDocumentsAtOnce'">
                        <mat-icon>edit</mat-icon>
                        Texte bearbeiten
                    </a>
                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="selectedSignablePdfTemplateConfig"
                        (click)="openPdfTemplateConfig()"
                        [disabled]="selectedTab?.documentType === 'signAllDocumentsAtOnce'">
                        <mat-icon>edit</mat-icon>
                        PDF-Vorlage bearbeiten
                    </a>

                    <a
                        mat-menu-item
                        class="menu-item-with-icon"
                        *ngIf="selectedSignablePdfTemplateConfig"
                        (click)="unlinkPdfTemplateConfig()"
                        [disabled]="selectedTab?.documentType === 'signAllDocumentsAtOnce'"
                        matTooltip="PDF-Vorlage entkoppeln, um das Dokument mit Textbausteinen zu verwenden.">
                        <mat-icon>link_off</mat-icon>
                        PDF-Vorlage entfernen
                    </a>

                    <!--********** Download Signature Files **********-->
                    <a mat-menu-item class="menu-item-with-icon" (click)="downloadSignatureFiles()">
                        <mat-icon>signature</mat-icon>
                        Unterschriften herunterladen
                    </a>
                </mat-menu>
                <mat-icon class="toolbar-icon" (click)="closeDialog()">close</mat-icon>
            </div>

            <claimant-signature-documents
                *ngIf="signableDocumentsRenderingConfig"
                [dipslayTitle]="true"
                [selectedTab]="selectedTab"
                [config]="signableDocumentsRenderingConfig"
                [signatureDisabledReason]="getSignaturePadDisabledReason()"
                [signaturePadFullscreenMode]="'signatureOnly'"
                [disableSignatureTransfer]="true"
                (addPowerOfAttorney)="addPowerOfAttorney()"
                (saveReport)="saveReport()"></claimant-signature-documents>

            <!--============================================-->
            <!-- Sign All Documents At Once -->
            <!--============================================-->
            <div id="sign-all-documents-container" *ngIf="selectedTab?.documentType === 'signAllDocumentsAtOnce'">
                <h2>Alle Dokumente unterschreiben</h2>

                <div id="sign-all-documents-intro">
                    Ich habe alle nachfolgend aufgeführten Dokumente zur Kenntnis genommen und erkläre mich mit ihnen
                    einverstanden.
                </div>

                <div id="sign-all-documents-list-container">
                    <mat-checkbox
                        *ngIf="doesAvailableTabsInclude({ documentType: 'declarationOfAssignment' })"
                        checked="true"
                        disabled>
                        {{ translateDocumentType('declarationOfAssignment') }}
                    </mat-checkbox>
                    <mat-checkbox
                        *ngIf="doesAvailableTabsInclude({ documentType: 'revocationInstruction' })"
                        checked="true"
                        disabled>
                        {{ translateDocumentType('revocationInstruction') }}
                    </mat-checkbox>
                    <mat-checkbox
                        *ngIf="doesAvailableTabsInclude({ documentType: 'consentDataProtection' })"
                        checked="true"
                        disabled>
                        {{ translateDocumentType('consentDataProtection') }}
                    </mat-checkbox>
                    <mat-checkbox
                        *ngIf="
                            doesAvailableTabsInclude({ documentType: 'powerOfAttorney' }) &&
                            shouldPowerOfAttorneyDocumentBeVisible()
                        "
                        checked="true"
                        disabled>
                        {{ translateDocumentType('powerOfAttorney') }}
                    </mat-checkbox>

                    <!-- Custom signable documents -->
                    <ng-container *ngFor="let tab of availableCustomDocumentTabs; trackBy: trackById">
                        <mat-checkbox checked="true" disabled>
                            {{ tab.title }}
                        </mat-checkbox>
                    </ng-container>
                </div>

                <!--============================================-->
                <!-- Signature Pad Small -->
                <!--============================================-->
                <signature-pad
                    *ngIf="isSignaturePadVisible()"
                    [claimantSignatures]="allClaimantSignatures"
                    [reportId]="report._id"
                    [signerName]="getSignerName()"
                    [signerOrganization]="report.claimant.contactPerson.organization"
                    (signatureSaved)="saveReport()"
                    (signatureFilesDeleted)="saveReport()"
                    [disabledReason]="getSignaturePadDisabledReason()"
                    [disableSignatureTransfer]="true"
                    #signaturePad="signaturePad"></signature-pad>
                <!--============================================-->
                <!-- END Signature Pad Small -->
                <!--============================================-->
            </div>
            <!--============================================-->
            <!-- END Sign All Documents At Once -->
            <!--============================================-->

            <!--============================================-->
            <!-- Document Selection Change Overlay -->
            <!--============================================-->
            <div
                id="document-selection-change-overlay-indicator"
                *ngIf="tabSelectionChangeOverlayShown"
                @fadeInAndOut></div>
            <!--============================================-->
            <!-- END Document Selection Change Overlay -->
            <!--============================================-->

            <!--============================================-->
            <!-- Pending Indicator -->
            <!--============================================-->
            <!--********** Don't show a loading indicator while we're on a PDF template tab. **********-->
            <div
                id="content-loading-indicator"
                *ngIf="buildingBlocksPending && !this.selectedSignablePdfTemplateConfig"
                @fadeInAndOut>
                <img src="/assets/images/icons/loading-indicator.svg" alt="" class="pending-icon" />
                Lädt...
            </div>
            <!--============================================-->
            <!-- END Pending Indicator -->
            <!--============================================-->
        </div>

        <!--============================================-->
        <!-- Next Button -->
        <!--============================================-->
        <button
            id="next-document-button"
            *ngIf="!buildingBlocksPending && !tabSelectionChangeOverlayShown"
            (click)="
                selectedTab?.documentType === 'signAllDocumentsAtOnce'
                    ? uploadSignatureToAllDocuments()
                    : openNextDocument()
            "
            [class.disabled]="!isDocumentComplete()"
            [matTooltip]="
                !isDocumentComplete()
                    ? 'Eine Unterschrift ist erforderlich.'
                    : isLastTabSelected()
                      ? 'Letztes Dokument abschließen'
                      : 'Nächstes Dokument'
            "
            @fadeInAndOut>
            <ng-container *ngIf="!savingSignaturePending">
                <mat-icon class="button-icon" *ngIf="!isLastTabSelected()">arrow_forward</mat-icon>
                <mat-icon class="button-icon" *ngIf="isLastTabSelected()">check</mat-icon>
            </ng-container>
            <img
                src="/assets/images/icons/loading-indicator-white.svg"
                class="pending-icon button-icon"
                *ngIf="savingSignaturePending" />
        </button>
        <!--============================================-->
        <!-- END Next Button -->
        <!--============================================-->
    </div>
</div>

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostBinding, HostListener, Injector, Input } from '@angular/core';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { InvoiceService } from '../../services/invoice.service';
import {
    DISPLAY_NET_VALUES_TOKEN,
    INVOICES_TOKEN,
    InvoiceListOverlayComponent,
    VALUE_DISPLAY_TYPE_TOKEN,
} from './invoice-list-overlay.component';

@Directive({
    selector: '[invoice-list-overlay-anchor]',
})
export class InvoiceListOverlayAnchorDirective {
    constructor(
        private elementRef: ElementRef,
        private overlayService: Overlay,
        private injector: Injector,
        private invoiceService: InvoiceService,
    ) {}

    @Input() invoiceIds: string[];
    @Input() displayNetValues: boolean;
    @Input() valueDisplayType: 'totalAmount' | 'unpaidAmount' = 'totalAmount';

    private overlayRef: OverlayRef;

    ngOnInit() {}

    //*****************************************************************************
    //  Load Invoices
    //****************************************************************************/
    private loadInvoices() {
        this.invoiceService.find({ _id: { $in: this.invoiceIds } }).subscribe((invoices) => {
            if (invoices.length) {
                this.openOverlay(invoices);
            }
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Invoices
    /////////////////////////////////////////////////////////////////////////////*/
    //*****************************************************************************
    //  Open Overlay
    //****************************************************************************/
    public openOverlay(invoices: Invoice[]) {
        // Don't open two dialogs on double click
        if (this.overlayRef) return;

        // Don't open dialog if there are no invoices
        if (!this.invoiceIds.length) return;

        this.overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    // Regular positioning: Below the anchor
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    // Alternative positioning: above the anchor
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                ])
                .withPush(true)
                .withViewportMargin(10)
                .withGrowAfterOpen(true),
            scrollStrategy: this.overlayService.scrollStrategies.close({
                threshold: 0,
            }),
        });

        // Handle closing behavior
        this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach());
        this.overlayRef.detachments().subscribe(() => {
            this.overlayRef = null;
        });

        // Create injector
        const injector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: INVOICES_TOKEN,
                    useValue: invoices,
                },
                {
                    provide: OverlayRef,
                    useValue: this.overlayRef,
                },
                {
                    provide: DISPLAY_NET_VALUES_TOKEN,
                    useValue: this.displayNetValues,
                },
                {
                    provide: VALUE_DISPLAY_TYPE_TOKEN,
                    useValue: this.valueDisplayType,
                },
            ],
        });

        const portal = new ComponentPortal(InvoiceListOverlayComponent, null, injector);
        this.overlayRef.attach(portal);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Open Overlay
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Host Events
    //****************************************************************************/
    @HostListener('click', ['$event'])
    public handleHostClick(event: MouseEvent) {
        if (!this.invoiceIds.length) return;

        this.loadInvoices();
    }

    @HostBinding('class.cursor-pointer')
    public get isClickable(): boolean {
        return this.invoiceIds.length > 0;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Host Events
    /////////////////////////////////////////////////////////////////////////////*/
}

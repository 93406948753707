import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Injector, Input, SimpleChanges } from '@angular/core';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { getContactPersonFullNameWithOrganization } from '@autoixpert/lib/contact-people/get-contact-person-full-name-with-organization';
import { calculateRankForTask } from '@autoixpert/lib/tasks/calculate-rank-for-task';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { Task } from '@autoixpert/models/tasks/task';
import { User } from '@autoixpert/models/user/user';
import { taskListRankMax, taskListRankMin } from '@autoixpert/static-data/tasks/task-list-rank-limits';
import { ApiErrorService } from '../../../services/api-error.service';
import { LoggedInUserService } from '../../../services/logged-in-user.service';
import { TaskService } from '../../../services/task.service';
import { ToastService } from '../../../services/toast.service';
import { UserPreferencesService } from '../../../services/user-preferences.service';
import { TaskDetailsDialogComponent } from '../task-details-dialog/task-details-dialog.component';

/**
 * Allows to display tasks inline, such as in the report/invoice details panel.
 */
@Component({
    selector: 'task-list-inline',
    templateUrl: './task-list-inline.component.html',
    styleUrls: ['./task-list-inline.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class TaskListInlineComponent {
    constructor(
        private taskService: TaskService,
        private apiErrorService: ApiErrorService,
        public userPreferences: UserPreferencesService,
        private toastService: ToastService,
        private loggedInUserService: LoggedInUserService,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}

    @Input() report?: Report;
    @Input() invoice?: Invoice;

    private user: User;

    public tasks: Task[] = [];
    public sortedTasks: Task[] = [];

    // Reference to the overlay with a task's details, allowing things like labels to be set.
    private taskDetailsOverlayRef: OverlayRef;

    ngOnInit() {
        this.user = this.loggedInUserService.getUser();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['report' satisfies keyof this]) {
            if (!this.report) {
                this.toastService.error(
                    'Gutachten nicht geladen',
                    "Beim Laden der verknüpften Aufgaben war das Gutachten nicht im Zugriff. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>",
                );
                return;
            }

            this.loadTasksForReport(this.report._id);
        } else if (changes['invoice' satisfies keyof this]) {
            if (!this.invoice) {
                this.toastService.error(
                    'Rechnung nicht geladen',
                    "Beim Laden der verknüpften Aufgaben war die Rechnung nicht im Zugriff. Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>",
                );
                return;
            }

            this.loadTasksForInvoice(this.invoice._id);
        }
    }

    //*****************************************************************************
    //  Load Tasks
    //****************************************************************************/
    /**
     * Load tasks associated with a report.
     */
    public async loadTasksForReport(reportId: string) {
        try {
            this.tasks = await this.taskService
                .find({
                    'associatedReport.reportId': reportId,
                })
                .toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Aufgaben für Gutachten nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        this.sortTasks();
    }

    /**
     * Load tasks associated with an invoice.
     */
    public async loadTasksForInvoice(invoiceId: string) {
        try {
            this.tasks = await this.taskService
                .find({
                    'associatedInvoice.invoiceId': invoiceId,
                })
                .toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Aufgaben für Rechnung nicht geladen`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        this.sortTasks();
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  CRUD Tasks
    //****************************************************************************/
    /**
     * Add new task to list and create task on the server.
     *
     * A task is added
     * - at the top/bottom of the list or
     * - before/after an existing task.
     */
    async createNewTask({
        position,
        referenceTask,
    }: {
        position: 'first' | 'last' | 'before' | 'after';
        referenceTask?: Task;
    }) {
        const newTask: Task = this.generateNewTask();

        newTask.rank = calculateRankForTask({
            position,
            sortedTasks: this.sortedTasks,
            referenceTask,
        });

        this.tasks.push(newTask);
        this.sortTasks();

        await this.taskService.create(newTask);
    }

    /**
     * Create instance of new class and attach the report or invoice data passed in from the parent component.
     * Only one (either report or invoice) shall be passed in from the parent.
     *
     * @returns New task with reference to and core data of report/invoice.
     */
    public generateNewTask(): Task {
        return new Task({
            associatedReport: this.report
                ? {
                      reportId: this.report._id,
                      token: this.report.token,
                      formattedClaimant: getContactPersonFullNameWithOrganization({
                          organization: this.report.claimant.contactPerson.organization,
                          firstName: this.report.claimant.contactPerson.firstName,
                          lastName: this.report.claimant.contactPerson.lastName,
                      }),
                      make: this.report.car.make,
                      model: this.report.car.model,
                      licensePlate: this.report.car.licensePlate,
                  }
                : null,
            associatedInvoice: this.invoice
                ? {
                      invoiceId: this.invoice._id,
                      number: this.invoice.number,
                      formattedRecipient: getContactPersonFullNameWithOrganization({
                          organization: this.invoice.recipient.contactPerson.organization,
                          firstName: this.invoice.recipient.contactPerson.firstName,
                          lastName: this.invoice.recipient.contactPerson.lastName,
                      }),
                      date: this.invoice.date,
                  }
                : null,
            assigneeId: this.user._id,
            createdBy: this.user._id,
        });
    }

    public async saveTask(task: Task) {
        try {
            await this.taskService.put(task);
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Aufgabe nicht gespeichert`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    public async deleteTask(task: Task) {
        const { index } = removeFromArray(task, this.tasks);
        this.sortTasks();

        try {
            await this.taskService.delete(task._id);
        } catch (error) {
            this.tasks.splice(index, 0, task);

            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Aufgabe nicht gelöscht`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END CRUD Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Sort & Filter Tasks
    //****************************************************************************/
    public sortTasks() {
        // Filter tasks
        this.sortedTasks = this.tasks.sort((a, b) => a.rank - b.rank);
    }

    public onTaskDropped(event: CdkDragDrop<any>) {
        if (event.previousIndex === event.currentIndex) {
            return;
        }

        const draggedTask = this.tasks[event.previousIndex];
        let elementAbove: Task;
        let elementBelow: Task;

        // Drag down -> index has not to be shifted since the element is removed before the new index
        if (event.currentIndex > event.previousIndex) {
            elementAbove = this.tasks[event.currentIndex];
            elementBelow = this.tasks[event.currentIndex + 1];
        }
        // Drag up -> index has to be shifted since the element is removed after the new index
        else {
            elementAbove = this.tasks[event.currentIndex - 1];
            elementBelow = this.tasks[event.currentIndex];
        }

        // If an element is dragged to the top or bottom, the rank is calculated from the edge
        const rankAbove = elementAbove?.rank || taskListRankMin;
        const rankBelow = elementBelow?.rank || taskListRankMax;

        draggedTask.rank = (rankAbove + rankBelow) / 2;

        this.sortTasks();
        this.saveTask(draggedTask);
    }

    public sortToBack(task: Task) {
        if (!this.userPreferences.taskPanel_sortToBackOnCompletion) return;

        const lastTask = this.sortedTasks.at(-1);

        // No need to re-sort if there's only one task.
        if (lastTask === task) return;

        // Sort in between the last task and the highest possible rank.
        task.rank = (lastTask.rank + taskListRankMax) / 2;

        this.sortTasks();
        this.saveTask(task);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Sort & Filter Tasks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Task Details Dialog
    //****************************************************************************/
    public openTaskDetailsDialog(task: Task) {
        // Avoid duplicate panels.
        if (this.taskDetailsOverlayRef) return;

        // Configure overlay
        this.taskDetailsOverlayRef = this.overlayService.create({
            hasBackdrop: true,
            positionStrategy: this.overlayService.position().global().centerHorizontally().centerVertically(),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
            width: 700,
            maxWidth: '95vw',
            // minWidth: 200,
            // maxWidth: 250,
        });

        // Close panel when clicking the backdrop.
        this.taskDetailsOverlayRef.backdropClick().subscribe(() => {
            this.taskDetailsOverlayRef.detach();
        });
        this.taskDetailsOverlayRef.detachments().subscribe(() => {
            this.taskDetailsOverlayRef = null;
        });

        // Instantiate the portal component.
        const componentPortal = new ComponentPortal<TaskDetailsDialogComponent>(
            TaskDetailsDialogComponent,
            null,
            Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: OverlayRef,
                        useValue: this.taskDetailsOverlayRef,
                    },
                ],
            }),
        );

        // Attach Component to Portal Outlet
        const componentRef = this.taskDetailsOverlayRef.attach(componentPortal);

        // Update component properties.
        componentRef.instance.task = task;

        // Subscribe to changes in component.
        componentRef.instance.taskDeleted.subscribe((deletedTask) => {
            this.deleteTask(deletedTask);
        });
    }

    /**
     * If the user double-clicks exactly on the element with this handler (not on its children),
     * open the details dialog.
     */
    public openTaskDetailsOnDirectDoubleClick({ event, task }: { event: MouseEvent; task: Task }) {
        if (event.target === event.currentTarget) {
            this.openTaskDetailsDialog(task);
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Task Details Dialog
    /////////////////////////////////////////////////////////////////////////////*/
}

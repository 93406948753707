export function isChangeDealingWithArrayOfPlainValues(oldArray: any[], newArray: any[]): boolean {
    const oldAndNewArrayElements = [...oldArray, ...newArray];
    for (const arrayElement of oldAndNewArrayElements) {
        /**
         * If the array element is not a plain value that can easily be compared and thus merged, return false.
         *
         * A value easy to compare is:
         * - a string
         * - a number
         * We assume that the same values never occur twice. If they do, merging the array does not work and the array is overwritten instead.
         *
         * A value hard to compare is:
         * - an object
         * - itself an array
         * - a function
         * - boolean values (multiple boolean values cannot be compared by content)
         */
        if (
            arrayElement !== null &&
            arrayElement !== undefined &&
            typeof arrayElement !== 'string' &&
            typeof arrayElement !== 'number'
        ) {
            return false;
        }
    }
    return true;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { tap } from 'rxjs/operators';
import { HelpCenterQuery } from '@autoixpert/models/help-center-query';

@Injectable()
export class HelpcenterSearchService {
    public lastHelpCenterQueryId: string;

    constructor(private httpClient: HttpClient) {}

    public find(searchTerm: string): Observable<ZendeskHelpcenterArticle[]> {
        return fromPromise(
            fetch(`https://wissen.autoixpert.de/api/v2/help_center/articles/search.json?query=${searchTerm}`)
                .then((response) => response.json() as Promise<ZendeskHelpcenterResponse>)
                .then((helpcenterResponse) => helpcenterResponse.results)
                .then((helpcenterArticles) => {
                    // The help center queries are being saved to improve search results by adding help center articles about topics that are frequently searched
                    // but which do not yet have their own article.
                    this.httpClient
                        .post<HelpCenterQuery>('/api/v0/analytics/helpCenterQueries', {
                            query: searchTerm,
                            numberOfResults: helpcenterArticles.length,
                        })
                        .subscribe({
                            next: (helpCenterQuery) => {
                                this.lastHelpCenterQueryId = helpCenterQuery._id;
                            },
                            error: (error) => {
                                console.error('Error writing the help center query analytics element.', { error });
                            },
                        });
                    return helpcenterArticles;
                }),
        );
    }

    public setClickedLinkOnHelpCenterQuery(clickedLink: string): Observable<HelpCenterQuery> {
        return this.httpClient
            .patch<HelpCenterQuery>(`/api/v0/analytics/helpCenterQueries/${this.lastHelpCenterQueryId}`, {
                clickedLink,
            })
            .pipe(
                tap({
                    error: (error) => {
                        console.error('Error writing the clicked link back to the help center analytics object.', {
                            error,
                        });
                    },
                }),
            );
    }
}

export interface ZendeskHelpcenterResponse {
    count: number;
    next_page: string;
    page: number;
    page_count: number;
    per_page: number;
    previous_page: string;
    results: ZendeskHelpcenterArticle[];
}

export interface ZendeskHelpcenterArticle {
    id: number;
    url: string;
    html_url: string;
    author_id: number;
    comments_disabled: boolean;
    draft: boolean;
    promoted: false;
    position: number;
    vote_sum: number;
    vote_count: number;
    section_id: number;
    created_at: string;
    updated_at: string;
    name: string;
    title: string;
    source_locale: string;
    locale: string;
    outdated: boolean;
    outdated_locales: [];
    edited_at: string;
    user_segment_id: number;
    permission_group_id: number;
    label_names: string[];
    body: string;
    snippet: string;
    result_type: 'article';
}

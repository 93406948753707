//*****************************************************************************
//  Repair Calculation
//****************************************************************************/
import { GarageFeeSet } from '../../contacts/garage-fee-set';
import { DamagedPart } from '../damage-calculation/damaged-part';
import { ManualCalculation } from '../damage-calculation/manual-calculation';

export class DatCalculation {
    dossierId: number = null;
}

/**
 * Result of a calculation provider. Subset of the Repair object.
 */
export class RepairCalculation {
    //*****************************************************************************
    //  Garage/Body Shop
    //****************************************************************************/

    sparePartsCostsNet: number = null;
    auxiliaryCostsNet: number = null;

    bodyworkLaborHours: number = null;
    electricLaborHours: number = null;
    mechanicLaborHours: number = null;
    // Sum of bodywork, electric and mechanic labor hours.
    garageLaborHours: number = null;
    /**
     * Garage labor costs do not include lacquer labor costs. The
     */
    garageLaborCostsNet: number = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Garage/Body Shop
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Lacquer
    //****************************************************************************/
    lacquerMaterialCostsNet: number = null;
    lacquerLaborCostsNet: number = null;
    lacquerLaborHours: number = null;
    /**
     * lacquerCostsNet = lacquerMaterialCostsNet + lacquerLaborCostsNet
     * This is a separate field since approximate calculations do not split lacquer into labor and material. This field should always be filled
     * by a client that changes material or labor costs.
     */
    lacquerCostsNet: number = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Lacquer
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * These are the costs that the garage would later print onto their invoice.
     */
    repairCostsNet: number = null;
    repairCostsGross: number = null;

    //*****************************************************************************
    //  Subtractions
    //****************************************************************************/
    /**
     * Discount (German "Rabatt").
     * Usually only used if the claimant is a large company that gets discounts
     * in the repairing garage such as Sixt or Europcar (rental car companies).
     */
    discountNet: number = null;
    discountGross: number = null;
    /**
     * New-for-old (German "Neu-für-alt"). Used by assessors to counterbalance an advantage for the claimant due to repair of parts that
     * needed replacement *soon* anyway. Costs must be *palpable*.
     */
    newForOldNet: number = null;
    newForOldGross: number = null;

    /**
     * This property holds the value increase entered through DAT/Audatex.
     * The user could also enter it directly in autoiXpert but that value is stored in `report.valuation.valueIncrease`.
     */
    increasedValue: number = null;
    increasedValueTaxationType: 'full' | 'net' | 'neutral' = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Subtractions
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * Repair costs are corrected for new-for-old and potential discounts. This is not yet the total
     * damage value since diminished value, increased value, residual value & replacement value (total economic
     * loss: yes or no?) must be taken into account.
     */
    correctedRepairCostsNet: number = null;
    correctedRepairCostsGross: number = null;

    /**
     * German "Überschlägige Kalkulation"
     * Used if the assessor starts a precise calculation but then decides that the repair costs will be way higher than the vehicle value,
     * so he creates an approximate calculation.
     */
    isApproximateCalculation?: boolean;
    /**
     * Whether the estimated repair costs were made in
     * - simple mode (total sum of repair costs) or
     * - complex mode (repair costs split into spare parts, labor and paint).
     */
    useSimpleEstimateCalculation?: boolean;
    /**
     * German "Phantom-Kalkulation"
     * When an assessor can't find the original vehicle within a data provider's system, the assessor uses a similar vehicle. That's
     * called a phantom calculation.
     */
    isPhantomCalculation?: boolean;

    /**
     * This is true if the damage calculation provider (currently only supported by DAT) indicates issues with the calculation because some combination
     * does not make sense, e.g. because a special equipment position was marked for repair but was not included in the equipment array.
     */
    hasInvalidPositions?: boolean;

    exportedFeeSet: GarageFeeSet = null;

    /**
     * Damaged Parts
     * These are required to generate the damage description or repair instruction automatically.
     */
    damagedParts: DamagedPart[] = [];

    //*****************************************************************************
    //  Partner Interfaces
    //****************************************************************************/
    datCalculation?: DatCalculation;
    manualCalculation?: ManualCalculation;
    calculationProvider: CalculationProvider = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Partner Interfaces
    /////////////////////////////////////////////////////////////////////////////*/

    /**
     * A placeholder that triggers re-rendering of the report DOCX/PDF when the calculation document changes.
     */
    documentHash?: string;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Repair Calculation
/////////////////////////////////////////////////////////////////////////////*/

/**
 * The Repair objects contains both the properties of RepairCalculation and additional properties not associated with
 * either calculation provider, e.g. paintBlending.
 */
export class Repair extends RepairCalculation {
    // DEKRA provides regional garage fee averages. The assessor might choose those instead of the ones of a specific garage.
    useDekraFees: boolean = null;
    // The ZIP code of the claimant can be overwritten in case there aren't any DEKRA fees for a ZIP area.
    customDekraFeeZip?: string = null;
    customDekraFeeCity?: string = null;
    /**
     * German "Reparaturauftrag erteilt"
     * If the claimant already issued a repair order, the insurance needs to pay the garage's fees that the claimant chose.
     */
    repairOrderIssued: boolean = null;

    //*****************************************************************************
    //  Qualitative Repair Data
    //****************************************************************************/
    /**
     * German "Beilackierung". If the car paint is metallic or similarly complex, the neighboring parts
     * must also be re-painted. Otherwise, the repaired part would be too obvious.
     */
    paintBlendingRequired: boolean = null;
    // Every time paint blending is required, the assessor should describe why that is so according to the BVSK.
    paintBlendingComment: string = null;

    /**
     * German "Kunststoffreparatur". Plastic Repair may be allowed if the damaged part qualifies for it. It's
     * cheaper but has to be confirmed by the assessor.
     */
    plasticRepairRequired: boolean = null;

    /**
     * German "Achsvermessung". Might be required before the repair to identify a possibly warped/distorted axle. Usually,
     * the axle is then handled as a risk part ("Risikobauteil").
     */
    axisMeasurement: AxisMeasurementStatus = null;
    axisMeasurementComment: string = null;

    /**
     * German "Karosserievermessung". Might be required before the repair to identify a possibly warped/distorted car body.
     * Usually, the entire car body is then handled as a risk part ("Risikobauteil").
     */
    carBodyMeasurement: CarBodyMeasurementStatus = null;
    carBodyMeasurementComment: string = null;

    /**
     * German "Batterieprüfung" or "Prüfung Hochvoltsystem". Might be required before the repair to identify if the battery and high voltage system is save.
     * Usually, the battery is then handled as a risk part ("Risikobauteil").
     */
    highVoltageSystemCheck: HighVoltageSystemCheckStatus = null;
    highVoltageSystemCheckComment: string = null;

    // German "Reparaturweg". Sometimes, the assessors want to specify the instructions the repair shop has to follow.
    repairInstructions: string = null;

    /**
     * German "Reparaturrisiken". Required if some parts can't be accessed for assessment, usually because other parts prevent a
     * clear line of sight, and the damage makes it possible that these underlying parts are warped. The assessor is usually called
     * in at the workshop during the repair after the garage workers uncovered the risk part.
     */
    repairRisks: string[] = [];
    repairRisksComment: string = null;

    /**
     * May be used to add comments about why certain actions were taken in the repair calculation.
     * Example: An assessor estimates repair costs for a Mercedes transporter. There are two wages: one for the Mercedes body shop and one for
     * the outfitter adding special shelves to the storage compartment of the transporter. The DAT can't use both wages, so a manual position is
     * added for the shelves. This calculationComment explains why there is a manual position with a different wage.
     */
    calculationComment: string = null;
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Qualitative Repair Data
    /////////////////////////////////////////////////////////////////////////////*/
}

export type AxisMeasurementStatus = 'required' | 'notRequired' | 'carriedOut';
export type CarBodyMeasurementStatus = 'required' | 'notRequired' | 'carriedOut';
export type HighVoltageSystemCheckStatus = 'required' | 'notRequired' | 'carriedOut';
/**
 * "estimate" means "Schätzung" in German. This is different from the GT Motive calculation which GT Motive calls "estimate", too.
 */
export type CalculationProvider =
    | 'dat'
    | 'audatex'
    | 'audatex-textimport'
    | 'gtmotive'
    | 'estimate'
    | 'manual'
    | 'other';

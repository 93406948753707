import { DateTime, Duration } from 'luxon';
import { Car } from '../../../models/reports/car-identification/car';
import { makeLuxonDateTime } from '../../ax-luxon';
import { todayIso } from '../../date/iso-date';

/**
 * Determine the number of downtime compensation classes a vehicle must be downgraded due to its age.
 *
 * The rule is:
 * > 5 years old = reduce by one class
 * > 10 years old = reduce by two classes
 *
 * We do that in accordance with
 * https://www.da-direkt.de/autoversicherung/ratgeber/nutzungsausfallentschaedigung-pkw
 * https://www.allianzdirect.de/kfz-versicherung/nutzungsausfallentschaedigung-ratgeber/
 * https://www.adac.de/rund-ums-fahrzeug/unfall-schaden-panne/unfall/nutzungsausfallentschaedigung/
 */
export function determineNumberOfDowntimeCompensationClassReductionsAccordingToAge({
    firstRegistration,
    comparisonDate,
}: {
    firstRegistration: Car['firstRegistration'];
    comparisonDate?: string;
}): NumberOfDowntimeCompensationClassReductions {
    if (!comparisonDate) {
        comparisonDate = todayIso();
    }

    const vehicleAge: Duration = DateTime.fromISO(comparisonDate).diff(makeLuxonDateTime(firstRegistration), ['years']);
    const vehicleAgeInYears: number = vehicleAge.toObject().years;

    let numberOfClassReductions: 0 | 1 | 2 = 0;
    if (vehicleAgeInYears >= 10) {
        numberOfClassReductions = 2;
    } else if (vehicleAgeInYears >= 5) {
        numberOfClassReductions = 1;
    }

    return numberOfClassReductions;
}

export type NumberOfDowntimeCompensationClassReductions = 0 | 1 | 2;

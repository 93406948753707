import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service to register special keyboard events to pimp mouse clicks.
 *
 * Example: With this service, we can execute a certain function when
 * the user holds E on his keyboard and clicks a certain element.
 */
@Injectable({
    providedIn: 'root',
})
export class KeyboardService {
    private heldKeys = new Set<string>();

    constructor() {
        document.addEventListener('keydown', this.onKeydown.bind(this));
        document.addEventListener('keyup', this.onKeyup.bind(this));
    }

    private onKeydown(event: KeyboardEvent): void {
        this.heldKeys.add(event.key);
    }

    private onKeyup(event: KeyboardEvent): void {
        this.heldKeys.delete(event.key);
    }

    public isKeyDown(key: string): boolean {
        return this.heldKeys.has(key);
    }
}

<div id="video-player-dialog-container">
    <h2 matDialogTitle>{{ data.heading }}</h2>
    <mat-icon class="dialog-close-icon" mat-dialog-close>close</mat-icon>
    <mat-dialog-content>
        <iframe
            *ngIf="data.videoUrl?.includes('youtube')"
            width="896"
            height="504"
            [src]="trustedVideoUrl"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

        <iframe
            *ngIf="data.videoUrl?.includes('vimeo')"
            [src]="trustedVideoUrl"
            width="896"
            height="456"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen></iframe>
    </mat-dialog-content>
</div>

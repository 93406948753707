import { generateId } from '@autoixpert/lib/generate-id';
import { changeReportType } from '@autoixpert/lib/reports/change-report-type';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { DocumentOrderConfig } from '../../../models/documents/document-order-config';
import { mergeUserPreferencesIntoReport } from './merge-user-preferences-into-report';

/**
 * Create empty report (with given data)
 *
 * This function creates a new report.
 * Given data is inserted into the new report.
 * All report-type specific fields and preferences for the new report are applied.
 *
 * All preferences are taken from the creator.
 * A different user may be the responsible assessor. If no responsible assessor is provided, the creator is used as responsible assessor.
 */
export function createEmptyReport({
    reportData,
    creator,
    overrideCreatedBy = creator._id,
    responsibleAssessor = creator,
    team,
    fieldGroupConfigs,
    customDocumentOrderConfigs = [],
}: {
    reportData: Partial<Report>;
    creator: User;
    // If the report is created by the api, createdBy is the api user, but the report data is created using the data from the responsible assessor.
    overrideCreatedBy?: User['_id'];
    team: Team;
    responsibleAssessor?: User;
    fieldGroupConfigs: FieldGroupConfig[];
    customDocumentOrderConfigs?: DocumentOrderConfig[];
}): Report {
    // Initialize the new report, but without the type.
    const { type: reportType, ...reportDataWithoutType } = reportData;
    const newReport = new Report(reportDataWithoutType);

    /**
     *  Responsible Assessor
     * If no responsible assessor is provided, the creator is used as responsible assessor.
     */
    newReport.responsibleAssessor = responsibleAssessor._id;

    /**
     * Add the report type specific roles and documents
     *
     * This function expects the report type not to be set yet.
     * It sets the report type, removes additional fields and adds the report type specific fields.
     */
    changeReportType({
        report: newReport,
        targetReportType: reportType,
        user: creator,
        team,
        fieldGroupConfigs,
        documentOrderConfigs: customDocumentOrderConfigs,
    });

    // Use the preferences from the responsible assessor for the new report.
    mergeUserPreferencesIntoReport({ report: newReport, team, creator, responsibleAssessor });

    // Generate _id for new report.
    newReport._id = generateId();

    // Use the id from the ApiUser as the createdBy field.
    newReport.createdBy = overrideCreatedBy ?? creator._id;
    newReport.teamId = team._id;

    return newReport;
}

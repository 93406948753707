<div class="association-panel-container card">
    <div class="association-header-container">
        <h2 id="association-heading">Deine Netzwerke</h2>
        <p>Wo bist du Mitglied? Damit hilfst du uns, unsere Feature-Entwicklung auszurichten.</p>
    </div>
    <div class="association-container">
        <div class="association-row">
            <h3>Verbände</h3>
            <div class="association-grid">
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'bvsk', type: 'association' })"
                    [ngClass]="{ active: associationIsAssigned('bvsk', 'association') }">
                    <img class="organization-logo" src="/assets/images/logos/bvsk-logo.png" alt="BVSK" />
                </div>
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'vks', type: 'association' })"
                    [ngClass]="{ active: associationIsAssigned('vks', 'association') }">
                    <img id="vks-logo" class="organization-logo" src="/assets/images/logos/vks-logo.png" alt="VKS" />
                </div>
                <div
                    class="organization-container"
                    (click)="showAssociationChipListInput()"
                    [ngClass]="{ active: showAssociationChipList }">
                    Sonstige
                </div>
            </div>
            <mat-form-field *ngIf="showAssociationChipList || otherAssociations.length">
                <mat-chip-list #associationChipList>
                    <mat-chip *ngFor="let association of otherAssociations" (removed)="removeAssociation(association)">
                        {{ association.organization }}
                        <mat-icon matChipRemove>close</mat-icon>
                    </mat-chip>
                    <input
                        placeholder="Sonstige Verbände"
                        #associationInput
                        [matChipInputFor]="associationChipList"
                        (matChipInputTokenEnd)="
                            toggleAssociation({ organization: associationInput.value, type: 'customAssociation' });
                            clearInput(associationInput)
                        "
                        matChipInputAddOnBlur="true" />
                </mat-chip-list>
            </mat-form-field>
        </div>
        <div class="network-row">
            <h3>Netzwerke</h3>
            <div class="network-grid">
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'gtü', type: 'networks' })"
                    [ngClass]="{ active: associationIsAssigned('gtü', 'networks') }">
                    <img
                        id="gtue-logo"
                        class="organization-logo"
                        src="/assets/images/logos/gtue-logo_50.png"
                        alt="GTÜ" />
                </div>
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'dat-expert-partner', type: 'networks' })"
                    [ngClass]="{ active: associationIsAssigned('dat-expert-partner', 'networks') }">
                    <img
                        id="dat-expert-partner-logo"
                        class="organization-logo"
                        src="/assets/images/logos/dat-expert-partner.png"
                        alt="DAT Expert Partner" />
                </div>
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'classic-data', type: 'networks' })"
                    [ngClass]="{ active: associationIsAssigned('classic-data', 'networks') }">
                    <img
                        id="classic-data-logo"
                        class="organization-logo"
                        src="/assets/images/logos/classic-data.png"
                        alt="Classic Data" />
                </div>
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'tüv-nord', type: 'networks' })"
                    [ngClass]="{ active: associationIsAssigned('tüv-nord', 'networks') }">
                    <img
                        id="tüv-nord-logo"
                        class="organization-logo"
                        src="/assets/images/logos/tuev-nord-logo.svg"
                        alt="TÜV Nord" />
                </div>
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'tüv-rheinland', type: 'networks' })"
                    [ngClass]="{ active: associationIsAssigned('tüv-rheinland', 'networks') }">
                    <img
                        id="tüv-rheinland-logo"
                        class="organization-logo"
                        src="/assets/images/logos/tuev-rheinland.svg"
                        alt="TÜV Rheinland" />
                </div>
                <div
                    class="organization-container"
                    (click)="showNetworkChipListInput()"
                    [ngClass]="{ active: showNetworkChipList }">
                    Sonstige
                </div>
            </div>
            <mat-form-field *ngIf="showNetworkChipList || otherNetworks.length">
                <mat-chip-list #networkChipList>
                    <mat-chip *ngFor="let network of otherNetworks" (removed)="removeAssociation(network)">
                        {{ network.organization }}
                        <mat-icon matChipRemove>close</mat-icon>
                    </mat-chip>
                    <input
                        placeholder="Sonstige Netzwerke"
                        #networkInput
                        [matChipInputFor]="networkChipList"
                        (matChipInputTokenEnd)="
                            toggleAssociation({ organization: networkInput.value, type: 'customNetwork' });
                            clearInput(networkInput)
                        "
                        matChipInputAddOnBlur="true" />
                </mat-chip-list>
            </mat-form-field>
        </div>
        <div class="certificate-row">
            <h3>Zertifizierungen</h3>
            <div class="certificate-grid">
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'ifs', type: 'certificates' })"
                    [ngClass]="{ active: associationIsAssigned('ifs', 'certificates') }">
                    <img id="ifs-logo" class="organization-logo" src="/assets/images/logos/ifs-logo.png" alt="IfS" />
                </div>
                <div
                    class="organization-container"
                    (click)="toggleAssociation({ organization: 'zak', type: 'certificates' })"
                    [ngClass]="{ active: associationIsAssigned('zak', 'certificates') }">
                    <img id="zak-logo" class="organization-logo" src="/assets/images/logos/zak-logo.png" alt="ZAK" />
                </div>
            </div>
        </div>
    </div>
    <div class="button-container">
        <button (click)="markDialogAsSubmitted(); saveTeam(); displayThankYouToast(); closeDialog()">Speichern</button>
        <button
            class="secondary"
            (click)="resetDialog(); markDialogAsSubmitted(); saveTeam(); displayThankYouToast(); closeDialog()"
            matTooltip="Wenn du Mitglied in keiner der Organisationen bist, klicke hier.">
            Keine
        </button>
    </div>
</div>

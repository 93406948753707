<!--============================================-->
<!-- Header -->
<!--============================================-->
<div class="workday-header">
    <div class="semibold">{{ workdaySettings.weekday | weekdayGerman: true }}</div>
    <mat-slide-toggle
        [(ngModel)]="workdaySettings.active"
        (change)="emitChange()"
        matTooltip="Ist dieser Tag ein Arbeitstag?"></mat-slide-toggle>
</div>
<!--============================================-->
<!-- END Header -->
<!--============================================-->

<!--============================================-->
<!-- Work Hours -->
<!--============================================-->
<div class="working-hours-container">
    <div
        class="working-hours-selector"
        *ngIf="workdaySettings.active"
        workingHoursSelector
        (dblclick)="showInstructionToast()"
        [(workingMinutes)]="workdaySettings.workingMinutes"
        (workingHoursResizeEnd)="emitChange()"
        @fadeInAndSlide>
        <span (click)="showInstructionToast()">{{ workdaySettings.workingMinutes / 60 | number: '1.0-1' }} h</span>
        <mat-icon class="working-hours-drag-indicator">keyboard_arrow_down</mat-icon>
    </div>
</div>
<!--============================================-->
<!-- END Work Hours -->
<!--============================================-->

<div id="dialog-wrapper">
    <form id="password-fields-container">
        <!-- Headline container -->
        <div class="password-dialog-headline-container">
            <!-- Headline -->
            <h2>Neues Passwort</h2>

            <!-- Subline -->
            <div *ngIf="isUpdateForOtherUser()" [matTooltip]="editedUser.email">für {{ editedUser.email }}</div>
        </div>

        <!-- Current password info text -->
        <div *ngIf="isUpdateForOtherUser()" class="current-password-info-text">
            Du bist Admin. Bitte gib
            <b>dein</b>
            Passwort ein.
        </div>

        <!-- Change password form -->
        <mat-form-field id="previous-password-container" class="password-field-container">
            <input
                matInput
                [type]="previousPasswordShown ? 'text' : 'password'"
                [placeholder]="isUpdateForOtherUser() ? 'Dein Passwort (Admin)' : 'Bisheriges Passwort'"
                [(ngModel)]="previousPassword"
                (keydown)="triggerFormOnEnterKey($event)"
                name="previous-password" />
            <mat-icon class="password-show-toggle small-icon" (click)="togglePreviousPassword()">
                remove_red_eye
            </mat-icon>
        </mat-form-field>

        <!-- Insert a hidden username field so that the browser asks the user about updating the password for the right account. -->
        <div class="hidden-username-container">
            <input
                type="email"
                [ngModel]="loggedInUser.email"
                name="username"
                placeholder="Benutzername"
                tabindex="-1" />
        </div>

        <!-- New password info text -->
        <div *ngIf="isUpdateForOtherUser()" class="new-password-info-text">
            Welches Passwort soll der Nutzer
            <b>{{ editedUser.email }}</b>
            bekommen?
        </div>

        <!-- New password -->
        <mat-form-field id="new-password-container" class="password-field-container">
            <input
                matInput
                [type]="newPasswordShown ? 'text' : 'password'"
                placeholder="Neues Passwort"
                [(ngModel)]="newPassword"
                (keydown)="triggerFormOnEnterKey($event)"
                name="new-password" />
            <mat-icon class="password-show-toggle small-icon" (click)="toggleNewPassword()">remove_red_eye</mat-icon>
        </mat-form-field>

        <!-- Warning messages -->
        <div id="warning-container" class="alert alert-danger">{{ warning }}</div>
    </form>

    <!-- Save button -->
    <div id="buttons-container">
        <button
            id="save-button"
            (click)="saveNewPassword()"
            [ngClass]="{ disabled: getSaveButtonTooltip() }"
            [matTooltip]="getSaveButtonTooltip()">
            <img
                id="save-button-loader"
                src="/assets/images/icons/loading-indicator-white.svg"
                alt=""
                *ngIf="loading" />
            Speichern
        </button>
    </div>

    <!-- Close button -->
    <mat-icon id="dialog-close-icon" (click)="closeDialog()">close</mat-icon>
</div>

/**
 * RegExp.prototype.toString() outputs slashes around the regular expression. If single regular expression
 * elements should be combined, however, those slashes are undesirable.
 *
 * Example:
 *   /\d{2}/.toString() === '/\d{2}/'
 *   regularExpressionElementToString(/\d{2}/) === '\d{2}'
 */
export function regularExpressionElementToString(input: RegExp): string {
    return input.toString().replace(/^\/(.+)\/$/, '$1');
}

<ng-container *ngIf="!!report.car.paintThicknessMeasurements">
    <paint-thickness-scale-selector
        (openEditScaleDialogClicked)="paintThicknessEditScalesDialogShown = true"
        [paintThicknessScale]="selectedPaintThicknessScale"
        [disabled]="isReportLocked(report)"
        [userIsAllowedToEditScales]="hasAccessRight(user, 'editTextsAndDocumentBuildingBlocks')"
        [availablePaintThicknessScales]="team.preferences.paintThicknessMeasurementScales"
        (scaleWasSelected)="updateSelectedPaintThicknessScale($event)"></paint-thickness-scale-selector>

    <div class="car-contour-vertical-alignment-wrapper">
        <div class="car-contour-container {{ report.car.shape }}">
            <!-------------- Real Tires, no pseudo elements -->
            <div class="tire-positioning-anchor" *ngIf="!isAxleConfiguratorRequired(report.car)">
                <div *ngFor="let tire of tires" class="tire axle-{{ tire.axle }} position-{{ tire.position }}">
                    <img src="/assets/images/icons/{{ getCarTireImageName(report.car.shape) }}" class="tire-image" />
                </div>
            </div>

            <!-------------- Drawing of the car -->
            <img
                class="car-contour"
                [src]="'/assets/images/car-contours/' + getCarContourImageName(report.car.shape) + '.png'" />

            <div id="paint-thickness-input-positioning-anchor" [class]="report.car.shape || 'sedan'">
                <paint-thickness-input
                    id="paint-thickness-{{ paintThicknessPosition }}"
                    *ngFor="let paintThicknessPosition of paintThicknessPositions"
                    [paintThicknessPosition]="paintThicknessPosition"
                    [paintThicknessScale]="selectedPaintThicknessScale"
                    [disabled]="isReportLocked(report)"
                    [paintThicknessMeasurement]="getPaintThicknessMeasurementByPosition(paintThicknessPosition)"
                    (valueChange)="saveReport()"
                    (updateManualTypeForAll)="updateManualTypeForAll($event)"></paint-thickness-input>
            </div>
        </div>
    </div>

    <ng-container *ngIf="customPaintThicknessPositions()?.length > 0">
        <div class="custom-paint-thickness-measurement-list">
            <div class="custom-paint-thickness-measurement-item" *ngFor="let item of customPaintThicknessPositions()">
                <mat-form-field class="custom-paint-thickness-measurement-item-title">
                    <input
                        #customPositionInput
                        matInput
                        placeholder="Bauteil"
                        [(ngModel)]="item.title"
                        (change)="saveReport()"
                        [disabled]="isReportLocked(report)" />
                </mat-form-field>

                <!--********** Value **********-->
                <paint-thickness-input
                    [paintThicknessScale]="selectedPaintThicknessScale"
                    [paintThicknessMeasurement]="item"
                    [hideLabel]="true"
                    [disabled]="isReportLocked(report)"
                    [commentIconPosition]="'bottom'"
                    (valueChange)="saveReport()"
                    (updateManualTypeForAll)="updateManualTypeForAll($event)"></paint-thickness-input>

                <mat-icon
                    *ngIf="!isReportLocked(report)"
                    class="custom-paint-thickness-measurement-item-delete-icon toolbar-icon"
                    (click)="deleteCustomPaintThicknessMeasurement(item)"
                    matTooltip="Löschen">
                    delete
                </mat-icon>
            </div>
        </div>
    </ng-container>

    <div class="paint-thickness-button-container">
        <button
            *ngIf="!isReportLocked(report)"
            class="paint-thickness-custom-position-button flat"
            (click)="addCustomPaintThicknessMeasurement()"
            [class.disabled]="isReportLocked(report)"
            tabindex="0"
            matTooltip="Weitere eigene Positionen hinzufügen">
            <mat-icon class="small-icon outlined">add_circle</mat-icon>
            Position hinzufügen
        </button>

        <button
            *ngIf="
                customPaintThicknessPositions().length > 0 ||
                !!user.preferences.paintThicknessCustomPositions?.[report.car.shape]
            "
            class="paint-thickness-custom-position-button flat"
            (click)="rememberCustomPaintThicknessMeasurements()"
            matTooltip="Eigene Positionen für diesen Fahrzeugtyp merken und beim nächsten Gutachten automatisch einfügen lassen.">
            <mat-icon class="small-icon outlined">bookmark</mat-icon>
            Merken
            <span class="button-overlay" *ngIf="customPaintThicknessPositionsUpdatedIconShown" @fadeInAndOut>
                <mat-icon class="button-action-done-icon">check</mat-icon>
            </span>
        </button>
    </div>

    <ng-container *ngIf="paintThicknessCommentShown">
        <mat-form-field class="notes mat-block margin-top-10">
            <mat-quill
                [(ngModel)]="report.car.paintThicknessMeasurementComment"
                (change)="saveReport()"
                placeholder="Kommentar Lackschichtdicke"
                [disabled]="isReportLocked(report)"></mat-quill>
            <mat-icon
                matSuffix
                *ngIf="!isReportLocked(report)"
                class="input-toolbar-icon"
                (click)="showPaintThicknessCommentTextTemplates = true"
                matTooltip="Textvorlagen">
                playlist_add
            </mat-icon>
        </mat-form-field>
        <text-template-selector
            [(text)]="report.car.paintThicknessMeasurementComment"
            (textChange)="saveReport()"
            [report]="report"
            *ngIf="showPaintThicknessCommentTextTemplates"
            placeholderForResultingText="Allgemeiner Kommentar zur Lackschichtdickemessung"
            (close)="showPaintThicknessCommentTextTemplates = false"
            [textTemplateType]="'paintThicknessComment'"
            @runChildAnimations></text-template-selector>
    </ng-container>

    <!--============================================-->
    <!-- Custom Fields -->
    <!--============================================-->
    <custom-field-display
        fieldLocation="car-condition_paint-thickness-sketch"
        [report]="report"
        (reportChange)="saveReport()"
        [disabled]="isReportLocked(report)"></custom-field-display>
    <!--============================================-->
    <!-- END Custom Fields -->
    <!--============================================-->

    <paint-thickness-scale-edit-dialog
        *ngIf="paintThicknessEditScalesDialogShown"
        [(selectedPaintThicknessScaleId)]="report.car.paintThicknessSelectedScaleId"
        (scaleDataChanged)="handleScaleChange(); saveTeam()"
        (selectedPaintThicknessScaleIdChange)="loadSelectedPaintThicknessScale(); saveReport()"
        (close)="paintThicknessEditScalesDialogShown = false"
        [disabled]="isReportLocked(report)"
        @runChildAnimations></paint-thickness-scale-edit-dialog>
</ng-container>

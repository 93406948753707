import { OfficialFeeTable } from '@autoixpert/models/reports/assessors-fee/fee-calculation';

// Array shape: [damageValue, net fee lower, net fee upper]
export const cgfFeeTable2023: OfficialFeeTable = {
    year: 2023,
    table: [
        [2_500, 641, 833],
        [5_000, 891, 1_157],
        [10_000, 1_327, 1_724],
        [15_000, 1_776, 2_307],
        [20_000, 2_302, 2_990],
        [25_000, 2_664, 3_460],
        [30_000, 3_105, 4_032],
        [35_000, 3_522, 4_574],
        [40_000, 3_863, 5_018],
        [45_000, 4_276, 5_553],
        [50_000, 4_663, 6_056],
        [60_000, 5_571, 7_236],
        [70_000, 6_338, 8_232],
        [80_000, 6_801, 8_834],
        [90_000, 7_499, 9_741],
        [100_000, 7_994, 10_383],
        [120_000, 8_662, 11_251],
        [140_000, 9_190, 11_936],
        [160_000, 9_733, 12_641],
        [180_000, 10_076, 13_087],
        [200_000, 10_605, 13_774],
        [220_000, 10_971, 14_249],
        [260_000, 11_495, 14_930],
        [300_000, 12_239, 15_896],
        [340_000, 12_690, 16_482],
        [380_000, 13_153, 17_083],
        [420_000, 13_552, 17_602],
        [460_000, 13_947, 18_115],
        [500_000, 14_486, 18_815],
    ],
};

<div class="paint-thickness-scale" [class.editing-allowed]="allowEditing">
    <mat-form-field id="scale-dropdown" class="mat-block" *ngIf="!hideScaleDropdown">
        <mat-select
            #scaleDropdown
            [disabled]="disabled"
            [ngModel]="paintThicknessScale._id"
            (selectionChange)="configSelected($event)">
            <mat-select-trigger>
                <div class="text-overflow-ellipsis">{{ paintThicknessScale.name || 'Kein Name' }}</div>
            </mat-select-trigger>
            <mat-option *ngFor="let config of availablePaintThicknessScales; trackBy: trackById" [value]="config._id">
                {{ config.name || 'Kein Name' }}
            </mat-option>

            <!--********** Edit scales **********-->
            <div
                class="mat-option"
                (click)="scaleDropdown.close(); editPaintThicknessScales()"
                [matTooltip]="
                    !userIsAllowedToEditScales ? getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks') : ''
                "
                [class.disabled]="!userIsAllowedToEditScales">
                <div class="label text-align-center flex-grow">Skalen bearbeiten</div>
            </div>
        </mat-select>
    </mat-form-field>

    <div class="scale">
        <div
            *ngFor="let range of paintThicknessScale?.rangeDefinitions; let index = index"
            [matTooltip]="PaintThicknessLabelMap[range.type]"
            class="range-item">
            <div class="range-color-indicator" [style.background-color]="range.color"></div>
            <div class="range-label-container">
                <span class="range-label">{{ range | paintThicknessRangeLabel: index : allowEditing }}</span>
                <mat-form-field class="range-label-input" *ngIf="allowEditing">
                    <input
                        matInput
                        type="number"
                        [value]="range | paintThicknessRangeValue: index"
                        (change)="userChangedRangeValue($event.target, index)" />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getAccessRightsErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        ADMIN_PRIVILEGES_REQUIRED: () => ({
            title: 'Admin-Rechte erforderlich',
            body: `Bitte wende dich an deinen Account-Inhaber.`,
        }),
        /**
         * If the user is not authenticated, don't show an error by default. The jwt-verification.interceptor.ts handles this
         * by logging the user out and sending him to the login screen.
         */
        AX_NOT_AUTHENTICATED: () => ({
            title: '',
            body: ``,
        }),
    };
}

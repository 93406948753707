import { OverlayRef } from '@angular/cdk/overlay';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { removeFromArray } from '@autoixpert/lib/arrays/remove-from-array';
import { sortByProperty } from '@autoixpert/lib/arrays/sort-by-property';
import { getLabelByLabelConfig } from '@autoixpert/lib/labels/get-label-by-label-config';
import { Label } from '@autoixpert/models/labels/label';
import { LabelConfig, LabelGroup } from '@autoixpert/models/labels/label-config';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { fadeInAndOutAnimation } from '../../animations/fade-in-and-out.animation';
import { fadeInAndSlideAnimation } from '../../animations/fade-in-and-slide.animation';
import { ApiErrorService } from '../../services/api-error.service';
import { LabelConfigService } from '../../services/label-config.service';
import { LoggedInUserService } from '../../services/logged-in-user.service';

@Component({
    selector: 'label-dropdown-overlay',
    templateUrl: './label-dropdown-overlay.component.html',
    styleUrls: ['./label-dropdown-overlay.component.scss'],
    animations: [
        fadeInAndSlideAnimation({
            duration: 150,
            slideDistance: 10,
        }),
        fadeInAndOutAnimation(),
    ],
    host: {
        '[class.card]': 'true',
    },
})
export class LabelDropdownOverlayComponent implements OnInit {
    constructor(
        private loggedInUserService: LoggedInUserService,
        private labelConfigService: LabelConfigService,
        private apiErrorService: ApiErrorService,
        private overlayRef: OverlayRef,
    ) {}

    @Input() selectedLabels: Label[];
    @Input() labelGroup: LabelGroup;
    @Output() selectedLabelsChange: EventEmitter<Label[]> = new EventEmitter();

    protected user: User;
    protected team: Team;

    protected labelConfigs: LabelConfig[] = [];
    /**
     * LabelConfigs for all labels that don't have a central label any more. Not filterable any more.
     * Usually happens if the user deletes an existing label in the management dialog but it's still in use.
     */
    protected labelConfigStubsForOrphanedLabels: LabelConfig[] = [];

    //*****************************************************************************
    //  Init
    //****************************************************************************/
    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        await this.loadLabelConfigs();
    }

    protected async loadLabelConfigs() {
        try {
            this.labelConfigs = await this.labelConfigService.find({ labelGroup: this.labelGroup }).toPromise();
        } catch (error) {
            this.apiErrorService.handleAndRethrow({
                axError: error,
                handlers: {},
                defaultHandler: {
                    title: `Label-Konfigs konnten nicht geladen werden`,
                    body: `Bitte versuche es erneut oder kontaktiere die <a href='/Hilfe'>Hotline</a>.`,
                },
            });
        }
        this.labelConfigs.sort(sortByProperty(['dragOrderPosition', 'name']));
        this.setupLocalLabelConfigStubs();
    }

    protected setupLocalLabelConfigStubs() {
        const labelsWithoutMatchingConfig: Label[] = this.selectedLabels.filter(
            (selectedLabel) =>
                !this.labelConfigs.find((labelConfig) => selectedLabel.labelConfigId === labelConfig._id),
        );
        this.labelConfigStubsForOrphanedLabels = labelsWithoutMatchingConfig.map(
            (label) => new LabelConfig({ labelGroup: this.labelGroup, color: label.color, name: label.name }),
        );
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Init
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Selection
    //****************************************************************************/
    protected isLabelSelected(labelConfig: LabelConfig) {
        return !!getLabelByLabelConfig({ labels: this.selectedLabels, labelConfig });
    }

    protected toggleLabel(labelConfig: LabelConfig) {
        const label: Label = getLabelByLabelConfig({ labels: this.selectedLabels, labelConfig });

        if (label) {
            removeFromArray(label, this.selectedLabels);
        } else {
            this.selectedLabels.push(
                new Label({ labelConfigId: labelConfig._id, name: labelConfig.name, color: labelConfig.color }),
            );
        }

        this.selectedLabelsChange.emit(this.selectedLabels);
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Selection
    /////////////////////////////////////////////////////////////////////////////*/

    public closeOverlay() {
        this.overlayRef.detach();
    }

    public repositionOverlay() {
        // Timeout is necessary so that the parent component may render the changes before the overlay repositions itself.
        window.setTimeout(() => this.overlayRef.updatePosition(), 0);
    }

    //*****************************************************************************
    //  Events
    //****************************************************************************/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Events
    /////////////////////////////////////////////////////////////////////////////*/

    @HostBinding('@fadeInAndSlide')
    public fadeInAndSlideAnimationActive = true;
}

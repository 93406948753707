import { insertAfterArrayElement } from '@autoixpert/lib/arrays/insert-after-array-element';
import { insertBeforeArrayElement } from '@autoixpert/lib/arrays/insert-before-array-element';
import { getMapFromRecords } from '@autoixpert/lib/server-sync/get-map-from-records';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { DocumentOrder } from '@autoixpert/models/documents/document-order';
import { DocumentOrderItem } from '@autoixpert/models/documents/document-order-item';
import { DefaultDocumentOrder } from '@autoixpert/models/teams/default-document-order/default-document-order';
import { DefaultDocumentOrderItem } from '@autoixpert/models/teams/default-document-order/default-document-order-item';
import { getDefaultDocumentOrderItemForDocument } from './get-default-document-order-item-for-document';

/**
 * Insert the given document at the correct index according to the given document order.
 */
export function insertDocumentOrderItem({
    newDocument,
    documents,
    documentOrder,
    defaultDocumentOrder,
    insertAfterFallback,
    includedInFullDocument,
}: {
    newDocument: DocumentMetadata;
    /**
     * The unordered list of documents that exist in this report/invoice.
     */
    documents: DocumentMetadata[];
    /**
     * The ordered list of references to each document. This exists in the report/invoice and once per recipient so that the lawyer may receive a different
     * order of documents than the claimant.
     */
    documentOrder: DocumentOrder;
    /**
     * This is a team configuration that defines how documents are arranged by default, so when they are inserted.
     */
    defaultDocumentOrder: DefaultDocumentOrder;
    /**
     * If the document type does not exist in the default document order, insert the new document after this document type.
     */
    insertAfterFallback: DocumentMetadata['type'];
    /**
     * In case the team has not configured whether this document should be active or not in their default document order, this will be the fallback.
     */
    includedInFullDocument?: boolean;
}): void {
    const defaultDocumentOderItem: DefaultDocumentOrderItem = getDefaultDocumentOrderItemForDocument({
        document: newDocument,
        defaultDocumentOrder,
    });

    /**
     * If neither a default document order item defines if the document should be activated nor the component from
     * which this function is called, activate this document. It seems like a more sensible assumption to activate
     * too many than too few documents.
     */
    if (!defaultDocumentOderItem) {
        if (includedInFullDocument == null) {
            includedInFullDocument = true;
        }
    } else if (defaultDocumentOderItem.includedInFullDocument != null) {
        includedInFullDocument = !!defaultDocumentOderItem.includedInFullDocument;
    }
    const newDocumentOrderItem = new DocumentOrderItem({
        _id: newDocument._id,
        includedInFullDocument: !!includedInFullDocument,
    });

    /**
     * Since the user has never defined where he wants the given document type to go within the document order, just append it to the list.
     */
    if (!defaultDocumentOderItem) {
        // This often happens with new custom documents because they usually do not exist in the default document order.
        console.log(
            `The document of type "${newDocument.type}" does not exist in the given default document order. Append it to the document order.`,
        );
        documentOrder.items.push(newDocumentOrderItem);
        return;
    }

    const defaultDocumentOrderIndex = defaultDocumentOrder.items.indexOf(defaultDocumentOderItem);
    // Iterate over the documents in the documentOrder object before the current document.
    let defaultDocumentOrderIndexBefore = defaultDocumentOrderIndex - 1;
    let defaultDocumentOrderIndexAfter = defaultDocumentOrderIndex + 1;
    /**
     * Find the document that is closest to the document to be inserted.
     * 1. First, the documents before the document to be inserted will be looked at.
     * 2. If we cannot find a match, we use the documents after it.
     * 3. Still no match? Use the fallback reference document.
     */
    let closestExistingDocumentOrderItemAtLowerIndex: DocumentOrderItem;
    let closestExistingDocumentOrderItemAtHigherIndex: DocumentOrderItem;

    const documentMap: Map<string, DocumentMetadata> = getMapFromRecords(documents);
    //*****************************************************************************
    //  Anchor Found In Front
    //****************************************************************************/
    while (defaultDocumentOrderIndexBefore >= 0 && !closestExistingDocumentOrderItemAtLowerIndex) {
        // Match document type and, if manuallyUploadedPdf, match uploadedDocumentId
        closestExistingDocumentOrderItemAtLowerIndex = documentOrder.items.find((existingDocumentOrderItem) => {
            const defaultDocumentOrderItem: DefaultDocumentOrderItem =
                defaultDocumentOrder.items[defaultDocumentOrderIndexBefore];
            const existingDocument: DocumentMetadata = documentMap.get(existingDocumentOrderItem._id);

            if (existingDocument.type === 'manuallyUploadedPdf') {
                return existingDocument.uploadedDocumentId === defaultDocumentOrderItem.uploadedDocumentId;
            } else if (existingDocument.type === 'customDocument') {
                return (
                    existingDocument.customDocumentOrderConfigId ===
                    defaultDocumentOrderItem.customDocumentOrderConfigId
                );
            }
            return existingDocument.type === defaultDocumentOrderItem.documentType;
        });
        defaultDocumentOrderIndexBefore--;
    }
    // If there is a close existing document, insert the document to be inserted after it.
    if (closestExistingDocumentOrderItemAtLowerIndex) {
        insertAfterArrayElement({
            array: documentOrder.items,
            newElement: newDocumentOrderItem,
            referenceElement: closestExistingDocumentOrderItemAtLowerIndex,
        });
        return;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Found In Front
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Anchor Found Behind
    //****************************************************************************/
    while (
        defaultDocumentOrderIndexAfter < defaultDocumentOrder.items.length &&
        !closestExistingDocumentOrderItemAtHigherIndex
    ) {
        // Match document type and, if manuallyUploadedPdf, match uploadedDocumentId
        closestExistingDocumentOrderItemAtHigherIndex = documentOrder.items.find((existingDocumentOrderItem) => {
            const defaultDocumentOrderItem: DefaultDocumentOrderItem =
                defaultDocumentOrder.items[defaultDocumentOrderIndexAfter];
            const existingDocument: DocumentMetadata = documentMap.get(existingDocumentOrderItem._id);

            if (existingDocument.type === 'manuallyUploadedPdf') {
                return existingDocument.uploadedDocumentId === defaultDocumentOrderItem.uploadedDocumentId;
            } else if (existingDocument.type === 'customDocument') {
                return (
                    existingDocument.customDocumentOrderConfigId ===
                    defaultDocumentOrderItem.customDocumentOrderConfigId
                );
            }
            return existingDocument.type === defaultDocumentOrderItem.documentType;
        });
        defaultDocumentOrderIndexAfter++;
    }

    // If there is a close existing document, insert the document to be inserted before it.
    if (closestExistingDocumentOrderItemAtHigherIndex) {
        insertBeforeArrayElement({
            array: documentOrder.items,
            newElement: newDocumentOrderItem,
            referenceElement: closestExistingDocumentOrderItemAtHigherIndex,
        });
        return;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor Found Behind
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Anchor is Fallback Document
    //****************************************************************************/
    // Fallback solution: Insert after a given other document. That document should ideally always exist, such as 'report'.
    const fallbackDocumentOrderItem: DocumentOrderItem = documentOrder.items.find((existingDocumentOrderItem) => {
        const existingDocument: DocumentMetadata = documentMap.get(existingDocumentOrderItem._id);

        return existingDocument.type === insertAfterFallback;
    });
    if (fallbackDocumentOrderItem) {
        insertAfterArrayElement({
            array: documentOrder.items,
            newElement: newDocumentOrderItem,
            referenceElement: fallbackDocumentOrderItem,
        });
        return;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Anchor is Fallback Document
    /////////////////////////////////////////////////////////////////////////////*/

    // If no document was found before this one, insert it at the end.
    documentOrder.items.push(newDocumentOrderItem);
}

<div id="analytics-list-container">
    <!--============================================-->
    <!-- Main Analytics Reports -->
    <!--============================================-->
    <!--********** Revenue **********-->
    <div
        class="analytics-report-container card card-with-padding"
        (click)="
            navigateTo('./Umsatz-pro-Monat');
            tutorialStateService.markUserTutorialStepComplete('revenueAnalyticsOpened')
        "
        [matTooltip]="
            'Wie viel Umsatz haben wir in welchem Monat gemacht?\n\nWelcher Mitarbeiter hat wie viel Umsatz erwirtschaftet?'
        ">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">equalizer</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Umsatz</h2>
        </div>
    </div>

    <!--    &lt;!&ndash;********** Numer of Reports **********&ndash;&gt;-->
    <!--    <div class="analytics-report-container card card-with-padding" (click)="navigateTo('./Honorar-pro-Mitarbeiter')" matTooltip="In welchen Monaten bearbeite ich mehr, in welchen weniger Gutachten?">-->
    <!--        <div class="analytics-report-icon-container">-->
    <!--            <mat-icon class="analytics-report-icon">assignment</mat-icon>-->
    <!--        </div>-->
    <!--        <div class="analytics-report-heading-container">-->
    <!--            <h2 class="analytics-report-heading">Anzahl Gutachten</h2>-->
    <!--        </div>-->
    <!--    </div>-->

    <!--********** Assessors Fee **********-->
    <div
        class="analytics-report-container card card-with-padding"
        (click)="navigateTo('./Honorar-pro-Sachverständigem')"
        matTooltip="Welcher Mitarbeiter hat wie viel Honorar erwirtschaftet?">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">euro_symbol</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Honorar</h2>
        </div>
    </div>

    <!--********** Short Payments **********-->
    <div
        class="analytics-report-container card card-with-padding"
        (click)="navigateTo('./Kürzungsquoten')"
        matTooltip="Wie viel haben Versicherungen gekürzt? Welche Versicherung kürzt am meisten?">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">content_cut</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Kürzungen</h2>
        </div>
    </div>

    <!--============================================-->
    <!-- END Main Analytics Reports -->
    <!--============================================-->

    <!--============================================-->
    <!-- Horizontal divider -->
    <!--============================================-->
    <div class="main-from-secondary-reports-divider"></div>
    <!--============================================-->
    <!-- END Horizontal divider -->
    <!--============================================-->

    <!--============================================-->
    <!-- Smaller Reports -->
    <!--============================================-->

    <!--********** Report Type **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-nach-Gutachtentyp')">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">description</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Gutachtentyp</h2>
        </div>
    </div>

    <!--********** Intermediary **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-pro-Vermittler')">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">record_voice_over</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Vermittler</h2>
        </div>
    </div>

    <!--********** Customers **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-pro-Kunde')">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">people</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Kunden</h2>
        </div>
    </div>

    <!--********** Location **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-nach-Ort')"
        matTooltip="Aus welcher Stadt oder aus welchem PLZ-Gebiet kommen meine Kunden?">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">map_marker</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Herkunft Kunden</h2>
        </div>
    </div>

    <!--********** Car Manufacturer **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-pro-Fahrzeughersteller')">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">directions_car</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Hersteller</h2>
        </div>
    </div>

    <!--********** Garage **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-pro-Werkstatt')">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">build</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Werkstätten</h2>
        </div>
    </div>

    <!--********** Garage **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-pro-Anwalt')">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon icon-emphasized">gavel</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Anwälte</h2>
        </div>
    </div>

    <!--********** Insurances **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-pro-Versicherung')"
        matTooltip="Umsatz pro Versicherung">
        <div class="analytics-report-icon-container">
            <img class="analytics-report-icon" src="/assets/images/icons/insurance-gradient-blue.svg" />
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Versicherungen</h2>
        </div>
    </div>

    <!--********** Good to know **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Umsatz-pro-Rechnungsposition')">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">list</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">
                Rechnungs-
                <br />
                positionen
            </h2>
        </div>
    </div>
    <!--============================================-->
    <!-- END Smaller Reports -->
    <!--============================================-->

    <!--============================================-->
    <!-- Horizontal divider -->
    <!--============================================-->
    <div class="main-from-secondary-reports-divider"></div>
    <!--============================================-->
    <!-- END Horizontal divider -->
    <!--============================================-->

    <!--***********************************************
    ** Damage Totals
    ************************************************-->

    <!--********** BVSK **********-->
    <div
        class="analytics-report-container small-analytics-card card card-with-padding"
        (click)="navigateTo('./Schadenhöhe')"
        [matTooltip]="
            'BVSK-Mitglieder werden vom BVSK regelmäßig nach dieser Kennzahl gefragt, um Branchen-Statistiken zu erstellen.\n\nHinweis: Nur Schaden-Gutachten mit vollständiger Kalkulation werden berücksichtigt.'
        ">
        <div class="analytics-report-icon-container">
            <mat-icon class="analytics-report-icon">car_crash</mat-icon>
        </div>
        <div class="analytics-report-heading-container">
            <h2 class="analytics-report-heading">Schadenhöhe</h2>
        </div>
    </div>

    <!--***********************************************
    ** END Damage Totals
    ************************************************-->
</div>

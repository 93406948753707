export const salutations: string[] = ['Herr', 'Frau', 'Herr Dr.', 'Frau Dr.', 'Herr Prof.', 'Frau Prof.'];

export function getSalutations(filterTerm: string): string[] {
    // If the term is empty, reset the list.
    if (!filterTerm) {
        return [...salutations];
    }

    filterTerm = filterTerm.toLowerCase();

    // Show only the elements that contain the filterTearm
    return salutations.filter((salutation: string) => salutation.toLowerCase().includes(filterTerm));
}

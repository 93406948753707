import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import {
    AccessRightGerman,
    translateAccessRightToGerman,
} from '@autoixpert/lib/users/translate-access-right-to-german';
import { AccessRights } from '@autoixpert/models/user/user';

/**
 * Access rights bei invitations:
 * - Invitations sind teil der team-members list
 * -> access rights muss mit interface umgehen können, wo sowohl ein user als auch eine invitation hinterstehen
 * -> FutureAccessRights bei invitations, AccessRights bei user
 */

@Component({
    selector: 'access-rights',
    templateUrl: './access-rights.component.html',
    styleUrls: ['./access-rights.component.scss'],
})
export class AccessRightsComponent {
    @Input() isAdmin: boolean;
    @Input() accessRights: AccessRights;
    @Input() askYourAdminTooltip: string;
    @Input() accessRightsDisplayConfig: AccessRightsDisplayConfig;

    @Output() isAdminChange = new EventEmitter<MatSlideToggleChange>();
    @Output() accessRightsChange = new EventEmitter<
        MatSlideToggleChange & {
            accessRight: keyof AccessRights;
        }
    >();

    get config() {
        return this.accessRightsDisplayConfig;
    }

    public translateAccessRight(accessRight: keyof AccessRights): AccessRightGerman {
        return translateAccessRightToGerman(accessRight);
    }
}

interface AccessRightsDisplayConfig {
    administrator?: AccessRightDisplayConfig;
    seeAllReports?: AccessRightDisplayConfig;
    lockReports?: AccessRightDisplayConfig;
    invoiceList?: AccessRightDisplayConfig;
    seeAllInvoices?: AccessRightDisplayConfig;
    seeAllPayments?: AccessRightDisplayConfig;
    bankAccountSync?: AccessRightDisplayConfig;
    seeReportFees?: AccessRightDisplayConfig;
    reporting?: AccessRightDisplayConfig;
    editContacts?: AccessRightDisplayConfig;
    editTextsAndDocumentBuildingBlocks?: AccessRightDisplayConfig;
    seeSettings?: AccessRightDisplayConfig;
}

interface AccessRightDisplayConfig {
    hidden?: boolean;
    disabled?: boolean;
    tooltip?: string;
}

import { ContactPerson } from '@autoixpert/models/contacts/contact-person';

export function areContactPeopleEqual(contactPersonA: ContactPerson, contactPersonB: ContactPerson): boolean {
    // If either one is empty, they're not equal.
    if ((contactPersonA && !contactPersonB) || (!contactPersonA && contactPersonB)) return false;

    if (!contactPersonA && !contactPersonB) return true;

    // Use simple comparison (==) to treat undefined and null as equal
    const isBaseDataEqual: boolean =
        areValuesEmptyOrEqual(contactPersonA.organization, contactPersonB.organization) &&
        areValuesEmptyOrEqual(contactPersonA.zip, contactPersonB.zip) &&
        areValuesEmptyOrEqual(
            contactPersonA.streetAndHouseNumberOrLockbox,
            contactPersonB.streetAndHouseNumberOrLockbox,
        ) &&
        areValuesEmptyOrEqual(contactPersonA.firstName, contactPersonB.firstName) &&
        areValuesEmptyOrEqual(contactPersonA.lastName, contactPersonB.lastName);

    return isBaseDataEqual;
}

export function areContactPeopleDifferent(contactPersonA: ContactPerson, contactPersonB: ContactPerson): boolean {
    // Get all keys from both objects. The objects may not have all properties defined. If the comparison object has a null or "" value in that key, that should qualify as equal.
    const keysToCompare: (keyof ContactPerson)[] = [
        'salutation',
        'firstName',
        'lastName',
        'email',
        'phone',
        'phone2',
        'organization',
        'organizationType',
        'streetAndHouseNumberOrLockbox',
        'birthdate',
        'addressAddition',
        'zip',
        'city',
        'website',
        'garageFeeSets',
        'datCustomerNumber',
        'financiallyStable',
        'mayDeductTaxes',
        'vatId',
        'notes',
    ];

    let differencesExist: boolean;
    // Check for equality
    for (const key of keysToCompare) {
        const bothFalsy: boolean = !contactPersonA[key] && !contactPersonB[key];

        // Deep comparison of feeSet
        if (key === 'garageFeeSets' && !bothFalsy) {
            const feeSetA = JSON.stringify(contactPersonA.garageFeeSets);
            const feeSetB = JSON.stringify(contactPersonB.garageFeeSets);
            if (feeSetA !== feeSetB) {
                differencesExist = true;
                break;
            }
            // Don't continue with the regular comparison for this key 'feeSet'
            continue;
        }

        // Check for inequality. Both values falsy (e.g. undefined and "") count as equal.
        if (contactPersonA[key] != contactPersonB[key] && !bothFalsy) {
            differencesExist = true;
            break;
        }
    }

    return differencesExist;
}

/**
 * Are both values
 * - empty ("", null or undefined) or
 * - equal (strict equality "===")?
 *
 * Simple comparison (==) won't consider "" (empty string) and null equal.
 *
 * @param value1
 * @param value2
 */
export function areValuesEmptyOrEqual(value1: string, value2: string): boolean {
    if (!value1 && !value2) {
        return true;
    }
    if (typeof value1 === 'object' && typeof value2 === 'object') {
        return undefined;
    }
    if (typeof value1 === 'string' && typeof value2 === 'string') {
        return value1.trim() === value2.trim();
    }
    return value1 === value2;
}

import { Report } from '../../models/reports/report';

export function getReportCustomerName({ report, fallback }: { report: Report; fallback?: string }): string {
    const organization = report?.claimant?.contactPerson?.organization || '';
    const firstName = report?.claimant?.contactPerson?.firstName || '';
    const lastName = report?.claimant?.contactPerson?.lastName || '';

    if (report?.claimant?.contactPerson?.organization) {
        return organization;
    }

    if (firstName || lastName) {
        return `${firstName} ${lastName}`.trim();
    }

    return fallback || `Kein Name`;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

/**
 * Let the user view his past autoiXpert invoices.
 */
@Injectable()
export class InvoiceHistoryService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public find(teamId: string): Promise<string> {
        return this.httpClient
            .get<{ link: string }>(`${this.apiPath}/teams/${teamId}/invoiceHistoryLink`)
            .pipe(map((response) => response.link))
            .toPromise();
    }
}

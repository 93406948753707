import { ReportTabName } from '@autoixpert/models/realtime-editing/report-tab-name';
import { ChecklistItemName } from '@autoixpert/models/report-progress/report-progress-checklist-item-names';
import { ReportProgressConfig } from '@autoixpert/models/report-progress/report-progress-config';
import { ChecklistItem } from '@autoixpert/models/report-progress/report-progress-setting';
import { Report } from '@autoixpert/models/reports/report';
import { getChecklistItemNamesByTabName } from './get-checklist-item-names-by-tab-name';

export function getAvailableChecklistItemsByTabName({
    reportProgressConfig,
    reportType,
    reportTabName,
}: {
    reportProgressConfig: ReportProgressConfig;
    reportType: Report['type'];
    reportTabName: ReportTabName;
}): ChecklistItem[] {
    // Which items should be displayed in the current tab? Get their names.
    const namesOfItemsInTab: ChecklistItemName[] = getChecklistItemNamesByTabName(reportTabName, reportType);

    // Get actual items from the team's config.
    // That skips deactivated options. Items include flag isRequired.
    return reportProgressConfig[reportType].filter((item) => namesOfItemsInTab.includes(item.name));
}

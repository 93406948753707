import { OutgoingEmailMessage, OutgoingMessage } from '@autoixpert/models/outgoing-message';
import { CommunicationRecipientRole, InvolvedParty } from './involved-party';

export class CommunicationRecipient extends InvolvedParty {
    declare role: CommunicationRecipientRole;

    receivedEmail: boolean = false;
    receivedLetter: boolean = false;
    outgoingMessageDraft: OutgoingMessageDraftStructure = new OutgoingMessageDraftStructure();

    /**
     * Allows the recipient to associate the communication to their own case file.
     * Garage: Needed for lease returns.
     */
    caseNumber: string = null;
}

export class OutgoingMessageDraftStructure {
    report: OutgoingEmailMessage = new OutgoingEmailMessage();
    repairConfirmation: OutgoingEmailMessage = null;
    expertStatement?: OutgoingEmailMessage = null;
    remoteSignature?: OutgoingEmailMessage = null;
}

<!--********** Progress Bar **********-->
<ng-container *ngIf="credits">
    <div id="remaining-test-days-progress-bar" class="slim-progress-bar-background">
        <div
            class="slim-progress-bar-fill"
            [style.width]="(credits.creditsUsed / credits.creditsAvailable) * 100 + '%'"
            [ngClass]="{
                warning: credits.creditsUsed > credits.creditsAvailable * 0.8,
            }"></div>
    </div>

    <div id="progress-bar-label-container">
        <span class="progress-bar-label">
            Verbrauchte Credits: {{ credits.creditsUsed }} von {{ credits.creditsAvailable }}
        </span>
    </div>
</ng-container>

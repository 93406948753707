<mat-form-field
    class="mat-block"
    [ngClass]="{ 'light-mode': lightMode, 'ghost-until-hover': ghostUntilHover }"
    [floatLabel]="floatPlaceholder">
    <input
        #input
        matInput
        name="datum"
        [placeholder]="placeholder"
        [value]="_date"
        (dateChange)="emitDateChange($event)"
        (focus)="emitFocusEvent($event)"
        (blur)="emitBlurEvent()"
        [disabled]="disabled"
        [matDatepicker]="datepicker"
        autocomplete="off"
        [axAutofocus]="autofocus" />
    <mat-datepicker-toggle
        *ngIf="showDatepickerIcon"
        matSuffix
        [for]="datepicker"
        [disableRipple]="true"
        [tabIndex]="-1">
        <mat-icon matDatepickerToggleIcon class="datepicker-toggle-icon" matTooltip="Datum auswählen">today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datepicker [touchUi]="isTablet" [startView]="startView" [startAt]="startAt"></mat-datepicker>
    <mat-hint *ngIf="isDateInvalid" class="error">Format prüfen</mat-hint>
    <mat-hint *ngIf="warning" class="warning">{{ warning }}</mat-hint>
    <mat-hint *ngIf="error" class="error">{{ error }}</mat-hint>
</mat-form-field>

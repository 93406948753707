import { AccessRights } from '@autoixpert/models/user/user';

export function translateAccessRightToGerman(accessRight: keyof AccessRights): AccessRightGerman {
    switch (accessRight) {
        case 'seeAllReports':
            return 'Alle Gutachten';
        case 'lockReports':
            return 'Gutachten abschließen';
        case 'invoiceList':
            return 'Rechnungsliste';
        case 'seeAllInvoices':
            return 'Alle Rechnungen';
        case 'seeReportFees':
            return 'Gutachtenrechnung';
        case 'bankAccountSync':
            return 'Bankkontoabgleich';
        case 'reporting':
            return 'Auswertungen';
        case 'editContacts':
            return 'Kontakte bearbeiten';
        case 'editTextsAndDocumentBuildingBlocks':
            return 'Standards & Texte ändern';
        case 'seeSettings':
            return 'Einstellungen';
    }
}

export type AccessRightGerman =
    | 'Alle Gutachten'
    | 'Gutachten abschließen'
    | 'Rechnungsliste'
    | 'Alle Rechnungen'
    | 'Gutachtenrechnung'
    | 'Bankkontoabgleich'
    | 'Auswertungen'
    | 'Kontakte bearbeiten'
    | 'Standards & Texte ändern'
    | 'Einstellungen';

import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { isAdmin } from '@autoixpert/lib/users/is-admin';
import { SetupSteps } from '@autoixpert/models/teams/setup-steps';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import { getProductName } from '../../../shared/libraries/get-product-name';
import { LoggedInUserService } from '../../../shared/services/logged-in-user.service';
import { NewWindowService } from '../../../shared/services/new-window.service';
import { TeamService } from '../../../shared/services/team.service';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';

@Component({
    selector: 'setup-steps',
    templateUrl: './setup-steps.component.html',
    styleUrls: ['./setup-steps.component.scss'],
})
export class SetupStepsComponent implements OnInit {
    constructor(
        public userPreferences: UserPreferencesService,
        private teamService: TeamService,
        private loggedInUserService: LoggedInUserService,
        private router: Router,
        private newWindowService: NewWindowService,
        private dialog: MatDialog,
        private toastService: ToastService,
    ) {}

    public user: User;
    public team: Team;
    public productName = getProductName();

    async ngOnInit() {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();

        if (!this.team.userInterfaceStates.setupSteps) {
            this.team.userInterfaceStates.setupSteps = new SetupSteps();
            await this.teamService.put(this.team);
        }
    }

    public async resetSetupSteps() {
        this.team.userInterfaceStates.setupSteps = new SetupSteps();
        await this.teamService.put(this.team);
    }

    public async hideSetupSteps() {
        if (!isAdmin(this.user._id, this.team)) {
            this.toastService.warn(
                'Nur von Admins ausblendbar',
                'Bitte sprich mit dem Team-Admin, ob er die Einrichtung abgeschlossen hat und sie ausblenden möchte.',
            );
            return;
        }

        // Shorthand
        const setupSteps = this.team.userInterfaceStates.setupSteps;

        // If non-optional items are still left undone, make the user confirm.
        if (!setupSteps.headerAndFooterTemplate || !setupSteps.signatureAndStamp || !setupSteps.emailCredentials) {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Dauerhaft ausblenden?',
                        content: 'Über das Einstellungen-Modul kannst du alles auch später noch einrichten.',
                        confirmLabel: 'Ausblenden',
                        cancelLabel: 'Abbrechen',
                        confirmColorRed: false,
                    },
                })
                .afterClosed()
                .toPromise();
            if (!decision) return;
        }

        setupSteps.closed = true;
        await this.teamService.put(this.team);
    }

    public navigate(url: string) {
        const [baseRoute, fragment] = url.split('#');

        // We use urlParams to let the target site explicitly wait for Angular to insert
        this.router.navigate([baseRoute], { queryParams: { section: fragment } });
    }

    public openInNewWindow(url: string) {
        this.newWindowService.open(url);
    }
}

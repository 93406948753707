import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { dialogEnterAndLeaveAnimation } from '@autoixpert/animations/dialog-enter-and-leave.animation';
import {
    documentBuildingBlocksEnhancedWithGraphic,
    enhancedDocumentBuildingBlocks,
} from '@autoixpert/lib/document-building-blocks/enhanced-document-building-blocks';
import { DocumentBuildingBlock } from '@autoixpert/models/documents/document-building-block';
import { EditedDocumentBuildingBlock } from '@autoixpert/models/reports/edited-document-building-block';
import { OriginalRenderedDocumentBuildingBlock } from '@autoixpert/models/reports/original-rendered-document-building-block';
import { openEnhancedDocumentBuildingBlockHelp } from 'src/app/shared/libraries/document-building-blocks/open-enhanced-document-building-block-help';
import { ApiErrorService } from '../../../../shared/services/api-error.service';
import { OriginalRenderedDocumentBuildingBlocksService } from '../../../../shared/services/original-rendered-document-building-blocks.service';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
    selector: 'overwrite-document-building-blocks',
    templateUrl: 'overwrite-document-building-blocks.component.html',
    styleUrls: ['overwrite-document-building-blocks.component.scss'],
    animations: [dialogEnterAndLeaveAnimation()],
})
export class OverwriteDocumentBuildingBlocksComponent {
    constructor(
        private toastService: ToastService,
        private router: Router,
        private originalRenderedDocumentBuildingBlocksService: OriginalRenderedDocumentBuildingBlocksService,
        private apiErrorService: ApiErrorService,
    ) {}

    @Input() reportId: string;
    @Input() editedBuildingBlocks: EditedDocumentBuildingBlock[] = [];
    @Output() editedBuildingBlocksChange: EventEmitter<EditedDocumentBuildingBlock[]> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    // Generated Building Blocks
    public generatedBuildingBlocks: OriginalRenderedDocumentBuildingBlock[] = [];
    public generatedBuildingBlocksPending: boolean;
    public uneditedBuildingBlocks: OriginalRenderedDocumentBuildingBlock[] = [];

    // Filter
    public searchTerm: string;
    public filteredGeneratedBuildingBlocks: OriginalRenderedDocumentBuildingBlock[] = [];

    // Edit Mode
    public buildingBlocksInEditMode: Map<OriginalRenderedDocumentBuildingBlock, void> = new Map();

    // These are the document building blocks for which DOCX partials exist. This list must be equal to the list of partial files in the backend.
    public enhancedDocumentBuildingBlocks = enhancedDocumentBuildingBlocks;
    public documentBuildingBlocksEnhancedWithGraphic = documentBuildingBlocksEnhancedWithGraphic;

    //*****************************************************************************
    //  Initialization
    //****************************************************************************/
    ngOnInit() {
        this.ensureEditedBuildingBlocksArray();
        this.loadGeneratedBuildingBlocks();
    }

    private ensureEditedBuildingBlocksArray(): void {
        if (!this.editedBuildingBlocks) {
            this.editedBuildingBlocks = [];
            this.emitEditedBuildingBlockChange();
        }
    }

    private mergePreviouslyEditedBuildingBlocks(): void {
        this.editedBuildingBlocks.forEach((previouslyEditedBlock) => {
            const matchingBlock = this.generatedBuildingBlocks.find(
                (block) => block.placeholder === previouslyEditedBlock.referencePlaceholder,
            );
            if (matchingBlock) {
                matchingBlock.heading = previouslyEditedBlock.heading;
                matchingBlock.content = previouslyEditedBlock.content;
            }
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Initialization
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Load Generated Building Blocks
    //****************************************************************************/
    private loadGeneratedBuildingBlocks(): void {
        this.originalRenderedDocumentBuildingBlocksService.find(this.reportId).subscribe({
            next: (buildingBlocks) => {
                this.generatedBuildingBlocks = buildingBlocks;
                this.uneditedBuildingBlocks = JSON.parse(JSON.stringify(buildingBlocks));
                this.mergePreviouslyEditedBuildingBlocks();
                this.filterBuildingBlocks();
            },
            error: (error) => {
                this.apiErrorService.handleAndRethrow({
                    axError: error,
                    handlers: {},
                    defaultHandler: {
                        title: 'Textbausteine konnte nicht generiert werden',
                        body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                    },
                });
            },
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Load Generated Building Blocks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Enhanced Document Building Blocks
    //****************************************************************************/
    public openEnhancedDocumentBuildingBlockHelp(
        renderedDocumentBuildingBlock: OriginalRenderedDocumentBuildingBlock,
    ): void {
        openEnhancedDocumentBuildingBlockHelp(renderedDocumentBuildingBlock.placeholder);
    }

    public mayDocumentBuildingBlockNotBePrinted(
        renderedDocumentBuildingBlock: OriginalRenderedDocumentBuildingBlock,
    ): boolean {
        // The document building block "Instandsetzungskosten" is an old relic. It is considered a "section heading" and will always be printed even if the content is empty.
        return (
            !renderedDocumentBuildingBlock.content &&
            !enhancedDocumentBuildingBlocks.includes(renderedDocumentBuildingBlock.placeholder) &&
            renderedDocumentBuildingBlock.placeholder !== 'Instandsetzungskosten'
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Enhanced Document Building Blocks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Table of Contents
    //****************************************************************************/
    public scrollIntoView(querySelector: string, documentBuildingBlockPlaceholder: DocumentBuildingBlock['_id']): void {
        const selectedElement = document.querySelector(querySelector);

        if (!selectedElement) {
            // Maybe just hidden by search query? -> Check if the missing element exists in the building block collection
            if (
                this.generatedBuildingBlocks.find(
                    (buildingBlock) => buildingBlock.placeholder === documentBuildingBlockPlaceholder,
                )
            ) {
                this.toastService.info(
                    'Durch Filter ausgeblendet',
                    'Das Element scheint durch den Suchfilter ausgeblendet zu sein.',
                );
            }
            // If the element does not have a corresponding building block, abort.
            return;
        } else {
            selectedElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Table of Contents
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Filter & Sort
    //****************************************************************************/
    public filterBuildingBlocks(): void {
        this.filteredGeneratedBuildingBlocks = [...this.generatedBuildingBlocks];

        this.applySearchFilter();
    }

    private applySearchFilter(): void {
        if (!this.searchTerm) {
            return;
        }

        const searchTerms = this.searchTerm.toLowerCase().split(' ');

        this.filteredGeneratedBuildingBlocks = this.filteredGeneratedBuildingBlocks.filter((buildingBlock) => {
            const propertiesToBeSearched: string[] = [buildingBlock.heading, buildingBlock.content];
            return searchTerms.every((searchTerm) => {
                return propertiesToBeSearched.some(
                    (propertyToBeSearched) =>
                        propertyToBeSearched && propertyToBeSearched.toLowerCase().includes(searchTerm),
                );
            });
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Filter & Sort
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Edit Building Blocks
    //****************************************************************************/
    public enterEditMode(buildingBlock: OriginalRenderedDocumentBuildingBlock): void {
        this.buildingBlocksInEditMode.set(buildingBlock);
    }

    public leaveEditMode(buildingBlock: OriginalRenderedDocumentBuildingBlock): void {
        this.buildingBlocksInEditMode.delete(buildingBlock);
    }

    public markBuildingBlockAsEdited(buildingBlock: OriginalRenderedDocumentBuildingBlock): void {
        const original = this.getOriginalBuildingBlock(buildingBlock);
        const hasChanged = buildingBlock.heading !== original.heading || buildingBlock.content !== original.content;

        if (hasChanged) {
            const editedBlock = new EditedDocumentBuildingBlock({
                referencePlaceholder: buildingBlock.placeholder,
                action: 'overwrite',
                heading: buildingBlock.heading,
                content: buildingBlock.content,
            });

            const indexOfExistingBlock = this.editedBuildingBlocks.findIndex(
                (editedBlock) => editedBlock.referencePlaceholder === buildingBlock.placeholder,
            );
            // If it doesn't exist, add it.
            if (indexOfExistingBlock < 0) {
                this.editedBuildingBlocks.push(editedBlock);
            }
            // ... otherwise replace the existing block to reflect any changes.
            else {
                this.editedBuildingBlocks.splice(indexOfExistingBlock, 1, editedBlock);
            }
            this.emitEditedBuildingBlockChange();
        }
    }

    private unmarkBuildingBlockAsEdited(buildingBlock: OriginalRenderedDocumentBuildingBlock): void {
        const indexOfExistingBlock = this.editedBuildingBlocks.findIndex(
            (editedBlock) => editedBlock.referencePlaceholder === buildingBlock.placeholder,
        );
        if (indexOfExistingBlock > -1) {
            this.editedBuildingBlocks.splice(indexOfExistingBlock, 1);
            this.emitEditedBuildingBlockChange();
        }
    }

    protected emptyBuildingBlock(buildingBlock: OriginalRenderedDocumentBuildingBlock): void {
        buildingBlock.heading = '';
        buildingBlock.content = '';
        this.markBuildingBlockAsEdited(buildingBlock);
    }

    /**
     * 1) Reset the building block for the edit mode
     * 2) Remove the record from editedBuildingBlocks that is passed back to the report.
     * @param buildingBlock
     */
    public resetBuildingBlock(buildingBlock: OriginalRenderedDocumentBuildingBlock): void {
        const original = this.getOriginalBuildingBlock(buildingBlock);

        // Reset building block locally
        buildingBlock.heading = original.heading;
        buildingBlock.content = original.content;

        // Remove edit record from report array
        this.unmarkBuildingBlockAsEdited(buildingBlock);
    }

    public resetAllDocumentBuildingBlocks() {
        for (const buildingBlock of this.generatedBuildingBlocks) {
            this.resetBuildingBlock(buildingBlock);
        }
        // Remove all elements. We don't replace the array with an empty array to avoid overwriting the reference within this.editedBuildingBlocks.
        this.editedBuildingBlocks.length = 0;
    }

    private getOriginalBuildingBlock(
        buildingBlock: OriginalRenderedDocumentBuildingBlock,
    ): OriginalRenderedDocumentBuildingBlock {
        return this.uneditedBuildingBlocks.find(
            (uneditedBlock) => uneditedBlock.placeholder === buildingBlock.placeholder,
        );
    }

    public buildingBlockIsChanged(buildingBlock: OriginalRenderedDocumentBuildingBlock): boolean {
        return !!this.editedBuildingBlocks.find(
            (editedBlock) => editedBlock.referencePlaceholder === buildingBlock.placeholder,
        );
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Building Blocks
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Navigation
    //****************************************************************************/
    public navigateToDocumentBuildingBlocks(): void {
        this.router.navigate(['Einstellungen/Textbausteine']);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Navigation
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Event Emitters
    //****************************************************************************/
    public closeDialog(): void {
        this.close.emit();
    }

    public emitEditedBuildingBlockChange(): void {
        this.editedBuildingBlocksChange.emit(this.editedBuildingBlocks);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Event Emitters
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    @HostListener('window:keydown', ['$event'])
    public handleCtrlEnter(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.closeDialog();
                break;
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { FrontendLog } from '@autoixpert/models/journals/frontend-log';

@Injectable()
export class FrontendLogService {
    constructor(private httpClient: HttpClient) {}

    public async create(frontendLog: Pick<FrontendLog, 'service' | 'resourceId' | 'payload'>): Promise<FrontendLog> {
        console.debug('Frontend log received', frontendLog);

        return this.httpClient.post<FrontendLog>(`/api/v0/frontendLogs`, frontendLog).toPromise();
    }

    /**
     * If the log entry is too large and does not need to be searched through a smart database, use this function to dump it
     * to AWS S3.
     */
    public async createFile(frontendLogFile: Blob): Promise<SignedFrontendLogUploadUrlResponse['frontendLogId']> {
        /**
         * Get a URL that's signed by a key in the autoiXpert backend so that AWS S3 knows that this link may be used for uploading
         * a file to AWS S3 directly without another form of authentication.
         */
        const { signedFrontendLogUploadUrl, frontendLogId } = await this.httpClient
            .get<SignedFrontendLogUploadUrlResponse>(`/api/v0/signedFrontendLogUploadUrls`)
            .toPromise();

        try {
            await this.httpClient.put(signedFrontendLogUploadUrl, frontendLogFile).toPromise();
        } catch (error) {
            throw new AxError({
                code: 'UPLOADING_FRONTEND_LOG_FAILED',
                message: 'The frontend log file could not be uploaded to AWS S3. Have a look at the causedBy property.',
                error,
            });
        }

        return frontendLogId;
    }
}

export type SignedFrontendLogUploadUrlResponse = {
    frontendLogId: string;
    signedFrontendLogUploadUrl: string;
};

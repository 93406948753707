import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment/moment';
import { getFullName } from '@autoixpert/lib/placeholder-values/get-full-name';
import { InternalNote } from '@autoixpert/models/internal-note';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from '../../services/logged-in-user.service';
import { ToastService } from '../../services/toast.service';
import { UserService } from '../../services/user.service';

/**
 * A component to display a single internal note.
 *
 * The component allows editing a note in place.
 * The parent is responsible for saving the changes to the server.
 */
@Component({
    selector: 'internal-note',
    templateUrl: './internal-note.component.html',
    styleUrls: ['./internal-note.component.scss'],
})
export class InternalNoteComponent implements OnInit {
    constructor(
        protected userService: UserService,
        private loggedInUserService: LoggedInUserService,
        private toastService: ToastService,
    ) {}

    public user: User;

    // Team Members
    public teamMembersMap: Map<string, User> = new Map();

    @Input() note: InternalNote;
    @Input() isNewNote: boolean = false;
    @Output() editModeActivated: EventEmitter<InternalNote> = new EventEmitter();
    @Output() editModeQuit: EventEmitter<InternalNote> = new EventEmitter();
    @Output() noteChange: EventEmitter<InternalNote> = new EventEmitter();
    @Output() noteDeleted: EventEmitter<InternalNote> = new EventEmitter();

    protected isEditModeActive: boolean;

    ngOnInit() {
        this.setupTeamMembersMap();
        this.user = this.loggedInUserService.getUser();

        if (this.isNewNote) {
            this.enterEditMode();
        }
    }

    //*****************************************************************************
    //  Team Members
    //****************************************************************************/
    private setupTeamMembersMap() {
        this.teamMembersMap = new Map(
            this.userService.getAllTeamMembersFromCache().map((teamMember) => [teamMember._id, teamMember]),
        );
    }

    public getTeamMember(id: string): User {
        return this.teamMembersMap.get(id);
    }

    public getFullNameOfTeamMember(id: string): string {
        const user = this.getTeamMember(id);

        if (!user) {
            return `Unbekannter Nutzer (ID: ${id})`;
        }

        return getFullName({
            firstName: user.firstName,
            lastName: user.lastName,
        });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Team Members
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Edit Mode
    //****************************************************************************/
    public enterEditMode() {
        this.isEditModeActive = true;
        this.editModeActivated.emit(this.note);
    }

    public toggleNoteAsImportant(note: InternalNote) {
        note.isImportant = !note.isImportant;
    }

    public emitNoteChange() {
        if (!this.isNewNote) {
            this.setEditDetails();
        }
        this.noteChange.emit(this.note);
    }

    public leaveEditMode() {
        // Remove empty notes
        if (!this.note.text) {
            this.emitNoteDeletion();
            return;
        }

        this.isEditModeActive = false;
        this.editModeQuit.emit(this.note);
    }

    public setEditDetails() {
        this.note.updatedBy = this.user._id;
        this.note.updatedAt = moment().format();
    }

    /**
     * When double-clicking, allow editing a note if it's your own.
     */
    public enterEditModeOnDoubleClick() {
        if (this.note.createdBy !== this.user._id) {
            this.toastService.warn('Nicht deine Notiz', 'Du kannst nur eigene Notizen bearbeiten.');
            return;
        }

        this.enterEditMode();
    }

    //*****************************************************************************
    //  Keyboard Shortcuts
    //****************************************************************************/
    public leaveEditModeOnCtrlEnter(event: KeyboardEvent) {
        switch (event.key) {
            case 'Enter':
                if (event.ctrlKey || event.metaKey) {
                    // Trigger change handler before removing the input through Angular.
                    (event.target as HTMLInputElement).blur();

                    this.leaveEditMode();
                    break;
                }
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Keyboard Shortcuts
    /////////////////////////////////////////////////////////////////////////////*/
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Edit Mode
    /////////////////////////////////////////////////////////////////////////////*/

    public emitNoteDeletion() {
        this.noteDeleted.emit(this.note);
    }
}

import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { AxAngularErrorHandlerData } from './ax-angular-error-handler-data';
import { consoleLogAxError } from './console-log-ax-error';

/**
 * Implementation of Angular's ErrorHandler that can be used as a drop-in replacement for the stock one.
 */
@Injectable({ providedIn: 'root' })
export class AxAngularErrorHandler implements ErrorHandler {
    /**
     * This is called every time an error is thrown in the application. It's captured through zone.js
     * (see https://github.com/angular/angular/blob/8a5f3197c05a195cc9655782fb9d885ab927510d/packages/core/src/platform/bootstrap.ts#L89),
     * so it includes both local errors (e.g. TypeError) and server errors (converted to AxErrors through the ConvertToAxErrorInterceptor).
     */
    handleError(
        potentiallyWrappedError: Error | AxError | { promise: Promise<any>; rejection: AxError | Error },
    ): void {
        /**
         * If errors occur within a promise in an Angular Zone, they are wrapped in an object with properties `promise` and a
         * `rejection`. That's usually the case with API errors.
         */
        let error: Error | AxError;
        if (
            typeof potentiallyWrappedError === 'object' &&
            'rejection' in potentiallyWrappedError &&
            'promise' in potentiallyWrappedError
        ) {
            error = potentiallyWrappedError.rejection;
        } else {
            error = potentiallyWrappedError;
        }

        //*****************************************************************************
        //  Console.Log
        //****************************************************************************/
        /**
         * AxErrors contain the error chain (error.causedBy) and extra data (error.data) which would be swallowed
         * by Angular's default console.error(error).
         * Print errors completely and in a useful format instead.
         *
         * Use error.type instead of an instanceof-check since instanceof does not work for @feathers/socketio-based errors
         * which e.g. result from an expired JSON Web Token (JWT).
         */
        if (typeof error === 'object' && 'type' in error && error.type === 'AxError') {
            /**
             * We do not need to log errors that were handled by the frontend. Examples of handled errors:
             * - Show an error toast about DOCX template errors with instructions to solve the template issues.
             * - Redirect the user to the login when the JWT expired.
             *
             * Errors are marked as handled instead of swallowed in the ApiErrorService because we still want them to
             * break the control flow of the function where ApiErrorService.handle() is called. That ensures no
             * further action is taken after the error was handled except there is another explicit catch block.
             */
            if ((error.data as AxAngularErrorHandlerData)?.didFrontendHandleError) {
                return;
            }

            consoleLogAxError(error);
        } else {
            /**
             * TypeErrors and other non-handled errors.
             */
            console.error(error);
        }
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Console.Log
        /////////////////////////////////////////////////////////////////////////////*/

        /**
         * Log all unhandled errors to Sentry. Examples of handled errors:
         * - Show an error toast about DOCX template errors with instructions to solve the template issues.
         * - Redirect the user to the login when the JWT expired.
         */
        Sentry.captureException(error);
    }
}

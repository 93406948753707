import { Report } from '@autoixpert/models/reports/report';

/**
 * Returns:
 * - if there are more decreases than increases: a negative number.
 * - otherwise a positive number.
 */
export function getTotalCorrectionsGross(report: Report): number {
    if (!report.valuation?.replacementValueCorrectionConfig) {
        return 0;
    }

    return (
        (report.valuation.replacementValueCorrectionConfig.totalIncrease ?? 0) -
        (report.valuation.replacementValueCorrectionConfig.totalDecrease ?? 0) -
        (report.valuation.decreaseFromDamageGross ?? 0)
    );
}

/**
 * Returns:
 * - if there are more decreases than increases: a negative number.
 * - otherwise a positive number.
 */
export function getTotalCorrectionsNet(report: Report): number {
    if (!report.valuation?.replacementValueCorrectionConfig) {
        return 0;
    }

    return (
        (report.valuation.replacementValueCorrectionConfig.totalIncrease ?? 0) / 1.19 -
        (report.valuation.replacementValueCorrectionConfig.totalDecrease ?? 0) / 1.19 -
        (report.valuation.decreaseFromDamageNet ?? 0)
    );
}

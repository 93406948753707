import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInAndOutAnimation } from '@autoixpert/animations/fade-in-and-out.animation';
import {
    FinancialInstitutionsService,
    GocardlessFinancialInstitution,
} from 'src/app/shared/services/Banking/financial-institutions.service';

@Component({
    selector: 'gocardless-financial-institutions-list',
    templateUrl: './gocardless-financial-institutions-list.component.html',
    styleUrls: ['./gocardless-financial-institutions-list.component.scss'],
    animations: [fadeInAndOutAnimation()],
})
export class GocardlessFinancialInstitutionsListComponent {
    @Input() financialInstitutions: GocardlessFinancialInstitution[] = [];
    @Input() financialInstitutionsLoading: boolean = false;
    @Output() financialInstitutionSelected = new EventEmitter<GocardlessFinancialInstitution>();

    trackByFinancialInstitutionId(index: number, financialInstitution: GocardlessFinancialInstitution): string {
        return financialInstitution.id;
    }
}

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
    MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatLegacyTooltipModule as MatTooltipModule,
} from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { LicensePlateComponent } from './license-plate.component';

@NgModule({
    imports: [BrowserModule, MatIconModule, FormsModule, MatTooltipModule],
    declarations: [LicensePlateComponent],
    exports: [LicensePlateComponent],
    providers: [
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                /**
                 * Prevent the tooltip text to be selectable with the mouse pointer. This way, it does not stand in the way when the user moves the mouse to click a button currently covered by the tooltip.
                 * Details: https://github.com/angular/components/issues/25173
                 */
                disableTooltipInteractivity: true,
                showDelay: 600,
            },
        },
    ],
})
export class LicensePlateModule {}

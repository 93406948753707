import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { removeInvalidFilenameCharacters } from '../libraries/remove-invalid-filename-characters';

/**
 * This service allows downloading an ArrayBuffer server response
 */
@Injectable()
export class DownloadService {
    constructor(private domSanitizer: DomSanitizer) {}

    public download$: Subject<DownloadInfo> = new Subject();

    public downloadBlobResponseWithHeaders(response: { body: Blob; headers: any }): void {
        let fileName = response.headers
            .get('Content-Disposition')
            .replace(/attachment; filename=/, '')
            .replace(/"/g, '');

        // This is a workaround for Google Chrome v76 in which headers are encoded into UTF-8 as if they were ISO-8859-1 encoded,
        // so the result is double UTF-8 encoded. If that's detected, use the filename that was en- and decoded URL-conform.
        // We would not want to always URIEncode the filename within the Content-Disposition header since that would cause weird
        // behavior in clients such as Postman.
        // TODO Remove as soon as Chrome is fixed. Steffen Langer on 2019-08-26.
        const uriDecodedFilename = decodeURIComponent(response.headers.get('x-filename-uriencoded-workaround'));
        if (fileName !== uriDecodedFilename) {
            fileName = uriDecodedFilename;
        }

        this.downloadFile(response.body, decodeURIComponent(fileName));
    }

    public downloadFile(file: Blob, fileName: string): void {
        const downloadInfo = {
            file,
            fileName: removeInvalidFilenameCharacters(fileName),
        };
        this.download$.next(downloadInfo);
    }
}

export class DownloadInfo {
    file: Blob;
    fileName: string;
}

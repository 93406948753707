import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { trackById } from '../../../lib/track-by/track-by-id';
import { IconPickerService } from '../icon-picker.service';
import { MaterialIconMetadata, MaterialIconsMetadata } from '../material-icons-metadata';

@Component({
    selector: 'icon-picker-overlay',
    templateUrl: './icon-picker-overlay.component.html',
    styleUrls: ['./icon-picker-overlay.component.scss'],
})
export class IconPickerOverlayComponent {
    readonly IconPickerRowType = IconPickerRowType;
    readonly trackById = trackById;

    constructor(private readonly iconPickerService: IconPickerService) {}

    protected icons: MaterialIconMetadata[];
    protected rows: IconPickerRowUnion[] = [];
    private subscriptions: Subscription[] = [];

    protected isLoading = true;
    protected searchTerm = '';
    protected focusedIndex = 0;
    private searchIcons$$ = new Subject<{ searchTerm: string }>();

    @ViewChild(CdkVirtualScrollViewport) iconsViewport?: CdkVirtualScrollViewport;
    @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

    @Output() iconPicked: EventEmitter<{ icon: string }> = new EventEmitter<{ icon: string }>();

    async ngOnInit() {
        this.isLoading = true;
        const metadata = await this.iconPickerService.loadIconsMetadata();
        this.icons = metadata.icons.filter((icon) => !icon.id.endsWith('_outline') && !icon.id.endsWith('_indicator'));

        this.rows = this.computeIconPickerRows(this.icons);
        this.isLoading = false;

        // Ensure that the viewport dimensions are up to date after the icons have been loaded
        setTimeout(() => this.iconsViewport?.checkViewportSize());

        // Search for users when the search term changes
        this.subscriptions.push(
            this.searchIcons$$
                .pipe(
                    distinctUntilChanged((a, b) => a.searchTerm === b.searchTerm),
                    debounceTime(300),
                    tap(({ searchTerm }) => this.computeFilteredIconPickerRows({ searchTerm })),
                )
                .subscribe(),
        );
    }

    private computeFilteredIconPickerRows({ searchTerm }: { searchTerm: string }) {
        const filteredIcons = this.icons.filter((icon) =>
            [icon.id, icon.label, ...icon.tags].some((value) => value.toLowerCase().includes(searchTerm.toLowerCase())),
        );
        console.log(filteredIcons);
        this.rows = this.computeIconPickerRows(filteredIcons);
        this.iconsViewport?.checkViewportSize();
    }

    private computeIconPickerRows(icons: MaterialIconMetadata[]) {
        const rows: IconPickerRowUnion[] = [];

        const categories = [
            {
                label: 'Beliebt',
                icons: icons.filter((icon) => this.getIconCategory(icon) === 'popular'),
            },
            {
                label: 'Andere',
                icons: icons.filter((icon) => this.getIconCategory(icon) === 'other'),
            },
        ];

        for (const category of categories) {
            if (category.icons.length === 0) continue;

            // Add category row
            rows.push({
                _id: `category-${category.label}`,
                type: IconPickerRowType.CATEGORY,
                label: category.label,
            });

            // Add icons row
            for (let i = 0; i < category.icons.length; i += 12) {
                const cells: Array<MaterialIconMetadata> = category.icons.slice(i, i + 12);
                while (cells.length < 12) {
                    cells.push({
                        id: `empty-${cells.length}`,
                        label: '',
                        tags: [],
                        category: '',
                        popularity: 0,
                    });
                }

                rows.push({
                    _id: `icons-${i}`,
                    type: IconPickerRowType.ICONS,
                    icons: cells.map((cell) => ({
                        _id: cell.id,
                        category: 'other',
                        tooltip: cell.label,
                    })),
                });
            }
        }
        return rows;
    }

    private getIconCategory(icon: MaterialIconMetadata): 'popular' | 'other' {
        if (
            [
                'handyman',
                'tools_wrench',
                'construction',
                'build_circle',
                'balance',
                'policy',
                'draw',
                'cases',
                'assured_workload',
                'directions_car',
                'motorcycle',
                'local_shipping',
                'minor_crash',
                'car_crash',
                'car_repair',
                'electric_car',
                'admin_panel_settings',
                'edit_document',
                'clinical_notes',
                'demography',
                'post_add',
                'content_paste_search',
                'fact_check',
            ].includes(icon.id)
        ) {
            return 'popular';
        }
        return 'other';
    }

    handleIconClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.className.includes('icon-picker-item')) return;
        const icon = target.getAttribute('id');
        this.iconPicked.emit({ icon });
    }

    //*****************************************************************************
    //  Search input
    //****************************************************************************/
    protected setFocusedIndex(index: number): void {
        this.focusedIndex = index;
    }

    protected moveFocusedIndex(delta: 1 | -1): void {
        const newIndex = (this.focusedIndex + delta + this.rows.length) % this.rows.length;
        this.setFocusedIndex(newIndex);
    }

    protected handleSearchInputChange(event: Event): void {
        const searchTerm = ((event.target as HTMLInputElement).value || '').trim();
        this.searchTerm = searchTerm;
        this.searchIcons$$.next({ searchTerm });
    }

    protected clearAndFocusSearchInput() {
        this.searchTerm = '';
        const searchInputEl = this.searchInput?.nativeElement;
        this.searchIcons$$.next({ searchTerm: '' });
        if (searchInputEl) {
            searchInputEl.value = '';
            searchInputEl.focus();
        }
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Search input
    /////////////////////////////////////////////////////////////////////////////*/

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

enum IconPickerRowType {
    ICONS = 'icons',
    CATEGORY = 'category',
}

interface IconPickerRow {
    _id: string;
    type: IconPickerRowType;
}

interface IconPickerCategoryRow extends IconPickerRow {
    type: IconPickerRowType.CATEGORY;
    label: string;
}

interface IconPickerIconsRow extends IconPickerRow {
    type: IconPickerRowType.ICONS;
    icons: MaterialIconCell[];
}

type IconPickerRowUnion = IconPickerCategoryRow | IconPickerIconsRow;

interface MaterialIconCell {
    _id: string;
    tooltip: string;
    category: 'popular' | 'other';
}

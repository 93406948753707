import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getGdvErrorHandlers(): { [key: string]: ApiErrorHandler | ApiErrorHandlerCreator } {
    return {
        GDV_RESPONSIBLE_INSURANCE_INVALID_LOGIN: {
            title: 'GDV-Zugangsdaten abgelehnt',
            body: 'Bitte prüfe deine Zugangsdaten auf dem <a href="https://zaonline.gdv-dl.de/" target="_blank" rel="noopener">ZA-Online-Login</a>.',
        },
        GDV_RESPONSIBLE_INSURANCE_TEMPORARILY_UNVAILABLE: {
            title: 'GDV temporär nicht erreichbar',
            body: 'Bitte versuche es später erneut. Prüfe auch, ob die Abfrage über <a href="https://zaonline.gdv-dl.de/" target="_blank" rel="noopener">ZA-Online</a> möglich ist.',
        },
        GDV_RESPONSIBLE_INSURANCE_NOT_FOUND_BUT_QUERIED_AT_KBA: {
            title: 'Anfrage an Zulassungsstelle gestellt',
            body: 'Die Versicherungsdaten sind dem GDV nicht bekannt, aber er hat eine Anfrage an die Zulassungsstelle gestellt, was Stunden bis Tage dauern kann. Bitte schaue dann auf <a href="https://zaonline.gdv-dl.de/" target="_blank" rel="noopener">ZA-Online</a> nach.',
            toastType: 'info',
        },
        GDV_RESPONSIBLE_INSURANCE_NOT_FOUND: {
            title: 'Versicherung nicht gefunden',
            body: 'Bitte gib die Versicherungsdaten manuell ein.',
        },
        MISSING_LICENSE_PLATE_FOR_GDV_QUERY: {
            title: 'Kennzeichen fehlt',
            body: 'Bitte gib zuerst das Kennzeichen des versicherten Fahrzeugs ein.',
        },
        INVALID_INSURANCE_TYPE_FOR_GDV_QUERY: {
            title: 'Nur Haftpflicht und Kasko möglich',
            body: 'Das ist ein technischer Fehler. Wende dich an die <a href=""/Hilfe">Hotline</a>.',
        },
        MISSING_ACCIDENT_DATE_FOR_GDV_QUERY: {
            title: 'Unfalltag fehlt',
            body: 'Bitte gib den Unfalltag an.',
        },
    };
}

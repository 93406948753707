import { ResidualValueBid } from './residual-value-bid';

export class ResidualValueOffer {
    offerId: string = null;
    link: string = null;
    readyAt: string = null;
    retrievedAt: string = null;
    renderedHash: string = null;
    selectedBid: any = null;
    bids: ResidualValueBid[] = [];
}

export type ResidualValueExchangeId = 'autoonline' | 'carcasion' | 'cartv' | 'winvalue';

export type SupportedResidualValueExchange<key extends ResidualValueExchangeId | any> = {
    id: key;
    name: string;
    logoFileName: string;
};
export const supportedResidualValueExchanges: {
    [key in ResidualValueExchangeId]: SupportedResidualValueExchange<key>;
} = {
    autoonline: {
        id: 'autoonline',
        name: 'AUTOonline',
        logoFileName: 'autoonline-logo.svg',
    },
    cartv: {
        id: 'cartv',
        name: 'CARTV',
        logoFileName: 'cartv-logo.svg',
    },
    carcasion: {
        id: 'carcasion',
        name: 'car.casion',
        logoFileName: 'car-casion.png',
    },
    winvalue: {
        id: 'winvalue',
        name: 'WinValue',
        logoFileName: 'winvalue.png',
    },
};

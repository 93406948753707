<div class="card-toolbar">
    <!--********** Edit / View Audatex Qapter Calculation **********-->
    <mat-icon
        *ngIf="report.damageCalculation.repair.calculationProvider === 'audatex'"
        (click)="report.audatexTaskId && openAudatexDamageCalculation()"
        matTooltip="Kalkulation öffnen"
        [class.disabled]="!report.audatexTaskId"
        class="toolbar-icon">
        {{ isReportLocked() ? 'visibility' : 'edit' }}
    </mat-icon>

    <!--********** Edit Audatex Text Import Calculation **********-->
    <mat-icon
        *ngIf="report.damageCalculation.repair.calculationProvider === 'audatex-textimport'"
        (click)="!isReportLocked() && showAudatexCalculationInsertionDialog()"
        matTooltip="Kalkulation öffnen"
        [class.disabled]="isReportLocked()"
        class="toolbar-icon">
        edit
    </mat-icon>

    <!--********** Edit / View DAT Calculation **********-->
    <mat-icon
        *ngIf="report.damageCalculation.repair.calculationProvider === 'dat'"
        (click)="report.damageCalculation.repair.datCalculation?.dossierId && openDatDamageCalculation()"
        matTooltip="Kalkulation öffnen"
        [class.disabled]="!report.damageCalculation.repair.datCalculation?.dossierId"
        class="toolbar-icon">
        {{ isReportLocked() ? 'visibility' : 'edit' }}
    </mat-icon>

    <!--********** Edit / View GT Motive Calculation **********-->
    <mat-icon
        *ngIf="report.damageCalculation.repair.calculationProvider === 'gtmotive' && !gtmotiveCalculationOpeningPending"
        (click)="report.gtmotiveEstimateId && openGtmotiveCalculation()"
        matTooltip="Kalkulation öffnen"
        [class.disabled]="!report.gtmotiveEstimateId"
        class="toolbar-icon">
        {{ isReportLocked() ? 'visibility' : 'edit' }}
    </mat-icon>

    <!--********** The manual calculation may be viewed if the report is locked, so don't disable it here. **********-->
    <mat-icon
        *ngIf="report.damageCalculation.repair.calculationProvider === 'manual'"
        (click)="showManualCalculationDialog()"
        matTooltip="Kalkulation öffnen"
        class="toolbar-icon">
        edit
    </mat-icon>
    <mat-icon
        #damageCalculationActionsSubMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="damageCalculationActionsSubMenu"
        class="toolbar-icon">
        more_vert
    </mat-icon>
</div>

<!--============================================-->
<!-- Damage Calculation Settings Submenu -->
<!--============================================-->
<mat-menu #damageCalculationSettingsSubMenu>
    <a
        mat-menu-item
        (click)="toggleCreateDocumentMetadataForDamageCalculation()"
        *ngIf="!user?.preferences.createDocumentMetadataForDamageCalculation"
        matTooltip="Die Kalkulation wird bei jedem Import als Dokument im Reiter Druck & Versand eingefügt.{{
            '\n\n'
        }}Das kannst du nutzen, um die Schadenskalkulation einzeln herunterzuladen oder zu verschicken.">
        Immer als Dokument verfügbar machen
    </a>
    <a
        mat-menu-item
        (click)="toggleCreateDocumentMetadataForDamageCalculation()"
        *ngIf="user?.preferences.createDocumentMetadataForDamageCalculation"
        matTooltip="Den Standard wiederherstellen: Die Schadenskalkulation wird nicht nach jedem Import als einzelnes Dokument im Reiter Druck & Versand aufgeführt.">
        Nur manuell als Dokument verfügbar machen
    </a>
</mat-menu>
<!--============================================-->
<!-- END Damage Calculation Settings Submenu -->
<!--============================================-->

<!--============================================-->
<!-- Damage Calculation Actions Submenu -->
<!--============================================-->
<mat-menu #damageCalculationActionsSubMenu="matMenu">
    <!--***********************************************
                ** DAT-Specific
                ************************************************-->
    <ng-container *ngIf="report.damageCalculation.repair.calculationProvider === 'dat'">
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="
                report.damageCalculation.repair.datCalculation?.dossierId &&
                    retrieveDamageCalculationResults() &&
                    damageCalculationActionsSubMenuTrigger.closeMenu()
            "
            matTooltip="Importiere die Ergebnisse der DAT-Kalkulation manuell, falls du über die DAT.de Änderungen an der Kalkulation vorgenommen hast."
            [disabled]="!report.damageCalculation.repair.datCalculation?.dossierId || isReportLocked()">
            <mat-icon>get_app</mat-icon>
            Ergebnisse von DAT holen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="
                report.damageCalculation.repair.datCalculation?.dossierId &&
                    updateFeesAndTransmitDamageCalculationPresetsToDat()
            "
            matTooltip="Relevant, falls du Kalkulationsdaten in autoiXpert nach der Erstellung der Kalkulation geändert hast:{{
                '\n'
            }}- Werkstattkostensätze{{ '\n' }}- Name des Anspruchstellers{{ '\n' }}- usw."
            [disabled]="!report.damageCalculation.repair.datCalculation?.dossierId || isReportLocked()">
            <mat-icon>publish</mat-icon>
            Daten zu DAT überspielen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="addDamageCalculationReportDocument()"
            matTooltip="Ermögliche den separaten Druck & Versand der Kalkulation, auch außerhalb des Gutachtens. Im Gutachten wird die Kalkulation immer abgedruckt."
            [disabled]="!report.damageCalculation.repair.datCalculation?.dossierId || isReportLocked()">
            <mat-icon>description</mat-icon>
            Als Dokument verfügbar machen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="downloadVxs()"
            matTooltip="Lade die VXS-Datei herunter, um die DAT-Kalkulation in einer anderen Software zu importieren."
            [disabled]="!report.damageCalculation.repair.datCalculation?.dossierId">
            <mat-icon>build</mat-icon>
            VXS-Datei herunterladen
        </a>
    </ng-container>

    <!--**********
                Allow linking/unlinking DAT damage calculation if either
                - no vehicle identification was conducted with a data provider (DAT, Audatex) because none of them has accurate data
                - a vehicle identification (VIN, DAT€Code, KBA) was conducted with the DAT data.
                Allowing to link a calculation if no vehicle identification provider was chosen is important in case the respective vehicle is a special vehicle without any VIN data.
                **********-->
    <ng-container
        *ngIf="
            report.car.identificationProvider === 'dat' &&
            !report.damageCalculation.repair.calculationProvider &&
            isDatUserComplete(user)
        ">
        <!--********** Connect DAT Damage Calculation manually **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="openDatCalculationConnectionDialog()"
            *ngIf="!report.damageCalculation.repair.calculationProvider"
            matTooltip="DAT-Kalkulation von DAT.de manuell mit diesem Gutachten verknüpfen">
            <mat-icon>link</mat-icon>
            DAT-Kalkulation verknüpfen
        </a>

        <!--********** Import SilverDAT 2 calculation **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="openDatCalculateExpertDossierListTab()"
            *ngIf="!report.damageCalculation.repair.calculationProvider"
            matTooltip="DAT-Kalkulation aus VXS-Datei importieren">
            <mat-icon>upload</mat-icon>
            DAT-Datei importieren (VXS)
        </a>
    </ng-container>

    <!--********** Disconnect DAT Damage Calculation **********-->
    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="unlinkDatDamageCalculation()"
        *ngIf="report.damageCalculation.repair.calculationProvider === 'dat'"
        matTooltip="DAT-Kalkulation von diesem Gutachten trennen. Diese bleibt aber anders als beim Löschen auf DAT.de erhalten."
        [disabled]="isReportLocked()">
        <mat-icon>link_off</mat-icon>
        DAT-Kalkulation trennen
    </a>

    <!--***********************************************
                ** END DAT-Specific
                ************************************************-->

    <!--***********************************************
                ** Audatex-Specific
                ************************************************-->
    <ng-container *ngIf="report.damageCalculation.repair.calculationProvider === 'audatex'">
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="importAudatexCalculation() && damageCalculationActionsSubMenuTrigger.closeMenu()"
            matTooltip="{{
                isReportLocked()
                    ? 'Entsperre den Vorgang um Änderungen von Audatex zu importieren.'
                    : 'Importiere die Ergebnisse der Kalkulation manuell, falls du über die Audatex-Oberfläche Änderungen an der Kalkulation vorgenommen hast.'
            }}"
            [disabled]="isReportLocked()">
            <mat-icon>get_app</mat-icon>
            Ergebnisse von Audatex holen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="updateFeesAndExportDataToAudatex()"
            matTooltip="Relevant, falls du Kalkulationsdaten in autoiXpert nach der Erstellung der Kalkulation geändert hast:{{
                '\n'
            }}- Werkstattkostensätze{{ '\n' }}- Name des Anspruchstellers{{ '\n' }}- usw."
            [disabled]="isReportLocked()">
            <mat-icon>publish</mat-icon>
            Daten zu Audatex überspielen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="addDamageCalculationReportDocument()"
            matTooltip="Ermögliche den separaten Druck & Versand der Kalkulation, auch außerhalb des Gutachtens. Im Gutachten wird die Kalkulation immer abgedruckt."
            [disabled]="!report.audatexTaskId || isReportLocked()">
            <mat-icon>description</mat-icon>
            Als Dokument verfügbar machen
        </a>
    </ng-container>

    <!--********** Connect Audatex Task manually **********-->
    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="openAudatexTaskConnectionDialog()"
        *ngIf="
            report.car.identificationProvider === 'audatex' &&
            !report.damageCalculation.repair.calculationProvider &&
            isAudatexUserComplete(user)
        "
        matTooltip="Audatex-Vorgang von Qapter manuell mit diesem Gutachten verknüpfen">
        <mat-icon>link</mat-icon>
        Audatex-Vorgang verknüpfen
    </a>

    <!--********** Disconnect Audatex Task **********-->
    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="unlinkAudatexTask()"
        *ngIf="report.damageCalculation.repair.calculationProvider === 'audatex'"
        matTooltip="Audatex-Vorgang von diesem Gutachten trennen. Dieser bleibt aber anders als beim Löschen in Qapter erhalten.">
        <mat-icon>link_off</mat-icon>
        Audatex-Vorgang trennen
    </a>
    <!--***********************************************
                ** END Audatex-Specific
                ************************************************-->

    <!--***********************************************
                ** GT-Motive-Specific
                ************************************************-->
    <ng-container *ngIf="report.damageCalculation.repair.calculationProvider === 'gtmotive'">
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="
                report.gtmotiveEstimateId &&
                    importGtmotiveCalculation() &&
                    damageCalculationActionsSubMenuTrigger.closeMenu()
            "
            matTooltip="{{
                isReportLocked()
                    ? 'Entsperre den Vorgang um Änderungen von GT-Motive zu importieren.'
                    : 'Importiere die Ergebnisse der Kalkulation manuell, falls du über die GT-Motive-Webiste Änderungen an der Kalkulation vorgenommen hast.'
            }}"
            [disabled]="!report.gtmotiveEstimateId || isReportLocked()">
            <mat-icon>get_app</mat-icon>
            Ergebnisse von GT Motive holen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="report.gtmotiveEstimateId && updateFeesAndExportToGtmotive()"
            matTooltip="Relevant, falls du Kalkulationsdaten in autoiXpert nach der Erstellung der Kalkulation geändert hast:{{
                '\n'
            }}- Werkstattkostensätze{{ '\n' }}- Name des Anspruchstellers{{ '\n' }}- usw."
            [disabled]="!report.gtmotiveEstimateId || isReportLocked()">
            <mat-icon>publish</mat-icon>
            Daten zu GT Motive überspielen
        </a>
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="addDamageCalculationReportDocument()"
            matTooltip="Ermögliche den separaten Druck & Versand der Kalkulation, auch außerhalb des Gutachtens. Im Gutachten wird die Kalkulation immer abgedruckt."
            [disabled]="!report.gtmotiveEstimateId || isReportLocked()">
            <mat-icon>description</mat-icon>
            Als Dokument verfügbar machen
        </a>
    </ng-container>

    <!--********** Connect GT Motive Estimate manually **********-->
    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="openGtmotiveEstimateConnectionDialog()"
        *ngIf="
            report.car.identificationProvider === 'gtmotive' &&
            !report.damageCalculation.repair.calculationProvider &&
            areGtmotiveCredentialsComplete()
        "
        matTooltip="GT-Motive-Kalkulation manuell mit diesem Gutachten verknüpfen">
        <mat-icon>link</mat-icon>
        GT-Motive-Kalkulation verknüpfen
    </a>

    <!--********** Disconnect GT Motive Estimate **********-->
    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="unlinkGtmotiveEstimate()"
        *ngIf="report.gtmotiveEstimateId && report.damageCalculation.repair.calculationProvider === 'gtmotive'"
        matTooltip="GT-Motive-Vorgang von diesem Gutachten trennen. Dieser bleibt aber anders als beim Löschen in GT Motive erhalten.">
        <mat-icon>link_off</mat-icon>
        GT-Motive-Vorgang trennen
    </a>
    <!--***********************************************
                ** END GT-Motive-Specific
                ************************************************-->

    <!--********** Manual Calculation **********-->
    <ng-container *ngIf="report.damageCalculation.repair.calculationProvider === 'manual'">
        <a
            mat-menu-item
            class="menu-item-with-icon"
            (click)="addDamageCalculationReportDocument()"
            [disabled]="isReportLocked()">
            <mat-icon>description</mat-icon>
            Als Dokument verfügbar machen
        </a>
    </ng-container>

    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="toggleRepairCalculationComment()"
        *ngIf="!repairCalculationCommentShown">
        <mat-icon>comment</mat-icon>
        Kommentar
    </a>

    <a mat-menu-item class="menu-item-with-icon" [matMenuTriggerFor]="damageCalculationSettingsSubMenu">
        <mat-icon>settings</mat-icon>
        Einstellungen
    </a>

    <!--********** Reset provider (all providers) **********-->
    <a
        mat-menu-item
        class="menu-item-with-icon"
        (click)="report.damageCalculation.repair.calculationProvider && handleResetDamageCalculationClick($event)"
        [disabled]="!report.damageCalculation.repair.calculationProvider || isReportLocked()">
        <mat-icon>delete</mat-icon>
        Löschen
    </a>
</mat-menu>
<!--============================================-->
<!-- END Damage Calculation Actions Submenu -->
<!--============================================-->

<div
    id="before-damage-calculation"
    class="external-damage-calculation-container"
    *ngIf="!report.damageCalculation.repair.calculationProvider || isExternalOnlineCalculationProvider()">
    <div *ngIf="!report.damageCalculation.repair.calculationProvider" @slideInAndOutVertically>
        Reparaturkosten kalkulieren mit:
    </div>
    <div id="calculation-providers-container">
        <!--********** DAT Main Calculation Provider **********-->
        <div
            class="main-calculation-provider calculation-provider"
            *ngIf="
                (!report.damageCalculation.repair.calculationProvider && isDatUserComplete(user)) ||
                (report.damageCalculation.repair.calculationProvider === 'dat' &&
                    !report.damageCalculation.repair.correctedRepairCostsNet)
            "
            [ngClass]="{
                disabled: report.car.identificationProvider !== 'dat' || !isDatUserComplete(user) || isReportLocked(),
            }"
            [matTooltip]="getDatCalculationOpenerTooltip()"
            (click)="!isReportLocked() && openDatDamageCalculation()">
            <img src="/assets/images/logos/dat-logo-no-text.svg" alt="DAT-Logo" />
            <div
                class="calculation-pending-indicator-container"
                *ngIf="
                    report.damageCalculation.repair.datCalculation?.dossierId &&
                    !report.damageCalculation.repair.repairCostsNet
                "
                @fadeInAndOut>
                <mat-icon
                    class="calculation-pending-indicator"
                    matTooltip="Kalkulation gestartet. Bitte schließe die Kalkulation in SilverDAT 3 ab. Zum Öffnen klicken.">
                    edit
                </mat-icon>
            </div>
        </div>

        <!--********** Audatex Main Calculation Provider **********-->
        <div
            class="main-calculation-provider calculation-provider"
            *ngIf="
                (!report.damageCalculation.repair.calculationProvider && isAudatexUserComplete(user)) ||
                (report.damageCalculation.repair.calculationProvider === 'audatex' &&
                    !report.damageCalculation.repair.correctedRepairCostsNet)
            "
            [ngClass]="{
                disabled:
                    report.car.identificationProvider !== 'audatex' || !isAudatexUserComplete(user) || isReportLocked(),
            }"
            [matTooltip]="getAudatexCalculationOpenerTooltip()"
            (click)="isAudatexUserComplete(user) && !isReportLocked() && openAudatexDamageCalculation()">
            <img src="/assets/images/icons/audatex-qapter-calculation.png" alt="Audatex-Logo" />

            <!--********** Edit Icon **********-->
            <div
                class="calculation-pending-indicator-container"
                *ngIf="report.audatexTaskId && report.damageCalculation.repair.calculationProvider === 'audatex'"
                @fadeInAndOut>
                <mat-icon
                    class="calculation-pending-indicator"
                    matTooltip="Kalkulation gestartet. Bitte schließe die Kalkulation in der Audatex-Oberfläche ab. Zum Öffnen klicken.">
                    edit
                </mat-icon>
            </div>
        </div>

        <!--********** GT Motive Calculation Provider **********-->
        <div
            class="main-calculation-provider calculation-provider"
            *ngIf="
                (!report.damageCalculation.repair.calculationProvider && areGtmotiveCredentialsComplete()) ||
                (report.damageCalculation.repair.calculationProvider === 'gtmotive' &&
                    !report.damageCalculation.repair.correctedRepairCostsNet)
            "
            [ngClass]="{
                disabled:
                    report.car.identificationProvider !== 'gtmotive' ||
                    !areGtmotiveCredentialsComplete() ||
                    isReportLocked(),
            }"
            [matTooltip]="getGtmotiveCalculationOpenerTooltip()"
            (click)="areGtmotiveCredentialsComplete() && !isReportLocked() && openGtmotiveCalculation()">
            <div id="gtmotive-logo-background">
                <img id="gtmotive-logo" src="/assets/images/logos/gtmotive-logo-no-text.png" alt="GT-Motive-Logo" />
            </div>

            <!--********** Loading Overlay **********-->
            <div
                class="calculation-loading-overlay flex-center-content"
                *ngIf="gtmotiveCalculationOpeningPending"
                @fadeInAndOut>
                <img src="/assets/images/icons/loading-indicator.svg" alt="" />
            </div>

            <!--********** Edit Icon **********-->
            <div
                class="calculation-pending-indicator-container"
                *ngIf="report.gtmotiveEstimateId && report.damageCalculation.repair.calculationProvider === 'gtmotive'"
                @fadeInAndOut>
                <mat-icon
                    class="calculation-pending-indicator"
                    matTooltip="Kalkulation gestartet. Bitte schließe die Kalkulation in der GT-Motive-Oberfläche ab. Zum Öffnen klicken.">
                    edit
                </mat-icon>
            </div>
        </div>
    </div>

    <div *ngIf="!report.damageCalculation.repair.calculationProvider" class="split-button flat with-shadow">
        <!--********** Default Report Type **********-->
        <div
            class="split-button-left"
            matTooltip="Kalkuliere Sonderfahrzeuge (LKW, Baumaschinen, Rennsportfahrzeuge etc.) manuell, indem du Preise aus Drittquellen einholst (z. B. Ersatzteilportale, Anfragen bei Werkstätten)."
            (click)="!isReportLocked() && showManualCalculationDialog()">
            <div>Manuelle Kalkulation</div>
        </div>

        <!--********** Other Report Types **********-->
        <div class="split-button-right" [matMenuTriggerFor]="otherCalculationProviders">
            <mat-icon class="small-icon">arrow_drop_down</mat-icon>
        </div>

        <mat-menu #otherCalculationProviders>
            <mat-option (click)="!isReportLocked() && selectCalculationProvider('estimate'); saveReport()">
                Schätzung
            </mat-option>
            <mat-option
                *ngIf="report.car.identificationProvider !== 'audatex'"
                (click)="showAudatexCalculationInsertionDialog()"
                matTooltip="Relevant, falls du kein Audatex Qapter hast, sondern:{{ '\n' }}- AudaFusion{{
                    '\n'
                }}- AudaPad{{ '\n' }}- AudaFusionWeb.basic{{ '\n' }}- AudaPadWeb.basic">
                Audatex Textimport
            </mat-option>
        </mat-menu>
    </div>
</div>
<!--============================================-->
<!-- Calculation Results -->
<!--============================================-->
<ng-container
    *ngIf="isExternalOnlineCalculationProvider() || report.damageCalculation.repair.calculationProvider === 'manual'">
    <!--============================================-->
    <!-- DAT, Audatex Qapter & manual calculation Results -->
    <!--============================================-->
    <div
        id="after-damage-calculation"
        *ngIf="
            report.damageCalculation.repair.repairCostsNet > 0 ||
            report.damageCalculation.repair.calculationProvider === 'manual'
        ">
        <table class="damage-calculation">
            <tr class="damage-calculation-row">
                <td class="damage-calculation-label">Reparaturkosten</td>
                <td class="damage-calculation-amount">
                    <span class="net-costs" matTooltip="Netto">
                        {{ report.damageCalculation.repair.repairCostsNet | number: '1.2-2' }} €
                    </span>
                    <br />
                    <span class="gross-costs label" matTooltip="Brutto">
                        {{ report.damageCalculation.repair.repairCostsGross | number: '1.2-2' }} €
                    </span>
                </td>
            </tr>
            <!--============================================-->
            <!-- New for Old -->
            <!--============================================-->
            <tr class="damage-calculation-row">
                <td
                    class="damage-calculation-label"
                    matTooltip="Angebracht, wenn der Geschädigte durch die Reparatur eine spürbare & zeitnahe Investition in das Fahrzeug spart.{{
                        '\n\n'
                    }}Ob im Haftpflichtschaden Nfa oder z. B. Vorteilsausgleich abgedruckt wird, kannst du in den Textbausteineinstellungen des Bausteins Zusammenfassung Haftpflichtschaden festlegen.">
                    Abzüge Neu-für-alt
                </td>
                <td
                    class="damage-calculation-amount"
                    [ngClass]="{
                        'negative-value': report.damageCalculation.repair.newForOldNet > 0,
                        'neutral-value': report.damageCalculation.repair.newForOldNet === 0,
                    }">
                    <span class="net-costs" matTooltip="Netto">
                        {{ report.damageCalculation.repair.newForOldNet * -1 | number: '1.2-2' }} €
                    </span>
                    <br />
                    <span class="gross-costs label" matTooltip="Brutto">
                        {{ report.damageCalculation.repair.newForOldGross * -1 | number: '1.2-2' }} €
                    </span>
                </td>
            </tr>
            <!--============================================-->
            <!-- END New for Old -->
            <!--============================================-->

            <!--============================================-->
            <!-- Increased Value -->
            <!--============================================-->
            <tr class="damage-calculation-row" *ngIf="report.damageCalculation.repair.increasedValue">
                <td
                    class="damage-calculation-label"
                    matTooltip="Angebracht, wenn der Gesamtwert des Fahrzeugs durch die Reparatur wesentlich steigt.">
                    Wertverbesserung
                </td>
                <td class="damage-calculation-amount">
                    <span
                        class="net-costs"
                        [ngClass]="{
                            'negative-value': report.damageCalculation.repair.increasedValue > 0,
                            'neutral-value': report.damageCalculation.repair.increasedValue === 0,
                        }">
                        {{ report.damageCalculation.repair.increasedValue * -1 | number: '1.2-2' }} €
                    </span>
                    <br />
                    <span
                        class="gross-costs label"
                        [ngSwitch]="report.damageCalculation.repair.increasedValueTaxationType">
                        <!--********** gross **********-->
                        <span *ngSwitchCase="'full'" matTooltip="Brutto">
                            {{ report.damageCalculation.repair.increasedValue * fullTaxationRate }}
                        </span>

                        <!--********** net **********-->
                        <ng-container *ngSwitchCase="'net'">netto</ng-container>

                        <!--********** tax neutral **********-->
                        <ng-container
                            *ngSwitchCase="'neutral'"
                            matTooltip="Die Wertverbesserung ist das Gegenstück zur Wertminderung. Weil kein Leistungsaustausch stattfindet, sind beide Werte steuerneutral, d. h. sie verursachen keine MwSt. und werden zum gleichen Betrag von Brutto- und Nettowerten abgezogen.">
                            steuerneutral
                        </ng-container>
                    </span>
                </td>
            </tr>
            <!--============================================-->
            <!-- END Increased Value -->
            <!--============================================-->

            <tr class="damage-calculation-row" *ngIf="report.damageCalculation.repair.discountNet > 0">
                <td class="damage-calculation-label">Abzgl. Rabatte/Vergütungen</td>
                <td
                    class="damage-calculation-amount"
                    [ngClass]="{
                        'negative-value': report.damageCalculation.repair.discountNet > 0,
                        'neutral-value': report.damageCalculation.repair.discountNet === 0,
                    }">
                    <span class="net-costs" matTooltip="Netto">
                        {{ report.damageCalculation.repair.discountNet * -1 | number: '1.2-2' }} €
                    </span>
                    <br />
                    <span id="total-discount-gross-costs" class="gross-costs label" matTooltip="Brutto">
                        {{ report.damageCalculation.repair.discountGross * -1 | number: '1.2-2' }} €
                    </span>
                </td>
            </tr>
            <tr class="damage-calculation-row">
                <td class="damage-calculation-label">Summe</td>
                <td
                    class="damage-calculation-amount"
                    [ngClass]="{
                        'negative-value': report.damageCalculation.repair.correctedRepairCostsNet < 0,
                    }">
                    <span class="net-costs" matTooltip="Netto">
                        {{ report.damageCalculation.repair.correctedRepairCostsNet | number: '1.2-2' }} €
                    </span>
                    <br />
                    <span class="gross-costs label" matTooltip="Brutto">
                        {{ report.damageCalculation.repair.correctedRepairCostsGross | number: '1.2-2' }} €
                    </span>
                </td>
            </tr>
        </table>

        <!--***********************************************
        ** GT Motive New-for-old Note
        ************************************************-->
        <div
            id="info-note-gtmotive-new-for-old"
            class="info-note label"
            *ngIf="
                report.damageCalculation.repair.newForOldNet &&
                report.damageCalculation.repair.calculationProvider === 'gtmotive'
            ">
            <mat-icon class="medium-icon">info</mat-icon>
            <div>
                <h3>GT Motive PDF</h3>
                Der Neu-für-alt-Abzug steht zwar unter "Abwertungen ges.", wird aber nicht von der fett gedruckten
                Gesamtsumme (Reparaturkosten) abgezogen.
                <br />
                <br />
                In deinem autoiXpert-Gutachten werden aber Reparaturkosten, Neu-für-alt und korrigierte Reparaturkosten
                übersichtlich abgedruckt.
            </div>
        </div>
        <!--***********************************************
        ** END GT Motive New-for-old Note
        ************************************************-->

        <div class="damage-total-large">
            <div class="damage-total-large-label" *ngIf="!report.damageCalculation.repair.isApproximateCalculation">
                <ng-container
                    *ngIf="
                        report.damageCalculation.repair.repairCostsNet !==
                        report.damageCalculation.repair.correctedRepairCostsNet
                    ">
                    Korrigierte
                </ng-container>
                Reparaturkosten
            </div>
            <div class="damage-total-large-label" *ngIf="report.damageCalculation.repair.isApproximateCalculation">
                Überschlägige Reparaturkosten
            </div>
            <div class="damage-total-large-amount" matTooltip="Netto">
                {{ report.damageCalculation.repair.correctedRepairCostsNet || 0 | number: '1.2-2' }} €
                <mat-icon id="open-repair-cost-input-icon" [matMenuTriggerFor]="editRepairCostMenu">more_vert</mat-icon>
            </div>
            <mat-menu #editRepairCostMenu>
                <a mat-menu-item class="menu-item-with-icon" (click)="toggleEditRepairCostsInput()">
                    <mat-icon>edit</mat-icon>
                    Reparaturkosten überschreiben
                </a>
                <a
                    mat-menu-item
                    class="menu-item-with-icon"
                    *ngIf="areRepairCostsChanged()"
                    (click)="resetOverwrittenRepairCosts()">
                    <mat-icon>delete</mat-icon>
                    Reparaturkosten zurücksetzen
                </a>
            </mat-menu>
            <div class="damage-total-large-amount-gross label" matTooltip="Brutto">
                {{ report.damageCalculation.repair.correctedRepairCostsGross || 0 | number: '1.2-2' }}
                €
            </div>
            <div
                class="edit-repair-cost-container"
                *ngIf="!report.damageCalculation.repair.isApproximateCalculation && editRepairCostsInput">
                <currency-input
                    placeholder="Reparaturkosten (brutto)"
                    [(value)]="editedRepairCostsInputValue"
                    [disabled]="isReportLocked()"></currency-input>
                <mat-icon
                    id="confirm-repair-cost-input-icon"
                    class="input-toolbar-icon"
                    (click)="overwriteRepairCosts(editedRepairCostsInputValue)">
                    check
                </mat-icon>
                <mat-icon
                    id="close-repair-cost-input-icon"
                    class="input-toolbar-icon"
                    (click)="toggleEditRepairCostsInput()">
                    close
                </mat-icon>
            </div>
        </div>
        <div
            class="report-type-switcher label"
            *ngIf="
                report.type === 'shortAssessment' && report.damageCalculation.repair.correctedRepairCostsGross > 700
            ">
            <span>Die Reparaturkosten liegen oberhalb von 700 €.</span>
            <br />
            <a href="javascript:void(0)" (click)="convertReportType('liability')">
                Zu Haftpflichtschaden konvertieren?
            </a>
            <span class="help-indicator" matTooltip="Nicht benötigte Angaben werden entfernt">?</span>
        </div>
        <div
            class="report-type-switcher label"
            *ngIf="report.type === 'liability' && report.damageCalculation.repair.correctedRepairCostsGross < 1000">
            <span>Die Reparaturkosten liegen unterhalb von 1.000 €.</span>
            <br />
            <a href="javascript:void(0)" (click)="convertReportType('shortAssessment')">
                Zu Kurzgutachten konvertieren?
            </a>
            <span
                class="help-indicator"
                matTooltip="Nicht benötigte Angaben werden entfernt, z. B. Restwertdaten und Marktwertanalysen">
                ?
            </span>
        </div>
    </div>
    <!--********** Loading overlay **********-->
    <div
        id="external-damage-calculation-loading-screen"
        *ngIf="datDamageCalculationPending || audatexImportExportPending || gtmotiveCalculationImportExportPending">
        <h2 class="loading-indicator-text" *ngIf="datDamageCalculationPending">Warte auf DAT...</h2>
        <h2 class="loading-indicator-text" *ngIf="audatexImportExportPending">Warte auf Audatex...</h2>
        <h2 class="loading-indicator-text" *ngIf="gtmotiveCalculationImportExportPending">Warte auf GT Motive...</h2>
    </div>
    <!--============================================-->
    <!-- END DAT & manual calculation Results -->
    <!--============================================-->
</ng-container>
<!--============================================-->
<!-- END Calculation Results -->
<!--============================================-->

<!--============================================-->
<!-- Audatex Offline Calculation & Estimate -->
<!--============================================-->
<div
    id="audatex-calculation-container"
    *ngIf="
        report.damageCalculation.repair.calculationProvider === 'audatex-textimport' ||
        report.damageCalculation.repair.calculationProvider === 'estimate'
    ">
    <div
        id="audatex-calculation-logo-container"
        *ngIf="report.damageCalculation.repair.calculationProvider === 'audatex-textimport'">
        <img src="/assets/images/logos/audatex.svg" alt="Audatex-Logo" />
    </div>

    <!--============================================-->
    <!-- Simple Repair Cost Estimate View -->
    <!--============================================-->
    <!--********** Total Repair Costs Net - Only display in simple layout **********-->
    <div class="calculation-input-container" *ngIf="report.damageCalculation.repair.useSimpleEstimateCalculation">
        <mat-icon class="calculation-input-icon">build</mat-icon>
        <currency-input
            placeholder="Reparaturkosten"
            class="mat-block"
            [(value)]="report.damageCalculation.repair.repairCostsNet"
            (valueChange)="calculateTotalsAndGrossValues(); emitValueChange()"
            name="lacquer-costs"
            [disabled]="isReportLocked()"></currency-input>

        <!--********** Split Fields Icon **********-->
        <mat-icon
            class="calculation-input-toolbar-icon toolbar-icon"
            *ngIf="!isReportLocked()"
            (click)="toggleRepairCostEstimateView()"
            matTooltip="Reparaturkosten separat nach Ersatzteilen, Lohn und Lackierung schätzen">
            call_split
        </mat-icon>
    </div>
    <!--============================================-->
    <!-- END Simple Repair Cost Estimate View -->
    <!--============================================-->

    <!--============================================-->
    <!-- Detailed Repair Cost Estimate View -->
    <!--============================================-->
    <!--********** Spare Parts Costs Net **********-->
    <div class="calculation-input-container" *ngIf="!report.damageCalculation.repair.useSimpleEstimateCalculation">
        <mat-icon class="calculation-input-icon">local_mall</mat-icon>
        <currency-input
            placeholder="Ersatzteile"
            class="mat-block"
            [(value)]="report.damageCalculation.repair.sparePartsCostsNet"
            (valueChange)="calculateTotalsAndGrossValues(); emitValueChange()"
            name="spare-parts-costs"
            [disabled]="isReportLocked()"></currency-input>

        <!--********** Merge Fields Icon **********-->
        <mat-icon
            class="calculation-input-toolbar-icon toolbar-icon"
            *ngIf="!isReportLocked()"
            (click)="toggleRepairCostEstimateView()"
            matTooltip="Reparaturkosten in einem einzigen Feld schätzen">
            call_merge
        </mat-icon>
    </div>

    <!--********** Labor Costs Net **********-->
    <div class="calculation-input-container" *ngIf="!report.damageCalculation.repair.useSimpleEstimateCalculation">
        <mat-icon class="calculation-input-icon">person</mat-icon>
        <currency-input
            placeholder="Arbeitslohn"
            class="mat-block"
            [(value)]="report.damageCalculation.repair.garageLaborCostsNet"
            (valueChange)="calculateTotalsAndGrossValues(); emitValueChange()"
            name="labor-costs"
            [disabled]="isReportLocked()"></currency-input>
    </div>

    <!--********** Lacquer Costs Net **********-->
    <div class="calculation-input-container" *ngIf="!report.damageCalculation.repair.useSimpleEstimateCalculation">
        <mat-icon class="calculation-input-icon">palette</mat-icon>
        <currency-input
            placeholder="Lackierung"
            class="mat-block"
            [(value)]="report.damageCalculation.repair.lacquerCostsNet"
            (valueChange)="calculateTotalsAndGrossValues(); emitValueChange()"
            name="lacquer-costs"
            [disabled]="isReportLocked()"></currency-input>
    </div>

    <!--********** Auxiliary Costs Net **********-->
    <div class="calculation-input-container" *ngIf="!report.damageCalculation.repair.useSimpleEstimateCalculation">
        <mat-icon class="calculation-input-icon">view_headline</mat-icon>
        <currency-input
            placeholder="Nebenkosten"
            class="mat-block"
            [(value)]="report.damageCalculation.repair.auxiliaryCostsNet"
            (valueChange)="calculateTotalsAndGrossValues(); emitValueChange()"
            name="auxiliary-costs"
            [disabled]="isReportLocked()"></currency-input>
    </div>
    <!--============================================-->
    <!-- END Detailed Repair Cost Estimate View -->
    <!--============================================-->

    <!--********** Value Increase **********-->
    <div class="calculation-input-container">
        <mat-icon class="calculation-input-icon">trending_up</mat-icon>
        <currency-input
            placeholder="Neu-für-alt-Abzüge"
            class="mat-block"
            [(value)]="report.damageCalculation.repair.newForOldNet"
            (valueChange)="calculateTotalsAndGrossValues(); emitValueChange()"
            name="value-increase"
            [disabled]="isReportLocked()"></currency-input>
    </div>

    <!--********** Discounts **********-->
    <div class="calculation-input-container">
        <mat-icon class="calculation-input-icon">euro_symbol</mat-icon>
        <currency-input
            placeholder="Rabatte/Vergütungen"
            class="mat-block"
            [(value)]="report.damageCalculation.repair.discountNet"
            name="total-discount"
            (valueChange)="calculateTotalsAndGrossValues(); emitValueChange()"
            [disabled]="isReportLocked()"></currency-input>
    </div>

    <div class="damage-total-large">
        <div
            class="damage-total-large-label"
            [matTooltip]="areRepairCostsCorrected() ? 'Reparaturkosten abzüglich Neu-für-alt-Abzüge & Rabatte' : ''">
            <ng-container *ngIf="areRepairCostsCorrected()">Korrigierte</ng-container>
            Reparaturkosten
        </div>
        <div class="damage-total-large-amount" matTooltip="Netto">
            {{ report.damageCalculation.repair.correctedRepairCostsNet || 0 | number: '1.2-2' }} €
        </div>
        <div class="damage-total-large-amount-gross label" matTooltip="Brutto">
            {{ report.damageCalculation.repair.correctedRepairCostsGross || 0 | number: '1.2-2' }}€
        </div>
    </div>
</div>
<!--============================================-->
<!-- END Audatex Offline Calculation & Estimate -->
<!--============================================-->

<!--***********************************************
            ** Repair Calculation Comment
            ************************************************-->
<div id="repair-calculation-comment-container" *ngIf="repairCalculationCommentShown">
    <mat-form-field class="mat-block">
        <mat-quill
            [(ngModel)]="report.damageCalculation.repair.calculationComment"
            placeholder="Kommentar Kalkulation"
            [disabled]="isReportLocked()"
            (change)="saveReport()"></mat-quill>
        <mat-icon
            matSuffix
            *ngIf="!isReportLocked()"
            class="input-toolbar-icon"
            (click)="repairCalculationCommentTextTemplateSelectorShown = true"
            matTooltip="Textvorlagen">
            playlist_add
        </mat-icon>
    </mat-form-field>
    <text-template-selector
        [(text)]="report.damageCalculation.repair.calculationComment"
        (textChange)="saveReport()"
        [report]="report"
        *ngIf="repairCalculationCommentTextTemplateSelectorShown"
        placeholderForResultingText="Kommentar Kalkulation"
        (close)="repairCalculationCommentTextTemplateSelectorShown = false"
        [textTemplateType]="'repairCalculationComment'"
        @runChildAnimations></text-template-selector>
</div>
<!--***********************************************
            ** END Repair Calculation Comment
            ************************************************-->

<!--***********************************************
            ** IncreasedValue Recommendation
            ************************************************-->
<div
    id="info-note-value-increase"
    class="info-note warning-note label"
    *ngIf="
        report.damageCalculation.repair.increasedValue &&
        report.damageCalculation.repair.increasedValueTaxationType !== 'neutral'
    ">
    <mat-icon class="medium-icon">warning</mat-icon>
    <div>
        <h3>Wertverbesserung</h3>
        Die Kalkulation beinhaltet eine Wertverbesserung, die in der Kalkulation nicht als steuerneutral markiert wurde.
        Das ist fachlich inkorrekt. Markiere sie in der Kalkulation als steuerneutral oder entferne sie in der
        Kalkulation und trage sie in autoiXpert ein, wo sie ebenfalls als steuerneutral behandelt wird.
        <br />
        <a
            href="https://wissen.autoixpert.de/hc/de/articles/4404973758610-Wertverbesserung-in-autoiXpert-eintragen"
            target="_blank"
            rel="”noopener”">
            Mehr Informationen.
        </a>
    </div>
</div>
<!--***********************************************
            ** END IncreasedValue Recommendation
            ************************************************-->

<!--***********************************************
            ** Invalid Repair Calculation Positions
            ************************************************-->
<div
    id="info-note-invalid-repair-calculation-positions"
    class="info-note error-note small-note"
    *ngIf="report.damageCalculation.repair.hasInvalidPositions">
    <mat-icon class="medium-icon">error</mat-icon>
    <div>
        <h3>Ungültige Positionen in Kalkulation</h3>
        Öffne die Kalkulation erneut, um die Widersprüche aufzulösen. Die DAT zeigt ungültige Positionen auf der letzten
        Seite an.
        <br />
        <br />

        <strong>Beispiel:</strong>
        Ein Bauteil einer Sonderausstattung soll ersetzt werden, in den Ausstattungen wurde diese Sonderausstattung aber
        nicht gewählt.
        <br />
        <br />
    </div>
</div>
<!--***********************************************
            ** END Invalid Repair Calculation Positions
            ************************************************-->

<!--********** DAT Downtime warning **********-->
<context-sensitive-downtime-notification
    childClass="margin-top-30"
    *ngIf="
        report.car.identificationProvider === 'dat' &&
        (!report.damageCalculation.repair.calculationProvider ||
            report.damageCalculation.repair.calculationProvider === 'dat')
    "
    [processContext]="'calculation'"></context-sensitive-downtime-notification>

<!--============================================-->
<!-- Dialogs -->
<!--============================================-->
<!--********** Audatex Calculation Copy & Paste Dialog **********-->
<audatex-calculation-insertion-dialog
    [(calculationText)]="audatexCalculationText.content"
    (calculationTextChange)="
        parseAudatexCalculation();
        selectCalculationProvider('audatex-textimport');
        saveAudatexCalculation();
        emitValueChange()
    "
    *ngIf="audatexCalculationText && audatexCalculationInsertionDialogShown"
    (close)="hideAudatexCalculationInsertionDialog()"></audatex-calculation-insertion-dialog>

<!--********** Manual Calculation **********-->
<manual-calculation-dialog
    *ngIf="manualCalculationDialogShown"
    [report]="report"
    (reportChange)="saveReport()"
    (close)="
        hideManualCalculationDialog(); createDamageCalculationReportDocument(); emitValueChange()
    "></manual-calculation-dialog>

<!--********** GT Motive **********-->
<connect-gtmotive-estimate-dialog
    *ngIf="connectGtmotiveEstimateDialogShown"
    [licensePlate]="report.car.licensePlate"
    [vin]="report.car.vin"
    (close)="connectGtmotiveEstimateDialogShown = false"
    (gtmotiveEstimateSelection)="handleGtmotiveEstimateConnection($event)"
    @runChildAnimations></connect-gtmotive-estimate-dialog>
<!--============================================-->
<!-- END Dialogs -->
<!--============================================-->

<!--============================================-->
<!-- Custom Fields -->
<!--============================================-->
<custom-field-display
    class="margin-top-20"
    fieldLocation="damage-calculation_calculation"
    [report]="report"
    [useNegativeSideMarginForCards]="true"
    (reportChange)="saveReport()"
    [disabled]="isReportLocked()"></custom-field-display>
<!--============================================-->
<!-- END Custom Fields -->
<!--============================================-->

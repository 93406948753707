/**
 * Determine that image path for the car contour image. Use each predefined car shape's ID or return the image of a sedan if
 * the user entered an unknown shape.
 * @param carShape
 * @returns {string}
 */
import { CarShape } from '@autoixpert/models/reports/car-identification/car';

export function getCarContourImageName(carShape: CarShape): string {
    let imageName: string;

    switch (carShape) {
        case 'sedan':
        case 'compact':
        case 'coupe':
        case 'stationWagon':
        case 'suv':
        case 'convertible':
        case 'van':
        case 'transporter':
        case 'pickup':
        case 'motorcycle':
        case 'motorHome':
        case 'trailer':
        case 'caravanTrailer':
        case 'semiTruck':
        case 'semiTrailer':
        case 'bus':
        case 'truck':
            imageName = carShape;
            break;
        case 'bicycle':
        case 'e-bike':
        case 'pedelec':
            imageName = 'bicycle';
            break;
        default:
            imageName = 'sedan';
    }

    return imageName;
}

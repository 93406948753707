import { Component, HostBinding, Input } from '@angular/core';

/**
 * A wand icon and vertical line that indicate that the data next to it has been automatically
 * determined by the software. The indicator positions itself absolute to the right outside of it's the parent.
 * It spans the whole height from the top to the bottom of the parent.
 * To use this component, either give the parent element a position value (e.g. relative) or use
 * the directive augmentedDataIndicator which does this for you.
 */
@Component({
    selector: 'augmented-data-indicator',
    templateUrl: './augmented-data-indicator.component.html',
    styleUrls: ['./augmented-data-indicator.component.scss'],
})
export class AugmentedDataIndicatorComponent {
    /**
     * Optional offset to the top. Useful if the vertical line should not reach
     * or surpass the top of the parent.
     */
    @HostBinding('style.top') offsetTop: CssNumberOrNumberWithUnit = 0;

    /**
     * Optional offset to the bottom. Useful if the vertical line should not reach
     * the bottom of the parent.
     */
    @HostBinding('style.bottom') offsetBottom: CssNumberOrNumberWithUnit = 0;

    /**
     * Optional tooltip to display when the user hovers the wand icon.
     */
    @Input() tooltip: string = 'Daten wurden automatisch eingefügt.';
}

export type CssNumberOrNumberWithUnit = number | string;

import { todayIso } from '../../lib/date/iso-date';
import { IsoDate } from '../../lib/date/iso-date.types';
import { generateId } from '../../lib/generate-id';
import { AudatexCalculationConfigCode } from './audatex-calculation-config-code';
import { WorkFractionUnit } from './work-fraction-unit';

export class GarageFeeSet {
    constructor(template: Partial<GarageFeeSet> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    title: string;
    validFrom: IsoDate = todayIso();
    isDefault: boolean;

    selectedWorkFractionUnit: WorkFractionUnit = 1; // Possible values: 1, 10, 12, 100
    mechanics: GarageWageLevels = {
        firstLevel: null,
        secondLevel: null,
        thirdLevel: null,
    };
    electrics: GarageWageLevels = {
        firstLevel: null,
        secondLevel: null,
        thirdLevel: null,
    };
    carBody: GarageWageLevels = {
        firstLevel: null,
        secondLevel: null,
        thirdLevel: null,
    };
    carPaint: {
        paintSystem: PaintSystem;
        wage: number;
        materialSurchargeUnit: PaintMaterialSurchargeUnit; // Unified for all paint surcharges.
        materialSurchargeDefault: number;
        materialSurchargesByPaintType: PaintMaterialSurcharge[];
    } = {
        paintSystem: 'manufacturer',
        wage: null,
        materialSurchargeUnit: 'percent',
        materialSurchargeDefault: null,
        materialSurchargesByPaintType: [],
    };
    transport: GarageFeeSetTransport = new GarageFeeSetTransport();
    dentsWage: number = null;
    sparePartsSurcharge: number; // Surcharge for storing and getting spare parts. Usually around 15%. Stored as decimal, so 15% = 0.15;
    smallPartsSurcharge: number;
    smallPartsUnit: 'percent' | 'flatFee' = 'percent';

    /**
     * Manual calculation items may be added to a calculation by hand. Temporary things like corona disinfection fees or energy surcharges may be entered here.
     */
    customCalculationItems: CustomCalculationItem[] = [];
    audatexConfigCodes: AudatexCalculationConfigCodeAndValue[] = [];
}

export interface GarageWageLevels {
    firstLevel: number; // In case of AWs, this field contains e.g. 16 € instead of the hourly wage of 160 €.
    secondLevel: number;
    thirdLevel: number;
}

export class GarageFeeSetTransport {
    constructor(template: Partial<GarageFeeSetTransport> = {}) {
        Object.assign(this, template);
    }
    calculationType: 'none' | 'mechanics' | 'electrics' | 'carBody' | 'fixedPrice' = 'mechanics';
    timeRequired: number = 1.5;
    fixedPrice: number = null;
}

/**
 * For different paint types, a specific material surcharge may be set.
 * If the paint type matches the one from the report, this overrides the default.
 */
export class PaintMaterialSurcharge {
    constructor(template: Partial<PaintMaterialSurcharge> = {}) {
        Object.assign(this, template);
    }

    _id: string = generateId();
    paintType: 'uni' | 'metallic' | 'perlcolor' = 'uni';
    value: number;
}

/**
 * Unified for all paint surcharges.
 * materialPointsOrUnits = Materialpunkte bzw. Materialeinheiten; same logic but different names in Eurolack and Manufacturer paint systems.
 */
export type PaintMaterialSurchargeUnit = 'percent' | 'materialIndex' | 'materialPointsOrUnits' | 'flatFee';

export type PaintSystem = 'allianzCenterForTechnology' | 'manufacturer' | 'eurolack';

export class CustomCalculationItem {
    _id: string = generateId();
    title: string;
    valueNet: number;
}

/**
 * German "Randfahnencode". Used to configure the calculation of Audatex.
 */
export class AudatexCalculationConfigCodeAndValue {
    constructor(template: Partial<AudatexCalculationConfigCodeAndValue> = {}) {
        Object.assign(this, template);
    }
    _id: string = generateId();
    numericalCode: number;
    title: string;
    valueType: AudatexCalculationConfigCode['valueType']; // Determines which HTML input type is used for this item's value.
    value: number;
}

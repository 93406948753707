import { generateId } from '@autoixpert/lib/generate-id';
import { Photo, PhotoConfiguration } from '@autoixpert/models/reports/damage-description/photo';
import { getHtmlImageFromFileOrBlob } from './get-html-image-from-file-or-blob';

/**
 * Resize a given photo file to a given target width.
 *
 * This function is used when uploading photos from the file system into the browser
 * to reduce the photo file size to the least possible size.
 * @param photoFile
 */
export async function getPhotoFromFile(photoFile: File, orderPosition): Promise<Photo> {
    const image: HTMLImageElement = await getHtmlImageFromFileOrBlob(photoFile);

    return new Photo({
        _id: generateId(),
        orderPosition,
        title: photoFile.name,
        originalName: photoFile.name,
        description: null,
        mimetype: photoFile.type,
        versions: new PhotoConfiguration(),
        size: photoFile.size,
        height: image.height,
        width: image.width,
    });
}

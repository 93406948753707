import { addClaimantAndLicensePlateToDocumentTitle } from '@autoixpert/lib/document-title/add-claimant-and-license-plate-to-document-title';
import { PlaceholderValues } from '@autoixpert/lib/placeholder-values/get-placeholder-values';
import { FieldGroupConfig } from '@autoixpert/models/custom-fields/field-group-config';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { UnprocessableEntity } from '@autoixpert/models/errors/ax-error';
import { FileNamePattern } from '@autoixpert/models/file-name-patterns/file-name-pattern';
import { Report } from '@autoixpert/models/reports/report';
import { replacePlaceholders } from './replace-document-building-block-placeholders';

/**
 * Generate the file name when a user downloads it. Hierarchy:
 * - explicitly set by the user
 * - set through a file pattern
 * - default title for the document type
 */
export function getDocumentDownloadName({
    documentMetadata,
    fileExtension,
    report,
    fileNamePatterns,
    placeholderValues,
    fieldGroupConfigs,
}: {
    documentMetadata: DocumentMetadata;
    fileExtension: 'pdf' | 'docx';
    report: Report;
    fileNamePatterns: FileNamePattern[];
    placeholderValues: PlaceholderValues;
    fieldGroupConfigs: FieldGroupConfig[];
}): string {
    // Manually Uploaded Pdf -> Use title.
    if (documentMetadata.uploadedDocumentId) {
        return `${documentMetadata.title}.${fileExtension}`;
    }

    //*****************************************************************************
    //  File Pattern
    //****************************************************************************/
    {
        const matchingFileNamePatterns: FileNamePattern[] = fileNamePatterns.filter(
            (fileNamePattern) =>
                fileNamePattern.documentType === documentMetadata.type &&
                fileNamePattern.customDocumentOrderConfigId == documentMetadata.customDocumentOrderConfigId &&
                fileNamePattern.reportTypes.includes(report.type),
        );

        if (matchingFileNamePatterns.length > 1) {
            throw new UnprocessableEntity({
                code: 'MULTIPLE_MATCHING_FILE_NAME_PATTERNS_FOUND',
                message:
                    'Please make sure there is only one file name pattern available for each combination of report type and document type.',
                data: {
                    documentType: documentMetadata.type,
                    reportType: report?.type,
                },
            });
        }
        const matchingFileNamePattern: FileNamePattern = matchingFileNamePatterns[0];

        if (matchingFileNamePattern) {
            const basename = replacePlaceholders({
                textWithPlaceholders: matchingFileNamePattern.pattern,
                placeholderValues,
                fieldGroupConfigs,
                isHtmlAllowed: false,
            })
                .replaceAll(/\s+/g, ' ')
                .trim();
            return `${basename}.${fileExtension}`;
        }
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END File Pattern
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Default Document Name
    //****************************************************************************/
    if (report) {
        return addClaimantAndLicensePlateToDocumentTitle(documentMetadata.title, report, fileExtension);
    } else {
        return `${documentMetadata.title}.${fileExtension}`;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Default Document Name
    /////////////////////////////////////////////////////////////////////////////*/
}

<!--============================================-->
<!-- Categories -->
<!--============================================-->
<link rel="stylesheet" href="preferences-overview.component.scss" />
<div id="categories-container" class="card dark-card">
    <div id="categories-heading-container">
        <user-selection-dropdown
            [theme]="'dark'"
            [selectedUser]="selectedUser"
            [loggedInUserIsFirstResult]="true"
            [isDisabled]="!userIsAdmin() || team.members.length <= 1"
            (userSelected)="selectUser($event, { showToast: false })"></user-selection-dropdown>
    </div>
    <div id="categories-list">
        <!--********** My Account **********-->
        <a (click)="scrollIntoView('#personal-data-container')" class="categories-list-heading">Mein Account</a>
        <!-- Partner accounts are test accounts without an expiration date. Don't show the order entry for them. -->
        <a
            (click)="scrollIntoView('#place-order-section')"
            class="categories-list-item"
            *ngIf="team?.accountStatus === 'test' && team.expirationDate && userIsAdmin()">
            Bestellen
        </a>
        <a (click)="scrollIntoView('#personal-data-container')" class="categories-list-item">Persönliche Daten</a>
        <a (click)="scrollIntoView('#email-settings-container')" class="categories-list-item">E-Mail</a>
        <a (click)="scrollIntoView('#signature-and-stamp-container')" class="categories-list-item">
            Unterschrift & Stempel
        </a>

        <!--********** Connected Applications **********-->
        <a (click)="scrollIntoView('#dat-container')" class="categories-list-heading">Schnittstellen</a>
        <a (click)="scrollIntoView('#calculation-providers-section')" class="categories-list-item">Kalkulation</a>
        <a (click)="scrollIntoView('#residual-and-market-value-exchanges-section')" class="categories-list-item">
            Markt- & Restwerte
        </a>
        <a (click)="scrollIntoView('#afzzert-container')" class="categories-list-item">Altfahrzeugzertifikate</a>
        <a (click)="scrollIntoView('#factoring-provider-container')" class="categories-list-item">Factoring</a>
        <a
            (click)="scrollIntoView('#adelta-container')"
            class="categories-list-item"
            *ngIf="selectedUser?.adeltaFinanzUser?.customerNumber || getCurrentFactoringProvider() === 'adelta'">
            Adelta.Finanz
        </a>
        <a
            (click)="scrollIntoView('#kfzvs-container')"
            class="categories-list-item"
            *ngIf="selectedUser?.kfzvsUser?.customerNumber || getCurrentFactoringProvider() === 'kfzvs'">
            KfzVS
        </a>
        <a
            (click)="scrollIntoView('#persaldo-container')"
            class="categories-list-item"
            *ngIf="selectedUser?.persaldoUser?.username || getCurrentFactoringProvider() === 'persaldo'">
            Goya Mobility
        </a>
        <a
            (click)="scrollIntoView('#crashback24-container')"
            class="categories-list-item"
            *ngIf="selectedUser?.crashback24User?.username">
            crashback24
        </a>
        <a (click)="scrollIntoView('#gdv-container')" class="categories-list-item" *ngIf="selectedUser?.gdvUser">GDV</a>

        <!--********** My Team **********-->
        <!--********** The user is sent to the section "team-members" from other components via a query parameter in the URL, so keep it short (no "-container") **********-->
        <a (click)="scrollIntoView('#team-members')" class="categories-list-heading">Mein Team</a>
        <a (click)="scrollIntoView('#team-members')" class="categories-list-item">Nutzer</a>
        <a (click)="scrollIntoView('#report-token-container')" class="categories-list-item">Aktenzeichen</a>
        <a (click)="scrollIntoView('#invoice-number-container')" class="categories-list-item">Rechnung</a>
        <a
            (click)="scrollIntoView('#autoixpert-payment-info-container')"
            class="categories-list-item"
            *ngIf="
                team &&
                (team.accountStatus !== 'test' || team.becameCustomerAt) &&
                !team.audatexFeatures?.qapterixpertLayout &&
                userIsAdmin()
            ">
            Abrechnung aX
        </a>
        <a
            (click)="scrollIntoView('#document-layout-container')"
            class="categories-list-item"
            *ngIf="team && userIsAdmin()">
            Layout Dokumente
        </a>
        <a (click)="scrollIntoView('#team-logo-container')" class="categories-list-item" *ngIf="team && userIsAdmin()">
            Logo
        </a>
        <a
            (click)="scrollIntoView('#signable-pdf-documents-container')"
            class="categories-list-item"
            *ngIf="team && userIsAdmin()">
            Kundenunterschriften
        </a>

        <a (click)="navigateToExternalApiSettings()" class="categories-list-item" *ngIf="userIsAdmin()">Externe API</a>

        <a
            (click)="navigateToDocumentBuildingBlockList()"
            class="categories-list-item"
            *ngIf="selectedUser?.accessRights.editTextsAndDocumentBuildingBlocks">
            Textbausteine
        </a>
    </div>
</div>
<!--============================================-->
<!-- END Categories -->
<!--============================================-->

<!--============================================-->
<!-- Preferences -->
<!--============================================-->
<div id="preferences-container">
    <!--============================================-->
    <!-- Place Order -->
    <!--============================================-->
    <section
        id="place-order-section"
        class="card card-with-padding preference-section-container background-url-ornament-bottom-full-width"
        *ngIf="team && team.accountStatus === 'test' && !team.becameCustomerAt">
        <h2>Account bestellen</h2>
        <div id="remaining-test-days-container" class="slim-progress-bar-row">
            <!--********** Start Date **********-->
            <div
                id="test-start-date"
                class="label"
                matTooltip="{{ team.createdAt | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                {{ team.createdAt | moment: 'DD. MMM' }}
            </div>

            <!--********** Progress Bar **********-->
            <div id="remaining-test-days-progress-bar" class="slim-progress-bar-background">
                <div
                    class="slim-progress-bar-fill"
                    [style.width]="getWidthOfRemainingDaysProgressBar()"
                    [ngClass]="{
                        warning: getRemainingTestDays() <= 7,
                        'test-expired': getRemainingTestDays() < 0,
                    }"></div>
            </div>

            <!--********** End Date **********-->
            <div
                id="test-end-date"
                class="label"
                matTooltip="{{ team.expirationDate | moment: 'DD.MM.YYYY - HH:mm [Uhr]' }}">
                {{ team.expirationDate | moment: 'DD. MMM' }}
            </div>
        </div>

        <strong>
            <!-- Test already endend? -->
            <ng-container *ngIf="getRemainingTestDays() < 0">Dein Test ist abgelaufen. Weitermachen?</ng-container>

            <!-- Test ends today -->
            <ng-container *ngIf="getRemainingTestDays() === 0">Dein Test endet heute. Weitermachen?</ng-container>

            <!-- Test ends tomorrow -->
            <ng-container *ngIf="getRemainingTestDays() === 1">Dein Test endet morgen. Weitermachen?</ng-container>

            <!-- Test ends in a few days -->
            <ng-container *ngIf="getRemainingTestDays() > 1 && getRemainingTestDays() <= 7">
                Dein Test endet in {{ getRemainingTestDays() }} Tagen. Weitermachen?
            </ng-container>

            <!-- Still more than a week available -->
            <ng-container *ngIf="getRemainingTestDays() > 7">
                Dein Test läuft noch {{ getRemainingTestDays() }} Tage. Viel Erfolg!
            </ng-container>
        </strong>

        <ng-container *ngIf="!isQapterixpertTeam()">
            <div id="place-order-buttons">
                <button (click)="openSubscriptionDialog({ displayFullBooking: true })">Preise & Bestellung</button>
            </div>
            <!-- prettier-ignore -->
            <p class="label place-order-label">
                Preise & Kontakt für
                <a href="https://www.autoixpert.de/Dat-Preisrechner" target="_blank" rel="noopener">DAT</a>,
                <a href="https://www.autoixpert.de/Audatex-Preisrechner" target="_blank" rel="noopener">Audatex</a>.
                Oder: <a href="https://www.autoixpert.de/Gesamtkostenrechner" target="_blank" rel="noopener">Gesamtpreisrechner</a>.
            </p>
        </ng-container>
        <ng-container *ngIf="isQapterixpertTeam()">
            <p class="label">
                Sobald du mit Qapter-iXpert durchstarten möchtest, kontaktiere deinen Audatex-Ansprechpartner.
            </p>
        </ng-container>

        <div
            id="autoixpert-advantages-row"
            [style.grid-template-columns]="isQapterixpertTeam() ? 'repeat(3, 1fr)' : 'repeat(4,1fr)'">
            <!-- Persönlicher Support -->
            <div *ngIf="isQapterixpertTeam()" class="autoixpert-advantage">
                <div class="autoixpert-advantage-icon"><mat-icon>file_upload</mat-icon></div>
                <div>
                    Import aus
                    <br />
                    AudaFusion
                    <a
                        href="https://wissen.autoixpert.de/hc/de/articles/14491069411090"
                        target="_blank"
                        class="help-indicator"
                        matTooltip="Importiere deine Kontakte (inkl. Werkstattkostensätzen) und die Kopfdaten deiner Gutachten, damit {{
                            productName
                        }}  dir melden kann, wenn ein Kennzeichen / eine VIN schon einmal bei dir war. Klicken für mehr Infos.">
                        ?
                    </a>
                </div>
            </div>

            <!-- Keine Kündigungsfrist -->
            <div *ngIf="!isQapterixpertTeam()" class="autoixpert-advantage">
                <div class="autoixpert-advantage-icon"><mat-icon>today</mat-icon></div>
                <div>
                    Keine
                    <br />
                    Kündigungsfrist
                    <span
                        class="help-indicator"
                        matTooltip="Du kannst jederzeit bequem online zur nächsten Rechnung kündigen (z. B. zum nächsten Monat). Du bekommst dann einfach keine weitere Rechnung mehr von uns.">
                        ?
                    </span>
                </div>
            </div>

            <!-- Unbegrenzte Anzahl an Gutachten -->
            <div *ngIf="!isQapterixpertTeam()" class="autoixpert-advantage">
                <div class="autoixpert-advantage-icon"><mat-icon>trending_up</mat-icon></div>
                <div>
                    Unbegrenzte Anzahl Gutachten
                    <span
                        class="help-indicator"
                        matTooltip="Bei uns zahlst du immer eine Pauschale, egal wie viele Gutachten dein Unternehmen jeden Monat in {{
                            productName
                        }} schreibt. Du zahlst nur dann mehr, wenn zu zusätzliches Personal einstellst - wenn es also schon gut bei dir läuft.{{
                            '\n\n'
                        }}Verträge mit einem Kalkulationsanbieter wie Audatex oder DAT brauchst du natürlich weiterhin.">
                        ?
                    </span>
                </div>
            </div>

            <!-- Daten aus dem Test bleiben erhalten-->
            <div class="autoixpert-advantage">
                <div class="autoixpert-advantage-icon"><mat-icon>drive_file_move</mat-icon></div>
                <div>
                    Daten aus dem Test
                    <br />
                    bleiben erhalten
                    <span
                        class="help-indicator"
                        matTooltip="Wenn du bestellst, kannst du deine im Test angelegten Daten nahtlos weiter nutzen.{{
                            '\n\n'
                        }}Selbst wenn du nicht bestellst, halten wir deine Daten kostenfrei 6 Monate lang vor, um dir die Chance zu geben, später zu bestellen.">
                        ?
                    </span>
                </div>
            </div>

            <!-- Persönlicher Support -->
            <div class="autoixpert-advantage">
                <div class="autoixpert-advantage-icon"><mat-icon>call</mat-icon></div>
                <div>
                    Persönlicher
                    <br />
                    Support
                    <span
                        class="help-indicator"
                        matTooltip="Wir helfen dir telefonisch Mo - Fr von 9 - 17 Uhr bei jeder Frage rund um die Software weiter.{{
                            '\n\n'
                        }}Fachlichen Support leisten wir zwar an der Hotline nicht, aber wir empfehlen dir, dich nach spezialisierten Facebook- oder WhatsApp-Gruppen umzuschauen. Dort findest du immer hilfsbereite Gutachter mit einer Menge Praxiserfahrung, die ihr Wissen gerne teilen.">
                        ?
                    </span>
                </div>
            </div>
        </div>
    </section>
    <!--============================================-->
    <!-- END Place Order -->
    <!--============================================-->

    <!--============================================-->
    <!-- Personal Data -->
    <!--============================================-->
    <div
        id="personal-data-container"
        class="card card-with-padding preference-section-container section-with-external-logo"
        *ngIf="selectedUser">
        <h2 id="personal-data-heading">Persönliche Daten</h2>
        <div id="ax-account-columns-container">
            <div id="ax-account-data" class="ax-account-column">
                <h3 id="ax-account-heading">Benutzerdaten</h3>

                <div id="ax-user-photo-container">
                    <!--********** User Avatar **********-->
                    <user-avatar
                        id="ax-user-photo"
                        [size]="62"
                        [matMenuTriggerFor]="userAvatarSubmenu"
                        [userId]="selectedUser._id"></user-avatar>

                    <mat-menu #userAvatarSubmenu>
                        <!--********** Add / Edit Profile Picture **********-->
                        <a mat-menu-item class="menu-item-with-icon" (click)="axUserPhotoFileUpload.click()">
                            <mat-icon>add_a_photo</mat-icon>
                            {{ selectedUser.profilePictureHash ? 'Profilbild ändern' : 'Profilbild hinzufügen' }}
                        </a>

                        <!--********** Delete Profile Picture **********-->
                        <a
                            mat-menu-item
                            *ngIf="selectedUser.profilePictureHash"
                            class="menu-item-with-icon"
                            (click)="deleteProfilePicture()">
                            <mat-icon>delete</mat-icon>
                            Profilbild löschen
                        </a>

                        <!--********** Edit Initials **********-->
                        <a mat-menu-item class="menu-item-with-icon" (click)="userForEditInitialsDialog = selectedUser">
                            <mat-icon>edit</mat-icon>
                            Initialen & Farbe bearbeiten
                        </a>
                    </mat-menu>

                    <!--********** File upload input **********-->
                    <input
                        id="ax-user-photo-file-upload"
                        type="file"
                        #axUserPhotoFileUpload
                        (change)="uploadProfilePicture($event)"
                        [accept]="allowedImageFileTypes.join(',')" />
                </div>
                <div id="ax-username-container">
                    <mat-form-field id="ax-user-name" class="preference-in-display-mode">
                        <input
                            matInput
                            placeholder="Benutzername"
                            disabled
                            [ngModel]="selectedUser.email"
                            [ngModelOptions]="{ standalone: true }" />
                    </mat-form-field>
                    <mat-icon
                        *ngIf="isChangingPersonalDataAllowed() && !isQapterixpertTeam()"
                        id="username-edit-icon"
                        class="small-icon"
                        (click)="openChangeUsernameDialog()"
                        matTooltip="Nutzername ändern">
                        edit
                    </mat-icon>
                </div>
                <div id="ax-password-container">
                    <password-input type="text" [(password)]="axPassword" [disabled]="true"></password-input>
                    <mat-icon
                        id="ax-password-edit-icon"
                        class="small-icon"
                        (click)="openAxPasswordDialog()"
                        matTooltip="Passwort ändern">
                        edit
                    </mat-icon>
                </div>
            </div>

            <div
                id="user-address-details"
                class="ax-account-column"
                [matTooltip]="getTooltipIfChangingPersonalDataIsDisallowed()">
                <h3 id="user-address-details-heading">Adressdaten</h3>

                <!--********** Organization **********-->
                <!-- Click handler to display a warning must sit on the mat-form-field. Being attached to a disabled input, the click won't be triggered. -->
                <mat-form-field class="mat-block" (click)="displayWarningIfChangingPersonalDataIsDisallowed()">
                    <input
                        matInput
                        placeholder="Firma"
                        [(ngModel)]="selectedUser.organization"
                        (change)="saveUser()"
                        [disabled]="!isChangingPersonalDataAllowed()" />
                </mat-form-field>

                <!--============================================-->
                <!-- Saluation, First and Last Name -->
                <!--============================================-->
                <div class="name-group">
                    <!--********** Salutation **********-->
                    <mat-form-field class="salutation">
                        <input
                            matInput
                            placeholder="Anrede"
                            [(ngModel)]="selectedUser.salutation"
                            [matAutocomplete]="salutationOptions"
                            (input)="filterSalutations(selectedUser.salutation)"
                            (change)="saveUser()" />
                    </mat-form-field>
                    <mat-autocomplete #salutationOptions="matAutocomplete" (optionSelected)="saveUser()">
                        <mat-option
                            *ngFor="let salutation of filteredSalutations"
                            [value]="salutation"
                            (mousedown)="$event.preventDefault()">
                            {{ salutation }}
                        </mat-option>
                    </mat-autocomplete>

                    <!--********** First Name **********-->
                    <!-- Click handler to display a warning must sit on the mat-form-field. Being attached to a disabled input, the click won't be triggered. -->
                    <mat-form-field class="first-name" (click)="displayWarningIfChangingPersonalDataIsDisallowed()">
                        <input
                            matInput
                            placeholder="Vorname"
                            [(ngModel)]="selectedUser.firstName"
                            (change)="saveUser()"
                            [disabled]="!isChangingPersonalDataAllowed()" />
                    </mat-form-field>

                    <!--********** Last Name **********-->
                    <!-- Click handler to display a warning must sit on the mat-form-field. Being attached to a disabled input, the click won't be triggered. -->
                    <mat-form-field class="last-name" (click)="displayWarningIfChangingPersonalDataIsDisallowed()">
                        <input
                            matInput
                            placeholder="Nachname"
                            [(ngModel)]="selectedUser.lastName"
                            (change)="saveUser()"
                            [disabled]="!isChangingPersonalDataAllowed()" />
                    </mat-form-field>
                </div>
                <!--============================================-->
                <!-- END Saluation, First and Last Name -->
                <!--============================================-->

                <!--============================================-->
                <!-- Phone -->
                <!--============================================-->
                <div
                    class="phone-numbers-container"
                    [ngClass]="{ 'second-phone-shown': secondPhoneNumberShown || selectedUser.phone2 }">
                    <!-- Click handler to display a warning must sit on the mat-form-field. Being attached to a disabled input, the click won't be triggered. -->
                    <mat-form-field
                        class="phone mat-block"
                        (click)="displayWarningIfChangingPersonalDataIsDisallowed()">
                        <input
                            matInput
                            type="tel"
                            placeholder="Telefon"
                            [(ngModel)]="selectedUser.phone"
                            (change)="saveUser()"
                            [disabled]="!isChangingPersonalDataAllowed()" />
                        <mat-icon
                            matSuffix
                            *ngIf="isChangingPersonalDataAllowed()"
                            class="input-toolbar-icon phone-number-suffix"
                            matTooltip="Telefonnummer hinzufügen"
                            (click)="showSecondPhoneNumber()">
                            add
                        </mat-icon>
                    </mat-form-field>
                    <mat-form-field class="phone2 mat-block" *ngIf="secondPhoneNumberShown || selectedUser.phone2">
                        <input
                            matInput
                            type="tel"
                            placeholder="Telefon 2"
                            [(ngModel)]="selectedUser.phone2"
                            (change)="saveUser()"
                            [disabled]="!isChangingPersonalDataAllowed()" />
                        <mat-icon
                            matSuffix
                            *ngIf="isChangingPersonalDataAllowed()"
                            class="input-toolbar-icon phone-number-suffix"
                            matTooltip="Zweite Telefonnummer entfernen"
                            (click)="removeSecondPhoneNumber(); saveUser()">
                            close
                        </mat-icon>
                    </mat-form-field>
                </div>
                <!--============================================-->
                <!-- END Phone -->
                <!--============================================-->

                <!--********** Occupational Qualification **********-->
                <mat-form-field class="mat-block">
                    <mat-quill
                        placeholder="Berufliche Qualifikation"
                        [(ngModel)]="selectedUser.occupationalQualification"
                        (change)="saveUser()"
                        matTooltip="Wird auf dem Deckblatt eines Gutachtens und unterhalb des Stempels und der Unterschrift abgedruckt."></mat-quill>
                </mat-form-field>
            </div>
        </div>

        <!--============================================-->
        <!-- Office Locations -->
        <!--============================================-->
        <section id="office-location-section">
            <h3>
                Standorte
                <span
                    class="help-indicator"
                    matTooltip="Der Standort eines Nutzers bestimmt, welche Adresse auf dem Briefpapier abgedruckt oder an Schnittstellen wie z. B. Restwertbörsen übermittelt wird.{{
                        '\n\n'
                    }}Der Standort kann in jedem Gutachten überschrieben werden. Sobald du mehr als einen Standort hast, wird dir im Gutachten eine Auswahl angeboten.">
                    ?
                </span>
            </h3>

            <div id="office-location-list" *ngIf="team">
                <!--============================================-->
                <!-- Office Location Item -->
                <!--============================================-->
                <div
                    class="office-location"
                    *ngFor="let officeLocation of team.officeLocations"
                    [ngClass]="{ active: isDefaultOfficeLocation(officeLocation) }"
                    (dblclick)="openOfficeLocationEditDialog(officeLocation)">
                    <!-- Evaluate the developerTools setting in the parent component, since we need the display-internal-id component not to be visible in the DOM in order to correct the styles with :has() -->
                    <display-internal-id
                        *ngIf="userPreferences.developerTools_displayIds"
                        [internalId]="officeLocation._id"
                        entityName="Standort"></display-internal-id>

                    <!--********** Icon **********-->
                    <div class="office-location-icon-container">
                        <mat-icon class="office-location-icon">home</mat-icon>
                    </div>

                    <!--********** Title & Address **********-->
                    <div class="office-location-title-and-address">
                        <div class="office-location-title">{{ officeLocation.title || 'Kein Titel' }}</div>
                        <div class="label">{{ officeLocation.streetAndHouseNumber || 'keine Straße' }}</div>
                        <div class="label" *ngIf="officeLocation.zip || officeLocation.city">
                            {{ officeLocation.zip }} {{ officeLocation.city }}
                        </div>
                        <div class="label" *ngIf="!(officeLocation.zip || officeLocation.city)">Keine PLZ & Stadt</div>

                        <!--********** Edit button for incomplete locations **********-->
                        <button
                            class="margin-top-10"
                            *ngIf="!isOfficeLocationComplete(officeLocation)"
                            (click)="openOfficeLocationEditDialog(officeLocation)">
                            Vervollständigen
                        </button>
                    </div>

                    <!--********** Toolbar **********-->
                    <div class="card-toolbar">
                        <mat-icon
                            class="default-icon toolbar-icon office-location-default-icon"
                            (click)="selectDefaultOfficeLocation(officeLocation); saveUser()"
                            [ngClass]="{ active: isDefaultOfficeLocation(officeLocation) }"
                            [matTooltip]="
                                isDefaultOfficeLocation(officeLocation)
                                    ? 'Dieser Standort ist der Standard für den Nutzer.'
                                    : 'Klicke, um diesen Standort zum Standard für diesen Nutzer zu machen.\n\nAlle Standorte können pro Gutachten auch manuell gesetzt werden.'
                            ">
                            star
                        </mat-icon>
                        <ng-container *ngIf="userIsAdmin()">
                            <mat-icon
                                [ngClass]="{ active: isDefaultOfficeLocation(officeLocation) }"
                                class="toolbar-icon"
                                [matMenuTriggerFor]="officeLocationSubmenu"
                                (click)="$event.stopPropagation()">
                                more_vert
                            </mat-icon>
                            <mat-menu #officeLocationSubmenu>
                                <!--********** Edit **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    (click)="openOfficeLocationEditDialog(officeLocation)">
                                    <mat-icon>edit</mat-icon>
                                    Bearbeiten
                                </a>

                                <!--********** Delete **********-->
                                <a
                                    mat-menu-item
                                    class="menu-item-with-icon"
                                    *ngIf="team.officeLocations.length > 1"
                                    (click)="removeOfficeLocation(officeLocation); saveTeam()">
                                    <mat-icon>delete</mat-icon>
                                    Löschen
                                </a>
                            </mat-menu>
                        </ng-container>
                    </div>
                </div>
                <!--============================================-->
                <!-- END Office Location Item -->
                <!--============================================-->

                <!--********** New Office Location **********-->
                <div id="add-office-location-container" *ngIf="userIsAdmin()">
                    <div
                        id="add-office-location-inner-container"
                        (click)="createNewOfficeLocation(); saveTeam()"
                        matTooltip="Standort hinzufügen">
                        <div id="add-office-location-icon-container">
                            <mat-icon id="add-office-location-icon">add</mat-icon>
                        </div>
                        <div id="add-office-location-text">Neuer Standort</div>
                    </div>
                </div>
            </div>
        </section>

        <office-location-edit-dialog
            *ngIf="officeLocationForEditDialog"
            [officeLocation]="officeLocationForEditDialog"
            (officeLocationChange)="saveTeam()"
            (close)="closeOfficeLocationEditDialog()"
            @runChildAnimations></office-location-edit-dialog>
        <!--============================================-->
        <!-- END Office Locations -->
        <!--============================================-->

        <div
            class="info-note margin-top-20"
            *ngIf="displayWarningIfOfficeLocationsAndBillingAddressDiffer()"
            @slideInAndOutVertically>
            <mat-icon class="outlined">info</mat-icon>
            <div>
                <h3>Rechnungsadresse weicht ab</h3>
                <p>
                    Die Adresse, die du auf deinen Gutachten abdruckst, weicht von der Adresse ab, an die wir unsere
                    Rechnung stellen ({{ team.billing.address.streetAndHouseNumberOrLockbox }},
                    {{ team.billing.address.zip }} {{ team.billing.address.city }}). Wenn du uns eine Änderung deiner
                    Rechnungsadresse mitteilen möchtest,
                    <a class="link" (click)="openInvoiceAddressDialog()">aktualisiere bitte deine Rechnungsadresse</a>
                    .
                </p>
            </div>
            <mat-icon
                class="info-note-close-icon toolbar-icon"
                (click)="closeBillingAddressWarning()"
                matTooltip="Hinweis für diese Adresse ausblenden">
                close
            </mat-icon>
        </div>

        <div id="personal-data-logo-container" class="preference-logo-container">
            <img *ngIf="!isQapterixpert()" src="/assets/images/logos/autoixpert-inverted.svg" alt="" />
            <img *ngIf="isQapterixpert()" src="/assets/images/logos/qapterixpert-logo-white.svg" alt="" />
        </div>
    </div>

    <user-initials-edit-dialog
        *ngIf="userForEditInitialsDialog"
        [user]="userForEditInitialsDialog"
        (userChange)="saveUser(userForEditInitialsDialog)"
        (close)="userForEditInitialsDialog = null"
        @runChildAnimations></user-initials-edit-dialog>
    <!--============================================-->
    <!-- END Personal Data -->
    <!--============================================-->

    <!--============================================-->
    <!-- E-Mail -->
    <!--============================================-->
    <div
        id="email-settings-container"
        class="card card-with-padding preference-section-container section-with-external-logo"
        *ngIf="selectedUser">
        <h2 id="email-settings-heading">
            E-Mail
            <span
                matSuffix
                class="help-indicator"
                matTooltip="Versende abgeschlossene Gutachten über deine eigene E-Mail-Adresse.{{
                    team?.accountStatus === 'test'
                        ? '\n\nOhne eigene E-Mailadresse versenden wir die Gutachten für dich von der Adresse app@de.autoixpert.de.'
                        : ''
                }}">
                ?
            </span>
        </h2>

        <!--============================================-->
        <!-- Credentials & Host Server -->
        <!--============================================-->
        <form id="email-settings-inputs-container">
            <mat-form-field id="email-user-name" class="preference-in-display-mode">
                <input
                    matInput
                    [placeholder]="emailAddressInputVisible ? 'Benutzername' : 'E-Mail oder Benutzername'"
                    [(ngModel)]="selectedUser.emailAccount.username"
                    name="email-address-username"
                    (change)="
                        deriveEmailSettingsFromEmailAddress(); saveAndValidateEmailCredentials(); showOrHideEmailInput()
                    "
                    matTooltip="Meist ist das deine E-Mailadresse. Bei manchen E-Mail-Anbietern aber auch ein Benutzername wie 'user123' oder 'vorname.nachname'." />
            </mat-form-field>

            <mat-form-field id="email-address" class="preference-in-display-mode" *ngIf="emailAddressInputVisible">
                <input
                    matInput
                    placeholder="E-Mail"
                    [(ngModel)]="selectedUser.emailAccount.email"
                    name="email-address"
                    (change)="deriveEmailSettingsFromEmailAddress(); saveAndValidateEmailCredentials()"
                    matTooltip="Falls der Nutzername keine Mailadresse ist, könnte dein E-Mail-Provider deine Absendermailadresse verlangen." />
            </mat-form-field>

            <!-- This is used to prevent browsers from saving passwords for SMTP accounts, DAT accounts, ... with the autoiXpert username. -->
            <div class="dummy-password-container">
                <input type="email" name="username-dummy-email" placeholder="Dummy" tabindex="-1" />
            </div>
            <!--********** Since Microsoft accounts must be linked through OAuth, hide the password field. **********-->
            <password-input
                *ngIf="!isMicrosoftEmailAccount()"
                type="text"
                [(password)]="emailPassword"
                (passwordChange)="saveAndValidateEmailCredentials()"
                name="email-password"></password-input>

            <div id="email-host-container">
                <mat-form-field id="email-host">
                    <input
                        matInput
                        placeholder="Host-Server"
                        [(ngModel)]="selectedUser.emailAccount.host"
                        (change)="saveAndValidateEmailCredentials()"
                        [matAutocomplete]="emailHostServerAutocomplete"
                        (focus)="filterEmailServerHosts()"
                        (ngModelChange)="filterEmailServerHosts()"
                        name="email-host" />
                </mat-form-field>
                <mat-autocomplete
                    #emailHostServerAutocomplete="matAutocomplete"
                    (optionSelected)="derivePortFromHost(); saveUser()">
                    <mat-option
                        *ngFor="let emailProvider of filteredEmailProviders"
                        [value]="emailProvider.smtpHost"
                        class="email-provider-host-option"
                        (mousedown)="$event.preventDefault()">
                        <div class="email-provider-title">{{ emailProvider.title }}</div>
                        <div class="email-provider-server label">{{ emailProvider.smtpHost }}</div>
                    </mat-option>
                </mat-autocomplete>
            </div>
            <mat-form-field id="email-port" class="preference-in-display-mode">
                <input
                    matInput
                    number-input
                    placeholder="SMTP-Port"
                    [(number)]="selectedUser.emailAccount.port"
                    name="email-port"
                    (numberChange)="saveAndValidateEmailCredentials()"
                    [minimumFractionDigits]="0"
                    [maximumFractionDigits]="0"
                    matTooltip="Meist 465 oder 587" />
            </mat-form-field>

            <!--********** Required only if the users's SMTP server is configured in an untypical way. **********-->
            <mat-form-field
                id="email-connection-start"
                class="preference-in-display-mode"
                *ngIf="selectedUser?.emailAccount.connectionStartType">
                <mat-select
                    (selectionChange)="saveAndValidateEmailCredentials()"
                    [(ngModel)]="selectedUser.emailAccount.connectionStartType"
                    placeholder="Verbindungstyp"
                    name="email-connection-start-type">
                    <mat-option value="starttls" class="email-connection-start-type-option">STARTTLS</mat-option>
                    <mat-option value="ssl" class="email-connection-start-type-option">SSL</mat-option>
                    <mat-option value="unencrypted" class="email-connection-start-type-option">
                        Unverschlüsselt
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <!--============================================-->
        <!-- END Credentials & Host Server -->
        <!--============================================-->

        <!--============================================-->
        <!-- Helpcenter Article -->
        <!--============================================-->
        <div class="helpcenter-link-container">
            <a
                class="helpcenter-link"
                href="https://wissen.autoixpert.de/hc/de/articles/360006973051-Absender-E-Mail-%C3%A4ndern"
                target="_blank"
                rel="noopener">
                Probleme bei der Einrichtung?
            </a>
        </div>
        <!--============================================-->
        <!-- END Helpcenter Article -->
        <!--============================================-->

        <div
            class="info-note error-note margin-bottom-15"
            *ngIf="selectedUser.emailAccount.port === 25"
            @slideInAndOutVertically>
            <mat-icon>error</mat-icon>
            <div>
                <strong>Port 25 ist unsicher und wird von {{ productName }} nicht unterstützt.</strong>
                Bitte weiche auf die heute gängigen sicheren Ports 465 oder 587 aus. Ggf. kann dir dein IT-Profi
                weiterhelfen.
            </div>
        </div>

        <!--============================================-->
        <!-- E-Mail Sender -->
        <!--============================================-->
        <mat-form-field class="mat-block" *ngIf="emailDisplayNameVisible">
            <input
                matInput
                placeholder="Anzeigename"
                [(ngModel)]="selectedUser.emailAccount.displayName"
                (change)="saveUser(selectedUser)"
                matTooltip="Der Name, der als Absender der E-Mail verwendet wird." />
            <!--********** Source for Microsoft not being able to send from different sender name: https://stackoverflow.com/questions/61900301/microsoft-graph-how-to-send-email-with-custom-sender-name-before-email-address **********-->
            <mat-hint class="warning" *ngIf="selectedUser?.emailAccount.oauthProvider === 'microsoft'">
                Microsoft unterstützt keinen abweichenden Anzeigenamen über die Schnittstelle.
            </mat-hint>
        </mat-form-field>

        <!--********** Preview **********-->
        <div id="email-sender-preview" class="label" *ngIf="getEmailSenderPreview(selectedUser)">
            <span matTooltip="Diesen Absender sehen die Empfänger deiner Mails in ihrer Inbox.">
                Absender-Vorschau: {{ getEmailSenderPreview(selectedUser) }}
            </span>
            <mat-icon
                class="toolbar-icon medium-icon"
                (click)="toggleEmailDisplayName()"
                matTooltip="Anzeigename bearbeiten">
                edit
            </mat-icon>
        </div>
        <!--============================================-->
        <!-- END E-Mail Sender -->
        <!--============================================-->

        <!--***********************************************
        ** Email Through OAuth
        ************************************************-->

        <!--********** TODO Reactivate Gmail connection UI as soon as the Gmail API is connected in the backend.  **********-->
        <!--        &lt;!&ndash;********** Connect Gmail Through OAuth **********&ndash;&gt;-->
        <!--        <div-->
        <!--            class="display-flex flex-center-content margin-top-20"-->
        <!--            *ngIf="-->
        <!--                (selectedUser?.emailAccount?.email | includes: '@gmail.com') ||-->
        <!--                (selectedUser?.emailAccount?.host | includes: 'gmail.com') ||-->
        <!--                selectedUser?.emailAccount?.oauthProvider === 'microsoft'-->
        <!--            ">-->
        <!--            <button-->
        <!--                matTooltip="Damit du mit Gmail E-Mails verschicken kannst, kannst du ein App-Passwort vergeben oder hier deinen Google-Account verknüpfen.{{-->
        <!--                    '\n\n'-->
        <!--                }}Weil es einfacher ist, empfehlen wir die Verknüpfung deines Google-Accounts."-->
        <!--                (click)="openGmailOauthDialog()"-->
        <!--                *ngIf="selectedUser?.emailAccount?.oauthProvider !== 'google'">-->
        <!--                Google-Account verknüpfen-->
        <!--            </button>-->
        <!--            <div *ngIf="selectedUser?.emailAccount?.oauthProvider === 'google'" class="label">-->
        <!--                Google-Account verknüpft.-->
        <!--                <a style="cursor: pointer" (click)="removeGmailOauth()">Entkoppeln.</a>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--********** Connect Office 365 Outlook Through OAuth **********-->
        <div class="display-flex flex-center-content margin-top-20" *ngIf="isMicrosoftEmailAccount()">
            <div
                id="transfer-signature-info-note"
                class="info-note"
                *ngIf="selectedUser?.emailAccount?.oauthProvider !== 'microsoft'"
                @fadeInAndSlide>
                <div class="info-note-text">
                    <div class="info-note-preserve-close-icon-space">
                        Um E-Mails über Microsoft zu verschicken, musst du deinen Account verknüpfen.
                        <span
                            class="help-indicator"
                            matTooltip="Damit du mit Microsoft 365 (bis November 2022: Office 365) E-Mails verschicken kannst, kannst du hier deinen Microsoft-365-Account verknüpfen.{{
                                '\n\n'
                            }}Microsoft hat den E-Mail-Versand über Benutzername und Passwort im September 2024 eingestellt.">
                            ?
                        </span>
                    </div>
                    <div class="info-note-buttons-row display-flex flex-center-content">
                        <div class="info-note-button-primary" (click)="openMicrosoftOauthDialog()">
                            Microsoft-Account verknüpfen
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="selectedUser?.emailAccount?.oauthProvider === 'microsoft'" class="label">
                Microsoft-Account verknüpft.
                <a style="cursor: pointer" (click)="removeMicrosoftOauth()">Entkoppeln.</a>
            </div>
        </div>
        <!--***********************************************
        ** END Email Through OAuth
        ************************************************-->

        <!--***********************************************
        ** E-Mail Signature
        ************************************************-->
        <section class="text-align-center margin-top-20" *ngIf="!isEmailSignatureShown">
            <button id="email-signature-opener" class="flat" (click)="showEmailSignature()">
                <mat-icon class="small-icon">draw</mat-icon>
                E-Mail-Signatur
            </button>
        </section>

        <ng-container *ngIf="isEmailSignatureShown">
            <h3>
                E-Mail-Signatur
                <span
                    class="help-indicator"
                    matTooltip="Deine E-Mail-Signatur wird bei jeder ausgehenden E-Mail ans Ende angehängt.">
                    ?
                </span>
            </h3>

            <div id="email-signature-loading-indicator-container" *ngIf="!emailSignature">
                <img
                    id="email-signature-loading-indicator"
                    src="/assets/images/icons/loading-indicator.svg"
                    matTooltip="Die E-Mail-Signatur wird geladen..." />
            </div>
            <mat-form-field class="message mat-block" *ngIf="emailSignature">
                <mat-quill
                    placeholder="Nachricht"
                    [(ngModel)]="emailSignature.content"
                    [isEmailQuill]="true"
                    [imageUploadAsync]="uploadEmailSignatureImage"
                    theme="snow"
                    (change)="saveEmailSignature()"></mat-quill>
            </mat-form-field>
        </ng-container>
        <!--***********************************************
        ** END E-Mail Signature
        ************************************************-->

        <!--***********************************************
        ** Sub Menu
        ************************************************-->
        <div id="email-settings-toolbar-container">
            <mat-icon class="toolbar-icon" [matMenuTriggerFor]="emailAccountSubmenu">more_vert</mat-icon>
        </div>
        <mat-menu #emailAccountSubmenu>
            <a
                mat-menu-item
                *ngIf="selectedUser?.emailAccount.connectionStartType"
                (click)="removeEmailConnectionStartType(); saveAndValidateEmailCredentials()">
                <mat-icon class="email-settings-connection-start-type-icon">vpn_lock</mat-icon>
                Verbindungstyp vom Port ableiten lassen
            </a>
            <a
                mat-menu-item
                *ngIf="!selectedUser?.emailAccount.connectionStartType"
                (click)="showEmailConnectionStartType(); saveAndValidateEmailCredentials()">
                <mat-icon class="email-settings-connection-start-type-icon">vpn_lock</mat-icon>
                Verbindungstyp explizit definieren
            </a>
        </mat-menu>
        <!--***********************************************
        ** END Sub Menu
        ************************************************-->

        <!-- Email Sending Delay Configuration -->
        <div class="email-delay-container">
            <div
                class="email-delay-toggle-row"
                (click)="
                    selectedUser.preferences.emailSendingDelayEnabled =
                        !selectedUser.preferences.emailSendingDelayEnabled;
                    saveSelectedUserEmailPreferences();
                    $event.stopPropagation()
                ">
                <div class="email-icon-container">
                    <mat-icon class="outlined">mail_off</mat-icon>
                </div>
                <div class="email-delay-label">
                    <div>E-Mails zurücknehmen</div>
                    <div class="label">E-Mails können für einen bestimmten Zeitraum zurückgenommen werden</div>
                </div>
                <mat-slide-toggle
                    [(ngModel)]="selectedUser.preferences.emailSendingDelayEnabled"
                    name="email-delay-toggle"
                    (click)="$event.stopPropagation()"
                    (change)="saveSelectedUserEmailPreferences()"></mat-slide-toggle>
            </div>

            <div
                class="email-delay-settings"
                [@slideInAndOutVertically]
                *ngIf="selectedUser.preferences.emailSendingDelayEnabled">
                <div class="email-delay-description">
                    Zeitversatz einstellen
                    <div class="label">
                        Jede Nachricht wird standardmäßig mit einem Zeitversatz gesendet. Dies ermöglicht das
                        Zurücknehmen von Nachrichten.
                    </div>
                </div>
                <div class="email-delay-inputs">
                    <mat-form-field class="email-delay-amount">
                        <input
                            matInput
                            type="number"
                            placeholder="Zeitversatz"
                            [(ngModel)]="selectedUser.preferences.emailSendingDelayAmount"
                            name="email-delay-amount"
                            min="1"
                            (change)="saveSelectedUserEmailPreferences()" />
                    </mat-form-field>

                    <mat-form-field class="email-delay-unit">
                        <mat-select
                            [(ngModel)]="selectedUser.preferences.emailSendingDelayUnit"
                            placeholder="Einheit"
                            name="email-delay-unit"
                            (selectionChange)="saveSelectedUserEmailPreferences()">
                            <mat-option value="seconds">Sekunden</mat-option>
                            <mat-option value="minutes">Minuten</mat-option>
                            <mat-option value="hours">Stunden</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <!--============================================-->
    <!-- END E-Mail -->
    <!--============================================-->

    <!--============================================-->
    <!-- Signature & Stamp -->
    <!--============================================-->
    <div
        id="signature-and-stamp-container"
        class="card card-with-padding preference-section-container reduced-padding-right text-align-center">
        <h2>Unterschrift & Stempel</h2>

        <ng-container *ngIf="isUserSealConfigurationVisible">
            <configure-user-seal
                [selectedUser]="selectedUser"
                (userChange)="saveUser()"
                (close)="userSealEditorOpened = false"
                (sealDeleted)="removeSeal(); userSealEditorOpened = false"></configure-user-seal>
        </ng-container>

        <div id="signature-and-preview-button-container" *ngIf="!isUserSealConfigurationVisible">
            <div
                id="signature-icon-container"
                matTooltip="Aktuelle Datei für Unterschrift & Stempel herunterladen."
                (click)="downloadUserSealFile()">
                <mat-icon id="signature-icon">gesture</mat-icon>
            </div>
            <p id="signature-uploaded-note">Unterschrift & Stempel hinterlegt.</p>
            <mat-icon
                id="signature-delete-icon"
                class="toolbar-icon"
                (click)="editSeal()"
                matTooltip="Unterschrift & Stempel bearbeiten">
                edit
            </mat-icon>
            <mat-icon
                id="signature-delete-icon"
                class="toolbar-icon"
                (click)="removeSeal()"
                matTooltip="Unterschrift & Stempel löschen">
                delete
            </mat-icon>
        </div>

        <div
            id="name-hidden-below-seal"
            *ngIf="selectedUser?.hideNameBelowSeal"
            [matTooltip]="
                'Dein Name wird nicht unter dem Stempel angezeigt.\nDadurch kannst du in die Bilddatei deines Stempels weiteren Text schreiben wie z. B. besondere Qualifikationen.'
            ">
            <mat-icon>visibility_off</mat-icon>
            <div clasS="label">Name unter Unterschrift & Stempel</div>
        </div>

        <div id="user-seal-toolbar-container">
            <mat-icon class="toolbar-icon user-seal-submenu-icon" [matMenuTriggerFor]="userSealSubmenu">
                more_vert
            </mat-icon>
        </div>
        <mat-menu #userSealSubmenu>
            <a
                mat-menu-item
                *ngIf="!selectedUser?.hideNameBelowSeal"
                (click)="hideNameBelowSeal(true)"
                matTooltip="Der Name unter der Unterschrift kann versteckt werden, damit der Name z. B. mit Qualifikationen in das Unterschrifts-Bild eingebaut werden kann.">
                <mat-icon>visibility_off</mat-icon>
                Namen unter Unterschrift verstecken
            </a>
            <a mat-menu-item *ngIf="selectedUser?.hideNameBelowSeal" (click)="hideNameBelowSeal(false)">
                Namen unter Unterschrift anzeigen
            </a>
            <a
                *ngIf="selectedUser?.sealHash"
                mat-menu-item
                (click)="downloadUserSealFile()"
                matTooltip="Aktuelle Datei für Unterschrift & Stempel herunterladen.">
                <mat-icon>file_download</mat-icon>
                Herunterladen
            </a>
        </mat-menu>

        <div class="helpcenter-link-container">
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/360023438731#wo-wird-die-unterschrift-abgedruckt"
                class="helpcenter-link"
                target="_blank"
                rel="”noopener”">
                Wo wird meine Unterschrift abgedruckt?
            </a>
        </div>
    </div>
    <!--============================================-->
    <!-- END Signature & Stamp -->
    <!--============================================-->

    <!--============================================-->
    <!-- Calculation Providers -->
    <!--============================================-->
    <section
        id="calculation-providers-section"
        class="card card-with-padding preference-section-container"
        *ngIf="selectedUser">
        <h2>Kalkulation</h2>

        <div id="calculation-providers">
            <!--============================================-->
            <!-- Audatex -->
            <!--============================================-->
            <div class="calculation-provider-container">
                <div id="audatex-calculation-provider" class="calculation-provider">
                    <!--********** Is Audatex Default Provider? **********-->
                    <div
                        class="calculation-provider-default-marker-container"
                        *ngIf="doMultipleCalculationProvidersExist()"
                        (click)="setDefaultVehicleIdentificationProvider('audatex')"
                        [ngClass]="{ active: userPreferences.vehicleIdentificationProvider === 'audatex' }"
                        [matTooltip]="
                            userPreferences.vehicleIdentificationProvider === 'audatex'
                                ? 'Die VIN-Abfrage über Audatex wird in neuen Gutachten vorbelegt.'
                                : 'Setze Audatex als dein Standard-System für VIN & Kalkulation.'
                        ">
                        <mat-icon class="calculation-provider-default-marker medium-icon">star</mat-icon>
                    </div>

                    <!--********** Header **********-->
                    <div class="calculation-provider-header">
                        <img src="/assets/images/logos/qapter-logo-white-orange.svg" alt="" />

                        <!--********** Settings **********-->
                        <div
                            *ngIf="userCanSynchronizeCredentials()"
                            class="residual-and-market-value-exchange-settings">
                            <mat-icon
                                class="toolbar-icon user-seal-submenu-icon"
                                [matMenuTriggerFor]="winvalueSettingsMenu">
                                more_vert
                            </mat-icon>
                        </div>

                        <mat-menu #winvalueSettingsMenu="matMenu" xPosition="after">
                            <!-- Synchronize credentials -->
                            <a mat-menu-item (click)="synchronizeCredentials('audatexUser')">
                                <mat-icon>sync</mat-icon>
                                Synchronisieren
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Credentials **********-->
                    <div class="calculation-provider-credentials">
                        <ng-container *ngIf="team?.audatexFeatures?.hasAudatexAddon">
                            <form id="audatex-credentials-container">
                                <mat-form-field class="mat-block">
                                    <input
                                        matInput
                                        placeholder="Benutzername"
                                        [(ngModel)]="selectedUser.audatexUser.username"
                                        (change)="
                                            trimAudatexUsername(); addDomainToAudatexUsername(); saveAudatexUser()
                                        "
                                        name="audatex-username" />
                                </mat-form-field>
                                <password-input
                                    type="text"
                                    [(password)]="selectedUser.audatexUser.password"
                                    (passwordChange)="trimAudatexPassword(); saveAudatexUser()"
                                    name="audatex-password"></password-input>

                                <div class="third-field-stub"></div>
                            </form>

                            <!--********** Synchronization message **********-->
                            <synchronize-credentials
                                *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.audatexUser"
                                (confirmClick)="synchronizeCredentials('audatexUser')"
                                (cancelClick)="dismissSynchronizationMessage('audatexUser')"></synchronize-credentials>

                            <!--============================================-->
                            <!-- Order Qapter Account -->
                            <!--============================================-->
                            <div
                                class="text-align-center margin-top-10 label"
                                *ngIf="
                                    (!selectedUser.audatexUser.username && !selectedUser.audatexUser.password) ||
                                    isAudatexTestAccount()
                                ">
                                Noch keinen Account?
                                <br />
                                <a href="https://www.autoixpert.de/audatex-preisrechner" target="_blank" rel="noopener">
                                    Audatex-Preise & Kontakt
                                </a>
                            </div>
                            <!--============================================-->
                            <!-- END Order Qapter Account -->
                            <!--============================================-->

                            <!--============================================-->
                            <!-- Deactivate Addon -->
                            <!--============================================-->
                            <!--
                            Only available if
                            - this is NOT a test account
                            - the team is NOT a Qapter-iXpert customer
                            - the team is a paying customer
                             -->
                            <div
                                class="text-align-center margin-top-20 label"
                                *ngIf="
                                    !isAudatexTestAccount() && !isQapterixpertTeam() && team.accountStatus === 'paying'
                                ">
                                <a
                                    class="cancel-audatex-addon"
                                    *ngIf="userIsAdmin()"
                                    (click)="cancelAudatexAddon()"
                                    matTooltip="Wenn du Audatex nicht mehr verwenden willst, kannst du das Add-on deaktivieren. Dir wird das Add-on dann ab der nächsten Rechnung nicht mehr berechnet."
                                    target="_blank"
                                    rel="noopener">
                                    Add-on deaktivieren
                                </a>
                            </div>
                            <!--============================================-->
                            <!-- END Deactivate Addon -->
                            <!--============================================-->
                        </ng-container>

                        <!--********** No Audatex Add-on **********-->
                        <div *ngIf="!team?.audatexFeatures?.hasAudatexAddon" class="no-audatex-addon">
                            <!--********** Video Thumbnail **********-->
                            <div id="audatex-parts-selection-thumbnail" class="card">
                                <a href="https://www.youtube.com/watch?v=GQfE1tqao2U" target="_blank" rel="noopener">
                                    <img
                                        class="video-thumbnail"
                                        src="/assets/images/qapter-parts-selection.jpg"
                                        alt="" />
                                    <img class="video-play-icon" src="/assets/images/icons/play.svg" alt="Play" />
                                </a>
                            </div>

                            <!--********** Text **********-->
                            <div class="text-align-center margin-top-20">
                                <a href="https://www.youtube.com/watch?v=GQfE1tqao2U" target="_blank" rel="noopener">
                                    Infos zu Audatex Qapter
                                </a>
                            </div>
                            <button
                                [matTooltip]="
                                    userIsAdmin()
                                        ? '30 € pro Monat für den Erstnutzer + 10 € pro Zusatznutzer. Ein separater Audatex-Account ist erforderlich.'
                                        : 'Das Audatex Add-on kann nur von einem Administrator gebucht werden.'
                                "
                                [ngClass]="{ disabled: !userIsAdmin() }"
                                (click)="openOrderAudatexAddonDialog()">
                                Add-on buchen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END Audatex -->
            <!--============================================-->

            <!--============================================-->
            <!-- DAT -->
            <!--============================================-->
            <div class="calculation-provider-container">
                <div id="dat-calculation-provider" class="calculation-provider">
                    <!--********** Is DAT Default Provider? **********-->
                    <div
                        class="calculation-provider-default-marker-container"
                        *ngIf="doMultipleCalculationProvidersExist()"
                        (click)="setDefaultVehicleIdentificationProvider('dat')"
                        [ngClass]="{ active: userPreferences.vehicleIdentificationProvider === 'dat' }"
                        [matTooltip]="
                            userPreferences.vehicleIdentificationProvider === 'dat'
                                ? 'Die VIN-Abfrage über DAT wird in neuen Gutachten vorbelegt.'
                                : 'Setze DAT als dein Standard-System für VIN & Kalkulation.'
                        ">
                        <mat-icon class="calculation-provider-default-marker medium-icon">star</mat-icon>
                    </div>

                    <!--********** Header **********-->
                    <div class="calculation-provider-header">
                        <img src="/assets/images/logos/dat-logo.svg" alt="" />

                        <!--********** Settings **********-->
                        <div
                            *ngIf="userCanSynchronizeCredentials()"
                            class="residual-and-market-value-exchange-settings">
                            <mat-icon class="toolbar-icon user-seal-submenu-icon" [matMenuTriggerFor]="datSettingsMenu">
                                more_vert
                            </mat-icon>
                        </div>

                        <mat-menu #datSettingsMenu="matMenu" xPosition="after">
                            <!-- Synchronize credentials -->
                            <a mat-menu-item (click)="synchronizeCredentials('datUser')">
                                <mat-icon>sync</mat-icon>
                                Synchronisieren
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Credentials **********-->
                    <div class="calculation-provider-credentials">
                        <form id="dat-credentials-container">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Benutzername"
                                    [(ngModel)]="selectedUser.datUser.username"
                                    (change)="trimDatUsername(); saveDatUser()"
                                    name="dat-username" />
                            </mat-form-field>
                            <password-input
                                type="text"
                                [(password)]="selectedUser.datUser.password"
                                (passwordChange)="trimDatPassword(); saveDatUser()"
                                name="dat-password"></password-input>
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Kundennummer"
                                    [(ngModel)]="selectedUser.datUser.customerNumber"
                                    (change)="trimDatCustomerNumber(); saveDatUser()"
                                    name="dat-customer-number" />
                            </mat-form-field>
                            <mat-form-field class="mat-block" *ngIf="selectedUser.datUser.isMyclaimUser">
                                <!--********** Use "saveUser()" instead of "saveDatUser()" to prevent validating DAT credentials which would set a network type even if the user deleted the network type manually. **********-->
                                <input
                                    matInput
                                    placeholder="myclaim-Netzwerk"
                                    [(ngModel)]="selectedUser.datUser.myclaimNetworkType"
                                    (change)="saveUser()"
                                    name="dat-myclaim-network-type"
                                    matTooltip="Normalerweise 'DAT', kann aber abweichend sein, wenn du z. B. ein TÜV-Süd-Partner bist." />
                            </mat-form-field>
                        </form>

                        <!--********** Synchronization message **********-->
                        <synchronize-credentials
                            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.datUser"
                            (confirmClick)="synchronizeCredentials('datUser')"
                            (cancelClick)="dismissSynchronizationMessage('datUser')"></synchronize-credentials>

                        <!--********** No DAT Account **********-->
                        <div
                            class="text-align-center margin-top-10 label"
                            *ngIf="
                                (!selectedUser.datUser.customerNumber && !selectedUser.datUser.password) ||
                                isDatTestAccount()
                            ">
                            Noch keinen eigenen Account?
                            <br />
                            <a href="https://www.autoixpert.de/DAT-Preisrechner" target="_blank" rel="”noopener”">
                                DAT-Preise & Kontakt
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END DAT -->
            <!--============================================-->

            <!--============================================-->
            <!-- GT Motive -->
            <!--============================================-->
            <!-- GT Motive is not available for Qapter-iXpert users, decision by Audatex. Show if credentials are complete, e.g. if Qapter-iXpert user
            used to be autoiXpert customer. -->
            <div class="calculation-provider-container" *ngIf="!isQapterixpertTeam() || isGtmotiveUserComplete()">
                <div id="gtmotive-calculation-provider" class="calculation-provider">
                    <!--********** Is GT Motive Default Provider? **********-->
                    <div
                        class="calculation-provider-default-marker-container"
                        *ngIf="doMultipleCalculationProvidersExist()"
                        (click)="setDefaultVehicleIdentificationProvider('gtmotive')"
                        [ngClass]="{ active: userPreferences.vehicleIdentificationProvider === 'gtmotive' }"
                        [matTooltip]="
                            userPreferences.vehicleIdentificationProvider === 'gtmotive'
                                ? 'Die VIN-Abfrage über GT Motive wird in neuen Gutachten vorbelegt.'
                                : 'Setze GT Motive als dein Standard-System für VIN & Kalkulation.'
                        ">
                        <mat-icon class="calculation-provider-default-marker medium-icon">star</mat-icon>
                    </div>

                    <!--********** Header **********-->
                    <div class="calculation-provider-header">
                        <img src="/assets/images/logos/gtmotive-logo.png" alt="" />

                        <!--********** Settings **********-->
                        <div
                            *ngIf="userCanSynchronizeCredentials()"
                            class="residual-and-market-value-exchange-settings">
                            <mat-icon
                                class="toolbar-icon user-seal-submenu-icon"
                                [matMenuTriggerFor]="gtmotiveSettingsMenu">
                                more_vert
                            </mat-icon>
                        </div>

                        <mat-menu #gtmotiveSettingsMenu="matMenu" xPosition="after">
                            <!-- Synchronize credentials -->
                            <a mat-menu-item (click)="synchronizeCredentials('gtmotiveUser')">
                                <mat-icon>sync</mat-icon>
                                Synchronisieren
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Credentials **********-->
                    <div class="calculation-provider-credentials">
                        <form id="gtmotive-credentials-container">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Kunden-ID"
                                    [(ngModel)]="selectedUser.gtmotiveUser.customerId"
                                    (change)="trimGtmotiveCustomerId(); saveGtmotiveUser()"
                                    name="gtmotive-customer-id" />
                            </mat-form-field>
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Benutzer-ID"
                                    [(ngModel)]="selectedUser.gtmotiveUser.userId"
                                    (change)="trimGtmotiveUserId(); saveGtmotiveUser()"
                                    name="gtmotive-user-id" />
                            </mat-form-field>

                            <div class="third-field-stub"></div>
                        </form>

                        <!--********** Synchronization message **********-->
                        <synchronize-credentials
                            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.gtmotiveUser"
                            (confirmClick)="synchronizeCredentials('gtmotiveUser')"
                            (cancelClick)="dismissSynchronizationMessage('gtmotiveUser')"></synchronize-credentials>

                        <div
                            class="text-align-center margin-top-10 label"
                            *ngIf="!selectedUser.gtmotiveUser.customerId && !selectedUser.gtmotiveUser.userId">
                            Noch keinen Account?
                            <br />
                            <a href="https://gtmotive.com/de/demo-anfrage/" target="_blank" rel="noopener">
                                Zum Test registrieren
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END GT Motive -->
            <!--============================================-->
        </div>
        <!--============================================-->
        <!-- DAT Test Account Notes -->
        <!--============================================-->
        <!--********** Test Account Active **********-->
        <div class="info-note label margin-top-20" *ngIf="isDatTestAccount() && isDatTestAccountExtendedAndValid()">
            <mat-icon class="medium-icon">info</mat-icon>
            <div>
                <h3>DAT-Test-Account aktiv</h3>
                Mit deinem autoiXpert-Test-Account erhältst du einen 30-tägigen DAT-Test-Account. Sobald du deinen
                eigenen DAT-Account hast, trage ihn hier ein, um auch nach deiner Testphase kalkulieren zu können.
                <br />
                <a href="https://www.autoixpert.de/DAT-Preisrechner" target="_blank" rel="”noopener”">
                    DAT-Preisrechner & Kontakt
                </a>
            </div>
        </div>

        <!--********** Test Account Expired **********-->
        <div
            class="info-note warning-note label margin-top-20"
            *ngIf="isDatTestAccount() && team?.accountStatus !== 'test' && !isDatTestAccountExtendedAndValid()">
            <mat-icon class="medium-icon">warning</mat-icon>
            <div>
                <h3>DAT-Test-Account abgelaufen</h3>
                Um weiter die Dienste der DAT nutzen zu können, trage deinen eigenen DAT-Account ein.
                <br />
                <a href="https://www.autoixpert.de/DAT-Preisrechner" target="_blank" rel="”noopener”">
                    DAT-Preisrechner & Kontakt
                </a>
            </div>
        </div>

        <!--********** MyClaim **********-->
        <div class="info-note label margin-top-20" *ngIf="selectedUser.datUser.isMyclaimUser">
            <mat-icon class="medium-icon">info</mat-icon>
            <div>
                <h3>DAT-Account ist myclaim-Account für Werkstätten</h3>
                Für Sachverständige erfordert die DAT einen
                <strong>calculateExpert</strong>
                -Account.
                <br />
                <br />
                AutoiXpert kann auch mit einem myClaim-Account arbeiten, damit du z. B. Kostenvoranschläge für deine
                Werkstatt schreiben kannst. Bitte stimme ggf. mit der DAT ab, ob du diesen Account auch zur Erstellung
                von Gutachten verwenden darfst.
            </div>
        </div>
        <!--============================================-->
        <!-- END DAT Test Account Notes -->
        <!--============================================-->

        <!--============================================-->
        <!-- Calculation Providers Test Account Notes -->
        <!--============================================-->
        <div *ngIf="isDatTestAccount() || isAudatexTestAccount()" class="info-note margin-top-20">
            <mat-icon class="medium-icon">info</mat-icon>
            <div>
                <h3>Test-Accounts aktiv</h3>
                Gültig bis zum Ende deines autoiXpert-Tests.
                <br />
                Eine Woche vor Ende deines Tests solltest du
                <a href="https://www.autoixpert.de/audatex-preisrechner" target="_blank" rel="noopener">Audatex</a>
                oder
                <a href="https://www.autoixpert.de/DAT-Preisrechner" target="_blank" rel="noopener">DAT</a>
                wegen deines eigenen Accounts kontaktieren.
            </div>
        </div>
        <div *ngIf="isDatTestAccount() || isAudatexTestAccount()" class="info-note margin-top-20">
            <mat-icon class="medium-icon">smart_display</mat-icon>
            <div>
                <h3>Audatex vs. DAT</h3>
                Vergleiche die beiden Marktführer
                <a href="https://www.youtube.com/watch?v=5bsoKIGgpWg" target="_blank" rel="noopener">Audatex Qapter</a>
                &
                <a href="https://www.youtube.com/watch?v=FBq1pZosgwk" target="_blank" rel="noopener">DAT SilverDAT 3</a>
                anhand einer Beispielkalkulation auf Youtube.
            </div>
        </div>
        <!--============================================-->
        <!-- END Calculation Providers Test Account Notes -->
        <!--============================================-->
    </section>

    <order-audatex-addon-dialog
        *ngIf="isOrderAudatexDialogVisible"
        [team]="team"
        (placeOrder)="orderAudatexAddon() && (isOrderAudatexDialogVisible = false)"
        (close)="isOrderAudatexDialogVisible = false"></order-audatex-addon-dialog>
    <!--============================================-->
    <!-- END Calculation Providers -->
    <!--============================================-->

    <!--============================================-->
    <!-- Residual& Market Value Exchange -->
    <!--============================================-->
    <section
        id="residual-and-market-value-exchanges-section"
        class="card card-with-padding preference-section-container"
        *ngIf="selectedUser">
        <h2>Markt- & Restwerte</h2>

        <div id="residual-and-market-value-exchanges">
            <!--============================================-->
            <!-- AUTOonline -->
            <!--============================================-->
            <div class="residual-and-market-value-exchange-container">
                <div id="autoonline-residual-and-market-value-exchange" class="residual-and-market-value-exchange">
                    <!--********** Header **********-->
                    <div class="residual-and-market-value-exchange-header">
                        <img src="/assets/images/logos/solera-autoonline.svg" alt="Solera AUTOOnline" />

                        <!--********** Settings **********-->
                        <div
                            *ngIf="userCanSynchronizeCredentials()"
                            class="residual-and-market-value-exchange-settings">
                            <mat-icon
                                class="toolbar-icon user-seal-submenu-icon"
                                [matMenuTriggerFor]="autoonlineSettingsMenu">
                                more_vert
                            </mat-icon>
                        </div>

                        <mat-menu #autoonlineSettingsMenu="matMenu" xPosition="after">
                            <!-- Synchronize credentials -->
                            <a mat-menu-item (click)="synchronizeCredentials('autoonlineUser')">
                                <mat-icon>sync</mat-icon>
                                Synchronisieren
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Credentials **********-->
                    <div class="residual-and-market-value-exchange-credentials">
                        <form id="autoonline-credentials-container">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Kundennummer"
                                    [(ngModel)]="selectedUser.autoonlineUser.customerNumber"
                                    (change)="padAutoonlineUser(); saveAutoonlineUser()"
                                    name="autoonline-username" />
                            </mat-form-field>
                            <password-input
                                type="text"
                                [(password)]="selectedUser.autoonlineUser.password"
                                (passwordChange)="trimAutoonlinePassword(); saveAutoonlineUser()"
                                name="autoonline-password"></password-input>

                            <div class="third-field-stub"></div>
                        </form>

                        <!--********** Synchronization message **********-->
                        <synchronize-credentials
                            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.autoonlineUser"
                            (confirmClick)="synchronizeCredentials('autoonlineUser')"
                            (cancelClick)="dismissSynchronizationMessage('autoonlineUser')"></synchronize-credentials>

                        <!--********** Registration info **********-->
                        <div
                            *ngIf="!selectedUser.autoonlineUser.customerNumber && !selectedUser.autoonlineUser.password"
                            class="residual-and-market-value-exchange-registration-info">
                            Noch keinen Account?
                            <a
                                href="https://www.autoonline.de/restwertboerse/restweertboerse-einsteller"
                                target="_blank"
                                rel="noopener">
                                Registriere dich bei Audatex AUTOonline
                            </a>
                            (keine Grundgebühr).
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END AUTOonline -->
            <!--============================================-->

            <!--============================================-->
            <!-- CARTV -->
            <!--============================================-->
            <div class="residual-and-market-value-exchange-container">
                <div id="cartv-residual-and-market-value-exchange" class="residual-and-market-value-exchange">
                    <!--********** Header **********-->
                    <div class="residual-and-market-value-exchange-header">
                        <img src="/assets/images/logos/cartv-inverted.png" alt="CARTV" />

                        <!--********** Settings **********-->
                        <div
                            *ngIf="userCanSynchronizeCredentials()"
                            class="residual-and-market-value-exchange-settings">
                            <mat-icon
                                class="toolbar-icon user-seal-submenu-icon"
                                [matMenuTriggerFor]="cartvSettingsMenu">
                                more_vert
                            </mat-icon>
                        </div>

                        <mat-menu #cartvSettingsMenu="matMenu" xPosition="after">
                            <!-- Synchronize credentials -->
                            <a mat-menu-item (click)="synchronizeCredentials('cartvUser')">
                                <mat-icon>sync</mat-icon>
                                Synchronisieren
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Credentials **********-->
                    <div class="residual-and-market-value-exchange-credentials">
                        <form id="cartv-credentials-container">
                            <mat-form-field
                                matTooltip="Die CarTV-Kundennummer ist gleichzeitig der CarTV-Benutzername."
                                class="mat-block">
                                <input
                                    matInput
                                    type="number"
                                    placeholder="Kundennummer"
                                    [(ngModel)]="selectedUser.cartvUser.customerNumber"
                                    (change)="
                                        saveUser(); completeTutorialStepResidualValueExchangeConfigured('cartvUser')
                                    "
                                    name="cartv-customer-number" />
                            </mat-form-field>
                            <password-input
                                type="text"
                                [(password)]="selectedUser.cartvUser.password"
                                (passwordChange)="
                                    saveUser(); completeTutorialStepResidualValueExchangeConfigured('cartvUser')
                                "
                                name="cartv-password"></password-input>
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="Zugangsschlüssel"
                                    [(ngModel)]="selectedUser.cartvUser.accessKey"
                                    (change)="
                                        saveUser(); completeTutorialStepResidualValueExchangeConfigured('cartvUser')
                                    "
                                    name="cartv-api-key" />
                                <mat-hint>
                                    <a
                                        class="secondary"
                                        href="https://wissen.autoixpert.de/hc/de/articles/360003976692?utm_source=autoixpert-app&utm_medium=preferences#api-key-section"
                                        target="_blank"
                                        rel="”noopener”">
                                        Wo finde ich den API-Schlüssel?
                                    </a>
                                </mat-hint>
                            </mat-form-field>
                        </form>

                        <!--********** Synchronization message **********-->
                        <synchronize-credentials
                            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.cartvUser"
                            (confirmClick)="synchronizeCredentials('cartvUser')"
                            (cancelClick)="dismissSynchronizationMessage('cartvUser')"></synchronize-credentials>

                        <!--********** Registration info **********-->
                        <div
                            *ngIf="
                                !selectedUser.cartvUser.customerNumber &&
                                !selectedUser.cartvUser.password &&
                                !selectedUser.cartvUser.accessKey
                            "
                            class="residual-and-market-value-exchange-registration-info">
                            Noch keinen Account?
                            <a href="https://www.cartv.eu/neuanmeldung-sv/" target="_blank" rel="noopener">
                                Registriere dich bei CARTV
                            </a>
                            (keine Grundgebühr).
                        </div>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END CARTV -->
            <!--============================================-->

            <!--============================================-->
            <!-- car.casion -->
            <!--============================================-->
            <div class="residual-and-market-value-exchange-container">
                <div id="carcasion-residual-and-market-value-exchange" class="residual-and-market-value-exchange">
                    <!--********** Header **********-->
                    <div class="residual-and-market-value-exchange-header">
                        <img src="/assets/images/logos/car-casion-white.png" alt="CAR.CASION" />
                        <div class="residual-and-market-value-exchange-name">
                            <div>CAR.CASION</div>
                            <div>von net.casion</div>

                            <!--********** Settings **********-->
                            <div
                                *ngIf="userCanSynchronizeCredentials()"
                                class="residual-and-market-value-exchange-settings">
                                <mat-icon
                                    class="toolbar-icon user-seal-submenu-icon"
                                    [matMenuTriggerFor]="carcasionSettingsMenu">
                                    more_vert
                                </mat-icon>
                            </div>

                            <mat-menu #carcasionSettingsMenu="matMenu" xPosition="after">
                                <!-- Synchronize credentials -->
                                <a mat-menu-item (click)="synchronizeCredentials('carcasionUser')">
                                    <mat-icon>sync</mat-icon>
                                    Synchronisieren
                                </a>
                            </mat-menu>
                        </div>
                    </div>

                    <!--********** Credentials **********-->
                    <div class="residual-and-market-value-exchange-credentials">
                        <form id="carcasion-credentials-container">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    type="email"
                                    placeholder="E-Mail"
                                    [(ngModel)]="selectedUser.carcasionUser.email"
                                    (change)="
                                        saveUser(); completeTutorialStepResidualValueExchangeConfigured('carcasionUser')
                                    "
                                    name="carcasion-customer-number" />
                            </mat-form-field>
                            <password-input
                                type="text"
                                [(password)]="selectedUser.carcasionUser.password"
                                (passwordChange)="
                                    saveUser(); completeTutorialStepResidualValueExchangeConfigured('carcasionUser')
                                "
                                name="carcasion-password"></password-input>
                            <div
                                class="third-field-stub"
                                *ngIf="
                                    !doesCarcasionEmailContainAutoixpertDomain(selectedUser.carcasionUser.email) &&
                                    !Environment.isDevelopment()
                                "></div>
                            <!--********** AutoiXpert Users Only Available in Development **********-->
                            <!--**********
                            car.casion sent us a bill once for testing their API in our beta or production system.
                            They asked us to only use their development systems beacuse they need to bill us in production.
                            We asked them to deactivate our production account. This info note informs autoiXpert team members about this.
                            **********-->
                            <div
                                class="info-note one-liner-note margin-top-10"
                                *ngIf="
                                    doesCarcasionEmailContainAutoixpertDomain(selectedUser.carcasionUser.email) &&
                                    !Environment.isDevelopment()
                                ">
                                <mat-icon class="medium-icon">info</mat-icon>
                                <div>
                                    Nur auf Development
                                    <span
                                        class="help-indicator"
                                        matTooltip="net.casion erlaubt leider keine kostenfreien Tests auf Produktion.{{
                                            '\n\n'
                                        }}Deshalb haben wir um Abstellung unseres Produktiv-Accounts gebeten. Demos mit der car.casion-Schnittstelle sind auf Produktion nicht möglich. Bitte auf Development testen.">
                                        ?
                                    </span>
                                </div>
                            </div>
                        </form>

                        <!--********** Synchronization message **********-->
                        <synchronize-credentials
                            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.carcasionUser"
                            (confirmClick)="synchronizeCredentials('carcasionUser')"
                            (cancelClick)="dismissSynchronizationMessage('carcasionUser')"></synchronize-credentials>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END car.casion -->
            <!--============================================-->

            <!--============================================-->
            <!-- WinValue -->
            <!--============================================-->
            <div class="residual-and-market-value-exchange-container">
                <div id="winvalue-residual-and-market-value-exchange" class="residual-and-market-value-exchange">
                    <!--********** Header **********-->
                    <div class="residual-and-market-value-exchange-header">
                        <img src="/assets/images/logos/winvalue-inverted.png" alt="WinValue" />

                        <!--********** Settings **********-->
                        <div
                            *ngIf="userCanSynchronizeCredentials()"
                            class="residual-and-market-value-exchange-settings">
                            <mat-icon
                                class="toolbar-icon user-seal-submenu-icon"
                                [matMenuTriggerFor]="winvalueSettingsMenu">
                                more_vert
                            </mat-icon>
                        </div>

                        <mat-menu #winvalueSettingsMenu="matMenu" xPosition="after">
                            <!-- Synchronize credentials -->
                            <a mat-menu-item (click)="synchronizeCredentials('winvalueUser')">
                                <mat-icon>sync</mat-icon>
                                Synchronisieren
                            </a>
                        </mat-menu>
                    </div>

                    <!--********** Credentials **********-->
                    <div class="residual-and-market-value-exchange-credentials">
                        <form id="winvalue-credentials-container">
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    type="number"
                                    placeholder="Kundennummer"
                                    [(ngModel)]="selectedUser.winvalueUser.customerNumber"
                                    (change)="
                                        saveUser(); completeTutorialStepResidualValueExchangeConfigured('winvalueUser')
                                    "
                                    name="winvalue-customer-number" />
                            </mat-form-field>
                            <password-input
                                type="text"
                                [(password)]="selectedUser.winvalueUser.password"
                                (passwordChange)="
                                    saveUser(); completeTutorialStepResidualValueExchangeConfigured('winvalueUser')
                                "
                                name="winvalue-password"></password-input>
                            <mat-form-field class="mat-block">
                                <input
                                    matInput
                                    placeholder="API-Schlüssel"
                                    [(ngModel)]="selectedUser.winvalueUser.apiKey"
                                    (change)="
                                        saveUser(); completeTutorialStepResidualValueExchangeConfigured('winvalueUser')
                                    "
                                    name="winvalue-api-key" />
                                <mat-hint>
                                    <a
                                        class="secondary"
                                        href="https://wissen.autoixpert.de/hc/de/articles/360004013091?utm_source=autoixpert-app&utm_medium=preferences#api-key-section"
                                        target="_blank"
                                        rel="”noopener”">
                                        Wo finde ich den API-Schlüssel?
                                    </a>
                                </mat-hint>
                            </mat-form-field>
                        </form>

                        <!--********** Synchronization message **********-->
                        <synchronize-credentials
                            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.winvalueUser"
                            (confirmClick)="synchronizeCredentials('winvalueUser')"
                            (cancelClick)="dismissSynchronizationMessage('winvalueUser')"></synchronize-credentials>
                    </div>
                </div>
            </div>
            <!--============================================-->
            <!-- END WinValue -->
            <!--============================================-->
        </div>
    </section>
    <!--============================================-->
    <!-- END Residual& Market Value Exchange -->
    <!--============================================-->

    <!--============================================-->
    <!-- Old Vehicle Certificates (German "Altfahrzeugzertifikate") -->
    <!--============================================-->
    <div
        id="afzzert-container"
        class="card card-with-padding preference-section-container section-with-external-logo"
        *ngIf="selectedUser?.afzzertUser">
        <h2 id="afzzert-heading">
            Afz-Zert. – Gesellschaft für Altfahrzeugzertifikate
            <span
                class="help-indicator"
                (click)="openAfzzertSummaryPage()"
                matTooltip="Altfahrzeugzertifikate helfen dem Anspruchsteller und Transportunternehmen, die Altfährzeuge transportieren, Strafen aufgrund fehlerhaften Transports zu vermeiden.{{
                    '\n\n'
                }}Für Details klicken.">
                ?
            </span>
        </h2>
        <form id="afzzert-credentials-container" class="flex-center-content flex-direction-column">
            <mat-form-field>
                <input
                    matInput
                    placeholder="API-Schlüssel"
                    type="string"
                    [(ngModel)]="selectedUser.afzzertUser.apiKey"
                    (change)="saveAfzzertUser()"
                    name="afzzert-api-key" />
                <span
                    matSuffix
                    class="help-indicator"
                    (click)="openAfzzertApiKeyPage()"
                    matTooltip="Deinen API-Schlüssel findest du im Afz-Zert.-Online-Portal.{{
                        '\n\n'
                    }}Klicke dazu auf dieses Fragezeichen.">
                    ?
                </span>
            </mat-form-field>

            <!--********** Synchronization message **********-->
            <synchronize-credentials
                *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.afzzertUser"
                (confirmClick)="synchronizeCredentials('afzzertUser')"
                (cancelClick)="dismissSynchronizationMessage('afzzertUser')"></synchronize-credentials>
        </form>
        <div id="afzzert-logo-container" class="preference-logo-container">
            <img src="/assets/images/logos/afzzert.svg" />
        </div>
    </div>
    <!--============================================-->
    <!-- END Old Vehicle Certificates (German "Altfahrzeugzertifikate") -->
    <!--============================================-->

    <!--============================================-->
    <!-- Factoring -->
    <!--============================================-->
    <div
        id="factoring-provider-container"
        class="card card-with-padding preference-section-container section-with-external-logo">
        <h2 id="factoring-provider-heading" class="no-margin-bottom">
            Factoring-Anbieter
            <span
                class="help-indicator"
                matTooltip="Die Kontaktdaten des Factoring Providers werden automatisch in die Abtretungserklärung übernommen. AutoiXpert erlaubt es dir auch, dem Anbieter das Gutachten per E-Mail zu senden.{{
                    '\n\n'
                }}Factoring-Anbieter zahlen dir gegen eine Gebühr von ca. 3-8 % des Rechnungsbetrags sofort deine Rechnungen aus und erleichtern dir damit deine Buchhaltung. Versicherer überweisen dann nach mehreren Wochen direkt an den Factoring-Anbieter.{{
                    '\n\n'
                }}Typische Anbieter für Sachverständige: KfzVS/DSR24, Goya Mobility, ADELTA.FINANZ">
                ?
            </span>
        </h2>

        <div class="text-align-center margin-bottom-15">
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/360003993092-Individueller-Factoring-Partner"
                target="_blank"
                rel="noopener">
                Was ist Factoring?
            </a>
        </div>

        <div id="factoring-provider-content-container" *ngIf="factoringProvider">
            <div id="factoring-provider-address-container">
                <h3>Anschrift</h3>
                <contact-person-form
                    [contact-person]="factoringProvider.contactPerson"
                    [show-phone-number]="false"
                    [show-person]="false"
                    [useMapsAutocompleteForPublicPlaces]="false"
                    [additionalContactPeopleForAutocomplete]="factoringProviderAddresses"
                    (contactPersonChange)="saveFactoringProvider()"
                    (contactPersonSelection)="
                        insertFactoringProviderBankAccount(); saveFactoringProvider()
                    "></contact-person-form>
            </div>
            <div id="factoring-provider-bank-account-container">
                <h3>
                    Bankverbindung
                    <span
                        class="help-indicator"
                        matTooltip="Die Bankverbindung kann auf Rechnungen an deine Kunden ausgewiesen werden, wenn du Factoring in einem Vorgang aktiviert hast.">
                        ?
                    </span>
                </h3>
                <mat-form-field id="factoring-provider-iban" class="mat-block">
                    <input
                        matInput
                        placeholder="IBAN"
                        [(ngModel)]="factoringProvider.bankAccount.iban"
                        iban-input
                        (ibanChange)="saveFactoringProvider()" />
                </mat-form-field>
                <mat-form-field id="factoring-provider-account-owner" class="mat-block">
                    <input
                        matInput
                        placeholder="Kontoinhaber"
                        [(ngModel)]="factoringProvider.bankAccount.owner"
                        (change)="saveFactoringProvider()" />
                </mat-form-field>
                <mat-form-field id="factoring-provider-bic" class="mat-block">
                    <input
                        matInput
                        placeholder="BIC"
                        [(ngModel)]="factoringProvider.bankAccount.bic"
                        (change)="saveFactoringProvider()" />
                </mat-form-field>
                <mat-form-field id="factoring-provider-bank-name" class="mat-block">
                    <input
                        matInput
                        placeholder="Name der Bank"
                        [(ngModel)]="factoringProvider.bankAccount.bankName"
                        (change)="saveFactoringProvider()" />
                </mat-form-field>
            </div>
        </div>
        <div id="factoring-provider-logo-container" class="preference-logo-container">
            <div id="factoring-provider-icon-container">
                <mat-icon id="factoring-provider-icon">euro_symbol</mat-icon>
            </div>
        </div>
    </div>
    <!--============================================-->
    <!-- END Factoring -->
    <!--============================================-->

    <!--============================================-->
    <!-- Adelta.Finanz -->
    <!--============================================-->
    <div
        id="adelta-container"
        class="card card-with-padding preference-section-container section-with-external-logo"
        *ngIf="
            selectedUser &&
            (selectedUser.adeltaFinanzUser?.customerNumber || getCurrentFactoringProvider() === 'adelta')
        ">
        <h2 id="adelta-heading">ADELTA.FINANZ</h2>
        <form id="adelta-credentials-container">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Benutzername"
                    [(ngModel)]="selectedUser.adeltaFinanzUser.username"
                    (change)="saveAdeltaFinanzUser()"
                    name="adelta-finanz-username" />
                <span
                    matSuffix
                    class="help-indicator"
                    matTooltip="Bsp.: GXAB123C. Bitte trage hier deine Schnittstellen-Zugangsdaten ein. Dies sind andere Zugangsdaten als diejenigen, die du für das Login auf die Weboberfläche (https://www.adeltafinanz.org) verwendest. Bitte wende dich an deinen ADELTA.FINANZ Ansprechpartner, falls die Daten unbekannt sind.">
                    ?
                </span>
            </mat-form-field>
            <password-input
                type="text"
                [(password)]="selectedUser.adeltaFinanzUser.password"
                (passwordChange)="saveAdeltaFinanzUser()"
                name="adelta-finanz-password"></password-input>
            <mat-form-field class="third-field">
                <input
                    matInput
                    placeholder="Kundennummer"
                    [(ngModel)]="selectedUser.adeltaFinanzUser.customerNumber"
                    (change)="adeltaCustomerNumber.validity.valid && saveAdeltaFinanzUser()"
                    name="adelta-finanz-customer-number"
                    pattern="[0-9]*"
                    #adeltaCustomerNumber />
                <mat-hint *ngIf="!adeltaCustomerNumber.validity.valid">Nur Ziffern erlaubt</mat-hint>
            </mat-form-field>
            <img
                id="adeltafinanz-import-pending-icon"
                *ngIf="adeltafinanzImportPending"
                src="/assets/images/icons/loading-indicator.svg"
                matTooltip="Der Import aus ADELTA.FINANZ läuft..." />
            <img
                id="adeltafinanz-import-icon"
                *ngIf="mayStartAdeltafinanzImport() && !adeltafinanzImportPending"
                src="/assets/images/icons/download_24.svg"
                (click)="mayStartAdeltafinanzImport() && importAdeltafinanzDebtors()"
                matTooltip="Debitoren aus ADELTA importieren." />
        </form>

        <!--********** Synchronization message **********-->
        <synchronize-credentials
            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.adeltaFinanzUser"
            (confirmClick)="synchronizeCredentials('adeltaFinanzUser')"
            (cancelClick)="dismissSynchronizationMessage('adeltaFinanzUser')"></synchronize-credentials>

        <div id="adelta-logo-container" class="preference-logo-container">
            <img src="/assets/images/logos/adelta-finanz.png" />
        </div>
    </div>
    <!--============================================-->
    <!-- END Adelta.Finanz -->
    <!--============================================-->

    <!--============================================-->
    <!-- KfzVS -->
    <!--============================================-->
    <div
        id="kfzvs-container"
        class="card card-with-padding preference-section-container section-with-external-logo"
        *ngIf="selectedUser && (selectedUser.kfzvsUser?.customerNumber || getCurrentFactoringProvider() === 'kfzvs')">
        <h2 id="kfzvs-heading">KfzVS</h2>
        <form id="kfzvs-credentials-container">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Kundennummer"
                    type="number"
                    [(ngModel)]="selectedUser.kfzvsUser.customerNumber"
                    (change)="saveKfzvsUser()"
                    name="kfzvs-customer-number" />
                <span
                    matSuffix
                    class="help-indicator"
                    matTooltip="Bitte trage hier deine KfzVS-Kundennummer ein. Bitte wende dich an deinen KfzVS-Ansprechpartner, falls dir die Kundennummer unbekannt ist.">
                    ?
                </span>
            </mat-form-field>

            <!--********** Synchronization message **********-->
            <synchronize-credentials
                *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.kfzvsUser"
                (confirmClick)="synchronizeCredentials('kfzvsUser')"
                (cancelClick)="dismissSynchronizationMessage('kfzvsUser')"></synchronize-credentials>
        </form>
        <div id="kfzvs-logo-container" class="preference-logo-container">
            <img src="/assets/images/logos/kfzvs.png" />
        </div>
    </div>
    <!--============================================-->
    <!-- END KfzVS -->
    <!--============================================-->

    <!--============================================-->
    <!-- Persaldo -->
    <!--============================================-->
    <div
        id="persaldo-container"
        class="card card-with-padding preference-section-container section-with-external-logo"
        *ngIf="selectedUser && (selectedUser.persaldoUser?.username || getCurrentFactoringProvider() === 'persaldo')">
        <h2>Goya Mobility (ehemals Persaldo24)</h2>
        <form id="persaldo-credentials-container">
            <mat-form-field id="persaldo-username-input">
                <input
                    matInput
                    placeholder="Benutzername"
                    [(ngModel)]="selectedUser.persaldoUser.username"
                    (change)="savePersaldoUser()"
                    name="persaldo-username" />
                <span
                    matSuffix
                    class="help-indicator"
                    matTooltip="Bitte wende dich an deinen Goya-Mobility-Ansprechpartner, falls du noch keinen Zugang hast.">
                    ?
                </span>
            </mat-form-field>

            <password-input
                type="text"
                [(password)]="selectedUser.persaldoUser.password"
                (passwordChange)="savePersaldoUser()"
                name="persaldo-password"></password-input>
        </form>

        <!--********** Synchronization message **********-->
        <synchronize-credentials
            [class]="'margin-bottom-20'"
            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.persaldoUser"
            (confirmClick)="synchronizeCredentials('persaldoUser')"
            (cancelClick)="dismissSynchronizationMessage('persaldoUser')"></synchronize-credentials>

        <div class="info-note label">
            <mat-icon class="outlined">info</mat-icon>
            <div>
                <h3>Nur eigene Abtretung verwenden</h3>
                Für Goya Mobility ist es erforderlich, dass du deine eigene Abtretung ohne Verweis auf einen
                Factoring-Anbieter verwendest.
                <br />
                <br />
                Bitte entferne als Goya-Mobility-Nutzer deshalb aus dem Textbaustein "Abtretung/Auftrag" die Varianten
                für Factoring-Provider & die Bedingung aus der Variante ohne Factoring-Anbieter. Das Ziel ist, dass
                deine eigene Abtretung immer abgedruckt wird.
                <br />
                <br />
                Falls du Hilfe benötigst, kontaktiere die
                <a href="/Hilfe">Hotline</a>
                .
            </div>
        </div>
        <div id="persaldo-logo-container" class="preference-logo-container">
            <img src="/assets/images/logos/goya-mobility-weiss.png" />
        </div>
    </div>
    <!--============================================-->
    <!-- END Persaldo -->
    <!--============================================-->

    <!--============================================-->
    <!-- crashback24 -->
    <!--============================================-->
    <div
        id="crashback24-container"
        class="card card-with-padding preference-section-container section-with-external-logo"
        *ngIf="selectedUser?.crashback24User?.username">
        <h2>crashback24</h2>
        <form id="crashback24-credentials-container">
            <mat-form-field id="crashback24-username-input">
                <input
                    matInput
                    placeholder="Benutzername"
                    [(ngModel)]="selectedUser.crashback24User.username"
                    (change)="saveCrashback24User()"
                    name="crashback24-username" />
                <span
                    matSuffix
                    class="help-indicator"
                    [matTooltip]="
                        'Bitte wende dich an deinen crashback24-Ansprechpartner, falls du noch keinen Zugang hast.\n\nKlicke, um die crashback24.de aufzurufen.'
                    "
                    (click)="openCrashback24Website()">
                    ?
                </span>
            </mat-form-field>

            <password-input
                type="text"
                [(password)]="selectedUser.crashback24User.password"
                (passwordChange)="saveCrashback24User()"
                name="crashback24-password"></password-input>
        </form>

        <!--********** Synchronization message **********-->
        <synchronize-credentials
            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.crashback24User"
            (confirmClick)="synchronizeCredentials('crashback24User')"
            (cancelClick)="dismissSynchronizationMessage('crashback24User')"></synchronize-credentials>

        <div id="crashback24-logo-container" class="preference-logo-container">
            <img src="/assets/images/logos/crashback24-inverted.svg" />
        </div>
    </div>
    <!--============================================-->
    <!-- END crashback24 -->
    <!--============================================-->

    <!--============================================-->
    <!-- GDV -->
    <!--============================================-->
    <div
        id="gdv-container"
        class="card card-with-padding preference-section-container section-with-external-logo"
        *ngIf="selectedUser?.gdvUser">
        <h2>GDV - Zentralruf der Autoversicherer</h2>
        <form id="gdv-credentials-container">
            <mat-form-field id="gdv-username-input">
                <input
                    matInput
                    placeholder="Benutzername"
                    [(ngModel)]="selectedUser.gdvUser.username"
                    (change)="saveGdvUser()"
                    name="gdv-username" />
            </mat-form-field>

            <password-input
                type="text"
                [(password)]="selectedUser.gdvUser.password"
                (passwordChange)="saveGdvUser()"
                name="gdv-password"></password-input>
        </form>

        <!--********** Synchronization message **********-->
        <synchronize-credentials
            *ngIf="userCanSynchronizeCredentials() && showSynchronizationMessage.gdvUser"
            (confirmClick)="synchronizeCredentials('gdvUser')"
            (cancelClick)="dismissSynchronizationMessage('gdvUser')"></synchronize-credentials>

        <!--********** No User Yet **********-->
        <div
            class="text-align-center margin-top-10"
            *ngIf="!selectedUser.gdvUser.username && !selectedUser.gdvUser.password">
            Noch keinen Account?
            <a
                href="mailto:kundenbetreuung@gdv-dl.de?subject=Z%40Online%20VPN-Zugang%20-%20Neuvertrag&body=Sehr%20geehrte%20Damen%20und%20Herren%2C%0A%0Ahiermit%20m%C3%B6chte%20ich%20einen%20Neuvertrag%20f%C3%BCr%20den%20Abruf%20der%20Versicherungsdaten%20eines%20Unfallverursachers%20per%20VPN%20beantragen.%0A%0AIch%20bin%20Sachverst%C3%A4ndiger%20und%20werde%20haupts%C3%A4chlich%20die%20VPN-Schnittstelle%20von%20autoiXpert%20nutzen.%0A%0AVielen%20Dank%20und%20freundliche%20Gr%C3%BC%C3%9Fe%0A%3CIhr%20Name%3E"
                target="_blank"
                rel="noopener">
                Registriere dich bei Z&#64;-Online des GDV
            </a>
            (kostenfrei).
        </div>

        <div id="gdv-logo-container" class="preference-logo-container">
            <img src="/assets/images/logos/gdv-logo.svg" />
        </div>
    </div>
    <!--============================================-->
    <!-- END GDV -->
    <!--============================================-->

    <!--============================================-->
    <!-- Team - Members -->
    <!--============================================-->
    <div
        id="team-members"
        *ngIf="team"
        class="card card-with-padding preference-section-container reduced-padding-right">
        <div id="team-members-heading-and-toolbar-container">
            <h2>Nutzer</h2>
        </div>

        <team-members
            [user]="loggedInUser"
            [team]="team"
            (userToAdministrateSelected)="selectUser($event, { showToast: true })" />

        <!--<div id="delete-account-container">-->
        <!--<button class="secondary delete" (click)="deleteOfficeAccount()">Team-Account kündigen</button>-->
        <!--</div>-->
    </div>
    <!--============================================-->
    <!-- END Team - Members -->
    <!--============================================-->

    <!--============================================-->
    <!-- Report Token -->
    <!--============================================-->
    <div
        id="report-token-container"
        class="card card-with-padding preference-section-container reduced-padding-right"
        *ngIf="team">
        <report-token-preferences
            [teamMembers]="teamMembers"
            [user]="selectedUser"
            [team]="team"></report-token-preferences>

        <!--********** Deactivated overlay **********-->
        <preference-section-deactivated
            [isDeactivated]="loggedInUser?._id !== selectedUser?._id"
            (switchToLoggedInUser)="selectUser(loggedInUser)"></preference-section-deactivated>
    </div>
    <!--============================================-->
    <!-- END Report Token -->
    <!--============================================-->

    <!--============================================-->
    <!-- Invoice Counter -->
    <!--============================================-->
    <div
        id="invoice-number-container"
        class="card card-with-padding preference-section-container reduced-padding-right position-relative"
        *ngIf="team">
        <invoice-number-preferences
            [teamMembers]="teamMembers"
            [user]="selectedUser"
            [team]="team"
            (scrollToReportTokenSettings)="scrollIntoView('#report-token-container')"></invoice-number-preferences>

        <!--********** Deactivated overlay **********-->
        <preference-section-deactivated
            [isDeactivated]="loggedInUser?._id !== selectedUser?._id"
            (switchToLoggedInUser)="selectUser(loggedInUser)"></preference-section-deactivated>
    </div>
    <!--============================================-->
    <!-- END Invoice Counter -->
    <!--============================================-->

    <!--============================================-->
    <!-- Invoice Settings -->
    <!--============================================-->
    <div
        id="invoice-settings"
        class="card card-with-padding preference-section-container reduced-padding-right"
        *ngIf="team">
        <h2>Rechnung</h2>

        <h3>
            Mehrwertsteuer
            <span class="help-indicator" matTooltip="Der Mehrwertsteuersatz wird für jede neue Rechnung verwendet.">
                ?
            </span>
        </h3>

        <div id="vat-options-row">
            <!--********** Small Entrepreneur **********-->
            <div
                class="vat-option-container"
                (click)="selectVatRate(0); saveTeam()"
                [ngClass]="{ selected: team.invoicing.vatRate === 0 }">
                <div class="vat-option-icon-and-rate-container">
                    <img class="vat-option-icon" src="/assets/images/icons/seedling.png" alt="Kleinunternehmer Icon" />
                    <span class="vat-option-rate">0%</span>
                </div>
                <div class="vat-option-description">Kleinunternehmer</div>
            </div>

            <!--********** German Standard Rate **********-->
            <div
                class="vat-option-container"
                (click)="selectVatRate(0.19); saveTeam()"
                [ngClass]="{ selected: team.invoicing.vatRate === 0.19 }">
                <div class="vat-option-icon-and-rate-container">
                    <img class="vat-option-icon" src="/assets/images/icons/germany-ball.png" alt="Deutschland Icon" />
                    <span class="vat-option-rate" *ngIf="!isReducedVatRateForSecondHalfOf2020Active()">19%</span>
                    <span class="vat-option-rate" *ngIf="isReducedVatRateForSecondHalfOf2020Active()">16%</span>
                </div>
                <div class="vat-option-description">Regelsatz Deutschland</div>
            </div>

            <!--********** Austrian Standard Rate **********-->
            <div
                class="vat-option-container"
                (click)="selectVatRate(0.2); saveTeam()"
                [ngClass]="{ selected: team.invoicing.vatRate === 0.2 }">
                <div class="vat-option-icon-and-rate-container">
                    <img class="vat-option-icon" src="/assets/images/icons/austria-ball.png" alt="Österreich Icon" />
                    <span class="vat-option-rate">20%</span>
                </div>
                <div class="vat-option-description">Regelsatz Österreich</div>
            </div>
        </div>

        <!--********** Book invoice automatically **********-->
        <div id="invoice-booking-container">
            <h3>Rechnung festschreiben</h3>
            <mat-checkbox
                [(ngModel)]="team.preferences.bookInvoiceWhenLockingReport"
                [disabled]="!hasAccessRight(selectedUser, 'editTextsAndDocumentBuildingBlocks')"
                (change)="saveTeam()"
                [matTooltip]="
                    hasAccessRight(selectedUser, 'editTextsAndDocumentBuildingBlocks')
                        ? 'Rechnungen bei Gutachtenabschluss unveränderlich festhalten. Veränderungen sind anschließend nur durch Storno möglich.'
                        : getMissingAccessRightTooltip('editTextsAndDocumentBuildingBlocks')
                ">
                Rechnungen bei Gutachtenabschluss automatisch festschreiben
            </mat-checkbox>
        </div>

        <div id="tax-ids-and-commercial-register-row">
            <!--********** Tax IDs **********-->
            <div id="tax-number-container">
                <h3 id="tax-numbers-heading">
                    Steuernummern
                    <span
                        class="help-indicator"
                        matTooltip="Jeder Bürger und jedes Unternehmen besitzt eine Steuernummer (Format 123/4567/8910).{{
                            '\n\n'
                        }}Falls du auch international Waren einkaufst oder verkaufst, brauchst du auch eine Umsatzsteuer-ID (Format DE123456789).{{
                            '\n\n'
                        }}Beides nicht zu verwechseln mit deiner privaten Steuer-ID (Format 12 345 678 901), die du nicht bei uns hinterlegst.">
                        ?
                    </span>
                </h3>

                <!--********** German VAT ID **********-->
                <mat-form-field
                    id="team-vat-id"
                    class="mat-block"
                    [matTooltip]="
                        isAdmin(selectedUser)
                            ? 'Die Steuernummer wird auf Rechnungen an deine Kunden ausgewiesen. Sie oder die USt-IdNr. sind für eine gültige Rechnung erforderlich.\n\nHast du noch keine, trage 000/000/00000 ein, damit E-Rechnungen funktionieren.\n\nDas Format kann pro Bundesland leicht abweichen.\n\nBeispiel: 181/815/08155'
                            : getAskYourAdminTooltip()
                    ">
                    <input
                        matInput
                        placeholder="Steuernummer"
                        [(ngModel)]="team.vatId"
                        (change)="validateGermanTaxId(); saveTeam()"
                        [disabled]="!isAdmin(selectedUser)" />
                    <mat-hint *ngIf="isGermanTaxIdInvalid" class="warning">
                        Ungültiges Format. Mehr Details im Tooltip.
                    </mat-hint>
                </mat-form-field>

                <!--********** European VAT ID **********-->
                <mat-form-field
                    id="team-european-vat-id"
                    class="mat-block"
                    [matTooltip]="
                        isAdmin(selectedUser)
                            ? 'Die Umsatzsteuer-Identifikationsnummer wird auf Rechnungen an deine Kunden ausgewiesen.\n\nSie ist erforderlich, wenn du Rechnungen außerhalb Deutschlands schreibst. Wenn du keine hast, Feld leer lassen und Steuernummer oben eintragen. Wenn du die Steuernummer und die USt-IdNr. angibst, wird die USt-IdNr. abgedruckt.\n\nBeispiel: DE123456789'
                            : getAskYourAdminTooltip()
                    ">
                    <input
                        matInput
                        placeholder="USt-IdNr."
                        [(ngModel)]="team.europeanVatId"
                        (change)="validateEuropeanVatId(); saveTeam()"
                        [disabled]="!isAdmin(selectedUser)" />
                    <mat-hint *ngIf="isEuropeanVatIdInvalid && !isGermanTaxIdInEuropeanVatIdField" class="warning">
                        Ungültiges Format. Format DE + 9 Ziffern. Mehr Details im Tooltip.
                    </mat-hint>
                    <mat-hint *ngIf="isGermanTaxIdInEuropeanVatIdField" class="warning">
                        Sieht aus wie eine Steuernummer. Die gehört in das Feld Steuernummer.
                    </mat-hint>
                </mat-form-field>
            </div>

            <!--********** Commercial Register ID **********-->
            <div id="commercial-register-container">
                <h3 id="commercial-register-heading">
                    Handelsregister
                    <span
                        class="help-indicator"
                        matTooltip="Jedes Unternehmen muss auf seinen Geschäftsbriefen (typischerweise Anschreiben, Rechnungen, E-Mails, ...) seinen Handelsregistereintrag nennen, bestehend aus Nummer und dem Namen/Ort des Registers.{{
                            '\n\n'
                        }}Ausgenommen sind: Freiberufler, Kleinunternehmer, GbR.{{
                            '\n\n'
                        }}Hinterlegst du die Nummern hier, werden sie automatisch in unserer Standard-Kopf-&-Fußzeile abgedruckt.">
                        ?
                    </span>
                </h3>
                <mat-form-field
                    id="team-commercial-register-name"
                    class="mat-block"
                    [matTooltip]="
                        isAdmin(selectedUser)
                            ? 'Der Name des Handelsregisters, in dem deine Firma registriert ist.\n\nParagraph § 37a HBG schreibt die Info für alle Geschäftsbriefe von Kaufmännern vor. Freiberufler wie sind z. B. keine Kaufmänner. Frage deinen Steuerberater, ob du Kaufmann bist.\n\nBeispiel: Amtsgericht München'
                            : getAskYourAdminTooltip()
                    ">
                    <input
                        matInput
                        placeholder="Name des Handelsregisters"
                        [(ngModel)]="team.commercialRegisterName"
                        (change)="saveTeam()"
                        [disabled]="!isAdmin(selectedUser)" />
                </mat-form-field>
                <mat-form-field
                    id="team-commercial-register-id"
                    class="mat-block"
                    [matTooltip]="
                        isAdmin(selectedUser)
                            ? 'Die Handelsregisternummer wird auf Rechnungen an deine Kunden ausgewiesen.\n\nParagraph § 37a HBG schreibt sie für alle Geschäftsbriefe von Kaufmännern vor. Freiberufler wie sind z. B. keine Kaufmänner. Frage deinen Steuerberater, ob du Kaufmann bist.\n\nBeispiel: HRA 12345'
                            : getAskYourAdminTooltip()
                    ">
                    <input
                        matInput
                        placeholder="Handelsregisternummer"
                        [(ngModel)]="team.commercialRegisterId"
                        (change)="saveTeam()"
                        [disabled]="!isAdmin(selectedUser)" />
                </mat-form-field>
            </div>
        </div>

        <div id="invoicing-bank-account-container">
            <h3>
                Bankverbindung
                <span class="help-indicator" matTooltip="Die Bankverbindung für Rechnungen an deine Kunden.">?</span>
            </h3>

            <div class="margin-bottom-20">
                <div class="iban-and-bic-container">
                    <!--********** IBAN **********-->
                    <mat-form-field
                        id="team-iban"
                        class="mat-block"
                        [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                        <input
                            matInput
                            iban-input
                            placeholder="IBAN"
                            [(ngModel)]="team.invoicing.bankAccount.iban"
                            (input)="isFirstBankAccountIbanInvalid = false"
                            (ibanChange)="handleIbanChangeEvent($event, team.invoicing.bankAccount); saveTeam()"
                            (bicRecognized)="handleBicRecognition($event, team.invoicing.bankAccount)"
                            [disabled]="!isAdmin(selectedUser)" />
                        <mat-hint *ngIf="isFirstBankAccountIbanInvalid" class="error">IBAN ungültig</mat-hint>
                    </mat-form-field>

                    <!--********** BIC **********-->
                    <mat-form-field
                        id="team-bic"
                        class="mat-block"
                        [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                        <input
                            matInput
                            placeholder="BIC"
                            [(ngModel)]="team.invoicing.bankAccount.bic"
                            (change)="saveTeam()"
                            [disabled]="!isAdmin(selectedUser)" />
                    </mat-form-field>
                </div>

                <div class="account-owner-and-bankname-container">
                    <!--********** Bank Account Owner **********-->
                    <div class="team-account-owner-container">
                        <mat-form-field
                            id="team-account-owner"
                            class="mat-block"
                            [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                            <input
                                matInput
                                placeholder="Kontoinhaber"
                                [(ngModel)]="team.invoicing.bankAccount.owner"
                                (change)="saveTeam()"
                                [disabled]="!isAdmin(selectedUser)" />
                        </mat-form-field>
                        <mat-icon
                            class="copy-team-account-owner-icon"
                            *ngIf="isAdmin(selectedUser)"
                            (click)="copyTeamNameToBankAccountOwner(team.invoicing.bankAccount)"
                            matTooltip="Teamnamen einfügen">
                            home
                        </mat-icon>
                    </div>

                    <!--********** Bank Name **********-->
                    <mat-form-field
                        id="team-bank-name"
                        class="mat-block"
                        [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                        <input
                            matInput
                            placeholder="Name der Bank"
                            [(ngModel)]="team.invoicing.bankAccount.bankName"
                            (change)="saveTeam()"
                            [disabled]="!isAdmin(selectedUser)" />
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="team.invoicing.secondBankAccount">
                <div class="display-flex flex-align-center">
                    <h4 class="flex-grow">2. Bankverbindung</h4>

                    <!--********** Delete Icon **********-->
                    <mat-icon
                        class="toolbar-icon"
                        (click)="deleteSecondBankAccount()"
                        matTooltip="2. Bankverbindung löschen">
                        delete
                    </mat-icon>
                </div>
                <div class="iban-and-bic-container">
                    <!--********** IBAN **********-->
                    <mat-form-field
                        class="mat-block"
                        [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                        <input
                            matInput
                            iban-input
                            placeholder="IBAN"
                            [(ngModel)]="team.invoicing.secondBankAccount.iban"
                            (input)="isFirstBankAccountIbanInvalid = false"
                            (ibanChange)="handleIbanChangeEvent($event, team.invoicing.secondBankAccount); saveTeam()"
                            (bicRecognized)="handleBicRecognition($event, team.invoicing.secondBankAccount)"
                            [disabled]="!isAdmin(selectedUser)" />
                        <mat-hint *ngIf="isSecondBankAccountIbanInvalid" class="error">IBAN ungültig</mat-hint>
                    </mat-form-field>

                    <!--********** BIC **********-->
                    <mat-form-field
                        class="mat-block"
                        [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                        <input
                            matInput
                            placeholder="BIC"
                            [(ngModel)]="team.invoicing.secondBankAccount.bic"
                            (change)="saveTeam()"
                            [disabled]="!isAdmin(selectedUser)" />
                    </mat-form-field>
                </div>

                <div class="account-owner-and-bankname-container">
                    <!--********** Bank Account Owner **********-->
                    <div class="team-account-owner-container">
                        <mat-form-field
                            class="mat-block"
                            [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                            <input
                                matInput
                                placeholder="Kontoinhaber"
                                [(ngModel)]="team.invoicing.secondBankAccount.owner"
                                (change)="saveTeam()"
                                [disabled]="!isAdmin(selectedUser)" />
                        </mat-form-field>
                        <mat-icon
                            class="copy-team-account-owner-icon"
                            *ngIf="isAdmin(selectedUser)"
                            (click)="copyTeamNameToBankAccountOwner(team.invoicing.secondBankAccount)"
                            matTooltip="Teamnamen einfügen">
                            home
                        </mat-icon>
                    </div>

                    <!--********** Bank Name **********-->
                    <mat-form-field
                        class="mat-block"
                        [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                        <input
                            matInput
                            placeholder="Name der Bank"
                            [(ngModel)]="team.invoicing.secondBankAccount.bankName"
                            (change)="saveTeam()"
                            [disabled]="!isAdmin(selectedUser)" />
                    </mat-form-field>
                </div>
            </div>
            <section class="text-align-center margin-top-20" *ngIf="!team.invoicing.secondBankAccount">
                <button class="flat" (click)="showSecondBankAccount()">
                    <mat-icon class="small-icon">add</mat-icon>
                    2. Bankverbindung
                </button>
            </section>
        </div>

        <div class="info-note margin-top-20" *ngIf="displayWarningIfBankAccountsDiffer()" @slideInAndOutVertically>
            <mat-icon class="outlined">info</mat-icon>
            <div>
                <h3>Bankverbindung weicht ab</h3>
                <p>
                    Das Konto, das du auf deiner Rechnung abdruckst, weicht von dem Konto ab, von dem wir abbuchen ({{
                        team.billing.bankAccount.iban
                    }}). Wenn du möchtest, dass wir von dem neuen Konto abbuchen,
                    <a class="link" (click)="openDirectDebitDialog()">aktualisiere bitte das SEPA-Mandat</a>
                    .
                </p>
            </div>
            <mat-icon
                class="info-note-close-icon toolbar-icon"
                (click)="closeBillingIBANWarning()"
                matTooltip="Hinweis für diese IBAN ausblenden">
                close
            </mat-icon>
        </div>

        <!--********** Deactivated overlay **********-->
        <preference-section-deactivated
            [isDeactivated]="loggedInUser?._id !== selectedUser?._id"
            (switchToLoggedInUser)="selectUser(loggedInUser)"></preference-section-deactivated>
    </div>
    <!--============================================-->
    <!-- END Invoice Settings -->
    <!--============================================-->

    <!--============================================-->
    <!-- Team  - Payment Details -->
    <!--============================================-->
    <div
        id="autoixpert-payment-info-container"
        class="card card-with-padding preference-section-container"
        *ngIf="
            team &&
            (team.accountStatus !== 'test' || team.becameCustomerAt) &&
            !team.audatexFeatures?.qapterixpertLayout &&
            userIsAdmin()
        ">
        <h2 id="team-heading">Abrechnung autoiXpert</h2>

        <div id="payment-and-invoice-history-container">
            <!--********** Payment Container **********-->
            <div id="payment-column-container">
                <div id="payment-container" (click)="openDirectDebitDialog()" matTooltip="Zahlungsdetails bearbeiten">
                    <!--********** Logo **********-->
                    <div id="payment-decorative-logo-container" class="margin-bottom-20">
                        <img src="/assets/images/icons/pentagons-logo.svg" alt="" />
                    </div>

                    <!--********** Direct Debit **********-->
                    <div
                        id="sepa-details"
                        class="payment-text-container"
                        *ngIf="team.billing.paymentMethod === 'directDebit'">
                        <!--********** Account Holder **********-->
                        <div class="monospaced">{{ team.billing.bankAccount.owner }}</div>

                        <!--********** IBAN **********-->
                        <div>
                            <div class="label">IBAN</div>
                            <div class="monospaced">{{ formatIban(team.billing.bankAccount.iban) }}</div>
                        </div>

                        <!--********** Edit Icon **********-->
                        <mat-icon class="toolbar-icon medium-icon">edit</mat-icon>
                    </div>

                    <!--********** Invoice **********-->
                    <div class="payment-text-container" *ngIf="team.billing.paymentMethod === 'invoice'">
                        <h3>Überweisung</h3>
                        <p>Du überweist die autoiXpert-Gebühr selbst.</p>
                    </div>

                    <!--********** Nothing Yet **********-->
                    <div class="payment-text-container" *ngIf="team.billing.paymentMethod === null">
                        <h3>Zahlungsart wählen</h3>
                        <p>Wähle zwischen SEPA-Lastschrift & Rechnung.</p>
                    </div>
                </div>

                <div class="text-align-center">
                    <div class="label" *ngIf="team.billing.paymentCycle">
                        Zahlungszyklus:
                        <span class="semibold">
                            {{ team.billing.paymentCycle === 'monthly' ? 'monatlich' : 'jährlich' }}
                        </span>
                    </div>
                    <div
                        id="sepa-download-link"
                        class="link"
                        *ngIf="team.billing.paymentMethod === 'directDebit'"
                        (click)="downloadDirectDebitMandate()">
                        SEPA-Mandat
                    </div>
                </div>
            </div>

            <!--********** Invoice Address Container **********-->
            <div>
                <div
                    id="invoice-address-container"
                    class="invoice-container display-flex flex-center-content cursor-pointer"
                    (click)="openInvoiceAddressDialog()"
                    matTooltip="Rechnungsadresse bearbeiten">
                    <!--********** Postage Stamp **********-->
                    <img
                        id="invoice-address-postage-stamp"
                        src="/assets/images/icons/postage-stamp.svg"
                        alt="Briefmarke" />

                    <!--********** Address **********-->
                    <div id="invoice-address-content">
                        <p>
                            <strong>{{ team.billing.address.organization }}</strong>
                            <br />
                            {{ team.billing.address.firstName }} {{ team.billing.address.lastName }}
                        </p>
                        <p>
                            {{ team.billing.address.streetAndHouseNumberOrLockbox }}
                            <br />
                            {{ team.billing.address.zip + ' ' + team.billing.address.city }}
                        </p>
                    </div>

                    <!--********** Edit Icon **********-->
                    <div id="invoice-address-edit-icon">
                        <mat-icon class="toolbar-icon medium-icon">edit</mat-icon>
                    </div>
                </div>
                <div class="text-align-center">
                    <div class="label">Rechnungsadresse</div>
                </div>
            </div>

            <!--********** Invoice History Container **********-->
            <div>
                <div
                    id="invoice-history-container"
                    class="invoice-container display-flex flex-center-content cursor-pointer"
                    (click)="openInvoiceHistory()">
                    <!--********** Icon **********-->
                    <div class="icon-on-background-with-rim margin-right-30">
                        <mat-icon id="invoice-history-euro-symbol">euro_symbol</mat-icon>
                    </div>

                    <!--********** Title **********-->
                    <div id="invoice-history-title">
                        Meine
                        <br />
                        Abrechnungen
                    </div>
                </div>
                <div class="text-align-center">
                    <div
                        id="cancel-account-link"
                        class="link"
                        *ngIf="!team.expirationDate"
                        (click)="openDeleteTeamDialog()">
                        Account kündigen
                    </div>
                </div>
            </div>
        </div>
        <div class="text-align-center delete-account">
            <!-- Paying- or Partner-Account will expire -->
            <div
                *ngIf="team.accountStatus !== 'test' && team.expirationDate"
                class="info-note error-note margin-bottom-15 centered"
                matTooltip="Dein Acount wird nicht verlängert. Falls du dein Account doch weiter nutzen möchtest, kontaktiere die Hotline."
                @slideInAndOutVertically>
                <mat-icon>error</mat-icon>
                <div>
                    Account wird am
                    <strong>{{ team.expirationDate | moment: 'DD.MM.YYYY' }}</strong>
                    deaktiviert.
                </div>
            </div>

            <!-- Test-Account will become customer -->
            <ng-container *ngIf="team.accountStatus === 'test' && team.expirationDate && team.becameCustomerAt">
                <div *ngIf="moment(team.expirationDate).isBefore(moment(team.becameCustomerAt), 'day')" class="label">
                    Du hast autoiXpert zum {{ team.becameCustomerAt | moment: 'DD.MM.YYYY' }} gebucht. Da deine
                    Testversion am {{ team.expirationDate | moment: 'DD.MM.YYYY' }} ausläuft, kannst du dich in der Zeit
                    dazwischen nicht einloggen.
                </div>
                <div
                    *ngIf="moment(team.expirationDate).isSameOrAfter(moment(team.becameCustomerAt), 'day')"
                    class="label">
                    Du hast autoiXpert zum {{ team.becameCustomerAt | moment: 'DD.MM.YYYY' }} gebucht.
                </div>
            </ng-container>
        </div>

        <!--********** Deactivated overlay **********-->
        <preference-section-deactivated
            [isDeactivated]="loggedInUser?._id !== selectedUser?._id"
            (switchToLoggedInUser)="selectUser(loggedInUser)"></preference-section-deactivated>
    </div>

    <cancel-team-account-dialog
        *ngIf="isDeleteTeamDialogVisible"
        [team]="team"
        (deleteTeam)="cancelTeamAccount($event)"
        (close)="isDeleteTeamDialogVisible = false"
        @runChildAnimations></cancel-team-account-dialog>
    <!--============================================-->
    <!-- END Team - Payment Details -->
    <!--============================================-->

    <!--============================================-->
    <!-- Document Layout -->
    <!--============================================-->
    <div
        id="document-layout-container"
        class="card card-with-padding preference-section-container"
        *ngIf="team && userIsAdmin()">
        <document-layout-group-preferences
            [teamMembers]="teamMembers"
            [user]="selectedUser"
            [team]="team"></document-layout-group-preferences>

        <!--********** Deactivated overlay **********-->
        <preference-section-deactivated
            [isDeactivated]="loggedInUser?._id !== selectedUser?._id"
            (switchToLoggedInUser)="selectUser(loggedInUser)"></preference-section-deactivated>
    </div>
    <!--============================================-->
    <!-- END Document Layout -->
    <!--============================================-->

    <!--============================================-->
    <!-- Team Logo -->
    <!--============================================-->
    <div
        id="team-logo-container"
        *ngIf="team && userIsAdmin()"
        class="card card-with-padding preference-section-container reduced-padding-right text-align-center">
        <h2>Logo</h2>

        <div id="team-logo-toolbar-container" *ngIf="team.logoHash">
            <mat-icon class="toolbar-icon user-seal-submenu-icon" [matMenuTriggerFor]="teamLogoSubmenu">
                more_vert
            </mat-icon>
        </div>
        <mat-menu #teamLogoSubmenu>
            <a mat-menu-item (click)="downloadTeamLogo()" matTooltip="Das hinterlegte Team-Logo herunterladen.">
                <mat-icon>file_download</mat-icon>
                Herunterladen
            </a>
        </mat-menu>

        <ng-container *ngIf="team && !team.logoHash">
            <p>
                Das Logo erscheint im Restwertverteiler, beim Einholen von Unterschriften und im Standard-Briefpapier.
            </p>
            <p class="label">
                Deine Logo-Datei kannst du mit einem Bildbearbeitungs-Tool anpassen, z. B. mit dem
                <a href="https://pixlr.com/x" target="_blank" rel="”noopener”">Online-Editor Pixlr</a>
                .
            </p>
            <p class="label">
                <strong>Tipp:</strong>
                150 px in der Grafik = ca. 4 cm ausgedruckt.
            </p>

            <button id="upload-team-logo-button" (click)="teamLogoUpload.click()">
                <mat-icon class="button-icon small-icon" *ngIf="!teamLogoUploadPending">publish</mat-icon>
                <img
                    class="pending-icon"
                    src="/assets/images/icons/loading-indicator-white.svg"
                    *ngIf="teamLogoUploadPending" />
                Hochladen
            </button>

            <input
                type="file"
                #teamLogoUpload
                ng2FileSelect
                [uploader]="teamLogoUploader"
                accept="image/jpeg,image/png" />
        </ng-container>

        <div id="uploaded-team-logo-container" *ngIf="team?.logoHash">
            <div
                id="team-logo-icon-container"
                matTooltip="Das hinterlegte Team-Logo herunterladen."
                (click)="downloadTeamLogo()">
                <mat-icon id="team-logo-icon">image</mat-icon>
            </div>
            <p id="team-logo-uploaded-note">Büro-Logo hinterlegt.</p>
            <mat-icon
                id="team-logo-delete-icon"
                class="toolbar-icon"
                (click)="removeTeamLogo()"
                matTooltip="Logo löschen">
                delete
            </mat-icon>
        </div>

        <div class="helpcenter-link-container" id="team-logo-helpcenter-link-container">
            <a
                href="https://wissen.autoixpert.de/hc/de/articles/18477119794450-Logo-des-Kfz-Sachverst%C3%A4ndigenb%C3%BCros"
                class="helpcenter-link"
                target="_blank"
                rel="”noopener”">
                Wo wird mein Logo verwendet?
            </a>
        </div>

        <!--********** Deactivated overlay **********-->
        <preference-section-deactivated
            [isDeactivated]="loggedInUser?._id !== selectedUser?._id"
            (switchToLoggedInUser)="selectUser(loggedInUser)"></preference-section-deactivated>
    </div>
    <!--============================================-->
    <!-- END Team Logo -->
    <!--============================================-->

    <!--============================================-->
    <!-- Signable PDF Documents -->
    <!--============================================-->
    <div
        id="signable-pdf-documents-container"
        class="card card-with-padding preference-section-container reduced-padding-right">
        <img
            id="signable-pdf-documents-icon"
            class="cursor-pointer"
            src="/assets/images/icons/signable-pdf-document.png"
            routerLink="Unterschreibbare-PDF-Dokumente" />

        <div id="signable-pdf-documents-text-container">
            <h2 class="text-align-start">Unterschreibbare PDF Dokumente</h2>
            <p class="margin-bottom-30">
                Lade eigene, individuelle PDF-Dateien hoch, die deine Kunden unterschreiben können.
                <br />
                <span class="label">Z. B. Abtretung oder Anwaltsvollmacht</span>
            </p>

            <div id="signable-pdf-documents-button-container">
                <button
                    id="signable-pdf-document-button"
                    routerLink="Unterschreibbare-PDF-Dokumente"
                    [disabled]="!isAdmin(selectedUser) && !selectedUser.accessRights.editTextsAndDocumentBuildingBlocks"
                    [class.disabled]="
                        !isAdmin(selectedUser) && !selectedUser.accessRights.editTextsAndDocumentBuildingBlocks
                    "
                    [matTooltip]="isAdmin(selectedUser) ? '' : getAskYourAdminTooltip()">
                    PDF-Dokumente verwalten
                </button>
            </div>

            <!--********** Deactivated overlay **********-->
            <preference-section-deactivated
                [isDeactivated]="loggedInUser?._id !== selectedUser?._id"
                (switchToLoggedInUser)="selectUser(loggedInUser)"></preference-section-deactivated>
        </div>
    </div>
    <!--============================================-->
    <!-- END Signable PDF Documents -->
    <!--============================================-->

    <!--============================================-->
    <!-- Document Building Blocks -->
    <!--============================================-->
    <div
        id="document-building-blocks-container"
        class="card card-with-padding preference-section-container reduced-padding-right"
        *ngIf="team">
        <h2>Textbausteine</h2>

        <div
            id="document-building-blocks-button-container"
            [style.min-height.px]="loggedInUser?._id !== selectedUser?._id ? 250 : undefined">
            <button
                id="document-building-block-button"
                (click)="
                    selectedUser?.accessRights.editTextsAndDocumentBuildingBlocks &&
                        navigateToDocumentBuildingBlockList()
                "
                [disabled]="!selectedUser?.accessRights.editTextsAndDocumentBuildingBlocks"
                [class.disabled]="!selectedUser.accessRights.editTextsAndDocumentBuildingBlocks"
                [matTooltip]="
                    selectedUser?.accessRights.editTextsAndDocumentBuildingBlocks ? '' : getAskYourAdminTooltip()
                ">
                Textbausteine anpassen
            </button>
        </div>

        <!--********** Deactivated overlay **********-->
        <preference-section-deactivated
            [isDeactivated]="loggedInUser?._id !== selectedUser?._id"
            (switchToLoggedInUser)="selectUser(loggedInUser)"></preference-section-deactivated>
    </div>
    <!--============================================-->
    <!-- END Document Building Blocks -->
    <!--============================================-->
</div>
<!--============================================-->
<!-- END Preferences -->
<!--============================================-->

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { iconFilePathForCarBrand, iconForCarBrandExists } from '@autoixpert/lib/car/icon-for-car-brand-exists';
import { getInvoiceRecipientName } from '@autoixpert/lib/invoices/invoice-recipient-name';
import { getReportCustomerName } from '@autoixpert/lib/report/report-customer-name';
import { Invoice } from '@autoixpert/models/invoices/invoice';
import { Report } from '@autoixpert/models/reports/report';
import { Task } from '@autoixpert/models/tasks/task';
import { isMiddleClick } from '../../../libraries/is-middle-click';
import { openInNewTabOnMiddleClick } from '../../../libraries/open-in-new-tab-on-middle-click';

@Component({
    selector: 'task-associated-invoice-or-report',
    templateUrl: './task-associated-invoice-or-report.component.html',
    styleUrls: ['./task-associated-invoice-or-report.component.scss'],
    host: {
        '[class.not-connected]': '!this.task.associatedReport && !this.task.associatedInvoice',
    },
})
export class TaskAssociatedInvoiceOrReportComponent {
    constructor(private router: Router) {}

    @Input() task: Task;
    @Output() taskChange = new EventEmitter<Task>();

    handleLinkedRecordChange(task: Task, { report, invoice }: { report: Report; invoice: Invoice }) {
        task.associatedReport = report
            ? {
                  reportId: report._id,
                  token: report.token,
                  formattedClaimant: getReportCustomerName({ report, fallback: 'Gutachten' }),
                  make: report.car.make,
                  model: report.car.model,
                  licensePlate: report.car.licensePlate,
              }
            : undefined;

        task.associatedInvoice = invoice
            ? {
                  invoiceId: invoice._id,
                  number: invoice.number,
                  date: invoice.date,
                  formattedRecipient: getInvoiceRecipientName({ invoice, fallback: 'Rechnung' }),
              }
            : undefined;
        this.taskChange.emit(task);
    }

    navigateToLinkedInvoiceOrReport(event: MouseEvent) {
        if (!isMiddleClick(event)) {
            if (this.task.associatedInvoice) {
                this.router.navigateByUrl(`/Rechnungen/${this.task.associatedInvoice.invoiceId}`);
            }
            if (this.task.associatedReport) {
                this.router.navigateByUrl(`/Gutachten/${this.task.associatedReport.reportId}`);
            }
        }
    }

    navigateToLinkedInvoiceOrReportOnMiddleClick(event: MouseEvent) {
        if (this.task.associatedInvoice) {
            openInNewTabOnMiddleClick(event, `/Rechnungen/${this.task.associatedInvoice.invoiceId}`);
        }
        if (this.task.associatedReport) {
            openInNewTabOnMiddleClick(event, `/Gutachten/${this.task.associatedReport.reportId}`);
        }
    }

    protected iconForCarBrandExists = iconForCarBrandExists;
    protected iconFilePathForCarBrand = iconFilePathForCarBrand;
}

<h2>Reifen</h2>
<!--============================================-->
<!-- TireSet Selector (if secondTireSet) -->
<!--============================================-->
<div id="tire-set-selector-container" *ngIf="car.hasSecondTireSet">
    <div
        class="tire-set-selector-element"
        [ngClass]="{ active: !secondTireSetSelected }"
        (click)="selectTireSet('first')">
        <div class="tire-set-selector-title-container">
            <span class="tire-set-selector-title" matTooltip="Ersten Reifensatz bearbeiten">Erster Reifensatz</span>
            <!--********** Complete **********-->
            <mat-icon
                class="progress-indicator progress-complete-indicator"
                *ngIf="isFirstTireSetComplete()"
                matTooltip="Alle Felder sind für alle Reifen befüllt">
                check
            </mat-icon>

            <!--********** Partially Complete **********-->
            <mat-icon
                class="progress-indicator progress-partially-complete-indicator"
                *ngIf="!isFirstTireSetComplete() && isFirstTireSetPartiallyFilled()"
                matTooltip="Einige Felder befüllt.">
                rule
            </mat-icon>
        </div>
    </div>
    <div
        class="tire-set-selector-element"
        [ngClass]="{ active: secondTireSetSelected }"
        (click)="selectTireSet('second')">
        <div class="tire-set-selector-title-container">
            <span class="tire-set-selector-title" matTooltip="Zweiten Reifensatz bearbeiten">Zweiter Reifensatz</span>
            <!--********** Complete **********-->
            <mat-icon
                class="progress-indicator progress-complete-indicator"
                *ngIf="isSecondTireSetComplete()"
                matTooltip="Alle Felder befüllt">
                check
            </mat-icon>

            <!--********** Partially Complete **********-->
            <mat-icon
                class="progress-indicator progress-partially-complete-indicator"
                *ngIf="!isSecondTireSetComplete() && isSecondTireSetPartiallyFilled()"
                matTooltip="Einige Felder befüllt.">
                rule
            </mat-icon>
        </div>
    </div>
</div>
<!--============================================-->
<!-- END TireSet Selector (if secondTireSet) -->
<!--============================================-->

<div id="tire-selector-container">
    <!--============================================-->
    <!-- Tire Selektor - up to 4 Tires -->
    <!--============================================-->
    <ng-container *ngIf="tires.length <= 4">
        <div
            class="tire-selector-element"
            *ngFor="let tire of tires"
            [ngClass]="{ active: tire === _selectedTireRef || highlightedTires.get(tire) }"
            (click)="selectTire(tire); emitTireTabsUsed()">
            <div class="tire-selector-title-container">
                <div class="tire-selector-title" [matTooltip]="translateTirePosition(tire).tooltip">
                    {{ translateTirePosition(tire).label }}

                    <!--********** Complete **********-->
                    <!-- Use SVG instead of font because it's rendered clearer. -->
                    <mat-icon
                        class="progress-indicator progress-complete-indicator"
                        *ngIf="isTireInfoComplete(tire)"
                        matTooltip="Alle Felder befüllt">
                        check
                    </mat-icon>

                    <!--********** Partially Complete **********-->
                    <mat-icon
                        class="progress-indicator progress-partially-complete-indicator"
                        *ngIf="!isTireInfoComplete(tire) && isTireInfoPartiallyFilled(tire)"
                        matTooltip="Einige Felder befüllt.">
                        rule
                    </mat-icon>

                    <!--********** Empty **********-->
                    <mat-icon
                        class="progress-indicator progress-empty-indicator"
                        *ngIf="!isTireInfoPartiallyFilled(tire)"
                        matTooltip="Noch keine Felder befüllt.">
                        more_horiz
                    </mat-icon>
                </div>
            </div>
        </div>
    </ng-container>
    <!--============================================-->
    <!-- END Tire Selektor - up to 4 Tires -->
    <!--============================================-->

    <!--============================================-->
    <!-- Tire Selector - More Than 4 Tires -->
    <!--============================================-->
    <ng-container *ngIf="tires.length > 4">
        <!--********** Axle Selector **********-->
        <div id="axle-selector">
            <!--********** Switch Previous Axle **********-->
            <mat-icon
                id="switch-to-previous-axle-icon"
                class="toolbar-icon"
                [ngClass]="{ disabled: !isPreviousAxleAvailable() }"
                (click)="selectAxleByOffset(-1); emitAxleSwitchesUsed()"
                matTooltip="Achse weiter vorne auswählen">
                keyboard_arrow_up
            </mat-icon>

            <div id="index-of-selected-axle" matTooltip="Achse">A{{ getIndexOfAxle(selectedAxle) + 1 }}</div>

            <!--********** Switch Next Axle **********-->
            <mat-icon
                id="switch-to-next-axle-icon"
                class="toolbar-icon"
                [ngClass]="{ disabled: !isNextAxleAvailable() }"
                (click)="selectAxleByOffset(1); emitAxleSwitchesUsed()"
                matTooltip="Achse weiter hinten auswählen">
                keyboard_arrow_down
            </mat-icon>
        </div>

        <!--********** Placeholder if there are only two tires on axle to prevent jumping of axle selector **********-->
        <div class="tire-selector-element placeholder" *ngIf="tiresOnSelectedAxle.length === 2"></div>

        <!--********** Tire Selector **********-->
        <div
            class="tire-selector-element"
            *ngFor="let tire of tiresOnSelectedAxle"
            [ngClass]="{ active: tire === _selectedTireRef || highlightedTires.get(tire) }"
            (click)="selectTire(tire); emitTireTabsUsed()">
            <div class="tire-selector-title-container">
                <div class="tire-selector-title" [matTooltip]="translateTirePosition(tire).tooltip">
                    {{ translateTirePosition(tire).label }}
                    <!--********** Complete **********-->
                    <!-- Use SVG instead of font because it's rendered clearer. -->
                    <img
                        class="progress-indicator progress-complete-indicator"
                        src="/assets/images/icons/task_alt_green.svg"
                        *ngIf="isTireInfoComplete(tire)"
                        matTooltip="Alle Felder befüllt" />

                    <!--********** Partially Complete **********-->
                    <mat-icon
                        class="progress-indicator progress-partially-complete-indicator"
                        *ngIf="!isTireInfoComplete(tire) && isTireInfoPartiallyFilled(tire)"
                        matTooltip="Einige Felder befüllt.">
                        rule
                    </mat-icon>

                    <!--********** Empty **********-->
                    <mat-icon
                        class="progress-indicator progress-empty-indicator"
                        *ngIf="!isTireInfoPartiallyFilled(tire)"
                        matTooltip="Noch keine Felder befüllt.">
                        more_horiz
                    </mat-icon>
                </div>
            </div>
        </div>

        <!--********** Placeholder if there are only two tires on axle to prevent jumping of axle selector **********-->
        <div class="tire-selector-element placeholder" *ngIf="tiresOnSelectedAxle.length === 2"></div>
    </ng-container>
    <!--============================================-->
    <!-- END Tire Selector - More Than 4 Tires -->
    <!--============================================-->

    <!--***********************************************
    ** No Tires
    ************************************************-->
    <ng-container *ngIf="!tires?.length">
        <div id="no-tires-container" class="info-note missing-placeholder-warning">
            <mat-icon>info</mat-icon>
            <div class="flex-grow">
                <h3>Keine Achsen</h3>
                <p>
                    Um Achsen und Reifen definieren zu können, wähle im Reiter "Fahrzeugauswahl" die Anzahl der Achsen
                    aus.
                </p>
                <div
                    class="info-note-button-primary"
                    (click)="navigateToCarDataComponent()"
                    matTooltip="Achsen in der Fahrzeugauswahl definieren">
                    Fahrzeugauswahl öffnen
                </div>
            </div>
        </div>
    </ng-container>
    <!--***********************************************
    ** END No Tires
    ************************************************-->
</div>
<ng-container *ngIf="_selectedTireRef">
    <div id="tire-type-and-tread-container" class="multiple-inputs-grid">
        <div>
            <mat-form-field class="mat-block">
                <input
                    matInput
                    [(ngModel)]="selectedTireData.type"
                    (change)="formatTireDimension(); emitTireChange()"
                    [disabled]="disabled"
                    placeholder="Reifendimension"
                    matTooltip="z. B. 195/65 R 15 91 H" />
            </mat-form-field>
        </div>

        <mat-form-field class="mat-block">
            <input
                matInput
                id="tread-input"
                number-input
                [(number)]="selectedTireData.treadInMm"
                (change)="emitTireChange()"
                [disabled]="disabled"
                placeholder="Profil"
                [minimumFractionDigits]="0"
                [maximumFractionDigits]="1" />
            <span matSuffix>mm</span>
        </mat-form-field>
        <mat-icon
            *ngIf="!disabled"
            id="tire-comment-icon"
            class="input-external-icon outlined"
            (click)="toggleTireComment()"
            matTooltip="Kommentar zum Reifen, z. B. Zustand">
            chat_bubble
        </mat-icon>
    </div>

    <!--============================================-->
    <!-- Tire Comment Autocomplete -->
    <!--============================================-->
    <autocomplete-with-memory
        *ngIf="tireCommentShown"
        [(ngModel)]="selectedTireData.comment"
        placeholder="Bemerkung Rad/Reifen"
        (change)="emitTireChange()"
        [autocompleteEntryType]="'tireComment'"
        [useTextarea]="true"
        name="Reifen-Kommentar"
        [disabled]="disabled"></autocomplete-with-memory>
    <!--============================================-->
    <!-- END Tire Comment Autocomplete -->
    <!--============================================-->

    <mat-form-field class="mat-block">
        <input
            matInput
            placeholder="Hersteller"
            [(ngModel)]="selectedTireData.manufacturer"
            [disabled]="disabled"
            [matAutocomplete]="tireManufacturersAutocomplete"
            (ngModelChange)="filterTireManufacturerAutocomplete(selectedTireData.manufacturer)"
            (change)="emitTireChange()"
            (focus)="filterTireManufacturerAutocomplete(selectedTireData.manufacturer)" />
    </mat-form-field>
    <mat-autocomplete #tireManufacturersAutocomplete="matAutocomplete">
        <mat-option
            *ngFor="let tireManufacturer of filteredTireManufacturers"
            [value]="tireManufacturer"
            (mousedown)="$event.preventDefault()"
            (click)="emitTireChange()">
            {{ tireManufacturer }}
        </mat-option>
    </mat-autocomplete>

    <div id="tire-equipment-container" class="options-row grid">
        <ng-container *ngIf="carShapeHasSeasonalTires">
            <div
                class="tire-equipment-option option-container"
                id="summer-tires"
                [ngClass]="{ active: selectedTireData.season === 'summer', unselectable: disabled }"
                (click)="!disabled && selectTireSeason('summer')"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <img src="/assets/images/icons/summer-tires.png" alt="Sommerreifen" class="option-image" />
                <div class="tire-equipment-option-title-container option-title-container">
                    <span class="option-title">Sommer</span>
                </div>
            </div>
            <div
                class="tire-equipment-option option-container"
                id="winter-tires"
                [ngClass]="{ active: selectedTireData.season === 'winter', unselectable: disabled }"
                (click)="!disabled && selectTireSeason('winter')"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <img src="/assets/images/icons/winter-tires.png" alt="Winterreifen" class="option-image" />
                <div class="tire-equipment-option-title-container option-title-container">
                    <span class="option-title">Winter</span>
                </div>
            </div>
            <div
                class="tire-equipment-option option-container"
                id="allyear-tires"
                [ngClass]="{ active: selectedTireData.season === 'allyear', unselectable: disabled }"
                (click)="!disabled && selectTireSeason('allyear')"
                (keydown)="triggerClickEventOnSpaceBarPress($event)"
                tabindex="0">
                <img src="/assets/images/icons/allyear-tires.png" alt="Ganzjahresreifen" class="option-image" />
                <div class="tire-equipment-option-title-container option-title-container">
                    <span class="option-title">Ganzjahr</span>
                </div>
            </div>
            <mat-icon
                *ngIf="!disabled"
                id="show-custom-season-icon"
                class="input-external-icon"
                (click)="customTireTypeShown = !customTireTypeShown"
                matTooltip="Eigenen Reifentyp angeben">
                add
            </mat-icon>
        </ng-container>
        <div class="input-for-custom-value" *ngIf="customTireTypeShown || !carShapeHasSeasonalTires">
            <mat-form-field>
                <input
                    matInput
                    placeholder="Anderer Reifentyp"
                    [(ngModel)]="selectedTireData.customType"
                    (change)="selectCustomTireType()"
                    [axAutofocus]="customTireTypeShown && !selectedTireData.customType" />
            </mat-form-field>
        </div>
    </div>
    <div id="apply-all-button-container">
        <!--********** Copy All **********-->
        <button
            (click)="!disabled && copyValuesToAllTires()"
            [ngClass]="{ disabled: disabled }"
            matTooltip="Daten auf alle Reifen {{
                car.hasSecondTireSet ? 'des ausgewählten Radsatzes' : ''
            }} übertragen">
            {{ car.hasSecondTireSet ? 'Reifensatz' : 'Alle' }} angleichen
            <span class="button-overlay" *ngIf="copiedToAllTiresIconShown" @fadeInAndOut>
                <mat-icon class="button-action-done-icon">check</mat-icon>
            </span>
        </button>

        <!--********** Copy for Axle **********-->
        <button
            id="apply-for-axle-button"
            class="secondary"
            (click)="!disabled && copyValuesToAllTiresOnAxle()"
            [ngClass]="{ disabled: disabled }"
            *ngIf="showCopyButtonForSameAxle()"
            matTooltip="Daten auf alle Reifen auf der gleichen Achse übertragen">
            Achse angleichen
            <span class="button-overlay" *ngIf="copiedToAllTiresOnAxleIconShown" @fadeInAndOut>
                <mat-icon class="button-action-done-icon">check</mat-icon>
            </span>
        </button>
    </div>
</ng-container>

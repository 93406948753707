import { Directive, HostListener } from '@angular/core';
import { ToastService } from '../services/toast.service';

/**
 * Threshold in pixel for recognizing a mouse drag event. If the user moved the mouse less than this
 * amount of pixel after mousedown, we assume it was not on purpose.
 */
const MOUSE_DRAGGED_THRESHOLD = 5;

/**
 * Directive that shows a toast message on click of the host element that it is a readonly input field.
 */
@Directive({
    selector: '[totalPriceReadonlyHint]',
})
export class TotalPriceReadonlyHintDirective {
    private initialPosition: { x: number; y: number } = null;
    constructor(private readonly toastService: ToastService) {}

    @HostListener('click', ['$event']) hostClicked(event: MouseEvent): void {
        const diffX = Math.abs(event.clientX - this.initialPosition.x);
        const diffY = Math.abs(event.clientY - this.initialPosition.y);

        this.initialPosition = null;

        if (diffX > MOUSE_DRAGGED_THRESHOLD || diffY > MOUSE_DRAGGED_THRESHOLD) {
            // User dragged the mouse -> potentially selecting and copying text. Don't show message
            return;
        }

        this.toastService.info(
            'Berechneter Wert',
            'Der Gesamtpreis wird berechnet aus Einzelpreis und Menge und kann nicht direkt bearbeitet werden.',
        );
    }

    @HostListener('mousedown', ['$event']) mousedown(event: MouseEvent): void {
        // Remember the position where the user started the click, so we can determine if the
        // mouse was moved between mousedown and mouseup later.
        this.initialPosition = {
            x: event.clientX,
            y: event.clientY,
        };
    }
}

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DowntimeNotification, ProcessContext } from '@autoixpert/models/notifications/downtime-notification';
import { DowntimeNotificationService } from '../../services/downtime-notification.service';
import { DowntimeNotificationOverlayComponent } from './downtime-notification-overlay/downtime-notification-overlay.component';

@Component({
    selector: 'context-sensitive-downtime-notification',
    templateUrl: './context-sensitive-downtime-notification.component.html',
    styleUrls: ['./context-sensitive-downtime-notification.component.scss'],
})
export class ContextSensitiveDowntimeNotificationComponent implements OnInit, OnDestroy {
    constructor(
        private downtimeNotificationService: DowntimeNotificationService,
        private overlayService: Overlay,
        private injector: Injector,
    ) {}
    @Input() processContext: ProcessContext;
    @Input() childClass: string;
    @ViewChild('overlayAnchor', { read: ElementRef }) overlayAnchor: ElementRef;

    public applicableDowntimeNotifications: DowntimeNotification[];
    private subscriptions: Subscription[] = [];

    ngOnInit() {
        this.checkForApplicableDowntimeNotification();
        this.subscribeToDowntimeNotificationChange();
    }

    private checkForApplicableDowntimeNotification() {
        this.applicableDowntimeNotifications = this.downtimeNotificationService.getDowntimeNotificationsForContext(
            this.processContext,
        );
    }

    private subscribeToDowntimeNotificationChange() {
        this.subscriptions.push(
            this.downtimeNotificationService.mayHaveChanged$$.subscribe(() =>
                this.checkForApplicableDowntimeNotification(),
            ),
        );
    }

    protected openDetailsOverlay(event: MouseEvent): void {
        // Prevent browser from jumping
        event.stopPropagation();

        const overlayRef = this.overlayService.create({
            hasBackdrop: true,
            backdropClass: 'panel-transparent-backdrop',
            positionStrategy: this.overlayService
                .position()
                .flexibleConnectedTo(this.overlayAnchor)
                .withPositions([
                    // Below the anchor
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    },
                ])
                .withDefaultOffsetY(15)
                .withViewportMargin(10),
            scrollStrategy: this.overlayService.scrollStrategies.noop(),
        });

        overlayRef.backdropClick().subscribe(() => overlayRef.detach());

        const overlayRefInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
            ],
        });

        const componentRef = overlayRef.attach(
            new ComponentPortal(DowntimeNotificationOverlayComponent, null, overlayRefInjector),
        );

        componentRef.instance.applicableMessages = this.applicableDowntimeNotifications;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}

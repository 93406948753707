import { get, set } from 'lodash-es';
import { DataTypeBase } from '../../models/indexed-db/database.types';
import { jsonStringifySorted } from '../arrays/json-stringify-sorted';
import { isChangeDealingWithArrayOfObjectsWithIds } from './merge-array-changes/is-change-dealing-with-array-of-objects-with-ids';
import { isChangeDealingWithArrayOfPlainValues } from './merge-array-changes/is-change-dealing-with-array-of-plain-values';
import { mergeChangesBetweenArraysOfObjectsWithIds } from './merge-array-changes/merge-changes-between-arrays-of-objects-with-ids';
import { mergeChangesBetweenArraysOfPlainValues } from './merge-array-changes/merge-changes-of-array-of-plain-values';

/**
 * Merge the array at `path` by comparing what changes have been made between...
 * - serverShadow -> localRecord
 * - serverShadow -> remoteRecord
 *
 * Merge sort order incl. new and deleted records on the top level.
 * Then merge changes to specific objects separately.
 *
 * If conflicts exist (either within sort order or an array element), the local record's values are used.
 */
export function mergeArrayChanges<DataType extends DataTypeBase>({
    path,
    serverShadow,
    localRecord,
    remoteRecord,
    forceMergeConflictingChanges,
}: {
    path: string;
    serverShadow: DataType;
    localRecord: DataType;
    remoteRecord: DataType;

    /**
     * Used for changes that originated in another browser tab. They are sent through a BroadcastChannel and
     * must be force merged. If we did not force-merge, a race condition between the BroadcastChannel and the IndexedDB
     * may cause conflicting changes to be detected where there are none. Especially with arrays such as report.photos,
     * that leads to unpredictable merge results.
     */
    forceMergeConflictingChanges?: boolean;
}): void {
    const serverShadowArray: any[] = get(serverShadow, path);
    const localArray: any[] = get(localRecord, path);
    const remoteArray: any[] = get(remoteRecord, path);

    console.log(`[Merge arrays] Merging on path '${path}'`);

    let sortedMergeResult: any[];
    if (isChangeDealingWithArrayOfPlainValues(localArray, remoteArray)) {
        console.log(`[Merge arrays] Merging happens between two arrays with plain values.`);
        sortedMergeResult = mergeChangesBetweenArraysOfPlainValues({
            serverShadowArray,
            localArray,
            remoteArray,
            forceMergeConflictingChanges,
        });
    } else if (isChangeDealingWithArrayOfObjectsWithIds(localArray, remoteArray)) {
        console.log(`[Merge arrays] Merging happens between two arrays with objects with ID.`);
        sortedMergeResult = mergeChangesBetweenArraysOfObjectsWithIds({
            serverShadowArray,
            localArray,
            remoteArray,
            forceMergeConflictingChanges,
        });
    } else {
        /**
         * Array of objects without IDs cannot be merged by their content because we can't decide whether an object was modified or deleted and created.
         * That would only be possible with IDs. Instead, we stringify the objects and compare them as plain values.
         */
        console.log(
            `[Merge arrays] Merging happens between two arrays with objects without IDs. Stringify their contents to compare them as plain values.`,
        );

        /**
         * Since there is no ID present on these objects, try to merge them by their content. That's done
         * through using JSON.stringify to compare the objects.
         */
        const plainValueServerShadowArray = serverShadowArray.map((objectWithoutId) =>
            jsonStringifySorted(objectWithoutId),
        );
        const plainValueLocalArray = localArray.map((objectWithoutId) => jsonStringifySorted(objectWithoutId));
        const plainValueRemoteArray = remoteArray.map((objectWithoutId) => jsonStringifySorted(objectWithoutId));

        const sortedMergeResultOfPlainValues = mergeChangesBetweenArraysOfPlainValues<string>({
            serverShadowArray: plainValueServerShadowArray,
            localArray: plainValueLocalArray,
            remoteArray: plainValueRemoteArray,
            forceMergeConflictingChanges,
        });

        /**
         * Keep the reference to the array intact. Replace its content with the sorted merge result.
         */
        localArray.splice(
            0,
            localArray.length,
            ...sortedMergeResultOfPlainValues.map((jsonOfObjectWithoutId) => JSON.parse(jsonOfObjectWithoutId)),
        );
        sortedMergeResult = localArray;
    }

    // Replace the existing array with the sorted merge result.
    set(localRecord, path, sortedMergeResult);

    console.log(`[Merge arrays] Result of the merge at '${path}'`, sortedMergeResult);
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as apexcharts from 'apexcharts';
import moment from 'moment';
import { DamageTotalAnalyticsRecord } from '@autoixpert/models/analytics/damage-total-analytics.record';
import { AnalyticsFilterComponent } from '../../shared/components/filter/analytics-filter/analytics-filter.component';
import { readAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/read-analytics-date-range-locally';
import { rememberAnalyticsDateRangeLocally } from '../../shared/libraries/analytics/remember-analytics-date-range-locally';
import { currencyFormatterEuro } from '../../shared/libraries/currency-formatter-euro';
import { numberFormatter } from '../../shared/libraries/number-formatter';
import { DamageTotalAnalyticService } from '../../shared/services/analytics/damage-total-analytic.service';
import { ApiErrorService } from '../../shared/services/api-error.service';
import { AnalyticsSettingsMenuComponent } from '../analytics-settings-menu/analytics-settings-menu.component';
import { seriesDimension1, setDefaultChartOptions } from '../default-chart-options';

@Component({
    selector: 'damage-total-analytic',
    templateUrl: './damage-total.component.html',
    styleUrls: ['./damage-total.component.scss'],
})
export class DamageTotalComponent implements OnInit {
    @ViewChild(AnalyticsFilterComponent, { static: true }) protected filter: AnalyticsFilterComponent;
    @ViewChild(AnalyticsSettingsMenuComponent, { static: true }) protected settings: AnalyticsSettingsMenuComponent;

    constructor(
        private damageTotalAnalyticService: DamageTotalAnalyticService,
        private apiErrorService: ApiErrorService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    public chartTitle: string = 'Gutachten nach Schadenhöhe';
    private chart: ApexCharts;
    private chartOptions: apexcharts.ApexOptions;
    public analyticsPending: boolean = false;

    public damageTotal: number = 0;
    public totalNumberOfReports: number = 0;

    public records: DamageTotalAnalyticsRecord[] = [];

    public filterAnalyticsFrom: string;
    public filterAnalyticsTo: string;

    ngOnInit() {
        setDefaultChartOptions();

        this.setDefaultDateFilter();
    }

    /**
     * The analytics-filter component loads the previous filter settings from local storage.
     * After these have been initialized, this function is called and fetches the initial data.
     */
    filtersLoaded(): void {
        this.updateAnalytics();
    }

    //*****************************************************************************
    //  Router
    //****************************************************************************/
    public navigateBackToAnalyticsList(): void {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Router
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Date Filter
    //****************************************************************************/
    private setDefaultDateFilter(): void {
        this.getDateRangeFromLocalStorage();

        if (!this.filterAnalyticsFrom) {
            this.filterAnalyticsFrom = moment().startOf('year').format('YYYY-MM-DD');
        }
        if (!this.filterAnalyticsTo) {
            this.filterAnalyticsTo = moment().endOf('year').format('YYYY-MM-DD');
        }
    }

    public rememberDateRange(): void {
        rememberAnalyticsDateRangeLocally(this.filterAnalyticsFrom, this.filterAnalyticsTo);
    }

    private getDateRangeFromLocalStorage(): void {
        const { from, to } = readAnalyticsDateRangeLocally();
        this.filterAnalyticsFrom = from;
        this.filterAnalyticsTo = to;
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Date Filter
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Chart
    //****************************************************************************/

    private drawChart(): void {
        // Don't draw new chart without records
        if (!this.records.length) return;

        const colors = [seriesDimension1];

        this.chartOptions = {
            chart: {
                type: 'bar',
            },
            colors,
            series: [
                {
                    name: 'Anzahl Gutachten',
                    data: this.records.map((record) => record.numberOfReports),
                    type: 'column',
                },
            ],
            xaxis: {
                categories: this.getDamageTierCategoryLabels(this.records).map((label) => label.toUpperCase()),
            },
            yaxis: [
                {
                    seriesName: 'Anzahl Gutachten',
                    labels: {
                        show: true,
                        formatter: numberFormatter,
                    },
                    show: true,
                },
            ],
            stroke: {
                show: true,
                width: [0, 2],
                colors,
                curve: 'straight',
            },
            fill: {
                opacity: 1,
            },
        };

        // Clear before re-drawing
        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
        this.chart.render();
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Chart
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Summary
    //****************************************************************************/
    public calculateDamageTotal(): void {
        this.damageTotal = this.records.reduce((total, currentItem) => total + currentItem.damageTotalValue, 0);
    }

    public getAverageDamageTotal(): number {
        return this.damageTotal / this.totalNumberOfReports;
    }

    public calculateTotalNumberOfReports(): void {
        this.totalNumberOfReports = this.records.reduce((total, record) => total + record.numberOfReports, 0);
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Summary
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  Retrieve Analytics
    //****************************************************************************/
    public updateAnalytics(): void {
        this.analyticsPending = true;

        this.damageTotalAnalyticService
            .getDamageTotalAnalytics({
                from: this.filterAnalyticsFrom,
                to: this.filterAnalyticsTo,
                net: this.settings.useNetValues,
                officeLocationIds: this.filter.officeLocationIds,
                assessorIds: this.filter.assessorIds,
                reportLabelIds: this.filter.reportLabelConfigIds,
            })
            .subscribe({
                next: (records) => {
                    this.records = records;
                    this.analyticsPending = false;
                    this.calculateDamageTotal();
                    this.calculateTotalNumberOfReports();

                    setTimeout(() => {
                        this.drawChart();
                    }, 0);
                },
                error: (error) => {
                    this.analyticsPending = false;

                    this.apiErrorService.handleAndRethrow({
                        axError: error,
                        handlers: {
                            ACCESS_DENIED: {
                                title: 'Zugriffsrecht fehlt',
                                body: 'Bitte lasse dir das Zugriffsrecht für die Auswertungen von einem Administrator zuweisen. Er kann dies in den Einstellungen tun.',
                                forceConsiderErrorHandled: false, // Although we have a specific error handler, we want ACCESS_DENIED errors to be logged in Sentry.
                            },
                        },
                        defaultHandler: {
                            title: 'Fehler bei Auswertung',
                            body: "Bitte kontaktiere die <a href='/Hilfe'>Hotline</a>.",
                        },
                    });
                },
            });
    }

    /////////////////////////////////////////////////////////////////////////////*/
    //  END Retrieve Analytics
    /////////////////////////////////////////////////////////////////////////////*/
    public getReportsWithDamageValueForOverlay(recordCategories: DamageTotalAnalyticsRecord) {
        return recordCategories.combinedReports;
    }

    public getReportsForOverlay(recordCategories: DamageTotalAnalyticsRecord) {
        return recordCategories.combinedReports.map((r) => r._id);
    }

    public prepareDataForExport(): DamageTotalAnalyticsExportRecord[] {
        return this.records.map((record) => {
            return {
                // export only needed fields
                upperLimit: record.upperLimit,
                lowerLimit: record.lowerLimit,
                numberOfReports: record.numberOfReports,
                // TODO Refactor the combinedReports property to be named more telling. Also, we might not need the object structure here.
                idsOfReports: record.combinedReports.map((combinedReport) => combinedReport._id),
                // export net or gross values with label
                ...(this.settings.useNetValues
                    ? { damageTotalValueNet: record.damageTotalValue }
                    : { damageTotalValueGross: record.damageTotalValue }),
            };
        });
    }

    public getDamageTierCategoryLabels(recordCategories: DamageTotalAnalyticsRecord[]) {
        return recordCategories.map((currentItem) => this.getDamageTierCategoryLabel(currentItem));
    }

    public getDamageTierCategoryLabel(recordCategory: DamageTotalAnalyticsRecord) {
        if (recordCategory.upperLimit) {
            return `bis ${currencyFormatterEuro(recordCategory.upperLimit)}`;
        }
        return `ab ${currencyFormatterEuro(recordCategory.lowerLimit)}`;
    }
}

export interface DamageTotalAnalyticsExportRecord {
    lowerLimit: number;
    upperLimit?: number;
    damageTotalValueNet?: number;
    damageTotalValueGross?: number;
    numberOfReports: number;
    idsOfReports: string[];
}

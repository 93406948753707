import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { generateId } from '@autoixpert/lib/generate-id';
import { trackById } from '@autoixpert/lib/track-by/track-by-id';
import { Team } from '@autoixpert/models/teams/team';
import { TeamWebhook, TeamWebhookEventType } from '@autoixpert/models/teams/team-webhook';
import { User } from '@autoixpert/models/user/user';
import { LoggedInUserService } from 'src/app/shared/services/logged-in-user.service';
import { TeamWebhookService } from 'src/app/shared/services/team-webhook.service';

@Component({
    selector: 'team-webhooks',
    templateUrl: './team-webhooks.component.html',
    styleUrls: ['./team-webhooks.component.scss'],
})
export class TeamWebhooksComponent {
    trackById = trackById;
    eventTypes = Object.values(TeamWebhookEventType);
    private user: User;
    private team: Team;
    private _webhooks: TeamWebhook[];
    private subscriptions: Subscription[] = [];
    protected addTeamWebhookPending = false;

    constructor(
        private loggedInUserService: LoggedInUserService,
        private readonly teamWebhookService: TeamWebhookService,
    ) {}

    get webhooks() {
        return (this._webhooks ?? []).sort(
            (webhook1, webhook2) => new Date(webhook1.createdAt).getTime() - new Date(webhook2.createdAt).getTime(),
        );
    }

    async ngOnInit() {
        // Get the currently logged-in user from cache.
        this.user = this.loggedInUserService.getUser();

        this.subscriptions.push(
            this.loggedInUserService.getTeam$().subscribe((team) => {
                this.team = team;
            }),
        );

        this._webhooks = await this.teamWebhookService.find().toPromise();
    }

    async createTeamWebhook() {
        if (this.addTeamWebhookPending) return;

        this.addTeamWebhookPending = true;
        await this.teamWebhookService.create(
            new TeamWebhook({
                _id: generateId(),
                teamId: this.team._id,
                active: true,
                url: '',
                eventType: TeamWebhookEventType.CONTACT_CREATED,
            }),
            { waitForServer: true },
        );
        this._webhooks = await this.teamWebhookService.find().toPromise();
        this.addTeamWebhookPending = false;
    }

    async updateTeamWebhook(webhook: TeamWebhook, property?: keyof TeamWebhook, value?: any) {
        webhook[property] = value as never;
        await this.teamWebhookService.put(webhook);
    }

    async deleteTeamWebhook(webhook: TeamWebhook) {
        await this.teamWebhookService.delete(webhook._id, { waitForServer: true });
        this._webhooks = await this.teamWebhookService.find().toPromise();
    }

    async duplicateTeamWebhook(webhook: TeamWebhook) {
        const newWebhook = new TeamWebhook({
            _id: generateId(),
            teamId: webhook.teamId,
            active: webhook.active,
            url: webhook.url,
            eventType: webhook.eventType,
            description: webhook.description,
        });
        await this.teamWebhookService.create(newWebhook, { waitForServer: true });
        this._webhooks = await this.teamWebhookService.find().toPromise();
    }

    translateWebhookEventType(eventType: TeamWebhookEventType) {
        switch (eventType) {
            case TeamWebhookEventType.CONTACT_CREATED:
                return 'Kontakt erstellt';

            case TeamWebhookEventType.CONTACT_UPDATED:
                return 'Kontakt aktualisiert';

            case TeamWebhookEventType.CONTACT_DELETED:
                return 'Kontakt gelöscht';

            case TeamWebhookEventType.INVOICE_CREATED:
                return 'Rechnung erstellt';

            case TeamWebhookEventType.INVOICE_LOCKED:
                return 'Rechnung festgeschrieben';

            case TeamWebhookEventType.INVOICE_PAID:
                return 'Rechnung bezahlt';

            case TeamWebhookEventType.INVOICE_DELETED:
                return 'Rechnung gelöscht';

            case TeamWebhookEventType.REPORT_CREATED:
                return 'Gutachten erstellt';

            case TeamWebhookEventType.REPORT_LOCKED:
                return 'Gutachten abgeschlossen';

            case TeamWebhookEventType.REPORT_UNLOCKED:
                return 'Gutachten entsperrt';

            case TeamWebhookEventType.REPORT_MOVED_TO_TRASH:
                return 'Gutachten archiviert';

            case TeamWebhookEventType.REPORT_RESTORED:
                return 'Gutachten wiederhergestellt';

            case TeamWebhookEventType.REPORT_DELETED:
                return 'Gutachten gelöscht';
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}

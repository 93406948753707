<div class="realtime-edit-sessions-container">
    <!--============================================-->
    <!-- Avatar -->
    <!--============================================-->
    <div
        class="co-editor-container"
        *ngFor="let realtimeEditSession of realtimeEditSessions; trackBy: trackById"
        (click)="tooltip.show(0)"
        [matTooltip]="getRealtimeEditSessionTooltip(realtimeEditSession)"
        #tooltip="matTooltip"
        @fadeInAndSlideSmall>
        <user-avatar [showTooltip]="false" [userId]="realtimeEditSession.createdBy"></user-avatar>
    </div>
    <!--============================================-->
    <!-- END Avatar -->
    <!--============================================-->
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankAccount } from '@autoixpert/models/teams/team';

/**
 * Let the user order autoiXpert.
 *
 * Before he can order, a direct debit mandate has to be created or the user must choose invoice.
 */
@Injectable()
export class DirectDebitMandateService {
    constructor(private httpClient: HttpClient) {}

    public create(teamId: string, bankAccount: BankAccount): Promise<any> {
        return this.httpClient
            .post(`/api/v0/teams/${teamId}/directDebitMandate`, {
                bankAccount: {
                    owner: bankAccount.owner,
                    iban: bankAccount.iban,
                    bic: bankAccount.bic,
                    bankName: bankAccount.bankName,
                },
                _documentVersion: 1,
                _schemaVersion: 1,
            })
            .toPromise();
    }

    public remove(teamId: string): Promise<any> {
        return this.httpClient.delete(`/api/v0/teams/${teamId}/directDebitMandate/${teamId}`).toPromise();
    }
}

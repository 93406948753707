import { NgModule } from '@angular/core';
import { AutofocusDirective } from './autofocus.directive';
import { ElementCreatedDirective } from './element-created.directive';
import { ResizableDirective } from './interactivejs/resizable.directive';
import { IntersectionDirective } from './intersection.directive';
import { NumberInputDirective } from './number-input.directive';
import { QuillContent } from './quill-content';
import { TrimDirective } from './trim.directive';

/**
 * This module contains all the shared directives used in the application.
 * It is necessary to avoid the Angular error: Directives should be declared only once.
 */
@NgModule({
    declarations: [
        AutofocusDirective,
        ElementCreatedDirective,
        IntersectionDirective,
        NumberInputDirective,
        QuillContent,
        ResizableDirective,
        TrimDirective,
    ],
    exports: [
        AutofocusDirective,
        ElementCreatedDirective,
        IntersectionDirective,
        NumberInputDirective,
        QuillContent,
        ResizableDirective,
        TrimDirective,
    ],
})
export class SharedDirectivesModule {}

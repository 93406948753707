import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AddressAutocompletionService } from '../services/google/address-autocompletion.service';

@Directive({
    selector: '[zip-city-input]',
})
export class ZipCityInputDirective {
    constructor(
        private hostElement: ElementRef<HTMLInputElement>,
        private addressAutocompletionService: AddressAutocompletionService,
    ) {}

    // ZIP codes may contain letters, dashes and numbers. Everything else is prohibited. See http://www.fedex.com/be/shippingguide/postalcodeformat.html for a list of formats.
    private disallowedKeys: RegExp = /[^A-Za-z0-9-]/;

    @Input() zipCityInput: HTMLInputElement;
    @Input() queryCities: boolean = true;
    @Input() street: string; // Some ZIP codes include multiple cities. Passing the street to the service identifies the city name uniquely.
    @Input() city: string;

    @Output() cityChange = new EventEmitter<string>();

    @HostListener('keydown', ['$event'])
    public filterAllowedKeys(event: KeyboardEvent): void {
        if (this.disallowedKeys.test(event.key)) {
            event.preventDefault();
            console.log('invalid character', event.key);
        }
    }

    @HostListener('paste', ['$event'])
    public filterInputValue(event: ClipboardEvent): void {
        // Clean the clipboard contents before pasting
        if (this.disallowedKeys.test(event.clipboardData.getData('text'))) {
            // Prevent pasting original content
            event.preventDefault();

            // Remove disallowed characters from pasted text
            this.hostElement.nativeElement.value = event.clipboardData
                .getData('text')
                .replace(new RegExp(this.disallowedKeys, 'g'), '');

            // After blocking the original event we must trigger some event for Angular to pick up on the change
            this.hostElement.nativeElement.dispatchEvent(new InputEvent('input'));
        }
    }

    @HostListener('input') getCity(): void {
        // No city lookup is required in the ZIP field for the
        if (!this.queryCities) return;

        // If the city is already set, don't overwrite it
        if (this.city) return;

        const searchTerm: string = this.hostElement.nativeElement.value;

        // Don't search if the search term is too short for a regular German zip code
        if (searchTerm.length !== 5) return;

        // Don't check if there are disallowed characters
        if (this.disallowedKeys.test(searchTerm)) return;

        // Add the city corresponding to the zip code to the city input
        this.addressAutocompletionService
            .getCityFromZip(searchTerm, this.street)
            .then((city) => {
                if (city) {
                    this.cityChange.emit(city);
                }
            })
            .catch((error) => {
                // If the client is offline, ignore errors from this service.
                if (['CLIENT_IS_OFFLINE', 'AUTOIXPERT_BACKEND_SERVERS_ARE_DOWN'].includes(error.code)) {
                    return;
                }

                console.error('COULD_NOT_SEARCH_CITY_BY_ZIP', { error });
            });
    }
}

import { Directive, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import interact from 'interactjs';
import { roundToNearest } from '@autoixpert/lib/numbers/round-to-nearest';

@Directive({
    selector: '[workingHoursSelector]',
})
export class WorkingHoursSelectorDirective {
    constructor(private elementRef: ElementRef<HTMLDivElement>) {}

    @Input() workingMinutes: number;
    @Output() workingMinutesChange = new EventEmitter<number>();
    @Output() workingHoursResizeEnd = new EventEmitter<number>();

    // How many parts shall be selectable within one hour? This defines the granularity of the resizing grid.
    private HOUR_PARTS: number = 2;
    // The user cannot select more than this number of work hours.
    private MAX_WORK_HOURS: number = 12;
    private heightPerHour: number;
    // Height in pixels of the snap grid
    private snapSize: number;

    ngOnInit() {}

    ngAfterViewInit() {
        this.calculateGridSizes();
        this.initializeElementHeight();
        this.registerResizeEventListener();
    }

    /**
     * Calculate how much space this element may take up at max, i. e. how tall the parent element is (excluding padding).
     */
    private calculateGridSizes() {
        const parentElement = this.elementRef.nativeElement.parentElement;
        const computedStyle = window.getComputedStyle(parentElement);
        const columnHeight =
            parentElement.clientHeight - (parseInt(computedStyle.paddingTop) + parseInt(computedStyle.paddingBottom));

        // E.g. if the user shall adjust every half hour, the HOUR_PARTS would be 2.
        this.snapSize = Math.floor(columnHeight / (this.MAX_WORK_HOURS * this.HOUR_PARTS));

        // How much vertical space does one hour take up? Must be in full pixels to avoid miscalculations in the grid sections.
        this.heightPerHour = this.snapSize * this.HOUR_PARTS;
    }

    private initializeElementHeight() {
        const height = this.translateHoursToHeight(this.workingMinutes / 60);
        this.elementRef.nativeElement.style.height = `${height}px`;
    }

    /**
     * Initialize InteractJS on the directive's host element to make it resizable and snap to certain grids.
     */
    private registerResizeEventListener() {
        interact(this.elementRef.nativeElement)
            .resizable({
                edges: {
                    bottom: true,
                },
                modifiers: [
                    interact.modifiers.snapEdges({
                        targets: [
                            interact.snappers.grid({
                                // Could be any value because we don't use the X dimension. We can only drag the bottom edge.
                                x: 1,
                                // Height of each snap slot.
                                y: this.snapSize,
                            }),
                        ],
                        range: Infinity,
                    }),

                    // minimum & maximum size
                    interact.modifiers.restrictSize({
                        min: {
                            width: 0,
                            // Don't let the user reduce the size to less than two hours. Less than two hours doesn't really count as an active day.
                            height: this.heightPerHour * 2,
                        },
                        max: {
                            width: Infinity,
                            height: this.heightPerHour * this.MAX_WORK_HOURS,
                        },
                    }),
                ],
            })
            .on('resizemove', (event) => {
                // InteractJS seems to have a bug that the height of the element is snapped to the grid with an offset of about 2 Px -> round.
                const heightSnappedToGrid = roundToNearest({
                    toBeRounded: event.rect.height,
                    stepSize: this.snapSize,
                });
                this.elementRef.nativeElement.style.height = `${heightSnappedToGrid}px`;
                this.updateWorkingHours(heightSnappedToGrid);
            })
            .on('resizeend', () => {
                this.emitWorkingHoursResizeEnd();
            });
    }

    private translateHeightToHours(height: number): number {
        return height / this.heightPerHour;
    }

    private translateHoursToHeight(hours: number): number {
        return roundToNearest({ toBeRounded: hours * this.heightPerHour, stepSize: this.snapSize });
    }

    /**
     * Update the working hours in the model by translating a height to hours
     * and emitting the change to the parent.
     */
    public updateWorkingHours(height: number) {
        this.workingMinutes = this.translateHeightToHours(height) * 60;
        this.emitWorkingHoursChange();
    }

    public emitWorkingHoursChange() {
        this.workingMinutesChange.emit(this.workingMinutes);
    }

    public emitWorkingHoursResizeEnd() {
        this.workingHoursResizeEnd.emit(this.workingMinutes);
    }
}

<img
    *ngIf="data.headerImagePath"
    [src]="data.headerImagePath"
    [style.max-height]="data.headerImageMaxHeight"
    class="header-image" />

<h2 matDialogTitle>{{ data.heading }}</h2>
<mat-dialog-content [innerHTML]="data.content" [class.text-align-left]="data.textAlign === 'left'"></mat-dialog-content>
<mat-dialog-actions [class.delete-red]="data.confirmColorRed">
    <button id="confirm-button" [class.delete]="data.confirmColorRed" (click)="confirm()">
        {{ data.confirmLabel || 'Ja' }}
    </button>
    <button
        id="abort-button"
        class="secondary"
        [ngClass]="{ 'grey-abort-button': data.confirmColorRed }"
        (click)="cancel()">
        {{ data.cancelLabel || 'Abbrechen' }}
    </button>
</mat-dialog-actions>

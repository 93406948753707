import { isGermanTaxIdValid } from '@autoixpert/lib/invoices/taxes/validate-german-tax-id';
import { XrechnungValidatorAutoixpertValidationMessage } from '@autoixpert/microservices/xrechnung-validator-autoixpert-validation-message';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { ApiErrorHandler, ApiErrorHandlerCreator } from '../../services/api-error.service';

export function getElectronicInvoiceApiErrorHandlers(): {
    [key: string]: ApiErrorHandler | ApiErrorHandlerCreator;
} {
    return {
        //*****************************************************************************
        //  Technical XML Issues
        //****************************************************************************/
        CONVERTING_XML_TO_JAVASCRIPT_OBJECTS_FAILED: {
            title: 'Ungültiges XML in XRechnung',
            body: `Die XML-Datei für die E-Rechnung ist ungültig. Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
            forceConsiderErrorHandled: false,
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Technical XML Issues
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Exchanged Document
        //****************************************************************************/
        INVOICE_NUMBER_MISSING_IN_XRECHNUNG_EXCHANGED_DOCUMENT: {
            title: 'Rechnungsnummer fehlt',
            body: `Die Rechnungsnummer ist ein Pflichtfeld. Bitte vergib sie, bevor du die Rechnung generierst.`,
        },
        INVOICE_TYPE_MISSING_IN_XRECHNUNG_EXCHANGED_DOCUMENT: {
            title: 'Rechnungstyp fehlt',
            body: `Damit wird unterschieden zwischen normalen Rechnungen, Gutschriften oder Stornorechnungen. Das ist ein technisches Problem. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        },
        INVOICE_DATE_MISSING_OR_INVALID_IN_XRECHNUNG_EXCHANGED_DOCUMENT: {
            title: 'Rechnungsdatum fehlt oder ist ungültig',
            body: `Bitte überprüfe, ob das Rechnungsdatum gefüllt und gültig ist.`,
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Exchanged Document
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Line Items
        //****************************************************************************/

        XRECHNUNG_LINE_ITEM_DESCRIPTION_MISSING: (error) => ({
            title: 'Positionsbeschreibung fehlt',
            body: `Die Rechnungsposition ${error.data.lineNumber} hat keine Beschreibung. Bitte füge eine hinzu.`,
        }),
        INVALID_XRECHNUNG_LINE_ITEM_UNIT_PRICE_NET: (error) => ({
            title: 'Rechnungsposition ohne Preis',
            body: `Die Rechnungsposition "${error.data.description}" (Nr. ${error.data.lineNumber}) hat keinen Preis. Bitte füge einen hinzu.`,
        }),
        INVALID_XRECHNUNG_LINE_ITEM_QUANTITY: (error) => ({
            title: 'Rechnungsposition ohne Menge',
            body: `Die Rechnungsposition "${error.data.description}" (Nr. ${error.data.lineNumber}) hat keine Mengenangabe. Bitte füge eine hinzu.`,
        }),
        XRECHNUNG_LINE_ITEM_GERMAN_UNIT_NOT_STRING: (error) => ({
            title: 'Einheit fehlt',
            body: `Die Rechnungsposition "${error.data.description}" (Nr. ${error.data.lineNumber}) hat keine Einheit. Bitte füge eine hinzu.`,
        }),
        XRECHNUNG_VAT_CATEGORY_CODE_MISSING: (error) => ({
            title: 'Mehrwertsteuer-Klasse fehlt',
            body: `Die Rechnungsposition "${error.data.description}" (Nr. ${error.data.lineNumber}) hat keine Angabe zur Umsatzsteuer. Bitte wähle den für dich relevanten Satz in den <a href="/Einstellungen?section=invoice-settings" target="_blank">Einstellungen</a>.`,
        }),
        MISSING_VAT_EXEMPTION_REASON_FOR_ZERO_VAT_RATE: (error) => ({
            title: 'Grund für 0 % MwSt. fehlt',
            body: `Die Rechnung <a href="" target="_blank">${error.data.invoiceNumber}</a> steht zwar auf 0 % MwSt., aber der Grund für die 0 % fehlt. Bitte wähle den Grund. Das geht im Menü zur Auswahl des MwSt.-Satzes.`,
        }),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Line Items
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Trade Delivery
        //****************************************************************************/
        MISSING_DELIVERY_DATE_IN_XRECHNUNG: (error) => ({
            title: 'Lieferdatum fehlt',
            body: `Bitte setze ein Lieferdatum für diese Rechnung.`,
        }),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Trade Delivery
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Trade Party
        //****************************************************************************/
        EUROPEAN_OR_GERMAN_TAX_ID_REQUIRED_FOR_SELLER: {
            title: 'Steuernummer oder Umsatzsteuer-ID fehlt',
            body: `Bitte trage deine Steuernummer oder Umsatzsteuer-ID in den <a href="/Einstellungen?section=invoice-settings" target="_blank">Einstellungen</a> ein.`,
        },
        ORGANIZATION_NAME_OR_PERSON_NAME_REQUIRED_FOR_XRECHNUNG: {
            title: 'Rechnungsempfänger fehlt',
            body: `Bitte trage eine Organisation oder Person als Rechnungsempfänger ein.`,
        },
        INVALID_EUROPEAN_VAT_ID_FOR_XRECHNUNG: (error) => {
            let noteAboutVatIdInGermanTaxIdFormat: string = '';
            if (isGermanTaxIdValid(error.data.europeanVatId)) {
                noteAboutVatIdInGermanTaxIdFormat = `Es sieht aus, als hättest du eine Steuernummer als Umsatzsteuer-ID eingetragen.<br><br>`;
            }

            if (error.data.tradePartyType === 'seller') {
                return {
                    title: 'Ungültige Umsatzsteuer-ID deines Büros',
                    body: `Bitte trage deine Umsatzsteuer-ID im richtigen Format in den <a href="/Einstellungen?section=invoice-settings" target="_blank">Einstellungen</a> ein.<br><br>${noteAboutVatIdInGermanTaxIdFormat}Aktuelle Umsatzsteuer-ID: ${error.data.europeanVatId}`,
                };
            } else if (error.data.tradePartyType === 'buyer') {
                return {
                    title: 'Ungültige Umsatzsteuer-ID des Rechnungsempfängers',
                    body: `Bitte trage die Umsatzsteuer-ID des Rechnungsempfängers im richtigen Format ein. Die kannst du bei der Vorsteuerabzugsfähigkeit deines Kunden oder des Rechnungsempfängers angeben.<br><br>${noteAboutVatIdInGermanTaxIdFormat}Unterschiedliche Länder haben leicht andere Formate, siehe <a href="https://de.wikipedia.org/wiki/Umsatzsteuer-Identifikationsnummer" rel="noopener" target="_blank">Wikipedia</a>.<br><br>Aktuelle Umsatzsteuer-ID: ${error.data.europeanVatId}`,
                };
            }
        },
        INVALID_GERMAN_TAX_ID_FOR_XRECHNUNG: (error) => {
            return {
                title: 'Ungültige Steuernummer deines Büros',
                body: `Bitte trage deine Steuernummer im richtigen Format in den <a href="/Einstellungen?section=invoice-settings" target="_blank">Einstellungen</a> ein.<br><br>Aktuelle Steuernummer: ${error.data.germanTaxId}`,
            };
        },
        INVALID_SWISS_BUSINESS_ID_FOR_XRECHNUNG: (error) => {
            return {
                title: 'Ungültige schweizer UID des Rechnungsempfängers',
                body: `Bitte trage die Unternehmens-Identifikationsnummer (UID) deines schweizer Kunden im richtigen Format in das Feld "Umsatzsteuer-ID" ein.<br><br>Beispiel: CHE-123.456.789 MWST (MWST am Ende gehört dazu).<br><br>Aktuelle Umsatzsteuer-ID: ${error.data.swissBusinessId}`,
            };
        },
        EMAIL_ADDRESS_REQUIRED_FOR_XRECHNUNG: (error) => {
            if (error.data.tradePartyType === 'seller') {
                return {
                    title: 'E-Mail-Adresse deines Büros fehlt',
                    body: `Bitte trage eine E-Mail-Adresse für dein Büro ein, damit die XRechnung generiert werden kann.`,
                };
            } else if (error.data.tradePartyType === 'buyer') {
                return {
                    title: 'E-Mail-Adresse des Rechnungsempfängers fehlt',
                    body: `Bitte trage die E-Mail-Adresse des Rechnungsempfängers ein, damit eine gültige XRechnung generiert werden kann.`,
                };
            }
        },
        COMPLETE_POSTAL_ADDRESS_REQUIRED_FOR_XRECHNUNG: (error) => {
            if (error.data.tradePartyType === 'seller') {
                return {
                    title: 'Adresse deines Büros unvollständig',
                    body: `Bitte vervollständige deine Postanschrift (Straße, Postleitzahl, Ort), damit die XRechnung generiert werden kann.`,
                };
            } else if (error.data.tradePartyType === 'buyer') {
                return {
                    title: 'Adresse des Rechnungsempfängers unvollständig',
                    body: `Bitte vervollständige die Postanschrift des Rechnungsempfängers (Straße, Postleitzahl, Ort), damit die XRechnung generiert werden kann.`,
                };
            }
        },
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Trade Party
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Trade Agreement
        //****************************************************************************/
        MISSING_BUYER_REFERENCE_IN_XRECHNUNG: (error) => ({
            title: 'Käuferreferenz fehlt',
            body: `Das ist ein Pflichtfeld in der E-Rechnung, das hier aber fehlt. Bitte kontaktiere die <a href="/Hilfe" target="_blank">Hotline</a>.`,
        }),
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Trade Agreement
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Specified Trade Settlement Payment Means
        //****************************************************************************/
        MISSING_IBAN_IN_XRECHNUNG_TRADE_SETTLEMENT: (error) => ({
            title: 'Deine IBAN fehlt',
            body: `Um ab dem 01.01.2025 eine E-Rechnung erzeugen zu können, musst du eine IBAN in deinen <a href="/Einstellungen?section=invoice-settings" target="_blank">Einstellungen</a> hinterlegen.`,
        }),

        /////////////////////////////////////////////////////////////////////////////*/
        //  END Specified Trade Settlement Payment Means
        /////////////////////////////////////////////////////////////////////////////*/

        //*****************************************************************************
        //  Unmapped Errors
        //****************************************************************************/
        XRECHNUNG_CONTAINS_ERRORS: handleXrechnungErrorsAndWarnings,
        XRECHNUNG_CONTAINS_WARNINGS: handleXrechnungErrorsAndWarnings,
        /////////////////////////////////////////////////////////////////////////////*/
        //  END Unmapped Errors
        /////////////////////////////////////////////////////////////////////////////*/
    };
}

function handleXrechnungErrorsAndWarnings(axError: AxError) {
    const errorsOrWarnings: XrechnungValidatorAutoixpertValidationMessage[] =
        axError.data.errors || axError.data.warnings;

    const errorsOrWarningsHtmlSnippets: string[] = [];
    for (const errorOrWarning of errorsOrWarnings) {
        errorsOrWarningsHtmlSnippets.push(
            `<b>Nachricht:</b> ${errorOrWarning.message}<br><b>Code:</b> ${errorOrWarning.schemaValidationRuleId}`,
        );
    }

    return {
        title: 'E-Rechnung nicht erstellbar',
        body: `Das System prüft jede E-Rechnung auf Vorgaben der Bundesregierung und der Europäischen Union. Dabei traten Fehler auf:<br><br>${errorsOrWarningsHtmlSnippets.join('<br><br>')}`,
    };
}

import { DocumentOrderConfig } from '../../models/documents/document-order-config';
import { translateDocumentOrderConfigName } from '../translators/translate-document-order-config-name';

/**
 * Order the map alphabetically by an entry's key in German.
 */
export function sortDocumentOrderConfigsAlphabetically(
    documentOrderConfigA: DocumentOrderConfig,
    documentOrderConfigB: DocumentOrderConfig,
): number {
    const documentOrderConfigAName: string = translateDocumentOrderConfigName({
        documentOrderConfig: documentOrderConfigA,
    });
    const documentOrderConfigBName: string = translateDocumentOrderConfigName({
        documentOrderConfig: documentOrderConfigB,
    });

    return documentOrderConfigAName.localeCompare(documentOrderConfigBName);
}

import { Report } from '@autoixpert/models/reports/report';
import { translateReportType } from './translate-report-type';

/**
 * Given a report type this function returns a german translation. All damage reports are summarized under "Gutachten".
 */
export function getReportCategory(reportType: Report['type']): string {
    switch (reportType) {
        // Damage Reports
        case 'liability':
        case 'shortAssessment':
        case 'partialKasko':
        case 'fullKasko':
            return 'Gutachten';
        // All other
        default:
            return translateReportType(reportType);
    }
}

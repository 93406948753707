import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from '@autoixpert/components/confirm-dialog/confirm-dialog.component';
import { todayIso } from '@autoixpert/lib/date/iso-date';
import { addDocumentToReport } from '@autoixpert/lib/documents/add-document-to-report';
import { removeDocumentTypeFromReport } from '@autoixpert/lib/documents/remove-document-type-from-report';
import { isAmendmentReport } from '@autoixpert/lib/report/is-amendment-report';
import { mayCarOwnerDeductTaxes } from '@autoixpert/lib/report/may-car-owner-deduct-taxes';
import { isDatTestAccount } from '@autoixpert/lib/users/is-dat-test-account';
import { isDatUserComplete } from '@autoixpert/lib/users/is-dat-user-complete';
import { DocumentMetadata } from '@autoixpert/models/documents/document-metadata';
import { AxError } from '@autoixpert/models/errors/ax-error';
import { DatValuation } from '@autoixpert/models/reports/market-value/dat-valuation';
import {
    DatValuationResponse,
    DatValuationWithDossierTaxation,
} from '@autoixpert/models/reports/market-value/dat-valuation-api';
import { Report } from '@autoixpert/models/reports/report';
import { Team } from '@autoixpert/models/teams/team';
import { User } from '@autoixpert/models/user/user';
import {
    ConnectDatDossierDialogComponent,
    ConnectDatDossierDialogData,
} from 'src/app/reports/details/shared/connect-dat-dossier-dialog/connect-dat-dossier-dialog.component';
import {
    DatValuationSettingsData,
    DatValuationSettingsDialogComponent,
} from 'src/app/reports/details/shared/dat-valuation-settings-dialog/dat-valuation-settings-dialog.component';
import { clearDatValuationData } from '../libraries/dat-helpers/clear-dat-valuation-data';
import { getDatValuationExportTooltip } from '../libraries/dat-helpers/get-dat-valuation-export-tooltip';
import { isDatValuationInputDataComplete } from '../libraries/dat-helpers/is-dat-valuation-input-data-complete';
import { isDatValuationPossible } from '../libraries/dat-helpers/is-dat-valuation-possible';
import { getDatErrorHandlers } from '../libraries/error-handlers/get-dat-error-handlers';
import { determineTaxationTypes } from '../libraries/report/determine-taxation-type';
import { ApiErrorHandleParameters, ApiErrorService } from './api-error.service';
import { AXRESTClient } from './ax-restclient';
import { DatAuthenticationService, DatJwtResponse } from './dat-authentication.service';
import { LoggedInUserService } from './logged-in-user.service';
import { NetworkStatusService } from './network-status.service';
import { NewWindowService } from './new-window.service';
import { ReportService } from './report.service';
import { ToastService } from './toast.service';
import { UserPreferencesService } from './user-preferences.service';

@Injectable()
export class DatValuationService {
    constructor(
        private loggedInUserService: LoggedInUserService,
        public userPreferences: UserPreferencesService,
        private newWindowService: NewWindowService,
        private httpClient: HttpClient,
        private datAuthenticationService: DatAuthenticationService,
        private reportService: ReportService,
        private apiErrorService: ApiErrorService,
        private toastService: ToastService,
        private dialog: MatDialog,
        private networkStatusService: NetworkStatusService,
        private axRESTClient: AXRESTClient,
    ) {
        this.user = this.loggedInUserService.getUser();
        this.team = this.loggedInUserService.getTeam();
    }

    private user: User;
    private team: Team;

    /**
     * Allow the components to display a loading spinner while the request is pending.
     */
    private isRequestPending$ = new BehaviorSubject<boolean>(false);
    public readonly isRequestPending$$ = this.isRequestPending$.asObservable();

    //*****************************************************************************
    //  Private methods (without error handling)
    //****************************************************************************/

    /**
     * Create a DAT dossier for evaluating a car. Then open the valuation interface.
     */
    public async create({
        report,
        datApprovalCode,
    }: {
        report: Report;
        datApprovalCode?: string;
    }): Promise<DatValuationResponse> {
        if (
            !isDatUserComplete(this.user) ||
            !isDatValuationInputDataComplete(report) ||
            !isDatValuationPossible(report) ||
            report.state === 'done'
        ) {
            throw new AxError({
                code: 'DAT_VALUATION_NOT_POSSIBLE',
                message: getDatValuationExportTooltip({ report: report, user: this.user }),
            });
        }

        if (!this.networkStatusService.isOnline()) {
            throw new AxError({
                code: 'DAT_VALUATION_NOT_AVAILABLE_OFFLINE',
                message: 'Die DAT-Bewertung ist verfügbar, sobald du wieder online bist.',
            });
        }

        if (!report.car.datIdentification) {
            throw new AxError({
                code: 'DAT_IDENTIFICATION_MISSING',
                message:
                    'Bitte führe im Reiter "Fahrzeugauswahl" eine DAT-VIN-Abfrage aus, bevor du die DAT-Bewertung startest.',
            });
        }

        const infoToast = this.toastService.info('DAT-Vorgang erstellen', 'Dies kann einige Sekunden dauern.', {
            timeOut: 10000,
        });

        this.isRequestPending$.next(true);

        if (!report.valuation.datValuation.requestedTaxationType) {
            // Valuation reports: The most important use case is the dealer purchase price.
            // Therefore, the taxation type is determined by the VAT status of the customer.
            if (report.type === 'valuation') {
                report.valuation.datValuation.requestedTaxationType = mayCarOwnerDeductTaxes(report)
                    ? 'full'
                    : 'neutral';
            } else {
                // Other reports: the most relevant use case is the replacement value.
                // The taxation of the replacement value is determined by the market (can be assumed through the car age).
                report.valuation.datValuation.requestedTaxationType =
                    report.valuation.taxationType ?? determineTaxationTypes(report);
            }
            await this.reportService.put(report, { waitForServer: true });
        }

        if (!report.valuation.datValuation.referenceDate) {
            report.valuation.datValuation.referenceDate = report.valuation.referenceDate ?? todayIso();
        }

        report.valuation.valuationProvider = 'dat';
        let datValuationResponse: DatValuationResponse;

        try {
            const datJwt = await this.datAuthenticationService.getJwt();
            datValuationResponse = await this.httpClient
                .post<DatValuationResponse>(
                    `/api/v0/reports/${report._id}/valuation`,
                    { datApprovalCode: datApprovalCode },
                    { headers: DatAuthenticationService.getDatJwtHeaders(datJwt) },
                )
                .toPromise();
        } catch (error) {
            this.isRequestPending$.next(false);

            // Close the info toast from above
            this.toastService.remove(infoToast.id);

            /**
             *  If customer uses DAT valuation with reference date,
             *  DAT asks for approval since there may be additional costs.
             *
             *  We handle this use case separately by calling executeDatQuickValidation recursively.
             *  Await till the valuation is finished. We need to wait before open the dat valuation ui.
             */
            const datApprovalError = this.apiErrorService.getErrorByCode({
                axError: error,
                code: 'DAT_NEEDS_APPROVAL',
            });
            if (datApprovalError && datApprovalError.data?.faultApprovalValue) {
                const datValuationApproved = await this.dialog
                    .open(ConfirmDialogComponent, {
                        data: {
                            heading: 'Historische Bewertung',
                            content: `Die DAT meldet:\n${datApprovalError.message}\n\nBearbeite den Stichtag über die Einstellungen zur DAT-Bewertung.`,
                            confirmLabel: 'Bewerten (kostenpflichtig)',
                            cancelLabel: 'Nee lieber nicht',
                        },
                        maxWidth: '700px',
                    })
                    .afterClosed()
                    .toPromise();

                if (!datValuationApproved) {
                    throw error;
                }
                // Retry the valuation with the approval code (recursive)
                return await this.create({
                    report,
                    datApprovalCode: datApprovalError.data.faultApprovalValue,
                });
            }

            throw error;
        }

        // Save the new DAT Dossier ID to the report and save the report back to the server.
        report.valuation.datValuation.dossierId = datValuationResponse.valuationResults.dossierId;
        report.valuation.datValuation.referenceDate ||= todayIso();

        /**
         * When importing a DAT Valuation, we update the taxation type with the taxation type of the DAT.
         * Since the DAT Dossier does not indicate neutral taxation, we handle the neutral taxation manually.
         */
        // Regular taxation means always full taxation
        if (datValuationResponse.valuationResults.dossierTaxationType === 'regular') {
            report.valuation.datValuation.requestedTaxationType = 'full';
        }
        // Difference taxation could mean difference or neutral taxation
        else if (report.valuation.datValuation.requestedTaxationType !== 'neutral') {
            report.valuation.datValuation.requestedTaxationType =
                datValuationResponse.valuationResults.dossierTaxationType === 'difference' ? 'margin' : 'full';
        }
        delete datValuationResponse.valuationResults.dossierTaxationType;

        await this.reportService.put(report, { waitForServer: true });
        this.toastService.remove(infoToast.id);
        this.isRequestPending$.next(false);

        return datValuationResponse;
    }

    /**
     * Lets the aX server send the current aX data to the DAT server.
     */
    public async updateDossier({
        report,
        forceUpdate,
        datApprovalCode,
    }: {
        report: Report;
        forceUpdate?: boolean;
        datApprovalCode?: string;
    }): Promise<number> {
        if (report.state === 'done') {
            return;
        }

        // Only send data if the report has a contractId already
        if (!report.valuation.datValuation?.dossierId) {
            throw new AxError({
                code: 'DAT_VALUATION_DOSSIER_MISSING',
                message: 'The DAT dossier cannot be updated if the aX report is not yet linked to a DAT dossier.',
            });
        }
        this.isRequestPending$.next(true);

        // Save the report to ensure the correct data is sent to the DAT server
        await this.reportService.put(report, { waitForServer: true });

        try {
            const datJwt = await this.datAuthenticationService.getJwt();
            await this.axRESTClient.patch(
                `/reports/${report._id}/valuation${forceUpdate ? `?forceUpdate=true` : ''}`,
                { datApprovalCode },
                {
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                },
            );
            this.toastService.success('Übertragung erfolgreich', 'Daten erfolgreich gesendet.');
            this.isRequestPending$.next(false);
            return report.valuation.datValuation.dossierId;
        } catch (error) {
            /**
             *  If customer uses DAT valuation with reference date,
             *  DAT asks for approval since there may be additional costs.
             *
             *  We handle this use case separately by calling executeDatQuickValidation recursively.
             *  Await till the valuation is finished. We need to wait before open the dat valuation ui.
             */
            const datApprovalError = this.apiErrorService.getErrorByCode({
                axError: error,
                code: 'DAT_NEEDS_APPROVAL',
            });
            if (datApprovalError && datApprovalError.data?.faultApprovalValue) {
                const datValuationApproved = await this.dialog
                    .open(ConfirmDialogComponent, {
                        data: {
                            heading: 'Historische Bewertung',
                            content: `Die DAT meldet:\n${datApprovalError.message}\n\nBearbeite den Stichtag über die Einstellungen zur DAT-Bewertung.`,
                            confirmLabel: 'Bewerten (kostenpflichtig)',
                            cancelLabel: 'Nee lieber nicht',
                        },
                        maxWidth: '700px',
                    })
                    .afterClosed()
                    .toPromise();

                if (!datValuationApproved) {
                    throw error;
                }
                // Retry the valuation with the approval code (recursive)
                return await this.updateDossier({
                    report,
                    forceUpdate,
                    datApprovalCode: datApprovalError.data.faultApprovalValue,
                });
            }

            throw error;
        }
    }

    /**
     * Import the valuation results, merge them into the report, add necessary documents and save the report back to the server.
     */
    public async retrieveResults({ report }: { report: Report }) {
        this.isRequestPending$.next(true);
        // Don't import
        if (report.state === 'done') {
            console.log('New calculation was not imported since report is locked.');
            return;
        }
        if (!report.valuation.datValuation.dossierId) {
            this.toastService.error('DAT-Bewertung fehlt', 'Erstelle eine Bewertung oder verknüpfe eine bestehende.');
            return;
        }

        const datJwt = await this.datAuthenticationService.getJwt();
        let datValuation: DatValuationWithDossierTaxation;

        try {
            // Return type: see translate-dat-valuation-data.ts (after-hook of valuation service)
            datValuation = await this.httpClient
                .get<DatValuationWithDossierTaxation>(`/api/v0/reports/${report._id}/valuation`, {
                    headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                })
                .toPromise();
        } catch (error) {
            this.isRequestPending$.next(false);
            throw error;
        }

        if (!report.valuation.datValuation.valuesRetrieved) {
            report.valuation.datValuation.valuesRetrieved = true;
        }

        /**
         * When importing a DAT Valuation, we update the taxation type with the taxation type of the DAT.
         * Since the DAT Dossier does not indicate neutral taxation, we handle the neutral taxation manually.
         */
        // Regular taxation means always full taxation
        if (datValuation.dossierTaxationType === 'regular') {
            report.valuation.datValuation.requestedTaxationType = 'full';
        }
        // Difference taxation could mean difference or neutral taxation
        else if (report.valuation.datValuation.requestedTaxationType !== 'neutral') {
            report.valuation.datValuation.requestedTaxationType =
                datValuation.dossierTaxationType === 'difference' ? 'margin' : 'full';
        }
        delete datValuation.dossierTaxationType;

        Object.assign<DatValuation, DatValuation>(report.valuation.datValuation, datValuation);

        /**
         * The valuation has this document in its DOCX file anyway, so don't add it as an attachment.
         *
         * DAT valuatePro is not capable of sending PDF files along with the valuation. That's for garages only.
         */
        if (report.type !== 'valuation' && !this.user.datUser.isValuateProUser) {
            this.addDatMarketAnalysisDocument({ report });
        }

        // Create DAT valuation document
        if (report.valuation.datValuation.includedDocuments.protocol) {
            this.addDatValuationProtocolDocument({ report });
        }

        // If no protocol shall be included, remove the protocol.
        else if (!report.valuation.datValuation.includedDocuments.protocol) {
            removeDocumentTypeFromReport({
                report: report,
                documentType: 'datValuationProtocol',
                documentGroup: 'report',
            });
        }

        await this.reportService.put(report, { waitForServer: true });
        this.isRequestPending$.next(false);
        return datValuation;
    }

    public async openSettingsDialog({ report }: { report: Report }) {
        if (report.state === 'done') {
            return;
        }

        const changedValuation = await this.dialog
            .open<DatValuationSettingsDialogComponent, DatValuationSettingsData, DatValuation>(
                DatValuationSettingsDialogComponent,
                {
                    data: {
                        report: report,
                        datUser: this.user.datUser,
                    },
                    maxWidth: '700px',
                },
            )
            .afterClosed()
            .toPromise();

        if (!changedValuation) {
            return;
        }
        await this.updateDossier({ report });
        await this.retrieveResults({ report });
    }

    public async openDossierConnectionDialog({ report }: { report: Report }) {
        if (report.state === 'done') return;

        if (isDatTestAccount(this.user.datUser)) {
            throw new AxError({
                code: 'NOT_AVAILABLE_FOR_DAT_TEST_ACCOUNT',
                message:
                    "This function is available once you've entered your own DAT account in the <a href='Einstellungen#calculation-providers-section'>settings</a>.",
            });
        }

        const datDossierId = await this.dialog
            .open<ConnectDatDossierDialogComponent, ConnectDatDossierDialogData, DatValuation['dossierId']>(
                ConnectDatDossierDialogComponent,
                {
                    data: {
                        licensePlate: report.car.licensePlate,
                        vin: report.car.vin,
                        dossierType: 'valuation',
                    },
                    maxWidth: '700px',
                },
            )
            .afterClosed()
            .toPromise();

        if (!datDossierId) {
            return;
        }

        this.isRequestPending$.next(true);
        if (!report.valuation.datValuation) {
            report.valuation.datValuation = new DatValuation();
        }
        report.valuation.datValuation.dossierId = datDossierId;

        // Save the report so that the server knows about the new datDossierId, then retrieve the results.
        await this.reportService.put(report, { waitForServer: true });
        await this.retrieveResults({ report });
    }

    /**
     * Delete the valuation from the DAT server and resets all values (except settings) in the report.
     * If the report is an amendment report, the valuation is not deleted from the DAT server.
     */
    public async reset({ report, options = {} }: { report: Report; options?: { showConfirmDialog?: boolean } }) {
        if (report.state === 'done') return;

        if (options.showConfirmDialog) {
            const decision = await this.dialog
                .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
                    data: {
                        heading: 'Bewertung löschen?',
                        content: 'Sicher? Dieser Schritt kann nicht rückgängig gemacht werden.',
                        confirmLabel: 'Weg damit!',
                        cancelLabel: 'Doch nicht...',
                        confirmColorRed: true,
                    },
                })
                .afterClosed()
                .toPromise();

            if (!decision) {
                return;
            }
        }

        if (report.valuation.datValuation.dossierId) {
            // Only actually delete the valuation if we're inside the original report. Only remove the connection to the valuation within an amendment report.
            // This prevents accidental deletion of the original report's valuation.
            if (isAmendmentReport(report)) {
                const datJwt = await this.datAuthenticationService.getJwt();

                try {
                    await this.httpClient
                        .delete(`/api/v0/reports/${report._id}/valuation`, {
                            headers: DatAuthenticationService.getDatJwtHeaders(datJwt),
                        })
                        .toPromise();
                } catch (error) {
                    // Don't do anything if the DAT failed to delete the valuation. The reference in the autoiXpert report was deleted, so the user can go on.
                    console.error(
                        'Failed to delete the valuation from the DAT server. No further action required.',
                        error,
                    );
                }
            }
        }

        this.unlink({ report });
    }

    /**
     * Clear the valuation data (except settings) from the report without deleting the valuation from the DAT server.
     */
    public async unlink({ report }: { report: Report }) {
        if (report.state === 'done') return;

        clearDatValuationData(report);
        await this.reportService.put(report, { waitForServer: true });
    }

    public addDatMarketAnalysisDocument({ report }) {
        addDocumentToReport({
            team: this.team,
            report,
            newDocument: new DocumentMetadata({
                type: 'datMarketAnalysis',
                title: 'DAT Bewertung',
                uploadedDocumentId: null,
                permanentUserUploadedDocument: false,
                createdAt: moment().format(),
                createdBy: this.user._id,
            }),
            documentGroup: 'report',
        });
    }

    public addDatValuationProtocolDocument({ report }) {
        addDocumentToReport(
            {
                team: this.team,
                report: report,
                newDocument: new DocumentMetadata({
                    type: 'datValuationProtocol',
                    title: 'DAT Bewertungsprotokoll',
                    uploadedDocumentId: null,
                    permanentUserUploadedDocument: false,
                    createdAt: moment().format(),
                    createdBy: this.user._id,
                }),
                documentGroup: 'report',
            },
            { insertAfterFallback: 'datMarketAnalysis' },
        );
    }

    //*****************************************************************************
    //  Error Handling
    //****************************************************************************/
    /**
     * Implement a specific error handler to handle possible DAT valuation errors.
     * This handler provides consistent error messages, e.g. between create() and createAndOpen()
     */
    public handleAndRethrow({
        axError,
        report,
        defaultHandler,
    }: {
        axError: AxError;
        report: Report;
        defaultHandler: ApiErrorHandleParameters['defaultHandler'];
    }) {
        this.isRequestPending$.next(false);
        this.apiErrorService.handleAndRethrow({
            axError,
            handlers: {
                ...getDatErrorHandlers(),
                DAT_VALUATION_NOT_POSSIBLE: (error) => ({
                    title: 'Schnellbewertung nicht möglich',
                    body: error.message,
                }),
                DAT_IDENTIFICATION_MISSING: {
                    title: 'DAT-VIN-Abfrage nötig',
                    body: 'Bitte führe im Reiter "Fahrzeugauswahl" eine DAT-VIN-Abfrage aus, bevor du die DAT-Bewertung startest.',
                },
                INITIAL_REGISTRATION_TOO_FAR_FROM_CONSTRUCTION_TIME: {
                    title: 'Zulassung & Baujahr',
                    body: `Das Baujahr (${moment(report.car.productionYear).format('YYYY')}) und die
                                    Erstzulassung (${moment(report.car.firstRegistration).format(
                                        'DD.MM.YYYY',
                                    )}) liegen laut DAT zu weit auseinander oder passen nicht zum DAT-Marktindex.`,
                },
                INVALID_MILEAGE: {
                    title: 'Laufleistung ungültig',
                    body: `Mindestens eine der Laufleistungen wurde von der DAT abgelehnt. Oft unterstützt die DAT keine sehr hohen Laufleistungen.\n
                            Abgelesen: ${
                                report.car.mileageMeter
                                    ? Number(report.car.mileageMeter).toLocaleString('de') +
                                      ' ' +
                                      report.car.mileageUnit
                                    : 'k. A.'
                            }
                            Geschätzt: ${
                                report.car.mileage
                                    ? Number(report.car.mileage).toLocaleString('de') + ' ' + report.car.mileageUnit
                                    : 'k. A.'
                            }
                            Angegeben: ${
                                report.car.mileageAsStated
                                    ? Number(report.car.mileageAsStated).toLocaleString('de') +
                                      ' ' +
                                      report.car.mileageUnit
                                    : 'k. A.'
                            }`,
                },
                DATE_AFTER_DATA_MONTH: {
                    title: `Keine Daten für Stichtag ${moment(report.valuation.datValuation.referenceDate).format(
                        'DD.MM.YYYY',
                    )}`,
                    body: `Die DAT hat keine Daten für den Stichtag. Die DAT unterstüt`,
                },
            },
            defaultHandler: defaultHandler ?? {
                title: 'Fehler in DAT-Schnittstelle',
                body: 'Probiere es erneut oder kontaktiere die Hotline.',
                partnerLogo: 'dat',
            },
        });
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END Error Handling
    /////////////////////////////////////////////////////////////////////////////*/

    //*****************************************************************************
    //  DAT Valuation UI
    //****************************************************************************/
    /**
     * Get the URL and parameters for the DAT damage calculation interface.
     */
    private getValuationUrl(report: Report, { onlyIdentification }: { onlyIdentification?: boolean } = {}) {
        let valuationUrl = `/assets/dat-bewertung/index.html?datDossierId=${report.valuation.datValuation.dossierId}&datCustomerNumber=${this.user.datUser.customerNumber}`;
        // If a garage has both myclaim and valuatePro, choose valuatePro since that seems more similar to the assessor's default valuation (DAT valuateExpert).
        if (this.user.datUser.isMyclaimUser && !this.user.datUser.isValuateProUser) {
            valuationUrl += '&myclaim=true';
        }
        if (this.user.datUser.isValuateProUser) {
            valuationUrl += '&valuatePro=true';
        }
        if (this.user.datUser.isValuateExpertPlusPartnerUser) {
            valuationUrl += '&valuateExpertPlusPartner=true';
        }

        // If the interface is only used for identification, start with the model page.
        if (onlyIdentification) {
            valuationUrl += '&startPage=valuation.model';
        }
        return valuationUrl;
    }

    /**
     * Opens the DAT valuation interface in a new window.
     * The provided callback function will be triggered when the user has finished working with the DAT interface.
     * E.g. for fetching the results from DAT and updating the report.
     */
    public openValuationUI({
        report,
        triggeringComponent,
        onFinish,
        options = {},
    }: {
        report: Report;
        triggeringComponent: ComponentWithNgZoneAndChangeDetectorRef;
        onFinish: () => unknown;
        options?: { onlyIdentification?: boolean };
    }) {
        // Open the Damage Calculation in a new window.
        const datUiUrl = this.getValuationUrl(report, options);
        this.newWindowService.open(datUiUrl);

        const runCallbackAndTriggerChangeDetection = () => {
            triggeringComponent.ngZone.run(async () => {
                await onFinish();

                // Trigger change detection because angular does not detect changes when this function is triggered from dat-schadenskalkulation.html
                // Only check though if the component still exists
                if (!triggeringComponent.changeDetectorRef['destroyed']) {
                    triggeringComponent.changeDetectorRef.detectChanges();
                }
            });
        };

        // Expose a global callback for when the calculation has successfully completed. This is called by
        // the file dat-schadenskalkulation.html.
        const callbacks: DatUiCallbacks = {
            getDatCredentials: () => this.datAuthenticationService.getJwt(),
            done: runCallbackAndTriggerChangeDetection,
        };
        (<any>window).datValuation = callbacks;
    }
    /////////////////////////////////////////////////////////////////////////////*/
    //  END DAT Valuation UI
    /////////////////////////////////////////////////////////////////////////////*/
}

interface ComponentWithNgZoneAndChangeDetectorRef {
    ngZone: NgZone;
    changeDetectorRef: ChangeDetectorRef;
}

/**
 * This callbacks are used from the DAT interface.
 */
export interface DatUiCallbacks {
    // This callback is called inside sphinx for logging in the user.
    getDatCredentials: () => Promise<DatJwtResponse>;
    // This callback is called when the user has finished working with the DAT interface.
    // This should be used to load the data from DAT and update the report.
    done: () => void;
}

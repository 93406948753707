<date-input
    [placeholder]="placeholderDateFrom || 'Von'"
    [(date)]="_dateFrom"
    (dateChange)="emitDateFromChange(); emitDateRangeChange()"
    [floatPlaceholder]="floatPlaceholder"></date-input>
<div id="date-range-input-divider" *ngIf="floatPlaceholder === 'never'">-</div>
<date-input
    [placeholder]="placeholderDateTo || 'Bis'"
    [(date)]="_dateTo"
    (dateChange)="emitDateToChange(); emitDateRangeChange()"
    [floatPlaceholder]="floatPlaceholder"></date-input>

<!--********** Filter by Invoice Date or Payment Date **********-->
<div id="date-filter-type-container" class="display-flex flex-center-content" *ngIf="dateFilterType === 'paymentDate'">
    <span
        matTooltip="Es wird nach Zahlungsdatum gefiltert. Mindestens eine Zahlung muss zwischen den Datumsgrenzen liegen.">
        Nach Zahlungsdatum
    </span>
    <mat-icon
        class="small-icon toolbar-icon"
        (click)="setDateFilterType('invoiceDate')"
        matTooltip="Filter entfernen. Stattdessen nach Rechnungsdatum filtern.">
        close
    </mat-icon>
</div>

<mat-icon class="toolbar-icon" [matMenuTriggerFor]="dateFilterSubmenu">more_vert</mat-icon>
<mat-menu #dateFilterSubmenu>
    <a mat-menu-item [mat-menu-trigger-for]="currentRangesMenu">Diese</a>
    <a mat-menu-item [mat-menu-trigger-for]="latestRangesMenu">Letzte</a>
    <a mat-menu-item [mat-menu-trigger-for]="previousRangesMenu">Vorherige</a>
    <a mat-menu-item [mat-menu-trigger-for]="monthsMenu">Monate</a>

    <ng-container *ngIf="dateFilterType">
        <!--********** By Invoice Date **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="dateFilterType === 'paymentDate'"
            (click)="setDateFilterType('invoiceDate')"
            matTooltip="Nach Rechnungsdatum filtern">
            <mat-icon>filter_alt</mat-icon>
            Rechnungsdatum
        </a>

        <!--********** By Payment Date **********-->
        <a
            mat-menu-item
            class="menu-item-with-icon"
            *ngIf="dateFilterType === 'invoiceDate'"
            (click)="setDateFilterType('paymentDate')"
            matTooltip="Nach Zahlungsdatum filtern. Mindestens eine Zahlung muss zwischen den Datumsgrenzen liegen.">
            <mat-icon>filter_alt</mat-icon>
            Zahlungsdatum
        </a>
    </ng-container>
</mat-menu>

<mat-menu #currentRangesMenu>
    <a mat-menu-item (click)="setDateRange('currentYear')">Dieses Jahr</a>
    <a mat-menu-item (click)="setDateRange('currentQuarter')">Dieses Quartal</a>
    <a mat-menu-item (click)="setDateRange('currentMonth')">Dieser Monat</a>
    <a mat-menu-item (click)="setDateRange('currentWeek')">Diese Woche</a>
</mat-menu>

<mat-menu #latestRangesMenu>
    <a mat-menu-item (click)="setDateRange('lastTwelveMonths')">Letzte 12 Monate</a>
    <a mat-menu-item (click)="setDateRange('lastThreeMonths')">Letzte 3 Monate</a>
    <a mat-menu-item (click)="setDateRange('lastThirtyDays')">Letzte 30 Tage</a>
    <a mat-menu-item (click)="setDateRange('lastSevenDays')">Letzte 7 Tage</a>
</mat-menu>

<mat-menu #previousRangesMenu>
    <a mat-menu-item (click)="setDateRange('previousYear')">Vorheriges Jahr</a>
    <a mat-menu-item (click)="setDateRange('previousQuarter')">Vorheriges Quartal</a>
    <a mat-menu-item (click)="setDateRange('previousMonth')">Vorheriger Monat</a>
    <a mat-menu-item (click)="setDateRange('previousWeek')">Vorherige Woche</a>
</mat-menu>

<mat-menu #monthsMenu>
    <a mat-menu-item (click)="setDateRange('monthJanuary')">Januar</a>
    <a mat-menu-item (click)="setDateRange('monthFebruary')">Februar</a>
    <a mat-menu-item (click)="setDateRange('monthMarch')">März</a>
    <a mat-menu-item (click)="setDateRange('monthApril')">April</a>
    <a mat-menu-item (click)="setDateRange('monthMay')">Mai</a>
    <a mat-menu-item (click)="setDateRange('monthJune')">Juni</a>
    <a mat-menu-item (click)="setDateRange('monthJuly')">Juli</a>
    <a mat-menu-item (click)="setDateRange('monthAugust')">August</a>
    <a mat-menu-item (click)="setDateRange('monthSeptember')">September</a>
    <a mat-menu-item (click)="setDateRange('monthOctober')">Oktober</a>
    <a mat-menu-item (click)="setDateRange('monthNovember')">November</a>
    <a mat-menu-item (click)="setDateRange('monthDecember')">Dezember</a>
</mat-menu>

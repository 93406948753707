import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { apiBasePath } from '@autoixpert/external-apis/api-base-path';
import { InvoiceNumberJournalEntry } from '@autoixpert/models/teams/invoice-number-journal-entry';
import { LiveSyncServiceBase } from '../libraries/database/live-sync.service-base';
import { get$SearchMongoQueryReports } from '../libraries/database/search-query-translators/get-$search-mongo-query-reports';
import { keysOfQuickSearchReports } from '../libraries/report-properties/keys-of-quick-search-reports';
import { FeathersQuery } from '../types/feathers-query';
import { FeathersSocketioService } from './feathers-socketio.service';
import { FrontendLogService } from './frontend-log.service';
import { NetworkStatusService } from './network-status.service';
import { SyncIssueNotificationService } from './sync-issue-notification.service';
import { ToastService } from './toast.service';

@Injectable()
export class InvoiceNumberJournalEntryService extends LiveSyncServiceBase<InvoiceNumberJournalEntry> {
    constructor(
        protected toastService: ToastService,
        protected httpClient: HttpClient,
        protected networkStatusService: NetworkStatusService,
        protected frontendLogService: FrontendLogService,
        protected syncIssueNotificationService: SyncIssueNotificationService,
        protected serviceWorker: SwUpdate,
        protected feathersSocketioService: FeathersSocketioService,
    ) {
        super({
            serviceName: 'invoiceNumberJournalEntry',
            httpClient,
            networkStatusService,
            syncIssueNotificationService,
            serviceWorker,
            frontendLogService,
            feathersSocketioClient: feathersSocketioService,
            keysOfQuickSearchRecords: keysOfQuickSearchReports,
            get$SearchMongoQuery: get$SearchMongoQueryReports,
            toastNotificationService: toastService,
        });
    }

    public create(journalEntry: Partial<InvoiceNumberJournalEntry>): Promise<InvoiceNumberJournalEntry> {
        return this.httpClient
            .post<InvoiceNumberJournalEntry>(`${apiBasePath}/invoiceNumberJournalEntries`, journalEntry)
            .toPromise();
    }

    public async getJournalEntriesFromServerOrIndexedDB({
        searchAfterPaginationToken,
        limit = 10,
        query,
        searchTerm,
    }: {
        searchAfterPaginationToken?: string;
        query: FeathersQuery;
        limit?: number;
        searchTerm?: string;
    }): Promise<{
        records: InvoiceNumberJournalEntry[];
        lastPaginationToken?: string;
    }> {
        if (searchTerm && searchTerm.length >= 3) {
            query.$search = searchTerm;
        }

        query.$limit = limit;
        if (searchAfterPaginationToken) {
            query.$searchAfterPaginationToken = searchAfterPaginationToken;
        }

        return await this.findWithPaginationToken(query);
    }
}

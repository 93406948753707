<div id="checkbox-config-panel" class="card">
    <!--********** Close Icon **********-->
    <mat-icon class="dialog-close-icon" (click)="closePanel()" matTooltip="Schließen">close</mat-icon>

    <!--********** Checkbox Icon Column **********-->
    <div id="checkbox-icon-column">
        <mat-icon class="medium-icon">check</mat-icon>
    </div>

    <!--********** Checkbox Negation **********-->
    <div id="config-column">
        <!--********** Checkbox Title **********-->
        <h3 id="checkbox-title" [ngSwitch]="checkboxElement.targetProperty">
            <ng-container *ngSwitchCase="'claimantMayDeductVat'">Vorsteuerabzug</ng-container>
            <ng-container *ngSwitchCase="'vehicleHasPreviousDamages'">Vorschäden</ng-container>
            <ng-container *ngSwitchDefault>Freie Checkbox</ng-container>
        </h3>

        <!-- Custom Label -->
        <mat-form-field
            *ngIf="!checkboxElement.targetProperty"
            [matTooltip]="
                'Die Bezeichnung der Checkbox wird dem Anspruchsteller angezeigt, wenn er das Dokument online unterschreibt.'
            ">
            <mat-label>Bezeichnung</mat-label>
            <input
                matInput
                [(ngModel)]="checkboxElement.label"
                (change)="emitConfigChange()"
                (elementCreated)="$event.element.focus()" />
        </mat-form-field>

        <!--============================================-->
        <!-- Answer Type - Yes or No -->
        <!--============================================-->
        <!-- Only pre-defined checkbox pairs with a target property require a yes/no selection. -->
        <ng-container *ngIf="checkboxElement.targetProperty || checkboxElement.checkboxPairId">
            <div>
                Diese Checkbox steht für die Antwort:
                <span
                    class="help-indicator"
                    matTooltip="Falls auf deinem Dokument zwei separate Checkboxen für 'Ja' und 'Nein' enthalten sind, solltest du auf jedem Ankreuzbereich eine Checkbox positionieren - eine mit Ja und eine mit Nein. Sonst reicht eine Ja-Checkbox.">
                    ?
                </span>
            </div>
            <div id="answer-type-container">
                <mat-radio-group [(ngModel)]="checkboxElement.answer" (ngModelChange)="emitConfigChange()">
                    <mat-radio-button value="yes">Ja</mat-radio-button>
                    <mat-radio-button value="no">Nein</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>
        <!--============================================-->
        <!-- END Answer Type - Yes or No -->
        <!--============================================-->

        <!--============================================-->
        <!-- Required -->
        <!--============================================-->
        <section id="is-field-required-section">
            <mat-slide-toggle [(ngModel)]="isRequiredField" (change)="emitRequiredChange()">
                Pflichtfeld
            </mat-slide-toggle>

            <span
                class="help-indicator"
                matTooltip="Mit einem Pflichtfeld gibst du vor, dass erst wenn alle Pflichtfelder von deinem Kunden ausgefüllt wurden, das Dokument gespeichert werden kann.">
                ?
            </span>
        </section>
        <!--============================================-->
        <!-- END Required -->
        <!--============================================-->
    </div>
</div>

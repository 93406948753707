import { DateTime } from 'luxon';
import { Team } from '../../models/teams/team';
import { TEST_PERIOD_DURATION_IN_DAYS } from '../../static-data/test-period-duration-in-days';
import { isQapterixpertTeam } from '../teams/is-qapterixpert-team';

/**
 * To prevent moochers from registering with fake data and changing them later on,
 * we block:
 * - changing their personal data.
 * - exporting PDF files in word.
 */
export function isTeamMatureCustomer({ team }: { team: Team }): boolean {
    const isTestPeriodOver =
        DateTime.now() > DateTime.fromISO(team.createdAt).plus({ day: TEST_PERIOD_DURATION_IN_DAYS });

    return (
        isQapterixpertTeam(team) ||
        ((team.accountStatus === 'paying' || team.accountStatus === 'partner') && isTestPeriodOver) ||
        team.isExemptFromTestAccountRestrictions
    );
}

import { InvoiceInvolvedPartyRole } from '../../models/invoices/invoice';
import { InvolvedPartyRole } from '../../models/reports/involved-parties/involved-party';
import { TextTemplateRole } from '../../models/text-templates/text-template';

export function translateRecipientRole(role: InvoiceInvolvedPartyRole | InvolvedPartyRole): string {
    switch (role) {
        case 'claimant':
            return 'Anspruchsteller';
        case 'invoiceRecipient':
            return 'Rechnungsempfänger';
        case 'garage':
            return 'Werkstatt';
        case 'lawyer':
            return 'Rechtsanwalt';
        case 'insurance':
            return 'Versicherung';
        case 'factoringProvider':
            return 'Factoring Provider';
        case 'leaseProvider':
            return 'Leasinggeber';
        case 'seller':
            return 'Verkäufer';
        default:
            return undefined;
    }
}

/**
 * This translator is used in the message template selector.
 * translateRecipientRole was used before, but enabling Angular Strict Templates showed, that it did not cover all roles.
 *
 * We have a bunch of other translators, but none of them covers all necessary roles. Furthermore sometimes there are small differences, e.g. lawyer -> Anwalt or Rechtsanwalt.
 * Mark decided, that we should keep the different translators and keep the explicit typing.
 *
 * To get correct typing for the message template selector while not changing any existing behavior I (Lukas) decided to create a new specific translator for TextTemplateRoles.
 * The translation is identical with translateRecipientRole, but the typing is more specific.
 *
 * TODO: I think we should combine all translators or at least check for exhaustiveness using eslint.
 */
export function translateTextTemplateRole(role: TextTemplateRole) {
    switch (role) {
        case 'claimant':
            return 'Anspruchsteller';
        case 'invoiceRecipient':
            return 'Rechnungsempfänger';
        case 'garage':
            return 'Werkstatt';
        case 'lawyer':
            return 'Rechtsanwalt';
        case 'insurance':
            return 'Versicherung';
        case 'factoringProvider':
            return 'Factoring Provider';
        case 'leaseProvider':
            return 'Leasinggeber';
        case 'seller':
            return 'Verkäufer';
        default:
            return undefined;
    }
}

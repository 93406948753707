import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { parseDateShorthands } from './parse-date-shorthands';

@Injectable()
export class AxDateAdapter extends MomentDateAdapter {
    public parse(value: any, parseFormats: string[]): Moment | null {
        return parseDateShorthands(value, parseFormats);
    }
}

/**
 * Sometimes it's necessary to check if a URL points to our own servers.
 */
export const whitelistedHostnames = [
    // Local
    'lokal.app.autoixpert.de',
    'lokal.app.qapter-ixpert.de',
    'lokal.accounts.autoixpert.de',

    // TODO Remove after 2024-12-01 when all engineers and all branches have been updated to the new lokal.* domains.
    'autoixpert.lokal',
    'qx.autoixpert.lokal',
    'qx.autoixpert.de',

    // Production
    'app.autoixpert.de',
    'app.qapter-ixpert.de',
    'accounts.autoixpert.de',
    'autoixpert.de',
    // Beta
    'beta.accounts.autoixpert.de',
    'beta.autoixpert.de',
];

export function hasWhitelistedHostname(url: string | URL): boolean {
    let hostnameOfUrl: string;

    if (typeof url === 'string') {
        hostnameOfUrl = new URL(url)?.hostname;
    } else {
        hostnameOfUrl = url.hostname;
    }

    /**
     * Allow connections to local network IP addresses (e.g. to debug on a mobile device).
     */
    if (
        /^(\d{1,3}\.){3}\d{1,3}$/.test(hostnameOfUrl) &&
        (hostnameOfUrl.startsWith('192.168.') || hostnameOfUrl.startsWith('10.') || hostnameOfUrl === '127.0.0.1')
    ) {
        console.log('Skipped hostname check for local network IP address:', hostnameOfUrl);
        return true;
    }

    return whitelistedHostnames.includes(hostnameOfUrl);
}

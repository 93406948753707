import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CopyMarketAnalysisDocumentsToReportService {
    constructor(private httpClient: HttpClient) {}

    private apiPath = '/api/v0';

    public copyMarketAnalysisDocuments({
        sourceReportId,
        targetReportId,
        marketAnalysisDocumentTypes,
    }: {
        sourceReportId: string;
        targetReportId: string;
        marketAnalysisDocumentTypes: string[];
    }): Promise<MarketAnalysisDocumentCopyResponse> {
        return this.httpClient
            .post<MarketAnalysisDocumentCopyResponse>(`${this.apiPath}/reports/${sourceReportId}/marketAnalysisCopy`, {
                sourceReportId,
                targetReportId,
                marketAnalysisDocumentTypes,
            })
            .toPromise();
    }
}

export interface MarketAnalysisDocumentCopyResponse {
    success: boolean;
}

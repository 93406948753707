<!--********** Report Dimension **********-->
<div id="report-dimensions-container">
    <div
        class="report-dimension"
        routerLinkActive="selected"
        routerLink="../Kürzungsquoten"
        matTooltip="Wie viel kürzt jede Versicherung relativ zum Gesamtumsatz?">
        Quoten
    </div>
    <div
        class="report-dimension"
        routerLinkActive="selected"
        routerLink="../Kürzungsstatus"
        matTooltip="Sind meine Kürzungen noch ungeklärt, abgeschrieben oder wurden sie doch noch bezahlt?">
        Status
    </div>
    <div
        class="report-dimension"
        routerLinkActive="selected"
        routerLink="../Kürzungsgründe"
        matTooltip="Aus welchen Gründen kürzen die Versicherer?">
        Gründe
    </div>
    <div
        class="report-dimension"
        routerLinkActive="selected"
        routerLink="../Kürzungen-Lösungen"
        matTooltip="Durch welche Maßnahmen wurden Kürzungen doch noch bezahlt?">
        Lösungen
    </div>
    <div
        class="report-dimension"
        routerLinkActive="selected"
        routerLink="../Kürzungen-Zahler"
        matTooltip="Wer hat bezahlte Kürzungen nachgezahlt?">
        Zahler
    </div>
</div>
